<dp-document-production-template
    [matter]="currentMatter"
    [rows]="rows"
    [documentTemplates]="documentTemplates"
    [loadDocumentTemplatesFunction]="loadDocTemplates"
    [supTask]="false"
    [sourceTabName]="sourceTabName"
    [selection]="'produceDocuments'"
    [usedInModal]="usedInModal"
>
</dp-document-production-template>



