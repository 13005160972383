<div class="margin-bottom-20 col-lg-12">
    <div></div>
    <div class="col-lg-7 padding-0">
        <div class="col-lg-12 fixed-select-fields">
        <div class="col-lg-4 margin-left-minus-7">
            <div>
                <input type="text"
                       trimmed
                       #search="ngModel"
                       [(ngModel)]="searchText"
                       [dpPlaceholder]="'Subject, Message details'"
                       id="search-by"
                       class="form-control input-search-icon input-search-text focus-first"  />
            </div>
        </div>
        <div class="col-lg-3">
            <div *ngIf="categoryDropDown.length > 0">
                <dp-multi-select  [multiSelectData]="categoryDropDown"  [inputName]="'category'"
                                  [title]="'No Categories Selected'"
                                  [multiSelectedTitle]="'Multiple Categories'"
                                  [showLabelInTitle]="true"
                                  [splitLabelLawyersAndClerks]="true"
                                  [multiSelectDataSelected]="selectedCategories"
                                  (updateMultiSelect)="updateMultiSelectFilters($event,'category')"
                                  class="lawyer-multi-select"></dp-multi-select>
            </div>
        </div>
        <div class="col-lg-5"></div>
        </div>

        <div class="col-lg-12 message-list padding-left-0 padding-right-0 padding-bottom-20 padding-top-10 fixed-fields">
            <ul>
                <li class="padding-top-10" >
                    <a (click)="toggleSearchUserAllMessages(true)" [ngClass]="{'selected-item': userAllMessages}">
                        <span>All</span>
                    </a>

                </li>
                <li class="padding-left-10 padding-top-10"  >
                    <a (click)="toggleSearchUserAllMessages(false)" [ngClass]="{'selected-item': !userAllMessages}">
                        <span>Unread</span>
                    </a>
                </li>
                <li style="width:175px;">
                </li>
            </ul>
        </div>
        <div class="col-lg-12 padding-0 message-table" infinite-scroll [scrollWindow]="true" (scrolled)="getNextPage()" infiniteScrollDistance="1"
        infiniteScrollThrottle="1000">
            <table>
                <thead>
                <th></th>
                <th>
                    <div (click)="sortList('messageSubject')">
                        Subject
                        <div class="sort-icons" *ngIf="sortQuery === 'messageSubject'">
                            <i class="glyphicon"
                               [ngClass]="{'active': sortQuery === 'messageSubject', 'glyphicon-triangle-top':  sortType === 'ASC', 'glyphicon-triangle-bottom':  sortType === 'DESC'}"></i>
                        </div>
                    </div>
                </th>
                <th>
                    <div (click)="sortList('effectiveDate')">
                        Date/Time
                        <div class="sort-icons" *ngIf="sortQuery === 'effectiveDate'">
                            <i class="glyphicon"
                               [ngClass]="{'active': sortQuery === 'effectiveDate', 'glyphicon-triangle-top':  sortType === 'ASC', 'glyphicon-triangle-bottom':  sortType === 'DESC'}"></i>
                        </div>
                    </div>
                </th>
                <th>
                    <div (click)="sortList('category')">
                        Category
                        <div class="sort-icons" *ngIf="sortQuery === 'category'">
                            <i class="glyphicon"
                               [ngClass]="{'active': sortQuery === 'category', 'glyphicon-triangle-top':  sortType === 'ASC', 'glyphicon-triangle-bottom':  sortType === 'DESC'}"></i>
                        </div>
                    </div>

                </th>
                </thead>
                <ng-container *ngIf="userMessagesListLoading">
                    <div id="spinner1" class="padding-top-30">
                        <img src="assets/img/spinner.gif"/>
                    </div>
                </ng-container>
                <ng-container *ngIf="userMessageList && userMessageList.length == 0">
                    <div *ngIf="!userMessagesListLoading" class="no-match-found padding-top-40 padding-bottom-10 text-center">No Records Found</div>
                </ng-container>
                <tbody *ngIf="userMessageList && userMessageList.length > 0">
                    <tr class="loading-row" *ngIf="loading && pageNo==1">
                        <td colspan="100%" class="text-center">
                            <div id="spinner2">
                                <img src="assets/img/spinner.gif"/>
                            </div>
                        </td>
                    </tr>
                <ng-container *ngFor="let item of userMessageList;let i=index;">
                    <tr *ngIf="item.unityMessage" [ngClass]="{'active': item.isSelected , 'unread' : !item.isUserMessageRead , 'read' : item.isUserMessageRead , 'must-read' : item.unityMessage.isMustRead}" tabindex="0"
                        (click)="selectRow(item)" (keydown.TAB)="focusNextRow(i+1)"
                        (keydown.Shift.tab)="focusNextRow(i-1)">
                        <td>
                            <i class="fas fa-exclamation" *ngIf="item.unityMessage.isMustRead"></i>
                        </td>
                        <td>
                            {{item.unityMessage.messageSubject}}

                        </td>
                        <td>
                            {{item.unityMessage.formattedEffectiveDate}}
                        </td>
                        <td>
                             {{getCategoryValue(item.unityMessage.category) === 'Notification' ? 'Correspondence' : getCategoryValue(item.unityMessage.category)}}
                        </td>
                    </tr>


                </ng-container>

                </tbody>
            </table>
        </div>
        <div id="spinner3" class="bottom-loading-row" *ngIf="loading && pageNo>1">
            <img src="assets/img/spinner.gif"/>
        </div>
    </div>
    <div class="col-lg-5 padding-right-0 padding-left-20 margin-top-85">
        <div class="col-lg-12 fixed-right-fields">
        <div class="col-lg-12 padding-left-0 padding-right-0 padding-bottom-10 hide-tool-bar " style="max-height: 270px;">
            <textarea style="width: 100%;" id="messageInfo" dp-read-only="true"
                         name="messageInfo"></textarea>
        </div>
        <div class="col-lg-12" *ngIf="selectedUserMessage">
            <div class="col-lg-6 left">
<!--                <div style="display: inline-block;float:left">-->
                <ng-container *ngIf="!showOpenMatterButton && showLinkToMatterButton">
                    <button type="button" class="dp-btn-green" (click)="openLinkMatterAssignment(selectedUserMessage)"><span>Link to a Matter</span></button>
                </ng-container>
                <ng-container *ngIf="showOpenMatterButton">
                    <button type="button" class="dp-btn-green" (click)="openMatterTab(selectedUserMessage.unityMessage)"><span>Open {{selectedUserMessage.unityMessage.additionalMessageData.opportunityMatterId ? 'Opportunity' : 'Matter'}}</span></button>
                </ng-container>
            </div>
            <div class="col-lg-6">
<!--                <div style="display: inline-block;float:right">-->
                <div class="right display-inline">
                <dp-checkbox fieldId="readCheck"><input type="checkbox"
                                                        id="readCheck"
                                                        [ngModel]="selectedUserMessage.isUserMessageRead"
                                                        (ngModelChange)="updateReadFlag($event)"
                                                        name="readCheck"
                ></dp-checkbox>

                <label class="control-label padding-left-5"  >Mark this message as
                    Read</label>
                </div>
            </div>
        </div>
        </div>

    </div>
</div>


