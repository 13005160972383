import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BankAccount} from '../shared/BankAccount';
import {currentMatter} from '../shared/current-matter';
import {dropDowns} from '../shared/matter-drop-downs';
import {Address, SameAsAddressOption} from '../shared/address';
import {Contact} from '../shared/contact';
import {MatterParticipantWrapper} from '../shared/matter-participant-wrapper';
import {MatterParticipant} from '../shared/matter-participant';
import {Utils} from '../shared/utils';
import {JurisdictionService} from '../property-teranet/jurisdiction.service';
import {Jurisdiction, Matter, MatterUtil, TelephoneTypes} from '../shared';
import {Observable, Subject} from 'rxjs';
import {ContactService} from '../../shared-main/contact.service';
import {ContactQueryService} from '../../contact/contact-query.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {messages} from '../../common/messages';
import {AppConfig} from '../../shared-main/app-configuration';
import {DefaultFieldsService} from '../../shared-main/default-fields.service';
import {OtherContactInformation} from '../../shared-main/other-contact-info-fields/other-contact-information';
import {PurchaserFamilyLawAct} from '../purchaser/family-law-act/purchaser-family-law-act';
import {FamilyLawAct, FlaData} from '../shared/fla-data';
import {PurchaserService} from '../purchaser/purchaser.service';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import * as _ from 'lodash';
import {Logger} from '@nsalaun/ng-logger';
import {ActivatedRoute} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {AutoComplete} from 'primeng/autocomplete';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ContactInfo} from '../shared/contact-info';
import {MatterTab} from '../matter-tab';
import {VendorsSolicitorState} from './vendors-solicitor-state';
import {
  booleanYesNoDropDowns,
  Constants,
  FlaErrorValues,
  LawSocietyMemberDirectoryLinks,
  MatterTopicKey, NOTARY_SOCIETY_MEMBER_DIRECTORY_LINK,
  OtherSideFlaErrorValues,
  SnapshotBurgerMenuActions
} from '../../shared-main/constants';
import {ErrorService} from '../../shared/error-handling/error-service';
import {SelectItem} from 'primeng/api';
import {contactDropDowns} from '../../contact/contact-drop-downs';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {AddressTypes} from '../shared/address-types';
import {ContactTab} from '../../contact/contact-tab';
import {CommonContactDialogComponent} from '../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {Tab} from '../../shared/tabbing/tab';
import {DPError} from '../../shared/error-handling/dp-error';
import {MatterService} from '../matter.service';
import {PurchaserCapacity} from '../purchaser/capacity/purchaser-capacity';
import {DropdownModel} from '../../shared-main/dropdown-model';
import {MatterContactInfo} from '../shared/matter-contact-info';
import {PurchasersTitleModalComponent} from '../purchaser/title/purchasers-title.component';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {MatterParticipantService} from '../matter-participant-service';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {UUIDUtil} from '../../main/uuid-util';
import {PROVINCE_CODES} from '../shared/user-province';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {AuthZService} from '../../core/authz/auth-z.service';
import {SuggestedSpouseModal} from '../suggested-spouse-modal/suggested-spouse-modal.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {ApplicationError} from '../../core/application-error';
import {GlobalLogger, LogLevelTypes} from '../../core/global-logger';
import {TitleDetailsUtil} from '../shared/title-details-util';
import {MatterTitleDetailsUtil} from '../shared/matter-utils/matter-title-details-util';
import {ContactChangesListener} from '../../contact/contact-changes-listener';
import {LegacyContactModalComponent} from '../signing-officer/legacy-contact/legacy-contact-modal.component';
import {ContactCommandService} from '../../contact/contact-command.service';
import {DocumentProductionService} from '../document-production/document-production.service';
import {ProvinceApplicableGenders} from '../../shared-main/province-applicable-gender';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {UserStateService} from '../../shared-main/user-state/user-state.service';

declare var jQuery: any;

const defaultTab = 'Profile';

/**
 * ### Notification: Please synchronize OtherSideSolicitorDetailsComponent if you modify solicitor, law firm and law clerk###
 */
@Component({
  selector: 'dp-matter-vendors-solicitor',
  templateUrl: 'vendors-solicitor.component.html',
  styleUrls: [
    './vendors-solicitor.components.scss'
  ],
  providers: [ EmailFieldService ]
})
@FocusFirstElementDecorator()
export class VendorsSolicitorComponent implements OnInit, OnDestroy {

  // @ViewChild('vendorSolicitorForm') vendorSolicitorForm : NgForm;
  @ViewChild('solicitor') solicitor: AutoComplete;
  @ViewChild('lawClerkName') lawClerkName: AutoComplete;
  @Input() showWizardFields: boolean = false;
  // contactAddressType : string = AddressTypes.solicitorAddress;
  subscription: any;
  mailingAddress: Address;
  Jurisdiction: string;
  survivalAfterClosing: string;

  // Auto complete Jurisdiction
  // list of searched results based on input
  jurisdictions: any;
  jurisdictionsLoading = false;
  searchTermJurisdiction = new Subject<string>();

  // flag to control displaying of help text 'start typing...'
  jurisdictionAutoCompleteEmptyFlag: boolean = false;

  purchaserSaveModel: any;
  selectedOtherParties: Array<MatterParticipantWrapper>;
  searchTermOtherPartiesSubject = new Subject<string>();
  utils: Utils;
  matchedOtherParties: any;
  loadingOtherParties: boolean = false;
  autoCompleteEmptyFlag: any;
  flaStatements: FlaData;

  typedPurchaserNameFull: string;

  solicitors: any;
  solicitorsLoading = false;
  searchTermSolicitor: Subject<string> = new Subject<string>();
  solicitorAutoCompleteEmptyFlag: boolean = false;
  selectedSolicitor: MatterParticipantWrapper;
  showSolicitorDropDownArrow: boolean;
  disableBarristerSolicitor: boolean = false;
  disableAddress: boolean = false;
  selectedLawFirm: MatterParticipantWrapper;
  lawFirms: any = [];
  lawFirmsLoading = false;
  searchTermLawFirm: Subject<string> = new Subject<string>();
  lawFirmAutoCompleteEmptyFlag: boolean = false;
  selectedLawFirmInputValue: string;
  lawFirmKeyDownCode: number;

  solicitorsForLawFirm: Contact[] = [];
  // solicitorsForLawFirmItems : any[] = [];
  selectedSolicitorsForLawFirm: any;

  //firmSelected : boolean = false;
  // clickListener : Function;

  lawFirmTrashMessage: string = messages.mortgage.lawFirmTrashMessage;

  lawClerks: any = [];
  // selectLawClerk = false;
  selectedLawClerkValue: any;
  showLawClerksDropDownArrow: boolean; //in case the law Firm is selected and there is member clerks in the firm

  clickListener: Function;

  //It is for checking if vendorContactInfo is changed
  // vendorContactInfoRef : ContactInfo;

  barristerSolicitors: SelectItem[];

  otherPartyContainerId: string = OtherSideFlaErrorValues.containerId;
  otherPartyFieldPrefix: string = OtherSideFlaErrorValues.fieldPrefix;
  otherPartyFieldIndexId: string = FlaErrorValues.fieldIndexId;
  flaMatterParticipantSpouseFieldId = FlaErrorValues.flaMatterParticipantSpouseFieldId;
  flaSpouseNameFieldId = FlaErrorValues.flaSpouseNameFieldId;

  currentTimeStamp = Date.now();
  contactAddressType: string = AddressTypes.solicitorAddress;
  solicitorAddressDd: SameAsAddressOption[] = [];

  fieldKey: string;

  residingAtSubjectPropertys: DropdownModel[] = dropDowns.residingAtSubjectPropertyOptions;

  matterPurchasersCapacityOptions: any[];

  otherPartiesChanged: boolean = true;
  _otherParties: MatterParticipant[];
  emailFieldKey: string;
  titleDetailTypes: DropdownModel[] = dropDowns.titleDetails;
  _matter: Matter;
  yesNoDefaultNoOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;
  defaultProvinceCode: string = 'ON';

  public defaultOtherContactInfo: OtherContactInformation[] = [];

  constructor(public contactService: ContactService,
              public matterService: MatterService,
              public purchaserService: PurchaserService,
              public childGetsFromParent: GetGlobalSaveModelService,
              public jurisdictionService: JurisdictionService,
              public contactQueryService: ContactQueryService,
              public contactCommandService: ContactCommandService,
              public errorService: ErrorService,
              public dialogService: DialogService,
              public defaultFieldsService: DefaultFieldsService,
              public appConfig: AppConfig,
              public route: ActivatedRoute,
              public logger: Logger,
              public globalLogger: GlobalLogger,
              public tabService: TabsService,
              public emailFieldService: EmailFieldService,
              public documentProfileCache: DocumentProfileCache,
              public authZService: AuthZService,
              public matterParticipantService: MatterParticipantService,
              public tabsStateService: TabsService,
              public contactChangesListener: ContactChangesListener,
              public documentProductionService: DocumentProductionService,
              public lockScreenService: LockScreenService,
              public userStateService: UserStateService) {
  }

  ngOnInit(excludeCapacityCheckWarningAndLawClekUpdate?: boolean) {
    //this.tabService.activeTab.section = this.route.routeConfig.path;
    this.defaultProvinceCode = this.userStateService.defaultProvinceCode;
    this.barristerSolicitors = contactDropDowns.BARRISTERSSOLICITORS;

    this.purchaserSaveModel = [];
    this.selectedOtherParties = [];
    this.utils = new Utils();
    this.emailFieldKey = this.matter && this.matter.isOpportunityMatter() ? EmailKeys.opportunityMatter : EmailKeys.vendorsSolicitor;

    if (this.matter.matterContactInfo == undefined) {
      this.matter.matterContactInfo = new MatterContactInfo();
    }

    if (!this.matter.matterContactInfo.eligibleForTaxRebate) {
      this.matter.matterContactInfo.eligibleForTaxRebate = DpBooleanValueTypes.Y_n;
    }

    //Initialize the vendor info structure, add otherPartyContactInfo if not present yet
    if (!this.matter.otherPartyContactInfo) {
      this.matter.createOtherPartyContactInfo();
      if (this.isMassUpdateTab()) {
        this.serviceAddress.country = '';
      }
    } else {
      if (this.matter.otherPartyContactInfo.firmId) {
        // this.populateComboBox(this.matter.vendorContactInfo.firmId);
        // this.firmSelected = true;
        this.disableBarristerSolicitor = true;
        this.disableAddress = true;
      }

      if (this.matter.otherPartyContactInfo.solicitorName) {
        // this.selectedSolicitor = this.matter.vendorContactInfo.solicitorName;
        if (this.matter.otherPartyContactInfo.solicitorId) {
          this.disableBarristerSolicitor = true;
          this.disableAddress = true;
        }

      }
      // DPPMP-30966 For copy link matter,  it should not update selectedLawClerk in this tab with linked matter data.
      // In DPPMP-30336, it has detials how to update
      if (!excludeCapacityCheckWarningAndLawClekUpdate) {
        if (this.matter.otherPartyContactInfo.lawClerkName) {
          this.selectedLawClerk = this.matter.otherPartyContactInfo.lawClerkName;
        } else {
          this.selectedLawClerk = null;
        }
      }

    }

    if (this.matter.otherPartyContactInfo && !this.matter.otherPartyContactInfo.titleDetailsType) {
      this.matter.otherPartyContactInfo.titleDetailsType = 'AUTO_POPULATED';
    }

    if (this.matter.otherPartyContactInfo && !this.matter.otherPartyContactInfo.residingAtSubjectProperty) {
      this.matter.otherPartyContactInfo.residingAtSubjectProperty = DpBooleanValueTypes.Y_n;
    }

    // this.mailingAddress = this.matter.vendorContactInfo.mailingAddress;
    // Get jurisdiction from backend and add it into UI
    this.selectedJurisdiction = this.matter.vendorExecDocsAt ?
      new Jurisdiction({jurisdictionName: this.matter.vendorExecDocsAt} as Jurisdiction) : null;
    // construct vendors
    this.buildOtherPartiesStructure();

    // construct the law firm
    this.buildLawFirmStructure(false);

    // construct the solicitor
    this.buildSolicitorStructure(false);

    this.defaultFieldsService.getValues(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId))
    .map((value: any) => value && value.ConfigurationType && value.ConfigurationType.configurationOptionValues)
    .subscribe(
      (values) => {
        if (Array.isArray(values)) {
          values.forEach((item: any) => {
            const oci: OtherContactInformation = new OtherContactInformation();
            oci.fieldName = item.configurationValue;
            this.defaultOtherContactInfo.push(oci);
          });
        }
      }
    );

    if (!this.matter.otherPartiesCapacity) {
      this.matter.otherPartiesCapacity = PurchaserCapacity.defaultMatterCapacityValue;
    }

    if (this.isPurchaseMatter || this.isMortgageMatter) {
      this.matter.addDirectDepositInstructionOtherParty();
    }
    // AutoComplete
    this.searchTermOtherPartiesSubject
    .switchMap((term: string) => {
      this.loadingOtherParties = true;
      if (term.trim() === '') {
        this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            // observer.next(this.utils.getStartTypingToVendor());
            observer.next({displayName: this.placeHolderMsgForOtherPartySearch});
          }, 10);
        });

        return observable;

      } else {
        this.autoCompleteEmptyFlag = false;
        let genderSearch = this.matter.isMatterProvinceBC ? ProvinceApplicableGenders.getGendersForProvince(this.matter.provinceCode) : null;
        return this.contactService.getActiveContactListPerPage(term, 'CLIENT', false, 1, 15, 'ACTIVE', false, null, genderSearch);
      }
    })
    .subscribe(
      (otherParties: any) => {
        if (otherParties && otherParties.length) {
          this.matchedOtherParties = otherParties;
          if (this.matchedOtherParties.some(entry => entry.noResultFoundFlag)) {
            this.autoCompleteEmptyFlag = true;
          }
        }
        this.loadingOtherParties = false;
      },
      error => {
        this.loadingOtherParties = false;
        this.logger.error('other party search error:', error);
      }
    );

    // Jurisdictions
    this.searchTermJurisdiction
    .switchMap((term: string) => {
      this.jurisdictionsLoading = true;
      this.jurisdictionAutoCompleteEmptyFlag = false;
      if (term.trim() === '') {
        this.jurisdictionAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;
      } else {
        return this.jurisdictionService.getJurisdictions(term, this.jurisdictionService.page, this.jurisdictionService.limit, this.jurisdictionService.sortQuery,
          this.jurisdictionService.sortingType, null, null, true);
      }
    })
    .subscribe(
      (jurisdictions: any[]) => {
        this.jurisdictionsLoading = false;
        return this.jurisdictions = jurisdictions;
      },
      error => {
        this.jurisdictionsLoading = false;
        this.logger.error('jurisdiction search error:', error);
      }
    );

    // This is an Observable which will notify the changes in javascript object.
    if (!this.subscription) {
      this.subscription = this.childGetsFromParent.getItem$
      .subscribe(() => {
        this.initVendorSolicitorStructure();
      });
    }

    // Law Firm
    this.searchTermLawFirm.switchMap((term: string) => {
      this.lawFirmAutoCompleteEmptyFlag = false;
      this.lawFirmsLoading = true;
      let page: number = 1;
      let perPage: number = 15;
      if (term.trim() === '') {
        this.lawFirmAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToContact()));
          }, 10);
        });
        return observable;

      } else {
        return this.contactService.getContactsList(term.trim(), 'LAW_FIRM', false, 'ACTIVE', null, null, null, page, perPage);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.lawFirmsLoading = false;
        return this.lawFirms = data;
      },
      error => {
        this.lawFirmsLoading = false;
        this.logger.error('law Firm search error:', error);
      });

    // Solicitor
    this.searchTermSolicitor.switchMap((term: string) => {
      this.solicitorAutoCompleteEmptyFlag = false;
      this.solicitorsLoading = true;
      if (term.trim() === '') {
        this.solicitorAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'SOLICITOR-DISPLAYNAME-ADDRESS', false, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.solicitorsLoading = false;
        if (this.isOpportunityActingForBoth()) {
          const legFirmId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.legalFirmId));
          return this.solicitors = data.filter(item => item.legalFirmId == legFirmId);
        } else {
          return this.solicitors = data;
        }

      },
      error => {
        this.solicitorsLoading = false;
        this.logger.error('solicitors search error:', error);
      });

    // this.checkSolicitorsForManualEntry();

    //When enter this page, it should check and show if there is capacity warning message
    // For copy link matter,  it should not add other side client Tenure waring message into this tab and should add the link active tab.
    if (!excludeCapacityCheckWarningAndLawClekUpdate) {
      this.checkCapacityWarningMessage(this.matter.otherPartiesCapacity);
    }

  }

  initVendorSolicitorStructure(): void {
    this.closeAllOpenedOtherPartiesShutters();
    if (this.selectedLawFirm && this.selectedLawFirm.matterParticipant) {
      this.setExpanded(this.selectedLawFirm, false);
    }
    if (this.selectedSolicitor && this.selectedSolicitor.matterParticipant) {
      this.setExpanded(this.selectedSolicitor, false);
    }
    //this.matter = newMatter;
    //this.matter = currentMatter;
    this.buildOtherPartiesStructure();
    this.buildLawFirmStructure(false);
    this.buildSolicitorStructure(false);
    this.initiateEmailFieldService();
    this.loadFlaStatements();
  }

  get matter(): Matter {
    return this._matter ? this._matter : currentMatter.value;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.closeAllOpenedOtherPartiesShutters();
    if (this.selectedLawFirm && this.selectedLawFirm.matterParticipant) {
      this.setExpanded(this.selectedLawFirm, false);
    }
    if (this.selectedSolicitor && this.selectedSolicitor.matterParticipant) {
      this.setExpanded(this.selectedSolicitor, false);
    }
  }

  initiateEmailFieldService(): void {
    this.emailFieldService.matter = this.matter;
    this.emailFieldService.key = this.matter && this.matter.isOpportunityMatter() ? EmailKeys.opportunityMatter : EmailKeys.matterOpening;
  }

  get lawFirm(): Contact {
    const otherPartyLawFirm: MatterParticipant = this.matter.otherPartyLawFirm;
    return otherPartyLawFirm && otherPartyLawFirm.contact;
  }

  public createLawFirmWrapper(participant?: MatterParticipant) {
    if (!this.selectedLawFirm) {
      this.selectedLawFirm = new MatterParticipantWrapper();
    }
    if (participant) {
      this.selectedLawFirm.matterParticipant = participant;
      this.selectedLawFirm.dataModel = participant.contact;
      this.selectedLawFirm.editMode = false;
    } else {
      this.selectedLawFirm.editMode = true;
    }
  }

  populateLawFirmDataStructure(unshutter: boolean, lawFirmParticipant: MatterParticipant, lawFirmContact: Contact, sourceContact: Contact) {
    // TODO: CLean up update OtherPartyContactInfo
    // At the beginning, law firm used OtherPartyContactInfo save source id, name and other information.
    // Then changed to use participant. How ever Docgen used other information and not switch to participant.
    // If the  docgen is changed, the OtherPartyContactInfo only need save law clerk information.
    this.selectedLawFirm.sourceContactLegfirmId = sourceContact.sourceContactId;
    this.selectedLawFirm.matterParticipant.sourceContact = sourceContact;
    this.selectedLawFirm.dataModel = sourceContact;
    this.updateOtherPartyContactInfoWithLawFirm(lawFirmContact);
    this.selectedLawFirm.dataModel = null;
    if (lawFirmParticipant.sourceContactLockAcquired
      && lawFirmContact.isDirty) {
      // If it is in edit mode and has already modified data, it should not be in out of sync state.
      // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic.
      this.selectedLawFirm.isStale = false;
      this.selectedLawFirm.isClearFlagWithoutUpdatingMatter = false;
    } else {
      this.selectedLawFirm.isStale = lawFirmContact.isStaleLawFirm(sourceContact);
      this.selectedLawFirm.isClearFlagWithoutUpdatingMatter
        = lawFirmContact.isLawFirmClearFlagWithoutUpdatingMatter(sourceContact);
    }
    this.tabService.updateIsLockedElsewhereStatus(this.selectedLawFirm, sourceContact);
    this.selectedLawFirm.canBeUpdatedFromSourceContact
      = this.selectedLawFirm.isStale && !!lawFirmContact.sourceContactId;
    this.selectedLawFirm.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;
    this.selectedLawFirm.updateSourceContactFlagsForSnapshot(sourceContact);
    this.setExpanded(this.selectedLawFirm, unshutter);
    this.populateComboBox(sourceContact);
    this.setSolicitorAddressDd(sourceContact);
  }

  public buildLawFirmStructure(unshutter: boolean, sourceLawFirm?: Contact): void {
    const lawFirmParticipant: MatterParticipant = this.matter.otherPartyLawFirm;
    this.createLawFirmWrapper(lawFirmParticipant);
    if (!lawFirmParticipant) {
      //reset some value in the state
      this.showSolicitorDropDownArrow = false;
      if (this.selectedLawFirm) {
        this.selectedLawFirm.dataModel = null;
      }
      this.setSolicitorAddressDd(null);
      return;
    }

    const lawFirmContact = lawFirmParticipant.contact;
    if (lawFirmContact && lawFirmContact.sourceContactId) {
      if (sourceLawFirm) {
        this.populateLawFirmDataStructure(unshutter, lawFirmParticipant, lawFirmContact, sourceLawFirm);
      } else {
        this.contactQueryService.getContactForMatter(lawFirmContact.sourceContactId, lawFirmContact.displayName, true).subscribe((sourceContact: Contact) => {
          this.populateLawFirmDataStructure(unshutter, lawFirmParticipant, lawFirmContact, sourceContact);
        }, (error: ApplicationError) => {
          if (error.errorCode == 'app.ContactNotFound') {
            //Do nothing for it
          }
        });
      }

    }
  }

  public createSolicitorWrapper(participant?: MatterParticipant) {
    if (!this.selectedSolicitor) {
      this.selectedSolicitor = new MatterParticipantWrapper();
    }
    if (participant) {
      this.selectedSolicitor.matterParticipant = participant;
      this.selectedSolicitor.dataModel = participant.contact;
      this.selectedSolicitor.editMode = false;
    } else {
      this.selectedSolicitor.editMode = true;
    }
  }

  public createSolicitorStructure(sourceContactId: number): void {
    this.contactQueryService.getContactForMatter(sourceContactId, null, true).subscribe((sourceSolicitor: Contact) => {

      this.matter.addMatterParticipant(sourceSolicitor, true, this.matter.otherPartyMPRoleSolicitor);
      this.buildSolicitorStructure(false, sourceSolicitor);
    });
  }

  public buildSolicitorStructure(unshutter: boolean, sourceSolicitor?: Contact): void {
    const solicitorParticipant: MatterParticipant = this.matter.otherPartySolicitor;
    this.createSolicitorWrapper(solicitorParticipant);
    if (!solicitorParticipant) {
      return;
    }

    const solicitorContact: Contact = solicitorParticipant.contact;
    if (solicitorContact) {
      //if passed source contact is already sufficent enough, we don't need to make another service call to obtain the same object
      if (sourceSolicitor && solicitorContact.sourceContactId === sourceSolicitor.sourceContactId) {

        this.populateSolicitorStructure(solicitorParticipant, unshutter, sourceSolicitor, solicitorContact);

      } else {
        this.lockScreenService.lockForUpdate = true;
        if (solicitorContact.sourceContactId) { // sourcecontactid missing for some otherside solicitors
          this.contactQueryService.getContactForMatter(solicitorContact.sourceContactId, solicitorContact.displayName, true)
          .finally(() => this.lockScreenService.lockForUpdate = false)
          .subscribe((sourceContact: Contact) => {
            this.populateSolicitorStructure(solicitorParticipant, unshutter, sourceContact, solicitorContact);

          }, (error: ApplicationError) => {
            let stackTrace: string = JSON.stringify(new Error().stack);
            this.globalLogger.log(LogLevelTypes.WARN, 'Fail to get solicitorContact with sourceContactId: ' + solicitorContact.sourceContactId, stackTrace);
          });
        }
        this.lockScreenService.lockForUpdate = false;
      }
    }
  }

  // TODO: CLean up update OtherPartyContactInfo.
  // At the beginning Solicitor used OtherPartyContactInfo save source id, name and other information
  // Then changed to use participant. However Docgen used other information and not switch to participant.
  // If the  docgen is change, the otherPartyContactInfo only need save law clerk information. It also can remove referral import solicitor propagation
  populateSolicitorStructure(solicitorParticipant: MatterParticipant, unshutter: boolean, sourceSolicitor: Contact, solicitorContact: Contact) {
    this.selectedSolicitor.dataModel = sourceSolicitor;
    this.updateOtherPartyContactInfoWithSolicitor(solicitorContact);
    this.selectedSolicitor.dataModel = null;
    if (solicitorParticipant.sourceContactLockAcquired
      && solicitorContact.isDirty) {
      // If it is in edit mode and has already modified data, it should not be in out of sync state.
      // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic.
      this.selectedSolicitor.isStale = false;
      this.selectedSolicitor.isClearFlagWithoutUpdatingMatter = false;
    } else {
      this.selectedSolicitor.isStale = solicitorContact.isStaleSolicitor(sourceSolicitor);
      this.selectedSolicitor.isClearFlagWithoutUpdatingMatter
        = solicitorContact.isSolicitorClearFlagWithoutUpdatingMatter(sourceSolicitor);
    }
    this.tabService.updateIsLockedElsewhereStatus(this.selectedSolicitor, sourceSolicitor);
    this.selectedSolicitor.canBeUpdatedFromSourceContact
      = this.selectedSolicitor.isStale && !!solicitorContact.sourceContactId;
    this.selectedSolicitor.lastUpdatedOn = sourceSolicitor.lastUpdatedOnMsg;
    this.selectedSolicitor.updateSourceContactFlagsForSnapshot(sourceSolicitor);
    this.setExpanded(this.selectedSolicitor, unshutter);
  }

  checkIfJurisdictionEmpty(selectedJurisdiction: string): void {
    if (!selectedJurisdiction || selectedJurisdiction.length == 0) {
      this.matter.vendorExecDocsAt = null;
      this.matter.documentsExecutedAtJurisdictionId = null;
    }
  }

  getContactKey(contact: Contact) {
    let tmpContactKey = 'PERSON';
    if (contact.gender === 'CORPORATION' || contact.gender === 'OTHERENTITY') {
      tmpContactKey = 'ORGANIZATION';
    }
    return tmpContactKey;
  }

  getDefaultQuery(type: string): any {
    return {query: type};
  }

  isMatterReadOnly(): boolean {
    return this.tabService.isLinkedMatterDirty() || this.tabService.isMatterProjectLocked(this.matter) || this.authZService.isMatterReadOnlyAccess();
  }

  enableSave(): void {
    this.matter.dirty = !this.isMatterReadOnly();
  }

  updateFamilyLawActFormSave(): void {
    this.enableSave();
  }

  updateProfileFormSave(): void {
    this.enableSave();
  }

  isExpanded(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return matterParticipantWrapper.expanded;
  }

  get directDepositOptions() {
    return dropDowns.directDepositOptions;
  }

  //only availale to the purchase matter
  get vendorWarrantyOptions() {
    return dropDowns.vendorWarrantyOptions;
  }

  get serviceAddress(): Address {
    var address = this.matter.otherPartyContactInfo ? this.matter.otherPartyContactInfo.serviceAddress : null;
    if (!address) {
      address = new Address();
    } else if (!address.id || address.id < 0) {
      address.id = UUIDUtil.getUUID();
    }
    return address;
  }

  setAsPrimaryOtherParty(otherParty: MatterParticipantWrapper): void {
    if (!otherParty.primary) {

      if (this.primaryMPWrapperOtherParty) {
        let currentPrimaryPurchaser: MatterParticipantWrapper = this.primaryMPWrapperOtherParty;
        currentPrimaryPurchaser.primary = false;
      }
      otherParty.primary = true;
      this.matter.reCalculateOtherPartyReLine();
      this.enableSave();
    }
  }

  get primaryMPWrapperOtherParty(): MatterParticipantWrapper {
    return this.selectedOtherParties.find((otherPartyEntry: MatterParticipantWrapper) => {
      return otherPartyEntry.primary;
    });
  }

  // get primaryVendor() : any {
  //     let pVendor : MatterParticipantWrapper = this.primaryMPWrapperOtherParty;
  //     return pVendor && pVendor.matterParticipant && pVendor.matterParticipant.contact;
  // }

  // This method is for fill the purchaser tab data and create
  //  the autocomplete (contact) dynamically .
  buildOtherPartiesStructure(): void {
    this.matterPurchasersCapacityOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(this.otherParties.length, this.matter.provinceCode);
    let matterParticipantSorted: MatterParticipant[] = this.matter.otherSideClients || [];
    let length: number = matterParticipantSorted.length;
    this.creatOtherPartyListByMatterParticipant(matterParticipantSorted);
    if (length === 0) {
      this.initOtherParty();
    }
  }

  public creatOtherPartyListByMatterParticipant(matterParticipantSorted: MatterParticipant[]): void {

    this.selectedOtherParties = [];
    let length: number = matterParticipantSorted.length;

    for (let i: number = 0; i < length; i++) {
      //isStale can add here if it is needed in the future
      let contact: Contact = matterParticipantSorted[ i ].contact;
      matterParticipantSorted[ i ].contact = new Contact(contact);

      // let isStale: boolean = false;
      const sourceContactId: number = matterParticipantSorted[ i ].contact.sourceContactId;
      if (sourceContactId) {
        this.contactQueryService
        .getContactForMatter(sourceContactId)
        .subscribe((sourceContact: Contact) => {
          const contact: Contact = matterParticipantSorted[ i ].contact;
          let currentPurchaser: MatterParticipantWrapper = this.selectedOtherParties.find(p => p.sourceContactId === contact.sourceContactId);
          this.matterParticipantService.updateParticipantWrapperState(currentPurchaser, sourceContact);
        });
      }
      const matterParticipantWrapper: MatterParticipantWrapper
        = this.addOtherPartyWrapperForMatterParticipant(matterParticipantSorted[ i ]);
    }
  }

  /**
   * This method update matter participant wrapper after building matter participant
   * @param MatterParticipantWrapper
   */
  updateOtherPartyWrapper(wrapper: MatterParticipantWrapper, matterParticipant: MatterParticipant) {
    wrapper.showLabel = true;
    wrapper.editMode = false;
    wrapper.setSourceContactFlagsAsPrivate();
    wrapper.dataModel = {genericFullName: matterParticipant.contact ? matterParticipant.contact.genericFullName : null};
    wrapper.matterParticipant = matterParticipant;
  }

  /**
   * This method creates matter participant wrapper from existing matter participant
   * @param MatterParticipantWrapper
   */
  addOtherPartyWrapper(wrapper: MatterParticipantWrapper): MatterParticipantWrapper {
    wrapper.showLabel = true;
    wrapper.setSourceContactFlagsAsPrivate();
    if (!Array.isArray(this.selectedOtherParties)) {
      this.selectedOtherParties = [];
    }
    this.selectedOtherParties.push(wrapper);
    return wrapper;
  }

  /**
   * This method creates matter participant wrapper from existing matter participant
   * @param matterParticipant
   * @returns {MatterParticipantWrapper}
   */
  createWrapperForMatterParticipant(matterParticipant: MatterParticipant): MatterParticipantWrapper {
    let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    matterParticipantWrapper.matterParticipant = matterParticipant;
    matterParticipantWrapper.dataModel = {genericFullName: matterParticipant.contact.genericFullName};
    matterParticipantWrapper.editMode = false;
    matterParticipantWrapper.selectedTab = defaultTab;
    return matterParticipantWrapper;
  }

  /**
   * This method creates matter participant wrapper for an existing matter participant and add it into OtherParty list
   * @param matterParticipant
   */
  addOtherPartyWrapperForMatterParticipant(matterParticipant: MatterParticipant) {
    return this.addOtherPartyWrapper(this.createWrapperForMatterParticipant(matterParticipant));
  }

  // Initialize the other Client
  initOtherParty(): void {
    let firstMatterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    // Dummy MatterParticipant and Contact data to avoid crash of hidden snapshot page
    firstMatterParticipantWrapper.matterParticipant = new MatterParticipant();
    firstMatterParticipantWrapper.matterParticipant.contact = new Contact();
    firstMatterParticipantWrapper.matterParticipant.contact.contactName.lastName = null;
    firstMatterParticipantWrapper.editMode = true;
    firstMatterParticipantWrapper.selectedTab = defaultTab;
    firstMatterParticipantWrapper.setSourceContactFlagsAsPrivate();
    this.selectedOtherParties.push(firstMatterParticipantWrapper);
  }

  toggleContactSnapshot(wrapper: MatterParticipantWrapper, isOtherParty?: boolean): void {
    if (wrapper.matterParticipant) {
      if (isOtherParty) {
        // build spouseNameOptions
        this.setOtherPartyExpanded(wrapper, !wrapper.expanded);
        wrapper.spouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(wrapper.matterParticipant, this.matter.otherSideClientType, this.matter);
      } else {
        this.setExpanded(wrapper, !wrapper.expanded);

      }
    }
  }

  toggleLawFirmContactSnapshot(): void {
    this.setExpanded(this.selectedLawFirm, !this.selectedLawFirm.expanded);
  }

  isFamilyLawActVisible(matterParticipant: MatterParticipant): boolean {
    return PurchaserFamilyLawAct.isFamilyLawActVisibleForParticipant(matterParticipant, true, this.matter.provinceCode, this.matter.otherSideClients.length, this.matter.matterType);
  }

  getAllFamilyLawActAlerts(matterParticipant: MatterParticipant): string[] {
    return PurchaserFamilyLawAct.getFamilyLawActAlerts(matterParticipant, this.matter.otherSideClientType, this.matter, this.matter.provinceCode);
  }

  // Omnibar methods to get other parties
  search(event): void {
    let entered: string = event.query;
    let currentEntity = this.selectedOtherParties[ this.selectedOtherParties.length - 1 ];
    if (entered) {
      this.searchTermOtherPartiesSubject.next(entered);
    }
  }

  validateJurisdiction() {
    // for autocomplete (blur) method is not working so we are using (change) method and then setTimeout so dataSelectedJurisdiction() will execute first
    // then we validate if a jurisdiction was selected from dropdown list
    setTimeout(() => {
      if (this.selectedJurisdiction.id === undefined) {
        this.selectedJurisdiction = undefined;
        this.matter.vendorExecDocsAt = null;
        this.matter.documentsExecutedAtJurisdictionId = null;
      }
    }, 1000);
  }

  // autocomplete Jurisdiction
  jurisdictionHelpText(): void {
    if (this.selectedJurisdiction === undefined
      || this.selectedJurisdiction === null
      || this.selectedJurisdiction.toString() === '') {
      this.searchTermJurisdiction.next('');
    }
  }

  // Code for omnibar search jurisdiction
  searchJurisdiction(event): void {
    let entered: string = event.query;
    this.searchTermJurisdiction.next(entered);
    //console.log("searchJurisdiction: ", this);
  }

  // On jurisdiction input data select event
  //only for Purchase Matter
  dataSelectedJurisdiction(): void {

    if (this.selectedJurisdiction.id === undefined) {
      this.selectedJurisdiction = undefined;
    } else {
      this.enableSave();

    }
    this.matter.vendorExecDocsAt = this.selectedJurisdiction ? this.selectedJurisdiction.jurisdictionName : null;
    this.matter.documentsExecutedAtJurisdictionId = this.selectedJurisdiction ? this.selectedJurisdiction.id : null;
  }

  hasSourceContact(wrapper: MatterParticipantWrapper): boolean {
    return !!(wrapper && wrapper.matterParticipant && wrapper.matterParticipant.contact && wrapper.matterParticipant.contact.sourceContactId);
  }

  setOtherPartyExpanded(wrapper: MatterParticipantWrapper, expandedTarget: boolean) {
    if (!wrapper) {
      return;
    }
    if (this.matter.locked || !this.hasSourceContact(wrapper)) {
      //If matter locked, or the contact is a matter level contact (no source contact ID), just toggle
      wrapper.expanded = expandedTarget;
      return;
    }

    this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, wrapper);
    // if(!wrapper.isOwnedBySystemAccount) {
    //     // "expandedTarget === true" means it will open shutter and lock this contact
    //     // "expandedTarget === false" means it will close shutter and unlock this contact
    //     if(expandedTarget === true
    //         && wrapper.matterParticipant
    //         && wrapper.matterParticipant.sourceContactLockAcquired) { //It is locked by itself
    //         wrapper.isLockedElsewhere = false;
    //         wrapper.lockedSourceContact = null;
    //         wrapper.expanded = expandedTarget;
    //     } else {// If this contact is locked by other user, it will return the locking user information
    //         this.tabService.lockSourceContactForShutter(wrapper, expandedTarget).subscribe((result : Contact) => {
    //             if(result) {
    //                 if(wrapper.matterParticipant.sourceContactLockAcquired
    //                     && wrapper.matterParticipant.contact.isDirty) {
    //                     // If it is in edit mode and has already modified data, it should not be in out of sync state.
    //                     // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic
    //                     wrapper.isStale = false;
    //                     wrapper.isClearFlagWithoutUpdatingMatter = false;
    //                 } else {
    //                     // wrapper.isStale = wrapper.matterParticipant.contact.isStaleSolicitor(result);
    //                     // wrapper.isClearFlagWithoutUpdatingMatter
    //                     //     = wrapper.matterParticipant.contact.isSolicitorClearFlagWithoutUpdatingMatter(result);
    //                     wrapper.isStale = wrapper.retrieveStaleStatus(result);
    //                     wrapper.isClearFlagWithoutUpdatingMatter
    //                         = wrapper.refreshClearFlagStatus(result);
    //                 }
    //                 this.tabService.updateIsLockedElsewhereStatus(wrapper, result);
    //
    //                 wrapper.canBeUpdatedFromSourceContact
    //                     = wrapper.isStale && !!wrapper.matterParticipant.contact.sourceContactId;
    //                 wrapper.lastUpdatedOn = result.lastUpdatedOnMsg;
    //                 wrapper.updateSourceContactFlagsForSnapshot(result);
    //                 wrapper.expanded = expandedTarget;
    //             }
    //         });
    //     }
    // } else {
    //     wrapper.expanded = expandedTarget;
    // }
  }

  setExpanded(wrapper: MatterParticipantWrapper, expandedTarget: boolean) {
    if (!wrapper || wrapper.expanded === expandedTarget) {
      return;
    }
    if (this.matter.locked || !wrapper.sourceContactId) {
      //If matter locked, or the contact is a matter level contact (no source contact ID), just toggle
      wrapper.expanded = expandedTarget;
      return;
    }

    if (!wrapper.isOwnedBySystemAccount) {
      // "expandedTarget === true" means it will open shutter and lock this contact
      // "expandedTarget === false" means it will close shutter and unlock this contact
      if (expandedTarget === true
        && wrapper.matterParticipant
        && wrapper.matterParticipant.sourceContactLockAcquired) { //It is locked by itself
        wrapper.isLockedElsewhere = false;
        wrapper.lockedSourceContact = null;
        wrapper.expanded = expandedTarget;
      } else {// If this contact is locked by other user, it will return the locking user information
        this.tabService.lockSourceContactForShutter(wrapper, expandedTarget).subscribe((result: Contact) => {
          if (result) {
            if (wrapper.matterParticipant.sourceContactLockAcquired
              && wrapper.matterParticipant.contact.isDirty) {
              // If it is in edit mode and has already modified data, it should not be in out of sync state.
              // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic
              wrapper.isStale = false;
              wrapper.isClearFlagWithoutUpdatingMatter = false;
            } else {
              // wrapper.isStale = wrapper.matterParticipant.contact.isStaleSolicitor(result);
              // wrapper.isClearFlagWithoutUpdatingMatter
              //     = wrapper.matterParticipant.contact.isSolicitorClearFlagWithoutUpdatingMatter(result);
              wrapper.isStale = wrapper.retrieveStaleStatus(result);
              wrapper.isClearFlagWithoutUpdatingMatter
                = wrapper.refreshClearFlagStatus(result);
            }
            this.tabService.updateIsLockedElsewhereStatus(wrapper, result);

            wrapper.canBeUpdatedFromSourceContact
              = wrapper.isStale && !!wrapper.matterParticipant.contact.sourceContactId;
            wrapper.lastUpdatedOn = result.lastUpdatedOnMsg;
            wrapper.updateSourceContactFlagsForSnapshot(result);
            wrapper.expanded = expandedTarget;
          }
        }, (error: ApplicationError) => {
          if (error.errorCode == 'app.ContactNotFound') {
            wrapper.matterParticipant.contact.sourceContactId = null;
            wrapper.expanded = expandedTarget;
            return;
          }
        });
      }
    } else {
      wrapper.expanded = expandedTarget;
    }
  }

  //Todo refactor
  public isContactAlreadyLocked(contact: Contact) {
    for (var i = 0; i < this.tabService.openTabs.length; i++) {
      var tab: Tab = this.tabService.openTabs[ i ];
      if (tab.isMatter()) {
        let matterParticipants = (tab as MatterTab).matter.matterParticipants;
        for (var j = 0; j < matterParticipants.length; j++) {
          var participant = matterParticipants[ j ];
          if (participant.contact.sourceContactId == contact.sourceContactId && participant.sourceContactLockAcquired) {
            return true;
          }
        }
      } else if (tab.isContact()) {
        //If open, the contact would be
        // open for editing (locked acquired by this user) if we get here. If contact were locked by somebody else, we
        // would not get an OK for locking from a matter contact edit. Border case: the contact is open in read only
        // (previous use locked it) and the lock is released before user edits in another matter but contact tab is not
        // refreshed
        if ((tab as ContactTab).contact.id == contact.sourceContactId) {
          return true;
        }
      }
    }

    return false;

    /*!!this.tabsService.openTabs.find(tab =>
         tab.isMatter() && !!((tab as MatterTab).matter.matterParticipants.find(participant =>
         participant.contact.sourceContactId == contact.sourceContactId &&
         participant.sourceContactLockAcquired))
         ||
         tab.isContact() && (tab as ContactTab).contact.id == contact.sourceContactId //If open, the contact would be
         // open for editing (locked acquired by this user) if we get here. If contact were locked by somebody else, we
         // would not get an OK for locking from a matter contact edit. Border case: the contact is open in read only
         // (previous use locked it) and the lock is released before user edits in another matter but contact tab is not
         // refreshed
         );*/
  }

  selectTab(selectedTab: string, selectedOtherParty: MatterParticipantWrapper): void {
    selectedOtherParty.selectedTab = selectedTab || defaultTab;

    setTimeout(function () {
      jQuery('.mat-modal-dialog').find('input[type=text],dp-checkbox label,textarea,select').filter(':visible:first')
      .focus();
    }, 500);
  }

  onClickFlaWarningIcon(selectedOtherParty: MatterParticipantWrapper): void {
    this.setOtherPartyExpanded(selectedOtherParty, true);
    selectedOtherParty.spouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(selectedOtherParty.matterParticipant, this.matter.otherSideClientType, this.matter);
    this.selectTab('Family-Law-Act', selectedOtherParty);
  }

  // select the contact form list of contacts
  dataSelectedOtherParty(index: number, sourceParticipantWrapper?: MatterParticipantWrapper): void {
    this.enableSave();
    this.otherPartiesChanged = true;
    //The selected client (from the drop-down list) would be set here through ng-model attr on the autocomplete control
    let activeOtherPartyWrapper: MatterParticipantWrapper = this.selectedOtherParties[ index ];
    if (activeOtherPartyWrapper.dataModel.id === undefined) {
      // if(activeOtherParty.dataModel.contactName && activeOtherParty.dataModel.contactName.lastName.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
      if (activeOtherPartyWrapper.dataModel.noResultFoundFlag) {
        activeOtherPartyWrapper.dataModel = null;
      } else {
        // Now take care for Add new client
        const newParticipant = this.matter.createMatterParticipant(this.matter.otherSideClientType);
        const outsideSpouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(newParticipant, this.matter.otherSideClientType, this.matter);
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            matterParticipant: newParticipant,
            spouseNameOptions: outsideSpouseNameOptions,
            flaStatements: this.flaStatements,
            purchaserId: newParticipant.matterParticipantId,
            contactType: 'CLIENT',
            contactName: activeOtherPartyWrapper.dataModel.displayName,
            isOtherParty: true,
            cacheDocumentProfile: this.documentProfileCache.cachedDocumentProfile,
            matterParticipantRole: this.matter.otherSideClientType,
            selectCreateNewClient: this.isCreateNewClientSelected(),
            isVisibleWithoutSourceContact: true
            // defaultOtherContactInfo: this.defaultOtherContactInfo,
            // typedPurchaserName: selectVendorDataModel.typedName,
            // fieldPrefix: "matter.vendor_solicitor",
            // fieldIdentifier: index + 1
          },
          onFulfillment: (result) => {
            if (result) {
              if (result.action === 'save') {//if contact modal is closed after save
                this.matter.dirty = true;
                let newMatterParticipant: MatterParticipant
                  = this.buildOtherSideMatterParticipant(result.contact, newParticipant,
                  result.createdNewClient,
                  this.matter.otherSideClientType, index);
                this.updateOtherPartyWrapper(activeOtherPartyWrapper, newMatterParticipant);
                this.selectTab(defaultTab, activeOtherPartyWrapper);
                this.updateOtherPartyFields(activeOtherPartyWrapper, index);
                this.propogateImportChangesAction();
              } else {
                activeOtherPartyWrapper.dataModel = null;
              }
            }
          },
          onRejection: (reason: any) => {
            activeOtherPartyWrapper.dataModel = null;
          },
          fullScreen: true,

        });
      }
    } else {
      activeOtherPartyWrapper.editMode = false;
      this.contactQueryService.getContactForMatter(activeOtherPartyWrapper.dataModel.id)
      .subscribe((contact: Contact) => {
        this.createOtherPartyParticipant(contact, activeOtherPartyWrapper, index);
        if (this.matter.isFlaApplicableForEstate(activeOtherPartyWrapper.matterParticipant)) {
          activeOtherPartyWrapper.matterParticipant.setGendeEstateOtherFlaDefaultValue();
        }
        if (!sourceParticipantWrapper && (this.matter.isMatterProvinceONorABorMBorSKorNSorNB || this.matter.isCustomMatter())) {
          this.openSuggestedSpouseModal(activeOtherPartyWrapper);
        }
        if (sourceParticipantWrapper) {
          activeOtherPartyWrapper.primary = sourceParticipantWrapper.primary;
          activeOtherPartyWrapper.matterParticipant.primary = sourceParticipantWrapper.primary;
          this.deleteOtherParty(sourceParticipantWrapper);
        }
      });
    }
  }

  openSuggestedSpouseModal(activePurchaserWrapper: MatterParticipantWrapper) {
    if (activePurchaserWrapper
      && activePurchaserWrapper.matterParticipant
      && activePurchaserWrapper.matterParticipant.contact
      && activePurchaserWrapper.matterParticipant.contact.spouses
      && activePurchaserWrapper.matterParticipant.contact.spouses.length > 0 && this.matter) {
      this.dialogService.matDialogContent({
        content: SuggestedSpouseModal,
        context: {
          spouses: activePurchaserWrapper.matterParticipant.contact.spouses,
          contactLabel: this.matter.otherPartyTitle,
          provinceCode: this.matter.provinceCode,
          isOtherSide: true,
          isCustomMatter: this.matter.isCustomMatter(),
          isOpportunityMatter: this.matter.isOpportunityMatter(),
          disableAddClient: this.selectedOtherParties.length >= this.appConfig.getMaxNumberOfPurchasers()

        },
        showModalBehind:true,
        onFulfillment: async (result) => {
          if (result && result.selectedSpouse && result.selectedSpouse.spouse) {
            let spouseSourceContact: Contact = await this.contactQueryService.getContactForMatter(result.selectedSpouse.spouse.id).toPromise();
            if (result.selectedSpouse.familyLawActStatementType) {
              activePurchaserWrapper.addSuggestedSpouseAsFlaConsentedSpouse(spouseSourceContact, result.selectedSpouse.familyLawActStatementType, this.matter);
            } else {
              this.addSuggestedSpouseAsClient(activePurchaserWrapper, spouseSourceContact);
            }
          }
        },

      });
    }
  }

  propogateImportChangesAction(excludeCapacityCheckWarning?: boolean) {
    '';
    this.updateOtherPartyFlaErrorMessage();
    // For copy link matter,  it should not add other side client Tenure waring message into this tab and should add the link active tab.
    if (!excludeCapacityCheckWarning) {
      this.checkCapacityWarningMessage(this.matter.otherPartiesCapacity);
    }
    this.matter.reCalculateOtherPartyReLine();
  }

  async createOtherPartyParticipant(contact: Contact, activeOtherParty: MatterParticipantWrapper, index: number, excludeCapacityCheckWarning?: boolean, keepShutterClosed?: boolean): Promise<void> {
    // activeVendor.dataModel = {fullName : contact.genericFullName};
    activeOtherParty.matterParticipant = this.matter.addMatterParticipant(new Contact(contact), true, this.matter.otherSideClientType, null, null, index);
    activeOtherParty.showLabel = true;
    //Todo refactor because sourceContact was save in matter
    activeOtherParty.updateSourceContactFlagsForSnapshot(contact);

    this.selectTab(defaultTab, activeOtherParty);
    if (contact.contactAssociations) {
      await this.matterParticipantService.createMatterParticipantAssociatedContactForClient(contact, activeOtherParty.matterParticipant, this.matter).then(() => {
        if (!keepShutterClosed && !this.showWizardFields) {
          this.setOtherPartyExpanded(activeOtherParty, true);
        }
      });
    } else if (!keepShutterClosed && !this.showWizardFields) { //This flag prevents locking the contact when we do mass update or copy matters
      this.setOtherPartyExpanded(activeOtherParty, true);
    }
    this.updateOtherPartyFields(activeOtherParty, index);
    this.propogateImportChangesAction(excludeCapacityCheckWarning);
  }

  updateOtherPartyFields(activeOtherParty: MatterParticipantWrapper, index: number): void {
    //  create check for primary contact on 0th index
    if (index === 0) {
      activeOtherParty.primary = true;
    }
  }

  // Add a new other Party in the list
  addNewOtherParty(index?: number): void {
    let length: number = this.selectedOtherParties.length;
    let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    matterParticipantWrapper.editMode = true;
    //matterParticipantWrapper.primary = (length === 0);
    matterParticipantWrapper.selectedTab = defaultTab;

    // Dummy MatterParticipant and Contact data to avoid crash of hidden snapshot page
    // matterParticipantWrapper.matterParticipant = new MatterParticipant();
    // matterParticipantWrapper.matterParticipant.contact = new Contact();
    matterParticipantWrapper.showLabel = false;
    if (index >= 0) {
      this.selectedOtherParties.splice(index, 0, matterParticipantWrapper);
    } else {
      this.selectedOtherParties.push(matterParticipantWrapper);
    }

  }

  get placeHolderMsgForOtherPartySearch(): string {
    if (this.matter.isSale) {
      return 'Search by Name or Add new';
    }
    if (this.matter.isPurchase) {
      return 'Search by Name or Add new';
    }
    //should not come here, Mortgage Matter does not have this func
    return 'Start typing to search ...';
  }

  getEditedOtherParties(): MatterParticipantWrapper {
    let editedOtherParties: MatterParticipantWrapper = this.selectedOtherParties.find((otherParty: MatterParticipantWrapper) => {
      return otherParty.editMode;
    });
    return editedOtherParties;
  }

  deleteOtherPartyWithConfirmation(otherParty: MatterParticipantWrapper): void {
    let partyTitle = this.isPurchaseMatter ? provinceBasedFieldLabels.get('matter.title.vendor', this.matter.provinceCode).toLowerCase() : provinceBasedFieldLabels.get('matter.title.purchaser', this.matter.provinceCode).toLowerCase();
    if (otherParty?.matterParticipant?.contact) {
      const contact = otherParty.matterParticipant.contact;
      if (this.matter?.isCustomMatter() || (contact.contactCategory === 'OTHER_CONTACT' && contact.partyRole === 'CLIENT')) {
        partyTitle = 'Other Client';
      }
    }
    const confirmDeletionMsg = `Do you wish to delete this ${ partyTitle }?`;

    this.dialogService.confirm('Confirmation', confirmDeletionMsg, false, 'Delete')
    .subscribe(res => {
      if (res == true) {
        if (this.matter && otherParty && otherParty.matterParticipant && this.documentProductionService && this.contactQueryService) {
          this.documentProductionService.tryToRevokePackage(this.matter, otherParty.matterParticipant, this.contactQueryService);
        }
        this.deleteOtherParty(otherParty);
      } else {
        this.checkCapacityWarningMessage(this.matter.otherPartiesCapacity);
      }
    });
  }

  deleteOtherParty(otherParty: MatterParticipantWrapper): void {
    if (this.documentProductionService && this.contactQueryService) {
      this.documentProductionService.tryToRevokePackage(this.matter, otherParty.matterParticipant, this.contactQueryService);
    }
    this.enableSave();
    this.deleteOtherPartyParticipant(otherParty);
    let selectedOtherPartyIdx: number =
      this.selectedOtherParties.findIndex(selectedOtherParty => selectedOtherParty === otherParty);
    let fieldKeyGender = 'matter.otherParty_solicitor.genderChangeProhibited' + (selectedOtherPartyIdx + 1);
    this.errorService.removeDpFieldError(fieldKeyGender);
    if (otherParty && otherParty.matterParticipant) {
      //Clean all FLA error message
      this.matterParticipantService.removeFlaSpouseNotAllowedErr(otherParty.matterParticipant.matterParticipantId, false, this.matter.provinceCode);
      this.matterParticipantService.removeFlaSpouseNameRequiredErr(otherParty.matterParticipant.matterParticipantId, false, this.matter.provinceCode);
    }

    const fieldId = otherParty.matterParticipant && otherParty.matterParticipant.matterParticipantId != null ? 'gender_' + otherParty.matterParticipant.matterParticipantId : 'gender';
    let fieldKeyGenderWithoutIndex: string = 'matter.otherParty.genderChangeProhibited';
    this.errorService.removeDpFieldError(fieldKeyGenderWithoutIndex, fieldId);
    this.updateOtherPartyFlaErrorMessage();
    this.checkCapacityWarningMessage(this.matter.otherPartiesCapacity);
  }

  deleteOtherPartyParticipant(otherParty: MatterParticipantWrapper, keepContactLocked?: boolean): void {
    this.otherPartiesChanged = true;
    let currentSpouse: MatterParticipant;
    // we generate ids on the client side. If there is matterParticipant, it should have a matterParticipantId
    if (otherParty && otherParty.matterParticipant) {
      currentSpouse = this.matter.getSpouseParticipant(otherParty.matterParticipant.matterParticipantId);
    }
    if (currentSpouse) {
      currentSpouse.deleteSpouse();
    }
    if (otherParty.matterParticipant) {
      let consentingSpouseParticipant = otherParty.matterParticipant.getMatterConsentingSpouseParticipantByParentParticipant(this.matter);
      if (consentingSpouseParticipant) {
        // For the link update, we shouldn't change lock status
        if (consentingSpouseParticipant.contact && consentingSpouseParticipant.contact.sourceContactId && !keepContactLocked) {
          this.contactService.unlockContact(consentingSpouseParticipant.contact.sourceContactId).subscribe((res) => {
            this.logger.info('consentingSpouseParticipant has been unlocked now');
          });
        }
        this.matterParticipantService.removeConsentingSpouseSigningOfficerCleanup(this.matter, consentingSpouseParticipant, keepContactLocked);
        this.matter.deleteMatterParticipant(consentingSpouseParticipant);
      }
    }
    this.matter.matterParticipants.filter(selectedSigningOfficer => selectedSigningOfficer.parentParticipantId === otherParty.matterParticipant.matterParticipantId).forEach(matterParticipant => {
      if (matterParticipant && matterParticipant.contact && matterParticipant.contact.sourceContactId && !keepContactLocked) {
        this.contactService.unlockContact(matterParticipant.contact.sourceContactId).subscribe((res) => {
          this.logger.info('other party has been unlocked now');
        });
      }
    });
    this.matter.matterParticipants.filter(selectedSigningOfficer => selectedSigningOfficer.parentParticipantId === otherParty.matterParticipant.matterParticipantId).forEach(matterParticipant => {
      (<any>this.matter.matterParticipants).remove(matterParticipant);
    });

    this.matter.deleteMatterParticipant(otherParty.matterParticipant);
    if (otherParty.matterParticipant && otherParty.matterParticipant.contact && otherParty.matterParticipant.contact.sourceContactId && !keepContactLocked) {
      this.contactService.unlockContact(otherParty.matterParticipant.contact.sourceContactId).subscribe((res) => {
        this.logger.info('other party has been unlocked now');
      });
    }

    let selectedOtherPartyIdx: number =
      this.selectedOtherParties.findIndex(selectedOtherParty => selectedOtherParty === otherParty);
    if (selectedOtherPartyIdx >= 0) {
      this.selectedOtherParties.splice(selectedOtherPartyIdx, 1);
    }

    if (this.selectedOtherParties.length === 0) {
      this.initOtherParty();
      this.matter.resetOtherPartyContactInfo();
    } else if (!this.primaryMPWrapperOtherParty && this.selectedOtherParties.length > 0) {
      this.setAsPrimaryOtherParty(this.selectedOtherParties[ 0 ]);
    }

    this.matter.reCalculateOtherPartyReLine();

  }

  closeAllOpenedOtherPartiesShutters(): void {
    if (this.selectedOtherParties) {
      this.selectedOtherParties.forEach((wrapper: MatterParticipantWrapper) => {
        wrapper.expanded = false;
        if (this.hasSourceContact(wrapper)) {
          this.matterParticipantService.unlockParticipantOnTabClose(wrapper, this.matter);
        }
      });
    }
  }

  public reorderOtherParties(selectedOfferor: MatterParticipantWrapper, moveUp: boolean): void {
    if (this.selectedOtherParties && this.selectedOtherParties.length > 1) {
      // Closing the shutter box if opened
      this.closeAllOpenedOtherPartiesShutters();

      let selectedOfferorIndex: number = _.findIndex(this.selectedOtherParties, function (p: MatterParticipantWrapper) {
        return p === selectedOfferor;
      });

      let otherOfferorIndex: number;

      // Calculating the index of other offeror in relative to selected index based on moving direction
      if (moveUp) {
        otherOfferorIndex = selectedOfferorIndex - 1;
      } else {
        otherOfferorIndex = selectedOfferorIndex + 1;
      }

      if (otherOfferorIndex >= 0 && otherOfferorIndex < this.selectedOtherParties.length) {
        // Swapping selected and other offerors in the array that is displayed on UI
        this.selectedOtherParties[ selectedOfferorIndex ] = this.selectedOtherParties[ otherOfferorIndex ];
        this.selectedOtherParties[ otherOfferorIndex ] = selectedOfferor;
        // Enabling global save button
        this.enableSave();
      } else {
        console.error('The index of other offeror is out of bounds.', otherOfferorIndex);
      }
    }
  }

  moveUpOtherParty(selectedOtherParty: MatterParticipantWrapper): void {
    this.closeAllOpenedOtherPartiesShutters();
    this.matter.reorderParticipant(selectedOtherParty.matterParticipant, true);
    // this.creatOtherPartyListByMatterParticipant(this.matter.otherSideClients);
    this.reorderOtherParties(selectedOtherParty, true);
    this.matter.reCalculateOtherPartyReLine();
    this.enableSave();
  }

  moveDownOtherParty(selectedOtherParty: MatterParticipantWrapper): void {
    this.closeAllOpenedOtherPartiesShutters();
    this.matter.reorderParticipant(selectedOtherParty.matterParticipant, false);
    // this.creatOtherPartyListByMatterParticipant(this.matter.otherSideClients);
    this.reorderOtherParties(selectedOtherParty, false);
    this.matter.reCalculateOtherPartyReLine();
    this.enableSave();
  }

  survivalAfterClosingVisible() {
    return this.matter.uffiWarrantyCode === 'LIMITED_TIME' && this.matter.isPurchase;
  }

  handleDirectDepositOption(ddOption: string) {
    if (ddOption == 'YES') {
      this.matter.directDepositInstructionOtherParty.bankAccount = new BankAccount();
    } else {
      this.errorService.removeDpFieldError(`directDeposit.bankNumber`);
      this.errorService.removeDpFieldError(`directDeposit.transitNumber`);
    }

    this.enableSave();
  }

  public loadFlaStatements(): void {
    if (!this.matter.isMatterProvinceBC) {
      this.purchaserService.getFlaStatements(this.matter.provinceCode, this.matter.matterType).subscribe((flaStatements: FlaData) => {
        this.flaStatements = flaStatements;
      });
    }
  }

  public getGenderValue(v) {
    switch (v) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      default :
        return '';

    }
  }

  getDeceasedString(otherParty) {
    return (otherParty && otherParty.id) ? otherParty.getDeceasedString() : '';
  }

  public getNameValue(otherParty: Contact) {
    let value: string = '';
    switch (otherParty && otherParty.gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
      case 'FEMALEPOA':
      case 'MALEPOA':
        value = otherParty.fullName;
        break;

      case 'ESTATE':
        value = otherParty.fullName;
        break;

      case 'CORPORATION':
      case 'OTHERENTITY':
        value = otherParty.organizationName;
        break;

      default :
        break;

    }
    if (value && value.length > 35) {
      value = value.substring(0, 35) + '...';
    }
    return value;
  }

  public getAddressValue(otherParty: Contact) {
    if (otherParty && otherParty.mailingAddress) {
      return otherParty.mailingAddress.addressTextWithoutCountryAndPostalCode;
    } else {
      return '';
    }
  }

  searchSolicitor(event): void {
    if (!this.showSolicitorDropDownArrow) {
      let entered: string = event.query;
      this.searchTermSolicitor.next(entered);
    } else {
      this.onSolicitorChange(event);
    }
  }

  searchLawFirm(event): void {
    let entered: string = event.query;
    this.searchTermLawFirm.next(entered);
  }

  public enableAutoCompleteTabTraverse(autoComplete: AutoComplete): void {
    if (autoComplete) {
      setTimeout(() => {
        if (autoComplete.el && autoComplete.el.nativeElement) {
          const btn = autoComplete.el.nativeElement.querySelector('button');
          if (btn) {
            // console.log("enableAutoCompleteTabTraverse: btn:", btn.tabIndex);
            btn.tabIndex = -1;
          }
        }
      }, 0);
    }
  }

  /**
   * populate solicitor drop down list and law clerk drop down list
   * @param sourceContact
   */
  populateComboBox(sourceContact: Contact): void {
    if (sourceContact) {
      if (sourceContact.id) {
        this.contactService.getSolicitorsForLawFirm(sourceContact.id, true).subscribe(
          (data: Contact[]) => {
            this.showSolicitorDropDownArrow = Array.isArray(data) && data.length > 0 ? true : false;
            // data can NOT set to this.solicitor.
            // It will show two pick lists. One is searching list, the other is drop down list if do it.
            // this.solicitors = data;

            this.enableAutoCompleteTabTraverse(this.solicitor);
          }
        );
      }

      let searchLawFirmId: number;
      if (sourceContact.isPrivateExcludeProxy) {
        // There is no law clerk for private law firm
      } else {
        //if there is no sourceContactId then it means user has selected their own firm therefore fetching contactId
        if (sourceContact.proxyForGlobal) {
          searchLawFirmId = sourceContact.sourceContactId;
        } else {
          searchLawFirmId = sourceContact.id;
        }
      }
      if (searchLawFirmId) {
        this.contactService.getOtherSideLawClerksForLawFirm(searchLawFirmId, true).subscribe(
          (data: Contact[]) => {
            if (Array.isArray(data) && data.length > 0) {
              this.lawClerks = data;
              this.showLawClerksDropDownArrow = true;
              this.enableAutoCompleteTabTraverse(this.lawClerkName);
            } else {
              this.lawClerks = null;
              this.showLawClerksDropDownArrow = false;

            }

          }
        );
      }
    }
  }

  /**
   * populate solicitor drop down list
   * @param lawFirmId
   */
  populateSolicitor(lawFirmId: number, removeAddNewRecord?: boolean): void {
    if (lawFirmId) {
      this.contactService.getSolicitorsForLawFirm(lawFirmId, true).subscribe(
        (data: any) => {
          const resetFlag = !this.solicitors;
          this.solicitors = data;
          //It will add a new record when there is dynamic drop down list
          // But If it is my own account, Don't add new solicitor
          if (!removeAddNewRecord
            && typeof this.selectedSolicitor.dataModel === 'string' && this.selectedSolicitor.dataModel.trim()) {
            const addNewRecordData: any = {
              displayName: Constants.ADD_NEW_RECORD + `"${ this.selectedSolicitor.dataModel.trim() }"`
            };
            this.solicitors.unshift(addNewRecordData);

          }
          // Solicitor implements the following two search functions
          // 1. Search solicitor for any law firms (the law firm wasn't selected).
          //    It uses the "completeMethod" of p-autoComplete to open dialog. This works well
          // 2. Search solicitor under the drop down list of the selected law firm (the law firm was selected)
          //    By changing "suggestions"(the dropdown list) of p-autoComplete to open dialog. In this case,
          //    however, there is an issue. When the dropdown list (this.solicitors) is changed from undefined to non-null,
          //    the dialog could not be opened.
          //    Fix: Call the "show" of p-autoComplete to open the dialog
          if (this.solicitor && typeof this.solicitor.show === 'function') {
            this.solicitor.show();
          }

          // In IE, clicking item from the dropdown list would generate empty selection while the existing
          // value of autocomplete was empty. This issue happened when Placeholder and Dropdown Arrow are both
          // enabled in autocomplete in IE.
          // The root cause is till unknown, and here is the hack which change the existing value to a space when
          // it is empty.
          if (this.selectedSolicitor.dataModel === '') {
            this.selectedSolicitor.dataModel = ' ';
          }

        }
      );
    }

  }

  /**
   * It will open a modal window to create a solicitor when it have the following ALL conditions
   *  1. A law firm exists
   *  2. No solicitor is selected
   *  3. There is at least 1 subcontact(solicitor). If there is no any subcontact,
   *     it can do search select new one and update law firm or select "Add new" item to create a new solicitor with the existing law firm
   * @param wrapper
   */
  onLeavingSolicitorOmniBarWithoutFreeInput(wrapper: MatterParticipantWrapper) {
    setTimeout(() => {
      if (wrapper && wrapper.dataModel && typeof wrapper.dataModel === 'string') {
        wrapper.dataModel = null;
        this.matter.otherPartyContactInfo.solicitorId = null;
        this.matter.otherPartyContactInfo.solicitorName = null;
      }
    }, 200);
  }

  onLeavingLawFirmOmniBarWithoutFreeInput(wrapper: MatterParticipantWrapper) {
    setTimeout(() => {
      if (wrapper && wrapper.dataModel && typeof wrapper.dataModel === 'string') {
        wrapper.dataModel = null;
        this.matter.otherPartyContactInfo.firmId = null;
        this.matter.otherPartyContactInfo.firmName = null;
      }
    }, 200);
  }

  lawFirmChange(event) {
    this.matter.otherPartyContactInfo.firmName = event.target.value;
    this.matter.otherPartyContactInfo.firmId = null;
  }

  recordLawFirmKeydownCode(event): void {
    this.lawFirmKeyDownCode = event.keyCode;
  }

  dataSelectedLawFirmTimeout(): void {
    setTimeout(() => {
      this.dataSelectedLawFirm();
    }, 0);
  }

  onSelectedLawFirmChange(event) {
    if (typeof event === 'string') {
      this.selectedLawFirmInputValue = event;
    }
  }

  // This is a workaround because of p-autoComplete's special behaviour which was found in DPPMP-9185.
  // While,
  //   1) an item was selected from dropdown, and the input box was displaying the name of the data item
  //   2) made some changes in the input box and left a non-empty string
  //   3) pressed 'Tab' key
  //   4) Displayed the LAST selected record
  // p-autoComplete's reaction:
  //   1) triggered 'ngModelChange' event with current selected data item
  //   2) triggered 'onSelect' event
  //   3) forwarded 'keydown' event of 'Tab' key to the caller, this component
  // The issue caused:
  //   Since 'ngModelChange' was triggered with current select data item and 'onSelect' was triggered as well,
  //   the effect is equivalent to mouse clicked the data item from the dropdown, and user's input change is lost.
  //   In this case, this component has no knowledge that these 'ngModelChange' and 'onSelect' events was triggered
  //   by mouse clicking or 'Tab' key pressing.
  // Workaround:
  //   1) record any user key input to a temp variable
  //   2) delay 'onSelect' handling by put it into a timer, so the handling will be after 'keydown' event. If the latest
  //      'keydown' was 'Tab', restore user's input from the temp variable.
  // Note:
  //   This issue is caused by p-autoComplete's behaviour, and every code using it is affected.
  dataSelectedLawFirm(): void {
    if (this.lawFirmKeyDownCode === 9) {
      this.selectedLawFirm.dataModel = null;
      this.matter.otherPartyContactInfo.firmName = this.selectedLawFirm.dataModel;
      this.matter.otherPartyContactInfo.firmId = null;
    } else {
      if (this.selectedLawFirm.dataModel.id === undefined) {
        // Now take care for adding ne law firm
        if (this.selectedLawFirm.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1) {
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: {
              matter: this.matter,
              isLawFirm: true,
              contactType: 'LAW_FIRM',
              contactName: this.selectedLawFirm.dataModel.displayName,
              matterParticipantRole: this.matter.otherPartyMPRoleLawFirm

            },
            onFulfillment: (result) => {
              if (result) {
                if (result.action === 'save') {
                  this.enableSave();
                  let snapshotContact: Contact;
                  //According to backend requirement, UI set law firm snapshot subContact as []
                  if (result.contact) {
                    snapshotContact = new Contact(result.contact);
                    snapshotContact.subContacts = [];
                  }
                  this.matter.addMatterParticipant(snapshotContact,
                    true,
                    this.matter.otherPartyMPRoleLawFirm);
                  this.buildLawFirmStructure(false, result.contact);
                  this.updatePayableToValue(result.contact && result.contact.legalFirmName);

                  this.selectedLawFirm.showLabel = true;
                  this.selectedLawFirm.createdOrSelected = 'Created';
                }
                this.selectedLawFirm.dataModel = null;
              }
            },
            fullScreen: false,

          });

        } else {
          this.selectedLawFirm.dataModel = null;
          this.matter.otherPartyContactInfo.firmName = null;
          this.matter.otherPartyContactInfo.firmId = null;
        }

      } else {
        this.contactQueryService.getContactForMatter(this.selectedLawFirm.dataModel.id).subscribe((source: Contact) => {
          if (source) {
            //According to backend requirement, UI set law firm snapshot subContact as []
            const snapshotContact: Contact = new Contact(source);
            snapshotContact.subContacts = [];
            this.matter.addMatterParticipant(snapshotContact,
              true,
              this.matter.otherPartyMPRoleLawFirm);
            this.buildLawFirmStructure(true, source);

            this.updatePayableToValue(source && source.legalFirmName);

          }
        });

      }
    }
    this.enableSave();
  }

  // ToDo use this.matter.getStatementOfAdjustmentPayableTo() if possible, to keep payableTo format consistent
  updatePayableToValue(legalFirmName?: string) {
    if (this.matter.matterType == 'PURCHASE') { // only for PURCHASE matters 'payableTo' needs to be changed, for SALE matters docProfile is used
      if (this.matter.statementOfAdjustmentPayable) {
        this.matter.statementOfAdjustmentPayable.payableTo = legalFirmName ? legalFirmName + ', in trust' : null;
      }
    }
  }

  updateOtherPartyContactInfoWithLawFirm(snapShotLawFirmContact: Contact) {
    // Set Solicitor's Address is readOnly
    this.disableAddress = true;
    // Populating the fields
    const otherPartyContactInfo: ContactInfo = this.matter.otherPartyContactInfo;
    otherPartyContactInfo.faxPhone = this.selectedLawFirm.dataModel.faxPhone;
    otherPartyContactInfo.workPhone = this.selectedLawFirm.dataModel.workPhone;
    otherPartyContactInfo.firmId = this.selectedLawFirm.dataModel.id;
    otherPartyContactInfo.firmName = snapShotLawFirmContact && snapShotLawFirmContact.legalFirmName;
    otherPartyContactInfo.mailingAddress = this.selectedLawFirm.dataModel.mailingAddress;
    // Back-end requirement
    otherPartyContactInfo.mailingAddress.id = null;
    otherPartyContactInfo.barristerSolicitor = this.selectedLawFirm.dataModel.barristerSolicitor;
    this.disableBarristerSolicitor = true;
  }

  onSolicitorChange(event): void {
    this.matter.otherPartyContactInfo.solicitorId = null;

    if (event.displayName) {
      let str = event.displayName;
      str = str.replace(Constants.ADD_NEW_RECORD, '');
      str = str.replace(/"/g, '');
      this.matter.otherPartyContactInfo.solicitorName = str;
    } else {
      if (typeof event !== 'object') {
        this.matter.otherPartyContactInfo.solicitorName = event;
      }
    }

    if (typeof this.selectedSolicitor.dataModel === 'string' && this.selectedSolicitor.dataModel.trim()) {
      this.handleDropDownClickSolicitorPickList(event);
    }
  }

  onLawClerkChange(event): void {
    //The ID should be null when manual input.
    this.matter.otherPartyContactInfo.lawClerkId = null;
    this.matter.otherPartyContactInfo.lawClerkName = event;
  }

  createSolicitorWithExistingLawFirm(matterParticipant: MatterParticipant, displayName: string) {
    if (matterParticipant) {
      if (matterParticipant.contact && matterParticipant.contact.sourceContactId) {
        // Get this exising law firm source contact
        this.contactQueryService.getContactForMatter(matterParticipant.contact.sourceContactId, matterParticipant.contact.displayName, true).subscribe((sourceContact: Contact) => {
          if (!Array.isArray(sourceContact.subContacts)) {
            sourceContact.subContacts = [];
          }
          let solicitorContact: Contact = sourceContact.createSolicitorContact(this.documentProfileCache, this.matter && this.matter.provinceCode);
          solicitorContact.contactName.setLastNameFromDisplayName(displayName);
          solicitorContact.legalFirmId = matterParticipant.contact.sourceContactId;
          solicitorContact.organizationId = matterParticipant.contact.sourceContactId;
          sourceContact.subContacts.push(solicitorContact);
          const context = {
            matter: this.matter,
            contact: sourceContact,
            isOnlySolicitor: true,
            isLawFirm: false,
            // contactKey        : 'LAWYER',
            contactType: 'LAW_FIRM',
            matterParticipantRole: this.matter.otherPartyMPRoleLawFirm,
            contactAddressType: this.contactAddressType,
            solicitorAddressDd: this.solicitorAddressDd,
            fieldPrefix: 'matter.otherParty'
          };
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: context,
            onFulfillment: (result) => {
              if (result) {
                if (result.action === 'save') {
                  this.enableSave();
                  if (result.contact && Array.isArray(result.contact.subContacts) && result.contact.subContacts.length > 0) {

                    this.createSolicitorStructure(result.contact.subContacts[ result.contact.subContacts.length - 1 ].id);

                    this.selectedSolicitor.showLabel = true;
                    this.selectedSolicitor.createdOrSelected = 'Created';
                  }
                } else {
                  sourceContact.subContacts.pop();
                }
              }
              this.selectedSolicitor.dataModel = null;

            },
            fullScreen: false,

          });

        });
      }
    }
  }

  createSolicitorWithoutExistingLawFirm() {
    const context = {
      matter: this.matter,
      isOnlySolicitor: false,
      isLawFirm: false,
      contactName: this.selectedSolicitor.dataModel.displayName,
      contactType: 'LAW_FIRM',
      matterParticipantRole: this.matter.otherPartyMPRoleLawFirm,
      contactAddressType: this.contactAddressType,
      solicitorAddressDd: [],
      fieldPrefix: this.matter.isMatterProvinceBC ? 'matter.otherParty.bc' : 'matter.otherParty'

    };
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: context,
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {
            let snapshotContact: Contact;
            this.enableSave();
            // Add law firm info
            //According to backend requirement, UI set law firm snapshot subContact as []
            if (result.contact) {
              snapshotContact = new Contact(result.contact);
              snapshotContact.subContacts = [];
            }
            this.matter.addMatterParticipant(snapshotContact, true, this.matter.otherPartyMPRoleLawFirm);
            this.buildLawFirmStructure(false, result.contact);
            this.selectedLawFirm.createdOrSelected = 'Created';
            // Add solicitor info
            if (result.contact && Array.isArray(result.contact.subContacts) && result.contact.subContacts.length > 0) {
              this.createSolicitorStructure(result.contact.subContacts[ result.contact.subContacts.length - 1 ].id);

              this.selectedSolicitor.createdOrSelected = 'Created';
            }
          }
          this.selectedSolicitor.dataModel = null;
        }
      },
      fullScreen: true,

    });

  }

  dataSelectedSolicitor(): void {
    // If there is an existing solicitor in MatterParticipant array of matter, it needs to remove it.
    let solicitorMP: MatterParticipant = this.matter.otherPartySolicitor;
    if (solicitorMP) {
      this.matter.removeMatterParticipant(solicitorMP);
      if (solicitorMP.contact) {
        this.contactService.unlockContact(solicitorMP.contact.sourceContactId).subscribe((res) => {
          this.logger.info('solicitor has been unlocked now');
        });
      }
    }
    if (this.selectedSolicitor.dataModel.id === undefined) {
      // Now take care for adding a new solicitor
      if (this.selectedSolicitor.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1) {
        // According to if there is an existing solicitor, it will call different methord
        const otherPartyLawFirm = this.matter.otherPartyLawFirm;
        if (otherPartyLawFirm) {
          this.createSolicitorWithExistingLawFirm(otherPartyLawFirm, this.selectedSolicitor.dataModel.displayName);
        } else {
          this.createSolicitorWithoutExistingLawFirm();
        }
      } else if (this.selectedSolicitor.dataModel.displayName.indexOf('No records available') === 0) {
        this.selectedSolicitor.dataModel = null;
        this.matter.otherPartyContactInfo.solicitorId = null;
        this.matter.otherPartyContactInfo.solicitorName = null;
      } else {
        this.selectExistingSolicitor();
      }

    } else {
      this.selectExistingSolicitor();
    }
    this.enableSave();

  }

  selectExistingSolicitor() {
    try {
      this.lockScreenService.lockForUpdate = true;
      const solicitorParticipant: MatterParticipant = this.matter.otherPartySolicitor;

      //Clean this.selectedSolicitor with new data Model
      //Remove old solicitorParticipant
      if (solicitorParticipant) {
        if (solicitorParticipant.contact) {
          this.contactService.unlockContact(solicitorParticipant.contact.sourceContactId).subscribe((res) => {
            this.logger.info('solicitor has been unlocked now');
          });
        }
        this.matter.removeMatterParticipant(solicitorParticipant);
        this.errorService.removeDpSaveError('matter.otherParty.solicitorContact.surname');

      }
      this.lockScreenService.lockForUpdate = true;
      this.contactQueryService.getContactForMatter(this.selectedSolicitor.dataModel.id)
      .finally(() => this.lockScreenService.lockForUpdate = false)
      .subscribe((sourceSolicitor: Contact) => {
        if (sourceSolicitor) {
          this.matter.addMatterParticipant(sourceSolicitor,
            true,
            this.matter.otherPartyMPRoleSolicitor);

          this.buildSolicitorStructure(!this.showWizardFields ? true : false, sourceSolicitor);

          // Change old user story ComboBox rule
          // Only when law firm is empty, it will get this solicitor's law firm
          // So it can improve performance
          if (!this.matter.otherPartyLawFirm) {
            // Populating the combo box
            this.lockScreenService.lockForUpdate = true;
            this.contactQueryService.getContactForMatter(sourceSolicitor.legalFirmId)
            .finally(() => this.lockScreenService.lockForUpdate = false)
            .subscribe((sourceLawFirm: Contact) => {
              if (sourceLawFirm) {
                // Add the Law Firm MatterParticipant
                //According to backend requirement, UI set law firm snapshot subContact as []
                let snapshotContact: Contact = new Contact(sourceLawFirm);
                snapshotContact.subContacts = [];
                this.matter.addMatterParticipant(snapshotContact,
                  true,
                  this.matter.otherPartyMPRoleLawFirm);
                //Build selectedLawFirm wrapper
                this.buildLawFirmStructure(false, sourceLawFirm);
              }
            });
          }
        }
      });
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  updateOtherPartyContactInfoWithSolicitor(snapShotContact: Contact) {
    // Set Solicitor's Address is readOnly
    this.disableAddress = true;

    const otherPartyContactInfo: ContactInfo = this.matter.otherPartyContactInfo;
    if (this.selectedSolicitor.dataModel.legalFirmId > 0) {
      otherPartyContactInfo.firmId = this.selectedSolicitor.dataModel.legalFirmId;
      // this.populateComboBox(this.selectedSolicitor.dataModel.legalFirmId);
    }
    //
    // if(this.selectedSolicitor.dataModel.firmName) {
    //     this.selectedLawFirm.dataModel = this.selectedSolicitor.dataModel.firmName;
    //     otherPartyContactInfo.firmName = this.selectedSolicitor.dataModel.firmName;
    // }

    otherPartyContactInfo.mailingAddress = this.selectedSolicitor.dataModel.mailingAddress;
    // Back-end requirement
    if (otherPartyContactInfo.mailingAddress) {
      otherPartyContactInfo.mailingAddress.id = null;
    }

    otherPartyContactInfo.barristerSolicitor = this.selectedSolicitor.dataModel.barristerSolicitor;
    this.disableBarristerSolicitor = true;

    otherPartyContactInfo.workPhone = this.selectedSolicitor.dataModel.workPhone;
    otherPartyContactInfo.faxPhone = this.selectedSolicitor.dataModel.faxPhone;
    otherPartyContactInfo.cellPhone = this.selectedSolicitor.dataModel.cellPhone;
    otherPartyContactInfo.email = this.selectedSolicitor.dataModel.email;
    // vendorContactInfo.dear = this.selectedSolicitor.dataModel.dear;
    otherPartyContactInfo.solicitorName = snapShotContact && snapShotContact.contactName && snapShotContact.contactName.surnameLastFullName;
    otherPartyContactInfo.solicitorId = this.selectedSolicitor.dataModel.id;

  }

  dataSelectedLawClerks(event: Contact): void {
    this.enableSave();
    if (event) {
      const otherPartyContactInfo: ContactInfo = this.matter.otherPartyContactInfo;
      // Populating the fields
      otherPartyContactInfo.lawClerkFaxPhone = event.faxPhone;
      otherPartyContactInfo.lawClerkWorkPhone = event.workPhone;
      otherPartyContactInfo.lawClerkCellPhone = event.cellPhone;
      otherPartyContactInfo.lawClerkEmail = event.email;
      otherPartyContactInfo.lawClerkName = event.surnameLastFullName;
      otherPartyContactInfo.lawClerkId = event.id;
      this.selectedLawClerk = otherPartyContactInfo.lawClerkName;
      this.handleLawClerkChanged();
    }
  }

  clearSelectedLawClerk(): void {
    const otherPartyContactInfo: ContactInfo = this.matter.otherPartyContactInfo;
    // Populating the fields
    otherPartyContactInfo.removeLawClerkWorkPhone();
    otherPartyContactInfo.removeLawClerkCellPhone();
    otherPartyContactInfo.removeLawClerkFaxPhone();
    otherPartyContactInfo.lawClerkEmail = null;
    otherPartyContactInfo.lawClerkName = null;
    this.selectedLawClerk = null;
    otherPartyContactInfo.lawClerkId = null;
  }

  clearLawFirm(): void {
    let solicitorTitle = provinceBasedFieldLabels.get('matter.title.solicitor', this.matter.provinceCode);
    let lawClerkTitle = provinceBasedFieldLabels.get('provinceBasedLawClerkTitle', this.matter.provinceCode);

    let message: string
      = `Proceed to remove this Firm, ${ solicitorTitle } and ${ lawClerkTitle } information from this matter?`;
    this.dialogService.confirm('Confirmation', message, false, 'Delete').subscribe(res => {
      if (res) {
        this.enableSave();
        this.documentProductionService.tryToRevokePackage(this.matter, this.matter.otherPartySolicitor, this.contactQueryService, null, this.emailFieldService);
        if (!this.matter.otherPartyContactInfo.firmId) { // free type text
          this.matter.otherPartyContactInfo.firmName = null;
        }
        const otherPartyContactInfo: ContactInfo = this.matter.otherPartyContactInfo;
        // Cleanup the followings fields
        otherPartyContactInfo.mailingAddress = new Address();
        otherPartyContactInfo.removeWorkPhone();
        otherPartyContactInfo.removeFaxPhone();
        otherPartyContactInfo.removeCellPhone();
        otherPartyContactInfo.barristerSolicitor = null;
        otherPartyContactInfo.email = null;
        // vendorContactInfo.dear = null;
        otherPartyContactInfo.fileNumber = null;
        otherPartyContactInfo.firmId = null;
        otherPartyContactInfo.firmName = null;
        otherPartyContactInfo.solicitorName = null;
        otherPartyContactInfo.solicitorId = null;

        this.selectedSolicitorsForLawFirm = null;
        this.disableAddress = false; // making Address fields editable
        this.disableBarristerSolicitor = false; //making BarristerSolicitor field editable
        this.showSolicitorDropDownArrow = false;

        //Clean law clerk fields
        this.clearSelectedLawClerk();
        this.lawClerks = [];
        // this.selectLawClerk = false;
        this.showLawClerksDropDownArrow = false;

        const lawFirm = this.matter.otherPartyLawFirm;
        if (lawFirm) {
          if (lawFirm.contact) {
            this.contactService.unlockContact(lawFirm.contact.sourceContactId).subscribe((res) => {
              this.logger.info('Other Party LawFirm has been unlocked now');
            });
          }
          this.matter.deleteMatterParticipant(lawFirm);
          this.errorService.removeDpSaveError('matter.otherParty.contact.firmSolicitor.lawFirm.firmName.MISSING');

          // this.firmSelected = false;
        }
        this.selectedLawFirm = new MatterParticipantWrapper();
        this.selectedLawFirm.editMode = true;
        this.matter.otherPartyContactInfo.firmId = null;
        this.matter.otherPartyContactInfo.firmName = null;

        const lawclerk = this.matter.otherPartyLawClerk;
        if (lawclerk) {
          this.matter.deleteMatterParticipant(lawclerk);
        }
        const solicitor = this.matter.otherPartySolicitor;
        if (solicitor) {
          if (solicitor.contact) {
            this.contactService.unlockContact(solicitor.contact.sourceContactId).subscribe((res) => {
              this.logger.info('solicitor has been unlocked now');
            });
          }
          this.matter.deleteMatterParticipant(solicitor);
          this.errorService.removeDpSaveError('matter.otherParty.solicitorContact.surname');
        }
        this.selectedSolicitor = new MatterParticipantWrapper();
        this.selectedSolicitor.editMode = true;
        this.matter.otherPartyContactInfo.solicitorId = null;
        this.matter.otherPartyContactInfo.solicitorName = null;
        //if law firm get deleted, clear the value of 'Payable To' -- even manual entered value in that field
        this.resetStatementOfAdjustmentPayable();
        this.solicitorAddressDd = [];
      }
    });
  }

  //Do a hack to show solicitors list
  handleDropDownClickSolicitorPickList = (event) => { // to preserve 'this' context
    const otherPartyLawFirm = this.matter.otherPartyLawFirm;
    if (otherPartyLawFirm && otherPartyLawFirm.contact) {
      if (this.isMyOwnAccountLawFirm()) {
        //If it is my own account, removing to add new solicitor
        this.populateSolicitor(otherPartyLawFirm.contact.sourceContactId, true);
      } else {
        this.populateSolicitor(otherPartyLawFirm.contact.sourceContactId);
      }
    }
  };

  //Do a hack to show lawClerks list with setTimeout
  handleDropDownClickLawClerksPickList = (event) => { // to preserve 'this' context
    const tempLawClerks: Contact[] = this.lawClerks.slice();
    this.lawClerks = [];
    this.lawClerks = tempLawClerks;
    setTimeout(() => {
      // In IE, clicking item from the dropdown list would generate empty selection while the existing
      // value of autocomplete was empty. This issue happened when Placeholder and Dropdown Arrow are both
      // enabled in autocomplete in IE.
      // The root cause is till unknown, and here is the hack which change the existing value to a space when
      // it is empty.
      if (this.selectedLawClerk === '') {
        this.selectedLawClerk = ' ';
      }
    }, 0);
  };

  generateF9OnDear(): string {
    if (this.selectedSolicitor && this.selectedSolicitor.matterParticipant && this.selectedSolicitor.matterParticipant.contact) {
      if (this.selectedSolicitor.matterParticipant.contact.gender === 'MALE' || this.selectedSolicitor.matterParticipant.contact.gender === 'FEMALE') {
        if (this.selectedSolicitor.matterParticipant.contact.gender === 'MALE') {
          return 'Press F9 to toggle ' + 'Mr. ' + this.selectedSolicitor.matterParticipant.contact.lastName + ' or Sir';
        } else if (this.selectedSolicitor.matterParticipant.contact.gender === 'FEMALE') {
          return 'Press F9 to toggle ' + 'Ms. ' + this.selectedSolicitor.matterParticipant.contact.lastName + ' or Madam';
        }
      } else {

        if (dropDowns.dearOptions && dropDowns.dearOptions.length > 0) {
          let filteredOptions = dropDowns.dearOptions.filter(dropDown => dropDown.label != '');
          return 'Press F9 to toggle ' + filteredOptions.map(function (elem) {
            return elem.label;
          }).join(', ').replace(/,(?!.*,)/gmi, ' or');

        }
      }
    } else {
      return null;
    }
  }

  /**
   * F9 Capture on "Dear" input field
   */
  handleF9OnDear(): void {
    if (this.selectedSolicitor && this.selectedSolicitor.matterParticipant && this.selectedSolicitor.matterParticipant.contact) {

      if (this.selectedSolicitor.matterParticipant.contact.gender === 'MALE' || this.selectedSolicitor.matterParticipant.contact.gender === 'FEMALE') {
        if (this.selectedSolicitor.matterParticipant.contact.gender === 'MALE') {
          const maleMrSurname: string = 'Mr. ' + this.selectedSolicitor.matterParticipant.contact.lastName;
          const dearMaleOptions = [
            {label: '', value: ''},
            {label: maleMrSurname, value: maleMrSurname},
            {label: 'Sir', value: 'Sir'}
          ];

          let inputValue: string = this.matter.otherPartyContactInfo.dear
            ? this.matter.otherPartyContactInfo.dear.trim()
            : '';
          let index: number = _.findIndex(dearMaleOptions, opt => opt.label.startsWith(inputValue));

          index++;

          this.matter.otherPartyContactInfo.dear = dearMaleOptions[ index % dearMaleOptions.length ].label + ' ';
        } else if (this.selectedSolicitor.matterParticipant.contact.gender === 'FEMALE') {
          const femaleMsSurname: string = 'Ms. ' + this.selectedSolicitor.matterParticipant.contact.lastName;
          const dearFemaleOptions = [
            {label: '', value: ''},
            {label: femaleMsSurname, value: femaleMsSurname},
            {label: 'Madam', value: 'Madam'}
          ];

          let inputValue: string = this.matter.otherPartyContactInfo.dear
            ? this.matter.otherPartyContactInfo.dear.trim()
            : '';
          let index: number = _.findIndex(dearFemaleOptions, opt => opt.label.startsWith(inputValue));

          index++;

          this.matter.otherPartyContactInfo.dear = dearFemaleOptions[ index % dearFemaleOptions.length ].label + ' ';
        }
      } else {
        let inputValue: string = this.matter.otherPartyContactInfo.dear
          ? this.matter.otherPartyContactInfo.dear.trim()
          : '';

        let index: number = _.findIndex(dropDowns.dearOptions, opt => opt.label.startsWith(inputValue));

        index++;

        this.matter.otherPartyContactInfo.dear = dropDowns.dearOptions[ index % dropDowns.dearOptions.length ].label + ' ';
      }
    }
  }

  get solicitorNameHelpText(): string {
    let helpText: string;

    if (this.showSolicitorDropDownArrow) {
      //If it is my own account, removing to add new solicitor
      if (this.isMyOwnAccountLawFirm()) {
        if (this.userStateService.isDefaultProvinceBC()) {
          helpText = 'Select a Lawyer/Notary from the Firm';
        } else {
          helpText = 'Select a Solicitor from the Law Firm';
        }

      } else {
        if (this.userStateService.isDefaultProvinceBC()) {
          helpText = 'Select a Lawyer/Notary from the Firm or add a new one';
        } else {
          helpText = 'Select a Solicitor from the Law Firm or to add a new one';
        }
      }
    } else {
      helpText = 'Search by "Surname, First Name", Address or Add new';
    }

    return helpText;
  }

  get selectedJurisdiction(): Jurisdiction {
    return this.otherPartiesSolicitorState.selectedJurisdiction;
  }

  set selectedJurisdiction(value: Jurisdiction) {
    this.otherPartiesSolicitorState.selectedJurisdiction = value;
  }

  get otherPartiesSolicitorState(): VendorsSolicitorState {
    if (!(this.tabService.activeTab as MatterTab).vendorsSolicitorState) {
      (this.tabService.activeTab as MatterTab).vendorsSolicitorState = new VendorsSolicitorState();
    }
    return (this.tabService.activeTab as MatterTab).vendorsSolicitorState;
  }

  // get selectedSolicitor() : any {
  //     return this.otherPartiesSolicitorState.selectedSolicitor;
  // }
  //
  // set selectedSolicitor(value : any) {
  //     this.otherPartiesSolicitorState.selectedSolicitor = value;
  // }

  get selectedLawClerk(): any {
    return this.otherPartiesSolicitorState.selectedLawClerk;
  }

  set selectedLawClerk(value: any) {
    this.otherPartiesSolicitorState.selectedLawClerk = value;
  }

  // get showSolicitorDropDownArrow() : boolean {
  //     return this.otherPartiesSolicitorState.showSolicitorDropDownArrow;
  // }
  //
  // set showSolicitorDropDownArrow(value : boolean) {
  //     this.otherPartiesSolicitorState.showSolicitorDropDownArrow = value;
  // }

  // get selectedLawFirm() : any {
  //     return this.otherPartiesSolicitorState.selectedLawFirm;
  // }
  //
  // set selectedLawFirm(value : any) {
  //     this.otherPartiesSolicitorState.selectedLawFirm = value;
  // }

  // get disableBarristerSolicitor() : boolean {
  //     return this.otherPartiesSolicitorState.disableBarristerSolicitor;
  // }
  //
  // set disableBarristerSolicitor(value : boolean) {
  //     this.otherPartiesSolicitorState.disableBarristerSolicitor = value;
  // }
  //
  // get disableAddress() : boolean {
  //     return this.otherPartiesSolicitorState.disableAddress;
  // }
  //
  // set disableAddress(value : boolean) {
  //     this.otherPartiesSolicitorState.disableAddress = value;
  // }

  openSourceContactTab(otherPartyWrapper: MatterParticipantWrapper): void {
    this.contactQueryService.getContactForOpeningTab(otherPartyWrapper.matterParticipant.contact.sourceContactId).subscribe((res: any) => {
      let source: Contact = res.contact;
      // const contactDetails : any = ContactDetails.find((item : any) => {
      //     return item.label === purchaser.selectedDetailsTabText;
      // });
      // const subRoute : string = contactDetails && contactDetails.route;
      const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/' + source.contactType, source.contactType);
      this.tabService.openTab(contactTab);
    });
  }

  getOtherPartyBurgerMenuItems(index: number, selectedOtherPartyWrapper: MatterParticipantWrapper): any {
    let menuItems = [];
    if (index > 0) {
      menuItems.push('Move Up');
    }
    if (index < this.matter.otherSideClients.length - 1) {
      menuItems.push('Move Down');
    }
    menuItems.push('Delete');
    if (selectedOtherPartyWrapper.sourceContactId && selectedOtherPartyWrapper.isStale) {
      menuItems.push('Clear Flag Without Updating Matter');
      menuItems.push('Replace Matter With Source Contact');
    }
    return menuItems;
  }

  clickOtherPartyBurgerMenu(event, otherPartyWrapper: MatterParticipantWrapper) {
    this.logger.info('clickBurgerMenu | event:', event);
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE : {
        this.deleteOtherPartyWithConfirmation(otherPartyWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_UP : {
        this.moveUpOtherParty(otherPartyWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_DOWN : {
        this.moveDownOtherParty(otherPartyWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT : {
        this.updateSnapshot(otherPartyWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.ADD_SPOUSE : {
        MatterParticipantWrapper.clearSelectedClientsIfNotSelected(this.selectedOtherParties);
        this.addOtherPartySpouse(otherPartyWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.CONVERT_CONTACT : {
        this.convertMatterOnlyClientToContact(otherPartyWrapper);
        break;
      }
      default: {
        break;
      }
    }
  }

  // This will act on Update Matter with Contact Data button click on dialog
  public updateSnapshot(otherPartyWrapper: MatterParticipantWrapper): void {
    this.matterParticipantService.updateMatterContactFromSourceContact(otherPartyWrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.otherPartyCallbackOnUpdateSourceContact, this.matter);
  }

  otherPartyCallbackOnUpdateSourceContact = (result: boolean, matterParticipantWrapper: MatterParticipantWrapper) => {
    if (result) {
      this.enableSave();
      matterParticipantWrapper.matterParticipant.contact.idInfoEnteredBy = null;
      matterParticipantWrapper.matterParticipant.contact.contactIdInfoEnteredBy = null;
      matterParticipantWrapper.matterParticipant.contact.enteredOn = null;
    }
  };

  //TODO: Why are we getting back to a callback based approach, this defeats the purpose of using observables in the first place
  public getContactAndInvokeCallback(id: number, callBack: (src: Contact) => void) {
    this.contactQueryService.getContactForMatter(id)
    .subscribe((source: Contact) => {
      callBack(source);
    });
  }

  isFullUpdate(selectedOtherParty: MatterParticipantWrapper): boolean {
    // console.log("isFullUpdate(): selectPurchaser:", selectPurchaser);
    if (selectedOtherParty.matterParticipant
      && selectedOtherParty.matterParticipant.contact
      && !selectedOtherParty.matterParticipant.contact.sourceContactId) {
      return true;
    }

    if (selectedOtherParty.matterParticipant.sourceContactLockAcquired
      && !selectedOtherParty.isStale
      && !selectedOtherParty.isClearFlagWithoutUpdatingMatter) {
      return true;
    }

    return false;
  }

  isNewManageContactReadOnly(selectedOtherPartyWrapper: MatterParticipantWrapper): boolean {
    const newManageContactReadOnly: boolean = (!this.isFullUpdate(selectedOtherPartyWrapper) || selectedOtherPartyWrapper.sourceContactIsLocked);
    // console.log('newManageContactReadOnly: ', newManageContactReadOnly, 'name', selectedOtherParty.matterParticipant.contact.genericFullName);
    return newManageContactReadOnly;
  }

  updateOtherPartyFlaErrorMessage(): void {
    this.matterParticipantService.updateWrappersFlaErrorMessage(this.selectedOtherParties,
      this.matter.otherSideClientType, false, this.matter.provinceCode, this.matter);
  }

  getLawFirmName(lawFirmWrapper: MatterParticipantWrapper): string {
    let organizationName: string;
    const otherPartyLawFirm: MatterParticipant = this.matter.otherPartyLawFirm;
    if (lawFirmWrapper && lawFirmWrapper.dataModel
      && lawFirmWrapper.dataModel instanceof Contact) {
      organizationName = lawFirmWrapper.dataModel.organizationName;
    } else if (otherPartyLawFirm && otherPartyLawFirm.contact && otherPartyLawFirm.contact.organizationName) {
      organizationName = otherPartyLawFirm.contact.organizationName;

    } else {
      organizationName = null;
    }
    return organizationName;
  }

  getSolicitorName(solicitorWrapper: MatterParticipantWrapper): string {
    const solicitor = this.matter.otherPartySolicitor;
    let surnameLastFullName: string;

    if (solicitorWrapper && solicitorWrapper.dataModel
      && solicitorWrapper.dataModel instanceof Contact && solicitorWrapper.dataModel.contactName) {
      surnameLastFullName = solicitorWrapper.dataModel.contactName.surnameLastFullName;
    } else if (solicitor && solicitor.contact && solicitor.contact.contactName) {
      surnameLastFullName = solicitor.contact.contactName.surnameLastFullName;
    } else {
      surnameLastFullName = null;
    }

    return surnameLastFullName;
  }

  getBurgerMenuItems(wrapper: MatterParticipantWrapper): any {
    let menuItems = [];

    if (!(wrapper && wrapper.matterParticipant && wrapper.matterParticipant.matterParticipantRole == MatterParticipantRoleTypes.OTHERPARTY_LAW_FIRM && this.isOpportunityActingForBoth())) {
      menuItems.push(SnapshotBurgerMenuActions.DELETE);
    }

    if (wrapper
      && wrapper.matterParticipant
      && wrapper.matterParticipant.contact) {
      wrapper.menuItemsForRevert(menuItems);
    }
    if (wrapper.isStale) {
      menuItems.push(SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
    }

    return menuItems;
  }

  getDisabledItems(wrapper: MatterParticipantWrapper) {
    let disabledMenuItems = [];
    if (wrapper
      && wrapper.matterParticipant
      && wrapper.matterParticipant.contact
      && wrapper.isLockedElsewhere) {
      wrapper.menuItemsForRevert(disabledMenuItems);
    }

    if (wrapper.isStale && wrapper.isLockedElsewhere) {
      disabledMenuItems.push('Clear Flag Without Updating Matter');
      disabledMenuItems.push('Replace Matter With Source Contact');
    }
    return disabledMenuItems;
  }

  clickBurgerMenu(event, wrapper: MatterParticipantWrapper, isLawFirm: boolean) {
    this.logger.info('clickBurgerMenu | event:', event);
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE: {
        if (isLawFirm) {
          this.clearLawFirm();
        } else {
          this.deleteSolicitor();
        }
        break;
      }
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL: {
        this.revertClearUpdateAction(wrapper, SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
        break;
      }
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT : {
        this.revertClearUpdateAction(wrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
        break;
      }

      default: {
        break;
      }
    }
  }

  deleteSolicitor(): void {
    const solicitor = this.matter.otherPartySolicitor;
    let solicitorTitle = provinceBasedFieldLabels.get('matter.title.solicitor', this.matter.provinceCode).toLowerCase();
    let deleteConfirmationMessage = `Proceed to remove this ${ solicitorTitle } from this matter?`;
    deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, solicitor, MatterParticipantRoleTypes.OTHERPARTY_SOLICITOR);
    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(res => {
      if (res) {
        this.silentDeleteSolicitor();
      }
    });
  }

  silentDeleteSolicitor(): void {
    this.enableSave();

    const otherPartyContactInfo: ContactInfo = this.matter.otherPartyContactInfo;
    // Cleanup the followings fields
    otherPartyContactInfo.solicitorName = null;
    otherPartyContactInfo.solicitorId = null;

    this.showSolicitorDropDownArrow = false;

    const solicitor = this.matter.otherPartySolicitor;
    if (solicitor) {
      if (solicitor.contact) {
        this.documentProductionService.tryToRevokePackage(this.matter, solicitor, this.contactQueryService, null, this.emailFieldService);
        this.contactService.unlockContact(solicitor.contact.sourceContactId).subscribe((res) => {
          this.logger.info('solicitor has been unlocked now');
        });
      }
      this.matter.deleteMatterParticipant(solicitor);
      this.errorService.removeDpSaveError('matter.otherParty.solicitorContact.surname');
      this.handleDeleteSolicitor(solicitor);

    }
    this.selectedSolicitor = new MatterParticipantWrapper();
    this.selectedSolicitor.editMode = true;

    const lawFirm = this.matter.otherPartyLawFirm;
    if (lawFirm) {
      if (lawFirm && lawFirm.contact) {
        this.contactService.getSolicitorsForLawFirm(lawFirm.contact.sourceContactId, true).subscribe(
          (data: Contact[]) => {
            this.showSolicitorDropDownArrow = Array.isArray(data) && data.length > 0 ? true : false;
            // data can NOT set to this.solicitor.
            // It will show two pick lists. One is searching list, the other is drop down list if do it.
            // this.solicitors = data;

            this.enableAutoCompleteTabTraverse(this.solicitor);
          }
        );
      }
    }
  }

  // This will act on Update Matter with Contact Data button click on dialog
  public updateClearLawFirmSolicitorSnapshot(wrapper: MatterParticipantWrapper, source: Contact, action: string): void {
    if (action === SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT) {
      wrapper.matterParticipant.contact.update(source);
      if (wrapper.matterParticipant.matterParticipantRole === this.matter.otherPartyMPRoleLawFirm) {
        this.buildLawFirmStructure(false, source);
      } else if (wrapper.matterParticipant.matterParticipantRole === this.matter.otherPartyMPRoleSolicitor) {
        this.buildSolicitorStructure(false, source);
      }
    }

    this.enableSave();
  }

  revertClearUpdateAction(wrapper: MatterParticipantWrapper, action: string) {
    this.contactQueryService.getContactForMatter(wrapper.matterParticipant
      && wrapper.matterParticipant.contact
      && wrapper.matterParticipant.contact.sourceContactId)
    .subscribe((sourceContact: Contact) => {
      this.tabService.updateIsLockedElsewhereStatus(wrapper, sourceContact);

      if (!wrapper.isLockedElsewhere) {
        switch (action) {
          case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
            this.enableSave();

            this.matterService.revertToGlobalAction(wrapper, sourceContact, null, this.matter);
            break;
          case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
            this.updateClearLawFirmSolicitorSnapshot(wrapper, sourceContact,
              SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
            break;
          default:
            break;
        }
      } else {

        this.setExpanded(wrapper, true);
      }
    });

  }

  // If it is Out of Sync, it needs update first
  editAsPrivateCopyAction(wrapper: MatterParticipantWrapper) {

    // Locked this contact and set this contact is dirty
    if (wrapper
      && wrapper.matterParticipant
      && wrapper.matterParticipant.contact) {

      this.contactQueryService.getContact(wrapper.matterParticipant.contact.sourceContactId).subscribe(
        (res: Contact) => {
          if (!res.locked && !this.tabService.isContactAlreadyLocked(wrapper.matterParticipant.contact)) {
            wrapper.lockedSourceContact = null;
            wrapper.matterParticipant.sourceContactLockAcquired = true;
            wrapper.isStale = wrapper.retrieveStaleStatus(res);
            wrapper.isClearFlagWithoutUpdatingMatter = wrapper.refreshClearFlagStatus(res);

            wrapper.matterParticipant.contact.isDirty = true;
            wrapper.isLockedElsewhere = false;

            wrapper.sourceProxyEdited = true;
            this.enableSave();
          } else {
            wrapper.isLockedElsewhere = true;
            wrapper.sourceProxyEdited = res.proxyEdited;
            wrapper.sourcePrivateFlag = res.privateFlag;
            wrapper.sourceProxyForGlobal = res.proxyForGlobal;
            wrapper.lockedSourceContact = res;
            wrapper.matterParticipant.sourceContactLockAcquired = false;
          }
        });
    }
  }

  resetStatementOfAdjustmentPayable(): void {
    this.matter.resetStatementOfAdjustmentPayable(this.documentProfileCache.cachedDocumentProfile, this.documentProfileCache.cachedDefaultDocumentProfile);
  }

  notifyChange(): void {
    this.enableSave();
  }

  isOutOfSync(wrapper: MatterParticipantWrapper): boolean {
    return !wrapper.sourceContactIsLocked
      && (wrapper.isStale || wrapper.isClearFlagWithoutUpdatingMatter);

  }

  isStale(wrapper: MatterParticipantWrapper): boolean {
    return !wrapper.sourceContactIsLocked && wrapper.isStale;
  }

  showSolicitorSnapshot(wrapper: MatterParticipantWrapper): boolean {

    return this.matter.otherPartySolicitor && this.isExpanded(wrapper);
  }

  showLawFirmSnapshot(wrapper: MatterParticipantWrapper): boolean {

    return this.matter.otherPartyLawFirm
      && this.isExpanded(wrapper);
  }

  showAllLawFirmSnapshotFields(wrapper: MatterParticipantWrapper): boolean {

    return this.showLawFirmSnapshot(wrapper) && this.matter.otherPartySolicitor == null;
  }

  get lawFormSnapshotReadOnly(): boolean {
    return this.isLawFormOrSolicitorLocked(this.selectedLawFirm) || this.isOutOfSync(this.selectedLawFirm)
      || this.isLawFormOrSolicitorGlobal(this.selectedLawFirm)
      || this.isMyOwnAccountLawFirm() || this.selectedLawFirm.hasParticipantWithoutSourceContact();
  }

  isLawFormOrSolicitorLocked(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper && wrapper.sourceContactIsLocked) {
      return true;
    }
    return false;
  }

  isLawFormOrSolicitorGlobal(wrapper: MatterParticipantWrapper): boolean {
    if ((wrapper && wrapper.sourceContactIsLocked)
      || (wrapper.matterParticipant
        && wrapper.matterParticipant.contact
        && wrapper.isOwnedBySystemAccount)) {
      return true;
    }

    return false;
  }

  get solicitorSnapshotReadOnly(): boolean {
    if (!this.selectedSolicitor.sourceContactId) {//we are checking sourceContactId because in some cases solicitor doesn't has one
      return false;
    }

    return this.isLawFormOrSolicitorLocked(this.selectedSolicitor) || this.isOutOfSync(this.selectedSolicitor)
      || this.isLawFormOrSolicitorGlobal(this.selectedSolicitor) || this.isMyOwnAccountSolicitor()
      || this.selectedSolicitor.hasParticipantWithoutSourceContact();
  }

  showGlobalWindow(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper.matterParticipant && wrapper.matterParticipant.contact) {
      return wrapper.isOwnedBySystemAccount;
    }
    return false;
  }

  showAddressBookWindow(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper.matterParticipant && wrapper.matterParticipant.contact) {
      return wrapper.isProxyCopyOfGlobal;
    }
    return false;
  }

  openSourceSolicitorTab() {
    let otherPartySolicitor: MatterParticipant = this.matter.otherPartySolicitor;
    if (otherPartySolicitor && otherPartySolicitor.contact && otherPartySolicitor.contact.sourceContactId) {
      this.contactQueryService.getContactForOpeningTab(otherPartySolicitor.contact.sourceParentOrganizationId).subscribe((res: any) => {
        let source: Contact = res.contact;
        // const contactDetails : any = ContactDetails.find((item : any) => {
        //     return item.label === purchaser.selectedDetailsTabText;
        // });
        // const subRoute : string = contactDetails && contactDetails.route;
        const contactTab
          = ContactTab.createContactTab(source, 'main/contacts/contact/LAW_FIRM', source.contactType, 'solicitor', otherPartySolicitor.contact.sourceContactId);
        this.tabService.openTab(contactTab);
      });
    }
  }

  isMyOwnAccountSolicitor(): boolean {
    const accountId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    const legFirmId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.legalFirmId));
    if (this.selectedSolicitor
      && this.selectedSolicitor.matterParticipant
      && this.selectedSolicitor.matterParticipant.contact) {
      return (this.selectedSolicitor.matterParticipant.contact.customerAccountId == accountId)
        && (this.selectedLawFirm.sourceContactLegfirmId == legFirmId);
    }

    return false;
  }

  isMyOwnAccountLawFirm(): boolean {
    return this.selectedLawFirm
      && this.selectedLawFirm.matterParticipant
      && this.selectedLawFirm.matterParticipant.isMyOwnAccountLawFirm();
  }

  get isCapacityVisible(): boolean {
    return this.matter.otherPartiesCapacity === 'OTHER';
  }

  updateOtherPartiesCapacities(capacitySetting: string): void {
    this.enableSave();
    this.selectedOtherParties.forEach((otherParty: MatterParticipantWrapper) => {
      if (otherParty.matterParticipant) {
        this.setPurchaserCapacityAndShare(otherParty.matterParticipant, capacitySetting);
      }
    });

    if (!(this.tabService.activeTab && this.tabService.activeTab.isMassUpdateSubType())) {
      this.checkCapacityWarningMessage(capacitySetting);
    }
  }

  checkCapacityWarningMessage(purchasersCapacitySetting: string): void {
    if (this.matter.isSale) {
      if (this.otherParties.length < 2
        && (purchasersCapacitySetting === 'JOINT_TENANTS'
          || purchasersCapacitySetting === 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES'
          || purchasersCapacitySetting === 'TENANTS_IN_COMMON_SPLIT_SHARES')) {

        this.fieldKey = 'matter.otherParty.capacity.asTenants';
        this.errorService.addDpFieldError(DPError.createDPError('matter.otherParty.capacity.asTenants'));
      } else {
        this.errorService.removeDpFieldError('matter.otherParty.capacity.asTenants');
      }

      if (this.otherParties.length > 1 && purchasersCapacitySetting === 'REGISTERED_OWNER') {
        this.fieldKey = 'matter.otherParty.capacity.registeredOwner';
        this.errorService.addDpFieldError(DPError.createDPError('matter.otherParty.capacity.registeredOwner'));
      } else {
        this.errorService.removeDpFieldError('matter.otherParty.capacity.registeredOwner');
      }
    }
  }

  setPurchaserCapacityAndShare(purchaser: MatterParticipant, matterPurchasersSetting: string) {
    switch (matterPurchasersSetting) {
      case 'UNSPECIFIED_CAPACITY':
        purchaser.purchaserCapacity = null;
        purchaser.purchaserShare = null;
        break;

      case 'JOINT_TENANTS':
        purchaser.purchaserCapacity = 'JOINT_TENANTS';
        purchaser.purchaserShare = null;
        break;

      case 'TENANTS_IN_COMMON_SPLIT_SHARES':
        purchaser.purchaserCapacity = 'TENANTS_IN_COMMON';
        //This value also gets changed when the purchasers are added or removed from the matter, see updatePurchasersSharesIfNeeded()
        purchaser.purchaserShare = PurchaserCapacity.getDynamicValueInterest(this.otherParties.length);
        break;

      case 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES':
        purchaser.purchaserCapacity = 'TENANTS_IN_COMMON';
        purchaser.purchaserShare = null;
        break;

      case 'REGISTERED_OWNER':
        purchaser.purchaserCapacity = 'REGISTERED_OWNER';
        purchaser.purchaserShare = null;
        break;

      case 'OTHER':
        //purchaser.purchaserCapacity = '';
        purchaser.purchaserShare = purchaser.previousPurchaserShareValue ? purchaser.previousPurchaserShareValue : '';
        break;

    }
  }

  //cached, it's called multiple times when the page is parsed (each time filtering through the participants list)
  get otherParties(): MatterParticipant[] {
    if (this.otherPartiesChanged || !this._otherParties) {
      this._otherParties = this.matter.otherSideClients;
    }
    return this._otherParties;
  }

  get isPurchaseMatter(): boolean {
    return this.matter.isPurchase;
  }

  get isSaleMatter(): boolean {
    return this.matter.isSale;
  }

  get isMortgageMatter(): boolean {
    return this.matter.isMortgage;
  }

  get isDirectDepositToTrustAccount(): boolean {
    return this.matter.directDepositInstructionOtherParty && this.matter.directDepositInstructionOtherParty.directDepositChoice === 'YES';
  }

  isOtherPartyResideAtSubjectProperty(): boolean {
    return this.matter.otherPartyContactInfo ? this.matter.otherPartyContactInfo.isResideAtSubjectProperty : false;
  }

  residingAtSubjectPropertyChange(): void {
    this.enableSave();
  }

  get displayServiceAddress(): boolean {
    return (this.isSaleMatter && !this.isOtherPartyResideAtSubjectProperty()) || this.isPurchaseMatter || this.matter.isProjectSale;
  }

  showPurchasersTitleDetails(): void {
    this.dialogService.matDialogContent({
      content: PurchasersTitleModalComponent,
      context: {purchasers: this.otherParties, flaStatements: this.flaStatements},

    });
  }

  showTitleDetails(): boolean {
    return this.selectedOtherParties && this.selectedOtherParties.length > 0;
  }

  get topicName(): MatterTopicKey {
    switch (this.matter.otherSideClientType) {
      case 'PURCHASER' :
        return 'PURCHASER_SOLICITOR';
      case 'MORTGAGOR' :
        return 'OTHER_SOLICITOR';
      default:
        return 'VENDOR_SOLICITOR';
    }
  }

  get actingForBothPartiesInMortgageMatter(): boolean {
    return this.matter.actingForBothPartiesInMortgageMatter;
  }

  get actingForBothPartiesMsg(): string {
    return 'Information about the other solicitor cannot be entered because response in Matter Opening tab indicates that you are acting for both parties';
  }

  onOtherPartyDataChanged(): void {
    this.matter.reCalculateOtherPartyReLine();
    this.enableSave();
  }

  onClickCapacityWarningIcon(selectedOtherParty: MatterParticipantWrapper): void {
    this.setOtherPartyExpanded(selectedOtherParty, true);
    this.selectTab('Capacity-Share', selectedOtherParty);
  }

  showCapacityWarningForOtherParty(selectedOtherParty: MatterParticipant): boolean {
    //There is only purchaserShare in AB,MB, SK, NS or NB.
    if (this.matter.isMatterProvinceABorMBorSKorNSorNB) {
      return selectedOtherParty && this.matter.otherPartiesCapacity === 'OTHER' && !selectedOtherParty.purchaserShare;
    } else {
      return selectedOtherParty && this.matter.otherPartiesCapacity === 'OTHER' && (!selectedOtherParty.purchaserCapacity || !selectedOtherParty.purchaserShare);
    }
  }

  setSolicitorAddressDd(firmContact: Contact) {
    this.solicitorAddressDd = [];
    if (firmContact) {
      this.solicitorAddressDd = firmContact.createSolicitorAddressDdOptions(firmContact);
    } else {
      const contact: Contact = new Contact();
      this.solicitorAddressDd = contact.createSolicitorAddressDdOptions(null);
    }

  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  cleanupOtherPartyField(wrapper: MatterParticipantWrapper): void {
    if (wrapper && typeof wrapper.dataModel === 'string') {
      wrapper.dataModel = undefined;
      this.loadingOtherParties = false;
    }
  }

  isAddSpouseVisible(participant: MatterParticipant): boolean {
    if (participant && this.matter) {
      return participant.onShowAddSpouseButton(this.selectedOtherParties && this.selectedOtherParties.length,
        this.appConfig.getMaxNumberOfOtherParties());
    }
    return false;
  }

  lawSocietyMemberDirectoryLink(): string {
    return (this.matter && this.matter.provinceCode && !this.matter.isOpportunityMatter()) ? LawSocietyMemberDirectoryLinks[ this.matter.provinceCode ] : undefined;
  }

  notarySocietyMemberLink(): string {
    return NOTARY_SOCIETY_MEMBER_DIRECTORY_LINK;
  }

  addSuggestedSpouseAsClient(originalPurchaserWrapper: MatterParticipantWrapper, suggestedSpouse: Contact): void {
    const participant = this.matter.createMatterParticipant(originalPurchaserWrapper.matterParticipant.matterParticipantRole);
    //Create dummy contact
    participant.contact = new Contact();
    // participant.contact.initializeContactIdDetails();
    // originalPurchaserWrapper.matterParticipant.contact.updateSpouseContactWithReferenceContact(participant.contact);
    // AB and MB only use existing spouse relationship and can't create spouse relationship
    if (!this.matter.isMatterProvinceABorMB && !this.matter.isCustomMatter()) {
      originalPurchaserWrapper.matterParticipant.setFlaDefaultValuesForSuggestedSpouse(participant);
    }
    let spouseMatterParticipant: MatterParticipant = this.updateOnOtherSideClientCreated(originalPurchaserWrapper, suggestedSpouse, participant, true, true);
    let spouseWrapper: MatterParticipantWrapper = this.selectedOtherParties.find(item => item.matterParticipant == spouseMatterParticipant);
    if (spouseWrapper) {
      this.selectTab(defaultTab, spouseWrapper);
      if (!this.showWizardFields) {//Not to open the Tab shutter if it called from Wizard
        this.toggleContactSnapshot(spouseWrapper);
      }

    }

    this.updateOtherPartyFlaErrorMessage();
    this.checkCapacityWarningMessage(this.matter.otherPartiesCapacity);
  }

  addOtherPartySpouse(originalPurchaserWrapper: MatterParticipantWrapper): void {
    if (originalPurchaserWrapper && originalPurchaserWrapper.matterParticipant && originalPurchaserWrapper.matterParticipant.contact) {

      const participant = this.matter.createMatterParticipant(originalPurchaserWrapper.matterParticipant.matterParticipantRole);
      //Create dummy contact
      participant.contact = new Contact();
      participant.contact.initializeContactIdDetails();
      originalPurchaserWrapper.matterParticipant.contact.updateSpouseContactWithReferenceContact(participant.contact);
      originalPurchaserWrapper.setSpouseDefaultValues(participant);
      const outsideSpouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(participant, this.matter.otherSideClientType, this.matter);
      this.dialogService.matDialogContent({
        content: CommonContactDialogComponent,
        context: {
          matter: this.matter,
          matterParticipant: participant,
          flaStatements: this.flaStatements,
          spouseNameOptions: outsideSpouseNameOptions,
          referencePurchaser: originalPurchaserWrapper.matterParticipant.contact,
          isRelatedPurchaser: true,
          isOtherParty: true,
          contactType: 'CLIENT',
          cacheDocumentProfile: this.documentProfileCache.cachedDocumentProfile,
          matterParticipantRole: originalPurchaserWrapper.matterParticipant.matterParticipantRole
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action === 'save') {
              let newMatterParticipant: MatterParticipant = this.updateOnOtherSideClientCreated(originalPurchaserWrapper, result.contact, participant, result.createdNewClient, true);
              // Although there is not FLA section in AB UI when there  are two other side clients, it still can build
              // spouse relationship in fla
              if (this.matter.isMatterProvinceAB) {
                originalPurchaserWrapper.matterParticipant.addSpouseForAB(newMatterParticipant);
              } else {
                if (participant.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE')) {
                  if (Array.isArray(originalPurchaserWrapper.matterParticipant.familyLawActs)) {
                    originalPurchaserWrapper.matterParticipant.familyLawActs.forEach((fla: FamilyLawAct) => {
                      if (fla.familyLawActStatementType !== 'OTHER' && fla.familyLawActStatementType !== 'MATTER_PARTICIPANT_SPOUSE') {
                        originalPurchaserWrapper.matterParticipant.deleteFamilyLawAct(fla.familyLawActStatementType);
                      }
                    });
                  } else {
                    originalPurchaserWrapper.matterParticipant.addFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
                  }
                }
              }
              // this.updateAllSuggestions();
            } else {
              originalPurchaserWrapper.matterParticipant.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
            }

            this.updateOtherPartyFlaErrorMessage();
            this.checkCapacityWarningMessage(this.matter.otherPartiesCapacity);
          }
        },
        fullScreen: true,

      });
    }
  }

  updateOnOtherSideClientCreated(activePurchaserWrapper: MatterParticipantWrapper, contact: Contact, outsideParticipant: MatterParticipant, withCloning: boolean, isSpouse: boolean): MatterParticipant {
    let newMatterParticipant: MatterParticipant = this.buildOtherSideMatterParticipant(contact, outsideParticipant, withCloning, activePurchaserWrapper.matterParticipant.matterParticipantRole);
    if (isSpouse) {
      let matterParticipantWrapper: MatterParticipantWrapper
        = this.addOtherPartyWrapperForMatterParticipant(newMatterParticipant);
      this.matterParticipantService.updateParticipantWrapperState(matterParticipantWrapper, contact);
    } else {
      this.matterParticipantService.updateParticipantWrapperState(activePurchaserWrapper, contact);
    }
    // this.buildPurchaserStructures(); //Cheating here a bit, for the time being

    this.matter.reCalculateOtherPartyReLine();

    this.enableSave();
    return newMatterParticipant;
  }

  // If the matterParticipant is added from "Add Spouse", it has already created the matterParticipant before open "Add contact dialog"
  buildOtherSideMatterParticipant(contact: Contact, outsideParticipant: MatterParticipant, withCloning: boolean, participantRole: MatterParticipantRole, index?: number): MatterParticipant {
    let matterParticipant: MatterParticipant;
    if (outsideParticipant) {
      matterParticipant = new MatterParticipant(outsideParticipant);
      //Remove dummy contact and add real contact.
      matterParticipant.contact = null;
      matterParticipant.enrolContactToParticipant(contact, withCloning);
      if (index >= 0) {
        this.matter.matterParticipants.splice(index, 0, matterParticipant);
      } else {
        this.matter.matterParticipants.push(matterParticipant);
      }

    } else {
      matterParticipant = this.matter.addMatterParticipant(contact, withCloning, participantRole);
    }
    this.matterParticipantService.createMatterParticipantAssociatedContactForClient(contact, matterParticipant, this.matter, undefined, true);
    return matterParticipant;
  }

  private isResidingPpropertyNoABMBSKTabC(): boolean {
    return (this.matter && this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.residingAtSubjectProperty === 'NO') &&
      (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceMBorSK);
  }

  private getAddressForResidingPpropertyNoABMBSK(): string {
    return ((this.serviceAddress) ? (this.serviceAddress.addressTextWithProvinceName) : '__________________________');
  }

  get getTitleDetails(): string {
    if (!this.matter.otherPartyContactInfo.isTitleDetailsManual()) {
      if (this.matter.otherSideClients && this.matter.otherSideClients.length > 0 && !this.matter.otherPartyContactInfo.isTitleDetailsManual()) {
        if (this.matter.otherPartyContactInfo.individualTitleDetails) {
          if (this.matter.isMatterProvinceMBorSK) {
            TitleDetailsUtil.generateAndUpdateIndividualTitleDetailsSkMb(this.matter, this.matter.otherPartyContactInfo);
          }
          if (this.matter.isMatterProvinceAB) {
            MatterTitleDetailsUtil.generateAndUpdateIndividualTitleDetailsAB(this.matter, this.matter.otherPartyContactInfo);
          }
        } else {
          let clientNames = [];
          this.matter.otherSideClients.forEach(item => {
            let genericName = item.contact && item.contact.genericName ? item.contact.genericName.toUpperCase() : '';
            clientNames.push(this.matter.otherPartiesCapacity == 'OTHER' && item.purchaserShare ? genericName + ' ' + item.purchaserShare : genericName);
          });
          let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
          formattedClientName = formattedClientName + (this.matter.otherSideClients.length == 1 ? ' of ' : '');
          formattedClientName = formattedClientName + (this.matter.otherSideClients.length == 2 ? ', both of ' : '');
          formattedClientName = formattedClientName + (this.matter.otherSideClients.length == 3 ? ', all of ' : '');
          if (this.isResidingPpropertyNoABMBSKTabC()) {
            formattedClientName = formattedClientName + this.getAddressForResidingPpropertyNoABMBSK();
          } else {
            formattedClientName = formattedClientName
              + ((this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.address && !this.matter.matterPropertyWithCondo.address.isEmpty)
                ? (' ' + this.matter.matterPropertyWithCondo.address.addressTextWithProvinceName)
                : '__________________________');
          }
          if (this.matter.otherPartiesCapacity == 'JOINT_TENANTS' && this.matter.otherSideClients.length > 1) {
            formattedClientName = formattedClientName + ' as joint tenants';
          } else if (this.matter.otherPartiesCapacity == 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES' && this.matter.otherSideClients.length > 1) {
            formattedClientName = formattedClientName + ' as tenants in common';
          }

          this.matter.otherPartyContactInfo.titleDetails = formattedClientName;
        }
      } else {
        this.matter.otherPartyContactInfo.titleDetails = null;
      }
    }
    return this.matter.otherPartyContactInfo.titleDetails;
  }

  get lawClerkPlaceHolder(): string {
    if (this.showLawClerksDropDownArrow) {
      if (this.matter.provinceCode === PROVINCE_CODES.ALBERTA) {
        return 'Select a Legal Assistant from the list or manually enter';
      } else if (this.matter.provinceCode === PROVINCE_CODES.NOVA_SCOTIA) {
        return 'Select a Paralegal from the list or manually enter';
      } else if (this.matter.provinceCode === PROVINCE_CODES.BRITISH_COLOMBIA) {
        return 'Select an Assistant from the list or manually enter';
      } else if (this.userStateService.isDefaultProvinceBC()) {
        return 'Select an Assistant from the list or manually enter';
      } else {
        return 'Select a Law Clerk from the list or manually enter';
      }
      //return this.matter.provinceCode === PROVINCE_CODES.ALBERTA ? 'Select a Legal Assistant from the list or manually enter' : 'Select a Law Clerk from the list or manually enter';
    } else {
      if (this.matter.provinceCode === PROVINCE_CODES.ALBERTA) {
        return 'Manually enter Legal Assistant\'s Name';
      } else if (this.matter.provinceCode === PROVINCE_CODES.NOVA_SCOTIA) {
        return 'Manually enter Paralegal\'s Name';
      } else if (this.matter.provinceCode === PROVINCE_CODES.BRITISH_COLOMBIA) {
        return 'Manually enter an Assistant\'s Name';
      } else if (this.matter.provinceCode === PROVINCE_CODES.MANITOBA) {
        return 'Manually enter Legal Assistant\'s Name';
      } else if (this.matter.provinceCode === PROVINCE_CODES.SASKATCHEWAN) {
        return 'Manually enter Legal Assistant\'s Name';
      } else if (this.userStateService.isDefaultProvinceBC()) {
        return 'Manually enter an Assistant\'s Name';
      } else {
        return 'Manually enter Law Clerk\'s Name';
      }
      //return this.matter.provinceCode === PROVINCE_CODES.ALBERTA ? 'Manually enter Legal Assistant\'s Name' : 'Manually enter Law Clerk\'s Name';
    }
  }

  isOtherPartyParticipantDataReadOnly(selectedOtherParty: MatterParticipantWrapper): boolean {
    if (selectedOtherParty && selectedOtherParty.matterParticipant && selectedOtherParty.matterParticipant.contact) {
      if (!selectedOtherParty.matterParticipant.contact.sourceContactId) {
        return false;
      } else {
        return selectedOtherParty.isParticipantDataReadOnly;
      }
    } else {
      return false;
    }
  }

  isDocExecutedAtVisible(): boolean {
    if (this.matter) {
      if (this.matter.isProjectSale) {
        return true;
      } else if (this.matter.isSale && this.documentProfileCache && this.documentProfileCache.cachedDocumentProfile
        && this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile) {

        if (this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile.sameAsDefaultProfileFlag
          && this.documentProfileCache.cachedDefaultDocumentProfile && this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile) {

          return this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile.displayJurisdictionSalesTax == DpBooleanValueTypes.YES;
        } else {
          return this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile.displayJurisdictionSalesTax == DpBooleanValueTypes.YES;
        }

      }
      return true;
    }
  }

  getProjectSaleAddressLabel(): string {
    return this.isOtherPartyResideAtSubjectProperty() ? 'Purchaser\'s address<br>until closing' : 'Purchaser\'s Address<br>(Pre & Post-Closing)';
  }

  onDateChangeDocsExecutedOnDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.docsExecutedOn) {
      this.matter.docsExecutedOn = tmpDate;
      this.enableSave();
    }
  }

  changeOutOfProvince(): void {
    this.enableSave();
    if (this.matter.outOfProvinceVendorExecDocsAt) {
      this.selectedJurisdiction = null;
      this.matter.vendorExecDocsAt = null;
      this.matter.documentsExecutedAtJurisdictionId = null;
    }

  }

  uffiWarrantyCodeChanged(): void {
    this.matter.isPurchaseTypeValueChanged = false;
    this.enableSave();
  }

  isMassUpdateTab(): boolean {
    return this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMassUpdateSubType();
  }

  /**
   * If a solicitor is selected with shutter closed, those solicitor fields are showed up.
   * If a solicitor is selected with shutter opened, those solicitor fields should be disappeared and snap shot will show these fields
   * If a solicitor is not selected, those solicitor fields should be disappear
   * @param selectedSolicitor
   * @returns {MatterParticipantWrapper|MatterParticipant|Contact|boolean}
   */
  isSolicitorOnSectionVisible(selectedSolicitor: MatterParticipantWrapper) {
    return selectedSolicitor && selectedSolicitor.matterParticipant && selectedSolicitor.matterParticipant.contact && !this.isExpanded(selectedSolicitor);
  }

  hasOfferorsThatCanBeAddedAsPurchasers(): boolean {
    return this.matter.offerors.length > 0;
  }

  addAllOfferorsAsPurchasers(): void {
    if (this.selectedOtherParties && this.selectedOtherParties.length > 0 && this.selectedOtherParties.filter(otherParty => otherParty.dataModel).length > 0) {
      this.dialogService.confirm('Information', 'Please note that any Purchasers previously entered will be removed and replaced with the Offerors.', false)
      .subscribe(res => {
        if (res) {
          this.copyAllOfferorsToPurchasers();
        }
      });
    } else {
      this.copyAllOfferorsToPurchasers();
    }
  }

  /**
   * Remove any existing purchasers and copy all offerors to purchasers.
   */
  copyAllOfferorsToPurchasers(): void {
    this.enableSave();

    //delete existing purchasers
    let existingPurchaserWrappers = this.selectedOtherParties.filter(otherParty => otherParty.matterParticipant);
    existingPurchaserWrappers.forEach((purchaserWrapper: MatterParticipantWrapper) => {
      this.deleteOtherParty(purchaserWrapper);
    });

    //create a copy of each offeror and add to purchasers
    this.matter.offerors.forEach((offeror: MatterParticipant) => {
      let purchaserParticipant: MatterParticipant = new MatterParticipant();

      purchaserParticipant.contact = new Contact();
      purchaserParticipant.contact.createNewContactClone(offeror.contact);
      purchaserParticipant.contact.resetContactTypeInstanceType();
      purchaserParticipant.contact.sourceContactId = offeror.contact.sourceContactId;
      purchaserParticipant.contact.snapshotFlag = true;
      purchaserParticipant.matterParticipantRole = 'PURCHASER';
      purchaserParticipant.matterParticipantPriority = offeror.matterParticipantPriority;
      if (purchaserParticipant.matterParticipantPriority == 1) {
        purchaserParticipant.primary = true;
      }
      if (!this.matter.matterParticipants) {
        this.matter.matterParticipants = [];
      }
      this.matter.matterParticipants.push(purchaserParticipant);

      let childParticipants = this.matter.getChildMatterParticipants(offeror);
      childParticipants.forEach(p => {
        let associatedContactParticipant = this.matterParticipantService.createNewMatterSigningOfficerContact(p.contact, purchaserParticipant, true, this.matter);
        associatedContactParticipant.matterParticipantRole = purchaserParticipant.signingOfficerParticipantRole;
        associatedContactParticipant.associatedContactTitle = p.associatedContactTitle;
        associatedContactParticipant.parentParticipantId = purchaserParticipant.matterParticipantId;
        associatedContactParticipant.signingMatter = p.signingMatter;
        associatedContactParticipant.primary = p.primary;
        if (!associatedContactParticipant.contact.id) {
          associatedContactParticipant.contact.id = UUIDUtil.getUUID();
        }
        if (!purchaserParticipant.contact.sourceContactId) {
          purchaserParticipant.contact.contactAssociations = [];
        }

      });
    });

    this.propogateImportChangesAction();
    this.buildOtherPartiesStructure();
  }

  ngAfterViewInit() {
  }

  isMatterProvinceON_SK(): boolean {
    return this.matter && (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceON);
  }

  individualTitleDetailsChange(event) {
    if (event) {
      this.matter.otherPartyContactInfo.residingAtSubjectProperty = DpBooleanValueTypes.NO;
      this.residingAtSubjectPropertyChange();
    }
    this.enableSave();
  }

  isCreateNewClientSelected(): boolean {
    if (this.matter && this.matter.isOpportunityMatter()) {
      return this.matter && this.matter.isOpportunityPurchaseOrSale && this.matter.isActingForBothPurchaseAndSaleMatters;
    } else {
      return this.matter.isSolicitorActingForBoth && this.matter.isMatterProvinceAB && (this.matter.isSale || this.matter.isPurchase);
    }
  }

  handleLawClerkChanged(): void {
    let isDeleted = !this.matter.otherPartyContactInfo.lawClerkName;
    this.contactChangesListener.handleContactChange(this.matter, null, null, this.tabsStateService, isDeleted, this.matter.otherPartyContactInfo);
  }

  handleDeleteSolicitor(solicitor: MatterParticipant): void {
    this.contactChangesListener.handleContactChange(this.matter, solicitor, MatterParticipantRoleTypes.OTHERPARTY_SOLICITOR, this.tabsStateService, true);
  }

  isOpportunityActingForBoth(): boolean {
    return this.matter && this.matter.isOpportunityMatter() && this.matter.isActingForBothPurchaseAndSaleMatters;
  }

  buildOtherSideClientsBurgerMenu(participantWrapper: MatterParticipantWrapper, index: number): string[] {
    let menuItems = participantWrapper.getBurgerMenuItemsForMatterParticipant(index, this.matter.otherSideClients && this.matter.otherSideClients.length,
      this.isAddSpouseVisible(participantWrapper.matterParticipant));
    if (this.matter && participantWrapper.matterParticipant && participantWrapper.matterParticipant.contact && !participantWrapper.matterParticipant.contact.sourceContactId) {
      menuItems.push(SnapshotBurgerMenuActions.CONVERT_CONTACT);
    }
    return menuItems;
  }

  async convertMatterOnlyClientToContact(participantWrapper: MatterParticipantWrapper): Promise<void> {
    if (participantWrapper.matterParticipant.contact && (participantWrapper.matterParticipant.contact.lastName || participantWrapper.matterParticipant.contact.fullName)) {
      let results = await this.getSuggestedContacts(participantWrapper);
      if (!results || (results.length == 1 && results[ 0 ].displayName && results[ 0 ].displayName.indexOf(Constants.NO_RESULTS_FOUND) != -1)) {
        let toProceed = await this.dialogService.confirm('WARNING',
          'A new client contact record will be created. Press “Ok” to proceed.', false).toPromise();
        if (toProceed) {
          await this.convertToFullContact(participantWrapper);
        }
      } else {
        this.dialogService.matDialogContent({
          content: LegacyContactModalComponent,
          context: {
            suggestedContacts: results,
            isOpportunity: this.matter.isOpportunityMatter(),
            snapshotContact: participantWrapper.matterParticipant.contact
          },
          onFulfillment: (result) => {
            if (result) {
              this.convertToFullContact(participantWrapper, result.contact, result.topicNotes);
            }
          }
        });
      }
    }

  }

  async getSuggestedContacts(participantWrapper: MatterParticipantWrapper): Promise<any[]> {
    let genderFilter = [ 'QUESTION', 'MALE', 'FEMALE', 'MALEPOA', 'FEMALEPOA', 'ESTATE' ];
    let query = participantWrapper.matterParticipant.contact.lastName +
      (participantWrapper.matterParticipant.contact.firstName ? ', ' + participantWrapper.matterParticipant.contact.firstName : '');

    if (participantWrapper.matterParticipant.contact.isCorporationOrOtherEntity) {
      genderFilter = [ 'CORPORATION', 'OTHERENTITY' ];
      query = participantWrapper.matterParticipant.contact.fullName;
    }
    return this.purchaserService.getClientPurchaser(query, true, false, genderFilter, true).toPromise();
  }

  async convertToFullContact(participantWrapper: MatterParticipantWrapper, contact?: Contact, topicNotes?: string): Promise<void> {
    let updatedContact = await this.prepareContact(participantWrapper, contact, topicNotes);
    if (updatedContact) {
      let currentIndex = this.selectedOtherParties.findIndex(item => item == participantWrapper);
      this.addNewOtherParty(currentIndex);
      let matterParticipantWrapper = this.selectedOtherParties[ currentIndex ];
      matterParticipantWrapper.dataModel = {};
      matterParticipantWrapper.dataModel.id = updatedContact.id;
      matterParticipantWrapper.dataModel = {genericFullName: updatedContact.displayName, id: updatedContact.id};
      this.dataSelectedOtherParty(currentIndex, participantWrapper);
    }

  }

  async prepareContact(participantWrapper: MatterParticipantWrapper, contact?: Contact, topicNotes?: string): Promise<Contact> {
    if (contact) { //If a contact exists, get the full contact
      contact = await this.contactQueryService.getContactForMatter(contact.id).toPromise();
      if (!contact.isIndividual || contact.isMalePoaOrFemalePoa) {
        /*
                * If the selected contact is a “Male (Under power of attorney), “Female (Power of Attorney)”, “Estate”, “Corporation”, or “Other Entity”
                * AND it has one or more Legacy Signers populated
                * AND the number of Legacy Signers on the selected contact plus the number of Signers on the snapshot being converted is greater than 2
                * We show an error message
                *  */
        let totalNumberOfLegacySigners = 0;
        if (contact.additionalName1) {
          totalNumberOfLegacySigners++;
        }
        if (contact.additionalName2) {
          totalNumberOfLegacySigners++;
        }
        let snapShotSigners: MatterParticipant[] = this.getSignersForMatterParticipant(participantWrapper.matterParticipant);
        totalNumberOfLegacySigners += snapShotSigners.length;

        if (totalNumberOfLegacySigners > 2) {
          let errorMessage = `Before proceeding, please update the selected contact's ${ this.getSigningOfficerLabel(participantWrapper.matterParticipant.contact) } to full fledged contacts.`;
          this.dialogService.confirm('ERROR', errorMessage, true);
          return null;

        } else if (totalNumberOfLegacySigners && snapShotSigners.length) {
          if (contact.locked) {
            this.dialogService.confirm('ERROR', 'The contact is locked and cannot be converted.', true);
            return null;
          }
          contact = await this.updateExitingContactLegacySigners(snapShotSigners, contact);
        }
      }
      if (topicNotes) {
        this.saveSnapShotAddressToStickyNotes(topicNotes);
      }

    } else { // Create new contact from the snapshot
      contact = await this.createNewContactFromSnapshot(participantWrapper);
    }
    return contact;
  }

  getSigningOfficerLabel(contact: Contact): string {
    if (contact.isEstate) {
      return 'Trustees';
    }
    if (contact.isCorporationOrOtherEntity) {
      return 'Signing Officers';
    }
    return 'Power of Attorneys';
  }

  saveSnapShotAddressToStickyNotes(topicNotes: string): void {
    MatterUtil.AddToTopicNotes(this.matter, 'VENDOR_SOLICITOR', topicNotes);
  }

  async updateExitingContactLegacySigners(snapShotSigners: MatterParticipant[], contact: Contact): Promise<Contact> {
    snapShotSigners.forEach((signer) => {
      if (!contact.additionalName1) {
        contact.additionalName1 = signer.contact.fullName;
        contact.additionalEmail1 = signer.contact.email;
      } else {
        contact.additionalName2 = signer.contact.fullName;
        contact.additionalEmail2 = signer.contact.email;
      }
    });
    return await this.contactCommandService.updateContact(contact).toPromise();
  }

  async createNewContactFromSnapshot(participantWrapper: MatterParticipantWrapper): Promise<Contact> {
    let newContact: Contact = new Contact(participantWrapper.matterParticipant.contact);
    // homePhone, faxPhone and workPhone will create a related phone if the phone is null or undefined
    if (!newContact.isCorporation && !newContact.isOtherEntity) {//Corporation and Other Entity don't have homPphone
      newContact.getPhone(TelephoneTypes.home).telephoneNumber;
    }
    newContact.getPhone(TelephoneTypes.work).telephoneNumber;
    newContact.getPhone(TelephoneTypes.fax).telephoneNumber;
    if (Array.isArray(newContact.address)) {// Miss county default value
      newContact.address.forEach(item => item.country = 'Canada');
    }

    newContact.clearAllIds();
    newContact.snapshotFlag = false;
    if (this.matter && this.matter.isOpportunityMatter()) {
      newContact.contactCategory = 'PROSPECT';
    } else {
      newContact.contactCategory = 'OTHER_CONTACT';
    }
    if (!participantWrapper.matterParticipant.contact.isIndividual || participantWrapper.matterParticipant.contact.isMalePoaOrFemalePoa) {
      this.convertSnapshotSignersToLegacy(participantWrapper, newContact);
    }
    return await this.contactQueryService.createContact(newContact, null, true).toPromise();
  }

  convertSnapshotSignersToLegacy(participantWrapper: MatterParticipantWrapper, newContact: Contact): void {
    let signers = this.getSignersForMatterParticipant(participantWrapper.matterParticipant);
    if (signers && signers.length) {
      newContact.additionalName1 = signers[ 0 ].contact.firstLastNames;
      newContact.additionalEmail1 = signers[ 0 ].contact.email;
      newContact.titleOfOfficeHeld1 = signers[ 0 ].associatedContactTitle;
      if (signers.length > 1) {
        newContact.additionalName2 = signers[ 1 ].contact.firstLastNames;
        newContact.additionalEmail2 = signers[ 1 ].contact.email;
        newContact.titleOfOfficeHeld2 = signers[ 1 ].associatedContactTitle;
      }
    }
  }

  getSignersForMatterParticipant(matterParticipant: MatterParticipant): MatterParticipant[] {
    return this.matter && this.matter.matterParticipants && this.matter.matterParticipants.filter(mp => mp.parentParticipantId == matterParticipant.matterParticipantId);
  }

  setSolicitorType(sourceSolicitor: any) {
    if (this.userStateService.isDefaultProvinceBC() && sourceSolicitor) {
      if (sourceSolicitor.notaryFlag == 'LAWYER') {
        return provinceBasedFieldLabels.get('matter.title.solicitor.Lawyer', this.userStateService.defaultProvinceCode);
      } else {
        return provinceBasedFieldLabels.get('matter.title.solicitor.Notary', this.userStateService.defaultProvinceCode);
      }
    } else {
      return provinceBasedFieldLabels.get('matter.title.solicitor', this.userStateService.defaultProvinceCode);
    }
  }

  getSolicitorLabel() {
    return this?.matter?.isMatterProvinceBC ? 'Lawyer/Notary' : 'solicitor';
  }

  resolveClientSolicitorKey() {
    return this.matter?.directDepositInstructionOtherParty?.directDepositChoice === 'YES' && this.matter?.isMatterProvinceBC ?
      'lawyer/notary' :
      'solicitor';
  }

}
