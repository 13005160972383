import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {PurposeOfPayoutType} from '../../matters/mortgages/mortgage/assyst-payout-request-modal/assyst-payout-request.modal.component';
import {PayoutStatementStatus} from './payout-statement-status';
import * as _ from 'lodash';
import Utils from '../utils';
import moment from 'moment';

export const ApplicablePayoutInstitutions = [ {institutionName: 'BMO', institutionNo: '001'} ];

export class PayoutStatement extends BaseEntity {

  id: number;
  mortgageId;
  number;
  purposeOfPayout: PurposeOfPayoutType;
  purposeOfPayoutOther: string;
  acknowledgementOfConsent: string;
  vendorReferenceNumber: number;
  responseReceivedTimestamp: string;
  payoutStatementStatuses: PayoutStatementStatus[] = [];
  expiryDate: string;
  registrationDate: string;
  registrationNumbers: string[] = [];
  paymentState: string;

  constructor(p?: PayoutStatement) {
    super(p);
    if (p) {
      if (p.payoutStatementStatuses) {
        for (let s of p.payoutStatementStatuses) {
          let payoutStatementStatus: PayoutStatementStatus = new PayoutStatementStatus(s);
          this.payoutStatementStatuses.push(payoutStatementStatus);
        }
        if (p.registrationNumbers) {
          for (let s of p.registrationNumbers) {
            this.registrationNumbers.push(s);
          }
        }

      }
    }
  }

  get sortedPayoutStatementStatuses(): PayoutStatementStatus[] {
    return this.payoutStatementStatuses && this.payoutStatementStatuses.length ? _.sortBy(this.payoutStatementStatuses, [ 'statusReceivedTimestamp' ]) : [];
  }

  getCurrentStatusText(): string {
    return this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length ?
      this.sortedPayoutStatementStatuses[ this.sortedPayoutStatementStatuses.length - 1 ].statementStatusText : '';
  }

  getLatestPayoutStatementStatus(): PayoutStatementStatus {
    return this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length ?
      this.sortedPayoutStatementStatuses[ this.sortedPayoutStatementStatuses.length - 1 ] : null;
  }

  getMostRecentActiveDocument(): PayoutStatementStatus {
    if (this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length) {
      for (var i = this.sortedPayoutStatementStatuses.length - 1; i >= 0; i--) {
        if (this.sortedPayoutStatementStatuses[ i ].isAuthorizationDischarge() || (this.sortedPayoutStatementStatuses[ i ].thirdPartyDocumentId && !this.sortedPayoutStatementStatuses[ i ].thirdPartyDocumentDeleted)) {
          return this.sortedPayoutStatementStatuses[ i ];
        }
      }
    }
  }

  isPayoutCancellationAllowed(): boolean {
    return this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length &&
      this.sortedPayoutStatementStatuses[ this.sortedPayoutStatementStatuses.length - 1 ].isPayoutCancellationAllowed();
  }

  isCancelled(): boolean {
    //If the last status was cancelled
    return this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length
      && this.sortedPayoutStatementStatuses[ this.sortedPayoutStatementStatuses.length - 1 ].isCancelled();
  }

  isReturnedToSolicitor(): boolean {
    //If the last status was cancelled
    return this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length
      && this.sortedPayoutStatementStatuses[ this.sortedPayoutStatementStatuses.length - 1 ].isReturnedToSolicitor();
  }

  isPayoutRequestSent(): boolean {
    return this.sortedPayoutStatementStatuses && this.sortedPayoutStatementStatuses.length
      && this.sortedPayoutStatementStatuses[ this.sortedPayoutStatementStatuses.length - 1 ].isPayoutRequestSent();
  }

  formattedExpiryDate(): string {
    return Utils.formatDateString(this.expiryDate, true, '');

  }

  isPayoutStatementExpired(): boolean {
    let today = moment(new Date()).format('YYYY/MM/DD');
    return moment(this.expiryDate, 'YYYY/MM/DD').isBefore(today);
  }
}
