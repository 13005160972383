import {Injectable} from '@angular/core';
import {MatterTopic} from '../../../shared/matter-topic';
import {StickyNotesModal} from '../../../../shared-main/sticky-notes/sticky-notes.modal.component';
import {Matter} from '../../../shared';
import {Cirf} from '../../../shared/cirf/cirf';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import Utils from '../../../../shared-main/utils';
import {CirfMortgage} from '../../../shared/cirf/cirf-mortgage';

@Injectable()
export class CirfImportStickyNotesHelperService {

  constructor(public dialogService: DialogService) {
  }

  openStickyNotesModal(matterTopic: MatterTopic): void {
    this.dialogService.matDialogContent({
      content: StickyNotesModal,
      context: {
        notes: matterTopic.topicNote
      },
      onFulfillment: (result) => {
        if (result && result.status === 'OK') {
          matterTopic.topicNote = result.notes;
        } else if (result && result.status === 'DELETE') {
          matterTopic.topicNote = '';
        }
      }
    });
  }

  get notesHeader() {
    return '\nFrom Client Information Request Form';
  }

  appendDataToMatterTopics(matter: Matter, cirf: Cirf) {
    this.appendDataToMatterTopic(matter.mainClientTopic, this.getMainClientNotes(cirf));
    if (!matter.isSale) {
      this.appendDataToMatterTopic(matter.matterInsuranceTopic, this.getMatterInsuranceNotes(cirf));
    }
    this.appendDataToMatterTopic(matter.newMortgageTopic, this.getMortgageNotes(cirf.cirfMatterData.newMortgages, cirf.cirfMatterData.mortgageNotes));
    this.appendDataToMatterTopic(matter.existingMortgageTopic, this.getMortgageNotes(cirf.cirfMatterData.existingMortgages, cirf.cirfMatterData.existingMortgageNotes));
  }

  appendDataToMatterTopic(matterTopic: MatterTopic, notesData: string): void {
    if (matterTopic) {
      if (!matterTopic.topicNote) {
        matterTopic.topicNote = '';
      }
      if (matterTopic.topicNote.indexOf(notesData) < 0) {
        if (matterTopic.topicNote.length > 0) {
          matterTopic.topicNote += '\n';
        }
        matterTopic.topicNote += notesData;
      }
    }

  }

  getMainClientNotes(cirf: Cirf): string {
    let notes = '';
    if (cirf.cirfMatterData && cirf.cirfMatterData.cirfClients && cirf.cirfMatterData.cirfClients.length) {
      notes += this.notesHeader;
      cirf.cirfMatterData.cirfClients.forEach(client => {
        notes += `\nClient Profile - ${ client.firstName } ${ client.lastName }`;
        if (cirf && cirf.cirfMatterData.isPurchase() && client.firstTimeHomeBuyer) {
          notes += '\n' + `First time home owner? ${ client.firstTimeHomeBuyer }\n`;
        }
        if (client.udfNotes()) {
          notes += `\n${ client.udfNotes() }\n`;
        }
        if (client.identifications && client.identifications.length > 0 && client.identifications[ 0 ].udfNotes()) {
          notes += `Primary Identification Verification\n${ client.identifications[ 0 ].udfNotes() }\n`;
        }
        if (client.identifications && client.identifications.length > 1 && client.identifications[ 1 ].udfNotes()) {
          notes += `Secondary Identification Verification\n${ client.identifications[ 1 ].udfNotes() }\n`;
        }
      });
    }
    if (cirf.cirfMatterData.matterContactInfo && cirf.cirfMatterData.matterContactInfo.matterContactInfoUdfs && cirf.cirfMatterData.matterContactInfo.matterContactInfoUdfs.length) {
      if (!notes) {
        notes += this.notesHeader;
      }
      notes += '\nMatter Contact Info';
      notes += '\n' + cirf.cirfMatterData.matterContactInfo.udfNotes();
    }
    return notes;
  }

  getMatterInsuranceNotes(cirf: Cirf): string {
    let notes = '';
    notes += this.notesHeader;
    if (cirf.cirfMatterData.insuranceNotes && cirf.cirfMatterData.insuranceNotes.length > 0) {
      notes += '\n\n' + 'Insurance Notes: ' + cirf.cirfMatterData.insuranceNotes;
    }
    notes += '\nINSURANCE INFORMATION';
    if (cirf.cirfMatterData.fireInsuranceDetail.companyName) {
      notes += '\nCompany: ' + cirf.cirfMatterData.fireInsuranceDetail.companyName;
      notes += '\nPolicy No: ' + cirf.cirfMatterData.fireInsuranceDetail.policyNumber;
      notes += '\nBroker Name: ' + cirf.cirfMatterData.fireInsuranceDetail.insuranceBroker.name;
      notes += '\nBroker phone: ' + cirf.cirfMatterData.fireInsuranceDetail.insuranceBroker.workPhone;
      notes += '\nBroker email: ' + cirf.cirfMatterData.fireInsuranceDetail.insuranceBroker.email;
    }
    if (Array.isArray(cirf.cirfMatterData.fireInsuranceDetail.insuranceUdfs) && cirf.cirfMatterData.fireInsuranceDetail.insuranceUdfs.length) {
      if (!notes) {
        notes += this.notesHeader;
      }
      notes += '\nInsurance Details';
      notes += '\n' + cirf.cirfMatterData.fireInsuranceDetail.udfNotes();
    }
    return notes;
  }

  getMortgageNotes(mortgages: CirfMortgage[], cirfNotes: string): string {
    let notes = '';
    if (Array.isArray(mortgages) && mortgages.length) {
      notes += this.notesHeader;
      if (cirfNotes) {
        notes += '\n\n' + 'Mortgage Notes: ' + cirfNotes;
      }
      mortgages.forEach((cirfMortgage, index) => {
        notes += '\n\n' + Utils.getOrdinal(index + 1) + ' MORTGAGE INFORMATION';
        notes += cirfMortgage.bankName ? '\nBank Name: ' + cirfMortgage.bankName : '';
        notes += cirfMortgage.referenceNumber ? '\nRef. No: ' + cirfMortgage.referenceNumber : '';
        notes += cirfMortgage.mortgageBroker.name ? '\nBroker Name: ' + cirfMortgage.mortgageBroker.name : '';
        notes += cirfMortgage.mortgageBroker.workPhone ? '\nBroker phone: ' + cirfMortgage.mortgageBroker.workPhone : '';
        notes += cirfMortgage.mortgageBroker.email ? '\nBroker email: ' + cirfMortgage.mortgageBroker.email : '';
        notes += cirfMortgage.udfNotes() ? '\n' + cirfMortgage.udfNotes() : '';
      });
    }
    return notes;
  }
}
