import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GoCardlessService} from './gocardless.service';

@Component({
  selector: 'app-gocardless-redirect',
  template: `<em style="display: inline-block; margin: 2rem;">Getting GoCardless access token...</em>`
})
export class GoCardlessRedirectComponent implements OnInit {
  private code?: string;

  constructor(private route: ActivatedRoute, private router: Router, private goCardlessService: GoCardlessService) {
  }

  public ngOnInit(): void {
    const postAuthRedirectUrl = this.goCardlessService.postAuthRedirectUrl;

    this.route.queryParams.subscribe((params: any) => {
      this.code = <string>params[ 'code' ];
      this.goCardlessService
      .exchangeCodeForToken(this.code)
      .subscribe({
        next: accessToken => {
          this.router.navigate([ postAuthRedirectUrl ]);
        },
        error: error => {
          this.router.navigate([ postAuthRedirectUrl ]);
          console.error(error);
        }
      });
    });
  }
}
