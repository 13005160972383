import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ImportTemplate extends BaseEntity {

  templates: TemplateData[] = [];

}

export class TemplateData extends BaseEntity {
  existingTemplateId: number;
  systemTemplateId: number;

}
