import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CurrencyPipe} from '@angular/common';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Matter} from '../../../shared/matter';
import {Mortgage} from '../../../shared/mortgage';
import * as _ from 'lodash';
import {KeyCode} from '../../../../shared-main/constants';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class MortgagePriorityModalContext {
  matter: Matter;
  updateMortgagePriority: boolean;
  isExistingMortgageActive: boolean;
  selectedMortgage: Mortgage;
}

@Component({
  selector: 'mortgage-priority-modal-content',
  templateUrl: 'mortgage-priority.modal.component.html',
  providers: [ CurrencyPipe, ErrorService ]

})
export class MortgagePriorityModal extends ModalComponent<MortgagePriorityModalContext> implements OnInit {
  mortgagePriority: any[] = [];
  mortgagePriorityItems: any[] = [];
  priority: number;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(public dialog: MatDialogRef<MortgagePriorityModal>,
              @Inject(MAT_DIALOG_DATA) context?: MortgagePriorityModalContext
  ) {
    super(dialog, context);
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get updateMortgagePriority(): boolean {
    return this.context.updateMortgagePriority;
  }

  ngOnInit(): void {
    this.mortgagePriorityItems = [];
    this.mortgagePriority = this.matter.availableMortgagePriority(this.priority);
    this.priority = Math.min(...this.mortgagePriority.map(item => {
      return Number(item.value);
    }));
    if (this.updateMortgagePriority) {
      let mortgagePriories = _.orderBy(this.matter.mortgageListAfterClosing(), [ 'mortgagePriority' ], [ 'asc' ]);
      if (mortgagePriories) {
        let maxPriority = Math.max(...mortgagePriories.map(item => {
          return Number(item.mortgagePriority);
        }));
        for (let i = 1; i <= maxPriority; i++) {
          let mortgage = mortgagePriories.find(item => item.mortgagePriority == i);
          this.mortgagePriorityItems.push({
            'mortgage': new Mortgage(mortgage),
            'isSelected': this.context.selectedMortgage && mortgage && (mortgage.isExistingMortgage() == this.context.isExistingMortgageActive) ? this.context.selectedMortgage.id == mortgage.id : false
          });
        }
      }
      setTimeout(() => {
        this.checkPriorityWarnings();
      }, 100);

    }

  }

  close(): void {
    this.dialog.close();
  }

  ok(): void {
    this.dialog.close(this.priority);
  }

  updatePriority(): void {
    this.dialog.close(this.mortgagePriorityItems);
  }

  getMortgagees(mortgage: Mortgage): string {
    let matterParticipant = [];
    if (this.matter && mortgage) {
      //if matterTyps is SALE, then changePriority currently only available for 'VTB Mortgage(s);, and mortgagee is Private Lender
      const isSaleMatter = this.matter.matterType === 'SALE';
      let matterParticipants = isSaleMatter ? this.matter.getPrivateLenders(mortgage) : this.matter.getMortgagees(mortgage);
      if (matterParticipants) {
        matterParticipant = matterParticipants.map(item => {
          if (item.contact) {
            return item.contact.genericFullName;
          }
        });
      }
    }
    return matterParticipant.join(' ');
  }

  keyUpSelectedItem(event: any, selectedMortgage: any): void {
    if (event.keyCode === KeyCode.Tab) {
      this.selectedItem(selectedMortgage);
    }
  }

  selectedItem(selectedMortgage: any): void {
    setTimeout(() => {
      for (let i: number = 0; i < this.mortgagePriorityItems.length; i = i + 1) {
        this.mortgagePriorityItems[ i ].isSelected = false;
      }
      selectedMortgage.isSelected = !selectedMortgage.isSelected;
    }, 100);
  }

  getIndexToMoveUp(selectedMortgageIndex: number): number {
    let upMortgageIndex: number = -1;
    if (selectedMortgageIndex > 0) {
      for (let i: number = selectedMortgageIndex - 1; i >= 0; i--) {
        let upMortgage: any = this.mortgagePriorityItems[ i ];
        if (upMortgage.mortgage && !upMortgage.mortgage.isEmpMortgage()) {
          upMortgageIndex = i;
          break;
        }
      }
    }
    return upMortgageIndex;
  }

  moveUp(): void {
    let selectedMortgageIndex: number = _.findIndex(this.mortgagePriorityItems, mortgagePriorityItem => mortgagePriorityItem.isSelected === true);
    let upMortgageIndex: number = this.getIndexToMoveUp(selectedMortgageIndex);
    if (upMortgageIndex >= 0) {
      let upMortgage: any = this.mortgagePriorityItems[ upMortgageIndex ];
      let downMortgage: any = this.mortgagePriorityItems[ selectedMortgageIndex ];
      if (upMortgage.mortgage && downMortgage.mortgage) {
        upMortgage.mortgage.mortgagePriority = Number(upMortgage.mortgage.mortgagePriority) + (selectedMortgageIndex - upMortgageIndex);
        downMortgage.mortgage.mortgagePriority = Number(downMortgage.mortgage.mortgagePriority) - (selectedMortgageIndex - upMortgageIndex);
      }
      this.mortgagePriorityItems[ upMortgageIndex ] = downMortgage;
      this.mortgagePriorityItems[ selectedMortgageIndex ] = upMortgage;
      this.removeLastEmptyMortgage();
      this.checkPriorityWarnings();
    }
    this.matter.dirty = true;
  }

  removeLastEmptyMortgage(): void {
    if (this.mortgagePriorityItems.length > 0) {
      let mortgageItem = this.mortgagePriorityItems[ this.mortgagePriorityItems.length - 1 ];
      if (mortgageItem && mortgageItem.mortgage && !mortgageItem.mortgage.mortgageType) {
        (<any>this.mortgagePriorityItems).remove(mortgageItem);
      }
    }
  }

  getIndexToMoveDown(selectedMortgageIndex: number): number {
    let downMortgageIndex: number = -1;
    if (selectedMortgageIndex > -1 && selectedMortgageIndex !== this.mortgagePriorityItems.length - 1) {
      for (let i: number = selectedMortgageIndex + 1; i <= this.mortgagePriorityItems.length - 1; i++) {
        let downMortgage: any = this.mortgagePriorityItems[ i ];
        if (downMortgage.mortgage && !downMortgage.mortgage.isEmpMortgage()) {
          downMortgageIndex = i;
          break;
        }
      }
    }
    return downMortgageIndex;
  }

  moveDown(): void {
    let selectedMortgageIndex: number = _.findIndex(this.mortgagePriorityItems, mortgagePriorityItem => mortgagePriorityItem.isSelected === true);
    let downMortgageIndex: number = this.getIndexToMoveDown(selectedMortgageIndex);
    if (downMortgageIndex > -1 && downMortgageIndex <= this.mortgagePriorityItems.length - 1) {
      let downMortgage: any = this.mortgagePriorityItems[ downMortgageIndex ];
      let upMortgage: any = this.mortgagePriorityItems[ selectedMortgageIndex ];
      if (downMortgage.mortgage && upMortgage.mortgage) {
        downMortgage.mortgage.mortgagePriority = Number(downMortgage.mortgage.mortgagePriority) - (downMortgageIndex - selectedMortgageIndex);
        upMortgage.mortgage.mortgagePriority = Number(upMortgage.mortgage.mortgagePriority) + (downMortgageIndex - selectedMortgageIndex);
      }
      this.mortgagePriorityItems[ downMortgageIndex ] = upMortgage;
      this.mortgagePriorityItems[ selectedMortgageIndex ] = downMortgage;
      this.removeLastEmptyMortgage();
      this.checkPriorityWarnings();
    }
    this.matter.dirty = true;
  }

  get selectedIndex(): number {
    return _.findIndex(this.mortgagePriorityItems, item => item.isSelected === true);
  }

  get selectedMortgageFromList(): Mortgage {
    if (this.selectedIndex >= 0) {
      return this.mortgagePriorityItems[ this.selectedIndex ].mortgage;
    }
  }

  checkPriorityWarnings(): void {

    this.modalErrorComponent.removeAllDpFieldError();
    if (this.mortgagePriorityItems) {
      this.mortgagePriorityItems.forEach((item, i) => {
        if (item.mortgage && !item.mortgage.mortgageType) {
          this.modalErrorComponent.createCustomDPFieldError('matter.newMortgage.mortgage.priority.' + i, 'A mortgage with priority ' + (Number(i) + 1) + ' is' +
            ' missing', '', 'WARNING');
        }
      });
    }
  }

  get isMoveUpDisabled(): boolean {
    if (this.selectedIndex === 0 || this.selectedIndex === -1 || (this.selectedMortgageFromList && this.selectedMortgageFromList.isEmpMortgage())) {
      return true;
    } else if (this.selectedIndex === 1) {
      let firstItem: any = this.mortgagePriorityItems[ 0 ];
      if (firstItem.mortgage && firstItem.mortgage.isEmpMortgage()) {
        return true;
      }
    }
    return false;
  }

  get isMoveDownDisabled(): boolean {
    if (this.selectedIndex === (this.mortgagePriorityItems.length - 1) || this.selectedIndex === -1 ||
      (this.selectedMortgageFromList && this.selectedMortgageFromList.isEmpMortgage())) {
      return true;
    } else if (this.selectedIndex === (this.mortgagePriorityItems.length - 2)) {
      let lastItem: any = this.mortgagePriorityItems[ this.mortgagePriorityItems.length - 1 ];
      if (lastItem.mortgage && lastItem.mortgage.isEmpMortgage()) {
        return true;
      }
    }
    return false;
  }
}
