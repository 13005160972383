import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import * as _ from 'lodash';
import {TelephoneTypes} from './telephone-types';

const propertyIgnoreList = [ 'id' ];

export class Telephone extends BaseEntity {

  static readonly PHONE_NUMBER_LENGTH_WITHOUT_CODE: number = 10;
  static readonly PHONE_NUMBER_LENGTH_WITH_CODE: number = 11;
  static readonly COUNTRY_CODE_PREFIX: string = '1';

  constructor(telephone?: Telephone) {
    super(telephone);
    if (telephone) {
      for (let prop in telephone) {
        if (telephone.hasOwnProperty(prop)) {
          this[ prop ] = telephone[ prop ];
        }
      }
    }
  }

  id?: string;

  telephoneNumber: string;

  phoneTypeCode: string;

  isPrimaryTelephone?: boolean;

  /** UI only field, temporarily stores UI value when the user is working with different phone options */
  localValue: string;

  get phoneWithLabel(): string {
    let label = '';
    switch (this.phoneTypeCode) {
      case 'HOME':
        label = 'H: ';
        break;
      case 'WORK':
        label = 'W: ';
        break;
      case 'MOBILE':
        label = 'C: ';
        break;
      case 'FAX':
        label = 'F: ';
        break;

    }
    return label + this.telephoneNumber;
  }

  static update(source: Telephone, target?: Telephone): Telephone {
    if (source) {
      if (!target) {
        target = new Telephone();
      }
      target.telephoneNumber = source.telephoneNumber;
      target.phoneTypeCode = source.phoneTypeCode;
      target.isPrimaryTelephone = source.isPrimaryTelephone;

      return target;
    }

    return undefined;
  }

  static isPhoneValid(phoneNumber: string) {
    if (!phoneNumber) {
      return false;
    }

    phoneNumber = phoneNumber.replace(/\D/g, '');

    if (phoneNumber.length == Telephone.PHONE_NUMBER_LENGTH_WITHOUT_CODE) {
      phoneNumber = Telephone.COUNTRY_CODE_PREFIX + phoneNumber;
    }

    if (!phoneNumber.startsWith('1')) {
      return false;
    }

    return phoneNumber.length == Telephone.PHONE_NUMBER_LENGTH_WITH_CODE;
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }

  toStringForComparison(): string {
    return 'Type:' + this.phoneTypeCode + 'Number:' + this.telephoneNumber + 'isPrimary:' + this.isPrimaryTelephone;
  }

  get capitalizedPhoneType(): string {
    if (this.phoneTypeCode) {
      return _.capitalize(this.phoneTypeCode);
    }
    return this.phoneTypeCode;
  }

  get isWorkPhone(): boolean {
    return this.phoneTypeCode === TelephoneTypes.work;
  }

  get isCellPhone(): boolean {
    return this.phoneTypeCode === TelephoneTypes.cell;
  }

  get isHomePhone(): boolean {
    return this.phoneTypeCode === TelephoneTypes.home;
  }

  get isFaxPhone(): boolean {
    return this.phoneTypeCode === TelephoneTypes.fax;
  }
}
