<form class="form-horizontal container-fluid custom-modal-container" id="tarionAdjForm" *ngIf="localAdj" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : HCRA Regulatory Oversight Fee</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Credit {{context.statementAdjustmentDisplayUtil.vendorLabel}},
                        {{context.statementAdjustmentDisplayUtil.purchaserLabel}} or No Adjustment?</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="creditType"
                            name="creditType"
                            [(ngModel)]="localAdj.creditType"
                            class="form-control">
                        <option *ngFor="let cto of creditTypeOptions" [value]="cto.value">
                            {{cto.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Enrolment date</label>
                </div>
                <div class="col-lg-5">
                    <select type="text"
                            id="enrolmentPeriodId"
                            name="enrolmentPeriodId"
                            [(ngModel)]="localAdj.enrolmentPeriodId"
                            class="form-control"
                    >
                        <option *ngFor="let epo of enrolmentPeriodOptions" [value]="epo.value">
                            {{epo.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Builder's registration number (optional)</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="builderRegistrationNumber"
                           name="builderRegistrationNumber"
                           [(ngModel)]="localAdj.builderRegistrationNumber"
                           class="form-control"
                           maxlength="38"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Enrolment number for the subject property (optional)</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="enrolmentNumber"
                           name="enrolmentNumber"
                           [(ngModel)]="localAdj.enrolmentNumber"
                           class="form-control"
                           maxlength="38"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Applicable section no. of Agreement (optional)</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="applicableSection"
                           name="applicableSection"
                           [(ngModel)]="localAdj.applicableSection"
                           class="form-control"
                           maxlength="38"
                    />
                </div>
            </div>
        </div>

        <div class="form-group col-lg-12">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">{{context.taxRateType}} percentage is</label>
                </div>
                <div class="col-lg-1">
                    <input type="text"
                           id="hstPercentage"
                           name="hstPercentage"
                           [(ngModel)]="localAdj.hstRate"
                           [dp-read-only]="localAdj.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'"
                           class="form-control"
                           dp-percentage
                           padding-zero="true"

                    />
                </div>
                <div class="col-lg-1">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Is amount additional consideration to vendor ?</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="amountAdditionalConsiderationToVendor"
                            name="amountAdditionalConsiderationToVendor"
                            [(ngModel)]="localAdj.amountAdditionalConsiderationToVendor"
                            class="form-control"
                    >
                        <option *ngFor="let aacvo of amountAdditionalConsiderationToVendorOptions" [value]="aacvo.value">
                            {{aacvo.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>


        <!-- adjustment report -->
        <div style="border-top: 1px solid #cccccc;">
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">
                        {{localAdj.adjustmentDescription}}
                        <!--
                        <span *ngIf="localAdj.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'">
                            <sup>1</sup>
                        </span>
                        <span *ngIf="localAdj.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'">
                            <sup>2</sup>
                        </span>
                        -->

                    </label>
                </div>
            </div>

            <div *ngFor="let line of details">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                    <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                </div>
            </div>

            <div *ngIf="localAdj.creditType == 'VENDOR'" class="col-lg-12">
                <label class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentDisplayUtil.vendorLabel}}</label>
                <label class="control-label col-lg-6">{{totalAmount | currency:CAD:'symbol'}}</label>

            </div>
            <div *ngIf="localAdj.creditType == 'PURCHASER'" class="col-lg-12">
                <label class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentDisplayUtil.purchaserLabel}}</label>
                <label class="control-label col-lg-4">{{totalAmount | currency:CAD:'symbol'}}</label>
            </div>
            <div *ngIf="localAdj.creditType == 'NO_ADJUSTMENT'" class="col-lg-12">
                <label class="control-label col-lg-3 text-left">No Adjustment</label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="cancel()">
                <span>Cancel</span>
            </button>
            <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                <span>Delete</span>
            </button>

        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
