import {ProgressionStatus, StatementAdjustment} from './statement-adjustment';
import {StatementAdjustmentDisplayTarionWarrantyBuilder} from './builders/statement-adjustment-display-tarion-warranty-builder';
import {StatementAdjustmentDisplayBuilder} from './builders/statement-adjustment-display-builder';
import {ItemizedCreditToType, SalePriceAdjustment} from './sale-price-adjustment/sale-price-adjustment';
import {AdditionalVendorConsiderationType} from './model/so-adj-tarion-warranty';
import {StatementOfAdjustmentHeading} from './model/statement-adjustment-heading';
import {SoaCommonExpense} from './model/common-expense-soa';
import {StatementAdjustmentKey} from './statement-adjustment-constants';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {MatterTax} from '../shared/property-taxes';
import {Matter} from '../shared';
import {ProjectAdjustmentConfig} from '../../projects/project-adjustments/project-adjustment-config';
import {Project} from '../../projects/shared/project';
import {SoAdjOtherFixed} from './model';
import {
  AdditionalConsiderationAmountType,
  AdditionalConsiderationAmountTypes
} from './modals/consideration-paid-on-interim-closing/so-adj-consideration-paid-on-interim-closing';
import {OtherFixedCreditTypes} from './model/so-adj-other-fixed';
import {creditPurchaserWith} from './model/so-adj-drop-downs';
import {SoAdjAmountPaidOnInterimClosing} from './modals/amount-paind-on-interim-closing/so-adj-amount-paid-on-interim-closing';
import Utils from '../../shared-main/utils';
import {StatementAdjustmentDisplayHcraFeeBuilder} from './builders/statement-adjustment-display-hcra-fee-builder';

// if this gets too big consider moving to a helper class like SoaTrustLedgerCollection
// as of now there is only one dependency between TarionWarranty and SalePrice type of adjustments and SalePriceAdjustment is not even in matter.statementOfAdjustments (matter.considerationLtt.salePriceAdjustment)
export class StatementAdjustmentUtil {

  static anyTarrionAdjustments(statementOfAdjustments: StatementAdjustment[]) {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isTarionWarranty());
  }

  static anyHCRAAdjustments(statementOfAdjustments: StatementAdjustment[]) {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isHCRAFee());
  }

  // Is amount additional consideration to vendor == NO
  static isTarionWarrantyAdjAvailableAndWithConsideration(statementOfAdjustments: StatementAdjustment[]): boolean {
    return this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments) ||
      this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments);
  }

  static isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isTarionWarrantyWithAdditionalConsiderationToVendorEligibleForTaxRebate());
  }

  static isTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes());
  }

  // Is amount additional consideration to vendor == NO
  static isHCRAAdjAvailableAndWithConsideration(statementOfAdjustments: StatementAdjustment[]): boolean {
    return this.isHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments) ||
      this.isHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments);
  }

  static isHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isHCRAWithAdditionalConsiderationToVendorEligibleForTaxRebate());
  }

  static isHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isHCRAWithAdditionalConsiderationToVendorNotEligibleForTaxRebate());
  }

  //Checks if Itemized Adjustment already added with creditsTo as 'VENDOR' AND automaticallyInsertTotal as 'YES'.
  static isItemizedAdjAvailableWithConsideration(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isItemizedCreditToVendorPurchaser() && this.isItemizedVendorAdjWithConsideration(adj));
  }

  //Checks if Itemized Adjustment already added with creditsTo as 'VENDOR' or 'PURCHASER' AND automaticallyInsertTotal as 'YES'.
  static isItemizedAdjAvailableWithConsiderationVedorPurchase(statementOfAdjustments: StatementAdjustment[], creditsTo: ItemizedCreditToType): boolean {
    if (statementOfAdjustments && creditsTo) {
      if (creditsTo === 'VENDOR') {
        return statementOfAdjustments.some(adj => adj.isItemizedCreditToVendorPurchaser() && this.isItemizedVendorAdjWithConsideration(adj));
      } else {
        return statementOfAdjustments.some(adj => adj.isItemizedCreditToVendorPurchaser() && this.isItemizedPurchaserAdjWithConsideration(adj));
      }
    }
    return false;
  }

  //Checks if Itemized Adjustment already added with creditsTo as 'PURCHASER' AND automaticallyInsertTotal as 'YES'.
  static isItemizedAdjAvailableWithConsiderationForPruchaser(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isItemizedCreditToVendorPurchaser() && this.isItemizedPurchaserAdjWithConsideration(adj));
  }

  static isItemizedVendorAdjWithConsideration(adj: StatementAdjustment): boolean {
    return adj.isItemizedCreditToVendorPurchaser() && adj.soAdjItemizedCreditToVendorPurchaser
      && adj.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'VENDOR'
      && adj.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES';
  }

  static isItemizedPurchaserAdjWithConsideration(adj: StatementAdjustment): boolean {
    return adj.isItemizedCreditToVendorPurchaser() && adj.soAdjItemizedCreditToVendorPurchaser
      && adj.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'PURCHASER'
      && adj.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES';
  }

  static getItemizedVendorAdjAvailableWithConsideration(statementOfAdjustments: StatementAdjustment[]): StatementAdjustment {
    return statementOfAdjustments && statementOfAdjustments.find(adj => adj.isItemizedCreditToVendorPurchaser() && this.isItemizedVendorAdjWithConsideration(adj));
  }

  static getItemizedPurchaserAdjAvailableWithConsideration(statementOfAdjustments: StatementAdjustment[]): StatementAdjustment {
    return statementOfAdjustments && statementOfAdjustments.find(adj => adj.isItemizedCreditToVendorPurchaser() && this.isItemizedPurchaserAdjWithConsideration(adj));
  }

  static isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isTarionWarrantyWithAdditionalConsiderationToVendorNotEligibleForTaxRebate());
  }

  static isTarionNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isTarioNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes());
  }

  static isAnyAdjustmentWithConsideration(statementOfAdjustments: StatementAdjustment[]): boolean {
    return this.anyOtherFixedAdjustmentsWithAnyConsideration(statementOfAdjustments) || this.isTarionWarrantyAdjAvailableAndWithConsideration(statementOfAdjustments)
      || this.isHCRAAdjAvailableAndWithConsideration(statementOfAdjustments);
  }

  static anyOtherFixedAdjustmentsWithAnyConsideration(statementOfAdjustments: StatementAdjustment[]) {
    return statementOfAdjustments && statementOfAdjustments
    .some(adj => (adj.isOtherFixed() && adj.soAdjOtherFixed.amountAdditionalConsiderationToVendor != 'NO') ||
      ((adj.isOtherFixedPayableOnOccupancy() && adj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor != 'NO')) ||
      ((adj.isOtherProratedOnPercentageInterest() && adj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor != 'NO'))
    );
  }

  static anyOtherFixedAdjustmentsWithFirstConsideration(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments
    .some(adj => (adj.isOtherFixed() && adj.soAdjOtherFixed.amountAdditionalConsiderationToVendor === 'YES_ELIGIBLE_FOR_REBATE') ||
      ((adj.isOtherFixedPayableOnOccupancy() && adj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor === 'YES_ELIGIBLE_FOR_REBATE')) ||
      ((adj.isOtherProratedOnPercentageInterest() && adj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor === 'YES_ELIGIBLE_FOR_REBATE'))
    );
  }

  static isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebatePlusTax());
  }

  static isOtherFixedWithNotEligibleForTaxRebatePlusTaxAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebatePlusTax());
  }

  static isOtherFixedAdjNotEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate());
  }

  static isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments
    .some(adj => (adj.isOtherFixed() && adj.soAdjOtherFixed.amountAdditionalConsiderationToVendor != 'NO') ||
      ((adj.isOtherFixedPayableOnOccupancy() && adj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor != 'NO')) ||
      ((adj.isOtherProratedOnPercentageInterest() && adj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor != 'NO'))
    );
  }

  static isOtherAdditionalConsiderationAmountAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments
    .some(adj => (adj.isOtherFixed() && adj.soAdjOtherFixed.amountAdditionalConsiderationToVendor != 'NO' && adj.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES') ||
      ((adj.isOtherFixedPayableOnOccupancy() && adj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor != 'NO' && adj.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')) ||
      ((adj.isOtherProratedOnPercentageInterest() && adj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor != 'NO' && adj.soAdjOtherProratedOnPercentageInterest.adjustmentPaidOnInterimClosing == 'YES'))
    );
  }

  static isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate());
  }

  static isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate());
  }

  static isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebatePlusTax(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments && statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebatePlusTax());
  }

  static totalTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): number {
    if (statementOfAdjustments) {
      return statementOfAdjustments.filter(adj => adj.isTarionWarrantyWithAdditionalConsiderationToVendorEligibleForTaxRebate()).reduce(function (sum, adj) {
        return Number(sum) + Number(adj.soAdjTarionWarranty.enrolmentFeePlusTax);
      }, 0);
    }
    return 0;
  }

  static totalHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): number {
    if (statementOfAdjustments) {
      return statementOfAdjustments.filter(adj => adj.isHCRAWithAdditionalConsiderationToVendorEligibleForTaxRebate()).reduce(function (sum, adj) {
        return Number(sum) + Number(adj.soAdjHCRAFee.enrolmentFeePlusTax);
      }, 0);
    }
    return 0;
  }

  static totalTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments: StatementAdjustment[]): number {
    if (statementOfAdjustments) {
      return statementOfAdjustments.filter(adj => adj.isTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes()).reduce(function (sum, adj) {
        return Number(sum) + Number(adj.soAdjTarionWarranty.enrolmentFeePlusTax);
      }, 0);
    }
    return 0;
  }

  static totalTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): number {
    if (statementOfAdjustments) {
      return statementOfAdjustments.filter(adj => adj.isTarionWarrantyWithAdditionalConsiderationToVendorNotEligibleForTaxRebate()).reduce(function (sum, adj) {
        return Number(sum) + Number(adj.soAdjTarionWarranty.enrolmentFee);
      }, 0);
    }
    return 0;
  }

  static totalHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): number {
    if (statementOfAdjustments) {
      return statementOfAdjustments.filter(adj => adj.isHCRAWithAdditionalConsiderationToVendorNotEligibleForTaxRebate()).reduce(function (sum, adj) {
        return Number(sum) + Number(adj.soAdjHCRAFee.enrolmentFee);
      }, 0);
    }
    return 0;
  }

  static totalTarioNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments: StatementAdjustment[]): number {
    if (statementOfAdjustments) {
      return statementOfAdjustments.filter(adj => adj.isTarioNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes()).reduce(function (sum, adj) {
        return Number(sum) + Number(adj.soAdjTarionWarranty.enrolmentFeePlusTax);
      }, 0);
    }
    return 0;
  }

  // get total of additional vendor considerations amount for all adjustments of type OtherFixed
  static totalOtherFixedAdj(avct: AdditionalVendorConsiderationType, sas: StatementAdjustment[], checkAdjustmentPaidByVendorOnInterimClosing?: boolean): number {
    if (sas) {
      return sas.filter(adj => adj.isOtherFixed())
      .reduce(function (sum, adj) {
        let adjTotal: number = 0;
        if (adj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == avct) {
          if (!checkAdjustmentPaidByVendorOnInterimClosing || adj.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES') {
            adjTotal += +adj.soAdjOtherFixed.amount;
          }
          if ((avct == 'YES_ELIGIBLE_FOR_REBATE' || avct == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX') && adj.soAdjOtherFixed.applyTaxBoolean
            && (!checkAdjustmentPaidByVendorOnInterimClosing || adj.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')) {
            if ([ 'SK', 'MB' ].indexOf(adj.provinceCode) > -1) {
              // PST does not get included on additional considerations for SK and MB
              adjTotal += adj.soAdjOtherFixed.gstAmount;
            } else {
              adjTotal += adj.soAdjOtherFixed.hstAmount;
            }
          }
        }
        return Number(sum) + Utils.roundCurrency(adjTotal);
      }, 0);
    }
    return 0;
  }

  // get total of additional vendor considerations amount for all adjustments of type OtherFixedPayableOnOccupancy
  static totalOtherFixedAdjPayableOnOccupancy(avct: AdditionalVendorConsiderationType, sas: StatementAdjustment[], checkAdjustmentPaidByVendorOnInterimClosing?: boolean): number {
    if (sas) {
      return sas.filter(adj => adj.isOtherFixedPayableOnOccupancy())
      .reduce(function (sum, adj) {
        let adjTotal: number = 0;
        if (adj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == avct) {
          if (!checkAdjustmentPaidByVendorOnInterimClosing || adj.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES') {
            adjTotal += +adj.soAdjOtherFixedPayableOnOccupancy.amount;
          }
          if ((avct == 'YES_ELIGIBLE_FOR_REBATE' || avct == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX') && adj.soAdjOtherFixedPayableOnOccupancy.applyTaxBoolean
            && (!checkAdjustmentPaidByVendorOnInterimClosing || adj.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')) {
            adjTotal += adj.soAdjOtherFixedPayableOnOccupancy.hstAmount;
          }
        }
        return Number(sum) + Number(adjTotal);
      }, 0);
    }
    return 0;
  }

  // get total of additional vendor considerations amount for all adjustments of type OtherProratedOnPercentageInterest
  static totalOtherProRatedOnPercentageInterest(avct: AdditionalVendorConsiderationType, sas: StatementAdjustment[]): number {
    if (sas) {
      return sas.filter(adj => adj.isOtherProratedOnPercentageInterest())
      .reduce(function (sum, adj) {
        let adjTotal: number = 0;
        if (adj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == avct) {
          if ((avct == 'YES_ELIGIBLE_FOR_REBATE') && adj.soAdjOtherProratedOnPercentageInterest.applyTaxBoolean) {
            adjTotal += adj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(adj.soAdjOtherProratedOnPercentageInterest.condominiumTotalSharePercentage);
          } else {
            adjTotal += adj.soAdjOtherProratedOnPercentageInterest.getAmountForPercentageInterest(adj.soAdjOtherProratedOnPercentageInterest.condominiumTotalSharePercentage);
          }
        }
        return Number(sum) + Number(adjTotal);
      }, 0);
    }
    return 0;
  }

  // get total of additional vendor considerations amount for Consideration Paid On Interim Closing adjustment
  static totalOtherProRatedOnPercentageInterestForInterimClosing(avct: AdditionalVendorConsiderationType, sas: StatementAdjustment[]): number {
    if (sas) {
      return sas.filter(adj => adj.isOtherProratedOnPercentageInterest())
      .reduce(function (sum, adj) {
        let adjTotal: number = 0;
        if (adj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == avct) {
          if (adj.soAdjOtherProratedOnPercentageInterest.adjustmentPaidOnInterimClosing == 'YES') {
            adjTotal += adj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(adj.soAdjOtherProratedOnPercentageInterest.condominiumTotalSharePercentage);
          }
        }
        return Number(sum) + Number(adjTotal);
      }, 0);
    }
    return 0;
  }

  // Tarion Warranty depends on calculations of sale price adjustment and needs to be updated after changes in sale price adjustment
  static updateTarionWarrantyAndHCRA(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], statementAdjustmentDisplayBuilder: StatementAdjustmentDisplayBuilder, projectAdjustmentConfig: ProjectAdjustmentConfig): boolean {
    let updated: boolean = false;

    statementOfAdjustments
    .filter(soAdj => soAdj.isTarionWarranty())
    .forEach(soAdjTarion => {
        soAdjTarion.soAdjTarionWarranty.enrolmentFee = StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFee(salePriceAdjustment, soAdjTarion.soAdjTarionWarranty, statementAdjustmentDisplayBuilder, projectAdjustmentConfig);
        soAdjTarion.amount = StatementAdjustmentDisplayTarionWarrantyBuilder.calculateAdjustmentAmount(salePriceAdjustment, soAdjTarion.soAdjTarionWarranty, statementAdjustmentDisplayBuilder, projectAdjustmentConfig);
        updated = true;
      }
    );

    statementOfAdjustments
    .filter(soAdj => soAdj.isHCRAFee())
    .forEach(soAdjHCRA => {
        soAdjHCRA.amount = StatementAdjustmentDisplayHcraFeeBuilder.calculateAdjustmentAmount(salePriceAdjustment, soAdjHCRA.soAdjHCRAFee, statementAdjustmentDisplayBuilder);
        updated = true;
      }
    );

    return updated;
  }

  static isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(statementOfAdjustments: StatementAdjustment[]): boolean {
    return StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments) ||
      StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments) ||
      StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments) ||
      StatementAdjustmentUtil.isHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments) ||
      StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(statementOfAdjustments);

  }

  static isAdditionalConsiderationsFromTarionOrOtherFixedOrItemized(statementOfAdjustments: StatementAdjustment[]): boolean {
    return StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments) ||
      StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments) ||
      StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(statementOfAdjustments) ||
      StatementAdjustmentUtil.isItemizedAdjAvailableWithConsiderationForPruchaser(statementOfAdjustments);

  }

  // If change updateSalePriceAdditionalConsiderations, please corresponding updateAdditionalConsiderationPaidOnInterimClosing and getConsiderationPaidOnInterimClosingAmount
  static updateSalePriceAdditionalConsiderations(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], existingConsideration?: boolean) {

    let isTarionWarrantyAdjAvailableAndEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments);
    let isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments);
    let isHCRAAdjAvailableAndEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments);
    let isHCRAAdjAvailableAndNotEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments);
    let isOtherFixedAdjAvailableAndWithAdtnlConsiderationAmount: boolean = StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(statementOfAdjustments);

    let ofaAdtnlVndCnsidElgblForTaxRebate: number = StatementAdjustmentUtil.totalOtherFixedAdj('YES_ELIGIBLE_FOR_REBATE', statementOfAdjustments);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebate: number = StatementAdjustmentUtil.totalOtherFixedAdj('YES_NOT_ELIGIBLE_FOR_REBATE', statementOfAdjustments);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTax: number = StatementAdjustmentUtil.totalOtherFixedAdj('YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX', statementOfAdjustments);

    let ofaAdtnlVndCnsidElgblForTaxRebatePayable: number = StatementAdjustmentUtil.totalOtherFixedAdjPayableOnOccupancy('YES_ELIGIBLE_FOR_REBATE', statementOfAdjustments);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePayable: number = StatementAdjustmentUtil.totalOtherFixedAdjPayableOnOccupancy('YES_NOT_ELIGIBLE_FOR_REBATE', statementOfAdjustments);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTaxPayable: number = StatementAdjustmentUtil.totalOtherFixedAdjPayableOnOccupancy('YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX', statementOfAdjustments);

    let ofaAdtnlVndCnsidElgblForTaxRebatePercentageInterest: number = StatementAdjustmentUtil.totalOtherProRatedOnPercentageInterest('YES_ELIGIBLE_FOR_REBATE', statementOfAdjustments);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePercentageInterest: number = StatementAdjustmentUtil.totalOtherProRatedOnPercentageInterest('YES_NOT_ELIGIBLE_FOR_REBATE', statementOfAdjustments);

    if (isOtherFixedAdjAvailableAndWithAdtnlConsiderationAmount || isTarionWarrantyAdjAvailableAndEligibleForTaxRebate || isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate || isHCRAAdjAvailableAndEligibleForTaxRebate || isHCRAAdjAvailableAndNotEligibleForTaxRebate) {

      if (!salePriceAdjustment.salePriceAdjustmentFormat) {
        salePriceAdjustment.salePriceAdjustmentFormat = 'CREDIT_VENDOR_NET_SALE_PRICE';
      }
      if (salePriceAdjustment.netOutHstFromHSTSalePrice == 'NO') {
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate = 0;
        if (isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate) {
          salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate = StatementAdjustmentUtil.totalTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments);
        }
        if (isHCRAAdjAvailableAndNotEligibleForTaxRebate) {
          salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(StatementAdjustmentUtil.totalHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments));
        }
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebate);
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePayable);
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePercentageInterest);

        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax = Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTax);
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTaxPayable);
      } else {
        salePriceAdjustment.additionalConsiderationsInclHst = 0;
        if (isTarionWarrantyAdjAvailableAndEligibleForTaxRebate) {
          // re-use the existing "additional considerations"
          salePriceAdjustment.additionalConsiderationsInclHst = StatementAdjustmentUtil.totalTarionWarrantyAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments);
        }
        if (isHCRAAdjAvailableAndEligibleForTaxRebate) {
          salePriceAdjustment.additionalConsiderationsInclHst += Number(StatementAdjustmentUtil.totalHCRAAdjAvailableAndEligibleForTaxRebate(statementOfAdjustments));
        }

        salePriceAdjustment.additionalConsiderationsInclHst += Number(ofaAdtnlVndCnsidElgblForTaxRebate);
        salePriceAdjustment.additionalConsiderationsInclHst += Number(ofaAdtnlVndCnsidElgblForTaxRebatePayable);
        salePriceAdjustment.additionalConsiderationsInclHst += Number(ofaAdtnlVndCnsidElgblForTaxRebatePercentageInterest);

        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate = 0;
        if (isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate) {
          salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate = StatementAdjustmentUtil.totalTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments);
        }
        if (isHCRAAdjAvailableAndNotEligibleForTaxRebate) {
          salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(StatementAdjustmentUtil.totalHCRAAdjAvailableAndNotEligibleForTaxRebate(statementOfAdjustments));
        }
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebate);
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePayable);
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePercentageInterest);

        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax = ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTax;
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax += ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTaxPayable;
      }
    } else if (existingConsideration) {
      // HCRA ?
      salePriceAdjustment.additionalConsiderationsInclHst = 0;

      if (!isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate || !isHCRAAdjAvailableAndNotEligibleForTaxRebate) {
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate = 0;
      }

      if (!isOtherFixedAdjAvailableAndWithAdtnlConsiderationAmount) {
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax = 0;
      }
    }
  }

  static updateAdditionalConsiderationPaidOnInterimClosing(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], existingConsideration?: boolean) {
    const totalAdditionAmount: number = this.getConsiderationPaidOnInterimClosingAmount(salePriceAdjustment, statementOfAdjustments, null, existingConsideration);
    if (statementOfAdjustments) {
      statementOfAdjustments.filter((soa: StatementAdjustment) => soa.isConsiderationPaidOnInterimClosing())
      .forEach((soa: StatementAdjustment) => soa.amount = totalAdditionAmount);
    }
  }

  static getConsiderationPaidOnInterimClosingAmount(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], amountType: AdditionalConsiderationAmountType, existingConsideration?: boolean) {
    let additionalConsiderationsInclHst: number = 0;
    let additionalVendorConsidNotEligibleForTaxRebate: number = 0;
    let additionalVendorConsidNotEligibleForTaxRebatePlusTax: number = 0;

    let isTarionWarrantyAdjAvailableAndEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments);
    let isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isTarionNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments);
    let isOtherFixedAdjAvailableAndWithAdtnlConsiderationAmount: boolean = StatementAdjustmentUtil.isOtherAdditionalConsiderationAmountAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments);

    let ofaAdtnlVndCnsidElgblForTaxRebate: number = StatementAdjustmentUtil.totalOtherFixedAdj('YES_ELIGIBLE_FOR_REBATE', statementOfAdjustments, true);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebate: number = StatementAdjustmentUtil.totalOtherFixedAdj('YES_NOT_ELIGIBLE_FOR_REBATE', statementOfAdjustments, true);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTax: number = StatementAdjustmentUtil.totalOtherFixedAdj('YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX', statementOfAdjustments, true);

    let ofaAdtnlVndCnsidElgblForTaxRebatePayable: number = StatementAdjustmentUtil.totalOtherFixedAdjPayableOnOccupancy('YES_ELIGIBLE_FOR_REBATE', statementOfAdjustments, true);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePayable: number = StatementAdjustmentUtil.totalOtherFixedAdjPayableOnOccupancy('YES_NOT_ELIGIBLE_FOR_REBATE', statementOfAdjustments, true);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTaxPayable: number = StatementAdjustmentUtil.totalOtherFixedAdjPayableOnOccupancy('YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX', statementOfAdjustments, true);

    let ofaAdtnlVndCnsidElgblForTaxRebatePercentageInterest: number = StatementAdjustmentUtil.totalOtherProRatedOnPercentageInterestForInterimClosing('YES_ELIGIBLE_FOR_REBATE', statementOfAdjustments);
    let ofaAdtnlVendorConsidNotEligibleForTaxRebatePercentageInterest: number = StatementAdjustmentUtil.totalOtherProRatedOnPercentageInterestForInterimClosing('YES_NOT_ELIGIBLE_FOR_REBATE', statementOfAdjustments);

    if (isOtherFixedAdjAvailableAndWithAdtnlConsiderationAmount || isTarionWarrantyAdjAvailableAndEligibleForTaxRebate || isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate) {

      if (salePriceAdjustment.netOutHstFromHSTSalePrice == 'NO') {
        additionalVendorConsidNotEligibleForTaxRebate = 0;
        if (isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate) {
          additionalVendorConsidNotEligibleForTaxRebate = StatementAdjustmentUtil.totalTarioNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments);
        }
        additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebate);
        additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePayable);
        additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePercentageInterest);

        additionalVendorConsidNotEligibleForTaxRebatePlusTax = Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTax);
        additionalVendorConsidNotEligibleForTaxRebatePlusTax += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTaxPayable);
      } else {
        if (isTarionWarrantyAdjAvailableAndEligibleForTaxRebate) {
          // re-use the existing "additional considerations"
          additionalConsiderationsInclHst = StatementAdjustmentUtil.totalTarionWithEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments);
        } else {
          additionalConsiderationsInclHst = 0;
        }

        additionalConsiderationsInclHst += Number(ofaAdtnlVndCnsidElgblForTaxRebate);
        additionalConsiderationsInclHst += Number(ofaAdtnlVndCnsidElgblForTaxRebatePayable);
        additionalConsiderationsInclHst += Number(ofaAdtnlVndCnsidElgblForTaxRebatePercentageInterest);

        if (isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate) {
          additionalVendorConsidNotEligibleForTaxRebate = StatementAdjustmentUtil.totalTarioNotEligibleForTaxRebateAndAdjustmentPaidOnInterimClosingYes(statementOfAdjustments);
        } else {
          additionalVendorConsidNotEligibleForTaxRebate = 0;
        }
        additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebate);
        additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePayable);
        additionalVendorConsidNotEligibleForTaxRebate += Number(ofaAdtnlVendorConsidNotEligibleForTaxRebatePercentageInterest);

        additionalVendorConsidNotEligibleForTaxRebatePlusTax = ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTax;
        additionalVendorConsidNotEligibleForTaxRebatePlusTax += ofaAdtnlVendorConsidNotEligibleForTaxRebatePlusTaxPayable;
      }
    } else if (existingConsideration) {
      additionalConsiderationsInclHst = 0;

      if (!isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate) {
        additionalVendorConsidNotEligibleForTaxRebate = 0;
      }

      if (!isOtherFixedAdjAvailableAndWithAdtnlConsiderationAmount) {
        additionalVendorConsidNotEligibleForTaxRebatePlusTax = 0;
      }
    }

    let amount: number = additionalConsiderationsInclHst + additionalVendorConsidNotEligibleForTaxRebate + additionalVendorConsidNotEligibleForTaxRebatePlusTax;
    switch (amountType) {
      case AdditionalConsiderationAmountTypes.ELIGIBLE_FOR_HST_REBATE:
        amount = additionalConsiderationsInclHst;
        break;
      case AdditionalConsiderationAmountTypes.NOT_ELIGIBLE_FOR_HST_REBATE:
        amount = additionalVendorConsidNotEligibleForTaxRebate;
        break;
      case AdditionalConsiderationAmountTypes.NOT_ELIGIBLE_FOR_HST_REBATE_INCL_HST:
        amount = additionalVendorConsidNotEligibleForTaxRebatePlusTax;
        break;
      default :
        break;
    }

    return amount;

  }

  static getAmountAdjOtherFixed(statementAdjustment: StatementAdjustment, salePriceAdjustment: SalePriceAdjustment, statementAdjustmentHeading: StatementOfAdjustmentHeading): number {
    return this._getAmountAdjOtherFixed(statementAdjustment.soAdjOtherFixed, salePriceAdjustment, statementAdjustmentHeading);
  }

  static getAmountAdjOtherFixedPayableOnOccupancy(statementAdjustment: StatementAdjustment, salePriceAdjustment: SalePriceAdjustment, statementAdjustmentHeading: StatementOfAdjustmentHeading): number {
    return this._getAmountAdjOtherFixed(statementAdjustment.soAdjOtherFixedPayableOnOccupancy, salePriceAdjustment, statementAdjustmentHeading);
  }

  static getAmountOtherProRatedOnPercentageInterest(adj: StatementAdjustment, condominiumTotalSharePercentage: number, salePriceAdjustment: SalePriceAdjustment, statementAdjustmentHeading: StatementOfAdjustmentHeading): number {
    if (adj && adj.soAdjOtherProratedOnPercentageInterest) {
      if (adj.soAdjOtherProratedOnPercentageInterest.creditType == OtherFixedCreditTypes.PURCHASER) {
        return adj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(condominiumTotalSharePercentage);
      } else if (adj.soAdjOtherProratedOnPercentageInterest.creditType == OtherFixedCreditTypes.VENDOR) {
        return adj.soAdjOtherProratedOnPercentageInterest.getTotalAmountVendorForPercentageInterest(condominiumTotalSharePercentage, salePriceAdjustment);
      }
    }
    return 0;
  }

  static getAmountPurchasePricePercentage(adj: StatementAdjustment): number {
    if (adj && adj.soAdjPurchasePricePercentage) {
      return adj.soAdjPurchasePricePercentage.getTotalAmount();
    }
    return 0;
  }

  static _getAmountAdjOtherFixed(adj: SoAdjOtherFixed, salePriceAdjustment: SalePriceAdjustment, statementAdjustmentHeading: StatementOfAdjustmentHeading): number {
    if (adj.creditType == OtherFixedCreditTypes.PURCHASER) { // Purchaser
      return adj.totalAmountPurchaser;
    } else if (adj.creditType == OtherFixedCreditTypes.VENDOR) { // Vendor
      return adj.totalAmountVendor(salePriceAdjustment);
    } else {
      return adj.totalAmount;
    }
  }

  static updateOtherFixedAdj(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], statementAdjustmentHeading: StatementOfAdjustmentHeading) {

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed())
    .forEach(soAdjOhterFixed => {
        soAdjOhterFixed.amount = this.getAmountAdjOtherFixed(soAdjOhterFixed, salePriceAdjustment, statementAdjustmentHeading);
      }
    );
  }

  static updateOtherFixedAdjPayableOnOccupancy(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], statementAdjustmentHeading: StatementOfAdjustmentHeading) {

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy())
    .forEach(soAdjOhterFixed => {
        soAdjOhterFixed.amount = this.getAmountAdjOtherFixedPayableOnOccupancy(soAdjOhterFixed, salePriceAdjustment, statementAdjustmentHeading);
      }
    );
  }

  static updateOtherProRatedOnPercentageInterest(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], condominiumTotalSharePercentage: number, statementAdjustmentHeading: StatementOfAdjustmentHeading) {

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherProratedOnPercentageInterest())
    .forEach(soAdjOtherFixed => {
        soAdjOtherFixed.soAdjOtherProratedOnPercentageInterest.condominiumTotalSharePercentage = condominiumTotalSharePercentage;
        soAdjOtherFixed.amount = this.getAmountOtherProRatedOnPercentageInterest(soAdjOtherFixed, condominiumTotalSharePercentage, salePriceAdjustment, statementAdjustmentHeading);
      }
    );
  }

  static updateItemizedCreditAdjustments(salePriceAdjustment: SalePriceAdjustment, statementOfAdjustments: StatementAdjustment[], federalHstRate: number, provinceHstRate: number) {

    statementOfAdjustments
    .filter(soAdj => soAdj.isItemizedCreditToVendorPurchaser())
    .forEach(itemizedAdjustment => {
        itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.netOutHstFromHSTSalePrice = salePriceAdjustment.netOutHstFromHSTSalePrice;
        itemizedAdjustment.amount = itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.totalNetSalePrice(federalHstRate, provinceHstRate);
      }
    );
  }

  static findByKey(statementOfAdjustments: StatementAdjustment[], key: string): StatementAdjustment {

    if (!statementOfAdjustments || statementOfAdjustments.length == 0) {
      return null;
    } else {
      return statementOfAdjustments.find(soAdj => key == soAdj.itemKey);
    }
  }

  static copyDepositAdjustmentData(source: StatementAdjustment, target: StatementAdjustment) {
    if (source && target) { // only copy those related to Deposit Adjustment
      target.matterTax = source.matterTax ? new MatterTax(source.matterTax) : null;
      target.propertyMatterTaxId = source.propertyMatterTaxId;
      target.provinceCode = source.provinceCode;
      target.infoOnly = source.infoOnly;
      target.fieldCode = source.fieldCode;
    }
  }

  static createStatementAdjustment(adjustmentMode: string, provinceCode: ProvinceCode, description: string, amount: number, itemKey: string, amountType: string, infoOnly: boolean, commonExpense: SoaCommonExpense, salePriceAdjustmentHeadingId: number, parcelLegalDescriptionId: number): StatementAdjustment {

    let statementAdjustment = new StatementAdjustment(adjustmentMode, provinceCode);
    statementAdjustment.description = description;
    statementAdjustment.amount = amount;
    statementAdjustment.itemKey = itemKey;
    statementAdjustment.statementOfAdjustmentCreditType = amountType;

    statementAdjustment.infoOnly = !!infoOnly;

    if (commonExpense) {
      statementAdjustment.soaCommonExpense = commonExpense;
    }
    if (statementAdjustment.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING) {
      statementAdjustment.addedFlag = true;
      if (salePriceAdjustmentHeadingId) {
        statementAdjustment.salePriceAdjustmentHeadingId = salePriceAdjustmentHeadingId;
      }
    }
    if (parcelLegalDescriptionId) {
      statementAdjustment.parcelLegalDescriptionId = parcelLegalDescriptionId;
    }
    return statementAdjustment;
  }

  static updateInterestOnDepositInterimAndFinal(matter: Matter): void {
    if (matter) {
      let interestOnDepositCondoList = matter.finalStatementAdjustments.concat(matter.interimStatementAdjustments).filter(item => item.isInterestOnDepositCondo() && item.soAdjInterestOnDepositCondo != undefined);
      if (interestOnDepositCondoList) {
        interestOnDepositCondoList.forEach(item => {
          item.amount = item.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(matter, item.isAdjustmentStatusFinal());
        });
      }

      //Updating InterestOnDepositInterest adjustment which depends upon InterestOnDeposit adjustment
      this.updateInterestOnDepositInterest(matter);
    }
  }

  static updateInterestOnDepositInterest(matter: Matter): void {
    if (matter) {
      // interest on deposit interest adjustment is only available on final SOA sheet
      let interestOnDepositCondoList = matter.finalStatementAdjustments.filter(item => item.isInterestOnDepositInterestCondo() && item.soAdjInterestOnDepositCondo != undefined);
      if (interestOnDepositCondoList) {
        interestOnDepositCondoList.forEach(item => {
          item.amount = item.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(matter, item.isAdjustmentStatusFinal());
        });
      }
    }
  }

  static updateInterestOnDepositUnderOldCondo(matter: Matter): void {
    if (matter) {
      let interestOnDepositUnderOldCondoList: StatementAdjustment[] = matter.statementOfAdjustments.filter(item => item.isInterestOnDepositUnderOldCondo() && item.soAdjInterestOnDepositCondo != undefined);
      if (interestOnDepositUnderOldCondoList) {
        interestOnDepositUnderOldCondoList.forEach(item => {
          item.amount = item.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(matter, item.isAdjustmentStatusFinal());
        });
      }
      //Updating InterestOnDepositInterest adjustment which depends upon InterestOnDeposit adjustment
      this.updateInterestOnDepositInterest(matter);
    }
  }

  static updateInterestOnDeferredMoniesDuringOccupancy(matter: Matter, project: Project): void {
    if (matter && project) {
      let deferredAdjList = matter.finalStatementAdjustments.filter(item => item.isInterestOnDeferredMoniesDuringOccupancy() && item.soAdjInterestCal);
      if (deferredAdjList) {
        deferredAdjList.forEach((adjustment: StatementAdjustment) => {
          adjustment.soAdjInterestCal.applyUpdatesBasedOnProjectSettings(matter, project);
        });
      }
    }
  }

  static updateBuildersTarionNo(updatedName: string, matter: Matter, isBuildersTarionNoAuto: boolean = false): void {
    if (matter && isBuildersTarionNoAuto) {
      let tarionAdjList = matter.finalStatementAdjustments.filter(item => item.isTarionWarranty() && item.soAdjTarionWarranty != undefined);
      if (tarionAdjList) {
        tarionAdjList.forEach(item => {
          item.soAdjTarionWarranty.builderRegistrationNumber = updatedName;
        });
      }
    }
  }

  static hasAdjWithVendorConsiderationEligibleForTaxRebate(statementOfAdjustments: StatementAdjustment[]): boolean {
    return statementOfAdjustments &&
      (statementOfAdjustments.some(adj => adj.isTarionWarrantyWithAdditionalConsiderationToVendorEligibleForTaxRebate()) ||
        statementOfAdjustments.some(adj => adj.isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate())
      );
  }

  static isManualAdditionalConsiderations(matter: Matter): Boolean {
    return (matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst > 0 && !this.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(matter.statementOfAdjustments));
  }

  static canSalePriceBeApplied(targetMatterAdj: StatementAdjustment, templateMatterAdj: StatementAdjustment, targetMatter: Matter, templateMatter: Matter): boolean {

    // First we check netOutHst if they are different then sale price can't be applied
    if (targetMatter.considerationLtt.salePriceAdjustment.isInclusivePrice() && templateMatter.considerationLtt.salePriceAdjustment.isNotInclusivePrice()
      && !this.canResetSalePriceAdjustment(targetMatter)) {
      return false;
    }
    if (targetMatter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst > 0 && templateMatter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst > 0
      && ((!this.isManualAdditionalConsiderations(targetMatter) && this.isManualAdditionalConsiderations(templateMatter))
        || (this.isManualAdditionalConsiderations(targetMatter) && !this.isManualAdditionalConsiderations(templateMatter)))) {
      return false;
    } else {
      return true;
    }
  }

  static canResetSalePriceAdjustment(matter: Matter): boolean {
    let existingAdjWithVendorConsiderationEligibleForTaxRebate: boolean = this.hasAdjWithVendorConsiderationEligibleForTaxRebate(matter.statementOfAdjustments);
    return (matter && matter.considerationLtt && matter.considerationLtt.salePriceAdjustment
      && (!matter.considerationLtt.salePriceAdjustment.hasAdditionalVendorConsiderationsFromAdj(existingAdjWithVendorConsiderationEligibleForTaxRebate)));

  }

  static amountPaidOnInterimClosing(amountPaidOnInterimClosing: SoAdjAmountPaidOnInterimClosing, matter: Matter): number {
    if (amountPaidOnInterimClosing) {
      return amountPaidOnInterimClosing.creditPurchaserWith == creditPurchaserWith.unadjustedBalance.value ? this.unadjustedBalance(matter.considerationLtt.salePriceAdjustment, this.depositAdjustment(matter.finalStatementAdjustments))
        : amountPaidOnInterimClosing.creditPurchaserWith == creditPurchaserWith.unadjustedBalanceIncludingCredits.value ? this.unadjustedBalanceIncludingCredits(matter.considerationLtt.salePriceAdjustment)
          : this.balanceDueOnClosingAmountInInterimSOA(matter);
    }
  }

  static unadjustedBalance(salePriceAdjustment: SalePriceAdjustment, depositAdjustment: StatementAdjustment): number {
    return Number(salePriceAdjustment.agreementSalePrice) - Number(depositAdjustment.amount);

  }

  static unadjustedBalanceIncludingCredits(salePriceAdjustment: SalePriceAdjustment): number {
    return Number(salePriceAdjustment.agreementSalePrice) + Number(salePriceAdjustment.finalAdditionalConsiderationsInclHst) - Number(Number(salePriceAdjustment.creditsToPurchaserInclHst) + Number(salePriceAdjustment.otherItems));

  }

  static depositAdjustment(statementOfAdjustments: StatementAdjustment[]): StatementAdjustment {
    return statementOfAdjustments.find(adj => adj.isDepositAdjustment() && adj.isAdjustmentStatusFinal());
  }

  static balanceDueOnClosingAmountInInterimSOA(matter: Matter): number {
    let modeChanged: boolean = false;
    if (matter.isAdjustmentStatusModeFinal) {
      matter.updateStatusMode(ProgressionStatus.INTERIM);
      modeChanged = true;
    }
    let creditPurchaserBDOC: number = matter.calculateBalanceDueOnClosingCreditPurchaser();
    if (modeChanged) {
      //revert back the Progression Status
      matter.updateStatusMode(matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    }
    return creditPurchaserBDOC;
  }

  static updateAmountPaidOnInterimClosingAdj(matter: Matter) {

    matter.allStatementAdjustments
    .filter(soAdj => soAdj.isAmountPaidOnInterimClosing())
    .forEach(soAdj => {
        soAdj.amount = this.amountPaidOnInterimClosing(soAdj.soAdjAmountPaidOnInterimClosing, matter);
      }
    );
  }

}

