<div class="form-horizontal container-fluid custom-modal-container">
    <div class="row modal-header margin-bottom-20">
        <div class="col-lg-10"><h1>Adjustment Type : Utilities</h1></div>
        <button type="button" class="btn-close pull-right" (click)="close()">
            &times;
        </button>
    </div>

    <div class="col-lg-12 fields-group margin-bottom-0 margin-top-0">
      <div class="form-group main-level">
        <span
          class="title min-height-30 text-center"
          style="border-bottom: none; margin-bottom: 0px; padding-bottom: 20px"
        >
          <label class="control-label boldfont"
            >Adjustment as at
            <ng-container *ngIf="this.dateStatusLabel !== ''">{{ this.dateStatusLabel }}</ng-container>
          </label>
        </span>
      </div>
    </div>
    <div class="row modal-body">
        <div class="col-lg-7">
            <div class="form-group row">
                <label class="control-label col-lg-6" for="utilityAccountNumber"
                    >Account Number</label
                >
                <div class="col-lg-6">
                    <input
                        type="text"
                        id="utilityAccountNumber"
                        name="utilityAccountNumber"
                        [(ngModel)]="context.propertyUtilitiesFee.accountNumber"
                        class="form-control text-right"
                        tabindex="-1"
                        dp-error-validator
                        [fieldKey]="
                            'matter.utilities.type.utilityAccountNumber'
                        "
                    />
                </div>
            </div>
            <div class="form-group row">
                <label
                    class="control-label col-lg-6"
                    for="utilityAmount"
                    >Utility amount</label
                >
                <div class="col-lg-6">
                    <input
                        type="text"
                        id="utilityAmount"
                        name="utilityAmount"
                        [(ngModel)]="context.propertyUtilitiesFee.utilityAmount"
                        class="form-control text-right"
                        dp-currency
                        tabindex="-1"
                        dp-error-validator
                        [fieldKey]="'matter.utilities.type.utilityAmount'"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label
                    class="control-label col-lg-6"
                    for="currentBillingStartDate"
                    >Commencement date of current billing period
                </label>
                <div class="col-lg-6">
                    <dp-partial-date
                        id="currentBillingStartDate"
                        [inputDate]="
                            context.propertyUtilitiesFee.currentBillingStartDate
                        "
                        [fullDateRequired]="true"
                        statusBarHelp
                        (dateChange)="onDateChange($event)"
                        [fieldKey]="
                            'matter.propertyUtilitiesFee.currentBillingStartDate'
                        "
                    >
                    </dp-partial-date>
                </div>
            </div>
            <div class="form-group row">
                <label
                    class="control-label col-lg-6"
                    for="currentBillingEndDate"
                    >End date of current billing period</label
                >
                <div class="col-lg-6">
                    <dp-partial-date
                        id="currentBillingEndDate"
                        [inputDate]="
                            context.propertyUtilitiesFee.currentBillingEndDate
                        "
                        [fullDateRequired]="true"
                        statusBarHelp
                        (dateChange)="onDateChange($event, false)"
                        [fieldKey]="
                            'matter.propertyUtilitiesFee.currentBillingEndDate'
                        "
                    >
                    </dp-partial-date>
                </div>
            </div>
            <div class="form-group row">
                <label class="control-label col-lg-6"
                    >Base utility adjustment on</label
                >
                <div class="col-lg-6">
                    <select
                        class="form-control"
                        id="taxAdjustmentType"
                        name="taxAdjustmentType"
                        [(ngModel)]="BaseTaxAdjustmentSelectedValue"
                        (change)="updateSelectedYearValue($event)"
                    >
                        <option
                            *ngFor="
                                let BaseTaxAdjustment of BaseTaxAdjustmentList
                            "
                            [value]="BaseTaxAdjustment.value"
                        >
                            {{ BaseTaxAdjustment.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="previousYearSelected" class="form-group row">
              <label class="control-label col-lg-6">Estimated percentage increase</label>
              <div class="col-lg-6" style="position: relative">
                <input
                  type="text"
                  [(ngModel)]="context.propertyUtilitiesFee.estimatedPercentageIncrease"
                  id="estimatedPercentageIncrease"
                  name="estimatedPercentageIncrease"
                  class="form-control text-right"
                  dp-percentage
                  style="padding-right: 25px"
                />
                <span style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: none"
                  >%</span
                >
              </div>
            </div>
            <ng-container>
                <div class="form-group row">
                    <label class="control-label col-lg-6"
                        >Have utilities been paid in full?</label
                    >
                    <div class="col-lg-2">
                        <select
                            class="form-control"
                            id="utilitiesPaidInFullStatus"
                            name="utilitiesPaidInFullStatus"
                            (change)="updateFullPaymentValueStatus($event)"
                            [(ngModel)]="context.propertyUtilitiesFee.utilitiesPaidInFull"
                        >
                            <option
                                *ngFor="
                                    let utilitiesPaidInFull of utilitiesPaidInFullList
                                "
                                [value]="utilitiesPaidInFull.value"
                            >
                                {{ utilitiesPaidInFull.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </ng-container>
            <ng-container >
                <div class="form-group row" *ngIf="!fullPaymentStatus">
                    <label class="control-label col-lg-6"
                        >Has a pre-payment been made?</label
                    >
                    <div class="col-lg-2">
                        <select
                            class="form-control"
                            id="doesUtilitiesHavePrePayment"
                            name="doesUtilitiesHavePrePayment"
                            (change)="updatePrepaymentValueStatus($event)"
                        >
                            <option
                                *ngFor="
                                    let doesUtilitiesHavePrePayment of doesUtilitiesHavePrePaymentList
                                "
                                [value]="doesUtilitiesHavePrePayment.value"
                            >
                                {{ doesUtilitiesHavePrePayment.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </ng-container>
            <div class="form-group row" *ngIf="prePaymentStatus">
                <label
                    class="control-label col-lg-6"
                    dp-province-field-label="matter.utilities.type.prePaymentAmount"
                    for="prePaymentAmount"
                    >Pre-payment amount</label
                >
                <div class="col-lg-6">
                    <input
                        type="text"
                        id="prePaymentAmount"
                        name="prePaymentAmount"
                        [(ngModel)]="
                            context.propertyUtilitiesFee.prePaymentAmount
                        "
                        class="form-control text-right"
                        dp-currency
                        tabindex="-1"
                        dp-error-validator
                        [fieldKey]="'matter.utilities.type.prePaymentAmount'"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-5"></div>

    <div class="row modal-footer">
        <div class="col-lg-10 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>Ok</span>
            </button>
            <button
                type="button"
                id="closeBtn"
                class="dp-btn"
                (click)="close()"
            >
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>
