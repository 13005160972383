import {api} from '../../common';

export const stewartTitleApi = {
  stewartTitleSubmitFile: `${ api }/integrations/stewart-title/matters/{matterId}/stewartUsers/{stewartUserId}/submitFiles`,
  relevantStewartUserForMatter: `${ api }/integrations/stewart-title/matters/{matterId}/stewartUsers`,
  stewartUserByContact: `${ api }/integrations/stewart-title/contacts/{contactId}/stewartUsers`,
  stewartTitleGetResult: `${ api }/integrations/stewart-title/matters/{matterId}/stewartUsers/{stewartUserId}/getResult/fileNo/{fileNo}`,
  removeTitleInsurance: `${ api }/integrations/stewart-title/matters/{matterId}/titleInsurance/{titleInsuranceId}`,
  premiumCalculatorUrl: `${ api }/integrations/stewart-assyst/premiumCalculatorUrl/`,

  stewartTitleUser(userId: string): string {
    return `${ api }/integrations/stewart-title/users/${ userId }/stewartUsers`;
  },
  solicitorStewartTitleUsers(accountId: string): string {
    return `${ api }/integrations/stewart-title/accounts/${ accountId }/solicitorStewartUsers`;
  },
  stewartTitleHomeToolsUrl(userId: string): string {
    return `${ api }/integrations/stewart-title/users/${ userId }/homeToolsUrls`;
  },
  stewartTitleAccessToken(userId: string): string {
    return `${ api }/integrations/stewart-title/users/${ userId }/stewartUsers/accessTokens`;
  },
  stewartTitleAuthenticationUrl(userId: string): string {
    return `${ api }/integrations/stewart-title/users/${ userId }/authenticationUrls`;
  },
  stewartTitleDownloadDocUrl(userId: number, matterId: number, fileNo: string, documentId: number): string {
    return `${ api }/integrations/stewart-title/matters/${ matterId }/stewartUsers/${ userId }/files/${ fileNo }/documents/${ documentId }`;
  },
  stewartTitleDeleteDocuments(userId: number, matterId: number, fileNo: string): string {
    return `${ api }/integrations/stewart-title/matters/${ matterId }/stewartUsers/${ userId }/files/${ fileNo }`;
  }

};

export const stewartTitleLinks = {
  assystRequestInfo: 'https://login.assystrealestate.com/onboarding',
  stewartTitleRequestInfo: 'http://www.stewart.ca/unityinfosignup'
};
