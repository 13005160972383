import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ParcelRegister} from './parcel-register';
import {TeranetRequestInstrumentSubmissionState} from './teranet-constants';

export class TeranetRequestInstrumentResponse extends BaseEntity {
  submissionState: TeranetRequestInstrumentSubmissionState;
  parcelRegister: ParcelRegister;

  constructor(response?: TeranetRequestInstrumentResponse) {
    super(response);
    if (response) {
      if (response.parcelRegister) {
        this.parcelRegister = new ParcelRegister(response.parcelRegister);
      }
    }
  }

  //Request was submitted successfully if submission state is 1 or 2. Both treated the same.
  isSuccess(): boolean {
    return (this.submissionState && (this.submissionState == TeranetRequestInstrumentSubmissionState.SUCCESS_1
      || this.submissionState == TeranetRequestInstrumentSubmissionState.SUCCESS_2)) ? true : false;
  }
}
