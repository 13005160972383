import {HttpClient} from '../../../../core/httpClient.service';
import {Injectable} from '@angular/core';
import {Precedent} from '../../../shared/precedent';
import {precedentApi} from './precedent.api';
import {Observable} from 'rxjs/Observable';
import {SESSION_STORAGE_KEYS} from '../../../../shared';
import {ProvinceCode} from '../../../../admin/accounts/shared/base-province';
import {AppConfig} from '../../../../shared-main/app-configuration';

@Injectable()
export class PrecedentService {

  constructor(private http: HttpClient, public appConfig: AppConfig) {
  }

  getBaseUrl(customerAccountId ?: number): string {
    let accountId: number;
    if (!customerAccountId) {
      accountId = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    } else {
      accountId = customerAccountId;
    }

    return precedentApi.precedents.replace('{accountId}', '' + accountId);
  }

  getPrecedentsUrl(includeDeleted: boolean, provinceCode?: ProvinceCode, accountId ?: number, precedentType?: string, excludeInactive?: boolean): string {
    let url: string = this.getBaseUrl(accountId);
    let conditionAdded: boolean = false;

    if (provinceCode) {
      url += `?provinceCode=${ provinceCode }`;
      conditionAdded = true;
    }
    if (precedentType) {
      url += `${ conditionAdded ? '&' : '?' }precedentType=${ precedentType }`;
      conditionAdded = true;
    }
    return url + `${ conditionAdded ? '&' : '?' }includeDeleted=${ includeDeleted }&excludeInactive=${ excludeInactive ? true : false }`;
  }

  // includes non-deleted ones
  getAllPrecedents(provinceCode?: ProvinceCode, accountId ?: number, precedentType?: string): Observable<any> {
    let url = this.getPrecedentsUrl(true, provinceCode, accountId, precedentType);
    //console.log(url);
    return this.http.get(url)
    .map((res) => {
      let data: Precedent[] = [];
      res[ 'Precedents' ].forEach((item) => {
        data.push(new Precedent(item));
      });
      return data;
    });
  }

  // does not include non-deleted ones
  getPrecedents(provinceCode?: ProvinceCode, accountId ?: number, precedentType?: string, excludeInactive?: boolean): Observable<any> {
    let url = this.getPrecedentsUrl(false, provinceCode, accountId, precedentType, excludeInactive);
    //console.log(url);
    return this.http.get(url)
    .map((res) => {
      let data: Precedent[] = [];
      if (this.appConfig.isHiddenForBCProvince) {
        res[ 'Precedents' ].forEach((item) => {
          if (item.precedentName != 'PURCHASE' && item.precedentName != 'SALE' && item.precedentName != 'MORTGAGE') {
            data.push(new Precedent(item));
          }
        });
      } else {
        res[ 'Precedents' ].forEach((item) => {
          data.push(new Precedent(item));
        });
      }
      return data;
    });
  }

  savePrecedent(precedent: Precedent, accountId ?: number): Observable<any> {
    if (precedent && precedent.id) {
      const url = this.getBaseUrl(accountId) + '/' + precedent.id;
      return this.http.put(url, precedent)
      .map((res) => {
        return res;
      });
    } else {
      return this.http.post(this.getBaseUrl(accountId), precedent)
      .map((res) => {
        return res;
      });
    }
  }

  getDefaultPrecedent(precedentName: string): Observable<any> {
    let url: string = precedentApi.defaultPrecedent;
    url += `?name=${ precedentName }`;
    return this.http.get(url)
    .map((res) => {
      return res;
    });
    ;
  }

  deletePrecedent(precedent: Precedent): Observable<any> {
    if (precedent && precedent.id) {
      const url = this.getBaseUrl() + '/' + precedent.id;
      return this.http.delete(url)
      .map((res) => {
        return res;
      });
      // return Observable.of(true);
    }
  }

}
