import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';
import Utils from '../../../shared-main/utils';
import {PROVINCE_CODES} from '../../shared/user-province';

export type FinalUseAmountFromInterimType = 'YES' | 'NO' | 'YES_EXCLUDING_TAXES';
export type FinalPurchaserHasPaidFeeType = 'AND_INCLUDING_MONTH_OF_CLOSING' |
  'END_OF_MONTH_PRECEDING_CLOSING' |
  'OTHER_ENTER_OWN_DATE' |
  'NO_OCCUPANCY_FEES_EVER_PAID' |
  'NOT_PAID_FOR_MONTH_OF_POSSESSION' |
  // there are only for Total Occupancy Fees
  'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT' |
  'OTHER_ENTER_OWN_START_AND_END_DATES' |
  'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING' |
  'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING' |
  'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT'
  ;

export class SoAdjFinalFee extends BaseEntity {

  id: number;

  useAmountFromInterim: FinalUseAmountFromInterimType;

  amount: number;

  purchaserHasPaidFee: FinalPurchaserHasPaidFeeType;

  paidOn: string;

  taxType: string;

  applyTax: boolean;

  hstRate: number;

  getCreditAmount(occupancyDate: string, interimAdjustAsDate: string, finalAdjustAsDate: string, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {
    let prchShrdAmnt: number = this.getPurchaserShareAmount(occupancyDate, interimAdjustAsDate, finalAdjustAsDate, isPaysForDateOfClosingPurchaser, provinceCode);
    if (this.purchaserHasPaidFee === 'AND_INCLUDING_MONTH_OF_CLOSING' && prchShrdAmnt >= 0) { //prchShrdAmnt could be -1 if some of the dates are null (but not undefined)
      return this.amountWithTax - prchShrdAmnt;
    }
    return prchShrdAmnt;
  }

  getCreditType(): string {
    if (this.purchaserHasPaidFee === 'AND_INCLUDING_MONTH_OF_CLOSING') {
      return StatementAdjustmentAmountTypes.PURCHASER;
    }
    return StatementAdjustmentAmountTypes.VENDOR;
  }

  get isTaxApplicable(): boolean {
    return Utils.convertToBoolean(this.applyTax);
  }

  get amountWithTax(): number {
    if (Utils.convertToBoolean(this.applyTax)) {
      return Utils.roundCurrency(Number(this.amount) + Number(this.amount) * Number(this.hstRate) / 100);
    }
    return Number(this.amount);
  }

  // to make your life easier try to find the excel spreadsheet that has all these cell references
  getPurchaserShareAmount(occupancyDate: string, interimAdjustAsDate: string, finalAdjustAsDate: string, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {

    if (this.areCalculationsDependentOnInterimAdjustAsDate() && Utils.isNotValidDate(interimAdjustAsDate)) {
      return -1;
    }

    let daysInAdjustAsAt: number = Utils.getDaysInMonth(finalAdjustAsDate); // H25
    let daysInInterimPossession: number = Utils.getDaysInMonth(interimAdjustAsDate); // H27

    let ratePerDaysInAdjustAsAt: number = Number(this.amountWithTax) / Number(daysInAdjustAsAt); // J25
    let ratePerDaysInInterimPossession: number = Number(this.amountWithTax) / Number(daysInInterimPossession); //J27

    if (isNaN(daysInAdjustAsAt) || isNaN(ratePerDaysInAdjustAsAt)) {
      return -1;
    }

    switch (this.purchaserHasPaidFee) {
      case 'AND_INCLUDING_MONTH_OF_CLOSING': //C2
      case 'END_OF_MONTH_PRECEDING_CLOSING': //C3
        let derivedDaysOccupied = this.getDerivedDaysOccupiedInMonthOfOccupancy(finalAdjustAsDate);
        derivedDaysOccupied = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? derivedDaysOccupied + 1 : derivedDaysOccupied;
        return Utils.roundCurrency(ratePerDaysInAdjustAsAt * derivedDaysOccupied);
      case 'OTHER_ENTER_OWN_DATE': //C4
        return Utils.roundCurrency(this.getOtherEnterOwnDateAmount(interimAdjustAsDate, finalAdjustAsDate, ratePerDaysInAdjustAsAt, isPaysForDateOfClosingPurchaser, provinceCode));
      case 'NO_OCCUPANCY_FEES_EVER_PAID': //C5
        return Utils.roundCurrency(this.getNoOccupancyFeesEveryPaidAmount(occupancyDate, interimAdjustAsDate, finalAdjustAsDate, isPaysForDateOfClosingPurchaser, provinceCode));
      case 'NOT_PAID_FOR_MONTH_OF_POSSESSION': //C6
        let derivedDaysLeftInMonth = this.getDerivedDaysLeftInMonthOfPossession(interimAdjustAsDate);
        return Utils.roundCurrency(ratePerDaysInInterimPossession * derivedDaysLeftInMonth);
      default:
        return 0;
    }
  }

  areCalculationsDependentOnInterimAdjustAsDate(): boolean {
    switch (this.purchaserHasPaidFee) {
      case 'AND_INCLUDING_MONTH_OF_CLOSING': //C2
      case 'END_OF_MONTH_PRECEDING_CLOSING': //C3
      case 'OTHER_ENTER_OWN_DATE': //C4
        return false;
      case 'NO_OCCUPANCY_FEES_EVER_PAID': //C5
      case 'NOT_PAID_FOR_MONTH_OF_POSSESSION': //C6
        return true;
      default:
        return false;
    }
  }

  // Please note that derivedLeftOverDaysBetweenFinalAndEntered is calculated differently on SoAdjFinalEarlyPossessionFee where it cannot have negative values (DPPMP-35654)
  // see method overwrite SoAdjFinalEarlyPossessionFee.getOtherEnterOwnDateAmount
  getOtherEnterOwnDateAmount(interimAdjustAsDate: string, finalAdjustAsDate: string, ratePerDaysInAdjustAsAt: number, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {
    let derivedMonthsDateToWhichPurchaserHasOccupancyFeesUntilFinal: number = this.purchaserHasPaidFee === 'NO_OCCUPANCY_FEES_EVER_PAID' ?
      Utils.getDateDiffMonths(interimAdjustAsDate, finalAdjustAsDate) :
      Utils.getDateDiffMonths(this.paidOn, finalAdjustAsDate); // E23

    let derivedLeftOverDaysBetweenFinalAndEntered: number = Utils.getDay(finalAdjustAsDate) - 1 - Utils.getDay(this.paidOn); //E24
    derivedLeftOverDaysBetweenFinalAndEntered = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? derivedLeftOverDaysBetweenFinalAndEntered + 1
      : derivedLeftOverDaysBetweenFinalAndEntered;
    let derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth: number = Utils.getDateDiff(this.paidOn, Utils.lastDayOfMonthFromDate(this.paidOn)); // E27
    derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth + 1
      : derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth;

    let daysInEnteredDateMonth: number = Utils.getDaysInMonth(this.paidOn); // H26
    let ratePerDaysInEnteredDateMonth: number = Number(this.amountWithTax) / Number(daysInEnteredDateMonth); // J26

    if (Utils.getDay(finalAdjustAsDate) < Utils.getDay(this.paidOn)) {
      return derivedMonthsDateToWhichPurchaserHasOccupancyFeesUntilFinal * this.amountWithTax
        + this.getDerivedDaysOccupiedInMonthOfOccupancy(finalAdjustAsDate) * ratePerDaysInAdjustAsAt
        + ratePerDaysInEnteredDateMonth * derivedDaysDateToWhichPurchaserHasOccupancyFeesBalanceOfMonth;
    } else {
      return derivedMonthsDateToWhichPurchaserHasOccupancyFeesUntilFinal * this.amountWithTax
        + ratePerDaysInAdjustAsAt * derivedLeftOverDaysBetweenFinalAndEntered;
    }
  }

  getNoOccupancyFeesEveryPaidAmount(occupancyDate: string, interimAdjustAsDate: string, finalAdjustAsDate: string, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {
    let tdd: number = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? Utils.getDateDiff(interimAdjustAsDate, finalAdjustAsDate, true) + 1
      : Utils.getDateDiff(interimAdjustAsDate, finalAdjustAsDate, true); // P5

    let tmm: number = Utils.getDateDiffMonths(interimAdjustAsDate, finalAdjustAsDate); //P6
    let totalAmtOnlyMonthly: number = tmm * this.amountWithTax; //P7
    let tempW: string = Utils.addMomentDays(finalAdjustAsDate, -tdd); //P8
    //let xDD: number = Utils.getDay(tempW); //P9
    //let xMM: number = Utils.getMonth(tempW); //P10
    //let xYYYY: number = Utils.getYear(tempW); //P11
    let daysInFinalMonth: number = Utils.getDaysInMonth(finalAdjustAsDate); //P12
    let amountPerDaysInLastMonth: number = this.amountWithTax / daysInFinalMonth; //P13
    let amountPerDayInLastMonth: number = amountPerDaysInLastMonth * Utils.getDay(finalAdjustAsDate); //P14
    //let monthOfFinal: number = Utils.getMonth(finalAdjustAsDate); //P15
    let p16: number = Utils.getDaysInMonth(tempW); //P16
    let p17: number = this.amountWithTax / p16; // p17
    let p18: number = p17 * (tdd - Utils.getDay(finalAdjustAsDate)); //p18

    let spanMultipleMonths: number = p18 + amountPerDayInLastMonth + totalAmtOnlyMonthly; //P19
    let spanLessThanAMonth: number = amountPerDaysInLastMonth * tdd; //P20

    if (tmm > 0) {
      return spanMultipleMonths;
    }

    return spanLessThanAMonth;
  }

  // E21
  getDerivedDaysInterimUntilFinal(interimAdjustAsDate: string, finalAdjustAsDate: string): number {
    if (Utils.isNotValidDate(interimAdjustAsDate)) {
      return -1;
    }
    return Utils.getDateDiff(interimAdjustAsDate, finalAdjustAsDate);
  }

  // E22
  getDerivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal(finalAdjustAsDate: string): number {
    return Utils.getDateDiff(this.paidOn, finalAdjustAsDate) - 1;
  }

  // E25
  getDerivedDaysOccupiedInMonthOfOccupancy(finalAdjustAsDate: string): number {
    return Utils.getDay(finalAdjustAsDate) - 1;
  }

  // E26
  getDerivedDaysLeftInMonthOfPossession(interimAdjustAsDate: string): number {
    if (Utils.isNotValidDate(interimAdjustAsDate)) {
      return -1;
    }
    return Utils.getDateDiff(interimAdjustAsDate, Utils.lastDayOfMonthFromDate(interimAdjustAsDate)) + 1;
  }

  getPurchaserShareDays(occupancyDate: string, interimAdjustAsDate: string, finalAdjustAsDate: string, isPaysForDateOfClosingPurchaser ?: boolean, provinceCode ?: string): number {
    let purchaserShareDays: number = 0;
    switch (this.purchaserHasPaidFee) {
      case 'AND_INCLUDING_MONTH_OF_CLOSING': //C2
      case 'END_OF_MONTH_PRECEDING_CLOSING': //C3
        let derivedDaysOccupied = this.getDerivedDaysOccupiedInMonthOfOccupancy(finalAdjustAsDate);
        derivedDaysOccupied = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? derivedDaysOccupied + 1 : derivedDaysOccupied;
        purchaserShareDays = derivedDaysOccupied;
        break;
      case 'OTHER_ENTER_OWN_DATE': //C4
        let derivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal = this.getDerivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal(finalAdjustAsDate);
        derivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? derivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal + 1
          : derivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal;
        purchaserShareDays = derivedDaysDateToWhichPurchaserHasOccupancyFeesUntilFinal;
        break;
      case 'NO_OCCUPANCY_FEES_EVER_PAID': //C5
        let derivedDaysInterimUntilFinal = this.getDerivedDaysInterimUntilFinal(interimAdjustAsDate, finalAdjustAsDate);
        derivedDaysInterimUntilFinal = provinceCode == PROVINCE_CODES.ALBERTA && isPaysForDateOfClosingPurchaser ? derivedDaysInterimUntilFinal + 1 : derivedDaysInterimUntilFinal;
        purchaserShareDays = derivedDaysInterimUntilFinal;
        break;
      case 'NOT_PAID_FOR_MONTH_OF_POSSESSION': //C6
        let derivedDaysLeftInMonth = this.getDerivedDaysLeftInMonthOfPossession(interimAdjustAsDate);
        purchaserShareDays = derivedDaysLeftInMonth;
        break;
      default:
        purchaserShareDays = 0;
    }
    return isNaN(purchaserShareDays) ? 0 : purchaserShareDays;
  }

}
