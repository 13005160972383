<div class="container">
    <div class="row">
        <div class="col-lg-12">

            <!-- Main Content -->
            <div class="col-lg-10 matter-content-area">
                <div class="row matter-title form-group">
                    <div class="col-lg-12">
                        <h1>Customize Extended Workflow Category
                            <ul class="right">
                                <li>
                                    <button id="saveBtn"
                                            type="button"
                                            class="function-icons"
                                            [ngClass]="{'icon-dirty' : supplementalTaskCategory.isDirty}"
                                            [disabled]="!supplementalTaskCategory.isDirty"
                                            (click)="saveAll()">
                                        <i class="svg-icon save margin-left-5" aria-hidden="true"></i>
                                    </button>
                                    <h6 [ngClass]="{'icon-dirty' : supplementalTaskCategory.isDirty}">Save</h6>
                                </li>
                            </ul>
                        </h1>
                    </div>
                </div>
                <div tabindex="0" class="admin-padding row" >
                    <!-- Detail Tab -->
                    <div class="form-horizontal admin-padding">
                        <div class="form-group">
                            <label class="control-label col-lg-2">Name Of Category*</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed id="categoryName" name="categoryName" fieldKey="supplementalTaskCategory.categoryName"
                                       dp-error-validator [(ngModel)]="supplementalTaskCategory.categoryName"
                                       maxlength="30"  [dp-read-only]="categoryNameReadOnly" class="form-control"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-2">Task Used in*</label>
                            <div class="col-lg-4">
                                <dp-multi-select [multiSelectData]="matterTypes"  fieldKey="supplementalTaskCategory.appliesTo"
                                                 dp-error-validator
                                                 [showLabelInTitle]="true"
                                                 [inputName]="'tasks'"
                                                 [multiSelectDataSelected]="supplementalTaskCategory.applicableMatterTypeCodes"
                                                 (updateMultiSelect)="updateMultiSelectFilters($event,'type')"
                                                 class="lawyer-multi-select"></dp-multi-select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-lg-2">Automatically add task to new matters?</label>
                            <div class="col-lg-1">
                                <select class="form-control" id="autoInsert"
                                        name="autoInsert" [(ngModel)]="supplementalTaskCategory.autoInsert"
                                >
                                    <option *ngFor="let yesNoOption of yesNoOptions" [value]="yesNoOption.value">
                                        {{yesNoOption.label}}
                                    </option>
                                </select>
                            </div>
                        </div>



                        <div *ngFor="let folder of templateFolders; let i=index;">

                            <dp-accordion [expanded]="false" [offset]="true" id="accodianFolders-{{i}}"
                                          *ngIf="supplementalTaskCategory && supplementalTaskCategory.categoryTemplates  && getCategoryFilesInFolder(folder.id)">

                                <div class="dp-accordion-header">  <div class="form-group main-level"> <span class="title min-height-30">{{folder
                                    .templateFolderName}}</span></div></div>

                                <div class="dp-accordion-content">
                                    <div class="col-lg-12"
                                         *ngIf="supplementalTaskCategory && supplementalTaskCategory.categoryTemplates && supplementalTaskCategory.categoryTemplates.length>0 && getCategoryFilesInFolder(folder.id).length>0">
                                          <table class="table undertakings-table" tabindex="0">
                                            <thead>
                                            <tr>
                                                <th class="table-cell-1">
                                                    <div class="table-cell-1"><label>Name</label></div>
                                                </th>
                                                <th class="table-cell-2">
                                                    <div class="table-cell-2"><label>Description</label></div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let row of getCategoryFilesInFolder(folder.id); let i=index;">
                                                <td class="table-cell-1">
                                                    <div class="table-cell-1 padding-table">
                                                        <input type="text"
                                                               trimmed
                                                               id="name-{{i}}"
                                                               name="name-{{i}}"
                                                               [(ngModel)]="row.documentTemplateName"
                                                               (change)="onChange()"
                                                               class="form-control"
                                                               readonly
                                                        >
                                                    </div>

                                                </td>
                                                <td class="table-cell-2">
                                                    <div class="table-cell-2 padding-table">
                                                        <input type="text"
                                                               trimmed
                                                               id="description-{{i}}"
                                                               name="description-{{i}}"
                                                               [(ngModel)]="row.templateDescription"
                                                               (change)="onChange()"
                                                               class="form-control"
                                                               readonly
                                                        >
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="clear"></div>
                                    <a (click)="openMaintainMastersDialog(folder);" class="padding-bottom-10 display-block">Maintain Masters</a>
                                </div>


                            </dp-accordion>

                        </div>



                        <div *ngIf="!supplementalTaskCategory.isCustomizedDefaultTaskCategory">
                            <div class="col-lg-12 fields-group padding-top-10 margin-bottom-0">
                                <div class="form-group main-level">
                                    <span class="title min-height-30">Pre-defined fields for {{supplementalTaskCategory.categoryName}}</span>
                                </div>
                                <div>
                                    <span>Select the pre-defined fields for use with this category</span>
                                </div>
                            </div>
                            <div class="fixed-padding-table-div">
                                <table class="table standard-table table-scroll-bar"  tabindex="0">
                                    <thead>
                                    <tr>
                                        <th class="table-cell-1">
                                            <div class="table-cell-1"></div>
                                        </th>
                                        <th class="table-cell-2">
                                            <div class="table-cell-2">Pre-defined field</div>
                                        </th>
                                        <th class="table-cell-3">
                                            <div class="table-cell-3">Field Label</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let row of supplementalTaskCategory.preDefinedFields; let i=index;">
                                        <td class="table-cell-1">
                                            <div class="table-cell-1 padding-table">
                                                <dp-checkbox fieldId="category_{{i}}"><input type="checkbox"
                                                                                             id="category_{{i}}"
                                                                                             name="category_{{i}}"
                                                                                             (ngModelChange)="onChange()"
                                                                                             [(ngModel)]="row.enabled"
                                                                                             [disabled]="isPreDefinedFieldReadOnly(row)"
                                                />
                                                </dp-checkbox>
                                            </div>
                                        </td>
                                        <td class="table-cell-2">
                                            <div class="table-cell-2 padding-table">
                                                {{row.fieldDefaultLabel}}
                                            </div>

                                        </td>
                                        <td class="table-cell-3">
                                            <div class="padding-table" *ngIf="row.enabled">
                                                <input type="text"
                                                       trimmed
                                                       [dp-read-only]="isPreDefinedFieldReadOnly(row)"
                                                       id="fieldLabel-{{i}}"
                                                       name="fieldLabel-{{i}}"
                                                       class="form-control"
                                                       [(ngModel)]="row.fieldLabel"
                                                       [fieldKey]="'supplementalTaskCategory.preDefinedField.'+i"
                                                       dp-error-validator
                                                       (change)="onChange();"
                                                       value="{{getDefaultFieldLabel(row)}}"
                                                       maxlength="50"
                                                >
                                            </div>
                                        </td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>
                            <!-- Tickler Group Begin -->
                            <div *ngIf="supplementalTaskCategory.ticklerGroup">
                                <div class="form-group">
                                    <div class="col-lg-12">
                                        <dp-checkbox fieldId="ticklerGroup">
                                            <input type="checkbox" id="ticklerGroup"
                                                   name="ticklerGroup" (ngModelChange)="onTicklerGroupCheck($event)"
                                                   [(ngModel)]="supplementalTaskCategory.ticklerGroup.enabled" />
                                        </dp-checkbox>
                                        <label class="control-label-ticker">{{supplementalTaskCategory.ticklerGroup.fieldDefaultLabel}}</label>
                                    </div>
                                </div>
                                <div  *ngIf="supplementalTaskCategory.ticklerGroup.enabled">
                                    <div *ngFor="let sub of supplementalTaskCategory.modifiableSubFieldsOfTickerGroup; let i=index;">
                                      <div class="form-group">
                                           <div class="col-lg-3">
                                                <label class="control-label-ticker">{{sub.fieldDefaultLabel}}</label>
                                            </div>
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-7">
                                                <input type="text"
                                                       trimmed
                                                       id="subFieldLabel-{{i}}"
                                                       name="subFieldLabel-{{i}}"
                                                       class="form-control"
                                                       [(ngModel)]="sub.fieldLabel"
                                                       [fieldKey]="'supplementalTaskCategory.tickleGroupSubFields.'+i"
                                                       dp-error-validator
                                                       (change)="onChange()"
                                                       value="{{getDefaultFieldLabel(sub)}}"
                                                       maxlength="50">
                                            </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-lg-12">
                                            <span>The Following fields will be automatically added to the Extended Workflows Category Completed?, Date, Per, Notes</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Tickler Group End -->
                            <div class="col-lg-12 fields-group padding-top-10 margin-bottom-0">
                                <div class="form-group main-level">
                                    <span class="title min-height-30">Additional details fields for {{supplementalTaskCategory.categoryName}}</span>
                                </div>
                                <div>
                                    <span>If desired, configure your additional details fields for use with this category</span>
                                </div>
                            </div>
<!--                            <div class="fixed-padding-table-div" *ngIf="supplementalTaskCategory.userDefinedFields.length > 0 "  [dp-read-only-block]="isIdVerificationOfNonClientCategory">-->
                            <div class="fixed-padding-table-div" *ngIf="supplementalTaskCategory.userDefinedFields.length > 0 ">
                                <table class="table standard-table user-defined-table table-scroll-bar"  tabindex="0">
                                    <thead>
                                    <tr>
                                        <th class="table-cell-1">
                                            <div class="table-cell-1">Additional details field type</div>
                                        </th>
                                        <th class="table-cell-2"></th>
                                        <th class="table-cell-3">
                                            <div class="table-cell-3">Field Label</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let udf of supplementalTaskCategory.userDefinedFields; let i=index;">
                                        <td class="table-cell-1">
                                            <div class="padding-table">
                                                <select class="form-control" id="userDefinedFieldOption-{{i}}" [dp-read-only]="isIdVerificationOfNonClientCategory || isUserDefinedFieldReadOnly(udf)"
                                                        name="userDefinedFieldOption-{{i}}"
                                                        [(ngModel)]="udf.fieldDataType" (ngModelChange)="onChange()">
                                                    <option *ngFor="let userDefinedFieldOption of userDefinedFieldOptions"
                                                            [value]="userDefinedFieldOption.value">
                                                        {{userDefinedFieldOption.label}}
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                          <td class="table-cell-2">
                                              <div  class="width-20">
                                                    <button type="button"
                                                            style="border: 0; background: none; font-size:28px"
                                                            class="function-icons"
                                                            [disabled]="isIdVerificationOfNonClientCategory || isUserDefinedFieldReadOnly(udf)"
                                                            (click)="deleteUserDefinedField(udf); onChange();"
                                                    >
                                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                              </div>
                                          </td>
                                        <td class="table-cell-3">
                                            <div class="padding-table" *ngIf="udf.fieldDataType != 'N_A' ">
                                                <input type="text"
                                                       trimmed
                                                       [(ngModel)]="udf.fieldLabel"
                                                       id="userDefinedFieldLabel-{{i}}"
                                                       name="userDefinedFieldLabel-{{i}}"
                                                       [fieldKey]="'supplementalTaskCategory.userDefinedField.'+i"
                                                       dp-error-validator
                                                       (change)="onChange()"
                                                       maxlength="50"
                                                       class="form-control"
                                                       [dp-read-only]="isIdVerificationOfNonClientCategory || isUserDefinedFieldReadOnly(udf)"
                                                >
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-group padding-bottom-100" *ngIf="supplementalTaskCategory.userDefinedFields.length < 10 &&  !isIdVerificationOfNonClientCategory">

                                <div class="col-lg-2">
                                     <span class="actionlink">
                                    <span>
                                        <a href="javascript:void(0);" (click)="addNewUserDefinedField()">
                                            Add Additional Details Field
                                        </a>
                                    </span>
                                    <span class="selector fa fa-plus-square-o"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
