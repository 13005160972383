// app-init.service.ts (updated)
import {Injectable} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {FeatureService} from './feature-service';
import {HttpClient} from './core';
import {Environment} from '../environments/environment';
import {UserProfilesService} from "./shared-main/user-profiles.service";
import {AuthZService} from "./core/authz/auth-z.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private authZService: AuthZService,
    private featureService: FeatureService,
    private environment: Environment,
    private http: HttpClient,
    private oauthService: OAuthService,
    private router: Router,
    private userProfileService: UserProfilesService) {
  }

  loadSSOConfigAndTryAuth() {
    return async () => {
      try {
        //don't move following call to Feature service, Feature service is used
        await this.http
          .get(`/api/feature-flags`)
          .toPromise()
          .then((response) =>
            this.featureService.setFeatureFlags(response.Features)
          );

        if (!this.featureService.isSSOEnabled) return Promise.resolve();

        this.saveSelectedAccountIdIfPresent();

        this.oauthService.configure(this.ssoConfig);
        await this.oauthService.loadDiscoveryDocumentAndLogin();

        if (this.oauthService.hasValidAccessToken()) {
          this.oauthService.setupAutomaticSilentRefresh();
          localStorage.setItem(
            'public-account-id',
            localStorage.getItem('selectedAccountId')
          );

          await this.userProfileService.getUserProfile().toPromise();

          await this.authZService.navigateToGetRedirectUrl();
        }

        return Promise.resolve();
      } catch (e) {
        console.log(e);
        return Promise.resolve();
      }

    };
  }

  saveSelectedAccountIdIfPresent() {
    // The first call what we received from "dashboard" application should have selectedAccountId parameter
    // We need to store this parameter because when login starts we will be redirected to the keycloak
    const url = new URL(window.location.href);
    let selectedAccountId = url.searchParams.get('selectedAccountId')!;

    // If we haven't this parameter that means we have been redirected from keycloak
    if (selectedAccountId == null) selectedAccountId = localStorage.getItem('selectedAccountId');

    // If we don't have an account ID from the URL parameter, and we don't have it in storage, we need to send
    // the user to the landing page to select the account
    if (selectedAccountId == null) this.router.navigate([ this.environment.config.landingPageUrl ]);

    localStorage.setItem('selectedAccountId', selectedAccountId);
  }

  get ssoConfig() {
    let authCodeFlowConfig: AuthConfig = {
      issuer: `${this.environment.config.keycloak?.domain}/auth/realms/${this.environment.config.keycloak?.realm}`,
      redirectUri: window.location.origin + window.location.pathname,
      clientId: this.environment.config.keycloak?.clientId,
      responseType: 'code',
      scope: 'openid profile email',
      logoutUrl: `${this.environment.config.keycloak?.domain}/auth/realms/${this.environment.config.keycloak?.realm}/protocol/openid-connect/logout/backchannel-logout`,
      postLogoutRedirectUri:
      this.environment.config.keycloak?.logoutRedirectUri,
      showDebugInformation: true,
    };

    return authCodeFlowConfig;
  }
}