import {Tab} from '../../shared/tabbing/tab';

export class DayAtGlanceTab extends Tab {

  subRoute: string;
  subRouteId: number;

  constructor(src?: DayAtGlanceTab) {
    super(src);
  }

  get tabTypeForDisplay(): string {
    return this.type;
  }
}


