import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {LockScreenService} from '../../core/lock-screen.service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {Logger} from '@nsalaun/ng-logger';
import {Observable} from 'rxjs';
import {TCOLUser} from './tcol-user';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {tcolApi} from './tcol-constants';
import {Response} from '@angular/http';
import {TCOLMunicipality} from "./tcol-municipality";
import {TcolSaveTaxCertificateRequest} from "./tcol-save-tax-certificate-request";
import {TcolTaxInfo} from "./tcol-tax-info";

@Injectable()
export class TCOLService {
  constructor(private http: HttpClient,
              private lockScreenService: LockScreenService,
              private errorService: ErrorService,
              private logger: Logger) {
  }

  getTCOLUser(): Observable<TCOLUser> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    const url: string = tcolApi.tcolUser.replace('{accountId}', accountId);
    return this.http.get(url).map((res: Response) => {
      return new TCOLUser(res[ 'TCOLUser' ]);
    });
  }

  validateTCOLUser(tcolUser: TCOLUser): Observable<TCOLUser> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    const url: string = tcolApi.validateTcolUser.replace('{accountId}', accountId);
    return this.http.post(url, JSON.stringify(tcolUser)).map((res: Response) => {
      return new TCOLUser(res[ 'TCOLUser' ]);
    });
  }

  getTCOLForgotPasswordUrl(): Observable<string> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    const url: string = tcolApi.forgotPasswordUrl.replace('{accountId}', accountId);
    return this.http.get(url).map((res: Response) => res[ 'TcolForgotPasswordUrl' ]);
  }

  deleteTCOLUnityUser(tcolUser: TCOLUser): Observable<any> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    const url: string = tcolApi.deleteTcolUser
    .replace('{accountId}', accountId)
    .replace('{userId}', '' + tcolUser.id);
    return this.http.delete(url);
  }

  getTcolWebUrl(userId: number): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = tcolApi.getTcolWebUrl.replace('{userId}', userId.toString()).replace('{accountId}', accountId);

    return this.http.get(url)
    .map((res: Response) => {
      return res[ 'TCOLSignUpUrl' ];
    });
  }

  getTcolSignupUrl(): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = tcolApi.getTcolSignupUrl.replace('{accountId}', accountId);

    return this.http.get(url)
    .map((res: Response) => {
      return res[ 'TCOLSignUpUrl' ];
    });
  }

  getTcolByPidAndProvince(pid: string, provinceCode: string): Observable<TCOLMunicipality[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = tcolApi.getTcolByPidAndProvince
      .replace('{accountId}', accountId)
      .replace('{pid}', pid)
      .replace('{provinceCode}', provinceCode);

    return this.http.get(url)
      .map((res: Response) => {
          return res['TCOLMunicipalities'];
      });
  }

  saveTaxCertificate(matterId: number, taxCertificateRequest:TcolSaveTaxCertificateRequest) : Observable<TcolTaxInfo> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = tcolApi.saveTaxCertificate
      .replace('{accountId}', accountId)
      .replace('{matterId}', matterId.toString());

    return this.http.post(url, JSON.stringify(taxCertificateRequest))
      .map((res: Response) => {
        return new TcolTaxInfo(res['TCOLTaxCertificateDocument']);
      });
  }

  getSavedTaxCertificates(matterId: number): Observable<TcolTaxInfo[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = tcolApi.saveTaxCertificate
      .replace('{accountId}', accountId)
      .replace('{matterId}', matterId.toString());

    return this.http.get(url)
      .map((res) => {
        let serverResponse = res['TCOLTaxCertificateDocument'];
        let response: TcolTaxInfo[] = [];

        serverResponse.forEach(taxInfo => response.push(new TcolTaxInfo(taxInfo)));
        return response;
      });
  }
}
