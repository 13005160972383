import {Injectable} from '@angular/core';
import {publicHolidaysApi} from './public-holiday-api';
import {PublicHoliday} from './public-holiday';
import {HttpClient} from '../../core/httpClient.service';
import {IMyDate} from '@nodro7/angular-mydatepicker';
import {Observable} from 'rxjs/Observable';
import Utils from '../utils';

@Injectable({
  providedIn: 'root'
})
export class PublicHolidayService {

  publicHolidays: PublicHoliday[] = [];
  datePickerPublicHolidays: IMyDate[] = [];
  holidays: Map<string, string> = new Map();

  constructor(private http: HttpClient) {
  }

  async loadPublicHolidays() {
    this.datePickerPublicHolidays = [];
    this.holidays.clear();
    this.publicHolidays = await this.retrievePublicHolidays().toPromise();
    this.publicHolidays && this.publicHolidays.forEach(holiday => {
      const dt = new Date(holiday.holidayDate);
      this.datePickerPublicHolidays.push(<IMyDate>{
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      });

      // set map for faster searching
      const dtKey = `${ +dt.getFullYear() }${ +dt.getMonth() + 1 }${ +dt.getDate() }`;
      const applicableProvince = (holiday.applicableProvinces.length == 1 && holiday.applicableProvinces[ 0 ] == '') ? ' (Canada)' : ` (${ holiday.applicableProvinces.join(',') })`;
      const dtValue = holiday.name + applicableProvince;
      if (this.holidays.has(dtKey)) {
        this.holidays.set(dtKey, this.holidays.get(dtKey) + ' \n' + dtValue);
      } else {
        this.holidays.set(dtKey, dtValue);
      }
    });
  }

  private retrievePublicHolidays(): Observable<PublicHoliday[]> {
    //return this.http.get(configApi.publicHolidays, false)
    return this.http.get(publicHolidaysApi.getPublicHolidays, false)
    .map(res => {
      if (res[ 'PublicHolidays' ]) {
        return res[ 'PublicHolidays' ].map((item: PublicHoliday) => {
          return new PublicHoliday(item);
        });
      }
    });
  }

  getPublicHolidays(): PublicHoliday[] {
    return this.publicHolidays;
  }

  getDatePickerPublicHolidays(): IMyDate[] {
    return this.datePickerPublicHolidays;
  }

  getHolidayByFullDate(date: Date): string {
    return this.getHoliday(date.getFullYear().toString(), (date.getMonth() + 1).toString(), date.getDate().toString());
  }

  getHolidayByFullDateString(date: string): string {
    if (Utils.isValidDate(date)) {
      const dateParts: string[] = date.split('/');
      return this.getHoliday(dateParts[ 0 ], dateParts[ 1 ], dateParts[ 2 ]);
    }
    return null;
  }

  getHoliday(year: string, month: string, day: string): string {
    if (this.holidays && year && year.length > 0 && month && month.length > 0 && day && day.length > 0) {
      const dtKey = `${ +year }${ +month }${ +day }`;
      return this.holidays.has(dtKey) ? this.holidays.get(dtKey) : null;
    }
    return null;
  }
}
