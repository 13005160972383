import {CirfClientInfo} from './cirf-client-info';

import {Address, Matter, MatterParticipant, Utils} from '../';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {UUIDUtil} from '../../../main/uuid-util';
import {DpBooleanValueTypes} from '../dp-boolean';
import {CirfMatterData} from './cirf-matter-data';
import {CirfMatterContactInfo} from './cirf-matter-contact-info';
import {YesNoType} from '../../../shared-main/constants';
import {CirfInsuranceDetail} from './cirf-insurance-detail';
import {CirfBrokerInfo} from './cirf-broker-info';
import {AddressTypes} from '../address-types';
import {CirfDocument} from './cirf-document';
import {ApplicableProvisionOptionsTypes, MaritalStatusTypes} from '../fla-data';

export type CirfStatusType = 'CANCELLED' | 'EXPIRED' | 'INPROGRESS' | 'OUTSTANDING' | 'PROCESSED' | 'SUBMITTED';
export type CirfType = 'MATTER' | 'PARTICIPANT';

export class Cirf extends BaseEntity {

  id: number;
  guid: string;
  matterId: number;
  clientEmail: string;
  formData: string;
  //formData is converted into below property
  cirfMatterData: CirfMatterData = new CirfMatterData();
  documents: CirfDocument[] = [];
  //Only UI side variable
  selectedDocuments: number[] = [];
  expiryDate: string;
  clientName: string;
  status: CirfStatusType;
  reviewStatus: string[] = [];
  lastUpdatedTimeStamp: string;
  cirfType: CirfType;
  matterParticipantId: number; //only for partial cirfs

  constructor(cirf?: Cirf) {
    super(cirf);
    if (cirf) {

      if (cirf.cirfMatterData) {
        this.cirfMatterData = new CirfMatterData(cirf.cirfMatterData);
      } else if (cirf.formData) {
        this.cirfMatterData = new CirfMatterData(JSON.parse(cirf.formData));
      }

      if (Array.isArray(cirf.documents)) {
        cirf.documents.forEach(item => this.documents.push(new CirfDocument(item)));
      }
    }
  }

  get cirfClients(): CirfClientInfo[] {
    return this.cirfMatterData.cirfClients;
  }

  set cirfClients(cirfClients: CirfClientInfo[]) {
    this.cirfMatterData.cirfClients = cirfClients;
  }

  mapMatterParticipantsToCirfClientInfo(matterParticipants: MatterParticipant[], isParticipantCirf: boolean): void {
    this.cirfClients = [];

    //map of matterParticipantId to cirfClientInfo.id (needed for assigning spousal references)
    let matterParticipantMappings: Map<number, string> = new Map<number, string>();
    if (matterParticipants && matterParticipants.length) {
      matterParticipants.filter(item => !!item.contact && !!item.contact.isPerson).forEach(matterParticipant => {
        let cirfClientInfo = new CirfClientInfo();
        cirfClientInfo.id = UUIDUtil.getUUID().toString();
        cirfClientInfo.firstName = matterParticipant.contact.firstName;
        cirfClientInfo.middleName = matterParticipant.contact.middleName;
        cirfClientInfo.lastName = matterParticipant.contact.lastName;
        cirfClientInfo.gender = matterParticipant.contact.gender;
        cirfClientInfo.email = matterParticipant.contact.email;
        cirfClientInfo.birthDate = matterParticipant.contact.birthDate;
        cirfClientInfo.isCanadianResident = matterParticipant.contact.canadianResidentFlag == DpBooleanValueTypes.Y_n ? DpBooleanValueTypes.YES : matterParticipant.contact.canadianResidentFlag;

        cirfClientInfo.residentStatus = matterParticipant.contact.residentStatus ? matterParticipant.contact.residentStatus : '';
        cirfClientInfo.spousalStatus = this.getMappedSpousalStatus[ this.cirfMatterData.provinceCode ](matterParticipant);
        cirfClientInfo.isFamilyResidence = this.getMappedIsFamilyResidence[ this.cirfMatterData.provinceCode ](matterParticipant);
        cirfClientInfo.isSpouseOnTitle = this.getMappedIsSpouseOnTitle[ this.cirfMatterData.provinceCode ](matterParticipant);
        cirfClientInfo.spouseName = this.getMappedSpouseName(matterParticipant);
        cirfClientInfo.spouseId = isParticipantCirf ? null : this.getSpouseId(matterParticipant);
        cirfClientInfo.homePhone = matterParticipant.contact.homePhone;
        cirfClientInfo.cellPhone = matterParticipant.contact.cellPhone;
        cirfClientInfo.workPhone = matterParticipant.contact.workPhone;
        cirfClientInfo.occupation = matterParticipant.contact.identificationRecords && matterParticipant.contact.identificationRecords.length ? matterParticipant.contact.identificationRecords[ 0 ].occupation : '';
        // Do not prepopulate any address fields from matter content
        //cirfClientInfo.businessAddress = new Address(matterParticipant.contact.businessAddress);

        matterParticipantMappings.set(matterParticipant.matterParticipantId, cirfClientInfo.id);

        this.cirfClients.push(cirfClientInfo);
      });

      if (!isParticipantCirf) {
        //assign spousal references
        this.cirfClients.forEach(cirfClient => {
          if (cirfClient.spouseId) {
            cirfClient.spouseId = matterParticipantMappings.get(Number(cirfClient.spouseId)); //change reference from matterParticipantId to cirfClientInfo.id
            let spouseClient: CirfClientInfo = this.cirfClients.find(client => client.id == cirfClient.spouseId);
            cirfClient.spouseName = spouseClient.getFullName();
          }
        });
      }

    }
  }

  mapMatterContactInfoToCirfMatterContactInfo(matter: Matter): void {
    let matterContactInfo = new CirfMatterContactInfo();
    matterContactInfo.clientCapacity = matter.purchasersCapacity;
    if (matter.matterContactInfo) {
      matterContactInfo.preClosingAddress = new Address(matter.matterContactInfo.preClosingAddress);
      matterContactInfo.postClosingAddress = new Address(this.getPostClosingAddress(matter));
      matterContactInfo.residingAtSubjectProperty =
        matter.matterContactInfo.residingAtSubjectProperty == DpBooleanValueTypes.Y_n ? null : (<YesNoType>matter.matterContactInfo.residingAtSubjectProperty);
    } else {
      matterContactInfo.preClosingAddress = new Address();
      matterContactInfo.residingAtSubjectProperty = null;
    }

    //matterContactInfo.firstTimeHomeBuyer = matter.considerationLtt.lttRefundEntitlementOntario == 'Yes' ? 'Yes' : 'No';
    this.cirfMatterData.matterContactInfo = matterContactInfo;
  }

  getPostClosingAddress(matter: Matter): Address {
    if (matter.matterContactInfo && matter.matterContactInfo.postClosingAddress) {
      if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.subjectAddress) {
        return matter.matterPropertyWithCondo.address;
      } else if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.preClosingAddress) {
        return matter.matterContactInfo.preClosingAddress;
      } else { //manually entered
        return matter.matterContactInfo.postClosingAddress;
      }
    }

    return null;
  }

  mapMatterInsuranceInfoToCirfInsuranceContactInfo(matter: Matter): void {
    let brokerMatterParticipant = matter.brokerMatterParticipant;
    let cirfBrokerInfo = new CirfBrokerInfo();
    let cirfInsuranceDetail = new CirfInsuranceDetail();
    if (brokerMatterParticipant && brokerMatterParticipant.contact) {
      cirfBrokerInfo.name = brokerMatterParticipant.contact.organizationName;
      cirfBrokerInfo.email = brokerMatterParticipant.contact.email;
      if (brokerMatterParticipant.contact.telephone && brokerMatterParticipant.contact.telephone.length) {
        cirfBrokerInfo.workPhone = brokerMatterParticipant.contact.workPhone;
      }
      cirfInsuranceDetail.insuranceBroker = cirfBrokerInfo;
      if (matter && matter.fireInsuranceContactInfo) {
        cirfInsuranceDetail.companyName = matter.fireInsuranceContactInfo.companyInsurerName;
        cirfInsuranceDetail.policyNumber = matter.fireInsuranceContactInfo.documentationValue;
      }

      this.cirfMatterData.fireInsuranceDetail = cirfInsuranceDetail;
    } else {
      let insurerMatterParticipant = matter.insurerMatterParticipant;
      if (insurerMatterParticipant && insurerMatterParticipant.contact) {
        cirfInsuranceDetail.companyName = insurerMatterParticipant.contact.organizationName;
        if (matter && matter.fireInsuranceContactInfo) {
          cirfInsuranceDetail.policyNumber = matter.fireInsuranceContactInfo.documentationValue;
        }
        this.cirfMatterData.fireInsuranceDetail = cirfInsuranceDetail;
      }
    }
  }

  mapIntroductoryInfo(matter: Matter, firmName: string): void {
    this.cirfMatterData.firmName = firmName;
    if (matter.isCustomMatter()) {
      this.cirfMatterData.legalDescriptionSummary = matter.matterPropertyWithCondo.legalDescriptionSummary;
    } else {
      this.cirfMatterData.propertyAddressText = matter.matterProperties[ 0 ].address.addressTextWithProvinceName;
    }
    this.cirfMatterData.closingDate = Utils.isNotValidDate(matter.matterCloseDate) ? '' : matter.matterCloseDate;
  }

  cleanUpCirf(): void {
    this.formData = JSON.stringify(this.cirfMatterData);
  }

  getMappedSpousalStatusON = (matterParticipant: MatterParticipant): string => {
    let spousalStatus = '';
    if (matterParticipant.notSpouse) {
      spousalStatus = 'NOT_MARRIED';
    }
    if (matterParticipant.matterParticipantSpouse || matterParticipant.consentedSpouse || matterParticipant.notAsFamilyResidence || matterParticipant.notMatrimonialHome
      || matterParticipant.neitherResidedOnPropertySinceMarriage || matterParticipant.consentedSpouseRegisteredInLTO) {
      spousalStatus = 'MARRIED';
    }
    if (matterParticipant.separatedSpouseNotFamilyResidence || matterParticipant.separatedSpouseReleasedRights) {
      spousalStatus = 'SEPARATED';
    }
    return spousalStatus;
  };

  getMappedSpousalStatusBC = (matterParticipant: MatterParticipant): string => {
    return '';
  };

  getMappedSpousalStatusAB = (matterParticipant: MatterParticipant): string => {
    let spousalStatus = '';
    if (matterParticipant.notSpouse) {
      spousalStatus = 'NOT_MARRIED';
    }
    if (matterParticipant.consentedSpouse || matterParticipant.neitherResidedOnPropertySinceMarriage || matterParticipant.consentedSpouseRegisteredInLTO) {
      spousalStatus = 'MARRIED';
    }
    return spousalStatus;
  };

  getMappedSpousalStatusMB = (matterParticipant: MatterParticipant): string => {
    let spousalStatus = '';
    if (matterParticipant.spouseTransferee || matterParticipant.matterParticipantSpouse || matterParticipant.consentedSpouse
      || matterParticipant.notHomestead || matterParticipant.dispensingOrderWithConsent) {
      spousalStatus = 'MARRIED';
    }
    return spousalStatus;
  };

  getMappedSpousalStatusSK = (matterParticipant: MatterParticipant): string => {
    let spousalStatus = '';
    if (matterParticipant.notSpouse) {
      spousalStatus = 'NOT_MARRIED';
    }
    if (matterParticipant.consentedSpouse || matterParticipant.notAsFamilyResidence || matterParticipant.matterParticipantSpouse
      || matterParticipant.interspousalAgreement || matterParticipant.authorizedByCourt || matterParticipant.notMatrimonialHome || matterParticipant.spouseTransferee) {
      spousalStatus = 'MARRIED';
    }
    return spousalStatus;
  };

  getMappedSpousalStatusNS = (matterParticipant: MatterParticipant): string => {
    let spousalStatus = '';
    if (matterParticipant.notSpouse || matterParticipant.weAreNotSpouses || matterParticipant.notOccupiedByAnyShareHolder || matterParticipant.other) {
      spousalStatus = 'NOT_MARRIED';
    }
    if (matterParticipant.matterParticipantSpouse || matterParticipant.consentedSpouse || matterParticipant.notMatrimonialHome || matterParticipant.spouseBySeparationAgreement
      || matterParticipant.spouseByVirtueOfOrder || matterParticipant.designatedAnotherPropertyAsMatrimonialHome) {
      spousalStatus = 'MARRIED';
    }
    return spousalStatus;
  };

  getMappedSpousalStatusNB = (matterParticipant: MatterParticipant): string => {
    return matterParticipant.familyLawActs && matterParticipant.familyLawActs.length ? matterParticipant.familyLawActs[ 0 ].maritalStatus : '';
  };

  getMappedSpousalStatus = {
    'ON': this.getMappedSpousalStatusON,
    'AB': this.getMappedSpousalStatusAB,
    'MB': this.getMappedSpousalStatusMB,
    'SK': this.getMappedSpousalStatusSK,
    'NS': this.getMappedSpousalStatusNS,
    'NB': this.getMappedSpousalStatusNB,
    'BC': this.getMappedSpousalStatusBC
  };

  getMappedIsFamilyResidenceON = (matterParticipant: MatterParticipant): string => {
    let isFamilyResidence = '';
    if (matterParticipant.matterParticipantSpouse || matterParticipant.consentedSpouse || matterParticipant.consentedSpouseRegisteredInLTO || matterParticipant.interspousalAgreement) {
      isFamilyResidence = DpBooleanValueTypes.YES;
    }
    if (matterParticipant.notAsFamilyResidence || matterParticipant.notMatrimonialHome || matterParticipant.neitherResidedOnPropertySinceMarriage
      || matterParticipant.authorizedByCourt || matterParticipant.spouseTransferee || matterParticipant.notHomestead || matterParticipant.dispensingOrderWithConsent) {
      isFamilyResidence = DpBooleanValueTypes.NO;
    }
    return isFamilyResidence;
  };

  getMappedIsFamilyResidenceBC = (matterParticipant: MatterParticipant): string => {
    return '';
  };

  getMappedIsFamilyResidenceMB = (matterParticipant: MatterParticipant): string => {
    let isFamilyResidence = '';
    // Handle MB Sale Matter "MATTER_PARTICIPANT_SPOUSE"
    // The transferee <Name of Spouse> is my spouse or common-law partner. This transferee is acquiring all my interest in this land. No other person has homestead rights in this land.
    if (matterParticipant.matterParticipantSpouse && this.cirfMatterData.matterType == 'SALE') {
      isFamilyResidence = DpBooleanValueTypes.NO;
    } else {
      if (matterParticipant.matterParticipantSpouse || matterParticipant.consentedSpouse || matterParticipant.consentedSpouseRegisteredInLTO || matterParticipant.interspousalAgreement || matterParticipant.spouseTransferee) {
        isFamilyResidence = DpBooleanValueTypes.YES;
      }
      if (matterParticipant.notAsFamilyResidence || matterParticipant.notMatrimonialHome || matterParticipant.neitherResidedOnPropertySinceMarriage
        || matterParticipant.authorizedByCourt || matterParticipant.notHomestead || matterParticipant.dispensingOrderWithConsent) {
        isFamilyResidence = DpBooleanValueTypes.NO;
      }
    }
    return isFamilyResidence;
  };

  getMappedIsFamilyResidenceNS = (matterParticipant: MatterParticipant): string => {
    let isFamilyResidence = '';
    if (matterParticipant.matterParticipantSpouse || matterParticipant.consentedSpouse || matterParticipant.spouseBySeparationAgreement) {
      isFamilyResidence = DpBooleanValueTypes.YES;
    }
    if (matterParticipant.notMatrimonialHome || matterParticipant.spouseByVirtueOfOrder || matterParticipant.designatedAnotherPropertyAsMatrimonialHome) {
      isFamilyResidence = DpBooleanValueTypes.NO;
    }
    return isFamilyResidence;
  };

  getMappedIsFamilyResidenceNB = (matterParticipant: MatterParticipant): string => {
    return matterParticipant.familyLawActs && matterParticipant.familyLawActs.length && matterParticipant.familyLawActs[ 0 ].maritalStatus == MaritalStatusTypes.MARRIED ?
      matterParticipant.familyLawActs[ 0 ].propertyOccupiedAsMaritalHome : '';
  };

  getMappedIsFamilyResidence = {
    'ON': this.getMappedIsFamilyResidenceON,
    'AB': this.getMappedIsFamilyResidenceON,
    'SK': this.getMappedIsFamilyResidenceON,
    'MB': this.getMappedIsFamilyResidenceMB,
    'NS': this.getMappedIsFamilyResidenceNS,
    'NB': this.getMappedIsFamilyResidenceNB,
    'BC': this.getMappedIsFamilyResidenceBC
  };

  getMappedIsSpouseOnTitleON = (matterParticipant: MatterParticipant): string => {
    let isSpouseOnTitle = '';
    if (matterParticipant.matterParticipantSpouse) {
      isSpouseOnTitle = DpBooleanValueTypes.YES;
    }
    if (matterParticipant.consentedSpouse || matterParticipant.consentedSpouseRegisteredInLTO || matterParticipant.interspousalAgreement || matterParticipant.authorizedByCourt) {
      isSpouseOnTitle = DpBooleanValueTypes.NO;
    }
    return isSpouseOnTitle;
  };

  getMappedIsSpouseOnTitleBC = (matterParticipant: MatterParticipant): string => {
    return '';
  };

  getMappedIsSpouseOnTitleMB = (matterParticipant: MatterParticipant): string => {
    let isSpouseOnTitle = '';
    // Handle MB Sale Matter "MATTER_PARTICIPANT_SPOUSE"
    // The transferee <Name of Spouse> is my spouse or common-law partner. This transferee is acquiring all my interest in this land. No other person has homestead rights in this land.
    if (matterParticipant.matterParticipantSpouse && this.cirfMatterData.matterType == 'SALE') {
      isSpouseOnTitle = DpBooleanValueTypes.NO;
    } else {
      if (matterParticipant.matterParticipantSpouse || matterParticipant.spouseTransferee) {
        isSpouseOnTitle = DpBooleanValueTypes.YES;
      }
      if (matterParticipant.consentedSpouse || matterParticipant.consentedSpouseRegisteredInLTO || matterParticipant.interspousalAgreement || matterParticipant.authorizedByCourt) {
        isSpouseOnTitle = DpBooleanValueTypes.NO;
      }
    }
    return isSpouseOnTitle;
  };

  getMappedIsSpouseOnTitleNS = (matterParticipant: MatterParticipant): string => {
    let isSpouseOnTitle = '';
    if (matterParticipant.matterParticipantSpouse) {
      isSpouseOnTitle = DpBooleanValueTypes.YES;
    } else if (matterParticipant.consentedSpouse || matterParticipant.spouseBySeparationAgreement || matterParticipant.spouseByVirtueOfOrder) {
      isSpouseOnTitle = DpBooleanValueTypes.NO;
    }
    return isSpouseOnTitle;
  };

  getMappedIsSpouseOnTitleNB = (matterParticipant: MatterParticipant): string => {
    let isSpouseOnTitle = '';
    if (matterParticipant.familyLawActs && matterParticipant.familyLawActs.length) {
      if (matterParticipant.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY) {
        isSpouseOnTitle = DpBooleanValueTypes.YES;
      }
      if (matterParticipant.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING
        || matterParticipant.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.DOMESTIC_CONTRACT
        || matterParticipant.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.RELEASED_BY_COURT_ORDER
        || matterParticipant.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.RELEASED_BY_DEED
        || matterParticipant.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.DISPOSITION_AUTHORIZED_BY_COURT_ORDER
      ) {
        isSpouseOnTitle = DpBooleanValueTypes.NO;
      }
    }
    return isSpouseOnTitle;
  };

  getMappedIsSpouseOnTitle = {
    'ON': this.getMappedIsSpouseOnTitleON,
    'AB': this.getMappedIsSpouseOnTitleON,
    'SK': this.getMappedIsSpouseOnTitleON,
    'MB': this.getMappedIsSpouseOnTitleMB,
    'NS': this.getMappedIsSpouseOnTitleNS,
    'NB': this.getMappedIsSpouseOnTitleNB,
    'BC': this.getMappedIsSpouseOnTitleBC
  };

  getMappedSpouseName(matterParticipant: MatterParticipant): string {
    let spouseName = '';
    if (this.getMappedSpousalStatus[ this.cirfMatterData.provinceCode ](matterParticipant) == 'MARRIED'
      && this.getMappedIsSpouseOnTitle[ this.cirfMatterData.provinceCode ](matterParticipant) == DpBooleanValueTypes.NO
      && matterParticipant.familyLawActs[ 0 ] && matterParticipant.familyLawActs[ 0 ].consentedSpouseParticipant && matterParticipant.familyLawActs[ 0 ].consentedSpouseParticipant.contact) {
      spouseName = matterParticipant.familyLawActs[ 0 ].consentedSpouseParticipant.contact.firstLastNames;
    }
    return spouseName;
  }

  getSpouseId(matterParticipant: MatterParticipant): string {
    //ON, SK, NS
    if ((this.cirfMatterData.provinceCode == 'ON' || this.cirfMatterData.provinceCode == 'SK' || this.cirfMatterData.provinceCode == 'NS') && this.getMappedSpousalStatus[ this.cirfMatterData.provinceCode ](matterParticipant) == 'MARRIED'
      && this.getMappedIsSpouseOnTitle[ this.cirfMatterData.provinceCode ](matterParticipant) == DpBooleanValueTypes.YES && matterParticipant.spouseParticipantId) {
      return String(matterParticipant.spouseParticipantId);
    }

    //MB
    if (this.cirfMatterData.provinceCode == 'MB' && this.getMappedSpousalStatus[ this.cirfMatterData.provinceCode ](matterParticipant) == 'MARRIED'
      && matterParticipant.spouseParticipantId) {
      return String(matterParticipant.spouseParticipantId);
    }

    //NB
    if (this.cirfMatterData.provinceCode == 'NB' && this.getMappedSpousalStatus[ this.cirfMatterData.provinceCode ](matterParticipant) == 'MARRIED'
      && matterParticipant.isSpouseAPartySelectedForNB() && matterParticipant.familyLawActs[ 0 ].spouseMatterParticipantId) {
      return String(matterParticipant.familyLawActs[ 0 ].spouseMatterParticipantId);
    }

    return '';
  }

  static buildConnectCapacityOptions(connectCapacityOptions, capacityOptions): void {
    //In unity connect cirf has couple of extra options
    if (Array.isArray(connectCapacityOptions) && Array.isArray(capacityOptions)) {
      connectCapacityOptions.push({label: '', value: 'UNSPECIFIED_CAPACITY'});
      connectCapacityOptions.push({label: 'I don\'t know', value: 'I_DONT_KNOW'});
      connectCapacityOptions.push(...capacityOptions);
    }
  }

  isMatterSale(): boolean {
    return this.cirfMatterData && this.cirfMatterData.matterType && this.cirfMatterData.matterType == 'SALE';
  }

  isMatterPurchase(): boolean {
    return this.cirfMatterData && this.cirfMatterData.matterType && this.cirfMatterData.matterType == 'PURCHASE';
  }

  isMatterMortgage(): boolean {
    return this.cirfMatterData && this.cirfMatterData.matterType && this.cirfMatterData.matterType == 'MORTGAGE';
  }

  get statusDisplay(): string {
    switch (this.status) {
      case 'CANCELLED':
        return 'Cancelled';
      case 'EXPIRED':
        return 'Expired';
      case 'INPROGRESS':
        return 'Client Entering Data';
      case 'OUTSTANDING':
        return 'Outstanding';
      case 'PROCESSED':
        return 'Processed';
      case 'SUBMITTED':
        return 'Received';
      default:
        return '';
    }
  }

  get statusDisplayPreposition(): string {
    switch (this.status) {
      case 'CANCELLED':
        return 'on';
      case 'EXPIRED':
        return 'on';
      case 'INPROGRESS':
        return 'as of';
      case 'OUTSTANDING':
        return 'as of';
      case 'PROCESSED':
        return 'on';
      case 'SUBMITTED':
        return 'on';
      default:
        return '';
    }
  }

  get isActive(): boolean {
    return this.status != 'CANCELLED' && this.status != 'EXPIRED';
  }

  isMatterCirf(): boolean {
    return this.cirfType == 'MATTER';
  }

  isParticipantCirf(): boolean {
    return this.cirfType == 'PARTICIPANT';
  }

  isCancelled(): boolean {
    return this.status == 'CANCELLED';
  }

  isCustomMatter(): boolean {
    return this.cirfMatterData && this.cirfMatterData.isCustomMatter();
  }
}

