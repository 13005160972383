import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetDocument} from './teranet-document';
import {WritExecution} from './property-writ-execution';

export type PartyOrigin =
  'CURRENT_OWNER'
  | 'PURCHASER'
  | 'VENDOR'
  | 'MORTGAGOR'
  | 'GUARANTOR'
  | 'CONDO_CORPORATION'
  | 'OWNER'
  | 'TRANSFEREE'
  | 'USER_ADDED'
  | 'USER_ADDED_WRIT';
export type WritStatus = 'NOT_SEARCHED' | 'CLEAR' | 'EXECUTION';
export type PartyType = 'Person' | 'Company'

export class Writ extends BaseEntity {
  id: number;
  parcelRegisterId: number;
  writPartyData: WritPartyData = new WritPartyData();
  certificateDate: string;
  enforcementOffice: string;
  writStatus: WritStatus;
  writDetails: string;
  declarationType: string;
  writCertificate: TeranetDocument;
  writExecutions: WritExecution[] = [];
  searchTimeStamp: number;

  constructor(w?: Writ) {
    super(w);
    if (w) {
      if (w.writPartyData) {
        this.writPartyData = new WritPartyData(w.writPartyData);
      }
      if (w.writCertificate) {
        this.writCertificate = new TeranetDocument(w.writCertificate);
      }
      if (w.writExecutions) {
        for (let o of w.writExecutions) {
          this.writExecutions.push(new WritExecution(o));
        }
      }
    }
  }

  get partyOrigin(): PartyOrigin {
    return this.writPartyData.partyOrigin;
  }

  set partyOrigin(partyOrigin: PartyOrigin) {
    this.writPartyData.partyOrigin = partyOrigin;
  }

  get lastName(): string {
    return this.writPartyData.lastName;
  }

  set lastName(lastName) {
    this.writPartyData.lastName = lastName;
  }

  get givenName(): string {
    return this.writPartyData.givenName;
  }

  set givenName(givenName) {
    this.writPartyData.givenName = givenName;
  }

  get partyNameHash(): string {
    return this.writPartyData.partyNameHash;
  }

  get partyType(): PartyType {
    return this.writPartyData.partyType;
  }

  set partyType(partyType) {
    this.writPartyData.partyType = partyType;
  }

  get partyName(): string {
    if (this.partyType === 'Person') {
      return this.lastName + (this.givenName ? ', ' + this.givenName.trim() : '');
    } else {
      return this.givenName + (this.lastName ? this.lastName : '');
    }
  }

  get partyNameForDeclaration(): string {
    return this.givenName + (this.lastName ? ' ' + this.lastName : '');
  }

  static createWritWithDefaultValues(): Writ {
    let writ: Writ = new Writ();
    writ.writStatus = 'NOT_SEARCHED';
    return writ;
  }
}

export class WritPartyData extends BaseEntity {
  lastName: string;
  givenName: string; //Keeps first & middle names for person & business name for company
  partyType: PartyType;
  partyOrigin: PartyOrigin;

  get isCompany(): boolean {
    return this.partyType === 'Company';
  }

  get isPerson(): boolean {
    return this.partyType === 'Person';
  }

  //This method return the hash of party name. it is used for checking if same party exists in any other parcel register
  get partyNameHash(): string {
    return (this.givenName ? this.givenName.trim().toLocaleLowerCase() : '') + (this.lastName ? this.lastName.toLocaleLowerCase() : '');
  }
}
