import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Mortgage} from '../../../shared/mortgage';
import {PayoutStatement} from '../../../../shared-main/telus/payout-statement';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {PayoutHistoryWrapper} from './payout-history-wrapper';
import moment from 'moment';
import {Matter} from '../../../shared';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import * as _ from 'lodash';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class AssystPayoutHistoryModalContext {
  mortgage: Mortgage;
  payoutStatements: PayoutStatement[];
  matter: Matter;
}

@Component({
  selector: 'dp-assyst-payout-history-modal-content',
  templateUrl: 'assyst-payout-history.modal.component.html'
})
export class AssystPayoutHistoryModalComponent extends ModalComponent<AssystPayoutHistoryModalContext> implements OnInit {

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  payoutHistoryWrapperItems: PayoutHistoryWrapper[] = [];

  constructor(public dialog: MatDialogRef<AssystPayoutHistoryModalComponent>,
              public teranetService: TeranetService,
              @Inject(MAT_DIALOG_DATA) context?: AssystPayoutHistoryModalContext) {
    super(dialog, context);
  }

  ngOnInit() {
    this.buildPayoutWrapperWrapperList();
    if (this.payoutHistoryWrapperItems && this.payoutHistoryWrapperItems.length) {
      this.payoutHistoryWrapperItems[ 0 ].expanded = true;
    }
  }

  close() {
    this.dialog.close();
  }

  togglePayoutHistoryShutter(item: PayoutHistoryWrapper): void {
    item.expanded = !item.expanded;
  }

  getStatusReceivedDate(statusReceivedTimestamp: string): string {
    return moment(statusReceivedTimestamp).format('MMMM DD, YYYY hh:mmA');
  }

  getDocumentDeletedText(thirdPartyDocumentName: string): string {
    return thirdPartyDocumentName + ' (Document deleted by Lender)';
  }

  downloadDocument(thirdPartyDocumentId: number) {
    this.teranetService.downloadTeranetDocumentAndDisplay(this.context.matter.id, thirdPartyDocumentId);
  }

  buildPayoutWrapperWrapperList(isShutterOpen?: boolean): void {
    this.payoutHistoryWrapperItems = [];
    if (this.context.payoutStatements && this.context.payoutStatements.length) {
      let sortedPayoutStatements = _.sortBy(this.context.payoutStatements, [ 'vendorReferenceNumber' ]).reverse();
      sortedPayoutStatements.forEach(payout => {
        let payoutHistoryWrapper = new PayoutHistoryWrapper();
        payoutHistoryWrapper.id = payout.id;
        if (isShutterOpen) {
          payoutHistoryWrapper.expanded = isShutterOpen;
        }
        payoutHistoryWrapper.payoutStatement = payout;
        if (payout.payoutStatementStatuses && payout.payoutStatementStatuses.length) {
          payoutHistoryWrapper.payoutStatement.payoutStatementStatuses = payout.sortedPayoutStatementStatuses.reverse();
        }
        this.payoutHistoryWrapperItems.push(payoutHistoryWrapper);
      });
    }
  }
}
