import {HttpClient} from '../../../core/httpClient.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {matterApi} from '../../shared/matter-api';
import {Lender} from './lender';
import {LenderForms} from './lender-forms';

@Injectable()
export class LenderFormsService {

  private cachedLenderForms: Map<string, LenderForms[]> = new Map();
  private previousLoadSubscription;

  constructor(private http: HttpClient) {

  }

  getLenders(lenderNames: string, matterProvince: string, telusFiNumber: string = ''): Observable<Lender[]> {
    let url: string = `${ matterApi.lenderForms }?lenderNames=${ lenderNames }&applicableProvince=${ matterProvince }&telusFiNumber=${ telusFiNumber }`;
    console.log(`retrieving lender and their forms ${ url }`);
    return this.http.get(url)
    .map((response) => {
      return response[ 'Lender' ].map((item) => {
        return new Lender(item);
      });
    });
  }

  getLenderForms(lenderNames: string, matterProvince: string): Observable<LenderForms[]> {
    if (this.previousLoadSubscription) {
      this.previousLoadSubscription.unsubscribe();
    }
    if (this.cachedLenderForms.has(lenderNames)) {
      return Observable.from([ this.cachedLenderForms.get(lenderNames) ]);
    }
    return this.getLenders(lenderNames, matterProvince).map(response => {
      const lenderForms = response[ 0 ] && response[ 0 ].lenderForms || [];
      this.cachedLenderForms.set(lenderNames, lenderForms);
      return lenderForms;
    });
  }

  loadLenderForms(lenderNames: string, matterProvince: string) {
    this.previousLoadSubscription = this.getLenderForms(lenderNames, matterProvince).subscribe();
  }
}
