<span>{{placeholder}}</span>
<div class="padding-top-10 padding-bottom-10">
    <input *ngIf="!uploadInProgress" type="file" [multiple]="multiple" #fileInput [accept]="accept" [placeholder]="placeholder" (change)="upload()"
           [tabindex]="tabIndex" name="uploadedFile" (click)="reset()">
    <span *ngIf="uploadInProgress">Uploading, please wait...</span>
</div>
<div class="upload-result-error error" *ngIf="error">
    Failed to upload the file: {{error}}
</div>
<div class="upload-result-info info" *ngIf="uploadedFile">
    File "{{uploadedFile}}" uploaded successfully
</div>
