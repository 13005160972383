import {AfterViewInit, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class DocumentProductionErrorModalContext {
  public errors: string[];
}

@Component({
  selector: 'document-production-error-modal-content',
  templateUrl: './document-production-error-modal.component.html',
  styleUrls: ['./document-production-error-modal.component.scss']
})
export class DocumentProductionErrorModal
  extends ModalComponent<DocumentProductionErrorModalContext>
  implements AfterViewInit
{
  constructor(
    public dialog: MatDialogRef<DocumentProductionErrorModal>,
    @Inject(MAT_DIALOG_DATA) context?: DocumentProductionErrorModalContext
  ) {
    super(dialog, context);
  }

  // After the view is initialized
  ngAfterViewInit(): void {
    setTimeout(() => {
      jQuery('.mat-modal-dialog').addClass('relative-position');
    });
  }

  close(): void {
    this.dialog.close();
  }
}
