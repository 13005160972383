import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {StatementOfAdjustmentHeading} from '../model/statement-adjustment-heading';
import {SoAdjOtherProrated} from '../model/so-adj-other-prorated';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {SelectItem} from 'primeng/api';
import {booleanYesNoDropDowns} from '../../../shared-main/constants';
import Utils from '../../../shared-main/utils';
import {StatementAdjustmentDisplayBuilder} from '../builders/statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ModalResult} from '../../../shared-main/enums';
import {StatementAdjustmentKey} from '../statement-adjustment-constants';
import {Matter} from '../../shared/matter';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class OtherProratedModalContext {
  closingDate: string;
  otherProrated: SoAdjOtherProrated;
  provinceDefaultTaxRate: string;
  taxRateType: string;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  matter: Matter;
  statementAdjustmentKey: string;
  addedFlag: boolean;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-other-prorated-modal-content',
  templateUrl: 'other-prorated.modal.component.html',
  providers: [DecimalPipe, CurrencyPipe, PercentPipe, ErrorService, DialogService]
})
export class OtherProratedModalComponent extends ModalComponent<OtherProratedModalContext> implements OnInit {
  otherProrated: SoAdjOtherProrated;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  taxTypes: SelectItem[];
  directionTypes: SelectItem[] = [
    {label: 'Receivable', value: 'RECEIVABLE'},
    {label: 'Payable', value: 'PAYABLE'}
  ];
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  closingDateLabel: string = '';
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<OtherProratedModalComponent>,
    public dialogService: DialogService,
    public decimalPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,
    public percentPipe: PercentPipe,
    private documentProfileCache: DocumentProfileCache,
    @Inject(MAT_DIALOG_DATA) context?: OtherProratedModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.otherProrated);
    }
  }

  checkForErrors() {
    if (!this.otherProrated.heading || this.otherProrated.heading.length == 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.heading');
    }
    if (this.otherProrated.amountToBeProrated <= 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.amount.prorated');
    }
    if (this.otherProrated.isNotValidDate(this.otherProrated.commencementDate)) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.commencement.date.empty');
    }
    if (this.otherProrated.isNotValidDate(this.otherProrated.expiryDate)) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.expiry.date.empty');
    }
    if (this.otherProrated.getDateDiff(this.otherProrated.commencementDate, this.context.closingDate) < 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.commencement.date.greater.adj');
    }
    if (this.otherProrated.getDateDiff(this.context.closingDate, this.otherProrated.expiryDate) < 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.expiry.date.less.adj');
    }
    if (this.otherProrated.getDateDiff(this.otherProrated.commencementDate, this.otherProrated.expiryDate) <= 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.prorated.commencement.date.greater.expiry');
    }
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj && !!this.context.addedFlag;
  }

  ngOnInit() {
    this.taxTypes = Utils.loadTaxSelectItem(this.context.taxRateType, this.context.matter.soaProvincialHst);

    this.statementAdjustmentDisplayUtil = new StatementAdjustmentDisplayBuilder(
      this.decimalPipe,
      this.currencyPipe,
      this.percentPipe
    );
    this.statementAdjustmentDisplayUtil.documentProfileCache = this.documentProfileCache;
    this.statementAdjustmentDisplayUtil.setMatter(this.context.matter);
    if (this.context.otherProrated) {
      this.otherProrated = new SoAdjOtherProrated(this.context.otherProrated);
    } else {
      this.otherProrated = new SoAdjOtherProrated();
      this.otherProrated.direction = 'PAYABLE';
      this.otherProrated.vendorPaidFullAmount = true;
      this.otherProrated.amountToBeProrated = 0;
      this.otherProrated.amountReceived = 0;
      this.otherProrated.taxType = this.context.provinceDefaultTaxRate;
      this.otherProrated.applyTax = false;
      this.otherProrated.applyPst = false;
      this.otherProrated.hstRate = this.context.matter.soaHst;
      this.otherProrated.gstRate = this.context.matter.soaFederalHst;
      this.otherProrated.pstRate = this.context.matter.soaProvincialHst;
      if (this.isHomeOwnersResidentFee()) {
        this.otherProrated.heading = "HOMEOWNERS RESIDENT'S FEE";
      }
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  onDateChange(event: any, dateType: string): void {
    this.otherProrated[dateType] = event.rawDate;
  }

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0) {
      console.log('rebuilding adjustment details');
      this.cachedDetails = this.statementAdjustmentDisplayUtil.getDisplayItems(
        this.otherProrated,
        this.context.closingDate,
        true,
        this.context.statementAdjustmentKey
      );
      this.cachedDetails.pop(); // remove last one about credit (it has another formatting on main "Statement of Adjustment" screen)
    }
    return this.cachedDetails;
  }

  get creditAmount(): number {
    return this.otherProrated.getCreditAmount(
      this.context.closingDate,
      this.statementAdjustmentKey,
      this.paysForDateOfClosing
    );
  }

  getCreditType(): string {
    return this.otherProrated.getCreditType(
      this.context.closingDate,
      this.statementAdjustmentKey,
      this.paysForDateOfClosing
    );
  }

  get vendorHasPaidOrReceivedFullAmount(): boolean {
    return Utils.convertToBoolean(this.otherProrated.vendorPaidFullAmount);
  }

  get showGstHstPercentageFields(): boolean {
    return this.otherProrated && Utils.convertToBoolean(this.otherProrated.applyTax);
  }

  get showPstPercentageFields(): boolean {
    return (
      this.otherProrated && this.otherProrated.taxType == 'GST' && Utils.convertToBoolean(this.otherProrated.applyPst)
    );
  }

  isHomeOwnersResidentFee(): boolean {
    return this.context.statementAdjustmentKey === StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE;
  }

  isOtherProrated(): boolean {
    return this.context.statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED;
  }

  get adjustmentType(): string {
    return this.isHomeOwnersResidentFee() ? "Homeowners Resident's Fee" : 'Other (Prorated Amount)';
  }

  get statementAdjustmentKey(): string {
    return this.context.statementAdjustmentKey;
  }

  get paysForDateOfClosing(): string {
    return this.context.matter.paysForDateOfClosing;
  }

  get purchaserLabel(): string {
    return this.context.matter && this.context.matter.statementOfAdjustmentHeading
      ? this.context.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.matter && this.context.matter.statementOfAdjustmentHeading
      ? this.context.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }
}
