<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-10">
            <h1>TitlePLUS</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>

    <div class="row modal-body view-port" *ngIf="titlePlusPortalURL">
        <iframe height="100%" width="100%" [src]="titlePlusPortalURL" #titlePlusFrame
        ></iframe>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Close
            </button>
        </div>
    </div>
</div>
