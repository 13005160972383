import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MatterProperty} from './matter-property';

export class SelectUnitLevelPlanResult extends BaseEntity {
  plan: MatterProperty;
  editCommonExpenseCommand: string;
  navigateToNextMatter: boolean;
  navigateToPreviousMatter: boolean;
  isDirty: boolean;

  constructor(selectUnitLevelPlanResult?: SelectUnitLevelPlanResult) {
    super(selectUnitLevelPlanResult);
    if (selectUnitLevelPlanResult) {
      this.plan = new MatterProperty(selectUnitLevelPlanResult.plan);
    }
  }

}
