<!--modal-->
<div class="custom-modal-container form-horizontal ignore-unsaved-leave-prompt">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1>Merge Documents</h1>
            </div>
        </div>
    </div>
    <div class="modal-body" style="overflow: hidden">
        <ng-container *ngIf="isDocumentOrderStage">
        <div class="form-group">
            <div class="col-lg-12 position-fixed z-index-2 fixed-header-document-merge">
                <label class="control-label col-lg-3">
                    New file name
                </label>
                <div class="col-lg-5">
                    <input type="text"
                           id="fileName"
                           name="fileName"
                           maxlength="255"
                           (blur)="fileNameRemoveSpecialChars()"
                           class="form-control"
                           [(ngModel)]="fileName"
                    />
                </div>
                <label class="control-label col-lg-4 text-left">
                    {{outputFileType}}
                </label>
            </div>
            <div class="col-lg-12 text-right position-fixed z-index-1 padding-top-40 padding-right-20 fixed-header-document-merge fixed-header-document-left" *ngIf="templateIdList && templateIdList.length > 0">
                <button label="Delete" class="secondary-button" (click)="delete()"
                        [disabled]="activeListItem < 0 || templateIdList.length == 0">
                    <span>Delete</span>
                    <div class="clear"></div>
                </button>
                <button label="Move Up" class="secondary-button" (click)="moveUp()"
                        [disabled]="activeListItem < 0 || activeListItem == 0 || templateIdList.length == 0">
                    <span>Move Up</span>
                    <div class="clear"></div>
                </button>
                <button label="Move Down" class="secondary-button" (click)="moveDown()"
                        [disabled]="activeListItem < 0 || activeListItem == templateIdList.length - 1  || templateIdList.length == 0">
                    <span>Move Down</span>
                    <div class="clear"></div>
                </button>
            </div>

            <div class="col-lg-12 padding-top-30" *ngIf="templateIdList && templateIdList.length > 0">
                <table class="doc-template-matter-list-table"  dp-table-key-commands>
                    <thead class="fixed-header-document-merge-table-head">
                    <tr>
                        <th style="width: 1000px !important;">
                            Master Templates to be merged
                        </th>
                        <th style="width: 1000px !important;">
                            Description
                        </th>

                    </tr>
                    </thead>
                    <tbody style="height: 250px !important;">
                    <ng-container *ngFor="let template of templateIdList; let i=index;">
                        <tr [ngClass]="template.active ? 'active' : ''"
                            (click)="selectTemplate(template)"
                            (focus)="setActiveIndex(i)">
                            <td style="width:1000px !important;">
                               {{template.fileName}}
                            </td>
                            <td style="width:1000px !important;">
                               {{template.description}}
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        </ng-container>
        <ng-container *ngIf="isDocumentProduceStage">
            <div class="col-lg-12 text-center">
                <label class="control-label">
                    Merged document being generated. Please wait.
                </label>
            </div>
            <div style="background-color: #fff;z-index: 100; text-align: center;">
                <img src="assets/img/spinner.gif"/>
            </div>
        </ng-container>
        <ng-container *ngIf="isDocumentGeneratedStage">
            <div class="col-lg-12 padding-top-30" *ngIf="generatedDocuments && generatedDocuments.length > 0">
                <div class="col-lg-12 position-fixed z-index-2 text-left fixed-header-document-merge">
                    <label class="control-label margin-left-30-ie">
                        Document generation is complete. Click on link to open document or access it from the Matter Documents tab.
                    </label>
                </div>
                <table class="doc-template-matter-list-table"  dp-table-key-commands>
                    <thead class="fixed-header-document-merge-table-head">
                    <tr>
                        <th style="width: 1000px !important;">
                            Produced Documents
                        </th>

                    </tr>
                    </thead>
                    <tbody style="height: 250px !important;">
                    <ng-container *ngFor="let document of generatedDocuments">
                        <tr (click)="openDocument(document ,$event)" (keyup.enter)="openDocument(document ,$event)">
                            <td style="width:1000px !important;">
                                <a class="primary">{{document.documentName}}</a>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="isDocumentFailedStage">
            <div class="col-lg-12 text-center">
                 <label class="control-label">
                      Merge has failed to complete. Please try again.
                 </label>
            </div>
        </ng-container>
    </div>
    <div class="row modal-footer padding-top-20">
        <div class="col-lg-12 buttons">
            <ng-container *ngIf="isDocumentOrderStage">
            <button type="button"
                    [disabled]="templateIdList.length == 0 || !fileName || !fileName.length"
                    (click)="produce()"
                    class="dp-btn dp-btn-green">
                <span>Produce</span>
            </button>
            <button type="button"
                    (click)="cancel()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            </ng-container>
            <ng-container *ngIf="isDocumentProduceStage">
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </ng-container>
            <ng-container *ngIf="isDocumentGeneratedStage || isDocumentFailedStage">
                <button type="button"
                        (click)="ok()"
                        class="dp-btn dp-btn-green">
                    <span>OK</span>
                </button>
            </ng-container>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
