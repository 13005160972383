import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {EmailFieldService} from './email-field/email-field-service';
import {NgModel} from '@angular/forms';
import {Utils} from '../matters/shared';

@Directive({
  selector: '[dp-email-field]'
})

export class DpEmailDirective {
  constructor(private el: ElementRef, private emailFieldService: EmailFieldService, private model: NgModel) {
  }

  @Input('dp-email-field') fieldKey: string = '';

  buttonElement: HTMLElement;
  mailTo: string[] = [];
  directiveReady: boolean = false;

  @HostListener('keyup', [ '$event' ]) keyEvent(event: KeyboardEvent) {
    setTimeout(this.toggleBtnVisibility, 100);
  }

  @HostListener('cut', [ '$event' ]) onCut(event: KeyboardEvent) {
    setTimeout(this.toggleBtnVisibility, 100);
  }

  @HostListener('paste', [ '$event' ]) onPaste(event: KeyboardEvent) {
    setTimeout(this.toggleBtnVisibility, 100);
  }

  @HostListener('focus', [ '$event' ]) onFocus(event: KeyboardEvent) {
    setTimeout(this.toggleBtnVisibility, 100);
  }

  @HostListener('change', [ '$event' ]) onChange(event: KeyboardEvent) {
    setTimeout(this.toggleBtnVisibility, 100);
  }

  @HostListener('mouseover', [ '$event' ]) onMouseOver(event: KeyboardEvent) {
    setTimeout(this.toggleBtnVisibility, 100);
  }

  toggleBtnVisibility = () => {
    if (this.buttonElement) {
      if (this.directiveReady && this.el.nativeElement.value) {
        this.splitEmails(this.el.nativeElement.value);
        if (this.mailTo.length) {
          this.buttonElement.style.visibility = 'visible';
        } else {
          this.buttonElement.style.visibility = 'hidden';
        }
      } else {
        this.buttonElement.style.visibility = 'hidden';
      }
    }
  };

  ngOnInit(): void {
    this.model.valueChanges.subscribe(this.onValueChanges);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.createEmailButton();
    }, 300);

  }

  private onValueChanges = (newValue: string): void => {
    setTimeout(this.toggleBtnVisibility, 100);
  };

  createEmailButton() {
    this.buttonElement = document.createElement('button');
    //Adding 2 classes in multiple steps because IE has issue with doing that in one step.
    //this.buttonElement.classList.add('button-glyph', 'not-disabled');
    this.buttonElement.classList.add('button-glyph');
    this.buttonElement.classList.add('not-disabled');
    this.buttonElement.setAttribute('title', 'Click to Email this address');
    let buttonSpan = document.createElement('span');
    buttonSpan.classList.add('fal');
    buttonSpan.classList.add('fa-envelope');
    this.buttonElement.appendChild(buttonSpan);
    this.buttonElement.onclick = this.initiateEmail;
    this.el.nativeElement.parentNode.insertBefore(this.buttonElement, this.el.nativeElement.nextSibling);
    this.el.nativeElement.classList.add('padding-right-30');
    this.splitEmails(this.el.nativeElement.value);
    if (this.mailTo.length) {
      this.buttonElement.style.visibility = 'visible';
    } else {
      this.buttonElement.style.visibility = 'hidden';
    }
    this.directiveReady = true;

  }

  splitEmails(value: string) {
    //To handle multiple emails & to selected only the valid ones
    let strArray = value.replace(/\s/g, '').split(';');
    this.mailTo = [];
    strArray.forEach((str) => {
      if (this.validateEmail(str)) {
        this.mailTo.push(str);
      }
    });
  }

  initiateEmail = () => {
    if (document.activeElement == this.buttonElement) {
      let mailSubject = this.getMailSubject();
      //Encode URI component for any special characters like "&"
      mailSubject = mailSubject ? encodeURIComponent(mailSubject) : '';
      let mailToUrl = 'mailto:' + this.mailTo.join(';') + '?subject=' + mailSubject;
      DpEmailDirective.initiateSendWithLocalEmailClient(mailToUrl);
    }
  };

  validateEmail(email: string): boolean {
    let re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return re.test(email.toLowerCase());
  }

  getMailSubject(): string {
    return this.emailFieldService.getMailSubject(this.fieldKey);
  }

  /**
   * Initiate the email send using the user's computer email client.
   * If the email sending is registered to an external application (like Outlook), the new window that's opened to trigger mail sending will close
   * automatically in the background.
   * If gmail (or another web based client) is configured for email sending, the new window will remain open so the client can review and send the email
   * themselves
   * @param mailToUrl A full mailto:... URL string (can include subject and body, too)
   */
  static initiateSendWithLocalEmailClient(mailToUrl: string) {
    Utils.checkIfMailToUrlIsOverLimit(mailToUrl, true);
    let util = new Utils();
    if (util.isIE()) {
      let emailClient: any = window.open('', '_blank');
      emailClient.location = mailToUrl;
      setTimeout(() => {
        if (emailClient && emailClient.location && emailClient.location.href && !emailClient.location.href.startsWith('http')) {
          emailClient.close();
        }
      }, 2000);
    } else {
      let emailClient = window.open(mailToUrl);
      if (emailClient) {
        emailClient.onblur = () => {
          //Only check if need to close on blur. If the email is through an external client, it would be brought to front
          // automatically and this handler would be triggered. Otherwise, the window stays in focus while the browser navigates to gmail or similar.
          // console.log("Mailto browser window focus lost, likely given to the external email client");
          setTimeout(() => {
            //Once the mailto window loses focus, we wait a bit to check if the mail is handled through chrome or another mail client.
            // If it's through a browser email service, the URL would have changed to an http:// or https:// by then (unless the user changes
            // focus quickly themselves, in which case we're out of luck)
            // If the latter, the URL will remain unchanged (mailto or blank), so we can close the window, it's served its purpose
            if (emailClient && emailClient.location && emailClient.location.href && !emailClient.location.href.startsWith('http')) {
              emailClient.close();
            }
          }, 2000);
        };
      }
    }
  }
}
