import {Component, Inject} from '@angular/core';
import {ActionTakenTypes, DuplicateContactData} from './duplicate-contact-data';
import {Contact} from '../../matters/shared';
import {DuplicateProspectModalResult} from '../../shared-main/enums';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {DialogService} from '../../shared/dialog/dialog.service';
import Utils from '../../shared-main/utils';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CloseGuard, ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class DuplicateProspectModalContext {
  duplicateProspectData: DuplicateContactData;
  isWizard: boolean;
}

@Component({
  selector: 'dp-duplicate-prospect-modal',
  templateUrl: 'duplicate-prospect.modal.component.html',
  providers: [ErrorService],
  styleUrls: ['./duplicate-prospect.modal.component.scss']
})
export class DuplicateProspectModalComponent extends ModalComponent<DuplicateProspectModalContext> implements CloseGuard {

  selectedContactId: number = -1;

  constructor(
    public dialog: MatDialogRef<DuplicateProspectModalComponent>,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: DuplicateProspectModalContext
  ) {
    super(dialog, context);
    this.setCloseGuard(this);
  }

  get duplicateSourceContacts(): Contact[] {
    if (
      this.context &&
      this.context.duplicateProspectData &&
      Array.isArray(this.context.duplicateProspectData.duplicateSourceContacts)
    ) {
      return this.context.duplicateProspectData.duplicateSourceContacts;
    } else {
      return [];
    }
  }

  onContactSelected(suggestedContact: Contact): void {
    this.selectedContactId = suggestedContact.id;
    this.context.duplicateProspectData.replacedSourceContactId = suggestedContact.id;
  }

  ignore(): void {
    this.context.duplicateProspectData.replacedSourceContactId = undefined;
    this.context.duplicateProspectData.actionTaken = ActionTakenTypes.DO_NOTHING;
    if (!this.context.isWizard) {
      this.dialogService
        .confirm(
          'Confirmation',
          "You have elected to leave the referral's prospect unchanged for now.\n" + 'You can review this later.',
          false,
          'OK',
          null,
          true
        )
        .subscribe((res) => {
          if (res) {
            this.dialog.close(DuplicateProspectModalResult.IGNORE);
          }
        });
    } else {
      this.dialog.close(DuplicateProspectModalResult.IGNORE);
    }
  }

  Keep(): void {
    this.context.duplicateProspectData.replacedSourceContactId = undefined;
    this.context.duplicateProspectData.actionTaken = ActionTakenTypes.KEEP;
    if (!this.context.isWizard) {
      this.dialogService
        .confirm('Confirmation', "You have elected to keep the referral's prospect.", false, 'OK')
        .subscribe((res) => {
          if (res) {
            this.dialog.close(DuplicateProspectModalResult.KEEP);
          }
        });
    } else {
      this.dialog.close(DuplicateProspectModalResult.KEEP);
    }
  }

  replace(): void {
    this.errorService.clearAllSaveErrors();
    if (!this.context.duplicateProspectData.replacedSourceContactId) {
      //Selected Province Code is not in the user provinces
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(
          'duplicateProspect.replacedSourceContactId',
          'Please select a contact for replacing.',
          null,
          'ERROR'
        )
      );
    }

    if (this.errorService.hasNoErrors()) {
      this.context.duplicateProspectData.actionTaken = ActionTakenTypes.REPLACE;
      if (!this.context.isWizard) {
        this.dialogService
          .confirm(
            'Confirmation',
            "You have elected to replace the referral's prospect with the selected contact.",
            false,
            'Ok'
          )
          .subscribe((res) => {
            if (res) {
              this.dialog.close(DuplicateProspectModalResult.REPLACE);
            }
          });
      } else {
        this.dialog.close(DuplicateProspectModalResult.REPLACE);
      }
    }
  }

  contactListName(): string {
    if (
      this.context &&
      this.context.duplicateProspectData &&
      Array.isArray(this.context.duplicateProspectData.duplicateSourceContacts)
    ) {
      let nameListString: string = Utils.concatenateNames(
        this.context.duplicateProspectData.duplicateSourceContacts.map((item) => item.genericName)
      );
      return nameListString ? nameListString : '';
    } else {
      return '';
    }
  }

  existingProspectName(): string {
    return this.context.isWizard && this.context.duplicateProspectData
      ? this.context.duplicateProspectData.genericName
      : this.context &&
          this.context.duplicateProspectData &&
          this.context.duplicateProspectData.existingProspectMatterParticipant &&
          this.context.duplicateProspectData.existingProspectMatterParticipant.contact &&
          this.context.duplicateProspectData.existingProspectMatterParticipant.contact.genericName;
  }

  async beforeDismiss(): Promise<boolean> {
    this.ignore();
    return true;
  }
}
