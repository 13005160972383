import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {loginResponseKey} from './login-response-key';
import {accountApi} from '../admin/accounts/shared/account-api';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {LoginDTO} from '../login';
import {AuthZService} from './authz/auth-z.service';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {Observable} from 'rxjs/index';
import {HttpClient} from './httpClient.service';
import {ReferenceDataService} from '../shared-main/reference-data/reference-data.service';
import {FeatureService} from "../feature-service";
import {UserStateService} from "../shared-main/user-state/user-state.service";
import {UserSessionService} from "../admin/active-user-sessions/user-session.service";

@Injectable()
export class AuthNService {
  constructor(private httpClient: HttpClient,
              private logger: Logger,
              private featureService: FeatureService,
              private authZService: AuthZService,
              public referenceDataService: ReferenceDataService,
              private userSessionService: UserSessionService,
              private userStateService: UserStateService) {
  }

  /**
   * Login service for authorization
   * After authorization it puts authentication details in session storage for the loggedInUser
   */
  login(user: LoginDTO) {
    return this.httpClient.post(accountApi.login, user)
      .map(res => {
        let authenticationSuccessResponse = res[loginResponseKey.loginSuccess];
        if (authenticationSuccessResponse) {
          this.userStateService.setUserRegion(authenticationSuccessResponse.defaultProvinceCode, authenticationSuccessResponse.userProvinces);
          this.userSessionService.initUserSession(authenticationSuccessResponse);
          return true;
        } else {
          return false;
        }
      });
  }

  /**
   * This methods is used in AuthGuard service
   * Checks if user is authenticated by checking the details in the sessionStorage
   * If user is authenticated return true
   * If user is not authenticated returns false
   */
  isUPMTokenActive() {
    let authorization = this.httpClient.getToken();
    let loggedInUser = sessionStorage.getItem(SESSION_STORAGE_KEYS.userLogin);
    if (authorization && loggedInUser) {
      return true;
    } else {
      this.logger.debug(`authorization failed`);
      return false;
    }
  }

  verifyPassword(verifyRequest: LoginDTO): Observable<any> {
    return this.httpClient.post(`${userAccountProfilesApi.updateLoginParams}`, verifyRequest)
      .map((res) => {
        return res;
      });
  }

  isSSOTokenActive(): boolean {
    if (this.featureService.isSSOEnabled &&
      sessionStorage.getItem(SESSION_STORAGE_KEYS.ssoTokenExpiry) &&
      Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.ssoTokenExpiry)) > Date.now()) {
      return true;
    }

    return false;
  }
}