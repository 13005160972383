import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {User} from '../../matters';
import {appRoutesUrlParts} from '../../app.routes';
import {AcceptTermsConditions} from '../../login/first-login/accept-terms-conditions/accept-terms-conditions';
import {messages} from '../../common';
import {AcceptTermsConditionsService} from '../../login/first-login/accept-terms-conditions/accept-terms-conditions.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Observable, of} from 'rxjs';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {Logger} from '@nsalaun/ng-logger';
import {catchError, first, map, switchMap} from 'rxjs/operators';
import {FeatureService} from '../../feature-service';
import {AuthZService} from '../../core/authz/auth-z.service';

@Injectable()
export class RequiredActionGuard implements CanActivate {
  constructor(
    private acceptTermsConditionsService: AcceptTermsConditionsService,
    public dialogService: DialogService,
    private router: Router,
    private staffProfilesService: StaffProfilesService,
    public logger: Logger,
    private featureService: FeatureService
  ) {}

  authenticatedUser: User;
  // Because we validate the terms and conditions by calling the backend, this must be an Observable.
  // Note that this makes a call to the backend for every route this guards under normal circumstances.
  // If the login flow's user JWT contained the info we needed then we could avoid these calls.
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const sessionUser = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
    if (sessionUser && this.featureService.isSSOEnabled) {
      return this.initializeStaffProfile().pipe(
        switchMap(() => {
          if (!this.authenticatedUser.userRegistrationStatus.caslacceptDate) {
            this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.acceptCasl}`]);
            return of(false);
          } else {
            return this.acceptLatestTermsAndConditionsIfHaventAlready(this.authenticatedUser);
          }
        }),
        catchError((err) => {
          this.logger.warn(err);
          return of(false);
        }),
        first()
      );
    } else {
      return of(true);
    }
  }

  acceptLatestTermsAndConditionsIfHaventAlready(user: User): Observable<boolean> {
    return this.acceptTermsConditionsService.getTermsAndConditions().pipe(
      switchMap((res: AcceptTermsConditions) => {
        const termsCreatedDate = res.createdDate;
        if (!user.termsAcceptedDate || user.termsAcceptedDate < termsCreatedDate) {
          this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.termsAndConditions}`]);
          return of(false);
        } else {
          return of(true);
        }
      }),
      catchError((error) => {
        this.dialogService.confirm('ERROR', messages.loginPage.internal, true);
        return of(false);
      })
    );
  }

  initializeStaffProfile(): Observable<void> {
    if (this.authenticatedUser) {
      // If already initialized, return an observable of void.
      return of(undefined);
    }
    return this.staffProfilesService.getLoggedInStaffProfile().pipe(
      map((staffProfiles: StaffProfiles) => {
        if (staffProfiles && staffProfiles.user) {
          this.authenticatedUser = staffProfiles.user;
        }
      }),
      catchError((err) => {
        this.logger.warn(err);
        return of(undefined); // explicitly return undefined (void)
      })
    );
  }
}
