import {Tab} from '../shared/tabbing/tab';
import {DepositManagerListState} from './deposit-manager-list-state';

export class DepositManagerTab extends Tab {

  constructor(src?: DepositManagerTab) {
    super(src);

  }

  subRoute: string;
  subRouteId: number;
  depositManagerListState: DepositManagerListState;
  provinceCode: string;

  get tabTypeForDisplay(): string {
    return this.type;
  }

}

