import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../shared/matter';
import {TrustLedgerMatterMortgageObjectTemplate} from '../shared/index';
import {currentMatter} from '../shared'; //Matter State injection
import {Mortgage} from '../shared/mortgage';
import {CleanUpMatterComponent} from '../../shared-main/cleanUpMatterData.guard';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {UserDefinedFieldService} from '../../shared-main/user-defined-field/user-defined-field-service';
import {UserDefinedField} from '../../shared-main/user-defined-field/user-defined-field';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {MortgageComponent} from './mortgage/mortgage.component';
import {AppConfig} from '../../shared-main/app-configuration';
import {MatterTab} from '../matter-tab';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import * as _ from 'lodash';
import {Section} from '../shared/section';
import {DialogService} from '../../shared/dialog/dialog.service';
import Utils from '../../shared-main/utils';
import {MortgagePriorityModal} from './mortgage/mortgage-priority/mortgage-priority.modal.component';
import {MortgageAction, MortgageDispositionType} from '../../shared-main/constants';
import {MortgageSoAdjService} from '../../shared-main/mortgage-so-adj.service';
import {UndertakingsConfigService} from '../../admin/shared/undertaking-config.service';
import {MatterCleanUpUtil} from '../shared/matter-utils/matter-clean-up-util';
import {MatterUserDefinedFieldsUtil} from '../shared/matter-utils/matter-user-defined-fields-util';
import {DEFAULT_LABEL, provinceBasedFieldLabels} from '../../../app/shared-main/province-based-field-labels';

declare var jQuery: any;

@Component({
  selector: 'dp-mortgages',
  templateUrl: 'mortgages.component.html',
  styleUrls: [ './mortgages.styles.scss' ],
  providers: [ MortgageComponent ]
})

@FocusFirstElementDecorator()
export class MortgagesComponent implements OnInit, AfterViewInit, CleanUpMatterComponent {

  //@ViewChild('dpMortgage') mortgageComponent: MortgageComponent;
  @ViewChild('btnAddNew') btnAddNew: ElementRef;
  isMortgageOpenFromEmp: boolean = false;

  constructor(public globalSaveModelService: GetGlobalSaveModelService,
              public userDefinedFieldService: UserDefinedFieldService,
              public route: ActivatedRoute,
              public router: Router,
              public tabService: TabsService,
              public appConfig: AppConfig,
              public mortgageSoAdjService: MortgageSoAdjService,
              public dialogService: DialogService,
              public undertakingsConfigService: UndertakingsConfigService) {
  }

  currentMortgage: number = 0;

  ngOnInit(): void {
    this.tabService.activeTab.section = this.route.routeConfig.path;
    this.currentMortgage = 0;
    this.isMortgageOpenFromEmp = false;
    //FIXME: this subscription needs removal (and check we don't already have it)
    this.globalSaveModelService.getItem$.subscribe(() => {
      //currentMatter.value = matter;
      this.currentMortgage = this.mortgageIndex;
      // Need to Update Route with Mortgage because after Saving Matter it again call router navigate
      // and appends only section Name , so we need to update route with mortgage.
      // so it will help in case of refresh too
      this.updateMortgageRouteOnMatterSave();
    });
    this.route
    .queryParams
    .subscribe((params: Params) => {
      if (params[ 'landing' ] && params[ 'landing' ] == 'EMP') {
        this.isMortgageOpenFromEmp = true;
      }
    });
    this.route
    .params
    .subscribe((params: Params) => {
      if (params[ 'mortgageId' ]) {
        this.currentMortgage = params[ 'mortgageId' ];
      }

    });
    if (!this.mortgages) {
      this.isExistingMortgageActive() ? this.matter.existingMortgages : this.matter.mortgages = [];
    } else if (this.mortgages && this.mortgages.length > 0) {
      this.openMortgage(this.currentMortgage, this.isMortgageOpenFromEmp);
    }

    //
    // this.router.events
    //     .subscribe((event : any) => {
    //         if(event) {
    //             if(this.router.url && (this.router.url.indexOf('mortgages') > -1
    //                  || this.router.url.indexOf('existingMortgages') > -1)//Mortgage is the current section
    //                 && event.url && (event.url.indexOf('mortgages') > -1
    //                 || event.url.indexOf('existingMortgages') > -1)
    //                 && ((event.url.indexOf('existingMortgages') > -1 && event.url.indexOf('existingMortgages/mortgage/') < 0)
    //                     || (event.url.indexOf('mortgages') > -1 && event.url.indexOf('mortgages/mortgage/') < 0))
    //                 && event.urlAfterRedirects
    //                 && event.url == event.urlAfterRedirects) {
    //                 if(this.mortgages && this.mortgages.length > 0) {
    //                     this.openMortgage(0);
    //                 }
    //             }
    //
    //         }
    //
    //     });
  }

  ngAfterViewInit() {
    if (Array.isArray(this.mortgages) && this.mortgages.length === 0) {
      this.btnAddNew.nativeElement.focus();
    }
  }

  get matter(): Matter {
    return currentMatter.value;
  }

  maximumNumberOfMortgages(): number {
    return this.matter && !!this.matter.isMatterTypeDischarge ? 1 : this.appConfig.getMaxNumberOfMortgages();
  }

  updateMortgageRouteOnMatterSave(): void {
    if (this.router.url && this.matter && this.matter.id > 0) {
      let url: string = this.router.url;
      if (((url.indexOf('existingMortgages') > -1 && url.indexOf('existingMortgages/mortgage/') < 0)
        || (url.indexOf('mortgages') > -1 && url.indexOf('mortgages/mortgage/') < 0)
        || (url.indexOf('vtbMortgages') > -1 && url.indexOf('vtbMortgages/mortgage/') < 0)) && (url.indexOf('mortgages') > -1 || url.indexOf('existingMortgages') > -1 || url.indexOf('vtbMortgages') > -1)) {
        this.openMortgage(0);
      }
    }
  }

  get mortgageIndex(): number {
    let mortgageIndex = 0;
    if (this.router.url) {
      let url: string = this.router.url;
      if (url.indexOf('mortgage/') > -1) {
        if (url.indexOf('?') > -1 && url.lastIndexOf('/') < url.lastIndexOf('?')) {
          let mIndex = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
          if (!isNaN(parseFloat(mIndex))) {
            mortgageIndex = Number(mIndex);
          }
        } else {
          let mIndex = url.substring(url.lastIndexOf('/') + 1, url.length);
          if (!isNaN(parseFloat(mIndex))) {
            mortgageIndex = Number(mIndex);
          }
        }

      }
    }

    return this.mortgages && mortgageIndex < this.mortgages.length ? mortgageIndex : 0;
  }

  get activeMortgage(): number {
    return this.currentMortgage;
  }

  changeMortgage(n) {
    //this.mortgageComponent.navSelected = 'Mortgagee';
    this.currentMortgage = n;
    this.openMortgage(n);
    jQuery('.loanType').focus();
  }

  get mortgages(): Mortgage[] {
    return this.isExistingMortgageActive() ? this.matter.existingMortgages : this.matter.mortgages;
  }

  changePriority(): void {
    // addNewMortgage will change the mortgage index of mortgages
    // the fromMortgageIndex of matterTrustLedgers will be updated with the new mortgage index
    const trustLedgerMatterMortgageObjectTemplateList: TrustLedgerMatterMortgageObjectTemplate[]
      = this.matter.buildTrustLedgerMatterMortgageObjectTemplate();
    this.dialogService.matDialogContent({
      content: MortgagePriorityModal,
      context: {
        matter: this.matter,
        updateMortgagePriority: true,
        isExistingMortgageActive: this.isExistingMortgageActive(),
        selectedMortgage: this.mortgageIndex < this.mortgages.length ? this.mortgages[ this.mortgageIndex ] : undefined
      },
      onFulfillment: (mortgagePriorityItems: any[]) => {
        if (mortgagePriorityItems) {
          mortgagePriorityItems.forEach(item => {
            if (item.mortgage && item.mortgage.mortgageType) {
              if (item.mortgage.isExistingMortgage()) {
                let mortgage = this.matter.existingMortgages.find(existingMort => existingMort.id == item.mortgage.id);
                mortgage.mortgagePriority = item.mortgage.mortgagePriority;
              } else if (item.mortgage.isUnityNewMortgage()) {
                let mortgage = this.matter.mortgages.find(mortgage => mortgage.id == item.mortgage.id);
                mortgage.mortgagePriority = item.mortgage.mortgagePriority;

                // this.matter.soaTrustLedgerCollection && this.matter.soaTrustLedgerCollection.sortTrustLedgerMatter();
                this.matter.updateTrustLedgerItemNameForUpdatePriority();
                if (this.matter.soaTrustLedgerCollection) {
                  this.matter.soaTrustLedgerCollection.updateStatementOfAccountFeeForActingOnPriorityChange();
                  if (this.matter.secondarySoaSheetsCollection) {
                    this.matter.secondarySoaSheetsCollection.forEach(collection => {
                      collection.updateStatementOfAccountFeeForActingOnPriorityChange();
                    });
                  }
                }
              }
              this.matter.mortgages = _.orderBy(this.matter.mortgages, [ 'mortgagePriority' ], [ 'asc' ]);
            }
          });
          if (this.matter.isProjectSale) {
            this.mortgageSoAdjService.rearrangeAdjustments(this.matter);
            this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
          }
          // the fromMortgageIndex of matterTrustLedgers will be updated with the new mortgage index
          this.matter.updateTrustLedgerMatterPriorityInTemplate(trustLedgerMatterMortgageObjectTemplateList);
          // this.matter.soaTrustLedgerCollection && this.matter.soaTrustLedgerCollection.sortTrustLedgerMatter();
          this.matter.updateTrustLedgerItemNameForUpdatePriority();
          if (this.matter.soaTrustLedgerCollection) {
            this.matter.mortgages.forEach(item => {
              if (item.isUnityNewMortgage()) {
                this.matter.soaTrustLedgerCollection.updateAutogeneratedMortgageBrokerFees(item);
              }
            });
            this.matter.soaTrustLedgerCollection.updateTrustLedgeMortgageLinesName();
          }
          if (this.isNewMortgageActive()) {
            this.matter.sortTrustLedgerMatterByPriority();
          }
          this.matter.updateTitleInsurance(MortgageAction.CHANGE_PRIORITY);
        }
      }
    });

  }

  addMortgage(): void {
    if (!this.validateVTBMortgages()) {
      return;
    }

    if (((this.isNewMortgageActive() && this.matter.isMortgage) || (this.isVTBMortgageActive() && this.matter.isSale)) && this.matter) {
      this.dialogService.matDialogContent({
        content: MortgagePriorityModal,
        context: {
          matter: this.matter
        },
        modalGrid: 5,
        onFulfillment: (mortgagePriority: number) => {
          if (mortgagePriority != undefined) {
            // addNewMortgage will change the mortgage index of mortgages
            const trustLedgerMatterMortgageObjectTemplateList: TrustLedgerMatterMortgageObjectTemplate[]
              = this.matter.buildTrustLedgerMatterMortgageObjectTemplate();
            this.addNewMortgage(mortgagePriority);
            // the fromMortgageIndex of matterTrustLedgers will be updated with the new mortgage index
            this.matter.updateTrustLedgerMatterPriorityInTemplate(trustLedgerMatterMortgageObjectTemplateList);
            // this.matter.soaTrustLedgerCollection && this.matter.soaTrustLedgerCollection.sortTrustLedgerMatter();
            this.matter.updateTrustLedgerItemNameForUpdatePriority();
            this.updateStatementOfAccount();
            this.updateStatementOfAdjustment();
            this.matter.updateTitleInsurance(MortgageAction.ADD, mortgagePriority);
            this.matter.updateMortgageRegistrationFee();
          }
        }
      });
    } else {
      const mortgagePriority: number = this.mortgages ? Number(this.mortgages.length + 1) : 1;
      this.addNewMortgage(mortgagePriority);
      this.updateStatementOfAccount();
      this.updateStatementOfAdjustment();
      if (this.isNewMortgageActive()) {
        this.matter.updateTitleInsurance(MortgageAction.ADD, mortgagePriority);
      }
      this.matter.updateMortgageRegistrationFee();
    }

  }

  validateVTBMortgages(): boolean {
    if (this.matter.isSale && this.matter.matterLink && this.isVTBMortgageActive()) {
      let message = '';
      if (!this.matter.isProjectSale) {
        message = 'Linked matters don\'t support VTB Mortgages.  To add a VTB mortgage, remove the link with matter ' + this.matter.matterLink.linkedMatterNumber + '.';
      } else {
        message = 'Cannot create a link to a purchase matter when a Purchaser’s mortgage is present.';
      }
      this.dialogService.confirm('Error', message, true).subscribe();
      return false;
    } else {
      return true;
    }
  }

  addNewMortgage(mortgagePriority?: number): void {
    if (!this.mortgages) {
      this.isExistingMortgageActive() ? this.matter.existingMortgages = [] : this.matter.mortgages = [];
    }
    if (this.mortgages.length < this.appConfig.getMaxNumberOfMortgages()) {
      if (!this.isExistingMortgageActive() && mortgagePriority != undefined && this.mortgages && this.mortgages.length > 0) {
        let mp = Number(mortgagePriority);
        let index = this.mortgages.findIndex(item => item.mortgagePriority > mp);
        if (index > -1) {
          let mortgage = this.matter.createMortgage(this.isExistingMortgageActive() ? 'EXISTING' : 'NEW', 'UNITY', mortgagePriority);
          this.mortgages.splice(index, 0, mortgage);
          this.openMortgageTabByIndex(index, mortgage.id);
        } else {
          let mortgage = this.matter.createMortgage(this.isExistingMortgageActive() ? 'EXISTING' : 'NEW', 'UNITY', mortgagePriority);
          this.mortgages.push(mortgage);
          this.openMortgageTabByIndex(Number(this.mortgages.length - 1), mortgage.id);
        }
      } else if (this.isExistingMortgageActive()) {
        const existingMortgage: Mortgage = this.matter.addNewExistingMortgage();
        this.openMortgageTabByIndex(Number(this.mortgages.length - 1), existingMortgage.id);
        if (this.matter.isMortgage && existingMortgage.mortgageDispositionType == MortgageDispositionType.DISCHARGED) {
          existingMortgage.undertakingDirty = true;
          this.matter.updateUndertakings(this.undertakingsConfigService);
        }
      } else {
        let mortgage = this.matter.createMortgage(this.isExistingMortgageActive() ? 'EXISTING' : 'NEW', 'UNITY', mortgagePriority);
        this.mortgages.push(mortgage);
        this.openMortgageTabByIndex(Number(this.mortgages.length - 1), mortgage.id);
      }

    } else {
      this.changeMortgage(this.mortgages.length - 1);
      this.openMortgage(this.mortgages.length - 1);
    }
    this.markMatterDirty();

  }

  public openMortgageTabByIndex(index: number, mortgageId: number): void {
    this.setMatterMortgageUserDefinedFields(mortgageId);
    this.changeMortgage(index);
    this.openMortgage(index);
  }

  public setMatterMortgageUserDefinedFields(mortgageId: number): void {
    this.userDefinedFieldService
    .getUserDefinedFieldsForLoggedInCustomerAccount(this.matter.matterType, this.matter.provinceCode)
    .subscribe((customerActUDFs: UserDefinedField[]) => {
      //console.log("setMatterMortgageUserDefinedFields | customerActUDFs=", customerActUDFs);
      if (customerActUDFs) {
        MatterUserDefinedFieldsUtil.initializeNewMortgageUserDefinedFields(this.matter, customerActUDFs, mortgageId);
      }
    });
  }

  get activeMatterTab(): MatterTab {
    return this.tabService.activeTab as MatterTab;
  }

  openMortgage(mortgageIndex: number, addQueryParams?: boolean): void {
    let mortgageUrlPrefix = 'mortgages/mortgage/';
    if (this.isExistingMortgageActive()) {
      mortgageUrlPrefix = 'existingMortgages/mortgage/';
    } else if (this.isVTBMortgageActive()) {
      mortgageUrlPrefix = 'vtbMortgages/mortgage/';
    }
    if (this.router.url && this.router.url.indexOf(mortgageUrlPrefix) > -1) {
      this.router.navigate([ this.router.url.substr(0, this.router.url.indexOf(mortgageUrlPrefix)) + mortgageUrlPrefix + mortgageIndex ], {queryParams: addQueryParams ? {'landing': 'EMP'} : {}});
      this.activeMatterTab.section = mortgageUrlPrefix + mortgageIndex;
    } else {
      this.router.navigate([ this.router.url + '/mortgage/' + mortgageIndex ], {queryParams: addQueryParams ? {'landing': 'EMP'} : {}});
      this.activeMatterTab.section = mortgageUrlPrefix + mortgageIndex;
    }
  }

  markMatterDirty() {
    this.matter.dirty = true;
  }

  cleanUpMatter(): boolean {
    MatterCleanUpUtil.cleanUpMortgages(currentMatter.value);
    return true;
  }

  updateStatementOfAccount(): void {
    if (this.matter.soaTrustLedgerCollection) {
      if ((this.isNewMortgageActive() || this.isExistingMortgageActive())) {
        this.matter.updateStatementOfAccount();
      }
      this.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach(collection => {
          collection.updateERegAndRegisterCharges();
        });
      }
    }
  }

  updateStatementOfAdjustment(): void {
    this.mortgageSoAdjService.rearrangeAdjustments(this.matter);
    this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
  }

  isExistingMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('existingMortgages') > -1);

  }

  isNewMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('mortgages') > -1);
  }

  isVTBMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('vtbMortgages') > -1);

  }

  get activeSection(): Section {
    return this.applicableSections
      ? this.applicableSections.find(item => item.active) : undefined;
  }

  get applicableSections(): Section[] {
    return this.matter
      ? this.matter.applicableSections : [];
  }

  existingTabLabel(i: number) {
    if (this.matter && !this.matter.isMatterProvinceBC) {
      return 'Existing ' + i;
    }
    return i;
  }

  existingTabLabelSuffix(i: number) {
    if (i == 1) {
      return 'st';
    } else if (i == 2) {
      return 'nd';
    } else if (i == 3) {
      return 'rd';
    } else {
      return 'th';
    }
  }

  getMortgageOrdinal(i: number, mortgage: Mortgage): string {
    if (this.isExistingMortgageActive()) {
      return Utils.getOrdinal(i);
    } else if (this.matter && this.matter.isMortgage && mortgage && mortgage.mortgagePriority != undefined) {
      return Utils.getOrdinal(mortgage.mortgagePriority);
    } else {
      return Utils.getOrdinal(i);
    }

  }

  hasMortgagesAfterClosing(): boolean {
    let allMortgages: Mortgage[] = (this.matter.existingMortgages ? this.matter.existingMortgages : [])
    .concat(this.matter.mortgages ? this.matter.mortgages : []);
    let uniqueMortgages: Mortgage[] = Array.from(new Set(allMortgages
    .filter(m => m.isMortgageDispositionRemain() || m.isUnityNewMortgage())));

    return uniqueMortgages.length > 1;
  }

  getAddNewMortgageLabelByMatter(): string {
    if (this.activeSection.sectionKey == 'EXISTING_MORTGAGE' && this.matter?.provinceCode == 'BC') {
      return provinceBasedFieldLabels.get('matter.mortgages.addNewCharge', this.matter?.provinceCode);
    }
    return provinceBasedFieldLabels.get('matter.mortgages.addNewMortgage', this.matter?.provinceCode);
  }
}
