import {Component, Inject, OnInit} from '@angular/core';
import {EventAvailabilityState} from '../event-list-state';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class EventSchedulingContext {
  eventAvailabilityState: EventAvailabilityState;
}

@Component({
  selector: 'dp-event-scheduling',
  templateUrl: 'event-scheduling.modal.component.html'
})
export class EventSchedulingModalComponent extends ModalComponent<EventSchedulingContext> implements OnInit {

  constructor(
    public dialog: MatDialogRef<EventSchedulingModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: EventSchedulingContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {}

  ok(): void {
    this.dialog.close(this.context.eventAvailabilityState);
  }

  close(): void {
    this.dialog.close();
  }

  dateTimeChange(event: any): void {
    if (this.context.eventAvailabilityState) {
      let eventAvailabilityState = new EventAvailabilityState(this.context.eventAvailabilityState);
      eventAvailabilityState.eventStartDate = event.startDate;
      eventAvailabilityState.eventEndDate = event.endDate;
      eventAvailabilityState.eventStartTime = event.startTime;
      eventAvailabilityState.eventEndTime = event.endTime;
      this.context.eventAvailabilityState = eventAvailabilityState;
    }
  }
}
