import {PTTExemptionCode} from '../shared/matter-participant';

export type PropertyTransferTax = {
  id?: number;
  transferOf: PTTTransferOf;
  transferType: string;
  propertyType: PTTPropertyType;
  exemptionCode?: PTTExemptionCode;
  bank?: string;
  grossAmount: number;
  onePercentOnFirst200kAmount: number;
  twoPercentOnRemainderUpTo2MAmount: number;
  threePercentOnRemainderOver2MAmount: number;
  twoPercentOnRemainderOver3MAmount: number;
  exemptionAmount: number;
  additionalAmount: number;
  netAmount: number;
}

export type PTTTransferOf = 'LAND_WITH_IMPROVEMENTS' | 'VACANT_LAND';

export type PTTPropertyType =
  | 'RESIDENTIAL'
  | 'RECREATIONAL'
  | 'COMMERCIAL'
  | 'FARM'
  | 'OTHER';

export const buildDefaultPtt = (): PropertyTransferTax => {
  return {
    transferOf: 'LAND_WITH_IMPROVEMENTS',
    transferType: 'FEE_SIMPLE',
    propertyType: 'RESIDENTIAL',
    grossAmount: 0.0,
    onePercentOnFirst200kAmount: 0.0,
    twoPercentOnRemainderUpTo2MAmount: 0.0,
    threePercentOnRemainderOver2MAmount: 0.0,
    twoPercentOnRemainderOver3MAmount: 0.0,
    exemptionAmount: 0.0,
    netAmount: 0.0,
    additionalAmount: 0.0
  };
};

export type PTTExemption = {
  id: number,
  exemptionCode: PTTExemptionCode;
  maxExemption: number;
  thresholdFactor: number;
  firstThreshold: number;
  secondThreshold: number;
  thirdThreshold: number;
  startDate: string;
  expirationDate?: string;
}

export type PTTExemptionConfig = Record<PTTExemptionCode, PTTExemption>