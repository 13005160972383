<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Select Contact</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-lg-12 padding-bottom-20">
            <p class="bold">A contact record may already exist for {{getSnapShotName()}}. You may select one of the existing contacts listed below or create a new one.</p>
        </div>
        <div class="container-div col-lg-12">
            <ng-container *ngFor="let suggestedContact of context.suggestedContacts; let i=index">
                <div class="col-lg-12 row-div"
                     [ngClass]="{'active': selectedContactId == suggestedContact.id}"
                     (click)="onContactSelected(suggestedContact)">
                     <span style="width: 3%;min-height: 15px !important;"  class="radio padding-top-0">
                         <input id="contactSelected-{{suggestedContact.id}}"
                                type="radio"
                               name="radio-contactSelected-{{suggestedContact.id}}"
                                [checked]="selectedContactId == suggestedContact.id" (click)="selectedContactId = suggestedContact.id"  />
                         <label for="contactSelected-{{suggestedContact.id}}"></label></span>
                    <span style="width: 30%">{{suggestedContact.displayName}}</span>
                    <span style="width: 30%">{{suggestedContact.displayAddress}}</span>
                    <span style="width: 10%">{{getGenderValue(suggestedContact.gender)}}</span>
                    <span style="width: 18%">{{suggestedContact.email}}</span>
                    <span style="width: 10%">{{suggestedContact.cellPhone}}</span>
                </div>
            </ng-container>
        </div>
        <div class="col-lg-12 padding-top-10" *ngIf="isContactSelected()">
            <p class="red bold text-center">
                Note: choosing an existing contact will result in its details replacing any information already entered into this {{openingFrom}}.<br>
                <span *ngIf="topicNotes">The current address will be preserved in the sticky note.</span>
            </p>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    [dp-read-only]="!isContactSelected()"
                    (click)="addContact()"
                    class="dp-btn secondary-button">
                <span>Use Selected</span>
            </button>
            <button type="button"
                    id="okBtn2"
                    (click)="createContact()"
                    class="dp-btn secondary-button">
                <span>Create New Contact</span>
            </button>
            <button type="button"
                    id="cancel"
                    (click)="cancel()"
                    class="dp-btn secondary-button">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</form>
