<!--<div class="change-font-block">-->
    <!--<button (click)="increaseFont()">+ Increase Font</button>-->
    <!--<button (click)="decreaseFont()">- Decrease Font</button>-->
<!--</div>-->


<a>
    Change Font
    <span (click)="decreaseFont()" class="font-selector fa fa-minus-circle"></span>
    <span (click)="resetFont()" class="font-selector fa fa-undo"></span>
    <span (click)="increaseFont()" class="font-selector fa fa-plus-circle"></span>
</a>
