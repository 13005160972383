import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {ContactName} from './contact-name';
import {AuthChallengePhone} from '../../login/first-login/trusted-phone-number/trusted-phone-number';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {PROVINCE_CODES, UserProvince} from './user-province';

export class UserRegistrationStatus extends BaseEntity {
  passwordProvidedDate: string;
  phoneProvidedDate: string;
  caslacceptDate: string;
  registrationCompleted: boolean;
}

// We only need to compare last name and first name. There is no middle name
// Avoid to compare the whole big User for improving performance.
const propertyCheckList = [ 'firstName', 'lastName' ];

export type SharingMessageNotificationMode = 'SYSTEM_MESSAGE_ONLY' | 'SYSTEM_MESSAGE_AND_EMAIL';

export class User {
  createdTimeStamp: string;
  emailId: string;
  firstName: string;
  lastName: string;
  lastUpdatedTimeStamp: string;
  password: string;
  retypePassword: string;
  oldPassword: string;
  passwordSalt: string;
  contactId: any;
  loginId: string;
  username: string;
  userStatus: string;
  accessProfileId: number;
  documentProfileId: number;
  publicAccountId: string;
  tokenType: string;
  id: number;
  rememberMe: boolean;
  registrationToken?: string;
  //registrationStatus : string;
  contactName: ContactName;
  contactActive: boolean;
  userAccountProfileId: number;
  termsAcceptedDate: Date;
  authChallengeRequired: boolean;
  authChallengePhones: AuthChallengePhone[] = [];
  userRegistrationStatus: UserRegistrationStatus;
  passwordChangeRequired: boolean;
  mustReadMessageExist: boolean;
  numberOfUnreadMessages: number;
  defaultProvinceCode: string;
  userProvinces: UserProvince[] = [];
  unitySignatureBlock: string;
  messagingDelegateUserIds: number[] = [];
  sharingMessageNotificationMode: SharingMessageNotificationMode;
  responsibleSolicitorId: number;
  responsibleLawClerkId: number;
  conveyancingCustomer: string;

  constructor(user?: User) {

    if (user) {
      for (let prop in user) {
        if (user.hasOwnProperty(prop)) {
          this[ prop ] = user[ prop ];
        }
      }
      if (Array.isArray(user.authChallengePhones)) {
        for (let i: number = 0; i < user.authChallengePhones.length; ++i) {
          this.authChallengePhones[ i ] = new AuthChallengePhone(user.authChallengePhones[ i ]);
        }
      }
      this.userProvinces = [];
      if (Array.isArray(user.userProvinces)) {
        for (let i: number = 0; i < user.userProvinces.length; ++i) {
          this.userProvinces[ i ] = new UserProvince(user.userProvinces[ i ]);
        }
      }
      if (user.userRegistrationStatus) {
        this.userRegistrationStatus = new UserRegistrationStatus(user.userRegistrationStatus);
      }
      if (Array.isArray(user.userProvinces)) {
        for (let i: number = 0; i < user.userProvinces.length; ++i) {
          this.userProvinces[ i ] = new UserProvince(user.userProvinces[ i ]);
        }
      }
    }

    if (!this.sharingMessageNotificationMode) {
      this.sharingMessageNotificationMode = 'SYSTEM_MESSAGE_ONLY';
    }
  }

  get fullName(): string {
    return `${ this.firstName } ${ this.lastName }`;
  }

  equals(other: User): boolean {
    return JSON.stringify(this, this.replacer) === JSON.stringify(other, this.replacer);
  }

  //This method is to list the properties which should be ignored in stringify
  replacer(key, val): any {
    if (key !== 'isDirty' && key !== 'isValid' && key !== 'errorMessage') {
      return val;
    }
  }

  validateUpdatePassword(errorService: ErrorService): boolean {
    errorService.clearAllSaveErrors();
    if (!this.password || (this.password && this.password.trim() == '')) {
      errorService.addDpSaveError(DPError.createDPError('user.password.MISSING'));
    }
    if (!this.retypePassword || (this.retypePassword && this.retypePassword.trim() == '')) {
      errorService.addDpSaveError(DPError.createDPError('user.retypePassword.MISSING'));
    }
    return errorService.hasNoErrors();
  }

  get isActive(): boolean {
    return (this.userStatus === 'ACTIVE');
  }

  // get isTokenForForgotPassword() : boolean {
  //     return (this.tokenType === "FORGOT_PASSWORD_TOKEN");
  // }

  get caslAccepted(): string {
    return this.userRegistrationStatus && this.userRegistrationStatus.caslacceptDate;

  }

  get trustedPhoneNumberProvided(): string {
    return this.userRegistrationStatus && this.userRegistrationStatus.phoneProvidedDate;
  }

  //Get Enabled User Provinces
  getEnabledUserProvinceCodes(): string[] {
    return this.userProvinces.filter(value => value.enabled).map(value => value.provinceCode);
  }

  get enabledUserProvinces(): UserProvince[] {
    return this.userProvinces.filter(userProvince => userProvince.enabled);
  }

  //Setter function to convert list of strings to list of entities
  setUserAccessibleProvinces(selectedProvinceCodes: string[]) {
    this.userProvinces.forEach(userProvince => {
      userProvince.enabled = !!(selectedProvinceCodes && selectedProvinceCodes.findIndex(value => value == userProvince.provinceCode) > -1);
    });
  }

  get userEnabledProvinces(): UserProvince[] {
    return this.userProvinces.filter(value => value.enabled);
  }

  isOntarioIncluded(): boolean {
    return this.userProvinces.findIndex(item => item.provinceCode == PROVINCE_CODES.ONTARIO && item.enabled) > -1;
  }

  isProvinceIncluded(provinceCode: string): boolean {
    return this.userProvinces.findIndex(item => item.provinceCode == provinceCode && item.enabled) > -1;
  }

  get registrationCompleted(): boolean {
    return this.userRegistrationStatus && this.userRegistrationStatus.registrationCompleted;
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    const ownPropertyDescriptor = Object.getOwnPropertyDescriptor(this, key);
    return ownPropertyDescriptor && ownPropertyDescriptor.hasOwnProperty('value') && (propertyCheckList.indexOf(key) >= 0);
  }
}

export class UserRedirect {
  public redirectId: string;
  public redirectType: string;
  public section: string;
  public sessionActive: boolean;
}
