import {Address} from './address';
import {DpBooleanValue} from './dp-boolean';
import {ContactUtil} from './contact-util';

export class MatterContactInfo {

  constructor(matterContactInfo?: MatterContactInfo) {

    if (matterContactInfo) {
      for (let prop in matterContactInfo) {
        if (matterContactInfo.hasOwnProperty(prop)) {
          this[ prop ] = matterContactInfo[ prop ];
        }
      }
      if (matterContactInfo.preClosingAddress) {
        this.preClosingAddress = new Address(matterContactInfo.preClosingAddress);
      }
      if (matterContactInfo.postClosingAddress) {
        this.postClosingAddress = new Address(matterContactInfo.postClosingAddress);
      }
    }
  }

  titleDetails: string;
  spousalText: string;
  spousalStatusCode: string;
  faxNumber: string;
  phoneNumberSummary: string;
  email: string;
  emailSameAsPrimaryContact: boolean;
  residingAtSubjectProperty: string;
  envelopeSalutation: string;
  envelopeSalutationContinued: string;
  dearText: string;
  directDepositExcessFunds: boolean;
  preClosingAddress: Address;
  preClosingAddressSameAsSubjectProperty: boolean;
  postClosingAddress: Address;
  titleDetailsType: string;
  individualTitleDetails: boolean = false;
  witnessKnowsTransferors: string;
  eligibleForTaxRebate: DpBooleanValue;
  isDeveloperTransferor: DpBooleanValue;

  identificationExecution: string;

  get isResideAtSubjectProperty(): boolean {
    return this.residingAtSubjectProperty === 'YES' || this.residingAtSubjectProperty === 'Y_n';
  }

  get firstEmail(): string {
    return ContactUtil.extractFirstEmail(this.email);
  }
}
