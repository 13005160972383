/**
 * Data transfer object (NOT entity) for retrieving information about duplicate Plan/Block/Lot in a project
 */
export class DuplicateLotMatterInfo {
  matterRecordNumber: string;
  purchaserReline: string;
  dupKeyData: string;
  projectRecordNumber: string;
  uiDupKeyData: string;// UI only

  //Not an entity, defining the constructor explicitly
  constructor(json?) {
    if (json) {
      for (let prop in json) {
        if (json.hasOwnProperty(prop)) {
          this[ prop ] = json[ prop ];
        }
      }
    }

  }

}
