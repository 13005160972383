<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" *ngIf="localAdj" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Adjustment Type : Assumed Mortgage</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Priority of mortgage to be assumed</label>
                    </div>
                    <div class="col-lg-2">
                        <select type="text"
                                id="mortgagePriority"
                                name="mortgagePriority"
                                [(ngModel)]="localAdj.mortgagePriority"
                                class="form-control"
                                [fieldKey]="'matter.soadj.assumed.mortgage.mortgage.priority.empty'"
                                dp-error-validator

                        >
                            <option *ngFor="let mortgagePriority of mortgagePriorityOptions" [value]="mortgagePriority.value">
                                {{mortgagePriority.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Are the figures for principal and interest combined or separate?</label>
                    </div>
                    <div class="col-lg-2">
                        <select type="text"
                                id="combinedOrSeparate"
                                name="combinedOrSeparate"
                                [(ngModel)]="localAdj.combinedOrSeparate"
                                class="form-control"
                        >
                            <option *ngFor="let combinedOrSeparate of combinedOrSeparateOptions" [value]="combinedOrSeparate.value">
                                {{combinedOrSeparate.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="form-group" *ngIf="localAdj.combinedOrSeparate == 'SEPARATE'">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Principal as at</label>
                    </div>
                    <div class="col-lg-4">
                        <dp-partial-date #fromDate
                                         (dateChange)="onDateChange($event ,'principalAsAtDate')"
                                         [fieldKey]="'matter.soadj.assumed.mortgage.principal.date.empty'"
                                         dp-error-validator
                                         [inputDate]="localAdj.principalAsAtDate"
                        >
                        </dp-partial-date>
                    </div>
                </div>
            </div>

            <div class="form-group" >
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label" *ngIf="localAdj.combinedOrSeparate == 'SEPARATE'">
                        <label class="control-label">Principal amount</label>
                    </div>
                    <div class="col-lg-3 vertical-align-label" *ngIf="localAdj.combinedOrSeparate == 'COMBINED'">
                        <label class="control-label">Principal and Interest as at {{closingDateLabel}}</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="principalAmount"
                               name="principalAmount"
                               [(ngModel)]="localAdj.principalAmount"
                               class="form-control"
                               dp-currency
                               significant-currency-digits="9"
                               [fieldKey]="'matter.soadj.assumed.mortgage.principal.amount.empty'"
                               dp-error-validator
                        />
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="localAdj.combinedOrSeparate == 'SEPARATE'">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Should the application perform the interest calculation?</label>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="performInterestCalculation"
                                name="performInterestCalculation"
                                [(ngModel)]="localAdj.performInterestCalculation"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="localAdj.performInterestCalculationBoolean && localAdj.combinedOrSeparate == 'SEPARATE'">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Enter the interest rate of the mortgage</label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="interestRate"
                               name="interestRate"
                               [(ngModel)]="localAdj.interestRate"
                               class="form-control"
                               dp-percentage
                               [max-int] = 999
                               [fieldKey]="'matter.soadj.assumed.mortgage.interest.rate.empty'"
                               dp-error-validator
                        />
                    </div>
                    <div class="col-lg-1">
                        <label class="control-label text-left">%</label>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="localAdj.combinedOrSeparate == 'SEPARATE' && !localAdj.performInterestCalculationBoolean">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Interest from {{principalAsAtDate}} to {{closingDateLabel}} to</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="interestAmount"
                               name="interestAmount"
                               [(ngModel)]="localAdj.interestAmount"
                               class="form-control"
                               dp-currency
                               [fieldKey]="'matter.soadj.assumed.mortgage.interest.amount.empty'"
                               dp-error-validator
                        />
                    </div>
                </div>
            </div>


            <!-- adjustment report -->
            <div style="border-top: 1px solid #cccccc;">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <label class="control-label" style="font-weight : bold">{{localAdj.adjustmentDescription}}</label>
                    </div>
                </div>

                <div *ngFor="let line of details">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                        <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                    </div>
                </div>

                <div>
                    <div class="col-lg-12">
                        <label class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentDisplayUtil.purchaserLabel}}</label>
                        <label *ngIf="validCalculations()" class="control-label col-lg-4">{{ localAdj.totalCreditPurchaser | currency:CAD:'symbol'}}</label>
                        <label *ngIf="!validCalculations()" class="control-label col-lg-4">$??????????.??</label>
                    </div>
                </div>

            </div>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>

            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </form>
</div>
