import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {StewartTitleService} from '../../../shared-main/stewart-title/stewart-title-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class StewartTitlePortalContext {
  fileUrl: string;
}

@Component({
  selector: 'dp-stewart-title-portal-modal',
  templateUrl: 'stewart-title-portal.modal.component.html'
})
export class StewartTitlePortalModal extends ModalComponent<StewartTitlePortalContext> implements OnInit {
  stewartTitleFileContainerUrl: SafeUrl;
  @ViewChild('stewartFrame') stewartFrame: ElementRef;

  constructor(
    public dialog: MatDialogRef<StewartTitlePortalModal>,
    public sanitizer: DomSanitizer,
    public stewartTitleService: StewartTitleService,
    @Inject(MAT_DIALOG_DATA) context?: StewartTitlePortalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.stewartTitleFileContainerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.fileUrl);
  }

  close(): void {
    //No need to invoke STG save call as it will be handled with unload event.
    //this.sendSaveMessage();
    //Clearing iFrame src so STG unload even gets invoked on closing of portal.
    this.stewartFrame.nativeElement.src = '';
    this.dialog.close({action: 'Close'});
  }

  sendSaveMessage(): void {
    //Sending a postMessage request to STG when closing the portal window to save. No need to check for a response as another GetResult call
    //will be made by the backend to retrieve status of file.
    this.stewartFrame.nativeElement.contentWindow.postMessage({messageName: 'SaveFile'}, this.context.fileUrl);
  }
}
