<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Import Driver's Licence Information</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 form-group border-bottom-2" >
            <div class="col-lg-12">
                <div class="col-lg-3 offset-lg-2">
                    <div class="form-group main-level">
                        <label class="control-label">
                            Driver's Licence Information
                        </label>
                    </div>
                </div>
                <div class="col-lg-1 text-center">
                    <dp-checkbox fieldId="allFields" fieldClass="top-8">
                        <input type="checkbox"
                               id="allFields"
                               [(ngModel)]="allFieldsSelected"
                               (change)="copyAllDriverLicenceInfo($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <div class="form-group main-level">
                        <label class="control-label">Matter Data</label>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-12 form-group">
            <div class="col-lg-12">
                <div class="form-group main-level">
                    <div class="title">
                        Personal Info
                    </div>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Name</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlName"
                        name="dlName"
                        [ngModel]="context?.driverLicenceInfo?.clientName"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="nameCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="nameCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeName($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="nameOnId"
                        name="nameOnId"
                        [(ngModel)]="localIdentificationDocument.nameOnId"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-3" *ngIf="isNameOnDriverLicenceDifferent()">
                    <p class="alert alert-warning error padding1rem margin-bottom-0">
                        <label class="control-label text-left padding-top-0">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            Name on the Driver's Licence does not match the existing client contact name
                        </label>
                    </p>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10" *ngIf="context?.contact?.isBlankMaleFemale">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Gender</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlGender"
                        name="dlGender"
                        [ngModel]="context?.driverLicenceInfo?.gender"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="genderCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="genderCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeGender($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <select class="form-control"
                            id="contactGender"
                            name="contactGender"
                            [(ngModel)]="localContact.gender"
                            class="form-control"
                            >
                        <option *ngFor="let gender of genders" [value]="gender.value">
                            {{gender.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Birth Date</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlBirthDate"
                        name="dlBirthDate"
                        [ngModel]="getFormatedDLDate(context.driverLicenceInfo.birthDate)"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="birthDateCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="birthDateCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeBirthDate($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <dp-partial-date #partialBirthdayDate dateType="birthday"
                                     [inputDate]="getBirthDate()"
                                     [dateFormat]="'MMM DD YYYY'"
                                     (dateChange)="onDateChangeBirthday($event)"
                                     [fieldKey]="'importDL.birthDate'"
                                     dp-error-validator
                                     statusBarHelp
                                     >
                    </dp-partial-date>
                </div>
            </div>

        </div>

        <div class="col-lg-12 form-group" *ngIf="context?.contact?.isBlankMaleFemale">
            <div class="col-lg-12">
                <div class="form-group main-level">
                    <div class="title">
                        Primary Address
                    </div>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Street Address</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlstreetAddress"
                        name="dlstreetAddress"
                        [ngModel]="context?.driverLicenceInfo?.addressLine1"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="streetAddressCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="streetAddressCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeAddress($event, 'addressLine1', true)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="streetAddress"
                        name="streetAddress"
                        [(ngModel)]="localContact.primaryAddress.addressLine1"
                        class="form-control"
                    />
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">City</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlCity"
                        name="dlCity"
                        [ngModel]="context?.driverLicenceInfo?.city"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="cityCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="cityCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeAddress($event, 'city', true)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="city"
                        name="city"
                        [(ngModel)]="localContact.primaryAddress.city"
                        class="form-control"
                    />
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Province</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlProvince"
                        name="dlProvince"
                        [ngModel]="context?.driverLicenceInfo?.provinceCode"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="provinceCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="provinceCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeAddress($event, 'provinceCode')"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <select class="form-control"
                            id="province"
                            name="province"
                            [(ngModel)]="localContact.primaryAddress.provinceCode"
                            (change)="changeProvinceName()"
                    >
                        <option *ngFor="let province of provinceOptions"
                                [value]="province.value">{{province.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Postal Code</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlPostalCode"
                        name="dlPostalCode"
                        [ngModel]="context?.driverLicenceInfo?.postalCode"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="postalCodeCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="postalCodeCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeAddress($event, 'postalCode')"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="postalCode"
                        name="postalCode"
                        [(ngModel)]="localContact.primaryAddress.postalCode"
                        class="form-control"
                    />
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Country</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlCountry"
                        name="dlCountry"
                        [ngModel]="'Canada'"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="countryCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="countryCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeAddress($event, 'country')"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <select class="form-control"
                            id="country"
                            name="country"
                            [(ngModel)]="localContact.primaryAddress.country"
                    >
                        <option *ngFor="let country of countryOptions"
                                [value]="country.value">{{country.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="col-lg-12 form-group">
            <div class="col-lg-12">
                <div class="form-group main-level">
                    <div class="title">
                        Driver's Licence Details
                    </div>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">ID Number</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlLicenceNumber"
                        name="dlLicenceNumber"
                        [ngModel]="context?.driverLicenceInfo?.licenceNumber"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="licenceNumberCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="licenceNumberCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeLicenceNumber($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="licenceNumber"
                        name="licenceNumber"
                        [(ngModel)]="localIdentificationDocument.identificationNumber"
                        class="form-control"
                    />
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Place of Issue / Issuing Jurisdiction</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlPlaceOfIssue"
                        name="dlPlaceOfIssue"
                        [ngModel]="context?.driverLicenceInfo?.placeOfIssue"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="placeOfIssueCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="placeOfIssueCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changePlaceOfIssue($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="placeOfIssue"
                        name="placeOfIssue"
                        [(ngModel)]="localIdentificationDocument.placeOfIssue"
                        class="form-control"
                    />
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Expiry Date</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlExpiryDate"
                        name="dlExpiryDate"
                        [ngModel]="getFormatedDLDate(context.driverLicenceInfo.expiryDate)"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="expiryDateCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="expiryDateCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeExpiryDate($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <dp-partial-date #partialExpiryDate
                                     [inputDate]="localIdentificationDocument.expiryDate"
                                     [dateFormat]="'MMM DD YYYY'"
                                     (dateChange)="onChangeExpiryDate($event)"
                                     [fieldKey]="'importDL.expryDate'"
                                     dp-error-validator
                                     statusBarHelp
                    >
                    </dp-partial-date>
                </div>
            </div>
            <div  class="col-lg-12 padding-bottom-10">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Country</label>
                </div>
                <div class="col-lg-3">
                    <input
                        id="dlIdCountry"
                        name="dlIdCountry"
                        [ngModel]="'Canada'"
                        [dp-read-only]="true"
                        class="form-control"
                    />
                </div>
                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="idCountryCheckBox" fieldClass="top-8">
                        <input type="checkbox"
                               id="idCountryCheckBox"
                               [checked]="allFieldsSelected"
                               (change)="changeIdCountry($event)"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-3">
                    <input
                        id="idCountry"
                        name="idCountry"
                        [(ngModel)]="localIdentificationDocument.country"
                        class="form-control"
                    />
                </div>
            </div>
        </div>


    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="applyChanges()">
                <span>Apply Changes</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
