<table *ngIf="isBC()" id="tax-certificate-table" class="table tc-table table-vertical-align-top" tabindex="-1" dp-table-key-commands>
  <tbody>
  <tr id="dueDiligenceRowTitleSearch" *ngIf="!!taxInfo" tabindex="0">
    <td [class]="(!displayDate && displayStatus) ? 'column-documents-table col-10' : 'column-documents-table col-12'">
      <span class="boldfont-14">Tax Certificate</span>
      <span class="margin-left-10 font-size-12" *ngIf="!showActionButtons">Mandatory</span>
    </td>
    <td class="column-status" *ngIf="displayStatus && !!taxInfo.status">{{taxInfo.status}}</td>
    <td class="column-order-date" *ngIf="displayDate">
      <div class="display-inline-block width-145">{{taxInfo.createdTimeStamp | date:'MMM d, yyyy , hh:mm a'}}</div>
    </td>
    <td [class]="(displayDate || displayStatus) ? 'column-est-cost' : showActionButtons ? 'column-est-cost padding-right-20' : 'column-est-cost padding-right-30'">
      <span class="semiboldfont padding-right-5 {{hasTaxCertificate ? '' : 'lightgray'}}">
        {{taxCertificate?.priceBeforeTax + taxCertificate?.tax | currency}}
      </span>
    </td>
    <td class="column-action">
      <div *ngIf="showActionButtons">
        <span class="trash-icon" *ngIf="hasTaxCertificate">
          <i class="fa fa-trash"
             aria-hidden="true"
             (click)="removeTaxCertificate()"
             [title]="'Remove Tax Certificate'">
          </i>
        </span>
        <span class="primary-font-color" *ngIf="!hasTaxCertificate">
          <i class="fal fa-shopping-cart"
             aria-hidden="true"
             (click)="addTaxCertificate()"
             [title]="'Add Tax Certificate'" >
          </i>
        </span>
      </div>
    </td>
  </tr>
  </tbody>
</table>
