<div class="col-lg-12">
    <form #fireInsuForm="ngForm"
          role="form"
          class="form-horizontal matter-purchaser"
    >
        <div class="col-lg-12 padding-bottom-20 padding-top-5" *ngIf="(isProjectSale && isProjectConfigDocForDirectionReFund)">
            <ul class="dppm-custom-tab">
                <li (click)="selectProgressionType('Interim')"  [ngClass]="{active: matter && matter.isDirectionReFundsInterim}">
                    <a><span class="icon-tab-span"><img class="width-20" src="/assets/img/icon_interim.png" border="0" alt="Interim" /></span>
                    <span>Interim&nbsp;&nbsp;&nbsp;</span>
                    <span *ngIf="(isProjectSale || matter.templateForProject) && isValidDate(matter.getInterimOccupancyDate())">{{
                        matter.getInterimOccupancyDate() ? (matter.getInterimOccupancyDate() | date : 'MMMM dd, yyyy') : '' }}
                    </span>
                </a>
                </li>
                <li  (click)="selectProgressionType('Final')"  [ngClass]="{active: matter && !matter.isDirectionReFundsInterim}">
                    <a><span class="icon-tab-span"><img class="width-20" src="/assets/img/icon_final.png" border="0" alt="Final" /></span>
                        <span>Final&nbsp;&nbsp;&nbsp;</span> <span
                            *ngIf="(isProjectSale || matter.templateForProject) && isValidDate(matter.getFinalClosingDate())">
                            {{matter.getFinalClosingDate() ?  (matter.getFinalClosingDate()  | date : 'MMMM dd, yyyy') : '' }}
                        </span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="form-group" >
            <div  class="col-lg-6 padding-top-10 padding-bottom-5" >
                <ng-container *dpShowByProvince="'matter.directionToPay.showPaymentAmounts';code:matter.provinceCode">
                    <dp-checkbox fieldId="paymentAmountsDisplayed"><input type="checkbox"
                                                                     id="paymentAmountsDisplayed"
                                                                     name="paymentAmountsDisplayedField"
                                                                     [(ngModel)]="matter.paymentAmountsDisplayed"
                                                                     (change)="onChangeOfPaymentAmountDisplayed()"
                    ></dp-checkbox>
                    <label class="control-label vertical-align-label display-inline" for="paymentAmountsDisplayed">Check to show payment amounts</label>
                </ng-container>
            </div>
            <div class="col-lg-6 text-right">
                <ul>
                    <li class="dropdown" style="display:inline-block;float: none;margin-right: 5px;">
                        <button label="Preview" class="dp-btn-white" (click)="previewDirectionReFunds()" *ngIf="!matter.isTemplateMatterForMassUpdate">
                            <span>Preview</span>
                            <div class="clear"></div>
                        </button>
                    </li>
                    <li class="dropdown">
                        <button label="Move Up" class="dp-btn-white" (click)="moveUp()" [disabled]="isMoveUpDisabled()">
                            <span>Move Up</span>
                            <div class="clear"></div>
                        </button>
                    </li>
                    <li class="dropdown">
                        <button label="Move Down" class="dp-btn-white" (click)="moveDown()" [disabled]="isMoveDownDisabled()">
                            <span>Move Down</span>
                            <div class="clear"></div>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="form-group margin-left-0 margin-right-0" [ngClass]="{'col-lg-10': !isPaymentAmountsDisplayed}">

            <table class="table">
                <thead>
                <tr>
                    <th style="width:82.8%;font-weight: bold">Name </th>
                    <th style="width: 17.5%;font-weight: bold" *ngIf="isPaymentAmountsDisplayed">Amount</th>
                </tr>
                </thead>

                <tbody>
                <tr >
                    <td style="border-right: 1px solid #ddd">
                        <label class="semiboldfont">Balance of funds payable to</label>
                    </td>
                    <td *ngIf="isPaymentAmountsDisplayed"></td>
                </tr>
                <tr (click)="selectFundsPayableToItem(balanceOfFundsPayableTo)"
                    [ngClass]="{'selected-active': selectableItem(balanceOfFundsPayableTo)}">
                    <td style="border-right: 1px solid #ddd">
                        <div >
                            <input type="text" trimmed id="reFundName" name="reFundName"
                                   class="focus-first"
                                   statusBarHelp
                                   fieldKey="matter.matterOpening.orderNumber"
                                   [dynamicHelpCallBackFunction] = "dynamicHelpFunction()"
                                   (keydown.f9)="handleF9BalancePayableToAction()"
                                   [(ngModel)]="balanceOfFundsPayableTo.reFundName"
                                   style="border: hidden;width: 95%;"
                                   (ngModelChange)="onBalanceChange()"
                            />
                            <dp-dropdown-menu style="display:inline-block;"
                                              [allowPopup]="false"
                                              [dropDownItems]="balanceBurgerMenuItems"
                                              (itemClicked)="clickBalanceOfFundsPayableToBurgerMenu($event)"
                            ></dp-dropdown-menu>
                        </div>
                    </td>
                    <td *ngIf="isPaymentAmountsDisplayed && !matter.isTemplateMatterForMassUpdate">
                        <div>
                            <input type="text"  id="amount" name="amount"
                                   [ngModel]="calculateBalancePayableTo()"
                                   style="border: hidden;width:84%;padding-right:10px;text-align: right;"
                                   allow-negative="true"
                                   [dp-read-only]="true"
                                   dp-currency
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="border-right: 1px solid #ddd">
                        <label class="semiboldfont">Funds payable to</label>
                    </td>
                    <td *ngIf="isPaymentAmountsDisplayed"></td>
                </tr>
                <tr *ngFor="let item of matter.filteredDirectionReFunds; let i=index;" (click)="selectFundsPayableToItem(item)"
                    [ngClass]="{'selected-active': selectableItem(item)}">
                    <ng-container *ngIf="i > 0">
                        <td style="border-right: 1px solid #ddd">
                            <div class="dropdown">
                                <input type="text" trimmed id="reFundName-{{item.identifier}}" name="reFundName-{{item.identifier}}"
                                       [(ngModel)]="item.reFundName" style="border: hidden;width: 95%;"
                                       (ngModelChange)="onFundsPayableToChange()"
                                       (blur)="fieldValueBlur(item)"
                                />
                                <dp-dropdown-menu
                                        [allowPopup]="false"
                                        [dropDownItems]="fundsPayableToBurgerMenuItems"
                                        (itemClicked)="clickFundsPayableToBurgerMenu($event, item, i)"
                                        [menuSize]="dynamicMenu()"
                                        [openSubMenuOnLeft]="true"
                                        [openSubSubMenuOnLeft]="true"
                                ></dp-dropdown-menu>
                            </div>
                        </td>
                        <td *ngIf="isPaymentAmountsDisplayed">
                            <div>
                                <input type="text"  id="amount-{{item.identifier}}" name="amount-{{item.identifier}}"
                                       [(ngModel)]="item.amount"
                                       style="border: hidden;width:84%;padding-right:10px;text-align: right;"
                                       (ngModelChange)="onFundsPayableToChange()"
                                       (blur)="fieldValueBlur(item)"
                                       dp-currency
                                />

                            </div>
                        </td>
                    </ng-container>
                </tr>
                <tr *ngIf="isPaymentAmountsDisplayed">
                    <td style="border-right: 1px solid #ddd">
                        <label class="semiboldfont text-right" style="width: 98%">Balance Due on Closing</label>
                    </td>
                    <td>
                        <input type="text"  id="balanceDueOnClosing" name="balanceDueOnClosing"
                               [ngModel]="balanceDueOnClosing"
                               style="border: hidden;width:84%;padding-right:10px;text-align: right;"
                               dp-currency
                               [dp-read-only]="true"
                        />
                    </td>
                </tr>

                </tbody>
            </table>
        </div>

    </form>
    <dp-user-defined-fields [topicName] = "'DIRECTION_RE_FUNDS'" (onChange)="enableSave()">
    </dp-user-defined-fields>
</div>
