import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {soAdjFormats} from './so-adj-drop-downs';

const propertyIgnoreList = [ 'adjustmentHeadingType', 'headingText' ];

export class SoAdjHeading extends BaseEntity {

  adjustmentHeadingType: string;
  headingText: string;
  headingLine1: string;
  headingLine2: string;
  headingLine3: string;
  customFormatSettingType: string;
  printInUpperCase: boolean;
  printInBold: boolean;
  underline: boolean;
  lastLineCreditVendorOrCreditPurchaser: boolean;
  followAdjustmentWithBlankLine: boolean;

  constructor(soAdjHeading ?: SoAdjHeading) {
    super();
    if (soAdjHeading) {
      this.adjustmentHeadingType = soAdjHeading.adjustmentHeadingType;
      this.headingText = soAdjHeading.headingText;
      this.headingLine1 = soAdjHeading.headingLine1;
      this.headingLine2 = soAdjHeading.headingLine2;
      this.headingLine3 = soAdjHeading.headingLine3;
      this.customFormatSettingType = soAdjHeading.customFormatSettingType;
      this.printInUpperCase = soAdjHeading.printInUpperCase;
      this.printInBold = soAdjHeading.printInBold;
      this.underline = soAdjHeading.underline;
      this.lastLineCreditVendorOrCreditPurchaser = soAdjHeading.lastLineCreditVendorOrCreditPurchaser;
      this.followAdjustmentWithBlankLine = soAdjHeading.followAdjustmentWithBlankLine;
    } else {
      this.adjustmentHeadingType = soAdjFormats.adjustmentHeadingType_AdoptDefault.value;
      this.headingText = '';
      this.headingLine1 = '';
      this.headingLine2 = '';
      this.headingLine3 = '';
      this.customFormatSettingType = soAdjFormats.adjustmentFormatSettingType_AdoptGlobalFormat.value;
      this.printInUpperCase = false;
      this.printInBold = false;
      this.underline = false;
      this.lastLineCreditVendorOrCreditPurchaser = false;
      this.followAdjustmentWithBlankLine = false;
    }

  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);

  }
}

