<!-- modal -->
<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>{{teranetOwnerImportModalLabel}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body form-horizontal padding-bottom-100">
        <ng-container *ngIf="context && !context.isWizard">
            <div class="col-lg-12 margin-bottom-20" style="border-bottom: 2px solid #cccccc">
                <div class="col-lg-1"></div>
                <div class="col-lg-5">
                    <div class="form-group main-level"><label
                        class="control-label">{{teranetOwnerImportModalTitlelabel}}</label>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group main-level">
                        <label class="control-label">
                            Names on {{teranetParcelRegisterModalLabel(true)}} tab to be replaced
                        </label>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let item of ownerData;let i = index;">
                <div class="col-lg-12 form-group margin-top-15">
                    <div class="col-lg-1 text-right">
                        <label class="control-label">Name {{i + 1}}</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="teranetConnectName{{i}}"
                               name="teranetConnectName{{i}}"
                               class="form-control"
                               [ngModel]="item.parcelRegisterName"
                               [dp-read-only]="true"
                        />
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="{{item.isNewRecord() && !item.clientName ? 'col-lg-2' : 'col-lg-4'}}"
                         *ngIf="!item.clientName">
                        <select id="clientName{{i}}"
                                name="clientName{{i}}"
                                class="form-control"
                                [(ngModel)]="item.clientContactId"
                                (change)="updateClientName(item ,i)">
                            <option *ngFor="let client of clients" [value]="client.value">
                                {{client.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-4" *ngIf="item.clientName">
                        <input type="text"
                               id="clientFullName{{i}}"
                               name="clientFullName{{i}}"
                               class="form-control"
                               [ngModel]="item.clientName"
                               [dp-read-only]="true"
                        />
                        <div class="actionbtns">
                            <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeClientName(item)" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="{{item.isNewRecord() && !item.clientName ? 'col-lg-4' : 'col-lg-2'}}"
                         *ngIf="item.isNewRecord() && !item.clientName">
                        <p-autoComplete
                            id="clientSearch{{i}}"
                            name="clientSearch{{i}}"
                            [class.autocomplete-searching]="clientPurchasersLoading"
                            [suggestions]="clientPurchasers"
                            (completeMethod)="search($event)"
                            [(ngModel)]="item.dataModel"
                            field="fullName"
                            [minLength]="0"
                            [delay]="1000"
                            maxlength="75"
                            scrollHeight="500px"
                            (onSelect)="dataSelectedClient(item)"
                            autocomplete="off" class="autocomplete-search-icon"
                            (focusout)="clearClient(item)">
                            <!-- Removed Help Text function for above autoCompleted:
                            (click)="showClientPurchaserHelpText(selectPurchaser)"
                            -->

                            <ng-template let-clientPurchaser pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                    [ngClass]="{'bold-font' : isAddNew(clientPurchaser)}"
                                    [disableItem]="clientPurchaser">
                                <span class="col-lg-5 text-overflow"
                                      *ngIf="autoCompleteEmptyFlag == true">{{ clientPurchaser.displayName }}</span>
                                    <span class="text-overflow"
                                          [ngClass]="{'col-lg-12':isAddNew(clientPurchaser), 'col-lg-5':!isAddNew(clientPurchaser)}"
                                          *ngIf="autoCompleteEmptyFlag == false">{{ clientPurchaser.displayName }}</span>
                                    <span class="col-lg-4 text-overflow"
                                          *ngIf="autoCompleteEmptyFlag == false">{{ clientPurchaser.displayAddress }}</span>
                                    <span class="col-lg-3 text-overflow"
                                          *ngIf="autoCompleteEmptyFlag == false && clientPurchaser.gender  != 'QUESTION'">{{
                                        getGenderValue(clientPurchaser.gender) }}</span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
                <ng-container *dpShowByProvince="'matter.property.spin.address'">
                    <div class="col-lg-12 form-group" *ngIf="isAddressFieldsVisible(item)">
                        <div class="col-lg-1 vertical-align-label"><label class="control-label">Address</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text"
                                   id="spinAddress"
                                   [dp-read-only]="true"
                                   [ngModel]="item.spinAddress && item.spinAddress.addressTextWithProvinceName"
                                   name="spinAddress"
                                   class="form-control"/>
                        </div>
                        <div class="col-lg-1 text-center padding-left-15">
                            <dp-checkbox fieldId="sameAsSpinAddressFlag_{{i}}" fieldClass="top-8"
                                         *ngIf="isAddressCheckboxVisible(item)">
                                <input type="checkbox"
                                       [(ngModel)]="item.sameAsSpinAddressFlag"
                                       (ngModelChange)="updateAddress(item)"
                                       id="sameAsSpinAddressFlag_{{i}}"
                                       name="sameAsSpinAddressFlag_{{i}}"
                                />
                            </dp-checkbox>
                        </div>
                        <div class="col-lg-4">
                            <input type="text"
                                   id="localAddress"
                                   [dp-read-only]="true"
                                   [ngModel]="item.localAddress && item.localAddress.addressTextWithProvinceName"
                                   name="localAddress"
                                   class="form-control"/>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.spin.tenure'">
                <div class="col-lg-12 form-group margin-top-30" *ngIf="isTenureItemVisible()">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-1 vertical-align-label"><label class="control-label">Tenure</label>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control"
                                id="spinTenure"
                                name="spinTenure"
                                [(ngModel)]="tenureData.spinTenure"
                                [dp-read-only]="true"
                        >
                            <option *ngFor="let option of tenureOptions"
                                    [value]="option.value">{{
                                option.label }}
                            </option>
                        </select>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="sameAsSpinTenureFlag" fieldClass="top-8"
                                     *ngIf="isTenureCheckboxVisible()">
                            <input type="checkbox"
                                   [(ngModel)]="tenureData.sameAsSpinTenureFlag"
                                   (ngModelChange)="updateTenure()"
                                   id="sameAsSpinTenureFlag"
                                   name="sameAsSpinTenureFlag"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control"
                                id="localTenure"
                                name="localTenure"
                                [(ngModel)]="tenureData.localTenure"
                                [disabled]="true"
                        >
                            <option *ngFor="let option of tenureOptions"
                                    [value]="option.value">{{
                                option.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="context && context.isWizard">
            <div class="col-lg-12 vertical-align-label text-center padding-top-25">
                <label class="control-label text-center bold font-22">
                       Import Data In Progress.... Please Wait ....
                </label>
            </div>
        </ng-container>

    </div>
    <div class="row modal-footer">

            <div class="col-lg-12">
                <div class="row padding-top-20">
                    <div class="col-lg-12 text-center">
                        <ng-container *ngIf="context && !context.isWizard">
                        <button type="button" class="dp-btn" (click)="ok()" id="okBtn">
                            <span>Update</span>
                        </button>
                        </ng-container>
                        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>

    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
