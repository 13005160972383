import {Observable} from 'rxjs/Rx';
import {ConfirmDialog} from './confirm-dialog.component';
import {UnSavedChangesDialog} from './unsavedchanges-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Injectable, NgZone, Renderer2, TemplateRef, Type, ViewContainerRef} from '@angular/core';
import {WindowRef} from '../window.ref';
import {CustomKeyCodesEnum} from '../../common/key-code-enum';

import {TabsService} from '../../core';
import {ModalDialogService} from './modal-dialog.service';

declare var jQuery: any;
export type ContainerContent = string | TemplateRef<any> | Type<any>;

export interface ContentDialogConfig {
  content: ContainerContent;
  modalGrid?: number;
  fullScreen?: boolean;
  context?: any;
  onFulfillment?: (value: any) => void;
  onRejection?: (result: any) => void;
  widthXl?: boolean;
  //This is to show modal on the top of lock screen. It is used in mass update so stop button can be pressed even if lock screen is present.
  showModalOnTop?: boolean;
  showModalBehind?: boolean;
  passwordModal?: boolean;
  modalContentCustomClass?: any;
  modalDialogCustomClass?: any;
  panelClass?: string[];
  width?: string;
  minWidth?: string;
  allowOverflow?: boolean; //useful for datepicker calendar
}

export interface ConfirmDialogConfig {
  title: string;
  body: string;
  okText: string;
  onFulfillment: (value: any) => void;
  onRejection?: (result: any) => void;
}

export interface DialogConfigParams {
  title: string;
  message: string;
  hideCancelBtn: boolean;
  hideOkBtn?: boolean;
  customConfirmBtnText?: string;
  customBtnText?: string;
  customReturnText?: string;
  customCancelBtnText?: string;
  alignContentLeft?: boolean;
  buttonClass?: string;
  confirmFunction?: Function;
  customClipBoardText?: string;
  customClipBoardCopyText?: string;
}

export class ScrollList {
  title: string;
  list: string[];
}

@Injectable()
export class DialogService {
  private _viewContainerRef: ViewContainerRef;
  private _tabStateService: TabsService;
  private renderer: Renderer2;
  private window: WindowRef;
  private currentTarget: any;
  private isModalContentSizeChecked = false;

  constructor(
    private dialog: MatDialog,
    private zone: NgZone,
    private modalDialogService: ModalDialogService
  ) {}

  set rendererRef(renderer: Renderer2) {
    this.renderer = renderer;
  }
  set tabStateService(tabStateService: TabsService) {
    this._tabStateService = tabStateService;
  }
  set windowRef(window: WindowRef) {
    this.window = window;
  }

  set viewContainerRef(viewContainerRef: ViewContainerRef) {
    this._viewContainerRef = viewContainerRef;
  }

  public setCurrentTarget(target: any) {
    this.currentTarget = target;
  }

  public setCurrentTargetByPenButton(target: any) {
    let parent = jQuery(target).parent();
    let targetInput = jQuery(parent).find('input');
    let penIcon = jQuery(parent).find('glyphicon glyphicon-edit');
    if (targetInput && penIcon) {
      this.currentTarget = targetInput;
      this.setFocusOnOriginTarget();
    }
  }

  public confirm(
    title: string,
    message: string,
    hideCancelBtn: boolean,
    customConfirmBtnText?: string,
    customCancelBtnText?: string,
    alignContentLeft?: boolean,
    buttonClass?: string,
    defaultCancel?: boolean,
    infoMessage?: string,
    scrollLists?: ScrollList[],
    breakOnLongWords?: boolean,
    customReturnText?: string,
    customBtnText?: string
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialog>;
    let config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    config.disableClose = true;
    config.panelClass = ['mat-modal-dialog-confirmation'];
    dialogRef = this.dialog.open(ConfirmDialog, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.infoMessage = infoMessage;
    dialogRef.componentInstance.scrollLists = scrollLists;
    if (customConfirmBtnText) {
      dialogRef.componentInstance.customConfirmBtnText = customConfirmBtnText;
    }
    if (customCancelBtnText) {
      dialogRef.componentInstance.customCancelBtnText = customCancelBtnText;
    }
    if (customReturnText) {
      dialogRef.componentInstance.customReturnText = customReturnText;
    }
    if (customBtnText) {
      dialogRef.componentInstance.customBtnText = customBtnText;
    }
    if (buttonClass) {
      dialogRef.componentInstance.buttonClass = buttonClass;
    }
    if (defaultCancel) {
      dialogRef.componentInstance.defaultCancel = defaultCancel;
    }
    if (breakOnLongWords) {
      dialogRef.componentInstance.breakOnLongWords = true;
    }
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.infoMessage = infoMessage;
    dialogRef.componentInstance.scrollLists = scrollLists;
    if (customConfirmBtnText) {
      dialogRef.componentInstance.customConfirmBtnText = customConfirmBtnText;
    }
    if (customCancelBtnText) {
      dialogRef.componentInstance.customCancelBtnText = customCancelBtnText;
    }
    if (customReturnText) {
      dialogRef.componentInstance.customReturnText = customReturnText;
    }
    if (customBtnText) {
      dialogRef.componentInstance.customBtnText = customBtnText;
    }
    if (buttonClass) {
      dialogRef.componentInstance.buttonClass = buttonClass;
    }
    if (defaultCancel) {
      dialogRef.componentInstance.defaultCancel = defaultCancel;
    }
    if (breakOnLongWords) {
      dialogRef.componentInstance.breakOnLongWords = true;
    }

    dialogRef.componentInstance.alignContentLeft = alignContentLeft != null ? alignContentLeft : false;
    // hideCancelBtn = true -> we hide the cancel button
    dialogRef.componentInstance.hideCancelBtn = hideCancelBtn;
    dialogRef.afterClosed().subscribe((res) => {
      this.setFocusOnOriginTarget();
      this.enableOkBtn();
    });
    return dialogRef.afterClosed();
  }

  // TODO : Need to update Method Name to confirm once everybody moves to this method
  // then we can remove above confirm method
  public confirmCustomDialog(dialogConfigParams: DialogConfigParams): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialog>;
    let config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    config.disableClose = true;
    config.panelClass = ['mat-modal-dialog-confirmation'];

    dialogRef = this.dialog.open(ConfirmDialog, config);

    dialogRef.componentInstance.title = dialogConfigParams.title;
    dialogRef.componentInstance.message = dialogConfigParams.message;
    if (dialogConfigParams.customConfirmBtnText) {
      dialogRef.componentInstance.customConfirmBtnText = dialogConfigParams.customConfirmBtnText;
    }
    if (dialogConfigParams.customCancelBtnText) {
      dialogRef.componentInstance.customCancelBtnText = dialogConfigParams.customCancelBtnText;
    }
    if (dialogConfigParams.buttonClass) {
      dialogRef.componentInstance.buttonClass = dialogConfigParams.buttonClass;
    }
    if (dialogConfigParams.customBtnText) {
      dialogRef.componentInstance.customBtnText = dialogConfigParams.customBtnText;
    }
    if (dialogConfigParams.customReturnText) {
      dialogRef.componentInstance.customReturnText = dialogConfigParams.customReturnText;
    }
    if (dialogConfigParams.confirmFunction) {
      dialogRef.componentInstance.confirmFunction = dialogConfigParams.confirmFunction;
    }
    if (dialogConfigParams.customClipBoardText) {
      dialogRef.componentInstance.customClipBoardText = dialogConfigParams.customClipBoardText;
    }
    if (dialogConfigParams.customClipBoardCopyText) {
      dialogRef.componentInstance.customClipBoardCopyText = dialogConfigParams.customClipBoardCopyText;
    }
    dialogRef.componentInstance.alignContentLeft =
      dialogConfigParams.alignContentLeft != null ? dialogConfigParams.alignContentLeft : false;
    // hideCancelBtn = true -> we hide the cancel button
    dialogRef.componentInstance.hideCancelBtn = dialogConfigParams.hideCancelBtn;
    dialogRef.afterClosed().subscribe((res) => {
      this.setFocusOnOriginTarget();
      this.enableOkBtn();
    });
    return dialogRef.afterClosed();
  }

  public confirmDialog(
    title: string,
    message: string,
    hideCancelBtn: boolean,
    customConfirmBtnText?: string,
    customCancelBtnText?: string,
    defaultCancel?: boolean,
    hideOkBtn?: boolean,
    alignContentLeft?: boolean
  ): MatDialogRef<any> {
    let dialogRef: MatDialogRef<ConfirmDialog>;
    let config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    config.disableClose = true;
    config.panelClass = ['mat-modal-dialog-confirmation'];
    dialogRef = this.dialog.open(ConfirmDialog, config);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;

    dialogRef.componentInstance.customConfirmBtnText = customConfirmBtnText;
    dialogRef.componentInstance.customCancelBtnText = customCancelBtnText;
    dialogRef.componentInstance.hideOkBtn = hideOkBtn;
    // hideCancelBtn = true -> we hide the cancel button
    dialogRef.componentInstance.hideCancelBtn = hideCancelBtn;
    if (defaultCancel) {
      dialogRef.componentInstance.defaultCancel = defaultCancel;
    }
    dialogRef.afterClosed().subscribe((res) => {
      this.setFocusOnOriginTarget();
    });
    dialogRef.componentInstance.alignContentLeft = alignContentLeft;
    return dialogRef;
  }

  removeModalOpenClass(): void {
    if (this.renderer) {
      this.renderer.removeClass(document.body, 'modal-open');
    }
  }

  public confirmUnsavedChange(
    saveBtnOption: boolean,
    unsavedChangesFormMessage?: string,
    dontSaveBtnOption?: boolean
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<UnSavedChangesDialog>;
    let config = new MatDialogConfig();
    config.viewContainerRef = this._viewContainerRef;
    config.panelClass = ['mat-modal-dialog-confirmation'];
    config.disableClose = true;
    dialogRef = this.dialog.open(UnSavedChangesDialog, config);
    dialogRef.componentInstance.message = unsavedChangesFormMessage
      ? unsavedChangesFormMessage
      : 'Do you wish to save your changes?';
    dialogRef.componentInstance.saveBtnOption = saveBtnOption === false ? saveBtnOption : true;
    dialogRef.componentInstance.dontSaveBtnOption = dontSaveBtnOption === false ? dontSaveBtnOption : true;
    dialogRef.afterClosed().subscribe((res) => {
      this.setFocusOnOriginTarget();
    });
    return dialogRef.afterClosed();
  }

  public closeDialog() {
    this.dialog.closeAll();
  }

  setFocusOnAutocomplete(id: string, readOnly?: boolean): void {
    setTimeout(() => {
      if (readOnly) {
        //focus on read-only field
        jQuery(`#${id}`).focus();
      } else {
        //focus on autocomplete text input field
        jQuery(`#${id} .ui-autocomplete-input`).focus();
      }
    }, 300);
  }

  setFocusOnOriginTarget(): void {
    let currentFocusedElement = jQuery(this.currentTarget);
    if (this.currentTarget) {
      if (this.currentTarget.tagName == 'BUTTON') {
        if (
          !jQuery(this.currentTarget).is(':visible') &&
          jQuery(this.currentTarget).closest('li').find('[class*="dropbtn"]')
        ) {
          jQuery(this.currentTarget).closest('li').find('[class*="dropbtn"]').tabIndex = 0;
          jQuery(this.currentTarget).closest('li').find('[class*="dropbtn"]').focus();
        } else {
          this.currentTarget.tabIndex = 0;
          jQuery(this.currentTarget).focus();
        }
      } else if (jQuery(this.currentTarget).hasClass('ui-autocomplete-input')) {
        let id = jQuery(this.currentTarget).closest('div').attr('id');

        setTimeout(function () {
          let isElementVisible = jQuery(currentFocusedElement).not(':visible');
          if (isElementVisible && id) {
            jQuery('#' + id).find('input[type=text]').tabIndex = 0;
            jQuery('#' + id)
              .find('input[type=text]')
              .focus();
          } else {
            jQuery(currentFocusedElement).focus();
          }
        }, 100);
      } else {
        setTimeout(function () {
          jQuery(currentFocusedElement).focus();
        }, 100);
      }
    }
  }

  matDialogContent(contentDialogConfig: ContentDialogConfig, _viewContainerRef?: ViewContainerRef) {
    let dialogServiceInstance = this;
    let isModalContentSizeChecked = this.isModalContentSizeChecked;
    let modalGrid = contentDialogConfig.modalGrid;
    let fullScreen = contentDialogConfig.fullScreen;
    let widthXl = contentDialogConfig.widthXl;
    let showModalOnTop = contentDialogConfig.showModalOnTop;
    let showModalBehind = contentDialogConfig.showModalBehind;
    let passwordModal = contentDialogConfig.passwordModal;
    let modalContentCustomClass = contentDialogConfig.modalContentCustomClass;
    let modalDialogCustomClass = contentDialogConfig.modalDialogCustomClass;
    let allowOverflow = contentDialogConfig.allowOverflow;

    if (!fullScreen || fullScreen == undefined) {
      fullScreen = false;
    }

    if (!widthXl || widthXl == undefined) {
      widthXl = false;
    }

    if (!showModalOnTop || showModalOnTop == undefined) {
      showModalOnTop = false;
    }

    if ([3, 4, 5, 6, 7, 8].indexOf(modalGrid) > -1) {
      let modalWidth: string = `modal-${modalGrid}-body-width`;
      contentDialogConfig.panelClass = [modalWidth, ...(contentDialogConfig?.panelClass || [])];
    }

    if (fullScreen) {
      contentDialogConfig.panelClass = ['mat-modal-dialog-fullscreen', ...(contentDialogConfig?.panelClass || [])];
    }

    if (modalDialogCustomClass) {
      contentDialogConfig.panelClass = [modalDialogCustomClass, ...(contentDialogConfig?.panelClass || [])];
    }

    if (widthXl) {
      contentDialogConfig.panelClass = ['mat-modal-dialog-width-xl', ...(contentDialogConfig?.panelClass || [])];
    }

    const {onFulfillment, onRejection} = contentDialogConfig;

    this.modalDialogService.open(contentDialogConfig).subscribe(
      (value) => {
        this.removeModalOpenClass();
        this.setFocusOnOriginTarget();
        this.enableOkBtn();
        if (onFulfillment) {
          onFulfillment(value);
        }
      },
      (value) => {
        this.removeModalOpenClass();
        this.setFocusOnOriginTarget();
        this.enableOkBtn();
        if (onRejection) {
          onRejection(value);
        }
      }
    );

    // shuffle the modals around under the cdk-overlay-container so that the opened modal is behind others.
    if (showModalBehind) {
      setTimeout(function () {
        let modalList = jQuery('.cdk-overlay-container:first');
        let lastOpenedModal = modalList.find('.cdk-global-overlay-wrapper:last');
        let lastBackDrop = modalList.find('.cdk-overlay-backdrop:last');
        lastOpenedModal.insertBefore('.cdk-overlay-backdrop:first');
        lastBackDrop.insertBefore('.cdk-global-overlay-wrapper:first');
      }, 0);
    }

    setTimeout(function () {
      if ([3, 4, 5, 6, 7, 8].indexOf(modalGrid) > -1) {
        jQuery('mat-dialog-container:last').addClass(`modal-${modalGrid}-body-width`);
      }
    }, 0);

    setTimeout(function () {
      // set the modal body height based on the users screen size subtracted by the top nav, tabs
      const winHeight: number = this.window.innerHeight;
      const navHeight: number = jQuery('nav').outerHeight();
      const tabsHeight: number = jQuery('.matter-header:last').outerHeight();
      const modalHeaderHeight: number = jQuery('mat-dialog-container:last .modal-header').outerHeight();
      const modalFooterHeight: number = jQuery('mat-dialog-container:last .modal-footer').outerHeight();

      let modalTitleHeight: number = jQuery('mat-dialog-container:last .modal-sub-header').outerHeight();
      if (modalTitleHeight == null) {
        modalTitleHeight = 0;
      }

      let f9HelpHeight: number = jQuery('dp-modal-f9-help .inspector-footer').outerHeight();
      if (f9HelpHeight == null) {
        f9HelpHeight = 20;
      }

      // 100 pixels subtracted for padding below tabs
      let maxHeight: number =
        winHeight -
        navHeight -
        tabsHeight -
        modalHeaderHeight -
        modalFooterHeight -
        f9HelpHeight -
        modalTitleHeight -
        100;

      if (isNaN(maxHeight) || maxHeight == null) {
        maxHeight = 712;
      }

      if (fullScreen) {
        maxHeight = winHeight - modalHeaderHeight - modalFooterHeight - f9HelpHeight - modalTitleHeight;
      }

      if (modalContentCustomClass) {
        jQuery('.modal-content:last').addClass(modalContentCustomClass);
      }

      if (showModalOnTop) {
        jQuery('mat-dialog-container').css('z-index', 10000000);
      }

      if (passwordModal) {
        jQuery('mat-dialog-container:last').css('z-index', 20000000);
      }

      if (allowOverflow) {
        jQuery('mat-dialog-container:last').find('.mat-mdc-dialog-surface').css('overflow', 'visible');

        var element = jQuery('mat-dialog-container:last');
        var existingStyles = element.attr('style');
        if (existingStyles !== undefined) {
          element.attr('style', existingStyles + 'overflow:visible !important');
        } else {
          element.attr('style', 'overflow:visible !important');
        }
      }

      jQuery('.modal-footer').on('click', 'button:not(.dp-btn-cancel)', function (_) {
        //Disable ok button
        if (jQuery('mat-dialog-container dp-modal-error').find('.error-main').length == 0) {
          if (jQuery(this).hasClass('dp-btn') && !jQuery(this).hasClass('dp-btn-ignore-disabled')) {
            setTimeout(() => {
              jQuery(this).attr('disabled', true);
            }, 100);
          }
        }
        //Enable the ok button in case of error
        var btn = this;
        setTimeout(
          function () {
            if (jQuery('mat-dialog-container dp-modal-error').find('.error-main').length != 0) {
              if (jQuery(btn).hasClass('dp-btn')) {
                jQuery(btn).attr('disabled', false);
                if (fullScreen) {
                  dialogServiceInstance.calculateWindowSize(fullScreen, winHeight);
                }
              }
            }
          },
          1500,
          btn
        );
      });

      if (jQuery('mat-dialog-container')) {
        let focusFirstElement = jQuery('mat-dialog-container:last').find('.focus-first').filter(':visible:first');

        if (focusFirstElement && focusFirstElement.length != 0) {
          focusFirstElement.focus();
        } else {
          console.log(
            jQuery('mat-dialog-container:last')
              .find('input[type=text],dp-checkbox label,textarea,select')
              .filter(':visible:first')
          );
          jQuery('mat-dialog-container:last')
            .find('input[type=text],input,dp-checkbox label,textarea,select')
            .filter(':visible:first')
            .focus();
        }
      }

      jQuery('.modal-body:last').css('max-height', maxHeight + 'px');
      if (fullScreen) {
        jQuery('.modal-body:last').css('height', maxHeight + 'px');
      }

      dialogServiceInstance.zone.runOutsideAngular(() => {
        jQuery('.modal-body:last').on('click', () => {
          // when user clicks in modal or changes tabs we check if window has to be resized again
          dialogServiceInstance.calculateWindowSize(fullScreen, winHeight);
          isModalContentSizeChecked = false;
        });

        jQuery('.modal-body:last').on('DOMSubtreeModified', () => {
          if (jQuery('.modal-body:last').hasClass('error-modal-padding') && !isModalContentSizeChecked) {
            // only run sizing once to stop DOMSubtreeModfied from using memory
            isModalContentSizeChecked = true;
            dialogServiceInstance.calculateWindowSize(fullScreen, winHeight);
          }
        });
      });

      jQuery('.custom-modal-container').on('keydown', (event) => {
        if (event.ctrlKey && event.which === CustomKeyCodesEnum.S) {
          let keyboardSaveBtn = jQuery('.keyboard-save-action');
          if (keyboardSaveBtn) {
            if (keyboardSaveBtn.length > 1) {
              keyboardSaveBtn = keyboardSaveBtn[keyboardSaveBtn.length - 1];
            }
            //Get the element that has focus
            let focusedElement = jQuery(':focus');
            event.stopPropagation();
            event.preventDefault();
            //Set the focus on the save button to invoke blur actions on all the active element
            keyboardSaveBtn.focus();
            keyboardSaveBtn.click();
            //Set the focus back to the prviously focued element.
            focusedElement.focus();
          }
        }
      });

      this.isModalContentSizeChecked = isModalContentSizeChecked;
    }, 900);
  }

  calculateWindowSize(fullScreen, winHeight) {
    setTimeout(() => {
      // set the modal body height based on the users screen size subtracted by the top nav, tabs
      let navHeight: number = jQuery('nav').outerHeight();
      let tabsHeight: number = jQuery('.matter-header:last').outerHeight();
      let modalTitleHeight: number = jQuery('.mat-modal-dialog:last .modal-sub-header').outerHeight();
      if (modalTitleHeight == null) {
        modalTitleHeight = 0;
      }
      let modalHeaderHeight: number = jQuery('.mat-modal-dialog:last .modal-header').outerHeight();
      let modalFooterHeight: number = jQuery('.mat-modal-dialog:last .modal-footer').outerHeight();
      let f9HelpHeight: number = jQuery('dp-modal-f9-help .inspector-footer').outerHeight();
      let errorHelpHeight: number = jQuery('dp-modal-error .inspector-footer').outerHeight();
      if (f9HelpHeight == null) {
        f9HelpHeight = 20;
      }

      if (errorHelpHeight == null) {
        errorHelpHeight = 0;
      }
      // 100 pixels subtracted for padding below tabs
      let maxHeight: number =
        winHeight -
        navHeight -
        tabsHeight -
        modalHeaderHeight -
        modalFooterHeight -
        f9HelpHeight -
        modalTitleHeight -
        errorHelpHeight -
        100;
      if (isNaN(maxHeight) || maxHeight == null) {
        maxHeight = 712;
      }
      if (fullScreen) {
        maxHeight =
          winHeight - modalHeaderHeight - modalFooterHeight - f9HelpHeight - modalTitleHeight - errorHelpHeight;
      }
      jQuery('.mat-modal-dialog .modal-body:last').css('max-height', maxHeight + 'px');
      if (fullScreen) {
        jQuery('.mat-modal-dialog .modal-body:last').css('height', maxHeight + 'px');
      }
    }, 500);
  }

  enableOkBtn(): void {
    //Enable disabled ok button
    if (jQuery('.mat-modal-dialog dp-modal-error').find('.error-main').length == 0) {
      jQuery('.modal-footer')
        .find('.dp-btn')
        .not('.dp-btn-cancel')
        .not('.dp-btn-ignore-disabled')
        .each(function () {
          if (jQuery(this).is(':disabled')) {
            jQuery(this).attr('disabled', false);
          }
        });
    } else {
      this.isModalContentSizeChecked = false;
    }
  }
}
