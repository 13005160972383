import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {ProgressionStatus, StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../statement-adjustment-constants';
import {
  StatementAdjustmentDisplayBalanceItem,
  StatementAdjustmentDisplayItem,
  StatementOfAdjustmentDisplayItemLine,
  StatementOfAdjustmentDisplayItemLineType
} from '../model/statement-adjustment-display-item';
import {Matter} from '../../shared/matter';
import {MatterTax} from '../../shared/property-taxes';
import {SoaCommonExpense} from '../model/common-expense-soa';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import Utils from '../../../shared-main/utils';
import {OtherFixedCreditTypes, SoAdjOtherFixed} from '../model/so-adj-other-fixed';
import {SoAdjOtherProrated} from '../model/so-adj-other-prorated';
import {SoAdjTenancyCurrent} from '../model/so-adj-tenancy-current';
import {SoAdjTenancyPrepaid} from '../model/so-adj-tenancy-prepaid';
import {RentInterestRate} from '../model/rent-interest-rate';
import {StatementAdjustmentDisplayTenancyPrepaidBuilder} from './statement-adjustment-display-tenancy-prepaid-builder';
import {StatementAdjustmentDisplayTenancyCurrentBuilder} from './statement-adjustment-display-tenancy-current-builder';
import {SoAdjAssumedMortgage} from '../model/so-adj-assumed-mortgage';
import {StatementAdjustmentDisplayAssumedMortgageBuilder} from './statement-adjustment-display-assumed-mortgage-builder';
import {TarionWarrantyEnrolmentPeriod} from '../model/tarion-warranty-enrolment-period';
import {SoAdjTarionWarranty} from '../model/so-adj-tarion-warranty';
import {StatementAdjustmentDisplayTarionWarrantyBuilder} from './statement-adjustment-display-tarion-warranty-builder';
import {SoAdjTaxOther, SoAdjVTBMortgage} from '../model';
import {StatementAdjustmentDisplayVTBMortgageBuilder} from './statement-adjustment-display-vtb-mortgage-builder';
import {StatementAdjustmentDisplayOtherProratedBuilder} from './statement-adjustment-display-other-prorated-builder';
import {StatementAdjustmentDisplaySalePriceBuilder} from './statement-adjustment-display-sale-price-builder';
import {StatementAdjustmentDisplayDepositBuilder} from './statement-adjustment-display-deposit-builder';
import {StatementAdjustmentDisplayHstOtherBuilder} from './statement-adjustment-display-hst-other-builder';
import {StatementAdjustmentDisplayHstBuilder} from './statement-adjustment-display-hst-builder';
import {StatementAdjustmentDisplayCommonExpenseBuilder} from './statement-adjustment-display-common-expense-builder';
import {StatementAdjustmentDisplayHeatBuilder} from './statement-adjustment-display-heat-builder';
import {StatementAdjustmentDisplayWaterBuilder} from './statement-adjustment-display-water-builder';
import {StatementAdjustmentDisplayTaxOtherBuilder} from './statement-adjustment-display-tax-other-builder';
import {InterestSoa} from '../interest-soa';
import {StatementAdjustmentDisplayInterestBuilder} from './statement-adjustment-display-interest-builder';
import {StatementAdjustmentDisplayPropertyTaxBuilder} from './statement-adjustment-display-property-tax-builder';
import {StatementAdjustmentDisplayOtherFixedBuilder} from './statement-adjustment-display-other-fixed-builder';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import {SalePriceAdjustmentBase} from '../sale-price-adjustment/sale-price-adjustment-base';
import {StatementAdjustmentDisplayReserveFundBuilder} from './statement-adjustment-display-reserve-fund-builder';
import {SoaExpenseAdjustment} from '../modals/reserve-fund/soa-expense-adjustment';
import {SoAdjInterimOccupancyFee} from '../model/so-adj-interim-occupancy-fee';
import {StatementAdjustmentRawData} from '../model/statement-adjustment-display-raw-data';
import {StatementAdjustmentRawDataValueHolder} from '../model/statement-adjustment-display-raw-data-value-holder';
import {StatementAdjustmentDisplayInterimOccupancyFeeBuilder} from './statement-adjustment-display-interim-occupancy-fee-builder';
import {SoaRealtyTaxAdjustment} from '../modals/realty-tax/soa-realty-tax-adjustment';
import {ProjectTaxAdjustmentConfig} from '../../../projects/project-adjustments/project-tax-adjustment-config';
import {StatementAdjustmentDisplayRealtyTaxBuilder} from './statement-adjustment-display-realty-tax-builder';
import {SoAdjInterimEarlyPossessionFee} from '../model/so-adj-interim-early-possession-fee';
import {
  StatementAdjustmentDisplayInterimEarlyPossessionFeeBuilder
} from './statement-adjustment-display-interim-early-possession-fee-builder';
import {SoAdjFinalOccupancyFee} from '../model/so-adj-final-occupancy-fee';
import {StatementAdjustmentDisplayFinalOccupancyFeeBuilder} from './statement-adjustment-display-final-occupancy-fee-builder';
import {SalePriceAdjustmentFactory} from '../sale-price-adjustment/sale-price-adjustment-factory';
import moment from 'moment';
import {StatementAdjustmentDisplayTaxesVendorBuilder} from './statement-adjustment-display-taxes-vendor-builder';
import {SoAdjFinalEarlyPossessionFee} from '../model/so-adj-final-early-possession-fee';
import {StatementAdjustmentDisplayFinalEarlyPossessionFeeBuilder} from './statement-adjustment-display-final-early-possession-fee-builder';
import {StatementAdjustmentDisplayTaxesPaidDuringOccupancy} from './statement-adjustment-display-taxes-paid-during-occupancy';
import {StatementAdjustmentDisplayInterestDeposit} from './statement-adjustment-display-interest-deposit';
import {ProjectAdjustmentConfig} from '../../../projects/project-adjustments/project-adjustment-config';
import {StatementAdjustmentDisplayForm4Charge} from './statement-adjustment-display-form-4-charge';
import {StatementAdjustmentDisplayOccupancyPeriodBuilder} from './statement-adjustment-display-occupancy-period-builder';
import {
  StatementAdjustmentDisplayPurchasePriceDeferredPortionBuilder
} from './statement-adjustment-display-purchase-price-deferred-portion-builder';
import {StatementAdjustmentDisplayItemizedCreditBuilder} from './statement-adjustment-display-itemized-credit-builder';
import {SoAdjTotalOccupancyFee} from '../model/so-adj-total-occupancy-fee';
import {StatementAdjustmentDisplayTotalOccupancyFeeBuilder} from './statement-adjustment-display-total-occupancy-fee-builder';
import {StatementAdjustmentDisplayCreditVendorTaxRebateBuilder} from './statement-adjustment-display-credit-vendor-tax-rebate-builder';
import {PropertyTaxesUtil} from '../../property-teranet/property-taxes/property-taxes-util';
import {InterimTaxMultiplier} from '../../../shared-main/constants';
import {StatementAdjustmentDisplayComponentBuilder} from './statement-adjustment-display-component-builder';
import {SoAdjPurchasePricePercentage} from '../model/so-adj-purchase-price-percentage';
import {StatementAdjustmentDisplayPurchasePricePercentageBuilder} from './atatement-adjustment-display-purchase-price-percentage-builder';
import {HstSalePrice} from '../model/hst-sale-price-soa';
import {StatementAdjustmentDisplayInterimClosingBuilder} from './statement-adjustment-display-interim-closing-builder';
import {StatementAdjustmentDisplayReserveVTBMtgBuilder} from './statement-adjustment-display-reserve-vtb-mtg-builder';
import {SoaHeat} from '../model/heat-soa';
import {
  StatementAdjustmentDisplayAmountPaidInterimClosingBuilder
} from './statement-adjustment-display-amount-paid-interim-closing-builder';
import {ComponentAdjCreditTypeValue} from '../model/so-adj-component';
import {SoAdjHCRAFee} from '../model/so-adj-hcra-fee';
import {StatementAdjustmentDisplayHcraFeeBuilder} from './statement-adjustment-display-hcra-fee-builder';
import {SoAdjVacantOccupancyTax} from '../model/so-adj-vacant-occupancy-tax';
import {StatementAdjustmentDisplayVacantOccupancyTaxBuilder} from './statement-adjustment-display-vacant-occupancy-tax-builder';
import { PROVINCE_CODES } from '../../shared/user-province';

const INFO_ONLY = 'Info Only';

export class StatementAdjustmentDisplayBuilder {

  constructor(
    public decimalPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe) {
  }

  // todo: documentProfileCache needs to be part of constructor
  documentProfileCache: DocumentProfileCache;
  matterDocumentProfile: DocumentProfile;

  matter: Matter;

  setMatter(matter: Matter) {
    this.matter = matter;
  }

  private soaConsiderationTaxes: ConsiderationTaxes;

  setSoaConsiderationTaxes(soaConsiderationTaxes: ConsiderationTaxes) {
    if (soaConsiderationTaxes) {
      this.soaConsiderationTaxes = soaConsiderationTaxes;
    }
  }

  getSoaConsiderationTaxes(): ConsiderationTaxes {
    return this.soaConsiderationTaxes;
  }

  rentInterestRates: RentInterestRate[] = [];

  setRentInterestRates(rentInterestRates: RentInterestRate[]) {
    if (rentInterestRates) {
      this.rentInterestRates = rentInterestRates;
    }
  }

  tarionWarrantyEnrolmentPeriods: TarionWarrantyEnrolmentPeriod[] = [];

  setTarionWarrantyEnrolmentPeriods(tarionWarrantyEnrolmentPeriods: TarionWarrantyEnrolmentPeriod[]) {
    if (tarionWarrantyEnrolmentPeriods) {
      this.tarionWarrantyEnrolmentPeriods = tarionWarrantyEnrolmentPeriods;
    }
  }

  hcraFeeEnrolmentPeriods: TarionWarrantyEnrolmentPeriod[] = [];

  setHCRAFeeEnrolmentPeriods(hcraFeeEnrolmentPeriods: TarionWarrantyEnrolmentPeriod[]) {
    if (hcraFeeEnrolmentPeriods) {
      this.hcraFeeEnrolmentPeriods = hcraFeeEnrolmentPeriods;
    }
  }

  containsOutOfRangeItem: boolean = false;
  statementAdjustmentDisplayItems: StatementAdjustmentDisplayItem[] = [];
  statementOfAdjustmentBalanceDisplayItems: StatementAdjustmentDisplayBalanceItem[] = [];
  cannotCalculateAmount: string = '$??????????.??';
  invalidDate: string = '???';

  updateStatementOfAdjustmentDisplayBalanceItems() {
    // TODO - to be adjusted in UCBA-2521 to refer to the correct tab (buyer/seller) for BC

    this.statementOfAdjustmentBalanceDisplayItems = [];
    if (this.matter) {
      if (this.containsOutOfRangeItem) {
        let item1 = new StatementAdjustmentDisplayBalanceItem('balanceDueOnClosing');
        item1.title = provinceBasedFieldLabels.get('matter.soa.balanceDueOnClosing', this.matter.provinceCode);
        item1.leftValue = '';
        item1.rightValue = '';
        this.statementOfAdjustmentBalanceDisplayItems.push(item1);
        if ([ 'AB', 'MB' ].indexOf(this.matter.provinceCode) == -1) {
          let item2 = new StatementAdjustmentDisplayBalanceItem(`10119.payableTo`);
          item2.title = this.getPayableTo(this.matter);
          item2.leftValue = '';
          item2.rightValue = '';
          this.statementOfAdjustmentBalanceDisplayItems.push(item2);
        }
        if (this.matter.provinceCode === PROVINCE_CODES.BRITISH_COLOMBIA) {
          // TODO - what will the key be?
          let item3 = new StatementAdjustmentDisplayBalanceItem(`total`);
          item3.title = "Total";
          item3.leftValue = '';
          item3.rightValue = '';
          this.statementOfAdjustmentBalanceDisplayItems.push(item3);
        }

      } else {
        let item1 = new StatementAdjustmentDisplayBalanceItem('balanceDueOnClosing');
        item1.title = provinceBasedFieldLabels.get('matter.soa.balanceDueOnClosing', this.matter.provinceCode);

        item1.leftValue = this.matter.calculateBalanceDueOnClosingPurchase() !== 0 ?
          this.getCurrency(this.matter.calculateBalanceDueOnClosingPurchase())
          : '';
        item1.rightValue = this.matter.calculateBalanceDueOnClosingCreditVendor() !== 0 ?
          this.getCurrency(this.matter.calculateBalanceDueOnClosingCreditVendor())
          : '';

        this.statementOfAdjustmentBalanceDisplayItems.push(item1);
        if ([ PROVINCE_CODES.ALBERTA, PROVINCE_CODES.MANITOBA, PROVINCE_CODES.NOVA_SCOTIA, PROVINCE_CODES.NEW_BRUNSWICK ].indexOf(this.matter.provinceCode) == -1) {
          let item2 = new StatementAdjustmentDisplayBalanceItem(`10119.payableTo`);
          item2.title = this.getPayableTo(this.matter);

          if (this.matter.isMatterProvinceBC) {
            const debit = this.matter.calculateBalanceDueOnClosingPurchase();
            const credit = this.matter.calculateBalanceDueOnClosingCreditVendor();

            if (credit <= debit) {
              item2.leftValue = '';
              item2.rightValue = this.getCurrency(debit - credit);
            } else {
              item2.leftValue = this.getCurrency(credit - debit);
              item2.rightValue = '';
            }
          } else {
            item2.leftValue = this.getCurrency(this.matter.calculatePayablePurchase());
            item2.rightValue = this.getCurrency(this.matter.calculatePayableCreditVendor());
          }

          this.statementOfAdjustmentBalanceDisplayItems.push(item2);
        }
        if (this.matter.provinceCode === PROVINCE_CODES.BRITISH_COLOMBIA) {
          // TODO - what will the key be?
          // TODO - values will be updated to accurately represent Buyer/Seller tabs in a separate ticket
          let item3 = new StatementAdjustmentDisplayBalanceItem(`total`);
          item3.title = 'Total';
          const debit = this.matter.calculateBalanceDueOnClosingPurchase();
          const credit = this.matter.calculateBalanceDueOnClosingCreditVendor();

          if (credit <= debit) {
            item3.leftValue = this.getCurrency(debit);
            item3.rightValue = this.getCurrency(debit);
          } else {
            item3.leftValue = this.getCurrency(credit);
            item3.rightValue = this.getCurrency(credit);
          }

          this.statementOfAdjustmentBalanceDisplayItems.push(item3);
        }
        if (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceMB || this.matter.isMatterProvinceSK) {
          // Update Delayed Interest
          this.matter.recalcuateDelayedInterestCashToCloseChange();
        }
        this.matter.calculateStatementAdjustment();
        this.matter.updateBalanceOfFundsPayableTo();
      }
    }
  }

  /*
  This method is used to populate the StatementOfAdjustmentDisplay.rawData filed. This filed is used by Backend only, will not be displayed in the  UI.
  */
  prepareAdjustmentsRawData(): StatementAdjustmentRawData {

    let totalCreditAdjustedForRebate: number = this.calculateTotalCreditAdjustedForRebate();
    let salePriceWithoutConsideration: number = this.calculateSalePriceWithoutConsiderations();

    let item = new StatementAdjustmentRawData();
    item.totalCreditAdjustedForRebate = this.getDecimal(totalCreditAdjustedForRebate).replace(/,/g, '');
    item.netSalePriceWithoutConsiderations = (salePriceWithoutConsideration == null) ? null : this.getDecimal(salePriceWithoutConsideration).replace(/,/g, '');

    if (this.salePriceAdjustment) {

      let salePrice: number = this.matter.getSalePriceForTaxAdjustments(this.federalHstRate, this.provinceHstRate);

      let rebateFederalPortion: number = this.salePriceAdjustment.calculateFederalRebatePortion(this.federalHstRate, salePrice);

      item.federalTaxRebateAmount = (rebateFederalPortion == null) ? null :
        this.getDecimal(rebateFederalPortion).replace(/,/g, '');

      let rebateOntarioPortion: number = this.salePriceAdjustment.calculateOntarioRebatePortion(this.provinceHstRate, salePrice);
      item.provincialTaxRebateAmount = (rebateOntarioPortion == null) ? null :
        this.getDecimal(rebateOntarioPortion).replace(/,/g, '');

      item.netSalePrice = this.netSalePrice ? this.getDecimal(this.netSalePrice).replace(/,/g, '') : null;

      if (this.salePriceAdjustment.netOutHstFromHSTSalePrice !== 'NO' || this.hstSalePriceAdjustment) {
        let federalHstPortion: number = this.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(this.federalHstRate, this.federalHstRate, this.provinceHstRate);
        item.federalSaleTaxAmount = (federalHstPortion == null) ? null :
          this.getDecimal(federalHstPortion).replace(/,/g, '');

        let provinceHstPortion: number = this.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(this.provinceHstRate, this.federalHstRate, this.provinceHstRate);

        if (this.matter.provinceCode === 'ON') {

          item.provincialSaleTaxAmount = (provinceHstPortion == null) ? null :
            this.getDecimal(provinceHstPortion).replace(/,/g, '');

        } else {
          item.provincialSaleTaxAmount = '0.00';
        }

      }

    }

    let statementAdjustment: StatementAdjustment = this.interimOccupancyFeeAdjustment;

    if (statementAdjustment) {
      item.creditVendorAmount = (statementAdjustment.amount == null) ? null : this.getDecimal(statementAdjustment.amount).replace(/,/g, '');

      let interimOccupancyFee: SoAdjInterimOccupancyFee = statementAdjustment.soAdjInterimOccupancyFee;

      if (interimOccupancyFee) {
        let feesTotal: number = interimOccupancyFee.total;
        item.interimOccupancyFeesTotal = (feesTotal == null) ? null : this.getDecimal(feesTotal).replace(/,/g, '');

        // this code is duplicated from statement-adjustment-display-interim-occupancy-fee-builder.ts
        // and should be updated if original one it changed
        if (Utils.convertToBoolean(interimOccupancyFee.applyTax)) {
          let hstCalculated: number = feesTotal * interimOccupancyFee.hstRate / 100;
          item.interimOccupancyFeesHstCalculated = this.getDecimal(hstCalculated).replace(/,/g, '');
        }

        let elapsedDays: number = this.matter.isMatterProvinceAB ? Number(moment(this.adjustmentDate, 'YYYY/MM/DD').date())
          : Number(this.matter.getElapsedDays(this.adjustmentDate));
        let numberOfDaysInMonth = Number(this.matter.getNumberofDaysMonth(this.adjustmentDate));

        let numberOfDays: number = interimOccupancyFee.numberOfDaysForPurchasersShare(elapsedDays, numberOfDaysInMonth);
        item.interimOccupancyFeesOfDaysForPurchasersShare = (numberOfDays == null) ? null : this.getDecimal(numberOfDays).replace(/,/g, '');

        let purchasersShare: number = interimOccupancyFee.getPurchaserShare(elapsedDays, numberOfDaysInMonth);
        item.interimOccupancyFeesPurchasersShare = (purchasersShare == null) ? null : this.getDecimal(purchasersShare).replace(/,/g, '');

      }

    }

    let interimEarlyPossessionFeeAdj: StatementAdjustment = this.interimEarlyPossessionFeeAdjustment;

    if (interimEarlyPossessionFeeAdj) {
      item.creditVendorAmount = (interimEarlyPossessionFeeAdj.amount == null) ? null : this.getDecimal(interimEarlyPossessionFeeAdj.amount).replace(/,/g, '');

      let interimEarlyPossessionFee: SoAdjInterimEarlyPossessionFee = interimEarlyPossessionFeeAdj.soAdjInterimEarlyPossessionFee;

      if (interimEarlyPossessionFee) {
        let feesTotal: number = interimEarlyPossessionFee.total;
        item.interimOccupancyFeesTotal = (feesTotal == null) ? null : this.getDecimal(feesTotal).replace(/,/g, '');

        if (Utils.convertToBoolean(interimEarlyPossessionFee.applyTax)) {
          let hstCalculated: number = feesTotal * interimEarlyPossessionFee.hstRate / 100;
          item.interimOccupancyFeesHstCalculated = this.getDecimal(hstCalculated).replace(/,/g, '');
        }

        let elapsedDays: number = this.matter.isMatterProvinceAB ? Number(moment(this.adjustmentDate, 'YYYY/MM/DD').date())
          : Number(this.matter.getElapsedDays(this.adjustmentDate));
        let numberOfDaysInMonth = Number(this.matter.getNumberofDaysMonth(this.adjustmentDate));

        let numberOfDays: number = interimEarlyPossessionFee.numberOfDaysForPurchasersShare(elapsedDays, numberOfDaysInMonth);
        item.interimOccupancyFeesOfDaysForPurchasersShare = (numberOfDays == null) ? null : this.getDecimal(numberOfDays).replace(/,/g, '');

        let purchasersShare: number = interimEarlyPossessionFee.getPurchaserShare(elapsedDays, numberOfDaysInMonth);
        item.interimOccupancyFeesPurchasersShare = (purchasersShare == null) ? null : this.getDecimal(purchasersShare).replace(/,/g, '');

      }

    }

    let finalOccupancyAdjustments: StatementAdjustment[] = this.finalOccupancyFeeAdjustments;
    if (finalOccupancyAdjustments) {

      let finalOccupancyFeesOfDaysForPurchasersShare = new StatementAdjustmentRawDataValueHolder();
      let finalOccupancyFeesPurchasersShare = new StatementAdjustmentRawDataValueHolder();

      for (var finalOccupancyAdjustment of finalOccupancyAdjustments) {
        item.creditVendorAmount = (finalOccupancyAdjustment.amount == null) ? null : this.getDecimal(finalOccupancyAdjustment.amount).replace(/,/g, '');

        let finalOccupancyFee: SoAdjFinalOccupancyFee = finalOccupancyAdjustment.soAdjFinalOccupancyFee;

        if (finalOccupancyFee) {
          let interimAdjustAsDate: string = this.matter.adjustAsAtClosingDateFlagInterim === 'SPECIFY' ? this.matter.adjustAsAtClosingDateInterim : this.matter.matterCloseDate;

          let finalAdjustAsDate: string = this.matter.adjustAsAtClosingDateFlag === 'SPECIFY' ? this.matter.adjustAsAtClosingDate : this.matter.matterCloseDate;

          let purchaserShare: number = finalOccupancyFee.getPurchaserShareAmount(this.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate, this.matter.isPaysForDateOfClosingPurchaser, this.matter.provinceCode);

          finalOccupancyFeesPurchasersShare.valueBySpecificAdjustment[ finalOccupancyAdjustment.id ] = (purchaserShare == null) ? null : this.getDecimal(purchaserShare).replace(/,/g, '');

          let purchaserShareDays: number = finalOccupancyFee.getPurchaserShareDays(this.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate);

          finalOccupancyFeesOfDaysForPurchasersShare.valueBySpecificAdjustment[ finalOccupancyAdjustment.id ] = (purchaserShareDays == null) ? null : this.getDecimal(purchaserShareDays).replace(/,/g, '');
        }
      }
      item.finalOccupancyFeesOfDaysForPurchasersShare = finalOccupancyFeesOfDaysForPurchasersShare;
      item.finalOccupancyFeesPurchasersShare = finalOccupancyFeesPurchasersShare;
    }

    let hstAdjustments: StatementAdjustment[] = this.hstAdjustments;
    if (hstAdjustments) {

      let hstAdjustmentHeading = new StatementAdjustmentRawDataValueHolder();
      let netSalePriceBasedOnTaxType = new StatementAdjustmentRawDataValueHolder();

      for (var hstAdjustment of hstAdjustments) {

        let hstSalePrice = hstAdjustment.hstSalePrice;

        let title = '';
        if (hstSalePrice && hstSalePrice.isHstRebate()) {
          title = this.appliedTaxRate + ' REBATE ASSIGNED TO ' + this.vendorLabel.toUpperCase();
        } else {
          title = this.appliedTaxRate + (this.isOnTotalConsiderationLabel() ? ' ON TOTAL CONSIDERATION' : ' ON SALE PRICE');
        }
        hstAdjustmentHeading.valueBySpecificAdjustment[ hstAdjustment.id ] = title;

        //filter HST_SALE_PRICE adjustments to calculate netSalePrice
        if (hstSalePrice && hstSalePrice.isHstSalePrice()) {
          let taxCalculatedAt = this.calculateNetSalePriceBasedOnTaxType(hstSalePrice);
          //set the netSalePriceBasedOnTaxType to data raw object by adjustment id, since there could b emultiple adjustments with different netSalePrice depends on Tax Type
          netSalePriceBasedOnTaxType.valueBySpecificAdjustment[ hstAdjustment.id ] = (taxCalculatedAt == null) ? null : taxCalculatedAt.replace(/,/g, '');
          ;
        }
      }

      item.hstAdjustmentHeading = hstAdjustmentHeading;
      item.netSalePriceBasedOnTaxType = netSalePriceBasedOnTaxType;
    }

    let depositAdjustment: StatementAdjustment = this.depositAdjustment;
    if (depositAdjustment) {
      item.totalDepositsSelected = (depositAdjustment.amount == null) ? null : this.getDecimal(depositAdjustment.amount).replace(/,/g, '');
    }

    let balanceDueOnClosingCreditPurchaser: number = this.matter.calculateBalanceDueOnClosingCreditPurchaser();
    let balanceDueOnClosingCreditVendor: number = this.matter.calculateBalanceDueOnClosingCreditVendor();

    if (balanceDueOnClosingCreditPurchaser !== 0 && balanceDueOnClosingCreditVendor === 0) {
      item.balanceDueOnClosing = this.getDecimal(balanceDueOnClosingCreditPurchaser).replace(/,/g, '');
    } else {
      item.balanceDueOnClosing = this.getDecimal(balanceDueOnClosingCreditVendor).replace(/,/g, '');
    }

    let netSalePriceBasedOnConsiderationTypes: number = this.matter.getSalePriceForTaxAdjustments(this.federalHstRate, this.provinceHstRate);
    item.netSalePriceBasedOnConsiderationTypes = (netSalePriceBasedOnConsiderationTypes == null) ? null : this.getDecimal(netSalePriceBasedOnConsiderationTypes).replace(/,/g, '');

    let tarionAdjustments: StatementAdjustment[] = this.tarionAdjustments;
    if (tarionAdjustments) {

      let totalCreditTarionWarranty = new StatementAdjustmentRawDataValueHolder();

      for (var adjustment of tarionAdjustments) {
        if (adjustment.soAdjTarionWarranty) {
          let totalCredit = StatementAdjustmentDisplayTarionWarrantyBuilder.calculatedEnrolmentFeePlusTax(this.salePriceAdjustment, adjustment.soAdjTarionWarranty,
            this, this.projectAdjustmentConfig());

          totalCreditTarionWarranty.valueBySpecificAdjustment[ adjustment.id ] = this.getDecimal(totalCredit).replace(/,/g, '');
        }
      }

      item.totalCreditTarionWarranty = totalCreditTarionWarranty;
    }

    let interestOnDepositInterestAdjustments: StatementAdjustment[] = this.interestOnDepositInterestAdjustments;
    if (interestOnDepositInterestAdjustments) {

      let unpaidInterest = new StatementAdjustmentRawDataValueHolder();

      for (var adjustment of interestOnDepositInterestAdjustments) {

        if (!adjustment.soAdjInterestOnDepositCondo || !this.matter) {
          continue;
        }

        let unpaidAmount = 0;
        if (this.isProvinceABWithProject()) {
          let interestOnDepositUnderOldCondo = this.matter.firstInterestOnDepositUnderOldCondoSoa;
          if (interestOnDepositUnderOldCondo) {
            //The "Unpaid interest on deposits" field is populated and in sync with the value from the "Interest on Deposit - Old Condo Act" adjustment
            unpaidAmount = interestOnDepositUnderOldCondo.amount;
          }
        } else {
          let depositInterestAdjustment = this.matter.getDepositInterestAdjustment();
          if (depositInterestAdjustment) {
            unpaidAmount = depositInterestAdjustment.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(this.matter, this.matter.isSelectedAdjustmentStatusModeFinal);
          }
        }

        unpaidInterest.valueBySpecificAdjustment[ adjustment.id ] = this.getDecimal(unpaidAmount).replace(/,/g, '');
      }

      item.depositOnDepositInterestUnpaidInterest = unpaidInterest;
    }

    return item;

  }

  /*
   This method calculates the net sale price, disregarding any other adjustments. like additional consideration, buydown and credits to purchaser as
   required for code 10169.
  */
  calculateSalePriceWithoutConsiderations(): number {

    if (!this.salePriceAdjustment || this.salePriceAdjustment.netOutHstFromHSTSalePrice !== 'YES_FACTOR_IN_HST_REBATE') {
      return null;
    }

    let salePriceWithoutCosideration: number = 0;
    if (this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment) {
      let tempSalePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, this.matter.considerationLtt.salePriceAdjustment);
      tempSalePriceAdjustment.additionalConsiderationsInclHst = 0;
      tempSalePriceAdjustment.creditsToPurchaserInclHst = 0;
      tempSalePriceAdjustment.otherItems = 0;
      salePriceWithoutCosideration = tempSalePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate, true);
    }
    return salePriceWithoutCosideration;
  }

  calculateTotalCreditAdjustedForRebate(): number {

    let rebateFederalPortion: number = 0;
    let rebateProvincialPortion: number = 0;
    if (this.salePriceAdjustment) {
      if (this.salePriceAdjustment.showRebateFederalPortion()) {
        rebateFederalPortion = this.salePriceAdjustment.calculateRebate(this.federalHstRate);
        rebateFederalPortion = Utils.roundNumber(rebateFederalPortion, 2);
      }
      if (this.salePriceAdjustment.showRebateProvincialPortion()) {
        rebateProvincialPortion = this.salePriceAdjustment.calculateProvincialRebate(this.provinceHstRate);
        rebateProvincialPortion = Utils.roundNumber(rebateProvincialPortion, 2);
      }
    }

    let payablePurchase: number = this.matter.calculatePayablePurchase();
    let calculatedAmount = payablePurchase - rebateFederalPortion - rebateProvincialPortion;
    return calculatedAmount;
  }

  populateSOASummaryInPreview(): StatementAdjustmentDisplayBalanceItem {
    let item2 = new StatementAdjustmentDisplayBalanceItem();
    if (this.matter.calculateBalanceDueOnClosingPurchase() !== 0) {
      item2.leftValue = this.getCurrency(this.matter.totalCreditPurchaserAmount() + this.matter.calculateBalanceDueOnClosingPurchase());
    } else {
      item2.leftValue = this.getCurrency(this.matter.totalCreditPurchaserAmount());
    }
    if (this.matter.calculateBalanceDueOnClosingCreditVendor() !== 0) {
      item2.rightValue = this.getCurrency(this.matter.totalCreditVendorAmount() + this.matter.calculateBalanceDueOnClosingCreditVendor());
    } else {
      item2.rightValue = this.getCurrency(this.matter.totalCreditVendorAmount());
    }
    return item2;
  }

  updateStatementOfAdjustmentDisplayItems() {

    this.statementAdjustmentDisplayItems = [];
    this.containsOutOfRangeItem = false;

    if (this.matter && this.matter.statementOfAdjustments) {
      this.matter.statementOfAdjustments.forEach(
        soaItem => {
          let sadItem = new StatementAdjustmentDisplayItem();
          sadItem.itemLines = [];
          sadItem.soaItem = soaItem;
          sadItem.title = this.getAdjustmentTile(soaItem, this.matter.provinceCode);

          if (soaItem.isSalePrice() && this.doesSalePriceApply) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }
          if (soaItem.isSalePriceAdjustmentHeading()) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isDepositAdjustment() && (this.anyDeposits || this.anyDepositsPaidOnOccupancy)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isHSTOtherFactor() && soaItem.hstSalePrice && soaItem.salePriceAdjustmentHSTFactor) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isHstAdjustment() && soaItem.hstSalePrice) {
            // TODO: Serhii, this tagging makes no sense, let's discuss this please.
            if (soaItem.hstSalePrice.isHstRebate()) {
              sadItem.key = '10149.hstRebate';
              // ToDo this should not be here in the builder it should be on the statement-adjustment.component.ts after editing the HST adjustment
              // let salePrice : number = this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment
              //     ? (this.matter.considerationLtt.salePriceAdjustment.isNotInclusivePrice
              //         ? this.matter.considerationLtt.salePriceAdjustment.getTotalConsiderationCost(this.matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings)
              //         :this.matter.considerationLtt.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate, true))
              //     : 0;
              soaItem.hstSalePrice.creditAmount = !this.salePriceAdjustment.isInclusivePrice() && soaItem.hstSalePrice.isRateTypePST() ? this.salePriceAdjustment.calculateProvincialRebatePortionSubjectToPST(this.provinceHstRate) :
                this.salePriceAdjustment.totalRebatePortion(this.provinceHstRate, this.federalHstRate, this.matter.getSalePriceForTaxAdjustments(this.federalHstRate, this.provinceHstRate));
              soaItem.amount = soaItem.hstSalePrice.creditAmount;
              let leftColumnCreditsTo: string = this.matter.statementOfAdjustmentHeading ? this.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo : undefined;
              soaItem.statementOfAdjustmentCreditType = soaItem.hstSalePrice.getCreditType(leftColumnCreditsTo);
            }
            if (soaItem.hstSalePrice.isHstSalePrice()) {
              sadItem.key = '10149.hstSalePrice';
            }
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }
          if (soaItem.isCreditVendorTaxRebateAdjustment() && soaItem.soAdjCreditVendorTaxRebate) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }
          if (soaItem.isConsiderationPaidOnInterimClosing() && soaItem.soAdjConsiderationPaidOnInterimClosing) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }
          if (soaItem.isAmountPaidOnInterimClosing() && soaItem.soAdjAmountPaidOnInterimClosing) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if ((soaItem.isCommonExpenseAdjustment() || soaItem.isOccupancyCommonExpenseAdjustment() || soaItem.isPOTLCommonExpenseAdjustment()) && soaItem.soaCommonExpense) {

            if (soaItem.isCommonExpenseAdjustment() && !soaItem.isOccupancyCommonExpenseAdjustment()) {
              // the codeKey notation 10194.commonExpenses is probably temporary till we learn if there is a specific corresponding code
              sadItem.key = '10194.commonExpenses';
            }

            if (soaItem.isOccupancyCommonExpenseAdjustment()) {
              // the codeKey notation 10194.occupancyFees is probably temporary till we learn if there is a specific corresponding code
              sadItem.key = '10194.occupancyFees';
            }

            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isTotalDuringOccupancyPeriodAdjustment() && !this.isItemOutOfRange(soaItem)) {
            soaItem.amount = soaItem.soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTotalAsNumber(this.matter.getUnitLevelPlanCondominiumTotalExpenses(),
              this.matter.getCondominiumTotalSharePercentage(),
              this.matter.soAdjExpenseOccupancyPeriodClosedate,
              this.matter.soAdjExpenseOccupancyPeriodOccupancyDate);
            soaItem.description = soaItem.soAdjExpenseOccupancyPeriod.adjustmentHeading;
            soaItem.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }
          if (soaItem.isPurchasePriceDeferredPortionAdjustment()) {
            if (this.matter.project) {
              const firstInterimEarlyPossessionFeeSoa: StatementAdjustment = this.matter.firstInterimEarlyPossessionFeeSoa;
              soaItem.soAdjPurchasePriceDeferredPortion.updateDeferredPortionOfPurchase(this.matter.project.isCondo,
                this.matter.project.isPOTL,
                this.matter.project.projectCondo && this.matter.project.projectCondo.occupancyFeesCalculatedBasedOn,
                this.matter.project.isOccupancyDateRequired,
                this.matter.extraDepositConfig && this.matter.extraDepositConfig.deferredPurchaseMoniesAmount,
                firstInterimEarlyPossessionFeeSoa && firstInterimEarlyPossessionFeeSoa.soAdjInterimEarlyPossessionFee && firstInterimEarlyPossessionFeeSoa.soAdjInterimEarlyPossessionFee.mortgageInterest,
                soaItem);
            }
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isHeat() && soaItem.soaHeat) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isWater() && soaItem.soaWater && !this.isItemOutOfRange(soaItem)) {
            soaItem.statementOfAdjustmentCreditType = soaItem.soaWater.getSoaCreditType(this.adjustmentDate, this.matter.isPaysForDateOfClosingVendor);
            soaItem.amount = soaItem.soaWater.getCredit(this.adjustmentDate, this.matter.isPaysForDateOfClosingVendor);
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isTaxOther()) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjTaxOther));
          }
          if (soaItem.isSalePriceMinusNetSalePrice()) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isInterestOnDeferredMoniesDuringOccupancy() && this.matter.project && this.matter.project.projectCondo) {
            if (this.matter.project.projectCondo.isOccupancyFeesCalculatedBasedOnPurchaseMonies) {
              soaItem.description = 'INTEREST ON DEFERRED PURCHASE MONIES';
            } else if (this.matter.project.projectCondo.isOccupancyFeesCalculatedBasedOnPhantomMortgage) {
              soaItem.description = 'INTEREST ON VTB MORTGAGE';
            }
          }

          if ((soaItem.isInterest() || soaItem.isInterestOnLumpSum() || soaItem.isInterestOnDeferredMoniesDuringOccupancy()) && soaItem.soAdjInterestCal && !this.isItemOutOfRange(soaItem)) {
            soaItem.soAdjInterestCal.applyMatterUpdatesOnInterestCalculationPeriod(this.matter);
            if (soaItem.isInterestOnDeferredMoniesDuringOccupancy()) {
              soaItem.soAdjInterestCal.applyUpdatesBasedOnProjectSettings(this.matter, this.matter.project);
            }
            soaItem.statementOfAdjustmentCreditType = soaItem.soAdjInterestCal.isInterestCreditTo == this.purchaserLabel ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR;
            soaItem.amount = soaItem.soAdjInterestCal.calculateSoAInterest(this.matter.provinceCode, this.matter.isPaysForDateOfClosingVendor, soaItem.isInterestOnDeferredMoniesDuringOccupancy());
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjInterestCal, '', false, soaItem.itemKey));
          }

          if (soaItem.isAnyPropertyTaxes() && !this.isItemOutOfRange(soaItem)) {
            if (soaItem.isOriginPropertyTaxes()) {
              let propertyMatterTax: MatterTax;
              if (Array.isArray(this.matter.matterProperties) && this.matter.matterProperties.length > 0 && Array.isArray(this.matter.matterProperties[ 0 ].matterTaxesByRollNumber) && this.matter.matterProperties[ 0 ].matterTaxesByRollNumber.length > 0) {
                propertyMatterTax = this.matter.matterProperties[ 0 ].matterTaxesByRollNumber.find(matterTaxByRollNumber => matterTaxByRollNumber.id === soaItem.propertyMatterTaxId);
              }
              if (!propertyMatterTax) { // matterTaxesByRollNumber does not get populated before save
                propertyMatterTax = this.matter.matterProperties[ 0 ].matterTax;
              }
              if (soaItem.isRealtyTax(propertyMatterTax)) {
                // the codeKey notation 10194.realtyTaxes is probably temporary till we learn if there is a specific corresponding code
                sadItem.key = '10194.realtyTaxes';
              }
              if (soaItem.infoOnly) {
                soaItem.amount = 0;
              }
              sadItem.itemLines.push(...this.getDisplayItems(propertyMatterTax));
            } else {
              if (soaItem.isRealtyTax() && !soaItem.isAdjOfTypeTaxBeginningOfYearUntilOcc()) {
                // the codeKey notation 10194.realtyTaxes is probably temporary till we learn if there is a specific corresponding code
                sadItem.key = '10194.realtyTaxes';
                soaItem.amount = PropertyTaxesUtil.calculateVendorShareDifference(this.matter, soaItem.matterTax);
              }
              if (soaItem.infoOnly) {
                soaItem.amount = 0;
              }
              sadItem.itemLines.push(...this.getDisplayItems(soaItem.matterTax, '', false, soaItem.itemKey));
              this.matter.createUpdateAdjustmentPropertyTaxes(soaItem);
            }
          }
          if (soaItem.isTaxesPaidByVendorAdjustment() && soaItem.matterTax) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }
          if ((soaItem.isReserveFundAdjustment() || soaItem.isSalesIncentiveAdjustment()) && soaItem.soaExpenseAdjustment) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soaExpenseAdjustment));
            if (soaItem.soaExpenseAdjustment.infoOnly) {
              soaItem.amount = 0;
            }
          }
          if (soaItem.isPreviousYearRealtyTaxAdjustment() && soaItem.soaRealtyTaxAdjustment) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soaRealtyTaxAdjustment));
          }
          if (soaItem.isOtherFixed() && soaItem.soAdjOtherFixed) {
            soaItem.statementOfAdjustmentCreditType = soaItem.soAdjOtherFixed.creditType.toUpperCase();
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjOtherFixed));
            sadItem.noteReferenceMark = StatementAdjustmentDisplayOtherFixedBuilder.getNoteReferenceMark(soaItem.soAdjOtherFixed);
            if (soaItem.soAdjOtherFixed.infoOnly) {
              soaItem.amount = 0;
            }
          }
          if (soaItem.isPercentageOfPurchasePrice() && soaItem.soAdjPurchasePricePercentage) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjPurchasePricePercentage));
            soaItem.amount = soaItem.soAdjPurchasePricePercentage.getTotalAmount();
          }
          if (soaItem.isOtherFixedPayableOnOccupancy() && soaItem.soAdjOtherFixedPayableOnOccupancy) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjOtherFixedPayableOnOccupancy));
            sadItem.noteReferenceMark = StatementAdjustmentDisplayOtherFixedBuilder.getNoteReferenceMark(soaItem.soAdjOtherFixedPayableOnOccupancy);
            if (soaItem.soAdjOtherFixedPayableOnOccupancy.infoOnly) {
              soaItem.amount = 0;
            }
          }

          if (soaItem.isOtherProratedOnPercentageInterest() && soaItem.soAdjOtherProratedOnPercentageInterest) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjOtherProratedOnPercentageInterest, this.matter.getClosingDate(), false, StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST));
            sadItem.noteReferenceMark = StatementAdjustmentDisplayOtherFixedBuilder.getNoteReferenceMark(soaItem.soAdjOtherProratedOnPercentageInterest);
          }

          if (soaItem.isOtherProrated() && soaItem.soAdjOtherProrated && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjOtherProrated, this.matter.getClosingDate()));
            soaItem.statementOfAdjustmentCreditType = soaItem.soAdjOtherProrated.getCreditType(this.matter.getClosingDate(), undefined, this.matter.paysForDateOfClosing);
            if (soaItem.soAdjOtherProrated.infoOnly) {
              soaItem.amount = 0;
            }
          }

          if (soaItem.isAmountPaidOnInterimClosing() && soaItem.soAdjAmountPaidOnInterimClosing && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjAmountPaidOnInterimClosing, this.matter.getClosingDate()));
            if (soaItem.soAdjAmountPaidOnInterimClosing.infoOnly) {
              soaItem.amount = 0;
            }
          }

          if (soaItem.isHomeOwnersResidentFee() && soaItem.soAdjHomeOwnersResidentFee && !this.isItemOutOfRange(soaItem)) {
            soaItem.amount = soaItem.soAdjHomeOwnersResidentFee.getCreditAmount(this.adjustmentDate, StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE, this.matter.paysForDateOfClosing);
            soaItem.description = soaItem.soAdjHomeOwnersResidentFee.heading;
            soaItem.statementOfAdjustmentCreditType = soaItem.soAdjHomeOwnersResidentFee.getCreditType(this.adjustmentDate, StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE, this.matter.paysForDateOfClosing);
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjHomeOwnersResidentFee, this.matter.getClosingDate(), false, StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE));
          }

          if (soaItem.isTenancyCurrent() && soaItem.soAdjTenancyCurrent && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjTenancyCurrent, this.matter.getClosingDate()));
          }

          if (soaItem.isTenancyPrepaid() && soaItem.soAdjTenancyPrepaid && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjTenancyPrepaid, this.matter.getClosingDate(), false));
          }

          if (soaItem.isAssumedMortgage() && soaItem.soAdjAssumedMortgage && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjAssumedMortgage, this.matter.getClosingDate()));
          }

          if (soaItem.isTarionWarranty() && soaItem.soAdjTarionWarranty) {

            // the codeKey notation 10194.tarion is probably temporary till we learn if there is a specific corresponding code
            sadItem.key = '10194.tarion';

            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjTarionWarranty));
            // add references to footers
            if (soaItem.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE') {
              sadItem.noteReferenceMark = '1';
            }
            if (soaItem.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE') {
              sadItem.noteReferenceMark = '2';
            }
          }
          if (soaItem.isHCRAFee() && soaItem.soAdjHCRAFee) {

            // HCRA ? what is key for hcra
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjHCRAFee));
            // add references to footers
            if (soaItem.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE') {
              sadItem.noteReferenceMark = '1';
            }
            if (soaItem.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE') {
              sadItem.noteReferenceMark = '2';
            }

          }
          if (soaItem.isVacantOccupancyTax() && soaItem.soAdjVacantOccupancyTax) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjVacantOccupancyTax));
          }
          if (soaItem.isVTBMortgage() && soaItem.soAdjVTBMortgage) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjVTBMortgage));
            if (soaItem.infoOnly) {
              soaItem.amount = 0;
            } else if (soaItem.amount == 0) {
              soaItem.amount = this.matter.getMortgagePrincipalByMortgageId(soaItem.soAdjVTBMortgage.mortgageId);
            }
          }

          if (soaItem.isInterimOccupancyFee() && soaItem.soAdjInterimOccupancyFee && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjInterimOccupancyFee));
          }

          if (soaItem.isInterimEarlyPossessionFee() && soaItem.soAdjInterimEarlyPossessionFee && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjInterimEarlyPossessionFee));
          }

          if (soaItem.isFinalOccupancyFee() && soaItem.soAdjFinalOccupancyFee && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjFinalOccupancyFee));
          }

          if (soaItem.isFinalEarlyPossessionFee() && soaItem.soAdjFinalEarlyPossessionFee && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjFinalEarlyPossessionFee));
          }

          if (soaItem.isTaxesPaidDuringOccupancy() && soaItem.taxPortion) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isInterestOnDepositCondo() && soaItem.soAdjInterestOnDepositCondo && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isForm4Charges() && soaItem.soAdjForm4Charge) {
            soaItem.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isInterestOnDepositInterestCondo() && soaItem.soAdjInterestOnDepositCondo && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isInterestOnDepositUnderOldCondo() && soaItem.soAdjInterestOnDepositCondo && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isTotalOccupancyFee() && soaItem.soAdjTotalOccupancyFee && !this.isItemOutOfRange(soaItem)) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem.soAdjTotalOccupancyFee));
          }

          if (soaItem.isItemizedCreditToVendorPurchaser() && soaItem.soAdjItemizedCreditToVendorPurchaser) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          if (soaItem.isComponentAdjustment() && soaItem.soAdjComponent) {
            sadItem.itemLines.push(...this.getDisplayItems(soaItem));
          }

          sadItem.leftValue = '';
          sadItem.rightValue = '';
          if (this.getTaxesLabel(soaItem.matterTax) != 'No Adjustment' || (soaItem.isDepositAdjustment() && !soaItem.infoOnly)) {
            sadItem.leftValue = this.getCreditPurchaser(soaItem);
            sadItem.rightValue = this.getCreditVendor(soaItem);
          }

          if (this.showNoAdjustmentOnVendorPurchaser(soaItem)) {
            sadItem.leftValue = 'No Adjustment';
            sadItem.rightValue = 'No Adjustment';
          }
          // 'No Adjustment' for Metered or Well Water --- DPPMP-14837
          if (soaItem.isWater() && (soaItem.soaWater.isWaterSupplyTypeMetered || soaItem.soaWater.isWaterSupplyTypeWellWater)) {
            sadItem.leftValue = 'No Adjustment';
            sadItem.rightValue = 'No Adjustment';
          }
          if (soaItem.isVTBMortgage() && soaItem.soAdjVTBMortgage && soaItem.infoOnly) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }

          if (soaItem.soAdjOtherProrated && soaItem.soAdjOtherProrated.infoOnly) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (soaItem.soaExpenseAdjustment && soaItem.soaExpenseAdjustment.infoOnly) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if ((soaItem.isCommonExpenseAdjustment() || soaItem.isOccupancyCommonExpenseAdjustment() || soaItem.isPOTLCommonExpenseAdjustment())) {
            if (soaItem.amount == 0) {
              sadItem.leftValue = 'No Adjustment';
              sadItem.rightValue = 'No Adjustment';
            }
          }
          if (soaItem.isSalePrice()) {
            soaItem.infoOnly = this.matter && this.matter.considerationLtt
              && this.matter.considerationLtt.salePriceAdjustment && this.matter.considerationLtt.salePriceAdjustment.infoOnly;
          }
          if (soaItem.isInterimOccupancyFee() && Utils.convertToBoolean(soaItem.soAdjInterimOccupancyFee.infoOnly)) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (soaItem.isTaxesPaidDuringOccupancy() && soaItem.amount == 0) {
            sadItem.leftValue = 'No Adjustment';
            sadItem.rightValue = 'No Adjustment';
          }
          if (soaItem.isInterimEarlyPossessionFee() && Utils.convertToBoolean(soaItem.soAdjInterimEarlyPossessionFee.infoOnly)) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (soaItem.isInterestOnDepositCondo() && Utils.convertToBoolean(soaItem.infoOnly)) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (this.isItemOutOfRange(soaItem)) {
            sadItem.leftValue = '-OUT OF RANGE-';
            sadItem.rightValue = '-OUT OF RANGE-';
            this.containsOutOfRangeItem = true;
          }
          // for this "Special" adjustments the credit value of $0.00 needs to be removed from the main screen
          if (soaItem.soAdjOtherFixed && !soaItem.soAdjOtherFixed.infoOnly &&
            (soaItem.soAdjOtherFixed.creditType == OtherFixedCreditTypes.SILENT ||
              soaItem.soAdjOtherFixed.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT ||
              soaItem.amount === 0)) {
            sadItem.leftValue = '';
            sadItem.rightValue = '';
          }
          if (soaItem.soAdjOtherFixed && soaItem.soAdjOtherFixed.infoOnly) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (soaItem.isComponentAdjustment() &&
            (soaItem.soAdjComponent.creditType == ComponentAdjCreditTypeValue.SILENT ||
              soaItem.soAdjComponent.creditType == ComponentAdjCreditTypeValue.NO_ADJUSTMENT)) {
            if (soaItem.soAdjComponent.creditType == ComponentAdjCreditTypeValue.NO_ADJUSTMENT) {
              sadItem.leftValue = 'No Adjustment';
              sadItem.rightValue = 'No Adjustment';
            } else {
              sadItem.leftValue = '';
              sadItem.rightValue = '';
            }
          }
          if (soaItem.soAdjOtherFixedPayableOnOccupancy && !soaItem.soAdjOtherFixedPayableOnOccupancy.infoOnly &&
            (soaItem.soAdjOtherFixedPayableOnOccupancy.creditType == OtherFixedCreditTypes.SILENT ||
              soaItem.amount === 0)) {
            sadItem.leftValue = '';
            sadItem.rightValue = '';
          }
          if (soaItem.soAdjOtherFixedPayableOnOccupancy && !soaItem.soAdjOtherFixedPayableOnOccupancy.infoOnly
            && soaItem.soAdjOtherFixedPayableOnOccupancy.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT) {
            sadItem.leftValue = 'No Adjustment';
            sadItem.rightValue = 'No Adjustment';
          }
          if (soaItem.soAdjOtherFixedPayableOnOccupancy && soaItem.soAdjOtherFixedPayableOnOccupancy.infoOnly) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (soaItem.soAdjOtherProratedOnPercentageInterest &&
            (soaItem.soAdjOtherProratedOnPercentageInterest.creditType == OtherFixedCreditTypes.SILENT ||
              soaItem.amount === 0) && soaItem.soAdjOtherProratedOnPercentageInterest.creditType !== OtherFixedCreditTypes.NO_ADJUSTMENT) {
            sadItem.leftValue = '';
            sadItem.rightValue = '';
          }
          if (soaItem.soAdjTarionWarranty && soaItem.amount === 0) {
            sadItem.leftValue = '';
            sadItem.rightValue = '';
          }
          if (soaItem.soAdjHCRAFee && soaItem.amount === 0) {
            sadItem.leftValue = '';
            sadItem.rightValue = '';
          }
          if (this.matter && this.matter.isProjectSale && soaItem.isProjectAdjustmentNotAppliedOnMatter()) {
            sadItem.leftValue = '';
            sadItem.rightValue = '';
          }
          if (soaItem.infoOnly) {
            sadItem.leftValue = INFO_ONLY;
            sadItem.rightValue = INFO_ONLY;
          }
          if (soaItem.isItemizedCreditToVendorPurchaser() && soaItem.soAdjItemizedCreditToVendorPurchaser) {
            if (soaItem.soAdjItemizedCreditToVendorPurchaser.adjustmentType === 'NO_ADJUSTMENT') {
              sadItem.leftValue = 'No Adjustment';
              sadItem.rightValue = 'No Adjustment';
            } else if (soaItem.soAdjItemizedCreditToVendorPurchaser.adjustmentType === 'SILENT') {
              sadItem.leftValue = '';
              sadItem.rightValue = '';
            }

          }
          this.statementAdjustmentDisplayItems.push(sadItem);
        }
      );
    }
    if (this.matter && this.matter.statementOfAdjustmentsUnApplied) {
      this.matter.statementOfAdjustmentsUnApplied.forEach(
        (soaItem: StatementAdjustment) => {
          let sadItem = new StatementAdjustmentDisplayItem();
          sadItem.itemLines = [];
          sadItem.soaItem = soaItem;
          sadItem.title = this.getAdjustmentTile(soaItem, this.matter.provinceCode);
          sadItem.leftValue = '';
          sadItem.rightValue = '';
          this.statementAdjustmentDisplayItems.push(sadItem);
        });

    }

  }

  // this method is also used on some modal components not only on this class

  // getDisplayItems(soAdj: any, closingDate: string, toBeUsedOnModal: boolean): StatementOfAdjustmentDisplayItemLine[] {
  getDisplayItems<T>(soAdj: T, closingDate: string = '', toBeUsedOnModal: boolean = false, statementAdjustmentKey?: string): StatementOfAdjustmentDisplayItemLine[] {

    if (soAdj) {

      if (soAdj instanceof StatementAdjustment) {
        if (soAdj.isSalePrice()) {
          return StatementAdjustmentDisplaySalePriceBuilder.getDisplayItems(null, this);
        }
        if (soAdj.isSalePriceAdjustmentHeading()) {
          return StatementAdjustmentDisplaySalePriceBuilder.getDisplayItems(null, this, soAdj);
        }
        if (soAdj.isDepositAdjustment()) {
          return StatementAdjustmentDisplayDepositBuilder.getDisplayItems(this);
        }
        if (soAdj.isHSTOtherFactor()) {
          return StatementAdjustmentDisplayHstOtherBuilder.getDisplayItems(soAdj, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateTypeLabel);
        }
        if (soAdj.isHstAdjustment()) {
          return StatementAdjustmentDisplayHstBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isCommonExpenseAdjustment() || soAdj.isOccupancyCommonExpenseAdjustment() || soAdj.isPOTLCommonExpenseAdjustment()) {
          return StatementAdjustmentDisplayCommonExpenseBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isTotalDuringOccupancyPeriodAdjustment()) {
          return StatementAdjustmentDisplayOccupancyPeriodBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isConsiderationPaidOnInterimClosing()) {
          return StatementAdjustmentDisplayInterimClosingBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isAmountPaidOnInterimClosing()) {
          return StatementAdjustmentDisplayAmountPaidInterimClosingBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isPurchasePriceDeferredPortionAdjustment()) {
          return StatementAdjustmentDisplayPurchasePriceDeferredPortionBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isCreditVendorTaxRebateAdjustment()) {
          return StatementAdjustmentDisplayCreditVendorTaxRebateBuilder.getDisplayItems(soAdj, this);
        }
        if (soAdj.isWater()) {
          return StatementAdjustmentDisplayWaterBuilder.getDisplayItems(soAdj.soaWater, this);
        }

        if (soAdj.isHeat()) {
          return StatementAdjustmentDisplayHeatBuilder.getDisplayItems(soAdj.soaHeat, this);
        }
        if (soAdj.isSalePriceMinusNetSalePrice()) {
          let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
          sadItemDetails.push(this.getNewItemLine(`Credit Vendor`, ``));
          return sadItemDetails;
        }

        if (soAdj.isTaxesPaidDuringOccupancy()) {
          return StatementAdjustmentDisplayTaxesPaidDuringOccupancy.getDisplayItems(soAdj.taxPortion, this);
        }

        if (soAdj.isTaxesPaidByVendorAdjustment()) {
          return StatementAdjustmentDisplayTaxesVendorBuilder.getDisplayItems(soAdj, this, this.matter.adjustmentStatusMode, this.matter.getClosingDate());
        }

        if (soAdj.isInterestOnDepositCondo()) {
          return StatementAdjustmentDisplayInterestDeposit.getDisplayItems(soAdj, this, this.matter);
        }

        if (soAdj.isForm4Charges()) {
          return StatementAdjustmentDisplayForm4Charge.getDisplayItems(soAdj.soAdjForm4Charge, this, this.matter);
        }

        if (soAdj.isInterestOnDepositInterestCondo()) {
          return StatementAdjustmentDisplayInterestDeposit.getDisplayItems(soAdj, this, this.matter);
        }

        if (soAdj.isInterestOnDepositUnderOldCondo()) {
          return StatementAdjustmentDisplayInterestDeposit.getDisplayItems(soAdj, this, this.matter);
        }

        if (soAdj.isItemizedCreditToVendorPurchaser()) {
          return StatementAdjustmentDisplayItemizedCreditBuilder.getDisplayItems(soAdj, this, this.federalHstRate, this.provinceHstRate);
        }

        if (soAdj.isComponentAdjustment()) {
          return StatementAdjustmentDisplayComponentBuilder.getDisplayItems(soAdj, this);
        }

        if (soAdj.isReserveForVTBMtg()) {
          return StatementAdjustmentDisplayReserveVTBMtgBuilder.getDisplayItems(soAdj, this);
        }
      }

      if (soAdj instanceof SalePriceAdjustmentBase) {
        return StatementAdjustmentDisplaySalePriceBuilder.getDisplayItems(soAdj, this);
      }
      if (soAdj instanceof SoaExpenseAdjustment) {
        return StatementAdjustmentDisplayReserveFundBuilder.getDisplayItems(soAdj, this, this.soaCommonExpenseAmount(soAdj));
      }
      if (soAdj instanceof SoaRealtyTaxAdjustment) {
        return StatementAdjustmentDisplayRealtyTaxBuilder.getDisplayItems(soAdj, this);
      }

      if (soAdj instanceof SoaHeat) {
        return StatementAdjustmentDisplayHeatBuilder.getDisplayItems(soAdj, this, toBeUsedOnModal);
      }

      if (soAdj instanceof SoAdjTaxOther) {
        return StatementAdjustmentDisplayTaxOtherBuilder.getDisplayItems(soAdj, this);
      }

      if (soAdj instanceof MatterTax) {
        return StatementAdjustmentDisplayPropertyTaxBuilder.getDisplayItems(soAdj, this, statementAdjustmentKey);
      }

      if (soAdj instanceof InterestSoa) {
        return StatementAdjustmentDisplayInterestBuilder.getDisplayItems(soAdj, this, statementAdjustmentKey);
      }

      if (soAdj instanceof SoAdjOtherFixed) {
        return StatementAdjustmentDisplayOtherFixedBuilder.getDisplayItems(soAdj, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType, statementAdjustmentKey, toBeUsedOnModal);
      }

      if (soAdj instanceof SoAdjOtherProrated) {
        return StatementAdjustmentDisplayOtherProratedBuilder.getDisplayItems(soAdj, closingDate, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType, statementAdjustmentKey);
      }

      if (soAdj instanceof SoAdjPurchasePricePercentage) {
        return StatementAdjustmentDisplayPurchasePricePercentageBuilder.getDisplayItems(soAdj, closingDate, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType, toBeUsedOnModal);
      }

      if (soAdj instanceof SoAdjTenancyCurrent) {
        return StatementAdjustmentDisplayTenancyCurrentBuilder.getDisplayItems(soAdj, closingDate, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType);
      }

      if (soAdj instanceof SoAdjTenancyPrepaid) {
        return StatementAdjustmentDisplayTenancyPrepaidBuilder.getDisplayItems(soAdj, closingDate, toBeUsedOnModal, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType);
      }

      if (soAdj instanceof SoAdjAssumedMortgage) {
        return StatementAdjustmentDisplayAssumedMortgageBuilder.getDisplayItems(soAdj, closingDate, this);
      }

      if (soAdj instanceof SoAdjTarionWarranty) {
        return StatementAdjustmentDisplayTarionWarrantyBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this, this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType, this.projectAdjustmentConfig());
      }

      if (soAdj instanceof SoAdjHCRAFee) {
        return StatementAdjustmentDisplayHcraFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }
      if (soAdj instanceof SoAdjVacantOccupancyTax) {
        return StatementAdjustmentDisplayVacantOccupancyTaxBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }

      if (soAdj instanceof SoAdjVTBMortgage) {
        return StatementAdjustmentDisplayVTBMortgageBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }

      if (soAdj instanceof SoAdjInterimOccupancyFee) {
        return StatementAdjustmentDisplayInterimOccupancyFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }

      if (soAdj instanceof SoAdjInterimEarlyPossessionFee) {
        return StatementAdjustmentDisplayInterimEarlyPossessionFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }

      if (soAdj instanceof SoAdjFinalOccupancyFee) {
        return StatementAdjustmentDisplayFinalOccupancyFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }

      if (soAdj instanceof SoAdjFinalEarlyPossessionFee) {
        return StatementAdjustmentDisplayFinalEarlyPossessionFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }
      if (soAdj instanceof SoAdjTotalOccupancyFee) {
        return StatementAdjustmentDisplayTotalOccupancyFeeBuilder.getDisplayItems(soAdj, toBeUsedOnModal, this);
      }

      console.log('StatementAdjustmentDisplayBuilder.getDisplayItems called with an invalid adjustment type');
    }
    return [];
  }

  showNoAdjustmentOnVendorPurchaser(soaItem: StatementAdjustment): boolean {
    return (soaItem.matterTax && (soaItem.matterTax.baseTaxAdjustmentTaxBillType == 'NOT_ASSESSED' || soaItem.matterTax.calculateVendorShareDifference(InterimTaxMultiplier, this.matter.getClosingDate(), this.matter.isPaysForDateOfClosingVendor, this.matter.isProjectProportionateShare, this.matter.provinceCode) === 0)) ||
      (soaItem.soAdjOtherFixed && soaItem.soAdjOtherFixed.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT);
  }

  isItemOutOfRange(soaItem: StatementAdjustment): boolean {
    return this.matter && this.matter.isItemOutOfRange(soaItem);
  }

  soaCommonExpenseAmount(soaExpenseAdjustment: SoaExpenseAdjustment): number {
    let soaCommonExpenseAmount = 0;
    if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.condominiumTotalExpenses) {
      soaCommonExpenseAmount = this.matter.matterPropertyWithCondo.condominiumTotalExpenses.valueOf();
    } else if (this.matter && this.matter.isProjectSale && this.matter.matterPropertyWithCondo && !this.matter.matterPropertyWithCondo.isPropertyCondominium() && this.matter.matterPropertyWithCondo.isPropertyParcelOfTiedLand()) {
      soaCommonExpenseAmount = this.matter.matterPropertyWithCondo.commonExpenses.valueOf();
    }
    if (soaExpenseAdjustment.isAdjustmentTypeSaleIncentive() && soaExpenseAdjustment.isAdditionalAmountApplicable()) {
      if (soaExpenseAdjustment.isOtherAmountSelected()) {
        return Number(soaExpenseAdjustment.additionalMonthlyAmount);
      } else {
        return (Number(soaCommonExpenseAmount) + Number(soaExpenseAdjustment.additionalMonthlyAmount));
      }
    } else {
      return soaCommonExpenseAmount;
    }
  }

  calculateSalePriceMinusNetSalePrice(): number {
    return this.matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice != 'NO' ? Number(this.matter.considerationLtt.salePriceAdjustment.agreementSalePrice) - Number(this.matter.considerationLtt.totalConsideration) : 0;
  }

  baseTaxAdjustment(): ProjectTaxAdjustmentConfig {
    return this.matter.project && this.matter.adjustmentStatusMode === ProgressionStatus.FINAL ?
      this.matter.project.projectAdjustmentConfig.taxesSameAsInterim ? this.matter.project.projectAdjustmentConfig.interimTaxAdjustment
        : this.matter.project.projectAdjustmentConfig.finalTaxAdjustment
      : this.matter.project && this.matter.project.projectAdjustmentConfig.interimTaxAdjustment ? this.matter.project.projectAdjustmentConfig.interimTaxAdjustment : null;
  }

  projectAdjustmentConfig(): ProjectAdjustmentConfig {
    return this.matter && this.matter.project && this.matter.project.projectAdjustmentConfig;
  }

  /*
      isItemOutOfRange(soaItem: StatementAdjustment): boolean {
          if (soaItem.isInterest() && soaItem.soAdjInterestCal && !soaItem.soAdjInterestCal.isValidAdjustmentDate(this.adjustmentDate)) {
              soaItem.isOutOfRange = true;
              soaItem.outOfRangeAdjustmentName = "Interest Calculation Adjustment";
              return true;
          }
          if (soaItem.isWater() && soaItem.soaWater && !soaItem.soaWater.isValidAdjustmentDate(this.adjustmentDate)) {
              soaItem.isOutOfRange = true;
              soaItem.outOfRangeAdjustmentName = "Water Adjustment";
              return true;
          }
          if (soaItem.isOtherProrated() && soaItem.soAdjOtherProrated &&
              (
                  (soaItem.soAdjOtherProrated.getDateDiff(this.adjustmentDate, soaItem.soAdjOtherProrated.commencementDate) > 0) ||
                  (soaItem.soAdjOtherProrated.getDateDiff(soaItem.soAdjOtherProrated.expiryDate, this.adjustmentDate) > 0) ||
                  (soaItem.soAdjOtherProrated.isNotValidDate(this.adjustmentDate)))) {
              soaItem.isOutOfRange = true;
              soaItem.outOfRangeAdjustmentName = "Other Prorated Adjustment";
              return true;
          }
          if (soaItem.isHomeOwnersResidentFee() && soaItem.soAdjHomeOwnersResidentFee &&
              (
                  (soaItem.soAdjHomeOwnersResidentFee.getDateDiff(this.adjustmentDate, soaItem.soAdjHomeOwnersResidentFee.commencementDate) > 0) ||
                  ((this.matter.isPaysForDateOfClosingPurchaser && soaItem.soAdjHomeOwnersResidentFee.getDateDiff(soaItem.soAdjHomeOwnersResidentFee.expiryDate, this.adjustmentDate) > 1)
                      || (this.matter.isPaysForDateOfClosingVendor && soaItem.soAdjHomeOwnersResidentFee.getDateDiff(soaItem.soAdjHomeOwnersResidentFee.expiryDate, this.adjustmentDate) > 0)) ||
                  (soaItem.soAdjHomeOwnersResidentFee.isNotValidDate(this.adjustmentDate)))) {
              soaItem.isOutOfRange = true;
              soaItem.outOfRangeAdjustmentName = "Homeowners Resident's Fee";
              return true;
          }
          if (soaItem.isTenancyCurrent() && soaItem.soAdjTenancyCurrent &&
              (
                  (soaItem.soAdjTenancyCurrent.getDateDiff(this.adjustmentDate, soaItem.soAdjTenancyCurrent.commencementDate) > 0) ||
                  (soaItem.soAdjTenancyCurrent.getDateDiff(soaItem.soAdjTenancyCurrent.endDate, this.adjustmentDate) > 0) ||
                  (soaItem.soAdjTenancyCurrent.isNotValidDate(this.adjustmentDate)))) {
              soaItem.isOutOfRange = true;
              soaItem.outOfRangeAdjustmentName = "Tenancy Adjustment";
              return true;
          }
          if (soaItem.isTenancyPrepaid() && soaItem.soAdjTenancyPrepaid){

              let validFromDate: boolean = !Utils.isNotValidDate(soaItem.soAdjTenancyPrepaid.interestCalculatedFromDate);
              let dateDiff: number = Utils.getDateDiff(this.adjustmentDate, soaItem.soAdjTenancyPrepaid.interestCalculatedFromDate) ;
              let outOfRange: boolean = false;

              if (soaItem.soAdjTenancyPrepaid.receiveCreditWithInterestBoolean) {

                  if (soaItem.soAdjTenancyPrepaid.performInterestCalculationBoolean){
                      outOfRange = (dateDiff >= 0 || ! validFromDate);
                  } else{
                      outOfRange = (validFromDate && dateDiff >= 0);
                  }
                  if (outOfRange) {
                      soaItem.isOutOfRange = true;
                      soaItem.outOfRangeAdjustmentName = "Tenancy Adjustment";
                      return true;
                  }
              }
          }
          if (soaItem.isAssumedMortgage() && soaItem.soAdjAssumedMortgage &&
              (
                  soaItem.soAdjAssumedMortgage.combinedOrSeparate == 'SEPARATE' &&
                  ( Utils.getDateDiff(this.adjustmentDate, soaItem.soAdjAssumedMortgage.principalAsAtDate) >= 0 ||
                      Utils.isNotValidDate(this.adjustmentDate)))) {
              soaItem.isOutOfRange = true;
              soaItem.outOfRangeAdjustmentName = "Assumed Mortgage";
              return true;
          }
          soaItem.isOutOfRange = false;
          return false;
      }
  */

  //since use 'CAD' to format 12.23 get CA$12.23, so use USD instead
  formatCurrency(value: number): string {
    return (value != undefined && value != null && !isNaN(value)) ? this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2') : '';
  }

  formattedDate(dateStr: string, useLongFormat?: boolean): string {
    if (useLongFormat) {
      return dateStr ? moment(dateStr).format('MMMM DD, YYYY') : '';
    } else {
      return dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
    }
  }

  shortFormattedDate(dateStr: string): string {
    return dateStr ? moment(dateStr).format('MMM DD/YY') : '';
  }

  isStringCurrencyZero(input: string): boolean {

    let currency = Number(input);
    if (isNaN(currency)) {
      return false;
    } else {
      return currency === 0;
    }
  }

  getCurrencyFromString(currencyValue: string): string {
    let currency = Number(currencyValue);

    if (isNaN(currency)) {
      return currencyValue;
    } else {
      return this.getCurrency(currency);
    }
  }

  getCurrency(currencyValue: number, numberOfDecimals: number = 2, allowBlank: boolean = false): string {
    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol', `1.${ numberOfDecimals }-${ numberOfDecimals }`).replace('CA', '');
    } else {
      if (allowBlank) {
        return '';
      } else {
        return this.currencyPipe.transform('0.00', 'CAD', 'symbol', `1.${ numberOfDecimals }-${ numberOfDecimals }`).replace('CA', '');
      }
    }
  }

  getPercentage(percentage: number): string {
    if (percentage != undefined && !isNaN(percentage) && percentage != null && percentage.toString().trim() != '') {
      return this.percentPipe.transform(percentage / 100, '1.2-3');
    } else {
      return this.percentPipe.transform('0.00', '1.2-3');
    }
  }

  getDecimal(decimal: number, maxDigits?: number): string {
    if (decimal != undefined && !isNaN(decimal) && decimal != null && decimal.toString().trim() != '') {
      return this.decimalPipe.transform(decimal, '1.2-2');
    } else if (decimal != undefined && !isNaN(decimal) && decimal != null && decimal.toString().trim() != '' && maxDigits) {
      return this.decimalPipe.transform(decimal, '1.2-' + maxDigits);
    } else {
      return this.decimalPipe.transform('0.00', '1.2-2');
    }
  }

  getCreditVendor(item: StatementAdjustment): string {
    if (item.isCreditVendor() && item.amount != undefined) {
      return this.formatCurrency(item.amount);
    } else if (item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.NO_ADJUSTMENT) {
      return 'No Adjustment';
    } else if (item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.INFO_ONLY) {
      return 'Info Only';
    } else {
      return '';
    }

  }

  getCreditPurchaser(item: StatementAdjustment): string {
    if (item.isCreditPurchaser() && item.amount != undefined) {
      let newValue: string = this.formatCurrency(item.amount);
      if (item.isDepositAdjustment()) {
        // deposit adjustment has dependency on sale price adjustment so we need recalculate on update
        newValue = StatementAdjustmentDisplayDepositBuilder.recalculateCreditPurchaserForDeposit(this, item);
      }
      return newValue;
    } else if (item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.NO_ADJUSTMENT) {
      return 'No Adjustment';
    } else if (item.statementOfAdjustmentCreditType == StatementAdjustmentAmountTypes.INFO_ONLY) {
      return 'Info Only';
    } else {
      return '';
    }

  }

  get purchaserLabel(): string {
    return (this.matter && this.matter.statementOfAdjustmentHeading ? this.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo : '');
  }

  get vendorLabel(): string {
    return (this.matter && this.matter.statementOfAdjustmentHeading ? this.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo : '');
  }

  getTaxesLabel(item: MatterTax): string {
    if (!item) {
      return '';
    }
    let vendorWillHavePaid: number;
    if (!item.adjustmentBasedOnPercentageOfTotalTaxes || item.adjustmentBasedOnPercentageOfTotalTaxes == 'NO') {
      vendorWillHavePaid = item.vendorWillHavePaid ? item.vendorWillHavePaid : 0;
    } else {
      vendorWillHavePaid = item.calculateProportionateShareOfVendor() ? item.calculateProportionateShareOfVendor() : 0;
    }
    let vendorShareAmount: number = item.vendorShareAmount ? item.vendorShareAmount : 0;//the amount may not available
    if (vendorWillHavePaid > vendorShareAmount) {
      return 'Credit ' + this.vendorLabel;
    } else if (vendorShareAmount > vendorWillHavePaid) {
      return 'Credit ' + this.purchaserLabel;
    } else {
      return 'No Adjustment';
    }
  }

  isOnTotalConsiderationLabel(): boolean {
    return !!(this.matter.considerationLtt
      && (this.matter.considerationLtt.additionalConsideration1Exists(this.matter.statementOfAdjustments))
      || (this.matter.isMatterProvinceAB && (this.matter.considerationLtt.calculateSalePriceAdjustmentHeadingsCost() != 0)));
  }

  getAdjustmentTile(item: StatementAdjustment, provinceCode: string): string {
    if (item && item.isDepositAdjustment() && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isDepositOneItemizedAdjustment() && this.matter.matterPropertyWithCondo.deposits.length > 1) {
      return 'DEPOSITS';
    } else if (item && item.isHstAdjustment()) {

      if (this.isOnTotalConsiderationLabel()) {
        return item.hstSalePrice.isHstRebate() ? item.hstSalePrice.rateType + ' REBATE ASSIGNED TO ' + this.vendorLabel.toUpperCase() : item.hstSalePrice.rateType + ' ON TOTAL CONSIDERATION';
      } else {
        return item.hstSalePrice.isHstRebate() ? item.hstSalePrice.rateType + ' REBATE ASSIGNED TO ' + this.vendorLabel.toUpperCase()
          : item.hstSalePrice.rateType + ' ON SALE PRICE';
      }
    } else if (item && item.isTotalDuringOccupancyPeriodAdjustment()) {
      return item && item.soAdjExpenseOccupancyPeriod.adjustmentHeading;
    } else if (item && item.isCommonExpenseAdjustment()) {
      return this.getProvinceItemDescription(item, provinceCode, 'matter.soa.commonExpenses');
    } else if (item && item.isTenancyPrepaid()) {
      return this.getProvinceItemDescription(item, provinceCode, 'matter.soa.prePaidRent') +
        ((item.soAdjTenancyPrepaid && item.soAdjTenancyPrepaid.unitOccupied === 'SPECIFY') ? ' - ' + item.soAdjTenancyPrepaid.areaOccupiedDescription.toUpperCase() : '');
    } else if (item && (item.isComponentAdjustment() || item.isOtherFixed())) {
      return item.description;
    } else {
      return item.description ? item.description.toUpperCase() : '';
    }
  }

  getProvinceItemDescription(item: StatementAdjustment, provinceCode: string, fieldKey: string): string {
    return provinceCode ? provinceBasedFieldLabels.get(fieldKey, provinceCode).toUpperCase() : (item.description ? item.description.toUpperCase() : '');
  }

  /**
   * converts date on 'MMMM DD, YYYY' format
   * when useDefaultInvalidDate is set it returns invalidDate='???'
   *
   * @param {string} date
   * @param {boolean} useDefaultInvalidDate
   * @returns {string}
   */
  getDate(date: string, useDefaultInvalidDate?: boolean): string {
    if (date) {
      let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
      if (pattern.test(date)) {
        return moment(date, 'YYYY/MM/DD').format('MMMM DD, YYYY');
      }
    }
    if (useDefaultInvalidDate) {
      return this.invalidDate;
    }
    return '';
  }

  get adjustmentDate(): string {
    return this.matter.getClosingDate();
  }

  getAdjustmentDateYear(currentYear: boolean): string {
    const closingDate = this.matter.getClosingDate();
    return MatterTax.getAdjustmentDateYearRule(currentYear, closingDate);
  }

  // {{item.soaCommonExpense.isExpensePaid ? 'Paid by '+vendorLabel+' for month of ' : 'To be paid by '+purchaserLabel+' for month of '}}{{item.soaCommonExpense.commonExpensePaidLabel(adjustmentDate)}}
  getCommonExpensePaidLabel(item: SoaCommonExpense, isOccupancyFeeSoa: boolean): string {
    return item.getCommonExpensePaidLabel(this.vendorLabel, this.purchaserLabel, item.commonExpensePaidLabel(this.adjustmentDate), isOccupancyFeeSoa);
  }

  get creditVendorPrice(): number {
    return this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment ? this.matter.considerationLtt.creditVendorPrice(this.soaConsiderationTaxes) : 0;
  }

  get netSalePrice(): number {
    return this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment ? this.matter.considerationLtt.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate) : 0;
  }

  get netSalePriceExcludeAdditionalVendorConsid(): number {
    return this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment ? this.matter.considerationLtt.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate, true) : 0;
  }

  get salePriceAdjustment(): SalePriceAdjustment {
    return (this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment);
  }

  get interimOccupancyFeeAdjustment(): StatementAdjustment {

    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isInterimOccupancyFee()).shift();

  }

  get finalOccupancyFeeAdjustments(): StatementAdjustment[] {

    if (!this.matter.statementOfAdjustments) {
      return [] as StatementAdjustment[];
    }
    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isFinalOccupancyFee());

  }

  get interimEarlyPossessionFeeAdjustment(): StatementAdjustment {

    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isInterimEarlyPossessionFee()).shift();

  }

  get tarionAdjustments(): StatementAdjustment[] {

    if (!this.matter.statementOfAdjustments) {
      return [] as StatementAdjustment[];
    }
    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isTarionWarranty());

  }

  get interestOnDepositInterestAdjustments(): StatementAdjustment[] {

    if (!this.matter.statementOfAdjustments) {
      return [] as StatementAdjustment[];
    }
    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isInterestOnDepositInterestCondo());

  }

  get hstAdjustments(): StatementAdjustment[] {

    if (!this.matter.statementOfAdjustments) {
      return [] as StatementAdjustment[];
    }
    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isHstAdjustment());

  }

  get depositAdjustment(): StatementAdjustment {
    if (!this.matter.statementOfAdjustments) {
      return undefined;
    }

    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments.filter(soAdj => soAdj.isDepositAdjustment())
      .shift();
  }

  get hstSalePriceAdjustment(): StatementAdjustment {
    return this.matter.statementOfAdjustments &&
      this.matter.statementOfAdjustments
      .filter(soAdj => {
        if (soAdj.hstSalePrice == null) {
          return false;
        }

        return soAdj.hstSalePrice.isHstSalePrice();

      }).shift();

  }

  get isSalePriceFormatINCLUSIVE(): boolean {
    if (this.salePriceAdjustment) {
      return this.salePriceAdjustment.isSalePriceFormatINCLUSIVE();
    }
    return false;
  }

  get anyDeposits(): boolean {
    return this.matter &&
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.depositNameAvailableDeposits &&
      this.matter.matterPropertyWithCondo.depositNameAvailableDeposits.length > 0;
  }

  get anyDepositsPaidOnOccupancy(): boolean {
    return this.matter &&
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.depositsPaidOnOccupancy &&
      this.matter.matterPropertyWithCondo.depositsPaidOnOccupancy.length > 0;
  }

  get doesSalePriceApply(): boolean {
    return this.matter.considerationLtt &&
      this.matter.considerationLtt.salePriceAdjustment &&
      this.matter.considerationLtt.salePriceAdjustment.agreementSalePrice >= 0 &&
      (this.matter.considerationLtt.salePriceAdjustment.isInclusivePrice() ||
        this.matter.considerationLtt.salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate > 0 ||
        this.matter.considerationLtt.salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax > 0);
  }

  get federalHstRate(): number {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstFederalPortion ? this.soaConsiderationTaxes.hstFederalPortion : 0);
  }

  get provinceHstRate(): number {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstProvincialPortion ? this.soaConsiderationTaxes.hstProvincialPortion : 0);
  }

  get hstRate(): number {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstRate ? this.soaConsiderationTaxes.hstRate : 0);
  }

  get isTaxRateGst(): boolean {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.provinceDefaultTaxRate == 'GST');
  }

  get appliedTaxRate(): string {
    return this.isTaxRateGst ? 'GST' : this.soaConsiderationTaxes ? this.soaConsiderationTaxes && this.soaConsiderationTaxes.rateType : '';
  }

  getPayableTo(fromMatter: Matter): string {
    let result: string = '';
    if (fromMatter.statementOfAdjustmentPayable) {
      result += 'Payable to: ';
      if (fromMatter.statementOfAdjustmentPayable.payableTo == null && this.documentProfileCache) {
        fromMatter.statementOfAdjustmentPayable.payableTo = fromMatter.getStatementOfAdjustmentPayableTo
        (this.matterDocumentProfile, this.documentProfileCache.cachedDefaultDocumentProfile);
      }
      result += fromMatter.statementOfAdjustmentPayable.payableTo ? fromMatter.statementOfAdjustmentPayable.payableTo : '';
    }
    return result;
  }

  /**
   * Creating a new item line
   * @param {string} title => appears in the first column
   * @param {string} value => appears in the second column*
   * @param {boolean} pushValueToRight => set this to 'true' if for some reason [[value]] needs to move to the third column
   * @param {string} noteReferenceMark => little superscript note that references text on footers (usually custom e.g. Tarion)
   * @param (StatementOfAdjustmentDisplayItemLineType) hintType => some adjustments need a pop-up with more details on the item line
   * @param (StatementOfAdjustmentDisplayItemLineType) isBoldRequired => some adjustments need specific first item as bold
   * @returns {StatementOfAdjustmentDisplayItemLine}
   */
  getNewItemLine(title: string, value: string, pushValueToRight?: boolean, codeKey?: string, noteReferenceMark?: string, hintType?: StatementOfAdjustmentDisplayItemLineType, isBoldRequired: boolean = false) {
    let newItemLine: StatementOfAdjustmentDisplayItemLine = new StatementOfAdjustmentDisplayItemLine();

    newItemLine.key = codeKey == undefined ? `` : codeKey;
    newItemLine.title = title;
    newItemLine.value = value;
    newItemLine.isBoldRequired = isBoldRequired;

    if (pushValueToRight != undefined && pushValueToRight) {
      newItemLine.pushValueToRight = pushValueToRight;
    } else {
      newItemLine.pushValueToRight = false;
    }
    if (noteReferenceMark) {
      newItemLine.noteReferenceMark = noteReferenceMark;
    }
    if (hintType) {
      newItemLine.hintType = hintType;
    } else {
      newItemLine.hintType = 'None';
    }
    return newItemLine;
  }

  toJSON(): string {
    return this.matter && this.matter.isProjectSale ?
      JSON.stringify({
        items: this.statementAdjustmentDisplayItems.filter(item => item.soaItem && item.soaItem.applyToAdjustmentRecord || !item.soaItem.sourceProjectAdjustmentId),
        balance: this.statementOfAdjustmentBalanceDisplayItems
      }) :
      JSON.stringify({items: this.statementAdjustmentDisplayItems, balance: this.statementOfAdjustmentBalanceDisplayItems});
  }

  addDecimalsToPercent(n) {

    // check if n already has decimals using modulus %
    if (n % 1 == 0) {
      return n + '.00';
    } else {
      return n;
    }
  }

  /**
   * Removes trailing .00
   * input 12.34 returns "12.34"
   * input 12.00 returns "12"
   */
  removeTrailingZeros(input: number): string {
    const fractionDigits = (input % 1 === 0) ? 0 : 2;
    return this.decimalPipe.transform(input, `1.${ fractionDigits }-${ fractionDigits }`);
  }

  get paysForDateOfClosing(): string {
    return this.matter.paysForDateOfClosing;
  }

  //this method is calculating netSalePrice depends on province and tax type
  calculateNetSalePriceBasedOnTaxType(hstSalePrice: HstSalePrice): string {
    let isGstTaxRate = this.isTaxRateGst;
    let isNBorNSProvince = this.matter.isMatterProvinceNBorNS;
    let isABProvince = this.matter.isMatterProvinceAB;

    if (!isGstTaxRate && !isNBorNSProvince) {
      if (this.matter && !isABProvince) {
        return this.getDecimal(this.netSalePriceExcludeAdditionalVendorConsid);
      }
    } else if (!isGstTaxRate && isNBorNSProvince) {
      //need to calculate federal and provincial tax portion and return it
      let federalTaxPortion: number = this.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(this.federalHstRate, this.federalHstRate, this.provinceHstRate);
      let provinceTaxPortion: number = this.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(this.provinceHstRate, this.federalHstRate, this.provinceHstRate);
      let totalTaxPortion = Number(federalTaxPortion) + Number(provinceTaxPortion);
      return this.getDecimal(totalTaxPortion);
    } else {
      let salePrice: number = this.matter.getSalePriceBasedOnAdjustment(this.federalHstRate, this.provinceHstRate, hstSalePrice.isRateTypePST());
      return this.getDecimal(salePrice);
    }
    return undefined;
  }

  isProvinceABWithProject(): boolean {
    return this.matter.isProvinceABWithProject;
  }
}
