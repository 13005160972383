import {api} from '../../common/api';

export const tcolApi = {
  tcolUser: `${ api }/integrations/tcol/accounts/{accountId}/users/currentUser`,
  validateTcolUser: `${ api }/integrations/tcol/accounts/{accountId}/validateUser`,
  forgotPasswordUrl: `${ api }/integrations/tcol/accounts/{accountId}/forgotUrl`,
  deleteTcolUser: `${ api }/integrations/tcol/accounts/{accountId}/users/{userId}/tcolUser/delete`,
  getTcolWebUrl: `${ api }/integrations/tcol/accounts/{accountId}/users/{userId}/webUrl`,
  getTcolSignupUrl: `${ api }/integrations/tcol/accounts/{accountId}/signupUrl`,
  getTcolByPidAndProvince: `${ api }/integrations/tcol/{accountId}/pid/{pid}/{provinceCode}`,
  saveTaxCertificate: `${ api }/integrations/tcol/{accountId}/tax-certificate/{matterId}`,
};

