<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" >
        <div class="modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10" >
                    <h1>Interest Rates</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
            <div class="clear"></div>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="fields-group">
                    <form class="form-horizontal" >
                        <div class="form-group" *ngFor="let row of context.rates;let j=index">
                            <div class="col-lg-1">
                                <label class="control-label text-right">{{j+1}}</label>
                            </div>
                            <div class="col-lg-4">
                                <label class="control-label text-left">{{row.from}}</label>
                            </div>
                            <div class="col-lg-1">
                                <label *ngIf="row.to" class="control-label text-left">to</label>
                            </div>
                            <div class="col-lg-4">
                                <label *ngIf="row.to" class="control-label text-left">{{row.to}}</label>
                            </div>
                            <div class="col-lg-1" >
                                <label class="control-label text-right">{{row.rate/100 | percent:'1.2'}}</label>
                            </div>
                            <div class="col-lg-1">
                                <label class="control-label"></label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons text-center">
                <button type="button"
                        class="dp-btn" (click)="close()">
                    <span>Close</span>
                </button>
            </div>
        </div>
    </form>
</div>

