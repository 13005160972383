import {constValues} from './const-values';
import {AddressDropdowns} from '../../shared-main/address-Form/drop-downs';
import {MatterUndertakingStatusDropdown} from './matter-undertaking';
import {
  AffidavitTobeSignedByOptionsValue,
  booleanYesNoDropDowns,
  DigitalSignaturePlatform,
  DigitalSignaturePlatformLabel,
  MatterActionRequiredFilters,
  MatterClosingStatus,
  MatterOverviewStatusTypesValue,
  MatterStatus,
  ReferredByTypeValues,
  TaskStatusFilter,
  templateCodes
} from '../../shared-main/constants';
import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {WaterSupplyType} from '../statement-adjustment/water-soa';
import {SelectItem} from 'primeng/api';
import {TotalEffectiveTypes} from '../statement-adjustment/modals/total-during-occupancy-period/so-adj-expense-occupancy-period';
import {TitleDeedNameTypes} from './title-deed';
import {CommissionPaidToType, OpportunityMatterSourceValue, OpportunityMatterStatusValue} from './matter-specific-type';

export const DEPOSIT_FORMAT_ONE_ITEM_ADJ = 'ONE_ITEM_ADJ';
export const DEPOSIT_FORMAT_SEPARATE_PER_DEPOSIT = 'SEPARATE_ADJ_PER_DEPOSIT';
export const ROLL_NUMBER_ASSIGNED = 'ASSIGNED';
export const ROLL_NUMBER_NOT_ASSIGNED = 'NOT_ASSIGNED';
export const ROLL_NUMBER_MULTIPLE = 'MULTIPLE';

export interface CommissionPaidToOption {
  label: string;
  value: CommissionPaidToType;
}

export interface SeparateEntriesForCommissionPaidToTrustLedgerOption {
  label: string;
  value: DpBooleanValue;
}

export const dropDowns = {

  // drop down on Property / Teranet for Purchase Type
  purchaseType: [
    {label: '', value: 'QUESTION'},
    {label: 'a resale home', value: 'RESALE_HOME'},
    {label: 'a new home from a builder', value: 'NEW_BUILDER_HOME'},
    {label: 'vacant land', value: 'VACANT_LAND'},
    {label: 'other', value: 'OTHER'},
    {label: 'commercial property', value: 'COMMERCIAL_PROPERTY'},
    {label: 'farm land', value: 'FARM_LAND'},
    {label: 'mobile home', value: 'MOBILE_HOME'}
  ],

  // drop down on Property / Teranet for interestEstate
  interestEstate: [
    {label: 'Fee Simple', value: 'FEE_SIMPLE'},
    {label: 'Leasehold', value: 'LEASEHOLD'},
    {label: 'Easement', value: 'EASEMENT'},
    {label: 'Fee Simple with New Easement', value: 'FEE_SIMPLE_NEW_EASEMENT'},
    {label: 'Fee Simple - Surface Rights', value: 'FEE_SIMPLE_SURFACE_RIGHTS'},
    {label: 'Fee Simple - Mining Rights', value: 'FEE_SIMPLE_MINING_RIGHTS'},
    {label: 'Life Estate Remainder in Fee', value: 'LIFE_ESTATE_REMAINDER'},
    {label: 'Other (requires pre-approval)', value: 'OTHER'}
  ],

  // drop down on Property / Teranet for propertyType
  titleInsurancePropertyTypeStewart: [
    {label: 'Not Yet Determined', value: 'QUESTION'},
    {label: 'Residential Single Family Dwelling', value: 'RESIDENTIAL_SINGLE_FAMILY'},
    {label: 'Residential Condo', value: 'RESIDENTIAL_CONDO'},
    {label: 'Residential Condo Townhouse', value: 'RESIDENTIAL_CONDO_TOWNHOUSE'},
    {label: 'Residential Multi-Unit (2 to 6 units)', value: 'RESIDENTIAL_MULTI_UNIT'},
    {label: 'Residential Vacant Land', value: 'RESIDENTIAL_VACANT_LAND'},
    {label: 'Residential Vacant Land Condo', value: 'RESIDENTIAL_VACANT_LAND_CONDO'},
    {label: 'Residential Rural', value: 'RESIDENTIAL_RURAL'},
    {label: 'Residential Common Element Condo', value: 'RESIDENTIAL_COMMON_CONDO'},
    {label: 'Residential Bareland Condo', value: 'RESIDENTIAL_BARELAND_CONDO'}
  ],

  // drop down on Property / Teranet for Chicago Title
  titleInsurancePropertyTypeChicago: [
    {label: 'Not Yet Determined', value: 'QUESTION'},
    {label: 'Single Family Dwelling', value: 'RESIDENTIAL_SINGLE_FAMILY'},
    {label: 'Residential Condominium', value: 'RESIDENTIAL_CONDO'},
    {label: 'Multi-Family Units', value: 'RESIDENTIAL_MULTI_UNIT'},
    {label: 'Vacant Land', value: 'RESIDENTIAL_VACANT_LAND'},
    {label: 'Farm', value: 'FARM'},
    {label: 'Mobile Home', value: 'MOBILE_HOME'},
    {label: 'Rooming/Student Housing', value: 'ROOMING_STUDENT_HOUSING'}
  ],

  // drop down on Property / Teranet for FCT
  titleInsurancePropertyTypeFCT: [
    {label: 'Not Yet Determined', value: 'QUESTION'},
    {label: 'Single Family Dwelling', value: 'RESIDENTIAL_SINGLE_FAMILY'},
    {label: 'Condominium/Strata', value: 'RESIDENTIAL_CONDO'},
    {label: 'Multi-Family Units', value: 'RESIDENTIAL_MULTI_UNIT'},
    {label: 'Vacant Land', value: 'RESIDENTIAL_VACANT_LAND'},
    {label: 'Rooming House', value: 'ROOMING_STUDENT_HOUSING'},
    {label: 'Mobile Home', value: 'MOBILE_HOME'},
    {label: 'Live Work Units', value: 'LIVE_WORK_UNITS'}
  ],
  titleInsurancePropertyTypeTitlePlus: [
    {label: 'Not Yet Determined', value: 'QUESTION'},
    {label: 'Condominium', value: 'RESIDENTIAL_CONDO'},
    {label: 'Single Family', value: 'RESIDENTIAL_SINGLE_FAMILY'},
    {label: 'Multi Unit', value: 'RESIDENTIAL_MULTI_UNIT'},
    {label: 'Vacant Land', value: 'RESIDENTIAL_VACANT_LAND'},
    {label: 'Vacant Land Condo', value: 'RESIDENTIAL_VACANT_LAND_CONDO'},
    {label: 'Farm', value: 'FARM'},
    {label: 'Leasehold', value: 'LEASEHOLD'},
    {label: 'Commercial', value: 'COMMERCIAL'}
  ],
  propertyTypeNB: [
    {label: 'Residential', value: 'RESIDENTIAL'},
    {label: 'Commercial', value: 'COMMERCIAL'},
    {label: 'Commercial & Residential', value: 'COMMERCIAL_RESIDENTIAL'}
  ],

  // drop down on Property / Teranet for registryLt
  registryLt: {
    default: [
      {label: '', value: constValues.registryLt.defaultType},
      {label: 'Registry', value: constValues.registryLt.registry},
      {label: 'Land Titles', value: constValues.registryLt.landTitles}
    ],
    manual: [
      {label: '', value: constValues.registryLt.defaultType},
      {label: 'Registry', value: constValues.registryLt.registry},
      {label: 'Land Titles', value: constValues.registryLt.landTitles}
    ],
    electronic: [
      {label: 'Land Titles', value: constValues.registryLt.landTitles}
    ],
    defaultLandTitle: [
      {label: 'Registry', value: constValues.registryLt.registry},
      {label: 'Land Titles', value: constValues.registryLt.landTitles}
    ]
  },

  farmlandEvidenceOptions: [
    {label: 'not farm land', value: 'NOT_FARM_LAND'},
    {label: 'farm land to be mortgaged', value: 'FARM_LAND_TO_BE_MORTGAGED'},
    {label: 'other', value: 'OTHER'}
  ],

  // input values on Property / Teranet for partLot
  partLotOptions: [
    {label: '', value: ''},
    {label: 'Lot', value: 'lot'},
    {label: 'Part Lot', value: 'partLot'},
    {label: 'Block', value: 'block'},
    {label: 'Part Block', value: 'partBlock'}
  ],

  planOptions: {
    blankOrRegistry: [
      {label: '', value: ''},
      {label: 'Plan', value: 'plan'},
      {label: 'Concession', value: 'concession'}
    ],
    torontoAndLandTitles: [
      {label: 'Plan 80M-', value: 'plan80M'},
      {label: 'Plan 66M-', value: 'plan66M'}
    ]
  },

  beingPartOptions: [
    {label: '', value: ''},
    {label: 'being Part', value: 'beingPart'},
    {label: 'Being PART', value: 'BeingPART'}
  ],

  onPlanOptions: {
    inToronto: [
      {label: 'on Plan 80R-', value: 'onPlan80R'},
      {label: 'on Reference Plan 80R-', value: 'onReferencePlan80R'},
      {label: 'on Plan 66R-', value: 'onPlan66R'},
      {label: 'on Reference Plan 66R-', value: 'onReferencePlan66R-'}
    ]
  },

  rollNumberType: [
    {label: '', value: ROLL_NUMBER_ASSIGNED},
    {label: 'NOT ASSIGNED', value: ROLL_NUMBER_NOT_ASSIGNED},
    {label: 'MULTIPLE', value: ROLL_NUMBER_MULTIPLE}
  ],

  yesNoOnly: booleanYesNoDropDowns.YesNoOnly,

  // drop down on Property / Teranet for yesNo
  yesNo: [ //TODO: This needs fixing, it's mixing two ways of dealing with the enums (label based for N/y, enum based for YES & NO)
    {label: 'N/y', value: 'N/y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  taxPaidUnderTIPPOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  YesNo_DefaultYes: booleanYesNoDropDowns.YesNo_DefaultYes,

  NoYes_DefaultNo: booleanYesNoDropDowns.NoYes_DefaultNo,

  hasPropertyMigratedOptions: booleanYesNoDropDowns.NoYes_DefaultNo,

  oldDefaultNoBoolean: [ //TODO: this needs to be moved to the standard enum based value list
    {label: 'N/y', value: 'N/y'},
    {label: 'Yes', value: 'Yes'},
    {label: 'No', value: 'No'}
  ],

  matterType: [
    {label: 'Select Matter Type', value: ''},
    {label: 'Purchase', value: 'purchase'},
    {label: 'Sale', value: 'sale'},
    {label: 'Mortgage', value: 'mortgage'},
    {label: 'Custom Matter', value: 'customMatter'}
  ],

  registryLtCustom: [
    {label: '', value: constValues.registryLt.defaultType},
    {label: 'Registry', value: constValues.registryLt.registry}
  ],

  yesNoCustom1: [
    {label: 'N/y', value: 'N/y'},
    {label: 'Yes', value: 'YES'},
    {label: 'Yes - Title Insurer', value: 'Yes - Title Insurer'},
    {label: 'Yes - with Other Title Insurer', value: 'Yes - with Other Title Insurer'},
    {label: 'No', value: 'No'}
  ],

  yesNoMatterFile: [
    {label: 'N/y', value: 'DEFAULT_ACTIVE'},
    {label: 'Yes', value: 'INACTIVE'},
    {label: 'No', value: 'ACTIVE'}
  ],

  // Dropdown for Registration Method in matter opening
  registrationMethodsType: [
    {label: 'Electronic', value: constValues.registrationMethodsType.electronic},
    {label: 'Manual', value: constValues.registrationMethodsType.manual}
  ],

  statusMatter: [
    {label: 'All', value: 'all'},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Inactive', value: 'INACTIVE'}
  ],

  addClientGenderType: [
    {label: '', value: 'QUESTION'},
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'},
    {label: 'Corporation', value: 'CORPORATION'},
    {label: 'Male (Under power of attorney)', value: 'MALEPOA'},
    {label: 'Female (Under power of attorney)', value: 'FEMALEPOA'},
    {label: 'Estate', value: 'ESTATE'},
    {label: 'Other Entity', value: 'OTHERENTITY'}
  ],

  flaNameOfConsentedSpouseGenderType: [
    {label: '', value: 'QUESTION'},
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'}
  ],

  gender_estate_code: [
    {label: '', value: 'QUESTION'},
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'}

  ],

  estate_Status: [
    {label: '', value: 'QUESTION'},
    {label: 'Testate', value: 'TESTATE'},
    {label: 'Intestate', value: 'INTESTATE'}

  ],

  addClientGenderTypeLabel: {
    'QUESTION': '',
    MALE: 'Male',
    FEMALE: 'Female',
    CORPORATION: 'Corporation',
    MALEPOA: 'Male (Under power of attorney)',
    FEMALEPOA: 'Female (Under power of attorney)',
    ESTATE: 'Estate',
    OTHERENTITY: 'Other Entity'
  },

  canadianResident: [
    {label: 'Y/n', value: 'Y_n'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  directDepositOptions: [
    {label: 'N/y', value: 'N_y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  corporateSealFlag: [
    {label: '', value: '???'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  offerorsGenderType: [
    {label: '', value: 'QUESTION'},
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'},
    {label: 'Other', value: 'OTHERENTITY'}
  ],

  ReferredBy: [
    {label: 'Manual Entry/Customizable Picklist', value: ReferredByTypeValues.MANUAL_ENTRY},
    {label: 'Client', value: ReferredByTypeValues.CLIENT},
    {label: 'Real Estate Broker', value: ReferredByTypeValues.REAL_ESTATE_BROKER},
    {label: 'Real Estate Agent', value: ReferredByTypeValues.REAL_ESTATE_AGENT},
    {label: 'Surveyor', value: ReferredByTypeValues.SURVEYOR},
    {label: 'Condominium Corporation', value: ReferredByTypeValues.CONDO_CORP},
    {label: 'Insurance Broker', value: ReferredByTypeValues.INSURANCE_BROKER},
    {label: 'Management Company', value: ReferredByTypeValues.MANAGE_COMP},
    {label: 'Mortgage Broker', value: ReferredByTypeValues.MORTGAGE_BROKER},
    {label: 'Mortgagee', value: ReferredByTypeValues.MORTGAGEE},
    // { label: 'Other Party', value: 'OTHER_PARTY' },
    //{label : 'Other Lender', value : 'PRIVATE_LENDER'},
    {label: 'Solicitor', value: ReferredByTypeValues.SOLICITOR}
  ],

  ReferredByForBC: [
    {label: 'Manual Entry/Customizable Picklist', value: ReferredByTypeValues.MANUAL_ENTRY},
    {label: 'Client', value: ReferredByTypeValues.CLIENT},
    {label: 'Real Estate Broker', value: ReferredByTypeValues.REAL_ESTATE_BROKER},
    {label: 'Real Estate Agent', value: ReferredByTypeValues.REAL_ESTATE_AGENT},
    {label: 'Surveyor', value: ReferredByTypeValues.SURVEYOR},
    {label: 'Strata Corporation', value: ReferredByTypeValues.CONDO_CORP},
    {label: 'Insurance Broker', value: ReferredByTypeValues.INSURANCE_BROKER},
    {label: 'Management Company', value: ReferredByTypeValues.MANAGE_COMP},
    {label: 'Mortgage Broker', value: ReferredByTypeValues.MORTGAGE_BROKER},
    {label: 'Lender', value: ReferredByTypeValues.MORTGAGEE},
    {label: 'Lawyer/Notary', value: ReferredByTypeValues.SOLICITOR}
  ],

  IDTypes: [
    {label: 'Driver\'s Licence - Canadian', value: 'DRIVERS_LICENCE_CANADIAN'},
    {label: 'Passport - Canadian', value: 'PASSPORT_CANADIAN'},
    {label: 'Birth Certificate', value: 'BIRTH_CERTIFICATE'},
    {label: 'Ontario Photo Card', value: 'ONTARIO_PHOTO_CARD'},
    {label: 'Alberta Photo ID Card', value: 'ALBERTA_PHOTO_ID_CARD'},
    {label: 'Manitoba Enhanced ID Card', value: 'MANITOBA_ENHANCED_ID_CARD'},
    {label: 'New Brunswick Photo ID Card', value: 'NEW_BRUNSWICK_PHOTO_ID_CARD'},
    {label: 'Canadian Prov/Territory Health Card', value: 'CANADIAN_PROV_TERRITORY_HEALTH_CARD'},
    {label: 'Canadian Citizenship Card', value: 'CANADIAN_CITIZENSHIP_CARD'},
    {label: 'Certificate of Canadian Citizenship', value: 'CERTIFICATE_OF_CANADIAN_CITIZENSHIP'},
    {label: 'Canadian Permanent Resident Card', value: 'CANADIAN_PERMANENT_RESIDENT_CARD'},
    {label: 'Certificate of Naturalization', value: 'CERTIFICATE_OF_NATURALIZATION'},
    {label: 'Canadian Social Insurance Card', value: 'CANADIAN_SOCIAL_INSURANCE_CARD'},
    {label: 'NEXUS Card', value: 'NEXUS_USA'},
    {label: 'Canadian Secure Certificate of Indian Status', value: 'CANADIAN_SECURE_CERTIFICATE_OF_INDIAN_STATUS'},
    {label: 'Canadian Forces Identification Card', value: 'CANADIAN_FORCES_IDENTIFICATION_CARD'},
    {label: 'Canadian Firearms Licence', value: 'CANADIAN_FIREARMS_LICENCE'},
    {label: 'Driver\'s License - USA', value: 'DRIVERS_LICENSE_USA'},
    {label: 'Driver\'s Licence - International', value: 'DRIVERS_LICENSE_INTERNATIONAL'},
    {label: 'Passport - USA', value: 'PASSPORT_USA'},
    {label: 'Passport - International', value: 'PASSPORT_INTERNATIONAL'},
    {label: 'British Columbia Photo ID', value: 'BRITISH_COLUMBIA_PHOTO_ID'}
  ],

  condoUnitType: [
    {label: '', value: ''},
    {label: 'Dwelling Unit', value: 'DWELLING_UNIT'},
    {label: 'Parking Space', value: 'PARKING_SPACE'},
    {label: 'Locker', value: 'LOCKER'},
    {label: 'Parking Unit', value: 'PARKING_UNIT'},
    {label: 'Storage Unit', value: 'STORAGE_UNIT'},
    {label: 'Parking + Storage', value: 'PARKING_PLUS_STORAGE'}
  ],

  // drop down on Property / Teranet for yesNo
  isCondoOptions: [
    {label: 'N/y', value: 'N_y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  isNRDTTaxOptions: [
    {label: 'N/y', value: 'N_y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  isVendorToSupplyRPROptions: [
    {label: 'N/y', value: 'N_y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  isExceptionTypeOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  isExceptionDescriptionOptions: [
    {label: '', value: ''},
    {label: 'Excepting thereout all mines and minerals', value: 'EXCEPTING_THEREOUT_ALL_MINES_AND_MINERALS'},
    {
      label: 'Excepting thereout all mines and minerals and the right to work the same',
      value: 'EXCEPTING_THEREOUT_ALL_MINES_AND_MINERALS_AND_THE_RIGHT_TO_WORK_THE_SAME'
    },
    {label: 'Excepting thereout all coal', value: 'EXCEPTING_THEREOUT_ALL_COAL'},
    {label: 'Excepting thereout all', value: 'EXCEPTING_THEREOUT_ALL'}
  ],
  isLandTitleOfficeLocation: [
    {label: 'North', value: 'North'},
    {label: 'South', value: 'South'}
  ],
  isLandTitleOffice: [
    {label: 'Land Titles Office for the North Alberta Land Registration District', value: 'NORTH_AB'},
    {label: 'Land Titles Office for the South Alberta Land Registration District', value: 'SOUTH_AB'}
  ],

  // drop down on Property / Teranet for Is there POTL
  isParcelOfTiedLandOptions: [
    {label: 'N/y', value: 'N_y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  f9HelpNameOfCondominiumPlan: [
    {
      jurisdictionKey: 'OTTAWA',
      f9Options: [ 'Ottawa-Carleton Common Elements Condominium Plan No. ', 'Ottawa-Carleton Standard Common Elements Condominium Plan No. ' ]
    },
    {
      jurisdictionKey: 'HAMILTON',
      f9Options: [ 'Hamilton-Wentworth Common Elements Condominium Plan No. ', 'Hamilton-Wentworth Standard Common Elements Condominium Plan No. ' ]
    },
    {
      jurisdictionKey: 'TORONTO',
      f9Options: [ 'Metropolitan Toronto Common Elements Condominium Plan No. ', 'Toronto Standard Common Elements Condominium Plan No. ' ]
    },
    {
      jurisdictionKey: '<<DEFAULT>>',
      f9Options: [ 'Common Elements Condominium Plan No. ', 'Standard Common Elements Condominium Plan No. ' ]
    }
  ],

  corporations: [
    {label: ' ', value: 'DEFAULT'},
    {label: 'Ontario Corporation No.', value: 'Ontario Corporation No.'},
    {label: 'Canada Corporation No.', value: 'Canada Corporation No.'},
    {label: 'Federal Business Identification No.', value: 'Federal Business Identification No.'},
    {label: 'Alberta Corporation No.', value: 'Alberta Corporation No.'},
    {label: 'British Columbia Corporation No.', value: 'British Columbia Corporation No.'},
    {label: 'Manitoba Corporation No.', value: 'Manitoba Corporation No.'},
    {label: 'Newfoundland Corporation No.', value: 'Newfoundland Corporation No.'},
    {label: 'New Brunswick Corporation No.', value: 'New Brunswick Corporation No.'},
    {label: 'Northwest Territories Corporation No.', value: 'Northwest Territories Corporation No.'},
    {label: 'Nova Scotia Corporation No.', value: 'Nova Scotia Corporation No.'},
    {label: 'Nunavut Corporation No.', value: 'Nunavut Corporation No.'},
    {label: 'Prince Edward Island Corporation No.', value: 'Prince Edward Island Corporation No.'},
    {label: 'Quebec Corporation No.', value: 'Quebec Corporation No.'},
    {label: 'Saskatchewan Corporation No.', value: 'Saskatchewan Corporation No.'},
    {label: 'Yukon Corporation No.', value: 'Yukon Corporation No.'},
    {label: 'Other', value: 'Other'}
  ],

  // drop down on Consideration/LTT
  prepareLTTOptionsOnlySolictor: [

    {label: 'Solicitor', value: 'SOLICITOR'}
  ],
  prepareLTTOptions: [
    {label: 'Client(s)', value: 'CLIENT'},
    {label: 'Solicitor', value: 'SOLICITOR'}
  ],

  prepareLTTSpouseOptions: [
    {label: 'Solicitor', value: 'SOLICITOR'},
    {label: 'Both Spouses', value: 'BOTH_SPOUSES'},
    {label: 'Spouse 1 Only', value: 'SPOUSE1'},
    {label: 'Spouse 2 Only', value: 'SPOUSE2'}
  ],

  prepareLTTRateOptions: [
    {label: '??? (response not required if consideration does not exceed $???)', value: 'QUESTION'},
    {label: 'RESIDENTIAL (contains either 1 or 2 single family residences)', value: 'RESIDENTIAL'},
    {label: 'COMMERCIAL (contains zero OR more than 2 single family residences)', value: 'COMMERCIAL'},
    {label: 'APPORTION (contains either 1 or 2 SFR\'s, and a non-residential portion)', value: 'APPORTION'}

  ],

  tableCalculationOptions: [
    {label: 'calculate "f" by totaling "a" through "e"', value: 'CALCULATE_F_TOTALLING_A_THOUGH_E'},
    {label: 'the amount in "a" equals the amount "f"', value: 'AMOUNT_IN_A_EQUALS_AMOUNT_F'},
    {label: 'calculate "a" by subtracting "b" through "e" from "f"', value: 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F'}

  ],

  completionOfTaxInfoOptions: [
    {label: 'Line "a" = Net Sale Price in Sale Price Adjustment (minus VTB if applicable)', value: 'LINE_A_EQUAL_NET_SALE_PRICE'},
    {label: 'Leave tax screen blank; Set Teraview "Amount" to $2.00', value: 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2'},
    {label: 'Complete tax info manually', value: 'COMPLETE_TAX_INFO_MANUALLY'},
    {
      label: 'Leave tax screen blank; Set Teraview "Amount" to Net Sale price of Net Sale Price',
      value: 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE'
    }
  ],

  considerationCityFlag: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  ontarioOptions: [
    {label: 'N/y', value: 'N/y'},
    {label: 'Yes', value: 'Yes'},
    {label: 'No', value: 'No'}
  ],

  residingAtSubjectPropertyOptions: [
    {label: 'Y/n', value: DpBooleanValueTypes.Y_n},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  postClosingAddressCopyOptions: [
    AddressDropdowns.sameAsSubject,
    AddressDropdowns.sameAsPreClosing,
    AddressDropdowns.manuallyEntered
  ],

  vendorWarrantyOptions: [
    {label: '', value: 'QUESTION'},
    {label: 'Unqualified', value: 'UNQUALIFIED'},
    {label: 'Knowledge & Belief', value: 'KNOWLEDGE_BELIEF'},
    {label: 'Limited Time', value: 'LIMITED_TIME'},
    {label: 'Standard Wording', value: 'STANDARD_WORDING'},
    {label: 'None', value: 'NONE'}
  ],

  vendorWarrantyOptionsNS: [
    {label: '', value: 'QUESTION'},
    {label: 'Unqualified', value: 'UNQUALIFIED'},
    {label: 'Knowledge & Belief', value: 'KNOWLEDGE_BELIEF'},
    {label: 'Limited Time', value: 'LIMITED_TIME'},
    {label: 'Long Form', value: 'LONG_FORM'},
    {label: 'None', value: 'NONE'}
  ],

  // netOutHst : [
  //     {label : 'No', value : 'NO'},
  //     {label : 'Yes - factor in HST rebate(s)', value : constValues.netOutHst.YES_FACTOR_IN_HST_REBATE},
  //     {label : 'Yes - divide sale price by 1.13', value : constValues.netOutHst.YES_DIVIDE_SALE_PRICE}
  // ],

  salePriceAdjustment: [
    {value: 'CREDIT_VENDOR_NET_SALE_PRICE', label: 'Credit Vendor = Net Sale Price'},
    {value: 'CREDIT_VENDOR_INCLUSIVE', label: 'Credit Vendor = HST - Inclusive price in Agreement'}
  ],

  multipleDepositOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  adjustmentBasedOnPercentageTotalTaxesOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  complianceWriteToOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  complianceStatusOptions: [
    {label: '', value: 'QUESTION'},
    {label: 'OK', value: 'OK'},
    {label: 'Not OK', value: 'NOT_OK'}
  ],

  adjustmentFormatOptions: [
    {label: 'One itemized adjustment', value: DEPOSIT_FORMAT_ONE_ITEM_ADJ},
    {label: 'Separate Adjustments for each Deposit', value: DEPOSIT_FORMAT_SEPARATE_PER_DEPOSIT}
  ],

  taxTypes: [
    {label: 'Business Taxes', value: 'BUSINESS_TAXES'},
    {label: 'Land Taxes', value: 'LAND_TAXES'},
    {label: 'Real Estate Taxes', value: 'REAL_ESTATE_TAXES'},
    {label: 'Realty Taxes', value: 'REALTY_TAXES'}
  ],

  taxTypesAB: [
    {label: 'Business Taxes', value: 'BUSINESS_TAXES'},
    {label: 'Land Taxes', value: 'LAND_TAXES'},
    {label: 'Real Estate Taxes', value: 'REAL_ESTATE_TAXES'},
    {label: 'Realty Taxes', value: 'REALTY_TAXES'},
    {label: 'Parking Unit Taxes', value: 'PARKING_UNIT_TAXES'}
  ],

  taxTypesBC: [
    {label: 'Property Taxes', value: 'REALTY_TAXES'}
  ],

  taxAdjustmentTypes: [
    {label: 'Final', value: 'FINAL'},
    {label: 'Interim', value: 'INTERIM'},
    {label: 'Previous', value: 'PREVIOUS'},
    {label: 'Estimated Final', value: 'ESTIMATED_FINAL'},
    {label: 'Not Assessed', value: 'NOT_ASSESSED'}

  ],
  taxAdjustmentTypesPaidByVendor: [
    {label: 'Final', value: 'FINAL'},
    {label: 'Interim', value: 'INTERIM'},
    {label: 'Previous', value: 'PREVIOUS'},
    {label: 'Estimated Final', value: 'ESTIMATED_FINAL'}
  ],

  reportingLetterToPurchaser: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Purchaser Only', value: 'PURCHASER_ONLY'},
    {label: 'Purchaser & Mortgagee', value: 'PURCHASER_MORTGAGEE'}
  ],

  reportingLetterToMortgagor: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Mortgagor only', value: 'MORTGAGOR_ONLY'},
    {label: 'Mortgagor and Mortgagee', value: 'MORTGAGOR_MORTGAGEE'}

  ],

  pageNumberLabel: [
    {labelStarting: 'Page No.', labelEnding: 'of the Standard Charge Terms', value: 'PAGE_NO_OF_THE_STANDARD_CHARGE_TERMS'},
    {labelStarting: 'Page No.', labelEnding: 'of the Mortgage Document', value: 'PAGE_NO_OF_THE_MORTGAGE_DOCUMENT'},
    {
      labelStarting: 'Page No.',
      labelEnding: 'of Cost of Borrowing or Disclosure Statement',
      value: 'PAGE_NO_OF_COST_OF_BORROWING_OR_DISCLOSURE_STATEMENT'
    },
    {labelStarting: 'Section No.', labelEnding: 'of the Standard Charge Terms', value: 'SECTION_NO_OF_STANDARD_CHARGE_TERMS'},
    {labelStarting: 'Section No.', labelEnding: 'of the Mortgage Document', value: 'SECTION_NO_OF_MORTGAGE_DOCUMENT'},
    {labelStarting: 'Box No.', labelEnding: 'of the Mortgage Document', value: 'BOX_NO_OF_MORTGAGE_DOCUMENT'}

  ],

  // input values on Vendors-solicitor for Dear
  dearOptions: [
    {label: '', value: ''},
    {label: 'Mr.', value: 'Mr.'},
    {label: 'Ms.', value: 'Ms.'},
    {label: 'Sir', value: 'Sir'},
    {label: 'Madam', value: 'Madam'}
  ],

  f9HelpPropertyJurisdiction: [
    {label: 'OTTAWA', value: 'F9 = Ottawa-Carleton'},
    {label: 'HAMILTON', value: 'F9 = Hamilton-Wentworth'},
    {label: 'TORONTO', value: 'Press F9 to toggle Toronto or Metropolitan Toronto'}
  ],

  insurancePolicy: [
    {value: 'ENCLOSED_REPORT', label: 'Enclosed with report'},
    {value: 'TO_FOLLOW', label: 'To follow'}

  ],

  taxBills: [
    {value: '??????', label: ' '},
    {value: 'INTERIM', label: 'Interim'},
    {value: 'FINAL', label: 'Final'},
    {value: 'YEAR_FINAL', label: 'YYYY Final'}

  ],

  taxBillsNB: [
    {value: '??????', label: ' '},
    {value: 'FINAL', label: 'YYYY Final'},
    {value: 'YEAR_FINAL', label: 'ZZZZ Final'}

  ],

  futurePayments: [
    {value: 'QUESTION', label: ' '},
    {value: 'REPORTING_LETTER', label: 'Provide details in reporting letter'},
    {value: 'REMIT_PAYMENT', label: 'Instruct purchaser to remit payments as per encl. tax bill'},
    {value: 'INSTRUCT_PURCHASER', label: 'Instruct purchaser to call tax department for details'},
    {value: 'DO_NOT_INSTRUCT_PURCHASER', label: 'Do not instruct purchaser in this regard'}

  ],

  hstSalePriceFormats: [
    {value: 'SHOW_AS_ONE_ADJUSTMENT', label: 'Show as one adjustment'},
    {
      value: 'SHOW_FEDERAL_ONTARIO_PORTION_AS_SEPARATE_ADJUSTMENT',
      label: 'Show Federal portion and Provincial portion as separate adjustments'
    }
  ],
  waterSupplyType: [
    {value: WaterSupplyType.METERED, label: 'Metered'},
    {value: WaterSupplyType.FLAT_RATE, label: 'Flat Rate'},
    {value: WaterSupplyType.WELL_WATER, label: 'Well Water'}
  ],
  waterBillingFrequency: [
    {value: 'ANNUALLY', label: 'Annually'},
    {value: 'SEMI_ANNUALLY', label: 'Semi-Annually'},
    {value: 'QUARTERLY', label: 'Quarterly'},
    {value: 'MONTHLY', label: 'Monthly'},
    {value: 'SEMI_MONTHLY', label: 'Semi-Monthly'},
    {value: 'BI_WEEKLY', label: 'Bi-Weekly'},
    {value: 'WEEKLY', label: 'Weekly'},
    {value: 'DAILY', label: 'Daily'},
    {value: 'EVERY_FOUR_MONTHS', label: 'Every 4 Months'}
  ],
  // waterAdjustType       : [
  //     {value : 'HST', label : 'HST'},
  //     {value : 'GST', label : 'GST'}
  // ],
  waterYesNoBoolean: booleanYesNoDropDowns.BooleanYesNo,

  commonExpensePaidOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  commonExpenseAdjustedOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  commonExpenseFormatOfAdjustment: [
    {label: 'Itemize if more than one unit', value: 'ITEMIZE_IF_MORE_THAN_ONE_UNIT'},
    {label: 'Do not itemize by unit', value: 'DO_NOT_ITEMIZE_BY_UNIT'}
  ],

  soAdjExpenseOccupancyPeriodAdjustedOptions: booleanYesNoDropDowns.YesNoOnly,

  soAdjExpenseOccupancyPeriodChargePurchaserOptions: booleanYesNoDropDowns.BooleanYesNo,

  useAmountFromPropertyTabOptions: booleanYesNoDropDowns.BooleanYesNo,

  effectiveFromTypeOptions: [
    {value: TotalEffectiveTypes.NO_START_DATE, label: 'no start date'},
    {value: TotalEffectiveTypes.ENTER_OWN_DATE, label: 'enter own date'}
  ],

  effectiveTillTypeOptions: [
    {value: TotalEffectiveTypes.NO_END_DATE, label: 'no end date'},
    {value: TotalEffectiveTypes.ENTER_OWN_DATE, label: 'enter own date'}
  ],

  dataSourceOptionsILA: [
    {label: 'Manual Entry', value: 'MANUAL_ENTRY'},
    {label: 'Solicitor Records', value: 'SOLICITOR_RECORDS'}
  ],
  heatTypes: [
    {value: 'GAS', label: 'Gas'},
    {value: 'ELECTRIC', label: 'Electric'},
    {value: 'FUEL_OIL', label: 'Fuel Oil'},
    {value: 'PROPANE', label: 'Propane'}
  ],
  heatTypesNS: [
    {value: 'GAS', label: 'Gas'},
    {value: 'OIL', label: 'Oil'},
    {value: 'PROPANE', label: 'Propane'},
    {value: 'ELECTRIC', label: 'Electric'},
    {value: 'OTHER', label: 'Other'}
  ],
  heatUnitMeasurementTypes: [
    {value: 'LITRES', label: 'Litres'},
    {value: 'GALLONS', label: 'Gallons'}
  ],
  heatAdjustFor: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  dataSourceOptionsNameAddress: [
    {label: 'Manual Entry', value: 'MANUAL_ENTRY'},
    {label: 'Jurisdiction Records', value: 'JURISDICTION_RECORDS'},
    {label: 'Other Contacts', value: 'OTHER_CONTACTS'}
  ],
  calculateUsingTypeOptions: [
    {label: 'Credit to amount', value: 'CREDIT_AMOUNT'},
    {label: 'Tax out Price', value: 'TAX_OUT_PRICE'}
  ],

  matterListSearchViews: [
    {label: 'My Recent', value: 'recent'},
    {label: 'All Matters', value: 'all'}
  ],
  outputTypeOptions: [
    {label: 'PDF Document', value: 'PDF'},
    {label: 'Word Document', value: 'WORD'},
    {label: 'Both Word & PDF', value: 'WORD_AND_PDF'}
  ],

  eRegTransfer: [
    {label: 'Transfer', value: 'TRANSFER'},
    {label: 'Transfer By Partnership', value: 'TRANSFER_BY_PARTNERSHIP'},
    {label: 'Transfer By Personal Representative', value: 'TRANSFER_BY_PERSONAL'},
    {label: 'Transfer By Religious Organization', value: 'TRANSFER_BY_RELIGIOUS'},
    {label: 'Transfer By Trustee In Bankruptcy', value: 'TRANSFER_BY_TRUSTEE'},
    {label: 'Transfer By Easement', value: 'TRANSFER_BY_EASEMENT'},
    {label: 'Transfer Release & Abandonment', value: 'TRANSFER_BY_RELEASE'},
    {label: 'Transfer Power of Sale', value: 'TRANSFER_POWER_SALE'}
  ],

  eRegActingFor: [
    {label: 'both', value: 'BOTH'},
    {label: 'parties from', value: 'PARTIES_FROM'},
    {label: 'parties to', value: 'PARTIES_TO'}
  ],

  eRegMortgage: [
    {label: 'Charge/Mortgage', value: 'CHARGE'},
    {label: 'Charge By Partnership', value: 'CHARGE_BY_PARTNERSHIP'},
    {label: 'Charge By Religious Organization', value: 'CHARGE_BY_PERSONAL'},
    {label: 'Notice of Charge of Lease', value: 'NOTICE_OF_CHARGE'}
  ],

  eRegDischarge: [
    {label: 'Discharge of a Charge', value: 'DISCHARGE_OF_CHARGE'},
    {label: 'Application Delete Housing Development Lien', value: 'APPLICATION_DELETE'},
    {label: 'Discharge of an Interest', value: 'DISCHARGE_OF_INTEREST'},
    {label: 'Discharge of Condominium Lien', value: 'DISCHARGE_OF_CONDO'},
    {label: 'Discharge of Construction Lien', value: 'DISCHARGE_OF_CONSTRUCTION'}
  ],

  interestCalculationTypes: [
    {label: 'Commencing on adjustment date', value: 'COMMENCING_ON_ADJUSTMENT_DATE'},
    {label: 'Ending on adjustment date', value: 'ENDING_ON_ADJUSTMENT_DATE'},
    {label: 'Enter own dates', value: 'ENTER_OWN_DATES'}
  ],

  interestCalculationProjectTypes: [
    {label: 'Commencing on adjustment date', value: 'COMMENCING_ON_ADJUSTMENT_DATE'},
    {label: 'Ending on adjustment date', value: 'ENDING_ON_ADJUSTMENT_DATE'},
    {label: 'Enter own dates', value: 'ENTER_OWN_DATES'},
    {label: 'Ending on interim closing date', value: 'ENDING_ON_INTERIM_CLOSING_DATE'},
    {label: 'From interim closing to adjustment date', value: 'FORM_INTERIM_CLOSING_TO_ADJUSTMENT_DATE'},
    {label: 'Commencing on interim closing date', value: 'COMMENCING_ON_INTERIM_CLOSING_DATE'}
  ],

  calculationPeriod: [
    {label: 'per diem interest at', value: 'PER_DIEM'},
    {label: 'interest calculated at', value: 'CALCULATED'}
  ],

  deductMoniesOptions: [
    {label: '???', value: 'QUESTION'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  SeparateEntriesForCommissionPaidToTrustLedgerOption: <SeparateEntriesForCommissionPaidToTrustLedgerOption[]>[
    {label: 'Y/n', value: DpBooleanValueTypes.Y_n},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  CommissionPaidToOptions: <CommissionPaidToOption[]>[
    {label: 'Vendor\'s broker only', value: constValues.commissionPaidTo.VENDOR_BROKER_ONLY},
    {label: 'Both Vendor\'s and Purchaser\'s brokers', value: constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER}

  ],

  CommissionPaidToOptionsPS: <CommissionPaidToOption[]>[
    {label: '', value: null},
    {label: 'Vendor\'s broker only', value: constValues.commissionPaidTo.VENDOR_BROKER_ONLY},
    {label: 'Both Vendor\'s and Purchaser\'s brokers', value: constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER}

  ],

  matterTypesPM: [
    {label: 'Purchase', value: 'PURCHASE'},
    {label: 'Mortgage', value: 'MORTGAGE'}
  ],

  matterTypesPSM: [
    {label: 'Purchase', value: 'PURCHASE'},
    {label: 'Sale', value: 'SALE'},
    {label: 'Mortgage', value: 'MORTGAGE'}
  ],

  MortgageeInterestNotedOnPolicyOptions: [
    {label: '', value: 'QUESTION'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  ProvideDetailedExplanationOfSoaOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  MmsDealExistsOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  YesNoNyOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  matterTopicStatusType: [
    {label: 'Select Status', value: 'QUESTION'},
    {label: 'Complete', value: 'COMPLETE'},
    {label: 'Incomplete', value: 'INCOMPLETE'},
    {label: 'Attention', value: 'ATTENTION'}
  ],

  matterTopicClosingStatusType: [
    {label: 'Select Closing Status', value: 'QUESTION'},
    {label: 'Pending', value: MatterClosingStatus.PENDING},
    {label: 'Escrow', value: MatterClosingStatus.ESCROW},
    {label: 'In-Funds', value: MatterClosingStatus.IN_FUNDS},
    {label: 'Post Closing', value: MatterClosingStatus.POST_CLOSING},
    {label: 'Occupancy', value: MatterClosingStatus.OCCUPANCY}
  ],

  matterActionRequiredFilters: [
    {label: 'Matters with one or more outstanding undertakings', value: MatterActionRequiredFilters.OUTSTANDING_UNDERTAKINGS},
    {label: 'Matters with one or more unresolved requisitions', value: MatterActionRequiredFilters.UNRESOLVED_REQUISITIONS},
    {label: 'Matters with one or more topics with an incomplete status', value: MatterActionRequiredFilters.INCOMPLETE_MATTER_TOPICS},
    {label: 'Matters with one or more topics with an attention status', value: MatterActionRequiredFilters.ATTENTION_MATTER_TOPICS},
    {
      label: 'Matters with one or more mortgages with an incomplete Preliminary Report',
      value: MatterActionRequiredFilters.INCOMPLETE_PRELIMINARY_REPORT_MORTGAGES
    },
    {
      label: 'Matters with one or more mortgages with an incomplete Final Report',
      value: MatterActionRequiredFilters.INCOMPLETE_FINAL_REPORT_MORTGAGES
    },
    {label: 'Matter with one or more Holdbacks that are not released', value: MatterActionRequiredFilters.NOT_RELEASED_MATTER_HOLDBACKS}
    // {label : 'Matters with one or more outstanding tasks', value : MatterActionRequiredFilters.OUTSTANDING_TASKS},
    // {label : 'Matters with one or more overdue tasks', value : MatterActionRequiredFilters.OVERDUE_TASKS}
  ],

  taskStatusFilters: [
    {label: 'Incomplete with no Due Date', value: TaskStatusFilter.incompleteNoDueDate},
    {label: 'Incomplete', value: TaskStatusFilter.incomplete},
    {label: 'Overdue', value: TaskStatusFilter.overdue},
    {label: 'Complete', value: TaskStatusFilter.completed}
  ],

  matterStatuses: [
    {label: 'Active', value: MatterStatus.ACTIVE},
    {label: 'Inactive', value: MatterStatus.INACTIVE},
    {label: 'Flagged', value: MatterOverviewStatusTypesValue.FLAGGED},
    {label: 'Overdue', value: MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE},
    {label: 'Ready', value: MatterOverviewStatusTypesValue.MATTER_READY}
  ],

  lenderChangeStatus: [
    {label: '', value: 'BLANK'},
    {label: 'ACCEPT', value: 'ACCEPT'},
    {label: 'REJECT', value: 'REJECT'}
  ],

  purchaserFinancing: [
    {label: 'New Mortgage', value: 'NEW_MORTGAGE'},
    {label: 'Cash', value: 'CASH'},
    {label: 'Assume Mortgage', value: 'ASSUME_MORTGAGE'}
  ],

  protocolClosing: [
    {label: 'Undetermined', value: 'UNDETERMINED'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  yesNoBlankOptions: [
    {label: '', value: 'BLANK'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  titleDetails: [
    {label: 'Auto Populated', value: 'AUTO_POPULATED'},
    {label: 'Manually Entered', value: 'MANUALLY_ENTERED'}

  ],
  unitPlanExceptionsTypes: [
    {label: '', value: 'BLANK'},
    {value: 'EXCEPTING_MINES_AND_MINERALS', label: 'Excepting thereout all mines and minerals'},
    {
      value: 'EXCEPTING_MINES_MINERALS_AND_RIGHT_TO_WORK',
      label: 'Excepting thereout all mines and minerals and the right to work the same'
    },
    {value: 'EXCEPTING_COAL', label: 'Excepting thereout all coal'},
    {value: 'EXCEPTING_ALL', label: 'Excepting thereout all'}

  ],
  teraviewLegalDescOverriddenTypes: [
    {value: 'PROVIDE_ENTIRE_LEGAL_DESC', label: 'Enter entire legal description below (ignore Tab "D" data)'},
    {value: 'PROVIDE_EASEMENT_TO_BE_APPENDED', label: 'Easement below to be appended to lot/plan info from Tab "D"'},
    {value: 'EASEMENT_APPENDED_TO_PROVIDED_INFO', label: 'Easement from Tab "D" to be appended to lot/plan info below'},
    {value: 'APPLY_LEGAL_DESC_AND_EASEMENT', label: 'Apply legal description and easement from Tab "D"'},
    {value: 'INSERT_PROVIDED_EASEMENT_IN_BETWEEN', label: 'Easement below to be appended in between Tab "D" lot/plan and estatement'}

  ],
  condominiumTypeOptions: [
    {label: 'Conventional Condominium', value: 'CONVENTIONAL_CONDOMINIUM_PLAN'},
    {label: 'Bare Land Condominium', value: 'BARE_LAND_PLAN'}
  ],
  occupancyOptions: [
    {label: 'Clients', value: 'CLIENTS'},
    {label: 'Vacant', value: 'VACANT'},
    {label: 'Tenants to vacate', value: 'TENANTS_TO_VACATE'},
    {label: 'Tenants to remain', value: 'TENANTS_TO_REMAIN'}
  ],
  applicationFiledByOptions: [
    {label: 'Builder', value: 'BUILDER'},
    {label: 'Claimant', value: 'CLAIMANT'}
  ],
  depositTypeFilterOptions: [
    {label: 'Select Deposits', value: 'NO_DEPOSITS'},
    {label: 'All deposits', value: 'ALL_DEPOSITS'},
    {label: 'All deposits excluding Deposits on Occupancy', value: 'ALL_DEPOSITS_EXCLUDING_OCCUPANCY'},
    {label: 'All deposits for which a Form 4 has been sent', value: 'ALL_DEPOSITS_FORM_4_SENT'},
    {label: 'All deposits for which a Form 4 has not been sent', value: 'ALL_DEPOSITS_FORM_4_NOT_SENT'},
    {label: 'Only Deposits on Occupancy', value: 'ONLY_DEPOSITS_ON_OCCUPANCY'}
  ],
  depositByDateOptions: [
    {label: 'Filter by date of deposit', value: 'FILTER_BY_DATE'},
    {label: 'Filter by date of Form 4', value: 'FILTER_BY_FORM_4_DATE'}
  ],
  depositPrintOptionsFromManager: [
    {label: 'selected deposit', value: 'SELECTED_DEPOSIT'}
  ],
  depositPrintOptionsFromSOA: [
    {label: 'selected deposit', value: 'SELECTED_DEPOSIT'},
    {label: 'aggregate of all deposits (except deposit on occupancy)', value: 'AGGREGATE_DEPOSITS_EXCEPT_OCCUPANCY'},
    {label: 'Each deposit for which a Form 4 not yet sent (on separate pages)', value: 'FORM_NOT_SENT_ON_SEPARATE_PAGE'},
    {label: 'Each deposit for which a Form 4 not yet sent (on 1 pages)', value: 'FORM_NOT_SENT_ON_SINGLE_PAGE'},
    {label: 'All deposits whether or not Form 4 already sent (on 1 pages)', value: 'ALL_DEPOSITS_ON_SINGLE_PAGE'},
    {
      label: 'All deposits (except deposit on occupancy) whether or not Form 4 already sent (on 1 pages)',
      value: 'ALL_DEPOSITS_ON_SINGLE_PAGE_EXCEPT_OCCUPANCY'
    }
  ],
  interestDepositCalculationProjectTypes: [
    {
      label: 'Interim closing as specified in Occupancy Date',
      value: 'INTERIM_CLOSING_AS_SPECIFIED_IN_OCCUPANCY_DATE'
    },
    {
      label: 'Interim closing as specified in Interim Calculated as at Date',
      value: 'INTERIM_CLOSING_AS_SPECIFIED_IN_INTERIM_CALCULATED_AS_AT_DATE'
    },
    {
      label: 'Final closing as specified in Closing Date (e.g. if no interim closing)',
      value: 'FINAL_CLOSING_AS_SPECIFIED_IN_CLOSING_DATE'
    },
    {
      label: 'Final closing as specified in Final Calculated as at Date (e.g. if no interim closing)',
      value: 'FINAL_CLOSING_AS_SPECIFIED_IN_FINAL_CALCULATED_AS_AT_DATE'
    }

  ],

  interestDepositPrintingTypes: [
    {label: 'Itemized Calculation', value: 'ITEMIZED_CALCULATION'},
    {label: 'Final Amount Only', value: 'FINAL_AMOUNT_ONLY'}
  ],
  depositAmountToBeTypes: [
    {label: 'enter manually in this adjustment', value: 'ENTER_MANUALLY'},
    {label: 'the total of deposits', value: 'TOTAL_DEPOSITS'}
  ],
  interestCalculationPeriodTypes: [
    {label: 'From interim closing to adjustment date', value: 'INTERIM_CLOSING_TO_ADJ_DATE'},
    {label: 'Commencing on adjustment date', value: 'COMMENCING_ADJ_DATE'},
    {label: 'Ending on adjustment date', value: 'ENDING_ADJ_DATE'},
    {label: 'Enter own dates', value: 'ENTER_DATES'},
    {label: 'Ending on interim closing date', value: 'ENDING_INTERIM_CLOSING_DATE'},
    {label: 'Commencing on interim closing date', value: 'COMMENCING_INTERIM_CLOSING_DATE'}
  ],
  form4ChargesTaxTypeOptions: [
    {label: 'GST', value: 'GST'},
    {label: 'HST', value: 'HST'}
  ],
  form4ChargesAdjustFor: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  creditsToOptions: [
    {label: 'Vendor', value: 'VENDOR'},
    {label: 'Purchaser', value: 'PURCHASER'}
  ],
  itemizedCreditAdjustmentTypeOptions: [
    {label: 'Credit to {PARTY}', value: 'CREDIT_TO_PARTY'},
    {label: 'No Adjustment', value: 'NO_ADJUSTMENT'},
    {label: 'Silent', value: 'SILENT'}
  ],
  itemizedCreditAdjustmentDivideTotalOptions: {
    'ON': [
      {label: 'No', value: 'NO'},
      {label: 'by 1.0448 - with "Less HST Component" text and HST amount', value: '10448_LESS_HST_COMPONENT'},
      {label: 'by 1.0448 - show calculation only', value: '10448_SHOW_CALCULATION_ONLY'},
      {label: 'by 1.13 - with "Less HST Component" text and HST amount', value: '113_LESS_HST_COMPONENT'},
      {label: 'by 1.13 - show calculation only', value: '113_SHOW_CALCULATION_ONLY'}
    ],
    'AB': [
      {label: 'No', value: 'NO'},
      {label: 'by 1.0320 - with "Less GST Component" text and GST amount', value: '10320_LESS_GST_COMPONENT'},
      {label: 'by 1.0320 - show calculation only', value: '10320_SHOW_CALCULATION_ONLY'},
      {label: 'by 1.05 - with "Less GST Component" text and GST amount', value: '105_LESS_GST_COMPONENT'},
      {label: 'by 1.05 - show calculation only', value: '105_SHOW_CALCULATION_ONLY'}
    ]
  },
  componentAdjustmentOptions: [
    {label: 'Vendor', value: 'VENDOR'},
    {label: 'Purchaser', value: 'PURCHASER'},
    {label: 'No Adjustment', value: 'NO_ADJUSTMENT'},
    {label: 'Silent', value: 'SILENT'}
  ],

  otherHoldbackStatusOptions: [
    {label: 'Not Released', value: 'NOT_RELEASED'},
    {label: 'Partial Release', value: 'PARTIAL_RELEASE'},
    {label: 'Released', value: 'RELEASED'}
  ],

  executionOptions: [
    {label: '', value: null},
    {label: 'Known to Witness', value: 'KNOWN_TO_WITNESS'},
    {label: 'Identities proven', value: 'IDENTITIES_PROVEN'}
  ],

  titleDeedNameTypeOptions: [
    {label: 'Same name as above', value: TitleDeedNameTypes.SAME_AS_CLIENT},
    {label: 'Manually Enter', value: TitleDeedNameTypes.MANUAL}
  ],

  interestToBenefitParcelOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  interestToBurdenParcelOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  subjectToCovenantsAndConditionsOptions: [
    {label: 'N/y', value: DpBooleanValueTypes.N_y},
    {label: 'Yes', value: DpBooleanValueTypes.YES},
    {label: 'No', value: DpBooleanValueTypes.NO}
  ],

  matterStatusTypeOptions: [
    {label: 'Flagged', value: MatterOverviewStatusTypesValue.FLAGGED},
    {label: 'Inactive', value: MatterOverviewStatusTypesValue.MATTER_INACTIVE},
    {label: 'Closed', value: MatterOverviewStatusTypesValue.MATTER_CLOSED},
    {label: 'Overdue', value: MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE},
    {label: 'In Progress', value: MatterOverviewStatusTypesValue.MATTER_IN_PROGRESS}
  ],

  digitalSigningPlatformOptions: [
    {label: '', value: 'BLANK'},
    {label: DigitalSignaturePlatformLabel.SYNGRAFII, value: DigitalSignaturePlatform.SYNGRAFII},
    {label: DigitalSignaturePlatformLabel.DOCU_SIGN, value: DigitalSignaturePlatform.DOCU_SIGN}
  ],

  opportunityStatusOptions: [
    {label: 'Open/Qualifying', value: OpportunityMatterStatusValue.qualifying},
    {label: 'Quoted', value: OpportunityMatterStatusValue.quoted},
    {label: 'Closed Converted', value: OpportunityMatterStatusValue.closedConverted},
    {label: 'Closed Inactive', value: OpportunityMatterStatusValue.closedInactive},
    {label: 'Closed Duplicate', value: OpportunityMatterStatusValue.closedDuplicate}
  ],

  opportunitySourceOptions: [
    {label: '', value: OpportunityMatterSourceValue.blank},
    {label: 'Request for Quote', value: OpportunityMatterSourceValue.requestForQuote},
    {label: 'General Inquiry', value: OpportunityMatterSourceValue.generalInquiry},
    {label: 'Referral', value: OpportunityMatterSourceValue.referral},
    {label: 'internally Identified', value: OpportunityMatterSourceValue.internallyIdentified},
    {label: 'Conveyancing Instructions', value: OpportunityMatterSourceValue.conveyancingInstructions}
  ],

  defaultLandingPageSelection: [ //TODO: This needs fixing, it's mixing two ways of dealing with the enums (label based for N/y, enum based for YES & NO)
    {label: 'Day At A Glance', value: 'DAYATGLANCE'},
    {label: 'Matter', value: 'MATTER'},
    {label: 'Events', value: 'EVENTS'}
  ]

};

export const AffidavitTobeSignedByOptions: SelectItem[] = <SelectItem[]>(
  [ {
    label: 'Client',
    value: AffidavitTobeSignedByOptionsValue.CLIENT
  }, {
    label: 'Solicitor',
    value: AffidavitTobeSignedByOptionsValue.SOLICITOR
  } ]
);

export const matterUndertakingStatusDropdowns: MatterUndertakingStatusDropdown[] = <MatterUndertakingStatusDropdown[]>(
  [ {
    label: 'Fulfilled',
    value: 'FULFILLED'
  }, {
    label: 'Outstanding',
    value: 'OUTSTANDING'
  } ]
);
export const matterUndertakingStatusDropdownsNB: MatterUndertakingStatusDropdown[] = <MatterUndertakingStatusDropdown[]>(
  [ {
    label: 'Approved',
    value: 'APPROVED'
  }, {
    label: 'Not Approved',
    value: 'NOT_APPROVED'
  } ]
);
export const matterUndertakingStatusDropdownsForMassUpdate: MatterUndertakingStatusDropdown[] = <MatterUndertakingStatusDropdown[]>(
  [ {
    label: '',
    value: ''
  },
    {
      label: 'Fulfilled',
      value: 'FULFILLED'
    }, {
    label: 'Outstanding',
    value: 'OUTSTANDING'
  } ]
);

export const getCalculationMethodOptions: any[] = <any[]>(
  [
    {label: 'Manual Entry', value: 'MANUAL_ENTRY'},
    {label: 'SALE PRICE, DEPOSIT - DATA ENTERED DIRECTLY BY USER', value: 'MANUAL_AMOUNTS', subOptions: templateCodes.manualAmountCodes},
    {label: 'SALE PRICE, DEPOSIT - DERIVED AMOUNTS', value: 'DERIVED_AMOUNTS', subOptions: templateCodes.derivedAmountCodes},
    {label: 'INTERIM OCCUPANCY FEE ADJUSTMENT', value: 'INTERIM_OCCUPANCY_ADJ', subOptions: templateCodes.interimOccupanyAdjCodes},
    {label: 'OTHER AMOUNTS', value: 'OTHER_AMOUNTS', subOptions: templateCodes.otherAmountCodes}
  ]
);

export const TitleDeedNameTypeOptions: SelectItem[] = <SelectItem[]>(
  [
    {label: 'Same name as above', value: TitleDeedNameTypes.SAME_AS_CLIENT},
    {label: 'Manually Enter', value: TitleDeedNameTypes.MANUAL}
  ]
);

export const RelationshipOptions: { [ gender: string ]: string[] } = {
  'MALE': [ 'Brother',
    'Brother-in-law',
    'Father',
    'Father-in-law',
    'Friend',
    'Grandson',
    'Son-in-law',
    'Step Father',
    'Uncle' ],
  'FEMALE': [ 'Aunt',
    'Daughter-in-law',
    'Friend',
    'Granddaughter',
    'Mother',
    'Mother-in-law',
    'Sister',
    'Sister-in-law',
    'Step Mother' ]
};
