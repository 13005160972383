import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'dpDateFormatPipe'
})

export class DateFormatPipe implements PipeTransform {
  // TODO: replace by AngualrJS's built-in date pipe when AngularJS updated
  transform(value: any, args: any[]): any {

    // if date value is undefined return
    if (!value) {
      return;
    }

    // create new date and set date based on time-stamp value
    let newDate: Date = new Date(value);
    // newDate.setMilliseconds(value);
    // let stringDate: string = newDate.toString();
    let days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
    let months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

    let hh = newDate.getHours();
    let ampm = 'AM';
    let h = hh;
    if (h >= 12) {
      h = hh - 12;
      ampm = 'PM';
    }
    if (h == 0) {
      h = 12;
    }
    let day = '';
    if (args && args.indexOf('noDay') == -1) {
      day = days[ newDate.getDay() ] + ' ';
    }
    if (args && args.indexOf('noTime') > -1) {
      return `${ day }${ months[ newDate.getMonth() ] } ${ newDate.getDate() }, ${ newDate.getFullYear() }`;
    } else {
      return `${ day }${ months[ newDate.getMonth() ] } ${ newDate.getDate() } ${ newDate.getFullYear() } ${ _.padStart('' + h, 2, '0') }:${ _.padStart('' + newDate.getMinutes(), 2, '0') } ${ ampm }`;
    }

    //  let newdate = DateFormatter.format(new Date(value), 'en-US', 'medium' );
  }
}
