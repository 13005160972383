<!-- modal -->
<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>{{teranetImportDataModallabel}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body form-horizontal" *ngIf="matterProperty && teranetImportDataTC &&  teranetImportDataSP && parcelRegister">
        <ng-container *ngIf="context && !context.isWizard">
        <div class="col-lg-12" style="border-bottom: 2px solid #cccccc">
            <div class="col-lg-6">
                <div class="form-group main-level">
                    <label class="control-label"
                           [dp-province-field-label]="'teranet.import.data.modal.title.label'">
                        Property Information in Teranet Parcel Register
                    </label>
                </div>
            </div>
            <div class="col-lg-1 text-center">
                <dp-checkbox fieldId="allField" fieldClass="top-8">
                    <input type="checkbox"
                           [(ngModel)]="allFields"
                           (change)="updateAllFields(allFields)"
                           id="allField"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-5">
                <div class="form-group main-level">
                    <label class="control-label"
                           [dp-province-field-label]="'teranet.import.data.modal.existing.property.label'">
                        Subject Property Data from 'Property' tab
                    </label>
                </div>
            </div>
        </div>
        <div class="col-lg-12 form-group padding-top-10">
            <div class="col-lg-12 form-group"
                 *ngIf="isParcelRegisterLegalDescriptionVisible">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label"
                           [dp-province-field-label]="'teranet.import.data.modal.description.label'">
                        Parcel Register Legal Description
                    </label>
                </div>
                <div class="col-lg-4">
                    <textarea
                            [ngModel]="parcelRegister.propertyDescription"
                            rows="5"
                            [dp-read-only]="true"
                            id="propertyDesc"
                            name="propertyDesc"
                            class="form-control"></textarea>
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Jurisdiction</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="jurisdictionTC"
                           *ngIf="teranetImportDataTC.jurisdiction"
                           [ngModel]="teranetImportDataTC.jurisdiction.jurisdictionName"
                           [dp-read-only]="true"
                           name="jurisdictionTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldJur" fieldClass="top-8">
                        <input type="checkbox" *ngIf="teranetImportDataTC.jurisdiction && ((matterProperty.jurisdiction && matterProperty.jurisdiction.jurisdictionName !=  teranetImportDataTC.jurisdiction.jurisdictionName)  ||  !matterProperty.jurisdiction)"
                               [(ngModel)]="fieldJur"
                               [checked]="allFields"
                               (change)="updateJurisdictionPT(fieldJur)"
                               id="fieldJur"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">

                    <div *ngIf="jurisdictionReadOnly">
                        <input type="text" id="autocomplete-jurisdiction"
                               class="form-control"
                               [value]="selectedJurisdiction.jurisdictionName"
                               dp-read-only="true"
                               autocomplete="off"
                        />
                        <span class="trash-icon-tc">
                                        <i class="fa fa-trash"
                                           (click)="deleteJurisdiction()"
                                           aria-hidden="true"
                                           [title]="'Delete Jurisdiction'">
                                        </i>
                                    </span>
                    </div>

                    <div class="fullLength-autocomplete" *ngIf="!jurisdictionReadOnly">
                        <p-autoComplete id="autocomplete-jurisdiction"
                                        #jurisdictionOmnibar
                                        name="jurisdiction"
                                        placeholder="Search by Name"
                                        [(ngModel)]="selectedJurisdiction"
                                        [class.autocomplete-searching]="jurisdictionsLoading"
                                        [suggestions]="jurisdictions"
                                        (completeMethod)="searchJurisdiction($event)"
                                        field="jurisdictionName"
                                        [minLength]="0"
                                        [delay]="1000"
                                        maxlength="75"
                                        scrollHeight="150px"
                                        (onSelect)="dataSelectedJurisdiction()"
                                        (focusout)="onBlurJurisdiction($event)"
                                        class="autocomplete-search-icon"
                                        (keyup)="checkIfJurisdictionEmpty(selectedJurisdiction)">

                            <ng-template let-jurisdiction pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="jurisdiction">
                                            <span class="omnibarLi100 col-lg-4"
                                                  *ngIf="jurisdictionAutoCompleteEmptyFlag == true">
                                            {{jurisdiction.jurisdictionName}}
                                            </span>
                                    <span class="omnibarLi40 col-lg-4"
                                          *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                            {{jurisdiction.jurisdictionName}}
                                            </span>
                                    <span class="omnibarLi40 col-lg-4"
                                          *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                            {{jurisdiction?.city?.name}}
                                            </span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label"
                           [dp-province-field-label]="'teranet.import.data.modal.property.address.label'">
                        Street Address
                    </label>
                </div>
                <div class="col-lg-4" *dpShowByProvince="'matter.property.spin.importData.singleAddress'">
                    <input type="text"
                           id="streetAddressTC"
                           *ngIf="teranetImportDataTC.address"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.address"
                           name="streetAddressTC"
                           class="form-control"/>

                </div>
                <div class="col-lg-4" *dpShowByProvince="'matter.property.spin.importData.mutipleAddress'">
                    <select class="form-control"
                            *ngIf="teranetImportDataTC.streetAddressOptions && (teranetImportDataTC.streetAddressOptions.length > 0)"
                            id="streetAddress"
                            name="streetAddress"
                            [(ngModel)]="teranetImportDataTC.address"
                            (ngModelChange)="onSelectedStreetAddress()">
                        <option *ngFor="let streetAddress of teranetImportDataTC.streetAddressOptions" [ngValue]="streetAddress.value">
                            {{streetAddress.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldSA" fieldClass="top-8" *ngIf="isAddressCheckboxVisible">
                        <input type="checkbox"
                               [(ngModel)]="fieldAddress"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldAddress,'address')"
                               id="fieldSA"
                               name="fieldSA"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"

                           id="streetAddressPT"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataSPAddress"
                           name="streetAddressPT"
                           class="form-control"/>
                </div>
            </div>

            <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label"><label class="control-label" dp-province-field-label="matter.property.isThisCondo">Is this a condo?</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="isCondoTC"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.isCondo ? 'Yes' : 'No'"
                           name="isCondoTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1" *dpShowByProvince="'matter.import.data.modal.label.hidden'">
                </div>
                <div class="col-lg-1 text-center padding-left-15" *dpShowByProvince="'matter.import.data.modal.condo'">
                    <dp-checkbox fieldId="isCondo" fieldClass="top-8"
                                 *ngIf="teranetImportDataTC.isCondo != matterProperty.isPropertyCondominium()">
                        <input type="checkbox"
                               [(ngModel)]="isCondo"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(isCondo,'isCondo');"
                               id="isCondo"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataSP.isCondo ? 'Yes' : 'No'"
                           id="isCondoPT"
                           name="isCondoPT"
                           class="form-control"/>
                </div>
            </div>

            <div class="col-lg-12 form-group" *dpShowByProvince="'matter.import.data.modal.pid'">
                <div class="col-lg-2 vertical-align-label"><label class="control-label"
                                                                  dp-province-field-label="matter.STG.PINLabel">PID</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.lincNumber"
                           id="bcLincTC"
                           name="bcLincTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldBCLinc" fieldClass="top-8" *ngIf="isLincNumberValidForImport()">
                        <input type="checkbox"
                               [(ngModel)]="bcLinc"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(bcLinc,'lincNumber');"
                               id="fieldBCLinc"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataSP.lincNumber"
                           id="bcLincSP"
                           name="bcLincSP"
                           class="form-control"/>
                </div>
            </div>

            <div class="col-lg-12 form-group" *dpShowByProvince="'matter.import.data.modal.label.hidden'">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'teranet.import.data.modal.interestEstate.label'">Ownership Interest</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="ownershipTC"
                           [dp-read-only]="true"
                           *ngIf="teranetImportDataTC.interestEstate"
                           [ngModel]="teranetImportDataTC.interestEstate"
                           name="ownershipTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldOI" fieldClass="top-8" *ngIf="teranetImportDataTC.interestEstate && teranetImportDataTC.interestEstate.toLocaleLowerCase()
                    != interestEstateLabel">
                        <input type="checkbox"
                               [(ngModel)]="fieldEstate"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldEstate,'interestEstate');"
                               id="fieldOI"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <select class="form-control" id="interestEstatePT"
                            name="interestEstatePT" [(ngModel)]="teranetImportDataSP.interestEstate">
                        <option *ngFor="let interestEstateObj of interestEstates" [value]="interestEstateObj.value">
                            {{interestEstateObj.label}}
                        </option>
                    </select>
                </div>
            </div>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.shortLegalDescription'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo && teranetImportDataTC.isMetesAndBounds">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Short Description</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="shortLegalDescriptionTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.shortLegalDescription"
                               [ngModel]="teranetImportDataTC.shortLegalDescription"
                               name="shortLegalDescriptionTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldShortLegalDescription" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.shortLegalDescription && teranetImportDataTC.shortLegalDescription != matterProperty.shortLegalDescription">
                            <input type="checkbox"
                                   [(ngModel)]="fieldShortLegalDescription"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldShortLegalDescription,'shortLegalDescription');"
                                   id="fieldShortLegalDescription"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="shortLegalDescriptionSP"
                               [dp-read-only]="true"
                               [(ngModel)]="teranetImportDataSP.shortLegalDescription"
                               name="shortLegalDescriptionSP"
                               class="form-control"/>
                    </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.legalDescription'">
                <div class="col-lg-12 form-group" *ngIf="isLegalDescriptionVisible">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Legal Description</label>
                    </div>
                    <div class="col-lg-4">
                        <textarea type="textarea"
                               rows="5"
                               id="fullLegalDescriptionTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.fullLegalDescription"
                               [ngModel]="teranetImportDataTC.fullLegalDescription"
                               name="fullLegalDescriptionTC"
                               class="form-control">
                            </textarea>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldFullLegalDescription" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.fullLegalDescription && teranetImportDataTC.fullLegalDescription != matterProperty.fullLegalDescription">
                            <input type="checkbox"
                                   [(ngModel)]="fieldFullLegalDescription"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldFullLegalDescription,'fullLegalDescription');"
                                   id="fieldFullLegalDescription"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <textarea type="textarea"
                                  rows="5"
                                  [dp-read-only]="true"
                                  id="fullLegalDescriptionSP"
                               [(ngModel)]="teranetImportDataSP.fullLegalDescription"
                               name="fullLegalDescriptionSP"
                               class="form-control">
                        </textarea>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.importData.memo'">
                <div class="col-lg-12 form-group" *ngIf="isMemoVisible">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Legal Description</label>
                    </div>
                    <div class="col-lg-4">
                        <textarea type="textarea"
                                  rows="5"
                                  id="memoTC"
                                  [dp-read-only]="true"
                                  *ngIf="teranetImportDataTC.parcelLegalDescription.memo"
                                  [ngModel]="teranetImportDataTC.parcelLegalDescription.memo"
                                  name="memoTC"
                                  class="form-control">
                            </textarea>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldMemo" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription.memo">
                            <input type="checkbox"
                                   [(ngModel)]="fieldMemo"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldMemo,'parcelLegalDescription', 'memo');"
                                   id="fieldMemo"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <textarea type="textarea"
                                  rows="5"
                                  [dp-read-only]="true"
                                  id="memoSP"
                                  [(ngModel)]="teranetImportDataSP.parcelLegalDescription.memo"
                                  name="memoSP"
                                  class="form-control">
                        </textarea>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.unitLevelPin'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataSP.isCondo">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Unit, Level, PIN</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="unitLevelPlanTC"
                           *ngIf="teranetImportDataTC.unitLevelPlan"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.unitLevelPlan"
                           name="unitLevelPlanTC"
                           class="form-control"/>

                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldunitLevelPlan" fieldClass="top-8" *ngIf="teranetImportDataTC.unitLevelPlan && isUnitLevelPlanAlreadyAdded < 0">
                        <input type="checkbox"
                               [(ngModel)]="fieldunitLevelPlanTC"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldunitLevelPlanTC,'unitLevelPlan');updateUnitLevelPlan(fieldunitLevelPlanTC);"
                               id="fieldunitLevelPlan"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text" id="unitLevelPlanPT" name="unitLevelPlanPT" [(ngModel)]="teranetImportDataSP.unitLevelPlan" class="form-control"
                           [dp-read-only]="true" (keydown.f9)="selectUnitLevelPlan()"/>
                    <button type="button"
                            id="unitLevelPlanBtn"
                            tabIndex="-1"
                            (click)="selectUnitLevelPlan()" class="button-glyph">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.partLot'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo">
                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Part (Lot)</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="partTC"
                               *ngIf="teranetImportDataTC.partLot"
                               name="partTC"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.partLot"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldPart" fieldClass="top-8" *ngIf="teranetImportDataTC.partLot && teranetImportDataTC.partLot != matterProperty.partLot">
                            <input type="checkbox"
                                   [(ngModel)]="fieldPartLot"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldPartLot,'partLot')"
                                   id="fieldPart"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="partPT"
                               [(ngModel)]="teranetImportDataSP.partLot"
                               name="partPT"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.condominiumPlans.condominiumPlanType'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataTC.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Condo Type</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="planCondoTypeTC"
                               [dp-read-only]="true"
                               *ngIf="condominiumPlanTC && condominiumPlanTC.condominiumPlanType"
                               [ngModel]="condominiumPlanType"
                               name="planCondoTypeTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldPlanCondoTypeTC" fieldClass="top-8"
                                     *ngIf="condominiumPlanTC && condominiumPlanTC.condominiumPlanType">
                            <input type="checkbox"
                                   *ngIf="condominiumPlanTC && condominiumPlanTC.condominiumPlanType"
                                   [(ngModel)]="fieldPlanCondoType"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldPlanCondoType,'condominiumPlans', 'condominiumPlanType');"
                                   id="fieldPlanCondoTypeTC"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="condominiumPlanSP">
                        <select class="form-control" id="planCondoTypeSP"
                                name="planCondoTypeSP" [(ngModel)]="teranetImportDataSP.condominiumPlans[0].condominiumPlanType">
                            <option *ngFor="let condoType of condoTypes" [value]="condoType.value">
                                {{condoType.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.spin.importData.condominiumPlans.condominiumPlanNumber'">
                <!--If it is condo, plan should be stored in condominiumExpenses.planNumber-->
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataTC.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Plan</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="condominiumPlanNumberTC"
                               [dp-read-only]="true"
                               *ngIf="condominiumPlanTC && condominiumPlanTC.condominiumPlanNumber"
                               [ngModel]="teranetImportDataTC.condominiumPlans[0].condominiumPlanNumber"
                               name="condominiumPlanNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcCondominiumPlanNumber" fieldClass="top-8"
                                     *ngIf="condominiumPlanTC && condominiumPlanTC.condominiumPlanNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldCondominiumPlanNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldCondominiumPlanNumber,'condominiumPlans', 'condominiumPlanNumber');"
                                   id="fieldTcCondominiumPlanNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="condominiumPlanSP">
                        <input type="text"
                               id="condominiumPlanNumberSP"
                               [(ngModel)]="teranetImportDataSP.condominiumPlans[0].condominiumPlanNumber"
                               name="condominiumPlanNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <!-- Start of Fields for SK province-->
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <!--If it is condo, plan should be stored in condominiumExpenses.planNumber-->
                <div class="col-lg-12 form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Parcel Number</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscParcelNumberTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.parcelNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.parcelNumber"
                               name="condominiumPlanNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldISCParcelNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.parcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldISCParcelNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldISCParcelNumber,'parcelLegalDescription', 'parcelNumber');"
                                   id="fieldISCParcelNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscParcelNumberSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.parcelNumber"
                               name="iscParcelNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataTC.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Unit</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscUnitNumberTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription && teranetImportDataTC.parcelLegalDescription.unitNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.unitNumber"
                               name="iscUnitNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldISCUnitNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription && teranetImportDataTC.parcelLegalDescription.unitNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldISCUnitNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldISCUnitNumber,'parcelLegalDescription', 'unitNumber');"
                                   id="fieldISCUnitNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscUnitNumberSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.unitNumber"
                               name="iscUnitNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataTC.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >No. of Individual Shares</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscSharesTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription && teranetImportDataTC.parcelLegalDescription.parcelTitles
                               && teranetImportDataTC.parcelLegalDescription.parcelTitles[0].numberOfIndividualShares"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.parcelTitles[0].numberOfIndividualShares"
                               name="iscSharesTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCShares" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.parcelTitles[0]?.numberOfIndividualShares">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCShares"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCShares,'parcelLegalDescription', 'parcelTitles.numberOfIndividualShares');"
                                   id="fieldTcISCShares"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelTitleSP">
                        <input type="text"
                               id="iscSharesSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.parcelTitles[0].numberOfIndividualShares"
                               name="iscSharesSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && !parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Lot</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscLotNumberTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.lotNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.lotNumber"
                               name="iscLotNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCLotNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.lotNumber && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCLotNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCLotNumber,'parcelLegalDescription', 'lotNumber');"
                                   id="fieldTcISCLotNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscLotNumberSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.lotNumber"
                               name="iscLotNumberSP" maxlength="10"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && !parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Block</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscBlockNumberTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.blockNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.blockNumber"
                               name="iscBlockNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCBlockNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.blockNumber && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCBlockNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCBlockNumber,'parcelLegalDescription', 'blockNumber');"
                                   id="fieldTcISCBlockNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscBlockNumberSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.blockNumber"
                               name="iscBlockNumberSP"  maxlength="10"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && !parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Plan</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscPlanNumberTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.planNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.planNumber"
                               name="iscPlanNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCPlanNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.planNumber && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCPlanNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCPlanNumber,'parcelLegalDescription', 'planNumber');"
                                   id="fieldTcISCPlanNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscPlanNumberSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.planNumber"
                               name="iscPlanNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >QtrSec</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscQtrSecTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.qtrsec"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.qtrsec"
                               name="iscQtrSecTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCQtrSec" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.qtrsec && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCQtrSec"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCQtrSec,'parcelLegalDescription', 'qtrsec');"
                                   id="fieldTcISCQtrSec"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscQtrSecSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.qtrsec"
                               name="iscQtrSecSP"  maxlength="25"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Sec</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscSecTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.sec"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.sec"
                               name="iscSecTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCSec" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.sec && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCSec"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCSec,'parcelLegalDescription', 'sec');"
                                   id="fieldTcISCSec"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscSecSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.sec"
                               name="iscSecSP"  maxlength="2"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Twp</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscTwpTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.twp"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.twp"
                               name="iscTwpTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCTwp" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.twp && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCTwp"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCTwp,'parcelLegalDescription', 'twp');"
                                   id="fieldTcISCTwp"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscTwpSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.twp"
                               name="iscTwpSP"  maxlength="10"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Rge</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscRngTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.rng"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.rng"
                               name="iscRngTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCRng" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.rng && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCRng"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCRng,'parcelLegalDescription', 'rng');"
                                   id="fieldTcISCRng"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscRngSP"  maxlength="2"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.rng"
                               name="iscRngSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo && parcelRegister?.iscImportData?.farmLand">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Mdn</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscMdnTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.mdn"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.mdn"
                               name="iscMdnTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCMdn" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.mdn && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCMdn"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCMdn,'parcelLegalDescription', 'mdn');"
                                   id="fieldTcISCMdn"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscMdnSP"  maxlength="2"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.mdn"
                               name="iscMdnSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Extension</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscExtensionTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription?.extensionNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.extensionNumber"
                               name="iscExtensionTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCExtension" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.extensionNumber && fieldISCParcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCExtension"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCExtension,'parcelLegalDescription', 'extensionNumber');"
                                   id="fieldTcISCExtension"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <input type="text"
                               id="iscExtensionSP"  maxlength="5"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.extensionNumber"
                               name="iscExtensionSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Mineral</label>
                    </div>
                    <div class="col-lg-4">
                        <dp-checkbox fieldId="iscMineralTC" fieldClass="top-8">
                            <input type="checkbox"
                                   id="iscMineralTC" [disabled]=true
                                   [checked]="teranetImportDataTC.parcelLegalDescription.mineral"
                                   [ngModel]="teranetImportDataTC.parcelLegalDescription.mineral"
                                   name="iscMineralTC"
                            />
                        </dp-checkbox>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCMineral" fieldClass="top-8">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCMineral"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCMineral,'parcelLegalDescription', 'mineral');"
                                   id="fieldTcISCMineral"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelLegalDescriptionSP">
                        <dp-checkbox fieldId="fieldSPISCMineral" fieldClass="top-8">
                            <input type="checkbox" [disabled]=true
                                   id="fieldSPISCMineral"
                                   [(ngModel)]="teranetImportDataSP.parcelLegalDescription.mineral"
                                   name="fieldSPISCMineral"/>
                        </dp-checkbox>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.isc.importData.condominiumPlansFields'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataTC.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Mineral Rights</label>
                    </div>
                    <div class="col-lg-4">

                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <!--<dp-checkbox fieldId="fieldTcISCMineralRights" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription?.mineralRights">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCMineralRights"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCMineralRights,'parcelLegalDescription', 'mineralRights');"
                                   id="fieldTcISCMineralRights"
                            />
                        </dp-checkbox>-->
                    </div>
                    <div class="col-lg-4" *ngIf="teranetImportDataSP.parcelLegalDescription.mineral">
                        <select class="form-control" id="iscMineralRightsSP"
                                name="iscMineralRightsSP" [(ngModel)]="teranetImportDataSP.parcelLegalDescription.mineralRights">
                            <option *ngFor="let mineralRights of mineralRightsOptions" [value]="mineralRights.value">
                                {{mineralRights.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-4"  *ngIf="!teranetImportDataSP.parcelLegalDescription.mineral">
                        <input type="text" readonly = true
                               id="iscMineralRightsReadOnlySP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.mineralRights"
                               name="iscMineralRightsReadOnlySP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>


            <!-- End of fields for SK province -->

            <ng-container *dpShowByProvince="'matter.property.spin.importData.condominiumExpenses.unitNumber'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataSP.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Unit</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="expensesUnitTC"
                               [dp-read-only]="true"
                               *ngIf="condominiumExpenseTC && condominiumExpenseTC.unitNumber"
                               [ngModel]="teranetImportDataTC.condominiumExpenses[0].unitNumber"
                               name="expensesUnitTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCExpensesUnit" fieldClass="top-8"
                                     *ngIf="condominiumExpenseTC && condominiumExpenseTC.unitNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldExpensesUnit"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldExpensesUnit,'condominiumExpenses', 'unitNumber');"
                                   id="fieldTCExpensesUnit"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="condominiumExpenseSP">
                        <input type="text"
                               id="expensesUnitSP"
                               maxlength="5" dp-trimmed uppercased
                               dp-alphanumeric-only
                               [(ngModel)]="teranetImportDataSP.condominiumExpenses[0].unitNumber"
                               name="expensesUnitSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.spin.importData.condominiumExpenses.noUndividedShare'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataSP.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >No of Undivided <br>Shares</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="expensesNoUndividedShareTC"
                               [dp-read-only]="true"
                               *ngIf="condominiumExpenseTC && condominiumExpenseTC.noUndividedShare && teranetImportDataTC.condominiumExpenses"
                               [ngModel]="teranetImportDataTC.condominiumExpenses[0].noUndividedShare"
                               name="expensesNoUndividedShareTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCExpensesNoUndividedShare" fieldClass="top-8"
                                     *ngIf="condominiumExpenseTC && condominiumExpenseTC.noUndividedShare">
                            <input type="checkbox"
                                   [(ngModel)]="fieldExpensesNoUndividedShare"
                                   [checked]="allFields"
                                   maxlength="5" dp-trimmed uppercased
                                   dp-alphanumeric-only
                                   (change)="updateSubjectProperty(fieldExpensesNoUndividedShare,'condominiumExpenses', 'noUndividedShare');"
                                   id="fieldTCExpensesNoUndividedShare"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="condominiumExpenseSP">
                        <input type="text"
                               id="expensesNoUndividedShareSP"
                               [max-int]=99999.999999
                               dp-percentage
                               [max-decimals]=6
                               [(ngModel)]="teranetImportDataSP.condominiumExpenses[0].noUndividedShare"
                               name="expensesNoUndividedShareSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.importData.condominiumPlanNumber'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataSP.isCondo">
                <!--// If it is not condo, plan should be mapped to plan of MatterProperty for AB-->
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Plan</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="matterCondominiumPlanNumberTC"
                           *ngIf="teranetImportDataTC.condominiumPlanNumber"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.condominiumPlanNumber"
                           name="matterCondominiumPlanNumberTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldTCmatterCondominiumPlanNumber" fieldClass="top-8" *ngIf="teranetImportDataTC.condominiumPlanNumber && teranetImportDataTC.condominiumPlanNumber != matterProperty.condominiumPlanNumber">
                        <input type="checkbox"
                               [(ngModel)]="fieldCondominiumPlanNumber"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldCondominiumPlanNumber,'condominiumPlanNumber')"
                               id="fieldTCmatterCondominiumPlanNumber"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="matterCondominiumPlanNumberSP"
                           [dp-read-only]="true"
                           [(ngModel)]="teranetImportDataSP.condominiumPlanNumber"
                           name="matterCondominiumPlanNumberSP"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.property.importData.parcelLegalDescription.unit'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataSP.isCondo">
                <!--// If it is not condo, plan should be mapped to plan of MatterProperty for AB-->
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Unit</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="parcelLegalDescriptionUnitTC"
                           *ngIf="teranetImportDataTC.parcelLegalDescription.unitNumber"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.parcelLegalDescription.unitNumber"
                           name="parcelLegalDescriptionUnitTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldTCParcelLegalDescriptionUnit" fieldClass="top-8" *ngIf="teranetImportDataTC.parcelLegalDescription">
                        <input type="checkbox"
                               [(ngModel)]="fieldTCParcelLegalDescriptionUnit"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldTCParcelLegalDescriptionUnit,'parcelLegalDescription','unitNumber')"
                               id="fieldTCParcelLegalDescriptionUnit"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4" *ngIf="teranetImportDataSP.parcelLegalDescription">
                    <input type="text"
                           id="parcelLegalDescriptionUnitSP"
                           [dp-read-only]="true"
                           [(ngModel)]="teranetImportDataSP.parcelLegalDescription.unitNumber"
                           name="parcelLegalDescriptionUnitSP"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.importData.percentageInterest'">
                <!--If it is condo, plan should be stored in condominiumExpenses.planNumber-->
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataSP.isCondo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Percentage Interest</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="percentageInterestTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription.percentageInterest"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.percentageInterest"
                               name="percentageInterestTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldPercentageInterest" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription.percentageInterest">
                            <input type="checkbox"
                                   [(ngModel)]="fieldPercentageInterest"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldPercentageInterest,'parcelLegalDescription', 'percentageInterest');"
                                   id="fieldPercentageInterest"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="percentageInterestSP"
                               [dp-read-only]="true"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.percentageInterest"
                               name="percentageInterestSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>


            <ng-container *dpShowByProvince="'matter.property.spin.importData.exception'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataTC.isCondo || !teranetImportDataTC.isMetesAndBounds">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Exception</label>
                    </div>
                    <div class="col-lg-4">
                        <select
                            class="form-control"
                            [dp-read-only]="true"
                            id="exceptionTC" name="exceptionTC"
                            [(ngModel)]="teranetImportDataTC.exceptionType"
                        >
                            <option *ngFor="let exceptionYNType of exceptionYNOptions"
                                    [value]="exceptionYNType.value">{{exceptionYNType.label}}
                            </option>
                        </select>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCExceptionType" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.exceptionType && teranetImportDataTC.exceptionType != matterProperty.exceptionType">
                            <input type="checkbox"
                                   [(ngModel)]="fieldException"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldException,'exceptionType');"
                                   id="fieldTCExceptionType"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <select
                            class="form-control"
                            id="exceptionYNOptionSP" name="exceptionYNOptionSP"
                            [(ngModel)]="teranetImportDataSP.exceptionType"
                        >
                            <option *ngFor="let exceptionYNType of exceptionYNOptions"
                                    [value]="exceptionYNType.value">{{exceptionYNType.label}}
                            </option>
                        </select>

                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.exceptionTypeDescription'">
                <div class="col-lg-12 form-group" *ngIf="teranetImportDataTC.isCondo || teranetImportDataTC.isPlanBlockLot">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Exception Text</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="exceptionTypeDescriptionTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.exceptionTypeDescription"
                               [ngModel]="teranetImportDataTC.exceptionTypeDescription"
                               name="exceptionTypeDescriptionTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCExceptionTypeDescriptionTC" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC && teranetImportDataTC.exceptionTypeDescription != matterProperty.exceptionTypeDescription">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTCExceptionTypeDescription"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTCExceptionTypeDescription,'exceptionTypeDescription');"
                                   id="fieldTCExceptionTypeDescriptionTC"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="form-group expdesc col-lg-4">
                        <p-autoComplete
                            [(ngModel)]="teranetImportDataSP.exceptionTypeDescription"
                            tabIndexIgnore
                            name="exceptionDescription"
                            [suggestions]="filteredExceptionTypeOptions"
                            (completeMethod)="filterExceptionTypeDescription($event)"
                            [minLength]="1"
                            [dropdown]="true"
                            (keydown.space)="utils.handleSpaceKeyDropdown($event, teranetImportDataSP.exceptionTypeDescription, handleDropdownClick)"
                            (keydown.enter)="utils.handleEnterKeyDropdown($event,  teranetImportDataSP.exceptionTypeDescription, handleDropdownClick)"
                            (onDropdownClick)="handleDropdownClickExceptionTypeDescription()">
                            <ng-template let-paymentFrequencyLabel pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                                    <span class="omnibarLi100">
                                                        {{paymentFrequencyLabel}}
                                                    </span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </ng-container>

            <div class="col-lg-12 form-group" *ngIf="isPlanVisible">
                <!--// If it is not condo, plan should be mapped to plan of MatterProperty for AB-->
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Plan</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="planTC"
                           *ngIf="teranetImportDataTC.plan"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.plan"
                           name="planTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldTCPlan" fieldClass="top-8" *ngIf="teranetImportDataTC.plan && teranetImportDataTC.plan != matterProperty.plan">
                        <input type="checkbox"
                               [(ngModel)]="fieldPlan"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldPlan,'plan')"
                               id="fieldTCPlan"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="planPT"
                           [(ngModel)]="teranetImportDataSP.plan"
                           name="planPT"
                           class="form-control"/>
                </div>
            </div>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.block'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo && teranetImportDataTC.isPlanBlockLot">
                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Block</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="blockTC"
                               *ngIf="teranetImportDataTC.block"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.block"
                               name="blockTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCBlock" fieldClass="top-8" *ngIf="teranetImportDataTC.block">
                            <input type="checkbox"
                                   [(ngModel)]="fieldBlock"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldBlock,'block')"
                                   id="fieldTCBlock"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="blockSP"
                               [(ngModel)]="teranetImportDataSP.block"
                               name="blockSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.lot'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo && teranetImportDataTC.isPlanBlockLot">
                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Lot</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="lotTC"
                               *ngIf="teranetImportDataTC.lot"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.lot"
                               name="lotTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCLot" fieldClass="top-8" *ngIf="teranetImportDataTC.lot">
                            <input type="checkbox"
                                   [(ngModel)]="fieldLot"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldLot,'lot')"
                                   id="fieldTCLot"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="lotSP"
                               [(ngModel)]="teranetImportDataSP.lot"
                               name="lotSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.beingPart'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo">
                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Being Part</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="bPartTC"
                               *ngIf="teranetImportDataTC.beingPart"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.beingPart"
                               name="bPartTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCBPart" fieldClass="top-8" *ngIf="teranetImportDataTC.beingPart && teranetImportDataTC.beingPart != matterProperty.beingPart">
                            <input type="checkbox"
                                   [(ngModel)]="fieldBPart"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldBPart,'beingPart')"
                                   id="fieldTCBPart"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="bPartPT"
                               [(ngModel)]="teranetImportDataSP.beingPart"
                               name="bPartPT"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.onPlan'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo">
                    <div class="col-lg-2 vertical-align-label"><label class="control-label">On Plan</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="onPlanTC"
                               *ngIf="teranetImportDataTC.onPlan"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.onPlan"
                               name="onPlanTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTCOnPlan" fieldClass="top-8" *ngIf="teranetImportDataTC.onPlan  && teranetImportDataTC.onPlan != matterProperty.onPlan">
                            <input type="checkbox"
                                   [(ngModel)]="fieldOnPlan"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldOnPlan,'onPlan')"
                                   id="fieldTCOnPlan"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="onPlanPT"
                               [(ngModel)]="teranetImportDataSP.onPlan"
                               name="onPlanPT"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.parcel'">
                <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Parcel</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="parcelTC"
                           *ngIf="teranetImportDataTC.parcel"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.parcel"
                           name="parcelTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldParcelTC" fieldClass="top-8" *ngIf="teranetImportDataTC.parcel && teranetImportDataTC.parcel != matterProperty.parcel">
                        <input type="checkbox"
                               [(ngModel)]="fieldParcel"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldParcel,'parcel')"
                               id="fieldParcelTC"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="parcelPT"
                           [(ngModel)]="teranetImportDataSP.parcel"
                           name="parcelPT"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.section'">
                <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Section</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="sectionTC"
                           [dp-read-only]="true"
                           *ngIf="teranetImportDataTC.section"
                           [ngModel]="teranetImportDataTC.section"
                           name="sectionTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldSectionTC" fieldClass="top-8" *ngIf="teranetImportDataTC.section && teranetImportDataTC.parcel != matterProperty.parcel">
                        <input type="checkbox"
                               [(ngModel)]="fieldSection"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldSection,'section')"
                               id="fieldSectionTC"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="sectionPT"
                           [(ngModel)]="teranetImportDataSP.section"
                           name="sectionPT"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.easementOfWay'">
                <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Easement/R of way</label>
                </div>
                <div class="col-lg-4">

                </div>

                <div class="col-lg-1 text-center padding-left-15">

                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="easementPT"
                           [(ngModel)]="teranetImportDataSP.easementRightOfWay"
                           name="easementPT"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>
            <!--The city and municipality of ON and AB are always read only. They can't be edited and only populated after selected -->
            <div class="col-lg-12 form-group" *dpShowByProvince="'matter.teranet.import.data.modal'">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">City</label>
                </div>
                <div class="col-lg-4">
                    <input *dpShowByProvince="'matter.property.spin.importData.cityTC'"
                           [dp-read-only]="true"
                           type="text"
                           id="cityTC"
                           [(ngModel)]="teranetImportDataTC.city"
                           name="cityTC"
                           class="form-control"/>

                </div>

                <div class="col-lg-1 text-center padding-left-15">

                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="cityPT"
                           [dp-read-only]="true"
                           [(ngModel)]="teranetImportDataSP.city"
                           name="cityPT"
                           class="form-control"/>
                </div>
            </div>


            <div class="col-lg-12 form-group" *dpShowByProvince="'matter.property.importData.municipality'">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Municipality</label>
                </div>
                <div class="col-lg-4">
                    <input *dpShowByProvince="'matter.property.spin.importData.municipalityTC'"
                           [dp-read-only]="true"
                           type="text"
                           id="municipalityTC"
                           [(ngModel)]="teranetImportDataTC.municipality"
                           name="municipalityTC"
                           class="form-control"/>

                </div>

                <div class="col-lg-1 text-center padding-left-15">

                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="municipalityPT"
                           [dp-read-only]="true"
                           [(ngModel)]="teranetImportDataSP.municipality"
                           name="municipalityPT"
                           class="form-control"/>
                </div>
            </div>

            <div class="col-lg-12 form-group" *dpShowByProvince="'matter.property.importData.registryOffice'">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'teranet.import.data.modal.registryOffice.label'">Registry Office</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="registryTC"
                           *ngIf="registryOfficeTC"
                           [dp-read-only]="true"
                           [ngModel]="registryOfficeTC"
                           name="registryTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldRegistryTC" fieldClass="top-8"
                                 *ngIf="registryOfficeTC && (!matterProperty.registryOffice || (registryOfficeTC != registryOfficeMatterProperty))">
                        <input type="checkbox"
                               [(ngModel)]="fieldRegistry"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldRegistry,'registryOffice')"
                               id="fieldRegistryTC"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="registryPT"
                           [dp-read-only]="true"
                           [ngModel]="registryOfficeSP"
                           name="registryPT"
                           class="form-control"/>
                </div>
            </div>

            <ng-container *dpShowByProvince="'matter.property.isc.importData.titleNumber'">
                <div class="col-lg-12 form-group" >
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Title Number</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="iscParcelTitleNumberTC"
                               [dp-read-only]="true"
                               *ngIf="teranetImportDataTC.parcelLegalDescription && teranetImportDataTC.parcelLegalDescription.parcelTitles[0].titleNumber"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.parcelTitles[0].titleNumber"
                               name="iscParcelTitleNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTcISCParcelTitleNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription.parcelTitles[0] && teranetImportDataTC.parcelLegalDescription.parcelTitles[0].titleNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTcISCParcelTitleNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTcISCParcelTitleNumber,'parcelLegalDescription', 'parcelTitles.titleNumber');"
                                   id="fieldTcISCParcelTitleNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4" *ngIf="parcelTitleSP">
                        <input type="text"
                               id="iscParcelTitleNumberSP"
                               [(ngModel)]="teranetImportDataSP.parcelLegalDescription.parcelTitles[0].titleNumber"
                               name="iscParcelTitleNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.pin'">
                <div class="col-lg-12 form-group" *ngIf="!teranetImportDataSP.isCondo">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">PIN</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="pinTC"
                           *ngIf="teranetImportDataTC.pin"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.pin"
                           name="pinTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldPinTC" fieldClass="top-8" *ngIf="teranetImportDataTC.pin && !isPinAlreadyAdded">
                        <input type="checkbox"
                               [(ngModel)]="fieldPin"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldPin,'pin');updatePin(fieldPin)"
                               id="fieldPinTC"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="pinPT"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataSP.pin"
                           name="pinPT"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.lastTransferNo'">
               <div class="col-lg-12 form-group">
                <div class="col-lg-2 vertical-align-label"><label class="control-label">Last Transfer No.</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="transferTC"
                           *ngIf="teranetImportDataTC.lastTransferNumber"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.lastTransferNumber"
                           name="transferTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldTransferTC" fieldClass="top-8"
                                 *ngIf="teranetImportDataTC.lastTransferNumber && teranetImportDataTC.lastTransferNumber != matterProperty.lastTransferNumber">
                        <input type="checkbox"
                               [(ngModel)]="fieldlastTransferNo"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldlastTransferNo,'lastTransferNumber')"
                               id="fieldTransferTC"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="transferPT"
                           name="transferPT"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataSP.lastTransferNumber"
                           class="form-control"/>
                </div>
            </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.linc'">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Linc</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="lincTC"
                               *ngIf="teranetImportDataTC.lincNumber"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.lincNumber"
                               name="lincTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldLinc" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.lincNumber && teranetImportDataTC.lincNumber != matterProperty.lincNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldLincNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldLincNumber,'lincNumber')"
                                   id="fieldLinc"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="LincSP"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataSP.lincNumber"
                               name="LincSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <ng-container *dpShowByProvince="'matter.property.spin.importData.titleNo'">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" [dp-province-field-label]="'teranet.import.data.modal.titleNo.label'">Vendor's Title No</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="titleNumberTC"
                               *ngIf="teranetImportDataTC.titleNumber"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.titleNumber"
                               name="titleNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldTitleNumber" fieldClass="top-8"
                                     *ngIf="isTitleNumberDifferent()">
                            <input type="checkbox"
                                   [(ngModel)]="fieldTitleNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldTitleNumber,'titleNumber')"
                                   id="fieldTitleNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="titleNumberSP"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataSP.titleNumber"
                               name="titleNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>

            <div class="col-lg-12 form-group" *dpShowByProvince="'matter.property.importData.lastInstrumentNo'">
                <div class="col-lg-2 vertical-align-label"><label class="control-label" [dp-province-field-label]="'teranet.import.data.modal.lastInstrumentNo.label'">Last Instrument No.</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="instrumentTC"
                           *ngIf="teranetImportDataTC.lastInstrumentNumber"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataTC.lastInstrumentNumber"
                           name="instrumentTC"
                           class="form-control"/>
                </div>

                <div class="col-lg-1 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldInstrument" fieldClass="top-8"
                                 *ngIf="teranetImportDataTC.lastInstrumentNumber && teranetImportDataTC.lastInstrumentNumber != matterProperty.lastInstrumentNumber">
                        <input type="checkbox"
                               [(ngModel)]="fieldInstrumentNo"
                               [checked]="allFields"
                               (change)="updateSubjectProperty(fieldInstrumentNo,'lastInstrumentNumber')"
                               id="fieldInstrument"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="instrumentPT"
                           [dp-read-only]="true"
                           [ngModel]="teranetImportDataSP.lastInstrumentNumber"
                           name="instrumentPT"
                           class="form-control"/>
                </div>
            </div>

            <ng-container *dpShowByProvince="'matter.property.importData.titleRollNumber.parcelNumber'">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" >Title No.</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="parcelNumberTC"
                               *ngIf="teranetImportDataTC.parcelLegalDescription.parcelNumber"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataTC.parcelLegalDescription.parcelNumber"
                               name="parcelNumberTC"
                               class="form-control"/>
                    </div>

                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="fieldParcelNumber" fieldClass="top-8"
                                     *ngIf="teranetImportDataTC.parcelLegalDescription.parcelNumber">
                            <input type="checkbox"
                                   [(ngModel)]="fieldParcelNumber"
                                   [checked]="allFields"
                                   (change)="updateSubjectProperty(fieldParcelNumber, 'parcelLegalDescription', 'parcelNumber')"
                                   id="fieldParcelNumber"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="parcelNumberSP"
                               [dp-read-only]="true"
                               [ngModel]="teranetImportDataSP.parcelLegalDescription.parcelNumber"
                               name="parcelNumberSP"
                               class="form-control"/>
                    </div>
                </div>
            </ng-container>
        </div>
        </ng-container>
        <ng-container *ngIf="context && context.isWizard">
            <div class="col-lg-12 vertical-align-label text-center padding-top-25">
                <label class="control-label text-center bold font-22">
                       Import Data In Progress.... Please Wait ....
                </label>
            </div>
        </ng-container>
    </div>
    <div class="row modal-footer">

        <div class="col-lg-12">
            <div class="row padding-top-20">
                <div class="col-lg-12 text-center">
                    <ng-container *ngIf="context && !context.isWizard">
                    <button type="button" class="dp-btn" [disabled]="false" (click)="ok()" id="okBtn">
                        <span>Import Selected</span>
                    </button>
                    </ng-container>
                    <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>

    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
