import {AppointmentSubtypes, EventDateRangeTypes, StaffAvailabilityValues} from './event.constants';

export const eventDropDowns = {

  dateRanges: [
    {label: 'Next 30 Days', value: EventDateRangeTypes.NEXT30DAYS},
    {label: 'Day View', value: EventDateRangeTypes.DAY},
    {label: 'Week View', value: EventDateRangeTypes.WEEK},
    {label: 'Month View', value: EventDateRangeTypes.MONTH}
  ],

  eventStatuses: [
    {label: 'All Statuses', value: 'ALL'},
    {label: 'Overdue', value: 'OVERDUE'},
    {label: 'Outstanding', value: 'OUTSTANDING'},
    {label: 'Completed', value: 'COMPLETED'}
  ],

  eventTypes: [
    {label: 'All Events type', value: 'ALL'},
    //Convert UI value into server search criteria value
    {label: 'Closing & Occupancy', value: 'CLOSING!OCCUPANCY'},
    {label: 'Requisitions', value: 'REQUISITION'},
    {label: 'Extended Workflows', value: 'SUPPLEMENTAL'},
    {label: 'Undertaking', value: 'UNDERTAKING'},
    {label: 'Appointment', value: 'APPOINTMENT'},
    {label: 'Holdback', value: 'HOLDBACK'},
    {label: 'Tasks', value: 'WORKITEMTASK'}
  ],
  eventLabels: [
    {label: 'CLOSING_OPPORTUNITY', value: 'event-type-color-closing-opportunity'},
    {label: 'CLOSING', value: 'event-type-color-closing'},
    {label: 'REQUISITION', value: 'event-type-color-requisition'},
    {label: 'UNDERTAKING', value: 'event-type-color-undertaking'},
    {label: 'OCCUPANCY', value: 'event-type-color-occupancy'},
    {label: 'SUPPLEMENTAL', value: 'event-type-color-supplemental'},
    {label: 'APPOINTMENT', value: 'event-type-color-appointment'},
    {label: 'HOLDBACK', value: 'event-type-color-holdback'},
    {label: 'WORKITEMTASK', value: 'event-type-color-task'}
  ],
  matterTypeLabels: [
    {label: 'All Matter Types', value: 'ALL'},
    {label: 'Purchase', value: 'PURCHASE'},
    {label: 'Sale', value: 'SALE'},
    {label: 'Mortgage', value: 'MORTGAGE'}

  ],
  eventStatusOptions: [
    {label: '', value: 'UNSPECIFIED'},
    {label: 'Performed', value: 'PERFORMED'},
    {label: 'Cancelled', value: 'CANCELLED'},
    {label: 'Postponed', value: 'POSTPONED'}

  ],
  eventLocationOptions: [
    {label: 'In-person', value: 'IN_PERSON'},
    {label: 'Other', value: 'OTHER'}
  ],

  eventDocsStatusOptions: [
    {label: 'Docs Pending', value: 'DOCS_PENDING'},
    {label: 'Docs Ready', value: 'DOCS_READY'},
    {label: 'N/A', value: 'NA'}
  ],

  eventSubtypeOptions: [
    {label: AppointmentSubtypes.SIGN_DOCUMENTS_LABEL, value: AppointmentSubtypes.SIGN_DOCUMENTS_VALUE},
    {label: AppointmentSubtypes.CLIENT_MEETING_LABEL, value: AppointmentSubtypes.CLIENT_MEETING_VALUE}
  ],

  staffStatusOptions: [
    {label: 'Tentative', value: StaffAvailabilityValues.Tentative},
    {label: 'Busy', value: StaffAvailabilityValues.Busy},
    {label: 'Out of Office', value: StaffAvailabilityValues.OutOfOffice}
  ]
};
