import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ChicagoDocument extends BaseEntity {
  storageId: number;
  fileNumber: number;
  fileName: string;
  dateAdded: string;
  documentType: string;
  documentExtension: string;
  documentCategory: string;
  documentRawStream: string; //normally should not be sent by back end.
  downloadStatus: string;

  constructor(c?: ChicagoDocument) {
    super(c);
  }

}
