import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MatterSupplementalTaskField} from './matter-supplemental-task-field';
import {MatterSupplementalTaskTemplate} from './matter-supplemental-task-template';
import {SupplementalTaskCategory} from '../../shared-main/supplemental-task-category/supplemental-task-category';
import {CategoryFieldType} from '../../shared-main/constants';
import {TrustLedgerMatter} from '../trust-ledger/trust-ledger-matter';
import {SoaMatter} from '../statement-account/soa-matter';
import {Contact} from './contact';
import {Matter} from './matter';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import Utils from '../../shared-main/utils';
import {UUIDUtil} from '../../main/uuid-util';

export type DataSource = 'MANUAL_ENTRY' | 'SOLICITOR_RECORDS' | 'JURISDICTION_RECORDS' | 'OTHER_CONTACTS';

//this field only has a init value 'true' for province AB, for other province, leave it to null/undefined
export const showPaymentAmountsInitValue: any = {
  'AB': true
};

export class MatterSupplementalTaskCategory extends BaseEntity {
  id: number;
  static clientAssignedIdentifierEntity: boolean = true; //Marker property for indicating client side assigns the ID to this entity
  supplementalTaskSeq: number;
  sourceSupplementalTaskCategoryId: number;
  categoryName: string;
  categoryFields: MatterSupplementalTaskField[] = [];
  categoryTemplates: MatterSupplementalTaskTemplate[] = [];
  trustLedgers: TrustLedgerMatter[] = [];
  matterSoas: SoaMatter[] = [];
  receivedOnAccount: number;
  supplementalTaskCategoryContact: Contact;
  solicitorFromMatterOpening: boolean;
  dataSource: DataSource;
  showPaymentAmounts: boolean;

  constructor(m?: MatterSupplementalTaskCategory) {
    super(m);
    if (m) {
      if (m.categoryFields) {
        for (let f of m.categoryFields) {
          this.categoryFields.push(new MatterSupplementalTaskField(f));
        }
      }

      if (m.categoryTemplates) {
        for (let t of m.categoryTemplates) {
          this.categoryTemplates.push(new MatterSupplementalTaskTemplate(t));
        }
      }

      if (m.trustLedgers) {
        for (let t of m.trustLedgers) {
          this.trustLedgers.push(new TrustLedgerMatter(t));
        }
      }
      if (m.matterSoas) {
        for (let t of m.matterSoas) {
          this.matterSoas.push(new SoaMatter(t));
        }
      }
      if (m.supplementalTaskCategoryContact) {
        this.supplementalTaskCategoryContact = new Contact(m.supplementalTaskCategoryContact);
      }
    } else {
      this.id = UUIDUtil.getUUID();
    }
  }

  static clone(source: MatterSupplementalTaskCategory): MatterSupplementalTaskCategory {
    let clonedTask: MatterSupplementalTaskCategory = new MatterSupplementalTaskCategory();

    for (let prop in source) {
      if (source.hasOwnProperty(prop)) {
        clonedTask[ prop ] = source[ prop ];
      }
    }

    clonedTask.id = UUIDUtil.getUUID();

    clonedTask.trustLedgers = [];
    if (Array.isArray(source.trustLedgers)) {
      source.trustLedgers.forEach(item => {
        let tlm: TrustLedgerMatter = new TrustLedgerMatter(item);
        tlm.id = null;
        clonedTask.trustLedgers.push(tlm);
      });
    }

    clonedTask.matterSoas = [];
    if (Array.isArray(source.matterSoas)) {
      source.matterSoas.forEach(item => {
        let soa: SoaMatter = new SoaMatter(item);
        soa.id = null;
        clonedTask.matterSoas.push(soa);
      });
    }

    clonedTask.categoryFields = [];
    if (source.categoryFields) {
      source.categoryFields.forEach(item => {
        clonedTask.categoryFields.push(MatterSupplementalTaskField.clone(item));
      });
    }

    clonedTask.categoryTemplates = [];
    if (source.categoryTemplates) {
      source.categoryTemplates.forEach(item => {
        clonedTask.categoryTemplates.push(MatterSupplementalTaskTemplate.clone(item));
      });
    }

    return clonedTask;
  }

  static setOrderNumber(matterSupplementalTaskCategory: MatterSupplementalTaskCategory, matter: Matter) {
    if (Array.isArray(matter.supplementalTasks)) {
      if (matter.maxSupplementalTaskSeq) {
        matterSupplementalTaskCategory.supplementalTaskSeq = matter.supplementalTasks.length >= Number(matter.maxSupplementalTaskSeq)
          ? matter.supplementalTasks.length + 1
          : matter.maxSupplementalTaskSeq + 1;
      } else {
        matterSupplementalTaskCategory.supplementalTaskSeq = matter.supplementalTasks.length + 1;
      }
    }
  }

  static createFromDefaultCategory(defaultSupplementalTaskCategory: SupplementalTaskCategory, matterDocumentProfile: DocumentProfile): MatterSupplementalTaskCategory {
    let matterSupplementalTaskCategory = new MatterSupplementalTaskCategory();
    matterSupplementalTaskCategory.categoryName = defaultSupplementalTaskCategory.categoryName;
    matterSupplementalTaskCategory.sourceSupplementalTaskCategoryId = defaultSupplementalTaskCategory.id;

    if (defaultSupplementalTaskCategory.categoryFields) {
      defaultSupplementalTaskCategory.categoryFields.forEach(categoryField => {
        if (categoryField.enabled) {
          matterSupplementalTaskCategory.categoryFields.push(MatterSupplementalTaskField.createFromDefaultCategoryField(categoryField));
        }
      });
    }

    if (defaultSupplementalTaskCategory.categoryTemplates && matterDocumentProfile) {
      defaultSupplementalTaskCategory.categoryTemplates.filter(categoryTemplate => categoryTemplate.templateFolderId == matterDocumentProfile.accountFileFolder.id).forEach(categoryTemplate => {
        matterSupplementalTaskCategory.categoryTemplates.push(MatterSupplementalTaskTemplate.createFromDefaultCategoryTemplate(categoryTemplate));
      });
    }

    return matterSupplementalTaskCategory;
  }

  get nameAddressField(): MatterSupplementalTaskField {
    return this.getFieldByType('NAME_ADDRESS');
  }

  get accountNumberField(): MatterSupplementalTaskField {
    return this.getFieldByType('ACCOUNT_NUMBER');
  }

  get detailField(): MatterSupplementalTaskField {
    return this.getFieldByType('DETAILS');
  }

  get ticklerDateField(): MatterSupplementalTaskField {
    return this.getTicklerGroupSubField('TICKLER_DATE');
  }

  get ticklerNotesField(): MatterSupplementalTaskField {
    return this.getTicklerGroupSubField('TICKLER_NOTES');
  }

  get ticklerTaskCompletedField(): MatterSupplementalTaskField {
    return this.getTicklerGroupSubField('HAS_TASK_COMPLETED');
  }

  get ticklerTaskCompletionDateField(): MatterSupplementalTaskField {
    return this.getTicklerGroupSubField('DATE_TASK_COMPLETED');
  }

  get ticklerPerField(): MatterSupplementalTaskField {
    return this.getTicklerGroupSubField('TICKLER_PER');
  }

  get ticklerCompletionNotesField(): MatterSupplementalTaskField {
    return this.getTicklerGroupSubField('TASK_COMPLETION_NOTES');
  }

  getTicklerGroupSubField(subField: CategoryFieldType): MatterSupplementalTaskField {
    return this.ticklerGroupField ? this.ticklerGroupField.subFields.find(item => item.fieldType === subField) : null;
  }

  get ticklerGroupField(): MatterSupplementalTaskField {
    return this.getFieldByType('TICKLER_GROUP');
  }

  get documentCount(): string {
    return this.getNumberOfDocProduced() + '/' + this.categoryTemplates.length;
  }

  get taskCompleted(): string {
    return this.ticklerTaskCompletedField ? this.ticklerTaskCompletedField.fieldValue : '';
  }

  getNumberOfDocProduced(): number {
    let producedDocCount: number = 0;
    this.categoryTemplates.forEach(item => {
      if (item.hasProducedDocument) {
        producedDocCount++;
      }
    });
    return producedDocCount;
  }

  getFieldByType(fieldType: CategoryFieldType): MatterSupplementalTaskField {
    return this.categoryFields.find(item => item.fieldType === fieldType);
  }

  get singularFields(): MatterSupplementalTaskField[] {
    return this.categoryFields.filter(item => item.fieldType !== 'NAME_ADDRESS' && item.fieldType !== 'TICKLER_GROUP' && item.fieldType !== 'ACCOUNT_NUMBER');
  }

  get isPayBillOrDebt(): boolean {
    return this.categoryName === 'PAY BILLS OR DEBTS';
  }

  get isIdVerificationOfNonClient(): boolean {
    return this.categoryName === 'ID VERIFICATION OF NON-CLIENT';
  }

  get isStatementOfAccount(): boolean {
    return this.categoryName === 'STATEMENT OF ACCOUNT';
  }

  get isJurisdictionRecord(): boolean {
    return this.dataSource == 'JURISDICTION_RECORDS';
  }

  get taskDisplayName(): string {
    let taskName: string = '';
    if (this.isJurisdictionRecord && this.nameAddressField) {
      taskName = this.nameAddressField.fieldValue;
    } else if (this.nameAddressField && this.nameAddressField.fieldContact && this.nameAddressField.fieldContact.contactType) {
      let fieldName = this.nameAddressField.getFieldForContactName();
      if (fieldName && this.nameAddressField.fieldContact[ fieldName ]) {
        taskName = this.nameAddressField.fieldContact[ fieldName ];
      }
    }
    return taskName;
  }

  isSolicitorFromMatterOpening(): boolean {
    return this.solicitorFromMatterOpening && Utils.convertToBoolean(this.solicitorFromMatterOpening);
  }
}
