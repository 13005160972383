<!-- modal -->
<div class="container-fluid custom-modal-container">
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1>Requisitions</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="fixed-header">
            <div class="row">
                <div class="col-lg-12 margin-top-10 margin-left-5">
                    <div class="form-group">
                        <div class="col-lg-12">
                            <input class="form-control focus-first" id="search-input" (keydown)="onKeyPress($event)"
                                   name="search-input" #search="ngModel" #searchInput placeholder="Start typing to search for desired REQUISITION NAME"
                                   [(ngModel)]="searchKey">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group padding-top-15">
                <div class="col-lg-8 vertical-align-label">
                    <label class="search-records">
                        Records:  {{rows.length}}
                    </label>
                </div>
                <div style="padding-right: 25px" class="col-lg-4 text-right padding-right: 25px;">
                    <ul>
                        <li class="dropdown margin-right-0">
                            <button label="Add New" class="dp-btn-green" (click)="addNewRequisitionTemplateDialog()">
                                <span>Add New</span>
                                <div class="clear"></div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div *ngIf="isInstrument() && enableShowAll" class="margin-left-5 margin-bottom-15">
                <dp-checkbox fieldId="show-all-checkbox">
                    <input type="checkbox" id="show-all-checkbox" name="show-all-checkbox" (click)="showAllRequisitionsClick()" [checked]="showAllRequisitions"/>
                </dp-checkbox>
                Show All Requisitions
            </div>
            <div class="templates-table">
                <div class="requisition-title">
                    Requisition Name
                </div>
                <table class="table standard-table" tabindex="-1"  #scrollable>
                    <tbody>
                    <tr *ngIf="loading">
                        <td>
                            <div id="spinner">
                                <img src="assets/img/spinner.gif"/>
                            </div>
                        </td>
                    </tr>
                    <tr *ngFor="let row of rows; let i=index;"  (click)="updateSelectedItem(row)"
                        id="requisitionArray{{i}}"
                        tabindex="0"
                        (keydown)="onKeyPress($event)"
                        (focus)="updateSelectedItem(row)"
                        [ngClass]="{'active': isRowSelected(row)}">
                        <td>
                            <div><input type="text" value="{{row.key}}" readonly
                                        (dblclick)="onSelectTemplate()"></div>
                        </td>
                        <td *ngIf="context.editReqTemplates">
                            <dp-dropdown-menu
                                [dropDownItems]="reqTemplateDropMenuItems"
                                (itemClicked)="clickReqTemplateDropDownMenu(row, $event)" >
                            </dp-dropdown-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn keyboard-save-action" *ngIf="selectedTemplate && !context.editReqTemplates" (click)="onSelectTemplate()">
                <span>Select</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span *ngIf="!context.editReqTemplates">Cancel</span>
                <span *ngIf="context.editReqTemplates">OK</span>
            </button>
        </div>
    </div>
</div>



