import {SpecialComment} from './special-comment';

export class SpecialCommentToDo {
  id: number;
  customPickListItemSequence: number;
  customPickListItemValue: string;
  rawCustomPickListItemValue: string;
  customPickListTypeCode: string;
  completed: boolean;
  editing: boolean;
  defaultType: boolean;

  constructor(comment?: SpecialComment) {
    if (!comment) {
      return null;
    }

    this.id = comment.id;
    this.customPickListItemSequence = comment.customPickListItemSequence;
    this.customPickListItemValue = comment.customPickListItemValue;
    this.rawCustomPickListItemValue = comment.customPickListItemValue;
    this.customPickListTypeCode = comment.customPickListTypeCode;
    this.completed = false;
    this.editing = false;
    this.defaultType = comment.defaultType;
  }
}
