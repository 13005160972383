<form class="form-horizontal" id="teranetChangePasswordForm" #teranetChangePasswordForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-7">
                <h1>Teranet Connect &trade;: Change Password</h1>
            </div>
        </div>



        <div class="row modal-body" [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">
            <div class="row">
                <div class="col-lg-7">
                    <div class="form-group">
                         <label class="control-label col-xs-12 col-lg-6">
                                Your password has expired.
                         </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label col-xs-12 col-lg-6" for="credential" >New Password</label>
                                    <div class="col-xs-12 col-lg-6">
                                        <input type="password"     id="newPassword" name="newPassword"
                                               class="form-control focus-first"
                                               [(ngModel)]="newPassword"
                                               dp-error-validator
                                               [fieldKey]="'teranet.changePassword.passwordNotMatch'"
                                              >
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label col-xs-12 col-lg-6">Confirm Password</label>
                                    <div class="col-xs-12 col-lg-6">
                                        <input type="password" id="tcPassword" name="tcPassword"
                                               [(ngModel)]="tcPassword"
                                               class="form-control"
                                               dp-error-validator
                                               [fieldKey]="'teranet.changePassword.passwordNotMatch'"
                                               #password="ngModel"
                                               required>
                                    </div>
                                </div>
                </div>
                <div class="col-lg-6">
                            <label class="control-label col-xs-12 col-lg-6 text-left">
                                Your password must have a minimum of 8 characters and contain a combination of upper case, lower case, and numeric characters.
                            </label>
                </div>
            </div>
        </div>
    </div>


    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button id="okBtn" class="dp-btn" type="button" (click)="ok()" [disabled]="teranetChangePasswordForm.form.invalid" >
                OK
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>
