import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LandingWidgetsService {
  public baseURL: string = "/pi/";
  public keyDpSessionId: string = "dpsessionid";
  public keyDppmAccountId: string = "dppm_accountId";
  public keyUserContactId: string = "dpUserContactid";
  public keyIsConveyancingUser: string = "isConveyancingUser";

    constructor(private httpClient: HttpClient) { }

  private getSessionID() {
    return sessionStorage.getItem(this.keyDpSessionId);
  }
  private getAccountID(){
    return sessionStorage.getItem(this.keyDppmAccountId);
  }
  private getContactID(){
    return sessionStorage.getItem(this.keyUserContactId);
  }
  public getIsConveyancingUser():boolean{
    let val = sessionStorage.getItem(this.keyIsConveyancingUser);
    if(val == "YES"){
      return true;
    }
    return false;
  }

  public getDashboardEvents() {
    try {
      const url: string = this.baseURL + "api/Dashboard/GetAllEventStats?id=" + this.getAccountID() + "&source_contact_id=" + this.getContactID();
      return this.httpClient.get(url);
    }
    catch (ex) {
      console.log(ex)
    }
  }

  public getDashboardMatters() {
    const url: string = this.baseURL + "api/Dashboard/GetMatterSummary?accountID=" + this.getAccountID();
    return this.httpClient.get(url);
  }

  public getOpeningForecastChartData() {
    const url: string = this.baseURL + "api/Dashboard/GetMatterOpeningForecast?id=" + this.getAccountID() + "&sessionID=" + this.getSessionID();
    return this.httpClient.get(url);
  }
  public getRevenueMatterData() {
    const url: string = this.baseURL + "api/Dashboard/GetMatterFeeStats?accountID=" + this.getAccountID() + "&sessionID=" + this.getSessionID();
    return this.httpClient.get(url);
  }

  public getLoggedInUserContent(){
    const url = "https://unityhome.dyedurham.ca/unity-user-home/?content_only=1";
    return this.httpClient.get(url, {responseType: 'text'});
  }

  public getNonLoggedInUserContent(){
    const url = "https://unityhome.dyedurham.ca/unity-non-user-home/?content_only=1";
    return this.httpClient.get(url, {responseType: 'text'});
  }
}
