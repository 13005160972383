import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ServerMatterListFilter} from '../matter-list/matter-list-filter';
import {ColorStyle, ThemeStyle} from '../../shared-main/constants';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {MatterType} from './index';

export class DocumentProductionDefaultCategory extends BaseEntity {
  matterType: string; //It's not really the matter type (where we don't have a value for project sale) but the UI label for indexing some settings
  defaultCategory: string;
}

export class EventListFilter extends BaseEntity {
  selectedLawyerContactIds: string;
  selectedClerkContactIds: string;
  eventTypeFilters: string;
  matterTypeFilters: string;
  selectedTime: string;
  provinceFilters: string;
  selectedProjectIds: string;
  selectedFilterStatuses: string;
}

export class UserProvincialConfiguration extends BaseEntity {

  id: number;
  soaDefaultDocumentTemplate: string;
  trustLedgerDefaultDocumentTemplate: string;
  wizardPayoutDefaultTemplate: string;
  userConfigId: number;
  provinceCode: ProvinceCode;
  matterType: MatterType;
  isProjectSaleConfig: boolean;

}

export type DocumentViewModeType = 'FOLDER' | 'LIST';
export const DocumentViewModeValue = {
  folder: <DocumentViewModeType>'FOLDER',
  list: <DocumentViewModeType>'LIST'
};

export class ConfigValues extends BaseEntity {
  theme: ThemeStyle;
  isCalendarViewActive: string;
  fontSize: number;
  sidebarFontSize: number;
  selectedLandingPage: string;
  sidebarFontColor: ColorStyle;
  isSidebarFontBold: boolean;
  isFieldCodesVisible: boolean;
  keepCheckmarkAfterProducingDocument: boolean;
  documentViewModeForMatterDocument: string;
}

export class UserConfig extends BaseEntity {

  id: number;

  statementAdjustmentShowOnlySummary: boolean;

  /* //Staled fields, should use the corresponding fields in userProvincialConfigurations
     soaDefaultDocumentTemplate: string;

      trustLedgerDefaultDocumentTemplate: string;*/

  documentProductionDefaultCategories: DocumentProductionDefaultCategory[];

  userProvincialConfigurations: UserProvincialConfiguration[];

  defaultMatterListFilter: ServerMatterListFilter;

  defaultOpportunityListFilter: ServerMatterListFilter;

  eventListFilter: EventListFilter;

  staffEventListFilter: EventListFilter;

  configValues: ConfigValues;

  get isCalendarActive(): boolean {
    return this.configValues && this.configValues.isCalendarViewActive == 'true';
  }

  getUserProvincialConfiguration(province: ProvinceCode = 'ON', matterType: MatterType = 'PURCHASE'): UserProvincialConfiguration {
    if (Array.isArray(this.userProvincialConfigurations)) {
      const userProvincialConfiguration: UserProvincialConfiguration = this.userProvincialConfigurations
      .find(upc => upc.provinceCode == province && upc.matterType == matterType);
      // console.log(":: found an UPC for province %s and matterType %s ", province, matterType, userProvincialConfiguration)
      return userProvincialConfiguration;
    }
    return null;
  }

  constructor(u?: UserConfig) {
    super(u);
    if (u) {
      if (u.documentProductionDefaultCategories.length) {
        this.documentProductionDefaultCategories = u.documentProductionDefaultCategories.map(item => {
          return new DocumentProductionDefaultCategory(item);
        });
      }
      if (u.eventListFilter) {
        this.eventListFilter = new EventListFilter(u.eventListFilter);
      }
      if (u.staffEventListFilter) {
        this.staffEventListFilter = new EventListFilter(u.staffEventListFilter);
      }
      if (u.configValues) {
        this.configValues = new ConfigValues(u.configValues);
      }

    }
  }

}
