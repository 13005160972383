import {ContactInstanceType} from './contact-instance-type';
import {OtherContactInformation} from './../../shared-main/other-contact-info-fields/other-contact-information';
import {Jurisdiction} from './jurisdiction';
import * as _ from 'lodash';
import {TelephoneTypes} from './telephone-types';
import {AddressTypes} from './address-types';
import {Address, SameAsAddressOption} from './address';
import {ContactName} from './contact-name';
import {Telephone} from './telephone';
import {IdentificationRecord} from './identification-record';
import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {PrivateContacts} from './private-contacts';
import {contactTypeMapping, ContactTypes, GenderTypes} from '../../contact/contact-type-mapping';
import {BasicUserInfo} from './basic-user-info';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ErrorDirectiveMapping} from '../../shared/error-handling/error-directive-mapping';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {isNumeric} from 'rxjs/util/isNumeric';
import {UserTrustAccount} from '../../admin/staff-profiles/user-trust-account';
import {Utils} from './utils';
import {LendingInstitution} from '../../contact/lending-institution';
import {
  CondoManagedTypeConstValue,
  Constants,
  ReportAddressDdString,
  ServiceAddressDdString,
  SolicitorAddressDdString
} from '../../shared-main/constants';
import {AddressDropdowns} from '../../shared-main/address-Form/drop-downs';
import {Ignore} from '../../admin/shared/dp-ignore-decorator';
import {ContactFieldsDiff} from './contact-field-diff';
import {contactDropDowns} from '../../contact/contact-drop-downs';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {SelectItem} from 'primeng/api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ContactProvinceCapacity} from './contact-province-capacity';
import {Account} from '../../admin/accounts/shared/account';
import {dropDowns} from '../../admin/accounts/shared/account-drop-downs';
import {UUIDUtil} from '../../main/uuid-util';
import {PartyRoleType} from './party-role-type';
import {JournalNote} from '../../admin/account-notes/account-notes';
import {Matter} from './matter';
import {AddressUtil} from './address-util';
import {ContactAssociation, ContactAssociationRole} from '../../../app/matters/shared/contact-association';
import {MatterParticipantRole} from './matter-participant-role-types';
import {MatterParticipantWrapper} from './matter-participant-wrapper';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ContactUtil} from './contact-util';
import {SpouseRelationship} from '../../contact/spouse-relationship';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {PROVINCE_CODES} from './user-province';
import {provinceBasedBusinessRoleOptions} from '../../shared-main/province-based-dropdowns';

const propertyIgnoreList = [ 'isDirty', 'isValid', 'errorMessage', 'isAddressValid', 'instanceCreationTime',
  'lockUserName', 'idInfoEnteredBy', 'enteredOn', 'hiddenNotesVisible', 'duplicateProspectActionTaken',
  'lastSyncedFromSource', 'lockedTimeStamp', 'proxyForGlobal', 'proxyForGlobal',
  'lockedByUser', 'identificationRecordId', 'contactId', 'instanceType', 'closeOtherInfoShutter',
  'updatedByUser', 'lastUpdatedTimeStamp', 'snapshotFlag', 'instanceCreationTime',
  'id', 'addressHash', 'signingOfficerLocal1', 'signingOfficerLocal2', 'locked', 'spouses',
  'attorneyNameLocal1', 'attorneyNameLocal2', 'estateTrusteeLocal1', 'estateTrusteeLocal2', 'signersFor', 'contactIdInfoEnteredBy' ];
export const PropertyLabel = {
  insuranceBrokerType: 'Insurer or Broker',
  organizationName: 'Company Name',
  alternateName: 'Alternate Name',
  institutionNo: 'Institution No.',
  transitNo: 'Transit No.',
  branch: 'Branch Name',
  MAILING: 'Mailing Address',
  SERVICE: 'Address for Service',
  REPORT: 'Address for Report',
  WORK: 'Work Phone',
  MOBILE: 'Cell Phone',
  FAX: 'Fax Phone',
  email: 'Email Address',
  activeFlag: 'Is record active',
  barristerSolicitor: 'Barrister & Solicitor',
  legalFirmName: 'Law Firm Name'
};
export type MortgageeLenderInstitutionLinkStatus = 'LINKED' | 'NOT_MATCHED' | 'EXPLICITLY_NOT_LINKED' | 'MULTIPLE_MATCHED'; //MULTIPLE_MATCHES only exists
export const COMPUTER_SHARE_LENDER_NAME = 'Computershare Trust Company of Canada';
export const COMPUTER_SHARE_ALTERNATE_NAME = 'All';

export const CONTACT_NOTE_CORE_TITLE = 'Notes';

export const MAX_CLIENT_ADDRESSES = 3;

export class SpousalStatusTypes {
  public static readonly CURRENT_SPOUSE: SpousalStatusType = 'CURRENT_SPOUSE';
  public static readonly DIVORCED: SpousalStatusType = 'DIVORCED';
  public static readonly SEPARATED: SpousalStatusType = 'SEPARATED';
  public static readonly SEPARATED_BY_DEATH: SpousalStatusType = 'SEPARATED_BY_DEATH';
  public static readonly COMMON_LAW: SpousalStatusType = 'COMMON_LAW';
  public static readonly WIDOW: SpousalStatusType = 'WIDOW';
}

// 'Current Spouse', ' Divorced', 'Separated by Death'
export type SpousalStatusType = 'CURRENT_SPOUSE' | 'DIVORCED' | 'SEPARATED' | 'SEPARATED_BY_DEATH' | 'COMMON_LAW' | 'WIDOW';

export class DuplicateProspectActionTakenTypes {
  public static readonly KEEP: DuplicateProspectActionTakenType = 'KEEP';
  public static readonly REPLACE: DuplicateProspectActionTakenType = 'REPLACE';
}

export type DuplicateProspectActionTakenType = 'KEEP' | 'REPLACE';

// on frontend
export class Contact extends BaseEntity {

  constructor(contact?: Contact) {
    super(contact);
    this.instanceCreationTime = (new Date()).getMilliseconds();
    if (contact) {

      for (let prop in contact) {
        if (contact.hasOwnProperty(prop)) {
          this[ prop ] = contact[ prop ];
        }
      }

      this.contactName = new ContactName(contact.contactName);

      this.address = [];
      if (Array.isArray(contact.address)) {
        for (let i: number = 0; i < contact.address.length; i++) {
          this.address[ i ] = new Address(contact.address[ i ]);
        }
      }

      this.telephone = [];
      if (Array.isArray(contact.telephone)) {
        for (let j: number = 0; j < contact.telephone.length; j++) {
          this.telephone[ j ] = new Telephone(contact.telephone[ j ]);
        }
      }
      this.trustAccounts = [];
      if (Array.isArray(contact.trustAccounts)) {
        for (let j: number = 0; j < contact.trustAccounts.length; j++) {
          this.trustAccounts[ j ] = new UserTrustAccount(contact.trustAccounts[ j ]);
        }
      }

      this.updatedByUser = new BasicUserInfo(contact.updatedByUser);

      this.otherContactInformation = [];
      if (Array.isArray(contact.otherContactInformation)) {
        for (let i: number = 0; i < contact.otherContactInformation.length; i++) {
          this.otherContactInformation[ i ] = new OtherContactInformation(contact.otherContactInformation[ i ]);
        }
      }

      this.contactProvinceCapacities = [];
      if (Array.isArray(contact.contactProvinceCapacities)) {
        for (let i: number = 0; i < contact.contactProvinceCapacities.length; i++) {
          this.contactProvinceCapacities[ i ] = new ContactProvinceCapacity(contact.contactProvinceCapacities[ i ]);
        }
      }

      this.identificationRecords = [];
      if (Array.isArray(contact.identificationRecords)) {
        for (let i: number = 0; i < contact.identificationRecords.length; i++) {
          this.identificationRecords[ i ] = new IdentificationRecord(contact.identificationRecords[ i ]);
        }
      }
      this.lockedByUser = contact.lockedByUser ? new BasicUserInfo(contact.lockedByUser) : null;
      this.jurisdiction = contact.jurisdiction ? new Jurisdiction(contact.jurisdiction) : null;

      this.subContacts = [];
      if (Array.isArray(contact.subContacts)) {
        for (let i: number = 0; i < contact.subContacts.length; i++) {
          this.subContacts[ i ] = new Contact(contact.subContacts[ i ]);
        }
      }
      this.attention = contact.attention;
      this.localGender = this.gender;
      this.partialPOADate = (this.partialPOADate) ? this.partialPOADate : this.poaDate;

      this.journalNotes = [];
      if (Array.isArray(contact.journalNotes)) {
        for (let i: number = 0; i < contact.journalNotes.length; i++) {
          this.journalNotes[ i ] = new JournalNote(contact.journalNotes[ i ]);
        }
      }
      this.contactAssociations = [];
      if (Array.isArray(contact.contactAssociations)) {
        for (let i: number = 0; i < contact.contactAssociations.length; i++) {
          this.contactAssociations[ i ] = new ContactAssociation(contact.contactAssociations[ i ]);
        }
      }

      //this.signersFor = [];
      this.spouses = [];
      if (Array.isArray(contact.spouses) && contact.spouses.length > 0) {
        for (let i: number = 0; i < contact.spouses.length; i++) {
          this.spouses[ i ] = new SpouseRelationship(contact.spouses[ i ]);
        }
      }

    } else {
      this.contactName = new ContactName();
      this.address = [];
      this.otherContactInformation = [];
      this.identificationRecords = [];
      this.telephone = [];
      this.updatedByUser = new BasicUserInfo();
      this.attention = '';
      this.organizationName = '';
      this.lockedByUser = null;
      this.contactAssociations = [];
      this.signersFor = [];
      this.spouses = [];
      this.spouses = [];
      this.deceased = false;
    }

    if (!this.lastSyncedFromSource) {
      this.lastSyncedFromSource = 0;
    }
  }

  id: number;
  /**
   * Field used by the server side to serialize/deserialize the JSON structure to the correct Contact subtype.
   * On the client side, this needs to be passed along as received from the server, when updating existing contact instances.
   * The value will need to be set when creating a new contact instance, so the server logic understands what to map this to.
   * See ContactInstanceType for potential values
   * //TODO: look into using a string literal type here
   */
  instanceType: string;
  nameOnDocuments: string;
  email: string;
  brokerageIntegrationsFaxPhone: string;
  brokerageIntegrationsEmail: string;
  envelopeSalutationLine1: string;
  envelopeSalutationLine2: string;
  letterSalutation: string;
  dear: string;
  contactType: string;
  organizationName: string;
  birthDate: string;
  poaDate: string;
  instrumentNumber: string;
  canadianResidentFlag: string;
  residentStatus: string;
  sourceContactId?: number;
  //This field is used by snapshot contact to store source's the parent organization's Id. It is used for checking if snapshot referring to same
  // management company as original contact.
  sourceParentOrganizationId?: number;
  //Need this field in staleCheck method for comparing purchaserAgent company's name. It is set in the ngInit of matterOpeningComponent
  sourceParentOrganizationName?: string; //Only for UI
  //Need this field in staleCheck method for distinguishing between saleSideAgent & purchaserSideAgent. It is set in the ngInit of matterOpeningComponent.
  purchaserSideAgent: boolean = false; //Only for UI
  lastSyncedFromSource: number;
  lastUpdatedTimeStamp: number;
  gender: string;
  estateStatusFlag: string;
  deceasedGender: string;
  genderFlag: string;
  executingUnderSealFlag: string;
  contactName: ContactName;
  telephone: Telephone[] = [];
  address: Address[] = [];
  partyRole: string;
  poaDateString: string;
  updatedByUser: BasicUserInfo;
  snapshotFlag: boolean;
  displayName: string;
  includeAuthorizeSignOfficer: DpBooleanValue;
  readonly reLineNameFieldMaxLength: number = 200;
  /* To implement additionalName1/additionalName2 map  to signingOfficer1/signingOfficer2,
     * attorneyName1/attorneyName2 or estateTrustee1/estateTrustee2, additionalName1/additionalName2
     * should set null when gender change. It can work well when get property for signingOfficer1/signingOfficer2,
     * attorneyName1/attorneyName2 or estateTrustee1/estateTrustee2,
     */
  /** About additionalName1 use
   * lable: Signing Officer #1,   Used by gender == CORPORATION || OTHERENTITY
   * lable: Name of Attorney(#1),   Used by gender == MALEPOA || FEMALEPOA
   * lable: Estate Trustee (#1),   Used by gender == Estate
   */
  additionalName1: string;
  signingOfficerLocal1: string;
  attorneyNameLocal1: string;
  estateTrusteeLocal1: string;
  additionalEmail1: string;
  /** About additionalName1 use
   * lable: Signing Officer #2,   Used by gender == CORPORATION || OTHERENTITY
   * lable: Name of Attorney(#2),   Used by gender == MALEPOA || FEMALEPOA
   * lable: Estate Trustee (#2),   Used by gender == Estate
   */
  additionalName2: string;
  signingOfficerLocal2: string;
  attorneyNameLocal2: string;
  estateTrusteeLocal2: string;
  additionalEmail2: string;
  legacySignerTwoPresent: boolean;
  legacySignerOnePresent: boolean;
  notaryFlag: string;

  /** About localGender use
   *  To decouple gender change with other party, use localGender to save gender status.
   *  If gender change is valid, localGender will set to real gender.
   *  If gender change is invalid, localGender will not set to real gender.
   *  Adding localGender to contact instead of local variables, it will stop  to lost gender information
   *  when switch page.
   */
  localGender: string;

  /** UI only field, temporarily stores UI value when the user is working with different email options */
  localEmail: string;

  titleOfOfficeHeld1: string;
  titleOfOfficeHeld2: string;
  isDirty: boolean;
  isValid: boolean;
  city: string;
  municipality: string;
  //The firmName is solicitor's parent firm name
  firmName: string;
  legalFirmId: number;
  //The legalFirmName is name of law firm in contact DTO
  legalFirmName: string;
  solicitorName: string;
  otherContactInformation: OtherContactInformation[];
  contactProvinceCapacities: ContactProvinceCapacity[];
  activeFlag: string;
  jurisdiction: Jurisdiction;
  barristerSolicitor: string;
  privateFlag: boolean;
  proxyForGlobal: boolean;
  proxyEdited: boolean;
  organizationId: number;
  enteredOn: string;
  partialBirthdayDate: string;
  partialPOADate: string;
  idInfoEnteredBy: string;
  identificationRecords: IdentificationRecord[] = [];
  label: string;
  sourceOfElectronicVersion: string;
  typeOfBusinessActivity: string;
  note: string;
  lockedByUser: BasicUserInfo = null;
  parentOrganizationName: string;
  parentOrganizationAddress: string;

  isStaffProfile: boolean;

  //Mortgagee fields
  alternateName: string;
  branch: string;
  transitNo: string;
  institutionNo: string;
  linkToStandardChargeItem: string;
  attention: string;

  subContacts: Contact[] = [];
  @Ignore
  instanceCreationTime: number;

  //Insurance Broker Contact
  insuranceBrokerType: string;

  //Guarantor type - Mortgage - Province - SK
  mortgageGuarantorType: string;

  // condo corporation
  abbreviatedName;
  selfManagedManagementCompanyType: string;
  feeForCondoStatusCertificate: number = 0;
  feesSubjectToHst: DpBooleanValue = 'Y_n';
  section13ReserveFundStudyDate: any;
  section13CurrentReserveFundAmount: number = 0;
  section15AnnualReserveFundAmount: number = 0;
  asOf: string = 'THE_DATE_HEREOF';
  enterDate: string;
  //By default contact is created without locking it. Make it true if requires to lock it on creation
  lockOnCreate: boolean;

  // status cert info
  position: string;
  trustAccounts: UserTrustAccount[] = [];
  contactCategory: string;
  otherCategoryName: string;

  customerAccountId: number;
  lenderInstitutionId: number;
  mortgageeLenderInstitutionLinkStatus: MortgageeLenderInstitutionLinkStatus;
  lenderNames: string;
  lenderInstitutionName: string;
  dischargeFax: string;
  depositsAccepted: boolean;
  mortgagesRegistered: boolean;

  // This locked should be added into propertyIgnoreList
  locked: boolean;
  closeOtherInfoShutter: boolean;

  //Residence Association
  description: string;
  instrumentNo: string;
  feeAmount: number;
  fiscalYearEnd: string;

  //It will be populated if contact is created as part of adjudication process
  sourceAdjudicationRequestId: number;

  spouses: SpouseRelationship[];

  signersFor: Contact[];

  birthSurname: string;

  gstNumber: string;

  //Flag for passing user's decision from frontend to backend.
  skipLawFirmDuplicateCheck: boolean;
  //Flag for tracking if user has changed law firm name or address inside matter snapshot. It's not used on contact tab.
  lawFirmNameOrAddressChanged: boolean;

  journalNotes: JournalNote[] = [];
  // For contact tab, every contact has one hiddenNotesVisible.
  hiddenNotesVisible: boolean; //Only for UI. The show hidden notes checkbox will not be persisted in the database.

  title: string;
  contactAssociations: ContactAssociation[] = [];
  legacySigner: boolean;

  deceased: boolean;

  duplicateProspectActionTaken: DuplicateProspectActionTakenType; // Only have Keep and replace

  contactIdInfoEnteredBy: number;

  createNewContactCloneWithOptions(contact: Contact, copyOnlySingleMailingAddress: boolean, documentProfileCache: DocumentProfileCache, provinceCode: ProvinceCode) {
    if (contact) {
      this.createNewContactClone(contact);
      if (copyOnlySingleMailingAddress && documentProfileCache && provinceCode) {
        //We can't use contact.mailingAddress, if mailing address is null, it will create a address.
        let mailingAddress: Address
          = Array.isArray(this.address)
          ? this.address.find(item => item.addressTypeCode == AddressTypes.mailing)
          : null;
        this.address = [];
        if (!mailingAddress) {
          mailingAddress = new Address();
          mailingAddress.addressTypeCode = AddressTypes.mailing;
          mailingAddress.country = 'Canada';
          this.setDefaultProvinceValue(documentProfileCache, provinceCode, mailingAddress);
        }
        this.address.push(mailingAddress);

      }
    }
  }

  createNewContactClone(contact: Contact) {

    if (contact) {
      for (let prop in contact) {
        if (contact.hasOwnProperty(prop)) {
          this[ prop ] = contact[ prop ];
        }
      }
      this.id = null;
      //Should not clone proxy flags, as snapshot of proxy shouldn't be marked as proxy
      this.proxyForGlobal = null;
      this.proxyEdited = null;
      this.contactName = new ContactName(contact.contactName);
      this.contactName.id = null;

      this.address = [];
      if (Array.isArray(contact.address)) {
        for (let i: number = 0; i < contact.address.length; i++) {
          this.address[ i ] = new Address(contact.address[ i ]);
          this.address[ i ].id = UUIDUtil.getUUID();
        }
      }

      this.telephone = [];
      if (Array.isArray(contact.telephone)) {
        for (let j: number = 0; j < contact.telephone.length; j++) {
          this.telephone[ j ] = new Telephone(contact.telephone[ j ]);
          this.telephone[ j ].id = null;
        }
      }

      this.updatedByUser = null;

      this.otherContactInformation = [];
      if (Array.isArray(contact.otherContactInformation)) {
        for (let i: number = 0; i < contact.otherContactInformation.length; i++) {
          this.otherContactInformation[ i ] = new OtherContactInformation(contact.otherContactInformation[ i ]);
          this.otherContactInformation[ i ].id = null;
          this.otherContactInformation[ i ].contactId = null;
        }
      }

      this.identificationRecords = [];
      if (Array.isArray(contact.identificationRecords)) {
        for (let i: number = 0; i < contact.identificationRecords.length; i++) {
          this.identificationRecords[ i ] = new IdentificationRecord();
          this.identificationRecords[ i ].createNewIdentificationRecordClone(contact.identificationRecords[ i ]);
          this.identificationRecords[ i ].id = null;
          this.identificationRecords[ i ].contactId = null;
        }
      }

      this.subContacts = [];
      if (Array.isArray(contact.subContacts)) {
        for (let i: number = 0; i < contact.subContacts.length; i++) {
          this.subContacts[ i ] = new Contact();
          this.subContacts[ i ].createNewContactClone(contact.subContacts[ i ]);
        }
      }
      this.contactProvinceCapacities = [];
      if (Array.isArray(contact.contactProvinceCapacities)) {
        contact.contactProvinceCapacities.forEach((cap) => {
          let provinceCapacity = new ContactProvinceCapacity(cap);
          provinceCapacity.id = null;
          provinceCapacity.contactId = null;
          this.contactProvinceCapacities.push(provinceCapacity);
        });
      }

      this.lockedByUser = contact.lockedByUser ? new BasicUserInfo(contact.lockedByUser) : null;

      this.journalNotes = [];
      if (Array.isArray(contact.journalNotes)) {
        contact.journalNotes.forEach((note) => {
          let journalNote = new JournalNote(note);
          journalNote.id = null;
          this.journalNotes.push(journalNote);
        });
      }

      this.contactAssociations = [];
      if (Array.isArray(contact.contactAssociations)) {
        for (let i: number = 0; i < contact.contactAssociations.length; i++) {
          this.contactAssociations[ i ] = new ContactAssociation(contact.contactAssociations[ i ]);
          this.contactAssociations[ i ].id = null;
        }
      }
      this.signersFor = [];
    }
  }

  get solicitors(): Contact[] {
    if (this.subContacts) {
      return this.subContacts.filter((c: Contact) => {
        return (c.contactType === 'SOLICITOR');
      });
    }
    return [];
  }

  addSolicitor(c: Contact): void {
    if (!this.subContacts) {
      this.subContacts = [];
    }
    this.subContacts.push(c);
  }

  deleteSolicitor(c: Contact): void {
    let solicitorIndex: number = _.findIndex(this.subContacts, subContact => subContact === c);
    this.subContacts.splice(solicitorIndex, 1);
  }

  get privateContacts(): PrivateContacts {
    let privateContacts: PrivateContacts = new PrivateContacts(this.subContacts);
    return privateContacts;
  }

  get lockUserName(): string {
    return this.lockedByUser && this.lockedByUser.fullName;
  }

  //Global contacts are owned by system account and cannot be edited by customer account.
  get isGlobal(): boolean {
    return !this.privateFlag;
  }

  //The privateFlag of  Private and Proxy contacts are true.
  get isPrivateExcludeProxy(): boolean {
    return this.privateFlag && !this.proxyForGlobal;
  }

  //Global contacts and proxies which are same as global are owned by system account. Customer account has only read-only access on them.
  get isOwnedBySystemAccount(): boolean {
    return this.isGlobal || this.isProxySameAsGlobal;
  }

  get isProxySameAsGlobal(): boolean {
    return this.proxyForGlobal && !this.proxyEdited;
  }

  //It returns true if contact is proxy of global but has some local changes made by customer account.
  get isProxyCopyOfGlobal(): boolean {
    return this.proxyForGlobal && this.proxyEdited;
  }

  get mailingAddress(): Address {

    // let homePhone: any = _.filter(matterContacts[0].contact.telephone, { 'phoneTypeCode': 'HOME' });
    let mailingAddress: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.mailing;
    });
    //Remove this.isPersonOrOrganization because it broken condo page
    if (!mailingAddress) {
      mailingAddress = new Address();
      mailingAddress.addressTypeCode = AddressTypes.mailing;
      mailingAddress.setAddressHash();
      this.address.push(mailingAddress);
    }
    return mailingAddress;
  }

  get residenceAddress(): Address {
    return _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.residence;
    });
  }

  get primaryAddress(): Address {
    let address: Address = this.address.find((item: Address) => {
      return item.primaryAddress;
    });

    if (!address) {
      address = this.mailingAddress;
    }

    return address;
  }

  set mailingAddress(mailingAddress: Address) {
    mailingAddress.addressTypeCode = AddressTypes.mailing;
    this.address.push(mailingAddress);
  }

  get otherPreviousAddress(): Address {
    return _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.otherPreviousAddress;
    });
  }

  //keep the rule about how to get province default value same with AddressFormComponent
  //TODO rrfactor, using currentMatter is bad.
  setDefaultProvinceValue(documentProfileCache: DocumentProfileCache, matterProvinceCode: string, address: Address) {
    let localProvinceName: string;
    let cachedDefaultProvinceName: string;
    let localObject = {localProvinceName: localProvinceName, cachedDefaultProvinceName: cachedDefaultProvinceName};
    if (documentProfileCache) {
      AddressUtil.setDefaultProvinceValue(address, true, documentProfileCache.cachedDocumentProfile,
        matterProvinceCode, localObject);
    }
  }

  //Add addresses for client if necessary
  addClientAddresses(documentProfileCache: DocumentProfileCache, matterProvinceCode: string): void {
    if (this.address.length === 0) {
      let firstAddress: Address = new Address();
      firstAddress.addressTypeCode = AddressTypes.mailing;
      firstAddress.primaryAddress = true;
      firstAddress.setAddressHash();
      this.setDefaultProvinceValue(documentProfileCache, matterProvinceCode, firstAddress);
      this.address.push(firstAddress);
    }

    //Add additional addresses (up to 3 total)
    let additionalAddress: Address;
    for (let i = this.address.length; i < MAX_CLIENT_ADDRESSES; i++) {
      additionalAddress = new Address();
      additionalAddress.addressTypeCode = null;
      additionalAddress.setAddressHash();
      this.setDefaultProvinceValue(documentProfileCache, matterProvinceCode, additionalAddress);
      this.address.push(additionalAddress);
    }

  }

  get profileAddresses(): Array<Address> {
    return this.address.filter(adr => adr.addressTypeCode !== AddressTypes.clientIdBusiness
      && adr.addressTypeCode !== AddressTypes.serviceAddress
      && adr.addressTypeCode !== AddressTypes.reportAddress);
  }

  get serviceAddress(): Address {

    let serviceAddress: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.serviceAddress;
    });
    if (!serviceAddress && !this.isPersonOrOrganization) {
      serviceAddress = new Address();
      serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
      serviceAddress.setAddressHash();
      this.address.push(serviceAddress);
    }
    return serviceAddress;
  }

  set serviceAddress(serviceAddress: Address) {
    serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
    this.address.push(serviceAddress);
  }

  get reportAddress(): Address {

    let reportAddress: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.reportAddress;
    });
    if (!reportAddress && !this.isPersonOrOrganization) {
      reportAddress = new Address();
      reportAddress.addressTypeCode = AddressTypes.reportAddress;
      reportAddress.setAddressHash();
      this.address.push(reportAddress);
    }
    return reportAddress;
  }

  set reportAddress(reportAddress: Address) {
    reportAddress.addressTypeCode = AddressTypes.reportAddress;
    this.address.push(reportAddress);
  }

  get businessAddress(): Address {

    // let homePhone: any = _.filter(matterContacts[0].contact.telephone, { 'phoneTypeCode': 'HOME' });
    let businessAddress: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.clientIdBusiness;
    });
    if (!businessAddress) {
      businessAddress = new Address();
      businessAddress.addressTypeCode = AddressTypes.clientIdBusiness;
      businessAddress.setAddressHash();
      this.address.push(businessAddress);
    }
    return businessAddress;
  }

  set businessAddress(businessAddress: Address) {
    businessAddress.addressTypeCode = AddressTypes.clientIdBusiness;
    this.address.push(businessAddress);
  }

  removeBusinessAddress(): void {

    let businessAddressId: number = _.findIndex(this.address, address => address.addressTypeCode === AddressTypes.clientIdBusiness);
    if (businessAddressId !== -1) {
      this.address.splice(businessAddressId, 1);
    }

  }

  get lastUpdatedOnForDialog(): string {

    return `Last updated on ${ this.lastUpdatedOn } by ${ this.updatedByUser.fullName }`;
  }

  get lastUpdatedOnMsg(): string {

    return `Contact record last updated on ${ this.lastUpdatedOn }`;
  }

  get lastUpdatedOn(): string {

    if (!this.lastUpdatedTimeStamp) {
      return '';
    }
    const options: any = {month: 'long', day: 'numeric', year: 'numeric'};
    const dt: Date = new Date(this.lastUpdatedTimeStamp);
    const lastUpdatedTimeStamp: string = dt.toLocaleDateString('en-CA', options);

    return lastUpdatedTimeStamp;
  }

  get homePhone(): string {
    return this.getPhone(TelephoneTypes.home).telephoneNumber;
  }

  set homePhone(phone: string) {
    this.getPhone(TelephoneTypes.home).telephoneNumber = phone;

  }

  get cellPhone(): string {
    return this.getPhone(TelephoneTypes.cell).telephoneNumber;
  }

  set cellPhone(phone: string) {
    this.getPhone(TelephoneTypes.cell).telephoneNumber = phone;
  }

  get workPhone(): string {
    return this.getPhone(TelephoneTypes.work).telephoneNumber;
  }

  removeWorkPhone(): void {

    let workPhoneId: number = _.findIndex(this.telephone, (telephone: Telephone) => {
      return telephone.phoneTypeCode === TelephoneTypes.work;
    });
    if (workPhoneId !== -1) {
      this.telephone.splice(workPhoneId, 1);
    }

  }

  set workPhone(phone: string) {
    this.getPhone(TelephoneTypes.work).telephoneNumber = phone;
  }

  get faxPhone(): string {
    return this.getPhone(TelephoneTypes.fax).telephoneNumber;
  }

  set faxPhone(phone: string) {
    this.getPhone(TelephoneTypes.fax).telephoneNumber = phone;
  }

  /**
   * Get the phone (for the specified type) from the list of phones, or create and return a new one if one doesn't already exists.
   * @param type
   * @returns {Telephone}
   */
  getPhone(type: string): Telephone {
    if (!this.telephone) {
      this.telephone = [];
    }

    let phone: Telephone = this.telephone.find((t: Telephone) => t.phoneTypeCode === type);
    if (!phone) {
      phone = new Telephone();
      phone.phoneTypeCode = type;
      this.telephone.push(phone);
    }
    return phone;
  }

  removePhone(type: string): void {
    let phoneIndex: number = _.findIndex(this.telephone, (telephone: Telephone) => {
      return telephone.phoneTypeCode === type;
    });
    if (phoneIndex !== -1) {
      this.telephone.splice(phoneIndex, 1);
    }
  }

  get lastName(): string {
    return this.contactName ? this.contactName.lastName : '';
  }

  set lastName(lastName: string) {
    if (this.contactName) {
      this.contactName.lastName = lastName;
    }
  }

  get firstName(): string {
    return this.contactName ? this.contactName.firstName : '';
  }

  set firstName(firstName: string) {
    if (this.contactName) {
      this.contactName.firstName = firstName;
    }
  }

  get lastOrBusinessName(): string {
    return this.isPerson ? this.lastName : this.organizationName;
  }

  get middleName(): string {
    return this.contactName ? this.contactName.middleName : '';
  }

  set middleName(middleName: string) {
    if (this.contactName) {
      this.contactName.middleName = middleName;
    }
  }

  get initials(): string {
    return this.contactName ? this.contactName.initials : '';
  }

  set initials(initials: string) {
    if (this.contactName) {
      this.contactName.initials = initials;
    }
  }

  get birthDateText(): string {

    if (this.birthDate) {
      return this.birthDate;
    }

    if (this.birthDate == '//') {
      return '';
    }

    return '';
  }

  set birthDateText(text: string) {

    if (text == '//') {
      this.birthDate = '';
      return;
    }

    this.birthDate = text;
  }

  get isPersonOrOrganization(): boolean {
    return this.contactType === 'PERSON' || this.contactType === 'ORGANIZATION';
  }

  get isOrganization(): boolean {
    return this.contactType === 'ORGANIZATION';
  }

  get isMaleFemale(): boolean {
    return this.gender === 'MALE' || this.gender === 'FEMALE';
  }

  get isBlankMaleFemale(): boolean {
    return this.gender === 'QUESTION' || this.gender === 'MALE' || this.gender === 'FEMALE';
  }

  get isPerson(): boolean {
    return this.gender === 'QUESTION' || this.gender === 'MALE' || this.gender === 'FEMALE' || this.gender === 'MALEPOA' || this.gender === 'FEMALEPOA' || this.gender === 'ESTATE';
  }

  //ToDo: this method should be cleaned-up, as it is not checking all the genders.
  get isIndividual(): boolean {

    return this.gender !== 'CORPORATION' && this.gender !== 'OTHERENTITY' && this.gender !== 'ESTATE' && !this.isLawFirm;
  }

  get isCorporationOrOtherEntity(): boolean {

    return this.gender === 'CORPORATION' || this.gender === 'OTHERENTITY';
  }

  get isMalePoaOrFemalePoa(): boolean {

    return this.gender === 'FEMALEPOA' || this.gender === 'MALEPOA';
  }

  get isEstate(): boolean {
    return this.gender === 'ESTATE';
  }

  get isCorporation(): boolean {
    return this.gender === 'CORPORATION';
  }

  get isOtherEntity(): boolean {
    return this.gender === 'OTHERENTITY';
  }

  // get isOtherParty() : boolean {
  //     return this.contactType === 'OTHER_PARTY_PERSON'
  //         || this.contactType === 'OTHER_PARTY_ORGANIZATION';
  // }

  get isLawFirm(): boolean {
    return this.contactType === 'LAW_FIRM';
  }

  get isInsuranceBroker(): boolean {
    return this.contactType === 'INSURANCE_BROKER';
  }

  get isLawClerk(): boolean {
    return this.contactType === 'LAWCLERK';
  }

  get isSolicitor(): boolean {
    return this.contactType === 'SOLICITOR';
  }

  get isMortgagee(): boolean {
    return this.contactType === 'MORTGAGEE';
  }

  get isPrivateContact(): boolean {
    return this.contactType === 'PRIVATE_CONTACT' || this.contactType === 'PRIVATE_CONTACT_ORGANIZATION';
  }

  get isResidenceAssociation(): boolean {
    return this.contactType === 'RESIDENCE_ASSOCIATION';
  }

  get isSurveyor(): boolean {
    return this.contactType === 'SURVEYOR';
  }

  get isPoaEstate0rOtherEntity(): boolean {
    return this.gender === 'FEMALEPOA' || this.gender === 'MALEPOA' || this.gender === 'ESTATE' || this.gender === 'OTHERENTITY';
  }

  get isCondoCorporation(): boolean {
    return this.contactType === 'CONDO_CORPORATION';
  }

  get isManagementCompany(): boolean {
    return this.contactType === 'MANAGEMENT_COMPANY';
  }

  get isMortgageBroker(): boolean {
    return this.contactType === 'MORTGAGE_BROKER';
  }

  get isRealEstateAgent(): boolean {
    return this.contactType === 'REALESTATEAGENT';
  }

  get isRealEstateBroker(): boolean {
    return this.contactType === 'REALESTATEBROKER';
  }

  /**
   * @deprecated - Private lender contact type has been replaced with Client
   * @returns {boolean}
   */
  get isPrivateLender(): boolean {
    return this.contactType === 'PRIVATE_LENDER_PERSON'
      || this.contactType === 'PRIVATE_LENDER_ORGANIZATION'
      || this.contactType === 'PRIVATE_LENDER';
  }

  //Full name should handle the logic of displaying name in different formats based on the contact type.
  //For now it handles guarantor organization & person, going forward the logic for other contact types should be also added here.
  get fullName(): string {
    switch (this.contactType) {
      case ContactTypes.LAW_FIRM:
      case ContactTypes.SURVEYOR:
      case ContactTypes.REALESTATEBROKER:
      case ContactTypes.MORTGAGE_BROKER:
      case ContactTypes.RESIDENCE_ASSOCIATION:
      case ContactTypes.MANAGEMENT_COMPANY:
      case ContactTypes.MORTGAGEE:
      case ContactTypes.CONDO_CORPORATION:
      case ContactTypes.INSURANCE_BROKER:
      case 'TRANSFEROR_ORGANIZATION':
      case 'GUARANTOR_ORGANIZATION':
      case 'PRIVATE_CONTACT_ORGANIZATION':
        return this.organizationName ? this.organizationName : '';
      default:
        if (this.gender === 'CORPORATION' || this.gender === 'OTHERENTITY') {
          return this.organizationName ? this.organizationName : '';
        } else {
          return this.contactName ? this.contactName.fullName : '';
        }
    }
  }

  set fullName(value: string) {
    //Ignore, only here so the field is exposed as a property
  }

  get contactFullNameStartWithFirstName(): string {
    return (this.isCorporation || this.isOtherEntity || this.isGenderDepartment()) ? (this.organizationName ? this.organizationName : '') : this.fullNameStartWithFirstName;
  }

  get fullNameStartWithFirstName(): string {
    let name = (this.contactName.firstName ? this.contactName.firstName + ' ' : '') +
      (this.contactName.middleName ? this.contactName.middleName + ' ' : '') +
      (this.contactName.lastName ? this.contactName.lastName + ' ' : '');
    return name.trim();
  }

  get subContactDisplayName(): string {
    let name = (this.isCorporation || this.isGenderDepartment()) ? this.organizationName : (this.contactName.firstName ? this.contactName.firstName + ' ' : '') +
      (this.contactName.middleName ? this.contactName.middleName + ' ' : '') +
      (this.contactName.lastName ? this.contactName.lastName + ' ' : '');
    return name.trim();
  }

  get surnameLastFullName(): string {
    return this.contactName.surnameLastFullName;
  }

  set surnameLastFullName(value: string) {
    //Ignore, only here so the field is exposed as a property
  }

  get firstLastNames(): string {
    return this.contactName.firstLastNames;
  }

  get firstMiddleNames(): string {
    return this.contactName.firstMiddleNames;
  }

  set firstLastNames(value: string) {
    //Ignore, only here so the field is exposed as a property
  }

  /**
   * The phones text as expected by the UI: // h: 4163445665; w: 9056194567; c: 2894092332
   * @returns {string}
   */
  get phonesText(): string {
    let phones: string = '';
    let homePh: string = this.homePhone;
    let workPh: string = this.workPhone;
    let cellPh: string = this.cellPhone;

    phones = (homePh ? 'h: ' + homePh : '');
    if (workPh) {
      phones = (phones ? phones + '; ' : '') + 'w: ' + workPh;
    }
    if (cellPh) {
      phones = (phones ? phones + '; ' : '') + 'c: ' + cellPh;
    }

    return phones;
  }

  get genericFullName(): string {
    let fullName: string;

    switch (this.gender) {
      case 'ESTATE':
        fullName = 'Estate of ' + this.contactName.surnameLastFullName;
        break;
      case 'CORPORATION':
      case 'OTHERENTITY':
        fullName = this.organizationName;
        break;
      default:
        fullName = this.contactName.fullName;
        break;
    }
    return fullName;
  }

  get genericFullNameWithLastUpper(): string {
    let fullName: string;

    switch (this.gender) {
      case 'ESTATE':
        fullName = this.contactName.fullNameLastInUpper;
        break;
      case 'CORPORATION':
      case 'OTHERENTITY':
        fullName = this.organizationName.toUpperCase();
        break;
      default:
        fullName = this.contactName.fullNameLastInUpper;
        break;
    }
    return fullName;
  }

  get genericName(): string {
    let fullName: string;

    switch (this.gender) {
      case 'ESTATE':
        fullName = 'Estate of ' + this.contactName.surnameLastFullName;
        break;
      case 'CORPORATION':
      case 'OTHERENTITY':
        fullName = this.organizationName;
        break;
      default:
        fullName = this.contactName.surnameLastFullName;
        break;
    }
    return fullName;
  }

  get genericFormatName(): string {
    let fullName: string;

    switch (this.gender) {
      case 'ESTATE':
        fullName = 'Estate of ' + this.contactName.surnameLastFormatFullName;
        break;
      case 'CORPORATION':
        fullName = this.organizationName;
        break;
      case 'OTHERENTITY':
        fullName = Utils.capitalizeTypedName(this.organizationName);
        break;
      default:
        fullName = this.contactName.surnameLastFormatFullName;
        break;
    }
    return fullName;
  }

  get genericFormatNameWithoutCapitalize(): string {
    let fullName: string;

    switch (this.gender) {
      case 'ESTATE':
        fullName = 'Estate of ' + this.contactName.surnameLastFormatFullName;
        break;
      case 'CORPORATION':
        fullName = this.organizationName;
        break;
      case 'OTHERENTITY':
        fullName = this.organizationName;
        break;
      default:
        fullName = this.contactName.surnameLastFormatFullName;
        break;
    }
    return fullName;
  }

  get fullOrBusinessName(): string {
    let fullName: string;
    switch (this.gender) {
      case 'CORPORATION':
      case 'OTHERENTITY':
        fullName = this.organizationName;
        break;
      default:
        fullName = this.contactName.fullName;
        break;
    }
    return fullName;
  }

  get surnameLastFullNameOrBusinessName(): string {
    let fullName: string;
    switch (this.gender) {
      case 'CORPORATION':
      case 'OTHERENTITY':
        fullName = this.organizationName;
        break;
      default:
        fullName = this.contactName.surnameLastFullName;
        break;
    }
    return fullName;
  }

  get lastNameForIndividualOrCorporationName(): string {
    let fullName: string;

    switch (this.gender) {
      case 'ESTATE':
        fullName = 'Estate of ' + this.contactName.surnameLastFullName;
        break;
      case 'CORPORATION':
      case 'OTHERENTITY':
        fullName = this.organizationName;
        break;
      default:
        fullName = this.contactName.lastName;
        break;
    }
    return fullName;
  }

  /**
   * Retrieve the contact name as used in the re line calculation (last name, estate of if needed or organization name for corp or other contacts)
   */
  get reLineName(): string {
    let contactLastName: string = this.contactName && this.contactName.lastName;
    if (contactLastName && this.gender === 'ESTATE') {
      contactLastName = this.getNameOnDocumentForEstate();
    }
    if (this.isContactOrganizationType()) {
      contactLastName = Utils.cutStringValue(this.organizationName, this.reLineNameFieldMaxLength);
    }
    return contactLastName;
  }

  get reLineFirstNameFormat(): string {
    let contactLastName: string = this.contactName && this.contactName.surnameLastFullName;
    if (contactLastName && this.gender === 'ESTATE') {
      contactLastName = this.getNameOnDocumentForEstate();
    }
    return contactLastName ? contactLastName : this.organizationName;
  }

  private getNameOnDocumentForEstate(): string {
    var nameOnDocument: string[] = [];
    nameOnDocument.push('Estate of');
    if (this.contactName.firstName) {
      nameOnDocument.push(this.contactName.firstName);
    }
    if (this.contactName.middleName) {
      nameOnDocument.push(this.contactName.middleName);
    }
    if (this.contactName.lastName) {
      nameOnDocument.push(this.contactName.lastName);
    }
    return nameOnDocument.join(' ');
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);

  }

  // copyOtherContactInformation(source : OtherContactInformation[]) : OtherContactInformation[] {
  //     if(Array.isArray(source)) {
  //         return source.map((item : OtherContactInformation) => new OtherContactInformation(item));
  //     } else {
  //         return undefined;
  //     }
  // }

  //@deprecated: use isStaleContact as it handles the different type of contacts
  isStale(source: Contact): boolean {
    return this.lastSyncedFromSource < source.lastUpdatedTimeStamp && !this.equals(source);
  }

  //@deprecated: use isContactStaleFlagClearedWithoutUpdatingMatter as it handles the different type of contacts
  isClearFlagWithoutUpdatingMatter(source: Contact): boolean {
    //Before saving the matter  to get snapshot contact ID, we don't need this condition
    //If it creates snapshot without saving, this.id should be null
    return (this.lastSyncedFromSource == source.lastUpdatedTimeStamp) && !this.equals(source);
  }

  //This is a common method for checking the contact is stale or not. Internally it compares the contact based on contact type
  isStaleContact(source: Contact): boolean {
    //If stale flag is cleared or snapshot itself is changed then it's not stale
    if (this.wasContactReset(source) || this.isDirty) {
      return false;
    }

    //Otherwise, comparing the data & based on result marking it as stale
    return this.isContactDataChanged(source);
  }

  //This is a common method for comparing the data b/w two contacts. The comparision method differs based on contact type
  isContactDataChanged(source: Contact): boolean {
    //Checking if snapshot data is different than source contact based on contact type. Other contact types should be added in this method
    if (this.isMortgagee) {
      return this.isMortgageeDataDifferent(source);
    } else if (this.isCondoCorporation) {
      return this.isCondoCorporationDataChanged(source);
    } else if (this.isManagementCompany) {
      return this.isManagementDataDifferent(source);
    } else if (this.isPrivateContact) {
      return this.isPrivateContactDataChanged(source);
    } else if (this.isRealEstateAgent) {
      return this.isRealEstateAgentDataChanged(source);
    } else if (this.isRealEstateBroker) {
      return this.isRealEstateBrokerDataChanged(source);
    } else if (this.isInsuranceBroker) {
      return !this.fireInsuranceContactEquals(source);
    } else if (this.isSolicitor) {
      return !this.solicitorContactEquals(source);
    } else if (this.isLawFirm) {
      return !this.lawFirmContactEquals(source);
    } else if (this.isSurveyor) {
      return this.isSurveyorsDataChanged(source);
    } else if (this.isMortgageBroker) {
      return this.isMortgageBrokerDataChanged(source);
    } else if (this.isOrganization && source.isMortgagee) {
      return this.isPrivateLenderMortgageeDataDifferent(source);
    } else if (source.contactAssociations && source.contactAssociations.some(item => item.associatedContact && !!item.associatedContact.legacySigner)) {
      let currentContact = new Contact(this);
      let sourceContact = new Contact(source);
      sourceContact.contactAssociations = sourceContact.contactAssociations.filter(item => item.associatedContact && !item.associatedContact.legacySigner);
      currentContact.contactAssociations = currentContact.contactAssociations.filter(item => item.associatedContact && !item.associatedContact.legacySigner);
      return !currentContact.equals(sourceContact);
    } else {
      return !this.equals(source);
    }
  }

  isContactStaleFlagClearedWithoutUpdatingMatter(source: Contact): boolean {
    //if the last sync time is same but data is different then it means flag was cleared without updating the matter
    return (this.lastSyncedFromSource === source.lastUpdatedTimeStamp) && this.isContactDataChanged(source);
  }

  wasContactReset(source: Contact): boolean {
    return this.lastUpdatedTimeStamp >= source.lastUpdatedTimeStamp && this.lastSyncedFromSource === source.lastUpdatedTimeStamp;
  }

  isStalePrivateLender(source: Contact): boolean {
    return this.lastSyncedFromSource < source.lastUpdatedTimeStamp && !this.privateLenderContactEquals(source);
  }

  privateLenderContactEquals(source: Contact): boolean {
    if (this.isOrganization && source.isMortgagee) {
      return !this.isPrivateLenderMortgageeDataDifferent(source);
    } else {
      let ret: boolean;
      let isAttentionNoEqual: boolean;

      //If the string is '' , null or undefined , it should be same.
      // There is not attention because it private Lender is changed to client
      // if(!this.attention && !source.attention) {
      //     isAttentionNoEqual = false;
      // } else {
      //     isAttentionNoEqual = this.attention != source.attention;
      // }

      const commonCheck: boolean = (this.gender !== source.gender)
        || (this.isAddressPresent(this.address, AddressTypes.mailing) && this.isAddressPresent(source.address, AddressTypes.mailing) ? !this.mailingAddress.equals(source.mailingAddress) : false)
        || isAttentionNoEqual
        || this.faxPhone !== source.faxPhone
        || this.workPhone !== source.workPhone
        || this.email !== source.email
        || this.dear !== source.dear;

      if (this.gender === 'OTHERENTITY' || this.gender === 'CORPORATION') {
        ret = commonCheck
          || this.organizationName !== source.organizationName
          || this.additionalName1 !== source.additionalName1
          || this.additionalName2 !== source.additionalName2
          || this.titleOfOfficeHeld1 !== source.titleOfOfficeHeld1
          || this.titleOfOfficeHeld2 !== source.titleOfOfficeHeld2;

      } else {
        ret = commonCheck
          || !this.contactName.equals(source.contactName);
      }
      return !ret;
    }
  }

  isAddressPresent(addresses: Address[], addressTypeCode: string): boolean {
    let mailingAddress = addresses.find(address => address.addressTypeCode == addressTypeCode);
    return !!mailingAddress;
  }

  isPrivateLenderClearFlagWithoutUpdatingMatter(source: Contact): boolean {
    //If it creates snapshot without saving, this.id should be null
    return (this.lastSyncedFromSource == source.lastUpdatedTimeStamp) && !this.privateLenderContactEquals(source);
  }

  isStaleLawFirm(source: Contact): boolean {
    return this.lastSyncedFromSource < source.lastUpdatedTimeStamp && !this.lawFirmContactEquals(source);
  }

  //Use stringDifferent to avoid, '' is not equal null
  lawFirmContactEquals(source: Contact): boolean {
    return this.mailingAddress.equals(source.mailingAddress)
      && this.organizationName == source.organizationName
      && this.legalFirmName == source.legalFirmName
      && this.barristerSolicitor == source.barristerSolicitor
      && !Utils.stringDifferent(this.workPhone, source.workPhone) && !Utils.stringDifferent(this.cellPhone, source.cellPhone)
      && !Utils.stringDifferent(this.faxPhone, source.faxPhone) && !Utils.stringDifferent(this.email, source.email)
      && this.activeFlag == source.activeFlag;

  }

  isLawFirmClearFlagWithoutUpdatingMatter(source: Contact): boolean {
    //If it creates snapshot without saving, this.id should be null
    return (this.lastSyncedFromSource == source.lastUpdatedTimeStamp) && !this.lawFirmContactEquals(source);
  }

  isStaleSolicitor(source: Contact): boolean {
    return this.lastSyncedFromSource < source.lastUpdatedTimeStamp && !this.solicitorContactEquals(source);
  }

  solicitorContactEquals(source: Contact): boolean {
    //Back end global solicitor is 'CUSTOMER_ACCOUNT'. However UI is 'FIRM_MAILING' instead of 'CUSTOMER_ACCOUNT'.
    if ((this.mailingAddress.sameAsAddressTypeCode === AddressTypes.firmMailing || this.mailingAddress.sameAsAddressTypeCode === AddressTypes.customerAccount)
      && (source.mailingAddress.sameAsAddressTypeCode === AddressTypes.firmMailing || source.mailingAddress.sameAsAddressTypeCode === AddressTypes.customerAccount)) {
      const mailingAddressSameAsAddressTypeCode: string = this.mailingAddress.sameAsAddressTypeCode;
      this.mailingAddress.sameAsAddressTypeCode = source.mailingAddress.sameAsAddressTypeCode;
      const ret: boolean = this.solicitorCoreContactEquals(source);
      this.mailingAddress.sameAsAddressTypeCode = mailingAddressSameAsAddressTypeCode;
      return ret;
    } else {
      return this.solicitorCoreContactEquals(source);
    }
  }

  solicitorCoreContactEquals(source: Contact): boolean {
    return this.mailingAddress.equals(source.mailingAddress)
      && this.contactName.equals(source.contactName)
      && !Utils.stringDifferent(this.workPhone, source.workPhone) && !Utils.stringDifferent(this.cellPhone, source.cellPhone)
      && !Utils.stringDifferent(this.faxPhone, source.faxPhone) && !Utils.stringDifferent(this.email, source.email)
      && this.activeFlag == source.activeFlag;

  }

  isSolicitorClearFlagWithoutUpdatingMatter(source: Contact): boolean {
    //If it creates snapshot without saving, this.id should be null
    return (this.lastSyncedFromSource == source.lastUpdatedTimeStamp) && !this.solicitorContactEquals(source);
  }

  isPrivateContactDataChanged(source: Contact): boolean {
    //If in organizationName, one is '' string, the other is null or undefined, it will show is not equal
    // It is better to clean up set '' as null in the future
    // Now if organizationName is '', null or undefined, it will be treat as equal
    return Utils.stringDifferent(this.firstName, source.firstName) || this.lastName != source.lastName || Utils.stringDifferent(this.middleName, source.middleName)
      || (this.organizationName !== source.organizationName && (!!this.organizationName || !!source.organizationName))
      || (this.dear != source.dear) || Utils.stringDifferent(this.homePhone, source.homePhone)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isCondoCorporationDataChanged(source: Contact): boolean {
    if (this.organizationName !== source.organizationName) {
      return true;
    }

    if (this.abbreviatedName !== source.abbreviatedName) {
      return true;
    }

    if (this.selfManagedManagementCompanyType !== source.selfManagedManagementCompanyType) {
      return true;
    }

    if (this.sourceParentOrganizationId != source.organizationId) {
      return true;
    }

    if (this.feeForCondoStatusCertificate !== source.feeForCondoStatusCertificate) {
      return true;
    }

    if (this.feesSubjectToHst !== source.feesSubjectToHst) {
      return true;
    }

    return !this.mailingAddress.equals(source.mailingAddress)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);

  }

  isManagementDataDifferent(source: Contact): boolean {
    return (this.organizationName != source.organizationName) || !this.mailingAddress.equals(source.mailingAddress)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isRealEstateAgentDataChanged(source: Contact): boolean {
    if (this.purchaserSideAgent) {
      //The Real Estate Agent snapshot of Matter Opening  keeps the source parentOrganizationName in sourceParentOrganizationName,
      // that's why it is used to compare with source.parentOrganizationName
      return this.isRealEstateAgentCoreDataChanged(source) || (this.sourceParentOrganizationName != source.parentOrganizationName);
    } else {
      return this.isRealEstateAgentCoreDataChanged(source);
    }

  }

  isRealEstateAgentCoreDataChanged(source: Contact): boolean {
    //snapshot keeps the source organization id in sourceParentOrganizationId, that's why it is used to compare with source.organizationId
    return (this.lastName != source.lastName) || Utils.stringDifferent(this.firstName, source.firstName)
      || Utils.stringDifferent(this.middleName, source.middleName) || (this.sourceParentOrganizationId != source.organizationId)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isRealEstateBrokerDataChanged(source: Contact): boolean {
    return (this.organizationName != source.organizationName) || !this.mailingAddress.equals(source.mailingAddress)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isMortgageeDataDifferent(source: Contact): boolean {
    return (this.organizationName != source.organizationName) || !this.mailingAddress.equals(source.mailingAddress)
      || !this.serviceAddress.equals(source.serviceAddress) || !this.reportAddress.equals(source.reportAddress)
      || (Utils.stringDifferent(this.transitNo, source.transitNo)) || this.institutionNo != source.institutionNo
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || (this.dischargeFax != source.dischargeFax)
      || (this.branch != source.branch)
      || (this.linkToStandardChargeItem != source.linkToStandardChargeItem)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email)
      || (this.lenderInstitutionId != source.lenderInstitutionId);
  }

  isMortgageBrokerDataChanged(source: Contact): boolean {
    return (this.organizationName != source.organizationName) || !this.mailingAddress.equals(source.mailingAddress)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isSurveyorsDataChanged(source: Contact): boolean {
    return (this.organizationName != source.organizationName) || !this.mailingAddress.equals(source.mailingAddress)
      || Utils.stringDifferent(this.workPhone, source.workPhone) || Utils.stringDifferent(this.cellPhone, source.cellPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isPrivateLenderMortgageeDataDifferent(source: Contact): boolean {
    return (this.organizationName != source.organizationName) || !this.mailingAddress.equals(source.mailingAddress)
      || !this.serviceAddress.equals(source.serviceAddress) || !this.reportAddress.equals(source.reportAddress)
      || Utils.stringDifferent(this.workPhone, source.workPhone)
      || Utils.stringDifferent(this.faxPhone, source.faxPhone) || Utils.stringDifferent(this.email, source.email);
  }

  isStaleOrgNameOrMailingAddress(source: Contact): boolean {
    if (this.wasContactReset(source)) {
      return false;
    }

    return !this.mailingAddress.equals(source.mailingAddress) || this.organizationName !== source.organizationName;
  }

  isStaleFireInsurance(source: Contact): boolean {
    if (this.wasContactReset(source)) {
      return false;
    }

    return !this.fireInsuranceContactEquals(source);
  }

  isFireInsuranceClearFlagWithoutUpdatingMatter(source: Contact): boolean {
    //Before saving the matter  to get snapshot contact ID, we don't need this condition
    // if(this.fireInsuranceContactEquals(source)) {
    //     console.log('fireInsuranceContactEquals equal!!!')
    // } else {
    //     console.log('fireInsuranceContactEquals NOT equal!!!')
    // }
    //If it creates snapshot without saving, this.id should be null
    return (this.lastSyncedFromSource == source.lastUpdatedTimeStamp) && !this.fireInsuranceContactEquals(source);
  }

  fireInsuranceContactEquals(source: Contact): boolean {
    return this.mailingAddress.equals(source.mailingAddress)
      && this.insuranceBrokerType == source.insuranceBrokerType
      && this.organizationName == source.organizationName
      && !Utils.stringDifferent(this.workPhone, source.workPhone) && !Utils.stringDifferent(this.cellPhone, source.cellPhone)
      && !Utils.stringDifferent(this.faxPhone, source.faxPhone) && !Utils.stringDifferent(this.email, source.email);
  }

  // equals(other : Contact) : boolean {
  // if(JSON.stringify(this, this.replacer) !== JSON.stringify(other, this.replacer)) {
  //     console.log('contact.equal(): this:', JSON.stringify(this, this.replacer));
  //     console.log('contact.equal(): other:', JSON.stringify(other, this.replacer));
  // }
  //     return JSON.stringify(this, this.replacer) === JSON.stringify(other, this.replacer);
  // }

  //This method is to list the properties which should be ignored in stringify

  replacer(key, val): any {
    if (key !== 'isDirty' && key !== 'isValid'
      // && key !== "provinceName" // Temporarily remove it
      // && key !== "provinceCode" // Temporarily remove it
      && key !== 'instanceCreationTime' && key !== 'identificationRecordId'
      && key !== 'lastSyncedFromSource' && key !== 'contactId'
      && key !== 'lockedTimeStamp'
      && key !== 'proxyForGlobal'
      && key !== 'proxyEdited'
      && key !== 'lockedByUser' && key !== 'instanceType'
      && key !== 'updatedByUser' && key !== 'lastUpdatedTimeStamp'
      && key !== 'snapshotFlag' && key !== 'instanceCreationTime'
      && key !== 'errorMessage' && key !== 'id'
      && key !== 'addressHash' // Backend doesn't add addressHash, UI will add it
      && key !== 'isAddressValid' && key !== 'identifier'
      && key !== 'signingOfficerLocal1' && key !== 'signingOfficerLocal2'
      && key !== 'attorneyNameLocal1' && key !== 'attorneyNameLocal2'
      && key !== 'estateTrusteeLocal1' && key !== 'estateTrusteeLocal2') {
      return val;
    }
  }

  //Commenting the equals method of contact that compares each property one-by-one, instead of that using JSON stringify.
  /* equals(other : Contact) : boolean {
     if(!other) {
     return false;
     }

     let ret : boolean = true;

     ret = this.lastUpdatedTimeStamp === other.lastUpdatedTimeStamp
     && this.gender === other.gender
     && this.canadianResidentFlag === other.canadianResidentFlag
     && this.email === other.email
     && this.nameOnDocuments === other.nameOnDocuments
     && this.envelopeSalutationLine1 === other.envelopeSalutationLine1
     && this.envelopeSalutationLine2 === other.envelopeSalutationLine2
     && this.dear === other.dear
     && this.letterSalutation === other.letterSalutation
     && this.faxPhone === other.faxPhone
     && this.mailingAddress
     && this.mailingAddress.equals(other.mailingAddress)
     && this.businessAddress
     && this.businessAddress.equals(other.businessAddress)
     && this.workPhone === other.workPhone
     && this.cellPhone === other.cellPhone
     && this.homePhone === other.homePhone
     && this.alternateName === other.alternateName
     && this.branch === other.branch
     && this.transitNo === other.transitNo
     && this.privateFlag === other.privateFlag
     && this.linkToStandardChargeItem === other.linkToStandardChargeItem
     && ((!this.otherContactInformation && !other.otherContactInformation)
     || (this.otherContactInformation && other.otherContactInformation
     && this.otherContactInformation.every((item : OtherContactInformation, index : number) => {
     return item && other.otherContactInformation[index] && item.equals(other.otherContactInformation[index]);
     })
     )
     )
     && ((!this.identificationRecords && !other.identificationRecords)
     || (this.identificationRecords && other.identificationRecords
     && this.identificationRecords.every((item : IdentificationRecord, index : number) => {
     return item && other.identificationRecords[index] && item.equals(other.identificationRecords[index]);
     })
     )
     );

     if(!ret) {
     return ret;
     }

     //Comparing private contact data
     if(JSON.stringify(this.privateContacts) !== JSON.stringify(other.privateContacts)) {
     return false;
     }

     switch(this.gender) {
     case 'QUESTION':
     case 'MALE':
     case 'FEMALE':
     ret = this.birthDate === other.birthDate
     && this.contactName.equals(other.contactName);
     break;

     case 'MALEPOA':
     case 'FEMALEPOA':
     ret = this.birthDate === other.birthDate
     && this.poaDate === other.poaDate
     && this.contactName.equals(other.contactName)
     && this.additionalName1 === other.additionalName1
     && this.additionalName2 === other.additionalName2
     && this.instrumentNumber === other.instrumentNumber;
     break;

     case 'CORPORATION':
     ret = this.organizationName === other.organizationName
     && this.additionalName1 === other.additionalName1
     && this.additionalName2 === other.additionalName2
     && this.titleOfOfficeHeld1 === other.titleOfOfficeHeld1
     && this.titleOfOfficeHeld2 === other.titleOfOfficeHeld2
     && this.executingUnderSealFlag === other.executingUnderSealFlag;

     break;

     case 'ESTATE':
     ret = this.contactName.equals(other.contactName)
     && this.additionalName1 === other.additionalName1
     && this.additionalName2 === other.additionalName2
     && this.estateStatusFlag === other.estateStatusFlag
     && this.deceasedGender === other.deceasedGender;

     break;

     case 'OTHERENTITY':
     ret = this.organizationName === other.organizationName
     && this.additionalName1 === other.additionalName1
     && this.additionalName2 === other.additionalName2
     && this.titleOfOfficeHeld1 === other.titleOfOfficeHeld1
     && this.titleOfOfficeHeld2 === other.titleOfOfficeHeld2;

     break;

     default:
     break;
     }

     return ret;
     // return this.lastUpdatedTimeStamp === other.lastUpdatedTimeStamp
     //     && this.gender === other.gender
     //     && this.birthDate === other.birthDate
     //     && this.canadianResidentFlag === other.canadianResidentFlag
     //     && this.email === other.email
     //     && this.birthDate === other.birthDate
     //     && this.nameOnDocuments === other.nameOnDocuments
     //     && this.envelopeSalutationLine1 === other.envelopeSalutationLine1
     //     && this.envelopeSalutationLine2 === other.envelopeSalutationLine2
     //     && this.dear === other.dear
     //     && this.letterSalutation === other.letterSalutation
     //     && this.faxPhone === other.faxPhone
     //     && this.contactName.equals(other.contactName)
     //     && this.mailingAddress
     //     && this.mailingAddress.equals(other.mailingAddress)
     //     && this.workPhone === other.workPhone
     //     && this.cellPhone === other.cellPhone
     //     && this.homePhone === other.homePhone;
     }*/

  // { label: '??????', value: 'QUESTION' },
  // { label: 'Male', value: 'MALE' },
  // { label: 'Female', value: 'FEMALE' },
  // { label: 'Corporation', value: 'CORPORATION' },
  // { label: 'Male (Under power of attorney)', value: 'MALEPOA' },
  // { label: 'Female (Under power of attorney)', value: 'FEMALEPOA' },
  // { label: 'Estate', value: 'ESTATE' },
  // { label: 'Other Entity', value: 'OTHERENTITY' }

  isContactNameNotSpecified(): boolean {
    if ((this.gender == 'CORPORATION' || this.gender == 'OTHERENTITY') && !this.organizationName) {
      return true;
    } else if (this.gender != 'CORPORATION' && this.gender != 'OTHERENTITY' && (!this.contactName
      || (this.contactName && !this.contactName.lastName))) {
      return true;
    } else {
      return false;
    }

  }

  parseAddress(): void {
    //parse the mailing address
    // this.address.forEach(address => )
  }

  cleanupRedundantData(): void {
    switch (this.gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
        // organizationName, titleOfOfficeHeld1 and titleOfOfficeHeld2 Only belong to CORPORATION and OTHERENTITY
        this.organizationName = undefined;
        this.titleOfOfficeHeld1 = undefined;
        this.titleOfOfficeHeld2 = undefined;

        // additionalName1 and additionalName2 include the following
        // signingOfficer1, signingOfficer2, estateTrustee1, estateTrustee2, attorneyName1 and attorneyName2
        // Only belong to CORPORATION, MALEPOA, FEMALEPOA, Estate or OTHERENTITY
        this.additionalName1 = undefined;
        this.additionalName2 = undefined;
        this.signingOfficerLocal1 = undefined;
        this.signingOfficerLocal2 = undefined;
        this.estateTrusteeLocal1 = undefined;
        this.estateTrusteeLocal2 = undefined;
        this.attorneyNameLocal1 = undefined;
        this.attorneyNameLocal2 = undefined;

        // poa Only belong to MALEPOA and FEMALEPOA
        this.poaDateString = undefined;
        this.poaDate = undefined;

        // executingUnderSealFlag only belong to CORPORATION
        this.executingUnderSealFlag = undefined;

        // instrumentNumber only belong to MALEPOA and FEMALEPOA
        this.instrumentNumber = undefined;

        // instrumeestateStatusFlagntNumber only belong to ESTATE
        this.estateStatusFlag = undefined;
        this.deceasedGender = undefined;

        break;

      case 'MALEPOA':
      case 'FEMALEPOA':
        // organizationName, signingOfficer1, signingOfficer2, titleOfOfficeHeld1 and titleOfOfficeHeld2
        // Only belong to CORPORATION and OTHERENTITY
        this.organizationName = undefined;
        this.signingOfficerLocal1 = undefined;
        this.signingOfficerLocal2 = undefined;
        this.titleOfOfficeHeld1 = undefined;
        this.titleOfOfficeHeld2 = undefined;

        // estateTrustee1, estateTrustee2 only belong to ESTATE
        this.estateTrusteeLocal1 = undefined;
        this.estateTrusteeLocal2 = undefined;

        // executingUnderSealFlag only belong to CORPORATION
        this.executingUnderSealFlag = undefined;

        // instrumeestateStatusFlagntNumber only belong to ESTATE
        this.estateStatusFlag = undefined;
        this.deceasedGender = undefined;

        break;

      case 'CORPORATION':
        // estateTrustee1, estateTrustee2 only belong to ESTATE
        this.estateTrusteeLocal1 = undefined;
        this.estateTrusteeLocal2 = undefined;

        // attorneyName1, attorneyName1 only belong to MALEPOA or FEMALEPOA
        this.attorneyNameLocal1 = undefined;
        this.attorneyNameLocal2 = undefined;

        // birthDate belong to QUESTION, MALE, FEMALE, MALEPOA and FEMALEPOA
        this.birthDate = undefined;

        // poa Only belong to MALEPOA and FEMALEPOA
        this.poaDateString = undefined;
        this.poaDate = undefined;

        // firstName, middleName and last Name does NOT belong to CORPORATION and OTHERENTITY
        this.contactName.firstName = undefined;
        this.contactName.middleName = undefined;
        this.contactName.lastName = undefined;

        // instrumentNumber only belong to MALEPOA and FEMALEPOA
        this.instrumentNumber = undefined;

        // instrumeestateStatusFlagntNumber only belong to ESTATE
        this.estateStatusFlag = undefined;
        this.deceasedGender = undefined;

        if (!this.executingUnderSealFlag) {
          this.executingUnderSealFlag = '???';
        }

        break;

      case 'ESTATE':
        // birthDate belong to QUESTION, MALE, FEMALE, MALEPOA and FEMALEPOA
        this.birthDate = undefined;

        // organizationName, signingOfficer1, signingOfficer2, titleOfOfficeHeld1 and titleOfOfficeHeld2
        // Only belong to CORPORATION and OTHERENTITY
        this.organizationName = undefined;
        this.signingOfficerLocal1 = undefined;
        this.signingOfficerLocal2 = undefined;
        this.titleOfOfficeHeld1 = undefined;
        this.titleOfOfficeHeld2 = undefined;

        // attorneyName1, attorneyName1 only belong to MALEPOA or FEMALEPOA
        this.attorneyNameLocal1 = undefined;
        this.attorneyNameLocal2 = undefined;

        // poa Only belong to MALEPOA and FEMALEPOA
        this.poaDateString = undefined;
        this.poaDate = undefined;

        // executingUnderSealFlag only belong to CORPORATION
        this.executingUnderSealFlag = undefined;

        // instrumentNumber only belong to MALEPOA and FEMALEPOA
        this.instrumentNumber = undefined;

        break;

      case 'OTHERENTITY':
        // estateTrustee1, estateTrustee2 only belong to ESTATE
        this.estateTrusteeLocal1 = undefined;
        this.estateTrusteeLocal2 = undefined;

        // attorneyName1, attorneyName1 only belong to MALEPOA or FEMALEPOA
        this.attorneyNameLocal1 = undefined;
        this.attorneyNameLocal2 = undefined;

        // birthDate belong to QUESTION, MALE, FEMALE, MALEPOA and FEMALEPOA
        this.birthDate = undefined;

        // poa Only belong to MALEPOA and FEMALEPOA
        this.poaDateString = undefined;
        this.poaDate = undefined;

        // firstName, middleName and last Name does NOT belong to CORPORATION and OTHERENTITY
        this.contactName.firstName = undefined;
        this.contactName.middleName = undefined;
        this.contactName.lastName = undefined;

        // executingUnderSealFlag only belong to Corporation
        this.executingUnderSealFlag = undefined;

        // instrumentNumber only belong to MALEPOA and FEMALEPOA
        this.instrumentNumber = undefined;

        // instrumeestateStatusFlagntNumber only belong to ESTATE
        this.estateStatusFlag = undefined;
        this.deceasedGender = undefined;

        break;

      default:
        break;
    }
    this.cleanupCondoDataBeforeSave();
    this.cleanOtherCategoryName();
  }

  cleanOtherCategoryName() {
    if (this.contactCategory !== 'OTHER_SPECIFY') {
      this.otherCategoryName = null;
    }
  }

  cleanupOfferorContactData(): void {
    switch (this.gender) {
      case 'OTHERENTITY':
        this.organizationName = undefined;
        this.titleOfOfficeHeld1 = undefined;
        this.titleOfOfficeHeld2 = undefined;
        this.additionalName1 = undefined;
        this.additionalName2 = undefined;
      default:
        this.contactName.firstName = undefined;
        this.contactName.middleName = undefined;
        this.contactName.lastName = undefined;
    }
  }

  // public updateFrom = (source: Contact) => {

  //     this.lastUpdatedTimeStamp = source.lastUpdatedTimeStamp;
  //     this.gender = source.gender;
  //     this.birthDate = source.birthDate;
  //     this.canadianResidentFlag = source.canadianResidentFlag;
  //     this.email = source.email;
  //     this.birthDate = source.birthDate;
  //     this.contactName.updateFrom(source.contactName);
  //     this.mailingAddress.updateFrom(source.mailingAddress);
  //     this.workPhone.telephoneNumber = source.workPhone.telephoneNumber;
  //     this.cellPhone.telephoneNumber = source.cellPhone.telephoneNumber;
  //     this.homePhone.telephoneNumber = source.homePhone.telephoneNumber;
  // }

  public update(source: Contact) {
    this.lastSyncedFromSource = source.lastUpdatedTimeStamp;
    this.lastUpdatedTimeStamp = source.lastUpdatedTimeStamp;
    this.gender = source.gender;
    this.localGender = source.localGender;
    this.displayName = source.displayName;
    this.canadianResidentFlag = source.canadianResidentFlag;
    this.residentStatus = source.residentStatus;
    this.email = source.email;
    this.enteredOn = source.enteredOn;
    this.dear = source.dear;
    this.envelopeSalutationLine1 = source.envelopeSalutationLine1;
    this.envelopeSalutationLine2 = source.envelopeSalutationLine2;
    this.transitNo = source.transitNo;
    this.institutionNo = source.institutionNo;
    this.idInfoEnteredBy = source.idInfoEnteredBy;
    this.contactIdInfoEnteredBy = source.contactIdInfoEnteredBy;
    this.faxPhone = source.faxPhone;
    this.homePhone = source.homePhone;
    this.cellPhone = source.cellPhone;
    this.workPhone = source.workPhone;
    this.letterSalutation = source.letterSalutation;
    this.nameOnDocuments = source.nameOnDocuments;
    this.sourceOfElectronicVersion = source.sourceOfElectronicVersion;
    this.typeOfBusinessActivity = source.typeOfBusinessActivity;
    this.label = source.label;
    this.note = source.note;
    this.feesSubjectToHst = source.feesSubjectToHst;
    this.feeForCondoStatusCertificate = source.feeForCondoStatusCertificate;
    this.contactCategory = source.contactCategory;
    this.otherCategoryName = source.otherCategoryName;
    this.attention = source.attention;
    this.activeFlag = source.activeFlag;
    this.notaryFlag = source.notaryFlag;
    this.dischargeFax = source.dischargeFax;
    this.depositsAccepted = source.depositsAccepted;
    this.mortgagesRegistered = source.mortgagesRegistered;

    this.section13ReserveFundStudyDate = source.section13ReserveFundStudyDate;
    this.section13CurrentReserveFundAmount = source.section13CurrentReserveFundAmount;
    this.section15AnnualReserveFundAmount = source.section15AnnualReserveFundAmount;
    this.asOf = source.asOf;
    this.enterDate = source.enterDate;

    this.insuranceBrokerType = source.insuranceBrokerType;
    // law firm need to update organizationName
    this.organizationName = source.organizationName;
    this.legalFirmName = source.legalFirmName;
    this.barristerSolicitor = source.barristerSolicitor;

    this.birthSurname = source.birthSurname;
    this.gstNumber = source.gstNumber;

    this.deceased = source.deceased;

    // Do not change id
    this.otherContactInformation = [];
    if (Array.isArray(source.otherContactInformation)) {
      for (let i = 0; i < source.otherContactInformation.length; i++) {
        this.otherContactInformation[ i ] = new OtherContactInformation();
        this.otherContactInformation[ i ].fieldName = source.otherContactInformation[ i ].fieldName;
        this.otherContactInformation[ i ].value = source.otherContactInformation[ i ].value;
      }
    }

    this.contactProvinceCapacities = [];
    if (Array.isArray(source.contactProvinceCapacities)) {
      source.contactProvinceCapacities.forEach((cap) => {
        let contactProvCapacity = new ContactProvinceCapacity(cap);
        contactProvCapacity.provinceCode = cap.provinceCode;
        contactProvCapacity.provinceCapacity = cap.provinceCapacity;
        contactProvCapacity.insertNameAddressToRegistrationDocument = cap.insertNameAddressToRegistrationDocument;
        contactProvCapacity.commissionExpiry = cap.commissionExpiry;
        this.contactProvinceCapacities.push(contactProvCapacity);
      });
    }

    this.contactAssociations = [];
    if (Array.isArray(source.contactAssociations)) {
      for (let i: number = 0; i < source.contactAssociations.length; i++) {
        this.contactAssociations[ i ] = new ContactAssociation(source.contactAssociations[ i ]);
        this.contactAssociations[ i ].id = null;
      }
    }

    switch (source.gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
        this.birthDate = source.birthDate;
        this.contactName.update(source.contactName);
        break;

      case 'MALEPOA':
      case 'FEMALEPOA':
        this.contactName.update(source.contactName);
        this.attorneyName1 = source.attorneyName1;
        this.attorneyName2 = source.attorneyName2;
        this.birthDate = source.birthDate;
        this.poaDate = source.poaDate;
        this.instrumentNumber = source.instrumentNumber;
        this.additionalEmail1 = source.additionalEmail1;
        this.additionalEmail2 = source.additionalEmail2;

        break;

      case 'CORPORATION':
        this.organizationName = source.organizationName;
        this.signingOfficer1 = source.signingOfficer1;
        this.signingOfficer2 = source.signingOfficer2;
        this.titleOfOfficeHeld1 = source.titleOfOfficeHeld1;
        this.titleOfOfficeHeld2 = source.titleOfOfficeHeld2;
        this.executingUnderSealFlag = source.executingUnderSealFlag;
        this.additionalEmail1 = source.additionalEmail1;
        this.additionalEmail2 = source.additionalEmail2;

        break;

      case 'ESTATE':
        this.contactName.update(source.contactName);
        this.estateTrustee1 = source.estateTrustee1;
        this.estateTrustee2 = source.estateTrustee2;
        this.deceasedGender = source.deceasedGender;
        this.estateStatusFlag = source.estateStatusFlag;
        this.additionalEmail1 = source.additionalEmail1;
        this.additionalEmail2 = source.additionalEmail2;

        break;

      case 'OTHERENTITY':
        this.organizationName = source.organizationName;
        this.additionalName1 = source.additionalName1;
        this.additionalName2 = source.additionalName2;
        this.titleOfOfficeHeld1 = source.titleOfOfficeHeld1;
        this.titleOfOfficeHeld2 = source.titleOfOfficeHeld2;
        this.signingOfficer1 = source.signingOfficer1;
        this.signingOfficer2 = source.signingOfficer2;
        this.titleOfOfficeHeld1 = source.titleOfOfficeHeld1;
        this.titleOfOfficeHeld2 = source.titleOfOfficeHeld2;
        this.executingUnderSealFlag = source.executingUnderSealFlag;
        this.additionalEmail1 = source.additionalEmail1;
        this.additionalEmail2 = source.additionalEmail2;

        break;

      default:
        break;
    }

    this.address = [];
    if (Array.isArray(source.address)) {
      source.address.forEach((address: Address) => {
        let newAddress: Address = new Address(address);
        newAddress.id = null;
        newAddress.setAddressHash();
        this.address.push(newAddress);
      });
    }

    this.journalNotes = [];
    if (Array.isArray(source.journalNotes)) {
      source.journalNotes.forEach((note: JournalNote) => {
        let journalNote: JournalNote = new JournalNote(note);
        journalNote.id = null;
        this.journalNotes.push(journalNote);
      });
    }

    //this.hiddenNotesVisible = source.hiddenNotesVisible;

    //copy telephones
    this.telephone = [];
    if (Array.isArray(source.telephone)) {
      source.telephone.forEach(tel => {
        this.telephone.push(Telephone.update(tel));
      });
    }

    //copy identificationRecords
    let identificationRecords: IdentificationRecord[] = [];
    if (Array.isArray(source.identificationRecords)) {
      source.identificationRecords.forEach((sourceRecord, index) => {
        let destinationRecord: IdentificationRecord = IdentificationRecord.update(sourceRecord, this.identificationRecords[ index ]);
        destinationRecord.contactId = this.id;
        identificationRecords.push(destinationRecord);
      });
    }
    this.identificationRecords = identificationRecords;

    this.abbreviatedName = source.abbreviatedName;
    this.selfManagedManagementCompanyType = source.selfManagedManagementCompanyType;
    //snapshot keeps the source organization id in sourceParentOrganizationId
    this.sourceParentOrganizationId = source.organizationId;
    //snapshot adds the source parentOrganizationName in sourceParentOrganizationName. It only for UI
    this.sourceParentOrganizationName = source.parentOrganizationName;
  }

  // clearStaleFlag(source: Contact): void {

  //     this.lastUpdatedTimeStamp = source.lastUpdatedTimeStamp;
  // }

  clearStaleFlag(lastUpdatedTimeStamp: number): void {

    this.lastUpdatedTimeStamp = lastUpdatedTimeStamp;
    this.lastSyncedFromSource = lastUpdatedTimeStamp;
  }

  resetContactTypeInstanceType(): void {
    if (this.gender === 'CORPORATION' ||
      this.gender === 'OTHERENTITY') {
      this.contactType = 'ORGANIZATION';
      this.instanceType = ContactInstanceType.ORGANIZATION;
      // client.organizationName = client.lastName;
    } else {
      this.contactType = 'PERSON';
      this.instanceType = ContactInstanceType.PERSON;
      this.initials = ' ';
    }
  }

  //removing the other contact info if not populated
  removeOtherContactFieldsIfNotPopulated(): void {
    if (this.otherContactInformation) {
      let filteredArr = this.otherContactInformation.filter(otherContactInformation => {
        return otherContactInformation.value;
      });
      this.otherContactInformation = filteredArr;
    }
  }

  //This method is used to change the contact instance type if based on gender it changes from one type to other for example organization to person.
  resetContactOnGenderChange(newGender: string, contactKey: string): void {
    let genderContactMapping = _.find(contactTypeMapping.GENDER_CONTACT_TYPE_MAPPING, genderContactMapping =>
      (genderContactMapping.contactKey === contactKey && genderContactMapping.GENDER.indexOf(newGender) >= 0
      ));
    //If there is gender contact mapping for a given contact key (for example guarantor)
    // and contact type according to the new gender is not same as the previous one then recreating the contact
    // with new gender based contact type
    const selectedContactType = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === contactKey);
    if (genderContactMapping && this.contactType !== genderContactMapping.contactTypeEnum) {
      this.setGenderBasedDefaultValue(selectedContactType, newGender);
    }
    //DPPMP-45711 Remove HomePhone if exists for Corporation.
    if (genderContactMapping && genderContactMapping.contactTypeEnum === 'ORGANIZATION') {
      this.removePhone(TelephoneTypes.home);
    }
  }

  //This method sets the default values of a contact based on given contactKey.
  //The selectedGender is an optional argument if provided then gender dependent fields like contactType, instanceType are populated using that.
  setDefaultValues(contactKey: string, selectedGender?: string): void {
    this.canadianResidentFlag = 'Y_n';
    this.activeFlag = 'Y_n';
    this.notaryFlag = 'LAWYER';
    const selectedContactType = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === contactKey);

    this.setGenderBasedDefaultValue(selectedContactType, selectedGender);

    if (selectedContactType.contactKey === 'LAWYER') {
      this.barristerSolicitor = 'BARRISTERS_SOLICITORS';
    }
  }

  //This method sets the default values which can vary based on selected gender. If gender is not provided then it sets the value as per default gender.
  setGenderBasedDefaultValue(selectedContactType: any, selectedGender?: string): void {
    //ToDo: Following fields are not gender specific so shouldn't be inside this method
    if (this.privateFlag == undefined) {
      this.privateFlag = selectedContactType.isPrivate;
    }
    this.partyRole = selectedContactType.partyRole;

    if (selectedGender) {
      const genderContactMapping = _.find(contactTypeMapping.GENDER_CONTACT_TYPE_MAPPING, genderContactMapping =>
        (genderContactMapping.contactKey === selectedContactType.contactKey && genderContactMapping.GENDER.indexOf(selectedGender) >= 0
        ));

      this.contactType = genderContactMapping.contactTypeEnum;
      this.instanceType = genderContactMapping.instanceType;
      this.gender = selectedGender;
    } else {
      this.contactType = selectedContactType.contactType;
      this.instanceType = selectedContactType.instanceType;
      this.gender = selectedContactType.defaultGender;
    }
  }

  //This method sets the default values
  setContactBasedDefaultValue(contactKey: string): void {
    const selectedContactType = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === contactKey);
    //ToDo: Following fields are not gender specific so shouldn't be inside this method
    this.privateFlag = selectedContactType.isPrivate;
    this.partyRole = selectedContactType.partyRole;

    this.contactType = selectedContactType.contactType;
    this.instanceType = selectedContactType.instanceType;
  }

  //This method adds the placeholder for service & report addresses for a Mortgagee. By default they should be same as mailing address. Mailing address is
  // added through the address getter directly.
  addSecondaryAddressesForMortgagee(): void {
    let serviceAddress: Address = this.serviceAddress;
    //by default service address same as mailing
    serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;

    let reportAddress: Address = this.reportAddress;
    //by default report address same as mailing
    reportAddress.sameAsAddressTypeCode = AddressTypes.mailing;
  }

  isContactDialogFormValid(contactKey: string): boolean {
    if (contactKey === 'GUARANTOR' || contactKey === 'TRANSFEROR') {
      return this.gender === 'CORPORATION' || this.gender === 'OTHERENTITY' ? (this.organizationName != undefined) : (this.contactName.lastName != undefined);
    } else {
      return true;
    }

  }

  checkDuplicateMailingAddress(): boolean {
    let ret: boolean = false;
    if (Array.isArray(this.address) && this.address.length > 1) {
      if (this.address.filter(item => item.addressTypeCode == AddressTypes.mailing).length > 1) {
        ret = true;
      }
    }
    return ret;
  }

  // This method sets the value for 'otherCategoryName' when the 'contactCategory' is other than 'OTHER_SPECIFY'
  // This is to fecilatate the sorting & filtering the contacts based on category
  // In case of 'OTHER_SPECIFY' the user has to set a value from the other category name custom-pick-list
  // setOtherCategoryName() : void {
  //     if(this.contactCategory && this.contactCategory !== 'QUESTION' && this.contactCategory !== 'OTHER_SPECIFY') {
  //         this.otherCategoryName = this.contactCategory;
  //     }
  // }

  isValidContact(errorService: ErrorService, fieldPrefix?: string, onlyMatterLevelContact?: boolean): boolean {
    let errorKey: string;
    let errorDirectiveMapping;
    if (this.privateContacts && this.privateContacts.persons.length > 0) {
      if (this.privateContacts.persons.filter(item => !item.genderChangeValid()).length === 0) {
        errorService.clearAllSaveErrors();
      }
    } else if (this.genderChangeValid()) {
      errorService.clearAllSaveErrors();
    }

    if (this.isPersonOrOrganization && this.checkDuplicateMailingAddress()) {
      errorService.addDpSaveError(DPError.createDPError('contact.duplicateMailingAddress'));
    }

    if (this.isInsuranceBroker) {
      if (!(this.insuranceBrokerType === 'INSURER'
        || this.insuranceBrokerType === 'BROKER')) {
        errorService.addDpSaveError(DPError.createDPError('contact.insuranceBrokerType'));
      }

    }
    if (this.isCondoCorporation) {
      if (this.selfManagedManagementCompanyType === 'MANAGEMENT_COMPANY' && !this.organizationId) {
        errorService.addDpSaveError(DPError.createDPError('contact.managementCompanyName'));
      }
    }
    if (this.isCorporationOrOtherEntity) {
      if (!this.organizationName) {
        if (this.partyRole === 'CLIENT') {
          errorKey = 'contact.companyName';
          errorDirectiveMapping = ErrorDirectiveMapping[ errorKey ];
          errorService.addDpSaveError(DPError.createCustomDPError(errorKey, errorDirectiveMapping.ERROR_MESSAGE,
            'Profile', errorDirectiveMapping.ERROR_TYPE));
        } else {
          errorService.addDpSaveError(DPError.createDPError('contact.companyName'));
        }
      }
      // if(!this.signingOfficer1){
      //     this.createSigningOfficerSaveError(errorService, 1);
      // }
      // if(!this.signingOfficer2){
      //     this.createSigningOfficerSaveError(errorService, 2);
      // }
    }
    if (this.isPerson) {
      if (!this.lastName) {
        errorService.addDpSaveError(DPError.createDPError('contact.lastName'));
      }
    }
    if (this.isMortgagee) {
      this.checkMortgageeFieldError(errorService);
    }
    if (this.isLawFirm) {
      this.isValidLawFirm(errorService, fieldPrefix);
    }
    if (this.isLawClerk || this.isSolicitor) {
      this.checkInitialFieldError(errorService);
    }
    if (this.isPrivateLender) {
      if (this.isPerson && !this.lastName) {
        errorKey = 'contact.lastName';
        errorDirectiveMapping = ErrorDirectiveMapping[ errorKey ];
      }
      if (this.isCorporationOrOtherEntity && !this.organizationName) {
        errorKey = 'contact.companyName';
        errorDirectiveMapping = ErrorDirectiveMapping[ errorKey ];
      }
      let topic: string = this.isPrivateLender ? 'General Information' : 'Profile';
      if (errorKey) {
        errorService.removeDpSaveError(errorKey);
        errorService.addDpSaveError(DPError.createCustomDPError(errorKey, errorDirectiveMapping.ERROR_MESSAGE,
          topic, errorDirectiveMapping.ERROR_TYPE));
      }
    }

    if (this.privateContacts && this.privateContacts.persons && this.privateContacts.persons.length > 0) {
      this.checkPrivateContactSurnameErrors(errorService);
    }

    if (this.statusCerts && this.statusCerts.length > 0) {
      this.checkStatusCertificateSurnameErrors(errorService);
    }

    if (this.contactType === 'ORGANIZATION' || this.contactType === 'PERSON') {
      //if we create a contact which doesn't have source contact, we allow this.contactCategory is "question"
      if (!this.contactCategory || (!onlyMatterLevelContact && (this.contactCategory === 'QUESTION'))) {
        errorService.addDpFieldError(DPError.createCustomDPError(`contact.profile.new.manage.contact.category.empty`, 'The \"Category\" field must' +
          ' be completed.', 'Profile', 'ERROR', 0, `category_${ this.id }`, null, `categoryFieldIndex_${ this.id }`, null, null));
      } else {
        errorService.removeDpFieldError('contact.profile.new.manage.contact.category.empty', `category_${ this.id }`);
      }

      if (this.contactCategory === 'OTHER_SPECIFY' && !this.otherCategoryName) {
        errorService.addDpFieldError(DPError.createCustomDPError(`contact.profile.new.manage.contact.specify.empty`, 'The \"Specify\" field must be' +
          ' completed.', 'Profile', 'ERROR', 0, `specify_${ this.id }`, null, `specifyFieldIndex_${ this.id }`, null, null));
      } else {
        errorService.removeDpFieldError('contact.profile.new.manage.contact.specify.empty', `specify_${ this.id }`);
      }
    }

    if (this.contactType === 'PRIVATE_CONTACT_ORGANIZATION' && this.isGenderDepartment()) {
      if (!this.organizationName) {
        errorService.addDpSaveError(DPError.createDPError('contact.departmentName'));
      }
    }

    return errorService.hasNoErrors();
  }

  checkPrivateContactSurnameErrors(errorService: ErrorService): void {
    let fieldKey = 'privateContact.surname';
    let errorDirectiveMapping = ErrorDirectiveMapping[ fieldKey ];
    if (this.privateContacts.persons) {
      this.privateContacts.persons.forEach((person: Contact) => {
        let fieldId = 'surname_' + person.identifier;
        if (!person.lastName && !person.isGenderDepartment()) {
          errorService.addDpFieldError(DPError.createDPError(fieldKey, fieldId));
        } else if (person.isGenderDepartment() && !person.organizationName) {
          let fieldKeyCompany = 'contact.companyName';
          let errorDirectiveMappingCompany = ErrorDirectiveMapping[ fieldKeyCompany ];
          errorService.addDpFieldError(DPError.createCustomDPError(fieldKey, errorDirectiveMappingCompany.ERROR_MESSAGE,
            errorDirectiveMapping.ERROR_TOPIC, errorDirectiveMapping.ERROR_TYPE, null, fieldId));
        } else {
          errorService.removeDpFieldError(fieldKey, fieldId);
        }
      });
    }
  }

  checkStatusCertificateSurnameErrors(errorService: ErrorService): void {
    let fieldKey = 'contact.statusCert.name';
    if (this.statusCerts && this.statusCerts.length > 0) {
      this.statusCerts.forEach((person: Contact) => {
        let fieldId = 'name_' + person.identifier;
        if (!person.lastName && !person.isGenderDepartment()) {
          errorService.addDpSaveError(DPError.createDPError(fieldKey, fieldId));
        } else {
          errorService.removeDpSaveError(fieldKey, fieldId);
        }
      });
    }
  }

  checkMortgageeFieldError(errorService: ErrorService): void {
    if (sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) === PROVINCE_CODES.BRITISH_COLOMBIA) {
      errorService.removeDpSaveError('contact.mortgagee.lenderLinkingNotCompleted.BC');
    } else {
      errorService.removeDpSaveError('contact.mortgagee.lenderLinkingNotCompleted');
    }
    if ((!this.mortgageeLenderInstitutionLinkStatus || this.mortgageeLenderInstitutionLinkStatus == 'MULTIPLE_MATCHED') && this.isPrivateExcludeProxy) {
      if (sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) === PROVINCE_CODES.BRITISH_COLOMBIA) {
        errorService.addDpSaveError(DPError.createDPError('contact.mortgagee.lenderLinkingNotCompleted.BC'));
      } else {
        errorService.addDpSaveError(DPError.createDPError('contact.mortgagee.lenderLinkingNotCompleted'));
      }
    }
    /*
           1) if it is private contact, then transitNo is optional, but 00000 not allowed, duplicate not allowed, but empty is allowed
           2) if it is global contact, then transitNo is required, 00000 also required - special meaning
           3) if it is private copy of global contact, no checking required, since the field value is copied from Global record as is, so not checking needed
         */
    errorService.removeDpfieldErrorByCoreErrorElementKey('contact.transitNo');

    if (this.isGlobal) {
      if (!this.transitNo) {
        errorService.addDpFieldError(DPError.createDPError('contact.transitNo.MISSING'));
      }
    } else if (this.isPrivateExcludeProxy) {
      if (this.transitNo && this.transitNo.match(/^0+$/)) {
        errorService.addDpFieldError(DPError.createDPError('contact.transitNo_INVALID'));
      }
    }
  }

  checkInitialFieldError(errorService: ErrorService): void {
    let errorElementKey = 'contact.initials';
    if (!this.initials) {
      errorService.addDpFieldError(DPError.createDPError(errorElementKey));
    }
  }

  createSigningOfficerSaveError(errorService: ErrorService, index: number): void {
    let errorElementKey = 'contact.signingOfficer';
    let errorDirectiveMapping = ErrorDirectiveMapping[ errorElementKey ];
    let errorMessage: string = errorDirectiveMapping.ERROR_MESSAGE;
    if (errorMessage && errorMessage.indexOf('*') > -1) {
      errorMessage = errorMessage.replace('*', '#' + index);
    }
    errorService.addDpFieldError(DPError.createCustomDPError(errorElementKey + index, errorMessage,
      errorDirectiveMapping.ERROR_TOPIC, errorDirectiveMapping.ERROR_TYPE));
  }

  // isValidStaffProfile(errorService : ErrorService) : boolean {
  //
  //     errorService.clearAllSaveErrors();
  //
  //     if(!this.contactType) {
  //         errorService.addDpSaveError(DPError.createDPError("admin.staffProfile.businessRole"));
  //     }
  //     if(!this.contactName || (this.contactName && !this.contactName.lastName)) {
  //         errorService.addDpSaveError(DPError.createDPError("admin.staffProfile.lastName"));
  //     }
  //     if(!this.email) {
  //         errorService.addDpSaveError(DPError.createDPError("admin.staffProfile.email.MISSING"));
  //     }
  //     if(!this.initials) {
  //         errorService.addDpSaveError(DPError.createDPError("admin.staffProfile.initials"));
  //     }
  //     return errorService.hasNoErrors();
  // }

  isValidLawFirm(errorService: ErrorService, fieldPrefix?: string): boolean {
    if (!this.legalFirmName) {
      errorService.addDpSaveError(DPError.createDPError('contact.firmSolicitor.lawFirm.firmName.MISSING'));
    }

    if (this.solicitors && this.solicitors.length > 0) {
      const solicitorSurnameErrorKey = fieldPrefix ? fieldPrefix + '.solicitorContact.surname' : 'solicitorContact.surname';

      for (let i = 0; i < this.solicitors.length; i++) {
        let contact: Contact = this.solicitors[ i ];
        let fieldId = 'surname_' + contact.identifier;
        if (contact && contact.contactName && !contact.contactName.lastName) {
          errorService.addDpSaveError(DPError.createDPError(solicitorSurnameErrorKey, fieldId));
        } else {
          let foundContactIndex: Number = _.findIndex(this.solicitors, (other: Contact) => {
            return other != contact && other.fullName === contact.fullName && other.mailingAddress.addressHash === contact.mailingAddress.addressHash;
          });
          if (foundContactIndex > -1 && i > foundContactIndex) {
            let dpError: DPError = DPError.createDPError(solicitorSurnameErrorKey, fieldId);
            dpError.errorMessage = 'Another solicitor with the same name and address as Solicitor # already exists in the law firm.';
            errorService.addDpSaveError(dpError);
          } else {
            errorService.removeDpSaveError(solicitorSurnameErrorKey);
          }
        }

      }
    }
    errorService.openErrorFooterNotification();
    return errorService.hasNoErrors();
  }

  convertToPrivate(): Contact {
    //If contact is already a proxy but presented as global then just change it's proxy edited true to false so it's visible as copy of global
    if (this.isProxySameAsGlobal) {
      this.proxyEdited = true;
      return this;
    } else {
      //If contact is actual global contact then creating a new proxy from it and returning that.
      return Contact.createNewProxy(this, true);
    }
  }

  static createNewProxy(globalContact: Contact, asPrivateCopy: boolean): Contact {
    let proxyContact: Contact = new Contact();
    proxyContact.createNewContactClone(globalContact);
    proxyContact.sourceContactId = globalContact.id;
    proxyContact.proxyForGlobal = true;
    proxyContact.privateFlag = true;
    //proxyContact never be StaffProfile
    proxyContact.isStaffProfile = false;

    if (asPrivateCopy) {
      proxyContact.proxyEdited = true;
      proxyContact.activeFlag = 'Y_n';
    }
    return proxyContact;
  }

  get isProxyPersisted(): boolean {
    return this.proxyForGlobal && this.id > 0;
  }

  /**
   * Update the lastname or organizationName if gender is changed
   */
  updateNameIfGenderChange(oldGender: string, newGender: string) {
    // GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
    const clientGenders: string[] = [ 'MALE', 'FEMALE', 'QUESTION', 'MALEPOA', 'FEMALEPOA', 'ESTATE' ];
    const organizationGenders: string[] = [ 'OTHERENTITY', 'CORPORATION', 'DEPARTMENT' ];

    if ((clientGenders.indexOf(oldGender) > -1)
      && (organizationGenders.indexOf(newGender) > -1)) {
      this.organizationName = this.lastName;
    }

    if ((organizationGenders.indexOf(oldGender) > -1)
      && (clientGenders.indexOf(newGender) > -1)) {
      this.lastName = this.organizationName;
    }
  }

  genderChangeValid(): boolean {
    console.log('this.gender is ', this.gender);
    console.log('this.localGender is ', this.localGender);
    // GENDER: ['MALE','FEMALE','QUESTION','MALEPOA','FEMALEPOA','ESTATE']
    const clientGenders: string[] = [ 'MALE', 'FEMALE', 'QUESTION', 'MALEPOA', 'FEMALEPOA', 'ESTATE' ];
    const organizationGenders: string[] = [ 'OTHERENTITY', 'CORPORATION', 'DEPARTMENT' ];
    let ret: boolean = true;

    if ((clientGenders.indexOf(this.gender) > -1)
      && (organizationGenders.indexOf(this.localGender) > -1)) {
      ret = false;
    }

    if ((organizationGenders.indexOf(this.gender) > -1)
      && (clientGenders.indexOf(this.localGender) > -1)) {
      ret = false;
    }

    return ret;
  }

  updateGender() {
    this.gender = this.localGender;
  }

  initialLocalGender() {
    this.localGender = this.gender;
  }

  get signingOfficer1() {
    if (this.signingOfficerLocal1 != null) {
      this.additionalName1 = this.signingOfficerLocal1;
    } else {
      this.signingOfficerLocal1 = this.additionalName1;
    }
    return this.signingOfficerLocal1;
  }

  set signingOfficer1(name) {
    this.additionalName1 = name;
    this.signingOfficerLocal1 = name;
  }

  get signingOfficer2() {
    if (this.signingOfficerLocal2 != null) {
      this.additionalName2 = this.signingOfficerLocal2;
    } else {
      this.signingOfficerLocal2 = this.additionalName2;
    }
    return this.signingOfficerLocal2;
  }

  set signingOfficer2(name) {
    this.additionalName2 = name;
    this.signingOfficerLocal2 = name;
  }

  get estateTrustee1() {
    if (this.estateTrusteeLocal1 != null) {
      this.additionalName1 = this.estateTrusteeLocal1;
    } else {
      this.estateTrusteeLocal1 = this.additionalName1;
    }
    return this.estateTrusteeLocal1;
  }

  set estateTrustee1(name) {
    this.additionalName1 = name;
    this.estateTrusteeLocal1 = name;
  }

  get estateTrustee2() {
    if (this.estateTrusteeLocal2 != null) {
      this.additionalName2 = this.estateTrusteeLocal2;
    } else {
      this.estateTrusteeLocal2 = this.additionalName2;
    }
    return this.estateTrusteeLocal2;
  }

  set estateTrustee2(name) {
    this.additionalName2 = name;
    this.estateTrusteeLocal2 = name;
  }

  get attorneyName1() {
    if (this.attorneyNameLocal1 != null) {
      this.additionalName1 = this.attorneyNameLocal1;
    } else {
      this.attorneyNameLocal1 = this.additionalName1;
    }
    return this.attorneyNameLocal1;
  }

  set attorneyName1(name) {
    this.additionalName1 = name;
    this.attorneyNameLocal1 = name;
  }

  get attorneyName2() {
    if (this.attorneyNameLocal2 != null) {
      this.additionalName2 = this.attorneyNameLocal2;
    } else {
      this.attorneyNameLocal2 = this.additionalName2;
    }
    return this.attorneyNameLocal2;
  }

  set attorneyName2(name) {
    this.additionalName2 = name;
    this.attorneyNameLocal2 = name;
  }

  syncAdditionalName(gender: string) {
    switch (gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
        this.additionalName1 = undefined;
        this.additionalName2 = undefined;
        break;

      case 'MALEPOA':
      case 'FEMALEPOA':
        this.additionalName1 = this.attorneyNameLocal1;
        this.additionalName2 = this.attorneyNameLocal2;
        break;

      case 'CORPORATION':
      case 'OTHERENTITY':
        this.additionalName1 = this.signingOfficerLocal1;
        this.additionalName2 = this.signingOfficerLocal2;
        break;

      case 'ESTATE':
        this.additionalName1 = this.estateTrusteeLocal1;
        this.additionalName2 = this.estateTrusteeLocal2;
        break;

      default:
        break;
    }

  }

  private genderGroup(gender: string): number {
    let group: number = 0;
    switch (gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
        group = 0;
        break;

      case 'MALEPOA':
      case 'FEMALEPOA':
        group = 1;
        break;

      case 'CORPORATION':
        group = 2;
        break;

      case 'ESTATE':
        group = 3;
        break;

      case 'OTHERENTITY':
        group = 4;
        break;

      default:
        break;
    }
    return group;
  }

  sameGenderGroup(gender0: string, gender1: string): boolean {
    return this.genderGroup(gender0) === this.genderGroup(gender1);
  }

  clearIDDetails(): void {
    this.label = null;
    this.idInfoEnteredBy = null;
    this.contactIdInfoEnteredBy = null;
    this.sourceOfElectronicVersion = null;
    this.removeBusinessAddress();
    // this.businessAddress;
    this.enteredOn = null;
    this.note = null;
    this.identificationRecords = [];
  }

  rebuildContactWithGenderChange(oldGender: string, newGender: string, contactKey: string) {
    this.updateNameIfGenderChange(oldGender, newGender);
    this.syncAdditionalName(newGender);
    this.resetContactOnGenderChange(newGender, contactKey);

    if (!this.sameGenderGroup(oldGender, newGender)) {
      this.clearIDDetails();
      this.initializeContactIdDetails();
    }
  }

  isOtherContactInfoVisible(): boolean {
    return !(this.contactType === 'REALESTATEBROKER'
      || this.contactType === 'MORTGAGEE'
      || this.contactType === 'ORGANIZATION'
      || this.contactType === 'MORTGAGE_BROKER'
      || this.contactType === 'SURVEYOR'
      || this.contactType === 'MANAGEMENT_COMPANY'
      || this.isLawFirm);
  }

  isContactOrganizationType(): boolean {
    return this.contactType === 'REALESTATEBROKER'
      || this.contactType === 'MORTGAGEE'
      || this.contactType === 'ORGANIZATION'
      || this.contactType === 'MORTGAGE_BROKER'
      || this.contactType === 'SURVEYOR'
      || this.contactType === 'PRIVATE_LENDER_ORGANIZATION'
      || this.contactType === 'MANAGEMENT_COMPANY';
  }

  // The beginning of Initialize ID Details
  // "initializeContactIdDetails" will update officerName and nameOnId before saving.
  // We may have a better name for this method.
  // It is called not only in Initialize ID Details
  initializeContactIdDetails(): void {
    if (Array.isArray(this.identificationRecords)) {
      this.initializeIdentificationRecords();
    }
  }

  private initializeIdentificationRecords(): void {
    if (!this.isCorporation && !this.isPoaEstate0rOtherEntity) {
      if (this.identificationRecords.length == 0) {
        this.initializeNonCorporationIDDetails();
      } else {
        this.identificationRecords[ 0 ].officerName = this.contactName.surnameLastFullName;

        this.updateNameOnID(0);
      }
    }
  }

  private initializeCorpPOAEstateOtherEntityIDDetails(): void {
    this.identificationRecords.push(IdentificationRecord.createIdentificationRecord(this.additionalName1 ? this.additionalName1 : '??????', this.titleOfOfficeHeld1 ? this.titleOfOfficeHeld1 : '??????', this.workPhone, TelephoneTypes.work));
    this.identificationRecords.push(IdentificationRecord.createIdentificationRecord(this.additionalName2 ? this.additionalName2 : '??????', this.titleOfOfficeHeld2 ? this.titleOfOfficeHeld2 : '??????'));
    this.updateNameOnID(0);
    this.updateNameOnID(1);
  }

  private initializeNonCorporationIDDetails(): void {
    this.identificationRecords.push(IdentificationRecord.createIdentificationRecord(this.contactName.surnameLastFullName));

    this.updateNameOnID(0);
  }

  public updateIdentificationRecordOfficerNames(): void {
    if (this.additionalName2) {
      if (this.identificationRecords.length <= 1) {
        this.identificationRecords.push(IdentificationRecord.createIdentificationRecord(this.additionalName2, this.titleOfOfficeHeld2));
        this.updateNameOnID(1);
      } else if (this.identificationRecords.length > 1) {
        this.identificationRecords[ 1 ].officerName = this.additionalName2;
        this.identificationRecords[ 1 ].officerTitle = this.titleOfOfficeHeld2;
        this.updateNameOnID(1);
      }

    } else {
      if (this.identificationRecords.length > 1) {
        this.identificationRecords.splice(1, 1);
      }
    }

    if (this.additionalName1) {
      if (this.identificationRecords.length === 0) {
        this.identificationRecords.push(IdentificationRecord.createIdentificationRecord(this.additionalName1, this.titleOfOfficeHeld1));
        this.updateNameOnID(0);
      } else {
        this.identificationRecords[ 0 ].officerName = this.additionalName1;
        this.identificationRecords[ 0 ].officerTitle = this.titleOfOfficeHeld1;
        this.updateNameOnID(0);
      }
    } else {
      if (this.identificationRecords.length === 0) {
        this.identificationRecords.push(IdentificationRecord.createIdentificationRecord('??????', this.titleOfOfficeHeld1));
        this.updateNameOnID(0);
      } else {
        this.identificationRecords[ 0 ].officerName = '??????';
        this.identificationRecords[ 0 ].officerTitle = this.titleOfOfficeHeld1;
        this.updateNameOnID(0);
      }
    }
  }

  private updateNameOnID(identificationRecord: number): void {
    let officerName = this.identificationRecords[ identificationRecord ].officerName;

    for (let j = 0; j < this.identificationRecords[ identificationRecord ].identificationDocuments.length; j++) {
      if (this.identificationRecords[ identificationRecord ].identificationDocuments[ j ].sameAsOfficerNameFlag === undefined
        || this.identificationRecords[ identificationRecord ].identificationDocuments[ j ].sameAsOfficerNameFlag) {
        this.identificationRecords[ identificationRecord ].identificationDocuments[ j ].nameOnId = officerName;
      }
    }

    // Separate to set sameAsOfficerNameFlag default value
    for (let j = 0; j < this.identificationRecords[ identificationRecord ].identificationDocuments.length; j++) {
      if (this.identificationRecords[ identificationRecord ].identificationDocuments[ j ].sameAsOfficerNameFlag === undefined) {
        this.identificationRecords[ identificationRecord ].identificationDocuments[ j ].sameAsOfficerNameFlag = true;
      }
    }
  }

  // The end of Initialize ID Details

  get statusCertPositionAndName(): string {
    let name: string = '';

    if (this.position) {
      name = this.position + '; ';
    }

    if (this.contactName.lastName) {
      name += this.contactName.lastName;
    }

    return name;
  }

  get condoCorpNumber(): string {
    let lastToken: string = this.organizationName && this.organizationName.split(' ').pop();

    if (lastToken && isNumeric(lastToken)) {
      return ('000000' + lastToken).slice(-6);
    }

    return '';
  }

  addStatusCert(c: Contact) {
    this.subContacts.push(c);
  }

  deleteStatusCert(c: Contact) {
    let statusCertIndex: number = _.findIndex(this.subContacts, obj => obj === c);
    this.subContacts.splice(statusCertIndex, 1);
  }

  get statusCerts(): Contact[] {
    if (!this.subContacts) {
      return [];
    }
    return this.subContacts.filter((contact: Contact) => {
      return (contact && contact.contactType === 'DIRECTOR_OFFICER');
    });
  }

  clearCondoStatusCerts(): void {
    let statusCertsList = this.statusCerts;
    for (let i = 0; i < statusCertsList.length; i++) {
      this.deleteStatusCert(statusCertsList[ i ]);
    }
  }

  public initializeAddresses(addressTypes: string[]): void {
    for (let addressType of addressTypes) {
      let address: Address = _.find(this.address, (address: Address) => {
        return address.addressTypeCode === addressType;
      });

      if (!address) {
        address = new Address();

        address.addressTypeCode = addressType;
        address.setAddressHash();

        this.address.push(address);
      }
    }
  }

  public initializeAddressesWithDefaultProvince(addressTypes: string[], documentProfileCache: DocumentProfileCache, matterProvinceCode: string): void {
    for (let addressType of addressTypes) {
      let address: Address = _.find(this.address, (address: Address) => {
        return address.addressTypeCode === addressType;
      });

      if (!address) {
        address = new Address();

        address.addressTypeCode = addressType;
        this.setDefaultProvinceValue(documentProfileCache, matterProvinceCode, address);
        address.setAddressHash();

        this.address.push(address);
      }
    }
  }

  public initializePhones(phoneTypes: string[]): void {
    for (let phoneType of phoneTypes) {
      let phone: Telephone = this.telephone.find((t: Telephone) => t.phoneTypeCode === phoneType);

      if (!phone) {
        phone = new Telephone();

        phone.phoneTypeCode = phoneType;

        this.telephone.push(phone);
      }
    }
  }

  isGenderDepartment(): boolean {
    return (this.localGender == 'DEPARTMENT');
  }

  // Added a Temporary getter for persons just in any one is still calling persons on contact
  // persons on contact was replaced with subcontacts...
  get persons(): Contact[] {
    return this.subContacts;
  }

  set persons(contacts: Contact[]) {
    this.subContacts = contacts;
  }

  /***Commenting the below logic as latest requirement is that if contact is locked by one component then in other components it should show as locked. The
   backend returns the contact locked even if the same user tries to acquire lock again ***/

  //Returns true if locked by other user
  //Else returns false if locked by self or not-locked
  /*get locked() : boolean {
     const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
     return (this.lockedByUser && this.lockedByUser.id !== Number(userId));
     }

     set locked(ignore: boolean) {
     //Do nothing
     }*/

  /*get lockedByMe() : boolean {
     const userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userId);
     return (this.lockedByUser && this.lockedByUser.id === Number(userId));
     }*/

  get lockedByCurrentUser(): boolean {
    return !this.locked;
  }

  isFeeSubjectToHST(): boolean {
    if (this.feesSubjectToHst) {
      return (this.feesSubjectToHst == 'YES' || this.feesSubjectToHst == 'Y_n');
    } else {
      return false;
    }
  }

  fillGenderSpecificDefaultFields(contactUntouched: Contact): void {
    if (contactUntouched) {
      if (this.firmName) {
        contactUntouched.firmName = this.firmName;
      }

      if (this.gender === 'MALEPOA' || this.gender === 'FEMALEPOA') {
        contactUntouched.attorneyNameLocal1 = this.attorneyNameLocal1;
        contactUntouched.attorneyNameLocal2 = this.attorneyNameLocal2;
      } else if (this.gender === 'CORPORATION' || this.gender === 'OTHERENTITY') {
        contactUntouched.signingOfficerLocal1 = this.signingOfficerLocal1;
        contactUntouched.signingOfficerLocal2 = this.signingOfficerLocal2;
      } else if (this.gender === 'ESTATE') {
        contactUntouched.estateTrusteeLocal1 = this.estateTrusteeLocal1;
        contactUntouched.estateTrusteeLocal2 = this.estateTrusteeLocal2;
      }

      if (!this.nameOnDocuments && contactUntouched && !contactUntouched.nameOnDocuments) {
        contactUntouched.nameOnDocuments = this.nameOnDocuments;
      }
    }
  }

  get isInstanceTypePerson(): boolean {
    return this.instanceType == ContactInstanceType.PERSON;
  }

  //add leading 0's if institution number less than 3 digits
  padInstitutionNo(): void {
    if (this.institutionNo && this.institutionNo.length < 3) {
      this.institutionNo = _.padStart(this.institutionNo, 3, '0');
    }
  }

  setAttentionValue() {
    if (this.gender === 'OTHERENTITY' || this.gender === 'CORPORATION') {
      this.attention = this.organizationName;
    } else {
      this.attention = this.contactName ? this.contactName.surnameLastFullName : '';
    }
  }

  createSolicitorContact(documentProfileCache: DocumentProfileCache, matterProvinceCode: string): Contact {
    let solicitorContact: Contact = new Contact();
    solicitorContact.privateFlag = !this.isGlobal;
    solicitorContact.legalFirmId = this.id;
    solicitorContact.organizationId = this.id;
    solicitorContact.setDefaultValues('LAWYER');
    solicitorContact.email = this.email;
    solicitorContact.workPhone = this.workPhone;
    solicitorContact.faxPhone = this.faxPhone;
    solicitorContact.cellPhone = this.cellPhone;
    solicitorContact.initializeAddressesWithDefaultProvince([ AddressTypes.mailing ], documentProfileCache, matterProvinceCode);
    solicitorContact.mailingAddress.sameAsAddressTypeCode = AddressTypes.firmMailing;
    return solicitorContact;
  }

  copySubContactsToGivenContact(proxyContact: Contact) {
    if (!proxyContact) {
      return;
    }
    proxyContact.subContacts = [];
    if (Array.isArray(this.subContacts)) {
      this.subContacts.forEach((subContact) => {
        proxyContact.subContacts.push(subContact);
      });
    }
  }

  static createMortgageeOfLender(lender: LendingInstitution): Contact {
    let mortgageeContact: Contact = new Contact();
    mortgageeContact.organizationName = lender.institutionName;
    mortgageeContact.alternateName = lender.alternateName;
    mortgageeContact.institutionNo = lender.institutionNumber;
    mortgageeContact.lenderInstitutionId = lender.id;
    mortgageeContact.lenderNames = lender.lenderName;
    mortgageeContact.depositsAccepted = lender.depositsAccepted;
    mortgageeContact.mortgagesRegistered = lender.mortgagesRegistered;
    return mortgageeContact;
  }

  isExistingAttention(): boolean {
    return this.id == null;
  }

  isDefaultAttention(): boolean {
    return this.firstName === Constants.MORTGAGE_DEPT
      || this.firstName === Constants.MORTGAGE_DISCHARGE
      || this.firstName === Constants.STATUS_CERTIFICATE_REQUEST;
  }

  //Temporarily added into contact as there are two utils classes and they are conflicting in matter
  static formatTransitNo(transitNo: string): string {
    if (transitNo && transitNo.length > 0 && transitNo.length < 5) {
      return _.padStart(transitNo, 5, '0');
    } else {
      return transitNo;
    }
  }

  isManagedByManagementCompany(): boolean {
    return this.selfManagedManagementCompanyType === 'MANAGEMENT_COMPANY';
  }

  isSelfManaged(): boolean {
    if (!this.isManagedByManagementCompany()) {
      return true;
    } else {
      return false;
    }
  }

  cleanupCondoDataBeforeSave() {
    if (this.isCondoCorporation) {
      if (this.isManagedByManagementCompany()) {
        // this.address = [];
        this.email = '';
        this.faxPhone = '';
        this.telephone = [];
      } else {
        this.organizationId = null;
      }
    }
  }

  get isReadOnly(): boolean {
    return this.locked || this.isOwnedBySystemAccount;
  }

  mapStrToContactName(contactName: string) {
    this.contactName = new ContactName();
    this.contactName.lastName = contactName;
  }

  mapStrToOrganization(contactName: string) {
    this.contactName = new ContactName();
    this.organizationName = contactName;
  }

  // serviceAddressDdString = [AddressDropdowns.sameAsMailing, AddressDropdowns.manuallyEntered];
  //serviceAddress can be same as mailing address or manual
  //reportAddress can be same as service address, mailing address or manul
  //if serviceAddress change same as option, it need update both correct option
  createServiceAddressOptions(): SameAsAddressOption[] {
    return ServiceAddressDdString.map(item => {
      return {
        description: item,
        srcAddress: item === AddressDropdowns.sameAsMailing ? this.mailingAddress : this.serviceAddress
      };
    });
  }

  // reportAddressDdString = [AddressDropdowns.sameAsMailing, AddressDropdowns.sameAsService, AddressDropdowns.manuallyEntered];;
  createReportAddressOptions(): SameAsAddressOption[] {
    return ReportAddressDdString.map(item => {
      return {
        description: item,
        srcAddress: item === AddressDropdowns.sameAsMailing
          ? this.mailingAddress
          : (item === AddressDropdowns.sameAsService
            ? (this.serviceAddress && this.serviceAddress.sameAsAddressTypeCode === AddressTypes.mailing ? this.mailingAddress : this.serviceAddress)
            : this.reportAddress)
      };
    });
  }

  // solicitorAddressDd : string[] = [AddressDropdowns.sameAsFirm, AddressDropdowns.manuallyEntered];
  createSolicitorAddressDdOptions(firmContact: Contact): SameAsAddressOption[] {
    return SolicitorAddressDdString.map(item => {
      return {
        description: item,
        srcAddress: item === AddressDropdowns.sameAsFirm ? firmContact && firmContact.mailingAddress : null
      };
    });
  }

  getInitials(): string {
    if (!this.contactName) {
      return null;
    } else {
      return this.contactName.initials;
    }
  }

  // return name in format firstName middleName, lastName
  getFullNameForContact(addComma: boolean, lastNameFirst: boolean = false): string {
    if (!this.contactName) {
      return null;
    }

    let firstName: string = Utils.returnValidString(this.contactName.firstName);
    let middleName: string = Utils.returnValidString(this.contactName.middleName);
    let lastName: string = addComma ?
      Utils.getNameWithComma(this.contactName.lastName) : ' ' + Utils.returnValidString(this.contactName.lastName);
    let fullName: string;

    if (lastNameFirst) {
      fullName = lastName + firstName;
      if (middleName) {
        fullName += ' ' + middleName;
      }
    } else {
      if (middleName) {
        fullName = firstName + ' ' + middleName + lastName;
      } else {
        fullName = firstName + lastName;
      }
    }
    return fullName;
  }

  createContactFieldsDiff(fieldName: string, updatedValue: any, globalValue: any): ContactFieldsDiff {
    return ContactFieldsDiff.createContactFieldsDiff(fieldName, updatedValue, globalValue);
  }

  determineContactNameDiff(updatedContactName: ContactName, currentContactName: ContactName): ContactFieldsDiff[] {
    let diffs: ContactFieldsDiff[] = [];
    if (!updatedContactName && !currentContactName) {
      return diffs;
    }

    if (!updatedContactName && currentContactName) {
      if (currentContactName.lastName) {
        diffs.push(this.createContactFieldsDiff('Surname', '', currentContactName.lastName));
      }
      if (currentContactName.firstName) {
        diffs.push(this.createContactFieldsDiff('First Name', '', currentContactName.firstName));
      }
      if (currentContactName.middleName) {
        diffs.push(this.createContactFieldsDiff('Middle Name(s)', '', currentContactName.middleName));
      }
    }

    if (updatedContactName && !currentContactName) {
      if (updatedContactName.lastName) {
        diffs.push(this.createContactFieldsDiff('Surname', updatedContactName.lastName, ''));
      }
      if (updatedContactName.firstName) {
        diffs.push(this.createContactFieldsDiff('First Name', updatedContactName.firstName, ''));
      }
      if (updatedContactName.middleName) {
        diffs.push(this.createContactFieldsDiff('Middle Name(s)', updatedContactName.middleName, ''));
      }
    }

    if (updatedContactName && currentContactName) {
      if (updatedContactName.lastName !== currentContactName.lastName) {
        diffs.push(this.createContactFieldsDiff('Surname', updatedContactName.lastName, currentContactName.lastName));
      }
      if (updatedContactName.firstName !== currentContactName.firstName) {
        diffs.push(this.createContactFieldsDiff('First Name', updatedContactName.firstName, currentContactName.firstName));
      }
      if (updatedContactName.middleName !== currentContactName.middleName) {
        diffs.push(this.createContactFieldsDiff('Middle Name(s)', updatedContactName.middleName, currentContactName.middleName));
      }
    }

    return diffs;
  }

  determinePhoneDiff(phoneType: string, updatedPhone: Telephone, currentPhone: Telephone): ContactFieldsDiff[] {
    let diffs: ContactFieldsDiff[] = [];
    if (!updatedPhone && !currentPhone) {
      return diffs;
    }

    if (!updatedPhone && currentPhone) {
      if (currentPhone.telephoneNumber && currentPhone.telephoneNumber !== '') {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ phoneType ], '', currentPhone.telephoneNumber));
      }
    }

    if (updatedPhone && !currentPhone) {
      if (updatedPhone.telephoneNumber && updatedPhone.telephoneNumber !== '') {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ phoneType ], updatedPhone.telephoneNumber, ''));
      }
    }

    if (updatedPhone && currentPhone) {
      //updatedPhone.telephoneNumber or currentPhone.telephoneNumber is null, undefined or null, it means equal
      if (updatedPhone.telephoneNumber || currentPhone.telephoneNumber) {
        if (updatedPhone.telephoneNumber != currentPhone.telephoneNumber) {
          diffs.push(this.createContactFieldsDiff(PropertyLabel[ phoneType ],
            updatedPhone.telephoneNumber, currentPhone.telephoneNumber));
        }
      }

    }
    return diffs;
  }

  determineAddressDiff(addressType: string, updatedAddress: Address, currentAddress: Address): ContactFieldsDiff[] {
    let diffs: ContactFieldsDiff[] = [];
    if (!updatedAddress && !currentAddress) {
      return diffs;
    }

    if (updatedAddress && !currentAddress) {
      if (updatedAddress.addressLine1) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Street', updatedAddress.addressLine1, ''));
      }
      if (updatedAddress.addressLine2) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Continued', updatedAddress.addressLine2, ''));
      }
      if (updatedAddress.city) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > City', updatedAddress.city, ''));
      }
      if (updatedAddress.provinceName) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Province', updatedAddress.provinceName, ''));
      }
      if (updatedAddress.country) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Country', updatedAddress.country, ''));
      }
      if (updatedAddress.postalCode) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Postal Code', updatedAddress.postalCode, ''));
      }
    }

    if (!updatedAddress && currentAddress) {
      if (currentAddress.addressLine1) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Street', '', currentAddress.addressLine1));
      }
      if (currentAddress.addressLine2) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Continued', '', currentAddress.addressLine2));
      }
      if (currentAddress.city) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > City', '', currentAddress.city));
      }
      if (currentAddress.provinceName) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Province', '', currentAddress.provinceName));
      }
      if (currentAddress.country) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Country', '', currentAddress.country));
      }
      if (currentAddress.postalCode) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Postal Code', '', currentAddress.postalCode));
      }
    }

    if (updatedAddress && currentAddress) {
      if (updatedAddress.addressLine1 !== currentAddress.addressLine1) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Street', updatedAddress.addressLine1, currentAddress.addressLine1));
      }
      if (updatedAddress.addressLine2 !== currentAddress.addressLine2) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Continued', updatedAddress.addressLine2, currentAddress.addressLine2));
      }
      if (updatedAddress.city !== currentAddress.city) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > City', updatedAddress.city, currentAddress.city));
      }
      if (updatedAddress.provinceName !== currentAddress.provinceName) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Province', updatedAddress.provinceName, currentAddress.provinceName));
      }
      if (updatedAddress.provinceName !== currentAddress.provinceName) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Country', updatedAddress.country, currentAddress.country));

      }
      if (updatedAddress.postalCode !== currentAddress.postalCode) {
        diffs.push(this.createContactFieldsDiff(PropertyLabel[ addressType ] + ' > Postal Code', updatedAddress.postalCode, currentAddress.postalCode));
      }
    }
    return diffs;
  }

  getLabelByValue(dropDownItems, value: string): string {
    let label: string = '';
    let labelItem;
    labelItem = dropDownItems.find(item => item.value === value);
    if (labelItem) {
      label = labelItem.label;
    }
    return label;
  }

  //label is created by concate the labelOption and labeValue together
  //parsed the labeOption and labelValue from the label
  getLabelOptionValue(): { labelOption: string, labeValue: string } {
    let labelOption: string = null;
    let labelValue: string = null;
    if (this.label && this.label != 'DEFAULT' && this.label.indexOf('No.') > -1) {
      labelValue = this.label.split('No.')[ 1 ].trim();
      labelOption = this.label.split('No.')[ 0 ] + 'No.';
    } else if (this.label && this.label != 'DEFAULT' && this.label.indexOf('Other') > -1) {
      labelValue = this.label.split('Other')[ 1 ].trim();
      labelOption = 'Other';
    }
    return {labelOption: labelOption, labeValue: labelValue};
  }

  changeValueToLabel(property: string, contact: Contact): string {
    let label: string = contact[ property ];
    switch (property) {
      case 'insuranceBrokerType':
        if (contact[ property ]) {
          label = this.getLabelByValue(contactDropDowns.INSURANCEBROKERTYPES, contact[ property ]);
        }
        break;
      case 'activeFlag':
        if (contact[ property ]) {
          label = this.getLabelByValue(contactDropDowns.YESNO, contact[ property ]);
        }
        break;
      default :
        break;
    }
    return label;
  }

  determineContactDiff(checkFields: string[], updatedContact: Contact, currentContact: Contact): any {
    let diffs: ContactFieldsDiff[] = [];
    if (!Array.isArray(checkFields) || !updatedContact) {
      return diffs;
    }

    checkFields.forEach(property => {
      if (property == 'contactName') {
        const nameDiffs: ContactFieldsDiff[]
          = this.determineContactNameDiff(updatedContact && updatedContact.contactName, currentContact && currentContact.contactName);
        if (Array.isArray(nameDiffs) && nameDiffs.length) {
          diffs.push(...nameDiffs);
        }
      } else {
        if (updatedContact.hasOwnProperty(property)) {
          if (!currentContact || !currentContact.hasOwnProperty(property)) {
            if (updatedContact[ property ]) {
              diffs.push(this.createContactFieldsDiff(PropertyLabel[ property ],
                this.changeValueToLabel(property, updatedContact),
                ''));
            }
          } else if (updatedContact[ property ] != currentContact[ property ]) {
            diffs.push(this.createContactFieldsDiff(PropertyLabel[ property ],
              this.changeValueToLabel(property, updatedContact),
              this.changeValueToLabel(property, currentContact)));
          }
        } else {
          if (property == 'MAILING' || property == 'SERVICE' || property == 'REPORT') {
            let updatedAddress: Address;
            if (!Array.isArray(updatedContact.address)) {
              updatedAddress = null;
            } else {
              updatedAddress = _.find(updatedContact.address, (address: Address) => {
                return address.addressTypeCode === property;
              });
            }
            let currentAddress: Address;
            if (!currentContact || !Array.isArray(currentContact.address)) {
              currentAddress = null;
            } else {
              currentAddress = _.find(currentContact.address, (address: Address) => {
                return address.addressTypeCode === property;
              });
            }

            const addressDiffs: ContactFieldsDiff[] = this.determineAddressDiff(property, updatedAddress, currentAddress);
            if (Array.isArray(addressDiffs) && addressDiffs.length) {
              diffs.push(...addressDiffs);
            }
          }

          if (property == 'WORK' || property == 'HOME' || property == 'MOBILE' || property == 'FAX') {
            let updatedPhone: Telephone;
            if (!Array.isArray(updatedContact.telephone)) {
              updatedPhone = null;
            } else {
              updatedPhone = _.find(updatedContact.telephone, (telephone: Telephone) => {
                return telephone.phoneTypeCode === property;
              });
            }
            let currentPhone: Telephone;
            if (!currentContact || !Array.isArray(currentContact.telephone)) {
              currentPhone = null;
            } else {
              currentPhone = _.find(currentContact.telephone, (telephone: Telephone) => {
                return telephone.phoneTypeCode === property;
              });
            }
            const phoneDiffs: any[] = this.determinePhoneDiff(property, updatedPhone, currentPhone);
            if (Array.isArray(phoneDiffs) && phoneDiffs.length) {
              diffs.push(...phoneDiffs);
            }
          }

        }
      }

    });
    return diffs;
  }

  //According to DPPMP 28468, remove auto formatting
  public handleFullNameWithoutFormatting(contactName: string): void {
    // contactName = Utils.capitalizeTypedName(contactName);
    let fullName = contactName.split('"')[ 1 ];
    if (fullName) {
      if (fullName.indexOf(',') == -1) {
        this.lastName = fullName;
      } else {
        this.lastName = fullName.split(',')[ 0 ];
        if (fullName.split(',').length > 1) {
          // this.firstName = Utils.capitalizeTypedName(fullName.split(',')[1].trim());
          this.firstName = fullName.split(',')[ 1 ].trim();
        }
      }
    }

  }

  public handleFullName(contactName: string): void {
    contactName = Utils.capitalizeTypedName(contactName);
    let fullName = contactName.split('"')[ 1 ];
    if (fullName.indexOf(',') == -1) {
      this.lastName = fullName;
    } else {
      this.lastName = fullName.split(',')[ 0 ];
      if (fullName.split(',').length > 1) {
        this.firstName = Utils.capitalizeTypedName(fullName.split(',')[ 1 ].trim());
      }
    }

  }

  setIdDetailsDefaultLabel() {
    if (this.isCorporation) {
      if (!this.label) {
        this.label = 'DEFAULT';
      }
    }
  }

  defaultProvinceValue(documentProfileCache: DocumentProfileCache, address: Address) {
    if (documentProfileCache && documentProfileCache.cachedDocumentProfile && documentProfileCache.cachedDocumentProfile.firmDocumentProfile
      && documentProfileCache.cachedDocumentProfile.firmDocumentProfile.address
      && documentProfileCache.cachedDocumentProfile.firmDocumentProfile.address.provinceName) {
      address.provinceName = documentProfileCache.cachedDocumentProfile.firmDocumentProfile.address.provinceName;
      address.provinceCode = documentProfileCache.cachedDocumentProfile.firmDocumentProfile.address.provinceCode;
    }
  }

  setClientIdBusinessAddressDefaultValue(documentProfileCache: DocumentProfileCache, provinceCode?: string) {
    let clientIdBusiness: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.clientIdBusiness;
    });
    if (!clientIdBusiness) {
      clientIdBusiness = new Address();
      clientIdBusiness.addressTypeCode = AddressTypes.clientIdBusiness;
      clientIdBusiness.country = 'CANADA';
      this.setDefaultProvinceValue(documentProfileCache, provinceCode, clientIdBusiness);
      clientIdBusiness.setAddressHash();
      this.address.push(clientIdBusiness);
    }
  }

  setClientAddressDefaultValue(docProfileFirmAddress: Address) {
    if (this.address.length === 0) {
      let firstAddress: Address = new Address();
      firstAddress.addressTypeCode = AddressTypes.mailing;
      firstAddress.primaryAddress = true;
      firstAddress.country = 'CANADA';
      if (docProfileFirmAddress) {
        firstAddress.provinceCode = docProfileFirmAddress.provinceCode;
        firstAddress.provinceName = docProfileFirmAddress.provinceName;
      }
      firstAddress.setAddressHash();
      this.address.push(firstAddress);
    }

    //Add additional addresses (up to 3 total)
    let additionalAddress: Address;
    for (let i = this.address.length; i < 3; i++) {
      additionalAddress = new Address();
      additionalAddress.addressTypeCode = null;
      additionalAddress.country = 'CANADA';
      if (docProfileFirmAddress) {
        additionalAddress.provinceCode = docProfileFirmAddress.provinceCode;
        additionalAddress.provinceName = docProfileFirmAddress.provinceName;
      }
      additionalAddress.setAddressHash();
      this.address.push(additionalAddress);
    }
  }

  copyAddresses(sourceAddresses) {
    this.address = [];
    if (Array.isArray(sourceAddresses)) {
      for (let i: number = 0; i < sourceAddresses.length; i++) {
        this.address[ i ] = new Address(sourceAddresses[ i ]);
        this.address[ i ].id = null;
      }
    }
  }

  copyOtherContactInformations(sourceOtherContactInformations) {
    this.otherContactInformation = [];
    if (Array.isArray(sourceOtherContactInformations)) {
      for (let i: number = 0; i < sourceOtherContactInformations.length; i++) {
        this.otherContactInformation[ i ] = new OtherContactInformation(sourceOtherContactInformations[ i ]);
        this.otherContactInformation[ i ].id = null;
        this.otherContactInformation[ i ].contactId = null;
      }
    }
  }

  updateSpouseContactWithReferenceContact(spouseContact: Contact) {
    spouseContact.canadianResidentFlag = this.canadianResidentFlag;
    spouseContact.residentStatus = this.residentStatus;
    spouseContact.activeFlag = this.activeFlag;
    spouseContact.homePhone = this.homePhone;
    spouseContact.faxPhone = this.faxPhone;
    //Address
    let primaryAddress: Address = this.address.find((item: Address) => {
      return item.primaryAddress;
    });
    if (primaryAddress) {
      let clone: Address = new Address(primaryAddress);
      clone.setAddressHash();
      clone.id = null;
      spouseContact.address.push(clone);
    }

    //Other Contact Information
    spouseContact.copyOtherContactInformations(this.otherContactInformation);
  }

  get contactBusinessRole(): string {
    let defaultProvinceCode = sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode);
    let ct = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactType == this.contactType);
    if (ct) {
      switch (defaultProvinceCode) {
        case PROVINCE_CODES.BRITISH_COLOMBIA: {
          return provinceBasedBusinessRoleOptions[ defaultProvinceCode ][ ct.contactType ];
          break;
        }
        default: {
          if (ct.contactKey == 'LAWCLERK') {
            return defaultProvinceCode != 'ON' ? 'Legal Assistant' : ct.label;
          }
          return ct.label;
          break;
        }
      }

    } else {
      return '';
    }
  }

  createContactProvinceCapacities(account: Account, contact: Contact, clearContactProvinceCapacities?: boolean) {
    if (clearContactProvinceCapacities) {
      // for staff profile, we need clear the object and recreate if we toggle law clerk or lawyer
      contact.contactProvinceCapacities = [];
    }
    account.accountProvinces.filter(e => e.enabled).forEach((e) => {
      let contactProvinceCapacity: ContactProvinceCapacity;
      if (contact && contact.contactProvinceCapacities && contact.contactProvinceCapacities.length > 0) {
        contactProvinceCapacity = contact.contactProvinceCapacities.find(cpc => (cpc.provinceCode == e.provinceCode));
      } else {
        contact.contactProvinceCapacities = [];
      }
      if (contactProvinceCapacity == undefined) {
        contactProvinceCapacity = new ContactProvinceCapacity();
        contactProvinceCapacity.provinceCode = e.provinceCode;
        if (contact.isSolicitor) {
          contactProvinceCapacity.provinceCapacity = 'NOTARY';
        } else {
          contactProvinceCapacity.provinceCapacity = 'NONE';
        }
        contactProvinceCapacity.insertNameAddressToRegistrationDocument = 'N_y';
        contact.contactProvinceCapacities.push(contactProvinceCapacity);
      }

    });
  }

  get isProvinceReadOnly() {
    return this.contactType === 'CONDO_CORPORATION'
      && this.selfManagedManagementCompanyType != CondoManagedTypeConstValue.MANAGEMENT_COMPANY;
  }

  get provinceCode(): string {
    //Avoif to call this.contact.mailing Address because it will create mailingAddress
    let mailingAddress: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === AddressTypes.mailing;
    });
    let option: SelectItem;
    if (mailingAddress) {
      option = dropDowns.accessibleProvinces.find(item => item.label == mailingAddress.provinceName);
    }

    return option && option.value;

  }

  get serviceAddressForDisplayingInSearchResult(): Address {
    return this.getAddressForDisplayingInSearchResult(AddressTypes.serviceAddress);
  }

  get serviceAddressTextForDisplayingInSearchResult(): string {
    return this.serviceAddressForDisplayingInSearchResult && this.serviceAddressForDisplayingInSearchResult.addressTextWithoutCountryAndPostalCode;
  }

  get mailingAddressForDisplayingInSearchResult(): string {
    return this.getAddressForDisplayingInSearchResult(AddressTypes.mailing) && this.getAddressForDisplayingInSearchResult(AddressTypes.mailing).addressTextWithoutCountryAndPostalCode;
  }

  //This method is used for extracting the address for displaying in search result. It takes care of scenario if address is same as some thing else then
  // navigates to other address and displays that.
  getAddressForDisplayingInSearchResult(addressType: string): Address {

    let addressForDisplay: Address = _.find(this.address, (address: Address) => {
      return address.addressTypeCode === addressType;
    });

    if (!addressForDisplay) {
      return null;
    }

    //For manuallyEntered the sameAsAddressTypeCode is set as OTHER (not sure why) therefore checking it.
    if (addressForDisplay.sameAsAddressTypeCode && addressForDisplay.sameAsAddressTypeCode != AddressTypes.manuallyEntered) {
      addressForDisplay = _.find(this.address, (address: Address) => {
        return address.addressTypeCode === addressForDisplay.sameAsAddressTypeCode;
      });
    }

    return addressForDisplay;
  }

  isExistingContact(): boolean {
    return this.id > 0;
  }

  initBusinessAddress() {
    let businessAddress: Address = new Address();
    businessAddress.addressTypeCode = AddressTypes.business;
    this.address.push(businessAddress);
  }

  initConsentedSpouseIdentificationRecords() {
    this.identificationRecords.push(IdentificationRecord.createIdentificationRecord());

    this.identificationRecords[ 0 ].identificationDocuments.forEach(doc => {
      doc.sameAsOfficerNameFlag = true;
    });
  }

  public loadConsentedSpouseSettings() {
    this.contactType = 'PERSON';
    this.partyRole = PartyRoleType.CONSENTED_SPOUSE;
    this.instanceType = 'person';

    if (!this.canadianResidentFlag) {
      this.canadianResidentFlag = 'Y_n';
    }
    if (!this.activeFlag) {
      this.activeFlag = DpBooleanValueTypes.Y_n;
    }

    let businessAddress: Address = this.address && this.address.find(item => item.addressTypeCode == AddressTypes.business);
    if (!businessAddress) {
      this.initBusinessAddress();
    }
    if (!this.identificationRecords || this.identificationRecords.length == 0) {
      this.initConsentedSpouseIdentificationRecords();
    }

    this.identificationRecords.forEach(record => {
      record.subject = 'spouse_consenting';
    });

    this.otherContactInformation = null;
  }

  populateDefaultOtherInfoFields(configurationValues): void {
    if (!(this.otherContactInformation && this.otherContactInformation.length > 0)) {
      configurationValues[ 'ConfigurationType' ][ 'configurationOptionValues' ].forEach(item => {
        let otherContactInformation: OtherContactInformation = new OtherContactInformation();
        otherContactInformation.fieldName = item.configurationValue;
        this.otherContactInformation.push(otherContactInformation);
      });
    } else if (this.otherContactInformation.length < 4) {
      //If there are less than 4 other info fields (ex: migrated contacts) then populating remaining slots. there should be always 4 fields.
      for (let i = this.otherContactInformation.length; i < 4; i++) {
        this.otherContactInformation.push(new OtherContactInformation());
      }
    }
  }

  getOrganizationNameOrFullName(): string {
    return this.organizationName ? this.organizationName : this.fullName;
  }

  updateRealEstateAgentFieldsWithSelectedBroker(selectedRealEstateBroker): void {
    this.organizationId = selectedRealEstateBroker.id;
    this.parentOrganizationName = selectedRealEstateBroker.organizationName;
    this.address = [];
    //this.email = "";
    //this.faxPhone = "";
    //this.telephone = [];

    if (selectedRealEstateBroker.address && selectedRealEstateBroker.address.length > 0) {
      let realEstateBrokerAddress = new Address(selectedRealEstateBroker.mailingAddress);
      realEstateBrokerAddress.id = null;
      this.address.push(realEstateBrokerAddress);
    }

    //DPPMP-12303  email no more defaulted to the selected broker's email
    //this.email = selectedRealEstateBroker.email;

    if (!this.faxPhone) {
      this.faxPhone = selectedRealEstateBroker.faxPhone;
    }

    if (!this.workPhone) {
      this.workPhone = selectedRealEstateBroker.workPhone;
    }

    if (!this.cellPhone) {
      this.cellPhone = selectedRealEstateBroker.cellPhone;
    }

    if (!this.email) {
      this.email = selectedRealEstateBroker.email;
    }

    // if(selectedRealEstateBroker.telephone && selectedRealEstateBroker.telephone.length > 0) {
    //     if(Array.isArray(selectedRealEstateBroker.telephone)) {
    //         for(let j : number = 0; j < selectedRealEstateBroker.telephone.length; j++) {
    //             let realEstateBrokerTelephone = new Telephone(selectedRealEstateBroker.telephone[j]);
    //             realEstateBrokerTelephone.id = null;
    //             this.contact.telephone[j] = realEstateBrokerTelephone;
    //         }
    //     }
    // }

  }

  //Need theses fields in staleCheck method for comparing purchaserAgent company's name. It is set in the ngInit of matterOpeningComponent
  //Need purchaserSideAgent in staleCheck method for distinguishing between saleSideAgent & purchaserSideAgent. It is set in the ngInit of matterOpeningComponent.
  updateRealEstateAgentWithBroker(sourceBrokerContact: Contact) {
    this.purchaserSideAgent = true;
    this.sourceParentOrganizationId = sourceBrokerContact.id;
    this.sourceParentOrganizationName = sourceBrokerContact.organizationName;
  }

  isMortgageeLinkedToComputerShareAll(): boolean {
    return this.lenderInstitutionName == COMPUTER_SHARE_LENDER_NAME && this.alternateName == COMPUTER_SHARE_ALTERNATE_NAME;
  }

  get isActive(): boolean {
    return 'YES' === this.activeFlag || 'Y_n' === this.activeFlag || 'Y/n' === this.activeFlag;
  }

  getNotesTitle(): string {
    return CONTACT_NOTE_CORE_TITLE +
      ((this.journalNotes && this.journalNotes.length > 0)
        ? ` (${ this.journalNotes.length })`
        : '');
  }

  isContactCategoryClient(): boolean {
    return this.contactCategory == 'CLIENT';
  }

  getEstateTrusteeLabel(matter: Matter): string {
    if (matter && (matter.isMatterProvinceAB || matter.isMatterProvinceMB || matter.isMatterProvinceSK)) {
      switch (this.estateStatusFlag) {
        case 'TESTATE':
          return 'Executor';
        case 'INTESTATE':
          return 'Administrator';
        default :
          return 'Personal Rep';
      }
    } else {
      return 'Estate Trustee';
    }
  }

  getAdditionalEmailLabel(index: string, matter: Matter): string {
    return 'Email address';
    /*let label = "'s (#" + index +") Email address";
        switch (this.gender) {
            case 'MALEPOA':
            case 'FEMALEPOA': return 'Attorney' + label;
            case 'ESTATE': return this.getEstateTrusteeLabel(matter) + label;
            case 'CORPORATION':
            case 'OTHERENTITY': return 'Signing Officer' + label;
            default : return "Email address";
        }*/
  }

  getAdditionalEmailKey(index: string, matter: Matter): string {
    let label = index + '.email';
    switch (this.gender) {
      case 'MALEPOA':
      case 'FEMALEPOA':
        return 'solicitor.contact.attorney' + label;
      case 'ESTATE':
        return matter ? 'solicitor.contact.' + this.getEstateTrusteeLabel(matter).toLowerCase().replace(' ', '') + label : '';
      case 'CORPORATION':
      case 'OTHERENTITY':
        return 'solicitor.contact.officer' + label;
      default :
        return 'Email address';
    }
  }

  hasAccessToBrokerageEmailAndPhone(): boolean {
    return this.contactType === ContactTypes.SOLICITOR && !this.privateFlag && !this.snapshotFlag;
  }

  get signingOfficerAssociationRole(): ContactAssociationRole {
    if (this.isEstate) {
      return 'ESTATE_TRUSTEE';
    }
    if (this.isMalePoaOrFemalePoa) {
      return 'POWER_OF_ATTORNEY';
    }

    return 'SIGNING_OFFICER';
  }

  get signingOfficerTypeText(): string {
    return Utils.signingOfficerTypeText(this.gender);
  }

  get signingOfficerType(): string {
    return Utils.signingOfficerType(this.gender);
  }

  getContactAssociationParticipantRoleByGender(): MatterParticipantRole {
    let matterParticipantRole: MatterParticipantRole;
    switch (this.gender) {
      case 'MALEPOA':
      case 'FEMALEPOA':
        matterParticipantRole = 'POWER_OF_ATTORNEY';
        break;
      case 'ESTATE':
        matterParticipantRole = 'ESTATE_TRUSTEE';
        break;
      case 'CORPORATION':
        matterParticipantRole = 'SIGNING_OFFICER';
        break;
      default:
        break;
    }
    return matterParticipantRole;
  }

  isMaleOrFemaleGender(): boolean {
    return this.gender == GenderTypes.MALE || this.gender == GenderTypes.FEMALE;
  }

  isBlankGender(): boolean {
    return this.gender == GenderTypes.QUESTION;
  }

  isMaleOrFemaleOrBlankGender(): boolean {
    return this.gender == GenderTypes.MALE || this.gender == GenderTypes.FEMALE || this.gender == GenderTypes.QUESTION;
  }

  duplicatedAssociationContact(selectedContactAssociationId: number) {
    return Array.isArray(this.contactAssociations) && this.contactAssociations.find(item => item.associatedContact && item.associatedContact.id == selectedContactAssociationId);
  }

  showDuplicatedAssociationContactErrorMeg(dialogService: DialogService, associationContactName: string, activeSigningOfficer?: MatterParticipantWrapper) {
    let message: string = (associationContactName ? associationContactName.trim() : '') + ' is already a '
      + this.signingOfficerType + ' for ' + (this.genericName ? this.genericName.trim() : '');
    dialogService.confirm('ERROR', message, true).subscribe(res => {
      if (activeSigningOfficer) {
        activeSigningOfficer.dataModel = null;
      }
    });
  }

  set firstEmail(val: string) {
  }

  get firstEmail(): string {
    return ContactUtil.extractFirstEmail(this.email);
  }

  getDeceasedString(): string {
    let returnString = '';

    if (this.gender && !this.isCorporation && !this.isOtherEntity) {
      returnString = this.deceased ? 'Deceased' : '';
    }
    return returnString;
  }

  getMobileNumber(): string {
    let telephone: Telephone = this.telephone.find(telephone => telephone.isCellPhone);
    if (telephone) {
      return telephone.telephoneNumber;
    }
    return '';
  }

  getGenderValue() {
    switch (this.gender) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      case 'QUESTION':
        return '';
      default :
        return this.gender;

    }
  }

  getDisplayAddress() {
    let addressToDisplay: string;

    if (this.address && this.address.length > 0) {

      let primaryAddress: Address = this.primaryAddress ? this.primaryAddress : this.address[ 0 ];
      addressToDisplay = Address.convertAddressToDisplayAddress(primaryAddress);
    }

    return addressToDisplay ? addressToDisplay : '';

  }
}
