import {Component, Inject, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SoaWater, WaterSupplyType} from './water-soa';
import moment from 'moment';
import {Moment} from 'moment';
import {dropDowns} from '../shared/matter-drop-downs';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {StatementOfAdjustmentHeading} from './model/statement-adjustment-heading';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {PartialDateComponent} from '../../shared/partial-date/partial-date.component';
import {CurrencyPipe} from '@angular/common';
import Utils from '../../shared-main/utils';
import {ModalResult} from '../../shared-main/enums';
import {Matter} from '../shared';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class WaterSoaModalContext {
  salePrice: string;
  closingDate: string;
  soaWater: SoaWater;
  provinceHstRateSlab: ConsiderationTaxes;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  sourceFromProject: boolean;
  vendorLabel: string;
  matter: Matter;
}

@Component({
  selector: 'dp-water-soa-modal-content',
  templateUrl: './water-soa.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class WaterSoaModal extends ModalComponent<WaterSoaModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('partialCommencementDate') partialCommencementDate: PartialDateComponent;
  @ViewChild('partialEndDate') partialEndDate: PartialDateComponent;
  context: WaterSoaModalContext;
  waterSupplyTypeList: SelectItem[];
  waterAdjustTypeList: SelectItem[];
  waterYesNoAdjustList: SelectItem[];
  vendorPaidYesNoList: SelectItem[];
  soaWaterLocal: SoaWater;
  closingDateLabel: string = 'Closing Date';
  // showTextNoAdjustment : boolean = false;
  // showTextMultiQuestionMarks : boolean = false;

  multiQuestoinMarksValue = '$?????????.??';
  noAdjustmentValue = 'No Adjustment';
  newAdj: boolean = false;
  isPaysForDateOfClosingVendor: boolean;

  constructor(
    public dialog: MatDialogRef<WaterSoaModal>,
    public dialogService: DialogService,
    public currencyPipe: CurrencyPipe,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: WaterSoaModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.waterSupplyTypeList = dropDowns.waterSupplyType;
    this.waterAdjustTypeList = Utils.loadTaxSelectItem(
      this.context.provinceHstRateSlab.rateType,
      this.context.provinceHstRateSlab.hstProvincialPortion
    );
    this.waterYesNoAdjustList = dropDowns.waterYesNoBoolean;
    this.vendorPaidYesNoList = dropDowns.waterYesNoBoolean;
    this.isPaysForDateOfClosingVendor = this.context.matter
      ? this.context.matter.paysForDateOfClosing === 'VENDOR'
      : false;

    if (!this.context.soaWater) {
      this.soaWaterLocal = new SoaWater();
      this.newAdj = true;
    } else {
      this.soaWaterLocal = new SoaWater(this.context.soaWater);
    }
    if (!this.soaWaterLocal.percentage) {
      this.soaWaterLocal.percentage = 0;
    }
    if (
      !this.soaWaterLocal.adjustType &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.provinceDefaultTaxRate
    ) {
      this.soaWaterLocal.adjustType = this.context.provinceHstRateSlab.provinceDefaultTaxRate;
    }
    if (!this.soaWaterLocal.waterSupplyType) {
      this.soaWaterLocal.waterSupplyType = WaterSupplyType.METERED;
    }
    if (!this.soaWaterLocal.currentWaterBillAmount) {
      this.soaWaterLocal.currentWaterBillAmount = 0;
    }
    if (!this.soaWaterLocal.adjustFor) {
      this.soaWaterLocal.adjustFor = 'false';
    }
    if (this.soaWaterLocal.fullAmountPaid == null) {
      this.soaWaterLocal.fullAmountPaid = 'true';
    }
    if (!this.soaWaterLocal.paidTowardsCurrentBilling) {
      this.soaWaterLocal.paidTowardsCurrentBilling = 0;
    }

    if (!this.soaWaterLocal.fullAmountPaid) {
      this.setVendorHasPaidField();
    }

    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  get fullAmountPaid(): boolean {
    return this.soaWaterLocal && this.soaWaterLocal.isTrueFullAmountPaid;
  }

  checkForErrors() {
    this.modalErrorComponent.removeDpFieldError('matter.soa.soawater.currentwaterbill');
    this.modalErrorComponent.removeDpSaveError('matter.soa.soawater.commencementdate');
    this.modalErrorComponent.removeDpSaveError('matter.soa.soawater.enddate');
    if (this.soaWaterLocal.isWaterSupplyTypeFlatRate) {
      let cwb: number = Number(this.soaWaterLocal.currentWaterBillAmount);
      if (cwb === 0) {
        this.modalErrorComponent.createDPFieldError('matter.soa.soawater.currentwaterbill');
      }

      if (this.partialCommencementDate.isDateComplete) {
        if (this.isCommencementDateGreaterThanClosingDate == true) {
          this.modalErrorComponent.createCustomDPSaveError(
            'matter.soa.soawater.commencementdate',
            'The Commencement date must be less than or equal to Adjustment date',
            '',
            'ERROR'
          );
        }
      } else {
        this.modalErrorComponent.createCustomDPSaveError(
          'matter.soa.soawater.commencementdate',
          'The Commencement date is invalid',
          '',
          'ERROR'
        );
      }

      if (this.partialEndDate.isDateComplete) {
        //  if(!this.soaWaterLocal.commencementDate || this.isCommencementDateGEthreshold==false) {
        //       this.modalErrorComponent.createCustomDPSaveError('matter.soa.soawater.commencementdate', 'The Commencement date must be greater than or' +
        //               ' equal to ' + this.commencementThresholdDate, '', 'ERROR');
        //   }
        if (this.soaWaterLocal.commencementDate >= this.soaWaterLocal.billingPeriodEndDate) {
          this.modalErrorComponent.createCustomDPSaveError(
            'matter.soa.soawater.enddate',
            'The End date must be greater than the Commencement date.',
            '',
            'ERROR'
          );
        }

        if (!this.isPaysForDateOfClosingVendor) {
          if (this.context.closingDate > this.soaWaterLocal.billingPeriodEndDate) {
            this.modalErrorComponent.createCustomDPSaveError(
              'matter.soa.soawater.enddate',
              'The End date must be greater than the Adjustment date',
              '',
              'ERROR'
            );
          }
        } else {
          if (this.context.closingDate >= this.soaWaterLocal.billingPeriodEndDate) {
            this.modalErrorComponent.createCustomDPSaveError(
              'matter.soa.soawater.enddate',
              'The End date must be greater than the Adjustment date',
              '',
              'ERROR'
            );
          }
        }
      } else {
        this.modalErrorComponent.createCustomDPSaveError(
          'matter.soa.soawater.enddate',
          'The End date is invalid',
          '',
          'ERROR'
        );
      }
    }
  }

  ok(): void {
    this.checkForErrors();

    if (!this.modalErrorComponent.anyErrorExist() || !this.soaWaterLocal.isWaterSupplyTypeFlatRate) {
      this.dialog.close(this.soaWaterLocal);
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  onDateChangeCommencementDate(event): void {
    const tmpDate = this.getDateToSave(event);
    if (tmpDate !== this.soaWaterLocal.commencementDate) {
      this.soaWaterLocal.commencementDate = tmpDate;
    }
  }

  onDateChangeEndDate(event): void {
    const tmpDate = this.getDateToSave(event);
    if (tmpDate !== this.soaWaterLocal.billingPeriodEndDate) {
      this.soaWaterLocal.billingPeriodEndDate = tmpDate;
    }
  }

  get commencementThreshold(): Moment {
    let threshold: Moment = moment(this.context.closingDate, 'YYYY/MM/DD');
    Utils.momentSubstractDays(threshold, this.soaWaterLocal.paymentPeriodInDays);
    threshold.add(1, 'days');

    return threshold;
  }

  //check whether commencedate greater than or equal to the  commencement Threshold or not
  get isCommencementDateGEthreshold(): boolean {
    // return moment(this.soaWaterLocal.commencementDate, "YYYY/MM/DD").isSameOrAfter(this.commencementThreshold, 'day');
    return this.soaWaterLocal.commencementDate >= this.commencementThreshold.format('YYYY/MM/DD');
  }

  get isCommencementDateGreaterThanClosingDate(): boolean {
    // let commencementDate : Moment = moment(this.soaWaterLocal.commencementDate, "YYYY/MM/DD");
    // let closingDate : Moment = moment(this.context.closingDate, "YYYY/MM/DD");
    // return commencementDate.isAfter(closingDate, 'day');// > closingDate;
    return this.soaWaterLocal.commencementDate > this.context.closingDate;
  }

  get isClosingDateGreaterThanCommencementDate(): boolean {
    // let commencementDate : Moment = moment(this.soaWaterLocal.commencementDate, "YYYY/MM/DD");
    // let closingDate = moment(this.context.closingDate, "YYYY/MM/DD");
    // return closingDate.isAfter(commencementDate, 'day');
    return this.context.closingDate > this.soaWaterLocal.commencementDate;
  }

  get isClosingDateGEcommencementDate(): boolean {
    // let commencementDate : Moment = moment(this.soaWaterLocal.commencementDate, "YYYY/MM/DD");
    // let closingDate = moment(this.context.closingDate, "YYYY/MM/DD");
    // return closingDate.isSameOrAfter(commencementDate, 'day');
    return this.context.closingDate >= this.soaWaterLocal.commencementDate;
  }

  getDateToSave(event): string {
    // let date : string;
    // date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;
    // return date;
    return event.rawDate;
  }

  getDefaultRate(type): number {
    if (
      type == this.context.provinceHstRateSlab.rateType &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.hstRate
    ) {
      return this.context.provinceHstRateSlab.hstRate;
    } else if (
      type == 'GST' &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.hstFederalPortion
    ) {
      return this.context.provinceHstRateSlab.hstFederalPortion;
    } else {
      return this.context.provinceHstRateSlab.hstRate;
    }
  }

  changeWaterAdjustType() {
    if (this.soaWaterLocal.isTrueAdjustFor) {
      this.soaWaterLocal.percentage = this.getDefaultRate(this.soaWaterLocal.adjustType);
    } else {
      this.soaWaterLocal.percentage = 0;
    }
    this.setVendorHasPaidField();
  }

  changeAdjustFor() {
    if (this.soaWaterLocal.isTrueAdjustFor) {
      this.changeWaterAdjustType();
    } else {
      this.soaWaterLocal.percentage = 0;
    }
    this.setVendorHasPaidField();
  }

  get commencementThresholdDate() {
    return this.commencementThreshold.format('MMM DD, YYYY');
  }

  get commencingLabel(): string {
    let result: string = '';
    if (this.soaWaterLocal.isTrueFullAmountPaid) {
      result += 'commencing';
    } else {
      result += 'for period commencing';
    }
    // if(this.isCommencementDateGEthreshold){
    result += ' ';
    result += this.formattedCommencementDate;
    // }
    return result;
  }

  get formattedCommencementDate() {
    return Utils.formatDateString(this.soaWaterLocal.commencementDate, true, '');
  }

  setVendorHasPaidField() {
    if (this.soaWaterLocal.isTrueFullAmountPaid) {
      this.soaWaterLocal.paidTowardsCurrentBilling =
        Number(this.soaWaterLocal.currentWaterBillAmount) +
        Number(this.soaWaterLocal.currentWaterBillAmount) * (Number(this.soaWaterLocal.percentage) / 100);
    }
  }

  get numberOfDaysElapsed(): number {
    return this.soaWaterLocal.getNumberOfDaysElapsed(
      this.context.closingDate,
      this.context.matter.isPaysForDateOfClosingVendor
    );
  }

  get numberOfDaysElapsedGTpaymentPeriodInDays(): boolean {
    return this.numberOfDaysElapsed > this.soaWaterLocal.paymentPeriodInDays;
  }

  get showQuestionMarksForVendorShare(): boolean {
    // return this.isCommencementDateGreaterThanClosingDate || !this.soaWaterLocal.commencementDate || !this.isCommencementDateGEthreshold || this.numberOfDaysElapsedGTpaymentPeriodInDays;
    return typeof this.vendorShareDays == 'string';
  }

  get vendorShareLabel(): string {
    return "'s share for "
      .concat(this.vendorShareDays.toString())
      .concat(this.showQuestionMarksForVendorShare ? ' day' : Number(this.vendorShareDays) > 1 ? ' days' : ' day');
  }

  get vendorShareDays(): number | string {
    if (
      this.isClosingDateGEcommencementDate &&
      Math.round(this.soaWaterLocal.paymentPeriodInDays) >= this.numberOfDaysElapsed
    ) {
      return this.numberOfDaysElapsed;
    } else {
      return '???';
    }
  }

  //since use 'CAD' to format 12.23 get CA$12.23, so use USD instead
  formatCurrency(value: number): string {
    return value ? this.currencyPipe.transform(value, 'USD', true, '1.2-2') : '';
  }

  get purchaserCreditValueType(): string {
    return typeof this.getPurchaserCreditValue();
  }

  getPurchaserCreditValue(): string {
    if (this.showQuestionMarksForVendorShare) {
      return this.multiQuestoinMarksValue;
    }
    if (this.getVendorCreditValue() == this.noAdjustmentValue) {
      return this.noAdjustmentValue;
    }
    if (
      this.isClosingDateGEcommencementDate &&
      Math.round(this.soaWaterLocal.paymentPeriodInDays) >= this.numberOfDaysElapsed
    ) {
      if (this.isCommencementDateGEthreshold) {
        if (this.soaWaterLocal.isTrueFullAmountPaid) {
          if (
            this.soaWaterLocal.currentWaterBillAmount +
              (this.soaWaterLocal.currentWaterBillAmount * this.soaWaterLocal.percentage) / 100 <
            this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)
          ) {
            return this.formatCurrency(
              Number(this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)) -
                Number(this.soaWaterLocal.currentWaterBillAmount) +
                Number((this.soaWaterLocal.currentWaterBillAmount * this.soaWaterLocal.percentage) / 100)
            );
          }
          if (
            this.soaWaterLocal.currentWaterBillAmount +
              (this.soaWaterLocal.currentWaterBillAmount * this.soaWaterLocal.percentage) / 100 ==
            this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)
          ) {
            return this.noAdjustmentValue;
          }
          return '';
        } else {
          if (
            this.soaWaterLocal.paidTowardsCurrentBilling <
            this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)
          ) {
            return this.formatCurrency(
              Number(this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)) -
                Number(this.soaWaterLocal.paidTowardsCurrentBilling)
            );
          }
          if (
            this.soaWaterLocal.paidTowardsCurrentBilling ==
            this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)
          ) {
            return this.noAdjustmentValue;
          }
          return '';
        }
      } else {
        return this.multiQuestoinMarksValue;
      }
    } else {
      return this.multiQuestoinMarksValue;
    }
  }

  get vendorCreditValueType(): string {
    return typeof this.getVendorCreditValue();
  }

  getVendorCreditValue(): string {
    if (this.showQuestionMarksForVendorShare) {
      return this.multiQuestoinMarksValue;
    }
    if (
      this.isClosingDateGreaterThanCommencementDate &&
      Math.round(this.soaWaterLocal.paymentPeriodInDays) >= this.numberOfDaysElapsed
    ) {
      if (this.isCommencementDateGEthreshold) {
        if (this.soaWaterLocal.isTrueFullAmountPaid) {
          if (
            Number(this.soaWaterLocal.currentWaterBillAmount) +
              Number((this.soaWaterLocal.currentWaterBillAmount * this.soaWaterLocal.percentage) / 100) >
            Number(this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor))
          ) {
            return this.formatCurrency(
              Number(this.soaWaterLocal.currentWaterBillAmount) +
                Number((this.soaWaterLocal.currentWaterBillAmount * this.soaWaterLocal.percentage) / 100) -
                Number(this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor))
            );
          } else {
            return this.noAdjustmentValue;
          }
        } else {
          if (
            this.soaWaterLocal.paidTowardsCurrentBilling >
            this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)
          ) {
            return this.formatCurrency(
              Number(this.soaWaterLocal.paidTowardsCurrentBilling) -
                Number(this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor))
            );
          } else {
            return '';
          }
        }
      } else {
        return this.multiQuestoinMarksValue;
      }
    } else {
      if (
        this.soaWaterLocal.paidTowardsCurrentBilling >
        this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor)
      ) {
        return this.formatCurrency(
          Number(this.soaWaterLocal.paidTowardsCurrentBilling) -
            Number(this.soaWaterLocal.getVendorsShare(this.context.closingDate, this.isPaysForDateOfClosingVendor))
        );
      } else {
        return this.noAdjustmentValue;
      }
    }
  }
}
