import {ConfigurationOptionValue} from './configuration-option-value';

export class Configuration {
  configurationType: string;
  configurationOptionValues: Array<ConfigurationOptionValue> = [];

  constructor(configuration: Configuration) {
    this.configurationType = configuration.configurationType;
    this.configurationOptionValues = configuration.configurationOptionValues;
  }

  getConfigurationValueByProperty(propertyName: string): ConfigurationOptionValue {
    let foundValues: ConfigurationOptionValue[] = this.configurationOptionValues.filter(configurationOptionValue => {
      if (configurationOptionValue.configurationOption.fieldName === propertyName) return configurationOptionValue;
    });

    if (foundValues.length === 0) {
      return null;
    }

    return foundValues[0];

  }
}
