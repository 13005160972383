<style>
    @media print {

        .modal-body {
            display : inline; /* Very important to allow print the fullpage not just the view */
        }

        .modal-header {
            display: none;
        }

        .modal-footer {
            display: none;
        }

        .col-lg-12 {
            width: 100%;
            float: left;
        }

        .col-lg-10 {
            width: 83%;
            float: left;
        }
        .col-lg-8 {
            width: 66%;
            float: left;
        }
        .col-lg-7 {
            width: 58%;
            float: left;
        }
        .col-lg-6 {
            width: 50%;
            float: left;
        }
        .col-lg-5 {
            width: 42%;
            float: left;
        }
        .col-lg-4 {
            width: 33%;
            float: left;
        }
        .col-lg-3 {
            width: 25%;
            float: left;
        }
        .col-lg-2 {
            width: 16%;
            float: left;
        }

        .view-matter .matter-table {
            margin-top: 0px;
        }

        .view-matter .matter-table tbody{
            top: auto;
        }

        .view-matter .matter-table .matter-inner-table {
            width: 830px;
        }
        .view-matter .matter-table .matter-inner-table th, .view-matter .matter-table .matter-inner-table td{
            min-width: 170px;
        }
    }

</style>

<div  dp-printable-dialog  >
    <div class="form-horizontal">
        <div class="modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Print Matter Overview</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <dp-matter-details-for-print
                [matter]="matter"
                [matterOverviewService]="matterOverviewService"
            ></dp-matter-details-for-print>

            <ng-container *ngIf="matterEvents?.length">
                <div class="main-level padding-bottom-10">
                    <span class="title">Matter Events</span>
                </div>
                <div class="col-lg-12 padding-bottom-10" *ngFor="let event of matterEvents">
                    <div data-testid="printMatterOverviewModal-date-div" class="col-lg-4">{{event.startDate | date : 'fullDate'}}</div>
                    <div data-testid="printMatterOverviewModal-event-div" class="col-lg-2">{{convertToTitleCase(event.getEventType())}}</div>
                    <div data-testid="printMatterOverviewModal-description-div" class="col-lg-6">
                        <span class="vertical-align-top">{{event.getEventDescription()}}</span>
                        <span class="vertical-align-top" *ngIf="event.isAppointment() && event.scheduledForSolicitorId">{{'-' + getAppointmentScheduledFor(event.scheduledForSolicitorId)}}</span>
                    </div>
                    <div class="col-lg-12" *ngIf="event && event.isAppointment()">
                        <div class="col-lg-6">
                        </div>
                        <div class="col-lg-6">
                            <span class="vertical-align-top">{{getAppointEventSpecificDescInfo(event)}} </span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="involvedParties?.length">
                <div class="main-level padding-bottom-10">
                    <span class="title">Involved Parties</span>
                </div>
                <div class="col-lg-12" >
                    <div *ngFor="let involvedParty of involvedParties" >
                        <div class="col-lg-12 form-group padding-bottom-10">
                            <div data-testid="printMatterOverviewModal-involvedRole-div" class="col-lg-2 bold">{{involvedParty.role}}</div>
                            <div data-testid="printMatterOverviewModal-involvedName-div" class="col-lg-4">{{involvedParty.name}}</div>
                            <div class="col-lg-4" >
                                {{getPartyPhones(involvedParty.telephones)}}
                            </div>
                            <div class="col-lg-2 context-word-break">{{involvedParty.email}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="opportunitiesList?.length">
                <div class="main-level padding-bottom-10">
                    <span class="title">Referrals/Shares/CIRFs.</span>
                </div>
                <div class="col-lg-12 view-matter">
                    <table class="matter-table"> <!-- We are adding this top level table to reuse global css -->
                        <tr>
                            <td>
                                <table class="table matter-inner-table"  tabindex="-1" [mfData]="opportunitiesList" #mf="mfDataTable" >
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Created On</th>
                                        <th>Last Updated</th>
                                        <th>Referred/Shared By/Sent To</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let row of mf.data; let i = index;">
                                        <td>{{row.opportunityType}}</td>
                                        <td [ngClass]="row.isPreferredOpportunity() ? 'red' : ''">{{row.statusDisplay(row.opportunityType)}}</td>
                                        <td>{{row.createdOn | date:'MMM d, y'}}</td>
                                        <td>{{row.lastUpdateOn | date:'MMM d, y'}}</td>
                                        <td><span class="text-overflow display-block">{{row.getOriginatorNameForDisplay()}}</span></td>
                                    </tr>
                                    <tr *ngIf="!opportunitiesList.length"
                                        class="loading-row">
                                        <td colspan="5" class="text-center no-match-found">
                                            <div >No Tasks Found</div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </ng-container>

        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
</div>
