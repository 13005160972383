export interface contactTypePlaceholderMapping {
  CONTACT_TYPE: string;
  PLACEHOLDER: string;
}

export const contactTypePlaceholderMapping: contactTypePlaceholderMapping[] =
  [
    {
      CONTACT_TYPE: 'REALESTATEBROKER',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'SURVEYOR',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'REALESTATEAGENT',
      PLACEHOLDER: 'Search by Name, Email Address'
    },
    {
      CONTACT_TYPE: 'PERSON',
      PLACEHOLDER: 'Search by Name'
    },
    {
      CONTACT_TYPE: 'CONDO_CORPORATION',
      PLACEHOLDER: 'Search by Name, Number or Address'
    },
    {
      CONTACT_TYPE: 'INSURANCE_BROKER',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'MANAGEMENT_COMPANY',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'MORTGAGE_BROKER',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'MORTGAGEE',
      PLACEHOLDER: 'Search by Address, Name'
    },
    {
      CONTACT_TYPE: 'PRIVATE_LENDER',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'SOLICITOR',
      PLACEHOLDER: 'Search by Name, Address'
    },
    {
      CONTACT_TYPE: 'RESIDENCE_ASSOCIATION',
      PLACEHOLDER: 'Search by Name, Address'
    }
  ];

export const DirectionRefundMaxF9Length: number = 180;
