<div class="col-lg-12">
    <div class="col-lg-12">
        <div class="main-level">

            <div *ngIf="!usedInModal" class="fixed-header">

                <div class="title" ><span>Tasks</span></div>
                <div class="pull-right margin-top-minus-65">
                    <div class="inline-block" *ngIf="filteredCheckListTemplates.length > 0">
                        <label class="control-label bold" style ="margin-right: 10px">Template Applied</label>
                    </div>
                    <div class="inline-block" *ngIf="filteredCheckListTemplates.length > 0"
                         style ="margin-right: 25px;width: 200px">
                        <select class="form-control"
                                id="checkListTemplate"
                                name="checkListTemplate"
                                [(ngModel)]="matter.appliedChecklistTemplateConfigId"
                                (ngModelChange)="applyDifferentChecklistTemplate()">
                            <option *ngFor="let template of filteredCheckListTemplates" [value]="template.id">
                                {{template.name}}
                            </option>
                        </select>
                    </div>

                    <div class="inline-block">
                        <button class="dp-btn-white margin-right-10"
                                [disabled]="isMoveUpDisabled()"
                                (click)="move('UP')">
                            <span>Move Up</span>
                        </button>
                    </div>
                    <div class="inline-block">
                        <button class="dp-btn-white margin-right-10"
                                [disabled]="isMoveDownDisabled()"
                                (click)="move('DOWN')">
                            <span>Move Down</span>
                        </button>
                    </div>
                    <div class="inline-block">
                        <button class="dp-btn-white margin-right-10"
                                [disabled]="isToggleButtonDisabled()"
                                (click)="toggleDetails()">
                            <span>Toggle Details</span>
                        </button>
                    </div>
                    <div class="inline-block">
                        <button class="dp-btn-green margin-left-10" (click)="addCategory()">
                            <span>Add Category</span>
                        </button>
                    </div>
                </div>
                <div class="form-group" *ngIf="matter">
                    <div class="col-lg-6">
                        <div class="display-inline-block padding-left-0 primary">
                            <mat-slide-toggle
                                name="internalStaff"
                                [ngModel]="matter.sendInternalNotifications == 'YES'"
                                [title]="'Internal Staff Correspondence'"
                                (change)="updateInternalNotification($event)">
                                Internal Staff Correspondence
                            </mat-slide-toggle>
                        </div>
                        <div class="display-inline-block padding-left-20 primary">
                            <mat-slide-toggle
                                name="externalStaff"
                                [ngModel]="matter.sendExternalNotifications == 'YES'"
                                [title]="'External Correspondence'"
                                (change)="updateExternalNotification($event)">
                                External Correspondence
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 padding-top-80"
                 [ngClass]="{'top-60' : matter?.isOpportunityMatter() && !usedInModal, 'top-100' : !usedInModal && !matter?.isOpportunityMatter() }"
                 *ngIf="matter && matter.matterWorkItems && matter.matterWorkItems.length">
                <table class="checklist-wrapper-table" id="tableContainer">
                    <thead class="sticky-table-header">
                    <tr class="workItem-row">
                        <th class="col1"></th>
                        <th class="col2">Description</th>
                        <th class="col3">Status</th>
                        <th class="col4">Due Date</th>
                        <th class="col5">Assignee</th>
                        <th class="col6">Completed</th>
                        <th class="col7">Correspondence</th>
                    </tr>

                    </thead>
                    <tbody [ngClass] = "{'tbody-in-modal' : usedInModal}">
                    <ng-container *ngFor="let cat of matter.matterWorkItems; let i=index">
                        <tr class="cat-row" id="row_{{cat.identifier}}"
                            [ngClass]="{'active': cat.isSelected}"
                            (click)="selectRow(i)"
                            (focus)="selectRow(i)"
                        >
                            <td class="col1">
                                <div *ngIf="cat.numberOfTasks">
                                    <div *ngIf="cat.expanded " class="padding-left-5">
                                        <img src="/assets/img/minus.svg" (click)="toggleShutter(cat)"/>
                                    </div>
                                    <div *ngIf="!cat.expanded" class="padding-left-5">
                                        <img src="/assets/img/plus.svg" (click)="toggleShutter(cat)"/>
                                    </div>
                                </div>
                            </td>
                            <td colspan="6">
                                <div class="inline-block name-ellipsis position-relative">
                                    <div *ngIf="!cat.isEditMode">
                                        <span class="bold line-heigh-25"
                                              title="{{cat.name}}"
                                              (click)="updateEditMode(cat, 'cat'+cat.identifier)">{{cat.name}}</span>
                                        <span *ngIf="cat.matterWorkItemTasks && cat.matterWorkItemTasks.length"
                                              [ngClass]="{'red' : cat.numberOfOverdueTasks > 0}"
                                        > ({{cat.numberOfCompletedTasks}}/{{cat.matterWorkItemTasks.length}})</span>
                                    </div>

                                    <div *ngIf="cat.isEditMode">
                                        <input type="text" trimmed
                                               class="form-control"
                                               id="cat{{cat.identifier}}"
                                               maxlength="50"
                                               [(ngModel)]="cat.name"
                                               (keyup)="enableSave()"
                                               (change)="updateEditMode(cat, 'cat'+cat.identifier)"
                                               (blur)="updateEditMode(cat, 'cat'+cat.identifier)"
                                               fieldKey="work-item.cat{{cat.identifier}}.name"
                                               dp-error-validator
                                        />
                                    </div>
                                </div>
                                <div class="inline-block">
                                    <dp-dropdown-menu
                                        [dropDownItems]="categoryBurgerMenuOptions"
                                        (itemClicked)="clickCategoryBurgerMenu($event, cat)"
                                        (menuOpened)="updateCategoryBurgerMenu(cat)"
                                        (burgerFocused)="selectRow(i)"
                                    >
                                    </dp-dropdown-menu>
                                </div>
                            </td>
                        </tr>
                        <ng-container *ngFor="let task of cat.matterWorkItemTasks; let idx=index">
                            <tr *ngIf="task.visible" id="row_{{task.identifier}}"
                                [ngClass]="{'red': task.isOverDue(), 'active': task.isSelected}"
                                (click)="selectRow(i, idx)"
                                (focus)="selectRow(i, idx)"
                            >
                                <td class="col1"></td>
                                <td class="col2">
                                    <div *ngIf="task.visible">
                                        <div class="inline-block">
                                            <dp-dropdown-menu
                                                [dropDownItems]="taskBurgerMenuOptions"
                                                (itemClicked)="clickTaskBurgerMenu($event, task, cat)"
                                                (menuOpened)="updateTaskBurgerMenu(task)"
                                                (burgerFocused)="selectRow(i, idx)"
                                            >
                                            </dp-dropdown-menu>
                                        </div>
                                        <div class="inline-block name-ellipsis position-relative">
                                            <span class="line-heigh-25" *ngIf="!task.isEditMode"
                                                  title="{{task.name}}"
                                                  (click)="updateEditMode(task, 'task'+task.identifier)">{{task.name}}</span>
                                            <div *ngIf="task.isEditMode">
                                                <input type="text" trimmed
                                                       class="form-control"
                                                       [ngClass]="{'red': task.isOverDue()}"
                                                       id="task{{task.identifier}}"
                                                       maxlength="100"
                                                       [(ngModel)]="task.name"
                                                       (keyup)="enableSave()"
                                                       (change)="updateEditMode(task, 'task'+task.identifier)"
                                                       (blur)="updateEditMode(task, 'task'+task.identifier)"
                                                       fieldKey="work-item.task{{task.identifier}}.name"
                                                       dp-error-validator
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </td>
                                <td class="col3">
                                    <select  class="form-control" [ngClass]="{'red': task.isOverDue()}"
                                             [(ngModel)]="task.status"
                                             (focus)="selectRow(i, idx)"
                                             (change)="onStatusChanged(task)">
                                        <option *ngFor="let status of ( (!!task.assignedToParticipantEmail || !!task.assignedToParticipantId) ? assignedTaskStatusOptions : taskStatusOptions )"
                                                [value]="status.value">{{status.label}}</option>
                                    </select>
                                </td>
                                <td class="col4">
                                    <div>
                                        <dp-partial-date
                                            id="dueDate_task{{task.identifier}}"
                                            [inputDate]="task.dueDateString"
                                            (dateChange)="onDueDateChanged($event, task)"
                                            [dateFormat]="'MMM DD, YYYY'"
                                            [fullDateRequired]="true"
                                            [dp-error-validator]="{data : 'work-item.task.dueDate_dueDate_task'+task.identifier}"
                                            fieldKey="work-item.task.dueDate"
                                            containerId="tableContainer"
                                            identifierId="dueDate_task{{task.identifier}}"
                                            [dynamicHelpCallBackFunction]="dynamicHelpForDueDate()"
                                            statusBarHelp
                                            (focusIn)="selectRow(i, idx)"
                                        >
                                        </dp-partial-date>
                                    </div>

                                </td>
                                <td class="col5">
                                    <div style="max-width: 90px;min-width: 90px;line-height: 20px;" *ngIf="task.assignedToParticipantId || task.assignedToParticipantEmail" class="inline-block position-relative">
                                        {{task.isAssignedToClient() ? 'Client' : getAssignedContactName(task.assignedToParticipantId)}}
                                    </div>
                                </td>
                                <td class="col6">
                                    <div *ngIf="task.isStatusCompleted()">
                                        {{task.completedDate | date:'mediumDate'}} - {{task.completedByUserInitials}}
                                    </div>
                                </td>
                                <td class="col7">
                                    <i class="fal fa-envelope pointerCursor margin-right-10" [ngClass]="{'not-visible' : !task.isAssigned() || (task.isAssignedToClient() && matter.sendExternalNotifications == 'NO') || (!task.isAssignedToClient() && matter.sendInternalNotifications == 'NO')}" (click)="sendQuickNotification(task)"></i>
                                    <i class="fas fa-check" [ngClass]="{'not-visible' : !cat.isAnyNotificationActive(task.id)}"></i>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td class="col1 add-task"></td>
                            <td class="col2 add-task">
                                <span>
                                    <a (click)="addTask(cat)">
                                        Add Task
                                    </a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                            </td>
                        </tr>
                        <tr><td colspan="5"><hr class="margin-top-0 margin-bottom-0"></td></tr>
                    </ng-container>
                    </tbody>
                </table>

            </div>

        </div>
    </div>
</div>
