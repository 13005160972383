import {BaseTaxAdjustmentOn, ProjectTaxAdjustmentConfig} from '../../../../projects/project-adjustments/project-tax-adjustment-config';
import {SoaRealtyTaxAdjustment} from './soa-realty-tax-adjustment';
import {ProgressionStatus, StatementAdjustment} from '../../statement-adjustment';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {Matter} from '../../../shared';
import moment from 'moment';
import {SelectItem} from 'primeng/api';
import {Project} from '../../../../projects/shared/project';
import Utils from '../../../../shared-main/utils';

export class SoaRealtyTaxAdjustmentUtil {

  static calculateYearForRealtyTax(interimTaxAdjustmentDate: string, finalTaxAdjustmentDate: string, adjustmentStatus: string): string {
    if (adjustmentStatus === ProgressionStatus.INTERIM) {
      let momentInAction: moment.Moment = moment(interimTaxAdjustmentDate);
      //moment(this.closingDate, "YYYY/MM/DD");
      let lastYear: string = momentInAction.subtract(1, 'year').year() + '';

      return lastYear;
    } else {
      let differenceInAdjustmentDays = moment(interimTaxAdjustmentDate).diff(moment(finalTaxAdjustmentDate), 'days');
      let finalAdjustmentDateYear = moment(finalTaxAdjustmentDate).year();
      let interimAdjustmentDateYear = moment(interimTaxAdjustmentDate).year();
      let noOfDaysInYear = moment(finalAdjustmentDateYear + '/12/31').diff(moment(finalAdjustmentDateYear + '/01/01'), 'days') + 1;
      if (differenceInAdjustmentDays > noOfDaysInYear) {
        return finalAdjustmentDateYear.toString();
      } else {
        return (finalAdjustmentDateYear - 1).toString();
      }
    }
  }

  static calculateNoOfDaysLeft(occupancyDate: string, closingDate: string): string {
    if (this.isYearSameForDates(occupancyDate, closingDate)) {
      return '???';
    } else {
      if (this.isValidDate(occupancyDate)) {
        let occupancyDateYear = moment(occupancyDate).year();
        return (moment(occupancyDateYear + '/12/31').diff(moment(occupancyDate), 'days') + 1).toString();
      } else {
        return '???';
      }

    }
  }

  static calculateVendorShare(matter: Matter, closingDate: string, baseTaxAdjustmentType: string, soaRealtyTaxAdjustment: SoaRealtyTaxAdjustment) {
    let occupancyDateForRealtyTax: string = this.getOccupancyDateForRealtyTax(matter);
    let numberOfDaysInOccupancyYear: number = Utils.daysInYear(occupancyDateForRealtyTax);
    if (this.isYearSameForDates(occupancyDateForRealtyTax, closingDate)) {
      soaRealtyTaxAdjustment.creditAmount = 0;
    } else {
      if (baseTaxAdjustmentType === BaseTaxAdjustmentOn.percentageOfTaxesForProject || baseTaxAdjustmentType === BaseTaxAdjustmentOn.EqualDivisionAmongUnits) {
        soaRealtyTaxAdjustment.creditAmount = Number((soaRealtyTaxAdjustment.previousYearTotalTaxes / numberOfDaysInOccupancyYear) * Number(soaRealtyTaxAdjustment.noOfDaysLeft) * (soaRealtyTaxAdjustment.purchaserPortionOfTotalTaxes / 100));
      }
      if (baseTaxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice || baseTaxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits) {
        soaRealtyTaxAdjustment.creditAmount = Number((soaRealtyTaxAdjustment.previousYearTotalTaxes / numberOfDaysInOccupancyYear) * Number(soaRealtyTaxAdjustment.noOfDaysLeft));
      }
    }
  }

  static isYearSameForDates(occupancyDate: string, closingDate: string): boolean {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (pattern.test(occupancyDate) && pattern.test(closingDate)) {
      let occupancyDateYear = moment(occupancyDate).year();
      let closingDateYear = moment(closingDate).year();
      return occupancyDateYear === closingDateYear;
    }
    return false;
  }

  static calculateDifferenceInYears(occupancyDate: string, closingDate: string): number {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (pattern.test(occupancyDate) && pattern.test(closingDate)) {

      return moment(occupancyDate).diff(moment(closingDate), 'years');
    }
    return null;
  }

  static updateRealtyTaxAdjustmentOnDateChange(matter: Matter) {
    let realtyTaxSOAList: StatementAdjustment[] = matter.statementOfAdjustments.filter(adj => adj.itemKey === StatementAdjustmentKey.REALTY_TAXES);
    if (realtyTaxSOAList) {
      realtyTaxSOAList.forEach(realtyTaxAdjustment => {
        this.populateSoaRealtyTaxAdjustmentFields(realtyTaxAdjustment.soaRealtyTaxAdjustment, matter);
        this.calculateVendorShare(matter, matter.matterCloseDate, this.getBaseTaxAdjustment(matter).taxAdjustmentType
          , realtyTaxAdjustment.soaRealtyTaxAdjustment);
        realtyTaxAdjustment.amount = realtyTaxAdjustment.soaRealtyTaxAdjustment.creditAmount;
        realtyTaxAdjustment.description = realtyTaxAdjustment.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear + ' REALTY TAXES';
        //this.onRealtyTaxAdjustmentUpdate(realtyTaxAdjustment.soaRealtyTaxAdjustment,realtyTaxAdjustment);
      });
    }
  }

  static updateRealtyTaxAdjustment(realtyTaxAdjustment: StatementAdjustment, matter: Matter) {
    this.populateSoaRealtyTaxAdjustmentFields(realtyTaxAdjustment.soaRealtyTaxAdjustment, matter);
    this.calculateVendorShare(matter, matter.matterCloseDate, this.getBaseTaxAdjustment(matter).taxAdjustmentType
      , realtyTaxAdjustment.soaRealtyTaxAdjustment);
    realtyTaxAdjustment.amount = realtyTaxAdjustment.soaRealtyTaxAdjustment.creditAmount;
    realtyTaxAdjustment.description = realtyTaxAdjustment.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear + ' REALTY TAXES';
  }

  static getBaseTaxAdjustment(matter: Matter): ProjectTaxAdjustmentConfig {
    if (matter.templateForProject) {
      return matter.adjustmentStatusMode === ProgressionStatus.FINAL ?
        matter.project.projectAdjustmentConfig.taxesSameAsInterim ? matter.project.projectAdjustmentConfig.interimTaxAdjustment
          : matter.project.projectAdjustmentConfig.finalTaxAdjustment
        : matter.project.projectAdjustmentConfig.interimTaxAdjustment;

    } else {
      return matter.project && matter.adjustmentStatusMode === ProgressionStatus.FINAL ?
        matter.project.projectAdjustmentConfig.taxesSameAsInterim ? matter.project.projectAdjustmentConfig.interimTaxAdjustment
          : matter.project.projectAdjustmentConfig.finalTaxAdjustment
        : matter.project && matter.project.projectAdjustmentConfig.interimTaxAdjustment ? matter.project.projectAdjustmentConfig.interimTaxAdjustment : null;
    }

  }

  static populateSoaRealtyTaxAdjustmentFields(soaRealtyTaxAdjustment: SoaRealtyTaxAdjustment, matter: Matter) {
    soaRealtyTaxAdjustment.realtyTaxAdjustmentYear = this.calculateYearForRealtyTax(this.getAdjustmentCalculationDate(ProgressionStatus.INTERIM, matter),
      this.getAdjustmentCalculationDate(ProgressionStatus.FINAL, matter), matter.adjustmentStatusMode);
    soaRealtyTaxAdjustment.noOfDaysLeft = this.calculateNoOfDaysLeft(this.getOccupancyDateForRealtyTax(matter), this.getClosingDateForRealtyTax(matter));
    //soaRealtyTaxAdjustment.occupancyDate = this.getOccupancyDateForRealtyTax(matter);
    this.populatePurchaserPortionAndTotalTaxes(this.getBaseTaxAdjustment(matter), soaRealtyTaxAdjustment, matter);
  }

  static getAdjustmentCalculationDate(adjustmentStatus: string, matter: Matter): string {
    if (adjustmentStatus === ProgressionStatus.INTERIM) {
      if (matter && matter.adjustAsAtClosingDateFlagInterim === 'SPECIFY') {
        return matter.adjustAsAtClosingDateInterim;
      } else if (matter && matter.adjustAsAtClosingDateFlagInterim === 'OCCUPANCY_DATE') {
        return matter.matterOccupancyDate;
      } else {
        return matter.matterCloseDate;
      }
    } else {
      if (matter && matter.adjustAsAtClosingDateFlag === 'SPECIFY') {
        return matter.adjustAsAtClosingDate;
      } else if (matter && matter.adjustAsAtClosingDateFlag === 'OCCUPANCY_DATE') {
        return matter.matterOccupancyDate;
      } else {
        return matter.matterCloseDate;
      }
    }
  }

  static populateAdjustmentYearForTaxesPaidByVendor(matter: Matter): SelectItem[] {
    let adjustmentYearOptions = [];
    let interimAdjustmentYear = moment(this.getAdjustmentCalculationDate(ProgressionStatus.INTERIM, matter)).year();
    let finalAdjustmentYear = moment(this.getAdjustmentCalculationDate(ProgressionStatus.FINAL, matter)).year();
    if ((finalAdjustmentYear - interimAdjustmentYear) == 0) {
      adjustmentYearOptions.push({label: finalAdjustmentYear, value: finalAdjustmentYear});
    } else {
      for (let i = 0; (finalAdjustmentYear - interimAdjustmentYear) > i; i++) {
        adjustmentYearOptions.push({label: finalAdjustmentYear - i, value: finalAdjustmentYear - i});
      }
    }

    return adjustmentYearOptions;
  }

  static getOccupancyDateForRealtyTax(matter: Matter): string {
    return matter.templateForProject ? matter.fileOpenDate : matter.isMatterProvinceON ?
      this.isValidDate(matter.requisitionDate) ? matter.requisitionDate : '???'
      : this.isValidDate(matter.occupancyDate) ? matter.occupancyDate : '???';
  }

  static isValidDate(date: string): boolean {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    return pattern.test(date);
  }

  static getClosingDateForRealtyTax(matter: Matter) {
    return matter.templateForProject ? matter.fileOpenDate : matter.matterCloseDate;
  }

  static populatePurchaserPortionAndTotalTaxes(baseTaxAdjustment: ProjectTaxAdjustmentConfig, soaRealtyTaxAdjustment: SoaRealtyTaxAdjustment, matter: Matter): void {
    if (baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfTaxesForProject) {
      soaRealtyTaxAdjustment.purchaserPortionOfTotalTaxes = SoaRealtyTaxAdjustmentUtil.totalCondominiumExpenses(matter);
      soaRealtyTaxAdjustment.previousYearTotalTaxes = baseTaxAdjustment.totalTaxesPreviousYear ? baseTaxAdjustment.totalTaxesPreviousYear : 0;

    } else if (baseTaxAdjustment.taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits) {
      soaRealtyTaxAdjustment.purchaserPortionOfTotalTaxes = baseTaxAdjustment.numberOfUnits == 0 ? 0 : (1 / Number(baseTaxAdjustment.numberOfUnits) * 100);
      soaRealtyTaxAdjustment.previousYearTotalTaxes = baseTaxAdjustment.totalTaxesPreviousYear ? baseTaxAdjustment.totalTaxesPreviousYear : 0;
    } else if (baseTaxAdjustment.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice) {
      if (soaRealtyTaxAdjustment.adjustmentBasedOnPercentage === 'YES') {
        soaRealtyTaxAdjustment.previousYearTotalTaxes = Number(baseTaxAdjustment.unitPurchasePrice / 100) * Number(SoaRealtyTaxAdjustmentUtil.agreedSalePrice(matter));
      }
    } else if (baseTaxAdjustment.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits) {
      soaRealtyTaxAdjustment.previousYearTotalTaxes = Number(baseTaxAdjustment.unitPurchasePrice / 100) * Number(SoaRealtyTaxAdjustmentUtil.totalIncludingTax(matter));

    }
  }

  static totalCondominiumExpenses(matter: Matter): number {
    if (matter.isMatterProvinceON) {
      return Number(matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
    } else {
      return Number(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes);
    }

  }

  static agreedSalePrice(matter: Matter): number {
    return matter.considerationLtt && matter.considerationLtt.salePriceAdjustment.agreementSalePrice;
  }

  static isAdditionalConsiderationExists(matter: Matter): boolean {
    return (matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst > 0);
  }

  static totalIncludingTax(matter: Matter): number {
    return matter.considerationLtt && matter.considerationLtt.salePriceAdjustment.totalPriceWithTax();
  }

  static calculateYearForRealtyTaxPaidByVendor(interimTaxAdjustmentDate: string, finalTaxAdjustmentDate: string, adjustmentStatus: string): string {
    if (adjustmentStatus === ProgressionStatus.INTERIM) {
      return moment(interimTaxAdjustmentDate).year().toString();
    } else {
      let momentInAction: moment.Moment = moment(interimTaxAdjustmentDate);
      let nextYear: number = momentInAction.add(1, 'year').year();
      let finalAdjustmentDateYear = moment(finalTaxAdjustmentDate).year();
      let interimAdjustmentDateYear = moment(interimTaxAdjustmentDate).year();
      return nextYear > finalAdjustmentDateYear ? interimAdjustmentDateYear.toString() : (nextYear + '-' + finalAdjustmentDateYear).toString();
    }
  }

  static updateRealtyTaxesPaidByVendor(matter: Matter, project ?: Project) {

    //project object is optional in case the Adjustment Config is updated
    if (project) {
      matter.project = new Project(project);
    }
    let realtyTaxesPaidByVendorSOAList: StatementAdjustment[] = matter.statementOfAdjustments.filter(adj => adj.itemKey === StatementAdjustmentKey.REALTY_TAXES_PAID_BY_VENDOR);
    if (realtyTaxesPaidByVendorSOAList) {
      realtyTaxesPaidByVendorSOAList.forEach(realtyTaxAdjustment => {
        if (realtyTaxAdjustment.matterTax) {
          if (realtyTaxAdjustment.matterTax.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && matter.project && matter.project.projectAdjustmentConfig) {
            matter.updatePropertyTaxForProject(realtyTaxAdjustment.matterTax, matter.projectTaxAdjustmentConfig);
          }
          if (realtyTaxAdjustment.matterTax.vendorPaidFullAmount == 'YES') {
            realtyTaxAdjustment.matterTax.vendorWillHavePaid = realtyTaxAdjustment.matterTax.totalTaxes;
          }
          // realtyTaxAdjustment.matterTax.adjustmentYearTaxesPaidByVendor = moment(SoaRealtyTaxAdjustmentUtil.getAdjustmentCalculationDate(matter.adjustmentStatusMode , matter)).year();
        }

        realtyTaxAdjustment.description = realtyTaxAdjustment.matterTax.getAdjustmentHeadingForPaidByVendor();
        //matterTax.adjustmentYearTaxesPaidByVendor +' '+ taxTypeSelected.label + ' PAID BY VENDOR'
        realtyTaxAdjustment.amount = realtyTaxAdjustment.matterTax.calculateVendorShareForPaidByVendor(matter.projectTaxAdjustmentConfig, true, matter);
      });
    }
  }

}
