import {Matter} from './shared/matter';
import {UUIDUtil} from '../main/uuid-util';
import {BaseEntity} from '../shared/BaseEntity/base-entity';
import {Mortgage} from './shared/mortgage';
import {Contact} from './shared/contact';
import {Jurisdiction, RegistryOffice} from './shared/jurisdiction';
import {MatterParticipant} from './shared/matter-participant';
import {TitleInsurancePolicy} from './title-insurance/title-insurance-policy';
import {AltoEForm} from '../shared-main/alto/alto-eform';
import {UserDefinedField} from '../shared-main/user-defined-field/user-defined-field';
import {ERegistrationForm} from './forms/eregistration/eregistrationform';
import {StatementAdjustment} from './statement-adjustment/statement-adjustment';
import {MatterHoldback} from './shared/advance-holdback/matter-holdback';
import {FamilyLawAct} from './shared/fla-data';
import {MatterUndertaking} from './shared/matter-undertaking';
import {PermittedRegistration} from './shared/permitted-registration';
import {MatterSupplementalTaskCategory} from './shared/matter-supplemental-task-category';
import {MatterTax} from './shared/property-taxes';
import {MatterSoaSheet} from '../../app/matters/shared/matter-soa-sheet';
import {ContactAssociation} from './shared/contact-association';
import {MatterProperty} from './shared';

export class MatterIdCleaner {

  static clearMatterIds(matter: Matter): Matter {
    let idMappings: Map<number, number> = new Map();
    MatterIdCleaner.cleanAllIds(matter, idMappings, matter);
    let matterJson: string = JSON.stringify(matter);

    idMappings.forEach((newId, existingId) => {
      if (existingId && newId) {
        //Looking for exact match and everywhere in existingId to replace it with the newId
        let oldId = new RegExp('\\b' + existingId.toString() + '\\b', 'g');
        matterJson = matterJson.replace(oldId, newId.toString());
      }
    });

    matter = new Matter(JSON.parse(matterJson) as Matter);
    return matter;
  }

  static cleanAllIds(obj: any, idMappings: Map<number, number>, matter: Matter): void {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (BaseEntity.isIdProperty(property) && obj[ property ] != null) {
          if (UUIDUtil.isClientSideAssignedId(obj)) {
            if (obj instanceof Mortgage) {
              MatterIdCleaner.clearMortgageId(obj as Mortgage, matter);
            } else if (obj instanceof MatterParticipant) {
              MatterIdCleaner.clearMatterParticipantId(obj as MatterParticipant, matter);
            } else if (obj instanceof MatterUndertaking) {
              MatterIdCleaner.clearMatterUndertakingId(obj as MatterUndertaking, matter);
            } else if (obj instanceof PermittedRegistration) {
              MatterIdCleaner.clearPermittedRegistrationId(obj as PermittedRegistration, matter);
            } else if (obj instanceof MatterHoldback) {
              MatterIdCleaner.clearMatterHoldbackId(obj as MatterHoldback, matter);
            } else if (obj instanceof MatterSupplementalTaskCategory) {
              MatterIdCleaner.clearSupplementalTaskId(obj as MatterSupplementalTaskCategory, matter);
            } else if (obj instanceof MatterSoaSheet) {
              MatterIdCleaner.clearMatterSoaSheet(obj as MatterSoaSheet, matter);
            } else {
              if (!idMappings.has(obj[ property ])) {
                const newId = UUIDUtil.getUUID();
                /*
                * Some very old ids created before start creating them from Front end
                * Those ids will have small numbers.
                * We will not add them to the mapping to avoid messing up other values
                */
                if (obj[ property ].toString().length > 5) {
                  //Keeping the mapping between old & new UUID
                  idMappings.set(obj[ property ], newId);
                }
                obj[ property ] = newId;
              }

            }

          } else {
            //if server side assigned id then nullify it
            if (obj instanceof Contact) {
              MatterIdCleaner.clearContactId(obj as Contact);
            } else if (obj instanceof Jurisdiction || obj instanceof RegistryOffice) {
              //jurisdiction in matter property is just a reference so not clearing it's id
            } else {
              obj[ property ] = null;
            }
          }
        } else if (obj[ property ] instanceof ContactAssociation) {
          obj[ property ].id = null;
        } else if (obj[ property ] instanceof Object) {
          this.cleanAllIds(obj[ property ], idMappings, matter);
        }
      }
    }
  }

  private static clearMatterParticipantId(matterParticipant: MatterParticipant, matter: Matter): void {
    const newId = UUIDUtil.getUUID();
    MatterIdCleaner.replaceMatterParticipantId(matterParticipant, matter, newId);
  }

  public static replaceMatterParticipantId(matterParticipant: MatterParticipant, matter: Matter, replacementId: number): void {
    if (matterParticipant && matterParticipant.familyLawActs && matterParticipant.familyLawActs.length) {
      matterParticipant.familyLawActs.forEach((fla) => {
        if (fla.matterParticipantId) {
          fla.matterParticipantId = replacementId;
        }
        let consentedSpouseParticipant = matterParticipant.consentingSpouseParticipant;
        if (consentedSpouseParticipant) {//familyLawActStatementType: "CONSENTED_SPOUSE"
          consentedSpouseParticipant.parentParticipantId = replacementId;
          if (consentedSpouseParticipant.contact) {
            consentedSpouseParticipant.contact.id = null;
          }
        }
      });
    }
    // If matterParticipant is consenting spouse, we need update parent participant  the consentSpouseMatterParticipantId of fla
    if (matterParticipant.isConsentingSpouseParticipant() && matter.matterParticipants) {
      let parentParticipant: MatterParticipant = matter.matterParticipants.find(item => item.matterParticipantId == matterParticipant.parentParticipantId);
      if (parentParticipant) {
        let fla: FamilyLawAct = parentParticipant.getConsentedSpouseFamilyLawAct();
        if (fla) {
          fla.consentSpouseMatterParticipantId = replacementId;
        }
      }
    }

    let matterParticipants = matter.matterParticipants;
    if (matterParticipants && matterParticipants.length) {
      matterParticipants.forEach((mp) => {
        let matterParticipantSpouseFamilyLawAct: FamilyLawAct = mp.getMatterParticipantSpouseFamilyLawAct();
        if (matterParticipantSpouseFamilyLawAct && matterParticipantSpouseFamilyLawAct.spouseMatterParticipantId == matterParticipant.matterParticipantId) {
          matterParticipantSpouseFamilyLawAct.spouseMatterParticipantId = replacementId;
        }
      });
    }
    let signerMatterParticipants = matter.getAllSigners(matterParticipant);
    if (signerMatterParticipants && signerMatterParticipants.length) {
      signerMatterParticipants.forEach((mp) => {
        mp.parentParticipantId = replacementId;
      });
    }

    matterParticipant.matterParticipantId = replacementId;
  }

  private static clearMatterUndertakingId(matterUndertaking: MatterUndertaking, matter: Matter): void {
    let newId = UUIDUtil.getUUID();
    let mortgage = matter.existingMortgages.find(mtg => mtg.undertakingId == matterUndertaking.id);
    if (mortgage) {
      mortgage.undertakingId = newId;
    }

    matterUndertaking.id = newId;
  }

  private static clearPermittedRegistrationId(permittedRegistration: PermittedRegistration, matter: Matter): void {
    let newId = UUIDUtil.getUUID();
    let mortgage = matter.existingMortgages.find(mtg => mtg.permittedRegistrationId == permittedRegistration.id);
    if (mortgage) {
      mortgage.permittedRegistrationId = newId;
    }
    permittedRegistration.id = newId;
  }

  private static clearMatterHoldbackId(holdback: MatterHoldback, matter: Matter): void {
    let newId = UUIDUtil.getUUID();
    let matterTrustLedgers = matter.matterTrustLedgers.filter(tl => tl.linkKey == String(holdback.id));
    if (matterTrustLedgers && matterTrustLedgers.length) {
      matterTrustLedgers.forEach((matterTrustLedger) => {
        matterTrustLedger.linkKey = String(newId);
      });
    }
    holdback.id = newId;
  }

  private static clearStatementAdjustmentId(statementAdjustment: StatementAdjustment, matter: Matter): void {
    let newId = UUIDUtil.getUUID();
    if (statementAdjustment.matterTax) {
      statementAdjustment.matterTax.id = UUIDUtil.getUUID();
    }
    statementAdjustment.id = newId;
  }

  private static clearSupplementalTaskId(matterSupplementalTask: MatterSupplementalTaskCategory, matter: Matter): void {
    let newId = UUIDUtil.getUUID();
    let matterTaxRate = matter.matterTaxRates.find(taxRate => taxRate.matterSupplementalTaskCategoryId == matterSupplementalTask.id);
    if (matterTaxRate) {
      matterTaxRate.matterSupplementalTaskCategoryId = newId;
    }
    matterSupplementalTask.id = newId;
  }

  private static clearMatterSoaSheet(matterSoaSheet: MatterSoaSheet, matter: Matter): void {
    let newId = UUIDUtil.getUUID();
    let matterTrustLedger = matter.matterTrustLedgers.find(tl => tl.matterSoaSheetId == matterSoaSheet.id);
    if (matterTrustLedger) {
      matterTrustLedger.matterSoaSheetId = newId;
    }
    if (matter.matterTaxRates) {
      let matterTaxRate = matter.matterTaxRates.find(tl => tl.matterSoaSheetId == matterSoaSheet.id);
      if (matterTaxRate) {
        matterTaxRate.matterSoaSheetId = newId;
      }
    }
    matterSoaSheet.id = newId;
  }

  private static clearMatterTaxId(matterTax: MatterTax, matter: Matter): void {
    let oldId = matterTax.id;
    let newId = UUIDUtil.getUUID();
    if (matter.finalStatementAdjustments) {
      let adjustments: StatementAdjustment[] = matter.finalStatementAdjustments.filter(value => value.propertyMatterTaxId == oldId);
      adjustments.forEach(value => {
        value.propertyMatterTaxId = newId;
      });
    }
    if (matter.interimStatementAdjustments) {
      let adjustments: StatementAdjustment[] = matter.interimStatementAdjustments.filter(value => value.propertyMatterTaxId == oldId);
      adjustments.forEach(value => {
        value.propertyMatterTaxId = newId;
      });
    }

    matterTax.id = newId;
  }

  private static clearMortgageId(mortgage: Mortgage, matter: Matter): void {
    const newId = matter.generateMortgageId();
    MatterIdCleaner.replaceMortgageId(mortgage, matter, newId);
  }

  public static replaceMortgageId(mortgage: Mortgage, matter: Matter, replacementId: number): void {
    //replacing the mortgage id from references
    let participants: MatterParticipant[] = matter.getMatterParticipantsByMortgage(mortgage);
    if (participants) {
      participants.forEach(participant => {
        participant.mortgageId = replacementId;
      });
    }

    let holdbacks: MatterHoldback[] = matter.getHoldBacksByMortgageId(mortgage.id);
    if (holdbacks && holdbacks.length) {
      holdbacks.forEach(hb => {
        hb.mortgageId = replacementId;
      });
    }

    if (matter.matterTitleInsurance && matter.matterTitleInsurance.policies && matter.matterTitleInsurance.policies.length > 0) {
      let mortgagePolicies: TitleInsurancePolicy[] = matter.matterTitleInsurance.policies.filter(policy => policy.mortgageId == mortgage.id);
      mortgagePolicies.forEach(policy => {
        policy.mortgageId = replacementId;
      });
    }

    if (matter.altoEForms && matter.altoEForms.length > 0) {
      let altoEForms: AltoEForm[] = matter.altoEForms.filter(altoEForm => altoEForm.mortgageId == mortgage.id);
      altoEForms.forEach(altoEForm => {
        altoEForm.mortgageId = replacementId;
      });
    }

    if (matter.eRegistrationForms && matter.eRegistrationForms.length > 0) {
      let eRegistrationForms: ERegistrationForm[] = matter.eRegistrationForms.filter(eRegistrationForm => eRegistrationForm.mortgageId == mortgage.id);
      eRegistrationForms.forEach(eRegistrationForm => {
        eRegistrationForm.mortgageId = replacementId;
      });
    }

    if (matter.matterUserDefinedFields && matter.matterUserDefinedFields.length > 0) {
      let matterUserDefinedFields: UserDefinedField[] = matter.matterUserDefinedFields.filter(userDefinedField => userDefinedField.mortgageId == mortgage.id);
      matterUserDefinedFields.forEach(userDefinedFields => {
        userDefinedFields.mortgageId = replacementId;
      });
    }

    if (matter.finalStatementAdjustments) {
      let adjustments: StatementAdjustment[] = matter.finalStatementAdjustments.filter(value => value.soAdjVTBMortgage && value.soAdjVTBMortgage.mortgageId == mortgage.id);
      adjustments.forEach(value => {
        value.soAdjVTBMortgage.mortgageId = replacementId;
      });
    }

    if (matter.interimStatementAdjustments) {
      let adjustments: StatementAdjustment[] = matter.interimStatementAdjustments.filter(value => value.soAdjVTBMortgage && value.soAdjVTBMortgage.mortgageId == mortgage.id);
      adjustments.forEach(value => {
        value.soAdjVTBMortgage.mortgageId = replacementId;
      });
    }
    if (matter.getBlanketMortgageProperties(mortgage.id)) {
      let blanketMorgageProperties: MatterProperty[] = matter.getBlanketMortgageProperties(mortgage.id);
      blanketMorgageProperties.forEach(blanketMortgageProperty => {
        blanketMortgageProperty.mortgageId = replacementId;
      });
    }
    mortgage.id = replacementId;
  }

  private static clearContactId(contact: Contact): void {
    //Clear contact id only if it's snapshot or sourceContactId is null (assumption is all original contacts should have source contact id populated)
    if (contact.snapshotFlag || !contact.sourceContactId) {
      contact.id = null;
    }
  }
}

