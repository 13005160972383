<!-- Modal - Private lenders title screen -->
<form class="form-horizontal">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10 padding-left-0"><h1>{{header}}</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="modal-body padding-top-0">
        <div *ngFor="let lender of privateLenders" class="col-lg-12 padding-left-0 private-lender-section">
            <div class="col-lg-12 boldfont margin-bottom-20">
                <span class="h4">{{getLenderName(lender.contact.fullOrBusinessName)}}</span>
            </div>
            <div class="col-lg-12 padding-left-0 lender-info-field">
                <div class="col-lg-1">
                    Capacity:
                </div>
                <div class="col-lg-11">
                    {{getPurchaserCapacity(lender)}}
                </div>
            </div>
            <div class="col-lg-12 padding-left-0 lender-info-field">
                <div class="col-lg-1">
                    Share:
                </div>
                <div class="col-lg-11">
                    {{lender.purchaserShare}}
                </div>

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-lg-12">
            <div class="row padding-top-20">
                <div class="col-lg-12 text-center">
                    <button type="button" id="okBtn" (click)="close()" class="dp-btn">
                    <span>OK</span>
                </button>
                </div>
            </div>
        </div>
    </div>
</form>
