import {Matter} from './matter';
import {MatterProperty} from './matter-property';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';

export class PropertyPinHelper {

  constructor() {
  }

  isAddAnotherPinDisplayed(matter: Matter): boolean {
    return matter && !matter.isTemplateMatterForMassUpdate
      && matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isCondominium != 'YES'
      && matter.matterProperties.length < 99;
  }

  getPid(matter: Matter): number {
    if (matter && matter.isMatterProvinceNB) {
      return 8;
    }
    if (matter && matter.isMatterProvinceNS) {
      return 9;
    }
    return 20;
  }

  getPANMaxLength(matter: Matter): number {
    if (matter.isMatterProvinceNB) {
      return 8;
    }
    return 20;
  }

  getPinFieldKey(matter: Matter, isPin1: boolean): string {
    if (matter && matter.isPartialPinAllowed) {
      return isPin1 ? 'matter.propertyteranet.pin1.warningOnly' : 'matter.propertyteranet.pin.warningOnly';
    } else {
      return isPin1 ? 'matter.propertyteranet.pin1' : 'matter.propertyteranet.pin';
    }
  }

  validatePinWithPan(errorService: ErrorService, matter: Matter, matterProperty: MatterProperty, identifier: number, index: number, isPin1: boolean) {
    if (matter.isMatterProvinceNBorNS) {
      errorService.removeDpFieldError('pinnumber_' + String(matterProperty.identifier));
      errorService.removeDpFieldError('pan' + String(identifier));

      if (matterProperty.pin && !matterProperty.assessmentAccountNumberSummary) {
        let accountName = matter.isMatterProvinceNB ? 'PAN' : 'AAN';
        errorService.addDpFieldError(DPError.createCustomDPError('pan' + String(identifier), accountName + `${ index } is required`, 'Subject Property', 'ERROR'));
        matterProperty.isPinValid = false;
      }
      if (!matterProperty.pin && matterProperty.assessmentAccountNumberSummary
        && !(matter.isMatterProvinceNB && matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPurchaseTypeMobileHome())) {
        errorService.addDpFieldError(DPError.createCustomDPError(
          'pinnumber_' + matterProperty.identifier, `PID Number ${ index } is required`, 'Subject Property', 'ERROR'));
        matterProperty.isPinValid = false;
      }
      let requiredPidLength = this.getPid(matter);
      if (matterProperty.pin && matterProperty.pin.length != requiredPidLength && !matter.isMatterProvinceNS) {
        let extraProvinceText = matter.isMatterProvinceNB ? 'digits' : 'characters (NS9999999)';
        errorService.addDpFieldError(DPError.createCustomDPError(
          'pinnumber_' + matterProperty.identifier, `PID Number ${ index } is invalid. PID must be ${ requiredPidLength } ${ extraProvinceText }`,
          'Subject Property', 'ERROR'));
        matterProperty.isPinValid = false;
      }
    }
  }

  getPinLabel(matter: Matter): string {
    return matter.isMatterProvinceBCorNBorNS ? 'PID Number' : 'PIN';
  }

  getAANLabel(matter: Matter): string {
    return matter.isMatterProvinceNB ? 'PAN' : 'AAN';
  }
}
