import {SoaMatter} from '../statement-account/soa-matter';
import * as _ from 'lodash';
import {SoaFee} from '../../shared-main/statement-account/fee-config/soa-fees';
import {DisbursementConfig} from '../../shared-main/statement-account/disbursement-config/disbursement-config';
import {SoaConfig} from '../../admin/soa/soa-config';
import {AccountingItemKeys, SoaConstants} from '../../admin/soa/soa-constants';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {StatementConfig} from '../../admin/shared/statement-config';
import {TrustLedgerMatter} from '../trust-ledger/trust-ledger-matter';
import {TrustLedger} from '../../admin/trust-ledger/trust-ledger';
import {TrustLedgerConfig} from '../../admin/trust-ledger/config/trust-ledger-config';
import {MortgageTerm} from './mortgage-term';
import {Matter} from './matter';
import {MatterType, MatterTypesValue} from './index';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {SoaFeeRate} from '../consideration-ltt/soa-fee-rate';
import Utils from '../../shared-main/utils';
import {Mortgage} from './mortgage';
import {Compliance} from '../compliance/compliance';
import {MatterSupplementalTaskCategory} from './matter-supplemental-task-category';
import {BrokerCommissionPageLabels, DEPOSIT_PAID_ON_OCCUPANCY} from '../../shared-main/constants';
import {TrustLedgerItemize} from '../trust-ledger/itemize/trust-ledger-itemize';
import {DpBooleanValueTypes} from './dp-boolean';
import {SoaExportConfig} from './soa-export-config';
import {NetAdvance} from './net-advance';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {MatterUndertaking} from './matter-undertaking';
import {undertakingTypes} from '../../common/common-labels';
import {constValues} from './const-values';
import {RollNumberMatterTax} from './roll-number-matter-tax';
import {ProgressionStatus, StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {MatterTax} from './property-taxes';
import {MatterHoldback} from './advance-holdback/matter-holdback';
import {Deposit} from './deposit';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {PROVINCE_CODES} from './user-province';
import {MatterSoaSheet} from '../../../app/matters/shared/matter-soa-sheet';
import {LateClosingInterest} from '../../../app/matters/shared/late-closing-interest';
import {SoaTemplate} from '../../admin/soa/soa-template';

export const SoaTrustLedgerConfigKeys = {
  L1_OT: 'ONTARIO_AND_TORONTO_LTT_FEE',
  L1_O: 'ONTARIO_LTT_FEE',
  L2_T: 'TORONTO_LTT_FEE',
  L1_MT: 'PAID_MANITOBA_LTT',
  L1_MG: 'MORTGAGE_FEE',
  L1_LF: 'LEGAL_FEE',
  L1_RDHIT: 'RECEIVED_DEPOSIT_HELD_IN_TRUST',
  L1_RF: 'RECEIVED_FROM_YOU_FEE',
  L1_RP: 'RECEIVED_FROM_PURCHASER_ON_CLOSING',
  L1_PY: 'PAID_TO_YOU_FEE',
  L1_PV: 'PAID_TO_VENDOR',
  L1_PM: 'PAID_TO_PREVIOUS_MORTGAGE',
  L1_PDF: 'PAID_DISCHARGE_FEE',
  L1_PDF_A: 'PAID_DISCHARGE_FEE_AUTO',
  L1_PB: 'PAID_TO_REAL_ESTATE_BROKER',
  L1_PB_SEPARATE: 'PAID_TO_REAL_ESTATE_BROKER_SEPARATE',
  L1_RECEIVED_EXCESS_DEPOSIT: 'RECEIVED_EXCESS_DEPOSIT',
  L1_CS: 'CASH_SHORTFALL',
  L1_CF: 'CONDO_CERTIFICATE_FEE',
  L1_NR: 'NRST',
  L1_EF: 'ELECTRONIC_MORTGAGE_FEE',
  L1_TI: 'PAID_TITLE_INSURANCE_FEE',
  L1_TR: 'PAID_TITLE_REGISTRATION_FEE',
  L1_MR: 'PAID_MORTGAGE_REGISTRATION_FEE',
  L1_TAX_PAID_FROM_TRUST_ACCOUNT: 'TAX_PAID_FROM_TRUST_ACCOUNT',
  L1_LC: 'LATE_CLOSING',
  L1_LCE: 'LATE_CLOSING_ESTIMATE',
  L1_ADVANCE_HOLDBACK: 'CONSTRUCTION_MORTGAGE_HOLDBACKS',
  L1_OTHER_HOLDBACK: 'OTHER_HOLDBACKS',
  CUSTOM_TO: 'TO_CLIENT_CUSTOM',
  CUSTOM_FROM: 'FROM_CLIENT_CUSTOM'
};

export const TrustLedgerExpenditureTypes = {
  BF: 'BROKER_FEE',
  BF_A: 'BROKER_FEE_AUTO',
  SE: 'SUPPLEMENTAL_TASK_EXPENDITURE',
  SE_A: 'SUPPLEMENTAL_TASK_EXPENDITURE_AUTO'
};

/**
 SoaTrustLedgerCollection is a class which is used by Statement of Accounts and Trust Ledger Component
 - It is a helper class which encapsulates all helper methods required for StatementOfAccounts and Trust Ledger Component
 - It works on matterTrustLedger and mattersoas list and creates different helper methods for both lists
 - It also loads StatementConfig Object which has the configuration for StatementOfAccount and Trust Ledger Component
 - Different methods within this class use this configuration object to add and remove items from  matterTrustLedger and mattersoas list
 Note: please Do Not use this.matter.soaTrustLedgerCollection because it is already in SoaTrustLedgerCollection
 */
export class SoaTrustLedgerCollection {

  _feesInterim: SoaMatter[] = [];
  _feesFinal: SoaMatter[] = [];

  _disbursementsHSTInterim: SoaMatter[] = [];
  _disbursementsHSTFinal: SoaMatter[] = [];

  _disbursementsNotHSTInterim: SoaMatter[] = [];
  _disbursementsNotHSTFinal: SoaMatter[] = [];

  _disbursementsAdditionalInterim: SoaMatter[] = [];
  _disbursementsAdditionalFinal: SoaMatter[] = [];

  _disbursementsPSTInterim: SoaMatter[] = [];
  _disbursementsPSTFinal: SoaMatter[] = [];

  _disbursementsGSTandPSTInterim: SoaMatter[] = [];
  _disbursementsGSTandPSTFinal: SoaMatter[] = [];

  _taxableOtherChargesGSTInterim: SoaMatter[] = [];
  _taxableOtherChargesGSTFinal: SoaMatter[] = [];

  _taxableOtherChargesPSTInterim: SoaMatter[] = [];
  _taxableOtherChargesPSTFinal: SoaMatter[] = [];

  _taxableOtherChargesGSTandPSTInterim: SoaMatter[] = [];
  _taxableOtherChargesGSTandPSTFinal: SoaMatter[] = [];

  _nonTaxableOtherChargesInterim: SoaMatter[] = [];
  _nonTaxableOtherChargesFinal: SoaMatter[] = [];

  _matterTrustLedgersInterim: TrustLedgerMatter[] = [];
  _matterTrustLedgersFinal: TrustLedgerMatter[] = [];

  _interimSoaExportConfig: SoaExportConfig;
  _finalSoaExportConfig: SoaExportConfig;

  documentProfile: DocumentProfile;
  statementConfiguration: StatementConfig;
  soaFeesRates: SoaFeeRate[];
  matter: Matter;
  combinedAccountCodeDataArr: string[] = [];
  accountDepartments: JurisdictionDepartment[];
  adjustmentAmountForFee: number = 0;
  // result of this.receiptTotal - this.expenditureTotal, can be negative
  adjustmentAmountForF9: number = 0;
  sheetId: number;
  soaStatementConfiguration: StatementConfig; // config loaded in matter initialization to use on StatementOfAccount to show Templates.

  get statementConfig(): StatementConfig {
    return this.statementConfiguration ? this.statementConfiguration : new StatementConfig();
  }

  get soaExportConfig(): SoaExportConfig {
    if (this.matter && this.matter.isProjectSale) {
      return this.matter.isProjectConfigDocForSoa() ? this.isSoaInterimMode ? this._interimSoaExportConfig : this._finalSoaExportConfig : this._finalSoaExportConfig;
    } else {
      return this._finalSoaExportConfig;
    }
  }

  set soaExportConfig(soaExportConfig: SoaExportConfig) {
    if (this.matter.isProjectSale) {
      if (soaExportConfig.soaProgressionType == ProgressionStatus.INTERIM) {
        this._interimSoaExportConfig = new SoaExportConfig(soaExportConfig);
      } else {
        this._finalSoaExportConfig = new SoaExportConfig(soaExportConfig);
      }
    } else {
      this._finalSoaExportConfig = new SoaExportConfig(soaExportConfig);
    }
  }

  set statementConfig(statementConfigPassed: StatementConfig) {
    this.statementConfiguration = statementConfigPassed;
  }

  get soaConfig(): SoaConfig {
    return this.statementConfig && this.statementConfig.statementOfAccount ? this.statementConfig.statementOfAccount : new SoaConfig();
  }

  get trustLedger(): TrustLedger {
    return this.statementConfig && this.statementConfig.trustLedger ? this.statementConfig.trustLedger : new TrustLedger();
  }

  get isSaleOrSaleOpportunity(): boolean {
    return this.matter.isSale || this.matter.isSaleOpportunity;
  }

  get isPurchaseOrPurchaseOpportunity(): boolean {
    return this.matter.isPurchase || this.matter.isPurchaseOpportunity;
  }

  get isMortgageOrMortgageOpportunity(): boolean {
    return this.matter.isMortgage || this.matter.isMortgageOpportunity;
  }

  get isDischargeOrDischargeOpportunity(): boolean {
    return !!this.matter.isMatterTypeDischarge || this.matter.isDischargeOpportunity;
  }

  updateMatterSoasInCollection(matterSoas: SoaMatter[]) {
    if (matterSoas && matterSoas.length > 0) {

      //Load the final Soas
      this._feesFinal = matterSoas.filter(soaMatter => soaMatter.itemType == 'FEES' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._disbursementsAdditionalFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_ADDITIONAL' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._disbursementsPSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_SUBJECT_TO_PST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._disbursementsGSTandPSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_SUBJECT_TO_GST_PST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._disbursementsHSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_SUBJECT_TO_HST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._disbursementsNotHSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_NOT_SUBJECT_TO_HST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._taxableOtherChargesGSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'TAXABLE_CHARGES_TO_GST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._taxableOtherChargesPSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'TAXABLE_CHARGES_TO_PST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._taxableOtherChargesGSTandPSTFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'TAXABLE_CHARGES_TO_GST_PST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);
      this._nonTaxableOtherChargesFinal =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'NON_TAXABLE_CHARGES_TO_GST_PST' && soaMatter.progressionStatus == ProgressionStatus.FINAL);

      //Load the interim Soas
      this._feesInterim = matterSoas.filter(soaMatter => soaMatter.itemType == 'FEES' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._disbursementsAdditionalInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_ADDITIONAL' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._disbursementsPSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_SUBJECT_TO_PST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._disbursementsGSTandPSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_SUBJECT_TO_GST_PST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._disbursementsHSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_SUBJECT_TO_HST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._disbursementsNotHSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'DISBURSEMENT_NOT_SUBJECT_TO_HST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._taxableOtherChargesGSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'TAXABLE_CHARGES_TO_GST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._taxableOtherChargesPSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'TAXABLE_CHARGES_TO_PST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._taxableOtherChargesGSTandPSTInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'TAXABLE_CHARGES_TO_GST_PST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);
      this._nonTaxableOtherChargesInterim =
        matterSoas.filter(soaMatter => soaMatter.itemType == 'NON_TAXABLE_CHARGES_TO_GST_PST' && soaMatter.progressionStatus == ProgressionStatus.INTERIM);

    }

  }

  constructor(matter: Matter, matterSoas?: SoaMatter[], trustLedgerMatters?: TrustLedgerMatter[]) {
    this.updateMatterSoasInCollection(matterSoas);
    if (trustLedgerMatters && trustLedgerMatters.length > 0) {
      this._matterTrustLedgersFinal = trustLedgerMatters.filter(tl => tl.progressionStatus == ProgressionStatus.FINAL);
      this._matterTrustLedgersInterim = trustLedgerMatters.filter(tl => tl.progressionStatus == ProgressionStatus.INTERIM);

      if (this._matterTrustLedgersFinal) {
        const cashShotFallPositionFinal: number = this._matterTrustLedgersFinal.findIndex(item => item.itemName == SoaConstants.CASH_SHORTFALL);
        if (cashShotFallPositionFinal != -1) {
          const trustLedgerMatter = this._matterTrustLedgersFinal[ cashShotFallPositionFinal ];
          this._matterTrustLedgersFinal.splice(cashShotFallPositionFinal, 1);
          this._matterTrustLedgersFinal.push(trustLedgerMatter);
        }
      }
      if (this._matterTrustLedgersInterim) {
        const cashShotFallPositionInterim: number = this._matterTrustLedgersInterim.findIndex(item => item.itemName == SoaConstants.CASH_SHORTFALL);
        if (cashShotFallPositionInterim != -1) {
          const trustLedgerMatter = this._matterTrustLedgersInterim[ cashShotFallPositionInterim ];
          this._matterTrustLedgersInterim.splice(cashShotFallPositionInterim, 1);
          this._matterTrustLedgersInterim.push(trustLedgerMatter);
        }
      }
    }
    this.matter = matter;
  }

  clearSoaStructure(): void {
    this.fees = [];
    this.disbursementsAdditional = [];
    this.disbursementsPST = [];
    this.disbursementsGSTandPST = [];
    this.disbursementsHST = [];
    this.disbursementsNotHST = [];
    this.taxableOtherChargesGST = [];
    this.taxableOtherChargesPST = [];
    this.taxableOtherChargesGSTandPST = [];
    this.nonTaxableOtherCharges = [];
  }

  createNewSoaStructure(): void {
    if (this.matter) {
      let isFirstMortgageArranged: boolean = this.matter.isFirstMortgageArranged();
      let isPropertyTeranetJurisdictionToronto: boolean = this.matter.isPropertyTeranetJurisdictionToronto();
      let applyMLTTBasedOnSubjectToLTT: boolean = (this.matter.considerationLtt && this.matter.considerationLtt.applyMLTTBasedOnSubjectToLTT());
      let applyTeranetConnectCharges: boolean = this.matter && this.matter.isTeranetConnectTransactionStatusBilled();
      //let isPropertyCondo : boolean = this.matter.isPropertyCondo();

      let enabledFees = this.soaConfig.fees.filter(soaFee => !soaFee.deleted && soaFee.enabled);
      for (let i = 0; i < enabledFees.length; i++) {
        if ((!enabledFees[ i ].isFeeForMortgage() || isFirstMortgageArranged) && !enabledFees[ i ].isFeeForAdditionalMortgage()) {
          let soaMatter = new SoaMatter();
          //soaMatter.identifier = Number(soaMatter.identifier) + Number(i);
          soaMatter.itemName = enabledFees[ i ].nameOfFees;

          soaMatter.feesId = enabledFees[ i ].id;
          soaMatter.itemValue = 0;
          soaMatter.itemType = 'FEES';
          soaMatter.itemKey = enabledFees[ i ].code;
          soaMatter.f9DefaultAmount = enabledFees[ i ].f9DefaultAmount;
          soaMatter.readOnly = enabledFees[ i ].readOnly;
          soaMatter.progressionStatus = this.soaProgressionStatus;
          if (enabledFees[ i ].isFeeForMortgage() && isFirstMortgageArranged) {
            soaMatter.fromMortgageIndex = 0;
          }
          this.updateFeeLabel(soaMatter, enabledFees[ i ]);

          this.fees.push(soaMatter);

        }
      }

      this.disbursementsHST = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_HST')
      .filter(item =>
        (!item.isMLTTConfiguration() || (isPropertyTeranetJurisdictionToronto && applyMLTTBasedOnSubjectToLTT))
        && (!item.isTeranetConnectCharges() || item.isTeranetConnectCharges() && applyTeranetConnectCharges)
        && !item.isCondoCertificationFee() && !item.isCondoDocumentsFee()
        && !item.isEMP()
        && !item.isCompliance())
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'DISBURSEMENT_SUBJECT_TO_HST');
      });

      this.disbursementsNotHST = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST')
      .filter(item => !item.isRetailSalesTaxChattels()
        && item.nameOfDisbursement != null
        && item.nameOfDisbursement != SoaConstants.NO_HST_ONTARIO_LTT
        && !item.isCondoCertificationFee() && !item.isCondoDocumentsFee()
        && !item.isCompliance()
        && !item.isNrst()
        && !item.isTitleInsurance())
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'DISBURSEMENT_NOT_SUBJECT_TO_HST');
      });

      this.disbursementsAdditional = this.soaConfig.getDisbursementsConfig('ADDITIONAL')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'DISBURSEMENT_ADDITIONAL');
      });

      this.disbursementsPST = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_PST')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'DISBURSEMENT_SUBJECT_TO_PST');
      });

      this.disbursementsGSTandPST = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_GST_PST')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'DISBURSEMENT_SUBJECT_TO_GST_PST');
      });

      this.taxableOtherChargesGST = this.soaConfig.getDisbursementsConfig('OTHER_SUBJECT_TO_GST')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'TAXABLE_CHARGES_TO_GST');
      });

      this.taxableOtherChargesPST = this.soaConfig.getDisbursementsConfig('OTHER_SUBJECT_TO_PST')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'TAXABLE_CHARGES_TO_PST');
      });

      this.taxableOtherChargesGSTandPST = this.soaConfig.getDisbursementsConfig('OTHER_SUBJECT_TO_GST_PST')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'TAXABLE_CHARGES_TO_GST_PST');
      });

      this.nonTaxableOtherCharges = this.soaConfig.getDisbursementsConfig('NOT_OTHER_GST_PST')
      .map(item => {
        return this.createSoaMatterBasedOnConfig(item, 'NON_TAXABLE_CHARGES_TO_GST_PST');
      });
    }
  }

  private createSoaMatterBasedOnConfig(item: DisbursementConfig, itemType: string) {
    let soaMatter = new SoaMatter();
    soaMatter.itemName = item.nameOfDisbursement;
    soaMatter.itemValue = 0;
    soaMatter.disbursementConfigId = item.id;
    soaMatter.itemType = itemType;
    soaMatter.itemKey = item.code;
    soaMatter.f9DefaultAmount = item.f9DefaultAmount;
    soaMatter.readOnly = item.readOnly;
    soaMatter.accountCode = item.configAccountCode;
    soaMatter.progressionStatus = this.soaProgressionStatus;
    return soaMatter;
  }

  get calculateFeeTotal(): number {
    let feesTotal = 0;
    for (let i: number = 0; i < this.fees.length; ++i) {
      feesTotal = Number(feesTotal) + Number(this.fees[ i ].itemValue);
    }
    return feesTotal;
  }

  feesHst(): number {
    let fee = Number((this.calculateFeeTotal * (this.soAccountHst) / 100));
    return Number(Math.round((fee) * 100) / 100 + this.adjustmentAmountForFee);
  }

  feesGst(): number {
    return Number((this.calculateFeeTotal * (this.soAccountFederalTax) / 100));
  }

  feesPst(): number {
    return Number((this.calculateFeeTotal * (this.soAccountProvincialTax) / 100));
  }

  get activeMatterSheet(): MatterSoaSheet {
    return this.sheetId > 0 && this.matter && this.matter.secondarySoaSheets ? this.matter.secondarySoaSheets.find(item => item.id == this.sheetId) : undefined;
  }

  get soAccountHst(): number {
    return this.matter ? (this.activeMatterSheet ? this.matter.getSoAccountHstBySheet(this.activeMatterSheet.id) : this.matter.soAccountHst) : 0;

  }

  get soAccountFederalTax(): number {
    return this.matter ? (this.activeMatterSheet ? this.matter.getSoAccountFederalHstBySheet(this.activeMatterSheet.id) : this.matter.soAccountFederalHst) : 0;
  }

  get soAccountProvincialTax(): number {
    return this.matter ? (this.activeMatterSheet ? this.matter.getSoAccountProvincialHstBySheet(this.activeMatterSheet.id) : this.matter.soAccountProvincialHst) : 0;
  }

  get totalDisbursementsNotSubjectToTax(): number {
    return Number(this.disbursementsNotHST.reduce((total: Number, current: SoaMatter) => Number(total) + Number(current.itemValue), 0));
  }

  get totalTaxableOtherChargesGST(): number {
    return Number(this.taxableOtherChargesGST.reduce((total: Number, soaMatter: SoaMatter) => Number(total) + Number(soaMatter.itemValue), 0));
  }

  get totalTaxableOtherChargesPST(): number {
    return Number(this.taxableOtherChargesPST.reduce((total: Number, soaMatter: SoaMatter) => Number(total) + Number(soaMatter.itemValue), 0));
  }

  get totalTaxableOtherChargesGSTandPST(): number {
    return Number(this.taxableOtherChargesGSTandPST.reduce((total: Number, soaMatter: SoaMatter) => Number(total) + Number(soaMatter.itemValue), 0));
  }

  get totalNonTaxableOtherCharges(): number {
    return Number(this.nonTaxableOtherCharges.reduce((total: Number, soaMatter: SoaMatter) => Number(total) + Number(soaMatter.itemValue), 0));
  }

  get feeCalculatedOnInclusivePriceSelection(): boolean {
    return this.matter ? (this.activeMatterSheet ? this.activeMatterSheet.feeCalculatedOnInclusivePriceFlag : this.matter.feeCalculatedOnInclusivePriceSelectionMatter) : false;
  }

  get soaFeesCalculatedOnAllInclusivePriceValue(): number {
    return this.matter ? (this.activeMatterSheet ? this.activeMatterSheet.soaFeesCalculatedOnAllInclusivePrice : this.matter.soaFeesCalculatedOnAllInclusivePriceValueMatter) : 0;
  }

  set feeCalculatedOnInclusivePriceSelection(val: boolean) {
    this.activeMatterSheet ? this.activeMatterSheet.feeCalculatedOnInclusivePriceFlag = val : (this.matter && (this.matter.feeCalculatedOnInclusivePriceSelectionMatter = val));
  }

  set soaFeesCalculatedOnAllInclusivePriceValue(val: number) {
    this.activeMatterSheet ? this.activeMatterSheet.soaFeesCalculatedOnAllInclusivePrice = val : (this.matter && (this.matter.soaFeesCalculatedOnAllInclusivePriceValueMatter = val));
  }

  // this will return for
  // ON: Disbursements subject to HST
  // AB: Disbursements subject to GST
  // MB, SK: Disbursements subject to GST
  get totalDisbursementsSubjectToTax(): number {
    return Number(this.disbursementsHST.reduce((total: Number, current: SoaMatter) => Number(total) + Number(current.itemValue), 0));
  }

  // To be used on MB, SK for Disbursements subject to PST
  get totalDisbursementsSubjectToPSTTax(): number {
    return Number(this.disbursementsPST.reduce((total: Number, current: SoaMatter) => Number(total) + Number(current.itemValue), 0));
  }

  // To be used on MB, SK for Disbursements subject to GST and PST
  get totalDisbursementsSubjectToGSTandPSTTax(): number {
    return Number(this.disbursementsGSTandPST.reduce((total: Number, current: SoaMatter) => Number(total) + Number(current.itemValue), 0));
  }

  get totalDisbursementsAdditional(): number {
    return Number(this.disbursementsAdditional.reduce((total: Number, current: SoaMatter) => Number(total) + Number(current.itemValue), 0));
  }

  taxAmountOfDisbursementsSubjectToTax(): number {
    if (this.matter?.isMatterProvinceBCorMBorSK) { // MB and SK have both GST and PST in this case return GST
      return Number(this.totalDisbursementsSubjectToTax * (this.soAccountFederalTax) / 100);
    }
    return Number(this.totalDisbursementsSubjectToTax * (this.soAccountHst) / 100);
  }

  // To be used on MB, SK for PST taxes on Disbursements subject to PST
  taxAmountOfDisbursementsSubjectToPSTTax(): number {
    return Number(this.totalDisbursementsSubjectToPSTTax * (this.soAccountProvincialTax) / 100);
  }

  taxAmountOfTotalTaxableOtherChargesGST(): number {
    return Number(this.totalTaxableOtherChargesGST * (this.soAccountFederalTax) / 100);
  }

  taxAmountOfTotalTaxableOtherChargesPST(): number {
    return Number(this.totalTaxableOtherChargesPST * (this.soAccountProvincialTax) / 100);
  }

  gstAmountOfTotalTaxableOtherChargesGSTandPSTTax(): number {
    return Number((this.totalTaxableOtherChargesGSTandPST * (this.soAccountFederalTax) / 100 * 100) / 100);
  }

  pstAmountOfTotalTaxableOtherChargesGSTandPSTTax(): number {
    return Number((this.totalTaxableOtherChargesGSTandPST * (this.soAccountProvincialTax) / 100 * 100) / 100);
  }

  // To be used on MB, SK for PST taxes on Disbursements subject to GST and PST
  gstAmountOfDisbursementsSubjectToGSTandPSTTax(): number {
    return Number((this.totalDisbursementsSubjectToGSTandPSTTax * (this.soAccountFederalTax) / 100 * 100) / 100);
  }

  pstAmountOfDisbursementsSubjectToGSTandPSTTax(): number {
    return Number((this.totalDisbursementsSubjectToGSTandPSTTax * (this.soAccountProvincialTax) / 100 * 100) / 100);
  }

  taxAmountOfDisbursementsSubjectToGSTandPSTTax(): number {
    return Number(Math.round(this.gstAmountOfDisbursementsSubjectToGSTandPSTTax()) + Number(this.pstAmountOfDisbursementsSubjectToGSTandPSTTax()));
  }

  hstDisbursementAdditionalAmount(): number {
    return Number(this.totalDisbursementsAdditional * (this.soAccountHst) / 100);
  }

  balanceOwing(): number {
    return Number((this.totalRounded() ? this.totalRounded() : 0) - (this.receivedOnAccount ? this.receivedOnAccount : 0));
  }

  total(): number {
    this.setAdjustmentForTotal();
    let totalAmount = this.calculateTotal();
    return Number(Math.round(totalAmount * 100) / 100);
  }

  private calculateTotal() {
    return Number(this.calculateFeeTotal)
      + Number(this.totalDisbursementsNotSubjectToTax)
      + Number(this.totalDisbursementsSubjectToTax)
      + Number(this.totalDisbursementsAdditional)
      + Number(this.totalDisbursementsSubjectToPSTTax)
      + Number(this.totalDisbursementsSubjectToGSTandPSTTax)
      + Number(this.totalTaxableOtherChargesGST)
      + Number(this.totalTaxableOtherChargesPST)
      + Number(this.totalTaxableOtherChargesGSTandPST)
      + Number(this.totalNonTaxableOtherCharges)
      + this.totalTaxes();
  }

  /**
   * This method will set the Adjustment amount by a cent to compensate for difference
   * in User Value and Calculated Total Value.
   */
  setAdjustmentForTotal(): void {
    this.adjustmentAmountForFee = 0;

    //Before changing peny change please verify these two bugs DPPMP-45378, DPPMP-57780
    if(this.soaFeesCalculatedOnAllInclusivePriceValue) {
      let totalAmount: number = this.calculateTotal();
      let differenceCalculated: number = Number(
        Math.round((this.soaFeesCalculatedOnAllInclusivePriceValue - totalAmount) * 100) / 100);

      if (differenceCalculated > 0 && differenceCalculated <= .01) {
        this.adjustmentAmountForFee = .01;
      } else if (differenceCalculated < 0 && differenceCalculated >= -.01) {
        this.adjustmentAmountForFee = -.01;
      }
    }
  }

  totalRounded(): number {
    return Number(Math.round(+this.total() * 100) / 100);
  }

  totalTaxes(): number {
    if (this.matter?.isMatterProvinceBCorMBorSK) {
      return this.totalPST() + this.totalGST();
    }
    return this.totalTax(this.matter ? this.soAccountHst : 0);
  }

  totalPST(): number {
    return this.feesPst() +
      +Number((this.totalDisbursementsSubjectToPSTTax * (this.matter ? this.soAccountProvincialTax : 0)) / 100)
      + Number(this.pstAmountOfDisbursementsSubjectToGSTandPSTTax())
      + Number((this.totalTaxableOtherChargesPST * (this.matter ? this.soAccountProvincialTax : 0)) / 100)
      + Number(this.pstAmountOfTotalTaxableOtherChargesGSTandPSTTax());
  }

  totalGST(): number {
    let tax: number = this.matter ? this.soAccountFederalTax : 0;
    let totalTaxes: number = Number(this.feesGst())
      + Number(this.totalDisbursementsSubjectToTax * tax / 100)
      + Number(this.totalDisbursementsAdditional * tax / 100)
      + Number(this.totalTaxableOtherChargesGST * tax / 100)
      + Number(this.totalTaxableOtherChargesGSTandPST * tax / 100)
      + Number(this.totalDisbursementsSubjectToGSTandPSTTax * tax / 100);

    return Number(totalTaxes);
  }

  totalTax(tax: number): number {
    let totalTaxes: number = Number(this.feesHst())
      + Number(this.totalDisbursementsSubjectToTax * tax / 100)
      + Number(this.totalDisbursementsAdditional * tax / 100)
      + Number(this.totalTaxableOtherChargesGST * tax / 100)
      + Number(this.totalTaxableOtherChargesGSTandPST * tax / 100)
      + Number(this.totalDisbursementsSubjectToGSTandPSTTax * tax / 100);
    return Number(totalTaxes);
  }

  isTorontoLTTExist(): boolean {
    if (this.soaConfig && this.soaConfig.id > 0) {
      return (this.soaConfigListForLTT.findIndex(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L2_T) > -1);
    } else {
      return false;
    }
  }

  isOntarioLTTExist(): boolean {
    if (this.soaConfig && this.soaConfig.id > 0) {
      return (this.soaConfigListForLTT.findIndex(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_O) > -1);
    } else {
      return false;
    }

  }

  isDTTExistNS(): boolean {
    if (this.soaConfig && this.soaConfig.id > 0) {
      return (this.soaConfigListForLTT.findIndex(soaMatter => soaMatter.isDTT()) > -1);
    } else {
      return false;
    }
  }

  isLTTExistNB(): boolean {
    if (this.soaConfig && this.soaConfig.id > 0) {
      return (this.soaConfigListForLTT.findIndex(soaMatter => soaMatter.isNBLTT()) > -1);
    } else {
      return false;
    }
  }

  isOntarioTorontoLTTAvailable(): boolean {
    if (this.soaConfig && this.soaConfig.id > 0) {
      return (this.soaConfigListForLTT.findIndex(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_OT) > -1);
    } else {
      return false;
    }

  }

  isSoaTitleInsuranceFeeExist(): boolean {
    return this.soaConfig && this.soaConfig.id > 0 && (this.disbursementsNotHST.findIndex(soaMatter => soaMatter.isTitleInsurance()) > -1);
  }

  isTrustLedgerTitleInsuranceExist(): boolean {
    return this.statementConfig && this.statementConfig.id > 0 && (this.matterTrustLedgers.findIndex(trustLedger => trustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_TI) > -1);
  }

  isSoaTitleRegistrationFeeExists(): boolean {
    return this.soaConfig && this.soaConfig.id > 0 && (this.disbursementsNotHST.findIndex(soaMatter => soaMatter.isTitleRegistrationFee()) > -1);
  }

  isTrustLedgerTitleRegistrationFeeExist(): boolean {
    return this.statementConfig && this.statementConfig.id > 0 && (this.matterTrustLedgers.findIndex(trustLedger => trustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_TR) > -1);
  }

  isSoaMortgageRegistrationFeeExists(): boolean {
    return this.soaConfig && this.soaConfig.id > 0 && (this.disbursementsNotHST.findIndex(soaMatter => soaMatter.isMortgageRegistrationFee()) > -1);
  }

  isTrustLedgerMortgageRegistrationFeeExist(): boolean {
    return this.statementConfig && this.statementConfig.id > 0 && (this.matterTrustLedgers.findIndex(trustLedger => trustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_MR) > -1);
  }

  get soaConfigListForLTT(): any[] {
    return this.statementConfig.isLTTSetForSoa() ? this.disbursementsNotHST : this.matterTrustLedgers;
  }

  isAnyLTTRowAvailable(): boolean {
    return (this.isOntarioTorontoLTTAvailable() || this.isOntarioLTTExist() || this.isTorontoLTTExist());
  }

  updateLttNS(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let existingLtt = this.soaConfigListForLTT.find(soaMatter => soaMatter.isDTT());
      if (existingLtt && this.matter && this.matter.matterPropertyWithCondo) {
        const lttValue: number = this.matter.matterPropertyWithCondo.landTransferTax;
        existingLtt.f9DefaultAmount = lttValue;
        if ((this.statementConfig && this.matter.autoInsertAllF9Values && this.statementConfig.isLTTSetForTrustLedger()) || (this.statementConfig && (this.statementConfig.isLTTForSoaAuto() || this.statementConfig.isLTTForTrustLedgerAuto()))) {
          existingLtt.itemValue = lttValue;
        }
      }
    }
  }

  updateLttNB(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let existingLtt = this.soaConfigListForLTT.find(soaMatter => soaMatter.isNBLTT());
      if (existingLtt && this.matter && this.matter.matterPropertyWithCondo) {
        const lttValue: number = this.matter.matterPropertyWithCondo.landTransferTax ? this.matter.matterPropertyWithCondo.landTransferTax : 0;
        existingLtt.f9DefaultAmount = lttValue;
        if ((this.statementConfig && this.matter.autoInsertAllF9Values && this.statementConfig.isLTTSetForTrustLedger()) || (this.statementConfig && (this.statementConfig.isLTTForSoaAuto() || this.statementConfig.isLTTForTrustLedgerAuto()))) {
          existingLtt.itemValue = lttValue;
        }
      }
    }
  }

  updateOntarioLtt(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterSoa = this.soaConfigListForLTT.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_O);
      if (matterSoa) {
        if (this.matter && this.statementConfig && (this.statementConfig.isLTTForSoaAuto() || this.statementConfig.isLTTForTrustLedgerAuto() || (!this.statementConfig.isLTTSetForSoa() && this.matter.autoInsertAllF9Values))) {
          if (!this.statementConfig.isLTTSetForSoa() && matterSoa.isItemized()) {
            //Updating the amount in fist read only itemized entry to be in sync with considerationLTT
            matterSoa.itemizedMatterTrustLedgers[ 0 ].itemAmount = this.getItemizeLttPayableAmount(matterSoa);
            matterSoa.itemValue = matterSoa.itemizeTotal;
          } else {
            matterSoa.itemValue = this.matter.considerationLtt.landTransferTaxValueOnt;
          }

        }
      }
    }
  }

  getItemizeLttPayableAmount(trustLedgerMatter: TrustLedgerMatter): number {
    return trustLedgerMatter.isOntarioLTT ? this.matter.considerationLtt.landTransferTaxValueOnt
      : (trustLedgerMatter.isTorontoLTT ? this.matter.considerationLtt.landTransferTaxValueTor : this.matter.considerationLtt.totalOntarioTorontoLttPayable);
  }

  updateTorontoLtt(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterSoa = this.soaConfigListForLTT.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L2_T);
      if (matterSoa) {
        if (this.matter && this.statementConfig && (this.statementConfig.isLTTForSoaAuto() || this.statementConfig.isLTTForTrustLedgerAuto() || (!this.statementConfig.isLTTSetForSoa() && this.matter.autoInsertAllF9Values))) {
          if (!this.statementConfig.isLTTSetForSoa() && matterSoa.isItemized()) {
            matterSoa.itemizedMatterTrustLedgers[ 0 ].itemAmount = this.getItemizeLttPayableAmount(matterSoa);
            matterSoa.itemValue = matterSoa.itemizeTotal;
          } else {
            matterSoa.itemValue = this.matter.considerationLtt.landTransferTaxValueTor;
          }

        }
      }
    }
  }

  updateOntarioTorontoLtt(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterSoa = this.soaConfigListForLTT.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_OT);
      if (matterSoa) {
        if (this.matter && this.statementConfig && (this.statementConfig.isLTTForSoaAuto() || this.statementConfig.isLTTForTrustLedgerAuto() || (!this.statementConfig.isLTTSetForSoa() && this.matter.autoInsertAllF9Values))) {
          if (!this.statementConfig.isLTTSetForSoa() && matterSoa.isItemized()) {
            matterSoa.itemizedMatterTrustLedgers[ 0 ].itemAmount = this.getItemizeLttPayableAmount(matterSoa);
            matterSoa.itemValue = matterSoa.itemizeTotal;
          } else {
            matterSoa.itemValue = this.matter.considerationLtt.totalOntarioTorontoLttPayable;
          }

        }
      }
    }
  }

  removeLTT(): void {
    if (this.soaConfig && this.soaConfig.id > 0 && this.matter) {
      this.removeLTTFrom(!this.statementConfig.isLTTSetForSoa());
    }
  }

  removeLTTFrom(removeTLLTT: boolean): void {
    if (this.soaConfig && this.soaConfig.id > 0 && this.matter) {
      let lttList = removeTLLTT ? this.matterTrustLedgers : this.disbursementsNotHST;
      if (this.matter.isMatterProvinceON) {
        let matterParticipantIndexL2: number = _.findIndex(lttList, tl => (tl.itemKey == SoaTrustLedgerConfigKeys.L2_T));
        if (matterParticipantIndexL2 >= 0) {
          lttList.splice(matterParticipantIndexL2, 1);
        }
        let matterParticipantIndexL1: number = _.findIndex(lttList, tl => (tl.itemKey == SoaTrustLedgerConfigKeys.L1_O));
        if (matterParticipantIndexL1 >= 0) {
          lttList.splice(matterParticipantIndexL1, 1);
        }
        let matterParticipantIndexL1_OT: number = _.findIndex(lttList, tl => (tl.itemKey == SoaTrustLedgerConfigKeys.L1_OT));
        if (matterParticipantIndexL1_OT >= 0) {
          lttList.splice(matterParticipantIndexL1_OT, 1);
        }
      } else if (this.matter.isMatterProvinceNS) {
        let matterParticipantIndex: number = _.findIndex(lttList, soaMatter => (soaMatter.itemKey == AccountingItemKeys[ this.statementConfig.getLTTKey() ]));
        if (matterParticipantIndex >= 0) {
          lttList.splice(matterParticipantIndex, 1);
        }
      }
    }
  }

  clearAnyGeneralStatementConfig(resetLTT: boolean, resetNRST: boolean, resetEMP: boolean, resetTitleInsurance: boolean): void {
    if (this.isAnyLTTRowAvailable() && resetLTT) {
      this.removeLTT();
    }
    if (resetEMP) {
      this.removeEmp();
    }
    if (resetTitleInsurance) {
      this.removeTI();
    }
    if (resetNRST) {
      this.removeNRST();
    }
  }

  removeMLTT(): void {
    _.remove(this.disbursementsHST, function (matterSoa: SoaMatter) {
      return matterSoa.isMLTTConfiguration();
    });
  }

  removeFeeForMortgage(fromMortgageIndex: number): void {
    //need to get the value the function, this.matterType not working inside the predicate function (if used directly inside predicate function, will get error: Cannot read property 'matterType' of undefined)
    let matterType: MatterType = this.matterType;
    let matterProvinceCode: ProvinceCode = this.matterProvinceCode;
    _.remove(this.fees, function (soaMatter: SoaMatter) {
      return (soaMatter.isMortgage() || soaMatter.isAdditionalMortgage() || soaMatter.isTransactionFees(matterType, matterProvinceCode)) && soaMatter.fromMortgageIndex == fromMortgageIndex;
    });
  }

  removeTorontoLTT(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterParticipantIndex: number = _.findIndex(this.soaConfigListForLTT, soaMatter => (soaMatter.itemKey == SoaTrustLedgerConfigKeys.L2_T));
      if (matterParticipantIndex >= 0) {
        this.soaConfigListForLTT.splice(matterParticipantIndex, 1);
      }
    }
  }

  removeOntarioLTT(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterParticipantIndex: number = _.findIndex(this.soaConfigListForLTT, soaMatter => (soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_O));
      if (matterParticipantIndex >= 0) {
        this.soaConfigListForLTT.splice(matterParticipantIndex, 1);
      }
    }
  }

  removePaidToVendor(): void {
    let paidToYouFeeIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PV);
    if (paidToYouFeeIdx != -1) {
      this.matterTrustLedgers.splice(paidToYouFeeIdx, 1);
    }
  }

  removeOntarioTorontoLTT(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterParticipantIndex: number = _.findIndex(this.soaConfigListForLTT, soaMatter => (soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_OT));
      if (matterParticipantIndex >= 0) {
        this.soaConfigListForLTT.splice(matterParticipantIndex, 1);
      }
    }
  }

  removeNovaScotiaDTT(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let matterParticipantIndex: number = _.findIndex(this.soaConfigListForLTT, soaMatter => (soaMatter.itemKey == AccountingItemKeys[ this.statementConfig.getLTTKey() ]));
      if (matterParticipantIndex >= 0) {
        this.soaConfigListForLTT.splice(matterParticipantIndex, 1);
      }
    }
  }

  isCustomSoaTemplate(): boolean {
    return this.soaConfig.soaSelectedTemplate && this.soaConfig.soaSelectedTemplate.isCustomSoaTemplate();
  }

  addLTT(): void {
    // Sale and Mortgage matter don't include LTT
    if (this.matter && (this.isSaleOrSaleOpportunity || this.isMortgageOrMortgageOpportunity || this.isDischargeOrDischargeOpportunity || this.matter.isCustomMatter() || this.isCustomSoaTemplate())) {
      return;
    }

    if (this.matter && this.matter.isMatterProvinceNBorNS) {
      this.createLttNSNB();
    } else {
      if (!this.isAnyLTTRowAvailable()) {
        this.removeLTT();
        this.createLTT();
      } else if (this.isOntarioTorontoLTTAvailable() && !this.isTorontoLTTApplicable()) {
        this.removeLTT();
        this.createLTT();
      } else if (this.isOntarioLTTExist() && this.isTorontoLTTExist() && !this.isTorontoLTTApplicable()) {
        this.removeTorontoLTT();
      } else if (this.isOntarioLTTExist() && !this.isTorontoLTTExist() && this.isTorontoLTTApplicable()) {
        if (this.statementConfig.isOntarioTorontoLTTOneEntry()) {
          this.removeLTT();
        }
        this.createLTT();
      }
    }
  }

  removeNRST(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      let purchaseDisbursementIndex: number = _.findIndex(this.disbursementsNotHST, soaMatter => soaMatter.isNrst());
      if (purchaseDisbursementIndex >= 0) {
        this.disbursementsNotHST.splice(purchaseDisbursementIndex, 1);
      }
      let matterTrustLedgerIndex: number = _.findIndex(this.matterTrustLedgers, trustLedgerMatter => trustLedgerMatter.isNrst());
      if (matterTrustLedgerIndex >= 0) {
        this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
      }
      this.updateFeeBasedOnAllIncPrice();
    }
  }

  /*
    adding Non-Resident Speculation Tax
     */
  addNRST(): void {
    // Sale and Mortgage matter don't include LTT
    if (this.matter && (this.isSaleOrSaleOpportunity || this.isMortgageOrMortgageOpportunity || this.isCustomSoaTemplate())) {
      return;
    }
    // NRST does not apply to NS or NB
    if (this.matter && (this.matter.isMatterProvinceNBorNS)) {
      return;
    }

    if (this.statementConfig && this.statementConfig.id > 0 && this.matter && this.matter.considerationLtt) {
      if (this.matter.considerationLtt.isNrstApplied()) {
        let purchaseDisbursement: SoaMatter = _.find(this.disbursementsNotHST, soaMatter => soaMatter.isNrst());
        let matterTrustLedger: TrustLedgerMatter = _.find(this.matterTrustLedgers, trustLedgerMatter => trustLedgerMatter.isNrst());
        if (this.statementConfig.isNrstSetForSoa()) {
          if (!purchaseDisbursement) {

            let disbursementsConfig: DisbursementConfig;

            if (this.statementConfig.statementOfAccount
              && this.statementConfig.statementOfAccount.disbursementsConfig) {
              disbursementsConfig = this.statementConfig.statementOfAccount.disbursementsConfig.find(item => item.isNrst() && item.matterType == this.matter.matterType);
            }

            let soaMatterOnt = new SoaMatter();
            soaMatterOnt.progressionStatus = this.soaProgressionStatus;
            soaMatterOnt.id = null;
            soaMatterOnt.statementConfigurationId = this.statementConfiguration.id;
            soaMatterOnt.itemKey = SoaTrustLedgerConfigKeys.L1_NR;
            soaMatterOnt.itemName = SoaConstants.NRST_DESC;
            soaMatterOnt.itemValue = 0;
            soaMatterOnt.f9DefaultAmount = 0;
            if (disbursementsConfig) {
              soaMatterOnt.accountCode = disbursementsConfig.configAccountCode;
              soaMatterOnt.disbursementConfigId = disbursementsConfig.id;
            }
            if (this.statementConfiguration.isNRSTForSoaAuto()) {
              soaMatterOnt.itemValue = this.matter.considerationLtt.nrstAmount;
              soaMatterOnt.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            } else if (this.matter && this.matter.considerationLtt) {
              soaMatterOnt.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            }
            soaMatterOnt.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
            soaMatterOnt.readOnly = false;
            if (this.soaConfig && this.soaConfig.id > 0) {
              let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.code == AccountingItemKeys[ AccountingItemKeys.NRST ]);
              if (selectedSoaFeIndex > -1) {
                this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatterOnt);
              } else {
                this.disbursementsNotHST.push(soaMatterOnt);
              }
            }
          } else {
            if (this.statementConfiguration.isNRSTForSoaAuto() && (purchaseDisbursement.itemValue == 0 || purchaseDisbursement.itemValue == this.matter.considerationLtt.nrstAmount)) {
              purchaseDisbursement.itemValue = this.matter.considerationLtt.nrstAmount;
              purchaseDisbursement.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            } else {
              purchaseDisbursement.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            }
          }
        } else {

          if (!matterTrustLedger) {
            let trustLedger = new TrustLedgerMatter();
            trustLedger.progressionStatus = this.tlProgressionStatus;
            trustLedger.id = null;
            trustLedger.statementConfigurationId = this.statementConfiguration.id;
            trustLedger.itemKey = SoaTrustLedgerConfigKeys.L1_NR;
            trustLedger.itemName = SoaConstants.NRST_DESC;
            trustLedger.itemValue = 0;
            trustLedger.f9DefaultAmount = 0;
            trustLedger.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
            if (this.statementConfiguration.isNRSTForTrustLedgerAuto() || (this.statementConfiguration.isNRSTForTrustLedgerAsF9Value() && this.matter.autoInsertAllF9Values)) {
              trustLedger.itemValue = this.matter.considerationLtt.nrstAmount;
              trustLedger.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            } else {
              trustLedger.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            }
            trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
            if (this.soaConfig && this.soaConfig.id > 0) {
              let selectedSoaFeIndex = this.trustLedger.getTrustLedgerList(this.matter).findIndex(t => t.isNrst());
              if (selectedSoaFeIndex > -1) {
                this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
              } else {
                this.matterTrustLedgers.push(trustLedger);
              }
            }
          } else if (!matterTrustLedger.isItemized()) {
            if ((this.statementConfiguration.isNRSTForTrustLedgerAuto() || (this.statementConfiguration.isNRSTForTrustLedgerAsF9Value() && this.matter.autoInsertAllF9Values))) {
              matterTrustLedger.itemValue = this.matter.considerationLtt.nrstAmount;
              matterTrustLedger.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            } else {
              matterTrustLedger.f9DefaultAmount = this.matter.considerationLtt.nrstAmount;
            }
          }
        }
      } else {
        this.removeNRST();
      }
      this.updateFeeBasedOnAllIncPrice();
    }

  }

  isTorontoLTTApplicable(): boolean {

    return this.matter &&
      this.matter.isPropertyTeranetJurisdictionToronto() &&
      (this.matter.considerationLtt && this.matter.considerationLtt.applyCityOfTorontoLandTransferTax == 'YES') &&
      this.matter.matterType != 'SALE';
  }

  private addOntarioTorontoSoaLTT(): void {
    let soaMatter = new SoaMatter();
    soaMatter.progressionStatus = this.soaProgressionStatus;
    soaMatter.statementConfigurationId = this.statementConfiguration.id;
    soaMatter.itemKey = SoaTrustLedgerConfigKeys.L1_OT;
    soaMatter.itemName = SoaConstants.NO_HST_ONTARIO_TORONTO_LTT;
    soaMatter.itemValue = 0;
    soaMatter.f9DefaultAmount = 0;
    if (this.matter && this.statementConfiguration && (this.statementConfiguration.isLTTForSoaAuto() || this.statementConfiguration.isLTTForTrustLedgerAuto()) && this.matter.considerationLtt) {
      soaMatter.itemValue = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
      soaMatter.f9DefaultAmount = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
    } else if (this.matter && this.matter.considerationLtt) {
      soaMatter.f9DefaultAmount = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
    }
    soaMatter.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';

    soaMatter.readOnly = false;
    if (this.soaConfig && this.soaConfig.id > 0) {
      let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.code == AccountingItemKeys[ AccountingItemKeys.ONTARIO_LTT_KEY ]);
      let disbursementsConfig = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').find(soaConfig => soaConfig.code == AccountingItemKeys[ AccountingItemKeys.ONTARIO_LTT_KEY ]);
      if (disbursementsConfig) {
        soaMatter.accountCode = disbursementsConfig.configAccountCode;
      }
      if (selectedSoaFeIndex > -1) {
        this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatter);
      } else {
        this.disbursementsNotHST.push(soaMatter);
      }
    }

  }

  private addOntarioTorontoTrustLedgerLTT(): void {
    let trustLedger = new TrustLedgerMatter();
    trustLedger.progressionStatus = this.tlProgressionStatus;
    trustLedger.statementConfigurationId = this.statementConfiguration.id;
    trustLedger.itemKey = SoaTrustLedgerConfigKeys.L1_OT;
    trustLedger.itemName = SoaConstants.PAID_NO_HST_ONTARIO_TORONTO_LTT;
    trustLedger.itemValue = 0;
    trustLedger.f9DefaultAmount = 0;
    trustLedger.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
    if (this.matter && this.statementConfiguration && (this.statementConfiguration.isLTTForSoaAuto() || this.statementConfiguration.isLTTForTrustLedgerAuto() || this.matter.autoInsertAllF9Values) && this.matter.considerationLtt) {
      trustLedger.itemValue = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
      trustLedger.f9DefaultAmount = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
    } else if (this.matter && this.matter.considerationLtt) {
      trustLedger.f9DefaultAmount = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
    }
    trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
    if (this.soaConfig && this.soaConfig.id > 0) {
      let selectedSoaFeIndex = this.trustLedger.getTrustLedgerList(this.matter).findIndex(tl => tl.isOntarioLTT());
      if (selectedSoaFeIndex > -1) {
        this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
      } else {
        this.matterTrustLedgers.push(trustLedger);
      }
    }

  }

  //add Land Transfer Tax (LTT) for Statement of Account(Soa) for Nova Scotia
  private addUpdateSoaLttNSNB(): void {
    if (this.matter.matterType == 'SALE') {
      return; // do not add for "SALE" matter types
    }
    let soaMatterDtt: SoaMatter = _.find(this.disbursementsNotHST, soaMatter => (soaMatter.isDTT() || soaMatter.isNBLTT()));
    const addNewItem: boolean = !soaMatterDtt;
    if (addNewItem) {
      soaMatterDtt = new SoaMatter();
      soaMatterDtt.progressionStatus = this.soaProgressionStatus;
      soaMatterDtt.id = null;
      soaMatterDtt.statementConfigurationId = this.statementConfig.id;
      soaMatterDtt.itemKey = AccountingItemKeys[ this.statementConfig.getLTTKey() ];
      soaMatterDtt.itemName = this.statementConfig.getSoALTTNameOfDisbursement();
      soaMatterDtt.itemValue = 0;
      soaMatterDtt.f9DefaultAmount = 0;
      soaMatterDtt.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
      if (this.soaConfig && this.soaConfig.id > 0) {
        let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.code === AccountingItemKeys[ this.statementConfig.getLTTKey() ]);//todo need to changed
        let disbursementsConfig = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').find(soaConfig => soaConfig.code === AccountingItemKeys[ this.statementConfig.getLTTKey() ]);//todo need to be changed
        if (disbursementsConfig) {
          soaMatterDtt.accountCode = disbursementsConfig.configAccountCode;
        }
        if (selectedSoaFeIndex > -1) {
          this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatterDtt);
        } else {
          this.disbursementsNotHST.push(soaMatterDtt);
        }
      }
    }
    soaMatterDtt.readOnly = false;
    if (this.matter && this.matter.matterPropertyWithCondo) {
      const dttValue: number = this.matter.matterPropertyWithCondo.landTransferTax;
      soaMatterDtt.f9DefaultAmount = dttValue;
      //for Soa, there is no MatterLevel autoInsertF9, only adminConfig level autoInsertF9
      if (addNewItem && this.statementConfiguration && this.statementConfiguration.isLTTForSoaAuto()) {
        soaMatterDtt.itemValue = dttValue;
      }
    }
  }

  private addOntarioSoaLTT(): void {
    if (this.matter.matterType == 'SALE') {
      return; // do not add for "SALE" matter types
    }
    let soaMatterOnt = new SoaMatter();
    soaMatterOnt.progressionStatus = this.soaProgressionStatus;
    soaMatterOnt.id = null;
    soaMatterOnt.statementConfigurationId = this.statementConfiguration.id;
    soaMatterOnt.itemKey = SoaTrustLedgerConfigKeys.L1_O;
    soaMatterOnt.itemName = SoaConstants.NO_HST_ONTARIO_LTT;
    soaMatterOnt.itemValue = 0;
    soaMatterOnt.f9DefaultAmount = 0;
    if (this.matter && this.statementConfiguration && (this.statementConfiguration.isLTTForSoaAuto() || this.statementConfiguration.isLTTForTrustLedgerAuto()) && this.matter.considerationLtt) {
      soaMatterOnt.itemValue = this.matter.considerationLtt.landTransferTaxValueOnt;
      soaMatterOnt.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueOnt;
    } else if (this.matter && this.matter.considerationLtt) {
      soaMatterOnt.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueOnt;
    }
    soaMatterOnt.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
    soaMatterOnt.readOnly = false;
    if (this.soaConfig && this.soaConfig.id > 0) {
      let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.code == AccountingItemKeys[ AccountingItemKeys.ONTARIO_LTT_KEY ]);
      let disbursementsConfig = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').find(soaConfig => soaConfig.code == AccountingItemKeys[ AccountingItemKeys.ONTARIO_LTT_KEY ]);
      if (disbursementsConfig) {
        soaMatterOnt.accountCode = disbursementsConfig.configAccountCode;
      }
      if (selectedSoaFeIndex > -1) {
        this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatterOnt);
      } else {
        this.disbursementsNotHST.push(soaMatterOnt);
      }
    }
  }

  private addPurchaseDisbursementsNotHST(selectedSoaFeIndex: number, soaMatter: SoaMatter): void {

    if (selectedSoaFeIndex == 0) {
      this.disbursementsNotHST.unshift(soaMatter);
    } else if (this.soaConfig && this.soaConfig.id > 0) {
      for (let i = selectedSoaFeIndex; i >= 0; i--) {
        let soaConfig = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST')[ i ];
        if (soaConfig) {
          let selectedIndex = this.disbursementsNotHST.findIndex(item => item.disbursementConfigId == soaConfig.id);
          if (selectedIndex > -1) {
            this.disbursementsNotHST.splice(selectedIndex + 1, 0, soaMatter);
            break;
          }
        }

      }
      let purchaseDisbursement = this.disbursementsNotHST.find(soaConfig => soaConfig == soaMatter);
      if (!purchaseDisbursement) {
        this.disbursementsNotHST.push(soaMatter);
      }
    }
  }

  private addUpdateTrustLedgerLttNSNB(): void {
    let trustLedgerLtt: TrustLedgerMatter = _.find(this.matterTrustLedgers, trustLedgerMatter => (trustLedgerMatter.isDTT() || trustLedgerMatter.isNBLTT()));
    const addNewItem: boolean = !trustLedgerLtt;
    if (addNewItem) {
      trustLedgerLtt = new TrustLedgerMatter();
      trustLedgerLtt.progressionStatus = this.tlProgressionStatus;
      trustLedgerLtt.id = null;
      trustLedgerLtt.statementConfigurationId = this.statementConfig.id;
      console.log('>> create new trustLedgerLtt with statementConfigurationId: ', trustLedgerLtt.statementConfigurationId);
      trustLedgerLtt.itemKey = AccountingItemKeys[ this.statementConfig.getLTTKey() ];
      trustLedgerLtt.itemName = this.statementConfig.getTrustLedgerLTTExpenseDescription();
      trustLedgerLtt.itemValue = 0;
      trustLedgerLtt.f9DefaultAmount = 0;
      trustLedgerLtt.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
      if (this.soaConfig && this.soaConfig.id > 0) {
        let selectedSoaFeIndex = this.trustLedger.getTrustLedgerList(this.matter).findIndex(tl => (tl.isLttNS() || tl.isLttNB()));
        if (selectedSoaFeIndex > -1) {
          this.insertTrustLedgerRowAsPerConfigOrder(trustLedgerLtt, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
        } else {
          this.matterTrustLedgers.push(trustLedgerLtt);
        }
      }
    }
    trustLedgerLtt.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);

    if (this.matter && this.matter.matterPropertyWithCondo) {
      const dttValue: number = this.matter.matterPropertyWithCondo.landTransferTax;
      trustLedgerLtt.f9DefaultAmount = dttValue;
      //matter autoInsertF9 will applicable for newItem and also overwrite existing item's value
      //statementConfiguration's autoInsertF9 will only applicable for newItem.
      if (this.matter.autoInsertAllF9Values || (addNewItem && this.statementConfiguration && this.statementConfiguration.isLTTForTrustLedgerAuto())) {
        trustLedgerLtt.itemValue = dttValue;
      }
    }
  }

  private addOntarioTrustLedgerLTT(): void {
    let trustLedger = new TrustLedgerMatter();
    trustLedger.progressionStatus = this.tlProgressionStatus;
    trustLedger.id = null;
    trustLedger.statementConfigurationId = this.statementConfiguration.id;
    trustLedger.itemKey = SoaTrustLedgerConfigKeys.L1_O;
    trustLedger.itemName = SoaConstants.PAID_NO_HST_ONTARIO_LTT;
    trustLedger.itemValue = 0;
    trustLedger.f9DefaultAmount = 0;
    trustLedger.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
    if (this.matter && this.statementConfiguration && (this.statementConfiguration.isLTTForSoaAuto() || this.statementConfiguration.isLTTForTrustLedgerAuto() || this.matter.autoInsertAllF9Values) && this.matter.considerationLtt) {
      trustLedger.itemValue = this.matter.considerationLtt.landTransferTaxValueOnt;
      trustLedger.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueOnt;
    } else if (this.matter && this.matter.considerationLtt) {
      trustLedger.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueOnt;
    }
    trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
    if (this.soaConfig && this.soaConfig.id > 0) {
      let selectedSoaFeIndex = this.trustLedger.getTrustLedgerList(this.matter).findIndex(tl => tl.isOntarioLTT());
      if (selectedSoaFeIndex > -1) {
        this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
      } else {
        this.matterTrustLedgers.push(trustLedger);
      }
    }

  }

  private addTorontoSoaLTT(): void {
    let soaMatterTor = new SoaMatter();
    soaMatterTor.progressionStatus = this.soaProgressionStatus;
    soaMatterTor.id = null;
    soaMatterTor.statementConfigurationId = this.statementConfiguration.id;
    soaMatterTor.itemKey = SoaTrustLedgerConfigKeys.L2_T;
    soaMatterTor.itemName = SoaConstants.NO_HST_TORONTO_LTT;
    soaMatterTor.itemValue = 0;
    soaMatterTor.f9DefaultAmount = 0;
    if (this.matter && this.statementConfiguration && (this.statementConfiguration.isLTTForSoaAuto() || this.statementConfiguration.isLTTForTrustLedgerAuto()) && this.matter.considerationLtt) {
      soaMatterTor.itemValue = this.matter.considerationLtt.landTransferTaxValueTor;
      soaMatterTor.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueTor;
    } else if (this.matter && this.matter.considerationLtt) {
      soaMatterTor.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueTor;
    }
    soaMatterTor.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
    soaMatterTor.readOnly = false;
    if (this.soaConfig && this.soaConfig.id > 0) {
      let selectedSoaFeIndex = this.disbursementsNotHST.findIndex(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_O);
      let soaMatter = this.disbursementsNotHST.find(purchaseDisbursement => purchaseDisbursement.itemKey == SoaTrustLedgerConfigKeys.L1_O);
      if (soaMatter) {
        soaMatterTor.accountCode = soaMatter.accountCode;
      }
      if (selectedSoaFeIndex > -1) {
        this.disbursementsNotHST.splice(selectedSoaFeIndex + 1, 0, soaMatterTor);
      } else {
        this.disbursementsNotHST.push(soaMatterTor);
      }
    }
  }

  private addTorontoTrustLedgerLTT(): void {
    let trustLedger = new TrustLedgerMatter();
    trustLedger.progressionStatus = this.tlProgressionStatus;
    trustLedger.id = null;
    //trustLedger.identifier = -Date.now() + 1;
    trustLedger.statementConfigurationId = this.statementConfiguration.id;
    ;
    trustLedger.itemKey = SoaTrustLedgerConfigKeys.L2_T;
    trustLedger.itemName = SoaConstants.PAID_NO_HST_TORONTO_LTT;
    trustLedger.itemValue = 0;
    trustLedger.f9DefaultAmount = 0;
    trustLedger.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
    if (this.matter && this.statementConfiguration && (this.statementConfiguration.isLTTForSoaAuto() || this.statementConfiguration.isLTTForTrustLedgerAuto() || this.matter.autoInsertAllF9Values) && this.matter.considerationLtt) {
      trustLedger.itemValue = this.matter.considerationLtt.landTransferTaxValueTor;
      trustLedger.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueTor;
    } else if (this.matter && this.matter.considerationLtt) {
      trustLedger.f9DefaultAmount = this.matter.considerationLtt.landTransferTaxValueTor;
    }
    trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
    if (this.soaConfig && this.soaConfig.id > 0) {
      let selectedSoaFeIndex = this.trustLedger.getTrustLedgerList(this.matter).findIndex(tl => tl.isOntarioLTT());
      if (selectedSoaFeIndex > -1) {
        this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
      } else {
        this.matterTrustLedgers.push(trustLedger);
      }
    }

  }

  createLTT(combineOntarioToronto?: boolean): void {
    if (this.statementConfiguration && this.matter && this.matter.isMatterProvinceON) {
      if (((combineOntarioToronto !== undefined && combineOntarioToronto) ||
        (combineOntarioToronto === undefined && this.statementConfiguration.isOntarioTorontoLTTOneEntry())) && this.isTorontoLTTApplicable()) {
        this.statementConfiguration.isLTTSetForSoa() ? this.addOntarioTorontoSoaLTT() : this.addOntarioTorontoTrustLedgerLTT();
      } else {
        if (!this.isOntarioLTTExist()) {
          this.statementConfiguration.isLTTSetForSoa() ? this.addOntarioSoaLTT() : this.addOntarioTrustLedgerLTT();
        }
        if (!this.isTorontoLTTExist() && this.isTorontoLTTApplicable()) {
          this.statementConfiguration.isLTTSetForSoa() ? this.addTorontoSoaLTT() : this.addTorontoTrustLedgerLTT();
        }
      }

      if (this.statementConfiguration.isLTTSetForSoa()) {
        this.updateFeeBasedOnAllIncPrice();
      }
    }
  }

  createLttNSNB(): void {
    if (this.statementConfiguration && this.matter && this.matter.isMatterProvinceNBorNS) {
      if ((this.matter.isMatterProvinceNS && !this.isDTTExistNS()) || (this.matter.isMatterProvinceNB && !this.isLTTExistNB())) {
        this.statementConfiguration.isLTTSetForSoa() ? this.addUpdateSoaLttNSNB() : this.addUpdateTrustLedgerLttNSNB();
      } else {
        this.updateF9LttNSNB();
      }
    }
  }

  isFeeForMortgageAvailable(fromMortgageIndex: number): boolean {
    let selectedSoaFee: SoaFee = this.soaConfig.fees.find(soaConfig => soaConfig.isFeeForMortgage() || soaConfig.isFeeForAdditionalMortgage());
    if (selectedSoaFee && selectedSoaFee.id > 0) {
      return (this.fees.findIndex(soaMatter => (soaMatter.isMortgage() || soaMatter.isAdditionalMortgage()) && soaMatter.fromMortgageIndex == fromMortgageIndex) > -1);
    } else {
      return false;
    }

  }

  isMLTTAvailable(): boolean {
    return (this.disbursementsHST.findIndex(soaMatter => soaMatter.isMLTTConfiguration()) > -1);
  }

  isSoaMatterMortgageFee(item: SoaMatter): boolean {
    let soaMatterMortgageFee: SoaMatter = this.fees.find(soaMatter => soaMatter.isMortgage());
    return (soaMatterMortgageFee && item.feesId == soaMatterMortgageFee.feesId);
  }

  get matterType(): MatterType {
    if (this.matter) {
      return !!this.matter.isMatterTypeDischarge ? MatterTypesValue.DISCHARGE : this.matter.matterType;
    }
    return MatterTypesValue.PURCHASE;
  }

  get matterProvinceCode(): ProvinceCode {
    if (this.matter) {
      return this.matter.provinceCode;
    }
    return <ProvinceCode>PROVINCE_CODES.ONTARIO;
  }

  isSoaMatterMortgagePurchase(item: SoaMatter): boolean {
    let soaMatterTransactionFee: SoaMatter = this.fees.find(soaMatter => soaMatter.isTransactionFees(this.matterType, this.matterProvinceCode, (this.matter ? this.matter.customMatterTypeName : undefined)));
    return (soaMatterTransactionFee && item.feesId == soaMatterTransactionFee.feesId);
  }

  isNsSoaMatterMortgagePurchase(item: SoaMatter): boolean {
    let soaMatterTransactionFee: SoaMatter = this.fees.find(soaMatter => soaMatter.isNsOurFeesForTransaction(this.matterType, this.matter && this.matter.customMatterTypeName));
    return (soaMatterTransactionFee && item.feesId == soaMatterTransactionFee.feesId);
  }

  private removeMortgageBrokerageFee(mortgageIndex: number): void {
    let matterTrustLedgerIndex = this.matterTrustLedgers.findIndex(mtl => mtl.itemKey == TrustLedgerExpenditureTypes.BF_A && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  updateStatementOfAccountFeeForActingOnPriorityChange(): void {
    if (this.matter.matterType == 'MORTGAGE') {
      this.fees.forEach(item => {
        if (item.isMortgage()) {
          let baseName: string = this.isMyFee() ? SoaConstants.LABEL_ADDITIONAL_MORTGAGE_MY : SoaConstants.LABEL_ADDITIONAL_MORTGAGE_OUR;
          item.itemName = baseName.replace('*', Utils.getOrdinal(item.fromMortgageIndex));
        }
        if (item.isAdditionalMortgage()) {
          let baseName: string = this.isMyFee() ? SoaConstants.FEE_ADDITIONAL_MORTGAGE_MY : SoaConstants.FEE_ADDITIONAL_MORTGAGE_OUR;
          item.itemName = baseName.replace('*', Utils.getOrdinal(this.matter.mortgages[ item.fromMortgageIndex ].mortgagePriority));
        }
      }, this);

    }
  }

  updateMortgageIndex(deletedIndex: number): void {

    // if in a MORTGAGE matter with additional mortgages delete the last "additional" one if exists
    if (this.matter.matterType == 'MORTGAGE') {
      if (this.matter.mortgages.length > 0) {
        if (deletedIndex == 0) {
          //try to delete the first mortgage, then we need to change the itemKey of the  first additional mortgage to TxnFee if exist
          this.removeFeeForMortgage(0);
          //ToDo bug 23477
          //since we remove the first mortgage, we need to change the text for the first additional mortgage
          let additionalMortgageRows = this.fees.filter(fee => fee.isAdditionalMortgage());
          if (additionalMortgageRows && additionalMortgageRows.length > 0) {
            additionalMortgageRows[ 0 ].itemKey = this.getTxnFeeItemKey();
            additionalMortgageRows[ 0 ].itemName = this.getTxnFeeItemName();
            additionalMortgageRows[ 0 ].fromMortgageIndex = 0;
          }
        } else {
          this.removeFeeForMortgage(deletedIndex);
        }

      }
    } else {
      this.removeFeeForMortgage(deletedIndex);
    }
    //Here we will reset the fee's itemName and fromMortgageIndex after mortgage deletion
    this.fees.forEach(item => {
      if (item.isMortgage() && item.fromMortgageIndex > deletedIndex) {
        let baseName: string = this.isMyFee() ? SoaConstants.FEE_ARRANGED_MORTGAGE_MY : SoaConstants.FEE_ARRANGED_MORTGAGE_OUR;
        item.fromMortgageIndex = Number(item.fromMortgageIndex) - 1;
        item.itemName = baseName.replace('*', Utils.getOrdinal(item.fromMortgageIndex + 1));
      }
      if (item.isAdditionalMortgage() && item.fromMortgageIndex > deletedIndex) {
        item.fromMortgageIndex = Number(item.fromMortgageIndex) - 1;
        let baseName: string = this.isMyFee() ? SoaConstants.FEE_ADDITIONAL_MORTGAGE_MY : SoaConstants.FEE_ADDITIONAL_MORTGAGE_OUR;
        item.itemName = baseName.replace('*', Utils.getOrdinal(this.matter.mortgages[ item.fromMortgageIndex ].mortgagePriority));
      }
    }, this);

    this.removeMortgageFromTrustLedger(deletedIndex);
    this.removeMortgageBrokerageFee(deletedIndex);

    this.allMatterTrustLedgers.forEach(item => {
      if (item.itemKey == SoaTrustLedgerConfigKeys.L1_MG && item.fromMortgageIndex > deletedIndex) {
        item.fromMortgageIndex = Number(item.fromMortgageIndex) - 1;
        if (this.matter.mortgages && item.fromMortgageIndex < this.matter.mortgages.length) {
          let mortgage = this.matter.mortgages[ item.fromMortgageIndex ];
          if (mortgage) {
            mortgage.isMortgageePrivateLender() ?
              this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(mortgage) :
              this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(mortgage);
          }
        }
      }
      if (item.itemKey == TrustLedgerExpenditureTypes.BF_A && item.fromMortgageIndex > deletedIndex) {
        item.fromMortgageIndex = Number(item.fromMortgageIndex) - 1;
        if (item.fromMortgageIndex < this.matter.mortgages.length) {
          let mortgage = this.matter.mortgages[ item.fromMortgageIndex ];
          let brokerName = this.matter.getMortgageBrokerNameByMortgageId(mortgage.id);
          // item.itemName = this.getBrokerageFeeName(item.fromMortgageIndex+1, brokerName);
          item.itemName = this.getBrokerageFeeName(this.matter.mortgages[ item.fromMortgageIndex ].mortgagePriority, brokerName);
        }
      }
    }, this);
  }

  updateExistingMortgageIndex(deletedIndex: number): void {
    this.updateExistingMortgageIndexSingleView(deletedIndex);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.updateExistingMortgageIndexSingleView(deletedIndex);
      this.progressionStatus = progressStatus;
    }
  }

  updateExistingMortgageIndexSingleView(deletedIndex: number): void {
    this.removeExistingMortgageFromTrustLedger(deletedIndex);

    this.matterTrustLedgers.forEach(item => {
      if (item.itemKey == SoaTrustLedgerConfigKeys.L1_PM && item.fromMortgageIndex > deletedIndex) {
        item.fromMortgageIndex = Number(item.fromMortgageIndex) - 1;
        if (Array.isArray(this.matter.existingMortgages) && item.fromMortgageIndex < this.matter.existingMortgages.length) {
          let existingMortgage = this.matter.existingMortgages[ item.fromMortgageIndex ];
          if (existingMortgage) {
            existingMortgage.isMortgageePrivateLender() ?
              this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(existingMortgage) :
              this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(existingMortgage);
          }
        }
      }
    }, this);
  }

  updatePaidDischargeFeeIndex(deletedIndex: number): void {
    this.updatePaidDischargeFeeIndexSingleView(deletedIndex);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.updatePaidDischargeFeeIndexSingleView(deletedIndex);
      this.progressionStatus = progressStatus;
    }
  }

  updatePaidDischargeFeeIndexSingleView(deletedIndex: number): void {
    this.removeTrustLedgerPaidDischargeFee(deletedIndex);

    this.matterTrustLedgers.forEach((item) => {
      if ((item.itemKey == SoaTrustLedgerConfigKeys.L1_PDF || item.itemKey == SoaTrustLedgerConfigKeys.L1_PDF_A) && item.fromMortgageIndex > deletedIndex) {
        item.fromMortgageIndex = Number(item.fromMortgageIndex) - 1;
        if (Array.isArray(this.matter.existingMortgages) && item.fromMortgageIndex < this.matter.existingMortgages.length) {
          let existingMortgage = this.matter.existingMortgages[ item.fromMortgageIndex ];
          if (existingMortgage) {
            item.itemName = SoaConstants.PAID_DISCHARGE_PREVIOUS_MORTGAGEE.replace('###', Utils.getOrdinal(item.fromMortgageIndex + 1)) + this.addChequePayableTo(existingMortgage);
          }
        }
      }
    });
  }

  addOrRemoveFeeForAllMortgages() {
    if (this.matter && this.matter.isProjectSale) {
      return;
    }
    if (this.matter && this.matter.mortgages && this.matter.mortgages.length > 0) {
      this.matter.mortgages.forEach(m => this.addOrRemoveFeeForMortgage(m, this.matter.mortgages.indexOf(m)));
    }
  }

  addOrRemoveFeeForMortgage(mortgage: Mortgage, mortgageIndex: number): void {
    if (this.matter && this.matter.isProjectSale) {
      return;
    }
    if (mortgage.isLoanTypeArranged() || (mortgage.isExistingMortgage() && mortgage.isMortgageDispositionRemain())) {

      let soaFee: SoaFee;
      if (!this.isFeeForMortgageAvailable(mortgageIndex) && this.soaConfig && this.soaConfig.id > 0) {
        if (this.matter && this.matter.matterType == 'MORTGAGE') {
          if (mortgageIndex !== 0) { // do not add this fee for first mortgage on a mortgage file
            soaFee = this.soaConfig.getSoaFeeForAdditionalMortgage();
          }
        } else {
          soaFee = this.soaConfig.getSoaFeeForMortgage();
        }
        if (soaFee && soaFee.enabled) {
          let mortgageFeeSoaMatter = new SoaMatter();
          mortgageFeeSoaMatter.progressionStatus = this.soaProgressionStatus;
          mortgageFeeSoaMatter.itemName = soaFee.nameOfFees;
          mortgageFeeSoaMatter.feesId = soaFee.id;
          mortgageFeeSoaMatter.itemValue = 0;
          mortgageFeeSoaMatter.itemType = 'FEES';
          mortgageFeeSoaMatter.itemKey = soaFee.code;
          mortgageFeeSoaMatter.f9DefaultAmount = soaFee.f9DefaultAmount;
          mortgageFeeSoaMatter.readOnly = true;
          mortgageFeeSoaMatter.fromMortgageIndex = mortgageIndex;

          this.updateFeeLabel(mortgageFeeSoaMatter, soaFee);
          this.addFeeForMortgage(mortgageFeeSoaMatter);
          this.updateFeeLabelBasedOnDefaultProfile();
        }
      }
    } else {
      this.removeFeeForMortgage(mortgageIndex);
    }

  }

  private addFeeForMortgage(mortgageFeeSoaMatter: SoaMatter) {

    let mortgageFeeEntryIndexInConfig: number = this.soaConfig.fees.findIndex(soaConfig => soaConfig.isFeeForMortgage() || soaConfig.isFeeForAdditionalMortgage());
    //let soaFee : SoaFee = this.soaConfig.getSoaFeeForMortgage();

    if (mortgageFeeEntryIndexInConfig == 0) {
      //mortgageFee is the very first one in the config. so add it to the top of the display collection if none exist so far
      if (!this.fees.find(fee => fee.isMortgage())) {
        this.fees.unshift(mortgageFeeSoaMatter);
      } else {
        //mortgageFee is the very first one in the config. insert it before the other mortgageFee with a higher mortgageIndex number or at the end of
        // mortgage fees
        this.addFeeForMortgageStartingFromIndex(mortgageFeeSoaMatter, 0);
      }
    } else {
      let indexInDisplayCollection: number = 0;
      //go through each Fee config entry (this.soaConfig.fees[i]) that needs to be before the mortgagee fee
      for (let i = mortgageFeeEntryIndexInConfig - 1; i >= 0; i--) {

        //try to find the config fee that is supposed to be before mortgage fee in the display collection
        // TODO : NEED TO CHANGE FROM ID TO KEY
        indexInDisplayCollection = this.fees.findIndex(soaMatter => soaMatter.feesId == this.soaConfig.fees[ i ].id);
        if (indexInDisplayCollection == -1) {
          if (i == 0) {
            //all config entries before mortgage fee are not in the display collection, so add mortgage fee to the top
            //the other mandatory fee should be after mortgage fee
            this.addFeeForMortgageStartingFromIndex(mortgageFeeSoaMatter, 0);
          }
        } else {
          //add the mortgagee fee after the first found Configured Fee entries in the display collection
          this.addFeeForMortgageStartingFromIndex(mortgageFeeSoaMatter, indexInDisplayCollection + 1);
          break;
        }
      }
    }
  }

  private addFeeForMortgageStartingFromIndex(mortgageFeeSoaMatter: SoaMatter, startFromIndex: number) {
    let soaFeeFromConfig: SoaFee = this.soaConfig.getSoaFeeForMortgage();
    if (!soaFeeFromConfig) {
      soaFeeFromConfig = this.soaConfig.getSoaFeeForAdditionalMortgage();
    }
    if (startFromIndex == this.fees.length) {
      this.fees.push(mortgageFeeSoaMatter);
    } else {
      let inserted: boolean = false;
      for (let idx = startFromIndex; idx < this.fees.length; idx++) {
        // TODO : NEED TO CHANGE FROM ID TO KEY
        if ((this.fees[ idx ].feesId == soaFeeFromConfig.id && this.fees[ idx ].fromMortgageIndex > mortgageFeeSoaMatter.fromMortgageIndex) || (this.fees[ idx ].feesId != soaFeeFromConfig.id)) {
          this.fees.splice(idx, 0, mortgageFeeSoaMatter);
          inserted = true;
          break;
        }
      }
      if (!inserted) {
        this.fees.push(mortgageFeeSoaMatter);
      }
    }
  }

  addOrRemoveMLTT(): void {

    if (this.matter && this.matterType !== 'SALE' &&
      !this.matter.isMatterProvinceNBorNS &&
      this.matter.isPropertyTeranetJurisdictionToronto() &&
      this.matter.considerationLtt &&
      this.matter.considerationLtt.applyMLTTBasedOnSubjectToLTT() && !this.isCustomSoaTemplate()) {
      const soaPurchase = this.soaConfig.getMLTT();
      if (this.isMLTTAvailable()) {
        let mlttSoa: SoaMatter = this.disbursementsHST.find(soaMatter => soaMatter.isMLTTConfiguration());
        if (mlttSoa) {
          mlttSoa.f9DefaultAmount = this.matter.isMatterProvinceON ? this.getSoaFeeRateOfMLTT() : soaPurchase.f9DefaultAmount;
        }
      }
      if (!this.isMLTTAvailable() && this.soaConfig.getMLTT() && this.soaConfig && this.soaConfig.id > 0) {
        let soaMatter = new SoaMatter();
        soaMatter.progressionStatus = this.soaProgressionStatus;
        soaMatter.disbursementConfigId = soaPurchase.id;
        soaMatter.itemName = soaPurchase.nameOfDisbursement;
        soaMatter.itemValue = 0;
        soaMatter.itemType = 'DISBURSEMENT_SUBJECT_TO_HST';
        soaMatter.itemKey = soaPurchase.code;
        //for MLTT, the ON fees has effective date (from soa_fees_rate table)
        soaMatter.f9DefaultAmount = this.matter.isMatterProvinceON ? this.getSoaFeeRateOfMLTT() : soaPurchase.f9DefaultAmount;
        soaMatter.readOnly = soaPurchase.readOnly;
        soaMatter.accountCode = soaPurchase.configAccountCode;
        let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isMLTTConfiguration());
        if (selectedSoaFeIndex > -1) {
          this.disbursementsHST.splice(selectedSoaFeIndex, 0, soaMatter);
        } else {
          this.disbursementsHST.push(soaMatter);
        }

      }
    } else {
      this.removeMLTT();
    }
  }

  isCondoFeeAdded(): boolean {
    return (this.disbursementsHST.findIndex(item => item.isCondoFeeAdded(this.matter)) > -1 || this.disbursementsNotHST.findIndex(item => item.isCondoFeeAdded(this.matter)) > -1);
  };

  isRetailTaxChattelsFeeAdded(): boolean {
    return (this.disbursementsNotHST.findIndex(item => item.isRetailTaxChattelsAdded()) > -1);
  }

  removeCondoFee(): void {
    _.remove(this.disbursementsHST, (matterSoa: SoaMatter) => {
      return matterSoa.isCondoFeeAdded(this.matter);
    });
    _.remove(this.disbursementsNotHST, (matterSoa: SoaMatter) => {
      return matterSoa.isCondoFeeAdded(this.matter);
    });
    this.updateFeeBasedOnAllIncPrice();
  }

  updateCondoFee(): void {
    this.addOrRemoveCondoFee();
    if (this.matter && this.isCondoFeeAdded() && this.matter.condoCorporation && this.matter.isCondoCorporation && this.matter.condoCorporation.contact) {
      let condoCertificateFee = this.condoFeePurchaseDisbursement();
      if (condoCertificateFee) {
        // condoCertificateFee should not be override. DPPMP-15259
        // condoCertificateFee.itemValue = this.matter.condoCorporation.contact.feeForCondoStatusCertificate;
        this.updateFeeBasedOnAllIncPrice();
      }
    }
  }

  private condoFeePurchaseDisbursement(): SoaMatter {
    let condoCertificateFee = this.disbursementsHST.find(item => item.isCondoFeeAdded(this.matter));
    return condoCertificateFee ? condoCertificateFee : this.disbursementsNotHST.find(item => item.isCondoFeeAdded(this.matter));
  }

  retailTaxFee(provinceHstRateSlab: ConsiderationTaxes): number {
    return this.matter && provinceHstRateSlab && provinceHstRateSlab.hstProvincialPortion && this.matter.considerationLtt ? Number((this.matter.considerationLtt.valueOfAllChattles * provinceHstRateSlab.hstProvincialPortion) / 100) : 0;
  }

  updateRetailTaxChattels(provinceHstRateSlab: ConsiderationTaxes, updateItemValue: boolean): void {
    if (this.matter && this.matter.isProjectSale) {
      return;
    }
    let retailTaxChattelFee = this.retailTaxFee(provinceHstRateSlab);
    if (retailTaxChattelFee > 0.00) {
      if (this.isRetailTaxChattelsFeeAdded()) {
        let retailTaxChattel = this.disbursementsNotHST.find(item => item.isRetailTaxChattelsAdded());
        if (retailTaxChattel) {
          retailTaxChattel.f9DefaultAmount = retailTaxChattelFee > 0 ? retailTaxChattelFee : 0;
          if (this.statementConfig.isLTTForSoaAuto() && updateItemValue) {
            retailTaxChattel.itemValue = retailTaxChattelFee > 0 ? retailTaxChattelFee : 0;
          }
        }
      } else {
        let soaPurchase = this.soaConfig.getRetailTax();
        if (soaPurchase) {
          let soaMatter = new SoaMatter();
          soaMatter.progressionStatus = this.soaProgressionStatus;
          soaMatter.disbursementConfigId = soaPurchase.id;
          soaMatter.itemName = soaPurchase.nameOfDisbursement;
          if (this.statementConfig.isLTTForSoaAuto() && updateItemValue) {
            soaMatter.itemValue = retailTaxChattelFee > 0 ? retailTaxChattelFee : 0;
          } else {
            soaMatter.itemValue = 0;
          }
          soaMatter.f9DefaultAmount = retailTaxChattelFee;
          soaMatter.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
          soaMatter.itemKey = soaPurchase.code;
          soaMatter.accountCode = soaPurchase.configAccountCode;
          soaMatter.readOnly = soaPurchase.readOnly;
          let selectedIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isRetailSalesTaxChattels());
          if (selectedIndex > -1) {
            this.addPurchaseDisbursementsNotHST(selectedIndex, soaMatter);
          }
        }
      }
    } else {
      _.remove(this.disbursementsNotHST, function (matterSoa: SoaMatter) {
        return matterSoa.isRetailTaxChattelsAdded();
      });
    }

  }

  private addCondoFee = () => {
    let condoProperty = this.matter.condoCorporation;
    let soaPurchase;
    if (condoProperty.contact) {
      soaPurchase = this.soaConfig.getCondoCertificate(condoProperty.contact.isFeeSubjectToHST());
    }
    if (soaPurchase) {
      let soaMatter = new SoaMatter();
      soaMatter.progressionStatus = this.soaProgressionStatus;
      soaMatter.disbursementConfigId = soaPurchase.id;
      soaMatter.itemName = soaPurchase.nameOfDisbursement;
      soaMatter.readOnly = false;
      soaMatter.f9DefaultAmount = condoProperty.contact && condoProperty.contact.feeForCondoStatusCertificate ? condoProperty.contact.feeForCondoStatusCertificate : 0;
      soaMatter.itemValue = 0;
      soaMatter.itemType = condoProperty.contact && condoProperty.contact.isFeeSubjectToHST() ? 'DISBURSEMENT_SUBJECT_TO_HST' : 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
      soaMatter.itemKey = soaPurchase.code;

      soaMatter.accountCode = soaPurchase.configAccountCode;
      if (condoProperty.contact && condoProperty.contact.isFeeSubjectToHST()) {
        let selectedIndex = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isCondoCertificationFee());
        if (selectedIndex > -1) {
          this.disbursementsHST.splice(selectedIndex, 0, soaMatter);
        } else {
          this.disbursementsHST.push(soaMatter);
        }

      } else {

        if (this.soaConfig && this.soaConfig.id > 0) {
          let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isCondoCertificationFee());
          if (selectedSoaFeIndex > -1) {
            this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatter);
          } else {
            this.disbursementsNotHST.push(soaMatter);
          }
        }

      }

    }
  };

  private addCondoFeeAB = () => {
    let condoCorporationDocumentation = this.matter.condoCorporationDocumentation;
    if (condoCorporationDocumentation) {
      let soaPurchase = this.soaConfig.getCondoDocuments(condoCorporationDocumentation.isFeeSubjectToHST());

      if (soaPurchase) {
        let soaMatter = new SoaMatter();
        soaMatter.progressionStatus = this.soaProgressionStatus;
        soaMatter.disbursementConfigId = soaPurchase.id;
        soaMatter.itemName = soaPurchase.nameOfDisbursement;
        soaMatter.readOnly = false;
        soaMatter.f9DefaultAmount = condoCorporationDocumentation.costOfDocumentsOrdered ? condoCorporationDocumentation.costOfDocumentsOrdered : 0;
        soaMatter.itemValue = 0;
        soaMatter.itemType = condoCorporationDocumentation.isFeeSubjectToHST() ? 'DISBURSEMENT_SUBJECT_TO_HST' : 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
        soaMatter.itemKey = soaPurchase.code;

        soaMatter.accountCode = soaPurchase.configAccountCode;
        if (condoCorporationDocumentation.isFeeSubjectToHST()) {
          let selectedIndex = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isCondoDocumentsFee());
          if (selectedIndex > -1) {
            this.disbursementsHST.splice(selectedIndex, 0, soaMatter);
          } else {
            this.disbursementsHST.push(soaMatter);
          }

        } else {

          if (this.soaConfig && this.soaConfig.id > 0) {
            let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isCondoDocumentsFee());
            if (selectedSoaFeIndex > -1) {
              this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatter);
            } else {
              this.disbursementsNotHST.push(soaMatter);
            }
          }

        }

      }
    }

  };

  getProvinceBasedAddCondoVariable(): any {
    if (this.matter && (this.matter.isMatterProvinceONorNBorNS)) {
      return this.matter && this.matter.condoCorporation;
    } else {
      return this.matter.condoCorporationDocumentation;
    }
  }

  callProvinceBasedAddCondoFees(): void {
    this.provinceBasedAddCondoFees[ this.matter.provinceCode ] ? this.provinceBasedAddCondoFees[ this.matter.provinceCode ]() : this.provinceBasedAddCondoFees[ 'DEFAULT' ]();
  }

  isCondoCorporationFeesSubjectToHST(): boolean {
    if (this.matter.isMatterProvinceABorMBorSK) {
      return this.matter.condoCorporationDocumentation && this.matter.condoCorporationDocumentation.isFeeSubjectToHST();
    } else {
      return this.matter.condoCorporation && this.matter.condoCorporation.contact && this.matter.condoCorporation.contact.isFeeSubjectToHST();
    }
  }

  /* ------------------------------- Province Based Methods -----------------------------------*/
  provinceBasedAddCondoFees = {
    'DEFAULT': this.addCondoFee,
    'AB': this.addCondoFeeAB,
    'MB': this.addCondoFeeAB,
    'SK': this.addCondoFeeAB

  };

  /* ------------------------------- Province Based Methods -----------------------------------*/

  addOrRemoveCondoFee(): void {
    if (this.matter && this.matter.isProjectSale) {
      return;
    }
    if (this.matter && this.getProvinceBasedAddCondoVariable() && this.matter.isCondoCorporation && this.soaConfig && this.soaConfig.id > 0) {
      if (!this.isCondoFeeAdded()) {
        this.callProvinceBasedAddCondoFees();
      } else if (this.isCondoFeeAdded() && this.isCondoCorporationFeesSubjectToHST() && this.disbursementsNotHST.findIndex(item => item.isCondoFeeAdded(this.matter)) > -1) {
        this.removeCondoFee();
        this.callProvinceBasedAddCondoFees();
      } else if (this.isCondoFeeAdded() && !this.isCondoCorporationFeesSubjectToHST() && this.disbursementsHST.findIndex(item => item.isCondoFeeAdded(this.matter)) > -1) {
        this.removeCondoFee();
        this.callProvinceBasedAddCondoFees();
      }
    } else {
      this.removeCondoFee();
    }
    this.updateFeeBasedOnAllIncPrice();

  }

  removeEmpSoaAndTrustLedger(): void {
    this.removeAllEMPFromTrust();
    this.removeEMPFromSOA();
  }

  addOrRemoveEmpSoaAndTrustLedger(): void {
    if (this.matter && this.matter.mortgages.length > 0 && this.matter.isAnyMortgageEMP && this.soaConfig && this.soaConfig.id > 0 && this.statementConfig && this.statementConfig.isEMPForSoa()) {
      this.addEMPToSOA();
    } else {
      this.addEMPToTrust();
    }
  }

  addNewEmpToTrustLedger() {
    const trustLedgerConfigList = this.trustLedger.getTrustLedgerList(this.matter);
    const selectedSoaFeIndex = trustLedgerConfigList && trustLedgerConfigList.findIndex(tl => tl.isEmp());
    //    let mortgageIndex:number = this.matter.mortgages.findIndex(tl => tl === mortgage);
    let trustLedger: TrustLedgerMatter = new TrustLedgerMatter();
    trustLedger.progressionStatus = this.tlProgressionStatus;

    trustLedger.statementConfigurationId = this.statementConfiguration.id;
    trustLedger.itemKey = SoaTrustLedgerConfigKeys.L1_EF;
    trustLedger.itemName = this.getEmpItemName(0);
    trustLedger.itemValue = 0;
    trustLedger.f9DefaultAmount = 0;
    trustLedger.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
    trustLedger.fromMortgageIndex = 0;
    if (this.matter && this.statementConfig) {
      let empCharges: number = this.matter.getMortgageEMPCharges(true);
      if (this.statementConfig.isEMPForTrustLedgerAuto() || this.matter.autoInsertAllF9Values) {
        trustLedger.itemValue = empCharges;
        trustLedger.f9DefaultAmount = empCharges;
      } else {
        trustLedger.itemValue = 0;
        trustLedger.f9DefaultAmount = empCharges;
      }

    }

    trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;

    if (selectedSoaFeIndex > -1) {
      this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
    } else {
      this.matterTrustLedgers.push(trustLedger);
    }

  }

  addNewEmpTrustLedger(mortgage: Mortgage) {
    // Update EMP mortagages as seperate lines
    // this code is depricated , using one line function addNewEmpToTrustLedger()
    if (mortgage.isEmpMortgage() && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.mortgageFeesData && mortgage.stewartAssystMortgageInstruction.mortgageFeesData.fee) {

      let mortgageIndex: number = this.matter.mortgages.findIndex(tl => tl === mortgage);
      let trustLedger: TrustLedgerMatter = new TrustLedgerMatter();
      trustLedger.progressionStatus = this.tlProgressionStatus;

      trustLedger.statementConfigurationId = this.statementConfiguration.id;
      trustLedger.itemKey = SoaTrustLedgerConfigKeys.L1_EF;
      trustLedger.itemName = this.getEmpItemName(0);
      trustLedger.itemValue = 0;
      trustLedger.f9DefaultAmount = 0;
      trustLedger.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
      trustLedger.fromMortgageIndex = mortgageIndex;
      if (this.matter && this.statementConfig) {
        let empCharges: number = mortgage.stewartAssystMortgageInstruction.mortgageFeesData.fee;
        if (this.statementConfig.isEMPForTrustLedgerAuto() || this.matter.autoInsertAllF9Values) {
          trustLedger.itemValue = empCharges;
          trustLedger.f9DefaultAmount = empCharges;
        } else {
          trustLedger.itemValue = 0;
          trustLedger.f9DefaultAmount = empCharges;
        }

      }

      trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;

      // let matterTrustLedgerLast = this.matterTrustLedgers.slice(0).reverse().find(l => l.isEMP);
      const firstEMPPosition = this.matterTrustLedgers.findIndex(l => l.isEMP);
      const trustLedgerConfigList = this.trustLedger.getTrustLedgerList(this.matter);
      const selectedSoaFeIndex = trustLedgerConfigList && trustLedgerConfigList.findIndex(tl => tl.isEmp());

      if (firstEMPPosition > -1) {
        let inserted: boolean = false;
        for (let i = firstEMPPosition; i < this.matterTrustLedgers.length; ++i) {
          let matterTrustLedger = this.matterTrustLedgers[ i ];
          if (!matterTrustLedger.isEMP || matterTrustLedger.fromMortgageIndex > trustLedger.fromMortgageIndex) {
            this.matterTrustLedgers.splice(i, 0, trustLedger);
            inserted = true;
            break;
          }
        }
        if (!inserted) {
          this.matterTrustLedgers.push(trustLedger);
        }
      } else if (selectedSoaFeIndex > -1) {
        this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
      } else {
        this.matterTrustLedgers.push(trustLedger);
      }

    }
  }

  addEMPToTrust() {
    // Sale  matter don't include EMP
    if (this.matter && this.isSaleOrSaleOpportunity) {
      return;
    }

    if (this.statementConfig && this.statementConfig.id > 0 && this.matter && this.matter.isAnyMortgageEMP && this.matter.mortgages.length > 0) {
      let anchorTrustLedgerItem: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.isEMP && item.trustLedgerConfigId != undefined);
      if (anchorTrustLedgerItem) {
        this.removeAnchorEMPFromTrust();
      }
      let trustLedgerItem: TrustLedgerMatter = _.find(this.matterTrustLedgers, tl => tl.isEMP);

      if (!trustLedgerItem) {
        // Update EMP mortagages as seperate lines
        // this code is depricated , using one line function addNewEmpToTrustLedger()

        //   this.matter.mortgages.filter(item => item.isEmpMortgage()).forEach( mortgage =>{
        //     if(!this.matterTrustLedgers.filter(item => item.isEMP).find(item => item.fromMortgageIndex ==
        // this.matter.getMortgageIndexById(mortgage.id) )) {
        //         this.addNewEmpTrustLedger(mortgage);
        //    }
        //});
        if (this.matter.getNumberofEMPMortgages() > 0) {
          this.addNewEmpToTrustLedger();
        }
      } else {
        // trustLedgerItem.itemValue = this.matter.getMortgageEMPCharges(true);
        trustLedgerItem.f9DefaultAmount = this.matter.getMortgageEMPCharges(true);
      }
    } else {
      this.removeAllEMPFromTrust();
    }
  }

  updateF9forTrustEmpMortgage(noSetItemValue?: boolean) {
    this.matter.mortgages.filter(item => item.isEmpMortgage()).forEach(mortgage => {
      let mortgageIndex = this.matter.mortgages.findIndex(mi => mi === mortgage);
      let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isMortgageFee && tl.fromMortgageIndex == mortgageIndex);
      if (matterTrustLedgerIndex > -1) {
        // if(this.matter.mortgages[mortgageIndex].itemizeMortgageAdvanceInTrustAdvance) {
        if (this.matterTrustLedgers[ matterTrustLedgerIndex ] && !this.matterTrustLedgers[ matterTrustLedgerIndex ].isItemized()) {
          this.matterTrustLedgers[ matterTrustLedgerIndex ].readOnly = false;
        }
        // }
        if (this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance == null) {
          this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance = this.matter.mortgages[ mortgageIndex ] &&
            this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction &&
            this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData &&
            this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance &&
            Number(this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance) !=
            this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.principalAmount;
        }
        if (this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance) {
          this.matterTrustLedgers[ matterTrustLedgerIndex ].f9DefaultAmount
            = Number(this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.netAdvance)
            ? Number(this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.netAdvance)
            : this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.principalAmount;
        } else {
          this.matterTrustLedgers[ matterTrustLedgerIndex ].f9DefaultAmount
            = this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.principalAmount;
        }

        if (!noSetItemValue) {
          if (this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance) {
            if (this.matterTrustLedgers[ matterTrustLedgerIndex ].itemizeTotal) {
              this.matterTrustLedgers[ matterTrustLedgerIndex ].itemValue
                = this.matterTrustLedgers[ matterTrustLedgerIndex ].itemizeTotal;
            } else if (this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.netAdvance) {
              this.matterTrustLedgers[ matterTrustLedgerIndex ].itemValue
                = Number(this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.netAdvance);
            } else {
              this.matterTrustLedgers[ matterTrustLedgerIndex ].itemValue
                = this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.principalAmount;

            }
          } else if (this.matterTrustLedgers[ matterTrustLedgerIndex ].isItemized()) {
            this.matterTrustLedgers[ matterTrustLedgerIndex ].itemValue
              = this.matterTrustLedgers[ matterTrustLedgerIndex ].itemizeTotal;
          } else {
            this.matterTrustLedgers[ matterTrustLedgerIndex ].itemValue
              = this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.principalAmount;
          }
        }
      }
    });
  }

  getEmpItemName(n): string {
    // return SoaConstants.PAID_ELECTRONIC_MORTGAGE_PRO_FEE + " for " + Utils.getOrdinal(n) + " Mortgage";
    return SoaConstants.PAID_ELECTRONIC_MORTGAGE_PRO_FEE;
  }

  updateF9ForTxnLevySurchargeItem() {
    let tlsItem: SoaMatter = _.find(this.disbursementsHST, soaMatter => soaMatter.isTransactionLevySurcharge());
    if (tlsItem) {
      if (this.matter && this.matter.isMatterTitleInsured()) {
        tlsItem.f9DefaultAmount = 0;
      } else {
        this.updateF9DefaultSoaDisbursement([ tlsItem ]);
      }
    }
  }

  updateF9ForSoaAndTrustEMPs() {
    if (this.statementConfig && this.statementConfig.id > 0 && this.statementConfig.isEMPForSoa()) {
      let purchaseDisbursement: SoaMatter = _.find(this.disbursementsHST, soaMatter => soaMatter.isEMP());
      if (purchaseDisbursement) {
        purchaseDisbursement.f9DefaultAmount = this.matter.getMortgageEMPCharges(false);

      }
    } else if (this.statementConfig && this.statementConfig.id > 0 && !this.statementConfig.isEMPForSoa()) {

      this.matter.mortgages.filter(item => item.isEmpMortgage()).forEach(mortgage => {
        let mortgageIndex = this.matter.mortgages.findIndex(mi => mi === mortgage);
        if (mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.mortgageFeesData && mortgage.stewartAssystMortgageInstruction.mortgageFeesData.fee) {
          let empCharges: number = mortgage.stewartAssystMortgageInstruction.mortgageFeesData.fee;
          let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isEMP && tl.fromMortgageIndex == mortgageIndex);
          if (matterTrustLedgerIndex > -1 && this.matterTrustLedgers.length > 0) {
            this.matterTrustLedgers[ matterTrustLedgerIndex ].f9DefaultAmount = empCharges;
          }
        }
      });

    }
  }

  addEMPToSOA() {

    // Sale  matter don't include EMP
    if (this.matter && this.isSaleOrSaleOpportunity) {
      return;
    }

    if (this.statementConfig && this.statementConfig.id > 0 && this.matter && this.matter.isAnyMortgageEMP && this.matter.mortgages.length > 0) {
      let purchaseDisbursement: SoaMatter = _.find(this.disbursementsHST, soaMatter => soaMatter.isEMP());
      if (!purchaseDisbursement) {
        let disbursementsConfigEMP: DisbursementConfig;

        if (this.statementConfig.statementOfAccount
          && this.statementConfig.statementOfAccount.disbursementsConfig) {
          disbursementsConfigEMP = this.statementConfig.statementOfAccount.disbursementsConfig.find(item => item.isEMP() && item.matterType == this.matter.matterType);
        }
        if (disbursementsConfigEMP) {

          let soaMatter = new SoaMatter();
          soaMatter.progressionStatus = this.soaProgressionStatus;
          //soaMatter.identifier = Number(soaMatter.identifier) + Number(i);
          soaMatter.itemName = disbursementsConfigEMP.nameOfDisbursement;
          if (this.statementConfig.isEMPForSoaAuto()) {
            soaMatter.itemValue = this.matter.getMortgageEMPCharges(false);
          } else {
            soaMatter.itemValue = 0;
          }
          soaMatter.disbursementConfigId = disbursementsConfigEMP.id;
          soaMatter.itemKey = disbursementsConfigEMP.code;
          soaMatter.itemType = 'DISBURSEMENT_SUBJECT_TO_HST';
          soaMatter.f9DefaultAmount = this.matter.getMortgageEMPCharges(false);
          soaMatter.readOnly = disbursementsConfigEMP.readOnly;
          soaMatter.accountCode = disbursementsConfigEMP.configAccountCode;
          let selectedIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isEMP());
          if (selectedIndex > -1) {
            this.disbursementsHST.splice(selectedIndex, 0, soaMatter);
          } else {
            this.disbursementsHST.push(soaMatter);
          }

        }
      } else {
        purchaseDisbursement.f9DefaultAmount = this.matter.getMortgageEMPCharges(false);
        if (this.statementConfig.isEMPForSoaAuto()) {
          purchaseDisbursement.itemValue = this.matter.getMortgageEMPCharges(false);
        }
      }

    } else {
      this.removeEMPFromSOA();
    }
    this.updateFeeBasedOnAllIncPrice();
  }

  removeAllEMPFromTrust() {
    _.remove(this.matterTrustLedgers, function (mtl: TrustLedgerMatter) {
      return mtl.isEMP;
    });

  }

  removeAnchorEMPFromTrust() {

    let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isEMP && tl.trustLedgerConfigId != undefined);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  removeEMPFromTrust(mortgageIndex: number) {

    let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isEMP && tl.fromMortgageIndex === mortgageIndex);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  removeEMPFromSOA() {

    _.remove(this.disbursementsHST, function (matterSoa: SoaMatter) {
      return matterSoa.isEMP();
    });
    this.updateFeeBasedOnAllIncPrice();
  }

  removeTeranetConnectChargesFromSOA() {
    _.remove(this.disbursementsHST, function (matterSoa: SoaMatter) {
      return matterSoa.isTeranetConnectCharges();
    });
    this.updateFeeBasedOnAllIncPrice();
  }

  addOrRemoveTitleInsuranceSoaAndTrustLedger(): void {
    if (this.matter && this.matter.isProjectSale) {
      return;
    }
    if (this.matter && this.matter.isMatterProvinceNS) {
      this.removeTitleInsuranceFromTrust();
      this.addTitleInsuranceToSOA();
    } else {
      if (this.matter && this.matter.isTransactionTitleInsured() && !this.matter.isTitlePlusInsuranceCancelledOrDeleted()) {
        if (this.statementConfig && this.statementConfig.isTitleInsuranceForSoa()) {
          this.addTitleInsuranceToSOA();
        } else if (this.statementConfig && !this.statementConfig.isTitleInsuranceForSoa()) {
          this.addTitleInsuranceToTrust();
        }
      } else {
        this.removeTitleInsuranceFromTrust();
        this.removeTitleInsuranceFromSOA();
      }
    }
  }

  //add/remove titleRegistrationFee && mortgageRegistrationFee
  //this method should be used when reset or new matter init
  //since the soa/tl item has been pre-populated in method ( createNewSoaStructure ) following the general rule
  //for Title Registration or Mortgage Registration, there is other rules need to consider
  //so we need to remove the existing item and recreate based on specific rules
  addOrRemoveTitleRegistrationSoaAndTrustLedger(): void {
    if (this.statementConfig && this.statementConfig.isTitleMortgageRegFeesForSoa()) {
      this.removeTitleRegistrationFromTrust();
      this.removeMortgageRegistrationFromTrust();
      this.removeTitleRegistrationFromSOA();//for reset or newMatter init
      this.removeMortgageRegistrationFromSOA();//for reset or newMatter init
      this.addOrUpdateTitleRegistrationToSOA();
      this.addOrUpdateMortgageRegistrationToSOA();
    } else if (this.statementConfig && !this.statementConfig.isTitleMortgageRegFeesForTrustLedger()) {
      this.removeTitleRegistrationFromSOA();
      this.removeMortgageRegistrationFromSOA();
      this.removeTitleRegistrationFromTrust();//for reset or newMatter init
      this.removeMortgageRegistrationFromTrust();//for reset or newMatter init
      this.updateTitleRegistrationToTrustForSkMb();
      this.updateMortgageRegistrationToTrustForSkMb();
    }
  }

  removeTitleInsuranceFromTrust() {
    let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isTitleInsurance);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  removeTitleInsuranceFromSOA() {
    _.remove(this.disbursementsNotHST, function (matterSoa: SoaMatter) {
      return matterSoa.isTitleInsurance();
    });
    this.updateFeeBasedOnAllIncPrice();
  }

  removeTitleRegistrationFromTrust() {
    let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isTitleRegistration);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  removeTitleRegistrationFromSOA() {
    _.remove(this.disbursementsNotHST, function (matterSoa: SoaMatter) {
      return matterSoa.isTitleRegistrationFee();
    });
  }

  removeMortgageRegistrationFromTrust() {
    let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isMortgageRegistration);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  removeMortgageRegistrationFromSOA() {
    _.remove(this.disbursementsNotHST, function (matterSoa: SoaMatter) {
      return matterSoa.isMortgageRegistrationFee();
    });
  }

  addOrUpdateTitleRegistrationToSOA(updateTitleRegistrationFeeItemValue?: boolean) {
    const titleRegFee: number = (this.matter.matterPropertyWithCondo && this.statementConfig && this.statementConfig.statementOfAccount && this.matter.matterPropertyWithCondo.titleRegistrationFee) ? this.matter.matterPropertyWithCondo.titleRegistrationFee : 0;
    let titleRegValue: number = titleRegFee;
    if (this.isPurchaseOrPurchaseOpportunity) {

      let disbursementsConfig: DisbursementConfig;
      if (this.statementConfig.statementOfAccount
        && this.statementConfig.statementOfAccount.disbursementsConfig) {
        disbursementsConfig = this.statementConfig.statementOfAccount.disbursementsConfig.find(item => item.isTitleRegistrationFee() && item.matterType == this.matter.matterType);

        if (this.statementConfig.isELRSAFeesTypeManual() && disbursementsConfig) {
          //the value will be the manually entered value in the admin config page
          titleRegValue = disbursementsConfig.f9DefaultAmount;
        }
      }
      let noHstDisbursement: SoaMatter = _.find(this.disbursementsNotHST, soaMatter => soaMatter.isTitleRegistrationFee());
      if (!noHstDisbursement) {
        if (disbursementsConfig) {

          let soaMatter = new SoaMatter();
          soaMatter.progressionStatus = this.soaProgressionStatus;
          ;
          //soaMatter.identifier = Number(soaMatter.identifier) + Number(i);
          soaMatter.itemName = disbursementsConfig.nameOfDisbursement;
          if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto()) {
            soaMatter.itemValue = titleRegValue;
          } else {
            soaMatter.itemValue = 0;
          }
          soaMatter.disbursementConfigId = disbursementsConfig.id;
          soaMatter.itemKey = disbursementsConfig.code;
          soaMatter.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
          soaMatter.f9DefaultAmount = titleRegValue;
          soaMatter.readOnly = disbursementsConfig.readOnly;
          soaMatter.accountCode = disbursementsConfig.configAccountCode;

          if (this.soaConfig && this.soaConfig.id > 0) {
            let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isTitleRegistrationFee());
            if (selectedSoaFeIndex > -1) {
              this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatter);
            } else {
              this.disbursementsNotHST.push(soaMatter);
            }
          }
          this.matter.dirty = true;
        }
      } else {
        noHstDisbursement.f9DefaultAmount = titleRegValue;
        //if value is configured to MANUAL_ENTRY, itemValue should not be updated apart of newMatter Init and Reset
        if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !this.statementConfig.isELRSAFeesTypeManual() && !updateTitleRegistrationFeeItemValue) {
          noHstDisbursement.itemValue = titleRegValue;
        }
      }
    }
  }

  addOrUpdateMortgageRegistrationToSOA(updateMortgageRegistrationFeeItemValue?: boolean) {
    if ((this.isPurchaseOrPurchaseOpportunity || this.isMortgageOrMortgageOpportunity) && this.matter.isMatterProvinceSK) {
      const mortgageRegFee: number = (this.matter.matterPropertyWithCondo && this.statementConfig && this.statementConfig.statementOfAccount && this.matter.matterPropertyWithCondo.mortgageRegistrationFee) ? this.matter.matterPropertyWithCondo.mortgageRegistrationFee : 0;
      let mortgageRegValue: number = mortgageRegFee;
      let noHstDisbursement: SoaMatter = _.find(this.disbursementsNotHST, soaMatter => soaMatter.isMortgageRegistrationFee());
      let disbursementsConfig: DisbursementConfig;

      if (this.statementConfig && this.statementConfig.statementOfAccount && Array.isArray(this.statementConfig.statementOfAccount.disbursementsConfig)) {
        disbursementsConfig = this.statementConfig.statementOfAccount.disbursementsConfig.find(item => item.isMortgageRegistrationFee() && item.matterType == this.matter.matterType);
        if (this.statementConfig.isELRSAFeesTypeManual() && disbursementsConfig) {
          //the value will be the manually entered value in the admin config page
          mortgageRegValue = disbursementsConfig.f9DefaultAmount;
        }
      }
      if (!noHstDisbursement) {
        if (disbursementsConfig) {
          let soaMatter = new SoaMatter();
          soaMatter.progressionStatus = this.soaProgressionStatus;
          //soaMatter.identifier = Number(soaMatter.identifier) + Number(i);
          soaMatter.itemName = disbursementsConfig.nameOfDisbursement;
          if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto()) {
            soaMatter.itemValue = mortgageRegValue;
          } else {
            soaMatter.itemValue = 0;
          }
          soaMatter.disbursementConfigId = disbursementsConfig.id;
          soaMatter.itemKey = disbursementsConfig.code;
          soaMatter.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
          soaMatter.f9DefaultAmount = mortgageRegValue;
          soaMatter.readOnly = disbursementsConfig.readOnly;
          soaMatter.accountCode = disbursementsConfig.configAccountCode;

          if (this.soaConfig && this.soaConfig.id > 0) {
            let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isMortgageRegistrationFee());
            if (selectedSoaFeIndex > -1) {
              this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatter);
            } else {
              this.disbursementsNotHST.push(soaMatter);
            }
          }
          this.matter.dirty = true;
        }
      } else {
        noHstDisbursement.f9DefaultAmount = mortgageRegValue;
        //if value is configured to MANUAL_ENTRY, itemValue should not be updated apart of newMatter Init and Reset
        if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !this.statementConfig.isELRSAFeesTypeManual() && !updateMortgageRegistrationFeeItemValue) {
          noHstDisbursement.itemValue = mortgageRegValue;
        }
      }
    }
  }

  addTitleInsuranceToSOA() {

    // Sale  matter don't include TITLE INSURANCE
    if (this.matter && this.isSaleOrSaleOpportunity) {
      return;
    }

    if (this.statementConfig && this.statementConfig.id > 0 && this.matter && (this.matter.isTransactionTitleInsured() || this.matter.isMatterProvinceNS)) {

      let disbursementsConfig: DisbursementConfig;
      if (this.statementConfig.statementOfAccount
        && this.statementConfig.statementOfAccount.disbursementsConfig) {
        disbursementsConfig = this.statementConfig.statementOfAccount.disbursementsConfig.find(item => item.isTitleInsurance()
          && (item.matterType == this.matter.matterType || (this.matter.isOpportunityMatter && item.matterType == this.matter.customMatterTypeName)));
      }
      const insurancePremiumValue: number = this.matter.isMatterProvinceNS ? (disbursementsConfig ? disbursementsConfig.f9DefaultAmount : 0) : this.matter.getTitleInsuranceCharges();
      let insurancePremiumDisbursement: SoaMatter = _.find(this.disbursementsNotHST, soaMatter => soaMatter.isTitleInsurance());

      if (!insurancePremiumDisbursement) {
        if (disbursementsConfig) {
          let soaMatter = new SoaMatter();
          soaMatter.progressionStatus = this.soaProgressionStatus;
          //soaMatter.identifier = Number(soaMatter.identifier) + Number(i);
          soaMatter.itemName = disbursementsConfig.nameOfDisbursement;
          if (this.statementConfig.isTitleInsuranceForSoaAuto()) {
            //NA for NS matter
            soaMatter.itemValue = insurancePremiumValue;
          } else {
            soaMatter.itemValue = 0;
          }
          soaMatter.disbursementConfigId = disbursementsConfig.id;
          soaMatter.itemKey = disbursementsConfig.code;
          soaMatter.itemType = 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
          soaMatter.f9DefaultAmount = insurancePremiumValue;
          soaMatter.readOnly = disbursementsConfig.readOnly;
          soaMatter.accountCode = disbursementsConfig.configAccountCode;

          if (this.soaConfig && this.soaConfig.id > 0) {
            let selectedSoaFeIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isTitleInsurance());
            if (selectedSoaFeIndex > -1) {
              this.addPurchaseDisbursementsNotHST(selectedSoaFeIndex, soaMatter);
            } else {
              this.disbursementsNotHST.push(soaMatter);
            }
          }
          this.matter.dirty = true;
        }
      } else {
        insurancePremiumDisbursement.f9DefaultAmount = insurancePremiumValue;
        if (this.statementConfig.isTitleInsuranceForSoaAuto()) {
          //NA for NS matter
          if (insurancePremiumDisbursement.itemValue != insurancePremiumValue) {
            this.matter.dirty = true;
          }
          insurancePremiumDisbursement.itemValue = insurancePremiumValue;
        }
      }
    } else {
      this.removeTitleInsuranceFromSOA();
    }
    this.updateFeeBasedOnAllIncPrice();
  }

  updateTitleRegistrationToTrustForSkMb() {
    if (this.matter) {
      if (this.matter.isMatterProvinceSK) {
        this.updateF9SaskTitleRegTrustLedger();
      } else if (this.matter.isMatterProvinceMB) {
        this.updateF9TitleRegistrationFeeTrustLedgerMB();
      }
    }

  }

  updateMortgageRegistrationToTrustForSkMb() {
    if (this.matter) {
      if (this.matter.isMatterProvinceSK) {
        this.updateF9SaskMortgageRegTrustLedger();
      } else if (this.matter.isMatterProvinceMB) {
        this.updateF9MortgageRegistrationFeeTrustLedgerMB();
      }
    }
  }

  addTitleInsuranceToTrust() {
    // Sale  matter don't include TITLE INSURANCE
    if (this.matter && this.isSaleOrSaleOpportunity) {
      return;
    }

    if (this.statementConfig && this.statementConfig.id > 0 && this.matter && this.matter.isTransactionTitleInsured()) {

      let trustLedgerItem: TrustLedgerMatter = _.find(this.matterTrustLedgers, tl => tl.isTitleInsurance);

      if (!trustLedgerItem) {
        this.addNewTitleInsuranceToTrustLedger();
      } else {
        const titleInsuranceCharges: number = this.matter.getTitleInsuranceCharges();
        if (trustLedgerItem.isItemized()) {
          if (trustLedgerItem.itemizedMatterTrustLedgers[ 0 ].itemAmount != titleInsuranceCharges) {
            trustLedgerItem.itemizedMatterTrustLedgers[ 0 ].itemAmount = titleInsuranceCharges;
            trustLedgerItem.itemValue = trustLedgerItem.itemizeTotal;
            this.matter.dirty = true;
          }
        } else {
          if (this.matter.autoInsertAllF9Values) {
            if (trustLedgerItem.itemValue != titleInsuranceCharges) {
              trustLedgerItem.itemValue = titleInsuranceCharges;
              this.matter.dirty = true;
            }
          }
        }
        trustLedgerItem.f9DefaultAmount = titleInsuranceCharges;
        trustLedgerItem.itemName = SoaConstants.PAID_INSURANCE_PREMIUM;
        if (this.matter.insurer) {
          trustLedgerItem.itemName += ' - ' + this.matter.insurer;
        }
      }
    } else {
      this.removeTitleInsuranceFromTrust();
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  addNewTitleInsuranceToTrustLedger() {
    if (this.matter) {
      const trustLedgerConfigList = this.trustLedger.getTrustLedgerList(this.matter);
      const selectedSoaFeIndex = trustLedgerConfigList && trustLedgerConfigList.findIndex(tl => tl.isTitleInsurance());

      let trustLedger: TrustLedgerMatter = new TrustLedgerMatter();
      trustLedger.progressionStatus = this.tlProgressionStatus;

      trustLedger.statementConfigurationId = this.statementConfiguration.id;
      trustLedger.itemKey = SoaTrustLedgerConfigKeys.L1_TI;
      if (this.matter.insurer) {
        trustLedger.itemName = SoaConstants.PAID_INSURANCE_PREMIUM + ' - ' + this.matter.insurer;
      } else {
        trustLedger.itemName = SoaConstants.PAID_INSURANCE_PREMIUM;
      }
      trustLedger.itemValue = 0;
      trustLedger.f9DefaultAmount = 0;
      trustLedger.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
      trustLedger.fromMortgageIndex = 0;
      if (this.statementConfig) {
        let charges: number = this.matter.getTitleInsuranceCharges();
        if (this.statementConfig.isTitleInsuranceForTrustLedgerAuto() || this.matter.autoInsertAllF9Values) {
          trustLedger.itemValue = charges;
          trustLedger.f9DefaultAmount = charges;
        } else {
          trustLedger.itemValue = 0;
          trustLedger.f9DefaultAmount = charges;
        }

      }

      trustLedger.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;

      if (selectedSoaFeIndex > -1) {
        this.insertTrustLedgerRowAsPerConfigOrder(trustLedger, this.trustLedger.getTrustLedgerList(this.matter).slice(selectedSoaFeIndex + 1));
      } else {
        this.matterTrustLedgers.push(trustLedger);
      }
      this.matter.dirty = true;
    }

  }

  updateF9ForSoaAndTrustTitleInsurance() {

    if (this.statementConfig && this.statementConfig.id > 0 && this.statementConfig.isTitleInsuranceForSoa()) {
      let insurancePremiumDisbursement: SoaMatter = _.find(this.disbursementsNotHST, soaMatter => soaMatter.isTitleInsurance());

      if (insurancePremiumDisbursement) {
        if (this.matter.isMatterProvinceNS) {
          //for NS, amountOfTitleInsurancePremium always set to 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE'
          const insurancePremiumConfig: DisbursementConfig = this.statementConfig.disbursementsConfigs
          .find(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && disb.matterType === (this.isPurchaseOrPurchaseOpportunity ? 'PURCHASE' : 'MORTGAGE'));
          insurancePremiumDisbursement.f9DefaultAmount = insurancePremiumConfig ? insurancePremiumConfig.f9DefaultAmount : 0;
        } else if (this.matter.matterTitleInsurance && (this.matter.matterTitleInsurance.policyTotal || this.matter.matterTitleInsurance.fctPremium || this.matter.matterTitleInsurance.grandTotal)) {
          insurancePremiumDisbursement.f9DefaultAmount = this.matter.getTitleInsuranceCharges();
        }
      }
    } else if (this.statementConfig && this.statementConfig.id > 0 && !this.statementConfig.isTitleInsuranceForSoa()) {
      //NA for NS matter
      if (this.matter.matterTitleInsurance && (this.matter.matterTitleInsurance.policyTotal || this.matter.matterTitleInsurance.fctPremium || this.matter.matterTitleInsurance.grandTotal)) {
        let insuranceCharges: number = this.matter.getTitleInsuranceCharges();
        let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, tl => tl.isTitleInsurance);
        if (matterTrustLedgerIndex > -1 && this.matterTrustLedgers.length > 0) {
          this.matterTrustLedgers[ matterTrustLedgerIndex ].f9DefaultAmount = insuranceCharges;
        }
      }
    }
  }

  updateFeeLabel(soaMatter: SoaMatter, soaFee: SoaFee) {
    if (soaFee.isFeeForSaleTxn()) {
      soaMatter.itemName = SoaConstants.FEE_SALE_TRANSACTION_OUR;
      soaMatter.readOnly = true;
    }
    if (soaFee.isFeeForPurchaseTxn()) {
      soaMatter.itemName = SoaConstants.FEE_PURCHASE_TRANSACTION_OUR;
      soaMatter.readOnly = true;
    }
    if (soaFee.isFeeForMortgageTxn()) {
      soaMatter.itemName = SoaConstants.LABEL_ADDITIONAL_MORTGAGE_OUR;
      soaMatter.fromMortgageIndex = -1;
      soaMatter.readOnly = true;
    }
    if (soaFee.isFeeForDischargeTxn()) {
      soaMatter.itemName = SoaConstants.FEE_DISCHARGE_TRANSACTION_OUR;
      soaMatter.fromMortgageIndex = -1;
      soaMatter.readOnly = true;
    }
    if (soaFee.isFeeForMortgage()) {
      soaMatter.itemName = SoaConstants.FEE_ARRANGED_MORTGAGE_OUR.replace('*', Utils.getOrdinal(soaMatter.fromMortgageIndex + 1));
      soaMatter.readOnly = true;
    }
    if (soaFee.isFeeForAdditionalMortgage()) {
      //How to deal if fromMortgageIndex is -1. Now just add check
      if (soaMatter.fromMortgageIndex > 0 && Array.isArray(this.matter.mortgages) && soaMatter.fromMortgageIndex < this.matter.mortgages.length) {
        soaMatter.itemName = SoaConstants.FEE_ADDITIONAL_MORTGAGE_OUR.replace('*', Utils.getOrdinal(this.matter.mortgages[ soaMatter.fromMortgageIndex ].mortgagePriority));
        soaMatter.readOnly = true;
      }
    }
  }

  //determine whether the Soa Fee item shows My Fee or Our Fee - 23477
  isMyFee(): boolean {
    return this.documentProfile
      && this.documentProfile.firmDocumentProfile
      && (this.documentProfile.firmDocumentProfile.solePractitionerTypeCode == 'YES_MALE'
        || this.documentProfile.firmDocumentProfile.solePractitionerTypeCode == 'YES_FEMALE');
  }

  updateFeeLabelBasedOnDefaultProfile() {
    if (this.isMyFee()) {

      this.fees.forEach(soaMatter => {
        if (soaMatter.isMortgage()) {
          soaMatter.itemName = SoaConstants.FEE_ARRANGED_MORTGAGE_MY.replace('*', Utils.getOrdinal(soaMatter.fromMortgageIndex + 1));
        }

        if (soaMatter.isAdditionalMortgage()) {
          //How to deal if fromMortgageIndex is -1. Now just add check
          if (soaMatter.fromMortgageIndex > 0 && Array.isArray(this.matter.mortgages) && soaMatter.fromMortgageIndex < this.matter.mortgages.length) {
            soaMatter.itemName = SoaConstants.FEE_ARRANGED_MORTGAGE_MY.replace('*', Utils.getOrdinal(this.matter.mortgages[ soaMatter.fromMortgageIndex ].mortgagePriority));
          }
        }

      });

      let soaMatterTransactionFee: SoaMatter = this.fees.find(soaMatter => soaMatter.isTransactionFees(this.matterType, this.matterProvinceCode));
      if (soaMatterTransactionFee) {
        soaMatterTransactionFee.itemName = this.getTxnFeeItemName();
        /*                if (this.matterType == 'PURCHASE') {
                                    soaMatterTransactionFee.itemName = SoaConstants.FEE_PURCHASE_TRANSACTION_MY;
                                }
                                if (this.matterType == 'SALE') {
                                    soaMatterTransactionFee.itemName = SoaConstants.FEE_SALE_TRANSACTION_MY;
                                }
                                if (this.matterType == 'MORTGAGE') {
                                    soaMatterTransactionFee.itemName = SoaConstants.LABEL_ADDITIONAL_MORTGAGE_MY;
                                }*/
      }
    } else {

      this.fees.forEach(soaMatter => {
        if (soaMatter.isMortgage()) {
          soaMatter.itemName = SoaConstants.FEE_ARRANGED_MORTGAGE_OUR.replace('*', Utils.getOrdinal(soaMatter.fromMortgageIndex + 1));
        }

        if (soaMatter.isAdditionalMortgage()) {
          //How to deal if fromMortgageIndex is -1. Now just add check
          if (soaMatter.fromMortgageIndex > 0 && Array.isArray(this.matter.mortgages) && soaMatter.fromMortgageIndex < this.matter.mortgages.length) {
            soaMatter.itemName = SoaConstants.FEE_ADDITIONAL_MORTGAGE_OUR.replace('*', Utils.getOrdinal(this.matter.mortgages[ soaMatter.fromMortgageIndex ].mortgagePriority));
          }
        }
      });

      let soaMatterTransactionFee: SoaMatter = this.fees.find(soaMatter => soaMatter.isTransactionFees(this.matterType, this.matterProvinceCode));
      if (soaMatterTransactionFee && !soaMatterTransactionFee.isParalegal()) {
        soaMatterTransactionFee.itemName = this.getTxnFeeItemName();
        /*                if (this.matterType == 'PURCHASE') {
                                    soaMatterTransactionFee.itemName = SoaConstants.FEE_PURCHASE_TRANSACTION_OUR;
                                }
                                if (this.matterType == 'SALE') {
                                    soaMatterTransactionFee.itemName = SoaConstants.FEE_SALE_TRANSACTION_OUR;
                                }
                                if (this.matterType == 'MORTGAGE') {
                                    soaMatterTransactionFee.itemName = SoaConstants.LABEL_ADDITIONAL_MORTGAGE_OUR;
                                }*/
      }
    }

  }

  getTxnFeeItemName(): string {
    if (this.matterType == 'PURCHASE') {
      return this.isMyFee() ? SoaConstants.FEE_PURCHASE_TRANSACTION_MY : SoaConstants.FEE_PURCHASE_TRANSACTION_OUR;
    }
    if (this.matterType == 'SALE') {
      return this.isMyFee() ? SoaConstants.FEE_SALE_TRANSACTION_MY : SoaConstants.FEE_SALE_TRANSACTION_OUR;
    }
    if (this.matterType == 'MORTGAGE') {
      return this.isMyFee() ? SoaConstants.LABEL_ADDITIONAL_MORTGAGE_MY : SoaConstants.LABEL_ADDITIONAL_MORTGAGE_OUR;
    }
    if (this.matterType == 'DISCHARGE') {
      return this.isMyFee() ? SoaConstants.FEE_DISCHARGE_TRANSACTION_MY : SoaConstants.FEE_DISCHARGE_TRANSACTION_OUR;
    }
    return '';
  }

  getTxnFeeItemKey(): string {
    switch (this.matterType) {
      case 'PURCHASE' :
        return AccountingItemKeys[ AccountingItemKeys.FEE_PURCHASE_TRANSACTION_KEY ];
      case 'SALE' :
        return AccountingItemKeys[ AccountingItemKeys.FEE_SALE_TRANSACTION_KEY ];
      case 'MORTGAGE' :
        return AccountingItemKeys[ AccountingItemKeys.FEE_MORTGAGE_TRANSACTION_KEY ];
    }
    return '';
  }

  updateF9Values(): void {
    for (let i = 0; i < this.fees.length; i++) {
      if (this.fees[ i ] && this.fees[ i ].f9DefaultAmount && this.fees[ i ].f9DefaultAmount > 0) {
        this.fees[ i ].itemValue = this.fees[ i ].f9DefaultAmount;
      }

    }
    for (let i = 0; i < this.disbursementsAdditional.length; i++) {
      if (this.disbursementsAdditional[ i ] && this.disbursementsAdditional[ i ].f9DefaultAmount && this.disbursementsAdditional[ i ].f9DefaultAmount > 0) {
        this.disbursementsAdditional[ i ].itemValue = this.disbursementsAdditional[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.disbursementsHST.length; i++) {
      if (this.disbursementsHST[ i ] && this.disbursementsHST[ i ].f9DefaultAmount && this.disbursementsHST[ i ].f9DefaultAmount > 0) {
        this.disbursementsHST[ i ].itemValue = this.disbursementsHST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.disbursementsNotHST.length; i++) {
      if (this.disbursementsNotHST[ i ] && this.disbursementsNotHST[ i ].f9DefaultAmount && this.disbursementsNotHST[ i ].f9DefaultAmount > 0) {
        this.disbursementsNotHST[ i ].itemValue = this.disbursementsNotHST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.disbursementsPST.length; i++) {
      if (this.disbursementsPST[ i ] && this.disbursementsPST[ i ].f9DefaultAmount && this.disbursementsPST[ i ].f9DefaultAmount > 0) {
        this.disbursementsPST[ i ].itemValue = this.disbursementsPST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.disbursementsGSTandPST.length; i++) {
      if (this.disbursementsGSTandPST[ i ] && this.disbursementsGSTandPST[ i ].f9DefaultAmount && this.disbursementsGSTandPST[ i ].f9DefaultAmount > 0) {
        this.disbursementsGSTandPST[ i ].itemValue = this.disbursementsGSTandPST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.taxableOtherChargesGST.length; i++) {
      if (this.taxableOtherChargesGST[ i ]?.f9DefaultAmount > 0) {
        this.taxableOtherChargesGST[ i ].itemValue = this.taxableOtherChargesGST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.taxableOtherChargesPST.length; i++) {
      if (this.taxableOtherChargesPST[ i ]?.f9DefaultAmount > 0) {
        this.taxableOtherChargesPST[ i ].itemValue = this.taxableOtherChargesPST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.taxableOtherChargesGSTandPST.length; i++) {
      if (this.taxableOtherChargesGSTandPST[ i ]?.f9DefaultAmount > 0) {
        this.taxableOtherChargesGSTandPST[ i ].itemValue = this.taxableOtherChargesGSTandPST[ i ].f9DefaultAmount;
      }
    }
    for (let i = 0; i < this.nonTaxableOtherCharges.length; i++) {
      if (this.nonTaxableOtherCharges[ i ]?.f9DefaultAmount > 0) {
        this.nonTaxableOtherCharges[ i ].itemValue = this.nonTaxableOtherCharges[ i ].f9DefaultAmount;
      }
    }
    this.updateUserOverriddenFlagOnSOA();
  }

  updateF9TrustLedgerValues(): void {
    for (let i = 0; i < this.matterTrustLedgers.length; i++) {
      if (this.matterTrustLedgers[ i ] && !this.matterTrustLedgers[ i ].readOnly && this.matterTrustLedgers[ i ].f9DefaultAmount && this.matterTrustLedgers[ i ].f9DefaultAmount != 0
        && !this.matterTrustLedgers[ i ].isPaidToYou && !this.matterTrustLedgers[ i ].isReceivedFromYou) {
        this.matterTrustLedgers[ i ].itemValue = this.matterTrustLedgers[ i ].f9DefaultAmount;

      }
    }
    this.updateF9ForPaidToYouAndReceivedFromYou();
    // Update Paid to you or Received for you f9 after insert all f9 are done
    for (let i = 0; i < this.matterTrustLedgers.length; i++) {
      if ((this.matterTrustLedgers[ i ].isPaidToYou || this.matterTrustLedgers[ i ].isReceivedFromYou) && this.matterTrustLedgers[ i ].f9DefaultAmount > 0) {
        this.matterTrustLedgers[ i ].itemValue = this.matterTrustLedgers[ i ].f9DefaultAmount;
      }
    }
    this.updateF9ForTrustLedgerOfExistingDischargedMtg(true);
    this.updateUserOverriddenFlagOnTL();
  }

  insertUpdateF9TrustLedgerValues(): void {
    for (let i = 0; i < this.matterTrustLedgers.length; i++) {
      if (this.matterTrustLedgers[ i ].itemKey) {
        if (this.matterTrustLedgers[ i ].isItemNameAlwaysEditable) {
          this.matterTrustLedgers[ i ].f9ReadOnly = false;
        } else {
          this.matterTrustLedgers[ i ].f9ReadOnly = this.matter.autoInsertAllF9Values;
        }
      }
      if (this.matterTrustLedgers[ i ] && !this.matterTrustLedgers[ i ].readOnly && this.matterTrustLedgers[ i ].f9DefaultAmount != null &&
        !this.matterTrustLedgers[ i ].isPaidToYou && !this.matterTrustLedgers[ i ].isReceivedFromYou) {
        if (this.matter.autoInsertAllF9Values) {
          this.matterTrustLedgers[ i ].itemValue = this.matterTrustLedgers[ i ].f9DefaultAmount;
        }
      }
    }
    if (this.matter && this.matter.autoInsertAllF9Values) {
      this.updateF9ForPaidToYouAndReceivedFromYou();
    }

    if (this.matter && this.matter.isMatterProvinceABorMBorSK && this.matter.hasExistingMortgageChargedAndPayoutEqualsTrustLedgerBalance()) {
      //in case RF itemValue and F9DefaultAmount are different after previous calculation, PY is 0 for itemValue and F9
      let rf: TrustLedgerMatter = this.getMatterTrustLedgerRF();
      rf.itemValue = rf.f9DefaultAmount;
    }
  }

  updateF9TrustLedger(): void {
    for (let i = 0; i < this.matterTrustLedgers.length; i++) {
      if (this.matterTrustLedgers[ i ].itemKey) {
        if (this.matterTrustLedgers[ i ].isItemNameAlwaysEditable) {
          this.matterTrustLedgers[ i ].f9ReadOnly = false;
        } else {
          this.matterTrustLedgers[ i ].f9ReadOnly = this.matter.autoInsertAllF9Values;
        }
      }
      if (this.matterTrustLedgers[ i ] && !this.matterTrustLedgers[ i ].readOnly && this.matterTrustLedgers[ i ].f9DefaultAmount != null &&
        !this.matterTrustLedgers[ i ].isPaidToYou && !this.matterTrustLedgers[ i ].isReceivedFromYou) {
        if (this.matter.autoInsertAllF9Values) {
          this.matterTrustLedgers[ i ].itemValue = this.matterTrustLedgers[ i ].f9DefaultAmount;
        }
      }
    }
  }

  updateF9DefaultAmount(): void {
    this.updateF9DefaultSoa();
    this.updateF9DefaultGeneral();
    this.updateF9DefaultTrustLedger();
    this.updateF9DefaultAmountForCompliances();
  }

  updateF9DefaultGeneral(): void {

    if (this.soaConfigListForLTT) {
      let matterSoaOT: SoaMatter = this.soaConfigListForLTT.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_OT);
      if (matterSoaOT && this.matter && this.matter.considerationLtt) {
        matterSoaOT.f9DefaultAmount = Number(this.matter.considerationLtt.totalOntarioTorontoLttPayable);
      }
      let matterSoaON: SoaMatter = this.soaConfigListForLTT.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_O);
      if (matterSoaON && this.matter && this.matter.considerationLtt) {
        matterSoaON.f9DefaultAmount = Number(this.matter.considerationLtt.landTransferTaxValueOnt);
      }
      let matterSoaTR: SoaMatter = this.soaConfigListForLTT.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L2_T);
      if (matterSoaTR && this.matter && this.matter.considerationLtt) {
        matterSoaTR.f9DefaultAmount = Number(this.matter.considerationLtt.landTransferTaxValueTor);
      }
    }
  }

  updateF9DefaultSoa(): void {
    if (this.soaConfig && this.soaConfig.id > 0) {
      for (let i = 0; i < this.fees.length; i++) {
        let soaFee: SoaFee = this.soaConfig.fees.find(soaFee => soaFee.code == this.fees[ i ].itemKey);
        if (soaFee && soaFee.f9DefaultAmount && soaFee.f9DefaultAmount > 0) {
          this.fees[ i ].f9DefaultAmount = soaFee.f9DefaultAmount;
          this.fees[ i ].itemName = soaFee.nameOfFees;
        }
      }

      this.updateF9DefaultSoaDisbursement(this.disbursementsAdditional);
      this.updateF9DefaultSoaDisbursement(this.disbursementsHST);
      this.updateF9DefaultSoaDisbursement(this.disbursementsNotHST);
      this.updateF9DefaultSoaDisbursement(this.disbursementsPST);
      this.updateF9DefaultSoaDisbursement(this.disbursementsGSTandPST);
      this.updateF9DefaultSoaDisbursement(this.taxableOtherChargesGST);
      this.updateF9DefaultSoaDisbursement(this.taxableOtherChargesPST);
      this.updateF9DefaultSoaDisbursement(this.taxableOtherChargesGSTandPST);
      this.updateF9DefaultSoaDisbursement(this.nonTaxableOtherCharges);

      this.updateF9DefaultSoaProvinceSpecific();
    }
  }

  updateF9DefaultSoaDisbursement(disbList: SoaMatter[]): void {
    if (Array.isArray(disbList)) {
      for (let i = 0; i < disbList.length; i++) {
        if (disbList[ i ].isSoftwareTransactionCharge()) {
          if (this.isSoaInterimMode) {
            disbList[ i ].f9DefaultAmount = 0;
          } else {
            disbList[ i ].f9DefaultAmount = this.matter && this.matter.matterPrice ? Number(this.matter.matterPrice) : 0;
          }
        } else if (disbList[ i ].isTeranetConnectCharges()) {
          if (this.matter && this.matter.isTeranetConnectChargesApplied()) {
            //In case of the applied charges are different from the configuration amount.
            disbList[ i ].f9DefaultAmount = this.matter.getTeranetConnectChargesForSOA();
          } else {
            disbList[ i ].f9DefaultAmount = 0;
          }

        } else {
          let disbConfig: DisbursementConfig = this.matter && this.soaConfig && this.soaConfig.disbursementsConfig ? this.soaConfig.disbursementsConfig
          .find((soaPurchase: DisbursementConfig) => soaPurchase.code == disbList[ i ].itemKey) : null;

          if (disbConfig && disbConfig.f9DefaultAmount && disbConfig.f9DefaultAmount > 0) {
            disbList[ i ].f9DefaultAmount = disbConfig.f9DefaultAmount;
          }
        }
      }
    }
  }

  getF9DefaultAmountForRegistrationDischargeOfOther(eRegChargeAmount: number = 0): number {
    if (this.matter && (this.matter.isMatterProvinceONOrAB || this.matter.isMatterProvinceNBorNS || this.matter.isMatterProvinceMB)) {
      if (this.isSoaInterimMode) {
        return 0;
      } else {
        let saleDischargedInstruments: number = 0;
        if (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceMB) {
          saleDischargedInstruments = this.matter ? this.matter.getDischargedInstrumentsForFinanceCavet() : 0;
        } else if (this.matter.isMatterProvinceON) {
          saleDischargedInstruments = this.matter ? this.matter.getDischargedInstrumentsByFinanceTypeOther() : 0;
        } else {
          saleDischargedInstruments = this.matter ? this.matter.getDischargedInstrumentsForFinanceCavetAndRegisteredByOurFirm() : 0;
        }

        let dischargeCaveat: SoaFeeRate;
        if (this.soaFeesRates) {
          //dischargeCaveat = this.soaFeesRates.find(item => item.isRegistrationDischargeOfOther());
          dischargeCaveat = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY);
        }
        if (this.matter.isMatterProvinceON) {
          if (dischargeCaveat) {
            return Number((saleDischargedInstruments * (dischargeCaveat.fees + eRegChargeAmount)).toFixed(2));
          }
        } else {
          if (dischargeCaveat) {
            return Number((saleDischargedInstruments * (dischargeCaveat.fees + (this.matter.numberOfParcels - 1) * dischargeCaveat.extraParcelFactor)).toFixed(2));
          }
        }

      }
    }
    return 0;
  }

  getF9DefaultAmountForLienCaveat(isRegisterVendorsLienCaveat: boolean) {
    if (this.matter.isMatterProvinceAB) {
      if (this.isSoaInterimMode) {
        return 0;
      } else {
        let soaFeeRate: SoaFeeRate;
        let vendorsLienCaveatUndertakings: MatterUndertaking[] = [];
        if (this.matter.matterUndertakings && this.matter.matterUndertakings.length > 0) {
          vendorsLienCaveatUndertakings = this.matter.matterUndertakings.filter(underTaking => underTaking.typeOfEncumbrance === undertakingTypes.vendorsLienCaveat.key);
        }
        if (this.soaFeesRates) {
          soaFeeRate = isRegisterVendorsLienCaveat ? this.soaFeesRates.find(item => item.isRegisterVendorsLienCaveat()) : this.soaFeesRates.find(item => item.isDischargeVendorsLienCaveat());
        }
        if (soaFeeRate) {
          return Number((vendorsLienCaveatUndertakings.length * (soaFeeRate.fees + (this.matter.numberOfParcels - 1) * soaFeeRate.extraParcelFactor)).toFixed(2));
        }
      }
    }
    return 0;
  }

  // For each mortgage (Value$+ (mortgage's PrincipalAmount * Sale Price Factor) + ( (#ofParcel-1) * Extra Parcel factor) )
  getF9DefaultValueForRegistrationOfMortgageForAlberta(): number {
    let f9Amount: number = 0;
    if (this.matter.isMatterProvinceAB) {
      if (this.soaFeesRates) {
        let regOfMrtg: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationOfMortgage());
        if (regOfMrtg) {
          this.matter.mortgages
          .filter(mrtg => this.updateF9AmountBasedOnLoanTypes(mrtg))
          .forEach(mrtg => {
            let mortgageTermPrincipal: number = mrtg.mortgageTerm ? Utils.ceiling(mrtg.mortgageTerm.principal, 5000) : 0;
            f9Amount += Number(regOfMrtg.fees)
              + Number(mortgageTermPrincipal * regOfMrtg.salePriceFactor)
              + Number((this.matter.numberOfParcels - 1) * regOfMrtg.extraParcelFactor);
          });
        }
      }
    }
    return f9Amount;
  }

  updateF9DefaultSoaProvinceSpecific() {
    // in Alberta some disbursements are calculated based on sale price, sale price factor and parcel(s) factor
    if (this.matter) {
      if (this.matter.isMatterProvinceAB) {
        this.disbursementsNotHST.forEach(soaMatter => {

          if (soaMatter.isRegistrationOfMortgage() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            if (this.soaFeesRates) {
              //let regOfMrtg: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationOfMortgage());
              let regOfMrtg: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY);
              if (regOfMrtg) {
                soaMatter.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfMortgageForAlberta();
              }
            }
          }
          if (soaMatter.isRegistrationTransfer() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            soaMatter.readOnly = false;
            let registrationTransferRate: SoaFeeRate;
            if (this.soaFeesRates) {
              //registrationTransferRate = this.soaFeesRates.find(item => item.isRegistrationTransfer());
              registrationTransferRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
            }

            if (registrationTransferRate && !this.matter.isOpportunityMatter()) {
              soaMatter.f9DefaultAmount = this.getF9DefaultAmntRegTransfAB(registrationTransferRate);
            }
          }
          if (soaMatter.isRegistrationOfMortgageDischarge() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            if (this.isSoaInterimMode) {
              soaMatter.f9DefaultAmount = 0;
            } else {
              //#ofDischarge * (Value$ + ( (#ofParcel-1) * Extra Parcel factor))
              let saleDischargedInstruments: number = this.matter ? this.matter.getDischargedInstrumentsForFinanceMortgage() : 0;
              let mrtgDischarge: SoaFeeRate;
              if (this.soaFeesRates) {
                //mrtgDischarge = this.soaFeesRates.find(item => item.isRegistrationOfMortgageDischarge());
                mrtgDischarge = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY);
              }
              if (mrtgDischarge) {
                soaMatter.f9DefaultAmount = Number((saleDischargedInstruments * (mrtgDischarge.fees + (this.matter.numberOfParcels - 1) * mrtgDischarge.extraParcelFactor)).toFixed(2));
              }
            }
          }
          if (soaMatter.isRegistrationDischargeOfOther() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            soaMatter.f9DefaultAmount = this.getF9DefaultAmountForRegistrationDischargeOfOther();
          }

          if (this.matter && this.isSaleOrSaleOpportunity &&
            (this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && (soaMatter.isRegisterVendorsLienCaveat() || soaMatter.isDischargeVendorsLienCaveat()))) {
            soaMatter.f9DefaultAmount = this.getF9DefaultAmountForLienCaveat(soaMatter.isRegisterVendorsLienCaveat());
          }
        });
      }

      if (this.matter.isMatterProvinceON) {
        const soaPurchase = this.soaConfig.getMLTT();
        let mlttSoa: SoaMatter = this.disbursementsHST.find(soaMatter => soaMatter.isMLTTConfiguration());
        if (mlttSoa) {
          mlttSoa.f9DefaultAmount = this.matter.isMatterProvinceON ? this.getSoaFeeRateOfMLTT() : (soaPurchase ? soaPurchase.f9DefaultAmount : 0);
        }

        if (this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
          this.disbursementsHST.forEach(soaMatter => {
            if (soaMatter.isERegServiceCharge()) {
              let eRegCharges: SoaFeeRate;
              if (this.soaFeesRates) {
                //eRegCharges = this.soaFeesRates.find(item => item.isERegServiceCharge());
                eRegCharges = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY);
              }
              if (eRegCharges) {
                soaMatter.f9DefaultAmount = this.getF9DefaultValueForEReg();
              }
            }
          });
          this.disbursementsNotHST.forEach(soaMatter => {
            if (soaMatter.isRegistrationDischargeOfOther()) {
              soaMatter.f9DefaultAmount = this.getF9DefaultAmountForRegistrationDischargeOfOther();
            }
            if (soaMatter.isRegistrationOfMortgageDischarge()) {
              soaMatter.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfMortgageDischarge();
            }
            if (soaMatter.isRegistrationTransfer()) {
              let defaultRegTransfer: SoaFeeRate;
              if (this.soaFeesRates) {
                //defaultRegTransfer = this.soaFeesRates.find(item => item.isRegistrationTransfer());
                defaultRegTransfer = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
              }
              if (defaultRegTransfer) {
                soaMatter.f9DefaultAmount = this.getF9DefaultValueForRegistrationTransfer();
              }
            }
            if (soaMatter.isRegistrationOfCharge()) {
              let defaultRegCharges: SoaFeeRate;
              if (this.soaFeesRates) {
                //defaultRegCharges = this.soaFeesRates.find(item => item.isRegistrationCharge());
                defaultRegCharges = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
              }
              if (defaultRegCharges) {
                soaMatter.f9DefaultAmount = this.getF9DefaultValueForRegistrationCharge();
              }
            }
          });
        }
      }

      if (this.matter.isMatterProvinceMB) {
        this.disbursementsHST.forEach(soaMatter => {
          if (soaMatter.isLandTitleSearches() && Array.isArray(this.soaFeesRates)) {
            //let soaFeeRate: SoaFeeRate = this.soaFeesRates.find(item => item.isLandTitleSearches());
            let soaFeeRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.LAND_TITLE_SEARCHES);
            if (soaFeeRate) {
              soaMatter.f9DefaultAmount = soaFeeRate.fees;
            }
          }
        });

        this.disbursementsNotHST.forEach(soaMatter => {
          //the f9 DefaultAmount is not affected by the number of Mortgages
          if (soaMatter.isRegistrationTransfer() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            soaMatter.readOnly = false;
            if (Array.isArray(this.soaFeesRates)) {
              //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationTransfer());
              const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
              if (configuredRate) {
                soaMatter.f9DefaultAmount = configuredRate.fees;
              }
            }
          }

          if (soaMatter.isRegistrationOfMortgage() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            //For the province of Manitoba: Registration of Mortgage amount will be retrieved from the amount of the Registration of Charge item.
            soaMatter.readOnly = false;
            if (Array.isArray(this.soaFeesRates)) {
              //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationCharge());
              const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
              if (configuredRate) {
                soaMatter.f9DefaultAmount = Number(configuredRate.fees) * Number(this.matter.getRegisteredInstruments());
              }
            }
          }

          if (soaMatter.isRegistrationOfMortgageDischarge() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            soaMatter.readOnly = false;
            if (Array.isArray(this.soaFeesRates)) {
              //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationOfMortgageDischarge());
              const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY);
              if (configuredRate) {
                soaMatter.f9DefaultAmount = Number(configuredRate.fees) * Number(this.matter.getDischargedInstrumentsForFinanceMortgage());
              }
            }
          }

          if (soaMatter.isRegistrationDischargeOfOther() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            //The amount will be retrieved from the amount of the Register Discharge of other Encumbrances item.
            soaMatter.readOnly = false;
            if (Array.isArray(this.soaFeesRates)) {
              //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationDischargeOfOther());
              const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY);
              if (configuredRate) {
                soaMatter.f9DefaultAmount = Number(configuredRate.fees) * Number(this.matter.getDischargedInstrumentsForFinanceCavet());
              }
            }
          }
        });
      }

      if (this.matter.isMatterProvinceSK) {
        this.disbursementsHST.forEach(soaMatter => {
          if (soaMatter.isTitleSearch()) {
            if (Array.isArray(this.soaFeesRates)) {
              //let soaFeeRate: SoaFeeRate = this.soaFeesRates.find(item => item.isTitleSearch());
              let soaFeeRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.TITLE_SEARCH);
              if (soaFeeRate) {
                soaMatter.f9DefaultAmount = soaFeeRate.fees;
              }
            }
          }
        });
      }

      if (this.matter.isMatterProvinceNBorNS) {

        this.disbursementsNotHST.forEach(soaMatter => {
          if (soaMatter.isRegistrationDischargeOfOther() && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
            soaMatter.f9DefaultAmount = this.getF9DefaultAmountForRegistrationDischargeOfOther();
          }
        });
      }
    }

  }

  updateF9AmountBasedOnLoanTypes(mortgage: Mortgage): boolean {
    if (this.matter.matterType === 'PURCHASE') {
      return mortgage.isLoanTypeArranged() || mortgage.isLoanTypeBackToVendor();
    } else if (this.matter.matterType === 'MORTGAGE') {
      return mortgage.isUnityNewMortgage() || mortgage.isEmpMortgage();
    }
    return false;
  }

  updateF9DefaultTrustLedger(): void {
    for (let i = 0; i < this.matterTrustLedgers.length; i++) {
      let trustLedgerConfig: TrustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.code === this.matterTrustLedgers[ i ].itemKey);
      if (trustLedgerConfig && trustLedgerConfig.f9DefaultAmount && trustLedgerConfig.f9DefaultAmount > 0
        && !trustLedgerConfig.isMortgage() && !trustLedgerConfig.isPreviousMortgage()) {
        this.matterTrustLedgers[ i ].f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
      }
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
    this.updateF9DefaultTrustLedgerProvinceSpecific();
  }

  updateF9DefaultTrustLedgerON(): void {
    if (this.matter && this.matter.isMatterProvinceON) {
      this.matterTrustLedgers.forEach(mtl => {

        if (this.statementConfiguration && !this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && Array.isArray(this.statementConfiguration.trustLedgerConfigs)) {
          //need to reset the value based on manual enter configuration value
          let trustLedgerConfig: TrustLedgerConfig = undefined;
          if (this.isPurchaseOrPurchaseOpportunity) {
            //'REGISTRATION_OF_TRANSFER_KEY', 'REGISTRATION_OF_CHARGE_KEY'
            if (mtl.isRegistrationTransfer()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isRegistrationOfTransfer());
            } else if (mtl.isRegistrationOfCharge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isRegistrationOfCharge());
            }
          } else if (this.isSaleOrSaleOpportunity) {
            //'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_VTB_MORTGAGE_KEY'
            if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegistrationOfVTBMortgage()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfVtbMortgage());
            }
          } else if (this.isMortgageOrMortgageOpportunity) {
            //'REGISTRATION_OF_CHARGE_KEY', 'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY'
            if (mtl.isRegistrationOfCharge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfCharge());
            } else if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfMortgageDischarge());
            }
          }
          //set the f9DefaultAmount based on the manual entered value for the corresponding configuration item
          if (trustLedgerConfig) {
            mtl.f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
          }
        }
      });
    }
  }

  updateF9DefaultTrustLedgerAB(): void {
    if (this.matter && this.matter.isMatterProvinceAB) {
      this.matterTrustLedgers.forEach(mtl => {

        if (this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
          if (mtl.isRegistrationDischargeOfOther()) {
            mtl.f9DefaultAmount = this.getF9DefaultAmountForRegistrationDischargeOfOther();
          } else if (this.matter && this.isSaleOrSaleOpportunity && (mtl.isRegisterVendorsLienCaveat() || mtl.isDischargeVendorsLienCaveat())) {
            mtl.f9DefaultAmount = this.getF9DefaultAmountForLienCaveat(mtl.isRegisterVendorsLienCaveat());
          }
        } else if (!this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && Array.isArray(this.statementConfiguration.trustLedgerConfigs)) {
          //need to reset the value based on manual enter configuration value
          let trustLedgerConfig: TrustLedgerConfig = undefined;
          if (this.isPurchaseOrPurchaseOpportunity) {
            //'REGISTRATION_OF_TRANSFER_KEY', 'REGISTRATION_OF_MORTGAGE_KEY'
            if (mtl.isRegistrationTransfer()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isRegistrationOfTransfer());
            } else if (mtl.isRegistrationOfMortgage()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isRegistrationOfMortgage());
            }
          } else if (this.isSaleOrSaleOpportunity) {
            //'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY', 'DISCHARGE_VENDOR_LIEN_CAVEAT_KEY', 'REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY'
            if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegisterVendorsLienCaveat()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfVendorLienCaveat());
            } else if (mtl.isDischargeVendorsLienCaveat()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isDischargeVendorLienCaveat());
            } else if (mtl.isRegistrationDischargeOfOther()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfDischargeOfOther());
            }
          } else if (this.isMortgageOrMortgageOpportunity) {
            //'REGISTRATION_OF_MORTGAGE_KEY', 'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY'
            if (mtl.isRegistrationOfMortgage()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfMortgage());
            } else if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegistrationDischargeOfOther()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfDischargeOfOther());
            }
          }
          //set the f9DefaultAmount based on the manual entered value for the corresponding configuration item
          if (trustLedgerConfig) {
            mtl.f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
          }
        }
      });
    }
  }

  updateF9DefaultTrustLedgerMB(): void {
    if (this.matter && this.matter.isMatterProvinceMB) {
      this.matterTrustLedgers.forEach(mtl => {

        if (!this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && Array.isArray(this.statementConfiguration.trustLedgerConfigs)) {
          //need to reset the value based on manual enter configuration value
          let trustLedgerConfig: TrustLedgerConfig = undefined;
          if (this.isPurchaseOrPurchaseOpportunity) {
            //'PAID_TITLE_REGISTRATION_FEE', 'PAID_MORTGAGE_REGISTRATION_FEE'
            if (mtl.isPaidTitleRegistrationFee()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isPaidTitleRegistrationFee());
            } else if (mtl.isPaidMortgageRegistrationFee()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isPaidMortgageRegistrationFee());
            }
          } else if (this.isSaleOrSaleOpportunity) {
            //'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY'
            if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegistrationDischargeOfOther()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfDischargeOfOther());
            }
          } else if (this.isMortgageOrMortgageOpportunity) {
            //'PAID_MORTGAGE_REGISTRATION_FEE', 'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY'
            if (mtl.isPaidMortgageRegistrationFee()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isPaidMortgageRegistrationFee());
            } else if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegistrationDischargeOfOther()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfDischargeOfOther());
            }
          }
          //set the f9DefaultAmount based on the manual entered value for the corresponding configuration item
          if (trustLedgerConfig) {
            mtl.f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
          }
        }
      });
    }
  }

  updateF9DefaultTrustLedgerSK(): void {
    if (this.matter && this.matter.isMatterProvinceSK) {
      this.matterTrustLedgers.forEach(mtl => {

        if (!this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && Array.isArray(this.statementConfiguration.trustLedgerConfigs)) {
          //need to reset the value based on manual enter configuration value
          let trustLedgerConfig: TrustLedgerConfig = undefined;
          if (this.isPurchaseOrPurchaseOpportunity) {
            //'PAID_TITLE_REGISTRATION_FEE', 'PAID_MORTGAGE_REGISTRATION_FEE
            if (mtl.isPaidTitleRegistrationFee()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isPaidTitleRegistrationFee());
            } else if (mtl.isPaidMortgageRegistrationFee()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isPaidMortgageRegistrationFee());
            }
          } else if (this.isMortgageOrMortgageOpportunity) {
            //'PAID_MORTGAGE_REGISTRATION_FEE'
            if (mtl.isPaidMortgageRegistrationFee()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isPaidMortgageRegistrationFee());
            }
          }
          //set the f9DefaultAmount based on the manual entered value for the corresponding configuration item
          if (trustLedgerConfig) {
            mtl.f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
          }
        }
      });
    }
  }

  updateF9DefaultTrustLedgerNBorNS(): void {
    if (this.matter && this.matter.isMatterProvinceNBorNS) {
      this.matterTrustLedgers.forEach(mtl => {

        if (this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {
          if (mtl.isRegistrationDischargeOfOther()) {
            mtl.f9DefaultAmount = this.getF9DefaultAmountForRegistrationDischargeOfOther();
          }
        } else if (!this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && Array.isArray(this.statementConfiguration.trustLedgerConfigs)) {
          //need to reset the value based on manual enter configuration value
          let trustLedgerConfig: TrustLedgerConfig = undefined;
          if (this.isPurchaseOrPurchaseOpportunity) {
            //'REGISTRATION_OF_CHARGE_KEY', 'REGISTRATION_OF_TRANSFER_KEY'
            if (mtl.isRegistrationOfCharge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isRegistrationOfCharge());
            } else if (mtl.isRegistrationTransfer()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'PURCHASE' && config.isRegistrationOfTransfer());
            }
          } else if (this.isSaleOrSaleOpportunity) {
            //'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY', 'REGISTRATION_OF_VTB_MORTGAGE_KEY'
            if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegistrationDischargeOfOther()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfDischargeOfOther());
            } else if (mtl.isRegistrationOfVTBMortgage()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs.find(config => config.matterType == 'SALE' && config.isRegistrationOfVtbMortgage());
            }
          } else if (this.isMortgageOrMortgageOpportunity) {
            //'REGISTRATION_OF_CHARGE_KEY', 'REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY', 'REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY'
            if (mtl.isRegistrationOfCharge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfCharge());
            } else if (mtl.isRegistrationOfMortgageDischarge()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfMortgageDischarge());
            } else if (mtl.isRegistrationDischargeOfOther()) {
              trustLedgerConfig = this.statementConfiguration.trustLedgerConfigs
              .find(config => config.matterType == (!!this.matter.isMatterTypeDischarge ? 'DISCHARGE' : 'MORTGAGE') && config.isRegistrationOfDischargeOfOther());
            }
          }
          //set the f9DefaultAmount based on the manual entered value for the corresponding configuration item
          if (trustLedgerConfig) {
            mtl.f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
          }
        }
      });
    }
  }

  updateF9DefaultTrustLedgerProvinceSpecific() {
    this.updateF9DefaultTrustLedgerON();
    this.updateF9DefaultTrustLedgerAB();
    this.updateF9DefaultTrustLedgerMB();
    this.updateF9DefaultTrustLedgerSK();
    this.updateF9DefaultTrustLedgerNBorNS();

  }

  calculateFeesTotalExceptTransactionFee(): number {
    let sum: number = 0;
    this.fees.forEach((fee) => {
      if (!fee.isTransactionFees(this.matterType, this.matterProvinceCode, this.matter.customMatterTypeName)) {
        sum = sum + Number(fee.itemValue);
      }
    });
    return sum;
  }

  resetFee(): void {
    if (this.soaConfig.soaSelectedTemplate && !this.soaConfig.soaSelectedTemplate.feeCalculatedOnInclusivePriceSelection) {
      let soaMatterTransactionFee: SoaMatter = this.fees
      .find(soaMatter => soaMatter.isTransactionFees(this.matterType, this.matterProvinceCode, this.matter.customMatterTypeName));
      if (soaMatterTransactionFee) {
        soaMatterTransactionFee.itemValue = 0;
      }
    }
  }

  updateFeeBasedOnAllIncPrice(): void {

    if (this.matter && this.feeCalculatedOnInclusivePriceSelection) {
      let taxRate: number = this.matter.isMatterProvinceBCorMBorSK ?
        Number(this.soAccountProvincialTax) + Number(this.soAccountFederalTax) :
        Number(this.soAccountHst);
      let taxMultiplier: number = 1 + taxRate / 100;

      let gstRate: number = this.matter.isMatterProvinceBCorMBorSK ?
        Number(this.soAccountFederalTax) :
        Number(this.soAccountHst);
      let gstTaxMultiplier: number = 1 + gstRate / 100;

      let pstTaxRate: number = this.matter.isMatterProvinceBCorMBorSK ?
        Number(this.soAccountProvincialTax) :
        0;
      //let pstTaxMultiplier: number = (pstTaxRate > 0) ? 1 + pstTaxRate / 100 : 0;
      let pstTaxMultiplier: number = 1 + pstTaxRate / 100;
      let feeValue = ((Number(this.soaFeesCalculatedOnAllInclusivePriceValue) -
        (
          Utils.roundCurrencyExcelStyle(this.totalDisbursementsAdditional * taxMultiplier) +
          Utils.roundCurrencyExcelStyle(this.calculateFeesTotalExceptTransactionFee() * taxMultiplier) +
          Utils.roundCurrencyExcelStyle(this.totalDisbursementsSubjectToTax * gstTaxMultiplier) +
          Utils.roundCurrencyExcelStyle(this.totalDisbursementsSubjectToPSTTax * pstTaxMultiplier) +
          Number(this.totalDisbursementsSubjectToGSTandPSTTax) +
          Number(Utils.roundCurrencyExcelStyle(this.totalDisbursementsSubjectToGSTandPSTTax * gstRate) / 100) +
          Number(Utils.roundCurrencyExcelStyle(this.totalDisbursementsSubjectToGSTandPSTTax * pstTaxRate) / 100) +
          Number(this.totalDisbursementsNotSubjectToTax) +
          Number(this.totalTaxableOtherChargesGST) +
          Number(Utils.roundCurrencyExcelStyle(this.totalTaxableOtherChargesGST * gstRate) / 100) +
          Number(this.totalTaxableOtherChargesPST) +
          Number(Utils.roundCurrencyExcelStyle(this.totalTaxableOtherChargesPST * pstTaxRate) / 100) +
          Number(this.totalTaxableOtherChargesGSTandPST) +
          Number(Utils.roundCurrencyExcelStyle(this.totalTaxableOtherChargesGSTandPST * gstRate) / 100) +
          Number(Utils.roundCurrencyExcelStyle(this.totalTaxableOtherChargesGSTandPST * pstTaxRate) / 100) +
          Number(this.totalNonTaxableOtherCharges)
        )));
      feeValue = Utils.roundCurrencyExcelStyle(feeValue / taxMultiplier);
      let soaMatterTransactionFee: SoaMatter = this.fees.find(soaMatter => soaMatter.isTransactionFees(this.matterType, this.matterProvinceCode, this.matter.customMatterTypeName));
      if (soaMatterTransactionFee) {
        soaMatterTransactionFee.itemValue = feeValue;
      }
    }
  }

  createNewTrustLedgerStructure(): void {
    let trustLedgerList = this.trustLedger.getTrustLedgerList(this.matter);
    if (trustLedgerList) {
      for (let i = 0; i < trustLedgerList.length; i++) {
        let trustLedgerConfig = new TrustLedgerConfig(trustLedgerList[ i ]);
        if (!trustLedgerConfig.isMortgage() && !trustLedgerConfig.isNrst() && !trustLedgerConfig.isPreviousMortgage()) {

          let trustLedgerMatter = new TrustLedgerMatter();
          trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
          //trustLedgerMatter.identifier = Number(trustLedgerMatter.identifier) + Number(i);
          trustLedgerMatter.itemName = trustLedgerList[ i ].expenseDescription;
          if (trustLedgerConfig.isOntarioLTT()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.statementConfigurationId = this.statementConfig.id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_O;
          } else if (trustLedgerConfig.isLegalFee()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_LF;
          } else if (trustLedgerConfig.isReceivedFromYou()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_RF;
          } else if (trustLedgerConfig.isReceivedFromPurchaserOnClosing()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_RP;
            if (this.matter && this.matter.isMatterProvinceAB && this.matter.isProjectSale) {
              trustLedgerMatter.itemName = 'Cash to Close ' + SoaConstants.AS_PER_SOA;
            }

          } else if (trustLedgerConfig.isPaidToYou()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_PY;
          } else if (trustLedgerConfig.isPaidToVendor()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_PV;
          } else if (trustLedgerConfig.isPreviousMortgage()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_PM;
          } else if (trustLedgerConfig.isReceivedFromPurchaserOnClosing()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_RP;
          } else if (trustLedgerConfig.isPaidToRealEstateBroker()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_PB;
          } else if (trustLedgerConfig.isEmp()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_EF;
          } else if (trustLedgerConfig.isTitleInsurance()) {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_TI;
          } else {
            trustLedgerMatter.trustLedgerConfigId = trustLedgerList[ i ].id;
            trustLedgerMatter.itemKey = trustLedgerList[ i ].code;
          }
          trustLedgerMatter.itemValue = 0;
          trustLedgerMatter.itemType = trustLedgerList[ i ].expenseType;
          trustLedgerMatter.f9DefaultAmount = trustLedgerList[ i ].f9DefaultAmount;
          this.matterTrustLedgers.push(trustLedgerMatter);
        }

      }
    }
  }

  canAddReceivedFromMortgageeRow(mortgage: Mortgage): boolean {
    return this.matter && this.matter.isProjectSale && this.matter.isMatterProvinceAB && this.matter.isActingForMortgagee && mortgage.isLoanTypeNew();
  }

  updateItemizePrincipalForEmp() {
    if (this.matter) {
      for (let i = 0; i < this.matter.mortgages.length; i++) {
        let existingMatterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == i);
        if (this.matter.mortgages[ i ].isEmpMortgage() && existingMatterTrustLedger && existingMatterTrustLedger.isItemized() && this.matter.autoInsertAllF9Values) {
          existingMatterTrustLedger.itemizedMatterTrustLedgers[ 0 ].itemAmount = this.matter.mortgages[ i ].mortgageTerm.principal;
        }
      }
    }
  }

  addTrustLedgerMortgageRowForAllMortgages() {
    if (this.matter) {
      for (let i = 0; i < this.matter.mortgages.length; i++) {
        let existingMatterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == i);
        if (!existingMatterTrustLedger) {
          this.addTrustLedgerMortgageRow(i, this.matter.mortgages[ i ].isLoanTypeBridge(), this.matter.mortgages[ i ].isLoanTypeArranged(), this.canAddReceivedFromMortgageeRow(this.matter.mortgages[ i ]));
        } else if (this.matter.isProjectSale && this.matter.isMatterProvinceAB
          && (!this.matter.isActingForMortgagee || !this.matter.mortgages[ i ].isLoanTypeNew())) {
          this.removeMortgageFromTrustLedger(i);
        }
      }
      // For existing mortgage
      for (let i = 0; i < this.matter.existingMortgages.length; i++) {
        let existingMatterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM && mtl.fromMortgageIndex == i);
        if (!existingMatterTrustLedger) {
          if (this.matter && this.isSaleOrSaleOpportunity) {
            this.addTrustLedgerExistingMortgageRow(i, this.matter.existingMortgages[ i ].isMortgageDispositionTypeBridgeFinancing(),
              this.matter.existingMortgages[ i ].isMortgageDispositionTypeDischarged(),
              this.matter.existingMortgages[ i ]);
          }

          if (this.isMortgageOrMortgageOpportunity) {
            this.addTrustLedgerExistingMortgageRow(i, false, this.matter.existingMortgages[ i ].isMortgageDispositionTypeDischarged(),
              this.matter.existingMortgages[ i ]);
          }
        }
      }
      console.log('###TrustLedger', this.matterTrustLedgers);
    }
  }

  addTrustLedgerMortgageRow(mortgageIndex: number, isBridgeFinance: boolean, isArranged: boolean, canAddReceivedFromMortgageeRow?: boolean): void {
    let matterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedger && (isBridgeFinance || isArranged || canAddReceivedFromMortgageeRow)) {
      if (isArranged || canAddReceivedFromMortgageeRow) {
        if (this.isMortgageOrMortgageOpportunity) {
          matterTrustLedger.itemName
            = SoaConstants.RECEIVED_NEW_ARRANGED_FINANCING_M_GE.replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)));

        } else {
          if (canAddReceivedFromMortgageeRow) {
            matterTrustLedger.itemName = SoaConstants.RECEIVED_FROM_PURCHASER_MORTGAGE
            .replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)));
          } else {
            matterTrustLedger.itemName = SoaConstants.RECEIVED_ARRANGED_FINANCING_M_GE.replace('###', Utils.getOrdinal(mortgageIndex + 1));
          }

        }
      } else if (isBridgeFinance) {
        if (this.isPurchaseOrPurchaseOpportunity) {
          matterTrustLedger.itemName = SoaConstants.RECEIVED_BRIDGE_FINANCING;
        } else if (this.matter && this.isSaleOrSaleOpportunity) {
          matterTrustLedger.itemName = SoaConstants.PAID_TO_BRIDGE_FINANCING;
        }
      }

    } else if (isBridgeFinance || isArranged || canAddReceivedFromMortgageeRow) {
      let trustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isMortgage());
      if (trustLedgerConfig || canAddReceivedFromMortgageeRow) {
        let trustLedgerMatter = new TrustLedgerMatter();
        trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
        //trustLedgerMatter.identifier = Number(trustLedgerMatter.identifier) + Number(this.matterTrustLedgers.length+1);
        if (isArranged || canAddReceivedFromMortgageeRow) {
          if (this.isMortgageOrMortgageOpportunity) {
            trustLedgerMatter.itemName = SoaConstants.RECEIVED_NEW_ARRANGED_FINANCING_M_GE.replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)) + '');
          } else {
            if (canAddReceivedFromMortgageeRow) {
              trustLedgerMatter.itemName = SoaConstants.RECEIVED_FROM_PURCHASER_MORTGAGE
              .replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)));
            } else {
              trustLedgerMatter.itemName = SoaConstants.RECEIVED_ARRANGED_FINANCING_M_GE.replace('###', Utils.getOrdinal(mortgageIndex + 1));
            }

          }
        } else if (isBridgeFinance) {
          if (this.isPurchaseOrPurchaseOpportunity) {
            trustLedgerMatter.itemName = SoaConstants.RECEIVED_BRIDGE_FINANCING;
          } else if (this.matter && this.isSaleOrSaleOpportunity) {
            trustLedgerMatter.itemName = SoaConstants.PAID_TO_BRIDGE_FINANCING;
          }
        } else {
          trustLedgerMatter.itemName = trustLedgerConfig.expenseDescription;
        }
        trustLedgerMatter.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
        trustLedgerMatter.trustLedgerConfigId = canAddReceivedFromMortgageeRow ? null : trustLedgerConfig.id;
        trustLedgerMatter.itemValue = 0;
        trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_MG;
        trustLedgerMatter.itemType = canAddReceivedFromMortgageeRow ? 'Receipt' : trustLedgerConfig.expenseType;
        trustLedgerMatter.f9DefaultAmount = canAddReceivedFromMortgageeRow ? 0 : trustLedgerConfig.f9DefaultAmount;
        trustLedgerMatter.fromMortgageIndex = mortgageIndex;

        if (mortgageIndex >= 0 && Array.isArray(this.matter.mortgages)
          && mortgageIndex < this.matter.mortgages.length
          && this.matter.mortgages[ mortgageIndex ].isEmpMortgage()) {
          if (this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance == null) {
            this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance = this.matter.mortgages[ mortgageIndex ] &&
              this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction &&
              this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData &&
              this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance &&
              Number(this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance) !=
              this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.principalAmount;
          }

          if (this.matter.mortgages[ mortgageIndex ].itemizeMortgageAdvanceInTrustAdvance) {
            trustLedgerMatter.itemizedMatterTrustLedgers = [];
            this.addEmpItemizedMatterTrustLedgers(this.matter.mortgages[ mortgageIndex ], trustLedgerMatter);
          } else {
            trustLedgerMatter.itemValue
              = this.matter.mortgages[ mortgageIndex ].stewartAssystMortgageInstruction.mortgageInstructionData.principalAmount;
            trustLedgerMatter.f9DefaultAmount = trustLedgerMatter.itemValue;
          }

        }

        if (this.matterTrustLedgers.length == 0) {
          this.matterTrustLedgers.unshift(trustLedgerMatter);
        } else {
          if (this.canAddReceivedFromMortgageeRow(this.matter.mortgages[ mortgageIndex ])) {
            let matterTrustLedgerRPIndex = this.matterTrustLedgers.findIndex(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RP);
            if (matterTrustLedgerRPIndex != -1) {
              this.matterTrustLedgers.splice(matterTrustLedgerRPIndex + 1, 0, trustLedgerMatter);
            } else {
              this.matterTrustLedgers.unshift(trustLedgerMatter);
            }
          } else {
            let configIndex: number = this.trustLedger.getTrustLedgerList(this.matter).findIndex(t => t.isMortgage());
            this.insertTrustLedgerRowAsPerConfigOrder(trustLedgerMatter, this.trustLedger.getTrustLedgerList(this.matter).slice(configIndex + 1));
          }

        }

        if (this.isMortgageOrMortgageOpportunity) {
          this.matter.sortTrustLedgerMatterByPriority();
        }

        if (this.isPurchaseOrPurchaseOpportunity || canAddReceivedFromMortgageeRow) {
          this.matter.sortTrustLedgerMatterByMortgageIndex(SoaTrustLedgerConfigKeys.L1_MG);
        }
        if (Array.isArray(this.matter.mortgages)
          && mortgageIndex < this.matter.mortgages.length && !this.matter.mortgages[ mortgageIndex ].isEmpMortgage()) {
          this.updateTrustLedgeMortgageValues(this.matter.getMortgageIndexById(this.matter.mortgages[ mortgageIndex ].id), this.matter.mortgages[ mortgageIndex ].mortgageTerm, this.matter.mortgages[ mortgageIndex ]);
        }
      }
    } else if (matterTrustLedger) {
      this.removeMortgageFromTrustLedger(mortgageIndex);
    }
    this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.matter.mortgages[ mortgageIndex ]);
  }

  addTrustLedgerHoldbacksRows(): void {
    this.addTrustLedgerHoldbacksRowsSingleView();
    if (this.matter && this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.addTrustLedgerHoldbacksRowsSingleView();
      this.progressionStatus = progressStatus;
    }
  }

  addTrustLedgerHoldbacksRowsSingleView(): void {
    if (this.matter) {
      let advanceHoldbacksTrustLedgers: TrustLedgerMatter[] = [];
      let otherHoldbacksTrustLedgers: TrustLedgerMatter[] = [];

      let trustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isConstructionMortgageHoldbacks());
      if (trustLedgerConfig) {
        advanceHoldbacksTrustLedgers = this.addAdvanceHoldbacksRows();
      }
      trustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isOtherHoldbacks());
      if (trustLedgerConfig) {
        otherHoldbacksTrustLedgers = this.addOtherHoldbacksRows();
      }

      let existingAdvanceHoldBackIndex = this.matterTrustLedgers.findIndex(tl => tl.itemKey == SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK);
      let existingOtherHoldBackIndex = this.matterTrustLedgers.findIndex(tl => tl.itemKey == SoaTrustLedgerConfigKeys.L1_OTHER_HOLDBACK);

      if (existingAdvanceHoldBackIndex != -1 && existingOtherHoldBackIndex != -1) {
        /*
                 * If Both Holdback types exists,
                 * Remove and insert the one with the highest index first
                 */
        if (existingAdvanceHoldBackIndex > existingOtherHoldBackIndex) {
          this.removeHoldbacksTLRows(SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK);
          this.insertHoldBackRowsIntoTL(advanceHoldbacksTrustLedgers, existingAdvanceHoldBackIndex);
          this.removeHoldbacksTLRows(SoaTrustLedgerConfigKeys.L1_OTHER_HOLDBACK);
          this.insertHoldBackRowsIntoTL(otherHoldbacksTrustLedgers, existingOtherHoldBackIndex);
        } else {
          this.removeHoldbacksTLRows(SoaTrustLedgerConfigKeys.L1_OTHER_HOLDBACK);
          this.insertHoldBackRowsIntoTL(otherHoldbacksTrustLedgers, existingOtherHoldBackIndex);
          this.removeHoldbacksTLRows(SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK);
          this.insertHoldBackRowsIntoTL(advanceHoldbacksTrustLedgers, existingAdvanceHoldBackIndex);
        }

      } else {
        let position = -1;
        if (existingAdvanceHoldBackIndex != -1 && existingOtherHoldBackIndex == -1 || existingAdvanceHoldBackIndex == -1 && existingOtherHoldBackIndex != -1) {
          //If only one type exists, then we insert all in the same position
          position = existingAdvanceHoldBackIndex != -1 ? existingAdvanceHoldBackIndex : existingOtherHoldBackIndex;
        }
        this.removeHoldbacksTLRows(SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK);
        this.removeHoldbacksTLRows(SoaTrustLedgerConfigKeys.L1_OTHER_HOLDBACK);
        this.insertHoldBackRowsIntoTL([ ...advanceHoldbacksTrustLedgers, ...otherHoldbacksTrustLedgers ], position);
      }
    }
  }

  addAdvanceHoldbacksRows(): TrustLedgerMatter[] {
    let advanceHoldbacksTrustLedgers: TrustLedgerMatter[] = [];
    let advanceHoldbacks = this.matter.holdbacks.filter(hb => hb.isAdvanceHoldback());
    if (advanceHoldbacks && advanceHoldbacks.length) {
      let composedHoldbacks = _.groupBy(advanceHoldbacks, hb => hb.getHoldbackMortgagePriority(this.matter));
      for (let priority in composedHoldbacks) {
        let holdbacks: MatterHoldback[] = composedHoldbacks[ priority ];
        let total = 0;
        holdbacks.forEach((hb) => {
          total += hb.holdbackAmount;
        });
        let advanceHoldbackTL;
        let existingTL = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK && mtl.linkKey == priority);
        if (existingTL) {
          advanceHoldbackTL = new TrustLedgerMatter(existingTL);
          if (!existingTL.isItemized()) {
            advanceHoldbackTL.f9DefaultAmount = total;
            advanceHoldbackTL.itemValue = this.matter && this.matter.autoInsertAllF9Values ? advanceHoldbackTL.f9DefaultAmount : advanceHoldbackTL.itemValue;
          }
        } else {
          advanceHoldbackTL = this.addTrustLedgerHoldbackRow(SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK, priority, total);
        }

        if (advanceHoldbackTL) {
          advanceHoldbacksTrustLedgers.push(advanceHoldbackTL);
        }
      }
    }
    return advanceHoldbacksTrustLedgers;
  }

  addOtherHoldbacksRows(): TrustLedgerMatter[] {
    let otherHoldbacksTrustLedgers: TrustLedgerMatter[] = [];
    let otherHoldbacks: MatterHoldback[] = this.matter.holdbacks.filter(hb => hb.isOtherHoldback());
    if (otherHoldbacks && otherHoldbacks.length) {
      otherHoldbacks.forEach((holdback) => {
        let otherHoldbackTL;
        let existingTL = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_OTHER_HOLDBACK && mtl.linkKey == String(holdback.id));
        if (existingTL) {
          otherHoldbackTL = new TrustLedgerMatter(existingTL);
          if (!existingTL.isItemized()) {
            otherHoldbackTL.f9DefaultAmount = holdback.holdbackAmount;
            otherHoldbackTL.itemValue = this.matter && this.matter.autoInsertAllF9Values ? otherHoldbackTL.f9DefaultAmount : otherHoldbackTL.itemValue;
          }
        } else {
          otherHoldbackTL = this.addTrustLedgerHoldbackRow(SoaTrustLedgerConfigKeys.L1_OTHER_HOLDBACK, String(holdback.id), holdback.holdbackAmount, holdback.otherHoldbackType);
        }

        if (otherHoldbackTL) {
          otherHoldbacksTrustLedgers.push(otherHoldbackTL);
        }
      });
    }
    return otherHoldbacksTrustLedgers;
  }

  insertHoldBackRowsIntoTL(holdbacks: TrustLedgerMatter[], position: number): void {
    if (holdbacks && holdbacks.length) {
      if (position != -1) {
        this.matterTrustLedgers.splice(position, 0, ...holdbacks);
      } else {
        let paidToYouFeeIdx = this.matterTrustLedgers.findIndex(l => l.itemKey === SoaTrustLedgerConfigKeys.L1_LF);
        if (paidToYouFeeIdx > -1) {
          this.matterTrustLedgers.splice(paidToYouFeeIdx, 0, ...holdbacks);
        } else {
          this.matterTrustLedgers.push(...holdbacks);
        }
      }
    }
  }

  removeHoldbacksTLRows(itemKey: string): void {
    this.matterTrustLedgers = this.matterTrustLedgers.filter(mtl => mtl.itemKey != itemKey);
  }

  addTrustLedgerHoldbackRow(itemKey: string, linkKey: string, amount: number, otherHoldbackType?: string): TrustLedgerMatter {
    let trustLedgerMatter = null;
    let trustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.code == itemKey);
    if (trustLedgerConfig) {
      trustLedgerMatter = new TrustLedgerMatter();
      trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
      trustLedgerMatter.itemName = itemKey == SoaTrustLedgerConfigKeys.L1_ADVANCE_HOLDBACK ?
        trustLedgerConfig.expenseDescription.replace('###', linkKey) : (trustLedgerConfig.expenseDescription + ` ${ otherHoldbackType }`);
      trustLedgerMatter.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
      trustLedgerMatter.trustLedgerConfigId = trustLedgerConfig.id;
      trustLedgerMatter.itemValue = this.matter && this.matter.autoInsertAllF9Values ? amount : 0;
      trustLedgerMatter.itemKey = itemKey;
      trustLedgerMatter.itemType = trustLedgerConfig.expenseType;
      trustLedgerMatter.f9DefaultAmount = amount;
      trustLedgerMatter.linkKey = linkKey;

    }
    return trustLedgerMatter;
  }

  private insertTrustLedgerRowAsPerConfigOrder(trustLedgerMatter: TrustLedgerMatter, higherOrderConfig: TrustLedgerConfig[]) {

    for (var i = 0; i < this.matterTrustLedgers.length; i++) {
      if (higherOrderConfig.findIndex(c => c.id == this.matterTrustLedgers[ i ].trustLedgerConfigId) >= 0) {
        this.matterTrustLedgers.splice(i, 0, trustLedgerMatter);
        return;
      }
    }

    this.matterTrustLedgers.push(trustLedgerMatter);
  }

  addRemoveTrustLedgerPaidDischargeFee(mortgage: Mortgage): void {
    if (mortgage && mortgage.mortgagePayout) {
      if (mortgage.mortgagePayout.insertSeparateChequeTL) {
        this.addTrustLedgerPaidDischargeFee(mortgage, true);
      } else {
        let mortgageIndex = this.matter.getExistingMortgageIndexById(mortgage.id);
        if (mortgageIndex != -1) {
          this.removeTrustLedgerPaidDischargeFee(mortgageIndex);
        }

      }
    }
  }

  addTrustLedgerPaidDischargeFee(mortgage: Mortgage, autoGenerated: boolean): void {
    this.addTrustLedgerPaidDischargeFeeSingleView(mortgage, autoGenerated);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.addTrustLedgerPaidDischargeFeeSingleView(mortgage);
      this.progressionStatus = progressStatus;
    }
  }

  addTrustLedgerPaidDischargeFeeSingleView(mortgage: Mortgage, autoInserted: boolean = false): void {

    let mortgageIndex = this.matter.getExistingMortgageIndexById(mortgage.id);

    if (autoInserted) {
      //check if there is an existing autoGenerated item for the Mortgage, if found, just update the value
      let existingAutoInsertedItem: TrustLedgerMatter = this.matterTrustLedgers.find(mtl => mtl.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE
        && mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF_A && mtl.fromMortgageIndex == mortgageIndex);
      if (existingAutoInsertedItem) {
        existingAutoInsertedItem.itemName = SoaConstants.PAID_DISCHARGE_PREVIOUS_MORTGAGEE.replace('###', Utils.getOrdinal(mortgageIndex + 1)) + this.addChequePayableTo(mortgage);
        existingAutoInsertedItem.f9DefaultAmount = 0;
        existingAutoInsertedItem.itemValue = mortgage.mortgagePayout && mortgage.mortgagePayout.dischargeAmount ? mortgage.mortgagePayout.dischargeAmount : 0;//this.matter && this.matter.autoInsertAllF9Values? matterTrustLedger.f9DefaultAmount: matterTrustLedger.itemValue;
        existingAutoInsertedItem.readOnly = true;
        return;//skip the rest
      }
    }
    let trustLedgerMatter = new TrustLedgerMatter();
    trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
    trustLedgerMatter.itemName = SoaConstants.PAID_DISCHARGE_PREVIOUS_MORTGAGEE.replace('###', Utils.getOrdinal(mortgageIndex + 1)) + this.addChequePayableTo(mortgage);
    trustLedgerMatter.f9ReadOnly = autoInserted;
    trustLedgerMatter.trustLedgerConfigId = null;
    trustLedgerMatter.f9DefaultAmount = 0;
    trustLedgerMatter.itemValue = mortgage.mortgagePayout && mortgage.mortgagePayout.dischargeAmount ? mortgage.mortgagePayout.dischargeAmount : 0;
    trustLedgerMatter.itemKey = autoInserted ? SoaTrustLedgerConfigKeys.L1_PDF_A : SoaTrustLedgerConfigKeys.L1_PDF;
    trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
    trustLedgerMatter.fromMortgageIndex = mortgageIndex;
    trustLedgerMatter.readOnly = autoInserted;
    trustLedgerMatter.itemizedMatterTrustLedgers = [];

    let anyExistingPdfIdx: number = this.matterTrustLedgers.findIndex(mtl => mtl.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE && (mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF || mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF_A));

    if (anyExistingPdfIdx < 0) {
      //if there is no L1_PDF / L1_PDF_A item exists, add it to the end of L1_PM
      let matterTrustLedgerPaidPreviousMtgIndex = this.matterTrustLedgers.findIndex(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM && mtl.fromMortgageIndex == mortgageIndex);
      if (matterTrustLedgerPaidPreviousMtgIndex > -1) {
        this.matterTrustLedgers.splice(matterTrustLedgerPaidPreviousMtgIndex + 1, 0, trustLedgerMatter);
      } else {
        this.matterTrustLedgers.push(trustLedgerMatter);
      }
    } else {
      let firstPdfIdxFromNextMortgage = this.matterTrustLedgers.findIndex(mtl => mtl.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE
        && (mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF || mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF_A)
        && mtl.fromMortgageIndex > mortgageIndex);
      //if found any similar items from Mortgage (with larger index, add it to the front of it, otherwise add to the end of the existing item with Key L1_PDF | L1_PDF_A
      if (firstPdfIdxFromNextMortgage < 0) {
        let anyOtherPDFIdx = _.findLastIndex(this.matterTrustLedgers, function (mtl: TrustLedgerMatter) {
          return mtl.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE && (mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF || mtl.itemKey === SoaTrustLedgerConfigKeys.L1_PDF_A);
        });
        //add to the end of the existing L1_PDF | L1_PDF_A
        this.matterTrustLedgers.splice(++anyOtherPDFIdx, 0, trustLedgerMatter);
      } else {
        //add to front of the L1_PDF | L1_PDF_A item from next Mortgage
        this.matterTrustLedgers.splice(firstPdfIdxFromNextMortgage, 0, trustLedgerMatter);
      }
    }
  }

  addChequePayableTo(mortgage: Mortgage): string {
    return mortgage.mortgagePayout && mortgage.mortgagePayout.chequePayableTo ? ` to ${ mortgage.mortgagePayout.chequePayableTo }` : '';
  }

  addTrustLedgerExistingMortgageRow(mortgageIndex: number, isBridgeFinance: boolean, isDischarged: boolean, mortgage: Mortgage): void {
    this.addTrustLedgerExistingMortgageRowSingleView(mortgageIndex, isBridgeFinance, isDischarged, mortgage);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.addTrustLedgerExistingMortgageRowSingleView(mortgageIndex, isBridgeFinance, isDischarged, mortgage);
      this.progressionStatus = progressStatus;
    }
    if (mortgage.mortgagePayout && mortgage.mortgagePayout.insertSeparateChequeTL) {
      this.addTrustLedgerPaidDischargeFee(mortgage, true);
    }
  }

  addTrustLedgerExistingMortgageRowSingleView(mortgageIndex: number, isBridgeFinance: boolean, isDischarged: boolean, mortgage: Mortgage): void {
    let matterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedger && (isBridgeFinance || isDischarged)) {
      if (isDischarged) {
        matterTrustLedger.itemName = this.buildProvinceBasedItemName(mortgage, mortgageIndex);
      } else if (isBridgeFinance) {
        matterTrustLedger.itemName = SoaConstants.PAID_TO_BRIDGE_FINANCING;
      }

    } else if (isBridgeFinance || isDischarged) {
      let trustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isPreviousMortgage());
      if (trustLedgerConfig) {
        let trustLedgerMatter = new TrustLedgerMatter();
        trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
        if (isDischarged) {
          trustLedgerMatter.itemName = this.buildProvinceBasedItemName(mortgage, mortgageIndex);
        } else if (isBridgeFinance) {
          trustLedgerMatter.itemName = SoaConstants.PAID_TO_BRIDGE_FINANCING;
        } else {
          trustLedgerMatter.itemName = trustLedgerConfig.expenseDescription;
        }

        trustLedgerMatter.f9ReadOnly = this.matter && this.matter.autoInsertAllF9Values;
        trustLedgerMatter.trustLedgerConfigId = trustLedgerConfig.id;
        trustLedgerMatter.itemValue = 0;
        trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_PM;
        trustLedgerMatter.itemType = trustLedgerConfig.expenseType;
        if (isDischarged) {
          trustLedgerMatter.f9DefaultAmount = mortgage.amountPayableToDischarge ? mortgage.amountPayableToDischarge : 0;
        } else {
          trustLedgerMatter.f9DefaultAmount = trustLedgerConfig.f9DefaultAmount;
        }
        trustLedgerMatter.fromMortgageIndex = mortgageIndex;

        if (this.matterTrustLedgers.length == 0) {
          this.matterTrustLedgers.unshift(trustLedgerMatter);
        } else {
          let configIndex: number = this.trustLedger.getTrustLedgerList(this.matter).findIndex(t => t.isPreviousMortgage());
          this.insertTrustLedgerRowAsPerConfigOrder(trustLedgerMatter, this.trustLedger.getTrustLedgerList(this.matter).slice(configIndex + 1));
          this.matter.sortTrustLedgerMatterByMortgageIndex(SoaTrustLedgerConfigKeys.L1_PM, SoaTrustLedgerConfigKeys.L1_PDF);
        }
      }
    } else if (matterTrustLedger) {
      this.removeExistingMortgageFromTrustLedger(mortgageIndex);
    }
    this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(mortgage);
  }

  buildProvinceBasedItemName(mortgage: Mortgage, mortgageIndex: number) {
    if (mortgage && mortgage.financingType && mortgage.financingType.toUpperCase() != 'MORTGAGE') {
      return SoaConstants.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE.replace('mortgage', mortgage.financingType).replace('###', '');
    } else {
      return SoaConstants.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE.replace('###', Utils.getOrdinal(mortgageIndex + 1));
    }
  }

  removeMortgageFromTrustLedger(mortgageIndex: number): void {
    this.removeMortgageFromTrustLedgerSingleView(mortgageIndex);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.removeMortgageFromTrustLedgerSingleView(mortgageIndex);
      this.progressionStatus = progressStatus;
    }
  }

  removeMortgageFromTrustLedgerSingleView(mortgageIndex: number): void {
    let matterTrustLedgerIndex = this.matterTrustLedgers.findIndex(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG
      && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
    this.removeTrustLedgerPaidDischargeFee(mortgageIndex);
  }

  removeTrustLedgerPaidDischargeFee(mortgageIndex: number): void {
    this.removeTrustLedgerPaidDischargeFeeSingleView(mortgageIndex);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.removeTrustLedgerPaidDischargeFeeSingleView(mortgageIndex);
      this.progressionStatus = progressStatus;
    }

  }

  removeTrustLedgerPaidDischargeFeeSingleView(mortgageIndex: number): void {
    this.matterTrustLedgers.filter(mtl => (mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PDF || mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PDF_A) && mtl.fromMortgageIndex == mortgageIndex).forEach(
      matterTrustLedger => {
        (<any>this.matterTrustLedgers).remove(matterTrustLedger);
      }
    );
  }

  removeExistingMortgageFromTrustLedger(mortgageIndex: number): void {
    let matterTrustLedgerIndex = this.matterTrustLedgers.findIndex(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM
      && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  setItemizeMortgageAdvance(mortgage: Mortgage): void {
    if (mortgage.itemizeMortgageAdvanceInTrustAdvance == null) {
      mortgage.itemizeMortgageAdvanceInTrustAdvance = mortgage &&
        mortgage.stewartAssystMortgageInstruction &&
        mortgage.stewartAssystMortgageInstruction.mortgageInstructionData &&
        mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance &&
        Number(mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance) !=
        mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.principalAmount;
    }
  }

  addEmpItemizedMatterTrustLedgers(mortgage: Mortgage, matterTrustLedger: TrustLedgerMatter) {

    mortgage.mortgageTerm.calculationMethod = 'MANUAL_EDIT';
    let principalAmount: number = mortgage.stewartAssystMortgageInstruction.principalAmount ? Number(mortgage.stewartAssystMortgageInstruction.principalAmount) : 0;
    let cashBackAmount: number = mortgage.stewartAssystMortgageInstruction.cashBack ? (Number(mortgage.stewartAssystMortgageInstruction.cashBack) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.cashBack) * -1 : 0) : 0;
    let appraisalFeesAmount: number = mortgage.stewartAssystMortgageInstruction.appraisalFees ? (Number(mortgage.stewartAssystMortgageInstruction.appraisalFees) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.appraisalFees) * -1 : 0) : 0;
    let retainedByLenderAmount: number = mortgage.stewartAssystMortgageInstruction.retainedByLender ? (Number(mortgage.stewartAssystMortgageInstruction.retainedByLender) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.retainedByLender) * -1 : 0) : 0;
    let insurancePremiumAmount: number = mortgage.stewartAssystMortgageInstruction.insurancePremium && mortgage.stewartAssystMortgageInstruction.insurancePremiumIncluded == '0' ? (Number(mortgage.stewartAssystMortgageInstruction.insurancePremium) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.insurancePremium) * -1 : 0) : 0;

    let insurancePremiumFCT: number = mortgage.stewartAssystMortgageInstruction.insurancePremium && insurancePremiumAmount == 0 && !mortgage.stewartAssystMortgageInstruction.insurancePremiumIncluded && Boolean(mortgage.stewartAssystMortgageInstruction.insurancePremium) ? (Number(mortgage.stewartAssystMortgageInstruction.insurancePremium) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.insurancePremium) * -1 : 0) : 0;

    let taxOnInsurancePremium: number = mortgage.stewartAssystMortgageInstruction.taxOnInsurancePremium ? (Number(mortgage.stewartAssystMortgageInstruction.taxOnInsurancePremium) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.taxOnInsurancePremium) * -1 : 0) : 0;
    let insuranceApplicationFee: number = mortgage.stewartAssystMortgageInstruction.insuranceApplicationFee ? (Number(mortgage.stewartAssystMortgageInstruction.insuranceApplicationFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.insuranceApplicationFee) * -1 : 0) : 0;
    let lenderFee: number = mortgage.stewartAssystMortgageInstruction.lenderFee ? (Number(mortgage.stewartAssystMortgageInstruction.lenderFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.lenderFee) * -1 : 0) : 0;
    let miscellaneousFee: number = mortgage.stewartAssystMortgageInstruction.miscellaneousFee ? (Number(mortgage.stewartAssystMortgageInstruction.miscellaneousFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.miscellaneousFee) * -1 : 0) : 0;
    let holdBackFee: number = mortgage.stewartAssystMortgageInstruction.holdBackFee ? (Number(mortgage.stewartAssystMortgageInstruction.holdBackFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.holdBackFee) * -1 : 0) : 0;
    let buyDownAtCommitmentFee: number = mortgage.stewartAssystMortgageInstruction.buyDownAtCommitmentFee ? (Number(mortgage.stewartAssystMortgageInstruction.buyDownAtCommitmentFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.buyDownAtCommitmentFee) * -1 : 0) : 0;
    let bridgeLoanApplicationFee: number = mortgage.stewartAssystMortgageInstruction.bridgeLoanApplicationFee ? (Number(mortgage.stewartAssystMortgageInstruction.bridgeLoanApplicationFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.bridgeLoanApplicationFee) * -1 : 0) : 0;
    let wireFee: number = mortgage.stewartAssystMortgageInstruction.wireFee ? (Number(mortgage.stewartAssystMortgageInstruction.wireFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.wireFee) * -1 : 0) : 0;
    let fctFee: number = mortgage.stewartAssystMortgageInstruction.fctFee ? (Number(mortgage.stewartAssystMortgageInstruction.fctFee) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.fctFee) * -1 : 0) : 0;
    let taxHoldBack: number = mortgage.stewartAssystMortgageInstruction.taxHoldBack ? (Number(mortgage.stewartAssystMortgageInstruction.taxHoldBack) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.taxHoldBack) * -1 : 0) : 0;
    let internalMortgagePayout: number = mortgage.stewartAssystMortgageInstruction.internalMortgagePayout ? (Number(mortgage.stewartAssystMortgageInstruction.internalMortgagePayout) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.internalMortgagePayout) * -1 : 0) : 0;

    // TBD
    let rebate: number = mortgage.stewartAssystMortgageInstruction.rebate ? (Number(mortgage.stewartAssystMortgageInstruction.rebate) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.rebate) * -1 : 0) : 0;
    let lineOfCredit: number = mortgage.stewartAssystMortgageInstruction.lineOfCredit ? (Number(mortgage.stewartAssystMortgageInstruction.lineOfCredit) != 0 ? Number(mortgage.stewartAssystMortgageInstruction.lineOfCredit) * -1 : 0) : 0;

    let netAdvanceAmount: number = mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance ? Number(mortgage.stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance) : 0;
    let otherAdjustmentsAmount: number = netAdvanceAmount - principalAmount - cashBackAmount - appraisalFeesAmount - retainedByLenderAmount - insurancePremiumAmount - insurancePremiumFCT - taxOnInsurancePremium - insuranceApplicationFee - lenderFee - miscellaneousFee - holdBackFee - buyDownAtCommitmentFee - bridgeLoanApplicationFee - wireFee - fctFee - taxHoldBack - internalMortgagePayout;

    /// Principal of Mortgage
    if (mortgage && mortgage.stewartAssystMortgageInstruction) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Principal of Mortgage';
      trustLedgerItemize.itemAmount = principalAmount;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Cashback
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.cashBack) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Cashback';
      trustLedgerItemize.itemAmount = cashBackAmount;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Appraisal Fees
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.appraisalFees) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Appraisal Fees';
      trustLedgerItemize.itemAmount = appraisalFeesAmount;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Retained by Lender
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.retainedByLender) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Retained by Lender';
      trustLedgerItemize.itemAmount = retainedByLenderAmount;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Mortgage Insurance Premium
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.insurancePremiumIncluded && mortgage.stewartAssystMortgageInstruction.insurancePremiumIncluded == '0') {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Mortgage Insurance Premium';
      trustLedgerItemize.itemAmount = insurancePremiumAmount;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Mortgage Insurance Premium - FCT
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.insurancePremium && !mortgage.stewartAssystMortgageInstruction.insurancePremiumIncluded) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Mortgage Insurance Premium';
      trustLedgerItemize.itemAmount = insurancePremiumFCT;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Tax on Insurance Premium
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.taxOnInsurancePremium) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Tax on Insurance Premium';
      trustLedgerItemize.itemAmount = taxOnInsurancePremium;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Insurance Application Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.insuranceApplicationFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Insurance Application Fee';
      trustLedgerItemize.itemAmount = insuranceApplicationFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Lender Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.lenderFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Lender Fee';
      trustLedgerItemize.itemAmount = lenderFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Miscellaneous Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.miscellaneousFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Miscellaneous Fee';
      trustLedgerItemize.itemAmount = miscellaneousFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Holdback Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.holdBackFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Holdback Fee';
      trustLedgerItemize.itemAmount = holdBackFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Buydown at Commitment Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.buyDownAtCommitmentFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Buydown at Commitment Fee';
      trustLedgerItemize.itemAmount = buyDownAtCommitmentFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Bridge Loan Application Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.bridgeLoanApplicationFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Bridge Loan Application Fee';
      trustLedgerItemize.itemAmount = bridgeLoanApplicationFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Wire Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.wireFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Wire Fee';
      trustLedgerItemize.itemAmount = wireFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    /// Rebate - TBD
    /*
        if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.rebate) {
            let trustLedgerItemize = new TrustLedgerItemize();
            trustLedgerItemize.itemName = 'Rebate';
            trustLedgerItemize.itemAmount = rebate;
            trustLedgerItemize.readOnly = false;
            matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
        }

        */

    /// FCT Fee
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.fctFee) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'FCT Fee';
      trustLedgerItemize.itemAmount = fctFee;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Line of Credit - TBD
    /*
        if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.lineOfCredit) {
            let trustLedgerItemize = new TrustLedgerItemize();
            trustLedgerItemize.itemName = 'Line of Credit';
            trustLedgerItemize.itemAmount = lineOfCredit;
            trustLedgerItemize.readOnly = false;
            matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
        }
        */

    ///  Tax Holdback
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.taxHoldBack) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Tax Holdback';
      trustLedgerItemize.itemAmount = taxHoldBack;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
    ///  Internal Mortgage Payout
    if (mortgage && mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.internalMortgagePayout) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Internal Mortgage Payout';
      trustLedgerItemize.itemAmount = internalMortgagePayout;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// Other Adjustments

    //  if(otherAdjustmentsAmount!= netAdvanceAmount) {
    if (otherAdjustmentsAmount != 0) {
      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemName = 'Other Adjustments';
      trustLedgerItemize.itemAmount = otherAdjustmentsAmount;
      trustLedgerItemize.readOnly = false;
      matterTrustLedger.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }

    /// update trust ledger values -
    if (netAdvanceAmount > 0) {
      matterTrustLedger.itemValue = netAdvanceAmount;
      matterTrustLedger.f9DefaultAmount = netAdvanceAmount;
    }

  }

  updateTrustLedgeMortgageValues(mortgageIndex: number, mortgageTerm: MortgageTerm, mortgage: Mortgage): void {
    let matterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedger) {
      if (!matterTrustLedger.calculationType || (matterTrustLedger.calculationType && mortgageTerm.calculationMethod != matterTrustLedger.calculationType)) {
        matterTrustLedger.calculationType = mortgageTerm.calculationMethod;
        if (mortgage.isEmpMortgage()) {
          if (mortgage.stewartAssystMortgageInstruction && mortgage.stewartAssystMortgageInstruction.principalAmount) {
            matterTrustLedger.f9DefaultAmount = mortgage.itemizeMortgageAdvanceInTrustAdvance
              ? (mortgage.stewartAssystMortgageInstruction.netAdvance ? Number(mortgage.stewartAssystMortgageInstruction.netAdvance) : 0)
              : (mortgage.stewartAssystMortgageInstruction.principalAmount ? Number(mortgage.stewartAssystMortgageInstruction.principalAmount) : 0);
            matterTrustLedger.itemValue = matterTrustLedger.f9DefaultAmount;
          }

        } else {
          matterTrustLedger.itemValue = mortgageTerm.isCalculationMethodManual() ? 0 : mortgageTerm.getTrustLedgerMortgageValue();
          if (matterTrustLedger.f9ReadOnly && mortgageTerm.isCalculationMethodManual()) {
            matterTrustLedger.itemValue = mortgageTerm.getTrustLedgerMortgageValue();
          }
          if (!mortgage.isEmpMortgage()) {
            matterTrustLedger.f9DefaultAmount = mortgageTerm.isCalculationMethodManual() ? mortgageTerm.getTrustLedgerMortgageValue() : 0;
            matterTrustLedger.readOnly = !mortgageTerm.isCalculationMethodManual();
          }
        }

      } else if (mortgageTerm.calculationMethod == matterTrustLedger.calculationType) {
        if (!mortgageTerm.isCalculationMethodManual()) {
          matterTrustLedger.itemValue = mortgageTerm.getTrustLedgerMortgageValue();
        } else {
          if (matterTrustLedger.f9ReadOnly && !matterTrustLedger.readOnly) {
            matterTrustLedger.itemValue = mortgageTerm.getTrustLedgerMortgageValue();
          }
          matterTrustLedger.f9DefaultAmount = mortgageTerm.getTrustLedgerMortgageValue();
        }
      }
      this.updateF9ForPaidToYouAndOtherTrustLedgers();
    }

  }

  get expenditureTotal(): Number {
    let total = 0;
    let trustLedgerList = this.matterTrustLedgers.filter(trustLedger => trustLedger.isExpenditure);
    for (let i = 0; i < trustLedgerList.length; i++) {
      total = total + Number(trustLedgerList[ i ].itemValue || 0);
    }
    return total;
  }

  // calculated based on TrustLedger progression status
  get receiptTotal(): Number {
    let total = 0;
    let trustLedgerList = this.matterTrustLedgers.filter(trustLedger => !trustLedger.isExpenditure);
    for (let i = 0; i < trustLedgerList.length; i++) {
      total = total + Number(trustLedgerList[ i ].itemValue || 0);
    }
    return total;
  }

  getReceiptTotalByProgressionStatus(progressionStatus: string): number {
    if (progressionStatus && progressionStatus === ProgressionStatus.INTERIM) {
      return Number(this._matterTrustLedgersInterim.filter((item) => !isNaN(item.itemValue) && !item.isExpenditure).reduce((total: Number, current: TrustLedgerMatter) => Number(total) + Number(current.itemValue), 0));
    }
    return Number(this._matterTrustLedgersFinal.filter((item) => !isNaN(item.itemValue) && !item.isExpenditure).reduce((total: Number, current: TrustLedgerMatter) => Number(total) + Number(current.itemValue), 0));
  }

  get expenditureTotalWithoutPYRY(): Number {
    let total = 0;
    let trustLedgerList = this.matterTrustLedgers.filter(trustLedger => trustLedger.isExpenditure && !trustLedger.isPaidToYou && !trustLedger.isReceivedFromYou);
    for (let i = 0; i < trustLedgerList.length; i++) {
      total = total + Number(trustLedgerList[ i ].itemValue || 0);
    }
    return total;
  }

  get receiptTotalWithoutPYRY(): Number {
    let total = 0;
    let trustLedgerList = this.matterTrustLedgers.filter(trustLedger => !trustLedger.isExpenditure && !trustLedger.isPaidToYou && !trustLedger.isReceivedFromYou);
    for (let i = 0; i < trustLedgerList.length; i++) {
      total = total + Number(trustLedgerList[ i ].itemValue || 0);
    }
    return total;
  }

  get receiptTotalWithoutPYRYCashShortFall(): Number {
    let total = 0;
    let trustLedgerList = this.matterTrustLedgers.filter(trustLedger => !trustLedger.isExpenditure && !trustLedger.isPaidToYou && !trustLedger.isReceivedFromYou && !trustLedger.isCashShortFall);
    for (let i = 0; i < trustLedgerList.length; i++) {
      total = total + Number(trustLedgerList[ i ].itemValue || 0);
    }
    return total;
  }

  get receiptTotalWithoutPYRYCashShortFallItemized(): Number {
    let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
    let matterTrustLedgerRY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
    return Number(this.receiptTotalWithoutPYRYCashShortFall) + Number(matterTrustLedgerPY && matterTrustLedgerPY.isItemized() && !matterTrustLedgerPY.isExpenditure ? matterTrustLedgerPY.itemizeTotal : 0) + Number(matterTrustLedgerRY && matterTrustLedgerRY.isItemized() && !matterTrustLedgerRY.isExpenditure ? matterTrustLedgerRY.itemizeTotal : 0);
  }

  get expenditureTotalWithoutPYRYItemized(): Number {
    let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
    let matterTrustLedgerRY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
    return Number(this.expenditureTotalWithoutPYRY) + Number(matterTrustLedgerPY && matterTrustLedgerPY.isItemized() && matterTrustLedgerPY.isExpenditure ? matterTrustLedgerPY.itemizeTotal : 0) + Number(matterTrustLedgerRY && matterTrustLedgerRY.isItemized() && matterTrustLedgerRY.isExpenditure ? matterTrustLedgerRY.itemizeTotal : 0);
  }

  get receiptTotalWithoutCashShortFall(): Number {
    let total = 0;
    let trustLedgerList = this.matterTrustLedgers.filter(trustLedger => !trustLedger.isExpenditure && !trustLedger.isCashShortFall);
    for (let i = 0; i < trustLedgerList.length; i++) {
      total = total + Number(trustLedgerList[ i ].itemValue || 0);
    }
    return total;
  }

  updateAllF9ForTrustLedger(): void {
    for (let i = 0; i < this.matterTrustLedgers.length; i++) {
      if (this.matterTrustLedgers[ i ] && this.matterTrustLedgers[ i ].f9DefaultAmount && this.matterTrustLedgers[ i ].f9DefaultAmount > 0) {
        this.matterTrustLedgers[ i ].itemValue = this.matterTrustLedgers[ i ].f9DefaultAmount;
      }
    }
  }

  //Use updateTrustLedgerLegalFeeName replace updateTrustLedgerLegalFeeExplanation
  // updateTrustLedgerLegalFeeExplanation(firmName: string): void {
  //     let matterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_LF);
  //     if (matterTrustLedger && firmName) {
  //         matterTrustLedger.itemName = matterTrustLedger.itemName + ' - ' + firmName;
  //
  //     }
  // }

  updateTrustLedgerLegalFeeName(): void {

    // Willem Kruger commented on  DPPMP-29709
    //
    //
    // Re: Document Profile - Firm Name saved as blank, not a mandatory field
    //
    // This is working as specified. Firm name for document profile is not mandatory and this mirror what we have in TC in the user setup.
    // It has been a long time ago, but if I remember correctly, it was stated that making it mandatory in Unity would complicate migration of existing customers as there is no guarantee that it is populated in TC.

    if (this.documentProfile && this.documentProfile.firmDocumentProfile && (this.statementConfig && this.statementConfig.trustLedger && this.statementConfig.trustLedger.trustLedgerEntries)) {
      let trustLedgerList = this.trustLedger.getTrustLedgerList(this.matter);
      let trusrLegalFeeConfig = trustLedgerList ? trustLedgerList.find(item => item.isLegalFee()) : null;
      let matterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_LF);
      if (trusrLegalFeeConfig && matterTrustLedger) {
        matterTrustLedger.itemName = this.documentProfile.firmDocumentProfile.firmName
          ? trusrLegalFeeConfig.expenseDescription + ' - ' + this.documentProfile.firmDocumentProfile.firmName
          : trusrLegalFeeConfig.expenseDescription;
      }
    }
  }

  updateTrustLedgerLegalFee = (): void => {
    //Soa Final => TL Final , Soa Interim => TL Interim
    //Soa Single => TL Single
    //Soa Single => TL Interim AND TL Final
    let matterTrustLedger: TrustLedgerMatter = this.matterTrustLedgers.find(mtl => mtl.isLegalFees);

    if (matterTrustLedger && this.matter) {
      const value: number = this.receivedOnAccount;
      // console.log(">> updateTrustLedgerLegalFee ?? isTlInterimMode ?  %s", this.isTlInterimMode)
      // console.log("--> update legalFeeFL %s f9 value to %s", matterTrustLedger.identifier, value);
      if (this.matter.autoInsertAllF9Values) {
        matterTrustLedger.itemValue = value;
      }
      matterTrustLedger.f9DefaultAmount = value;
    }
    if (this.matter.secondarySoaSheets) {
      this.matter.secondarySoaSheets.forEach(sheet => {
          let matterTrustLedger: TrustLedgerMatter = this.matterTrustLedgers.find(mtl => mtl.isLegalFees && mtl.matterSoaSheetId == sheet.id);
          if (matterTrustLedger && this.matter) {
            const value: number = sheet.receivedOnAccount;
            if (this.matter.autoInsertAllF9Values) {
              matterTrustLedger.itemValue = value;
            }
            matterTrustLedger.f9DefaultAmount = value;
          }
        }
      );
    }
  };

  updateTrustLedgerPaidFees(): void {
    if (this.matter && !this.matter.isProjectConfigDocForSoa() && this.matter.isProjectConfigDocForTrustLedger()) {
      //For Case: Single SoA, TL: Interim + Final, then value from Soa will update in TL both Tabs
      this.execActionForInterimAndFinal(this.updateTrustLedgerLegalFee);
    } else {
      this.updateTrustLedgerLegalFee();
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  removeSecondarySheetTrustLedgerLegalFee(sheetId: number): void {
    let matterTrustLedgerIndex = this.matterTrustLedgers.findIndex(mtl => mtl.isLegalFees && mtl.matterSoaSheetId == sheetId);
    if (matterTrustLedgerIndex > -1) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  addSecondarySheetTrustLedgerLegalFee(activeMatterSheetId: number): void {
    if (!this.matter.isWillMatter()) {
      let matterTrustLedger: TrustLedgerMatter = this.matterTrustLedgers.find(mtl => mtl.isLegalFees && mtl.matterSoaSheetId == activeMatterSheetId);
      let matterTrustLedgerPrimary: TrustLedgerMatter = this.matterTrustLedgers.find(mtl => mtl.isLegalFees && !mtl.matterSoaSheetId);
      let matterTrustLedgerPrimaryIndex = this.matterTrustLedgers.map(mtl => {
        return mtl.itemKey;
      }).lastIndexOf(SoaTrustLedgerConfigKeys.L1_LF);
      if (!matterTrustLedger) {
        let trustLedgerMatter = new TrustLedgerMatter();
        trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
        trustLedgerMatter.itemName = matterTrustLedgerPrimary?.itemName;
        trustLedgerMatter.itemValue = 0;
        trustLedgerMatter.itemType = matterTrustLedgerPrimary?.itemType;
        trustLedgerMatter.itemKey = matterTrustLedgerPrimary?.itemKey;
        trustLedgerMatter.readOnly = matterTrustLedgerPrimary?.readOnly;
        trustLedgerMatter.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
        trustLedgerMatter.matterSoaSheetId = activeMatterSheetId;
        if (matterTrustLedgerPrimaryIndex > -1) {
          this.matterTrustLedgers.splice(matterTrustLedgerPrimaryIndex + 1, 0, trustLedgerMatter);
        } else {
          this.matterTrustLedgers.push(trustLedgerMatter);
        }

      }
    }
  }

  setReadOnlySupplementalTaskExpenditures(): void {
    if (this.matter) {
      this.matterTrustLedgers.forEach(matterTrustLedger => {
        if (matterTrustLedger.itemKey === TrustLedgerExpenditureTypes.SE_A) {
          matterTrustLedger.readOnly = true;
        } else if (matterTrustLedger.itemKey === TrustLedgerExpenditureTypes.BF) {
          matterTrustLedger.readOnly = false;
        }
        if (matterTrustLedger.itemKey === SoaTrustLedgerConfigKeys.L1_PDF_A) {
          matterTrustLedger.readOnly = true;
        } else if (matterTrustLedger.itemKey === SoaTrustLedgerConfigKeys.L1_PDF) {
          matterTrustLedger.readOnly = false;
        }
      });
    }
  }

  updateTLF9MortgageFee(): void {
    if (this.matter) {

      for (let i = 0; i < this.matter.mortgages.length; i++) {

        let existingMatterTrustLedger = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == i);

        if (existingMatterTrustLedger && this.matter.mortgages[ i ].mortgageTerm) {
          if ((this.matter.mortgages[ i ].mortgageTerm.isCalculationMethodManual() && (!existingMatterTrustLedger.itemizedMatterTrustLedgers || (existingMatterTrustLedger.itemizedMatterTrustLedgers && existingMatterTrustLedger.itemizedMatterTrustLedgers.length == 0))) && !this.matter.mortgages[ i ].isEmpMortgage()) {
            existingMatterTrustLedger.readOnly = false;
            existingMatterTrustLedger.f9DefaultAmount = this.matter.mortgages[ i ].mortgageTerm.getTrustLedgerMortgageValue();
          } else {
            existingMatterTrustLedger.readOnly = true;
          }
        }
      }
    }
  }

  updateF9PaidToVendor(): void {
    if (this.matter && this.trustLedger && this.trustLedger.getTrustLedgerList(this.matter) && this.matter.calculateBalanceDueOnClosingPurchase() != undefined && this.statementConfig) {
      let trustLedgerConfig: TrustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isPaidToVendor());
      let calculateBalanceDueOnClosing = this.matter.calculateBalanceDueOnClosingPurchase();
      if (this.matter && this.matter.isPurchaseMatterLinkedProjectSale()) {
        calculateBalanceDueOnClosing = this.matter.calculateBalanceDueOnClosingCreditPurchaserFromFocusedSoAdjSheet();
      }
      let matterTrustLedgerPV = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PV);
      if (trustLedgerConfig) {
        trustLedgerConfig.f9DefaultAmount = calculateBalanceDueOnClosing;
      }
      if (matterTrustLedgerPV) {
        matterTrustLedgerPV.f9DefaultAmount = calculateBalanceDueOnClosing;
        if (matterTrustLedgerPV.f9ReadOnly && !matterTrustLedgerPV.isItemized()) {
          matterTrustLedgerPV.itemValue = calculateBalanceDueOnClosing;
          this.updateF9ForPaidToYouAndOtherTrustLedgers();
        }
      }
    }
  }

  updateF9PaidToPreviousMortgage(): void {
    if (this.matter && Array.isArray(this.matterTrustLedgers)) {
      this.matterTrustLedgers.forEach(matterTrustLedgerPM => {
        if (matterTrustLedgerPM.itemKey == SoaTrustLedgerConfigKeys.L1_PM
          && this.matter.existingMortgages.length > matterTrustLedgerPM.fromMortgageIndex
          && this.matter.existingMortgages[ matterTrustLedgerPM.fromMortgageIndex ]) {
          matterTrustLedgerPM.f9DefaultAmount = this.matter.existingMortgages[ matterTrustLedgerPM.fromMortgageIndex ].amountPayableToDischarge;
          if (matterTrustLedgerPM.f9ReadOnly && !matterTrustLedgerPM.isItemized()) {
            matterTrustLedgerPM.itemValue = matterTrustLedgerPM.f9DefaultAmount;
          }
        }
      });
    }
  }

  updateF9PaidToBroker(): void {
    if (this.matter && this.trustLedger && this.trustLedger.getTrustLedgerList(this.matter) && this.matter.calculateBalanceDueOnClosingPurchase() != undefined && this.statementConfig) {
      let trustLedgerConfig: TrustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isPaidToRealEstateBroker());
      let matterTrustLedgerPB = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PB);
      if (trustLedgerConfig) {
        trustLedgerConfig.f9DefaultAmount = this.matter.calculateBrokerCommission();
      }
      if (matterTrustLedgerPB) {
        matterTrustLedgerPB.f9DefaultAmount = this.matter.calculateBrokerCommission();
        if (matterTrustLedgerPB.f9ReadOnly) {
          if (matterTrustLedgerPB.itemizedMatterTrustLedgers && matterTrustLedgerPB.itemizedMatterTrustLedgers.length > 0) {
            matterTrustLedgerPB.itemValue = matterTrustLedgerPB.itemizedMatterTrustLedgers.map(item => item.itemAmount).reduce((a: number, b: number) => Number(a) + Number(b));
            matterTrustLedgerPB.f9DefaultAmount = matterTrustLedgerPB.itemValue;
          } else {
            matterTrustLedgerPB.itemValue = matterTrustLedgerPB.f9DefaultAmount;
          }
        }
      }
      let matterTrustLedgerPBSeparate = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PB_SEPARATE);
      if (matterTrustLedgerPBSeparate) {
        matterTrustLedgerPBSeparate.f9DefaultAmount = this.matter.calculateBrokerCommission(true);
        if (matterTrustLedgerPBSeparate.f9ReadOnly && !matterTrustLedgerPBSeparate.isItemized()) {
          matterTrustLedgerPBSeparate.itemValue = matterTrustLedgerPBSeparate.f9DefaultAmount;
        }
      }
    }
  }

  updateF9ReceivedFromPurchaser(): void {
    if (this.matter && this.trustLedger && this.trustLedger.getTrustLedgerList(this.matter) && this.matter.calculateBalanceDueOnClosingCreditVendor() != undefined && this.statementConfig) {
      let trustLedgerConfig: TrustLedgerConfig = this.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isReceivedFromPurchaserOnClosing());
      let matterTrustLedgerRP = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RP);
      const itemAmt: number = Utils.roundCurrencyTCStyle(this.matter.isProjectSale ? this.matter.calculateBalanceDueOnClosingCreditPurchaserFromFocusedSoAdjSheet() :
        this.matter.calculateBalanceDueOnClosingCreditPurchaser());
      if (trustLedgerConfig) {
        trustLedgerConfig.f9DefaultAmount = itemAmt;
      }
      if (matterTrustLedgerRP) {
        matterTrustLedgerRP.f9DefaultAmount = itemAmt;
        if (matterTrustLedgerRP.f9ReadOnly && !matterTrustLedgerRP.isItemized()) {
          matterTrustLedgerRP.itemValue = itemAmt;
        }
      }
    }
  }

  //it is used for TrustLedge/Soa
  updateF9LttNSNB(): void {

    let lttItem = this.soaConfigListForLTT.find(soaMatter => (soaMatter.isDTT() || soaMatter.isNBLTT()));
    if (lttItem && this.matter && this.matter.matterPropertyWithCondo) {
      const lttValue: number = this.matter.matterPropertyWithCondo.landTransferTax;
      if (this.statementConfiguration && this.statementConfiguration.isLTTSetForTrustLedger() && this.matter.autoInsertAllF9Values) {//autInsertAllF9 is only applicable to TrustLedger
        lttItem.itemValue = lttValue;
      }
      lttItem.f9DefaultAmount = lttValue;
    }
  }

  updateF9ManitobaLtt(): void {
    if (this.matter && this.matter.matterPropertyWithCondo && this.matterTrustLedgers.length > 0) {
      let matterTrustLedgerMT = this.matterTrustLedgers.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_MT);
      if (matterTrustLedgerMT) {
        if (this.matter.autoInsertAllF9Values) {
          matterTrustLedgerMT.itemValue = this.matter.matterPropertyWithCondo.landTransferTax;
        }
        matterTrustLedgerMT.f9DefaultAmount = this.matter.matterPropertyWithCondo.landTransferTax;
        this.updateF9ForPaidToYouAndOtherTrustLedgers();
      }
    }
  }

  getSoaFeeRateOfMLTT(): number {
    if (Array.isArray(this.soaFeesRates)) {
      //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isMLTTConfiguration());
      const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.HST_MLTT_ADMIN_FEE);
      if (configuredRate && configuredRate.fees) {
        return configuredRate.fees;
      }
    }
    return 0;
  }

  getSoaFeeRateOfRegistrationTransfer(): number {
    if (Array.isArray(this.soaFeesRates)) {
      //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationTransfer());
      const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
      if (configuredRate && configuredRate.fees) {
        return configuredRate.fees;
      }
    }
    return 0;
  }

  getSoaFeeRateOfRegistrationChargeMB(): number {
    if (Array.isArray(this.soaFeesRates)) {
      //const configuredRate: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationCharge());
      const configuredRate: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
      if (configuredRate && configuredRate.fees != null && configuredRate.fees != undefined) {
        return Number(configuredRate.fees) * Number(this.matter.getRegisteredInstruments());
      }
    }
    return 0;
  }

  updateF9TitleRegistrationFeeTrustLedgerMB(doNotUpdateItemValue?: boolean): void {
    if (this.statementConfiguration && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && Array.isArray(this.matterTrustLedgers) && this.matterTrustLedgers.length > 0) {
      let matterTrustLedgerTR = this.matterTrustLedgers.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_TR);
      if (matterTrustLedgerTR) {
        const value: number = this.getSoaFeeRateOfRegistrationTransfer();
        if ((this.matter.autoInsertAllF9Values || this.statementConfig.isTitleMortgageRegFeesForTrustLedgerAuto()) && !doNotUpdateItemValue && !matterTrustLedgerTR.userOverriddenValue) {
          matterTrustLedgerTR.itemValue = value;
        }
        matterTrustLedgerTR.f9DefaultAmount = value;
        if (!doNotUpdateItemValue) {
          this.updateF9ForPaidToYouAndOtherTrustLedgers();
        }
      }
    }
  }

  updateF9MortgageRegistrationFeeTrustLedgerMB(): void {
    if (this.statementConfiguration && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && this.matter.matterPropertyWithCondo && this.matterTrustLedgers.length > 0) {
      let matterTrustLedgerMR = this.matterTrustLedgers.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_MR);
      if (matterTrustLedgerMR) {
        const value: number = this.getSoaFeeRateOfRegistrationChargeMB();
        if (this.matter.autoInsertAllF9Values || this.statementConfig.isTitleMortgageRegFeesForTrustLedgerAuto() && !matterTrustLedgerMR.userOverriddenValue) {
          matterTrustLedgerMR.itemValue = value;
        }
        matterTrustLedgerMR.f9DefaultAmount = value;
        this.updateF9ForPaidToYouAndOtherTrustLedgers();
      }
    }
  }

  updateF9SaskTitleRegTrustLedger(): void {
    if (this.statementConfiguration && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && this.matter.matterPropertyWithCondo && this.matterTrustLedgers.length > 0) {
      let matterTrustLedgerTR = this.matterTrustLedgers.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_TR);
      if (matterTrustLedgerTR) {
        if (this.matter.autoInsertAllF9Values || this.statementConfig.isTitleMortgageRegFeesForTrustLedgerAuto() && !matterTrustLedgerTR.userOverriddenValue) {
          matterTrustLedgerTR.itemValue = this.matter.matterPropertyWithCondo.titleRegistrationFee;
        }
        matterTrustLedgerTR.f9DefaultAmount = this.matter.matterPropertyWithCondo.titleRegistrationFee;
        this.updateF9ForPaidToYouAndOtherTrustLedgers();
      }

    }
  }

  updateF9SaskMortgageRegTrustLedger(): void {
    if (this.statementConfiguration && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated() && this.matter && this.matter.matterPropertyWithCondo && this.matterTrustLedgers.length > 0) {
      let matterTrustLedgerMR = this.matterTrustLedgers.find(soaMatter => soaMatter.itemKey == SoaTrustLedgerConfigKeys.L1_MR);
      if (matterTrustLedgerMR) {
        if (this.matter.autoInsertAllF9Values || this.statementConfig.isTitleMortgageRegFeesForTrustLedgerAuto() && !matterTrustLedgerMR.userOverriddenValue) {
          matterTrustLedgerMR.itemValue = this.matter.matterPropertyWithCondo.mortgageRegistrationFee;
        }
        matterTrustLedgerMR.f9DefaultAmount = this.matter.matterPropertyWithCondo.mortgageRegistrationFee;
        this.updateF9ForPaidToYouAndOtherTrustLedgers();
      }
    }
  }

  // updateF9ForPTYAndRFYForInsertF9(updatePYRFValue?: boolean): void {
  //     this.updateCashShortFall();
  //     let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
  //     if (matterTrustLedgerPY && (this.receiptTotalWithoutPYRY > this.expenditureTotalWithoutPYRY) && matterTrustLedgerPY.isExpenditure) {
  //         matterTrustLedgerPY.itemValue = 0;
  //         matterTrustLedgerPY.f9DefaultAmount = (Number(this.receiptTotalWithoutPYRY) - Number(this.expenditureTotalWithoutPYRY));
  //     }
  //     else if (matterTrustLedgerPY) {
  //         matterTrustLedgerPY.f9DefaultAmount = 0;
  //         matterTrustLedgerPY.itemValue = 0;
  //     }
  //     let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
  //     if (matterTrustLedgerRF && (this.expenditureTotalWithoutPYRY > this.receiptTotalWithoutPYRY) && !matterTrustLedgerRF.isExpenditure) {
  //         matterTrustLedgerRF.itemValue = 0;
  //         matterTrustLedgerRF.f9DefaultAmount = (Number(this.expenditureTotalWithoutPYRY) - Number(this.receiptTotalWithoutPYRY));
  //     }
  //     else if (matterTrustLedgerRF) {
  //         matterTrustLedgerRF.itemValue = 0;
  //         matterTrustLedgerRF.f9DefaultAmount = 0;
  //     }
  //     this.updateF9ValueForPTYAndRFYForInsertF9(updatePYRFValue);
  //
  // }

  // updateF9ValueForPTYAndRFYForInsertF9(updatePYRFValue?: boolean): void {
  //     let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
  //     if (matterTrustLedgerPY && !matterTrustLedgerPY.readOnly && matterTrustLedgerPY.f9DefaultAmount != 0 && (matterTrustLedgerPY.f9ReadOnly || updatePYRFValue)) {
  //         matterTrustLedgerPY.itemValue = matterTrustLedgerPY.f9DefaultAmount;
  //         if (this.receiptTotal == this.expenditureTotal) {
  //             matterTrustLedgerPY.f9DefaultAmount = 0;
  //         }
  //     }
  //     let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
  //     if (matterTrustLedgerRF && !matterTrustLedgerRF.readOnly && matterTrustLedgerRF.f9DefaultAmount != 0 && (matterTrustLedgerPY.f9ReadOnly || updatePYRFValue)) {
  //         matterTrustLedgerRF.itemValue = matterTrustLedgerRF.f9DefaultAmount;
  //         if (this.receiptTotal == this.expenditureTotal) {
  //             matterTrustLedgerRF.f9DefaultAmount = 0;
  //         }
  //     }
  // }

  resetPaidToYou(): void {
    let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
    if (matterTrustLedgerPY && !matterTrustLedgerPY.isItemized()) {
      matterTrustLedgerPY.itemValue = 0;
      matterTrustLedgerPY.f9DefaultAmount = 0;
    }
  }

  resetReceivedFromYou(resetItemValue: boolean = false): void {
    let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
    if (matterTrustLedgerRF && !matterTrustLedgerRF.isItemized()) {
      if (resetItemValue) {
        matterTrustLedgerRF.itemValue = 0;
      }
      matterTrustLedgerRF.f9DefaultAmount = 0;
    }
  }

  resetPayToYouTrustLedgerItem(): void {
    if (this.matter && this.matter.isMatterProvinceABorMBorSK) {
      if (this.matter.hasExistingMortgageChargedAndPayoutEqualsTrustLedgerBalance()) {
        this.resetValueAndRoForPayToYouTL();
      }
    }
  }

  resetReceiveFromYouTrustLedgerItem(): void {
    if (this.matter && this.matter.isMatterProvinceABorMBorSK) {
      if (this.matter.hasExistingMortgageChargedAndPayoutEqualsBalance()) {
        this.resetReceivedFromYou();
      }
    }
  }

  updateF9ForTrustLedgerOfExistingDischargedMtg(setValueFromF9: boolean = false): void {
    if (this.matter && this.matter.isMatterProvinceABorMBorSK) {
      let trustLedgerMatter: TrustLedgerMatter = this.getMatterTrustLedgerToExistingMtgWithPayoutEqualsBalance();
      if (trustLedgerMatter) {
        const updatedAmt: number = this.calculateF9ValueForPaidToDischargedMtgWithPayoutEqualsBalance();
        trustLedgerMatter.f9DefaultAmount = updatedAmt;
        if (setValueFromF9) {
          trustLedgerMatter.itemValue = updatedAmt;
        }
        this.matter.updateValueForExistingDischargedMtgWithPayoutEqualsBalance(updatedAmt);
        this.resetValueAndRoForPayToYouTL();
      }
    }
  }

  makePayToYouTLEditable(): void {
    let payToYouMTL: TrustLedgerMatter = this.getMatterTrustLedgerPY();
    if (payToYouMTL) {
      payToYouMTL.readOnly = false;
    }
  }

  resetValueAndRoForPayToYouTL(): void {
    let payToYouMTL: TrustLedgerMatter = this.getMatterTrustLedgerPY();
    if (payToYouMTL) {
      payToYouMTL.itemValue = 0;
      payToYouMTL.f9DefaultAmount = 0;
      payToYouMTL.readOnly = true;
    }
  }

  updateF9ForPaidToYouAndOtherTrustLedgers(): void {
    //this method should called before updateF9ForPaidToYouAndReceivedFromYou, Only for SK, AB, MB when existing Mtg with PayoutEqualBalance
    this.resetPayToYouTrustLedgerItem();
    this.resetReceiveFromYouTrustLedgerItem();

    this.updateF9ForPaidToYouAndReceivedFromYou();

    //this method should called after updateF9ForPaidToYouAndReceivedFromYou, Only for SK, AB, MB when existing Mtg with PayoutEqualBalance
    this.updateF9ForTrustLedgerOfExistingDischargedMtg();
  }

  updateF9ForPaidToYouAndReceivedFromYou(): void {

    this.adjustmentAmountForF9 = 0;
    if (this.matter && !this.matter.autoInsertCashShortfall && this.matter.autoInsertAllF9Values) {
      if (this.receiptTotalWithoutPYRYCashShortFallItemized > this.expenditureTotalWithoutPYRYItemized) {
        this.resetReceivedFromYou(true);
        let matterTrustLedgerPY = this.getMatterTrustLedgerPY();
        let updatedValue: number = Number(Number(this.receiptTotalWithoutPYRYCashShortFallItemized) - Number(this.expenditureTotalWithoutPYRYItemized));
        this.adjustmentAmountForF9 = updatedValue;
        if (matterTrustLedgerPY) {
          matterTrustLedgerPY.itemValue = Utils.roundCurrencyExcelStyle((!matterTrustLedgerPY.readOnly && matterTrustLedgerPY.f9ReadOnly && !matterTrustLedgerPY.isItemized()) ? updatedValue : Number(matterTrustLedgerPY.itemValue));
          matterTrustLedgerPY.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
        }
      } else if (this.expenditureTotalWithoutPYRYItemized > this.receiptTotalWithoutPYRYCashShortFallItemized) {

        this.resetPaidToYou();
        let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
        let updatedValue: number = Number(Number(this.expenditureTotalWithoutPYRYItemized) - Number(this.receiptTotalWithoutPYRYCashShortFallItemized));
        this.adjustmentAmountForF9 = Number(updatedValue) * Number(-1);
        if (matterTrustLedgerRF) {
          matterTrustLedgerRF.itemValue = Utils.roundCurrencyExcelStyle((!matterTrustLedgerRF.readOnly && matterTrustLedgerRF.f9ReadOnly && !matterTrustLedgerRF.isItemized()) ? updatedValue : Number(matterTrustLedgerRF.itemValue));
          matterTrustLedgerRF.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
        }
      } else if (this.expenditureTotalWithoutPYRYItemized == this.receiptTotalWithoutPYRYCashShortFallItemized) {
        this.resetPaidToYou();
        if (this.matter && this.matter.isMatterProvinceABorMBorSK && this.matter.hasExistingMortgageChargedAndPayoutEqualsTrustLedgerBalance()) {
          this.resetReceivedFromYou(true);
        }
      }
    } else if (this.matter && this.matter.autoInsertCashShortfall && this.matter.autoInsertAllF9Values) {
      let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
      if (this.receiptTotalWithoutCashShortFall > this.expenditureTotalWithoutPYRYItemized) {
        let updatedValue: number = Number(Number(this.receiptTotalWithoutCashShortFall) - Number(this.expenditureTotalWithoutPYRYItemized));
        this.adjustmentAmountForF9 = updatedValue;
        if (matterTrustLedgerPY) {
          matterTrustLedgerPY.itemValue = Utils.roundCurrencyExcelStyle((!matterTrustLedgerPY.readOnly && matterTrustLedgerPY.f9ReadOnly && !matterTrustLedgerPY.isItemized()) ? updatedValue : Number(matterTrustLedgerPY.itemValue));
          matterTrustLedgerPY.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
        }
      } else if (this.expenditureTotalWithoutPYRYItemized > this.receiptTotalWithoutCashShortFall) {
        this.resetPaidToYou();
        if (this.isSaleOrSaleOpportunity && this.matter.isMatterProvinceABorMBorSK) {
          let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
          let updatedValue: number = Number(Number(this.expenditureTotalWithoutPYRYItemized) - Number(this.receiptTotalWithoutPYRYCashShortFallItemized));
          this.adjustmentAmountForF9 = Number(updatedValue) * Number(-1);
          if (matterTrustLedgerRF) {
            matterTrustLedgerRF.itemValue = Utils.roundCurrencyExcelStyle((!matterTrustLedgerRF.readOnly && matterTrustLedgerRF.f9ReadOnly && !matterTrustLedgerRF.isItemized()) ? updatedValue : Number(matterTrustLedgerRF.itemValue));
            matterTrustLedgerRF.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
          }
        }
      }
    } else if (this.matter && this.matter.autoInsertCashShortfall && !this.matter.autoInsertAllF9Values) {
      if (this.receiptTotalWithoutCashShortFall > this.expenditureTotal) {
        let matterTrustLedgerPY = this.getMatterTrustLedgerPY();
        let updatedValue = (Number(this.receiptTotalWithoutCashShortFall) - Number(this.expenditureTotalWithoutPYRY));
        this.adjustmentAmountForF9 = updatedValue;
        if (matterTrustLedgerPY) {
          matterTrustLedgerPY.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
        }
      }

    } else if (this.matter && !this.matter.autoInsertCashShortfall && !this.matter.autoInsertAllF9Values) {
      if (this.receiptTotal > this.expenditureTotal) {
        let matterTrustLedgerPY = this.getMatterTrustLedgerPY();
        let updatedValue = (Number(this.receiptTotal) - Number(this.expenditureTotalWithoutPYRY));
        this.adjustmentAmountForF9 = updatedValue;
        if (matterTrustLedgerPY) {
          matterTrustLedgerPY.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
        }
        let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
        if (matterTrustLedgerRF) {
          matterTrustLedgerRF.f9DefaultAmount = 0;
        }
      } else if (this.expenditureTotal > this.receiptTotal) {
        let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
        let updatedValue = (Number(this.expenditureTotal) - Number(this.receiptTotalWithoutPYRY));
        this.adjustmentAmountForF9 = Number(updatedValue) * Number(-1);
        if (matterTrustLedgerRF) {
          matterTrustLedgerRF.f9DefaultAmount = Utils.roundCurrencyExcelStyle((this.receiptTotal == this.expenditureTotal) ? 0 : updatedValue);
        }
        let matterTrustLedgerPY = this.getMatterTrustLedgerPY();
        if (matterTrustLedgerPY) {
          matterTrustLedgerPY.f9DefaultAmount = 0;
        }
      } else if (this.expenditureTotal == this.receiptTotal) {
        let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
        if (matterTrustLedgerRF) {
          matterTrustLedgerRF.f9DefaultAmount = 0;
        }
        let matterTrustLedgerPY = this.getMatterTrustLedgerPY();
        if (matterTrustLedgerPY) {
          matterTrustLedgerPY.f9DefaultAmount = 0;
        }
      }

    }

    this.updateCashShortFall();

  }

  updateTrustLedgerPurchaserNames(purchaserNames: string): void {
    let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF || matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.CUSTOM_FROM);
    let matterTrustLedgerPY = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_PY || matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.CUSTOM_TO);
    if (this.statementConfig && this.statementConfig.trustLedger && this.statementConfig.trustLedger.trustLedgerEntries && this.statementConfig.trustLedger.trustLedgersConfig) {
      let trustLedgerRF = this.statementConfig.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isReceivedFromYou() || trustLedger.isCustomReceivedFromYou());
      if (matterTrustLedgerRF && trustLedgerRF) {
        matterTrustLedgerRF.itemName = trustLedgerRF.expenseDescription + (purchaserNames ? ' - ' + purchaserNames : '');
      }

      let trustLedgerPY = this.statementConfig.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isPaidToYou() || trustLedger.isCustomPaidToYou());
      if (matterTrustLedgerPY && trustLedgerPY) {
        matterTrustLedgerPY.itemName = trustLedgerPY.expenseDescription + (purchaserNames ? ' - ' + purchaserNames : '');

      }
    }

  }

  updateTrustLedgerReceivedFromPurchaserNames(purchaserNames: string): void {
    let matterTrustLedgerRP = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RP);
    if (this.statementConfig && this.statementConfig.trustLedger && this.statementConfig.trustLedger.trustLedgerEntries && this.statementConfig.trustLedger.trustLedgersConfig) {
      let trustLedgerRP = this.statementConfig.trustLedger.getTrustLedgerList(this.matter).find(trustLedger => trustLedger.isReceivedFromPurchaserOnClosing());
      if (matterTrustLedgerRP && trustLedgerRP) {
        matterTrustLedgerRP.itemName = trustLedgerRP.expenseDescription + (purchaserNames ? ' - ' + purchaserNames : '');
        this.updateABProjectSaleReceivedFromPurchaserItemName();
      }
    }
  }

  updateABProjectSaleReceivedFromPurchaserItemName(): void {
    this.updateABProjectSaleReceivedFromPurchaserItemNameSingleView();
    if (this.matter && this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.updateABProjectSaleReceivedFromPurchaserItemNameSingleView();
      this.progressionStatus = progressStatus;
    }
  }

  updateABProjectSaleReceivedFromPurchaserItemNameSingleView(): void {
    let matterTrustLedgerRP = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RP);
    if (matterTrustLedgerRP && this.matter && this.matter.isMatterProvinceAB && this.matter.isProjectSale) {
      matterTrustLedgerRP.itemName = 'Cash to Close ' + SoaConstants.AS_PER_SOA;
      if (this.matter.newMortgages && this.matter.newMortgages.length) {
        if (this.matter.newMortgages.every((mortgage: Mortgage) => !mortgage.isLoanTypeBackToVendor())) {
          matterTrustLedgerRP.itemName = 'Cash Difference ' + SoaConstants.AS_PER_SOA;
        }
      }
    }
  }

  updateTrustLedgerPrivateLenderNames(mortgageIndex: number, purchaserNames: string, isArranged: boolean, isBridgeFinance: boolean): void {
    this.updateTrustLedgerPrivateLenderNamesSingleView(mortgageIndex, purchaserNames, isArranged, isBridgeFinance);
    if (this.matter.isProjectConfigDocForTrustLedger()) {
      let progressStatus = this.progressionStatus;
      this.progressionStatus = progressStatus == ProgressionStatus.FINAL ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      this.updateTrustLedgerPrivateLenderNamesSingleView(mortgageIndex, purchaserNames, isArranged, isBridgeFinance);
      this.progressionStatus = progressStatus;
    }
  }

  updateTrustLedgerPrivateLenderNamesSingleView(mortgageIndex: number, purchaserNames: string, isArranged: boolean, isBridgeFinance: boolean): void {
    let matterTrustLedgerMG = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedgerMG) {
      if (isArranged || this.canAddReceivedFromMortgageeRow(this.matter.mortgages[ mortgageIndex ])) {
        if (this.isMortgageOrMortgageOpportunity) {
          if (purchaserNames) {
            matterTrustLedgerMG.itemName
              = SoaConstants.RECEIVED_NEW_ARRANGED_FINANCING.replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)) + '') + (' - ' + purchaserNames);
          } else {
            matterTrustLedgerMG.itemName
              = SoaConstants.RECEIVED_NEW_ARRANGED_FINANCING_M_GE.replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)) + '');
          }
        } else {
          if (purchaserNames) {
            if (this.canAddReceivedFromMortgageeRow(this.matter.mortgages[ mortgageIndex ])) {
              matterTrustLedgerMG.itemName
                = SoaConstants.RECEIVED_FROM_PURCHASER_MORTGAGEE
              .replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex))) + (' - ' + purchaserNames);
            } else {
              matterTrustLedgerMG.itemName
                = SoaConstants.RECEIVED_ARRANGED_FINANCING.replace('###', Utils.getOrdinal(mortgageIndex + 1)) + (' - ' + purchaserNames);
            }
          } else {
            if (this.canAddReceivedFromMortgageeRow(this.matter.mortgages[ mortgageIndex ])) {
              matterTrustLedgerMG.itemName
                = SoaConstants.RECEIVED_FROM_PURCHASER_MORTGAGE.replace('###', Utils.getOrdinal(this.matter.getMortgagePriorityByMortgageIndex(mortgageIndex)));
            } else {
              matterTrustLedgerMG.itemName
                = SoaConstants.RECEIVED_ARRANGED_FINANCING_M_GE.replace('###', Utils.getOrdinal(mortgageIndex + 1));
            }

          }
        }

      } else if (isBridgeFinance) {
        if (this.isPurchaseOrPurchaseOpportunity) {
          matterTrustLedgerMG.itemName = SoaConstants.RECEIVED_BRIDGE_FINANCING + (purchaserNames ? ' - ' + purchaserNames : '');
        } else if (this.matter && this.isSaleOrSaleOpportunity) {
          matterTrustLedgerMG.itemName = SoaConstants.PAID_TO_BRIDGE_FINANCING + (purchaserNames ? ' - ' + purchaserNames : '');
        }

      }
    }
  }

  removeSeparatePaidToBrokerTrustLedger(): void {
    let pbTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB_SEPARATE);
    if (pbTrustLedgerIdx != -1) {
      this.matterTrustLedgers.splice(pbTrustLedgerIdx, 1);
    }
  }

  get isPaidToRealEstateBrokerRemovalRequired(): boolean {
    return this.matter && this.isSaleOrSaleOpportunity && this.matter.commissionPaidToVendorBrokerOnly
      && BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor === this.matter.getCoreCommissionPayableToVendorBrokerLabel();
  }

  get isPaidToRealEstateBrokerRestoreRequired(): boolean {
    if (!this.isPaidToRealEstateBrokerRemovalRequired) {
      let trustLedgerList = this.trustLedger.getTrustLedgerList(this.matter);
      let configIndex: number = -1;
      if (trustLedgerList) {
        configIndex = trustLedgerList.findIndex(item => item.isPaidToRealEstateBroker());
      }
      return configIndex != -1;
    }
    return false;
  }

  removePaidToRealEstateBroker(): void {
    let pbTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB);
    if (pbTrustLedgerIdx != -1) {
      this.matterTrustLedgers.splice(pbTrustLedgerIdx, 1);
    }
  }

  getPaidToRealEstateBroker(): TrustLedgerMatter {
    let matterTrustLedgerPB: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB);
    if (matterTrustLedgerPB) {
      return matterTrustLedgerPB;
    } else {
      if (this.isPaidToRealEstateBrokerRestoreRequired) {
        let rpTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_RP);
        let trustLedgerMatter = new TrustLedgerMatter();
        trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
        trustLedgerMatter.id = null;
        trustLedgerMatter.statementConfigurationId = this.statementConfiguration.id;
        trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_PB;
        trustLedgerMatter.itemName = '';
        trustLedgerMatter.itemValue = 0;
        trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
        trustLedgerMatter.itemizedMatterTrustLedgers = [];
        trustLedgerMatter.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
        trustLedgerMatter.itemizedMatterTrustLedgers = [];
        this.matterTrustLedgers.splice(rpTrustLedgerIdx + 1, 0, trustLedgerMatter);
        return trustLedgerMatter;
      }
    }
    return null;
  }

  getSeparatePaidToBrokerTrustLedger(): TrustLedgerMatter {
    let matterTrustLedgerPBSeparate: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB_SEPARATE);
    if (matterTrustLedgerPBSeparate) {
      return matterTrustLedgerPBSeparate;
    } else {
      let pbTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB);
      if (pbTrustLedgerIdx != -1) {
        let newPaidToBrokerTL: TrustLedgerMatter = new TrustLedgerMatter();
        newPaidToBrokerTL.progressionStatus = this.tlProgressionStatus;
        newPaidToBrokerTL.id = null;
        newPaidToBrokerTL.statementConfigurationId = this.statementConfiguration.id;
        newPaidToBrokerTL.itemKey = SoaTrustLedgerConfigKeys.L1_PB_SEPARATE;
        newPaidToBrokerTL.itemName = '';
        newPaidToBrokerTL.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
        newPaidToBrokerTL.itemValue = 0;
        newPaidToBrokerTL.f9DefaultAmount = 0;
        newPaidToBrokerTL.itemizedMatterTrustLedgers = [];
        newPaidToBrokerTL.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
        this.matterTrustLedgers.splice(pbTrustLedgerIdx + 1, 0, newPaidToBrokerTL);
        return newPaidToBrokerTL;
      }
    }
    return null;
  }

  removeReceivedDepositHeldInTrust() {
    let matterTrustLedgerIndex = _.findIndex(this.matterTrustLedgers, item => item.itemKey === SoaTrustLedgerConfigKeys.L1_RDHIT);
    if (matterTrustLedgerIndex >= 0) {
      this.matterTrustLedgers.splice(matterTrustLedgerIndex, 1);
    }
  }

  addNewReceivedDepositHeldInTrust(): TrustLedgerMatter {
    let matterTrustLedgerRDHIT = new TrustLedgerMatter();
    matterTrustLedgerRDHIT.progressionStatus = this.tlProgressionStatus;
    matterTrustLedgerRDHIT.id = null;
    matterTrustLedgerRDHIT.statementConfigurationId = this.statementConfiguration.id;
    matterTrustLedgerRDHIT.itemKey = SoaTrustLedgerConfigKeys.L1_RDHIT;
    matterTrustLedgerRDHIT.itemName = SoaConstants.RECEIVED_DEPOSIT_HELD_IN_TRUST;
    matterTrustLedgerRDHIT.itemType = SoaConstants.TRUST_LEDGER_RECEIPT;
    matterTrustLedgerRDHIT.readOnly = false;
    matterTrustLedgerRDHIT.itemValue = 0;
    matterTrustLedgerRDHIT.f9DefaultAmount = 0;
    matterTrustLedgerRDHIT.itemizedMatterTrustLedgers = [];
    //The new item will added above the Received from you for Purchase and
    // above the Received from purchaser on closing for Sale
    const anchorItemKey: string = (this.matter && this.isSaleOrSaleOpportunity) ? SoaTrustLedgerConfigKeys.L1_RP : SoaTrustLedgerConfigKeys.L1_RF;
    const anchorItemIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey == anchorItemKey);
    if (anchorItemIdx > -1) {
      this.matterTrustLedgers.splice(anchorItemIdx, 0, matterTrustLedgerRDHIT);
    } else {
      this.matterTrustLedgers.push(matterTrustLedgerRDHIT);
    }
    return matterTrustLedgerRDHIT;
  }

  updateTrustLedgerReceivedDepositHeldInTrust(): void {
    const needToPopulateReceivedDepositHeldInTrustTL: boolean = this.matter && this.matter.needToPopulateReceivedDepositHeldInTrustTL;
    const depositAmount: number = this.matter && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.depositAmount;

    if (needToPopulateReceivedDepositHeldInTrustTL && depositAmount > 0) {
      let matterTrustLedgerRDHIT: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_RDHIT);
      if (!matterTrustLedgerRDHIT) {
        matterTrustLedgerRDHIT = this.addNewReceivedDepositHeldInTrust();
      }
      matterTrustLedgerRDHIT.f9DefaultAmount = depositAmount;
      matterTrustLedgerRDHIT.itemizedMatterTrustLedgers = [];

      if (this.matter && this.matter.autoInsertAllF9Values) {
        matterTrustLedgerRDHIT.itemValue = depositAmount;
      }
      if (Array.isArray(this.matter.matterPropertyWithCondo.deposits)) {
        const deposits: Deposit[] = this.matter.matterPropertyWithCondo.deposits.filter(deposit => deposit.depositName && deposit.depositName != DEPOSIT_PAID_ON_OCCUPANCY);
        if (deposits.length > 1) {//Multi Deposits => Itemized and itemValue populated
          deposits.forEach(deposit => {
            let trustLedgerItemize = new TrustLedgerItemize();
            trustLedgerItemize.itemName = deposit.depositName;
            trustLedgerItemize.itemAmount = Utils.toNumber(deposit.depositAmount);
            trustLedgerItemize.readOnly = true;
            matterTrustLedgerRDHIT.itemizedMatterTrustLedgers.push(trustLedgerItemize);
          });
          matterTrustLedgerRDHIT.itemValue = depositAmount;
        }
      }
    } else {
      //not show RDHIT item if f9 amount is 0
      this.removeReceivedDepositHeldInTrust();
    }
  }

  updateTrustLedgerPaidToRealEstateBroker(): void {
    //let matterTrustLedgerPB: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB);
    let matterTrustLedgerPB: TrustLedgerMatter = this.getPaidToRealEstateBroker();
    let matterTrustLedgerPBSeparate: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB_SEPARATE);
    if (matterTrustLedgerPB) {
      if (this.matter && this.isSaleOrSaleOpportunity) {
        matterTrustLedgerPB.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
        matterTrustLedgerPB.itemName = SoaConstants.PAID_TO_REAL_ESTATE_BROKER;
        let commissionPayableToVendorBrokerLabel: string = this.matter.getCoreCommissionPayableToVendorBrokerLabel();
        if (this.matter.commissionPaidToVendorBrokerOnly) {
          matterTrustLedgerPB.itemizedMatterTrustLedgers = [];
          // let commissionPayableToVendorBrokerLabel : string = BrokerCommissionPageLabels["Balance of Commission owing"];
          if (this.matter.realEstateBroker && this.matter.realEstateBroker.contact && this.matter.realEstateBroker.contact.organizationName) {
            matterTrustLedgerPB.itemName += ` - ${ this.matter.realEstateBroker.contact.organizationName }`;
          }
          matterTrustLedgerPB.f9DefaultAmount = 0;
          matterTrustLedgerPB.readOnly = false;
          this.removeSeparatePaidToBrokerTrustLedger();
          if (this.isPaidToRealEstateBrokerRemovalRequired) {
            this.removePaidToRealEstateBroker();
          }
        } else if (this.matter.commissionPaidToBothVendorAndPurchaserBroker) {
          let commissionPayableToPurchaserBrokerLabel: string = this.matter.getCoreCommissionPayableToPurchaserBrokerLabel();
          if (this.matter.separateEntriesForCommissionPaidToTrustLedger === DpBooleanValueTypes.NO) {
            this.removeSeparatePaidToBrokerTrustLedger();
            matterTrustLedgerPB.itemName = SoaConstants.PAID_REAL_ESTATE_BROKERS;
            matterTrustLedgerPB.itemizedMatterTrustLedgers = [];
            matterTrustLedgerPB.f9DefaultAmount = 0;
            if (commissionPayableToVendorBrokerLabel != BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor
              && (commissionPayableToPurchaserBrokerLabel != BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor
                || this.matter.insertExcessDepositIntoTrustLedger)) {
              let trustLedgerItemizeVendor = new TrustLedgerItemize();
              trustLedgerItemizeVendor.itemName = SoaConstants.PAID_TO_VENDOR_BROKER;
              if (this.matter.realEstateBroker && this.matter.realEstateBroker.contact && this.matter.realEstateBroker.contact.organizationName) {
                trustLedgerItemizeVendor.itemName += ` - ${ this.matter.realEstateBroker.contact.organizationName }`;
              }
              if (this.matter.brokerCommission && this.matter.brokerCommission.vendorCommission && this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker) {
                trustLedgerItemizeVendor.itemAmount = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
              } else {
                trustLedgerItemizeVendor.itemAmount = 0;
              }
              matterTrustLedgerPB.f9DefaultAmount += trustLedgerItemizeVendor.itemAmount;
              matterTrustLedgerPB.itemizedMatterTrustLedgers.push(trustLedgerItemizeVendor);
            }
            if (commissionPayableToPurchaserBrokerLabel != BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor && (commissionPayableToVendorBrokerLabel != BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor
              || this.matter.insertExcessDepositIntoTrustLedger)) {
              let trustLedgerItemizePurchaser = new TrustLedgerItemize();
              trustLedgerItemizePurchaser.itemName = SoaConstants.PAID_TO_PURCHASER_BROKER;
              if (this.matter.purchaserRealEstateBroker && this.matter.purchaserRealEstateBroker.contact && this.matter.purchaserRealEstateBroker.contact.organizationName) {
                trustLedgerItemizePurchaser.itemName += ` - ${ this.matter.purchaserRealEstateBroker.contact.organizationName }`;
              }
              if (this.matter.brokerCommission && this.matter.brokerCommission.purchaserCommission && this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker) {
                trustLedgerItemizePurchaser.itemAmount = this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
              } else {
                trustLedgerItemizePurchaser.itemAmount = 0;
              }
              matterTrustLedgerPB.f9DefaultAmount += trustLedgerItemizePurchaser.itemAmount;
              matterTrustLedgerPB.itemizedMatterTrustLedgers.push(trustLedgerItemizePurchaser);
            }
            if ((commissionPayableToVendorBrokerLabel == BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor
              || commissionPayableToPurchaserBrokerLabel == BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor) && this.matter.insertExcessDepositIntoTrustLedger) {
              this.addExcessDepositToItemized(matterTrustLedgerPB);
            }

            if (matterTrustLedgerPB.itemizedMatterTrustLedgers.length == 1) {
              //add blank itemize to make the itemize count >= 2 (if not 0)
              let placeHolderTrustLedgerItemize = new TrustLedgerItemize();
              placeHolderTrustLedgerItemize.itemAmount = 0;
              matterTrustLedgerPB.itemizedMatterTrustLedgers.push(placeHolderTrustLedgerItemize);
            }
          } else {// separate the vendor/purchaser broker in Trust Ledger
            matterTrustLedgerPB.readOnly = false;
            matterTrustLedgerPB.itemizedMatterTrustLedgers = [];
            matterTrustLedgerPB.f9DefaultAmount = 0;
            let hasVendorTrustLedger = false;
            if (commissionPayableToVendorBrokerLabel != BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor) {
              hasVendorTrustLedger = true;
              matterTrustLedgerPB.itemName = SoaConstants.PAID_TO_VENDOR_RE_BROKER;
              if (this.matter.realEstateBroker && this.matter.realEstateBroker.contact && this.matter.realEstateBroker.contact.organizationName) {
                matterTrustLedgerPB.itemName += ` - ${ this.matter.realEstateBroker.contact.organizationName }`;
              }
              if (this.matter.brokerCommission && this.matter.brokerCommission.vendorCommission && this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker) {
                matterTrustLedgerPB.f9DefaultAmount = Number(this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker);
              } else {
                matterTrustLedgerPB.f9DefaultAmount = 0;
              }
            }
            if (commissionPayableToPurchaserBrokerLabel !== BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor) {
              if (hasVendorTrustLedger) {
                let separateMatterTrustLedgerPB: TrustLedgerMatter = this.getSeparatePaidToBrokerTrustLedger();
                separateMatterTrustLedgerPB.itemizedMatterTrustLedgers = [];
                separateMatterTrustLedgerPB.itemName = SoaConstants.PAID_TO_PURCHASER_RE_BROKER;
                if (this.matter.purchaserRealEstateBroker && this.matter.purchaserRealEstateBroker.contact && this.matter.purchaserRealEstateBroker.contact.organizationName) {
                  separateMatterTrustLedgerPB.itemName += ` - ${ this.matter.purchaserRealEstateBroker.contact.organizationName }`;
                }
                if (this.matter.brokerCommission && this.matter.brokerCommission.purchaserCommission && this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker) {
                  separateMatterTrustLedgerPB.f9DefaultAmount = Number(this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker);
                } else {//should not be here, but just in case
                  separateMatterTrustLedgerPB.f9DefaultAmount = 0;
                }
              } else {
                this.removeSeparatePaidToBrokerTrustLedger();
                matterTrustLedgerPB.itemName = SoaConstants.PAID_TO_PURCHASER_RE_BROKER;
                if (this.matter.purchaserRealEstateBroker && this.matter.purchaserRealEstateBroker.contact && this.matter.purchaserRealEstateBroker.contact.organizationName) {
                  matterTrustLedgerPB.itemName += ` - ${ this.matter.purchaserRealEstateBroker.contact.organizationName }`;
                }
                if (this.matter.brokerCommission && this.matter.brokerCommission.purchaserCommission && this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker) {
                  matterTrustLedgerPB.f9DefaultAmount = Number(this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker);
                } else {//should not be here, but just in case
                  matterTrustLedgerPB.f9DefaultAmount = 0;
                }
              }

            } else {
              this.removeSeparatePaidToBrokerTrustLedger();
            }
          }
        }
      }
    }
  }

  updateExcessDepositInTrustLedger(doNotUpdateItemName?: boolean): void {
    if (this.matter && this.isSaleOrSaleOpportunity) {
      if (this.matter.commissionPaidToBothVendorAndPurchaserBroker || this.matter.commissionPaidToVendorBrokerOnly) {
        let CommissionPayableLabel: string = '';
        if (this.matter.commissionPaidToBothVendorAndPurchaserBroker) {
          if (this.matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER) {
            CommissionPayableLabel = this.matter.getCoreCommissionPayableToVendorBrokerLabel();
          } else if (this.matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER) {
            CommissionPayableLabel = this.matter.getCoreCommissionPayableToPurchaserBrokerLabel();
          }
        } else {
          CommissionPayableLabel = this.matter.getCoreCommissionPayableToVendorBrokerLabel();
        }

        if (CommissionPayableLabel == BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor && this.matter.insertExcessDepositIntoTrustLedger) {
          if (this.matter.separateEntriesForCommissionPaidToTrustLedger === DpBooleanValueTypes.NO) {
            this.removeExcessDepositTrustLedger();
          } else {
            let matterTrustLedgerExcessDeposit: TrustLedgerMatter = this.matterTrustLedgers.find(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_RECEIVED_EXCESS_DEPOSIT);
            if (matterTrustLedgerExcessDeposit) {
              this.updateExcessDepositTrustLedger(matterTrustLedgerExcessDeposit, undefined, doNotUpdateItemName);
            } else {
              this.addExcessDepositTrustLedger();
            }
          }
        } else {
          this.removeExcessDepositTrustLedger();
        }
      } else {
        this.removeExcessDepositTrustLedger();
      }

    }

  }

  addExcessDepositToItemized(trustLedgerMatter: TrustLedgerMatter): void {
    if (this.matter.insertExcessDepositIntoTrustLedger) {
      let trustLedgerItemized = new TrustLedgerItemize();
      this.updateExcessDepositTrustLedger(null, trustLedgerItemized);
      if (trustLedgerMatter && trustLedgerMatter.itemizedMatterTrustLedgers) {
        trustLedgerMatter.itemizedMatterTrustLedgers.unshift(trustLedgerItemized);
        trustLedgerMatter.itemValue += trustLedgerItemized.itemAmount;
        if (trustLedgerMatter.itemValue < 0) {
          trustLedgerMatter.itemName = SoaConstants.RECEIVED_REAL_ESTATE_BROKERS;
          trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_RECEIPT;
        }
      }
    }

  }

  addExcessDepositTrustLedger(): void {
    let trustLedgerMatter = this.createExcessDepositTrustLedger();
    this.updateExcessDepositTrustLedger(trustLedgerMatter);
    //Trying to find PAID_TO_REAL_ESTATE_BROKER entry in the Trust Ledger to insert it before
    let pbTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_PB);
    if (pbTrustLedgerIdx != -1) {
      this.matterTrustLedgers.splice(pbTrustLedgerIdx, 0, trustLedgerMatter);
    } else {
      // If No PAID_TO_REAL_ESTATE_BROKER entry found in the Trust Ledger found, It inserts it after RECEIVED_FROM_PURCHASER_ON_CLOSING
      let rpTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_RP);
      if (rpTrustLedgerIdx != -1) {
        this.matterTrustLedgers.splice(rpTrustLedgerIdx + 1, 0, trustLedgerMatter);
      }
    }
  }

  createExcessDepositTrustLedger(): TrustLedgerMatter {
    let trustLedgerMatter = new TrustLedgerMatter();
    trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
    trustLedgerMatter.id = null;
    trustLedgerMatter.statementConfigurationId = this.statementConfiguration.id;
    trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_RECEIVED_EXCESS_DEPOSIT;
    trustLedgerMatter.itemValue = 0;
    trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_RECEIPT;
    trustLedgerMatter.itemizedMatterTrustLedgers = [];
    trustLedgerMatter.f9ReadOnly = (this.matter && this.matter.autoInsertAllF9Values);
    return trustLedgerMatter;
  }

  updateExcessDepositTrustLedger(trustLedgerMatter: TrustLedgerMatter, trustLedgerItemize?: TrustLedgerItemize, doNotUpdateItemName?: boolean): void {
    let itemName = SoaConstants.RECEIVED_EXCESS_DEPOSIT;
    if (this.matter.commissionPaidToBothVendorAndPurchaserBroker) {
      if (this.matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER) {
        itemName += ` Vendor's Real Estate Broker`;
        if (trustLedgerMatter) {
          trustLedgerMatter.f9DefaultAmount = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
          if (this.matter.autoInsertAllF9Values) {
            trustLedgerMatter.itemValue = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
          }
        }
        if (trustLedgerItemize) {
          trustLedgerItemize.itemAmount = (-1) * this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
        }
        if (this.matter.realEstateBroker && this.matter.realEstateBroker.contact && this.matter.realEstateBroker.contact.organizationName) {
          itemName += ` - ${ this.matter.realEstateBroker.contact.organizationName }`;
        }

      }
      if (this.matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER) {
        itemName += ` Purchaser's Real Estate Broker`;
        if (trustLedgerMatter) {
          trustLedgerMatter.f9DefaultAmount = this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
          if (this.matter.autoInsertAllF9Values) {
            trustLedgerMatter.itemValue = this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
          }
        }
        if (trustLedgerItemize) {
          trustLedgerItemize.itemAmount = (-1) * this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
        }

        if (this.matter.purchaserRealEstateBroker && this.matter.purchaserRealEstateBroker.contact && this.matter.purchaserRealEstateBroker.contact.organizationName) {
          itemName += ` - ${ this.matter.purchaserRealEstateBroker.contact.organizationName }`;
        }
      }
    }
    if (this.matter.commissionPaidToVendorBrokerOnly) {
      itemName += ` Real Estate Broker`;
      if (trustLedgerMatter) {
        trustLedgerMatter.f9DefaultAmount = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
        if (this.matter.autoInsertAllF9Values) {
          trustLedgerMatter.itemValue = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
        }
      }
      if (trustLedgerItemize) {
        trustLedgerItemize.itemAmount = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
      }
      if (this.matter.realEstateBroker && this.matter.realEstateBroker.contact && this.matter.realEstateBroker.contact.organizationName) {
        itemName += ` - ${ this.matter.realEstateBroker.contact.organizationName }`;
      }
    }

    if (trustLedgerMatter && !doNotUpdateItemName) {
      trustLedgerMatter.itemName = itemName;
    }
    if (trustLedgerItemize && !doNotUpdateItemName) {
      trustLedgerItemize.itemName = itemName;
    }

  }

  removeExcessDepositTrustLedger(): void {
    let excessDepositTrustLedgerIdx: number = this.matterTrustLedgers.findIndex(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_RECEIVED_EXCESS_DEPOSIT);
    if (excessDepositTrustLedgerIdx != -1) {
      this.matterTrustLedgers.splice(excessDepositTrustLedgerIdx, 1);
    }
  }

  updateTrustLedgerExistingPrivateLenderNames(mortgageIndex: number, purchaserNames: string, isDischarged: boolean, isBridgeFinance: boolean, mortgage ?: Mortgage): void {
    let matterTrustLedgerPM = this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM && mtl.fromMortgageIndex == mortgageIndex);
    if (matterTrustLedgerPM && this.statementConfig && this.statementConfig.trustLedger && this.statementConfig.trustLedger.trustLedgerEntries) {
      if (isDischarged) {
        matterTrustLedgerPM.itemName = this.buildProvinceBasedItemName(mortgage, mortgageIndex) + (purchaserNames ? ' - ' + purchaserNames : '');
      } else if (isBridgeFinance) {
        if (this.isPurchaseOrPurchaseOpportunity) {
          matterTrustLedgerPM.itemName = SoaConstants.RECEIVED_BRIDGE_FINANCING + (purchaserNames ? ' - ' + purchaserNames : '');
        } else if (this.matter && this.isSaleOrSaleOpportunity) {
          matterTrustLedgerPM.itemName = SoaConstants.PAID_TO_BRIDGE_FINANCING + (purchaserNames ? ' - ' + purchaserNames : '');

        }

      }
    }
  }

  get isExpenditureTotalGreaterThanReceiptTotalWithoutCashShortFall(): boolean {
    return Utils.roundNumber(Number(this.expenditureTotal), 2) > Utils.roundNumber(Number(this.receiptTotalWithoutCashShortFall), 2);
  }

  updateCashShortFall(): void {
    let matterTrustLedgerCS = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.isCashShortFall);
    if (matterTrustLedgerCS) {
      if (this.isExpenditureTotalGreaterThanReceiptTotalWithoutCashShortFall) {
        matterTrustLedgerCS.itemValue = Utils.roundNumber(Number(Number(this.expenditureTotal) - Number(this.receiptTotalWithoutCashShortFall)), 2);
        //console.log("@@@ In updateCashShortFall(), expendTotal %s > receiptTotalWithoutCashShortFall %s, so ShortFall is %s", this.expenditureTotal, this.receiptTotalWithoutCashShortFall, matterTrustLedgerCS.itemValue)
      } else {
        this.removeCashShortFall();
      }
    } else if (this.matter && this.matter.autoInsertCashShortfall && this.isExpenditureTotalGreaterThanReceiptTotalWithoutCashShortFall) {
      this.addCashShortFall();
    } else {
      this.removeCashShortFall();
    }
  }

  private addCashShortFall(): void {
    let matterTrustLedgerRF = this.matterTrustLedgers.find(matterTrustLedger => matterTrustLedger.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
    if (matterTrustLedgerRF) {
      matterTrustLedgerRF.itemValue = 0;
      matterTrustLedgerRF.f9DefaultAmount = 0;
      matterTrustLedgerRF.itemizedMatterTrustLedgers = [];
    }
    let trustLedgerMatter = new TrustLedgerMatter();
    trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
    if (this.statementConfig && this.statementConfig.trustLedger && this.statementConfig.trustLedger.cashShortfall) {
      trustLedgerMatter.itemName = this.statementConfig.trustLedger.cashShortfall;
    } else {
      trustLedgerMatter.itemName = SoaConstants.CASH_SHORTFALL;
    }
    trustLedgerMatter.itemValue = Utils.roundNumber(Number(Number(this.expenditureTotal) - Number(this.receiptTotalWithoutCashShortFall)), 2);
    trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_RECEIPT;
    trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_CS;
    trustLedgerMatter.readOnly = true;
    this.matterTrustLedgers.push(trustLedgerMatter);
  }

  addOrRemoveCashShortFall(): void {

    if (this.matter && this.matter.autoInsertCashShortfall && this.isExpenditureTotalGreaterThanReceiptTotalWithoutCashShortFall) {
      this.addCashShortFall();
    } else {
      this.removeCashShortFall();
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  removeCashShortFall(): void {
    _.remove(this.matterTrustLedgers, function (trustLedgerMatter: TrustLedgerMatter) {
      return trustLedgerMatter.isCashShortFall;
    });
  }

  updateFeesLabelBasedOnDefaultProfile(): void {
    if (this.documentProfile) {

      this.updateTrustLedgerLegalFeeName();

      this.updateFeeLabelBasedOnDefaultProfile();
    }
  }

  resetSoaStructure(provinceHstRateSlab: ConsiderationTaxes): void {
    this.clearSoaStructure();
    this.createNewSoaStructure();
    this.addOrRemoveFeeForAllMortgages();

    this.addTrustLedgerMortgageRowForAllMortgages();
    this.addTrustLedgerHoldbacksRows();
    this.addOrRemoveMLTT();
    this.updateERegAndRegisterCharges();

    this.addOrRemoveCondoFee();

    // Common Settings
    //if (resetNRST) {
    this.addNRST();
    //}
    //if (resetEMP) {
    this.removeEMPFromSOA();
    if (this.matter && this.matter.mortgages.length > 0 && this.matter.isAnyMortgageEMP && this.soaConfig && this.soaConfig.id > 0 && this.statementConfig && this.statementConfig.isEMPForSoa()) {
      this.addEMPToSOA();
    }
    //}
    //if (resetTitleInsurance) {
    this.removeTitleInsuranceFromSOA();
    this.addOrRemoveTitleInsuranceSoaAndTrustLedger();
    //}

    this.removeTitleRegistrationFromSOA();//for reset or newMatter init
    this.removeMortgageRegistrationFromSOA();//for reset or newMatter init
    if (this.statementConfig && this.statementConfig.isTitleMortgageRegFeesForSoa()) {
      this.addOrUpdateTitleRegistrationToSOA();
      this.addOrUpdateMortgageRegistrationToSOA();
    }

    //if (resetLTT) {
    this.resetSoaTrustLedgeStructure(false);
    //}

    this.updateRetailTaxChattels(provinceHstRateSlab, true);
    this.addOrUpdateCompliance();

    // Update Transaction Fee Value
    if (this.feeCalculatedOnInclusivePriceSelection) {
      this.updateFeeBasedOnAllIncPrice();
    }
    this.updateF9DefaultSoa();
    this.updateF9DefaultGeneral();
    this.updateF9ForSoaAndTrustTitleInsurance();
    this.resetCondominiumStatusCertificate();
    this.populateAccountCodeArrayInDisbursements();
    this.setSoaAdditionalConfigAccountCode();
    this.updateF9ForTxnLevySurchargeItem();

  }

  // Only reset or create a new matter, we need set accountCode of Disbursement Additional Charges SOA as configAccountCode
  // In other situation, we need to keep the values which  was selected in UI side
  setSoaAdditionalConfigAccountCode() {
    const disbursmentsConfig = this.statementConfig && this.statementConfig.statementOfAccount && this.statementConfig.statementOfAccount.disbursementsConfig;
    if (disbursmentsConfig) {
      if (this.matter && this.matter.provinceCode == 'AB') {
        const purchaseDisbursmentsAdditional = this.disbursementsAdditional;
        let accountCodeAdditionalCharges = disbursmentsConfig.filter(config => config.matterType == this.matter.matterType &&
          config.subjectToHstType == 'ADDITIONAL');

        if (accountCodeAdditionalCharges && purchaseDisbursmentsAdditional) {
          purchaseDisbursmentsAdditional.forEach(item => {
            let disbursmentsConfig = accountCodeAdditionalCharges.find(disbursmentConfig => disbursmentConfig.code == item.itemKey);
            if (disbursmentsConfig && disbursmentsConfig.configAccountCode) {
              item.accountCode = disbursmentsConfig.configAccountCode;
            }

          });
        }
      }
    }
  }

  populateAccountCodeArrayInOtherCharges() {
    this.taxableOtherChargesGST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
    this.taxableOtherChargesPST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
    this.taxableOtherChargesGSTandPST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
    this.nonTaxableOtherCharges.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
  }

  populateAccountCodeArrayInDisbursements() {
    if (this.statementConfig && this.statementConfig.statementOfAccount) {
      this.combinedAccountCodeDataArr = this.statementConfig.pcLawCodesCombinedData;
      this.disbursementsHST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
      this.disbursementsNotHST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
      this.disbursementsAdditional.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
      this.disbursementsPST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));
      this.disbursementsGSTandPST.forEach(item => item.accountCodeArray = this.pushSelectedCodeInArr(item.accountCode));

      const disbursmentsConfig = this.statementConfig.statementOfAccount.disbursementsConfig;

      if (disbursmentsConfig != null) {
        let accountCodeSubjectToHST = disbursmentsConfig.find(config => this.matter && config.matterType == this.matter.matterType &&
          config.subjectToHstType == 'SUBJECT_TO_HST' && config.code == 'COMPLIANCE_ITEMS');
        let accountCodeSubjectToPST = disbursmentsConfig.find(config => this.matter && config.matterType == this.matter.matterType &&
          config.subjectToHstType == 'SUBJECT_TO_PST' && config.code == 'COMPLIANCE_ITEMS');
        let accountCodeNotSubjectToHST = disbursmentsConfig.find(config => this.matter && config.matterType == this.matter.matterType &&
          config.subjectToHstType == 'NOT_SUBJECT_TO_HST' && config.code == 'COMPLIANCE_ITEMS');

        if (accountCodeSubjectToHST && accountCodeSubjectToHST.configAccountCode) {
          this.disbursementsHST.filter(item => item.isCompliance()).map(item => item.accountCode = accountCodeSubjectToHST.configAccountCode);
        }
        if (accountCodeSubjectToPST && accountCodeSubjectToPST.configAccountCode) {
          this.disbursementsPST.filter(item => item.isCompliance()).map(item => item.accountCode = accountCodeSubjectToPST.configAccountCode);
        }
        if (accountCodeNotSubjectToHST && accountCodeNotSubjectToHST.configAccountCode) {
          this.disbursementsNotHST.filter(item => item.isCompliance()).map(item => item.accountCode = accountCodeNotSubjectToHST.configAccountCode);
        }

        // Only reset or create a new matter, we need set accountCode as configAccountCode
        // In other situation, we need to keep the values which  was selected in UI side

      }
    }
  }

  pushSelectedCodeInArr(selectedItemCode: string): any[] {
    let combinedArr: any[] = [];
    this.combinedAccountCodeDataArr.forEach(item => combinedArr.push(item));
    let codeIndex: number = _.findIndex(combinedArr, item => item.value === selectedItemCode);
    if (codeIndex === -1 && (selectedItemCode != null || selectedItemCode != '')) {
      combinedArr.push({'label': selectedItemCode, 'value': selectedItemCode});
    }
    return combinedArr;
  }

  resetCondominiumStatusCertificate(): void {
    const csc = this.disbursementsNotHST.find(item => item.itemName == SoaConstants.CONDO_STATUS_CERT_FEE_TEXT);
    if (!csc) {
      return;
    }
    const feeForCondoStatusCertificateF9Default = this.matter && this.matter.isMatterProvinceAB ?
      this.matter && this.matter.condoCorporationDocumentation && this.matter.condoCorporationDocumentation.costOfDocumentsOrdered :
      this.matter && this.matter.condoCorporation && this.matter.condoCorporation.contact && this.matter.condoCorporation.contact.feeForCondoStatusCertificate;
    csc.itemValue = feeForCondoStatusCertificateF9Default || 0;
    csc.f9DefaultAmount = feeForCondoStatusCertificateF9Default || 0;
  }

  updateTrustLedgeMortgageLinesName() {
    const matter = this.matter;
    if (matter && matter.mortgages && matter.mortgages.length > 0) {
      matter.mortgages.forEach(m => {
        m.isMortgageePrivateLender() ?
          matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(m) :
          matter.reCalculateTrustLedgerMortgageeLineByMortgage(m);
      });
    }
  }

  resetTrustLedgeStructure(): void {
    this.matterTrustLedgers = [];
    this.createNewTrustLedgerStructure();
    this.updateFeesLabelBasedOnDefaultProfile();
    this.addTrustLedgerMortgageRowForAllMortgages();
    this.addTrustLedgerHoldbacksRows();

    // Update Mortgage value if any
    if (this.matter.mortgages && this.matter.mortgages.length > 0) {

      this.matter.mortgages.forEach(m => {

        this.updateTrustLedgeMortgageValues(this.matter.getMortgageIndexById(m.id), m.mortgageTerm, m);

        m.isMortgageePrivateLender() ?
          this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(m) :
          this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(m);
        this.updateAutogeneratedMortgageBrokerFees(m);
        if (m.isEmpMortgage()) {
          const matterTrustLedger: TrustLedgerMatter
            = this.matterTrustLedgers.find(t => t.isMortgageFee && this.matter.getMortgageIndexById(m.id) == t.fromMortgageIndex);

          if (m.itemizeMortgageAdvanceInTrustAdvance == null) {
            m.itemizeMortgageAdvanceInTrustAdvance =
              m.stewartAssystMortgageInstruction &&
              m.stewartAssystMortgageInstruction.mortgageInstructionData &&
              m.stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance &&
              Number(m.stewartAssystMortgageInstruction.mortgageInstructionData.netAdvance) !=
              m.stewartAssystMortgageInstruction.mortgageInstructionData.principalAmount;
          }

          if (matterTrustLedger && m.itemizeMortgageAdvanceInTrustAdvance) {
            matterTrustLedger.itemizedMatterTrustLedgers = [];
            this.addEmpItemizedMatterTrustLedgers(m, matterTrustLedger);
          }
        }
      }, this);
    }
    // Update Existing Mortgage value if any
    if (Array.isArray(this.matter.mortgages)) {

      this.matter.existingMortgages.forEach(m => {
        m.isMortgageePrivateLender() ?
          this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(m) :
          this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(m);
      }, this);
    }
    if (this.matter.secondarySoaSheets) {
      this.matter.secondarySoaSheets.forEach(sheet => {
        this.addSecondarySheetTrustLedgerLegalFee(sheet.id);
      });
    }
    this.updateTrustLedgerPaidFees();
    if (this.matter.purchasers && this.matter.purchasers.length > 0) {
      this.matter.reCalculateTrustLedgerPurchaserLine();
    }
    if (this.matter) {
      this.matter.calculateStatementAdjustment();
    }
    if (this.matter.containsPayBillsOrDebtsTask) {
      this.updateSupplementalTaskExpenditures();
    }

    // Common Settings
    //if (resetNRST) {
    this.addNRST();
    //}
    //if (resetEMP) {
    this.removeAllEMPFromTrust();
    if (this.matter && this.matter.mortgages.length > 0 && this.matter.isAnyMortgageEMP && this.soaConfig && this.soaConfig.id > 0 && this.statementConfig && !this.statementConfig.isEMPForSoa()) {
      this.addEMPToTrust();
    }

    if (this.statementConfig && this.statementConfig.isTitleMortgageRegFeesForTrustLedger()) {
      this.updateTitleRegistrationToTrustForSkMb();
      this.updateMortgageRegistrationToTrustForSkMb();
    }

    //}
    //if (resetTitleInsurance) {
    this.removeTitleInsuranceFromTrust();
    this.addOrRemoveTitleInsuranceSoaAndTrustLedger();
    //}
    //if (resetLTT) {
    this.resetSoaTrustLedgeStructure(true);
    //}

    this.updateF9DefaultTrustLedger();
    this.updateF9DefaultGeneral();
    // if (resetNRST) {
    //     this.addNRST();
    // }
    // TL RESET FOR AUTO INSERT F9 VALUES
    this.updateF9TrustLedger();
    this.updateF9PaidToVendor();
    this.updateERegAndRegisterCharges();
  }

  resetSoaTrustLedgeStructure(isResetTL: boolean): void {
    // Sale and Mortgage matter don't include LTT
    if (this.matter && (this.isSaleOrSaleOpportunity || this.isMortgageOrMortgageOpportunity)) {
      return;
    }
    this.removeLTTFrom(isResetTL);
    this.addLTT();
    if (!this.matter.isMatterProvinceNS && this.matter.considerationLtt && ((isResetTL && this.statementConfig && !this.statementConfig.isLTTSetForSoa())
      || (!isResetTL && this.statementConfig && this.statementConfig.isLTTSetForSoa()))) {
      this.updateOntarioLtt();
      this.updateTorontoLtt();
      this.updateOntarioTorontoLtt();
      this.updateLttNB();
    }
  }

  private createTrustLedgerConfigIdMap(trustLedgerList: TrustLedgerConfig[]): any {
    const trustLedgerConfigIdMap = {};
    if (Array.isArray(trustLedgerList)) {
      trustLedgerList.forEach((trustLedgerConfig: TrustLedgerConfig, index: number) => {
        trustLedgerConfigIdMap[ trustLedgerConfig.id ] = index;
      });
    }
    return trustLedgerConfigIdMap;
  }

  // sortTrustLedgerMatter() {
  //     const trustLedgerList = this.trustLedger.getTrustLedgerList(this.matter);
  //     const trustLedgerConfigIdMap = this.createTrustLedgerConfigIdMap(trustLedgerList);
  //
  //     if(Array.isArray(this.matterTrustLedgers) && this.matterTrustLedgers.length > 0) {
  //         this.matterTrustLedgers.sort((a, b): number => {
  //             const posA : number = trustLedgerConfigIdMap[a.trustLedgerConfigId];
  //             const posB : number = trustLedgerConfigIdMap[b.trustLedgerConfigId];
  //             if(posA > posB) {
  //                 return 1;
  //             } else if (posA < posB) {
  //                 return -1;
  //             } else {
  //                 if (a.fromMortgageIndex > b.fromMortgageIndex) {
  //                     return 1;
  //                 } else if (a.fromMortgageIndex < b.fromMortgageIndex) {
  //                     return -1;
  //                 } else {
  //                     return 0;
  //                 }
  //             }
  //         });
  //     }
  // }

  getBrokerageFeeName(mortgagePriority: number, brokerName: string): string {
    return SoaConstants.BROKERAGE_FEES_TEXT.replace('*', Utils.getOrdinal(mortgagePriority)) + brokerName;
  }

  addMortgageBrokerFees(itemName: string, itemValue: number, fromMortgageIndex: number, autogenerated: boolean = false): void {

    let trustLedgerMatter = new TrustLedgerMatter();
    trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
    trustLedgerMatter.itemName = itemName;
    trustLedgerMatter.itemValue = Number(itemValue || 0);
    trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
    trustLedgerMatter.readOnly = false;
    trustLedgerMatter.f9ReadOnly = false;
    trustLedgerMatter.itemKey = TrustLedgerExpenditureTypes.BF;
    trustLedgerMatter.fromMortgageIndex = fromMortgageIndex;
    trustLedgerMatter.itemizedMatterTrustLedgers = [];
    if (autogenerated) {

      trustLedgerMatter.readOnly = true;
      trustLedgerMatter.itemKey = TrustLedgerExpenditureTypes.BF_A;
    }

    let otherBFIdx = this.matterTrustLedgers.findIndex(l => l.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE && (l.itemKey === TrustLedgerExpenditureTypes.BF_A || l.itemKey === TrustLedgerExpenditureTypes.BF));

    if (otherBFIdx < 0) {

      let paidToYouFeeIdx = this.matterTrustLedgers.findIndex(l => l.itemKey === SoaTrustLedgerConfigKeys.L1_LF);

      if (paidToYouFeeIdx > -1) {
        this.matterTrustLedgers.splice(paidToYouFeeIdx, 0, trustLedgerMatter);
      } else {
        this.matterTrustLedgers.push(trustLedgerMatter);
      }
    } else {

      otherBFIdx = this.matterTrustLedgers.findIndex(l => l.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE && (l.itemKey === TrustLedgerExpenditureTypes.BF_A || l.itemKey === TrustLedgerExpenditureTypes.BF) && l.fromMortgageIndex > fromMortgageIndex);
      if (otherBFIdx < 0) {
        otherBFIdx = _.findLastIndex(this.matterTrustLedgers, function (l: TrustLedgerMatter) {
          return l.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE && (l.itemKey === TrustLedgerExpenditureTypes.BF_A || l.itemKey === TrustLedgerExpenditureTypes.BF);
        });
        otherBFIdx++;
      }
      this.matterTrustLedgers.splice(otherBFIdx, 0, trustLedgerMatter);
    }

    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  updateAutogeneratedMortgageBrokerFees(mortgage: Mortgage) {
    if (mortgage && this.matter) {
      let remove: boolean = false;
      let brokerName = this.matter.getMortgageBrokerNameByMortgageId(mortgage.id);
      let mrtgIndex = this.matter.mortgages.findIndex(item => item.id == mortgage.id);
      let brokerFee = mortgage.brokerageFee;
      if (mortgage && mortgage.brokerageFee == 0 || brokerName.length == 0 || !mortgage.automaticallyUpdateBrokerageFeeTrustLedger) {
        remove = true;
      }
      let tlmIdx = this.matterTrustLedgers.findIndex(l => l.itemType == SoaConstants.TRUST_LEDGER_EXPENDITURE && l.fromMortgageIndex == mrtgIndex && l.itemKey == TrustLedgerExpenditureTypes.BF_A);

      if (remove) {
        if (tlmIdx >= 0) {
          this.matterTrustLedgers.splice(tlmIdx, 1);
        }
      } else {
        let itmName;
        if (mrtgIndex > -1) {
          itmName = this.getBrokerageFeeName(this.matter.mortgages[ mrtgIndex ].mortgagePriority, brokerName);
        }

        if (tlmIdx >= 0) {
          this.matterTrustLedgers[ tlmIdx ].itemName = itmName;
          this.matterTrustLedgers[ tlmIdx ].itemValue = brokerFee;
        } else {
          this.addMortgageBrokerFees(itmName, brokerFee, mrtgIndex, true);
        }
      }
    }
  }

  clearPropertyTaxPaidFromTrustAccountMatterTax() {
    _.remove(this.matterTrustLedgers, function (item) {
      return item.isPropertyTaxPaidFromTrustAccount && item.matterTaxId != undefined;
    });
  }

  clearItemFromPropertyTaxPaidFromTrustAccount(matterTaxId: number) {
    _.remove(this.matterTrustLedgers, function (item) {
      return item.isPropertyTaxPaidFromTrustAccount && item.matterTaxId == matterTaxId;
    });
  }

  // clearItemsFromPropertyTaxPaidFromTrustAccount() {
  //     let matterTaxByRollNumbers: RollNumberMatterTax[] = this.matter.matterTaxByRollNumbers();
  //     if (this.matter.matterPropertyWithCondo)  {
  //         matterTaxByRollNumbers.forEach(mt => {
  //             if(mt.matterTax) {
  //                 this.clearItemFromPropertyTaxPaidFromTrustAccount(mt.matterTax.id);
  //             }
  //         });
  //     } else if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.matterTax){
  //         this.clearItemFromPropertyTaxPaidFromTrustAccount( this.matter.matterPropertyWithCondo.matterTax.id);
  //     }
  // }

  addOrUpdatePropertyTaxPaidFromTrustAccount(itemValue: number, city: string, rollNumber: string, matterTaxId: number): void {
    if (itemValue > 0) {
      //add / update the paidTax Item
      let paidTaxItem = this.matterTrustLedgers.find(item => item.isPropertyTaxPaidFromTrustAccount && item.matterTaxId == matterTaxId);
      if (!paidTaxItem) {
        paidTaxItem = new TrustLedgerMatter();
        paidTaxItem.progressionStatus = this.tlProgressionStatus;
        //need to add the new created TaxPaid item above the legalFee item if found
        let legalFeeItemIdx = this.matterTrustLedgers.findIndex(item => item.itemKey === SoaTrustLedgerConfigKeys.L1_LF);
        if (legalFeeItemIdx > -1) {
          this.matterTrustLedgers.splice(legalFeeItemIdx, 0, paidTaxItem);
        } else {
          this.matterTrustLedgers.push(paidTaxItem);
        }
      }
      rollNumber = this.matter.isMatterProvinceON ? '' : rollNumber; //for ON matter, rollnumber should not be part of the TL name (in call cases: single/multi rows)
      paidTaxItem.itemName = [ 'Paid Tax Department', city, rollNumber ].filter(Boolean).join(', ');
      paidTaxItem.itemValue = itemValue;
      paidTaxItem.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
      paidTaxItem.itemKey = SoaTrustLedgerConfigKeys.L1_TAX_PAID_FROM_TRUST_ACCOUNT;
      paidTaxItem.readOnly = true;
      paidTaxItem.f9ReadOnly = false;
      paidTaxItem.matterTaxId = matterTaxId;
    } else {
      this.clearItemFromPropertyTaxPaidFromTrustAccount(matterTaxId);
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  updateSupplementalTaskExpenditures(): void {
    this.removeSupplemetalTaskExpenditures();
    if (this.matter.updateSupplemetalTaskExpenditureInTrustLedger && this.matter.containsPayBillsOrDebtsTask) {
      let payBillsOrDebts: MatterSupplementalTaskCategory[] = this.matter.supplementalTasks.filter(st => st.isPayBillOrDebt);
      payBillsOrDebts.forEach(supplementalTaskCategory => {
        let categoryFieldForValue = supplementalTaskCategory.categoryFields.find(categoryField => categoryField.fieldType === 'FEE_AMOUNT');
        let categoryFieldForLabel = supplementalTaskCategory.categoryFields.find(categoryField => categoryField.fieldType === 'DETAILS');
        const payeeName = supplementalTaskCategory.taskDisplayName;
        let itemName: string = 'Paid';
        if (categoryFieldForLabel && !Utils.isSpaceOnlyOrEmpty(categoryFieldForLabel.fieldValue)) {
          itemName += ` ${ categoryFieldForLabel.fieldValue.trim() }`;
        }
        if (!Utils.isSpaceOnlyOrEmpty(payeeName)) {
          itemName += ` to ${ payeeName.trim() }`;
        }
        let itemValue: string = categoryFieldForValue && categoryFieldForValue.fieldValue ? categoryFieldForValue.fieldValue : '0';
        this.addSupplemetalTaskExpenditures(itemName, itemValue, true);
      });
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
    this.updateF9ForPaidToYouAndReceivedFromYou();
  }

  removeSupplemetalTaskExpenditures(): void {
    this.matterTrustLedgers.filter(item => item.itemKey === TrustLedgerExpenditureTypes.SE_A).forEach(
      matterTrustLedger => {
        (<any>this.matterTrustLedgers).remove(matterTrustLedger);
      }
    );
  }

  addSupplemetalTaskExpenditures(itemName: string, itemValue: string, autogenerated: boolean = false): void {
    let trustLedgerMatter = new TrustLedgerMatter();
    trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
    trustLedgerMatter.itemName = itemName;
    trustLedgerMatter.itemValue = Number(itemValue);
    trustLedgerMatter.itemType = SoaConstants.TRUST_LEDGER_EXPENDITURE;
    trustLedgerMatter.readOnly = false;
    trustLedgerMatter.f9ReadOnly = false;
    trustLedgerMatter.itemKey = TrustLedgerExpenditureTypes.SE;
    trustLedgerMatter.itemizedMatterTrustLedgers = [];
    if (autogenerated) {
      trustLedgerMatter.readOnly = true;
      trustLedgerMatter.itemKey = TrustLedgerExpenditureTypes.SE_A;
    }

    let matterTrustLedgerLast = this.matterTrustLedgers.slice(0).reverse().find(l => l.itemType === SoaConstants.TRUST_LEDGER_EXPENDITURE && (l.itemKey === TrustLedgerExpenditureTypes.SE_A || l.itemKey === TrustLedgerExpenditureTypes.SE));
    let legalFeeMatterTrustLedgerIndex = this.matterTrustLedgers.findIndex(l => l.itemKey == SoaTrustLedgerConfigKeys.L1_LF);
    if (matterTrustLedgerLast) {
      let paidToYouFeeIdx = this.matterTrustLedgers.findIndex(l => l === matterTrustLedgerLast);
      if (paidToYouFeeIdx > -1) {
        this.matterTrustLedgers.splice(paidToYouFeeIdx, 0, trustLedgerMatter);
      } else {
        this.matterTrustLedgers.push(trustLedgerMatter);
      }
    } else if (legalFeeMatterTrustLedgerIndex > -1) {
      this.matterTrustLedgers.splice(legalFeeMatterTrustLedgerIndex, 0, trustLedgerMatter);
    } else {
      this.matterTrustLedgers.push(trustLedgerMatter);
    }
    this.updateF9ForPaidToYouAndOtherTrustLedgers();
  }

  getF9DefaultAmntRegTransfAB(soaFeeRate: SoaFeeRate): number {
    if (this.matter && this.matter.isMatterProvinceAB) {
      let purchasePrice: number = this.matter.matterProperties[ 0 ] ? Utils.ceiling(Number(this.matter.matterProperties[ 0 ].purchasePrice), 5000) : 0; // round up to next 5000
      return Number(soaFeeRate.fees)
        + Number(purchasePrice) * soaFeeRate.salePriceFactor
        + Number((this.matter.numberOfParcels - 1) * soaFeeRate.extraParcelFactor);
    }
    return 0;
  }

  /*
    updates F9 Default Amounts for both Statement of Account and Trust Ledger for these items:
        - ERegServiceCharge
        - RegistrationOfCharge
        - RegistrationOfTransfer
        - RegistrationOfVTBMortgage
        - RegistrationOfMOrtgageDischarge
     */
  updateERegAndRegisterCharges(doNotUpdateItemValue?: boolean): void {

    this.updateF9DefaultSoa();
    if (this.soaFeesRates) {

      const eRegCharges: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY);
      const defaultRegCharges: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
      const defaultRegTransfer: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
      //const eRegCharges: SoaFeeRate = this.soaFeesRates.find(item => item.isERegServiceCharge());
      //const defaultRegCharges: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationCharge());
      //const defaultRegTransfer: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationTransfer());

      // update Statement of Account items
      if (this.disbursementsHST && this.statementConfig && !this.statementConfig.isELRSAFeesTypeManual()) {
        let soaMatterEReg = this.disbursementsHST.find(item => item.isERegServiceCharge());
        if (soaMatterEReg && eRegCharges) {
          soaMatterEReg.f9DefaultAmount = this.getF9DefaultValueForEReg();
        }
        this.disbursementsNotHST
        .filter(item => item.isRegistrationOfCharge() || item.isRegistrationTransfer() || item.isRegistrationOfVTBMortgage() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfMortgage() || item.isRegistrationDischargeOfOther)
        .forEach(item => {

          if (this.matter && !this.matter.isProjectSale && !this.matter.isOpportunityMatter()) {
            // registrationCharge and registrationTransfer's F9 function still NA for PS matter
            if (item.isRegistrationOfCharge() && defaultRegCharges) {
              item.f9DefaultAmount = this.getF9DefaultValueForRegistrationCharge();
              if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !doNotUpdateItemValue && !item.userOverriddenValue) {
                item.itemValue = item.f9DefaultAmount;
              }
            } else if (item.isRegistrationTransfer() && defaultRegTransfer) {
              item.f9DefaultAmount = this.getF9DefaultValueForRegistrationTransfer();
              if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !doNotUpdateItemValue && !item.userOverriddenValue) {
                item.itemValue = item.f9DefaultAmount;
              }
            }
          }
          // the F9 function of the following Soa types are also enabled for Project Sale matter, for non-PS matter, this.isSoaInterimMode always return false
          if (item.isRegistrationOfVTBMortgage() && defaultRegCharges) {
            item.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfVTBMortgage();
            if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !doNotUpdateItemValue && !item.userOverriddenValue) {
              item.itemValue = item.f9DefaultAmount;
            }
          } else if (item.isRegistrationOfMortgageDischarge()) {
            item.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfMortgageDischarge();
            if (this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !doNotUpdateItemValue && !item.userOverriddenValue) {
              item.itemValue = item.f9DefaultAmount;
            }
          } else if (item.isRegistrationOfMortgage() && this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !doNotUpdateItemValue && !item.userOverriddenValue) {
            item.itemValue = item.f9DefaultAmount;
          } else if (item.isRegistrationDischargeOfOther() && this.statementConfig.isTitleMortgageRegFeesForSoaAuto() && !doNotUpdateItemValue && !item.userOverriddenValue) {
            item.itemValue = item.f9DefaultAmount;
          }
        });
      }

      if (this.matter && this.matter.isMatterProvinceMB) {
        this.updateF9TitleRegistrationFeeTrustLedgerMB(doNotUpdateItemValue);
      }

      if (this.matter && this.matter.isMatterProvinceNS) {
        // recordings for NS depend on number of mortgages and use the same calculations as F9 RegTransfer RegCharge
        this.matter.updateRecordingsForNS();
      }
      this.updateF9DefaultRegistrationValuesForTrustLedger();
    }
  }

  updateF9DefaultRegistrationValuesForTrustLedger() {
    if (this.statementConfiguration && this.statementConfiguration.isELRSAFeesTypeAutomaticallyCalculated()) {

      const eRegCharges: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY);
      const defaultRegCharges: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
      //const eRegCharges: SoaFeeRate = this.soaFeesRates.find(item => item.isERegServiceCharge());
      //const defaultRegCharges: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationCharge());
      const eRegChargeAmount: number = (this.matter && this.matter.isMatterProvinceON) && eRegCharges ? Number(Math.round((eRegCharges.fees * (1 + this.matter.matterHst / 100)) * 100) / 100) : 0;
      // update Trust Ledger items
      this.matterTrustLedgers
      .filter(mtl => mtl.isRegistrationOfCharge() || mtl.isRegistrationOfMortgage() || mtl.isRegistrationTransfer() || mtl.isRegistrationOfMortgageDischarge() || mtl.isRegistrationOfVTBMortgage() || mtl.isRegistrationDischargeOfOther())
      .forEach(mtl => {
        if (mtl.isRegistrationOfCharge()) {
          mtl.f9DefaultAmount = this.getF9DefaultValueForRegistrationCharge(eRegChargeAmount);
        }
        if (mtl.isRegistrationOfMortgage()) {
          mtl.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfMortgage(eRegChargeAmount);
        }
        if (mtl.isRegistrationTransfer()) {
          mtl.f9DefaultAmount = this.getF9DefaultValueForRegistrationTransfer(eRegChargeAmount);
        }
        if (mtl.isRegistrationOfMortgageDischarge()) {
          mtl.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfMortgageDischarge(eRegChargeAmount);
        }
        if (mtl.isRegistrationDischargeOfOther()) {
          mtl.f9DefaultAmount = this.getF9DefaultAmountForRegistrationDischargeOfOther(eRegChargeAmount);
        }
        if (mtl.isRegistrationOfVTBMortgage() && defaultRegCharges) {
          mtl.f9DefaultAmount = this.getF9DefaultValueForRegistrationOfVTBMortgage(eRegChargeAmount); // To Do not clear if for ON we still add the eRegCharge
        }

        if (((this.statementConfig && this.statementConfig.isTitleMortgageRegFeesForTrustLedgerAuto()) || (this.matter && this.matter.autoInsertAllF9Values)) && !mtl.isItemized() && !mtl.userOverriddenValue) {
          mtl.itemValue = mtl.f9DefaultAmount;
        }
      });
    }
  }

  getF9DefaultValueForRegistrationCharge(eRegChargeAmount: number = 0): number {
    if (this.soaFeesRates) {
      //if it is NB, get defaultRegCharges according to the value of "Registry or LT"
      const defaultRegCharges = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
      const registeredInstruments: number = this.matter ? this.matter.getRegisteredInstruments() : 0;
      let f9RegOfCharge: number = Number(((defaultRegCharges.fees + eRegChargeAmount) * registeredInstruments).toFixed(2));
      if (this.matter && this.matter.isMatterProvinceNB) {
        return f9RegOfCharge * this.getNumberOfValidPids();
      }
      return f9RegOfCharge;
    }
    return 0;
  }

  getDefaultChargesForSoaFeesRate(soaFeeRateKey: any): SoaFeeRate {
    return this.soaFeesRates.find(item => this.matter && this.matter.isMatterProvinceNB ?
      this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isRegistryOrLtIsLandTitle ?
        item.key == AccountingItemKeys[ soaFeeRateKey ] && item.landRegistryType == 'LAND_TITLE'
        : item.key == AccountingItemKeys[ soaFeeRateKey ] && item.landRegistryType == 'REGISTRY'
      : item.key == AccountingItemKeys[ soaFeeRateKey ]);
  }

  getF9DefaultValueForRegistrationTransfer(eRegChargeAmount: number = 0): number {
    if (this.soaFeesRates) {
      //let defaultRegTransfer: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationTransfer());
      let defaultRegTransfer: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
      if (this.matter && this.matter.isMatterProvinceON) {
        return defaultRegTransfer.fees + eRegChargeAmount;
      }
      if (this.matter && this.matter.isMatterProvinceAB) {
        return this.getF9DefaultAmntRegTransfAB(defaultRegTransfer);
      }
      if (this.matter && this.matter.isMatterProvinceNB) {
        //if it is NB, get defaultRegCharges according to the value of "Registry or LT"
        defaultRegTransfer = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY);
        return defaultRegTransfer.fees * this.getNumberOfValidPids();
      }
      if (this.matter && this.matter.isMatterProvinceNS) {
        return defaultRegTransfer.fees;
      }
      if (this.matter && this.matter.isMatterProvinceMB) {
        return defaultRegTransfer.fees;
      }
    }
    return 0;
  }

  getNumberOfValidPids(): number {

    if (this.matter && this.matter.isPropertyCondo() && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.condominiumExpenses) {
      return this.matter.matterPropertyWithCondo.condominiumExpenses.filter(prop => prop.pinNumber).length; // use this if you need to ignore empty PINs

    } else if (this.matter && !this.matter.isPropertyCondo() && this.matter.matterProperties) {
      return this.matter.matterProperties.length;
    }
    return 0;
  }

  getF9DefaultValueForRegistrationOfVTBMortgage(eRegChargeAmount: number = 0): number {
    if (this.isSoaInterimMode) {
      return 0;
    }
    const registeredVTBInstruments: number = this.matter ? this.matter.getRegisteredVTBInstruments() : 0;
    if (this.soaFeesRates) {
      //const defaultRegCharges: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationCharge());
      const defaultRegCharges: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
      return Number(((defaultRegCharges.fees + eRegChargeAmount) * registeredVTBInstruments).toFixed(2));
    }
    return 0;
  }

  getF9DefaultValueForRegistrationOfMortgage(eRegChargeAmount: number = 0): number {
    if (this.isSoaInterimMode) {
      return 0;
    }
    if (this.matter.isMatterProvinceAB) {
      return this.getF9DefaultValueForRegistrationOfMortgageForAlberta();
    }
    const registeredInstruments: number = this.matter ? this.matter.getRegisteredInstruments() : 0;
    if (this.soaFeesRates) {
      const defaultRegCharges: SoaFeeRate = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY);
      //const defaultRegCharges: SoaFeeRate = this.soaFeesRates.find(item => item.isRegistrationOfMortgage());
      return Number(((defaultRegCharges.fees + eRegChargeAmount) * registeredInstruments).toFixed(2));
    }
    return 0;
  }

  getF9DefaultValueForRegistrationOfMortgageDischarge(eRegChargeAmount: number = 0): number {
    if (this.isSoaInterimMode) {
      return 0;
    }
    if (this.soaFeesRates) {
      const saleDischargedInstruments: number = this.matter ? this.matter.getDischargedInstruments() : 0;
      //const defaultRegCharges = this.soaFeesRates.find(item => item.isRegistrationCharge());
      const defaultRegCharges = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY);
      const mrtgDischarge = this.soaFeesRates.find(item => item.isRegistrationOfMortgageDischarge());

      if (this.matter && this.matter.isMatterProvinceON && defaultRegCharges) {
        let saleDischargedInstrumentsFinance: number = this.matter ? this.matter.getDischargedInstrumentsByFinanceTypeAsMortgageOrCharge() : 0;
        return Number(((defaultRegCharges.fees + eRegChargeAmount) * saleDischargedInstrumentsFinance).toFixed(2));
      } else if (this.matter && this.matter.isMatterProvinceNS && defaultRegCharges) {
        //After confirm with BA, for NS  it should be mortgageDispositionType is DISCHARGED, financingType is 'Mortgage' and dischargeRegisteredBy is
        // "OUR_FIRM"
        let saleDischargedInstrumentsFinance: number = this.matter ? this.matter.getDischargedInstrumentsForFinanceAndRegisteredByOurFirm() : 0;
        return Number(((defaultRegCharges.fees + eRegChargeAmount) * saleDischargedInstrumentsFinance).toFixed(2));
      } else if (this.matter && this.matter.isMatterProvinceNB && defaultRegCharges) {
        //After confirm with BA, for NB  it should be mortgageDispositionType is DISCHARGED, financingType is 'Mortgage' and dischargeRegisteredBy is
        // "OUR_FIRM"
        let saleDischargedInstrumentsFinance: number = this.matter ? this.matter.getDischargedInstrumentsForFinanceAndRegisteredByOurFirm() : 0;
        return Number(((defaultRegCharges.fees + eRegChargeAmount) * saleDischargedInstrumentsFinance * this.getNumberOfValidPids()).toFixed(2));
      } else if (this.matter && this.matter.isMatterProvinceAB && mrtgDischarge) {
        // #ofDischarge * (Value$ + ( (#ofParcel-1) * Extra Parcel factor))
        let saleDischargedInstrumentsFinance: number = this.matter ? this.matter.getDischargedInstrumentsForFinanceMortgage() : 0;
        return Number((saleDischargedInstrumentsFinance * (mrtgDischarge.fees + (this.matter.numberOfParcels - 1) * mrtgDischarge.extraParcelFactor)).toFixed(2));
      } else if (this.matter && this.matter.isMatterProvinceMB && mrtgDischarge) {
        let existingMortgagesToBeDischarged: number = this.matter ? this.matter.getDischargedInstrumentsForFinanceMortgage() : 0;
        return Number(((mrtgDischarge.fees + eRegChargeAmount) * existingMortgagesToBeDischarged).toFixed(2));
      }
    }
    return 0;
  }

  getF9DefaultValueForEReg(): number {
    if (this.isSoaInterimMode) {
      return 0;
    }
    const registeredInstruments: number = this.matter ? this.matter.getRegisteredInstruments() : 0;
    const registeredVTBInstruments: number = this.matter ? this.matter.getRegisteredVTBInstruments() : 0;
    const saleDischargedInstruments: number = this.matter ? this.matter.getDischargedInstruments() : 0;
    let eRegCharges: SoaFeeRate;

    if (this.soaFeesRates) {
      //eRegCharges = this.soaFeesRates.find(item => item.isERegServiceCharge());
      eRegCharges = this.getDefaultChargesForSoaFeesRate(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY);

      if (this.disbursementsHST && this.statementConfig && !this.statementConfig.isELRSAFeesTypeManual()) {

        if (eRegCharges) {
          let eRegRegisteredInstruments = 0;
          if (this.matterType === 'PURCHASE') {
            eRegRegisteredInstruments = registeredInstruments + 1;
          } else if (this.matterType === 'SALE') {
            eRegRegisteredInstruments = registeredVTBInstruments + saleDischargedInstruments;
          } else if (this.matterType === 'MORTGAGE' || this.matterType === 'DISCHARGE') {
            eRegRegisteredInstruments = registeredVTBInstruments + saleDischargedInstruments;
          }
          return Number((eRegCharges.fees * eRegRegisteredInstruments).toFixed(2));
        }
      }
    }
    return 0;
  }

  clearStatementConfig(statementConfig: StatementConfig): StatementConfig {
    if (statementConfig) {
      statementConfig.id = null;
      if (statementConfig.trustLedger) {
        statementConfig.trustLedger.id = null;
        statementConfig.trustLedger.trustLedgersConfig.forEach(item => item.id = null);
      }
      if (statementConfig.statementOfAccount) {
        statementConfig.statementOfAccount.id = null;
        statementConfig.statementOfAccount.fees.forEach(item => item.id = null);
        statementConfig.statementOfAccount.disbursementsConfig.forEach(item => item.id = null);
      }
    }
    return statementConfig;
  }

  removeAllComplianceItems(): void {
    let complianceMatterNotHstSoas: SoaMatter[] = this.disbursementsNotHST.filter(item => item.isCompliance());
    complianceMatterNotHstSoas.forEach(item => {
      (<any>this.disbursementsNotHST).remove(item);
    }, this);

    let complianceMatterHstSoas: SoaMatter[] = this.disbursementsHST.filter(item => item.isCompliance());
    complianceMatterHstSoas.forEach(item => {
      (<any>this.disbursementsHST).remove(item);
    }, this);
  }

  addOrRemoveCompliance(compliance: Compliance): void {
    if (this.matter && this.matter.isProjectSale) {
      return;
    }
    let soaMatter = (this.isSubjectToHst(compliance) ? this.disbursementsHST : this.disbursementsNotHST).find(item => item.departmentPriority == compliance.departmentPriority);
    let soaHSTCompliancePurchase = this.soaConfig.getHSTCompliance();
    let soaNoHSTCompliancePurchase = this.soaConfig.getNoHSTCompliance();
    if (compliance.isWriteToYes() && !soaMatter) {
      // this.createCompliance(compliance, compliance.noHstApplied() ? soaNoHSTCompliancePurchase : soaHSTCompliancePurchase);
      this.createCompliance(compliance, this.isSubjectToHst(compliance) ? soaHSTCompliancePurchase : soaNoHSTCompliancePurchase);
    } else {
      // (<any>(compliance.noHstApplied() ? this.purchaseDisbursementsNotHST : this.purchaseDisbursementsHST)).remove(soaMatter);
      (<any>(this.isSubjectToHst(compliance) ? this.disbursementsHST : this.disbursementsNotHST)).remove(soaMatter);
    }
  }

  createCompliance(item: Compliance, soaPurchase: DisbursementConfig): void {
    if (item.isWriteToYes() && item.fee > 0 && soaPurchase) {

      let soaMatter = new SoaMatter();
      soaMatter.progressionStatus = this.soaProgressionStatus;
      soaMatter.disbursementConfigId = soaPurchase.id;
      soaMatter.itemName = item.departmentName;
      soaMatter.itemValue = 0;
      soaMatter.departmentPriority = item.departmentPriority;
      soaMatter.f9DefaultAmount = item.fee;
      // soaMatter.itemType = item.noHstApplied() ? 'DISBURSEMENT_NOT_SUBJECT_TO_HST' : 'DISBURSEMENT_SUBJECT_TO_HST';
      soaMatter.itemType = this.isSubjectToHst(item) ? 'DISBURSEMENT_SUBJECT_TO_HST' : 'DISBURSEMENT_NOT_SUBJECT_TO_HST';
      soaMatter.itemKey = soaPurchase.code;
      //the Compliance soa Description is always READ ONLY, since this SoaItem is compliance item (by soaMatter.isCompliance() )
      //the Compliance Soa Amount is always NOT READ ONLY for all the compliance item, default to 0.00 and with F9Amount populated, (by soaMatter.readOnly )
      soaMatter.readOnly = false;
      if (this.soaConfig && this.soaConfig.id > 0) {

        let selectedIndex = this.getNextComplianceIndex(item);
        if (selectedIndex > -1) {
          // item.noHstApplied() ? this.purchaseDisbursementsNotHST.splice(selectedIndex, 0, soaMatter) : this.purchaseDisbursementsHST.splice(selectedIndex, 0, soaMatter);
          this.isSubjectToHst(item) ? this.disbursementsHST.splice(selectedIndex, 0, soaMatter) : this.disbursementsNotHST.splice(selectedIndex, 0, soaMatter);
        } else {
          // item.noHstApplied() ? this.purchaseDisbursementsNotHST.push(soaMatter) : this.purchaseDisbursementsHST.push(soaMatter);
          this.isSubjectToHst(item) ? this.disbursementsHST.push(soaMatter) : this.disbursementsNotHST.push(soaMatter);
        }
      }

    }
  }

  updateF9DefaultAmountForCompliances(): void {
    if (this.matter) {
      if (this.matter.isMatterProvinceON) {
        if (this.matter.compliances) {
          //get the compliance that applicable for SOA
          let filteredCompliance: Compliance[] = this.matter.compliances.filter(compliance => compliance.isWriteToYes() && compliance.fee > 0);
          filteredCompliance && filteredCompliance.forEach(compliance => {
            this.updateF9DefaultAmountForCompliance(this.disbursementsHST, compliance);
            this.updateF9DefaultAmountForCompliance(this.disbursementsNotHST, compliance);
          });
        }
      } else {
        // ToDo for 17785
        if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.jurisdiction && this.matter.matterPropertyWithCondo.jurisdiction.id) {
          let filteredCompliance: Compliance[] = this.getComplianceFromJurisidction().filter(compliance => compliance.isWriteToYes() && compliance.fee > 0);
          if (filteredCompliance) {
            filteredCompliance.forEach(compliance => {
              this.updateF9DefaultAmountForCompliance(this.disbursementsHST, compliance);
              this.updateF9DefaultAmountForCompliance(this.disbursementsNotHST, compliance);
            });
          }
        }
      }
    }

  }

  updateF9DefaultAmountForCompliance(soaMatters: SoaMatter[], compliance: Compliance) {
    if (Array.isArray(soaMatters) && soaMatters.length > 0 && compliance) {
      let soaMatter: SoaMatter[] = soaMatters.filter(soa => {
        // soa.itemKey == "COMPLIANCE_ITEMS" && soa.itemName == compliance.departmentName && soa.departmentPriority == compliance.departmentPriority
        return soa.departmentPriority == compliance.departmentPriority;
      });
      if (Array.isArray(soaMatter) && soaMatter.length > 0) {
        soaMatter[ 0 ].f9DefaultAmount = compliance.fee;
      }
    }
  }

  addOrUpdateCompliance(): void {
    if (this.matter.isProjectSale) {
      return;
    }

    this.removeAllComplianceItems();
    let soaHSTCompliancePurchase = this.soaConfig.getHSTCompliance();
    let soaNoHSTCompliancePurchase = this.soaConfig.getNoHSTCompliance();
    if (this.matter && this.matter.isMatterProvinceON) { // compliance disbursements in ONTARIO are based on compliance items

      if (soaHSTCompliancePurchase && soaNoHSTCompliancePurchase && this.matter.matterCompliances &&
        this.matter.matterCompliances.length > 0) {
        this.matter.matterCompliances.forEach(item => {
          // this.createCompliance(item, item.noHstApplied() ? soaNoHSTCompliancePurchase : soaHSTCompliancePurchase);
          this.createCompliance(item, this.isSubjectToHst(item) ? soaHSTCompliancePurchase : soaNoHSTCompliancePurchase);
        });
      }
    }

    if (this.matter.isMatterProvinceABorMBorSKorNSorNB && Array.isArray(this.accountDepartments)) { // compliance disbursements in Alberta/SK/MB are based on account's departments

      this.getComplianceFromJurisidction().forEach(item => {
        this.createCompliance(item, this.isSubjectToHst(item) ? soaHSTCompliancePurchase : soaNoHSTCompliancePurchase);
      });
    }
  }

  getComplianceFromJurisidction(): Compliance[] {
    // ToDo 17785 do we need to map to matter's jurisdiction to get the departments and their fees ?
    return this.matter ? this.matter.jurisdictionDepartmentsComplianceMapping(this.accountDepartments) : [];
    //return [];
  }

  isSubjectToHst(matterCompliance: Compliance): boolean {
    if (this.soaConfig.isShowAllAsSubjectToHst) {
      return true;
    } else {
      return matterCompliance && !matterCompliance.noHstApplied();
    }
  }

  removeEmp(): void {
    let matterTrustLedgerEMP = this.matterTrustLedgers.find(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_EF);
    (<any>this.matterTrustLedgers).remove(matterTrustLedgerEMP);
    let purchaseDisbursementsEMP = this.disbursementsHST.find(item => item.itemKey == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ]);
    (<any>this.disbursementsHST).remove(purchaseDisbursementsEMP);

  }

  removeTI(): void {
    let matterTrustLedgerTI = this.matterTrustLedgers.find(item => item.itemKey == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ]);
    (<any>this.matterTrustLedgers).remove(matterTrustLedgerTI);
    let purchaseDisbursementsTI = this.disbursementsNotHST.find(item => item.itemKey == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ]);
    (<any>this.disbursementsNotHST).remove(purchaseDisbursementsTI);
  }

  getNextComplianceIndex(compliance: Compliance): number {
    let complianceIndex = -1;
    // if (compliance.noHstApplied()) {
    if (this.isSubjectToHst(compliance)) {
      let complianceDisbursement: SoaMatter = this.disbursementsHST.filter(purchaseDisbursementsHSTItem => purchaseDisbursementsHSTItem.isCompliance()).reverse().find(
        purchaseDisbursementItem => purchaseDisbursementItem.departmentPriority < compliance.departmentPriority
      );
      if (complianceDisbursement) {
        complianceIndex = Number(this.disbursementsHST.findIndex(item => item == complianceDisbursement)) + 1;
      } else {
        complianceIndex = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isCompliance());
      }
    } else {

      let complianceDisbursement: SoaMatter = this.disbursementsNotHST.filter(purchaseDisbursementsHSTItem => purchaseDisbursementsHSTItem.isCompliance()).reverse().find(
        purchaseDisbursementItem => purchaseDisbursementItem.departmentPriority < compliance.departmentPriority
      );
      if (complianceDisbursement) {
        complianceIndex = Number(this.disbursementsNotHST.findIndex(item => item == complianceDisbursement)) + 1;
      } else {
        complianceIndex = this.soaConfig.getDisbursementsConfig('NOT_SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isCompliance());
      }

    }
    return complianceIndex;
  }

  addNewTrustLedgerMortgageItems(trustLedgerMatter: TrustLedgerMatter): void {
    if (trustLedgerMatter) {
      let netAdvances: NetAdvance[] = this.getNetAdvance(trustLedgerMatter.fromMortgageIndex);
      if (trustLedgerMatter.isMortgageFee && netAdvances.length > 0) {

        let isSpecificAmountNetAdvance: boolean = this.isSpecificAmountNetAdvance(trustLedgerMatter.fromMortgageIndex);
        trustLedgerMatter.itemizedMatterTrustLedgers = [];
        let trustLedgerItemizeTL = new TrustLedgerItemize();
        trustLedgerItemizeTL.itemName = this.isItemizeDisable(trustLedgerMatter.fromMortgageIndex) ? 'Principal of Mortgage' : '';
        trustLedgerItemizeTL.itemAmount = this.getPrincipalMortgageValue(trustLedgerMatter.fromMortgageIndex);
        trustLedgerItemizeTL.readOnly = true;
        trustLedgerMatter.itemizedMatterTrustLedgers.push(trustLedgerItemizeTL);
        for (let i = 0; i < netAdvances.length; i++) {
          let trustLedgerItemize = new TrustLedgerItemize();
          trustLedgerItemize.itemName = netAdvances[ i ].name;
          trustLedgerItemize.itemAmount = netAdvances[ i ].amount;
          //trustLedgerItemize.identifier = -Date.now() + trustLedgerMatter.itemizedMatterTrustLedgers.length;
          trustLedgerItemize.readOnly = true;
          trustLedgerMatter.itemizedMatterTrustLedgers.push(trustLedgerItemize);
        }
        if (isSpecificAmountNetAdvance) {
          let trustLedgerItemize = new TrustLedgerItemize();
          trustLedgerItemize.itemName = 'Amount Not Advanced';
          trustLedgerItemize.itemAmount = Number(trustLedgerMatter.itemValue) - Number(trustLedgerMatter.itemizeTotal);
          //trustLedgerItemize.identifier = -Date.now() + trustLedgerMatter.itemizedMatterTrustLedgers.length;
          trustLedgerItemize.readOnly = true;
          trustLedgerMatter.itemizedMatterTrustLedgers.push(trustLedgerItemize);
        }
      } else {
        trustLedgerMatter.addNewTrustLedgerItems();
      }
    }
  }

  isItemizeDisable(mortgageIndex: number): boolean {
    return (this.matter && this.matter.mortgages && this.matter.mortgages.length > 0 && mortgageIndex >= 0 && this.matter.mortgages[ mortgageIndex ].mortgageTerm
      && (this.matter.mortgages[ mortgageIndex ].mortgageTerm.isCalculationMethodFSpecificAmount() || this.matter.mortgages[ mortgageIndex ].mortgageTerm.isCalculationMethodAdvance()));
  }

  getNetAdvance(mortgageIndex: number): NetAdvance[] {
    if (this.matter && this.matter.mortgages && this.matter.mortgages.length > 0
      && mortgageIndex >= 0 && this.matter.mortgages[ mortgageIndex ] &&
      this.matter.mortgages[ mortgageIndex ].mortgageTerm && this.matter.mortgages[ mortgageIndex ].mortgageTerm.netAdvanceItems &&
      (this.matter.mortgages[ mortgageIndex ].mortgageTerm.isCalculationMethodAdvance() || this.matter.mortgages[ mortgageIndex ].mortgageTerm.isCalculationMethodFSpecificAmount())) {
      return this.matter.mortgages[ mortgageIndex ].mortgageTerm.netAdvanceItems;
    } else {
      return [];
    }

  }

  isSpecificAmountNetAdvance(mortgageIndex: number): boolean {
    return (this.matter.mortgages && this.matter.mortgages.length > 0 && mortgageIndex >= 0 && this.matter.mortgages[ mortgageIndex ].mortgageTerm && this.matter.mortgages[ mortgageIndex ].mortgageTerm.netAdvanceItems &&
      this.matter.mortgages[ mortgageIndex ].mortgageTerm.isCalculationMethodFSpecificAmount());
  }

  getPrincipalMortgageValue(mortgageIndex): number {
    return this.matter && this.matter.mortgages && this.matter.mortgages.length > 0 && mortgageIndex >= 0 && this.matter.mortgages[ mortgageIndex ].mortgageTerm ? this.matter.mortgages[ mortgageIndex ].mortgageTerm.principal : 0;
  }

  getMatterTrustLedgerByMortgageIndex(mortgageIndex): TrustLedgerMatter {
    return this.matterTrustLedgers ? this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_MG && mtl.fromMortgageIndex == mortgageIndex) : undefined;

  }

  //used to temporarily associate the MatterTrustLedger with Mortgage through Identifier before order the mortgage
  populateMortgageIdentifierForMatterTrustLedger(mortgages: Mortgage[]): void {
    if (Array.isArray(this.matterTrustLedgers) && this.matterTrustLedgers.length > 0) {
      const mortgagesCnt = mortgages && mortgages.length;
      this.matterTrustLedgers.forEach(mtl => {
        const fromMtgIdx: number = mtl.fromMortgageIndex;
        if (fromMtgIdx <= mortgagesCnt) {
          mtl.fromMortgageIdentifier = mortgages[ fromMtgIdx ] && mortgages[ fromMtgIdx ].identifier;
        }
      });
    }
  }

  //update the fromMortgageIndex after the order the mortgages based on mortgageIdentifier
  //need to work pairly with this.populateMortgageIdentifierForMatterTrustLedger method
  refreshFromMortgageIndexForMatterTrustLedger(mortgages: Mortgage[]): void {
    if (Array.isArray(this.matterTrustLedgers) && this.matterTrustLedgers.length > 0) {
      const mortgagesCnt = mortgages && mortgages.length;
      this.matterTrustLedgers.filter(mtl => mtl.fromMortgageIndex != -1).forEach(mtl => {
        if (Array.isArray(mortgages) && mortgages.length > 0) {
          const mortgageIdx: number = mortgages.findIndex(m => m.identifier == mtl.fromMortgageIdentifier);
          console.log('>> MatterTrustLedger(%s)\'s fromMortgageIndex has been updated from %s to %s', mtl.id, mtl.fromMortgageIndex, mortgageIdx);
          mtl.fromMortgageIndex = mortgageIdx;
        }
      });
    }
  }

  // isEmpMorgageFeeTrustLedger(trustLedgerMatter : TrustLedgerMatter) {
  //     if(trustLedgerMatter && trustLedgerMatter.isMortgageFee
  //         && trustLedgerMatter.fromMortgageIndex < (Array.isArray(this.matter.mortgages) && this.matter.mortgages.length)) {
  //         if (this.matter.mortgages[trustLedgerMatter.fromMortgageIndex].isEmpMortgage()) {
  //             return true;
  //         }
  //     }
  //     return false;
  // }
  //
  // setEmpItemizedMatterTrustLedgers() {
  //     if(Array.isArray(this.matterTrustLedgers)) {
  //         this.matterTrustLedgers.forEach(item => {
  //             if(item && item.isMortgageFee
  //                 && item.fromMortgageIndex < (Array.isArray(this.matter.mortgages) && this.matter.mortgages.length)) {
  //                 if (this.matter.mortgages[item.fromMortgageIndex].isEmpMortgage()
  //                     && this.matter.mortgages[item.fromMortgageIndex].itemizeMortgageAdvanceInTrustAdvance ) {
  //                     this.addEmpItemizedMatterTrustLedgers(this.matter.mortgages[item.fromMortgageIndex], item);
  //                 }
  //             }
  //         });
  //     }
  // }

  addOrRemoveLateClosingInterestLCE(): void {
    let trustLedgerMatterIndex = this.matterTrustLedgers.findIndex(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_LCE);
    if (this.matter && this.matter.isMatterProvinceMB && this.matter.closingDatePayment && this.matter.closingDatePayment.isLateClosing()
      && this.matter.isLateClosingAllowed() && this.matter.closingDatePayment.lateClosingInterests && this.matter.closingDatePayment.lateClosingInterests.length > 0) {

      let trustLedgerMatter;
      if (trustLedgerMatterIndex > -1) {
        trustLedgerMatter = this.matterTrustLedgers[ trustLedgerMatterIndex ];
      } else {
        trustLedgerMatter = new TrustLedgerMatter();
        trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
      }
      trustLedgerMatter.itemName = SoaConstants.LABEL_LATE_CLOSING_INTEREST_ESTIMATE;
      const lastLateClosingInterest: LateClosingInterest = this.matter.closingDatePayment.lateClosingInterests[ this.matter.closingDatePayment.lateClosingInterests.length - 1 ];
      const lastLateInterestAmtValue: number = this.matter.getLastLateInterestAmt();
      trustLedgerMatter.itemName = trustLedgerMatter.itemName + ' (' + Utils.formattedCurrencyValue(lastLateInterestAmtValue) + ' at ' + lastLateClosingInterest.interestRate + '% for ' + this.matter.closingDatePayment.interestEstimateDays + ' days)';
      trustLedgerMatter.itemValue = this.matter.closingDatePayment.interestEstimateAmount;
      trustLedgerMatter.readOnly = true;
      trustLedgerMatter.f9ReadOnly = false;
      trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_LCE;
      trustLedgerMatter.itemizedMatterTrustLedgers = [];
      trustLedgerMatter.readOnly = true;
      trustLedgerMatter.itemType = this.matter && this.isSaleOrSaleOpportunity ? SoaConstants.TRUST_LEDGER_RECEIPT : SoaConstants.TRUST_LEDGER_EXPENDITURE;
      if (trustLedgerMatterIndex < 0) {
        if (this.isSaleOrSaleOpportunity) {

          //for Sale Matter, insert right after 'RECEIVED_FROM_PURCHASER_ON_CLOSING' OR at the end if not found (NA)
          let receivedFromPurchaserOnClosingIdx = this.matterTrustLedgers.findIndex(l => l.itemKey === SoaTrustLedgerConfigKeys.L1_RP);
          if (receivedFromPurchaserOnClosingIdx > -1) {
            this.matterTrustLedgers.splice(receivedFromPurchaserOnClosingIdx + 1, 0, trustLedgerMatter);
          } else {
            this.matterTrustLedgers.push(trustLedgerMatter);
          }
        } else {
          let paidToYouFeeIdx = this.matterTrustLedgers.findIndex(l => l.itemKey === SoaTrustLedgerConfigKeys.L1_PV);
          if (paidToYouFeeIdx > -1) {
            this.matterTrustLedgers.splice(paidToYouFeeIdx + 1, 0, trustLedgerMatter);
          } else {
            this.matterTrustLedgers.push(trustLedgerMatter);
          }
        }
      }
      this.updateF9ForPaidToYouAndOtherTrustLedgers();

    } else if (trustLedgerMatterIndex > -1) {
      let trustLedgerMatter = this.matterTrustLedgers[ trustLedgerMatterIndex ];
      (<any>this.matterTrustLedgers).remove(trustLedgerMatter);
    }
  }

  addOrRemoveLateClosingInterest(): void {
    let trustLedgerMatterIndex = this.matterTrustLedgers.findIndex(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_LC);
    if (this.matter && this.matter.isMatterProvinceABorMBorSK && this.matter.closingDatePayment && this.matter.closingDatePayment.isLateClosing()
      && this.matter.isLateClosingAllowed() && this.matter.closingDatePayment.isLateClosingDateValid()) {

      let trustLedgerMatter;
      if (trustLedgerMatterIndex > -1) {
        trustLedgerMatter = this.matterTrustLedgers[ trustLedgerMatterIndex ];
      } else {
        trustLedgerMatter = new TrustLedgerMatter();
        trustLedgerMatter.progressionStatus = this.tlProgressionStatus;
      }
      trustLedgerMatter.itemName = SoaConstants.LABEL_LATE_CLOSING_INTEREST;
      if (this.matter && this.isSaleOrSaleOpportunity) {
        trustLedgerMatter.itemName = trustLedgerMatter.itemName.replace('Paid', 'Received');
      }
      if (this.matter.closingDatePayment.lateClosingInterests) {
        if (this.matter.closingDatePayment.lateClosingInterests.length == 1) {
          let lateClosingInterest = this.matter.closingDatePayment.lateClosingInterests[ 0 ];
          let principalAmount = this.matter.closingDatePayment.getPrincipalAmount(lateClosingInterest);
          let interestRate = '0.000';
          if (lateClosingInterest.interestRate) {
            interestRate = Number(lateClosingInterest.interestRate).toFixed(3).replace(/00$/g, '0');
          }

          trustLedgerMatter.itemName = trustLedgerMatter.itemName + ' (' + Utils.formattedCurrencyValue(principalAmount) + ' at ' + interestRate + '% for ' + lateClosingInterest.getClosingDateDifference(true) + ')';
        } else if (this.matter.closingDatePayment.lateClosingInterests.length > 1) {
          trustLedgerMatter.itemName = trustLedgerMatter.itemName + ' for ' + this.matter.getMatterClosingLateClosingDateDifference();
        }
      }
      trustLedgerMatter.itemValue = this.matter.closingDatePayment.lateInterestAmountTotal;
      trustLedgerMatter.readOnly = true;
      trustLedgerMatter.f9ReadOnly = false;
      trustLedgerMatter.itemKey = SoaTrustLedgerConfigKeys.L1_LC;
      trustLedgerMatter.itemizedMatterTrustLedgers = [];
      trustLedgerMatter.readOnly = true;
      trustLedgerMatter.itemType = this.matter && this.isSaleOrSaleOpportunity ? SoaConstants.TRUST_LEDGER_RECEIPT : SoaConstants.TRUST_LEDGER_EXPENDITURE;
      if (trustLedgerMatterIndex < 0) {
        if (this.isSaleOrSaleOpportunity) {
          //for Sale Matter, insert right after 'RECEIVED_FROM_PURCHASER_ON_CLOSING' OR at the end if not found (NA)
          let receivedFromPurchaserOnClosingIdx = this.matterTrustLedgers.findIndex(l => l.itemKey === SoaTrustLedgerConfigKeys.L1_RP);
          if (receivedFromPurchaserOnClosingIdx > -1) {
            this.matterTrustLedgers.splice(receivedFromPurchaserOnClosingIdx + 1, 0, trustLedgerMatter);
          } else {
            this.matterTrustLedgers.push(trustLedgerMatter);
          }
        } else {
          //for Purchase and Mortgage Matter, insert right after "Paid To Vendor" OR at the end if not found
          let paidToYouFeeIdx = this.matterTrustLedgers.findIndex(l => l.itemKey === SoaTrustLedgerConfigKeys.L1_PV);

          if (paidToYouFeeIdx > -1) {
            this.matterTrustLedgers.splice(paidToYouFeeIdx + 1, 0, trustLedgerMatter);
          } else {
            this.matterTrustLedgers.push(trustLedgerMatter);
          }
        }
      }
      // In Case late closing is applicable we will remove late closing estimate
      let trustLedgerMatterIndexLCE = this.matterTrustLedgers.findIndex(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_LCE);
      if (trustLedgerMatterIndexLCE > -1) {
        let trustLedgerMatterLCE = this.matterTrustLedgers[ trustLedgerMatterIndexLCE ];
        (<any>this.matterTrustLedgers).remove(trustLedgerMatterLCE);
      }
      this.updateF9ForPaidToYouAndOtherTrustLedgers();

    } else if (trustLedgerMatterIndex > -1) {
      // In case where late closing is not applicable then we remove late closing and check if last closing estimate is applicable
      let trustLedgerMatter = this.matterTrustLedgers[ trustLedgerMatterIndex ];
      (<any>this.matterTrustLedgers).remove(trustLedgerMatter);
      this.addOrRemoveLateClosingInterestLCE();
    } else {
      // In case where late closing is not available then we check if last closing estimate is applicable
      this.addOrRemoveLateClosingInterestLCE();
    }

  }

  existDisbursementsWithAmountAndGLCode(soaMatter?: SoaMatter[]): boolean {
    if (Array.isArray(soaMatter)) {
      return soaMatter.some(item => item.itemValue && Math.abs(item.itemValue) > 0 && item.accountCode && item.accountCode.trim() != '');
    }
    return this.existDisbursementsWithAmountAndGLCode(this.disbursementsHST) ||
      this.existDisbursementsWithAmountAndGLCode(this.disbursementsNotHST) ||
      this.existDisbursementsWithAmountAndGLCode(this.disbursementsAdditional) ||
      this.existDisbursementsWithAmountAndGLCode(this.disbursementsPST) ||
      this.existDisbursementsWithAmountAndGLCode(this.disbursementsGSTandPST);
  }

  getPaidToDischargeMortgage(mtgIdx: number): TrustLedgerMatter {
    if (mtgIdx && this.matterTrustLedgers) {
      return this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM
        && mtl.fromMortgageIndex == (mtgIdx - 1));
    }
    return null;
  }

  calculateF9ValueForPaidToDischargedMtgWithPayoutEqualsBalance(): number | undefined {
    let payToYouTL: TrustLedgerMatter = this.getMatterTrustLedgerPY();
    let matterTrustLedger: TrustLedgerMatter = this.getMatterTrustLedgerToExistingMtgWithPayoutEqualsBalance();
    if (matterTrustLedger && payToYouTL) {
      const value: number = Number(matterTrustLedger.itemValue || 0) + Number(this.adjustmentAmountForF9 || 0);
      this.adjustmentAmountForF9 = 0;
      return value < 0 ? 0 : value;
      /*
                        if(payToYouTL.f9DefaultAmount > 0){
                            return Number(payToYouTL.f9DefaultAmount) + Number(matterTrustLedger.itemValue);
                        }else{
                            const value : number = Number(matterTrustLedger.itemValue) + Number(this.adjustmentAmount);
                            return value < 0 ? 0 : value;
                        }
            */
    }
    return 0;
  }

  getMatterTrustLedgerToExistingMtgWithPayoutEqualsBalance(): TrustLedgerMatter {
    if (this.matter.hasExistingMortgageChargedAndPayoutEqualsTrustLedgerBalance()) {
      const mtgIdx: number = this.matter.getMtgIdxForExistingDischargedMtgWithPayoutEqualsBalance();
      return this.getMatterTrustLedgerItemForMortgage(mtgIdx);
    }
    return null;
  }

  //get Pay To You Trust Ledger Item
  getMatterTrustLedgerPY(): TrustLedgerMatter {
    return this.getMatterTrustLedgerItem(SoaTrustLedgerConfigKeys.L1_PY);
  }

  //get Receive From You Trust Ledger Item
  getMatterTrustLedgerRF(): TrustLedgerMatter {
    return this.getMatterTrustLedgerItem(SoaTrustLedgerConfigKeys.L1_RF);
  }

  getMatterTrustLedgerItemForMortgage(mtgIdx: number): TrustLedgerMatter {
    if (mtgIdx && mtgIdx >= 0) {
      return this.matterTrustLedgers.find(mtl => mtl.itemKey == SoaTrustLedgerConfigKeys.L1_PM
        && mtl.fromMortgageIndex == (mtgIdx - 1));
    }
    return null;
  }

  getMatterTrustLedgerItemForDischargedExistingMortgage(mortgage: Mortgage): TrustLedgerMatter {
    const mtgIdx: number = mortgage ? this.matter.getExistingMortgageIndex(mortgage) : -1;
    return this.getMatterTrustLedgerItemForMortgage(mtgIdx);
  }

  //update value from mortgage's amountPayableToDischarge
  updateF9ValueForCorrespondingTL(mortgage: Mortgage, updateItemValue: boolean = false): void {
    if (mortgage) {
      let mtl: TrustLedgerMatter = this.getMatterTrustLedgerItemForDischargedExistingMortgage(mortgage);
      if (mtl) {
        mtl.f9DefaultAmount = mortgage.amountPayableToDischarge;
        if (updateItemValue) {
          mtl.itemValue = mortgage.amountPayableToDischarge;
        }
      }
    }
  }

  getMatterTrustLedgerItem(trustLedgerKey: string): TrustLedgerMatter {
    if (trustLedgerKey) {
      if (Array.isArray(this.matterTrustLedgers) && this.matterTrustLedgers.length > 0) {
        return this.matterTrustLedgers.find(mtl => mtl.itemKey == trustLedgerKey);
      }
    }
    return null;
  }

  updatePropertyTaxPaidTrustLedger(): void {
    this.clearPropertyTaxPaidFromTrustAccountMatterTax();
    //this.clearItemsFromPropertyTaxPaidFromTrustAccount();
    let matterTaxByRollNumbers: RollNumberMatterTax[] = this.matter.matterTaxByRollNumbers();
    if (this.matter.matterPropertyWithCondo && this.hasMatterTaxOnRollNumbers) {
      // multiple roll numbers to add to trust ledger
      matterTaxByRollNumbers.forEach(mt => {
        if (mt.matterTax) {
          this.addOrUpdatePropertyTaxPaidFromTrustAccount(Number(mt.matterTax.amountPaidFromTrustAccount), this.matter.matterPropertyWithCondo.city ? this.matter.matterPropertyWithCondo.city : '', mt.rollNumber, mt.matterTax.id);
        }
      });
    } else if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.matterTax) {
      //DPPMP-33623, As per discussion with Edmund, for all Provinces, if there is only single row, then not includes the Roll number as part of the TL item name
      this.addOrUpdatePropertyTaxPaidFromTrustAccount(Number(this.matter.matterPropertyWithCondo.matterTax.amountPaidFromTrustAccount), this.matter.matterPropertyWithCondo.city ? this.matter.matterPropertyWithCondo.city : '', '', this.matter.matterPropertyWithCondo.matterTax.id);
    }
    this.createUpdatePropertyTaxPaidTrustLedgerStatementAdjustmentPS();
  }

  updatePropertyTaxPaidTrustLedgerStatementAdjustment(statementAdjustment: StatementAdjustment): void {
    let matterPropertyTax: MatterTax;
    if (this.matter.matterPropertyWithCondo && statementAdjustment && statementAdjustment.isOriginPropertyTaxes()) {
      matterPropertyTax = this.matter.matterPropertyWithCondo.matterTax;
    } else if (statementAdjustment && statementAdjustment.addedFlag && statementAdjustment.matterTax) {
      matterPropertyTax = statementAdjustment.matterTax;
    }

    if (this.matter && this.matter.matterPropertyWithCondo && matterPropertyTax) {
      //DPPMP-33623 for all provinces, if only create a single row TL, then no rollnumber should be included as part of the name
      this.addOrUpdatePropertyTaxPaidFromTrustAccount(Number(matterPropertyTax.amountPaidFromTrustAccount), this.matter.matterPropertyWithCondo.city ? this.matter.matterPropertyWithCondo.city : '', '', matterPropertyTax.id);
    }
  }

  createUpdatePropertyTaxPaidTrustLedgerStatementAdjustmentPS() {
    let statementAdjustments: StatementAdjustment [] = this.matter && this.matter.selectedProgressionStatus == ProgressionStatus.INTERIM ?
      this.matter.interimStatementAdjustments : this.matter.finalStatementAdjustments;
    this.matterTrustLedgers.filter(tl => tl.matterTaxId != undefined).forEach(tl => {
      let adjFound = statementAdjustments.filter(adj => !!adj.applyToAdjustmentRecord).find(adj => adj.matterTax && adj.matterTax.id == tl.matterTaxId);
      if (!adjFound) {
        (<any>this.matterTrustLedgers).remove(tl);
      }
    });
    statementAdjustments.filter(adj => adj.matterTax && !!adj.applyToAdjustmentRecord).forEach(adj => {
      let matterTrustLedger = this.matterTrustLedgers.find(tl => tl.matterTaxId == adj.matterTax.id);
      if (!matterTrustLedger) {
        this.updatePropertyTaxPaidTrustLedgerStatementAdjustment(adj);
      }
    });
  }

  updatePropertyTaxPaidTrustLedgerStatementAdjustmentManualEntries() {
    if (this.matter.statementOfAdjustments && this.matter.statementOfAdjustments.length > 0) {
      this.matter.statementOfAdjustments.forEach(stadj => {
        if (!this.matter.isProjectSale && stadj.matterTax && this.isPropertyTaxPaidStatementAdjustmentManualEntry(stadj)) {
          this.updatePropertyTaxPaidTrustLedgerStatementAdjustment(stadj);
        }
      });
    }
  }

  clearPropertyTaxPaidTrustLedgerStatementAdjustment(statementAdjustment: StatementAdjustment): void {
    if (this.matter && this.matter.matterPropertyWithCondo && statementAdjustment && statementAdjustment.matterTax && statementAdjustment.matterTax.id) {
      this.clearItemFromPropertyTaxPaidFromTrustAccount(statementAdjustment.matterTax.id);
      this.updateF9ForPaidToYouAndOtherTrustLedgers();
    }
  }

  isPropertyTaxPaidStatementAdjustmentManualEntry(statementAdjustment: StatementAdjustment): boolean {
    if (this.hasMatterTaxOnRollNumbers) {
      let matterTaxByRollNumbers: RollNumberMatterTax[] = this.matter.matterTaxByRollNumbers();
      let matterTaxByRollNumber: RollNumberMatterTax = matterTaxByRollNumbers.find(mr => mr.matterTax.id === statementAdjustment.matterTax.id);
      if (matterTaxByRollNumber) {
        return false;
      }
    }
    return true;
  }

  get hasMatterTaxOnRollNumbers(): boolean {
    let matterTaxByRollNumbers: RollNumberMatterTax[] = this.matter.matterTaxByRollNumbers();
    let hasMatterTax: boolean = false;
    if (matterTaxByRollNumbers && matterTaxByRollNumbers.length > 0) {
      matterTaxByRollNumbers.forEach(mt => {
        if (mt.matterTax) {
          hasMatterTax = true;
        }
      });
    }
    return hasMatterTax;
  }

  get soaProgressionStatus(): string {
    if (this.matter && this.matter.isProjectConfigDocForSoa()) {
      return this.progressionStatus;
    } else {
      return ProgressionStatus.FINAL;
    }
  }

  get tlProgressionStatus(): string {
    if (this.matter && this.matter.isProjectConfigDocForTrustLedger()) {
      return this.progressionStatus;
    } else {
      return ProgressionStatus.FINAL;
    }
  }

  get isSoaInterimMode(): boolean {
    return this.soaProgressionStatus == ProgressionStatus.INTERIM;
  }

  get isTlInterimMode(): boolean {
    return this.tlProgressionStatus == ProgressionStatus.INTERIM;
  }

  get fees(): SoaMatter[] {
    return this.isSoaInterimMode ? this._feesInterim : this._feesFinal;
  }

  set fees(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._feesInterim = soaMatter;
    } else {
      this._feesFinal = soaMatter;
    }
  }

  get allFees(): SoaMatter[] {
    return [ ...this._feesInterim, ...this._feesFinal ];
  }

  get disbursementsHST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._disbursementsHSTInterim : this._disbursementsHSTFinal;
  }

  set disbursementsHST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._disbursementsHSTInterim = soaMatter;
    } else {
      this._disbursementsHSTFinal = soaMatter;
    }
  }

  get allPurchaseDisbursementsHST(): SoaMatter[] {
    return [ ...this._disbursementsHSTInterim, ...this._disbursementsHSTFinal ];
  }

  get disbursementsAdditional(): SoaMatter[] {
    return this.isSoaInterimMode ? this._disbursementsAdditionalInterim : this._disbursementsAdditionalFinal;
  }

  set disbursementsAdditional(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._disbursementsAdditionalInterim = soaMatter;
    } else {
      this._disbursementsAdditionalFinal = soaMatter;
    }
  }

  get allPurchaseDisbursementsAdditional(): SoaMatter[] {
    return [ ...this._disbursementsAdditionalInterim, ...this._disbursementsAdditionalFinal ];
  }

  get disbursementsPST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._disbursementsPSTInterim : this._disbursementsPSTFinal;
  }

  set disbursementsPST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._disbursementsPSTInterim = soaMatter;
    } else {
      this._disbursementsPSTFinal = soaMatter;
    }
  }

  get allPurchaseDisbursementsPST(): SoaMatter[] {
    return [ ...this._disbursementsPSTInterim, ...this._disbursementsPSTFinal ];
  }

  get disbursementsGSTandPST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._disbursementsGSTandPSTInterim : this._disbursementsGSTandPSTFinal;
  }

  set disbursementsGSTandPST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._disbursementsGSTandPSTInterim = soaMatter;
    } else {
      this._disbursementsGSTandPSTFinal = soaMatter;
    }
  }

  get allPurchaseDisbursementsGSTandPST(): SoaMatter[] {
    return [ ...this._disbursementsGSTandPSTInterim, ...this._disbursementsGSTandPSTFinal ];
  }

  get disbursementsNotHST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._disbursementsNotHSTInterim : this._disbursementsNotHSTFinal;
  }

  set disbursementsNotHST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._disbursementsNotHSTInterim = soaMatter;
    } else {
      this._disbursementsNotHSTFinal = soaMatter;
    }
  }

  get taxableOtherChargesGST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._taxableOtherChargesGSTInterim : this._taxableOtherChargesGSTFinal;
  }

  set taxableOtherChargesGST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._taxableOtherChargesGSTInterim = soaMatter;
    } else {
      this._taxableOtherChargesGSTFinal = soaMatter;
    }
  }

  get allPurchaseTaxableOtherChargesGST(): SoaMatter[] {
    return [ ...this._taxableOtherChargesGSTInterim, ...this._taxableOtherChargesGSTFinal ];
  }

  get taxableOtherChargesPST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._taxableOtherChargesPSTInterim : this._taxableOtherChargesPSTFinal;
  }

  set taxableOtherChargesPST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._taxableOtherChargesPSTInterim = soaMatter;
    } else {
      this._taxableOtherChargesPSTFinal = soaMatter;
    }
  }

  get allPurchaseTaxableOtherChargesPST(): SoaMatter[] {
    return [ ...this._taxableOtherChargesPSTInterim, ...this._taxableOtherChargesPSTFinal ];
  }

  get taxableOtherChargesGSTandPST(): SoaMatter[] {
    return this.isSoaInterimMode ? this._taxableOtherChargesGSTandPSTInterim : this._taxableOtherChargesGSTandPSTFinal;
  }

  set taxableOtherChargesGSTandPST(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._taxableOtherChargesGSTandPSTInterim = soaMatter;
    } else {
      this._taxableOtherChargesGSTandPSTFinal = soaMatter;
    }
  }

  get allPurchaseTaxableOtherChargesGSTandPST(): SoaMatter[] {
    return [ ...this._taxableOtherChargesGSTandPSTInterim, ...this._taxableOtherChargesGSTandPSTFinal ];
  }

  get nonTaxableOtherCharges(): SoaMatter[] {
    return this.isSoaInterimMode ? this._nonTaxableOtherChargesInterim : this._nonTaxableOtherChargesFinal;
  }

  set nonTaxableOtherCharges(soaMatter: SoaMatter[]) {
    if (this.isSoaInterimMode) {
      this._nonTaxableOtherChargesInterim = soaMatter;
    } else {
      this._nonTaxableOtherChargesFinal = soaMatter;
    }
  }

  get allPurchaseNonTaxableOtherCharges(): SoaMatter[] {
    return [ ...this._nonTaxableOtherChargesInterim, ...this._nonTaxableOtherChargesFinal ];
  }

  get allPurchaseDisbursementsNotHST(): SoaMatter[] {
    return [ ...this._disbursementsNotHSTInterim, ...this._disbursementsNotHSTFinal ];
  }

  get matterTrustLedgers(): TrustLedgerMatter[] {
    return this.isTlInterimMode ? this._matterTrustLedgersInterim : this._matterTrustLedgersFinal;
  }

  set matterTrustLedgers(trustLedger: TrustLedgerMatter[]) {
    if (this.isTlInterimMode) {
      this._matterTrustLedgersInterim = trustLedger;
    } else {
      this._matterTrustLedgersFinal = trustLedger;
    }
  }

  get allMatterTrustLedgers(): TrustLedgerMatter[] {
    return [ ...this._matterTrustLedgersInterim, ...this._matterTrustLedgersFinal ];
  }

  get receivedOnAccount(): number {
    if (this.matter && this.matter.isProjectConfigDocForSoa()) {
      if (this.isProgressionStatusFinal) {
        return this.matter && this.matter.receivedOnAccount;
      } else {
        return this.matter && this.matter.receivedOnAccountInterim;
      }
    } else if (this.activeMatterSheet) {
      return this.activeMatterSheet.receivedOnAccount;
    }
    return this.matter && this.matter.receivedOnAccount;
  }

  set receivedOnAccount(amount: number) {
    if (this.matter) {
      if (this.matter.isProjectConfigDocForSoa()) {
        if (this.isProgressionStatusFinal) {
          this.matter.receivedOnAccount = amount;
        } else {
          this.matter.receivedOnAccountInterim = amount;
        }
      } else if (this.activeMatterSheet) {
        this.activeMatterSheet.receivedOnAccount = amount;
      } else {
        this.matter.receivedOnAccount = amount;
      }

    }
  }

  get isProgressionStatusFinal(): boolean {
    return this.progressionStatus == ProgressionStatus.FINAL;
  }

  get progressionStatus(): string {
    return this.matter ? this.matter.adjustmentStatusMode : ProgressionStatus.FINAL;
  }

  set progressionStatus(mode: string) {
    if (this.matter) {
      this.matter.adjustmentStatusMode = mode;
    }

  }

  insertUpdateAllF9 = (): void => {
    this.insertUpdateF9TrustLedgerValues();
    this.updateF9forTrustEmpMortgage(true);
    if (this.matter && this.matter.isMatterProvinceMB) {
      this.updateF9ManitobaLtt();
      this.updateF9TitleRegistrationFeeTrustLedgerMB();
      this.updateF9MortgageRegistrationFeeTrustLedgerMB();
    }
    if (this.matter && this.matter.isMatterProvinceNBorNS) {
      this.updateF9LttNSNB();
    }
    this.updateMortgageRegistrationToTrustForSkMb();
    this.updateTitleRegistrationToTrustForSkMb();
    this.updateItemizePrincipalForEmp();
    this.updateF9ForTrustLedgerOfExistingDischargedMtg(true);
    this.updateUserOverriddenFlagOnTL();
    this.updateERegAndRegisterCharges();
  };

  updateUserOverriddenFlagOnTL() {
    this.matterTrustLedgers.filter(item => item.isRegistrationOfCharge() || item.isRegistrationTransfer() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfVTBMortgage()
      || item.isRegistrationDischargeOfOther() || item.isRegistrationOfMortgage() || item.isPaidTitleRegistrationFee() || item.isPaidMortgageRegistrationFee())
    .forEach(mt => mt.userOverriddenValue = false);
  }

  updateUserOverriddenFlagOnSOA() {
    this.disbursementsNotHST.filter(item => item.isRegistrationOfCharge() || item.isRegistrationTransfer() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfVTBMortgage()
      || item.isRegistrationDischargeOfOther() || item.isRegistrationOfMortgage() || item.isPaidTitleRegistrationFee() || item.isPaidMortgageRegistrationFee())
    .forEach(item => item.userOverriddenValue = false);
  }

  reCalculateTrustLedgerPaidToRealEstateBrokerForInterimFinal(): void {
    this.execActionForInterimAndFinal(this.reCalculateTrustLedgerPaidToRealEstateBroker);
  }

  reCalculateTrustLedgerReceivedDepositHeldInTrustForInterimFinal(): void {
    this.execActionForInterimAndFinal(this.reCalculateTrustLedgerReceivedDepositHeldInTrust);
  }

  private reCalculateTrustLedgerReceivedDepositHeldInTrust = (): void => {
    this.updateTrustLedgerReceivedDepositHeldInTrust();
  };

  private reCalculateTrustLedgerPaidToRealEstateBroker = (): void => {
    this.updateTrustLedgerPaidToRealEstateBroker();
    this.updateExcessDepositInTrustLedger();
  };

  execActionForInterimAndFinal(action: Function): void {
    action();
    if (this.matter && this.matter.isProjectConfigDocForTrustLedger()) {
      this.switchProgressionStatus();
      action();
      this.switchProgressionStatus();
    }
  }

  switchProgressionStatus(): void {
    this.progressionStatus = this.isProgressionStatusFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
    console.log('>> switch the progressionStatus in SoaTrustLedger to %s', this.progressionStatus);
  }

  createSoaMatter(type: string): SoaMatter {
    let soaMatter = new SoaMatter();
    soaMatter.itemValue = 0;
    soaMatter.itemType = type;
    soaMatter.accountCodeArray = this.pushSelectedCodeInArr('');
    soaMatter.progressionStatus = this.soaProgressionStatus;
    return soaMatter;
  }

  clearInterimFeesAndDisbursements(): void {
    this._feesInterim = [];
    this._disbursementsAdditionalInterim = [];
    this._disbursementsGSTandPSTInterim = [];
    this._disbursementsHSTInterim = [];
    this._disbursementsNotHSTInterim = [];
    this._disbursementsPSTInterim = [];
    this._taxableOtherChargesGSTInterim = [];
    this._taxableOtherChargesPSTInterim = [];
    this._taxableOtherChargesGSTandPSTInterim = [];
    this._nonTaxableOtherChargesInterim = [];
  }

  addTeranetConnectChargesToSOA(): void {
    if (this.statementConfig && this.statementConfig.id > 0 && this.matter && this.matter.isTeranetConnectTransactionStatusBilled()) {
      let purchaseDisbursement: SoaMatter = _.find(this.disbursementsHST, soaMatter => soaMatter.isTeranetConnectCharges());
      if (!purchaseDisbursement) {
        let disbursementsConfigTC: DisbursementConfig;

        if (this.statementConfig.statementOfAccount
          && this.statementConfig.statementOfAccount.disbursementsConfig) {
          disbursementsConfigTC = this.statementConfig.statementOfAccount.disbursementsConfig.find(item => item.isTeranetConnectCharges()
            && (!!this.matter.isMatterTypeDischarge ? item.matterType == 'DISCHARGE' : item.matterType == this.matter.matterType));
        }
        if (disbursementsConfigTC) {

          let soaMatter = new SoaMatter();
          soaMatter.progressionStatus = this.soaProgressionStatus;
          soaMatter.itemName = disbursementsConfigTC.nameOfDisbursement;
          soaMatter.itemValue = 0;
          soaMatter.disbursementConfigId = disbursementsConfigTC.id;
          soaMatter.itemKey = disbursementsConfigTC.code;
          soaMatter.itemType = 'DISBURSEMENT_SUBJECT_TO_HST';
          soaMatter.f9DefaultAmount = this.matter.getTeranetConnectChargesForSOA();
          soaMatter.readOnly = disbursementsConfigTC.readOnly;
          soaMatter.accountCode = disbursementsConfigTC.configAccountCode;
          let selectedIndex = this.soaConfig.getDisbursementsConfig('SUBJECT_TO_HST').findIndex(soaConfig => soaConfig.isTeranetConnectCharges());
          if (selectedIndex > -1) {
            this.disbursementsHST.splice(selectedIndex, 0, soaMatter);
          } else {
            this.disbursementsHST.push(soaMatter);
          }

        }
      }
    }
  }

  isResetSoaFeeCalculatedOnInclusivePriceComplete(makeReset: boolean = true): boolean {
    if (this.statementConfig && makeReset && this.matter && !this.matter.isProjectSale) {
      let soaSelectedTemplate: SoaTemplate = this.statementConfig.statementOfAccount.soaSelectedTemplate;
      if (soaSelectedTemplate) {
        this.feeCalculatedOnInclusivePriceSelection = soaSelectedTemplate.feeCalculatedOnInclusivePriceSelection;
        this.soaFeesCalculatedOnAllInclusivePriceValue = soaSelectedTemplate.feeCalculatedOnInclusivePriceValue;
        return true;
      }
    }
    return false;
  }
}

