import moment from 'moment';
import {DpBooleanValue, DpBooleanValueTypes} from './dp-boolean';
import {PropertyTaxesTippInstallment} from './property-taxes-tipp-installment';
import {UUIDUtil} from '../../main/uuid-util';
import {Matter} from './matter';
import {BaseTaxAdjustmentOn, ProjectTaxAdjustmentConfig} from '../../projects/project-adjustments/project-tax-adjustment-config';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';
import {provinceBasedTaxTypes} from '../../shared-main/province-based-dropdowns';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';
import {PROVINCE_CODES} from './user-province';
import {ProgressionStatus} from "../statement-adjustment/statement-adjustment";
import {PaidByVendorStatementAdjustmentUtil} from "../statement-adjustment/statement-adjustment-paid-by-vendor-util";

export type BaseTaxAdjustmentTaxBillTypeType = 'FINAL' | 'INTERIM' | 'PREVIOUS' | 'ESTIMATED_FINAL' | 'NOT_ASSESSED';

export class MatterTax extends BaseEntity {

  id: number;
  static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
  taxType: string;
  baseTaxAdjustmentTaxBillType: BaseTaxAdjustmentTaxBillTypeType;
  estimatedPercentageIncrease: string;
  totalTaxes: number;
  vendorPaidFullAmount: string;
  vendorWillHavePaid: number;
  vendorShareAmount: number;
  payOutOfTrustAccount: string = 'N/y';
  amountPaidFromTrustAccount: number;
  //This the order column for roll numbers for displaying it in UI.
  rollNumberOrder: number;
  accountNumber: string;
  homeOwnerGrantAmount: number;

  hasPaidTaxesUnderTIPP: DpBooleanValue;
  tippInstallment: PropertyTaxesTippInstallment;
  overrideTippPayment: boolean;

  // project sale
  adjustmentBasedOnPercentageOfTotalTaxes: string;
  overrideVendorHasPaid: boolean;
  overrideVendorHasPaidLabel: string;
  proportionateShareCalculated: boolean;
  purchaserPortionOfTotalTax: number;
  adjustmentYearTaxesPaidByVendor: number;

  constructor(propertyTaxes?: MatterTax) {
    super(propertyTaxes);
    if (propertyTaxes) {
      for (let prop in propertyTaxes) {
        if (propertyTaxes.hasOwnProperty(prop)) {
          this[ prop ] = propertyTaxes[ prop ];
        }
      }

      if (propertyTaxes.tippInstallment) {
        this.tippInstallment = new PropertyTaxesTippInstallment(propertyTaxes.tippInstallment);
      }
      // per story DPPMP-13971 we removed this logic.
      //  if(!this.isTotalTaxGreaterThanVendorPaid){
      //    this.payOutOfTrustAccount = 'N/y';
      //  this.amountPaidFromTrustAccount = 0;
      // }

    }
    if (!this.id) {
      this.id = UUIDUtil.getUUID();
    }

  }

  getDaysSinceBeginningOfYearForPropertyTax(closingDate: string, isPaysForDateOfClosingVendor?: boolean): string {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (closingDate && pattern.test(closingDate)) {
      let closingDateStart = moment(closingDate.split('/')[ 0 ] + '/01/01', 'YYYY/MM/DD');
      let closingDateEnd = moment(closingDate, 'YYYY/MM/DD');
      let numOfDays: number = closingDateEnd.diff(closingDateStart, 'days');
      if (isPaysForDateOfClosingVendor) {
        numOfDays++;
      }
      return numOfDays.toString();
    } else {
      return '';
    }
  }

  getDaysUntilEndOfYearForPropertyTax(closingDate: string, isPaysForDateOfClosingVendor?: boolean): string {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (closingDate && pattern.test(closingDate)) {
      let closingDateStart = moment(closingDate, 'YYYY/MM/DD');
      let closingDateEnd = moment(closingDate.split('/')[ 0 ] + '/12/31', 'YYYY/MM/DD');
      let numOfDays: number = closingDateEnd.diff(closingDateStart, 'days');
      if (isPaysForDateOfClosingVendor) {
        numOfDays++;
      }
      return numOfDays.toString();
    } else {
      return '';
    }
  }

  getNSTaxationDaysForPropertyTax(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (closingDate && pattern.test(closingDate)) {
      //Tax year for NS starts from 1st April to 31st March
      let yearOfClosingDate: number = Number(closingDate.split('/')[ 0 ]);
      let currentYearTaxationDateStart = moment(yearOfClosingDate + '/04/01', 'YYYY/MM/DD');
      if (moment(closingDate, 'YYYY/MM/DD') < currentYearTaxationDateStart) {
        currentYearTaxationDateStart = moment((yearOfClosingDate - 1) + '/04/01', 'YYYY/MM/DD');
      }
      let closingDateEnd = moment(closingDate, 'YYYY/MM/DD');
      let numOfDays: number = closingDateEnd.diff(currentYearTaxationDateStart, 'days');
      if (isPaysForDateOfClosingVendor) {
        numOfDays++;
      }
      return numOfDays;
    } else {
      return 0;
    }
  }

  getNumberOfDaysFiscalYearForPropertyTaxNS(closingDate: string): number {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (closingDate && pattern.test(closingDate)) {
      let yearOfClosingDate: number = Number(closingDate.split('/')[ 0 ]);
      let currentYearTaxationDateStart = moment(yearOfClosingDate + '/04/01', 'YYYY/MM/DD');
      let currentYearTaxationDateEnd = moment((yearOfClosingDate + 1) + '/04/01', 'YYYY/MM/DD');
      if (moment(closingDate, 'YYYY/MM/DD') < currentYearTaxationDateStart) {
        currentYearTaxationDateStart = moment((yearOfClosingDate - 1) + '/04/01', 'YYYY/MM/DD');
        currentYearTaxationDateEnd = moment((yearOfClosingDate) + '/04/01', 'YYYY/MM/DD');
      }
      let numOfDays: number = currentYearTaxationDateEnd.diff(currentYearTaxationDateStart, 'days');
      return numOfDays;
    } else {
      return 0;
    }
  }

  getNumberofDaysCurrentYearForPropertyTax(closingDate: string): string {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (closingDate && pattern.test(closingDate)) {
      let closingDateStart = moment((Number(closingDate.split('/')[ 0 ]) - 1) + '/12/31', 'YYYY/MM/DD');
      let closingDateEnd = moment(closingDate.split('/')[ 0 ] + '/12/31', 'YYYY/MM/DD');
      let numOfDays: number = closingDateEnd.diff(closingDateStart, 'days');
      return numOfDays.toString();
    } else {
      return '';
    }
  }

  calculateEstimatedTaxes(interimTaxMultiplier: number): number {
    if (this.totalTaxes && interimTaxMultiplier && this.baseTaxAdjustmentTaxBillType == 'INTERIM') {
      return Number(Number(this.totalTaxes) * Number(interimTaxMultiplier)) + Number(Number(this.totalTaxes) * Number(interimTaxMultiplier) * Number(this.estimatedPercentageIncrease) / 100);
    } else if (this.totalTaxes && this.baseTaxAdjustmentTaxBillType == 'PREVIOUS') {
      return Number(Number(Number(this.totalTaxes) * Number(this.estimatedPercentageIncrease)) / 100) + Number(this.totalTaxes);
    } else {
      return 0;
    }
  }

  calculateTotalTaxForTheUnit(interimTaxMultiplier: number): number {
    return this.totalTaxes && this.baseTaxAdjustmentTaxBillType == 'PREVIOUS' ? Number(Number(Number(this.totalTaxes) * Number(this.estimatedPercentageIncrease)) / 100) + Number(this.totalTaxes) : this.totalTaxes;
  }

  calculateTotalTaxesForAdjBeginningOfYearUntilOcc(interimTaxMultiplier: number): number {
    return Number(Number(this.calculateTotalTaxForTheUnit(interimTaxMultiplier)) * Number(this.purchaserPortionOfTotalTax) / 100);
  }

  calculateVendorShareForPurchaseBC(netTaxes: number = 0, adjustmentYear: string, adjustmentDate: string, isVendorPaying: boolean) {
    const numberOfDaysYear = moment([ adjustmentYear ]).isLeapYear() ? 366 : 365;
    let elapsedDays = isVendorPaying ?
      this.getDaysUntilEndOfYearForPropertyTax(adjustmentDate, true)
      : this.getDaysSinceBeginningOfYearForPropertyTax(adjustmentDate, true);

    if (numberOfDaysYear && elapsedDays) {
      this.vendorShareAmount = Number((netTaxes / numberOfDaysYear) * Number(elapsedDays));
    } else {
      this.vendorShareAmount = 0;
    }
    return this.vendorShareAmount;
  }

  calculateVendorShare(interimTaxMultiplier: number, closingDate: string, isPaysForDateOfClosingVendor?: boolean, isProjectProportionateShare?: boolean, isBeginningOfOcc?: boolean, provinceCode?: string): number {
    let elapsedDays = provinceCode && provinceCode == PROVINCE_CODES.NOVA_SCOTIA ? this.getNSTaxationDaysForPropertyTax(closingDate, isPaysForDateOfClosingVendor).toString()
      : this.getDaysSinceBeginningOfYearForPropertyTax(closingDate, isPaysForDateOfClosingVendor);
    let numberOfDaysYear = provinceCode && provinceCode == PROVINCE_CODES.NOVA_SCOTIA ? this.getNumberOfDaysFiscalYearForPropertyTaxNS(closingDate).toString() : this.getNumberofDaysCurrentYearForPropertyTax(closingDate);
    let totalProportionOfTotalTaxes: number = this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && isProjectProportionateShare ? this.calculateProportionateShare(interimTaxMultiplier) : this.totalTaxes;
    let totalProportionOfInterimTaxes: number = this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && isProjectProportionateShare ? this.calculateProportionateShare(interimTaxMultiplier) : Number(this.calculateEstimatedTaxes(interimTaxMultiplier).toFixed(2));
    if (isBeginningOfOcc) {
      totalProportionOfTotalTaxes = this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && isProjectProportionateShare ? this.calculateTotalTaxesForAdjBeginningOfYearUntilOcc(interimTaxMultiplier) : this.totalTaxes;
      totalProportionOfInterimTaxes = this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && isProjectProportionateShare ? this.calculateTotalTaxesForAdjBeginningOfYearUntilOcc(interimTaxMultiplier) : Number(this.calculateEstimatedTaxes(interimTaxMultiplier).toFixed(2));
    }

    if (elapsedDays && elapsedDays != '' && numberOfDaysYear && numberOfDaysYear != '' && totalProportionOfTotalTaxes && (this.baseTaxAdjustmentTaxBillType == 'INTERIM' || this.baseTaxAdjustmentTaxBillType == 'PREVIOUS') && interimTaxMultiplier) {
      this.vendorShareAmount = Number((Number(Number(elapsedDays) / Number(numberOfDaysYear)) * totalProportionOfInterimTaxes));
    } else if (elapsedDays && elapsedDays != '' && numberOfDaysYear && numberOfDaysYear != '' && totalProportionOfTotalTaxes && (this.baseTaxAdjustmentTaxBillType == 'FINAL' || this.baseTaxAdjustmentTaxBillType == 'ESTIMATED_FINAL')) {
      this.vendorShareAmount = Number((Number(Number(elapsedDays) / Number(numberOfDaysYear)) * totalProportionOfTotalTaxes).toFixed(2));
    } else {
      this.vendorShareAmount = 0;
    }
    return this.vendorShareAmount;
  }

  /**
   * The original calculateVendorShareDifference only calculates the vendor share difference which is not TaxBeginningOfYearUntilOcc.
   * It enhances to include TaxBeginningOfYearUntilOcc
   * @param interimTaxMultiplier
   * @param closingDate
   * @param isPaysForDateOfClosingVendor
   * @param isProjectProportionateShare
   * @param provinceCode
   * @param isBeginningOfOcc
   * @returns {number}
   */
  calculateVendorShareDifference(interimTaxMultiplier: number, closingDate: string, isPaysForDateOfClosingVendor?: boolean, isProjectProportionateShare?: boolean, provinceCode ?: string, isBeginningOfOcc?: boolean): number {
    let vendorShareAmount: number = isBeginningOfOcc
      ? this.calculateVendorShareForTaxBeginningOfYearUntilOcc(interimTaxMultiplier, closingDate, isPaysForDateOfClosingVendor, isProjectProportionateShare)
      : this.calculateVendorShare(interimTaxMultiplier, closingDate, isPaysForDateOfClosingVendor, isProjectProportionateShare, false, provinceCode);
    let vendorProportionWillHavePaid: number = this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && isProjectProportionateShare ? Number(this.vendorWillHavePaid) * Number(this.purchaserPortionOfTotalTax) / 100 : this.vendorWillHavePaid;
    if (vendorShareAmount > vendorProportionWillHavePaid) {
      return (Number(vendorShareAmount) - Number(vendorProportionWillHavePaid));
    } else if (vendorProportionWillHavePaid > vendorShareAmount) {
      return (Number(vendorProportionWillHavePaid) - Number(vendorShareAmount));
    } else {
      return 0;
    }
  }

  calculateVendorShareForTaxBeginningOfYearUntilOcc(interimTaxMultiplier: number, closingDate: string, isPaysForDateOfClosingVendor?: boolean, isProjectProportionateShare?: boolean): number {
    return this.calculateVendorShare(interimTaxMultiplier, closingDate, isPaysForDateOfClosingVendor, isProjectProportionateShare, true);
  }

  calculateCreditAmountForTaxBeginningOfYearUntilOcc(baseTaxAdjustmentType: ProjectTaxAdjustmentConfig, interimTaxMultiplier: number, closingDate: string, isPaysForDateOfClosingVendor?: boolean, isProjectProportionateShare?: boolean): number {
    let vendorShareDiff: number = this.calculateVendorShareDifference(interimTaxMultiplier, closingDate, isPaysForDateOfClosingVendor, isProjectProportionateShare, null, true);
    /*if (this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && (baseTaxAdjustmentType.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfTaxesForProject || baseTaxAdjustmentType.taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits) && !isNaN(this.purchaserPortionOfTotalTax)) {
        return (vendorShareDiff * ( Number(this.purchaserPortionOfTotalTax) / 100) );
    }*/
    return vendorShareDiff;
  }

  calculateVendorShareForPaidByVendor(baseTaxAdjustmentType: ProjectTaxAdjustmentConfig, realtyTaxesPaidByVendor: boolean, matter: Matter): number {
    if (realtyTaxesPaidByVendor) {
      const purchaserShareAmt: string = PaidByVendorStatementAdjustmentUtil.getPurchaserShareAmtValue(matter, this); //E40
      const unitShareAmt: string = PaidByVendorStatementAdjustmentUtil.getUnitShareAmount(matter, this) + ''; //E38
      if (this.isBlankOrZero(purchaserShareAmt) && this.isBlankOrZero(unitShareAmt)) {
        return this.vendorWillHavePaid;  //E36
      } else {
        return this.isBlankOrZero(purchaserShareAmt) ? +unitShareAmt : +purchaserShareAmt;
      }
    }

    if (baseTaxAdjustmentType.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice
      || baseTaxAdjustmentType.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits) {
      return this.calculateVendorHasPaid(matter);
    } else {
      return baseTaxAdjustmentType.numberOfUnits == 0 ? 0 : (this.calculateVendorHasPaid(matter) * (1 / baseTaxAdjustmentType.numberOfUnits));
    }

  }

  isBlankOrZero(value: number | string): boolean {
    return !value || value == 0;
  }

  calculateVendorHasPaid(matter: Matter): number {
    if ((matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject || matter.isBaseTaxAdjustmentEqualDivisionAmongUnits) && this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && this.baseTaxAdjustmentTaxBillType == 'INTERIM') {
      return Number(this.vendorWillHavePaid) * Number(this.purchaserPortionOfTotalTax) / 100;
    } else {
      return this.vendorWillHavePaid;
    }
  }

  calculateProportionateShare(interimTaxMultiplier: number): number {

    switch (this.baseTaxAdjustmentTaxBillType) {
      case 'FINAL':
        return ((Number(this.totalTaxes) * Number(this.purchaserPortionOfTotalTax) / 100));
      case 'INTERIM':
        return ((Number(this.calculateEstimatedTaxes(interimTaxMultiplier)) * Number(this.purchaserPortionOfTotalTax) / 100));
      case 'PREVIOUS':
        return ((Number(this.calculateEstimatedTaxes(interimTaxMultiplier)) * Number(this.purchaserPortionOfTotalTax) / 100));
      case 'ESTIMATED_FINAL':
        return ((Number(this.totalTaxes) * Number(this.purchaserPortionOfTotalTax) / 100));
      default:
        return 0;
    }

  }

  calculateProportionateShareOfVendor(): number {
    return ((Number(this.vendorWillHavePaid) * Number(this.purchaserPortionOfTotalTax) / 100));

  }

  static getAdjustmentDateYearRule(currentYear: boolean, closingDate: string): string {
    if (!currentYear && closingDate && closingDate.indexOf('/') > -1 && closingDate.split('/')[ 0 ] && !isNaN(Number(closingDate.split('/')[ 0 ]))) {
      return (Number(closingDate.split('/')[ 0 ]) - 1).toString();
    } else if (currentYear && closingDate && closingDate.indexOf('/') > -1 && closingDate.split('/')[ 0 ] && !isNaN(Number(closingDate.split('/')[ 0 ]))) {
      return (Number(closingDate.split('/')[ 0 ])).toString();
    } else {
      return '????';
    }
  }

  getPropertyTaxLabel(matter: Matter): string {
    let propertyTaxLabel = this.getAdjustmentYearLabel(matter);
    if (this.baseTaxAdjustmentTaxBillType === 'PREVIOUS' || this.baseTaxAdjustmentTaxBillType === 'FINAL') {
      propertyTaxLabel += ' total taxes';
    } else if (this.baseTaxAdjustmentTaxBillType === 'INTERIM') {
      propertyTaxLabel += ' interim taxes';
    } else {
      propertyTaxLabel += ' estimated total taxes';
    }
    return propertyTaxLabel;
  }

  //After confirming with BA in DPPMP-2330, it should be ‘Current Year of Adjustment Date’
  // for "Vendor will have paid (toward <Current Year of Adjustment Date> taxes)" field.
  getAdjustmentYearLabel(matter: Matter, isVendorWillHavePaid: Boolean = false): string {
    let adjustmentYearLabel = '';
    if (matter.isMatterProvinceNS) {
      if (this.baseTaxAdjustmentTaxBillType === 'PREVIOUS') {
        adjustmentYearLabel = 'Previous Year';
      } else {
        adjustmentYearLabel = 'Current Fiscal Year';
      }
    } else {
      let date = matter.isMatterProvinceBC ? matter.adjustAsAtClosingDate : matter.getClosingDate();
      if (this.baseTaxAdjustmentTaxBillType === 'PREVIOUS' && !isVendorWillHavePaid) {
        adjustmentYearLabel = MatterTax.getAdjustmentDateYearRule(false, date);
      } else {
        adjustmentYearLabel = MatterTax.getAdjustmentDateYearRule(true, date);
      }
    }
    return adjustmentYearLabel;
  }

  get isBaseTaxAdjustmentTaxBillTypeFinal(): boolean {
    return this.baseTaxAdjustmentTaxBillType == 'FINAL';
  }

  get isTotalTaxGreaterThanVendorPaid(): boolean {
    return Number(this.totalTaxes) > Number(this.vendorWillHavePaid);
  }

  get isPayOutOfTrustAccount(): boolean {
    return this.payOutOfTrustAccount == 'YES';
  }

  get isPaidTaxesUnderTIPP(): boolean {
    return this.hasPaidTaxesUnderTIPP == 'YES';
  }

  get netTaxes(): number {
    let netTaxes = this.totalTaxes ?? 0;
    if (this.baseTaxAdjustmentTaxBillType == 'PREVIOUS' && netTaxes > 0 && Number(this.estimatedPercentageIncrease) > 0) {
      return netTaxes * (1 + Number(this.estimatedPercentageIncrease) / 100) - (this.homeOwnerGrantAmount || 0);
    }
    return netTaxes - (this.homeOwnerGrantAmount || 0);
  }

  proportionateShare(interimMultiplier: number): number {
    switch (this.baseTaxAdjustmentTaxBillType) {
      case 'FINAL':
        return Number(this.totalTaxes);
      case 'INTERIM':
        return Number(this.calculateEstimatedTaxes(interimMultiplier));
      case 'PREVIOUS':
        return Number(this.calculateEstimatedTaxes(interimMultiplier));
      case 'ESTIMATED_FINAL':
        return Number(this.totalTaxes);
      default:
        return 0;
    }
  }

  getLabelForTaxesPaidByVendor(adjustmentStatusMode: string): string {
    if (adjustmentStatusMode === ProgressionStatus.FINAL) {
      return this.adjustmentYearTaxesPaidByVendor + ' total taxes';
    } else {
      if (this.baseTaxAdjustmentTaxBillType === 'FINAL' ||
        this.baseTaxAdjustmentTaxBillType === 'PREVIOUS') {
        return this.adjustmentYearTaxesPaidByVendor - 1 + ' total taxes';
      } else if (this.baseTaxAdjustmentTaxBillType === 'INTERIM') {
        return this.adjustmentYearTaxesPaidByVendor + ' interim taxes';
      } else {
        return this.adjustmentYearTaxesPaidByVendor + ' estimated total taxes';
      }
    }
  }

  getAdjustmentHeadingForPaidByVendor(): string {
    let selectItem: SelectItem[] = _.cloneDeep(provinceBasedTaxTypes[ 'ON' ]);
    let taxTypeSelected = _.find(selectItem, taxType => taxType.value == this.taxType);
    return (taxTypeSelected) ?
      `${ this.adjustmentYearTaxesPaidByVendor } ${ taxTypeSelected.label } PAID BY VENDOR` :
      `${ this.adjustmentYearTaxesPaidByVendor } PAID BY VENDOR`;
  }

  updatePurchaserPortionValue(matter: Matter): void {
    if ((matter.isProjectSale || matter.templateForProject || (matter.project && matter.isPurchase && !!matter.matterLink)) && matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject && (matter.isCondoCorporation || matter.project.isCondo)) {
      this.purchaserPortionOfTotalTax = matter.isMatterProvinceAB ? Number(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) :
        Number(matter.matterPropertyWithCondo.condominiumTotalSharePercentage);

    }
  }

  isAdjustmentBasedOnPercentageOfTotalTaxesApplied(): boolean {
    return this.adjustmentBasedOnPercentageOfTotalTaxes == 'YES';
  }

  initializeForRollNumber(estTaxIncrease: string, matterProvinceCode: ProvinceCode, closingDate: string) {
    if (!this.taxType || this.taxType == null) {
      this.taxType = 'REALTY_TAXES';
    }
    if (!this.totalTaxes || this.totalTaxes == null) {
      this.totalTaxes = 0;
    }
    if (!this.vendorWillHavePaid || this.vendorWillHavePaid == null) {
      this.vendorWillHavePaid = 0;
    }
    if (!this.vendorPaidFullAmount || this.vendorPaidFullAmount == null) {
      this.vendorPaidFullAmount = 'NO';
    }
    if (!this.baseTaxAdjustmentTaxBillType || this.baseTaxAdjustmentTaxBillType == null) {
      this.baseTaxAdjustmentTaxBillType = this.checkAdjustmentDateMonth(closingDate) ? 'PREVIOUS' : 'FINAL';
    }
    if ((!this.estimatedPercentageIncrease || this.estimatedPercentageIncrease == null) && estTaxIncrease) {
      this.estimatedPercentageIncrease = estTaxIncrease;
    } else if ((!this.estimatedPercentageIncrease || this.estimatedPercentageIncrease == null) && !estTaxIncrease) {
      this.estimatedPercentageIncrease = '0';
    }

    if (this.payOutOfTrustAccount == null || this.payOutOfTrustAccount === 'N_y') {
      this.payOutOfTrustAccount = 'N/y';
    }

    if ([ 'AB', 'MB' ].indexOf(matterProvinceCode) > -1) {
      if (!this.hasPaidTaxesUnderTIPP || this.hasPaidTaxesUnderTIPP == null) {
        this.hasPaidTaxesUnderTIPP = DpBooleanValueTypes.N_y;
      }
      if (!this.tippInstallment || this.tippInstallment == null) {
        this.tippInstallment = new PropertyTaxesTippInstallment();
      }
    }
  }

  checkAdjustmentDateMonth(closingDate: string): boolean {
    return (closingDate && closingDate.indexOf('/') > -1 && closingDate.split('/')[ 1 ] && (
      closingDate.split('/')[ 1 ] == '01' ||
      closingDate.split('/')[ 1 ] == '02' ||
      closingDate.split('/')[ 1 ] == '03' ||
      closingDate.split('/')[ 1 ] == '04' ||
      closingDate.split('/')[ 1 ] == '05'));
  }

  isPayOutOfTrustAccount0SetToYes(): boolean {
    return this.payOutOfTrustAccount == 'YES';
  }
}
