import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {PROVINCE_CODES} from '../../matters/shared/user-province';
import {Account} from '../../admin/accounts/shared/account';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../admin/accounts/shared/account-drop-downs';

@Injectable()
export class UserStateService {
  private _defaultProvinceCode: string;
  private userProvinces: string[] = [];
  private accountSubject = new Subject<Account>();
  private account: Account;
  public APIRequest = null;

  constructor() {
  }

  setUserRegion(defaultProvinceCode: string, userProvinces: string[]): void {
    this.defaultProvinceCode = defaultProvinceCode;
    sessionStorage.setItem(SESSION_STORAGE_KEYS.defaultProvinceCode, defaultProvinceCode);
    this.setEnabledUserProvinceCodes(userProvinces);
  }

  get defaultProvinceCode(): string {
    if (!this._defaultProvinceCode) {
      this._defaultProvinceCode = sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode);
    }
    return this._defaultProvinceCode;
  }

  set defaultProvinceCode(province: string) {
    this._defaultProvinceCode = province;
  }

  setEnabledUserProvinceCodes(provinces: string[]): void {
    this.userProvinces = provinces;
  }

  getEnabledUserProvinceCodes(): string[] {
    if (!(this.userProvinces && this.userProvinces.length)) {
      this.userProvinces = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)).userProvinces;
    }
    return this.userProvinces;
  }

  getSortedEnabledUserProvinceCodes(): string[] {
    return this.getEnabledUserProvinceCodes().sort((a, b) => {
      return (a == this.defaultProvinceCode) ? -1 :
        (((a < b) && a != this.defaultProvinceCode && b != this.defaultProvinceCode) ? -1 : 1);
    });
  }

  getAccount(): Observable<Account> {
    return this.account ? Observable.of(this.account) : this.accountSubject;
  }

  setAccount(account: Account): void {
    this.account = account;
    this.accountSubject.next(this.account);
  }

  isDefaultProvinceON(): boolean {
    return this.defaultProvinceCode == PROVINCE_CODES.ONTARIO;
  }

  isDefaultProvinceBC(): boolean {
    return this.defaultProvinceCode == PROVINCE_CODES.BRITISH_COLOMBIA;
  }

  clearUserStat(): void {
    this.account = null;
    this.userProvinces = [];
    this.accountSubject = new Subject<Account>();
    this.APIRequest = null;
  }

  getProvinceOptions(): SelectItem[] {
    let provincesOptions: SelectItem[] = [];
    const userProvinceCodes = this.getEnabledUserProvinceCodes();
    if (Array.isArray(userProvinceCodes)) {
      //Adding ProvinceOptions based on selected accessibleProvinces
      userProvinceCodes.forEach(item => {
        let userProvinceOption: SelectItem = dropDowns.accessibleProvinces.find(accessibleProvince => accessibleProvince.value == item);
        if (userProvinceOption != null) {
          provincesOptions.push(userProvinceOption);
        }
      });
    }
    return provincesOptions;
  }

  isUnityDriveAvailable(): boolean {
    return (this.account && this.account.applications && this.account.applications.find((app) => app.applicationName == 'UNITY_DRIVE' && app.active) != undefined);
  }

  get isdefaultProvinceBC(): boolean {
    return (this.defaultProvinceCode === PROVINCE_CODES.BRITISH_COLOMBIA);
  }

  isOnlyBCEnabled() {
    let enabledProvinces = this.getEnabledUserProvinceCodes();
    return enabledProvinces.length === 1 && PROVINCE_CODES.BRITISH_COLOMBIA === enabledProvinces[ 0 ];
  }
}
