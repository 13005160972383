import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {api} from '../common/api';
import {SESSION_STORAGE_KEYS} from '../shared';
import {Environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoCardlessService {
  private static postAuthRedirectUrl = 'post_aut_redirect_url';

  private goCardlessApiUrl?: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private environment: Environment
  ) {
    this.goCardlessApiUrl = this.environment.config.goCardlessApiUrl?.replace(/[\/]$/, '');
  }

  private set postAuthRedirectUrl(value: string) {
    localStorage.setItem(GoCardlessService.postAuthRedirectUrl, value);
  }

  public get postAuthRedirectUrl() {
    const url = localStorage.getItem(GoCardlessService.postAuthRedirectUrl);
    if (url) {
      return url;
    }
  }

  public authorize(): void {
    this.postAuthRedirectUrl = this.router.url;
    let authoriseRedirectUrl = `${ this.goCardlessApiUrl }/oauth/authorize`;

    authoriseRedirectUrl +=
      '?client_id=' + this.environment.config.goCardlessClientId;
    authoriseRedirectUrl +=
      '&redirect_uri=' +
      this.buildRedirectUri(
        this.environment.config.goCardlessAuthRedirectUri
      );
    authoriseRedirectUrl += '&scope=read_write';
    authoriseRedirectUrl += '&response_type=code';
    authoriseRedirectUrl += '&initial_view=signup';

    window.location.href = authoriseRedirectUrl;
  }

  public exchangeCodeForToken(authCode: string): Observable<any> {
    const accountId = this.accountId;
    const url = `${ api }/accounts/${ accountId }/gocardless/accessToken`;
    let headers = new HttpHeaders();
    headers = headers.append('authorization-code', authCode);

    return this.httpClient.post<any>(url, {}, {headers});
  }

  public revokedAccessToken(accountId: number): Observable<any> {
    const url = `${ api }/accounts/${ accountId }/gocardless/revoke`;
    return this.httpClient.post<any>(url, {});
  }

  private get accountId(): string {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }

  private buildRedirectUri(environmentConfig: string): string {
    let redirectUri =
      window.location.protocol +
      '//' +
      window.location.host +
      environmentConfig;
    return redirectUri;
  }
}
