<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Revoking Documents</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group" >
            <div class="col-lg-10 offset-lg-1 text-center padding-bottom-10">
                After selecting Revoke, {{context.sharedDocumentsPackage.recipientName}} will no longer be able to access
                {{context.documentId ? 'the selected document.' : 'documents from this matter.'}}
                <br>A notification will be sent to them using the message entered below.
            </div>

        </div>
        <div class="form-group" >
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Message Precedent</label>
            </div>
            <div class="col-lg-8">
                <select class="form-control" id="messagePrecedents"  name="messagePrecedents"
                        [(ngModel)]="selectedPrecedent"
                        (change)="updateEditorMessage()">
                    <option value=""></option>
                    <option *ngFor="let precedent of messagePrecedents" [value]="precedent.id">
                        {{precedent.precedentName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group" >
            <div class="col-lg-8 offset-lg-2">
                Dear Sharing Recipient
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label text-right">Message</label>
            </div>
            <div class="col-lg-8" >
                    <textarea class="form-control" rows="8"
                              name="unitySignatureBlock"
                              id="unitySignatureBlock"
                              [(ngModel)]="message">
                    </textarea>
            </div>
        </div>


    </div>

    <div class="row modal-footer">

        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="revoke()">
                <span>Revoke</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
