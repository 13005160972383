import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from '../../shared/contact';
import {Constants, SnapshotBurgerMenuActions} from '../../../shared-main/constants';
import {MatterParticipantWrapper} from '../../shared/matter-participant-wrapper';
import {MatterParticipantRoleTypes} from '../../shared/matter-participant-role-types';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatterParticipantService} from '../../matter-participant-service';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {ActivatedRoute, Params} from '@angular/router';
import {GetGlobalSaveModelService} from '../../shared/get-global-save-model.service';
import {Subject} from 'rxjs/Rx';
import {CommonContactDialogComponent} from '../common-contact-dialog/common-contact-dialog.component';
import {ContactTab} from '../../../contact/contact-tab';
import {TabsService} from '../../../core/tabs.service';
import {Matter} from '../../shared/matter';
import {MatterParticipant} from '../../shared/matter-participant';

@Component({
  selector: 'dp-attention-info',
  templateUrl: 'attention-info.component.html'
})
export class AttentionInfoComponent implements OnInit {
  @Input() attentionList: Contact[];
  @Input() parentMatterParticipant: MatterParticipant;
  @Input() mortgageId: number;
  @Input() matter: Matter;

  @Output() changed = new EventEmitter();
  public ngUnsubscribe: Subject<void> = new Subject<void>();

  attentionWrapper: MatterParticipantWrapper;//Selected attention gets added as a matter participant

  // parentContact : Contact;

  constructor(public dialogService: DialogService,
              public tabsService: TabsService,
              public matterParticipantService: MatterParticipantService,
              public contactQueryService: ContactQueryService,
              public globalSaveModelService: GetGlobalSaveModelService,
              public activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    //Subscribing to mortgage changes
    this.activatedRoute.params
    .subscribe((params: Params) => {
      setTimeout(() => {
        this.initializeAttentionComponentState();
      }, 100);
    });
  }

  initializeAttentionComponentState(): void {
    this.buildAttentionWrapper();

    //Subscribing to matter changes
    this.globalSaveModelService.getItem$.takeUntil(this.ngUnsubscribe).subscribe(() => {
      // Switching page or save matter, the shutter will be closed automatically.
      // However it do not unlock source contact if the snapshot date does not be changed
      // So this method should be call before switching new matter
      //it will unlock source contact if the snapshot date does not be changed
      if (this.attentionWrapper && this.attentionWrapper.matterParticipant && this.attentionWrapper.expanded) {
        this.toggleAttentionShutter();
      }
      this.buildAttentionWrapper();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    //it will unlock source contact if the snapshot date does not be changed
    if (this.attentionWrapper && this.attentionWrapper.matterParticipant && this.attentionWrapper.expanded) {
      this.toggleAttentionShutter();
    }
  }

  buildAttentionWrapper(): void {
    if (this.parentMatterParticipant) {
      let persistedAttentionParticipant: MatterParticipant
        = this.getPersistedAttentionParticipant();

      if (persistedAttentionParticipant) {
        this.createAttentionWrapperByMatterParticipant(persistedAttentionParticipant);
      } else {
        this.initAttentionWrapper();
      }
    }
  }

  public getPersistedAttentionParticipant() {
    if (this.parentMatterParticipant.matterParticipantRole == MatterParticipantRoleTypes.MORTGAGEE) {
      return this.matter.findMatterParticipant(MatterParticipantRoleTypes.MORTGAGEE_ATTENTION, this.mortgageId);
    } else if (this.parentMatterParticipant.matterParticipantRole == MatterParticipantRoleTypes.CONDO_CORPORATION) {
      return this.matter.findMatterParticipant(MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION);
    } else if (this.parentMatterParticipant.matterParticipantRole == MatterParticipantRoleTypes.MANAGEMENT_COMPANY) {
      return this.matter.findMatterParticipant(MatterParticipantRoleTypes.MANAGEMENT_COMPANY_ATTENTION);
    }

    return null;
  }

  public createAttentionWrapperByMatterParticipant(matterParticipant: MatterParticipant): void {
    if (matterParticipant) {
      this.attentionWrapper = new MatterParticipantWrapper();
      this.attentionWrapper.matterParticipant = matterParticipant;
      this.attentionWrapper.dataModel = matterParticipant.contact;
      this.attentionWrapper.editMode = false;
      this.attentionWrapper.createdOrSelected = 'Selected';
      // There are two scenarios:
      //   1. source contact exists, which means this snapshot is cloned from source contact
      //   2. source contact doesn't exist, which means this snapshot ONLY exist in matter level, and doesn't exist in contact level.
      //
      // If sourceContactId doesn't exist we don't need update wrapper state.
      if (matterParticipant.contact && matterParticipant.contact.sourceContactId) {
        this.contactQueryService
        .getContactForMatter(matterParticipant.contact.sourceContactId)
        .subscribe((sourceContact: Contact) => {
          this.matterParticipantService.updateParticipantWrapperState(this.attentionWrapper, sourceContact);
        });
      }
    }
  }

  emitChange(): void {
    this.changed.emit();
  };

  toggleAttentionShutter() {
    if (this.attentionWrapper) {
      // There are two scenarios:
      //   1. source contact exists, which means this snapshot is cloned from source contact
      //   2. source contact doesn't exist, which means this snapshot ONLY exist in matter level, and doesn't exist in contact level.
      //
      // If sourceContactId doesn't exist we don't need update wrapper state.
      if (this.attentionWrapper.sourceContactId) {
        this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, this.attentionWrapper);
      } else {
        this.attentionWrapper.expanded = !this.attentionWrapper.expanded;
      }
    }
  }

  get attentionContact(): Contact {
    return this.attentionWrapper && this.attentionWrapper.matterParticipant
      && this.attentionWrapper.matterParticipant.contact;
  }

  get isAttentionDisplayedAsShutter(): boolean {
    return !!(this.attentionWrapper && !this.attentionWrapper.editMode);
  }

  get isAttentionDisplayedAsSelect(): boolean {
    return !!(this.attentionWrapper && this.attentionWrapper.editMode);
  }

  get isAttentionDisplayedAsDefaultValue(): boolean {
    return !!(this.attentionWrapper && (this.parentMatterParticipant.attentionName === Constants.MORTGAGE_DEPT
      || this.parentMatterParticipant.attentionName === Constants.MORTGAGE_DISCHARGE
      || this.parentMatterParticipant.attentionName === Constants.STATUS_CERTIFICATE_REQUEST));
  }

  selectAttention(selectedAttentionContact: Contact): void {
    if (selectedAttentionContact) {
      // As participant is not created for default attentions therefore saving attention name in
      // attentionName of  parent MatterParticipant .

      if (!selectedAttentionContact.isExistingAttention()) {
        //Creating participant from the selected attention
        //If there was attention selected before then removing it
        if (this.attentionWrapper && this.attentionWrapper.matterParticipant) {
          this.matter.removeMatterParticipant(this.attentionWrapper.matterParticipant);
        }
        if (this.attentionWrapper) {
          this.selectAttentionExistingAttention(this.attentionWrapper.dataModel);
        }

      } else if (selectedAttentionContact.firstName == Constants.ADD_NEW_RECORD_ATTENTION) {
        //Add New Attention
        this.createNewAttention(this.parentMatterParticipant.contact);
      } else if (selectedAttentionContact.isDefaultAttention()) {
        this.initAttentionWrapper();
      }
      this.parentMatterParticipant.attentionName = selectedAttentionContact.subContactDisplayName;

    }
  }

  selectAttentionExistingAttention(contact: Contact) {
    this.contactQueryService
    .getContactForMatter(contact.id)
    .subscribe((sourceContact: Contact) => {
      if (sourceContact) {
        this.setContactInAttentionWrapper(sourceContact, true, true);
      }
    });
  }

  createNewAttention(parentContact: Contact) {
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        parentContact: parentContact,
        contactType: 'PRIVATE_CONTACT',
        keyboard: null
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {//if contact modal is closed after save
            this.initAttentionWrapper();
            // There are two scenarios:
            //   1. source contact exists, which means this snapshot is cloned from source contact
            //   2. source contact doesn't exist, which means this snapshot ONLY exist in matter level,
            //   and doesn't exist in contact level.
            //
            // 'createdNewClient' is true, means this public(attention) contact should be attached to this mortgage
            // as source public contact.
            // Otherwise, source public contact will not be created.
            if (result.createdNewClient) {
              this.setContactInAttentionWrapper(result.contact, true, false);
              this.attentionList.push(result.contact);
            } else {
              this.setContactInAttentionWrapper(result.contact, false, false);
            }
            this.parentMatterParticipant.attentionName = result.contact.subContactDisplayName;
          } else {
            this.initAttentionWrapper();
            this.parentMatterParticipant.attentionName = null;
            this.emitChange();
          }
        }
      },
      onRejection: () => {

      },
      
    });
  }

  onF9InDear() {
    const sirMadam: string = 'Sir or Madam';
    if (this.attentionWrapper && this.attentionWrapper.matterParticipant) {
      this.attentionWrapper.matterParticipant.contact.dear
        = this.attentionWrapper.matterParticipant.contact.dear === sirMadam ? '' : sirMadam;
    }

  }

  clickAttentionBurgerMenuItem(event, selectedAttention: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE:
        this.deleteAttention();
        break;
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
        this.matterParticipantService.updateMatterContactFromSourceContact(selectedAttention, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.attentionCallbackOnUpdateFromSource, this.matter);
        break;
    }
  }

  deleteAttention(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this Attention from this matter?', false, 'Delete').subscribe(res => {
      if (res) {
        if (this.attentionWrapper.matterParticipant) {
          this.matterParticipantService.removeParticipant(this.attentionWrapper, this.matter);
        }
        this.initAttentionWrapper();
        this.parentMatterParticipant.attentionName = null;
        this.emitChange();
      }
    });
  }

  initAttentionWrapper() {
    this.attentionWrapper = new MatterParticipantWrapper();
    this.attentionWrapper.editMode = true;
  }

  attentionCallbackOnUpdateFromSource = () => {
    //Open the participant after performing revert or clear & update
    this.attentionWrapper.expanded = true;
    this.emitChange();
  };

  onSelectedAttention() {
    this.emitChange();
    if (this.attentionWrapper && this.attentionWrapper.dataModel) {
      this.selectAttention(this.attentionWrapper.dataModel);
      // While attentionWrapper.dataModel is undefined, the Attention dropdown should be 'unselected' status,
      // however only a different falsy value could trigger the update, so set it to 'null'.
      if (this.attentionWrapper.dataModel === undefined) {
        this.attentionWrapper.dataModel = null;
      }
    }
  }

  public setContactInAttentionWrapper(sourceContact: Contact, withCloning: boolean, toggleAttentionShutter: boolean): void {
    this.attentionWrapper = new MatterParticipantWrapper();
    this.attentionWrapper.editMode = false;
    this.attentionWrapper.matterParticipant
      = this.addMatterParticipant(sourceContact, withCloning);
    if (toggleAttentionShutter) {
      this.toggleAttentionShutter();
    } else {
      // There are two scenarios:
      //   1. source contact exists, which means this snapshot is cloned from source contact
      //   2. source contact doesn't exist, which means this snapshot ONLY exist in matter level, and doesn't exist in contact level.
      //
      // If sourceContactId doesn't exist we don't need update wrapper state.
      if (this.attentionContact && this.attentionContact.sourceContactId) {
        this.matterParticipantService.updateParticipantWrapperState(this.attentionWrapper, sourceContact);
      }
    }
  }

  openAttentionSourceContactTab(): void {
    if (this.attentionWrapper && this.attentionWrapper.matterParticipant) {
      this.contactQueryService.getContactForOpeningTab(this.attentionWrapper.sourceContactId).subscribe((res: any) => {
        let source: Contact = res.contact;
        const contactTab = this.getContactTab(source);
        this.tabsService.openTab(contactTab);
      });
    }
  }

  removeAttention(): void {
    if (this.attentionWrapper && this.attentionWrapper.matterParticipant) {
      this.matterParticipantService.removeParticipant(this.attentionWrapper, this.matter);
    }
    this.initAttentionWrapper();
    if (this.parentMatterParticipant) {
      this.parentMatterParticipant.attentionName = null;
    }
    this.emitChange();
  }

  get isPrivateContactReadOnly() {
    return this.attentionWrapper.sourceContactId
      ? this.attentionWrapper.isParticipantDataReadOnly
      : false;
  }

  addMatterParticipant(contact: Contact, withCloning: boolean) {
    if (this.parentMatterParticipant && this.parentMatterParticipant.contact) {
      if (this.parentMatterParticipant.contact.isMortgagee) {
        return this.matter.addMatterParticipant(contact, withCloning, MatterParticipantRoleTypes.MORTGAGEE_ATTENTION, this.mortgageId);
      } else if (this.parentMatterParticipant.contact.isCondoCorporation) {
        return this.matter.addMatterParticipant(contact, withCloning, MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION);
      } else if (this.parentMatterParticipant.contact.isManagementCompany) {
        return this.matter.addMatterParticipant(contact, withCloning, MatterParticipantRoleTypes.MANAGEMENT_COMPANY_ATTENTION);
      }
    }
    return null;
  }

  getContactTab(source: Contact): ContactTab {
    if (this.parentMatterParticipant) {
      if (this.parentMatterParticipant.contact.isMortgagee) {
        return ContactTab.createContactTab(source, 'main/contacts/contact/MORTGAGEE', source.contactType, 'my-contacts');
      } else if (this.parentMatterParticipant.contact.isCondoCorporation) {
        return ContactTab.createContactTab(source, 'main/contacts/contact/CONDO_CORPORATION', source.contactType, 'my-contacts');
      } else if (this.parentMatterParticipant.contact.isManagementCompany) {
        return ContactTab.createContactTab(source, 'main/contacts/contact/MANAGEMENT_COMPANY', source.contactType, 'my-contacts');
      }
    }

    return null;

  }

  setLocalInstancesForMassUpdate(mortgageId: number, parentMatterParticipant: MatterParticipant, matter: Matter, attentionList: Contact[]) {
    this.matter = matter;
    this.mortgageId = mortgageId;
    this.parentMatterParticipant = parentMatterParticipant;
    this.attentionList = attentionList;

  }

}
