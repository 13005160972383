<div class="container-fluid custom-modal-container border-radius" >
    <div class="row modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10"><h1>{{header}}</h1></div>
            <button type="button" class="btn-close pull-right" (click)="cancel()" >&times;</button>
        </div>
    </div>
    <div class="row modal-body">
        <div class="insurerDialog">
            <div class="col-lg-12">
                    <form class="form-horizontal">
                        <div class="form-group">
                        <ul class="clear">
                            <li *ngFor="let item of insurers; let i = index" class="col-lg-3">
                                <div [user-access]="{'operationName': getOperationName(item.organizationName)}" *ngIf="i < 4"
                                     [ngClass]="{'margin-left-minus-35' : insurers[i].organizationName == insurersOrganizationNames.TP}">
                                    <span class="radio" (disabled)="isInsurerDisabled(item.organizationName)">
                                        <input type="radio"
                                               id="insurer-{{i}}"
                                               name="insurer"
                                               [checked]="selectedInsurerIndex === i"
                                               [disabled]="isInsurerDisabled(item.organizationName)"
                                        />
                                        <label tabindex="0" for="insurer-{{i}}"
                                               [ngClass]="{'focus-first': selectedInsurerIndex === i}"
                                               (click)="updateInsurer(i)"
                                               (keydown.space)="updateInsurer(i)" (disabled)="isInsurerDisabled(item.organizationName)"
                                        ></label>
                                    </span>
                                    <span class="img" *ngIf="insurers[i].organizationName == insurersOrganizationNames.STG && matter.isMatterProvinceMB" ngClass="lsstewart"></span>
                                    <span class="img" *ngIf="insurers[i].organizationName == insurersOrganizationNames.STG && !matter.isMatterProvinceMB" ngClass="stewart"></span>
                                    <span class="img" *ngIf="insurers[i].organizationName == insurersOrganizationNames.FCT" ngClass="fct"></span>
                                    <span class="img" *ngIf="insurers[i].organizationName == insurersOrganizationNames.CTI" ngClass="chicago"></span>
                                    <span class="img float-none" *ngIf="insurers[i].organizationName == insurersOrganizationNames.TP" ngClass="titlePlus"></span>
                                    <div class="contactGroup clear" [ngClass] = "{ 'padding-top-20' : insurers[i].organizationName != insurersOrganizationNames.TP}">
                                        <a href="javascript:void(0);" (click)="toggleShutterState(i)">
                                            <i class="fa fa-caret-down" aria-hidden="true" *ngIf="shutterFlag[i]"></i>
                                            <i class="fa fa-caret-up"   aria-hidden="true" *ngIf="!shutterFlag[i]"></i>
                                            <u>Contact Info</u>
                                        </a>
                                        <div *ngIf="shutterFlag[i]">
                                            <span>{{insurers[i].organizationName}}</span>
                                            <span *ngIf="insurers[i].address[0]">{{insurers[i].address[0].addressLine1}}</span>
                                            <span *ngIf="insurers[i].address[0]">{{insurers[i].address[0].addressLine2}}</span>
                                            <span *ngIf="insurers[i].address[0]">{{insurers[i].address[0].city}},
                                                {{insurers[i].address[0].provinceName}} {{insurers[i].address[0].postalCode}}</span>
                                            <span class="break"></span>
                                            <span *ngIf="insurers[i].organizationName !== insurersOrganizationNames.STG">
                                              <span *ngIf="i!==0">Tel: {{getPhoneNumbersBasedOnType(insurers[i].telephone,"WORK")}}</span>
                                              <span *ngIf="i!==0">Fax: {{getPhoneNumbersBasedOnType(insurers[i].telephone,"FAX")}}</span></span>
                                            <span *ngIf="insurers[i].organizationName == insurersOrganizationNames.STG">
                                                <p *ngIf="insurers[i].telephone.length > 0">Stewart Title Software Support: {{insurers[i].telephone[0].telephoneNumber}}</p>
                                                <span class="break"  *ngIf="insurers[i].telephone.length > 1"></span>
                                                <p *ngIf="insurers[i].telephone.length > 1">Stewart Title Underwriting: {{insurers[i].telephone[1].telephoneNumber}}
                                                    <ng-container *ngIf="insurers[i].telephone.length > 2">or toll free at: {{insurers[i].telephone[2].telephoneNumber}}</ng-container></p>
                                            </span>
                                            <span class="break"></span>
                                            <span>Email:  <a href="mailto:{{insurers[i].email}}" target="_blank">{{insurers[i].email}}</a></span>
                                            <span>Website: <a href="http://{{insurers[i].websiteURL}}" target="_blank" class="margin-bottom-4">{{insurers[i].websiteURL}}</a></span>
                                        </div>
                                        <!-- <div *ngIf="insurers[i].organizationName == 'Stewart Title Guaranty Company'">
                                            <a href="https://www.stepsonline.ca/Transaction/PremiumCalculator"
                                               target="_blank">
                                                <i class="fa fa-external-link"></i> Premium Calculator
                                            </a>
                                        </div> -->
                                    </div>
                                    <div *ngIf="isInsurerDisabled(item.organizationName)" class="form-group-warning-message-small col-lg-6 margin-bottom-0">
                                        <div class="col-lg-1">
                                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-lg-5">
                                            <p>Not Available in {{matter.provinceCode}}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        </div>
                        <div class="form-group">
                        <ul class="clear">
                            <li *ngFor="let message of insurerValues; let j = index" class="col-lg-3  margin-top-20">
                                <div *ngIf="j > 3">
                                    <span class="radio" >
                                    <input type="radio"
                                           id="insurer-{{j}}"
                                           name="insurer"
                                           [checked]="selectedInsurerIndex === j"

                                    />
                                    <label  tabindex="0" for="insurer-{{j}}"
                                            [ngClass]="{'focus-first': (selectedInsurerIndex === j)||(!selectedInsurerIndex && j == insurerValues.length-1) }"
                                            (click)="updateInsurer(j)"
                                            (keydown.space)="updateInsurer(j)"
                                    ></label>
                                    </span>
                                    <span class="txt">{{insurerValues[j].label}}</span>

                                </div>

                            </li>
                        </ul>
                        </div>
                        <div class="form-group padding-left-40" *ngIf="isPropertyCommercial()">
                            <span>For Commercial Policies - Manually enter title insurance details in Other Title Insurer</span>
                        </div>
                    </form>
                </div>
        </div>
    </div>
    <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="closeModal()"
                        [disabled]="!hasSelectedRadio()"
                        class="dp-btn keyboard-save-action">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
    </div>
</div>
