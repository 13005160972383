<div class="row margin-bottom-20">
    <div class="col-lg-12">
        <div class="matter-title form-group">
            <h1>Manage Document Profile - {{documentProfileName}}
                <ul class="right margin-top-minus-10">
                    <li>
                        <button id="saveBtn"
                                type="button"
                                class="function-icons"
                                [ngClass]="{'icon-dirty' : isDirty}"
                                [dp-read-only]="!isDirty"
                                (click)="save()">
                            <i class="svg-icon save margin-left-5" aria-hidden="true"></i>
                        </button>
                        <h6 [ngClass]="{'icon-dirty' : isDirty}">Save</h6>
                    </li>
                </ul>
            </h1>
        </div>
    </div>
    <div  class="col-lg-12 admin-padding" id="spinner2"
          *ngIf="( !misc && !misc.sameAsDefaultProfileFlag) || (!miscDefault && misc && misc.sameAsDefaultProfileFlag)">
        <div class="text-center">
            <img src="assets/img/spinner.gif"/>
        </div>
    </div>
    <div class="col-lg-12 admin-padding" *ngIf="((misc && !misc.sameAsDefaultProfileFlag)  || (misc && misc.sameAsDefaultProfileFlag && miscDefault))">
        <div class="form-horizontal row">
            <div class="col-lg-12">
                <!-- Default Matter Profile checkbox -->
                <div class="form-group">
                    <div class="col-lg-4 offset-lg-6 margin-top-20">
                        <dp-checkbox fieldId="sameAsDefaultProfileFlag">
                            <input id="sameAsDefaultProfileFlag"
                                   name="sameAsDefaultProfileFlag"
                                   type="checkbox"
                                   [(ngModel)]="misc.sameAsDefaultProfileFlag"
                                   [disabled]="documentProfile.defaultProfileFlag"
                                   (ngModelChange)="sameAsDefaultProfileOnchange($event)"
                                   [title]="sameAsDefaultProfile.checked ? 'Check this box to adopt those settings in the firm defaults for all fields in this tab' : 'Editing within this tab is disabled because settings of firm defaults have been adopted'"
                                   #sameAsDefaultProfile>
                        </dp-checkbox>
                        <label for="sameAsDefaultProfileFlag">Same As Default Document Profile</label>
                    </div>
                </div>

                <!-- Address Format -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Address Format
                    </label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="miscAddressFormatCode"
                                name="miscAddressFormatCode"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().miscAddressFormatCode">
                            <option *ngFor="let item of addressFormat"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- No. of Characters comprising signing line -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Number of characters comprising signing line
                    </label>
                    <div class="col-lg-2">
                        <input type="text"
                               dp-onlynumeric
                               class="form-control"
                               id="charactersInSigningLine"
                               name="charactersInSigningLine"
                               [fieldKey]="'admin.miscDocumentProfile.charactersInSigningLine'"
                               statusBarHelp
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               (keypress)="enforceNumericRules($event)"
                               [(ngModel)]="getMiscDocumentProfile().charactersInSigningLine"
                               [maxlength]="2"
                               min="0"
                               max="99"
                        />
                    </div>
                </div>

                <!-- Auto insert file no. at top of polaris forms -->
                <div class="form-group" *dpShowByProvince="'admin.documentProfile.misc.autoInsertFileNoFlag';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Automatically insert File No. at top of polaris forms?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="autoInsertFileNoFlag"
                                name="autoInsertFileNoFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().autoInsertFileNoFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Automatically create TELE P/S/M file during document production -->
                <div class="form-group" *dpShowByProvince="'admin.documentProfile.misc.autoCreateTeleFileFlag';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Automatically create TELE P/S/M file during document production
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="autoCreateTeleFileFlag"
                                name="autoCreateTeleFileFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().autoCreateTeleFileFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Automatically create TELE P/S/M file during document production -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        F9 default for "Dear" fields
                    </label>
                    <div class="col-lg-4">
                        <input type="text"
                               class="form-control"
                               id="defaultDearFieldsCode"
                               name="defaultDearFieldsCode"
                               maxlength="30"
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               [(ngModel)]="getMiscDocumentProfile().defaultDearFieldsCode"
                        />
                    </div>
                </div>

                <!-- Documents signed by a corporation to always have two signing lines? -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Documents signed by a corporation to always have two signing lines?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="twoSigningLinesFlag"
                                name="twoSigningLinesFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().twoSigningLinesFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Statutory declarations for a corporation to be signed by only one officer? -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Statutory declarations for a corporation to be signed by only one officer?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="statutoryDeclarationsFlag"
                                name="statutoryDeclarationsFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().statutoryDeclarationsFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Include "bind the corporation" in signing lines? -->
                <div class="form-group"  *dpShowByProvince="'admin.documentProfile.misc.bindTheCorporationFlag';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Include "bind the corporation" in signing lines?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="bindTheCorporationFlag"
                                name="bindTheCorporationFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().bindTheCorporationFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Bold corp names in signing lines in documents produced? -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Bold corp names in signing lines in documents produced?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="boldCorpNamesFlag"
                                name="boldCorpNamesFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().boldCorpNamesFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Bold corp names in signing lines in documents produced? -->
                <div class="form-group"  *dpShowByProvince="'admin.documentProfile.misc.defaultTextPolarisMortgageCode';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Default text for Box 9j of Polaris Mortgage
                    </label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="defaultTextPolarisMortgageCode"
                                name="defaultTextPolarisMortgageCode"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().defaultTextPolarisMortgageCode">
                            <option *ngFor="let item of defaultTextPolarisMortgageOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Use "Sworn before me" or "Declared before me" in signing lines? -->
                <div class="form-group"  *dpShowByProvince="'admin.documentProfile.misc.swornBeforeMeOrDeclaredBeforeMe';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Use "Sworn before me" or "Declared before me" in signing lines?
                    </label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="declaredOrSwornCode"
                                name="declaredOrSwornCode"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().declaredOrSwornCode">
                            <option *ngFor="let item of declaredOrSwornOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>


                <!-- Automatically complete "Planning Act" (boxes 13 & 14) of Polaris Deed? -->
                <div class="form-group" *dpShowByProvince="'admin.documentProfile.misc.autoCompletePlanningActFlag';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Automatically complete "Planning Act" (boxes 13 & 14) of Polaris Deed?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="autoCompletePlanningActFlag"
                                name="autoCompletePlanningActFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().autoCompletePlanningActFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- WP print command -->
                <div class="form-group" *dpShowByProvince="'admin.documentProfile.misc.wpPrintCommand';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        WP print command
                    </label>
                    <div class="col-lg-4">
                        <input type="text"
                               class="form-control"
                               id="wpPrintCommand"
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               name="wpPrintCommand"
                               [(ngModel)]="getMiscDocumentProfile().wpPrintCommand"
                               maxlength="20"
                        />
                    </div>
                </div>

                <!-- Number of characters per line in: Statement of Accounts -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Number of characters per line in: Statement of Accounts
                    </label>
                    <div class="col-lg-2">
                        <input type="text"
                               dp-onlynumeric
                               class="form-control"
                               id="charactePerLineSOA"
                               name="charactePerLineSOA"
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               [(ngModel)]="getMiscDocumentProfile().charactePerLineSOA"
                               (keypress)="enforceNumericRules($event)"
                               maxlength="2"
                               min="0"
                               max="99"
                        />
                    </div>
                </div>

                <!-- Number of characters per line in: Trust Ledger -->
                <div class="form-group">
                    <label
                        class="control-label col-lg-6"
                        [label-province-code]="account.selectedProvinceCode"
                        dp-province-field-label="admin.documentProfile.misc.trustLedger"
                    >
                        Number of characters per line in: Trust Ledger
                    </label>
                    <div class="col-lg-2">
                        <input type="text"
                               dp-onlynumeric
                               class="form-control"
                               id="characterPerLineTrustLedger"
                               name="characterPerLineTrustLedger"
                               (keypress)="enforceNumericRules($event)"
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               [(ngModel)]="getMiscDocumentProfile().characterPerLineTrustLedger"
                               maxlength="2"
                               min="0"
                               max="99"
                        />
                    </div>
                </div>

                <!-- Display jurisdiction where Purchase is executing doc'ts in a Sale txn? -->
                <div class="form-group">
                    <label class="control-label col-lg-6" [label-province-code]="account.defaultProvinceCode"
                    dp-province-field-label="admin.documentProfile.misc.displayJurisdiction">
                        Display jurisdiction where Purchaser is executing doc'ts in a Sale txn?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="displayJurisdictionSalesTax"
                                name="displayJurisdictionSalesTax"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().displayJurisdictionSalesTax">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Use side-by-side signing format for multiple parties in unsworn doc'ts? -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Use side-by-side signing format for multiple parties in unsworn doc'ts?
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="useSideBySideFlag"
                                name="useSideBySideFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().useSideBySideFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- If closing date is greater than  ____  insert default "execution of documents" month -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        If closing day is greater than
                    </label>
                    <div class="col-lg-2">
                        <input type="text"
                               dp-onlynumeric
                               class="form-control"
                               id="closingDateGreaterThan"
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               name="closingDateGreaterThan"
                               (keypress)="enforceNumericRules($event)"
                               [(ngModel)]="getMiscDocumentProfile().closingDateGreaterThan"
                               maxlength="2"
                               min="0"
                               max="20"
                        />
                    </div>
                    <label class="control-label col-lg-4">
                        insert default "execution of documents" month
                    </label>
                </div>

                <!-- Document production to use underscores rather than spaces for components of a date field which are blank
                 (e.g. January ____, 2009) -->
                <div class="form-group">
                    <label class="control-label col-lg-6">
                        Document production to use underscores rather than spaces for components of a date field which
                        are blank
                        (e.g. January ____, 2009)
                    </label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="underscoreFlag"
                                name="underscoreFlag"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().underscoreFlag">
                            <option *ngFor="let item of yesNoOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group"  *dpShowByProvince="'admin.documentProfile.misc.titleNoInPurchaserOption';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Title No. in a Purchaser's mortgage
                    </label>
                    <div class="col-lg-2">
                        <select class="form-control"
                                id="titleNoInPurchaserOption"
                                name="titleNoInPurchaserOption"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().titleNoInPurchaserOption">
                            <option *ngFor="let item of titleNoInPurchaserOption"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="getMiscDocumentProfile().titleNoInPurchaserOption == 'OTHER'">
                        <label class="control-label col-lg-1">
                            Specify
                        </label>
                        <div class="col-lg-3">
                            <input type="text"
                                   class="form-control"
                                   id="specifyTitleNoOther"
                                   [dp-read-only]="sameAsDefaultProfile.checked"
                                   name="specifyTitleNoOther"
                                   [(ngModel)]="getMiscDocumentProfile().specifyTitleNoOther"
                                   maxlength="60"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group"  *dpShowByProvince="'admin.documentProfile.misc.defaultAddressOfService';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Default address of service of Caveator (Box 5)
                    </label>
                    <div class="col-lg-2">
                        <select class="form-control"
                                id="defaultAddressOfService"
                                name="defaultAddressOfService"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().defaultAddressOfService">
                            <option *ngFor="let item of defaultAddressOfService"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group"  *dpShowByProvince="'admin.documentProfile.misc.manageUFFIWarranty';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Manage UFFI Warranty
                    </label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="uffiWarrantyCode"
                                name="uffiWarrantyCode"
                                [dp-read-only]="sameAsDefaultProfile.checked"
                                [(ngModel)]="getMiscDocumentProfile().uffiWarrantyCode"
                        >
                            <option *ngFor="let item of vendorWarrantyOptions" [value]="item.value">{{item.label}}</option>
                        </select>
                    </div>
                </div>
                <ng-container *ngIf="getMiscDocumentProfile() && getMiscDocumentProfile().contactDefaultEnvelopeData">
                    <div class="form-group"  >
                        <label class="control-label col-lg-6">
                            Contact Default for Male Envelope Salutation
                        </label>
                        <div class="col-lg-2">
                            <select class="form-control"
                                    id="contactDefaultMaleEnvelopeSalutation"
                                    name="contactDefaultMaleEnvelopeSalutation"
                                    [dp-read-only]="sameAsDefaultProfile.checked"
                                    [(ngModel)]="getMiscDocumentProfile().contactDefaultEnvelopeData.maleSalutation"
                            >
                                <option *ngFor="let item of contactDefaultMaleEnvelopeSalutations" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                        <div>
                            <label class="control-label col-lg-2">
                                Dear
                            </label>
                            <div class="col-lg-2">
                                <select class="form-control"
                                        id="maleDear"
                                        name="maleDear"
                                        [dp-read-only]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getMiscDocumentProfile().contactDefaultEnvelopeData.maleDear"
                                >
                                    <option *ngFor="let item of contactDefaultMaleDears" [value]="item.value">{{item.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group"  >
                        <label class="control-label col-lg-6">
                            Female Envelope Salutation
                        </label>
                        <div class="col-lg-2">
                            <select class="form-control"
                                    id="femaleSalutation"
                                    name="femaleSalutation"
                                    [dp-read-only]="sameAsDefaultProfile.checked"
                                    [(ngModel)]="getMiscDocumentProfile().contactDefaultEnvelopeData.femaleSalutation"
                            >
                                <option *ngFor="let item of contactDefaultFemaleEnvelopeSalutations" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                        <label class="control-label col-lg-2">
                            Dear
                        </label>
                        <div class="col-lg-2">
                            <select class="form-control"
                                    id="femaleDear"
                                    name="femaleDear"
                                    [dp-read-only]="sameAsDefaultProfile.checked"
                                    [(ngModel)]="getMiscDocumentProfile().contactDefaultEnvelopeData.femaleDear"
                            >
                                <option *ngFor="let item of contactDefaultFemaleDears" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group"  >
                        <label class="control-label col-lg-6">
                            Couple (same last name) Envelope Salutation
                        </label>
                        <div class="col-lg-2">
                            <select class="form-control"
                                    id="coupleSalutation"
                                    name="coupleSalutation"
                                    [dp-read-only]="sameAsDefaultProfile.checked"
                                    [(ngModel)]="getMiscDocumentProfile().contactDefaultEnvelopeData.coupleSalutation"
                            >
                                <option *ngFor="let item of contactDefaultCoupleEnvelopeSalutations" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                        <label class="control-label col-lg-2">
                            Dear
                        </label>
                        <div class="col-lg-2">
                            <select class="form-control"
                                    id="coupleDear"
                                    name="coupleDear"
                                    [dp-read-only]="sameAsDefaultProfile.checked"
                                    [(ngModel)]="getMiscDocumentProfile().contactDefaultEnvelopeData.coupleDear"
                            >
                                <option *ngFor="let item of contactDefaultCoupleDears" [value]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                    </div>
                </ng-container>
                <div class="form-group" *dpShowByProvince="'admin.documentProfile.misc.excludeMineralParcels';code:account.selectedProvinceCode">
                    <div class="col-lg-4 offset-lg-6 margin-top-20">
                        <dp-checkbox fieldId="excludeMineralParcels">
                            <input id="excludeMineralParcels"
                                   name="excludeMineralParcels"
                                   type="checkbox"
                                   [dp-read-only]="sameAsDefaultProfile.checked"
                                   [(ngModel)]="getMiscDocumentProfile().excludeMineralParcels"
                                   />
                        </dp-checkbox>
                        <label class ="control-label padding-top-0" for="excludeMineralParcels">
                            Exclude Mineral Parcel from Sale Price Value
                        </label>
                    </div>
                </div>
                <div class="form-group" *dpShowByProvince="'admin.documentProfile.misc.interestRateSummary';code:account.selectedProvinceCode">
                    <label class="control-label col-lg-6">
                        Interest rate on late delivery of funds under protocol
                    </label>
                    <div class="col-lg-4">
                        <input type="text"
                               class="form-control"
                               id="interestRateOnLateDelivery"
                               name="interestRateOnLateDelivery"
                               maxlength="50"
                               [dp-read-only]="sameAsDefaultProfile.checked"
                               [(ngModel)]="getMiscDocumentProfile().interestRateSummary"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
