import {
  AdditionalConsiderationAmountTypes,
  InterimClosingAdjustmentFormatTypes,
  SoAdjConsiderationPaidOnInterimClosing
} from './so-adj-consideration-paid-on-interim-closing';
import {Component, Inject, ViewChild} from '@angular/core';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ModalResult} from '../../../../shared-main/enums';
import Utils from '../../../../shared-main/utils';
import {SelectItem} from 'primeng/api';
import {considerationPaidOnInterimClosingDropDowns} from '../../model/so-adj-drop-downs';
import {StatementAdjustmentUtil} from '../../statement-adjustment-util';
import {StatementAdjustment} from '../../statement-adjustment';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {StatementAdjustmentDisplayInterimClosingBuilder} from '../../builders/statement-adjustment-display-interim-closing-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../../model/statement-adjustment-display-item';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {ProvinceCode} from '../../../../admin/accounts/shared/base-province';
import {PROVINCE_CODES} from '../../../shared/user-province';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class ConsiderationPaidOnInterimClosingModalContext {
  public closingDate: string;
  public considerationPaidOnInterimClosing: SoAdjConsiderationPaidOnInterimClosing;
  public statementAdjustments: StatementAdjustment[];
  public salePriceAdjustment: SalePriceAdjustment;
  public statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  public adjustmentStatusMode: string;
  public provinceCode: ProvinceCode;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-consideration-paid-on-interim-closing-modal-content',
  templateUrl: 'consideration-paid-on-interim-closing.modal.component.html',
  styleUrls: ['./consideration-paid-on-interim-closing.modal.component.scss'],
  providers: [ErrorService, DialogService]
})
export class ConsiderationPaidOnInterimClosingModalComponent extends ModalComponent<ConsiderationPaidOnInterimClosingModalContext> {
  context: ConsiderationPaidOnInterimClosingModalContext;
  considerationPaidOnInterimClosing: SoAdjConsiderationPaidOnInterimClosing;
  adjustmentFormatOptions: SelectItem[] = considerationPaidOnInterimClosingDropDowns.interimClosingAdjFormatTypes;
  eligibleForHSTRebateItems: StatementOfAdjustmentDisplayItemLine[] = [];
  notEligibleForHSTRebateItems: StatementOfAdjustmentDisplayItemLine[] = [];
  notEligibleForHSTRebateInclHSTItems: StatementOfAdjustmentDisplayItemLine[] = [];
  eligibleForHSTRebateAmount: number;
  notEligibleForHSTRebateAmount: number;
  notEligibleForHSTRebateInclHSTAmount: number;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  // taxTypes : SelectItem[];
  // directionTypes : SelectItem[] = [{label : "Receivable", value : "RECEIVABLE"}, {label : "Payable", value : "PAYABLE"}];
  // yesNoOptions : SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  closingDateLabel: string = '';
  // statementAdjustmentDisplayUtil : StatementAdjustmentDisplayBuilder;
  // cachedDetails : StatementOfAdjustmentDisplayItemLine[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<ConsiderationPaidOnInterimClosingModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ConsiderationPaidOnInterimClosingModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.considerationPaidOnInterimClosing);
    }
  }

  checkForErrors() {
    if (
      !this.considerationPaidOnInterimClosing.adjustmentHeading ||
      this.considerationPaidOnInterimClosing.adjustmentHeading.length == 0
    ) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.consideration.paid.on.interim.closing.heading');
    }
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  ngOnInit() {
    if (this.context.considerationPaidOnInterimClosing) {
      this.considerationPaidOnInterimClosing = new SoAdjConsiderationPaidOnInterimClosing(
        this.context.considerationPaidOnInterimClosing
      );
    } else {
      this.considerationPaidOnInterimClosing = new SoAdjConsiderationPaidOnInterimClosing();
      this.considerationPaidOnInterimClosing.adjustmentHeading = 'Consideration Paid on Interim Closing';
      this.considerationPaidOnInterimClosing.adjustmentFormat = InterimClosingAdjustmentFormatTypes.ITEMIZED_ADJUSTMENT;
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
    this.getAdditionalDisplayItems();
    this.eligibleForHSTRebateAmount = StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(
      this.context.salePriceAdjustment,
      this.context.statementAdjustments,
      AdditionalConsiderationAmountTypes.ELIGIBLE_FOR_HST_REBATE
    );
    this.notEligibleForHSTRebateAmount = StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(
      this.context.salePriceAdjustment,
      this.context.statementAdjustments,
      AdditionalConsiderationAmountTypes.NOT_ELIGIBLE_FOR_HST_REBATE
    );
    this.notEligibleForHSTRebateInclHSTAmount = StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(
      this.context.salePriceAdjustment,
      this.context.statementAdjustments,
      AdditionalConsiderationAmountTypes.NOT_ELIGIBLE_FOR_HST_REBATE_INCL_HST
    );
  }

  getAdditionalDisplayItems() {
    const statementAdjustment: StatementAdjustment = new StatementAdjustment(
      this.context.adjustmentStatusMode,
      this.context.provinceCode
    );
    statementAdjustment.itemKey = StatementAdjustmentKey.CONSIDERATION_PAID_ON_INTERIM_CLOSING;
    statementAdjustment.soAdjConsiderationPaidOnInterimClosing = this.considerationPaidOnInterimClosing;
    this.getDisplayItems(statementAdjustment, this.context.statementAdjustmentDisplayUtil);
  }

  getCreditPurchaserAmount(): number {
    return StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(
      this.context.salePriceAdjustment,
      this.context.statementAdjustments,
      null
    );
  }

  // getEligibleForHSTRebateAmount() : number {
  //     return StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(this.context.salePriceAdjustment, this.context.statementAdjustments,
  //                                                                               AdditionalConsiderationAmountTypes.ELIGIBLE_FOR_HST_REBATE);
  //
  // }
  //
  // getNotEligibleForHSTRebateAmount() : number {
  //
  //     return StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(this.context.salePriceAdjustment, this.context.statementAdjustments,
  //                                                                               AdditionalConsiderationAmountTypes.NOT_ELIGIBLE_FOR_HST_REBATE);
  // }
  //
  // getNotEligibleForHSTRebateInclHSTAmount() : number {
  //     return StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(this.context.salePriceAdjustment, this.context.statementAdjustments,
  //                                                                               AdditionalConsiderationAmountTypes.NOT_ELIGIBLE_FOR_HST_REBATE_INCL_HST);
  //
  // }

  getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder) {
    // let sadItemDetails : StatementOfAdjustmentDisplayItemLine[] = [];

    if (
      item.soAdjConsiderationPaidOnInterimClosing &&
      !item.soAdjConsiderationPaidOnInterimClosing.isCreditPurchaserOnly()
    ) {
      this.eligibleForHSTRebateItems = StatementAdjustmentDisplayInterimClosingBuilder.getDetails1(
        this.context.statementAdjustments,
        this.context.statementAdjustmentDisplayUtil,
        true
      );
      this.notEligibleForHSTRebateItems = StatementAdjustmentDisplayInterimClosingBuilder.getDetails2(
        this.context.statementAdjustments,
        this.context.statementAdjustmentDisplayUtil,
        true
      );
      this.notEligibleForHSTRebateInclHSTItems = StatementAdjustmentDisplayInterimClosingBuilder.getDetails3(
        this.context.statementAdjustments,
        this.context.statementAdjustmentDisplayUtil,
        true
      );
    }

    // let amount : number = StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(mainBuilder.salePriceAdjustment, mainBuilder.matter.statementOfAdjustments, null);
    // sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Purchaser`, mainBuilder.getCurrency(amount)));
    //
    // return sadItemDetails;
  }

  isProvinceAB(): boolean {
    return this.context.provinceCode === PROVINCE_CODES.ALBERTA;
  }

  getLabelTitle(): string {
    if (this.isProvinceAB) {
      return 'GST';
    } else {
      return 'HST';
    }
  }
}
