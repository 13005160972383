import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Matter} from '../../../shared/matter';
import {Mortgage} from '../../../shared/mortgage';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {ContactService} from '../../../../shared-main/contact.service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Logger} from '@nsalaun/ng-logger';
import {MatterParticipant} from '../../../shared/matter-participant';
import {messages} from '../../../../common/messages';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import * as _ from 'lodash';
import {GuarantorLastNameFieldCodes} from '../../../../shared-main/constants';
import {Contact} from '../../../shared/contact';
import {CommonContactDialogComponent} from '../../../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {MatterTab} from '../../../matter-tab';
import {Subscription} from 'rxjs';
import {StatusBarService} from '../../../../shared-main/status-bar.service';
import {TabsService} from '../../../../core/tabs.service';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {DocumentProductionService} from '../../../document-production/document-production.service';
import {ProvinceCode} from '../../../../admin/accounts/shared/base-province';
import {IvfGeneratorBase} from '../../../shared/ivf-generator-base';
import {MatterParticipantService} from '../../../matter-participant-service';
import {MatterParticipantRoleTypes} from '../../../shared/matter-participant-role-types';
import {ContactChangesListener} from '../../../../contact/contact-changes-listener';

declare var jQuery: any;

@Component({
  selector: 'dp-mortgage-guarantor',
  templateUrl: 'mortgage-guarantor.component.html',
  providers: [ CurrencyPipe ]
})
export class MortgageGuarantorComponent extends IvfGeneratorBase implements OnInit, OnDestroy {
  @Input() matter: Matter;
  @Input() mortgage?: Mortgage;
  @Input() isContactProjectTransferor: boolean = false;
  @Output() onChange = new EventEmitter();

  guarantorOpenedShutterIndex: number = -1;
  selection: string = 'profile';
  currentTimeStamp = Date.now();
  statusCompleteFlag: boolean = false;

  constructor(public contactQueryService: ContactQueryService,
              public appConfig: AppConfig,
              public contactService: ContactService,
              public logger: Logger,
              public dialogService: DialogService,
              public errorService: ErrorService,
              public statusBarService: StatusBarService,
              public documentProductionService: DocumentProductionService,
              public contactChangesListener: ContactChangesListener,
              public tabsService: TabsService, public matterParticipantService: MatterParticipantService) {
    super(documentProductionService, dialogService, statusBarService);
  }

  ngOnInit(): void {

  }

  get currentMatter(): Matter {
    return this.matter;
  }

  getMatterProvince(): ProvinceCode {
    return this.currentMatter.provinceCode;
  }

  isExpanded(selectedGuarantorIndex: number): boolean {
    return selectedGuarantorIndex === this.guarantorOpenedShutterIndex;
  }

  toggleGuarantorShutter(selectedGuarantorIndex: number) {
    //if selectedGuarantorIndex is same as guarantorOpenedShutterIndex that means it was already opened so closing it by changing to -1
    if (selectedGuarantorIndex === this.guarantorOpenedShutterIndex) {
      this.guarantorOpenedShutterIndex = -1;
    } else {
      //else setting guarantorOpenedShutterIndex as selectedGuarantorIndex so it opens
      this.guarantorOpenedShutterIndex = selectedGuarantorIndex;
      this.selection = 'profile';
    }
  }

  showIdWarningForGuarantor(guarantor: MatterParticipant): boolean {
    const enteredOnRegExp: RegExp = /^(((19|20)([2468][048]|[13579][26]|0[48])|2000)\/02\/29|((19|20)[0-9]{2}\/(0[469]|11)\/(0[1-9]|[12][0-9]|30)|(19|20)[0-9]{2}\/(0[13578]|1[02])\/(0[1-9]|[12][0-9]|3[01])|(19|20)[0-9]{2}\/02\/(0[1-9]|1[0-9]|2[0-8])))$/;
    return guarantor && guarantor.contact && (!enteredOnRegExp.test(guarantor.contact.enteredOn) || !guarantor.contact.idInfoEnteredBy);
  }

  onClickIdWarningIcon(selectedGuarantorIndex: number, selectedTab: string, guarantor: MatterParticipant): void {
    if (selectedGuarantorIndex === this.guarantorOpenedShutterIndex) {
      this.guarantorOpenedShutterIndex = -1;
    } else {
      //else setting guarantorOpenedShutterIndex as selectedGuarantorIndex so it opens
      this.guarantorOpenedShutterIndex = selectedGuarantorIndex;
    }
    this.selectTab(selectedTab, guarantor);
  }

  selectTab(selectedTab: string, guarantor: MatterParticipant): void {
    this.selection = selectedTab || 'Profile';
    if (selectedTab === 'idDetails') {
      guarantor.contact.initializeContactIdDetails();
    }
  }

  getGuarantorBurgerMenuItems(index: number, length: number): any {
    let menuItems = [];
    if (index > 0) {
      menuItems.push('Move Up');
    }
    if (index < length - 1) {
      menuItems.push('Move Down');
    }
    menuItems.push('Delete');

    return menuItems;
  }

  clickGuarantorBurgerMenu(event, selectedGuarantor: MatterParticipant) {
    this.logger.info('clickBurgerMenu | event:', event);
    switch (event) {
      case 'Delete': {
        this.deleteGuarantor(selectedGuarantor);
        break;
      }
      case 'Move Up': {
        this.moveUpGuarantor(selectedGuarantor);
        break;
      }
      case 'Move Down': {
        this.moveDownGuarantor(selectedGuarantor);
        break;
      }
      default: {
        break;
      }
    }
  }

  deleteGuarantor(selectedGuarantor: MatterParticipant): void {
    let deleteConfirmationMessage = messages.mortgageTerm.deleteConfirmation;
    deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, selectedGuarantor, MatterParticipantRoleTypes.GUARANTOR);
    let postalCodeKey = 'guarantor.address.postalCode';
    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(res => {
      if (res == true) {
        this.enableSave();

        let selectedParticipantIndex: number = _.findIndex(this.currentMatter.matterParticipants, function (p: MatterParticipant) {
          return p === selectedGuarantor;
        });
        if (this.matter) {
          this.matter.getChildMatterParticipants(selectedGuarantor).forEach(matterParticipant => {
            (<any>this.matter.matterParticipants).remove(matterParticipant);
          });
        }
        let guarantorIndex: number = _.findIndex(this.currentMatter.guarantors, function (p: MatterParticipant) {
          return p === selectedGuarantor;
        });
        if (selectedGuarantor.contact) {
          if (selectedGuarantor.contact && selectedGuarantor.contact.address && selectedGuarantor.contact.address.length > 0) {
            this.errorService.removeDpFieldError(postalCodeKey, 'postalCode_' + selectedGuarantor.contact.address[ 0 ].identifier);
          }
          let errorPrefix: string = this.isContactProjectTransferor ? 'project.docsReg.transferor' : 'matter.mortgages.1st.mortgagee.terms';
          this.errorService.removeDpFieldError(errorPrefix + '.genderChangeProhibited_gender_' + selectedGuarantor.identifier);
          this.errorService.removeDpSaveError(errorPrefix + '_name_' + selectedGuarantor.identifier);
        }

        // this.errorService.removeDpFieldError('matter.mortgages.1st.mortgagee.terms_genderChangeProhibited');
        this.currentMatter.matterParticipants.splice(selectedParticipantIndex, 1);

        this.currentMatter.removeReconciledBorrowersByMatterParticipant(selectedGuarantor);
        this.handleDeleteContact(selectedGuarantor);
      }
    });
  }

  moveUpGuarantor(selectedGuarantor: MatterParticipant): void {
    if (this.isContactProjectTransferor) {
      this.currentMatter.reorderParticipant(selectedGuarantor, true);
    } else {
      this.currentMatter.reorderParticipantByMortgage(selectedGuarantor, true, this.mortgage);
    }

    this.enableSave();
  }

  moveDownGuarantor(selectedGuarantor: MatterParticipant): void {
    if (this.isContactProjectTransferor) {
      this.currentMatter.reorderParticipant(selectedGuarantor, false);
    } else {
      this.currentMatter.reorderParticipantByMortgage(selectedGuarantor, false, this.mortgage);
    }
    this.enableSave();
  }

  enableSave(): void {
    if (this.isContactProjectTransferor) {
      this.onChange.emit();
    }
    this.matter.dirty = true;
  }

  getFieldCodeMapForGuarantor(type: string, matterParticipant: MatterParticipant): any {
    if (type == 'LAST_NAME') {
      return {lastName: GuarantorLastNameFieldCodes[ this.mortgageIndex - 1 ][ this.matter.getGuarantors(this.mortgage).findIndex(item => item == matterParticipant) ]};

    }
  }

  get mortgageIndex(): number {
    let index = this.matter.mortgages.findIndex(item => item.id == this.mortgage.id);
    return index >= 0 ? (Number(index) + 1) : index;
  }

  onGuarantorChange(guarantor: Contact): void {
    // // guarantor.updateIdentificationRecordOfficerNames();
    // const nameOnId: string = guarantor.surnameLastFullName || '??????';
    // if (guarantor.identificationRecords) {
    //     guarantor.identificationRecords.forEach((r: IdentificationRecord) => this.updateIdentificationDocuments(r, nameOnId));
    // }
    this.enableSave();
  }

  addGuarantor(): void {
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        contactType: this.isContactProjectTransferor ? 'TRANSFEROR' : 'GUARANTOR'
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {
            this.currentTimeStamp = Date.now();
            this.silentAddGurantor(result.contact);
            this.enableSave();
          }

        }
      },
      fullScreen: true,
      
    });

  }

  silentAddGurantor(guarantor: Contact): void {
    let matterParticipant: MatterParticipant;
    if (this.isContactProjectTransferor) {
      matterParticipant = this.currentMatter.addMatterParticipant(guarantor, false, 'TRANSFEROR');
    } else {
      matterParticipant = this.currentMatter.addMatterParticipant(guarantor, false, 'GUARANTOR', this.mortgage.id);
    }
    this.matterParticipantService.createMatterParticipantAssociatedContactForClient(guarantor, matterParticipant, this.matter, undefined, true);
  }

  onClickPrintIdForms(): void {

    if (this.matter.dirty) {
      this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
        if (res && res == true) {
          let matterTab = this.tabsService.activeTab as MatterTab;
          if (matterTab && matterTab.matterComponent) {
            let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
              if (result) {
                setTimeout(() => {
                  //Need to put the following two lines in Timeout block, otherwise this matter is still not the updatedMatter due to unknown reason at this time, so the guarantors still without ID (needed for printIdForms)
                  let participantList: MatterParticipant[] = this.matter.getParticpantForPrintingIdVerification();
                  this.openPrintIdFormsModal(this.matter.id, participantList);
                }, 0);
                subscription.unsubscribe();
              }
            });
          }
        }
      });
    } else {
      let participantList: MatterParticipant[] = this.matter.getParticpantForPrintingIdVerification();
      this.openPrintIdFormsModal(this.matter.id, participantList);
    }
  }

  ngOnDestroy(): void {

    super.ngOnDestroy();
  }

  getGuarantorsOrTransferors(): MatterParticipant[] {
    if (this.isContactProjectTransferor) {
      return this.currentMatter.getParticipantsByRole('TRANSFEROR');
    } else {
      return this.currentMatter.getGuarantors(this.mortgage);
    }
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgage: Mortgage) {
    this.matter = matter;
    this.mortgage = mortgage;
  }

  handleDeleteContact(selectedGuarantor: MatterParticipant) {
    if (this.matter && selectedGuarantor && selectedGuarantor.contact) {
      this.contactChangesListener.handleContactChange(this.matter, selectedGuarantor, MatterParticipantRoleTypes.GUARANTOR, this.tabsService, true);
    }
  }
}
