import {Address} from './address';

export class BankAccount {
  constructor(bankAccount?: BankAccount) {
    if (bankAccount) {
      for (let prop in bankAccount) {
        if (bankAccount.hasOwnProperty(prop)) {
          this[ prop ] = bankAccount[ prop ];
        }
      }
      this.bankAddress = new Address(bankAccount.bankAddress);
    } else {
      this.bankAddress = new Address();
      this.bankAddress.addressTypeCode = 'MAILING';
    }
  }

  bankName: string;
  bankNumber: string;
  transitNumber: string;
  accountNumber: string;
  overrideBankAddress: boolean;
  bankAddress: Address;

  get summary(): string {
    let account = (this.bankNumber ? this.bankNumber : '') +
      (this.transitNumber ? '-' + this.transitNumber + '-' : '') +
      (this.accountNumber ? this.accountNumber : '');

    return (this.bankName ? this.bankName + ', ' : '') + account;

  }

  isEmpty(): boolean {
    return !this.bankName && !this.bankNumber && !this.transitNumber && !this.accountNumber;
  }

}
