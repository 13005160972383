import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Contact} from '../matters/shared/contact';
import {contactTypeMapping} from './contact-type-mapping';
import {DialogService} from '../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';
import {ErrorService} from '../shared/error-handling/error-service';

@Component({
  selector: 'dp-new-contact-private-info',
  templateUrl: 'new-contact-private-info.component.html'
})
export class NewContactPrivateInfo implements OnInit, OnDestroy {

  @Input() contact: Contact;
  @Input() contactKey: string;
  @Input() readonly: boolean;

  isFormDirty: boolean;
  selectedContactTypeMapping: any;
  genders: SelectItem[];
  currentTimeStamp = Date.now();

  //to keep the track of which shutter should be opened
  openShutterIndex: number;

  constructor(public dialogService: DialogService, public errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.selectedContactTypeMapping = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === 'PRIVATE_CONTACT');
    this.genders = this.selectedContactTypeMapping.genders;
    this.initializePrivateContacts();
  }

  ngOnDestroy(): void {

  }

  isFormValid(): boolean {
    if (this.contact.privateContacts && this.contact.privateContacts.persons && this.contact.privateContacts.persons.length == 1) {
      //if 1 item and clean, ret true
      let pristine = this.isPrivateContactUntouched(this.contact.privateContacts.persons[ 0 ]);
      if (pristine) {
        this.contact.privateContacts.clearPersons();
        return true;
      }
      //if 1 item and dirty and invalid, ret false
      if (!pristine && !this.isPrivateContactValid(this.contact.privateContacts.persons[ 0 ])) {
        return false;
      }
    }
    let isValid = true;
    for (let privateContact of this.contact.privateContacts.persons) {
      isValid = this.isPrivateContactValid(privateContact);
      if (!isValid) {
        break;
      }
    }
    return isValid;
  }

  public isPrivateContactUntouched(contact: Contact): boolean {
    let ret = false;
    if (contact.gender == 'QUESTION' &&
      !contact.contactName.lastName &&
      !contact.contactName.firstName &&
      !contact.contactName.middleName &&
      !contact.dear &&
      !contact.workPhone &&
      !contact.cellPhone &&
      !contact.email) {
      ret = true;
    }
    return ret;
  }

  public isPrivateContactValid(privateContact: Contact): boolean {
    let isValid = true;

    if (privateContact.contactName) {
      if (!privateContact.contactName.lastName || privateContact.contactName.lastName.trim().length == 0) {
        isValid = false;
      }
    }

    return isValid;
  }

  /**
   * Initialises public contacts if required for selected type of contact.
   */
  initializePrivateContacts() {
    //If public contact doesn't exist at all then creating a new one else use the existing one to add subContacts into the public contacts tab
    /*if(this.contact.privateContacts == null) {
        this.contact.privateContacts = new PrivateContacts();
    }*/

    //If there is no subContacts then adding a blank person to display the first one on UI for user to enter the data
    if (this.contact.privateContacts.persons == null || this.contact.privateContacts.persons.length === 0) {
      let privateContact = this.createNewPrivateContact();
      this.contact.privateContacts.addPrivateContact(privateContact);
      this.openShutterIndex = 0;
    }
  }

  ngAfterViewInit() {
    //this.contactUntouched = _.cloneDeep(this.contact);
  }

  createNewPrivateContact(): Contact {
    let privateContact: Contact = new Contact();
    let privateContactStaticInfo = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === 'PRIVATE_CONTACT');
    privateContact.contactType = privateContactStaticInfo.contactType;
    privateContact.instanceType = privateContactStaticInfo.instanceType;
    privateContact.canadianResidentFlag = 'Y_n';
    privateContact.partyRole = privateContactStaticInfo.partyRole;
    privateContact.activeFlag = 'Y_n';
    privateContact.privateFlag = privateContactStaticInfo.isPrivate;
    privateContact.gender = 'QUESTION';
    return privateContact;
  }

  deletePrivateContact(selectedPrivateContact: Contact): void {
    let surNameElementKey = 'privateContact.surname';
    let emailElementKey = 'privateContact.email';
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this attention?', false, 'Delete').subscribe(res => {
      if (res) {
        this.contact.privateContacts.deletePrivateContact(selectedPrivateContact);
        this.errorService.removeDpFieldError(surNameElementKey, 'surname_' + selectedPrivateContact.identifier);
        this.errorService.removeDpFieldError(emailElementKey, 'email_' + selectedPrivateContact.identifier);
        //After delete if there is no more public contact then add one blank contact to display on UI
        this.initializePrivateContacts();
      }
    });
  }

  isExpanded(selectedPersonIndex: number): boolean {
    return selectedPersonIndex === this.openShutterIndex;
  }

  addNewPrivateContact() {
    this.currentTimeStamp = Date.now();
    let privateContact = this.createNewPrivateContact();
    this.contact.privateContacts.addPrivateContact(privateContact);
    //Shutter should be open for the newly added public contact
    this.openShutterIndex = this.contact.privateContacts.persons.length - 1;
  }

  onGenderChange(selectedContact: Contact): void {

    if (!selectedContact.id && !selectedContact.sourceContactId) { //new
      selectedContact.updateNameIfGenderChange(selectedContact.gender, selectedContact.localGender);
      selectedContact.resetContactOnGenderChange(selectedContact.localGender, 'PRIVATE_CONTACT');
      selectedContact.gender = selectedContact.localGender;

    } else {               // Existing contact
      if (selectedContact.genderChangeValid()) {
        selectedContact.updateNameIfGenderChange(selectedContact.gender, selectedContact.localGender);
        selectedContact.resetContactOnGenderChange(selectedContact.localGender, 'PRIVATE_CONTACT');
        selectedContact.gender = selectedContact.localGender;
      }
    }
  }
}
