import {Component, Inject, ViewChild} from '@angular/core';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {Matter} from '../shared/matter';
import {Deposit} from '../shared/deposit';
import {dropDowns} from '../shared/matter-drop-downs';
import {DPError} from '../../shared/error-handling/dp-error';
import {MatterService} from '../matter.service';
import Utils from '../../shared-main/utils';
import {DocumentProductionService} from '../document-production/document-production.service';
import {ERegistrationService} from '../forms/eregistration/eregistration.service';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class Form4SpecificationModalContext {
  matter: Matter;
  selectedDeposit: Deposit;
  initiatedFromTab: string;
}

@Component({
  selector: 'dp-form-4-specification',
  templateUrl: 'form-4-specification.modal.component.html',
  providers: [ErrorService]
})
export class Form4SpecificationModalComponent extends ModalComponent<Form4SpecificationModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  depositPrintFormatOptions: SelectItem[];

  constructor(
    public dialog: MatDialogRef<Form4SpecificationModalComponent>,
    public dialogService: DialogService,
    public matterService: MatterService,
    public errorService: ErrorService,
    public eRegistrationService: ERegistrationService,
    public documentProductionService: DocumentProductionService,
    @Inject(MAT_DIALOG_DATA) context?: Form4SpecificationModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    //The "FOR" field (depositPrintFormat) should always default back to SELECTED_DEPOSIT, DPPMP-31987
    this.selectedDeposit.depositPrintFormat = 'SELECTED_DEPOSIT';
    if (this.context && this.context.initiatedFromTab === 'DepositListManager') {
      this.depositPrintFormatOptions = dropDowns.depositPrintOptionsFromManager;
    } else {
      this.depositPrintFormatOptions = dropDowns.depositPrintOptionsFromSOA;
    }
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get selectedDeposit(): Deposit {
    return this.context.selectedDeposit;
  }

  //This method returns the deposit amount as per the option selected in depositPrintFormat.
  get depositAmountPerPrintFormat(): number {
    if (this.selectedDeposit.depositPrintFormat == 'SELECTED_DEPOSIT') {
      return Utils.toNumber(this.selectedDeposit.depositAmount);
    } else if (this.selectedDeposit.depositPrintFormat == 'AGGREGATE_DEPOSITS_EXCEPT_OCCUPANCY') {
      return this.calculateTotalDepositsExcludingOccupancy();
    } else {
      return 0.0;
    }
  }

  get isDepositAmountVisible(): boolean {
    return (
      this.selectedDeposit.depositPrintFormat == 'SELECTED_DEPOSIT' ||
      this.selectedDeposit.depositPrintFormat == 'AGGREGATE_DEPOSITS_EXCEPT_OCCUPANCY'
    );
  }

  calculateTotalDepositsExcludingOccupancy(): number {
    if (
      !this.matter.matterPropertyWithCondo.depositsExcludePayOnOccupancy ||
      this.matter.matterPropertyWithCondo.depositsExcludePayOnOccupancy.length == 0
    ) {
      return 0.0;
    }
    return this.matter.matterPropertyWithCondo.depositsExcludePayOnOccupancy.reduce(
      (sum: number, deposit: Deposit): number => {
        return sum + Utils.toNumber(deposit.depositAmount);
      },
      0.0
    );
  }

  onDepositDateChange(event): void {
    if (event.rawDate !== this.selectedDeposit.depositDate) {
      this.selectedDeposit.form4Date = event.rawDate;
    }
  }

  saveDepositForm(isPrintRequired: boolean): void {
    //if form4Date is not empty then it cannot be partial
    if (
      this.errorService.hasNoErrors() &&
      this.selectedDeposit.form4Date &&
      this.selectedDeposit.form4Date.length > 0 &&
      !Utils.isFullDate(this.selectedDeposit.form4Date)
    ) {
      this.errorService.addDpFieldError(DPError.createDPError('form4.sentDate'));
    }

    if (this.errorService.hasNoErrors()) {
      if (this.context.initiatedFromTab == 'DepositListManager') {
        this.saveMatter(isPrintRequired);
      } else {
        this.dialog.close({action: 'OK', updatedDeposit: this.selectedDeposit, isPrintRequired: isPrintRequired});
      }
    }
  }

  saveMatter(isPrintRequired: boolean): void {
    this.matterService
      .validateMatter(this.matter, null, this.errorService)
      .finally(() => {})
      .subscribe((matterValidated) => {
        if (matterValidated) {
          this.matter.cleanUpMatterBeforeSaving(this.matterService);
          this.matterService.saveMatter(this.matter).subscribe(
            (matter: Matter) => {
              this.dialog.close({action: 'OK', updatedDeposit: this.selectedDeposit, isPrintRequired: isPrintRequired});
            },
            (error: any) => {
              this.errorService.addDpSaveError(
                DPError.createCustomDPError(error.errorCode, error.errorCode + ':' + error.errorMessage, null, 'ERROR')
              );
            }
          );
        }
      });
  }

  close(): void {
    this.dialog.close({action: 'cancel'});
  }
}
