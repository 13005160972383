import {Component, Inject, ViewChild} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {Matter} from '../../../shared';
import {SoaExpenseAdjustment} from './soa-expense-adjustment';
import {SelectItem} from 'primeng/api';
import Utils from '../../../../shared-main/utils';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {SoaExpenseAdjustmentUtil} from './soa-expense-adjustment-util';
import {ModalResult} from '../../../../shared-main/enums';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class SoaExpenseAdjustmentModalContext {
  public closingDate: string;
  public soaCommonExpenseAmount: number;
  public soaExpenseAdjustment: SoaExpenseAdjustment;
  public provinceHstRateSlab: ConsiderationTaxes;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public addedFlag: boolean;
  public matter: Matter;
  public expenseType: string;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-reserve-fund-soa-modal-content',
  templateUrl: './soa-expense-adjustment.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class SoaExpenseAdjustmentModal extends ModalComponent<SoaExpenseAdjustmentModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = 'Closing Date';
  soaExpenseAdjustment: SoaExpenseAdjustment;
  addedFlag: boolean;
  taxTypeDropDown: SelectItem[];
  yesNoDropDown: SelectItem[];
  atMonthlyAmountOfDropDown: SelectItem[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<SoaExpenseAdjustmentModal>,
    public currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: SoaExpenseAdjustmentModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.initDropDowns();
    if (!this.context.soaExpenseAdjustment) {
      this.soaExpenseAdjustment = new SoaExpenseAdjustment();
      this.newAdj = true;
    } else {
      this.soaExpenseAdjustment = new SoaExpenseAdjustment(this.context.soaExpenseAdjustment);
    }
    if (!this.soaExpenseAdjustment.taxType) {
      this.soaExpenseAdjustment.taxType = this.appliedTaxRate();
    }
    if (!this.soaExpenseAdjustment.applyTax) {
      this.soaExpenseAdjustment.applyTax = 'NO';
    }
    if (!this.soaExpenseAdjustment.adjustPercentage) {
      this.soaExpenseAdjustment.adjustPercentage = this.appliedRate();
    }
    if (!this.soaExpenseAdjustment.expenseType) {
      this.soaExpenseAdjustment.expenseType = this.context.expenseType;
    }
    if (!this.soaExpenseAdjustment.monthlyAmountType && this.soaExpenseAdjustment.isAdjustmentTypeSaleIncentive()) {
      this.soaExpenseAdjustment.monthlyAmountType = 'COMMON_EXPENSE_AMOUNT';
    }

    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  soaCommonExpenseAmount(soaExpenseAdjustment: SoaExpenseAdjustment): number {
    return SoaExpenseAdjustmentUtil.soaCommonExpenseAmount(soaExpenseAdjustment, this.context.soaCommonExpenseAmount);
    /*if(soaExpenseAdjustment.isAdjustmentTypeSaleIncentive() && soaExpenseAdjustment.isAdditionalAmountApplicable()){
        if(soaExpenseAdjustment.isOtherAmountSelected()){
            return Number(soaExpenseAdjustment.additionalMonthlyAmount) ;
        }else{
            return this.context.soaCommonExpenseAmount ? (Number(this.context.soaCommonExpenseAmount) + Number(soaExpenseAdjustment.additionalMonthlyAmount)) : 0;
        }
    }else{
        return this.context.soaCommonExpenseAmount ? this.context.soaCommonExpenseAmount : 0;
    }*/
  }

  get adjustmentTypeTitle() {
    return this.context.expenseType == StatementAdjustmentKey.RESERVE_FUND
      ? 'Reserve Fund'
      : this.context.matter.isMatterProvinceON
        ? 'Common Expenses - Sales Incentive'
        : this.context.matter.isMatterProvinceBC
          ? 'Strata Fees - Sales Incentive'
          : 'Condominium Fees - Sales Incentive';
  }

  appliedRate(): number {
    return this.soaExpenseAdjustment.appliedRate(this.context.provinceHstRateSlab);
  }

  public initDropDowns() {
    this.yesNoDropDown = [
      {
        label: 'Yes',
        value: 'YES'
      },
      {
        label: 'No',
        value: 'NO'
      }
    ];
    this.atMonthlyAmountOfDropDown.push(
      {label: this.context.soaCommonExpenseAmount.toString(), value: 'COMMON_EXPENSE_AMOUNT'},
      {
        label: this.context.soaCommonExpenseAmount.toString() + ' per month plus an additional monthly amount of',
        value: 'COMMON_EXPENSE_AMOUNT_PLUS_ADDITIONAL'
      },
      {label: 'other amount', value: 'OTHER_AMOUNT'}
    );

    if (this.context.matter.provinceCode == 'AB') {
      this.taxTypeDropDown = [{label: 'GST', value: 'GST'}];
    } else {
      this.taxTypeDropDown = [
        {label: 'HST', value: 'HST'},
        {label: 'GST', value: 'GST'}
      ];
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  ok(): void {
    this.dialog.close(this.soaExpenseAdjustment);
    /* this.checkErrorsOnModal();
     if(!this.modalErrorComponent.anyErrorExist()) {
         this.dialog.close({soaExpenseAdjustment : this.soaExpenseAdjustment});
     }*/
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (
      currencyValue != undefined &&
      !isNaN(currencyValue) &&
      currencyValue != null &&
      currencyValue.toString().trim() != ''
    ) {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  onChangeApplyTax(event) {
    this.soaExpenseAdjustment.applyTax = event;
  }

  updateReserveFundCalculations(soaExpenseAdjustment: SoaExpenseAdjustment, soaCommonExpenseAmount: number) {
    SoaExpenseAdjustmentUtil.updateCalculations(soaExpenseAdjustment, soaCommonExpenseAmount);
  }

  appliedTaxRate(): string {
    return this.soaExpenseAdjustment.appliedTaxRate(this.context.provinceHstRateSlab);
  }

  updateTaxRate(rateType: string): void {
    if (
      rateType == this.context.provinceHstRateSlab.rateType &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.hstRate
    ) {
      this.soaExpenseAdjustment.adjustPercentage = this.context.provinceHstRateSlab.hstRate;
    } else {
      this.soaExpenseAdjustment.adjustPercentage = this.context.provinceHstRateSlab.hstFederalPortion;
    }
  }

  updateMonthlyAmount(monthlyAmount: string): void {
    this.soaExpenseAdjustment.monthlyAmountType = monthlyAmount;
  }

  get isSalesIncentive(): boolean {
    return this.context.expenseType && this.context.expenseType == 'SALES_INCENTIVE';
  }
}
