import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IMyDate, IMyDateModel, IAngularMyDpOptions, AngularMyDatePickerDirective} from '@nodro7/angular-mydatepicker';
import moment from 'moment';
import {ErrorService} from '../error-handling/error-service';
import {DPError} from '../error-handling/dp-error';
import {ON_DEMAND_VALUE} from '../../shared-main/constants';
import {PublicHolidayService} from '../../shared-main/public-holiday/public-holiday.service';

@Component({
  selector: 'dp-partial-date',
  templateUrl: './partial-date.component.html',
  styleUrls: [
    './partial-date.styles.scss'
  ]
})
export class PartialDateComponent implements OnInit, AfterViewInit {

  constructor(public errorService: ErrorService,
              private e: ElementRef,
              private publicHolidayService: PublicHolidayService
  ) {
  }

  @Input() hideDateYear: boolean = false;
  @Input() showFormattedDateLabel: boolean = true;
  @Input() dateType: string;
  @Input() disableUntil: IMyDate;
  _inputDate: string;
  @Input()
  set inputDate(value: string) {
    this._inputDate = value;
    if (value == '//') {
      //stale data, need to do some self cleanup to set to null, even if user didn't update the data
      this.dateChange.emit({
        day: null,
        month: null,
        year: null,
        date: null, //This is populated only if it's a full date
        rawDate: null, //Temp data field until we have the date holding this value
        message: 'set date value from // to null'
      });
      return;
    }
    if (this._inputDate == undefined) {
      this.selectedDateNormal = null;//moment().format("DD/MM/YYYY");
    }
    // this.displayDate should be changed only MaturityDate with 'F8'
    //this.displayDate = !this.isOnDemand(value);
  }

  @Input() openCalendarPopupOnTop: boolean;
  @Input() dateDisable: string;
  @Input() dateFormat: string;
  @Input() fieldKey: string;
  @Input() fullDateRequired: boolean = false;
  @Input() disableAll: boolean = false;
  @Input() identifier: string;
  @Input() identifierId: string;
  @Input() containerId: string;
  @Input() groupId: string;
  @Input() parentId: string;
  @Input() customErrorMessagePrefix: string;
  @Input() fieldCode: string;
  @Input() errorTopic: string;

  /** A reference to a component that holds the date we're working on. The date field name is what's used for identifying the date */
  @Input() dateParent: any;
  @Input() dateFieldName: string;
  // @Input() mandatoryFields: string; // 'd/m/y' string, with the date components that are to be mandatory. '/m/y' mandates month and year
  @Input() customF9Behaviour: Function;
  //the data used by the Function Keys like F8, F9
  @Input() customF9ExternalDate: { dd: string, mm: string, yy: string, type: string };
  @Output() dateChange = new EventEmitter();
  @Output() focusIn = new EventEmitter();

  @ViewChild('refDateDay') refDateDay: ElementRef;
  @ViewChild('refDateMonth') refDateMonth: ElementRef;
  @ViewChild('refDateYear') refDateYear: ElementRef;
  @ViewChild('refOnDemand') refOnDemand: ElementRef;
  @ViewChild('myDatePicker') myDatePicker: any;

  dateParts: ElementRef[];
  focusedPartIndex: number;
  @Input() showFocusClass: boolean = false;
  @Input() hideHolidayAndWeekendDate: boolean = false;
  @Input() customStyleClass: string = '';

  // Initialize selector to zero - selector is closed
  public openCalendarWindow: number = 0;

  formattedDateLabel: string;
  dateDay: string;
  dateMonth: string;
  dateYear: string;
  dateHoliday: string;
  dateWeekendDay: string;
  date: string;
  dayRegularExpression: any;
  monthRegularExpression: any;
  yearRegularExpression: any;
  dayAndMonthRegularExpression: any;
  dateRegularExpression: any;
  dateErrorMessages: string[] = [];
  dateSamplingMessage: string;
  maxBirthdayDate: string;
  invalidFieldsCount: number;

  partsOfCalendarString: string[];
  selectedDateNormal: string = '';
  dateFormatting: string;
  myDatePickerOptions: IAngularMyDpOptions;

  displayDate: boolean = true;
  fieldKeyMissing: string;
  fieldKeyIncomplete: string;
  customErrorEvent: any;

  fieldId: string;

  existingErrors: DPError[] = []; //used to store the existing errors, used to help preventing the duplicate bubble notification

  refreshDate(): void {
    this.dateDay = undefined;
    this.dateMonth = undefined;
    this.dateYear = undefined;
    this.date = undefined;
    this.dateErrorMessages = [];
    this.dateSamplingMessage = undefined;
    this.dateHoliday = undefined;
  }

  updateDateHoliday() {
    this.dateHoliday = this.hideHolidayAndWeekendDate ? null : this.publicHolidayService.getHoliday(this.dateYear, this.dateMonth, this.dateDay);
    this.dateWeekendDay = this.isWeekend();
  }

  isWeekend(): string {
    let ret = null;
    //it is better to avoid use validateDateDay, validateDateMonth and validateDateYear.
    // These 3 functions will change this.dateYear, this.dateMonth or this.dateDay value. They maybe add "0" as prefix
    // Use moment to do validation
    if (this.dateYear && this.dateMonth && this.dateDay) {
      let date = moment(`${ this.dateYear }-${ this.dateMonth }-${ this.dateDay }`);
      if (date && (date.isoWeekday() == 6 || date.isoWeekday() == 7)) {
        ret = date.isoWeekday() + '';
      }
    }
    return ret;
  }

  isHolidayOrWeekendDate() {
    return this.hideHolidayAndWeekendDate ? null : (this.dateHoliday || this.dateWeekendDay);
  }

  setValues(year: string, month: string, day: string): void {
    this.dateDay = day;
    this.dateMonth = month;
    this.dateYear = year;
    this.updateDateHoliday();
  }

  setDate(date: string, validate?: boolean) {
    if (date && this.displayDate) {
      let values: string[] = date.split('/');
      if (validate) {
        this.verifyDateInput(values[ 2 ], 'day');
        this.verifyDateInput(values[ 1 ], 'month');
        this.verifyDateInput(values[ 0 ], 'year');
      } else {
        this.setValues(values[ 0 ], values[ 1 ], values[ 2 ]);
        if (this.isDateComplete) {
          this.formattedDateLabel = values[ 1 ] + '/' + values[ 2 ] + '/' + values[ 0 ];
          this.formatDateLabel();
        }
      }
    } else {
      this.setValues('', '', '');
      this.formattedDateLabel = '';
    }
  }

  refreshInputDate(): void {
    if (!this.inputDate && this.dateParent && this.dateFieldName) {
      this.inputDate = this.dateParent[ this.dateFieldName ];
    }
    if (!this.displayDate) {
      return;
    }
    if (this.inputDate) {
      let date = this.inputDate;
      let dates = date.split('/');
      let missingData: boolean = false;

      if (dates[ 2 ] && dates[ 2 ] !== 'undefined') {
        this.dateDay = dates[ 2 ];
      } else {
        missingData = true;
        this.dateDay = '';
      }
      if (dates[ 1 ] && dates[ 1 ] !== 'undefined') {
        this.dateMonth = dates[ 1 ];
      } else {
        missingData = true;
        this.dateMonth = '';
      }
      if (dates[ 0 ] && dates[ 0 ] !== 'undefined') {
        this.dateYear = dates[ 0 ];
      } else {
        missingData = true;
        this.dateYear = '';
      }

      if (missingData === false) {
        this.formattedDateLabel = this.dateMonth + '/' + this.dateDay + '/' + this.dateYear;
        this.selectedDateNormal = this.dateDay + '/' + this.dateMonth + '/' + this.dateYear;
        this.formatDateLabel();
        this.updateDateHoliday();
      } else {
        this.formattedDateLabel = '';
      }
    } else {
      this.setValues('', '', '');
      this.formattedDateLabel = '';
    }
  }

  setDateFormat(): void {
    // set the date pipe format
    if (this.dateFormat) {
      this.dateFormatting = this.dateFormat;
    } else {
      // this.dateFormatting = 'EEE MMM dd y'; // old way of formatting pipe
      this.dateFormatting = 'ddd MMM DD YYYY';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDateFormat();
    this.refreshInputDate();
  }

  ngOnInit(): void {
    this.e.nativeElement.setAttribute('xpathId', this.fieldKey);
    this.myDatePickerOptions = {
      dateFormat: 'dd/mm/yyyy',
      firstDayOfWeek: 'su',
      todayTxt: 'Today',
      showFooterToday: true
      //componentDisabled : this.disableAll
    };
    if (this.identifier) {
      this.fieldId = this.identifier;
      this.fieldKeyIncomplete = this.fieldKey + '_INVALID';
    } else {
      this.fieldKeyIncomplete = this.fieldKey + '.INVALID';
    }
    this.fieldKeyMissing = this.fieldKey + '.MISSING';
    this.dateRegularExpression = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

    this.dayAndMonthRegularExpression = /^(?:(?:31(\/|-|\.)(?:0?([13578]|1[02])|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))))$|^(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2)$|^(?:29(\/|-|\.)(?:0?2|(?:Feb)))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))$/;

    this.dayRegularExpression = /^([0]?[1-9]|[1-2][0-9]|3[0-1]|)$/;
    this.monthRegularExpression = /^([0]?[1-9]|[1][0-2]|)$/;
    this.yearRegularExpression = /^([0]?[0-9]|[1-9]\d|[1-9]\d{3}|0|)$/;
    // If date type is birthday max date is today's date
    // For other types no max date or a date from future

    if (this.dateType && this.dateType.toLocaleLowerCase() === 'birthday') {
      this.maxBirthdayDate = '+0d';
      let d: Date = new Date();
      this.myDatePickerOptions.disableSince = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate() + 1
      };
    } else {
      this.maxBirthdayDate = '+8000y';
    }

    if (this.disableUntil) {
      this.myDatePickerOptions.disableUntil = {
        year: this.disableUntil.year,
        month: this.disableUntil.month,
        day: this.disableUntil.day
      };
    }

    this.setDateFormat();

    if (!this.openCalendarPopupOnTop || this.openCalendarPopupOnTop !== true) {
      this.myDatePickerOptions.openSelectorTopOfInput = false;
      this.myDatePickerOptions.showSelectorArrow = true;
    } else {
      this.myDatePickerOptions.openSelectorTopOfInput = true;
      this.myDatePickerOptions.showSelectorArrow = false;
    }

    //If we inject a reference to the date holder and date field, we use them to set the date value into it
    if (this.dateParent && this.dateFieldName) {
      this.dateChange.subscribe(event => {
        if (typeof event == 'string' && this.isOnDemand(event)) {
          this.inputDate = ON_DEMAND_VALUE;
        } else if (this.dateYear || this.dateMonth || this.dateDay) {
          this.inputDate = `${ this.dateYear }/${ this.dateMonth }/${ this.dateDay }`;
        } else {
          this.inputDate = null;
        }
        if (typeof (this.dateParent) !== 'string') {
          this.dateParent[ this.dateFieldName ] = this.inputDate;
        } else {
          this.dateFieldName = this.inputDate;
        }
      });
    }

    if (this.customF9ExternalDate) {
      if (!this.dateParent || !this.dateFieldName) {
        return;
      } else if (this.isOnDemand(this.dateParent[ this.dateFieldName ])) {
        this.displayDate = false;
        this.formattedDateLabel = '';
      }
    }
    this.myDatePickerOptions.satHighlight = true;
    this.myDatePickerOptions.highlightDates = this.publicHolidayService.getDatePickerPublicHolidays();
  }

  ngAfterViewInit(): void {
    this.focusedPartIndex = 0;
    this.dateParts = [ this.refDateDay, this.refDateMonth, this.refDateYear ];
    this.createIncompleteDateErrorEvent();
  }

  createIncompleteDateErrorEvent(): void {
    if (this.myDatePicker && !this.myDatePicker.showSelectorArrow) {
      this.customErrorEvent = setTimeout(() => {
        //console.warn("createIncompleteDateErrorEvent: fieldKeyIncomplete | fieldID : ", `${this.fieldKeyIncomplete} | ${this.fieldId}`);
        if (this.fullDateRequired) {

          const errorElementKey: string = this.customErrorMessagePrefix ? this.fieldKey + '.MISSING' : this.fieldKeyIncomplete + (this.identifierId ? '_' + this.identifierId : '');
          this.errorService.removeDpFieldError(errorElementKey);

          if (!this.isDateComplete && !this.isEmpty) {
            this.customErrorMessagePrefix ?
              this.errorService.addDpFieldError(DPError.createCustomDPError(this.fieldKey + '.MISSING',
                this.customErrorMessagePrefix + ' is incomplete.',
                this.errorTopic, 'ERROR', null, null, this.fieldCode, null, null, this.groupId)) :
              this.errorService.addDpFieldError(DPError.createDPError(this.fieldKeyIncomplete, this.identifierId, this.containerId, this.fieldCode, null, null, this.groupId));
          }
        }
        if ((this.isClosingDate || this.isBirthDate) && !this.isDateComplete && !this.isEmpty) {
          this.errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError(this.fieldKeyIncomplete, this.fieldId, this.containerId, this.fieldCode, null, null, this.groupId), this.existingErrors);
        } else if (this.isExpiryDate && !this.isDateComplete && !this.isEmpty) {
          if (this.fieldId != null && this.parentId) {
            this.errorService.addDpFieldError(DPError.createCustomDPError(`${ this.fieldKey }`,
              `Invalid expiry date for ID # ${ this.parentId }`,
              'ID Details',
              'ERROR'));
          } else {
            //console.warn(`createIncompleteDateErrorEvent() : this.fieldKeyIncomplete=${this.fieldKeyIncomplete}, this.fieldKey=${this.fieldKey},
            // this.fieldId=${this.fieldId}, this.containerId=${this.containerId}, this.fieldCode=${this.fieldCode},
            // this.identifierId=${this.identifierId}`);
            this.errorService.addDpFieldError(DPError.createDPError(this.fieldKeyIncomplete, this.fieldId, this.containerId, this.fieldCode, null, null, this.groupId));
          }
        }
      }, 250);
    }
  }

  setFocusedField(index: number): void {
    this.showFocusClass = true;
    clearTimeout(this.customErrorEvent);
    this.focusedPartIndex = index;
    this.focusIn.emit();
  }

  changeFocusToMonthField(value): void {
    this.dateChange.emit(this.getDateEvent());
    if (value.length === 2) {
      this.refDateMonth.nativeElement.focus();
      this.focusIn.emit();
    }
  }

  changeFocusToYearField(value): void {
    this.dateChange.emit(this.getDateEvent());
    if (value.length === 2) {
      this.refDateYear.nativeElement.focus();
      this.focusIn.emit();
    }
  }

  emitChangeEvent(value): void {
    this.dateChange.emit(this.getDateEvent());
  }

  /**
   * This method is to verify the date
   * @Input value for either day, month or year
   * @Output method verifies the date and sets values for day, month, year, date and
   * date sampling message and emits an event with these values
   */
  verifyDateInput(value, field): void {
    this.showFocusClass = false;
    //console.warn(`verifyDateInput : value=${value} | field=${field} | this.fieldKeyIncomplete=${this.fieldKeyIncomplete} | this.fieldKey=${this.fieldKey} | this.identifierId=${this.identifierId} | this.fieldId=${this.fieldId}` );
    //to use later for verify whether it is new Error or Existing Error after existing errors get cleared
    this.existingErrors = this.errorService.copyOfExistingErrors;
    this.errorService.removeDpFieldError(this.fieldKey);
    if (this.identifierId) {
      this.errorService.removeDpFieldError(`${ this.fieldKey }_${ this.identifierId }`);
      this.errorService.removeDpFieldError(`${ this.fieldKeyIncomplete }_${ this.identifierId }`);
    } else {
      this.errorService.removeDpFieldError(`${ this.fieldKey }_${ this.fieldId }`);
      this.errorService.removeDpFieldError(`${ this.fieldKeyIncomplete }_${ this.fieldId }`);
    }
    if (!this.displayDate) {
      return;
    }
    this.createIncompleteDateErrorEvent();
    this.invalidFieldsCount = 0;
    let today = new Date();
    this.dateSamplingMessage = undefined;

    if (field === 'day') {
      this.dateDay = value;
    } else if (field === 'month') {
      this.dateMonth = value;
    } else if (field === 'year') {
      this.dateYear = value;
    }

    let isDayValid: boolean = true;
    let isMonthValid: boolean = true;
    let isYearValid: boolean = true;

    if (this.validateDateDay()) {
      // valid day
      isDayValid = true;
    } else {
      // invalid day
      isDayValid = false;
      this.invalidFieldsCount++;
    }

    if (this.validateDateMonth()) {
      // valid month
      isMonthValid = true;
    } else {
      // invalid month
      isMonthValid = false;
      this.invalidFieldsCount++;
    }

    if (this.validateDateYear()) {
      // valid year
      isYearValid = true;
    } else {
      // invalid year
      isYearValid = false;
      this.invalidFieldsCount++;
    }
    if (isYearValid && isMonthValid && isDayValid) {
      this.updateDateHoliday();
    }
    this.addDPFieldError();
    //Analyzing the invalid date fields
    switch (this.invalidFieldsCount) {
      case 0:
        // There is no any invalid date field because the invalid Fields number is zero.
        // So we do not need to set dateSamplingMessage and formattedDateLabel.
        break;
      case 1:
        if (!isDayValid) {
          this.dateSamplingMessage = 'Invalid Day';
        } else if (!isMonthValid) {
          this.dateSamplingMessage = 'Invalid Month';
        } else if (!isYearValid) {
          this.dateSamplingMessage = 'Invalid Year';
        }
        this.formattedDateLabel = '';
        break;
      case 2:
      case 3:
      default :
        this.dateSamplingMessage = 'Invalid Date';
        this.formattedDateLabel = '';
        break;
    }
    this.dateChange.emit(this.getDateEvent());

    // This updates Calendar popup to default values, when some of the input fields are missing
    if (this.invalidFieldsCount === 0) {
      if (this.dateDay && this.dateMonth && this.dateYear) {
        this.selectedDateNormal = this.dateDay + '/' + this.dateMonth + '/' + this.dateYear;
        // through pipe ends up formatted as e.g. Sat Feb 04, 2017
        this.formattedDateLabel = this.dateMonth + '/' + this.dateDay + '/' + this.dateYear;
      } else if (this.dateMonth && this.dateYear) {
        this.selectedDateNormal = '01/' + this.dateMonth + '/' + this.dateYear;
        this.formattedDateLabel = '';
      } else if (this.dateDay && this.dateYear) {
        this.selectedDateNormal = this.dateDay + '/01/' + this.dateYear;
        this.formattedDateLabel = '';
      } else if (this.dateDay && this.dateMonth) {
        this.selectedDateNormal = this.dateDay + '/' + this.dateMonth + '/' + today.getFullYear();
        this.formattedDateLabel = '';
      }
      this.formatDateLabel();
    }
  }

  formatDateLabel(): void {
    if (!this.dateFormatting) {
      this.dateFormatting = 'ddd MMM DD YYYY';
    }
    if (moment(this.formattedDateLabel, 'MM/DD/YYYY').format(this.dateFormatting) !== 'Invalid date') {
      this.formattedDateLabel = moment(this.formattedDateLabel, 'MM/DD/YYYY').format(this.dateFormatting);
    } else {
      this.formattedDateLabel = '';
    }
  }

  ensureDateValidity(): void {
    console.log('Date blurred');
  }

  /**
   * This method updates Calendar popup with values from partial date input fields
   */
  onDatePickerDateChange(event: IMyDateModel) {
    this.selectedDateNormal = event.singleDate.formatted;
    this.partsOfCalendarString = this.selectedDateNormal.split('/');

    this.dateDay = this.partsOfCalendarString[ 0 ];
    this.dateMonth = this.partsOfCalendarString[ 1 ];
    this.dateYear = this.partsOfCalendarString[ 2 ];

    this.updateDateHoliday();

    this.verifyDateInput(this.dateDay, 'day');
    this.verifyDateInput(this.dateMonth, 'month');
    this.verifyDateInput(this.dateYear, 'year');

    this.refDateDay.nativeElement.focus();
  }

  onDatePickerDblClick(event) {
    event.stopPropagation();
  }

  // Calling this function opens the datepicker calendar window
  // Note: by increasing the value of selector by one - the component detects the change and opens calendar
  onOpenCalendarSelector() {
    this.openCalendarWindow++;
    this.myDatePicker.toggleCalendar();
  }

  // Keystroke Capture
  captureKeyPress(event): void {
    if (this.disableAll) {
      return;
    }
    // capture F8 Key for Maturity Date
    if (this.customF9ExternalDate) {
      let {type} = this.customF9ExternalDate;
      if (type == 'MaturityDate') {
        //F8 key
        if (event.keyCode === 119) {
          if (this.displayDate) {
            this.displayDate = false;
            this.dateChange.emit(ON_DEMAND_VALUE);
            this.formattedDateLabel = '';
            setTimeout(() => {
              this.refOnDemand && this.refOnDemand.nativeElement && this.refOnDemand.nativeElement.focus();
            }, 30);
          } else {
            this.displayDate = true;
            this.setValues('', '', '');
            this.dateChange.emit(this.getDateEvent());
            setTimeout(() => {
              this.refDateDay.nativeElement.focus();
            }, 30);
          }
          return;
        }
      }
    }

    // capture F9 Key
    if (event.keyCode === 120 && this.displayDate) {
      if (this.customF9Behaviour) {
        this.customF9Behaviour();
        if (this.dateMonth && this.dateDay && this.dateYear) {
          this.formattedDateLabel = this.dateMonth + '/' + this.dateDay + '/' + this.dateYear;
        }
      } else if (this.customF9ExternalDate) {
        let {dd, mm, yy, type} = this.customF9ExternalDate;
        if (!dd && !mm && !yy) {
          event.stopPropagation();
          this.onOpenCalendarSelector();
          return;
        }
        if (type != 'MaturityDate') {
          this.setValues(yy, mm, dd);
          this.dateChange.emit(this.getDateEvent());
        } else {
          this.setValues('', mm, dd);
          this.dateChange.emit(this.getDateEvent());
          setTimeout(() => {
            this.formattedDateLabel = '';
            this.refDateYear.nativeElement.value = '';
            this.refDateYear.nativeElement.focus();
          }, 50);
        }
      } else {
        event.stopPropagation();
        this.onOpenCalendarSelector();
      }
    }

    if (event.keyCode === 37) { // Left arrow
      if (this.focusedPartIndex != 0) {
        this.focusedPartIndex--;
        this.dateParts[ this.focusedPartIndex ].nativeElement.focus();
        this.dateParts[ this.focusedPartIndex ].nativeElement.select();
      }
    }
    if (event.keyCode === 39) { // Right arrow
      if (this.focusedPartIndex != this.dateParts.length - 1) {
        this.focusedPartIndex++;
        this.dateParts[ this.focusedPartIndex ].nativeElement.focus();
        this.dateParts[ this.focusedPartIndex ].nativeElement.select();
      }
    }
  }

  /**
   * Sets current date to today. Used by customF9 behaviour in some instances.
   */
  setAsToday() {
    let today: Date = new Date();
    this.dateDay = today.getDate() < 10 ? '0' + today.getDate() : '' + today.getDate();
    let month = today.getMonth() + 1;
    this.dateMonth = month < 10 ? '0' + month : '' + month;
    this.dateYear = '' + today.getFullYear();
    this.updateDateHoliday();
    this.dateChange.emit(this.getDateEvent());
  }

  /** This method validates the day value
   * Based on the day value, appends 0
   */
  validateDateDay(): boolean {
    let value = this.dateDay;
    if (this.isValueEmpty(value)) {
      return true;
    }
    if (!this.dayRegularExpression.test(value)) {
      return false;
    } else {
      if (+value >= 1 && +value <= 9) {
        let woZero = +value;
        value = '0' + woZero;
      }
      this.dateDay = value;
      return true;
    }
  }

  /** This method validates the month value
   * Based on the month value, appends 0
   */
  validateDateMonth(): boolean {
    let value = this.dateMonth;
    if (this.isValueEmpty(value)) {
      return true;
    }
    if (!this.monthRegularExpression.test(value)) {
      return false;
    } else {
      if (+value >= 1 && +value <= 9) {
        let woZero = +value;
        value = '0' + woZero;
      }
      this.dateMonth = value;
      return true;
    }
  }

  /** Method sets the date sampling message
   * If full date is valid - e.g. Wed 17 Jul 2016
   * If date is invalid - Invalid Day, Invalid Month etc.
   */

  parseDatetoMMM_DD_YYYY(): string {
    let months = {
      0: 'Jan', 1: 'Feb', 2: 'Mar', 3: 'Apr', 4: 'May', 5: 'Jun',
      6: 'Jul', 7: 'Aug', 8: 'Sep', 9: 'Oct', 10: 'Nov', 11: 'Dec'
    };
    let days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

    let date = new Date(parseInt(this.dateYear), parseInt(this.dateMonth) - 1, parseInt(this.dateDay));

    return `${ days[ date.getDay() ] } ${ months[ Number(this.dateMonth) - 1 ] } ${ this.dateDay }, ${ this.dateYear }`;
  }

  /** This method validates the year value
   * Based on the year value coverts year to 4 digits year value
   * Checks validation and updates year for birthday types date
   */
  validateDateYear(): boolean {
    let value = this.dateYear;
    if (this.isValueEmpty(value)) {
      return true;
    }

    if (!this.yearRegularExpression.test(value)) {
      return false;
    }

    let dateToday = new Date();
    let currentFullYearNumber: number = dateToday.getFullYear();
    //If birth year is in future then it is invalid
    if ((this.dateType && this.dateType.toLocaleLowerCase() === 'birthday')
      && +value > currentFullYearNumber) {
      return false;
    } else {
      let currentFullYear: string = dateToday.getFullYear().toString();
      // let currentYearTwoDigits: number = +currentFullYear.substr(2, 4);
      let currentYearFirstTwoDigits = +currentFullYear.substr(0, 2);
      let currentMonth = dateToday.getMonth() + 1;
      let currentTodayDate = dateToday.getDate();

      if (this.dateType && this.dateType.toLocaleLowerCase() === 'birthday') {
        //InCase if Year has more than 2 digits entered the last 2 digits are taken into account.
        if (value.length > 2) {
          value = value.substr(value.length - 2);
        }
        // Case1: if entered year < current year : Just append the first 2 digit of current year

        if (Number(value) < Number(currentFullYear.substr(1))) {
          if (+value >= 0 && +value <= 9) {
            value = (currentFullYear.substr(0, 2) + '0' + +value);
          } else {
            value = (currentFullYear.substr(0, 2) + value);
          }

          // Case2: if entered year > current year : Deduct the first 2 digits of current year by 1 example  :currentYear 2018 enteredYear 19 subtract (20-1)

        } else if (Number(value) > Number(currentFullYear.substr(1))) {
          if (+value >= 0 && +value <= 9) {
            value = (((+currentYearFirstTwoDigits - 1)) + '0' + +value);
          } else {
            value = (String((+currentYearFirstTwoDigits - 1)) + +value);
          }
        }
        // Case3: if entered year = current year
        else {
          let month: number = +this.dateMonth;
          //case : if month entered is future month then currentYear 2018 enteredYear 18 subtract (20-1)

          if (month > currentMonth) {
            if (+value >= 0 && +value <= 9) {
              value = (((+currentYearFirstTwoDigits - 1)) + '0' + +value);
            } else {
              value = (String((+currentYearFirstTwoDigits - 1)) + +value);
            }
          }
          //case : if month entered is current month
          else if (month === currentMonth) {
            let day: number = +this.dateDay;
            if (day > currentTodayDate) {
              if (+value >= 0 && +value <= 9) {
                value = (((+currentYearFirstTwoDigits - 1)) + '0' + +value);
              } else {
                value = (String((+currentYearFirstTwoDigits - 1)) + +value);
              }
            } else if (day <= currentTodayDate) {
              if (+value >= 0 && +value <= 9) {
                value = (currentFullYear.substr(0, 2) + '0' + +value);
              } else {
                value = (currentFullYear.substr(0, 2) + value);
              }
            }
          }
          //case : if month entered < current month then accept the current year
          else {
            if (+value >= 0 && +value <= 9) {
              value = (currentFullYear.substr(0, 2) + '0' + +value);
            } else {
              value = (currentFullYear.substr(0, 2) + value);
            }
          }
        }

        /* if(+value >= 0 && +value <= 9) {
             value = (currentFullYear.substr(0, 2) + '0' + +value);
         } else if(+value >= 10 && +value <= 99) {
             value = (String((+currentYearFirstTwoDigits - 1)) + +value);
         } else {
             let month : number = +this.dateMonth;
             if(month > currentMonth) {
                 value = '' + (+value - 100);
             } else if(month === currentMonth) {
                 let day : number = +this.dateDay;
                 if(day > currentTodayDate) {
                     value = '' + (+value - 100);
                 }
             }
         }*/
      } else {
        if (+value >= 0 && +value <= 9) {
          value = (currentFullYear.substr(0, 2) + '0' + +value);
        } else if (+value >= 10 && +value <= 99) {
          value = (currentFullYear.substr(0, 2) + +value);
        }
      }
      this.dateYear = value;
      return true;
    }
  }

  /** This method checks the date validity based on the values of day, month and year */
  checkDateValidity(): boolean {
    let month: string = this.dateMonth;
    let day: string = this.dateDay;
    let year: string = this.dateYear;
    // let isValid: boolean = true;

    if (day && month && year) {
      if (!this.isValidDate()) {
        return false;
      } else {
        this.setDateToCalendar();
        this.setDateSamplingMessage();
        return true;
      }
    } else if (day && month && !year) {
      return this.isValidDayAndMonth();
    } else {
      return true;
    }
  }

  /** Checks validity of full date based on regular expression */
  isValidDate(): boolean {

    let fullDate: string = `${ this.dateDay }/${ this.dateMonth }/${ this.dateYear }`;

    return this.dateRegularExpression.test(fullDate);
  }

  /** Checks validity of full date based on regular expression */
  isValidDayAndMonth(): boolean {
    let dayAndMonth: string = `${ this.dateDay }/${ this.dateMonth }`;

    return this.dayAndMonthRegularExpression.test(dayAndMonth);
    /* let month: number = +this.dateMonth;
     let day: number = +this.dateDay;
     let expectedDays: number;
     switch (month) {
     case 1: case 3: case 5: case 7: case 8: case 10: case 12:
     expectedDays = 31;
     break;
     case 4: case 6: case 9: case 11:
     expectedDays = 30;
     break;
     case 2:
     expectedDays = 29;
     break;
     default:
     expectedDays = 0;
     }
     if (day <= expectedDays) return true;
     return false;*/
  }

  setDateSamplingMessage() {
    //  let x = new Date(+this.dateYear, +this.dateMonth -1, +this.dateDay);
    this.dateSamplingMessage = this.parseDatetoMMM_DD_YYYY();
  }

  /** Method sets the values for date to calendar */
  setDateToCalendar(): void {
    this.date = `${ this.dateDay }/${ this.dateMonth }/${ this.dateYear }`;
  }

  /** Method sets the values for date selected from calendar */
  setDateFromCalendar(): void {

    let date = this.date;
    let dates = date.split('/');

    this.dateDay = dates[ 0 ];
    this.dateMonth = dates[ 1 ];
    this.dateYear = dates[ 2 ];
    this.updateDateHoliday();
    this.setDateSamplingMessage();
    this.dateChange.emit(this.getDateEvent());
  }

  /** Method  defines the structure of dateChange event
   */
  getDateEvent() {
    return {
      day: this.dateDay,
      month: this.dateMonth,
      year: this.dateYear,
      date: this.date, //This is populated only if it's a full date
      rawDate: this.dateValue, //Temp data field until we have the date holding this value
      message: this.dateSamplingMessage
    };
  }

  get dateValue() {
    const rawDate = `${ ((this.dateYear) ? this.dateYear : '') }/${ ((this.dateMonth) ? this.dateMonth : '') }/${ ((this.dateDay) ? this.dateDay : '') }`;
    return rawDate == '//' ? null : rawDate;
  }

  isValueEmpty(value: string): boolean {
    return value == null || value === undefined || value === '';
  }

  get isEmpty(): boolean {
    return this.isValueEmpty(this.dateDay) && this.isValueEmpty(this.dateMonth) && this.isValueEmpty(this.dateYear);
  }

  get isDateComplete(): boolean {
    return !this.isValueEmpty(this.dateDay) && !this.isValueEmpty(this.dateMonth) && !this.isValueEmpty(this.dateYear);
  }

  public removeDPFieldErrors(): void {
    let fieldId: string = this.fieldId;
    if (this.fieldId != null && this.parentId && this.isExpiryDate) {
      fieldId = `${ this.parentId }_${ this.fieldId }`;
    }
    //console.warn(`removeDPFieldErrors() : this.fieldKeyIncomplete=${this.fieldKeyIncomplete}, this.fieldKey=${this.fieldKey}, this.fieldId=${this.fieldId}, this.containerId=${this.containerId}, this.fieldCode=${this.fieldCode}, this.identifierId=${this.identifierId}`);
    this.errorService.removeDpFieldError(this.fieldKey, fieldId);
    this.errorService.removeDpFieldError(this.fieldKeyMissing, fieldId);
    this.errorService.removeDpFieldError(this.fieldKeyIncomplete, fieldId);
    if (this.identifierId) {
      this.errorService.removeDpFieldError(this.fieldKey + '_' + this.identifierId);
      this.errorService.removeDpFieldError(this.fieldKeyMissing + '_' + this.identifierId);
      this.errorService.removeDpFieldError(this.fieldKeyIncomplete + '_' + this.identifierId);
    }
  }

  /**
   * this method is called from component to explicitly trigger the validation of specific date component
   * to verify the data change for all the potential error with changes may not from current UI
   */
  public verifyData(): void {
    this.createIncompleteDateErrorEvent();
    this.setDate(this.dateValue, true);
  }

  public addDPFieldError(): void {
    if (this.fieldKey && this.myDatePicker && !this.myDatePicker.showSelectorArrow && this.displayDate) {
      this.removeDPFieldErrors();
      if (this.invalidFieldsCount > 0 && this.fieldKey) {
        if (this.fieldId != null && this.parentId && this.isExpiryDate) {

          this.errorService.addDpFieldError(DPError.createCustomDPError(`${ this.fieldKey }_${ this.parentId }_${ this.fieldId }`,
            `Expiry date for ID ${ this.fieldId } of ID Record ${ this.parentId } is invalid.`,
            'ID Details',
            'WARNING', null, '', this.fieldCode, null, null, this.groupId));
        } else {
          //console.warn(`addDPFieldError() : this.fieldKey=${this.fieldKey}, this.fieldId=${this.fieldId}, this.containerId=${this.containerId},
          // this.fieldCode=${this.fieldCode}, this.identifierId=${this.identifierId}`);
          this.customErrorMessagePrefix ? this.errorService.addDpFieldError(DPError.createCustomDPError(this.fieldKey + '.INVALID',
              this.customErrorMessagePrefix + ' is invalid.',
              this.errorTopic, 'ERROR', null, null, this.fieldCode, null, null, this.groupId)) :
            //this.errorService.addDpFieldError(DPError.createDPError(this.fieldKey, this.identifierId, this.containerId, this.fieldCode)
            this.errorService.addDpFieldError(DPError.createDPError(this.fieldKey, this.identifierId, this.containerId, this.fieldCode, null, null, this.groupId)
            );
        }
      } else if (this.invalidFieldsCount === 0 && !this.checkDateValidity()) {
        if (this.fieldId != null && this.parentId && this.isExpiryDate) {

          this.errorService.addDpFieldError(DPError.createCustomDPError(`${ this.fieldKey }_${ this.parentId }_${ this.fieldId }`,
            `Expiry date for ID ${ this.fieldId } of ID Record ${ this.parentId } is invalid.`,
            'ID Details',
            'WARNING', null, '', this.fieldCode, null, null, this.groupId));
        } else {
          //console.warn(`addDPFieldError() : this.fieldKey=${this.fieldKey}, this.fieldId=${this.fieldId}, this.containerId=${this.containerId},
          // this.fieldCode=${this.fieldCode}, this.identifierId=${this.identifierId}`);
          this.customErrorMessagePrefix ?
            this.errorService.addDpFieldError(DPError.createCustomDPError(this.fieldKey + '.INVALID', this.customErrorMessagePrefix + ' is invalid.', this.errorTopic, 'ERROR', null, null, this.fieldCode)) :
            this.errorService.addDpFieldError(DPError.createDPError(this.fieldKey, this.identifierId, this.containerId, this.fieldCode, null, null, this.groupId));
        }
      } else if (this.isClosingDate && this.isEmpty) {
        this.errorService.addDpFieldErrorWithNoDupBubble(DPError.createDPError(this.fieldKeyMissing, this.fieldId, this.containerId, this.fieldCode, null, null, this.groupId), this.existingErrors);
      }
    }
  }

  get isClosingDate(): boolean {
    return this.fieldKey && this.fieldKey.indexOf('matterCloseDate') > -1;
  }

  get isBirthDate(): boolean {
    return this.fieldKey && this.fieldKey.indexOf('birthDate') > -1;
  }

  get isExpiryDate(): boolean {
    return this.fieldKey && this.fieldKey.indexOf('expiryDate') > -1;
  }

  get isRegisteredDate(): boolean {
    return this.fieldKey && this.fieldKey.indexOf('registeredDate') > -1;
  }

  dynamicHelpCallBackFunctionForOnDemand(): string {
    return 'F8 = return to original format';
  }

  get onDemandValue(): string {
    return this.displayDate ? '' : 'On Demand';
  }

  dynamicHelpCallBackFunctionForDate(): string {
    if (this.customF9ExternalDate) {
      let {dd, mm, yy, type} = this.customF9ExternalDate;
      if (type == 'MaturityDate') {
        if (moment(mm + '/' + dd + '/', 'MM/DD').format('MMM DD') !== 'Invalid date') {
          return 'F8 = On Demand, F9 = ' + moment(mm + '/' + dd + '/', 'MM/DD').format('MMM DD');
        } else {
          return 'F8 = On Demand';
        }
      } else if (moment(mm + '/' + dd + '/' + yy, 'MM/DD/YYYY').format('ddd MMM DD YYYY') !== 'Invalid date') {
        return 'F9 = ' + moment(mm + '/' + dd + '/' + yy, 'MM/DD/YYYY').format('ddd MMM DD YYYY');
      } else {
        return '';
      }
    } else if (this.customF9Behaviour) {
      let today: Date = new Date();
      if (moment(today, 'MM/DD/YYYY').format('ddd MMM DD YYYY') !== 'Invalid date') {
        return 'F9 = ' + moment(today, 'MM/DD/YYYY').format('ddd MMM DD YYYY');
      } else {
        return '';
      }
    } else {
      return '';
    }

  }

  get inputDate(): string {
    return ('//' == this._inputDate) ? null : this._inputDate;
  }

  isOnDemand(value: string) {
    if (value) {
      return value.toUpperCase() === ON_DEMAND_VALUE;
    }
    return false;
  }

}

