import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {WindowRef} from '../../../app/shared/window.ref';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class SyngrafiiPortalContext {
  title: string;
  securedUrl: string;
}

@Component({
  selector: 'dp-syngrafii-portal-modal',
  templateUrl: 'syngrafii-portal.modal.component.html'
})
export class SyngrafiiPortalModalComponent extends ModalComponent<SyngrafiiPortalContext> implements OnInit {
  syngrafiiUrl: SafeUrl;

  showCloseButton: boolean = true;

  @ViewChild('syngrafiiFrame') syngrafiiFrame: ElementRef;

  constructor(
    public dialog: MatDialogRef<SyngrafiiPortalModalComponent>,
    public sanitizer: DomSanitizer,
    public winRef: WindowRef,
    @Inject(MAT_DIALOG_DATA) context?: SyngrafiiPortalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.syngrafiiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
    this.handleSyngrafiiMessages();
  }

  handleSyngrafiiMessages(): void {
    window.addEventListener(
      'message',
      (event) => {
        if (!event.data) {
          return;
        }
        // Handle action
        switch (event.data.action) {
          case 'package_open':
            //Close button is needed in case of error while opening portal (eg. cookies disabled).
            //Once package is opened successfully, hide close button so user uses close button in portal.
            this.showCloseButton = false;
            break;
          case 'package_close':
            this.close();
            break;
          case 'session_expired':
            this.showCloseButton = true;
            // User session has expired due to inactivity
            break;
          default:
            break;
        }
      },
      false
    );
  }

  close(): void {
    this.dialog.close();
  }
}
