<div>
    <div class="form-group" *ngIf="privateLenderWrapper &&  !privateLenderWrapper.isPrivateLenderMortgagee">
        <label class="control-label col-lg-2" for="privateLenderGender">Gender</label>
        <div class="col-lg-3">
            <select class="form-control"
                    id="privateLenderGender_{{fieldIdentifier}}"
                    name="privateLenderGender"
                    [dp-read-only]="readonly"
                    (change)="onGenderChange();"
                    [(ngModel)]="privateLender.localGender"
                    [fieldKey]="fieldKeyGender"
                    dp-error-validator
                    (change)="onPrivateLenderInfoChanged()">
                <option *ngFor="let gender of privateLenderGenderOptions"
                        [value]="gender.value">{{gender.label}}</option>
            </select>
        </div>
    </div>
    <ng-container *ngIf="isPrivateLenderCorporationOrOther">
        <div class="form-group">
            <label class="control-label col-lg-2" for="privateLenderOrganizationName">Name*</label>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       id="privateLenderOrganization_{{fieldIdentifier}}"
                       name="privateLenderOrganizationName"
                       maxlength="100"
                       [dp-read-only]="readonly"
                       dp-error-validator
                       [fieldKey]="fieldPrefix+'.surname'+'privateLenderOrganization_' + fieldIdentifier"
                       [(ngModel)]="privateLender.organizationName"
                       (ngModelChange)="onNameChange()"/>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isPrivateLenderCorporationOrOther">
        <div class="form-group">
            <label class="control-label col-lg-2" for="privateLenderLastName">Surname*</label>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       id="privateLenderSurname_{{privateLender.identifier}}"
                       name="privateLenderLastName"
                       [dp-read-only]="readonly"
                       maxlength="100"
                       dp-error-validator
                       [fieldKey]="fieldPrefix+'.surname'+'privateLenderSurname_' + fieldIdentifier"
                       [(ngModel)]="privateLender.lastName"
                       (ngModelChange)="onNameChange()"
                       (change)="handleContactChange($event)"
                />
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-lg-2" for="privateLenderFirstName">First Name</label>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       id="privateLenderFirstName"
                       [dp-read-only]="readonly"
                       name="privateLenderFirstName"
                       maxlength="50"
                       [(ngModel)]="privateLender.firstName"
                       (ngModelChange)="onNameChange()"
                       (change)="handleContactChange($event)"
                />
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-lg-2" for="privateLenderMiddleName">Middle Names</label>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       id="privateLenderMiddleName"
                       name="privateLenderMiddleName"
                       [dp-read-only]="readonly"
                       maxlength="50"
                       [(ngModel)]="privateLender.middleName"
                       (ngModelChange)="onNameChange()"
                       (change)="handleContactChange($event)"
                />
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngIf="isPrivateLenderCorporationOrOther && signingOfficerVisible && privateLenderWrapper && privateLenderWrapper.matterParticipant">
        <div [dp-read-only-block]="!isSnapshotPrivateLenderGlobalMortgage && (isSourceContactLocked || isOutOfSync)" [elementRefComp]="elementRef">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" for="includeAuthorizeSignOfficer">Include Authorized<br/>Signing
                        officer?</label>
                </div>
                <div class="col-lg-1">
                    <select class="form-control"
                            id="includeAuthorizeSignOfficer"
                            name="includeAuthorizeSignOfficer"
                            [(ngModel)]="privateLenderWrapper.matterParticipant.includeAuthorizeSignOfficer"
                            (change)="onPrivateLenderInfoChanged()">
                        <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>

            <div *ngIf="signingOfficerInfoVisible">
                <dp-signing-officer #dpSigningOfficer [matter]="matter"
                                    [parentParticipant]="privateLenderWrapper.matterParticipant"
                                    [parentContact]="privateLender"
                                    [isMatterSnapShot]="privateLenderWrapper.isPrivateLenderMortgagee || isVTBMortgagee"
                                    [errorTopic]="'<MAIN_TOPIC>'"
                                    (onDataChange)="signingOfficerChanges($event)"
                ></dp-signing-officer>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="privateLenderWrapper.primary">
        <div class="form-group" *ngIf="privateLenderWrapper &&  !privateLenderWrapper.isPrivateLenderMortgagee">
            <div>
                <label class="control-label col-lg-2" for="dear">Dear</label>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="dear"
                           name="dear"
                           class="form-control"
                           (keydown.f9)="onF9InDear()"
                           [dp-read-only]="readonly"
                           [fieldKey]="'privateLender.dear'"
                           statusBarHelp
                           [(ngModel)]="privateLender.dear"
                           maxlength="30"
                           (change)="onPrivateLenderInfoChanged()"/>
                </div>
            </div>

        </div>

        <div class="form-group" *ngIf="privateLenderWrapper.primary">
            <div *ngIf="!privateLender.isCorporationOrOtherEntity">
                <label class="control-label col-lg-2" for="homePhone">Home Phone</label>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="homePhone"
                           name="homePhone"
                           [dp-read-only]="readonly"
                           class="form-control"
                           [(ngModel)]="privateLender.homePhone"
                           maxlength="30"
                           (change)="onPrivateLenderInfoChanged()"/>
                </div>
            </div>
            <div>
                <label class="control-label col-lg-2" for="cellPhone">Cell Phone</label>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="cellPhone"
                           name="cellPhone"
                           [dp-read-only]="readonly"
                           class="form-control"
                           [(ngModel)]="privateLender.cellPhone"
                           maxlength="30"
                           (change)="onCellPhoneChanged()"/>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="privateLenderWrapper.primary">
            <div>
                <label class="control-label col-lg-2" for="workPhone">Work Phone</label>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="workPhone"
                           name="workPhone"
                           [dp-read-only]="readonly"
                           class="form-control"
                           [(ngModel)]="privateLender.workPhone"
                           maxlength="30"
                           (change)="onPrivateLenderInfoChanged()"/>
                </div>
            </div>
            <div>
                <label class="control-label col-lg-2" for="fax">Fax</label>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="fax"
                           [dp-read-only]="readonly"
                           name="fax"
                           class="form-control"
                           [(ngModel)]="privateLender.faxPhone"
                           maxlength="30"
                           (change)="onPrivateLenderInfoChanged()"/>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="privateLenderWrapper.primary">
            <div>
                <label class="control-label col-lg-2" for="email" [ngClass]="{error: !email.valid}">Email
                    Address</label>
                <div class="col-lg-10">
                    <input type="email"
                           trimmed
                           id="email"
                           name="email"
                           dp-email-field
                           [dp-read-only]="readonly"
                           #email="ngModel"
                           class="form-control"
                           [(ngModel)]="privateLender.email"
                           [fieldKey]="'privateLender.email'"
                           dp-error-validator
                           maxlength="100"
                           (change)="handleContactChange($event);onPrivateLenderInfoChanged()"/>
                    <!-- @formatter:off -->

                    <!-- @formatter:on -->
                </div>
            </div>
        </div>

        <div *ngIf="showMailingAddress && privateLenderWrapper.primary">
            <dp-address-form [address]="privateLender.mailingAddress"
                             addressSummaryLabel="Mailing Address"
                             (change)="onPrivateLenderInfoChanged()"
                             [disabled]="readonly"
                             [fieldPrefix]="fieldPrefix+'.mailing.address'"
                             [addressMaxLength]="getMortgageeAddressMaxLength()"
                             [populateDefaultProvince]="false"
            ></dp-address-form>
        </div>
    </ng-container>


</div>
