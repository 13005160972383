import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Paragraph} from './paragraph';
import {DeclarationSigningOfficer} from './declarationSigningOfficer';

export type DeclarationType = 'CLIENT' | 'SOLICITOR';

export class Declaration extends BaseEntity {
  id: number;
  matterId: number;
  writId: number; //present if created from writ
  clientName: string;
  solicitorName: string;
  gender: string;
  declarationType: DeclarationType;
  officerName1: string;
  officerName2: string;
  isDirty: boolean = false;
  paragraphs: Paragraph[] = [];
  declarationSigningOfficers: DeclarationSigningOfficer[] = [];
  participantId: number;

  constructor(d?: Declaration) {
    super(d);
    if (d) {
      if (d.paragraphs) {
        for (let p of d.paragraphs) {
          this.paragraphs.push(new Paragraph(p));
        }
      }
      if (d.declarationSigningOfficers) {
        for (let p of d.declarationSigningOfficers) {
          this.declarationSigningOfficers.push(new DeclarationSigningOfficer(p));
        }
      }
    }
  }

  isSolicitorDeclaration(): boolean {
    return this.declarationType === 'SOLICITOR';
  }

  get declarationHeading(): string {
    let heading: string = this.clientName + ' - ';
    heading += this.isSolicitorDeclaration() ? 'Solicitor\'s Declaration' : 'Client\'s Declaration';
    return heading;
  }

  get isNonIndividualDeclaration(): boolean {
    return !this.isIndividualDeclaration;
  }

  get isIndividualDeclaration(): boolean {
    return !this.isSolicitorDeclaration() && (this.gender === 'MALE' || this.gender === 'FEMALE');
  }

  findParagraphByWritExecutionId(writExecutionId: number): Paragraph {
    return this.paragraphs.find(paragraph => paragraph.writExecutionId == writExecutionId);
  }

  createSolicitorParagraphFromClientParagraph(paragraph: Paragraph): Paragraph {
    let solicitorParagraph: Paragraph = new Paragraph(paragraph);
    solicitorParagraph.id = null;
    solicitorParagraph.automaticallyCreatedDeclaration = false;
    solicitorParagraph.solicitorName = null;
    solicitorParagraph.adjustParagraphTextForNonIndividualEntity(this.clientName);
    return solicitorParagraph;
  }
}
