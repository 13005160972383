<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Edit Details</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12">
            <div class="col-lg-3 text-right">
                <label class="control-label" for="fileName">Name</label>
            </div>
            <div class="col-lg-9">
                <input class="form-control"  name="fileName" id="fileName"
                       [(ngModel)]="documentTemplateFile.documentTemplate.fileName"
                       [dp-read-only]="true"
                />
            </div>
        </div>
        <div class="col-lg-12 padding-top-10">
            <div class="col-lg-3 text-right">
                <label class="control-label" for="description">Description</label>
            </div>
            <div class="col-lg-9">
                <textarea name="description" id="description"
                          [(ngModel)]="documentTemplateFile.documentTemplate.description"
                          maxlength="255"
                ></textarea>
            </div>
        </div>
        <div class="col-lg-12 padding-top-10 padding-bottom-10">
            <div class="col-lg-3 text-right padding-top-7">
                <label class="control-label">Matter Type(s)</label>
            </div>
            <div class="col-lg-9">
                <dp-multi-select [multiSelectData]="matterTypes"
                                 [showLabelInTitle]="true"
                                 [inputName]="'matterType'"
                                 [defaultToAll]="false"
                                 [multiSelectDataSelected]="getDocumentTemplateMatterTypes()"
                                 (updateMultiSelect)="updateMultiSelectFilters($event)"
                                 class="lawyer-multi-select"></dp-multi-select>
            </div>

        </div>
        <div class="row col-lg-12 form-group padding-top-20 margin-bottom-200">
            <div class="col-lg-3 text-right">
                <label class="control-label">Multi Client Template</label>
            </div>
            <div class="col-lg-9 padding-top-8">
                <dp-checkbox fieldId="templateProductionClass">
                    <input type="checkbox"
                           id="templateProductionClass"
                           name="templateProductionClass"
                           [(ngModel)]="isMultiClientProductionClass"
                           (ngModelChange)="onTemplateProductionClassChange($event)"
                    />
                </dp-checkbox>
            </div>
        </div>



    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="save()">
                <span>Save</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>

</div>
