import {EnvelopeSalutationFormats} from '../misc/EnvelopeSalutationFormats';

export const soaDropDowns = {

  YESNOOPTIONS: [
    {value: 'YES', label: 'Yes'},
    {value: 'NO', label: 'No'}
  ],
  PAPERSIZES: [
    {value: 'LETTER', label: 'Letter'},
    {value: 'LEGAL', label: 'Legal'}
  ],

  OTHERHEADINGSVENDORNAMES: [
    {value: 'RE_LINE', label: 'Vendor\'s RE: Line (from Vendors & Solicitor Topic)'},
    {value: 'FULL_NAMES', label: 'full names'},
    {value: 'NONE', label: '*** none ***'}
  ],
  OTHERHEADINGSPURCHASERNAMES: [
    {value: 'RE_LINE', label: 'Client\'s RE: Line (from Purchasers Topic)'},
    {value: 'FULL_NAMES', label: 'full names'},
    {value: 'NONE', label: '*** none ***'}
  ],
  OTHERHEADINGSVENDORSALENAMES: [
    {value: 'RE_LINE', label: 'Client\'s RE: Line  (from Vendors Topic)'},
    {value: 'FULL_NAMES', label: 'full names'},
    {value: 'NONE', label: '*** none ***'}
  ],
  OTHERHEADINGSPURCHASERSALENAMES: [
    {value: 'RE_LINE', label: 'Purchaser\'s RE: Line (from Purchasers & Solicitor Topic)'},
    {value: 'FULL_NAMES', label: 'full names'},
    {value: 'NONE', label: '*** none ***'}
  ],
  OTHERHEADINGSVENDORDPNAMES: [
    {value: 'RE_LINE', label: 'Vendor\'s RE: Line'},
    {value: 'FULL_NAMES', label: 'full names'},
    {value: 'NONE', label: '*** none ***'}
  ],
  OTHERHEADINGSPURCHASERDPNAMES: [
    {value: 'RE_LINE', label: 'Purchaser\'s RE: Line'},
    {value: 'FULL_NAMES', label: 'full names'},
    {value: 'NONE', label: '*** none ***'}
  ],
  ADDRESSDATA: [
    {value: 'FORMAT1', label: '(A) street no. street name and city'},
    {value: 'FORMAT2', label: '(B) street no. and street name only'},
    {value: 'FORMAT3', label: '(C) street no., name, city and postal code'},
    {value: 'FORMAT4', label: '(E) same as (A) with legal description'},
    {value: 'FORMAT5', label: '(F) same as (B) with legal description'},
    {value: 'FORMAT6', label: '(G) same as (C) with legal description'},
    {value: 'FORMAT7', label: 'legal description only'},
    {value: 'NONE', label: '*** none ***'}
  ],

  ADJUSTDATE: [
    {value: 'ADJUST_DATE', label: 'adjustment date'},
    {value: 'NONE', label: '*** none ***'}
  ],

  PREFERREDTERMINOLOGY: [
    {value: 'CREDITED_WITH_SUM_OF', label: 'was credited with the sum of'},
    {value: 'CREDITED_FOR_SUM_OF', label: 'was credited for the sum of'}
  ],

  ADDRESSFORMAT: [
    {value: 'FORMAT1', label: 'Toronto, Ontario [hard return] M1M 1M1'},
    {value: 'FORMAT2', label: 'Toronto ON M1M 1M1'},
    {value: 'FORMAT3', label: 'TORONTO ON M1M 1M1'}
  ],

  DEFAULTTEXTPOLARISMORTGAGEOPTIONS: [
    {value: 'FULL_INSURABLE_VALUE', label: 'Full insurable value'},
    {value: 'FULL_REPLACEMENT_COST', label: 'Full replacement cost'}
  ],

  DECLAREDORSWORNOPTIONS: [
    {value: 'DECLARED', label: 'Declared'},
    {value: 'SWORN', label: 'Sworn'}
  ],

  TITLENOINPURCHASEROPTION: [
    {value: 'VENDORTITLENO', label: 'Vendor\'s title no'},
    {value: 'OTHER', label: 'Other'}
  ],

  DEFAULTADDRESSOFSERVICE: [
    {value: 'COLAWFIRM', label: 'c/o law firm'},
    {value: 'SAMEASBOX1ABOVE', label: 'same as box 1 above'}
  ],

  PROJECTSOAGLOBALFORMATS: [
    {label: '1. Print adjustment heading in uppercase', value: 'HEADING_UPPERCASE'},
    {label: '2. Print adjustment heading in bold', value: 'HEADING_BOLD'},
    {label: '3. Underline adjustment heading', value: 'HEADING_UNDERLINE'},
    {label: '4.  Last line to read "Credit Vendor" or "Credit Purchaser"', value: 'HEADING_LAST_LINE_CREDIT'},
    {label: '5. Follow adjustment with one blank line', value: 'HEADING_BLANK_LINE'},
    {label: '6. Print dollar signs only for first credit of each column', value: 'HEADING_DOLLAR_SIGN_FIRST'},
    {label: '7. Don\'t print dollar signs in subtotal column', value: 'HEADING_NO_DOLLAR_SIGN_SUBTOTAL'}
  ],

// client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe"
// a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe". Example of a female client with a first name of "Jane Mary", middle name of "Fredericka" and last name of "Doe"
// FLFN = FirstLetterFirstName
// FLMN = FirstLetterMiddletName
// FN = FirstName
// LN = LastName
// MFN = male first name
// FFN = female first name
// MLN = male last name
// FLN = female last name
// MFLFN = male FirstLetterFirstName
// FFLMN = female FirstLetterMiddletName

  contactDefaultMaleEnvelopeSalutations: [
    {label: 'No Default', value: EnvelopeSalutationFormats.NO_DEFAULT},
    {label: 'Mr. J. Doe', value: EnvelopeSalutationFormats.MR_FLFN_LN},
    {label: 'Mr. John Richard Doe', value: EnvelopeSalutationFormats.MR_FN_LN},
    {label: 'John Richard Doe', value: EnvelopeSalutationFormats.FN_LN}
  ],

  contactDefaultMaleDears: [
    {label: 'No Default', value: EnvelopeSalutationFormats.NO_DEFAULT},
    {label: 'Mr. Doe', value: EnvelopeSalutationFormats.MR_LN},
    {label: 'John Richard', value: EnvelopeSalutationFormats.FN},
    {label: 'Sir', value: EnvelopeSalutationFormats.SIR}
  ],

  contactDefaultFemaleEnvelopeSalutations: [
    {label: 'No Default', value: EnvelopeSalutationFormats.NO_DEFAULT},
    {label: 'Ms. J. Doe', value: EnvelopeSalutationFormats.MS_FLFN_LN},
    {label: 'Ms. Jane Mary Doe', value: EnvelopeSalutationFormats.MS_FN_LN},
    {label: 'Mrs. J. Doe', value: EnvelopeSalutationFormats.MRS_FLFN_LN},
    {label: 'Mrs. Jane Mary Doe', value: EnvelopeSalutationFormats.MRS_FN_LN},
    {label: 'Miss J. Doe', value: EnvelopeSalutationFormats.MISS_FLFN_LN},
    {label: 'Miss Jane Mary Doe', value: EnvelopeSalutationFormats.MISS_FN_LN},
    {label: 'Jane Mary Doe', value: EnvelopeSalutationFormats.FN_LN}
  ],

  contactDefaultFemaleDears: [
    {label: 'No Default', value: EnvelopeSalutationFormats.NO_DEFAULT},
    {label: 'Ms. Doe', value: EnvelopeSalutationFormats.MS_LN},
    {label: 'Mrs. Doe', value: EnvelopeSalutationFormats.MRS_LN},
    {label: 'Miss Doe', value: EnvelopeSalutationFormats.MISS_LN},
    {label: 'Jane Mary', value: EnvelopeSalutationFormats.FN},
    {label: 'Madam', value: EnvelopeSalutationFormats.MADAM}
  ],

  contactDefaultCoupleEnvelopeSalutations: [
    {label: 'No Default', value: EnvelopeSalutationFormats.NO_DEFAULT},
    {label: 'Mr. J. Doe & Ms. J. Doe', value: EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MS_FFLFN_FLN},
    {label: 'Mr. J. Doe and Ms. J. Doe', value: EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MS_FFLFN_FLN},
    {label: 'Mr. J. Doe & Mrs. J. Doe', value: EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN},
    {label: 'Mr. J. Doe and Mrs. J. Doe', value: EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MRS_FFLFN_FLN},
    {label: 'Mr. & Ms. J. Doe', value: EnvelopeSalutationFormats.MR_AMP_MS_MFLFN_MLN},
    {label: 'Mr. and Ms. J. Doe', value: EnvelopeSalutationFormats.MR_AND_MS_MFLFN_MLN},
    {label: 'Mr. & Mrs. J. Doe', value: EnvelopeSalutationFormats.MR_AMP_MRS_MFLFN_MLN},
    {label: 'Mr. and Mrs. J. Doe', value: EnvelopeSalutationFormats.MR_AND_MRS_MFLFN_MLN},
    {label: 'Mr. & Ms. J. & J. Doe', value: EnvelopeSalutationFormats.MR_AMP_MS_MFLFN_AMP_FFLFN_MLN},
    {label: 'Mr. and Ms. J. & J. Doe', value: EnvelopeSalutationFormats.MR_AND_MS_MFLFN_AMP_FFLFN_MLN},
    {label: 'Mr. and Ms. J. and J. Doe', value: EnvelopeSalutationFormats.MR_AND_MS_MFLFN_AND_FFLFN_MLN},
    {label: 'Mr. & Mrs. J. & J. Doe', value: EnvelopeSalutationFormats.MR_AMP_MRS_MFLFN_AMP_FFLFN_MLN},
    {label: 'Mr. and Mrs. J. & J. Doe', value: EnvelopeSalutationFormats.MR_AND_MRS_MFLFN_AMP_FFLFN_MLN},
    {label: 'Mr. and Mrs. J. and J. Doe', value: EnvelopeSalutationFormats.MR_AND_MRS_MFLFN_AND_FFLFN_MLN},
    {label: 'Mr. & Ms. John Richard & Jane Mary Doe', value: EnvelopeSalutationFormats.MR_AMP_MS_MFN_AMP_FFN_MLN},
    {label: 'Mr. and Ms. John Richard & Jane Mary Doe', value: EnvelopeSalutationFormats.MR_AND_MS_MFN_AMP_FFN_MLN},
    {label: 'Mr. and Ms. John Richard and Jane Mary Doe', value: EnvelopeSalutationFormats.MR_AND_MS_MFN_AND_FFN_MLN},
    {label: 'Mr. & Mrs. John Richard & Jane Mary Doe', value: EnvelopeSalutationFormats.MR_AMP_MRS_MFN_AMP_FFN_MLN},
    {label: 'Mr. and Mrs. John Richard & Jane Mary Doe', value: EnvelopeSalutationFormats.MR_AND_MRS_MFN_AMP_FFN_MLN},
    {label: 'Mr. and Mrs. John Richard and Jane Mary Doe', value: EnvelopeSalutationFormats.MR_AND_MRS_MFN_AND_FFN_MLN},
    {label: 'John Richard & Jane Mary Doe', value: EnvelopeSalutationFormats.FN_AMP_FN_LN},
    {label: 'John Richard and Jane Mary Doe', value: EnvelopeSalutationFormats.FN_AND_FN_LN},
    {label: 'John Richard Doe & Jane Mary Doe', value: EnvelopeSalutationFormats.FN_LN_AMP_FN_LN},
    {label: 'John Richard Doe and Jane Mary Doe', value: EnvelopeSalutationFormats.FN_LN_AND_FN_LN}
  ],

  contactDefaultCoupleDears: [
    {label: 'No Default', value: EnvelopeSalutationFormats.NO_DEFAULT},
    {label: 'Mr. & Ms. Doe', value: EnvelopeSalutationFormats.MR_AMP_MS_MLN},
    {label: 'Mr. and Ms. Doe', value: EnvelopeSalutationFormats.MR_AND_MS_MLN},
    {label: 'Mr. & Mrs. Doe', value: EnvelopeSalutationFormats.MR_AMP_MRS_MLN},
    {label: 'Mr. and Mrs. Doe', value: EnvelopeSalutationFormats.MR_AND_MRS_MLN},
    {label: 'Mr. Doe & Ms. Doe', value: EnvelopeSalutationFormats.MR_MLN_AMP_MS_FLN},
    {label: 'Mr. Doe and Ms. Doe', value: EnvelopeSalutationFormats.MR_MLN_AND_MS_FLN},
    {label: 'Mr. Doe & Mrs. Doe', value: EnvelopeSalutationFormats.MR_MLN_AMP_MRS_FLN},
    {label: 'Mr. Doe and Mrs. Doe', value: EnvelopeSalutationFormats.MR_MLN_AND_MRS_FLN},
    {label: 'John Richard & Jane Mary', value: EnvelopeSalutationFormats.FN_AMP_FN},
    {label: 'John Richard and Jane Mary', value: EnvelopeSalutationFormats.FN_AND_FN},
    {label: 'Sir & Madam', value: EnvelopeSalutationFormats.SIR_AMP_MADAM},
    {label: 'Sir and Madam', value: EnvelopeSalutationFormats.SIR_AND_MADAM}
  ]

};
