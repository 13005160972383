import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {JournalNote} from './account-notes';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class AddNoteDialogContext {
  notes: string; //existing notes, if any
  showNotesOnOpen: boolean;
  isShowNotesOnOpenVisible: boolean;
}

@Component({
  selector: 'dp-add-modal-note',
  templateUrl: 'add-note-modal.component.html',
  styleUrls: ['./add-note-modal.styles.scss'],
  providers: [ErrorService]
})
export class AddNoteDialogComponent extends ModalComponent<AddNoteDialogContext> {
  userCreationTimestamp: number;
  textAreaNote: string;
  showNotesOnOpen: boolean;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) context?: AddNoteDialogContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.showNotesOnOpen = this.context.showNotesOnOpen;
    if (this.context.notes) {
      this.textAreaNote = this.context.notes;
    }
    this.userCreationTimestamp = Date.now();
  }

  checkForErrors() {
    this.modalErrorComponent.removeDpFieldError('admin.notes.addJournalNote');
    if (this.textAreaNote) {
      this.textAreaNote = this.textAreaNote.trim();
    }
    if (this.textAreaNote == '' || this.textAreaNote == null || this.textAreaNote == undefined) {
      this.modalErrorComponent.createDPFieldError('admin.notes.addJournalNote');
    }
  }

  cancelDialog() {
    this.dialog.close();
  }

  closeDialog() {
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      let noteData = new JournalNote();
      noteData.userCreationTimestamp = this.userCreationTimestamp;
      noteData.noteBody = this.textAreaNote;
      this.dialog.close({noteData: noteData, showNotesOnOpen: this.showNotesOnOpen});
    }
  }
}
