import {docketApi} from './../shared/docket-api';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {TeraviewConfig} from './teraview-config';

@Injectable()
export class DocketService {

  Docket_keyvalue_map = {
    '1': 'DOCKET_CONFIG_BLANK',
    '2': 'DOCKET_CONFIG_MATTER',
    '3': 'DOCKET_CONFIG_FILENO',
    '4': 'DOCKET_CONFIG_PSM_MATTER',
    '5': 'DOCKET_CONFIG_PSM_FILENO'
  };

  constructor(private http: HttpClient) {
  }

  getTeraviewConfig(accountId: string): Observable<TeraviewConfig> {
    let url = docketApi.teraviewConfigurations.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return new TeraviewConfig(res[ 'TeraviewConfigurations' ]);
    });
  }

  updateTeraviewConfig(teraviewConfig: TeraviewConfig, accountId: string): Observable<any> {
    let url = docketApi.teraviewConfigurations.replace('{accountId}', accountId) + `/${ teraviewConfig.id }`;

    return this.http.put(url, teraviewConfig)
    .map((response) => {
      if (response) {
        return new TeraviewConfig(response[ 'TeraviewConfigurations' ]);
      }
    });
  }

}

