import {Component, Inject, ViewChild} from '@angular/core';
import moment from 'moment';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {StatementOfAdjustmentHeading} from '../model/statement-adjustment-heading';
import {booleanYesNoDropDowns, PERCENT_OF_OPTIONS} from '../../../shared-main/constants';
import {AppConfig} from '../../../shared-main/app-configuration';
import Utils from '../../../shared-main/utils';
import {ModalResult} from '../../../shared-main/enums';
import {Matter} from '../../shared';
import {Project} from '../../../projects/shared/project';
import {StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../statement-adjustment-constants';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {StatementAdjustmentDisplayBuilder} from '../builders/statement-adjustment-display-builder';
import {
  SoAdjPurchasePricePercentage,
  SoAdjPurchasePricePercentageItem
} from '../model/so-adj-purchase-price-percentage';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var jQuery: any;

class SoaOtherPurchasePricePercentageModalContext {
  closingDate: string;
  soAdjPurchasePricePercentage: SoAdjPurchasePricePercentage;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  provinceDefaultTaxRate: string;
  defaultHstRate: number;
  defaultPstRate: number;
  defaultGstRate: number;
  provinceCode: string;
  additionalConsiderationsInclHst: number;
  matter: Matter;
  project: Project;
  statementAdjustmentKey: string;
  soaConsiderationTaxes: ConsiderationTaxes;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-purchase-price-percentage-modal-content',
  templateUrl: 'other-purchase-price-percentage.modal.component.html',
  providers: [ErrorService]
})
export class SoaOtherPurchasePricePercentageModal extends ModalComponent<SoaOtherPurchasePricePercentageModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  localAdjustment: SoAdjPurchasePricePercentage;
  creditTargetTypes: SelectItem[];
  taxTypes: SelectItem[];
  yesNoOptions: SelectItem[] = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];
  noYesOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;
  percentOfOptions: SelectItem[];
  maxItems: number = 2;
  minNumberOfItems: number = 2;
  closingDateLabel: string = '';
  deleteIndex: number = 0;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<SoaOtherPurchasePricePercentageModal>,
    public dialogService: DialogService,
    public decimalPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,
    public percentPipe: PercentPipe,
    private documentProfileCache: DocumentProfileCache,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: SoaOtherPurchasePricePercentageModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.taxTypes = Utils.loadTaxSelectItem(
      this.context.soaConsiderationTaxes.rateTypeLabel,
      this.context.defaultPstRate
    );
    this.statementAdjustmentDisplayUtil = new StatementAdjustmentDisplayBuilder(
      this.decimalPipe,
      this.currencyPipe,
      this.percentPipe
    );
    this.statementAdjustmentDisplayUtil.documentProfileCache = this.documentProfileCache;
    this.statementAdjustmentDisplayUtil.setMatter(this.context.matter);
    this.statementAdjustmentDisplayUtil.setSoaConsiderationTaxes(this.context.soaConsiderationTaxes);

    this.populateCreditTargetTypes();

    this.percentOfOptions = [
      {label: 'Sale Price', value: PERCENT_OF_OPTIONS.salePrice},
      {label: 'Adj. Sale Price', value: PERCENT_OF_OPTIONS.adjSalePrice},
      {label: 'Deposit', value: PERCENT_OF_OPTIONS.deposit},
      {label: 'Interest on Deposits', value: PERCENT_OF_OPTIONS.interestOnDeposits},
      {label: 'Interest on Deposit Interest', value: PERCENT_OF_OPTIONS.interestOnDepositInterest}
    ];

    if (!this.context.soAdjPurchasePricePercentage) {
      this.loadDefaultPurchasePricePercentageSoa();
      this.newAdj = true;
    } else {
      this.localAdjustment = new SoAdjPurchasePricePercentage(this.context.soAdjPurchasePricePercentage);
      if (this.localAdjustment.items.length < this.minNumberOfItems) {
        this.fillInEmptyItems(this.minNumberOfItems - this.localAdjustment.items.length);
      }
    }

    this.maxItems = this.appConfig.maxNumberOfAmountItemsOnAdjustmentsOtherFixed;
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  loadDefaultPurchasePricePercentageSoa(): void {
    this.localAdjustment = new SoAdjPurchasePricePercentage();
    this.localAdjustment.creditType = StatementAdjustmentAmountTypes.VENDOR;
    this.localAdjustment.percent = 0.0;
    this.localAdjustment.percentOf = PERCENT_OF_OPTIONS.salePrice;

    this.localAdjustment.taxType = this.context.soaConsiderationTaxes.provinceDefaultTaxRate;
    this.localAdjustment.applyTax = false;
    this.localAdjustment.applyPst = false;
    this.localAdjustment.hstRate = this.context.defaultHstRate;
    this.localAdjustment.gstRate = this.context.defaultGstRate;
    this.localAdjustment.pstRate = this.context.defaultPstRate;

    // always have at least two empty ones
    this.fillInEmptyItems(this.minNumberOfItems);
  }

  get adjustmentType(): string {
    return ' Percentage of Purchase Price';
  }

  isOtherFixedPayableOnOccupancy(): boolean {
    return this.context.statementAdjustmentKey === StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY;
  }

  populateCreditTargetTypes(): void {
    this.creditTargetTypes = [
      {label: this.purchaserLabel, value: StatementAdjustmentAmountTypes.PURCHASER},
      {label: this.vendorLabel, value: StatementAdjustmentAmountTypes.VENDOR}
    ];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    this.cachedDetails = this.statementAdjustmentDisplayUtil.getDisplayItems(
      this.localAdjustment,
      this.context.closingDate,
      true,
      this.context.statementAdjustmentKey
    );

    return this.cachedDetails;
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  get isCreditPurchaser(): boolean {
    return this.localAdjustment && this.localAdjustment.creditType === StatementAdjustmentAmountTypes.PURCHASER;
  }

  get isCreditVendor(): boolean {
    return this.localAdjustment && this.localAdjustment.creditType === StatementAdjustmentAmountTypes.VENDOR;
  }

  get isCreditPurchaserOrVendor(): boolean {
    return this.isCreditPurchaser || this.isCreditVendor;
  }

  get creditToLabel(): string {
    return `Credit ${this.purchaserLabel} or ${this.vendorLabel}?`;
  }

  removeEmptyItems() {
    this.localAdjustment.items = this.localAdjustment.items.filter((itm) => itm.heading.length > 0);
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.removeEmptyItems();
      this.dialog.close(this.localAdjustment);
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  formattedDate(dateStr: string): string {
    return dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
  }

  addNewItem() {
    if (this.localAdjustment.items.length < this.maxItems) {
      this.localAdjustment.items.push(this.getNewItem());

      setTimeout(() => {
        jQuery(`#item-heading-${this.localAdjustment.items.length - 1}-${this.deleteIndex}`).focus();
      }, 100);
    }
  }

  removeItem(item: SoAdjPurchasePricePercentageItem) {
    let index: number = this.localAdjustment.items.indexOf(item);
    if (index > -1) {
      this.localAdjustment.items.splice(index, 1);
      this.deleteIndex++;
      //always at least 2 empty items
      if (this.localAdjustment.items.length < this.minNumberOfItems) {
        this.fillInEmptyItems(1);
      }

      setTimeout(() => {
        jQuery(`#item-heading-${this.localAdjustment.items.length - 1}-${this.deleteIndex}`).focus();
      }, 100);
    }
  }

  fillInEmptyItems(howMany: number) {
    for (let i: number = 0; i < howMany; i++) {
      this.localAdjustment.items.push(this.getNewItem());
    }
  }

  getNewItem(): SoAdjPurchasePricePercentageItem {
    let newItem = new SoAdjPurchasePricePercentageItem();
    newItem.heading = '';
    return newItem;
  }

  checkForErrors() {
    if (!this.localAdjustment.adjustmentHeading || this.localAdjustment.adjustmentHeading.length == 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.fixed.description');
    }
  }

  displayTaxFields(): boolean {
    return (
      this.localAdjustment &&
      this.isCreditPurchaserOrVendor &&
      (this.localAdjustment.applyTaxBoolean || this.localAdjustment.applyPstBoolean)
    );
  }

  get showPstPercentageFields(): boolean {
    return (
      this.localAdjustment &&
      this.localAdjustment.taxType == 'GST' &&
      Utils.convertToBoolean(this.localAdjustment.applyPst)
    );
  }

  isMatterProvinceAB(): boolean {
    return this.context.matter && this.context.matter.isMatterProvinceAB;
  }
}
