import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {UUIDUtil} from '../../main/uuid-util';
import {Matter} from './matter';
import {Mortgage} from './mortgage';
import Utils from '../../shared-main/utils';
import {Utils as Utils2} from './';
import {undertakingTypes} from '../../common/common-labels';
import moment from 'moment';
import {MortgageDispositionType} from '../../shared-main/constants';
import {UndertakingsConfigService} from '../../admin/shared/undertaking-config.service';
import {UndertakingsConfig} from '../../admin/mortgage-discharge/undertakings-config';
import {MortgageDischargeConfig} from '../../admin/mortgage-discharge/config/mortgage-discharge-config';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {maxFieldLength} from './max-field-length';

export type MatterUndertakingStatus = 'FULFILLED' | 'OUTSTANDING' | 'APPROVED' | 'NOT_APPROVED' | ''; //The empty value is for Mass update template matter only

export interface MatterUndertakingStatusDropdown {
  label: string;
  value: MatterUndertakingStatus;
}

export const MatterUndertakingStatusValues = {
  outstanding: 'OUTSTANDING',
  fulfilled: 'FULFILLED',
  approved: 'APPROVED',
  not_approved: 'NOT_APPROVED'
};
export const MatterUndertakingNBDefaultConfig = {
  Non_Encumbrance_Undertakings: 'Non-Encumbrance Undertakings',
  Acknowledgments: 'Acknowledgments'

};

export class MatterUndertaking extends BaseEntity {
  id: number;
  static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
  subject: string;
  matterUndertakingStatus: MatterUndertakingStatus;
  dischargedByInstrumentNo: string;
  registeredDate: string;
  followupDate: string;
  description: string;
  matterId: number;
  requisitionTemplateId: number;
  onCCT: boolean;
  actionToBeTaken: string;
  instrumentType: string;
  instrumentNo: string;
  inFavourOf: string;
  notes: string;
  dateOfInstrument: string;
  typeOfEncumbrance: string;
  instrumentId: number; //undertaking created from instrument

  approvedForSoAdjFlag: boolean;
  purchaserUndertakingFlag: boolean;

  constructor(undertaking?: MatterUndertaking) {
    super(undertaking);

    //generate a UUID for new undertaking (needed for reference from existing mortgage)
    if (!this.id) {
      this.id = UUIDUtil.getUUID();
      this.identifier = this.id;
    }
  }

  equals(ref: MatterUndertaking): boolean {
    return this.subject === ref.subject &&
      this.matterUndertakingStatus === ref.matterUndertakingStatus &&
      this.dischargedByInstrumentNo === ref.dischargedByInstrumentNo &&
      this.registeredDate === ref.registeredDate &&
      this.followupDate === ref.followupDate &&
      this.description === ref.description &&
      this.onCCT === ref.onCCT &&
      this.actionToBeTaken === ref.actionToBeTaken &&
      this.instrumentType === ref.instrumentType &&
      this.instrumentNo === ref.instrumentNo &&
      this.inFavourOf === ref.inFavourOf &&
      this.notes === ref.notes &&
      this.dateOfInstrument === ref.dateOfInstrument &&
      this.typeOfEncumbrance === ref.typeOfEncumbrance &&
      this.approvedForSoAdjFlag === ref.approvedForSoAdjFlag &&
      this.purchaserUndertakingFlag === ref.purchaserUndertakingFlag;
  }

  copy(src: MatterUndertaking): MatterUndertaking {
    this.id = src.id;
    this.subject = src.subject;
    this.matterUndertakingStatus = src.matterUndertakingStatus;
    this.dischargedByInstrumentNo = src.dischargedByInstrumentNo;
    this.registeredDate = src.registeredDate;
    this.followupDate = src.followupDate;
    this.description = src.description;
    this.matterId = src.matterId;
    this.requisitionTemplateId = src.requisitionTemplateId;
    this.onCCT = src.onCCT;
    this.actionToBeTaken = src.actionToBeTaken;
    this.instrumentType = src.instrumentType;
    this.instrumentNo = src.instrumentNo;
    this.inFavourOf = src.inFavourOf;
    this.notes = src.notes;
    this.dateOfInstrument = src.dateOfInstrument;
    this.typeOfEncumbrance = src.typeOfEncumbrance;
    this.approvedForSoAdjFlag = src.approvedForSoAdjFlag;
    this.purchaserUndertakingFlag = src.purchaserUndertakingFlag;
    return this;
  }

  //checks if this undertaking was created from an existing mortgage
  isFromExistingMortgage(matter: Matter): boolean {
    return matter.existingMortgages.some(existingMortgage => existingMortgage.undertakingId == this.id);
  }

  isTypeMortgage(): boolean {
    return this.typeOfEncumbrance == undertakingTypes.mortgage.key;
  }

  isTypeVendorsLienCaveat(): boolean {
    return this.typeOfEncumbrance == undertakingTypes.vendorsLienCaveat.key;
  }

  isTypeOther(): boolean {
    return this.typeOfEncumbrance == undertakingTypes.other.key;
  }

  isTypeWrit(): boolean {
    return this.typeOfEncumbrance == undertakingTypes.writ.key;
  }

  isNonEncumbranceUndertakingNB(): boolean {
    return this.subject == MatterUndertakingNBDefaultConfig.Non_Encumbrance_Undertakings;
  }

  isAcknowledgmentsNB(): boolean {
    return this.subject == MatterUndertakingNBDefaultConfig.Acknowledgments;
  }

  /**
   * Update undertaking based on the existing mortgage.
   *
   * @param {MatterUndertaking} undertaking
   * @param {Mortgage} mortgage
   */
  updateUndertakingFromMortgage(matter: Matter, mortgage: Mortgage, undertakingsConfigService: UndertakingsConfigService, status?: MatterUndertakingStatus): void {

    if (matter.isMatterProvinceON) {
      this.updateUndertakingFromMortgageON(matter, mortgage, status);
    } else if (matter.isMatterProvinceABorMBorSK || matter.isMatterProvinceBC) {
      this.updateUndertakingFromMortgageAB(matter, mortgage, status);
    } else if (matter.isMatterProvinceNS) {
      this.updateUndertakingFromMortgageNS(matter, mortgage, undertakingsConfigService, status);
    } else if (matter.isMatterProvinceNB) {
      this.updateUndertakingFromMortgageNB(matter, mortgage, undertakingsConfigService, status);
    }
  }

  // This method is now used for BC province too, as we have same functionality across ON & BC
  updateUndertakingFromMortgageON(matter: Matter, mortgage: Mortgage, status?: MatterUndertakingStatus): void {
    this.subject = 'Discharge ' + mortgage.financingType + ' - ' + (mortgage.mortgageRequestNo ? mortgage.mortgageRequestNo + ' - ' : '');
    this.matterUndertakingStatus = status ? status : <MatterUndertakingStatus>MatterUndertakingStatusValues.outstanding;

    this.description = 'obtain and register a good and valid discharge of the ' + mortgage.financingType;

    if (mortgage.mortgageRequestNo) {
      this.description += ' registered as Instrument Number ' + mortgage.mortgageRequestNo;
    }

    if (Array.isArray(mortgage.relatedInstruments) && mortgage.relatedInstruments.length > 0 && mortgage.relatedInstruments[ 0 ].registrationNumber) {
      this.description += ' as assigned by ' + mortgage.relatedInstruments[ 0 ].registrationNumber;
    }

    this.updateUndertakingSubjectAndDescription(matter, mortgage);
  }

  updateUndertakingFromMortgageNB(matter: Matter, mortgage: Mortgage, undertakingsConfigService: UndertakingsConfigService, status?: MatterUndertakingStatus): void {
    const mortgageIndex = matter.existingMortgages.findIndex(item => item.id == mortgage.id);
    this.subject = 'Discharge ' + Utils.getOrdinal(mortgageIndex + 1) + ' mortgage - ' + (mortgage.mortgageRequestNo ? mortgage.mortgageRequestNo + ' - ' : '');
    this.matterUndertakingStatus = status ? status : <MatterUndertakingStatus>MatterUndertakingStatusValues.outstanding;
    let undertakingsConfig = this.getUndertakingsConfig(undertakingsConfigService, matter);
    this.description = undertakingsConfig.mortgageDischargedConfiguration.actionToBeTakenText + ' ' + (mortgage.financingType ? Utils.convertToTitleCase(mortgage.financingType) : 'Mortgage');
    if (mortgage.mortgageRequestNo) {
      this.description += ' ' + undertakingsConfig.mortgageDischargedConfiguration.mortgageDocNumberText + ' ' + mortgage.mortgageRequestNo;
    }

    let inFavourOf = mortgage.getMortgageeNameWithModifier(matter, 'M');
    // if(mortgage.assignmentRequestNo){
    //    this.description +=  ' '+undertakingsConfig.mortgageDischargedConfiguration.assignedByInstrumentNumberText+' '+mortgage.assignmentRequestNo;
    if (Array.isArray(mortgage.relatedInstruments) && mortgage.relatedInstruments.length > 0 && mortgage.relatedInstruments[ 0 ].registrationNumber) {
      this.description += ' ' + undertakingsConfig.mortgageDischargedConfiguration.assignedByInstrumentNumberText + ' ' + mortgage.relatedInstruments[ 0 ].registrationNumber;
      if (inFavourOf) {
        this.description += ' ' + undertakingsConfig.mortgageDischargedConfiguration.toMortgageeText + ' ' + inFavourOf;
      }
    } else if (inFavourOf) {
      this.description += ' ' + undertakingsConfig.mortgageDischargedConfiguration.inFavourOfText + ' ' + inFavourOf;
    }
    this.approvedForSoAdjFlag = true;
    this.subject += mortgage.getMortgageeNameWithModifier(matter, 'Q');
  }

  // This method is now used for SK and MB provinces too, as we have same functionality across AB, SK & MB
  updateUndertakingFromMortgageAB(matter: Matter, mortgage: Mortgage, status?: MatterUndertakingStatus): void {
    this.onCCT = true;
    this.actionToBeTaken = 'to pay out sufficient funds to discharge';
    this.instrumentType = mortgage.financingType ? Utils.convertToTitleCase(mortgage.financingType) : 'Mortgage';
    this.description = 'to pay out sufficient funds to discharge ' + this.instrumentType;
    this.typeOfEncumbrance = undertakingTypes.mortgage.key;
    this.instrumentNo = mortgage.mortgageRequestNo;
    // if(mortgage.assignmentRequestNo){
    //     this.instrumentNo += ` as assigned by Instrument Number ${mortgage.assignmentRequestNo}`;
    // }

    if (Array.isArray(mortgage.relatedInstruments) && mortgage.relatedInstruments.length > 0 && mortgage.relatedInstruments[ 0 ].registrationNumber) {
      this.instrumentNo += ` as assigned by Instrument Number ${ mortgage.relatedInstruments[ 0 ].registrationNumber }`;
    }

    this.description += (this.instrumentNo ? ' No. ' + this.instrumentNo : '');
    this.matterUndertakingStatus = status ? status : <MatterUndertakingStatus>MatterUndertakingStatusValues.outstanding;
    if (matter.isMatterProvinceABorMB || matter.isMatterProvinceBC) {
      this.followupDate = this.calculateFollowupDate(matter);
    } else {
      this.followupDate = null;
    }
    this.inFavourOf = mortgage.getMortgageeNameWithModifier(matter, 'M');
    if (this.inFavourOf) {
      this.description += ' in favour of ' + this.inFavourOf;
    }

    if (mortgage.instrumentId && matter.isMatterProvinceMB && mortgage.mortgageDispositionType == MortgageDispositionType.DISCHARGED) {
      let tprInstrument = matter.getTprInstrumentByInstrumentId(mortgage.instrumentId);
      if (tprInstrument && tprInstrument.affectedByInstrumentTypeCode && tprInstrument.affectedByInstrumentTypeCode == 'AA') { //Amending Agreement - TPR
        this.description += ', including the deletion of the Assignment No. ' + tprInstrument.affectedByInstrumentNumber;
      }
    }

    //associating undertaking with instrument used to create existing mortgage
    this.instrumentId = mortgage.instrumentId;
    this.subject = this.getOnCCTSubject();
  }

  updateUndertakingFromMortgageNS(matter: Matter, mortgage: Mortgage, undertakingsConfigService: UndertakingsConfigService, status?: MatterUndertakingStatus): void {
    let undertakingsConfig = this.getUndertakingsConfig(undertakingsConfigService, matter);

    this.onCCT = true;
    this.actionToBeTaken = undertakingsConfig.mortgageDischargedConfiguration.actionToBeTakenText;
    this.instrumentType = mortgage.financingType ? Utils.convertToTitleCase(mortgage.financingType) : 'Mortgage';
    this.typeOfEncumbrance = undertakingTypes.mortgage.key;
    this.instrumentNo = mortgage.mortgageRequestNo;
    // if(mortgage.assignmentRequestNo){
    //     this.instrumentNo +=  ' ' + undertakingsConfig.mortgageDischargedConfiguration.assignedByInstrumentNumberText + ' ' + mortgage.assignmentRequestNo;
    // }

    if (Array.isArray(mortgage.relatedInstruments) && mortgage.relatedInstruments.length > 0 && mortgage.relatedInstruments[ 0 ].registrationNumber) {
      this.instrumentNo += ' ' + undertakingsConfig.mortgageDischargedConfiguration.assignedByInstrumentNumberText + ' ' + mortgage.relatedInstruments[ 0 ].registrationNumber;
    }

    this.inFavourOf = mortgage.getMortgageeNameWithModifier(matter, 'M');
    this.matterUndertakingStatus = status ? status : <MatterUndertakingStatus>MatterUndertakingStatusValues.outstanding;
    this.instrumentId = mortgage.instrumentId;
    this.subject = this.getOnCCTSubject();

    this.updateUndertakingFromMortgageDescriptionNS(undertakingsConfig);
  }

  getUndertakingsConfig(undertakingsConfigService: UndertakingsConfigService, matter: Matter) {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let undertakingsConfig: UndertakingsConfig = undertakingsConfigService.getCachedConfig(matter.provinceCode);
    if (!undertakingsConfig) {
      undertakingsConfig = new UndertakingsConfig();
      undertakingsConfig.customerAccountId = +accountId;
      undertakingsConfig.provinceCode = matter.provinceCode;
    }

    if (!undertakingsConfig.mortgageDischargedConfiguration) {
      undertakingsConfig.mortgageDischargedConfiguration = new MortgageDischargeConfig();
      undertakingsConfig.mortgageDischargedConfiguration.initValue(matter.provinceCode);
    }
    return undertakingsConfig;
  }

  updateUndertakingFromMortgageDescriptionNS(undertakingsConfig: UndertakingsConfig): void {
    this.description = this.actionToBeTaken + ' ' + this.instrumentType;
    if (this.instrumentNo) {
      this.description += ' ' + undertakingsConfig.mortgageDischargedConfiguration.mortgageDocNumberText + ' ' + this.instrumentNo;
    }

    if (this.inFavourOf) {
      this.description += ' ' + undertakingsConfig.mortgageDischargedConfiguration.inFavourOfText + ' ' + this.inFavourOf;
    }
  }

  /**
   * Update undertaking with mortgagee names using applicable modifiers.
   *
   * @param {MatterUndertaking} undertaking
   * @param {Mortgage} mortgage
   */
  private updateUndertakingSubjectAndDescription(matter: Matter, mortgage: Mortgage): void {
    this.subject += mortgage.getMortgageeNameWithModifier(matter, 'Q');

    const mortgageeNameModifierM = mortgage.getMortgageeNameWithModifier(matter, 'M');
    if (mortgageeNameModifierM) {
      // if (mortgage.assignmentRequestNo) {
      if (Array.isArray(mortgage.relatedInstruments) && mortgage.relatedInstruments.length > 0 && mortgage.relatedInstruments[ 0 ].registrationNumber) {
        this.description += ' to ';
      } else {
        this.description += ' in favour of ';
      }
      this.description += mortgageeNameModifierM;
    }
  }

  calculateFollowupDate(matter: Matter): string {
    if (Utils.isNotValidDate(matter.matterCloseDate)) {
      return null;

    } else {
      let followupDate = moment(matter.matterCloseDate, 'YYYY/MM/DD').add(30, 'days');
      return moment(followupDate).format('YYYY/MM/DD');
    }
  }

  getOnCCTSubject(): string {
    let subject = Utils.convertToTitleCase(this.instrumentType);
    if (this.instrumentNo) {
      subject += ` No. ${ this.instrumentNo }`;
    }
    if (this.inFavourOf) {
      subject += ` in favour of ${ this.inFavourOf }`;
    }
    return Utils2.cutStringValue(subject, maxFieldLength.undertaking.subject);
  }

  get groupId(): string {
    return this.identifier ? this.identifier + '' : '';
  }

}
