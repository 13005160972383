<form class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1 dp-province-field-label="matter.soa.prePaidRentInterestRateTitle">Pre-Paid Rent Interest
                    Rate</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div class="col-lg-12 alert alert-info alert-info-rent">
            <div class="col-lg-1">
                <i aria-hidden="true" class="fa fa-info-circle" ></i>
            </div>
            <div class="col-lg-11">
                <span>
                    Note that the rate of interest to be applied is that in effect at the time that payment is due.
                </span>
            </div>
        </div>

        <div class="row col-lg-12 interest-div" *dpShowByProvince="'matter.soa.prepaidTenancy.interestRateModel.legal'">
            <span>
            Interest<br> (6) A landlord of a rental unit shall pay interest to the tenant annually on the amount of the
            rent deposit at a rate equal to the guideline determined under section 120 that is in effect at the time
            payment becomes due. 2006, c. 17, s. 106(6).
            </span>
        </div>

        <div class="row col-lg-12 interest-div" *dpShowByProvince="'matter.soa.prepaidTenancy.interestRateModel.legal.other'">
            <span>
            Interest on security deposit<br>
                45(1) Subject to subsection (2), a landlord shall pay annually to the tenant interest calculated at the prescribed rate on a security deposit
                consisting of money.
                <br> (2) If a security deposit consists of money, a tenant and the tenant’s landlord may
                agree in writing that the interest on the security deposit shall not be paid annually and in that case the interest shall be compounded
                annually and be paid to the tenant on the expiration or termination of the tenancy.
                <br> (3) A landlord is entitled to retain any interest and
                profit resulting from the investment of a security deposit in excess of the amount of interest payable to the tenant under this section. (4)
                If a landlord and tenant agree that interest is to be payable under this section at a rate higher than the rate prescribed pursuant to this
                section, subsections (1), (2) and (3) are deemed to refer to the higher rate.
            </span>
        </div>




        <div class="row col-lg-12 main-div" *ngIf="theList && theList.length > 0">
            <table class="standard-table rent-interest-rate-table">
                <tbody>
                <tr *ngFor="let item of theList">
                    <td class="table-cell-1"><span>{{item.index}}</span></td>
                    <td class="table-cell-2 text-right"><span>{{item.fromDate | date: 'MMMM d, y'}}</span></td>
                    <td class="table-cell-3 text-center"><span>to</span></td>
                    <td class="table-cell-4 text-left"><span>{{item.toDate | date: 'MMMM d, y'}}</span></td>
                    <td class="table-cell-5"><span>{{item.interestRate/100 | percent:'1.2'}}</span></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">

            <button type="button" class="dp-btn" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
</form>
