import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, Inject} from '@angular/core';
import {ErrorService} from '../../../shared/error-handling/error-service';
import * as _ from 'lodash';
import {StaffProfiles} from '../../../admin/staff-profiles/staff-profiles';
import {TitleInsuranceBaseUser} from '../../../shared-main/title-insurance-base-user';
import {IntegrationSection} from '../manage-thirdparty-credentials.modal.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class StewartTitleLawyerProfileContext {
  stewartTitleUser: TitleInsuranceBaseUser;
  stewartTitleUserForReValidation: TitleInsuranceBaseUser;
  solicitors: TitleInsuranceBaseUser[];
  currentUserProfile: StaffProfiles;
  currentInsurer: IntegrationSection;
}

@Component({
  selector: 'dp-stewart-title-lawyer-profile-modal',
  templateUrl: 'stewart-title-lawyer-profile.modal.component.html',
  providers: [ErrorService]
})
@AutoUnsubscribe()
export class StewartTitleLawyerProfileModal extends ModalComponent<StewartTitleLawyerProfileContext> {
  selectedStewartUser: TitleInsuranceBaseUser;
  profileId: string;
  solicitorDropDownOptions: TitleInsuranceBaseUser[] = [];

  constructor(
    public dialog: MatDialogRef<StewartTitleLawyerProfileModal>,
    @Inject(MAT_DIALOG_DATA) context?: StewartTitleLawyerProfileContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.stewartTitleUserForReValidation) {
      this.solicitorDropDownOptions.push(this.stewartTitleUserForReValidation);
      this.selectedStewartUser = this.stewartTitleUserForReValidation;
      this.profileId = this.stewartTitleUserForReValidation.profileId;
    } else {
      this.solicitorDropDownOptions = _.sortBy(this.context.solicitors, ['solicitorName.firstLastNames']);
      this.solicitorDropDownOptions.unshift(new TitleInsuranceBaseUser()); //add blank user
      if (
        this.context &&
        this.context.currentUserProfile &&
        this.context.currentUserProfile.contact &&
        this.context.currentUserProfile.contact.contactName &&
        this.context.currentUserProfile.contact.contactName.firstLastNames
      ) {
        this.selectedStewartUser = this.solicitorDropDownOptions.find(
          (user) =>
            user.solicitorName &&
            user.solicitorName.firstLastNames == this.context.currentUserProfile.contact.contactName.firstLastNames
        );
      }

      if (!this.selectedStewartUser) {
        this.selectedStewartUser = this.solicitorDropDownOptions[0];
      }
    }
  }

  get stewartTitleUserForReValidation(): TitleInsuranceBaseUser {
    return this.context.stewartTitleUserForReValidation;
  }

  close(): void {
    this.dialog.close({action: 'Cancel'});
  }

  proceedToStewartTitleAuthentication(): void {
    this.selectedStewartUser.profileId = this.profileId;
    this.dialog.close({action: 'proceedStewartTitleAuth', solicitor: this.selectedStewartUser});
  }

  nextButtonEnabled(): boolean {
    return this.profileId && this.selectedStewartUser && this.selectedStewartUser.solicitorName ? true : false;
  }

  isTitlePlus(): boolean {
    return this.context.currentInsurer.isTitlePlus();
  }

  isStewartTitle(): boolean {
    return this.context.currentInsurer.isStewartTitle();
  }

  get modalHeaderText(): string {
    return this.isTitlePlus() ? 'Law Society Number' : 'Stewart Title Profile ID';
  }

  ngOnDestroy() {}
}
