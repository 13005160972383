<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="unity-logo-box register-card-unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo" style="width: 420px;"></span></a>
            </div>
        </div>
        <div class="trusted-phone-box register-card-trusted-phone-box">
            <div class="register-left-box">

                <div class="padding-bottom-20"
                     [ngClass]="{'padding-bottom-25' :!user.publicAccountId && fromDataMissing}">

                    <label class="login-label padding-bottom-5" for="publicAccountId">Account Number</label>
                    <div>
                        <input [(ngModel)]="user.publicAccountId" type="text" id="publicAccountId"
                               name="publicAccountId"
                               class="form-control" required>
                    </div>
                    <div *ngIf="!user.publicAccountId && fromDataMissing" class="col-lg-12 login-error">
                        <span class="error-input-img"></span>Account Number is required
                    </div>

                </div>
                <div class="padding-bottom-20">

                    <label class="login-label  padding-bottom-5" for="loginId">User ID</label>
                    <div>
                        <input [(ngModel)]="user.loginId" type="text" id="loginId" name="loginId"
                               class="form-control"
                               readonly>
                    </div>
                </div>
                <div>
                    <button type="submit" (click)="validateUserProfile()"

                            class="primary-btn login-btn forgot-pwd-btn next-btn">NEXT
                    </button>
                </div>
                <div class="error-msg forgot-pwd-error register-err-msg" *ngIf="errorMsg">
                    <span class="error-img"></span> <span>{{errorMsg}}</span>
                </div>
                <div class="login-card-info register-card-info" [ngClass]="{'login-card-info-height-error register-card-info-height-error' : errorMsg}">
                    If you forgot your Account Number or User ID, please contact your account administrator at your law firm.
                    If you are the law firm administrator, please contact the <a href="https://dyedurham.ca/support/unity/" target="_blank">Unity&reg; support team</a> at 1-866-367-7648
                </div>
            </div>
        </div>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>
<div *ngIf="submitted" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>
