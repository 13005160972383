<div class="col-lg-12 form-horizontal fields-group">
    <div class="main-level">
        <span class="title">Listing Broker</span>
    </div>
    <div class="clear"></div>
    <!--Use change to instead of ngModelChange.
    dp-currency and dp-percentage will trigger ngModelChange when using ctrl + s to save -->
    <ng-container>
        <div  *ngIf="realEstateAgentWrapper">
            <div  [ngClass]="{'shutter-bg': realEstateAgentWrapper.expanded, 'shutter-bg-closed':
                  (matter.locked || !realEstateAgentWrapper.editMode) && !realEstateAgentWrapper.expanded}">
                <div class="form-group">
                    <div class="shutter-arrow" *ngIf="!realEstateAgentWrapper.isLoadingParticipantState" (click)="toggleBrokerShutter(realEstateAgentWrapper)">
                        <div *ngIf="realEstateAgentWrapper.expanded"><img src="/assets/img/minus.svg" alt="" /></div>
                        <div *ngIf="(matter.locked || !realEstateAgentWrapper.editMode) && !realEstateAgentWrapper.expanded" ><img
                            src="/assets/img/plus.svg" /></div>
                    </div>
                    <span
                        class="col-lg-2 control-label"
                        (click)="toggleBrokerShutter(realEstateAgentWrapper)"
                        [class.focus]="fieldHoverIndex===11">Agent</span>
                    <div class="col-lg-10">
                        <ng-container *ngIf="realEstateAgentWrapper">
                            <ng-container *ngIf="realEstateAgentWrapper.editMode">
                                <p-autoComplete id="agent-autocomplete"
                                                name="agent-autocomplete"
                                                placeholder='Search by "Surname, First Name", Email Address or Add new'
                                                [(ngModel)]="realEstateAgentWrapper.dataModel"
                                                [class.autocomplete-searching]="realEstateAgentsLoading"
                                                (ngModelChange)="onListingRealEstateAgentSelected()"
                                                [suggestions]="realEstateAgents"
                                                (completeMethod)="searchRealEstateAgent($event)"
                                                minLength="0"
                                                maxlength="75"
                                                delay="1000"
                                                scrollHeight="350px"
                                                (focusout)="cleanupAgentField(realEstateAgentWrapper)"
                                                autocomplete="off" class="autocomplete-search-icon">
                                    <ng-template let-realEstateAgent pTemplate="item">
                                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                            [disableItem]="realEstateAgent"
                                            [ngClass]="{'font-style-italic': realEstateAgent?.fullName == constantNoResultValue,'bold-font': isAddNew(realEstateAgent)}">
                                                    <span class="col-lg-4 text-overflow"
                                                          *ngIf="realEstateAgent.displayName != null">
                                                            {{realEstateAgent.displayName }}</span>
                                            <span class="col-lg-4  text-overflow"
                                                  *ngIf="realEstateAgent.fullName != null && realEstateAgent.displayName!=realEstateAgent.fullName">
                                                            {{realEstateAgent.fullName}}</span>
                                            <span class="col-lg-3  text-overflow" >
                                                            {{realEstateAgent.parentOrganizationName}}</span>
                                            <span class="col-lg-4  text-overflow" >
                                                            {{realEstateAgent.email}}</span>
                                        </li>
                                    </ng-template>


                                </p-autoComplete>
                            </ng-container>
                            <ng-container *ngIf="!realEstateAgentWrapper.editMode">
                                <input type="text"
                                       class="form-control"
                                       [dp-read-only]="true"
                                       [value]="realEstateAgent.fullName"
                                       (click)="toggleBrokerShutter(realEstateAgentWrapper)"
                                       (keydown.f9)="toggleBrokerShutter(realEstateAgentWrapper)"
                                       autocomplete="off"
                                />
                                <div class="actionbtns">
                                    <div class="width-30 global-icon padding-right-40 padding-top-5">
                                                                    <span *ngIf="realEstateAgentWrapper.isGlobalIconVisible()">
                                                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                                                    </span>
                                        <span *ngIf="realEstateAgentWrapper.isAddressBookIconVisible()">
                                                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                                                    </span>
                                    </div>
                                    <div class="width-60 padding-right-40 padding-top-5">
                                                                    <span *ngIf="realEstateAgentWrapper.isStale">
                                                                        <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                                                    </span>
                                    </div>

                                    <div class="width-30 actionbtns">
                                        <dp-burger-menu *ngIf="!realEstateAgentWrapper.editMode && realEstateAgentWrapper.matterParticipant"
                                                        [openSubMenuOnLeft]="true"
                                                        [allowPopUp]="true"
                                                        [items]="realEstateAgentWrapper.getBurgerMenuItemsForMatterParticipant()"
                                                        [disabledItems]="realEstateAgentWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                        (itemClicked)="clickAgentBurgerMenuItem($event, realEstateAgentWrapper, realEstateBrokerWrapper)">
                                        </dp-burger-menu>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div *ngIf="realEstateAgentWrapper && realEstateAgentWrapper.matterParticipant && realEstateAgentWrapper.expanded">
                    <div class="form-group-warning-message col-lg-10 offset-lg-2"
                         *ngIf="realEstateAgentWrapper.isLockedElsewhere">
                        <div class="form-group">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The real estate agent contact is currently locked by {{realEstateAgentWrapper.lockedByUserFullName}}.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-warning-message col-lg-10 offset-lg-2"
                         *ngIf="realEstateAgentWrapper.isStale && !realEstateAgentWrapper.isLockedElsewhere">
                        <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                        up-->
                        <div class="form-group">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{realEstateAgentWrapper.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openSourceContactTab(realEstateAgentWrapper)" class="line-height-adjust">
                                        {{realEstateAgentWrapper.outOfSyncUnderlineMsg}}</a> {{realEstateAgentWrapper.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div [dp-read-only-block]="isRealEstateAgentReadOnly" [elementRefComp]="elementRef">
                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Surname</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.lastName"
                                       (change)="onAgentChanged(); handleContactChange()"
                                       trimmed />
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">First Name</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.firstName"
                                       (change)="onAgentChanged(); handleContactChange()"
                                       trimmed />
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Middle Name(s)</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.middleName"
                                       (change)="onAgentChanged(); handleContactChange()"
                                       trimmed />
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="control-label col-lg-2">Work Phone</label>
                            <div class="col-lg-4">
                                <input type="text"
                                       trimmed
                                       name="workPhone"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.workPhone"
                                       (change)="onAgentChanged()"
                                       maxlength="50"  />
                            </div>

                            <label class="control-label col-lg-2">Cell Phone</label>
                            <div class="col-lg-4">
                                <input type="text"
                                       trimmed
                                       name="workPhone"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.cellPhone"
                                       (change)="onAgentChanged()"
                                       maxlength="50"  />
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-lg-2" for="email">Email Address</label>
                            <div class="col-lg-4">
                                <input type="text"
                                       trimmed
                                       dp-email-field
                                       id="email"
                                       name="email"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.email"
                                       (change)="onAgentChanged(); handleContactChange()"
                                       maxlength="100"
                                />

                            </div>

                            <label class="control-label col-lg-2">Fax</label>
                            <div class="col-lg-4">
                                <input type="text"
                                       trimmed
                                       name="fax"
                                       class="form-control"
                                       [(ngModel)]="realEstateAgent.faxPhone"
                                       (change)="onAgentChanged()"
                                       maxlength="50" />
                            </div>
                        </div>

                    </div>

                </div>

                <!-- Listing Broker Section-->
                <ng-container>
                    <div class="broker-adjustment secondary-accordion" *ngIf="realEstateBrokerWrapper && isListBrokerVisible">
                        <div  [ngClass]="{ 'shutter-bg': realEstateBrokerWrapper.expanded,
                                          'shutter-bg-closed': (matter.locked || !realEstateBrokerWrapper.editMode) && !realEstateBrokerWrapper.expanded}">
                            <div class="form-group">
                                <div class="shutter-arrow"
                                     [ngClass]="{ 'margin-left-20' : isListBrokerVisibleInsideAgent}"
                                     *ngIf="!realEstateBrokerWrapper.isLoadingParticipantState"
                                     (click)="toggleBrokerShutter(realEstateBrokerWrapper)">
                                    <div *ngIf="realEstateBrokerWrapper.expanded"><img src="/assets/img/minus.svg" alt="" /></div>
                                    <div *ngIf="(matter.locked || !realEstateBrokerWrapper.editMode) && !realEstateBrokerWrapper.expanded" ><img
                                        src="/assets/img/plus.svg" /></div>
                                </div>

                                <span
                                    class="col-lg-2 control-label"
                                    (click)="toggleBrokerShutter(realEstateBrokerWrapper)"
                                    [class.focus]="fieldHoverIndex===11">Listing Broker</span>

                                <div class="col-lg-10">
                                    <input type="text"
                                           class="form-control"
                                           readonly="readonly"
                                           *ngIf="!realEstateBrokerWrapper.editMode"
                                           [value]="realEstateBrokerWrapper.matterParticipant?.contact?.organizationName"
                                           (click)="toggleBrokerShutter(realEstateBrokerWrapper)"
                                           (keydown.f9)="toggleBrokerShutter(realEstateBrokerWrapper)"
                                           autocomplete="off"/>

                                    <p-autoComplete *ngIf="realEstateBrokerWrapper.editMode && !isRealEstateAgentReadOnly"
                                                    id="listing-broker-autocomplete"
                                                    name="listing-broker-autocomplete"
                                                    placeholder="Search by Name, Address or Add new"
                                                    [(ngModel)]="realEstateBrokerWrapper.dataModel"
                                                    [class.autocomplete-searching]="realEstateBrokersLoading"
                                                    (ngModelChange)="onListingBrokerCompanySelected()"
                                                    field="organizationName"
                                                    [suggestions]="realEstateBrokers"
                                                    (completeMethod)="searchRealEstateBroker($event)"
                                                    minLength="0"
                                                    maxlength="75"
                                                    delay="1000"
                                                    scrollHeight="350px"
                                                    (focusout)="cleanupBrokerField(realEstateBrokerWrapper)"
                                                    autocomplete="off" class="autocomplete-search-icon">
                                        <ng-template let-realEstateBroker pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                                [disableItem]="realEstateBroker"
                                                [ngClass]="{'font-style-italic': realEstateBroker?.displayName  == constantNoResultValue || realEstateBroker?.organizationName == constantNoResultValue, 'bold-font' : isAddNew(realEstateBroker)}">
                                         <span  class="col-lg-12 text-overflow"
                                                *ngIf="realEstateBrokerAutoCompleteEmptyFlag == true">{{ realEstateBroker.displayName }}</span>
                                                <span  class="col-lg-6 text-overflow"
                                                       *ngIf="realEstateBrokerAutoCompleteEmptyFlag == false">
                                            {{ realEstateBroker.displayName ? realEstateBroker.displayName : realEstateBroker.organizationName }}
                                        </span>
                                                <span  class="col-lg-6 text-overflow"
                                                       *ngIf="realEstateBrokerAutoCompleteEmptyFlag == false">
                                            {{getContactDisplayMailingAddress(realEstateBroker)}}
                                        </span>

                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                    <div class="actionbtns">
                                        <div class="width-30 global-icon padding-right-40 padding-top-5">
                                                        <span *ngIf="realEstateBrokerWrapper.matterParticipant && realEstateBrokerWrapper.isGlobalIconVisible()">
                                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                                        </span>
                                            <span *ngIf="realEstateBrokerWrapper.isAddressBookIconVisible()">
                                                            <i class="fa fa-address-book" aria-hidden="true"></i>
                                                        </span>
                                        </div>
                                        <div class="width-60 padding-right-40 padding-top-5">
                                                        <span *ngIf="realEstateBrokerWrapper.isStale">
                                                            <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                                        </span>
                                        </div>

                                        <div class="width-30 actionbtns">
                                            <dp-burger-menu
                                                *ngIf="!realEstateBrokerWrapper.editMode && realEstateBrokerWrapper.matterParticipant && !isRealEstateAgentReadOnly"
                                                [openSubMenuOnLeft]="true"
                                                [allowPopUp]="true"
                                                [items]="realEstateBrokerWrapper.getBurgerMenuItemsForMatterParticipant()"
                                                [disabledItems]="realEstateBrokerWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                (itemClicked)="clickBrokerBurgerMenuItem($event, realEstateBrokerWrapper, realEstateAgentWrapper, realEstateAgent,true)">
                                            </dp-burger-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div  *ngIf="(realEstateBrokerWrapper.matterParticipant && realEstateBrokerWrapper.expanded)">
                                <div class="col-lg-12 margin-top-10">
                                    <div class="right margin-bottom-20"
                                         *ngIf="realEstateBrokerWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount
                                     && !realEstateBrokerWrapper.isStale && !realEstateBrokerWrapper.isLockedElsewhere">
                                    <span>
                                        <a href="javascript:void(0);"
                                           (click)="editBrokerAsPrivateCopy(realEstateBrokerWrapper)">
                                            Edit as Private Copy
                                        </a>
                                    </span>
                                    </div>
                                    <div class="form-group-warning-message col-lg-10 offset-lg-2"
                                         *ngIf="realEstateBrokerWrapper.isLockedElsewhere">
                                        <div class="col-lg-1">
                                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-lg-11">
                                            <p>
                                                The listing broker contact is currently locked by {{realEstateBrokerWrapper.lockedByUserFullName}}.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="form-group-warning-message col-lg-10 offset-lg-2"
                                         *ngIf="realEstateBrokerWrapper.isStale && !realEstateBrokerWrapper.isLockedElsewhere">
                                        <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                                        up-->
                                        <div class="col-lg-1">
                                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-lg-11">
                                            <p>
                                                {{realEstateBrokerWrapper.outOfSyncPreMsg}}
                                                <a href="javascript:void(0);"
                                                   (click)="openSourceContactTab(realEstateBrokerWrapper)" class="line-height-adjust">
                                                    {{realEstateBrokerWrapper.outOfSyncUnderlineMsg}}</a> {{realEstateBrokerWrapper.outOfSyncPostFixMsg}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="clear"></div>

                                    <div [dp-read-only-block]="realEstateBrokerWrapper.isAgentBrokerParticipantDataReadOnly || isRealEstateAgentReadOnly"
                                         [elementRefComp]="elementRef">
                                        <div class="form-group" *ngIf="!realEstateBrokerWrapper.isAgentBrokerParticipantDataReadOnly && !isRealEstateAgentReadOnly">
                                            <label class="control-label col-lg-2">Listing Broker</label>
                                            <div class="col-lg-10">
                                                <input type="text"
                                                       trimmed
                                                       name="brokerName"
                                                       class="form-control"
                                                       [(ngModel)]="realEstateBroker.organizationName"
                                                       (change)="onBrokerChanged()"
                                                       maxlength="50"  />
                                            </div>
                                        </div>


                                        <dp-address-form *ngIf="realEstateBroker.mailingAddress"
                                                         [closeShutter]="realEstateBrokerWrapper.isAgentBrokerParticipantDataReadOnly || isRealEstateAgentReadOnly"
                                                         [address]="realEstateBroker.mailingAddress"
                                                         [fieldPrefix]="'contact.address'"
                                                         [addressSummaryLabel]="'Mailing Address'"
                                                         [secondaryShutter]="true"
                                                         (onChange)="onBrokerChanged();"
                                                         [populateDefaultProvince]="false"
                                                         [offsetShutter]="true">
                                        </dp-address-form>

                                        <div class="form-group">
                                            <label class="control-label col-lg-2">Work Phone</label>
                                            <div class="col-lg-4">
                                                <input type="text"
                                                       trimmed
                                                       name="workPhone"
                                                       class="form-control"
                                                       [(ngModel)]="realEstateBroker.workPhone"
                                                       (change)="onBrokerChanged()"
                                                       maxlength="50"  />
                                            </div>

                                            <label class="control-label col-lg-2">Cell Phone</label>
                                            <div class="col-lg-4">
                                                <input type="text"
                                                       trimmed
                                                       name="mobilePhone"
                                                       class="form-control"
                                                       [(ngModel)]="realEstateBroker.cellPhone"
                                                       (change)="onBrokerChanged()"
                                                       maxlength="50" />
                                            </div>
                                        </div>


                                        <div class="form-group">
                                            <label class="control-label col-lg-2" for="email">Email Address</label>
                                            <div class="col-lg-4">
                                                <input type="text"
                                                       trimmed
                                                       dp-email-field
                                                       id="broker-email"
                                                       name="broker-email"
                                                       class="form-control"
                                                       [(ngModel)]="realEstateBroker.email"
                                                       (change)="onBrokerChanged()"
                                                       maxlength="100"
                                                />

                                            </div>

                                            <label class="control-label col-lg-2">Fax</label>
                                            <div class="col-lg-4">
                                                <input type="text"
                                                       trimmed
                                                       name="fax"
                                                       class="form-control"
                                                       [(ngModel)]="realEstateBroker.faxPhone"
                                                       (change)="onBrokerChanged()"
                                                       maxlength="50" />
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </ng-container>
                 <div class="clear"></div>
            </div>
        </div>
    </ng-container>

    <div class="form-group" *ngIf="isSellerBrokerDearAttentionVisible()">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Broker Dear</label>
        </div>
        <div class="col-lg-4">
            <input id="listingBrokerDear"
                   name="listingBrokerDear"
                   type="text"
                   maxlength="30"
                   [fieldKey]="'matter.broker.dear'"
                   statusBarHelp
                   trimmed
                   class="form-control"
                   (keydown.f9)="setF9SellerDefaultDear()"
                   [(ngModel)]="matter.sellerBrokerMatterLevelInfo.dear"
                   (change)="enableSave()"
            />
        </div>

        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Broker Attention</label>
        </div>
        <div class="col-lg-4">
            <input id="listingBrokerAttention"
                   name="listingBrokerAttention"
                   type="text"
                   maxlength="30"
                   trimmed
                   class="form-control"
                   [(ngModel)]="matter.sellerBrokerMatterLevelInfo.attention"
                   (change)="enableSave()"
            />
        </div>
    </div>

    <ng-container *dpShowByProvince="'matter.sale.brokerCommission.AutoInsUpdExcessDepositTrustLedger'">
        <div class="form-group" *ngIf="!matter.isProjectSale">
            <div class="col-lg-10 offset-lg-2">
                <dp-checkbox fieldId="insertExcessDepositIntoTL">
                    <input id="insertExcessDepositIntoTL"
                           name="insertExcessDepositIntoTL"
                           type="checkbox"
                           [(ngModel)]="matter.insertExcessDepositIntoTrustLedger"
                           (change)="onInsertExcessDepositIntoTLChanged()"
                    />
                </dp-checkbox>
                <label class="control-label padding-left-10 display-inline" for="insertExcessDepositIntoTL" >Automatically insert/update Excess Deposit into the Trust Ledger</label>
            </div>

        </div>
    </ng-container>

    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Commission paid to</label>
        </div>
        <div class="col-lg-4">
            <select
                class="form-control"
                [(ngModel)]="matter.commissionPaidTo"
                (ngModelChange)="onChangeCommissionPaidTo()">
                <option *ngFor="let item of commissionPaidToOptions"
                        [value]="item.value">
                    {{item.label}}
                </option>
            </select>
        </div>

        <ng-container *ngIf="isPurchaserBrokerCommissionVisible">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    Separate entries<br/><span dp-province-field-label="matter.broker.commission.inTrustLedger">in trust ledger</span>
                </label>
            </div>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    [(ngModel)]="matter.separateEntriesForCommissionPaidToTrustLedger"
                    (ngModelChange)="onChangeSeparateEntries()">
                    <option *ngFor="let item of separateEntriesForCommissionPaidToTrustLedgerOptions"
                            [value]="item.value">
                        {{item.label}}
                    </option>
                </select>
            </div>
        </ng-container>

    </div>

    <ng-container *dpShowByProvince="'matter.sale.brokerCommission.commissionDisbursedTo'">
        <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Commission disbursed to</label>
                </div>
                <div class="col-lg-4">
                    <select
                        class="form-control"
                        [(ngModel)]="matter.commissionDisbursedTo"
                        (ngModelChange)="onChangeCommissionDischargeTo()">
                        <option *ngFor="let item of commissionDisbursedToOptions"
                                [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
        </div>
    </ng-container>


    <div class="main-level" *ngIf="isPurchaserBrokerCommissionVisible">
        <span class="title" dp-province-field-label="matter.broker.commission.purchaserBroker">Purchaser Broker</span>
    </div>
    <div class="clear"></div>

    <div *ngIf="isPurchaserBrokerCommissionVisible">
        <ng-container>
            <div *ngIf="purchaserRealEstateAgentWrapper">
                <div [ngClass]="{'shutter-bg': purchaserRealEstateAgentWrapper.expanded, 'shutter-bg-closed':
                  (matter.locked || !purchaserRealEstateAgentWrapper.editMode) && !purchaserRealEstateAgentWrapper.expanded}">
                    <div class="form-group">
                        <div class="shutter-arrow" *ngIf="!purchaserRealEstateAgentWrapper.isLoadingParticipantState" (click)="toggleBrokerShutter(purchaserRealEstateAgentWrapper)">
                            <div *ngIf="purchaserRealEstateAgentWrapper.expanded"><img src="/assets/img/minus.svg" alt="" /></div>
                            <div *ngIf="(matter.locked || !purchaserRealEstateAgentWrapper.editMode) && !purchaserRealEstateAgentWrapper.expanded" ><img
                                src="/assets/img/plus.svg" /></div>
                        </div>
                        <span
                            class="col-lg-2 control-label"
                            (click)="toggleBrokerShutter(purchaserRealEstateAgentWrapper)"
                            [class.focus]="fieldHoverIndex===11">Agent</span>

                        <div class="col-lg-10">
                            <ng-container *ngIf="purchaserRealEstateAgentWrapper">
                                <ng-container *ngIf="purchaserRealEstateAgentWrapper.editMode">
                                    <p-autoComplete id="agent-autocomplete"
                                                    name="agent-autocomplete"
                                                    placeholder='Search by "Surname, First Name", Email Address or Add new'
                                                    [(ngModel)]="purchaserRealEstateAgentWrapper.dataModel"
                                                    [class.autocomplete-searching]="realEstateAgentsLoading"
                                                    (ngModelChange)="onPurchaserRealEstateAgentSelected()"
                                                    [suggestions]="purchaserRealEstateAgents"
                                                    (completeMethod)="purchaserSearchRealEstateAgent($event)"
                                                    minLength="0"
                                                    maxlength="75"
                                                    delay="1000"
                                                    scrollHeight="350px"
                                                    (focusout)="cleanupAgentField(purchaserRealEstateAgentWrapper)"
                                                    autocomplete="off" class="autocomplete-search-icon">
                                        <ng-template let-purchaserRealEstateAgent pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                                [disableItem]="purchaserRealEstateAgent"
                                                [ngClass]="{'font-style-italic': purchaserRealEstateAgent?.fullName == constantNoResultValue, 'bold-font' : isAddNew(purchaserRealEstateAgent)}">
                                                    <span class="col-lg-4 text-overflow"
                                                          *ngIf="purchaserRealEstateAgent.displayName != null">
                                                            {{purchaserRealEstateAgent.displayName }}</span>
                                                <span class="col-lg-4  text-overflow"
                                                      *ngIf="purchaserRealEstateAgent.fullName != null && purchaserRealEstateAgent.displayName!=purchaserRealEstateAgent.fullName">
                                                            {{purchaserRealEstateAgent.fullName}}</span>
                                                <span class="col-lg-3  text-overflow" >
                                                            {{purchaserRealEstateAgent.parentOrganizationName}}</span>
                                                <span class="col-lg-4  text-overflow" >
                                                            {{purchaserRealEstateAgent.email}}</span>
                                            </li>
                                        </ng-template>


                                    </p-autoComplete>
                                </ng-container>
                                <ng-container *ngIf="!purchaserRealEstateAgentWrapper.editMode">
                                    <input type="text"
                                           class="form-control header-name text-overflow"
                                           [dp-read-only]="true"
                                           [value]="purchaserRealEstateAgent.fullName"
                                           (click)="toggleBrokerShutter(purchaserRealEstateAgent)"
                                           (keydown.f9)="toggleBrokerShutter(purchaserRealEstateAgent)"
                                           autocomplete="off"
                                    />
                                    <div class="actionbtns">
                                        <div class="width-30 global-icon padding-right-40 padding-top-5">
                                                                    <span *ngIf="purchaserRealEstateAgentWrapper.isGlobalIconVisible()">
                                                                        <i class="fa fa-globe" aria-hidden="true"></i>
                                                                    </span>
                                            <span *ngIf="purchaserRealEstateAgentWrapper.isAddressBookIconVisible()">
                                                                        <i class="fa fa-address-book" aria-hidden="true"></i>
                                                                    </span>
                                        </div>
                                        <div class="width-60 padding-right-40 padding-top-5">
                                                                    <span *ngIf="purchaserRealEstateAgentWrapper.isStale">
                                                                        <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                                                    </span>
                                        </div>

                                        <div class="width-30 actionbtns">
                                            <dp-burger-menu *ngIf="!purchaserRealEstateAgentWrapper.editMode && purchaserRealEstateAgentWrapper.matterParticipant"
                                                            [openSubMenuOnLeft]="true"
                                                            [allowPopUp]="true"
                                                            [items]="purchaserRealEstateAgentWrapper.getBurgerMenuItemsForMatterParticipant()"
                                                            [disabledItems]="purchaserRealEstateAgentWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                            (itemClicked)="clickAgentBurgerMenuItem($event, purchaserRealEstateAgentWrapper, purchaserRealEstateBrokerWrapper)">
                                            </dp-burger-menu>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div
                         *ngIf="purchaserRealEstateAgentWrapper && purchaserRealEstateAgentWrapper.matterParticipant && purchaserRealEstateAgentWrapper.expanded">
                        <div class="form-group-warning-message col-lg-10 offset-lg-2" *ngIf="purchaserRealEstateAgentWrapper.isLockedElsewhere">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The real estate agent contact is currently locked by {{purchaserRealEstateAgentWrapper.lockedByUserFullName}}.
                                </p>
                            </div>
                        </div>
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="purchaserRealEstateAgentWrapper.isStale && !purchaserRealEstateAgentWrapper.isLockedElsewhere">
                            <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                            up-->
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{purchaserRealEstateAgentWrapper.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openSourceContactTab(purchaserRealEstateAgentWrapper)" class="line-height-adjust">
                                        {{purchaserRealEstateAgentWrapper.outOfSyncUnderlineMsg}}</a> {{purchaserRealEstateAgentWrapper.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>

                        <div [dp-read-only-block]="isPurchaserRealEstateAgentReadOnly" [elementRefComp]="elementRef">
                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Surname</label>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.lastName"
                                           (change)="onPurchaserAgentChanged()"
                                           trimmed />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">First Name</label>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.firstName"
                                           (change)="onPurchaserAgentChanged()"
                                           trimmed />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Middle Name(s)</label>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.middleName"
                                           (change)="onPurchaserAgentChanged()"
                                           trimmed />
                                </div>
                            </div>


                            <div class="form-group">
                                <label class="control-label col-lg-2">Work Phone</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           trimmed
                                           name="workPhone"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.workPhone"
                                           (change)="onPurchaserAgentChanged()"
                                           maxlength="50"  />
                                </div>

                                <label class="control-label col-lg-2">Cell Phone</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           trimmed
                                           name="workPhone"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.cellPhone"
                                           (change)="onPurchaserAgentChanged()"
                                           maxlength="50"  />
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label col-lg-2" for="email">Email Address</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           trimmed
                                           id="email"
                                           name="email"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.email"
                                           (change)="onPurchaserAgentChanged()"
                                           maxlength="100"
                                    />

                                </div>

                                <label class="control-label col-lg-2">Fax</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           trimmed
                                           name="fax"
                                           class="form-control"
                                           [(ngModel)]="purchaserRealEstateAgent.faxPhone"
                                           (change)="onPurchaserAgentChanged()"
                                           maxlength="50" />
                                </div>
                            </div>

                        </div>


                    </div>

                    <!-- Purchaser Broker Section-->
                    <ng-container>
                        <div class="broker-adjustment" *ngIf="purchaserRealEstateBrokerWrapper && isPurchaserBrokerVisible">
                            <div  [ngClass]="{'margin-left-20' : isPurchaserBrokerVisibleInsideAgent,
                            'shutter-bg': purchaserRealEstateBrokerWrapper.expanded,
                            'shutter-bg-closed': (matter.locked || !purchaserRealEstateBrokerWrapper.editMode) && !purchaserRealEstateBrokerWrapper.expanded}">
                                <div class="form-group">
                                    <div class="shutter-arrow"
                                         [ngClass]="{ 'margin-left-20' : isPurchaserBrokerVisibleInsideAgent}"
                                         *ngIf="!purchaserRealEstateBrokerWrapper.isLoadingParticipantState"
                                         (click)="toggleBrokerShutter(purchaserRealEstateBrokerWrapper)">
                                        <div *ngIf="purchaserRealEstateBrokerWrapper.expanded"><img src="/assets/img/minus.svg" alt="" /></div>
                                        <div *ngIf="(matter.locked || !purchaserRealEstateBrokerWrapper.editMode) && !purchaserRealEstateBrokerWrapper.expanded" ><img
                                            src="/assets/img/plus.svg" /></div>
                                    </div>

                                    <span
                                        class="col-lg-2 control-label"
                                        (click)="toggleBrokerShutter(purchaserRealEstateBrokerWrapper)"
                                        [class.focus]="fieldHoverIndex===11"
                                        dp-province-field-label="matter.broker.commission.purchaserBroker">Purchaser Broker</span>

                                    <div class="col-lg-10">
                                        <input type="text"
                                               class="form-control"
                                               readonly="readonly"
                                               *ngIf="!purchaserRealEstateBrokerWrapper.editMode"
                                               [value]="purchaserRealEstateBrokerWrapper.matterParticipant?.contact?.organizationName"
                                               (click)="toggleBrokerShutter(purchaserRealEstateBrokerWrapper)"
                                               (keydown.f9)="toggleBrokerShutter(purchaserRealEstateBrokerWrapper)"
                                               autocomplete="off"/>

                                        <p-autoComplete *ngIf="purchaserRealEstateBrokerWrapper.editMode && !isPurchaserRealEstateAgentReadOnly"
                                                        id="listing-broker-autocomplete"
                                                        name="listing-broker-autocomplete"
                                                        placeholder="Search by Name, Address or Add new"
                                                        [(ngModel)]="purchaserRealEstateBrokerWrapper.dataModel"
                                                        [class.autocomplete-searching]="realEstateBrokersLoading"
                                                        (ngModelChange)="onPurchaserBrokerCompanySelected()"
                                                        [suggestions]="purchaserRealEstateBrokers"
                                                        (completeMethod)="purchaserSearchRealEstateBroker($event)"
                                                        field="organizationName"
                                                        minLength="0"
                                                        maxlength="75"
                                                        delay="1000"
                                                        scrollHeight="350px"
                                                        (focusout)="cleanupBrokerField(purchaserRealEstateBrokerWrapper)"
                                                        autocomplete="off" class="autocomplete-search-icon">
                                            <ng-template let-purchaserRealEstateBroker pTemplate="item">
                                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                                    [disableItem]="purchaserRealEstateBroker"
                                                    [ngClass]="{'font-style-italic': purchaserRealEstateBroker?.displayName  == constantNoResultValue || purchaserRealEstateBroker?.organizationName == constantNoResultValue, 'bold-font' : isAddNew(purchaserRealEstateBroker)}">
                                         <span  class="col-lg-12 text-overflow"
                                                *ngIf="purchaserRealEstateBrokerAutoCompleteEmptyFlag == true">{{ purchaserRealEstateBroker.displayName }}</span>
                                                    <span  class="col-lg-6 text-overflow"
                                                           *ngIf="purchaserRealEstateBrokerAutoCompleteEmptyFlag == false">
                                            {{ purchaserRealEstateBroker.displayName ? purchaserRealEstateBroker.displayName : purchaserRealEstateBroker.organizationName }}
                                        </span>
                                                    <span  class="col-lg-6 text-overflow"
                                                           *ngIf="purchaserRealEstateBrokerAutoCompleteEmptyFlag == false">
                                            {{getContactDisplayMailingAddress(purchaserRealEstateBroker)}}
                                        </span>

                                                </li>
                                            </ng-template>
                                        </p-autoComplete>
                                        <div class="actionbtns">
                                            <div class="width-30 global-icon padding-right-40 padding-top-5">
                                                        <span *ngIf="purchaserRealEstateBrokerWrapper.matterParticipant && purchaserRealEstateBrokerWrapper.isGlobalIconVisible()">
                                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                                        </span>
                                                <span *ngIf="purchaserRealEstateBrokerWrapper.isAddressBookIconVisible()">
                                                            <i class="fa fa-address-book" aria-hidden="true"></i>
                                                        </span>
                                            </div>
                                            <div class="width-60 padding-right-40 padding-top-5">
                                                        <span *ngIf="purchaserRealEstateBrokerWrapper.isStale">
                                                            <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                                        </span>
                                            </div>

                                            <div class="width-30 actionbtns">
                                                <dp-burger-menu
                                                    *ngIf="!purchaserRealEstateBrokerWrapper.editMode && purchaserRealEstateBrokerWrapper.matterParticipant && !isPurchaserRealEstateAgentReadOnly"
                                                    [openSubMenuOnLeft]="true"
                                                    [allowPopUp]="true"
                                                    [items]="purchaserRealEstateBrokerWrapper.getBurgerMenuItemsForMatterParticipant()"
                                                    [disabledItems]="purchaserRealEstateBrokerWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                    (itemClicked)="clickBrokerBurgerMenuItem($event, purchaserRealEstateBrokerWrapper, purchaserRealEstateAgentWrapper)">
                                                </dp-burger-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="(purchaserRealEstateBrokerWrapper.matterParticipant && purchaserRealEstateBrokerWrapper.expanded)">
                                    <div class="col-lg-12 margin-top-10">
                                        <div class="right margin-bottom-20"
                                             *ngIf="purchaserRealEstateBrokerWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount
                                     && !purchaserRealEstateBrokerWrapper.isStale && !purchaserRealEstateBrokerWrapper.isLockedElsewhere">
                                    <span>
                                        <a href="javascript:void(0);"
                                           (click)="editBrokerAsPrivateCopy(purchaserRealEstateBrokerWrapper)">
                                            Edit as Private Copy
                                        </a>
                                    </span>
                                        </div>
                                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                                             *ngIf="purchaserRealEstateBrokerWrapper.isLockedElsewhere">
                                            <div class="col-lg-1">
                                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-lg-11">
                                                <p>
                                                    The purchaser broker contact is currently locked by {{purchaserRealEstateBrokerWrapper.lockedByUserFullName}}.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                                             *ngIf="purchaserRealEstateBrokerWrapper.isStale && !purchaserRealEstateBrokerWrapper.isLockedElsewhere">
                                            <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                                            up-->
                                            <div class="col-lg-1">
                                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-lg-11">
                                                <p>
                                                    {{purchaserRealEstateBrokerWrapper.outOfSyncPreMsg}}
                                                    <a href="javascript:void(0);"
                                                       (click)="openSourceContactTab(purchaserRealEstateBrokerWrapper)" class="line-height-adjust">
                                                        {{purchaserRealEstateBrokerWrapper.outOfSyncUnderlineMsg}}</a> {{purchaserRealEstateBrokerWrapper.outOfSyncPostFixMsg}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="clear"></div>

                                        <div [dp-read-only-block]="purchaserRealEstateBrokerWrapper.isAgentBrokerParticipantDataReadOnly || isPurchaserRealEstateAgentReadOnly"
                                             [elementRefComp]="elementRef">

                                            <div class="form-group" *ngIf="!purchaserRealEstateBrokerWrapper.isAgentBrokerParticipantDataReadOnly && !isPurchaserRealEstateAgentReadOnly">
                                                <label class="control-label col-lg-2" dp-province-field-label="matter.broker.commission.purchaserBroker">Purchaser Broker</label>
                                                <div class="col-lg-10">
                                                    <input type="text"
                                                           trimmed
                                                           name="brokerName"
                                                           class="form-control"
                                                           [(ngModel)]="purchaserRealEstateBroker.organizationName"
                                                           (change)="onPurchaserBrokerChanged()"
                                                           maxlength="50"  />
                                                </div>
                                            </div>


                                            <dp-address-form *ngIf="purchaserRealEstateBroker.mailingAddress"
                                                             [closeShutter]="purchaserRealEstateBrokerWrapper.isAgentBrokerParticipantDataReadOnly || isPurchaserRealEstateAgentReadOnly"
                                                             [address]="purchaserRealEstateBroker.mailingAddress"
                                                             [fieldPrefix]="'contact.address'"
                                                             [addressSummaryLabel]="'Mailing Address'"
                                                             [secondaryShutter]="true"
                                                             (onChange)="onPurchaserBrokerChanged();"
                                                             [populateDefaultProvince]="false"
                                                             [offsetShutter]="true">
                                            </dp-address-form>

                                            <div class="form-group">
                                                <label class="control-label col-lg-2">Work Phone</label>
                                                <div class="col-lg-4">
                                                    <input type="text"
                                                           trimmed
                                                           name="workPhone"
                                                           class="form-control"
                                                           [(ngModel)]="purchaserRealEstateBroker.workPhone"
                                                           (change)="onPurchaserBrokerChanged()"
                                                           maxlength="50"  />
                                                </div>

                                                <label class="control-label col-lg-2">Cell Phone</label>
                                                <div class="col-lg-4">
                                                    <input type="text"
                                                           trimmed
                                                           name="mobilePhone"
                                                           class="form-control"
                                                           [(ngModel)]="purchaserRealEstateBroker.cellPhone"
                                                           (change)="onPurchaserBrokerChanged()"
                                                           maxlength="50" />
                                                </div>
                                            </div>


                                            <div class="form-group">
                                                <label class="control-label col-lg-2" for="email">Email Address</label>
                                                <div class="col-lg-4">
                                                    <input type="text"
                                                           trimmed
                                                           id="broker-email"
                                                           name="broker-email"
                                                           class="form-control"
                                                           [(ngModel)]="purchaserRealEstateBroker.email"
                                                           (change)="onPurchaserBrokerChanged()"
                                                           maxlength="100"
                                                    />

                                                </div>

                                                <label class="control-label col-lg-2">Fax</label>
                                                <div class="col-lg-4">
                                                    <input type="text"
                                                           trimmed
                                                           name="fax"
                                                           class="form-control"
                                                           [(ngModel)]="purchaserRealEstateBroker.faxPhone"
                                                           (change)="onPurchaserBrokerChanged()"
                                                           maxlength="50" />
                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="form-group" *ngIf="isPurchaserBrokerDearAttentionVisible()">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Broker Dear</label>
        </div>
        <div class="col-lg-4">
            <input id="purchaserBrokerDear"
                   name="purchaserBrokerDear"
                   type="text"
                   maxlength="30"
                   [fieldKey]="'matter.broker.dear'"
                   statusBarHelp
                   trimmed
                   class="form-control"
                   (keydown.f9)="setF9PurchaserDefaultDear()"
                   [(ngModel)]="matter.purchaserBrokerMatterLevelInfo.dear"
                   (change)="enableSave()"
            />
        </div>

        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Broker Attention</label>
        </div>
        <div class="col-lg-4">
            <input id="purchaserBrokerAttention"
                   name="purchaserBrokerAttention"
                   type="text"
                   maxlength="30"
                   trimmed
                   class="form-control"
                   [(ngModel)]="matter.purchaserBrokerMatterLevelInfo.attention"
                   (change)="enableSave()"
            />
        </div>
    </div>

    <!--Is Commission based on a fixed percentage of sale price-->
    <div class="form-group" *dpShowByProvince="'matter.sale.brokerCommission.commissionBasedOnFixedPercentageOfSalePrice'">
        <div class="col-lg-6"></div>
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">
                Is Commission based on a fixed percentage <br/> of sale price
            </label>
        </div>
        <div class="col-lg-4">
            <select
                class="form-control"
                [(ngModel)]="matter.commissionBasedOnFixedPercentageOfSalePrice"
                (ngModelChange)="onCommissionBasedOnFixedPercentageOfSalePriceChange()">
                <option *ngFor="let item of commissionBasedSalePriceOptions"
                        [value]="item.value">
                    {{item.label}}
                </option>
            </select>
        </div>
    </div>

    <ng-container *ngIf="!isBlankOfCommissionBasedSalePrice">
        <!--Total Commission Percentage (%)-->
        <div *dpShowByProvince="'matter.sale.brokerCommission.totalCommissionPercentage'">
            <div class="form-group" *ngIf="isYesOfCommissionBasedSalePrice">
                <div  class="col-lg-6"></div>
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        Total Commission Percentage (%)
                    </label>
                </div>
                <div class="col-lg-1" >
                    <input type="text"
                           id="totalCommissionPercentage"
                           name="totalCommissionPercentage"
                           [(ngModel)]="brokerCommission.totalCommissionPercentage"
                           (change)="enableSave()"
                           [padding-zero]="true"
                           class="form-control text-right"
                           [dp-read-only]="isTotalCommissionPercentageReadOnly"
                           dp-percentage
                    />
                </div>
                <div class="col-lg-2">
                    <label class="control-label">
                        %
                    </label>
                </div>
            </div>
        </div>
        <!--Commission (before HST)-->
        <div class="form-group">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    {{getCommissionBeforeHstYesLabel()}}
                </label>
            </div>
            <div class="col-lg-4">
                <input *ngIf="isYesOfCommissionBasedSalePrice"
                       type="text"
                       id="commissionBeforeHstYes"
                       name="commissionBeforeHstYes"
                       [ngModel]="calculateCommissionBeforeHst()"
                       class="form-control text-right"
                       [dp-read-only]="true"
                       dp-currency
                />
                <input *ngIf="isNoOfCommissionBasedSalePrice && !isBothOfCommissionPaidTo"
                       type="text"
                       id="commissionBeforeHstNoSingle"
                       name="commissionBeforeHstNoSingle"
                       [(ngModel)]="brokerCommission.commissionBeforeHst"
                       (change)="enableSave()"
                       class="form-control text-right"
                       dp-currency
                />
                <input *ngIf="isNoOfCommissionBasedSalePrice && isBothOfCommissionPaidTo"
                       type="text"
                       id="commissionBeforeHstEdit"
                       name="commissionBeforeHstEdit"
                       [ngModel]="brokerCommission.commissionBeforeHst"
                       [dp-read-only]="isCommissionBeforeHstReadOnly"
                       class="form-control text-right"
                       dp-currency
                />
            </div>
        </div>
        <!--Rate of HST on Commission-->
        <div class="form-group">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    {{getRateOfHstOnCommissionLabel()}}
                </label>
            </div>
            <div class="col-lg-1">
                <input type="text"
                       id="rateOfHstOnCommission"
                       name="rateOfHstOnCommission"
                       [(ngModel)]="brokerCommission.rateOfHstOnCommission"
                       (change)="enableSave()"
                       [padding-zero]="true"
                       class="form-control text-right"
                       dp-percentage
                />
            </div>
            <div class="col-lg-2">
                <label class="control-label">
                    %
                </label>
            </div>
        </div>
        <!--Rate of PST on Commission fir SK-->
        <div class="form-group" *ngIf="matter.isMatterProvinceSK && !matter.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome()">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Rate of PST on Commission</label>
            </div>
            <div class="col-lg-1">
                <input type="text"
                       id="rateOfPstOnCommission"
                       name="rateOfPstOnCommission"
                       [(ngModel)]="brokerCommission.rateOfPstOnCommission"
                       (change)="enableSave()"
                       [padding-zero]="true"
                       class="form-control text-right"
                       dp-percentage
                />
            </div>
            <div class="col-lg-2">
                <label class="control-label">
                    %
                </label>
            </div>
        </div>
        <!--Commission (incl.HST)-->
        <div class="form-group" *ngIf="isBothOfCommissionPaidTo">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    {{getCommissionInclHstLabel()}}
                </label>
            </div>
            <div class="col-lg-4">
                <input
                    type="text"
                    id="commissionInclHst"
                    name="commissionInclHst"
                    [ngModel]="calculateCommissionInclHst()"
                    class="form-control text-right"
                    [dp-read-only]="true"
                    dp-currency
                />
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isBlankOfCommissionBasedSalePrice && !matter.isProjectSale">
        <!--"Deposit held by" and  "Applied towards payment of commission"-->
        <div class="form-group">
            <!--<div  class="col-lg-6" *ngIf="!isBothOfCommissionPaidTo"></div>-->
            <!--<div class="col-lg-2 vertical-align-label" *ngIf="isBothOfCommissionPaidTo">-->
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    Deposit held by
                </label>
            </div>
            <div  class="col-lg-4" *ngIf="isDepositHeldByVisible()">
                <select
                    class="form-control"
                    [(ngModel)]="matter.depositHeldBy"
                    (ngModelChange)="onDepositHeldByChange()">
                    <option *ngFor="let item of depositHeldByOptions"
                            [value]="item.value">
                        {{item.label}}
                    </option>
                </select>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!isBlankOfCommissionBasedSalePrice">
        <div class="form-group">
            <div  class="col-lg-6" *ngIf="!isDepositHeldByVisible()"></div>
            <!--<div  class="col-lg-6" *ngIf="!isBothOfCommissionPaidTo"></div>-->
            <!--<div class="col-lg-2 vertical-align-label" *ngIf="isBothOfCommissionPaidTo">-->
            <div class="col-lg-2 vertical-align-label" *ngIf="isDepositHeldByVisible()">
                <label class="control-label">
                    Deposit held by
                </label>
            </div>
            <div  class="col-lg-4" *ngIf="isDepositHeldByVisible()">
                <select
                    class="form-control"
                    [(ngModel)]="matter.depositHeldBy"
                    (ngModelChange)="onDepositHeldByChange()">
                    <option *ngFor="let item of depositHeldByOptions"
                            [value]="item.value">
                        {{item.label}}
                    </option>
                </select>
            </div>

            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" *ngIf="isBothOfCommissionPaidTo">
                    Applied towards<br/>payment of commission?
                </label>
                <label class="control-label" *ngIf="!isBothOfCommissionPaidTo">
                    Deposit held by Real<br/>Estate Broker applied<br/>towards payment of commission?
                </label>
            </div>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    [dp-read-only]="isAppliedTowardsPaymentOfCommissionReadyOnly"
                    [(ngModel)]="brokerCommission.appliedTowardsPaymentOfCommission"
                    (ngModelChange)="enableSave()">
                    <option *ngFor="let item of appliedTowardsPaymentOfCommissionOptions"
                            [value]="item.value">
                        {{item.label}}
                    </option>
                </select>
            </div>
        </div>

        <!--Vendor Commission-->
        <!--"Vendor's broker percentage of commission" and "On"-->
        <ng-container *dpShowByProvince="'matter.sale.brokerCommission.vendorsBrokerPercentageOfCommission'">
            <div class="form-group" *ngIf="!isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo">
                <div  class="col-lg-6" *ngIf="!isBothOfCommissionPaidTo"></div>
                <div class="col-lg-2 vertical-align-label" *ngIf="isBothOfCommissionPaidTo">
                    <label class="control-label">
                        Vendor's broker percentage of commission
                    </label>
                </div>
                <div class="col-lg-1" *ngIf="isBothOfCommissionPaidTo">
                    <input type="text"
                           id="vendorBrokerPercentageOfCommission"
                           name="vendorBrokerPercentageOfCommission"
                           [(ngModel)]="brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission"
                           (change)="setTotalCommissionPercentage()"
                           [padding-zero]="true"
                           class="form-control text-right"
                           dp-percentage
                    />
                </div>
                <div class="col-lg-3" *ngIf="isBothOfCommissionPaidTo">
                    <label class="control-label">
                        {{getVendorBrokerPercentageOfCommissionOnLabel()}}
                    </label>
                </div>
                <div class="col-lg-2 vertical-align-label"
                     *ngIf="!isNofCommissionBasedSalePriceVendorOnlyOfCommissionPaidTo && !isMassUpdateBlankCommissionPaidTo()">
                    <label class="control-label">
                        {{getVendorOnLabel()}}
                    </label>
                </div>
                <div class="col-lg-4" *ngIf="!isNofCommissionBasedSalePriceVendorOnlyOfCommissionPaidTo && !isMassUpdateBlankCommissionPaidTo()">
                    <input
                        type="text"
                        id="vendorBrokerPercentageOfCommissionOn"
                        name="vendorBrokerPercentageOfCommissionOn"
                        [ngModel]="calculateVendorBrokerPercentageOfCommissionOn()"
                        class="form-control text-right"
                        [dp-read-only]="true"
                        dp-currency
                    />
                </div>
            </div>
        </ng-container>
        <!--Vendor's broker commission-->
        <ng-container *ngIf="isVendorBrokerCommissionVisible">
        <div class="form-group">
            <div *ngIf="!matter.isMatterProvinceBC" class="col-lg-6"></div>
            <ng-container *ngIf="matter.isMatterProvinceBC">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        Commission disbursed by
                    </label>
                </div>
                <div class="col-lg-4">
                    <select
                        class="form-control"
                        [(ngModel)]="matter.commissionDisbursedBy"
                        (ngModelChange)="onCommissionDisbursedByChange()">
                        <option *ngFor="let item of commissionDisbursedByOptions"
                                [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
            </ng-container>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" *ngIf="isBothOfCommissionPaidTo" dp-province-field-label="matter.broker.commission.vendorsBrokerCommission">
                    Vendors' Broker Commission
                </label>
                <label class="control-label" *ngIf="!isBothOfCommissionPaidTo && !isPurchaserOnlyOfCommissionPaidTo">
                    Commission
                </label>
            </div>
            <div class="col-lg-4">
                <input  *ngIf="isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo"
                        type="text"
                        id="vendorBrokerCommissionNoBoth"
                        name="vendorBrokerCommissionNoBoth"
                        [(ngModel)]="brokerCommission.vendorCommission.vendorBrokerCommission"
                        (change)="setCommissionBeforeHst()"
                        class="form-control text-right"
                        dp-currency
                />
                <input *ngIf="!isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo"
                    type="text"
                    id="vendorBrokerCommissionNoEdit"
                    name="vendorBrokerCommissionNoEdit"
                    [ngModel]="calculateVendorBrokerCommission()"
                    class="form-control text-right"
                    [dp-read-only]="true"
                    dp-currency
                />
            </div>
        </div>
        <!--HST on Commission-->
        <div class="form-group">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    {{getCommissionTaxOrGstOrHst()}}
                </label>
            </div>
            <div class="col-lg-4">
                <input
                    type="text"
                    id="vendorHstOnCommission"
                    name="vendorHstOnCommission"
                    [ngModel]="calculateVendorHstOnCommission()"
                    class="form-control text-right"
                    [dp-read-only]="true"
                    dp-currency
                />
            </div>
        </div>
        <!--Total Commission (incl. HST)-->
        <div class="form-group">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    {{getTotalCommissionInclHstLabelForVendorBroker()}}
                </label>
            </div>
            <div class="col-lg-4">
                <input
                    type="text"
                    id="vendorTotalCommissionIinclHst"
                    name="vendorTotalCommissionIinclHst"
                    [ngModel]="calculateVendorTotalCommissionInclHst()"
                    class="form-control text-right"
                    [dp-read-only]="true"
                    dp-currency
                />
            </div>
        </div>
        <!--Deposit-->
        <div class="form-group" *ngIf="!isVendorDepositInvisible">
            <div  class="col-lg-6"></div>
            <div class="col-lg-2 vertical-align-label" *ngIf="isVendorDepositVisible">
                <label class="control-label">
                    Deposit
                </label>
            </div>
            <div class="col-lg-4" *ngIf="isVendorDepositVisible">
                <input
                    type="text"
                    id="vendorDeposit"
                    name="vendorDeposit"
                    [ngModel]="matter.calculateBrokerDeposit()"
                    class="form-control text-right"
                    [dp-read-only]="true"
                    dp-currency
                />
            </div>
        </div>
        <!--Commission payable to Vendor's broker-->
        <div class="form-group">
            <div class="col-lg-6" *ngIf="!isBrokerReleaseExcessToVendorVisible"></div>
            <div *ngIf="isBrokerReleaseExcessToVendorVisible">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        Broker Release Excess to
                    </label>
                </div>
                <div  class="col-lg-4">
                    <select
                        class="form-control"
                        [(ngModel)]="matter.brokerReleaseExcessTo"
                        (ngModelChange)="onBrokerReleaseExcessToChange()">
                        <option *ngFor="let item of brokerReleaseExcessToOptions"
                                [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">
                    {{getCommissionPayableToVendorBrokerLabel()}}
                </label>
            </div>
            <div class="col-lg-4">
                <input
                    type="text"
                    id="vendorCommissionPayableToVendorBroker"
                    name="vendorCommissionPayableToVendorBroker"
                    [ngModel]="calculateVendorCommissionPayableToVendorBroker()"
                    class="form-control text-right"
                    [dp-read-only]="true"
                    dp-currency
                />
            </div>
        </div>

        <div class="main-level" *ngIf="isBothOfCommissionPaidTo">
            <span class="title"></span>
        </div>
        <div class="clear"></div>
        </ng-container>
        <!--Purchaser's  Commission-->
        <!--"Purchaser's broker percentage of commission" and "On"-->
        <ng-container *ngIf="isBothOfCommissionPaidTo || isPurchaserOnlyOfCommissionPaidTo">
            <div *dpShowByProvince="'matter.sale.brokerCommission.purchasersBrokerPercentageOfCommission'">
                <div class="form-group" *ngIf="isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" dp-province-field-label="matter.broker.commission.purchasersBrokerCommission">
                            Purchaser's broker percentage of commission
                        </label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="purchaserBrokerPercentageOfCommission"
                               name="purchaserBrokerPercentageOfCommission"
                               [(ngModel)]="brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission"
                               (change)="setTotalCommissionPercentage()"
                               [padding-zero]="true"
                               [dp-read-only]="matter.isMatterProvinceSK"
                               class="form-control text-right"
                               dp-percentage
                        />
                    </div>
                    <div class="col-lg-3">
                        <label class="control-label">
                            {{getVendorBrokerPercentageOfCommissionOnLabel()}}
                        </label>
                    </div>
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" *ngIf="!matter.isMatterProvinceSK">
                            On
                        </label>
                    </div>
                    <div class="col-lg-4">
                        <input *ngIf="!matter.isMatterProvinceSK"
                            type="text"
                            id="purchaserBrokerPercentageOfCommissionOn"
                            name="purchaserBrokerPercentageOfCommissionOn"
                            [ngModel]="calculatePurchaserBrokerPercentageOfCommissionOn()"
                            class="form-control text-right"
                            [dp-read-only]="true"
                            dp-currency
                        />
                    </div>
                </div>
            </div>
            <!--'Purchaser's broker commission-->
            <div class="form-group">
                <div *ngIf="!isPurchaserOnlyOfCommissionPaidTo" class="col-lg-6"></div>
                <ng-container *ngIf="isPurchaserOnlyOfCommissionPaidTo">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">
                            Commission disbursed by
                        </label>
                    </div>
                    <div class="col-lg-4">
                        <select
                            class="form-control"
                            [(ngModel)]="matter.commissionDisbursedBy"
                            (ngModelChange)="onCommissionDisbursedByChange()">
                            <option *ngFor="let item of commissionDisbursedByOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" *ngIf="isBothOfCommissionPaidTo" dp-province-field-label="matter.broker.commission.purchasersBrokerCommission">
                        Purchaser's Broker Commission
                    </label>
                    <label class="control-label" *ngIf="isPurchaserOnlyOfCommissionPaidTo">
                        Commission
                    </label>
                </div>
                <div class="col-lg-4">
                    <input *ngIf="isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo || isPurchaserOnlyOfCommissionPaidTo"
                    type="text"
                           id="purchaserBrokerCommissionNoEdit"
                           name="purchaserBrokerCommissionNoEdit"
                           [ngModel]="calculatePurchaserBrokerCommission()"
                           class="form-control text-right"
                           [dp-read-only]="true"
                           dp-currency
                    />
                    <input  *ngIf="isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo"
                            type="text"
                            id="purchaserBrokerCommissionEdit"
                            name="purchaserBrokerCommissionEdit"
                            [(ngModel)]="brokerCommission.purchaserCommission.purchaserBrokerCommission"
                            (change)="setCommissionBeforeHst()"
                            class="form-control text-right"
                            dp-currency
                    />
                </div>
            </div>
            <!--HST on Commission-->
            <div class="form-group">
                <div  class="col-lg-6"></div>
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        {{getCommissionTaxOrGstOrHst()}}
                    </label>
                </div>
                <div class="col-lg-4">
                    <input
                        type="text"
                        id="purchaserHstOnCommission"
                        name="purchaserHstOnCommission"
                        [ngModel]="calculatePurchaserHstOnCommission()"
                        class="form-control text-right"
                        [dp-read-only]="true"
                        dp-currency
                    />
                </div>
            </div>
            <!--Total Commission (incl. HST)-->
            <div class="form-group">
                <div  class="col-lg-6"></div>
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        {{getTotalCommissionInclHstLabelForPurchaserBroker()}}
                    </label>
                </div>
                <div class="col-lg-4">
                    <input
                        type="text"
                        id="purchaserTotalCommissionInclHst"
                        name="purchaserTotalCommissionInclHst"
                        [ngModel]="calculatePurchaserTotalCommissionInclHst()"
                        class="form-control text-right"
                        [dp-read-only]="true"
                        dp-currency
                    />
                </div>
            </div>
            <!--Deposit-->
            <div class="form-group" *ngIf="isBothOfCommissionPaidTo || isPurchaserOnlyOfCommissionPaidTo">
                <div  class="col-lg-6"></div>
                <div class="col-lg-2 vertical-align-label" *ngIf="isPurchaserDepositVisible">
                    <label class="control-label">
                        Deposit
                    </label>
                </div>
                <div class="col-lg-4" *ngIf="isPurchaserDepositVisible">
                    <input
                        type="text"
                        id="deposit"
                        name="deposit"
                        [ngModel]="matter.calculateBrokerDeposit()"
                        class="form-control text-right"
                        [dp-read-only]="true"
                        dp-currency
                    />
                </div>
            </div>
            <!--Commission payable to Purchaser's broker-->
            <div class="form-group">
                <div  class="col-lg-6" *ngIf="!isBrokerReleaseExcessToPurchaserVisible"></div>
                <div *ngIf="isBrokerReleaseExcessToPurchaserVisible">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">
                            Broker Release Excess to
                        </label>
                    </div>
                    <div  class="col-lg-4">
                        <select
                            class="form-control"
                            [(ngModel)]="matter.brokerReleaseExcessTo"
                            (ngModelChange)="onBrokerReleaseExcessToChange()">
                            <option *ngFor="let item of brokerReleaseExcessToOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        {{getCommissionPayableToPurchaserBrokerLabel()}}
                    </label>
                </div>
                <div class="col-lg-4">
                    <input
                        type="text"
                        id="commissionPayableToVendorBroker"
                        name="commissionPayableToVendorBroker"
                        [ngModel]="calculatePurchaserCommissionPayableToVendorBroker()"
                        class="form-control text-right"
                        [dp-read-only]="true"
                        dp-currency
                    />
                </div>
            </div>
        </ng-container>


    </ng-container>
    <dp-user-defined-fields [topicName] = "'REAL_ESTATE_BROKER'" (onChange)="enableSave()">
    </dp-user-defined-fields>
</div>
