import {BaseEntity} from "../../shared/BaseEntity/base-entity";
import {TCOLDocumentCost} from "./tcol-document-cost";

export class TcolTaxInfo extends BaseEntity {

  groupId: number;
  matterId: number;
  matterDocumentId: number;
  status: string;
  createdTimeStamp: string;
  documentCost: TCOLDocumentCost;
}
