import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {DecimalPipe} from '@angular/common';
import {RequisitionsService} from './requisitions.service';
import {RequisitionGroupsService} from '../../admin/requisition-groups/requisition-groups.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {RequisitionTemplate} from '../shared/requisition-template';
import {requisitionCategoryDropdownOptions, requisitionDropDowns} from '../mortgages/mortgage/dropdown-options';
import {CkEditorUtil} from '../shared/ckeditor-util';
import {SelectItem} from 'primeng/api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ApplicationError, FieldError} from '../../core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

declare var CKEDITOR: any;
declare var jQuery: any;

export type RequisitionEditAction = 'NEW' | 'EDIT' | 'COPY';

class RequisitionsAddTemplateModalContext {
  public action: RequisitionEditAction;
  public requisitionTemplate: RequisitionTemplate;
  public isCategoryVisible: boolean = false;
}

@Component({
  selector: 'dp-requisitions-add-template-modal',
  templateUrl: './requisition-add-template.modal.component.html',
  providers: [DecimalPipe, StatusBarService, ErrorService, RequisitionsService, RequisitionGroupsService],
  styleUrls: ['./requisition-add-template.modal.component.scss']
})
export class RequisitionsAddTemplateModal
  extends ModalComponent<RequisitionsAddTemplateModalContext>
  implements OnInit, AfterViewInit
{
  requisitionTemplate: RequisitionTemplate;
  requisitionCategoryOptions = requisitionCategoryDropdownOptions;
  requisitionCodeOptions: SelectItem[];
  disableOK: boolean = false;
  action: string;
  editor;
  requisitionCode: string;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<RequisitionsAddTemplateModal>,
    public dialogService: DialogService,
    public requisitionsService: RequisitionsService,
    public decimalPipe: DecimalPipe,
    public statusBarService: StatusBarService,
    public errorService: ErrorService,
    public requisitionGroupsService: RequisitionGroupsService,
    @Inject(MAT_DIALOG_DATA) context?: RequisitionsAddTemplateModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    if (this.context.requisitionTemplate) {
      this.requisitionTemplate = new RequisitionTemplate(this.context.requisitionTemplate);
      if (this.context.action === 'COPY') {
        this.requisitionTemplate.id = null;
      }
    } else {
      this.requisitionTemplate = new RequisitionTemplate();
      this.requisitionTemplate.instanceType = 'DEFAULT_REQUISITION';
      this.requisitionTemplate.requisitionCategory = null;
    }
    let statusbarService: StatusBarService = this.statusBarService;
    let requisitionTemplate = this.requisitionTemplate;
    let firstParaOfStandardText = this.firstParagraphOfStandardText;
    let secondParaOfStandardText = this.secondParagraphOfStandardText;
    let editor = this.editor;
    this.editor = CKEDITOR.replace('rteditorModal', {});
    let action = this.context.action;
    CkEditorUtil.setUpEditor(this.editor);
    this.editor.addCommand('f9command', {
      exec: function (editor) {
        if (action === 'NEW') {
          if (!requisitionTemplate.requisitionText) {
            requisitionTemplate.requisitionText = firstParaOfStandardText + secondParaOfStandardText;
            editor.setData('<p>' + firstParaOfStandardText + '</p><p>' + secondParaOfStandardText + '</p>');
          } else {
            requisitionTemplate.requisitionText = '';
            editor.setData(requisitionTemplate.requisitionText);
          }
        } else {
          let data: string = editor.getData();
          if (!data || data.trim() === '') {
            editor.setData(requisitionTemplate.requisitionText);
          } else {
            editor.setData('');
          }
        }
      }
    });

    this.editor.on('focus', function (e) {
      statusbarService.dynamicHelpFocus(
        'Enter full text of requisition. F8 = Special Characters Menu, F9 = Insert Standard Paragraph'
      );
      console.log('The editor is now focused');
    });
    this.editor.on('blur', function (e) {
      let startElement = e.editor.getSelection().getRanges()[0];
      console.log('The editor is out of focus' + startElement);
      requisitionTemplate.requisitionText = e.editor.getData();
    });

    this.editor.setKeystroke(120, 'f9command'); // 1
    this.requisitionCodeOptions = requisitionDropDowns.code;
    this.retrieveCategoryVisibilityForAccount();
  }

  get firstParagraphOfStandardText() {
    return 'Instrument No. <<1[[Enter the Instrument No.]]>> is a <<8[[Enter the Encumbrance Type]]>> registered <<2[[Enter the Date of Registration]]>> from <<5[[Enter the Party From]]>> in favour of <<6[[Enter the Party To]]>> securing the principal sum of <<4[[Enter the Instrument Amount]]>>.';
  }

  get secondParagraphOfStandardText() {
    return '»REQUIRED:»On or before closing, production and registration of';
  }

  //Need to check if category is visible based on account settings.
  retrieveCategoryVisibilityForAccount(): void {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.requisitionGroupsService.getRequisitionGroups(id).subscribe(
      (data) => {
        if (data && data != null && data.configurationOptionValues && data.configurationOptionValues.length > 0) {
          let optionValue = data.configurationOptionValues[0].configurationValue;
          this.context.isCategoryVisible = optionValue == 'true';
        } else {
          this.context.isCategoryVisible = false;
        }
      },
      (err) => {
        this.context.isCategoryVisible = false;
      }
    );
  }

  ngAfterViewInit(): void {}

  close(): void {
    this.dialog.close({});
  }

  isCategoryVisible(): boolean {
    return this.context.isCategoryVisible;
  }

  handleF9Undertakings(): void {
    this.requisitionTemplate.require = 'obtain and register a good and valid discharge of the';
  }

  handleF9Encumbrance(): void {
    this.requisitionTemplate.encumbranceType = '<<8>>';
  }

  handleF9RegistrationNumber(): void {
    this.requisitionTemplate.registrationNumber = '<<1>>';
  }

  handleF9InFavourOf(): void {
    this.requisitionTemplate.inFavourOf = '<<6>>';
  }

  onCodeChange(requisitionCode: string): void {
    if (requisitionCode != '') {
      this.editor.insertText(requisitionCode);
    }
  }

  validateFormSubmission(): boolean {
    this.modalErrorComponent.removeAllDpSaveError();
    if (this.requisitionTemplate) {
      if (!this.requisitionTemplate.key) {
        this.modalErrorComponent.createCustomDPSaveError(
          'requisitionTemplate.key',
          'Requisition Name is mandatory',
          'Requisition',
          'ERROR'
        );
      }
    }
    return this.modalErrorComponent.anyErrorExist();
  }

  onOK(): void {
    this.disableOK = true; // Disabling the OK button to prevent multiple submissions
    this.requisitionTemplate.requisitionText = this.editor.getData();
    if (!this.validateFormSubmission()) {
      if (this.context.action === 'EDIT') {
        this.updateRequisition();
      } else {
        this.createRequisition();
      }
    }
  }

  createRequisition() {
    this.requisitionsService.createDefaultRequisition(this.requisitionTemplate).subscribe(
      (data: RequisitionTemplate) => {
        if (data) {
          this.dialog.close({requisitionTemplate: data});
        }
      },
      (error: ApplicationError) => {
        if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
          error.fieldErrors.forEach((fieldError: FieldError) => {
            this.modalErrorComponent.createCustomDPSaveError(fieldError.errorCode, fieldError.message, null, 'ERROR');
          });
        }
      }
    );
  }

  updateRequisition() {
    this.requisitionsService.updateRequisition(this.requisitionTemplate).subscribe(
      (data: RequisitionTemplate) => {
        if (data) {
          this.dialog.close({requisitionTemplate: data});
        }
      },
      (error: ApplicationError) => {
        if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
          error.fieldErrors.forEach((fieldError: FieldError) => {
            this.modalErrorComponent.createCustomDPSaveError(fieldError.errorCode, fieldError.message, null, 'ERROR');
          });
        }
      }
    );
  }
}
