import {Component, Inject, OnInit} from '@angular/core';
import {Matter} from '../../shared/matter';
import {MatterService} from '../../matter.service';
import {Subscription} from 'rxjs/index';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import {MatterLink} from '../matter-link';
import {LockScreenService} from '../../../core/lock-screen.service';
import {CopyMatterLinkDataService} from '../../../matters/copy-matter-link-data.service';
import {Contact, ContactService} from '../../shared';
import {AuthZService} from '../../../core/authz/auth-z.service';
import {MatterExtraDepositConfig} from '../../shared/matter-extra-deposit-config';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class LinkMatterModalContext {
  public matter: Matter;
  public isNewMatter: boolean;
  public copyMatterLinkDataService: CopyMatterLinkDataService;
}

@Component({
  selector: 'dp-link-matter-modal',
  templateUrl: 'link-matter-modal.component.html',
  styleUrls: ['./link-matter-modal.styles.scss'],
  providers: [ErrorService]
})
export class LinkMatterModalComponent extends ModalComponent<LinkMatterModalContext> implements OnInit {
  saveSubscription: Subscription;
  matterRecordNumber: string;
  fileNumber: string;
  clientReLine: string;
  closingDate: string;
  matterSubscription: Subscription;
  linkedMatter: Matter;
  loading: boolean = false;

  //This flag is created to replace the automatic ok btn enable happening in dialog.service
  //As the APIs take longer time to come back which exceeds the timeout period to enable the ok btn
  isSubmitBtnDisabled: boolean = false;

  constructor(
    public dialog: MatDialogRef<LinkMatterModalComponent>,
    private matterService: MatterService,
    private errorService: ErrorService,
    private lockScreenService: LockScreenService,
    private documentProfileService: DocumentProfileService,
    private authZService: AuthZService,
    public dialogService: DialogService,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) context?: LinkMatterModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {}

  submit() {
    if (this.context.isNewMatter) {
      this.createNewMatter();
    } else {
      this.dialogService
        .confirm(
          'Information',
          'Information from the current matter will be used to populate the linked matter and will override any information previously entered.',
          false
        )
        .subscribe((res) => {
          if (res) {
            this.linkToExistingMatter();
          }
        });
    }
  }

  findMatter() {
    this.loading = true;
    this.errorService.clearAllSaveErrors();
    let searchMatterType = this.context.matter.isPurchase ? 'SALE' : null;

    this.matterSubscription = this.matterService
      .getMatterByRecordNumber(this.matterRecordNumber, searchMatterType)
      .subscribe(
        (data: Matter[]) => {
          this.loading = false;
          if (data.length == 0) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.matterOpening.matterNo',
                'No ' +
                  (this.context.matter.isPurchase ? 'Sale' : 'Purchase') +
                  ' matter ' +
                  this.matterRecordNumber +
                  ' was found. Please provide a' +
                  ' valid matter number.',
                '',
                'ERROR'
              )
            );
          } else if (data.length == 1 && data[0].matterType != (this.context.matter.isPurchase ? 'SALE' : 'PURCHASE')) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.matterOpening.matterNo',
                'You cannot' +
                  ' link a ' +
                  data[0].matterType.toLowerCase() +
                  ' matter to another ' +
                  data[0].matterType.toLowerCase() +
                  ' matter. Please try again.',
                '',
                'ERROR'
              )
            );
          } else {
            this.linkedMatter = data[0];
            this.matterRecordNumber = data[0].matterRecordNumber;
            this.fileNumber = data[0].fileNumber;
            this.clientReLine = data[0].clientReLine;
            this.closingDate = data[0].getClosingDate();
          }
        },
        (error) => {
          // ignore errors
          this.loading = false;
          console.log(error);
        }
      );
  }

  linkToExistingMatter(): void {
    if (this.linkedMatter.lockedByLoggedInUser) {
      let firstName =
        this.linkedMatter.lockedByUser && this.linkedMatter.lockedByUser.firstName
          ? this.linkedMatter.lockedByUser.firstName
          : ' ';
      let lastName =
        this.linkedMatter.lockedByUser && this.linkedMatter.lockedByUser.lastName
          ? this.linkedMatter.lockedByUser.lastName
          : ' ';
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(
          'matter.matterOpening.matterNo',
          'Matter ' +
            this.linkedMatter.matterRecordNumber +
            ' is currently locked for edit by ' +
            firstName +
            ' ' +
            lastName +
            " and can't be linked until closed.",
          '',
          'ERROR'
        )
      );
    } else {
      this.matterService.getMatter(this.linkedMatter.id).subscribe((matter) => {
        if (matter) {
          this.linkedMatter = matter;
          //Copy the solicitor and lawClerk of linked Matter tab A to source matter tab c
          this.linkedMatter.isSolicitorOrLawClerkDirty = !!(this.linkedMatter.solicitor || this.linkedMatter.lawClerk);
          this.errorService.clearAllSaveErrors();
          if (this.linkedMatter.matterLink) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.matterOpening.matterNo',
                'Matter ' +
                  this.linkedMatter.matterRecordNumber +
                  ' is ' +
                  ' already linked to' +
                  ' another ' +
                  (this.context.matter.isPurchase ? 'purchase' : 'sale') +
                  ' matter',
                '',
                'ERROR'
              )
            );
          }
          if (this.linkedMatter.locked) {
            let firstName =
              this.linkedMatter.lockedByUser && this.linkedMatter.lockedByUser.firstName
                ? this.linkedMatter.lockedByUser.firstName
                : ' ';
            let lastName =
              this.linkedMatter.lockedByUser && this.linkedMatter.lockedByUser.lastName
                ? this.linkedMatter.lockedByUser.lastName
                : ' ';
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.matterOpening.matterNo',
                'Matter ' +
                  this.linkedMatter.matterRecordNumber +
                  ' is currently locked for edit by ' +
                  firstName +
                  ' ' +
                  lastName +
                  " and can't be linked until closed.",
                '',
                'ERROR'
              )
            );
          }

          if (this.linkedMatter.isAnyVTBMortgages()) {
            this.errorService.addDpSaveError(DPError.createDPError('matter.matterOpening.matterLink'));
          }

          /*                    if( this.linkedMatter &&  this.linkedMatter.otherSideClients){
                                  this.linkedMatter.otherSideClients.forEach( matterParticipant =>{
                                      if(matterParticipant.contact && (!matterParticipant.contact.sourceContactId || matterParticipant.contact.sourceContactId == null)){
                                          this.errorService.addDpSaveError(DPError.createCustomDPError("matter.matterOpening.otherSideClient.sourceContactId.missing"+matterParticipant.matterParticipantId,  matterParticipant.contact.genericFullName + " is not a new Client", "", "ERROR"));
                                      }
                                  })
                              }
                              if( this.context.matter &&   this.context.matter.otherSideClients){
                                  this.context.matter.otherSideClients.forEach( matterParticipant =>{
                                      if(matterParticipant.contact && (!matterParticipant.contact.sourceContactId || matterParticipant.contact.sourceContactId == null)){
                                          this.errorService.addDpSaveError(DPError.createCustomDPError("matter.matterOpening.otherSideClient.sourceContactId.missing"+matterParticipant.matterParticipantId,  matterParticipant.contact.genericFullName + " is not a new Client", "", "ERROR"));
                                      }
                                  })
                              }*/

          if (this.context.matter && this.context.matter.containsMatterOnlyClients()) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.matterOpening.otherSideClient.sourceContactId.missing',
                `Can't link to existing matter:  ${this.context.matter.matterRecordNumber} has matter-only ${this.context.matter.isSale ? 'purchaser' : 'vendor'} contacts.  To link, matter-only contacts must be removed.`,
                '',
                'ERROR'
              )
            );
          }

          if (this.errorService.hasNoErrors() && this.linkedMatter) {
            this.linkedMatter.actingFor = this.context.matter.isPurchase ? 'VENDOR_PURCHASER' : 'PURCHASER_VENDOR';
            // Create Matter Link Object
            this.linkedMatter.matterLink = new MatterLink();
            this.linkedMatter.matterLink.linkedMatterId = this.context.matter.id;
            this.copyAndSaveLinkMatter();
          }
        }
      });
    }
  }

  createNewMatter(): void {
    this.isSubmitBtnDisabled = true;
    this.errorService.clearAllSaveErrors();
    /*        if( this.context.matter &&   this.context.matter.otherSideClients){
                this.context.matter.otherSideClients.forEach( matterParticipant =>{
                    if(matterParticipant.contact && (!matterParticipant.contact.sourceContactId || matterParticipant.contact.sourceContactId == null)){
                        this.errorService.addDpSaveError(DPError.createCustomDPError("matter.matterOpening.otherSideClient.sourceContactId.missing"+matterParticipant.matterParticipantId,  matterParticipant.contact.genericFullName + " is not a new Client", "", "ERROR"));
                        this.isSubmitBtnDisabled = false;
                    }
                })
            }*/

    if (this.context.matter && this.context.matter.containsMatterOnlyClients()) {
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(
          'matter.matterOpening.otherSideClient.sourceContactId.missing',
          `Can't create and link to new ${this.context.matter.isSale ? 'purchase' : 'sale'} matter:  ${this.context.matter.matterRecordNumber} has matter-only ${this.context.matter.isSale ? 'purchaser' : 'vendor'} contacts.  To link, matter-only contacts must be removed.`,
          '',
          'ERROR'
        )
      );
    }

    if (this.errorService.hasNoErrors()) {
      this.lockScreenService.lockForUpdate = true;
      this.matterService
        .createNewMatter(this.context.matter.isPurchase ? 'SALE' : 'PURCHASE', this.context.matter.provinceCode)
        .finally(() => {
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe(
          async (matter) => {
            if (matter) {
              this.linkedMatter = matter;
              if (this.context.matter.isProjectSale && matter.isPurchase) {
                this.linkedMatter.project = this.context.matter.project;
                this.linkedMatter.extraDepositConfig = new MatterExtraDepositConfig(
                  this.context.matter.extraDepositConfig
                );
              }
              this.linkedMatter.matterRecordNumber = this.matterRecordNumber;
              this.linkedMatter.fileNumber = this.fileNumber;
              this.linkedMatter.accountingNumber = this.matterRecordNumber;
              this.linkedMatter.actingFor = this.context.matter.isPurchase ? 'VENDOR_PURCHASER' : 'PURCHASER_VENDOR';
              // Create Matter Link Object
              this.linkedMatter.matterLink = new MatterLink();
              this.linkedMatter.matterLink.linkedMatterId = this.context.matter.id;
              let solicitors: Contact[] = await this.contactService.getSolicitorLawClerkList('SOLICITOR').toPromise();
              this.linkedMatter.initializeSolicitorLawClerk(solicitors, 'SOLICITOR');
              let lawClerks: Contact[] = await this.contactService.getSolicitorLawClerkList('LAWCLERK').toPromise();
              this.linkedMatter.initializeSolicitorLawClerk(lawClerks, 'LAWCLERK');
              this.copyAndSaveLinkMatter();
            }
          },
          (error) => {
            this.isSubmitBtnDisabled = false;
          }
        );
    }
  }

  close() {
    this.dialog.close();
  }

  getTitle(): string {
    let title: string;
    if (this.context.isNewMatter) {
      title = this.context.matter.isPurchase
        ? 'Create new sale matter importing purchase record'
        : 'Create new purchase matter importing sale record';
    } else {
      title = 'Link Matters';
    }
    return title;
  }

  getOkText(): string {
    let matterActionText: string = this.context.isNewMatter ? 'Create' : 'Link to';
    let matterTypeText: string = this.context.matter.isPurchase ? 'Sale Matter' : 'Purchase Matter';

    return matterActionText + ' ' + matterTypeText;
  }

  populateFileNo(): void {
    if (this.matterRecordNumber && this.context.isNewMatter) {
      if (!this.fileNumber) {
        this.fileNumber = this.matterRecordNumber;
      }
    }
  }

  copyAndSaveLinkMatter(): void {
    if (this.linkedMatter) {
      // Init Matter
      this.matterService.initMatter(this.linkedMatter).subscribe((isMatterUpdated) => {
        if (isMatterUpdated) {
          // Copy Data
          this.context.matter.isSolicitorOrLawClerkDirty = true; //update tab A Solicitor and LawClerk to linked matter tab c
          this.context.copyMatterLinkDataService
            .copyLinkedMatterFields(this.context.matter, this.linkedMatter)
            .subscribe((isCopyCompleted) => {
              if (isCopyCompleted) {
                // Save Linked Matter
                this.matterService
                  .validateMatter(this.linkedMatter, undefined, this.errorService)
                  .subscribe((linkedMatterValidated) => {
                    if (linkedMatterValidated) {
                      this.matterService
                        .saveMatter(this.linkedMatter, undefined, undefined, undefined, this.errorService)
                        .subscribe(
                          (updatedLinkedMatter) => {
                            this.context.copyMatterLinkDataService.copyFileNumber(
                              updatedLinkedMatter,
                              this.context.matter
                            );
                            this.addMatterLink(updatedLinkedMatter);
                            //validate and save source matter after copying fields to it from the linked matter
                            this.matterService
                              .validateMatter(this.context.matter, undefined, this.errorService)
                              .subscribe((sourceMatterValidated) => {
                                if (sourceMatterValidated) {
                                  this.matterService
                                    .saveMatter(this.context.matter, undefined, undefined, undefined, this.errorService)
                                    .subscribe(
                                      (updatedSourceMatter) => {
                                        this.dialog.close({
                                          sourceMatter: updatedSourceMatter,
                                          linkedMatter: updatedLinkedMatter
                                        });
                                      },
                                      (error) => {
                                        this.isSubmitBtnDisabled = false;
                                      }
                                    );
                                } else {
                                  this.isSubmitBtnDisabled = false;
                                }
                              });
                          },
                          (error) => {
                            this.isSubmitBtnDisabled = false;
                          }
                        );
                    } else {
                      this.isSubmitBtnDisabled = false;
                    }
                  });
              }
            });
        }
      });
    }
  }

  //adding matterlink to current matter manually, otherwise the linking will get wiped out after the save
  addMatterLink(linkedMatter: Matter): void {
    this.context.matter.matterLink = new MatterLink();
    this.context.matter.matterLink.id = linkedMatter.matterLink.id;
    this.context.matter.matterLink.linkedMatterId = linkedMatter.id;
    this.context.matter.matterLink.linkedMatterNumber = linkedMatter.matterRecordNumber;
  }

  hasFullAccessToProjectMatters(): boolean {
    return this.authZService.hasFullAccessToProjectMatters();
  }
}
