export const TCOL_PRODUCT_TYPE : TCOLProductType = "TCOL";
export const TCOL_PLUS_PRODUCT_TYPE : TCOLProductType = "TCOLPLUS";

export type TCOLProductType = "TCOL" | "TCOLPLUS";

export class TCOLDocumentCost {
  type: TCOLProductType;
  priceBeforeTax: number;
  tax: number;
}
