import {AccessProfile} from './access-profile';
import {User} from '../../matters/shared/user';

export class UserAccountProfile {
  customerAccountId: number;
  id: number;
  accessProfile: AccessProfile;
  user: User;

  constructor(profile) {
    this.customerAccountId = profile.customerAccountId;
    this.id = profile.id;
    this.accessProfile = new AccessProfile(profile.accessProfile);
    this.user = new User(profile.user);
  }
}
