import {Component, Inject, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ProjectService} from '../../../../projects/project.service';
import {Project} from '../../../../projects/shared/project';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Constants} from '../../../../shared-main/constants';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class ProjectDeleteSoaContext {
  project: Project;
  numberOfProjectMatters: number;
}

export type ActionType = 'IMPORT' | 'DELETE';
export type ProjectAdjustmentActionType =
  'ALL_ADJUSTMENTS'
  | 'ALL_ADJUSTMENTS_EXCEPT_SALE_PRICE_AND_DEPOSIT'
  | 'ALL_ADJUSTMENTS_EXCEPT_DEPOSIT';

@Component({
  selector: 'dp-project-delete-soa-modal-content',
  templateUrl: 'project-delete-soa.modal.component.html',
  providers: [ ErrorService ],
  styleUrls: [ './project-delete-soa.modal.component.scss' ]
})

export class ProjectDeleteSoaComponent extends ModalComponent<ProjectDeleteSoaContext> implements OnInit {
  selectedAction: ActionType;
  actionOptions: SelectItem[] = [
    {label: 'Import Project Statement of Adjustments from another project', value: 'IMPORT'},
    {label: 'Delete all Adjustments from the Project Statement of Adjustments', value: 'DELETE'}
  ];
  selectedRemoveAdjustmentAction: ProjectAdjustmentActionType;
  removeAdjustmentOptions: SelectItem[] = [
    {label: 'Remove all adjustments', value: 'ALL_ADJUSTMENTS'},
    {label: 'Remove all adjustments except Sale Price and Deposit adjustments', value: 'ALL_ADJUSTMENTS_EXCEPT_SALE_PRICE_AND_DEPOSIT'},
    {label: 'Remove all adjustments except Deposit adjustments', value: 'ALL_ADJUSTMENTS_EXCEPT_DEPOSIT'}
  ];
  sourceProjectId: number;
  sourceProject: Project;
  projectOptions: SelectItem[] = [];
  selectedProjectForSearch: any;
  projectSuggestions: SelectItem[] = [];

  constructor(public dialog: MatDialogRef<ProjectDeleteSoaComponent>,
              public dialogService: DialogService,
              public projectService: ProjectService,
              @Inject(MAT_DIALOG_DATA) context?: ProjectDeleteSoaContext
            ) {
    super(dialog, context);
  }

  async ok(): Promise<void> {
    let actionMsg: string = this.actionOptions.filter(ao => ao.value == this.selectedAction)[ 0 ].label;
    let removeMsg: string = this.removeAdjustmentOptions.filter(ao => ao.value == this.selectedRemoveAdjustmentAction)[ 0 ].label;
    let confirmationMsg = '';
    if (this.context.numberOfProjectMatters) {
      confirmationMsg = `You have selected:<br><br>1. ${ actionMsg }<br>2. ${ removeMsg }<br><br>Do you wish to proceed?`;
    } else {
      confirmationMsg = `You have selected:<br><br>${ actionMsg }<br><br>Do you wish to proceed?`;
    }

    let res = await this.dialogService.confirm('Confirmation', confirmationMsg, false, 'Ok', 'Cancel', true).toPromise();
    if (res) {
      this.dialog.close({
        selectedAction: this.selectedAction,
        projectAdjustmentActionType: this.selectedRemoveAdjustmentAction,
        sourceProject: this.sourceProject
      });
    }
  }

  close(): void {
    this.dialog.close();
  }

  ngOnInit(): void {
    if (!this.context.numberOfProjectMatters) {
      this.selectedRemoveAdjustmentAction = 'ALL_ADJUSTMENTS';
    }
    this.buildProjectsList();
  }

  async buildProjectsList(): Promise<void> {
    let projects: Project[] = await this.projectService
    .getProjects(null, '', undefined, 'lastUpdatedTimeStamp', 'DESC', 1, 1000).toPromise();

    if (Array.isArray(projects)) {
      let project = this.context.project;
      this.projectOptions = [];
      this.projectOptions.push({label: ' ', value: 0});
      projects.filter(item => !!item.id && !!project && item.id != project.id).forEach((pr: Project) => this.projectOptions.push({
        label: `${ pr.projectRecordNumber }${ (pr.projectName) ? ' : ' + pr.projectName : '' }`,
        value: pr.id
      }));
    }
  }

  async validateSelectedProject(): Promise<void> {
    let msg: string;
    if (this.sourceProjectId && this.context.project) {
      let project = this.context.project;

      this.sourceProject = await this.projectService.getProject(this.sourceProjectId, false).toPromise();
      if (!project) {
        msg = 'Project does not exist\n.' +
          'please select another project';
      } else if (project.isCondo && !this.sourceProject.isCondo) {
        msg = 'The current and selected projects must be the same type.\n' +
          'The current project is a condominium but the selected project is not a condominium.\n' +
          'Please select a project that is a condominium.';

      } else if (!project.isCondo && this.sourceProject.isCondo) {
        msg = 'The current and selected projects must be the same type.\n' +
          'The current project is not a condominium but the selected project is a condominium\n' +
          'Please select a project that is not a condominium.';
      } else if (!this.sourceProject.isCondo && !this.sourceProject.isPOTL && !project.isCondo && project.isPOTL) {
        msg = 'The current and selected projects must be the same type.\n' +
          'The current project is not a condominium with a POTL.\n' +
          'Please select a project that is not a condominium with a POTL.';
      } else if (this.sourceProject.isCondo && !this.sourceProject.isPOTL && project.isCondo && project.isPOTL) {
        msg = 'The current and selected projects must be the same type.\n' +
          'The current project is a condominium with a POTL.\n' +
          'Please select a project that is a condominium with a POTL.';
      } else if (!this.sourceProject.isCondo && this.sourceProject.isPOTL && !project.isCondo && !project.isPOTL) {
        msg = 'The current and selected projects must be the same type.\n' +
          'The current project is not a condominium without a POTL.\n' +
          'Please select a project that is not a condominium without a POTL.';
      } else if (this.sourceProject.isCondo && this.sourceProject.isPOTL && project.isCondo && !project.isPOTL) {
        msg = 'The current and selected projects must be the same type.\n' +
          'The current project is a condominium without a POTL.\n' +
          'Please select a project that is a condominium without a POTL.';
      } else if (this.sourceProject.isStatementOfAdjustmentInterim() && !project.isStatementOfAdjustmentInterim()) {
        msg = 'You are unable to import this project\'s Statement of Adjustment.\n\n' +
          'The source project has both an interim and final Statement of Adjustment, while the current project only has a single Statement of Adjustment';
      } else if (!this.sourceProject.isStatementOfAdjustmentInterim() && project.isStatementOfAdjustmentInterim()) {
        msg = 'You are unable to import this project\'s Statement of Adjustment.\n\n' +
          'The source project has a single Statement of Adjustment, while the current project has both an interim and final Statement of Adjustment';
      } else if (!this.sourceProject.fullyInitialized) {
        msg = 'You are unable to import this project\'s Statement of Adjustment.\n\n' +
          'The source project is in an \'Incomplete\' state , Open the project for edit to complete the initialization';
      }

    }
    if (!!msg) {
      this.sourceProjectId = undefined;
      this.selectedProjectForSearch = undefined;
      this.dialogService.confirm('Confirmation', msg, true, 'OK');
    }

  }

  disableProceedBtn(): boolean {
    let disable = (!this.selectedAction || !this.selectedRemoveAdjustmentAction || (this.selectedAction == 'IMPORT' && !this.sourceProjectId));
    console.log(disable + ' this.selectedAction ' + this.selectedAction + ' this.selectedRemoveAdjustmentAction ' + this.selectedRemoveAdjustmentAction + ' this.sourceProjectId ' + this.sourceProjectId + ' End');
    return disable;
  }

  omniSearchProject(event) {

    const query: string = event.query.trim();
    this.projectSuggestions = !query
      ? this.projectOptions.slice()
      : this.projectOptions.filter(x => x.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
    if (this.projectSuggestions.length === 0) {
      this.projectSuggestions.push({label: Constants.NO_RESULTS_FOUND, value: Constants.NO_RESULTS_FOUND});
    }
  }

  dataSelectedProject(event) {
    console.log('onSelect : ', event);
    if (event.label === Constants.NO_RESULTS_FOUND) {
      this.selectedProjectForSearch = null;
      this.sourceProjectId = undefined;
      setTimeout(() => {
        this.selectedProjectForSearch = null;
        this.sourceProjectId = undefined;
      }, 0);
    } else {
      if (this.selectedProjectForSearch !== event.label) {
        this.selectedProjectForSearch = event.label;
        this.sourceProjectId = event.value;
        this.validateSelectedProject();
      } else {
        this.selectedProjectForSearch = null;
        setTimeout(() => {
          this.selectedProjectForSearch = event.label;
          this.sourceProjectId = event.value;
          this.validateSelectedProject();
        }, 0);
      }
    }
  }

  handleDropdownClickProject() {
    this.projectSuggestions = this.projectOptions.slice();
  }
}
