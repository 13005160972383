import {Mortgage} from '../../../shared/mortgage';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SelectItem} from 'primeng/api';
import {PurposeOfPayoutOptions, PurposeOfPayoutOptionsForBmo, yesNoOnlyOptions} from '../dropdown-options';
import {Matter} from '../../../shared';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ApplicationError, TabsService} from '../../../../core';
import {TelusService} from '../../../../shared-main/telus/telus-service';
import {LockScreenService} from '../../../../core/lock-screen.service';
import {PayoutRequestData} from './payout-request-data';
import {LegalDescriptionUtil} from '../../../title-insurance/title-insurance-legal-description';
import {DpBooleanValue, DpBooleanValueTypes} from '../../../shared/dp-boolean';
import {PinLegalDescription} from '../../../title-insurance/pin-legal-description';
import {PayoutStatement} from '../../../../shared-main/telus/payout-statement';
import Utils from '../../../../shared-main/utils';
import {PROVINCE_CODES} from '../../../shared/user-province';
import {RegistryOfficeService} from '../../../property-teranet/registry-office/registry-office.service';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export type PurposeOfPayoutType =
  'RATE'
  | 'CONVENIENCE'
  | 'PRODUCT_FEATURES'
  | 'DECLINED_BY_BMO'
  | 'SERVICE'
  | 'PROPERTY_SOLD_OWN_RESOURCE_VALUE'
  | 'CDC_RELOCATION'
  | 'REFINANCE_WITH_BMO'
  | 'DEATH_OF_CUSTOMER_DESTROYED_BY_FIRE'
  | 'COLLECTIONS_BANKRUPTCY_FRAUD'
  | 'OTHER';

export class AssystPayoutRequestModalContext {
  mortgage: Mortgage;
  matter: Matter;
  payoutStatement: PayoutStatement;
}

const RegistryOfficeNames = [
  {name: 'Toronto Land Registry (NO. 80)', newName: 'Toronto Land Registry Office (64 and 66)'},
  {name: 'York Region Land Registry (NO. 65)', newName: 'York Land Registry Office (65)'},
  {name: 'Wentworth Land Registry (NO. 62)', newName: 'Hamilton-Wentworth Land Registry Office (62)'}
];

@Component({
  selector: 'dp-assyst-payout-request-modal-content',
  templateUrl: 'assyst-payout-request.modal.component.html',
  providers: [ ErrorService, RegistryOfficeService ]
})
export class AssystPayoutRequestModalComponent extends ModalComponent<AssystPayoutRequestModalContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  puposeOfPayoutOptions: SelectItem[] = this.appConfig && this.appConfig.isDropdownOptionsEnableForAssystPayout ? PurposeOfPayoutOptionsForBmo : PurposeOfPayoutOptions;
  acknowledgementOfConsentOptions: SelectItem[] = yesNoOnlyOptions;
  purposeOfPayout: PurposeOfPayoutType;
  purposeOfPayoutOther: string;
  acknowledgementOfConsent: DpBooleanValue;
  reasonForCancellation: string;
  landRegistryOffice: string;
  registryOffices: SelectItem[];
  acknowledgeMessage: string;
  isAcknowledgeCheckboxChecked: boolean = false;

  constructor(public dialog: MatDialogRef<AssystPayoutRequestModalComponent, PayoutStatement>,
              public errorService: ErrorService,
              public dialogService: DialogService,
              public telusService: TelusService,
              public lockScreenService: LockScreenService,
              public registryOfficeService: RegistryOfficeService,
              public tabsService: TabsService,
              public appConfig: AppConfig,
              @Inject(MAT_DIALOG_DATA) context?: AssystPayoutRequestModalContext) {
    super(dialog, context);
  }

  get mortgage(): Mortgage {
    return this.context.mortgage;
  }

  get matter(): Matter {
    return this.context.matter;
  }

  ngOnInit() {
    if (!this.purposeOfPayout) {
      if (this.context.matter.isSale) {
        this.purposeOfPayout = 'PROPERTY_SOLD_OWN_RESOURCE_VALUE';
      } else {
        this.purposeOfPayout = 'OTHER';
      }
    }
    if (!this.acknowledgementOfConsent) {
      this.acknowledgementOfConsent = 'YES';
    }
    if (this.isLandTitleOfficeDropdownVisible() && !this.isModalOpenForCancellation()) {
      this.loadRegistryOffices();
    }
  }

  async loadRegistryOffices(): Promise<void> {
    this.registryOffices = [];
    let registryOffices = await this.registryOfficeService.getTelusPayoutRegistryOffices(this.matter.provinceCode).toPromise();
    this.registryOffices = registryOffices.map((item) => {
      return {label: item, value: item};
    });
    this.registryOffices.unshift({label: '', value: ''});
  }

    changePuposeOfPayoutOptions(purposeOfPayout) {
        this.modalErrorComponent.removeDpFieldError("integrations.telusAssyst.acknowledgementCheckboxLC");
        this.isAcknowledgeCheckboxChecked = false;
        if (purposeOfPayout == 'CDC_RELOCATION') {
            this.acknowledgeMessage = `I acknowledge that I have confirmed that my client is a member of the Canadian Defence Community Banking program at
            BMO Financial Group and that they are relocating under the Canadian Armed Forces or RCMP Relocation Directive. I
            confirm that I have viewed and kept a copy of the applicable Posting Orders or Transfer Request (the “Posting
            Order”) issued from the CAF/RCMP & contact info for their Relocation Advisor. I undertake to provide a copy of the
            Posting Order to the BMO Branch at the time of payout of my client’s existing mortgage with BMO.`;
    } else if (purposeOfPayout == 'REFINANCE_WITH_BMO') {
      this.acknowledgeMessage = `I acknowledge that I have confirmed that my client is paying off their existing BMO mortgage for the purpose of refinancing of their existing BMO mortgage with another BMO mortgage or Homeowner ReadiLine®. I acknowledge that my client is refinancing to a new replacement BMO mortgage or Homeowner ReadiLine®, and that I am facilitating the registration of the replacement mortgage or Homeowner ReadiLine®. I confirm that my client will have the replacement mortgage or Homeowner ReadiLine® with BMO. I undertake to advise BMO forthwith if the payout of the mortgage that I requested on my client’s behalf is no longer for the purpose of refinancing of my client’s existing mortgage with BMO.`;
    } else {
      this.acknowledgeMessage = '';
    }
  }

  acknowledgeCheckboxChange(event) {
    if (event.target.checked) {
      this.isAcknowledgeCheckboxChecked = true;
    } else {
      this.isAcknowledgeCheckboxChecked = false;
    }
  }

  close() {
    this.dialog.close();
  }

  async processSubmitPayoutRequest(): Promise<void> {
    await this.submitPayoutRequest();
  }

    validatePayoutRequest():void{
        this.modalErrorComponent.removeAllDpFieldError();
        if (this.acknowledgementOfConsent && !DpBooleanValueTypes.isTrue(this.acknowledgementOfConsent)) {
            this.modalErrorComponent.createDPFieldError('integrations.telusAssyst.acknowledgementConsentLC');
        }
        if(this.purposeOfPayout == 'OTHER' && Utils.isEmptyString(this.purposeOfPayoutOther)){
            this.modalErrorComponent.createDPFieldError('integrations.telusAssyst.payoutOtherLC');
        }
        if(this.context.matter && this.isLandTitleOfficeDropdownVisible() && !this.landRegistryOffice){
            this.modalErrorComponent.createDPFieldError('integrations.telusAssyst.landRegistryOffice');
        }
        if((this.purposeOfPayout == 'CDC_RELOCATION' || this.purposeOfPayout == 'REFINANCE_WITH_BMO') && !this.isAcknowledgeCheckboxChecked) {
            this.modalErrorComponent.createDPFieldError("integrations.telusAssyst.acknowledgementCheckboxLC");
        }
    }

    async submitPayoutRequest() : Promise<void>{
        this.validatePayoutRequest();
        if(!this.modalErrorComponent.anyErrorExist()){
            let payoutRequestData = this.createPayoutRequestData();
            this.telusService.submitPayoutRequest(this.solicitorContactId, this.context.matter.id ,this.mortgage.id, payoutRequestData).finally(() => {
                this.lockScreenService.lockForUpdate = false;
            }).subscribe((response :PayoutStatement) => {
                    this.dialog.close(response);
                },
                (error: ApplicationError) => {
                    const isAssyst = "Assyst Real Estate";
                    let errorMessage = error.message;
                    (errorMessage && errorMessage.includes(isAssyst)) ? errorMessage = 'A payout statement and discharge request for this mortgage has already been placed by another solicitor in Unity® Lender Centre. A new request cannot be placed until the original request has been cancelled.' : errorMessage;
                    this.dialogService.confirm('Error', errorMessage, true)
                }
            );
        }
    }


  createPayoutRequestData(): PayoutRequestData {
    let payoutRequestData: PayoutRequestData = new PayoutRequestData();
    payoutRequestData.mattterId = this.context.matter.id;
    payoutRequestData.mortgageId = this.mortgage.id;
    payoutRequestData.solicitorId = this.solicitorContactId;
    payoutRequestData.pinLegalDescriptions = this.createPinLegalDescriptions();
    payoutRequestData.purposeForPayout = this.purposeOfPayout;
    payoutRequestData.landRegistryOffice = this.constructLandRegistryOffice();
    if (this.purposeOfPayout == 'OTHER') {
      payoutRequestData.purposeOfPayoutOther = this.purposeOfPayoutOther;
    }
    payoutRequestData.acknowledgementOfConsent = this.acknowledgementOfConsent;
    return payoutRequestData;
  }

  createPinLegalDescriptions(): PinLegalDescription[] {
    return LegalDescriptionUtil.createAssystPayoutPinLegalDescriptions(this.matter);
  }

  get solicitorContactId(): number {
    if (this.context.matter.solicitor && this.context.matter.solicitor.contact && this.context.matter.solicitor.contact.id) {
      return this.context.matter.solicitor.contact.id;
    } else {
      return null;
    }
  }

    getModalHeader() : string {
        return this.context.payoutStatement && !this.context.payoutStatement.isCancelled() && !this.context.payoutStatement.isReturnedToSolicitor()? 'Cancel Payout Statement Request' : 'Unity® Lender Centre Payout Request Submission';
    }

    cancelPayoutRequest() : void {
        this.validatePayoutCancellation();
        if(!this.modalErrorComponent.anyErrorExist()){
            let payoutRequestData : PayoutRequestData = new PayoutRequestData();
            payoutRequestData.reasonForCancellation = this.reasonForCancellation;
            payoutRequestData.fileReferenceNumber = this.context.mortgage.loanNo;
            this.telusService.cancelPayoutRequest(payoutRequestData, this.solicitorContactId, this.context.matter.id, this.context.payoutStatement.id)
                .finally(() => { this.lockScreenService.lockForUpdate = false; })
                .subscribe((response :PayoutStatement) => {
                        this.dialog.close(response);
                    },
                    (error: ApplicationError) => {
                        if(error.errorCode === 'app.stewartAssystUserNotFoundForSolicitor') {
                            this.dialogService.confirm('Error', 'A Solicitor with Unity® Lender Centre Credentials is required', true);
                        } else {
                            this.dialogService.confirm('Error', error.message, true)
                        }
                    }
                );
        }
    }

    validatePayoutCancellation():void{
        this.modalErrorComponent.removeAllDpFieldError();
        if(this.isModalOpenForCancellation() && !this.reasonForCancellation) {
            this.modalErrorComponent.createDPFieldError('assyst.payout.request.reasonForCancellation');
        }
        if(!this.solicitorContactId) {
            this.modalErrorComponent.createDPFieldError('assyst.payout.request.assystCredentialsRequiredLC');
        }
    }

  isModalOpenForCancellation(): boolean {
    return this.context.payoutStatement && !this.context.payoutStatement.isCancelled() && !this.context.payoutStatement.isReturnedToSolicitor();
  }

  constructLandRegistryOffice(): string {
    if (this.context.matter) {
      switch (this.context.matter.provinceCode) {
        case PROVINCE_CODES.MANITOBA:
          return this.constructLandRegistryOfficeMB();
        case PROVINCE_CODES.ALBERTA:
          return 'Alberta Land Title Office';
        case PROVINCE_CODES.BRITISH_COLOMBIA:
        case PROVINCE_CODES.NEW_BRUNSWICK:
        case PROVINCE_CODES.NOVA_SCOTIA:
          return this.landRegistryOffice;
        case PROVINCE_CODES.ONTARIO:
          return this.constructLandRegistryOfficeON();
        default :
          break;
      }
    }
    return '';
  }

  constructLandRegistryOfficeMB(): string {
    if (this.context.matter.matterProperties && this.context.matter.matterProperties.length && this.context.matter.matterProperties[ 0 ].registryOfficeName) {
      return this.context.matter.matterProperties[ 0 ].registryOfficeName.replace('Titles', 'Title');
    }
    return '';
  }

  constructLandRegistryOfficeON(): string {
    if (this.context.matter && this.context.matter.matterPropertyWithCondo && this.context.matter.matterPropertyWithCondo.registryOfficeName) {
      let registryOffice = RegistryOfficeNames.find(item => item.name.toLowerCase() == this.context.matter.matterPropertyWithCondo.registryOfficeName.toLowerCase());
      if (registryOffice) {
        return registryOffice.newName;
      } else {
        return this.context.matter.matterPropertyWithCondo.registryOfficeName
        .replace('NO.', '')
        .replace('No.', '')
        .replace('no.', '')
        .replace('( ', '(')
        .split('Registry')
        .join('Registry Office');
      }
    }
    return '';
  }

  isLandTitleOfficeDropdownVisible() : boolean {
    return this.context.matter?.provinceCode &&
      [PROVINCE_CODES.BRITISH_COLOMBIA, PROVINCE_CODES.NOVA_SCOTIA, PROVINCE_CODES.NEW_BRUNSWICK].includes(this.context.matter?.provinceCode);
  }
}
