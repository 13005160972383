<div class="dppm-md-loading-bg" *ngIf="((percentageLoading < 100 || !isAllModuleLoaded) && isLoaderInitialize)">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="loading-box">
                <span class="unity-logo"></span>
                <span class="unity-progress-box" >
                            <div class="unity-progress-bar"></div>
                          <div class="unity-percentage-bar" [style.right]="progressBarLoading + '%'"></div>
                </span>
                <span class="loading-text">
                    Please wait while the application is starting..
                </span>
            </div>
        </div>
    </div>
</div>
<div [hidden]="((percentageLoading < 100 || !isAllModuleLoaded) && isLoaderInitialize)">
<div>
    <div>
        <router-outlet #route="outlet"></router-outlet>
    </div>
    <notifier-container></notifier-container>
</div>