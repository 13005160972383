import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[ngModel][dp-onlynumeric]',
  host: {
    '(keypress)': 'onInputChange($event)'
  }
})
export class OnlyNumericDirective {

  @Input('dp-onlynumeric') digitOnly: boolean = false;

  onInputChange($event): void {

    let keyCode: number = $event.keyCode;
    switch (keyCode) {
      case 8 : //backspace
      case 127: //delete code is 127 not 46. In fact 46 is dot
        return;
      default://for other key, need to check whether it is number or not
    }
    const pattern = this.digitOnly ? /[0-9]/ : /[0-9\.\ ]/;
    let inputChar = String.fromCharCode($event.charCode);
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
    }
  }
}
