<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo"></span></a>
            </div>
        </div>
        <div class="trusted-phone-box">
            <div class="text">
                <div  *ngIf="isPhoneNumberRequired() && !token">
                    <div class="col-lg-12 padding-bottom-30">
                        <div class="col-lg-9  line-height-1-4">
                            <p class="padding-bottom-10">The protection and security of your firm and customer information is our top priority. To provide you
                                with maximum
                                security Unity&reg; Practice Management includes Two-Factor Authentication as part of your Unity&reg; Practice Management user profile.</p>
                            <p class="padding-bottom-10">What is Two-Factor Authentication? ...it's a combination of two things:</p>
                            <ul class="padding-bottom-10 padding-left-20" style="list-style-type:circle">
                                <li>Something you know - like your login or your password, and</li>
                                <li>Something you have - like a one-time code from your cell phone</li>
                            </ul>
                            <p>Unity&reg; Practice Management requires you to store a trusted cell phone number to which it can text a random numeric code.
                                You will then enter that code into Unity&reg; Practice Management.  This enhances security as even if someone pretending
                                to be you has the right user ID and password they would not have your phone and your account is still protected.
                                The additional step will only occur for certain actions such as using a new computer or if you have forgotten your password.</p>

                        </div>
                    </div>

                    <div class="col-lg-12 padding-bottom-20">
                        <div class="col-lg-9">
                            Please enter a cell phone number to be saved that can receive text messages and select Send Passcode
                        </div>
                    </div>

                    <div class="col-lg-12 padding-bottom-10">
                        <div class="col-lg-6 ">
                            <p class="phone-banner">Trusted Phone Number</p>
                        </div>
                    </div>


                    <div class="col-lg-12 padding-bottom-10">
                        <div class="col-lg-2 ">
                            <input name="phoneNum" id="phoneNum" type="text"
                                   class="form-control focus-first"
                                   dp-phone-number
                                   [dp-read-only]="isSendPasscodeTimeout"
                                   [(ngModel)]="typedPhoneNumber"
                                   (keyup.enter)="submitPhoneNumber()"
                            />
                        </div>
                    </div>

                    <div class="col-lg-12 margin-bottom-20">
                        <div class="col-lg-9 ">
                            <button type="button" class="dp-btn"
                                    [disabled]="isSendPasscodeTimeout || !typedPhoneNumber || (typedPhoneNumber && typedPhoneNumber.length < 10)"
                                    (click)="submitPhoneNumber()">
                                Send Passcode
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isPhoneNumberRequired()">
                    <div class="col-lg-12 padding-bottom-30">
                        <div class="col-lg-9  line-height-1-4">
                            <p *ngIf="!token || (token && validToken)">
                                {{passcodeTitle}}
                            </p>

                            <p *ngIf="token && (!validToken || lockUserFlag)">
                                {{registerTokenErrorMsg}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="!token || (token && validToken && !lockUserFlag)" class="col-lg-12 margin-bottom-20">
                        <div *ngFor="let phone of phoneNumbers" class="col-lg-9 padding-bottom-5" >
                            <button type="button" class="dp-btn"
                                    [disabled]="isSendPasscodeTimeout"
                                    (click)="selectPhoneNumber(phone.telephoneNumber)">
                                Send Passcode to ******{{phone.telephoneNumber}}
                            </button>
                        </div>

                    </div>
                </div>
                <div *ngIf="!token && phoneNumbers && phoneNumbers.length" class="col-lg-12">
                    <div class="col-lg-9 padding-bottom-5 contact_admin">
                        <a (click)="contactAdmin()">Don't have your phone?</a>
                    </div>
                </div>
                <div class="col-lg-12 margin-bottom-20">
                    <div class="col-lg-9 line-height-1-4">
                                    <div>{{passcodeText}}</div>
                    </div>
                </div>
                <div *ngIf="isPasscodeVisible()">
                    <div class="col-lg-12 padding-bottom-10">
                        <div class="col-lg-6 ">
                            <p class="phone-banner">Passcode</p>
                        </div>
                    </div>


                    <div class="col-lg-12 padding-bottom-10">
                        <div class="col-lg-2 ">
                            <input name="code" id="code" type="text"
                                   class="form-control"
                                   [(ngModel)]="passcode"
                                   (keyup.enter)="verifyAuthChallenge()"
                                   [disabled]="!selectedPhoneNumber && !contactAdminstrator"
                            />
                        </div>
                    </div>
                    <div *ngIf="errorFlag" class="trusted-phone-error-msg padding-bottom-15">
                         <span class="trusted-phone-error-img"></span>{{errorMessage}}
                    </div>
                    <div *ngIf="!token" class="col-lg-12 padding-bottom-20">
                        <div class="col-lg-12">
                            <dp-checkbox fieldId="trusted">
                                <input id="trusted"
                                       name="trusted"
                                       type="checkbox"
                                       [(ngModel)]="trustedBrowser"
                                       #rememberMe
                                />
                            </dp-checkbox>
                            <label class="control-label padding-left-10 display-inline" (click)="trustedBrowser=!trustedBrowser" >
                                Select this checkbox if this is a trusted device in a secure location.
                            </label>
                        </div>
                        <div class="col-lg-12 smallerFont padding-top-10 padding-left-10">
                            Note: Marking this device as trusted will prevent Unity&reg; Practice Management from requiring two-step verification for 180 days.
                        </div>

                    </div>
                    <div class="col-lg-12 margin-bottom-40 padding-bottom-20">
                        <div  class="col-lg-9 ">
                            <button type="button" class="dp-btn" [disabled]="!passcode"
                                    (click)="verifyAuthChallenge()">
                                Submit Passcode
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>
<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>
