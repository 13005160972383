<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Change {{matterNoLabel}}.</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 padding-bottom-10">
            <div class="col-lg-4">
                <label class="control-label right"
                       for="matterNo">
                    New {{matterNoLabel}}.
                </label>
            </div>
            <div class="col-lg-6">
            <input type="text"
                   id="matterNo"
                   name="matterNo"
                   class="form-control"
                   [fieldKey]="!newMatterNo ? 'matter.matterOpening.matterNo' : 'matter.matterOpening.uniqueMatterNo'"
                   [(ngModel)]="newMatterNo"
                   maxlength="25"
                   required
                   dp-uppercase-preserve-cursor-position
                   dp-matterNoFilter
                   dp-error-validator
            />
            </div>
        </div>


    </div>

    <div class="row modal-footer">
        <div class="offset-lg-6 col-lg-6 buttons matter-button-center">
            <button type="button" id="okBtn" class="dp-btn" (click)="submit()" [attr.disabled]="isValid? null: true">
                <span>Ok</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
