import {Component, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SoAdjTaxOther} from '../model/so-adj-tax-other';
import {SelectItem} from 'primeng/api';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class TaxOtherCostModalContext {
  closingDate: string;
  leftColumnListsCreditsTo: string;
  rightColumnListsCreditsTo: string;
  hstRate: number;
  gstRate: number;
  taxOther: SoAdjTaxOther;
  taxRateType: string;
  isMatterProvinceMBorSK: boolean;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-tax-other-cost-modal-content',
  templateUrl: './tax-other-cost.modal.component.html',
  providers: [ErrorService]
})
export class TaxOtherCostModalComponent extends ModalComponent<TaxOtherCostModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  taxOther: SoAdjTaxOther;
  creditTypeDropDown: SelectItem[];
  chargeTypeDropDown: SelectItem[];
  taxTypeDropDown: SelectItem[];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TaxOtherCostModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TaxOtherCostModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.initDropDowns();

    if (!this.context.gstRate) {
      this.context.gstRate = 5;
    }
    if (!this.context.hstRate) {
      this.context.hstRate = 13;
    }
    this.initLocalTaxOther(this.context.taxOther);
  }

  ok(): void {
    if (this.noErrors()) {
      this.taxOther.isVendorSelected = this.taxOther.creditType == this.context.rightColumnListsCreditsTo;
      this.dialog.close({taxOther: this.taxOther});
    }
  }

  noErrors(): boolean {
    let rslt: boolean = true;
    this.modalErrorComponent.removeAllDpSaveError();

    if (this.taxOther.cost == 0) {
      this.modalErrorComponent.createDPSaveError('soa.tax.other.cost');
      rslt = false;
    }
    if (!this.taxOther.adjustmentHeading) {
      this.modalErrorComponent.createDPSaveError('soa.tax.other.heading');
      rslt = false;
    }
    return rslt;
  }

  cancel(): void {
    this.dialog.close();
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close({taxOther: undefined});
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  creditTypeChange(): void {
    this.taxOther.isVendorSelected = this.taxOther.creditType == this.context.rightColumnListsCreditsTo;
  }

  chargeTypeChange(): void {}

  taxTypeChange(event) {
    if (event === 'GST') {
      this.taxOther.percentage = this.context.gstRate;
    } else {
      this.taxOther.percentage = this.context.hstRate;
    }
    this.taxOther.taxType = event;
    this.chargeTypeDropDown[0].label = this.taxOther.taxType + ' Only';
    this.chargeTypeDropDown[1].label = 'Cost + ' + this.taxOther.taxType;
  }

  public initLocalTaxOther(taxOther: SoAdjTaxOther) {
    if (!taxOther) {
      this.taxOther = new SoAdjTaxOther();
      this.taxOther.creditType = this.context.rightColumnListsCreditsTo;
      this.taxOther.adjustmentHeading = '';
      this.taxOther.taxType = this.context.taxRateType;
      this.taxOther.percentage = this.context.isMatterProvinceMBorSK ? this.context.gstRate : this.context.hstRate;
      this.taxOther.cost = 0;
      this.taxOther.chargeType = 'COST_PLUS_TAX';
      this.newAdj = true;
    } else {
      this.taxOther = new SoAdjTaxOther(taxOther);
    }
    this.chargeTypeDropDown[0].label = this.taxOther.taxType + ' Only';
    this.chargeTypeDropDown[1].label = 'Cost + ' + this.taxOther.taxType;
    this.taxOther.isVendorSelected = this.taxOther.creditType === this.context.rightColumnListsCreditsTo;
  }

  public initDropDowns() {
    this.creditTypeDropDown = [
      {
        label: this.context.leftColumnListsCreditsTo,
        value: this.context.leftColumnListsCreditsTo
      },
      {
        label: this.context.rightColumnListsCreditsTo,
        value: this.context.rightColumnListsCreditsTo
      }
    ];

    this.chargeTypeDropDown = [
      {
        label: 'Tax Only',
        value: 'TAX_ONLY'
      },
      {
        label: 'Cost + Tax',
        value: 'COST_PLUS_TAX'
      }
    ];

    if (this.context.hstRate == this.context.gstRate) {
      this.taxTypeDropDown = [{label: this.context.taxRateType, value: this.context.taxRateType}];
    } else {
      this.taxTypeDropDown = [
        {
          label: this.context.taxRateType,
          value: this.context.taxRateType
        },
        {
          label: 'GST',
          value: 'GST'
        }
      ];
    }
  }
}
