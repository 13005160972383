<div class="form-group fields-group margin-bottom-0">
    <div class="col-lg-12 padding-top-20 margin-bottom-0">
        <div class="main-level">
            <span class="title min-height-30">{{ isContactProjectTransferor ? 'Third Party Transferors': 'Guarantor(s)' }}</span>
        </div>
    </div>
</div>

<div class="fields-group">
    <div id="mattermortgages1stmortgageeterms_container" class="field_code_accordion_container" *ngIf="getGuarantorsOrTransferors()">
        <div *ngFor="let guarantor of getGuarantorsOrTransferors(); let i=index" style="padding-bottom: 40px;"
             [ngClass]="{'shutter-bg': isExpanded(i), 'shutter-bg-closed': !isExpanded(i)}">
            <div class="shutter-arrow" (click)="toggleGuarantorShutter(i)">
                <div *ngIf="isExpanded(i)"><img src="/assets/img/minus.svg" alt=""/></div>
                <div *ngIf="!isExpanded(i)"><img
                    src="/assets/img/plus.svg"/></div>
            </div>

            <div class="col-lg-2 text-right" (click)="toggleGuarantorShutter(i)"
                 [class.focus]="fieldHoverIndex===11">
               <label class="control-label"> {{ isContactProjectTransferor ? 'Transferor ': 'Guarantor ' }}  {{ ( i+1 ) }}</label>
            </div>
            <div class="col-lg-10">
                <input type="text" id="guarantorFieldIndex_{{guarantor?.identifier}}" name="guarantorText{{i+currentTimeStamp}}"
                       class="form-control field_code_accordion_input"
                       (click)="toggleGuarantorShutter(i);"
                       (keydown.f9)="toggleGuarantorShutter(i);"
                       [ngModel]="guarantor.contact.fullName"
                       [fieldKey]="'mortgage.term.guarantor.shutter'"
                       statusBarHelp
                       readonly/>
                <!-- Action buttons -->
                <div class="actionbtns">
                    <div class="width-80">
                                        <span *ngIf="showIdWarningForGuarantor(guarantor) && !isContactProjectTransferor">
                                            <i class="fa fa-warning warning-term" (click)="onClickIdWarningIcon(i, 'idDetails', guarantor)"></i>
                                            <span class="warning-id-text">ID</span>
                                        </span>
                    </div>
                    <div class="width-20  padding-top-0">
                        <dp-burger-menu  *ngIf="!!guarantor"
                                         [items]="getGuarantorBurgerMenuItems(i, getGuarantorsOrTransferors().length)"
                                         (itemClicked)="clickGuarantorBurgerMenu($event, guarantor)">
                        </dp-burger-menu>
                    </div>
                </div>
                <!-- //END -- Action buttons -->
            </div>
            <div class="form-group" [hidden]="!isExpanded(i)">
                <div class="col-lg-2"></div>
                <div class="col-lg-10">
                    <ul class="dppm-custom-tab margin-top-20 margin-bottom-20">
                        <li [ngClass]="{'active' : selection === 'profile'}" (click)="selectTab('profile', guarantor)"><a >Profile</a></li>
                        <li *ngIf="!isContactProjectTransferor" [ngClass]="{'active' : selection === 'idDetails'}"
                            (click)="selectTab('idDetails', guarantor)"><a >ID Verification</a></li>
                    </ul>
                    <div class="clear"></div>
                    <dp-new-manage-contact  *ngIf="selection === 'profile' && isExpanded(i) && !isContactProjectTransferor"
                                           [contactKey]="'GUARANTOR'"
                                           [participant]="guarantor"
                                           [fieldCodeMap]="getFieldCodeMapForGuarantor('LAST_NAME',guarantor)"
                                           [matter]="matter"
                                           [contact]="guarantor.contact"
                                           (onDataChange)="onGuarantorChange(guarantor.contact)"
                                           [fieldPrefix]="'matter.mortgages.1st.mortgagee.terms'"
                                           [fieldIdentifier]="guarantor?.identifier"
                                           [fieldIndexId]="'guarantorFieldIndex'"
                                           [containerId]="'mattermortgages1stmortgageeterms_container'"
                    ></dp-new-manage-contact>

                    <dp-new-manage-contact  *ngIf="selection === 'profile' && isExpanded(i) && isContactProjectTransferor"
                                            [contactKey]="'TRANSFEROR'"
                                            [participant]="guarantor"
                                            [matter]="matter"
                                            [contact]="guarantor.contact"
                                            (onDataChange)="onGuarantorChange(guarantor.contact)"
                                            [fieldPrefix]="'project.docsReg.transferor'"
                                            [fieldIdentifier]="guarantor?.identifier"
                                            [fieldIndexId]="'guarantorFieldIndex'"
                                            [containerId]="'mattermortgages1stmortgageeterms_container'"
                    ></dp-new-manage-contact>

                    <!--<dp-contact-id-details *ngSwitchCase="'ID-Details'" [contact]="contact"  [contactKey]="context.contactType" ></dp-contact-id-details>-->
                    <dp-contact-id-details *ngIf="selection === 'idDetails' && isExpanded(i) && !isContactProjectTransferor"
                                           (onDataChange)="enableSave()"
                                           [provinceCode]="getMatterProvince()"
                                           [contact]="guarantor.contact" [contactKey]="'GUARANTOR'"></dp-contact-id-details>
                </div>

            </div>
        </div>

    </div>

    <div class="form-group">
        <div class="col-lg-10" *ngIf="!getGuarantorsOrTransferors() || getGuarantorsOrTransferors().length == 0">
            <span class="selector fa fa-plus-square-o"></span> <span class="actionlink"><a href="javascript:void(0);" (click)="addGuarantor()"> Add
                 {{ isContactProjectTransferor ? 'Transferor ': 'Guarantor ' }}
                </a></span>

        </div>
        <div class="col-lg-10 offset-lg-2" *ngIf="getGuarantorsOrTransferors().length > 0">
                <span class="actionlink">
                    <span class="margin-right-20"
                          *ngIf="getGuarantorsOrTransferors().length > 0 && getGuarantorsOrTransferors().length < appConfig.maxNumberOfGuarantor">

                        <a href="javascript:void(0);" (click)="addGuarantor()">Add Another  {{ isContactProjectTransferor ? 'Transferor ': 'Guarantor ' }} </a> <span class="selector fa fa-plus-square-o"></span>
                    </span>
                    <span  *ngIf="!isContactProjectTransferor && !matter.isTemplateMatterForMassUpdate">
                        <a href="javascript:void(0);" (click)="onClickPrintIdForms()">Produce ID Forms and Declarations</a>
                    </span>

                </span>
        </div>

        <div class="col-lg-10 offset-lg-2" *ngIf="getGuarantorsOrTransferors().length > 0 && !isContactProjectTransferor">
            <dp-checkbox fieldId="guarantorPartyToMortgage">
                <input type="checkbox" id="guarantorPartyToMortgage" (change)="enableSave()" name="guarantorPartyToMortgage"
                       [(ngModel)]="mortgage.guarantorPartyToMortgage"/>
            </dp-checkbox>
            <label class="control-label">
                Show Guarantor(s) as party to the mortgage
            </label>
        </div>
    </div>
</div>
