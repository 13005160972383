import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoaHeat} from '../model/heat-soa';

export class StatementAdjustmentDisplayHeatBuilder {

  static getDisplayItems(item: SoaHeat, mainBuilder: StatementAdjustmentDisplayBuilder, toBeUsedOnModal: boolean = false): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    if (item.heatType == 'GAS' || item.heatType == 'ELECTRIC') {
      // sadItemDetails.push(mainBuilder.getNewItemLine(`${toBeUsedOnModal ? 'Metered' : 'METERED'} - No Adjustment`, ``));
      sadItemDetails.push(mainBuilder.getNewItemLine(`Metered - No Adjustment`, ``));
    } else {
      sadItemDetails.push(mainBuilder.getNewItemLine(
        `${ mainBuilder.getDecimal(item.tankCapacity) } ${ item.heatMeasurementSingular } ${ item.heatTypeShortLabel } tank`,
        ``)
      );

      sadItemDetails.push(mainBuilder.getNewItemLine(
        `at ${ mainBuilder.getCurrency(item.costPerLitre, 4) } per ${ item.heatMeasurementSingular }`,
        mainBuilder.getCurrency(item.costPerLitre * item.tankCapacity)
      ));

      if (item.adjustForCarbonTax === 'YES') {
        sadItemDetails.push(mainBuilder.getNewItemLine(
          `Carbon tax at ${ mainBuilder.getCurrency(item.carbonTaxAmount, 4) } per ${ item.heatMeasurementSingular }`,
          mainBuilder.getCurrency(item.totalCarbonTax)
        ));
      }

      if (item.adjustForCarbonTax === 'YES' && item.adjustFor == 'YES') {
        sadItemDetails.push(mainBuilder.getNewItemLine(
          `Total amount subject to ${ item.adjustType }`,
          mainBuilder.getCurrency(this.getTotalAmount(item))
        ));
      }

      if (item.adjustFor == 'YES') {
        sadItemDetails.push(mainBuilder.getNewItemLine(
          `${ item.adjustType } calculated at ${ mainBuilder.getPercentage(item.percentage) }`,
          mainBuilder.getCurrency(item.tax)
        ));
      }

      sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ mainBuilder.vendorLabel }`,
        toBeUsedOnModal ? `${ mainBuilder.getCurrency(item.creditVendor) }` : '',
        true));
    }
    return sadItemDetails;
  }

  static getTotalAmount(soaHeat: SoaHeat) {
    return Number(soaHeat.totalCarbonTax + (soaHeat.costPerLitre * soaHeat.tankCapacity));
  }
}
