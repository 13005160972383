import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import Utils from '../../../app/shared-main/utils';
import {WindowRef} from '../../../app/shared/window.ref';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class DocusignPortalContext {
  title: string;
  securedUrl: string;
}

@Component({
  selector: 'dp-docusign-portal-modal',
  templateUrl: 'docusign-portal.modal.component.html'
})
export class DocusignPortalModalComponent extends ModalComponent<DocusignPortalContext> implements OnInit {
  docuSignUrl: SafeUrl;

  @ViewChild('docuSignFrame') docuSignFrame: ElementRef;

  constructor(
    public dialog: MatDialogRef<DocusignPortalModalComponent>,
    public sanitizer: DomSanitizer,
    public winRef: WindowRef,
    @Inject(MAT_DIALOG_DATA) context?: DocusignPortalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.docuSignUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
    this.registerDocuSignCallbackMethod();
  }

  registerDocuSignCallbackMethod() {
    this.winRef.nativeWindow.docuSignCallback = (redirectUrl) => {
      const event = Utils.extractQueryParamFromUrl(redirectUrl, 'event');
      const envelopeId = Utils.extractQueryParamFromUrl(redirectUrl, 'envelopeId');
      this.dialog.close({event: event, envelopeId: envelopeId});
    };
  }
}
