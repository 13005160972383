import {api} from '../common/api';

export const eventApi = {

  events(accountId: string): string {
    return `${ api }/accounts/${ accountId }/matterEvents`;
  },
  createEvent(matterId: string): string {
    return `${ api }/matters/${ matterId }/matterEvents`;
  },
  createStaffEvent(accountId: string): string {
    return `${ api }/accounts/${ accountId }/matterEvents`;
  },
  matterEvent(matterId: string, eventId: string): string {
    return `${ api }/matters/${ matterId }/matterEvents/${ eventId }`;
  },
  event(accountId: string, eventId: string): string {
    return `${ api }/account/${ accountId }/matterEvents/${ eventId }`;
  },
  updateStaffEvent(accountId: string, eventId: string): string {
    return `${ api }/accounts/${ accountId }/matterEvents/${ eventId }`;
  },
  deleteEvent(matterId: string, eventId: string): string {
    return `${ api }/matters/${ matterId }/matterEvents/${ eventId }`;
  },
  deleteStaffEvent(accountId: string, eventId: string): string {
    return `${ api }/accounts/${ accountId }/matterEvents/${ eventId }`;
  },
  exportMatterEvent(matterId: string, eventId: string): string {
    return `${ api }/matters/${ matterId }/matterEvents/${ eventId }/calendar`;
  },
  exportStaffEvent(accountId: string, eventId: string): string {
    return `${ api }/accounts/${ accountId }/matterEvents/${ eventId }/calendar`;
  },
  downloadIcsFile(matterId: string, inviteGuid: number): string {
    return `${ api }/events/${ inviteGuid }/calendar/download`;
  },
  docuSignPortalUrl(matterId: string, eventId: number): string {
    return `${ api }/matters/${ matterId }/matterEvents/${ eventId }/envelopes/viewUrl`;
  },
  syngrafiiEditLink(matterId: string, eventId: number): string {
    return `${ api }/matters/${ matterId }/matterEvents/${ eventId }/syngrafiiEditLink`;
  },
  syngrafiiVideoLinks(matterId: string, eventId: number): string {
    return `${ api }/matters/${ matterId }/matterEvents/${ eventId }/syngrafiiDownloadLinks`;
  },
  eventByStaff(accountId: string, solicitorId: number): string {
    return `${ api }/accounts/${ accountId }/${ solicitorId }/events`;
  },
  totalTransactionCount(accountId: string): string {
    return `${ api }/accounts/${ accountId }/matterEvents/totalTransactionCount`;
  }
};

