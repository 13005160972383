import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TeranetUser extends BaseEntity {
  id: number;
  userAccountProfileId: number;
  teranetAccountName: string;
  teranetLoginName: string;
  teranetPassword: string;
  teranetBalance: number;
  teranetChangePassword: string;

}
