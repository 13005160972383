<div class="aibot-container__header">
  <div class="headericon">
    <span class="material-symbols-outlined"> robot_2 </span>
  </div>
  <div class="aibot-container__header__textHeader">
    <p class="heading">DeeDee</p>
    <p class="subheading">
      Your AI Assistant <span class="preview preview__heading">Preview</span>
    </p>
  </div>
  <div class="aibot-container__header__actionIconsDiv">
    <div class="infoIcon" *ngIf="!isMinimized">
      <span
        data-bs-toggle="infoIcon"
        (click)="infoBtnClick()"
        class="aiHeaderBtn material-symbols-outlined"
        >info</span
      >
      <ul *ngIf="showInfoLinks" class="dropdown-menu">
        <li>
          <a class="dropdown-item" (click)="openLicenseTerms()">
            <span>Terms and Conditions</span>
          </a>
        </li>
        <li>
          <a class="dropdown-item" (click)="downloadFAQ(faqDocumentLink)">
            <span>FAQs</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="resizeIcon">
      <span
        *ngIf="isMinimized"
        (click)="ResizeModal()"
        class="aiHeaderBtn material-symbols-outlined"
        >open_in_full</span
      >
      <span
        *ngIf="!isMinimized"
        (click)="ResizeModal()"
        class="aiHeaderBtn material-symbols-outlined"
        >close_fullscreen</span
      >
    </div>
    <div class="closeIcon">
      <span
        (click)="closeModal()"
        class="aiHeaderBtn material-symbols-outlined"
      >
        close
      </span>
    </div>
  </div>
</div>
<div
  [ngClass]="{
    'aibot-container__aibot-content': !isMinimized,
    'aibot-container__aibot-content-minimized': isMinimized
  }"
>
  <div class="responseBox" (scroll)="scrolled($event)" #allmessages>
    <ng-container *ngFor="let message of messages">
      <div
        class="time"
        [ngClass]="{
          from: message.author === 'bot',
          to: message.author === 'user'
        }"
      >
        {{ message.timenow }}
      </div>
      <div
        class="whole-message"
        [ngClass]="{
          from: message.author === 'bot',
          to: message.author === 'user'
        }"
      >
        <span class="material-symbols-outlined"> robot_2 </span>
        <div
          class="message"
          [ngClass]="{
            from: message.author === 'bot',
            to: message.author === 'user'
          }"
        >
          {{ message.content }}

          <div
            [ngClass]="{
              sentiment: message.isAIResponseMessage,
              'sentiment-on-welcomemessage': !message.isAIResponseMessage
            }"
          >
            <hr />
            <div class="sentiment__feedback">
              <div
              (click)="
                setPositiveUserSentiment(
                  message.partitionkey,
                  message.rowkey,
                  message.userSentiment
                )
              "
              (mouseenter)="setTooltipHoverState(message, 'up', true)"
              (mouseleave)="setTooltipHoverState(message, 'up', false)"
              >
                <span><i
                  class="fa"
                  [ngClass]="{
                    'fa-thumbs-up': message.userSentiment === 'positive',
                    'fa-thumbs-o-up':
                      !message.userSentiment ||
                      message.userSentiment === 'negative'
                  }"
                  aria-hidden="true"
                ></i
              ></span>
                <div *ngIf="message.showGoodResponseTooltip" class="goodresponse-tooltip">
                  Good Response
                </div>
              </div>

              <div
              (click)="
                setNegativeUserSentiment(
                  message.partitionkey,
                  message.rowkey,
                  message.userSentiment
                )
              "
              (mouseenter)="setTooltipHoverState(message, 'down', true)"
              (mouseleave)="setTooltipHoverState(message, 'down', false)"
              >
                <span><i
                  class="fa"
                  [ngClass]="{
                    'fa-thumbs-down': message.userSentiment === 'negative',
                    'fa-thumbs-o-down':
                      !message.userSentiment ||
                      message.userSentiment === 'positive'
                  }"
                  aria-hidden="true"
                ></i
              ></span>
                <div *ngIf="message.showBadResponseTooltip" class="badresponse-tooltip">
                  Bad Response
                </div>
              </div>
              <div (click)="CopyToClipboard(message)" class="material-symbols-outlined"
                (mouseenter)="setTooltipHoverState(message, 'copy', true)"
                (mouseleave)="setTooltipHoverState(message, 'copy', false)">
                <span>
                  copy_all
                </span>
                  <div class="parent-container">
                      <div *ngIf="message.showCopyTooltip" class="copy-tooltip">
                          {{ copyMessage }}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="isLoading" class="whole-message from">
      <span class="material-symbols-outlined"> robot_2 </span>
      <div class="loadingmessage from">
        <span class="loading"></span>
      </div>
    </div>
  </div>
</div>
<div
  [ngClass]="{
    'input-group': !isMinimized,
    'input-group-minimized': isMinimized
  }"
>
  <textarea
    #aiQuery
    class="form-control custom-control"
    rows="1"
    style="resize: none"
    placeholder="Type a message"
    (keyup.enter)="CallAIAssitant()"
    (focus)="onQueryTextFocus()"
  ></textarea>
  <svg
    (click)="CallAIAssitant()"
    style="cursor: pointer; margin: 10px 15px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3.51 6.03L11.02 9.25L3.5 8.25L3.51 6.03ZM11.01 14.75L3.5 17.97V15.75L11.01 14.75ZM1.51 3L1.5 10L16.5 12L1.5 14L1.51 21L22.5 12L1.51 3Z"
      fill="#41484D"
    />
  </svg>
</div>
