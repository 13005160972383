import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {ErrorService} from '../../shared/error-handling/error-service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {Logger} from '@nsalaun/ng-logger';
import {Component, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ApplicationError} from '../../core/application-error';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {FieldError} from '../../core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class TestTeranetUserCredentialModalContext {
  teranetUser: TeranetUser;
  accountId: string;
  userId: string;
}

@Component({
  selector: 'dp-test-teranet-user-credential-modal',
  templateUrl: 'test-teranet-user-credential.modal.component.html',
  styleUrls: ['./manage-teranet-user.scss'],
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class TestTeranetUserCredentialModal extends ModalComponent<TestTeranetUserCredentialModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  password: string = '';

  constructor(
    public dialog: MatDialogRef<TestTeranetUserCredentialModal>,
    public logger: Logger,
    public errorService: ErrorService,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TestTeranetUserCredentialModalContext
  ) {
    super(dialog, context);
  }

  get teranetUser(): TeranetUser {
    return this.context.teranetUser;
  }

  testCredentials(): void {
    this.modalErrorComponent.removeAllDpFieldError();
    this.teranetUser.teranetPassword = this.password;
    this.teranetService.getTeranetBalance(this.teranetUser).subscribe(
      (teranetResult: TeranetUser) => {
        this.dialog.close({action: 'Success'});
      },
      (error: ApplicationError) => {
        if (this.getExpiredPassword(error)) {
          this.dialog.close({
            action: 'PASSWORD_EXPIRED',
            teranetUser: this.teranetUser
          });
        } else if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
          error.fieldErrors.forEach((fieldError: FieldError) => {
            this.modalErrorComponent.createCustomDPFieldError(
              fieldError.errorCode,
              fieldError.errorCode + ':' + fieldError.message,
              null,
              'ERROR'
            );
          });
        } else {
          this.modalErrorComponent.createCustomDPFieldError(
            error.errorCode,
            error.errorCode + ':' + error.errorMessage,
            null,
            'ERROR'
          );
        }
      }
    );
  }

  getExpiredPassword(error: any): boolean {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      return error.fieldErrors.find((fieldError: FieldError) => fieldError.errorCode === 'DAW21045');
    }
    return false;
  }

  cancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  ngAfterViewInit() {}
}
