import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalErrorComponent} from './modal-error/modal-error.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ModalErrorComponent
  ],
  exports: [
    ModalErrorComponent
  ],
  providers: []
})
export class DPErrorModule {
}
