import {soaDropDowns} from './soa-drop-downs';
import {Component, DoCheck, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DocumentProfileSoa} from './document-profile-soa';
import {DocumentProfileService} from '../document-profile.service';
import {DocumentProfile} from '../../document-profile';
import {SelectItem} from 'primeng/api';
import {Account} from './../../../accounts/shared/account';
import {ActivatedRoute} from '@angular/router';
import {TabsService} from '../../../../core/tabs.service';
import {AdminTab} from '../../../admin-tab';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {Subscription} from 'rxjs/Subscription';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {ConsiderationTaxes} from '../../../../matters/consideration-ltt/consideration-taxes';
import {TaxRateService} from '../../../../matters/consideration-ltt/tax-rate.service';
import {AccountService} from '../../../accounts/account.service';
import {PROVINCE_CODES} from '../../../../matters/shared/user-province';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import {PurchaserVendorOptions} from '../../../../matters/mortgages/mortgage/dropdown-options';

@Component({
  selector: 'dp-document-profile-soa',
  templateUrl: 'document-profile-soa.component.html'
})

@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class DocumentProfileSoaComponent implements OnInit, DoCheck {

  documentProfile: DocumentProfile;
  soa: DocumentProfileSoa;

  soaForm: FormGroup;

  soaDefault: DocumentProfileSoa;
  yesNoOptions: SelectItem[];
  paperSizes: SelectItem[];
  otherHeadingVendorNames: SelectItem[];
  otherHeadingsPurchaserNames: SelectItem[];
  addressData: SelectItem[];
  adjustDate: SelectItem[];
  preferredTerminology: SelectItem[];
  showNetSalePriceInAdjustmentOptions: SelectItem[] = booleanYesNoDropDowns.YesNoOnly;
  account: Account;
  copyDefaultProfileEnabled: boolean = false;

  saveSubscription: Subscription;
  routeSubscription: Subscription;
  routeParentSubscription: Subscription;
  taxRateType: string;
  paysForDateOfClosing: SelectItem[] = PurchaserVendorOptions;

  constructor(public formBuilder: FormBuilder,
              public route: ActivatedRoute,
              public taxRateService: TaxRateService,
              public tabsStateService: TabsService,
              public documentProfileService: DocumentProfileService, public accountService: AccountService) {
  }

  get activeAdminTab(): AdminTab {
    return this.tabsStateService.activeTab as AdminTab;
  }

  initializeAccount(): void {
    if (this.activeAdminTab.account) {
      this.account = this.activeAdminTab.account;
    } else {
      let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      this.accountService.getAccount(loggedUserId).subscribe((account: Account) => {
        this.account = new Account(account);
      });
    }
  }

  public initializeDocumentProfile() {
    this.documentProfile = this.activeAdminTab.documentProfile;
    this.soa = this.activeAdminTab.documentProfile.statementOfAdjustmentsProfile;
    this.copyDefaultProfileEnabled = this.soa.id == null || this.soa.sameAsDefaultProfileFlag;
  }

  ngOnInit(): void {

    this.initializeDocumentProfile();

    //this is helpful when switching between tabs that have the same section opened
    this.routeParentSubscription = this.route
    .parent
    .params
    .subscribe(() => {
      this.initializeDocumentProfile();
    });

    this.routeSubscription = this.route
    .params
    .subscribe(() => {
      // listens for params changes  when it's not a parent component
      this.initializeAccount();

    });

    this.soaForm = this.formBuilder.group({
      sameAsDefaultProfileFlag: {value: this.soa.sameAsDefaultProfileFlag},
      printBalanceFlag: {value: this.soa.printBalanceFlag},
      includeVendorHstFlag: {value: this.soa.includeVendorHstFlag},
      paperSizeCode: {value: this.soa.paperSizeCode},
      directionOfFundsPaperSizeCode: {value: this.soa.directionOfFundsPaperSizeCode},
      estTaxIncrease: {value: this.soa.estTaxIncrease},
      balanceDueOnClosing: {value: this.soa.balanceDueOnClosing},
      soaHeading: [ this.soa.soaHeading ],
      otherHeadingsVendor: {value: this.soa.otherHeadingsVendor},
      otherHeadingsVendorDataCode: {value: this.soa.otherHeadingsVendorDataCode},
      otherHeadingsPurchaser: {value: this.soa.otherHeadingsPurchaser},
      otherHeadingsPurchaserDataCode: {value: this.soa.otherHeadingsPurchaserDataCode},
      otherHeadingsAddress: {value: this.soa.otherHeadingsAddress},
      otherHeadingsAddressDataCode: {value: this.soa.otherHeadingsAddressDataCode},
      otherHeadingsAdjustDate: {value: this.soa.otherHeadingsAdjustDate},
      otherHeadingsAdjustDateDataCode: {value: this.soa.otherHeadingsAdjustDateDataCode},
      rightColumnListsCreditsTo: {value: this.soa.rightColumnListsCreditsTo},
      leftColumnListsCreditsTo: {value: this.soa.leftColumnListsCreditsTo},
      wordingDirectionFunds: {value: this.soa.wordingDirectionFunds},
      headingReportVendor: {value: this.soa.headingReportVendor},
      showNetSalePriceCode: {value: this.soa.showNetSalePriceCode},
      preferredTerminologyCode: {value: this.soa.preferredTerminologyCode},
      referenceStatementOfAdjustments: {value: this.soa.referenceStatementOfAdjustments}

    });

    this.yesNoOptions = soaDropDowns.YESNOOPTIONS;
    this.paperSizes = soaDropDowns.PAPERSIZES;
    this.otherHeadingVendorNames = soaDropDowns.OTHERHEADINGSVENDORDPNAMES;
    this.otherHeadingsPurchaserNames = soaDropDowns.OTHERHEADINGSPURCHASERDPNAMES;
    this.addressData = soaDropDowns.ADDRESSDATA;
    this.adjustDate = soaDropDowns.ADJUSTDATE;
    this.preferredTerminology = soaDropDowns.PREFERREDTERMINOLOGY;

    if (this.soa.sameAsDefaultProfileFlag) {
      this.fillSoaFromDefaultProfile();
    }

    this.loadConsiderationTaxRate();
  }

  customKeyStruckDirection(event): void {

    if (event.keyCode === 120) {
      event.target.value = 'This is to direct you and shall constitute your good and sufficient authority to make certified cheques for the proceeds of sale in the above transaction payable as follows:';
      this.soa.wordingDirectionFunds = 'This is to direct you and shall constitute your good and sufficient authority to make certified cheques for the proceeds of sale in the above transaction payable as follows:';

    }
  }

  customKeyStruckReportVendor(event): void {

    if (event.keyCode === 120) {
      event.target.value = 'Summary of Statement of Adjustments';
      this.soa.headingReportVendor = 'Summary of Statement of Adjustments';
    }
  }

  customKeyStruckRightColumnCreditsTo(event): void {

    if (event.keyCode === 120) {
      event.target.value = 'Vendor';
      this.soa.rightColumnListsCreditsTo = 'Vendor';
    }
  }

  customKeyStruckLeftColumnCreditsTo(event): void {

    if (event.keyCode === 120) {
      event.target.value = 'Purchaser';
      this.soa.leftColumnListsCreditsTo = 'Purchaser';
    }
  }

  getSoaDocumentProfile(): DocumentProfileSoa {
    return this.soa && this.soa.sameAsDefaultProfileFlag && this.soaDefault ? this.soaDefault : this.soa;
  }

  getIncludeVendorTaxRateTypeLabel(): string {
    if ((this.documentProfile.provinceCode === PROVINCE_CODES.SASKATCHEWAN ||
      this.documentProfile.provinceCode === PROVINCE_CODES.MANITOBA)) {
      return 'Include Vendor\'s GST No?';
    } else {
      return 'Include Vendor\'s ' + this.taxRateType + ' No?';
    }
  }

  sameAsDefaultProfileOnchange(event: any): void {

    // console.log(this.profile.firmDocumentProfile.sameAsDefaultProfileFlag);

    if (event) {
      this.fillSoaFromDefaultProfile();
    } else {
      if (this.soaDefault) {
        if (this.copyDefaultProfileEnabled) {
          let idBeforeCopy = this.soa.id;
          Object.assign(this.soa, this.soaDefault, {id: idBeforeCopy});
          this.copyDefaultProfileEnabled = false;
        }
      }
      this.soa.sameAsDefaultProfileFlag = false;
    }
  }

  public fillSoaFromDefaultProfile(): void {
    let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.documentProfileService.getDefaultProfileForAccount(id, this.documentProfile.provinceCode).subscribe((profile: DocumentProfile) => {
      this.soaDefault = profile.statementOfAdjustmentsProfile;
      this.soa.sameAsDefaultProfileFlag = true;
    });
  }

  get isDirty(): Boolean {
    return this.documentProfile.dirty;
  }

  get documentProfileName(): string {
    if (this.documentProfile && this.documentProfile.profileName) {
      return this.documentProfile.profileName;
    } else {
      return '';
    }
  }

  ngDoCheck(): void {
    if (!this.activeAdminTab.documentProfileRef.statementOfAdjustmentsProfile.equals(this.soa)) {
      this.documentProfile.dirty = true;
    }
  }

  save(): void {
    if (this.activeAdminTab && this.activeAdminTab.documentProfileComponent) {

      this.activeAdminTab.documentProfile.statementOfAdjustmentsProfile = this.soa;

      this.saveSubscription = this.activeAdminTab.documentProfileComponent.saveDocProfile()
      .subscribe((saved) => {
        if (saved.valueOf()) {
          this.initializeDocumentProfile();
        }
        this.documentProfile.dirty = false;
      });
    }
  }

  loadConsiderationTaxRate(): void {
    this.taxRateService.cachedConsiderationTaxRate(this.documentProfile.provinceCode)
    .subscribe(
      (considerationTaxes: ConsiderationTaxes[]) => {
        //let taxRateSlabs = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
        let taxRateSlabs = this.taxRateService.findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes);
        this.taxRateType = taxRateSlabs && taxRateSlabs.rateType ? taxRateSlabs.rateType : '';

      });
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }
}

