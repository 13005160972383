import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthNService} from './auth-n.service';
import {FeatureService} from '../feature-service';
import {HttpClient} from './httpClient.service';
import {AuthZService} from '../core/authz/auth-z.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authNService: AuthNService,
    private router: Router,
    public httpClient: HttpClient
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.activeToken()) {
      return true;
    } else {
      this.router.navigate(['/login']);
    }
  }

  activeToken(): boolean {
    return this.authNService.isSSOTokenActive() || this.authNService.isUPMTokenActive();
  }
}
