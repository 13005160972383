import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {RentInterestRate} from '../../model/rent-interest-rate';
import {SelectItem} from 'primeng/api';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import {SoAdjAssumedMortgage} from '../../model/so-adj-assumed-mortgage';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import Utils from '../../../../shared-main/utils';
import {StatementOfAdjustmentDisplayItemLine} from '../../model';
import {ModalResult} from '../../../../shared-main/enums';
import {assumedMortgageDropDowns} from '../../model/so-adj-drop-downs';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class TenancyPrepaidModalContext {
  closingDate: string;
  adjustment: SoAdjAssumedMortgage;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  isPaysForDateOfClosingVendor: boolean;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-so-adj-assumed-mortgage-modal-content',
  templateUrl: './so-adj-assumed-mortgage.modal.component.html',
  providers: [ErrorService]
})
export class SoAdjAssumedMortgageModalComponent extends ModalComponent<TenancyPrepaidModalContext> implements OnInit {
  maxMortgageAllowed: number = 9; // wasn't this 8 ?
  context: TenancyPrepaidModalContext;

  rentInterestRates: RentInterestRate[] = [];
  localAdj: SoAdjAssumedMortgage;
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  mortgagePriorityOptions: SelectItem[] = [];
  combinedOrSeparateOptions: SelectItem[] = assumedMortgageDropDowns.combinedOrSeparateTypes;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = '';
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<SoAdjAssumedMortgageModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TenancyPrepaidModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.localAdj);
    }
  }

  checkForErrors() {
    if (Number(this.localAdj.mortgagePriority) == 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.assumed.mortgage.mortgage.priority.empty');
    }

    if (this.localAdj.combinedOrSeparate == 'SEPARATE') {
      if (Utils.isNotValidDate(this.localAdj.principalAsAtDate)) {
        this.modalErrorComponent.createDPFieldError('matter.soadj.assumed.mortgage.principal.date.empty');
      } else if (Utils.getDateDiff(this.localAdj.principalAsAtDate, this.context.closingDate) <= 0) {
        this.modalErrorComponent.createDPFieldError('matter.soadj.assumed.mortgage.principal.date.greater.adj');
      }
    }
    if (this.localAdj.principalAmount <= 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.assumed.mortgage.principal.amount.empty');
    }

    if (
      this.localAdj.combinedOrSeparate == 'SEPARATE' &&
      !this.localAdj.performInterestCalculationBoolean &&
      this.localAdj.interestAmount <= 0
    ) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.assumed.mortgage.interest.amount.empty');
    }
    if (
      this.localAdj.combinedOrSeparate == 'SEPARATE' &&
      this.localAdj.performInterestCalculationBoolean &&
      this.localAdj.interestRate <= 0
    ) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.assumed.mortgage.interest.rate.empty');
    }
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  ngOnInit() {
    this.mortgagePriorityOptions = [];
    this.mortgagePriorityOptions.push(...assumedMortgageDropDowns.mortgagePriorityTypes);
    for (let i: number = 1; i <= this.maxMortgageAllowed; i++) {
      this.mortgagePriorityOptions.push({value: i, label: Utils.getLongOrdinal(i).toUpperCase()});
    }

    if (this.context.adjustment) {
      this.localAdj = new SoAdjAssumedMortgage(this.context.adjustment);
    } else {
      this.localAdj = new SoAdjAssumedMortgage();
      this.localAdj.mortgagePriority = 0;
      this.localAdj.combinedOrSeparate = 'COMBINED';
      this.localAdj.performInterestCalculation = false;
      this.localAdj.interestAmount = 0;
      this.localAdj.interestRate = 0;
      this.localAdj.totalCreditPurchaser = 0;
      this.localAdj.principalAmount = 0;
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  onDateChange(event: any, dateType: string): void {
    this.localAdj[dateType] = event.rawDate;
    this.clearCachedDetails();
  }

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0) {
      this.cachedDetails = this.context.statementAdjustmentDisplayUtil.getDisplayItems(
        this.localAdj,
        this.context.closingDate
      );
      this.cachedDetails.pop(); // remove last one about credit (it has another formatting on main "Statement of Adjustment" screen)
      this.updateTotalAmount();
    }
    return this.cachedDetails;
  }

  validCalculations(): boolean {
    return this.localAdj.validCalculations(this.context.closingDate);
  }

  get principalAsAtDate(): string {
    if (Utils.isNotValidDate(this.localAdj.principalAsAtDate)) {
      return '???';
    }
    return this.context.statementAdjustmentDisplayUtil.getDate(this.localAdj.principalAsAtDate);
  }

  updateTotalAmount(): void {
    this.localAdj.updateTotalAmount(this.context.closingDate, this.context.isPaysForDateOfClosingVendor);
  }
}
