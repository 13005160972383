<!-- This is the Document for this Matter section -->
<ng-container *ngIf="!showWizardFields">
    <script src="documents-for-this-matter.component.ts"></script>
    <div *ngIf="!isProjectDocument && sharedDocsAvailable && !isMaterOpening"
         class="col-lg-12 padding-bottom-20 padding-top-5">
        <div class="sticky padding-bottom-40">
            <div class="row margin-top-10">
                <ul class="dppm-custom-tab">
                    <li [ngClass]="{active: subTabSelected === 'MATTER_DOCUMENTS'}"
                        (click)="subTabSelected = 'MATTER_DOCUMENTS'"
                    >
                        <span>Matter Documents</span>
                    </li>
                    <li [ngClass]="{active: subTabSelected === 'SHARED_DOCUMENTS'}"
                        (click)="subTabSelected = 'SHARED_DOCUMENTS'; loadSharedDocuments()"
                    >
                        <span>Sharing History</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="clear"></div>
        <div class="row padding-top-25"></div>
    </div>
    <div class="col-lg-12" *ngIf="subTabSelected === 'MATTER_DOCUMENTS'">
        <div class="padding-top-10 padding-bottom-20 form-horizontal documents-for-this-matter"
             [style.paddingBottom]="documentUtility.tablePadding">

            <div class="sticky" [ngClass]="{'sticky' : !isMaterOpening}">
                <div class="row"  *ngIf="!isMaterOpening">
                    <div class="col-lg-2 margin-top-8" [hidden]="isProjectDocument">
                        <select class="form-control" *ngIf="currentMatter && !currentMatter.isOpportunityMatter() && !currentMatter?.isWillMatter()"
                                id="typeFilter"
                                name="typeFilter"
                                [(ngModel)]="typeFilter"
                                class="form-control"
                                (ngModelChange)="onTypeChange()">
                            <option value="" selected>All Files</option>
                            <option value="STEWART_ASSYST">Unity® Lender Centre</option>
                            <option value="BROKER_UPLOADED">Broker Uploaded</option>
                            <option value="CHICAGO_TITLE">Chicago Title</option>
                            <option value="CLIENT_UPLOADED">Client Uploaded</option>
                            <option value="FCT">FCT Title Insurance</option>
                            <option value="FCT_LLC">FCT-LLC</option>
                            <option value="FCT_MMS">FCT-MMS</option>
                            <option value="FORMS">Forms</option>
                            <option value="ISC" *dpShowByProvince="'matter.subjectProperty.typeFilter.isc'">ISC</option>
                            <option value="PRODUCED">Produced</option>
                            <option value="REALTOR_UPLOADED">Realtor Uploaded</option>
                            <option value="SHARED">Received Shared Documents</option>
                            <option value="THIRD_PARTY_SIGNED">Signed Documents</option>
                            <option value="SPIN" *dpShowByProvince="'matter.subjectProperty.typeFilter.spin'">SPIN
                            </option>
                            <option value="STEWART_TITLE">Stewart Title</option>
                            <option value="SUPPLEMENTALTASK">Extended Workflows</option>
                            <option value="TERANET_CONNECT"
                                    *dpShowByProvince="'matters.property.typeFilter.teranetConnect'">Teranet Connect
                                &trade;
                            </option>
                            <option value="TITLE_PLUS">TitlePLUS</option>
                            <option value="TPR" *dpShowByProvince="'matter.subjectProperty.typeFilter.tpr'">TPR</option>
                            <option value="USER_UPLOADED">User Uploaded</option>

                        </select>
                    </div>
                    <div class="col-lg-3 margin-top-8">
                        <input type="text" *ngIf="currentMatter && !currentMatter.isOpportunityMatter() && !(currentMatter?.isWillMatter())"
                               id="search-by"
                               class="form-control "
                               [(ngModel)]="searchText"
                               placeholder="File Name Search"
                               (keyup)="applyFilter()"
                        />
                    </div>
                    <div [ngClass]="{'margin-top-8 col-lg-7' : !isProjectDocument , 'margin-top-8 col-lg-9' :
                isProjectDocument}">
                        <div class="pull-right">
                            <button type="button" class="dp-btn-green margin-right-4"
                                    [disabled]="sharingOrDownloadDocumentsDisabled()"
                                    [hidden]="isProjectDocument || (currentMatter && !(currentMatter.isDigitalSignPlatformSet())) || currentMatter?.isWillMatter()"
                                    (click)="saveMatterIfDirtyAndOpenAppointment()">
                                <span>Create {{signingPlatformLabel}} Appointment</span>
                            </button>
                            <button type="button" class="dp-btn-green margin-right-4" *ngIf="currentMatter  && !currentMatter.isOpportunityMatter()"
                                    [disabled]="sharingOrDownloadDocumentsDisabled()"
                                    [hidden]="isProjectDocument"
                                    (click)="shareDocumentsForMatter()">
                                <span>Share Documents</span>
                            </button>
                            <button type="button" [disabled]="sharingOrDownloadDocumentsDisabled()"
                                    class="dp-btn-white margin-right-4" (click)="downloadMultipleDocumentsForMatter()">
                                <span>Download</span>
                            </button>
                            <ng-container  *ngTemplateOutlet="uploadDocument"></ng-container>
                        </div>

                        <div class="clear"></div>
                    </div>
                </div>
                <div class="col-lg-12 text-right">
                    <div class="col-lg-6 text-left padding-top-15">
                      <span class="radio display-inline" style="margin-top: 4px;" >
                                        <input type="radio"
                                               id="folder{{isMaterOpening ? '--mo' : ''}}"
                                               name="folder{{isMaterOpening ? '--mo' : ''}}"
                                               (keyup.space)="documentViewMode = 'FOLDER';generateFolderStructure(false);"
                                               (click)="documentViewMode = 'FOLDER';generateFolderStructure(false);"
                                               [(ngModel)]="documentViewMode"
                                               (ngModelChange)="updateDocumentViewMode()"
                                               [value]="'FOLDER'"
                                        />

                                        <label tabindex="0" for="folder{{isMaterOpening ? '--mo' : ''}}" >Folder</label>
                      </span>
                        <span  class="radio display-inline padding-left-10" style="margin-top: 4px;">
                                        <input type="radio"
                                               id="list{{isMaterOpening ? '--mo' : ''}}"
                                               name="list{{isMaterOpening ? '--mo' : ''}}"
                                               (keyup.space)="documentViewMode = 'FOLDER';generateFolderStructure(false);"
                                               (click)="documentViewMode = 'LIST'"
                                               [(ngModel)]="documentViewMode"
                                               (ngModelChange)="updateDocumentViewMode()"
                                               [value]="'LIST'"
                                        />

                                        <label tabindex="0" for="list{{isMaterOpening ? '--mo' : ''}}" >List</label>
                      </span>
                    </div>
                    <div class="col-lg-6 text-right" id="dragAndDropTextArea"  *ngIf="!isMaterOpening">
                    <span class="padding-right-5"><img
                        class="arrow-down"
                        src="assets/img/down_arrow.png"/></span><label class="boldfont">Drag and Drop your files here to
                        upload</label>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="!isMaterOpening">
                <ngx-file-drop (onFileOver)="fileOver($event)" dropZoneClassName="drop-zone"
                               (onFileLeave)="fileLeave($event)"
                               (onFileDrop)="onFileDropped($event)">
                    <ng-template ngx-file-drop-content-tmp>
                        <i class="fa fa-cloud-upload padding-right-20 drop-zone-icon"></i>
                        <span class="boldfont drop-zone-label">Drop your files here to Upload</span>
                    </ng-template>
                </ngx-file-drop>
            </ng-container>
            <div [hidden]="documentViewMode != 'LIST'" (dragover)="docTableDragOver($event)">
                <div class="padding-bottom-10 view-table  position-relative">
                    <div class="col-lg-12">
                        <table class="standard-table col-lg-12 sticky-table top-100" [mfData]="rows" #mf="mfDataTable"
                               [attr.tabindex]="-1"
                               (keydown)="tableKeyCommands($event)">

                            <thead class="sticky-table-header">
                            <tr>
                                <th class="table-dp-cell-2">
                                    <div class="wrap-header top-0 padding-0">
                                        <dp-checkbox fieldId="templateCheckBoxAll{{isMaterOpening ? '--mo' : ''}}" class="padding-left-7">
                                            <input type="checkbox"
                                                   id="templateCheckBoxAll{{isMaterOpening ? '--mo' : ''}}"
                                                   name="templateCheckBoxAll{{isMaterOpening ? '--mo' : ''}}"
                                                   [(ngModel)]="checkBoxAllTemplate"
                                                   (checked)="selectedDocuments.length > 0"
                                                   (ngModelChange)="selectAllTemplate()"


                                            />
                                        </dp-checkbox>
                                    </div>
                                </th>
                                <th class="table-dp-cell-2"></th>
                                <th class="{{isProjectDocument ? 'table-dp-cell-1-alt' : 'table-dp-cell-1'}}"
                                    (click)="sortDocumentName.sort();" id="sortDocumentName">
                                    <div class="wrap-header top-0 padding-0">File Name
                                        <dp-default-sorter #sortDocumentName by="documentName"
                                                           [singleState]="true"></dp-default-sorter>
                                    </div>
                                </th>
                                <th class="table-dp-cell-2">
                                </th>

                                <th class="table-dp-cell-autoShare" *ngIf="isProjectDocument">
                                    <div>Share With Other Side?</div>
                                </th>

                                <th class="{{isProjectDocument ? 'table-dp-cell-3-alt' : 'table-dp-cell-3'}}"
                                    (click)="sortDescription.sort();"
                                    id="sortDescription">
                                    <div *ngIf="currentMatter  && !currentMatter.isOpportunityMatter()" class="wrap-header top-0 padding-0 padding-left-15">Description
                                        <dp-default-sorter #sortDescription by="description"
                                                           [singleState]="true"></dp-default-sorter>
                                    </div>
                                </th>
                                <th class="table-dp-cell-4" (click)="sortLastUpdatedTimeStamp.sort();"
                                    id="sortLastUpdatedTimeStamp">
                                    <div class="wrap-header top-0 padding-0 padding-left-20">Date
                                        <dp-default-sorter #sortLastUpdatedTimeStamp by="lastUpdatedTimeStamp"
                                                           [singleState]="true"></dp-default-sorter>
                                    </div>
                                </th>
                                <th class="table-dp-cell-5" (click)="sortGeneratedDocumentType.sort();"
                                    id="sortGeneratedDocumentType"
                                    *ngIf="!isProjectDocument">
                                    <div *ngIf="currentMatter  && !currentMatter.isOpportunityMatter()" class="wrap-header top-0 padding-0 padding-left-15">Type
                                        <dp-default-sorter #sortGeneratedDocumentType by="generatedDocumentType"
                                                           [singleState]="true"></dp-default-sorter>
                                    </div>
                                </th>
                                <th class="table-dp-cell-status">
                                </th>
                                <th class="table-dp-cell-6" (click)="sortInitials.sort();" id="sortInitials">
                                    <div class="wrap-header top-0 padding-0">User
                                        <dp-default-sorter #sortInitials by="initials"
                                                           [singleState]="true"></dp-default-sorter>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            <ng-container *ngFor="let row of mf.data; let i = index;">
                                <ng-container [ngTemplateOutlet]="documentRow"
                                              [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                            </ng-container>

                            <tr *ngIf="matterDocuments.length == 0" class="loading-row">
                                <td colspan="7" class="text-center" *ngIf="!documentsLoadInProgress">
                                    <div class="no-match-found">No Documents Found.</div>
                                </td>
                                <td colspan="7" class="text-center" *ngIf="documentsLoadInProgress">
                                    <div id="spinner">
                                        <img src="assets/img/spinner.gif"/>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div [hidden]="documentViewMode != 'FOLDER'" style="top: 5px;z-index: 2;position: relative">
                <div class="col-lg-12 folder-table" [ngClass]="{'top-0' : this.isMaterOpening}">
                    <table class="standard-table col-lg-12 sticky-table" >

                        <thead class="sticky-table-header">
                        <tr>
                            <th class="table-dp-cell-2">
                                <div class="wrap-header top-0 padding-0">

                                </div>
                            </th>
                            <th class="table-dp-cell-2"></th>
                            <th class="{{isProjectDocument ? 'table-dp-cell-1-alt' : 'table-dp-cell-1'}}"
                            >
                                <div class="wrap-header top-0 padding-0">File Name
                                </div>
                            </th>
                            <th class="table-dp-cell-2">
                            </th>

                            <th class="table-dp-cell-autoShare" *ngIf="isProjectDocument">
                                <div>Share With Other Side?</div>
                            </th>

                            <th class="{{isProjectDocument ? 'table-dp-cell-3-alt' : 'table-dp-cell-3'}}">

                                <div class="wrap-header top-0 padding-0 padding-left-15">Description

                                </div>
                            </th>
                            <th class="table-dp-cell-4"
                            >
                                <div class="wrap-header top-0 padding-0 padding-left-20">Date
                                </div>
                            </th>
                            <th class="table-dp-cell-5"

                                *ngIf="!isProjectDocument">
                                <div class="wrap-header top-0 padding-0 padding-left-15">Type

                                </div>
                            </th>
                            <th class="table-dp-cell-status">
                            </th>
                            <th class="table-dp-cell-6">
                                <div class="wrap-header top-0 padding-0">User

                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <div class="padding-top-10">
                            <tree-root #tree [nodes]="documentNodes" [focused]="true" [options]="options"
                                       (moveNode)="onMoveNode($event)">
                                <ng-template #treeNodeTemplate let-node let-index="index">
                                    <div *ngIf="!node.data.document">
                                        <i class="fa fa-folder padding-right-5 padding-left-7" *ngIf="!node.isExpanded"></i>
                                        <i class="fas fa-folder-open padding-right-5 padding-left-7" *ngIf="!!node.isExpanded"></i>
                                        <input type="text"
                                               id="nodeId_{{node.id}}"
                                               name="nodeName_{{node.id}}"
                                               class="form-control input-control"
                                               [(ngModel)]="node.data.name"
                                               (blur)="renameSubFolder(node,$event)"
                                               (focus)="selectNode(node,$event)"
                                               (keydown.Enter)="renameSubFolder(node,$event)"
                                               *ngIf="node.data.isEdited"

                                        />
                                        <label class="control-label padding-top-5 padding-bottom-5" *ngIf="!node.data.isEdited">{{ node.data.name }}</label>


                                        <span class="padding-left-5" *ngIf="!node.data.isEdited  && node.data.isNodeEditable">
                                                              <dp-dropdown-menu
                                                                  [dropDownItems]="node.data.burgerMenuNodeItems"
                                                                  (itemClicked)="clickFolderNodeBurgerMenu($event, node)"
                                                              >
                                                              </dp-dropdown-menu>
                                    </span>
                                    </div>
                                    <ng-container *ngIf="node.data.document" [ngTemplateOutlet]="documentRow"
                                                  [ngTemplateOutletContext]="{ $implicit: node.data.document , tableCellOneCssClass : node.data.tableCellOneCssClass , nodeData : node.data }"></ng-container>
                                </ng-template>
                            </tree-root>
                        </div>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
    <div class="col-lg-12 padding-top-50" *ngIf="subTabSelected === 'SHARED_DOCUMENTS'">
        <table class="shared-doc-table">
            <thead class="sticky-table-header margin-top-minus-30 height-31">
            <th class="col1"></th>
            <th class="col2 padding-left-55">Filename</th>
            <th class="col3 padding-left-0">Description</th>
            <th class="col4 padding-left-0">Date Shared</th>
            <th class="col5 padding-left-0">Date Last Accessed</th>
            </thead>
            <tbody>
            <tr *ngFor="let itm of sharedDocSummaryList; let i=index"
                [hidden]="!itm.visible"
                [ngClass]="{'tr-sub-header': itm.isRecipientName, 'active': sharedDocsSelectedIndex==i}"
                (click)="onRowClick(itm, i, $event)"
                (focus)="sharedDocsSelectedIndex=i"
            >
                <td class="col1">
                    <div *ngIf="itm.isRecipientName && itm.expanded " class="padding-left-5">
                        <img src="/assets/img/minus.svg" alt=""/>
                    </div>
                    <div *ngIf="itm.isRecipientName && !itm.expanded" class="padding-left-5">
                        <img src="/assets/img/plus.svg"/>
                    </div>
                </td>
                <td class="col2">

                    <div *ngIf="itm.isRecipientName" class="inline-block max-width-270">
                        <span class="bold line-heigh-25">{{itm.recipient}}</span><br>
                        <span class="font-size-12">{{itm.recipientRole}}</span>
                    </div>
                    <div *ngIf="itm.isRecipientName && !itm.isPackageSharingRevoked() && !itm.documentId"
                         class="float-right">
                        <dp-dropdown-menu
                            [dropDownItems]="itm.burgerMenu"
                            (itemClicked)="clickSharingBurgerMenu($event, itm)"
                        >
                        </dp-dropdown-menu>
                    </div>
                    <div *ngIf="itm.documentId">
                        <div class="col-lg-1 offset-lg-1">
                            <dp-dropdown-menu
                                [dropDownItems]="itm.burgerMenu"
                                (itemClicked)="clickSharingBurgerMenu($event, itm)"
                            >
                            </dp-dropdown-menu>
                        </div>
                        <div class="col-lg-10 text-ellipsis width-250">
                            <a (click)="openSharedDoc(itm)">
                                <span title="{{itm.fileName}}">{{itm.fileName}}</span>
                            </a>
                        </div>
                    </div>

                </td>
                <td class="col3">
                    <div *ngIf="itm.documentId" class="text-ellipsis width-250">
                        <span title="{{itm.description}}">{{itm.description}}</span>
                    </div>

                </td>
                <td class="col4">
                    <div>
                        <div *ngIf="itm.lastSharedTimeStamp" class="inline-block width-150">
                            {{itm.lastSharedTimeStamp| date:'mediumDate'}}, {{itm.lastSharedTimeStamp| date:'shortTime'}}
                        </div>
                        <span *ngIf="itm.isDocSharingRevoked">
                        <i class="virus-icon errorMessage fa fa-times-circle font-size-16 padding-bottom-3"></i>
                        <span class="matter-locked-message virus-message sharing-bubble-message">
                            <span class="bubble-arrow">
                                <img src="/assets/img/bubble_arrow_top.png" alt=""/>
                            </span>
                            <span>Revoked</span>
                        </span>
                    </span>
                        <span *ngIf="!itm.isDocSharingRevoked && isSharedDocumentOutOfDate(itm)">
                        <i class="virus-icon warningMessage fas fa-exclamation-triangle padding-bottom-3"></i>
                        <span class="matter-locked-message virus-message sharing-bubble-message">
                            <span class="bubble-arrow">
                                <img src="/assets/img/bubble_arrow_top.png" alt=""/>
                            </span>
                            <span>Shared document is not the latest version</span>
                        </span>
                    </span>
                    </div>


                </td>
                <td class="col5">
                    <div *ngIf="itm.lastViewedTimeStamp">
                        {{itm.lastViewedTimeStamp| date:'mediumDate'}}, {{itm.lastViewedTimeStamp| date:'shortTime'}}
                    </div>
                    <div *ngIf="itm.isSharedDocumentPackageDeclinedOrAck()">
                        {{itm.getPackageStatusToDisplay()}}
                    </div>

                </td>
            </tr>
            </tbody>
        </table>

    </div>
</ng-container>
<ng-container *ngIf="!!showWizardFields">
    <div class="form-group col-lg-12">
        <ng-container  *ngTemplateOutlet="uploadDocument"></ng-container>
    </div>
</ng-container>

<ng-template #documentRow let-row let-tableCellOneCssClass="tableCellOneCssClass" let-nodeData="nodeData">
    <tr *ngIf="documentUtility.mapStatus(row.status ,false) === 'Available'"
        (click)="clickedDocumentListRow($event,tableCellOneCssClass);" [attr.tabindex]="0">
        <td>
            <ng-container>
                <dp-checkbox fieldId="templateCheckBox-{{isMaterOpening ? 'mo-'+row.id : row.id}}">
                    <input type="checkbox"
                           id="templateCheckBox-{{isMaterOpening ? 'mo-'+row.id : row.id}}"
                           name="templateCheckBox-{{isMaterOpening ? 'mo-'+row.id : row.id}}"
                           (click)="selectDocument(row ,  $event )"
                            [checked]="isDocumentSelected(row)"

                    />
                </dp-checkbox>
            </ng-container>
        </td>
        <td>
            <img *ngIf="row.isWordOrWordPerfect" src="{{getWordDocsIcon(row.documentName)}}"
                 alt="word Icon"
                 class="word-icon"/>
            <img *ngIf="row.isPdf" src="assets/img/pdf.png" alt="pdf Icon"
                 class="pdf-icon"/>
            <img *ngIf="row.isTxt" src="assets/img/txt.png" alt="txt Icon"
                 class="txt-icon"/>
            <img *ngIf="row.isXml" src="assets/img/xml.png" alt="xml Icon"
                 class="txt-icon"/>
            <i *ngIf="row.isEmail"
               class="align-document-icon fal fa-envelope font-18"></i>
            <i *ngIf="row.isOther"
               [class]="'align-document-icon ' + 'fa fa-file-' + row.documentType.toLowerCase() +  '-o font-18'"></i>
        </td>
        <td class="{{isProjectDocument ? 'table-dp-cell-1-alt' : 'table-dp-cell-1'}}"
            [style.min-width.px]="tableCellOneCssClass" [style.max-width.px]="tableCellOneCssClass">
            <div class="inline-block">
                                        <span *ngIf="documentUtility.isDocumentLocked(row) || currentMatter.locked"
                                              class="matter-lock-icon-div">
                                        <span class="matter-lock-icon">
                                          <i class="fa fa-lock"></i>
                                        </span>
                                        <span class="matter-locked-message">
                                            <span class="bubble-arrow">
                                                <img src="/assets/img/bubble_arrow_top.png" alt=""/>
                                            </span>
                                            <span>
                                                Locked by: <u><i>{{ currentMatter.lockedByUser.firstName + ' ' + currentMatter.lockedByUser.lastName}}</i></u>
                                           </span>
                                        </span>
                                    </span>
                <span *ngIf="row.isInfected || row.isVirusScanPending">
                                        <i class="virus-icon"
                                           [ngClass]="row.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>
                                        <span class="matter-locked-message virus-message">
                                            <span class="bubble-arrow">
                                                <img src="/assets/img/bubble_arrow_top.png" alt=""/>
                                            </span>
                                            <span *ngIf="row.isInfected">We have detected this file is infected with a virus.</span>
                                            <span *ngIf="row.isVirusScanPending">Please wait... we are currently scanning this file for viruses.</span>
                                        </span>
                                    </span>
            </div>
            <div class="inline-block text-ellipsis">
                                        <span
                                            *ngIf="row.documentName && !row.isInfected && !row.isVirusScanPending && (row.status == 'COMPLETED' || row.status == 'UPLOADED')">
                                            <a (click)="openFile(row)">
                                                <span title="{{row.documentName+(row.subpath && currentMatter ? ('  ( '+currentMatter.matterRecordNumber+'/'+row.subpath+' )') : '')}}">
                                                   {{row.documentName}}
                                                </span>
                                            </a>
                                        </span>
                <span
                    *ngIf="row.documentName && (row.isInfected || row.isVirusScanPending) && (row.status == 'COMPLETED' || row.status == 'UPLOADED')">
                                            <span title="{{row.documentName}}">
                                               {{row.documentName}}
                                            </span>
                                        </span>


            </div>
            <img *ngIf="row.isDocumentDigitallySignedByDocuSign && !isMaterOpening"
                 src="assets/img/docusign_logo_black_text_on_white_0.png" alt="Docusign Icon"
                 class="word-icon third-party-logo"/>
            <img *ngIf="row.isDocumentDigitallySignedBySyngrafii && !isMaterOpening"
                 src="assets/img/iinked-Sign-Video-Signing-Room.svg" alt="Syngrafii Icon"
                 class="word-icon third-party-logo"/>
        </td>

        <td class="table-dp-cell-2 padding-left-0">
            <dp-burger-menu #dpMenu
                            [items]="getBurgerMenuItemsForThisMatter(row)"
                            (itemClicked)="burgerMenuForThisMatterClicked($event, row, nodeData)"
                            (menuOpened)="documentUtility.burgerMenuOpen(row,$event,documentTemplateIds)"
                            [addFocus]="true"
                            [disabledItems]="getDisabledItemsForThisMatter(row)"
            ></dp-burger-menu>
        </td>
        <td class="table-dp-cell-autoShare"
            *ngIf="isProjectDocument && isAutoShareVisible(row)">
            <ng-container>
                <dp-checkbox fieldId="autoShare-{{row.id}}" class="padding-left-20">
                    <input type="checkbox"
                           id="autoShare-{{row.id}}"
                           name="autoShare-{{row.id}}"
                           (change)="selectAutoShare($event, row)"
                           [(ngModel)]="row.autoShared"
                           [dp-read-only]="row.isInfected || row.isVirusScanPending"

                    />
                </dp-checkbox>
            </ng-container>
        </td>
        <td class="table-dp-cell-autoShare"
            *ngIf="isProjectDocument && !isAutoShareVisible(row)">

        </td>
        <td class="{{isProjectDocument ? 'table-dp-cell-3-alt' : 'table-dp-cell-3'}}">
                                    <span *ngIf="currentMatter  && !currentMatter.isOpportunityMatter()" title="{{row.description}}">
                                        {{row.description}}
                                    </span>
        </td>

        <td class="table-dp-cell-4">
            <i *ngIf="row.showDocumentGenerationDate">{{row.documentGenerationDate| date:'mediumDate'}}
                , {{row.documentGenerationDate| date:'shortTime'}}</i>
            <i *ngIf="!row.showDocumentGenerationDate">{{row.lastUpdatedTimeStamp|  date:'mediumDate'}}
                , {{row.lastUpdatedTimeStamp| date:'shortTime'}}</i>
        </td>
        <td class="table-dp-cell-5" *ngIf="!isProjectDocument">

            <!-- For now all the row.documentType 's are Produced and Once we receive a proper type we will display it -->
            <ng-container *ngIf="currentMatter  && !currentMatter.isOpportunityMatter()">
                <div *ngIf="!row.generatedDocumentType">
                    <!--TODO: Look into the generated document type, the source should be used-->
                    {{row.source == 'USER_UPLOAD' ? 'Uploaded' : 'Produced'}}
                </div>
                <div *ngIf="row.generatedDocumentType">
                    <!--Map to a friendly name-->
                    {{displayFriendlyDocumentType(row)}}
                </div>
            </ng-container>
        </td>
        <td class="table-dp-cell-status red bold">
            {{row.docStatus}}
        </td>
        <td class="table-dp-cell-6">
            {{row.isThirdPartySigned ? '' : (row.initials | slice:0:4)}}
        </td>
    </tr>
</ng-template>
<ng-template #uploadDocument>
    <label for="file-upload" #fileUpload class="fileLabel" tabindex="0" id="fileUploadElementRef"
           (keyup.enter)="fileUpload.click()" [hidden]="!!showWizardFields" >
        <span class="btn dp-btn"   [class.disabled]="isUploadDisabled()">
            {{!!showWizardFields ? 'Upload Documents' : 'Upload'}}</span>
    </label>
    <input style="display: none;"
           #fileUploadInput
           [disabled]="isUploadDisabled()"
           id="file-upload"
           type="file"
           multiple="true"
           [accept]="acceptedFileExtensions"
           (change)="fileBrowseUpload()"
    />
</ng-template>
