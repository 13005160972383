import {Contact} from '../shared/contact';
import {ContactService} from '../../shared-main';
import * as _ from 'lodash';
import {Observable} from 'rxjs/Observable';
import {ReferredByInfo} from '../shared/referred-by-info';
import {Matter} from '../shared';
import {ReferredByModalComponent} from './referred-by-modal/referred-by-modal.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Subject} from 'rxjs/Subject';
import {MatterNoModalComponent} from './matter-no-modal/matter-no-modal.component';
import {MatterTab} from '../matter-tab';
import {DocumentProductionService} from '../document-production/document-production.service';
import {Tab, TabsComponent} from '../../shared/tabbing';
import {TabsService} from '../../core';

export class MatterOpeningUtil {
  /**
   * Assignee can be any member of my firm - Lawyers and Law Clerks.
   * @param type
   * @param contactService
   * @param inactiveAssignedToList
   * @returns {Promise<Contact[]>}
   */
  static async getLawClerkSolicitorList(type, contactService: ContactService, inactiveAssignedToList: Contact[]): Promise<Contact[]> {
    let filteredList: Contact[] = [];
    let data: Contact[] = await contactService.getUpdatedSolicitorLawClerkList(type).toPromise();
    if (data && data.length) {
      filteredList = data.filter(item => 'YES' === item.activeFlag || 'Y_n' === item.activeFlag || 'Y/n' === item.activeFlag);
      let inactiveList = data.filter(item => !item.isActive);
      inactiveAssignedToList.push(...inactiveList);
    }
    return filteredList;
  }

  /**
   * Assignee can be any member of my firm - Lawyers, Law Clerks and Other.
   * @param type
   * @param contactService
   * @param inactiveAssignedToList
   * @returns {Promise<Contact[]>}
   */
  static async getLawClerkSolicitorOtherList(type, contactService: ContactService, inactiveAssignedToList: Contact[], filterApiClientUser: boolean = false): Promise<Contact[]> {
    let filteredList: Contact[] = [];
    let data: Contact[] = await contactService.getUpdatedSolicitorLawClerkOtherList(type, true, filterApiClientUser).toPromise();
    if (data && data.length) {
      filteredList = data.filter(item => 'YES' === item.activeFlag || 'Y_n' === item.activeFlag || 'Y/n' === item.activeFlag);
      let inactiveList = data.filter(item => !item.isActive);
      inactiveAssignedToList.push(...inactiveList);
    }
    return filteredList;
  }

  /**
   *  Assignee can be any member of my firm - Lawyers, Law Clerks and Other.   Both active and inactive.
   * @param type
   * @param contactService
   * @param inactiveAssignedToList
   * @returns {Promise<Contact[]>}
   */
  static async getAllLawClerkSolicitorOtherList(type, contactService: ContactService): Promise<Contact[]> {
    return await contactService.getUpdatedSolicitorLawClerkOtherList(type).toPromise();
  }

  static loadContactDropDownList(contactList: Contact[]): any[] {
    let dropDownList = [];
    dropDownList.push({
      label: '',
      value: 'N/A'
    });
    for (let i = 0; i < contactList.length; i++) {
      dropDownList.push({
        label: contactList[ i ].isLawClerk ?
          contactList[ i ].getFullNameForContact(false).trim() : contactList[ i ].contactName.surnameLastFullName,
        value: contactList[ i ].id,
        initials: contactList[ i ].initials,
        email: contactList[ i ].firstEmail,
        mobileNumber: contactList[ i ].getMobileNumber()
      });
    }

    dropDownList = _.sortBy(dropDownList, item => item.label.toLowerCase());
    return dropDownList;
  }

  static getPickListAutocomplete(query: string, listDropDown: any[]): Observable<Contact[]> {
    let pickList: any[] = [];

    if (query && query.trim() !== '' && Array.isArray(listDropDown) && listDropDown.length > 0) {
      pickList = listDropDown.filter((item) => {
        if (item.label) {
          return query && (item.label.toUpperCase().indexOf(query.toUpperCase()) > -1);
        }
      });
    }
    let observable = Observable.create((observer) => {
      setTimeout(() => {
        observer.next(pickList);
      }, 10);
    });
    return observable;
  }

  static getReferredBy(matter: Matter): string {
    let ret: string = '';
    if (matter && Array.isArray(matter.referredByList)) {
      ret = matter.referredByList.map((referredByInfo: ReferredByInfo) => referredByInfo.referredByAttention || referredByInfo.referredBy)
      .filter(item => item)
      .join(', ');

    }
    return ret;
  }

  static openReferredByModal(matter: Matter, dialogService: DialogService): Observable<boolean> {
    let ret = new Subject<boolean>();
    dialogService.matDialogContent({
      content: ReferredByModalComponent,
      widthXl: true,
      fullScreen: true,
      context: {
        matter: matter
      },
      onFulfillment: (result) => {
        if (result && result.action == 'OK' && result.isDirty) {
          matter.referredByList = result.referredByList;
          ret.next(true);
          ret.complete();
        } else {
          ret.next(false);
          ret.complete();
        }
      }
    });
    return ret;
  }

  static openMatterNumberModal(matter: Matter, matterTab: MatterTab, documentProductionService: DocumentProductionService, dialogService: DialogService): void {

    dialogService.matDialogContent({
      content: MatterNoModalComponent,
      context: {
        matter: matter,
        matterTab: matterTab
      },
      modalGrid: 5,
      onFulfillment: (result) => {
        if (result) {
          let newMatterNoConfirmationMessage = result;
          if (matter.isOpportunityMatter()) {
            dialogService.confirm('Confirmation', newMatterNoConfirmationMessage, true);
          } else {
            documentProductionService.getDocuments(matter.id)
            .subscribe(data => {
              if (data && data.length) {
                newMatterNoConfirmationMessage += '<br>Verify any produced documents do not contain the original Matter No.';
              }
              dialogService.confirm('Confirmation', newMatterNoConfirmationMessage, true);
            });
          }

        }
      }
    });
  }

  static openTabForLinkedMatter(matter: Matter, tabsService: TabsService, tabsComponent: TabsComponent): void {
    if (matter.matterLink) {
      let tab: Tab = tabsService.getTab(matter.matterLink.linkedMatterId, 'matter');
      if (tab) {
        tabsComponent.openSelectedTab(tab);
      } else {
        let linkedMatter = new Matter;
        linkedMatter.id = matter.matterLink.linkedMatterId;
        tabsComponent.openMatter(linkedMatter);
      }
    }
  }

}
