import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetDocument, TeranetDocumentStatus} from './teranet-document';
import moment from 'moment';
import {ContactNameUtil} from '../../matters/shared/contact-name-util';
import * as _ from 'lodash';

export const TransferTypes = [ 'APL AMEND ORDER', 'APL CH NAME OWNER', 'APL DEV/HEIR-LAND', 'APL OF SURV-LAND', 'APL TR BK-OWNER', 'APL VESTING ORDER', 'DEED POLL', 'JDGMT FORECLOSURE', 'ORDER IN COUNCIL', 'PLAN EXPROPRIATION',
  'TRANSFER', 'TRANS ADMIN', 'TRANS ADM S DEBTS', 'TRANS & APPNTMNT', 'TRANSFER EASEMENT', 'TRANS EXECUTORS', 'TRANS PARTNERSHIP', 'TRANS PERSONAL REP', 'TRANS POWER SALE', 'TRANSMISSION-LAND',
  'TRANS RLIGIOUS ORG', 'TR TRUST BANKRPTCY', 'TRANS TO USES' ];

export const TeranetConnectImportInstruments = [ 'MORTGAGE', 'CAVEAT', 'APL INH ORDER-LAND', 'APL ANNEX REST COV', 'ASSIGNMENT', 'ASSIGNMENT OF RENTS', 'BUILDER LIEN',
  'CAU AGR PUR &amp; SALE', 'CAUTION-CHARGE', 'CAUTION BKRUPT-CH', 'CAUTION-LAND', 'CAU L EST ADM ACT',
  'CERT LIEN HD ACT', 'CHARGE', 'CHARGE (ASSUMED, AMENDED TERMS)', 'CHARGE (ASSUMED, EXISTING TERMS)', 'CHARGE (POWER OF SALE)', 'CHARGE PARTNERSHIP',
  'CHRG RELIGIOUS ORG', 'CONDOMINIUM LIEN', 'CONSTRUCTION LIEN', 'CERTIFICATES OF LIS PENDENS',
  'ENCUMBRANCE', 'JUDGEMENT', 'LR\'S CAUTION', 'LIEN', 'LEASE', 'MOBILE_HOME', 'NO ASSGN RENT SPEC', 'NO AGR PUR &amp; SALE', 'NO ASSGN RENT GEN',
  'NOTICE OF LEASE', 'NO OIL &amp; GAS LEASE', 'NO OPTION LEASE', 'NO OPTION PURCHASE', 'NO SEC INTEREST', 'NO SUB AGREEMENT', 'NO VENDORS LIEN',
  'REST COV APL ANNEX', 'RESTICTIVE COVENANT', 'SURFACE LEASE', 'TAX NOTIFICATION', 'UTILITY RIGHT OF WAY', 'WRIT', 'ZONING REGULATIONS' ];

export class Instrument extends BaseEntity {
  id: number;
  instrumentNumber: string;
  instrumentDate: string;
  requestedDate: string; //Date when an instrument is requested.
  courierDate: string; //Date when courier was requested.
  type: string;
  amount: string;
  instrumentStatus: string;
  partiesFrom: string[] = [];
  partiesTo: string[] = [];
  remarks: string[] = [];
  instrumentDocument: TeranetDocument;
  attachments: TeranetDocument[] = [];
  numberCourierCopies: number; // The number of copies for a courier request. Not saved to backend.
  adjacentParcelAnalysisAvailable: boolean = false;
  parcelRegisterId: number; // only set on the adjacent parcel analysis
  analysisStillNeeded: boolean; // only used on adjacent parcel analysis

  //For TPR Instrument
  description: string;
  affectedByInstrumentNumber: string;
  affectedByInstrumentTypeCode: string;

  constructor(p?: Instrument) {
    super(p);
    if (p) {
      if (p.instrumentDocument) {
        this.instrumentDocument = new TeranetDocument(p.instrumentDocument);
      }
      if (p.attachments) {
        for (let o of p.attachments) {
          this.attachments.push(new TeranetDocument(o));
        }
      }
      if (p.partiesFrom) {
        for (let o of p.partiesFrom) {
          this.partiesFrom.push(o);
        }
      }
      if (p.partiesTo) {
        for (let o of p.partiesTo) {
          this.partiesTo.push(o);
        }
      }
      if (p.remarks) {
        for (let o of p.remarks) {
          this.remarks.push(o);
        }
      }
    }
  }

  get instrumentDocumentStatus(): TeranetDocumentStatus {
    return this.instrumentDocument ? this.instrumentDocument.documentStatus : 'NOT_AVAILABLE';
  }

  get instrumentDocumentPageCount(): number {
    return this.instrumentDocument ? this.instrumentDocument.numOfPages : 0;
  }

  get amountAsNumber(): number {
    let num: number = null;
    if (this.amount) {
      //remove any characters that are not digits (0-9), letter "e" or "E", decimal, or minus sign
      //Number() will convert values like 1.035E7 to 10350000
      num = Number(this.amount.replace(/[^0-9eE\.-]+/g, ''));
    }
    return num;
  }

  //used for courier requests
  get typeWithPartyTo(): string {
    let text = '';

    //Remove any parties that are just blanks or spaces
    let partiesToBlanksRemoved: string[] = this.partiesTo.filter(partyTo => partyTo.trim());
    if (this.type) {
      text += this.type;
    }

    //Add partiesTo with semicolon separator if multiple
    if (partiesToBlanksRemoved.length > 0) {
      text += ' (' + partiesToBlanksRemoved.join('; ') + ')';
    }
    return text;
  }

  get partiesToBlanksRemoved(): string {
    let text = '';

    //Remove any parties that are just blanks or spaces
    let partiesToBlanksRemoved: string[] = this.partiesTo.filter(partyTo => partyTo.trim());

    //Add partiesTo with semicolon separator if multiple
    if (partiesToBlanksRemoved.length > 0) {
      text += partiesToBlanksRemoved.join('; ');
    }
    return text;
  }

  get partiesFromBlanksRemoved(): string {
    let text = '';

    //Remove any parties that are just blanks or spaces
    let partiesFromBlanksRemoved: string[] = this.partiesFrom.filter(partyTo => partyTo.trim());

    //Add partiesTo with semicolon separator if multiple
    if (partiesFromBlanksRemoved.length > 0) {
      text += partiesFromBlanksRemoved.join('; ');
    }
    return text;
  }

  get partiesToFormatted(): string {
    //Remove any parties that are just blanks or spaces
    let partiesToBlanksRemoved: string[] = this.partiesTo.filter(partyTo => partyTo.trim());
    let text = this.partiesFormatter(partiesToBlanksRemoved);
    return text;
  }

  get partiesFromFormatted(): string {
    //Remove any parties that are just blanks or spaces
    let partiesFromBlanksRemoved: string[] = this.partiesFrom.filter(partyFrom => partyFrom.trim());
    let text = this.partiesFormatter(partiesFromBlanksRemoved);
    return text;
  }

  private partiesFormatter(partiesBlanksRemoved: string[]): string {
    let text = '';

    let partiesFormatted: string[] = partiesBlanksRemoved.map(party => {
        let partyText = party;
        if (!ContactNameUtil.isItCorporation(party) && party.includes(',')) {
          let splitName: string[] = ContactNameUtil.splitLastAndGivenName(party);
          partyText = splitName[ 1 ] + ' ' + splitName[ 0 ];
        } else {
          return partyText;
        }

        return _.toLower(partyText).split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
      }
    );

    if (partiesFormatted.length > 0) {
      text += partiesFormatted.join(' and ');
    }

    return text;
  }

  get instrumentDateAsDate(): Date {
    return this.instrumentDate ? moment(this.instrumentDate, 'YYYY/MM/DD').toDate() : null;
  }

  getAttachmentByDocumentNumber(documentNumber: string) {
    return this.attachments.find(attachment => attachment.documentNumber === documentNumber);
  }

  get isInstrumentOfTypeTransfer(): boolean {

    return TransferTypes.indexOf(this.type) > -1;

  }

  get isInstrumentTypeOfTeranetConnectInstruments(): boolean {
    return TeranetConnectImportInstruments.indexOf(this.type) > -1;
  }

  nonDeletedChargeType(): boolean {
    return (this.instrumentStatus != 'D' && this.type == 'CHARGE');
  }

  nonDeletedNonTransferType(): boolean {
    return (this.instrumentStatus != 'D' && !this.isInstrumentOfTypeTransfer && this.isInstrumentTypeOfTeranetConnectInstruments);
  }

}
