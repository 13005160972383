<div>


    <!--   <span class="fa fa-plus-square-o dp-mortgage-plus icon-position"></span> -->
    <div>
        <div class="mortgages-header">
            <div class="mortgage-btns">
                    <div *ngIf="hasMortgagesAfterClosing() && !(isExistingMortgageActive() && matter.isSale)" class="float-left">
                        <button #btnChangePriority (click)="changePriority();" class="dp-btn-white">
                            <span>Change Priority</span>
                        </button>
                    </div>
                    <div *ngIf="mortgages && mortgages.length < maximumNumberOfMortgages()" class="float-left margin-left-10">
                        <button #btnAddNew (click)="addMortgage()" class="dp-btn-green">
                            <span>{{getAddNewMortgageLabelByMatter()}}</span>
                        </button>
                    </div>
            </div>

            <ul class="dppm-custom-tab" *ngIf="mortgages && mortgages.length > 0">
                <!-- TAB WITH BURGER MENU EXAMPLE  <li class="active dropdown"><div class="burger-menu-tab"><i class="fa fa-bars"></i></div>
                     <a>1<sup>st</sup> Mortgagee</a>
                      <ul class="dropdown-menu dropdown-content">
                               <li><a>Open</a></li>
                               <li><a>Closed</a></li>
                       </ul>
                 </li>-->
                <li (click)="changeMortgage(i)" class="padding-left-10 padding-right-10"
                    [ngClass]="{'active': mortgageIndex==i, 'hide' : (!isExistingMortgageActive() && row.isExistingMortgage())}"
                    *ngFor="let row of mortgages; let i = index;">
                    <div>
                        <a *ngIf="isExistingMortgageActive()">{{existingTabLabel(i+1)}}<sup>{{existingTabLabelSuffix(i+1)}}</sup>
                        </a>

                        <a *ngIf="!isExistingMortgageActive()  && matter && (matter.isMortgage || matter.isSale ) && !row.isExistingMortgage()">{{row.mortgagePriority
                            }}<sup
                                *ngIf="row.mortgagePriority==1">st
                        </sup><sup
                                *ngIf="row.mortgagePriority==2">nd</sup><sup
                                *ngIf="row.mortgagePriority==3">rd
                        </sup><sup *ngIf="row.mortgagePriority>3">th</sup>
                            Mortgage
                        </a>


                        <a *ngIf="!isExistingMortgageActive() && matter && !matter.isMortgage && !matter.isSale && !row.isExistingMortgage()">{{i+1}}<sup *ngIf="i==0">st
                        </sup><sup
                                *ngIf="i==1">nd
                        </sup><sup *ngIf="i==2">rd
                        </sup><sup *ngIf="i>2">th</sup>
                             Mortgage
                        </a>
                    </div>
                    <div class="text-overflow"><a>{{row.lenderReline?row.lenderReline:''}}</a></div>
                </li>
            </ul>
        </div>
    </div>

    <div class="clear"></div>
    <router-outlet></router-outlet>
    <!--   <div *ngIf=" matter.mortgages && matter.mortgages.length > 0">
           <div *ngFor="let item of matter.mortgages ; let i = index;">
               <div [hidden]="activeMortgage != i">
                   <dp-mortgage [mortgage]="item" (onChange)="markMatterDirty()" [matter]="matter" [activeIndex]="activeMortgage"></dp-mortgage>
               </div>
           </div>
       </div> -->

</div>

