import {api, SESSION_ID_REQ_PARAM} from '../common/api';
import {CirfStatusType} from '../matters/shared/cirf/cirf';

export const opportunitiesApi = {

  accountCirfs(accountId: string): string {
    return `${ api }/accounts/${ accountId }/cirfs`;
  },

  opportunities(accountId: string): string {
    return `${ api }/accounts/${ accountId }/connect/opportunities`;
  },

  readUnreadStatus(accountId: string): string {
    return `${ api }/accounts/${ accountId }/connect/opportunities/markAsRead`;
  },

  exportOpportunityListToCsv(accountId: string): string {
    return `${ api }/accounts/${ accountId }/opportunities/exportListToCsv`;
  },

  opportunitiesByStatus(accountId: string, status: CirfStatusType): string {
    return `${ api }/accounts/${ accountId }/cirfs?status=${ status }`;
  },

  numberOfOpportunitiesByStatus(accountId: string, status: CirfStatusType): string {
    return `${ api }/accounts/${ accountId }/connect/opportunities/count?status=${ status }`;
  },

  cirfs(matterId: number): string {
    return `${ api }/matters/${ matterId }/cirfs`;
  },

  getCIRFByPackageGUIDForUnity(matterId: number, cirfGuid: string): string {
    return `${ api }/matters/${ matterId }/cirfs/${ cirfGuid }`;
  },

  reOpenCirf(matterId: number, cirfId: number): string {
    return `${ api }/matters/${ matterId }/cirfs/${ cirfId }/reopen`;
  },

  cancelCirf(matterId: number, cirfId: number): string {
    return `${ api }/matters/${ matterId }/cirfs/${ cirfId }/cancel`;
  },

  matterCirf(matterId: number): string {
    return `${ api }/matters/${ matterId }/cirfs/matterCirf`;
  },

  completeCIRF(matterId: number, cirfId: number): string {
    return `${ api }/matters/${ matterId }/cirfs/${ cirfId }/complete`;
  },

  referralById(accountId: string, referralId: number): string {
    return `${ api }/accounts/${ accountId }/referrals/${ referralId }`;
  },

  reOpenReferral(accountId: string, referralId: number): string {
    return `${ api }/accounts/${ accountId }/referrals/${ referralId }/reopen`;
  },

  declineReferral(accountId: string, referralId: number): string {
    return `${ api }/accounts/${ accountId }/referrals/${ referralId }/decline`;
  },

  opportunityMatterByReferral(accountId: string, referralId: number): string {
    return `${ api }/accounts/${ accountId }/referrals/${ referralId }/opportunity`;
  },

  acceptReferral(matterId: number, referralId: number): string {
    return `${ api }/matters/${ matterId }/referrals/${ referralId }/accept`;
  },

  getOpportunitiesByMatter(matterId: number): string {
    return `${ api }/matters/${ matterId }/connect/opportunities`;
  },

  downloadReferralDocument(accountId: string, sessionId: string, referralId: number, documentId: number): string {
    return `${ api }/accounts/${ accountId }/referrals/${ referralId }/documents/${ documentId }/download?${ SESSION_ID_REQ_PARAM }=${ sessionId }`;
  }

};
