import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Mortgage} from '../../../shared/mortgage';
import {Matter} from '../../../shared';
import {PayoutStatement} from '../../../../shared-main/telus/payout-statement';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {TelusService} from '../../../../shared-main/telus/telus-service';
import {ApplicationError} from '../../../../core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {AssystPayoutData} from './assyst-payout-data';
import {PayoutStatementStatus} from '../../../../shared-main/telus/payout-statement-status';
import {ModalAction} from '../../../../shared-main/constants';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class AssystImportPayoutModalContext {
  mortgage: Mortgage;
  matter: Matter;
  payoutStatement: PayoutStatement;
}

@Component({
  selector: 'dp-assyst-import-payout-modal-content',
  templateUrl: 'assyst-import-payout.modal.component.html'
})
export class AssystImportPayoutModalComponent extends ModalComponent<AssystImportPayoutModalContext> implements OnInit {

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  latestPayoutStatementStatus: PayoutStatementStatus;

  constructor(public dialog: MatDialogRef<AssystImportPayoutModalComponent, {action: string, assystPayoutData?: AssystPayoutData}>,
              public dialogService: DialogService,
              public telusService: TelusService,
              @Inject(MAT_DIALOG_DATA) context?: AssystImportPayoutModalContext) {
    super(dialog, context);
  }

  ngOnInit() {
    this.latestPayoutStatementStatus = this.context.payoutStatement.getMostRecentActiveDocument();
  }

  get mortgage(): Mortgage {
    return this.context.mortgage;
  }

  get payoutStatement(): PayoutStatement {
    return this.context.payoutStatement;
  }

  close() {
    this.dialog.close({action: ModalAction.CANCEL});
  }

  parsePayoutDocument() {
    if (!this.latestPayoutStatementStatus.isDischargeRegistered()) {
      this.telusService.parsePayoutData(this.context.matter.id, this.latestPayoutStatementStatus.thirdPartyDocumentId).finally(() => {
      }).subscribe((response: AssystPayoutData) => {
          if (this.isRequiredPayoutDataAvailable(response)) {
            this.dialog.close({action: ModalAction.OK, assystPayoutData: response});
          } else {
            this.dialog.close({action: 'PARSING_ERROR'});
          }
        },
        (error: ApplicationError) => {
          this.dialogService.confirm('Information', error.message, true);
        }
      );
    } else {
      this.dialog.close({action: ModalAction.OK});
    }
  }

  isRequiredPayoutDataAvailable(assystPayoutData: AssystPayoutData): boolean {
    if (this.mortgage.isCollateralMortgage()) {
      return !!assystPayoutData.totalPayoutAmount;
    } else {
      return ((!!assystPayoutData.totalPayoutAmount || !!assystPayoutData.projectedTotalPayoffAmount) && !!assystPayoutData.dailyAmountOfInterest);
    }
  }
}
