import {BaseEntity} from '../../shared/BaseEntity/base-entity';

/**
 * As matter type has some additional properties therefore now using following class for it.
 * It's named MatterTypeDTO because there is already a constant with name MatterType
 */
export class MatterTypeInfo extends BaseEntity {
  matterTypeCode: string;
  matterTypeDescription: string;
  customMatterType: boolean;//True for custom matter type including reserved ones like RFC
  systemMatterType: boolean;//True for P,S,M
  reserved: boolean;//true for P,S,M and custom reserved matter type like RFC
}
