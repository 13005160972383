import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../../shared/dialog/modal-dialog.service';
import {DocumentTemplateFile} from '../../../../../matters/document-production/document-template';
import {DocumentProfileService} from '../../document-profile.service';
import {CustomMatterTypeService} from '../../../../custom-matter-types/custom-matter-type.service';
import {Account} from '../../../../accounts/shared/account';
import {AccountService} from '../../../../accounts/account.service';
import {MatterTypeInfo} from '../../../../shared/matter-type-info';
import {TemplateProductionClass} from '../../../../../shared-main/constants';

export class EditDocumentTemplateDetailsContext {
  documentTemplateFile: DocumentTemplateFile;
  documentProfileId: number;
  accountFileFolderId: number;
  account: Account;
}

@Component({
  selector: 'dp-edit-document-template-details-modal-content',
  templateUrl: 'edit-document-template-details.modal.html'
})
export class EditDocumentTemplateDetailsComponent
  extends ModalComponent<EditDocumentTemplateDetailsContext>
  implements OnInit
{
  documentTemplateFile: DocumentTemplateFile;
  matterTypes: any[] = [];
  isMultiClientProductionClass: boolean;

  constructor(
    public dialog: MatDialogRef<EditDocumentTemplateDetailsComponent>,
    private documentProfileService: DocumentProfileService,
    public accountService: AccountService,
    public customMatterTypeService: CustomMatterTypeService,
    @Inject(MAT_DIALOG_DATA) context?: EditDocumentTemplateDetailsContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.documentTemplateFile = new DocumentTemplateFile(this.context.documentTemplateFile);
    this.buildMatterTypesDropDown();
    this.isMultiClientProductionClass =
      this.documentTemplateFile.documentTemplate.templateProductionClass === TemplateProductionClass.MULTI_CLIENT;
  }

  async buildMatterTypesDropDown(): Promise<void> {
    this.matterTypes = [];
    let allMatterTypes: MatterTypeInfo[] = await this.accountService
      .getMatterTypesDetails(this.context.account)
      .toPromise();
    if (allMatterTypes && allMatterTypes.length) {
      allMatterTypes.forEach((matterType: MatterTypeInfo) => {
        this.matterTypes.push({label: matterType.matterTypeDescription, value: matterType.matterTypeCode});
      });
    }
  }

  updateMultiSelectFilters(event: string[]): void {
    this.documentTemplateFile.documentTemplate.applicableMatterTypeCodes = event;
  }

  getDocumentTemplateMatterTypes(): string[] {
    if (
      this.documentTemplateFile &&
      this.documentTemplateFile.documentTemplate &&
      Array.isArray(this.documentTemplateFile.documentTemplate.applicableMatterTypeCodes)
    ) {
      return this.documentTemplateFile.documentTemplate.applicableMatterTypeCodes;
    } else {
      return [];
    }
  }

  save() {
    this.documentProfileService
      .editDocTemplateFile(this.context.accountFileFolderId, this.documentTemplateFile)
      .subscribe((data) => {
        this.context.documentTemplateFile.documentTemplate.description =
          this.documentTemplateFile.documentTemplate.description;
        this.context.documentTemplateFile.documentTemplate.applicableMatterTypeCodes =
          this.documentTemplateFile.documentTemplate.applicableMatterTypeCodes;
        this.context.documentTemplateFile.documentTemplate.templateProductionClass =
          this.documentTemplateFile.documentTemplate.templateProductionClass;
        this.dialog.close();
      });
  }

  close() {
    this.dialog.close();
  }

  onTemplateProductionClassChange(event): void {
    if (this.isMultiClientProductionClass) {
      this.documentTemplateFile.documentTemplate.templateProductionClass = TemplateProductionClass.MULTI_CLIENT;
    } else {
      this.documentTemplateFile.documentTemplate.templateProductionClass = TemplateProductionClass.REGULAR;
    }
  }
}
