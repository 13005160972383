import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class GovermentSiteLink extends BaseEntity {

  id: number;
  governmentSiteLinkCategory: string;
  governmentSiteLinkName: string;
  governmentSiteLinkUrl: string;

  constructor(govermentSiteLink?: GovermentSiteLink) {
    super(govermentSiteLink);
  }

}
