import {Component, Inject} from '@angular/core';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Matter} from '../../shared/matter';
import moment from 'moment';
import {Account} from '../../../admin/accounts/shared/account';
import Moment = moment.Moment;
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class PrintTransactionModalContext {
  matter: Matter;
  noticeType: string;
  account: Account;
}

@Component({
  selector: 'print-transaction-modal-content',
  templateUrl: './print-transaction-modal.html'
})
export class PrintTransactionModalComponent extends ModalComponent<PrintTransactionModalContext> {
  matter: Matter;
  noticeType: string;
  account: Account;
  vendorReLine: string = '';
  reType: string = '';
  orderNumber: string = '';
  transactionCharge: string = '';
  subTotal: string = '';
  orderDate: string = '';

  constructor(
    public dialog: MatDialogRef<PrintTransactionModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: PrintTransactionModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.matter = this.context.matter;
    this.noticeType = this.context.noticeType;
    this.account = this.context.account;

    if (this.matter && this.matter.newMortgages && this.matter.newMortgages.length > 0) {
      this.matter.reCalculateLenderReLine(this.matter.newMortgages[0]);
    }

    this.vendorReLine = this.getVendorReLine();

    this.reType = this.getRetype();
    this.orderNumber = this.getOrderNumber();
    this.transactionCharge = this.getTransactionCharge();
    this.subTotal = this.getSubTotal();
    this.orderDate = this.getOrderDate();
  }

  getMatterTypeShortName(m) {
    return m == null ? '' : m.charAt(0);
  }

  getOrderNumber() {
    if (this.billingInfo && this.billingInfo.orderNumber) {
      return this.billingInfo.orderNumber;
    } else {
      return 'Trial Per';
    }
  }

  getTransactionCharge() {
    if (this.billingInfo && this.billingInfo.transactionCharge && this.billingInfo.transactionCharge > 0) {
      return this.billingInfo.transactionCharge.toFixed(2);
    } else {
      return '0.00';
    }
  }

  getSubTotal() {
    let subTotal: number = 0;
    if (this.billingInfo && this.billingInfo.transactionCharge) {
      let transCharge: number = this.billingInfo.transactionCharge;
      subTotal = Number(transCharge);
      if (this.billingInfo.federalTax) {
        subTotal = subTotal - this.billingInfo.federalTax;
      }
      if (this.billingInfo.provincialTax) {
        subTotal = subTotal - this.billingInfo.provincialTax;
      }
    }
    return subTotal.toFixed(2);
  }

  getOrderDate() {
    let oDate: Moment = moment(this.billingInfo.createdTimeStamp);
    return oDate.format('MMMM DD, YYYY');
  }

  getVendorReLine() {
    if (this.matter.matterType == 'PURCHASE') {
      if (this.matter.contactInfo.length > 0) {
        let contactInfoObj: any = this.matter.contactInfo.filter((item) => item.contactInfoType == 'VENDOR');
        if (contactInfoObj.length > 0 && contactInfoObj[0] && contactInfoObj[0].reline) {
          return contactInfoObj[0].reline;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else if (this.matter.matterType == 'SALE') {
      if (this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline) {
        return this.matter.otherPartyContactInfo.reline;
      } else {
        return '';
      }
    } else if (this.matter.matterType == 'MORTGAGE') {
      if (
        this.matter &&
        this.matter.newMortgages &&
        this.matter.newMortgages.length > 0 &&
        this.matter.newMortgages[0].lenderReline != null
      ) {
        return this.matter.newMortgages[0].lenderReline;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getRetype() {
    switch (this.matter.matterType) {
      case 'PURCHASE':
        return ' purchase from ';
      case 'SALE':
        return ' sale to ';
      case 'MORTGAGE':
        return ' mortgage from ';
      default:
        return '';
    }
  }

  get billingInfo() {
    let billingObj: any;
    if (this.noticeType == 'CREDIT') {
      billingObj = this.matter.billingTransactions.filter((item) => item.transactionType == 'REFUND');
    } else {
      billingObj = this.matter.billingTransactions.filter((item) => item.transactionType == 'CHARGE');
    }
    return billingObj && billingObj.length > 0 ? billingObj[0] : '';
  }

  ok(): void {
    this.dialog.close();
  }

  cancel(): void {
    this.dialog.close();
  }
}
