import {Component, Inject} from '@angular/core';
import {MatterParticipant} from '../../../shared/matter-participant';
import {PurchaserCapacity} from '../../../purchaser/capacity/purchaser-capacity';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class PrivateLendersTitleContext {
  privateLenders: MatterParticipant[];
}

@Component({
  selector: 'dp-private-lenders-title',
  templateUrl: 'private-lenders-title.component.html',
  styleUrls: [
    './private-lenders-title.component.scss'
  ]
})
export class PrivateLendersTitleComponent extends ModalComponent<PrivateLendersTitleContext> {
  privateLenders: MatterParticipant[];
  header: string;

  constructor(public dialog: MatDialogRef<PrivateLendersTitleComponent>,
              @Inject(MAT_DIALOG_DATA) context?: PrivateLendersTitleContext
  ) {
    super(dialog, context);
    this.privateLenders = this.context.privateLenders;
    this.header = 'Title Details';
  }

  getLenderName(name: string): string {
    if (name.indexOf(',') > -1) {
      let nameParts = name.split(',');
      name = nameParts[ 0 ].toUpperCase() + ',' + nameParts[ 1 ];
    } else {
      name = name.toUpperCase();
    }
    return name;
  }

  close(): void {
    this.dialog.close();
  }

  getPurchaserCapacity(purchaser: MatterParticipant): string {
    let capacityOption = PurchaserCapacity.purchaserCapacityOptions.find(option => option.value == purchaser.purchaserCapacity);
    return capacityOption ? capacityOption.label : '';
  }
}
