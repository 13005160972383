import {Routes} from '@angular/router';
import {LoginComponent} from "./login.component";
import {ForgotPasswordComponent} from "./forgot-password";
import {SetupPasswordComponent} from "./first-login/setup-password/setup-password.component";
import {RegisterComponent} from "./first-login/register/register.component";

export const LoginRoutes: Routes = [
  {
    path: 'login',
    children: [
      {
        path: 'home',
        pathMatch: 'full',
        component: LoginComponent,
      },
      {
        path: 'home/:timeout',
        component: LoginComponent,
      },
      {
        path: 'about-unity',
        component: LoginComponent,
      },
      {
        path: 'contact-us',
        component: LoginComponent,
      },
      {
        path: 'privacy-policy',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: SetupPasswordComponent
      },
      {
        path: 'setup-password',
        component: SetupPasswordComponent,
      },
      {
        path: 'register/:token',
        component: RegisterComponent,
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      }
    ]
  },
];