import {Instrument} from '../../shared-main/teranet/instrument';
import {TeranetDocument, TeranetDocumentStatus} from '../../shared-main/teranet/teranet-document';
import {BurgerMenuExtendedItem} from './burger-menu-extended-item';
import {MatterParticipant} from './index';
import {MatterParticipantRole} from './matter-participant-role-types';
import {ContactNameUtil} from './contact-name-util';
import {PartyOrigin, Writ} from '../../shared-main/teranet/property-writ';
import {TeranetDocket} from '../../shared-main/teranet/teranet-docket';
import {TeranetPropertyOwner} from '../../shared-main/teranet/teranet-property-owner';

//This is the wrapper class for writ. It encapsulates writ and primary writ together.
export class WritWrapper {
  writ: Writ;
  primaryWrit: Writ;
  writBurgerMenuItems: BurgerMenuExtendedItem[];
}

//This wrapper class contains the writs array for displaying on UI. By default writs are only displayed on frontend and they are persisted to backend only if
// they have been searched. The array in this wrapper contains all the writs originated from different sources (dynamic & persisted).
export class WritsCollection {
  teranetDocket: TeranetDocket;
  writsWrapper: WritWrapper[] = [];

  constructor(teranetDocket: TeranetDocket) {
    this.teranetDocket = teranetDocket;
  }

  //Add a new element to the writ array if not already exists.
  add(writ: Writ) {
    if (this.writsWrapper.findIndex(writWrapper => writWrapper.writ.partyNameHash === writ.partyNameHash
      && writWrapper.writ.enforcementOffice == writ.enforcementOffice) == -1) {
      this.writsWrapper.push(this.createWritWrapper(writ));
    }
  }

  //Add a new element to the writ array if not already exists.
  addFromMatterParticipant(writ: Writ) {
    if (!writ.enforcementOffice //if from matterParticipant, then the EO value is blank
      && !this.writsWrapper.some(writWrapper =>
        writWrapper.writ.partyNameHash === writ.partyNameHash //share the same name hash
        && (writWrapper.writ.partyOrigin == writ.partyOrigin //share the same matterParticipantRole like PURCHSER
          || writWrapper.writ.partyOrigin == 'CURRENT_OWNER') //prevent duplication of names from parcel register and vendors (sale matter) and mortgagors (mortgage matter), especially after import
      )) {//if there is no existing writ search result share the same name and same ROLE, then add it (for multi-MP case)
      this.writsWrapper.push(this.createWritWrapper(writ));
    }
  }

  createWritWrapper(writ: Writ): WritWrapper {
    let writWrapper: WritWrapper = new WritWrapper();
    writWrapper.writ = writ;
    writWrapper.primaryWrit = this.teranetDocket.getPrimaryWrit(writ);
    return writWrapper;
  }

  //converting and adding owner to writ
  addOwner(propertyOwner: TeranetPropertyOwner, enforcementOfficeName: string) {
    let writ: Writ = Writ.createWritWithDefaultValues();
    writ.enforcementOffice = enforcementOfficeName;
    this.setWritPartyNameFromTeranetData(writ, propertyOwner.fullName);
    writ.partyOrigin = 'CURRENT_OWNER';
    this.add(writ);
  }

  //This method sets the party name with the fullName recieved from Teranet data.
  setWritPartyNameFromTeranetData(writ: Writ, fullName: string) {
    if (ContactNameUtil.isItCorporation(fullName)) {
      //If Company then save company name in givenName field
      writ.partyType = 'Company';
      writ.givenName = fullName;
    } else {
      //If Person then split the fullName into surname & given name
      let splittedName = ContactNameUtil.splitLastAndGivenName(fullName);
      writ.partyType = 'Person';
      writ.lastName = splittedName[ 0 ];
      if (splittedName.length > 1) {
        writ.givenName = splittedName[ 1 ];
      }
    }
  }

  //converting and adding mp to writ
  addMatterParticipant(mp: MatterParticipant) {
    let writ: Writ = Writ.createWritWithDefaultValues();
    writ.partyType = mp.contact.isCorporationOrOtherEntity ? 'Company' : 'Person';

    if (mp.contact.isPerson) {
      writ.lastName = mp.contact.lastName;
      writ.givenName = (mp.contact.firstName ? mp.contact.firstName : '') + ' ' + (mp.contact.middleName ? mp.contact.middleName : '');
    } else {
      writ.givenName = mp.contact.organizationName;
    }
    writ.partyOrigin = WritsCollection.getPartyOrigin(mp.matterParticipantRole);
    //need to verify whether we already have the writ search based on this matterParticipant before add it to the list
    this.addFromMatterParticipant(writ);
  }

  addInstrumentPartyToWrit(party: string, isRegistryProperty: boolean) {
    let writ: Writ = Writ.createWritWithDefaultValues();
    this.setWritPartyNameFromTeranetData(writ, party);
    writ.partyOrigin = isRegistryProperty ? 'TRANSFEREE' : 'OWNER';
    this.add(writ);
  }

  //Though the values of partyOrigin & MatterParticipantRole are same but still we had to map it because there Types are different.
  private static getPartyOrigin(mp: MatterParticipantRole): PartyOrigin {
    switch (mp) {
      case 'PURCHASER':
        return 'PURCHASER';
      case 'VENDOR':
        return 'VENDOR';
      case 'MORTGAGOR':
        return 'MORTGAGOR';
      case 'CONDO_CORPORATION':
        return 'CONDO_CORPORATION';
      case 'GUARANTOR':
        return 'GUARANTOR';
    }
  }

}

export class InstrumentWrapper {
  instrument: Instrument;
  attachment: TeranetDocument;
  //As same instrument/attachment can exists in multiple properties and all of them will share the document data, therefore we select the first one as
  // primary document and use that for saving & displaying document data
  primaryDocument: TeranetDocument;
  attachmentInstrumentNumber: string; //In case of attachment the parent instrument number is kept in this field. Required for order instrument.
  last: boolean = false; //true if this is the last attachment
  instrumentBurgerMenuItems: BurgerMenuExtendedItem[];

  //Below fields added to instrument wrapper to facilitate preparing of data for display in instrument table ahead of time,
  // to avoid using getters in HTML
  undertakingStatus: string;
  requisitionStatus: string;
  hoverText: string;
  typeContext: string;
  amountFormatted: string;
  hover: boolean = false;

  get documentStatus(): TeranetDocumentStatus {
    return this.isAttachmentWrapper ? this.attachment.documentStatus : this.instrument.instrumentDocumentStatus;
  }

  get instrumentNumber(): string {
    return this.isAttachmentWrapper ? this.attachment.documentName : this.instrument.instrumentNumber;
  }

  get instrumentDate(): string {
    return this.isAttachmentWrapper ? '' : this.instrument.instrumentDate;
  }

  get isAttachmentWrapper(): boolean {
    return !!this.attachment;
  }

  get isInstrumentWrapper(): boolean {
    return !!this.instrument;
  }

  get id(): number {
    return this.instrument ? this.instrument.id : (this.attachment ? this.attachment.id : null);
  }

  get teranetDocumentId(): number {
    return this.isAttachmentWrapper ? this.attachment.id : this.instrument.instrumentDocument.id;
  }

  get courierDate(): string {
    return this.isAttachmentWrapper ? '' : this.instrument.courierDate;
  }

}
