<form class="form-horizontal">
    <div class="container-fluid custom-modal-container">


        <div class="row modal-body">
            <lib-LandingWidgets (enableContinueBtn)="enableWPContinueBtn($event)"></lib-LandingWidgets>
        </div>


        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn2"
                        [disabled]="contButtonDisabled"
                        (click)="close()"
                        class="dp-btn">
                    <span>Continue</span>
                </button>
            </div>
        </div>
    </div>
</form>
