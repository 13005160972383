import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgModel} from '@angular/forms';

declare var jQuery: any;

/*
 *  MatterNoFilterDirective implements two function
 *  1. Removes all invalid characters
 *  2. Put cursor back to the correct position
 */

@Directive({
  selector: '[dp-matterNoFilter]'
})

export class MatterNoFilterDirective {
  constructor(private el: ElementRef, private ngModel: NgModel) {
  }

  /*
   * Using NgModel instead of ngModelChange event emiitter for immediate value change
   * It helps getting rid of setTimeout which was causing lot's of editing hiccups
   */
  @HostListener('input') onInput(): void {
    let originalValue = this.el.nativeElement.value;
    let currentInputControl = this.el.nativeElement;
    let currentCursorPosition = this.el.nativeElement.selectionStart;
    let value: string = this.el.nativeElement.value.replace(/[^\w&\$#@\-!\{\}]/g, '');
    if (originalValue != value) {
      this.ngModel.reset(value);
      if ((currentInputControl != null) && (currentCursorPosition != null) && jQuery(currentInputControl).is(':focus')) {
        currentInputControl.setSelectionRange(currentCursorPosition - 1, currentCursorPosition - 1);
      }
    }
  }
}
