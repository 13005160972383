import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TotalTransactionCount extends BaseEntity {
  purchaseCount;
  saleCount;
  projectSaleCount;
  mortgageCount;
  dischargeCount;
  otherCount;
  willCount: number;
  total;

  constructor(totalTransactionCount?: TotalTransactionCount) {
    super(totalTransactionCount);
  }
}
