<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo"></span></a>
            </div>
        </div>
        <div class="privacy-policy-box">
            <div class="text">
                <h1 class="margin-bottom-20 header">Terms & Conditions</h1>
                <span class="padding-bottom-20 padding-top-20 display-block">Terms & Conditions have changed and must be accepted in order to utilize Unity&reg; Practice Management</span>
                <div class="terms-box">

                    <div>
                        <div innerHtml="{{termsAndConditions}}"></div>
                    </div>
                    <div class="padding-top-20">
                        <dp-checkbox fieldId="accepted">
                            <input id="accepted"
                                   name="accepted"
                                   type="checkbox"
                                   [(ngModel)]="accepted"
                                   #rememberMe
                            />
                        </dp-checkbox>
                        <label class="control-label padding-left-10 display-inline" (click)="accepted=!accepted">I
                            Accept</label>
                    </div>
                    <div class="form-group margin-top-20 margin-bottom-60">
                        <button type="submit" class="dp-btn-green" [disabled]="!accepted"
                                (click)="submit()">
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>
<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>

