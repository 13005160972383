import {Jurisdiction, RegistryOffice} from '../../matters/shared/jurisdiction';
import {ParcelRegister} from './parcel-register';
import {Instrument} from './instrument';
import {MatterProperty, MatterPropertyDescriptionType} from '../../matters/shared/matter-property';
import {CondominiumExpense} from '../../matters/property-teranet/unit-level-plan/condominium-expense';
import {CondominiumPlan} from '../../matters/property-teranet/unit-level-plan/condominium-plan';
import {Address} from '../../matters/shared/address';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {DpBooleanValue} from '../../matters/shared/dp-boolean';
import {ParcelLegalDescription} from '../../matters/shared/parcel-legal-description';
import {ParcelTitle} from '../../matters/shared/parcel-title';
import {AddressUtil} from '../../matters/shared/address-util';
import {PROVINCE_CODES} from '../../matters/shared/user-province';
import {ImportPropertyDataService} from '../../matters/shared/services/import-property-data.service';

export interface StreetAddressOption {
  label: string;
  value: Address;
}

export class TeranetImportData {

  propertyDescription: string;
  propertyDescriptionForPart: string; //used for Part(Lot) and Being Part only
  jurisdiction: Jurisdiction;
  address: string | Address; //For Teranet it is string and for spin it is of type address
  isCondo: boolean;
  interestEstate: string;
  partLot: string;
  plan: string;
  beingPart: string;
  onPlan: string;
  parcel: string;
  section: string;
  registryOffice: RegistryOffice;
  pin: string;
  lastTransferNumber: string;
  lastInstrumentNumber: string;
  instruments: Instrument[] = [];
  recently: string;
  easementRightOfWay: string;
  city: string;
  municipality: string;
  lro: string;
  unitLevelPlan: string;
  condominiumExpenses: CondominiumExpense[];
  condominiumJurisdiction: string;
  condominiumTotalExpenses: Number;
  condominiumPlans: CondominiumPlan[];

  // streetAddress : Address;
  streetAddressOptions: StreetAddressOption[];
  fieldStreetAddress: boolean = false;
  provinceCode: ProvinceCode;

  unitNo: string;
  levelNo: string;

  exceptionType: DpBooleanValue;
  exceptionTypeDescription: string;
  isCondominium: boolean;
  propertyDescriptionType: MatterPropertyDescriptionType;
  block: string;
  lot: string;
  lincNumber: string;
  titleNumber: string;
  shortLegalDescription: string;
  fullLegalDescription: string;
  parcelRegisterId: number; //spin parcel register being imported

  isLegalDescriptionUpdated: boolean = false; //tracking if legal description related fields changed for special handling when importing Spin parcels
  isShortDescriptionUpdated: boolean = false;

  condominiumPlanNumber: string;
  parcelLegalDescription: ParcelLegalDescription;

  mapParcelRegisterData(parcelRegister: ParcelRegister): void {
    if (PROVINCE_CODES.ALBERTA == this.provinceCode) {
      this.mapParcelRegisterDataAB(parcelRegister);
    } else if (PROVINCE_CODES.MANITOBA == this.provinceCode) {
      this.mapParcelRegisterDataMB(parcelRegister);
    } else if (PROVINCE_CODES.SASKATCHEWAN == this.provinceCode) {
      this.mapParcelRegisterDataSK(parcelRegister);
    } else if (PROVINCE_CODES.BRITISH_COLOMBIA == this.provinceCode) {
      this.mapParcelRegisterDataBC(parcelRegister);
    } else {
      this.mapParcelRegisterDataON(parcelRegister);
    }
  }

  mapParcelRegisterDataON(parcelRegister: ParcelRegister): void {
    this.lro = parcelRegister.lro;
    this.propertyDescription = parcelRegister.propertyDescription;
    this.replaceAbbreviationWith('PT ', 'PART ');
    this.replaceAbbreviationWith('PTS ', 'PARTS ');
    this.replaceAbbreviationWith('LT ', 'LOT ');
    this.replaceAbbreviationWith('LTS ', 'LOTS ');
    this.replaceAbbreviationWith('CON ', 'CONCESSION ');
    this.replaceAbbreviationWith('CONC ', 'CONCESSION ');
    this.replaceAbbreviationWith('CON. ', 'CONCESSION ');
    this.replaceAbbreviationWith('BLK ', 'BLOCK ');
    this.replaceAbbreviationWith('BLKS ', 'BLOCKS ');
    this.replaceAbbreviationWith('PCL ', 'PARCEL ');
    this.replaceAbbreviationWith('PCLS ', 'PARCELS ');
    this.replaceAbbreviationWith('PL ', 'PLAN ');
    this.replaceAbbreviationWith('PLS ', 'PLANS ');
    this.replaceAbbreviationWith('SEC ', 'SECTION ');
    this.replaceAbbreviationWith('SECS ', 'SECTIONS ');
    this.replaceAbbreviationWith('YR ', 'YEAR ');
    this.replaceAbbreviationWith('YRS ', 'YEARS ');
    this.replaceAbbreviationWith('S/T ', 'SUBJECT TO ');
    this.propertyDescriptionForPart = this.propertyDescription;

    //handling special case where part lot is in easement
    this.propertyDescriptionForPart = this.propertyDescriptionForPart.replace(new RegExp('TOGETHER WITH AN EASEMENT OVER PART LOT', 'g'), '');
    this.instruments = parcelRegister.instruments;
    this.recently = parcelRegister.recently;
    this.isCondo = this.isPropertyCondoTC();
    this.address = parcelRegister.address != 'N/A' ? parcelRegister.address : '';
    this.interestEstate = parcelRegister.estate;
    this.partLot = this.getPartAndBlockData();
    this.beingPart = this.getBeingPartsData(); //needs to be done in this sequence otherwise "Being Part" will parse the "Part (Lot)"
    this.onPlan = this.onPlanData;// On Plan Data has to be parsed before Plan Data to compare the values are not same
    this.plan = this.planData;
    this.parcel = this.parcelData;
    this.section = this.sectionData;
    this.pin = parcelRegister.pin;
    this.lastTransferNumber = this.transferInstrument;
    this.lastInstrumentNumber = this.nonDeletedInstrument;
    this.unitLevelPlan = this.unitLevelPlanData;
    this.unitNo = this.parseUnitNumberON();
    this.levelNo = this.parseLevelNumberON();
  }

  //IN Progress
  mapParcelRegisterDataSK(parcelRegister: ParcelRegister): void {
    this.instruments = parcelRegister.instruments;
    this.city = parcelRegister.propertyOwners[ 0 ].address.city;
    this.streetAddressOptions = this.getUniqueAddresses(this.getOwnerDataAddressOptionsForSK(parcelRegister));
    this.address = this.streetAddressOptions && this.streetAddressOptions.length > 0 ? this.streetAddressOptions[ 0 ].value : null;
    this.interestEstate = parcelRegister.estate;
    this.parcelLegalDescription = new ParcelLegalDescription();
    this.parcelLegalDescription.parcelTitles = [];
    this.parcelLegalDescription.parcelTitles.push(new ParcelTitle());

    this.condominiumExpenses = [];
    this.condominiumExpenses.push(new CondominiumExpense());
    this.condominiumPlans = [];
    this.condominiumPlans.push(new CondominiumPlan());

    if (parcelRegister.iscImportData) {
      this.isCondo = parcelRegister.iscImportData.condominium;
      this.propertyDescription = parcelRegister.iscImportData.legalDescription;
      if (this.isCondo) {
        this.parcelLegalDescription.unitNumber = parcelRegister.iscImportData.unit;
        this.parcelLegalDescription.parcelTitles[ 0 ].numberOfIndividualShares = parcelRegister.iscImportData.shares;
        this.condominiumPlans[ 0 ].condominiumPlanNumber = parcelRegister.iscImportData.condoPlan;
        this.condominiumPlans[ 0 ].condominiumPlanType = parcelRegister.iscImportData.condoType;
      } else {
        if (parcelRegister.iscImportData.farmLand) {
          this.parcelLegalDescription.qtrsec = parcelRegister.iscImportData.qtrsec;
          this.parcelLegalDescription.sec = parcelRegister.iscImportData.sec;
          this.parcelLegalDescription.twp = parcelRegister.iscImportData.twp;
          this.parcelLegalDescription.rng = parcelRegister.iscImportData.rge;
          this.parcelLegalDescription.mdn = parcelRegister.iscImportData.mdn;
        } else {
          this.parcelLegalDescription.lotNumber = parcelRegister.iscImportData.lotNumber;
          this.parcelLegalDescription.planNumber = parcelRegister.iscImportData.planNumber;
          this.parcelLegalDescription.blockNumber = parcelRegister.iscImportData.blockNumber;
        }
        this.parcelLegalDescription.mineral = parcelRegister.iscImportData.mineral;
      }
      this.parcelLegalDescription.extensionNumber = parcelRegister.iscImportData.extensionNumber;
      this.parcelLegalDescription.parcelTitles[ 0 ].titleNumber = parcelRegister.titleNumber;
      this.parcelLegalDescription.parcelNumber = parcelRegister.parcelNo;
    }
    this.lastInstrumentNumber = this.getLastInstrumentNumber();
  }

  mapParcelRegisterDataBC(parcelRegister: ParcelRegister): void {
    this.propertyDescription = parcelRegister.propertyDescription;
    this.fullLegalDescription = parcelRegister.propertyDescription;
    this.streetAddressOptions = this.getOwnerDataAddressOptions(parcelRegister);
    this.address = this.streetAddressOptions && this.streetAddressOptions.length > 0 ? this.streetAddressOptions[ 0 ].value : null;
    this.condominiumExpenses = [];
    this.condominiumExpenses.push(new CondominiumExpense());
    this.condominiumPlans = [];

    this.condominiumPlans.push(new CondominiumPlan());
    if (parcelRegister.spinImportData) {
      this.isCondo = parcelRegister.spinImportData.condominium;

      if (parcelRegister.spinImportData.condominium) {
        this.condominiumPlans[ 0 ].condominiumPlanNumber = parcelRegister.spinImportData.plan;

      } else {
        this.plan = parcelRegister.spinImportData.plan;
      }
    }
    this.titleNumber = parcelRegister.titleNumber;
    this.lincNumber = parcelRegister.pin?.replace(/-/g, '');
  }

  mapParcelRegisterDataAB(parcelRegister: ParcelRegister): void {
    this.instruments = parcelRegister.instruments;
    this.lro = parcelRegister.lro;
    this.propertyDescription = parcelRegister.propertyDescription;
    this.streetAddressOptions = this.getOwnerDataAddressOptions(parcelRegister);
    this.address = this.streetAddressOptions && this.streetAddressOptions.length > 0 ? this.streetAddressOptions[ 0 ].value : null;
    this.interestEstate = parcelRegister.estate;

    this.condominiumExpenses = [];
    this.condominiumExpenses.push(new CondominiumExpense());
    this.condominiumPlans = [];
    this.condominiumPlans.push(new CondominiumPlan());

    this.shortLegalDescription = parcelRegister.getSpinShortLegalDescription();
    this.fullLegalDescription = parcelRegister.propertyDescription;
    if (parcelRegister.spinImportData) {
      this.isCondo = parcelRegister.spinImportData.condominium;
      this.unitNo = parcelRegister.spinImportData.unit;

      // If it is condo, plan should be stored in condominiumExpenses.planNumber
      // If it is not condo, plan should be mapped to plan of MatterProperty
      if (parcelRegister.spinImportData.condominium) {
        this.condominiumExpenses[ 0 ].unitNumber = parcelRegister.spinImportData.unit;
        this.condominiumExpenses[ 0 ].noUndividedShare = parcelRegister.spinImportData.undividedShare + '';

        this.condominiumPlans[ 0 ].condominiumPlanNumber = parcelRegister.spinImportData.plan;
        this.condominiumPlans[ 0 ].condominiumPlanType = parcelRegister.spinImportData.condoType;

      } else {
        this.plan = parcelRegister.spinImportData.plan;
      }
      this.block = parcelRegister.spinImportData.block;
      this.lot = parcelRegister.spinImportData.lot;
      this.propertyDescriptionType = parcelRegister.spinImportData.propertyDescriptionType;
      this.exceptionType = parcelRegister.spinImportData.exceptionType;
      this.exceptionTypeDescription = parcelRegister.spinImportData.exceptionTypeDescription;
    }
    this.lincNumber = parcelRegister.propertyDetails.map(item => item.lincNumber).join(', ');
    this.titleNumber = parcelRegister.titleNumber;
    this.lastInstrumentNumber = this.getLastInstrumentNumber();
  }

  getAddressOptions(addressList: Address[]): StreetAddressOption[] {
    let addressOptions: StreetAddressOption[] = [];
    if (Array.isArray(addressList) && (addressList.length > 0)) {
      addressList.forEach(addressItem => {
        let address: Address = new Address(addressItem);
        addressOptions.push({label: address.addressTextWithProvinceName, value: address});
      });
    }
    return addressOptions;
  }

  mapParcelRegisterDataMB(parcelRegister: ParcelRegister): void {
    this.instruments = parcelRegister.instruments;
    this.propertyDescription = parcelRegister.propertyDescription;
    this.interestEstate = parcelRegister.estate;

    this.fullLegalDescription = parcelRegister.propertyDescription;
    this.parcelLegalDescription = new ParcelLegalDescription();

    this.streetAddressOptions = this.getOwnerDataAddressOptions(parcelRegister);
    this.address = this.streetAddressOptions && this.streetAddressOptions.length > 0 ? this.streetAddressOptions[ 0 ].value : null;

    if (parcelRegister.tprImportData) {
      this.parcelLegalDescription.parcelNumber = parcelRegister.titleNumber;

      this.isCondo = parcelRegister.tprImportData.condominiumFlag;
      if (this.isCondo) {
        this.parcelLegalDescription.unitNumber = parcelRegister.tprImportData.unit;
        const percentageInterest: string = parcelRegister.tprImportData.percentageInterest;
        this.parcelLegalDescription.percentageInterest = percentageInterest;
        if (percentageInterest && percentageInterest.endsWith('PERCENT')) {
          this.parcelLegalDescription.percentageInterest = percentageInterest.substring(0, percentageInterest.lastIndexOf('PERCENT')).trim();
        }
        this.condominiumPlanNumber = parcelRegister.tprImportData.plan;
      } else {
        this.parcelLegalDescription.memo = parcelRegister.propertyDescription;
      }
    }
  }

  private parseLevelNumberON(): string {
    let levelNo: string = '';
    let unitLevelPlan = this.unitLevelPlan.split(',');
    let levelItem: string = unitLevelPlan.find(item => item.indexOf('Level') > -1);
    let levelIndex = levelItem && levelItem.indexOf('Level');
    let level: string;

    if (levelIndex > -1) {
      level = levelItem.slice(levelIndex + 'Level'.length);
      levelNo = level && level.trim();
    }

    return levelNo;
  }

  private parseUnitNumberON(): string {
    let unitNo: string = '';
    let unitLevelPlan = this.unitLevelPlan.split(',');
    let unitItem: string = unitLevelPlan.find(item => item.indexOf('Unit') > -1);
    let unitIndex = unitItem && unitItem.indexOf('Unit');
    let unit;

    if (unitIndex > -1) {
      unit = unitItem.slice(unitIndex + 'Unit'.length);
      unitNo = unit && unit.trim();
    }

    return unitNo;
  }

  getUnitNumberAB(): string {
    return this.unitNo;
  }

  getFirstOwnerDataAddress(parcelRegister: ParcelRegister) {
    return (parcelRegister.propertyOwners && (parcelRegister.propertyOwners.length > 0)
      && parcelRegister.propertyOwners[ 0 ].address && parcelRegister.propertyOwners[ 0 ].address);
  }

  getOwnerDataAddressOptions(parcelRegister: ParcelRegister): StreetAddressOption[] {
    let ownerDataAddressOptions: StreetAddressOption[] = [];
    if (parcelRegister.propertyOwners && (parcelRegister.propertyOwners.length > 0)) {
      parcelRegister.propertyOwners.forEach(item => {
        if (item.address && !AddressUtil.isCareOfInAddressLines(item.address)) {
          let address: Address = new Address(item.address);
          ownerDataAddressOptions.push({label: address.addressTextWithProvinceName, value: address});
        }
      });
    }
    return ownerDataAddressOptions;
  }

  getOwnerDataAddressOptionsForSK(parcelRegister: ParcelRegister): StreetAddressOption[] {
    let ownerDataAddressOptions: StreetAddressOption[] = [];
    if (parcelRegister.propertyOwners && (parcelRegister.propertyOwners.length > 0)) {
      parcelRegister.propertyOwners.forEach(item => {
        if (item.address && !AddressUtil.isCareOfInAddressLines(item.address)) {
          let address: Address = new Address(AddressUtil.parseOwnerAddressSK(item.address, true));
          ownerDataAddressOptions.push({label: address.addressTextWithProvinceName, value: address});
        }
      });
    }
    return ownerDataAddressOptions;
  }

  getUniqueAddresses(streetAddressOptions: StreetAddressOption[]): StreetAddressOption[] {
    let result: StreetAddressOption[] = [];
    let map = new Map();
    if (Array.isArray(streetAddressOptions)) {
      for (let i: number = 0; i < streetAddressOptions.length; i++) {
        if (!map.has(streetAddressOptions[ i ].label)) {
          map.set(streetAddressOptions[ i ].label, streetAddressOptions[ i ].value);
          result.push({label: streetAddressOptions[ i ].label, value: streetAddressOptions[ i ].value});
        }
      }
    }
    return result;
  }

  mapSubjectPropertyData(matterProperty: MatterProperty): void {
    this.jurisdiction = matterProperty.jurisdiction;
    this.address = matterProperty.address ? (ImportPropertyDataService.hasObjectAddress(this.provinceCode) ? matterProperty.address : matterProperty.address.addressTextWithoutCountry) : '';
    this.isCondo = matterProperty.isPropertyCondominium();
    this.interestEstate = matterProperty.interestEstate;
    this.partLot = matterProperty.partLot;
    this.plan = matterProperty.plan;
    this.onPlan = matterProperty.onPlan;
    this.beingPart = matterProperty.beingPart;
    this.parcel = matterProperty.parcel;
    this.section = matterProperty.section;
    this.easementRightOfWay = matterProperty.easementRightOfWay;
    this.city = matterProperty.city;
    this.municipality = matterProperty.municipality;
    this.registryOffice = matterProperty.registryOffice;
    this.lastTransferNumber = matterProperty.lastTransferNumber;
    this.lastInstrumentNumber = matterProperty.lastInstrumentNumber;
    this.pin = matterProperty.pin;

    if (this.provinceCode == PROVINCE_CODES.ALBERTA || this.provinceCode == PROVINCE_CODES.BRITISH_COLOMBIA) {
      this.condominiumExpenses = [];
      this.condominiumExpenses.push(new CondominiumExpense());
      this.condominiumPlans = [];
      this.condominiumPlans.push(new CondominiumPlan());
      this.condominiumExpenses[ 0 ].noUndividedShare = null;
      this.block = matterProperty.block;
      this.lot = matterProperty.lot;
      this.exceptionType = matterProperty.exceptionType;
      this.exceptionTypeDescription = matterProperty.exceptionTypeDescription;
      this.titleNumber = matterProperty.titleNumber;
      this.shortLegalDescription = matterProperty.shortLegalDescription;
      this.fullLegalDescription = matterProperty.fullLegalDescription;
    }
    if (this.provinceCode == 'SK') {
      if (matterProperty.condominiumPlans && matterProperty.condominiumPlans.length > 0) {
        this.condominiumPlans = [];
        this.condominiumPlans.push(matterProperty.condominiumPlans[ 0 ]);
      } else {
        this.condominiumPlans = [];
        this.condominiumPlans.push(new CondominiumPlan());
        this.condominiumPlans[ 0 ].condominiumPlanType = 'CONVENTIONAL_CONDOMINIUM_PLAN';
      }
      this.parcelLegalDescription = new ParcelLegalDescription();
      this.parcelLegalDescription.parcelTitles = [];
      this.parcelLegalDescription.parcelTitles.push(new ParcelTitle());
    }

    if (this.provinceCode == 'MB') {
      this.parcelLegalDescription = new ParcelLegalDescription();
      this.condominiumPlanNumber = matterProperty.condominiumPlanNumber;
    }

  }

  isRecentlyCondo(): boolean {
    return (this.recently && this.recently.trim().toLowerCase() == 'condominium');
  }

  isPropertyCondoTC(): boolean {
    return ((this.isRecentlyCondo() || this.propertyDescription.search(/CONDOMINIUM/i) > -1)
      && this.propertyDescription.search(/COMMON ELEMENTS CONDO/i) < 0 && this.propertyDescription.search(/COMMON ELEMENT CONDO/i) < 0);
  }

  replaceAbbreviationWith(searchStr: string, replaceStr: string): void {
    this.propertyDescription = this.propertyDescription.replace(new RegExp(searchStr, 'g'), replaceStr);
  }

  private getPartAndBlockData(): string {
    let regexList = [ {regex: 'PART LOTS ', text: 'Part Lots'}, {regex: 'PART LOT ', text: 'Part Lot'},
      {regex: 'PART BLOCKS ', text: 'Part Blocks'}, {regex: 'PART BLOCK ', text: 'Part Block'}, {regex: 'LOT ', text: 'Lot'},
      {regex: 'LOTS ', text: 'Lots'}, {regex: 'BLOCK ', text: 'Block'}, {regex: 'BLOCKS ', text: 'Blocks'} ];

    return this.extractData(regexList, false, true);

  }

  private extractData(regexList: any[], getFirstMatch?: boolean, isPartData?: boolean, isExtractForPlan?: boolean): string {
    let extractedData: string[] = [];
    let propertyDescriptionText: string = isPartData ? this.propertyDescriptionForPart : this.propertyDescription;
    regexList.forEach(item => {
      let regexStr = item.text.trim().toLowerCase().endsWith('s') ? new RegExp(item.regex + '(.*?)((\\s*(\\AND|\\&)\\s*(\\S*?)(\\s|;|\\,))|(\\s|;|\\,))', 'i') : new RegExp(item.regex + '(.*?)(\\s*)(\\;|\\ |\\,)', 'i');
      let regexVal = propertyDescriptionText.match(regexStr);
      if (regexVal && regexVal.length > 1 && new RegExp('\\d').test(regexVal[ 0 ])) {
        if (item.regex == this.lro + 'R') {
          extractedData.push((item.text + ' ' + regexVal[ 0 ]).replace(';', '').replace(',', ''));
        } else if (!isExtractForPlan || (isExtractForPlan && !this.isDataMatchesOnPlanData(regexVal[ 1 ]))) {
          extractedData.push(regexVal[ 0 ].replace(item.regex, item.text + ' ').replace(';', '').replace(',', ''));
        }
        propertyDescriptionText = propertyDescriptionText.replace(regexStr, '');
        if (isPartData) {
          this.propertyDescriptionForPart = propertyDescriptionText; //remove matched expression from description so they get excluded for Being Part
        }
      }
    });
    if (extractedData.length == 0) {
      return '';
    } else if (getFirstMatch) {
      return extractedData[ 0 ];
    } else {
      let extractedDataStr: string = extractedData.length < 2 ? extractedData.join(', ') : extractedData.slice(0, -1).join(', ') + ' & ' + extractedData.slice(-1);
      return extractedDataStr;
    }
  }

  isDataMatchesOnPlanData(regexVal: string): boolean {
    let onPlanStr = this.onPlanDataRegexList[ 0 ].text;
    return regexVal == this.onPlanData.substring(this.onPlanData.toUpperCase().indexOf(onPlanStr.toUpperCase()) + onPlanStr.length).trim();

  }

  get planData(): string {

    let regexList = [ {regex: 'PLAN ', text: 'Plan'}, {regex: 'PLANS ', text: 'Plan'},
      {regex: 'PLAN NO. ', text: 'Plan'},
      {regex: 'CONCESSION ', text: 'Concession'} ];

    return this.extractData(regexList, true, false, true);
  }

  get onPlanData(): string {
    //let regexList = [{regex : 'ON PLAN ', text : 'On Plan'} , {regex : this.lro + 'R', text : 'On Plan'}];
    return this.extractData(this.onPlanDataRegexList);
  }

  get onPlanDataRegexList(): any[] {
    return [ {regex: 'ON PLAN ', text: 'On Plan'}, {regex: this.lro + 'R', text: 'On Plan'} ];
  }

  get sectionData(): string {
    let regexList = [ {regex: 'SECTION ', text: 'Section'}, {regex: 'SECTIONS ', text: 'Sections'} ];
    return this.extractData(regexList);

  }

  get parcelData(): string {
    let regexList = [ {regex: 'PARCEL ', text: 'Parcel'}, {regex: 'PARCELS ', text: 'Parcels'} ];
    return this.extractData(regexList);

  }

  get transferInstrument(): string {
    let instrument = this.instruments.slice(0).reverse().find(item => item.type == 'TRANSFER' && item.instrumentStatus !== 'D');
    return instrument ? instrument.instrumentNumber : '';
  }

  get nonDeletedInstrument(): string {
    let instrument = this.instruments.slice(0).reverse().find(item => item.instrumentStatus !== 'D');
    return instrument ? instrument.instrumentNumber : '';
  }

  getLastInstrumentNumber(): string {
    // let instrument = this.instruments[this.instruments.length - 1];
    let instrument;
    if (Array.isArray(this.instruments) && this.instruments.length > 0) {
      instrument = this.instruments[ this.instruments.length - 1 ];
    }
    return instrument ? instrument.instrumentNumber : '';
  }

  private getBeingPartsData(): string {
    //the first two regexes are a subset of the second two, so the second two are likely not necessary.
    let regexList = [ {regex: 'PART ', text: 'Being Part'}, {regex: 'PARTS ', text: 'Being Parts'}, {
      regex: 'BEING PART ',
      text: 'Being Part'
    },
      {regex: 'BEING PARTS ', text: 'Being Parts'} ];
    return this.extractData(regexList, false, true);
  }

  get unitLevelPlanData(): string {
    let partAndBlockData: string[] = [];
    let regexList = [ {regex: 'Unit', text: 'Unit'}, {regex: 'Level', text: 'Level'} ];
    regexList.forEach(item => {
      let regexStr = new RegExp(item.regex + '(.*)', 'i');
      let regexSplit = this.propertyDescription.match(regexStr);
      if (regexSplit && regexSplit.length > 1) {
        let regexVal = regexSplit[ 1 ].match(/\ (.*?)\ /);
        if (regexVal && regexVal.length > 1) {
          partAndBlockData.push(item.text + ' ' + regexVal[ 1 ].replace(',', '').replace(';', ''));
        }
      }
    });
    if (partAndBlockData.length == 0) {
      return '';
    } else {
      partAndBlockData.push(this.pin);
      return partAndBlockData.join(', ');
    }
  }

  get planNumber(): string {
    // return (this.planData && this.planData.split(" ").length > 1 ? this.planData.split(" ")[1] :'')
    let planDataSplits: string[] = this.planData && this.planData.split(' ');
    return (Array.isArray(planDataSplits) && planDataSplits.length > 1 ? planDataSplits[ 1 ] : '');
  }

  get isMetesAndBounds() {
    return this.propertyDescriptionType === 'METES_AND_BOUNDS';
  }

  get isPlanBlockLot() {
    return this.propertyDescriptionType === 'PLAN_BLOCK_LOT';
  }
}
