import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {RequisitionTemplate} from '../shared/requisition-template';
import * as _ from 'lodash';
import {
  requisitionCategoryDropdownOptions,
  requisitionStatusDropdownOptions
} from '../mortgages/mortgage/dropdown-options';
import {Matter} from '../shared/matter';
import {currentMatter} from '../shared/current-matter';
import {DialogService} from '../../shared/dialog/dialog.service';
import {messages} from '../../common/messages';
import {RequisitionsService} from './requisitions.service';
import {CkEditorUtil} from '../shared/ckeditor-util';
import {RequisitionsEditorModalResult} from './requisitions-editor.modal.enum';
import {Instrument} from '../../shared-main/teranet/instrument';
import {UUIDUtil} from '../../main/uuid-util';
import {RequisitionInstrumentConfig} from '../shared/requisition-instrument-config';
import Utils from '../../shared-main/utils';
import {DecimalPipe} from '@angular/common';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {RequisitionGroupsService} from '../../admin/requisition-groups/requisition-groups.service';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

declare var jQuery: any;
declare var CKEDITOR: any;

export type RequisitionEditAction = 'NEW' | 'EDIT' | 'COPY';

class RequisitionsEditorModalContext {
  public selectedTemplate: RequisitionTemplate;
  public action: RequisitionEditAction;
  public isCategoryVisible: boolean = false;
  public instrument: Instrument;
  public relatedInstrument: Instrument; //used for transfer of charge or postponement
  public configs: RequisitionInstrumentConfig[];
}

@Component({
  selector: 'dp-requisitions-editor-modal',
  templateUrl: './requisitions-editor.modal.component.html',
  providers: [DecimalPipe, StatusBarService, RequisitionsService, RequisitionGroupsService],
  styleUrls: ['./requisitions-editor.modal.component.scss']
})
export class RequisitionsEditorModal
  extends ModalComponent<RequisitionsEditorModalContext>
  implements OnInit, AfterViewInit
{
  public static readonly STOP_CODE_REGEX = /&lt;&lt;([0-9]*)\[\[([^\]]*)\]\]&gt;&gt;/i;
  public static readonly CONDITION_CODE_REGEX = /{{\|@PDPX\/\d+((&lt;|&gt;|=)+)(blank|\d+)\|([^}]*)}}/i;
  public static readonly A_AN_CODE_REGEX1 = /&lt;&lt;a\*&gt;&gt;/i;
  public static readonly A_AN_CODE_REGEX2 = /&lt;&lt;a\*&gt;&gt;(\s*[^\s]+)?/i;

  rows: string[] = [];
  editor;
  prompt: string;
  stopCodeId: string;
  stopCodeIdOnly: string;
  @ViewChild('stopCodeValueControl') public stopCodeValueControl: ElementRef;
  stopCodeValue: string = '';
  requisitionStatusOptions = requisitionStatusDropdownOptions;
  requisitionCategoryOptions = requisitionCategoryDropdownOptions;
  showNext: boolean = true;
  instrumentProcessingComplete: boolean = false;
  instrumentParsingMatchFound: boolean = false;
  highlightReplace: string;
  fieldsEditable: boolean = false;
  showDelete: boolean = false;
  stopCodeIndex: number;
  disableOK: boolean = false;
  matchedMaxLength: number;
  @ViewChild('okButtonControl') public okButtonControl: ElementRef;
  @ViewChild('optionControl') public optionControl: ElementRef;

  constructor(
    public dialog: MatDialogRef<RequisitionsEditorModal>,
    public dialogService: DialogService,
    public requisitionsService: RequisitionsService,
    public decimalPipe: DecimalPipe,
    public requisitionGroupsService: RequisitionGroupsService,
    @Inject(MAT_DIALOG_DATA) context?: RequisitionsEditorModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    if (this.context.action === 'NEW') {
      this.editor = CKEDITOR.replace('rteditorModal', {
        readOnly: true
      });
    } else if (this.context.action === 'EDIT' || this.context.action === 'COPY') {
      this.editor = CKEDITOR.replace('rteditorModal', {});
    }

    if (this.context.action === 'EDIT') {
      this.showDelete = true;
    }

    CkEditorUtil.setUpEditor(this.editor);

    if (this.context.instrument) {
      this.retrieveCategoryVisibilityForAccount();
    }
  }

  public isCategoryVisible(): boolean {
    return this.context.isCategoryVisible;
  }

  ngAfterViewInit(): void {
    this.initializeContent();
    this.matchedMaxLength = this.getMatchedMaxLength();
    setTimeout(() => {
      let winHeight: number = jQuery('#ReqEditModal').outerHeight();
      jQuery(window).scrollTop(winHeight);
    }, 0);
  }

  initializeContent() {
    if (this.context.instrument && this.context.action === 'NEW') {
      //Presence of instrument means this was invoked from a Teranet Connect instrument.
      this.context.selectedTemplate.requisitionStatus = 'UNRESOLVED';
      this.context.selectedTemplate.requisitionCategory = null;
      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(/Â/gi, '');

      //simulating the completion of requisition flow and substituting values from instruments
      while (!this.instrumentProcessingComplete) {
        this.parseNextStopCodeInstrument();
        if (this.instrumentParsingMatchFound) {
          this.processNextWithInstrument();
        } else {
          this.handleConditionAndAStar();
          this.stopCodeValue = '';
        }
      }

      //replace temporary values which were sbustituted during auto-population of the requisition
      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
        /&lt;TEMP_STOP&lt;/g,
        '&lt;&lt;'
      );
      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
        /{TEMP_CONDITION{/g,
        '{{'
      );
      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
        /&lt;TEMP_ASTAR&lt;/g,
        '&lt;&lt;'
      );

      this.editor.setData(this.context.selectedTemplate.requisitionText);
      this.parseNextStopCode();
    } else if (this.context.action === 'NEW') {
      this.context.selectedTemplate.requisitionStatus = 'UNRESOLVED';
      if (!this.context.selectedTemplate.requisitionCategory) {
        this.context.selectedTemplate.requisitionCategory = null;
      }
      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(/Â/gi, '');
      this.parseNextStopCode();
    } else if (this.context.action === 'EDIT' || this.context.action === 'COPY') {
      this.editor.setData(this.context.selectedTemplate.requisitionText);
      this.showNext = false;
      this.fieldsEditable = true;
      setTimeout(() => {
        this.okButtonControl.nativeElement.focus();
      }, 0);
    }
  }

  //Need to check if category is visible based on account settings.
  retrieveCategoryVisibilityForAccount(): void {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.requisitionGroupsService.getRequisitionGroups(id).subscribe(
      (data) => {
        if (data && data != null && data.configurationOptionValues && data.configurationOptionValues.length > 0) {
          let optionValue = data.configurationOptionValues[0].configurationValue;
          this.context.isCategoryVisible = optionValue == 'true';
        } else {
          this.context.isCategoryVisible = false;
        }
      },
      (err) => {
        this.context.isCategoryVisible = false;
      }
    );
  }

  //Substitute temporary values for related condition codes and A Star codes in the case when the stop code could not be substituted automatically from instrument.
  //This prevents them from being picked up by regex searches until later on when the codes are restored.
  public handleConditionAndAStar(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    let conditionCodeIndex: number = requisitionText.search(RequisitionsEditorModal.CONDITION_CODE_REGEX);

    if (conditionCodeIndex != -1 && conditionCodeIndex < this.stopCodeIndex) {
      let match = requisitionText.match(RequisitionsEditorModal.CONDITION_CODE_REGEX);
      requisitionText = requisitionText.replace(match[0], match[0].replace('{{', '{TEMP_CONDITION{'));
    }

    let aStarIndex: number = requisitionText.search(RequisitionsEditorModal.A_AN_CODE_REGEX1);
    if (aStarIndex != -1 && aStarIndex < this.stopCodeIndex) {
      let match = requisitionText.match(RequisitionsEditorModal.A_AN_CODE_REGEX1);
      requisitionText = requisitionText.replace(match[0], match[0].replace('&lt;&lt;', '&lt;TEMP_ASTAR&lt;'));
    }

    this.context.selectedTemplate.requisitionText = requisitionText;
  }

  public getMatchedMaxLength(): number {
    let maxLength: number = null;
    if (this.context && this.context.selectedTemplate) {
      if (
        this.stopCodeId === this.context.selectedTemplate.encumbranceType ||
        this.stopCodeId === this.context.selectedTemplate.inFavourOf
      ) {
        maxLength = 100;
      } else if (this.stopCodeId === this.context.selectedTemplate.registrationNumber) {
        maxLength = 50;
      }
    }
    console.log('getMatchedMaxLength():', maxLength);

    return maxLength;
  }

  //Used when auto populating requisition based on an instrument.
  parseNextStopCodeInstrument(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    this.stopCodeIndex = requisitionText.search(RequisitionsEditorModal.STOP_CODE_REGEX);
    let match = requisitionText.match(RequisitionsEditorModal.STOP_CODE_REGEX);
    if (match) {
      this.stopCodeId = '<<' + match[1] + '>>';
      this.stopCodeIdOnly = match[1];
      this.prompt = match[2];

      //Find relevant config based on requisition name.
      let config: RequisitionInstrumentConfig = this.context.configs.find(
        (config) => this.context.selectedTemplate.key == config.requisitionName
      );

      let primaryInstrument: Instrument = this.context.instrument;
      if (this.isTransferOrPostponement()) {
        primaryInstrument = this.context.relatedInstrument;
      }

      this.instrumentParsingMatchFound = true;
      if (this.prompt == 'Enter the Instrument No.' && primaryInstrument.instrumentNumber) {
        this.stopCodeValue = primaryInstrument.instrumentNumber;
      } else if (this.prompt == 'Enter the Date of Registration' && primaryInstrument.instrumentDate) {
        this.stopCodeValue = Utils.formatDateString(primaryInstrument.instrumentDate, false);
      } else if (this.prompt == 'Enter the Instrument Amount' && primaryInstrument.amount) {
        this.stopCodeValue = this.decimalPipe.transform(primaryInstrument.amountAsNumber, '1.2-2');
      } else if (this.prompt == 'Enter the Party To' && primaryInstrument.partiesToFormatted) {
        this.stopCodeValue = primaryInstrument.partiesToFormatted;
      } else if (this.prompt == 'Enter the Party From' && primaryInstrument.partiesFromFormatted) {
        this.stopCodeValue = primaryInstrument.partiesFromFormatted;
      } else if (this.prompt == 'Enter the Encumbrance Type' && this.isTransferOrPostponement()) {
        this.stopCodeValue = 'Charge';
      } else if (this.prompt == 'Enter the Encumbrance Type' && config && config.encumbranceType) {
        this.stopCodeValue = config.encumbranceType;
      } else if (
        this.prompt == 'Enter the Instrument No. of the Transfer of Charge' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.instrumentNumber
      ) {
        this.stopCodeValue = this.context.instrument.instrumentNumber;
      } else if (
        this.prompt == 'Enter the Date of Registration of the Transfer of Charge' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.instrumentDate
      ) {
        this.stopCodeValue = Utils.formatDateString(this.context.instrument.instrumentDate, false);
      } else if (
        this.prompt == 'Enter name of Transferee in the Transfer of Charge' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.partiesToFormatted
      ) {
        this.stopCodeValue = this.context.instrument.partiesToFormatted;
      } else if (
        this.prompt == 'Enter the Instrument No. of the Postponement' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.instrumentNumber
      ) {
        this.stopCodeValue = this.context.instrument.instrumentNumber;
      } else if (
        this.prompt == 'Enter the Date of Registration of the Postponement' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.instrumentDate
      ) {
        this.stopCodeValue = Utils.formatDateString(this.context.instrument.instrumentDate, false);
      } else if (
        this.prompt == 'Enter name of other Mortgagee' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.partiesToFormatted
      ) {
        this.stopCodeValue = this.context.instrument.partiesToFormatted;
      } else if (
        this.prompt == 'Enter the Instrument No. of the Mortgage which was postponed' &&
        this.isTransferOrPostponement() &&
        primaryInstrument.instrumentNumber
      ) {
        this.stopCodeValue = primaryInstrument.instrumentNumber;
      } else if (
        this.prompt == 'Enter the Instrument No. of the mortgage benefiting from the postponement' &&
        this.isTransferOrPostponement() &&
        this.context.instrument.instrumentNumber
      ) {
        this.stopCodeValue = this.context.instrument.instrumentNumber;
      } else {
        //value could not be substituted. Put temp value.
        this.instrumentParsingMatchFound = false;
        this.stopCodeValue = match[0].replace('&lt;&lt;', '&lt;TEMP_STOP&lt;');
      }

      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
        match[0],
        this.stopCodeValue
      );
    } else {
      //no more stop codes found
      this.instrumentProcessingComplete = true;
    }
  }

  parseNextStopCode(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    this.stopCodeIndex = requisitionText.search(RequisitionsEditorModal.STOP_CODE_REGEX);
    let match = requisitionText.match(RequisitionsEditorModal.STOP_CODE_REGEX);
    if (match) {
      this.showNext = true;
      this.highlightReplace = '<span style="background-color:#f1c40f">' + match[0] + '</span>';
      requisitionText = requisitionText.replace(RequisitionsEditorModal.STOP_CODE_REGEX, this.highlightReplace);
      this.stopCodeId = '<<' + match[1] + '>>';
      this.prompt = match[2];
      setTimeout(() => {
        this.stopCodeValueControl.nativeElement.focus;
      }, 200);

      this.editor.setData(requisitionText);
      this.context.selectedTemplate.requisitionText = requisitionText;
    } else {
      requisitionText = this.context.selectedTemplate.requisitionText;

      this.requisitionsService
        .produceRequisitionText(requisitionText, currentMatter.value)
        .subscribe((templateText) => {
          this.context.selectedTemplate.requisitionText = templateText;
          this.parseAllAStarCodes();

          this.showNext = false;
          this.editor.setReadOnly(false);
          this.fieldsEditable = true;

          this.editor.setData(this.context.selectedTemplate.requisitionText);

          setTimeout(() => {
            this.optionControl.nativeElement.focus();
          }, 0);
        });
    }
  }

  //Similar to onNext() but used for requisitions based on an instrument.
  processNextWithInstrument(): void {
    if (this.context.selectedTemplate.encumbranceType === this.stopCodeId) {
      this.context.selectedTemplate.encumbranceType = this.stopCodeValue;
    }
    if (this.context.selectedTemplate.registrationNumber === this.stopCodeId) {
      this.context.selectedTemplate.registrationNumber = this.stopCodeValue;
    }
    if (this.context.selectedTemplate.inFavourOf === this.stopCodeId) {
      this.context.selectedTemplate.inFavourOf = this.stopCodeValue;
    }

    //substitute any occurrences of codes such as <<8>>
    let stopIDRegex = new RegExp('&lt;&lt;' + this.stopCodeIdOnly + '&gt;&gt;', 'ig');
    this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
      stopIDRegex,
      this.stopCodeValue
    );

    this.parseConditionCode();
    this.parseAStarCode();

    this.stopCodeValue = '';

    this.matchedMaxLength = this.getMatchedMaxLength();
  }

  onNext(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    requisitionText = requisitionText.replace(this.highlightReplace, this.stopCodeValue);
    this.context.selectedTemplate.requisitionText = requisitionText;

    if (this.context.selectedTemplate.encumbranceType === this.stopCodeId) {
      this.context.selectedTemplate.encumbranceType = this.stopCodeValue;
    }
    if (this.context.selectedTemplate.registrationNumber === this.stopCodeId) {
      this.context.selectedTemplate.registrationNumber = this.stopCodeValue;
    }
    if (this.context.selectedTemplate.inFavourOf === this.stopCodeId) {
      this.context.selectedTemplate.inFavourOf = this.stopCodeValue;
    }

    this.parseConditionCode();
    this.parseAStarCode();

    this.stopCodeValue = '';

    this.parseNextStopCode();

    this.matchedMaxLength = this.getMatchedMaxLength();

    jQuery('.focus-first').focus();
  }

  parseConditionCode(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    let conditionCodeIndex: number = requisitionText.search(RequisitionsEditorModal.CONDITION_CODE_REGEX);

    if (conditionCodeIndex != -1 && conditionCodeIndex < this.stopCodeIndex) {
      let match = requisitionText.match(RequisitionsEditorModal.CONDITION_CODE_REGEX);
      let operator: string = match[1];
      let argument: string = match[3];
      let text: string = match[4];

      if (operator === '&lt;&gt;') {
        if (argument === 'blank') {
          if (!(this.stopCodeValue === '')) {
            requisitionText = requisitionText.replace(match[0], text);
          } else {
            requisitionText = requisitionText.replace(match[0], '');
          }
        }
      } else if (operator === '&gt;') {
        if (!(this.stopCodeValue === '')) {
          if (isNaN(Number(this.stopCodeValue)) && this.stopCodeValue.startsWith('$')) {
            this.stopCodeValue = this.stopCodeValue.replace('$', '');
          }
          if (Number(this.stopCodeValue.replace(',', '').replace(' ', '')) > Number(argument)) {
            requisitionText = requisitionText.replace(match[0], text);
          } else {
            requisitionText = requisitionText.replace(match[0], '');
          }
        } else {
          requisitionText = requisitionText.replace(match[0], '');
        }
      }
    }

    this.context.selectedTemplate.requisitionText = requisitionText;
  }

  parseAllAStarCodes(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    let match = requisitionText.match(RequisitionsEditorModal.A_AN_CODE_REGEX2);
    while (match) {
      if (match[1]) {
        if (this.isVowel(match[1].trim().charAt(0))) {
          requisitionText = requisitionText.replace(match[0], 'an ' + match[1]);
        } else {
          requisitionText = requisitionText.replace(match[0], 'a ' + match[1]);
        }
      } else {
        requisitionText = requisitionText.replace(match[0], '');
      }

      match = requisitionText.match(RequisitionsEditorModal.A_AN_CODE_REGEX2);
    }

    this.context.selectedTemplate.requisitionText = requisitionText;
  }

  parseAStarCode(): void {
    let requisitionText = this.context.selectedTemplate.requisitionText;
    let aStarIndex: number = requisitionText.search(RequisitionsEditorModal.A_AN_CODE_REGEX1);
    if (aStarIndex != -1 && aStarIndex < this.stopCodeIndex) {
      let match = requisitionText.match(RequisitionsEditorModal.A_AN_CODE_REGEX1);
      if (this.stopCodeValue.length > 0) {
        if (this.isVowel(this.stopCodeValue.trim().charAt(0))) {
          requisitionText = requisitionText.replace(match[0], 'an ');
        } else {
          requisitionText = requisitionText.replace(match[0], 'a ');
        }
      } else {
        requisitionText = requisitionText.replace(match[0], '');
      }
      this.context.selectedTemplate.requisitionText = requisitionText;
    }
  }

  isVowel(c) {
    return ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase()) !== -1;
  }

  onOK(): void {
    this.disableOK = true; // Disabling the OK button to prevent multiple submissions
    this.context.selectedTemplate.requisitionText = this.editor.getData();

    this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
      /&nbsp;/g,
      ' '
    );

    if (
      this.context.selectedTemplate.requisitionText.includes('<p></p>') ||
      this.context.selectedTemplate.requisitionText.includes('<p> </p>')
    ) {
      this.context.selectedTemplate.requisitionText = this.context.selectedTemplate.requisitionText.replace(
        /<p><\/p>|<p> <\/p>/g,
        '<p>&nbsp;</p>'
      );
    }

    let requisition: RequisitionTemplate = null;
    if (this.context.action === 'NEW' || this.context.action === 'COPY') {
      requisition = new RequisitionTemplate(this.context.selectedTemplate);
      requisition.instanceType = 'MATTER_REQUISITION';
      requisition.id = UUIDUtil.getUUID();
      requisition.identifier = requisition.id;

      if (this.context.instrument) {
        requisition.instrumentId = this.context.instrument.id;
      }

      this.matter.matterRequisitions.push(requisition);
    }
    this.matter.dirty = true;
    this.dialog.close({
      action:
        this.context.action === 'COPY'
          ? RequisitionsEditorModalResult.COPY_COMPLETED
          : RequisitionsEditorModalResult.ADD_COMPLETED,
      requisition: requisition
    });
  }

  close(): void {
    this.dialog.close({});
  }

  onDelete(): void {
    this.dialogService
      .confirm('Confirmation', messages.requisition.deleteConfirmation, false, 'Delete')
      .subscribe((res) => {
        if (res == true) {
          this.dialog.close({action: 'Delete'});
        }
      });
  }

  enterForNext(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onNext();
    }
  }

  enterForOK(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onOK();
    }
  }

  get matter(): Matter {
    return currentMatter.value;
  }

  onRequisitionCategoryChange(selectedTemplate: RequisitionTemplate): void {
    if (selectedTemplate && selectedTemplate.requisitionCategory === 'null') {
      this.context.selectedTemplate.requisitionCategory = null;
    }
  }

  handleF9StopCode(): void {
    if (this.isStopCodeF9Visible()) {
      this.stopCodeValue = this.instrumentTypeFormatted;
    }
  }

  generateF9StopCode(): string {
    let helpText = '';

    if (this.isStopCodeF9Visible()) {
      helpText = 'F9 = ' + this.instrumentTypeFormatted;
    }

    return helpText;
  }

  get instrumentTypeFormatted(): string {
    //capitalize first letter of each word
    return _.toLower(this.context.instrument.type)
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  }

  public isStopCodeF9Visible(): boolean {
    if (this.context.instrument && this.context.instrument.type && this.prompt == 'Enter the Encumbrance Type') {
      return true;
    } else {
      return false;
    }
  }

  public isTransferOrPostponement(): boolean {
    if (
      (this.context.selectedTemplate.key == 'Charge with Transfer of Charge' ||
        this.context.selectedTemplate.key == 'Charge with Postponement') &&
      this.context.relatedInstrument
    ) {
      return true;
    } else {
      return false;
    }
  }

  //Instrument number only editable for requistions created from instrument if edit requisition invoked from Teranet Connect tab (not requisition tab)
  isInstrumentNumberEditable(): boolean {
    if (
      !this.context.selectedTemplate.instrumentId ||
      (this.context.selectedTemplate.instrumentId && this.context.configs)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
