<form #privateContactsForm="ngForm" class="form-horizontal overFlow offset-lg-1 col-lg-11" *ngIf="contact">

    <div class="padding-top-10" id="privateContactsurname_container">
        <div *ngFor="let selectedPerson of contact.privateContacts.persons; let i=index">


                    <dp-accordion [expanded]="isExpanded(i)" >
                        <div class="dp-accordion-header">
                        <div class="row  row-group">
                            <label class="control-label col-lg-2" for="fullName{{i+currentTimeStamp}}">Attention</label>
                            <div class="col-lg-9">
                                <input type="text" trimmed id="fullName{{i+currentTimeStamp}}" name="fullName{{i+currentTimeStamp}}" class="form-control"
                                       [value]="selectedPerson.contactFullNameStartWithFirstName"
                                       [dp-read-only]="true">
                            </div>
                            <!--Delete icon should not be displayed if there is only one blank element-->
                            <div  class="col-lg-1" *ngIf="((i!=0 || selectedPerson.contactName.lastName) && !readonly)">
                                <span class="trash-icon">
                                    <i class="fa fa-trash" (click)="deletePrivateContact(selectedPerson)" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="dp-accordion-content">
                    <div class="form-group">
                        <label class="control-label col-lg-2">Gender</label>
                        <div class="col-lg-3">
                            <select class="form-control" id="gender{{i+currentTimeStamp}}"
                                    name="gender{{i+currentTimeStamp}}" [(ngModel)]="selectedPerson.localGender" (change)="onGenderChange(selectedPerson)">
                                <option *ngFor="let gender of genders" [value]="gender.value">{{gender.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="!selectedPerson.isGenderDepartment()">
                    <div class="form-group">
                        <label class="control-label col-lg-2" for="surname_{{selectedPerson.identifier}}"
                                [ngClass]="{error: !lastName.valid && isFormDirty}">Surname*</label>
                        <div class="col-lg-6">
                            <input type="text" trimmed id="surname_{{selectedPerson.identifier}}" name="surname_{{selectedPerson.identifier}}" #lastName="ngModel"
                                    [(ngModel)]="selectedPerson.contactName.lastName"
                                    [fieldKey]="'privateContact.surname'"
                                    [dp-error-validator]="{data: {identifier: selectedPerson.identifier}}"
                                    class="form-control" maxlength="100" trimmed/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-lg-2">First Name</label>
                        <div class="col-lg-6">
                            <input type="text" trimmed id="firstName{{i+currentTimeStamp}}" name="firstName{{i+currentTimeStamp}}" [(ngModel)]="selectedPerson.contactName.firstName"
                                    maxlength="50" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-lg-2">Middle Name(s)</label>
                        <div class="col-lg-6">
                            <input type="text" trimmed id="middleName{{i+currentTimeStamp}}" name="middleName{{i+currentTimeStamp}}" [(ngModel)]="selectedPerson.contactName.middleName"
                                    maxlength="50" class="form-control"/>
                        </div>
                    </div>
                    </div>
                    <div class="form-group" *ngIf="selectedPerson.isGenderDepartment()">
                            <label class="control-label col-lg-2">Department Name*</label>
                            <div class="col-lg-10">
                                <input type="text"
                                       trimmed
                                       id="organizationName_{{selectedPerson.identifier}}"
                                       name="organizationName_{{selectedPerson.identifier}}"
                                       [(ngModel)]="selectedPerson.organizationName"
                                       [fieldKey]="'privateContact.name'"
                                       [dp-error-validator]="{data: {identifier: selectedPerson.identifier}}"
                                       maxlength="100"
                                       class="form-control"/>
                            </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-lg-2">Dear</label>
                        <div class="col-lg-4">
                            <input type="text" trimmed name="dear{{i+currentTimeStamp}}" class="form-control" [(ngModel)]="selectedPerson.dear"
                                    maxlength="30"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <div>
                            <label class="control-label col-lg-2">Work Phone</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed name="workPhone{{i+currentTimeStamp}}" class="form-control" [(ngModel)]="selectedPerson.workPhone"
                                        maxlength="30"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div>
                            <label class="control-label col-lg-2">Cell Phone</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed name="mobilePhone{{i+currentTimeStamp}}" class="form-control" [(ngModel)]="selectedPerson.cellPhone"
                                        maxlength="30"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div>
                            <label class="control-label col-lg-2" for="email_{{selectedPerson.identifier}}" [ngClass]="{error: !email.valid}">Email Address</label>
                            <div class="col-lg-4">

                                <input type="text" trimmed id="email_{{selectedPerson.identifier}}" name="email{{i+currentTimeStamp}}" #email="ngModel" class="form-control"
                                        [(ngModel)]="selectedPerson.email"
                                        maxlength="50"
                                       dp-email-field
                                       [fieldKey]="'privateContact.email'"
                                       [dp-error-validator]="{isEmail : true, data: {identifier: selectedPerson.identifier, containerId: 'privateContactsurname_container'}}"
                                        multi-email-validator/>

                            </div>

                        </div>
                    </div>
                    </div>
                    </dp-accordion>

        </div>
    </div>

<!-- Add another attention link -->
<div class="form-group" *ngIf="!readonly">
    <div class="col-lg-10 offset-lg-2">
            <span class="actionlink">
                <span>
                    <a href="javascript:void(0);" (click)="addNewPrivateContact()">
                        Add Attention
                    </a>
                </span>
                <span class="selector fa fa-plus-square-o"></span>
            </span>
    </div>
</div>
<!-- //END - Add another attention link -->

</form>
