import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedMainModule} from '../../shared-main';
import {PartialDateComponent} from './partial-date.component';
import {AngularMyDatePickerModule} from '@nodro7/angular-mydatepicker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMainModule,
    AngularMyDatePickerModule
  ],
  declarations: [
    PartialDateComponent
  ],
  exports: [
    PartialDateComponent
  ],
  providers: []
})
export class PartialDateModule {
}


