import {ErrorService} from '../../shared/error-handling/error-service';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {ParcelRegister} from '../../shared-main/teranet/parcel-register';
import {Instrument} from '../../shared-main/teranet/instrument';
import {ApplicationError, FieldError} from '../../core/application-error';
import {TeranetAddInstrumentRequest} from '../../shared-main/teranet/teranet-add-instrument-request';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class TeranetAddInstrumentModalContext {
  teranetUser: TeranetUser;
  selectedParcelRegister: ParcelRegister;
}

@Component({
  selector: 'dp-teranet-add-instrument-modal',
  templateUrl: 'teranet-add.instrument.modal.component.html',
  providers: [ErrorService]
})
export class TeranetAddInstrumentModal extends ModalComponent<TeranetAddInstrumentModalContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  teranetAddInstrumentRequest: TeranetAddInstrumentRequest;

  constructor(
    public dialog: MatDialogRef<TeranetAddInstrumentModal>,
    public dialogService: DialogService,
    public teranetService: TeranetService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetAddInstrumentModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.teranetAddInstrumentRequest = new TeranetAddInstrumentRequest();
    this.teranetAddInstrumentRequest.parcelRegisterId = this.context.selectedParcelRegister.id;
    this.teranetAddInstrumentRequest.teranetUser = this.context.teranetUser;
  }

  close(): void {
    this.dialog.close();
  }

  cancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  addInstrument(): void {
    let instrumentExist: Instrument = this.context.selectedParcelRegister.instruments.find(
      (instrument) => instrument.instrumentNumber === this.teranetAddInstrumentRequest.instrumentNumber.trim()
    );
    if (instrumentExist) {
      let message: string =
        'Instrument No. ' +
        this.teranetAddInstrumentRequest.instrumentNumber +
        ' already exists in the list. To order the instrument, ' +
        'check the associated checkbox and click the "Order" button.';
      this.dialogService.confirm(
        'Teranet Connect &trade;: Instrument No. ' +
          this.teranetAddInstrumentRequest.instrumentNumber +
          ' Already Exists',
        message,
        true,
        'Close'
      );
    } else {
      this.teranetService.addTeranetInstrument(this.teranetAddInstrumentRequest).subscribe(
        (res: ParcelRegister) => {
          if (res) {
            this.dialog.close({
              action: 'OK',
              updatedParcelRegister: res
            });
          }
        },
        (error: ApplicationError) => {
          this.handleInstrumentSearchApiError(error);
        }
      );
    }
  }

  handleInstrumentSearchApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }
}
