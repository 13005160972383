import {TeranetUser} from './teranet-user';

export class TeranetOrderMapRequest {

  parcelRegisterId: number;
  docketSystemId: number;
  lro: string;
  pin: string;
  includeRoadsInd: boolean;
  includeRailRoadsInd: boolean;
  teranetUser: TeranetUser;
}
