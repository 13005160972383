<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>Sticky Notes</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>

    <div class="row modal-body">
        <div class="col-lg-12">
            <textarea cols="2" rows="25" [(ngModel)]="stickyNotes" class="border-hide sunrise-bg focus-first" (keyup)="isDirty = true"  style="height: auto;"></textarea>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    class="dp-btn">
                <span>OK</span>
            </button>

            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>

            <button type="button" *ngIf="!context.hideDeleteButton"
                    id="delete"
                    [disabled]="stickyNotes == undefined || stickyNotes == '' || stickyNotes == null"
                    (click)="delete()"
                    class="dp-btn dp-btn-cancel">
                <span>Delete</span>
            </button>

        </div>
    </div>
</div>
