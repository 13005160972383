import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Contact} from '../../../shared/contact';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import * as _ from 'lodash';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {ContactChangesListener} from '../../../../contact/contact-changes-listener';

export class CreateMortgageBrokerContext {
  mortgageBrokerTemplate: {
    typedName: string;
  };
  showClose: boolean;
}

@Component({
  selector: 'dp-create-mortgage-broker',
  templateUrl: './create-mortgage-broker.component.html',
  styleUrls: [ './create-mortgage-broker.component.scss' ],
  providers: [ ErrorService, ContactChangesListener ]
})
export class CreateMortgageBrokerComponent extends ModalComponent<CreateMortgageBrokerContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  mortgageBroker: Contact;
  cachedChangedValues: any;

  constructor(public dialog: MatDialogRef<CreateMortgageBrokerComponent, Contact | string>,
              public contactQueryService: ContactQueryService,
              public errorService: ErrorService,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) context?: CreateMortgageBrokerContext) {
    super(dialog, context);
    this.mortgageBroker = new Contact();
    this.mortgageBroker.organizationName = this.capitalizeFirstLetter(this.context.mortgageBrokerTemplate.typedName);
    this.mortgageBroker.setDefaultValues('MORTGAGE_BROKER');
    this.mortgageBroker.sourceContactId = null;
    this.mortgageBroker.snapshotFlag = false;
  }

  get contactKey(): string {
    return this.mortgageBroker.contactType;
  }

  close(result?: Contact | string): void {
    if (this.mortgageBroker.isDirty) {
      this.dialogService.confirmUnsavedChange(true).subscribe(
        (response: any) => {
          if (response == 'DONT_SAVE') {
            return this.dialog.close();
          } else if (response == 'CANCEL') {
          } else if (response == 'SAVE') {
            this.saveMortgageBroker();
          }
        });
    } else {
      this.dialog.close(result);
    }
  }

  dataChanged(values: any) {
    if (!this.cachedChangedValues) {
      this.cachedChangedValues = values;
    }

    if (!_.isEqual(this.cachedChangedValues, values)) {
      this.cachedChangedValues = values;
      this.mortgageBroker.isDirty = true;
    }
  }

  saveMortgageBroker(): void {
    if (this.isMortgageBrokerValid()) {
      this.cleanupPrivateInformation();
      this.mortgageBroker.cleanupRedundantData();
      this.contactQueryService.createContact(this.mortgageBroker).subscribe(
        (c: Contact) => {
          this.mortgageBroker.isDirty = false;
          this.close(c);
        },
        (error: any) => console.log(error));
    }
  }

  public capitalizeFirstLetter(text: string): string {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
  }

  public cleanupPrivateInformation(): void {
    let untouchedAttentions: Contact[] = this.mortgageBroker.privateContacts.persons.filter(c => !this.isPersonPopulated(c));
    untouchedAttentions.forEach(c => {
      this.mortgageBroker.privateContacts.deletePrivateContact(c);
    });
  }

  public isPersonPopulated(person: Contact): boolean {
    return (!!person.gender && (person.gender !== 'QUESTION'))
      || !!person.lastName
      || !!person.firstName
      || !!person.middleName
      || !!person.dear
      || !!person.workPhone
      || !!person.faxPhone
      || !!person.cellPhone
      || !!person.organizationName;
  }

  public isMortgageBrokerValid(): boolean {
    this.errorService.clearAllSaveErrors();
    let valid: boolean = true;
    //Check mortgageBroker
    if (!this.mortgageBroker.organizationName) {
      this.errorService.addDpSaveError(DPError.createDPError('contact.companyName'));
      valid = false;
    }
    // Check My Contacts
    if (this.mortgageBroker.privateContacts && this.mortgageBroker.privateContacts.persons) {
      for (let i = this.mortgageBroker.privateContacts.persons.length; i > 0; i--) {
        const person: Contact = this.mortgageBroker.privateContacts.persons[ i - 1 ];
        if (this.isPersonPopulated(person)) {
          let errorMsg: string;
          if (person.gender === 'DEPARTMENT' && !person.organizationName) {
            errorMsg = 'Department Name for my contacts # is required.';
          } else if (person.gender !== 'DEPARTMENT' && !person.lastName) {
            errorMsg = 'Surname for my contacts # is required.';
          }
          if (errorMsg) {
            this.errorService.addDpSaveError(DPError.createCustomDPError(`privateContact.surname_surname_${ person.identifier }`,
              errorMsg,
              'Add New Mortgage Broker',
              'ERROR',
              0,
              `surname_${ person.identifier }`));
            valid = false;
          }
        }
      }
    }
    return valid;
  }
}
