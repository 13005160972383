import {ChicagoBaseResponse} from './chicago-base-response';

export class ChicagoSubmitResponseObject extends ChicagoBaseResponse {

  securedUrl: string;
  newFileNumber: number;
  policyNumber: string;

  constructor(c?: ChicagoSubmitResponseObject) {
    super(c);
  }

}
