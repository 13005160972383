<div class="dropdown multi-select-dropdown">
    <a class="select" data-target="#" data-toggle="dropdown" role="button" aria-haspopup="true"
       aria-expanded="false">
        <span *ngIf="selectedValues.length===0 || selectedValues[0]==='ALL' || showLabelInTitle===false">{{title}}</span>
        <span *ngIf="selectedValues.length>0 && selectedValues[0]!='ALL' && showLabelInTitle===true">
                                    {{selectedValues}}
                                </span>

        <span class="caret dp-custom-arrow"><img src="/assets/img/dropdown-firefox-primeng.png" /></span>
    </a>
    <div class="dropdown-menu " *ngIf="multiSelectData.length>0">
        <div style="position: relative;">
            <ul style="max-height: 350px; overflow-x: hidden; overflow-y: auto;"
                infinite-scroll [scrollWindow]="false" (scrolled)="getNextPage()" infiniteScrollDistance="3"
                infiniteScrollThrottle="500">
                <li *ngFor="let msData of multiSelectData; let i = index;"><dp-checkbox fieldId="{{inputName + i}}">
                    <input type="checkbox" id="{{inputName + i}}" name="{{inputName + i}}" (click)="dataFilter(msData.value)"
                           [checked]="isSelected(msData.value)"  />
                </dp-checkbox> <label for="{{inputName + i}}" class="label-text"><span>{{msData.label}}</span></label></li>
            </ul>


            <div *ngIf="pageNo > 1 && loading === true"
                 style="position: absolute; bottom: 5px; left: 50%; transform: translate(-50%, 0%);">
                <img src="assets/img/spinner.gif"/>
            </div>
        </div>
    </div>

</div>
