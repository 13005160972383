<div class="container-fluid custom-modal-container border-radius">
    <div class="modal-header margin-bottom-20" >
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Paragraph Editor</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="row modal-body form-horizontal">
        <div class="form-group">
            <div class="col-lg-4 vertical-align-label">
                <label class="control-label">Execution Number</label>
            </div>
            <div class="col-lg-4">
                <input class="form-control" id="executionNumber"
                       name="executionNumber"
                       maxlength="255"
                       [(ngModel)]="paragraph.executionNumber">
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-4 vertical-align-label">
                <label class="control-label">Plaintiff</label>
            </div>
            <div class="col-lg-4">
                <input class="form-control" id="plaintiffName"
                       name="plaintiffName"
                       maxlength="255"
                       [(ngModel)]="paragraph.plaintiffName">
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-4 vertical-align-label">
                <label class="control-label">Amount of Judgment</label>
            </div>
            <div class="col-lg-4">
                <input class="form-control" id="judgmentAmount"
                       name="judgmentAmount"
                       maxlength="255"
                       [(ngModel)]="paragraph.judgmentAmount">
            </div>
        </div>
        <div class="row padding-top-20 tool-bar">
            <div class="col-lg-12 hide-tool-bar" >
            <textarea name="paragraphText" id="paragraphText" class="focus-first"  [(ngModel)]='paragraph.paragraphText' rows="30" cols="120" rows="10" cols="80"
                      style="line-height: 20px; height: 150px"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn keyboard-save-action" (click)="onOK()" #okButtonControl id="okBtn">
                <span>OK</span>
            </button>
            <button *ngIf="showViewButton" type="button" class="dp-btn-white" (click)="onViewWritDetails()">
                <span>View Writ Details</span>
            </button>
            <button *ngIf="showDeleteButton" type="button" class="dp-btn-white" (click)="onDelete()">
                <span>Delete</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>

</div>
