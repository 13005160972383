import {Component, Inject} from '@angular/core';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {StatementAdjustment} from '../../statement-adjustment';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import * as _ from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class StatementAdjustmentFormatModalContext {
  statementAdjustmentHeadingMatter: StatementOfAdjustmentHeading;
  statementAdjustment: StatementAdjustment;
  viewOnly: boolean;
}

@Component({
  selector: 'dp-statement-adjustment-format-modal',
  templateUrl: './statement-adjustment-format.modal.component.html',
  styleUrls: ['./statement-adjustment-format.modal.component.scss']
})
export class StatementAdjustmentFormatModalComponent extends ModalComponent<StatementAdjustmentFormatModalContext> {
  statementAdjustment: StatementAdjustment;

  constructor(
    public dialog: MatDialogRef<StatementAdjustmentFormatModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: StatementAdjustmentFormatModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    //clone the statementAdjustment to prevent the change directly affected the matter before User click <OK>
    this.statementAdjustment = _.cloneDeep(this.context.statementAdjustment);
  }

  ok(): void {
    this.dialog.close(this.statementAdjustment);
  }

  close(): void {
    this.dialog.close();
  }

  statementAdjustmentFormatDialogTitle(): string {
    return 'Format this Adjustment';
  }
}
