import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';

declare var jQuery: any;

@Component({
  selector: 'dp-sticky-notes',
  templateUrl: './dp-sticky-notes.component.html'

})

export class DpStickyNotesComponent {

  @Input() data: string;
  @Output() dataChanged = new EventEmitter();
  @Output() saveEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();

  constructor(private dialogService: DialogService) {
  }

  close() {
    jQuery('.dp-sticky-note').removeClass('display-block');
  }

  onBlur() {
    this.dataChanged.emit(this.data);
  }

  enableSave() {
    this.saveEvent.emit();
  }

  deleteNote() {
    this.dialogService.confirm('Confirmation', 'Do you wish to delete this sticky note?', false).subscribe(res => {
      if (res) {
        this.dataChanged.emit('');
        this.enableSave();
        this.close();
      }
    });

  }

}
