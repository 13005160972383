import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MortgageeEnclosureConfig, MortgageeEnclosureConfigItem} from '../../../../admin/mortgagee-enclosure/mortgagee-enclosure-config';
import * as _ from 'lodash';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class InterimEnclosureModalContext {
  interimEnclosures: number[];
  enclosureConfig: MortgageeEnclosureConfig;
  isCondo: string;
  //is it a final vs interim enclosure
  isFinal: boolean;
  loadDefaultEnclosures: boolean;
  isLender?: boolean = false;
}

@Component({
  selector: 'interim-enclosure-modal-content',
  templateUrl: 'interim-enclosure.modal.component.html'
})
export class InterimEnclosureModal extends ModalComponent<InterimEnclosureModalContext> {
  interimEnclosures: MortgageeEnclosureConfigItem[];
  selectedEnclosures: number[];
  header: string;

  constructor(public dialog: MatDialogRef<InterimEnclosureModal, {selectedEnclosures: number[]}>,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) context?: InterimEnclosureModalContext) {
    super(dialog, context);
    this.selectedEnclosures = _.cloneDeep(this.context.interimEnclosures);
    this.interimEnclosures = this.context.enclosureConfig.mortgageeEnclosureConfigItems;

    if (!this.selectedEnclosures) {
      this.selectedEnclosures = [];
    }

  }

  ngOnInit(): void {
    const lenderOrMortgagee: string = this.context.isLender ? 'Lender' : 'Mortgagee';
    if (this.context.isFinal) {
      this.header = `Final Report to ${ lenderOrMortgagee } Enclosures`;
    } else {
      this.header = `Interim Report to ${ lenderOrMortgagee } Enclosures`;
    }
    if (this.context.loadDefaultEnclosures) {
      this.loadDefaultEnclosures();
    }
  }

  isCondo(): boolean {
    return this.context.isCondo === 'YES';
  }

  close(): void {
    this.dialog.close();
  }

  loadDefaults(): void {
    if (this.selectedEnclosures.length > 0) {
      this.dialogService.confirm('Confirmation', 'Do you wish to load the predefined values from configuration?.', false).subscribe(res => {
        if (res) {
          this.loadDefaultEnclosures();
        }
      });
    } else {
      this.loadDefaultEnclosures();
    }
  }

  loadDefaultEnclosures() {
    this.selectedEnclosures = [];
    this.interimEnclosures.forEach((enclosure) => {
      if (this.context.isFinal) {
        if (enclosure.finalEnclosureType === 'CONDO_AND_NON_CONDO') {
          this.selectedEnclosures.push(enclosure.id);
        } else if (enclosure.finalEnclosureType === 'CONDO') {
          if (this.isCondo()) {
            this.selectedEnclosures.push(enclosure.id);
          }
        } else if (enclosure.finalEnclosureType === 'NON_CONDO') {
          if (!this.isCondo()) {
            this.selectedEnclosures.push(enclosure.id);
          }
        }
      } else {
        if (enclosure.interimEnclosureType === 'CONDO_AND_NON_CONDO') {
          this.selectedEnclosures.push(enclosure.id);
        } else if (enclosure.interimEnclosureType === 'CONDO') {
          if (this.isCondo()) {
            this.selectedEnclosures.push(enclosure.id);
          }
        } else if (enclosure.interimEnclosureType === 'NON_CONDO') {
          if (!this.isCondo()) {
            this.selectedEnclosures.push(enclosure.id);
          }
        }
      }
    });
  }

  isFieldInSelectedList(interimFieldId): boolean {
    return this.selectedEnclosures.indexOf(interimFieldId) > -1;
  }

  editSelectedList(interimFieldId): void {
    let indexOfField = this.selectedEnclosures.indexOf(interimFieldId);
    if (indexOfField > -1) {
      this.selectedEnclosures.splice(indexOfField, 1);
    } else {
      this.selectedEnclosures.push(interimFieldId);
    }
  }

  ok(): void {
    this.dialog.close({selectedEnclosures: this.selectedEnclosures});
  }
}


