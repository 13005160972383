<div class="modal-header margin-bottom-20" >
    <div class="col-lg-12">
        <div class="col-lg-10">
            <h1>Add Paragraph</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
    </div>
</div>
<div class="row modal-body form-horizontal padding-left-30 padding-right-30" style="overflow:hidden;">
    <div class="form-group">
        Insert Paragraph into the following declaration(s):
    </div>

    <div class="form-group">
        <dp-checkbox fieldId="clientSelectedFlag">
            <input id="clientSelectedFlag"
                   name="clientSelectedFlag"
                   type="checkbox"
                   [(ngModel)]="isClientSelected"
                   [disabled]="!isClientDeclaration"
                   #clientSelectedFlag>
        </dp-checkbox>
        Client
    </div>
    <div class="form-group">
        <dp-checkbox fieldId="solicitorSelectedFlag">
            <input id="solicitorSelectedFlag"
                   name="clientSelectedFlag"
                   type="checkbox"
                   [(ngModel)]="isSolicitorSelected"
                   [disabled]="!isSolicitorDeclaration"
                   #solicitorSelectedFlag>
        </dp-checkbox>
        Solicitor
    </div>
</div>

<div class="modal-footer">
    <div class="col-lg-12 buttons">
        <button type="button" class="dp-btn" (click)="onOK()" [disabled]="!okEnabled" #okButtonControl id="okBtn">
            <span>OK</span>
        </button>
        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
            <span>Cancel</span>
        </button>
    </div>
</div>

