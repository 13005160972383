import {EventEmitter, inject, Injectable} from '@angular/core';
import {MatterParticipant} from '../matter-participant';
import {
  TeranetOwnerImportModal
} from '../../property-teranet/teranet-connect-detail/import-data/teranet-owner-import.modal.component';
import {CustomModalAction, ModalAction} from '../../../shared-main/constants';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {ContactUtil} from '../contact-util';
import {ContactSuggestion} from '../../../contact/contact-suggestion/contact-suggestion';
import {ParcelRegister} from '../../../shared-main/teranet/parcel-register';
import {AppConfig} from '../../../shared-main/app-configuration';
import {DialogConfigParams, DialogService} from '../../../shared/dialog/dialog.service';
import {currentMatter} from '../current-matter';
import {
  TeranetImportMortgageModal
} from '../../property-teranet/teranet-connect-detail/import-data/teranet-import-mortgage.modal.component';
import {TeranetImportData} from '../../../shared-main/teranet/teranet-import-data';
import {
  TeranetImportModal
} from '../../property-teranet/teranet-connect-detail/import-data/teranet-import.modal.component';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {JurisdictionDepartment} from '../../../admin/jurisdiction-departments/jurisdiction-departments';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {Subject} from 'rxjs/Subject';
import {ImportOwnerDataService} from './import-owner-data.service';
import {ImportPropertyDataService} from './import-property-data.service';
import {ImportMortgageDataService} from './import-mortgage-data.service';

@Injectable()
export class ImportDataService {

  appConfig = inject(AppConfig);
  dialogService = inject(DialogService);
  errorService = inject(ErrorService);
  importOwnerDataService = inject(ImportOwnerDataService);
  importPropertyDataService = inject(ImportPropertyDataService);
  importMortgageDataService = inject(ImportMortgageDataService);

  get matter() {
    return currentMatter.value;
  }

  // Owner Import
  public openOwnerImportTeranetModal(selectedParcelRegister: ParcelRegister, showWizardFields?: boolean, updateOwnerDataForWizard?: EventEmitter<string>): void {
    if (selectedParcelRegister && selectedParcelRegister.propertyOwners) {

      if (selectedParcelRegister.propertyOwners.length > this.appConfig.getMaxNumberOfPurchasers()) {
        this.dialogService.confirm('Information', 'The parcel register contains ' + selectedParcelRegister.propertyOwners.length + ' names.  Unity will' +
          ' only import' +
          ' the first ' + this.appConfig.getMaxNumberOfPurchasers() + ' names.', true, 'OK').subscribe(() => {
          this.openOwnerModal(selectedParcelRegister, showWizardFields, updateOwnerDataForWizard);
        });
      } else {
        this.openOwnerModal(selectedParcelRegister, showWizardFields, updateOwnerDataForWizard);
      }
    }
  }

  // Teranet Mortgage Import

  public isImportMortgageMenuOptionApplicable(selectedParcelRegister: ParcelRegister): boolean {
    return this.importMortgageDataService.isImportMortgageMenuOptionApplicable(selectedParcelRegister);
  }

  public openImportTeranetMortgageModal(selectedParcelRegister: ParcelRegister): void {
    this.dialogService.matDialogContent({
      content: TeranetImportMortgageModal,
      context: {
        matter: this.matter,
        selectedParcelRegister: selectedParcelRegister
      },
      onFulfillment: (instruments: any[]) => {
        this.importMortgageDataService.importTeranetMortgageData(instruments);
      }
    });
  }

  // Property Data Import

  public openImportPropertyData(
    selectedParcelRegister: ParcelRegister, ontarioTaxRateSlab?: ConsiderationTaxes,
    torontoTaxRateSlab?: ConsiderationTaxes, jurisdictionDepartments?: JurisdictionDepartment[],
    blanketMortgage: boolean = false, updatePropertyDataForBlanketMortgage = new EventEmitter<ParcelRegister>,
    showWizardFields: boolean = false, updateOwnerDataForWizard = new EventEmitter<string>()
  ): Subject<void> {
    let returnSubject: Subject<void> = new Subject<void>();

    if (selectedParcelRegister && this.importPropertyDataService.isParcelRegisterAlreadyImported(selectedParcelRegister)) {
      this.dialogService.confirm('Error', 'The property information for this title has already been imported for this matter and cannot be imported a' +
        ' second time.', true, 'OK').subscribe(() => {
      });
      return;
    }

    let titleContext: string = 'Teranet Connect : Mismatch on whether property is a condominium';
    let propertyType: string = 'parcel register';
    if (this.matter.isMatterProvinceAB) {
      titleContext = 'SPIN : Mismatch on whether property is a condominium';
      propertyType = 'land title certificate';
    } else if (this.matter.isMatterProvinceMB) {
      titleContext = 'TPR : Mismatch on whether property is a condominium';
    } else if (this.matter.isMatterProvinceSK) {
      titleContext = 'ISC : Mismatch on whether property is a condominium';
    } else if (this.matter.isMatterProvinceBC) {
      titleContext = 'LTSA : Mismatch on whether property is a strata';
      propertyType = 'title search';
    }

    if (blanketMortgage) {
      updatePropertyDataForBlanketMortgage.emit(selectedParcelRegister);
    } else {
      if (selectedParcelRegister && selectedParcelRegister.isPropertyCondo(this.matter.provinceCode) != this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
        if (showWizardFields) {
          this.importPropertyDataService.updateCondoChangesForImport();
          this.openImportTeranetConnectDataModal(selectedParcelRegister, showWizardFields, updateOwnerDataForWizard, ontarioTaxRateSlab, torontoTaxRateSlab, jurisdictionDepartments).subscribe(() => {
            returnSubject.next();
          });
        } else {
          let importMessage: string = 'The ' + propertyType + ' indicated that the property is ' + (!selectedParcelRegister.isPropertyCondo(this.matter.provinceCode) ? 'not' : '') + ' a' +
            ` ${ this.matter.isMatterProvinceBC ? 'strata' : 'condominium' },` +
            ' but the response in the "Is this a ' + `${ this.matter.isMatterProvinceBC ? 'strata' : 'condo' }` + '" field in' +
            ' the' +
            ' property tab is ' + (this.matter.matterPropertyWithCondo.isPropertyCondominium() ? 'Yes.' : 'No.') + ' Would you like to change the ' +
            ' response in' +
            ' this' +
            ' field to ' + (this.matter.matterPropertyWithCondo.isPropertyCondominium() ? 'No?' : 'Yes?') + '';
          const contentModalConfig: DialogConfigParams = {
            title: titleContext,
            message: importMessage,
            hideCancelBtn: false,
            customConfirmBtnText: 'Yes',
            customBtnText: 'No',
            customReturnText: 'No'
          };
          this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => {
            if (res) {
              if (res.toString() != 'No') {
                this.importPropertyDataService.updateCondoChangesForImport();
              }
              this.openImportTeranetConnectDataModal(selectedParcelRegister, showWizardFields, updateOwnerDataForWizard, ontarioTaxRateSlab, torontoTaxRateSlab, jurisdictionDepartments).subscribe(() => {
                returnSubject.next();
              });
            }
          });
        }
      } else {
        this.openImportTeranetConnectDataModal(selectedParcelRegister, showWizardFields, updateOwnerDataForWizard, ontarioTaxRateSlab, torontoTaxRateSlab, jurisdictionDepartments).subscribe(() => {
          returnSubject.next();
        });
      }
    }
    return returnSubject;
  }

  private openOwnerModal(selectedParcelRegister: ParcelRegister, showWizardFields?: boolean, updateOwnerDataForWizard?: EventEmitter<string>): Subject<void> {
    let returnSubject: Subject<void> = new Subject<void>();

    let originalMainClients: MatterParticipant[] = [];
    this.matter.mainClients.forEach(item => originalMainClients.push(new MatterParticipant(item)));
    this.dialogService.matDialogContent({
      content: TeranetOwnerImportModal,
      context: {
        matter: this.matter,
        selectedParcelRegister,
        isWizard: showWizardFields
      },
      onFulfillment: async (result: any) => {
        if (result && result.action === ModalAction.OK) {
          this.matter.dirty = true;
          this.matter.matterPropertyWithCondo.lastImportedParcelRegisterId = selectedParcelRegister.id;
          let documentProfile: DocumentProfile = await this.importOwnerDataService.getDefaultDocumentProfile();
          if (ContactUtil.needUpdateAllSuggestions(documentProfile, originalMainClients, this.matter.mainClients)) {
            ContactUtil.updateAllSuggestions(this.importOwnerDataService.createMockWrapperList(), this.matter,
              new ContactSuggestion(), documentProfile, true, true, false, true);
          }
          if (showWizardFields) {
            updateOwnerDataForWizard.emit('OwnerImported');
          }
        } else if (result && result.action === CustomModalAction.WIZARD_OWNER_IMPORT_DATA_ISSUE) {
          this.dialogService.confirm('Warning', 'We are not able to import owner data , please import them manually once matter is created', true).subscribe(
            () => {
            });
        }
        returnSubject.next();
      }
    });
    return returnSubject;
  }

  private openImportTeranetConnectDataModal(
    selectedParcelRegister: ParcelRegister,
    showWizardFields: boolean = false, updateOwnerDataForWizard = new EventEmitter<string>(),
    ontarioTaxRateSlab: ConsiderationTaxes, torontoTaxRateSlab: ConsiderationTaxes,
    jurisdictionDepartments: JurisdictionDepartment[]
  ): Subject<void> {
    let returnSubject: Subject<void> = new Subject<void>();

    this.dialogService.matDialogContent({
      fullScreen: true,
      content: TeranetImportModal,
      context: {
        matter: this.matter,
        selectedParcelRegister: selectedParcelRegister,
        isWizard: showWizardFields
      },
      onFulfillment: (teranetImportDataSP: TeranetImportData) => {
        if (teranetImportDataSP) {
          this.importPropertyDataService.importTeranetConnectData(
            teranetImportDataSP,
            ontarioTaxRateSlab,
            torontoTaxRateSlab,
            jurisdictionDepartments,
            selectedParcelRegister,
            showWizardFields,
            updateOwnerDataForWizard
          );
        }
        if (showWizardFields) {
          this.openOwnerModal(selectedParcelRegister, showWizardFields, updateOwnerDataForWizard).subscribe(() => {
            returnSubject.next();
          });
        } else {
          returnSubject.next();
        }
      }
    });
    return returnSubject;
  }
}
