<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container border-radius" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Sale Price</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="row modal-body maximum-height">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <label class="control-label col-lg-4 vertical-align-label padding-top-5">Net out {{appliedTaxRate}} from {{appliedTaxRate}} inclusive
                    sale
                    price?</label>
                <div class="col-lg-7">
                    <select class="form-control"
                            id="netOutHst"
                            [fieldKey]="'matter.consideration.soa.hst'"
                            dp-error-validator
                            (change)="onNetOutHstFromHSTSalePriceChange()"
                            [(ngModel)]="salePriceAdjustmentLocal.netOutHstFromHSTSalePrice"
                            name="netOutHst">
                            <!--[disabled]="isTarionWarrantyAdjAvailableAndEligibleForTaxRebate || isOtherFixedAdjAvailableAndEligibleForTaxRebate">-->
                        <option *ngFor="let netOutHstValues of netOutHst" [value]="netOutHstValues.value">
                            {{netOutHstValues.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!salePriceAdjustmentLocal.isInclusivePrice()">
            <ng-container *ngIf="showFormatOfSalePriceAdjustment1 || isExtraGSTExist()">
                <div class="form-group">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-4 vertical-align-label padding-top-5">Format of Sale Price Adjustment</label>
                        <div class="col-lg-7">
                            <select class="form-control"
                                    id="format_not_inclusive_in_price"
                                    (change)="addHstWarning()"
                                    [(ngModel)]="salePriceAdjustmentLocal.salePriceAdjustmentFormat"
                                    name="format">
                                <option *ngFor="let salePriceAdj of salePriceAdjustmentFormatItems" [value]="salePriceAdj.value">
                                    {{salePriceAdj.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Sale price{{isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate ? ' as per Agreement':''}}</label>
                    <div class="col-lg-4">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.agreementSalePrice"
                               name="salePrice_not_inclusive_in_price"
                               id="salePrice_not_inclusive_in_price"
                               dp-default-currency
                               dp-currency
                               [dp-read-only]="isMatterProvinceSK()"
                               dp-error-validator
                               fieldKey="matter.soa.modal.salePriceSOA"
                               class="form-control text-right"/>

                    </div>
                    <ng-container *dpShowByProvince="'matter.matterProperty.purchaserPrice.readOnlyInfo'">
                    <span class="price-info-icon"><i class="fa fa-info-circle sale-price-info-circle"></i></span>
                    <span class="message">Edit from the Parcel on the Subject Property Tab</span>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="isMatterProvinceSK()">
                <div class="form-group">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-4 vertical-align-label padding-top-5">Price of Land</label>
                        <div class="col-lg-4">
                            <input type="text"
                                   [(ngModel)]="salePriceAdjustmentLocal.priceOfLand"
                                   name="priceOfLand"
                                   id="priceOfLand"
                                   significant-currency-digits="9"
                                   max-decimals="2"
                                   dp-default-currency
                                   dp-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-4 vertical-align-label padding-top-5">Home & Extras Excluding Land</label>
                        <div class="col-lg-4">
                            <input type="text"
                                   [ngModel]="homeAndExtrasExcludingLand"
                                   name="extrasExcludingLand"
                                   id="extrasExcludingLand"
                                   dp-default-currency
                                   significant-currency-digits="9"
                                   max-decimals="2"
                                   dp-currency
                                   [dp-read-only]="true"
                                   allow-negative="true"
                                   class="form-control text-right"/>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate">
                <div class="form-group">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-4">Additional Consideration</label>
                        <label class="control-label col-lg-4 text-left">See Below<sup>2</sup></label>
                    </div>
                </div>
            </ng-container>
            <div class="form-group" *ngIf="!isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate">
                <div class="col-lg-12" *dpShowByProvince="'matter.soa.sale-price-modal.creditVendor'">
                    <label class="control-label col-lg-4">Credit {{vendorLabel}}</label>
                    <div class="col-lg-4">
                        <label class="control-label col-lg-4 text-left padding-left-0">{{salePriceAdjustmentLocal.agreementSalePrice |
                            currency:CAD:'symbol'}}</label>

                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="salePriceAdjustmentLocal.isInclusivePrice()">
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4 vertical-align-label padding-top-5">Format of Sale Price Adjustment</label>
                    <div class="col-lg-7">
                        <select class="form-control"
                                id="format"
                                (change)="addHstWarning()"
                                [(ngModel)]="salePriceAdjustmentLocal.salePriceAdjustmentFormat"
                                name="format">
                            <option *ngFor="let salePriceAdj of salePriceAdjustmentFormatItems" [value]="salePriceAdj.value">
                                {{salePriceAdj.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Sale price as per Agreement, incl. {{appliedTaxRate}}</label>
                    <div class="col-lg-4">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.agreementSalePrice"
                               name="salePrice"
                               id="salePrice"
                               dp-default-currency
                               dp-currency
                               dp-error-validator
                               [dp-read-only]="isMatterProvinceSK()"
                               fieldKey="matter.soa.modal.salePriceSOA"
                               class="form-control text-right"/>

                    </div>
                    <ng-container *dpShowByProvince="'matter.matterProperty.purchaserPrice.readOnlyInfo'">
                    <span class="price-info-icon"><i class="fa fa-info-circle sale-price-info-circle"></i></span>
                    <span class="message">Edit from the Parcel on the Subject Property Tab</span>
                    </ng-container>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Chattels included in above sale price</label>
                    <div class="col-lg-4">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.chattelsIncludedInSalePrice"
                               name="chattels"
                               id="chattels"
                               dp-default-currency
                               dp-currency
                               class="form-control text-right"/>

                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="isAdditionalConsiderationsInclHstAvailable">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Additional Consideration, incl {{appliedTaxRate}}</label>
                    <ng-container *ngIf="isAdditionalConsiderationsAvailableOnOtherSheet">
                        <label class="control-label col-lg-6 text-left semi-bold">
                            <i class="fa fa-warning text-warning"></i> No such adjustments in {{context.matter.isAdjustmentStatusModeFinal ? 'Final' :'Interim'}}  SOA
                        </label>
                    </ng-container>
                    <div class="col-lg-4">
                        <input type="text"
                               *ngIf="!isAdditionalConsiderationsAvailableOnOtherSheet"
                               [(ngModel)]="salePriceAdjustmentLocal.additionalConsiderationsInclHst"
                               name="consideration"
                               id="consideration"
                               dp-default-currency
                               dp-currency
                               [dp-read-only]="isAdditionalConsiderationsReadOnly"
                               (blur)="additionalConsiderationsInclHstChange()"
                               class="form-control text-right"/>

                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="!isAdditionalConsiderationsInclHstAvailable">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Additional Consideration</label>
                    <label class="control-label col-lg-4 text-left">
                        See Below
                        <span *ngIf="superscript1"><sup>1</sup></span>
                        <span *ngIf="superscript1 && superscript2 && superscript3">, </span>
                        <span *ngIf="superscript1 && (superscript2 || superscript3) && (superscript2 != superscript3)"> and </span>
                        <span *ngIf="superscript2"><sup>2</sup></span>
                        <span *ngIf="superscript2 && superscript3"> and </span>
                        <span *ngIf="superscript3"><sup>3</sup></span>
                    </label>

                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Credits to {{purchaserLabel}}, incl {{appliedTaxRate}}</label>
                    <div class="col-lg-4">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.creditsToPurchaserInclHst"
                               name="credit"
                               id="credit"
                               dp-default-currency
                               dp-currency
                               [dp-read-only]="isCreditPurchaserReadOnly"
                               class="form-control text-right"/>

                    </div>
                </div>
            </div>
            <div *ngIf="context.project" class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">{{projectHstReductionInSalePriceAdjustmentLabel}}</label>
                    <div class="col-lg-4" *dpProjectShowByProvince="'project.soa.modal.sale.price.adj.salePricePortionNotSubjectToHST';project:context.project">
                        <ng-container *ngIf="isProjectHstReductionInSalePriceAdjustment">
                            <label class="control-label text-right">
                                <i class="fa fa-warning text-warning"></i> Info Only
                            </label>
                        </ng-container>
                        <ng-container *ngIf="!isProjectHstReductionInSalePriceAdjustment">
                            <input type="text"
                                   [(ngModel)]="context.project.projectAdjustmentConfig.salePricePortionNotSubjectToHST"
                                   id="salePricePortionNotSubjectToHST"
                                   name="salePricePortionNotSubjectToHST"
                                   class="form-control"
                                   [dp-read-only]="true"
                            />
                        </ng-container>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.excludeFromTax"
                               id="excludeFromTax"
                               name="excludeFromTax"
                               dp-default-currency
                               dp-currency
                               class="form-control text-right"/>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Buydowns, etc.</label>
                    <div class="col-lg-4">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.buyDowns"
                               id="buyDowns"
                               name="buyDowns"
                               class="form-control"/>

                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="salePriceAdjustmentLocal.buyDowns">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">{{salePriceAdjustmentLocal.buyDowns}}</label>
                    <div class="col-lg-4">
                        <input type="text"
                               [(ngModel)]="salePriceAdjustmentLocal.otherItems"
                               name="other"
                               id="other"
                               dp-default-currency
                               dp-currency
                               class="form-control text-right"/>

                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *dpShowByProvince="'matter.soa.modal.adjustmentHeading'">
            <div *ngIf="!salePriceAdjustmentLocal.isInclusivePrice()" class="col-lg-12">
                <table class="table dp-teranet-table " tabindex="-1" (keydown)="onKeyPress($event)">
                    <thead>
                    <tr>
                        <th>Adjustment Heading</th>
                        <th></th>
                        <th>Cost</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="salePriceAdjustmentHeadings && !(salePriceAdjustmentHeadings.length)"
                        tabindex="0"
                        [ngClass]="{'active': isRowSelected(-1), 'dp-teranet-row-active': isRowSelected(-1)}"
                        (click)="updateRowIndex(-1)" (keydown.tab)="updateRowIndex(-1)">
                        <td></td>
                        <td>
                            <dp-dropdown-menu [dropDownItems]="emptyAdjustmentHeadingsMenutems"
                                              (itemClicked)="clickAdjustmentHeadingsMenutem($event)"
                                              (menuOpened)="updateRowIndex(-1)" >
                            </dp-dropdown-menu>
                        </td>
                        <td>$0.00</td>
                    </tr>
                    <tr *ngFor="let item of salePriceAdjustmentHeadings; let i=index;" tabindex="0"
                        [ngClass]="{'active': isRowSelected(i), 'dp-teranet-row-active': isRowSelected(i)}"
                        (click)="updateRowIndex(i)" (keydown.tab)="updateRowIndex(i)">
                        <td>{{item.name}}</td>
                        <td>
                            <dp-dropdown-menu [dropDownItems]="adjustmentHeadingsMenutems"
                                              (itemClicked)="clickAdjustmentHeadingsMenutem($event)"
                                              (menuOpened)="updateRowIndex(i)"
                                              (keydown.tab)="moveToNextRow()" >
                            </dp-dropdown-menu>
                        </td>
                        <td>{{item.cost | currency:CAD:'symbol'}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="showAdditionalConsiderationDetails">
            <div *ngIf="salePriceAdjustmentLocal.agreementSalePrice >= 0">
                <div class="form-group">

                    <div *ngFor="let line of details">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-5 text-left">{{line.title}}<sup>{{line.noteReferenceMark}}</sup></label>
                            <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                            <label *ngIf="line.hintType == 'Ref1'">
                                <span class="info-icon"><i class="fa fa-info-circle tarion-info-circle" aria-hidden="true"></i></span>
                                <span class="matter-locked-message">
                                    <div class="col-lg-12">
                                        <table class="standard-table">
                                            <thead>
                                                <th class="width-70-percent"><b>ADJUSTMENT</b></th>
                                                <th class="width-30-percent"><b>AMOUNT</b></th>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let t1 of details1">
                                                <td>{{t1.title}}<sup>{{t1.noteReferenceMark}}</sup></td>
                                                <td>{{t1.value}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </label>
                            <label *ngIf="line.hintType == 'Ref2'">
                                <span class="info-icon"><i class="fa fa-info-circle tarion-info-circle" aria-hidden="true"></i></span>
                                <span class="matter-locked-message">
                                    <div  class="col-lg-12">
                                        <table class="standard-table">
                                            <thead>
                                                <th class="width-70-percent"><b>ADJUSTMENT</b></th>
                                                <th class="width-30-percent"><b>AMOUNT</b></th>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let t2 of details2">
                                                <td>{{t2.title}}<sup>{{t2.noteReferenceMark}}</sup></td>
                                                <td>{{t2.value}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </label>
                            <label *ngIf="line.hintType == 'Ref3'">
                                <span class="info-icon"><i class="fa fa-info-circle tarion-info-circle" aria-hidden="true"></i></span>
                                <span class="matter-locked-message">
                                    <div  class="col-lg-12">
                                        <table class="standard-table">
                                            <thead>
                                                <th class="width-70-percent"><b>ADJUSTMENT</b></th>
                                                <th class="width-30-percent"><b>AMOUNT</b></th>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let t3 of details3">
                                                <td>{{t3.title}}<sup>{{t3.noteReferenceMark}}</sup></td>
                                                <td>{{t3.value}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-lg-12" *ngIf="!hideCreditVendorSalePriceLabel()">
                        <label class="control-label col-lg-5 text-left">{{salePriceAdjustmentLocal.isSalePriceFormatNSP() ? "Credit "+vendorLabel+" (Net Sale Price)"
                            :
                            "Credit "+vendorLabel+" (Agreed Sale Price)"}}</label>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-2"></div>
                        <label class="control-label col-lg-2">{{(salePriceAdjustmentLocal.isSalePriceFormatNSP() ?
                            (context.considerationLtt && context.considerationLtt.creditVendorPrice(context.provinceHstRateSlab, salePriceAdjustmentLocal)):
                            salePriceAdjustmentLocal.agreementSalePrice)|
                            currency:CAD:'symbol' }}</label>
                    </div>
                </div>

            </div>
        </ng-container>
        <ng-container *dpShowByProvince="'matter.soa.modal.adjustmentHeading'">
            <div class="form-group" *ngIf="salePriceAdjustmentLocal.isNotInclusivePrice()">
                <div class="col-lg-12">
                    <label class="control-label col-lg-5 text-left">Credit {{vendorLabel}}</label>
                    <div class="col-lg-3 text-right">
                        <label class="control-label padding-left-0">
                            {{(salePriceAdjustmentLocal.isSalePriceFormatNSP() ?
                            (salePriceAdjustmentLocal.totalNetSalePrice(federalHstRate,provinceHstRate)) : salePriceAdjustmentLocal.agreementSalePrice) |
                            currency:CAD:'symbol' }}
                        </label>

                    </div>
                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-5 text-left">Plus extras subject to {{appliedTaxRate}}</label>
                    <div class="col-lg-3 text-right">
                        <label class="control-label padding-left-0">{{salePriceAdjustmentLocal.getExtrasCost(salePriceAdjustmentHeadings) | currency:CAD:'symbol'}}</label>

                    </div>
                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-5 text-left">Total Consideration subject to {{appliedTaxRate}}</label>
                    <div class="col-lg-3 text-right">
                        <label class="control-label padding-left-0">
                            {{totalConsiderationWithExtraGST(salePriceAdjustmentLocal.totalNetSalePrice(federalHstRate,provinceHstRate),salePriceAdjustmentLocal.getExtrasCost(salePriceAdjustmentHeadings)) |
                            currency:CAD:'symbol'}}</label>

                    </div>
                </div>
                <ng-container *ngIf="isMatterProvinceSK()">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-5 text-left">Price of Land</label>
                        <div class="col-lg-3 text-right">
                            <label class="control-label padding-left-0">
                                {{salePriceAdjustmentLocal.priceOfLand | currency:CAD:'symbol'}}</label>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <label class="control-label col-lg-5 text-left">Total Consideration subject to PST</label>
                        <div class="col-lg-3 text-right">
                            <label class="control-label padding-left-0">
                                {{totalConsiderationSubjectToPST() | currency:CAD:'symbol'}}</label>
                        </div>
                    </div>
                </ng-container>

            </div>
        </ng-container>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <div class="col-lg-10 buttons">
                    <button (click)="ok()" class="dp-btn keyboard-save-action" id="okBtn2" type="button">
                        <span>OK</span>
                    </button>

                    <button (click)="close()" class="dp-btn dp-btn-cancel" type="button">
                        <span>Cancel</span>
                    </button>

                    <button (click)="reset()" class="secondary-button" type="button" [disabled]="isResetDisabled" *ngIf="!isMatterProvinceSK()">
                        <span>Reset</span>
                    </button>
                </div>
                <div *ngIf="context.project">
                    <div class="col-lg-2" *dpProjectShowByProvince="'project.soa.modal.sale.price.adj.infoOnly';project:context.project">
                        <dp-checkbox fieldId="checkBoxInfoOnly">
                            <input type="checkbox"
                                   id="checkBoxInfoOnly"
                                   name="checkBoxInfoOnly"
                                   [(ngModel)]="salePriceAdjustmentLocal.infoOnly"
                                   [checked]="salePriceAdjustmentLocal.infoOnly"/>
                        </dp-checkbox>

                        <label class="control-label vertical-align-label display-inline" >Info Only</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="padding: 5px 10px 0px 5px;">
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    </div>
</form>
