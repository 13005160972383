import {AfterViewInit, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ScrollList} from './dialog.service';

declare var jQuery: any;

@Component({
  selector: 'confirm-dialog',
  template: `
                   <div class="confirm-dialog" (keydown)="onKeyDown($event)">
                       <div class="modal-header">
                           <div class="col-lg-12">
                               <h1><span [innerHtml]="title"></span>
                                   <button [hidden]="hideCancelBtn" class="btn-close float-right" type="button" (click)="dialogRef.close(false)" tabIndex="-1">
                                       <span aria-hidden="true">×</span></button>
                               </h1>
                           </div>
                       </div>
                       <div class="modal-body">
                           <div *ngIf="infoMessage" class="form-group col-md-12 padding-bottom-5">
                               <div class="col-lg-12" style="border: 2px solid #ddd; padding: 10px">
                                   <span class="info-icon padding-right-5 padding-left-5 left padding-top-7"><i class="fa fa-info-circle font-20 blue" aria-hidden="true"></i></span>
                                   <label class="control-label col-md-11 text-left" style="line-height: 20px" [innerHtml]="infoMessage"></label>
                               </div>
                           </div>
                           <div class="form-group col-md-12">
                               <p [ngClass]="{'text-center' : !alignContentLeft, 'text-left' : alignContentLeft, 'message-word-break' : breakOnLongWords }" class="col-lg-12"
                                    style="padding: 10px 40px 10px 40px;line-height: 16px; white-space: pre-line;"
                                    [innerHtml]="message" ></p>
                           </div>
                           <div *ngIf="scrollLists && scrollLists.length" class="form-group col-md-12">
                               <div *ngFor="let scrollList of scrollLists">
                                   <p *ngIf="scrollList.title" style="padding: 0px 40px 0px 40px;line-height: 16px; white-space: pre-line;">{{scrollList.title}}</p>
                                   <div class="form-group col-md-10 col-md-offset-1" style="max-height:200px; overflow-y: auto;">
                                       <ul style="list-style: circle; padding: 15px;"><li *ngFor="let item of scrollList.list" class="padding-bottom-10">{{item}}</li></ul>
                                   </div>
                               </div>
                           </div>

                       </div>
                       <div class="modal-footer">
                           <div class="modal-footer">
                               <div class="col-md-12 text-center" [ngClass]="buttonClass">
                                   <button class="margin-right-10" id="unsavedChangesOK" (focusout)="checkTarget($event);" type="button"
                                           (click)="onConfirmBtnClicked();"
                                           [hidden]="hideOkBtn"
                                           [ngClass]="{'dp-btn-green' : !defaultCancel ,'dp-btn-white': defaultCancel }">
                                       <span>{{customConfirmBtnText ? customConfirmBtnText : 'OK'}}</span>
                                   </button>
                                   <button type="button" class="dp-btn-white" (focusout)="checkTarget($event);" id="btnCustom"
                                           (click)="dialogRef.close(customReturnText ? customReturnText : true)"
                                           *ngIf="customBtnText" tabIndex="0">
                                       <span>{{customBtnText}}</span>
                                   </button>
                                   <button type="button" class="margin-left-10" (focusout)="checkTarget($event);" id="btnCancel" (click)="dialogRef.close(false)"
                                           [hidden]="hideCancelBtn" tabIndex="0" [ngClass]="{'dp-btn-green' : defaultCancel ,'dp-btn-white': !defaultCancel }">
                                       <span>{{customCancelBtnText ? customCancelBtnText : 'Cancel'}}</span>
                                   </button>
                                   <span class="right padding-top-5" *ngIf="customClipBoardText">
                                  <dp-checkbox fieldId="copyClipBoard"><input type="checkbox"
                                                                            id="copyClipBoard" checked="true"
                                                                            ngxClipboard [cbContent]="customClipBoardCopyText"
                                                                            name="copyClipBoard"
                                                                            #copyClipBoard></dp-checkbox>

                            <label class="control-label vertical-align-label display-inline"  >{{customClipBoardText}}</label>
                </span>
                               </div>
                           </div>
                       </div>
                   </div>
               `,
  styleUrls: [
    './confirm-dialog.component.scss'
  ]
})
export class ConfirmDialog implements AfterViewInit {

  public title: string;
  public message: string;
  public hideOkBtn: boolean;
  public hideCancelBtn: boolean;
  public alignContentLeft: boolean = true;
  public customConfirmBtnText: string;
  public customCancelBtnText: string;
  public customBtnText: string;
  public customReturnText: string;
  public keyCommandListener: Function;
  public alignCenter: boolean = true;
  public buttonClass: string;
  public defaultCancel: boolean;
  public customClipBoardText: string;
  public customClipBoardCopyText: string;
  confirmFunction: Function;
  public infoMessage: string;
  public scrollLists: ScrollList[];
  public breakOnLongWords: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let defaultBtn = this.defaultCancel ? '#btnCancel' : '#unsavedChangesOK';
      jQuery(defaultBtn).focus();
    }, 500);

  }

  onKeyDown(event) {
    event.stopPropagation();
    //Esc Key
    if (event.which === 27) {
      this.dialogRef.close(false);
    }
    //Enter Key
    if (event.which === 13) {
      //this.dialogRef.close(true); //No need
    }
  }

  checkTarget(event) {
    /// keep focus on confirmation buttons so user cannot focus on fields behind modal

    console.log(event);
    if (event.relatedTarget == null || event.relatedTarget.id == '') {
      jQuery('#unsavedChangesOK').focus();
    }
  }

  onConfirmBtnClicked() {
    if (this.confirmFunction) {
      this.confirmFunction();
    }
    this.dialogRef.close(true);
  }

}
