import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class CaslInfo extends BaseEntity {
  id: number;
  caslEmailOptOut: boolean;
  staffProfileId: number;
  /**
   * a value of -1 implies this is 'Affected Individual'
   */
  caslRequestedById: number;
  caslRequestedByName: string;
  caslLastModifiedById: number;
  caslLastModifiedBy: string;
  caslLastModifiedDate: Date;
  sameAsStaffProfile: boolean;
}
