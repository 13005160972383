import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {SoAdjVacantOccupancyTax} from '../model/so-adj-vacant-occupancy-tax';

export class StatementAdjustmentDisplayVacantOccupancyTaxBuilder {

  static getDisplayItems(soaAdj: SoAdjVacantOccupancyTax, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {

    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    let assessmentValue: number = soaAdj.assessmentAmount ? soaAdj.assessmentAmount : 0;
    let interestRate: number = soaAdj.interestRate ? soaAdj.interestRate : 0;
    let totalAmount: number =

      sadItemDetails.push(mainBuilder.getNewItemLine(
        `${ mainBuilder.getCurrency(assessmentValue) } CVA`,
        ``
      ));
    sadItemDetails.push(mainBuilder.getNewItemLine(
      `at ${ interestRate }% interest`,
      `${ mainBuilder.getCurrency(this.calculateVacantOccupancyTaxAmount(soaAdj)) }`
    ));
    /*sadItemDetails.push(mainBuilder.getNewItemLine(
        `Credit Purchaser`,
        `${mainBuilder.getCurrency(this.calculateVacantOccupancyTaxAmount(soaAdj))}`
    ));*/

    return sadItemDetails;
  }

  static calculateVacantOccupancyTaxAmount(soaAdj: SoAdjVacantOccupancyTax): number {
    return Number((soaAdj.assessmentAmount ? soaAdj.assessmentAmount : 0) * (soaAdj.interestRate ? soaAdj.interestRate : 0) / 100);
  }

}
