<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Manage Category</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div class="form-group">
            <div class="fields-group ">
                <div class="fixed-header col-lg-12">
                    <div class="col-lg-2 vertical-align-label margin-top-8">
                        <label class="control-label" for="categoryName">Category Name*</label>
                    </div>
                    <div class="col-lg-10 padding-right-10">
                        <input type="text" name="categoryName" id="categoryName" *ngIf="isDefault"
                               class="form-control"
                               maxlength="150"
                               trimmed
                               dp-error-validator
                               [fieldKey]="'documentProduction.categoryName'"
                               [dp-read-only]="true"
                               [value]="getCategoryDescription(documentTemplateCategory)"/>
                        <input type="text" name="categoryName" id="categoryName" *ngIf="!isDefault"
                               class="form-control"
                               maxlength="150"
                               trimmed
                               dp-error-validator
                               [fieldKey]="'documentProduction.categoryName'"
                               [(ngModel)]="documentTemplateCategory.categoryDescription"/>
                    </div>
                    <div class="col-lg-12 main-level padding-top-20">
                        <span class="title">Manage Master Templates</span>
                    </div>
                </div>

                <form class="col-lg-12 form-horizontal margin-top-100">

                    <div class="col-lg-12 text-right padding-right-10">
                        <button *ngIf="isDefault" type="button" class="dp-btn dp-btn-cancel" (click)="delete()">
                            <span>Delete</span>
                        </button>
                        <button *ngIf="moveUpDownOptionsAvailable" type="button" class="dp-btn dp-btn-cancel" (click)="moveUp()">
                            <span>Move Up</span>
                        </button>
                        <button *ngIf="moveUpDownOptionsAvailable" type="button" class="dp-btn dp-btn-cancel" (click)="moveDown()">
                            <span>Move Down</span>
                        </button>
                    </div>
                    <div class="col-lg-12 padding-right-10">
                        <div *ngIf="!isDefault" class="form-group col-sm-12 col-lg-5 margin-left-10" >
                            <div class="control-label col-sm-12 padding-bottom-10 text-left" for="notInGroupSelect" >Available</div>
                            <div class="col-sm-12 ">

                                <select size="10" class="form-control noBG" id="notInGroupSelect" name="notInGroupSelect" multiple
                                        [(ngModel)]="selectedTemplateIDArr[0]" >
                                    <option *ngFor="let template of notSelectedList;"
                                            [value]="template.docGenTemplateId" >
                                        {{template.fileName}} <span *ngIf="template.description"> - {{template.description}}</span>
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div *ngIf="!isDefault" class="form-group col-sm-12 col-lg-2 padding-top-75">

                            <div class="col-sm-12 text-center ">
                                <button class="dp-btn dp-btn-cancel dp-btn-small" type="button"
                                        (click)="moveTemplate(notSelectedList, selectedList, 0 , 1, 'inGroupSelect')"
                                        [disabled]="disableMoveRight"
                                >
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="col-sm-12 text-center padding-top-10">
                                <button class="dp-btn dp-btn-cancel dp-btn-small" type="button"
                                        (click)="moveTemplate(selectedList, notSelectedList, 1 , 0, 'notInGroupSelect')"
                                        [disabled]="disableMoveLeft"
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                </button>
                            </div>


                        </div>

                        <div class="form-group col-sm-12" [ngClass]="{'col-lg-5': !isDefault , 'col-lg-12': isDefault}">
                            <div class="control-label col-sm-12 padding-bottom-10  text-left" for="inGroupSelect" >Selected</div>
                            <div class="col-sm-12 ">

                                <select size="10" class="form-control noBG" id="inGroupSelect" name="inGroupSelect" multiple
                                        [(ngModel)]="selectedTemplateIDArr[1]"
                                        (ngModelChange)="changeSelectedTemplate($event)">
                                    <option *ngFor="let template of selectedList; let i=index;"
                                            [value]="template.docGenTemplateId" >
                                        {{template.fileName}} <span *ngIf="template.description"> - {{template.description}}</span>
                                    </option>
                                </select>

                            </div>


                        </div>

                    </div>
                </form>
            </div>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn" (click)="save()" [disabled]="!documentTemplateCategory.categoryDescription">
                <span>Save</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
