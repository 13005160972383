import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {RequisitionTemplate} from '../shared/requisition-template';
import {RequisitionsService} from './requisitions.service';
import {Subscription} from 'rxjs';
import {NgModel} from '@angular/forms';
import {Instrument} from '../../shared-main/teranet/instrument';
import {RequisitionInstrumentConfig} from '../shared/requisition-instrument-config';
import {DialogService} from '../../shared/dialog/dialog.service';
import {RequisitionsAddTemplateModal} from './requisition-add-template.modal.component';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class RequisitionsAddModalContext {
  public selectedTemplate: RequisitionTemplate;
  public instrument: Instrument;
  public configs: RequisitionInstrumentConfig[];
  public editReqTemplates: boolean;
}

@Component({
  selector: 'dp-requisitions-add-modal',
  templateUrl: './requisitions-add.modal.component.html',
  styleUrls: ['./requisitions-add.modal.component.scss'],
  providers: [RequisitionsService]
})
export class RequisitionsAddModal extends ModalComponent<RequisitionsAddModalContext> implements OnInit, OnDestroy {
  rows: RequisitionTemplate[] = [];
  allRows: RequisitionTemplate[] = [];
  filteredRows: RequisitionTemplate[] = [];
  filteredConfigs: string[] = [];
  initialFilterCheck: boolean = true;
  enableShowAll: boolean = false;
  showAllRequisitions: boolean = false;
  loading: boolean = false;
  reqTemplateDropMenuItems: BurgerMenuExtendedItem[] = [];

  selectedTemplate: RequisitionTemplate;
  subscription: Subscription;
  searchControlSubscription: Subscription;
  searchKey: string;
  @ViewChild('search', {static: true, read: NgModel}) searchControl: NgModel;
  @ViewChild('scrollable') public scrollable: ElementRef;
  @ViewChild('searchInput') public searchInput: ElementRef;

  constructor(
    public dialog: MatDialogRef<RequisitionsAddModal>,
    public requisitionService: RequisitionsService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: RequisitionsAddModalContext
  ) {
    super(dialog, context);
  }

  onSelectTemplate(): void {
    if (this.selectedTemplate && !this.context.editReqTemplates) {
      this.context.selectedTemplate = this.selectedTemplate;
      this.dialog.close(this.context);
    } else if (this.selectedTemplate && this.context.editReqTemplates) {
      this.editReqTemplate(this.selectedTemplate);
    }
  }

  ngOnInit(): void {
    if (this.isInstrument()) {
      this.context.configs.map((config) => {
        if (config.description == this.context.instrument.type) {
          this.filteredConfigs.push(config.requisitionName);
        }
      });
    }

    this.searchControlSubscription = this.searchControl.valueChanges
      .debounceTime(500)
      .distinctUntilChanged()
      .subscribe((searchText: string) => {
        this.searchTemplates(searchText);
      });

    if (this.context.editReqTemplates) {
      this.addToBurgerMenu(this.reqTemplateDropMenuItems, 'Edit', this.editReqTemplate);
      this.addToBurgerMenu(this.reqTemplateDropMenuItems, 'Copy', this.copyReqTemplate);
      this.addToBurgerMenu(this.reqTemplateDropMenuItems, 'Delete', this.deleteReqTemplate);
    }
  }

  addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], text: string, action?: any): BurgerMenuExtendedItem {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.action = action;
    burgerMenuItems.push(burgerMenuItem);
    return burgerMenuItem;
  }

  clickReqTemplateDropDownMenu(
    requisitionTemplate: RequisitionTemplate,
    clickedMenuOption: BurgerMenuExtendedItem
  ): void {
    this.updateSelectedItem(requisitionTemplate);
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(requisitionTemplate);
    }
  }

  editReqTemplate = (requisitionTemplate: RequisitionTemplate) => {
    this.editRequisitionTemplateDialog(requisitionTemplate);
  };

  copyReqTemplate = (requisitionTemplate: RequisitionTemplate) => {
    this.copyRequisitionTemplateDialog(requisitionTemplate);
  };

  deleteReqTemplate = (requisitionTemplate: RequisitionTemplate) => {
    const index: number = this.rows.findIndex((requisition) => requisitionTemplate.id === requisition.id);

    this.dialogService
      .confirm(
        'Confirmation',
        'Requisition Name: ' + requisitionTemplate.key + '.\nAre you sure you would like to delete this template?',
        false,
        'Delete',
        'Cancel',
        true
      )
      .subscribe((res) => {
        if (res) {
          this.requisitionService.deleteRequisition(requisitionTemplate).subscribe((res) => {
            if (res) {
              const index: number = this.rows.findIndex((requisition) => requisitionTemplate.id === requisition.id);
              this.rows.splice(index, 1);
              this.selectedTemplate = this.rows[0];
            }
          });
        }
      });
  };

  searchTemplates(key?: string) {
    this.loading = true;
    this.selectedTemplate = null;

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.requisitionService
      .getDefaultRequisitions(key)
      .subscribe((requisitionTemplates: RequisitionTemplate[]) => {
        this.allRows = requisitionTemplates;

        //only handle filtering if adding requisition based on instrument
        if (this.isInstrument()) {
          this.filteredRows = requisitionTemplates.filter(
            (template) => this.filteredConfigs.indexOf(template.key) > -1
          );

          //need to enable/disable the Show All checkbox based on whether any filtered matches were found initially
          if (this.initialFilterCheck) {
            this.enableShowAll = this.filteredRows.length > 0;
            this.initialFilterCheck = false;
          }

          if (!this.showAllRequisitions && this.enableShowAll) {
            this.rows = this.filteredRows;
          } else {
            this.rows = this.allRows;
          }
        } else {
          this.rows = this.allRows;
        }

        this.selectedTemplate = this.rows[0];
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.searchControlSubscription) {
      this.searchControlSubscription.unsubscribe();
    }
  }

  isRowSelected(row: RequisitionTemplate): boolean {
    return this.selectedTemplate === row;
  }

  isInstrument(): boolean {
    return this.context.instrument ? true : false;
  }

  updateSelectedItem(template: RequisitionTemplate): void {
    this.selectedTemplate = template;
    let selectedRequisitionIndex: number = _.findIndex(this.rows, (p: RequisitionTemplate) => {
      return p === this.selectedTemplate;
    });
    jQuery('#requisitionArray' + selectedRequisitionIndex).focus();
  }

  close(): void {
    this.dialog.close();
  }

  onKeyPress(event: KeyboardEvent): void {
    let selectedRequisitionIndex: number = _.findIndex(this.rows, (p: RequisitionTemplate) => {
      return p === this.selectedTemplate;
    });
    if (event.keyCode === 40) {
      //Down arrow key
      event.preventDefault();
      if (selectedRequisitionIndex < this.rows.length - 1) {
        this.selectedTemplate = this.rows[selectedRequisitionIndex + 1];
        setTimeout(() => {
          jQuery('tr.active').focus();
        }, 0);
      }
    } else if (event.keyCode === 38) {
      //Up arrow key
      event.preventDefault();
      if (selectedRequisitionIndex > 0) {
        this.selectedTemplate = this.rows[selectedRequisitionIndex - 1];
        setTimeout(() => {
          jQuery('tr.active').focus();
        }, 0);
      }
    } else if (event.keyCode === 13) {
      event.preventDefault();
      this.onSelectTemplate();
    } else if (this.context.editReqTemplates && event.keyCode == 46) {
      event.preventDefault();
      this.deleteReqTemplate(this.selectedTemplate);
    }
  }

  scrollDown(selectedRequisitionIndex: number): void {
    var tr = jQuery(this.scrollable.nativeElement)
      .find('tr')
      .eq(selectedRequisitionIndex + 1);
    var top = tr.position().top;
    tr.find('input').focus();
    if (top > 240) {
      jQuery('.templates-table').scrollTop(top + 40 - 240);
    }
    jQuery(this.searchInput.nativeElement).focus();
  }

  scrollUp(selectedRequisitionIndex: number): void {
    var tr = jQuery(this.scrollable.nativeElement)
      .find('tr')
      .eq(selectedRequisitionIndex - 1);
    var top = tr.position().top;
    tr.find('input').focus();
    if (top == 0) {
      jQuery('.templates-table').scrollTop(0);
    }
    jQuery(this.searchInput.nativeElement).focus();
  }

  showAllRequisitionsClick(): void {
    if (!this.showAllRequisitions) {
      this.rows = this.allRows;
      this.showAllRequisitions = true;
    } else {
      this.rows = this.filteredRows;
      this.showAllRequisitions = false;
    }
  }

  addNewRequisitionTemplateDialog(): void {
    this.dialogService.matDialogContent({
      content: RequisitionsAddTemplateModal,
      context: {isCategoryVisible: true, action: 'NEW'},
      onFulfillment: (result) => {
        if (result && result.requisitionTemplate) {
          this.allRows.push(result.requisitionTemplate);
          console.log(result);
          this.selectedTemplate = this.rows[this.rows.length - 1];
          setTimeout(() => {
            jQuery('#requisitionArray' + (this.rows.length - 1)).focus();
          }, 100);
        }
      }
    });
  }

  editRequisitionTemplateDialog(requisitionTemplate: RequisitionTemplate): void {
    this.dialogService.matDialogContent({
      content: RequisitionsAddTemplateModal,
      context: {isCategoryVisible: true, action: 'EDIT', requisitionTemplate: requisitionTemplate},
      onFulfillment: (result) => {
        if (result && result.requisitionTemplate) {
          let index = this.allRows.findIndex((item) => item.id == requisitionTemplate.id);
          this.allRows[index] = result.requisitionTemplate;
          this.updateSelectedItem(result.requisitionTemplate);
          console.log(result);
        }
      }
    });
  }

  copyRequisitionTemplateDialog(requisitionTemplate: RequisitionTemplate): void {
    this.dialogService.matDialogContent({
      content: RequisitionsAddTemplateModal,
      context: {isCategoryVisible: true, action: 'COPY', requisitionTemplate: requisitionTemplate},
      onFulfillment: (result) => {
        if (result && result.requisitionTemplate) {
          this.allRows.push(result.requisitionTemplate);
          this.updateSelectedItem(result.requisitionTemplate);
          console.log(result);
        }
      }
    });
  }
}
