<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Reset/Import Project Statement of Adjustments</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group col-lg-12">
            <div class="col-lg-12">
                <div class="col-lg-1"></div>
                <div class="col-lg-11">
                    <span>
                        This function allows the Project Statement of Adjustment to be deleted (including headers and footers) or to import Project <br>
                        Statement of Adjustment from another Project.
                        <span *ngIf="context.numberOfProjectMatters">
                            All adjustments, with the exception of Sale Price and Deposit, will be deleted from all Project <br>
                            Sale Matters within the Project. The values in the Sale Price and Deposit adjustments may be retained depending on the selection mode below.<br><br>
                        You must make a selection in each of "Project Statement of Adjustments" and "Adjustments Within Existing Matters" in order to proceed.
                        </span>
                        <br><br>
                        Note: If importing from another project, all settings including headers and footers will first be deleted then replaced with those
                        from the<br>
                        imported Project.<br>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group col-lg-12">
            <div class="col-lg-12">
                <div class="col-lg-1">
                    <i class="fa fa-times-circle red padding-right-10" ></i>
                    <span class="red">Warning</span>
                </div>
                <div class="col-lg-11">
                    <span class="red">
                        - Changes will apply to all Statements of Adjustments within the Project (both interim and final, if applicable)
                    </span>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="col-lg-1">
                    <span></span>
                </div>
                <div class="col-lg-11">
                    <span class="red">
                        - This function cannot be undone once "Proceed" has been selected
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Project Statement of Adjustments</span>
                </div>
            </div>
        </div>

        <div class="form-group col-lg-12">
            <div class="col-lg-1"></div>
            <div class="col-lg-11">
                <ul>
                    <li *ngFor="let ao of actionOptions; let i = index;">
                        <span class="radio">
                            <input type="radio"
                                   id="action-option-{{i}}"
                                   name="action-option-{{i}}"
                                   value="{{ao.value}}"
                                   [(ngModel)]="selectedAction"
                            />
                            <label tabindex="0" for="action-option-{{i}}" class="label-text">{{ao.label}}</label>
                        </span>
                        <span *ngIf="selectedAction == 'IMPORT' && ao.value == 'IMPORT'">
                                <label class="label-text display-inline-block" style="margin: 10px 10px 15px 20px">Project Record No and Name</label>
                                <div class="display-inline-block width-300">

                                <p-autoComplete
                                                      id="selectedProject"
                                                      name="selectedProject"
                                                      [ngModel]="selectedProjectForSearch"
                                                      [suggestions]="projectSuggestions"
                                                      (completeMethod)="omniSearchProject($event)"
                                                      (onSelect)="dataSelectedProject($event)"
                                                      [dropdown]="true"
                                                      [class.dropdown-arrow-refferedBy]="true"
                                                      (onDropdownClick)=" handleDropdownClickProject()"
                                                      maxlength="100" scrollHeight="350px"
                                                      minLength="0" delay="1000"
                                      >
                                    <ng-template let-project pTemplate="item" >
                                <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="project">
                                                <span class="omnibarLi100">
                                                    {{ project.label }}
                                                </span>
                                </li>
                                </ng-template>
                                </p-autoComplete>



                              <!--  <select class="form-control"
                                        style="margin: 10px 10px 10px 20px;"
                                        id="selectedProject"
                                        name="selectedProject"
                                        [(ngModel)]="sourceProjectId"
                                        >
                                    <option *ngFor="let opt of projectOptions"
                                            [ngValue]="opt.value">
                                        {{opt.label}}
                                    </option>
                                </select>-->
                                    </div>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <ng-container *ngIf="context.numberOfProjectMatters">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustments Within Existing Matters</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-11">
                    <ul>
                        <li *ngFor="let rao of removeAdjustmentOptions; let i = index;">
                            <span class="radio">
                                <input type="radio"
                                       id="remove-adj-{{i}}"
                                       name="remove-adj-{{i}}"
                                       value="{{rao.value}}"
                                       [(ngModel)]="selectedRemoveAdjustmentAction"
                                />
                                <label tabindex="0" for="remove-adj-{{i}}" class="label-text">{{rao.label}}</label>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="proceedBtn"
                    class="dp-btn dp-btn-ignore-disabled"
                    [disabled]="(!selectedAction || !selectedRemoveAdjustmentAction || (selectedAction == 'IMPORT' && !sourceProjectId))"
                    (click)="ok()">
                <span>Proceed</span>
            </button>
            <button type="button"
                    class="dp-btn dp-btn-cancel"
                    (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
