import {Component, Inject} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SoAdjPurchasePriceDeferredPortion} from '../../model/so-adj-purchase-price-deferred-portion';
import {StatementAdjustment} from '../../statement-adjustment';
import {ModalResult} from '../../../../shared-main/enums';
import {Matter} from '../../../shared/matter';
import SharedUtils from '../../../../shared-main/utils';
import {Utils} from '../../../shared/utils';
import {Project} from '../../../../projects/shared/project';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class PurchasePriceDeferredPortionModalContext {
  public closingDate: string;
  public soAdjPurchasePriceDeferredPortion: SoAdjPurchasePriceDeferredPortion;
  public matter: Matter;
  public project: Project;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-purchase-price-deferred-portion',
  templateUrl: './purchase-price-deferred-portion.component.html',
  providers: [ErrorService]
})
export class PurchasePriceDeferredPortionModal extends ModalComponent<PurchasePriceDeferredPortionModalContext> {
  soAdjPurchasePriceDeferredPortion: SoAdjPurchasePriceDeferredPortion;
  closingDateLabel: string;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<PurchasePriceDeferredPortionModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: PurchasePriceDeferredPortionModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }

    if (!this.context.soAdjPurchasePriceDeferredPortion) {
      this.newAdj = true;
      this.soAdjPurchasePriceDeferredPortion = new SoAdjPurchasePriceDeferredPortion();
      const firstInterimEarlyPossessionFeeSoa: StatementAdjustment =
        this.context.matter.firstInterimEarlyPossessionFeeSoa;

      if (this.context.matter && this.context.project) {
        this.soAdjPurchasePriceDeferredPortion.updateDeferredPortionOfPurchase(
          this.context.project.isCondo,
          this.context.project.isPOTL,
          this.context.project.projectCondo && this.context.project.projectCondo.occupancyFeesCalculatedBasedOn,
          this.context.project.isOccupancyDateRequired,
          this.context.matter.extraDepositConfig && this.context.matter.extraDepositConfig.deferredPurchaseMoniesAmount,
          firstInterimEarlyPossessionFeeSoa &&
            firstInterimEarlyPossessionFeeSoa.soAdjInterimEarlyPossessionFee &&
            firstInterimEarlyPossessionFeeSoa.soAdjInterimEarlyPossessionFee.mortgageInterest
        );
      }
    } else {
      this.soAdjPurchasePriceDeferredPortion = new SoAdjPurchasePriceDeferredPortion(
        this.context.soAdjPurchasePriceDeferredPortion
      );
    }
  }

  getSoAdjPurchasePriceDeferredPortionTotal() {
    return SharedUtils.formattedCurrencyValue(
      this.soAdjPurchasePriceDeferredPortion && this.soAdjPurchasePriceDeferredPortion.amount
    );
  }

  ok(): void {
    this.dialog.close(this.soAdjPurchasePriceDeferredPortion);
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }
}
