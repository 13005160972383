<form class="form-horizontal" *ngIf="localAdj" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Vendor Take Back Mortgage</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Priority of mortgage to be assumed</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="mortgagePriority"
                            name="mortgagePriority"
                            [(ngModel)]="context.mortgagePriority"
                            class="form-control"
                            [fieldKey]="'matter.soadj.vtb.mortgage.heading.empty'"
                            dp-error-validator
                            [disabled]="true"

                    >
                        <option *ngFor="let mortgagePriority of mortgagePriorityOptions" [value]="mortgagePriority.value">
                            {{mortgagePriority.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" >
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label" >
                    <label class="control-label">Principal amount of First mortgage back</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="principalAmount"
                           name="principalAmount"
                           [(ngModel)]="context.principalAmount"
                           class="form-control"
                           dp-currency
                           [disabled]="true"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Adjustment Heading *</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="heading"
                           name="heading"
                           [(ngModel)]="localAdj.heading"
                           class="form-control"
                           maxlength="38"
                    />
                </div>
            </div>
        </div>

        <div style="border-top: 1px solid #cccccc;">

            <div class="col-lg-12 form-group">
                <div class="col-lg-5">
                    <label class="control-label text-left" style="font-weight : bold">{{adjustmentDescription}}</label>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-6">
                        <input type="text"
                               id="firstInput"
                               name="firstInput"
                               [(ngModel)]="localAdj.firstInput"
                               class="form-control"
                               maxlength="37"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-6">
                        <input type="text"
                               id="secondInput"
                               name="secondInput"
                               [(ngModel)]="localAdj.secondInput"
                               class="form-control"
                               maxlength="37"
                        />
                    </div>
                </div>
            </div>

            <div *ngFor="let line of details">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                    <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                </div>
            </div>

            <div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentDisplayUtil.purchaserLabel}}</label>
                    <label class="control-label col-lg-4">{{ context.principalAmount | currency:CAD:'symbol'}}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" disabled>
                    <span>Delete</span>
                </button>
            </div>
            <div class="col-lg-2" *dpShowByProvince="'ps.matter.soa.modal.adj.VTBMortgage.infoOnly'">
                <dp-checkbox fieldId="checkBoxInfoOnlyForProjectSale">
                    <input type="checkbox"
                           id="checkBoxInfoOnlyForProjectSale"
                           name="checkBoxInfoOnlyForProjectSale"
                           [(ngModel)]="localAdj.infoOnly"
                           [checked]="localAdj.infoOnly"/>
                </dp-checkbox>
                <label class="control-label vertical-align-label display-inline" >Info Only</label>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
