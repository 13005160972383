import {ErrorTab} from '../error-handling/error-tab';
import {MissingFieldTab} from '../missing-fields/missing-field-tab';
import {DpFooterNotification, DpFooterTypes} from '../error-handling/dp-footer-notification';
import {DPNotificationTab} from '../error-handling/dp-notification-tab';
import {AccountProvince} from '../../admin/accounts/shared/account-province';
import {Account} from '../../admin/accounts/shared/account';
import {FieldMetaData} from '../../matters/shared/field-code-mapping';
import {Matter} from '../../matters/shared';
import {SESSION_STORAGE_KEYS} from '../index';
import {PROVINCE_CODES} from '../../matters/shared/user-province';

export class Tab {

  constructor(json?: Tab) {
    if (json) {
      for (let prop in json) {
        if (json.hasOwnProperty(prop)) {
          this[ prop ] = json[ prop ];
        }
      }
      this.dpFooterNotifications = [];

      if (json.dpFooterNotifications) {
        for (let fn of json.dpFooterNotifications) {
          this.dpFooterNotifications.push(new DpFooterNotification(fn));
        }
      }

    }

    // this.equals = (other: Tab) => this.id === other.id && this.type === other.type;
  }

  equals(other: Tab): boolean {
    return this.id === other.id && this.type === other.type;
  }

  _id: number;
  type: string;
  _title: string;
  _lineNumber: string;
  tabType: TabType;
  tabSubType: TabType;
  tabDisplayLabel: string;
  active: string;
  route: string;
  section: string;
  provinceCode: string;
  selectedProvince: AccountProvince;
  account: Account;
  //errorTab: ErrorTab;
  //missingFieldTab :MissingFieldTab;
  dpFooterNotifications: DpFooterNotification[] = [];
  projectSoAdjFieldCodes: number[] = [];
  matterSoAdjFieldCodes: number[] = [];
  lastFocusedElementMetaData: FieldMetaData;
  focusedOnLastVisitedElement: boolean = false;
  redirectParams: any;

  // equals(other: Tab): boolean {
  //     return this.id === other.id && this.type === other.type;
  // }

  get routeParams(): string[] {
    // return this.type != "contact" ? [this.route, '' + this.id, this.type] : [this.route, '' + this.id];
    if ((this.id || this.id == 0) && this.tabType != 'admin' && this.tabType != 'billing' && this.tabType != 'integrations' && this.tabType != 'docServices' && this.tabType != 'config' && this.tabType != 'lenders' && this.tabType != 'wizard') {
      if (this.section && this.section.indexOf('/') > -1) {
        return [ this.route, this.id.toString(), this.type ].concat(this.section.split('/'));
      } else if (this.section) {

        return [ this.route, this.id.toString(), this.type, this.section ];
      } else {
        return [ this.route, this.id.toString(), this.type ];
      }
    } else if (this.section) {
      return [ this.route, this.section ];
    } else {
      return [ this.route ];
    }
  }

  setActive(active: boolean): void {
    this.active = active ? 'active' : 'inActive';
  }

  isActive(): boolean {
    return this.active === 'active';
  }

  isLocked(): boolean {
    return false;
  }

  isDirty(): boolean {
    return false;
  }

  isMatter(): boolean {
    return (this.tabType == 'matter');
  }

  isWizard(): boolean {
    return (this.tabType == 'wizard');
  }

  isProject(): boolean {
    return (this.tabType == 'project');
  }

  isShareDocuments(): boolean {
    return (this.tabType == 'shareDocuments');
  }

  isExportFileManager(): boolean {
    return (this.tabType == 'exportFileManager');
  }

  isDayAtGlance(): boolean {
    return (this.tabType == 'dayAtGlance');
  }

  isMassUpdateSubType(): boolean {
    return (this.tabSubType == 'massUpdate');
  }

  isContact(): boolean {
    return (this.tabType == 'contact');
  }

  isBilling(): boolean {
    return (this.tabType == 'billing');
  }

  isAccountAdmin(): boolean {
    return (this.tabType == 'accountadmin');
  }

  isEvents(): boolean {
    return (this.tabType == 'event');
  }

  isMessages(): boolean {
    return (this.tabType == 'messages');
  }

  isAdjudication(): boolean {
    return (this.tabType == 'adjudication');
  }

  isAdmin(): boolean {
    return (this.tabType == 'admin');
  }

  isIntegrations(): boolean {
    return (this.tabType == 'integrations');
  }

  isMortgageInstrucing(): boolean {
    return (this.tabType == 'Mortgage Instructing');
  }

  isDocServices(): boolean {
    return (this.tabType == 'docServices');
  }

  isDepositManager(): boolean {
    return (this.tabType == 'depositManager');
  }

  isAnchorTab(): boolean {
    return (this.tabSubType == 'anchor');
  }

  isConfig(): boolean {
    return (this.tabType == 'config');
  }

  isOpportunities(): boolean {
    return (this.tabType === 'opportunities');
  }

  isOpportunityMatter(): boolean {
    return (this.tabType === 'opportunityMatter');
  }

  deCoupleUIComponent(): void {
    // console.log('clean matters from matterListState');
    // MatterList anchor has a list of matters that need to be cleared for circular references
    if (this[ 'matterListState' ] && this[ 'matterListState' ].rows) {
      this[ 'matterListState' ].rows
      .filter(Boolean)
      .filter(m => m instanceof Matter)
      .forEach(m => m.clearCircularReferencesBeforeStringify());
    }
  }

  get tabTypeForDisplay(): string {
    if (this.tabType == 'depositManager' || this.tabType == 'exportFileManager' || this.tabType == 'opportunityMatter' || this.tabType == 'dayAtGlance' || this.tabType == 'event') {
      return this.tabDisplayLabel;
    }
    if (sessionStorage.getItem(SESSION_STORAGE_KEYS.accountDefaultProvinceCode) == PROVINCE_CODES.NOVA_SCOTIA && this.tabType == 'jurisdiction') {
      return 'community';
    } else {
      return this.tabType;
    }
  }

  get title(): string {
    return this._title;
  }

  set title(titleStr: string) {
    this._title = titleStr;
  }

  get id(): number {
    return this._id;
  }

  set id(idNum: number) {
    this._id = idNum;
  }

  get lineNumber(): string {
    return this._lineNumber;
  }

  set lineNumber(lineNumberStr: string) {
    this._lineNumber = lineNumberStr;
  }

  static createMatterAnchorTab(redirect?: string): Tab {
    let matterAnchorTab: Tab = new Tab();
    matterAnchorTab.id = undefined;
    matterAnchorTab.type = 'Matters';
    matterAnchorTab.tabSubType = 'anchor';
    matterAnchorTab.tabType = 'matter';
    matterAnchorTab.title = undefined;
    matterAnchorTab.lineNumber = 'undefined';
    matterAnchorTab.route = '/main/tabs/matters';
    matterAnchorTab.active = 'active';
    matterAnchorTab.redirectParams = redirect;
    return matterAnchorTab;
  }

  static createProjectAnchorTab(): Tab {
    let tab: Tab = new Tab();
    tab.type = 'Projects';
    tab.tabSubType = 'anchor';
    tab.tabType = 'project';
    tab.route = 'main/projects/list';
    return tab;
  }

  static createExportFileManagerAnchorTab(): Tab {
    let tab: Tab = new Tab();
    tab.type = 'Export File Manager';
    tab.tabSubType = 'anchor';
    tab.tabType = 'exportFileManager';
    tab.tabDisplayLabel = 'Export (XML) File Manager';
    tab.route = '/main/export-file-manager/list';
    return tab;
  }

  static createDayAtGlanceAnchorTab(): Tab {
    let tab: Tab = new Tab();
    tab.type = 'Day At A Glance';
    tab.tabSubType = 'anchor';
    tab.tabType = 'dayAtGlance';
    tab.tabDisplayLabel = 'Day At A Glance';
    tab.route = '/main/events/dayAtGlance';
    return tab;
  }

  static createContactAnchorTab(): Tab {
    let tab: Tab = new Tab();
    tab.type = 'Contacts';
    tab.tabSubType = 'anchor';
    tab.tabType = 'contact';
    tab.route = 'main/contacts/list';
    return tab;
  }

  static createIntegrationsTab(): Tab {
    let tab: Tab = new Tab();
    tab.tabSubType = 'anchor';
    tab.type = 'integrations';
    tab.tabType = 'integrations';
    tab.route = 'main/integrations/home';
    return tab;
  }

  static createMortgageInstructionTab(): Tab {
    let tab: Tab = new Tab();
    tab.tabSubType = 'anchor';
    tab.type = 'Mortgage Instructing';
    tab.tabType = 'Mortgage Instructing';
    tab.route = 'main/integrations/mortgage-instructions';
    return tab;
  }

  static createMessagesTab(): Tab {

    let tab: Tab = new Tab();
    tab.tabSubType = 'anchor';
    tab.type = 'messages';
    tab.tabType = 'messages';
    tab.route = 'main/messages/list';
    return tab;
  }

  static createDocServicesTab(): Tab {
    let tab: Tab = new Tab();
    tab.tabSubType = 'anchor';
    tab.type = 'Document Services Administration';
    tab.tabType = 'docServices';
    tab.route = 'main/doc-services/home';
    return tab;
  }

  static createDepositManagerTab(): Tab {
    let tab: Tab = new Tab();
    tab.tabSubType = 'anchor';
    tab.type = 'Deposit Manager';
    tab.tabType = 'depositManager';
    tab.tabDisplayLabel = 'Deposit Manager';
    tab.route = 'main/deposit-manager/list';
    return tab;
  }

  static createOpportunitiesAnchorTab(): Tab {
    let tab: Tab = new Tab();
    tab.type = 'Opportunities';
    tab.tabSubType = 'anchor';
    tab.tabType = 'opportunities';
    tab.tabDisplayLabel = 'Opportunities Manager';
    tab.route = '/main/opportunities/list';
    return tab;
  }

  static createOpportunityMatterAnchorTab(): Tab {
    let tab: Tab = new Tab();
    tab.type = 'Opportunity Matter';
    tab.tabSubType = 'anchor';
    tab.tabType = 'opportunityMatter';
    tab.tabDisplayLabel = 'Opportunities';
    tab.route = '/main/opportunity-matter/list';
    return tab;
  }

  // openDocServicesMainTab(route : string) : void {
  //     this.tabsService.removeAnchorTab();
  //     let tab : DocServicesTab = new DocServicesTab();
  //     tab.tabSubType = 'anchor';
  //     tab.type = "Document Services Administration";
  //     tab.tabType = 'docServices';
  //     tab.route = 'main/doc-services/'+route;
  //     this.tabsService.openTab(tab);
  // }

  // Notification Tab Creation , if you want to add new add in footer notification area , please add in below method.
  createDPNotificationFooter(): void {
    if (this.dpFooterNotifications) {
      // Error Tab
      this.dpFooterNotifications.push(this.createDPErrorNotification());
      // Ereg Tab
      this.dpFooterNotifications.push(this.createDPMissingFieldNotification(DpFooterTypes.EREG));
      // ALTOEFORMS
      this.dpFooterNotifications.push(this.createDPMissingFieldNotification(DpFooterTypes.ALTOEFORMS));
      // Notification Tab
      this.dpFooterNotifications.push(this.createDPThirdPartyNotification());
      // Third Party Integration Tab
      this.dpFooterNotifications.push(this.createDPMissingFieldNotification(DpFooterTypes.THIRDPARTY));

    }
  }

  createDPErrorNotification(): DpFooterNotification {
    let dPNotificationErrorFooter = new DpFooterNotification();
    dPNotificationErrorFooter.notificationHeader = 'Error';
    dPNotificationErrorFooter.errorTab = new ErrorTab();
    dPNotificationErrorFooter.dpFooterType = DpFooterTypes.ERROR;
    dPNotificationErrorFooter.isActive = false;
    return dPNotificationErrorFooter;
  }

  createDPMissingFieldNotification(missingFieldType: string): DpFooterNotification {

    let dPNotificationMissingFieldFooter = new DpFooterNotification();
    dPNotificationMissingFieldFooter.notificationHeader = 'Missing Fields';
    dPNotificationMissingFieldFooter.missingFieldTab = new MissingFieldTab();
    dPNotificationMissingFieldFooter.dpFooterType = missingFieldType;
    dPNotificationMissingFieldFooter.isActive = false;
    return dPNotificationMissingFieldFooter;
  }

  createDPThirdPartyNotification(): DpFooterNotification {

    let dPNotificationMissingFieldFooter = new DpFooterNotification();
    dPNotificationMissingFieldFooter.notificationHeader = 'Integrations - Notifications';
    dPNotificationMissingFieldFooter.dpThirdPartyNotificationTab = new DPNotificationTab();
    dPNotificationMissingFieldFooter.dpFooterType = DpFooterTypes.THIRDPARTYNOTIFICATIONS;
    dPNotificationMissingFieldFooter.isActive = false;
    return dPNotificationMissingFieldFooter;
  }

}

export type TabType =
  'matter'
  | 'project'
  | 'exportFileManager'
  | 'contact'
  | 'admin'
  | 'accountadmin'
  | 'jurisdiction'
  | 'billing'
  | 'docServices'
  | 'adjudication'
  | 'event'
  | 'integrations'
  | 'anchor'
  | 'messages'
  | 'config'
  | 'lenders'
  | 'massUpdate'
  | 'depositManager'
  | 'shareDocuments'
  | 'opportunities'
  | 'opportunityMatter'
  | 'dayAtGlance'
  | 'wizard'
  | 'Mortgage Instructing';

