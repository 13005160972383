import {Injectable} from '@angular/core';
import {IGNORE_FIELDS} from './constants';
import * as deepDiffModule from 'deep-diff';

@Injectable()
export class DpDirtyCheckService {

  constructor() {
  }

  checkDirty(referenceObject: any, actualObject: any): boolean {
    let differenceFound: any[] = [];
    let ignoreFields = [];
    IGNORE_FIELDS.forEach(item => {
      ignoreFields.push(...(item || []));
    });

    if (!referenceObject && !actualObject) {
      return false;
    } else if ((!referenceObject && actualObject)
      || (referenceObject && !actualObject)
      || (referenceObject.length != actualObject.length)) {
      return true;
    } else {
      let deepDiffHandler = deepDiffModule.diff;
      let differenceList = deepDiffHandler(referenceObject, actualObject,
        (path, key) =>
          ~ignoreFields.indexOf(key)
      );
      if (differenceList) {
        differenceFound = differenceList.filter(item => this.filter(item));
        //Commenting below code as it's just logging the differences
        if (differenceFound) {
          differenceFound.forEach(item => {
              console.log('Operation : ' + item.kind + ' Back End Value : ' + item.lhs + ' Front End Value : ' + item.rhs + ' Path : ' + (item.path ? item.path.join('/') : ''));
            }
          );
        }

      }
      return (differenceFound && differenceFound.length > 0);
    }

  }

  getObjectDifference(referenceObject: any, actualObject: any): any {
    let differenceFound: any[] = [];
    let ignoreFields = [];
    IGNORE_FIELDS.forEach(item => {
      ignoreFields.push(...(item || []));
    });

    if (!referenceObject && !actualObject) {
      return [];
    } else if ((!referenceObject && actualObject)
      || (referenceObject && !actualObject)
      || (referenceObject.length != actualObject.length)) {
      return [];
    } else {
      let deepDiffHandler = deepDiffModule.diff;
      let differenceList = deepDiffHandler(referenceObject, actualObject,
        (path, key) =>
          ~ignoreFields.indexOf(key)
      );
      if (differenceList) {
        differenceFound = differenceList.filter(item => (item.lhs != undefined || item.kind == 'A') && item.path.indexOf('burgerMenuNodeItems') < 0 && item.path.indexOf('lastUpdatedTimeStamp') < 0);
        //Commenting below code as it's just logging the differences
        if (differenceFound) {
          differenceFound.forEach(item => {
              console.log('Operation : ' + item.kind + ' Back End Value : ' + item.lhs + ' Front End Value : ' + item.rhs + ' Path : ' + (item.path ? item.path.join('/') : ''));
            }
          );
        }

      }
      return differenceFound;
    }
  }

  filter(item: any): boolean {
    return !(((item.lhs == null || item.lhs == '' || item.lhs == undefined || !item.lhs) && (item.rhs == null || item.rhs == '' || item.rhs == undefined || !item.rhs)
        && item.kind != 'A')
      || (typeof item.lhs === 'function' || typeof item.rhs === 'function'));
  }
}
