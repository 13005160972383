import {Component, Inject} from '@angular/core';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {Subscription} from 'rxjs/Subscription';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {TeranetParcelMapDetail} from '../../../../shared-main/teranet/teranet-parcel-map';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {SearchAdjacentParcelComponent} from '../search-adjacent-parcel/search-adjacent-parcel.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class ViewMapContext {
  matterId: number;
  parcelId: number;
  mapId: number;
  pin: string;
  withRoads: boolean;
  creationDate: string;
  adjacentParcels: ParcelRegister[];
}

@Component({
  selector: 'dp-view-map-modal',
  templateUrl: './view-map.modal.component.html',
  styleUrls: ['./view-map.scss']
})
@AutoUnsubscribe()
export class ViewMapModalComponent extends ModalComponent<ViewMapContext> implements ModalComponent<ViewMapContext> {
  modalCaption: string = 'Map for PIN';
  imageUrl: string;
  mapDetails: TeranetParcelMapDetail;
  legend1Color = '#cba500';
  legend1Text = 'SubjectProperty';
  legend2Color = '#fee4cb';
  legend2Text = 'AdjacentProperty';
  percentage: number = 100;
  scaleMetersOriginal: number = 0;

  getMapSubcription: Subscription;

  constructor(
    public dialog: MatDialogRef<ViewMapModalComponent>,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ViewMapContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.modalCaption =
      'Map (with' + (this.context.withRoads ? '' : 'out') + ` roads and railways) for PIN ${this.context.pin}`;

    this.imageUrl = this.teranetService.getMapUrl(this.context.parcelId, this.context.mapId);
    this.getMapSubcription = this.teranetService
      .getMapDetails(this.context.parcelId, this.context.mapId)
      .subscribe((rslt) => {
        if (rslt) {
          this.mapDetails = rslt;
          if (this.mapDetails && this.mapDetails.legends.length > 1) {
            this.legend1Color = `rgb(${this.mapDetails.legends[0].red}, ${this.mapDetails.legends[0].green}, ${this.mapDetails.legends[0].blue} )`;
            this.legend1Text = this.mapDetails.legends[0].layer;
            this.legend2Color = `rgb(${this.mapDetails.legends[1].red}, ${this.mapDetails.legends[1].green}, ${this.mapDetails.legends[1].blue} )`;
            this.legend2Text = this.mapDetails.legends[1].layer;
            this.scaleMetersOriginal = +this.mapDetails.scaleDistance.replace('m', '').trim();
            this.mapDetails.disclaimer = this.mapDetails.disclaimer.replace(/\n/g, '.  ');
            //let scale = +this.mapDetails.scalePixels;
            //this.percentage = scale;
            this.percentage = 64; //fits the whole image on screen
          }
        }
      });

    jQuery('dp-view-map-modal .mapImage .draggableDiv').draggable();
    jQuery('#printBtn').focus();
  }

  getStyle(legendId: number) {
    let backgroundColor = this.legend1Color;
    if (this.mapDetails && this.mapDetails.legends && this.mapDetails.legends.length > 1) {
      backgroundColor = `rgb(${this.mapDetails.legends[legendId].red}, ${this.mapDetails.legends[legendId].green}, ${this.mapDetails.legends[legendId].blue} )`;
    } else {
      if (legendId > 0) {
        backgroundColor = this.legend2Color;
      }
    }
    return {
      border: '1px solid black',
      width: '25px',
      height: '15px',
      'margin-left': '7px',
      'background-color': backgroundColor
    };
  }

  imgStyle() {
    return {
      height: `${this.percentage}%`,
      width: `${this.percentage}%`,
      display: 'block',
      margin: 'auto'
    };
  }

  get scaleMeters(): number {
    return (this.scaleMetersOriginal * this.percentage) / 100;
  }

  zoomIn() {
    console.log(this.percentage);
    this.percentage++;
  }

  zoomOut() {
    this.percentage--;
  }

  printMap() {
    if (this.percentage > 100) {
      this.percentage = 100;
    }
    let printableArea = document.getElementById('printableArea').innerHTML;
    printableArea = printableArea.replace(/<button.+?<\/button>/g, ''); //no buttons on printout
    console.log(printableArea);
    let printableWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    printableWindow.document.open();
    printableWindow.document.write(`
                                  <html>
                                    <head><title>Print map</title></head>
                                        <body onload="window.print();window.close()">${printableArea}</body>
                                  </html>`);
    printableWindow.document.close();
  }

  close() {
    this.dialog.close();
  }

  viewAdjacentParcels() {
    this.dialogService.matDialogContent({
      modalGrid: 5,
      content: SearchAdjacentParcelComponent,
      context: {
        adjacentParcels: this.context.adjacentParcels,
        withRoads: this.context.withRoads
      },
      onFulfillment: (result: any) => {
        if (result && result.action == 'SEARCH_PARCELS' && result.selectedParcels) {
          this.dialog.close({
            action: 'SEARCH_PARCELS',
            selectedParcels: result.selectedParcels
          });
        }
      }
    });
  }

  ngOnDestroy() {}
}
