import {MortgageInstructionDecorator} from './mortgage-instruction-decorator';
import {MortgageInstructionFileStatusCode, StewartMortgageInstruction} from '../../shared-main/telus/stewart-mortgage-instruction';
import {
  FileStatus,
  StewartAssystDocument,
  StewartAssystMortgageInstructionData
} from '../../shared-main/telus/stewart-assyst-mortgage-instruction-data';

const EXPIRE_TIME_PATTERN = '$1 hr $2 min';

export class TelusMortgageInstructionDecorator implements MortgageInstructionDecorator {
  formattedExpireTiming(mortgageInstruction: StewartMortgageInstruction): string {
    if (mortgageInstruction.expiresIn) {
      if (mortgageInstruction.expiresIn == '000:00:00') {
        return 'N/A';
      } else {
        let timeUnits = mortgageInstruction.expiresIn.split(':');
        if (timeUnits.length > 1) {
          return EXPIRE_TIME_PATTERN.replace('$1', timeUnits[ 0 ]).replace('$2', timeUnits[ 1 ]);
        }
      }
    }

    return '';
  }

  hasOnlyNewFileStatus(mortgageInstruction: StewartMortgageInstruction): boolean {
    let otherStatuses: FileStatus[] = [];
    if (mortgageInstruction.mortgageInstructionData) {
      otherStatuses = mortgageInstruction.mortgageInstructionData.fileStatuses.filter(value => value.status != MortgageInstructionFileStatusCode.NEW_FILE_RECEIVED);
    }
    return otherStatuses.length == 0;
  }

  acceptedOnDate(mortgageInstructionData: StewartAssystMortgageInstructionData): string {
    if (mortgageInstructionData.fileStatuses) {
      let fileAcceptedStatus: FileStatus = mortgageInstructionData.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_ACCEPTED);
      if (fileAcceptedStatus) {
        return fileAcceptedStatus.dateExecuted;
      }
    }
    return null;
  }

  isMortgageInstructionCancelled(mortgageInstructionData: StewartAssystMortgageInstructionData): boolean {
    return !!(mortgageInstructionData && mortgageInstructionData.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_CANCELLED_BY_FI
      || value.status == MortgageInstructionFileStatusCode.FILE_CANCELLED_BY_TELUS));
  }

  getPrelim(mortgageInstructionData: StewartAssystMortgageInstructionData): string {
    let documents = mortgageInstructionData ? mortgageInstructionData.documents : [];
    let fileStatuses = mortgageInstructionData ? mortgageInstructionData.fileStatuses : [];
    if (documents.find(document => document.docType === 'RAP')) {
      return fileStatuses.find(value => value.status === MortgageInstructionFileStatusCode.FILE_PRELIMINARY_TRANSMITTED) ? 'Yes' : 'No';
    } else {
      return 'N/A';
    }
  }

  getFinal(mortgageInstructionData: StewartAssystMortgageInstructionData): string {
    let documents = mortgageInstructionData ? mortgageInstructionData.documents : [];
    let fileStatuses = mortgageInstructionData ? mortgageInstructionData.fileStatuses : [];
    if (documents.find(document => document.docType === 'RAF')) {
      return fileStatuses.find(value => value.status === MortgageInstructionFileStatusCode.FILE_FINAL_TRANSMITTED) ? 'Yes' : 'No';
    } else {
      return 'N/A';
    }
  }

  areDocumentsAvailableToDownload(mortgageInstruction: StewartMortgageInstruction): boolean {
    if (mortgageInstruction.isDealAccepted &&
      ((!mortgageInstruction.isPreliminaryReportApplicable && !mortgageInstruction.isFinalReportApplicable)
        || (!mortgageInstruction.isPreliminaryReportApplicable && mortgageInstruction.isFinalReportApplicable && mortgageInstruction.isFinalReportGenerated && mortgageInstruction.isFinalReportTransmitted)
        || (!mortgageInstruction.isFinalReportApplicable && mortgageInstruction.isPreliminaryReportApplicable && mortgageInstruction.isPreliminaryReportGenerated && mortgageInstruction.isPreliminaryReportTransmitted)
        || (mortgageInstruction.isPreliminaryReportApplicable && mortgageInstruction.isFinalReportApplicable && mortgageInstruction.isPreliminaryReportGenerated
          && mortgageInstruction.isFinalReportGenerated && mortgageInstruction.isPreliminaryReportTransmitted && mortgageInstruction.isFinalReportTransmitted))
    ) {
      return true;
    } else {
      return false;
    }
  }

  downloadableDocuments(mortgageInstructionData: StewartAssystMortgageInstructionData): StewartAssystDocument[] {
    return mortgageInstructionData.documents.filter(doc => doc.docType != 'UPL' && doc.docType != 'IMP' && doc.docType != 'MAN' && (doc.documentStatus == 'NEW' || doc.documentStatus == 'UPDATED'));
  }

}
