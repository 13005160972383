import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalResult} from '../../../../shared-main/enums';
import {Project} from '../../../../projects/shared/project';
import {Matter} from '../../../shared';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {FinalUseAmountFromInterimType} from '../../model/so-adj-final-fee';
import {AdjustmentBaseModalComponent} from '../adjustment-base.modal.component';
import Utils from '../../../../shared-main/utils';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {SoAdjTotalOccupancyFee, TotalOccupancyFeeTotalType} from '../../model/so-adj-total-occupancy-fee';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {OccupancyFeesCalculatedBasedOn} from '../../../../projects/project-condo/project-condo';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class TotalOccupancyFeeContext {
  closingDate: string;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  existingTotalFee: SoAdjTotalOccupancyFee;
  project: Project;
  matter: Matter;
  hideDeleteFromModal: boolean;
  soaConsiderationTaxes: ConsiderationTaxes;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
}

@Component({
  selector: 'dp-total-occupancy-fee-modal-content',
  templateUrl: 'total-occupancy-fee.modal.component.html',
  styleUrls: ['./total-occupancy-fee.modal.component.scss'],
  providers: [ErrorService, DialogService]
})
export class TotalOccupancyFeeModalComponent
  extends AdjustmentBaseModalComponent<SoAdjTotalOccupancyFee, TotalOccupancyFeeContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  context: TotalOccupancyFeeContext;
  localAdjustment: SoAdjTotalOccupancyFee;
  closingDateLabel: string = '';
  totalTypes: SelectItem[] = [];
  creditToTypes: SelectItem[] = [];
  purchaserHasPaidFeeTypes: SelectItem[] = [];
  useAmountFromLabel: string;
  amountOfMonthlyLabel: string;
  purchaserHasPaidFeeLabel: string;

  paidFromLabel: string;
  isPaidFromVisible: boolean = false;
  isPaidFromDisabled: boolean = true;

  paidToLabel: string = ' to ';
  isPaidToVisible: boolean = false;
  isPaidToDisabled: boolean = true;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TotalOccupancyFeeModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TotalOccupancyFeeContext
  ) {
    super(dialog, dialogService, context);
    this.statementAdjustmentDisplayUtil = this.context.statementAdjustmentDisplayUtil;
  }

  ok(): void {
    this.dialog.close(this.localAdjustment);
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res: boolean) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  ngOnInit(): void {
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');

    this.buildTypes();

    if (!this.context.existingTotalFee) {
      this.localAdjustment = new SoAdjTotalOccupancyFee();
      this.newAdj = true;
    } else {
      this.localAdjustment = new SoAdjTotalOccupancyFee(this.context.existingTotalFee);
    }

    if (!this.localAdjustment.taxType) {
      this.localAdjustment.taxType = this.context.soaConsiderationTaxes.rateType;
    }
    if (!this.localAdjustment.hstRate) {
      this.localAdjustment.hstRate = this.context.matter.soaHst;
    }
    if (!this.localAdjustment.gstRate) {
      this.localAdjustment.gstRate = this.context.matter.soaFederalHst;
    }

    if (this.context) {
      this.localAdjustment.updateFromMatterAndProject(this.context.matter, this.context.project);
    }

    this.onTotalTypeChange();
    this.onCreditToChange();
    this.onPurchaserHasPaidFeeChange();
  }

  get isProjectOccFeeBasedOnPhantomMortgage(): boolean {
    return (
      this.context &&
      this.context.project &&
      this.context.project.projectCondo &&
      this.context.project.projectCondo.occupancyFeesCalculatedBasedOn ===
        OccupancyFeesCalculatedBasedOn.phantomMortgage
    );
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  buildTypes(): void {
    this.totalTypes = [
      {label: 'Occupancy Fees', value: <TotalOccupancyFeeTotalType>'OCCUPANCY_FEES'},
      {
        label:
          (this.isProjectOccFeeBasedOnPhantomMortgage && !this.context.matter.isMatterProvinceAB ? 'Mortgage ' : '') +
          'Interest',
        value: <TotalOccupancyFeeTotalType>'INTEREST'
      },
      {
        label: this.context.matter.isMatterProvinceAB
          ? 'Condominium Fees '
          : this.context.matter.isMatterProvinceBC
            ? 'Strata Fees'
            : 'Common Expenses',
        value: <TotalOccupancyFeeTotalType>(
          (this.context.matter.isMatterProvinceAB
            ? 'CONDOMINIUM_FEES'
            : this.context.matter.isMatterProvinceBC
              ? 'STRATA_FEES'
              : 'COMMON_EXPENSES')
        )
      },
      {label: 'Taxes', value: <TotalOccupancyFeeTotalType>'TAXES'}
    ];

    this.creditToTypes = [
      {label: this.purchaserLabel, value: 'PURCHASER'},
      {label: this.vendorLabel, value: 'VENDOR'}
    ];

    this.purchaserHasPaidFeeTypes = [
      {label: 'and including month of closing', value: <FinalUseAmountFromInterimType>'AND_INCLUDING_MONTH_OF_CLOSING'},
      {label: 'end of month preceding closing', value: <FinalUseAmountFromInterimType>'END_OF_MONTH_PRECEDING_CLOSING'},
      {label: 'other - enter own date', value: <FinalUseAmountFromInterimType>'OTHER_ENTER_OWN_DATE'},
      {
        label: 'use date from Final Occupancy Fees adjustment',
        value: <FinalUseAmountFromInterimType>'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT'
      },
      {
        label: 'other - enter own start and end dates',
        value: <FinalUseAmountFromInterimType>'OTHER_ENTER_OWN_START_AND_END_DATES'
      },
      {
        label: 'other - enter own start date to and including month of closing',
        value: <FinalUseAmountFromInterimType>'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING'
      },
      {
        label: 'other - enter own start date to end of month preceding closing',
        value: <FinalUseAmountFromInterimType>'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING'
      },
      {
        label: 'other - enter own start date to date from Final Occupancy Fees adjustment',
        value: <FinalUseAmountFromInterimType>'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT'
      }
    ];
  }

  onTotalTypeChange(): void {
    let adjustmentLabelSuffix: string = !this.isNonCondoPotlON()
      ? 'interim occupancy adjustment?'
      : 'interim early possession fee adjustment?';
    let amountLabelSuffix: string = !this.isNonCondoPotlON() ? 'occupancy fees' : 'early possession fees';
    switch (this.localAdjustment.totalType) {
      case 'OCCUPANCY_FEES':
        this.useAmountFromLabel = 'Use amount from ' + adjustmentLabelSuffix;
        this.amountOfMonthlyLabel = 'Amount of monthly ' + amountLabelSuffix;
        break;
      case 'INTEREST':
        this.useAmountFromLabel = `Use amount from ${
          this.isProjectOccFeeBasedOnPhantomMortgage && !this.context.matter.isMatterProvinceAB ? 'mortgage ' : ''
        }interest component of ${adjustmentLabelSuffix}`;
        this.amountOfMonthlyLabel = `Amount of monthly ${
          this.isProjectOccFeeBasedOnPhantomMortgage && !this.context.matter.isMatterProvinceAB ? 'mortgage ' : ''
        }interest component of ${amountLabelSuffix}`;
        break;

      case 'COMMON_EXPENSES':
        this.useAmountFromLabel = 'Use amount from common expenses component of  ' + adjustmentLabelSuffix;
        this.amountOfMonthlyLabel = 'Amount of monthly common expenses component of ' + amountLabelSuffix;
        break;
      case 'CONDOMINIUM_FEES':
        this.useAmountFromLabel = 'Use amount from condominium Fees component of  ' + adjustmentLabelSuffix;
        this.amountOfMonthlyLabel = 'Amount of monthly condominium Fees component of ' + amountLabelSuffix;
        break;
      case 'STRATA_FEES':
        this.useAmountFromLabel = 'Use amount from strata fees component of  ' + adjustmentLabelSuffix;
        this.amountOfMonthlyLabel = 'Amount of monthly strata fees component of ' + amountLabelSuffix;
        break;
      case 'TAXES':
        this.useAmountFromLabel = 'Use amount from taxes component of  ' + adjustmentLabelSuffix;
        this.amountOfMonthlyLabel = 'Amount of monthly taxes component of ' + amountLabelSuffix;
        break;
    }
  }

  onPurchaserHasPaidFeeChange(): void {
    let creditToLabel: string = this.localAdjustment.creditTo === 'PURCHASER' ? this.purchaserLabel : this.vendorLabel;
    this.localAdjustment.paidTo = this.localAdjustment.getEndDate();
    switch (this.localAdjustment.purchaserHasPaidFee) {
      case 'AND_INCLUDING_MONTH_OF_CLOSING':
      case 'END_OF_MONTH_PRECEDING_CLOSING':
        this.isPaidToVisible = false;
        this.isPaidFromVisible = false;
        this.paidFromLabel = '';
        break;
      case 'OTHER_ENTER_OWN_DATE':
        this.isPaidFromVisible = true;
        this.isPaidFromDisabled = false;
        this.isPaidToVisible = false;
        this.paidFromLabel = `Date to which ${creditToLabel} has paid occupancy fees`;
        break;
      case 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
        this.isPaidFromVisible = false;
        this.isPaidToDisabled = true;
        this.isPaidToVisible = true;
        this.paidFromLabel = `Date to which ${creditToLabel} has paid occupancy fees`;
        this.localAdjustment.updatePurchasePaidDates(this.context.matter);
        break;
      case 'OTHER_ENTER_OWN_START_AND_END_DATES':
        this.isPaidFromVisible = true;
        this.isPaidToDisabled = true;
        this.isPaidToVisible = true;
        this.isPaidToDisabled = false;
        this.paidFromLabel = `${creditToLabel} has paid occupancy fees from`;
        break;
      case 'OTHER_ENTER_OWN_START_DATE_TO_AND_INCLUDING_MONTH_OF_CLOSING':
      case 'OTHER_ENTER_OWN_START_DATE_TO_END_OF_MONTH_PRECEDING_CLOSING':
      case 'OTHER_ENTER_OWN_START_DATE_TO_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT':
        this.isPaidFromVisible = true;
        this.isPaidToDisabled = true;
        this.isPaidToVisible = true;
        this.isPaidToDisabled = true;
        this.paidFromLabel = `${creditToLabel} has paid occupancy fees from`;
        this.localAdjustment.updatePurchasePaidDates(this.context.matter);
        break;
      default:
        break;
    }
  }

  updateTaxRate(rateType: string): void {
    if (rateType == 'HST') {
      // only in ON
      this.localAdjustment.hstRate = this.context.soaConsiderationTaxes.hstRate;
    } else if (rateType == 'GST') {
      this.localAdjustment.hstRate = Number(this.context.soaConsiderationTaxes.hstFederalPortion);
    }
  }

  onDateChange(event: any, dateType: string): void {
    this.localAdjustment[dateType] = event.rawDate;
    this.clearCachedDetails();
    this.modalErrorComponent.removeAllDpFieldError();
  }

  onCreditToChange(): void {
    this.purchaserHasPaidFeeLabel =
      this.purchaserLabel + (this.localAdjustment.creditTo === 'PURCHASER' ? ' has paid to' : ' responsible up to');
    this.onPurchaserHasPaidFeeChange();
  }

  get closingDateMonthAndYear(): string {
    let finalAdjustAsDate: string =
      this.context.matter.adjustAsAtClosingDateFlag === 'SPECIFY'
        ? this.context.matter.adjustAsAtClosingDate
        : this.context.matter.matterCloseDate;
    return `${Utils.getMonthName(finalAdjustAsDate)} ${Utils.getYear(finalAdjustAsDate)}`;
  }

  get allowDelete(): boolean {
    return !this.context.hideDeleteFromModal && !this.newAdj;
  }

  isNonCondoPotlON(): boolean {
    return this.context.project && this.context.project.isNonCondoPotlON;
  }
}
