<form #addressForm="ngForm" novalidate *ngIf="address">
    <dp-accordion [expanded]="openForm" [primary]="primaryShutter" [offset]="offsetShutter" [offsetOther]="offsetShutterOther"
                  [primaryOther]="primaryShutterOther" [secondary]="secondaryShutter" [hideShutter]="!isAddressFieldDisplayed()">
        <div class="dp-accordion-header" [fieldKey]="fieldPrefix+'.shutter'" statusBarHelp>
            <div class="row  row-group" (click)="shouldThisToggle($event)">
                <div class="col-lg-2 vertical-align-label"><label class="control-label" for="completeAddress_{{address.identifier}}"
                                                                  [innerHTML]="addressSummaryLabel"></label></div>
                <div class="col-lg-4" *ngIf="showDropdown">
                    <select (ngModelChange)="shouldFormOpen($event)" class="form-control select-font-edit" [(ngModel)]="currentSameAsAddressOption"
                            name="addressDropdown"
                            [dp-read-only]="disabled">
                        <option *ngFor="let item of sameAsAddressOptions" [ngValue]="item">{{item.description}}</option>
                    </select>
                </div>
                <div class="col-lg-4" *ngIf="showAddressTypeDropdown">
                    <select (ngModelChange)="addressTypeChanged($event)" class="form-control select-font-edit" [(ngModel)]="address.addressTypeCode"
                            name="addressDropdown"
                            [dp-read-only]="disabled">
                        <option *ngFor="let item of addressDd" [value]="item.value">{{item.label}}</option>
                        <option *ngIf="address.isOtherPreviousAddress()" [value]="'OTHER_PREVIOUS_ADDRESS'">Other Previous Address</option>>
                    </select>
                </div>
                <div [ngClass]="showDropdown || showAddressTypeDropdown ? 'col-lg-6':'col-lg-10'">
                     <input type="text" trimmed id="{{fieldIndex}}_{{address.identifier}}" name="completeAddress" class="form-control text-overflow rightPadding95"
                           [ngModel]="getCompleteAddress()" readonly>

                    <div *ngIf="showAddressTypeDropdown" class="actionbtns width-132">
                        <span class="primary radio rightPadding15" (click)="disabled ? null: setAsPrimaryAddress($event)">
                         <input type="radio" [checked]="address.primaryAddress">
                         <label>Primary</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="dp-accordion-content inner-area address-form-area" *ngIf="!isSameAs">

            <div class="form-group">

            </div>
            <div class="form-group">
                <label class="control-label col-lg-2" for="addressLine1_{{address.identifier}}">Street{{showMandatoryFields? '*':''}}</label>
                <div class="col-lg-10">
                    <input type="text" class="form-control rightPadding350" name="address-readonly" [dp-read-only]="disabled"
                           *ngIf="disabled" [ngModel]="address.addressLine1"/>
                    <p-autoComplete *ngIf="!disabled" id="addressLine1_{{address.identifier}}"  #street
                                    name="address-autocomplete"
                                    [(ngModel)]="address.addressLine1"
                                    [class.autocomplete-searching]="addressSuggestionsLoading"
                                    [suggestions]="addressSuggestions"
                                    (completeMethod)="searchAddresses($event)"
                                    minLength="0" delay="1000"
                                    [maxlength]="addressMaxLength" scrollHeight="350px"
                                    (ngModelChange)="onStreetLineChange($event)"
                                    (onSelect)="dataSelectedAddress($event)"
                                    (keydown)="onAddressChange($event); utils.handleKeyDown($event, address.addressLine1, searchAddresses)"
                                    [fieldKey]="fieldPrefix+'.street'"
                                    statusBarHelp class="autocomplete-search-icon" dp-error-validator>

                        <ng-template let-place pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="place"
                                [ngClass]="{'font-style-italic': place?.description  == constantNoResultValue || place?.description == constantNoResultValue}">
                                    <span class="omnibarLi100">
                                        {{ place.description }}
                                    </span>
                                    <span style="float:right">{{place.moreAddressIndicator}}</span>
                            </li>
                        </ng-template>
                    </p-autoComplete>

                    <!--Following div is temporarily added to choose between google or canada post api-->
<!--                    <div class="form-group form-inline">
&lt;!&ndash;                        <span class="radio col-lg-3">
                            <input type="radio"
                                   id="google_radio_id"
                                   name="google_radio"
                                   value="GOOGLE"
                                   [(ngModel)]="selectedAddressSource"
                            />
                            <label for="google_radio_id">Google</label>
                            <input type="radio"
                                   id="canadapost_radio_id"
                                   name="canadapost_radio"
                                   value="CANADA_POST"
                                   [(ngModel)]="selectedAddressSource"
                            />
                            <label for="canadapost_radio_id">Canada Post</label>
                        </span>&ndash;&gt;
&lt;!&ndash;                        <select class="form-control"
                                id="address_search_country_id"
                                name="address_search_country"
                                class="form-control"
                                [(ngModel)]="selectedCountryForSearch">
                            <option *ngFor="let country of countryList"
                                    [value]="country.value">
                                {{country.label}}
                            </option>
                        </select>&ndash;&gt;
                    </div>-->

                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2" for="addressLine2_{{address.identifier}}">Continued</label>
                <div class="col-lg-10" [ngClass]="{'input-invalid': (submitted && !continued.valid)}">
                    <input type="text" trimmed id="addressLine2_{{address.identifier}}" name="addressLine2" class="form-control"
                           [(ngModel)]="address.addressLine2" #continued="ngModel"  (ngModelChange)="populateProvince()"
                           (keydown)="onAddressChange($event)"
                           [maxlength]="addressMaxLength" [dp-read-only]="disabled">
                </div>
            </div>
            <div class="form-group">
                <label i18n="@@suburb" class="control-label col-lg-2" for="city_{{address.identifier}}">City{{showMandatoryFields? '*':''}}</label>
                <div class="col-lg-4" [ngClass]="{'input-invalid': (submitted && !city.valid)}">
                    <input type="text" id="city_{{address.identifier}}" name="city" class="form-control" [(ngModel)]="address.city"
                           #city="ngModel" maxlength="30" [dp-read-only]="disabled" [fieldKey]="fieldPrefix+'.city'" dp-error-validator
                           (ngModelChange)="populateProvince()" (keydown)="onAddressChange($event)"
                           [dynamicHelpCallBackFunction]="dynamicF9helpCity()"
                           statusBarHelp
                           (keydown.f9)="captureF9ForCityField()">
                    <div *ngIf="!city.valid && addressFlags?.city">
                        <div class="error-wrapper" style="position:relative;"><i class="fa fa-exclamation-triangle"
                                                                                 aria-hidden="true"></i>
                            <p class="error">City must be completed.</p>
                            <a class="btn-close"><b class="fa fa-times" aria-hidden="true"></b></a>
                        </div>
                    </div>
                </div>
                <label i18n="@@state" class="control-label col-lg-2" for="province_{{address.identifier}}">Province{{showMandatoryFields || showMandatoryProvince ?
                    '*':''}}</label>
                <div *ngIf="page != 'LegalFirm'" class="col-lg-4">
                    <input type="text" trimmed id="province_{{address.identifier}}" name="province" class="form-control"
                           [(ngModel)]="localProvinceName" (ngModelChange)="addProvinceCode()" [fieldKey]="fieldPrefix+'.provinceName'" dp-error-validator
                           #province="ngModel" (keydown)="onAddressChange($event)"
                           maxlength="33" [dp-read-only]="disabled || provinceReadOnly"
                           [dynamicHelpCallBackFunction]="dynamicF9helpProvince()"
                           statusBarHelp
                           (keydown.f9)="captureF9ForProvinceField()">
                    <div *ngIf="!province.valid && addressFlags?.province">
                        <div class="error-wrapper" style="position:relative;"><i class="fa fa-exclamation-triangle"
                                                                                 aria-hidden="true"></i>
                            <p class="error">Province must be completed.</p>
                            <a class="btn-close"><b class="fa fa-times" aria-hidden="true"></b></a>
                        </div>
                    </div>
                </div>
                <div *ngIf="page == 'LegalFirm'" class="col-lg-4">
                    <select class="form-control" id="province_{{address.identifier}}" name="province" [(ngModel)]="address.provinceCode" [dp-read-only]="disabled">
                        <option *ngFor="let province of provinceNameList" [value]="province.value">
                            {{province.label}}
                        </option>
                    </select>
                </div>

            </div>
            <div class="form-group">
                <label class="control-label col-lg-2" for="address_search_country_id">Country{{showMandatoryFields? '*':''}}</label>
                <div class="col-lg-4">
<!--                    <input type="text" trimmed id="country_{{address.identifier}}" name="country" class="form-control" uppercased
                           [(ngModel)]="address.country" #country="ngModel"
                           [dp-read-only]="page == 'LegalFirm' || disabled" maxlength="32"
                           (blur)="checkPostCode()"
                           [dynamicHelpCallBackFunction]="dynamicF9helpCountry()"
                           statusBarHelp
                           [fieldKey]="fieldPrefix+'.country'" dp-error-validator
                           (keydown.f9)="captureF9ForCountryField()">-->
                    <!--<select *ngIf="!isOmniSearchForCountry"-->
                            <!--class="form-control"  #country-->
                            <!--id="address_search_country_id"-->
                            <!--name="address_search_country"-->
                            <!--(change)="updateAddressCountryValue($event)"-->
                            <!--[attr.disabled]="page == 'LegalFirm' ? 'true' : null"-->
                            <!--(keydown.f9)="captureF9ForCountryField()"-->
                            <!--class="form-control"-->
                            <!--[dp-read-only]="disabled"-->
                            <!--dp-error-validator-->
                            <!--[fieldKey]="fieldPrefix+'.country'"-->
                            <!--statusBarHelp-->
                            <!--[(ngModel)]="selectedCountryForSearch">-->
                        <!--<option *ngFor="let country of countryList" [value]="country.value">-->
                            <!--{{country.label}}-->
                        <!--</option>-->
                    <!--</select>-->
                    <input type="text" id="country_{{address.identifier}}" name="country" class="form-control"
                           [ngModel]="address.country" dp-read-only="true"
                           *ngIf="disabled" >
                    <p-autoComplete *ngIf="!disabled"
                                    id="country_{{address.identifier}}"
                                    name="omniSearchCountry"
                                    [ngModel]="selectedCountryForSearch"
                                    (ngModelChange)="onCountryChange($event)"
                                    [class.autocomplete-searching]="omniSearchForCountryLoading"
                                    [suggestions]="countrySuggestions"
                                    (completeMethod)="omniSearchCountry($event)"
                                    (onSelect)="dataSelectedAddressCountry($event)"
                                    [dropdown]="true"
                                    [class.dropdown-arrow-refferedBy]="true"
                                    (onDropdownClick)="handleDropdownClicksearchCountry()"
                                    maxlength="100" scrollHeight="350px"
                                    minLength="0" delay="1000"
                                    fieldKey="fieldPrefix+'.country'"
                                    statusBarHelp
                                    tabIndexIgnore
                    >
                        <ng-template let-country pTemplate="item" >
                            <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="country">
                                    <span class="omnibarLi100">
                                        {{ country.label }}
                                    </span>
                            </li>
                        </ng-template>
                    </p-autoComplete>
                    <div *ngIf="!address.country && addressFlags?.country">
                        <div class="error-wrapper" style="position:relative;"><i class="fa fa-exclamation-triangle"
                                                                                 aria-hidden="true"></i>
                            <p class="error">Country must be completed.</p>
                            <a class="btn-close"><b class="fa fa-times" aria-hidden="true"></b></a>
                        </div>
                    </div>
                </div>

                <div class="form-input-wrapper">
                    <label i18n="@@postcode" class="control-label col-lg-2" for="postalCode_{{address.identifier}}">{{ isCountryCanada() ?
                        'Postal Code' : 'Zip Code' }}{{showMandatoryFields? '*':''}}</label>
                    <div class="col-lg-4">
                        <input type="text"
                               id="postalCode_{{address.identifier}}"
                               name="postalCode"
                               class="form-control"
                               uppercased
                               [fieldCode]="fieldCodeMap['postalCode']"
                               [fieldKey]="fieldPrefix+'.postalCode'" dp-error-validator
                               (blur)="validatePostalCode()"
                               [maxlength]="selectedCountryForSearch ==='CA' ? 7 : 10"
                               [(ngModel)]="address.postalCode"
                               (ngModelChange)="populateProvince()"
                               (keydown)="onAddressChange($event)"
                               #postalCode="ngModel"
                               #_postalCode_
                               [dp-read-only]="disabled">
                    </div>
                </div>
            </div>
        </div>
    </dp-accordion>
</form>
