import {Injectable} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Observable} from 'rxjs/Rx';
import {LoginLtsaModalComponent} from '../../main/manage-thirdparty-credentials/ltsa/login-ltsa.modal.component';

declare var jQuery: any;

@Injectable()
export class LtsaAuthenticationService {

  constructor(private dialogService: DialogService) {
  }

  openLtsaAuthentication(): Observable<any> {
    let observable: Observable<any> = new Observable<any>(observer => {
      this.dialogService.matDialogContent({
        modalGrid: 8,
        content: LoginLtsaModalComponent,
        context: {},
        onFulfillment: (result: any) => {
          observer.next(result);
          jQuery('body').removeClass('modal-open');
        }
      });
    });
    return observable;
  }

}
