import {UnSavedChangesDialog} from './unsavedchanges-dialog.component';
import {DialogService} from './dialog.service';
import {NgModule} from '@angular/core';
import {ConfirmDialog} from './confirm-dialog.component';
import {CommonModule} from '@angular/common';
import {DPPMCheckBoxModule} from '../checkbox/checkbox.module';
import {ClipboardModule} from 'ngx-clipboard';
import {MaterialModule} from '../../MaterialModule';

@NgModule({
  imports: [MaterialModule, CommonModule, DPPMCheckBoxModule, ClipboardModule],
  exports: [ConfirmDialog, UnSavedChangesDialog],
  declarations: [ConfirmDialog, UnSavedChangesDialog],
  providers: [DialogService]
})
export class MDDialogsModule {}
