import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {UUIDUtil} from '../../main/uuid-util';
import Utils from '../../shared-main/utils';

export class MortgageInstrument extends BaseEntity {
  id: number;
  instrumentType: string;
  registrationNumber: string;
  registrationDate: string;
  rollBookNumber: number;
  framePageNumber: number;

  constructor(mortgageInstrument?: MortgageInstrument) {
    super(mortgageInstrument);
    // Before saving, we need to use id to find mortgageInstrument. So UI creates the id
    if (!this.id) {
      this.id = UUIDUtil.getUUID();

    }
  }

  isMortgageInstrumentEmpty(): boolean {
    return (Utils.isEmptyString(this.instrumentType) && Utils.isEmptyString(this.registrationNumber)
      && Utils.isEmptyString(this.registrationDate) && (this.rollBookNumber == undefined || this.rollBookNumber == null)
      && (this.framePageNumber == undefined || this.framePageNumber == null));
  }
}
