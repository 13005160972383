<div *ngIf="templateStopCodesArray.length > 0" class="stop-codes-div">
    <div *ngFor="let item of templateStopCodesArray">
        <br>
        <p class="file-name">File: {{documentProductionData.getTemplateName(item.template)}}</p>
        <div *ngIf="item.stopCodeWrappers.length > 0" class="stop-codes-wrapper">
            <div *ngFor="let stopCodeWrapper of item.stopCodeWrappers">
                <div class="row margin-bottom-20">
                    <div class="col-lg-12">
                        <label for="stopcode1">
                            <h6 class="semiboldfont">{{stopCodeWrapper.documentStopCode.code}}</h6>
                            <p *ngIf="getClientName(stopCodeWrapper).length > 0">{{getClientName(stopCodeWrapper)}}</p>
                            <p class="s-code-txt">{{stopCodeWrapper.documentStopCode.text}}</p>
                        </label>
                        <input type="text" id="stopcode1" maxlength="256" class="form-control" [(ngModel)]="stopCodeWrapper.displayValue"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
