<div class="row logo"></div>
<div class="row">
  <span>If you forgot your credentials please contact your account administrator at your law firm. If you are the law firm administrator please contact Unity support team at
      <span class="email">
        <a href="mailto:support@doprocess.com">support@doprocess.com</a>
      </span>
  <span> or </span>
  <span class="phone">416.322.6111</span>
  <span> or toll-free at </span>
  <span class="phone">1.866.367.7648</span>
  </span>
</div>
<div class="row">
  <a href="javascript:void(0);" (click)="backToLogin()">Back to login</a>
</div>
