import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class GeneralLedgerAccountExplanation extends BaseEntity {

  id: number;
  statementConfigurationId: number;
  generalLedgerAccountCodeId: number;
  accountNumber: string;
  code: string;
  explanation: string;
  sum: string;
  status: string;

  constructor(generalLedgerAccountExplanation?: GeneralLedgerAccountExplanation) {
    super(generalLedgerAccountExplanation);
  }
}
