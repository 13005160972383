import {Component, Inject, OnInit} from '@angular/core';
import {Matter} from '../shared';
import {MatterOverviewService} from './matter-overview.service';
import {AssignedTaskStatusOptions, MatterWorkItemTask, TaskStatusOptions} from '../shared/matter-work-item';
import {SelectItem} from 'primeng/api';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class PrintMatterWorkItemsModalContext {
  matter: Matter;
  matterOverviewService: MatterOverviewService;
}

@Component({
  selector: 'dp-print-matter-work-items-modal-content',
  templateUrl: 'print-matter-work-items-modal.component.html',
  styleUrls: ['./print-matter-overview-modal.component.scss']
})
export class PrintMatterWorkItemsModalComponent
  extends ModalComponent<PrintMatterWorkItemsModalContext>
  implements OnInit
{
  taskStatusOptions: SelectItem[] = TaskStatusOptions;
  assignedTaskStatusOptions: SelectItem[] = AssignedTaskStatusOptions;

  constructor(
    public dialog: MatDialogRef<PrintMatterWorkItemsModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: PrintMatterWorkItemsModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {}

  close() {
    this.dialog.close();
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get matterOverviewService(): MatterOverviewService {
    return this.context.matterOverviewService;
  }

  getStatusText(task: MatterWorkItemTask): string {
    let statusOptions =
      !!task.assignedToParticipantEmail || !!task.assignedToParticipantId
        ? this.assignedTaskStatusOptions
        : this.taskStatusOptions;
    let statusOption = statusOptions.find((item) => item.value == task.status);
    return statusOption ? statusOption.label : '';
  }
}
