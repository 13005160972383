import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {FieldError} from '../../../core';

export class TprValidationResponse extends BaseEntity {

  validationStatus: string;
  fieldErrorList: FieldError[];

  get tprValidationStatus(): boolean {
    return this.validationStatus === 'OK';

  }

}


