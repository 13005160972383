import {Component, Inject, ViewChild} from '@angular/core';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {Matter} from '../../../shared/matter';
import {StatementAdjustment} from '../../statement-adjustment';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {SoAdjCreditVendorTaxRebate} from './so-adj-credit-vendor-tax-rebate';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import {creditVendorTaxRebateDropDowns} from '../../model/so-adj-drop-downs';
import {booleanYesNoDropDowns, YesNoTypes} from '../../../../shared-main/constants';
import SharedUtils from '../../../../shared-main/utils';
import {Utils} from '../../../shared/utils';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class CreditVendorTaxRebateModalContext {
  public closingDate: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public soAdjCreditVendorTaxRebate: SoAdjCreditVendorTaxRebate;
  public statementAdjustment: StatementAdjustment;
  public salePrice: number;
  public taxRateType: string;
  public matter: Matter;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-credit-vendor-tax-rebate-modal-content',
  templateUrl: './credit-vendor-tax-rebate.modal.component.html',
  providers: [ErrorService]
})
export class CreditVendorTaxRebateModal extends ModalComponent<CreditVendorTaxRebateModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = 'Closing Date';
  soAdjCreditVendorTaxRebate: SoAdjCreditVendorTaxRebate;
  adjustedForDropDown: SelectItem[];
  showAdjustmentAsDropDown: SelectItem[] = creditVendorTaxRebateDropDowns.showAdjustmentAsTypes;
  isAdjustedDropDown: SelectItem[] = booleanYesNoDropDowns.YesNoOnly;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<CreditVendorTaxRebateModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: CreditVendorTaxRebateModalContext
  ) {
    super(dialog, context);
  }

  get salePriceAdjustment(): SalePriceAdjustment {
    return (
      this.context.matter &&
      this.context.matter.considerationLtt &&
      this.context.matter.considerationLtt.salePriceAdjustment
    );
  }

  get purchaserLabel(): string {
    return this.context.matter.statementOfAdjustmentHeading
      ? this.context.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.matter.statementOfAdjustmentHeading
      ? this.context.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  isTaxRateGst(): boolean {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.provinceDefaultTaxRate == 'GST';
  }

  get appliedTaxRate(): string {
    return this.isTaxRateGst() ? 'GST' : this.context.provinceHstRateSlab.rateType;
  }

  get federalHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstFederalPortion
      ? this.context.provinceHstRateSlab.hstFederalPortion
      : 0;
  }

  get provinceHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstProvincialPortion
      ? this.context.provinceHstRateSlab.hstProvincialPortion
      : 0;
  }

  get appliedRate(): number {
    return this.context.provinceHstRateSlab ? this.context.provinceHstRateSlab.hstRate : 0;
  }

  get isAdded(): boolean {
    return (
      this.context.statementAdjustment &&
      this.context.statementAdjustment.addedFlag != undefined &&
      !this.context.statementAdjustment.addedFlag
    );
  }

  ngOnInit() {
    this.adjustedForDropDown = SharedUtils.loadTaxSelectItem(
      this.context.provinceHstRateSlab.rateType,
      this.context.provinceHstRateSlab.hstProvincialPortion
    );
    if (!this.context.soAdjCreditVendorTaxRebate) {
      this.soAdjCreditVendorTaxRebate = new SoAdjCreditVendorTaxRebate();
      this.newAdj = true;
    } else {
      this.soAdjCreditVendorTaxRebate = new SoAdjCreditVendorTaxRebate(this.context.soAdjCreditVendorTaxRebate);
    }
    if (!this.soAdjCreditVendorTaxRebate.adjustmentHeading) {
      this.soAdjCreditVendorTaxRebate.adjustmentHeading = this.appliedTaxRate + ' Rebate Purchaser Not Eligible';
    }
    if (!this.soAdjCreditVendorTaxRebate.adjustedFor) {
      this.soAdjCreditVendorTaxRebate.adjustedFor = this.adjustedForDropDown[0].value;
    }
    if (!this.soAdjCreditVendorTaxRebate.isAdjusted) {
      this.soAdjCreditVendorTaxRebate.isAdjusted = YesNoTypes.NO;
    }
    if (!this.soAdjCreditVendorTaxRebate.percentage) {
      this.soAdjCreditVendorTaxRebate.percentage = this.context.matter.soaHst;
    }
    if (!this.soAdjCreditVendorTaxRebate.showAdjustmentAs) {
      this.soAdjCreditVendorTaxRebate.showAdjustmentAs = creditVendorTaxRebateDropDowns.showAdjustmentAsTypes[0].value;
    }
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }

    this.soAdjCreditVendorTaxRebate.amount = this.salePriceAdjustment
      ? this.salePriceAdjustment.totalRebatePortion(this.provinceHstRate, this.federalHstRate, this.context.salePrice)
      : 0;
  }

  adjustTaxRatePercentage(): void {
    if (this.soAdjCreditVendorTaxRebate && this.soAdjCreditVendorTaxRebate.adjustedFor === 'GST') {
      this.soAdjCreditVendorTaxRebate.percentage = this.context.matter.soaFederalHst;
    } else {
      this.soAdjCreditVendorTaxRebate.percentage = this.context.matter.soaHst;
    }
  }

  ok(): void {
    let hasError: boolean = false;
    if (this.soAdjCreditVendorTaxRebate.adjustmentHeading) {
      this.modalErrorComponent.removeDpSaveError('matter.creditVendorTaxRebate.modal.adjustmentHeading');
    } else {
      hasError = true;
      this.modalErrorComponent.createDPSaveError('matter.creditVendorTaxRebate.modal.adjustmentHeading');
    }

    if (!hasError) {
      this.dialog.close({soAdjCreditVendorTaxRebate: this.soAdjCreditVendorTaxRebate});
    }
  }

  close(): void {
    this.dialog.close();
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close({soAdjCreditVendorTaxRebate: undefined});
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  getPercentageLabel(): string {
    if (this.adjustedForDropDown.length > 1) {
      return (
        (this.soAdjCreditVendorTaxRebate.adjustedFor === this.adjustedForDropDown[0].value
          ? this.adjustedForDropDown[0].label
          : this.adjustedForDropDown[1].label) + ' percentage is '
      );
    } else {
      if (this.adjustedForDropDown && this.adjustedForDropDown.length === 1) {
        return this.adjustedForDropDown[0].label + ' percentage is ';
      }
    }
    return ' percentage is ';
  }

  creditVendorTaxRebatePosition(): number {
    let ret: number = 4;
    if (this.soAdjCreditVendorTaxRebate.isPartOfSalePrice()) {
      ret = 2;
    }

    if (this.soAdjCreditVendorTaxRebate.isSeparateAdjustmentSilent()) {
      ret = 6;
    }
    return ret;
  }

  getModalTitle(): string {
    return 'Adjustment Type : ' + this.appliedTaxRate + ' Rebate Purchaser Not Eligible';
  }

  isPercentageVisible(): boolean {
    return this.soAdjCreditVendorTaxRebate.isAdjusted === 'YES';
  }

  getCreditVendorLabel(): string {
    let ret: string = '';
    if (this.soAdjCreditVendorTaxRebate.isPartOfSalePrice()) {
      ret = 'Credit Vendor (in a sale price adjustment)';
    }

    if (this.soAdjCreditVendorTaxRebate.isSeparateAdjustment()) {
      ret = 'Credit Vendor';
    }

    if (this.soAdjCreditVendorTaxRebate.isSeparateAdjustmentSilent()) {
      ret = 'Amount';
    }
    return ret;
  }
}
