import {Injectable} from '@angular/core';
import {HttpClient} from '../core/httpClient.service';
import {Observable} from 'rxjs';
import {EventData} from './event-data';
import {eventApi} from './event-api';
import {Utils as SharedUtils, Utils as MoreUtils} from '../matters/shared/utils';
import {eventResponseKey} from './event-response-key';
import moment from 'moment';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import EventUtils from './event.utils';
import {CalendarEvent} from 'angular-calendar';
import Utils from '../shared-main/utils';
import {PROJECT_SALE_MATTER_TYPE} from '../shared-main/constants';
import {SESSION_ID_REQ_PARAM} from '../common';
import {PublicHolidayService} from '../shared-main/public-holiday/public-holiday.service';
import {Contact} from '../matters';
import {SyngrafiiAccountConfiguration} from './syngrafii-account-configuration';
import {thirdPartyIntegrationApi} from '../shared-main/third-party/third-party-integration-constants';
import {TotalTransactionCount} from './event-list/total-transaction-count';

@Injectable()
export class EventService {
  constructor(private http: HttpClient) {
  }

  // this method is for getting  all the events from database
  getEventsURL(isEventsApiUrl: boolean, filterSolicitor: string[], filterLawClerk: string[],
               filterEventType: string[], filterMatterType: string[],
               filterProvince: string[], filterStatuses: string[],
               filterStartDate: Date, filterEndDate: Date,
               filterMatterId?: string,
               pagination?: boolean, page?: number, perPage?: number, selectedProjects?: string[], includeMatterSubNodes = true): string {

    let url: string;
    let sort: string = '';
    let sortingType: string = 'ASC';
    let filter: string = '';
    let filterFlag: boolean = false;
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let matterTypeFilters: string = '';

    let scheduledFor: string = '';
    // ANYmatterStatus_IN_ACTIVE!DEFAULT
    // Events belonging to matters where "Is file inactive?" is set to Yes are not displayed on the Events List, Calendar or the Printable Events List/Calendar.
    // Regardless of what filter options are selected.
    // For ACTIVE and "N/y" MatterStatuses
    filter = SharedUtils.addCommaToFilter(filter);
    filterFlag = true;
    filter += 'ANY' + 'matter.matterStatus_IN_ACTIVE!' + SharedUtils.escapeSearchText('DEFAULT_ACTIVE');

    // EventType Filter
    if (Array.isArray(filterEventType)) {
      if (filterEventType.indexOf('ALL') > -1 || filterEventType.length === 0) {
        //No Filter
      } else {
        let assignedFilter: string = '';
        for (let i = 0; i < filterEventType.length; i++) {
          if (assignedFilter.length === 0) {
            assignedFilter = 'eventType_IN_';
          }
          if (i == (filterEventType.length - 1)) {
            assignedFilter += filterEventType[ i ];
          } else {
            assignedFilter += filterEventType[ i ] + '!';
          }
        }
        if (assignedFilter.length > 0) {
          filter = SharedUtils.addCommaToFilter(filter);
          filterFlag = true;
          filter += assignedFilter;
        }
      }
    }

    // Matter Type Filter
    if (Array.isArray(filterMatterType)) {
      if (filterMatterType.indexOf('ALL') > -1) {
        let matterTypeList = filterMatterType.slice(0);
        // matterTypeList = matterTypeList.filter( item => item  != PROJECT_SALE_MATTER_TYPE && item  != 'ALL');
        // filter += 'matterSearchView.matterType_IN_' + matterTypeList.join('!');
        // CHECK IF PROJECT SALE MATTER TYPE IS INCLUDED
        if (filterMatterType.some(item => item == PROJECT_SALE_MATTER_TYPE)) {
          if (Array.isArray(selectedProjects)) {
            if (selectedProjects.indexOf('ALL') > -1) {
              //No filter
            } else if (selectedProjects.length > 0) {
              filter = MoreUtils.addCommaToFilter(filter);
              filter += 'matterSearchView.projectMatter_EQ_true,matter.unityProject.id_IN_' + selectedProjects.join('!');
            } else {
              //No filter
            }
          }
        }
      } else if (filterMatterType && filterMatterType.length > 0) {
        let matterTypeList = filterMatterType.slice(0);
        matterTypeList = matterTypeList.filter(item => item != PROJECT_SALE_MATTER_TYPE);
        const selectedSale = filterMatterType.some(item => item == 'SALE');
        if (matterTypeList.length > 0) {
          filter = MoreUtils.addCommaToFilter(filter);
          filter += 'ANYmatterSearchView.derivedMatterType_IN_' + matterTypeList.join('!');
        }
        // CHECK IF PROJECT SALE MATTER TYPE IS SELECTED
        if (filterMatterType.some(item => item == PROJECT_SALE_MATTER_TYPE)) {

          if (Array.isArray(selectedProjects)) {
            if (selectedProjects.indexOf('ALL') > -1) {
              if (matterTypeList.length > 0) {
                filter += '|matterSearchView.projectMatter_EQ_true';
              } else {
                filter = MoreUtils.addCommaToFilter(filter) + 'matterSearchView.projectMatter_EQ_true';
              }
            } else if (selectedProjects.length > 0) {
              if (matterTypeList.length > 0) {
                filter += ',matterSearchView.projectMatter_EQ_true,matter.unityProject.id_IN_' + selectedProjects.join('!');
              } else {
                filter = MoreUtils.addCommaToFilter(filter) + 'matter.unityProject.id_IN_' + selectedProjects.join('!');
              }
            } else {
              if (matterTypeList.length > 0) {
                if (selectedSale) {
                  //No filter
                } else {
                  filter += '|matterSearchView.projectMatter_EQ_true';
                }
              } else {
                filter = MoreUtils.addCommaToFilter(filter) + 'matterSearchView.projectMatter_EQ_true';
              }
            }
          }
        } else {
          if (matterTypeList.some(item => item == 'SALE')) {
            filter = matterTypeList.length > 0 ? (filter + ',matterSearchView.projectMatter_EQ_false') : (MoreUtils.addCommaToFilter(filter) + 'matterSearchView.projectMatter_EQ_false');
          }
        }
      }
    }

    // Province Filter
    if (Array.isArray(filterProvince)) {
      let provinceFilters: string = '';
      let index: number = filterProvince.indexOf('ALL');
      if (index > -1) {
        //do nothing. We don't need filter if there is "ALL".
      } else {
        if (filterProvince.length > 0) {
          filter = SharedUtils.addCommaToFilter(filter);
          provinceFilters = 'matter.provinceCode_IN_' + filterProvince.join('!');
          filterFlag = true;
          filter += provinceFilters;
        }
      }
    }

    // StartDate Filter
    if (filterStartDate) {
      filter = SharedUtils.addCommaToFilter(filter);
      filterFlag = true;
      // According backend requirement, StartDate must be 1 day ahead.
      filter += `startDate_GT_${ moment(filterStartDate).subtract(1, 'days').format('YYYY/MM/DD') }`;
    }

    // endDate Filter
    if (filterEndDate) {
      filter = SharedUtils.addCommaToFilter(filter);
      filterFlag = true;
      // According backend requirement, EndDate must be 1 day after.
      filter += `startDate_LT_${ moment(filterEndDate).add(1, 'days').format('YYYY/MM/DD') }`;
    }

    // Matter Filter
    if (filterMatterId) {
      filter = SharedUtils.addCommaToFilter(filter);
      filterFlag = true;
      filter += `matter.id_EQ_${ filterMatterId }`;
    }

    // filter = Utils.addCommaToFilter(filter);
    // DPPMP-20675 filter +=`eventStatus_IN_COMPLETED!PENDING`;

    let derivedStatusParam = '';
    if (Array.isArray(filterStatuses)) {
      if (filterStatuses.indexOf('ALL') > -1 || filterStatuses.length === 0) {
        //No additional value needed
      } else {
        derivedStatusParam = `derivedFilterStatusValue=${ filterStatuses }&overDueDate=${ SharedUtils.escapeSearchText(moment(new Date()).format('YYYY/MM/DD')) }`;
      }
    }

    if (sort != '') {
      sort = 'sort=' + sort + '|' + sortingType;
    } else {
      sort = '';
    }

    let apiUrl = isEventsApiUrl ? eventApi.events(accountId) : eventApi.totalTransactionCount(accountId);

    url = filterFlag
      ? `${ apiUrl }?${ sort }${ derivedStatusParam }&filter=${ filter }`
      : `${ apiUrl }?${ sort }${ derivedStatusParam }`;

    // url = `${eventApi.events}&filter=${filter}`

    if (page) {
      url = `${ url }&page=${ page }&per_page=${ perPage }`;
    }

    url = `${ url }&includeMatterSubNodes=${ includeMatterSubNodes }`;
    if (filterSolicitor && filterSolicitor.some(fs => fs != 'N/A' && fs != 'ALL' && fs != 'ALL_ACTIVE_LAWYERS')) {
      url = `${ url }&selectedLawyerIds=` + filterSolicitor.filter(fs => fs != 'N/A' && fs != 'ALL' && fs != 'ALL_ACTIVE_LAWYERS').join(',');
    }
    if (filterLawClerk && filterLawClerk.some(fs => fs != 'N/A' && fs != 'ALL' && fs != 'ALL_ACTIVE_LAWCLERKS')) {
      url = `${ url }&selectedLawClerkIds=` + filterLawClerk.filter(fs => fs != 'N/A' && fs != 'ALL' && fs != 'ALL_ACTIVE_LAWCLERKS').join(',');
    }
    if (filterSolicitor && filterSolicitor.some(fs => fs == 'N/A')) {
      url = `${ url }&fetchEventsWithoutLawClerk=true`;
    }
    if (filterLawClerk && filterLawClerk.some(fs => fs == 'N/A')) {
      url = `${ url }&fetchEventsWithoutLawyer=true`;
    }

    //For templately test
    // url = `${eventApi.events}?per_page=20&filterIgnoreCase=false`;

    return url;

  }

  // this method is for getting  all the events from database
  getEvents(filterSolicitor: string[], filterLawClerk: string[],
            filterEventType: string[], filterMatterType: string[],
            filterProvince: string[], filterStatuses: string[],
            filterStartDate: Date, filterEndDate: Date,
            filterMatterId?: string,
            pagination?: boolean, page?: number, perPage?: number, selectedProjects?: string[], includeMatterSubNodes = true): Observable<EventData[]> {

    let url: string = this.getEventsURL(true, filterSolicitor, filterLawClerk, filterEventType, filterMatterType, filterProvince, filterStatuses,
      filterStartDate, filterEndDate, filterMatterId, pagination, page, perPage, selectedProjects, includeMatterSubNodes);

    //If mass update mode enabled then no need to load matters events which is done in component initialization
    if (this.http.isMassUpdateModeEnabled()) {
      return Observable.of([]);
    } else {
      return this.http.get(url).map((res) => {
        const data: any[] = res[ eventResponseKey.events ];
        if (Array.isArray(data)) {
          return data.map(item => new EventData(item));
        } else {
          return [];
        }
      });
    }

  }

  getEventDescriptionForCalendar(item: EventData): string {
    let text = `(${ Utils.convertToTitleCase(item.getEventType()) }) ${ EventUtils.getMatterTypeDisplayShortName(item) } ${ EventUtils.getMatterRecordNumber(item) } `;
    text += `${ item.isWorItemTask() ? item.eventDescription : '' } `;
    text += `${ item.isProjectSale ? Utils.getFormattedClientReLine(item.vendorReline) : Utils.getFormattedClientReLine(item.clientReLine) } `;
    text += `${ item.solicitorInitials } ${ item.lawClerkInitials } `;
    return text;
  }

  getEventDataforCalendar(events: EventData[], publicHolidayService: PublicHolidayService, eventDataFor: string, lawClerkSolicitorList: Contact[]): CalendarEvent[] {
    let calendarEventData: CalendarEvent[] = [];
    if (events.length > 0) {
      events.forEach(item => {
        let clientReLine: string = !item.clientReLine ? EventUtils.getMatterRecordNumber(item, false) : Utils.getFormattedClientReLine(item.clientReLine);
        let formatInitials: string = item.lawClerkInitials && item.solicitorInitials ? item.lawClerkInitials + '/' + item.solicitorInitials : item.lawClerkInitials + item.solicitorInitials;
        let printTitle: string = `${ EventUtils.getMatterTypeDisplayShortName(item) } : ${ clientReLine } ${ formatInitials }`;
        let printTitleAppointment: string = `A : ${ item.startTime ? item.startTime : '' }${ item.startTime && item.endTime ? ' - ' : '' }${ item.endTime ? item.endTime : '' }`;
        let description: string = `${ this.getEventDescriptionForCalendar(item) }`;
        let descriptionAppointment: string = `(${ Utils.convertToTitleCase(item.eventType) }) ${ item.description } (${ EventUtils.getMatterTypeDisplayShortName(item) } ${ EventUtils.getMatterRecordNumber(item) }) ${ item.eventStatus }`;
        if (item.isAppointment() && !item.isEventDocsStatusEmptyOrNA()) {
          descriptionAppointment += `, ${ item.eventDocsStatusLabel }`;
        }
        if (item.inviteeList) {
          descriptionAppointment += ', ' + item.getInviteeListTruncated(190 - descriptionAppointment.length);
        }
        if (item.isWorItemTaskAssigned()) {
          let assignedTo = EventUtils.getSolicitorInitials(item, lawClerkSolicitorList);
          if (assignedTo && assignedTo.length > 0) {
            if (eventDataFor == 'LIST') {
              description += ' Assigned-' + assignedTo;
            }
            if (eventDataFor == 'PRINT') {
              printTitle += '/' + assignedTo;
            }
            if (eventDataFor == 'MATTER_OVERVIEW') {
              description += ' Assignee:' + assignedTo;
            }

          }
        }
        let calenderEvent: CalendarEvent = {
          start: moment(item.startDate).toDate(),
          title: item.eventType == 'APPOINTMENT' ? descriptionAppointment : description,
          color: EventUtils.getColorForEventCalendar(item.eventType, item.matterType == 'OPPORTUNITY'),
          meta: {
            type: item.getCalenderEventEventType(),
            id: item.matterId,
            printTitle: item.eventType == 'APPOINTMENT' ? printTitleAppointment : printTitle,
            dateHoliday: publicHolidayService.getHolidayByFullDateString(item.startDate)
          },
          cssClass: item.eventStatus && (item.eventStatus == 'COMPLETED' || item.eventStatus == 'CLOSED') ? 'task-completed' : 'task-pending'
        };
        calendarEventData.push(calenderEvent);
      });
      return calendarEventData;
    }
  }

  /**
   * Create matter event.
   * @param matterId
   * @param eventData
   * @param ignoreConflicts - when true, will force creating of appointment even if there is a conflict during that time window
   */
  createEvent(matterId: number, eventData: EventData, ignoreConflicts?: boolean): Observable<EventData> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = matterId ? eventApi.createEvent(String(matterId)) : eventApi.createStaffEvent(accountId);

    if (ignoreConflicts) {
      url += `?ignoreConflicts=${ ignoreConflicts }`;
    }

    return this.http.post(url, eventData)
    .map((response) => {
      return new EventData(response[ 'MatterEvent' ]);
    });
  }

  deleteEvent(matterId: number, eventId: number) {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = matterId ? eventApi.deleteEvent(String(matterId), String(eventId)) : eventApi.deleteStaffEvent(accountId, String(eventId));

    return this.http.delete(url).map((res) => {
      return res;
    });
  }

  /**
   * Update matter event.
   * @param matterId
   * @param eventData
   * @param ignoreConflicts - when true, will force saving of appointment even if there is a conflict during that time window
   * @param sendSyngrafiiPackage - a flag for sending the syngrafii package to receptients
   */
  updateEvent(matterId: number, eventData: EventData, ignoreConflicts?: boolean, sendSyngrafiiPackage?: boolean): Observable<EventData> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = matterId ? eventApi.matterEvent(String(matterId), String(eventData.id)) : eventApi.updateStaffEvent(accountId, String(eventData.id));

    if (ignoreConflicts) {
      url += `?ignoreConflicts=${ ignoreConflicts }`;
    }
    if (sendSyngrafiiPackage) {
      if (ignoreConflicts) {
        url += `&sendSyngrafiiPackage=${ sendSyngrafiiPackage }`;
      } else {
        url += `?sendSyngrafiiPackage=${ sendSyngrafiiPackage }`;
      }
    }

    return this.http.put(url, eventData)
    .map((response) => {
      return new EventData(response[ 'MatterEvent' ]);
    });
  }

  //Downloads .ics file for use by external calendars.
  exportEvent(matterId: number, eventId: number): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = matterId ? eventApi.exportMatterEvent(String(matterId), String(eventId)) : eventApi.exportStaffEvent(accountId, String(eventId));
    url = url + `?${ SESSION_ID_REQ_PARAM }=${ sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) }`;
    url = url + '&offset=' + moment().format('Z');
    window.open(this.http.encodePlusSign(url), '_blank');
  }

  //Downloads .ics file for use by external calendars with a link in Email
  getUrlForIcsFile(matterId: number, inviteGuid: number): string {
    let documentUrl: string = '';
    if (inviteGuid != null) {
      let loc = window.location;
      let url: string = eventApi.downloadIcsFile(String(matterId), inviteGuid);
      documentUrl = loc.protocol + '//' + loc.host + url;
    }
    return documentUrl;
  }

  getDocuSignUrl(matterId: number, eventId: number): Observable<string> {
    let url: string = eventApi.docuSignPortalUrl(String(matterId), eventId);
    return this.http.get(url).map((res) => {
      return res[ 'SigningEnvelopeViewUrl' ];
    });
  }

  getSyngrafiiUrl(matterId: number, eventId: number): Observable<string> {
    let url: string = eventApi.syngrafiiEditLink(String(matterId), eventId);
    return this.http.get(url).map((res) => {
      return res[ 'SyngrafiiEditLink' ];
    });
  }

  getEvent(eventId: number, accountId: string): Observable<EventData> {
    let url: string = eventApi.event(String(accountId), String(eventId));
    return this.http.get(url).map((res) => {
      return new EventData(res[ 'MatterEvent' ]);
    });
  }

  getMatterEvent(eventId: number, matterId: number): Observable<EventData> {
    let url: string = eventApi.matterEvent(String(matterId), String(eventId));
    return this.http.get(url).map((res) => {
      return new EventData(res[ 'MatterEvent' ]);
    });
  }

  getSyngrafiiDownloadLinks(matterId: number, eventId: number): Observable<string[]> {
    let url: string = eventApi.syngrafiiVideoLinks(String(matterId), eventId);
    return this.http.get(url).map((res) => {
      return res[ 'SyngrafiiDownloadLinks' ];
    });
  }

  getSyngrafiiDefaultConfiguration(accountId: string, accountIntegrationCredentialId: number): Observable<SyngrafiiAccountConfiguration> {
    let url: string = thirdPartyIntegrationApi.getSyngrafiiAccountConfiguration(accountId);
    if (accountIntegrationCredentialId) {
      url += '?accountIntegrationCredentialId=' + accountIntegrationCredentialId;
    }

    return this.http.get(url).map((res) => {
      return res[ 'SyngrafiiAccountConfiguration' ];
    });
  }

  getEventByStaff(accountId: string, solicitorId: number, startDate: string, endDate: string, eventType: string[], status: string[]): Observable<EventData[]> {
    let url: string = eventApi.eventByStaff(accountId, solicitorId);
    if (startDate) {
      url += '?startDate=' + startDate;
    }
    if (endDate) {
      url += '&endDate=' + endDate;
    }
    if (eventType && eventType.length > 0 && !eventType.some(item => item == 'ALL')) {
      if (eventType.indexOf('CLOSING!OCCUPANCY') > -1) {
        let eventTypeFilters = eventType.filter(item => item != 'CLOSING!OCCUPANCY');
        eventTypeFilters.push('CLOSING');
        eventTypeFilters.push('OCCUPANCY');
        url += '&eventTypes=' + eventTypeFilters;
      } else {
        url += '&eventTypes=' + eventType;
      }

    }
    if (Array.isArray(status)) {
      if (status.indexOf('ALL') > -1 || status.length === 0) {
        //No additional value needed
      } else {
        url += `&derivedFilterStatusValue=${ status }&overDueDate=${ SharedUtils.escapeSearchText(moment(new Date()).format('YYYY/MM/DD')) }`;
      }
    }
    return this.http.get(url).map((res) => {
      return res[ 'MatterEvents' ];
    });
  }

  getTotalTransactionCount(filterSolicitor: string[], filterLawClerk: string[],
                           filterEventType: string[], filterMatterType: string[],
                           filterProvince: string[], filterStatuses: string[],
                           filterStartDate: Date, filterEndDate: Date,
                           filterMatterId?: string,
                           pagination?: boolean, page?: number, perPage?: number, selectedProjects?: string[], includeMatterSubNodes = true): Observable<TotalTransactionCount> {
    let url: string = this.getEventsURL(false, filterSolicitor, filterLawClerk, filterEventType, filterMatterType, filterProvince, filterStatuses,
      filterStartDate, filterEndDate, filterMatterId, pagination, page, perPage, selectedProjects, includeMatterSubNodes);

    //If mass update mode enabled then no need to load matters events which is done in component initialization
    if (this.http.isMassUpdateModeEnabled()) {
      return Observable.of(null);
    } else {
      return this.http.get(url)
      .map((response) => {
        return new TotalTransactionCount(response[ 'TotalTransactionCount' ]);
      });
    }
  }

}

