<div [dp-read-only-block]="!profileEditable" [elementRefComp]="elementRef">
    <dp-new-manage-contact [contact]="contact"
                           [participant]="matterParticipant"
                           [matter]="matter"
                           [isEditable]="profileEditable"
                           [closeOtherInfoShutter]="true"
                           (onDataChange)="emitDataChange($event);"
                           (primaryAddressChanged)="emitDataChange($event);"
                           (onSuggestionsChange)="emitEnvelopeChange()"
                           [fieldPrefix]="fieldPrefix"
                           [fieldIndexId]="fieldIndexId"
                           [containerId]="containerId"
                           [fieldIdentifier]="fieldIdentifier"
                           contactKey="CLIENT">
    </dp-new-manage-contact>
</div>

<ng-container *dpShowByProvince="'matter.matterParticipant.titleDeedName'">
    <div class="col-lg-12"  *ngIf="isTitleDeedNameVisible()" >
        <dp-accordion [expanded]="expanded" [hideShutter]="!matterParticipant.titleDeed.isManuallyEnter()"  >
            <div class="dp-accordion-header">
                <div class="row  row-group padding-left-70" (click)="stopPropagateClickEvent($event)">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Title Deed Name</label>
                    </div>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="titleDeedNameType"
                                name="titleDeedNameType"
                                [(ngModel)]="matterParticipant.titleDeed.nameType"
                                (ngModelChange)="openTdnShutterOnManualEnter()"
                        >
                            <option *ngFor="let titleDeedNameType of titleDeedNameTypeOptions"
                                    [value]="titleDeedNameType.value">
                                {{titleDeedNameType.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-6" >
                        <input type="text"
                               id="fullTitleDeedName{{matterParticipant.matterParticipantId}}"
                               name="fullTitleDeedName{{matterParticipant.matterParticipantId}}"
                               class="form-control text-overflow" readonly
                               [ngModel]="getTitleDeedName()"/>
                    </div>
                </div>
            </div>
            <div class="dp-accordion-content">
                <div class="padding-left-75" *ngIf="matterParticipant.titleDeed.isManuallyEnter()">
                    <div class="form-group" >
                        <div class="col-lg-2 text-right">
                            <label class="control-label">Surname*</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text"
                                   trimmed
                                   id="lastName_{{matterParticipant.matterParticipantId}}"
                                   name="lastName_{{matterParticipant.matterParticipantId}}"
                                   [(ngModel)]="matterParticipant.titleDeed.surName"
                                   (ngModelChange)="upperCaseTitleDeedSurname(matterParticipant)"
                                   (keydown.f9)="populateTitleDeedSurname(matterParticipant)"
                                   statusBarHelp
                                   [fieldKey]="'matter.mainClient.titleDeedName'"
                                   dp-error-validator
                                   class="form-control"
                                   maxlength="100"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-2 text-right">
                            <label class="control-label">First Name</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text"
                                   trimmed
                                   id="firstName_{{matterParticipant.matterParticipantId}}"
                                   name="firstName"
                                   [(ngModel)]="matterParticipant.titleDeed.firstName"
                                   (ngModelChange)="onChange()"
                                   maxlength="50"
                                   class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-2 text-right">
                            <label class="control-label">Middle Name</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text"
                                   trimmed
                                   id="middleName_{{matterParticipant.matterParticipantId}}"
                                   name="middleName"
                                   [(ngModel)]="matterParticipant.titleDeed.middleName"
                                   (ngModelChange)="onChange()"
                                   maxlength="50"
                                   class="form-control"/>
                        </div>
                    </div>
                </div>

            </div>
        </dp-accordion>
    </div>
</ng-container>

<div class="form-group" *ngIf="!matter?.isOpportunityMatter() && !!matter && !this.matterParticipant.isSigningOfficer">
    <div class="col-lg-12">
        <div class="col-lg-1 padding-right-0 text-right">
            <dp-checkbox fieldId="overrideDefaultSigningLocationFlag_{{index}}">
                <input type="checkbox"
                       name="overrideDefaultSigningLocationFlag_{{index}}"
                       id="overrideDefaultSigningLocationFlag_{{index}}"
                       [(ngModel)]="matterParticipant.overrideDefaultSigningLocationFlag"
                       (ngModelChange)="onChange()"
                       [checked]="matterParticipant.overrideDefaultSigningLocationFlag"/>
            </dp-checkbox>
        </div>
        <div class="col-lg-11">
            <label class="control-label-snapshot">
                Override of the default signing location of documents as specified in Matter Opening topic. {{showPurchaserExecDocsAt()}}
            </label>
        </div>
    </div>
</div>

<div *ngIf="matterParticipant.overrideDefaultSigningLocationFlag && matterParticipant.contact && matterParticipant.contact.isBlankMaleFemale && matterParticipant.signingLocations.length > 0" class="fields-group">
   <dp-signing-location [signingLocation]="matterParticipant.signingLocations[0]">
   </dp-signing-location>
</div>

