import {ErrorService} from '../../shared/error-handling/error-service';
import {Injectable} from '@angular/core';
import {Matter} from '../../matters';
import {DPError, ErrorType} from '../../shared/error-handling/dp-error';
import {Mortgage} from '../../matters/shared/mortgage';
import {ContactNameUtil} from '../../matters/shared/contact-name-util';
import {ErrorDirectiveMapping} from '../../shared/error-handling/error-directive-mapping';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyDataValidationService {

  constructor(protected errorService: ErrorService) {
  }

  addDataValidationError(errorKey: string) {
    if (errorKey) {
      this.errorService.addDpMissingFieldError(DPError.createDPError(errorKey), true);
    }
  }

  addDataValidationErrorWithCustomTopic(errorKey: string, customTopic: string) {
    if (errorKey) {
      const errorDef = ErrorDirectiveMapping[ errorKey ];
      if (errorDef) {
        this.addCustomDataValidationError(errorKey, errorDef.ERROR_MESSAGE, customTopic, errorDef.ERROR_TYPE);
      } else {
        this.errorService.addDpMissingFieldError(DPError.createDPError(errorKey), true);
      }
    }
  }

  addCustomDataValidationError(errorElementKey: string, errorMessage: string, errorTopic: string, errorType: ErrorType) {
    if (errorElementKey) {
      this.errorService.addDpMissingFieldError(DPError.createCustomDPError(errorElementKey, errorMessage, errorTopic, errorType), true);
    }
  }

  validatePropertyPostalCode(matter: Matter) {
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && !matter.matterPropertyWithCondo.address.postalCode) {
      this.addDataValidationError('integrations.stewartTitle.propertyPostalCode');
    }
  }

  validatePropertyCity(matter: Matter) {
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && !matter.matterPropertyWithCondo.address.city) {
      this.addDataValidationError('integrations.stewartTitle.propertyCity');
    }
  }

  validatePropertyProvince(matter: Matter) {
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && (!matter.matterPropertyWithCondo.address.provinceName || !matter.matterPropertyWithCondo.address.isProvinceValid)) {
      this.addDataValidationError('integrations.stewartTitle.propertyProvince');
    }
  }

  validatePropertyStreet(matter: Matter) {
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && !matter.matterPropertyWithCondo.address.addressLine1) {
      this.addDataValidationError('integrations.stewartTitle.propertyAddress');
    }
  }

  validateMatterCloseDate(matter: Matter) {
    if (!matter.isClosingDateAvailable()) {
      if (matter.isMatterTypeDischarge) {
        this.addDataValidationError('integrations.discharge.stewartTitle.closingDate');
      } else {
        if ((matter.isMatterProvinceMB || matter.isMatterProvinceSK) && matter.isSale) {
          this.addDataValidationError('integrations.stewartTitle.possessionDate');
        } else {
          this.addDataValidationError('integrations.stewartTitle.closingDate');
        }
      }

    }
  }

  mortgageTopic(matter: Matter, mortgage?: Mortgage): string {
    if (mortgage && mortgage.isExistingMortgage()) {
      return 'Existing Mortgage(s)';
    } else {
      return matter.isPurchase ? 'Mortgages' : 'New Mortgage';
    }
  }

  ordinalSuffix(ordinal: number): string {
    return (ordinal === 1 ? 'st' : ordinal === 2 ? 'nd' : ordinal === 3 ? 'rd' : 'th');
  }

  isFirstNamePresent(name: string): boolean {
    const names: string[] = ContactNameUtil.parseLastFirstMiddleNames(name);
    return (names.length > 0 && names[ 0 ]) ? true : false;
  }

  isLastNamePresent(name: string): boolean {
    const names: string[] = ContactNameUtil.parseLastFirstMiddleNames(name);
    return (names.length > 1 && names[ 1 ]) ? true : false;
  }

  purchasersMortgagorsTopic(matter: Matter): string {
    return matter.isPurchase ? 'Purchasers' : (matter.isMortgage ? 'Mortgagors' : 'Unknown');
  }

  solicitorActingFor(matter: Matter) {
    return matter && matter.actingFor == 'MORTGAGOR' ? 'mortgagor' : 'mortgagee';
  }

  isNewHomeFromBuilder(matter: Matter): boolean {
    return matter && matter.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome();
  }

  vendorOrMortgagor(matter: Matter): string {
    return matter.isSale ? 'Vendor' : (matter.isMortgage ? 'Mortgagor' : 'Unknown');
  }

  vendorsMortgagorsTopic(matter: Matter): string {
    return matter.isSale ? 'Vendors' : (matter.isMortgage ? 'Mortgagors' : 'Unknown');
  }

  propertyTypeIdentifier(matter: Matter): string {
    switch (matter.provinceCode) {
      case 'ON':
        return 'PIN';
      case 'AB':
        return 'LINC';
      case 'SK':
        return 'Title Number';
      case 'MB':
        return 'Title Number';
      case 'NB':
        return 'PID Number';
      case 'NS':
        return 'PID Number';
      case 'BC' :
        return 'PID Number';
      default:
        return '';
    }
  }

}
