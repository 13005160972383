<!-- modal -->
<style type="text/css">
    /*
    we use an inline print stylesheet to hide/show certain elements on the printed page
    */
    @media print {
        .modal-header {
            display:none;
        }
        .modal-body {
            max-height: none !important;
            height: auto !important;
        }

        .modal-footer {
            display:none;
        }
        .print-height {
            height: 100px;
        }

        .td-45{
            width: 45% !important;
        }
        .td-55 {
            width: 55% !important;
        }

    }

    .notice-template .clear {
        clear: both;
    }

    .notice-template .width-50-per {
        width: 50%;
    }
    .notice-template .width-25-per {
        width: 25%;
    }
    .notice-template .width-75-per {
        width: 75%;
    }

    .notice-template .width-30-per {
        width: 30%;
    }
    .notice-template .width-70-per {
        width: 70%;
    }
    .notice-template .width-80-per {
        width: 80%;
    }

    .notice-template .width-100-per {
        width: 100%;
    }

    .notice-template .padding-bottom-10 {
        padding-bottom: 10px;
    }

    .notice-template .padding-10 {
        padding: 10px;
    }
    .notice-template .vertical-align-top {
        vertical-align: top;
    }
    .notice-template .bold {
        font-weight: bold;
    }

    .notice-template .normal {
        font-weight: normal;
    }

    .notice-template .margin-top-5 {
        margin-top: 5px;
    }

    .notice-template .margin-bottom-20 {
        margin-bottom: 20px;
    }

    .notice-template .DPPM-logo { width: 200px; }

    .notice-template .print-table {
        width: 100%; border: 2px solid #000; display: table;
    }

    .notice-template .print-table-left-cell {
        display:table-cell; width: 50%; height: 100%; border-right: 1px solid #000; padding: 2%; vertical-align: top;
    }

    .notice-template .print-table-right-cell {
        display:table-cell; width: 50%; padding: 2%; height: 100%; vertical-align: top;
    }
    .notice-template .print-table-left-address-cell {
        width: 100%; background: #fff; border-top: 1px solid #666; height: 1px; margin: 10px 0 10px 0;
    }
    .notice-template .print-table-cell-to {
        width: 15%; padding-bottom: 10px; vertical-align: top;
    }
    .notice-template .print-table-cell-to-address {
        width: 85%; padding-bottom: 10px;
    }

    .notice-template .print-table-body {
        width: 100%; border: 2px solid #000; display: table;  margin-top:10px;
    }

    .notice-template .gray-line {
        width: 100%; background: #fff; border-top: 1px solid #666; height: 1px; margin: 10px 0 10px 0;
    }

    .notice-template .border-right {
        border-right: 1px solid #000;
    }

    .notice-template .border-bottom {
        border-bottom: 1px solid #000;
    }
    .notice-template .text-center {
        text-align: center;
    }
    .notice-template .text-right {
        text-align: right;
    }

    .notice-template .border-td {
        width: 50%; background: #fff; border-top: 1px solid #666; height: 1px;  margin: 0 0 0 auto;
    }

    .notice-template .border-top-white { ; border-top: 1px solid #fff; }
    .notice-template .border-top-black { ; border-top: 1px solid #000; }
    .notice-template .vertical-align-bottom {
        vertical-align: bottom;
    }

    .notice-template .padding-left-5 {
        padding-left: 5px;
    }
    .notice-template .padding-10-5 {
        padding: 10px 10px 10px 5px;
    }

    .hst-num {
        margin: 100px 0 0px 0;
        padding: 10px;
    }

</style>
<div class="form-horizontal" dp-printable-dialog>
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{noticeType.charAt(0).toUpperCase() + noticeType.slice(1).toLowerCase() + ' Notice'}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
                <div class="col-lg-12 notice-template">
                    <div class="margin-bottom-20"><img src="assets/img/Unity_RGB_app_72ppi.png" class="DPPM-logo" /></div>
                    <!-- table header starts -->
                            <div class="print-table">
                                <div class="print-table-left-cell">
                                    <table class="width-100-per">
                                        <tr><td
                                             class="td-45 width-25-per padding-bottom-10"><b class="bold">{{noticeType.charAt(0).toUpperCase() +
                                            noticeType.slice(1).toLowerCase() + ' Notice No.:'}}</b></td><td
                                            class="td-55 width-75-per padding-bottom-10">{{getMatterTypeShortName(matter.matterType)}}
                                            {{matter.matterRecordNumber
                                            }}</td></tr>
                                        <tr><td><b class="bold">Date: </b></td><td>{{orderDate}}</td></tr>
                                    </table>
                                    <div class="print-table-left-address-cell"></div>
                                    <table class="width-100-per">
                                        <tr><td class="print-table-cell-to"><b class="bold">To:</b></td><td
                                             class="print-table-cell-to-address">
                                            <p *ngIf="account.firmName">{{account.firmName}}</p>
                                            <p *ngIf="account.billingAddress.addressLine1">{{account.billingAddress.addressLine1}}</p>
                                            <p *ngIf="account.billingAddress.addressLine2">{{account.billingAddress.addressLine2}}</p>
                                            <p
                                                *ngIf="account.billingAddress.city && account.billingAddress.provinceName">{{account.billingAddress.city}},
                                                {{account.billingAddress.provinceName}}</p>
                                            <p>{{account.billingAddress.postalCode}}</p>
                                            <p *ngIf="account.billingAddress.country !='Canada'">{{account.billingAddress.country}}</p>
                                        </td></tr>

                                    </table>
                                </div>
                                <div class="print-table-right-cell">
                                    <table class="width-100-per">
                                        <tr><td class="padding-bottom-10" colspan="2"><b class="bold">Transaction Information</b></td></tr>
                                        <tr><td class="width-25-per" ><b class="bold">Order No.: </b></td><td class="width-75-per">{{orderNumber
                                            }}</td></tr>
                                    </table>
                                    <div class="gray-line"></div>
                                    <table class="width-100-per">
                                        <tr><td  class="width-25-per padding-bottom-10 vertical-align-top"><b class="bold">Account Name:</b></td><td
                                            class="width-75-per padding-bottom-10 vertical-align-top" *ngIf="account.firmName">{{account.firmName}}</td></tr>
                                        <tr><td class="width-25-per padding-bottom-10 vertical-align-top"><b class="bold">Program:</b></td><td
                                            class="width-75-per padding-bottom-10 vertical-align-top">Unity</td></tr>
                                        <tr><td class="width-25-per padding-bottom-10 vertical-align-top"><b class="bold">Re:</b></td><td
                                            class="width-75-per padding-bottom-10 vertical-align-top"><p><span>{{matter.clientReLine}}</span> <span
                                            *ngIf="vendorReLine!=''">{{reType}}</span>
                                            <span>
                                            {{vendorReLine}}</span></p>
                                        <p *ngIf="matter.matterProperties[0] && matter.matterProperties[0].address && matter.matterProperties[0].address
                                            .addressTextWithoutProvinceAndPostalCode">{{matter.matterProperties[0].address.addressTextWithoutProvinceAndPostalCode}}</p>
                                            <p>Your File No: {{matter.fileNumber}}</p>
                                        </td></tr>

                                    </table>

                                </div>
                                <div class="clear"></div>
                            </div>
                    <!-- table header ends -->
                    <!-- table body starts -->
                    <div class="print-table-body">
                        <table class="width-100-per">
                            <tr><th
                                class="width-70-per padding-10 border-bottom border-right"><b class="bold">
                                Description</b></th><th
                              class="width-30-per padding-10 border-bottom text-center"><b
                                class="bold">
                                Amount</b></th></tr>
                            <tr><td class="width-70-per padding-10  border-right"><p class="width-80-per">Your Unity account has been
                                {{noticeType.toLowerCase()}}ed in the sum of ${{subTotal}} plus applicable taxes in relation to the
                                above matter.</p>
                             </td><td class="padding-10 width-30-per text-right">${{subTotal}}</td></tr>
                            <tr><td  class="padding-10 width-70-per border-right">
                            </td><td
                                class="padding-10 width-30-per text-right">
                                <div class="border-td"></div>
                            </td></tr>
                            <tr><td class="width-70-per padding-10 border-right">Transaction
                                Charge</td><td class="width-30-per padding-10 text-right">${{ subTotal }}</td></tr>
                            <tr  *ngIf="billingInfo?.federalTax && billingInfo?.federalTax>0"><td class="width-70-per padding-10 border-right">HST/GST</td><td
                                class="width-30-per padding-10 text-right">${{billingInfo?.federalTax.toFixed(2)}}</td></tr>
                            <tr *ngIf="billingInfo?.provincialTax && billingInfo?.provincialTax>0"><td class="width-70-per padding-10 border-right">Provincial
                                Tax</td><td class="width-30-per padding-10 text-right">${{billingInfo?.provincialTax.toFixed(2)}}</td></tr>
                            <tr class="print-height"><td  class="width-70-per border-right  border-top-white padding-left-5 vertical-align-bottom">
                                <table  class="width-100-per"><tr><td
                                    class="width-50-per border-top-black padding-10-5 vertical-align-bottom"><b
                                    class="bold">Total
                                </b></td><td class="width-50-per">
                                    &nbsp;</td></tr></table>
                                </td><td class="width-30-per text-right border-top-white border-bottom vertical-align-bottom"><table
                                class="width-100-per"><tr><td class="width-100-per border-top-black padding-10"><b
                                class="normal">${{transactionCharge}}
                            </b></td></tr></table></td></tr>
                            <tr><td class="width-70-per padding-10 border-right border-bottom vertical-align-top"
                            ></td><td  class="width-30-per padding-10"><p>This is not an invoice.<br>Do not remit payment</p></td></tr>
                        </table>
                        <div class="hst-num"><h6>HST No. 80799 5055 RT0001</h6></div>
                    </div>
                    <!-- table body ends -->
                    <p class="text-center margin-top-5">25 York Street. 11th Floor, Toronto, Ontario M5J 2V5</p>
                    <p class="text-center">Fax: (416) 322-6106 &#x25CF; Toll Free 1-866-367-7648 &#x25CF; email: unity.support@dyedurham.com &#x25CF; website: www.dyedurham.ca
                    </p>
                    </div>


    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons  margin-top-20">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn">
                <span>OK</span>
            </button>

        </div>

    </div>
    </div>
