export class Province {

  name: string;
  code: string;
}

export const provinces: Province[] = [
  {name: 'Ontario', code: 'ON'},
  {name: 'Quebec', code: 'QC'},
  {name: 'Nova Scotia', code: 'NS'},
  {name: 'New Brunswick', code: 'NB'},
  {name: 'Manitoba', code: 'MB'},
  {name: 'British Columbia', code: 'BC'},
  {name: 'Prince Edward Island', code: 'PE'},
  {name: 'Saskatchewan', code: 'SK'},
  {name: 'Alberta', code: 'AB'},
  {name: 'Newfoundland and Labrador', code: 'NL'}
];

export const PROVINCE_NAMES = {
  ONTARIO: 'Ontario',
  QUEBEC: 'Quebec',
  NOVA_SCOTIA: 'Nova Scotia',
  NEW_BRUNSWICK: 'New Brunswick',
  MANITOBA: 'Manitoba',
  BRITISH_COLOMBIA: 'British Columbia',
  PRINCE_EDWARD: 'Prince Edward Island',
  SASKATCHEWAN: 'Saskatchewan',
  ALBERTA: 'Alberta',
  NEWFOUNDLAND_LABRADOR: 'Newfoundland and Labrador'
};