<div>
    <div *ngIf="events && events.length>0 && !loading || calendarAlwaysVisible">
            <mwl-calendar-month-view
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (beforeViewRender)="beforeMonthViewRender($event)"
                [cellTemplate]="customCellTemplate"
            >
            </mwl-calendar-month-view>
        <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div tabindex="0" class="cal-div" >
                <div *ngIf="showHoliday">
                    <span class="smalltext red bold" >
                                {{getHoliday(day.date)}}
                    </span>
                </div>
                <div class="{{printView ? '' : 'cal-cell-top'}}">
                        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0 && !printView">{{ day.badgeTotal }}</span>
                        <span class="cal-day-number {{isCurrentSelectedDaySameAsCalendarDay(viewDate, day.date) ? 'current-selected-day' : ''}} {{isHoliday(day.date) ? 'public-holiday' : ''}}">
                            {{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                </div>
                <div class="cell-totals" *ngIf="!printView && day.eventGroups.length>0">
                        <span *ngFor="let group of day.eventGroups" class="badge event-type-color-{{ group[0]?.toLowerCase() }}">{{ group[1].length }}</span>
                </div>
                <div *ngIf="printView && day.events.length>0">
                    <div *ngFor="let e of day.events">
                        <span class="smalltext" *ngIf="e.meta && e.meta.printTitle">
                                {{e.meta.printTitle}}
                        </span>
                    </div>
                </div>

            </div>

        </ng-template>
    </div>

    <div>
        <table class="table matter-table event-table"  tabindex="-1">
            <tr class="loading-row" *ngIf="loading">
                <td colspan="11" class="text-center">
                    <div id="spinner">
                        <img src="assets/img/spinner.gif"/>
                    </div>
                </td>
            </tr>
            <tr  class="loading-row" *ngIf="!loading && events && events.length==0">
                    <td colspan="11" class="text-center">
                        <div class="no-match-found">No Match Found</div>
                    </td>
             </tr>
        </table>
    </div>
</div>
