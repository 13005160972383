<!-- modal -->
<form class="form-horizontal">
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1>Document Production Error</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="padding-bottom-10 padding-left-10">
            <label class="control-label">Document(s) can not be produced at this time. The following issues prevented production:</label>
        </div>
        <ul>
            <li *ngFor="let error of context.errors">{{error}}</li>
        </ul>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>OK</span>
            </button>
        </div>
    </div>

</form>
