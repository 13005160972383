import {StewartTitleService} from '../../../shared-main/stewart-title/stewart-title-service';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class TitlePlusPortalContext {
  url: string;
}

@Component({
  selector: 'dp-title-plus-portal-modal',
  templateUrl: 'title-plus-portal.modal.component.html'
})
export class TitlePlusPortalModalComponent extends ModalComponent<TitlePlusPortalContext> implements OnInit {
  titlePlusPortalURL: SafeUrl;
  @ViewChild('titlePlusFrame') titlePlusFrame: ElementRef;

  constructor(
    public dialog: MatDialogRef<TitlePlusPortalModalComponent>,
    public sanitizer: DomSanitizer,
    public stewartTitleService: StewartTitleService,
    @Inject(MAT_DIALOG_DATA) context?: TitlePlusPortalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.titlePlusPortalURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.url);
  }

  close(): void {
    this.dialog.close({action: 'Close'});
  }
}
