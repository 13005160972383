import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../../shared';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';

import {TeranetDocket} from '../../../../shared-main/teranet/teranet-docket';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {ApplicationError, FieldError} from '../../../../core/application-error';
import {
  TeranetViewDocketSummaryRequest,
  TeranetViewDocketSummaryResponse
} from '../../../../shared-main/teranet/teranet-view-docket-summary';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {IMyDate} from '@nodro7/angular-mydatepicker';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class GetTeranetDocketSummaryContext {
  teranetDocket: TeranetDocket;
  matter: Matter;
  teranetUser: TeranetUser;
}

class DateRange {
  constructor(
    public startDate?: Date,
    public endDate?: Date,
    public title?: string
  ) {}
}

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-get-teranet-docekt-summary-modal',
  templateUrl: 'get-teranet-docket-summary.modal.component.html',
  providers: [ErrorService]
})
export class GetTeranetDocketSummaryModalComponent
  extends ModalComponent<GetTeranetDocketSummaryContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  selectedSummaryDate: DateRange = new DateRange();
  summaryDate: DateRange[];
  otherSummaryDate: boolean = false;
  disableDateUntil: IMyDate;
  startDateMissing: boolean = false;
  endDateMissing: boolean = false;

  constructor(
    public dialog: MatDialogRef<GetTeranetDocketSummaryModalComponent>,
    public teranetService: TeranetService,
    @Inject(MAT_DIALOG_DATA) context?: GetTeranetDocketSummaryContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    let today = new Date();
    let option1: DateRange = new DateRange(today, today, 'Today');

    let option2: DateRange = new DateRange(
      new Date(this.context.matter.fileOpenDate),
      today,
      'Since File Opening Date'
    );

    let d1 = new Date();
    d1.setDate(d1.getDate() - 364); //364 days back
    let option3: DateRange = new DateRange(d1, today, 'In Past Year');

    let d2 = new Date();
    d2.setFullYear(d2.getFullYear() - 2); //two years back
    d2.setDate(d2.getDate() + 1);
    let d3 = new Date();
    d3.setFullYear(d3.getFullYear() - 1); //one year back
    let option4: DateRange = new DateRange(d2, d3, 'In Prior Year');

    this.summaryDate = [];
    this.summaryDate.push(option1, option2, option3, option4);

    this.disableDateUntil = {
      year: today.getFullYear() - 2,
      month: today.getMonth() + 1,
      day: today.getDate()
    };
  }

  onDateChange(event, flag: number): void {
    if (event.rawDate) {
      if (flag) {
        this.selectedSummaryDate.endDate = new Date(event.rawDate);
      } else {
        this.selectedSummaryDate.startDate = new Date(event.rawDate);
      }
    }
  }

  selectOwnDate(): void {
    this.otherSummaryDate = true;
    this.selectedSummaryDate = new DateRange();
  }

  isProceedActive(): boolean {
    return this.otherSummaryDate || (!this.otherSummaryDate && this.selectedSummaryDate.startDate != undefined);
  }

  isDateValid(): boolean {
    if (this.otherSummaryDate) {
      if (this.selectedSummaryDate.startDate == undefined) {
        this.startDateMissing = true;
        this.modalErrorComponent.createDPFieldError('matter.teranetConnect.viewDocketSummary.startDate');
        return false;
      }
      if (this.selectedSummaryDate.endDate == undefined) {
        this.endDateMissing = true;
        this.modalErrorComponent.createDPFieldError('matter.teranetConnect.viewDocketSummary.endDate');
        return false;
      }
      if (this.selectedSummaryDate.endDate < this.selectedSummaryDate.startDate) {
        this.modalErrorComponent.createDPFieldError('matter.teranetConnect.viewDocketSummary.otherDate');
        return false;
      }
      if (this.selectedSummaryDate && this.selectedSummaryDate.startDate && this.selectedSummaryDate.endDate) {
        //Get 1 day in milliseconds
        let one_day = 1000 * 60 * 60 * 24;
        let diffDays =
          (this.selectedSummaryDate.endDate.getTime() - this.selectedSummaryDate.startDate.getTime()) / one_day;
        if (diffDays > 364) {
          this.modalErrorComponent.createDPFieldError('matter.teranetConnect.viewDocketSummary.otherDate.INVALID');
          return false;
        }
      }
    }
    return true;
  }

  proceed() {
    this.modalErrorComponent.removeAllDpFieldError();
    this.startDateMissing = false;
    this.endDateMissing = false;
    if (this.isDateValid()) {
      //Call the API
      let teranetViewDocketSummaryRequest: TeranetViewDocketSummaryRequest = new TeranetViewDocketSummaryRequest();
      teranetViewDocketSummaryRequest.matterId = this.context.teranetDocket.matterId;
      teranetViewDocketSummaryRequest.matterDocketId = this.context.teranetDocket.matterDocketId;
      teranetViewDocketSummaryRequest.docketSystemId = this.context.teranetDocket.docketSystemId;
      teranetViewDocketSummaryRequest.teranetUser = this.context.teranetUser;
      teranetViewDocketSummaryRequest.startDate = this.selectedSummaryDate.startDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '/');
      teranetViewDocketSummaryRequest.endDate = this.selectedSummaryDate.endDate
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, '/');
      console.log(teranetViewDocketSummaryRequest);
      this.teranetService.viewDocketSummary(teranetViewDocketSummaryRequest).subscribe(
        (res: TeranetViewDocketSummaryResponse[]) => {
          console.log(res);
          this.dialog.close({
            docketSummary: res,
            startDate: this.selectedSummaryDate.startDate,
            endDate: this.selectedSummaryDate.endDate
          });
        },
        (error: ApplicationError) => {
          this.handleApiError(error);
        }
      );
    }
  }

  handleApiError(error: ApplicationError) {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  close() {
    this.dialog.close();
  }

  ngAfterViewInit() {}
}
