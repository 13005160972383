import {api} from '../common/api';

export const adjudicationApi = {

  adjudicationRequests: `${ api }/adjudicationRequests`,
  contactForAdjudication(contactId: number): string {
    return `${ api }/contacts/${ contactId }/contactForAdjudication`;
  },
  dealAdjudication(adjudicationRequestsId: number): string {
    return `${ api }/adjudicationRequests/${ adjudicationRequestsId }`;
  },
  getAdjudication(adjudicationLawFirmId: number): string {
    return `${ api }/adjudicationRequests/contacts/${ adjudicationLawFirmId }/latest`;
  }
};
