<form class="form-horizontal" id="manageThirdPartyForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div style="float: left">
                    <h1>My Integration Credentials</h1>
                </div>
                <div style="float: right">
                    <button style="margin-top: -2px" type="button" class="btn-close pull-right" (click)="close()">&times;</button>
                </div>
            </div>


        </div>


        <div class="modal-body">
            <div class="col-lg-2"  *ngIf="!context.key">
                <div class="col-lg-12 left-nav integration-left-nav" tabindex="-1">
                    <ul>
                        <li *ngFor="let integrationSection of integrationSections"
                            [user-access]="{'operationName': integrationSection.authOperation}"
                            class="{{ (integrationSection.active ? 'active': 'inactive')}}">
                            <a (click)="openIntegrationSection(integrationSection)" innerHtml="{{integrationSection.title}}"></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="col-lg-12 ereg-header text-black">
                    <span *ngIf="activeIntegrationSection" innerHtml="{{activeIntegrationSection.title}}"></span>
                </div>
                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isTeranetConnect()">
                    <div class="col-lg-12 text-right padding-top-10 padding-bottom-10">
                        <a id="teranetLinkMyTeranet" class="padding-right-10" href="{{links.myTeranetConnect}}" target="_blank" (keydown)="linkMyTeranetKeyCommands($event)">www.myteranetconnect.ca</a>
                        <a class="padding-right-10" href="{{links.faq}}" target="_blank">Teranet Connect &trade; FAQ</a>
                        <a href="{{links.fees}}" target="_blank">Teranet Connect &trade; Rate Sheet</a>

                    </div>
                    <form class="form-horizontal col-lg-12">
                        <div class="form-group">
                            <div class="col-lg-12 text-right">
                                <button label="Add New" #btnAddNew class="dp-btn-green" (click)="addTeranetUser()" (keydown.tab)="updateRowIndex(0)">
                                    <span>Add New</span>
                                    <div class="clear"></div>
                                </button>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div>
                            <div>
                                <table class="table dp-teranet-table margin-bottom-100" tabindex="-1" (keydown)="onKeyPress($event)">
                                    <thead>
                                    <tr>
                                        <th>Teranet Account Name</th>
                                        <th>Teranet Login Name</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of teranetUsers; let i=index;" tabindex="0"
                                        [ngClass]="{'active': isRowSelected(i), 'dp-teranet-row-active': isRowSelected(i)}"
                                        (click)="updateRowIndex(i)" (keydown.tab)="updateRowIndex(i)">
                                        <td>
                                            <div>
                                                {{item.teranetAccountName}}
                                            </div>
                                        </td>
                                        <td style="border-left: 1px #dddddd;">
                                            <div>
                                                {{item.teranetLoginName}}
                                            </div>
                                        </td>
                                        <td style="text-align: right">
                                            <dp-burger-menu style="display:inline-block;" [items]="burgerMenuItems"
                                                            [openSubMenuOnLeft] = true
                                                            (itemClicked)="burgerMenuClicked($event, i , item)"
                                                            (menuOpened)="updateRowIndex(i)"
                                                            (keydown.tab)="moveToNextRow()"
                                            ></dp-burger-menu>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isStewartTitle()">
                    <div class="col-lg-12 padding-top-10 padding-bottom-10" >
                        <div class="col-lg-12 text-right padding-top-10 padding-bottom-10">
                            <a id="stewartTitleLink"
                               title="Stewart Title is a leading provider of title insurance products for residential and commercial transactions. We partner with legal professionals to streamline their practices and provide peace of mind to their clients."
                               class="padding-right-10"
                               href="{{localStewartTitleLinks.stewartTitleRequestInfo}}"
                               target="_blank">Register</a>
                        </div>
                        <div class="form-group" *ngIf="!currentUserProfile.isLawyer && currentStewartTitleUser && !currentStewartTitleUser.id">
                            <div class="col-lg-12 margin-top-30">
                                <p>
                                    <span>Click </span> <a id="addStgUserLink" tabindex="0" (keydown.Enter)="addStewartTitleUser()"
                                                    (click)="addStewartTitleUser()">here</a> <span> to associate and save your Stewart Title credentials</span>
                                </p>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!currentUserProfile.isLawyer && currentStewartTitleUser && currentStewartTitleUser.id">
                            <div class="col-lg-12">
                                <ng-container *ngIf="currentUserProfile && !currentUserProfile.isLawyer">
                                    <label class="control-label col-lg-3">Credentials Associated</label>
                                </ng-container>

                                <div class="col-lg-4">
                                    <button type="button" class="dp-btn dp-btn-cancel"  (click)="addStewartTitleUser()">
                                        <span>Re-Validate</span>
                                    </button>
                                    <ng-container *ngIf="currentUserProfile && !currentUserProfile.isLawyer">
                                        <button type="button" class="dp-btn dp-btn-cancel"  (click)="deleteStewartTitleUser(currentStewartTitleUser, false)">
                                            <span>Delete</span>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="currentUserProfile && !currentUserProfile.isLawyer" class="col-lg-12 ereg-header text-black margin-top-10 margin-bottom-10">
                                Solicitor's Stewart Title Credentials
                            </div>
                            <div class="float-right">
                                <button id="btnStewartAdd" type="button" class="dp-btn dp-btn-cancel"  (click)="openStewartTitleLawyerProfile()">
                                    <span>Add</span>
                                </button>
                            </div>
                            <div *ngIf="isSolicitorStewartProfiles()">
                                <table class="table dp-teranet-table margin-bottom-100" tabindex="-1">
                                    <thead>
                                    <tr>
                                        <th>Profile ID</th>
                                        <th>Solicitor Name</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of getStewartTitleUsersWithCredentials(); let i=index;" tabindex="0">
                                        <td>
                                            <div>
                                                {{item.profileId}}
                                            </div>
                                        </td>
                                        <td style="border-left: 1px #dddddd;">
                                            <div>
                                                {{item?.solicitorName.surnameLastFullName}}
                                            </div>
                                        </td>
                                        <td style="text-align: right">
                                            <dp-burger-menu style="display:inline-block;" [items]="burgerMenuItemsStewartTitle"
                                                            [openSubMenuOnLeft] = true
                                                            (itemClicked)="burgerMenuClickedStewartTitle($event, i , item)"
                                                            (menuOpened)="updateRowIndex(i)"
                                            ></dp-burger-menu>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isTitlePlus()">
                    <div class="col-lg-12 text-right padding-top-10 padding-bottom-10">
                        <a id="myTitlePLUSLink" title="Backed by LAWPRO, TitlePLUS insurance is the only wholly Canadian owned title insurance product available in Canada. Lawyers are a critical part of the real estate transaction, and TitlePLUS is committed to offering a service that values lawyers’ expertise. Additional information can be found at http://www.titleplus.ca." class="padding-right-10" href="{{localTitlePlusLinks.myTitlePplus}}" target="_blank">Register</a>
                    </div>
                    <div class="col-lg-12 padding-top-10 padding-bottom-10" >
                        <div class="form-group" *ngIf="!currentUserProfile.isLawyer && currentTitlePlusUser && !currentTitlePlusUser.id">
                            <div class="col-lg-12 margin-top-30">
                                <p>
                                    <span>Click </span> <a id="addTPUserLink" tabindex="0"
                                                           (keydown.Enter)="addTitlePlusUser()"
                                                           (click)="addTitlePlusUser()">here
                                </a> <span> to associate and save your TitlePLUS credentials</span>
                                </p>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!currentUserProfile.isLawyer && currentTitlePlusUser && currentTitlePlusUser.id">
                            <div class="col-lg-12">
                                <ng-container *ngIf="currentUserProfile && !currentUserProfile.isLawyer">
                                    <label class="control-label col-lg-3">Credentials Associated</label>
                                </ng-container>

                                <div class="col-lg-4">
                                    <button type="button" class="dp-btn dp-btn-cancel"  (click)="addTitlePlusUser()">
                                        <span>Re-Validate</span>
                                    </button>
                                    <ng-container *ngIf="currentUserProfile && !currentUserProfile.isLawyer">
                                        <button type="button" class="dp-btn dp-btn-cancel"
                                                (click)="deleteTitlePlusUser(currentTitlePlusUser, false)">
                                            <span>Delete</span>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="currentUserProfile && !currentUserProfile.isLawyer" class="col-lg-12 ereg-header text-black margin-top-10 margin-bottom-10">
                                Solicitor's TitlePLUS Credentials
                            </div>
                            <div class="float-right">
                                <button id="btnTitlePlusAdd" type="button" class="dp-btn dp-btn-cancel"
                                        (click)="openTitlePlusLawyerProfile()">
                                    <span>Add</span>
                                </button>
                            </div>
                            <div *ngIf="isTitlePlusProfilesExistForSolicitors()">
                                <table class="table dp-teranet-table margin-bottom-100" tabindex="-1">
                                    <thead>
                                    <tr>
                                        <th>Law Society Number</th>
                                        <th>Solicitor Name</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of getTitlePlusUsersWithCredentials(); let i=index;" tabindex="0">
                                        <td>
                                            <div>
                                                {{item.profileId}}
                                            </div>
                                        </td>
                                        <td style="border-left: 1px #dddddd;">
                                            <div>
                                                {{item?.solicitorName.surnameLastFullName}}
                                            </div>
                                        </td>
                                        <td style="text-align: right">
                                            <dp-burger-menu style="display:inline-block;" [items]="burgerMenuItemsStewartTitle"
                                                            [openSubMenuOnLeft] = true
                                                            (itemClicked)="burgerMenuClickedTitlePlus($event, i , item)"
                                                            (menuOpened)="updateRowIndex(i)"
                                            ></dp-burger-menu>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isFCT()">
                    <div class="col-lg-12 text-right padding-top-10 padding-bottom-10">
                        <a id="FCTLink"
                           title="FCT offers solutions that support every stage of the real estate life cycle."
                           class="padding-right-10" href="{{localFctLinks.register}}" target="_blank">Register</a>
                    </div>
                    <dp-third-party-credentials [thirdPartyUser]="fctUser" [thirdPartyType]="'FCT'"
                                                (thirdPartyUserCreated)="updateThirdPartyUserReference($event, 'FCT')"
                    >
                    </dp-third-party-credentials>
                </div>
                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isChicagoTitle()">
                    <div class="col-lg-12 padding-top-10 padding-bottom-10" >
                        <div class="col-lg-12 text-right padding-bottom-10">
                            <a id="chicagoTitleLink"
                               title="As North America’s largest title insurance company, Chicago Title has built an industry reputation for our unmatched claims-paying and underwriting abilities. Learn more about what Chicago Title can do for you at chicagotitle.ca!"
                               class="padding-right-10" href="{{localChicagoTitleLinks.register}}" target="_blank">Register</a>
                        </div>
                        <div class="margin-top-30">
                            No user credentials required.
                        </div>
                    </div>
                </div>
                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isStewartAssyst() || activeIntegrationSection.isUnityLenderCentre()">
                    <div class="col-lg-12 text-right padding-top-10 padding-bottom-10">
                        <a id="lenderCenterTitleLink"
                           title="Unity&reg; Lender Centre enables your office to electronically exchange instructions, reports, payout and discharge requests, and other mortgage data with top Canadian lenders to reduce repetition and to increase accuracy and efficiency."
                           class="padding-right-10" href="{{localStewartTitleLinks.assystRequestInfo}}" target="_blank">Register</a>
                    </div>
                    <dp-third-party-credentials [thirdPartyUser]="stewartAssystUser" [thirdPartyType]="'STEWART_ASSYST'"
                                                (thirdPartyUserCreated)="updateThirdPartyUserReference($event, 'STEWART_ASSYST')"
                    >
                    </dp-third-party-credentials>
                </div>
                <div *ngIf="activeIntegrationSection && activeIntegrationSection.isTaxCertificatesOnLine()">
                    <dp-third-party-credentials [thirdPartyUser]="tcolUser" [thirdPartyType]="'TCOL'"
                                                (thirdPartyUserCreated)="updateThirdPartyUserReference($event, 'TCOL')">
                    </dp-third-party-credentials>
                </div>
                <ng-container *ngIf="activeIntegrationSection && activeIntegrationSection.isLTSA()">
                    <form class="form-horizontal col-lg-12">
                        <div class="form-group">
                            <div class="col-lg-12 text-right padding-top-10 padding-bottom-10">
                            </div>
                            <div class="col-lg-12 text-right">
                                <button label="Add New" #btnAddNewLtsa class="dp-btn-green" (click)="addLtsaUser()"
                                        (keydown.tab)="updateLtsaRowIndex(0)">
                                    <span>Add New</span>
                                    <div class="clear"></div>
                                </button>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div>
                            <div>
                                <table class="table dp-teranet-table margin-bottom-100" tabindex="-1">
                                    <thead>
                                    <tr>
                                        <th>User Name</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let item of ltsaUsers; let i=index;" tabindex="0"
                                        [ngClass]="{'active': isLtsaRowSelected(i), 'dp-teranet-row-active': isLtsaRowSelected(i)}"
                                        (click)="updateLtsaRowIndex(i)" (keydown.tab)="updateLtsaRowIndex(i)">
                                        <td style="border-left: 1px #dddddd;">
                                            <div>
                                                {{ item.ltsaLoginName }}
                                            </div>
                                        </td>
                                        <td style="text-align: right">
                                            <dp-burger-menu style="display:inline-block;" [items]="ltsaBurgerMenuItems"
                                                            [openSubMenuOnLeft]=true
                                                            (itemClicked)="ltsaBurgerMenuClicked($event, i , item)"
                                                            (menuOpened)="updateLtsaRowIndex(i)"
                                                            (keydown.tab)="ltsaMoveToNextRow()"
                                            ></dp-burger-menu>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 text-center">
                <button id="btnClose" class="dp-btn-white" type="button" (click)="close()" (keydown)="closeKeyCommands($event)">
                    <span>Close</span>
                </button>
            </div>
        </div>
    </div>

    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

