import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../shared/matter';
import {ClosingDatePayment} from '../../shared/closing-date-payment';
import {dropDowns} from '../../shared';
import {SelectItem} from 'primeng/api';
import {LateClosingInterest, PrincipalAmountTypeForLateInterest} from '../../shared/late-closing-interest';
import Utils from '../../../shared-main/utils';
import {DpBooleanValueTypes} from '../../shared/dp-boolean';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {LateClosingDetailsModalComponent} from './late-closing-details-modal.component';
import moment from 'moment';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {CurrencyPipe, PercentPipe} from '@angular/common';
import {ModalResult} from '../../../shared-main/enums';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class DelayedInterestCalculationModalContext {
  public matter: Matter;
}

@Component({
  selector: 'dp-delayed-interest-modal-content',
  templateUrl: 'delayed-interest-calculation-modal.component.html',
  providers: [ErrorService]
})
export class DelayedInterestCalculationModalComponent
  extends ModalComponent<DelayedInterestCalculationModalContext>
  implements OnInit
{
  closingDatePayment: ClosingDatePayment;
  closingDatePaymentCopy: ClosingDatePayment;
  yesNoMatterFile: SelectItem[];
  currentTimeStamp = Date.now();
  delayedAmountOptionText: string = PrincipalAmountTypeForLateInterest.delayedAmountOptionText;
  cashToCloseOptionText: string; // = PrincipalAmountTypeForLateInterest.cashToCloseOptionText;
  registeredMortgageOptionText: string = PrincipalAmountTypeForLateInterest.registeredMortgageOptionText;
  editButtonLabel: string = 'Edit';
  deleteButtonLabel: string = 'Delete';
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<DelayedInterestCalculationModalComponent>,
    public dialogService: DialogService,
    public percentPipe: PercentPipe,
    public currencyPipe: CurrencyPipe,
    @Inject(MAT_DIALOG_DATA) context?: DelayedInterestCalculationModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.yesNoMatterFile = dropDowns.taxPaidUnderTIPPOptions;
    if (!this.context.matter.closingDatePayment) {
      this.context.matter.closingDatePayment = new ClosingDatePayment();
      if (this.context.matter.isSale) {
        this.context.matter.closingDatePayment.delayedAmountSameAsTabG = false;
      }
    } else {
      this.closingDatePaymentCopy = new ClosingDatePayment(this.context.matter.closingDatePayment);
    }
    this.closingDatePayment = this.context.matter.closingDatePayment;
    if (this.context.matter.isClosingDayTypeCashtoClose()) {
      this.closingDatePayment.lateClosing = DpBooleanValueTypes.NO;
    } else {
      this.closingDatePayment.lateClosing = DpBooleanValueTypes.YES;
    }
    this.syncCashToClose();
    this.syncSegmentAdjustment();
    this.syncDelayedAmount();
    this.syncMortgageAmount();
    /*
            //the sale matter maybe a linked sale, this field value may be copy from Purchase
            //can be used to decide whether the delayed amount field should be readonly or not
            //and even the following code is needed, it need to be done before syncDelayedAmount()
            if (this.context.matter.isSale && this.context.matter.isClosingDayTypeCashDifference()) {
                this.closingDatePayment.delayedAmountSameAsTabG = false;
            }*/
    if (this.context.matter.isClosingDayTypeNil()) {
      this.syncCashToClose();
    }

    if (this.context.matter) {
      switch (this.context.matter.provinceCode) {
        case 'MB':
        case 'SK':
          this.cashToCloseOptionText = PrincipalAmountTypeForLateInterest.balanceToCloseOptionText;
          break;
        case 'AB':
        default:
          this.cashToCloseOptionText = PrincipalAmountTypeForLateInterest.cashToCloseOptionText;
          break;
      }
    }
  }

  syncCashToClose(): void {
    this.context.matter.syncCashToClose();
  }

  syncSegmentAdjustment(): void {
    if (
      this.matter.isSale &&
      this.matter.matterLink != null &&
      this.closingDatePayment &&
      !this.closingDatePayment.autoCalculate &&
      this.closingDatePaymentCopy
    ) {
      //for Linked Sale matter, if is not autoCalculate, delayedAmountSameAsTabG's default value should sync with Linked Purchase matter
      this.closingDatePayment.delayedAmountSameAsTabG = this.closingDatePaymentCopy.delayedAmountSameAsTabG;
    }
    this.context.matter.syncSegmentAdjustment();
  }

  syncDelayedAmount(): void {
    this.context.matter.syncDelayedAmount();
  }

  get isAllowedToManualUpdateDelayedAmount(): boolean {
    return !(
      (this.closingDatePayment.delayedAmountSameAsTabG || this.closingDatePayment.autoCalculate) &&
      (this.context.matter.isSale || this.context.matter.isPurchase)
    );
  }

  syncMortgageAmount(): void {
    this.context.matter.syncMortgageAmount();
  }

  get matterProvince(): ProvinceCode {
    return this.context.matter ? this.context.matter.provinceCode : 'AB';
  }

  getModalTitle(): string {
    /*
            Calculation of {{context.matter && context.matter.isClosingDayTypeCashDifference() ? 'Cash
                Difference and' : ''}} Late Closing Interest
    */
    let value: string = '';
    if (this.matter && this.matter.provinceCode) {
      const isClosingDayTypeNil: boolean = this.matter.isClosingDayTypeNil();
      switch (this.matter.provinceCode) {
        case 'AB':
          value = isClosingDayTypeNil ? 'Late' : 'Cash Difference and Late';
          break;
        case 'SK':
        case 'MB':
          value = isClosingDayTypeNil ? 'Delayed' : 'Balance of Down Payment and Delayed';
          break;
      }
    }
    return `Calculation of ${value} Closing Interest`;
  }

  get matter(): Matter {
    return this.context && this.context.matter;
  }

  onDateChangeLateClosingDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.closingDatePayment.lateClosingDate) {
      this.closingDatePayment.lateClosingDate = tmpDate;
      this.daysToCalculate();
      this.closingDatePayment.onLateClosingDateChange(this.matterProvince);
    }
  }

  /*    getDateToSave(event): string {
          let date: string;

          date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;

          return date;
      }*/

  ok(): void {
    //data cleanup before close the modal
    if (!this.modalErrorComponent.anyErrorExist()) {
      if (this.matterProvince == 'MB' || this.matterProvince == 'SK' || this.matterProvince == 'AB') {
        this.closingDatePayment.updateLateInterestAmountTotal(this.context.matter.provinceCode);

        if (this.matterProvince == 'MB' || this.matterProvince == 'SK') {
          this.updateDifferenceAmt();
          this.updateEstimateInterestAmt();
        }
      }

      this.dialog.close(ModalResult.OK);
    }
  }

  close(): void {
    this.context.matter.closingDatePayment = this.closingDatePaymentCopy;
    this.dialog.close(ModalResult.Cancel);
  }

  openLateClosingModal(lateClosingInterest?: LateClosingInterest): void {
    if (this.matterProvince == 'MB' || this.matterProvince == 'SK' || this.matterProvince == 'AB') {
      this.modalErrorComponent.removeDpSaveError('matter.matterOpening.lateClosingInterest.secondNotAllowed');
      if (!lateClosingInterest && Array.isArray(this.closingDatePayment.lateClosingInterests)) {
        //try to add new
        if (
          this.closingDatePayment.lateClosingInterests.length == 1 &&
          this.closingDatePayment.lateClosingInterests[0].sameAs1stMortgage
        ) {
          //not allowed to add another one
          this.modalErrorComponent.createCustomDPSaveError(
            'matter.matterOpening.lateClosingInterest.secondNotAllowed',
            "Only one Interest row is permitted when 'Interest same as 1st Mortgage' is set to true.",
            '',
            'ERROR'
          );
          return;
        }
      }
    }

    this.dialogService.matDialogContent({
      content: LateClosingDetailsModalComponent,
      context: {
        title: this.getModalTitle(),
        matter: this.context.matter,
        lateClosingInterest: lateClosingInterest ? lateClosingInterest : undefined,
        closingDatePayment: this.closingDatePayment
      },
      onFulfillment: (result) => {},
      onRejection: (reject: any) => {},
      
    });
  }

  daysToCalculate(): void {
    this.context.matter.daysToCalculate();
  }

  calculateInterest(lateClosingInterest: LateClosingInterest): string {
    if (this.context.matter && this.context.matter.closingDatePayment) {
      this.context.matter.closingDatePayment.calculateInterest(lateClosingInterest, this.context.matter.provinceCode);
    }
    return Utils.formattedCurrencyValue(lateClosingInterest.lateInterestAmount);
  }

  getInterestAmountTotal(): string {
    this.closingDatePayment.updateLateInterestAmountTotal(this.context.matter.provinceCode);
    return Utils.formattedCurrencyValue(this.closingDatePayment.lateInterestAmountTotal);
  }

  principalAmountText(lateClosingInterest: LateClosingInterest): any[] {
    let principalAmountOption = this.principalAmountOptions().find(
      (item) => item.value == lateClosingInterest.principalAmount
    );
    return principalAmountOption ? principalAmountOption.label : lateClosingInterest.principalAmount;
  }

  getLateInterestDescription(): string {
    this.closingDatePayment.getLateInterestDescription(
      this.context.matter.matterCloseDate,
      this.context.matter.provinceCode
    );
    return this.closingDatePayment.lateInterestDescription;
  }

  principalAmountOptions(): any[] {
    let principalAmountOptions = [];
    principalAmountOptions.push({
      label: this.delayedAmountOptionText + Utils.formattedCurrencyValue(this.closingDatePayment.delayedAmount),
      value: this.delayedAmountOptionText
    });
    principalAmountOptions.push({
      label: this.cashToCloseOptionText + Utils.formattedCurrencyValue(this.closingDatePayment.cashToClose),
      value: this.cashToCloseOptionText
    });
    principalAmountOptions.push({
      label:
        this.registeredMortgageOptionText +
        Utils.formattedCurrencyValue(this.closingDatePayment.registeredMortgageAmount),
      value: this.registeredMortgageOptionText
    });
    return principalAmountOptions;
  }

  burgerMenuClicked(clickedMenuOption: string, index: number, lateClosingInterest: LateClosingInterest): void {
    switch (clickedMenuOption) {
      case this.editButtonLabel:
        this.openLateClosingModal(lateClosingInterest);
        break;
      case this.deleteButtonLabel:
        this.deleteLateClosingInterestItem(index);
        break;
      default:
        return;
    }
  }

  deleteLateClosingInterestItem(lateClosingInterestIndex): void {
    this.dialogService
      .confirm('Confirmation', 'Are you sure you would like to delete this Item?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.context.matter.closingDatePayment.removeLateClosingInterest(lateClosingInterestIndex);
        }
      });
  }

  formatInterestRate(interestRate: number): string {
    return Utils.formatPercentageValue(interestRate);
  }

  formatDate(date: string, isLast: boolean = false): string {
    if (isLast) {
      switch (this.context.matter.provinceCode) {
        case 'MB':
        case 'SK':
          return 'Delayed Closing Date';
        case 'ON':
        case 'AB':
        default:
          return 'Late Closing Date';
      }
    } else {
      return date ? moment(date).format('DD/MM/YYYY') : '';
    }
  }

  showClosingPaymentTable(): boolean {
    switch (this.matterProvince) {
      case 'AB':
        return (
          this.closingDatePayment.isLateClosing() &&
          this.closingDatePayment.isLateClosingDateValid() &&
          this.closingDatePayment.lateClosingInterests &&
          this.closingDatePayment.lateClosingInterests.length > 0
        );
      case 'MB':
      case 'SK':
        return (
          this.closingDatePayment.isLateClosing() &&
          this.closingDatePayment.lateClosingInterests &&
          this.closingDatePayment.lateClosingInterests.length > 0
        );
      default:
        return false;
    }
  }

  showAddLateClosingInterestLink(): boolean {
    switch (this.matterProvince) {
      case 'AB':
        return this.closingDatePayment.isLateClosing() && this.closingDatePayment.isLateClosingDateValid();
      case 'MB':
      case 'SK':
        return this.closingDatePayment.isLateClosing();
      default:
        return false;
    }
  }

  getInterestEstimateOnLabel(): string {
    //get the last lateClosingInterest
    if (
      this.closingDatePayment &&
      Array.isArray(this.closingDatePayment.lateClosingInterests) &&
      this.closingDatePayment.lateClosingInterests.length > 0
    ) {
      const lastLateClosingInterest: LateClosingInterest =
        this.closingDatePayment.lateClosingInterests[this.closingDatePayment.lateClosingInterests.length - 1];
      const formattedInterestRate: string = this.percentPipe.transform(
        lastLateClosingInterest.interestRate / 100,
        '1.3'
      );
      const lastLateInterestAmtValue: string = Utils.formattedCurrencyValue(this.getLastLateInterestAmt());
      return `Interest Estimate on ${lastLateInterestAmtValue} @ ${formattedInterestRate} for`;
    }
    return '';
  }

  //if user leave the field blank, then we will reset it back to 31 days (default value
  verifyInterestEstimateDays(): void {
    if (this.closingDatePayment && !this.closingDatePayment.interestEstimateDays) {
      this.closingDatePayment.interestEstimateDays = 31;
    }
  }

  getDifference(): number {
    this.updateDifferenceAmt();
    return this.closingDatePayment.differenceAmount == null ? 0 : this.closingDatePayment.differenceAmount;
  }

  updateDifferenceAmt(): void {
    if (this.closingDatePayment.hasLateClosingInterests()) {
      const difference: number =
        Number(this.closingDatePayment.lateInterestAmountTotal) - Number(this.getEstimatedInterest());
      this.closingDatePayment.differenceAmount = isNaN(difference) ? null : Number(difference.toFixed(2));
    } else {
      this.closingDatePayment.differenceAmount = null;
    }
  }

  getLastLateInterestAmt(): number {
    return this.context.matter.getLastLateInterestAmt();
  }

  getEstimatedInterest(): number {
    this.updateEstimateInterestAmt();
    return this.closingDatePayment.interestEstimateAmount;
  }

  updateEstimateInterestAmt(): void {
    if (this.closingDatePayment.hasLateClosingInterests()) {
      this.closingDatePayment.interestEstimateAmount = this.context.matter.getEstimatedInterest();
    } else {
      this.closingDatePayment.interestEstimateAmount = null;
    }
  }
}
