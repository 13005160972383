import {Contact} from '../../matters/shared/contact';
import {ContactSuggestionSalutationInterface} from './contact-suggestion-salutation-interface';
import {MatterParticipantWrapper} from '../../matters/shared/matter-participant-wrapper';
import {ContactSuggestionBase} from './contact-suggestion-base';
import {
  EnvelopeSalutationFormats,
  EnvelopeSalutationFormatType
} from '../../admin/document-profile/document-profile-edit/misc/EnvelopeSalutationFormats';
import {ContactSuggestion} from './contact-suggestion';
import {ContactName, Matter, MatterParticipant} from '../../matters';
import {GenderTypes} from '../contact-type-mapping';
import {
  AmpPlusSpace,
  AndPlusSpace,
  MadamAmpSir,
  MadamAndSir,
  Madams,
  SirAmpMadam,
  SirAndMadam,
  Sirs,
  TitleMiss,
  TitleMr,
  TitleMrs,
  TitleMs
} from '../../shared-main/constants';
import {MatterParticipantUtil} from '../../matters/shared/matter-utils/matter-participant-util';
import Utils from '../../shared-main/utils';
import {ContactSuggestionSalutationEnum} from './contact-suggestion-salutation-enum.enum';

export class ContactSuggestionDear extends ContactSuggestionBase implements ContactSuggestionSalutationInterface {

  addContactAssociations(contact: Contact, result: Map<EnvelopeSalutationFormatType, string>, matter?: Matter, parentParticipant?: MatterParticipant) {
    if (contact && Array.isArray(contact.contactAssociations) && (contact.contactAssociations.length > 0)) {
      if (contact.gender === 'CORPORATION') {
        let cont: string = '';
        let associationContacts: Contact[] = [];
        //Matter level use matterParticipants because we can unselect check box in matter shutter signing office
        if (matter && parentParticipant) {
          let associationParticipants: MatterParticipant[] = matter.getAssociationParticipants(contact, parentParticipant);
          associationContacts = associationParticipants.map(participant => participant.contact).filter(item => !!item);
        } else { //Contact level use contactAssociations
          associationContacts = contact.contactAssociations.map(contactAssociation => contactAssociation.associatedContact).filter(item => !!item);
        }
        if (associationContacts && associationContacts.length > 0) {
          associationContacts.forEach((contact, index) => {
            if (index) {
              if (index == associationContacts.length - 1) {
                cont += ' and ';
              } else {
                cont += ', ';
              }
            }
            cont += contact ? contact.genericName : '';

          });
          //result.push(cont);
          result.set(EnvelopeSalutationFormats.JOIN_ASSOCIATED_CONTACT, cont);
        }
      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be male and female
   *  The following rules will be applied when there are 2 clients with same last name:
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  female client with a first name of "Jane Mary", middle name of "Eva" and last name "Doe"
   *   Mr. & Ms. Doe
   *   Mr. and Ms. Doe
   *   Mr. & Mrs. Doe
   *   Mr. and Mrs. Doe
   *   Mr. Doe & Ms. Doe
   *   Mr. Doe and Ms. Doe
   *   Mr. Doe & Mrs. Doe
   *   Mr. Doe & Mrs. Doe
   *   John Richard & Jane Mary
   *   John Richard and Jane Mary
   *   Sir & Madam
   *   Sir and Madam
   *
   */
  create2DiffGenderClientsWithSameLastName(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        let titleMs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMs : TitleMr;
        let titleMrs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMrs : TitleMr;
        // Mr. & Ms. Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MS_MLN, ContactSuggestion.getTitleMr(client1.gender, true) + AmpPlusSpace + titleMs2 + client1Name.lastNameFormat);
        // Mr. and Ms. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MS_MLN, ContactSuggestion.getTitleMr(client1.gender, true) + AndPlusSpace + titleMs2 + client1Name.lastNameFormat);
        // Mr. & Mrs. Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MLN, ContactSuggestion.getTitleMr(client1.gender, false) + AmpPlusSpace + titleMrs2 + client1Name.lastNameFormat);
        // Mr. and Mrs. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MRS_MLN, ContactSuggestion.getTitleMr(client1.gender, false) + AndPlusSpace + titleMrs2 + client1Name.lastNameFormat);
        // Mr. Doe & Ms. Doe
        result.set(EnvelopeSalutationFormats.MR_MLN_AMP_MS_FLN, ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMs2 + client2Name.lastNameFormat);
        // Mr. Doe and Ms. Doe
        result.set(EnvelopeSalutationFormats.MR_MLN_AND_MS_FLN, ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.lastNamePlusSpace + AndPlusSpace + titleMs2 + client2Name.lastNameFormat);
        // Mr. Doe & Mrs. Doe
        result.set(EnvelopeSalutationFormats.MR_MLN_AMP_MRS_FLN, ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMrs2 + client2Name.lastNameFormat);
        // Mr. Doe and Mrs. Doe
        result.set(EnvelopeSalutationFormats.MR_MLN_AND_MRS_FLN, ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.lastNamePlusSpace + AndPlusSpace + titleMrs2 + client2Name.lastNameFormat);
        // John Richard & Jane Mary
        result.set(EnvelopeSalutationFormats.FN_AMP_FN, client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNameFormat);
        // John Richard and Jane Mary
        result.set(EnvelopeSalutationFormats.FN_AND_FN, client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNameFormat);
        // Sir & Madam
        result.set(EnvelopeSalutationFormats.SIR_AMP_MADAM, client1.gender === GenderTypes.MALE ? SirAmpMadam : MadamAmpSir);
        // Sir and Madam
        result.set(EnvelopeSalutationFormats.SIR_AND_MADAM, client1.gender === GenderTypes.MALE ? SirAndMadam : MadamAndSir);

      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be male and female
   *  The following rules will be applied when there are 2 clients with different last name:
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  female client with a first name of "Jane" and last name "Public"
   *   John Richard & Jane
   *   John Richard and Jane
   *   Mr. J. Doe & Ms. J. Public
   *   Mr. J. Doe and Ms. J. Public
   *   Mr. J. Doe & Mrs. J. Public
   *   Mr. J. Doe and Mrs. J. Public
   *   Sir & Madam
   *   Sir and Madam
   * @param result
   * @param client1
   * @param client2
   */
  create2DiffGenderClientsWithDiffLastName(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        let titleMs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMs : TitleMr;
        let titleMrs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMrs : TitleMr;
        // John Richard & Jane
        result.set(EnvelopeSalutationFormats.FN_AMP_FN,
          client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNameFormat);
        // John Richard and Jane
        result.set(EnvelopeSalutationFormats.FN_AND_FN,
          client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNameFormat);
        // Mr. J. Doe & Ms. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Ms. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace + titleMs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe & Mrs. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMrs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Mrs. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MRS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace + titleMrs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Sir & Madam
        result.set(EnvelopeSalutationFormats.SIR_AMP_MADAM, client1.gender === GenderTypes.MALE ? SirAmpMadam : MadamAmpSir);
        // Sir and Madam
        result.set(EnvelopeSalutationFormats.SIR_AND_MADAM, client1.gender === GenderTypes.MALE ? SirAndMadam : MadamAndSir);
      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be male and female
   *  The following changes will be applied when there are two clients same Gender.
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  male client with a first name of "Michael Luke", middle name of "Matthew" and last name "Brown"
   *   Mr. J. Doe & Mr. M. Brown
   *   Mr. J. Doe and Mr. M. Brown
   *   John Richard & Michael Luke
   *   John Richard & Michael Luke
   *   Sirs
   *  Note that the requirement will be stated given the example of a female client with a first name of "Jane", middle name of "Josephine" and last name of "Doe",
   *  female client with a first name of "Mary", middle name of "Magdalene" and last name "Brown":
   *   Ms. J. Doe & Ms. M. Brown
   *   Ms. J. Doe and Ms. M. Brown
   *   Mrs. J. Doe & Mrs. M. Brown
   *   Mrs. J. Doe and Mrs. M. Brown
   *   Miss J. Doe & Miss M. Brown
   *   Miss J. Doe and Miss M. Brown
   *   Jane & Mary
   *   Jane and Mary
   *   Madams
   *   * @param result
   * @param client1
   * @param client2
   */
  create2SameGenderClients(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        // Mr. J. Doe & Mr. M. Brown
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MR_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace
          + ContactSuggestion.getTitleMr(client1.gender, true) + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Mr. M. Brown
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MR_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace
          + ContactSuggestion.getTitleMr(client1.gender, true) + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        if (client1.gender == GenderTypes.FEMALE) {
          // Mrs. J. Doe & Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MRS_MFLFN_MLN_AMP_MRS_MFLFN_MLN,
            TitleMrs + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace
            + TitleMrs + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
          // Mrs. J. Doe and Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MRS_MFLFN_MLN_AND_MRS_MFLFN_MLN,
            TitleMrs + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace
            + TitleMrs + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
          // Miss Doe & Mrs. Brown
          result.set(EnvelopeSalutationFormats.MISS_MFLFN_MLN_AMP_MISS_MFLFN_MLN,
            TitleMiss + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace
            + TitleMiss + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
          // Msr. J. Doe and Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MISS_MFLFN_MLN_AND_MISS_MFLFN_MLN,
            TitleMiss + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace
            + TitleMiss + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        }
        // John Richard & Michael Luke
        result.set(EnvelopeSalutationFormats.FN_AMP_FN,
          client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNameFormat);
        // John Richard and Michael Luke
        result.set(EnvelopeSalutationFormats.FN_AND_FN,
          client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNameFormat);
        // Sirs./Madams
        if (client1.gender === GenderTypes.MALE) {
          result.set(EnvelopeSalutationFormats.SIRS_OR_MADAMS, Sirs);
        } else {
          result.set(EnvelopeSalutationFormats.SIRS_OR_MADAMS, Madams);
        }
      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be blank and male/female
   *  The following changes will be applied when there are two clients <Blank> gender and male/female.
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  <Blank> client with a first name of "Joe" and last name "Blank"
   *   Mr. J. Doe & Joe Blank
   *   Mr. J. Doe and Joe Blank
   *   John Richard & Joe
   *   John Richard and Joe
   *   Sir & Madam
   *   Sir and Madam
   *   * @param result
   * @param client1
   * @param client2
   */
  create2DiffBlankAndMaleOrFemaleGenderClients(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        // Mr. J. Doe & Joe Blank
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_FN_LN,
          ContactSuggestion.getTitleMr(client1.gender) + (ContactSuggestion.getTitleMr(client1.gender) ? client1Name.firstLetterFirstNameFormat : client1Name.firstNamePlusSpace) + client1Name.lastNamePlusSpace
          + AmpPlusSpace
          + ContactSuggestion.getTitleMr(client2.gender) + (ContactSuggestion.getTitleMr(client2.gender) ? client2Name.firstLetterFirstNameFormat : client2Name.firstNamePlusSpace) + client2Name.lastNameFormat);
        // Mr. J. Doe and Joe Blank
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_FN_LN,
          ContactSuggestion.getTitleMr(client1.gender) + (ContactSuggestion.getTitleMr(client1.gender) ? client1Name.firstLetterFirstNameFormat : client1Name.firstNamePlusSpace) + client1Name.lastNamePlusSpace
          + AndPlusSpace
          + ContactSuggestion.getTitleMr(client2.gender) + (ContactSuggestion.getTitleMr(client2.gender) ? client2Name.firstLetterFirstNameFormat : client2Name.firstNamePlusSpace) + client2Name.lastNameFormat);
        // John Richard & Joe
        result.set(EnvelopeSalutationFormats.FN_AMP_FN,
          client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNameFormat);
        // John Richard and Joe
        result.set(EnvelopeSalutationFormats.FN_AND_FN,
          client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNameFormat);
        // Sir & Madam
        result.set(EnvelopeSalutationFormats.SIR_AMP_MADAM, SirAmpMadam);
        // Sir and Madam
        result.set(EnvelopeSalutationFormats.SIR_AND_MADAM, SirAndMadam);
      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The following rules will be applied when there are more than two individual clients (male/female).
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Henry", middle name of "Joshua" and last name of "Smith",
   *  female client with a first name of "Maria", middle name of "Thelma" and last name "Board"
   *  and male client with a first name of "Anthony" , middle name "Robert" and last name "Adams"
   *   Mr. J. Smith, Ms. M. Board & Mr. A. Adams
   *   Mr. J. Smith, Ms. M. Board and Mr. A. Adams
   *   John Henry, Maria & Anthony
   *   John Henry, Maria and Anthony
   *   Sir & Madam
   *   Sir and Madam
   *   @param result
   *   @param participants
   */
  createIndividualClients(result: Map<EnvelopeSalutationFormatType, string>, wrappers: Array<MatterParticipantWrapper>) {
    if (MatterParticipantUtil.isIndividualClients(wrappers)) {
      let tmpWrappers = wrappers.filter(item => item && item.matterParticipant && item.matterParticipant.contact && item.matterParticipant.contact.contactName);
      let tmpContacts = wrappers.filter(item => item && item.matterParticipant && item.matterParticipant.contact);
      //Mr. J. Smith format
      let suggestionMrFlfnLn: string[] = ContactSuggestion.createMaleOrFemaleParticipantsSuggestion(tmpWrappers, EnvelopeSalutationFormats.MR_FLFN_LN);
      //Mr. John Henry Smith
      let suggestionFn: string[] = ContactSuggestion.createMaleOrFemaleParticipantsSuggestion(tmpWrappers, EnvelopeSalutationFormats.FN);
      // Mr. J. Smith, Ms. M. Board & Mr. A. Adams
      result.set(EnvelopeSalutationFormats.TITLE_FLFN_LN_JOIN_WITH_AMP, Utils.concatenateNames(suggestionMrFlfnLn, null, '&'));
      // Mr. J. Smith, Ms. M. Board and Mr. A. Adams
      result.set(EnvelopeSalutationFormats.TITLE_FLFN_LN_JOIN_WITH_AND, Utils.concatenateNames(suggestionMrFlfnLn, null, 'and'));
      // John Henry Smith, Maria Board & Anthony Adams
      result.set(EnvelopeSalutationFormats.FN_JOIN_WITH_AMP, Utils.concatenateNames(suggestionFn, null, '&'));
      // John Henry Smith, Maria Board and Anthony Adams
      result.set(EnvelopeSalutationFormats.FN_JOIN_WITH_AND, Utils.concatenateNames(suggestionFn, null, 'and'));
      // Sir & Madam
      // When all clients are male then 'Sirs' will be displayed instead of Sir & Madam
      if (tmpContacts.every(item => item.matterParticipant.contact.gender == GenderTypes.MALE)) {
        result.set(EnvelopeSalutationFormats.SIRS_OR_MADAMS, Sirs);
      }
      // When all clients are female then 'Madams' will be displayed instead of Sir & Madam
      else if (tmpContacts.every(item => item.matterParticipant.contact.gender == GenderTypes.FEMALE)) {
        result.set(EnvelopeSalutationFormats.SIRS_OR_MADAMS, Madams);
      } else {
        // Sir & Madam
        result.set(EnvelopeSalutationFormats.SIR_AMP_MADAM, SirAmpMadam);
        // Sir and Madam
        result.set(EnvelopeSalutationFormats.SIR_AND_MADAM, SirAndMadam);
      }
    }

  }

  initSuggestions(contact: Contact, matter?: Matter, parentParticipant?: MatterParticipant): Map<EnvelopeSalutationFormatType, string> {
    let result = new Map<EnvelopeSalutationFormatType, string>();

    switch (contact.gender) {
      case 'MALE':
        if (contact.contactName.lastName) {
          //result.push(`Mr. ${contact.contactName.lastName}`);
          result.set(EnvelopeSalutationFormats.MR_LN, `Mr. ${ contact.contactName.lastName }`);
        }
        if (contact.contactName.firstName) {
          //result.push(`${contact.contactName.firstName}`);
          result.set(EnvelopeSalutationFormats.FN, `${ contact.contactName.firstName }`);
        }

        // result.push(`Sir`);
        result.set(EnvelopeSalutationFormats.SIR, `Sir`);
        break;
      case 'FEMALE':
        // ['Ms.', 'Mrs.', 'Miss'].forEach((title) => {
        //     if (contact.contactName.lastName)
        //         result.push(`${title} ${contact.contactName.lastName}`);
        // }
        // );
        result.set(EnvelopeSalutationFormats.MS_LN, contact.contactName.lastName ? `Ms. ${ contact.contactName.lastName }` : 'Ms.');
        result.set(EnvelopeSalutationFormats.MRS_LN, contact.contactName.lastName ? `Mrs. ${ contact.contactName.lastName }` : 'Mrs.');
        result.set(EnvelopeSalutationFormats.MISS_LN, contact.contactName.lastName ? `Miss ${ contact.contactName.lastName }` : 'Miss');

        if (contact.contactName.firstName) {
          // result.push(`${contact.contactName.firstName}`);
          result.set(EnvelopeSalutationFormats.FN, `${ contact.contactName.firstName }`);

        }

        // result.push(`Madam`);
        result.set(EnvelopeSalutationFormats.MADAM, `Madam`);

        break;
      case 'MALEPOA':
      case 'FEMALEPOA':
        //result.push(`Dear Sir or Madam`);
        result.set(EnvelopeSalutationFormats.DEAR_SIR_OR_MADAM, `Dear Sir or Madam`);
        break;
      case 'QUESTION':
        // result.push(`Sir or Madam`);
        result.set(EnvelopeSalutationFormats.SIR_OR_MADAM, `Sir or Madam`);
        break;
      case 'ESTATE':
        //result.push(`Dear Sir or Madam`);
        result.set(EnvelopeSalutationFormats.DEAR_SIR_OR_MADAM, `Dear Sir or Madam`);
        break;
      case 'CORPORATION':
        this.addContactAssociations(contact, result, matter, parentParticipant);
      default:
        break;
    }
    return result;

  }

  initMatterParticipantsSuggestions(wrappers: Array<MatterParticipantWrapper>, matter: Matter): Map<EnvelopeSalutationFormatType, string> {
    let result = new Map<EnvelopeSalutationFormatType, string>();

    let participants: MatterParticipantWrapper[] = Array.isArray(wrappers) ? wrappers.filter(item => item.matterParticipant && item.matterParticipant.contact) : [];
    if (Array.isArray(participants) && participants.length > 0) {
      //DPPMP-47841 Add concatenatedValue into drop down list
      let concatenatedValue = this.allParticipantsArePersonsOrBlank(participants) ? ContactSuggestion.getDefaultSuggestions(participants, ContactSuggestionSalutationEnum.dear) : '';
      if (Array.isArray(concatenatedValue) && concatenatedValue.length > 0) {
        result.set(EnvelopeSalutationFormats.CONCATENATED_VALUE, Utils.concatenateNames(concatenatedValue));
      }

      if (participants.length === 1 && participants[ 0 ].matterParticipant) {
        let resultTmp = this.initSuggestions(participants[ 0 ].matterParticipant.contact, matter, participants[ 0 ].matterParticipant);
        resultTmp.forEach((v, k) => result.set(k, v));
        return result;
      }
      if (MatterParticipantUtil.is2DiffMaleOrFemaleGenderClientsWithSameLastName(participants)) {
        this.create2DiffGenderClientsWithSameLastName(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);

      } else if (MatterParticipantUtil.is2DiffMaleAndFemaleGenderClientsWithDiffLastName(participants)) {
        this.create2DiffGenderClientsWithDiffLastName(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.is2SameMaleOrFemaleGenderClients(participants)) {
        this.create2SameGenderClients(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.is2DiffBlankAndMaleOrFemaleGenderClientsWithDiffLastName(participants)) {
        this.create2DiffBlankAndMaleOrFemaleGenderClients(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.isIndividualClients(participants)) {
        this.createIndividualClients(result, participants);
      } else if (MatterParticipantUtil.excludeCorpEntityAndOneMaleFemaleBlank(participants)) {//only one contact gender is male , female or blank and others exclude CORPRATION or OTHER_ENTITY
        let wrapper: MatterParticipantWrapper = participants.find(item => item.matterParticipant && item.matterParticipant.contact
          && item.matterParticipant.contact.isMaleOrFemaleOrBlankGender());
        if (wrapper && wrapper.matterParticipant && wrapper.matterParticipant.contact) {
          let resultTmp = this.initSuggestions(wrapper.matterParticipant.contact);
          resultTmp.forEach((v, k) => result.set(k, v));
          return result;
        }
      } else if (participants.length >= 1 && !MatterParticipantUtil.includeCorpEntity(participants) && (this.allParticipantsArePersons(participants) || this.isAnyParticipantWithoutGender(participants))) {
        //second client purchasers just a place holder
        if (participants.length === 2 && !participants[ 1 ].matterParticipant) {
          let resultTmp = this.initSuggestions(participants[ 0 ].matterParticipant.contact);
          resultTmp.forEach((v, k) => result.set(k, v));
          return result;
        } else if (participants.length === 2 && this.contactsOfOppositeGender(participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact)) {
          let c0 = participants[ 0 ].matterParticipant.contact;
          let c1 = participants[ 1 ].matterParticipant.contact;

          let maleFirstLetterFirstName, maleFirstName, maleLastName, femaleFirstLetterFirstName,
            femaleFirstName, femaleLastName: string;

          if (c0.gender === 'MALE' || c0.gender === 'MALEPOA') {
            maleFirstLetterFirstName = c0.contactName.firstName ? c0.contactName.firstName[ 0 ] + '. ' : '';
            maleFirstName = c0.contactName.firstName ? c0.contactName.firstName + ' ' : '';
            femaleFirstName = c1.contactName.firstName ? 'and ' + c1.contactName.firstName : '';
          } else {
            maleFirstLetterFirstName = c1.contactName.firstName ? c1.contactName.firstName[ 0 ] + '. ' : '';
            maleFirstName = c1.contactName.firstName ? c1.contactName.firstName + ' ' : '';
            femaleFirstName = c0.contactName.firstName ? 'and ' + c0.contactName.firstName : '';
          }

          // purchasers are spouses and FLA statement 'MATTER_PARTICIPANT_SPOUSE' is in the list
          if ((participants[ 0 ].matterParticipant.matterParticipantId === participants[ 1 ].matterParticipant.spouseParticipantId ||
              participants[ 1 ].matterParticipant.matterParticipantId === participants[ 0 ].matterParticipant.spouseParticipantId) &&
            (c0.contactName.lastName === c1.contactName.lastName) && !this.anySpouseIsPOAOrEstate(c0, c1)) {

            // result.push(`Mr. & Mrs. ${c0.contactName.lastName}`);
            // result.push(`${maleFirstName}${femaleFirstName}`);
            result.set(EnvelopeSalutationFormats.MR_AMP_MRS_LN, `Mr. & Mrs. ${ c0.contactName.lastName }`);
            result.set(EnvelopeSalutationFormats.MFN_FFN, `${ maleFirstName }${ femaleFirstName }`);

          } else {

            // purchasers are have same last name, potential spousal situation
            if ((participants[ 0 ].matterParticipant.contact.lastName === participants[ 1 ].matterParticipant.contact.lastName ||
                participants[ 1 ].matterParticipant.contact.lastName === participants[ 0 ].matterParticipant.contact.lastName) &&
              (c0.contactName.lastName === c1.contactName.lastName) && !this.anySpouseIsPOAOrEstate(c0, c1)) {
              //result.push(`Mr. & Mrs. ${c0.contactName.lastName}`);
              result.set(EnvelopeSalutationFormats.MR_AMP_MRS_LN, `Mr. & Mrs. ${ c0.contactName.lastName }`);

            }
            // For MALEPOA or FEMALEPOA we don't add "Dear Sir and Madam" into matter.dear drop down list
            let mapSg0 = (c0.gender != GenderTypes.MALEPOA && c0.gender != GenderTypes.FEMALEPOA) ? this.initSuggestions(c0) : null;
            let mapSsg1 = (c1.gender != GenderTypes.MALEPOA && c1.gender != GenderTypes.FEMALEPOA) ? this.initSuggestions(c1) : null;
            let sg0 = ContactSuggestion.getSuggestionsArrayFormMap(mapSg0);
            let sg1 = ContactSuggestion.getSuggestionsArrayFormMap(mapSsg1);

            if (this.anySpouseIsPOAOrEstate(c0, c1)) {
              // if (sg0 && sg0.length > 0) {
              //     sg0.forEach(p => {
              //         result.push(p)
              //     })
              // }
              if (mapSg0) {
                mapSg0.forEach((v, k) => result.set(k, v));
              }
              // if (sg1 && sg1.length > 0) {
              //     sg1.forEach(p => {
              //         result.push(p)
              //     })
              // }
              if (mapSsg1) {
                mapSsg1.forEach((v, k) => result.set(k, v));
              }
            } else {
              let item1: string = sg0[ 0 ];
              let item2: string = sg0.pop();
              if (sg1 && sg1.length > 0) {
                item1 += ' & ' + sg1[ 0 ];
                item2 += ' and ' + sg1.pop();
              }
              if (item1 && item1.length > 0) {
                //result.push(item1);
                result.set(EnvelopeSalutationFormats.TITLE_LN_JOIN_WITH_AMP, item1);
              }
              if (item2 && item2.length > 0) {
                //result.push(item2);
                result.set(EnvelopeSalutationFormats.TITLE_LN_JOIN_WITH_AND, item2);

              }
            }
          }
        } else {
          let sirNumber: number = participants.filter(item => item.matterParticipant && item.matterParticipant.contact && item.matterParticipant.contact.gender == GenderTypes.MALE).length;
          let madamNumber: number = participants.filter(item => item.matterParticipant && item.matterParticipant.contact && item.matterParticipant.contact.gender == GenderTypes.FEMALE).length;
          let name: string[] = [];
          let nameWithTitle: string[] = [];
          let firstMale: MatterParticipantWrapper = participants.find(p => p.matterParticipant.contact.gender === 'MALE');
          let participantsWithoutGender: string[] = [];

          let allParticipantsWithoutGender: boolean = participants.every(i => i.matterParticipant.contact.gender === 'QUESTION');
          if (allParticipantsWithoutGender) {
            participants.forEach(p => {
              participantsWithoutGender.push(this.formatNameWithoutGender(p));
              name.push(this.getName(p.matterParticipant.contact, false, false, false));
            });
          } else {
            participants.forEach(p => {
              if (p.matterParticipant.contact.gender === 'MALE' || p.matterParticipant.contact.gender === 'FEMALE') {

                nameWithTitle.push(this.getName(p.matterParticipant.contact, true, true));
                name.push(this.getName(p.matterParticipant.contact, false, false, false));

              }
              if (p.matterParticipant.contact.gender === 'QUESTION') {
                nameWithTitle.push(this.formatNameWithoutGender(p));
                name.push(this.getName(p.matterParticipant.contact, false, false, false));
              }
            });
          }
          if (nameWithTitle && nameWithTitle.length > 0) {
            //result.push(this.mergeListOfNames(nameWithTitle, ' & '));
            result.set(EnvelopeSalutationFormats.TITLE_FN_LN_JOIN_WITH_AMP, this.mergeListOfNames(nameWithTitle, ' & '));
          }
          if (participantsWithoutGender && participantsWithoutGender.length > 0) {
            // result.push(this.mergeListOfNames(participantsWithoutGender, ' & '));
            result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AMP, this.mergeListOfNames(participantsWithoutGender, ' & '));
          }
          if (name && name.length > 0) {
            //result.push(this.mergeListOfNames(name, ' and '));
            result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AND, this.mergeListOfNames(name, ' and '));
          }

          if (sirNumber > 0 && madamNumber > 0) {
            // Sir & Madam
            result.set(EnvelopeSalutationFormats.SIR_AMP_MADAM, SirAmpMadam);
            // Sir and Madam
            result.set(EnvelopeSalutationFormats.SIR_AND_MADAM, SirAndMadam);
          } else if (sirNumber > 0 && madamNumber == 0) {
            if (sirNumber > 1) {
              result.set(EnvelopeSalutationFormats.SIRS_OR_MADAMS, Sirs);
            } else {
              result.set(EnvelopeSalutationFormats.SIR, `Sir`);
            }
          } else if (madamNumber > 0 && sirNumber == 0) {
            if (madamNumber > 1) {
              result.set(EnvelopeSalutationFormats.SIRS_OR_MADAMS, Madams);
            } else {
              result.set(EnvelopeSalutationFormats.MADAM, `Madam`);
            }
          }
        }
      }
    }

    return result;
  }

  formatNameWithoutGender(participant: MatterParticipantWrapper) {
    let formattedName: string = '';
    if (participant.matterParticipant && participant.matterParticipant.contact && participant.matterParticipant.contact.gender === 'QUESTION') {
      let firstLetterMiddleName = participant.matterParticipant.contact.contactName.middleName ? participant.matterParticipant.contact.contactName.middleName[ 0 ] + '. ' : '';
      let firstName = participant.matterParticipant.contact.contactName.firstName ? participant.matterParticipant.contact.contactName.firstName + ' ' : '';
      let lastName = participant.matterParticipant.contact.contactName.lastName ? participant.matterParticipant.contact.contactName.lastName : '';

      if (participant.matterParticipant.contact.contactName.middleName) {
        formattedName = `${ firstName }${ firstLetterMiddleName }${ lastName }`;
      } else {
        formattedName = `${ firstName }${ lastName }`;
      }
    }
    return formattedName;

  }

}
