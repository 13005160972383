import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Address} from '../../matters/shared/address';
import {BasicUserInfo} from '../../matters/shared/basic-user-info';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import {ContactFieldsDiff} from '../../matters/shared/contact-field-diff';

export const AdjudicationRequestRouteBase = 'main/adjudication/adjudication-requests';
export const AdjudicationRequestRouteList = 'main/adjudication/adjudication-requests/list';

export class AdjudicationRequestContactTypes {
  public static readonly INSURANCE_BROKER: AdjudicationRequestContactType = 'INSURANCE_BROKER';
  public static readonly LAW_FIRM: AdjudicationRequestContactType = 'LAW_FIRM';
  public static readonly MANAGEMENT_COMPANY: AdjudicationRequestContactType = 'MANAGEMENT_COMPANY';
  public static readonly MORTGAGE_BROKER: AdjudicationRequestContactType = 'MORTGAGE_BROKER';
  public static readonly MORTGAGEE: AdjudicationRequestContactType = 'MORTGAGEE';
  public static readonly REALESTATEBROKER: AdjudicationRequestContactType = 'REALESTATEBROKER';
  public static readonly SOLICITOR: AdjudicationRequestContactType = 'SOLICITOR';
  public static readonly SURVEYOR: AdjudicationRequestContactType = 'SURVEYOR';
}

export class AdjudicationRequestTypes {
  public static readonly NEW: AdjudicationRequestType = 'NEW';
  public static readonly UPDATE: AdjudicationRequestType = 'UPDATE';
}

export class AdjudicationStatusTypes {
  public static readonly ACCEPTED: AdjudicationStatusType = 'ACCEPTED';
  public static readonly REJECTED: AdjudicationStatusType = 'REJECTED';
  public static readonly PENDING: AdjudicationStatusType = 'PENDING';
}

export type AdjudicationRequestContactType =
  'INSURANCE_BROKER'
  | 'LAW_FIRM'
  | 'MANAGEMENT_COMPANY'
  | 'MORTGAGE_BROKER'
  | 'MORTGAGEE'
  | 'REALESTATEBROKER'
  | 'SOLICITOR'
  | 'SURVEYOR';
export type AdjudicationRequestType = 'NEW' | 'UPDATE';
export type AdjudicationStatusType = 'ACCEPTED' | 'REJECTED' | 'PENDING';

export class AdjudicationRequestListState {
  isNew: boolean = true;
  rows: AdjudicationRequest[] = [];
  selectedAdjudicationRequests: AdjudicationRequest[] = [];
  sortQuery: string = 'lastUpdatedTimeStamp';
  sortType: string = 'ASC';
  selectedContactTypes: string[] = [];
  selectedProvinces: string[] = [];
  selectedAdjudicationTypes: string[] = [];
  searchText: string;
  lastSearchText: string;
  pageNo: number = 1;
  listEndReached: boolean = false;
  scrollY: number;
  contactFieldsDiff: ContactFieldsDiff;
  showDiffAdjudicationRequestId: number;
}

export class AdjudicationRequest extends BaseEntity {

  constructor(adjudicationRequest?: AdjudicationRequest) {
    super(adjudicationRequest);

    if (adjudicationRequest) {
      this.contactAddress = new Address(adjudicationRequest.contactAddress);
      this.updatedByUser = new BasicUserInfo(adjudicationRequest.updatedByUser);
    }
  }

  id: number;
  globalContactId: number;
  privateContactId: number;
  privateJurisdictionId: number;
  jurisdictionProvinceCode: string;
  globalJurisdictionId: number;
  privateDepartmentId: number;
  globalDepartmentId: number;
  contactType: AdjudicationRequestContactType;
  adjudicationSourceType: string;
  contactName: string;
  contactAddress: Address;
  customerAccountId: number;
  publicAccountId: string;
  accountName: string;
  adjudicationStatus: string;
  adjudicationRequestType: AdjudicationRequestType;
  updatedByUser: BasicUserInfo;
  lastUpdatedTimeStamp: string;
  adjudicationMessage: string;
  internalNotes: string;
  // If the contact is solicitor, we need show law firm name
  organizationName: string;
  firmSelfManaged: boolean;
  globalOrganizationId: number;
  privateOrganizationId: number;
  //UI Data
  adjudicationRequestBurgerMenuItems: BurgerMenuExtendedItem[] = [];

  get isContactAdjudicationRequest(): boolean {
    return this.privateContactId != null;
  }

  get isDepartmentAdjudicationRequest(): boolean {
    return this.privateDepartmentId != null;
  }

  get isJurisdictionAdjudicationRequest(): boolean {
    return this.privateJurisdictionId != null;
  }
}
