import {HttpClient} from '../core';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Matter} from './shared/matter';
import {ProjectMatterCacheService} from '../core/project-matter-cache.service';
import {StatementAdjustmentComponent} from '../matters/statement-adjustment/statement-adjustment.component';

@Injectable()
export class AdjustmentPropagationService {

  constructor(private http: HttpClient, private projectMatterCacheService: ProjectMatterCacheService, public statementAdjustmentComponent: StatementAdjustmentComponent) {
  }

  async callAsynchronously(callback: Function): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    await callback();
    let serviceInstance = this;
    let scheduler = setInterval(() => {

      if (serviceInstance.http.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 500);
    return returnSubject.toPromise();
  }

  async initStatementAdjustmentComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.statementAdjustmentComponent) {
        this.statementAdjustmentComponent.setLocalInstanceMatter(matter);
        await this.statementAdjustmentComponent.initSoAdjComponent();
        await this.statementAdjustmentComponent.setUpStatementOfAdjustment();
      }
    });
  }

  async updateLinkedStatementAdjustments(matter: Matter): Promise<boolean> {
    if (matter.isProjectSale && matter.allStatementAdjustments.some(item => !!item.linkId)) {
      return await this.initStatementAdjustmentComponent(matter);
    } else {
      return true;
    }
  }

  async addProjectLevelStatementOfAdjustments(matter: Matter): Promise<boolean> {
    this.statementAdjustmentComponent.soaUtils.setInitNewProjectSaleMatter(true);
    await this.initStatementAdjustmentComponent(matter);
    this.statementAdjustmentComponent.soaUtils.setInitNewProjectSaleMatter(false);
    return await this.callAsynchronously(async () => {
      let templateMatter = await this.projectMatterCacheService.getMatter(matter.project.templateMatterId).toPromise();
      if (templateMatter) {
        this.statementAdjustmentComponent.soaUtils.setInitNewProjectSaleMatter(true);
        await this.statementAdjustmentComponent.addProjectLevelStatementOfAdjustments(matter, templateMatter);
        this.statementAdjustmentComponent.soaUtils.setInitNewProjectSaleMatter(false);
      }
    });
  }

  async updateProjectLevelStatementOfAdjustments(matter: Matter): Promise<boolean> {
    await this.initStatementAdjustmentComponent(matter);
    return await this.callAsynchronously(async () => {
      let templateMatter = await this.projectMatterCacheService.getMatter(matter.project.templateMatterId).toPromise();
      if (templateMatter) {
        let currentAdjustmentStatusMode = matter.adjustmentStatusMode;
        await this.statementAdjustmentComponent.updateProjectLevelStatementOfAdjustments(matter, templateMatter);
        matter.adjustmentStatusMode = currentAdjustmentStatusMode;
      }
    });
  }

}
