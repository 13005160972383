import {TreeNode} from 'primeng/api';

export class TreeNodeUtil {

  /**
   * determines if scrolling is needed on a TreeNode that has ParcelNode for nodes
   * @param {TreeNode[]} parcelNodes
   * @param {number} parcelId
   * @param {number} minNodes
   * @param {number} searchInLastNodes
   * @returns {boolean}
   */
  static needsScrolling(parcelNodes: TreeNode[], parcelId: number, minNodes: number, searchInLastNodes: number): boolean {

    let nodesNumber: number = 0;
    let indexOfSelectedNode: number = 0;
    parcelNodes.forEach(pn => {
      if (pn.expanded) {
        if (pn.children && pn.children.length > 0) {
          pn.children.forEach(child => {
            nodesNumber++;
          });
        } else {
          if (pn.data.parcel.id === parcelId) {
            indexOfSelectedNode = nodesNumber;
          }
          nodesNumber++;
        }
      } else {
        if (pn.data.parcel.id === parcelId) {
          indexOfSelectedNode = nodesNumber;
        }
        nodesNumber++;
      }
    });
    return nodesNumber > minNodes && indexOfSelectedNode > nodesNumber - searchInLastNodes;
  }
}

