<div class="staff-view-box" (scroll)="onScroll($event)"   [ngClass]="{'height-100-percent' : eventAvailabilityState && eventAvailabilityState.openInModal ,'width-100-percent' : eventAvailabilityState && eventAvailabilityState.openInModal ,'top-0' : eventAvailabilityState && eventAvailabilityState.openInModal}">
    <div class="padding-bottom-10 col-lg-12 header-text"  *ngIf="eventAvailabilityState && !eventAvailabilityState.isOpenForScheduleAssistant">
        <div class="col-lg-4">
            <ul class="legend float-left" *ngIf="currentStaffViewState != 'DAY' && !printPreview">
                <li><span class="busy"></span>Busy</li>
                <li><span class="notSoBusy"></span>Not So Busy</li>
                <li><span class="available"></span>Available</li>
            </ul>
        </div>
        <div class="col-lg-4 text-center">
            <label class="control-label font-18 bold">{{headerText}}</label>
        </div>
        <div class="col-lg-4 text-right">
            <div>
                <span (click)="createAvailabilityData()" class="fa-stack margin-bottom-5" style="font-size: 9px !important;color: #A05E94;">
                                    <span class="fa fa-circle-o fa-stack-2x"></span>
                                    <strong class="fa-stack-1x task-text">
                                    </strong>
                                    </span> Events
                <span class="fa-stack  margin-left-15 margin-bottom-5" style="font-size: 8px !important;color: #CC3369;">
                                      <span class="fa fa-calendar-o fa-stack-2x"></span>
                                      <strong class="fa-stack-1x app-text"></strong>
                                    </span> Appointments
            </div>
        </div>

    </div>

    <table class="table table-bordered"  style="height: 1px;" [ngClass]="{'font-size-12' : currentStaffViewState == 'MONTH'}">
        <thead>
        <tr *ngIf="eventAvailabilityState && eventAvailabilityState.isOpenForScheduleAssistant">
            <ng-container *ngFor="let ht of headersText;let j=index;let firstRow = first;let lastRow = last;">
                <th class="bold top-0" *ngIf="firstRow">
                </th>
                <th class="bold top-0" *ngIf="!firstRow" colspan="16">
                    {{ht.text}}
                </th>
            </ng-container>

        </tr>
        <tr>
            <ng-container *ngFor="let colHeader of headers;let j=index;let firstRow = first;let lastRow = last;">
                <th class="bold"
                    [attr.title]="colHeader.isCurrentDay ? 'Current Day '+(colHeader.holidayString ? colHeader.holidayString : '') : (colHeader.holidayString ? colHeader.holidayString : '')"
                    [innerHTML]="colHeader.day"
                    [ngClass]="{'top-0': eventAvailabilityState && eventAvailabilityState.isOpenForScheduleAssistant,'text-right' : firstRow, 'text-center' : !firstRow , 'day-last-child' :  currentStaffViewState == 'DAY' && lastRow && colHeader.length == 0 ,'weekend' :  !!colHeader.isWeekend , 'current-day' : !!colHeader.isCurrentDay , 'holiday' :  !!colHeader.holidayString}">
                </th>
            </ng-container>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let row of rows;let firstDRow = first;let lastDRow = last;">
            <tr *ngIf=" currentStaffViewState == 'DAY'">
                <ng-container *ngFor="let data of row.data;let first = first;let last = last;">
                    <td class="text-right bold padding-left-15 padding-right-15" *ngIf="first">{{data.resource}}</td>
                    <td class="padding-left-0 padding-right-0 day-view-bg padding-top-15 padding-bottom-15"
                        *ngIf="!first && (!last || (!data.nonAppointments && !data.appointments))"
                        [ngClass]="{'text-right' : data.startTime == 'YES'}">
                        <div *ngIf="!data.isMultiple && data.occupancyPercentage > 0 && data.scheduleTime != 'YES'"
                             [popoverTrigger]="popover" [startPosition]="true" [closeOnClickOutside]="true"
                             [popoverData]="data.popoverData"
                             [ngClass]="{'float-right' : data.startTime == 'YES'}"
                             showDelay="250" class="day-view-bar"
                             [ngStyle]="{'width': 'calc(' +data.occupancyPercentage + '%)' }">

                        </div>
                        <div *ngIf="!data.isMultiple && data.occupancyPercentage > 0  && data.scheduleTime == 'YES'"
                             [ngClass]="{'right-0' : data.startTime == 'YES' , 'schedule-left-bar': data.startTime == 'YES', 'schedule-right-bar': data.endTime == 'YES','schedule-top-bar' : firstDRow ,'schedule-bottom-bar': lastDRow}"
                             class="schedule"
                             [ngStyle]="{'width': 'calc(' +data.occupancyPercentage + '%)' }">

                        </div>
                        <ng-container *ngIf="data.isMultiple">
                            <ng-container
                                *ngFor="let occupancyPercentageObj of data.occupancyPercentageList;let firstObj = first;">
                                <div *ngIf="occupancyPercentageObj.occupancyPercentage > 0  && occupancyPercentageObj.scheduleTime != 'YES'" [startPosition]="true"
                                     [popoverTrigger]="popover" [closeOnClickOutside]="true"
                                     [popoverData]="occupancyPercentageObj.popoverData"
                                     [ngClass]="{'margin-top-15': !firstObj,'float-right' : occupancyPercentageObj.startTime == 'YES' , 'margin-left-1' : (occupancyPercentageObj.startTime == 'YES' && occupancyPercentageObj.occupancyPercentage < 100)}"
                                     showDelay="250" class="day-view-bar"
                                     [ngStyle]="{'width': 'calc(' +occupancyPercentageObj.occupancyPercentage + '%)' }">

                                </div>
                                <div *ngIf="occupancyPercentageObj.occupancyPercentage > 0  && occupancyPercentageObj.scheduleTime == 'YES'"
                                     [ngClass]="{'right-0' : occupancyPercentageObj.startTime == 'YES' , 'schedule-left-bar': occupancyPercentageObj.startTime == 'YES', 'schedule-right-bar': occupancyPercentageObj.endTime == 'YES','schedule-top-bar' : firstDRow ,'schedule-bottom-bar': lastDRow}"
                                     class="schedule"
                                     [ngStyle]="{'width': 'calc(' +occupancyPercentageObj.occupancyPercentage + '%)' }">

                                </div>
                            </ng-container>
                        </ng-container>

                    </td>
                    <td class="day-last-child" *ngIf="last && (data.nonAppointments || data.appointments)"
                        [popoverTrigger]="popover" [closeOnClickOutside]="true" [popoverData]="data">
                        <div class="info-box display-flex align-center"
                             *ngIf="( (data.nonAppointments && data.nonAppointments.length > 0) || (data.appointments && data.appointments.length > 0))">
                                    <span class="fa-stack margin-right-5 task-info"
                                          *ngIf="data.nonAppointments && data.nonAppointments.length > 0">
                                    <span class="fa fa-circle-o fa-stack-2x"></span>
                                    <strong class="fa-stack-1x task-text">
                                        {{data.nonAppointments.length}}
                                    </strong>
                                    </span>

                            <span class="fa-stack margin-left-5 app-info"
                                  *ngIf="data.appointments && data.appointments.length > 0">
                                      <span class="fa fa-calendar-o fa-stack-2x"></span>
                                      <strong class="fa-stack-1x app-text">{{data.appointments.length}}</strong>
                                    </span>

                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="currentStaffViewState != 'DAY'">
                <ng-container *ngFor="let data of row.data;let i=index;">
                    <td class="bold"
                        *ngIf="i==0">{{data.resource}}</td>
                    <td class="text-center" *ngIf="i > 0">
                        <ng-container *ngIf="currentStaffViewState == 'WEEK'">
                            <div class="info-box display-flex align-center"
                                 *ngIf="( (data.nonAppointments && data.nonAppointments.length > 0) || (data.appointments && data.appointments.length > 0))"
                                 [popoverTrigger]="popover" [closeOnClickOutside]="true" [popoverData]="data"
                                 [ngClass]="data.cssClass">
                                    <span class="fa-stack task-info "
                                          [ngClass]="{'margin-right-10': data.appointments && data.appointments.length > 0}"
                                          *ngIf="data.nonAppointments && data.nonAppointments.length > 0">
                                    <span class="fa fa-circle-o fa-stack-2x"></span>
                                    <strong class="fa-stack-1x task-text">
                                        {{data.nonAppointments.length}}
                                    </strong>
                                    </span>

                                <span class="fa-stack app-info"
                                      *ngIf="data.appointments && data.appointments.length > 0">
                                      <span class="fa fa-calendar-o fa-stack-2x"></span>
                                      <strong class="fa-stack-1x app-text">{{data.appointments.length}}</strong>
                                    </span>

                            </div>
                        </ng-container>
                        <ng-container *ngIf="currentStaffViewState == 'MONTH'">
                            <div class="padding-top-10 padding-bottom-10 info-box"
                                 *ngIf="( (data.nonAppointments && data.nonAppointments.length > 0) || (data.appointments && data.appointments.length > 0))"
                                 [popoverTrigger]="popover" [closeOnClickOutside]="true" [popoverData]="data"
                                 [ngClass]="data.cssClass">
                                    <span class="fa-stack task-info"
                                          *ngIf="data.nonAppointments && data.nonAppointments.length > 0">
                                    <span class="fa fa-circle-o fa-stack-2x"></span>
                                    <strong class="fa-stack-1x task-text">
                                         {{data.nonAppointments.length}}
                                    </strong>
                                    </span>

                                <span class="fa-stack padding-top-5 app-info"
                                      *ngIf="data.appointments && data.appointments.length > 0">
                                      <span class="fa fa-calendar-o fa-stack-2x"></span>
                                      <strong class="fa-stack-1x app-text">{{data.appointments.length}}</strong>
                                    </span>

                            </div>
                        </ng-container>
                    </td>

                </ng-container>
            </tr>

        </ng-container>
        </tbody>
    </table>

</div>
<ng-template #popover let-close="close" let-data let-hasBackdrop="hasBackdrop" let-sp="startPosition">
    <ng-container
        *ngIf="( (data.nonAppointments && data.nonAppointments.length > 0) || (data.appointments && data.appointments.length > 0))">
        <div class="svp">
            <div class="upper-arrow-popover" *ngIf="sp"></div>
            <div class="arrow-popover" *ngIf="!sp"></div>
            <div class="staff-view-popover" [ngClass]="{'top-10-height' : sp , 'left-0' :sp}">
                <div class="height-100-percent" style="max-height: 300px;!important;overflow-y: auto;">
                    <ng-container *ngIf="(data.nonAppointments && data.nonAppointments.length > 0)">
                        <div
                            class="task-header">
                            <span><i class="fas fa-tasks padding-right-10 padding-bottom-2"></i></span>Events
                            <span *ngIf="hasBackdrop"><button type="button" class="btn-close pull-right close-btn"
                                                              (click)="close()">&times;</button></span>
                        </div>
                        <div class="task-box">
                            <ng-container *ngFor="let nonAppointment of data.nonAppointments;">
                                <div class="padding-bottom-5 padding-top-10">
                                    <span class="padding-right-10 task-row">{{nonAppointment.eventDescription}}</span>
                                </div>
                                <div class="border-bottom-sep">
                                                <span
                                                    class="padding-right-10 border-right border-right-sep">{{nonAppointment.customMatterTypeName ? nonAppointment.customMatterTypeName : nonAppointment.matterType}}</span>
                                    <span
                                        class="padding-right-10 padding-left-10 border-right-sep">{{nonAppointment.matterRecordNumber}}</span>
                                    <span class="padding-left-10">{{(userStateService && userStateService.isdefaultProvinceBC) ? nonAppointment.getBCEventDescription() : nonAppointment.getDefaultEventDescription()}}</span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(data.appointments && data.appointments.length > 0)">
                        <div class="app-header">
                                        <span><i
                                            class="fas fa-calendar-alt padding-right-10 padding-bottom-2"></i></span>Appointments
                            <span *ngIf="hasBackdrop &&  (!data.nonAppointments || data.nonAppointments.length == 0)"><button
                                type="button" class="btn-close pull-right close-btn"
                                (click)="close()">&times;</button></span>
                        </div>
                        <div class="app-box">
                            <ng-container *ngFor="let appointment of data.appointments;">
                                <div class="padding-bottom-5 padding-top-10">
                                    <span class="padding-right-10 app-row" *ngIf="hasBackdrop"><a
                                        (click)="close();openAppointmentModal(appointment);"
                                        title="click to edit appointment">{{appointment.eventDescription}}</a></span>
                                    <span class="padding-right-10 app-row"
                                          *ngIf="!hasBackdrop">{{appointment.eventDescription}}</span>
                                </div>
                                <div class="border-bottom-sep">
                                  {{getAppointmentHoverDescription(appointment)}}
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

