import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {DocumentProductionService} from '../document-production.service';
import {DocumentTemplateCategory} from '../document-template-category';
import {ApplicationError, FieldError} from '../../../core/application-error';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {DocumentTemplate} from '../document-template';
import {Observable} from 'rxjs/Observable';
import {forkJoin} from 'rxjs/observable/forkJoin';
import * as _ from 'lodash';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {MatterTypeUtil} from '../../../shared-main/matter-type/matter-type-util';
import {MatterTypeInfo} from '../../../admin/shared/matter-type-info';

export class ManageDocumentProductionCategoryContext {
  matterTypeCode: string;
  categoryID: number;
  defaultCategoryID: number;
  accountFileFolderId: number;
  accountId: number;
  matterTypeInfos: MatterTypeInfo[] = [];
}

@Component({
  selector: 'dp-manage-document-production-category-modal',
  templateUrl: 'manage-document-production-category.modal.component.html',
  providers: [ErrorService],
  styleUrls: ['../document-production-template.component.scss']
})
export class ManageDocumentProductionCategoryModalComponent
  extends ModalComponent<ManageDocumentProductionCategoryContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  categoryName: string;
  notSelectedList: DocumentTemplate[] = [];
  selectedList: DocumentTemplate[] = [];
  documentTemplateCategory: DocumentTemplateCategory;
  newCategory = false;
  isDefault = false;
  selectedTemplateIDArr: number[] =
    []; /* This array hase 2 elements. element 0 for available ID (right side), Element 1 for selected ID (Left side)
                                             In case of multi-selection each element turns to an array of IDs for all highlighted items
                                             respectively for Left and Right side.
                                           */
  selectedTemplateIndex: number;
  moveUpDownOptionsAvailable: boolean = false; // not available for now see 33275

  constructor(
    public dialog: MatDialogRef<ManageDocumentProductionCategoryModalComponent>,
    public documentProductionService: DocumentProductionService,
    @Inject(MAT_DIALOG_DATA) context?: ManageDocumentProductionCategoryContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.categoryID && this.context.categoryID == this.context.defaultCategoryID) {
      this.isDefault = true;
    }
    this.documentTemplateCategory = new DocumentTemplateCategory();
    let getDefaultCategoryObservable: Observable<DocumentTemplateCategory> =
      this.documentProductionService.getDocumentTemplateCategoryWithFilter(
        this.context.defaultCategoryID,
        this.context.matterTypeCode,
        this.context.accountFileFolderId,
        this.context.accountId
      );
    let getCategoryObservable: Observable<DocumentTemplateCategory>;
    let obsArray = [getDefaultCategoryObservable];
    if (this.context.categoryID && this.context.categoryID != this.context.defaultCategoryID) {
      //Updating an existing category & is not the default
      getCategoryObservable = this.documentProductionService.getDocumentTemplateCategoryWithFilter(
        this.context.categoryID,
        this.context.matterTypeCode,
        this.context.accountFileFolderId,
        this.context.accountId
      );
      obsArray.push(getCategoryObservable);
    }

    forkJoin(obsArray).subscribe((results) => {
      if (this.context.categoryID) {
        //Updating an existing access group

        if (this.context.categoryID == this.context.defaultCategoryID) {
          //Updated category is the default
          this.documentTemplateCategory = <DocumentTemplateCategory>results[0];
          // this.selectedList = (<DocumentTemplateCategory>results[0]).documentTemplates;
          this.selectedList = this.sortList((<DocumentTemplateCategory>results[0]).documentTemplates);
        } else {
          //Updated category is not the default
          this.notSelectedList = this.sortList((<DocumentTemplateCategory>results[0]).documentTemplates);
          // this.notSelectedList = (<DocumentTemplateCategory>results[0]).documentTemplates;
        }
      } else {
        this.newCategory = true;
        this.notSelectedList = this.sortList((<DocumentTemplateCategory>results[0]).documentTemplates);
        // this.notSelectedList = (<DocumentTemplateCategory>results[0]).documentTemplates ;
      }

      if ((<any>results).length == 2) {
        this.documentTemplateCategory = <DocumentTemplateCategory>results[1];
        this.selectedList = this.sortList(this.documentTemplateCategory.documentTemplates);
        // this.selectedList = this.documentTemplateCategory.documentTemplates;
        this.selectedList.forEach((template: DocumentTemplate) => {
          (<any>this.notSelectedList).remove(this.getTemplateByID(this.notSelectedList, template.docGenTemplateId));
        });
      }

      this.selectedTemplateIDArr[0] = this.notSelectedList.length ? this.notSelectedList[0].docGenTemplateId : null;
      this.selectedTemplateIDArr[1] = this.selectedList.length ? this.selectedList[0].docGenTemplateId : null;
    });
  }

  sortList(DocumentTemplates: DocumentTemplate[]): DocumentTemplate[] {
    return _.sortBy(DocumentTemplates, (template: DocumentTemplate) => {
      return template.fileName.toLowerCase();
    });
  }

  getTemplateByID(list: DocumentTemplate[], id: number): DocumentTemplate {
    let template: DocumentTemplate = list.find((temp) => {
      return temp.docGenTemplateId == id;
    });
    return template;
  }

  get disableMoveRight(): boolean {
    return !this.selectedTemplateIDArr.length || (this.selectedTemplateIDArr.length && !this.selectedTemplateIDArr[0]);
  }

  get disableMoveLeft(): boolean {
    return !this.selectedTemplateIDArr.length || (this.selectedTemplateIDArr.length && !this.selectedTemplateIDArr[1]);
  }

  save() {
    if (this.newCategory) {
      this.documentTemplateCategory.applicationType = 'CONVEYANCING';
      this.documentTemplateCategory.applicableMatterTypeCode = this.context.matterTypeCode;
      this.documentTemplateCategory.customerAccountId = this.context.accountId;
      this.documentTemplateCategory.documentTemplates = this.selectedList;
      this.documentTemplateCategory.accountFileFolderId = this.context.accountFileFolderId;
      this.documentProductionService
        .saveDocumentTemplateCategory(this.documentTemplateCategory, this.context.accountId)
        .subscribe(
          (data: DocumentTemplateCategory) => {
            this.dialog.close({
              documentTemplateCategory: data
            });
          },
          (error: ApplicationError) => {
            this.handleApiError(error);
          }
        );
    } else {
      //Update Category
      this.documentTemplateCategory.documentTemplates = this.selectedList;
      this.documentProductionService
        .updateDocumentTemplateCategory(this.documentTemplateCategory, this.context.accountId)
        .subscribe(
          (data: DocumentTemplateCategory) => {
            this.dialog.close({
              documentTemplateCategory: data
            });
          },
          (error: ApplicationError) => {
            this.handleApiError(error);
          }
        );
    }
  }

  changeSelectedTemplate(event) {
    console.log(event);
  }

  handleApiError(error: ApplicationError) {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        'documentProduction.categoryName',
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  delete() {
    let selectedTemplateIDs = this.selectedTemplateIDArr[1];
    if (Array.isArray(selectedTemplateIDs)) {
      selectedTemplateIDs.forEach((selectedTemplateID: number) => {
        (<any>this.selectedList).remove(
          this.selectedList.find((item) => {
            return item.docGenTemplateId == selectedTemplateID;
          })
        );
      });
    }
  }

  moveDown() {
    let oldIndex = this.selectedList.indexOf(
      this.selectedList.find((item) => {
        return item.docGenTemplateId == this.selectedTemplateIDArr[1];
      })
    );
    if (oldIndex != this.selectedList.length - 1) {
      let newIndex = oldIndex + 1;
      this.selectedList.splice(newIndex, 0, this.selectedList.splice(oldIndex, 1)[0]);
    }
  }

  moveUp() {
    let oldIndex = this.selectedList.indexOf(
      this.selectedList.find((item) => {
        return item.docGenTemplateId == this.selectedTemplateIDArr[1];
      })
    );
    if (oldIndex > 0) {
      let newIndex = oldIndex - 1;
      this.selectedList.splice(newIndex, 0, this.selectedList.splice(oldIndex, 1)[0]);
    }
  }

  moveTemplate(
    outList: DocumentTemplate[],
    inLlist: DocumentTemplate[],
    outIndex: number,
    inIndex: number,
    focusID
  ): void {
    let selectedTemplateIDs = this.selectedTemplateIDArr[outIndex];
    if (Array.isArray(selectedTemplateIDs)) {
      selectedTemplateIDs.forEach((templateID: number) => {
        let template = this.getTemplateByID(outList, templateID);
        if (template) {
          inLlist.push(template);
          (<any>outList).remove(template);
        }
      });
      this.selectedTemplateIDArr[inIndex] = this.selectedTemplateIDArr[outIndex];

      if (outList.length) {
        this.selectedTemplateIDArr[outIndex] = outList[0].docGenTemplateId;
      } else {
        this.selectedTemplateIDArr[outIndex][0] = null;
      }
      document.getElementById(focusID).focus();
      this.notSelectedList = this.sortList(this.notSelectedList);
      this.selectedList = this.sortList(this.selectedList);
    }
  }

  getCategoryDescription(documentTemplateCategory: DocumentTemplateCategory): string {
    return MatterTypeUtil.getDocumentCategoryDescription(documentTemplateCategory, this.context.matterTypeInfos);
  }

  close() {
    this.dialog.close();
  }
}
