<form class="form-horizontal container-fluid custom-modal-container" id="interimEncForm">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-11 padding-left-0">
                <h1>{{header}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="main-level modal-sub-header col-lg-12 outer-modal-noscroll">
            <span class="title margin-bottom-20">Name of Enclosure</span>
        </div>
        <div class="col-lg-12">
            <div class="enclosure-list">
                <div class="form-group margin-left-minus-3" *ngFor="let field of interimEnclosures; let i = index">
                    <dp-checkbox fieldId="field{{i}}">
                        <input id="field{{i}}" name="field{{i}}" type="checkbox"
                            [checked]="isFieldInSelectedList(field.id)" (change)="editSelectedList(field.id)"
                            (keydown.f9)="editSelectedList(field.id)" />
                    </dp-checkbox>
                    <label for="field{{i}}">{{field.enclosureName}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn keyboard-save-action" id="okBtn" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="secondary-button" (click)="loadDefaults()">
                <span>Reset To Defaults</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</form>