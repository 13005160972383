import {Injectable} from '@angular/core';

@Injectable()
export class AppLoaderService {

  private moduleCounter: number = 0;
  isLoaderInitialize: boolean = true;
  isAllModuleLoaded: boolean = true;
  currentStateRoute: string;
  isAotOn: boolean = false;

  get loadedModuleCount(): number {
    return this.moduleCounter;
  }

  set loadedModuleCount(count: number) {
    this.moduleCounter = count;
  }

  get percentageLoading(): number {
    // return this.moduleCounter;
    return 100;
  }

}
