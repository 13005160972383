import {SoaConstants} from '../../admin/soa/soa-constants';
import {SoaTrustLedgerConfigKeys, TrustLedgerExpenditureTypes} from '../shared/soa-trustledger-collection';
import {TrustLedgerItemize} from './itemize/trust-ledger-itemize';
import {ProgressionStatus} from '../statement-adjustment/statement-adjustment';
import {AccountingMatter} from '../accounting/accounting-matter';

export class TrustLedgerMatter extends AccountingMatter {

  id: number;
  itemName: string;
  itemValue: number;
  itemType: string;
  readOnly: boolean;
  isSelected: boolean;
  f9DefaultAmount: number;
  f9ReadOnly: boolean;
  trustLedgerConfigId: number;
  statementConfigurationId: number;

  calculationType: string;
  itemizedMatterTrustLedgers: TrustLedgerItemize[];
  fromMortgageIndex: number;
  fromMortgageIdentifier: number; //UI only, used to link to the Mortgage when during mortgage reorder
  matterTaxId: number; // for trust ledger items related to property tax roll number
  progressionStatus: string;
  linkKey: string;
  matterSoaSheetId: number;
  userOverriddenValue: boolean;
  rcvdOrDisbDate: string;

  constructor(trustLedgerMatter?: any) {
    super(trustLedgerMatter);
    this.itemizedMatterTrustLedgers = [];
    if (trustLedgerMatter && Array.isArray(trustLedgerMatter.itemizedMatterTrustLedgers)) {
      for (let trustLedgerItem of trustLedgerMatter.itemizedMatterTrustLedgers) {
        this.itemizedMatterTrustLedgers.push(new TrustLedgerItemize(trustLedgerItem));
      }
    }
    this.readOnly = (this.itemizedMatterTrustLedgers.length > 0 || this.isCashShortFall);
    this.readOnly = this.readOnly || (this.itemKey == TrustLedgerExpenditureTypes.BF_A); //automatically added from Mortgage tab
    if (isNaN(this.fromMortgageIndex)) {
      this.fromMortgageIndex = -1;
    }

    if (!trustLedgerMatter || trustLedgerMatter && !trustLedgerMatter.progressionStatus) {
      this.progressionStatus = ProgressionStatus.FINAL;
    }

  }

  get isExpenditure(): boolean {
    return (this.itemType == SoaConstants.TRUST_LEDGER_EXPENDITURE);
  }

  get isBrokerFee(): boolean {
    return (this.itemKey == TrustLedgerExpenditureTypes.BF_A || this.itemKey == TrustLedgerExpenditureTypes.BF);
  }

  get isPaidDischargedFee(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_PDF_A || this.itemKey == SoaTrustLedgerConfigKeys.L1_PDF);
  }

  get isLateClosingInterest(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_LC || this.itemKey == SoaTrustLedgerConfigKeys.L1_LCE);
  }

  get isSupplementalTaskExpenditure(): boolean {
    return (this.itemKey === TrustLedgerExpenditureTypes.SE_A || this.itemKey === TrustLedgerExpenditureTypes.SE);
  }

  get isMortgageFee(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_MG);
  }

  get isDiscahrgedPreviousMortgage(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_PM);
  }

  get isReceivedDepositHeldInTrust(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_RDHIT);
  }

  get isReceivedFromYou(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_RF);
  }

  get isPaidToYou(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_PY);
  }

  get isCustom(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.CUSTOM_FROM || this.itemKey == SoaTrustLedgerConfigKeys.CUSTOM_TO);
  }

  get isPaidToVendor(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_PV);
  }

  get isLegalFees(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_LF);
  }

  get isOntarioLTT(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_O);
  }

  get isTorontoLTT(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L2_T);
  }

  get isOntarioTorontoLTT(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_O || this.itemKey == SoaTrustLedgerConfigKeys.L2_T || this.itemKey == SoaTrustLedgerConfigKeys.L1_OT);
  }

  get isCashShortFall(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_CS);
  }

  get isEMP(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_EF);
  }

  get isTitleInsurance(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_TI);
  }

  get isTitleRegistration(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_TR);
  }

  get isMortgageRegistration(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_MR);
  }

  isLTT(itemKey: string, configId: number): boolean {
    return (this.statementConfigurationId == configId && this.itemKey == itemKey);
  }

  isNrst(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_NR);
  }

  get isPropertyTaxPaidFromTrustAccount(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_TAX_PAID_FROM_TRUST_ACCOUNT);
  }

  isOntarioTorontoLTTAvailable(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_OT);
  }

  get itemizeTotal(): number {
    let itemizeTotal = 0;
    for (let i = 0; i < this.itemizedMatterTrustLedgers.length; i++) {
      itemizeTotal = itemizeTotal + Number(this.itemizedMatterTrustLedgers[ i ].itemAmount);
    }
    return itemizeTotal;
  }

  get itemizedLabelForLtt(): string {
    return this.isOntarioLTT ? 'Paid Ontario Land Transfer Tax'
      : this.isTorontoLTT ? 'Paid Toronto Land Transfer Tax' : 'Paid Ontario and Toronto Land Transfer Tax';

  }

  isItemized(): boolean {
    return (this.itemizeTotal > 0 || (this.itemizedMatterTrustLedgers && this.itemizedMatterTrustLedgers.length > 0 && this.itemizedMatterTrustLedgers.findIndex(item => item.isItemNameAdded()) > -1));
  }

  addNewTrustLedgerItems(): void {
    if (!this.isItemized()) {
      this.itemizedMatterTrustLedgers = [];
      let trustLedgerItemizeTL = new TrustLedgerItemize();
      trustLedgerItemizeTL.itemAmount = this.itemValue;
      trustLedgerItemizeTL.readOnly = false;
      this.itemizedMatterTrustLedgers.push(trustLedgerItemizeTL);

      let trustLedgerItemize = new TrustLedgerItemize();
      trustLedgerItemize.itemAmount = 0;
      //trustLedgerItemize.identifier = -Date.now() + trustLedgerMatter.itemizedMatterTrustLedgers.length;
      trustLedgerItemize.readOnly = false;
      this.itemizedMatterTrustLedgers.push(trustLedgerItemize);
    }
  }

  get isItemNameAlwaysEditable(): boolean {
    //the Items with the following keys are inserted through TrustLeger=>OtherExpenditure Menu,
    //their itemName are all editable even when 'Automatically insert/update all F9 value as required' is checked
    return this.itemKey == TrustLedgerExpenditureTypes.BF || this.itemKey == TrustLedgerExpenditureTypes.SE || this.itemKey == SoaTrustLedgerConfigKeys.L1_PDF;
  }

}
