import {Component, Inject, OnInit} from '@angular/core';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {AuthNService} from '../../core/auth-n.service';
import {LoginDTO} from '../../login';
import {User} from '../../matters/shared/user';
import {ApplicationError, HttpClient} from '../../core';
import {userAccountProfilesApi} from '../../admin/shared/user-account-profiles-api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {DppmCookieService} from '../dppm-cookie.service';
import {MatDialog} from '@angular/material/dialog';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class ConfirmPasswordModalContext {
  user: User;
  oldToken: string;
  fullLoginFlag: boolean;
}

@Component({
  selector: 'dp-confirm-password-modal-content',
  templateUrl: 'confirm-password.modal.component.html',
  providers: [ ErrorService ]
})

export class ConfirmPasswordModalComponent extends ModalComponent<ConfirmPasswordModalContext> implements OnInit {
  verifyPassword: string;
  okBtnDisabled: boolean = false;

  constructor(public dialog: MatDialogRef<ConfirmPasswordModalComponent>,
              public matDialog: MatDialog,
              public authNService: AuthNService,
              public httpClient: HttpClient,
              public cookieService: DppmCookieService,
              public errorService: ErrorService,
              @Inject(MAT_DIALOG_DATA) context?: ConfirmPasswordModalContext
            ) {
              super(dialog, context);
  }

  next() {
    if (this.validatePassword()) {
      let verifyRequest = new LoginDTO();
      verifyRequest.loginId = this.context.user.loginId;
      verifyRequest.password = this.verifyPassword;
      verifyRequest.publicAccountId = this.context.user.publicAccountId;

      this.okBtnDisabled = true;
      this.authNService.verifyPassword(verifyRequest).subscribe(
        (res) => {
          this.okBtnDisabled = false;
          this.dialog.close(res);
        },
        (error: ApplicationError) => {
          this.okBtnDisabled = false;
          if (error.errorCode == 'app.invalidateCredential') {
            this.errorService.addDpSaveError(DPError.createDPError('verifyPassword.password.INVALID'));
          }
          if (error.errorCode == 'app.accountLockedOut') {
            this.httpClient.accountLockedOut();
          }
        });
    }

  }

  validatePassword(): boolean {
    this.errorService.clearAllSaveErrors();
    if (!this.verifyPassword) {
      this.errorService.addDpSaveError(DPError.createDPError('verifyPassword.password'));
    }
    return this.errorService.hasNoErrors();
  }

  close() {
    if (this.isExpiredTokenOrSessionFlow()) {
      this.httpClient.sessionExpired();
    }
    this.dialog.close();
    this.matDialog.closeAll();
  }

  ok() {
    if (this.validatePassword()) {
      if (this.context.fullLoginFlag) {
        this.relogin();
      } else {
        this.requestNewToken();
      }
    }
  }

  requestNewToken() {
    let verifyRequest = new LoginDTO();
    verifyRequest.password = this.verifyPassword;
    let url = `${ userAccountProfilesApi.relogin }`;

    this.okBtnDisabled = true;
    this.httpClient.relogin(url, verifyRequest, this.context.oldToken).subscribe(
      (res) => {
        this.okBtnDisabled = false;
        this.dialog.close({newToken: res, fullLoginFlag: false});
      },
      (error: ApplicationError) => {
        this.okBtnDisabled = false;
        if (error.errorCode == 'app.invalidateCredential') {
          this.errorService.addDpSaveError(DPError.createDPError('verifyPassword.password.INVALID'));
        }
        if (error.errorCode == 'app.accountLockedOut') {
          this.httpClient.accountLockedOut();
        }
        if (error.errorCode == 'app.userSessionNotValid') { //This may happen if the session is expired before the user enters the password
          this.relogin();
        }
      });
  }

  //Using the AccountId and UserName with the user provided password to fully authenticate the user to renew his server session
  relogin() {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.tokens);
    let sessionUser: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
    if (sessionUser && sessionUser != null) {
      this.okBtnDisabled = true;
      let authenticatedUser = new User(JSON.parse(sessionUser));
      let user = new LoginDTO();
      user.publicAccountId = authenticatedUser.publicAccountId;
      user.loginId = authenticatedUser.username;
      user.password = this.verifyPassword;
      let userAgentId = this.cookieService.getUserAgentId(user.loginId, user.publicAccountId);
      user.agentId = userAgentId ? userAgentId.agentId : null;
      this.authNService.login(user)
      .finally(() => {
        this.okBtnDisabled = false;
      })
      .subscribe((data: boolean) => {
        let sessionUser: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
        if (sessionUser) {
          let authenticatedUser = new User(JSON.parse(sessionUser));
          if (authenticatedUser.authChallengeRequired || authenticatedUser.passwordChangeRequired) {
            this.close();
            this.httpClient.sessionExpired();
          } else {
            let tokens = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.tokens));
            if (data && tokens && tokens.length) {
              this.dialog.close({newToken: tokens[ 0 ], fullLoginFlag: true});
            }
          }
        }

      }, (error: ApplicationError) => {
        if (error.errorCode == 'app.invalidateCredential') {
          this.errorService.addDpSaveError(DPError.createDPError('verifyPassword.password.INVALID'));
        } else if (error.errorCode == 'app.accountLockedOut') {
          this.httpClient.accountLockedOut();
        } else {
          this.httpClient.sessionExpired();
        }
      });

    } else {
      this.close();
    }

  }

  isExpiredTokenOrSessionFlow(): boolean {
    return !!this.context.oldToken || this.context.fullLoginFlag;
  }

  isUpdateStaffProfileFlow(): boolean {
    return !this.isExpiredTokenOrSessionFlow();
  }

  ngOnInit() {
  }

}
