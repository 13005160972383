export enum Permissions {
  ADMINISTRATION,
  MATTERS,
  PROJECTS,
  MANAGE_PROJECTS,
  PROJECT_MATTERS,
  CONTACT_MANAGEMENT,
  MESSAGING,
  WORK_MANAGEMENT,
  REPORTING,
  ACCOUNT_LIST_VIEW,
  GENERAL_ACCOUNT_MAINTENANCE,
  CONFIGURE_TERAVIEW,
  CUSTOMER_BILLING_INFORMATION,
  FORMS,
  EVENTS,
  MANAGE_ADJUDICATION,
  CUSTOMER_ACCOUNT_LIST,
  CUSTOMER_DETAILS_NOTES,
  CUSTOMER_STAFF,
  CUSTOMER_DOCUMENTS,
  CUSTOMER_SYSTEM_CONFIGURATION,
  DOPROCESS_ACCOUNT_MANAGEMENT,
  ADD_CUSTOMER_ACCOUNT,
  CHICAGO_TITLE_INTEGRATIONS,
  FCT_INTEGRATIONS,
  STEWART_NEXT_STEPS_INTEGRATIONS,
  TELUS_ASSYST_INTEGRATIONS,
  MANAGE_GLOBAL_CONTACTS,
  DEPOSIT_MANAGER,
  EXPORT_FILE_MANAGER,
  OPPORTUNITIES,
  TEMPLATE_AND_CATEGORY_MANAGEMENT,
  CONVEYANCING,
  FIELD_CODE_UI_MAPPING,
  TITLE_PLUS_INTEGRATIONS,
  MANAGE_DATA_MOVE_UTILITY,
  TCOL_INTEGRATIONS
}