import {Component, Inject, OnInit} from '@angular/core';
import {ColorStyle, DpBold, DPThemes, FontColors, ThemeStyle} from '../../shared-main/constants';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';
import {TabsService} from '../../core';
import {ErrorService} from '../../shared/error-handling/error-service';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../matters/shared';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export class AppearanceModalContext {
  tabsService: TabsService;
}

@Component({
  selector: 'dp-appearance-modal-content',
  templateUrl: 'appearance.modal.component.html',
  styleUrls: ['./appearance.modal.component.scss'],
  providers: [ErrorService]
})
export class AppearanceModalComponent extends ModalComponent<AppearanceModalContext> implements OnInit {
  dpThemes = DPThemes;
  dpColors = FontColors;
  themeStyle: ThemeStyle;
  pageFont: number;
  sidebarFontSize: number;
  sidebarFontColor: ColorStyle;
  isSidebarFontBold: boolean;
  landingPageItems: SelectItem[];
  selectedLandingPage: string;

  constructor(
    public dialog: MatDialogRef<AppearanceModalComponent>,
    public userConfigurationService: UserConfigurationService,
    @Inject(MAT_DIALOG_DATA) context?: AppearanceModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.updateAppearnce();
    this.dialog.close();
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    this.getUserConfiguration();
    this.landingPageItems = dropDowns.defaultLandingPageSelection;
  }

  getUserConfiguration(): void {
    this.userConfigurationService.getUserConfiguration().subscribe((uc) => {
      this.themeStyle = uc.configValues.theme;
      this.pageFont = this.userConfigurationService.fontSize;
      this.sidebarFontSize = this.userConfigurationService.sidebarFontSize;
      this.sidebarFontColor = this.userConfigurationService.sidebarFontColor;
      this.isSidebarFontBold = this.userConfigurationService.isSidebarFontBold;
      this.selectedLandingPage = this.userConfigurationService.selectedLandingPage;
      this.updateSampleTextStyle();
      this.updateSampleSidebarStyle();
    });
  }

  updateAppearnce(): void {
    this.userConfigurationService.userConfig.configValues.theme = this.themeStyle;
    this.context.tabsService.themeStyle = this.themeStyle;
    this.userConfigurationService.fontSize = this.pageFont;
    this.userConfigurationService.sidebarFontSize = this.sidebarFontSize;
    this.userConfigurationService.sidebarFontColor = this.sidebarFontColor;
    this.userConfigurationService.isSidebarFontBold = this.isSidebarFontBold;
    this.userConfigurationService.selectedLandingPage = this.selectedLandingPage;
    this.userConfigurationService.updateUserConfiguration();
  }

  setThemeStyle(theme: ThemeStyle) {
    this.themeStyle = theme;
  }

  updatePageFont(): void {
    this.updateSampleTextStyle();
  }

  decreasePageFont(): void {
    if (this.pageFont && this.pageFont > this.userConfigurationService.minPageFontPercentage) {
      this.pageFont -= this.userConfigurationService.fontStep;
      this.updateSampleTextStyle();
    }
  }

  increasePageFont(): void {
    if (this.pageFont && this.pageFont < this.userConfigurationService.maxPageFontPercentage) {
      this.pageFont += this.userConfigurationService.fontStep;
      this.updateSampleTextStyle();
    }
  }

  updateSampleTextStyle(): void {
    jQuery('.page-font-style').css({'font-size': this.pageFont + '%'});
  }

  updateSidebarFontSize(): void {
    this.updateSampleSidebarStyle();
  }

  decreaseSidebarFont(): void {
    if (this.sidebarFontSize && this.sidebarFontSize > this.userConfigurationService.minPageFontPercentage) {
      this.sidebarFontSize -= this.userConfigurationService.fontStep;
      this.updateSampleSidebarStyle();
    }
  }

  increaseSidebarFont(): void {
    if (this.sidebarFontSize && this.sidebarFontSize < this.userConfigurationService.maxPageFontPercentage) {
      this.sidebarFontSize += this.userConfigurationService.fontStep;
      this.updateSampleSidebarStyle();
    }
  }

  updateSampleSidebarStyle(): void {
    jQuery('.sidebar-style').css({'font-size': this.sidebarFontSize + '%'});
    jQuery('.sidebar-style').addClass(this.sidebarFontColor);
    this.isSidebarFontBold ? jQuery('.sidebar-style').addClass(DpBold) : jQuery('.sidebar-style').removeClass(DpBold);
  }

  setFontColor(fontColor: ColorStyle) {
    jQuery('.sidebar-style').removeClass(this.sidebarFontColor);
    this.sidebarFontColor = fontColor;
    this.updateSampleSidebarStyle();
  }

  updateSidebarFontWeight(): void {
    this.updateSampleSidebarStyle();
  }
}
