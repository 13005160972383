import {ContactInfo} from '../contact-info';
import {Matter} from '../matter';
import {ContactInfoType} from '../contact-info-type';
import {DpBooleanValueTypes} from '../dp-boolean';
import {MORTGAGE_BC_HOME_INSURANCE_GST_PERCENTAGE} from '../../../shared-main/constants';

export class MatterFireInsuranceUtil {

  static copyFireInsuranceInfoData(matter: Matter, targetMatter: Matter): void {
    if (matter.fireInsuranceContactInfo) {
      if (targetMatter.fireInsuranceContactInfo) {
        (<any>targetMatter.contactInfo).remove(targetMatter.fireInsuranceContactInfo);
      }

      let matterFireInsuranceContactInfo: ContactInfo = new ContactInfo(matter.fireInsuranceContactInfo);
      matterFireInsuranceContactInfo.clearIds();
      if (!Array.isArray(targetMatter.contactInfo)) {
        targetMatter.contactInfo = [];
      }
      if (!targetMatter.fireInsuranceContactInfo) {
        targetMatter.contactInfo.push(matterFireInsuranceContactInfo);
      }
    }
  }

  static initializedFireInsuranceContactInfo(matter: Matter): void {
    if (!Array.isArray(matter.contactInfo)) {
      matter.contactInfo = [];
    }
    if (!matter.fireInsuranceContactInfo) {
      matter.contactInfo.push(MatterFireInsuranceUtil.createFireInsurerContactInfo(matter));
    }
  }

  static createFireInsurerContactInfo(matter: Matter = undefined): ContactInfo {
    const contactInfo: ContactInfo = new ContactInfo();
    contactInfo.contactInfoType = ContactInfoType.FIRE_INSURER;
    contactInfo.instanceType = 'fireInsurer';
    contactInfo.coverage = 0;
    if (matter?.isMatterProvinceBC) {
      contactInfo.subjectToGst = DpBooleanValueTypes.YES;
      contactInfo.taxRate = MORTGAGE_BC_HOME_INSURANCE_GST_PERCENTAGE;
    }
    return contactInfo;
  }
}
