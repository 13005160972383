import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {ModalResult} from '../../shared-main/enums';
import {ConsiderationLttComponent} from './consideration-ltt.component';
import * as _ from 'lodash';
import {ConsiderationLtt} from './consideration-ltt';

export class ConsiderationLttModalComponentContext {
  public prevConsiderationLtt: ConsiderationLtt;
}

@Component({
  selector: 'dp-consideration-ltt-modal-content',
  templateUrl: 'consideration-ltt.modal.component.html',
  styleUrls: [ './consideration-ltt.modal.component.scss' ]
})

export class ConsiderationLttModalComponent extends ModalComponent<ConsiderationLttModalComponentContext> implements OnInit, AfterViewInit {

  @ViewChild(ConsiderationLttComponent) mainConsiderationLttComponent;

  constructor(public dialog: MatDialogRef<ConsiderationLttModalComponent>, @Inject(MAT_DIALOG_DATA) context?: ConsiderationLttModalComponentContext) {
    super(dialog, context);
  }

  ok() {
    if (this.mainConsiderationLttComponent.considerationLttForm.dirty) {
      this.context.prevConsiderationLtt = _.cloneDeep(this.mainConsiderationLttComponent.considerationLtt);
      this.dialog.close({action: ModalResult.OK, prevConsiderationLtt: this.context.prevConsiderationLtt});
    } else {
      this.close();
    }
  }

  close() {
    this.dialog.close({action: ModalResult.Cancel, prevConsiderationLtt: this.context.prevConsiderationLtt});
  }

  ngOnInit() {
    this.mainConsiderationLttComponent.considerationLttForm.markAsPristine();
  }

  ngAfterViewInit() {
    if (this.mainConsiderationLttComponent && this.mainConsiderationLttComponent.considerationLtt
      && (!this.context.prevConsiderationLtt)) {
      this.context.prevConsiderationLtt = _.cloneDeep(this.mainConsiderationLttComponent.considerationLtt);
    }
  }
}
