import {api, apiV3} from '../common/api';

export const opportunityMatterApi = {

  getOpportunities(accountId: string): string {
    return `${ api }/accounts/${ accountId }/opportunities`;
  },
  createOpportunity(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/opportunities`;
  },
  updateOpportunity(opportunityId: string) {
    return `${ apiV3 }/opportunities/${ opportunityId }`;
  },
  opportunityDetailReport(accountId: string) {
    return `${ api }/accounts/${ accountId }/opportunities/exportToCsv`;
  },
  convertedMattersReport(accountId: string) {
    return `${ api }/accounts/${ accountId }/opportunities/matters/exportToCsv`;
  }

};
