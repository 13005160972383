import {constValues} from '../const-values';
import {DpBooleanValueTypes} from '../dp-boolean';
import {Matter} from '../matter';
import {BrokerCommissionPageLabels} from '../../../shared-main/constants';

export class MatterCommissionUtil {
  static calculateBrokerCommission(matter: Matter, forSeparatePayToRealEstateBroker?: boolean): number {
    if (!matter || !matter.brokerCommission) {
      return 0;
    }

    let vendorTotalCommissionInclHst: number = matter.brokerCommission.vendorCommission.vendorTotalCommissionInclHst;
    let purchaserTotalCommissionInclHst: number = matter.brokerCommission.purchaserCommission.purchaserTotalCommissionInclHst;
    let vendorDeposit: number = matter.calculateBrokerDeposit();

    if (matter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY) {
      if (vendorTotalCommissionInclHst > vendorDeposit) {
        return matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
      }
    }

    if (matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
      if (matter.separateEntriesForCommissionPaidToTrustLedger === DpBooleanValueTypes.NO) {
        let brokerCommissionAmount = 0;
        if (matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER) {
          if (!matter.insertExcessDepositIntoTrustLedger && vendorTotalCommissionInclHst < vendorDeposit) {
            brokerCommissionAmount = matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
          } else {
            brokerCommissionAmount = matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker
              + (vendorTotalCommissionInclHst > vendorDeposit
                ? matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker : (-1) * matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker);
          }
          if (brokerCommissionAmount >= 0) {
            return brokerCommissionAmount;
          } else {
            matter.separateEntriesForCommissionPaidToTrustLedger = DpBooleanValueTypes.YES;
            matter.reCalculateTrustLedgerPaidToRealEstateBroker();
          }
        } else if (matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER) {
          if (!matter.insertExcessDepositIntoTrustLedger && purchaserTotalCommissionInclHst < vendorDeposit) {
            brokerCommissionAmount = matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
          } else {
            brokerCommissionAmount = matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker
              + (purchaserTotalCommissionInclHst > vendorDeposit
                ? matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker : (-1) * matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker);
          }
          if (brokerCommissionAmount >= 0) {
            return brokerCommissionAmount;
          } else {
            matter.separateEntriesForCommissionPaidToTrustLedger = DpBooleanValueTypes.YES;
            matter.reCalculateTrustLedgerPaidToRealEstateBroker();
          }
        } else {
          matter.reCalculateTrustLedgerPaidToRealEstateBroker();
        }
      } else {
        if (matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER) {
          if (vendorTotalCommissionInclHst > vendorDeposit) {
            if (forSeparatePayToRealEstateBroker) {
              return matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
            }
            // the first Trust Ledger pay to broker item is - pay to vendor broker
            return matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
          } else {
            if (forSeparatePayToRealEstateBroker) {
              //there is no separate item in this case
              return 0;
            }
            //the first(only one) Trust Ledger pay to broker item is - pay to purchaser broker
            return matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
          }
        }

        if (matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER) {
          if (forSeparatePayToRealEstateBroker) {
            if (purchaserTotalCommissionInclHst > vendorDeposit) {
              return matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
            } else {
              return 0;
            }
          }
          return matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
        }
        if ([ constValues.depositHeldBy.VENDOR_LAWYER, constValues.depositHeldBy.PURCHASER_LAWYER, constValues.depositHeldBy.BUILDER, constValues.depositHeldBy.DEVELOPER ].indexOf(matter.depositHeldBy) > -1) {
          if (forSeparatePayToRealEstateBroker) {
            if (purchaserTotalCommissionInclHst > vendorDeposit) {
              return matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
            } else {
              return 0;
            }
          }
          return matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
        }
      }
    }
    return 0;
  }

  static getCoreCommissionPayableToPurchaserBrokerLabel(matter: Matter): string {
    if (matter) {
      let purchaserTotalCommissionInclHst: number = matter.brokerCommission.purchaserCommission.purchaserTotalCommissionInclHst;
      let purchaserDeposit: number = matter.calculateBrokerDeposit();

      // Transform all falsey to number 0
      if (!purchaserTotalCommissionInclHst) {
        purchaserTotalCommissionInclHst = 0;
      }

      if (!purchaserDeposit) {
        purchaserDeposit = 0;
      }

      if (!matter.isMatterProvinceBC || matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
        if (matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER) {
          // return 'Commission payable to Purchaser\'s broker';
          return BrokerCommissionPageLabels.Commission_Payable_To_Purchaser_Broker;
        }
        if (matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER) {
          if (purchaserTotalCommissionInclHst >= purchaserDeposit) {
            // return 'Commission payable to Purchaser\'s broker';
            return BrokerCommissionPageLabels.Commission_Payable_To_Purchaser_Broker;
          } else if (purchaserTotalCommissionInclHst < purchaserDeposit) {
            // return 'Excess deposit refundable to Vendor';
            return BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor;
          }
        }
      } else {
        if (purchaserTotalCommissionInclHst >= purchaserDeposit) {
          // return 'Balance of Commission owing';
          return BrokerCommissionPageLabels.Balance_Of_Commission_Owing;
        } else if (purchaserTotalCommissionInclHst < purchaserDeposit) {
          // return 'Excess deposit refundable to Vendor';
          return BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor;
        }
      }
    }

    return BrokerCommissionPageLabels.Commission_Payable_To_Purchaser_Broker;
  }

  static getCoreCommissionPayableToVendorBrokerLabel(matter: Matter): string {
    if (matter) {
      let vendorTotalCommissionInclHst: number = Number(matter.brokerCommission.vendorCommission.vendorTotalCommissionInclHst);
      let vendorDeposit: number = Number(matter.calculateBrokerDeposit());

      // Transform all falsey to number 0
      if (!vendorTotalCommissionInclHst) {
        vendorTotalCommissionInclHst = 0;
      }

      if (!vendorDeposit) {
        vendorDeposit = 0;
      }

      if (matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
        if (matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER) {
          // return 'Commission payable to Vendor\'s broker';
          return BrokerCommissionPageLabels.Commission_Payable_To_Vendor_Broker;
        }

        if (vendorTotalCommissionInclHst >= vendorDeposit) {
          // return 'Commission payable to Vendor\'s broker';
          return BrokerCommissionPageLabels.Commission_Payable_To_Vendor_Broker;
        } else if (vendorTotalCommissionInclHst < vendorDeposit) {
          // return 'Excess deposit refundable to Vendor';
          return BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor;
        }
      } else {
        if (vendorTotalCommissionInclHst >= vendorDeposit) {
          // return 'Balance of Commission owing';
          return BrokerCommissionPageLabels.Balance_Of_Commission_Owing;
        } else if (vendorTotalCommissionInclHst < vendorDeposit) {
          // return 'Excess deposit refundable to vendor';
          return BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor;
        }
      }
    }
    return '';
  }
}
