<style>
    @media print {

        .modal-body {
            display : inline; /* Very important to allow print the fullpage not just the view */
        }

        .modal-header {
            display: none;
        }

        .modal-footer {
            display: none;
        }

        .col-lg-12 {
            width: 100%;
            float: left;
        }

        .col-lg-10 {
            width: 83%;
            float: left;
        }
        .col-lg-8 {
            width: 66%;
            float: left;
        }
        .col-lg-7 {
            width: 58%;
            float: left;
        }
        .col-lg-6 {
            width: 50%;
            float: left;
        }
        .col-lg-5 {
            width: 42%;
            float: left;
        }
        .col-lg-4 {
            width: 33%;
            float: left;
        }
        .col-lg-3 {
            width: 25%;
            float: left;
        }
        .col-lg-2 {
            width: 16%;
            float: left;
        }

        .view-matter .matter-table {
            margin-top: 0px;
        }

        .view-matter .matter-table tbody{
            top: auto;
        }

        .view-matter .matter-table .matter-inner-table {
            width: 830px;
        }
        .view-matter .matter-table .matter-inner-table th, .view-matter .matter-table .matter-inner-table td{
            min-width: 120px;
            max-width: 120px;
            padding: 5px;
        }
        .desc-col {
            min-width: 500px !important;
            max-width: 500px !important;
        }
        .padding-left-20 {
            padding-left: 20px!important;
        }
        .line-heigh-25 {
            line-height: 25px;
        }
        /* To repeat the table header on every print page */
        thead {
            display: table-header-group !important;
        }
    }

</style>

<div  dp-printable-dialog  >
    <div class="form-horizontal">
        <div class="modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Tasks</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <dp-matter-details-for-print
                [matter]="matter"
                [matterOverviewService]="matterOverviewService" >
            </dp-matter-details-for-print>

            <ng-container *ngIf="matter && matter.matterWorkItems && matter.matterWorkItems.length">
                <div class="col-lg-12 view-matter">
                    <table class="matter-table"> <!-- We are adding this top level table to reuse global css -->
                        <tr>
                            <td>
                                <table class="table matter-inner-table"  tabindex="-1" >
                                    <thead>
                                    <tr>
                                        <th class="desc-col">Description</th>
                                        <th>Status</th>
                                        <th>Due Date</th>
                                        <th>Completed</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ng-container *ngFor="let cat of matter.matterWorkItems; let i=index">
                                        <tr class="bold" >
                                            <td class="desc-col">
                                                <div>
                                                    <span class="bold line-heigh-25" >{{cat.name}}</span>
                                                    <span *ngIf="cat.matterWorkItemTasks && cat.matterWorkItemTasks.length"
                                                          [ngClass]="{'red' : cat.numberOfOverdueTasks > 0}"
                                                    > ({{cat.numberOfCompletedTasks}}/{{cat.matterWorkItemTasks.length}})</span>
                                                </div>
                                            </td>
                                            <td colspan="3"></td>
                                        </tr>
                                        <ng-container *ngFor="let task of cat.matterWorkItemTasks; let idx=index">
                                            <tr [ngClass]="{'red': task.isOverDue()}">
                                                <td class="desc-col">
                                                    <div [ngClass]="{'red': task.isOverDue()}" class="padding-left-20">
                                                        <span class="line-heigh-25" >{{task.name}}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div [ngClass]="{'red': task.isOverDue()}">
                                                        {{getStatusText(task)}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div [ngClass]="{'red': task.isOverDue()}">
                                                        {{task.dueDate | date:'mediumDate'}}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="task.isStatusCompleted()" [ngClass]="{'red': task.isOverDue()}">
                                                        {{task.completedDate | date:'mediumDate'}} - {{task.completedByUserInitials}}
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </ng-container>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
</div>

