<form class="form-horizontal" id="mortgage-priority-modal">
    <ng-container *ngIf="!updateMortgagePriority">
        <div class="modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>{{matter && matter.isSale ? 'VTB Mortgage Information' : 'New Mortgage Information'}}</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-6">
                    Priority of Mortgage
                </label>
                <div class="col-lg-2">
                    <select class="form-control"
                            name="priority" id="priority"
                            [(ngModel)]="priority">
                        <option *ngFor="let item of mortgagePriority" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="ok()"
                        class="dp-btn">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="updateMortgagePriority">


        <div class="modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Mortgage Priorities</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <div  *ngIf="mortgagePriorityItems">
                <div class="form-group">
                <div class="btn2 col-lg-12 text-right padding-bottom-20">
                    <input
                            type="button"
                            class="action primary-button"
                            [disabled]="isMoveUpDisabled"
                            (click)="moveUp()"
                            value="Move Up">
                    <input
                            type="button"
                            class="action primary-button padding-0"
                            [disabled]="isMoveDownDisabled"
                            (click)="moveDown()"
                            value="Move Down">

                </div>
                </div>

                <div class="form-group">
                    <table class="table soa-table" sticky-table-header id="sticky-table-t" [tableHeightVariance]="'100'">
                        <thead>
                        <tr>
                            <th>Priority</th>
                            <th>Name</th>
                            <th>Mortgage Type</th>
                            <th>Balance Owing Principal Amount</th>
                            <th>Mortgage Loan No</th>
                        </tr>
                        </thead>

                        <tbody>
                       <tr *ngFor="let item of mortgagePriorityItems; let i=index;" (click)="selectedItem(item)" (keyup)="keyUpSelectedItem($event,item)"
                           [ngClass]="{'selected-active': item.isSelected }">
                           <ng-container *ngIf="item.mortgage && item.mortgage.mortgageType">
                               <td>{{i+1}}</td>
                               <td>{{getMortgagees(item.mortgage)}}</td>
                               <td>{{item.mortgage.isExistingMortgage() ? 'Existing Mortgage':'New Mortgage'}}</td>
                               <td>{{item.mortgage.isExistingMortgage() ? ((item.mortgage.amountPayableToDischarge != undefined ? item.mortgage.amountPayableToDischarge : 0)  |
                                   currency:CAD:'symbol') :
                                   (item.mortgage.mortgageTerm?.principal |
                                   currency:CAD:'symbol')}}</td>
                               <td>{{item.mortgage.loanNo}}</td>
                           </ng-container>
                           <ng-container *ngIf="item.mortgage && !item.mortgage.mortgageType">
                               <td>{{i+1}}</td>
                               <td></td>
                               <td></td>
                               <td></td>
                               <td></td>
                           </ng-container>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn2"
                        (click)="updatePriority()"
                        class="dp-btn">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </ng-container>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>


