import {PurchaserCapacity} from '../purchaser/capacity';
import {Matter} from './matter';
import {Address, SameAsAddressOption} from './address';
import {AddressDropdowns} from '../../shared-main/address-Form';
import {AddressTypes} from './address-types';
import {dropDowns} from './matter-drop-downs';
import {CapacityTypes} from '../purchaser/capacity/purchaser-capacity';
import {ContactInfo} from './contact-info';

export class TitleDetailsUtil {

  //This Util method is used to update the Title Details on MatterProperty.
  static generateAndUpdateTitleDetailsSkMb(matter: Matter): string {
    if (matter.mainClients && matter.mainClients.length > 0 && !matter.isTitleDetailsManual()) {
      if (matter.isIndividualTitleDetails()) {
        TitleDetailsUtil.generateAndUpdateIndividualTitleDetailsSkMb(matter);
      } else {
        TitleDetailsUtil.generateAndUpdateCommonTitleDetailsSkMb(matter);
      }
    }
    if (!matter.isTitleDetailsManual() && (!Array.isArray(matter.mainClients) || matter.mainClients.length == 0)) {
      matter.matterContactInfo.titleDetails = null;
    }
    return matter.matterContactInfo.titleDetails;
  }

  static generateAndUpdateCommonTitleDetailsSkMb(matter: Matter) {
    if (matter.mainClients && matter.mainClients.length > 0 && !matter.isTitleDetailsManual()) {
      let clientNames = [];
      matter.mainClients.forEach(item => {

        let genericName = item.contact && item.contact.genericFormatName;

        if (genericName) {
          if (matter.purchasersCapacity == 'OTHER') {
            genericName = genericName + ' as to a ' + item.purchaserShare + '/100 interest ';
          }
          clientNames.push(genericName);
        }
      });
      let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
      if (matter.isResidingPpropertyNoABMBSKTabB()) {
        formattedClientName += this.getAddressResidingPpropertyNoMbTabB(matter);
      } else {
        formattedClientName += ((!matter.matterPropertyWithCondo.address.isEmpty) ? (' \n' + matter.matterPropertyWithCondo.address.addressTextWithProvinceName) : (' \n' + '__________________________'));
      }
      /*if(!this.matter.matterPropertyWithCondo.address.isEmpty){
       formattedClientName += ' \n' + this.matter.matterPropertyWithCondo.address.addressTextWithProvinceName;
       }else{
       formattedClientName += ' \n' + '__________________________';
       }*/
      if (matter.purchasersCapacity == 'JOINT_TENANTS' && matter.mainClients.length > 1) {
        formattedClientName = formattedClientName + ' \n' + 'as joint tenants';
      } else if (matter.purchasersCapacity == 'TENANTS_IN_COMMON_SPLIT_SHARES' && matter.mainClients.length > 1) {
        formattedClientName = formattedClientName + ' \n' + 'Each ' + PurchaserCapacity.getDynamicValueInterestMbSK(matter.mainClients.length);
      } else if (matter.purchasersCapacity == 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES' && matter.mainClients.length > 1) {
        formattedClientName = formattedClientName + ' \n' + 'joint tenants with no survivorship';
      }

      matter.matterContactInfo.titleDetails = formattedClientName;
    }
    if (!matter.isTitleDetailsManual() && (!Array.isArray(matter.mainClients) || matter.mainClients.length == 0)) {
      matter.matterContactInfo.titleDetails = null;
    }
    return matter.matterContactInfo.titleDetails;
  }

  static generateAndUpdateIndividualTitleDetailsSkMb(matter: Matter, contactInfo?: ContactInfo) {
    let clientNames = [];
    const partcipants = contactInfo ? matter.otherSideClients : matter.mainClients;
    const capacity = contactInfo ? matter.otherPartiesCapacity : matter.purchasersCapacity;
    partcipants.forEach(item => {

      let genericName = item.contact && item.contact.genericFormatNameWithoutCapitalize;

      if (genericName) {
        if (capacity == 'OTHER') {
          genericName = genericName + ' as to a ' + (item.purchaserShare ? TitleDetailsUtil.getTenureNumerator(item.purchaserShare) : '0') + '/100 interest ';
        }

        genericName += ((item.contact && item.contact.primaryAddress && !item.contact.primaryAddress.isEmpty) ? (' \n\n' + item.contact.primaryAddress.addressTextWithProvinceName) : (' \n\n' + '__________________________'));
        clientNames.push(genericName);
      }
    });
    let formattedClientName = clientNames.length < 2 ? clientNames.join(', \n\n') : clientNames.slice(0, -1).join(', \n\n') + ' \n\nand\n\n' + clientNames.slice(-1);

    if (capacity == 'JOINT_TENANTS' && partcipants.length > 1) {
      formattedClientName = formattedClientName + ' \n\n' + 'as joint tenants';
    } else if (capacity == 'TENANTS_IN_COMMON_SPLIT_SHARES' && partcipants.length > 1) {
      formattedClientName = formattedClientName + ' \n\n' + 'Each ' + PurchaserCapacity.getDynamicValueInterestMbSK(partcipants.length);
    } else if (capacity == 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES' && partcipants.length > 1) {
      formattedClientName = formattedClientName + ' \n\n' + 'joint tenants with no survivorship';
    }

    if (contactInfo) {
      contactInfo.titleDetails = formattedClientName;
    } else {
      matter.matterContactInfo.titleDetails = formattedClientName;
    }
  }

  static getAddressResidingPpropertyNoMbTabB(matter: Matter): string {
    if (matter.isSale || matter.isMortgage) {
      return ((matter.matterContactInfo.postClosingAddress) ? (' \n' + matter.matterContactInfo.postClosingAddress.addressTextWithProvinceName) : (' \n' + '__________________________'));
    } else if (matter.isPurchase) {
      return this.getCompleteAddressForPurchaseMBSK(matter);
    }
    return ' \n' + '__________________________';
  }

  static getselectedSameAsAddressDescription(matter: Matter): string {
    if (matter.matterContactInfo.postClosingAddress && matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode) {
      if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode === AddressTypes.preClosingAddress) {
        return AddressDropdowns.sameAsPreClosing;
      } else if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode === AddressTypes.subjectAddress) {
        return AddressDropdowns.sameAsSubject;
      } else if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode === AddressTypes.manuallyEntered) {
        return AddressDropdowns.manuallyEntered;
      }
    }
    return '';
  }

  static getCompleteAddressForPurchaseMBSK(matter: Matter): string {
    let selectedSameAsAddressOption: SameAsAddressOption;
    let selectedSameAsAddressDescription: string = this.getselectedSameAsAddressDescription(matter);
    let sameAsAddress: Address;
    if (selectedSameAsAddressDescription !== '') {
      if (selectedSameAsAddressDescription === AddressDropdowns.manuallyEntered) {
        sameAsAddress = matter.matterContactInfo.postClosingAddress;
      } else {

        selectedSameAsAddressOption = this.createPostClosingAddressCopyActionOptions(matter).find(item => {
          if (item.description === selectedSameAsAddressDescription) {
            return true;
          }
        });
        if (selectedSameAsAddressOption && selectedSameAsAddressOption.srcAddress) {
          sameAsAddress = selectedSameAsAddressOption.srcAddress;
        }
      }
    }
    return (sameAsAddress ? (' \n' + sameAsAddress.addressTextWithProvinceName) : (' \n' + '__________________________'));
  }

  static createPostClosingAddressCopyActionOptions(matter: Matter): SameAsAddressOption[] {
    return dropDowns.postClosingAddressCopyOptions.map(item => {
      return {
        description: item,
        srcAddress: item === AddressDropdowns.sameAsSubject
          ? matter.getSubjectPropertyAddress()
          : (item === AddressDropdowns.sameAsPreClosing
            ? (matter.matterContactInfo && matter.matterContactInfo.preClosingAddress)
            : null)
      };
    });

  }

  static generateAndUpdateTitleDetailsNbNs(matter: Matter): string {
    if (matter.mainClients && matter.mainClients.length > 0 && !matter.isTitleDetailsManual()) {
      if (matter.isIndividualTitleDetails()) {
        TitleDetailsUtil.generateAndUpdateTitleDetailsActionNbNs(matter, true);
      } else {
        TitleDetailsUtil.generateAndUpdateTitleDetailsActionNbNs(matter, false);
      }
    }
    if (!matter.isTitleDetailsManual() && (!Array.isArray(matter.mainClients) || matter.mainClients.length == 0)) {
      matter.matterContactInfo.titleDetails = null;
    }
    return matter.matterContactInfo.titleDetails;
  }

  static generateAndUpdateTitleDetailsActionNbNs(matter: Matter, isIndividualTitleDetails: boolean) {
    let clientNames = [];
    matter.mainClients.forEach(item => {

      let genericName = item.contact && item.contact.genericFormatNameWithoutCapitalize;

      if (genericName) {
        switch (matter.purchasersCapacity) {
          case CapacityTypes.OTHER :
            genericName = genericName + ' as to a ' + (item.purchaserShare ? TitleDetailsUtil.getTenureNumerator(item.purchaserShare) : '0') + '/100 interest ';
            break;
          default:
            break;

        }
        if (isIndividualTitleDetails) {
          genericName += ((item.contact && item.contact.primaryAddress && !item.contact.primaryAddress.isEmpty) ? (' \n\n' + item.contact.primaryAddress.addressTextWithProvinceName) : (' \n\n' + '__________________________'));
        }
        clientNames.push(genericName);
      }
    });
    let formattedClientName: string = '';
    if (isIndividualTitleDetails) {
      formattedClientName = clientNames.length < 2 ? clientNames.join(',\n\n') : clientNames.slice(0, -1).join(', \n\n') + '\n\nand\n\n' + clientNames.slice(-1);
    } else {
      formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
      formattedClientName += ((matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && !matter.matterPropertyWithCondo.address.isEmpty)
        ? (' \n' + matter.matterPropertyWithCondo.address.addressTextWithProvinceName) : (' \n' + '__________________________'));
    }

    let sepString = isIndividualTitleDetails ? ' \n\n' : '\n';
    if (matter.purchasersCapacity == CapacityTypes.JOINT_TENANTS && matter.mainClients.length > 1) {
      formattedClientName = formattedClientName + sepString + 'as joint tenants';
    } else if (matter.purchasersCapacity == CapacityTypes.TENANTS_IN_COMMON_SPLIT_SHARES && matter.mainClients.length > 1 && matter.isMatterProvinceNS) {
      formattedClientName = formattedClientName + sepString + PurchaserCapacity.getDynamicValueInterestNS(matter.mainClients.length);
    } else if (matter.purchasersCapacity == CapacityTypes.TENANTS_IN_COMMON_UNSPECIFIED_SHARES && matter.mainClients.length > 1) {
      formattedClientName = formattedClientName + sepString + 'as tenants in common';
    } else if (matter.purchasersCapacity == CapacityTypes.REGISTERED_OWNER && matter.isMatterProvinceNS) {
      formattedClientName = formattedClientName + sepString + 'as registered owner';
    }

    matter.matterContactInfo.titleDetails = formattedClientName;
  }

  // Tenure/Share value should be displayed without decimals if there are no decimal values and if there are any decimal values then display decimal value until first zero
  // Example -
  // 33.00000 will be displayed as 33
  // 33.50000 will be displayed as 33.50
  // 33.55600 will be displayed as 33.5560
  static getTenureNumerator(tenure: string): string {
    let ret: string = '0';
    if (tenure) {
      if (Number(tenure) === Number.NaN) {
        ret = tenure;
      } else {
        ret = Number(tenure) + '';//change number to string
        if (tenure.includes('.') && !Number.isInteger(Number(tenure)) && tenure.endsWith('0')) {
          ret += '0';
        }
      }
    }
    return ret;
  }

  static getAndUpdateTitleDetails(matter: Matter): string {
    if (matter) {
      switch (matter.provinceCode) {
        case 'AB':
          return matter.generateAndUpdateTitleDetailsAB();
        case 'SK':
        case 'MB':
          return TitleDetailsUtil.generateAndUpdateTitleDetailsSkMb(matter);
        case 'NB':
        case 'NS':
          return TitleDetailsUtil.generateAndUpdateTitleDetailsNbNs(matter);
        default :
          return matter.matterContactInfo && matter.matterContactInfo.titleDetails;
      }
    } else {
      return '';
    }
  }
}
