<div class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Teranet Connect &trade;: Teranet Docket Summary</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12" *ngFor="let sDate of summaryDate; let i=index">
            <span class="radio"  (click)="selectedSummaryDate = sDate; otherSummaryDate=false">
                <input type="radio" name="warningLevelRadio" [checked]="(sDate==selectedSummaryDate) && !otherSummaryDate" >
                <label tabindex="0" [ngClass]="{'focus-first' : i == 0}" (keydown.space)="selectedSummaryDate = sDate" >
                    {{sDate.title}} ({{sDate.startDate | date:'mediumDate'}} <span *ngIf="sDate.startDate!=sDate.endDate">to {{sDate.endDate |
                    date:'mediumDate'}}</span>)</label>
            </span>
        </div>


        <div class="col-lg-2">
        <span class="radio"  (click)="selectOwnDate()">
            <input type="radio" name="warningLevelRadio" [checked]="otherSummaryDate" >
            <label tabindex="0" (keydown.space)="otherSummaryDate=true" >Enter own date</label>
        </span>
        </div>
        <div *ngIf="otherSummaryDate" class="col-lg-10 padding-top-7">
            <div class="col-lg-2 text-right padding-top-5"><label>Start Date</label></div>
            <div class="col-lg-6 ">
                <dp-partial-date
                    #startDate
                    [fieldKey]="startDateMissing? 'matter.teranetConnect.viewDocketSummary.startDate':'matter.teranetConnect.viewDocketSummary.otherDate'"
                    [dateDisable]="true"
                    [dateType]="'birthday'"
                    [disableUntil]="disableDateUntil"
                    dp-error-validator
                    statusBarHelp
                    (dateChange)="onDateChange($event, 0)"
                >
                </dp-partial-date>
            </div>

        </div>
        <div *ngIf="otherSummaryDate" class="col-lg-10 offset-lg-2 padding-top-10 min-height-270">
            <div class="col-lg-2 text-right padding-top-5"><label>End Date</label></div>
            <div class="col-lg-6">
                <dp-partial-date
                    #endDate
                    [fieldKey]="endDateMissing? 'matter.teranetConnect.viewDocketSummary.endDate':'matter.teranetConnect.viewDocketSummary.otherDate'"
                    [dateDisable]="true"
                    [dateType]="'birthday'"
                    [disableUntil]="disableDateUntil"
                    dp-error-validator
                    statusBarHelp
                    (dateChange)="onDateChange($event, 1)"
                >
                </dp-partial-date>
            </div>
        </div>






    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" [disabled]="!isProceedActive()"  (click)="proceed()">
                <span>Proceed</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
