import {
  InterimClosingAdjustmentFormatTypes
} from '../modals/consideration-paid-on-interim-closing/so-adj-consideration-paid-on-interim-closing';

export const tenancyDropDowns = {

  tenantOccupancyTypes: [
    {label: 'Basement', value: 'BASEMENT'},
    {label: '1st Floor', value: 'FIRST_FLOOR'},
    {label: '2nd Floor', value: 'SECOND_FLOOR'},
    {label: '3rd Floor', value: 'THIRD_FLOOR'},
    {label: 'Other', value: 'OTHER'}
  ],

  tenantUnitOccupiedTypes: [
    {label: 'Specify', value: 'SPECIFY'},
    {label: 'Silent', value: 'SILENT'}
  ],

  printingFormatType: [
    {label: 'Final Amount Only', value: 'FINAL_AMOUNT_ONLY'},
    {label: 'Itemized Calculation', value: 'ITEMIZED_CALCULATION'}
  ]

};

export const assumedMortgageDropDowns = {
  combinedOrSeparateTypes: [
    {label: 'Separate', value: 'SEPARATE'},
    {label: 'Combined', value: 'COMBINED'}
  ],

  mortgagePriorityTypes: [
    {label: ' ', value: 0}
  ]

};
export const soAdjFormats = {
  adjustmentHeadingType_AdoptDefault: {label: 'Adopt Default', value: 'ADOPT_DEFAULT'},
  adjustmentHeadingType_Custom: {label: 'Custom', value: 'CUSTOM'},
  adjustmentFormatSettingType_AdoptGlobalFormat: {label: 'Adopt "Global Format" settings', value: 'ADOPT_GLOBAL_FORMAT_SETTINGS'},
  adjustmentFormatSettingType_ApplyCustomFormat: {
    label: 'Apply custom format settings to this adjustment',
    value: 'APPLY_CUSTOM_FORMAT_SETTINGS'
  }
};

export const soAdjDropdowns = {
  headingTypeDropdown: [ soAdjFormats.adjustmentHeadingType_AdoptDefault, soAdjFormats.adjustmentHeadingType_Custom ],
  formattingTyoeDropdown: [ soAdjFormats.adjustmentFormatSettingType_AdoptGlobalFormat, soAdjFormats.adjustmentFormatSettingType_ApplyCustomFormat ]
};

export const soAdjProjectFooters = {
  applyToNone: {label: 'None', value: 'NONE'},
  applyToInterim: {label: 'Interim', value: 'INTERIM'},
  applyToFinal: {label: 'Final', value: 'FINAL'},
  applyToBoth: {label: 'Both Interim and Final', value: 'BOTH'}
};

export const soAdjProjectFooterDropdown = [ soAdjProjectFooters.applyToNone, soAdjProjectFooters.applyToInterim, soAdjProjectFooters.applyToFinal, soAdjProjectFooters.applyToBoth ];

export const creditVendorTaxRebateShowTypes = {
  partOfSalePrice: {label: 'part of Sale Price adjustment (i.e. addition to sale price)', value: 'PART_OF_SALE_PRICE_ADJUSTMENT'},
  separateAdjustment: {label: 'a separate adjustment', value: 'SEPARATE_ADJUSTMENT'},
  separateAdjustmentSilent: {label: 'a separate adjustment - silent', value: 'SEPARATE_ADJUSTMENT_SILENT'}
};

export const creditVendorTaxRebateDropDowns = {
  showAdjustmentAsTypes: [
    creditVendorTaxRebateShowTypes.partOfSalePrice,
    creditVendorTaxRebateShowTypes.separateAdjustment,
    creditVendorTaxRebateShowTypes.separateAdjustmentSilent
  ]

};

export const interimClosingAdjustmentFormat = {
  itemizedAdjustment: {label: 'Itemized adjustment', value: InterimClosingAdjustmentFormatTypes.ITEMIZED_ADJUSTMENT},
  itemizedAdjustmentHeadingsUpperCase: {
    label: 'Itemized adjustment (adjustment headings in upper case)',
    value: InterimClosingAdjustmentFormatTypes.ITEMIZED_ADJUSTMENT_HEADINGS_UPPER_CASE
  },
  creditPurchaserOnly: {label: 'Credit purchaser only', value: InterimClosingAdjustmentFormatTypes.CREDIT_PURCHASER_ONLY}
};

export const considerationPaidOnInterimClosingDropDowns = {
  interimClosingAdjFormatTypes: [
    interimClosingAdjustmentFormat.itemizedAdjustment,
    interimClosingAdjustmentFormat.itemizedAdjustmentHeadingsUpperCase,
    interimClosingAdjustmentFormat.creditPurchaserOnly
  ]

};

export const creditPurchaserWith = {
  unadjustedBalance: {label: 'Unadjusted Balance', value: 'UNADJUSTED_BALANCE'},
  unadjustedBalanceIncludingCredits: {
    label: 'Unadjusted Balance (including credits to P & V)',
    value: 'UNADJUSTED_BALANCE_INCLUDING_CREDITS'
  },
  bdocInInterimSOA: {label: 'BDOC in Interim Statement of Adjustments', value: 'BDOC'}
};

export const amountPaidOnInterimClosingDropDowns = {
  creditPurchaserWithTypes: [
    creditPurchaserWith.unadjustedBalance,
    creditPurchaserWith.unadjustedBalanceIncludingCredits,
    creditPurchaserWith.bdocInInterimSOA
  ]

};
