import {inject, Injectable} from '@angular/core';
import {MatterParticipant} from '../matter-participant';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {MatterParticipantWrapper} from '../matter-participant-wrapper';
import {Contact} from '../contact';
import {defaultTab} from '../../purchaser';
import {ParcelRegister} from '../../../shared-main/teranet/parcel-register';
import {AppConfig} from '../../../shared-main/app-configuration';
import {currentMatter} from '../current-matter';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import {Mortgage} from '../mortgage';
import {MatterMortgageUtil} from '../matter-utils/matter-mortgage-util';
import {UndertakingsConfigService} from '../../../admin/shared/undertaking-config.service';
import {MortgageSoAdjService} from '../../../shared-main/mortgage-so-adj.service';
import {ErrorService} from '../../../shared/error-handling/error-service';

@Injectable({
  providedIn: 'root'
})
export class ImportOwnerDataService {

  appConfig = inject(AppConfig);
  documentProfileService = inject(DocumentProfileService);
  undertakingsConfigService = inject(UndertakingsConfigService);
  mortgageSoAdjService = inject(MortgageSoAdjService);
  errorService = inject(ErrorService);

  get matter() {
    return currentMatter.value;
  }

  public isImportMortgageMenuOptionApplicable(selectedParcelRegister: ParcelRegister): boolean {
    return this.matter.isMatterProvinceON && (this.matter.isSale || this.matter.isMortgage) && selectedParcelRegister
      && selectedParcelRegister.instrumentsNonDeletedNonTransferType().length > 0;
  }

  public async getDefaultDocumentProfile(): Promise<DocumentProfile> {
    let ret = Promise.resolve(null);
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (this.matter && this.matter.documentProfileId) {
      let documentProfile: DocumentProfile = await this.documentProfileService.getById(this.matter.documentProfileId, accountId, true, this.matter).toPromise();
      if (documentProfile) {
        if (documentProfile.miscDocumentProfile && documentProfile.miscDocumentProfile.sameAsDefaultProfileFlag) {
          return this.documentProfileService.getDefaultProfileForAccount(accountId, this.matter.provinceCode).toPromise();
        } else {
          ret = Promise.resolve(documentProfile);
        }
      }
    }
    return ret;
  }

  // Teranet Mortgage Import

  createMockWrapperList(): MatterParticipantWrapper[] {
    //These are purchasers and already come back sorted by priority from the matter getter
    let matterParticipantSorted: MatterParticipant[] = this.matter.mainClients;

    let wrapperList: MatterParticipantWrapper[] = [];
    let length: number = matterParticipantSorted.length;

    for (let i: number = 0; i < length; i++) {
      let contact: Contact = matterParticipantSorted[ i ].contact;
      matterParticipantSorted[ i ].contact = new Contact(contact);
      const matterParticipantWrapper: MatterParticipantWrapper
        = MatterParticipantWrapper.createWrapperFromMatterParticipant(matterParticipantSorted[ i ], defaultTab);
      matterParticipantWrapper.showLabel = true;
      wrapperList.push(matterParticipantWrapper);
    }
    return wrapperList;
  }

  private openImportTeranetMortgageModal(instruments: any[]) {
    {
      let copiedInstruments = instruments.filter(item => item.isCopied);
      if (copiedInstruments && copiedInstruments.length > 0) {
        copiedInstruments.forEach(item => {
          if (item.matterMortgage && item.isCopied) {
            let existingMortgage: Mortgage;
            if (item.matterMortgage.existingMortgageId != undefined) {
              existingMortgage = this.matter.existingMortgages.find(mortgage => mortgage.id == item.matterMortgage.existingMortgageId);
            } else if (this.matter.existingMortgages.length < this.appConfig.getMaxNumberOfMortgages()) {
              existingMortgage = this.matter.addNewExistingMortgage();
            }
            if (existingMortgage) {

              if (item.matterMortgage.mortgageDispositionType) {
                existingMortgage.mortgageDispositionType = item.matterMortgage.mortgageDispositionType;
              }

              existingMortgage.mortgageRequestNo = item.matterMortgage.registrationNo != undefined ?
                item.matterMortgage.registrationNo : existingMortgage.mortgageRequestNo;

              existingMortgage.mortgageRequestDate = item.matterMortgage.registrationDate != undefined ?
                item.matterMortgage.registrationDate : existingMortgage.mortgageRequestDate;

              existingMortgage.financingType = item.matterMortgage.instrumentType != undefined ?
                MatterMortgageUtil.mapTeranetInstrumentTypeToMortgageFinancingType(item.matterMortgage.instrumentType, this.matter.provinceCode)
                : existingMortgage.financingType;

              existingMortgage.teranetSuggestedMortgagees = item.matterMortgage.mortgageesText != undefined ?
                item.matterMortgage.mortgageesText : existingMortgage.teranetSuggestedMortgagees;
              existingMortgage.undertakingDirty = true;

              if (existingMortgage.isMortgageDispositionRemain()) {
                let availableMortgagePriority = this.matter.availableMortgagePriority(existingMortgage ? existingMortgage.mortgagePriority : undefined);
                if (existingMortgage.mortgagePriority == undefined) {
                  existingMortgage.mortgagePriority = Math.min(...availableMortgagePriority.map(item => {
                    return Number(item.value);
                  }));
                }
              }

            }

          }
        });
        this.matter.updateUndertakings(this.undertakingsConfigService);
        this.matter.updateTrustLedgerAndStatementOfAdjustment(this.mortgageSoAdjService);
        this.matter.dirty = true;
      }
    }
  }
}
