import {Component, Inject, OnInit} from '@angular/core';
import {Precedent} from '../../shared/precedent';
import {PrecedentService} from '../../mortgages/mortgage/precedent/precedent.service';
import {PrecedentTypes} from '../../shared/mortgage-precedent-type';
import Utils from '../../../shared-main/utils';
import {User} from '../../shared';
import {RevokeSharing, SharedDocumentsPackage} from '../../document-production/shared-documents-package';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class RevokeDocumentsModalContext {
  sharedDocumentsPackage: SharedDocumentsPackage;
  documentId: number;
}

@Component({
  selector: 'dp-revoke-documents-modal',
  templateUrl: 'revoke-documents-modal.component.html'

})

export class RevokeDocumentsModalComponent extends ModalComponent<RevokeDocumentsModalContext> implements OnInit {
  messagePrecedents: Precedent[] = [];
  selectedPrecedent: any;
  message: string;

  constructor(public dialog: MatDialogRef<RevokeDocumentsModalComponent>,
              public messagePrecedentService: PrecedentService,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) context?: RevokeDocumentsModalContext
  ) {
    super(dialog, context);
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    this.getMessagePrecedents();
    this.updateEditorMessage();
  }

  async getMessagePrecedents(): Promise<void> {
    //Get precedents from backend
    let precedents: Precedent[] = await this.messagePrecedentService.getPrecedents().toPromise();
    if (precedents) {
      this.messagePrecedents = precedents.filter(precedent => precedent.precedentType === PrecedentTypes.MESSAGE);
    }
  }

  updateEditorMessage(): void {

    this.message = '';
    if (this.context.sharedDocumentsPackage) {
      this.addSharedPackageText();
    }
    if (this.selectedPrecedent) {
      let messagePrecedent = this.getSelectedPrecedent();
      if (messagePrecedent) {
        this.message += messagePrecedent.description;
      }
    }
    this.message += this.unityMessageBlock;
  }

  addSharedPackageText() {
    if (this.context.documentId) {
      let selectedDocument = this.context.sharedDocumentsPackage.documents.find(doc => doc.id == this.context.documentId);
      if (selectedDocument) {
        this.message += 'Access to the following document has been withdrawn.  It will no longer be available for your review.\n';
        this.message += selectedDocument.name + '\n\n';
      }

    } else {
      this.message += 'Access to all documents for this matter has been withdrawn.  They will no longer be available for your review.\n\n';
    }
  }

  getSelectedPrecedent() {
    return this.messagePrecedents.find(item => item.id == this.selectedPrecedent);
  }

  get unityMessageBlock(): string {
    let sessionUser: User = Utils.getUserFromSession();
    return '\n\n' + sessionUser.unitySignatureBlock;
  }

  revoke() {
    let revokeSharing = new RevokeSharing();
    revokeSharing.revokeSharingMessageBody = this.message;
    this.dialog.close({revokeSharing: revokeSharing});
  }

}
