import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {Logger} from '@nsalaun/ng-logger';
import {Contact} from '../shared/contact';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Address} from '../shared/address';
import {TabsService} from '../../core/tabs.service';
import {BrokerCommissionPageLabels, Constants, SnapshotBurgerMenuActions} from '../../shared-main/constants';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {ContactService} from '../shared/';
import {currentMatter} from '../shared/current-matter';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {Matter} from '../shared/matter';
import {MatterParticipantWrapper} from '../shared/matter-participant-wrapper';
import {Observable} from 'rxjs/Observable';
import {MatterParticipant} from '../shared/matter-participant';
import {MatterParticipantService} from '../matter-participant-service';
import {Subscription} from 'rxjs/Subscription';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {CommonContactDialogComponent} from '../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {ContactQueryService} from '../../contact/contact-query.service';
import {ContactTab} from '../../contact/contact-tab';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {dropDowns} from '../shared/matter-drop-downs';
import {SaleCommissionConfigService} from '../../admin/sale-commission/sale-commission-config.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {constValues} from '../shared/const-values';
import {SalePriceAdjustment} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {BrokerCommissionDropDowns} from './broker-commission.dropdown';
import {BrokerCommission} from './broker-commission';
import Utils from '../../shared-main/utils';
import {CurrencyPipe} from '@angular/common';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {MatterService} from '../matter.service';
import {SalePriceAdjustmentFactory} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {VendorCommission} from './vendor-commission';
import {PurchaserCommission} from './purchaser-commission';
import {ProjectTab} from '../../projects/shared/project-tab';
import {ProgressionStatus} from '../statement-adjustment/statement-adjustment';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {UUIDUtil} from '../../main/uuid-util';
import {ContactChangesListener} from '../../contact/contact-changes-listener';
import {DocumentProductionService} from '../document-production/document-production.service';
import {
  commissionPaidToDropdown, commissionPaidToDropdownOptions,
  depositHeldByDropdown,
  depositHeldByDropdownOptions
} from '../../shared-main/province-based-dropdowns';
import {SelectItem} from 'primeng/api';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';

declare var jQuery: any;

export const commissionTier1Percent: number = 0.06;
export const commissionTier2Percent: number = 0.04;
export const commissionTier3Percent: number = 0.02;

export interface DdCommissionBasedSaleDropDown {
  label: string;
  value: string;
}

@Component({
  selector: 'dp-broker-commission',
  templateUrl: 'broker-commission.component.html',
  styleUrls: [
    './broker-commission.styles.scss'
  ],
  providers: [ CurrencyPipe, EmailFieldService ]
})
@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class BrokerCommissionComponent implements OnInit, OnDestroy {
  maxCommissionBasedSalePriceOptionsLength: number = 4;
  maxFractDigits: number = 3;
  separateEntriesForCommissionPaidToTrustLedgerOptions = dropDowns.SeparateEntriesForCommissionPaidToTrustLedgerOption;
  commissionPaidToOptions;
  public realEstateAgentSubscription: Subscription;
  public realEstateBrokerSubscription: Subscription;
  public matterChangeSubscription: Subscription;
  saleCommissionConfigSub: Subscription;
  taxRateSub: Subscription;

  realEstateBrokerWrapper: MatterParticipantWrapper;
  realEstateAgentWrapper: MatterParticipantWrapper;
  utils;
  // RealEstateBroker
  realEstateAgents: Contact[] = [];
  realEstateAgentAutoCompleteEmptyFlag: boolean = false;
  realEstateAgentsLoading = false;
  searchTermRealEstateAgentSubject: Subject<string> = new Subject<string>();
  // RealEstateBroker
  realEstateBrokers: any = [];
  realEstateBrokerAutoCompleteEmptyFlag: boolean = false;
  realEstateBrokersLoading = false;
  searchTermRealEstateBroker: Subject<string> = new Subject<string>();

  address: Address;

  // Purchaser
  public purchaserRealEstateAgentSubscription: Subscription;
  public purchaserRealEstateBrokerSubscription: Subscription;

  purchaserRealEstateAgentWrapper: MatterParticipantWrapper;
  purchaserRealEstateBrokerWrapper: MatterParticipantWrapper;
  purchaserRealEstateAgents: Contact[] = [];
  purchaserRealEstateAgentAutoCompleteEmptyFlag: boolean = false;
  purchaserRealEstateAgentsLoading = false;
  purchaserSearchTermRealEstateAgentSubject: Subject<string> = new Subject<string>();
  purchaserRealEstateBrokers: any = [];
  purchaserRealEstateBrokerAutoCompleteEmptyFlag: boolean = false;
  purchaserRealEstateBrokersLoading = false;
  purchaserSearchTermRealEstateBroker: Subject<string> = new Subject<string>();
  //used to track/compare the value change in order to trigger the reevaluate the data for Trust Ledger
  _commissionPayableToPurchaserBroker: number;
  _commissionPayableToVendorBroker: number;
  _commissionTier1: number;
  _commissionTier2: number;

  purchaserAddress: Address;

  // The section of "Is Commission based on a fixed percentage of sale price"
  commissionBasedSalePriceOptions: DdCommissionBasedSaleDropDown[] = [];
  depositHeldByOptions: DdCommissionBasedSaleDropDown[];

  commissionDisbursedByOptions: SelectItem[];
  commissionDisbursedToOptions: SelectItem[];
  brokerReleaseExcessToOptions: SelectItem[];

  appliedTowardsPaymentOfCommissionOptions: DdCommissionBasedSaleDropDown[]
    = BrokerCommissionDropDowns.appliedTowardsPaymentOfCommissionOptions;

  _matter: Matter;

  // isAppliedTowardsPaymentOfCommissionReadyOnly : boolean = false;
  constructor(public logger: Logger,
              public tabsService: TabsService,
              public documentProfileService: DocumentProfileService,
              public matterService: MatterService,
              public contactQueryService: ContactQueryService,
              public contactService: ContactService,
              public globalSaveModelService: GetGlobalSaveModelService,
              public taxRateService: TaxRateService,
              public dialogService: DialogService,
              public emailFieldService: EmailFieldService,
              public contactChangesListener: ContactChangesListener,
              public saleCommissionConfigService: SaleCommissionConfigService,
              public matterParticipantService: MatterParticipantService,
              public documentProductionService: DocumentProductionService,
              public currencyPipe: CurrencyPipe) {
    this.utils = new Utils();
  }

  get matter(): Matter {
    if (this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject() && !(this.tabsService.activeTab as ProjectTab).matter) {
      (this.tabsService.activeTab as ProjectTab).matter = new Matter();
    }
    let matter: Matter = this._matter
      ? this._matter
      : (this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject()
        ? (this.tabsService.activeTab as ProjectTab).matter : currentMatter.value);
    this.emailFieldService.matter = matter;
    this.emailFieldService.key = EmailKeys.matterOpening;

    return matter;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  get isPurchaserBrokerCommissionVisible() {
    return this.matter && this.matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER
      || this.isPurchaserOnlyOfCommissionPaidTo;
  }

  public buildListingAgentWrapper(realEstateAgentParticipant: MatterParticipant): void {
    this.realEstateAgentWrapper = new MatterParticipantWrapper();
    this.buildAgentWrapper(realEstateAgentParticipant, this.realEstateAgentWrapper);
  }

  public buildPurchaserAgentWrapper(realEstateAgentParticipant: MatterParticipant): void {
    this.purchaserRealEstateAgentWrapper = new MatterParticipantWrapper();
    this.buildAgentWrapper(realEstateAgentParticipant, this.purchaserRealEstateAgentWrapper);
  }

  public buildAgentWrapper(realEstateAgentParticipant: MatterParticipant, agentWrapper: MatterParticipantWrapper): void {
    if (realEstateAgentParticipant) {
      agentWrapper.matterParticipant = realEstateAgentParticipant;
      agentWrapper.editMode = false;

      //If real estate agent created with an original contact then only need to update participant state (lock, out-of sync etc) else doesn't need it.
      if (this.isRealEstateAgentCreatedWithSourceContact(agentWrapper.matterParticipant)) {
        //If contact is already locked then no need to reload source contact and sync again
        if (realEstateAgentParticipant.contact
          && realEstateAgentParticipant.contact.sourceContactId
          && !agentWrapper.matterParticipant.sourceContactLockAcquired) {
          agentWrapper.isLoadingParticipantState = true;
          this.contactQueryService
          .getContactForMatter(realEstateAgentParticipant.contact.sourceContactId)
          .subscribe((sourceContact: Contact) => {
            this.matterParticipantService.updateParticipantWrapperState(agentWrapper, sourceContact);
            agentWrapper.isLoadingParticipantState = false;
          });
        }
      }
    } else {
      //Initialize blank broker wrapper
      agentWrapper.editMode = true;
    }
  }

  public buildListingBrokerCompanyWrapper(brokerCompanyParticipant: MatterParticipant): void {
    this.realEstateBrokerWrapper = new MatterParticipantWrapper();
    this.buildBrokerWrapper(brokerCompanyParticipant, this.realEstateBrokerWrapper);
  }

  public buildPurchaserBrokerCompanyWrapper(brokerCompanyParticipant: MatterParticipant): void {
    this.purchaserRealEstateBrokerWrapper = new MatterParticipantWrapper();
    this.buildBrokerWrapper(brokerCompanyParticipant, this.purchaserRealEstateBrokerWrapper);
  }

  public buildBrokerWrapper(brokerCompanyParticipant: MatterParticipant,
                            brokerWrapper: MatterParticipantWrapper): void {
    if (brokerCompanyParticipant) {
      brokerWrapper.matterParticipant = brokerCompanyParticipant;

      //If contact is already locked then no need to reload source contact and sync again
      if (brokerCompanyParticipant.contact && brokerCompanyParticipant.contact.sourceContactId && !brokerWrapper.matterParticipant.sourceContactLockAcquired) {
        brokerWrapper.isLoadingParticipantState = true;
        this.contactQueryService
        .getContactForMatter(brokerCompanyParticipant.contact.sourceContactId)
        .subscribe((sourceContact: Contact) => {
          this.matterParticipantService.updateParticipantWrapperState(brokerWrapper, sourceContact);
          brokerWrapper.isLoadingParticipantState = false;
        });
      }
    } else {
      //Initialize blank broker wrapper
      brokerWrapper.editMode = true;
    }
  }

  initDepositHeldBy(): void {
    if (this.matter.isProjectSale) {
      this.depositHeldByOptions = BrokerCommissionDropDowns.depositHeldByOptionsForProjectSale;
    } else {
      this.depositHeldByOptions = this.matter ? depositHeldByDropdown[ this.matter.provinceCode ] : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault;
    }
  }

  initCommissionDisbursedBy(): void {
    this.commissionDisbursedByOptions = BrokerCommissionDropDowns.commissionDisbursedByBC;
  }

  initCommissionDisbursedTo(): void {
    this.commissionDisbursedToOptions = BrokerCommissionDropDowns.commissionDisbursedToBC;
    if (!this.matter.commissionDisbursedTo) {
      this.matter.commissionDisbursedTo = 'BOTH_VENDOR_AND_PURCHASER_BROKER';
    }
  }

  initBrokerReleaseExcessToOptions(): void {
    this.brokerReleaseExcessToOptions = BrokerCommissionDropDowns.brokerReleaseExcessToBC;
  }

  initLocalBrokerCommissionFields(): void {
    //this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker
    if (this.brokerCommission && this.brokerCommission.purchaserCommission && this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker != null) {
      this._commissionPayableToPurchaserBroker = Utils.roundNumber(this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker, 2);
    }

    //this.brokerCommission.vendorCommission.commissionPayableToVendorBroker
    if (this.brokerCommission && this.brokerCommission.vendorCommission && this.brokerCommission.vendorCommission.commissionPayableToVendorBroker != null) {
      this._commissionPayableToVendorBroker = Utils.roundNumber(this.brokerCommission.vendorCommission.commissionPayableToVendorBroker, 2);
    }

  }

  ngOnInit() {
    // CommissionPaidTo Fields
    this.initCommissionPaidToFields();

    //CommissionBasedSalePrice Fields
    this.initCommissionBasedSalePrice();

    // RealEstateAgent
    this.realEstateAgentSubscription = this.searchTermRealEstateAgentSubject.switchMap((term: string) => {
      setTimeout(() => {
        this.realEstateAgentsLoading = true;
      }, 1);
      this.realEstateAgentAutoCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.realEstateAgentAutoCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToRealEstateAgent()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'REALESTATEAGENT', null, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.realEstateAgentsLoading = false;
        return this.realEstateAgents = data;
      });

    // RealEstateBroker
    this.realEstateBrokerSubscription = this.searchTermRealEstateBroker.switchMap((term: string) => {
      setTimeout(() => {
        this.realEstateBrokersLoading = true;
      }, 1);
      this.realEstateBrokerAutoCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.realEstateBrokerAutoCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToRealEstateBroker()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'REALESTATEBROKER', null, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.realEstateBrokersLoading = false;
        return this.realEstateBrokers = data;
      });

// RealEstateAgent
    this.purchaserRealEstateAgentSubscription = this.purchaserSearchTermRealEstateAgentSubject.switchMap((term: string) => {
      setTimeout(() => {
        this.purchaserRealEstateAgentsLoading = true;
      }, 1);
      this.purchaserRealEstateAgentAutoCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.purchaserRealEstateAgentAutoCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToRealEstateAgent()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'REALESTATEAGENT', null, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.purchaserRealEstateAgentsLoading = false;
        return this.purchaserRealEstateAgents = data;
      });

// Purchaser RealEstateBroker
    this.purchaserRealEstateBrokerSubscription = this.purchaserSearchTermRealEstateBroker.switchMap((term: string) => {
      setTimeout(() => {
        this.purchaserRealEstateBrokersLoading = true;
      }, 1);
      this.purchaserRealEstateBrokerAutoCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.purchaserRealEstateBrokerAutoCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToRealEstateBroker()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'REALESTATEBROKER', null, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.purchaserRealEstateBrokersLoading = false;
        return this.purchaserRealEstateBrokers = data;
      });

    this.matterChangeSubscription = this.globalSaveModelService.getItem$.subscribe(() => {
      this.openBrokerCommission();
    });
    this.initDepositHeldBy();
    this.initCommissionDisbursedBy();
    this.initCommissionDisbursedTo();
    this.initBrokerReleaseExcessToOptions();
    this.initLocalBrokerCommissionFields();
    this.setCommissionBeforeHst(false);
    this.setTotalCommissionPercentage(false);

    this.initForSaleMatterProvinceABAndBC();
  }

  async setF9SellerDefaultDear() {
    if (this.matter) {
      this.matter.sellerBrokerMatterLevelInfo.dear = await this.documentProfileService.getF9DefaultDear(this.matter.documentProfileId, this.matter.provinceCode);
      this.enableSave();
    }
  }

  async setF9PurchaserDefaultDear() {
    if (this.matter) {
      this.matter.purchaserBrokerMatterLevelInfo.dear = await this.documentProfileService.getF9DefaultDear(this.matter.documentProfileId, this.matter.provinceCode);
      this.enableSave();
    }
  }

  isSellerBrokerDearAttentionVisible(): boolean {
    return this.isBrokerDearAttentionVisible(this.realEstateAgentWrapper);
  }

  isPurchaserBrokerDearAttentionVisible(): boolean {
    return (this.isBothOfCommissionPaidTo || this.isPurchaserOnlyOfCommissionPaidTo) && this.isBrokerDearAttentionVisible(this.purchaserRealEstateAgentWrapper);
  }

  isBrokerDearAttentionVisible(selectedRealEstateAgent: MatterParticipantWrapper): boolean {
    return !selectedRealEstateAgent || !selectedRealEstateAgent.matterParticipant || selectedRealEstateAgent.expanded;
  }

  addBlankOptionInDepositHeldBy() {
    if (this.depositHeldByOptions) {
      let optionExist = this.depositHeldByOptions.findIndex(item => item.value === '');
      if (optionExist === -1) {
        this.depositHeldByOptions.splice(0, 0, {label: '', value: ''});
        // this.depositHeldByOptions.push( {label : '', value : ''});
      }
    }
  }

  initForSaleMatterProvinceABAndBC() {
    if ((this.matter.isMatterProvinceAB || this.matter.isMatterProvinceBC) && this.matter.matterType == 'SALE') {
      this.matter.commissionBasedOnFixedPercentageOfSalePrice = 'NO';
    }
  }

  openBrokerCommission(): void {
    console.log('Releasing lock from ngOnInit for matter ' + this.matter.id);
    // Listing Broker
    this.releaseLockFromMatterParticipantsOnAgentTab(this.realEstateAgentWrapper, this.realEstateBrokerWrapper);
    this.buildListingAgentWrapper(this.matter.realEstateAgent);
    this.buildListingBrokerCompanyWrapper(this.matter.realEstateBroker);
    // Purchaser Broker
    this.releaseLockFromMatterParticipantsOnAgentTab(this.purchaserRealEstateAgentWrapper, this.purchaserRealEstateBrokerWrapper);
    this.buildPurchaserAgentWrapper(this.matter.purchaserRealEstateAgent);
    this.buildPurchaserBrokerCompanyWrapper(this.matter.purchaserRealEstateBroker);
  }

  get accountId(): string {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }

  setCommissionDefaultValue() {
    this.matterService.setCommissionPaidTo(currentMatter.value);
  }

  ngOnDestroy(): void {
    // Listing Broker
    this.releaseLockFromMatterParticipantsOnAgentTab(this.realEstateAgentWrapper, this.realEstateBrokerWrapper);
    // Purchaser Broker
    this.releaseLockFromMatterParticipantsOnAgentTab(this.purchaserRealEstateAgentWrapper, this.purchaserRealEstateBrokerWrapper);
  }

  //In case of page refresh angular doesn't call ngDestroy and it's releasing any locks taken on contact. That will leave contact permanently locked
  // therefore calling unlock code on unload as well
  @HostListener('window:unload', [ '$event' ])
  beforeUnloadHandler(event) {
    // Listing Broker
    this.releaseLockFromMatterParticipantsOnAgentTab(this.realEstateAgentWrapper, this.realEstateBrokerWrapper);
    // Purchaser Broker
    this.releaseLockFromMatterParticipantsOnAgentTab(this.purchaserRealEstateAgentWrapper, this.purchaserRealEstateBrokerWrapper);
  }

  releaseLockFromMatterParticipantsOnAgentTab(agentWrapper: MatterParticipantWrapper, brokerWrapper: MatterParticipantWrapper): void {
    console.log('Entering into method releaseLockFromMatterParticipantsOnAgentTabClose for matter ' + (this.matter && this.matter.id) + ' agentWrapper ' + agentWrapper);
    if (agentWrapper && this.isRealEstateAgentCreatedWithSourceContact(agentWrapper.matterParticipant)) {
      agentWrapper.obsolete = true;
      console.log('Unlocking agent from matter ' + (this.matter && this.matter.id) + ' from wrapper matter ' + agentWrapper.matterParticipant.matterId);
      this.matterParticipantService.unlockParticipantOnTabClose(agentWrapper, this.matter);
    }

    //If realEstateBrokerWrapper shutter is open before leaving matter then close it and release the lock
    if (brokerWrapper && brokerWrapper.matterParticipant && brokerWrapper.expanded) {
      brokerWrapper.obsolete = true;
      console.log('Unlocking broker for matter ' + (this.matter && this.matter.id));
      this.toggleBrokerShutter(brokerWrapper);
    }
  }

  //As real estate agent can be created only on matter therefore this method checks if source contact exists or not.
  isRealEstateAgentCreatedWithSourceContact(mp: MatterParticipant) {
    return !!(mp && mp.contact && mp.contact.sourceContactId);
  }

  searchRealEstateAgent(event): void {
    let entered: string = event.query;
    this.searchTermRealEstateAgentSubject.next(entered);
  }

  searchRealEstateBroker(event): void {
    let entered: string = event.query;
    this.searchTermRealEstateBroker.next(entered);
  }

  purchaserSearchRealEstateAgent(event): void {
    let entered: string = event.query;
    this.purchaserSearchTermRealEstateAgentSubject.next(entered);
  }

  purchaserSearchRealEstateBroker(event): void {
    let entered: string = event.query;
    this.purchaserSearchTermRealEstateBroker.next(entered);
  }

  getDeleteModalAgentLabel(isNotSellingOrPurchaseBroker: boolean): string {
    return isNotSellingOrPurchaseBroker ?
      String(provinceBasedFieldLabels.get('matter.broker.commission.purchaserBroker', this.matter.provinceCode)).replace('Selling', 'Listing').replace('Purchaser', 'Listing')
      : provinceBasedFieldLabels.get('matter.broker.commission.purchaserBroker', this.matter.provinceCode);
  }

  deleteSelectedRealEstateAgent(agentWrapper: MatterParticipantWrapper, brokerWrapper: MatterParticipantWrapper): void {
    let deleteConfirmationMessage = `Proceed to remove ${ this.getDeleteModalAgentLabel(brokerWrapper !== this.purchaserRealEstateBrokerWrapper) } and Agent from this matter?`;
    deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, brokerWrapper.matterParticipant, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEAGENT);
    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(
      (response) => {
        if (response) {
          this.documentProductionService.tryToRevokePackage(this.matter, agentWrapper.matterParticipant, this.contactQueryService);
          this.matterParticipantService.removeParticipant(agentWrapper, this.matter);
          //If broker participant exists then remove that too on agent deletion
          if (brokerWrapper && brokerWrapper.matterParticipant) {
            this.documentProductionService.tryToRevokePackage(this.matter, brokerWrapper.matterParticipant, this.contactQueryService);
            this.matterParticipantService.removeParticipant(brokerWrapper, this.matter, this.isRealEstateAgentCreatedWithSourceContact(brokerWrapper.matterParticipant));
            this.handleContactChange(true);
          }
          this.enableSave();
          this.propagateAddAndRemoveBrokerChanges();
        }
      });
  }

  propagateAddAndRemoveBrokerChanges() {
    this.matter.reCalculateTrustLedgerPaidToRealEstateBroker();
  }

  enableSave(): void {
    currentMatter.value.dirty = true;
  }

  get constantNoResultValue() {
    return Constants.NO_RESULTS_FOUND;
  }

  getContactDisplayMailingAddress(contact: Contact): string {
    if (!contact || !contact.id || !contact.mailingAddress) {
      return '';
    }

    return contact.mailingAddress.addressTextWithoutCountryAndPostalCode;
  }

  public onListingBrokerCompanySelected() {
    this.onBrokerCompanySelected(this.realEstateBrokerWrapper, MatterParticipantRoleTypes.REALESTATEBROKER);
  }

  public onPurchaserBrokerCompanySelected() {
    this.onBrokerCompanySelected(this.purchaserRealEstateBrokerWrapper, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER);
  }

  public onBrokerCompanySelected(brokerWrapper: MatterParticipantWrapper,
                                 matterParticipantRoleType: MatterParticipantRole): void {
    if (brokerWrapper.dataModel && brokerWrapper.dataModel.organizationName === 'No records available') {
      brokerWrapper.dataModel = null;
    } else if (brokerWrapper.dataModel && brokerWrapper.dataModel.displayName
      && brokerWrapper.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD) > -1) {
      this.addNewRealEstateBroker(brokerWrapper, matterParticipantRoleType);
      this.enableSave();
    } else if (brokerWrapper.dataModel instanceof Contact) {
      this.enableSave();
      this.selectExistingBroker(brokerWrapper.dataModel, matterParticipantRoleType);
    } else {
      this.enableSave();
      if (brokerWrapper.matterParticipant) {
        brokerWrapper.matterParticipant.contact.organizationName = brokerWrapper.dataModel;
        this.propagateAddAndRemoveBrokerChanges();
      }
    }

  }

  selectExistingBroker(sourceContact: Contact, matterParticipantRoleType: MatterParticipantRole) {
    this.contactQueryService.getContactForMatter(sourceContact.id).subscribe(
      (brokerCompanyForMatter: Contact) => {
        if (matterParticipantRoleType === MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER) {
          this.selectPurchaserBroker(brokerCompanyForMatter, true, matterParticipantRoleType);
        } else {
          this.selectListingBroker(brokerCompanyForMatter, true, matterParticipantRoleType);

        }
        this.propagateAddAndRemoveBrokerChanges();
      }
    );
  }

  selectListingBroker(sourceContact: Contact, openShutter: boolean, matterParticipantRoleType: MatterParticipantRole) {
    // Move new brokerWrapper in outside to avoid change
    this.realEstateBrokerWrapper = new MatterParticipantWrapper();
    this.setCoreContactInBrokerWrapper(sourceContact, openShutter, this.realEstateBrokerWrapper, matterParticipantRoleType);
    if (this.realEstateBrokerWrapper && this.realEstateBrokerWrapper.matterParticipant && this.realEstateAgent) {
      //If broker company changed then setting the reference of new broker id into agent's snapshot
      this.realEstateAgent.sourceParentOrganizationId = this.realEstateBrokerWrapper.matterParticipant.sourceContact.id;
      //In this case making agent snapshot dirty as well because we want to propagate the new reference to the original agent
      this.realEstateAgent.isDirty = true;
    }
  }

  selectPurchaserBroker(sourceContact: Contact, openShutter: boolean, matterParticipantRoleType: MatterParticipantRole) {
    // Move new brokerWrapper in outside to avoid change
    this.purchaserRealEstateBrokerWrapper = new MatterParticipantWrapper();
    this.setCoreContactInBrokerWrapper(sourceContact, openShutter, this.purchaserRealEstateBrokerWrapper, matterParticipantRoleType);
    if (this.purchaserRealEstateBrokerWrapper && this.purchaserRealEstateBrokerWrapper.matterParticipant && this.purchaserRealEstateAgent) {
      //If broker company changed then setting the reference of new broker id into agent's snapshot
      this.purchaserRealEstateAgent.sourceParentOrganizationId = this.purchaserRealEstateBrokerWrapper.matterParticipant.sourceContact.id;
      //In this case making agent snapshot dirty as well because we want to propagate the new reference to the original agent
      this.purchaserRealEstateAgent.isDirty = true;
    }
    this.propagateAddAndRemoveBrokerChanges();
  }

  public onListingRealEstateAgentSelected(): void {
    this.onRealEstateAgentSelected(this.realEstateAgentWrapper, MatterParticipantRoleTypes.REALESTATEAGENT, this.realEstateBrokerWrapper);
  }

  public onPurchaserRealEstateAgentSelected(): void {
    this.onRealEstateAgentSelected(this.purchaserRealEstateAgentWrapper, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEAGENT, this.purchaserRealEstateBrokerWrapper);
  }

  public onRealEstateAgentSelected(agentWrapper: MatterParticipantWrapper, agentMatterParticipantRoleType: MatterParticipantRole, brokerWrapper: MatterParticipantWrapper): void {
    if (agentWrapper.dataModel && agentWrapper.dataModel.fullName === Constants.NO_RESULTS_FOUND) {
      agentWrapper.dataModel = null;
    } else if (agentWrapper.dataModel && agentWrapper.dataModel.displayName
      && agentWrapper.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD) > -1) {
      this.addNewRealEstateAgent(agentWrapper, agentMatterParticipantRoleType, brokerWrapper);
    } else if (agentWrapper.dataModel instanceof Contact) {
      let dataModelContact: Contact = new Contact(agentWrapper.dataModel);
      this.deleteBrokerWithoutAgent(agentMatterParticipantRoleType);
      this.setContactInRealEstateAgentWrapper(agentMatterParticipantRoleType, agentWrapper, dataModelContact, true);
      this.enableSave();
    }
  }

  public deleteBrokerWithoutAgent(agentMatterParticipantRoleType: MatterParticipantRole) {
    let brokerWrapper: MatterParticipantWrapper;
    if (agentMatterParticipantRoleType === MatterParticipantRoleTypes.REALESTATEAGENT) {
      brokerWrapper = this.realEstateBrokerWrapper;
    } else {
      brokerWrapper = this.purchaserRealEstateBrokerWrapper;
    }
    if (brokerWrapper && brokerWrapper.matterParticipant) {
      this.matterParticipantService.removeParticipant(brokerWrapper, this.matter, this.isRealEstateAgentCreatedWithSourceContact(brokerWrapper.matterParticipant));
    }
    this.propagateAddAndRemoveBrokerChanges();
  }

  public addNewRealEstateAgent(agentWrapper: MatterParticipantWrapper,
                               agentMatterParticipantRoleType: MatterParticipantRole,
                               brokerWrapper: MatterParticipantWrapper): void {
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        brokerContact: brokerWrapper && brokerWrapper.matterParticipant && brokerWrapper.matterParticipant.contact,
        contactType: 'REALESTATEAGENT',
        contactName: agentWrapper.dataModel.displayName
      },
      onFulfillment: (result) => {
        if (result && result.contact) {
          this.enableSave();
          this.deleteBrokerWithoutAgent(agentMatterParticipantRoleType);
          if (result.createdNewClient) {
            this.setContactInRealEstateAgentWrapper(agentMatterParticipantRoleType, agentWrapper, new Contact(result.contact));
          } else {
            this.setContactInRealEstateAgentWrapper(agentMatterParticipantRoleType, agentWrapper, new Contact(result.contact), true);
            // Notification is only for Listing Agent.
            // If RealEstateAgent doesn't have sourceContact, UI will create id for this snapshot contact
            if (agentMatterParticipantRoleType == MatterParticipantRoleTypes.REALESTATEAGENT
              && agentWrapper && agentWrapper.matterParticipant && agentWrapper.matterParticipant.contact) {
              agentWrapper.matterParticipant.contact.id = UUIDUtil.getUUID();
            }
          }
        } else {
          agentWrapper.dataModel = null;
        }
      },
      onRejection: (reason: any) => {
        agentWrapper.dataModel = null;
      },
      fullScreen: true,
      
    });

  }

  public addNewRealEstateBroker(brokerWrapper: MatterParticipantWrapper,
                                matterParticipantRoleType: MatterParticipantRole): void {
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        contactType: 'REALESTATEBROKER',
        contactName: brokerWrapper.dataModel.displayName
      },
      onFulfillment: (result) => {
        if (result && result.contact) {
          this.enableSave();
          if (matterParticipantRoleType === MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER) {
            //You can Not use brokerWrapper. "new MatterParticipantWrapper" will create a new reference
            this.selectPurchaserBroker(new Contact(result.contact), false, matterParticipantRoleType);

          } else {
            this.selectListingBroker(new Contact(result.contact), false, matterParticipantRoleType);
          }
          this.propagateAddAndRemoveBrokerChanges();

        } else {
          brokerWrapper.dataModel = null;
        }
      },
      onRejection: (reason: any) => {
        brokerWrapper.dataModel = null;
      },
      fullScreen: true,
      
    });

  }

  public setContactInRealEstateAgentWrapper(agentMatterParticipantRoleType: MatterParticipantRole,
                                            agentWrapper: MatterParticipantWrapper,
                                            sourceContact: Contact,
                                            isExitingAgent: boolean = false,
                                            isContactCreatedForMatterOnly: boolean = false): void {
    agentWrapper.editMode = false;
    agentWrapper.matterParticipant
      = this.matter.addMatterParticipant(sourceContact, !isContactCreatedForMatterOnly, agentMatterParticipantRoleType);
    if (agentWrapper.matterParticipant) {

      //Setting broker company id into snapshot's sourceParentOrganization. In this case no need to make agent dirty as source contact
      // would be referring to the same broker company
      agentWrapper.matterParticipant.contact.sourceParentOrganizationId = sourceContact.organizationId;

      if (this.isRealEstateAgentCreatedWithSourceContact(agentWrapper.matterParticipant)) {
        if (isExitingAgent) {
          this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, agentWrapper);
        } else {
          this.matterParticipantService.updateParticipantWrapperState(agentWrapper, sourceContact);
        }
      }

      if (sourceContact.organizationId) {
        this.contactQueryService.getContactForMatter(sourceContact.organizationId).subscribe(
          (brokerCompany: Contact) => {
            if (agentMatterParticipantRoleType === MatterParticipantRoleTypes.REALESTATEAGENT) {
              this.realEstateBrokerWrapper = new MatterParticipantWrapper();
              this.setCoreContactInBrokerWrapper(brokerCompany, false, this.realEstateBrokerWrapper, MatterParticipantRoleTypes.REALESTATEBROKER);
            } else {
              this.purchaserRealEstateBrokerWrapper = new MatterParticipantWrapper();
              this.setCoreContactInBrokerWrapper(brokerCompany, false, this.purchaserRealEstateBrokerWrapper, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER);
            }
            this.propagateAddAndRemoveBrokerChanges();
          }
        );
      }
    }
  }

  get realEstateAgent(): Contact {
    return this.realEstateAgentWrapper && this.realEstateAgentWrapper.matterParticipant ? this.realEstateAgentWrapper.matterParticipant.contact : null;
  }

  get purchaserRealEstateAgent(): Contact {
    return this.purchaserRealEstateAgentWrapper && this.purchaserRealEstateAgentWrapper.matterParticipant ? this.purchaserRealEstateAgentWrapper.matterParticipant.contact : null;
  }

  //If agent added with a broker company then shutter will be closed else opened
  public setCoreContactInBrokerWrapper(sourceContact: Contact, openShutter: boolean,
                                       brokerWrapper: MatterParticipantWrapper, matterParticipantRoleType: MatterParticipantRole): void {
    brokerWrapper.editMode = false;
    brokerWrapper.matterParticipant = this.matter.addMatterParticipant(sourceContact, true, matterParticipantRoleType);

    if (openShutter) {
      //if broker added separately then open shutter and lock participant
      this.toggleBrokerShutter(brokerWrapper);
    } else {
      //if broker added as part of listing agent then just update wrapper state but don't open shutter or lock contact
      this.matterParticipantService.updateParticipantWrapperState(brokerWrapper, sourceContact);
    }

  }

  toggleBrokerShutter(brokerWrapper: MatterParticipantWrapper): void {
    if (this.matter.locked || !(brokerWrapper && brokerWrapper.matterParticipant && brokerWrapper.matterParticipant.contact && brokerWrapper.matterParticipant.contact.sourceContactId)) {
      //If matter locked, or the contact is a matter level contact (no source contact ID), just toggle
      brokerWrapper.expanded = !brokerWrapper.expanded;
      return;
    }
    this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, brokerWrapper);
  }

  clickAgentBurgerMenuItem(event, agentWrapper: MatterParticipantWrapper, brokerWrapper: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE:
        this.deleteSelectedRealEstateAgent(agentWrapper, brokerWrapper);
        break;
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
        //If replacing agent then also removing any existing broker snapshot
        if (brokerWrapper && brokerWrapper.matterParticipant) {
          this.matter.removeMatterParticipant(brokerWrapper.matterParticipant);
        }

        this.matterParticipantService.updateMatterContactFromSourceContact(agentWrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.agentWrapperCallbackOnReplaceSourceContact, this.matter);
        this.handleContactChange();
        break;
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
        //If replacing agent then also removing any existing broker snapshot
        if (brokerWrapper && brokerWrapper.matterParticipant) {
          this.matter.removeMatterParticipant(brokerWrapper.matterParticipant);
        }
        this.matterParticipantService.revertToGlobal(agentWrapper, this.agentWrapperCallbackOnReplaceSourceContact);
        break;

    }
  }

  agentWrapperCallbackOnReplaceSourceContact = (result, matterParticipantWrapper, oldSnapshotReference: Contact) => {
    //If result true that means contact updated successfully
    if (result) {
      //When agent is replaced with latest data then re-creating broker participant snapshot as it might have been changed.
      if (matterParticipantWrapper.matterParticipant.contact.sourceParentOrganizationId) {
        this.contactQueryService.getContactForMatter(matterParticipantWrapper.matterParticipant.contact.sourceParentOrganizationId).subscribe(
          (brokerCompany: Contact) => {
            if (matterParticipantWrapper.matterParticipant.matterParticipantRole === MatterParticipantRoleTypes.REALESTATEAGENT) {
              this.realEstateBrokerWrapper = new MatterParticipantWrapper();
              this.setCoreContactInBrokerWrapper(brokerCompany, false, this.realEstateBrokerWrapper, MatterParticipantRoleTypes.REALESTATEBROKER);
            } else {
              this.purchaserRealEstateBrokerWrapper = new MatterParticipantWrapper();
              this.setCoreContactInBrokerWrapper(brokerCompany, false, this.purchaserRealEstateBrokerWrapper, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER);
            }
          }
        );
      }
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  agentWrapperCallback = (result, matterParticipantWrapper) => {
    //If result true that means contact updated successfully
    if (result) {
      this.enableSave();
    }
    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  clickBrokerBurgerMenuItem(event, brokerWrapper: MatterParticipantWrapper, agentWrapper: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE:
        this.deleteBroker(brokerWrapper, agentWrapper);
        break;
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
        this.matterParticipantService.updateMatterContactFromSourceContact(brokerWrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.brokerWrapperCallbackOnUpdateSourceContact, this.matter);
        break;
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
        this.matterParticipantService.revertToGlobal(brokerWrapper, this.brokerWrapperCallbackOnUpdateSourceContact);
        break;

    }
  }

  deleteBroker(brokerWrapper: MatterParticipantWrapper, agentWrapper: MatterParticipantWrapper): void {
    if (agentWrapper && agentWrapper.matterParticipant) {
      let message: string;
      if (agentWrapper.matterParticipant.matterParticipantRole === 'REALESTATEAGENT') {
        message = 'Proceed to remove Listing Broker from this Agent?';
      } else {
        message = `Proceed to remove ${ provinceBasedFieldLabels.get('matter.broker.commission.purchaserBroker', this.matter.provinceCode) } from this Agent?`;
      }

      this.dialogService.confirm('Confirmation', message, false, 'Delete').subscribe(
        (response) => {
          if (response) {
            if (this.documentProductionService && this.contactQueryService) {
              this.documentProductionService.tryToRevokePackage(this.matter, brokerWrapper.matterParticipant, this.contactQueryService);
            }
            this.matterParticipantService.removeParticipant(brokerWrapper, this.matter);
            if (agentWrapper.matterParticipant.contact) {
              agentWrapper.matterParticipant.contact.sourceParentOrganizationId = null;
              agentWrapper.matterParticipant.contact.isDirty = true;
            }
            this.enableSave();
            this.propagateAddAndRemoveBrokerChanges();
          }
        });
    } else {
      let message: string;
      if (brokerWrapper && brokerWrapper.matterParticipant && brokerWrapper.matterParticipant.matterParticipantRole === 'REALESTATEBROKER') {
        message = 'Proceed to remove Listing Broker?';
      } else {
        message = `Proceed to remove ${ provinceBasedFieldLabels.get('matter.broker.commission.purchaserBroker', this.matter.provinceCode) }?`;
      }
      this.dialogService.confirm('Confirmation', message, false, 'Delete').subscribe(
        (response) => {
          this.matterParticipantService.removeParticipant(brokerWrapper, this.matter);
          this.enableSave();
          this.propagateAddAndRemoveBrokerChanges();
        });
    }
  }

  //This function called from common service after updating participant's snapshot from source contact
  brokerWrapperCallbackOnUpdateSourceContact = (result, matterParticipantWrapper) => {
    //If result true that means contact updated successfully
    if (result) {
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  editBrokerAsPrivateCopy(brokerWrapper: MatterParticipantWrapper) {
    this.enableSave();
    this.matterParticipantService.editContactAsPrivateCopy(brokerWrapper);
  }

  openSourceContactTab(wrapper: MatterParticipantWrapper): void {
    if (wrapper && wrapper.matterParticipant && wrapper.matterParticipant.contact) {
      this.contactQueryService.getContactForOpeningTab(wrapper.matterParticipant.contact.sourceContactId).subscribe((res: any) => {
        let source: Contact = res.contact;
        const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/' + source.contactType, source.contactType);
        this.tabsService.openTab(contactTab);
      });
    }
  }

  get realEstateBroker(): Contact {
    return this.realEstateBrokerWrapper && this.realEstateBrokerWrapper.matterParticipant ? this.realEstateBrokerWrapper.matterParticipant.contact : null;
  }

  get purchaserRealEstateBroker(): Contact {
    return this.purchaserRealEstateBrokerWrapper && this.purchaserRealEstateBrokerWrapper.matterParticipant ? this.purchaserRealEstateBrokerWrapper.matterParticipant.contact : null;
  }

  public onAgentChanged(): void {
    this.realEstateAgent.isDirty = true;
    this.enableSave();
  }

  public onBrokerChanged(): void {
    this.propagateAddAndRemoveBrokerChanges();
    this.realEstateBroker.isDirty = true;
    this.enableSave();
  }

  public onPurchaserAgentChanged(): void {
    this.purchaserRealEstateAgent.isDirty = true;
    this.enableSave();
  }

  public onPurchaserBrokerChanged(): void {
    this.purchaserRealEstateBroker.isDirty = true;
    this.enableSave();
  }

  get isRealEstateAgentReadOnly(): boolean {
    return !!(this.realEstateAgentWrapper && this.realEstateAgentWrapper.matterParticipant
      && this.isRealEstateAgentCreatedWithSourceContact(this.realEstateAgentWrapper.matterParticipant)
      && this.realEstateAgentWrapper.isAgentBrokerParticipantDataReadOnly);
  }

  get isPurchaserRealEstateAgentReadOnly(): boolean {
    return !!(this.purchaserRealEstateAgentWrapper && this.realEstateAgentWrapper.matterParticipant
      && this.isRealEstateAgentCreatedWithSourceContact(this.purchaserRealEstateAgentWrapper.matterParticipant)
      && this.purchaserRealEstateAgentWrapper.isAgentBrokerParticipantDataReadOnly);
  }

  // CommissionPaidTo Fields
  initCommissionPaidToFields() {
    if (this.matter.isTemplateMatterForMassUpdate) {
      this.commissionPaidToOptions = dropDowns.CommissionPaidToOptionsPS;

    } else {
      this.commissionPaidToOptions = this.matter && this.matter.provinceCode ? commissionPaidToDropdown[ this.matter.provinceCode ]
        : commissionPaidToDropdownOptions.commissionPaidToDropdownOptionsDefault;
    }

    if (!this.matter.separateEntriesForCommissionPaidToTrustLedger) {
      this.matter.separateEntriesForCommissionPaidToTrustLedger = DpBooleanValueTypes.Y_n;
    }

    if (!this.matter.commissionPaidTo) {
      if (!this.matter.isTemplateMatterForMassUpdate) {
        this.setCommissionDefaultValue();
      }
    }
  }

  // The section of "Is Commission based on a fixed percentage of sale price
  get brokerCommission(): BrokerCommission {
    if (this.matter && !this.matter.brokerCommission) {
      this.matter.brokerCommission = new BrokerCommission();
    }

    return (this.matter && this.matter.brokerCommission);
  }

  get isNoOptionOfNetOutHst(): boolean {
    return this.salePriceAdjustment && this.salePriceAdjustment.netOutHstFromHSTSalePrice === DpBooleanValueTypes.NO;
  }

  get isYesDivideSalePriceOptionOfNetOutHst(): boolean {
    return this.salePriceAdjustment && this.salePriceAdjustment.netOutHstFromHSTSalePrice === constValues.netOutHst.YES_DIVIDE_SALE_PRICE;
  }

  get isYesFactorInHstRebateOptionOfNetOutHst(): boolean {
    return this.salePriceAdjustment && this.salePriceAdjustment.netOutHstFromHSTSalePrice === constValues.netOutHst.YES_FACTOR_IN_HST_REBATE;
  }

  get salePrice(): number {
    if (this.isNoOptionOfNetOutHst) {
      return this.salePriceForNoOption;
    }

    if (this.commissionBasedSalePriceOptions.length == 0) {
      if (this.isYesDivideSalePriceOrFactorInHstRebate) {
        this.getDynamicCommissionBasedSalePriceOptions();
      } else {
        this.commissionBasedSalePriceOptions = BrokerCommissionDropDowns.isCommissionBasedSalePriceOptions;
      }
    }

    if (this.isYesDivideSalePriceOptionOfNetOutHst) {
      if (this.matter.commissionBasedOnFixedPercentageOfSalePrice === this.commissionBasedSalePriceOptions[ 1 ].value) {
        return this.salePriceForYesFactorInHstRebateOption;

      } else if (this.matter.commissionBasedOnFixedPercentageOfSalePrice === this.commissionBasedSalePriceOptions[ 2 ].value) {
        return this.salePriceForYesDivideSalePriceOption;
      } else {
        return this.salePriceForYesDivideSalePriceOption;
      }
    }

    if (this.isYesFactorInHstRebateOptionOfNetOutHst) {
      if (this.matter.commissionBasedOnFixedPercentageOfSalePrice === this.commissionBasedSalePriceOptions[ 1 ].value) {
        return this.salePriceForYesFactorInHstRebateOption;

      } else if (this.matter.commissionBasedOnFixedPercentageOfSalePrice === this.commissionBasedSalePriceOptions[ 2 ].value) {
        return this.salePriceForYesDivideSalePriceOption;
      } else {
        return this.salePriceForYesFactorInHstRebateOption;
      }
    }
  }

  get isYesDivideSalePriceOrFactorInHstRebate(): boolean {
    return (this.salePriceAdjustment && (this.salePriceAdjustment.netOutHstFromHSTSalePrice === constValues.netOutHst.YES_DIVIDE_SALE_PRICE
      || this.salePriceAdjustment.netOutHstFromHSTSalePrice === constValues.netOutHst.YES_FACTOR_IN_HST_REBATE));
  }

  get salePriceAdjustment(): SalePriceAdjustment {
    let salepriceAdjustment: SalePriceAdjustment = this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment;//get final salePriceAdjustment
    if (this.matter && this.matter.isProjectSale && this.matter.selectedProgressionStatus == ProgressionStatus.INTERIM) {
      //get the Interim sale price adjustment
      salepriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(ProgressionStatus.INTERIM, this.matter.provinceCode, salepriceAdjustment);
    }
    return salepriceAdjustment;
  }

  get salePriceForNoOption(): number {
    return (this.salePriceAdjustment && this.salePriceAdjustment.agreementSalePrice);
  }

  get salePriceForYesFactorInHstRebateOption(): number {
    return (this.salePriceAdjustment && this.salePriceAdjustment.agreementSalePrice);
  }

  get salePriceForYesDivideSalePriceOption(): number {

    if (this.matter && this.matter.isProjectSale) {
      return this.salePriceAdjustment && this.salePriceAdjustment.totalNetSalePrice(this.getFederalHstFromSoAdjFocusedSheet(), this.getProvincialHstFromSoAdjFocusedSheet());
    } else {
      return this.salePriceAdjustment && this.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate);
    }
  }

  get yesHstInclusiveLabel(): string {
    return 'Yes-' + (this.matter && this.matter.matterTaxType) + '-inclusive price ' + Utils.formattedCurrencyValue(this.salePriceForYesFactorInHstRebateOption);
  }

  get federalHstRate(): number {
    return (this.matter && this.matter.soaFederalHst ? this.matter.soaFederalHst : (this.matter && this.matter.matterFederalHst ? this.matter.matterFederalHst : 0));
  }

  get provinceHstRate(): number {
    return (this.matter && this.matter.soaProvincialHst ? this.matter.soaProvincialHst : (this.matter && this.matter.matterProvincialHst ? this.matter.matterProvincialHst : 0));
  }

  getFederalHstFromSoAdjFocusedSheet(): number {
    if (this.matter) {
      return this.matter.selectedProgressionStatus == ProgressionStatus.FINAL ?
        (this.matter.soAdjustmentTaxRateFinal ? this.matter.soAdjustmentTaxRateFinal.federalHstRate : null) :
        (this.matter.soAdjustmentTaxRateInterim ? this.matter.soAdjustmentTaxRateInterim.federalHstRate : null);
    }
    return null;
  }

  getProvincialHstFromSoAdjFocusedSheet(): number {
    if (this.matter) {
      return this.matter.selectedProgressionStatus == ProgressionStatus.FINAL ?
        (this.matter.soAdjustmentTaxRateFinal ? this.matter.soAdjustmentTaxRateFinal.provincialHstRate : null) :
        (this.matter.soAdjustmentTaxRateInterim ? this.matter.soAdjustmentTaxRateInterim.provincialHstRate : null);
    }
    return null;
  }

  /*    //will get the rate based on the focused SoAdj sheet
      getFederalHstRate() : number {
          if(this.matter && this.matter.isProjectSale){
              if(this.getFederalHstFromSoAdjFocusedSheet()){
                  return this.getFederalHstFromSoAdjFocusedSheet();
              }else{
                  return this.matter && this.matter.matterFederalHst ? this.matter.matterFederalHst : 0;
              }
          }else{
              return this.federalHstRate;
          }
      }

      //will get the rate based on the focused SoAdj sheet
      getProvinceHstRate() : number {
          if(this.matter && this.matter.isProjectSale){
              if(this.getProvincialHstFromSoAdjFocusedSheet()){
                  return this.getProvincialHstFromSoAdjFocusedSheet();
              }else{
                  this.matter && this.matter.matterProvincialHst ? this.matter.matterProvincialHst : 0;
              }
          }else{
              return this.provinceHstRate
          }
      }*/

  get yesNetLabel() {
    return 'Yes-net sale price ' + Utils.formattedCurrencyValue(this.salePriceForYesDivideSalePriceOption);
  }

  get isBlankOfCommissionBasedSalePrice(): boolean {
    return (this.matter && this.matter.commissionBasedOnFixedPercentageOfSalePrice === '');
  }

  get isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo(): boolean {
    return this.matter
      && this.matter.commissionBasedOnFixedPercentageOfSalePrice !== DpBooleanValueTypes.NO
      && this.matter.commissionBasedOnFixedPercentageOfSalePrice !== ''
      && this.matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER;
  }

  get isYesOfCommissionBasedSalePrice(): boolean {
    return this.matter
      && this.matter.commissionBasedOnFixedPercentageOfSalePrice !== DpBooleanValueTypes.NO
      && this.matter.commissionBasedOnFixedPercentageOfSalePrice !== '';
  }

  get isVendorOnlyOfCommissionPaidTo(): boolean {
    return this.matter && this.matter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY;
  }

  get isPurchaserOnlyOfCommissionPaidTo(): boolean {
    return this.matter && this.matter.isMatterProvinceBC && this.matter.commissionPaidTo === constValues.commissionPaidTo.PURCHASER_BROKER_ONLY;
  }

  get isBothOfCommissionPaidTo(): boolean {
    return (this.matter && this.matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER);
  }

  get isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo(): boolean {
    return this.matter
      && this.matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO
      && this.matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER;
  }

  get isNofCommissionBasedSalePriceVendorOnlyOfCommissionPaidTo(): boolean {
    return this.matter &&
      this.matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO &&
      this.matter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY;
  }

  get isNoOfCommissionBasedSalePrice(): boolean {
    return (this.matter && (this.matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO || this.matter.commissionBasedOnFixedPercentageOfSalePrice === ''));
  }

  //From matter-list switch to project tab, there is no matter, this component is injected.
  // so it need check if matter is null for getter
  get isVendorDepositVisible(): boolean {
    return this.isVendorOnlyOfCommissionPaidTo
      || (this.matter && (this.matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER));
  }

  get isVendorDepositInvisible(): boolean {
    return this.brokerCommission && this.brokerCommission.appliedTowardsPaymentOfCommission === DpBooleanValueTypes.NO
      && (this.isVendorOnlyOfCommissionPaidTo || this.isMassUpdateBlankCommissionPaidTo());
  }

  //From matter-list switch to project tab, there is no matter, this component is injected.
  // so it need check if matter is null for getter
  get isPurchaserDepositVisible(): boolean {
    return this.matter && (this.matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER);
  }

  get isBrokerReleaseExcessToVendorVisible(): boolean {
    let label = this.matter.getCoreCommissionPayableToVendorBrokerLabel();
    return this.matter.isMatterProvinceBC && this.isVendorDepositVisible && label === BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor;
  }

  get isBrokerReleaseExcessToPurchaserVisible(): boolean {
    let label = this.matter?.getCoreCommissionPayableToPurchaserBrokerLabel();
    return this.matter?.isMatterProvinceBC && this.isPurchaserDepositVisible && label === BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor;
  }

  get isVendorBrokerCommissionVisible(): boolean {
    return !this.matter?.isMatterProvinceBC || this.isBothOfCommissionPaidTo || this.isVendorOnlyOfCommissionPaidTo;
  }

  async setRateOfHstOnCommissionDefaultValue() {
    // Set 'Rate of HST on Commission'  default value
    //We need to handle the case rateOfHstOnCommission is zero
    if (!this.brokerCommission.rateOfHstOnCommission || (this.matter.isMatterProvinceSK && !this.brokerCommission.rateOfPstOnCommission)) {
      if (!this.provinceHstRate || !this.federalHstRate) {
        let considerationTaxes: ConsiderationTaxes[] = await this.taxRateService.cachedConsiderationTaxRate(this.matter.provinceCode).toPromise();
        if (considerationTaxes && considerationTaxes.length > 0) {
          //const provinceHstRateSlab : ConsiderationTaxes = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
          const provinceHstRateSlab: ConsiderationTaxes = this.taxRateService.findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes, this.matter.getSoaTaxRateEffectiveDate());
          this.brokerCommission.rateOfHstOnCommission = provinceHstRateSlab.hstRate;
          if (this.matter.isMatterProvinceSK) {
            this.brokerCommission.rateOfPstOnCommission = provinceHstRateSlab.hstProvincialPortion;
          }
        }
      } else {
        if ((this.matter.isMatterProvinceSK && this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) || this.matter.isMatterProvinceMB) {
          this.brokerCommission.rateOfHstOnCommission = this.federalHstRate;
        } else {
          if (this.matter.isMatterProvinceBC) {
            this.brokerCommission.rateOfHstOnCommission = this.federalHstRate;
          } else if (!this.matter.isMatterProvinceSK) {
            this.brokerCommission.rateOfHstOnCommission = this.provinceHstRate + this.federalHstRate;
          } else {
            this.brokerCommission.rateOfHstOnCommission = this.federalHstRate;
            this.brokerCommission.rateOfPstOnCommission = this.provinceHstRate;
          }
        }
      }
    }
  }

  //If add new Commission Based default value, it should update cleanupBrokerCommission of matter.ts
  async initCommissionBasedSalePrice() {
    //init considerationLtt
    if (!this.matter.considerationLtt) {
      this.matter.createNewConsiderationLtt();
      this.matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    } else if (!this.matter.considerationLtt.salePriceAdjustment) {
      this.matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    }

    // Set 'Is Commission based on a fixed percentage of sale price?' default value
    if (!this.matter.commissionBasedOnFixedPercentageOfSalePrice) {
      this.matter.commissionBasedOnFixedPercentageOfSalePrice = '';
    }

    // Set 'Total Commission Percentage' default value
    if (!this.brokerCommission.totalCommissionPercentage) {
      this.brokerCommission.totalCommissionPercentage = 0;
    }

    // Set 'Commission (before HST)' default value
    if ((!this.brokerCommission.commissionBeforeHst) && this.isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
      this.brokerCommission.commissionBeforeHst = 0;
    }

    await this.setRateOfHstOnCommissionDefaultValue();

    // Set depositHeldBy default value
    if (!this.matter.depositHeldBy) {
      if (this.matter.isTemplateMatterForMassUpdate) {
        this.addBlankOptionInDepositHeldBy();
        this.matter.depositHeldBy = '';
      } else {
        this.matter.depositHeldBy = this.matter.isMatterProvinceBC ? constValues.depositHeldBy.PURCHASER_BROKER
          : constValues.depositHeldBy.VENDOR_BROKER;
      }

    }

    // Set depositHeldBy default value
    if (!this.brokerCommission.appliedTowardsPaymentOfCommission) {
      this.brokerCommission.appliedTowardsPaymentOfCommission = this.matter.isMatterProvinceBC ? DpBooleanValueTypes.YES
        : BrokerCommissionDropDowns.appliedTowardsPaymentOfCommissionOptions[ 0 ].value;
    }

    if (!this.brokerCommission.vendorCommission) {
      this.brokerCommission.vendorCommission = new VendorCommission();
    }

    // Set 'Vendor's broker percentage of commission' default value
    if (!this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission) {
      this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission = 0;
    }

    // Set 'Commission payable to Vendor's broker' default value
    if (!this.brokerCommission.vendorCommission.commissionPayableToVendorBroker) {
      this.brokerCommission.vendorCommission.commissionPayableToVendorBroker = 0;
    }

    if (!this.brokerCommission.purchaserCommission) {
      this.brokerCommission.purchaserCommission = new PurchaserCommission();
    }

    //  Set 'Purchaser's broker percentage of commission'  default value
    if (!this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission) {
      this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission = 0;
    }

    this.updateCommissionBasedOnFixedPercentageOfSalePriceWithSoa();
  }

  getDynamicCommissionBasedSalePriceOptions() {
    this.commissionBasedSalePriceOptions = [
      {label: '', value: ''},
      {label: this.yesHstInclusiveLabel, value: this.yesHstInclusiveLabel},
      {label: this.yesNetLabel, value: this.yesNetLabel},
      {label: 'No', value: DpBooleanValueTypes.NO}
    ];
  }

  calculateCommissionBeforeHst(): number {
    if (this.matter.isMatterProvinceSK) {
      this.brokerCommission.commissionBeforeHst = this.calculateCommissionBeforeHst_SK();
    } else {
      if (this.isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
        this.brokerCommission.commissionBeforeHst =
          Utils.roundNumber(this.brokerCommission.vendorCommission.vendorBrokerCommission, 2) +
          Utils.roundNumber(this.brokerCommission.purchaserCommission.purchaserBrokerCommission, 2);
      } else if (this.isYesOfCommissionBasedSalePrice) {
        this.brokerCommission.commissionBeforeHst = this.brokerCommission.totalCommissionPercentage * this.salePrice / 100;
      }
    }
    return this.brokerCommission.commissionBeforeHst;
  }

  calculateCommissionBeforeHst_SK(): number {
    if (this.isNoOfCommissionBasedSalePrice) {
      // After confirm with BA, we use another  formulate
      // when it is  Both Vendor's and Purchaser's brokers and  "Is Commission based on a fixed percentage" is NO
      // Commission (before tax) = vendorBrokerCommission + purchaserBrokerCommission
      this.setCommissionBeforeHst();
      return this.brokerCommission.commissionBeforeHst;
    } else {
      if (this.salePrice >= 100000) {
        this._commissionTier1 = 100000 * commissionTier1Percent;
      } else {
        return this._commissionTier1 = this.salePrice * commissionTier1Percent;
      }
      if (this.salePrice >= 200000) {
        this._commissionTier2 = 100000 * commissionTier2Percent;
      } else {
        return this._commissionTier1 + (this.salePrice - 100000) * commissionTier2Percent;
      }
      return this._commissionTier1 + this._commissionTier2 + (this.salePrice - 200000) * commissionTier3Percent;
    }
  }

  calculatePurchaserBrokerPercentageOfCommission(): string {
    if (this.matter.isMatterProvinceSK) {
      this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission =
        100 - this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission;
    }

    return Number(this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission).toFixed(3).valueOf() + '';
  }

  calculateCommissionInclHst(): number {
    if (this.isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
      this.brokerCommission.commissionInclHst =
        this.calculatePurchaserTotalCommissionInclHst() + this.calculateVendorTotalCommissionInclHst();
      // = this.calculateCommissionBeforeHst() * this.brokerCommission.rateOfHstOnCommission / 100
      //                 // + this.calculateCommissionBeforeHst();

    }
    if (this.isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
      if (this.matter.isMatterProvinceSK) {
        this.brokerCommission.commissionInclHst = this.brokerCommission.commissionBeforeHst +
          Utils.roundNumber(this.brokerCommission.vendorCommission.vendorHstOnCommission, 2) +
          Utils.roundNumber(this.brokerCommission.purchaserCommission.purchaserHstOnCommission, 2);
      } else {
        this.brokerCommission.commissionInclHst
          = this.brokerCommission.commissionBeforeHst * this.brokerCommission.rateOfHstOnCommission / 100
          + Number(this.brokerCommission.commissionBeforeHst);
      }
    }

    return this.brokerCommission.commissionInclHst;
  }

  calculateVendorBrokerPercentageOfCommissionOn(): number {
    this.matter.brokerPercentageOfCommissionOn = this.salePrice;
    return this.matter.brokerPercentageOfCommissionOn;
  }

  calculateVendorBrokerCommission(): number {
    if (!this.matter.isMatterProvinceSK && this.isBothOfCommissionPaidTo && this.isYesOfCommissionBasedSalePrice) {
      this.brokerCommission.vendorCommission.vendorBrokerCommission
        = this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission / 100 * this.calculateVendorBrokerPercentageOfCommissionOn();
    }

    if (!this.matter.isMatterProvinceSK && this.isVendorOnlyOfCommissionPaidTo && this.isYesOfCommissionBasedSalePrice) {
      this.brokerCommission.vendorCommission.vendorBrokerCommission = this.calculateCommissionBeforeHst();
    }

    if (!this.matter.isMatterProvinceSK && this.isVendorOnlyOfCommissionPaidTo && this.isNoOfCommissionBasedSalePrice) {
      this.brokerCommission.vendorCommission.vendorBrokerCommission
        = this.brokerCommission.commissionBeforeHst;
    }

    if (this.matter.isMatterProvinceSK) {
      if (this.isVendorOnlyOfCommissionPaidTo) {
        if (this.isNoOfCommissionBasedSalePrice) {
          this.brokerCommission.vendorCommission.vendorBrokerCommission
            = this.brokerCommission.commissionBeforeHst;
        }
        if (this.isYesOfCommissionBasedSalePrice) {
          this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission = 100;
          this.brokerCommission.vendorCommission.vendorBrokerCommission = this.calculateCommissionBeforeHst();
        }

      } else {
        this.brokerCommission.vendorCommission.vendorBrokerCommission =
          this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission / 100 * this.calculateCommissionBeforeHst();
      }
    }

    return this.brokerCommission.vendorCommission.vendorBrokerCommission;
  }

  calculateVendorOnlyFieldsForMassUpdate() {
    this.calculateVendorBrokerCommission();
    this.calculateVendorHstOnCommission();
    this.calculateVendorTotalCommissionInclHst();
    this.matter.calculateBrokerDeposit();
    this.calculateVendorCommissionPayableToVendorBroker();
  }

  // SK
  // Calculation for 'GST OR Tax on Commission' related to Vendor
  // Tax on commission ($ value) =  (Vendor's broker commission * GST) + (Commission (before tax)* PST)
  // GST on Commission ($ value) = (Vendor's broker commission * GST)
  calculateVendorHstOnCommission(): number {
    if (this.isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
      if (this.matter.isMatterProvinceSK) {
        if (this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) { //GST on Commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = (this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission());
        } else { //Tax on commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = (this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission()) + (this.brokerCommission.rateOfPstOnCommission / 100 * this.calculateCommissionBeforeHst());
        }
      } else { //Tax on commission
        this.brokerCommission.vendorCommission.vendorHstOnCommission
          = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission();
      }
    }

    if (this.isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
      if (this.matter.isMatterProvinceSK) {
        if (this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) { //GST on Commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = this.brokerCommission.rateOfHstOnCommission / 100 * this.brokerCommission.vendorCommission.vendorBrokerCommission;
        } else { //Tax on commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = (this.brokerCommission.rateOfHstOnCommission / 100 * this.brokerCommission.vendorCommission.vendorBrokerCommission) + (this.brokerCommission.rateOfPstOnCommission / 100 * this.brokerCommission.commissionBeforeHst);
        }

      } else {
        this.brokerCommission.vendorCommission.vendorHstOnCommission
          = this.brokerCommission.rateOfHstOnCommission / 100 * this.brokerCommission.vendorCommission.vendorBrokerCommission;
      }

    }

    if (this.isYesOfCommissionBasedSalePrice && this.isVendorOnlyOfCommissionPaidTo) {
      if (this.matter.isMatterProvinceSK) {
        if (this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) { //GST on Commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission();
        } else { //Tax on commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = (this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission()) + (this.brokerCommission.rateOfPstOnCommission / 100 * this.calculateCommissionBeforeHst());
        }

      } else {
        this.brokerCommission.vendorCommission.vendorHstOnCommission
          = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission();
      }
    }

    if (this.isNoOfCommissionBasedSalePrice && this.isVendorOnlyOfCommissionPaidTo) {
      if (this.matter.isMatterProvinceSK) {
        if (this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) { //GST on Commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission();
        } else { //Tax on commission
          this.brokerCommission.vendorCommission.vendorHstOnCommission
            = (this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission()) + (this.brokerCommission.rateOfPstOnCommission / 100 * this.brokerCommission.commissionBeforeHst);
        }

      } else {
        this.brokerCommission.vendorCommission.vendorHstOnCommission
          = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculateVendorBrokerCommission();
      }
    }

    return Utils.roundCurrency(this.brokerCommission.vendorCommission.vendorHstOnCommission);
  }

  calculateVendorTotalCommissionInclHst(): number {
    if (this.isYesOfCommissionBasedSalePrice) {
      this.brokerCommission.vendorCommission.vendorTotalCommissionInclHst
        = Utils.roundNumber(this.calculateVendorBrokerCommission() + this.calculateVendorHstOnCommission(), 2);
    }
    if (this.isNoOfCommissionBasedSalePrice) {
      this.brokerCommission.vendorCommission.vendorTotalCommissionInclHst
        = Number(this.brokerCommission.vendorCommission.vendorBrokerCommission) + this.calculateVendorHstOnCommission();
    }
    return this.brokerCommission.vendorCommission.vendorTotalCommissionInclHst;
  }

  getCommissionPayableToVendorBrokerLabel() {
    let label = this.matter?.getCoreCommissionPayableToVendorBrokerLabel();
    //return localized label
    if (this.matter?.provinceCode) {
      return this.getLocalizedPayableToBrokerLabel(label, this.matter.provinceCode);
    } else {
      return label;
    }
  }

  onChangeCommissionDischargeTo() {
    this.enableSave();
  }

  onChangeCommissionPaidTo() {
    this.setAppliedTowardsPaymentOfCommission();
    this.setTotalCommissionPercentage();
    this.setCommissionBeforeHst();
    this.matter.reCalculateTrustLedgerPaidToRealEstateBroker();
    this.enableSave();
  }

  calculateVendorCommissionPayableToVendorBroker(): number {
    //if sale price has been reset/changed from SoA page, we need to recalculate  vendorTotalCommissionInclHst
    let vendorTotalCommissionInclHst: number = Utils.roundNumber(this.calculateVendorTotalCommissionInclHst(), 2); //this.brokerCommission.vendorCommission.vendorTotalCommissionInclHst;
    let vendorDeposit: number = this.matter.calculateBrokerDeposit();

    // Transform all falsey to number 0
    if (!vendorTotalCommissionInclHst) {
      vendorTotalCommissionInclHst = 0;
    }

    if (!vendorDeposit) {
      vendorDeposit = 0;
    }

    if (this.isVendorDepositVisible) {
      if (vendorTotalCommissionInclHst >= vendorDeposit) {
        this.brokerCommission.vendorCommission.commissionPayableToVendorBroker
          = vendorTotalCommissionInclHst - vendorDeposit;
      } else if (vendorTotalCommissionInclHst < vendorDeposit) {
        this.brokerCommission.vendorCommission.commissionPayableToVendorBroker
          = vendorDeposit - vendorTotalCommissionInclHst;
      }
    } else {
      this.brokerCommission.vendorCommission.commissionPayableToVendorBroker
        = this.calculateVendorTotalCommissionInclHst();
    }
    this.brokerCommission.vendorCommission.commissionPayableToVendorBroker = Utils.roundNumber(this.brokerCommission.vendorCommission.commissionPayableToVendorBroker, 2);

    //the following line is used to monitor the value change for the calculated value in order to trigger the data refresh in TrustLedger
    this.commissionPayableToVendorBroker = this.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
    this.matter.calculateStatementAdjustment();

    return this.brokerCommission.vendorCommission.commissionPayableToVendorBroker;
  }

  calculatePurchaserBrokerPercentageOfCommissionOn(): number {
    this.matter.brokerPercentageOfCommissionOn = this.salePrice;
    return this.matter.brokerPercentageOfCommissionOn;
  }

  calculatePurchaserBrokerCommission(): number {
    if (!this.matter.isMatterProvinceSK && this.isYesOfCommissionBasedSalePrice) {
      this.brokerCommission.purchaserCommission.purchaserBrokerCommission
        = this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission / 100 * this.calculatePurchaserBrokerPercentageOfCommissionOn();
    }

    if (this.matter.isMatterProvinceSK && this.isYesOfCommissionBasedSalePrice) {
      this.brokerCommission.purchaserCommission.purchaserBrokerCommission =
        this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission / 100 * this.calculateCommissionBeforeHst();
    }

    if (!this.matter.isMatterProvinceSK && this.isPurchaserOnlyOfCommissionPaidTo && this.isNoOfCommissionBasedSalePrice) {
      this.brokerCommission.purchaserCommission.purchaserBrokerCommission
        = this.brokerCommission.commissionBeforeHst;
    }

    return Number(this.brokerCommission.purchaserCommission.purchaserBrokerCommission);
  }

  calculatePurchaserHstOnCommission(): number {
    if (this.matter.isMatterProvinceSK) {
      this.brokerCommission.purchaserCommission.purchaserHstOnCommission
        = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculatePurchaserBrokerCommission();
    } else {
      this.brokerCommission.purchaserCommission.purchaserHstOnCommission
        = this.brokerCommission.rateOfHstOnCommission / 100 * this.calculatePurchaserBrokerCommission();
    }
    return Utils.roundCurrency(this.brokerCommission.purchaserCommission.purchaserHstOnCommission);
  }

  calculatePurchaserTotalCommissionInclHst(): number {
    if (this.isYesOfCommissionBasedSalePrice) {
      this.brokerCommission.purchaserCommission.purchaserTotalCommissionInclHst
        = Utils.roundNumber(this.calculatePurchaserBrokerCommission(), 2) + Utils.roundNumber(this.calculatePurchaserHstOnCommission(), 2);
    }

    if (this.isNoOfCommissionBasedSalePrice) {
      this.brokerCommission.purchaserCommission.purchaserTotalCommissionInclHst
        = Number(this.brokerCommission.purchaserCommission.purchaserBrokerCommission) + this.calculatePurchaserHstOnCommission();
    }
    return this.brokerCommission.purchaserCommission.purchaserTotalCommissionInclHst;
  }

  getCommissionPayableToPurchaserBrokerLabel() {
    let label = this.matter.getCoreCommissionPayableToPurchaserBrokerLabel();//BrokerCommissionComponent.getCoreCommissionPayableToPurchaserBrokerLabel(this.matter);
    //return localized label
    if (this.matter && this.matter.provinceCode) {
      return this.getLocalizedPayableToBrokerLabel(label, this.matter.provinceCode);
    } else {
      return label;
    }
  }

  getLocalizedPayableToBrokerLabel(label: string, provinceCode: string): string {
    if (label === BrokerCommissionPageLabels.Commission_Payable_To_Purchaser_Broker) {
      return provinceBasedFieldLabels.get('matter.broker.commission.CommissionPayableToPurchasersBroker', provinceCode);
    } else if (label === BrokerCommissionPageLabels.Commission_Payable_To_Vendor_Broker) {
      return provinceBasedFieldLabels.get('matter.broker.commission.CommissionPayableToVendorsBroker', provinceCode);
    } else if (label === BrokerCommissionPageLabels.Excess_Deposit_Refundable_To_Vendor) {
      return provinceBasedFieldLabels.get('matter.broker.commission.ExcessDepositRefundableToVendor', provinceCode);
    } else {
      return label;
    }
  }

  calculatePurchaserCommissionPayableToVendorBroker(): number {
    let purchaserTotalCommissionInclHst: number = this.calculatePurchaserTotalCommissionInclHst(); // this.brokerCommission.purchaserCommission.purchaserTotalCommissionInclHst;
    let purchaserDeposit: number = this.matter.calculateBrokerDeposit();

    // Transform all falsey to number 0
    if (!purchaserTotalCommissionInclHst) {
      purchaserTotalCommissionInclHst = 0;
    }

    if (!purchaserDeposit) {
      purchaserDeposit = 0;
    }
    if (this.isPurchaserDepositVisible) {
      //According  to BA confirmation, if Total Commission (incl. HST) >= Deposit, it should Total Commission (incl. HST) - Deposit
      // if Total Commission (incl. HST) < Deposit, it should Deposit - Total Commission (incl. HST)
      if (purchaserTotalCommissionInclHst >= purchaserDeposit) {
        this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker
          = purchaserTotalCommissionInclHst - purchaserDeposit;
      } else {
        this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker
          = purchaserDeposit - purchaserTotalCommissionInclHst;
      }
    } else {
      //In VENDOR_BROKER, VENDOR_LAWYER, PURCHASER_LAWYER, BUILDER, DEVELOPER don't not need to include deposit
      this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker
        = this.calculatePurchaserTotalCommissionInclHst();
    }
    this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker = Utils.roundNumber(this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker, 2);
    //the following line is used to monitor the value change for the calculated value in order to trigger the data refresh in TrustLedger
    this.commissionPayableToPurchaserBroker = this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;

    return this.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker;
  }

  getVendorOnLabel(): string {
    if (this.isBothOfCommissionPaidTo) {
      return 'On';
    } else {
      return this.matter.isMatterProvinceSK ? 'Commission based on ' :
        'Commission based on '
        + Utils.formatWithDecimals(this.brokerCommission.totalCommissionPercentage, this.maxFractDigits)
        + '% of';
    }
  }

  getRateOfHstOnCommissionLabel(): string {
    if ((this.matter.isMatterProvinceMB && this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceBC) {
      return 'Rate of GST on Commission';
    } else if (this.matter.isMatterProvinceMB && !this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Tax % on Commission';
    } else {
      return 'Rate of ' + this.matter.matterTaxType + ' on Commission';
    }
  }

  getCommissionInclHstLabel(): string {
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Commission (incl. GST)';
    } else if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && !this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Commission (incl. tax)';
    } else if (this.matter.isMatterProvinceBC) {
      return 'Total Commission (incl. GST)';
    } else {
      return 'Commission (incl. ' + this.matter.matterTaxType + ')';
    }
  }

  getVendorBrokerPercentageOfCommissionOnLabel(): string {
    if (this.matter.isMatterProvinceSK) {
      return '% of ' + this.formatCurrency(this.calculateCommissionBeforeHst());
    } else {
      return '%';
    }
  }

  getTotalCommissionInclHstLabelForVendorBroker(): string {
    if (this.matter.isMatterProvinceBC) {
      return 'Total Listing Commission (incl. GST)';
    } else {
      return this.getTotalCommissionInclHstLabel();
    }
  }

  getTotalCommissionInclHstLabelForPurchaserBroker(): string {
    if (this.matter.isMatterProvinceBC) {
      return 'Total Selling Commission (incl. GST)';
    } else {
      return this.getTotalCommissionInclHstLabel();
    }
  }

  getTotalCommissionInclHstLabel(): string {
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Total Commission (incl. GST)';
    } else if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && !this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Total Commission (incl. tax)';
    } else if (this.matter.isMatterProvinceBC) {
      return 'Total Listing Commission (incl. GST)';
    } else {
      return 'Total Commission (incl. ' + this.matter.matterTaxType + ')';
    }
  }

  getCommissionBeforeHstYesLabel(): string {
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && !this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Commission (before tax)';
    } else if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Commission (before GST)';
    } else if (this.matter.isMatterProvinceBC) {
      return 'Total Commission (before GST)';
    } else {
      return 'Commission (before ' + this.matter.matterTaxType + ')';
    }
  }

  formatCurrency(value: number): string {
    return (value != undefined && value != null && !isNaN(value)) ? this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2') : '';
  }

  getCommissionTaxOrGstOrHst(): string {
    if (this.matter.isMatterProvinceBC || (
      (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome())) {
      return 'GST on Commission';
    } else if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && !this.matter.matterProperties[ 0 ].isPurchaseTypeNewBuilderHome()) {
      return 'Tax on Commission';
    } else {
      return this.matter.matterTaxType + ' on Commission';
    }
  }

  async onCommissionBasedOnFixedPercentageOfSalePriceChange(isBrokerCommissionPageAction: boolean = true) {
    if (isBrokerCommissionPageAction) {
      this.enableSave();
    }
    await this.setRateOfHstOnCommissionDefaultValue();
    // If it is mass update, we don't need clean up them. It will lost import data. They are already the default value.
    // Only we clean up them when we change it in "Broker Commission" page.
    if (this.isNoOfCommissionBasedSalePrice && isBrokerCommissionPageAction) {
      this.brokerCommission.commissionBeforeHst = 0;
      this.brokerCommission.vendorCommission.vendorBrokerCommission = 0;
      this.brokerCommission.purchaserCommission.purchaserBrokerCommission = 0;
    }
    this.updateCommissionPayableToVendorBroker();
  }

  onDepositHeldByChange() {
    this.enableSave();
    this.setAppliedTowardsPaymentOfCommission();
    //If commissionBasedOnFixedPercentageOfSalePrice is not blank, update Commission calculation
    if (!this.isBlankOfCommissionBasedSalePrice) {
      this.updateCommissionPayableToVendorBroker();
    }
    if (this.matter.isSale && this.matter.isDepositHeldByVendorSolicitor() && this.matter.matterPropertyWithCondo) {
      this.matter.matterPropertyWithCondo.payDepositOutOfTrust = DpBooleanValueTypes.YES;
    }
    this.matter.reCalculateTrustLedgerReceivedDepositHeldInTrust();
  }

  onCommissionDisbursedByChange() {
    this.enableSave();
  }

  onBrokerReleaseExcessToChange() {
    if (this.matter && this.matter.brokerReleaseExcessTo === constValues.brokerReleaseExcessTo.VENDOR_LAWYER) {
      this.matter.insertExcessDepositIntoTrustLedger = true;
      this.updateCommissionPayableToVendorBroker();
    } else {
      this.matter.insertExcessDepositIntoTrustLedger = false;
    }
    this.enableSave();
  }

  onChangeSeparateEntries(): void {
    this.matter.reCalculateTrustLedgerPaidToRealEstateBroker();
    this.enableSave();
  }

  //used to monitor the value change, in order to refresh the data in TrustLedger
  set commissionPayableToPurchaserBroker(value: number) {
    if (this._commissionPayableToPurchaserBroker != value) {
      this.matter.reCalculateTrustLedgerPaidToRealEstateBroker();
    }
    this._commissionPayableToPurchaserBroker = value;
  }

  get commissionPayableToPurchaserBroker(): number {
    return this._commissionPayableToPurchaserBroker;
  }

  set commissionPayableToVendorBroker(value: number) {
    if (this._commissionPayableToVendorBroker != value) {
      this.matter.reCalculateTrustLedgerPaidToRealEstateBroker();
    }
    this._commissionPayableToVendorBroker = value;

  }

  get commissionPayableToVendorBroker(): number {
    return this._commissionPayableToVendorBroker;
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  get isListBrokerVisible(): boolean {
    return this.realEstateAgentWrapper && (!this.realEstateAgentWrapper.matterParticipant || this.realEstateAgentWrapper.expanded);
  }

  get isListBrokerVisibleInsideAgent(): boolean {
    return this.realEstateAgentWrapper && this.realEstateAgentWrapper.matterParticipant && this.realEstateAgentWrapper.expanded;
  }

  get isPurchaserBrokerVisible(): boolean {
    return this.purchaserRealEstateAgentWrapper && (!this.purchaserRealEstateAgentWrapper.matterParticipant || this.purchaserRealEstateAgentWrapper.expanded);
  }

  get isPurchaserBrokerVisibleInsideAgent(): boolean {
    return this.purchaserRealEstateAgentWrapper && this.purchaserRealEstateAgentWrapper.matterParticipant && this.purchaserRealEstateAgentWrapper.expanded;
  }

  cleanupAgentField(wrapper: MatterParticipantWrapper): void {
    if (wrapper && typeof wrapper.dataModel === 'string') {
      wrapper.dataModel = undefined;
      this.realEstateAgentsLoading = false;
    }
  }

  cleanupBrokerField(wrapper: MatterParticipantWrapper): void {
    if (wrapper && typeof wrapper.dataModel === 'string') {
      wrapper.dataModel = undefined;
      this.realEstateBrokersLoading = false;
    }
  }

  get isCommissionBeforeHstReadOnly(): boolean {
    return this.isNoOfCommissionBasedSalePrice && this.isBothOfCommissionPaidTo;
  }

  setCommissionBeforeHst(needEnableSave: boolean = true) {
    if (this.isNoOfCommissionBasedSalePrice && this.isBothOfCommissionPaidTo) {
      this.brokerCommission.commissionBeforeHst
        = +Number(Number(this.brokerCommission.vendorCommission.vendorBrokerCommission)
        + Number(this.brokerCommission.purchaserCommission.purchaserBrokerCommission)).toFixed(2);
      if (needEnableSave) {
        this.enableSave();
      }
    }
  }

  get isTotalCommissionPercentageReadOnly(): boolean {
    return this.isYesOfCommissionBasedSalePrice && this.isBothOfCommissionPaidTo;
  }

  setTotalCommissionPercentage(needEnableSave: boolean = true) {
    if (this.isYesOfCommissionBasedSalePrice && this.isBothOfCommissionPaidTo) {
      this.brokerCommission.totalCommissionPercentage
        = +Number(Number(this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission)
        + Number(this.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission)).toFixed(3);
      if (needEnableSave) {
        this.enableSave();
      }
    } else {
      if (this.isYesOfCommissionBasedSalePrice && this.matter.isMatterProvinceSK) {
        this.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission = 100;
      }
    }
    this.calculatePurchaserBrokerPercentageOfCommission();
  }

  ngAfterViewInit() {
  }

  updateCommissionPayableToVendorBroker(): void {
    if (this.matter && this.matter.isSale) {

      this.updateCommissionBasedOnFixedPercentageOfSalePriceWithSoa(true);
      this.calculatePurchaserCommissionPayableToVendorBroker();
      this.calculateVendorCommissionPayableToVendorBroker();
      this.calculateCommissionBeforeHst();
      this.calculateCommissionInclHst();
      if (!this.isNoOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
        this.calculateVendorBrokerCommission();
      }
      if (this.isYesOfCommissionBasedSalePriceBothOfCommissionPaidTo) {
        this.calculatePurchaserBrokerCommission();
      }
      this.matter.reCalculateTrustLedgerPaidToRealEstateBroker();
    }
  }

  updateCommissionBasedOnFixedPercentageOfSalePriceWithSoa(doNotUpdate?: boolean): void {
    if (this.isYesDivideSalePriceOrFactorInHstRebate) {
      if (!doNotUpdate) {
        this.getDynamicCommissionBasedSalePriceOptions();
      }
      // If "YES" option wss selected, it did not exist in current options.
      // Because "Net out HST from HST inclusive sale price" was changed and let the dropdown options be changed
      // It will select the second one
      if (this.matter.commissionBasedOnFixedPercentageOfSalePrice
        && this.commissionBasedSalePriceOptions && this.commissionBasedSalePriceOptions.length > 2) {
        if (this.matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.YES) {
          this.matter.commissionBasedOnFixedPercentageOfSalePrice = this.commissionBasedSalePriceOptions[ 1 ].value;
        } else {
          if (this.matter.commissionBasedOnFixedPercentageOfSalePrice !== DpBooleanValueTypes.NO) {
            if (this.matter.commissionBasedOnFixedPercentageOfSalePrice.indexOf('inclusive price') > 0) {
              this.matter.commissionBasedOnFixedPercentageOfSalePrice = this.commissionBasedSalePriceOptions[ 1 ].value;
            } else {
              this.matter.commissionBasedOnFixedPercentageOfSalePrice = this.commissionBasedSalePriceOptions[ 2 ].value;
            }
          }
        }
      }
    } else {
      //If select yesHstInclusiveLabel or yesNetLabel and the commissionBasedSalePriceOptions is changed to two items
      // it will choose DpBooleanValueTypes.YES
      this.commissionBasedSalePriceOptions = BrokerCommissionDropDowns.isCommissionBasedSalePriceOptions;
      if (this.matter.commissionBasedOnFixedPercentageOfSalePrice) {
        if (this.matter.commissionBasedOnFixedPercentageOfSalePrice !== DpBooleanValueTypes.NO) {
          this.matter.commissionBasedOnFixedPercentageOfSalePrice = DpBooleanValueTypes.YES;
        }
      }

    }
  }

  onInsertExcessDepositIntoTLChanged(): void {
    this.updateCommissionPayableToVendorBroker();
    this.enableSave();
  }

  isMassUpdateBlankCommissionPaidTo(): boolean {
    return this.matter.isTemplateMatterForMassUpdate
      && (this.matter.commissionPaidTo !== constValues.commissionPaidTo.VENDOR_BROKER_ONLY
        || this.matter.commissionPaidTo !== constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER);
  }

  isDepositHeldByVisible() {
    if (this.matter.isProjectSale) {
      return this.isBothOfCommissionPaidTo;
    } else {
      return true;
    }
  }

  get isAppliedTowardsPaymentOfCommissionReadyOnly(): boolean {
    if (this.matter && (!this.matter.isProjectSale || !this.matter.mortgages)) {
      if (this.matter && this.matter.isMatterProvinceBC) {
        return true;
      }
      if (this.matter.depositHeldBy !== constValues.depositHeldBy.VENDOR_BROKER && this.isVendorOnlyOfCommissionPaidTo) {
        return true;
      }
    }
    return false;
  }

  setAppliedTowardsPaymentOfCommission() {
    if (this.matter.isSale && !this.matter.isProjectSale) {
      if (this.matter.isMatterProvinceBC) {
        if ((this.matter.depositHeldBy === constValues.depositHeldBy.VENDOR_BROKER && (this.isBothOfCommissionPaidTo || this.isVendorOnlyOfCommissionPaidTo))
          || (this.matter.depositHeldBy === constValues.depositHeldBy.PURCHASER_BROKER && (this.isBothOfCommissionPaidTo || this.isPurchaserOnlyOfCommissionPaidTo))) {
          this.brokerCommission.appliedTowardsPaymentOfCommission = DpBooleanValueTypes.YES;
        } else {
          this.brokerCommission.appliedTowardsPaymentOfCommission = DpBooleanValueTypes.NO;

        }
      } else if (this.matter.depositHeldBy !== constValues.depositHeldBy.VENDOR_BROKER && this.isVendorOnlyOfCommissionPaidTo) {
        this.brokerCommission.appliedTowardsPaymentOfCommission = DpBooleanValueTypes.NO;
      }
    }
  }

  handleContactChange(isDeleted?: boolean) {
    if (this.matter && this.realEstateAgent) {
      let mp = new MatterParticipant();
      mp.contact = this.realEstateAgent;
      this.contactChangesListener.handleContactChange(this.matter, mp, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEAGENT, this.tabsService, isDeleted);
    }
  }

}
