import {TCOLDocumentCost, TCOLProductType} from "./tcol-document-cost";
import {BaseEntity} from "../../shared/BaseEntity/base-entity";

export class TCOLMunicipality extends BaseEntity{
  id: string;
  name: string;
  status: string;
  products: TCOLDocumentCost[];

  getProduct(type: TCOLProductType): TCOLDocumentCost {
    return this.products?.find(product => product.type === type)
  }
}
