<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-10">
            <h1>Stewart Title Authentication</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div *ngIf="!!message" class="padding-top-10 padding-left-10 vertical-align-label">
        {{message}}
    </div>
    <div class="row modal-body view-port" *ngIf="stewartTitleAuthUrl">
        <iframe height="410px" width="100%" [src]="stewartTitleAuthUrl" name="iframe"></iframe>
        <!--To be loaded in embedded page. currently opening in new browser-->
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </div>

    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
