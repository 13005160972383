<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Adjustment Type : Water</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >Is water supply to property Metered, Flat&nbsp;Rate or Well&nbsp;Water</label>
                    </div>
                    <div class="col-lg-5">
                        <select id="waterSupplyType" name="waterSupplyType" class="form-control"
                                [(ngModel)]="soaWaterLocal.waterSupplyType">
                            <option *ngFor="let wtrSupplyType of waterSupplyTypeList" [value]="wtrSupplyType.value">
                                {{wtrSupplyType.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="soaWaterLocal.isWaterSupplyTypeFlatRate">

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >Amount of current water bill</label>
                    </div>
                    <div class="col-lg-5">
                        <input type="text" class="form-control" id="currentWaterBill"
                               name="currentWaterBill" [(ngModel)]="soaWaterLocal.currentWaterBillAmount" dp-default-currency
                               dp-currency   dp-error-validator [fieldKey]="'matter.soa.soawater.currentwaterbill'" (blur)="setVendorHasPaidField()" />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >Commencement date of current billing&nbsp;period</label>
                    </div>
                    <div class="col-lg-5">
                        <dp-partial-date  #partialCommencementDate [inputDate]="soaWaterLocal.commencementDate"
                                         [fieldKey]="'matter.soa.soawater.commencementdate'"
                                         (dateChange)="onDateChangeCommencementDate($event)"></dp-partial-date>
                    </div>
                </div>
            </div>
            <div class="form-group">
                    <div class="col-lg-12">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-3 vertical-align-label">
                            <label class="control-label"
                            >End date of current billing period</label>
                        </div>
                        <div class="col-lg-5">
                            <dp-partial-date  #partialEndDate [inputDate]="soaWaterLocal.billingPeriodEndDate"
                                             [fieldKey]="'matter.soa.soawater.enddate'"
                                             (dateChange)="onDateChangeEndDate($event)"></dp-partial-date>
                        </div>
                    </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label" [dp-province-field-label]="'matter.soa.water.modal.adjustFor'" >Adjust for</label>
                    </div>
                    <ng-container *dpShowByProvince="'matter.soa.water-soa-modal.adjustFor'">
                        <div class="col-lg-2">
                            <select id=" waterAdjustType" name="waterAdjustType" class="form-control" [(ngModel)]="soaWaterLocal.adjustType"
                                    (change)="changeWaterAdjustType();">
                                <option *ngFor="let  wtrAdjustType of  waterAdjustTypeList" [value]="wtrAdjustType.value">
                                    {{wtrAdjustType.label}}
                                </option>
                            </select>
                        </div>
                        <div style="width: 6px;float: left;"><label class="control-label">?</label></div>
                    </ng-container>

                    <div class="col-lg-2"><select id="waterYesNoAdjust" (change)="changeAdjustFor();"  name="waterYesNoAdjust" class="form-control"
                                                  [(ngModel)]="soaWaterLocal.adjustFor">
                        <option *ngFor="let  wtrYesNoAdjust of  waterYesNoAdjustList" [value]="wtrYesNoAdjust.value">
                            {{wtrYesNoAdjust.label}}
                        </option>
                    </select></div>
                </div>
            </div>
            <div class="form-group" *ngIf="soaWaterLocal.isTrueAdjustFor">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >{{soaWaterLocal.adjustType}} percentage is</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                                id="percentage"
                                name="percentage"
                                class="form-control"
                                dp-percentage
                                [(ngModel)]="soaWaterLocal.percentage"
                                (blur)="setVendorHasPaidField()"
                        />
                    </div>
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label text-left">%</label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >Has {{context.vendorLabel}} paid full amount?</label>
                    </div>
                    <div class="col-lg-2">
                        <select id="vendorPaidYesNo" name="vendorPaidYesNo"  class="form-control" [(ngModel)]="soaWaterLocal.fullAmountPaid"
                                (change)="setVendorHasPaidField()">
                            <option *ngFor="let  vdrPaidYesNo of  vendorPaidYesNoList" [value]="vdrPaidYesNo.value">
                                {{vdrPaidYesNo.label}}
                            </option>
                        </select>
                    </div>
                </div>
             </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >{{context.vendorLabel}} has paid (toward current billing)</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="vendorPaidTowardsCurrentBilling" name="vendorPaidTowardsCurrentBilling" class="form-control"
                               [(ngModel)]="soaWaterLocal.paidTowardsCurrentBilling" dp-default-currency
                               dp-currency [dp-read-only]="soaWaterLocal.isTrueFullAmountPaid" />
                    </div>
                </div>
            </div>
            </div>



            <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd" >
                <div class="col-lg-12 form-group">
                    <div class="col-lg-4">
                        <label class="control-label"><u  *ngIf="soaWaterLocal.isWaterSupplyTypeFlatRate">FLAT RATE WATER</u><u
                            *ngIf="!soaWaterLocal.isWaterSupplyTypeFlatRate">WATER</u></label>
                    </div>
                </div>
                <div *ngIf="soaWaterLocal.isWaterSupplyTypeMetered">
                    <div class="col-lg-12  form-group" >
                        <div class="col-lg-4">
                            <label class="control-label"> {{ soaWaterLocal.waterSupplyTypeLabel}} - No Adjustment
                            </label>
                        </div>
                    </div>
                </div>
                    <div *ngIf="soaWaterLocal.isWaterSupplyTypeWellWater">
                        <div class="col-lg-12  form-group" >
                            <div class="col-lg-4">
                                <label class="control-label">Supplied by Well - No Adjustment</label>
                            </div>
                        </div>
                    </div>

                <div *ngIf="soaWaterLocal.isWaterSupplyTypeFlatRate">


                <div class="col-lg-12  form-group" >
                    <div class="col-lg-4">
                        <label class="control-label"
                        >Current Bill</label>
                    </div>
                    <div class="col-lg-3 text-right">
                        {{soaWaterLocal.currentWaterBillAmount | currency:CAD:'symbol'}}
                    </div>
                </div>
                <div class="col-lg-12  form-group"   *ngIf="soaWaterLocal.isTrueAdjustFor">
                    <div class="col-lg-4">
                        <label class="control-label"
                        >{{soaWaterLocal.adjustType}} calculated at {{soaWaterLocal.percentage/100 | percent:'1.2-3'}}</label>
                    </div>
                    <div class="col-lg-3 text-right">
                        {{(soaWaterLocal.currentWaterBillAmount * soaWaterLocal.percentage/100) | currency:CAD:'symbol'}}
                    </div>
                </div>
                <ng-container *ngIf="!fullAmountPaid">
                    <div class="col-lg-12  form-group">
                        <div class="col-lg-4">
                            <label class="control-label"
                            >{{context.vendorLabel}} has paid</label>
                        </div>
                        <div class="col-lg-3 text-right">
                            {{soaWaterLocal.paidTowardsCurrentBilling | currency:CAD:'symbol'}}
                        </div>
                    </div>
                    <div class="col-lg-12  form-group"  >
                        <div class="col-lg-4">
    <!--
                            <label class="control-label"
                            >for period commencing&nbsp;{{formattedCommencementDate}}</label>
    -->
                            <label class="control-label">{{commencingLabel}}</label>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="fullAmountPaid">
                    <div class="col-lg-12  form-group">
                        <div class="col-lg-4">
                            <label class="control-label"
                            >Paid by {{context.vendorLabel}} for period</label>
                        </div>
                    </div>
                    <div class="col-lg-12  form-group"  >
                        <div class="col-lg-4">
                            <!--<label class="control-label"-->
                            <!--&gt;commencing&nbsp;{{formattedCommencementDate}}</label>-->
                            <label class="control-label">{{commencingLabel}}</label>
                        </div>
                    </div>
                </ng-container>
                <div class="col-lg-12  form-group" >
                    <div class="col-lg-4">
    <!--                    <label class="control-label"
                        >{{context.vendorLabel}}'s share for <span *ngIf="!showQuestionMarksForVendorShare">{{soaWaterLocal
                            .getNumberOfDaysElapsed(context.closingDate)}}</span>
                            <span *ngIf="showQuestionMarksForVendorShare">???</span> days
                        </label>-->
                        <label class="control-label">{{context.vendorLabel}}{{vendorShareLabel}}</label>
                    </div>
                    <div class="col-lg-3 text-right">
    <!--                    <span *ngIf="showQuestionMarksForVendorShare">$?????????.??</span>
                        <span *ngIf="!showQuestionMarksForVendorShare">{{soaWaterLocal.getVendorsShare(context.closingDate) |
                            currency:CAD:'symbol'}} </span>-->
                        <span>{{showQuestionMarksForVendorShare ? multiQuestoinMarksValue : soaWaterLocal.getVendorsShare(context.closingDate, isPaysForDateOfClosingVendor) | currency:CAD:'symbol'}}</span>
                    </div>
                </div>

                <ng-container *ngIf="!soaWaterLocal.needToCreditVendor(context.closingDate)">
                    <div class="col-lg-12  form-group">
                        <div class="col-lg-4">
                            <label class="control-label"
                            ><span>Credit {{context.purchaserLabel}}</span></label>
                        </div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-3 text-right">
                            <span>{{getPurchaserCreditValue()}}</span>
                        </div>
                        <div class="col-lg-3"></div>
                    </div>
                </ng-container>
                <ng-container *ngIf="soaWaterLocal.needToCreditVendor(context.closingDate)">
                    <div class="col-lg-12  form-group">
                        <div class="col-lg-4">
                            <label class="control-label"
                            ><span> Credit {{context.vendorLabel}}</span></label>
                        </div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3 text-center">
                            <span>{{getVendorCreditValue()}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>


        </div>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 text-center">
                <button type="button" class="dp-btn keyboard-save-action" (click)="ok()" id="okBtn">
                    <span>OK</span>
                </button>

                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>

                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent ></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </form>
</div>
