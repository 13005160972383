<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="componentSoaLocal" id="componentSoaForm">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Component Adjustment</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Credit Purchaser, Vendor or No Adjustment?</label>
                </div>
                <div class="col-lg-4">
                    <select type="text"
                            id="creditType"
                            name="creditType"
                            [(ngModel)]="componentSoaLocal.creditType"
                            class="form-control"
                    >
                        <option *ngFor="let adjustmentOption of componentAdjustmentOptions" [value]="adjustmentOption.value">
                            {{adjustmentOption.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Use adjustment heading</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="useAdjustmentHeading"
                            name="useAdjustmentHeading"
                            [(ngModel)]="componentSoaLocal.useAdjustmentHeading"
                            class="form-control"
                    >
                        <option *ngFor="let useAdjHeadingOption of useAdjustmentHeadingOptions" [value]="useAdjHeadingOption.value">
                            {{useAdjHeadingOption.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="componentSoaLocal.useAdjustmentHeading == 'YES'">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Adjustment heading</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           name="adjustmentHeading"
                           id="adjustmentHeading"
                           maxlength="38"
                           [(ngModel)]="componentSoaLocal.adjustmentHeading"
                           trimmed
                           class="form-control"
                           dp-error-validator
                           [fieldKey]="'matter.soadj.component.description'"
                    />
                </div>
            </div>
        </div>

        <div id="itemsContainer" class="row padding-top-20 padding-bottom-10">
            <div class="col-lg-12 form-group" *ngIf="componentSoaLocal.useAdjustmentHeading == 'YES'">
                <div class="col-lg-1"></div>
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{componentSoaLocal.adjustmentHeading}}</label>
                </div>
            </div>

            <div *ngFor="let item of componentSoaLocal.items;let i = index;" >
                <div class="form-group">
                    <div class="col-lg-1">
                        <label class="control-label">Item{{i+1}}</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="itemName-{{i+currentTimeStamp}}"
                               name="itemName-{{i+currentTimeStamp}}"
                               maxlength="38"
                               [(ngModel)]="item.itemName"
                               class="form-control"
                        />
                        <div class="actionbtns">
                            <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeItem(item);" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 vertical-align-label">
                        <label class="control-label">Amount</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="itemCalculationMethod-{{i+currentTimeStamp}}"
                                name="itemCalculationMethod-{{i+currentTimeStamp}}"
                                [(ngModel)]="item.calculationMethod"
                                (change)="onChangeItemCalculationMethod(item)"
                                class="form-control"
                        >
                            <ng-template ngFor let-item [ngForOf]="calculationMethodOptions">
                                <optgroup *ngIf="item.subOptions" label="{{item.label}}">
                                    <option *ngFor="let child of item.subOptions" [value]="child.value">
                                        {{child.label}}
                                    </option>
                                </optgroup>
                                <option *ngIf="!item.subOptions" [value]="item.value">{{item.label}}</option>
                            </ng-template>
                        </select>


                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="itemAmount-{{i+currentTimeStamp}}" name="itemAmount-{{i+currentTimeStamp}}"
                               class="form-control"
                               style="text-align:right;"
                               [dp-read-only]="isItemAmountReadOnly(item)"
                               [(ngModel)]="item.amount"
                               allow-negative="true"
                               dp-currency
                               allow-blank="true"
                               />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-4"><span class="actionlink">
                                <span>
                                    <a href="javascript:void(0);" (click)="addItem()" id="addItem" tabindex="0">Add another item</a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                            </span>
                </div>
            </div>

        </div>


        <div class="padding-top-10 padding-left-5  modal-separator-line">
            <div class="col-lg-12 form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-3">
                    <label class="control-label">{{componentSoaLocal.getCreditNoteHeading()}}</label>
                </div>
                <div class="col-lg-3"></div>
                <ng-container *ngIf="componentSoaLocal.creditType == 'PURCHASER' || componentSoaLocal.creditType == 'VENDOR'">
                    <div class="col-lg-3">
                        <select type="text"
                                id="creditNoteCalculationMethod"
                                name="creditNoteCalculationMethod"
                                [(ngModel)]="componentSoaLocal.creditNoteCalculationMethod"
                                (change)="onChangeCreditNoteCalculationMethod()"
                                class="form-control"
                        >
                            <ng-template ngFor let-item [ngForOf]="creditNoteCalculationOptions">
                                <optgroup *ngIf="item.subOptions" label="{{item.label}}">
                                    <option *ngFor="let child of item.subOptions" [value]="child.value">
                                        {{child.label}}
                                    </option>
                                </optgroup>
                                <option *ngIf="!item.subOptions" [value]="item.value">{{item.label}}</option>
                            </ng-template>
                        </select>
                    </div>
                    <div class="col-lg-2  text-right">
                        <input type="text" id="creditNoteAmount" name="creditNoteAmount"
                               class="form-control"
                               style="text-align:right;"
                               [dp-read-only]="isCreditNoteAmountReadOnly"
                               [(ngModel)]="componentSoaLocal.creditNoteAmount"
                               allow-negative="true"
                               dp-currency
                               />
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>
                <button type="button" class="secondary-button" (click)="retrievePredefined()">
                    <span>Retrieve Predefined</span>
                </button>
                <button type="button" class="secondary-button" (click)="savePredefined()">
                    <span>Save Predefined</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

