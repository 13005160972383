import {MatterUndertaking, MatterUndertakingStatus, MatterUndertakingStatusValues} from '../shared/matter-undertaking';
import {Matter} from '../shared/matter';
import {Component, ElementRef, OnInit, Inject, ViewChild, ViewContainerRef} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {matterUndertakingStatusDropdowns} from '../shared/matter-drop-downs';
import {MortgagePrecedentComponent} from '../mortgages/mortgage/precedent/mortgage-precedent.component';
import {messages} from '../../common/messages';
import {PrecedentTypes} from '../shared/mortgage-precedent-type';
import {Observable} from 'rxjs';
import {FocusFirstElementModalDecorator} from '../../shared-main/focus-first-element-modal-decorator';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {undertakingTypes} from '../../common/common-labels';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import moment from 'moment';
import {PROVINCE_CODES} from '../shared/user-province';
import {GlobalLogger, LogLevelTypes} from '../../core/global-logger';
import {MortgageDispositionType} from '../../shared-main/constants';
import {UndertakingsConfig} from '../../admin/mortgage-discharge/undertakings-config';
import {UndertakingsConfigService} from '../../admin/shared/undertaking-config.service';
import {currentMatter, Utils} from '../shared';
import {maxFieldLength} from '../shared/max-field-length';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export type UndertakingsDetailAction = 'NEW' | 'EDIT' | 'DELETE';

export class UndertakingsDetailModalContext {
  public undertaking: MatterUndertaking;
  public action: UndertakingsDetailAction;
  public matter: Matter;
  public subject: string;
  public documentProfileCache: DocumentProfileCache;
}

@Component({
  selector: 'dp-undertakings-detail-modal',
  templateUrl: './undertakings-detail.modal.component.html',
  styleUrls: [
    './undertakings-detail.modal.component.scss'
  ],
  providers: [ DialogService, ErrorService, StatusBarService ]
})

@FocusFirstElementModalDecorator()
export class UndertakingsDetailModal extends ModalComponent<UndertakingsDetailModalContext> implements OnInit {
  matterUndertakingStatusOptions: SelectItem[];
  showDelete: boolean;
  subject: string;
  public undertaking: MatterUndertaking;
  @ViewChild('descriptionArea') public descriptionArea: ElementRef;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  descriptionAreaFocused: boolean = false;

  constructor(public dialog: MatDialogRef<UndertakingsDetailModal>,
              public dialogService: DialogService,
              public statusBarService: StatusBarService,
              public viewContainerRef: ViewContainerRef,
              public globalLogger: GlobalLogger,
              public undertakingsConfigService: UndertakingsConfigService,
              @Inject(MAT_DIALOG_DATA) context?: UndertakingsDetailModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.undertaking = (new MatterUndertaking()).copy(this.context.undertaking);
    this.matterUndertakingStatusOptions = matterUndertakingStatusDropdowns;

    this.showDelete = (this.context.action === 'EDIT') && !this.isDefaultMatterUndertakingsForNB();
    this.subject = this.context.subject;
    if (this.undertaking.matterUndertakingStatus && this.undertaking.matterUndertakingStatus.trim() === '') {
      this.globalLogger.log(LogLevelTypes.DEBUG, `UndertakingsDetailModal::invalid undertaking status on modal initialization ${ JSON.stringify(this.undertaking) }`);
    }

  }

  getSubjectLabel() {
    if (this.undertaking.isFromExistingMortgage(this.context.matter) || this.undertaking.requisitionTemplateId) {
      return provinceBasedFieldLabels.get('matter-undertaking-re', this.context.matter.provinceCode);
    } else {
      return provinceBasedFieldLabels.get('matter-undertaking-to', this.context.matter.provinceCode);
    }
  }

  subjectEditable() {
    return !this.undertaking.requisitionTemplateId && !this.undertaking.isFromExistingMortgage(this.context.matter)
      && !this.undertaking.isNonEncumbranceUndertakingNB() && !this.undertaking.isAcknowledgmentsNB();
  }

  isDefaultMatterUndertakingsForNB(): boolean {
    return (this.undertaking.isNonEncumbranceUndertakingNB() || this.undertaking.isAcknowledgmentsNB());
  }

  getDateToSave(event): string {
    //this.isRegisteredDateComplete();
    let date: string;

    if (event.year && event.month && event.day) {
      date = `${ event.year }/${ event.month }/${ event.day }`;
    }

    return date;
  }

  onFollowupDate(event): void {
    this.undertaking.followupDate = this.getDateToSave(event);

  }

  onRegisteredDate(event): void {
    this.undertaking.registeredDate = this.getDateToSave(event);

  }

  onInstrumentDate(event): void {
    this.undertaking.dateOfInstrument = this.getDateToSave(event);

  }

  /*
  isRegisteredDateComplete() : boolean {
   if (this.undertaking.registeredDate) {return true;} else {return false;}
  }
  */

  get isUndertakingStatusFulfilled(): boolean {
    return this.undertaking.matterUndertakingStatus === 'FULFILLED';
  }

  onInsertPrecedent(): void {
    this.precedentCoreAction('INSERT');
  }

  onSavePrecedent(): void {
    this.precedentCoreAction('SAVE');
  }

  public precedentCoreAction(precedentAction: string) {
    let input: string;
    let isDescriptionAreaFocused: boolean = this.descriptionAreaFocused;
    if (this.undertaking.requisitionTemplateId) {
      input = JSON.stringify({subject: this.context.subject, description: this.undertaking.description});

    } else {
      input = JSON.stringify({subject: this.undertaking.subject, description: this.undertaking.description});
    }
    this.dialogService.matDialogContent({
      content: MortgagePrecedentComponent,
      context: {
        additionalClause: input,
        action: precedentAction,
        caller: PrecedentTypes.PURCHASE_UNDERTAKING
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'ok') {//if  modal is closed after save
            if (precedentAction === 'INSERT') {
              try {
                const output = JSON.parse(result.additionalClause);
                if (output) {
                  if (!this.isDefaultMatterUndertakingsForNB()) {
                    this.undertaking.subject = output.subject ? output.subject : null;
                  }
                  if (this.undertaking && output.description) {
                    this.setDescriptionText(output.description, isDescriptionAreaFocused);
                  }
                }
              } catch (e) {
                // error in the above string (in this case, yes)!
                this.undertaking.subject = null;
                if (this.undertaking && result.additionalClause) {
                  this.setDescriptionText(result.additionalClause, isDescriptionAreaFocused);
                }
                console.log('MortgagePrecedentComponent: result.additionalClause is bad data', result.additionalClause);
              }

            }
          } else {
            //    For now, do nothing
          }
        }
      },
      
    }, this.viewContainerRef);
  }

  onOK(): void {
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.fieldsValueCleanup();
      this.close('OK');
    }
  }

  fieldsValueCleanup() {
    if (this.context.matter.provinceCode === PROVINCE_CODES.ONTARIO) {
      if (!this.isUndertakingStatusFulfilled) {
        //reset two fields' value
        if (this.undertaking) {
          this.undertaking.dischargedByInstrumentNo = undefined;
          this.undertaking.registeredDate = undefined;
        }

      }
    }
    if (this.undertaking) {
      // subject value cannot be over 2000 chars
      this.undertaking.subject = Utils.cutStringValue(this.undertaking.subject, maxFieldLength.undertaking.subject);
    }
  }

  close(action?: string): void {
    this.closeConfirm(action).subscribe((action: string) => {
      if (action) {
        this.dialog.close({action: action, undertaking: this.undertaking});
      }
    });
  }

  public closeConfirm(action?: string): Observable<string> {
    if ((!action || action === 'Cancel') && !this.undertaking.equals(this.context.undertaking)) {
      return this.dialogService.confirm('Confirmation', messages.undertaking.cancelConfirmation, false).map(res => {
        return res ? 'Cancel' : null;
      });
    } else {
      return Observable.of(action || 'Cancel');
    }
  }

  onDelete(): void {
    if (this.undertaking.requisitionTemplateId) {
      this.dialogService.confirm('Error', messages.undertaking.stopConfirmationRequisition, true).subscribe();
    } else if (this.undertaking.isFromExistingMortgage(this.context.matter)) {
      this.dialogService.confirm('Error',
        messages.getMessage('undertaking.stopConfirmationMortgage',
          currentMatter.value.isMatterProvinceBC ? currentMatter.value.provinceCode : null),
        true).subscribe();
    } else {
      this.dialogService.confirm('Confirmation', messages.undertaking.deleteConfirmation, false, 'Delete').subscribe(res => {
        if (res == true) {
          this.close('Delete');
        }
      });
    }
  }

  setDescriptionAreaFocusFlag(): void {
    this.descriptionAreaFocused = true;
    setTimeout(() => {
      this.descriptionAreaFocused = false;
    }, 500);
  }

  public setDescriptionText(description: string, isFieldInFocus: boolean): void {
    let cursorPosition = this.descriptionArea && this.descriptionArea.nativeElement && this.descriptionArea.nativeElement.selectionStart;
    if (cursorPosition > -1) {
      if (!isFieldInFocus) {
        cursorPosition = 0;
      }
      this.undertaking.description = this.undertaking.description
        ? this.undertaking.description.slice(0, cursorPosition) + description + this.undertaking.description.slice(cursorPosition)
        : description;
    }
  }

  get undertakingDescriptionLabel(): string {
    if (this.undertaking.onCCT && (this.context.matter.isMatterProvinceSK || this.context.matter.isMatterProvinceMB || this.context.matter.isMatterProvinceNS)) {
      return 'The solicitor for the vendor undertakes:';
    } else if (this.isDefaultMatterUndertakingsForNB()) {
      return 'Modify default wording according to requirements of the transaction, and check the "Approved" checkbox.';
    } else {
      if (this.context.matter.isMortgage) {
        return provinceBasedFieldLabels.get('matter-undertaking-detail-modal-undertakingDescriptionLabel-M', this.context.matter.provinceCode);
      } else if (this.context.matter.isSale) {
        let documentProfile = this.context.documentProfileCache.cachedDocumentProfile;
        if (documentProfile && documentProfile.firmDocumentProfile && (documentProfile.firmDocumentProfile.solePractitionerTypeCode == 'YES_MALE' || documentProfile.firmDocumentProfile.solePractitionerTypeCode == 'YES_FEMALE')) {
          return provinceBasedFieldLabels.get('matter-undertaking-detail-modal-undertakingDescriptionLabel-S-i', this.context.matter.provinceCode);
        } else {
          return provinceBasedFieldLabels.get('matter-undertaking-detail-modal-undertakingDescriptionLabel-S-we', this.context.matter.provinceCode);
        }
      } else if (this.context.matter.isPurchase) {
        return provinceBasedFieldLabels.get('matter-undertaking-detail-modal-undertakingDescriptionLabel-P', this.context.matter.provinceCode);
      }
    }
  }

  get descriptionFieldKey(): string {
    return provinceBasedFieldLabels.get('matter.undertaking-details-modal.description.fieldKey', this.context.matter.provinceCode);
  }

  isAdditionalDataVisible(): boolean {
    return this.undertaking && this.undertaking.onCCT && this.undertaking.isTypeVendorsLienCaveat() && this.context.matter.isSale;
  }

  getTypeOfEncumbrance(): string {
    switch (this.undertaking.typeOfEncumbrance) {
      case undertakingTypes.mortgage.key :
        return undertakingTypes.mortgage.text;
      case undertakingTypes.vendorsLienCaveat.key :
        return undertakingTypes.vendorsLienCaveat.text;
      case undertakingTypes.writ.key :
        return undertakingTypes.writ.text;
      case undertakingTypes.other.key :
        return undertakingTypes.other.text;
      default :
        return '';
    }
  }

  getEncumbranceTitle(): string {
    if (this.context.matter.isMatterProvinceBC) {
      return 'Charge Appearing on Title Search';
    }
    if (this.context.matter.isMatterProvinceMB || this.context.matter.isMatterProvinceNS) {
      return 'Encumbrance Appearing on Certificate of Title';
    } else {
      return `Encumbrance Appearing on Certificate of Title(${ this.getTypeOfEncumbrance() })`;
    }
  }

  get undertakingDescription(): string {
    if (this.undertaking.onCCT) {

      if (this.context.matter.isMatterProvinceNS && this.undertaking.isFromExistingMortgage(this.context.matter)) {
        let undertakingsConfig: UndertakingsConfig = this.undertakingsConfigService.getCachedConfig(this.context.matter.provinceCode);
        this.undertaking.updateUndertakingFromMortgageDescriptionNS(undertakingsConfig);
        return this.undertaking.description;
      }

      this.undertaking.description = `${ this.undertaking.actionToBeTaken } ${ this.undertaking.instrumentType }`;
      if (this.undertaking.instrumentNo) {
        this.undertaking.description += ` No. ${ this.undertaking.instrumentNo }`;
      }
      if (this.undertaking.inFavourOf) {
        this.undertaking.description += ` in favour of ${ this.undertaking.inFavourOf }`;
      }
      if (this.undertaking.dateOfInstrument) {
        this.undertaking.description += ` dated ${ moment(this.undertaking.dateOfInstrument).format('LL') }`;
      }
      if (this.undertaking.registeredDate) {
        this.undertaking.description += ` registered on ${ moment(this.undertaking.registeredDate).format('LL') }`;
      }
      if (this.context.matter.isMatterProvinceMB && this.undertaking.id && this.undertaking.isFromExistingMortgage(this.context.matter)) {
        let mortgage = this.context.matter.existingMortgages.find(existingMortgage => existingMortgage.undertakingId == this.undertaking.id);
        if (mortgage && mortgage.instrumentId && mortgage.mortgageDispositionType == MortgageDispositionType.DISCHARGED) {
          let tprInstrument = this.context.matter.getTprInstrumentByInstrumentId(mortgage.instrumentId);
          if (tprInstrument && tprInstrument.affectedByInstrumentTypeCode && tprInstrument.affectedByInstrumentTypeCode == 'AA') { //Amending Agreement - TPR
            this.undertaking.description += ', including the deletion of the Assignment No. ' + tprInstrument.affectedByInstrumentNumber;
          }
        }
      }
    }
    return this.undertaking.description;
  }

  approvedSOAFlagChange(): void {
    if (this.isDefaultMatterUndertakingsForNB()) {
      !!this.undertaking.approvedForSoAdjFlag ? (this.undertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.approved) :
        (this.undertaking.matterUndertakingStatus = <MatterUndertakingStatus>MatterUndertakingStatusValues.not_approved);
    } else {
      (this.undertaking.approvedForSoAdjFlag == false) ? (this.undertaking.purchaserUndertakingFlag = false) : null;
    }

  }

  get maxFieldLengthForSubject(): number {
    return maxFieldLength.undertaking.subject;
  }

  ngAfterViewInit() {
  }
}
