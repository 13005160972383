import {Address} from './address';
import {AddressTypes} from './address-types';
import {Telephone} from './telephone';
import {TelephoneTypes} from './telephone-types';
import Utils from '../../shared-main/utils';
import {ContactUtil} from './contact-util';
import {DpBooleanValue} from './dp-boolean';

export class ContactInfo {
  constructor(contactInfo?: ContactInfo) {
    this.addresses = [];
    this.telephones = [];
    this.lawClerkTelephones = [];

    if (contactInfo) {
      for (let prop in contactInfo) {
        if (contactInfo.hasOwnProperty(prop)) {
          this[ prop ] = contactInfo[ prop ];
        }
      }

      if (Array.isArray(contactInfo.addresses)) {
        for (let i: number = 0; i < contactInfo.addresses.length; i++) {
          this.addresses[ i ] = new Address(contactInfo.addresses[ i ]);
        }
      }

      if (Array.isArray(contactInfo.telephones)) {
        for (let j: number = 0; j < contactInfo.telephones.length; j++) {
          this.telephones[ j ] = new Telephone(contactInfo.telephones[ j ]);
        }
      }

      if (Array.isArray(contactInfo.lawClerkTelephones)) {
        for (let k: number = 0; k < contactInfo.lawClerkTelephones.length; k++) {
          this.lawClerkTelephones[ k ] = new Telephone(contactInfo.lawClerkTelephones[ k ]);
        }
      }
    }
  }

  id: number;
  contactInfoType: string;
  instanceType: string;
  reline: string;
  solicitorName: string;
  firmName: string;
  solicitorId: number;
  firmId: number;
  barristerSolicitor: string;
  dear: string;
  email: string;
  fileNumber: string;
  lawClerkName: string;
  lawClerkId: number;
  lawClerkEmail: string;
  branchTransitNumber: number;
  telephones: Telephone[];
  lawClerkTelephones: Telephone[];
  addresses: Address[] = [];

  //Fire Insurance Data
  insuranceBrokerType: string;
  documentationType: string;
  expiryDate: string;
  coverage: number;
  agent: string;
  guaranteedReplacementCoverage: string;
  mortgageSelected: string;
  // companyInsurerId : number;
  companyInsurerName: string;
  documentationValue: string;
  titleDetails: string;
  titleDetailsType: string;
  individualTitleDetails: boolean = false;
  residingAtSubjectProperty: string;
  costToObtainBinder: number;
  subjectToGst: DpBooleanValue;
  taxRate: number;

  get serviceAddress(): Address {
    let serviceAddress: Address = this.addresses.find((address: Address) => {
      return address.addressTypeCode === AddressTypes.serviceAddress;
    });
    if (!serviceAddress) {
      serviceAddress = new Address();
      serviceAddress.country = 'Canada';
      serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
      this.addresses.push(serviceAddress);
    }
    return serviceAddress;
  }

  get mailingAddress(): Address {
    let mailingAddress: Address = this.addresses.find((address: Address) => {
      return address.addressTypeCode === AddressTypes.mailing;
    });
    if (!mailingAddress) {
      mailingAddress = new Address();
      mailingAddress.addressTypeCode = AddressTypes.mailing;
      this.addresses.push(mailingAddress);
    }
    return mailingAddress;
  }

  set mailingAddress(mailingAddress) {
    let index: number = this.addresses.findIndex((address: Address) => {
      return address.addressTypeCode === AddressTypes.mailing;
    });
    let newMailingAddress: Address = new Address(mailingAddress);
    newMailingAddress.addressTypeCode = AddressTypes.mailing;

    if (index > -1) {
      this.addresses[ index ] = newMailingAddress;
    } else {
      this.addresses.push(newMailingAddress);
    }

  }

  private clearAddress(addressType: string): void {
    const index: number = this.addresses.findIndex((address: Address) => {
      return address.addressTypeCode === addressType;
    });

    if (index > -1) {
      this.addresses[ index ] = new Address({addressTypeCode: addressType} as Address);
    }
  }

  clearMailingAddress(): void {
    this.clearAddress(AddressTypes.mailing);
  }

  clearServiceAddress(): void {
    this.clearAddress(AddressTypes.serviceAddress);
  }

  /**
   * Get the phone (for the specified type) from the list of phones, or create and return a new one if one doesn't already exists.
   * @param type
   * @returns {Telephone}
   */
  getPhone(type: string, phoneList: Telephone[]): Telephone {
    if (!Array.isArray(phoneList)) {
      phoneList = [];
    }

    let phone: Telephone = phoneList.find((t: Telephone) => t.phoneTypeCode === type);
    if (!phone) {
      phone = new Telephone();
      phone.phoneTypeCode = type;
      phoneList.push(phone);
    }
    return phone;
  }

  /**
   * Achieve get for work/fax phone
   * @param telephoneType
   * @param phoneList
   * @returns {string}
   */
  getPhoneByType(telephoneType: string, phoneList: Telephone[]): string {
    let telephoneNumber: string;
    switch (telephoneType) {
      case TelephoneTypes.work:
        telephoneNumber =
          this.getPhone(TelephoneTypes.work, phoneList).telephoneNumber;
        break;
      case TelephoneTypes.fax:
        telephoneNumber =
          this.getPhone(TelephoneTypes.fax, phoneList).telephoneNumber;
        break;
      case TelephoneTypes.cell:
        telephoneNumber =
          this.getPhone(TelephoneTypes.cell, phoneList).telephoneNumber;
        break;
      default:
        break;

    }
    return telephoneNumber;
  }

  /**
   * Achieve set for work/fax phone
   * @param telephoneType
   * @param phone
   * @param phoneList
   */
  setPhoneByType(telephoneType: string, phoneList: Telephone[], phone: string) {
    switch (telephoneType) {
      case TelephoneTypes.work:
        this.getPhone(TelephoneTypes.work, phoneList).telephoneNumber = phone;
        break;
      case TelephoneTypes.fax:
        this.getPhone(TelephoneTypes.fax, phoneList).telephoneNumber = phone;
        break;
      case TelephoneTypes.cell:
        this.getPhone(TelephoneTypes.cell, phoneList).telephoneNumber = phone;
        break;
      default:
        break;

    }
  }

  get workPhone(): string {
    return this.getPhoneByType(TelephoneTypes.work, this.telephones);
  }

  set workPhone(phone: string) {
    this.setPhoneByType(TelephoneTypes.work, this.telephones, phone);
  }

  removeWorkPhone() {
    const index: number
      = this.telephones.findIndex((t: Telephone) => t.phoneTypeCode === TelephoneTypes.work);
    if (index !== -1) {
      this.telephones.splice(index, 1);
    }
  }

  get faxPhone(): string {
    return this.getPhoneByType(TelephoneTypes.fax, this.telephones);
  }

  set faxPhone(phone: string) {
    this.setPhoneByType(TelephoneTypes.fax, this.telephones, phone);
  }

  removeFaxPhone() {
    const index: number
      = this.telephones.findIndex((t: Telephone) => t.phoneTypeCode === TelephoneTypes.fax);
    if (index !== -1) {
      this.telephones.splice(index, 1);
    }
  }

  get cellPhone(): string {
    return this.getPhoneByType(TelephoneTypes.cell, this.telephones);
  }

  set cellPhone(phone: string) {
    this.setPhoneByType(TelephoneTypes.cell, this.telephones, phone);
  }

  removeCellPhone() {
    const index: number
      = this.telephones.findIndex((t: Telephone) => t.phoneTypeCode === TelephoneTypes.cell);
    if (index !== -1) {
      this.telephones.splice(index, 1);
    }
  }

  get lawClerkWorkPhone(): string {
    return this.getPhoneByType(TelephoneTypes.work, this.lawClerkTelephones);
  }

  set lawClerkWorkPhone(phone: string) {
    this.setPhoneByType(TelephoneTypes.work, this.lawClerkTelephones, phone);
  }

  removeLawClerkWorkPhone() {
    const index: number
      = this.lawClerkTelephones.findIndex((t: Telephone) => t.phoneTypeCode === TelephoneTypes.work);
    if (index !== -1) {
      this.lawClerkTelephones.splice(index, 1);
    }
  }

  get lawClerkCellPhone(): string {
    return this.getPhoneByType(TelephoneTypes.cell, this.lawClerkTelephones);
  }

  set lawClerkCellPhone(phone: string) {
    this.setPhoneByType(TelephoneTypes.cell, this.lawClerkTelephones, phone);
  }

  removeLawClerkCellPhone() {
    const index: number
      = this.lawClerkTelephones.findIndex((t: Telephone) => t.phoneTypeCode === TelephoneTypes.cell);
    if (index !== -1) {
      this.lawClerkTelephones.splice(index, 1);
    }
  }

  get lawClerkFaxPhone(): string {
    return this.getPhoneByType(TelephoneTypes.fax, this.lawClerkTelephones);
  }

  set lawClerkFaxPhone(phone: string) {
    this.setPhoneByType(TelephoneTypes.fax, this.lawClerkTelephones, phone);
  }

  removeLawClerkFaxPhone() {
    const index: number
      = this.lawClerkTelephones.findIndex((t: Telephone) => t.phoneTypeCode === TelephoneTypes.fax);
    if (index !== -1) {
      this.lawClerkTelephones.splice(index, 1);
    }
  }

  get localContactName(): string {
    return this.lawClerkName;
  }

  set localContactName(value: string) {
    this.lawClerkName = value;
  }

  get localContactId(): number {
    return this.lawClerkId;
  }

  set localContactId(value: number) {
    this.lawClerkId = value;
  }

  get policyNumber(): string {

    if (this.documentationType == 'POLICY_NUMBER') {
      return Utils.isEmptyString(this.documentationValue) ? '' : this.documentationValue;
    }
    return '';
  }

  resetValues(): void {
    this.reline = null;
    this.solicitorName = null;
    this.firmName = null;
    this.solicitorId = null;
    this.firmId = null;
    this.barristerSolicitor = null;
    this.dear = null;
    this.email = null;
    this.fileNumber = null;
    this.lawClerkName = null;
    this.lawClerkId = null;
    this.lawClerkEmail = null;
    this.branchTransitNumber = null;
    this.telephones = [];
    this.lawClerkTelephones = [];
    this.addresses = [];

    //Fire Insurance Data
    // insuranceBrokerType : string;
    // documentationType : string;
    this.expiryDate = null;
    this.coverage = null;
    this.agent = null;
    this.guaranteedReplacementCoverage = null;
    this.mortgageSelected = null;
    this.companyInsurerName = null;
    this.documentationValue = null;
  }

  isTitleDetailsManual(): boolean {
    return (this.titleDetailsType && this.titleDetailsType == 'MANUALLY_ENTERED');
  }

  get isResideAtSubjectProperty(): boolean {
    return this.residingAtSubjectProperty === 'YES' || this.residingAtSubjectProperty === 'Y_n';
  }

  clearIds(): void {
    this.id = null;
    if (Array.isArray(this.addresses)) {
      this.addresses.forEach(value => {
        value.id = null;
      });
    }

    if (Array.isArray(this.telephones)) {
      this.telephones.forEach(value => {
        value.id = null;
      });
    }

    if (Array.isArray(this.lawClerkTelephones)) {
      this.lawClerkTelephones.forEach(value => {
        value.id = null;
      });
    }
  }

  getLawClerkFirstEmail(): string {
    return ContactUtil.extractFirstEmail(this.lawClerkEmail);
  }
}

