<!-- modal -->
<div>
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1  [dp-province-field-label]="'jurisdiction.title'" [label-province-code]="context.defaultProvinceCode">Add new Jurisdiction</h1></div>
            <button  type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body padding-top-0 jurisdiction-left-nav-modal jurisdiction-modal"  *ngIf="context && context.jurisdictionTab">
        <dp-jurisdiction-sectioned  [jurisdictionTab]="context.jurisdictionTab" [jurisdictionProvinceReadOnly] = true (modalActionUpdate)="update($event)"></dp-jurisdiction-sectioned>
    </div>
</div>
