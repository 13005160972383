import {Tab, TabType} from '../shared/tabbing/tab';
import {ErrorTab} from '../shared/error-handling/error-tab';
import {AdjudicationComponent} from './adjudication.component';
import {AdjudicationRequest, AdjudicationRequestListState} from './adjudication-list/adjudication-request';
import {Contact} from '../matters/shared/contact';

export const AdjudicationTabType: TabType = 'adjudication';

export class AdjudicationTab extends Tab {

  constructor(json?: AdjudicationTab) {
    super(json);
  }

  adjudicationRequestListState: AdjudicationRequestListState;
  adjudicationComponent: AdjudicationComponent;
  errorTab: ErrorTab;

  contact: Contact;
  adjudicationRequest: AdjudicationRequest;
  contactSubType: string;
  contactKey: string;
  subRoute: string;
  subRouteId: number;

  static createAdjudicationRequestContactTab(adjudicationRequest: AdjudicationRequest, contact: Contact, routeBase: string,
                                             contactKey?: string, subRoute?: string, subRouteId?: number): AdjudicationTab {
    const _tab: any = {
      id: adjudicationRequest.identifier,
      tabType: AdjudicationTabType,
      type: AdjudicationTabType,
      title: contact.organizationName,
      contactKey: contactKey ? contactKey : contact.contactType,
      contact: contact,
      contactSubType: contact.contactType,
      route: routeBase,
      subRoute: subRoute,
      subRouteId: subRouteId,
      adjudicationRequest: adjudicationRequest
    };
    let tab: AdjudicationTab = new AdjudicationTab(_tab);
    return tab;
  }

  get routeParams(): string[] {
    if (this.id) {
      if (this.subRoute && this.subRouteId) {
        return [ this.route, this.id.toString(), this.subRoute, this.subRouteId.toString() ];
      } else if (this.subRoute) {
        return [ this.route, this.id.toString(), this.subRoute ];
      } else {
        return [ this.route, this.id.toString() ];
      }
    } else {
      return [ this.route ];
    }

  }

  deCoupleUIComponent(): void {
    this.adjudicationComponent = null;
  }
}
