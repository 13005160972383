import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';

export class StatementAdjustmentDisplayAmountPaidInterimClosingBuilder {
  static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder, modalDisplay?: boolean): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Purchaser`, null));
    return sadItemDetails;
  }

}
