import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {CreateDocketModal} from '../create-docket/create-docket.modal.component';
import {Matter} from '../../../shared/index';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {TeranetParcelRegisterSearchRequest} from '../../../../shared-main/teranet/teranet-parcel-register-search-request';
import {TeranetParcelRegisterSearchResponse} from '../../../../shared-main/teranet/teranet-parcel-register-search-response';
import {TeranetParcelRegisterPurchaseRequest} from '../../../../shared-main/teranet/teranet-parcel-register-purchase-request';
import {TeranetPinInfo} from '../../../../shared-main/teranet/teranet-pin-info';
import {ApplicationError} from '../../../../core/application-error';
import {FieldError} from '../../../../core';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {Observable} from 'rxjs/Rx';
import {LockScreenService} from '../../../../core/lock-screen.service';
import {messages} from '../../../../common';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class PurchaseParcelRegistersContext {
  matter: Matter;
  teranetUser: TeranetUser;
  teranetSearchRequest: TeranetParcelRegisterSearchRequest;
  teranetSearchResponse: TeranetParcelRegisterSearchResponse;
}

@Component({
  selector: 'dp-purchase-parcel-registers-modal',
  templateUrl: 'purchase-parcel-registers.modal.component.html',
  styleUrls: ['./purchase-parcel-registers.scss'],
  providers: [ErrorService]
})
export class PurchaseParcelRegistersModal extends ModalComponent<PurchaseParcelRegistersContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  warningMessage: string = '';

  constructor(
    public dialog: MatDialogRef<PurchaseParcelRegistersModal>,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    public lockScreenService: LockScreenService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: PurchaseParcelRegistersContext
  ) {
    super(dialog, context);
  }

  get teranetSearchRequest(): TeranetParcelRegisterSearchRequest {
    return this.context.teranetSearchRequest;
  }

  get teranetSearchResponse(): TeranetParcelRegisterSearchResponse {
    return this.context.teranetSearchResponse;
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get teranetUser(): TeranetUser {
    return this.context.teranetUser;
  }

  ngOnInit() {}

  openCreateDocketModal() {
    this.dialogService.matDialogContent({
      content: CreateDocketModal,
      context: {
        matter: this.matter,
        teranetUser: this.teranetUser
      },
      onFulfillment: (result: any) => {
        if (result && result.action == 'OK' && result.teranetDocket) {
          this.matter.teranetDocket = result.teranetDocket;
          this.proceedToPurchase();
        } else if (result && result.action === 'Cancel') {
          this.onCancel();
        }
      }
    });
  }

  purchase(): void {
    if (!this.matter.teranetDocket || !this.matter.teranetDocket.docketSystemId) {
      this.openCreateDocketModal();
    } else if (this.isPinAlreadyPurchased()) {
      this.warningMessage = this.warningMessage + '<br> Do you wish to proceed to order it again?';
      this.dialogService
        .confirm('Teranet Connect &trade;: Parcel Register Already Exists', this.warningMessage, false, 'Proceed')
        .subscribe((res) => {
          if (res) {
            this.proceedToPurchase(true);
          } else {
            this.warningMessage = '';
          }
        });
    } else {
      this.proceedToPurchase();
    }
  }

  isPinAlreadyPurchased(): boolean {
    let isPinPurchased: boolean = false;
    this.teranetSearchResponse.searchedProperties.forEach((item) => {
      this.matter.teranetDocket.parcelRegisters.forEach((parcelRegister) => {
        if (item.purchasePin && item.pin === parcelRegister.pin) {
          isPinPurchased = true;
          this.warningMessage =
            this.warningMessage +
            'The Parcel Register for PIN ' +
            parcelRegister.pin +
            ' was previously ordered on ' +
            parcelRegister.creationDate +
            '. If ordered again, a new Parcel Register will be created ' +
            'resulting in more than one Parcel Register with the same PIN.' +
            '<br><br>';
        }
      });
    });
    return isPinPurchased;
  }

  proceedToPurchase(allowDuplicates?: boolean): void {
    this.warningMessage = '';
    if (this.isAnyParcelSelectedForPurchase()) {
      let purchaseRequestCalls: any = [];
      this.teranetSearchResponse.searchedProperties.forEach((item) => {
        if (item.purchasePin) {
          purchaseRequestCalls.push(
            this.teranetService.purchaseParcelRegister(
              this.createPurchaseRequestFromSearchResponse(item, allowDuplicates),
              true
            )
          );
        }
      });
      this.lockScreenService.lockForUpdate = true;
      let dialogRef = this.showWaitMessgePopup();
      Observable.forkJoin(purchaseRequestCalls)
        .finally(() => {
          dialogRef.close();
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe(
          (results) => {
            for (let i = 0; i < results.length; i++) {
              let parcelRegister: ParcelRegister = new ParcelRegister(results[i] as ParcelRegister);
              this.matter.updateSameParcelRegisterIndex(parcelRegister);
              this.matter.teranetDocket.parcelRegisters.push(parcelRegister);
            }
            this.dialog.close({action: 'OK'});
          },
          (error: ApplicationError) => {
            this.handleTeranetSearchApiError(error);
          }
        );
    }
  }

  showWaitMessgePopup(): MatDialogRef<any> {
    let dialogRef: MatDialogRef<any> = this.dialogService.confirmDialog(
      messages.teranet.purchaseParcelWaitMessage.title,
      messages.teranet.purchaseParcelWaitMessage.text,
      true,
      null,
      null,
      false,
      true
    );
    return dialogRef;
  }

  handleTeranetSearchApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      let errorMessage: string = error.errorCode + ' : ';
      errorMessage +=
        error.errorCode == 'app.TeranetPinAlreadyPurchased'
          ? 'A parcel register with this PIN has already been ordered. Please close the matter and reopen to view it.'
          : error.errorMessage;
      this.modalErrorComponent.createCustomDPFieldError(error.errorCode, errorMessage, null, 'ERROR');
    }
  }

  createPurchaseRequestFromSearchResponse(
    teranetPinInfo: TeranetPinInfo,
    allowDuplicates?: boolean
  ): TeranetParcelRegisterPurchaseRequest {
    let purchaseRequest: TeranetParcelRegisterPurchaseRequest = new TeranetParcelRegisterPurchaseRequest();
    purchaseRequest.docketSystemId = this.matter.teranetDocket.docketSystemId;
    purchaseRequest.matterDocketId = this.matter.teranetDocket.matterDocketId;
    purchaseRequest.lroNumber = this.teranetSearchResponse.lroNumber;
    purchaseRequest.propertyPin = teranetPinInfo.pin;
    purchaseRequest.includeDeletedInstruments = teranetPinInfo.includeDeletedInstruments;
    purchaseRequest.applyAdjacentPropDiscount = false;
    purchaseRequest.teranetUser = this.teranetUser;
    purchaseRequest.address = teranetPinInfo.fullAddress;
    purchaseRequest.allowDuplicates = allowDuplicates;
    return purchaseRequest;
  }

  isAnyParcelSelectedForPurchase(): boolean {
    return this.teranetSearchResponse.searchedProperties.some((item) => {
      return item.purchasePin;
    });
  }

  onCancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  backToSearch(): void {
    this.dialog.close({action: 'backToSearch'});
  }

  get searchTitle(): string {
    switch (this.teranetSearchRequest.searchType) {
      case 'SEARCH_BY_ADDRESS':
        return 'Search PIN By Address';
      case 'SEARCH_BY_NAME':
        return 'Search PIN By Name';
      case 'SEARCH_BY_PIN':
        return 'Search PIN by PIN';
      case 'SEARCH_BY_INSTRUMENT':
        return 'Search PIN by Instrument Number';
      default:
        return '';
    }
  }

  get searchLabel(): string {
    switch (this.teranetSearchRequest.searchType) {
      case 'SEARCH_BY_ADDRESS':
        return 'Address searched:';
      case 'SEARCH_BY_NAME':
        return 'Name searched:';
      case 'SEARCH_BY_PIN':
        return 'PIN searched:';
      case 'SEARCH_BY_INSTRUMENT':
        return 'Instrument Number searched:';
      default:
        return '';
    }
  }

  get searchLabelValue(): string {
    switch (this.teranetSearchRequest.searchType) {
      case 'SEARCH_BY_ADDRESS':
        return (
          this.teranetSearchRequest.streetNumber +
          ' ' +
          this.teranetSearchRequest.streetName +
          (this.teranetSearchRequest.unitNumber ? ' ' + this.teranetSearchRequest.unitNumber : '')
        );
      case 'SEARCH_BY_NAME':
        return (
          this.teranetSearchRequest.lastName +
          (this.teranetSearchRequest.firstName ? ' ' + this.teranetSearchRequest.firstName : '')
        );
      case 'SEARCH_BY_PIN':
        return this.teranetSearchRequest.pin;
      case 'SEARCH_BY_INSTRUMENT':
        return this.teranetSearchRequest.instrumentNumber;
      default:
        return '';
    }
  }
}
