import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {Matter} from '../shared/matter';
import {MatterTab} from '../matter-tab';
import {DPError, ErrorType} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {MatterTitleInsurance} from '../shared/matter-title-insurance';
import {Mortgage} from '../shared/mortgage';
import {DpFooterTypes} from '../../shared/error-handling/dp-footer-notification';

@Component({
  selector: 'dp-title-insurance',
  templateUrl: './title-insurance.component.html'
})

export class TitleInsuranceComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected errorService: ErrorService,
              protected tabService: TabsService) {
  }

  ngOnInit(): void {
    this.errorService.provinceCode = this.matter.provinceCode;
    this.tabService.activeTab.section = this.route.routeConfig.path;
    if (this.matter) {
      this.matter.titleInsuranceBuildMortgagePolicies();
    }
  }

  get matter(): Matter {
    const matterTab = this.tabService.activeTab as MatterTab;
    return matterTab.matter;
  }

  get isStewartTitleInsurance(): boolean {
    //this will exclude case where user has selected Other Title insurer and manually selected Stewart
    return this.matter && this.matter.isInsurerStewartTitle() && this.matter.transactionTitleInsuredCode == 'Yes - Title Insurer';
  }

  get isChicagoTitleInsurance(): boolean {
    //this will exclude case where user has selected Other Title insurer and manually selected Chicago
    return this.matter && this.matter.isInsurerChicagoTitle() && this.matter.transactionTitleInsuredCode == 'Yes - Title Insurer';
  }

  get isFctInsurance(): boolean {
    return this.matter && this.matter.isInsurerFCT() && this.matter.transactionTitleInsuredCode == 'Yes - Title Insurer';
  }

  get isTitlePlusInsurance(): boolean {
    return this.matter && this.matter.isInsurerTitlePlus() && this.matter.transactionTitleInsuredCode == 'Yes - Title Insurer';
  }

  get isOtherTitleInsurance(): boolean {
    return this.matter && this.matter.isOtherTitleInsurer();
  }

  protected addDataValidationError(errorKey: string) {
    if (errorKey) {
      this.errorService.addDpMissingFieldError(DPError.createDPError(errorKey), true);
    }
  }

  protected addCustomDataValidationError(errorElementKey: string, errorMessage: string, errorTopic: string, errorType: ErrorType) {
    if (errorElementKey) {
      this.errorService.addDpMissingFieldError(DPError.createCustomDPError(errorElementKey, errorMessage, errorTopic, errorType), true);
    }
  }

  protected get mainClientTopic(): string {
    return this.matter.isPurchase ? 'Purchasers' : (this.matter.isMortgage ? 'Mortgagors' : 'Vendors');
  }

  protected get mainClientType(): string {
    // return this.matter.isPurchase ? "Purchaser" : "Mortgagor";
    return this.matter.isPurchase ? 'Purchaser' : (this.matter.isMortgage ? 'Mortgagor' : 'Vendor');
  }

  public get matterTitleInsurance(): MatterTitleInsurance {
    if (!this.matter.matterTitleInsurance) {
      this.matter.matterTitleInsurance = new MatterTitleInsurance();
    }
    return this.matter.matterTitleInsurance;
  }

  protected removeAllTitleInsuranceMissingDataErrors(): void {
    this.errorService.removeDpMissingFieldErrorsByPartialKey('integrations.stewartTitle.*');
    this.errorService.removeDpMissingFieldErrorsByPartialKey('integrations.chicagoTitle.*');
    this.errorService.removeDpMissingFieldErrorsByPartialKey('integrations.fct.*');
  }

  protected initializeThirdPartyNotification(): void {
    this.errorService.clearAllMissingFieldErrors();
    this.errorService.setMissingFieldType(DpFooterTypes.THIRDPARTY);
    if (this.isFctInsurance) {
      this.errorService.setMissingFieldHeader('FCT Title Insurance – Missing Data');
    } else if (this.isChicagoTitleInsurance) {
      this.errorService.setMissingFieldHeader('Chicago Title – Missing Data');
    } else if (this.isStewartTitleInsurance) {
      this.errorService.setMissingFieldHeader('Stewart Title – Missing Data');
    } else if (this.isTitlePlusInsurance) {
      this.errorService.setMissingFieldHeader('TitlePLUS – Missing Data');
    }
  }

  protected ordinalSuffix(ordinal: number): string {
    return (ordinal === 1 ? 'st' : ordinal === 2 ? 'nd' : ordinal === 3 ? 'rd' : 'th');
  }

  protected mortgageeOrLenderExistsForMortgage(mortgage: Mortgage): boolean {
    if (mortgage && this.matter.matterParticipants && this.matter.matterParticipants.length > 0) {
      return this.matter.matterParticipants.some(mp => mp.mortgageId === mortgage.id
        && mp.matterParticipantRole === (mortgage.isMortgageeAnInstitution() ? 'MORTGAGEE' : 'PRIVATE_LENDER'));
    }
    return false;
  }

  protected mortgageTopic(mortgage: Mortgage): string {
    if (mortgage.isExistingMortgage()) {
      return 'Existing Mortgage(s)';
    } else {
      return this.matter.isPurchase ? 'Mortgages' : (this.matter.isMortgage ? 'New Mortgage' : 'VTB Mortgage(s)');
    }
  }

  protected mortgageIndex(mortgage: Mortgage): number {
    if (mortgage.isExistingMortgage()) {
      return this.matter.getExistingMortgageIndexById(mortgage.id);
    } else {
      return this.matter.getMortgageIndexById(mortgage.id);
    }
  }
}
