import {Component, Inject} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ErrorService} from '../error-handling/error-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class CustomThirdPartyModalContext {
  title: string;
  thirdPartyUrl: string;
}

@Component({
  selector: 'dp-custom-third-party',
  templateUrl: 'custom-third-party.modal.component.html',
  providers: [ErrorService]
})
export class CustomThirdpartyModalComponentModal extends ModalComponent<CustomThirdPartyModalContext> {
  sanitizedThirdPartyUrl: SafeUrl;

  constructor(
    public dialog: MatDialogRef<CustomThirdpartyModalComponentModal>,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) context?: CustomThirdPartyModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.sanitizedThirdPartyUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.thirdPartyUrl);
  }

  close(): void {
    this.dialog.close();
  }
}
