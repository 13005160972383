import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {FctMortgageType} from '../../matters/shared/matter-specific-type';
import {MortgageInstructionFileStatusCode} from './stewart-mortgage-instruction';
import {TelusEmpMessages} from '../emp-messages';

const propertyIgnoreList = [ 'documents', 'lastUpdatedTimeStamp' ];

export const BORROWER_CODES: string[] = [ 'PR', 'PRC', 'CO1', 'CO2', 'CO3', 'CO4', 'CO5', 'CO6', 'CO7', 'CO8', 'CO9', 'BORROWER' ];
export const GUARANTOR_CODES: string[] = [ 'EN1', 'EN2', 'EN3', 'EN4', 'EN5', 'EN6', 'EN7', 'EN8', 'EN9', 'GUARANTOR' ];

export class StewartAssystMortgageInstructionData extends BaseEntity {
  dealId: string;
  provider: string;
  lenderName: string;
  solicitorName: any;
  lenderRefNo: string;
  mortgagePriority: number;
  mortgageProduct: string;
  principalAmount: number;
  closingDate: string;
  firstPaymentDate: string;
  lastPaymentDate: Date;
  lenderRemarks: string;
  solicitorRemarks: string;
  borrower: string;//primary borrower
  borrowers: Borrower[] = [];
  propertyAddress: Address;
  noteIndicator: boolean;
  fileStatuses: FileStatus[] = [];
  expiresIn: string;
  fiNumber: string;
  program: string;
  documents: StewartAssystDocument[] = [];
  lenderBranchName: string;
  contactName: string;
  contactPhone: string;
  mortgagee: string;

  mortgageeAddress: Address;
  mortgageeBranchName: string;
  mortgageeBranchContactName: string;
  mortgageeBranchEmail: string;
  mortgageeBranchPhone: string;
  mortgageeBranchFax: string;
  mortgageeBranchTransit: string;

  servicingBranchAddress: Address;
  servicingBranchName: string;
  servicingBranchContactName: string;
  servicingBranchEmail: string;
  servicingBranchPhone: string;
  servicingBranchFax: string;
  servicingBranchTransit: string;
  servicingBranchTransitOrg: string;

  originatingBranchAddress: Address;
  originatingBranchName: string;
  originatingBranchEmail: string;
  originatingBranchPhone: string;
  originatingBranchFax: string;
  originatingBranchTransit: string;
  originatingBranchTransitOrg: string;

  loanType: string;
  rateType: string;
  cashBack: string;
  appraisalFees: string;
  loanAmount: number;
  retainedByLender: string;
  mortgageInsurer: string;
  insurancePremium: string;
  taxOnInsurancePremium: string;
  insurancePremiumIncluded: string;
  insuranceApplicationFee: string;
  netAdvance: string;
  variableRate: string;
  interestRateForRegistration: string;
  interestRateForManitobaRegistration: string;
  registeredInstructionRateType: string;
  registeredInstructionVariableRate: string;
  flagInterestOnly: string;
  flagBlendedPayment: string;
  flagRegularRate: string;
  flagConvertible: string;

  baseRate: number;
  rateDifferential: number;
  minimumRate: number;
  maximumRate: number;
  reimbursementRate: number;
  endOfRateDate: Date;
  interestCalculated: string;
  loanTerm: number;
  loanAmortization: number;
  standardChargeTermNo: string;
  paymentFrequency: string;
  paymentsDue: string;
  interestAdjustmentDateAsRegistered: string;
  interestAdjustmentDateText: string;
  firstPaymentDateAsRegistered: string;
  maturityDateAsRegistered: string;
  principalAndInterestAsRegistered: number;
  taxesAsRegistered: number;
  otherAsRegistered: number;
  totalAsRegistered: number;
  paymentFrequencyAlternate: string;
  paymentsDueAlternate: string;
  principalAndInterestAlternate: number;
  taxesAlternate: number;
  otherAlternate: number;
  totalAlternate: number;
  legalDescription: string;
  purchasePrice: number;
  lastUpdatedTimeStamp: Date;
  fctStatusData: FctTransactionStatusResponse;
  dealStatus: string;//Fct deal status
  principalAmountText: string;
  firstPaymentDateText: string;
  lastPaymentDateText: string;
  transactionType: string;
  rffRequired: boolean;
  sourceFctUrn: string;
  interestRate: string;
  lenderFee: string;
  miscellaneousFee: string;
  holdBackFee: string;
  buyDownAtCommitmentFee: string;
  bridgeLoanApplicationFee: string;
  wireFee: string;
  rebate: string;
  fctFee: string;
  lineOfCredit: string;
  taxHoldBack: string;
  internalMortgagePayout: string;
  constructionMortgage: boolean;
  assignmentOfRents: boolean;
  bridgeLoanAmount: string;
  bridgeInterestRate: string;
  bridgeInterestAdjustmentDate: string;
  bridgeFirstPaymentDate: string;
  bridgeMaturityDate: string;
  bridgePropertyAddress: Address;
  firstPaymentDateAlternate: string;
  condoUnitNumber: string;
  collateralMortgage: boolean;
  mortgageType: FctMortgageType;

  //the following 7 attributes contains the Server side provided date string value with format yyyy/MM/dd
  //they are used for formatDate to avoid the impact of users' local PC TZ
  closingDateFormatted: string; //related field: closingDate
  firstPaymentDateFormatted: string; //related field: firstPaymentDate
  firstPaymentDateAsRegisteredFormatted: string; //related field: firstPaymentDateAsRegistered
  lastPaymentDateFormatted: string; //related field: lastPaymentDate
  endOfRateDateFormatted: string; //related field: endOfRateDate
  interestAdjustmentDateAsRegisteredFormatted: string; //related field: interestAdjustmentDateAsRegistered
  maturityDateAsRegisteredFormatted: string; //related field: maturityDateAsRegistered

  constructor(s?: StewartAssystMortgageInstructionData) {
    super(s);
    if (s) {
      if (s.borrowers) {
        for (let p of s.borrowers) {
          this.borrowers.push(new Borrower(p));
        }
      }
      if (s.propertyAddress) {
        this.propertyAddress = new Address(s.propertyAddress);
      }

      if (s.mortgageeAddress) {
        this.mortgageeAddress = new Address(s.mortgageeAddress);
      }

      if (s.servicingBranchAddress) {
        this.servicingBranchAddress = new Address(s.servicingBranchAddress);
      }

      if (s.originatingBranchAddress) {
        this.originatingBranchAddress = new Address(s.originatingBranchAddress);
      }

      if (s.bridgePropertyAddress) {
        this.bridgePropertyAddress = new Address(s.bridgePropertyAddress);
      }

      if (s.fileStatuses) {
        for (let p of s.fileStatuses) {
          this.fileStatuses.push(new FileStatus(p));
        }
      }

      if (s.documents) {
        for (let d of s.documents) {
          this.documents.push(new StewartAssystDocument(d));
        }
      }
      if (s.fctStatusData) {
        this.fctStatusData = new FctTransactionStatusResponse(s.fctStatusData);
      }
    }
  }

  shouldKeyBeChecked(key): boolean {
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }

  //for FCT, display value from sourceFctUrn, other provider display value from dealId field
  getDealIdForDisplay() {
    return this.isFctInstruction ? this.sourceFctUrn : this.dealId;
  }

  get borrowersExcludingGuarantors(): Borrower[] {
    return this.borrowers.filter(value => BORROWER_CODES.indexOf(value.borrowerTypeCode) > -1);
  }

  get guarantors(): Borrower[] {
    return this.borrowers.filter(value => GUARANTOR_CODES.indexOf(value.borrowerTypeCode) > -1);
  }

  get borrowerNames(): string {
    return this.borrowersExcludingGuarantors.map(value => value.borrowerName.fullName).join(', ');
  }

  borrowerAddressData(): any[] {
    let borrowerAddressData = [];
    this.borrowersExcludingGuarantors.forEach(item => {
      if (item.borrowerAddress && item.borrowerAddress.fullAddress.trim() != '') {
        borrowerAddressData.push({label: item.borrowerName.fullName + ': ' + item.borrowerAddress.fullAddress, value: item.borrowerID});
      }

    });

    return borrowerAddressData;
  }

  get guarantorNames(): string {
    return this.guarantors.map(value => value.borrowerName.fullName).join(', ');
  }

  isFiNumberTD(): boolean {
    return (this.fiNumber && (this.fiNumber == '004' || this.fiNumber == '4' || this.fiNumber == '-44'));
  }

  isFiNumberBNS(): boolean {
    return (this.fiNumber && (this.fiNumber == '002' || this.fiNumber == '2'));
  }

  isFiNumberSimpliiFinancial(): boolean {
    return (this.fiNumber && (this.fiNumber == '326'));
  }

  isFiNumberBMO(): boolean {
    return (this.fiNumber && (this.fiNumber == '001' || this.fiNumber == '1'));
  }

  isFiNumberCMHC(): boolean {
    return (this.fiNumber == '-8');
  }

  isFiNumberRBC(): boolean {
    return (this.fiNumber && (this.fiNumber == '003' || this.fiNumber == '3'));
  }

  isRBCNotLineOfCredit(): boolean {
    return (this.fiNumber && (this.fiNumber == '003' || this.fiNumber == '3') && (this.program != '230'));
  }

  isRBCBuilderMortgages(): boolean {
    return (this.fiNumber && (this.fiNumber == '003' || this.fiNumber == '3') && (this.program == '204' || this.program == '205'));
  }

  isProvideStewartAssyst(): boolean {
    return (this.provider && this.provider.toLowerCase() == 'assyst real estate');
  }

  get isFctInstruction(): boolean {
    return this.provider == 'FCT-LLC' || this.provider == 'FCT-MMS';
  }

  get isFctMMSInstruction(): boolean {
    return this.provider == 'FCT-MMS';
  }

  get isFctLLCInstruction(): boolean {
    return this.provider == 'FCT-LLC';
  }

  // TELUS cancellation message
  getTelusCancellationMessage(): string {
    if (this.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_CANCELLED_BY_FI)) {
      return TelusEmpMessages.EMP999;
    } else if (this.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_CANCELLED_BY_TELUS)) {
      return TelusEmpMessages.EMP990;
    } else if (this.fileStatuses.find(value => value.status == MortgageInstructionFileStatusCode.FILE_CLOSED_BY_LENDER)) {
      return TelusEmpMessages.EMP109;
    }
    return TelusEmpMessages.EMPDEFAULTCANCEL;
  }
}

export class Borrower extends BaseEntity {
  borrowerID: string;
  borrowerName: BorrowerName;
  borrowerTypeCode: string;
  borrowerAddress: Address;
  borrowerCellPhone: string;
  borrowerHomePhone: string;
  borrowerWorkPhone: string;
  representatives: string[] = [];

  constructor(s?: Borrower) {
    super(s);
    if (s) {
      if (s.borrowerName) {
        this.borrowerName = new BorrowerName(s.borrowerName);
      }
      if (s.borrowerAddress) {
        this.borrowerAddress = new Address(s.borrowerAddress);
      }
      this.representatives = [];
      if (Array.isArray(s.representatives)) {
        for (let j: number = 0; j < s.representatives.length; j++) {
          this.representatives[ j ] = s.representatives[ j ];
        }
      }
    }
  }

  isIndividual(): boolean {
    if (this.borrowerTypeCode === 'BORROWER' || this.borrowerTypeCode === 'GUARANTOR') {
      return (this.borrowerName && this.borrowerName.corporateName === null);
    } else {
      return (this.borrowerTypeCode != 'PRC');
    }
  }

  isGuranator(): boolean {
    return (GUARANTOR_CODES.indexOf(this.borrowerTypeCode) > -1);
  }

  isBorrower(): boolean {
    return (BORROWER_CODES.indexOf(this.borrowerTypeCode) > -1);
  }

  isReconciled(borrower: Borrower): boolean {
    return ((this.borrowerID == borrower.borrowerID)
      && ((borrower.isIndividual() && this.isIndividual()) || (!borrower.isIndividual() && !this.isIndividual()))
      && (borrower.borrowerTypeCode == this.borrowerTypeCode)
      && ((this.isIndividual() && this.borrowerName.firstName == borrower.borrowerName.firstName
          && this.borrowerName.lastName
          == borrower.borrowerName.lastName)
        || (!this.isIndividual() && this.borrowerName.corporateName == borrower.borrowerName.corporateName))
    );
  }

}

export class BorrowerName extends BaseEntity {
  //In case of FCT u can get both populated corporateName & firstName, lastName
  corporateName: string;
  firstName: string;
  lastName: string;
  middleName: string;

  get fullName(): string {
    if (this.corporateName) {
      return this.corporateName;
    } else {
      return this.personFullName;
    }
  }

  get personFullName(): string {
    return ((this.firstName ? this.firstName : '') + (this.middleName ? ' ' + this.middleName : '') + (this.lastName ? ' ' + this.lastName : '')).trim();
  }
}

export class Address extends BaseEntity {
  addressLine1: string;
  city: string;
  province: string;
  postalCode: string;
  provinceOther: string;
  apartment: string;
  civicNumber: string;
  country: string;
  countryOther: string;
  poBox: string;

  get fullAddress(): string {
    let fullAddress = this.addressLine1 ? this.addressLine1 : '';

    fullAddress += this.apartment && this.addressLine1 ? ' ' : '';
    fullAddress += this.apartment ? this.apartment : '';

    fullAddress += this.city && this.addressLine1 ? ', ' : '';
    fullAddress += this.city ? this.city : '';

    fullAddress += this.province && (this.addressLine1 || this.city) ? ', ' : '';
    fullAddress += this.province ? this.province : '';

    fullAddress += this.postalCode && (this.addressLine1 || this.city) || this.province ? ' ' : '';
    fullAddress += this.postalCode ? this.postalCode : '';

    return fullAddress;

  }
}

export class FileStatus extends BaseEntity {
  status: string;
  dateExecuted: string;
  dateExecutedDelay: string;
}

export type StewartAssystDocumentStatus = 'NEW' | 'DOWNLOADED' | 'UPDATED';

export class StewartAssystDocument extends BaseEntity {
  documentName: string;
  docType: string;
  documentNumber: number;
  documentLanguage: string;
  documentReadOnly: number;
  versionName: string;
  dateGenerated: number;
  isSigned: number;
  documentVersionNumber: number;
  documentStatus: StewartAssystDocumentStatus;
}

export class FctTransactionStatusResponse extends BaseEntity {
  status: string;
  lenderChanges: boolean;
  lenderNotes: boolean;
  milestones: Milestone[] = [];

  constructor(f?: FctTransactionStatusResponse) {
    super(f);
    if (f) {
      if (f.milestones) {
        for (let m of f.milestones) {
          this.milestones.push(new Milestone(m));
        }
      }
    }
  }

  isCancellationRequested(): boolean {
    return this.status == 'CANCELLATION REQUESTED';
  }

  isCancelled(): boolean {
    return this.status == 'CANCELLED';
  }

  isCompleted(): boolean {
    return this.status == 'COMPLETED';
  }

  isActive(): boolean {
    return this.status == 'ACTIVE';
  }
}

export class Milestone extends BaseEntity {
  id: string;
  name: string;
  description: string;
  completedDateTime: string;
  sequence: string;
  enabled: boolean;
}
