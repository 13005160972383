import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustment} from '../statement-adjustment';
import {RESERVE_FOR_VTB_MTG_TEXT} from '../statement-adjustment-constants';

export class StatementAdjustmentDisplayReserveVTBMtgBuilder {

  static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    if (item) {
      sadItemDetails.push(mainBuilder.getNewItemLine(RESERVE_FOR_VTB_MTG_TEXT, ``));
    }
    return sadItemDetails;
  }
}
