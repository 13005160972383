import {Component, Inject} from '@angular/core';
import {SoaExportConfig} from '../../shared/soa-export-config';
import {Account} from '../../../../app/admin/accounts/shared/account';
import {AppConfig} from '../../../shared-main/app-configuration';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class ExportToAccountingModalContext {
  soaExportConfigs: SoaExportConfig[];
  account: Account;
  isAccountingSoftwareEsiLaw: boolean;
}

@Component({
  selector: 'export-to-accounting-modal-content',
  templateUrl: './export-to-accounting-modal.component.html'
})
export class ExportToAccountingModalComponent extends ModalComponent<ExportToAccountingModalContext> {
  soaConfigs: SoaExportConfig[];

  constructor(
    public dialog: MatDialogRef<ExportToAccountingModalComponent>,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: ExportToAccountingModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.soaConfigs = this.context.soaExportConfigs;
  }

  get successfullyExportedMatters(): number {
    return this.soaConfigs.filter((item) => item.sendToExport === true).length;
  }

  get soaExportFailedForMatters(): string[] {
    let notExportedMatters: string[] = [];
    let soaConfig: SoaExportConfig[] = this.soaConfigs.filter((item) => item.sendToExport === false);
    soaConfig.forEach((item) => notExportedMatters.push(item.uiDisplayField));
    return notExportedMatters;
  }

  get soaExportFailedCountForMatters(): number {
    return this.soaConfigs.length - this.successfullyExportedMatters;
  }

  cancel() {
    this.dialog.close();
  }

  get successMessage(): string {
    let msg: string;
    if (this.successfullyExportedMatters == 0) {
      msg = this.context.isAccountingSoftwareEsiLaw
        ? 'Accounting data successfully prepared for 0 matters.'
        : `Export files successfully created for 0 matters.`;
    } else if (this.successfullyExportedMatters > 0 && this.soaExportFailedCountForMatters == 0) {
      msg = this.context.isAccountingSoftwareEsiLaw
        ? `Fee and Disbursement entries for the selected matter(s) have been prepared for syncing. To sync the entries, click the Practice Management Sync option on the ` +
          (this.appConfig.isEsiLawIntegrationEnabled ? 'Unity® Accounting' : 'esiLaw') +
          ` toolbar.`
        : `Export files successfully created for the selected matter(s). To download the accounting export file(s), select the “Download Accounting Export Files” option located in the “Integrations” menu`;
    } else {
      msg = this.context.isAccountingSoftwareEsiLaw
        ? 'Accounting data successfully prepared for ' + this.successfullyExportedMatters + ' matters.'
        : `Export files successfully created for the selected matter(s). To download the accounting export file(s), select the “Download Accounting Export Files” option located in the “Integrations” menu`;
    }
    return msg;
  }

  get failureMessage(): string {
    return (
      '' +
      this.soaExportFailedCountForMatters +
      ' matter(s) were not ' +
      (this.context.isAccountingSoftwareEsiLaw ? 'successful' : 'exported')
    );
  }
}
