import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {StaffProfiles} from './staff-profiles';
import {Observable} from 'rxjs';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {userAccountProfilesApi} from '../shared/user-account-profiles-api';
import {Contact, Utils} from '../../matters/shared';
import {Account} from '../accounts/shared/account';
import {dropDowns} from '../shared/admin-drop-down';
import {AddressDropdowns} from '../../shared-main/address-Form/drop-downs';
import {SameAsAddressOption} from '../../matters/shared/address';
import SharedMainUtils from '../../shared-main/utils';

@Injectable()
export class StaffProfilesService {

  private cachedLoggedInUser: StaffProfiles;
  staffProfileContactsCache: Map<string, Contact[]> = new Map<string, Contact[]>();
  utils;

  constructor(private httpClient: HttpClient) {
    this.utils = new Utils();
  }

  saveStaffProfiles(staffProfiles: StaffProfiles, accountId?: string): Observable<StaffProfiles> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url = userAccountProfilesApi.staffProfiles.replace('{accountId}', accountId);
    const spData = {
      user: staffProfiles.user,
      accessProfile: staffProfiles.accessProfile,
      contact: staffProfiles.contact,
      caslInfo: staffProfiles.caslInfo
    };

    if (staffProfiles.id) {
      url = `${ userAccountProfilesApi.staffProfiles.replace('{accountId}', accountId) }/${ staffProfiles.id }`;
      return this.httpClient.put(url, JSON.stringify(spData))
      .map((res) => {
        return new StaffProfiles(res[ 'User' ]);
      });
    } else {
      return this.httpClient.post(url, JSON.stringify(spData))
      .map((res) => {
        return new StaffProfiles(res[ 'User' ]);
      });
    }
  }

  saveMyProfile(staffProfiles: StaffProfiles): Observable<StaffProfiles> {

    let userId = SharedMainUtils.getAuthenticatedUserId();
    let url = userAccountProfilesApi.loggedInStaffProfiles;
    const spData = {
      user: staffProfiles.user,
      accessProfile: staffProfiles.accessProfile,
      contact: staffProfiles.contact,
      publicAccountId: staffProfiles.user.publicAccountId,
      caslInfo: staffProfiles.caslInfo
    };

    return this.httpClient.put(url, JSON.stringify(spData))
    .map((res) => {
      return new StaffProfiles(res[ 'User' ]);
    });

  }

  getStaffProfiles(accountId?: string, sortQuery?: string, sortingType?: string, accessRightProfileType?: string, searchText?: string): Observable<StaffProfiles[]> {

    let sort: string;
    let accessRightProfileTypeValue = Utils.escapeSearchText(accessRightProfileType);
    let searchTextValue = Utils.escapeSearchText(searchText);
    if (sortQuery && sortingType) {
      sort = Utils.parseSort(sortQuery, sortingType);
    }
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url: string = userAccountProfilesApi.staffProfiles.replace('{accountId}', accountId);
    let filter: string = accessRightProfileTypeValue && accessRightProfileTypeValue.length > 0 ? 'accessProfileType_EQ_' + accessRightProfileTypeValue : '';

    if (searchTextValue && searchTextValue.length > 0) {
      filter = filter ? filter + ',ANYuserName_EQ_*' + searchTextValue + '*' + '|userName_EQ_*' + searchTextValue + '*' : 'ANYuserName_EQ_*' + searchTextValue + '*' + '|userId_EQ_*' + searchTextValue + '*';
    }

    sort = sort && sort.length > 0 ? `&sort=${ sort }` : '';
    filter = filter && filter.length > 0 ? `&filter=${ filter }&filterType=ALL` : '';

    url = `${ url }?${ sort }${ filter }`;
    return this.httpClient.get(url)
    .map((res) => {
      let staffProfilesList: StaffProfiles[] = [];
      if (Array.isArray(res[ 'StaffProfiles' ])) {
        staffProfilesList = res[ 'StaffProfiles' ].map(item => {
          return new StaffProfiles(item);
        });
      }
      return staffProfilesList;
    });
  }

  getSingleStaffProfile(staffProfileId: number, accountId?: string): Observable<StaffProfiles> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url = `${ userAccountProfilesApi.staffProfiles.replace('{accountId}', accountId) }/${ staffProfileId }`;

    return this.httpClient.get(url)
    .map((res) => {
      return new StaffProfiles(res[ 'StaffProfile' ]);
    });
  }

  getLoggedInStaffProfile(): Observable<StaffProfiles> {

    let userId = SharedMainUtils.getAuthenticatedUserId();
    return this.httpClient.get(userAccountProfilesApi.loggedInStaffProfiles)
    .map((res) => {
      this.cachedLoggedInUser = new StaffProfiles(res[ 'StaffProfile' ]);
      return this.cachedLoggedInUser;
    });
  }

  getCachedLoggedInStaffProfile(): Observable<StaffProfiles> {

    let userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userLogin);
    //Second condition is to cover the case if logged in user different then cached user (it can happen if another user login without closing browser)
    if (!this.cachedLoggedInUser || this.cachedLoggedInUser.userId != userId) {
      return this.httpClient.get(userAccountProfilesApi.loggedInStaffProfiles)
      .map((res) => {
        this.cachedLoggedInUser = new StaffProfiles(res[ 'StaffProfile' ]);
        return this.cachedLoggedInUser;
      });
    } else {
      return Observable.of(this.cachedLoggedInUser);
    }

  }

  // solicitorAddressDd : string[] = [AddressDropdowns.sameAsFirm, AddressDropdowns.manuallyEntered];
  createStaffProfileAddressCopyOptions(account: Account): SameAsAddressOption[] {
    return dropDowns.staffProfileAddressCopyOptions.map(item => {
      return {
        description: item,
        srcAddress: item === AddressDropdowns.sameAsAccount ? account && account.mailingAddress : null
      };
    });
  }

  cleanCachedLoggedInStaffProfile() {
    this.cachedLoggedInUser = null;
  }

  getStaffProfileContact(accountId: string, refreshCache: boolean = false): Observable<Contact[]> {
    if (refreshCache) {
      this.staffProfileContactsCache.delete(accountId);
    }
    if (!this.staffProfileContactsCache.has(accountId)) {
      const url = userAccountProfilesApi.getAllStaffProfileContacts.replace('{accountId}', accountId);
      return this.httpClient.get(url)
      .map((data) => {
        if (Array.isArray(data[ 'Contacts' ])) {
          this.staffProfileContactsCache.set(accountId, data[ 'Contacts' ].map(item => new Contact(item)));
          return this.staffProfileContactsCache.get(accountId);
        }
      });
    }
    return Observable.of(this.staffProfileContactsCache.get(accountId));
  }
}
