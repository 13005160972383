import {MatterTab} from '../../matters/matter-tab';
import {MassUpdateType, MassUpdateTypes} from '../../projects/shared/project';
import {Matter} from '../../matters/shared/matter';
import {BaseEntity} from '../BaseEntity/base-entity';
import {TrustAccount} from '../../admin/trust-account/trust-account';
import {ProgressionStatusType} from '../../matters/statement-adjustment/statement-adjustment';
import {ProjectMatterUpdateTransaction} from '../../matters/shared/project-matter-update-transaction';

export const MaxRangeOfExistingMortgage = 8;
export const MinRangeOfExistingMortgage = 1;

export class MassUpdateData extends BaseEntity {
  rangeOfMortgage: number;
  selectedTrustAccount: TrustAccount;
  data: any; //generic placeholder for value used for different types of massUpdate
  fieldsToRemove: string[] = []; //Holds the names of the fields need to be removed
  directionReFundDirty: ProgressionStatusType[] = []; // used to determine which of the Direction re Funds sheet has been changed on template and needs to be updated on mass update process

  constructor(massUpdateData?: MassUpdateData) {
    super(massUpdateData);
  }

  isFieldMarkedForRemoval(name: string): boolean {
    return !!this.fieldsToRemove.find(item => item == name);
  }

  //Add or remove the field to the FieldsToRemove array
  toggleRemovalPropagation(name: string) {
    if (this.isFieldMarkedForRemoval(name)) {
      (<any>this.fieldsToRemove).remove(name);
    } else {
      this.fieldsToRemove.push(name);
    }
  }

  directionReFundSheetModified(fromProgression: ProgressionStatusType): void {
    if (!this.directionReFundDirty.find(progression => progression === fromProgression)) {
      this.directionReFundDirty.push(fromProgression);
    }
  }

}

export class MassUpdateTab extends MatterTab {
  massUpdateType: MassUpdateType;
  massUpdateStage: MassUpdateStageType;
  massUpdateData: MassUpdateData;
  //This gets populated when a new mass update is started. it is used for resuming mass update in case of restart
  massUpdateTransaction: ProjectMatterUpdateTransaction;

  static createMassUpdateTab(massUpdateType: MassUpdateType, templateMatter: Matter, massUpdateData?: MassUpdateData): MassUpdateTab {
    let tab: MassUpdateTab = new MassUpdateTab();
    tab.tabType = 'matter';
    tab.tabSubType = 'massUpdate';
    tab.massUpdateStage = 'MATTER_LEVEL';
    tab.matter = templateMatter;
    tab.massUpdateType = massUpdateType;
    tab.type = templateMatter.matterType;
    tab.route = 'main/tabs/matter';

    if (massUpdateType == MassUpdateTypes.ADD_NEW_SUPPLEMENTAL_TASKS) {
      tab.section = 'supplementalTasks';
    }

    if ((massUpdateType == MassUpdateTypes.NUMBER_OF_EXISTING_MORTGAGES ||
        massUpdateType == MassUpdateTypes.NUMBER_OF_VTB_MORTGAGES ||
        massUpdateType == MassUpdateTypes.UPDATE_FORM4_BANK_ACCOUNT_INFO ||
        massUpdateType == MassUpdateTypes.FINAL_ADJUSTMENT_DATE ||
        massUpdateType == MassUpdateTypes.INTERIM_ADJUSTMENT_DATE ||
        massUpdateType == MassUpdateTypes.UNIT_TYPES ||
        massUpdateType == MassUpdateTypes.GST_HST_APPLICATION
        || massUpdateType == MassUpdateTypes.IMPORT_MATTER_ADJ_INF
        || massUpdateType == MassUpdateTypes.TAX_RATE_OF_SOA_SOAJ)
      && massUpdateData) {
      tab.massUpdateData = new MassUpdateData(massUpdateData);
    }

    return tab;
  }

  clearMassUpdateTransaction(): void {
    this.massUpdateTransaction = null;
  }

  isMassUpdateTabMatterLevel(): boolean {
    return this.massUpdateStage == 'MATTER_LEVEL';
  }

  isMassUpdateTabSelectionLevel(): boolean {
    return this.massUpdateStage == 'SELECTION_LEVEL';
  }

  isMassUpdateTabUpdateLevel(): boolean {
    return this.massUpdateStage == 'CONFIRM_MASS_UPDATE';
  }

  isMassUpdateTabCompletedLevel(): boolean {
    return this.massUpdateStage == 'COMPLETED_MASS_UPDATE';
  }

  isMassUpdateTabNumberOfExistingMortgagesType(): boolean {
    return this.massUpdateType == 'NUMBER_OF_EXISTING_MORTGAGES';
  }

  isMassUpdateSupportBackToTemplateMatter(): boolean {
    return this.massUpdateType != MassUpdateTypes.NUMBER_OF_EXISTING_MORTGAGES &&
      this.massUpdateType != MassUpdateTypes.NUMBER_OF_VTB_MORTGAGES &&
      this.massUpdateType != MassUpdateTypes.UPDATE_FORM4_BANK_ACCOUNT_INFO &&
      this.massUpdateType != MassUpdateTypes.FINAL_ADJUSTMENT_DATE &&
      this.massUpdateType != MassUpdateTypes.INTERIM_ADJUSTMENT_DATE &&
      this.massUpdateType != MassUpdateTypes.TAX_RATE_OF_SOA_SOAJ &&
      this.massUpdateType != MassUpdateTypes.UNIT_TYPES &&
      this.massUpdateType != MassUpdateTypes.IMPORT_MATTER_ADJ_INF;
  }

  isMassUpdateTabPrecedentLevel(): boolean {
    return this.massUpdateStage == 'PRECEDENT_LEVEL';
  }

  isMassUpdateTabTypeSoaGlCodes(): boolean {
    return this.massUpdateType == MassUpdateTypes.SOA_GL_CODES
      || this.massUpdateType == MassUpdateTypes.SOA_GL_CODES_INTERIM
      || this.massUpdateType == MassUpdateTypes.SOA_GL_CODES_FINAL;
  }

  isMassUpdateTabTypeStatementOfAccount(): boolean {
    return this.massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT
      || this.massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_INTERIM
      || this.massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_FINAL;
  }

  isMassUpdateTabTypeInterimStatementOfAccount(): boolean {
    return this.massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_INTERIM;
  }

  isMassUpdateTabTypeFinalStatementOfAccount(): boolean {
    return this.massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_FINAL;
  }

  isMassUpdateTabTypeTrustLedger(): boolean {
    return this.massUpdateType == MassUpdateTypes.TRUST_LEDGER
      || this.massUpdateType == MassUpdateTypes.TRUST_LEDGER_INTERIM
      || this.massUpdateType == MassUpdateTypes.TRUST_LEDGER_FINAL;
  }

  isMassUpdateTabTypeInterimTrustLedger(): boolean {
    return this.massUpdateType == MassUpdateTypes.TRUST_LEDGER_INTERIM;
  }

  isMassUpdateTabTypeFinalTrustLedger(): boolean {
    return this.massUpdateType == MassUpdateTypes.TRUST_LEDGER_FINAL;
  }

  isMassUpdateTabTypeImportMatterAdjustment(): boolean {
    return this.massUpdateType == MassUpdateTypes.IMPORT_MATTER_ADJ_INF;
  }
}

export type MassUpdateStageType = 'MATTER_LEVEL' | 'SELECTION_LEVEL' | 'CONFIRM_MASS_UPDATE' | 'COMPLETED_MASS_UPDATE' | 'PRECEDENT_LEVEL';
