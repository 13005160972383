import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';
import {Address} from '../../matters/shared/address';

export class TeranetCourierInstrumentRequest extends BaseEntity {
  teranetUser: TeranetUser;
  attention: string;
  firmName: string;
  address: Address;
  deliveryInstructions: string;
  instrumentIds: number[]; //Instruments for courier request
}
