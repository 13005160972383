<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Mass Open</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div class="form-group">
            <div class="col-lg-12 main-level margin-bottom-0"><span class="title">{{getMainTitle()}}</span></div>
        </div>
        <ng-container *ngIf="targetMatter.isMatterProvinceON">
            <div class="form-group" *ngIf="targetMatter.matterPropertyWithCondo.isCondominium  != 'YES'">
                <div class="col-lg-2">
                    <div class="col-lg-8"></div>
                    <div class="col-lg-1"><span class="control-label">Auto-Increment</span></div>
                </div>
            </div>
            <div class="form-group" *ngIf="targetMatter.matterPropertyWithCondo.isCondominium  != 'YES'">
                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="autoIncrementPartLot">"Part (Lot)"</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementPartLot ?  '' : 'Check to auto-increment \u0022Part (Lot)\u0022' }}">
                            <dp-checkbox fieldId="autoIncrementPartLot" [hasTabIndex]="true">
                                <input type="checkbox" id="autoIncrementPartLot"
                                       [(ngModel)]="autoIncrementPartLot"
                                       (change)="autoIncrementUpdate('PartLot')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <input type="text" trimmed id="partLot" name="partLot" class="form-control" maxlength="100"
                               [(ngModel)]="targetMatter.matterPropertyWithCondo.partLot"
                               (change)="updateTeraviewLegalDescs()"
                               [fieldKey]="'property.partLot'"
                               statusBarHelp
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="plan">"Plan"</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementPlan ? '' : 'Check to auto-increment \u0022Plan\u0022'}}">
                            <dp-checkbox fieldId="autoIncrementPlan" [hasTabIndex]="true">
                                <input type="checkbox" id="autoIncrementPlan"
                                       [(ngModel)]="autoIncrementPlan"
                                       (change)="autoIncrementUpdate('Plan')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <input type="text" trimmed id="plan" name="plan" class="form-control" maxlength="100"
                               statusBarHelp
                               [(ngModel)]="targetMatter.matterPropertyWithCondo.plan"
                               (change)="updateTeraviewLegalDescs()"

                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="beingPart">"Being Part"</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementBeingPart ? '' : 'Check to auto-increment \u0022Being Part\u0022'}}">
                            <dp-checkbox fieldId="autoIncrementBeingPart" [hasTabIndex]="true">
                                <input type="checkbox" id="autoIncrementBeingPart"
                                       [(ngModel)]="autoIncrementBeingPart"
                                       (change)="autoIncrementUpdate('BeingPart')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <input type="text" trimmed id="beingPart" name="beingPart" class="form-control" maxlength="80"
                               [fieldKey]="'property.beingPart'"
                               (change)="updateTeraviewLegalDescs()"
                               statusBarHelp
                               [(ngModel)]="targetMatter.matterPropertyWithCondo.beingPart"/>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="onPlan">"On Plan"</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementOnPlan ? '' : 'Check to auto-increment \u0022On Plan\u0022'}}">
                            <dp-checkbox fieldId="autoIncrementOnPlan" [hasTabIndex]="true">
                                <input type="checkbox" id="autoIncrementOnPlan"
                                       [(ngModel)]="autoIncrementOnPlan"
                                       (change)="autoIncrementUpdate('OnPlan')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <input type="text" trimmed id="onPlan" name="onPlan" class="form-control" maxlength="80"
                               (change)="updateTeraviewLegalDescs()"
                               statusBarHelp
                               [(ngModel)]="targetMatter.matterPropertyWithCondo.onPlan"/>
                    </div>
                </div>

                <div class="form-group" *ngIf="targetMatter.matterPropertyWithCondo.isCondominium  != 'YES'">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10">PIN 1</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementPin[0] ? '' : 'Check to auto-increment PIN 1'}}">
                            <dp-checkbox fieldId="autoIncrementPin_0" [hasTabIndex]="true">
                                <input type="checkbox" id="autoIncrementPin_0"
                                       [(ngModel)]="autoIncrementPin[0]"
                                       (change)="autoIncrementUpdate('PIN1',0)"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" dp-pinFormat id="pinnumber_{{targetMatter.matterPropertyWithCondo.identifier}}" #pinnumber="ngModel"
                               name="pinnumber_{{targetMatter.matterPropertyWithCondo.identifier}}"
                               [(ngModel)]="targetMatter.matterPropertyWithCondo.pin" pattern="^[0-9]{9}$"
                               [dp-error-validator]="{elementFunction : checkPinFormat, data: {identifier: targetMatter.matterPropertyWithCondo.identifier}}"
                               class="pin57 form-control" [fieldKey]="'targetMatter.propertyteranet.pin1'" placeholder="     -    "/>
                        <i [ngClass]="{'hide': pinnumber.invalid }"></i>
                    </div>
                    <div class="col-lg-4 padding-top-5" *dpShowByProvince="'matter.subjectProperty.overrideLegalDesc'">
                        <dp-checkbox fieldId="overrideDescription_{{targetMatter.matterPropertyWithCondo.identifier}}" class="margin-left-40">
                            <input type="checkbox"
                                   id="overrideDescription_{{targetMatter.matterPropertyWithCondo.identifier}}"
                                   name="overrideDescription_{{targetMatter.matterPropertyWithCondo.identifier}}"
                                   (change)="overrideDescChange(targetMatter.matterPropertyWithCondo)"
                                   [(ngModel)]="targetMatter.matterPropertyWithCondo.overrideDescription">
                        </dp-checkbox>
                        <label class="control-label padding-left-10 display-inline" for="overrideDescription_{{targetMatter.matterPropertyWithCondo.identifier}}"  >Override Teraview legal description</label>
                    </div>
                    <div class="col-lg-4" *ngIf="targetMatter.matterPropertyWithCondo.overrideDescription">
                        <select
                            class="form-control"
                            id="descriptionOverriddenType_0"
                            name="descriptionOverriddenType_0"
                            [(ngModel)]="targetMatter.matterPropertyWithCondo.descriptionOverriddenType"
                            (change)="updateTeraviewLegalDescs()"
                        >
                            <option *ngFor="let teraviewLegalDescOverriddenType of teraviewLegalDescOverriddenTypes"
                                    [value]="teraviewLegalDescOverriddenType.value">
                                {{teraviewLegalDescOverriddenType.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group" *ngIf="targetMatter.matterPropertyWithCondo.isCondominium  != 'YES' && targetMatter.matterPropertyWithCondo.overrideDescription">
                    <div class="col-lg-10 offset-lg-2" >
                                <textarea rows="5"
                                          class="form-control"
                                          id="teraviewLegalDesc"
                                          name="teraviewLegalDesc"
                                          [dp-read-only]="targetMatter.matterPropertyWithCondo.isLegalDescriptionApplyLegalDescEasement()"
                                          [(ngModel)]="targetMatter.matterPropertyWithCondo.fullLegalDescription" >
                                </textarea>
                    </div>
                </div>
                <div id="matterpropertyteranetpin_container" attr-offset="1">
                    <div *ngFor="let matterProperty of targetMatter.additionalMatterProperty; let j=index">
                        <div class="form-group" *ngIf="matterProperty.isCondominium  != 'YES'" [ngClass]="{'has-error' : !matterProperty.isPinValid}">
                            <div class="col-lg-2">
                                <label class="control-label col-lg-10">PIN {{j + 2}}</label>
                                <div class="col-lg-1 padding-top-7" title="{{autoIncrementPin[j+1] ? '' : 'Check to auto-increment PIN ' + (j + 2)}}">
                                    <dp-checkbox fieldId="autoIncrementPin_{{j+2}}" [hasTabIndex]="true">
                                        <input type="checkbox" id="autoIncrementPin_{{j+2}}"
                                               [(ngModel)]="autoIncrementPin[j+1]"
                                               (change)="autoIncrementUpdate('PIN',j)"/>
                                    </dp-checkbox>
                                </div>
                            </div>
                            <div [ngClass]="targetMatter.isProjectSale ? 'col-lg-2' : 'col-lg-3'" >
                                <input type="text" dp-pinFormat id="pinnumber_{{matterProperty.identifier}}"
                                       name="pinnumber_{{matterProperty.identifier}}"
                                       [(ngModel)]="matterProperty.pin" [fieldKey]="'targetMatter.propertyteranet.pin'"
                                       [dp-error-validator]="{elementFunction : checkPinFormat, data: {identifier: matterProperty.identifier}}"
                                       class="pin57 form-control" placeholder="     -    "/>
                                <i [ngClass]="{'hide': !matterProperty.isPinValid }"></i>
                            </div>
                            <div class="col-lg-4 padding-top-5" *dpShowByProvince="'matter.subjectProperty.overrideLegalDesc'">
                                <dp-checkbox fieldId="overrideDescription_{{j + 1 + currentTimeStamp}}" class="margin-left-40">
                                    <input type="checkbox"
                                           id="overrideDescription_{{j + 1 + currentTimeStamp}}"
                                           name="overrideDescription_{{j + 1 + currentTimeStamp}}"
                                           (change)="overrideDescChange(matterProperty)"
                                           [(ngModel)]="matterProperty.overrideDescription">
                                </dp-checkbox>
                                <label class="control-label padding-left-10 display-inline" for="overrideDescription_{{j + 1 + currentTimeStamp}}"  >Override Teraview legal description</label>
                            </div>
                            <div class="col-lg-4" *ngIf="matterProperty.overrideDescription">
                                <select
                                    class="form-control"
                                    id="descriptionOverriddenType_{{j + 1 + currentTimeStamp}}"
                                    name="descriptionOverriddenType_{{j + 1 + currentTimeStamp}}"
                                    [(ngModel)]="matterProperty.descriptionOverriddenType"
                                    (change)="updateTeraviewLegalDescs()"
                                >
                                    <option *ngFor="let teraviewLegalDescOverriddenType of teraviewLegalDescOverriddenTypes"
                                            [value]="teraviewLegalDescOverriddenType.value">
                                        {{teraviewLegalDescOverriddenType.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="matterProperty.overrideDescription" >
                            <div class="col-lg-10 offset-lg-2">
                                    <textarea rows="5"
                                              class="form-control"
                                              id="teraviewLegalDesc_{{j + 1 + currentTimeStamp}}"
                                              name="teraviewLegalDesc_{{j + 1 + currentTimeStamp}}"
                                              [dp-read-only]="matterProperty.isLegalDescriptionApplyLegalDescEasement()"
                                              [(ngModel)]="matterProperty.fullLegalDescription" >
                                    </textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group padding-bottom-10" *ngIf="targetMatter.matterPropertyWithCondo.isCondominium  != 'YES' &&  targetMatter.matterProperties.length < 99 ">
                    <label class="control-label col-lg-2"></label>
                    <div class="col-lg-2">
                                 <span class="actionlink">
                                <span>
                                    <a href="javascript:void(0);" (click)="addNewPIN()">
                                        Add Another PIN
                                    </a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                            </span>
                    </div>
                </div>
            </div>
        </ng-container>
            <div class="form-group" *ngIf="targetMatter.matterPropertyWithCondo.isPropertyCondominium()">
                <div class="form-group row">
                <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                     *ngIf="targetMatter.isMatterProvinceAB">
                    <label class="control-label col-lg-2 text-right">LINC Number</label>
                </div>
                    <div class="col-lg-1">
                        <label class="control-label text-left width-30 margin-left-minus-5">Auto Inc</label>
                        <label class="control-label text-left unit-number-label margin-left-5">Unit Number</label>

                    </div>
                <div class="col-lg-1"
                     *ngIf="targetMatter.isMatterProvinceON">
                        <label class="control-label text-left width-30 margin-left-minus-5">Auto Inc</label>
                        <label class="control-label text-left unit-number-label margin-left-5">Level Number</label>
                    </div>
                    <div class="col-lg-2" *ngIf="unitLevelPlan.condominiumPlans && unitLevelPlan.condominiumPlans.length > 1">
                        <label class="control-label  text-right">Plan Number</label>

                    </div>
                <div class="col-lg-1"
                     *ngIf="targetMatter.isMatterProvinceAB">
                    <label class="control-label col-lg-2 text-right">Number of Undivided Shares</label>
                </div>
                <div [ngClass]="getPinNumberColumnCssClass()"
                     *ngIf="targetMatter.isMatterProvinceON">
                        <label class="control-label text-left width-30 margin-left-minus-5">Auto Inc</label>
                        <label class="control-label text-left unit-number-label margin-left-5">PIN Number</label>
                    </div>
                <div class="col-lg-2" *ngIf="targetMatter.isMatterProvinceON">
                        <label class="control-label share-percent-label padding-top-0">% Share</label>
                        <label class="control-label text-right width-30">Auto calc</label>
                    </div>
                    <div class="col-lg-1">
                        <label class="control-label col-lg-2 text-right">Common Expenses</label>
                    </div>
                    <div class="col-lg-2">
                        <label class="control-label  text-right">Unit Type</label>
                    </div>
                <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                     *ngIf="targetMatter.isMatterProvinceAB">
                    <label class="control-label  text-right">Roll Number</label>
                </div>
                <div class="col-lg-1" *ngIf="targetMatter.isMatterProvinceON">
                        <label class="control-label  text-right">ID Number</label>
                    </div>
                <div class="col-lg-1" *ngIf="targetMatter.isMatterProvinceON">
                        <label *ngIf="isOverrideDescIndVisible()" class="control-label text-left">Override Description</label>
                    </div>
                </div>
                <div class="form-group row condo-expense"
                     *ngFor="let condominiumExpense of unitLevelPlan.condominiumExpenses; let j=index"
                     [ngClass]="{'table-header-line' : j == 0}">
                    <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                         *ngIf="targetMatter.isMatterProvinceAB">
                        <input id="lincNumber-{{j +currentTimeStamp}}" name="lincNumber-{{j +currentTimeStamp}}"
                               uppercased
                               dp-alphanumeric-only
                               maxlength="200"
                               [(ngModel)]="condominiumExpense.lincNumber"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 condo-expense-unit">
                        <span class="tool-tip-icon margin-left-minus-5">
                            <dp-checkbox fieldId="autoIncrementUnitNumber-{{j +currentTimeStamp}}">
                            <input type="checkbox"
                                   id="autoIncrementUnitNumber-{{j +currentTimeStamp}}"
                                   name="autoIncrementUnitNumber-{{j +currentTimeStamp}}"
                                   [(ngModel)]="condominiumExpense.autoIncrementUnitNumber"
                                   (change)="autoIncrementUpdate('UnitNumber',j)"
                            >
                            </dp-checkbox>
                        </span>
                        <span class="tool-tip-message left-minus-10">
                            <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to auto-increment Unit Number</span>
                        </span>
                        <input id="unitNumber-{{j +currentTimeStamp}}" name="unitNumber-{{j +currentTimeStamp}}"
                               maxlength="5" dp-trimmed uppercased
                               dp-alphanumeric-only
                               [(ngModel)]="condominiumExpense.unitNumber"
                               (change)="teraviewLegalDescOverriddenTypeChanged(condominiumExpense)"
                               class="form-control unit-number-percent">
                        <button type="button" *ngIf="j > 2"
                                (click)="removeCondominiumExpense(condominiumExpense)" class="textbox-glyphicon-icon">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                    <div class="col-lg-1" *ngIf="targetMatter.isMatterProvinceON">
                        <span class="tool-tip-icon margin-left-minus-5">
                            <dp-checkbox fieldId="autoIncrementLevelNumber-{{j +currentTimeStamp}}">
                            <input type="checkbox"
                                   id="autoIncrementLevelNumber-{{j +currentTimeStamp}}"
                                   name="autoIncrementLevelNumber-{{j +currentTimeStamp}}"
                                   [(ngModel)]="condominiumExpense.autoIncrementLevelNumber"
                                   (change)="autoIncrementUpdate('LevelNumber',j)"
                            >
                            </dp-checkbox>
                        </span>
                        <span class="tool-tip-message left-minus-10">
                            <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to auto-increment Level Number</span>
                        </span>
                        <input id="levelNumber-{{j +currentTimeStamp}}"
                               name="levelNumber-{{j +currentTimeStamp}}" maxlength="5"
                               dp-trimmed uppercased
                               dp-alphanumeric-only
                               [(ngModel)]="condominiumExpense.levelNumber"
                               (change)="teraviewLegalDescOverriddenTypeChanged(condominiumExpense)"
                               class="form-control unit-number-percent"/>

                    </div>
                    <div class="col-lg-2" *ngIf="unitLevelPlan.condominiumPlans.length > 1">
                        <select
                            class="form-control"
                            id="planNumber-{{j +currentTimeStamp}}" name="planNumber-{{j +currentTimeStamp}}"
                            [(ngModel)]="condominiumExpense.condominiumPlan"
                            (change)="planNumerChanged(condominiumExpense)"
                        >
                            <option *ngFor="let condoPlan of unitLevelPlan.condominiumPlans"
                                    [ngValue]="condoPlan">{{getPlanNumber(condoPlan)}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-1" *ngIf="targetMatter.isMatterProvinceAB">
                        <input id="dividedShares-{{j +currentTimeStamp}}" name="dividedShares-{{j +currentTimeStamp}}"
                               [(ngModel)]="condominiumExpense.noUndividedShare"
                               [max-int]=99999.999999
                               dp-percentage
                               [max-decimals]=6
                               class="form-control padding-right-3"/>
                    </div>
                    <div [ngClass]="getPinNumberColumnCssClass()" *ngIf="targetMatter.isMatterProvinceON">
                        <span class="tool-tip-icon margin-left-minus-5">
                            <dp-checkbox fieldId="autoIncrementPinNumber-{{j +currentTimeStamp}}">
                            <input type="checkbox"
                                   id="autoIncrementPinNumber-{{j +currentTimeStamp}}"
                                   name="autoIncrementPinNumber-{{j +currentTimeStamp}}"
                                   [(ngModel)]="condominiumExpense.autoIncrementPinNumber"
                                   (change)="autoIncrementUpdate('PinNumber',j)"
                            >
                            </dp-checkbox>
                        </span>
                        <span class="tool-tip-message left-minus-10">
                            <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to auto-increment Pin Number</span>
                        </span>
                        <input id="pinNumber-{{j +currentTimeStamp}}" name="pinNumber-{{j +currentTimeStamp}}"
                               #pinNumber="ngModel"
                               [(ngModel)]="condominiumExpense.pinNumber"
                               [fieldKey]="'matter.propertyteranet.pin'+j"
                               dp-error-validator
                               (blur)="addCustomPinWarning($event.target.value,'matter.propertyteranet.pin',j)"
                               dp-pinFormat
                               class="form-control unit-number-percent"/>
                    </div>
                    <div class="col-lg-2" *ngIf="targetMatter.isMatterProvinceON">
                        <input id="sharePercent-{{j +currentTimeStamp}}" name="sharePercent-{{j +currentTimeStamp}}"
                               [(ngModel)]="condominiumExpense.sharePercentage"
                               [max-int]=99.999999999
                               dp-percentage
                               [max-decimals]=9
                               (blur)="updateTotalShare()"
                               class="form-control padding-right-3 share-percent"/>
                        <label class="control-label padding-top-5">%</label>
                        <span class="tool-tip-icon">
                            <dp-checkbox fieldId="autoCalc-{{j +currentTimeStamp}}">
                            <input type="checkbox"
                                   id="autoCalc-{{j +currentTimeStamp}}"
                                   name="autoCalc-{{j +currentTimeStamp}}"
                                   (change)="onAutoCalcChange($event, j)"
                                   [(ngModel)]="condominiumExpense.autoCalcInd"
                            >
                        </dp-checkbox>
                        </span>
                        <span class="tool-tip-message left-150">
                            <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to auto-calculate common expenses based on percentage share</span>
                        </span>
                    </div>
                    <div class="col-lg-1">
                        <input
                            [dp-read-only]="condominiumExpense.autoCalcInd"
                            id="commonExpenses-{{j +currentTimeStamp}}"
                            name="commonExpenses-{{j +currentTimeStamp}}"
                            [(ngModel)]="condominiumExpense.condominiumExpense"
                            (blur)="updateTotalExpenses()" dp-currency dp-default-currency
                            (focus)="updatecondominiumExpenseF9Help(j)"
                            (keydown.f9)="condominiumExpensef9Clicked(j)"
                            [fieldKey]="'matter.propertyteranet.condominiumExpense'+j"
                            statusBarHelp
                            class="form-control"/>

                    </div>
                    <div class="col-lg-2">
                        <select
                            class="form-control"
                            id="unitType-{{j +currentTimeStamp}}" name="unitType-{{j +currentTimeStamp}}"
                            [(ngModel)]="condominiumExpense.condominiumUnitType">
                            <option *ngFor="let unitType of condoUnitType"
                                    [value]="unitType.value">{{unitType.label}}
                            </option>
                        </select>
                    </div>
                    <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-3' : 'col-lg-4'"
                         *ngIf="targetMatter.isMatterProvinceAB">
                        <input id="rollNumber-{{j +currentTimeStamp}}" name="rollNumber-{{j +currentTimeStamp}}"
                               uppercased
                               dp-alphanumeric-only
                               maxlength="20"
                               [(ngModel)]="condominiumExpense.rollNumber"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1" *ngIf="targetMatter.isMatterProvinceON">
                        <input id="idNumber-{{j +currentTimeStamp}}"
                               name="idNumber-{{j +currentTimeStamp}}"
                               [(ngModel)]="condominiumExpense.idNumber"
                               maxlength="10"
                               class="form-control padding-right-3"/>
                    </div>
                    <ng-container *ngIf="targetMatter.isMatterProvinceON">
                    <div class="col-lg-1" *ngIf="isOverrideDescIndVisible()">
                        <span class="tool-tip-icon">
                            <dp-checkbox fieldId="overrideDescInd-{{j +currentTimeStamp}}" class="margin-left-40">
                                <input type="checkbox"
                                       id="overrideDescInd-{{j +currentTimeStamp}}"
                                       name="overrideDescInd-{{j +currentTimeStamp}}"
                                       (change)="overrideCondoDescChange($event, j)"
                                       [(ngModel)]="condominiumExpense.overrideDescInd">
                            </dp-checkbox>
                        </span>
                        <span class="tool-tip-message override-desc">
                            <div class="bubble-arrow override-desc"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to Override Teraview Legal Description</span>
                        </span>
                    </div>
                    <div class="col-lg-12 padding-top-10" *ngIf="condominiumExpense.overrideDescInd">
                        <div class="col-lg-2">
                            <label class="control-label padding-top-5">Override Teraview Legal Description</label>
                        </div>
                        <div class="col-lg-3">
                            <select
                                class="form-control"
                                id="teraviewLegalDescOverriddenType-{{j +currentTimeStamp}}"
                                name="teraviewLegalDescOverriddenType-{{j +currentTimeStamp}}"
                                [(ngModel)]="condominiumExpense.teraviewLegalDescOverriddenType"
                                (change)="teraviewLegalDescOverriddenTypeChanged(condominiumExpense)"
                            >
                                <option *ngFor="let teraviewLegalDescOverriddenType of teraviewLegalDescOverriddenTypes"
                                        [value]="teraviewLegalDescOverriddenType.value">
                                    {{teraviewLegalDescOverriddenType.label}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-7">
                            <textarea rows="4"
                                      class="form-control"
                                      id="teraviewLegalDesc-{{j +currentTimeStamp}}"
                                      name="teraviewLegalDesc-{{j +currentTimeStamp}}"
                                      [dp-read-only]="condominiumExpense.isLegalDescriptionApplyLegalDescEasement()"
                                      [(ngModel)]="condominiumExpense.teraviewLegalDesc"
                            >
                            </textarea>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                        </div>
                    </div>

                    </ng-container>
                </div>
                <div class="form-group row padding-bottom-40">
                    <ng-container *dpShowByProvince="'matter.matterProperty.unitLevelPlan.level'">
                        <div class="text-left col-lg-2">
                         <span class="actionlink"
                               *ngIf="unitLevelPlan.condominiumExpenses.length < 20"
                               (click)="addCondominiumExpense();">
                                <span>
                                    <a href="javascript:void(0);">
                                        Add New
                                    </a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                           </span>

                        </div>
                        <div class="col-lg-3 text-right">
                            <label class="control-label">Total</label>

                        </div>
                    </ng-container>
                    <ng-container *dpShowByProvince="'matter.matterProperty.unitLevelPlan.rollNumber'">
                        <div class="text-left" class="col-lg-4"
                             [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-5' : 'col-lg-4'">
                             <span class="actionlink"
                                   *ngIf="unitLevelPlan.condominiumExpenses.length < 20"
                                   (click)="addCondominiumExpense();">
                                    <span>
                                        <a href="javascript:void(0);">
                                            Add New
                                        </a>
                                    </span>
                                    <span class="selector fa fa-plus-square-o"></span>
                               </span>
                        </div>
                        <div class="text-right" [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-1'">
                            <label class="control-label">Total</label>
                        </div>
                    </ng-container>
                <div class="col-lg-2" *ngIf="targetMatter.isMatterProvinceON">
                        <input name="condominiumTotalSharePercentage"
                               [(ngModel)]="unitLevelPlan.condominiumTotalSharePercentage"
                               readonly="true" class="form-control share-percent"/>
                        <label class="control-label padding-top-5">%</label>
                    </div>

                    <div class="col-lg-1">
                        <input name="total"
                               [(ngModel)]="unitLevelPlan.condominiumTotalExpenses"
                               readonly="true" class="form-control"
                               dp-currency
                        />
                    </div>
                    <div class="col-lg-2" *ngIf="targetMatter.isMatterProvinceON">

                    </div>
                    <ng-container
                        *ngIf="targetMatter.isMatterProvinceAB">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">Percentage share of total realty taxes</label>
                        </div>
                        <div class="col-lg-2">
                            <div class="col-lg-10 margin-left-minus-5">
                                <input
                                    type="text"
                                    id="percentageShareOfTotalRealtyTaxes"
                                    name="percentageShareOfTotalRealtyTaxes"
                                    class="form-control"
                                    [max-int]=99.999999999
                                    [(ngModel)]="unitLevelPlan.percentageShareOfTotalRealtyTaxes"
                                    dp-percentage
                                    max-decimals=9
                                >
                            </div>
                            <div class="col-lg-2">
                                <label class="control-label">%</label>
                            </div>
                        </div>
                        <!--<div class="percentage-width-10">-->
                        <!--<label class="control-label">%</label>-->
                        <!--</div>-->
                    </ng-container>
                </div>
            </div>
        <ng-container *ngIf="targetMatter.isMatterProvinceAB">
            <div class="form-group" *ngIf="!targetMatter.matterPropertyWithCondo.isPropertyCondominium()">
                <div class="form-group">
                    <div class="radio col-lg-12" style="padding-left: 30px">
                             <span class="radio display-inline" (click)="initPlanBlockLotFields()">
                              <input type="radio" [checked]="unitLevelPlan.propertyDescriptionType == 'PLAN_BLOCK_LOT'">
                              <label>Plan/Block/Lot</label>
                             </span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-2">
                        <div class="col-lg-8"></div>
                        <div class="col-lg-1"><span class="control-label">Auto-Increment</span></div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="planNumber">Plan Number</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementPlan ? '' : 'Check to auto-increment \u0022Plan Number\u0022'}}">
                            <dp-checkbox fieldId="autoIncPlanNumber-AB" [hasTabIndex]="true">
                                <input type="checkbox"
                                       id="autoIncPlanNumber-AB"
                                       name="autoIncPlanNumber-AB"
                                       [dp-read-only] = "unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                                       [(ngModel)]="autoIncrementPlan"
                                       (change)="autoIncrementUpdate('PlanNum')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" name="planNumber" id="planNumber" class="form-control" [(ngModel)]="unitLevelPlan.plan"
                               maxlength="100" [dp-read-only]="unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="blockNumber">Block Number</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementBlock ? '' : 'Check to auto-increment \u0022Block Number\u0022'}}">
                            <dp-checkbox fieldId="autoIncBlockNumber-AB" [hasTabIndex]="true">
                                <input type="checkbox"
                                       id="autoIncBlockNumber-AB"
                                       name="autoIncBlockNumber-AB"
                                       [dp-read-only] = "unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                                       [(ngModel)]="autoIncrementBlock"
                                       (change)="autoIncrementUpdate('BlockNum')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" name="blockNumber" id="blockNumber" class="form-control" [(ngModel)]="unitLevelPlan.block"
                               maxlength="80" [dp-read-only]="unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10" for="lotNumber">Lot Number</label>
                        <div class="col-lg-1 padding-top-7" title="{{autoIncrementLot ? '' : 'Check to auto-increment \u0022Lot Number\u0022'}}">
                            <dp-checkbox fieldId="autoIncLotNumber-AB" [hasTabIndex]="true">
                                <input type="checkbox"
                                       id="autoIncLotNumber-AB"
                                       name="autoIncLotNumber-AB"
                                       [dp-read-only] = "unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                                       [(ngModel)]="autoIncrementLot"
                                       (change)="autoIncrementUpdate('LotNum')"
                                />
                            </dp-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" name="lotNumber" id="lotNumber" class="form-control" [(ngModel)]="unitLevelPlan.lot"
                               maxlength="80" [dp-read-only]="unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-2">
                        <label class="control-label col-lg-10">Exception Wording?</label>
                        <div class="col-lg-1 padding-top-7">
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <select class="form-control" id="exceptionType"
                                name="exceptionType"  [(ngModel)]="unitLevelPlan.exceptionType" (ngModelChange)="initExceptionTypeDescription($event)"
                                [dp-read-only] = "unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'">
                            <option value=""></option>
                            <option *ngFor="let exceptingType of exceptionTypeOptions" [value]="exceptingType.value">
                                {{exceptingType.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-4" *ngIf="unitLevelPlan.exceptionType == 'YES'">
                        <p-autoComplete
                            [(ngModel)]="unitLevelPlan.exceptionTypeDescription"
                            tabIndexIgnore
                            name="exceptionDescription"
                            [suggestions]="filteredExceptionTypeOptions"
                            (completeMethod)="filterExceptionTypeDescription($event)"
                            [minLength]="1"
                            [dropdown]="true"
                            maxlength="80"
                            [disabled]="unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'"
                            (keydown.space)="utils.handleSpaceKeyDropdown($event, unitLevelPlan.exceptionTypeDescription, handleDropdownClick)"
                            (keydown.enter)="utils.handleEnterKeyDropdown($event,  unitLevelPlan.exceptionTypeDescription, handleDropdownClick)"
                            (onDropdownClick)="handleDropdownClickExceptionTypeDescription()">
                            <ng-template let-paymentFrequencyLabel pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                                <span class="omnibarLi100">
                                                    {{paymentFrequencyLabel}}
                                                </span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
                    <div class="form-group">
                        <div class="radio col-lg-12" style="padding-left: 30px">
                                 <span class="radio display-inline" (click)="initMetesAndBoundsFields()">
                                  <input type="radio" [checked]="unitLevelPlan.propertyDescriptionType == 'METES_AND_BOUNDS'">
                                  <label>Metes and Bounds</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div>
                            <label class="control-label col-lg-2 margin-left-minus-20">Short legal description</label>
                            <div class="col-lg-9">
                                <input type="text" trimmed id="shortLegalDescription" name="shortLegalDescription"  class="form-control"
                                       maxlength="255"
                                       [(ngModel)]="unitLevelPlan.shortLegalDescription" [dp-read-only] = "unitLevelPlan.propertyDescriptionType == 'PLAN_BLOCK_LOT'"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div>
                            <label class="control-label col-lg-2 margin-left-minus-20">Full legal description</label>
                            <div class="col-lg-9">
                                        <textarea  type="textarea" id="fullLegalDescription" name="fullLegalDescription"
                                                   class="form-control"  rows="5" [(ngModel)]="unitLevelPlan.fullLegalDescription" [dp-read-only] = "unitLevelPlan.propertyDescriptionType == 'PLAN_BLOCK_LOT'"
                                        ></textarea>
                            </div>
                        </div>
                    </div>
                <!-- end of new change -->

            </div>
        </ng-container>
        <div class="form-group">
            <div class="col-lg-12"><hr class="hr-title margin-bottom-0 margin-top-0"></div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="newMatterNo">New Matter No.</label>
            </div>
            <div class="col-lg-4">
                <input type="text"
                       id="newMatterNo"
                       name="newMatterNo"
                       class="form-control"
                       maxlength="25"
                       [(ngModel)]="targetMatter.matterRecordNumber"
                       dp-uppercase-preserve-cursor-position
                       dp-matterNoFilter
                       [fieldKey]="!targetMatter.matterRecordNumber ? 'matter.matterOpening.matterNo' : 'matter.matterOpening.uniqueMatterNo'"
                       dp-error-validator
                />
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2">
                <label class="control-label" for="needAutoIncrementFileNo">check to auto-increment File No.</label>
            </div>
            <div class="col-lg-4 padding-top-10">
                <dp-checkbox fieldId="needAutoIncrementFileNo" [hasTabIndex]="true">
                    <input type="checkbox" id="needAutoIncrementFileNo"
                           [(ngModel)]="needAutoIncrementFileNo"/>
                </dp-checkbox>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="newFileNo">New File No.</label>
            </div>
            <div class="col-lg-4">
                <input type="text" id="newFileNo" name="newFileNo"
                       [(ngModel)]="targetMatter.fileNumber"
                       maxlength="50"
                       trimmed
                       class="form-control" />
            </div>
            <ng-container *ngIf="targetMatter.isMatterProvinceON">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" for="teraviewDockedId">Teraview Docked ID</label>
                </div>
                <div class="col-lg-4" *ngIf="targetMatter.teranetDocket">
                    <input type="text" id="teraviewDockedId" name="teraviewDockedId"
                           maxlength="10"
                           [(ngModel)]="targetMatter.teranetDocket.teraviewDocketIdentifier"
                           dp-uppercase
                           [dp-read-only]="targetMatter.project && targetMatter.project.useSameDocketIdentifier"
                           trimmed
                           class="form-control" />
                </div>
            </ng-container>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn2 " class="dp-btn" (click)="createMatter()"
                    [disabled]="createMatterDisabled"
            >
                <span>Create Matter</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>
