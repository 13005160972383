import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

import {categoryDescriptionMap} from './lender-forms-constants';
import {DocumentTemplate} from '../../document-production/document-template';

export class LenderForms extends BaseEntity {

  id: number;
  lenderInstitutionId: number;
  formCategory: string;
  sctNumber: string;
  sctFiler: string;
  formName: string;
  templateName: string;
  outputFormName: string;
  lenderFormOrder: number;
  documentTemplate: DocumentTemplate;
  retired: boolean;

  get formCategoryDescription(): string {
    return categoryDescriptionMap[ this.formCategory ] || '';
  }
}
