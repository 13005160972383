import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Instrument} from '../../../../shared-main/teranet/instrument';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class AddInstrumentToCourierContext {
  parcelRegister: ParcelRegister;
  selectedCourierInstruments: Instrument[];
}

@Component({
  selector: 'add-instrument-to-courier',
  templateUrl: 'add-instrument-to-courier.modal.component.html',
  providers: [ErrorService],
  styleUrls: ['./courier-instrument.scss']
})
export class AddInstrumentToCourierModal extends ModalComponent<AddInstrumentToCourierContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  selectedInstrumentIndex: number;

  constructor(
    public dialog: MatDialogRef<AddInstrumentToCourierModal>,
    @Inject(MAT_DIALOG_DATA) context?: AddInstrumentToCourierContext
  ) {
    super(dialog, context);
  }

  get parcelRegister(): ParcelRegister {
    return this.context.parcelRegister;
  }

  get instrumentOptions(): Instrument[] {
    //only show instruments that are not already selected
    return this.parcelRegister.instruments.filter(
      (instrument) => this.context.selectedCourierInstruments.indexOf(instrument) < 0
    );
  }

  get selectedInstrument(): Instrument {
    return this.instrumentOptions[this.selectedInstrumentIndex];
  }

  ngOnInit() {
    this.selectedInstrumentIndex = 0;
  }

  ngAfterViewInit() {
    jQuery('#instrumentArray' + this.selectedInstrumentIndex).focus();
  }

  proceed(): void {
    this.dialog.close({
      action: 'SELECTED',
      selectedInstrument: this.selectedInstrument
    });
  }

  selectInstrument(index: number): void {
    this.selectedInstrumentIndex = index;
  }

  onCancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  isRowSelected(index: number): boolean {
    return this.selectedInstrumentIndex == index;
  }

  onKeyPress(event: KeyboardEvent) {
    if (
      event.keyCode === 40 || //Down arrow key
      event.keyCode === 38 || //Up arrow key
      event.keyCode === 13 // enter key
    ) {
      this.handleArrowKeys(event);
    }
  }

  handleArrowKeys(event) {
    event.preventDefault();
    event.stopPropagation();
    //for up/down key, if go to top or end of the list, we need to start again from end or start of the list
    if (event.keyCode === 40) {
      //Down arrow key
      if (this.selectedInstrumentIndex < this.instrumentOptions.length - 1) {
        this.selectedInstrumentIndex++;
      } else {
        this.selectedInstrumentIndex = 0;
      }
      jQuery('#instrumentArray' + this.selectedInstrumentIndex).focus();
    } else if (event.keyCode === 38) {
      //Up arrow key
      if (this.selectedInstrumentIndex > 0) {
        this.selectedInstrumentIndex--;
      } else {
        this.selectedInstrumentIndex = this.instrumentOptions.length - 1;
      }
      jQuery('#instrumentArray' + this.selectedInstrumentIndex).focus();
    } else if (event.keyCode === 13) {
      this.proceed();
    }
  }
}
