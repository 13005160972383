import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {ProvinceCode} from './account-province';

export type SourceDateType =
  'CLOSING_DATE'
  | 'FILE_OPENING_DATE'
  | 'OCCUPANCY_DATE'
  | 'REQUISITION_DATE'
  | 'AGREEMENT_OF_PS_DATE'
  | 'FILE_INACTIVE_DATE';

export type DurationOrderType = 'BEFORE' | 'AFTER';

export class DateTypes {
  public static readonly REQUISITION_DATE: SourceDateType = 'REQUISITION_DATE';
  public static readonly CLOSING_DATE: SourceDateType = 'CLOSING_DATE';
  public static readonly OCCUPANCY_DATE: SourceDateType = 'OCCUPANCY_DATE';
}

export class DateCalculationConfig extends BaseEntity {

  constructor(dateCalculationConfig?: DateCalculationConfig) {
    super(dateCalculationConfig);
  }

  id: number;
  customerAccountId: number;
  provinceCode: ProvinceCode;
  sourceDateType: SourceDateType;
  autoPopulate: boolean;
  duration: number;
  durationType: string;
  durationOrder: DurationOrderType;
  sourceDate: SourceDateType;

  isBeforeSelected(): boolean {
    return (this.durationOrder === 'BEFORE');
  }

  get isSourceSelectedAsClosingDate(): boolean {
    return this.sourceDate && this.sourceDate == 'CLOSING_DATE';
  }

  get isAutoPopulateEnabled(): boolean {
    return !!this.autoPopulate;
  }

}
