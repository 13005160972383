import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import * as _ from 'lodash';
import {HttpClient} from '../core';
import {matterApi, MatterParticipant, matterResponseKey, Utils} from '../matters/shared';
import {Contact, MortgageeLenderInstitutionLinkStatus} from '../matters/shared/contact';
import {BehaviorSubject, of} from 'rxjs';
import {Constants} from './constants';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {AuthZService} from '../core/authz/auth-z.service';
import {LockStatus} from './lock-status';
import {LendingInstitution} from '../contact/lending-institution';
import {contactDropDowns} from '../contact/contact-drop-downs';
import {ContactQueryService} from '../contact/contact-query.service';
import {OwnerData} from '../matters/property-teranet/teranet-connect-detail/import-data/teranet-owner-import.modal.component';
import {map} from 'rxjs/operators';

interface TaxRate {
  instanceType: string,
  hstRate: number
}

// This this a sharable service which is used for all the contact apis.
@Injectable()
export class ContactService {

  private _contactSource: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(undefined);

  private _contact = this._contactSource.asObservable();
  private cachedSolicitorList: Contact[] = [];
  private cachedLawClerkList: Contact[] = [];
  private cachedOtherkList: Contact[] = [];
  private cachedLendingInstitutionList: LendingInstitution[] = [];

  utils;

  constructor(private http: HttpClient,
              private authZService: AuthZService) {
    this.utils = new Utils();
  }

  clearCache(): void {
    this.cachedSolicitorList = [];
    this.cachedLawClerkList = [];
  }

  clearSolicitorLawClerkOtherCache(): void {
    this.cachedSolicitorList = [];
    this.cachedLawClerkList = [];
    this.cachedOtherkList = [];
  }

  getUpdatedSolicitorLawClerkList(type?): Observable<Contact[]> {
    this.clearCache();
    return this.getSolicitorLawClerkOtherList(type);
  }

  getSolicitorLawClerkList(type?, fullGraph: boolean = true): Observable<Contact[]> {
    return this.getSolicitorLawClerkOtherList(type, fullGraph);
  }

  getUpdatedSolicitorLawClerkOtherList(type?, fullGraph: boolean = true, filterApiClientUser: boolean = false): Observable<Contact[]> {
    this.clearSolicitorLawClerkOtherCache();
    return this.getSolicitorLawClerkOtherList(type, fullGraph, filterApiClientUser);
  }

  //by default api users should be filtered out in all the cases except created by user on matter
  getSolicitorLawClerkOtherList(type?, fullGraph: boolean = true, filterApiClientUser: boolean = true): Observable<Contact[]> {

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (type === 'SOLICITOR') {
      if (this.cachedSolicitorList.length === 0) {
        let filter: string = 'customerAccount.id_EQ_' + accountId + (!!filterApiClientUser ? ',apiClientUser_EQ_false' : '');
        let url = `${ matterApi.contactsList(accountId) }?fullGraph=${ fullGraph }&contactType=${ type }&filter=${ filter }&filterOtherFirmContact=true&sort=contactName.firstName|ASC&page=${ 1 }&per_page=${ 200 }`;//Temporary fetching top 200 to solve performance issue
        return this.http.get(url).map((res) => {
          //If there is two call in short time, it will have duplicate data.
          if (this.cachedSolicitorList.length === 0) {
            res[ matterResponseKey.contacts ].forEach(item => {
              this.cachedSolicitorList.push(new Contact(item));
            });
          }
          return this.cachedSolicitorList;
        });
      } else {
        return Observable.of(this.cachedSolicitorList);
      }
    } else if (type === 'LAWCLERK') {
      if (this.cachedLawClerkList.length === 0) {
        let filter: string = 'customerAccount.id_EQ_' + accountId + (!!filterApiClientUser ? ',apiClientUser_EQ_false' : '');
        let url = `${ matterApi.contactsList(accountId) }?fullGraph=${ fullGraph }&contactType=${ type }&filter=${ filter }&filterOtherFirmContact=true&sort=contactName.firstName|ASC&page=${ 1 }&per_page=${ 100 }`;

        return this.http.get(url).map((res) => {
          //If there is two call in short time, it will have duplicate data.
          if (this.cachedLawClerkList.length === 0) {
            res[ matterResponseKey.contacts ].forEach(item => {
              this.cachedLawClerkList.push(new Contact(item));
            });
          }
          return this.cachedLawClerkList;
        });
      } else {
        return Observable.of(this.cachedLawClerkList);
      }
    } else if (type === 'OTHER') {
      if (this.cachedOtherkList.length === 0) {
        let filter: string = 'customerAccount.id_EQ_' + accountId + (!!filterApiClientUser ? ',apiClientUser_EQ_false' : '');
        let url = `${ matterApi.contactsList(accountId) }?fullGraph=${ fullGraph }&contactType=${ type }&filter=${ filter }&filterOtherFirmContact=true&sort=contactName.firstName|ASC&page=${ 1 }&per_page=${ 100 }`;

        return this.http.get(url).map((res) => {
          //If there is two call in short time, it will have duplicate data.
          if (this.cachedOtherkList.length === 0) {
            res[ matterResponseKey.contacts ].forEach(item => {
              this.cachedOtherkList.push(new Contact(item));
            });
          }
          return this.cachedOtherkList;
        });
      } else {
        return Observable.of(this.cachedOtherkList);
      }
    } else {//ToDo: This method should be optimized more later
      if (this.cachedLawClerkList.length === 0 || this.cachedSolicitorList.length === 0) {
        let url = `${ matterApi.contactsList(accountId) }?fullGraph=${ fullGraph }&contactType=LAWCLERK,SOLICITOR&filter=customerAccount.id_EQ_${ accountId }&filterOtherFirmContact=true&sort=contactName.firstName|ASC&page=${ 1 }&per_page=${ 100 }`;

        return this.http.get(url).map((res) => {
          res[ matterResponseKey.contacts ].forEach(item => {
            let contact: Contact = new Contact(item);
            if (contact.isLawClerk) {
              this.cachedLawClerkList.push(contact);
            } else {
              this.cachedSolicitorList.push(contact);
            }
          });
          return this.cachedLawClerkList.concat(this.cachedSolicitorList);
        });
      } else {
        return Observable.of(this.cachedLawClerkList.concat(this.cachedSolicitorList));
      }
    }

  }

  /**
   * get contacts for search query based on types
   *
   * @param query
   * @param type
   * @param removeAddNewRecordLabel
   * @param filterByAccountId
   * @param isActive
   * @param filterOtherFirmContact pass in 'true' to restrict contact search to user's own firm only
   */
  getContactsType(query: string, type: string, removeAddNewRecordLabel?: boolean, filterByAccountId?: boolean, isActive?: boolean, filterOtherFirmContact?: boolean): Observable<any[]> {
    if (this.authZService.isContactReadOnlyAccess()) {
      removeAddNewRecordLabel = true;
    }
    // TODO: use https://angular.io/docs/ts/latest/api/http/index/URLSearchParams-class.html to build query string. Doesn't work in current 2.0.0 version.
    let url: string;
    let filterKey: string;
    let typeUpperCase: string = type.toUpperCase();
    //Backend need do escape for contact search
    let urlQuery = query;
    urlQuery = Utils.escapeSearchText(urlQuery);
    let sort: string;
    let filter: string = '';
    let page: string = '1';
    let per_page: string = '15';
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    const replacedAddressHash: string = this.removeSpecialCharactersFromAddressSearch(urlQuery);
    switch (typeUpperCase) {
      case 'LAWCLERK':
      case 'SOLICITOR':
        sort = 'contactName.initials|ASC';
        url = `${ matterApi.contactsList(accountId) }?contactType=${ type }&filter=customerAccount.id_EQ_${ accountId },contactName.initials_EQ_${ urlQuery }*&sort=${ sort }&page=${ page }&per_page=${ per_page }`;
        filterKey = 'initials';
        break;
      case 'SOLICITOR-LASTNAME': // just like previous one, but filtering by lastName rather than initials
        sort = 'contactName.lastName|ASC,contactName.firstName|ASC';
        filter = urlQuery && urlQuery.length > 0 ? 'contactName.lastName_EQ_*' + urlQuery + '*' : '';
        isActive ? filter = this.appendContactStatusFilter(filter, 'ACTIVE') : '';
        url = `${ matterApi.contactsList(accountId) }?contactType=SOLICITOR&filter=${ filter }&sort=${ sort }&page=${ page }&per_page=${ per_page }`;
        filterKey = 'initials';
        break;
      case 'SOLICITOR-DISPLAYNAME': // just like previous one, but filtering by lastName rather than initials
        sort = 'displayName|ASC';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_*' + urlQuery + '*|contactMailingAddress.addressHash_EQ_mailing:*' + replacedAddressHash + '*' : '';
        isActive ? filter = this.appendContactStatusFilter(filter, 'ACTIVE') : '';
        url = `${ matterApi.contactsList(accountId) }?contactType=SOLICITOR&filter=${ filter }&sort=${ sort }&page=${ page }&per_page=${ per_page }`;
        filterKey = 'initials';
        break;
      case 'SOLICITOR-DISPLAYNAME-ADDRESS':
        // DPPMP-25882: each word in search term must match either name or address
        if (query.length > 0) {
          url = `${ matterApi.searchSolicitors(accountId) }?contactType=SOLICITOR&filter=${ urlQuery }&page=${ page }&per_page=${ per_page }`;
          filterKey = 'name_address';
        }
        break;
      case 'REALESTATEAGENT':
        //DPPMP-12303, remove the address from the ReAgent search and sorting
        // sort = 'contactName.lastName|ASC,email|ASC,contactMailingAddress.addressHash|ASC';
        sort = 'contactName.lastName|ASC,contactName.firstName|ASC,contactName.middleName|ASC,email|ASC';
        // filter = urlQuery && urlQuery.length > 0 ? 'ANYcontactName.lastName_EQ_*'+urlQuery+'*|email_EQ_*'+urlQuery+'*|contactMailingAddress.addressHash_EQ_*'+replacedAddressHash+'*MAILING' : '';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_*' + urlQuery + '*|contactName.lastName_EQ_*' + urlQuery + '*|email_EQ_*' + urlQuery + '*' : '';
        isActive ? filter = this.appendContactStatusFilter(filter, 'ACTIVE') : '';
        url = `${ matterApi.contactsList(accountId) }?contactType=${ type }&filter=${ filter }&filterType=ALL&sort=${ sort }&page=${ page }&per_page=${ per_page }`;
        filterKey = 'fullName';
        break;
      case 'REALESTATEBROKER':
      case 'SURVEYOR':
      case 'RESIDENCE_ASSOCIATION':
        sort = 'organizationName|ASC,contactMailingAddress.addressHash|ASC';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*|contactMailingAddress.addressHash_EQ_mailing:*' + replacedAddressHash + '*' : '';
        isActive ? filter = this.appendContactStatusFilter(filter, 'ACTIVE') : '';
        url = `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }&filterType=ALL&page=${ page }&per_page=${ per_page }`;
        filterKey = 'organizationName';
        break;
      case 'LAWCLERK,SOLICITOR':
        sort = 'contactName.initials|ASC';
        filter = `contactName.initials_EQ_${ query }*`;
        if (filterByAccountId) {
          filter = `customerAccount.id_EQ_${ accountId },` + filter;
        }
        isActive ? filter = this.appendContactStatusFilter(filter, 'ACTIVE') : '';
        url = `${ matterApi.contactsList(accountId) }?contactType=${ type }&filter=${ filter }&sort=${ sort }&filterType=ALL&page=${ page }&per_page=${ per_page }`;
        if (filterOtherFirmContact) {
          url = url + `&filterOtherFirmContact=true`;
        }

        filterKey = 'initials';
        break;
      case 'SOLICITOR-LASTNAME-FIRMNAME':
        //sort by 1) LastNameFirstFullName 2) FirmName
        sort = 'contactName.lastName|ASC,contactName.firstName|ASC,contactName.middleName|ASC,organization.organizationName|ASC';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYcontactName.lastName_EQ_*' + urlQuery + '*|organization.organizationName_EQ_*' + urlQuery + '*' : '';
        isActive ? filter = this.appendContactStatusFilter(filter, 'ACTIVE') : '';
        url = `${ matterApi.contactsList(accountId) }?contactType=SOLICITOR&filter=${ filter }&sort=${ sort }&page=${ page }&per_page=${ per_page }`;
        filterKey = 'initials';
        break;
      default:
        url = `${ matterApi.contactsList(accountId) }?contactType=${ type }&page=${ page }&per_page=${ per_page }`;
        filterKey = undefined;
    }

    return this.http.get(url)
    .map((res) => {

      let sorted: any[] = [];

      if (type.toUpperCase() === 'REALESTATEBROKER') {

        let data = res[ matterResponseKey.contacts ];
        let modified = [];
        if (!this.doesQueryEqualType(query, typeUpperCase)) {
          data.forEach(item => {
            // DPPMP-2439
            // 1. Organization itself with no subContacts
            let organizationWithoutPerson = _.cloneDeep(item);
            organizationWithoutPerson.persons = [];
            modified.push(new Contact(organizationWithoutPerson));
          }, this);
        }
        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          addNewRecordData.typedName = `${ query.trim() }`;
          modified.unshift(addNewRecordData);
        }
        if (query === Constants.REAL_ESTATE_BROKER) {
          return modified;
        } else if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          return this.noRecordFoundOrganization(modified, filterKey);
        } else {
          return modified;
        }
      } else if (type.toUpperCase() === 'SOLICITOR-LASTNAME' || type.toUpperCase() === 'SOLICITOR-DISPLAYNAME' || type.toUpperCase() === 'SOLICITOR-DISPLAYNAME-ADDRESS') {

        let modified = [];
        let data = res[ matterResponseKey.contacts ];
        if (!this.doesQueryEqualType(query, typeUpperCase)) {
          data.forEach(item => {
            modified.push(new Contact(item));
          }, this);
        }

        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          modified.unshift(addNewRecordData);
        }
        if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          modified.push(this.createNewRecordEntry(query, 'none'));
          return modified;
        } else {
          return modified;
        }

      } else if (type.toUpperCase() === 'REALESTATEAGENT') {

        let modified = [];
        let data = res[ matterResponseKey.contacts ];
        if (!this.doesQueryEqualType(query, typeUpperCase)) {
          data.forEach(item => {
            modified.push(new Contact(item));
          }, this);
        }

        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          modified.unshift(addNewRecordData);
        }
        if (query === Constants.REAL_ESTATE_AGENT) {
          return modified;
        } else if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          return this.noRecordFoundOrganization(modified, filterKey);
        } else {
          return modified;
        }

      } else if (type.toUpperCase() === 'RESIDENCE_ASSOCIATION') {

        let modified = [];
        let data = res[ matterResponseKey.contacts ];
        if (!this.doesQueryEqualType(query, typeUpperCase)) {
          data.forEach(item => {
            modified.push(new Contact(item));
          }, this);
        }

        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          modified.unshift(addNewRecordData);
        }
        if (query === Constants.RESIDENCE_ASSOCIATION) {
          return modified;
        } else if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          return this.noRecordFoundOrganization(modified, filterKey);
        } else {
          return modified;
        }

      } else if (type.toUpperCase() === 'SURVEYOR') {

        let data = res[ matterResponseKey.contacts ];

        // modify data for autocomplete omnibar to display data according to user story
        let modified = [];
        data.forEach(item => {
          modified.push(new Contact(item));
        }, this);

        // DPPMP-5390 Remove attention and only show the globle information
        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          modified.unshift(addNewRecordData);
        }
        if (query === Constants.SURVEYOR) {
          return modified;
        } else if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          return this.noRecordFoundOrganization(modified, filterKey);
        } else {
          return modified;
        }

      } else if (typeUpperCase === 'LAWCLERK' || typeUpperCase === 'SOLICITOR' || typeUpperCase === 'LAWCLERK,SOLICITOR' || typeUpperCase === 'SOLICITOR-LASTNAME-FIRMNAME') {
        sorted = res[ matterResponseKey.contacts ];
        // sorted = _.sortBy(data, ['contactName.initials']);

        if (sorted.length === 0) {
          return this.noRecordFound(sorted, filterKey);
        }
      }
      if (sorted.length > 15) {
        sorted = sorted.slice(0, 15);
      }

      // TODO: apply the same conversion into object to other contact types
      if (typeUpperCase === 'SOLICITOR-LASTNAME' || typeUpperCase === 'SOLICITOR-LASTNAME-FIRMNAME') {
        let contacts: Contact[] = [];
        sorted.forEach(val => {
          contacts.push(new Contact(val));
        });
        return contacts;
      } else {
        return sorted;
      }

    });
  }

  doesQueryEqualType(query: string, type: string) {
    return query.split(' ').join('').toUpperCase() === type;
  }

  getActiveContactListPerPage(query: string, type: string, removeAddNewRecordLabel?: boolean, page?: number, perPage?: number,
                              contactStatusSearch?: string, addMortgagesRegisteredFlag?: boolean, provinceSearch?: string[], genderSearch?: string[],
                              includeGlobalFlag?: boolean //includeGlobalFlag is currently only implemented for CONDO_CORPORATIONS
  ): Observable<any[]> {
    return this.getContactsList(query, type, removeAddNewRecordLabel, 'ACTIVE', null, provinceSearch, null, page, perPage, null,
      null, null, addMortgagesRegisteredFlag, null, genderSearch, includeGlobalFlag);
  }

  getContactsListPerPage(query: string, type: string, removeAddNewRecordLabel?: boolean, page?: number, perPage?: number): Observable<any[]> {
    return this.getContactsList(query, type, removeAddNewRecordLabel, null, null, null, null, page, perPage);
  }

  // Get contact list
  getContactsList(query: string, type: string, removeAddNewRecordLabel?: boolean, contactStatusSearch?: string, accessScopeSearch?: string,
                  provinceSearch?: string[], sortQuery?: string, page?: number, perPage?: number, sortingType?: string, contactType?: string,
                  categorySearch?: string[], addMortgagesRegisteredFlag?: boolean, provinceCode?: string, genderSearch?: string[],
                  includeGlobalFlag?: boolean //includeGlobalFlag is currently only implemented for CONDO_CORPORATIONS
  ): Observable<any[]> {

    // TODO: use https://angular.io/docs/ts/latest/api/http/index/URLSearchParams-class.html to build query string. Doesn't work in current 2.0.0 version.
    let url: string;
    let filterKey: string;

    //Backend need do escape for contact search
    let urlQuery = query;
    let filter: string = '';
    let sort: string = '';
    let sortType: string = '';
    urlQuery = Utils.escapeSearchText(urlQuery);
    let addressHashPart: string = this.removeSpecialCharactersFromAddressSearch(query);
    let replacedAddressHashPart: string = addressHashPart && addressHashPart.length > 0 ? Utils.escapeSearchText(addressHashPart) : '';

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    switch (type) {
      case 'MORTGAGEE_MATTER_TAB': //MORTGAGEE search from matter tab
      case 'PRIVATE_LENDER_MATTER_TAB' : //PRIVATE_LENDER search from matter tab
        let mortgageeFilter = type === 'MORTGAGEE_MATTER_TAB' ? 'true' : 'false';
        let qr = urlQuery && urlQuery.length > 0 ? urlQuery : '';
        url = `${ matterApi.lendersContactList(accountId) }?filter=${ urlQuery }&mortgagee=${ mortgageeFilter }&active_only=true`;
        if (addMortgagesRegisteredFlag) {
          url += '&includeOnlyRegisteredMortgagees=' + addMortgagesRegisteredFlag;
        }
        // override values for default contact search
        perPage = 40;

        break;
      case 'MORTGAGEE': //MORTGAGEE search from contact tab
        sort = sortQuery ? sortQuery : 'organizationName,alternateName,lenderContactSearchView.listSearchLenderAddress';
        sortType = sortingType ? sortingType : 'ASC';

        //Creating filter for mortgagee search. The text is searched in one of the three fields Organization name or alter name or mailing address.
        //Address hash field contains the concatenated address and as we are searching only in mailing address therefore we are concatenating
        // MAILING in address filter at the end (address.addressHash_EQ_*'+urlQuery+'*MAILING)
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*|alternateName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|lenderContactSearchView.listSearchLenderAddress_EQ_*' + replacedAddressHashPart + '*';
        }
        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        filter = this.appendMortgageRegisteredFilter(filter, addMortgagesRegisteredFlag);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }&filterType=ALL`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;
        filterKey = 'organizationName';
        break;
      case 'MANAGEMENT_COMPANY':
        sort = sortQuery ? sortQuery : 'organizationName,alternateName,contactMailingAddress.addressHash';
        sortType = sortingType ? sortingType : 'ASC';

        //Creating filter for mortgagee search. The text is searched in one of the three fields Organization name or alter name or mailing address.
        //Address hash field contains the concatenated address and as we are searching only in mailing address therefore we are concatenating
        // MAILING in address filter at the end (address.addressHash_EQ_*'+urlQuery+'*MAILING)
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*|alternateName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }
        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }&filterType=ALL`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;
        filterKey = 'organizationName';
        break;
      case 'SURVEYOR':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'organizationName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }
        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }&filterType=ALL`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;
        filterKey = 'organizationName';
        break;
      case 'MORTGAGE_BROKER':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'organizationName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*|subContacts.contactSearchView.lastName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }
        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'organizationName';
        break;
      case 'RESIDENCE_ASSOCIATION':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'organizationName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*|subContacts.contactSearchView.lastName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }
        // filter = this.appendProvinceFilter(filter, provinceSearch);
        // filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'organizationName';
        break;
      case 'REALESTATEBROKER':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'organizationName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }
        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'organizationName';
        break;
      case 'LAW_FIRM':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'legalFirmName';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYlegalFirmName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'lastName';
        break;
      case 'SOLICITOR':

        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'contactName.lastName';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'lastName';
        break;
      case 'REALESTATEAGENT':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'contactName.lastName,email,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_' + urlQuery + '*|email_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filterIgnoreCase=true&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'lastName';
        break;
      case 'CLIENT':
        sort = sortQuery ? sortQuery : 'displayName';
        sortType = sortingType ? sortingType : 'ASC';
        filter = urlQuery && urlQuery.length > 0 ? 'displayName_EQ_*' + urlQuery + '*' : '';
        filter = this.appendGenderFilter(filter, genderSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        filter = this.appendCategoryFilter(filter, categorySearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filterIgnoreCase=true&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;
        filterKey = 'lastName';
        break;
      case 'PRIVATE_LENDER' : //PRIVATE_LENDER search from contact tab
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'displayName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filterIgnoreCase=true&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;
        filterKey = 'lastName';
        break;
      case 'PRIVATE_CONTACT':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'displayName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filterIgnoreCase=true&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;
        filterKey = 'lastName';
        break;
      case 'MORTGAGE_BROKER_ATTENTION':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'displayName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYdisplayName_EQ_' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }
        filter += `${ (filter && filter.length > 0) ? ',' : '' }organization.contactType_EQ_` + Utils.escapeSearchText('MORTGAGE_BROKER');
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=PRIVATE_CONTACT&sort=${ sort }&filterIgnoreCase=true&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=PRIVATE_CONTACT&sort=${ sort }`;
        filterKey = 'lastName';
        break;
      case 'INSURANCE_BROKER':
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'organizationName,contactMailingAddress.addressHash';

        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendInsurerTypeFilter(filter, contactType);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }`;

        filterKey = 'organizationName';
        break;
      // case 'OTHER_PARTY':
      //     sortType = sortingType ? sortingType : 'ASC';
      //     sort = sortQuery ? sortQuery : 'displayName';
      //     filter = urlQuery && urlQuery.length > 0 ? 'displayName_EQ_'+urlQuery+'*' : '';
      //     filter = this.appendContactStatusFilter(filter, contactStatusSearch);
      //     console.log(filter);
      //     sort = Utils.parseSort(sort,sortType);
      //     url = filter && filter.length > 0 ?
      //           `${matterApi.contactsList(accountId)}?contactType=OTHER_PARTY&sort=${sort}&filterType=ALL&filterIgnoreCase=true&filter=${filter}`
      //         : `${matterApi.contactsList(accountId)}?contactType=OTHER_PARTY&sort=${sort}`;
      //     filterKey = 'lastName';
      //     break;
      case 'VENDOR' :
        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'displayName';
        filter = urlQuery && urlQuery.length > 0 ? 'displayName_EQ_' + urlQuery + '*' : '';
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        url = filter && filter.length > 0 ?
          `${ matterApi.vendor }?sort=${ sort }&filterIgnoreCase=true&filter=${ filter }`
          : `${ matterApi.vendor }?sort=${ sort }`;
        filterKey = 'lastName';
        break;
      case 'CONDO_CORPORATION':

        sortType = sortingType ? sortingType : 'ASC';
        sort = sortQuery ? sortQuery : 'organizationName,contactMailingAddress.addressHash';
        filter = urlQuery && urlQuery.length > 0 ? 'ANYorganizationName_EQ_*' + urlQuery + '*|abbreviatedName_EQ_*' + urlQuery + '*' : '';
        if (replacedAddressHashPart) {
          filter += '|contactMailingAddress.listSearchAddressHash_EQ_mailing:*' + replacedAddressHashPart + '*';
        }

        filter = this.appendProvinceFilter(filter, provinceSearch);
        filter = this.appendContactStatusFilter(filter, contactStatusSearch);
        sort = Utils.parseSort(sort, sortType);
        includeGlobalFlag = includeGlobalFlag === undefined ? false : includeGlobalFlag;
        url = filter && filter.length > 0 ?
          `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&includeGlobal=${ includeGlobalFlag }&filter=${ filter }`
          : `${ matterApi.contactsList(accountId) }?contactType=${ type }&sort=${ sort }&includeGlobal=${ includeGlobalFlag }`;

        filterKey = 'organizationName';
        break;
      default:
        url = `${ matterApi.contactsList(accountId) }?contactType=${ type }`;
        filterKey = undefined;
    }

    if (page) {
      url = url + `&page=${ page }&per_page=${ perPage }`;
    }

    return this.http.get(url)
    .map((res) => {

      // let queryLowerCase: string = query.toLowerCase();
      let sorted: any[] = [];
      if (type.toUpperCase() === 'REALESTATEBROKER'
        || type.toUpperCase() === 'MORTGAGE_BROKER'
        || type.toUpperCase() === 'RESIDENCE_ASSOCIATION'
        || type.toUpperCase() === 'MORTGAGEE'
        || type.toUpperCase() === 'MORTGAGEE_MATTER_TAB'
        || type.toUpperCase() === 'SURVEYOR'
        || type.toUpperCase() === 'INSURANCE_BROKER'
        || type.toUpperCase() === 'MANAGEMENT_COMPANY'
        || type.toUpperCase() === 'CONDO_CORPORATION') {

        let data = res[ matterResponseKey.contacts ];
        let modified = [];
        data.forEach(item => {
          // DPPMP-2439
          // 1. Organization itself with no subContacts
          let organizationWithoutPerson = _.cloneDeep(item);
          //Don't remove subContacts from organization as that is used to show the attentions on contact list tab.
          //ToDo: Need to figure out how should we handle the case of omni-search where selected organization should not have subContacts
          //organizationWithoutPerson.subContacts = [];
          modified.push(new Contact(organizationWithoutPerson));

        }, this);

        let addNewRecordKey: string = 'displayName';

        if (type === 'CONDO_CORPORATION' || type === 'MANAGEMENT_COMPANY') {
          //In case of CONDO_CORPORATION or MANAGEMENT_COMPANY Add new record is displayed in organizationName field
          addNewRecordKey = 'organizationName';
        }

        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};

          //In case of mortgagee Add new record is displayed without entered name
          if (type === 'MORTGAGEE' || type === 'MORTGAGEE_MATTER_TAB') {
            addNewRecordData[ addNewRecordKey ] = Constants.ADD_NEW_RECORD_MORTGAGEE;
          } else {
            addNewRecordData[ addNewRecordKey ] = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          }

          addNewRecordData.typedName = `${ query.trim() }`;
          modified.unshift(addNewRecordData);
        }
        if (query === Constants.MORTGAGE_BROKER) {
          return modified;
        } else if (modified.length === 0 || (modified.length === 1 &&
          (modified[ 0 ][ addNewRecordKey ].indexOf(Constants.ADD_NEW_RECORD) > -1 || modified[ 0 ][ addNewRecordKey ].indexOf(Constants.ADD_NEW_RECORD_MORTGAGEE) > -1))) {
          return this.noRecordFoundOrganization(modified, filterKey);
        } else {
          return modified;
        }

      } else if (type.toUpperCase() === 'CLIENT'
        || type.toUpperCase() === 'REALESTATEAGENT'
        // || type.toUpperCase() === "LAW_FIRM"
        || type.toUpperCase() === 'SOLICITOR'
        || type.toUpperCase() === 'PRIVATE_CONTACT'
        || type.toUpperCase() === 'MORTGAGE_BROKER_ATTENTION'
      ) {
        let data = res[ matterResponseKey.contacts ];
        let modified = [];
        data.forEach(item => {
          modified.push(new Contact(item));
        }, this);
        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          addNewRecordData.typedName = `${ query.trim() }`;
          modified.unshift(addNewRecordData);
        }
        if (query === Constants.VENDOR) {
          return modified;
        } else if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          return this.noRecordFound(modified, Constants.DISPLAYNANME);
        } else {
          return modified;
        }

      } else if (type.toUpperCase() === 'VENDOR'
        || type.toUpperCase() === 'PRIVATE_LENDER_MATTER_TAB'
        || type.toUpperCase() === 'PRIVATE_LENDER'
        || type.toUpperCase() === 'LAW_FIRM') {
        let data = res[ matterResponseKey.contacts ];
        let modified = [];
        data.forEach(item => {
          modified.push(new Contact(item));
        }, this);
        if (!removeAddNewRecordLabel) {
          let addNewRecordData: any = {};
          addNewRecordData.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
          addNewRecordData.typedName = `${ query.trim() }`;
          modified.unshift(addNewRecordData);
        }
        if (modified.length === 0 || (modified.length === 1 && modified[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
          modified.push(this.createNewRecordEntry(query, 'none'));
          return modified;
        } else {
          return modified;
        }

      }

      if (sorted.length > 15) {
        sorted = sorted.slice(0, 15);
      }
      return sorted;
    });
  }

  createNewRecordEntry(query: string, type: string): any {
    let dummyPurchaser: any = {};
    if (type === 'none') {
      dummyPurchaser.displayName = Constants.NO_RESULTS_FOUND;
    } else {
      dummyPurchaser.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
      dummyPurchaser.typedName = `${ query.trim() }`;
    }
    return dummyPurchaser;
  }

  appendProvinceFilter(filter: string, provinceSearch?: string[]): string {
    let provinceFilters: string = '';
    if (Array.isArray(provinceSearch)) {
      for (let i = 0; i < provinceSearch.length; i++) {

        if (provinceSearch[ i ] != 'ALL') {
          if (i == 0) {
            provinceFilters = 'contactMailingAddress.provinceCode_IN_';
          }
          if (i == (provinceSearch.length - 1)) {
            provinceFilters += provinceSearch[ i ];
          } else {
            provinceFilters += provinceSearch[ i ] + '!';
          }

        }
      }
    }

    if (provinceFilters.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + provinceFilters;
      } else {
        filter += provinceFilters;
      }
    }

    return filter;
  }

  appendCategoryFilter(filter: string, categorySearch?: string[]): string {
    let categoryFilters: string = '';
    let fixedCategoryFilter: string = '';
    let customCategoryFilter: string = '';
    if (Array.isArray(categorySearch)) {
      for (let i = 0; i < categorySearch.length; i++) {
        let selectedCategory: string = categorySearch[ i ];
        if (selectedCategory !== 'ALL') {

          if (contactDropDowns.categoryFilter.find(item => item.value == selectedCategory)) {
            fixedCategoryFilter += Utils.escapeSearchText(selectedCategory) + '!';
          } else {
            customCategoryFilter += Utils.escapeSearchText(selectedCategory) + '!';
          }
        }
      }

      if (fixedCategoryFilter.length > 0 && customCategoryFilter.length > 0) {
        fixedCategoryFilter = fixedCategoryFilter.substring(0, fixedCategoryFilter.length - 1);
        customCategoryFilter = customCategoryFilter.substring(0, customCategoryFilter.length - 1);
        categoryFilters = 'ANYcontactCategory_IN_' + fixedCategoryFilter + '|otherCategoryName_IN_' + customCategoryFilter;
      } else if (fixedCategoryFilter.length > 0) {
        fixedCategoryFilter = fixedCategoryFilter.substring(0, fixedCategoryFilter.length - 1);
        categoryFilters = 'contactCategory_IN_' + fixedCategoryFilter;
      } else if (customCategoryFilter.length > 0) {
        customCategoryFilter = customCategoryFilter.substring(0, customCategoryFilter.length - 1);
        categoryFilters = 'otherCategoryName_IN_' + customCategoryFilter;
      }
    }

    if (categoryFilters.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + categoryFilters;
      } else {
        filter += categoryFilters;
      }
    }

    return filter;
  }

  appendGenderFilter(filter: string, genderSearch?: string[]): string {
    let genderFilters: string = '';
    if (Array.isArray(genderSearch) && genderSearch.length > 0) {
      let selectedGender: string = genderSearch.join('!');
      genderFilters = 'gender_IN_' + selectedGender;

    }

    if (genderFilters.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + genderFilters;
      } else {
        filter += genderFilters;
      }
    }

    return filter;
  }

  appendInsurerTypeFilter(filter: string, insurerBrokerQuery: string): string {
    let insurerTypeFilter: string = '';

    if (insurerBrokerQuery && insurerBrokerQuery.length > 0) {
      switch (insurerBrokerQuery.toUpperCase()) {
        case 'QUESTION':
          break;
        case 'INSURER':
          insurerTypeFilter = 'insuranceBrokerType_EQ_INSURER';
          break;
        case 'BROKER':
          insurerTypeFilter = 'insuranceBrokerType_EQ_BROKER';
          break;
        default :
          break;
      }
    }
    if (insurerTypeFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + insurerTypeFilter;
      } else {
        filter += insurerTypeFilter;
      }
    }
    return filter;
  }

  appendAccessScopeFilter(filter: string, privateGlobalQuery: string): string {
    let accessScopeFilter: string = '';

    if (privateGlobalQuery && privateGlobalQuery.length > 0) {
      switch (privateGlobalQuery.toUpperCase()) {
        case 'PRIVATE_GLOBAL':
          break;
        case 'GLOBAL':
          accessScopeFilter = 'contactSearchView.ownedByCustomer_EQ_false';
          break;
        case 'PRIVATE':
          accessScopeFilter = 'contactSearchView.ownedByCustomer_EQ_true';
          break;
        default :
          break;
      }
    }

    if (accessScopeFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + accessScopeFilter;
      } else {
        filter += accessScopeFilter;
      }
    }

    return filter;
  }

  appendMortgageRegisteredFilter(filter: string, mortgagesRegistered: boolean): string {
    let mortgagesRegisteredFilter: string = '';
    if (mortgagesRegistered) {
      mortgagesRegisteredFilter = 'lenderContactSearchView.mortgagesRegistered_EQ_' + mortgagesRegistered;
    }

    if (mortgagesRegisteredFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + mortgagesRegisteredFilter;
      } else {
        filter += mortgagesRegisteredFilter;
      }
    }

    return filter;
  }

  appendProvinceCodeFilter(filter: string, provinceCode: string): string {
    let provinceCodeFilter: string = '';
    if (provinceCode) {
      provinceCodeFilter = 'address.provinceCode_IN_' + provinceCode;
    }

    if (provinceCodeFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + provinceCodeFilter;
      } else {
        filter += provinceCodeFilter;
      }
    }

    return filter;
  }

  appendContactStatusFilter(filter: string, clientStatusQuery: string): string {
    let clientStatusFilter: string = '';
    if (clientStatusQuery && clientStatusQuery.length > 0) {
      switch (clientStatusQuery.toUpperCase()) {
        case 'ALL':
          break;
        case 'ACTIVE':
          clientStatusFilter += 'activeFlag_IN_' + Utils.escapeSearchText(`Y_n`) + '!YES';
          break;
        case 'INACTIVE':
          clientStatusFilter += 'activeFlag_IN_' + Utils.escapeSearchText(`N_y`) + '!NO';
          break;
        default :
          break;
      }
    }

    if (clientStatusFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + clientStatusFilter;
      } else {
        filter += clientStatusFilter;
      }
    }

    return filter;
  }

  // last name could have two names split using space
  // so we need to check for both the names
  searchLastNameBroker(name, queryLowerCase) {
    let result: boolean = false;
    let nameSpaceArray: string[] = name.split(' ');
    for (let i = 0; i < nameSpaceArray.length; i = i + 1) {
      if (nameSpaceArray[ i ].toLowerCase().indexOf(queryLowerCase) === 0) {
        return true;
      }
    }
    let nameDashArray: string[] = name.split('-');
    for (let i = 0; i < nameDashArray.length; i = i + 1) {
      if (nameDashArray[ i ].toLowerCase().indexOf(queryLowerCase) === 0) {
        return true;
      }
    }
    return result;
  }

  // No record found item for LawClerk and solicitor
  noRecordFound(data, filterKey) {
    let person: any = {};
    let contactName = {};
    contactName[ filterKey ] = Constants.NO_RESULTS_FOUND;
    person.contactName = contactName;
    person.noResultFoundFlag = true;
    data.push(person);
    return data;
  }

  // No record found item for realEstateBroker and surveyors
  noRecordFoundOrganization(data, filterKey) {
    let person: any = {};
    person[ filterKey ] = Constants.NO_RESULTS_FOUND;
    data.push(person);
    return data;
  }

  unlockContact(id: number): Observable<Contact> {
    return this.http.get(`${ matterApi.contacts }/${ id }/unlock?fullUnlock=false`)
    .map((res) => {
      return new Contact(res[ 'Contact' ]);
    });
  }

  // As required by getContactLockStatus, we need pass an array of ids which include parents id and all subContacts' id
  // Backend responds an array of LockStatus
  getContactLockStatus(id: number[]): Observable<LockStatus[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.post(`${ matterApi.contactsLockStatus(accountId) }`, id, true)
    .map((res) => {
      const data: any[] = res[ 'ContactLockStatus' ];
      let contactLockStatusList: LockStatus[] = [];
      if (Array.isArray(data)) {
        contactLockStatusList = data.map((val: Contact) => {
          let lockStatus: LockStatus = {id: val.id, locked: val.locked};
          return lockStatus;
        });
      }
      return contactLockStatusList;
    });
  }

  lockContact(id: number, lastUpdatedTimeStamp?: number): Observable<boolean> {
    if (id < 0) {
      return Observable.of(null);
    }

    let url: string = `${ matterApi.lockContact(String(id)) }`;
    if (lastUpdatedTimeStamp) {
      url += '?lastUpdatedTimestamp=' + lastUpdatedTimeStamp;
    }

    return this.http.put(url, id)
    .map((res) => {
      return res[ 'ContactLockStatus' ];
    });
  }

  //This method should be used when a parent contact is added into another contact. If the parent contact is global then it creates it's proxy else return
  // the same contact.
  getContactForReference(sourceContact: Contact): Observable<Contact> {
    if (sourceContact.privateFlag) {
      return Observable.of(sourceContact);
    } else {
      return this.http.get(`${ matterApi.proxyForGlobal }/${ sourceContact.id }`)
      .map((res) => {
        return new Contact(res[ 'Contact' ]);
      });
    }
  }

  getSolicitorsForLawFirm(legalFirmId: number, onlyActiveContactRequired?: boolean) {
    let url: string;
    if (onlyActiveContactRequired) {
      url = `${ matterApi.contacts }/${ legalFirmId }/solicitors?sort=contactName.lastName|ASC&filter=contactType_EQ_SOLICITOR|activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;
    } else {
      url = `${ matterApi.contacts }/${ legalFirmId }/solicitors?sort=contactName.lastName|ASC&filter=contactType_EQ_SOLICITOR`;
    }

    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Solicitors' ];
      let contacts: Contact[] = [];
      data.forEach(val => {
        contacts.push(new Contact(val));
      });
      return contacts;
    });
  }

  getLawClerksForLawFirm(legalFirmId: number, onlyActiveContactRequired?: boolean) {
    let url: string;
    if (onlyActiveContactRequired) {
      url = `${ matterApi.contacts }/${ legalFirmId }/solicitors?sort=contactName.firstName|ASC&filter=contactType_EQ_LAWCLERK|activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;
    } else {
      url = `${ matterApi.contacts }/${ legalFirmId }/solicitors?sort=contactName.firstName|ASC&filter=contactType_EQ_LAWCLERK`;
    }

    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Solicitors' ];
      let contacts: Contact[] = [];
      data.forEach(val => {
        contacts.push(new Contact(val));
      });
      return contacts;
    });
  }

  /**
   *  It is only gor other side law clerk
   * @param legalFirmId
   * @param onlyActiveContactRequired
   * @returns {Observable<R>}
   */
  getOtherSideLawClerksForLawFirm(legalFirmId: number, onlyActiveContactRequired?: boolean) {
    let url: string;
    if (onlyActiveContactRequired) {
      url = `${ matterApi.contacts }/${ legalFirmId }/clerks?sort=contactName.firstName|ASC&filter=activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;
    } else {
      url = `${ matterApi.contacts }/${ legalFirmId }/clerks?sort=contactName.firstName|ASC`;
    }

    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Solicitors' ];
      let contacts: Contact[] = [];
      data.forEach(val => {
        contacts.push(new Contact(val));
      });
      return contacts;
    });
  }

  getLendingInstitutions(lockScreen: boolean = false): Observable<LendingInstitution[]> {
// returns list of institutions
    return this.http.get(`${ matterApi.lenders }`, lockScreen)
    .map((res) => {
      let data: any[] = res[ 'Lenders' ];
      let lenders: LendingInstitution[] = [];
      data.forEach(val => {
        lenders.push(new LendingInstitution(val));
      });
      this.cachedLendingInstitutionList = lenders;
      return lenders;
    });
  }

  getCachedLendingInstitutions(): Observable<LendingInstitution[]> {
    if (this.cachedLendingInstitutionList.length > 0) {
      return Observable.of(this.cachedLendingInstitutionList);
    } else {
      return this.getLendingInstitutions(true);
    }
  }

  clearLendingInstitutionsCache() {
    this.cachedLendingInstitutionList = [];
  }

  chooseLenderInstitution(lenderInstitutions: LendingInstitution[], participantContact: Contact): void {
    if (lenderInstitutions && lenderInstitutions.length == 1) {
      this.linkMortgageeWithLender(participantContact, lenderInstitutions[ 0 ]);
    } else if (lenderInstitutions && lenderInstitutions.length > 1) {
      if (lenderInstitutions.every(lender => lender.lenderName == lenderInstitutions[ 0 ].lenderName)) {
        let selectedLender = lenderInstitutions.every(lender => Number(lender.institutionNumber) == Number(lenderInstitutions[ 0 ].institutionNumber)) ?
          lenderInstitutions[ 0 ] : this.findLenderWithLowestInstitutionNumber(lenderInstitutions);
        this.linkMortgageeWithLender(participantContact, selectedLender);
      } else {
        this.removeMortgageeLenderLink(participantContact, 'MULTIPLE_MATCHED');
      }
    } else {
      this.removeMortgageeLenderLink(participantContact, 'NOT_MATCHED');
    }
  }

  searchInstitutionByLenderKeywords(institutionName: string): Observable<LendingInstitution[]> {
    let url = matterApi.searchInstitutionByLenderKeywords(institutionName);
    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Lenders' ];
      let lenders: LendingInstitution[] = [];
      data.forEach(val => {
        lenders.push(new LendingInstitution(val));
      });
      return lenders;
    });
  }

  saveLendingInstitution(lender: LendingInstitution): Observable<LendingInstitution> {

    let url: string;
    !lender.id ? url = matterApi.createLender : url = matterApi.postLender.replace('{id}', lender.id.toString());
    return this.http.post(url, JSON.stringify(lender))
    .map((res) => {
      return new LendingInstitution(res[ 'Lender' ]);
    });
  }

  getLendingInstitution(id: number): Observable<LendingInstitution> {
    /// returns institution by id
    let filter: string;
    let url: string;
    filter = '&filter=id_EQ_' + id;
    url = `${ matterApi.lenders }?${ filter }&filterIgnoreCase=false`;
    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Lenders' ];
      let lenders: LendingInstitution[] = [];
      data.forEach(val => {
        lenders.push(new LendingInstitution(val));
      });
      if (lenders && lenders.length > 0) {
        return lenders[ 0 ];
      } else {
        return new LendingInstitution();
      }
    });
  }

  filterLendingInstitution(searchText: string) {
    let filter: string;
    let url: string;
    filter = 'institutionName_EQ_*' + searchText + '*|alternateName_EQ_*' + searchText + '*';
    url = `${ matterApi.lenders }?filter=${ filter }&filterType=ANY`;
    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Lenders' ];
      let lenders: LendingInstitution[] = [];
      data.forEach(val => {
        lenders.push(new LendingInstitution(val));
      });
      return lenders;
    });
  }

  getContact() {
    return this._contact;
  }

  setContact(contact: Contact) {
    this._contactSource.next(contact);
  }

  removeSpecialCharactersFromAddressSearch(text: string): string {
    try {
      let removeUrlEncoding: string = decodeURIComponent(text);
      return removeUrlEncoding.replace(/[^A-Za-z0-9!?&]/g, '');
    } catch (e) {
      //decodeUIComponent gives URIError if input is only %  since % is a escape character, it can't be on its own.
      return text.replace(/[^A-Za-z0-9!?&]/g, '');
    }
  }

  cleanNoResultsFound(data: any): void {
    if (data && Array.isArray(data)) {
      if (data.length == 1 && JSON.stringify(data[ 0 ]).indexOf(Constants.NO_RESULTS_FOUND) > -1) {
        data.shift();
      }
    }
  }

  revertProxyToGlobal(id: number): Observable<Contact> {
    return this.http.get(`${ matterApi.contacts }/${ id }/revertToGlobal`)
    .map((res) => {
      return new Contact(res[ 'Contact' ]);
    });

  }

  getDirectDeposit(institutionNo: string, transitNo: string, type: string): Observable<any[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string;
    let filter: string;

    filter = 'depositsAccepted_EQ_true,institutionNo_EQ_' + institutionNo + ',transitNo_EQ_' + transitNo;

    url = `${ matterApi.contactsList(accountId) }?contactType=${ type }&filter=${ filter }&filterType=ALL`;
    return this.http.get(url)
    .map((res) => {
      let data = res[ matterResponseKey.contacts ];
      let banks = [];
      data.forEach(item => {
        let organization = _.cloneDeep(item);
        banks.push(new Contact(organization));
      });

      return banks;
    });
  }

  getActiveMortgagee(lenderInstitutionId: string, transitNo: string): Observable<any[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string;
    let filter: string;

    filter = 'lenderInstitution.id_EQ_' + lenderInstitutionId + ',transitNo_EQ_' + transitNo;
    filter = this.appendContactStatusFilter(filter, 'ACTIVE');

    url = `${ matterApi.contactsList(accountId) }?contactType=MORTGAGEE&filter=${ filter }&filterType=ALL`;
    return this.http.get(url)
    .map((res) => {
      let data = res[ matterResponseKey.contacts ];
      let banks = [];
      data.forEach(item => {
        let organization = _.cloneDeep(item);
        banks.push(new Contact(organization));
      });

      return banks;
    });
  }

  getInstitutions(institutionNo: string): Observable<any[]> {
    let url: string;
    let filter: string;
    filter = 'institutionNumber_EQ_' + institutionNo;
    url = `${ matterApi.lenders }?filter=${ filter }&filterType=ALL`;

    return this.http.get(url)
    .map((res) => {
      let data: any[] = res[ 'Lenders' ];
      let lenders: LendingInstitution[] = [];
      data.forEach(val => {
        lenders.push(new LendingInstitution(val));
      });
      return lenders;
    });
  }

  //This method indicates that mortgagee is linked to lender automatically without user intervention.
  automaticallyLinkMortgageeWithLender(matterParticipant: MatterParticipant, lenderInstitution: LendingInstitution): void {
    matterParticipant.updatedLenderLinkAutomatically = true;
    this.linkMortgageeWithLender(matterParticipant.contact, lenderInstitution);
  }

  linkMortgageeWithLender(contact: Contact, lenderInstitution: LendingInstitution): void {
    if (contact && lenderInstitution) {
      contact.lenderInstitutionId = lenderInstitution.id;
      contact.alternateName = lenderInstitution.alternateName;
      contact.institutionNo = lenderInstitution.institutionNumber;
      contact.depositsAccepted = lenderInstitution.depositsAccepted;
      //Private mortgagees should always have 'Mortgages Accepted' set to True  (DPPMP-37724)
      //Even if not linked to a lender, or linked to a lender institution where 'Mortgages Accepted' flag is No.
      //Global mortgagees added via system account inherit Mortgages Accepted/Registered from the parent lender.
      if (!contact.isPrivateExcludeProxy) {
        contact.mortgagesRegistered = lenderInstitution.mortgagesRegistered;
      }
      contact.mortgageeLenderInstitutionLinkStatus = 'LINKED';
    }
  }

  removeMortgageeLenderLink(contact: Contact, status: MortgageeLenderInstitutionLinkStatus): void {
    if (contact) {
      contact.lenderInstitutionId = null;
      contact.alternateName = null;
      contact.institutionNo = null;
      contact.depositsAccepted = null;
      if (!contact.isPrivateExcludeProxy) {
        contact.mortgagesRegistered = null;
      }
      contact.mortgageeLenderInstitutionLinkStatus = status;
    }
  }

  findLenderWithLowestInstitutionNumber(lenderInstitutions: LendingInstitution[]): LendingInstitution {
    // console.log(">> In findLenderWithLowestInstitutionNumber:: try to find the Lender Institution with SMALLEST institution number;")
    if (lenderInstitutions && lenderInstitutions.length) {
      lenderInstitutions.sort((a, b) => {
        return (Number(a.institutionNumber) < Number(b.institutionNumber)) ? -1 : 1;
      });
      // console.log(">> find the lender institution %s with institution Number %s", lenderInstitutions[0].lenderName, lenderInstitutions[0].institutionNumber)
      return lenderInstitutions[ 0 ];
    }
    return null;
  }

  /**
   * This method tries to find the matching lenderInstitutions based on organization name. First it tries to lookup the organization name in list of
   * lenderInstitutions and if not found there then it calls backend for searching by keyword.
   * @param {string} organizationName
   * @param {LendingInstitution[]} lenderInstitutions - list of lenderInstitutions should be already loaded and passed into this method.
   * @returns {Observable<LendingInstitution[]>}
   */
  findMatchingLenderInstitutionsByOrganizationName(organizationName: string, lenderInstitutions: LendingInstitution[]): Observable<LendingInstitution[]> {
    if (organizationName) {
      let matchedLenderInstitutions: LendingInstitution[] = lenderInstitutions.filter(value => value.institutionName.trim().toUpperCase() == organizationName.trim().toUpperCase());
      if (matchedLenderInstitutions && matchedLenderInstitutions.length > 0) {
        return Observable.of(matchedLenderInstitutions);
      } else {
        return this.searchInstitutionByLenderKeywords(Utils.escapeSearchText(organizationName));
      }
    }
    return Observable.of([]);
  }

  //return number of mortgagee lender linkage happened if any
  async linkNotMatchedMortgageeToLender(matterParticipants: MatterParticipant[], contactQueryService: ContactQueryService): Promise<number> {
    // console.log(">> linkNotMatchedMortgageeToLender with %s matter participants", (Array.isArray(matterParticipants) ? matterParticipants.length : 0));
    let linkCount: number = 0;
    if (Array.isArray(matterParticipants) && matterParticipants.length > 0) {

      const lenders: LendingInstitution[] = await this.getLendingInstitutions(true).toPromise();
      // console.log(">> Get from Svr Side %s lenders.", Array.isArray(lenders) ? lenders.length: 0);
      if (Array.isArray(lenders) && lenders.length > 0) {

        for (let i = 0; i < matterParticipants.length; i++) {

          let contact: Contact = matterParticipants[ i ].contact;
          //DPPMP-34755 based on Terry and Dov: only do the linking if mortgagee is not linked and the company name on the snapshot matches what is on the base contact
          const sourceContact: Contact = await contactQueryService.getContactForMatter(contact.sourceContactId).toPromise();
          if (sourceContact.organizationName != contact.organizationName) {
            console.log('-- mortgage name (%s) is out of sync with updated value (%s), so skip it for re-link', contact.organizationName, sourceContact.organizationName);
            continue;
          }
          // console.log(">> Contact with orgName %s in the mortgagee with lenderLinkStatus: %s", contact && contact.organizationName, contact && contact.mortgageeLenderInstitutionLinkStatus)
          if (contact && contact.mortgageeLenderInstitutionLinkStatus == 'NOT_MATCHED' && !!contact.organizationName) {
            // console.log(">> Start matching process with %s lenders ...", lenders && lenders.length);
            if (Array.isArray(lenders) && lenders.length > 0) {
              let matchedLenderInstitutions: LendingInstitution[] = await this.findMatchingLenderInstitutionsByOrganizationName(contact.organizationName, lenders).toPromise();
              if (Array.isArray(matchedLenderInstitutions) && matchedLenderInstitutions.length > 0) {
                // console.log(">> Found %s matched LenderInstitution", matchedLenderInstitutions && matchedLenderInstitutions.length);
                if (matchedLenderInstitutions.length == 1) {
                  // console.log(">> Only One Lender found, link this lender to Mortgagee, matter is updated.")
                  this.automaticallyLinkMortgageeWithLender(matterParticipants[ i ], matchedLenderInstitutions[ 0 ]);
                  linkCount++;
                } else if (matchedLenderInstitutions.length > 1) {
                  // console.log(">> there are multi-lenders found (%s), need to do further ...", matchedLenderInstitutions.length);
                  if (matchedLenderInstitutions.every(lender => lender.lenderName == matchedLenderInstitutions[ 0 ].lenderName)) {
                    // console.log(">> multi-lenders share the name lender name, next step find the lender with smallest institution number if they are not use the same institution number.");
                    let selectedLender = matchedLenderInstitutions.every(lender => Number(lender.institutionNumber) == Number(matchedLenderInstitutions[ 0 ].institutionNumber)) ?
                      //if returned multi-lenders have the same instituionNumber then choose anyone, otherwise pick the one with lowest institutionNumber
                      matchedLenderInstitutions[ 0 ] : this.findLenderWithLowestInstitutionNumber(matchedLenderInstitutions);
                    this.automaticallyLinkMortgageeWithLender(matterParticipants[ i ], selectedLender);
                    linkCount++;
                  }
                  // console.log(">> multi-lenders NOT share the name lender name, won't try to do the linking, return with matter not changed.");
                }
              }
            }
          }
        }
      }
    }
    return Promise.resolve(linkCount);
  }

  duplicateOwnerSearch(ownerData: OwnerData, givenNames: string[]): Observable<any[]> {
    if (!ownerData) {
      return Observable.of([]);
    }
    if (ownerData.isContactCorporation) {
      return this.duplicateOwnerSearchCorporation(givenNames[ 0 ]);
    } else {
      return this.duplicateOwnerSearchPerson(givenNames);
    }
  }

  /**
   * For BC we would like to check for both corporations and persons for duplicates, regardless of the format of the name
   * @param ownerData
   * @param givenNames
   */
  duplicateOwnerSearchBC(ownerData: OwnerData, givenNames: string[]): Observable<any[]> {
    if (!ownerData) {
      return Observable.of([]);
    }

    let contactObservables: Observable<any>[] = [];
    contactObservables.push(this.duplicateOwnerSearchCorporation(ownerData.parcelRegisterName));
    contactObservables.push(this.duplicateOwnerSearchPerson(givenNames));

    return Observable.forkJoin(contactObservables).pipe(
      map(([ array1, array2 ]) => [ ...array1, ...array2 ])
    );
  }

  duplicateOwnerSearchPerson(givenNames: string[]): Observable<any[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    let filter;

    let sort = 'contactName.lastName|ASC,contactName.firstName|ASC';
    if (givenNames && givenNames.length > 0) {
      filter = 'contactName.lastName_EQ_' + Utils.escapeSearchText(givenNames[ 0 ]);
    }
    if (givenNames && givenNames.length > 1) {
      filter += ',contactName.firstName_EQ_' + Utils.escapeSearchText(givenNames[ 1 ]);
    }
    let url = `${ matterApi.contactsList(accountId) }?contactType=PERSON&sort=${ sort }&filterIgnoreCase=true&filter=${ filter },activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;

    return this.performContactSearch(url);
  }

  duplicateOwnerSearchCorporation(name: string): Observable<any[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    let sort = 'organizationName|ASC';
    let filter = 'organizationName_EQ_' + Utils.escapeSearchText(name);
    let url = `${ matterApi.contactsList(accountId) }?contactType=ORGANIZATION&sort=${ sort }&filterIgnoreCase=true&filter=${ filter },activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;

    return this.performContactSearch(url);
  }

  duplicateProspectsSearch(contact: Contact): Observable<any[]> {
    if (!contact) {
      return Observable.of([]);
    }

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url;
    let filter;
    let sort;
    if (contact.isCorporation || contact.isOtherEntity) {
      sort = 'organizationName|ASC';
      filter = 'organizationName_EQ_' + Utils.escapeSearchText(contact.organizationName);
      url = `${ matterApi.contactsList(accountId) }?contactType=ORGANIZATION&sort=${ sort }&filterIgnoreCase=true&filter=${ filter },activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;
    } else {
      sort = 'contactName.lastName|ASC,contactName.firstName|ASC';
      if (contact.contactName && contact.contactName.lastName) {
        filter = 'contactName.lastName_EQ_' + Utils.escapeSearchText(contact.contactName.lastName);
      }
      if (contact.contactName && contact.contactName.firstName) {
        filter += ',contactName.firstName_EQ_' + Utils.escapeSearchText(contact.contactName.firstName);
      }
      url = `${ matterApi.contactsList(accountId) }?contactType=PERSON&sort=${ sort }&filterIgnoreCase=true&filter=${ filter },activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;

    }

    return this.performContactSearch(url);
  }

  performContactSearch(url: string): Observable<any[]> {
    return this.http.get(url)
    .map((res) => {
      let data = res[ matterResponseKey.contacts ];
      let modified = [];
      data.forEach(item => {
        modified.push(new Contact(item));
      });
      return modified;
    });
  }

  softDeleteContact(contactId: number): Observable<boolean> {
    let url = matterApi.softDeleteContact(contactId);
    return this.http.delete(url);
  }
}
