<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" *ngIf="otherProrated" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Adjustment Type : {{adjustmentType}}</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>


            <div class="form-group">

            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Is adjustment a receivable or a payable of the property owner ?</label>
                    </div>
                    <div class="col-lg-2">
                        <select type="text"
                                id="direction"
                                name="direction"
                                [(ngModel)]="otherProrated.direction"
                                class="form-control"
                        >
                            <option *ngFor="let direction of directionTypes" [value]="direction.value">
                                {{direction.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="isOtherProrated()">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Adjustment heading</label>
                    </div>
                    <div class="col-lg-5">
                        <input type="text"
                               name="adjustmentHeading"
                               id="adjustmentHeading"
                               maxlength="38"
                               [(ngModel)]="otherProrated.heading"
                               dp-uppercase
                               trimmed
                               class="form-control"
                               dp-error-validator
                               [fieldKey]="'matter.soadj.other.prorated.heading'"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Commencement date of period to be prorated (dd/mm/yy)</label>
                    </div>
                    <div class="col-lg-4">
                        <dp-partial-date #fromDate
                                         [disableAll]="otherProrated.commencementDate == 'COMMENCING_ON_ADJUSTMENT_DATE'"
                                         (dateChange)="onDateChange($event ,'commencementDate')"
                                         [fieldKey]="'matter.soadj.other.prorated.commencement.date.empty'"
                                         dp-error-validator
                                         [inputDate]="otherProrated.commencementDate"
                        >
                        </dp-partial-date>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Expiry date of period to be prorated (dd/mm/yy)</label>
                    </div>
                    <div class="col-lg-4">
                        <dp-partial-date #fromDate
                                         [disableAll]="otherProrated.expiryDate == 'COMMENCING_ON_ADJUSTMENT_DATE' || !context.addedFlag"
                                         (dateChange)="onDateChange($event ,'expiryDate')"
                                         [fieldKey]="'matter.soadj.other.prorated.expiry.date.empty'"
                                         dp-error-validator
                                         [inputDate]="otherProrated.expiryDate"
                        >
                        </dp-partial-date>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Amount to be prorated</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="itemAdjAmtToBeProrated"
                           name="itemAdjAmtToBeProrated"
                           [(ngModel)]="otherProrated.amountToBeProrated"
                           class="form-control"
                           dp-currency
                           dp-error-validator
                           [dp-read-only]="!context.addedFlag"
                           [fieldKey]="'matter.soadj.other.prorated.amount.prorated'"
                    />
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-3 vertical-align-label">
                    <label *ngIf="otherProrated.direction == 'PAYABLE'" class="control-label">Has {{vendorLabel}} paid full amount?</label>
                    <label *ngIf="otherProrated.direction == 'RECEIVABLE'" class="control-label">Has {{vendorLabel}} received full amount?</label>

                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="vendorPaidFullAmount"
                            name="vendorPaidFullAmount"
                            [(ngModel)]="otherProrated.vendorPaidFullAmount"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>
            </div>

            <div *ngIf="!vendorHasPaidOrReceivedFullAmount" class="form-group">
                <div class="col-lg-3 vertical-align-label">
                    <label *ngIf="otherProrated.direction == 'PAYABLE'" class="control-label">{{vendorLabel}} has paid</label>
                    <label *ngIf="otherProrated.direction == 'RECEIVABLE'" class="control-label">{{vendorLabel}} has received</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="itemAdjAmtReceived"
                           name="itemAdjAmtReceived"
                           [(ngModel)]="otherProrated.amountReceived"
                           class="form-control"
                           dp-currency
                           dp-error-validator
                           [fieldKey]="'matter.soadj.other.fixed.adj.amount'"
                    />
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label" [dp-province-field-label]="'matter.soa.tenancy-current.modal.adjustFor'">Adjust for</label>
                    </div>
                    <div class="col-lg-1" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'">
                        <ng-container *ngIf="isOtherProrated()">
                            <select type="text"
                                    id="taxType"
                                    name="taxType"
                                    [(ngModel)]="otherProrated.taxType"
                                    class="form-control"
                            >
                                <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                                    {{taxType.label}}
                                </option>
                            </select>
                        </ng-container>

                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyTax"
                                name="applyTax"
                                [(ngModel)]="otherProrated.applyTax"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>

                    <ng-container *ngIf="showGstHstPercentageFields">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">{{otherProrated.taxType}} percentage is</label>
                        </div>
                        <ng-container *dpShowByProvince="'matter.soa.other-prorated-modal.hstRate'">
                            <div class="col-lg-1" *ngIf="(otherProrated.taxType == context.taxRateType)">
                                <input type="text"
                                       id="hstPercentage"
                                       name="hstPercentage"
                                       [(ngModel)]="otherProrated.hstRate"
                                       class="form-control"
                                       dp-percentage
                                />
                            </div>
                        </ng-container>
                        <div class="col-lg-1" *ngIf="(otherProrated.taxType == 'GST' )">
                            <input type="text"
                                   id="gstPercentage"
                                   name="gstPercentage"
                                   [(ngModel)]="otherProrated.gstRate"
                                   class="form-control"
                                   dp-percentage
                            />
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="form-group" *ngIf="isOtherProrated() && otherProrated.taxType == 'GST' && otherProrated.pstRate">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Adjust for PST</label>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyPst"
                                name="applyPst"
                                [(ngModel)]="otherProrated.applyPst"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>

                    <ng-container *ngIf="showPstPercentageFields">
                        <div class="col-lg-2 vertical-align-label" >
                            <label class="control-label">PST percentage</label>
                        </div>
                        <div class="col-lg-1">
                            <input type="text"
                                   id="pstPercentage"
                                   name="taxPercentage"
                                   [(ngModel)]="otherProrated.pstRate"
                                   class="form-control"
                                   dp-percentage
                            />
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </ng-container>

                </div>
            </div>

            <!-- adjustment report -->
            <div style="border-top: 1px solid #cccccc;">

                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <label class="control-label" style="font-weight : bold">{{otherProrated.heading | uppercase}}</label>
                    </div>
                </div>

                <div *ngFor="let line of details">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                        <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                    </div>
                </div>
                <div>
                    <div class="col-lg-12">
                        <label *ngIf="getCreditType() == 'VENDOR'" class="control-label col-lg-3 text-left">Credit {{vendorLabel}}</label>
                        <label *ngIf="getCreditType() == 'PURCHASER'" class="control-label col-lg-3 text-left">Credit {{purchaserLabel}}</label>

                        <label *ngIf="getCreditType() == 'VENDOR' && creditAmount >= 0" class="control-label col-lg-6">{{creditAmount| currency:CAD:'symbol'}}</label>
                        <label *ngIf="getCreditType() == 'VENDOR' && creditAmount < 0" class="control-label col-lg-6">$??????????.??</label>
                        <label *ngIf="getCreditType() == 'PURCHASER' && creditAmount >= 0" class="control-label col-lg-4">{{creditAmount| currency:CAD:'symbol'}}</label>
                        <label *ngIf="getCreditType() == 'PURCHASER' && creditAmount < 0" class="control-label col-lg-4">$??????????.??</label>

                    </div>
                </div>
            </div>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>

            </div>
            <div class="col-lg-2" *ngIf="!isHomeOwnersResidentFee() && !context.matter.isMatterProvinceNBorNS">
                <dp-checkbox fieldId="checkBoxInfoOnly">
                    <input type="checkbox"
                           id="checkBoxInfoOnly"
                           name="checkBoxInfoOnly"
                           [(ngModel)]="otherProrated.infoOnly"
                           [checked]="otherProrated.infoOnly"/>
                </dp-checkbox>

                <label class="control-label vertical-align-label display-inline" >Info Only</label>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </form>
</div>
