<div class="form-group">
    <div class="{{startDateLabelClass}} vertical-align-label">
        <label class="control-label">Start Time</label>
    </div>
    <div class="{{startDateFieldClass}}">
        <dp-partial-date #eventStartDate
                         statusBarHelp
                         [inputDate]="startDate"
                         [fullDateRequired]="fullStartDateRequired"
                         [fieldKey]="'dpDateTimePicker.startDate.'+fieldKey"
                         (dateChange)="onStartDateChange($event)">
        </dp-partial-date>
    </div>
    <div class="{{startTimeSelectClass}}">
        <select type="text"
                id="startTime"
                name="startTime"
                class="form-control editableBox"
                [dp-read-only]="allDayEvent"
                [(ngModel)]="startTime"
                (change)="startTimeChange($event)"
        >
            <option *ngFor="let time of startTimeIntervals" [value]="time">{{time}}</option>
        </select>
        <input class="timeTextBox"
               name="starttimebox"
               id="starttimebox"
               maxlength="8"
               [dp-read-only]="allDayEvent"
               [(ngModel)]="startTime"
               (change)="startTimeChange($event)"
               [fieldKey]="'dpDateTimePicker.startTime.'+fieldKey"
               dp-error-validator
        />
        <div class="allDay" *ngIf="showAllDayEvent">
            <dp-checkbox fieldId="allDayEvent">
                <input id="allDayEvent"
                       name="allDayEvent"
                       type="checkbox"
                       [(ngModel)]="allDayEvent"
                       (ngModelChange)="allDayChange($event)"
                />
            </dp-checkbox>
            <label class="control-label padding-left-10 display-inline" (click)="allDayEvent=!allDayEvent; allDayChange($event)" >All day event</label>
        </div>
    </div>
</div>
<div class="form-group">
    <div class="{{endDateLabelClass}} vertical-align-label">
        <label class="control-label">End Time</label>
    </div>
    <div class="{{endDateFieldClass}}">
        <dp-partial-date #eventEndDate
                         statusBarHelp
                         [inputDate]="endDate"
                         [fieldKey]="'dpDateTimePicker.endDate.'+fieldKey"
                         [disableAll]="!allowMultiDays || allDayEvent"
                         [fullDateRequired]="fullEndDateRequired"
                         (dateChange)="onEndDateChange($event)">
        </dp-partial-date>
    </div>
    <div class="{{endTimeSelectClass}}">
        <select type="text"
                id="endTime"
                name="endTime"
                class="form-control editableBox"
                [dp-read-only]="allDayEvent"
                (change)="endTimeChange($event)"
                [(ngModel)]="endTime" >
            <option *ngFor="let time of endTimeIntervals" [value]="time">{{time}}</option>
        </select>
        <input class="timeTextBox"
               name="endtimebox"
               id="endtimebox"
               maxlength="8"
               [dp-read-only]="allDayEvent"
               [(ngModel)]="endTime"
               [fieldKey]="'dpDateTimePicker.endTime.'+fieldKey"
               (change)="endTimeChange($event)"
               dp-error-validator
        />
    </div>
</div>
