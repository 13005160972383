<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Previous Year's Realty Taxes</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="!isPurchaserPortionOfTotalTaxesApplicable()">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Purchaser's portion of total taxes</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="purchaserPortionOfTotalTaxes"
                           name="purchaserPortionOfTotalTaxes"
                           class="form-control"
                           readonly = true
                           [(ngModel)]="soaRealtyTaxAdjustment.purchaserPortionOfTotalTaxes"
                           [max-int] = 99.999999999  [padding-zero]="true"
                           dp-percentage
                           [max-decimals] = 9
                    />
                    <button
                        *ngIf="(context.matter.isCondoCorporation || context.project.isCondo) && context.matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject" type="button"
                        id="unitLevelPlanBtn"
                        tabIndex="-1"
                        (click)="selectUnitLevelPlan()" class="button-glyph">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </div>
                <label class="control-label col-lg-1 text-left">%</label>
            </div>
        </div>
        <div class="form-group" *ngIf="isPurchaserPortionOfTotalTaxesApplicable()">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Adjustment based on percentage of sale price</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="adjustPercentage"
                            name="adjustPercentage"
                            class="form-control"
                            class="form-control inline-block margin-left-5 width-91percent"
                            [(ngModel)]="soaRealtyTaxAdjustment.adjustmentBasedOnPercentage"
                            (ngModelChange)="onChangeAdjustmentBasedOnPercentageFlag($event);calculateVendorShare()"
                    >
                        <option *ngFor="let yesNoOption of yesNoDropDown" [value]="yesNoOption.value">
                            {{yesNoOption.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">{{previousYearTotalTaxesLabel}}</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="numberOfMonthsCommonExpenses"
                           name="numberOfMonthsCommonExpenses"  [dp-read-only]= "!isPreviousYearTotalTaxesApplicable(soaRealtyTaxAdjustment.adjustmentBasedOnPercentage)"
                           (ngModelChange)="calculateVendorShare()"
                           [(ngModel)]="soaRealtyTaxAdjustment.previousYearTotalTaxes"
                           class="form-control" dp-default-currency dp-currency
                    />
                </div>

            </div>
        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <label class="control-label">
                        {{adjustmentTypeTitle}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12" >
                <div class="col-lg-4">
                    <label class="control-label">
                       {{previousYearTotalRealtyTaxLabel}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12" *ngIf="isRealtyTaxEstimated">
                <label class="control-label col-lg-4 text-left">
                    Purchaser's share for {{soaRealtyTaxAdjustment.noOfDaysLeft}} days
                </label>
               <!-- Interest at {{interestSoa.interestRate | number:'1.2-3'}} -->
            </div>
            <div class="col-lg-12" *ngIf="!isRealtyTaxEstimated">
                <label class="control-label col-lg-4 text-left">
                    Purchaser's share at {{totalCondominiumExpenses | number : '1.1-2' }} % of {{this.getFormattedCurrencyValue(this.soaRealtyTaxAdjustment.previousYearTotalTaxes)}}
                </label>

            </div>
            <div class="col-lg-12">
                <label class="control-label col-lg-4 text-left">
                    {{occupancyDateLabel}}
                </label>

                <label class="control-label col-lg-2 text-right">
                    {{getFormattedCurrencyValue(soaRealtyTaxAdjustment.creditAmount)}}
                </label>
            </div>

            <div class="col-lg-12" *ngIf="soaRealtyTaxAdjustment.noOfDaysLeft === '???'">
                <label class="control-label col-lg-3 text-left">
                    No Adjustment
                </label>
                <label class="control-label col-lg-4 text-right">
                    {{getFormattedCurrencyValue(soaRealtyTaxAdjustment.creditAmount)}}
                </label>
            </div>
            <div class="col-lg-12" *ngIf="soaRealtyTaxAdjustment.noOfDaysLeft !== '???'">
                <label class="control-label col-lg-4 text-left">
                    Credit Vendor
                </label>

                <label class="control-label col-lg-4 text-right">
                    {{getFormattedCurrencyValue(soaRealtyTaxAdjustment.creditAmount)}}
                </label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="ok()"
                        class="dp-btn keyboard-save-action">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
                <button type="button"
                        (click)="delete()"
                        *ngIf="allowDelete"
                        class="secondary-button">
                    <span>Delete</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
