import {MatterTopicKey} from '../constants';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';

export type UserDefinedInstanceType = 'CUSTOMER_ACCOUNT_UDF' | 'MATTER_UDF';

export class UserDefinedField extends BaseEntity {
  id: number;
  fieldName: string;
  fieldValue: string;
  topicName: MatterTopicKey;
  matterId: number;
  matterType: string;
  customerAccountId: number;
  userDefinedFieldOrder: number;
  sourceUDFId: number;
  instanceType: UserDefinedInstanceType;
  mortgageId: number;
  docGenCodeIndx: number;
  active: boolean;
  provinceCode: ProvinceCode;

  constructor(userDefinedField?: UserDefinedField) {
    super(userDefinedField);
    if (userDefinedField) {
      for (let prop in userDefinedField) {
        if (userDefinedField.hasOwnProperty(prop)) {
          this[ prop ] = userDefinedField[ prop ];
        }
      }
    }
  }

  isMortgageTopic(): boolean {
    return this.topicName == 'EXISTING_MORTGAGE' || this.topicName == 'MORTGAGES_MORTGAGEE' || this.topicName == 'MORTGAGES_TERMS' || this.topicName == 'MORTGAGES_REPORT';
  }

}
