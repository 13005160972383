import {Component, Inject} from '@angular/core';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {taxRateOpenFromValues} from '../../shared-main/constants';
import {PROVINCE_CODES} from '../shared/user-province';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
class TaxRatesModalContext {
  considerationTaxRateSlabs: ConsiderationTaxes[];
  matterHst: string;
  soaHst: string;
  openConfirmationBox: boolean;
  taxRateType: string;
  taxRateOpenFrom: string;
  provinceCode: string;
  selectedHstRateSlab: ConsiderationTaxes;
}

@Component({
  selector: 'dp-tax-rates',
  templateUrl: 'tax-rates.component.html'
})
export class TaxRatesComponent extends ModalComponent<TaxRatesModalContext> {
  adjustmentHst: string;
  considerationTaxRateSlab: ConsiderationTaxes;

  constructor(
    public dialog: MatDialogRef<TaxRatesComponent>,
    @Inject(MAT_DIALOG_DATA) context?: TaxRatesModalContext
  ) {
    super(dialog, context);
  }

  get taxRateSlabs(): ConsiderationTaxes[] {
    return this.context.considerationTaxRateSlabs && this.context.considerationTaxRateSlabs.length > 0
      ? this.context.considerationTaxRateSlabs
      : [];
  }

  isNotConfirmBox(): boolean {
    return !this.context.openConfirmationBox;
  }

  getTaxRateLabel(provinceHstRateSlab: ConsiderationTaxes): string {
    return provinceHstRateSlab && provinceHstRateSlab.provinceDefaultTaxRate
      ? provinceHstRateSlab.provinceDefaultTaxRate
      : this.context.taxRateType;
  }

  isProvinceNSOrNB(): boolean {
    return (
      this.context.provinceCode == PROVINCE_CODES.NEW_BRUNSWICK ||
      this.context.provinceCode == PROVINCE_CODES.NOVA_SCOTIA
    );
  }

  ngOnInit() {
    this.adjustmentHst = this.context.soaHst == undefined ? this.context.matterHst : this.context.soaHst;
    if (this.context.considerationTaxRateSlabs && this.context.selectedHstRateSlab) {
      this.considerationTaxRateSlab = this.context.considerationTaxRateSlabs.find(
        (taxRate) =>
          taxRate.hstRate == this.context.selectedHstRateSlab.hstRate &&
          taxRate.hstFederalPortion == this.context.selectedHstRateSlab.hstFederalPortion &&
          taxRate.hstProvincialPortion == this.context.selectedHstRateSlab.hstProvincialPortion
      );
    }
  }

  updateTaxRateSlab(taxRateSlab: ConsiderationTaxes): void {
    this.considerationTaxRateSlab = taxRateSlab;
  }

  updateTaxRate(taxRate: number): void {
    if (this.taxRateSlabs) {
      this.considerationTaxRateSlab = this.taxRateSlabs.find((item) => item.hstRate == taxRate);
    }
  }

  ok(): void {
    this.dialog.close(this.considerationTaxRateSlab);
  }

  close(): void {
    this.dialog.close();
  }

  isOpenFromStatementOfAdjustment(): boolean {
    return this.context.taxRateOpenFrom == taxRateOpenFromValues.statementOfAdjustment;
  }

  isOpenFromStatementOfAccount(): boolean {
    return this.context.taxRateOpenFrom == taxRateOpenFromValues.statementOfAccount;
  }

  isMatterProvinceON(): boolean {
    return this.context.provinceCode == PROVINCE_CODES.ONTARIO;
  }

  getStatementOfAccountTaxRateLabel(provinceHstRateSlab: ConsiderationTaxes): string {
    switch (this.context.provinceCode) {
      case PROVINCE_CODES.NEW_BRUNSWICK:
      case PROVINCE_CODES.NOVA_SCOTIA:
      case PROVINCE_CODES.ONTARIO:
        return `HST Rate is ${provinceHstRateSlab.hstRate}% where GST is ${provinceHstRateSlab.hstFederalPortion}% and PST is ${provinceHstRateSlab.hstProvincialPortion}%`;
      case PROVINCE_CODES.MANITOBA:
      case PROVINCE_CODES.SASKATCHEWAN:
        return `GST is ${provinceHstRateSlab.hstFederalPortion}% and PST is ${provinceHstRateSlab.hstProvincialPortion}%`;
      case PROVINCE_CODES.ALBERTA:
        return `GST is ${provinceHstRateSlab.hstFederalPortion}%`;
      default:
        return '';
    }
  }

  getStatementOfAdjustmentTaxRateLabel(provinceHstRateSlab: ConsiderationTaxes): string {
    switch (this.context.provinceCode) {
      case PROVINCE_CODES.MANITOBA:
      case PROVINCE_CODES.SASKATCHEWAN:
        return `GST is ${provinceHstRateSlab.hstFederalPortion}% and PST is ${provinceHstRateSlab.hstProvincialPortion}%`;
      default:
        return `${this.getTaxRateLabel(provinceHstRateSlab)} Rate is ${provinceHstRateSlab.hstRate}%`;
    }
  }

  getSoaConfirmBoxTaxRateLabel(provinceHstRateSlab: ConsiderationTaxes): string {
    switch (this.context.provinceCode) {
      case PROVINCE_CODES.MANITOBA:
      case PROVINCE_CODES.SASKATCHEWAN:
        return `GST is ${provinceHstRateSlab.hstFederalPortion}% and PST is ${provinceHstRateSlab.hstProvincialPortion}%`;
      default:
        return `${provinceHstRateSlab.hstRate}%`;
    }
  }
}
