<!-- modal -->
<div class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type: {{context.taxRateType}} - Other</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">


        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{context.closingDate | date: 'MMMM dd, yyyy'}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4  vertical-align-label">
                    <label class="control-label">Credit {{context.leftColumnListsCreditsTo}} or
                        {{context.rightColumnListsCreditsTo}} ?</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="creditType"
                            name="creditType"
                            (change)="creditTypeChange()"
                            class="form-control"
                            [(ngModel)]="taxOther.creditType"
                    >
                        <option *ngFor="let creditTypeItem of creditTypeDropDown" [value]="creditTypeItem.value">
                            {{creditTypeItem.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Adjustment Heading*</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="adjHeading"
                           name="adjHeading"
                           class="form-control"
                           dp-uppercase
                           dp-error-validator
                           fieldKey="soa.tax.other.heading"
                           maxlength="31"
                           [(ngModel)]="taxOther.adjustmentHeading"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Cost of {{taxOther.adjustmentHeading}}</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           style="text-align:right;"
                           id="cost"
                           name="cost"
                           dp-currency
                           dp-error-validator
                           fieldKey="soa.tax.other.cost"
                           class="form-control"
                           [(ngModel)]="taxOther.cost"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-2"></div>
                <div class="col-lg-2 vertical-align-label">
                    <select type="text" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'"
                            id="taxType"
                            name="taxType"
                            class="form-control"
                            [ngModel]="taxOther.taxType"
                            (ngModelChange)="taxTypeChange($event)">
                        <option *ngFor="let taxTypeItem of taxTypeDropDown" [value]="taxTypeItem.value">
                            {{taxTypeItem.label}}
                        </option>
                    </select>
                    <label class="control-label" [dp-province-field-label]="'matter.soa.tenancy-current.modal.percentage'">percentage is</label>
                </div>

                <div class="col-lg-2">
                    <input type="text"
                           style="text-align:right;"
                           id="percentage"
                           name="percentage"
                           class="form-control"
                           [(ngModel)]="taxOther.percentage"
                           dp-percentage
                    />
                </div>
                <div class="col-lg-1">
                    <label class="control-label text-right">%</label>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-4  vertical-align-label">
                    <label class="control-label">Charge for {{taxOther.taxType}} only or cost plus {{taxOther.taxType}}
                        ?</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="chargeType"
                            name="chargeType"
                            (change)="chargeTypeChange()"
                            class="form-control"
                            [(ngModel)]="taxOther.chargeType"
                    >
                        <option *ngFor="let chargeTypeItem of chargeTypeDropDown" [value]="chargeTypeItem.value">
                            {{chargeTypeItem.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <!--How Adjustment will look like-->

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">

            <div class="col-lg-12 form-group" *ngIf="taxOther.chargeType == 'COST_PLUS_TAX'">
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{taxOther.adjustmentHeading}}</label>
                </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="taxOther.chargeType == 'TAX_ONLY'">
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{taxOther.taxType}} ON {{taxOther.adjustmentHeading}}</label>
                </div>
            </div>

            <div class="col-lg-12 form-group" *ngIf="taxOther.chargeType == 'COST_PLUS_TAX'">
                <div class="col-lg-3">
                    <label class="control-label">Cost</label>
                </div>
                <div class="col-lg-3  text-right">
                    <label class="control-label">{{taxOther.cost | currency:CAD:'symbol'}}</label>
                </div>
            </div>

            <div class="col-lg-12 form-group" *ngIf="taxOther.chargeType == 'COST_PLUS_TAX'">
                <div class="col-lg-3">
                    <label class="control-label">{{taxOther.taxType}} calculated at {{taxOther.percentage | number:'1.2-3'}}%</label>
                </div>
                <div class="col-lg-3  text-right" >
                    <label class="control-label">{{taxOther.taxValue | currency:CAD:'symbol'}}</label>
                </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="taxOther.chargeType == 'TAX_ONLY'">
                <div class="col-lg-3">
                    <label class="control-label">Calculated at {{taxOther.percentage | number:'1.2-3'}}% of {{taxOther.cost | currency:CAD:'symbol'}}</label>
                </div>
                <div class="col-lg-3 text-right">

                </div>

            </div>

            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label">Credit {{taxOther.creditType}}</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="text-right" *ngIf="taxOther.chargeType == 'TAX_ONLY'" [ngClass]="taxOther.isVendorSelected ? 'col-lg-3' : 'col-lg-2'">
                    <label class="control-label">{{taxOther.taxValue | currency:CAD:'symbol'}}</label>
                </div>
                <div class="text-right"  *ngIf="taxOther.chargeType == 'COST_PLUS_TAX'" [ngClass]="taxOther.isVendorSelected ? 'col-lg-3' : 'col-lg-2'">
                    <label class="control-label">{{taxOther.costPlusTaxValue | currency:CAD:'symbol'}}</label>
                </div>
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="cancel()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button type="button"
                    *ngIf="allowDelete"
                    (click)="delete()"
                    class="secondary-button">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
