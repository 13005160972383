<form class="form-horizontal" id="teranetConnectForm" #teranetConnectForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-10">
                <h1>Teranet Connect &trade;: Account Balance</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="row modal-body margin-top-40">
        <div class="form-group">
            <label class="control-label col-xs-12 text-center">Your Teranet Account Balance is: {{teranetBalance  | currency:'CAD':'symbol' }}</label>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel focus-first">
                <span>OK</span>
            </button>
        </div>
    </div>
</form>
