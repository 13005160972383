import {AppMode, EnvironmentConfig, KeycloakConfig} from '../environment';

export class CaDevEnvironment implements EnvironmentConfig {
  appMode = AppMode.CA;
  tcolUrl = 'https://account-t.apicanada.com/register';
  keycloak: KeycloakConfig = {
    clientId: 'unity-spa',
    domain: 'https://sso-qa.dyedurham.dev',
    logoutRedirectUri: 'http://localhost:4200',
    realm: 'dyedurham_sso'
  };
  ltsaWebBaseUrl: string = 'https://appsuat.ltsa.ca';
  landingPageUrl: string = 'https://ingress-sso-qa.dyedurham.dev/sso-landing-page/';
  goCardlessApiUrl: string = 'https://connect-sandbox.gocardless.com/';
  goCardlessClientId: string = 'umqWZWz796Js1b_kPzT3VnzpevYiVM-Wke6V0ivPs-kOpyfaC8fJ5joeukjPOMd2';
  goCardlessAuthRedirectUri: string = '/gocardless/redirect';
}