import moment from 'moment';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import Utils from '../../shared-main/utils';
import {MortgageUtil} from '../../shared-main/mortgage-utility';

import {DpBooleanValue, DpBooleanValueTypes} from '../shared/dp-boolean';
import {Project} from '../../projects/shared/project';
import {StatementAdjustment} from './statement-adjustment';
import {PROVINCE_CODES} from '../shared/user-province';
import {Matter} from '../shared/matter';

export class InterestSoa extends BaseEntity {

  id: number;
  interestCalculationPeriod: string;
  isInterestCreditTo: string;
  itemName: string;
  principalAmount: number;
  interestRate: number;
  interestCalculatedFrom: string;
  interestCalculatedTo: string;

  sameInterestRateAsInterim: DpBooleanValue; //only used for Interest Chargeable During Occupancy Period adjustment

  constructor(interestSoa?: InterestSoa) {
    super(interestSoa);

  }

  syncWithChangedAdjustmentDate(adjustmentDate: string) {
    if (this.interestCalculationPeriod == 'COMMENCING_ON_ADJUSTMENT_DATE') {
      this.interestCalculatedFrom = adjustmentDate;
    } else if (this.interestCalculationPeriod == 'ENDING_ON_ADJUSTMENT_DATE') {
      this.interestCalculatedTo = adjustmentDate;
    }
  }

  isValidAdjustmentDate(adjustmentDate: string, matter: Matter): boolean {
    if (adjustmentDate && Utils.isValidDate(adjustmentDate)) {
      if (this.interestCalculationPeriod == 'COMMENCING_ON_ADJUSTMENT_DATE') {
        //adjustment date used as from date
        return (matter && matter.isMatterProvinceAB && matter.isPaysForDateOfClosingVendor) ?
          (adjustmentDate <= this.interestCalculatedTo) : (adjustmentDate < this.interestCalculatedTo);
      } else if (this.interestCalculationPeriod == 'ENDING_ON_ADJUSTMENT_DATE') {
        return adjustmentDate > this.interestCalculatedFrom;
      }
      return true;
    }
    return false;
  }

  isValidAdjustmentDateForDeferredMonies(adjustmentDate: string): boolean {
    return Utils.isValidDate(this.interestCalculatedFrom) && Utils.isValidDate(adjustmentDate) && this.interestCalculatedFrom < adjustmentDate;
  }

  // DPPMP-55395 : Removed check (provinceCode !== AB). Because before, only AB was going to 'else' statement calculation
  // Now that check is gone, AB, SK, MB, NS and NB matters all correctly go to 'else' statement calculation
  // Still correctly performs 'if' calculation when applicable (EX: AB project matter has Occupancy Interest)
  calculateSoAInterest(provinceCode: string, isPaysForDateOfClosingVendor?: boolean, isInterestOnDeferredMoniesDuringOccupancy?: boolean): number {
    if (isPaysForDateOfClosingVendor && (provinceCode == PROVINCE_CODES.ALBERTA && isInterestOnDeferredMoniesDuringOccupancy)) {
      return MortgageUtil.calculateInterest(this.interestCalculatedFrom, this.interestCalculatedTo, this.principalAmount, this.interestRate, null, isPaysForDateOfClosingVendor);
    } else {
      return MortgageUtil.calculateInterest(this.interestCalculatedFrom, this.interestCalculatedTo, this.principalAmount, this.interestRate);
    }
  }

  /* THIS METHOD IS DEPRICATED AS IT DID NOT CALCULATE LEAP YEAR CORRECTLY */
  oldMethodToCalculateInterest(): number {
    if (this.interestCalculatedFrom && this.interestCalculatedTo &&
      (moment(this.interestCalculatedTo, 'YYYY/MM/DD') >= moment(this.interestCalculatedFrom, 'YYYY/MM/DD'))) {
      let interest: number = 0;
      let yearOfInterestFrom = Number(this.interestCalculatedFrom.split('/')[ 0 ]);
      let numberOfYears = (moment(this.interestCalculatedTo, 'YYYY/MM/DD').diff(moment(this.interestCalculatedFrom, 'YYYY/MM/DD'), 'years'));
      if (numberOfYears < 1) {

        let numberOfDays = this.getNumberOfDays(Number(yearOfInterestFrom), numberOfYears);
        let yearOfInterest = Number(this.interestCalculatedFrom.split('/')[ 0 ]);
        let interestPerDay = this.calculateInterestPerDay(yearOfInterest, (this.interestRate / 100));
        interest = (numberOfDays * interestPerDay * this.principalAmount);
      } else {
        let numberOfDays: number = 0;

        for (let i = 0; i <= numberOfYears; i++) {

          let yearOfInterest = Number(yearOfInterestFrom) + i;
          let interestPerDay = this.calculateInterestPerDay(yearOfInterest, (this.interestRate / 100));

          numberOfDays = (i == 0 ? Number(this.getNumberOfDays(Number(yearOfInterest) + 1, numberOfYears)) :
            Number(this.getNumberOfDays(Number(yearOfInterest) + 1, numberOfYears, yearOfInterest + '/01/01')));

          interest = interest + (i == numberOfYears ? Number(numberOfDays * interestPerDay * this.principalAmount) : Number((numberOfDays + 1) * interestPerDay * this.principalAmount));

        }
      }
      return Number(interest.toFixed(2));
    } else {
      return 0;
    }

  }

  private getNumberOfDays(year: number, noOfYears: number, fromDate?: string): number {
    let dateTo = moment(this.interestCalculatedTo, 'YYYY/MM/DD');
    let dateYearEnd = moment(year + '/01/01', 'YYYY/MM/DD');
    let dateFrom = fromDate ? moment(fromDate, 'YYYY/MM/DD') : moment(this.interestCalculatedFrom, 'YYYY/MM/DD');
    if (noOfYears == 0) {
      return dateTo.diff(dateFrom, 'days');
    } else if (dateTo > dateYearEnd) {
      return moment((year - 1) + '/12/31', 'YYYY/MM/DD').diff(dateFrom, 'days');
    } else {
      return dateTo.diff(moment((year - 1) + '/01/01', 'YYYY/MM/DD'), 'days');
    }
  }

  private calculateInterestPerDay(year: number, interest: number): number {
    return interest / Utils.daysInGivenYear(year);
  }

  isSameInterestRateAsInterim(): boolean {
    return this.sameInterestRateAsInterim && this.sameInterestRateAsInterim != DpBooleanValueTypes.NO;
  }

  //negative result indicate an invalid data -- OUT OF RANGE
  calculateDateDiff(isPaysForDateOfClosingVendor: boolean, provinceCode: string, isInterestOnDeferredMoniesDuringOccupancy: boolean): number {
    if (this.interestCalculatedFrom && this.interestCalculatedTo &&
      (moment(this.interestCalculatedTo, 'YYYY/MM/DD') >= moment(this.interestCalculatedFrom, 'YYYY/MM/DD'))) {
      let numofDays: number = moment(this.interestCalculatedTo, 'YYYY/MM/DD').diff(moment(this.interestCalculatedFrom, 'YYYY/MM/DD'), 'days');
      if (isPaysForDateOfClosingVendor && provinceCode) {
        if (provinceCode == PROVINCE_CODES.ALBERTA && (isInterestOnDeferredMoniesDuringOccupancy || provinceCode != PROVINCE_CODES.ALBERTA)) {
          numofDays++;
        }
      }
      return numofDays;
    } else {
      return -1;
    }
  }

  applyMatterUpdatesOnInterestCalculationPeriod(matter: Matter): void {
    if (this.interestCalculationPeriod == 'COMMENCING_ON_ADJUSTMENT_DATE') {
      this.interestCalculatedFrom = matter.getClosingDate();
    } else if (this.interestCalculationPeriod == 'ENDING_ON_ADJUSTMENT_DATE') {
      this.interestCalculatedTo = matter.getClosingDate();
    } else if (this.interestCalculationPeriod == 'ENDING_ON_INTERIM_CLOSING_DATE') {
      if (matter && matter.isProjectSale) {
        this.interestCalculatedTo = matter.isMatterProvinceAB ? matter.occupancyDate : matter.requisitionDate;
      } else {
        this.interestCalculatedTo = moment(matter.createdTimeStamp).format('YYYY/MM/DD');
      }
    } else if (this.interestCalculationPeriod == 'FORM_INTERIM_CLOSING_TO_ADJUSTMENT_DATE') {
      if (matter && matter.isProjectSale) {
        this.interestCalculatedFrom = matter.isMatterProvinceAB ? matter.occupancyDate : matter.requisitionDate;
      } else {
        this.interestCalculatedFrom = moment(matter.createdTimeStamp).format('YYYY/MM/DD');
      }
      if (matter.adjustAsAtClosingDateFlag == 'CLOSING_DATE') {
        this.interestCalculatedTo = matter.getClosingDate();
      } else if (matter.adjustAsAtClosingDateFlag == 'OCCUPANCY_DATE') {
        this.interestCalculatedTo = matter && matter.isProjectSale && matter.isMatterProvinceAB ? matter.occupancyDate : matter.requisitionDate;
      } else {
        this.interestCalculatedTo = matter.adjustAsAtClosingDate;
      }
    } else if (this.interestCalculationPeriod == 'COMMENCING_ON_INTERIM_CLOSING_DATE') {
      if (matter && matter.isProjectSale) {
        this.interestCalculatedFrom = matter.isMatterProvinceAB ? matter.occupancyDate : matter.requisitionDate;
      } else {
        this.interestCalculatedFrom = moment(matter.createdTimeStamp).format('YYYY/MM/DD');
      }
    }
  }

  //Interest Chargeable During Occupancy Period Adjustment
  applyUpdatesBasedOnProjectSettings(matter: Matter, project: Project): void {
    this.applyMatterUpdatesOnInterestCalculationPeriod(matter);
    if (matter && project && project.projectCondo) {
      if (project.projectCondo.isOccupancyFeesCalculatedBasedOnPurchaseMonies) {
        if (this.isSameInterestRateAsInterim()) {
          if (project.projectCondo.isInterestRateChargeableOnPurchaseMoniesNo) {
            this.interestRate = matter.interestRateOnDeferredPurchaseMoniesFinal;
          } else if (project.projectCondo.isInterestRateChargeableOnPurchaseMoniesYes) {
            this.interestRate = project.projectCondo.interestRateChargeableOnPurchaseMonies;
          }
        }
        //Deferred purchase monies from Deposit Adjustment
        this.principalAmount = matter.extraDepositConfig ? matter.extraDepositConfig.deferredPurchaseMoniesAmount : 0.0;
      } else if (project.projectCondo.isOccupancyFeesCalculatedBasedOnPhantomMortgage) {
        //In project or project matter with AB, there isn't sameInterestRateAsInterim, interestRate comes from mortgageTerm.interest.
        if (project.isProjectProvinceAB) {
          this.interestRate = matter.getFirstPurchaserMortgage() ? Number(matter.getFirstPurchaserMortgage().mortgageTerm.interest) : 0;
        } else {
          if (this.isSameInterestRateAsInterim()) {
            //find first interim occupancy fee adjustment
            let interimOccupancyFeeAdjustment: StatementAdjustment = matter.interimStatementAdjustments.find(item => item.isInterimOccupancyFee());

            if (interimOccupancyFeeAdjustment && interimOccupancyFeeAdjustment.soAdjInterimOccupancyFee) {
              this.interestRate = interimOccupancyFeeAdjustment.soAdjInterimOccupancyFee.mortgageInterestPercentage;
            } else {
              this.interestRate = 0;
            }
          }
        }

        //Phantom mortgage monies on Deposit Adjustment
        this.principalAmount = matter.getFirstPurchaserMortgage() ? matter.getFirstPurchaserMortgage().mortgageTerm.principal : 0.0;
      }
    }
  }

}
