import {Component, Inject} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {condoUnitTypeDropDownOptions} from '../../../shared-main/province-based-dropdowns';
import {dropDowns, Matter} from '../../shared';
import {MatterProperty} from '../../shared/matter-property';
import * as _ from 'lodash';
import {ParcelLegalDescription} from '../../shared/parcel-legal-description';
import {ParcelTitle} from '../../shared/parcel-title';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class ParcelLegalDescriptionModalContext {
  matter: Matter;
  matterProperty: MatterProperty;
  parcelLegalDescription: ParcelLegalDescription;
}

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-parcel-legal-description-modal-content',
  templateUrl: './parcel-legal-description.modal.component.html'
})
export class ParcelLegalDescriptionModalComponent extends ModalComponent<ParcelLegalDescriptionModalContext> {
  condoUnitType: SelectItem[];
  mineralRightsOptions: SelectItem[];
  localParcelLegalDescription: ParcelLegalDescription;

  constructor(
    public dialog: MatDialogRef<ParcelLegalDescriptionModalComponent>,
    public documentProfileService: DocumentProfileService,
    @Inject(MAT_DIALOG_DATA) context?: ParcelLegalDescriptionModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.condoUnitType = condoUnitTypeDropDownOptions[this.context.matter.provinceCode];
    this.mineralRightsOptions = dropDowns.isExceptionDescriptionOptions;
    this.localParcelLegalDescription = _.cloneDeep(this.context.parcelLegalDescription);
    if (!this.localParcelLegalDescription.parcelTitles || this.localParcelLegalDescription.parcelTitles.length == 0) {
      this.addAnotherTitle();
    }
    this.loadMineralDefaults();
  }

  async loadMineralDefaults() {
    if (this.localParcelLegalDescription.mineral === undefined) {
      this.localParcelLegalDescription.mineral = false;

      const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      let documentProfile = await this.documentProfileService
        .getById(this.context.matter.documentProfileId, accountId, true, this.context.matter)
        .toPromise<DocumentProfile>();
      this.localParcelLegalDescription.excludeMineralParcels =
        documentProfile && documentProfile.miscDocumentProfile
          ? documentProfile.miscDocumentProfile.excludeMineralParcels
          : false;
    }
  }

  isCondo() {
    return this.context.matterProperty.isCondominium === 'YES';
  }

  isPurchaseOfFarmLand() {
    return this.context.matterProperty.purchaseIsOfCode === 'FARM_LAND';
  }

  addAnotherTitle() {
    if (!this.localParcelLegalDescription.parcelTitles) {
      this.localParcelLegalDescription.parcelTitles = [];
    }
    this.localParcelLegalDescription.parcelTitles.push(new ParcelTitle());
    if (this.localParcelLegalDescription.parcelTitles.length > 0) {
      this.localParcelLegalDescription.parcelTitles[
        this.localParcelLegalDescription.parcelTitles.length - 1
      ].parcelLegalDescriptionId = this.localParcelLegalDescription.id;
      this.localParcelLegalDescription.parcelTitles[
        this.localParcelLegalDescription.parcelTitles.length - 1
      ].parcelTitleValue = 0;
    }
  }

  ok(): void {
    this.dialog.close({localParcelLegalDescription: this.localParcelLegalDescription});
  }

  close(): void {
    this.dialog.close();
  }

  removeParcelTitle(parcelTitle: ParcelTitle): void {
    (<any>this.localParcelLegalDescription.parcelTitles).remove(parcelTitle);
  }
}
