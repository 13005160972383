import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../../accounts/shared/account-province';

export class MortgageDischargeConfig extends BaseEntity {

  actionToBeTakenText: string;
  mortgageDocNumberText: string;
  assignedByInstrumentNumberText: string;

  toMortgageeText: string;

  inFavourOfText: string;

  initValue(provinceCode: ProvinceCode): void {
    if (provinceCode == 'NS' || provinceCode == 'NB') {
      this.actionToBeTakenText = 'to discharge';
      this.mortgageDocNumberText = 'No.';
      this.assignedByInstrumentNumberText = 'as assigned by Instrument Number';
      this.toMortgageeText = 'to';
      this.inFavourOfText = 'in favour of';
    }
  }

  getActionToBeTakenTextOnMatter(provinceCode: ProvinceCode): string {
    const suffixText: string = '<Type of Instrument>';
    if (provinceCode == 'NS' || provinceCode == 'NB') {
      if (this.actionToBeTakenText) {
        return `${ this.actionToBeTakenText } ${ suffixText }`;
      }
      return suffixText;
    } else {
      return 'TBD';
    }

  }

  getMortgageDocNumberTextOnMatter(provinceCode: ProvinceCode): string {
    const suffixText: string = provinceCode == 'NS' ? '<Mortgage Doc. No.>' : '<Mortgage Reg No.>';
    if (provinceCode == 'NS' || provinceCode == 'NB') {
      if (this.mortgageDocNumberText) {
        return `${ this.mortgageDocNumberText } ${ suffixText }`;
      }
      return suffixText;
    } else {
      return 'TBD';
    }

  }

  getAssignedByInstrumentNumberTextOnMatter(provinceCode: ProvinceCode): string {
    const suffixText: string = provinceCode == 'NS' ? '<Mortgage Reg. No.>' : '<Assignment Reg. No.>';
    if (provinceCode == 'NS' || provinceCode == 'NB') {
      if (this.assignedByInstrumentNumberText) {
        return `${ this.assignedByInstrumentNumberText } ${ suffixText }`;
      }
      return suffixText;
    } else {
      return 'TBD';
    }
  }

  getToTextOnMatter(provinceCode: ProvinceCode): string {
    const suffixText: string = '<Mortgagee>';
    if (provinceCode == 'NB') {
      if (this.toMortgageeText) {
        return `${ this.toMortgageeText } ${ suffixText }`;
      }
      return suffixText;
    } else {
      return 'TBD';
    }
  }

  getInFavourOfTextOnMatter(provinceCode: ProvinceCode): string {
    const suffixText: string = '<Mortgagee>';
    if (provinceCode == 'NS' || provinceCode == 'NB') {
      if (this.inFavourOfText) {
        return `${ this.inFavourOfText } ${ suffixText }`;
      }
      return suffixText;
    } else {
      return 'TBD';
    }
  }

  getSampleDischargeStatement(provinceCode: ProvinceCode): string {
    if (provinceCode == 'NS') {
      const parts: string[] = [ this.getActionToBeTakenTextOnMatter('NS'), this.getMortgageDocNumberTextOnMatter('NS'), this.getAssignedByInstrumentNumberTextOnMatter('NS'), this.getInFavourOfTextOnMatter('NS') ];
      return parts.filter(Boolean).join(' ');
    } else {
      return 'TBD';
    }
  }

  sampleDischargeStatementClausePresent(provinceCode: ProvinceCode): string {
    if (provinceCode == 'NB') {
      const parts: string[] = [ this.getActionToBeTakenTextOnMatter(provinceCode),
        this.getMortgageDocNumberTextOnMatter(provinceCode),
        this.getAssignedByInstrumentNumberTextOnMatter(provinceCode),
        this.getToTextOnMatter(provinceCode) ];
      return parts.filter(Boolean).join(' ');
    } else {
      return 'TBD';
    }
  }

  sampleDischargeStatementClauseNotPresent(provinceCode: ProvinceCode): string {
    if (provinceCode == 'NB') {
      const parts: string[] = [ this.getActionToBeTakenTextOnMatter(provinceCode),
        this.getMortgageDocNumberTextOnMatter(provinceCode),
        this.getInFavourOfTextOnMatter(provinceCode) ];
      return parts.filter(Boolean).join(' ');
    } else {
      return 'TBD';
    }
  }
}
