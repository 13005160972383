import {Component, Inject, Input} from '@angular/core';
import {DPError, ErrorType} from '../dp-error';
import {ErrorService} from '../error-service';
import {ErrorTab} from '../error-tab';
import {DpFooterNotification, DpFooterTypes} from '../dp-footer-notification';
import {TabsService} from '../../../core';
import {Matter} from '../../../matters/shared';
import {MatterTab} from '../../../matters/matter-tab';

declare var jQuery: any;

@Component({
  selector: 'dp-modal-error',
  templateUrl: 'modal-error.component.html'
})
export class ModalErrorComponent {
  @Input() errorElementKey: string;

  constructor(@Inject(ErrorService) public errorModalService: ErrorService,
              @Inject(TabsService) public tabsStateService: TabsService) {
  }

  ngOnInit(): void {
    let dPNotificationErrorFooter = new DpFooterNotification();
    dPNotificationErrorFooter.notificationHeader = 'Error';
    dPNotificationErrorFooter.errorTab = new ErrorTab();
    dPNotificationErrorFooter.dpFooterType = DpFooterTypes.ERROR;
    dPNotificationErrorFooter.isActive = false;
    this.errorModalService.dpFooterNotifications.push(dPNotificationErrorFooter);

  }

  createDPFieldError(elementKey: string): void {
    this.errorModalService.addDpFieldError(DPError.createDPError(elementKey));
  }

  createCustomDPFieldError(errorElementKey: string, errorMessage: string, errorTopic: string, errorType: ErrorType): void {
    this.errorModalService.addDpFieldError(DPError.createCustomDPError(errorElementKey, errorMessage, errorTopic, errorType));
  }

  createCustomDPSaveError(errorElementKey: string, errorMessage: string, errorTopic: string, errorType: ErrorType): void {
    this.errorModalService.addDpSaveError(DPError.createCustomDPError(errorElementKey, errorMessage, errorTopic, errorType));
  }

  removeDpFieldError(elementKey: string): void {
    this.errorModalService.removeDpFieldError(elementKey);
  }

  createDPSaveError(elementKey: string): void {
    this.errorModalService.addDpSaveError(DPError.createDPError(elementKey));
  }

  anyErrorExist(): boolean {
    return this.errorModalService.hasErrors();
  }

  removeAllDpFieldError(): void {
    this.errorModalService.removeAllDpFieldError();
  }

  removeDpSaveError(elementKey: string): void {
    this.errorModalService.removeDpSaveError(elementKey);
  }

  removeAllDpSaveError(): void {
    this.errorModalService.clearAllSaveErrors();
  }

  getDPError(errorElementKey: string): DPError {
    return this.errorModalService.getDPError(errorElementKey);
  }

  updateErrorMsg(errorElementKey: string, errorMsg: string) {
    const dPError: DPError = this.errorModalService.getDPError(errorElementKey);
    if (dPError) {
      dPError.errorMessage = errorMsg;
    }
  }

  get errorList(): DPError[] {
    return this.errorModalService.errorTab
      ? this.errorModalService.errorTab.saveErrors
      .concat(this.errorModalService.errorTab.fieldErrors)
      .filter(error => !this.errorElementKey || this.errorElementKey === error.errorElementKey)
      : [];
  }

  get activeMatter(): Matter {
    let activeTab = this.tabsStateService.activeTab;
    if (activeTab && activeTab.isMatter()) {
      return (activeTab as MatterTab).matter;
    } else {
      return null;
    }
  }

  getErrorMessage(error: DPError): string {
    this.activateSaveButton();
    let rawMsg = this.errorModalService.getErrorMessageWithLatestIndex(error);
    return this.errorModalService.createMatterTypeAwareMessage(this.activeMatter, rawMsg, error);
  }

  public activateSaveButton(): void {
    jQuery('.modal-footer button').each(function () {
      var str = jQuery(this).find('span').text();
      if (str && (str.trim().toUpperCase() == 'SAVE' || str.trim().toUpperCase() == 'OK')) {
        jQuery(this).attr('disabled', false);
      }
    });

  }

}
