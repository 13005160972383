import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {Observable} from 'rxjs';
import {Contact} from '../shared/contact';
import {matterApi} from '../shared/matter-api';
import {MatterParticipant} from '../shared/matter-participant';
import {matterResponseKey} from '../shared/matter-response-key';
import {configApi} from '../../main/config-api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {DocumentTemplate, DocumentTemplateUtil, preProduceValidationFlags} from './document-template';
import {Document, MatterDocumentMetadata} from './document';
import {DocumentProductionData, DocumentPurchasersData, MultipleTemplatesDocument} from './document-production-data';
import {DocumentTemplateCategory} from './document-template-category';
import {Matter} from '../shared/matter';
import {Utils} from '../shared/utils';
import {DocumentIdentificationData} from '../purchaser/print-id/document-identification-data';
import {MultipleMattersDocumentCall} from './multiple-matters-document-call';
import {DocumentWrapper} from './documents-for-this-matter.component';
import moment from 'moment';
import {ZendeskRequestType} from './zendesk/zendesk-request';
import {StatementAdjustmentPreview} from '../statement-adjustment/model';
import {ProjectDepositView} from '../shared/projectDepositView';
import {DepositPrintFormatType} from '../shared/deposit';
import {RevokeSharing, SharedDocumentsPackage} from './shared-documents-package';
import {BulkSharing, BulkSharingResponse} from '../matter-list/modals/bulk-sharing';
import {DocumentProductionInfo, DocumentProductionLoggerService} from './document-production-logger.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ContactQueryService} from '../../contact/contact-query.service';
import {Mortgage} from '../shared/mortgage';
import {EmailFieldService} from '../../shared-main/email-field/email-field-service';
import {teranetApi} from "../../shared-main/teranet/teranet-constants";

declare var ITHit: any;

// This this a sharable service which is used for all the document production api.
@Injectable()
export class DocumentProductionService {

  utils = new Utils();

  constructor(private http: HttpClient,
              private documentProductionLoggerService: DocumentProductionLoggerService) {
  }

  // this method is for getting  all the document template for given matter and type
  getDocGenTemplates(matterId: number, categoryFilter: string, isPublic?: boolean, documentProfileId?: number): Observable<DocumentTemplate[]> {
    if (matterId === undefined) {
      return Observable.of([]);
    }
    if (matterId > 0) {
      let url = `${ matterApi.getTemplates(matterId) }`;
      if (categoryFilter != 'ALL') {
        url = url + `?filter=documentTemplateCategories.id_EQ_${ categoryFilter }`;
      } else {
        if (isPublic != undefined || documentProfileId != undefined) {
          url = url + `?`;
        }
      }

      if (isPublic != undefined) {
        url = url + `&filter=isPublic_EQ_${ isPublic }`;
      }
      if (documentProfileId != undefined) {
        url = url + `&documentProfileId=${ documentProfileId }`;
      }

      console.log('loading templates with url:', url);
      return this.http.get(url)
      .map((res) => {
        return res[ matterResponseKey.templates ].map((template) => {
          return new DocumentTemplate(template);
        });
      });
    } else {
      return Observable.of([]);
    }
  }

  // get getDocumentTemplateCategory
  getDocumentTemplateCategory(documentProfileId: number): Observable<DocumentTemplateCategory[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(`${ matterApi.documentTemplateCategories(accountId) }?documentProfileId=${ documentProfileId }`)
    .map((res) => {
      return res[ matterResponseKey.documentTemplateCategories ].map((item) => new DocumentTemplateCategory(item));
    });
  }

  // get all DocumentTemplateCategories
  getAllDocumentTemplateCategory(matterTypeCode: string, forAccId?: number): Observable<DocumentTemplateCategory[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (forAccId) {
      accountId = forAccId.toString(); // sysadmin might request template categories on behalf of another customer account
    }
    return this.http.get(`${ matterApi.documentTemplateCategories(accountId) }?filter=ANYapplicableMatterTypeCode_EQ_${ matterTypeCode }`)
    .map((res) => {
      let data = res[ 'DocumentTemplateCategories' ];
      let documentTemplateCategories = data.map((item) => new DocumentTemplateCategory(item));
      return documentTemplateCategories;
    });
  }

  // get a DocumentTemplateCategory by matterType and accountFileFolderId
  getDocumentTemplateCategoryWithFilter(catId: number, matterTypeCode: string, accountFileFolderId: number, forAccId?: number): Observable<DocumentTemplateCategory> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (forAccId) {
      accountId = forAccId.toString(); // sysadmin might request template categories on behalf of another customer account
    }
    let url = `${ matterApi.documentTemplateCategories(accountId) }/${ catId }?applicableMatterTypeCode=${ matterTypeCode }&accountFileFolderId=${ accountFileFolderId }`;
    return this.http.get(url)
    .map((res) => {
      return new DocumentTemplateCategory(res[ 'DocumentTemplateCategory' ]);
    });
  }

  // get a DocumentTemplateCategory
  // getDocumentTemplateCategorybyID(catId: number, matterType: string, documentProfileId: number): Observable<DocumentTemplateCategory> {
  //     let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  //     let url = `${matterApi.documentTemplateCategories(accountId)}/${catId}?matterType=${matterType}&documentProfileId=${documentProfileId}`;
  //     return this.http.get(url)
  //         .map((res) => {
  //             return new DocumentTemplateCategory(res['DocumentTemplateCategory']);
  //         });
  // }

  // get a DocumentTemplateCategory

  getDocumentTemplateCategoryByCatID(catId: number): Observable<DocumentTemplateCategory> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = `${ matterApi.documentTemplateCategories(accountId) }/${ catId }`;

    return this.http.get(url)
    .map((res) => {
      return new DocumentTemplateCategory(res[ 'DocumentTemplateCategory' ]);
    });
  }

  // save New getDocumentTemplateCategory
  saveDocumentTemplateCategory(documentTemplateCategoryResquest: DocumentTemplateCategory, forAccId?: number): Observable<DocumentTemplateCategory> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (forAccId) {
      accountId = forAccId.toString(); // sysadmin might add template categories on behalf of another customer account
    }
    return this.http.post(`${ matterApi.documentTemplateCategories(accountId) }`, documentTemplateCategoryResquest)
    .map((res) => {
      return new DocumentTemplateCategory(res[ 'DocumentTemplateCategory' ]);
    });
  }

  // save New getDocumentTemplateCategory
  updateDocumentTemplateCategory(documentTemplateCategoryResquest: DocumentTemplateCategory, forAccId?: number): Observable<DocumentTemplateCategory> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (forAccId) {
      accountId = forAccId.toString(); // sysadmin might add template categories on behalf of another customer account
    }
    return this.http.put(`${ matterApi.documentTemplateCategories(accountId) }/${ documentTemplateCategoryResquest.id }`, documentTemplateCategoryResquest)
    .map((res) => {
      return new DocumentTemplateCategory(res[ 'DocumentTemplateCategory' ]);
    });
  }

  // this is to produce document for given DocumentProductionData
  produceDocument(documentProductionData: DocumentProductionData, matterSupplementalTaskCategoryId?: number, logProductionTime?: boolean): Observable<DocumentProductionData> {
    if (documentProductionData && documentProductionData.matterId > 0) {
      let documentProductionInfo: DocumentProductionInfo;
      if (logProductionTime) {
        // Start to collect information about document production clinet side duration
        documentProductionInfo = this.documentProductionLoggerService.createDocumentProductionLogger(documentProductionData.matterId, documentProductionData.templates.length, documentProductionData.produceFileType);
      }

      let url: string = '';
      // this is to produce document for given Supplemental Task Category
      if (matterSupplementalTaskCategoryId) {
        url = matterApi.generateDocumentForSupplementalTaskCategory.replace('{matterId}', '' + documentProductionData.matterId).replace('{supplementalTaskCategoryId}', '' + matterSupplementalTaskCategoryId);
      } else {
        url = matterApi.generateDocument.replace('{matterId}', '' + documentProductionData.matterId);
      }

      return this.http.post(url, documentProductionData)
      .map((res) => {
        let documentProductionData = new DocumentProductionData(res[ 'DocumentProductionData' ]);
        if (logProductionTime && documentProductionInfo) {
          // Record the time after the request is return
          this.documentProductionLoggerService.setProduceDocumentRequestReturnTime(documentProductionInfo, documentProductionData.matterDocumentIds);
        }
        return documentProductionData;
      });
    } else {
      return Observable.of(null);
    }

  }

  produceProjectForm4(documentProductionData: DocumentProductionData, depositPrintFormat: DepositPrintFormatType): Observable<DocumentProductionData> {

    let url: string;
    if (depositPrintFormat == 'FORM_NOT_SENT_ON_SEPARATE_PAGE') {
      url = matterApi.generateMultipleDepositsForm4ForSingleMatter.replace('{matterId}', '' + documentProductionData.matterId);
    } else {
      url = matterApi.generateDepositsForm4Document.replace('{matterId}', '' + documentProductionData.matterId);
    }

    return this.http.post(url, documentProductionData)
    .map((res) => {
      return new DocumentProductionData(res[ 'DocumentProductionData' ]);
    });
  }

  openForm4Preview(matter: Matter): void {
    let docProdSubscription = this.produceProjectForm4Preview(matter)
    .subscribe((response: DocumentProductionData) => {
      const documentId: number = response.matterDocumentIds[ 0 ];
      let pdfStatusIntervalCheck: number = window.setInterval(() => {

        this.getIdentificationFormsStatus(matter.id, documentId)
        .subscribe(data => {
          let progressStatus: string = data;
          if (progressStatus === 'COMPLETED') {
            window.clearInterval(pdfStatusIntervalCheck);
            this.openFileInNewWindow(matter.id, documentId, true, false);
          }
        });
      }, 500);
    });
  }

  produceProjectForm4Preview(matter: Matter): Observable<DocumentProductionData> {
    let url: string = matterApi.generateDepositsForm4Preview.replace('{matterId}', '' + matter.id);
    return this.http.post(url, matter)
    .map((res) => {
      return new DocumentProductionData(res[ 'DocumentProductionData' ]);
    });
  }

  produceMergedDepositsForm4(selectedDeposits: ProjectDepositView[]): Observable<DocumentProductionData> {
    return this.http.post(matterApi.generateMultipleDepositsForm4, selectedDeposits)
    .map((res) => {
      return new DocumentProductionData(res[ 'DocumentProductionData' ]);
    });
  }

  produceMultipleMatterDocument(documentProductionData: DocumentProductionData): Observable<DocumentProductionData> {
    let url: string = matterApi.generateMultipleMatterDocuments.replace('{matterId}', '' + documentProductionData.matterId);

    return this.http.post(url, documentProductionData)
    .map((res) => {
      return new DocumentProductionData(res[ 'DocumentProductionData' ]);
    });
  }

  mergeMultipleDocuments(multipleMattersDocumentRequest: MultipleMattersDocumentCall): Observable<MultipleMattersDocumentCall> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = matterApi.generateMergedMultipleDocuments.replace('{accountId}', '' + accountId).replace('{documentTemplateId}', '' + multipleMattersDocumentRequest.template.templateId);

    return this.http.post(url, multipleMattersDocumentRequest)
    .map((res) => {
      return res[ 'MatterDocument' ];
    });
  }

  getDocuments(matterId: number, excludeSecondaryDocuments?: boolean): Observable<Document[]> {
    if (matterId > 0) {
      let url: string = matterApi.getDocumentsForMatter.replace('{matterId}', '' + matterId);
      if (excludeSecondaryDocuments) {
        url += '?excludeSecondaryDocuments=true';
      }
      return this.http.get(url)
      .map((res) => {
        let documents: Document[] = [];
        if (res) {
          res[ 'Documents' ].forEach(document => {
            documents.push(new Document(document));
          });
        }
        let completedDocuments = documents.filter(doc => doc.isCompleted);
        if (completedDocuments && completedDocuments.length) {
          // Log client side duration for document production
          this.documentProductionLoggerService.logAndRemoveFromDocumentLogger(matterId, completedDocuments);
        }
        return documents;
      });
    } else {
      return Observable.of([]);
    }

  }

  getDocumentsMetaData(matterId: number): Observable<MatterDocumentMetadata> {
    if (matterId > 0) {
      let url: string = matterApi.getDocumentsMetaDataForMatter.replace('{matterId}', '' + matterId);
      return this.http.get(url)
      .map((res) => {
        return (res && new MatterDocumentMetadata(res[ 'MatterDocumentMetaData' ]));
      });
    } else {
      return Observable.of(null);
    }
  }

  getDocument(matterId: number, documentId: string): Observable<Document> {
    if (matterId > 0) {
      let url: string = matterApi.getDocument.replace('{matterId}', '' + matterId);
      url = url.replace('{documentId}', '' + documentId);
      return this.http.get(url)
      .map((res) => {
        return new Document(res[ 'MatterDocument' ]);
      });
    } else {
      return Observable.of(null);
    }

  }

  //used to open WORD and WORDPERFECT document
  openDocumentForEdit(id: string, documentName: string, documentType: string = 'WORD'): void {
    //we want to trigger a token cache reload in cases where server was restarted,
    //so chain http request
    console.log(documentName);
    this.http.get(`${ configApi.apiVersion }`).subscribe(res => {
      let loc = window.location;
      //Adding random string to the document url so word won't cache it and pull from server everytime document is opened
      let randomStr = Date.now();

      //the following line is a temporarily workaround for JR23838
      documentName = documentName.replace(/ /g, '_');

      let documentPath: string = '/api/webdav/' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) + '/' + id + '/' + randomStr + '/' + encodeURIComponent(documentName); //Encoding document Name as it can have special characters
      let documentUrl: string = loc.protocol + '//' + loc.host + documentPath;
      if (documentType == 'WORD') {
        var windowObject = window.open('ms-word:ofe|u|' + documentUrl, '_self');
      } else if (documentType == 'WORDPERFECT') {
        //the current solution is working with .wpd url with https protocol
        // documentUrl = 'https://www.joe0.com/webdav/wptest1.wpd';
        // let oNs = ITHit.WebDAV.Client.DocManager;
        // oNs.DavProtocolEditDocument(documentUrl, null, this.protocolInstallCallback);
        // var windowObject = window.open(unityDoProcessDoc, "_self");
        // var windowObject = window.open(documentUrl, "_self");
        let filePath = documentUrl.substring(documentUrl.indexOf('/api/webdav')).replace(/\//g, '\\');
        console.log('-- file path: ', filePath);
        let wpUrl = '';
        if (loc.protocol.startsWith('https')) {
          wpUrl = 'unitydoprocess:' + '\\\\' + loc.hostname + '@SSL' + (loc.port && loc.port != '443' ? '@' + loc.port : '') + filePath;
        } else {
          wpUrl = 'unitydoprocess:' + '\\\\' + loc.hostname + (loc.port && loc.port != '80' ? '@' + loc.port : '') + filePath;
        }
        console.log('-- unitydoprocess url', wpUrl);
        var windowObject = window.open(wpUrl, '_self');
        //documentUrl = 'https://www.joe0.com/webdav/wptest1.wpd';
        /*                if (oNs.IsMicrosoftOfficeDocument(documentUrl)) {
                            oNs.MicrosoftOfficeEditDocument(documentUrl, this.protocolInstallCallback);
                        } else {
                            oNs.DavProtocolEditDocument(documentUrl, null, this.protocolInstallCallback);
                        }*/
      }

    });

  }

  // Called if protocol handler is not installed
  protocolInstallCallback(message) {
    // var installerFilePath = "http://www.ajaxbrowser.com/ITHitService/WebDAVAJAXLibrary/Plugins/" +
    //     ITHit.WebDAV.Client.DocManager.GetInstallFileName();

    let installerFilePath = '/assets/unityhandler/UnityDPInstaller.msi';

    if (confirm('This action requires a protocol installation. Select OK to download the protocol installer.')) {
      window.open(installerFilePath);
    }
  }

  downloadFileInNewWindow(matterId: number, documentId: number) {
    // Downloads the pdf file, instead of opening in new window.
    let url: string = this.getUrlForDownload(matterId, documentId, false);
    var windowObject = window.open(url, '_blank');
  }

  downloadZip(matter: Matter, matterDocuments: DocumentWrapper[]) {
    let url: string = matterApi.downloadZip.replace('{matterId}', '' + matter.id);

    //Temporary mapping as the zip API expects (INCORRECTLY) a templateId attribute
    let wrappers = matterDocuments.map(doc => {
      return {templateId: doc.documentId, generatedDocumentType: doc.generatedDocumentType};
    });
    this.http.downloadThroughPost(url, JSON.stringify(wrappers))
    .subscribe((res) => {
      let fileName = matter.matterRecordNumber + '_' + moment(new Date(), 'YYYY/MM/DD').format('YYYY-MM-DD hhmmA') + '.zip';
      var mediaType = 'application/zip';
      var blob = new Blob(([ res.body ]), {type: mediaType});
      if (this.utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, fileName);
        }
      } else {
        var fileURL = window.URL.createObjectURL(blob);
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = fileName;
        fileLink.click();
      }
    });
  }

  /**
   * opens the document for specified matter in a new window
   * if changeActionBasedOnBrowser and the file is a PDF and if browser is not IE  => it downloads the file instead of opening the PDF on another window
   * @param matterId
   * @param documentId
   * @param isPdf
   * @param changeActionBasedOnBrowser
   */
  openFileInNewWindow(matterId: number, documentId: number, isPdf: boolean = false, changeActionBasedOnBrowser: boolean = false): void {
    // opening in new window.

    let openInLine: boolean = true;
    if (changeActionBasedOnBrowser && isPdf && !this.utils.isIE()) {
      openInLine = false;
    }
    let url: string = this.getUrlForDownload(matterId, documentId, openInLine);
    var windowObject = window.open(url, '_blank');
  }

  getUrlForDownload(matterId: number, documentId: number, openInLine: boolean): string {
    let url: string =
      openInLine ?
        matterApi.openDocumentInline.replace('{matterId}', '' + matterId) : // server response header -> Content-Disposition:inline
        matterApi.downloadDocument.replace('{matterId}', '' + matterId);  // server response header -> Content-Disposition:attachment

    url = url.replace('{documentId}', '' + documentId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    console.log(openInLine ? 'open url:' : 'downloadFileAndDisplay url:', url);

    return url;
  }

  getUrlForThirdPartyDocument(matterId: number, documentId: number): string {
    let url: string = teranetApi.downloadTeranetDocument.replace('{matterId}', '' + matterId);
    url = url.replace('{documentId}', '' + documentId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    return url;
  }

  getUrlForDownloadInTeraview(matterId: number, documentId: number, fileName: string): string {
    let url: string = matterApi.documentForTeraviewUpload.replace('{matterId}', '' + matterId);
    url = url.replace('{documentId}', '' + documentId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    url = url.replace('{fileName}', '' + fileName);
    return url;
  }

  openPdfFile(matterId: number, doc: Document): Observable<any> {

    let url: string = matterApi.downloadDocument.replace('{matterId}', '' + matterId);
    url = url.replace('{documentId}', '' + doc.id);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));

    return this.http.getPdf(url)
    .map((blob) => {
      console.log(blob);
      if (this.utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, doc.documentName);
        }
      } else {
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
      }
    });
  }

  getDocLockedErrorMessage(doc: Document): string {
    return 'The document ' + doc.documentName + ' is currently opened by ' + (doc.lockedByUser ? doc.lockedByUser.loginId : '') +
      ' and cannot be overwritten. Please ask ' + (doc.lockedByUser ? doc.lockedByUser.loginId : '') + ' to close document and try again.';
  }

  renameFile(matterId: number, documentId: number, newFileName: string, fileExt: string, description: string = undefined): Observable<any> {
    let url: string = matterApi.renameDocument.replace('{matterId}', '' + matterId).replace('{documentId}', '' + documentId);
    let encodedFileName = encodeURIComponent(newFileName);
    url = url + '?newFileName=' + encodedFileName + '.' + fileExt;
    if (description !== undefined) {
      url = url + '&description=' + (description ? encodeURIComponent(description) : '');
    }
    let documentRename: any = {newFileName: encodedFileName};
    return this.http.post(url, JSON.stringify(documentRename))
    .map((res) => {
      // console.log(res);
      return res;
    });
  }

  copyFile(matterId: number, documentId: number, newFileName: string, fileExt: string, isThirdPartyDocument: boolean, subpath?: string): Observable<any> {
    let urlDocument: string = matterApi.copyDocument.replace('{matterId}', '' + matterId).replace('{documentId}', '' + documentId);
    let urlThirdPartyDocument: string = matterApi.copyThirdPartyDocument.replace('{matterId}', '' + matterId).replace('{thirdPartyDocumentId}', '' + documentId);
    let url = isThirdPartyDocument ? urlThirdPartyDocument : urlDocument;
    let encodedFileName = encodeURIComponent(newFileName);
    url = url + '?newFileName=' + encodedFileName + '.' + fileExt;
    if (subpath) {
      url = url + `&newSubpath=${ subpath }`;
    }
    let documentRename: any = {newFileName: encodedFileName};
    return this.http.post(url, JSON.stringify(documentRename))
    .map((res) => {
      //  console.log(res);
      return res;
    });
  }

  deleteFile(matterId: number, documentId: string): Observable<any> {
    let url: string = matterApi.deleteDocument.replace('{matterId}', '' + matterId).replace('{documentId}', '' + documentId);
    return this.http.delete(url)
    .map((res) => {
      return res;
    });
  }

  downloadWordOrOpenPDFFile(matterId: number, document: Document): Observable<any> {
    let url: string = matterApi.downloadWordDocument.replace('{matterId}', '' + matterId).replace('{documentId}', '' + document.id);
    return this.http.getPdf(url)
    .map((blob) => {
      console.log(blob);
      if (this.utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, document.documentName);
        }
      } else {
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
      }

    });
  }

  generateStatementOfAdjustmentPdfPreview(matterId: number, soa: any): Observable<any> {

    let url: string = matterApi.generateStatementOfAdjustmentPdfPreview.replace('{matterId}', '' + matterId);

    return this.http.postPdf(url, JSON.stringify(soa))
    .map((res) => {
      console.log(blob);
      var mediaType = 'application/pdf';
      var blob = new Blob(([ res.body ]), {type: mediaType});
      if (this.utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, 'StatementOfAdjustmentsPreview.pdf');
        }
      } else {
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
      }
    });
  }

  generateStatementOfAdjustmentEditablePreview(matterId: number, sap: StatementAdjustmentPreview): Observable<Document> {
    let url: string = matterApi.generateStatementOfAdjustmentEditablePreview.replace('{matterId}', '' + matterId);
    return this.http.post(url, JSON.stringify(sap))
    .map((res) => {
        return new Document(res[ 'MatterDocument' ]);
      }
    );
  }

  generateStatementOfAdjustmentPdfPreviewMatterDocument(matterId: number, soa: any): Observable<DocumentProductionData> {

    let url: string = matterApi.generateStatementOfAdjustmentPdfPreviewMatterDocument.replace('{matterId}', '' + matterId);

    return this.http.post(url, JSON.stringify(soa))
    .map((res) => {
      return new DocumentProductionData(res[ 'DocumentProductionData' ]);
    });
  }

  generateDirectionReFundsPreview(matterId: number, directionReFunds: any): Observable<any> {

    let url: string = matterApi.generateDirectionReFundsPreview.replace('{matterId}', '' + matterId);

    return this.http.postPdf(url, JSON.stringify(directionReFunds))
    .map((res) => {
      console.log(blob);
      var mediaType = 'application/pdf';
      var blob = new Blob(([ res.body ]), {type: mediaType});
      if (this.utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, 'DirectionReFundsPreview.pdf');
        }
      } else {
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
      }
    });
  }

  generateDirectionReFundsPreviewMatterDocument(matterId: number, directionReFunds: any): Observable<DocumentProductionData> {

    let url: string = matterApi.generateDirectionReFundsPreviewMatterDocument.replace('{matterId}', '' + matterId);

    return this.http.post(url, JSON.stringify(directionReFunds))
    .map((res) => {
      return new DocumentProductionData(res[ 'DocumentProductionData' ]);
    });
  }

  reportStatementOfAdjustmentPreview(matter: Matter): Observable<any> {
    let url: string = matterApi.reportToPurchaserStatementOfAdjustmentPreview.replace('{matterId}', '' + matter.id);
    return this.http.get(url)
    .map((res) => {
      let data = res[ 'ReportToPurchaseSoAdjPreview' ];
      data = data.split('\r').join('<br>');
      // It uses "<br>" instead of '\r' because "\r" can not show a new line in UI.
      return data;
    });
  }

  convertToPDF(matterId: number, documentId: number): Observable<Document> {
    let url = matterApi.convertToPDF.replace('{matterId}', '' + matterId);
    url = url.replace('{documentId}', '' + documentId);
    return this.http.post(url, '')
    .map((res) => {
      return new Document(res[ 'MatterDocument' ]);
    });
  }

  generateIdentificationForm(documentIdentificationData: DocumentIdentificationData, printIvF: boolean): Observable<any> {
    let stringToAppend = printIvF ? '/ivf' : '/declaration';
    let url: string = matterApi.generateIdentificationForm.replace('{matterId}', '' + documentIdentificationData.matterId);
    url = url + stringToAppend;
    return this.http.post(url, documentIdentificationData)
    .map((response) => {
      if (response) {
        return new DocumentIdentificationData(response[ 'DocumentIdentificationData' ]);
      }
    });
  }

  // Get status for document Id corresponding to Identification Form
  getIdentificationFormsStatus(matterId: number, documentId: number) {
    let url: string = matterApi.generateIdentificationForm.replace('{matterId}', '' + matterId);
    url = `${ url }/${ documentId }/status`;
    return this.http.get(
      `${ url }`)
    .map((response) => {
      return response[ 'DocumentStatus' ];
    });
  }

  getAccountDocumentStatus(documentId: number): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = matterApi.checkAccountDocumentStatus.replace('{documentId}', '' + documentId).replace('{accountId}', '' + accountId);
    return this.http.get(
      `${ url }`)
    .map((response) => {
      return response[ 'DocumentStatus' ];
    });
  }

  produceMultipleTemplateDocuments(multipleTemplatesDocumentObj: MultipleTemplatesDocument): Observable<MultipleTemplatesDocument> {
    // Start to collect information about merging documents clinet side duration
    let documentProductionInfo = this.documentProductionLoggerService.createDocumentMergeLogger(multipleTemplatesDocumentObj.matterId, multipleTemplatesDocumentObj.produceFileType, multipleTemplatesDocumentObj.templates.length);
    let url = matterApi.multipleTemplatesMasterDocument.replace('{matterId}', '' + multipleTemplatesDocumentObj.matterId);
    return this.http.post(url, multipleTemplatesDocumentObj)
    .map((res) => {
      let multipleTemplatesDocument = new MultipleTemplatesDocument(res[ 'DocumentProductionData' ]);
      if (multipleTemplatesDocument.masterMaterDocuments && multipleTemplatesDocument.masterMaterDocuments.length) {
        // Record the time after the request is return
        this.documentProductionLoggerService.setMergeDocumentRequestReturnTime(documentProductionInfo, multipleTemplatesDocument.masterMaterDocuments);
      }
      return multipleTemplatesDocument;
    });
  }

  produceMergeMultipleForms(url: string, multipleTemplatesDocumentObj: MultipleTemplatesDocument): Observable<MultipleTemplatesDocument> {

    return this.http.post(url, multipleTemplatesDocumentObj)
    .map((res) => {
      return new MultipleTemplatesDocument(res[ 'DocumentProductionData' ]);
    });
  }

  produceDocumentForPurchasers(dpd: DocumentPurchasersData): Observable<string> {
    let url = matterApi.multipleTemplatesForPurchasers.replace('{matterId}', '' + dpd.matterId);
    return this.http.post(url, dpd)
    .map((res) => {
      if (res[ 'DocumentPurchasersData' ]) {
        return res[ 'DocumentPurchasersData' ].documentStatus;
      }
    });

  }

  zendeskUrl(requestType: ZendeskRequestType): Observable<string> {
    let url = matterApi.zendeskUrl + requestType;
    return this.http.get(url)
    .map((res) => {
      if (res[ 'ZendeskUrl' ]) {
        return res[ 'ZendeskUrl' ];
      }
    });
  }

  generateDownloadURLFromDocumentToClipboard(matterDocument: Document, selectedMatterId: number) {

    const createCopy = (e: ClipboardEvent) => {
      let loc = window.location;
      let url : string = loc.protocol + '//' + loc.host;

      if (matterDocument.isThirdPartyDocument) {
        url += this.getUrlForThirdPartyDocument(selectedMatterId, matterDocument.id);
      } else {
        url += this.getUrlForDownloadInTeraview(selectedMatterId, matterDocument.id, matterDocument.documentName);
      }

      e.clipboardData.setData('text/plain', url);
      e.preventDefault();
    };
    document.addEventListener('copy', createCopy);
    document.execCommand('copy');
    document.removeEventListener('copy', createCopy);
  }

  generateShareDocsPackage(shareDocumentPackage: SharedDocumentsPackage): Observable<SharedDocumentsPackage> {
    let url = matterApi.shareMatterDocumentPackage.replace('{matterId}', '' + shareDocumentPackage.matterId);
    return this.http.post(url, shareDocumentPackage)
    .map((res) => {
      if (res[ 'SharedDocumentsPackage' ]) {
        return res[ 'SharedDocumentsPackage' ];
      }
    });
  }

  bulkShare(bulkSharing: BulkSharing): Observable<BulkSharingResponse> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = matterApi.bulkShare.replace('{accountId}', accountId);
    return this.http.post(url, bulkSharing, true)
    .map((res) => {
      if (res[ 'BulkShareDocumentsResponse' ]) {
        return new BulkSharingResponse(res[ 'BulkShareDocumentsResponse' ]);
      }
    });
  }

  copyMatterDocument(matterId: number, sourceMatterId: number, documentId: number): Observable<Document> {
    let url: string = matterApi.copyMatterDocument.replace('{matterId}', String(matterId))
    .replace('{sourceMatterId}', String(sourceMatterId))
    .replace('{documentId}', String(documentId));

    return this.http.post(url, null)
    .map((res) => {
      return new Document(res[ 'MatterDocument' ]);
    });
  }

  getSharedDocumentPackageSummary(matterId: number): Observable<SharedDocumentsPackage[]> {
    if (matterId === undefined) {
      return Observable.of([]);
    }
    if (matterId > 0) {
      let url = matterApi.sharedDocumentPackageSummary.replace('{matterId}', '' + matterId);
      return this.http.get(url)
      .map((res) => {
        if (res[ 'SharedDocumentsPackageSummary' ]) {
          return res[ 'SharedDocumentsPackageSummary' ];
        }
      });
    } else {
      return Observable.of([]);
    }
  }

  toggleProjectDocumentAutoShare(projectId: number, matterId: number, document: Document): Observable<void> {
    let url = matterApi.toggleProjectDocumentAutoShare.replace('{projectId}', '' + projectId)
    .replace('{matterId}', '' + matterId)
    .replace('{documentId}', '' + document.id);
    return this.http.post(url, null).map((res) => {
    });
  }

  revokePackage(revokeSharing: RevokeSharing, guid: string): Observable<SharedDocumentsPackage> {
    let url = matterApi.revokePackage.replace('{packageGuid}', guid);
    return this.http.post(url, revokeSharing)
    .map((res) => {
      if (res[ 'SharedDocumentsPackage' ]) {
        return res[ 'SharedDocumentsPackage' ];
      }
    });
  }

  async tryToRevokePackage(matter: Matter, matterParticipant: MatterParticipant, contactQueryService?: ContactQueryService, contactId?: number, emailFieldService ?: EmailFieldService): Promise<void> {
    if (matter && matter.id && (matterParticipant || contactId)) {
      const documentsRecipients: MatterParticipant[] = this.getPparticipantAsRecipient(matter, matterParticipant);
      let documentsRecipientContacts: Contact[] = [];
      let matterSharedDocumentsPackages: SharedDocumentsPackage[] = await this.getSharedDocumentPackageSummary(matter.id).toPromise();
      if (contactId && contactQueryService) {
        let recipientContact = await contactQueryService.getContactForMatter(contactId).toPromise();
        if (recipientContact) {
          documentsRecipientContacts.push(recipientContact);
        }
      } else if (documentsRecipients && documentsRecipients.length > 0) {
        for (let documentsRecipient of documentsRecipients) {
          let recipientContact: Contact;
          if (documentsRecipient.contact.sourceContactId && documentsRecipient.contact.sourceContactId != documentsRecipient.contact.id && !documentsRecipient.contact.isCondoCorporation && contactQueryService) {
            //await this.getContactForMatter(documentsRecipient.contact.sourceContactId, documentsRecipientContacts, contactQueryService);
            recipientContact = await contactQueryService.getContactForMatter(documentsRecipient.contact.sourceContactId).toPromise();
            if (recipientContact) {
              documentsRecipientContacts.push(recipientContact);
            }
          } else {
            documentsRecipientContacts.push(documentsRecipient.contact);
          }
        }
        ;
      }

      if (matterSharedDocumentsPackages && matterSharedDocumentsPackages.length && documentsRecipientContacts && documentsRecipientContacts.length > 0) {
        documentsRecipientContacts.forEach(documentsRecipientContact => {
          let sharedDocumentsPackages = matterSharedDocumentsPackages.slice().filter(item => this.isSharedDocumentsPackagesRecipient(item, documentsRecipientContact));
          if (sharedDocumentsPackages && sharedDocumentsPackages.length) {
            let revokeSharing: RevokeSharing = new RevokeSharing();
            revokeSharing.revokeSharingMessageSubject = emailFieldService ? emailFieldService.getMailSubject('MATTER-OPENING') : '';
            revokeSharing.revokeSharingMessageBody = 'Dear ' + documentsRecipientContact.contactName.fullName + '.\n' + 'Access to all documents for this matter has been withdrawn.  They will no longer be available for your review.\n\n';
            sharedDocumentsPackages.forEach(item => {
              this.revokePackage(revokeSharing, item.guid).subscribe(() => {
              });
            });
          }
        });
      }
    }
  }

  isSharedDocumentsPackagesRecipient(sharedpackage: SharedDocumentsPackage, recipient: Contact): boolean {
    return (sharedpackage.unityRecipientIds && sharedpackage.unityRecipientIds.length > 0 && !!sharedpackage.unityRecipientIds.find(id => id === recipient.sourceContactId)) ||
      recipient.firstEmail && sharedpackage.connectRecipientEmail && sharedpackage.connectRecipientEmail.toLocaleLowerCase() == recipient.firstEmail.toLocaleLowerCase();
  }

  getPparticipantAsRecipient(matter: Matter, matterParticipant: MatterParticipant): MatterParticipant[] {
    let recipients: MatterParticipant[] = [];
    if (matter && matter.matterParticipants && matterParticipant && matterParticipant.contact) {
      recipients.push(matterParticipant);
      if (!matterParticipant.contact.isIndividual) {
        const signingOfficers: MatterParticipant[] = matter.matterParticipants.slice().filter(item => item.parentParticipantId === matterParticipant.matterParticipantId && item.isSigningOfficer);
        if (signingOfficers && signingOfficers.length > 0) {
          signingOfficers.forEach(signingOfficer => {
            if (signingOfficer && signingOfficer.contact && signingOfficer.contact.firstEmail) {
              recipients.push(signingOfficer);
            }
          });
        }
      }
    }
    return recipients;
  }

  revokeAssociatedPackages(matter: Matter, mortgage: Mortgage, contactQueryService?: ContactQueryService): void {
    let documentsRecipients: MatterParticipant[] = [];
    if (matter && matter.matterParticipants && matter.matterParticipants.length && mortgage) {
      matter.matterParticipants.forEach(participant => {
        if (participant.mortgageId == mortgage.id) {
          documentsRecipients.push(participant);
        }
      });
      if (documentsRecipients.length > 0) {
        documentsRecipients.forEach(item => {
          if (contactQueryService) {
            this.tryToRevokePackage(matter, item, contactQueryService);
          }
        });
      }
    }
  }

  revokeDocument(revokeSharing: RevokeSharing, guid: string, documentId: number): Observable<SharedDocumentsPackage> {
    let url = matterApi.revokeDocument.replace('{packageGuid}', guid).replace('{documentId}', '' + documentId);
    return this.http.post(url, revokeSharing)
    .map((res) => {
      if (res[ 'SharedDocumentsPackage' ]) {
        return res[ 'SharedDocumentsPackage' ];
      }
    });
  }

  updateDocumentSubFolder(document: Document, matter: Matter): void {
    let url = matterApi.documentMoveToSubfolder.replace('{matterId}', matter.id.toString()).replace('{documentId}', '' + document.id);
    url = url + `?newSubpath=${ document.subpath }`;
    this.http.post(url, {})
    .subscribe((res) => {
    });
  }

  validateMatterBeforeProducingDocument(documentTemplate: DocumentTemplate, currentMortgageIndex: number, matter: Matter, supTask: boolean, dialogService: DialogService, checkForOneMortgage?: boolean): boolean {
    /*
    * If the template has validation flag
    * */
    if (DocumentTemplateUtil.hasValidationFlag(documentTemplate, preProduceValidationFlags.amortizationSschedule)) {
      /*
      * If currentMortgageIndex is null, we will check for all mortgages
      * Except if checkForOneMortgage flag is true
      * */
      if (!currentMortgageIndex && checkForOneMortgage) {
        currentMortgageIndex = 1;
      }
      /*
      * If the template is produced for a specific mortgage, we have to check if the produced mortgage has interest only term
      * OR If the template is produced to all mortgages, we have to check if some mortgages are interest only term
      * */
      if ((currentMortgageIndex && currentMortgageIndex > 0 && matter.isMortgageHasInterestOnlyTerm(currentMortgageIndex - 1)
        || !currentMortgageIndex && matter.someMortgageWithInterestOnlyTerm())) {
        this.displayDocGenErrorMessage('The Amortization Schedule cannot be produced as a Mortgage is Interest Only', dialogService);
        return false;
      }
    }

    if (documentTemplate.trustLedgerBalancedValidationRequired && !matter.isTrustLedgerTopicsBalanced()) {
      this.displayDocGenErrorMessage('Document(s) cannot be produced because the Trust Ledger columns do not balance', dialogService);
      return false;
    }

    if (documentTemplate.trustLedgerBalancedValidationRequired && matter.checkTrustLedgerSoaLegalFeeBalanced().length > 0 && !supTask) {
      this.displayDocGenErrorMessage(matter.checkTrustLedgerSoaLegalFeeBalanced().join('\n\n'), dialogService);
      return false;
    }
    return true;
  }

  displayDocGenErrorMessage(errorText: string, dialogService: DialogService): void {
    dialogService.confirm('Error', errorText, true, 'OK').subscribe(res => {
    });
  }

}
