import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Writ} from './property-writ';
import {WritExecution} from './property-writ-execution';

export class TeranetWritSearchResponse extends BaseEntity {
  successfulWrits: Writ[];
  successfulWritExecutions: WritExecution[];
  failedOrders: string[];
  updatedWrit: Writ;
  removedExecutions: string[];
  newExecutions: string[];
}
