import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {FctMessage} from './fct-message';

export class SubmitFileResponse extends BaseEntity {

  responseCode: number;
  url: string;
  dealURN: string;
  messages: FctMessage[] = [];

  constructor(t?: SubmitFileResponse) {
    super(t);
    if (t && t.messages) {
      for (let f of t.messages) {
        this.messages.push(new FctMessage(f));
      }
    }
  }

  get isSuccessful(): boolean {
    return this.responseCode == 0;
  }
}
