import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {Component, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SoaCommonExpense} from '../../model/common-expense-soa';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {CurrencyPipe} from '@angular/common';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {UnitLevelPlanModalComponent} from '../../../property-teranet/unit-level-plan/unit-level-plan.component';
import {Matter} from '../../../shared/matter';
import {MatterProperty} from '../../../shared/matter-property';
import {UnitLevelPlanUtil} from '../../../property-teranet/unit-level-plan/unit-level-plan-util';
import {StatementAdjustmentAmountTypes} from '../../statement-adjustment-constants';
import Utils from '../../../../shared-main/utils';
import {Utils as Utils2} from '../../../shared/utils';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {TitleRollNumberModal} from '../../../property-teranet/title-roll-number/title-roll-number.modal.component';
import {DpBooleanValueTypes} from '../../../shared/dp-boolean';
import {CondominiumExpense} from '../../../property-teranet/unit-level-plan/condominium-expense';
import {StatementAdjustment} from '../../statement-adjustment';
import {SelectUnitLevelPlanResult} from '../../../shared/select-unit-level-plan-result';
import {MatterService} from '../../../matter.service';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class SoaCommonExpenseModalContext {
  public closingDate: string;
  public expenseType: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public soaCommonExpense: SoaCommonExpense;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public addedFlag: boolean;
  public currentMatter: Matter;
  public inSecondModal: boolean;
  public isMBEditBtnVisible: boolean;
  public parcelLegalDescriptionId: number;
  public isONProject: boolean;
  public sourceFromProject: boolean;
  public statementAdjustment: StatementAdjustment;
}

@Component({
  selector: 'dp-common-expenses-soa-modal-content',
  templateUrl: './common-expenses-soa.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class SoaCommonExpenseModal extends ModalComponent<SoaCommonExpenseModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  context: SoaCommonExpenseModalContext;
  closingDateLabel: string = 'Closing Date';
  soaCommonExpense: SoaCommonExpense;
  soaCommonExpenseCached: SoaCommonExpense; //used on return from unitLevelPlan
  commonExpensePaidDropDown: SelectItem[];
  commonExpenseAdjustedDropDown: SelectItem[];
  commonExpenseAdjustedForDropDown: SelectItem[];
  addedFlag: boolean;
  inSecondModal: boolean;
  isClosingDateValid: boolean;
  commonExpenseFormatOfAdjustment: SelectItem[]; // for ON Project
  noYesOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;
  isUsePotlCommonExpenseAmountVisible: boolean = false;
  localInfoOnly: boolean;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<SoaCommonExpenseModal>,
    public currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    public matterService: MatterService,
    @Inject(MAT_DIALOG_DATA) context?: SoaCommonExpenseModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.commonExpensePaidDropDown = dropDowns.commonExpensePaidOptions;
    this.commonExpenseAdjustedDropDown = dropDowns.commonExpenseAdjustedOptions;
    this.commonExpenseFormatOfAdjustment = dropDowns.commonExpenseFormatOfAdjustment;

    this.commonExpenseAdjustedForDropDown = Utils.loadTaxSelectItem(
      this.context.provinceHstRateSlab.rateType,
      this.context.provinceHstRateSlab.hstProvincialPortion
    );

    if (!this.context.soaCommonExpense) {
      this.soaCommonExpense = new SoaCommonExpense();
      this.soaCommonExpense.usePotlCommonExpenseAmount = 'N_y';
      this.newAdj = true;
    } else {
      this.soaCommonExpense = new SoaCommonExpense(this.context.soaCommonExpense);
    }

    if (!this.soaCommonExpense.expenseType) {
      this.soaCommonExpense.expenseType = this.context.expenseType;
    }

    if (!this.soaCommonExpense.isCommonExpensePaid) {
      this.soaCommonExpense.isCommonExpensePaid = 'YES';
    }

    if (!this.soaCommonExpense.isCommonExpenseAdjusted) {
      this.soaCommonExpense.isCommonExpenseAdjusted = 'NO';
    }

    if (!this.soaCommonExpense.commonExpenseAmount) {
      this.soaCommonExpense.commonExpenseAmount = 0;
    }
    if (
      !this.soaCommonExpense.commonExpenseAdjustedFor ||
      this.context.currentMatter.isMatterProvinceMB ||
      this.context.currentMatter.isMatterProvinceSK
    ) {
      this.soaCommonExpense.commonExpenseAdjustedFor = this.appliedTaxRate();
    }

    if (!this.soaCommonExpense.adjustPercentage) {
      this.soaCommonExpense.adjustPercentage = this.appliedRate().toString();
    }

    if (!this.soaCommonExpense.adjustmentFormat && this.context.isONProject) {
      this.soaCommonExpense.adjustmentFormat = 'DO_NOT_ITEMIZE_BY_UNIT';
    }

    if (this.context.closingDate) {
      this.closingDateLabel = Utils2.formatDate(this.context.closingDate, '???????');
      this.isClosingDateValid = !this.closingDateLabel.includes('?');
    }

    if (this.context) {
      this.addedFlag = this.context.addedFlag;
      this.inSecondModal = this.context.inSecondModal == undefined ? false : this.context.inSecondModal;
    }

    if (
      (this.context.isONProject || this.isProjectSaleStandardAdjustmentForAB()) &&
      this.soaCommonExpense.isStandardCommonExpense()
    ) {
      this.soaCommonExpense.commonExpenseAmount = Number(
        this.context.currentMatter.matterPropertyWithCondo.condominiumTotalExpenses
      );
    }

    if (this.context.currentMatter && this.soaCommonExpense.isPOTLCommonExpense()) {
      this.isUsePotlCommonExpenseAmountVisible =
        this.context.currentMatter.matterPropertyWithCondo.isParcelOfTiedLand === 'YES' ||
        (this.context.currentMatter.project && this.context.currentMatter.project.isPOTL);
    }
    this.localInfoOnly = this.context.statementAdjustment ? this.context.statementAdjustment.infoOnly : false;
  }

  isProjectTemplate(): boolean {
    return (
      this.context.currentMatter &&
      (this.context.currentMatter.templateForProject || this.context.currentMatter.isProjectSale)
    );
  }

  public commonExpensePaidDateLabel(): string {
    if (this.isClosingDateValid) {
      return this.soaCommonExpense.commonExpensePaidLabel(this.context.closingDate);
    }

    return '???';
  }

  getCommonExpensePaidLabel(item: SoaCommonExpense): string {
    let labelValue = item.getCommonExpensePaidLabel(
      this.vendorLabel,
      this.purchaserLabel,
      this.commonExpensePaidDateLabel(),
      this.soaCommonExpense.isOccupancyCommonExpense()
    );
    if (labelValue && labelValue.length > 1) {
      let idx = labelValue.indexOf('for');
      if (idx > -1) {
        labelValue = [labelValue.slice(0, idx + 3), '\n', labelValue.slice(idx + 3)].join('');
      }
    }
    return labelValue;
  }

  getCommonShareLabel(item: SoaCommonExpense): string {
    return item.getCommonShareLabel(
      this.vendorLabel,
      this.purchaserLabel,
      this.soaCommonExpense.isOccupancyCommonExpense()
    );
  }

  get closingDate(): string {
    return this.context.closingDate;
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  get creditType(): string {
    switch (
      this.soaCommonExpense.adjustmentCreditType(
        this.closingDate,
        this.context.currentMatter.isPaysForDateOfClosingVendor
      )
    ) {
      case StatementAdjustmentAmountTypes.NO_ADJUSTMENT:
        return 'No Adjustment';
      case StatementAdjustmentAmountTypes.VENDOR:
        return this.vendorLabel;
      case StatementAdjustmentAmountTypes.PURCHASER:
        return this.purchaserLabel;
      default:
        return '';
    }
  }

  appliedTaxRate(): string {
    return this.soaCommonExpense.appliedTaxRate(this.context.provinceHstRateSlab);
  }

  appliedRate(): number {
    if (this.context.currentMatter.isMatterProvinceMB || this.context.currentMatter.isMatterProvinceSK) {
      return this.soaCommonExpense.appliedFederalRate(this.context.provinceHstRateSlab);
    } else {
      return this.soaCommonExpense.appliedRate(this.context.provinceHstRateSlab);
    }
  }

  close(): void {
    if (!this.addedFlag && this.soaCommonExpenseCached) {
      //try to save the value edited on Unit/Level/Plan
      this.dialog.close({soaCommonExpense: this.soaCommonExpenseCached});
    } else {
      this.dialog.close();
    }
  }

  ok(): void {
    this.checkErrorsOnModal();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close({soaCommonExpense: this.soaCommonExpense, infoOnly: this.localInfoOnly});
      if (this.context.statementAdjustment) {
        this.context.statementAdjustment.infoOnly = this.localInfoOnly;
      }
    }
  }

  isZeroAmountIgnored(commonExpenseAmount: number): boolean {
    return this.context.currentMatter.project && !this.context.currentMatter.isProjectSale
      ? true
      : commonExpenseAmount != 0;
  }

  checkErrorsOnModal() {
    this.modalErrorComponent.removeAllDpSaveError();
    if (
      this.modalErrorComponent &&
      !this.isZeroAmountIgnored(this.soaCommonExpense.commonExpenseAmount) &&
      this.soaCommonExpense.usePotlCommonExpenseAmount !== 'YES' &&
      !this.localInfoOnly
    ) {
      this.soaCommonExpense.isOccupancyCommonExpense()
        ? this.modalErrorComponent.createDPSaveError('soa.commonExpenseAmount.occupancy')
        : this.modalErrorComponent.createDPSaveError('soa.commonExpenseAmount');
    }
  }

  geFormattedCurrencyValue(currencyValue: number): string {
    if (
      currencyValue != undefined &&
      !isNaN(currencyValue) &&
      currencyValue != null &&
      currencyValue.toString().trim() != ''
    ) {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  getElapsedDays(): number {
    return this.soaCommonExpense.getElapsedDays(
      this.context.closingDate,
      this.context.currentMatter.isPaysForDateOfClosingVendor
    );
  }

  getElapsedDaysLabel(): string {
    if (this.isClosingDateValid) {
      return this.soaCommonExpense
        .getElapsedDays(this.context.closingDate, this.context.currentMatter.isPaysForDateOfClosingVendor)
        .toString();
    }

    return '???';
  }

  getElapsedDaysText(): string {
    return Utils.getDaysLabel(this.getElapsedDays());
  }

  calculateVendorShare(): number {
    return this.soaCommonExpense.calculateVendorShare(
      this.context.closingDate,
      this.context.currentMatter.isPaysForDateOfClosingVendor
    );
  }

  calculateVendorShareDifference(): number {
    return this.soaCommonExpense.calculateVendorShareDifference(
      this.context.closingDate,
      this.context.currentMatter.isPaysForDateOfClosingVendor
    );
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.soaCommonExpense = new SoaCommonExpense();
          this.dialog.close({soaCommonExpense: undefined});
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  updateTaxRate(rateType: string): void {
    if (
      rateType == this.context.provinceHstRateSlab.rateType &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.hstRate
    ) {
      this.soaCommonExpense.adjustPercentage = this.context.provinceHstRateSlab.hstRate.toString();
    } else {
      this.soaCommonExpense.adjustPercentage = this.context.provinceHstRateSlab.hstFederalPortion.toString();
    }
  }

  selectUnitLevelPlan(): void {
    let modelWidthXl: boolean =
      (this.context.currentMatter.isProjectSale || this.context.currentMatter.templateForProject) &&
      this.context.currentMatter.isMatterProvinceON;
    this.dialogService.matDialogContent({
      content: UnitLevelPlanModalComponent,
      widthXl: modelWidthXl,
      context: {
        propertyJurisdiction: this.context.currentMatter.matterPropertyWithCondo.jurisdiction,
        unitLevelPlanOrg: this.context.currentMatter.matterPropertyWithCondo,
        currentMatter: this.context.currentMatter,
        isReadOnly: false,
        inSecondModal: true,
        isUnitLevelPinReadOnly: true
      },
      onFulfillment: (result: SelectUnitLevelPlanResult) => {
        if (result) {
          if (result.plan) {
            this.updateUnitLevelPlanCreated(result.plan);
            this.context.currentMatter.calculateStatementAdjustment();
          }
        }
      }
    });
  }

  updateUnitLevelPlanCreated(unitLevelPlan: MatterProperty): void {
    this.context.currentMatter.updateUnitLevelPlanCreated(unitLevelPlan);
    if (this.soaCommonExpense.commonExpenseAmount !== Number(unitLevelPlan.condominiumTotalExpenses)) {
      this.soaCommonExpense.commonExpenseAmount = Number(unitLevelPlan.condominiumTotalExpenses);
      this.soaCommonExpenseCached = new SoaCommonExpense(this.soaCommonExpense);
    }
  }

  generateUnitLevelPlan(unitLevelPlan: MatterProperty): string {
    return UnitLevelPlanUtil.generateUnitLevelPlan(unitLevelPlan, this.context.currentMatter.provinceCode);
  }

  generatePinNumber(unitLevelPlan: MatterProperty): string {
    return UnitLevelPlanUtil.generatePinNumber(unitLevelPlan);
  }

  getExpenseTitle(): string {
    if (this.soaCommonExpense && this.soaCommonExpense.isStandardCommonExpense()) {
      return provinceBasedFieldLabels.get('matter.soa.commonExpenses', this.context.currentMatter.provinceCode);
    } else if (this.soaCommonExpense && this.soaCommonExpense.isPOTLCommonExpense()) {
      return 'POTL Common Expenses';
    } else if (this.soaCommonExpense && this.soaCommonExpense.isOccupancyCommonExpense()) {
      return 'Occupancy Fees';
    } else {
      return '';
    }
  }

  getExpenseHeader(): string {
    if (this.soaCommonExpense && this.soaCommonExpense.isStandardCommonExpense()) {
      return provinceBasedFieldLabels.get('matter.soa.commonExpenses', this.context.currentMatter.provinceCode);
    } else if (this.soaCommonExpense && this.soaCommonExpense.isPOTLCommonExpense()) {
      return 'Parcel of Tied Land Common Expenses';
    } else if (this.soaCommonExpense && this.soaCommonExpense.isOccupancyCommonExpense()) {
      return 'OCCUPANCY FEES';
    } else {
      return '';
    }
  }

  getAmountLabel(): string {
    if (this.soaCommonExpense && this.soaCommonExpense.isStandardCommonExpense()) {
      return provinceBasedFieldLabels.get('matter.soa.commonExpenses', this.context.currentMatter.provinceCode);
    } else if (this.soaCommonExpense && this.soaCommonExpense.isPOTLCommonExpense()) {
      return 'POTL common expenses';
    } else if (this.soaCommonExpense && this.soaCommonExpense.isOccupancyCommonExpense()) {
      return 'monthly occupancy fees';
    } else {
      return '';
    }
  }

  get commonExpensesLabel(): string {
    return provinceBasedFieldLabels.get('matter.soa.commonExpenses', this.context.currentMatter.provinceCode);
  }

  isMBSKEditBtnVisible(): boolean {
    return !this.addedFlag && this.context.isMBEditBtnVisible;
  }

  clickMBEditBtn(): void {
    if (
      this.context.parcelLegalDescriptionId &&
      this.context.currentMatter &&
      this.context.currentMatter.matterPropertyWithCondo &&
      this.context.currentMatter.matterPropertyWithCondo.parcelLegalDescriptions
    ) {
      let parcelLegalDescriptionIndex =
        this.context.currentMatter.matterPropertyWithCondo.parcelLegalDescriptions.findIndex(
          (item) => item.id == this.context.parcelLegalDescriptionId
        );
      let parcelLegalDescription =
        this.context.currentMatter.matterPropertyWithCondo.parcelLegalDescriptions[parcelLegalDescriptionIndex];
      this.dialogService.matDialogContent({
        content: TitleRollNumberModal,
        context: {
          titleRollNumber: parcelLegalDescription,
          order: parcelLegalDescriptionIndex + 1,
          isCondominium: DpBooleanValueTypes.YES,
          matter: this.context.currentMatter
        },
        modalGrid: 7,
        onFulfillment: (result: any) => {
          if (result) {
            if (result.titleRollNumber) {
              parcelLegalDescription = result.titleRollNumber;
              this.context.currentMatter.matterPropertyWithCondo.parcelLegalDescriptions[parcelLegalDescriptionIndex] =
                result.titleRollNumber;
              this.context.currentMatter.matterPropertyWithCondo.calcCondominiumFeesFromTitleRollNumbers();
              this.soaCommonExpense.commonExpenseAmount = parcelLegalDescription.commonElementFees;
              this.context.currentMatter.createUpdateAdjustmentCommonExp(parcelLegalDescription);
            }
          }
        }
      });
    } else {
      this.dialog.close({jumpToSubjectProperty: true});
    }
  }

  get isFormatOfAdjustmentItemize(): boolean {
    return this.soaCommonExpense.isFormatOfAdjustmentItemize(
      this.context.currentMatter.matterPropertyWithCondo.condominiumExpenses
    );
  }

  get unitLevelExpenses(): CondominiumExpense[] {
    return this.soaCommonExpense.getUnitLevelExpenses(
      this.context.currentMatter.matterPropertyWithCondo.condominiumExpenses
    );
  }

  isPOTLCommonExpense(): boolean {
    return this.soaCommonExpense && this.soaCommonExpense.isPOTLCommonExpense();
  }

  isProjectSaleStandardAdjustmentForAB() {
    return (
      (this.context.currentMatter.isProjectSale || this.context.currentMatter.templateForProject) &&
      this.context.currentMatter.isMatterProvinceAB &&
      this.soaCommonExpense.isStandardCommonExpense()
    );
  }

  isStandardCommonExpense(): boolean {
    return this.soaCommonExpense && this.soaCommonExpense.isStandardCommonExpense();
  }

  onUsePotlCommonExpenseAmountChange(newSelection): void {
    if (newSelection === 'YES') {
      this.soaCommonExpense.commonExpenseAmount = Number(
        this.context.currentMatter.matterPropertyWithCondo.commonExpenses
      );
    }
  }
}
