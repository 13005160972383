import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Component, Inject} from '@angular/core';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'dp-legend-soa',
  templateUrl: 'legend-soa.modal.component.html',
  styleUrls: ['./legend-soa.modal.component.scss'],
  providers: [DialogService]
})
export class LegendSoaModal extends ModalComponent<void> {
  constructor(
    public dialog: MatDialogRef<any, void>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: void
  ) {
    super(dialog, context);
  }

  close(): void {
    this.dialog.close();
  }
}
