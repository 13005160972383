import {GenderTypes} from '../contact/contact-type-mapping';

export class ProvinceApplicableGenders {

  // This map defines the applicable genders for a province
  public static readonly PROVINCE_GENDER_MAP = new Map<string, string[]>([
    [ 'BC', GenderTypes.ALL.filter(genderType => genderType != GenderTypes.ESTATE) ],
    [ 'DEFAULT', GenderTypes.ALL ]
  ]);

  /**
   * return the applicable genders for a province
   * @param provinceCode
   */
  public static getGendersForProvince(provinceCode: string): string[] {
    return this.PROVINCE_GENDER_MAP.has(provinceCode) ? this.PROVINCE_GENDER_MAP.get(provinceCode) : this.PROVINCE_GENDER_MAP.get('DEFAULT');
  }

}
