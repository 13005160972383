<form #feesform="ngForm" *ngIf="soaConfig" class="form-horizontal overFlow">
    <div class="row" tabindex="0">

        <div class="fees-header">
            Templates for {{matterTypeCapitalized}} Fees
        </div>


            <div class="form-group">
                <div class="btn2 col-lg-12">
                      <input
                            type="button"
                            class="action primary-button"
                            [disabled]="(selectedIndex===0 || selectedIndex === -1 || disableMoveUpMoveDown)"
                            (click)="moveUp()"
                            value="Move Up">
                        <input
                            type="button"
                            class="action primary-button padding-0"
                            [disabled]="(selectedIndex===(fees.length -1) || selectedIndex === -1 || disableMoveUpMoveDown)"
                            (click)="moveDown()"
                            value="Move Down">
                        <input
                            type="button"
                            class="action primary-button"
                            (click)="reset()"
                            value="Reset">

                </div>
            </div>

            <div class="form-group" *dpShowByProvince="'admin.statement.account.config.separateFeeForMortgage';matterType:matterType;code:provinceCode">
                <div class="col-lg-9 padding-top-5">

                    <dp-checkbox fieldId="checkBoxOption">
                        <input type="checkbox"
                               id="checkBoxOption"
                               name="checkBoxOption"
                               [(ngModel)]="soaConfig.separateFeeForMortgage"
                               (ngModelChange)="onSeparateFeeForMortgageChange($event)"
                        />
                    </dp-checkbox>

                    <label class="control-label vertical-align-label display-inline" >Statement of Account to show separate fee for each new mortgage</label>

                </div>
            </div>

            <div *ngIf="fees" class="form-group">
                <table class="table soa-table"  id="sticky-table-t" (keydown)="onKeyPress($event)" sticky-table-header [tableHeightVariance]="100">
                    <thead>
                    <tr>
                        <th><b>Description of Fee</b></th>
                        <th></th>
                        <th><b>F9 Default Amount</b></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngIf="fees.length === 0">
                        <td ></td>
                        <td align="center">
                            <dp-burger-menu [items]="burgerMenuItemWithoutDelete"
                                            (itemClicked)="burgerMenuClicked($event, null)">
                            </dp-burger-menu>
                        </td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let item of fees; let i=index;" (click)="selectedItem(item)" (keyup)="keyUpSelectedItem($event,item)"
                        [ngClass]="{'selected-active': item.isSelected }">
                        <td >

                                <textarea autoresize rows="1" minHeight="30" maxlength="200"  id="feeName-{{item.identifier}}" name="FeeName-{{item.identifier}}"
                                       [(ngModel)]="item.nameOfFees"
                                       (ngModelChange)="setFormDirty()"
                                       [ngClass]="{'selected-active': item.isSelected }"
                                       [attr.readonly]="(item.readOnly ? 'readonly' : null)"
                                       [style.color]="item.readOnly ?'#cccccc' : ''" >
                                </textarea>

                        </td>
                        <td align="center">
                            <dp-burger-menu [items]="item.readOnly ? burgerMenuItemWithoutDelete : burgerMenuItems"
                                            (itemClicked)="burgerMenuClicked($event, item)"></dp-burger-menu>
                        </td>
                        <td>

                                <input type="text" *ngIf="!item.calculated" id="feeValue-{{item.identifier}}" name="feeValue-{{item.identifier}}"
                                       [(ngModel)]="item.f9DefaultAmount"
                                       (ngModelChange)="setFormDirty()"
                                       [ngClass]="{'selected-active': item.isSelected }"
                                       dp-currency
                                       dp-default-currency/>
                            <input type="text" *ngIf="item.calculated" id="calculateValue-{{item.identifier}}" name="calculateValue-{{item.identifier}}"
                                   readonly = "true"
                                   value="Calculated"
                                   [ngClass]="{'selected-active': item.isSelected }"
                                   [style.color]="item.readOnly && !soaConfig.subjectToHst  ?'#cccccc' : ''"
                            />

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

</form>
