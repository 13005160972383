import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoAdjForm4Charge} from '../model/so-adj-form-4-charge';
import {Matter} from '../../shared';

export class StatementAdjustmentDisplayForm4Charge {

  static getDisplayItems(soAdj: SoAdjForm4Charge, mainBuilder: StatementAdjustmentDisplayBuilder, matter: Matter): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    sadItemDetails.push(mainBuilder.getNewItemLine(`${ soAdj.textReferencingAgreement ? soAdj.textReferencingAgreement : '' }`, ``));

    sadItemDetails.push(mainBuilder.getNewItemLine(`${ soAdj.numberOfForm4Issued } Form 4's issued @ ${ mainBuilder.getCurrency(soAdj.costPerForm4) }`,
      `${ mainBuilder.getCurrency(soAdj.calculateForm4SubTotal) }`));

    let taxAmountLabel: string = soAdj.adjustedForType == 'YES' || soAdj.adjustForPst == 'YES' ? soAdj.taxAmountLabel : '';
    if (soAdj.adjustedForType == 'YES' || soAdj.adjustForPst == 'YES') {
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ taxAmountLabel }`,
        `${ mainBuilder.getCurrency(soAdj.calculateTaxAmount) }`));
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ mainBuilder.vendorLabel }`, ``));

    return sadItemDetails;
  }
}
