import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {HttpClient, TabsService} from '../../core';
import {Observable} from 'rxjs/Rx';
import {Matter} from '../../matters/shared';
import {chicagoTitleApi} from './chicago-title-constants';
import {ChicagoSubmitResponse} from './chicago-submit-response';
import {ChicagoGetAllStatusDetailsResponse} from './chicago-get-all-status-details-response';
import {ChicagoBaseResponse} from './chicago-base-response';
import {ChicagoGetDocumentResponse} from './chicago-get-document-response';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {MatterTitleInsurance} from '../../matters/shared/matter-title-insurance';
import {ChicagoCancelRequest} from './chicago-cancel-request';

@Injectable()
export class ChicagoTitleService {

  constructor(private http: HttpClient, private logger: Logger, private tabsService: TabsService) {
  }

  submitChicagoTitleFileRequest(matter: Matter): Observable<ChicagoSubmitResponse> {
    let url: string = chicagoTitleApi.submitFile.replace('{matterId}', '' + matter.id);
    return this.http.post(url, matter.id) //As post method require some data therefore passing matter id in it, though it's present in url too.
    .map((response) => {
      return new ChicagoSubmitResponse(response[ 'ChicagoTitleSubmitResponse' ]);
    });
  }

  getAllStatusDetails(matter: Matter, lockScreen?: boolean): Observable<ChicagoGetAllStatusDetailsResponse> {
    let url: string = chicagoTitleApi.getAllStatusDetails.replace('{matterId}', '' + matter.id);
    return this.http.get(url, lockScreen ? lockScreen : false)
    .map((res) => {
      let response: ChicagoGetAllStatusDetailsResponse = new ChicagoGetAllStatusDetailsResponse(res[ 'ChicagoGetAllStatusDetailsResponse' ]);
      return response;
    }).catch(e => {
      return Observable.of(null);
    });
  }

  cancelTitleInsurance(matterId: number, dealId: string, chicagoCancelRequest: ChicagoCancelRequest): Observable<ChicagoBaseResponse> {
    let url: string = chicagoTitleApi.cancelTitleInsurance.replace('{matterId}', '' + matterId).replace('{titleInsuranceId}', dealId);
    return this.http.post(url, chicagoCancelRequest)
    .map((response) => {
      return new ChicagoBaseResponse(response[ 'ChicagoResponseObject' ]);
    });
  }

  downloadDocument(matter: Matter, documentNumber: number): Observable<ChicagoGetDocumentResponse> {
    let url: string = chicagoTitleApi.downloadDocument.replace('{matterId}', '' + matter.id).replace('{titleInsuranceId}', matter.matterTitleInsurance.dealId).replace('{documentNumber}', '' + documentNumber);
    return this.http.get(url)
    .map((res) => {
      return new ChicagoGetDocumentResponse(res[ 'ChicagoGetDocumentResponse' ]);
    });
  }

  checkDownloadsAvailable(errorService: ErrorService, matterTitleInsurance: MatterTitleInsurance): void {
    if (matterTitleInsurance.isChicagoReadyForPickup() && matterTitleInsurance.isChicagoDocumentsPresent() && !matterTitleInsurance.isChicagoDownloadsCompleted()) {
      let message = 'New Document(s) available';
      errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.chicagoTitle.documentsAvailable', message, 'Chicago Title', 'NOTIFICATION', null, null, 'M220001'));
    }
  }

  deleteAllDocuments(matterId: number, dealId: string): Observable<any> {
    let url: string = chicagoTitleApi.deleteDocuments.replace('{matterId}', '' + matterId).replace('{titleInsuranceId}', dealId);
    return this.http.delete(url);
  }

}
