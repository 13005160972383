<div class="col-lg-12 text-right padding-top-10 padding-bottom-10" *ngIf="(thirdPartyUser && !thirdPartyUser.id) || reValidateUser">
    <div class="form-group">
        <label class="control-label col-lg-2">User Name *</label>
        <div class="col-lg-5">
            <input type="text"
                   name="thirdPartyUserName"
                   id="thirdPartyUserName"
                   class="form-control focus-first"
                   [(ngModel)]="thirdPartyUser.userName"
                   [dp-read-only]="reValidateUser"
                   [maxlength]="getUsernameMaxLength()"
                   (keydown.Shift.tab)="firstFieldKeyCommands()"
                   class="form-control"
                   trimmed
            />
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-lg-2">Password *</label>
        <div class="col-lg-5">
            <input type="text"
                   name="thirdPartyPassword"
                   id="thirdPartyPassword"
                   [maxlength]="getPasswordMaxLength()"
                   [(ngModel)]="thirdPartyUser.password"
                   class="form-control"
            />
        </div>
        <div class="col-lg-3 text-left">
            <span class="actionlink">
                <span>
                    <a role="link" tabindex="0" (click)="openForgotPassword()" (keyup.Enter)="openForgotPassword()">
                        Forgot Password
                    </a>
                </span>
            </span>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="row padding-top-10">
            <div class="col-lg-7">
                <button type="button" class="dp-btn" [disabled]="!isValidateButtonEnabled" (click)="validateThirdPartyUser()">
                    <span>Validate</span>
                </button>
            </div>
            <div class="col-lg-5" *ngIf="reValidateUser" style="padding-right: 250px">
                <button type="button" class="dp-btn dp-btn-cancel"  (click)="cancelRevalidate()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="col-lg-12 text-right padding-top-10 padding-bottom-10 margin-bottom-140"
     *ngIf="(thirdPartyUser && thirdPartyUser.id ) && !reValidateUser">
    <div class="form-group">
        <label class="control-label col-lg-2">User Name *</label>
        <div class="col-lg-5">
            <input type="text"
                   id="thirdPartyUserName1"
                   name="thirdPartyUserName1"
                   [ngModel]="thirdPartyUser.userName"
                   dp-read-only="true"
                   class="form-control focus-first"
            />
            <div class="width-30 actionbtns">
                <dp-burger-menu
                    [items]="burgerMenuItems"
                    (itemClicked)="burgerMenuClicked($event)">
                </dp-burger-menu>
            </div>
        </div>
    </div>
</div>
