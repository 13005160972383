import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class AssystPayoutMessagesModalContext {
  fieldsUpdatedAfterAssystPayoutSubmission: string [];
}

@Component({
  selector: 'dp-assyst-payout-messages-modal-content',
  templateUrl: 'assyst-payout-messages.modal.component.html'
})
export class AssystPayoutMessagesModalComponent extends ModalComponent<AssystPayoutMessagesModalContext> {

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(public dialog: MatDialogRef<AssystPayoutMessagesModalComponent>,
              public appConfig: AppConfig,
              @Inject(MAT_DIALOG_DATA) context?: AssystPayoutMessagesModalContext) {
    super(dialog, context);
  }

  close() {
    this.dialog.close();
  }
}
