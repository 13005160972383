<!-- modal -->
<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Upload Files</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">


        <div *ngIf="!uploadedFiles">
            <div class="form-group" *ngIf="preUploadValidationIssues?.length > 0">
                <div class="col-lg-12">
                    <div class="main-level">
                        <span class="title col-lg-12 margin-bottom-0">
                        The following files can't be uploaded due to issues preventing upload
                        </span>
                    </div>
                    <table class="standard-table margin-bottom-20 margin-top-5">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Reason</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let issue of preUploadValidationIssues">
                            <td>
                                <i class="fa fa-warning text-warning"></i>
                                {{issue.fileName}}
                            </td>
                            <td>{{issue.description}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="form-group" *ngIf="replacingFiles?.length > 0">
                <div class="col-lg-12">
                    <div class="main-level">
                        <span class="title col-lg-12 margin-bottom-0">
                        The following files already exist. Please select the files to be replaced
                        </span>
                    </div>
                    <table class="standard-table  margin-bottom-20 margin-top-5">
                        <thead>
                        <tr>
                            <th class="padding-left-8">
                                <dp-checkbox fieldId="toggleAll">
                                    <input type="checkbox" id="toggleAll" [ngModel]="allReplacingSelected" (ngModelChange)="toggleAllReplacingFiles()"/>
                                </dp-checkbox>
                            </th>
                            <th>File Name</th>
                            <th>Unity Document Date</th>
                            <th>Local File</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let uploadFile of replacingFiles; let i = index" (click)="toggleReplacementFileInclusion(uploadFile.name)">
                            <td>
                                <dp-checkbox fieldId="replacingFile-{{i}}">
                                    <input type="checkbox" id="replacingFile-{{i}}" (change)="toggleReplacementFileInclusion(uploadFile.name)"
                                           [checked]="isSelected(uploadFile.name)"/>
                                </dp-checkbox>
                            </td>
                            <td>{{uploadFile.name}}</td>
                            <td>{{replacedFileDates && replacedFileDates.get(uploadFile.name) | date:'MMM d, y hh:mm a'}}</td>
                            <td>{{uploadFile.lastModified | date:'MMM d, y hh:mm a'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="form-group" *ngIf="newFiles?.length > 0">
                <div class="col-lg-12">
                    <div class="main-level">
                        <span class="title col-lg-12 margin-bottom-0">
                        The following files will be uploaded
                        </span>
                    </div>
                    <table class="standard-table margin-bottom-20 margin-top-5">
                        <thead>
                        <tr>
                            <th>File Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let uploadFile of newFiles">
                            <td>
                                {{uploadFile.name}}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

        <div *ngIf="uploadedFiles?.length > 0">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="padding-left-10">
                        <span *ngIf="uploadInProgress;else notInProgress">
                            Upload in progress
                        </span>
                        <ng-template #notInProgress>
                        <span *ngIf="numberOfUploadErrors > 0 && numberOfSuccessfullyUploadedFiles > 0">
                            <i class="fa fa-info-circle text-warning"></i>
                            Upload completed, however with errors: {{numberOfSuccessfullyUploadedFiles}} success, {{numberOfUploadErrors}} error(s)
                        </span>
                            <span *ngIf="numberOfUploadErrors == uploadedFiles.length ">
                                <i class="fa fa-ban text-danger"></i>
                                None of the files were uploaded
                            </span>
                            <span *ngIf="numberOfUploadErrors == 0">
                                <i class="fa fa-check text-success"></i>
                                Upload completed successfully
                            </span>
                        </ng-template>
                    </div>

                    <table class="standard-table">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Upload status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let file of uploadedFiles">
                            <td>{{file.fileName}}</td>
                            <td>
                                <i class="fa fa-check text-success" *ngIf="isComplete(file.status)"></i>
                                <span *ngIf="isFailed(file.status)">
                                    <i class="fa fa-ban text-danger" ></i> ({{getFailureInfo(file.status, file.message)}})
                                </span>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>








    </div>
    <div class="row modal-footer">
        <div [style.visibility]="uploadPercentage < 0 ? 'hidden' : 'visible'">
            <div class="progress" style="height: 5px">
                <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [style.width]="uploadPercentage + '%'">

                </div>
            </div>
        </div>
        <div class="col-lg-12 buttons" *ngIf="uploadPercentage < 100 && !onlyValidationErrorsToDisplay()">
            <button type="button" *ngIf="isUserUploadInitiationRequired()"
                    id="okBtn"
                    (click)="performUpload()"
                    (keyup.enter)="performUpload()"
                    [disabled]="!isProceedWithUploadActive()"
                    tabindex="0"
                    class="dp-btn">
                <span>Proceed</span>
            </button>
            <button type="button" *ngIf="isUploadInProgress()"
                    (click)="cancelUploadInProgress()"
                    (keyup.enter)="cancelUploadInProgress()"
                    tabindex="0"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel Upload</span>
            </button>
            <button type="button" *ngIf="!isUploadInProgress()"
                    (click)="cancel()"
                    (keyup.enter)="cancel()"
                    tabindex="0"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>

        </div>

        <div class="col-lg-12 buttons" [hidden]="!(uploadPercentage == 100 || onlyValidationErrorsToDisplay())">
            <button type="button"
                    (click)="ok()"
                    (keyup.enter)="ok()"
                    tabindex="0"
                    class="dp-btn dp-btn-green focus-first">
                <span>OK</span>
            </button>

        </div>

    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
