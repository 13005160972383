<ng-container class="container-fluid custom-modal-container">
    <div  class="padding-bottom-10 view-table  position-relative" *ngIf="documents">
        <div class="col-lg-12">
            <table class="standard-table col-lg-12 sticky-table" [mfData]="rows" #mf="mfDataTable" [attr.tabindex]="-1"
                   (keydown)="tableKeyCommands($event)"  >

                <thead class="sticky-table-header">
                <tr>
                    <th  class="table-dp-cell-2" *ngIf="!hideCheckBoxes">
                        <div class="wrap-header">
                            <dp-checkbox fieldId="templateCheckBoxAll">
                                <input type="checkbox"
                                       id="templateCheckBoxAll"
                                       name="templateCheckBoxAll"
                                       [dp-read-only]="isCheckBoxAllTemplateReadOnly()"
                                       [(ngModel)]="checkBoxAllTemplate"
                                       (checked)="selectedDocuments.length > 0"
                                       (ngModelChange)="selectAllTemplate()"

                                />
                            </dp-checkbox>
                        </div>
                    </th>
                    <th  class="{{'table-dp-cell-1'}}" (click)="sortDocumentName.sort();" id="sortDocumentName">
                        <div class="wrap-header" style="left:-10px;">File Name
                            <dp-default-sorter #sortDocumentName by="documentName" [singleState]="true"></dp-default-sorter></div>
                    </th>

                    <th  class="{{'table-dp-cell-3'}}" (click)="sortDescription.sort();"
                         id="sortDescription"><div class="wrap-header">Description
                        <dp-default-sorter  #sortDescription by="description" [singleState]="true"></dp-default-sorter></div>
                    </th>
                </tr>
                </thead>
                <tbody>

                <ng-container *ngFor="let row of mf.data; let i = index;">
                    <tr [attr.tabindex]="0">
                        <td *ngIf="!hideCheckBoxes">
                            <dp-checkbox fieldId="templateCheckBox-{{i}}">
                                <input type="checkbox"
                                       id="templateCheckBox-{{i}}"
                                       name="templateCheckBox-{{i}}"
                                       [dp-read-only]="row.isInfected || row.isVirusScanPending"
                                       (click)="selectDocument(row ,  $event )"
                                       [checked]="isDocumentSelected(row)"

                                />
                            </dp-checkbox>
                        </td>
                        <td   class="{{'table-dp-cell-1'}}">
                            <span *ngIf="row.isInfected || row.isVirusScanPending">
                                    <i class="virus-icon"     [ngClass]="row.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>
                                    <span class="matter-locked-message virus-message">
                                                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                                   <span *ngIf="row.isInfected">
                                      We have detected this file is infected with a virus.
                                  </span>
                                         <span *ngIf="row.isVirusScanPending">
                                      Please wait... we are currently scanning this file for viruses.
                                  </span>
                                </span>
                            </span>
                            <a *ngIf="isDownoadDocumentsAllowed" (click)="downloadDocument(row)">
                                <span title="{{row.documentName}}" *ngIf="documentUtility.doesValueNeedTriming(row.documentName, 100)">
                                    {{row.documentName | slice:0:97}}...
                                </span>
                                <span title="{{row.documentName}}"  *ngIf="!documentUtility.doesValueNeedTriming(row.documentName, 100)">
                                   {{row.documentName}}
                                </span>
                            </a>
                            <ng-container *ngIf="!isDownoadDocumentsAllowed">
                                <span title="{{row.documentName}}" *ngIf="documentUtility.doesValueNeedTriming(row.documentName, 67)">
                                    {{row.documentName | slice:0:64}}...
                                </span>
                                <span title="{{row.documentName}}"  *ngIf="!documentUtility.doesValueNeedTriming(row.documentName, 67)">
                                   {{row.documentName}}
                                </span>
                            </ng-container>

                        </td>


                        <td  class="{{'table-dp-cell-3'}}">

                                <span title="{{row.description}}" *ngIf="documentUtility.doesValueNeedTriming(row.description, 85)">
                                    {{row.description | slice:0:82}}...
                                </span>
                            <span title="{{row.description}}" *ngIf="!documentUtility.doesValueNeedTriming(row.description, 85)">
                                   {{row.description}}
                                </span>

                        </td>
                    </tr>
                </ng-container>
                    <tr>
                        <td colspan="3" class="text-center" *ngIf="rows?.length==0">
                            <div class="no-match-found">No Attachments Found</div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>




</ng-container>
