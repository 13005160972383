
<div class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Edit Payable To</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div class="col-lg-12 padding-top-5 padding-left-40">
            <div class="form-group" *ngIf="context.matter.isProjectSale">
                <dp-checkbox fieldId="adoptProjectSOA" >
                    <input id="adoptProjectSOA"
                           name="adoptProjectSOA"
                           type="checkbox"
                           (change)="toggleAdoptProjectSoaPayableTo()"
                           [checked]="payableTo.adoptProjectSoaPayableTo"
                    />
                </dp-checkbox> <label class="control-label vertical-align-label display-inline" for="adoptProjectSOA">Check to adopt Project SOA 'Payable to'</label>
            </div>
            <div class="form-group">
                <span>
                    Payable to (enter how the balance due on closing should be payable): (Text entered will be preceded by the words "Payable to:" and followed by "or as further directed")
                </span>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="form-group">
                <div class="col-lg-12 padding-left-40 padding-right-20">
                    <input class="form-control"
                           maxlength="250"
                           statusBarHelp
                           [dynamicHelpCallBackFunction]="generateF9()"
                           (keydown.f9)="handleF9()"
                           [(ngModel)]="payableTo.payableTo"
                           (ngModelChange)="markDirty()"
                           name="payableTo"
                           id="payableTo"
                            [dp-read-only]="context.matter.isProjectSale && payableTo.adoptProjectSoaPayableTo"
                    />
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="form-group">
                <div class="col-lg-9 padding-left-40">
                    <dp-checkbox fieldId="excludePrefix">
                        <input type="checkbox"
                               id="excludePrefix"
                               name="excludePrefix"
                               [(ngModel)]="payableTo.excludePrefix"
                               [checked]="payableTo.excludePrefix"
                               (change)="markDirty()"  [dp-read-only]="context.matter.isProjectSale && payableTo.adoptProjectSoaPayableTo"/>
                    </dp-checkbox>
                    <label class="control-label vertical-align-label display-inline" >Check to omit the words "Payable to:"</label>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="form-group">
                <div class="col-lg-9 padding-left-40">
                    <dp-checkbox fieldId="excludeSuffix">
                        <input type="checkbox"
                               id="excludeSuffix"
                               name="excludeSuffix"
                               [(ngModel)]="payableTo.excludeSuffix"
                               [checked]="payableTo.excludeSuffix"
                               (change)="markDirty()" [dp-read-only]="context.matter.isProjectSale && payableTo.adoptProjectSoaPayableTo" />
                    </dp-checkbox>
                    <label class="control-label vertical-align-label display-inline" >Check to omit the words "or as further directed"</label>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <div class="row">
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </div>
</div>
