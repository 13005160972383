import {ThirdPartyDataValidationService} from '../third-party/third-party-data-validation-service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {constValues, Matter, MatterParticipant, MatterProperty, Utils as SharedUtils} from '../../matters';
import {DpFooterTypes} from '../../shared/error-handling/dp-footer-notification';
import {Mortgage} from '../../matters/shared/mortgage';
import {Injectable} from '@angular/core';
import {MortgageInstrument} from '../../matters/shared/mortgage-instrument';
import {CondominiumPlan} from '../../matters/property-teranet/unit-level-plan/condominium-plan';
import {CondominiumExpense} from '../../matters/property-teranet/unit-level-plan/condominium-expense';
import {MortgageDispositionType} from '../constants';
import {ParcelLegalDescription} from '../../matters/shared/parcel-legal-description';
import {provinceBasedFieldLabels} from '../province-based-field-labels';
import {StewartAssystUser} from './stewart-assyst-user';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import Utils from '../utils';
import {AppConfig} from '../app-configuration';

@Injectable({
  providedIn: 'root'
})
export class TelusAssystDataValidationService extends ThirdPartyDataValidationService {

  CollateralMortgage_LoanNumber_RequiredDigits = 11;
  NonCollateralMortgage_LoanNumber_RequiredDigits = 10;
  CollateralMortgage_BMO_LoanNumber_RequiredDigits = 16;
  CollateralMortgage_BMO_LoanNumber_Prefix_BMO = '91052';

  constructor(protected errorService: ErrorService, public appConfig: AppConfig) {
    super(errorService);
  }

    validateForPayoutStatement(matter: Matter, mortgage: Mortgage, stewartAssystUser: StewartAssystUser) {
        this.errorService.clearAllMissingFieldErrors();
        this.errorService.setMissingFieldType(DpFooterTypes.THIRDPARTY);
        this.errorService.setMissingFieldHeader('Lender Centre Payout and Discharge – Missing Data');

    this.validateVendorsAndMortgagors(matter);
    this.validateMortgage(matter, mortgage);
    this.validateProperty(matter);

    this.validateMatterCloseDate(matter);
    this.validatePropertyCity(matter);
    this.validatePropertyPostalCode(matter);
    this.validatePropertyProvince(matter);
    this.validatePropertyStreet(matter);
    this.validateMortgageLoanNumber(matter, mortgage);

    this.validateCredentials(stewartAssystUser);
    this.validatePinNumberForCondo(matter);
  }

  validatePinNumberForCondo(matter: Matter) {
    if (matter.isPropertyCondo() && matter.matterPropertyWithCondo && matter.isMatterProvinceABorONorNBorNS) {
      if (matter.matterPropertyWithCondo.condominiumExpenses.length === 0) {
        if (!matter.matterPropertyWithCondo.pin) {
          this.showErrorMessageWithPropertyIdentifier(matter);
        }
      } else {
        if (matter.matterPropertyWithCondo.condominiumExpenses.every(items => matter.isMatterProvinceAB ? !items.lincNumber && !items.unitNumber && !Utils.isEmptyOrUndefinedValue(items) : matter.isMatterProvinceONorNBorNS ? !items.pinNumber && !items.unitNumber && !Utils.isEmptyOrUndefinedValue(items) : null)) {
          this.showErrorMessageWithPropertyIdentifier(matter);
        }
        matter.matterPropertyWithCondo.condominiumExpenses.forEach((item, index: number) => {
          if (matter.isMatterProvinceAB) {
            if (!item.lincNumber) {
              this.showErrorMessageForPinLinc(matter, item, index);
            }
          } else if (matter.isMatterProvinceONorNBorNS) {
            if (!item.pinNumber) {
              this.showErrorMessageForPinLinc(matter, item, index);
            }
          }
        });
      }
    }
    if (matter.isPropertyCondo() && matter.matterProperties && matter.isMatterProvinceMB) {
      matter.matterProperties.forEach(parcel => {
        if (parcel.parcelLegalDescriptions.length === 0) {
          this.showErrorMessageWithPropertyIdentifier(matter);
        }
      });
    }
  }

  showErrorMessageForPinLinc(matter, item, index) {
    if (item.unitNumber) {
      this.showErrorMessageWithUnitNumber(matter, item, index);
    }
    if (Utils.isEmptyOrUndefinedValue(item)) {
      this.showErrorMessageWithRowNumber(matter, item, index);
    }
  }

  showErrorMessageWithPropertyIdentifier(matter) {
    this.addCustomDataValidationError('integrations.unitLevelPlanForCondo.pin.missing',
      `At least one ${ this.propertyTypeIdentifier(matter) } is required`, 'Property', 'ERROR');
  }

  showErrorMessageWithUnitNumber(matter, items, index) {
    this.addCustomDataValidationError(`integrations.unitLevelPlanForCondoWithUnit.${ index + 1 }.pin.missing`,
      `${ this.propertyTypeIdentifier(matter) } is required for Condo Unit #${ items.unitNumber }`, 'Property', 'ERROR');
  }

  showErrorMessageWithRowNumber(matter, items, index) {
    this.addCustomDataValidationError(`integrations.unitLevelPlanForCondoWithUnit.${ index + 1 }.pin.missing`,
      `${ this.propertyTypeIdentifier(matter) } is required for Row #${ index + 1 }`, 'Property', 'ERROR');
  }

    validateCredentials(stewartAssystUser: StewartAssystUser) {
        if (!stewartAssystUser) {
            this.addCustomDataValidationError('integrations.telusAssyst.solicitor.credentials', "A Solicitor with Unity® Lender Centre Credentials is required", 'Matter Opening', "ERROR");
        }
    }

  validateVendorsAndMortgagors(matter: Matter) {
    if (matter.mainClients.length) {
      matter.mainClients.forEach((mainClient: MatterParticipant, index: number) => {
        if (mainClient.contact && mainClient.contact.isPerson && !mainClient.contact.firstName) {
          this.addCustomDataValidationError('integrations.telusAssyst.mainClient.firstName.' + mainClient.identifier,
            `${ this.vendorOrMortgagor(matter) } ${ index + 1 } First name is required.`, this.vendorsMortgagorsTopic(matter), 'ERROR');
        }
        if (mainClient.contact && mainClient.contact.isPerson && !mainClient.contact.lastName) {
          this.addCustomDataValidationError('integrations.telusAssyst.mainClient.lastName.' + mainClient.identifier,
            `${ this.vendorOrMortgagor(matter) } ${ index + 1 } Last name is required.`, this.vendorsMortgagorsTopic(matter), 'ERROR');
        }
        if (mainClient.contact && !mainClient.contact.isPerson && !mainClient.contact.organizationName) {
          this.addCustomDataValidationError('integrations.telusAssyst.mainClient.companyName.' + mainClient.identifier,
            `${ this.vendorOrMortgagor(matter) } ${ index + 1 } Company name is required.`, this.vendorsMortgagorsTopic(matter), 'ERROR');
        }
      });
    } else {
      this.addCustomDataValidationError('integrations.telusAssyst.vendor.missing',
        `At least one ${ this.vendorOrMortgagor(matter) } is required.`, this.vendorsMortgagorsTopic(matter), 'ERROR');
    }
  }

  validateMortgageLoanNumber(matter: Matter, mortgage: Mortgage) {
    if (mortgage) {
      // ToDo move this to a function on 3.1
      const mortgageIndex = matter.existingMortgages.findIndex(item => item.id == mortgage.id);
      const mortgageTopic: string = `Existing ${ mortgageIndex + 1 }${ this.ordinalSuffix(mortgageIndex + 1) } Mortgage`;

      if (!mortgage.loanNo) {
        this.addDataValidationErrorWithCustomTopic('integrations.telusAssyst.loanNumber', mortgageTopic);
      } else {
        const loanNo = mortgage.loanNo.replace(/\D/g, '');
        if (mortgage.collateralMortgage === 'N_y' || mortgage.collateralMortgage === 'NO') {
          if (loanNo.length !== this.NonCollateralMortgage_LoanNumber_RequiredDigits) {
            this.addDataValidationErrorWithCustomTopic('integrations.telusAssyst.collateralMortgage.no', mortgageTopic);
          }
        } else {
          if (!(loanNo.length == this.CollateralMortgage_LoanNumber_RequiredDigits || (loanNo.length == this.CollateralMortgage_BMO_LoanNumber_RequiredDigits
            && loanNo.substring(0, 5) == this.CollateralMortgage_BMO_LoanNumber_Prefix_BMO))) {
            this.addDataValidationErrorWithCustomTopic('integrations.telusAssyst.collateralMortgage.yes', mortgageTopic);
          }
        }
      }
    }
  }

  regOrDoc(provinceCode: ProvinceCode) {
    return provinceCode == 'NS' ? 'Doc' : 'Reg';
  }

  validateMortgage(matter: Matter, mortgage: Mortgage) {
    if (matter && matter.existingMortgages.length && mortgage) {
      const mortgageIndex = matter.existingMortgages.findIndex(item => item.id == mortgage.id);
      const mortgageTopic: string = `Existing ${ mortgageIndex + 1 }${ this.ordinalSuffix(mortgageIndex + 1) } Mortgage`;

      this.validateMortgageRegNo(matter.provinceCode, mortgage, mortgageTopic);
      if ([ 'ON', 'NS', 'NB' ].indexOf(matter.provinceCode) > -1) {
        if (!mortgage.mortgageRequestDate) {
          this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.registrationDate`,
            `The Mortgage ${ this.regOrDoc(matter.provinceCode) }. Date is required`,
            mortgageTopic, 'ERROR');
        }
      }

      if ([ 'ON', 'NS', 'NB' ].indexOf(matter.provinceCode) > -1) {
        if (SharedUtils.isDatePartial(mortgage.mortgageRequestDate)) {
          this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.requestDate`,
            `The Mortgage ${ this.regOrDoc(matter.provinceCode) }. Date is required.`,
            mortgageTopic, 'ERROR');
        }
      }
      this.validateMortgageType(mortgage, matter, mortgageTopic);

      if ((mortgage.mortgageDispositionType === MortgageDispositionType.DISCHARGED || mortgage.mortgageDispositionType === MortgageDispositionType.BRIDGE_FINANCING) && mortgage.relatedInstruments && mortgage.relatedInstruments.length) {
        mortgage.relatedInstruments.forEach((relatedInstrument: MortgageInstrument, index: number) => {
          if (relatedInstrument && relatedInstrument.instrumentType) {

            this.validateMortgageRelatedInstrumentRegNo(matter.provinceCode, relatedInstrument, index, mortgage, mortgageTopic);

            if ([ 'ON', 'NS', 'NB' ].indexOf(matter.provinceCode) > -1) {
              if (!relatedInstrument.registrationDate || SharedUtils.isDatePartial(relatedInstrument.registrationDate)) {
                this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.relatedInstrument.${ index }.registrationDate`,
                  `The Related instrument ${ index + 1 } Mortgage Registration Date is required.`,
                  mortgageTopic, 'ERROR');
              }
            }
          }
        });
      }

    }
  }

  private validateMortgageType(mortgage: Mortgage, matter: Matter, mortgageTopic: string) {
    const provinceCode: string = matter.matterProperties && matter.matterProperties.length && matter.matterProperties[ 0 ].address && matter.matterProperties[ 0 ].address.provinceCode ?
      matter.matterProperties[ 0 ].address.provinceCode :
      matter.provinceCode;
    const displayError =
      [ 'SK' ].indexOf(provinceCode) > -1 && [ 'Mortgage', 'Caveat', 'Charge', 'Assignment of Rents' ].indexOf(mortgage.financingType) == -1 ||
      [ 'ON', 'NB', 'NS' ].indexOf(provinceCode) > -1 && [ 'Mortgage', 'Charge' ].indexOf(mortgage.financingType) == -1 ||
      [ 'AB', 'MB' ].indexOf(provinceCode) > -1 && [ 'Mortgage', 'Caveat', 'Charge' ].indexOf(mortgage.financingType) == -1;
    if (displayError) {

      this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.financingType`,
        'The Existing Mortgage Type selected is not supported',
        mortgageTopic, 'ERROR');
    }
  }

  mortgageRegNoValidationRules = {
    'AB': {
      regex: /^\d{3}\s\d{3}\s\d{3}$/,
      errMsg: 'Mortgage Reg. No. must be in the following format 999 999 999'
    },
    'ON': {
      regex: /^[a-zA-Z0-9]{1,9}$/,
      errMsg: 'Mortgage Reg. No. should not exceed 9 characters'
    },
    'MB': {
      regex: /^[a-zA-Z0-9\\_-]{1,13}\/[1-6]$/,
      errMsg: 'Mortgage Reg. No. must be in the following format, 3 to 13 digits / LTO designation number, e.g. 1234567890/1'
    },
    'NS': {
      regex: /^[a-zA-Z0-9]{1,10}$/,
      errMsg: 'Mortgage Doc. No. should not exceed 10 characters'
    },
    'SK': {
      regex: /^\d{0,10}$/,
      errMsg: 'Mortgage Reg. No. must be numeric and should not exceed 10 digits'
    },
    'NB': {
      regex: /^[a-zA-Z0-9]{1,10}$/,
      errMsg: 'Mortgage Reg. No. should not exceed 10 characters'
    }
  };

  validateMortgageRegNoByProvince(provinceCode: ProvinceCode, mortgagePriority: number, mortgageRequestNo: string, mortgageTopic: string) {
    if (this.mortgageRegNoValidationRules[ provinceCode ]) { // just in case a province does not need validation
      if (!this.mortgageRegNoValidationRules[ provinceCode ][ 'regex' ].test(mortgageRequestNo)) {
        this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgagePriority }.registrationNo.format`,
          this.mortgageRegNoValidationRules[ provinceCode ][ 'errMsg' ],
          mortgageTopic, 'ERROR');
      }
    }
  }

  validateMortgageRegNo(matterProvince: ProvinceCode, mortgage: Mortgage, mortgageTopic: string) {
    if (mortgage) {
      if ([ 'NB', 'NS' ].indexOf(matterProvince) > -1) {
        if (!mortgage.rollBookNumber && !mortgage.framePageNumber && !mortgage.mortgageRequestNo) {
          this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.registrationNo`,
            `Mortgage ${ this.regOrDoc(matterProvince) }. No. is required if Book and Frame have not been entered`,
            mortgageTopic, 'ERROR');
        } else if (mortgage.mortgageRequestNo) {
          this.validateMortgageRegNoByProvince(matterProvince, mortgage.mortgagePriority, mortgage.mortgageRequestNo, mortgageTopic);
        }
      } else {
        if (!mortgage.mortgageRequestNo) {
          this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.registrationNo`,
            `Mortgage Reg. No. is required`,
            mortgageTopic, 'ERROR');
        } else {
          this.validateMortgageRegNoByProvince(matterProvince, mortgage.mortgagePriority, mortgage.mortgageRequestNo, mortgageTopic);
        }
      }
    }
  }

  relatedInstrumentRegNoValidationRules = {
    'AB': {
      regex: /^\d{3}\s\d{3}\s\d{3}$/,
      errMsg: 'Related Instrument <index> Registration Number must be in the following format 999 999 999'
    },
    'ON': {
      regex: /^[a-zA-Z0-9]{1,9}$/,
      errMsg: 'Related Instrument <index> Registration Number should not exceed 9 characters'
    },
    'MB': {
      regex: /^[a-zA-Z0-9\\_-]{1,13}\/[1-6]$/,
      errMsg: 'Related Instrument <index> Registration Number must be in the following format, 3 to 13 digits / LTO designation number, e.g. 1234567890/1'
    },
    'NS': {
      regex: /^[a-zA-Z0-9]{1,10}$/,
      errMsg: 'Related Instrument <index> Registration Number should not exceed 10 characters'
    },
    'SK': {
      regex: /^\d{0,10}$/,
      errMsg: 'Related Instrument <index> Registration Number must be numeric and should not exceed 10 digits'
    },
    'NB': {
      regex: /^[a-zA-Z0-9]{1,10}$/,
      errMsg: 'Related Instrument <index> Registration Number should not exceed 10 characters'
    }
  };

  validateMortgageRelatedInstrumentRegNoByProvince(provinceCode: ProvinceCode, mortgagePriority: number, registrationNo: string, mortgageTopic: string, index: number) {
    if (this.relatedInstrumentRegNoValidationRules[ provinceCode ]) { // just in case a province does not need validation
      if (!this.relatedInstrumentRegNoValidationRules[ provinceCode ][ 'regex' ].test(registrationNo)) {
        this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgagePriority }.relatedInstrument.${ index }.registrationNumber.format`,
          this.relatedInstrumentRegNoValidationRules[ provinceCode ][ 'errMsg' ].replace('<index>', index),
          mortgageTopic, 'ERROR');
      }
    }
  }

  validateMortgageRelatedInstrumentRegNo(matterProvince: ProvinceCode, relatedInstrument: MortgageInstrument, index: number, mortgage: Mortgage, mortgageTopic: string) {
    if (mortgage) {
      if ([ 'NB', 'NS' ].indexOf(matterProvince) > -1) {
        if (!relatedInstrument.rollBookNumber && !relatedInstrument.framePageNumber && !!relatedInstrument.instrumentType && !relatedInstrument.registrationNumber) {
          this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.relatedInstrument.${ index }.registrationNumber`,
            `Related Instrument's Registration Number ${ index + 1 } is required if Instrument Type has been identified and Book and Frame have not been entered`,
            mortgageTopic, 'ERROR');
        } else if (relatedInstrument.registrationNumber) {
          this.validateMortgageRelatedInstrumentRegNoByProvince(matterProvince, mortgage.mortgagePriority, relatedInstrument.registrationNumber, mortgageTopic, index + 1);
        }
      } else {
        if (!relatedInstrument.registrationNumber) {
          this.addCustomDataValidationError(`integrations.telusAssyst.mortgage.${ mortgage.mortgagePriority }.relatedInstrument.${ index }.registrationNumber`,
            `The Related instrument ${ index + 1 } Registration Number is required.`,
            mortgageTopic, 'ERROR');
          return;
        } else {
          this.validateMortgageRelatedInstrumentRegNoByProvince(matterProvince, mortgage.mortgagePriority, relatedInstrument.registrationNumber, mortgageTopic, index + 1);
        }
      }
    }
  }

  validateProperty(matter: Matter) {

    if (matter.isMatterProvinceMB) {
      matter.matterProperties.forEach(property => {
        if (property.parcelLegalDescriptions && property.parcelLegalDescriptions.length) {
          property.parcelLegalDescriptions.forEach((legalDescr: ParcelLegalDescription, index: number) => {
            if (!legalDescr.parcelNumber) {
              this.addCustomDataValidationError(`integrations.telusAssyst.property.${ index }.title.number`,
                `${ this.propertyTypeIdentifier(matter) } ${ index + 1 } is required.`,
                'Property', 'ERROR');
            } else {
              this.validatePropertyPinFormat(matter, index, legalDescr.parcelNumber);
            }
          });
        } else {
          this.addCustomDataValidationError(`integrations.telusAssyst.property.0.title.number`,
            `${ this.propertyTypeIdentifier(matter) } is required.`,
            'Property', 'ERROR');
        }
      });

    } else if (matter.isMatterProvinceSK && !matter.isPropertyCondo()) {
      matter.matterProperties.forEach(property => {
        property.parcelLegalDescriptions.forEach((legalDescr: ParcelLegalDescription, index: number) => {
          legalDescr.parcelTitles.forEach(parcelTitle => {
            if (!parcelTitle.titleNumber) {
              this.addCustomDataValidationError(`integrations.telusAssyst.property.${ index }.title.number`,
                `${ this.propertyTypeIdentifier(matter) } ${ index + 1 } is required.`,
                'Property', 'ERROR');
            } else {
              this.validatePropertyPinFormat(matter, index, parcelTitle.titleNumber);
            }
          });
        });
      });

    } else {
      if (matter.isPropertyCondo() && matter.matterPropertyWithCondo) {
        const condominiumExpenses: CondominiumExpense[] = matter.matterPropertyWithCondo.condominiumExpenses;
        if ([ 'AB', 'BC' ].indexOf(matter.provinceCode) > -1) {
          if (condominiumExpenses && condominiumExpenses.length) {
            condominiumExpenses.forEach((condominiumExpense: CondominiumExpense, index: number) => {
              if (condominiumExpense.lincNumber) {
                this.validatePropertyPinFormat(matter, index, condominiumExpense.lincNumber);
              }
            });
          }
        } else {
          if (condominiumExpenses && condominiumExpenses.length) {
            condominiumExpenses.forEach((condominiumExpense: CondominiumExpense, index: number) => {
              if (condominiumExpense.pinNumber) {
                this.validatePropertyPinFormat(matter, index, condominiumExpense.pinNumber);
              }
            });
          }
        }
      } else {
        if ([ 'AB', 'BC' ].indexOf(matter.provinceCode) > -1) {
          if (matter && matter.matterProperties.length) {
            matter.matterProperties.forEach((property: MatterProperty, index: number) => {
              if (!property.lincNumber) {
                this.addCustomDataValidationError(`integrations.telusAssyst.property.${ index }.linc.pin`,
                  `Property ${ index + 1 } ${ this.propertyTypeIdentifier(matter) } is required.`,
                  'Property', 'ERROR');
              } else {
                this.validatePropertyPinFormat(matter, index, property.lincNumber);
              }
            });
          }
        } else {
          if (matter && matter.matterProperties.length) {
            matter.matterProperties.forEach((property: MatterProperty, index: number) => {
              if (!property.pin) {
                this.addCustomDataValidationError(`integrations.telusAssyst.property.${ index }.linc.pin`,
                  `Property ${ index + 1 } ${ this.propertyTypeIdentifier(matter) } is required.`,
                  'Property', 'ERROR');
              } else {
                this.validatePropertyPinFormat(matter, index, property.pin);
              }
            });
          }
        }
      }
    }

    if ([ 'ON', 'MB' ].indexOf(matter.provinceCode) > -1) {
      if (matter.isMatterProvinceON && matter.matterProperties.length > 1) {
        for (let i: number = 1; i < matter.matterProperties.length; i++) {
          if (matter.matterProperties[ i ].pin && !matter.matterProperties[ i ].propertyTaxesSummary) {
            this.addCustomDataValidationError(`integrations.telusAssyst.${ i + 1 }.legalDescription`,
              `Property ${ (i + 1) } Legal Description is required.`, 'Property', 'ERROR');
          }
        }
      }
      if (matter.isMatterProvinceMB) {
        if (matter.matterProperties.length == 0 || !matter.matterProperties[ 0 ].legalDescriptionSummary) {
          this.addCustomDataValidationError(`integrations.telusAssyst.0.legalDescription`,
            `Property Legal Description is required.`, 'Property', 'ERROR');
        }
      }
      if (matter.isPropertyCondo() && matter.matterPropertyWithCondo) {
        const condoPlans: CondominiumPlan[] = matter.matterPropertyWithCondo.condominiumPlans;
        if (!condoPlans || condoPlans.length == 0 || (condoPlans.length == 1 && !condoPlans[ 0 ].condominiumPlanNumber)) {
          this.addCustomDataValidationError(`integrations.telusAssyst.property.0.plan`,
            `Property Plan is required.`,
            'Property', 'ERROR');
        }
      } else {
        const property = matter.matterProperties[ 0 ];
        if (property && !property.plan && matter.provinceCode !== 'MB') {
          this.addCustomDataValidationError(`integrations.telusAssyst.property.0.plan`,
            `Property Plan is required.`,
            'Property', 'ERROR');
        }
        if (matter.provinceCode == 'ON' && property && !property.partLot) {
          this.addCustomDataValidationError(`integrations.telusAssyst.property.0.planLot`,
            `Property Part (lot) is required.`,
            'Property', 'ERROR');
        }
      }
    }

    if ([ 'ON', 'NS', 'NB' ].indexOf(matter.provinceCode) > -1) {
      const property = matter.matterProperties[ 0 ];
      if (property && property.registryOrLt === constValues.registryLt.defaultType) {
        this.addCustomDataValidationError(`integrations.telusAssyst.property.0.registryOrLt`,
          `Registry or LT is required.`,
          'Property', 'ERROR');
      }
    }
    if ([ 'AB', 'MB', 'ON' ].indexOf(matter.provinceCode) > -1) {
      const property = matter.matterProperties[ 0 ];
      if (property && !property.registryOfficeName) {
        this.addCustomDataValidationError(`integrations.telusAssyst.property.0.registryOfficeName`,
          `A ${ provinceBasedFieldLabels.get('matter.subjectProperty.registryOffice', matter.provinceCode) } is required.`,
          'Property', 'ERROR');
      }
    }
  }

  validatePropertyPinFormat(matter: Matter, propertyIndex: number, pinNumber: string) {
    if (pinNumber) {
      let errorMessage: string;
      switch (matter.provinceCode) {
        case 'AB':
          if (pinNumber.length > 12) {
            errorMessage = `${ this.propertyTypeIdentifier(matter) } ${ propertyIndex + 1 } length cannot be more than 12 chars.`;
          } else if (!(/\d{4}\s*\d{3}\s*\d{3}/.test(pinNumber))) {
            errorMessage = `${ this.propertyTypeIdentifier(matter) } ${ propertyIndex + 1 } has an invalid format. Expected format is 9999 999 999`;
          }
          break;
        case 'MB':
          if (pinNumber.length > 15) {
            errorMessage = `${ this.propertyTypeIdentifier(matter) } ${ propertyIndex + 1 } length cannot be more than 15 chars.`;
          } else if (!(/[a-zA-Z0-9\\_-]{1,13}\/[1-6]/.test(pinNumber))) {
            errorMessage = `${ this.propertyTypeIdentifier(matter) } ${ propertyIndex + 1 } has an invalid format. Expected format is 9999999999/6`;
          }
          break;
        case 'ON':
          if (!(/\d{9}/.test(pinNumber))) {
            errorMessage = `Property ${ propertyIndex + 1 } ${ this.propertyTypeIdentifier(matter) } has an invalid format. Expected format is 999999999`;
          }
          break;
        case 'BC':
          if (!(/^\D*(\d\D*){9}$/.test(pinNumber))) {
            errorMessage = `Property ${ propertyIndex + 1 } ${ this.propertyTypeIdentifier(matter) } has an invalid format. Expected format 999 999 999`;
          }
          break;
        default:
          if (pinNumber.length > 10) {
            errorMessage = `Property ${ propertyIndex + 1 } ${ this.propertyTypeIdentifier(matter) } length cannot be more than 10 chars.`;
          }
          break;
      }
      if (errorMessage) {
        this.addCustomDataValidationError(`integrations.telusAssyst.property.${ propertyIndex }.pin.format`,
          errorMessage,
          'Property', 'ERROR');
      }
    }
  }
}
