import {Component, Inject} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {Matter, MatterProperty} from '../../../shared';
import {ModalResult} from '../../../../shared-main/enums';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import Utils from '../../../../shared-main/utils';
import {Project} from '../../../../projects/shared/project';
import {SelectItem} from 'primeng/api';
import {ProgressionStatus, StatementAdjustment} from '../../statement-adjustment';
import {ProjectAdjustmentConfig} from '../../../../projects/project-adjustments/project-adjustment-config';
import {
  BaseTaxAdjustmentOn,
  ProjectTaxAdjustmentConfig
} from '../../../../projects/project-adjustments/project-tax-adjustment-config';
import {SoaRealtyTaxAdjustment} from './soa-realty-tax-adjustment';
import moment from 'moment';
import {SoaRealtyTaxAdjustmentUtil} from './soa-realty-tax-adjustment-util';
import {UnitLevelPlanModalComponent} from '../../../property-teranet/unit-level-plan/unit-level-plan.component';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var jQuery: any;

class SoaRealtyTaxModalContext {
  public closingDate: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public addedFlag: boolean;
  public matter: Matter;
  public expenseType: string;
  public project: Project;
  public isOpenedFromProjectConfig: boolean;
  /*public realtyTaxAdjustmentYear: string;
  public noOfDaysLeftInYear:string;
  public occupancyDate: string;*/
  public soaPreviousYearRealtyTaxAdjustment: SoaRealtyTaxAdjustment;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-realty-tax-soa-modal-content',
  templateUrl: './soa-realty-tax.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class SoaRealtyTaxModalComponent extends ModalComponent<SoaRealtyTaxModalContext> {
  context: SoaRealtyTaxModalContext;
  closingDateLabel: string = 'Closing Date';
  yesNoDropDown: SelectItem[];
  soaRealtyTaxAdjustment: SoaRealtyTaxAdjustment;
  // purchaserPortionOfTotalTaxes : number;
  // previousYearTotalTaxes:number;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<SoaRealtyTaxModalComponent>,
    public currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: SoaRealtyTaxModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.populateSoaRealtyTaxAdjustmentFields();
    this.initDropDowns();
    this.soaRealtyTaxAdjustment = this.context.soaPreviousYearRealtyTaxAdjustment;
    /*if(!this.context.soaPreviousYearRealtyTaxAdjustment){
        this.soaRealtyTaxAdjustment = new SoaRealtyTaxAdjustment();
    }else{
        this.soaRealtyTaxAdjustment = this.context.soaPreviousYearRealtyTaxAdjustment;
    }
    this.soaRealtyTaxAdjustment.noOfDaysLeft = this.context.noOfDaysLeftInYear;
    this.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear = this.context.realtyTaxAdjustmentYear;
    this.soaRealtyTaxAdjustment.occupancyDate= this.context.occupancyDate;*/
    if (!this.soaRealtyTaxAdjustment.adjustmentBasedOnPercentage) {
      this.soaRealtyTaxAdjustment.adjustmentBasedOnPercentage = 'YES';
    }
    this.purchaserPortion();
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
    this.calculateVendorShare();
  }

  populateSoaRealtyTaxAdjustmentFields() {
    if (!this.context.soaPreviousYearRealtyTaxAdjustment) {
      this.newAdj = true;
      this.context.soaPreviousYearRealtyTaxAdjustment = new SoaRealtyTaxAdjustment();
    }
    SoaRealtyTaxAdjustmentUtil.populateSoaRealtyTaxAdjustmentFields(
      this.context.soaPreviousYearRealtyTaxAdjustment,
      this.context.matter
    );
  }

  purchaserPortion(): void {
    SoaRealtyTaxAdjustmentUtil.populatePurchaserPortionAndTotalTaxes(
      this.baseTaxAdjustment,
      this.soaRealtyTaxAdjustment,
      this.context.matter
    );
  }

  onChangeAdjustmentBasedOnPercentageFlag(event) {
    if (event == 'YES') {
      this.soaRealtyTaxAdjustment.previousYearTotalTaxes =
        Number(this.baseTaxAdjustment.unitPurchasePrice / 100) * Number(this.agreedSalePrice);
    }
  }

  get totalCondominiumExpenses(): number {
    return this.baseTaxAdjustment &&
      this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.EqualDivisionAmongUnits
      ? this.baseTaxAdjustment.numberOfUnits == 0
        ? 0
        : (1 / Number(this.baseTaxAdjustment.numberOfUnits)) * 100
      : SoaRealtyTaxAdjustmentUtil.totalCondominiumExpenses(this.context.matter);
  }

  get agreedSalePrice(): number {
    return (
      this.context.matter.considerationLtt &&
      this.context.matter.considerationLtt.salePriceAdjustment.agreementSalePrice
    );
  }

  isPreviousYearTotalTaxesApplicable(event): boolean {
    return (
      event == 'NO' &&
      (this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice ||
        this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits)
    );
  }

  get previousYearTotalTaxesLabel(): string {
    if (
      this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfTaxesForProject ||
      this.baseTaxAdjustment.taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits
    ) {
      return "Previous year's total realty taxes";
    } else {
      return "Estimated previous year's total realty taxes";
    }
  }

  get occupancyDateLabel() {
    let occupancyDateLabel =
      '(Occupancy : ' +
      moment(SoaRealtyTaxAdjustmentUtil.getOccupancyDateForRealtyTax(this.context.matter), 'YYYY/MM/DD').format(
        'MMMM DD, YYYY'
      ) +
      ')';
    if (this.isRealtyTaxEstimated) {
      return occupancyDateLabel;
    } else {
      return 'for ' + this.soaRealtyTaxAdjustment.noOfDaysLeft + ' days ' + occupancyDateLabel;
    }
  }

  get projectAdjustmentConfig(): ProjectAdjustmentConfig {
    return this.context.project.projectAdjustmentConfig;
  }

  get baseTaxAdjustment(): ProjectTaxAdjustmentConfig {
    return this.context.matter.adjustmentStatusMode === ProgressionStatus.FINAL
      ? this.projectAdjustmentConfig.taxesSameAsInterim
        ? this.projectAdjustmentConfig.interimTaxAdjustment
        : this.projectAdjustmentConfig.finalTaxAdjustment
      : this.projectAdjustmentConfig.interimTaxAdjustment;
  }

  isPurchaserPortionOfTotalTaxesApplicable(): boolean {
    return (
      this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice ||
      this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits
    );
  }

  get adjustmentTypeTitle() {
    return this.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear + ' Realty Taxes';
  }

  get previousYearTotalRealtyTaxLabel(): string {
    if (this.isRealtyTaxEstimated) {
      return (
        'Estimated ' +
        this.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear +
        ' Taxes ' +
        this.getFormattedCurrencyValue(this.soaRealtyTaxAdjustment.previousYearTotalTaxes)
      );
    } else {
      return (
        this.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear +
        ' Total Realty Taxes ' +
        this.getFormattedCurrencyValue(this.soaRealtyTaxAdjustment.previousYearTotalTaxes)
      );
    }
  }

  get purchaserShareLabel(): string {
    if (this.isRealtyTaxEstimated) {
      return "Purchaser's share for " + this.soaRealtyTaxAdjustment.noOfDaysLeft;
    } else {
      return (
        "Purchaser's share at " +
        this.soaRealtyTaxAdjustment.purchaserPortionOfTotalTaxes +
        ' of ' +
        this.getFormattedCurrencyValue(this.soaRealtyTaxAdjustment.previousYearTotalTaxes)
      );
    }
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (
      currencyValue != undefined &&
      !isNaN(currencyValue) &&
      currencyValue != null &&
      currencyValue.toString().trim() != ''
    ) {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  calculateVendorShare() {
    SoaRealtyTaxAdjustmentUtil.calculateVendorShare(
      this.context.matter,
      this.context.matter.matterCloseDate,
      this.baseTaxAdjustment.taxAdjustmentType,
      this.soaRealtyTaxAdjustment
    );
  }

  get isRealtyTaxEstimated(): boolean {
    return (
      this.baseTaxAdjustment.taxAdjustmentType === BaseTaxAdjustmentOn.percentageOfUnitPurchasePrice ||
      this.baseTaxAdjustment.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfUnitPurchasePriceIncludingCredits
    );
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  ok(): void {
    this.dialog.close(this.soaRealtyTaxAdjustment);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  public initDropDowns() {
    this.yesNoDropDown = [
      {
        label: 'Yes',
        value: 'YES'
      },
      {
        label: 'No',
        value: 'NO'
      }
    ];
  }

  get propertyModel(): MatterProperty {
    if (this.context.matter && !this.context.matter.matterPropertyWithCondo) {
      this.context.matter.matterProperties.push(MatterProperty.createDefaultMatterProperty());
    }
    return this.context.matter.matterPropertyWithCondo;
  }

  updateUnitLevelPlan(matterProperty: MatterProperty): void {
    this.context.matter.updateUnitLevelPlanCreated(matterProperty);
    this.context.matter.calculateStatementAdjustment();
  }

  updateRealtyTaxAdjustment(realtyTaxAdjustment: SoaRealtyTaxAdjustment, matter: Matter) {
    SoaRealtyTaxAdjustmentUtil.populateSoaRealtyTaxAdjustmentFields(realtyTaxAdjustment, matter);
    SoaRealtyTaxAdjustmentUtil.calculateVendorShare(
      matter,
      matter.matterCloseDate,
      SoaRealtyTaxAdjustmentUtil.getBaseTaxAdjustment(matter).taxAdjustmentType,
      realtyTaxAdjustment
    );
  }

  selectUnitLevelPlan(): void {
    let modelWidthXl = this.context.matter.isProjectSale || this.context.matter.templateForProject;

    let cea: StatementAdjustment = this.context.matter.statementOfAdjustments.find((item) =>
      item.isCommonExpenseAdjustmentFromProperty()
    );
    this.dialogService.matDialogContent({
      content: UnitLevelPlanModalComponent,
      widthXl: modelWidthXl,
      context: {
        propertyJurisdiction: this.context.matter.matterPropertyWithCondo.jurisdiction,
        unitLevelPlanOrg: this.propertyModel,
        commonExpenseAdj: cea,
        currentMatter: this.context.matter,
        isReadOnly: false,
        isUnitLevelPinReadOnly: true
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.plan) {
            this.updateUnitLevelPlan(result.plan);
            this.updateRealtyTaxAdjustment(this.soaRealtyTaxAdjustment, this.context.matter);

            // the issue here is that 'blur' on purchaserPortionOfTotalTaxes does not get called once this modal gets closed
            // forcing a focus here (on the input and not on button) to get a chance to dp-percentage directive to run its magic from onBlur
            setTimeout(() => {
              jQuery('#purchaserPortionOfTotalTaxes').focus();
            }, 100);
          }
        }
      }
    });
  }
}
