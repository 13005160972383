import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {HttpClient} from '../../app/core/httpClient.service';
import {matterApi} from '../../app/matters/shared/matter-api';
import {ErrorService} from '../../app/shared/error-handling/error-service';
import {EventService} from '../../app/event/event.service';
import {EventData} from '../../app/event/event-data';
import {DPError} from '../../app/shared/error-handling/dp-error';
import {MatterPollingData} from '../../app/core/matter-polling-data';
import {MatterTab} from '../../app/matters/matter-tab';
import {Tab} from '../../app/shared/tabbing/tab';

@Injectable()
export class MatterPollingService {

  pollingPeriod: number = 5000;

  constructor(private http: HttpClient, private errorService: ErrorService, private eventService: EventService) {

  }

  // Method Starts Polling as Soon a new Tab is open or Tab Switch
  startMatterPolling(matterTab: MatterTab, openTabs: Tab[]): void {
    this.stopAllMatterPolling(openTabs);
    if (matterTab.matter && matterTab.matter.id > 0) {
      this.startDocuSignStatusPolling(matterTab);
    }
  }

  //Centralized method to stop all polling when you switch to other topics or other matter or any other context ...
  stopAllMatterPolling(openTabs: Tab[]): void {
    openTabs.filter(tab => tab.isMatter()).forEach(matterTab => {
      if ((matterTab as MatterTab).inProgressPolling && (matterTab as MatterTab).inProgressPolling.length) {
        (matterTab as MatterTab).inProgressPolling.forEach(ti => {
          if (ti && ti.pollingIntervalSubscription && !ti.pollingIntervalSubscription.closed) {
            ti.pollingIntervalSubscription.unsubscribe();
          }
        });
        (matterTab as MatterTab).inProgressPolling = [];
      }
    });
  }

  // DocuSing Polling Methods ...

  startDocuSignStatusPolling(matterTab: MatterTab): void {
    this.stopDocuSignPolling(matterTab);
    if (matterTab.docuSingInProgressPolling && matterTab.docuSingInProgressPolling.pollingData && matterTab.docuSingInProgressPolling.pollingData.matterEventsData) {
      let matterEvents: EventData[] = matterTab.docuSingInProgressPolling.pollingData.matterEventsData.map(ed => {
        return new EventData(ed);
      }).slice(0);
      this.performDocuSignPolling(matterEvents, matterTab);
    } else {
      this.eventService.getEvents(null, null,
        [ 'APPOINTMENT' ], null, null, null,
        null, null, String(matterTab.matter.id))
      .subscribe((matterEvents: EventData[]) => {
        this.showMatterEventNotificationAndUpdate(matterEvents, matterTab);
      });
    }

  }

  showMatterEventNotificationAndUpdate(matterEvents: EventData[], matterTab: MatterTab): void {
    if (matterEvents && matterEvents.length && matterEvents.some(item => item.signingEnvelope && item.signingEnvelope.needToDisplayNotification()) && matterTab) {
      let changedEvent: EventData = matterEvents.find(item => item.signingEnvelope && item.signingEnvelope.needToDisplayNotification() && !item.signingEnvelope.isEnvelopeFailed());
      if (changedEvent) {
        this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.docuSign.documentsAvailable.Success', 'Document(s) were successfully saved to your matter', changedEvent.getSigningPlatformLabel(), 'NOTIFICATION'));
      }

      let failedEvent: EventData = matterEvents.find(item => item.signingEnvelope && item.signingEnvelope.needToDisplayNotification() && item.signingEnvelope.isEnvelopeFailed());
      if (failedEvent) {
        this.errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.docuSign.documentsAvailable.failed', 'Document(s) failed to download', failedEvent.getSigningPlatformLabel(), 'NOTIFICATION'));
      }

      matterEvents.filter(item => item.signingEnvelope && item.signingEnvelope.needToDisplayNotification()).forEach(me => {
        me.signingEnvelope.userAcknowledgementStatus = 'DISPLAYED';
        this.eventService.updateEvent(matterTab.id, me, true).subscribe();
        if (matterTab.matter && matterTab.matter.matterEvents && matterTab.matter.matterEvents.length) {
          let updatedMatterEventIndex: number = matterTab.matter.matterEvents.findIndex(event => event.id == me.id);
          if (updatedMatterEventIndex > -1 && me.signingEnvelope) {
            let updatedMatterEvent: EventData = matterTab.matter.matterEvents[ updatedMatterEventIndex ];
            updatedMatterEvent.signingEnvelope.envelopeStatus = me.signingEnvelope.envelopeStatus;
            updatedMatterEvent.eventStatus = me.eventStatus;
          }
        }
      });
    }
    this.performDocuSignPolling(matterEvents, matterTab);
  }

  performDocuSignPolling(matterEvents: EventData[], matterTab: MatterTab): void {
    // we remove polling if any
    matterTab.removeDocuSignInProgressPolling();
    if (matterEvents && matterEvents.some(ev => ev.signingEnvelope && ev.signingEnvelope.isEnvelopeSent())) {

      let url: string = matterApi.docuSignPolling
      .replace('{matterId}', '' + matterTab.matter.id);

      // First poll call to receive initial data for polling and create polling data and add into inprogress polling pool ...
      this.http.get(url).subscribe(res => {

        // setup polling Logic
        let intervalDocuSign = Observable.interval(this.pollingPeriod)
        .startWith(0)
        .switchMap(() => this.http.get(url)
        .map((res: any) => {
          return res;
        }))
        .subscribe(res => {
          // After every poll we check condition
          this.checkDocuSignLatestEventTimestamp(res, matterTab);
        });

        // Create Polling Data ...
        let matterPollingData = new MatterPollingData();
        matterPollingData.pollingData = {
          latestEventTimestamp: res[ 'MatterEventMetaData' ].latestEventTimestamp,
          matterEventsData: matterEvents
        };
        matterPollingData.pollingIntervalSubscription = intervalDocuSign;
        matterPollingData.pollingType = 'DocuSignStatusPolling';
        matterTab.inProgressPolling.push(matterPollingData);
      });
    }

  }

  // in our case we check whether polling data received which is latestEventTimestamp is different then last call
  // if it is we stop polling and then push notification to user and again check start polling again ...
  checkDocuSignLatestEventTimestamp(res: any, matterTab: MatterTab): void {
    if (matterTab.docuSingInProgressPolling && matterTab.docuSingInProgressPolling.pollingData && matterTab.docuSingInProgressPolling.pollingData.latestEventTimestamp != res[ 'MatterEventMetaData' ].latestEventTimestamp) {
      this.stopDocuSignPolling(matterTab);
      this.eventService.getEvents(null, null,
        [ 'APPOINTMENT' ], null, null, null,
        null, null, String(matterTab.matter.id))
      .subscribe((eventDataList: EventData[]) => {
        let updatedDocuSignMatterEvents = eventDataList.filter(ed => ed.signingEnvelope && (ed.signingEnvelope.needToDisplayNotification())
          && matterTab.docuSingInProgressPolling.pollingData.matterEventsData.filter(ev => ev.signingEnvelope && ev.signingEnvelope.isEnvelopeSent()).map(mev => {
            return mev.id;
          }).indexOf(ed.id) > -1);
        this.showMatterEventNotificationAndUpdate(updatedDocuSignMatterEvents, matterTab);
      });
    }
  }

  stopDocuSignPolling(matterTab: MatterTab): void {
    let ti = matterTab.inProgressPolling.find(t => t.isDocuSignPolling());
    if (ti && ti.pollingIntervalSubscription && !ti.pollingIntervalSubscription.closed) {
      ti.pollingIntervalSubscription.unsubscribe();
    }
  }

}
