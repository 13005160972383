import {DocketService} from './../admin/docket/docket.service';
import {Component, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/interval';
import {Logger} from '@nsalaun/ng-logger';
import {MatterService} from './matter.service';
import {
  BulkMatterUpdate,
  Contact,
  ContactService,
  currentMatter,
  errorMessage,
  GetGlobalSaveModelService,
  matterBaseUrl,
  MatterListState,
  MatterSectionsTitles,
  MatterUtil,
  opportunityBaseUrl,
  OpportunityMatterStatusValue,
  Utils
} from './shared';
import SharedUtils from '../shared-main/utils';
import {
  HOLDBACKS_SECTION_ROUTE_PATH,
  OPPORTUNITY_WORKITEMS_ROUTE_PATH,
  SectionedComponent,
  WORKITEMS_ROUTE_PATH
} from '../shared/tabbing/sectioned-component';
import {DialogService} from '../shared/dialog/dialog.service';
import {MatterTab} from './matter-tab';
import {Matter} from './shared/matter';
import {TabsService} from '../core/tabs.service';
import {WindowRef} from '../shared/window.ref';
import {Section} from './shared/section';
import {ErrorService} from '../shared/error-handling/error-service';
import {DPError} from '../shared/error-handling/dp-error';
import {StatusBarService} from '../shared-main/status-bar.service';
import {Subscription} from 'rxjs/Subscription';
import {Observable} from 'rxjs/Observable';
import {DocumentProfileCache} from '../shared-main/document-profile-cache.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {DocumentProfile} from '../admin/document-profile/document-profile';
import {AuthZService} from '../core/authz/auth-z.service';
import {FirstFocusMatterUtil} from '../shared-main/first-focus-matter-util';
import {RequisitionsService} from './requisitions/requisitions.service';
import {ApplicationError} from '../core/application-error';
import {UnityBillingService} from '../billing/unity-billing-service';
import {UnityBilling} from '../billing/unity-billing';
import {CurrencyPipe, DecimalPipe, Location, PercentPipe} from '@angular/common';
import {StaffProfilesService} from '../admin/staff-profiles/staff-profiles.service';
import {SoaTrustLedgerHelperService} from '../shared/soa-trustledger-helper.service';
import {Mortgage} from './shared/mortgage';
import {SupplementalTaskService} from '../shared-main/supplemental-task-category/supplemental-task-service';
import {ContactQueryService} from '../contact/contact-query.service';
import {UserDefinedFieldService} from '../shared-main/user-defined-field/user-defined-field-service';
import {DpDirtyCheckService} from '../shared-main/dp-dirty-check.service';
import {FctEmpMessages, TelusEmpMessages} from '../shared-main/emp-messages';
import {StewartMortgageInstruction} from '../shared-main/telus/stewart-mortgage-instruction';
import {TelusService} from '../shared-main/telus/telus-service';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {TitleInsuranceConfigurationService} from '../integrations/title-insurance-configuration.service';
import {AccountService} from '../admin/accounts/account.service';
import {Account} from '../admin/accounts/shared/account';
import {StewartTitleService} from '../shared-main/stewart-title/stewart-title-service';
import {StewartTitleUser} from '../shared-main/stewart-title/stewart-title-user';
import {StewartGetResultResponse} from '../shared-main/stewart-title/stewart-get-result-response';
import {SaleCommissionConfigService} from '../admin/sale-commission/sale-commission-config.service';
import {SelectItem} from 'primeng/api';
import {dropDowns} from './shared/matter-drop-downs';
import {MatterTopic} from './shared/matter-topic';
import {FCTService} from '../shared-main/fct/fct-service';
import {ChicagoTitleService} from '../shared-main/chicago-title/chicago-title-service';
import {ChicagoGetAllStatusDetailsResponse} from '../shared-main/chicago-title/chicago-get-all-status-details-response';
import {MatterTitleInsurance} from './shared/matter-title-insurance';
import {AccountProvince} from '../admin/accounts/shared/account-province';
import {LockScreenService} from '../core/lock-screen.service';
import {SubjectPropertyTitle} from '../shared-main/province-based-dropdowns';
import {AppConfig} from '../shared-main/app-configuration';
import {FieldCodeService} from '../shared-main/field-code.service';
import moment from 'moment';
import {CopyMatterLinkDataService} from '../matters/copy-matter-link-data.service';
import {PurchaserComponent} from '../matters/purchaser';
import {VendorsSolicitorComponent} from '../matters/vendors-solicitor/vendors-solicitor.component';
import {PropertyTeranetComponent} from '../matters/property-teranet';
import {BrokerCommissionComponent} from '../matters/broker-commission/broker-commission.component';
import {CondoCorporationComponent} from './condo-corporation/condo-corporation.component';
import {AttentionInfoComponent} from './matter-opening/attention/attention-info.component';
import {FireInsuranceComponent} from './fire-insurance/fire-insurance.component';
import {FamilyLawActComponent} from '../matters/purchaser/family-law-act/family-law-act.component';
import {StatementAdjustmentComponent} from '../matters/statement-adjustment/statement-adjustment.component';
import {MassUpdateType, MassUpdateTypes, Project} from '../projects/shared/project';
import {MassUpdateStageType, MassUpdateTab} from '../shared/tabbing/mass-update-tab';
import {BurgerMenuExtendedItem} from './shared/burger-menu-extended-item';
import {ProjectService} from '../projects/project.service';
import {Tab} from '../shared/tabbing/tab';
import {TabsComponent} from '../shared/tabbing';
import {MassUpdateService} from '../matters/mass-update.service';
import {MatterOpeningComponent} from '../matters/matter-opening/matter-opening.component';
import {Subject} from 'rxjs/Subject';
import {SoajFieldCodeService} from '../shared-main/soaj-field-code.service';
import {MassOpenProjectModalComponent} from './mass-open-project-modal/mass-open-project-modal.component';
import {AdjustmentPropagationService} from '../matters/adjustment-propagation.service';
import {MassUpdateAdjustmentDateData} from '../projects/project-list/mass-update-adjustmentdate-modal/mass-update_adjustmentdate-data';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {TaxRateService} from './consideration-ltt/tax-rate.service';
import {InterestRate} from './statement-adjustment/model/interest-rate';
import {MortgageeComponent} from './mortgages/mortgage/mortgagee/mortgagee.component';
import {VtbMortgageeComponent} from './mortgages/mortgage/mortgagee/vtb-mortgagee.component';
import {ExistingMortgageComponent} from './mortgages/mortgage/existing-mortgage/existing-mortgage.component';
import {MortgageDetailComponent} from './mortgages/mortgage/mortagage-detail/mortgage-detail.component';
import {MortgageTermComponent} from './mortgages/mortgage/term/mortgage-term.component';
import {MortgageReportComponent} from './mortgages/mortgage/report/mortgage-report.component';
import {MortgageGuarantorComponent} from './mortgages/mortgage/mortgage-guarantor/mortgage-guarantor.component';
import {OpportunitiesService} from '../opportunities/opportunities.service';
import {CustomKeyCodesEnum} from '../common/key-code-enum';
import {NavigateToMatterService} from './navigate-to-matter.service';
import {UnitConnectImportService} from './purchaser/cirf/cirf-import-data/unit-connect-import.service';
import {MortgageBrokerInfoComponent} from './mortgages/mortgage/mortgage-broker-information/mortgage-broker-info.component';
import {Cirf} from './shared/cirf/cirf';
import {CirfImportContainerModalComponent} from './purchaser/cirf/cirf-import-data/cirf-import-container.modal.component';
import {UnitConnectDataPropagation} from './purchaser/cirf/cirf-import-data/unit-connect-data-propagation';
import {ModalResult} from '../shared-main/enums';
import {CirfDocument} from './shared/cirf/cirf-document';
import {Referral} from './shared/referral/referral';
import {ReferralImportContainerModal} from './matter-opening/referral/referral-import-container.modal.component';
import {ProgressionStatus} from './statement-adjustment/statement-adjustment';
import {PurchaserCapacity} from './purchaser/capacity/purchaser-capacity';
import {PresentCirfModalComponent} from '../shared-main/opportunities/present-cirf-modal.component';
import {PrintMatterOverviewModalComponent} from './matter-overview/print-matter-overview-modal.component';
import {MatterOverviewService} from './matter-overview/matter-overview.service';
import {ConsiderationLttComponent} from './consideration-ltt/consideration-ltt.component';
import {DirectionReFundsComponent} from './direction-re-funds/direction-re-funds.component';
import {UserConfigurationService} from '../shared-main/user-configuration.service';
import {DpBold, MatterClosingStatus, MatterTopicKey} from '../shared-main/constants';
import {ProjectMassUpdateData, ProjectMatterUpdateTransaction} from './shared/project-matter-update-transaction';
import {ProjectMatterUpdateService} from '../../app/matters/project-matter-update.service';
import {ProjectDataPropagationService} from '../../app/projects/project-data-propagation.service';
import {MatterErrorUtil} from './shared/matter-utils/matter-error-util';
import {PrintMatterWorkItemsModalComponent} from './matter-overview/print-matter-work-items-modal.component';
import {MatterOverviewUtil} from './shared/matter-utils/matter-overview-util';
import {ConvertToMatterModalComponent} from '../opportunity-matter/convert-to-matter/convert-to-matter.modal.component';
import {MatterListUtil} from './matter-list/matter-list.util';
import {CopyOpportunityMatterService} from './copy-opportunity-matter.service';
import {CirfConfigService} from '../shared-main/cirf-config/cirf-config.service';
import {TitlePlusDealOrderStatusResponseDto} from '../shared-main/title-plus/title-plusp-deal-order-status-response.dto';
import {TitlePlusService} from '../shared-main/title-plus/title-plus-service';
import {TitlePlusMatterUser} from '../shared-main/title-plus/title-plus-matter-user';
import {AddNoteDialogComponent} from '../admin/account-notes/add-note-modal.component';
import {NotificationOptOutService} from '../main/notification-opt-out.service';
import {ActionTakenTypes, DuplicateContactData} from '../opportunity-matter/duplicate-prospect/duplicate-contact-data';
import {MatterStatementAdjustmentUtil} from './shared/matter-utils/matter-statement-adjustment-util';
import {SectionLocalizationUtil} from '../shared-main/section-localization-util';
import {MatterUtility} from './shared/matter-utility';

declare var jQuery: any;

@Component({
  selector: 'dp-matter',
  templateUrl: 'matter.component.html',
  styleUrls: ['./matter.styles.scss'],
  host: {
    '(document:keydown)': 'onDocumentKeyDown($event)',
    '(document:cut)': 'enableSave()',
    '(document:paste)': 'enableSave()'
  },
  providers: [
    DocumentProfileCache,
    MatterService,
    RequisitionsService,
    CurrencyPipe,
    DecimalPipe,
    PercentPipe,
    StewartTitleService,
    CopyMatterLinkDataService,
    MassUpdateService,
    ConsiderationLttComponent,
    AdjustmentPropagationService,
    PurchaserComponent,
    VendorsSolicitorComponent,
    BrokerCommissionComponent,
    PropertyTeranetComponent,
    CondoCorporationComponent,
    MortgageBrokerInfoComponent,
    AttentionInfoComponent,
    FireInsuranceComponent,
    FamilyLawActComponent,
    StatementAdjustmentComponent,
    MatterOpeningComponent,
    MortgageeComponent,
    VtbMortgageeComponent,
    MortgageGuarantorComponent,
    ExistingMortgageComponent,
    MortgageDetailComponent,
    MortgageTermComponent,
    MortgageReportComponent,
    NavigateToMatterService,
    UnitConnectImportService,
    OpportunitiesService,
    MatterOverviewService,
    DirectionReFundsComponent,
    ProjectDataPropagationService,
    ProjectMatterUpdateService,
    CopyOpportunityMatterService
  ]
})
export class MatterComponent extends SectionedComponent implements OnInit, OnDestroy {
  subscription: any;
  navigationEventSubscription: Subscription;
  matterSwitchSubscription: Subscription;
  dirtySubscription: Subscription;

  utils: Utils = new Utils();
  errorMessage = errorMessage;
  saveError: string = '';
  isMatterLocked: boolean = undefined;
  isMatterRefunded: boolean = undefined;
  displayUnlockMessageForMatter: boolean = undefined;
  matterLockedByUserName: string = undefined;
  matterLockedStatusSubscription: any;
  isMatterReadOnly: boolean = false;
  public result: any;
  saveSubscription: Subscription;
  isShortCutMode: boolean = false;
  isThirdPartyCallInProcess: boolean = true;
  thirdPartyApiSubscription: Subscription;
  matterTopicStatuses: SelectItem[] = [];
  matterTopicClosingStatuses: SelectItem[] = [];
  account: Account;
  accountProvince: AccountProvince;
  projectDropDownItems: BurgerMenuExtendedItem[];
  massUpdateMessages: string[] = [];
  massUpdateTopics: Set<string> = new Set();
  matterUtility: any;
  matterUtilitySaveSubscription: Subscription;

  constructor(
    public router: Router,
    public userConfigurationService: UserConfigurationService,
    public elementRef: ElementRef,
    public route: ActivatedRoute,
    public logger: Logger,
    public matterService: MatterService,
    public requisitionsService: RequisitionsService,
    public sendToChild: GetGlobalSaveModelService,
    public tabsService: TabsService,
    public dialogService: DialogService,
    public docketService: DocketService,
    public window: WindowRef,
    public errorService: ErrorService,
    public authZService: AuthZService,
    public statusBarService: StatusBarService,
    public documentProfileService: DocumentProfileService,
    public unityBillingService: UnityBillingService,
    public currencyPipe: CurrencyPipe,
    public decimalPipe: DecimalPipe,
    public percentPipe: PercentPipe,
    public documentProfileCache: DocumentProfileCache,
    public staffProfilesService: StaffProfilesService,
    public soaTrustLedgerHelperService: SoaTrustLedgerHelperService,
    public supplementalTaskService: SupplementalTaskService,
    public contactQueryService: ContactQueryService,
    public userDefinedFieldService: UserDefinedFieldService,
    public dpDirtyCheckService: DpDirtyCheckService,
    public accountService: AccountService,
    public saleCommissionConfigService: SaleCommissionConfigService,
    public telusService: TelusService,
    public titleInsuranceConfigurationService: TitleInsuranceConfigurationService,
    public stewartTitleService: StewartTitleService,
    public chicagoTitleService: ChicagoTitleService,
    public fctService: FCTService,
    public titlePlusService: TitlePlusService,
    public lockScreenService: LockScreenService,
    public appConfig: AppConfig,
    public projectService: ProjectService,
    public tabsComponent: TabsComponent,
    public fieldCodeService: FieldCodeService,
    public copyMatterLinkDataService: CopyMatterLinkDataService,
    public soajFieldCodeService: SoajFieldCodeService,
    public massUpdateService: MassUpdateService,
    public adjustmentPropagationService: AdjustmentPropagationService,
    public mortgageSoAdjService: MortgageSoAdjService,
    public taxRateService: TaxRateService,
    public opportunitiesService: OpportunitiesService,
    public unitConnectImportService: UnitConnectImportService,
    public brokerCommissionComponent: BrokerCommissionComponent,
    public matterOverviewService: MatterOverviewService,
    public contactService: ContactService,
    public navigateToMatterService: NavigateToMatterService,
    private location: Location,
    public copyOpportunityMatterService: CopyOpportunityMatterService,
    private projectMatterUpdateService: ProjectMatterUpdateService,
    private notificationOptOutService: NotificationOptOutService,
    public cirfConfigService: CirfConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.cacheDefaultDocumentProfile();
    this.getSoajFieldCodes();
    this.matterTopicStatuses = dropDowns.matterTopicStatusType;
    this.initializeMatterTopicClosingStatuses();
    this.sections = this.applicableSections;
    if (Array.isArray(this.sections) && this.sections.length > 0) {
      //init the section to the first section
      this.sectionName = this.sections[0].title;

      this.sectionName = this.sections[this.getSelectedSectionIndex()].title;
      this.initializeMatter();
      this.refreshSections(this.applicableSections);
      this.notificationOptOutService.loadOptOutEmails().subscribe();
    }

    this.accountService
      .getShallowAccount(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId).toString())
      .subscribe((account: Account) => {
        this.account = new Account(account);
        if (!this.matter.isMatterExisting() && !this.matter.isOpportunityMatter()) {
          this.checkWarningLevel();
        }
      });

    //initialize requisition instrument configs so they will be cached.
    this.requisitionsService
      .getRequisitionInstrumentConfigs(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId))
      .subscribe();

    this.addInputFieldListeners();

    //for existing PS matter with SoAdj focusSheet is Interim
    //the brokerCommission data used to Only bind to the taxRate from Final SoAdj page
    //need to correct it to bind to taxRate from the current Focused SoAdj Sheet
    if (
      this.matter &&
      this.matter.isProjectSale &&
      this.matter.selectedProgressionStatus == ProgressionStatus.INTERIM
    ) {
      this.brokerCommissionComponent.updateCommissionPayableToVendorBroker();
    }
    this.contactService.clearLendingInstitutionsCache();

    if (this.matter && this.matter.unityProjectId) {
      this.checkProjectMassUpdateTransactionStatus(this.matter.unityProjectId);
    }
  }

  initializeMatterTopicClosingStatuses() {
    this.matterTopicClosingStatuses = [];
    let selectItems: SelectItem[] = [];
    if (this.matter.isMatterProvinceNotON) {
      selectItems = dropDowns.matterTopicClosingStatusType.slice().filter((item) => item.value != 'ESCROW');
    } else {
      selectItems = dropDowns.matterTopicClosingStatusType.slice();
    }
    if (this.matter && this.isOccupationDateApplicable() && !this.matter.isMatterProvinceBC) {
      this.matterTopicClosingStatuses = selectItems;
    } else {
      this.matterTopicClosingStatuses = selectItems.filter((item) => item.value != 'OCCUPANCY');
    }
    this.localizeMatterTopicClosingStatuses(this.matterTopicClosingStatuses);
    if (this.matter && this.matter.matterClosingStatus === MatterClosingStatus.OCCUPANCY) {
      let occupancy: SelectItem = this.matterTopicClosingStatuses.find(
        (item) => item.value == MatterClosingStatus.OCCUPANCY
      );
      if (!occupancy) {
        this.matter.matterClosingStatus = MatterClosingStatus.QUESTION;
      }
    }
  }

  localizeMatterTopicClosingStatuses(matterTopicClosingStatuses: SelectItem[]) {
    if (this?.matter.isMatterProvinceBC) {
      matterTopicClosingStatuses.find((item) => item.value == MatterClosingStatus.POST_CLOSING).label =
        'Post Completion';
    }
  }

  isOccupationDateApplicable(): boolean {
    if (!this.matter.isCustomMatter()) {
      if (this.matter.isMatterProvinceABorMBorSK) {
        return this.matter.isPurchase || this.matter.isSale;
      } else if (this.matter.isMatterProvinceONorNBorNS) {
        return this.matter.isProjectSale
          ? true
          : (this.matter.isPurchase || this.matter.isSale) &&
              this.matter.matterPropertyWithCondo &&
              this.matter.matterPropertyWithCondo.isPropertyCondominium() &&
              this.matter.matterPropertyWithCondo.isNewHomeFromBuilder;
      }
    }
    return false;
  }

  initializeMassUpdate(): void {
    if (this.isMassUpdateTab()) {
      if (this.isMassUpdateTabTypeImportMatterAdjustment() && this.isMassUpdateTabUpdateLevel()) {
        this.massUpdateMessages = [];
        this.massUpdateMessages.push('.....Import Matter Adjustment Information.');
        this.performMassUpdate();
      } else {
        this.matter.dirtySubject = new Subject<boolean>();
        this.dirtySubscription = this.matter.dirtySubject.subscribe((isDirty) => {
          if (isDirty) {
            this.massUpdateTopics.add(this.getSectionTitleForMassUpdate());
          }
        });
      }
    }
  }

  getSectionTitleForMassUpdate(): string {
    let sectionTitle: string = this.getSectionTitle(this.activeSection);

    if (sectionTitle.indexOf('=') > 0) {
      //remove any equals suffixes, eg Purchaser's Mortgage(s) = 1
      sectionTitle = sectionTitle.substr(0, sectionTitle.indexOf('=') - 1);
    } else if (sectionTitle.startsWith('Notes')) {
      sectionTitle = 'Notes';
    }

    return sectionTitle;
  }

  addInputFieldListeners(): void {
    //Clearing field code text on focus out (i.e. when using Ctrl-F2)
    jQuery('.matters-content').on('focusout', 'input', () => {
      this.fieldCodeService.onFocusOutFieldCode();
    });
  }

  ngAfterViewInit(): void {
    if (this.isMatterRefunded) {
      this.showResetWarningMessage();
    }
  }

  cacheDefaultDocumentProfile(): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (accountId) {
      this.documentProfileService
        .getDefaultProfileForAccountFromCache(accountId, currentMatter.value.provinceCode)
        .subscribe((defaultDocProfile: DocumentProfile) => {
          if (defaultDocProfile) {
            this.documentProfileCache.cacheDefaultDocProfile(defaultDocProfile);
          }
        });
    }
  }

  showResetWarningMessage(): void {
    let resetMsg: string =
      'This matter is read-only because a reset or reversal of the software transaction charge has already been issued. ' +
      'As a result you cannot modify this record; however, you can copy it and modify the copied version. <br><br>' +
      'To copy a record, go to Matter Opening and < instructions based on final Unity UI > <br><br>' +
      'Note that if LOFTI policy numbers were included in the original record, you will have to choose ' +
      'whether the new record will be saved with LOFTI information (and you will have to assign new policy numbers), ' +
      'the record will be saved without LOFTI information, or the record will not be saved.';

    this.dialogService.confirm('WARNING - Matter has already been reset', resetMsg, true, 'OK').subscribe((res) => {
      if (res) {
        this.dialogService.closeDialog();
      }
    });
  }

  getSectionTitle(section: Section) {
    if (section && section.title) {
      if (section.title.startsWith('Mortgage')) {
        return 'Mortgages = ' + (currentMatter.value.mortgages ? currentMatter.value.mortgages.length : 0);
      } else if (section.title.startsWith('Existing Mortgage(s)') && !this.matter.isMatterProvinceBC) {
        return (
          'Existing Mortgage(s) = ' +
          (currentMatter.value.existingMortgages ? currentMatter.value.existingMortgages.length : 0)
        );
      } else if (section.title.startsWith('VTB Mortgage')) {
        return (
          (currentMatter.value && currentMatter.value.isProjectSale
            ? "Purchaser's Mortgage(s) = "
            : 'VTB Mortgage(s) = ') +
          (currentMatter.value.newOrEmpMortgages ? currentMatter.value.newOrEmpMortgages.length : 0)
        );
      } else if (section.title.startsWith('New Mortgage')) {
        return (
          'New Mortgage = ' + (currentMatter.value.newOrEmpMortgages ? currentMatter.value.newOrEmpMortgages.length : 0)
        );
      } else if (section.title.startsWith('Note')) {
        return (
          'Notes' +
          (currentMatter.value.notesList &&
          currentMatter.value.notesList.notes &&
          currentMatter.value.notesList.notes.length > 0
            ? ` (${currentMatter.value.notesList.notes.length})`
            : '')
        );
      } else if (section.title.startsWith(MatterSectionsTitles.UNITY_WILL_AI)) {
        return `${MatterSectionsTitles.UNITY_WILL_AI} <span class = "new-feature-color">(New)</span>`;
      } else if (section.title.startsWith(MatterSectionsTitles.CHARGES_TO_REMOVE) && this.matter.isMatterProvinceBC) {
        return `${MatterSectionsTitles.CHARGES_TO_REMOVE} = ${currentMatter.value.existingMortgages ? currentMatter.value.existingMortgages.length : 0}`;
      } else if (section.title.startsWith(MatterSectionsTitles.EXISTING_CHARGE) && this.matter.isMatterProvinceBC) {
        return `${MatterSectionsTitles.EXISTING_CHARGE} = ${currentMatter.value.existingMortgages ? currentMatter.value.existingMortgages.length : 0}`;
      } else {
        if (section.sectionKey == 'PROPERTY') {
          return MatterUtil.getSubjectProperty(this.matter, section.title);
        }

        let label = SectionLocalizationUtil.getSectionTitle(section.sectionKey, this.matter);
        return label ? label : section.title;
      }
    }

    return '';
  }

  getSectionPageTitle(section: Section): string {
    return this.getProvinceSectionPageTitle(section);
  }

  getProvinceSectionPageTitle(section: Section): string {
    if (section) {
      if (section.sectionKey == 'PROPERTY') {
        if (this.matter && this.matter.isOpportunityMatter()) {
          return section.title;
        } else if (this.matter && this.matter.isTemplateMatterForMassUpdate && this.matter.isMatterProvinceON) {
          return SubjectPropertyTitle.Subject_Property;
        } else {
          return SectionLocalizationUtil.getSectionTitle(section.sectionKey, this.matter);
        }
      }
      if (section.title.startsWith('VTB Mortgage')) {
        return this.matter && this.matter.isProjectSale ? "Purchaser's Mortgage(s)" : section.title;
      }
      let label = SectionLocalizationUtil.getSectionTitle(section.sectionKey, this.matter);
      return label ? label : section.title;
    }
    return '';
  }

  onDocumentKeyDown($event: KeyboardEvent) {
    if ($event.shiftKey && $event.ctrlKey && $event.altKey && $event.keyCode == 75 /*K*/) {
      this.matterService.uploadCompressionEnabled = !this.matterService.uploadCompressionEnabled;
      this.statusBarService.dynamicHelpFocus(
        'Matter save upload compression: ' + (this.matterService.uploadCompressionEnabled ? 'ON' : 'OFF')
      );
    }
    if ($event.shiftKey && $event.ctrlKey && $event.altKey && $event.keyCode == 84 /*T*/) {
      if (this.activeMatterTab) {
        this.activeMatterTab.showSnapshotDiffSourceContact = !this.activeMatterTab.showSnapshotDiffSourceContact;
        this.statusBarService.dynamicHelpFocus(
          'Show first difference between snapshot and source contact  : ' +
            (this.activeMatterTab.showSnapshotDiffSourceContact ? 'Show' : 'Hide')
        );
      }
    }
    let modalDialogElement = jQuery('.mat-modal-dialog');
    if (modalDialogElement.length < 1) {
      if ($event.keyCode == 27) {
        this.isShortCutMode = !this.isShortCutMode;
        if (this.isShortCutMode) {
          jQuery('ul#left-nav-matter li.active a').focus();
        } else {
          FirstFocusMatterUtil.setFocusOnFirstAvailableElement();
        }
      } else if (this.isShortCutMode) {
        let keyPress = $event.key;
        if (keyPress) {
          let section: Section = this.applicableSections.find((item) => item.shortCutKey == keyPress.toUpperCase());
          if (section && !this.isSectionDisabled(section) && !this.notYetImplemented(section.title)) {
            $event.stopPropagation();
            $event.preventDefault();
            this.openMatterSection(section.route, section);
            this.isShortCutMode = false;
          }
        }
      }
    }
  }

  isShortCutModeFocusOutFunction(): void {
    setTimeout(() => {
      if (jQuery('ul#left-nav-matter li a:focus').length < 1) {
        this.isShortCutMode = false;
      }
    }, 50);
  }

  setUpMatterObject(): void {
    if (this.matter && this.matter.isProjectSale) {
      if (this.isMassUpdateTab()) {
        //lock screen for editing before dirtySubject can be initialized (needed to capture topics updated by user)
        this.lockScreenService.lockForUpdate = true;
      }

      this.matterService
        .initProjectSaleMatter(this.matter, this.adjustmentPropagationService)
        .finally(() => {
          if (this.isMassUpdateTab()) {
            this.lockScreenService.lockForUpdate = false;
          }
        })
        .subscribe((isMatterCreated) => {
          if (
            isMatterCreated &&
            this.matter.project &&
            this.matter.project.isStatementOfAdjustmentInterim() &&
            !this.matter.isMatterExisting()
          ) {
            this.matter.selectedProgressionStatus = ProgressionStatus.INTERIM;
          }
          if (isMatterCreated && this.matter && !this.matter.locked && !this.isMatterReadOnly) {
            this.updateMatterWithThirdPartyDataAfterMatterSetup();
          }

          this.initializeMassUpdate();
          this.openCIRFImportModalIfApplicable();
        });
    } else {
      console.log('MatterComponent : setUpMatterObject ');
      this.matterService.initMatter(this.matter).subscribe(async (isMatterCreated) => {
        console.log('MatterComponent : initMatter : setUpMatterObject : completed ');
        if (isMatterCreated && this.matter && !this.matter.locked && !this.isMatterReadOnly) {
          this.updateMatterWithThirdPartyDataAfterMatterSetup();
          //Open cirf import modal
          this.openCIRFImportModalIfApplicable();
          //Open referral import modal
          this.openReferralImportModalIfApplicable();

          if (this.matter && this.matter.hasPropertyJurisdiction()) {
            // Because we update compliance according to the latest jurisdiction data, it will enable save button.
            this.matterService.updateCompliancesWithLatestJurisdiction(this.matter);
          }

          await this.matterService.initializeOpportunity(this.matter, this.account);

          if (
            this.account &&
            !this.account.isDigitalSigningAccessAvailable() &&
            this.matter.isDigitalSignPlatformSet()
          ) {
            this.matter.digitalSignaturePlatform = undefined;
            this.matter.dirty = true;
          }
          // if the opportunity is disabled, we don't do duplicateProspectsCheck
          if (
            !this.isMatterReadOnly &&
            this.matter.opportunity &&
            this.matter.opportunity.sourceReferralId &&
            this.matter.opportunity.opportunityStatus !== OpportunityMatterStatusValue.closedDuplicate
          ) {
            //jump to purchase component
            await this.duplicateProspectsCheck();
            if (
              Array.isArray(this.matter.duplicateProspectDataList) &&
              this.matter.duplicateProspectDataList.length > 0
            ) {
              let section: Section = this.applicableSections.find((item) => item.sectionKey == 'PURCHASER');
              this.openMatterSection(section.route, section);
            } else {
              this.checkIfOpportunityConversionModalIsRequiredAndOpen();
            }
          } else {
            this.checkIfOpportunityConversionModalIsRequiredAndOpen();
          }
        }
      });
    }
  }

  async duplicateProspectsCheck(): Promise<void> {
    let duplicateProspectDataList: DuplicateContactData[] = [];
    let contactObservables: Observable<any>[] = [];

    this.matter.mainClients.forEach((client) => {
      if (
        client &&
        client.contact &&
        client.contact.contactCategory == 'PROSPECT' &&
        client.contact.displayName &&
        !client.contact.duplicateProspectActionTaken
      ) {
        let duplicateProspectData: DuplicateContactData = new DuplicateContactData();
        duplicateProspectData.existingProspectMatterParticipant = client;
        duplicateProspectData.actionTaken = ActionTakenTypes.DO_NOTHING;
        duplicateProspectDataList.push(duplicateProspectData);
        contactObservables.push(this.contactService.duplicateProspectsSearch(client.contact));
      }
    });
    if (contactObservables && contactObservables.length) {
      let responseData = await Observable.forkJoin(contactObservables).toPromise();
      responseData.forEach((data: Contact[], index: number) => {
        duplicateProspectDataList[index].duplicateSourceContacts = data.filter(
          (item) =>
            item.id != duplicateProspectDataList[index].existingProspectMatterParticipant.contact.sourceContactId
        );
      });
      duplicateProspectDataList = duplicateProspectDataList.filter(
        (item) => Array.isArray(item.duplicateSourceContacts) && item.duplicateSourceContacts.length > 0
      );
      if (Array.isArray(duplicateProspectDataList) && duplicateProspectDataList.length > 0) {
        this.matter.duplicateProspectDataList = duplicateProspectDataList;
      }
    }
  }

  updateMatterWithThirdPartyDataAfterMatterSetup(): void {
    if (
      this.matter.matterTitleInsurance &&
      this.matter.matterTitleInsurance.dealId &&
      this.matter.isInsurerStewartTitle() &&
      this.matter.id > 0
    ) {
      this.stewartTitleService.getRelevantStewartUserForMatter(this.matter.id).subscribe((result: StewartTitleUser) => {
        if (result) {
          this.refreshThirdPartyData(result);
        } else {
          this.errorService.addDpThirdPartyNotification(
            DPError.createDPError('integrations.stewartTitle.validCredentials')
          );
          //continue with overall third party refresh without stewart title user so stewart title refresh will be bypassed.
          this.refreshThirdPartyData();
        }
      });
    } else {
      this.refreshThirdPartyData();
    }
    this.matter.checkOutOfRangeWarning(this.errorService);
  }

  loadMatterDocumentProfileInCache(): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (currentMatter.value.documentProfileId) {
      this.documentProfileService
        .getById(currentMatter.value.documentProfileId, accountId, false, currentMatter.value)
        .subscribe((documentProfile: DocumentProfile) => {
          if (documentProfile) {
            this.documentProfileCache.cacheDocumentProfile(documentProfile);
          }
        });
    }
  }

  async loadInterestRates(matter: Matter): Promise<void> {
    this.matter.interestRates = [];
    let interestRates: InterestRate[] = await this.taxRateService.cachedInterestRates(matter.provinceCode).toPromise();
    matter.interestRates.push(...(interestRates || []));
  }

  get isLinkedMatterDirty(): boolean {
    return this.tabsService.isLinkedMatterDirty();
  }

  // this method will call the backend api and get the matter by id.
  initializeMatter(): void {
    this.initializeNonOpportunityMatter();

    this.isMatterReadOnly = MatterUtil.isMatterDisabled(
      this.matter,
      this.activeMatterTab,
      this.authZService,
      this.tabsService
    );
    this.activeMatterTab.matterComponent = this;

    //The matter component is instantiated when a matter tab is activated (through a route navigation to a URL mapped to the
    // MatterComponent). Therefore, the active tab should be a MatterTab, so we'll pull the matter state from the MatterTab
    // instance. This will ensure we're operating on the matter model stored with the tab (and kept in local storage while the tab
    // is not active).

    let matterTab = this.activeMatterTab;
    let matter = matterTab.matter;

    this.isMatterLocked = matter.locked;
    this.displayUnlockMessageForMatter = false;
    this.isMatterRefunded = matter.refunded;

    if (matter.unityProjectId) {
      this.createProjectDropdownMenu();
    }

    if (matter.locked) {
      // disable matter tabs
      this.displayUnlockMessageForMatter = false;
      this.matterLockedByUserName = matter.lockedByUser.firstName + ' ' + matter.lockedByUser.lastName;

      this.logger.info('isMatterLocked:' + this.isMatterLocked);
      this.logger.info('By:' + this.matterLockedByUserName);

      this.matterLockedStatusSubscription = Observable.interval(3000)
        .switchMap(() => {
          return this.matterService.getMatterLockStatus(matter.id);
        })
        .subscribe((status: any) => {
          let lockStatus: boolean = status.MatterLockStatus;

          if (!lockStatus) {
            this.logger.info('Matter status is unlock now');
            //this.isMatterLocked = lockStatus;
            this.displayUnlockMessageForMatter = true;
            this.matterLockedByUserName = undefined;
            this.matterLockedStatusSubscription.unsubscribe();
          }
        });
    } else {
      if (this.matterLockedStatusSubscription) {
        this.logger.info('matterLockedStatusSubscription is pending so unsubscribe');
        this.matterLockedStatusSubscription.unsubscribe();
      }
    }

    //The downstream components are still using the current matter constant, we'll put the data here, too, for the time being
    currentMatter.value = new Matter(matter);
    this.setUpMatterObject();
  }

  initializeNonOpportunityMatter(): void {
    if (this.matter && !this.matter.isOpportunityMatter()) {
      this.loadMatterDocumentProfileInCache();
      if (this.matter.unityProjectId) {
        this.createProjectDropdownMenu();
      }
      this.loadInterestRates(this.matter);
    }
  }

  get matterProjectName(): string {
    return this.matter && this.matter.project ? this.matter.project.projectRecordNumber : '';
  }

  get isMatterInRoMode(): boolean {
    return this.isMatterLocked || this.isMatterReadOnly;
  }

  getMatterToUpdate(matterId: number): Matter {
    if (matterId == this.matter.id) {
      return this.matter;
    } else {
      let matterTab: MatterTab = this.tabsService.getMatterTab(matterId);
      return matterTab ? matterTab.matter : null;
    }
  }

  get activeMatterTab(): MatterTab {
    return this.tabsService && (this.tabsService.activeTab as MatterTab);
  }

  get matter(): Matter {
    return this.activeMatterTab && this.activeMatterTab.matter;
  }

  get applicableSections(): Section[] {
    if (!this.matter) {
      return [];
    }

    if (this.matter.isCustomMatter()) {
      return this.matter.getApplicationSectionsForCustomMatters(this.appConfig);
    }

    return this.matter.applicableSections;
  }

  /*
   * We introduced visibleSections Instead of applicableSections
   * to show and hide some sections dynamically
   * Because using applicableSections will cause some issues
   * with refreshing the sections and with the active state of the sctions
   * */

  get visibleSections(): Section[] {
    if (this.matter && this.matter.isOpportunityMatter() && !this.matter.isOpportunityPurchaseSaleMortgageOrDischarge) {
      let sections = this.applicableSections.filter(
        (section) => !(section.sectionKey == 'PROPERTY' && section.applicableFor == 'O')
      );
      return this.matter.isOpportunityMatterAndTypeWill()
        ? sections.filter((s) => s.title !== MatterSectionsTitles.OTHER_SIDE)
        : sections;
    } else {
      if (this.matter.isOpportunityDischarge) {
        return this.applicableSections.filter(
          (section) => !(section.sectionKey == 'VENDOR_SOLICITOR' && section.applicableFor == 'CO')
        );
      } else if (this.account?.applications.every((app) => !app.isUnityWillAIApplication() || !app.active)) {
        return this.applicableSections.filter(
          (section) => !(section.sectionKey == 'UNITY_WILL_AI' && section.applicableFor == 'W')
        );
      } else {
        return this.applicableSections;
      }
    }
  }

  isMassUpdateSectionDisabledForTOPIC_A_K_M_N(sectionKey: MatterTopicKey): boolean {
    return (
      sectionKey == 'SUPPLEMENTAL_TASKS' ||
      sectionKey == 'REQUISITIONS' ||
      sectionKey == 'TRUST_LEDGER' ||
      sectionKey == 'STATEMENT_OF_ACCOUNT' ||
      sectionKey == 'FORMS' ||
      sectionKey == 'DOCUMENT_PRODUCTION' ||
      sectionKey == 'EXECUTION_AFFIDAVITS' ||
      sectionKey == 'MATTER_DOCUMENTS' ||
      sectionKey == 'NOTES'
    );
  }

  isSectionDisabled(section: Section): boolean {
    let sectionTitle = section.title;
    let sectionKey = section.sectionKey;
    if (this.activeMatterTab.tabSubType == 'massUpdate') {
      let massUpdateType: MassUpdateType = (this.activeMatterTab as MassUpdateTab).massUpdateType;
      if (massUpdateType == 'TOPIC_A_K_M_N' && this.isMassUpdateSectionDisabledForTOPIC_A_K_M_N(section.sectionKey)) {
        return true;
      } else if (massUpdateType == 'TOPIC_A_K_M_N' && sectionKey == 'UNDERTAKINGS') {
        if (this.matter.getExistingMortgagesToBeDischarged().length == 0) {
          return true;
        }
      } else if (massUpdateType == 'TOPIC_A_K_M_N' && sectionKey == 'CONDO_CORPORATION') {
        return !this.matter.isCondoCorporationTabEnabled;
      } else if (massUpdateType == 'ADD_NEW_SUPPLEMENTAL_TASKS' && sectionKey !== 'SUPPLEMENTAL_TASKS') {
        return true;
      } else if (
        (massUpdateType == 'SOA_GL_CODES' ||
          massUpdateType == 'SOA_GL_CODES_FINAL' ||
          massUpdateType == 'SOA_GL_CODES_INTERIM') &&
        sectionKey !== 'STATEMENT_OF_ACCOUNT'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return (
        (sectionTitle === 'Title Insurance' && !this.matter.isTitleInsured()) ||
        (sectionTitle === 'Forms' && this.isMatterNoNotAvailable) ||
        (sectionTitle === 'Condo Corporation' && !this.matter.isCondoCorporationTabEnabled) ||
        (sectionTitle === 'Strata Corporation' && !this.matter.isCondoCorporationTabEnabled) ||
        (sectionTitle === 'Requisitions' &&
          currentMatter.value.isMortgage &&
          currentMatter.value.actingFor !== 'MORTGAGEE') ||
        this.isProjectONABPurchaserFireInsurance(sectionTitle, "Purchaser's Fire Insurance") ||
        !this.matter.filterWillsPages(section)
      );
    }
  }

  private isProjectONABPurchaserFireInsurance(sectionTitle: string, titleKey: string): boolean {
    return sectionTitle === titleKey && currentMatter.value && currentMatter.value.isProjectSale
      ? false
      : sectionTitle === titleKey && currentMatter.value.mortgages && currentMatter.value.mortgages.length == 0;
  }

  get isSectionDocumentProduction(): boolean {
    /// must override document production sections to customize readonly capabilities
    return (
      this.applicableSections &&
      this.applicableSections.findIndex(
        (item) =>
          item.active &&
          (item.title == 'Document Production' ||
            item.title == 'Matter Documents' ||
            item.title == 'Forms' ||
            (item.title == MatterSectionsTitles.OVERVIEW && !this.isWorkItemsSubtab()))
      ) > -1
    );
  }

  isSectionNotes(): boolean {
    return (
      this.applicableSections &&
      this.applicableSections.findIndex((item) => item.active && item.sectionKey == 'NOTES') > -1
    );
  }

  isSectionAssociatedFiles(): boolean {
    return (
      this.applicableSections &&
      this.applicableSections.findIndex((item) => item.active && item.sectionKey == 'ASSOCIATED_FILES') > -1
    );
  }

  openMatterSection(routeUrl: string, section?: Section): void {
    if (
      this.matter?.isWillMatter() &&
      [
        MatterSectionsTitles.PARTIES_AND_ROLES,
        MatterSectionsTitles.MATTER_OPENING,
        MatterSectionsTitles.CLIENT_INFO,
        MatterSectionsTitles.ASSETS_AND_GIFTS
      ].includes(this.activeSection.title)
    ) {
      this.matterService.callOperationAfterMatterSaved(this.matter, () => this.redirectToRoute(routeUrl, section));
    } else {
      this.redirectToRoute(routeUrl, section);
    }
  }

  redirectToRoute(routeUrl: string, section?: Section) {
    if (!section || (section && !this.isSectionDisabled(section))) {
      let id: number = +this.route.snapshot.params['id'];
      let redirectRoute: string = this.getRoute(id, this.matter.isOpportunityMatter()) + routeUrl;
      if (!section) {
        //TODO: we should probably find the section based on the route, rather than defaulting to first
        if (this.matter.id) {
          this.setActiveSection(1);
        } else {
          this.setActiveSection(0);
        }
      } else if (section.active) {
        return; // Need to return. This disables double clicking on a selected tab.
      } else {
        this.inactivateAllSections();
        section.active = true;
      }
      this.tabsService.saveTabsToSessionStorageForMatter();
      this.activeMatterTab.section = routeUrl;
      this.activeMatterTab.childComponentSelection = null;
      this.router.navigate([redirectRoute]);
      if (!this.isStickyNoteVisibleForMatter()) {
        this.hideStickyNotes();
      }
    }
  }

  async validateTemplateMatter(): Promise<void> {
    if (this.errorService.hasErrors()) {
      const errorMsg: string =
        'System can not proceed with mass update until all error entries are fixed.\n\nFor error details, see the error entries listed in the flyout at the bottom of the screen.';
      this.dialogService.confirm('Error in Mass Update Criteria', errorMsg, true, null, null, true);
    } else {
      let toProceed: boolean = await this.validateComplianceTopic();
      if (toProceed) {
        if (this.matter.dirtySubject && this.dirtySubscription) {
          this.matter.dirtySubject.complete();
          this.dirtySubscription.unsubscribe();
        }
        this.openMassUpdateMatterList();
      } else {
        this.dialogService.confirm(
          'Error',
          'In order to mass update fields for the Compliance topic, a jurisdiction must first be selected in the Property topic.',
          true
        );
      }
    }
  }

  validateComplianceTopic(): Promise<boolean> {
    return this.massUpdateService.validateComplianceTopic(this.matter);
  }

  openMassUpdateMatterList(): void {
    let id: number = +this.route.snapshot.params['id'];
    let redirectRoute: string = this.getRoute(id) + 'massUpdateMatterList';
    (this.tabsService.activeTab as MassUpdateTab).massUpdateStage = 'SELECTION_LEVEL';
    this.router.navigate([redirectRoute]);
  }

  // this method will create the url for the tab selected.
  getRoute(id: number, isOpportunityMatter?: boolean): string {
    let type: string = this.route.snapshot.params['type'];
    let baseUrlMatterTabs: string = isOpportunityMatter ? opportunityBaseUrl : matterBaseUrl;
    let redirectRoute = `${baseUrlMatterTabs}/${id}/${type}/`;
    return redirectRoute;
  }

  validateAndSaveMatter(
    optionalMatter?: Matter,
    unassignedMortgageInstructionId?: number,
    cancelledMortgageInstructionIds?: number[],
    newMatterRecordNumber?: string,
    customErrorService?: ErrorService
  ): Observable<boolean> {
    //Verify if the matter was not billed before
    if (currentMatter.value && currentMatter.value.id > 0 && currentMatter.value.isDraftMatter()) {
      //Remove lock for update layer
      this.lockScreenService.lockForUpdate = false;
      let validationSubject = new Subject<boolean>();
      let message =
        'The record was created by copying a project. At the time of making the copy, transaction charges were not applied to the newly-created records.<br><br>' +
        'You cannot modify this record unless the transaction charge is re-applied. Do you wish to:';
      this.dialogService
        .confirm('WARNING!', message, false, 'Apply software transaction charge')
        .subscribe((result) => {
          if (result) {
            this.lockScreenService.lockForUpdate = true;
            this.completeMatterValidation(
              optionalMatter,
              unassignedMortgageInstructionId,
              cancelledMortgageInstructionIds,
              newMatterRecordNumber,
              customErrorService
            )
              .finally(() => {
                this.lockScreenService.lockForUpdate = false;
              })
              .subscribe((isMatterValid) => {
                validationSubject.next(isMatterValid);
                validationSubject.complete();
              });
          } else {
            validationSubject.next(false);
            validationSubject.complete();
          }
        });
      return validationSubject;
    } else {
      return this.completeMatterValidation(
        optionalMatter,
        unassignedMortgageInstructionId,
        cancelledMortgageInstructionIds,
        newMatterRecordNumber,
        customErrorService
      );
    }
  }

  completeMatterValidation(
    optionalMatter?: Matter,
    unassignedMortgageInstructionId?: number,
    cancelledMortgageInstructionIds?: number[],
    newMatterRecordNumber?: string,
    customErrorService?: ErrorService
  ): Observable<boolean> {
    if (customErrorService == null) {
      customErrorService = this.errorService;
    }

    // Verifying Linking Flow both current Matter and Optional Matter Should have matter link
    if (!optionalMatter && currentMatter.value && currentMatter.value.matterLink) {
      // when you need to save currentMatter and Linked Matter Changes together in single transactions.
      // Pushing Current Matter
      let bulkMatterUpdate = new BulkMatterUpdate(); // matterList : Matter[] = [];
      bulkMatterUpdate.matters = [];
      if (unassignedMortgageInstructionId || cancelledMortgageInstructionIds) {
        bulkMatterUpdate.matterIdWithCancelledOrUnassignedEmp = currentMatter.value.id;
      } else {
        bulkMatterUpdate.matterIdWithCancelledOrUnassignedEmp = undefined;
      }
      bulkMatterUpdate.unassignedMortgageInstructionId = unassignedMortgageInstructionId;
      bulkMatterUpdate.cancelledMortgageInstructionIds = cancelledMortgageInstructionIds;
      currentMatter.value.updateMatterRecordNumber(newMatterRecordNumber);
      bulkMatterUpdate.matters.push(currentMatter.value);
      let linkedMatter = this.activeMatterTab.linkedMatter;

      if (
        linkedMatter &&
        currentMatter.value.matterLink &&
        linkedMatter.id == currentMatter.value.matterLink.linkedMatterId
      ) {
        return this.matterService.initMatter(linkedMatter).flatMap((isMatterUpdated) => {
          if (isMatterUpdated) {
            // Copy Field from current Matter onto Linked Matter.
            return this.copyMatterLinkDataService
              .copyLinkedMatterFields(currentMatter.value, linkedMatter)
              .flatMap((isCopyCompleted) => {
                if (isCopyCompleted) {
                  bulkMatterUpdate.matters.push(linkedMatter);
                  // Validate only current Matter
                  return this.matterService
                    .validateMatter(currentMatter.value, newMatterRecordNumber, customErrorService)
                    .flatMap((matterValidated) => {
                      if (matterValidated) {
                        this.soajFieldCodeService.generateMissingSoajFieldCodes();
                        MatterStatementAdjustmentUtil.generateMissingStatementOfAdjustmentsOrder(this.matter);
                        // save currentMatter and Linked Matter Changes together in single transactions.
                        return this.matterService
                          .updateMultipleMatterUnAssignOrCancelEMP(bulkMatterUpdate, this.account, customErrorService)
                          .flatMap((matters) => {
                            if (matters) {
                              if (linkedMatter) {
                                // Invoke this method only to linked matter object onto active matter tab and if tab is openeed for linked matter
                                // then it updated linked matter tab with latest matter
                                let updateLinkedMatter = matters.find((item) => item.id == linkedMatter.id);
                                if (
                                  updateLinkedMatter &&
                                  this.activeMatterTab.linkedMatter &&
                                  this.activeMatterTab.linkedMatter.id == updateLinkedMatter.id
                                ) {
                                  this.activeMatterTab.linkedMatter = updateLinkedMatter;
                                  let matterTab = this.tabsService.getMatterTab(updateLinkedMatter.id);
                                  if (matterTab) {
                                    this.matterService.initMatter(updateLinkedMatter).subscribe((isMatterUpdated) => {
                                      if (isMatterUpdated) {
                                        matterTab.matter = updateLinkedMatter;
                                      }
                                    });
                                  }
                                }
                              }

                              let updatedMatter = matters.find((item) => item.id == this.activeMatterTab.matter.id);
                              if (updatedMatter) {
                                // Invoke this method only for current Matter since it update current active tab with latest Matter
                                return this.matterService
                                  .updateMatterAfterSave(
                                    updatedMatter,
                                    customErrorService,
                                    this.adjustmentPropagationService
                                  )
                                  .flatMap((updatedAndInitMatter: Matter) => {
                                    optionalMatter ? optionalMatter : (currentMatter.value = updatedAndInitMatter);
                                    let updateLinkedMatter = matters.find((item) => item.id == linkedMatter.id);
                                    if (updateLinkedMatter) {
                                      this.activeMatterTab.linkedMatter = updateLinkedMatter;
                                      let matterTab = this.tabsService.getMatterTab(updateLinkedMatter.id);
                                      if (matterTab) {
                                        matterTab.matter = updateLinkedMatter;
                                        matterTab.backEndMatter = new Matter(updateLinkedMatter);
                                        matterTab.linkedMatter = updatedAndInitMatter;
                                      }
                                    }
                                    this.loadInterestRates(updatedAndInitMatter);
                                    currentMatter.updateMatterStateAndNotify(updatedAndInitMatter);
                                    this.updateMatterList(updatedMatter);
                                    return Observable.of(true);
                                  });
                              } else {
                                return Observable.of(false);
                              }
                            } else {
                              return Observable.of(false);
                            }
                          })
                          .catch((error: any) => {
                            this.logger.error('Error executing matter save action: ', error);
                            return Observable.of(false);
                          });
                      } else {
                        return Observable.of(false);
                      }
                    });
                } else {
                  return Observable.of(false);
                }
              });
          } else {
            return Observable.of(false);
          }
        });
      }
    } else {
      let matter = optionalMatter ? optionalMatter : currentMatter.value;
      let isNewMatter: boolean = matter && (!matter.id || matter.id < 0);
      let referralId: number = matter.referralId;
      this.saveMatterUtility();
      // Change Matter Record Number
      matter.updateMatterRecordNumber(newMatterRecordNumber);
      return this.matterService
        .validateMatter(matter, newMatterRecordNumber, customErrorService)
        .flatMap((matterValidated) => {
          if (matterValidated) {
            this.soajFieldCodeService.generateMissingSoajFieldCodes();
            MatterStatementAdjustmentUtil.generateMissingStatementOfAdjustmentsOrder(this.matter);

            if (
              currentMatter.value.soaTrustLedgerCollection &&
              currentMatter.value.soaTrustLedgerCollection.feeCalculatedOnInclusivePriceSelection
            ) {
              currentMatter.value.soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
            }

            return Observable.fromPromise(this.adjustmentPropagationService.updateLinkedStatementAdjustments(matter))
              .flatMap(() => {
                return this.matterService
                  .saveMatter(
                    matter,
                    unassignedMortgageInstructionId,
                    cancelledMortgageInstructionIds,
                    this.account,
                    customErrorService
                  )
                  .flatMap((updatedMatter: Matter) => {
                    if (optionalMatter) {
                      // Invoke this method only to linked matter object onto active matter tab and if tab is openeed for linked matter
                      // then it updated linked matter tab with latest matter
                      optionalMatter = updatedMatter;
                      if (
                        optionalMatter.matterLink &&
                        this.activeMatterTab.matter.id == optionalMatter.matterLink.linkedMatterId
                      ) {
                        this.activeMatterTab.linkedMatter = optionalMatter;
                        let matterTab = this.tabsService.getMatterTab(optionalMatter.id);
                        if (matterTab) {
                          matterTab.matter = optionalMatter;
                        }
                      }
                      return Observable.of(true);
                    } else if (updatedMatter) {
                      // Invoke this method only for current Matter since it update current active tab with latest Matter
                      return this.matterService
                        .updateMatterAfterSave(updatedMatter, customErrorService, this.adjustmentPropagationService)
                        .flatMap((updatedAndInitMatter: Matter) => {
                          if (optionalMatter) {
                            optionalMatter = updatedAndInitMatter;
                          } else {
                            currentMatter.updateMatterStateAndNotify(updatedAndInitMatter);
                          }
                          if (updatedAndInitMatter) {
                            updatedAndInitMatter.dirty = false;
                            if (!updatedMatter.isOpportunityMatter()) {
                              this.loadInterestRates(updatedMatter);
                            }

                            this.updateMatterList(updatedMatter);
                            let isWizardTab =
                              this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isWizard();
                            if (isNewMatter && !isWizardTab) {
                              this.router
                                .navigate(
                                  [
                                    `${this.getRoute(updatedMatter.id, updatedMatter.isOpportunityMatter())}/${this.getActiveSectionRoutePath()}`
                                  ],
                                  {queryParams: {doNotReload: true}}
                                )
                                .then(() => {
                                  this.router.navigate([], {
                                    queryParams: {
                                      doNotReload: null
                                    },
                                    queryParamsHandling: 'merge'
                                  });
                                });
                              let matterTab = this.tabsService.getMatterTab(updatedMatter.id);
                              this.tabsComponent.saveProjectMattersToMatterTab(updatedMatter, matterTab);
                            }
                            if (referralId) {
                              this.opportunitiesService.acceptReferral(updatedMatter.id, referralId).subscribe();
                              this.unitConnectImportService.applyDeferredCommands(updatedMatter, referralId);
                            }
                            // Since we have issue between matter.ts holding applicable sections and section component holding section
                            // we need some clean up .. for now i am refreshing section in section component with applicable section
                            // from matter in case it does not have overview section
                            if (
                              this.sections &&
                              !this.sections.some((section) => section.title == MatterSectionsTitles.OVERVIEW)
                            ) {
                              this.refreshSections(this.applicableSections);
                            }

                            return Observable.of(true);
                          }
                        });
                    } else {
                      return Observable.of(true);
                    }
                  })
                  .catch((error: any) => {
                    this.logger.error('Error executing matter save action: ', error);
                    return Observable.of(false);
                  });
              })
              .catch((error: any) => {
                this.logger.error('Error executing matter save action: ', error);
                return Observable.of(false);
              });
          } else {
            return Observable.of(false);
          }
        });
    }
  }

  async validateAndSaveMatterAndSubscribe(): Promise<void> {
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    this.lockScreenService.lockForUpdate = true;
    let toProceed = await this.isMatterProjectNotLocked();
    this.lockScreenService.lockForUpdate = false;
    if (toProceed) {
      let isMatterCreated: boolean = !currentMatter.value.id || currentMatter.value.id < 0;
      this.lockScreenService.lockForUpdate = true;
      this.saveSubscription = this.validateAndSaveMatter()
        .finally(() => {
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe((result: boolean) => {
          if (result) {
            // Need to check whether we need this after updating or saving matter.
            let matterTab = this.tabsService.activeTab as MatterTab;
            if (matterTab) {
              matterTab.matterComponent = this;
            }
            if (isMatterCreated) {
              /* While creating new matter if it's not billed due to any system error then showing billing delay message. Any business errors related to
                 billing are handled in MatterService.handleApiErrors. */
              if (!currentMatter.value.isBilled()) {
                if (currentMatter.value.hasBillingFailed()) {
                  //if any business errors then matter will become readonly else can continue working on it
                  this.isMatterReadOnly = currentMatter.value.hasBillingFailed();
                } else {
                  if (!currentMatter.value.isOpportunityMatter()) {
                    this.dialogService
                      .confirm(
                        'Information',
                        'Billing of this matter may be delayed. You can still continue to work on the matter.',
                        true
                      )
                      .subscribe();
                  }
                }
              }
              if (!currentMatter.value.isOpportunityMatter()) {
                this.checkWarningLevel();
              }
              this.router
                .navigate(
                  [
                    `${this.getRoute(currentMatter.value.id, currentMatter.value.isOpportunityMatter())}/${this.getActiveSectionRoutePath()}`
                  ],
                  {queryParams: {doNotReload: true}}
                )
                .then(() => {
                  this.router.navigate([], {
                    queryParams: {
                      doNotReload: null
                    },
                    queryParamsHandling: 'merge'
                  });
                });
            }
          }
        });
    } else {
      this.showProjectLockedErrorMessage();
    }
  }

  public checkMatterBilling(): void {
    if (!currentMatter.value.isBilled()) {
      if (currentMatter.value.hasBillingFailed()) {
        //if any business errors then matter will become readonly else can continue working on it
        this.isMatterReadOnly = currentMatter.value.hasBillingFailed();
      } else {
        if (!currentMatter.value.isOpportunityMatter()) {
          this.dialogService
            .confirm(
              'Information',
              'Billing of this matter may be delayed. You can still continue to work on the matter.',
              true
            )
            .subscribe();
        }
      }
    }
    if (!currentMatter.value.isOpportunityMatter()) {
      this.checkWarningLevel();
    }
  }

  public checkWarningLevel(): void {
    if (this.account.paymentMethodType == 'CREDIT_CARD' && this.isQuickOpenBillingVisibleForMatter()) {
      this.unityBillingService.getCurrentBalance().subscribe((res: UnityBilling) => {
        if (res && Number(res.currentBalance) <= Number(res.warningLevelAmount)) {
          let currentBalance = this.currencyPipe.transform(res.currentBalance, 'CAD', 'symbol-narrow', '1.2-2');
          let message =
            'You have a balance of ' +
            currentBalance +
            ' in transaction credits remaining.<br>' +
            'You may not be able to create new matters if the account balance falls below $0.00.';
          this.dialogService.confirm('Warning re Balance in Transaction Management Account', message, true, 'OK');
        }
      });
    }
  }

  onActivate() {
    this.window.nativeWindow.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    this.logger.info('OnDestroy');

    if (this.matterLockedStatusSubscription) {
      this.logger.info('matterLockedStatusSubscription is pending so unsubscribe');
      this.matterLockedStatusSubscription.unsubscribe();
    }

    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }

    if (this.navigationEventSubscription) {
      this.navigationEventSubscription.unsubscribe();
    }
    if (this.matterSwitchSubscription) {
      this.matterSwitchSubscription.unsubscribe();
    }

    if (this.matter && this.matter.dirtySubject) {
      this.matter.dirtySubject.complete();
    }

    if (this.dirtySubscription) {
      this.dirtySubscription.unsubscribe();
    }

    if (this.matterUtilitySaveSubscription) {
      this.matterUtilitySaveSubscription.unsubscribe();
    }

    this.projectMatterUpdateService.destroyMassUpdatePolling();
  }

  get isMatterNoNotAvailable(): boolean {
    return !currentMatter.value.matterRecordNumber;
  }

  get activeSection(): Section {
    return this.applicableSections ? this.applicableSections.find((item) => item.active) : undefined;
  }

  isActiveSectionOverview(): boolean {
    return this.activeSection && this.activeSection.sectionKey == 'OVERVIEW';
  }

  isActiveSectionDuediligence(): boolean {
    return this.activeSection && this.activeSection.sectionKey == 'DUE_DILIGENCE';
  }

  isActiveSectionNotes(): boolean {
    return this.activeSection && this.activeSection.sectionKey == 'NOTES';
  }

  isWorkItemsSubtab(): boolean {
    return (
      this.getActiveSectionRoutePath() == WORKITEMS_ROUTE_PATH ||
      this.getActiveSectionRoutePath() == OPPORTUNITY_WORKITEMS_ROUTE_PATH
    );
  }

  notYetImplemented(sectionTitle: string): boolean {
    let notImplementedSectionList: string[] = [];
    switch (currentMatter.value.matterType) {
      case 'PURCHASE':
        break;
      case 'SALE':
        // notImplementedSectionList = ['Direction Re Funds'];
        break;
      default:
        break;
    }
    // console.log("Section [" + sectionTitle + "] function is not yet implemented ? " + result);
    return sectionTitle && !!notImplementedSectionList.find((title) => title == sectionTitle);
  }

  async refreshThirdPartyData(stewartTitleRelevantUser?: StewartTitleUser): Promise<void> {
    this.isThirdPartyCallInProcess = false;
    let isEmpDataUpdated: boolean = false;
    let titlePlusMatterUser: TitlePlusMatterUser;
    if (
      currentMatter.value.id &&
      currentMatter.value.id > 0 &&
      !currentMatter.value.locked &&
      !this.activeMatterTab.updateThirdPartyData
    ) {
      let obsArray: any[] = [];
      this.activeMatterTab.updateThirdPartyData = true;
      if (this.matter.empMortgages && this.matter.empMortgages.length > 0) {
        this.matter.empMortgages.forEach((item) => {
          if (
            item.stewartAssystMortgageInstruction.dealId &&
            !item.stewartAssystMortgageInstruction.hasFileClosedByLender() &&
            !item.stewartAssystMortgageInstruction.hasFileClosedByOtherLender()
          ) {
            this.isThirdPartyCallInProcess = true;
            if (item.stewartAssystMortgageInstruction.isFctInstruction) {
              if (item.stewartAssystMortgageInstruction.isFctDealInCompletedStatus || this.isMatterInRoMode) {
                //don't refresh the status, if FCT deal is Completed, US17871 OR matter is read only;
                this.isThirdPartyCallInProcess = false;
                return;
              }
              obsArray.push(
                this.fctService
                  .refreshDealStatus(
                    item.stewartAssystMortgageInstruction.solicitorId,
                    item.stewartAssystMortgageInstruction.dealId
                  )
                  .catch((error) => Observable.of(error))
              );
            } else {
              if (
                !item.stewartAssystMortgageInstruction.isInstructionCancelled &&
                !item.stewartAssystMortgageInstruction.isInstructionClosedByLender &&
                !item.stewartAssystMortgageInstruction.isFctInstruction
              ) {
                obsArray.push(
                  this.telusService
                    .getDealData(
                      item.stewartAssystMortgageInstruction.solicitorId,
                      item.stewartAssystMortgageInstruction.dealId
                    )
                    .catch((error) => {
                      if (error && error.errorCode !== 'app.stewartAssysInstructionReissued') {
                        const isAssyst = 'Assyst Real Estate';
                        const dealID = item.stewartAssystMortgageInstruction.dealId
                          ? item.stewartAssystMortgageInstruction.dealId
                          : '[DEAL_ID]';
                        error.message.includes(isAssyst)
                          ? (error.message =
                            'Unity® Lender Centre Deal ID ' +
                            dealID +
                            ' has been archived and is no longer available in the Unity® Lender Centre portal. Documents can be accessed from the Matter Documents tab.')
                          : error.message;
                      }
                      return Observable.of(error);
                    })
                );
              }
            }
          }
        });
      }
      if (this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.dealId) {
        this.isThirdPartyCallInProcess = true;
        if (this.matter.isInsurerStewartTitle() && stewartTitleRelevantUser) {
          obsArray.push(
            this.stewartTitleService
              .getResult(this.matter, stewartTitleRelevantUser, this.matter.matterTitleInsurance.dealId)
              .catch((error) => Observable.of(error))
          );
        } else if (
          this.matter.isInsurerChicagoTitle() &&
          (!this.matter.matterTitleInsurance.isChicagoReadyForPickup() ||
            !this.matter.matterTitleInsurance.isChicagoDownloadsCompleted())
        ) {
          obsArray.push(
            this.chicagoTitleService.getAllStatusDetails(this.matter).catch((error) => Observable.of(error))
          );
        } else if (this.matter.isInsurerFCT() && !this.matter.matterTitleInsurance.isFCTDownloadsCompleted()) {
          obsArray.push(this.fctService.updateFctStatus(this.matter, true).catch((error) => Observable.of(error)));
        } else if (this.matter.isInsurerTitlePlus()) {
          titlePlusMatterUser = await this.titlePlusService.getTitlePlusUsersForMatter(this.matter.id).toPromise();
          if (titlePlusMatterUser && titlePlusMatterUser.relevantUser) {
            obsArray.push(
              this.titlePlusService
                .refreshDealStatus(
                  this.matter,
                  titlePlusMatterUser.relevantUser,
                  this.matter.matterTitleInsurance.dealId
                )
                .catch((error) => Observable.of(error))
            );
          }
        }
      }

      this.thirdPartyApiSubscription = forkJoin(obsArray)
        .finally(() => {
          this.isThirdPartyCallInProcess = false;
        })
        .subscribe(
          (results) => {
            if (results) {
              let mortgageCancellations: Map<number, string> = new Map(); //Used for tracking mortgage cancellations which are needed when saving matter.
              let mortgageInstructionsUnAssignments: Map<Mortgage, StewartMortgageInstruction> = new Map();

              results.forEach((result) => {
                if (result instanceof StewartMortgageInstruction) {
                  let stewartAssystMortgageInstruction = result;
                  let matter: Matter = this.getMatterToUpdate(stewartAssystMortgageInstruction.matterId);
                  if (matter) {
                    let empMortgage = matter.empMortgages.find(
                      (item) =>
                        item.stewartAssystMortgageInstruction &&
                        item.stewartAssystMortgageInstruction.dealId == stewartAssystMortgageInstruction.dealId
                    );

                    if (empMortgage) {
                      if (this.isCancelFctMortgage(stewartAssystMortgageInstruction)) {
                        let confirmationMessage: string = this.cancelFctMortgageInstruction(matter, empMortgage);
                        mortgageCancellations.set(stewartAssystMortgageInstruction.id, confirmationMessage);
                        isEmpDataUpdated = true;
                      } else if (empMortgage.mortgagePriority != stewartAssystMortgageInstruction.mortgagePriority) {
                        // this brunch pre-events matter update for StewartAssyst Mortgage Instruction with changed priority.
                        // Instruction could be un-assigned form Mortgage tab using existing functionality by DPPMP-32019 story
                        if (this.sectionName === this.sections[0].title) {
                          mortgageInstructionsUnAssignments.set(empMortgage, stewartAssystMortgageInstruction);
                        }
                      } else {
                        if (!isEmpDataUpdated && stewartAssystMortgageInstruction.isTelusInstruction) {
                          isEmpDataUpdated = !empMortgage.stewartAssystMortgageInstruction.equals(
                            stewartAssystMortgageInstruction
                          );
                        }
                        if (
                          !stewartAssystMortgageInstruction.isFctInstruction &&
                          (stewartAssystMortgageInstruction.isInstructionClosedByLender ||
                            stewartAssystMortgageInstruction.isInstructionCancelled)
                        ) {
                          empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.fileStatuses = [];
                          empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.fileStatuses.push(
                            ...(stewartAssystMortgageInstruction.mortgageInstructionData.fileStatuses || [])
                          );
                          matter.updateAndMapMortgageInstructionToMortgage(
                            empMortgage,
                            empMortgage.stewartAssystMortgageInstruction
                          );
                        } else {
                          empMortgage.stewartAssystMortgageInstruction = stewartAssystMortgageInstruction;
                          matter.updateAndMapMortgageInstructionToMortgage(
                            empMortgage,
                            stewartAssystMortgageInstruction
                          );
                        }

                        if (matter == this.matter) {
                          if (empMortgage.stewartAssystMortgageInstruction.isFctInstruction) {
                            this.fctService.addNotifications(empMortgage, matter);
                            //only show documentAvailable for download if applicable when open the matter
                            MatterErrorUtil.checkEmpErrors(matter, this.errorService, this.appConfig);
                            this.fctService.addDocumentAvailableForDownloadNotification(empMortgage, matter);
                          } else {
                            this.telusService.addNotifications(
                              this.errorService,
                              empMortgage,
                              matter,
                              this.currencyPipe,
                              true
                            );
                          }
                        }
                      }
                    }
                  }

                  // if (!(this.matter && empMortgage && this.matter.isEMPMortgageReconciled(empMortgage))) {
                  //     let mortgageIdx: number = this.matter.mortgages.findIndex(item => item.id == empMortgage.id) + 1;
                  //     this.errorService.addDpMissingFieldError(DPError.createDPError(`mortgage.${mortgageIdx}.lawyerData.borrowerAndGuarantorNotReconciled`, null, null, 'M40002', null, empMortgage.id));
                  // }
                } else if (result instanceof StewartGetResultResponse) {
                  this.errorService.removeDpThirdPartyNotification('notification.stewartTitle.invalid');
                  this.errorService.removeDpThirdPartyNotification('notification.stewartTitle.status');
                  let matter: Matter = this.getMatterToUpdate(result.matterTitleInsurance.matterId);
                  if (result.getResultResponse && result.getResultResponse.isValid && result.matterTitleInsurance) {
                    if (matter) {
                      matter.matterTitleInsurance = result.matterTitleInsurance;
                      if (matter == this.matter) {
                        let message = result.matterTitleInsurance.invoiceOrderStatus
                          ? "<span class='boldfont line-height-1'>" +
                            result.matterTitleInsurance.invoiceOrderStatus +
                            ':</span>'
                          : '';
                        message =
                          message +
                          ' ' +
                          (result.getResultResponse.messages && result.getResultResponse.messages.length > 0
                            ? result.getResultResponse.messages[0].message
                            : '');
                        message = message + ' (' + moment(Date.now()).format('MMM DD, YYYY hh:mm A') + ')';
                        this.errorService.addDpThirdPartyNotification(
                          DPError.createCustomDPError(
                            'notification.stewartTitle.status',
                            message,
                            'Stewart Title',
                            'NOTIFICATION'
                          )
                        );

                        if (matter.matterTitleInsurance.hasNewStgDocuments()) {
                          this.errorService.addDpThirdPartyNotification(
                            DPError.createCustomDPError(
                              'notification.stewartTitle.documentsAvailable',
                              'Document(s) available for download',
                              'Stewart Title',
                              'NOTIFICATION',
                              null,
                              null,
                              'M220001'
                            )
                          );
                        }
                      }
                    }
                  } else if (
                    result.getResultResponse &&
                    !result.getResultResponse.isValid &&
                    result.matterTitleInsurance &&
                    matter == this.matter
                  ) {
                    this.errorService.addDpThirdPartyNotification(
                      DPError.createCustomDPError(
                        'notification.stewartTitle.invalid',
                        'The title insurance order status could not be retrieved. Please visit the Stewart Title page from the Title Insurance tab.',
                        'Stewart Title',
                        'NOTIFICATION'
                      )
                    );
                  }
                } else if (result instanceof TitlePlusDealOrderStatusResponseDto) {
                  this.errorService.removeDpThirdPartyNotification('notification.titlePlus.invalid');
                  this.errorService.removeDpThirdPartyNotification('notification.titlePlus.status');
                  let matter: Matter = this.getMatterToUpdate(result.matterTitleInsurance.matterId);
                  if (result.matterTitleInsurance) {
                    if (matter) {
                      matter.matterTitleInsurance = new MatterTitleInsurance(result.matterTitleInsurance);
                      if (matter == this.matter) {
                        let message = result.status
                          ? "<span class='boldfont line-height-1'>" + result.status + ':</span>'
                          : '';
                        message = message + ' ' + (result.statusMessage ? result.statusMessage : '');
                        message = message + ' (' + moment(Date.now()).format('MMM DD, YYYY hh:mm A') + ')';
                        this.errorService.addDpThirdPartyNotification(
                          DPError.createCustomDPError(
                            'notification.titlePlus.status',
                            message,
                            'TitlePLUS',
                            'NOTIFICATION'
                          )
                        );

                        if (matter.matterTitleInsurance.hasNewTitlePlusDocuments()) {
                          this.errorService.addDpThirdPartyNotification(
                            DPError.createCustomDPError(
                              'notification.titlePlus.documentsAvailable',
                              'Document(s) available for download',
                              'TitlePLUS',
                              'NOTIFICATION',
                              null,
                              null,
                              'M220001'
                            )
                          );
                        }
                      }
                    }
                  } else if (
                    result.titlePlusDealOrderStatusResponse &&
                    result.titlePlusDealOrderStatusResponse.validationResult &&
                    !result.titlePlusDealOrderStatusResponse.validationResult.IsValid &&
                    result.matterTitleInsurance &&
                    matter == this.matter
                  ) {
                    this.errorService.addDpThirdPartyNotification(
                      DPError.createCustomDPError(
                        'notification.titlePlus.invalid',
                        'The title insurance order status could not be retrieved. Please visit the TitlePLUS page from the Title Insurance tab.',
                        'TitlePLUS',
                        'NOTIFICATION'
                      )
                    );
                  }
                } else if (result instanceof ChicagoGetAllStatusDetailsResponse) {
                  this.errorService.removeDpThirdPartyNotification('notification.chicagoTitle.invalid');
                  this.errorService.removeDpThirdPartyNotification('notification.chicagoTitle.status');
                  let matter: Matter = this.getMatterToUpdate(result.matterTitleInsurance.matterId);
                  if (result && result.isSuccessful && result.matterTitleInsurance) {
                    if (matter) {
                      matter.matterTitleInsurance = result.matterTitleInsurance;
                      if (matter == this.matter) {
                        let message = result.matterTitleInsurance.invoiceOrderStatus
                          ? "<span class='boldfont line-height-1'>" +
                            result.matterTitleInsurance.invoiceOrderStatus +
                            ':</span>'
                          : '';
                        message = message + ' (' + moment(Date.now()).format('MMM DD, YYYY hh:mm A') + ')';
                        this.errorService.addDpThirdPartyNotification(
                          DPError.createCustomDPError(
                            'notification.chicagoTitle.status',
                            message,
                            'Chicago Title',
                            'NOTIFICATION'
                          )
                        );
                        this.chicagoTitleService.checkDownloadsAvailable(
                          this.errorService,
                          result.matterTitleInsurance
                        );
                      }
                    }
                  } else if (matter == this.matter) {
                    this.errorService.addDpThirdPartyNotification(
                      DPError.createCustomDPError(
                        'notification.chicagoTitle.invalid',
                        'The title insurance order status could not be retrieved. Please visit the Chicago Title page from the Title Insurance tab.',
                        'Chicago Title',
                        'NOTIFICATION'
                      )
                    );
                  }
                } else if (result instanceof MatterTitleInsurance) {
                  //FCT
                  if (result.matterId == this.matter.id) {
                    this.fctService.updateThirdPartyFctStatusNotification(result);
                    this.fctService.checkDownloadsAvailable(result);
                  }
                } else if (result instanceof ApplicationError) {
                  //need to handle errors like this instead of at the forkJoin to ensure all other calls without errors complete.
                  if (result && result.errorCode == '7') {
                    this.dialogService.confirm(
                      'Information',
                      TelusEmpMessages.LCEMP4,
                      true
                    );
                  } else if (result && result.errorCode === 'app.titlePlus.RefreshTokenIsInvalidOrExpired') {
                    let userId =
                      titlePlusMatterUser && titlePlusMatterUser.relevantUser
                        ? titlePlusMatterUser.relevantUser.id
                        : '';
                    this.errorService.addDpThirdPartyNotification(
                      DPError.createCustomDPError(
                        'integrations.titlePlus.validCredentials.' + String(userId),
                        result.message,
                        'Title Insurance',
                        'ERROR'
                      )
                    );
                  } else {
                    this.dialogService.confirm('Information', result.message, true);
                  }
                }
              });

              // Update Insurance Row in Trust Ledger after third party refresh
              if (this.matter.isMainTitleInsurer() && this.matter.soaTrustLedgerCollection) {
                this.matter.soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
                if (this.matter.secondarySoaSheetsCollection) {
                  this.matter.secondarySoaSheetsCollection.forEach((collection) => {
                    collection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
                  });
                }
              }

              this.saveMatterAfterEmpChangesConfirmUnAssignments(
                isEmpDataUpdated,
                mortgageCancellations,
                mortgageInstructionsUnAssignments
              );
            }
          },
          () => {
            console.log('Third Party Data Refresh Error.');
          }
        );
    }
  }

  async saveMatterAfterEmpChangesConfirmUnAssignments(
    isEmpDataUpdated: boolean,
    mortgageCancellations: Map<number, string>,
    mortgageInstructionsUnAssignments: Map<Mortgage, StewartMortgageInstruction>
  ): Promise<void> {
    if (isEmpDataUpdated) {
      await this.saveMatterAfterEmpChanges(mortgageCancellations);
    }
    if (mortgageInstructionsUnAssignments.size > 0) {
      await this.confirmUnAssignMortgageInstructions(mortgageInstructionsUnAssignments);
    }
  }

  async confirmUnAssignMortgageInstructions(
    mortgageInstructionsUnAssignments: Map<Mortgage, StewartMortgageInstruction>
  ): Promise<void> {
    if (mortgageInstructionsUnAssignments.size > 0) {
      let empMortgages: Mortgage[] = Array.from(mortgageInstructionsUnAssignments.keys());
      let stewartMortgageInstructions: StewartMortgageInstruction[] = Array.from(
        mortgageInstructionsUnAssignments.values()
      );
      for (let i: number = 0; i < empMortgages.length; i++) {
        await this.confirmUnAssignMortgageInstruction(empMortgages[i], stewartMortgageInstructions[i]);
      }
    }
  }

  async confirmUnAssignMortgageInstruction(
    empMortgage: Mortgage,
    stewartAssystMortgageInstruction: StewartMortgageInstruction
  ): Promise<void> {
    let res = await this.dialogService
      .confirm(
        'Information',
        'The priority of the ' +
          empMortgage.stewartAssystMortgageInstruction.lender +
          ' (' +
          empMortgage.stewartAssystMortgageInstruction.dealId +
          ') mortgage instructions has changed from ' +
          SharedUtils.getOrdinal(empMortgage.mortgagePriority) +
          '  to ' +
          SharedUtils.getOrdinal(stewartAssystMortgageInstruction.mortgagePriority) +
          '. Please unassign the mortgage instructions and reassign to the matter.',
        false,
        'Unassign'
      )
      .toPromise();
    if (res) {
      let unassignedMortgageInstructionId = empMortgage.stewartAssystMortgageInstruction.id;
      this.matterService.unAssignMortgage(this.matter, empMortgage.id);
      this.matter.dirty = true;
      await this.validateAndSaveMatter(undefined, unassignedMortgageInstructionId, undefined).toPromise();
    }
  }

  isCancelFctMortgage(stewartAssystMortgageInstruction: StewartMortgageInstruction): boolean {
    return (
      stewartAssystMortgageInstruction &&
      stewartAssystMortgageInstruction.isFctInstruction &&
      stewartAssystMortgageInstruction.mortgageInstructionData &&
      stewartAssystMortgageInstruction.mortgageInstructionData.fctStatusData &&
      stewartAssystMortgageInstruction.mortgageInstructionData.fctStatusData.isCancelled()
    );
  }

  cancelFctMortgageInstruction(matter: Matter, empMortgage: Mortgage): string {
    let confirmationMessage: string = 'Mortgage instructions were cancelled.';

    if (empMortgage.stewartAssystMortgageInstruction.id) {
      confirmationMessage = FctEmpMessages.EMP053.replace(
        '{DEAL_ID}',
        empMortgage.stewartAssystMortgageInstruction.getDealIdForDisplay()
      )
        .replace('{LENDER_NAME}', empMortgage.stewartAssystMortgageInstruction.lender)
        .replace('{MORTGAGE_PRIORITY}', SharedUtils.getOrdinal(empMortgage.mortgagePriority));

      matter.replaceEmpMortgageWithBlankMortgage(empMortgage, this.mortgageSoAdjService);
    }

    return confirmationMessage;
  }

  async saveMatterAfterEmpChanges(mortgageCancellations: Map<number, string>): Promise<void> {
    let cancelledMortgageInstructionIds: number[] =
      mortgageCancellations.size > 0 ? Array.from(mortgageCancellations.keys()) : null;
    let result: boolean = await this.validateAndSaveMatter(
      undefined,
      null,
      cancelledMortgageInstructionIds
    ).toPromise();
    if (result) {
      for (let confirmationMessage of Array.from(mortgageCancellations.values())) {
        this.dialogService.confirm('Confirmation', confirmationMessage, true);
      }
    } else {
      console.log('Unable to cancel mortgage instruction(s).');
    }
  }

  get disableMatterForThirdPartyDataRefresh(): boolean {
    return (
      this.matter &&
      this.matter.isThirdPartyDataAvailable &&
      this.isThirdPartyCallInProcess &&
      !this.matter.locked &&
      !this.isMatterReadOnly
    );
  }

  cancelThirdPartyRefresh(): void {
    if (this.thirdPartyApiSubscription) {
      this.thirdPartyApiSubscription.unsubscribe();
    }
  }

  isNotesAvailable(section: Section): boolean {
    return section && section.sectionKey && currentMatter.value.matterTopics
      ? currentMatter.value.matterTopics.some(
          (item) =>
            item.matterTopicKey == section.sectionKey &&
            item.topicNote != undefined &&
            item.topicNote != '' &&
            item.topicNote != null
        )
      : false;
  }

  isStatusAvailable(section: Section): boolean {
    return section && section.sectionKey && currentMatter.value.matterTopics
      ? currentMatter.value.matterTopics.some(
          (item) => item.matterTopicKey == section.sectionKey && item.topicStatus && item.topicStatus != 'QUESTION'
        )
      : false;
  }

  getNotes(): string {
    if (this.activeSection && this.activeSection.sectionKey && currentMatter.value.matterTopics) {
      let matterTopicInfo = currentMatter.value.matterTopics.find(
        (item) =>
          item.matterTopicKey == this.activeSection.sectionKey &&
          item.topicNote != undefined &&
          item.topicNote != '' &&
          item.topicNote != null
      );
      return matterTopicInfo
        ? matterTopicInfo.topicNote && matterTopicInfo.topicNote.length > 800
          ? matterTopicInfo.topicNote.substring(0, 800) + '...'
          : matterTopicInfo.topicNote
        : '';
    } else {
      return '';
    }
  }

  getActiveTopicStatus(): string {
    if (this.activeSection && this.activeSection.sectionKey && currentMatter.value.matterTopics) {
      let matterTopicInfo = currentMatter.value.matterTopics.find(
        (item) =>
          item.matterTopicKey == this.activeSection.sectionKey &&
          item.topicStatus != undefined &&
          item.topicStatus != '' &&
          item.topicStatus != null
      );
      let currentStatus = matterTopicInfo ? matterTopicInfo.topicStatus : 'QUESTION';
      let matterTopicStatus = this.matterTopicStatuses.find((item) => item.value == currentStatus);
      return matterTopicStatus ? matterTopicStatus.label : '';
    }
  }

  getMatterClosingStatus(): string {
    if (currentMatter && currentMatter.value) {
      let matterTopicInfo = currentMatter.value.matterTopics.find(
        (item) =>
          item.matterTopicKey == this.activeSection.sectionKey &&
          item.topicStatus != undefined &&
          item.topicStatus != '' &&
          item.topicStatus != null
      );
      let currentStatus = currentMatter.value.matterClosingStatus
        ? currentMatter.value.matterClosingStatus
        : 'QUESTION';
      let matterTopicStatus = this.matterTopicClosingStatuses.find((item) => item.value == currentStatus);
      return matterTopicStatus ? matterTopicStatus.label.trim() : '';
    }
  }

  updateMatterClosingStatus(matterTopicValue: string): void {
    if (currentMatter && currentMatter.value) {
      currentMatter.value.matterClosingStatus = matterTopicValue;
      currentMatter.value.dirty = true;
    }
  }

  getActiveTopicStatusIcon(section: Section): string {
    let activeMatteTopic: MatterTopic =
      section && section.sectionKey && currentMatter.value.matterTopics
        ? currentMatter.value.matterTopics.find((item) => item.matterTopicKey == section.sectionKey)
        : undefined;
    let status = activeMatteTopic ? activeMatteTopic.topicStatus : '';
    if (status == 'COMPLETE') {
      return 'fa fa-check primary padding-right-5';
    } else if (status == 'INCOMPLETE') {
      return 'fa fa-star sunrise padding-right-5';
    } else if (status == 'ATTENTION') {
      return 'fa fa-exclamation red padding-left-3 padding-right-5';
    } else {
      return '';
    }
  }

  getActiveTopicClosingStatusIcon(section: Section): string {
    let status = this.matter ? this.matter.matterClosingStatus : '';
    if (status == MatterClosingStatus.PENDING) {
      return 'fa fa fa-clock-o padding-right-5 ';
    } else if (status == MatterClosingStatus.ESCROW) {
      return 'fa fa-handshake-o padding-right-5';
    } else if (status == MatterClosingStatus.IN_FUNDS) {
      //return 'fa fa-badge-dollar padding-left-3 padding-right-5';
      return 'fa fa-dollar padding-right-5';
    } else if (status == MatterClosingStatus.POST_CLOSING) {
      return 'fa fa-calendar-minus padding-right-5';
    } else if (status == MatterClosingStatus.OCCUPANCY) {
      return 'fa fa-building-o padding-right-5';
    } else {
      return '';
    }
  }

  getstatusIconClass(status: string): string {
    if (status == 'COMPLETE') {
      return 'fa fa-check primary padding-left-10';
    } else if (status == 'INCOMPLETE') {
      return 'fa fa-star sunrise padding-left-10';
    } else if (status == 'ATTENTION') {
      return 'fa fa-exclamation red padding-right-3 padding-left-13';
    } else {
      return 'padding-left-5';
    }
  }

  getClosingStatusIconClass(status: string): string {
    if (status == MatterClosingStatus.PENDING) {
      return 'fa fa-clock-o padding-left-10 closing-status-icon-block';
    } else if (status == MatterClosingStatus.ESCROW) {
      return 'fa fa-handshake-o padding-left-10 closing-status-icon-block';
    } else if (status == MatterClosingStatus.IN_FUNDS) {
      return 'fa fa-dollar padding-left-10 closing-status-icon-block';
    } else if (status == MatterClosingStatus.POST_CLOSING) {
      return 'fa fa-calendar-minus padding-left-10 closing-status-icon-block';
    } else if (status == MatterClosingStatus.OCCUPANCY) {
      return 'fa fa-building-o padding-left-10 closing-status-icon-block';
    } else {
      return 'padding-left-10';
    }
  }

  addToBurgerMenu(
    burgerMenuItems: BurgerMenuExtendedItem[],
    text: string,
    isDisabled: boolean,
    action?: any
  ): BurgerMenuExtendedItem {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.isDisabled = isDisabled;

    if (action) {
      burgerMenuItem.action = action;
    }
    burgerMenuItems.push(burgerMenuItem);
    return burgerMenuItem;
  }

  clickProjectDropDown(clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(clickedMenuOption);
    }
  }

  createProjectDropdownMenu(): void {
    this.projectDropDownItems = [];
    this.addToBurgerMenu(this.projectDropDownItems, 'Open Project Record', false, this.openMatterProject);
    if (this.authZService.hasFullAccessToProjectMatters()) {
      this.addToBurgerMenu(this.projectDropDownItems, 'Mass Open', false, this.projectMassOpen);
    }
  }

  // Open an existing project.
  openMatterProject = () => {
    // The reason we create a project Object is that openProject() method
    // takes a Project as a parameter but only uses it's id.
    let project = new Project();
    project.id = this.matter.unityProjectId;
    if (project.id) {
      let tab: Tab = this.tabsService.getTab(project.id, 'project');
      if (tab) {
        this.tabsComponent.openSelectedTab(tab);
      } else {
        this.tabsComponent.openProject(project, this.projectAccessDeniedHandler);
      }
    }
  };

  projectMassOpen = () => {
    if (this.matter.dirty) {
      this.dialogService
        .confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save')
        .subscribe((res) => {
          if (res && res == true) {
            let matterTab = this.tabsService.activeTab as MatterTab;
            if (matterTab && matterTab.matterComponent) {
              let subscription: Subscription = matterTab.matterComponent
                .validateAndSaveMatter()
                .subscribe((result: boolean) => {
                  if (result) {
                    this.openMassOpenDialog();
                    subscription.unsubscribe();
                  }
                });
            }
          } else {
            console.log('cancel');
          }
        });
    } else {
      this.openMassOpenDialog();
    }
  };

  openMassOpenDialog(): void {
    this.dialogService.matDialogContent({
      content: MassOpenProjectModalComponent,
      context: {
        sourceMatter: this.matter,
        tabsComponent: this.tabsComponent,
        tabsService: this.tabsService
      },
      widthXl: true,
      onFulfillment: (result) => {
        if (result) {
        }
      }
    });
  }

  projectAccessDeniedHandler = () => {
    this.dialogService
      .confirm('Error', 'Your access to this project has been removed by your administrator.', true)
      .subscribe();
  };

  private unLockMassUpdateTemplateMatter(): void {
    let currentMatter = (this.tabsService.activeTab as MassUpdateTab).matter;
    if (currentMatter && currentMatter.id) {
      this.matterService.unlockMatter(currentMatter.id);
    }
    (this.tabsService.activeTab as MassUpdateTab).massUpdateStage = 'COMPLETED_MASS_UPDATE';
  }

  isMassUpdateTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isMassUpdateSubType();
  }

  isMassUpdateTabMatterLevel(): boolean {
    return (
      !this.isMassUpdateTab() ||
      (this.isMassUpdateTab() &&
        this.tabsService.activeTab.isMatter() &&
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabMatterLevel())
    );
  }

  isMassUpdateTabSelectionLevel(): boolean {
    return (
      this.isMassUpdateTab() &&
      this.tabsService.activeTab.isMatter() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabSelectionLevel()
    );
  }

  isMassUpdateTabPrecedentLevel(): boolean {
    return (
      this.isMassUpdateTab() &&
      this.tabsService.activeTab.isMatter() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabPrecedentLevel()
    );
  }

  isMassUpdateTabUpdateLevel(): boolean {
    return (
      this.isMassUpdateTab() &&
      this.tabsService.activeTab.isMatter() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabUpdateLevel()
    );
  }

  isMassUpdateTabCompletedLevel(): boolean {
    return (
      this.isMassUpdateTab() &&
      this.tabsService.activeTab.isMatter() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabCompletedLevel()
    );
  }

  isMassUpdateTabTypeImportMatterAdjustment(): boolean {
    return (
      this.isMassUpdateTab() &&
      this.tabsService.activeTab.isMatter() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeImportMatterAdjustment()
    );
  }

  isMassUpdateRestartVisible(): boolean {
    return (
      this.isMassUpdateTabCompletedLevel() &&
      !this.isMassUpdateTabTypeImportMatterAdjustment() &&
      (this.tabsService.activeTab as MassUpdateTab).massUpdateTransaction.getRemainingMattersCountIncludingFailed() > 0
    );
  }

  isMassUpdateSupportBackToTemplateMatter(): boolean {
    return (
      this.isMassUpdateTab() &&
      this.tabsService.activeTab.isMatter() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateSupportBackToTemplateMatter()
    );
  }

  isMassUpdateTabTypeSoaGlCodes(): boolean {
    return (
      this.isMassUpdateTabPrecedentLevel() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeSoaGlCodes()
    );
  }

  isMassUpdateTabTypeStatementOfAccount(): boolean {
    return (
      this.isMassUpdateTabPrecedentLevel() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeStatementOfAccount()
    );
  }

  isMassUpdateTabTypeTrustLedger(): boolean {
    return (
      this.isMassUpdateTabPrecedentLevel() &&
      (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeTrustLedger()
    );
  }

  getMassUpdateMode(): string {
    if (this.isMassUpdateTabTypeStatementOfAccount() || this.isMassUpdateTabTypeTrustLedger()) {
      if (
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeInterimStatementOfAccount() ||
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeInterimTrustLedger()
      ) {
        return 'Interim';
      } else if (
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeFinalStatementOfAccount() ||
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeFinalTrustLedger()
      ) {
        return 'Final';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  get selectedProjectBasedMattersCnt(): number {
    return this.isMassUpdateTab() &&
      (this.tabsService.activeTab as MassUpdateTab).matterListState &&
      (this.tabsService.activeTab as MassUpdateTab).matterListState.selectedMatters
      ? (this.tabsService.activeTab as MassUpdateTab).matterListState.selectedMatters.length
      : 0;
  }

  get selectedMassUpdateMattersCntText(): string {
    if (this.isMassUpdateTab()) {
      let massUpdateActiveTab = this.tabsService.activeTab as MassUpdateTab;
      if (
        massUpdateActiveTab.matterListState &&
        MatterListUtil.isAllMattersSelected(massUpdateActiveTab.matterListState, this.tabsService)
      ) {
        return ' (all matters) ';
      }
    }
    return this.selectedProjectBasedMattersCnt > 0 ? `(${this.selectedProjectBasedMattersCnt} matters)` : '';
  }

  get runMassUpdateBtnLabel(): string {
    return `Run Mass Update ${this.selectedMassUpdateMattersCntText}`;
  }

  get proceedMassUpdateBtnLabel(): string {
    return `Proceed with Mass Update ${this.selectedMassUpdateMattersCntText}`;
  }

  confirmMassUpdate(): void {
    if (this.isMassUpdateTab()) {
      let massUpdateActiveTab = this.tabsService.activeTab as MassUpdateTab;
      if (
        massUpdateActiveTab &&
        massUpdateActiveTab.matterListState &&
        massUpdateActiveTab.matterListState.selectedMatters &&
        massUpdateActiveTab.matterListState.selectedMatters.length > 0
      ) {
        massUpdateActiveTab.matterListState.sortSelectedMatterListAsRowsDisplay();
        massUpdateActiveTab.massUpdateStage = 'CONFIRM_MASS_UPDATE';
        this.massUpdateMessages = [];

        if (massUpdateActiveTab.massUpdateType) {
          switch (massUpdateActiveTab.massUpdateType) {
            case MassUpdateTypes.TOPIC_A_K_M_N:
              //this.massUpdateMessages.push('Topics A - K, M and N');
              this.massUpdateTopics.forEach((topic) => this.massUpdateMessages.push(topic));
              break;
            case MassUpdateTypes.NUMBER_OF_EXISTING_MORTGAGES:
              this.massUpdateMessages.push('Existing Mortgages');
              break;
            case MassUpdateTypes.NUMBER_OF_VTB_MORTGAGES:
              this.massUpdateMessages.push('VTB Mortgages');
              break;
            case MassUpdateTypes.FINAL_ADJUSTMENT_DATE:
              let msg: string = 'Statement of Adjustment Date';
              if (
                massUpdateActiveTab.massUpdateData &&
                massUpdateActiveTab.massUpdateData.data &&
                massUpdateActiveTab.massUpdateData.data instanceof MassUpdateAdjustmentDateData
              ) {
                msg = (<MassUpdateAdjustmentDateData>massUpdateActiveTab.massUpdateData.data)
                  .projectContainsInterimAndFinalAdjustment
                  ? 'Final Adjustment Date'
                  : 'Statement of Adjustment Date';
              }
              this.massUpdateMessages.push(msg);
              break;
            case MassUpdateTypes.INTERIM_ADJUSTMENT_DATE:
              this.massUpdateMessages.push('Interim Adjustment Date');
              break;
            case MassUpdateTypes.UPDATE_FORM4_BANK_ACCOUNT_INFO:
              this.massUpdateMessages.push('Form 4 Bank Account Info');
              break;
            case MassUpdateTypes.ADD_NEW_SUPPLEMENTAL_TASKS:
              this.massUpdateMessages.push('Extended Workflows');
              break;
            case MassUpdateTypes.SOA_GL_CODES:
            case MassUpdateTypes.SOA_GL_CODES_INTERIM:
            case MassUpdateTypes.SOA_GL_CODES_FINAL:
              this.massUpdateMessages.push('Statement Of Account GL Codes');
              break;
            case MassUpdateTypes.STATEMENT_OF_ACCOUNT:
              this.massUpdateMessages.push('Statement Of Account');
              break;
            case MassUpdateTypes.STATEMENT_OF_ACCOUNT_INTERIM:
              this.massUpdateMessages.push('Interim Statement Of Account');
              break;
            case MassUpdateTypes.STATEMENT_OF_ACCOUNT_FINAL:
              this.massUpdateMessages.push('Final Statement Of Account');
              break;
            case MassUpdateTypes.TRUST_LEDGER:
              this.massUpdateMessages.push('Trust Ledger');
              break;
            case MassUpdateTypes.TRUST_LEDGER_INTERIM:
              this.massUpdateMessages.push('Interim Trust Ledger');
              break;
            case MassUpdateTypes.TRUST_LEDGER_FINAL:
              this.massUpdateMessages.push('Final Trust Ledger');
              break;
            case MassUpdateTypes.UNIT_TYPES:
              this.massUpdateMessages.push('Unit Type (e.g. dwelling, parking, locker)');
              break;
            case MassUpdateTypes.GST_HST_APPLICATION:
              this.massUpdateMessages.push('Produce HST/GST Forms');
              break;
            case MassUpdateTypes.TAX_RATE_OF_SOA_SOAJ:
              this.massUpdateMessages.push('Mass Update Rate of GST/HST');
              break;
          }
        }
      }
    }
  }

  async performMassUpdate(): Promise<void> {
    if (this.isMassUpdateTab()) {
      let massUpdateActiveTab = this.tabsService.activeTab as MassUpdateTab;
      if (
        massUpdateActiveTab.matterListState &&
        massUpdateActiveTab.matterListState.selectedMatters &&
        massUpdateActiveTab.matterListState.selectedMatters.length > 0
      ) {
        let matterIds = [];
        if (MatterListUtil.isAllMattersSelected(massUpdateActiveTab.matterListState, this.tabsService)) {
          let result = await this.matterService.getMatterIds(massUpdateActiveTab.matterListState);
          if (result && result.length > 0) {
            matterIds = this.filterLockedMatterIds(result, massUpdateActiveTab.matterListState);
          }
        } else {
          matterIds = massUpdateActiveTab.matterListState.selectedMatters.map((item) => {
            return item.id;
          });
        }
        //massUpdateActiveTab.massUpdateStage = 'RUN_MASS_UPDATE';
        let projectMatterUpdateTransaction: ProjectMatterUpdateTransaction =
          this.projectMatterUpdateService.createNewProjectMatterUpdateTransaction(matterIds.length, 'MASS_UPDATE');
        projectMatterUpdateTransaction.projectMassUpdateData = new ProjectMassUpdateData();
        projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData = massUpdateActiveTab.massUpdateData;
        projectMatterUpdateTransaction.projectMassUpdateData.templateMatter = massUpdateActiveTab.matter;
        projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType = massUpdateActiveTab.massUpdateType;
        projectMatterUpdateTransaction.showProjectMatterUpdateStop = false;
        projectMatterUpdateTransaction.updateOnlyMatters = true;
        (this.tabsService.activeTab as MassUpdateTab).massUpdateTransaction = projectMatterUpdateTransaction;
        // lock project
        await this.projectService.getProject(massUpdateActiveTab.matter.unityProjectId, true).toPromise();
        projectMatterUpdateTransaction.massUpdateTransactionId = await this.projectService
          .beginProjectUpdateTransaction(massUpdateActiveTab.matter.unityProjectId, 'ONLY_MATTERS', matterIds.length)
          .toPromise();

        this.projectMatterUpdateService.createMassUpdatePolling(projectMatterUpdateTransaction);
        this.projectMatterUpdateService
          .performProjectMatterUpdates(matterIds, projectMatterUpdateTransaction, this.massUpdateMessages)
          .then(() => {
            this.unLockMassUpdateTemplateMatter();
          });
      }
    }
  }

  filterLockedMatterIds(matterIds: number[], matterListState: MatterListState): number[] {
    let lockedIds = [];
    matterListState.rows.forEach((item) => {
      let obj = item as Matter;
      if (
        obj.locked ||
        MatterListUtil.isMatterTabOpen(obj.id, this.tabsService) ||
        MatterListUtil.isLinkedMatterLocked(obj.id, this.tabsService)
      ) {
        lockedIds.push(obj.id);
      }
    });

    return matterIds.filter((matterId) => {
      return lockedIds.indexOf(matterId) < 0;
    });
  }

  restartMassUpdate(): void {
    if (this.isMassUpdateTab()) {
      let massUpdateActiveTab = this.tabsService.activeTab as MassUpdateTab;
      if (
        massUpdateActiveTab.matterListState &&
        massUpdateActiveTab.massUpdateTransaction &&
        massUpdateActiveTab.massUpdateTransaction.getRemainingMattersCountIncludingFailed() > 0
      ) {
        let massUpdateTransaction: ProjectMatterUpdateTransaction = massUpdateActiveTab.massUpdateTransaction;
        //Resetting massUpdateTransaction
        massUpdateTransaction.status = '';
        massUpdateTransaction.failedMatterCount = 0;
        this.massUpdateMessages.push('.....Restarting mass update ' + new Date().toString());
        this.projectMatterUpdateService
          .executeProjectMatterUpdate(massUpdateTransaction, this.massUpdateMessages)
          .then(() => {
            this.unLockMassUpdateTemplateMatter();
          });
      }
    }
  }

  backMassUpdate(): void {
    let massUpdateStage: MassUpdateStageType;
    let massUpdateActiveTab = this.tabsService.activeTab as MassUpdateTab;
    massUpdateActiveTab.clearMassUpdateTransaction();
    if (
      this.isMassUpdateTabSelectionLevel() &&
      ((this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeSoaGlCodes() ||
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeStatementOfAccount() ||
        (this.tabsService.activeTab as MassUpdateTab).isMassUpdateTabTypeTrustLedger())
    ) {
      let currentMatter = (this.tabsService.activeTab as MassUpdateTab).matter;
      if (currentMatter && currentMatter.id) {
        this.matterService.unlockMatter(currentMatter.id);
      }
      massUpdateActiveTab.massUpdateStage = 'PRECEDENT_LEVEL';
      let redirectRoute: string = this.getRoute(-1) + 'massUpdateMatterList';
      this.router.navigate([redirectRoute]);
    } else {
      if (this.isMassUpdateTabSelectionLevel()) {
        massUpdateStage = 'MATTER_LEVEL';
        this.initializeMassUpdate();
      } else if (this.isMassUpdateTabUpdateLevel()) {
        massUpdateStage = 'SELECTION_LEVEL';
      }
      if (massUpdateActiveTab && massUpdateStage) {
        massUpdateActiveTab.massUpdateStage = massUpdateStage;
      }
      if (this.isMassUpdateTabMatterLevel()) {
        massUpdateActiveTab.active = 'inActive';
        this.tabsService.openTab(massUpdateActiveTab);
      }
    }
  }

  exitMassUpdate(): void {
    this.errorService.removeAllDpFieldError();
    let currentMatter = (this.tabsService.activeTab as MassUpdateTab).matter;

    if (currentMatter && currentMatter.id) {
      this.matterService.unlockMatter(currentMatter.id);
    }
    if (this.isMassUpdateTabCompletedLevel()) {
      this.tabsService.removeMassUpdateTab(this.tabsService.activeTab as MassUpdateTab, this.matterService);
    } else {
      this.tabsService.closeMassUpdateTab(this.tabsService.activeTab as MassUpdateTab, this.matterService);
    }
  }

  async isMatterProjectNotLocked(): Promise<boolean> {
    let matterTab = this.tabsService.activeTab as MatterTab;
    if (matterTab && matterTab.matter && matterTab.matter.unityProjectId) {
      let project = await this.projectService.getProject(matterTab.matter.unityProjectId, false).toPromise();
      return Promise.resolve(!project.locked);
    } else {
      return Promise.resolve(true);
    }
  }

  showProjectLockedErrorMessage(): void {
    this.dialogService
      .confirm('Error', 'The matter cannot be saved as parent Project is being edited', true)
      .subscribe();
  }

  isMatterProjectLocked(): boolean {
    return this.tabsService.isMatterProjectLocked(this.matter);
  }

  isMatterInaccessible(): boolean {
    return this.matter && this.matter.inaccessible;
  }

  inaccessibleMessage(): string {
    if (this.matter.inaccessibleReason == 'MATTER_TOO_OLD') {
      return (
        'This matter has been set to read-only. Further editing of matter detail information has been disabled. ' +
        'You can create a new matter with the existing matter details using the Clone or Copy functions on the Matters page. '
      );
    } else {
      return 'All fields are read-only because a Project mass update has failed or is currently in progress, please try again once the update is complete.';
    }
  }

  async getSoajFieldCodes(): Promise<void> {
    if (this.matter && this.matter.isProjectSale) {
      let projectFieldCodes = await this.projectService.getProjectSoaFieldCodes(this.matter.unityProjectId).toPromise();
      this.activeMatterTab.projectSoAdjFieldCodes = this.soajFieldCodeService.mapFieldCodesResponse(projectFieldCodes);
      // for mass update template generating field codes needs to follow the same rules as the ones at the project level therefore loading here all field code claims for all Project Sale Matters that belong to current project
      if (this.activeMatterTab.isMassUpdateSubType()) {
        let matterFieldCodes = await this.projectService
          .getProjectMattersSoaFieldCodes(this.matter.unityProjectId)
          .toPromise();
        this.activeMatterTab.matterSoAdjFieldCodes = this.soajFieldCodeService.mapFieldCodesResponse(matterFieldCodes);
      } else if (this.matter.id) {
        let matterFieldCodes = await this.projectService
          .getMatterSoaFieldCodes(this.matter.unityProjectId, this.matter.id)
          .toPromise();
        this.activeMatterTab.matterSoAdjFieldCodes = this.soajFieldCodeService.mapFieldCodesResponse(matterFieldCodes);
      }
    }
  }

  get projectMatters(): Matter[] {
    return this.navigateToMatterService.projectMatters;
  }

  isPreviousMatterBtnVisible(): boolean {
    return this.navigateToMatterService.isPreviousMatterBtnVisible(this.matter);
  }

  isNextMatterBtnVisible(): boolean {
    return this.navigateToMatterService.isNextMatterBtnVisible(this.matter);
  }

  getFocusedElement(event: FocusEvent) {
    console.log(event, event.relatedTarget);
    if (event.type == 'focus' && event.relatedTarget && (<any>event.relatedTarget).nodeName != 'BUTTON') {
      this.activeMatterTab.focusedElement = event.relatedTarget;
    }
  }

  async goToPreviousMatter() {
    let targetMatterId: number = await this.navigateToMatterService.goToPreviousMatter(
      this.matter,
      this.tabsComponent.isTabReadOnly()
    );
    if (targetMatterId) {
      this.navigateToMatter();
    }
  }

  async goToNextMatter(): Promise<void> {
    let targetMatterId: number = await this.navigateToMatterService.goToNextMatter(
      this.matter,
      this.tabsComponent.isTabReadOnly()
    );
    if (targetMatterId) {
      this.navigateToMatter();
    }
  }

  navigateToMatter() {
    const url = this.router.createUrlTree(this.activeMatterTab.routeParams).toString();
    this.location.go(url);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent = (event: KeyboardEvent) => {
    if (event.shiftKey && event.keyCode == CustomKeyCodesEnum.PageUp) {
      this.goToPreviousMatter();
    }
    if (event.shiftKey && event.keyCode == CustomKeyCodesEnum.PageDown) {
      this.goToNextMatter();
    }
  };

  //Open Cirf dialog should be in matter.component
  openCIRFImportModalIfApplicable() {
    this.route.queryParams.subscribe((params: Params) => {
      let currentComponent = this;
      setTimeout(() => {
        if (params['cirfUUID']) {
          let cirfUUID = params['cirfUUID'];
          currentComponent.openCIRFImportDataModal(cirfUUID);
        }
      }, 200);
    });
  }

  async openCIRFImportDataModal(cirfUUID: string, purchaserComponent?: PurchaserComponent): Promise<void> {
    let cirf: Cirf = await this.opportunitiesService.getCIRFByPackageGUIDForUnity(cirfUUID, this.matter.id).toPromise();
    // try to load cirf configuration before opening the modal so all fields statuses are ready for directive
    let matterType = this.matter && this.matter.isProjectSale ? 'PROJECT_SALE' : this.matter.derivedMatterType;
    await this.cirfConfigService.getCirfConfig(matterType).toPromise();
    if (cirf) {
      let matterCopy = new Matter(this.matter);
      this.dialogService.matDialogContent({
        content: CirfImportContainerModalComponent,
        widthXl: true,
        context: {
          matter: matterCopy,
          originalMatter: this.matter,
          cirf: cirf
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action == ModalResult.OK) {
              if (result.completeRequest) {
                this.opportunitiesService.completeCIRFRequest(cirf.id, this.matter.id).subscribe((cirf: Cirf) => {
                  //since this method can be called by purchaserComponent, need to update purchaserComponent with the updated cirf
                  if (purchaserComponent) {
                    purchaserComponent.updateCirf(cirf);
                  }
                });
              }
              if (cirf.selectedDocuments.length > 0) {
                this.importSelectedCirfDocuments(cirf);
              }
              this.propogateCirfImportChanges(matterCopy, result.cirfDataPropagation);
            }
          }
          // this.setFocusAfterModalClose();
        }
      });
    }
  }

  async openProcessCIRFModal(guid: string) {
    let cirf: Cirf = await this.opportunitiesService.getCIRFByPackageGUIDForUnity(guid, this.matter.id).toPromise();
    if (this.matter && cirf) {
      let capacityOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(
        this.matter.mainClients.length,
        this.matter.provinceCode
      );
      let connectCapacityOptions = [];
      Cirf.buildConnectCapacityOptions(connectCapacityOptions, capacityOptions);
      // try to load cirf configuration before opening the modal so all fields statuses are ready for directive
      let matterType = this.matter && this.matter.isProjectSale ? 'PROJECT_SALE' : this.matter.derivedMatterType;
      await this.cirfConfigService.getCirfConfig(matterType).toPromise();
      this.dialogService.matDialogContent({
        content: PresentCirfModalComponent,
        context: {
          cirf: cirf,
          matterType: this.matter.getFormattedMatterType(),
          matterPurchasersCapacityOptions: connectCapacityOptions
        },
        onFulfillment: () => {
          // Do nothing
        }
      });
    }
  }

  async propogateCirfImportChanges(matter: Matter, cirfDataPropagation: UnitConnectDataPropagation): Promise<void> {
    try {
      this.lockScreenService.lockForUpdate = true;
      if (
        cirfDataPropagation &&
        cirfDataPropagation.unitConnectImportDataPropagationCommands &&
        cirfDataPropagation.unitConnectImportDataPropagationCommands.length > 0
      ) {
        await this.unitConnectImportService.propagateToMatter(
          matter,
          cirfDataPropagation.unitConnectImportDataPropagationCommands
        );
      }
      let updatedMatter = new Matter(matter);
      currentMatter.updateMatterStateAndNotify(updatedMatter);
      // In CirfImportContainerModal, it will addToDataPropagationCommands. The following functions into propagateToMatter of unitConnectImportService
      // this.propogateImportChangesAction();
      // this.enableSave();
      currentMatter.value.isDirty = true;
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  importSelectedCirfDocuments(cirf: Cirf): void {
    cirf.selectedDocuments.forEach((selectedDocumentId) => {
      let cirfDocument: CirfDocument = cirf.documents.find((cirfDocument) => cirfDocument.id == selectedDocumentId);
      if (cirfDocument) {
        this.matterService.importCirfMatterDocument(this.matter.id, cirf.guid, cirfDocument).subscribe();
      }
    });
  }

  //Open referral dialog should be in matter.component
  openReferralImportModalIfApplicable() {
    this.route.queryParams.subscribe((params: Params) => {
      let currentComponent = this;
      setTimeout(() => {
        if (params['referralId']) {
          let referralId = params['referralId'];
          currentComponent.openReferralImportDataModal(referralId);
        }
      }, 200);
    });
  }

  async openReferralImportDataModal(referralId: number): Promise<void> {
    //TODO: re-use referral from opporunities-list.component.ts to avoid second call
    let referral: Referral = await this.opportunitiesService.getReferralById(referralId).toPromise();

    if (referral) {
      let matterCopy = new Matter(this.matter);
      this.dialogService.matDialogContent({
        content: ReferralImportContainerModal,
        context: {
          matter: matterCopy,
          referral: referral
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action === ModalResult.OK) {
              this.propagateReferralImportChanges(matterCopy, referral, result);
            }
          }
        }
      });
    }
  }

  async propagateReferralImportChanges(matterCopy: Matter, referral: Referral, result): Promise<void> {
    try {
      this.lockScreenService.lockForUpdate = true;
      if (
        result &&
        result.referralDataPropagation &&
        result.referralDataPropagation.unitConnectImportDataPropagationCommands &&
        result.referralDataPropagation.unitConnectImportDataPropagationCommands.length > 0
      ) {
        await this.unitConnectImportService.propagateToMatter(
          matterCopy,
          result.referralDataPropagation.unitConnectImportDataPropagationCommands
        );
      }
      let updatedMatter = new Matter(matterCopy);
      currentMatter.updateMatterStateAndNotify(updatedMatter);
      this.matter.referralId = referral.id;
      this.matter.createdFromReferral = true;
      // In ReferralImportContainerModal, it will addToDataPropagationCommands. The following functions are moved into propagateToMatter of
      // unitConnectImportService
      // if (result.solicitorChanged) {
      //     this.onSolicitorChange(this.matter.selectedSolicitorId);
      // }
      currentMatter.value.isDirty = true;
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  openPrintOverviewModel() {
    if (this.isWorkItemsSubtab() || (this.matter && this.matter.isOpportunityMatter())) {
      this.openWorkItemsPrintModal();
    } else {
      this.openWorkItemsOverviewModal();
    }
  }

  openWorkItemsPrintModal(): void {
    this.dialogService.matDialogContent({
      content: PrintMatterWorkItemsModalComponent,
      fullScreen: true,
      context: {
        matter: this.matter,
        matterOverviewService: this.matterOverviewService
      },
      onFulfillment: () => {}
    });
  }

  openWorkItemsOverviewModal(): void {
    this.dialogService.matDialogContent({
      content: PrintMatterOverviewModalComponent,
      fullScreen: true,
      context: {
        matter: this.matter,
        matterOverviewService: this.matterOverviewService
      },
      onFulfillment: () => {}
    });
  }

  getSidebarFontSizeClass(): string {
    return `percent-${this.userConfigurationService.sidebarFontSize}`;
  }

  getSidebarFontColor(): string {
    return this.userConfigurationService.sidebarFontColor;
  }

  getSidebarFontWeight(): string {
    return this.userConfigurationService.isSidebarFontBold ? DpBold : '';
  }

  enableSave() {
    this.matter.isDirty = true;
  }

  isLinkedMatterProjectSale(): boolean {
    return (
      this.matter &&
      this.matter.matterLink &&
      this.activeMatterTab &&
      this.activeMatterTab.isMatter() &&
      this.activeMatterTab.isLinkedMatterProjectSale()
    );
  }

  getLinkedMatterRecordNumber(): string {
    return this.activeMatterTab && this.activeMatterTab.isMatter() && this.activeMatterTab.linkedMatter
      ? this.activeMatterTab.linkedMatter.matterRecordNumber
      : '';
  }

  getLinkedMatterAdjustmentProgressionStatus(): string {
    return this.activeMatterTab && this.activeMatterTab.isMatter() && this.activeMatterTab.linkedMatter
      ? this.activeMatterTab.linkedMatter.isSelectedAdjustmentStatusModeFinal
        ? 'Final'
        : 'Interim'
      : '';
  }

  isTabCorTabD(): boolean {
    return (
      this.activeSection.sectionKey == 'VENDOR_SOLICITOR' ||
      (this.activeSection.sectionKey == 'PROPERTY' &&
        this.getActiveSectionRoutePath() != HOLDBACKS_SECTION_ROUTE_PATH) ||
      this.activeSection.sectionKey == 'STATEMENT_OF_ADJUSTMENT'
    );
  }

  isTabCorTabDDisabled(): boolean {
    return (
      this.isLinkedMatterProjectSale() &&
      !this.isLinkedMatterDirty &&
      !this.isLinkedMatterProjectLocked() &&
      this.isTabCorTabD()
    );
  }

  isSelectedLinkTopic(): boolean {
    return (
      this.activeSection.sectionKey == 'VENDOR_SOLICITOR' ||
      this.activeSection.sectionKey == 'PROPERTY' ||
      this.activeSection.sectionKey == 'STATEMENT_OF_ADJUSTMENT' ||
      this.activeSection.sectionKey == 'UNDERTAKINGS'
    );
  }

  isSelectedLinkTopicDisabled(): boolean {
    return (
      this.isLinkedMatterProjectSale() &&
      !this.isLinkedMatterDirty &&
      !this.isLinkedMatterProjectLocked() &&
      this.isSelectedLinkTopic()
    );
  }

  linkedProjectSalePurchaseDisabledMsg(): string {
    if (this.activeSection.sectionKey == 'PROPERTY') {
      return (
        'This topic (except for Title Insurance fields and Holdbacks) is disabled due to linking with project sale matter ' +
        this.getLinkedMatterRecordNumber() +
        '. Please edit on project matter.'
      );
    } else if (this.activeSection.sectionKey == 'STATEMENT_OF_ADJUSTMENT') {
      return (
        'This topic is disabled due to linking with a project sale matter.  This is the ' +
        this.getLinkedMatterAdjustmentProgressionStatus() +
        ' Statement of Adjustment from ' +
        this.getLinkedMatterRecordNumber() +
        '. To modify, please edit on project sale matter.'
      );
    } else if (this.activeSection.sectionKey == 'MATTER_OPENING') {
      return (
        'Selected fields (i.e. Occupancy Date and Closing Date) are disabled due to linking with project sale matter ' +
        this.getLinkedMatterRecordNumber() +
        '. Please edit on project matter.'
      );
    } else {
      return (
        'This topic is disabled due to linking with project sale matter ' +
        this.getLinkedMatterRecordNumber() +
        '. Please edit on project matter.'
      );
    }
  }

  linkedPurchaseDisabledMsg(): string {
    if (this.isLinkedMatterProjectSale()) {
      // The linked matters is project sale matter
      return this.linkedProjectSalePurchaseDisabledMsg();
    } else if (this.matter && this.matter.matterLink && this.matter.isPurchase) {
      // Neither  these two linked matters are project sale matters.
      if (this.activeSection.sectionKey == 'UNDERTAKINGS') {
        return (
          'This topic is disabled due to linking with sale matter ' +
          this.getLinkedMatterRecordNumber() +
          '. Please edit on sale matter.'
        );
      }
    }
  }

  isLinkedMatterProjectLocked(): boolean {
    if (this.isLinkedMatterProjectSale() && !this.isLinkedMatterDirty) {
      let linkedMatter = this.activeMatterTab && this.activeMatterTab.isMatter() && this.activeMatterTab.linkedMatter;
      return this.tabsService.isMatterProjectLocked(linkedMatter);
    } else {
      return false;
    }
  }

  isReadOnlyBlock(): boolean {
    return (
      ((this.isMatterReadOnly ||
        this.isMatterLocked ||
        this.isMatterRefunded ||
        this.disableMatterForThirdPartyDataRefresh ||
        this.isTabCorTabDDisabled() ||
        this.isLinkedMatterProjectLocked()) &&
        !this.isSectionDocumentProduction) ||
      (this.matter &&
        this.matter.isOpportunityMatter() &&
        this.matter.opportunity &&
        this.matter.opportunity.isClosedDuplicate() &&
        !(this.isSectionNotes() || this.isSectionAssociatedFiles()))
    );
  }

  isMatterOpeningInLinkedPurchseMatter(): boolean {
    return (
      this.isLinkedMatterProjectSale() &&
      !this.isLinkedMatterDirty &&
      !this.isLinkedMatterProjectLocked() &&
      this.activeSection.sectionKey == 'MATTER_OPENING'
    );
  }

  isUndertakingsInLinkedPurchseMatter(): boolean {
    return (
      !this.isLinkedMatterProjectSale() &&
      this.matter &&
      this.matter.isPurchase &&
      this.matter.matterLink &&
      this.activeSection.sectionKey == 'UNDERTAKINGS'
    );
  }

  // Update the row of the matter list with the updated matter
  updateMatterList(updatedMatter: Matter): void {
    let matterListTab: MatterTab = this.tabsService.openTabs.find(
      (tab) => tab.isMatter() && tab.isAnchorTab()
    ) as MatterTab;
    if (matterListTab && matterListTab.matterListState) {
      this.updateMatterInList(matterListTab.matterListState.rows, updatedMatter);
      this.updateMatterInList(matterListTab.matterListState.selectedMatters, updatedMatter);
    }
  }

  updateMatterInList(list: Matter[], updatedMatter: Matter): void {
    if (list && list.length) {
      let selectedMatterIndex = list.findIndex((matter) => matter.id == updatedMatter.id);
      if (selectedMatterIndex != -1) {
        list[selectedMatterIndex] = updatedMatter;
      }
    }
  }

  isStickyNoteVisibleForMatter(): boolean {
    return this.matter && !this.matter.isTemplateMatterForMassUpdate && !this.isActiveSectionOverview();
  }

  isAddNoteVisibleForMatter(): boolean {
    return this.matter && !this.isActiveSectionNotes();
  }

  isStatusVisibleForMatter(): boolean {
    return !this.isActiveSectionOverview() && this.matter && !this.matter.isOpportunityMatter();
  }

  isClosingStatusVisibleForMatter(): boolean {
    return (
      this.isStatusVisibleForMatter() &&
      this.matter &&
      !(this.matter.isDischargeMatter() || (this.matter.isMortgage && this.matter.isMatterTypeDischarge)) &&
      !this.matter.isCustomMatter() &&
      !this.matter.isWillMatter()
    );
  }

  isQuickOpenBillingVisibleForMatter(): boolean {
    return !this.matter.isMatterTypeDischarge && !this.matter.isProjectSale && !this.matter.isCustomMatter();
  }

  isOpportunityWorkItemSummaryVisible(): boolean {
    return (
      this.matter &&
      this.matter.isOpportunityMatter() &&
      this.isActiveSectionOverview() &&
      this.matter.matterWorkItems &&
      this.matter.matterWorkItems.length > 0
    );
  }

  get workItemsSummaryText(): string {
    return MatterOverviewUtil.getWorkItemsSummaryText(this.matter);
  }

  get numberOfWorkitemsTasks(): number {
    return MatterOverviewUtil.getNumberOfWorkitemsTasks(this.matter);
  }

  get numberOfOverDueTasks(): number {
    return MatterOverviewUtil.getNumberOfOverDueTasks(this.matter);
  }

  isConvertToMatterBtnVisible(): boolean {
    return (
      this.matter &&
      this.matter.isOpportunityMatter() &&
      !this.isMassUpdateTab() &&
      this.authZService.hasFullAccessToConveyancingMatters() &&
      !this.matter.opportunity.associatedToLinkedMatters
    );
  }

  openConvertOpportunityToMatterModal(onlyAllowImportToExisting: boolean = false): void {
    let matterTab = this.tabsService.activeTab as MatterTab;
    if (matterTab) {
      matterTab.matterComponent = this;
    }
    if (
      this.matter &&
      this.matter.isOpportunityPurchaseOrSale &&
      this.matter.isActingForBothPurchaseAndSaleMatters &&
      this.matter.containsMatterOnlyClients()
    ) {
      this.dialogService.confirm(
        'ERROR',
        'When acting on behalf of both the vendors and purchasers, ' +
          "all of the 'Other Side' parties must be full contacts. " +
          'Either update these parties to become full contacts or remove them from the opportunity.',
        true
      );
      return;
    }
    this.matterService.callOperationAfterMatterSaved(this.matter, () => {
      this.dialogService.matDialogContent({
        content: ConvertToMatterModalComponent,
        context: {
          opportunityMatter: this.matter,
          copyOpportunityMatterService: this.copyOpportunityMatterService,
          copyMatterLinkDataService: this.copyMatterLinkDataService,
          onlyAllowImportToExisting: onlyAllowImportToExisting
        },
        modalGrid: 10,
        onFulfillment: (result) => {
          this.matter.dirty = false;
          if (result) {
            if (result.matter) {
              MatterListUtil.openMatter(result.matter, this.tabsService, this.tabsComponent, this.dialogService);
            }
            if (result.linkedMatter) {
              MatterListUtil.openMatter(result.linkedMatter, this.tabsService, this.tabsComponent, this.dialogService);
            }
          }
        }
      });
    });
  }

  isConvertToMatterBtnDisabled(): boolean {
    return this.isMatterReadOnly || this.isMatterLocked;
  }

  getMatterTitle(): string {
    return this.matter.isOpportunityMatter() ? 'Opportunity' : 'Matter';
  }

  showStickyNotes(): void {
    jQuery('.dp-sticky-note').addClass('display-block');
  }

  hideStickyNotes(): void {
    jQuery('.dp-sticky-note').removeClass('display-block');
  }

  getConvertToMatterLabel(): string {
    return this.matter && this.matter.opportunity && this.matter.opportunity.isClosedDuplicate()
      ? 'Attach to Matter'
      : 'Convert to Matter';
  }

  openAddNoteModal(): void {
    this.dialogService.matDialogContent({
      content: AddNoteDialogComponent,
      context: {
        showNotesOnOpen: this.matter.showNotesOnOpen,
        isShowNotesOnOpenVisible: true
      },
      onFulfillment: (result) => {
        if (result) {
          this.matter.showNotesOnOpen = result.showNotesOnOpen;
          MatterUtil.addNote(this.matter, sessionStorage, result.noteData);
          this.enableSave();
        }
      }
    });
  }

  checkIfOpportunityConversionModalIsRequiredAndOpen() {
    this.route.queryParams.subscribe((params: Params) => {
      setTimeout(() => {
        if (params['oc'] == 'true') {
          this.openConvertOpportunityToMatterModal(true);
        }
      }, 200);
    });
  }

  private checkProjectMassUpdateTransactionStatus(unityProjectId: number) {
    this.projectService.getProject(unityProjectId, false).subscribe((project) => {
      this.projectMatterUpdateService.checkMassUpdateTransactionInProgress(project);
    });
  }

  saveMatterUtility() {
    this.matterUtility = this.matter.matterUtility;
    if (this.matterUtility.matterId) {
      this.matterUtilitySaveSubscription = this.matterService.saveMatterUtility(this.matterUtility).subscribe(
        (matterUtility: MatterUtility) => {
          this.matter.matterUtility = matterUtility;
        },
        (error: ApplicationError) => {
          return false;
        }
      );
    }
  }
}
