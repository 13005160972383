<div class="inspector-footer">
    <div class="info-box">
        <div class="info-main">
            <ul>
                 <li>{{statusBarService.currentHelpText}}</li>
            </ul>
        </div>
    </div>
</div>

