import {ParcelRegister} from '../../../shared-main/teranet/parcel-register';

const ParcelTypeDescriptions = [
  {parcelType: 'R', parcelDescription: 'Road Parcel'},
  {parcelType: 'P', parcelDescription: 'Regular Parcel'},
  {parcelType: 'D', parcelDescription: 'Disjointed Surface Parcel'},
  {parcelType: 'C', parcelDescription: 'Surface Condo Parcel'},
  {parcelType: 'I', parcelDescription: 'Indian Lands Parcel'},
  {parcelType: 'M', parcelDescription: 'Many to Many'},
  {parcelType: 'O', parcelDescription: 'Other'},
  {parcelType: 'X', parcelDescription: 'Railway Parcel'},
  {parcelType: 'B', parcelDescription: 'Undefined Boundary Parcels'},
  {parcelType: 'N', parcelDescription: 'Unknown number of Properties Parcel'},
  {parcelType: 'F', parcelDescription: 'Disjointed Condo Parcel'}
];

// only used for display purposes
export class ParcelAdjacentSelection {
  id: number;
  pin: string;
  parcelType: string;
  ordered: boolean;
  selected: boolean;

  constructor(fromParcelRegister: ParcelRegister) {
    this.id = fromParcelRegister.id;
    this.pin = fromParcelRegister.pin;
    this.parcelType = fromParcelRegister.adjacentParcelType;
    this.ordered = fromParcelRegister.ordered;
  }

  /**
   * If 'Type' = 'C', 'B','O','I','A','M','D','N','F', disable the checkbox
   * If already ordered disable the checkbox
   * @returns {boolean}
   */
  get cannotBeOrdered(): boolean {
    return [ 'C', 'B', 'O', 'I', 'A', 'M', 'D', 'N', 'F' ].indexOf(this.parcelType) > -1 || this.ordered;
  }

  get hint(): string {
    if (this.parcelType == undefined || this.parcelType == null) {
      return '';
    }

    if ([ 'B', 'O', 'I', 'A', 'M', 'D', 'N', 'F' ].indexOf(this.parcelType) > -1) {
      return 'This PIN represents an unusual situation that cannot be mapped.  Please contact the local Land Registry office for more information.';
    }

    if (this.parcelType === 'C') {
      return 'This PIN represents many parcels contained within the Condominium and cannot be searched.';
    }
    return '';
  }

  get adjacentParcelTypeDescription(): string {
    if (this.parcelType == undefined || this.parcelType == null) {
      return '';
    }

    let descr = ParcelTypeDescriptions.find(ptd => ptd.parcelType === this.parcelType);
    if (descr != null) {
      return (this.ordered ? '(Ordered) ' : '') + descr.parcelDescription;
    }

    return 'Unknown Parcel Type';
  }
}
