import {Directive, ElementRef, Input, Renderer2, SimpleChanges} from '@angular/core';
import {WindowRef} from '../shared/window.ref';
import {DpSortableColumnParams} from './dp-sortable-column-params';

declare var jQuery: any;

@Directive({
  selector: '[dpSortableColumn]'
})

export class dpSortableColumn {
  constructor(private el: ElementRef, private renderer: Renderer2, private window: WindowRef) {
  }

  sortParams: DpSortableColumnParams;

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.initSortedTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initSortedTable();
  }

  initSortedTable() {
    //freez the screen when move from message to this any screen which uses this directive
    // if(this.dpSortableColumn){
    //     this.sortParams = this.dpSortableColumn;
    //     let html: string = this.sortParams.title;
    //     if(this.sortParams.sortKey==this.sortParams.sortQuery){
    //         let sortClass:string='active';
    //         let sortClassType:string = this.sortParams.sortType=== 'ASC' ? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom';
    //         html = html + `<div class="sort-icons"><i class="glyphicon ${sortClass} ${sortClassType}"></i></div>`;
    //     }
    //
    //     this.el.nativeElement.innerHTML = html;
    // }
  }

  @Input() dpSortableColumn: DpSortableColumnParams;

}

