import {MatterParticipant} from '..';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type WillBeneficiaryWrapperType = 'BENEFICIARY' | 'CO_OWNER' | 'PET_OWNERSHIP';

export class BeneficiariesOrCoOwners extends BaseEntity {
  id?: number;
  wrapperType: WillBeneficiaryWrapperType;
  beneficiaryOrCoOwner: MatterParticipant;

  constructor(beneficiariesOrCoOwners?: BeneficiariesOrCoOwners) {
    super(beneficiariesOrCoOwners);
  }

}
