import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {User} from '../../matters/shared/user';

export class AccessGroup extends BaseEntity {

  id: number;
  customerAccountId: number;
  accessGroupName: string;
  active: boolean;
  default: boolean;
  users: User[];

}
