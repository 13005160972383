<form class="form-horizontal container-fluid custom-modal-container" *ngIf="localAdj" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : <span [dp-province-field-label]="'matter.soa.prePaidRent'">Pre-Paid Rent</span></h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Unit occupied</label>
                </div>
                <div class="col-lg-4">
                    <select type="text"
                            id="unitOccupied"
                            name="unitOccupied"
                            [(ngModel)]="localAdj.unitOccupied"
                            class="form-control"
                    >
                        <option *ngFor="let unitOccupied of unitOccupiedTypes" [value]="unitOccupied.value">
                            {{unitOccupied.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div *ngIf = "localAdj.unitOccupied == 'SPECIFY'" class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Description of area occupied</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           name="areaOccupiedDescription"
                           id="areaOccupiedDescription"
                           maxlength="20"
                           [(ngModel)]="localAdj.areaOccupiedDescription"
                           trimmed
                           class="form-control"
                           dp-error-validator
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Amount of <span [dp-province-field-label]="'matter.soa.prePaidRent'">Pre-Paid Rent</span> (excluding {{context.soaConsiderationTaxes && context.soaConsiderationTaxes.rateTypeLabel}})</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           id="prepaidRentAmount"
                           name="prepaidRentAmount"
                           [(ngModel)]="localAdj.prepaidRentAmount"
                           class="form-control"
                           dp-currency
                           dp-error-validator
                           significant-currency-digits="8"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Is Purchaser to receive credit with interest on <span [dp-province-field-label]="'matter.soa.prePaidRent'">Pre-Paid Rent</span>?</label>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="receiveCreditWithInterest"
                            name="receiveCreditWithInterest"
                            (ngModelChange)="modalErrorComponent.removeAllDpFieldError()"
                            [(ngModel)]="localAdj.receiveCreditWithInterest"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="localAdj.receiveCreditWithInterestBoolean">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Should the application perform the interest calculation?</label>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="performInterestCalculation"
                            name="performInterestCalculation"
                            [(ngModel)]="localAdj.performInterestCalculation"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="localAdj.receiveCreditWithInterestBoolean && localAdj.performInterestCalculationBoolean">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Calculation method</label>
                </div>
                <div class="col-lg-5">
                    <select type="text"
                            id="calculationMethod"
                            name="calculationMethod"
                            [(ngModel)]="localAdj.calculationMethod"
                            class="form-control"
                    >
                        <option *ngFor="let calcMethod of calculationMethodType" [value]="calcMethod.value">
                            {{calcMethod.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-4">
                    <span class="actionlink">
                        <span>
                            <a href="javascript:void(0);" (click)="viewInterestRateTable();">
                            View Interest Rate Table
                            </a>
                        </span>
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="localAdj.receiveCreditWithInterestBoolean && localAdj.performInterestCalculationBoolean && localAdj.calculationMethod != 'FLAT_INTEREST_RATE'">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Format for printing</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="printingFormat"
                            name="printingFormat"
                            [(ngModel)]="localAdj.printingFormat"
                            class="form-control"
                    >
                        <option *ngFor="let pft of printingFormatType" [value]="pft.value">
                            {{pft.label}}
                        </option>
                    </select>
                </div>

            </div>
        </div>

        <div class="form-group" *ngIf="localAdj.receiveCreditWithInterestBoolean &&  (localAdj.calculationMethod == 'FLAT_INTEREST_RATE' ||  !localAdj.performInterestCalculationBoolean)">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Enter the interest rate</label>
                </div>
                <div class="col-lg-1"  *ngIf="!context.currentMatter.isMatterProvinceNBorNS">
                    <input type="text"
                           id="interestRate"
                           name="interestRate"
                           [(ngModel)]="localAdj.interestRate"
                           class="form-control"
                           dp-percentage
                           padding-zero="true"
                    />
                </div>
                <div class="col-lg-1" *ngIf="context.currentMatter.isMatterProvinceNBorNS">
                    <input type="text"
                           id="interestRateNSAndNB"
                           name="interestRateNSAndNB"
                           [(ngModel)]="localAdj.interestRate"
                           class="form-control"
                           dp-percentage
                           padding-zero="true"
                           statusBarHelp
                           (keydown.f9)="updateInterestRateFromSOAProfile()"
                           [dynamicHelpCallBackFunction] = "generateF9HelpInterestRate()"
                    />
                </div>
                <div class="col-lg-1">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="localAdj.receiveCreditWithInterestBoolean && !localAdj.performInterestCalculationBoolean">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Enter the interest amount</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="interestAmount"
                           name="interestAmount"
                           [(ngModel)]="localAdj.interestAmount"
                           class="form-control"
                           dp-currency
                           significant-currency-digits="8"
                    />
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="localAdj.receiveCreditWithInterestBoolean">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Date from which interest is calculated</label>
                </div>
                <div class="col-lg-4">
                    <dp-partial-date #fromDate
                                     (dateChange)="onDateChange($event ,'interestCalculatedFromDate')"
                                     [fieldKey]="'matter.soadj.tenancy.prepaid.from.date'"
                                     [fullDateRequired]="true"
                                     dp-error-validator
                                     [inputDate]="localAdj.interestCalculatedFromDate"
                    >
                    </dp-partial-date>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'matter.soa.tenancy-current.modal.adjustFor'">Adjust for</label>
                </div>
                <div class="col-lg-1" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'">
                    <select type="text"
                            id="taxType"
                            name="taxType"
                            [(ngModel)]="localAdj.taxType"
                            class="form-control"
                    >
                        <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                            {{taxType.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="applyTax"
                            name="applyTax"
                            [(ngModel)]="localAdj.applyTax"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-2 vertical-align-label" *ngIf="localAdj.applyTaxBoolean">
                    <label class="control-label">{{localAdj.taxType}} percentage is</label>
                </div>
                <div class="col-lg-1" *ngIf="context.soaConsiderationTaxes && localAdj.taxType == context.soaConsiderationTaxes.rateType && localAdj.applyTaxBoolean">
                    <input type="text"
                           id="hstPercentage"
                           name="hstPercentage"
                           [(ngModel)]="localAdj.hstRate"
                           class="form-control"
                           dp-percentage
                           padding-zero="true"
                    />
                </div>
                <div class="col-lg-1" *ngIf="localAdj.taxType == 'GST'  && localAdj.applyTaxBoolean && context.defaultPstRate">
                    <input type="text"
                           id="gstPercentage"
                           name="gstPercentage"
                           [(ngModel)]="localAdj.gstRate"
                           class="form-control"
                           dp-percentage
                           padding-zero="true"
                    />
                </div>
                <div class="col-lg-1" *ngIf="localAdj.applyTaxBoolean">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>
        <!-- if you're here to bring back PST just change false to localAdj.taxType == 'GST' , you're welcome -->
        <div class="form-group" *ngIf="false">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Adjust for PST</label>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="applyPst"
                            name="applyPst"
                            [(ngModel)]="localAdj.applyPst"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">PST percentage is</label>
                </div>
                <div class="col-lg-1">
                    <input type="text"
                           id="pstPercentage"
                           name="taxPercentage"
                           [(ngModel)]="localAdj.pstRate"
                           class="form-control"
                           dp-percentage
                           padding-zero="true"
                    />
                </div>
                <div class="col-lg-1">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>


        <!-- adjustment report -->
        <div style="border-top: 1px solid #cccccc;">
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{adjustmentDescription | uppercase}}</label>
                </div>
            </div>

            <div *ngIf="(localAdj.prepaidRentAmount > 0)">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left"><span [dp-province-field-label]="'matter.soa.prePaidRent'">Pre-Paid Rent</span> Amount</label>
                    <label class="control-label col-lg-2">{{localAdj.prepaidRentAmount | currency:'CAD':'symbol-narrow':'1.2-2'}}</label>
                </div>
            </div>

            <div *ngFor="let line of details">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                    <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                </div>
            </div>

            <div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentHeading.leftColumnListsCreditsTo}}</label>
                    <label *ngIf="validCalculations()" class="control-label col-lg-4">{{ localAdj.totalCreditPurchaser | currency:'CAD':'symbol-narrow':'1.2-2'}}</label>
                    <label *ngIf="!validCalculations()" class="control-label col-lg-4">$??????????.??</label>
                </div>
            </div>

        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
            <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                <span>Delete</span>
            </button>

        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</form>
