<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1 *ngIf="showErrors">Errors</h1>
            <h1 *ngIf="!showErrors">

                <span *ngIf="amortizationObject.paymentType=='REGULAR_PAYMENTS'">Create Mortgage Amortization Schedule for Regular Payments</span>
                <span *ngIf="amortizationObject.paymentType=='ALT_PAYMENTS'">Create Mortgage Amortization Schedule for Atl. Regular Payments</span>

            </h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="closeDialog()" >&times;</button>
    </div>
    <div class=" modal-body form-horizontal padding-bottom-100">
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                <div class="form-group" *ngIf="showErrors">
                    <div class="col-lg-12">

                        <div class="errors" >
                            <p>All fields must be completed in order to display the Amortization Table</p>
                            <br/>
                            <p class="tab">* Principal Amount</p>
                            <p class="tab">* Interest Rate</p>
                            <p class="tab">* Calculated</p>
                            <p class="tab">* Payment Frequency</p>
                            <p class="tab">* Amortization Period</p>
                            <p class="tab">* Regular Payments</p>
                            <p class="tab">* Number of Payments</p>
                        </div>

                    </div>
                </div>
                <div  *ngIf="!showErrors">
                    <div class="form-group">
                        <div class="col-lg-12">
                            <p>Select the number of payments that will be reported in the Amortization table</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="radio col-lg-3">

                            <input type="radio"
                                   id="payments_60"
                                   name="payments_select_radio"
                                   value="SIXTY_PAYMENTS"
                                   [(ngModel)]="amortizationObject.selectedPayments"
                                   (change)="selectPaymentOption($event)"
                            />
                            <label for="payments_60">60 Payments</label>
                        </div>
                        <div class="radio col-lg-3">
                            <input type="radio"
                                   id="maturity_date"
                                   name="payments_select_radio"
                                   value="TO_MATURITY"
                                   [(ngModel)]="amortizationObject.selectedPayments"
                                   (change)="selectPaymentOption($event)"
                            />
                            <label for="maturity_date">To Maturity Date</label>
                        </div>
                        <div class="radio col-lg-2">
                            <input type="radio"
                                   id="to_payout"
                                   name="payments_select_radio"
                                   value="TO_PAYOUT"
                                   [(ngModel)]="amortizationObject.selectedPayments"
                                   (change)="selectPaymentOption($event)"
                            />
                            <label for="to_payout">To Payout</label>
                        </div>
                        <div class="radio col-lg-4">
                            <input type="radio"
                                   id="enter_payments"
                                   name="payments_select_radio"
                                   value="ENTER_PAYMENTS"
                                   [(ngModel)]="amortizationObject.selectedPayments"
                                   (change)="selectPaymentOption($event)"
                            />
                            <label for="enter_payments">Enter the number of payments</label>

                        </div>
                    </div>
                    <div class="form-group" *ngIf="amortizationObject.autocalculated==false || amortizationObject.autocalculated==null">

                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">Amortization Period</label>
                        </div>
                        <div class="col-lg-2">
                            <input type="text" numberOnly
                                   [fieldKey]="'mortgage.terms.amortizationPeriod'"   dp-error-validator  (blur)="validateErrorForSave()"
                                                     [(ngModel)]="amortizationObject.amortizationPeriod" class="form-control focus-first" /></div>
                        <div class="col-lg-2 vertical-align-label"><label class="control-label text-left">years</label></div>
                    </div>

                    <div class="form-group" *ngIf="amortizationObject.selectedPayments=='ENTER_PAYMENTS'">

                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">Enter the number of&nbsp;Payments</label>
                        </div>
                        <div class="col-lg-2"><input type="text" numberOnly id="numOfPayments" name="numOfPayments" [(ngModel)]="amortizationObject.numOfPayments"
                                                     class="form-control"
                                                     (blur)="validateErrorForSave()"
                                                     [fieldKey]="'mortgage.terms.amortization.payments'"   dp-error-validator
                        /></div>
                    </div>

                </div>

            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons text-center">
            <div *ngIf="showErrors">
                <button type="button" class="dp-btn primary" (click)="closeDialog()">
                    <span>OK</span>
                </button>
            </div>

            <div *ngIf="!showErrors">
                <button type="button" class="dp-btn keyboard-save-action"
                        [ngClass]="{'focus-first' : amortizationObject.autocalculated}"
                        (click)="produce()" [disabled]="isAnySaveErrors() || showErrors">
                    <span>Produce</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="cancelDialog()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent ></dp-modal-error>
</div>
