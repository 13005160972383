import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class FctMessage extends BaseEntity {
  message: string;
  errorCode: string;

  constructor(t?: FctMessage) {
    super(t);
  }
}
