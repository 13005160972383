import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {WritStatus} from './property-writ';
import {TeranetDocument} from './teranet-document';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import Utils from '../utils';

export class WritExecution extends BaseEntity {
  id: number;
  writId: number;
  executionNumber: string;
  certificateDate: string;
  enforcementOffice: string;
  executionStatus: WritStatus;
  executionDetails: string;
  lastName: string;
  givenName: string;
  judgementsTotal: number; //Total $ value of judgements
  costsTotal: number; //Total $ value of costs
  executionCertificate: TeranetDocument;
  writExecutionBurgerMenuItems: BurgerMenuExtendedItem[];

  constructor(w?: WritExecution) {
    super(w);
    if (w) {
      if (w.executionCertificate) {
        this.executionCertificate = new TeranetDocument(w.executionCertificate);
      }
    }
  }

  get partyName(): string {
    let names: string[] = [];
    if (this.lastName) {
      names.push(this.lastName);
    }
    if (this.givenName) {
      names.push(this.givenName);
    }
    return this.executionNumber + ' vs. ' + names.join(', ');
  }

  get debtorName(): string {
    return this.givenName + (this.lastName ? ' ' + this.lastName : '');
  }

  get plaintiffName(): string {
    if (!this.executionDetails) {
      return '';
    } else {
      //splitting each line of the execution details
      const details: string[] = this.executionDetails.split('\n');
      let plaintiffName: string = details[ 0 ];

      //Special case. If first line ends with "For the Benefit Of"... concatenate first and second lines.
      if (details.length > 1) {
        if (plaintiffName.endsWith('FOR THE BENEFIT OF') || details[ 1 ].startsWith('FOR THE BENEFIT OF')) {
          plaintiffName += ' ' + details[ 1 ];
        }
      }

      return Utils.convertToTitleCase(plaintiffName);
    }

  }

}
