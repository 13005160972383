import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {Logger} from '@nsalaun/ng-logger';
import {Observable} from 'rxjs';
import {StewartAssystUser} from './stewart-assyst-user';
import {telusApi, telusConfigurations} from './telus-constants';
import {SolicitorMortgageInstruction} from './solicitor-mortgage-instruction';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {StewartAssystDocument, StewartAssystMortgageInstructionData} from './stewart-assyst-mortgage-instruction-data';
import {StewartMortgageInstruction} from './stewart-mortgage-instruction';
import Utils from '../utils';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {Mortgage} from '../../matters/shared/mortgage';
import {Matter} from '../../matters/shared';
import {CurrencyPipe} from '@angular/common';
import {Document} from '../../matters/document-production/document';
import * as _ from 'lodash';
import {StewartAssystDownloadDocResponse} from './stewart-assyst-download-doc-response';
import moment from 'moment';
import {SESSION_ID_REQ_PARAM} from '../../common';
import {PayoutRequestData} from '../../matters/mortgages/mortgage/assyst-payout-request-modal/payout-request-data';
import {PayoutStatement} from './payout-statement';
import {AssystPayoutData} from '../../matters/mortgages/mortgage/assyst-import-payout/assyst-payout-data';
import {AppConfig} from '../app-configuration';

@Injectable()
export class TelusService {

  constructor(private http: HttpClient, private logger: Logger, public appConfig: AppConfig) {
  }

  getTelusUser(): Observable<StewartAssystUser> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.telusUser.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return new StewartAssystUser(res[ 'StewartAssystUser' ]);
    });
  }

  getTelusUserForSolicitor(solicitorId: number): Observable<StewartAssystUser> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.telusUserForSolicitor.replace('{accountId}', accountId).replace('{solicitorId}', '' + solicitorId);
    return this.http.get(url)
    .map((res) => {
      return new StewartAssystUser(res[ 'StewartAssystUser' ]);
    });
  }

  validateUser(stewartAssystUser: StewartAssystUser): Observable<StewartAssystUser> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.validateTelusUser.replace('{accountId}', accountId);
    return this.http.post(url, JSON.stringify(stewartAssystUser))
    .map((res) => {
      return new StewartAssystUser(res[ 'StewartAssystUser' ]);
    });
  }

  deleteStewartAssystUnityUser(stewartAssystUser: StewartAssystUser): Observable<any> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.deleteStewartAssysteUser.replace('{userId}', '' + stewartAssystUser.id).replace('{accountId}', accountId);
    return this.http.delete(url);
  }

  getTelusWebUrl(userId: number, actionType: string = 'Profile', fileNumber ?: string, readOnlyAccess ?: boolean): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.getwebUrl.replace('{userId}', userId.toString()).replace('{accountId}', accountId) + `?actionType=${ actionType }`;
    if (fileNumber) {
      url += `&fileNumber=${ fileNumber }`;
    }
    if (readOnlyAccess) {
      url += '&readOnlyAccess=true';
    }
    return this.http.get(url)
    .map((res) => {
      return res[ 'StewartAssystWebUrl' ];
    });
  }

  getNewMortgageInstructions(solicitorIds: number[]): Observable<SolicitorMortgageInstruction[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.getNewMortgageInstructions.replace('{accountId}', accountId) + `?solicitorIds=${ solicitorIds }`;
    return this.http.get(url)
    .map((res) => {
      return (res[ 'StewartAssystMortgageInstructions' ]).map((item) => {
        return new SolicitorMortgageInstruction(item);
      });
    });
  }

  /**
   *
   * @param {string} status
   * @param {number} page
   * @param {number} perPage
   * @param {string} sort Optional parameter in form of "sortByFieldName|sortOrder", e.g "fileName|asc".
   * @return {Observable<StewartMortgageInstruction[]>}
   */
  getMortgageInstructionsFromUnity(status: string, page?: number, perPage?: number, sort?: string): Observable<StewartMortgageInstruction[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.unityMortgageInstructions.replace('{accountId}', accountId).replace('{status}', status);

    let params = '';
    if (page) {
      params += `page=${ page }&per_page=${ perPage }`;
    }
    if (sort) {
      params += `&sort=${ sort }`;
    }
    if (params.length > 0) {
      url = url + '?' + params;
    }
    return this.http.get(url)
    .map((res) => {
      return (res[ 'StewartAssystMortgageInstructions' ]).map((item) => {
        return new StewartMortgageInstruction(item);
      });
    });
  }

  exportMortgageInstructionsFromUnityToCSV(status: string): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.unityMortgageInstructionsCSVExport.replace('{accountId}', accountId).replace('{status}', status);
    let timeZoneOffset: string = moment().format('Z');
    url = url + `?${ SESSION_ID_REQ_PARAM }=${ sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) }&offset=${ this.http.encodePlusSign(timeZoneOffset) }`;
    window.open(this.http.encodePipe(this.http.normalizeUrl(url)), '_blank');
  }

  getMortgageInstructionsFromUnityAndExportCSV(status: string): Observable<StewartMortgageInstruction[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.unityMortgageInstructionsCSVExport.replace('{accountId}', accountId).replace('{status}', status);
    let timeZoneOffset: string = moment().format('Z');
    url = url + '?offset=' + timeZoneOffset;
    return this.http.get(url)
    .map((res) => {
      return (res[ 'StewartAssystMortgageInstructions' ]).map((item) => {
        return new StewartMortgageInstruction(item);
      });
    });
  }

  getStewartAssystUsers(): Observable<StewartAssystUser[]> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.getStewartUsers.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return (res[ 'StewartAssystUsers' ]).map((item) => {
        return new StewartAssystUser(item);
      });
    });
  }

  getDealData(solicitorId: number, dealId: string): Observable<StewartMortgageInstruction> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.getDealData.replace('{accountId}', accountId).replace('{solicitorId}', '' + solicitorId).replace('{dealId}', '' + dealId);
    return this.http.get(url)
    .map((res) => {
      return new StewartMortgageInstruction(res[ 'StewartAssystMortgageInstruction' ]);
    });
  }

  private findInDownloadedDocuments(document: StewartAssystDocument, downloadedDocuments: Document[]): Document {
    return _.find(downloadedDocuments, downloadedDocument => downloadedDocument.documentNumber == String(document.documentNumber)
      && downloadedDocument.documentVersionNumber == document.documentVersionNumber);
  }

  downloadDocument(matterId: number, dealId: string, documentNumber: number): Observable<StewartAssystDownloadDocResponse> {
    let url: string = telusApi.downloadDocument.replace('{matterId}', '' + matterId).replace('{dealId}', '' + dealId).replace('{documentNumber}',
      '' + documentNumber);
    return this.http.get(url)
    .map((res) => {
      return new StewartAssystDownloadDocResponse(res[ 'StewartAssystDownloadDocuments' ]);
    });
  }

  deleteAllDocuments(matterId: number, dealId: string): Observable<any> {
    let url: string = telusApi.deleteDocuments.replace('{matterId}', '' + matterId).replace('{dealId}', '' + dealId);
    return this.http.delete(url);
  }

  declineDeal(solicitorId: number, dealId: string): Observable<string> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.declineDeal.replace('{accountId}', accountId).replace('{solicitorId}', '' + solicitorId).replace('{dealId}', '' + dealId);
    return this.http.post(url, null)
    .map((res) => {
      return res[ 'StewartAssystDeletedDeal' ];
    });
  }

  acceptDeal(solicitorId: number, dealId: string, dealData: StewartAssystMortgageInstructionData): Observable<string> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.acceptDeal.replace('{accountId}', accountId).replace('{solicitorId}', '' + solicitorId).replace('{dealId}', '' + dealId);
    return this.http.post(url, dealData)
    .map((res) => {
      return res[ 'StewartAssystAcceptDeal' ];
    });
  }

  unlockMortgageInstruction(solicitorId: number, dealId: string) {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.unlockDeal.replace('{accountId}', accountId).replace('{solicitorId}', '' + solicitorId).replace('{dealId}', '' + dealId);
    return this.http.get(url)
    .map((res) => {
      return res;
    });
  }

  saveTelusFileData(matterId: number, mortgageId: number, stewartAssystUser: StewartAssystUser): Observable<string> {
    let url: string = telusApi.saveFileData.replace('{userId}', '' + stewartAssystUser.id).replace('{matterId}', '' + matterId).replace('{mortgageId}', '' + mortgageId);
    return this.http.get(url)
    .map((res) => {
      return res ? res.toString() : '';
    });
  }

  getAssystRealEstateSupportUrl(): Observable<string> {
    let url: string = telusApi.assystRealEstateSupportUrl;
    return this.http.get(url)
    .map((res) => {
      return res[ 'AssystRealEstateSupportUrl' ];
    });
  }

    getStewartTitleSupportUrl(): Observable<string> {
        let url: string = telusApi.stewartTitleSupportUrl;
        return this.http.get(url)
                   .map((res) => {
                       return res['StewartTitleSupportUrl'];
                   });
}
    addNotifications(errorService : ErrorService , empMortgage : Mortgage  , matter : Matter , currencyPipe: CurrencyPipe, isMatterOpening: boolean): void {
        let mortgageIndex = matter.mortgages.findIndex(item => item.id == empMortgage.id);
        errorService.removeDpThirdPartyNotification('notification.stewartAssyst.purchasePrice.'+empMortgage.id);
        errorService.removeDpThirdPartyNotification('notification.stewartAssyst.noteIndicator.'+empMortgage.id);
        errorService.removeDpThirdPartyNotification('notification.stewartAssyst.documentDownload.'+empMortgage.id);
        errorService.removeDpThirdPartyNotificationByPartialKey('notification.stewartAssyst.mortgage.*');
        if (empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData &&
            empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice) {
            if (!matter.matterPropertyWithCondo ||
                (empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice != matter.matterPropertyWithCondo.purchasePrice)){
                let sellingPrice = currencyPipe.transform(empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.purchasePrice,
                    'CAD', 'symbol', '1.2-2').replace("CA", "");
                ;
                let purchasePrice = currencyPipe.transform(matter.matterPropertyWithCondo.purchasePrice, 'CAD', 'symbol', '1.2-2').replace("CA", "");
                ;
                errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.purchasePrice.' +
                    empMortgage.id, 'The purchase price ' + purchasePrice + ' does not match the purchase price of ' + sellingPrice +
                    ' specified in the mortgage instructions',
                    'Unity® Lender Centre - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION" , null,
                    null,null ,null , empMortgage.id ));
            }
        }
        if (empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData &&
            empMortgage.stewartAssystMortgageInstruction.mortgageInstructionData.noteIndicator) {

            errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.noteIndicator.' +
                empMortgage.id, 'New note(s) available',
                'Unity® Lender Centre - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION" , null ,
                null,'M40004' ,null , empMortgage.id));
        }
        if (empMortgage.stewartAssystMortgageInstruction && empMortgage.stewartAssystMortgageInstruction.areDocumentsAvailableToDownload
            && empMortgage.stewartAssystMortgageInstruction.hasOutstandingDocumentsForDownload) {
            errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.documentDownload.' +
                empMortgage.id, 'Document(s) are available for download',
                'Unity® Lender Centre - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION" , null ,
                null,'M40003' ,null , empMortgage.id, null, null, empMortgage));
        }
        if (!empMortgage.isStandardChargeTermNo) {
            errorService.addDpThirdPartyNotification(DPError.createCustomDPError('notification.stewartAssyst.standardChargeTermNo.' +
                empMortgage.id, 'Please confirm Standard Charge Term No. for registration.',
                'Unity® Lender Centre - ' + Utils.getOrdinal(mortgageIndex + 1) + ' Mortgage', "NOTIFICATION", null ,null, null ,null , empMortgage.id));
        }

        //Telus only supports up to 9 pins
        if (!isMatterOpening) {
            if (matter.isPropertyCondo()) {
                if (!matter.matterPropertyWithCondo.isCondominiumExpenseEmpty() && matter.matterPropertyWithCondo.condominiumExpenses.length > telusConfigurations.MAX_PINS) {
                    errorService.addDpThirdPartyNotification(DPError.createDPError(`notification.stewartAssyst.mortgage.${mortgageIndex+1}.tooManyPinsLC`, null, null, null, null, empMortgage.id));
                }
            } else if (matter.matterProperties.length > telusConfigurations.MAX_PINS) {
                errorService.addDpThirdPartyNotification(DPError.createDPError(`notification.stewartAssyst.mortgage.${mortgageIndex+1}.tooManyPinsLC`, null, null, null, null, empMortgage.id));
            }
        }

  }

  getForgotPasswordUrl(): Observable<String> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.forgotPasswordUrl.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return res[ 'StewartAssystForgotPasswordUrl' ];
    });
  }

  deleteStewartAssystDeal(solicitorId: number, dealId: string): Observable<any> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.deleteStewartAssysteDeal.replace('{solicitorId}', '' + solicitorId).replace('{accountId}', accountId).replace('{dealId}', dealId);
    return this.http.delete(url);
  }

  submitPayoutRequest(solicitorId: number, matterId: number, mortgageId: number, payoutRequestData: PayoutRequestData): Observable<PayoutStatement> {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = telusApi.submitPayout.replace('{solicitorId}', '' + solicitorId).replace('{matterId}', '' + matterId).replace('{mortgageId}', '' + mortgageId);
    return this.http.post(url, payoutRequestData)
    .map((res) => {
      return new PayoutStatement(res[ 'StewartAssystPayoutStatement' ]);
    });
  }

  parsePayoutData(matterId: number, thirdPartyDocumentId: number): Observable<AssystPayoutData> {
    let url: string = telusApi.parsePayoutData.replace('{matterId}', '' + matterId).replace('{thirdPartyDocumentId}', '' + thirdPartyDocumentId);
    return this.http.post(url, null)
    .map((res) => {
      return new AssystPayoutData(res[ 'ImportAssystPayoutData' ]);
    });
  }

  fetchLatestPayoutStatement(matterId: number, mortgageId: number): Observable<PayoutStatement> {
    let url: string = telusApi.latestPayoutStatement.replace('{matterId}', '' + matterId).replace('{mortgageId}', '' + mortgageId);
    return this.http.get(url)
    .map((res) => {

      return res[ 'StewartAssystPayoutStatement' ] ? new PayoutStatement(res[ 'StewartAssystPayoutStatement' ]) : null;
    });
  }

  fetchPayoutStatements(matterId: number, mortgageId: number): Observable<PayoutStatement[]> {
    let url: string = telusApi.payoutStatements.replace('{matterId}', '' + matterId).replace('{mortgageId}', '' + mortgageId);
    return this.http.get(url)
    .map((res) => {
      return res[ 'StewartAssystPayoutStatements' ].map((item) => {
        return new PayoutStatement(item);
      });
    });
  }

  cancelPayoutRequest(payoutRequestData: PayoutRequestData, solicitorId: number, matterId: number, payoutStatementId: number): Observable<PayoutStatement> {
    let url: string = telusApi.cancelPayoutRequest
    .replace('{solicitorId}', '' + solicitorId)
    .replace('{matterId}', '' + matterId)
    .replace('{payoutStatementId}', '' + payoutStatementId);
    return this.http.post(url, payoutRequestData)
    .map((res) => {
      return new PayoutStatement(res[ 'StewartAssystPayoutStatement' ]);
    });
  }

}
