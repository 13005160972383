import {TrustLedger} from '../trust-ledger/trust-ledger';
import {SoaConfig} from '../soa/soa-config';
import {TrustLedgerConfig, TrustLedgerExpenseType} from '../trust-ledger/config/trust-ledger-config';
import {AccountingItemKeys, SoaConstants} from '../soa/soa-constants';
import {DisbursementConfig, DisbursementSubjectToType} from '../../shared-main/statement-account/disbursement-config/disbursement-config';
import {MatterType} from '../../matters/shared/matter-specific-type';
import {GeneralLedgerAccountCode} from './general-ledger-account-code';
import {GeneralLedgerAccountExplanation} from './general-ledger-account-explanation';
import * as _ from 'lodash';
import {AccountNationalSetting} from './general-ledger-account-list';
import {CosmolexExpenseCode} from './cosmolex-expense-code';
import {PROVINCE_CODES} from '../../matters/shared/user-province';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {EsiLawAccountCode} from './esiLaw-account-code';
import {EsiLawAccountExplanation} from './esiLaw-account-explanation';

export type elrsafeesTypeType = 'MANUAL_ENTRY' | 'CALCULATE_AUTOMATICALLY' | 'NA';
export type StatementConfigContext = 'ACCOUNT' | 'PROJECT';
export type RelatedPosition = 'ABOVE' | 'AFTER' | 'FIRST';

export class StatementConfig extends BaseEntity {

  id: number;
  amountOfTitleInsurancePremium: string;
  titleMortgageRegFees: string;
  mortgageProcessingFee: string;
  landTransferTax: string;
  ontarioTorontoLttType: string;
  nrstType: string;
  dirty: boolean;
  statementOfAccount: SoaConfig;
  trustLedger: TrustLedger;
  elrsafeesType: elrsafeesTypeType;
  snapshot: boolean;
  accountNationalSetting: AccountNationalSetting;
  provinceCode: string;
  hstOnSearchAndWritCosts: string;

  constructor(soa?: StatementConfig) {
    super(soa);
    if (soa) {
      this.accountNationalSetting = new AccountNationalSetting(soa.accountNationalSetting);
      this.statementOfAccount = new SoaConfig(soa.statementOfAccount);
      this.statementOfAccount.accountNationalSetting = this.accountNationalSetting;//Setting accountNationalSetting reference into SOA
      this.trustLedger = new TrustLedger(soa.trustLedger);
      this.snapshot = false;

    }
  }

  get esiLawAccountCodes(): EsiLawAccountCode[] {
    return this.accountNationalSetting && this.accountNationalSetting.esiLawAccountCodes;
  }

  set esiLawAccountCodes(esiLawAccountCodes: EsiLawAccountCode[]) {
    this.accountNationalSetting && (this.accountNationalSetting.esiLawAccountCodes = esiLawAccountCodes);
  }

  get esiLawAccountExplanation(): EsiLawAccountExplanation[] {
    return this.accountNationalSetting && this.accountNationalSetting.esiLawAccountExplanations;
  }

  set esiLawAccountExplanation(esiLawAccountExplanation: EsiLawAccountExplanation[]) {
    this.accountNationalSetting && (this.accountNationalSetting.esiLawAccountExplanations = esiLawAccountExplanation);
  }

  get generalLedgerAccountCodes(): GeneralLedgerAccountCode[] {
    return this.accountNationalSetting.generalLedgerAccountCodes;
  }

  set generalLedgerAccountCodes(generalLedgerAccountCodes: GeneralLedgerAccountCode[]) {
    this.accountNationalSetting.generalLedgerAccountCodes = generalLedgerAccountCodes;
  }

  get generalLedgerAccountExplanations(): GeneralLedgerAccountExplanation[] {
    return this.accountNationalSetting.generalLedgerAccountExplanations;
  }

  set generalLedgerAccountExplanations(generalLedgerAccountExplanations: GeneralLedgerAccountExplanation[]) {
    this.accountNationalSetting.generalLedgerAccountExplanations = generalLedgerAccountExplanations;
  }

  get cosmolexExpenseCodes(): CosmolexExpenseCode[] {
    return this.accountNationalSetting.cosmolexExpenseCodes;
  }

  set cosmolexExpenseCodes(cosmolexExpenseCodes: CosmolexExpenseCode[]) {
    this.accountNationalSetting.cosmolexExpenseCodes = cosmolexExpenseCodes;
  }

  isLTTSetForSoa(): boolean {
    return (this.landTransferTax === 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE' || this.landTransferTax === 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isLTTSetForTrustLedger(): boolean {
    return (this.landTransferTax == 'INTO_TRUST_LEDGER_AS_F9_VALUE' || this.landTransferTax == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  isNrstSetForSoa(): boolean {
    return (this.nrstType === 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE' || this.nrstType === 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isOntarioTorontoLTTOneEntry(): boolean {
    return (this.ontarioTorontoLttType == 'SHOW_AS_ONE_ENTRY');
  }

  isLTTForSoaAuto(): boolean {
    return (this.landTransferTax == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isLTTForTrustLedgerAuto(): boolean {
    return (this.landTransferTax == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  isNRSTForSoaAuto(): boolean {
    return (this.nrstType == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isNRSTForTrustLedgerAuto(): boolean {
    return (this.nrstType == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  isNRSTForTrustLedgerAsF9Value(): boolean {
    return (this.nrstType == 'INTO_TRUST_LEDGER_AS_F9_VALUE');
  }

  isEMPForSoa(): boolean {
    return (this.mortgageProcessingFee == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE' || this.mortgageProcessingFee == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isEMPForSoaAuto(): boolean {
    return (this.mortgageProcessingFee == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isEMPForSoaF9(): boolean {
    return (this.mortgageProcessingFee == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE');
  }

  isEMPForTrustLedgerAuto(): boolean {
    return (this.mortgageProcessingFee == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  isEMPForTrustLedgerF9(): boolean {
    return (this.mortgageProcessingFee == 'INTO_TRUST_LEDGER_AS_F9_VALUE');
  }

  isTitleInsuranceForSoa(): boolean {
    return (this.amountOfTitleInsurancePremium == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE' || this.amountOfTitleInsurancePremium == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isTitleInsuranceForSoaAuto(): boolean {
    return (this.amountOfTitleInsurancePremium == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isTitleInsuranceForTrustLedgerAuto(): boolean {
    return (this.amountOfTitleInsurancePremium == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  isTitleMortgageRegFeesForSoa(): boolean {
    return (this.titleMortgageRegFees == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE' || this.titleMortgageRegFees == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isTitleMortgageRegFeesForTrustLedger(): boolean {
    return (this.titleMortgageRegFees == 'INTO_TRUST_LEDGER_AS_F9_VALUE' || this.titleMortgageRegFees == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  isTitleMortgageRegFeesForSoaAuto(): boolean {
    return (this.titleMortgageRegFees == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY');
  }

  isTitleMortgageRegFeesForTrustLedgerAuto(): boolean {
    return (this.titleMortgageRegFees == 'INTO_TRUST_LEDGER_AUTOMATICALLY');
  }

  getLTTKey(): number {
    if (this.isProvinceON()) {
      return AccountingItemKeys.ONTARIO_LTT_KEY;
    }
    if (this.isProvinceNB()) {
      return AccountingItemKeys.NB_LTT_KEY;
    }
    if (this.isProvinceNS()) {
      return AccountingItemKeys.NS_DTT_KEY;
    }
    return 0;
  }

  getTrustLedgerLTTExpenseDescription(): string {
    if (this.isProvinceON()) {
      return SoaConstants.PAID_NO_HST_ONTARIO_LTT;
    }
    if (this.isProvinceNB()) {
      return SoaConstants.PAID_NB_LAND_TRANSFER_TAX;
    }
    if (this.isProvinceNS()) {
      return SoaConstants.PAID_DEED_TRANSFER_TAX;
    }
    return '';
  }

  getSoALTTNameOfDisbursement(): string {
    if (this.isProvinceON()) {
      return SoaConstants.NO_HST_ONTARIO_LTT;
    }
    if (this.isProvinceNB()) {
      return SoaConstants.LAND_TRANSFER_TAX;
    }
    if (this.isProvinceNS()) {
      return SoaConstants.DEED_TRANSFER_TAX;
    }
    return '';
  }

  /**
   *  For provinces that have this.landTransferTax = 'NA' this function will have no effect
   */
  createLTT(): void {
    let purchaseDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(purchaseDisbursement => purchaseDisbursement.code == AccountingItemKeys[ this.getLTTKey() ]);
    let trustLedgerIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ this.getLTTKey() ] && tl.matterType === 'PURCHASE');
    let validProvince: boolean = [ PROVINCE_CODES.ONTARIO, PROVINCE_CODES.NEW_BRUNSWICK, PROVINCE_CODES.NOVA_SCOTIA ].indexOf(this.provinceCode) > -1;
    if (this.isLTTSetForTrustLedger()) {
      if (purchaseDisbursementIndex > -1) {
        this.statementOfAccount.disbursementsConfig.splice(purchaseDisbursementIndex, 1);
      }
      if (trustLedgerIndex < 0 && validProvince) {
        let trustLedgerConfig = new TrustLedgerConfig();
        //trustLedgerConfig.identifier = -Date.now() + Number(this.trustLedger.trustLedgersConfig.length);
        trustLedgerConfig.reservedFor = 'LTT';
        trustLedgerConfig.expenseDescription = this.getTrustLedgerLTTExpenseDescription();
        trustLedgerConfig.readOnly = true;
        trustLedgerConfig.code = AccountingItemKeys[ this.getLTTKey() ];
        trustLedgerConfig.f9DefaultAmount = 0;
        trustLedgerConfig.calculated = true;
        trustLedgerConfig.expenseType = 'Expenditure';
        trustLedgerConfig.matterType = 'PURCHASE';
        this.trustLedger.trustLedgersConfig.push(trustLedgerConfig);
      }
      this.statementOfAccount.removeRetailSalesTaxRow();
    } else if (this.isLTTSetForSoa()) {

      if (trustLedgerIndex > -1) {
        this.trustLedger.trustLedgersConfig.splice(trustLedgerIndex, 1);
      }
      if (purchaseDisbursementIndex < 0 && validProvince) {
        let purchaseDisbursement = new DisbursementConfig();
        // purchaseDisbursement.identifier = -Date.now() + Number(this.statementOfAccount.disbursementsConfig.length);
        purchaseDisbursement.nameOfDisbursement = this.getSoALTTNameOfDisbursement();
        purchaseDisbursement.readOnly = false;
        purchaseDisbursement.f9DefaultAmount = 0;
        purchaseDisbursement.code = AccountingItemKeys[ this.getLTTKey() ];
        purchaseDisbursement.subjectToHstType = 'NOT_SUBJECT_TO_HST';
        purchaseDisbursement.deleted = false;
        purchaseDisbursement.calculated = true;
        purchaseDisbursement.matterType = 'PURCHASE';
        this.statementOfAccount.disbursementsConfig.push(purchaseDisbursement);
      }
      if (!this.isProvinceNS() && !this.isProvinceNB()) {
        this.statementOfAccount.addRetailSalesTaxRow();
      }
    }
  }

  /**
   *  For provinces that have this.nrstType = 'NA' this function will have no effect
   */

  createNRST(): void {
    if (this.isProvinceNS() || this.isProvinceNB()) {
      //no NRST for NS, NB
      return;
    }
    let purchaseDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(purchaseDisbursement => purchaseDisbursement.code == AccountingItemKeys[ AccountingItemKeys.NRST ]);
    let trustLedgerIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.NRST ] && tl.matterType == 'PURCHASE');

    if (this.nrstType == 'INTO_TRUST_LEDGER_AUTOMATICALLY' || this.nrstType == 'INTO_TRUST_LEDGER_AS_F9_VALUE') {
      if (purchaseDisbursementIndex > -1) {
        this.statementOfAccount.disbursementsConfig.splice(purchaseDisbursementIndex, 1);
      }
      if (trustLedgerIndex < 0) {
        let trustLedgerConfig = new TrustLedgerConfig();
        //trustLedgerConfig.identifier = -Date.now() + Number(this.trustLedger.trustLedgersConfig.length);
        trustLedgerConfig.reservedFor = 'NRST';
        trustLedgerConfig.expenseDescription = SoaConstants.NRST_DESC;
        trustLedgerConfig.readOnly = true;
        trustLedgerConfig.code = AccountingItemKeys[ AccountingItemKeys.NRST ];
        trustLedgerConfig.f9DefaultAmount = 0;
        trustLedgerConfig.calculated = true;
        trustLedgerConfig.expenseType = 'Expenditure';
        trustLedgerConfig.matterType = 'PURCHASE';
        this.trustLedger.trustLedgersConfig.push(trustLedgerConfig);
      }
    } else if (this.nrstType == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY' || this.nrstType == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE') {
      if (trustLedgerIndex > -1) {
        this.trustLedger.trustLedgersConfig.splice(trustLedgerIndex, 1);
      }
      if (purchaseDisbursementIndex < 0) {
        let purchaseDisbursement = new DisbursementConfig();
        //purchaseDisbursement.identifier = -Date.now() + Number(this.statementOfAccount.disbursementsConfig.length);
        purchaseDisbursement.nameOfDisbursement = SoaConstants.NRST_DESC;
        purchaseDisbursement.readOnly = true;
        purchaseDisbursement.f9DefaultAmount = 0;
        purchaseDisbursement.subjectToHstType = 'NOT_SUBJECT_TO_HST';
        purchaseDisbursement.deleted = false;
        purchaseDisbursement.code = AccountingItemKeys[ AccountingItemKeys.NRST ];
        purchaseDisbursement.calculated = true;
        purchaseDisbursement.matterType = 'PURCHASE';
        this.statementOfAccount.disbursementsConfig.push(purchaseDisbursement);
      }
    }
  }

  createTitleInsurance(): void {

    if (this.provinceCode == PROVINCE_CODES.NOVA_SCOTIA) {
      //based on the amended requirement
      //even the option for insert item into TrustLedger/Soa is available for NS at General Page (Edmund confirms that leave the Insert TI dropdown at General page)
      //we will not insert the item into either TrustLedger Config or Soa Config
      //existing already inserted items (TrustLedger/Soa) will be cleanup by DB script
      return;
    }

    let purchaseDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && disb.matterType === 'PURCHASE');
    let mortgageDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && disb.matterType === 'MORTGAGE');
    let purchaseTrustLedgerIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && tl.matterType == 'PURCHASE');
    let mortgageTrustLedgerIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && tl.matterType == 'MORTGAGE');

    if (this.amountOfTitleInsurancePremium == 'INTO_TRUST_LEDGER_AUTOMATICALLY' || this.amountOfTitleInsurancePremium == 'INTO_TRUST_LEDGER_AS_F9_VALUE') {
      if (purchaseDisbursementIndex > -1) {
        this.statementOfAccount.disbursementsConfig.splice(purchaseDisbursementIndex, 1);
        mortgageDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && disb.matterType === 'MORTGAGE');
      }
      if (mortgageDisbursementIndex > -1) {
        this.statementOfAccount.disbursementsConfig.splice(mortgageDisbursementIndex, 1);
      }
      if (purchaseTrustLedgerIndex < 0) {
        this.addTrustLedgerTitleInsurance('PURCHASE');
      }
      if (mortgageTrustLedgerIndex < 0) {
        this.addTrustLedgerTitleInsurance('MORTGAGE');
      }
    } else if (this.amountOfTitleInsurancePremium == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY' || this.amountOfTitleInsurancePremium == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE') {
      if (purchaseTrustLedgerIndex > -1) {
        this.trustLedger.trustLedgersConfig.splice(purchaseTrustLedgerIndex, 1);
        mortgageTrustLedgerIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && tl.matterType == 'MORTGAGE');
      }
      if (mortgageTrustLedgerIndex > -1) {
        this.trustLedger.trustLedgersConfig.splice(mortgageTrustLedgerIndex, 1);
      }
      if (purchaseDisbursementIndex < 0) {
        this.addTitleInsuranceDisbursement('PURCHASE');
      }
      if (mortgageDisbursementIndex < 0) {
        this.addTitleInsuranceDisbursement('MORTGAGE');
      }
    }
  }

  createTitleMortgageRegFeesNBorNS(): void {
    if (this.isProvinceNB() || this.isProvinceNS()) {
      let newConfigDisplayName: string;
      if (this.isTitleMortgageRegFeesForTrustLedger()) { // into Trust Ledger

        const isItemCalculated: boolean = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');

        // Registration of Charge
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.PAID_FOR_REGISTRATION_OF_MORTGAGE : SoaConstants.PAID_RECORDING_A_MORTGAGE_FEE;
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'PURCHASE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('PURCHASE', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MTG',
          newConfigDisplayName, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'AFTER', isItemCalculated);

        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.PAID_FOR_REGISTRATION_OF_CHARGE : SoaConstants.PAID_RECORDING_A_MORTGAGE_FEE;
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MTG',
          newConfigDisplayName, 'Expenditure',
          AccountingItemKeys.PAID_TO_CLIENT, 'AFTER', isItemCalculated);

        // Registration of Transfer
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.PAID_FOR_REGISTRATION_OF_TRANSFER : SoaConstants.PAID_RECORDING_A_DEED_FEE;
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'PURCHASE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('PURCHASE', AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'REG\'n',
          newConfigDisplayName, 'Expenditure',
          AccountingItemKeys.PAID_TO_VENDOR, 'AFTER', isItemCalculated);

        // Register Discharge of Mortgage
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.PAID_TO_REGISTER_DISCHARGE : SoaConstants.PAID_RECORDING_A_DISCHARGE_FEE;
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
          newConfigDisplayName, 'Expenditure',
          AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
          newConfigDisplayName, 'Expenditure',
          AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

        //Register Discharge of other Encumbrances (RDOE), position need to after #Register Discharge of Mortgage
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'Register Other encumbrances',
          SoaConstants.PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'Register Other encumbrances',
          SoaConstants.PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

        // Register VTB Mortgage

        //any change of the display name from UI, may need pair with DB script to cleanup the existing config value
        const vtbItemDisplayName = this.isProvinceNS() ? SoaConstants.PAID_TO_RECORD_A_VTB_MORTGAGE_FEE : SoaConstants.PAID_TO_REGISTER_VTB_MORTGAGE;
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY, 'VTB',
          vtbItemDisplayName, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'AFTER', isItemCalculated);

        //Discharge Configurations
        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'DISCHARGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('DISCHARGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
          newConfigDisplayName, 'Expenditure',
          AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'DISCHARGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('DISCHARGE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'Register Other encumbrances',
          SoaConstants.PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

      } else { // into Statement of Account

        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.REGISTRATION_OF_CHARGE : SoaConstants.RECORDING_A_MORTGAGE;
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY,
          newConfigDisplayName, AccountingItemKeys.NRST, 'ABOVE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MORTGAGE');

        // Registration of Transfer
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.REGISTRATION_OF_TRANSFER : SoaConstants.RECORDING_A_DEED;
        this.addNewSoaConfigItem('PURCHASE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY,
          newConfigDisplayName, AccountingItemKeys.COMPLIANCE_ITEMS, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'PURCHASE');

        // 'Registration of Mortgage'(NS)/Registration of Charge(ON) added after 'Registration of Transfer'
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.REGISTRATION_OF_MORTGAGE : SoaConstants.RECORDING_A_MORTGAGE;
        this.addNewSoaConfigItem('PURCHASE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY,
          newConfigDisplayName, AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'PURCHASE');

        // Register Discharge of Mortgage
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.REGISTER_DISCHARGE : SoaConstants.RECORDING_A_DISCHARGE;
        this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, newConfigDisplayName,
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'SALE');

        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.REGISTRATION_OF_DISCHARGE : SoaConstants.RECORDING_A_DISCHARGE;
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, newConfigDisplayName,
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'MORTGAGE');

        //Register Discharge of other Encumbrances (RDOE), position need to after #Register Discharge of Mortgage
        this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST',
          AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES,
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'SALE');
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST',
          AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES,
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MORTGAGE');

        // Register VTB Mortgage
        this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY,
          'Register VTB Mortgage', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY, 'SALE');

        //Discharge Matter Type Cofigurations
        // Register Discharge of Mortgage
        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        newConfigDisplayName = (this.isProvinceNB()) ? SoaConstants.REGISTER_DISCHARGE : SoaConstants.RECORDING_A_DISCHARGE;
        this.addNewSoaConfigItem('DISCHARGE', 'NOT_SUBJECT_TO_HST',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, newConfigDisplayName,
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'DISCHARGE');

        // Register Discharge of Other Encumberances
        this.addNewSoaConfigItem('DISCHARGE', 'NOT_SUBJECT_TO_HST',
          AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES,
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'DISCHARGE');
      }
    }
  }

  //ToDo Refactoring of this method is required as it insert rows for all Matter Types (PSM)
  createTitleMortgageRegFeesONorAB(): void {

    if (this.isTitleMortgageRegFeesForTrustLedger()) { //'INTO_TRUST_LEDGER_AS_F9_VALUE' , 'INTO_TRUST_LEDGER_AUTOMATICALLY'

      //E-Reg Service Charge
      if (this.isProvinceON()) {
        this.removeSoaDisbursementConfig(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY, 'PURCHASE', 'SUBJECT_TO_HST');
        this.removeSoaDisbursementConfig(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY, 'SALE', 'SUBJECT_TO_HST');
        this.removeSoaDisbursementConfig(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY, 'MORTGAGE', 'SUBJECT_TO_HST');
        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        this.removeSoaDisbursementConfig(AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY, 'DISCHARGE', 'SUBJECT_TO_HST');
        //no pair action for TL
      }

      const isItemCalculated: boolean = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');

      //Registration of Transfer(ON) , Register Transfer of Land(AB) for P
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'PURCHASE', 'NOT_SUBJECT_TO_HST');
      //pair action, also need to add related config into TrustLeger: Paid for Registration of Transfer for ON, Paid for Register Transfer of Land for AB
      this.addNewTrustLedgerConfigItem('PURCHASE', AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'REG\'n',
        (this.isProvinceAB() ? SoaConstants.PAID_TO_REGISTER_TRANSFER_OF_LAND : SoaConstants.PAID_FOR_REGISTRATION_OF_TRANSFER), 'Expenditure',
        AccountingItemKeys.PAID_TO_VENDOR, 'AFTER', isItemCalculated);

      //Registration of Charge for ON, Register Mortgage for AB, TL position after #Registration of Transfer
      if (this.isProvinceON()) {
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'PURCHASE', 'NOT_SUBJECT_TO_HST');
        //pair action in TL
        this.addNewTrustLedgerConfigItem('PURCHASE', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MTG',
          SoaConstants.PAID_FOR_REGISTRATION_OF_CHARGE, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'AFTER', isItemCalculated);

      } else if (this.isProvinceAB()) {
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'PURCHASE', 'NOT_SUBJECT_TO_HST');
        //pair action in TL
        this.addNewTrustLedgerConfigItem('PURCHASE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'MTG',
          SoaConstants.PAID_TO_REGISTER_MORTGAGE, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'AFTER', isItemCalculated);
      }

      //Register Discharge of Mortgage
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
      //pair acton in TL, add REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
        SoaConstants.PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE, 'Expenditure',
        AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

      //need to after #Register Discharge of Mortgage, position after REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      if (this.isProvinceAB()) {
        //Register Vendor's Lien Caveat, AB Only
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
        //pair acton in TL
        this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY, 'Register Lien Caveat',
          'Paid to Register Vendor\'s Lien Caveat', 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

        //Discharge Vendor's Lien Caveat, AB ony
        this.removeSoaDisbursementConfig(AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
        //pair acton in TL
        this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY, 'Discharge Lien Caveat',
          'Paid to Discharge Vendor\'s Lien Caveat', 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY, 'AFTER', isItemCalculated);
      }

      //Register Discharge of other Encumbrances (RDOE) for Sale, position need to after #Register Discharge of Mortgage
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
      //pair action in TL
      this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'Register Other encumbrances',
        'Paid to Register Discharge of other Encumbrances', 'Expenditure',
        AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY, 'AFTER', isItemCalculated);

      //Register VTB Mortgage, ON only
      if (this.isProvinceON()) {
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY, 'SALE', 'NOT_SUBJECT_TO_HST');
        //pair acton in TL
        this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY, 'VTB',
          SoaConstants.PAID_TO_REGISTER_VTB_MORTGAGE, 'Expenditure',
          AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);
      }

      //Registration of Charge (ON) , Register Mortgage (AB)  for M
      if (this.isProvinceON()) {
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MTG',
          'Paid for Registration of Charge', 'Expenditure',
          AccountingItemKeys.PAID_TO_CLIENT, 'AFTER', isItemCalculated);//pair action in TL

      } else if (this.isProvinceAB()) {
        this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
        this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'MTG',
          'Paid to Register Mortgage', 'Expenditure',
          AccountingItemKeys.PAID_TO_CLIENT, 'AFTER', isItemCalculated);//pair acton in TL
      }

      //Registration of Discharge (ON), Register Discharge of Mortgage (AB)  for M , with same Key: REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
      //pair acton in TL
      this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
        (this.isProvinceON() ? 'Paid for Registration of Discharge' : 'Paid to Register Discharge of Mortgage'), 'Expenditure',
        AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

      //Register Discharge of other Encumbrances (RDOE) for Mortgage, position need to after key: REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MORTGAGE', 'NOT_SUBJECT_TO_HST');
      //pair action in TL
      this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MTG',
        'Paid to Register Discharge of other Encumbrances', 'Expenditure',
        AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

      //Registration of Discharge (ON), Register Discharge of Mortgage (AB)  for DISCHARGE , with same Key: REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'DISCHARGE', 'NOT_SUBJECT_TO_HST');
      //pair acton in TL
      this.addNewTrustLedgerConfigItem('DISCHARGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
        (this.isProvinceON() ? 'Paid for Registration of Discharge' : 'Paid to Register Discharge of Mortgage'), 'Expenditure',
        AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

      //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
      //Register Discharge of other Encumbrances (RDOE) for Discharge, position need to after key: REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      this.removeSoaDisbursementConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'DISCHARGE', 'NOT_SUBJECT_TO_HST');
      //pair action in TL
      this.addNewTrustLedgerConfigItem('DISCHARGE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MTG',
        'Paid to Register Discharge of other Encumbrances', 'Expenditure',
        AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

    } else {// 'Into Statement of Account - automatically' or 'Into Statement of Account - as an F9 value' (default)

      //E-Reg Service Charge
      if (this.isProvinceON()) {
        //add 'E-Reg Service Charge' to PSM -  (Subject to HST), no paired TL action needed, Note: E-Reg Service position is after Software Transaction Charge in Statement of Account.
        this.addSOAERegServiceCharge('PURCHASE', 'SUBJECT_TO_HST');
        this.addSOAERegServiceCharge('SALE', 'SUBJECT_TO_HST');
        this.addSOAERegServiceCharge('MORTGAGE', 'SUBJECT_TO_HST');
        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        this.addSOAERegServiceCharge('DISCHARGE', 'SUBJECT_TO_HST');
      }

      //Registration of Transfer(ON) , Register Transfer of Land(AB) for P
      this.addNewSoaConfigItem('PURCHASE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY,
        (this.isProvinceAB() ? SoaConstants.REGISTER_TRANSFER_OF_LAND : SoaConstants.REGISTRATION_OF_TRANSFER),
        AccountingItemKeys.COMPLIANCE_ITEMS, 'AFTER');
      //as a pair action, also need to remove related config from TrustLedger: Paid for Registration of Transfer for ON/Paid for Register Transfer of Land for AB
      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'PURCHASE');//pair action

      //Registration of Charge for ON, Register Mortgage for AB
      if (this.isProvinceON()) {
        this.addNewSoaConfigItem('PURCHASE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY,
          SoaConstants.REGISTRATION_OF_CHARGE, AccountingItemKeys.NRST, 'ABOVE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'PURCHASE');//pair action
      } else if (this.isProvinceAB()) {
        this.addNewSoaConfigItem('PURCHASE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY,
          SoaConstants.REGISTER_MORTGAGE, AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'PURCHASE');//pair action
      }

      //Discharge Vendor's Lien Caveat, for AB only, need to be insert before  #Register Discharge of Mortgage, since #Register Discharge of Mortgage is inserted after Discharge Vendor's Lien Caveat
      if (this.isProvinceAB()) {
        //Register Vendor's Lien Caveat, AB Only
        this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY,
          'Register Vendor\'s Lien Caveat', AccountingItemKeys.COMPLIANCE_ITEMS, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY, 'SALE');//pair action

        //Discharge Vendor's Lien Caveat, AB ony
        this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY,
          'Discharge Vendor\'s Lien Caveat', AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY, 'SALE');//pair action
      }

      //Register Discharge of Mortgage
      this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST',
        AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, SoaConstants.REGISTRATION_OF_MORTGAGE_DISCHARGE,
        (this.isProvinceON() ? AccountingItemKeys.COMPLIANCE_ITEMS : AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY), 'AFTER', false, 0, this.isELRSAFeesTypeAutomaticallyCalculated());
      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'SALE');//pair action

      //Register Discharge of other Encumbrances (RDOE), position need to after #Register Discharge of Mortgage
      this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY,
        SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', false, 0, this.isELRSAFeesTypeAutomaticallyCalculated());
      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'SALE');//pair action

      //Register VTB Mortgage, ON only for S
      if (this.isProvinceON()) {
        this.addNewSoaConfigItem('SALE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY,
          'Register VTB Mortgage', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', false, 0, this.isELRSAFeesTypeAutomaticallyCalculated());
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY, 'SALE');//pair action
      }

      //Registration of Charge (ON) , Register Mortgage (AB)  for M
      if (this.isProvinceON()) {
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY,
          SoaConstants.REGISTRATION_OF_CHARGE, AccountingItemKeys.COMPLIANCE_ITEMS, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'MORTGAGE');//pair action
      } else if (this.isProvinceAB()) {
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY,
          SoaConstants.REGISTER_MORTGAGE, AccountingItemKeys.COMPLIANCE_ITEMS, 'AFTER');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'MORTGAGE');//pair action
      }

      //Registration of Discharge (ON), Register Discharge of Mortgage (AB)  for M , with same Key: REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      //need to insert after  #Registration of Charge (ON) , Register Mortgage (AB), based on position after
      if (this.isProvinceON()) {
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY,
          'Registration of Discharge', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'AFTER');
      } else if (this.isProvinceAB()) {
        this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY,
          'Register Discharge of Mortgage', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'AFTER');
      }
      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'MORTGAGE');//pair action
      this.addNewSoaConfigItem('MORTGAGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY,
        SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');

      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MORTGAGE');//pair action

      //For DISCHARGE matter Type
      //Registration of Discharge (ON), Register Discharge of Mortgage (AB)  for D , with same Key: REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY
      //need to insert after  #Registration of Charge (ON) , Register Mortgage (AB), based on position after
      //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
      if (this.isProvinceON()) {
        this.addNewSoaConfigItem('DISCHARGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY,
          'Registration of Discharge', AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY, 'AFTER');
      } else if (this.isProvinceAB()) {
        this.addNewSoaConfigItem('DISCHARGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY,
          'Register Discharge of Mortgage', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY, 'AFTER');
      }
      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'DISCHARGE');//pair action

      this.addNewSoaConfigItem('DISCHARGE', 'NOT_SUBJECT_TO_HST', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY,
        SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER');

      this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'DISCHARGE');//pair action

    }
  }

  private removeSoaDisbursementConfig(disbursementKey: AccountingItemKeys, matterType: MatterType, subjectToHstType: DisbursementSubjectToType) {
    if (disbursementKey && matterType && Array.isArray(this.disbursementsConfigs)) {
      let configToBeRemovedIdx =
        this.disbursementsConfigs.findIndex(config => config.code == AccountingItemKeys[ disbursementKey ] && config.matterType === matterType && config.subjectToHstType == subjectToHstType);
      if (configToBeRemovedIdx > -1) {
        this.disbursementsConfigs.splice(configToBeRemovedIdx, 1);
      }
    }
  }

  private removeTrustLedgerConfig(disbursementKey: AccountingItemKeys, matterType: MatterType): void {
    if (disbursementKey && matterType && Array.isArray(this.trustLedgerConfigs)) {
      let configToBeRemovedIdx = this.trustLedgerConfigs.findIndex(tl => tl.code == AccountingItemKeys[ disbursementKey ] && tl.matterType == matterType);
      if (configToBeRemovedIdx > -1) {
        this.trustLedgerConfigs.splice(configToBeRemovedIdx, 1);
      }
    }
  }

  //ToDo Refactoring of this method is required as it insert rows for all Matter Types (PSM)
  createTitleMortgageRegFees(): void {

    if (this.isProvinceON() || this.isProvinceAB()) {
      this.createTitleMortgageRegFeesONorAB();
    }

    if (this.isProvinceSK()) {
      if (this.isTitleMortgageRegFeesForTrustLedger()) {
        //SOA
        let purchaseTRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.TITLE_REGISTRATION_FEE ] && disb.matterType === 'PURCHASE');
        if (purchaseTRFDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseTRFDisbursementIndex, 1);
        }
        let purchaseMRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.MORTGAGE_REGISTRATION_FEE ] && disb.matterType === 'PURCHASE');
        if (purchaseMRFDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseMRFDisbursementIndex, 1);
        }
        let mortgageMRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.MORTGAGE_REGISTRATION_FEE ] && disb.matterType === 'MORTGAGE');
        if (mortgageMRFDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(mortgageMRFDisbursementIndex, 1);
        }

        //Trust Ledger
        let trustLedgerPurchaseReg: TrustLedgerConfig = this.trustLedger.trustLedgersConfig.find(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE ] && tl.matterType == 'PURCHASE');
        if (trustLedgerPurchaseReg == null) {
          this.addTrustLedgerReg('PURCHASE');
        } else {
          //during reset, the config item inherit from System account, but we need to adjust the calculated field
          trustLedgerPurchaseReg.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
        }
        let trustLedgerPurchaseMtg: TrustLedgerConfig = this.trustLedger.trustLedgersConfig.find(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ] && tl.matterType == 'PURCHASE');
        if (trustLedgerPurchaseMtg == null) {
          this.addTrustLedgerMtg('PURCHASE');
        } else {
          //during reset, the config item inherit from System account, but we need to adjust the calculated field
          trustLedgerPurchaseMtg.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
        }
        let trustLedgerMortgageMtg: TrustLedgerConfig = this.trustLedger.trustLedgersConfig.find(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ] && tl.matterType == 'MORTGAGE');
        if (trustLedgerMortgageMtg == null) {
          this.addTrustLedgerMtg('MORTGAGE');
        } else {
          //during reset, the config item inherit from System account, but we need to adjust the calculated field
          trustLedgerMortgageMtg.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
        }
      } else if (this.isTitleMortgageRegFeesForSoa()) {
        //SOA
        let purchaseTRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.TITLE_REGISTRATION_FEE ] && disb.matterType === 'PURCHASE');
        if (purchaseTRFDisbursementIndex < 0) {
          this.addSOATitleRegFees('PURCHASE');
        }
        let purchaseMRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.MORTGAGE_REGISTRATION_FEE ] && disb.matterType === 'PURCHASE');
        if (purchaseMRFDisbursementIndex < 0) {
          this.addSOAMortgageRegFees('PURCHASE');
        }
        let mortgageMRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.MORTGAGE_REGISTRATION_FEE ] && disb.matterType === 'MORTGAGE');
        if (mortgageMRFDisbursementIndex < 0) {
          this.addSOAMortgageRegFees('MORTGAGE');
        }

        //Trust Ledger
        let trustLedgerPurchaseRegIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE ] && tl.matterType == 'PURCHASE');
        if (trustLedgerPurchaseRegIndex > -1) {
          this.trustLedger.trustLedgersConfig.splice(trustLedgerPurchaseRegIndex, 1);
        }
        let trustLedgerPurchaseMtgIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ] && tl.matterType == 'PURCHASE');
        if (trustLedgerPurchaseMtgIndex > -1) {
          this.trustLedger.trustLedgersConfig.splice(trustLedgerPurchaseMtgIndex, 1);
        }
        let trustLedgerMortgageMtgIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ] && tl.matterType == 'MORTGAGE');
        if (trustLedgerMortgageMtgIndex > -1) {
          this.trustLedger.trustLedgersConfig.splice(trustLedgerMortgageMtgIndex, 1);
        }
      }
    }

    if ([ PROVINCE_CODES.NEW_BRUNSWICK, PROVINCE_CODES.NOVA_SCOTIA ].indexOf(this.provinceCode) > -1) {
      this.createTitleMortgageRegFeesNBorNS();
    }

    if (this.provinceCode == PROVINCE_CODES.MANITOBA) {
      if (this.isTitleMortgageRegFeesForTrustLedger()) {
        //need to insert the configuration item into TrustLedger pages
        {//add configuration items into TL configuration page
          //Trust Ledger
          let trustLedgerPurchaseReg: TrustLedgerConfig = this.trustLedger.trustLedgersConfig.find(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE ] && tl.matterType == 'PURCHASE');
          if (trustLedgerPurchaseReg == null) {
            this.addTrustLedgerReg('PURCHASE');
          } else {
            //during reset, the config item inherit from System account, but we need to adjust the calculated field
            trustLedgerPurchaseReg.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
          }
          let trustLedgerPurchaseMtg: TrustLedgerConfig = this.trustLedger.trustLedgersConfig.find(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ] && tl.matterType == 'PURCHASE');
          if (trustLedgerPurchaseMtg == null) {
            this.addTrustLedgerMtg('PURCHASE');
          } else {
            //during reset, the config item inherit from System account, but we need to adjust the calculated field
            trustLedgerPurchaseMtg.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
          }
          let trustLedgerMortgageMtg: TrustLedgerConfig = this.trustLedger.trustLedgersConfig.find(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ] && tl.matterType == 'MORTGAGE');
          if (trustLedgerMortgageMtg == null) {
            this.addTrustLedgerMtg('MORTGAGE');
          } else {
            //during reset, the config item inherit from System account, but we need to adjust the calculated field
            trustLedgerMortgageMtg.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
          }
          const isItemCalculated: boolean = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');

          this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
            SoaConstants.PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE, 'Expenditure',
            AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

          this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
            SoaConstants.PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE, 'Expenditure',
            AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

          this.addNewTrustLedgerConfigItem('SALE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MTG',
            SoaConstants.PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, 'Expenditure',
            AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

          this.addNewTrustLedgerConfigItem('MORTGAGE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MTG',
            SoaConstants.PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, 'Expenditure',
            AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

          //Discharge Configurations
          //Register Discharge of Mortgage
          //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
          this.addNewTrustLedgerConfigItem('DISCHARGE', AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'REG\'n',
            SoaConstants.PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE, 'Expenditure',
            AccountingItemKeys.PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE, 'AFTER', isItemCalculated);

          //Register Discharge of Other Encumberances
          this.addNewTrustLedgerConfigItem('DISCHARGE', AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MTG',
            SoaConstants.PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES, 'Expenditure',
            AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'AFTER', isItemCalculated);

        }

        let purchaseTRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY ] && disb.matterType === 'PURCHASE');
        if (purchaseTRFDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseTRFDisbursementIndex, 1);
        }
        let purchaseROMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ] && disb.matterType === 'PURCHASE');
        if (purchaseROMDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseROMDisbursementIndex, 1);
        }
        let purchaseRODMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ] && disb.matterType === 'SALE');
        if (purchaseRODMDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseRODMDisbursementIndex, 1);
        }
        let purchaseRODMMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ] && disb.matterType === 'MORTGAGE');
        if (purchaseRODMMDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseRODMMDisbursementIndex, 1);
        }
        let purchaseRDOEDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ] && disb.matterType === 'SALE');
        if (purchaseRDOEDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseRDOEDisbursementIndex, 1);
        }
        let purchaseRDOEMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ] && disb.matterType === 'MORTGAGE');
        if (purchaseRDOEMDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseRDOEMDisbursementIndex, 1);
        }
        let purchaseROMMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ] && disb.matterType === 'MORTGAGE');
        if (purchaseROMMDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(purchaseROMMDisbursementIndex, 1);
        }

        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        //Discharge Cofigurations
        let dischargeRODMMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ] && disb.matterType === 'DISCHARGE');
        if (dischargeRODMMDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(dischargeRODMMDisbursementIndex, 1);
        }
        let dischargeRDOEDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ] && disb.matterType === 'DISCHARGE');
        if (dischargeRDOEDisbursementIndex > -1) {
          this.statementOfAccount.disbursementsConfig.splice(dischargeRDOEDisbursementIndex, 1);
        }

      } else if (this.isTitleMortgageRegFeesForSoa()) {

        // remove the configuration items from TL config page
        this.removeTrustLedgerConfig(AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE, 'PURCHASE');
        this.removeTrustLedgerConfig(AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE, 'PURCHASE');
        this.removeTrustLedgerConfig(AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE, 'MORTGAGE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'SALE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'MORTGAGE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'SALE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'MORTGAGE');

        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, 'DISCHARGE');
        this.removeTrustLedgerConfig(AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, 'DISCHARGE');

        let purchaseTRFDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY ] && disb.matterType === 'PURCHASE');
        if (purchaseTRFDisbursementIndex < 0) {
          this.addSOATitleRegRegOfTransferDisb('PURCHASE');
        }
        let purchaseROMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ] && disb.matterType === 'PURCHASE');
        if (purchaseROMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfMrtgDisb('PURCHASE');
        }
        let purchaseRODMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ] && disb.matterType === 'SALE');
        if (purchaseRODMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfMrtgDischDisb('SALE');
        }
        let purchaseRODMMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ] && disb.matterType === 'MORTGAGE');
        if (purchaseRODMMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfMrtgDischDisb('MORTGAGE');
        }
        let purchaseRDOEDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ] && disb.matterType === 'SALE');
        if (purchaseRDOEDisbursementIndex < 0) {
          this.addSOATitleRegRegOfOtherDischDisb('SALE');
        }
        let purchaseRDOEMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ] && disb.matterType === 'MORTGAGE');
        if (purchaseRDOEMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfOtherDischDisb('MORTGAGE');
        }
        let purchaseROMMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ] && disb.matterType === 'MORTGAGE');
        if (purchaseROMMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfMrtgDisb('MORTGAGE');
        }

        //Discharge Cofigurations
        //Commented as part of JIra - https://doprocess.atlassian.net/browse/DPPMP-52159
        let dischargeRODMMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ] && disb.matterType === 'DISCHARGE');
        if (dischargeRODMMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfMrtgDischDisb('DISCHARGE');
        }
        let dischargeRDOEMDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ] && disb.matterType === 'DISCHARGE');
        if (dischargeRDOEMDisbursementIndex < 0) {
          this.addSOATitleRegRegOfOtherDischDisb('DISCHARGE');
        }
      }
    }
  }

  addSOATitleRegRegOfTransferDisb(matterType: MatterType) {
    let newDisb = new DisbursementConfig();
    newDisb.nameOfDisbursement = SoaConstants.REGISTRATION_OF_TRANSFER;
    newDisb.readOnly = false;
    newDisb.f9DefaultAmount = 0;
    newDisb.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    newDisb.deleted = false;
    newDisb.code = AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY ];
    newDisb.calculated = true;
    newDisb.matterType = matterType;
    this.statementOfAccount.disbursementsConfig.push(newDisb);
  }

  addSOATitleRegRegOfMrtgDisb(matterType: MatterType) {
    let newDisb = new DisbursementConfig();
    newDisb.nameOfDisbursement = SoaConstants.REGISTRATION_OF_MORTGAGE;
    newDisb.readOnly = false;
    newDisb.f9DefaultAmount = 0;
    newDisb.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    newDisb.deleted = false;
    newDisb.code = AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ];
    newDisb.calculated = true;
    newDisb.matterType = matterType;
    this.statementOfAccount.disbursementsConfig.push(newDisb);
  }

  addSOATitleRegRegOfMrtgDischDisb(matterType: MatterType) {
    let newDisb = new DisbursementConfig();
    newDisb.nameOfDisbursement = SoaConstants.REGISTRATION_OF_MORTGAGE_DISCHARGE;
    newDisb.readOnly = false;
    newDisb.f9DefaultAmount = 0;
    newDisb.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    newDisb.deleted = false;
    newDisb.code = AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ];
    newDisb.calculated = true;
    newDisb.matterType = matterType;
    this.statementOfAccount.disbursementsConfig.push(newDisb);
  }

  addSOATitleRegRegOfOtherDischDisb(matterType: MatterType) {
    let newDisb = new DisbursementConfig();
    newDisb.nameOfDisbursement = SoaConstants.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES;
    newDisb.readOnly = false;
    newDisb.f9DefaultAmount = 0;
    newDisb.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    newDisb.deleted = false;
    newDisb.code = AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ];
    newDisb.calculated = true;
    newDisb.matterType = matterType;
    this.statementOfAccount.disbursementsConfig.push(newDisb);
  }

  private addSOATitleRegFees(matterType: MatterType) {
    let purchaseDisbursement = new DisbursementConfig();
    purchaseDisbursement.nameOfDisbursement = SoaConstants.TITLE_REGISTRATION_FEE;
    purchaseDisbursement.readOnly = false;
    purchaseDisbursement.f9DefaultAmount = 0;
    purchaseDisbursement.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    purchaseDisbursement.deleted = false;
    purchaseDisbursement.code = AccountingItemKeys[ AccountingItemKeys.TITLE_REGISTRATION_FEE ];
    purchaseDisbursement.calculated = (this.elrsafeesType != 'MANUAL_ENTRY');
    purchaseDisbursement.matterType = matterType;
    let insurancePremIndex = this.statementOfAccount.disbursementsConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && tl.matterType == matterType);
    if (insurancePremIndex) {
      this.statementOfAccount.disbursementsConfig.splice(insurancePremIndex, 0, purchaseDisbursement);
    } else {
      this.statementOfAccount.disbursementsConfig.push(purchaseDisbursement);
    }

  }

  private addSOAMortgageRegFees(matterType: MatterType) {
    let purchaseDisbursement = new DisbursementConfig();
    purchaseDisbursement.nameOfDisbursement = SoaConstants.MORTGAGE_REGISTRATION_FEE;
    purchaseDisbursement.readOnly = false;
    purchaseDisbursement.f9DefaultAmount = 0;
    purchaseDisbursement.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    purchaseDisbursement.deleted = false;
    purchaseDisbursement.code = AccountingItemKeys[ AccountingItemKeys.MORTGAGE_REGISTRATION_FEE ];
    purchaseDisbursement.calculated = (this.elrsafeesType != 'MANUAL_ENTRY');
    purchaseDisbursement.matterType = matterType;
    let insurancePremIndex = this.statementOfAccount.disbursementsConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ] && tl.matterType == matterType);
    if (insurancePremIndex) {
      this.statementOfAccount.disbursementsConfig.splice(insurancePremIndex, 0, purchaseDisbursement);
    } else {
      this.statementOfAccount.disbursementsConfig.push(purchaseDisbursement);
    }
  }

  get disbursementsConfigs(): DisbursementConfig[] {
    if (!Array.isArray(this.statementOfAccount.disbursementsConfig) && this.statementOfAccount.soaSelectedTemplate) {
      this.statementOfAccount.soaSelectedTemplate.disbursementsConfig = [];
    }
    return this.statementOfAccount.disbursementsConfig;
  }

  get trustLedgerConfigs(): TrustLedgerConfig[] {
    if (!Array.isArray(this.trustLedger.trustLedgersConfig)) {
      this.trustLedger.trustLedgersConfig = [];
    }
    return this.trustLedger.trustLedgersConfig;
  }

  isProvinceAB(): boolean {
    return this.provinceCode == PROVINCE_CODES.ALBERTA;
  }

  isProvinceON(): boolean {
    return this.provinceCode == PROVINCE_CODES.ONTARIO;
  }

  isProvinceNS(): boolean {
    return this.provinceCode === PROVINCE_CODES.NOVA_SCOTIA;
  }

  isProvinceNB(): boolean {
    return this.provinceCode === PROVINCE_CODES.NEW_BRUNSWICK;
  }

  isProvinceSK(): boolean {
    return this.provinceCode == PROVINCE_CODES.SASKATCHEWAN;
  }

  addNewTrustLedgerConfigItem(matterType: MatterType, newConfigKey: AccountingItemKeys, newConfigReservedFor: string,
                              newConfigDisplayName: string, newConfigExpenseType: TrustLedgerExpenseType,
                              existingConfigKey: AccountingItemKeys, relatedPositionToExistingConfig: RelatedPosition,
                              newConfigCalculated: boolean = true, newConfigReadOnly: boolean = true, newConfigF9Amt: number = 0) {
    let existingConfig: TrustLedgerConfig = this.retrieveTrustLedgerConfig(matterType, newConfigKey);
    if (existingConfig == null) {
      let trustLedgerConfig = new TrustLedgerConfig();
      trustLedgerConfig.reservedFor = newConfigReservedFor;
      trustLedgerConfig.expenseDescription = newConfigDisplayName;
      trustLedgerConfig.readOnly = newConfigReadOnly;
      trustLedgerConfig.code = AccountingItemKeys[ newConfigKey ];
      trustLedgerConfig.f9DefaultAmount = newConfigF9Amt;
      trustLedgerConfig.calculated = newConfigCalculated;
      trustLedgerConfig.expenseType = newConfigExpenseType;
      trustLedgerConfig.matterType = matterType;
      this.addTrustLedgerConfigAroundExistingConfig(matterType, trustLedgerConfig, existingConfigKey, relatedPositionToExistingConfig);
    } else {
      //during reset, the config item inherit from System account, but we need to adjust the calculated field
      existingConfig.calculated = newConfigCalculated;
    }
  }

  private retrieveTrustLedgerConfig(matterType: MatterType, existingConfigKey: AccountingItemKeys): TrustLedgerConfig {
    return this.trustLedgerConfigs.find(config => config.code == AccountingItemKeys[ existingConfigKey ] && config.matterType == matterType);
  }

  private isSOAConfigDefined(matterType: MatterType, subjectToHstType: DisbursementSubjectToType, existingConfigKey: AccountingItemKeys): boolean {
    const existingConfigIdx: number =
      this.disbursementsConfigs.findIndex(config => config.code == AccountingItemKeys[ existingConfigKey ] && config.matterType == matterType && config.subjectToHstType == subjectToHstType);
    return existingConfigIdx > -1;
  }

  addNewSoaConfigItem(matterType: MatterType, subjectToHstType: DisbursementSubjectToType,
                      newConfigKey: AccountingItemKeys, nameOfDisbursement: string,
                      existingConfigKey: AccountingItemKeys, relatedPositionToExistingConfig: RelatedPosition,
                      newConfigReadOnly: boolean = false, newConfigF9Amt: number = 0, newConfigCalculated: boolean = true) {
    //check whether new config item key already exists, if yes, then do nothing
    //this can happens when user choose options in the same group, for example:   'Into Statement of Account - automatically' or 'Into Statement of Account - as an F9 value'
    if (this.isSOAConfigDefined(matterType, subjectToHstType, newConfigKey)) {
      return;
    }
    let newDisbursementConfigItem = new DisbursementConfig();
    newDisbursementConfigItem.nameOfDisbursement = nameOfDisbursement;
    newDisbursementConfigItem.readOnly = newConfigReadOnly;
    newDisbursementConfigItem.f9DefaultAmount = newConfigF9Amt;
    newDisbursementConfigItem.subjectToHstType = subjectToHstType;
    newDisbursementConfigItem.deleted = false;
    newDisbursementConfigItem.code = AccountingItemKeys[ newConfigKey ];
    newDisbursementConfigItem.calculated = newConfigCalculated;
    newDisbursementConfigItem.matterType = matterType;
    this.addSOAConfigAroundExistingConfig(matterType, subjectToHstType, newDisbursementConfigItem, existingConfigKey, relatedPositionToExistingConfig);
  }

  //for ON
  private addSOAERegServiceCharge(matterType: MatterType, subjectToHstType: DisbursementSubjectToType): void {
    this.addNewSoaConfigItem(matterType, subjectToHstType, AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY, SoaConstants.E_REG_SERVICE_CHARGE,
      AccountingItemKeys.SOFTWARE_TRANSACTION_CHARGE, 'AFTER');
  }

  private addSOAConfigAroundExistingConfig(matterType: MatterType, subjectToHstType: DisbursementSubjectToType, newDisbursementConfig: DisbursementConfig,
                                           existingConfigKey: AccountingItemKeys, relatedPosition: RelatedPosition): void {
    let existingConfigIdx: number;
    if (relatedPosition == 'FIRST') {
      existingConfigIdx = 0;
    } else {
      existingConfigIdx =
        this.disbursementsConfigs.findIndex(config => config.code == AccountingItemKeys[ existingConfigKey ] && config.matterType == matterType && config.subjectToHstType == subjectToHstType);
    }
    if (existingConfigIdx > -1) {
      const insertPosition: number = (relatedPosition == 'AFTER') ? existingConfigIdx + 1 : existingConfigIdx;
      this.disbursementsConfigs.splice(insertPosition, 0, newDisbursementConfig);
    } else {
      this.disbursementsConfigs.push(newDisbursementConfig);
    }
  }

  private addTrustLedgerConfigAroundExistingConfig(matterType: MatterType, newTrustLedgerConfig: TrustLedgerConfig,
                                                   existingConfigKey: AccountingItemKeys, relatedPosition: RelatedPosition): void {
    let existingConfigIdx: number =
      this.trustLedgerConfigs.findIndex(config => config.code == AccountingItemKeys[ existingConfigKey ] && config.matterType == matterType);
    if (existingConfigIdx > -1) {
      const insertPosition: number = (relatedPosition == 'AFTER') ? existingConfigIdx + 1 : existingConfigIdx;
      this.trustLedgerConfigs.splice(insertPosition, 0, newTrustLedgerConfig);
    } else {
      this.trustLedgerConfigs.push(newTrustLedgerConfig);
    }
  }

  addTrustLedgerReg(matterType: MatterType) {
    let trustLedgerConfig = new TrustLedgerConfig();
    trustLedgerConfig.reservedFor = 'REG\'n';
    trustLedgerConfig.expenseDescription = SoaConstants.PAID_TITLE_REGISTRATION_FEE;
    trustLedgerConfig.readOnly = true;
    trustLedgerConfig.code = AccountingItemKeys[ AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE ];
    trustLedgerConfig.f9DefaultAmount = 0;
    trustLedgerConfig.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
    trustLedgerConfig.expenseType = 'Expenditure';
    trustLedgerConfig.matterType = matterType;
    let toVendorIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_TO_VENDOR ] && tl.matterType == matterType);
    if (toVendorIndex) {
      this.trustLedger.trustLedgersConfig.splice(toVendorIndex + 1, 0, trustLedgerConfig);
    } else {
      this.trustLedger.trustLedgersConfig.push(trustLedgerConfig);
    }

  }

  addTrustLedgerTitleInsurance(matterType: 'PURCHASE' | 'MORTGAGE') {
    let trustLedgerConfig = new TrustLedgerConfig();
    //trustLedgerConfig.identifier = -Date.now() + Number(this.trustLedger.trustLedgersConfig.length);
    trustLedgerConfig.reservedFor = 'Title Insurance';
    trustLedgerConfig.expenseDescription = SoaConstants.PAID_INSURANCE_PREMIUM;
    trustLedgerConfig.readOnly = true;
    trustLedgerConfig.code = AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ];
    trustLedgerConfig.f9DefaultAmount = 0;
    trustLedgerConfig.calculated = true;
    trustLedgerConfig.expenseType = 'Expenditure';
    trustLedgerConfig.matterType = matterType;
    this.trustLedger.trustLedgersConfig.push(trustLedgerConfig);
  }

  addTrustLedgerMtg(matterType: MatterType) {
    let trustLedgerConfig = new TrustLedgerConfig();
    trustLedgerConfig.reservedFor = 'MTG';
    trustLedgerConfig.expenseDescription = SoaConstants.PAID_MORTGAGE_REGISTRATION_FEE;
    trustLedgerConfig.readOnly = true;
    trustLedgerConfig.code = AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ];
    trustLedgerConfig.f9DefaultAmount = 0;
    trustLedgerConfig.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
    trustLedgerConfig.expenseType = 'Expenditure';
    trustLedgerConfig.matterType = matterType;
    let toClientIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.PAID_TO_CLIENT ] && tl.matterType == matterType);
    if (toClientIndex) {
      this.trustLedger.trustLedgersConfig.splice(toClientIndex + 1, 0, trustLedgerConfig);
    } else {
      this.trustLedger.trustLedgersConfig.push(trustLedgerConfig);
    }
  }

  private addTitleInsuranceDisbursement(matterType: MatterType) {
    let purchaseDisbursement = new DisbursementConfig();
    //purchaseDisbursement.identifier = -Date.now() + Number(this.statementOfAccount.disbursementsConfig.length);
    purchaseDisbursement.nameOfDisbursement = SoaConstants.INSURANCE_PREMIUM;
    purchaseDisbursement.readOnly = false;
    purchaseDisbursement.f9DefaultAmount = 0;
    purchaseDisbursement.subjectToHstType = 'NOT_SUBJECT_TO_HST';
    purchaseDisbursement.deleted = false;
    purchaseDisbursement.code = AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ];
    purchaseDisbursement.calculated = true;
    purchaseDisbursement.matterType = matterType;
    this.statementOfAccount.disbursementsConfig.push(purchaseDisbursement);
  }

  createEMP(): void {
    if (this.isProvinceNS()) {
      //No EMP for NS
      return;
    }
    let purchaseDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ] && disb.matterType == 'PURCHASE');
    let mortgageDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ] && disb.matterType == 'MORTGAGE');

    let trustLedgerPurchaseIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ] && tl.matterType == 'PURCHASE');
    let trustLedgerMortgageIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ] && tl.matterType == 'MORTGAGE');

    if (this.mortgageProcessingFee == 'INTO_TRUST_LEDGER_AUTOMATICALLY' || this.mortgageProcessingFee == 'INTO_TRUST_LEDGER_AS_F9_VALUE') {
      if (purchaseDisbursementIndex > -1) {
        this.statementOfAccount.disbursementsConfig.splice(purchaseDisbursementIndex, 1);
        mortgageDisbursementIndex = this.statementOfAccount.disbursementsConfig.findIndex(disb => disb.code == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ]);
      }
      if (mortgageDisbursementIndex > -1) {
        this.statementOfAccount.disbursementsConfig.splice(mortgageDisbursementIndex, 1);
      }
      if (trustLedgerPurchaseIndex < 0) {
        this.addEMPTrustLedger('PURCHASE');
      }
      if (trustLedgerMortgageIndex < 0) {
        this.addEMPTrustLedger('MORTGAGE');
      }
    } else if (this.mortgageProcessingFee == 'INTO_STATEMENT_OF_ACCOUNT_AUTOMATICALLY' || this.mortgageProcessingFee == 'INTO_STATEMENT_OF_ACCOUNT_AS_F9_VALUE') {
      if (trustLedgerPurchaseIndex > -1) {
        this.trustLedger.trustLedgersConfig.splice(trustLedgerPurchaseIndex, 1);
        trustLedgerMortgageIndex = this.trustLedger.trustLedgersConfig.findIndex(tl => tl.code == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ] && tl.matterType == 'MORTGAGE');
      }
      if (trustLedgerMortgageIndex > -1) {
        this.trustLedger.trustLedgersConfig.splice(trustLedgerMortgageIndex, 1);
      }
      if (purchaseDisbursementIndex < 0) {
        this.addEMPDisbursement('PURCHASE');
      }
      if (mortgageDisbursementIndex < 0) {
        this.addEMPDisbursement('MORTGAGE');
      }
    }
  }

  private addEMPTrustLedger(matterType: MatterType) {
    let trustLedgerConfig = new TrustLedgerConfig();

    trustLedgerConfig.reservedFor = 'EMP';
    trustLedgerConfig.expenseDescription = SoaConstants.PAID_ELECTRONIC_MORTGAGE_PRO_FEE;
    trustLedgerConfig.readOnly = true;
    trustLedgerConfig.code = AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ];
    trustLedgerConfig.f9DefaultAmount = 0;
    trustLedgerConfig.calculated = true;
    trustLedgerConfig.expenseType = 'Expenditure';
    trustLedgerConfig.matterType = matterType;
    this.trustLedger.trustLedgersConfig.push(trustLedgerConfig);
  }

  private addEMPDisbursement(matterType: MatterType) {
    let disbursementConfig = new DisbursementConfig();
    disbursementConfig.nameOfDisbursement = SoaConstants.ELECTRONIC_MORTGAGE_PRO_FEE;
    disbursementConfig.readOnly = false;
    disbursementConfig.subjectToHstType = 'SUBJECT_TO_HST';
    disbursementConfig.f9DefaultAmount = 0;
    disbursementConfig.code = AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ];
    disbursementConfig.deleted = false;
    disbursementConfig.calculated = true;
    disbursementConfig.matterType = matterType;
    this.statementOfAccount.disbursementsConfig.push(disbursementConfig);
  }

  isELRSAFeesTypeManual(): boolean {
    return (this.elrsafeesType == 'MANUAL_ENTRY');
  }

  isELRSAFeesTypeAutomaticallyCalculated(): boolean {
    return (this.elrsafeesType == 'CALCULATE_AUTOMATICALLY');
  }

  isElrsaFeeTypeNA(): boolean {
    return (this.elrsafeesType === 'NA');
  }

  cleanUpGLAccountCodeIdentifiers() {
    if (this.generalLedgerAccountCodes) {
      this.generalLedgerAccountCodes.forEach(item => item.identifier = null);
    }
    if (this.generalLedgerAccountExplanations) {
      this.generalLedgerAccountExplanations.forEach(item => {
        item.identifier = null;
        item.generalLedgerAccountCodeId = null;
      });
    }
    if (this.esiLawAccountCodes) {
      this.esiLawAccountCodes.forEach(item => item.identifier = null);
    }
    if (this.esiLawAccountExplanation) {
      this.esiLawAccountExplanation.forEach(item => {
        item.identifier = null;
        item.esiLawAccountCodeId = null;
      });
    }
  }

  get pcLawCodesCombinedData(): any[] {
    let combinedArr = [];
    if (this.accountNationalSetting && this.accountNationalSetting.accountingSoftware === 'PCLAW') {
      this.generalLedgerAccountCodes = _.sortBy(this.generalLedgerAccountCodes, [ 'accountNumber' ]);
      this.generalLedgerAccountExplanations = _.sortBy(this.generalLedgerAccountExplanations, [ 'code' ]);
      this.generalLedgerAccountExplanations.forEach(item => {
        combinedArr.push({
          'label': item.code + ' - ' + item.explanation + ' [' + item.accountNumber + ']',
          'value': item.code + ' - ' + item.explanation + ' [' + item.accountNumber + ']'
        });
      });
      this.generalLedgerAccountCodes.forEach(item => {
        combinedArr.push({
          'label': item.accountNumber + ' {' + item.accountName + '}',
          'value': item.accountNumber + ' {' + item.accountName + '}'
        });
      });
    } else if (this.accountNationalSetting && this.accountNationalSetting.accountingSoftware === 'Cosmolex') {
      this.cosmolexExpenseCodes = _.sortBy(this.cosmolexExpenseCodes, [ 'expenseType' ]);
      this.cosmolexExpenseCodes.forEach(item => {
        combinedArr.push({'label': item.expenseType, 'value': item.expenseType});
      });
    } else if (this.accountNationalSetting && this.accountNationalSetting.accountingSoftware === 'ESILAW') {
      this.esiLawAccountExplanation = _.sortBy(this.esiLawAccountExplanation, [ 'code' ]);
      this.esiLawAccountExplanation.forEach(item => {
        combinedArr.push({
          'label': item.code + ' - ' + item.explanation + ' [' + item.accountNumber + ']',
          'value': item.code + ' - ' + item.explanation + ' [' + item.accountNumber + ']'
        });
      });
      /*this.esiLawAccountCodes = _.sortBy(this.esiLawAccountCodes, ['accountNumber']);
      this.esiLawAccountCodes.forEach(item => {
          combinedArr.push({
              'label': item.accountNumber + ' {' + item.accountName + '}',
              'value': item.accountNumber + ' {' + item.accountName + '}'
          });
      });*/
    }

    return combinedArr;
  }

  //based on statementConfig.isELRSAFeesTypeManual() or not, need to update SoA or TL config items' calculated and F9DefaultAmount
  updateConfigItemBasedOnELRSA(provinceCode: string): void {
    //moved from soa-general.component.ts
    // this.elrsafeesType = this.isELRSAFeesTypeManual() ? 'CALCULATE_AUTOMATICALLY' : 'MANUAL_ENTRY' ;
    if (this.isTitleMortgageRegFeesForSoa() || this.isTitleMortgageRegFeesForTrustLedger()) {
      if (this.statementOfAccount && Array.isArray(this.statementOfAccount.disbursementsConfig)) {
        //Statement of Account portion
        this.statementOfAccount.disbursementsConfig
        .filter(item => item.isRegistrationTransfer() || item.isRegistrationCharge() || item.isERegServiceCharge()
          || item.isRegistrationOfVTBMortgage() || item.isRegistrationOfMortgageDischarge()
          || item.isRegistrationOfMortgage() || item.isRegisterVendorsLienCaveat() || item.isRegistrationDischargeOfOther()
          || item.isDischargeVendorsLienCaveat() // for SALE disbursements
          // this applies too
          || (this.provinceCode == 'SK' && (item.isTitleRegistrationFee() || item.isMortgageRegistrationFee())) //for SK, related items' key: TITLE_REGISTRATION_FEE, MORTGAGE_REGISTRATION_FEE
        )
        .forEach(
          item => {
            item.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
            item.f9DefaultAmount = 0;
          }
        );
      }
      //Trust Ledger portion
      if (this.trustLedger && Array.isArray(this.trustLedger.trustLedgersConfig)) {

        this.trustLedger.trustLedgersConfig
        .filter(item => this.isTitleMortgageRegFeesRelatedTrustLedgerConfig(item, provinceCode))
        .forEach(item => {
          item.calculated = (this.elrsafeesType === 'CALCULATE_AUTOMATICALLY');
          item.f9DefaultAmount = 0;
        });
      }
    }
  }

  //used as filter function to determine whether the trustLedgerConfig item need to be changed to Editable when isTitleMortgageRegFeesForTrustLedger = true
  isTitleMortgageRegFeesRelatedTrustLedgerConfig(item: TrustLedgerConfig, provinceCode: string): boolean {
    if (provinceCode && item && item.matterType) {
      switch (provinceCode) {
        case 'SK':
          switch (item.matterType) {
            case 'PURCHASE' :
              return item.isPaidTitleRegistrationFee() || item.isPaidMortgageRegistrationFee();
            case 'MORTGAGE' :
              return item.isPaidMortgageRegistrationFee();
          }
          break;
        case 'MB':
          switch (item.matterType) {
            case 'PURCHASE' :
              return item.isPaidTitleRegistrationFee() || item.isPaidMortgageRegistrationFee();
            case 'SALE'     :
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
            case 'MORTGAGE' :
              return item.isPaidMortgageRegistrationFee() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
            case 'DISCHARGE':
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
          }
          break;
        case 'NB':
        case 'NS':
          switch (item.matterType) {
            case 'PURCHASE' :
              return item.isRegistrationOfCharge() || item.isRegistrationOfTransfer();
            case 'SALE'     :
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther() || item.isRegistrationOfVtbMortgage();
            case 'MORTGAGE' :
              return item.isRegistrationOfCharge() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
            case 'DISCHARGE':
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
          }
          break;
        case 'ON':
          switch (item.matterType) {
            case 'PURCHASE' :
              return item.isRegistrationOfTransfer() || item.isRegistrationOfCharge();
            case 'SALE'     :
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfVtbMortgage() || item.isRegistrationOfDischargeOfOther();
            case 'MORTGAGE' :
              return item.isRegistrationOfCharge() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
            case 'DISCHARGE':
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
          }
          break;
        case 'AB':
          switch (item.matterType) {
            case 'PURCHASE' :
              return item.isRegistrationOfTransfer() || item.isRegistrationOfMortgage();
            case 'SALE'     :
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfVendorLienCaveat() || item.isDischargeVendorLienCaveat() || item.isRegistrationOfDischargeOfOther();
            case 'MORTGAGE' :
              return item.isRegistrationOfMortgage() || item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
            case 'DISCHARGE':
              return item.isRegistrationOfMortgageDischarge() || item.isRegistrationOfDischargeOfOther();
          }
          break;
      }
    }
    return false;
  }

}
