<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo"></span></a>
            </div>
        </div>
        <div class="privacy-policy-box">
            <div class="text">
                <h2 class="casl-header">Canada's Anti-Spam Legislation Settings</h2>
                <br>
                <h3>Optional</h3>
                <ng-container *ngIf="currentUser && currentUserStaffProfile && currentUserStaffProfile.caslInfo">
                    <div>
                             <span class="primary radio">
                                        <input type="radio"
                                               id="optIn"
                                               name="optIn"
                                               value="false"
                                               [(ngModel)]="currentUserStaffProfile.caslInfo.caslEmailOptOut"
                                               (ngModelChange)="updateReqFields()"
                                        />
                                        <label for="optIn">CASL Email Opt-in</label>
                                </span>
                        <span class="casl-opt-text">{{commonLabels.caslOptIn}}</span>

                    </div>
                    <div>
                             <span class="primary radio">
                                        <input type="radio"
                                               id="optOut"
                                               name="optOut"
                                               value="true"
                                               [(ngModel)]="currentUserStaffProfile.caslInfo.caslEmailOptOut"
                                               (ngModelChange)="updateReqFields()"
                                        />
                                        <label for="optOut">CASL Email Opt-out</label>
                                </span>
                        <span class="casl-opt-text">{{commonLabels.caslOptOut}}</span>

                    </div>
                    <ng-container *ngIf="isCaslInfoAvailable">
                        <div class="padding-top-25">
                            <div class="inline-block padding-right-30">
                                <span>CASL Requested By : </span>
                                <span class="semiboldfont">{{caslRequestedBy}}</span>
                            </div>
                            <div class="inline-block padding-right-30">
                                <span>CASL Last Modified By : </span>
                                <span
                                    class="semiboldfont">{{currentUserStaffProfile.caslInfo.caslLastModifiedBy || ''}}</span>
                            </div>
                            <div class="inline-block">
                                <span>CASL Last Modified Date : </span>
                                <span
                                    class="semiboldfont">{{currentUserStaffProfile.caslInfo.caslLastModifiedDate|dpDateFormatPipe}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <div class="padding-top-25">
                        <button type="submit" style="float:left; width:auto;" class="dp-btn-green"
                                (click)="next()">
                            Next
                        </button>
                    </div>
                </ng-container>
            </div>

        </div>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>
<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>

