<div class="container-fluid custom-modal-container">

    <div class="row form-horizontal padding-bottom-100">

        <div class="col-lg-12 panel-group">
            <div *ngFor="let cirfClientInfo of cirfImportData;let i = index;" class="row">

                <dp-accordion [expanded]="true">
                    <div class="dp-accordion-header">
                        <div class="row  row-group">
                            <div class="col-lg-4">
                                <label class="control-label text-left padding-left-40">{{cirfClientInfo.fullName}}</label>
                            </div>
                            <div class="col-lg-6" *ngIf="cirfClientInfo?.cirfClient?.allowMissingFields">
                                <label class="control-label text-left padding-left-40 warningMessage">Not all details available for this client</label>
                            </div>

                        </div>
                    </div>
                    <div class="dp-accordion-content row padding-left-30">
                        <div class="col-lg-12 form-group margin-top-15">
                            <div class="col-lg-1 text-right">
                                <label class="control-label">Name</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="cirfClientName{{i}}"
                                       name="cirfClientName{{i}}"
                                       class="form-control"
                                       [ngModel]="cirfClientInfo.fullName"
                                       [dp-read-only]="true"
                                />
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-2"
                                 *ngIf="!cirfClientInfo.clientName">
                                <select id="clientName{{i}}"
                                        name="clientName{{i}}"
                                        class="form-control"
                                        [(ngModel)]="cirfClientInfo.clientContactId"
                                        (change)="updateClientName(cirfClientInfo ,i)"
                                >
                                    <option *ngFor="let client of clients" [value]="client.value">
                                        {{client.label}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-4" *ngIf="cirfClientInfo.clientName">
                                <input type="text"
                                       id="clientFullName{{i}}"
                                       name="clientFullName{{i}}"
                                       class="form-control"
                                       [ngModel]="cirfClientInfo.clientName"
                                       [dp-read-only]="true"
                                />
                                <div class="actionbtns" *ngIf="cirf?.isMatterCirf()">
                                    <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeClientName(cirfClientInfo)" aria-hidden="true"></i>
                                </span>
                                    </div>
                                </div>
                            </div>
                            <div class="{{cirfClientInfo.isNewRecord() && !cirfClientInfo.clientName ? 'col-lg-4' : 'col-lg-2'}}"
                                 *ngIf="cirfClientInfo.isNewRecord() && !cirfClientInfo.clientName">
                                <p-autoComplete
                                    id="clientSearch{{i}}"
                                    name="clientSearch{{i}}"
                                    [class.autocomplete-searching]="clientPurchasersLoading"
                                    [suggestions]="clientPurchasers"
                                    (completeMethod)="search($event)"
                                    [(ngModel)]="cirfClientInfo.dataModel"
                                    field="fullName"
                                    [minLength]="0"
                                    [delay]="1000"
                                    maxlength="75"
                                    (onSelect)="dataSelectedClient(cirfClientInfo)"
                                    autocomplete="off" class="autocomplete-search-icon"
                                    (focusout)="clearClient(cirfClientInfo)">


                                    <ng-template let-clientPurchaser pTemplate="item">
                                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                            [ngClass]="{'bold-font' : isAddNew(clientPurchaser)}"
                                            [disableItem]="clientPurchaser">
                                <span class="col-lg-5 text-overflow"
                                      *ngIf="autoCompleteEmptyFlag == true">{{ clientPurchaser.displayName }}</span>
                                            <span class="text-overflow"
                                                  [ngClass]="{'col-lg-12':isAddNew(clientPurchaser), 'col-lg-5':!isAddNew(clientPurchaser)}"
                                                  *ngIf="autoCompleteEmptyFlag == false">{{ clientPurchaser.displayName }}</span>
                                            <span class="col-lg-4 text-overflow"
                                                  *ngIf="autoCompleteEmptyFlag == false">{{ clientPurchaser.displayAddress }}</span>
                                            <span class="col-lg-3 text-overflow"
                                                  *ngIf="autoCompleteEmptyFlag == false && clientPurchaser.gender  != 'QUESTION'">{{
                                                getGenderValue(clientPurchaser.gender) }}</span>
                                        </li>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                        </div>

                        <ng-container *ngIf="cirfClientInfo.clientContact">
                            <div class="col-lg-12 form-group" style="border-bottom: 2px solid #cccccc">
                                <div class="col-lg-1">
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group main-level">
                                        <label class="control-label">
                                            CIRF Data
                                        </label>
                                    </div>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="allFields{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="allFields{{i}}"
                                               [(ngModel)]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateAllFields($event , cirfClientInfo)"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group main-level">
                                        <label class="control-label">Matter Data</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.gender', fieldValue:cirfClientInfo.cirfClient.gender}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Gender</label>
                                </div>
                                <div class="col-lg-3">
                                    <select
                                        class="form-control"
                                        id="genderCirf" name="genderCirf" [dp-read-only]=true
                                        [(ngModel)]="cirfClientInfo.cirfClient.gender"
                                    >
                                        <option *ngFor="let gender of genderTypeOptions"
                                                [value]="gender.value">{{gender.label}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="genderC{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contact', 'gender')"
                                               id="genderC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <select
                                        class="form-control"
                                        id="genderMatter" name="genderMatter"
                                        [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.gender"
                                    >
                                        <option *ngFor="let gender of genderTypeOptions"
                                                [value]="gender.value">{{gender.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.firstName', fieldValue:cirfClientInfo.cirfClient.firstName}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">First Name</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="fNameCirf"
                                           name="fNameCirf"
                                           [dp-read-only]="true"
                                           *ngIf="cirfClientInfo.cirfClient.firstName"
                                           [ngModel]="cirfClientInfo.cirfClient.firstName"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="fNameC{{i}}" fieldClass="top-8"
                                                 *ngIf="cirfClientInfo.cirfClient.firstName">
                                        <input type="checkbox"
                                               id="fNameC{{i}}"

                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contactName', 'firstName', true)"
                                               id="fNameC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="fNameMatter"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.contactName.firstName"
                                           name="fNameMatter"
                                           maxlength="50"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.lastName', fieldValue:cirfClientInfo.cirfClient.lastName}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Last Name</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="lNameCirf"
                                           name="lNameCirf"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.lastName"
                                           class="form-control"/>
                                </div>

                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="lNameC{{i}}" fieldClass="top-8">
                                        <input type="checkbox"

                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contactName', 'lastName', true)"
                                               id="lNameC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="lNameMatter"
                                           maxlength="100"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.contactName.lastName"
                                           name="lNameMatter"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.middleName', fieldValue:cirfClientInfo.cirfClient.middleName}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Middle Name</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="mNameCirf"
                                           name="mNameCirf"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.middleName"
                                           class="form-control"/>
                                </div>

                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="mNameC{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contactName', 'middleName', true)"
                                               id="mNameC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="lmameMatter"
                                           maxlength="50"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.contactName.middleName"
                                           name="lmameMatter"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.birthDate', fieldValue:cirfClientInfo.cirfClient.birthDate}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Birth Date</label>
                                </div>
                                <div class="col-lg-3">
                                    <dp-partial-date id="birthDateCirf"
                                                     [inputDate]="cirfClientInfo.cirfClient.birthDate"
                                                     [dateFormat]="'MMM DD YYYY'"
                                                     [disableAll]="true"
                                                     [fieldKey]="'birthDateCirf'"
                                                     dp-error-validator>
                                    </dp-partial-date>
                                </div>

                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="birthDateC{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contact', 'birthDate')"
                                               id="birthDateC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <dp-partial-date #partialBirthdayDate dateType="birthday"
                                                     [inputDate]="selectedParticipantFromMatter(cirfClientInfo).contact.birthDate"
                                                     [dateFormat]="'MMM DD YYYY'"
                                                     (dateChange)="onDateChangeBirthday($event , cirfClientInfo)"
                                                     [fieldKey]="'contact.profile.birthDate'"
                                                     dp-error-validator>
                                    </dp-partial-date>
                                </div>
                            </div>


                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.isCanadianResident', fieldValue:cirfClientInfo.cirfClient.isCanadianResident}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Canadian Resident</label>
                                </div>
                                <div class="col-lg-3">
                                    <select
                                        class="form-control"
                                        id="canadianResidentCirf" name="canadianResidentCirf" [dp-read-only]=true
                                        [(ngModel)]="cirfClientInfo.cirfClient.isCanadianResident"
                                    >
                                        <option *ngFor="let canadianResident of canadianResidentOptions"
                                                [value]="canadianResident.value">{{canadianResident.label}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="canadianResidentC{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contact', 'isCanadianResident')"
                                               id="canadianResidentC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <select
                                        class="form-control"
                                        id="canadianResidentMatter" name="canadianResidentMatter"
                                        [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.canadianResidentFlag"
                                    >
                                        <option *ngFor="let canadianResident of canadianResidentOptions"
                                                [value]="canadianResident.value">{{canadianResident.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.email', fieldValue:cirfClientInfo.cirfClient.email}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Email</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="emailCirf"
                                           name="emailCirf"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.email"
                                           class="form-control"/>
                                </div>

                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="emailC{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo ,$event,'contact', 'email', true)"
                                               id="emailC{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="emailMatter"
                                           maxlength="100"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.email"
                                           name="emailMatter"
                                           class="form-control"/>
                                </div>
                            </div>


                            <div [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.firstTimeHomeBuyer', fieldValue:cirfClientInfo.cirfClient?.firstTimeHomeBuyer}">
                                <div class="col-lg-12 form-group" *ngIf="matter.isPurchase">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label">Are you a first time home owner?</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <select
                                            class="form-control"
                                            id="cirfFirstTimeBuyer" name="cirfFirstTimeBuyer" [dp-read-only]=true
                                            [ngModel]="cirfClientInfo.cirfClient.firstTimeHomeBuyer"
                                        >
                                            <option *ngFor="let option of yNOptions" [value]="option.value">
                                                {{option.label}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 text-center padding-left-15">
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="col-lg-2">
                                            <button id="firstTimeHomeBuyerStickyNotes"
                                                    type="button"
                                                    class="function-icons"
                                                    (click)="openStickyNotesModal(matter.mainClientTopic)">
                                                <i class="svg-icon sticky-note pointerCursor"></i>
                                            </button>
                                        </div>
                                        <div class="col-lg-10">
                                            <div>First time home owner will be appended to existing {{matter.getMainClientType(true)}} Sticky Note content.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-12 form-group"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.residentStatus', fieldValue:cirfClientInfo.cirfClient.residentStatus}">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Resident Status</label>
                                </div>
                                <div class="col-lg-3">
                                    <select class="form-control"
                                            id="residentStatusCirf{{i}}"
                                            name="residentStatusCirf{{i}}"
                                            [dp-read-only]="true"
                                            [ngModel]="cirfClientInfo.cirfClient.residentStatus"
                                            class="form-control">
                                        <option *ngFor="let residentStatus of residentStatusOptions"
                                                [value]="residentStatus.value">{{residentStatus.label}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="residentStatusCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="updateContactDetailsFromCIrf(cirfClientInfo, $event,'contact', 'residentStatus')"
                                               id="residentStatusCheckbox{{i}}"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <select class="form-control"
                                            id="residentStatusMatter{{i}}"
                                            name="residentStatusMatter{{i}}"
                                            [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.residentStatus"
                                    >
                                        <option *ngFor="let residentStatus of residentStatusOptions"
                                                [value]="residentStatus.value">{{residentStatus.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 form-group padding-top-10" *ngIf="cirf && !cirf.isParticipantCirf() && !cirf.isCustomMatter()">
                                <dp-accordion [expanded]="cirfClientInfo.allFieldsSelected" [secondary]="true">
                                    <div class="dp-accordion-header">
                                        <div class="row  row-group">
                                            <label class="control-label col-lg-3 text-left padding-left-60">Spousal Information</label>
                                        </div>
                                    </div>
                                    <div class="dp-accordion-content row">
                                        <div class="col-lg-12">
                                            <div class="col-lg-5 vertical-align-label">
                                            </div>
                                            <div class="col-lg-1 text-center padding-left-15 margin-top-minus-30">
                                                <dp-checkbox fieldId="flaCheckbox{{i}}" fieldClass="top-8">
                                                    <input type="checkbox"
                                                           id="flaCheckbox{{i}}"
                                                           [checked]="cirfClientInfo.allFieldsSelected"
                                                           (change)="requestFlaChange(cirfClientInfo, $event)"
                                                    />
                                                </dp-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 padding-top-10">
                                            <div class="col-lg-2 vertical-align-label">
                                                <label class="control-label">Spousal Status</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"
                                                       id="spousalStatusCirf{{i}}"
                                                       name="spousalStatusCirf{{i}}"
                                                       [dp-read-only]="true"
                                                       [ngModel]="cirfClientInfo.cirfClient.spousalStatus"
                                                       class="form-control"/>
                                            </div>
                                            <div class="col-lg-1 padding-left-15">
                                            </div>
                                            <div class="col-lg-4">
                                                <textarea rows="3"
                                                          class="form-control"
                                                          id="flaMatter{{i}}"
                                                          name="flaMatter{{i}}"
                                                          [dp-read-only]="true"
                                                          [ngModel]="getFlaText(cirfClientInfo)"
                                                          class="form-control">
                                                </textarea>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 padding-top-10">
                                            <div class="col-lg-2 vertical-align-label">
                                                <label class="control-label">Is this the family residence?</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"
                                                       id="isFamilyResidenceCirf{{i}}"
                                                       name="isFamilyResidenceCirf{{i}}"
                                                       [dp-read-only]="true"
                                                       [ngModel]="cirfClientInfo.cirfClient.isFamilyResidence"
                                                       class="form-control"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 padding-top-10">
                                            <div class="col-lg-2 vertical-align-label">
                                                <label class="control-label">Is spouse on title?</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"
                                                       id="isSpouseOnTitleCirf{{i}}"
                                                       name="isSpouseOnTitleCirf{{i}}"
                                                       [dp-read-only]="true"
                                                       [ngModel]="cirfClientInfo.cirfClient.isSpouseOnTitle"
                                                       class="form-control"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 padding-top-10">
                                            <div class="col-lg-2 vertical-align-label">
                                                <label class="control-label">Spouse Name</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"
                                                       id="spouseNameCirf{{i}}"
                                                       name="spouseNameCirf{{i}}"
                                                       [dp-read-only]="true"
                                                       [ngModel]="cirfClientInfo.cirfClient.spouseName"
                                                       class="form-control"/>
                                            </div>
                                            <div class="col-lg-1 padding-left-15">
                                            </div>
                                            <div class="col-lg-4">
                                                <input type="text"
                                                       id="spouseNameMatter{{i}}"
                                                       name="spouseNameMatter{{i}}"
                                                       [ngModel]="getFlaSpouseName(cirfClientInfo, i)"
                                                       [dp-read-only]="true"
                                                       class="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                </dp-accordion>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Home Phone</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="homePhoneCirf{{i}}"
                                           name="homePhoneCirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.homePhone"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="homePhoneCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="homePhoneCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="phoneChange(cirfClientInfo, $event, 'homePhone')"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="homePhoneMatter{{i}}"
                                           name="homePhoneMatter{{i}}"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.homePhone"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Cell Phone</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="cellPhoneCirf{{i}}"
                                           name="cellPhoneCirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.cellPhone"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="cellPhoneCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="cellPhoneCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="phoneChange(cirfClientInfo, $event, 'cellPhone')"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="cellPhoneMatter{{i}}"
                                           name="cellPhoneMatter{{i}}"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.cellPhone"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Work Phone</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="workPhoneCirf{{i}}"
                                           name="workPhoneCirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.workPhone"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="workPhoneCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="workPhoneCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="phoneChange(cirfClientInfo, $event, 'workPhone')"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="workPhoneMatter{{i}}"
                                           name="workPhoneMatter{{i}}"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.workPhone"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.occupation', fieldValue:cirfClientInfo.cirfClient.occupation}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Occupation</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="occupationCirf{{i}}"
                                           name="occupationCirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.occupation"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="occupationCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="occupationCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="occupationChange(cirfClientInfo, $event)"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="occupationMatter{{i}}"
                                           name="occupationMatter{{i}}"
                                           maxlength="50"
                                           [(ngModel)]="getIdRecord(cirfClientInfo).occupation"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.addressLine1', fieldValue:cirfClientInfo.cirfClient.businessAddress.addressLine1}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Primary Address 1</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="address1Cirf{{i}}"
                                           name="address1Cirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.businessAddress.addressLine1"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="address1Checkbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="address1Checkbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="addressChange(cirfClientInfo, $event, 'addressLine1', true)"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="address1Matter{{i}}"
                                           name="address1Matter{{i}}"
                                           maxlength="250"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.primaryAddress.addressLine1"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.addressLine2', fieldValue:cirfClientInfo.cirfClient.businessAddress.addressLine2}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Address 2</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="address2Cirf{{i}}"
                                           name="address2Cirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.businessAddress.addressLine2"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="address2Checkbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="address2Checkbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="addressChange(cirfClientInfo, $event, 'addressLine2', true)"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="address2Matter{{i}}"
                                           name="address2Matter{{i}}"
                                           maxlength="250"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.primaryAddress.addressLine2"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.city', fieldValue:cirfClientInfo.cirfClient.businessAddress.city}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">City</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="cityCirf{{i}}"
                                           name="cityCirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.businessAddress.city"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="cityCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="cityCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="addressChange(cirfClientInfo, $event, 'city', true)"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="cityMatter{{i}}"
                                           name="cityMatter{{i}}"
                                           maxlength="100"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.primaryAddress.city"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.provinceCode', fieldValue:cirfClientInfo.cirfClient.businessAddress.provinceCode}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Province</label>
                                </div>
                                <div class="col-lg-3">
                                    <select class="form-control"
                                            id="provinceCirf{{i}}"
                                            name="provinceCirf{{i}}"
                                            [dp-read-only]="true"
                                            [ngModel]="cirfClientInfo.cirfClient.businessAddress.provinceCode"
                                            class="form-control">
                                        <option *ngFor="let province of provinceOptions"
                                                [value]="province.value">{{province.label}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="provinceCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="provinceCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="addressChange(cirfClientInfo, $event, 'provinceCode')"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <select class="form-control"
                                            id="provinceMatter{{i}}"
                                            name="provinceMatter{{i}}"
                                            [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.primaryAddress.provinceCode"
                                    >
                                        <option *ngFor="let province of provinceOptions"
                                                [value]="province.value">{{province.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.postalCode', fieldValue:cirfClientInfo.cirfClient.businessAddress.postalCode}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Postal Code</label>
                                </div>
                                <div class="col-lg-3">
                                    <input type="text"
                                           id="postalCodeCirf{{i}}"
                                           name="postalCodeCirf{{i}}"
                                           [dp-read-only]="true"
                                           [ngModel]="cirfClientInfo.cirfClient.businessAddress.postalCode"
                                           class="form-control"/>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="postalCodeCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="postalCodeCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="addressChange(cirfClientInfo, $event, 'postalCode')"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="postalCodeMatter{{i}}"
                                           name="postalCodeMatter{{i}}"
                                           [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.primaryAddress.postalCode"
                                           class="form-control"/>
                                </div>
                            </div>

                            <div class="col-lg-12 form-group padding-top-10"
                                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.country', fieldValue:cirfClientInfo.cirfClient.businessAddress.country}">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Country</label>
                                </div>
                                <div class="col-lg-3">
                                    <select class="form-control"
                                            id="countryCirf{{i}}"
                                            name="countryCirf{{i}}"
                                            [dp-read-only]="true"
                                            [ngModel]="cirfClientInfo.cirfClient.businessAddress.country"
                                            class="form-control">
                                        <option *ngFor="let country of countryOptions"
                                                [value]="country.value">{{country.label}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-1 text-center padding-left-15">
                                    <dp-checkbox fieldId="countryCheckbox{{i}}" fieldClass="top-8">
                                        <input type="checkbox"
                                               id="countryCheckbox{{i}}"
                                               [checked]="cirfClientInfo.allFieldsSelected"
                                               (change)="addressChange(cirfClientInfo, $event, 'country')"
                                        />
                                    </dp-checkbox>
                                </div>
                                <div class="col-lg-4">
                                    <select class="form-control"
                                            id="countryMatter{{i}}"
                                            name="countryMatter{{i}}"
                                            [(ngModel)]="selectedParticipantFromMatter(cirfClientInfo).contact.primaryAddress.country"
                                    >
                                        <option *ngFor="let country of countryOptions"
                                                [value]="country.value">{{country.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <ng-container *ngFor="let udfField of cirfClientInfo.clientUdfs; let k=index">
                                <div class="col-lg-12 form-group padding-top-10">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label">{{udfField.fieldLabel}}</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <input type="text"
                                               id="clientUdfField{{'_'+i+'_'+k}}"
                                               name="clientUdfField{{'_'+i+'_'+k}}"
                                               [dp-read-only]="true"
                                               [ngModel]="udfField.fieldValue"
                                               class="form-control"/>
                                    </div>
                                    <div class="col-lg-1 text-center padding-left-15">
                                    </div>
                                    <div class="col-lg-4" *ngIf="k==0">
                                        <div class="col-lg-2">
                                            <button id="openUdfStickyNotes"
                                                    type="button"
                                                    class="function-icons"
                                                    (click)="openStickyNotesModal(matter.mainClientTopic)">
                                                <i class="svg-icon sticky-note pointerCursor"></i>
                                            </button>
                                        </div>
                                        <div class="col-lg-10">
                                            <div>Additional Details fields will be appended to existing {{matter.getMainClientType(true)}} Sticky Note content.</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>


                            <ng-container *ngIf="cirfClientInfo.cirfClient.hasIdentifications()">
                                <div class="col-lg-12 form-group padding-top-10" *ngFor="let idDocument of getIdRecord(cirfClientInfo).identificationDocuments; let j=index">
                                    <div class="col-lg-4">
                                        <div class="form-group main-level">
                                            <label class="control-label">
                                                ID #{{j + 1}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-group padding-top-10"
                                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification'+(j+1)+'.identificationTypeCode',
                                         fieldValue:cirfClientInfo.cirfClient.getIdentification(j).identificationTypeCode}">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">Type of ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input
                                                id="idTypeCirf{{'_'+i+j}}"
                                                name="idTypeCirf{{'_'+i+j}}"
                                                [ngModel]="cirfClientInfo.cirfClient.getIdentification(j).identificationTypeCode"
                                                [dp-read-only]="true"
                                                class="form-control"
                                            />
                                        </div>
                                        <div class="col-lg-1 text-center padding-left-15">
                                            <dp-checkbox fieldId="idTypeCheckbox{{'_'+i+j}}" fieldClass="top-8">
                                                <input type="checkbox"
                                                       id="idTypeCheckbox{{'_'+i+j}}"
                                                       [checked]="cirfClientInfo.allFieldsSelected"
                                                       (change)="identificationChange(cirfClientInfo, $event, j, 'identificationTypeCode')"
                                                />
                                            </dp-checkbox>
                                        </div>
                                        <div class="col-lg-4">
                                            <select class="form-control"
                                                    id="idTypeMatter{{'_'+i+j}}"
                                                    name="idTypeMatter{{'_'+i+j}}"
                                                    [(ngModel)]="idDocument.identificationTypeCode"
                                            >
                                                <option *ngFor="let identification of identificationOptions"
                                                        [value]="identification.value">{{identification.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 form-group padding-top-10"
                                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification'+(j+1)+'.identificationNumber',
                                            fieldValue:cirfClientInfo.cirfClient.getIdentification(j).identificationNumber}">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">ID Number</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"
                                                   id="idNumberCirf{{'_'+i+j}}"
                                                   name="idNumberCirf{{'_'+i+j}}"
                                                   [dp-read-only]="true"
                                                   [ngModel]="cirfClientInfo.cirfClient.getIdentification(j).identificationNumber"
                                                   class="form-control"/>
                                        </div>
                                        <div class="col-lg-1 text-center padding-left-15">
                                            <dp-checkbox fieldId="idNumberCheckbox{{'_'+i+j}}" fieldClass="top-8">
                                                <input type="checkbox"
                                                       id="idNumberCheckbox{{'_'+i+j}}"
                                                       [checked]="cirfClientInfo.allFieldsSelected"
                                                       (change)="identificationChange(cirfClientInfo, $event, j, 'identificationNumber')"
                                                />
                                            </dp-checkbox>
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text"
                                                   id="idNumberMatter{{'_'+i+j}}"
                                                   name="idNumberMatter{{'_'+i+j}}}"
                                                   maxlength="50"
                                                   [(ngModel)]="idDocument.identificationNumber"
                                                   class="form-control"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 form-group padding-top-10"
                                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification'+(j+1)+'.placeOfIssue',
                                            fieldValue:cirfClientInfo.cirfClient.getIdentification(j).placeOfIssue}">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">Place of Issue</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"
                                                   id="placeOfIssueCirf{{'_'+i+j}}"
                                                   name="placeOfIssueCirf{{'_'+i+j}}"
                                                   [dp-read-only]="true"
                                                   [ngModel]="cirfClientInfo.cirfClient.getIdentification(j).placeOfIssue"
                                                   class="form-control"/>
                                        </div>
                                        <div class="col-lg-1 text-center padding-left-15">
                                            <dp-checkbox fieldId="placeOfIssueCheckbox{{'_'+i+j}}" fieldClass="top-8">
                                                <input type="checkbox"
                                                       id="placeOfIssueCheckbox{{'_'+i+j}}"
                                                       [checked]="cirfClientInfo.allFieldsSelected"
                                                       (change)="identificationChange(cirfClientInfo, $event, j, 'placeOfIssue', true)"
                                                />
                                            </dp-checkbox>
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text"
                                                   id="placeOfIssueMatter{{'_'+i+j}}"
                                                   name="placeOfIssueMatter{{'_'+i+j}}}"
                                                   maxlength="50"
                                                   [(ngModel)]="idDocument.placeOfIssue"
                                                   class="form-control"/>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 form-group padding-top-10"
                                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification'+(j+1)+'.expiryDate',
                                            fieldValue:cirfClientInfo.cirfClient.getIdentification(j).expiryDate}">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">Expiry Date</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <dp-partial-date id="expiryDateCirf{{'_'+i+j}}"
                                                             [inputDate]="cirfClientInfo.cirfClient.getIdentification(j).expiryDate"
                                                             [disableAll]="true"
                                                             [fieldKey]="'idVerification.expiryDateCirf'+i+j"
                                                             dp-error-validator>
                                            </dp-partial-date>
                                        </div>
                                        <div class="col-lg-1 text-center padding-left-15">
                                            <dp-checkbox fieldId="expiryDateCheckbox{{'_'+i+j}}" fieldClass="top-8">
                                                <input type="checkbox"
                                                       id="expiryDateCheckbox{{'_'+i+j}}"
                                                       [checked]="cirfClientInfo.allFieldsSelected"
                                                       (change)="identificationChange(cirfClientInfo, $event, j, 'expiryDate')"
                                                />
                                            </dp-checkbox>
                                        </div>
                                        <div class="col-lg-4">
                                            <dp-partial-date id="expiryDate{{'_'+i+j}}"
                                                             [inputDate]="idDocument.expiryDate"
                                                             (dateChange)="onExpiryDateChange($event, cirfClientInfo, j)"
                                                             [fieldKey]="'idVerification.expiryDate'+i+j"
                                                             dp-error-validator>
                                            </dp-partial-date>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 form-group padding-top-10"
                                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification'+(j+1)+'.country',
                                            fieldValue:cirfClientInfo.cirfClient.getIdentification(j).country}">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">Country</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="text"
                                                   id="countryCirf{{'_'+i+j}}"
                                                   name="countryCirf{{'_'+i+j}}"
                                                   [dp-read-only]="true"
                                                   [ngModel]="cirfClientInfo.cirfClient.getIdentification(j).country"
                                                   class="form-control"/>
                                        </div>
                                        <div class="col-lg-1 text-center padding-left-15">
                                            <dp-checkbox fieldId="countryCheckbox{{'_'+i+j}}" fieldClass="top-8">
                                                <input type="checkbox"
                                                       id="countryCheckbox{{'_'+i+j}}"
                                                       [checked]="cirfClientInfo.allFieldsSelected"
                                                       (change)="identificationChange(cirfClientInfo, $event, j, 'country', true)"
                                                />
                                            </dp-checkbox>
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text"
                                                   id="idCountryMatter{{'_'+i+j}}"
                                                   name="idCountryMatter{{'_'+i+j}}}"
                                                   maxlength="75"
                                                   [(ngModel)]="idDocument.country"
                                                   class="form-control"/>
                                        </div>
                                    </div>

                                    <ng-container *ngFor="let udfField of getIdentificationUdfs(cirfClientInfo, j); let k=index">
                                        <div class="col-lg-12 form-group padding-top-10">
                                            <div class="col-lg-2 vertical-align-label">
                                                <label class="control-label">{{udfField.fieldLabel}}</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text"
                                                       id="udfField{{'_'+i+'_'+j+'_'+k}}"
                                                       name="udfField{{'_'+i+'_'+j+'_'+k}}"
                                                       [dp-read-only]="true"
                                                       [ngModel]="udfField.fieldValue"
                                                       class="form-control"/>
                                            </div>
                                            <div class="col-lg-1 text-center padding-left-15">
                                            </div>
                                            <div class="col-lg-4" *ngIf="k==0">
                                                <div class="col-lg-2">
                                                    <button id="openUdfStickyNotes{{'_'+k}}"
                                                            type="button"
                                                            class="function-icons"
                                                            (click)="openStickyNotesModal(matter.mainClientTopic)">
                                                        <i class="svg-icon sticky-note pointerCursor"></i>
                                                    </button>
                                                </div>
                                                <div class="col-lg-10">
                                                    <div>Additional details fields will be appended to existing {{matter.getMainClientType(true)}} Sticky Note content.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </dp-accordion>


            </div>
        </div>

    </div>

</div>
