import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ChicagoError} from './chicago-error';

export class ChicagoBaseResponse extends BaseEntity {
  responseCode: number;
  responseMessage: string;
  error: ChicagoError[] = []; //name is error instead of errors because that's what's sent by Chicago Title

  constructor(c?: ChicagoBaseResponse) {
    super(c);
    if (c && c.error) {
      for (let e of c.error) {
        this.error.push(new ChicagoError(e));
      }
    }
  }

  get isSuccessful(): boolean {
    return this.responseCode == 0;
  }
}
