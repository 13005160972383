import {SoAdjWithTax} from './so-adj-with-tax';
import Utils from '../../../shared-main/utils';

export type TenantUnitOccupiedType = 'SPECIFY' | 'SILENT';
export type TenantCalculationMethodType = 'USE_INTEREST_TABLE' | 'ONTARIO_RESIDENTIAL_TENANCIES_ACT_2006' | 'FLAT_INTEREST_RATE';
export type TenantPrintingFormatType = 'FINAL_AMOUNT_ONLY' | 'ITEMIZED_CALCULATION';

export class SoAdjTenancyPrepaid extends SoAdjWithTax {

  id: number;
  unitOccupied: TenantUnitOccupiedType;
  areaOccupiedDescription: string;
  prepaidRentAmount: number;
  receiveCreditWithInterest: boolean;
  performInterestCalculation: boolean;
  calculationMethod: TenantCalculationMethodType;
  printingFormat: TenantPrintingFormatType;
  interestCalculatedFromDate: string;
  interestRate: number;
  interestAmount: number;

  get hstOrGstValueFromPrepaidRentAmount(): number {
    return this.getHstOrGstValueForAmount(this.prepaidRentAmount);
  }

  get pstValueFromPrepaidRentAmount(): number {
    return this.getPstValueForAmount(this.prepaidRentAmount);

  }

  get receiveCreditWithInterestBoolean(): boolean {
    return Utils.convertToBoolean(this.receiveCreditWithInterest);
  }

  get performInterestCalculationBoolean(): boolean {
    return Utils.convertToBoolean(this.performInterestCalculation);
  }

  getNumberOfDaysElapsed(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    if (this.isNotValidDate(this.interestCalculatedFromDate) || this.isNotValidDate(closingDate)) {
      return 0;
    } else {
      let elapsedDays: number = this.getDateDiff(this.interestCalculatedFromDate, closingDate);
      if (isPaysForDateOfClosingVendor) {
        elapsedDays++;
      }
      return elapsedDays;
    }
  }

  calculatedInterest: number;

  get totalCreditPurchaser(): number {
    let result: number = Number(this.prepaidRentAmount) + this.getHstOrGstValueForAmount(this.prepaidRentAmount) + this.getPstValueForAmount(this.prepaidRentAmount);

    if (this.receiveCreditWithInterestBoolean) {
      if (this.performInterestCalculationBoolean) {
        result += +this.calculatedInterest;
      } else {
        result += +this.interestAmount;
      }
    }

    return result;
  }

  // get adjustmentDescription(){
  //     let result: string = 'PRE-PAID RENT';
  //     if (this.unitOccupied == 'SPECIFY' && this.areaOccupiedDescription && this.areaOccupiedDescription.length > 0){
  //         result += ' - ' + this.areaOccupiedDescription;
  //     }
  //     return result;
  // }
}
