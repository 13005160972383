import {Inject, Injectable} from '@angular/core';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustmentDisplayBuilder} from '../builders/statement-adjustment-display-builder';
import {SelectItem} from 'primeng/api';
import {booleanYesNoDropDowns} from '../../../shared-main/constants';
import {AdjustmentTemplateSavePredefinedModalComponent} from './adjustment-template/adjustment-template-save-predefined.modal.component';
import {StatementAdjustment} from '../statement-adjustment';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {AdjustmentTemplateRetrievePredefinedModalComponent} from './adjustment-template/adjustment-template-retrieve-predefined.modal.component';
import {ModalResult} from '../../../shared-main/enums';
import {AdjustmentTemplate} from '../model/adjustment-template';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export type StatementOfAdjustmentCreditType = 'VENDOR' | 'PURCHASER' | 'NO_ADJUSTMENT';

@Injectable()
export class AdjustmentBaseModalComponent<TADJUSTMENT extends BaseEntity, TCONTEXT> extends ModalComponent<TCONTEXT> {
  localAdjustment: TADJUSTMENT;
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  taxTypes: SelectItem[] = [
    {label: 'HST', value: 'HST'},
    {label: 'GST', value: 'GST'}
  ]; // only for ON as of Oct2019
  statementAdjustmentKey: string;

  constructor(
    public dialog: MatDialogRef<any, any>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TCONTEXT
  ) {
    super(dialog, context);
  }

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.statementAdjustmentDisplayUtil && this.cachedDetails.length == 0) {
      this.cachedDetails = this.statementAdjustmentDisplayUtil.getDisplayItems(
        this.localAdjustment,
        '',
        true,
        this.statementAdjustmentKey
      );
    }
    return this.cachedDetails;
  }

  saveAdjustmentTemplate(statementAdjustment: StatementAdjustment, modalHeader: string): void {
    this.dialogService.matDialogContent({
      modalGrid: 6,
      content: AdjustmentTemplateSavePredefinedModalComponent,
      context: {
        statementAdjustment: statementAdjustment,
        modalHeader: modalHeader
      }
    });
  }

  retrievePredefined(provinceCode: string, itemKey: string) {
    this.dialogService.matDialogContent({
      content: AdjustmentTemplateRetrievePredefinedModalComponent,
      context: {
        provinceCode: provinceCode,
        itemKey: itemKey
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === ModalResult.OK) {
            this.copyFrom(result.adjustmentTemplate);
          }
        }
      }
    });
  }

  copyFrom(adjustmentTemplate: AdjustmentTemplate): void {}
}
