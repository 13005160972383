import {Address, SameAsAddressOption} from './../../matters/shared/address';
import {TypeCodesService} from './../type-codes.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {errorAddressMessage} from '../address-Form';
import {AddressDropdowns, dropDowns} from './drop-downs';
import {SelectItem} from 'primeng/api';
import {AddressTypes} from '../../matters/shared/address-types';
import {currentMatter} from '../../matters/shared';
import {Account} from '../../admin/accounts/shared/account';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {TempAccountService} from './temp-account.service';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DocumentProfileCache} from '../document-profile-cache.service';
import {Observable, Subject} from 'rxjs';
import {AddressSearchService} from './address-search.service';
import {AddressSourceType, Place} from '../../matters/shared/place';
import {Constants} from '../constants';
import {Utils} from '../../matters/shared/utils';
import {AddressUtil} from '../../matters/shared/address-util';

@Component({
  selector: 'dp-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: [
    './address-form.component.scss'
  ]
})
export class AddressFormComponent implements OnInit, AfterViewInit, OnChanges {
  @Output() updateAddress = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() makeAddressPrimary: EventEmitter<Address> = new EventEmitter<Address>();
  @Input('showMandatoryFields') showMandatoryFields: boolean = false;
  @Input('showMandatoryProvince') showMandatoryProvince: boolean = false;
  @Input('address') address: Address;
  @Input('page') page;
  @Input('addressType') addressType: string;
  @Input('showDropdown') showDropdown: boolean = false;
  @Input('showAddressTypeDropdown') showAddressTypeDropdown: boolean = false;
  @Input('addressFlags') addressFlags;
  @Input('submitted') submitted;
  @Input('addressSummaryLabel') addressSummaryLabel: string;
  @Input('disabled') disabled: boolean = false;
  @Input('provinceReadOnly') provinceReadOnly: boolean = false;
  @Input('provinceName') provinceName: string;
  @Input('city') city: string;
  @Input('fieldCodeMap') fieldCodeMap: any = [];
  @Input('closeShutter') closeShutter: boolean = false;
  @Input('addressMaxLength') addressMaxLength: number = Address.ADDRESS_LINE_DEFAULT_MAX_LENGTH;

  // @Input('addressDd') addressDd : any[] = [];
  @Input('fieldPrefix') fieldPrefix: string; //used to prefix the field key to uniquely identify the fields in re-usable components
  @Input('parentContainer') parentContainer: string;

  @Input('offsetShutter') offsetShutter: boolean = false;
  @Input('offsetShutterOther') offsetShutterOther: boolean = false;
  @Input('primaryShutter') primaryShutter: boolean = false;
  @Input('primaryShutterOther') primaryShutterOther: boolean = false;
  @Input('secondaryShutter') secondaryShutter: boolean = false;
  @Input('defaultForProvinceAndCountry') defaultForProvinceAndCountry: boolean = true;

  @Input('populateDefaultProvince') populateDefaultProvince: boolean = true;

  @ViewChild('addressForm') form;
  @ViewChild('_postalCode_') postalCodeElement: ElementRef;

  @ViewChild('street') streetElement;

  _addressDd: any[] = [];
  @Input() set addressDd(addressDd: any[]) {
    this._addressDd = addressDd;
    this.setDropdown();

  }

  get addressDd(): any[] {
    return this._addressDd;
  }

  @Input() set refreshLocal(refresh: boolean) {
    if (refresh && this.address) {
      this.localProvinceName = this.address.provinceName;
    }
  }

  provinceNameList: SelectItem[];
  openForm: boolean = false;
  previousAddressText: string;
  account: Account;
  documentProfileAccount: Account;
  documentProfile: DocumentProfile;
  addressDropdownValue: string;
  addressSuggestions: any = [];
  addressSuggestionsLoading: boolean = false;
  searchAddressStream = new Subject<string>();
  fieldIndex: string = 'completeAddress';
  userLocation: Location;
  countryList: SelectItem[] = dropDowns.countryList;
  selectedAddressSource: AddressSourceType = 'CANADA_POST';
  selectedCountryForSearch: any;
  localProvinceName: string;
  cachedDefaultProvinceName: string;

  sameAsAddressOptions: SameAsAddressOption[];
  currentSameAsAddressOption: SameAsAddressOption;
  isSameAs: boolean = false;
  utils: Utils;

  omniSearchForCountryLoading: boolean = false;
  countrySuggestions: SelectItem[] = [];

  constructor(public typeCodesService: TypeCodesService,
              public accountService: TempAccountService,
              public errorService: ErrorService,
              public logger: Logger,
              public documentProfileCache: DocumentProfileCache,
              public addressSearchService: AddressSearchService,
              private e: ElementRef) {
    this.utils = new Utils();
  }

  ngOnInit(): void {
    //Loading user's current location
    //ToDo: Can be moved to a common place like configuration
    this.setCurrentPosition();
    this.e.nativeElement.setAttribute('xpathId', this.addressSummaryLabel);
    //if address is empty and it's not same as any other address then by default shutter should be open
    // this.openForm = !this.closeShutter && this.address.isEmpty && !this.address.sameAsAddressTypeCode && !this.disabled;
    //DPPMP-8469, as long as the address is empty and not ready only, address form should be opened for edit
    this.openForm = !this.closeShutter && this.address && this.address.isEmpty && !this.disabled;
    this.setDropdown();
    if (!this.defaultForProvinceAndCountry && !this.address.country && !this.address.provinceName) {
      this.selectedCountryForSearch = '';
      this.address.country = '';
      this.address.provinceName = '';
    }
    if (this.defaultForProvinceAndCountry) {
      // this.closed = true;
      // for LegalFirm page default province is ON
      if (this.page === 'LegalFirm') {
        this.provinceNameList = dropDowns.provinceName;
        //this.address.provinceCode = 'ON';
        //this.localProvinceName = 'Ontario';
        if (this.address) {
          this.address.country = 'Canada';
        }
      }

      // I don't understand why the label and value of country drop down list are different.
      // It looks the back end DB saves label not value. We can let label same as with value to reduce so much trouble.
      // It looks the DB size of country(32) is not enough. It should open a bug for it.
      if (this.address && this.address.country) {
        let countryOption: SelectItem = this.countryList.find(x => x.label == this.address.country);
        this.selectedCountryForSearch = countryOption ? countryOption.label : this.address.country;
      } else {
        this.selectedCountryForSearch = 'Canada';
        if (this.address) {
          this.address.country = 'Canada';
        }
      }
      this.previousAddressText = this.address && this.address.addressTextWithProvinceName;
      // AutoComplete Address
      this.searchAddressStream
      .switchMap((term: string) => {
        this.addressSuggestionsLoading = true;
        if (term.trim() === '') {
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
          return observable;
        } else {
          return this.addressSearchService.searchPlaces(term, this.selectedAddressSource, this.selectedCountryForSearch, this.userLocation);
        }
      })
      .subscribe(
        (data: Place[]) => {
          this.addressSuggestions = data;
          this.addressSuggestionsLoading = false;
        },
        error => {
          this.logger.error('Address search error: ', error);
          this.addressSuggestionsLoading = false;
        }
      );

      if (!this.address || (!this.address.addressLine1 && !this.address.addressLine2 && !this.address.city && !this.address.provinceName && !this.address.postalCode)) {
        if (this.populateDefaultProvince) {
          this.localProvinceName = this.defaultProvinceValue();
        }
      } else {
        this.localProvinceName = this.address.provinceName;
      }
      this.populateProvince();
    }
  }

  //set current user's position for localized address auto complete
  public setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.userLocation = new Location();
        this.userLocation.latitude = position.coords.latitude;
        this.userLocation.longitude = position.coords.longitude;
      });
    }
  }

  dataSelectedAddress(event) {
    this.address.addressLine1 = ' ';
    if (event && event.description && event.description.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
      this.resetAddressFields(this.address);
    } else {
      if (event.source === 'CANADA_POST' && event.nextStep === 'Find') {
        this.address.addressLine1 = event.description;
        this.addressSuggestionsLoading = true;
        this.getPlaceListById(event);
      } else {
        this.getPlaceDetail(event);
      }
    }
  }

  getPlaceListById(selectedPlace: Place): void {
    let encodedPlaceId: string = encodeURIComponent(selectedPlace.placeId);
    this.addressSearchService.searchPlacesById(encodedPlaceId).subscribe(
      (data: Place[]) => {
        this.addressSuggestions = data;
        this.addressSuggestionsLoading = false;
        if (data.length == 0) {
          this.resetAddressFields(this.address);
        } else {
          //autocomplete won't show the dropdown by default since the suggestion value changes not due to search func.
          this.streetElement.handleDropdownClick();
        }
      },
      error => this.logger.error('getPlaceListById error: ', error)
    );
  }

  //reset the address field value
  resetAddressFields(address: Address): void {
    address.addressLine1 = null;
    address.addressLine2 = null;
    address.city = null;
    address.provinceCode = null;
    address.provinceName = null;
    address.postalCode = null;
  }

  getPlaceDetail(selectedPlace: Place): void {
    this.addressSearchService.getPlaceDetail(selectedPlace).subscribe(
      (place: Place) => {
        if (place) {
          this.address.addressLine1 = place.addressLine1;
          this.address.addressLine2 = place.addressLine2;
          this.address.city = place.city;
          this.address.country = place.country;
          // After search, it should transfer it to the country drop down format
          // For example, if the search back is "CANADA", it should transfer { label: 'Canada', value: 'CA' } instead of adding a new "CANADA" item.
          if (place.country) {
            const country = this.countryList.find(item => item.label && item.label.toLowerCase() && item.label.toLowerCase() === place.country.toLowerCase());
            if (country) {
              this.address.country = country.label;
              this.selectedCountryForSearch = this.address.country;
            }
          }
          this.address.provinceName = place.provinceName;
          this.localProvinceName = this.address.provinceName;
          this.address.postalCode = place.postalCode;
          this.addProvinceCode();
          this.validatePostalCode();

        } else {
          this.resetAddressFields(this.address);
        }
      },
      error => this.logger.error('getPlaceDetail error: ', error)
    );

  }

  onStreetLineChange(event) {
    this.address.addressLine1 = event;
    this.populateProvince();
  }

  onAddressChange(event) {
    if (event.keyCode != 9      //Tab Key
      && event.keyCode != 13  //Enter key
      && event.keyCode != 37  //Left arrow key
      && event.keyCode != 38  //Up arrow key
      && event.keyCode != 39  //Right arrow key
      && event.keyCode != 40  //Down arrow key
    ) {
      this.address.sameAsImportedAddress = false;
    }
  }

  searchAddresses(event): void {
    let entered: string = event.query;
    if (entered && entered.trim().length > 0) {
      this.searchAddressStream.next(entered);
    }
  }

  get cachedDocumentProfile(): DocumentProfile {
    return this.documentProfileCache.cachedDocumentProfile;
  }

  public extractCityName(name: string): string {
    let ret: string = name;

    if (name) {
      // regex captures value2 in the string format "<value1> of <value2>"
      let regex: RegExp = new RegExp(/^\w+\sof\s(.+)$/, 'g');
      let matches: string[] = regex.exec(name);

      if (matches && matches.length === 2) {
        ret = matches[ 1 ];
      }
    }
    return ret;
  }

  dynamicF9helpCity = () => {
    if (this.fieldPrefix && (this.fieldPrefix == 'property.address' || this.fieldPrefix == 'pin.address') && currentMatter && currentMatter.value && currentMatter.value.matterPropertyWithCondo && currentMatter.value.matterPropertyWithCondo.jurisdiction
      && currentMatter.value.matterPropertyWithCondo.jurisdiction.city) {
      return 'F9 = ' + this.extractCityName(currentMatter.value.matterPropertyWithCondo.jurisdiction.city.name);
    } else {
      return this.cachedDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile.cityOf ? 'F9 = ' + this.cachedDocumentProfile.firmDocumentProfile.cityOf : '';
    }
  };

  dynamicF9helpProvince = () => {
    if (this.cachedDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile.address && this.cachedDocumentProfile.firmDocumentProfile.address.provinceName) {
      return 'F9 = ' + this.cachedDocumentProfile.firmDocumentProfile.address.provinceName;
    } else {
      if (this.cachedDefaultProvinceName) {
        return 'F9 = ' + this.cachedDefaultProvinceName;
      }
    }
    return 'F9 = ' + this.cachedDefaultProvinceName;
  };

  // Move the below functionality to populate default province into some common util class and use that from both places.
  // then no one has to worry about maintaining it in all the places.
  defaultProvinceValue(): string {
    //Use localObject to get back local variable value
    let localObject = {localProvinceName: this.localProvinceName, cachedDefaultProvinceName: this.cachedDefaultProvinceName};
    let provinceValue = AddressUtil.setDefaultProvinceValue(this.address, this.defaultForProvinceAndCountry, this.cachedDocumentProfile,
      currentMatter && currentMatter.value && currentMatter.value.provinceCode, localObject);
    // Update local variable
    this.localProvinceName = localObject.localProvinceName;
    this.cachedDefaultProvinceName = localObject.localProvinceName;
    return provinceValue;
  }

  updateLocalProvinceName() {
    this.localProvinceName = this.address.provinceName;
  }

  setDropdown() {
    //Add this.address null check to avoid breaking UI
    if (!this.address) {
      return;
    }
    if (this.address.sameAsAddressTypeCode === AddressTypes.mailing) {
      this.addressDropdownValue = AddressDropdowns.sameAsMailing;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.solicitorAddress) {
      this.addressDropdownValue = AddressDropdowns.sameAsSolicitor;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.serviceAddress) {
      this.addressDropdownValue = AddressDropdowns.sameAsService;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.firmMailing) {
      this.addressDropdownValue = AddressDropdowns.sameAsFirm;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.preClosingAddress) {
      this.addressDropdownValue = AddressDropdowns.sameAsPreClosing;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.subjectAddress) {
      this.addressDropdownValue = AddressDropdowns.sameAsSubject;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.customerAccount && this.addressType && this.addressType == AddressTypes.solicitorAddress) {
      //Back end global solicitor is 'CUSTOMER_ACCOUNT'. However UI is 'FIRM_MAILING' instead of 'CUSTOMER_ACCOUNT'.
      this.addressDropdownValue = AddressDropdowns.sameAsFirm;
    } else if (this.address.sameAsAddressTypeCode === AddressTypes.customerAccount) {
      this.addressDropdownValue = AddressDropdowns.sameAsAccount;
    } else {
      this.addressDropdownValue = AddressDropdowns.manuallyEntered;
    }
    if (this.addressDropdownValue && this.addressDropdownValue !== AddressDropdowns.manuallyEntered) {
      // this.openForm = false;
    }

    if (Array.isArray(this.addressDd) && this.addressDd.length > 0 && typeof this.addressDd[ 0 ] === 'string') {
      this.sameAsAddressOptions = this.addressDd.map(item => {
        return {
          description: item
        } as SameAsAddressOption;
      });
    } else {
      this.sameAsAddressOptions = this.addressDd as SameAsAddressOption[];
    }

    if (Array.isArray(this.sameAsAddressOptions)) {
      this.currentSameAsAddressOption = this.sameAsAddressOptions.find(item => {
        //Back end global solicitor is 'CUSTOMER_ACCOUNT'. However UI is 'FIRM_MAILING' instead of 'CUSTOMER_ACCOUNT'.
        if (item.description === this.addressDropdownValue
          || (item.description === AddressTypes.customerAccount
            && AddressTypes.firmMailing === this.addressDropdownValue
            && this.addressType == AddressTypes.solicitorAddress)) {
          return true;
        }
      });
    }

    if (this.showDropdown) {
      if (Array.isArray(this.sameAsAddressOptions) && this.sameAsAddressOptions.length > 0) {
        const sameAsAddressOption: SameAsAddressOption
          = this.sameAsAddressOptions.find(
          (item: SameAsAddressOption) => {
            if ((this.transferAddressDropdownToAddressType(item.description) === this.address.sameAsAddressTypeCode)
              || (AddressTypes.customerAccount === this.address.sameAsAddressTypeCode
                && this.transferAddressDropdownToAddressType(item.description) === AddressTypes.firmMailing
                && this.addressType == AddressTypes.solicitorAddress)) {
              return true;
            }
          });
        if (sameAsAddressOption) {
          this.updateAddressSameAs(sameAsAddressOption);
        }
      }
    }

    if (this.addressDropdownValue === AddressDropdowns.manuallyEntered) {
      this.isSameAs = false;
    } else {
      this.isSameAs = true;
    }
  }

  transferAddressDropdownToAddressType(addressDropdownOption: string): string {
    let ret: string = null;
    switch (addressDropdownOption) {
      case AddressDropdowns.sameAsService :
        ret = AddressTypes.serviceAddress;
        break;
      case AddressDropdowns.sameAsMailing :
        ret = AddressTypes.mailing;
        break;
      case AddressDropdowns.sameAsFirm :
        ret = AddressTypes.firmMailing;
        break;
      case AddressDropdowns.sameAsPreClosing :
        ret = AddressTypes.preClosingAddress;
        break;
      case AddressDropdowns.sameAsSubject :
        ret = AddressTypes.subjectAddress;
        break;
      case AddressDropdowns.manuallyEntered :
        ret = AddressTypes.manuallyEntered;
        break;
      case AddressDropdowns.sameAsAccount :
        ret = AddressTypes.customerAccount;
        break;
      case AddressDropdowns.sameAsSolicitor :
        ret = AddressTypes.solicitorAddress;
        break;
      default :
        break;
    }
    return ret;
  }

  // after the form change this will class the change method to check the global validation.
  ngAfterViewInit() {
    if (!this.addressSummaryLabel) {
      this.addressSummaryLabel = 'Complete Address';
    }

    if (this.form) {
      this.form.control.valueChanges.debounceTime(500)
      .subscribe(values => this.onValueChanged(values));
    }

  }

  isAddressManuallyEntered(): boolean {
    return this.addressDropdownValue === AddressDropdowns.manuallyEntered;
  }

  isAddressFieldDisplayed(): boolean {
    if (this.showDropdown) {
      if (this.addressDropdownValue !== AddressDropdowns.manuallyEntered) {
        return false;
      }
      return true;
    }
    return true;
  }

  shouldThisToggle(event): void {
    if (event.target instanceof HTMLSelectElement || !this.isAddressFieldDisplayed()) {
      if (this.addressDropdownValue !== AddressDropdowns.manuallyEntered) {
        // this.openForm = false;
      }
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /*    getDataForValidator() : Object {
          if(this.parentContainer) {
              return {elementFunction : this.checkPostCode, data : {containerId : this.parentContainer}};
          }
          return {elementFunction : this.checkPostCode};
      }*/

  shouldFormOpen(sameAsAddressOption: SameAsAddressOption) {
    this.addressDropdownValue = sameAsAddressOption && sameAsAddressOption.description;
    this.changeAddressSameAs(sameAsAddressOption);
    if (sameAsAddressOption.description === AddressDropdowns.manuallyEntered) {
      this.openForm = true;
      this.isSameAs = false;
    } else {
      this.isSameAs = true;
    }
  }

  addressTypeChanged(addressTypeOption: string) {
    //Bug DPPMP-17924 - The select-box has a two-way binding with the addressTypeCode,
    //time out is used to emit the updated addressTypeCode not the old value.
    setTimeout(() => {
      this.onChange.emit({value: this.address});
    }, 0);

  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // console.log('ngOnChange:', simpleChanges);
    if (simpleChanges[ 'provinceName' ]) {
      this.localProvinceName = this.address.provinceName;
    }
    if (simpleChanges[ 'address' ]) {
      this.previousAddressText = this.address ? this.address.addressTextWithProvinceName : null;
      if (this.address && this.address.country) {
        let countryOption: SelectItem = this.countryList.find(x => x.label == this.address.country);
        this.selectedCountryForSearch = countryOption ? countryOption.label : this.address.country;
      } else {
        this.selectedCountryForSearch = 'Canada';
      }
      if (!this.address || (!this.address.addressLine1 && !this.address.addressLine2 && !this.address.city && !this.address.provinceName && !this.address.postalCode)) {
        if (this.populateDefaultProvince) {
          this.localProvinceName = this.defaultProvinceValue();
        }
      } else {
        this.localProvinceName = this.address.provinceName;
      }

      this.validatePostalCode();
      this.setDropdown();
    }
    if (simpleChanges[ 'disabled' ]) {
      // this.openForm = this.address.isEmpty && !this.address.sameAsAddressTypeCode && !this.disabled;
      this.openForm = this.address && this.address.isEmpty && !this.disabled;
    }
    if (simpleChanges[ 'closeShutter' ]) {
      if (this.closeShutter) {
        this.openForm = false;
      }
    }
  }

  // on change of the form this method will be called and
  // it will update the error message dynamically
  onValueChanged(data?: any) {
    let valueChanged: boolean = false;

    // this is to prevent the address being updated after ngAfterViewInit and nothing is updated.
    for (let prop in data) {
      if (typeof data[ prop ] !== 'undefined') {
        valueChanged = true;

        break;
      }
    }

    if (valueChanged && this.address) {
      this.address.setAddressHash();
      let newAddressSummary = this.address.addressTextWithProvinceName;
      if (this.previousAddressText !== newAddressSummary) {
        this.onChange.emit({value: this.address});
        this.previousAddressText = newAddressSummary;
      }
      this.address.errorMessage = ' ';
      for (let prop in this.form.form.controls) {
        if (this.form.form.controls[ prop ]._errors !== null) {
          this.address.errorMessage = this.address.errorMessage + errorAddressMessage[ prop ];
        }
      }
      this.address.isAddressValid = this.form && this.form.valid;
    }
  }

  changeAddressSameAs(sameAsAddressOption: SameAsAddressOption) {
    this.updateAddressSameAs(sameAsAddressOption);
    this.onChange.emit({value: this.address, sameAs: sameAsAddressOption});
  }

  updateAddressSameAs(sameAsAddressOption: SameAsAddressOption) {
    if (!sameAsAddressOption) {
      return;
    }
    const oldAddress = new Address(this.address);
    //If it is not manuallyEntered, point to sameAsAddressOption.srcAddress reference to get latest value
    this.address.addressTypeCode = oldAddress.addressTypeCode;

    switch (sameAsAddressOption.description) {
      case AddressDropdowns.sameAsService :
        this.address.sameAsAddressTypeCode = AddressTypes.serviceAddress;
        break;
      case AddressDropdowns.sameAsMailing :
        this.address.sameAsAddressTypeCode = AddressTypes.mailing;
        break;
      case AddressDropdowns.sameAsFirm :
        this.address.sameAsAddressTypeCode = AddressTypes.firmMailing;
        break;
      case AddressDropdowns.sameAsPreClosing :
        this.address.sameAsAddressTypeCode = AddressTypes.preClosingAddress;
        break;
      case AddressDropdowns.sameAsSubject :
        this.address.sameAsAddressTypeCode = AddressTypes.subjectAddress;
        break;
      case AddressDropdowns.manuallyEntered :
        this.address.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
        break;
      case AddressDropdowns.sameAsAccount :
        this.address.sameAsAddressTypeCode = AddressTypes.customerAccount;
        break;
      case AddressDropdowns.sameAsSolicitor :
        this.address.sameAsAddressTypeCode = AddressTypes.solicitorAddress;
        break;
      default:
        break;
    }
  }

  getCompleteAddress(): string {
    let ret: string;
    let sameAsAddress: Address;
    if (this.address.sameAsAddressTypeCode && this.address.sameAsAddressTypeCode !== AddressTypes.manuallyEntered) {
      sameAsAddress = this.currentSameAsAddressOption && this.currentSameAsAddressOption.srcAddress;
    } else {
      sameAsAddress = this.address;
    }
    if (sameAsAddress) {
      ret = this.page === 'LegalFirm' ? sameAsAddress.addressText : sameAsAddress.addressTextWithProvinceName;
    }

    // console.log('getCompleteAddress: ', ret);
    return ret;
  }

  addProvinceCode(): void {
    //If there is any change, please add into addProvinceCodeAction
    AddressUtil.addProvinceCodeAction(this.address, this.localProvinceName);
  }

  captureF9ForCityField(): void {
    this.populateCityField();

  }

  captureF9ForProvinceField(): void {
    this.populateProvinceField();
    this.populateCountryField();
  }

  captureF9ForCountryField(): void {
    if (!this.disabled) {
      this.populateCountryField();
    }
    // this.selectedCountryForSearch = 'CA';
  }

  populateCityField(): void {
    if (this.city) {
      this.address.city = this.extractCityName(this.city);
    } else if (this.fieldPrefix && this.fieldPrefix == 'pin.address' && currentMatter && currentMatter.value && currentMatter.value.matterPropertyWithCondo && currentMatter.value.matterPropertyWithCondo.jurisdiction && currentMatter.value.matterPropertyWithCondo.jurisdiction.city) {
      this.address.city = this.extractCityName(currentMatter.value.matterPropertyWithCondo.jurisdiction.city.name);
    } else if (this.cachedDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile && this.cachedDocumentProfile.firmDocumentProfile.cityOf) {
      this.address.city = this.cachedDocumentProfile.firmDocumentProfile.cityOf;
      this.populateProvinceField();
      this.populateCountryField();
    }
  }

  populateProvince(): void {
    //If there is any change, please add into populateProvinceAction
    AddressUtil.populateProvinceAction(this.address, this.defaultForProvinceAndCountry, this.localProvinceName);
  }

  populateProvinceField(): void {
    this.address.provinceName = this.defaultProvinceValue();
    this.localProvinceName = this.address.provinceName;
    this.addProvinceCode();
  }

  populateCountryField(): void {
    this.selectedCountryForSearch = 'Canada';
    this.address.country = 'Canada';
    setTimeout(() => {
      this.postalCodeElement.nativeElement.focus();
    }, 50);
  }

  // getProvinceName(provinceCode) : string {
  //     let provinceObj = dropDowns.provinceName.find((item : any) => item.value == provinceCode);
  //     return provinceObj ? provinceObj.label : '';
  // }

  toggle(event: KeyboardEvent): void {
    let target = event.currentTarget as HTMLElement;
    if (this.addressDropdownValue === AddressDropdowns.manuallyEntered) {
      if (target.id === 'addressShutter' && !(event.target instanceof HTMLInputElement)) {
        this.openForm = !this.openForm;
      }
    }
  }

  setAsPrimaryAddress(): void {
    this.makeAddressPrimary.emit(this.address);
    event.stopPropagation();
  }

  // isCountryCanada : boolean = true;

  isCountryCanada(): boolean {
    return this.selectedCountryForSearch == 'CA' || (this.selectedCountryForSearch && this.selectedCountryForSearch.toUpperCase() == 'CANADA');
  }

  //From existing code, this.address.country(in DB) was the label of countryList not value
  updateAddressCountryValue(event): void {
    // this.isCountryCanada = event.currentTarget.value == 'CA';
    let countryOption: SelectItem = this.countryList.find(x => x.value == this.selectedCountryForSearch);
    this.address.country = countryOption ? countryOption.label : this.selectedCountryForSearch;
    this.validatePostalCode();
    this.populateProvince();
  }

  validatePostalCode(): void {
    if (this.address && this.address.postalCode) {
      this.address.postalCode = this.address.postalCode.trim();
    }
    //let pattern = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\d[ABCEGHJKLMNPRSTVWXYZ]\d/i);
    let pattern = new RegExp(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i);
    // https://howtodoinjava.com/regex/java-regex-validate-canadian-postal-zip-codes/
    // Postal codes do not include the letters D, F, I, O, Q or U,
    // and the first position also does not make use of the letters W or Z
    let postalCodeFieldKey = this.fieldPrefix + '.postalCode';
    let postalCodeFieldCode = this.fieldCodeMap[ 'postalCode' ];
    let addressId: string = '';
    if (this.address && this.address.id) {
      addressId = this.address.id.toString();
    }

    if (this.address && this.address.id && this.isCountryCanada() && this.address.postalCode && !pattern.test(this.address.postalCode)
      || this.address && this.isCountryCanada() && this.address.postalCode && !pattern.test(this.address.postalCode)) {
      //Remove old one one, add new one.
      this.errorService.removeDpFieldError(postalCodeFieldKey, addressId);
      this.errorService.addDpFieldError(DPError.createDPError(postalCodeFieldKey, addressId));
    } else {
      if (this.address && this.address.id
        || this.address && this.isCountryCanada() && this.address.postalCode) {
        this.errorService.removeDpFieldError(postalCodeFieldKey, addressId);
      }
    }

  }

  handleDropdownClicksearchCountry() {
    this.countrySuggestions = this.countryList.slice();
  }

  omniSearchCountry(event) {
    console.log('omniSearchCountry : ', event);
    const query: string = event.query.trim();
    this.countrySuggestions = !query
      ? this.countryList.slice()
      : this.countryList.filter(x => x.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
    if (this.countrySuggestions.length === 0) {
      this.countrySuggestions.push({label: Constants.NO_RESULTS_FOUND, value: Constants.NO_RESULTS_FOUND});
    }
  }

  dataSelectedAddressCountry(event) {
    console.log('onSelect : ', event);
    if (event.label === Constants.NO_RESULTS_FOUND) {
      this.selectedCountryForSearch = null;
      setTimeout(() => {
        if (this.address.country == Constants.NO_RESULTS_FOUND) {
          this.address.country = null;
          this.selectedCountryForSearch = null;
        } else {
          this.selectedCountryForSearch = this.address.country;
        }
        this.validatePostalCode();
      }, 0);
    } else {
      this.address.country = event.label;
      if (this.selectedCountryForSearch !== event.label) {
        this.selectedCountryForSearch = event.label;
        this.validatePostalCode();
      } else {
        //If the same country is selected, "this.selectedCountryForSearch = event.label;" does not work.
        // We have to set 'selectedCountryForSearch' as null, then use timeout to set the correct value back
        // I suspect it is primeng's bug
        this.selectedCountryForSearch = null;
        setTimeout(() => {
          this.selectedCountryForSearch = event.label;
          this.validatePostalCode();
        }, 0);
      }
    }
  }

  onCountryChange(event) {
    if (typeof event == 'string') {
      this.address.country = event;
      this.selectedCountryForSearch = this.address.country;

    }
    this.address.sameAsImportedAddress = false;
    this.updateAddress.emit({value: this.address});
  }

  ngDoCheck() {

  }
}

export class Location {
  ////In Google api The location must be specified as latitude,longitude.
  latitude: number;
  longitude: number;

  toUrl(): string {
    return this.latitude + ',' + this.longitude;
  }
}
