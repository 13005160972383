import {Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {contactDropDowns} from '../../contact/contact-drop-downs';
import {currentMatter} from '../shared/current-matter';
import {MatterParticipantWrapper} from '../shared/matter-participant-wrapper';
import {ContactQueryService} from '../../contact/contact-query.service';
import {ContactService} from '../../shared-main/contact.service';
import {Observable, Subject} from 'rxjs';
import {Logger} from '@nsalaun/ng-logger';
import {Contact} from '../shared/contact';
import {MatterParticipant} from '../shared/matter-participant';
import {contactTypeMapping} from '../../contact/contact-type-mapping';
import * as _ from 'lodash';
import {ContactInfo} from '../shared/contact-info';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {CommonContactDialogComponent} from '../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {NgForm} from '@angular/forms';
import {TabsService} from '../../core/tabs.service';
import {ContactTab} from '../../contact/contact-tab';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {constValues} from '../shared/const-values';
import {
  Constants,
  MORTGAGE_BC_HOME_INSURANCE_GST_PERCENTAGE,
  RevertToGlobalMessage,
  SnapshotBurgerMenuActions
} from '../../shared-main/constants';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {PartialDateComponent} from '../../shared/partial-date/partial-date.component';

import {InterestOnMortgage} from './interest-mortgage';
import {ContactCommandService} from '../../contact/contact-command.service';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {dropDowns} from '../shared';
import {Matter} from '../shared/matter';
import {Mortgage} from '../shared/mortgage';
import moment from 'moment';
import Utils from '../../shared-main/utils';
import {ErrorService} from '../../shared/error-handling/error-service';
import {MatterParticipantService} from '../matter-participant-service';
import {MassUpdateData, MassUpdateTab} from '../../shared/tabbing/mass-update-tab';
import {LockScreenService} from '../../core/lock-screen.service';
import {DocumentProductionService} from '../document-production/document-production.service';
import {DpBooleanValueTypes} from '../shared/dp-boolean';

declare var jQuery: any;

@Component({
  selector: 'dp-fire-insurance',
  templateUrl: 'fire-insurance.component.html',
  styleUrls: [
    './fire-insurance.styles.scss'
  ],
  providers: [ EmailFieldService ]
})
@FocusFirstElementDecorator()
export class FireInsuranceComponent implements OnInit, OnDestroy {

  @ViewChild('fireInsuForm') fireInsuForm: NgForm;
  @ViewChild('focusOnLoad') focusOnLoad: ElementRef;
  @ViewChild('partialExpiryDate') partialExpiryDateComponent: PartialDateComponent;
  //# DropDown
  insuranceBrokerTypes: SelectItem[];
  documentationTypes: SelectItem[];
  guaranteedReplacementCoverageS: SelectItem[];
  mortgageSelectedOptions: SelectItem[];
  mortgageeInterestOnPolicyOptions: SelectItem[];
  interestOnMortgagees: InterestOnMortgage[];
  _matter: Matter;

  // # Global Data

  //Insurance Data
  selectedInsurer: MatterParticipantWrapper;
  // selectedInsurerSourceContact : Contact;

  //Broker Data
  selectedBroker: MatterParticipantWrapper;
  // selectedBrokerSourceContact : Contact;

  //Either Broker Or Insurance Data
  selectedInsurerBroker: any;
  // # Local data
  isAddressDisabled: boolean = true;
  expanded: boolean = false;
  subscription: any;
  selectedInsuranceBrokerContactTypeMapping: any;

  //AutoComplete Broker
  brokersLoading: boolean = false;
  searchBrokerSubject: Subject<string> = new Subject<string>();
  // autoCompleteEmptyFlag : any;
  brokers: Contact[];

  //AutoComplete Insurer
  insurersLoading: boolean = false;
  searchInsurerSubject: Subject<string> = new Subject<string>();
  insurers: Contact[];

  //AutoComplete both Broker Insurer
  insurersBrokersLoading: boolean = false;
  searchBrokerInsurerSubject: Subject<string> = new Subject<string>();
  insurersBrokers: Contact[];

  // AdditionalInsurer
  additionalInsurersLoading: boolean = false;
  searchAdditionalInsurerSubject: Subject<string> = new Subject<string>();
  additionalInsurers: Contact[];
  selectedAdditionalInsurer: Contact;

  //temporarily storing agent if user switches between insurer and broker
  tempInsurerAgent: string;
  tempBrokerAgent: string;
  public subjectToGSTOptions = dropDowns.yesNoOnly;

  constructor(public contactQueryService: ContactQueryService,
              public childGetsFromParent: GetGlobalSaveModelService,
              public contactService: ContactService,
              public tabsService: TabsService,
              public contactCommandService: ContactCommandService,
              public dialogService: DialogService,
              public ngZone: NgZone,
              public logger: Logger,
              public emailFieldService: EmailFieldService,
              public matterParticipantService: MatterParticipantService,
              public renderer: Renderer2,
              public errorService: ErrorService,
              public tabsStateService: TabsService,
              public documentProductionService: DocumentProductionService,
              public lockScreenService: LockScreenService) {
  }

  ngOnInit() {
    this.selectedInsuranceBrokerContactTypeMapping
      = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === 'INSURANCE_BROKER');

    //Initialize DropDowns options
    this.insuranceBrokerTypes = contactDropDowns.INSURANCEBROKERTYPES;
    this.documentationTypes = contactDropDowns.documentationTypes;
    this.guaranteedReplacementCoverageS = contactDropDowns.NOYES;
    this.mortgageSelectedOptions = contactDropDowns.BLANKYESNO;
    this.mortgageeInterestOnPolicyOptions = dropDowns.MortgageeInterestNotedOnPolicyOptions;

    // AutoComplete Broker
    this.searchBrokerSubject
    .switchMap((term: string) => {
      this.brokersLoading = true;
      if (term.trim() === '') {
        // this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });

        return observable;

      } else {
        // this.autoCompleteEmptyFlag = false;
        return this.contactService
        .getContactsList(term.trim(),
          'INSURANCE_BROKER',
          false,
          'ACTIVE',
          'PRIVATE_GLOBAL', null, null, 1, 15, null, 'BROKER');
      }
    })
    .subscribe(
      (data: any) => {
        this.brokers = data;
        this.brokersLoading = false;
      },
      error => {
        this.logger.error('brokers search error:', error);
        this.brokersLoading = false;
      }
    );

    // AutoComplete Insurer
    this.searchInsurerSubject
    .switchMap((term: string) => {
      this.insurersLoading = true;
      if (term.trim() === '') {
        // this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;
      } else {
        // this.autoCompleteEmptyFlag = false;
        return this.contactService
        .getContactsList(term.trim(),
          'INSURANCE_BROKER',
          false,
          'ACTIVE',
          'PRIVATE_GLOBAL', null, null, 1, 15, null, 'INSURER');

      }
    })
    .subscribe(
      (data: any) => {
        this.insurers = data;
        this.insurersLoading = false;
      },
      error => {
        this.logger.error('insurers search error:', error);
        this.insurersLoading = false;
      }
    );

    // AutoComplete both Insurer and Broker
    // AutoComplete Insurer
    this.searchBrokerInsurerSubject
    .switchMap((term: string) => {
      this.insurersBrokersLoading = true;
      if (term.trim() === '') {
        // this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;
      } else {
        // search both
        return this.contactService
        .getContactsList(term.trim(),
          'INSURANCE_BROKER',
          false,
          'ACTIVE',
          'PRIVATE_GLOBAL', null, null, 1, 15, null);
      }
    })
    .subscribe(
      (data: any) => {
        this.insurersBrokers = data;
        this.insurersBrokersLoading = false;
      },
      error => {
        this.logger.error('insurers brokers search error:', error);
        this.insurersBrokersLoading = false;
      }
    );
    // AutoComplete Additional Insurer
    this.searchAdditionalInsurerSubject
    .switchMap((term: string) => {
      this.additionalInsurersLoading = true;
      if (term.trim() === '') {
        // this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;
      } else {
        // this.autoCompleteEmptyFlag = false;
        return this.contactService
        .getContactsList(term.trim(),
          'INSURANCE_BROKER',
          false,
          'ACTIVE',
          'PRIVATE_GLOBAL', null, null, 1, 15, null, 'INSURER');
      }
    })
    .subscribe(
      (data: any) => {
        this.additionalInsurers = data;
        this.additionalInsurersLoading = false;
      },
      error => {
        this.logger.error('additionalInsurers search error:', error);
        this.additionalInsurersLoading = false;
      }
    );

    if (this.isBroker()) {
      this.buildBrokerStructure();
    } else {
      this.buildInsurerStructure();
    }

    this.loadDefaultDropdowns();

    // This is an Observable which will notify the changes in javascript object.
    if (!this.subscription) {
      this.subscription = this.childGetsFromParent.getItem$
      .subscribe(() => {
        //Close existing shutters before switching to a new matter,
        // to release any locks when changing to a different tab or after saving a matter
        // open at the same topic
        this.unlockSourceContact(this.selectedBroker);
        this.unlockSourceContact(this.selectedInsurer);
        //currentMatter.value = matter;
        this.initiateEmailFieldService();
        this.fireInsuranceOpening();
        //DPPMP-6549 Focus/cursor displays Not from the 1st field after click save button - IE only
        this.setFocusOnInsurerBrokerType();
      });
    }

    this.setFocusOnInsurerBrokerType();

  }

  setFocusOnInsurerBrokerType(): void {
    //DPPMP-6549 Focus/cursor displays Not from the 1st field when page is open - IE only
    if (this.focusOnLoad) {
      setTimeout(() => {
        this.renderer.selectRootElement(this.focusOnLoad.nativeElement, true).focus();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.unlockSourceContact(this.selectedBroker);
    this.unlockSourceContact(this.selectedInsurer);
  }

  initiateEmailFieldService(): void {
    this.emailFieldService.matter = this.matter;
    this.emailFieldService.key = EmailKeys.fireInsurance;
  }

  unlockSourceContact(currentWrapper: MatterParticipantWrapper): void {
    if (currentWrapper) {
      this.setExpanded(currentWrapper, false);
    }
  }

  get contact(): Contact {
    return this.isBroker()
      ? this.selectedBroker && this.selectedBroker.matterParticipant && this.selectedBroker.matterParticipant.contact
      : this.selectedInsurer && this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact;
  }

  setPartialExpiryDate(date): void {
    this.logger.info('setPartialExpiryDate , date=', date);
    if (this.partialExpiryDateComponent) {
      this.partialExpiryDateComponent.setDate(date);
    }
  }

  fireInsuranceOpening() {
    this.buildInsurerStructure();
    this.buildBrokerStructure();
    this.setPartialExpiryDate(this.matter.fireInsuranceContactInfo.expiryDate);
  }

  get matter() {
    return this._matter ? this._matter : currentMatter.value;
  }

  // this method will enable save after change in any filed.
  enableSave(): void {
    this.matter.dirty = !this.tabsService.isLinkedMatterDirty();
  }

  isBroker() {
    return this.matter.isFireInsuranceContactBroker;
  }

  getUpdateButtonTitle(): string {
    let matterParticipant: MatterParticipant = this.isBroker() ? this.selectedBroker.matterParticipant : this.selectedInsurer.matterParticipant;
    if (matterParticipant && matterParticipant.contact) {
      return matterParticipant.contact.lastUpdatedOnMsg;
    }
    return '';
  }

  onInsurerOrBrokerTypeChange(): void {
    this.enableSave();
    this.populateAdditionalInsurer();
    this.handleDearAndAgent();
    this.mapSelectedInsurerOrBroker();
  }

  //prepopulate the Insurance company name for a Broker if an Insurer was previously selected
  public populateAdditionalInsurer() {

    if (this.isBroker() && this.selectedInsurer && this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact) {
      this.selectedAdditionalInsurer = new Contact(this.selectedInsurer.matterParticipant.contact);
      this.matter.fireInsuranceContactInfo.companyInsurerName = this.selectedInsurer.matterParticipant.contact.organizationName;
    }
  }

  //keeping track of dear and agent when switching between broker and insurer
  public handleDearAndAgent() {
    const fireInsuranceContactInfo: ContactInfo = this.matter.fireInsuranceContactInfo;

    if (this.isBroker()) {
      if (this.selectedInsurer && this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact) {
        this.selectedInsurer.matterParticipant.contact.dear = fireInsuranceContactInfo.dear;
        this.tempInsurerAgent = fireInsuranceContactInfo.agent;
      }
    } else {
      if (this.selectedBroker && this.selectedBroker.matterParticipant && this.selectedBroker.matterParticipant.contact) {
        this.selectedBroker.matterParticipant.contact.dear = fireInsuranceContactInfo.dear;
        this.tempBrokerAgent = fireInsuranceContactInfo.agent;
      }
    }
  }

  //map values from previously selected insurer or broker to the model when switching between the two
  public mapSelectedInsurerOrBroker(): void {
    const fireInsuranceContactInfo: ContactInfo = this.matter.fireInsuranceContactInfo;
    let insurerOrBroker: MatterParticipantWrapper = this.isBroker() ? this.selectedBroker : this.selectedInsurer;

    if (insurerOrBroker && insurerOrBroker.matterParticipant && insurerOrBroker.matterParticipant.contact) {
      const insuranceContact: Contact = insurerOrBroker.matterParticipant.contact;
      fireInsuranceContactInfo.mailingAddress = insuranceContact.mailingAddress;
      fireInsuranceContactInfo.mailingAddress.id = null;
      fireInsuranceContactInfo.workPhone = insuranceContact.workPhone;
      fireInsuranceContactInfo.faxPhone = insuranceContact.faxPhone;
      fireInsuranceContactInfo.email = insuranceContact.email;
      fireInsuranceContactInfo.dear = insuranceContact.dear;
      fireInsuranceContactInfo.agent = this.isBroker() ? this.tempBrokerAgent : this.tempInsurerAgent;

    } else {
      this.matter.resetFieldsFromInsurerOrBroker();
      fireInsuranceContactInfo.agent = '';
    }
  }

  getInsurerBrokerLabel(): string {
    return this.matter.fireInsuranceContactInfo.insuranceBrokerType === MatterParticipantRoleTypes.BROKER
      ? 'Broker' : 'Company (Insurer)';
  }

  // This method is for fill the broker tab data and create
  //  the autocomplete (contact) dynamically .
  buildBrokerStructure(): void {
    const broker: MatterParticipant = this.matter.brokerMatterParticipant;
    if (broker) {
      this.createInsurerBrokerByMatterParticipant(broker, MatterParticipantRoleTypes.BROKER);
      // this.selectedBroker.expanded = true;
    } else {
      this.initializedInsuranceBroker(MatterParticipantRoleTypes.BROKER);
    }
  }

  // This method is for fill the broker tab data and create
  //  the autocomplete (contact) dynamically .
  buildInsurerStructure(): void {
    const insurer: MatterParticipant = this.matter.insurerMatterParticipant;
    if (insurer) {
      this.createInsurerBrokerByMatterParticipant(insurer, MatterParticipantRoleTypes.INSURER);
      // this.selectedInsurer.expanded = true;
    } else {
      this.initializedInsuranceBroker(MatterParticipantRoleTypes.INSURER);
    }
  }

  public createInsurerBrokerByMatterParticipant(matterParticipant: MatterParticipant, insurerOrBroker: string): void {

    const contact: Contact = matterParticipant.contact;
    matterParticipant.contact = new Contact(contact);
    if (this.isBroker()) {
      this.selectedBroker = this.createWrapperFromMatterParticipant(matterParticipant);
    } else {
      this.selectedInsurer = this.createWrapperFromMatterParticipant(matterParticipant);
    }
    let {sourceContactId}: Contact = matterParticipant.contact;
    if (sourceContactId) {
      this.contactQueryService
      .getContactForMatter(sourceContactId)
      .subscribe((sourceContact: Contact) => {
        const {contact} = matterParticipant;
        const wrapper: MatterParticipantWrapper = this.isBroker() ? this.selectedBroker : this.selectedInsurer;
        if (wrapper.matterParticipant.sourceContactLockAcquired
          && wrapper.matterParticipant.contact.isDirty) {
          // If it is in edit mode and has already modified data, it should not be in out of sync state
          // So isStale should be false. isClearFlagWithoutUpdatingMatter should be false.
          wrapper.isStale = false;
          wrapper.isClearFlagWithoutUpdatingMatter = false;
        } else {
          wrapper.isStale = contact.isStaleFireInsurance(sourceContact);
          wrapper.isClearFlagWithoutUpdatingMatter = contact.isFireInsuranceClearFlagWithoutUpdatingMatter(sourceContact);
        }
        this.tabsService.updateIsLockedElsewhereStatus(wrapper, sourceContact);

        wrapper.updateSourceContactFlagsForSnapshot(sourceContact);

        wrapper.canBeUpdatedFromSourceContact = wrapper.isStale && !!contact.sourceContactId;
        wrapper.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;
      });
    }

  }

  public getContactAndInvokeCallback(id: number, callBack: (src: Contact) => void) {
    this.contactQueryService.getContactForMatter(id).subscribe((source: Contact) => {
      callBack(source);
    });
  }

  // This will act on Update Matter with Contact Data button click on dialog
  public updateClearSnapshot(matterParticipantWrapper: MatterParticipantWrapper, action: string): void {
    this.getContactAndInvokeCallback(matterParticipantWrapper && matterParticipantWrapper.matterParticipant.contact.sourceContactId,
      (source: Contact) => {
        if (action === SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT) {
          const isInsuranceBrokerTypeChanged: boolean = matterParticipantWrapper.matterParticipant.contact.insuranceBrokerType != source.insuranceBrokerType;
          matterParticipantWrapper.matterParticipant.contact.update(source);
          if (isInsuranceBrokerTypeChanged) {
            this.updateInsuranceBrokerTypeRelatedData(matterParticipantWrapper, source);
          }

        }

        // if(this.isBroker()) {
        //     this.buildBrokerStructure();
        // } else {
        //     this.buildInsurerStructure();
        // }
        this.matterParticipantService.updateParticipantWrapperState(matterParticipantWrapper, source);
        this.populateFieldsFromInsurerOrBroker(matterParticipantWrapper.matterParticipant.contact);
        this.enableSave();
      });
  }

  deleteBroker(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this Broker and contact information from this matter?', false, 'Delete').subscribe(res => {
      if (res) {
        this.errorService.removeDpFieldError('fireInsuranceContactInfo.address.postalCode',
          this.contact.mailingAddress.id ? this.contact.mailingAddress.id.toString() : null);
        if (this.selectedBroker && this.selectedBroker.matterParticipant && this.selectedBroker.matterParticipant.contact) {
          this.documentProductionService.tryToRevokePackage(this.matter, this.selectedBroker.matterParticipant, this.contactQueryService);
          this.tabsService.unLockSourceContact(this.selectedBroker);
          this.selectedBroker.expanded = false;
        }

        this.removeBroker();
        this.enableSave();
      }
    });
  }

  removeBroker(): void {
    if (this.selectedBroker.matterParticipant) {
      this.matter.removeMatterParticipant(this.selectedBroker.matterParticipant);
    }
    if (this.selectedBroker.createdOrSelected === 'Selected') {
      // this.resetMortgageFields();
    }
    this.selectedBroker.matterParticipant = null;
    this.selectedBroker.createdOrSelected = null;
    this.selectedBroker.dataModel = null;
    this.selectedBroker.editMode = true;
    this.selectedBroker.isStale = false;
    this.selectedBroker.isClearFlagWithoutUpdatingMatter = false;
    this.selectedBroker.isLockedElsewhere = false;

    // Only CleanUp in Broker
    this.selectedAdditionalInsurer = null;
    this.propagateRemoveBrokerChanges();
  }

  propagateRemoveBrokerChanges(): void {
    this.matter.fireInsuranceContactInfo.companyInsurerName = null;
    this.matter.resetFieldsFromInsurerOrBroker();

  }

  /**
   * This method creates matter participant wrapper from existing matter participant
   * @param matterParticipant
   * @returns {MatterParticipantWrapper}
   */
  createWrapperFromMatterParticipant(matterParticipant: MatterParticipant): MatterParticipantWrapper {
    let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    matterParticipantWrapper.matterParticipant = matterParticipant;
    // matterParticipantWrapper.dataModel = {fullName : matterParticipant.contact.genericFullName};
    matterParticipantWrapper.dataModel = matterParticipant.contact;
    matterParticipantWrapper.editMode = false;
    if (matterParticipantWrapper.dataModel.sourceContactId) {
      matterParticipantWrapper.createdOrSelected = 'Selected';
      this.isAddressDisabled = true;
    } else {
      matterParticipantWrapper.createdOrSelected = 'Created';
    }
    matterParticipantWrapper.selectedDetailsTabIndex = 0;
    return matterParticipantWrapper;
  }

  /**
   * Initialize Insurance or Broker option
   */
  initializedInsuranceBroker(isInsurer: string): void {
    let firstMatterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    firstMatterParticipantWrapper.editMode = true;
    firstMatterParticipantWrapper.selectedDetailsTabIndex = 0;
    if (isInsurer === MatterParticipantRoleTypes.BROKER) {
      this.selectedBroker = firstMatterParticipantWrapper;
    } else {
      this.selectedInsurer = firstMatterParticipantWrapper;
    }
  }

  /**
   * Set default values for dropdowns lists
   */
  loadDefaultDropdowns(): void {
    if (!this.matter.fireInsuranceContactInfo.insuranceBrokerType) {
      this.matter.fireInsuranceContactInfo.insuranceBrokerType = 'QUESTION';
    }

    if (!this.matter.fireInsuranceContactInfo.mortgageSelected) {
      this.matter.fireInsuranceContactInfo.mortgageSelected = '';
    }

    if (!this.matter.fireInsuranceContactInfo.documentationType) {
      this.matter.fireInsuranceContactInfo.documentationType = '';
    }

    if (!this.matter.fireInsuranceContactInfo.guaranteedReplacementCoverage) {
      this.matter.fireInsuranceContactInfo.guaranteedReplacementCoverage = 'N_y';
    }
  }

  /**
   *  autocomplete Broker Omnibar methods to get contacts
   * @param event
   */
  searchBroker(event): void {
    let entered: string = event.query;
    this.searchBrokerSubject.next(entered);
  }

  searchInsurerBrokers(event): void {
    let entered: string = event.query;
    this.searchBrokerInsurerSubject.next(entered);
  }

  // select the Broker form list of contacts

  selectInsurerOrBroker(): void {
    if (this.selectedInsurerBroker.insuranceBrokerType == 'BROKER') {
      this.matter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.BROKER;
    } else {
      this.matter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.INSURER;
    }

    if (this.isBroker()) {
      this.buildBrokerStructure();
      this.selectedBroker.dataModel = this.selectedInsurerBroker;
    } else {
      this.buildInsurerStructure();
      this.selectedInsurer.dataModel = this.selectedInsurerBroker;
    }

    this.selectEntity();

  }

  selectEntity(): void {
    this.enableSave();

    let currentEntity = this.isBroker() ? this.selectedBroker : this.selectedInsurer;
    let participantType: MatterParticipantRole = this.isBroker() ? MatterParticipantRoleTypes.BROKER : MatterParticipantRoleTypes.INSURER;
    let insurerOrBroker = this.isBroker() ? constValues.insurerOrBroker.BROKER : constValues.insurerOrBroker.INSURER;
    let otherMatterParticipantTypeDoesNotExist = this.isBroker() ? !this.matter.insurerMatterParticipant : !this.matter.brokerMatterParticipant;
    if (currentEntity.dataModel.id === undefined) { // Created new broker
      // Now take care for Add new client
      if (currentEntity.dataModel.displayName && currentEntity.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD) === 0) {
        this.removeCursorIE('#broker-autocomplete .ui-autocomplete-input');
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            contactType: 'INSURANCE_BROKER',
            contactName: currentEntity.dataModel.typedName,
            insurerOrBroker: null,
            keyboard: null
          },
          onFulfillment: (result) => {
            if (result) {
              if (result.action === 'save') {//if contact modal is closed after save
                if (result.contact.insuranceBrokerType == 'BROKER') {
                  this.matter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.BROKER;
                } else {
                  this.matter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.INSURER;
                }
                if (this.isBroker()) {
                  this.buildBrokerStructure();
                } else {
                  this.buildInsurerStructure();
                }

                currentEntity = this.isBroker() ? this.selectedBroker : this.selectedInsurer;
                participantType = this.isBroker() ? MatterParticipantRoleTypes.BROKER : MatterParticipantRoleTypes.INSURER;
                insurerOrBroker = this.isBroker() ? constValues.insurerOrBroker.BROKER : constValues.insurerOrBroker.INSURER;
                otherMatterParticipantTypeDoesNotExist = this.isBroker() ? !this.matter.insurerMatterParticipant : !this.matter.brokerMatterParticipant;

                this.isAddressDisabled = true;

                currentEntity.matterParticipant
                  = this.matter.addMatterParticipant(result.contact, true, result.contact.insuranceBrokerType);
                currentEntity.updateSourceContactFlagsForSnapshot(result.contact);
                currentEntity.dataModel = {organizationName: result.contact.organizationName};
                currentEntity.editMode = false;
                currentEntity.expanded = true;
                if (otherMatterParticipantTypeDoesNotExist) {
                  this.populateFieldsFromInsurerOrBroker(currentEntity.matterParticipant.contact);

                } else {
                  this.matter.fireInsuranceContactInfo.mailingAddress = currentEntity.matterParticipant.contact.mailingAddress;
                }
                currentEntity.createdOrSelected = 'Created';

              } else {
                currentEntity = null;
                this.initializedInsuranceBroker(participantType);
              }
            } else {
              currentEntity = null;
              this.initializedInsuranceBroker(participantType);
            }
          },
          onRejection: () => {
            currentEntity = null;
            this.initializedInsuranceBroker(participantType);
          },
          
        });
      } else {
        currentEntity = null;
        this.initializedInsuranceBroker(participantType);
      }

    } else {

      this.isAddressDisabled = true;
      currentEntity.editMode = false;
      this.contactQueryService.getContactForMatter(currentEntity.dataModel.id)
      .subscribe((contact: Contact) => {
        // activeBroker.dataModel.fullName = contact.organizationName;
        if (this.isBroker()) {
          currentEntity.matterParticipant = this.createNewMatterBrokerContact(contact);
        } else {
          currentEntity.matterParticipant = this.createNewMatterInsurerContact(contact);
        }

        currentEntity.updateSourceContactFlagsForSnapshot(contact);

        this.populateFieldsFromInsurerOrBroker(contact);
        currentEntity.selectedDetailsTabIndex = 0;
        currentEntity.createdOrSelected = 'Selected';
        this.setExpanded(currentEntity, true);
      });
    }
    setTimeout(() => {
      jQuery('#insurerBroker_container .readonly-input-field').focus();
    }, 0);

    /// clear autocomplete field
    this.selectedInsurerBroker = null;
  }

  /**
   *
   * @param contact
   * @returns {MatterParticipant}
   */
  createNewMatterBrokerContact(contact: Contact): MatterParticipant {
    let participant: MatterParticipant
      = this.matter.addMatterParticipant(contact, true, MatterParticipantRoleTypes.BROKER);

    return participant;
  }

  removeCursorIE(id): void {
    /// need to blur auto-complete field as this is causing cursor to blink in weird issue on IE.
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        jQuery(id).blur();
      });

    });
  }

  /**
   *
   * @param contact
   * @returns {MatterParticipant}
   */
  createNewMatterInsurerContact(contact: Contact): MatterParticipant {
    let participant: MatterParticipant
      = this.matter.addMatterParticipant(contact, true, MatterParticipantRoleTypes.INSURER);

    return participant;
  }

  /**
   *  autocomplete Insurer Omnibar methods to get contacts
   * @param event
   */
  searchInsurer(event): void {
    let entered: string = event.query;
    this.searchInsurerSubject.next(entered);
  }

  deleteInsurer(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this Insurer from this matter?', false, 'Delete').subscribe(res => {
      if (res) {
        this.errorService.removeDpFieldError('fireInsuranceContactInfo.address.postalCode',
          this.contact.mailingAddress.id ? this.contact.mailingAddress.id.toString() : null);
        if (this.selectedInsurer && this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact) {
          this.documentProductionService.tryToRevokePackage(this.matter, this.selectedInsurer.matterParticipant, this.contactQueryService);
          this.tabsService.unLockSourceContact(this.selectedInsurer);
          this.selectedInsurer.expanded = false;
        }
        this.removeInsurer();
        this.enableSave();
      }
    });
  }

  removeInsurer(): void {
    if (this.selectedInsurer.matterParticipant) {
      this.matter.removeMatterParticipant(this.selectedInsurer.matterParticipant);
    }
    if (this.selectedInsurer.createdOrSelected === 'Selected') {
      // this.resetMortgageFields();
    }
    this.selectedInsurer.matterParticipant = null;
    this.selectedInsurer.createdOrSelected = null;
    this.selectedInsurer.dataModel = null;
    this.selectedInsurer.editMode = true;
    this.selectedInsurer.isStale = false;
    this.selectedInsurer.isClearFlagWithoutUpdatingMatter = false;
    this.selectedAdditionalInsurer = null;
    this.matter.fireInsuranceContactInfo.companyInsurerName = null;
    this.matter.resetFieldsFromInsurerOrBroker();
  }

  deleteAdditionalInsurer(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this Insurer from this matter?', false, 'Delete').subscribe(res => {
      if (res) {
        this.matter.fireInsuranceContactInfo.companyInsurerName = null;
        this.selectedAdditionalInsurer = null;
        this.enableSave();
      }
    });
  }

  /**
   * It passes insurer Or broker contact to populate contact info Fields
   * @param insurerOrBroker
   */
  populateFieldsFromInsurerOrBroker(insurerOrBroker: Contact): void {
    this.matter.populateFieldsFromInsurerOrBroker(insurerOrBroker);
  }

  /**
   *  autocomplete Insurer Omnibar methods to get contacts
   * @param event
   */
  searchAdditionalInsurer(event): void {
    let entered: string = event.query;
    this.searchAdditionalInsurerSubject.next(entered);
  }

  selectAdditionalInsurer(): void {
    this.enableSave();
    if (this.selectedAdditionalInsurer.id === undefined) { // Created new broker
      // Now take care for Add new client
      if (this.selectedAdditionalInsurer.displayName && this.selectedAdditionalInsurer.displayName.indexOf(Constants.ADD_NEW_RECORD) === 0) {
        this.removeCursorIE('#insurer-autocomplete .ui-autocomplete-input');
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            contactType: 'INSURANCE_BROKER',
            contactName: this.selectedAdditionalInsurer[ 'typedName' ],
            insurerOrBroker: constValues.insurerOrBroker.INSURER,
            keyboard: null
          },
          onFulfillment: (result) => {
            if (result) {
              if (result.action === 'save') {//if contact modal is closed after save
                this.selectedAdditionalInsurer = result.contact;

                if (this.selectedAdditionalInsurer.organizationName) {
                  this.matter.fireInsuranceContactInfo.companyInsurerName = this.selectedAdditionalInsurer.organizationName;
                }
              } else {
                this.selectedAdditionalInsurer = null;
              }
            } else {
              this.selectedAdditionalInsurer = null;
            }
          },
          onRejection: () => {
            this.selectedAdditionalInsurer = null;
          },
          
        });
      } else {
        this.selectedAdditionalInsurer = null;
      }

    } else {
      if (this.selectedAdditionalInsurer.organizationName) {
        this.matter.fireInsuranceContactInfo.companyInsurerName = this.selectedAdditionalInsurer.organizationName;
      }
    }

  }

  updateAddress(event) {
    if (event && event.value && event.value.isAddressValid) {
      this.errorService.removeDpFieldError('fireInsuranceContactInfo.address.postalCode',
        this.contact.mailingAddress.id ? this.contact.mailingAddress.id.toString() : null);
    }
  }

  addressEmitChange() {
    this.dataChange();

  }

  onDateChangeExiryDate(event): void {
    this.enableSave();
    this.matter.fireInsuranceContactInfo.expiryDate = event.rawDate;
  }

  // F9 Capture on various <select> elements of the page
  handleF9OnDear(event) {
    this.matter.fireInsuranceContactInfo.dear = 'Sir or Madam';
  }

  getMortgageOrder(n: number): string {
    if (this.matter.isPurchase) {
      return this.getOrdinal(n);
    } else {
      let priority = this.matter.mortgages[ n - 1 ].mortgagePriority;
      return this.getOrdinal(priority);
    }
  }

  // ToDo use Utils.getOrdinal
  getOrdinal(n: number): string {
    if (isNaN(n)) {
      return '';
    }
    var s = [ 'th', 'st', 'nd', 'rd' ],
      v = n % 100;
    return n + (s[ (v - 20) % 10 ] || s[ v ] || s[ 0 ]);
  }

  isInterestOfMortgageesNotedOnPolicyVisible(): boolean {
    if (!this.matter.isSale) {
      return (Array.isArray(this.matter.mortgagees) && this.matter.mortgagees.length > 0) || (Array.isArray(this.matter.privateLenders) && this.matter.privateLenders.length > 0);
    } else {
      //return this.matter.isProjectSale ? false : this.matter.mortgages.length > 0;
      return this.matter.mortgages && this.matter.mortgages.length > 0;
    }

  }

  // Open or Close shutter
  toggleInterestOfMortgageesNotedOnPolicy(): void {
    this.expanded = !this.expanded;
  }

  showEditUpdateButton() {
    let ret: boolean = false;
    if (this.matter.fireInsuranceContactInfo.insuranceBrokerType === 'BROKER') {
      ret = !this.selectedBroker.editMode;
    } else {
      ret = !this.selectedInsurer.editMode;
    }

    return ret;
  }

  // onMortgageSelectedChange() {
  //     this.enableSave();
  //     this.matter.fireInsuranceContactInfo.interestOfMortgageesNotedOnPolicy
  //         = this.matter.fireInsuranceContactInfo.mortgageSelected;
  // }

  showInputField() {
    return (this.matter.fireInsuranceContactInfo.documentationType !== null
      && this.matter.fireInsuranceContactInfo.documentationType !== ''
      && this.matter.fireInsuranceContactInfo.documentationType !== 'QUESTION');
  }

  get documentationTypeLabel(): string {

    //Get right label value to be displayed for selected documentation type.
    let selectedItem: SelectItem = contactDropDowns.documentationTypes
    .find(documentationType => documentationType.value === this.matter.fireInsuranceContactInfo.documentationType);

    if (selectedItem) {
      return selectedItem.label;
    } else {
      return '';
    }

  }

  interestOnMortgageesChanged(): void {
    this.enableSave();
  }

  massUpdateInterestOnMortgageesChanged(value, index: number): void {
    this.matter.massUpdateMortgageeInterestNotedOnPolicies[ index ] = value;
  }

  isInsuranceAvailable(mortgage: Mortgage): boolean {
    if (!this.matter.isSale) {
      return this.matter.getMatterParticipantsByMortgage(mortgage).length > 0;
    } else {
      return true;
    }
  }

  //ToDo (matterparticipant): Instead of doing this in each component, added a common method in wrapper to return burger menu options.
  getBrokerOrInsurerBurgerMenuItems(selectedMatterParticipantWrapper: MatterParticipantWrapper) {
    let menuItems = [];
    menuItems.push(SnapshotBurgerMenuActions.DELETE);
    if (selectedMatterParticipantWrapper
      && selectedMatterParticipantWrapper.matterParticipant
      && selectedMatterParticipantWrapper.matterParticipant.contact) {
      selectedMatterParticipantWrapper.menuItemsForRevert(menuItems);
    }

    if (selectedMatterParticipantWrapper.isStale) {
      menuItems.push(SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
    }
    return menuItems;
  }

  clickBrokerOrInsurerBurgerMenu(event, selectedMatterParticipantWrapper: MatterParticipantWrapper) {
    this.logger.info('clickBurgerMenu | event:', event);
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE: {
        if (this.isBroker()) {
          this.deleteBroker();
        } else {
          this.deleteInsurer();
        }
        break;
      }
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL: {
        this.revertClearUpdateAction(selectedMatterParticipantWrapper, SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
        break;
      }
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT : {
        this.revertClearUpdateAction(selectedMatterParticipantWrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
        break;
      }
      default: {
        break;
      }
    }
  }

  revertClearUpdateAction(selectedMatterParticipantWrapper: MatterParticipantWrapper, action: string) {
    this.contactQueryService.getContactForMatter(selectedMatterParticipantWrapper.matterParticipant
      && selectedMatterParticipantWrapper.matterParticipant.contact
      && selectedMatterParticipantWrapper.matterParticipant.contact.sourceContactId)
    .subscribe((sourceContact: Contact) => {
      this.errorService.removeDpFieldError('fireInsuranceContactInfo.address.postalCode',
        this.contact.mailingAddress.id ? this.contact.mailingAddress.id.toString() : null);
      this.tabsService.updateIsLockedElsewhereStatus(selectedMatterParticipantWrapper, sourceContact);

      if (!selectedMatterParticipantWrapper.isLockedElsewhere) {
        switch (action) {
          case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
            this.revertToGlobalAction(selectedMatterParticipantWrapper);
            break;
          case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
            this.updateClearSnapshot(selectedMatterParticipantWrapper,
              SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
            break;
          default:
            break;
        }
      } else {
        this.setExpanded(selectedMatterParticipantWrapper, true);
      }
    });

  }

  // If it is Out of Sync, it needs update first
  editAsPrivateCopyAction(selectedMatterParticipantWrapper: MatterParticipantWrapper) {

    // Locked this contact and set this contact is dirty
    if (selectedMatterParticipantWrapper
      && selectedMatterParticipantWrapper.matterParticipant
      && selectedMatterParticipantWrapper.matterParticipant.contact) {

      this.contactQueryService.getContact(selectedMatterParticipantWrapper.matterParticipant.contact.sourceContactId).subscribe(
        (res: Contact) => {
          selectedMatterParticipantWrapper.matterParticipant.sourceContact = res;
          if (!res.locked && !this.tabsService.isContactAlreadyLocked(selectedMatterParticipantWrapper.matterParticipant.contact)) {
            selectedMatterParticipantWrapper.lockedSourceContact = null;
            selectedMatterParticipantWrapper.matterParticipant.sourceContactLockAcquired = true;
            selectedMatterParticipantWrapper.isStale
              = selectedMatterParticipantWrapper.matterParticipant.contact.isStaleFireInsurance(res);
            selectedMatterParticipantWrapper.isClearFlagWithoutUpdatingMatter
              = selectedMatterParticipantWrapper.matterParticipant.contact.isFireInsuranceClearFlagWithoutUpdatingMatter(res);
            selectedMatterParticipantWrapper.matterParticipant.contact.isDirty = true;
            selectedMatterParticipantWrapper.isLockedElsewhere = false;

            selectedMatterParticipantWrapper.sourceProxyEdited = true;
            this.enableSave();
          } else {
            selectedMatterParticipantWrapper.isLockedElsewhere = true;
            selectedMatterParticipantWrapper.sourceProxyEdited = res.proxyEdited;
            selectedMatterParticipantWrapper.sourcePrivateFlag = res.privateFlag;
            selectedMatterParticipantWrapper.sourceProxyForGlobal = res.proxyForGlobal;
            selectedMatterParticipantWrapper.lockedSourceContact = res;
            selectedMatterParticipantWrapper.matterParticipant.sourceContactLockAcquired = false;
          }
        });
    }
  }

  updateInsuranceBrokerTypeRelatedData(currentEntity: MatterParticipantWrapper, sourceContact: Contact): void {
    // let newCurrentEntity : MatterParticipantWrapper;
    if (sourceContact.insuranceBrokerType === 'BROKER') {
      this.selectedBroker = currentEntity;
      // this.initializedInsuranceBroker(MatterParticipantRoleTypes.BROKER, currentEntity);
      this.selectedBroker.matterParticipant.matterParticipantRole = MatterParticipantRoleTypes.BROKER;
      this.initializedInsuranceBroker(MatterParticipantRoleTypes.INSURER);
      // newCurrentEntity = this.selectedBroker;
    } else {
      // this.initializedInsuranceBroker(MatterParticipantRoleTypes.INSURER, currentEntity);
      this.selectedInsurer = currentEntity;
      this.selectedInsurer.matterParticipant.matterParticipantRole = MatterParticipantRoleTypes.INSURER;
      this.initializedInsuranceBroker(MatterParticipantRoleTypes.BROKER);
      // newCurrentEntity = this.selectedInsurer;

    }
    this.matter.fireInsuranceContactInfo.insuranceBrokerType = sourceContact.insuranceBrokerType;
    // return newCurrentEntity;
  }

  revertToGlobalAction(selectedMatterParticipantWrapper: MatterParticipantWrapper) {
    this.enableSave();
    if (selectedMatterParticipantWrapper
      && selectedMatterParticipantWrapper.matterParticipant
      && selectedMatterParticipantWrapper.matterParticipant.contact) {
      // let currentEntity = this.isBroker() ? this.selectedBroker : this.selectedInsurer;
      let currentInsuranceBrokerType: string = selectedMatterParticipantWrapper.matterParticipant.contact.insuranceBrokerType;
      this.dialogService.confirm('Confirmation', RevertToGlobalMessage, false).subscribe((response: any) => {
        if (response) {
          //After finishing the reverting global action, it will unlock this contact
          this.tabsService.lockSourceContactForShutter(selectedMatterParticipantWrapper, true).subscribe((sourceContact: Contact) => {
            if (sourceContact) {
              let proxyContact: Contact;
              //Reloading the actual global contact from server as proxy might have already changed
              this.contactQueryService.getGlobalContact(sourceContact.sourceContactId)
              .subscribe((globalContact: Contact) => {
                //create proxy of actual global contact
                proxyContact = Contact.createNewProxy(globalContact, false);
                //As proxy is created from global contact that doesn't have attentions associated with this customer account,
                //therefore copying over the attentions to proxy, as that should not be reverted only global info is reverted.
                if (sourceContact.privateContacts.persons) {
                  sourceContact.privateContacts.persons.forEach(c => {
                    proxyContact.privateContacts.addOrUpdatePrivateContact(c);
                  });
                }

                //If proxy was already persisted then assign it's same id as proxy so API updates it instead of creating a new one.
                if (sourceContact.isProxyPersisted) {
                  proxyContact.id = sourceContact.id;
                }
                //If User confirms to revert then it should automatically save without user requiring
                // to click global save button in contact tab
                if (proxyContact.id) {
                  this.contactCommandService.updateContact(proxyContact).subscribe((source: Contact) => {
                    const isInsuranceBrokerTypeChanged: boolean = currentInsuranceBrokerType != source.insuranceBrokerType;
                    this.setSnapshotDataWithProxyContact(selectedMatterParticipantWrapper, source);
                    if (isInsuranceBrokerTypeChanged) {
                      this.updateInsuranceBrokerTypeRelatedData(selectedMatterParticipantWrapper, source);
                    }
                    this.tabsService.unLockSourceContact(this.isBroker() ? this.selectedBroker : this.selectedInsurer);
                  });
                } else {
                  this.contactQueryService.createContact(proxyContact).subscribe((source: Contact) => {
                    const isInsuranceBrokerTypeChanged: boolean = currentInsuranceBrokerType != source.insuranceBrokerType;
                    this.setSnapshotDataWithProxyContact(selectedMatterParticipantWrapper, source);
                    if (isInsuranceBrokerTypeChanged) {
                      this.updateInsuranceBrokerTypeRelatedData(selectedMatterParticipantWrapper, source);
                    }
                    this.tabsService.unLockSourceContact(this.isBroker() ? this.selectedBroker : this.selectedInsurer);
                  });
                }

              });
            }
          });

        }
      });
    }
  }

  setSnapshotDataWithProxyContact(currentEntity: MatterParticipantWrapper, source: Contact): void {
    if (this.isBroker()) {
      this.matter.removeMatterParticipant(currentEntity.matterParticipant);

      currentEntity.matterParticipant = this.createNewMatterBrokerContact(source);
    } else {
      this.matter.removeMatterParticipant(currentEntity.matterParticipant);

      currentEntity.matterParticipant = this.createNewMatterInsurerContact(source);
    }
    currentEntity.isStale = currentEntity.matterParticipant.contact.isStaleFireInsurance(source);
    currentEntity.isClearFlagWithoutUpdatingMatter = currentEntity.matterParticipant.contact.isFireInsuranceClearFlagWithoutUpdatingMatter(source);
    currentEntity.canBeUpdatedFromSourceContact = currentEntity.isStale && !!currentEntity.matterParticipant.contact.sourceContactId;
    currentEntity.lastUpdatedOn = source.lastUpdatedOnMsg;
    currentEntity.dataModel.organizationName = source.organizationName;
    this.populateFieldsFromInsurerOrBroker(currentEntity.matterParticipant.contact);
    currentEntity.selectedDetailsTabIndex = 0;
    currentEntity.createdOrSelected = 'Selected';
    currentEntity.updateSourceContactFlagsForSnapshot(source);
  }

  isExpanded(selectedMatterParticipantWrapper: MatterParticipantWrapper) {
    return selectedMatterParticipantWrapper.expanded;
  }

  get shutterBackground() {
    if (this.isBroker()) {
      return this.isExpanded(this.selectedBroker);
    } else {
      return this.isExpanded(this.selectedInsurer);
    }
  }

  get shutterBackgroundClosed() {
    if (this.isBroker()) {
      return !this.selectedBroker.editMode && !this.isExpanded(this.selectedBroker);
    } else {
      return !this.selectedInsurer.editMode && !this.isExpanded(this.selectedInsurer);
    }
  }

  toggleContactSnapshot(participantWrapper: MatterParticipantWrapper): void {
    if (participantWrapper && participantWrapper.matterParticipant) {
      this.setExpanded(participantWrapper, !participantWrapper.expanded);
    }
  }

  setExpanded(matterParticipantWrapper: MatterParticipantWrapper, expandedTarget: boolean): void {
    if (matterParticipantWrapper.expanded === expandedTarget) {
      return;
    }

    if (this.matter.locked) {
      //If matter locked, just toggle
      matterParticipantWrapper.expanded = expandedTarget;
      return;
    }

    if (!matterParticipantWrapper.isOwnedBySystemAccount) {
      // "expandedTarget === true" means it will open shutter and lock this contact
      // "expandedTarget === false" means it will close shutter and unlock this contact
      if (expandedTarget === true
        && matterParticipantWrapper.matterParticipant
        && matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) { //It is locked by itself
        matterParticipantWrapper.isLockedElsewhere = false;
        matterParticipantWrapper.lockedSourceContact = null;
        matterParticipantWrapper.expanded = expandedTarget;
      } else {// If this contact is locked by other user, it will return the locking user information
        this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, expandedTarget).subscribe((result: Contact) => {
          if (result) {
            if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired
              && matterParticipantWrapper.matterParticipant.contact.isDirty) {
              // If it is in edit mode and has already modified data, it should not be in out of sync state.
              // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic
              matterParticipantWrapper.isStale = false;
              matterParticipantWrapper.isClearFlagWithoutUpdatingMatter = false;
            } else {
              matterParticipantWrapper.isStale = matterParticipantWrapper.matterParticipant.contact.isStaleFireInsurance(result);
              matterParticipantWrapper.isClearFlagWithoutUpdatingMatter
                = matterParticipantWrapper.matterParticipant.contact.isFireInsuranceClearFlagWithoutUpdatingMatter(result);
            }
            matterParticipantWrapper.updateSourceContactFlagsForSnapshot(result);
            matterParticipantWrapper.expanded = expandedTarget;
          }
        });
      }
    } else {
      matterParticipantWrapper.expanded = expandedTarget;
    }

  }

  get isBrokerInsurerGlobal(): boolean {
    if (this.isBroker()) {
      if ((this.selectedBroker && this.selectedBroker.sourceContactIsLocked)
        || (this.selectedBroker.matterParticipant
          && this.selectedBroker.matterParticipant.contact
          && this.selectedBroker.isOwnedBySystemAccount)) {
        return true;
      }
    } else {
      if ((this.selectedInsurer && this.selectedInsurer.sourceContactIsLocked)
        || (this.selectedInsurer.matterParticipant
          && this.selectedInsurer.matterParticipant.contact
          && this.selectedInsurer.isOwnedBySystemAccount)) {
        return true;
      }
    }
    return false;
  }

  get isLocked(): boolean {
    if (this.isBroker()) {
      if (this.selectedBroker && this.selectedBroker.sourceContactIsLocked) {
        return true;
      }
    } else {
      if (this.selectedInsurer && this.selectedInsurer.sourceContactIsLocked) {
        return true;
      }
    }
    return false;
  }

  get isOutOfSync(): boolean {
    return this.isBroker()
      ? !this.selectedBroker.sourceContactIsLocked && (this.selectedBroker.isStale || this.selectedBroker.isClearFlagWithoutUpdatingMatter)
      : !this.selectedInsurer.sourceContactIsLocked && (this.selectedInsurer.isStale || this.selectedInsurer.isClearFlagWithoutUpdatingMatter);

  }

  openSourceContactTabWrapper(): void {
    if (this.isBroker()) {
      if (this.selectedBroker.matterParticipant) {
        this.openSourceContactTab(this.selectedBroker.matterParticipant);
      }
    } else {
      if (this.selectedInsurer.matterParticipant) {
        this.openSourceContactTab(this.selectedInsurer.matterParticipant);
      }
    }
  }

  openSourceContactTab(matterParticipant: MatterParticipant): void {
    this.contactQueryService.getContactForOpeningTab(matterParticipant.contact.sourceContactId).subscribe((res: any) => {
      let source: Contact = res.contact;
      const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/' + source.contactType, source.contactType);
      this.tabsService.openTab(contactTab);
    });
  }

  onBlurBrokerInsurer(event, participantWrapper: any) {
    setTimeout(() => {
      if (participantWrapper && participantWrapper.dataModel && typeof participantWrapper.dataModel === 'string') {
        participantWrapper.dataModel = null;
      }
    }, 200);
  }

  cleanupAdditionalInsurerField(participant: any) {
    setTimeout(() => {
      if (typeof participant === 'string') {
        this.additionalInsurersLoading = false;
        this.selectedAdditionalInsurer = null;
      }
    }, 200);
  }

  get snapshotReadOnly(): boolean {

    return this.isLocked || this.isOutOfSync || this.isBrokerInsurerGlobal;
  }

  lockedByUserFullName(): string {
    if (this.isBroker()) {
      return this.selectedBroker && this.selectedBroker.lockedSourceContact &&
        this.selectedBroker.lockedSourceContact.lockedByUser.fullName;
    } else {
      return this.selectedInsurer && this.selectedInsurer.lockedSourceContact &&
        this.selectedInsurer.lockedSourceContact.lockedByUser.fullName;
    }

  }

  showGlobalWindow(): boolean {
    if (this.isBroker()) {
      if (this.selectedBroker.matterParticipant && this.selectedBroker.matterParticipant.contact) {
        return this.selectedBroker.isOwnedBySystemAccount;
      }
    } else {
      if (this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact) {
        return this.selectedInsurer.isOwnedBySystemAccount;
      }
    }
    return false;
  }

  showAddressBookWindow(): boolean {
    if (this.isBroker()) {
      if (this.selectedBroker.matterParticipant && this.selectedBroker.matterParticipant.contact) {
        return this.selectedBroker.isProxyCopyOfGlobal;
      }
    } else {
      if (this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact) {
        return this.selectedInsurer.isProxyCopyOfGlobal;
      }
    }
    return false;
  }

  dataChange() {
    this.enableSave();
    if (this.isBroker()) {
      if (this.selectedBroker && this.selectedBroker.matterParticipant && this.selectedBroker.matterParticipant.contact) {
        this.selectedBroker.matterParticipant.contact.isDirty = true;
      }
    } else {
      if (this.selectedInsurer && this.selectedInsurer.matterParticipant && this.selectedInsurer.matterParticipant.contact) {
        this.selectedInsurer.matterParticipant.contact.isDirty = true;
      }
    }
    this.mapSelectedInsurerOrBroker();
  }

  //ToDo (matterparticipant): why do we need this method in component? what's different b/w if-else conditions
  get isLockedElsewhere(): boolean {
    if (this.isBroker()) {
      return this.selectedBroker.isLockedElsewhere;
    } else {
      return this.selectedInsurer.isLockedElsewhere;
    }
  }

  //ToDo (matterparticipant): this should be also in wrapper or in the common component for participant
  getDisabledItems(selectedMatterParticipantWrapper: MatterParticipantWrapper) {
    let disabledMenuItems = [];
    if (selectedMatterParticipantWrapper
      && selectedMatterParticipantWrapper.matterParticipant
      && selectedMatterParticipantWrapper.matterParticipant.contact
      && this.isLockedElsewhere) {
      selectedMatterParticipantWrapper.menuItemsForRevert(disabledMenuItems);
    }

    if (selectedMatterParticipantWrapper.isStale && this.isLockedElsewhere) {
      disabledMenuItems.push('Clear Flag Without Updating Matter');
      disabledMenuItems.push('Replace Matter With Source Contact');
    }
    return disabledMenuItems;
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD_MORTGAGEE) != -1;
  }

  cleanupFireInsuranceField(): void {
    if (this.selectedInsurerBroker && typeof this.selectedInsurerBroker === 'string') {
      this.selectedInsurerBroker = undefined;
      this.insurersBrokersLoading = false;
    }
  }

  getCustomExpiryDate(): any {
    let partialExpiryDate: any = {dd: '', mm: '', yy: '', type: 'partialExpiryDate'};
    if (this.matter.matterCloseDate && Utils.isValidDate(this.matter.matterCloseDate)) {
      let expiryDateForF9: string[] = moment(this.matter.matterCloseDate, 'YYYY/MM/DD').add('years', 1).format('YYYY/MM/DD').split('/');
      partialExpiryDate.mm = expiryDateForF9[ 1 ];
      partialExpiryDate.yy = expiryDateForF9[ 0 ];
      ;
      partialExpiryDate.dd = expiryDateForF9[ 2 ];
      ;
    }
    return partialExpiryDate;
  }

  ngAfterViewInit() {
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  isMassUpdateTab(): boolean {
    return this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMassUpdateSubType();
  }

  getMassUpdateData(): MassUpdateData {
    //return  this.mortgageeInterestNotedOnPolicies;
    return this.tabsStateService && this.tabsStateService.activeTab && (this.tabsStateService.activeTab as MassUpdateTab).massUpdateData;
  }

  isSaving(): boolean {
    return this.lockScreenService && this.lockScreenService.lockForUpdate;
  }

  get isSubjectToGst(): boolean {
    return this.matter?.fireInsuranceContactInfo?.subjectToGst == DpBooleanValueTypes.YES;
  }

  get costToObtainBinderCostTaxRate(): number {
    return this.matter?.fireInsuranceContactInfo?.costToObtainBinder ?
      this.matter.fireInsuranceContactInfo.costToObtainBinder * this.matter.fireInsuranceContactInfo.taxRate / 100 : 0;
  }

  get costToObtainBinderTotalCost(): number {
    return this.matter?.fireInsuranceContactInfo?.costToObtainBinder ?
      this.matter.fireInsuranceContactInfo.costToObtainBinder * (1 + (this.matter.fireInsuranceContactInfo.taxRate / 100)) : 0;
  }

  enableDisableGSTPercentage() {
    if (this.matter.isMatterProvinceBC) {
      this.matter.fireInsuranceContactInfo.taxRate = MORTGAGE_BC_HOME_INSURANCE_GST_PERCENTAGE;
    }
    this.enableSave();
  }
}
