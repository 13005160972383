import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {RemoteSigningConfiguration} from '../remote-signing-configuration/remote-signing-configuration';
import {accountApi} from '../accounts/shared/account-api';
import {Observable} from 'rxjs';
import {JurisdictionDepartmentsService} from '../jurisdiction-departments';
import {TaxRateService} from '../../matters/consideration-ltt/tax-rate.service';
import moment from 'moment';

@Injectable()
export class ApicFeeService {

  readonly APIC_FEE_KEY: string = 'APIC_FEE';
  readonly BC_PROVINCE: string = 'BC';

  constructor(private http: HttpClient,
              private taxRateService: TaxRateService) {
  }

  getApicFee() {
    return this.taxRateService.getSoaFeesRate(moment().format('YYYY-MM-DD'), this.BC_PROVINCE, this.APIC_FEE_KEY);
  }

  updateApicFee(newFee: number) {
    return this.taxRateService.updateSoaFeeRate(this.APIC_FEE_KEY, this.BC_PROVINCE, newFee);
  }
}
