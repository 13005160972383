import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DpBooleanValue} from './dp-boolean';

export class PropertyStrataMaintenanceFee extends BaseEntity {
  id: number;

  strataMaintenanceAmount: number = 0.00;

  maintenanceStartDate: string;

  maintenanceEndDate: string;

  isMaintenanceFeePaid: DpBooleanValue = 'YES';

  //Use this information to decide whether to calculate statement of adjustments or not
  infoOnly: boolean;

  outOfRange: boolean;

  public static createDefaultPropertyStrataMaintenanceFee(): PropertyStrataMaintenanceFee {
    let propertyStrataFee = new PropertyStrataMaintenanceFee();
    return propertyStrataFee;
  }
}
