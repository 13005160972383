<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="soaCommonExpense">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : {{getExpenseTitle()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12" *ngIf="isUsePotlCommonExpenseAmountVisible">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Use POTL Common Expenses amount from the Property Tab?</label>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="usePotlCommonExpenseAmount"
                            name="usePotlCommonExpenseAmount"
                            [(ngModel)]="soaCommonExpense.usePotlCommonExpenseAmount"
                            (ngModelChange)="onUsePotlCommonExpenseAmountChange($event)"
                            class="form-control"
                    >
                        <option *ngFor="let noYesOption of noYesOptions" [value]="noYesOption.value">
                            {{noYesOption.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="context.isONProject && !isPOTLCommonExpense()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Format of Adjustment</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="formatOfAdjustment"
                            name="formatOfAdjustment"
                            class="form-control"
                            [(ngModel)]="soaCommonExpense.adjustmentFormat"
                    >
                        <option *ngFor="let commonExpenseFormat of commonExpenseFormatOfAdjustment" [value]="commonExpenseFormat.value">
                            {{commonExpenseFormat.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Amount of {{getAmountLabel()}}</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="commonExpenseAmount"
                           name="commonExpenseAmount"
                           dp-default-currency
                           dp-currency
                           class="form-control text-right padding-right-30"
                           [fieldKey]="soaCommonExpense.isOccupancyCommonExpense() ? 'soa.commonExpenseAmount.occupancy' : 'soa.commonExpenseAmount'"
                           dp-error-validator
                           [(ngModel)]="soaCommonExpense.commonExpenseAmount"
                           [readonly]="((!addedFlag || context.isONProject) && (!isPOTLCommonExpense())) || (isPOTLCommonExpense() && soaCommonExpense.usePotlCommonExpenseAmount === 'YES') || isProjectSaleStandardAdjustmentForAB()"
                    />
                    <ng-container *dpShowByProvince="'soa.commonExpenseAmount.editAmount'">
                        <button type="button"  *ngIf="((!addedFlag && !inSecondModal) || context.isONProject) && (!isPOTLCommonExpense()) || isProjectSaleStandardAdjustmentForAB()"
                                id="unitLevelPlanBtn"  [disabled]="isStandardCommonExpense && !isProjectTemplate() && soaCommonExpense.commonExpenseAmount == 0"
                                tabIndex="-1"
                                (click)="selectUnitLevelPlan()" class="button-glyph">
                            <span class="glyphicon glyphicon-edit"></span>
                        </button>
                    </ng-container>
                    <ng-container *dpShowByProvince="'soa.commonExpenseAmount.editAmountMBSK'">
                        <button type="button"  *ngIf="isMBSKEditBtnVisible()"
                                id="parcelLegalDescBtn"
                                tabIndex="-1"
                                (click)="clickMBEditBtn()" class="button-glyph">
                            <span class="glyphicon glyphicon-edit"></span>
                        </button>
                    </ng-container>

                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="soaCommonExpense.usePotlCommonExpenseAmount == 'YES'">
            <div class="col-lg-12">
                <div class="form-group-warning-message margin-bottom-0 offset-lg-3">
                    <div class="col-lg-1">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </div>
                    <div class="col-lg-11">
                        <p>
                            This is a linked adjustment.  Edit the common expenses amount from the Property Tab.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{soaCommonExpense.isOccupancyCommonExpense() ? 'Has '+purchaserLabel+' paid occupancy fees for month of ' :  'Are ' +commonExpensesLabel+' paid for month of' }}
                        {{commonExpensePaidDateLabel()}}</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="expensePaid"
                            name="expensePaid"
                            class="form-control"
                            [(ngModel)]="soaCommonExpense.isCommonExpensePaid"
                    >
                        <option *ngFor="let commonExpensePaid of commonExpensePaidDropDown" [value]="commonExpensePaid.value">
                            {{commonExpensePaid.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'matter.soa.tenancy-current.modal.adjustFor'">Adjust for</label>
                </div>
                <div class="col-lg-2" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'">
                    <select type="text"
                            id="format"
                            name="format"
                            class="form-control"
                            [(ngModel)]="soaCommonExpense.commonExpenseAdjustedFor"
                            (ngModelChange)="updateTaxRate(soaCommonExpense.commonExpenseAdjustedFor)"
                    >
                        <option *ngFor="let commonExpenseAdjustedFor of commonExpenseAdjustedForDropDown" [value]="commonExpenseAdjustedFor.value">
                            {{commonExpenseAdjustedFor.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-2 text-left">
                    <span class="inline-block width-3percent;" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'">?</span>
                    <select type="text"
                    id="format1"
                    name="format1"
                    class="form-control inline-block margin-left-5 width-91percent"
                    [(ngModel)]="soaCommonExpense.isCommonExpenseAdjusted"
                    >
                    <option *ngFor="let commonExpenseAdjusted of commonExpenseAdjustedDropDown"
                            [value]="commonExpenseAdjusted.value">
                        {{commonExpenseAdjusted.label}}
                    </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="soaCommonExpense.isExpenseAdjusted">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{soaCommonExpense.commonExpenseAdjustedFor}} percentage is</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="adjustPercentage"
                           name="adjustPercentage"
                           class="form-control"
                           [(ngModel)]="soaCommonExpense.adjustPercentage"
                           dp-percentage
                    />
                </div>
                <label class="control-label col-lg-1 text-left">%</label>
            </div>
        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <label class="control-label">
                        {{getExpenseHeader()}}
                    </label>
                </div>
            </div>
            <ng-container *ngIf="isFormatOfAdjustmentItemize && context.isONProject">
                <ng-container *ngFor="let condominiumExpense of unitLevelExpenses; let j=index">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">
                        Unit {{condominiumExpense.unitNumber}}, Level {{condominiumExpense.levelNumber}} {{ condominiumExpense.condominiumUnitType ? '(' +
                        condominiumExpense.condominiumUnitType + ')' : ''}}
                    </label>
                    <label class="control-label col-lg-2 text-right">
                        {{geFormattedCurrencyValue(condominiumExpense.condominiumExpense)}}
                    </label>
                </div>
                </ng-container>

            </ng-container>
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-left">
                     Monthly {{getExpenseTitle()}}
                </label>
                <label class="control-label col-lg-2 text-right">
                    {{geFormattedCurrencyValue(soaCommonExpense.commonExpenseAmount)}}
                </label>
            </div>
            <div class="col-lg-12" *ngIf="soaCommonExpense.isExpenseAdjusted">
                  <label class="control-label col-lg-3 text-left">
                        {{soaCommonExpense.commonExpenseAdjustedFor}} calculated at
                        {{soaCommonExpense.adjustPercentage}}%
                  </label>

                  <label class="control-label col-lg-2 text-right">
                    {{geFormattedCurrencyValue(soaCommonExpense.calculateEstimatedTax())}}
                  </label>
            </div>
            <div class="col-lg-12 margin-top-minus-3">
                <label class="control-label col-lg-3 text-left" style="white-space: pre-line; line-height: 23px">
                        {{getCommonExpensePaidLabel(soaCommonExpense)}}
                </label>
            </div>
            <div class="col-lg-12 margin-top-minus-3">
                <label class="control-label col-lg-3 text-left">
                        {{getCommonShareLabel(soaCommonExpense)}}'s share for {{getElapsedDaysLabel()}} {{getElapsedDaysText()}}
                </label>

                <label class="control-label col-lg-2 text-right">
                    {{geFormattedCurrencyValue(calculateVendorShare())}}
                </label>
            </div>

            <div class="col-lg-12" *ngIf="getElapsedDays() == 0 && soaCommonExpense.isCommonExpensePaid === 'NO' ">
                <label class="control-label col-lg-3 text-left">
                   No Adjustment
                </label>
            </div>

            <div class="col-lg-12" *ngIf="!(getElapsedDays() == 0 && soaCommonExpense.isCommonExpensePaid === 'NO')">
                <label class="control-label col-lg-3 text-left">
                        Credit {{creditType}}
                </label>

                <label class="control-label col-lg-3 text-right" *ngIf="creditType === purchaserLabel">
                    {{geFormattedCurrencyValue(calculateVendorShareDifference())}}
                </label>
                <label class="control-label col-lg-4 text-right" *ngIf="creditType === vendorLabel">
                    {{geFormattedCurrencyValue(calculateVendorShareDifference())}}
                </label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-10 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button type="button" *ngIf="addedFlag && allowDelete"
                    (click)="delete()"
                    class="secondary-button">
                <span>Delete</span>
            </button>
        </div>
        <div class="col-lg-2" *ngIf="!context.currentMatter.isMatterProvinceNBorNS">
            <dp-checkbox fieldId="checkBoxInfoOnly">
                <input type="checkbox"
                       id="checkBoxInfoOnly"
                       name="checkBoxInfoOnly"
                       [(ngModel)]="localInfoOnly"
                       [checked]="localInfoOnly"/>
            </dp-checkbox>

            <label class="control-label vertical-align-label display-inline" >Info Only</label>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
