import {Contact} from '../../shared/contact';
import {IdentificationRecord} from '../../shared/identification-record';

export interface PrintIdInfo {
  contact: Contact;
  identificationRecord: IdentificationRecord;
  name: string;
  similarNameDeclared: string;
  partyIs: string;
  verifiedBy: string;
}

export class PrintIdAction {
  public static readonly PRINT_ID: string = 'PRINT_ID';
  public static readonly PRINT_DECLARATION: string = 'PRINT_DECLARATION';
}
