import {Component, Inject, OnInit} from '@angular/core';
import {ModalResult} from '../../../../shared-main/enums';
import {Precedent} from '../../../shared/precedent';
import {PrecedentTypes} from '../../../shared/mortgage-precedent-type';
import {PrecedentService} from '../../../mortgages/mortgage/precedent/precedent.service';
import {Matter} from '../../../shared';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class WelcomeClientPrecedentModalContext {
  matter: Matter;
  mainClientName: string;
  participantName: string;
  setTextToCirfPrecedent: boolean;
}

@Component({
  selector: 'dp-welcome-client-precedent-modal-content',
  templateUrl: 'welcome-client-precedent-modal.component.html',
  styleUrls: ['./welcome-client-precedent-modal.component.scss']
})
export class WelcomeClientPrecedentModalComponent extends ModalComponent<WelcomeClientPrecedentModalContext> implements OnInit {
  precedents: Precedent[] = [];
  cirfPrecedent: Precedent;
  selectedPrecedentId: number;
  message: string;
  welcomePackagePrecedentId: number;

  constructor(
    public dialog: MatDialogRef<WelcomeClientPrecedentModalComponent>,
    public precedentService: PrecedentService,
    @Inject(MAT_DIALOG_DATA) context?: WelcomeClientPrecedentModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.dialog.close({
      action: ModalResult.OK,
      welcomePackagePrecedentId: this.welcomePackagePrecedentId,
      messagePrecedent: this.message
    });
  }

  close() {
    this.dialog.close({action: ModalResult.Cancel});
  }

  ngOnInit() {
    this.loadPrecedents();
  }

  async loadPrecedents(): Promise<void> {
    const precedents: Precedent[] = await this.precedentService.getPrecedents().toPromise();
    if (precedents && precedents.length) {
      this.precedents = precedents.filter((precedent) => precedent.precedentType === PrecedentTypes.MESSAGE);
      this.cirfPrecedent = precedents.find(
        (precedent) =>
          precedent.precedentType === PrecedentTypes.CIRF &&
          this.context.matter.derivedMatterType === precedent.precedentName
      );
    }
    if (this.context.setTextToCirfPrecedent) {
      this.setTextToCirfPrecedent();
    }
  }

  setMessage(): void {
    this.message = '';
    const selectedPrecedent: Precedent = this.getSelectedPrecedent();
    if (selectedPrecedent) {
      this.message = selectedPrecedent.description;
      this.welcomePackagePrecedentId = selectedPrecedent.id;
    }
  }

  getSelectedPrecedent(): Precedent {
    return this.precedents.find((prec) => prec.id === +this.selectedPrecedentId);
  }

  setTextToCirfPrecedent() {
    this.selectedPrecedentId = undefined;
    this.message = this.cirfPrecedent.description;
    this.welcomePackagePrecedentId = this.cirfPrecedent.id;
  }
}
