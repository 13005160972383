import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

declare var jQuery: any;

@Directive({
  selector: '[ngModel][dp-uppercase-preserve-cursor-position]',
  host: {
    '(input)': 'onInputChange($event)',
    '(keyup)': 'onInputChange($event)',
    '(blur)': 'onInputChange($event)'
  }
})
/*
 *  Combination dp-uppercase directive and dp-preserve-cursor-position directive to one directive.
 *  The dp-uppercase-preserve-cursor-position implements two function
 *  1. Immediately changes input to uppercase
 *  2. Put cursor back to the correct position
 */
export class UppercasePreserveCursorPositionDirective {
  constructor(private ngModel: NgModel) {
  }

  /*
   * Using NgModel instead of ngModelChange event emiitter for immediate value change
   * It helps getting rid of setTimeout which was causing lot's of editing hiccups
   */
  onInputChange($event): void {
    let originalValue = $event.target.value;
    let value = $event.target.value.toUpperCase();
    let currentInputControl = $event.target;
    let currentCursorPosition = $event.target.selectionStart;
    if (originalValue != value) {
      this.ngModel.reset(value);
      if ((currentInputControl != null) && (currentCursorPosition != null) && jQuery(currentInputControl).is(':focus')) {
        currentInputControl.setSelectionRange(currentCursorPosition, currentCursorPosition);
      }
    }
  }
}
