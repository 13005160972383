<div class="fields-group">
    <div class="main-level">
        <span class="title no-min-height">Mortgagee(s)</span>
    </div>

    <ng-container *ngFor="let mortgageeWrapper of matterPrivateLenders; let i=index">
        <div [ngClass]="{'shutter-bg': isExpanded(mortgageeWrapper),'shutter-bg-closed': !isExpanded(mortgageeWrapper)}">
            <!--<div class="form-group">-->
            <div class="form-group">
                <div class="shutter-arrow" (click)="toggleVtbMortgageeSnapshot(mortgageeWrapper)">
                    <div *ngIf="isExpanded(mortgageeWrapper)"><img src="/assets/img/minus.svg" alt=""/></div>
                    <div *ngIf="!isExpanded(mortgageeWrapper)"><img
                        src="/assets/img/plus.svg"/></div>
                </div>
                <div class="col-lg-2 control-label" (click)="toggleVtbMortgageeSnapshot(mortgageeWrapper)">Mortgagee {{i + 1}}</div>
                <div class="col-lg-10 vtb-mortgagee-header">
                    <input type="text"
                           id="mortgageeIndexId_{{mortgageeWrapper?.matterParticipant?.identifier}}"
                           class="form-control vtb-mortgagee-header-name"
                           readonly="readonly"
                           (click)="toggleVtbMortgageeSnapshot(mortgageeWrapper)"
                           (keydown.f9)="toggleVtbMortgageeSnapshot(mortgageeWrapper)"
                           [value]="getPrivateLenderName(mortgageeWrapper)"
                           fieldKey="matter.privateLender.shutter"
                           statusBarHelp
                           autocomplete="off"/>
                    <div class="vtb-mortgagee-header-actions">
                        <div class="vtb-mortgagee-header-primary" (click)="onPrivateLenderActionsClick($event)">
                                            <span class="primary radio">
                                              <input type="radio" [checked]="mortgageeWrapper.primary"/>
                                              <label (click)="setAsPrimaryPrivateLender(mortgageeWrapper)">Primary Contact</label>
                                            </span>
                        </div>
                        <div class="vtb-mortgagee-header-capacity">
                                        <span *ngIf="showIdWarningForParticipant(mortgageeWrapper.matterParticipant)"
                                              (click)="openIDVerificationSection(mortgageeWrapper)"
                                              [title]="'Incomplete Client ID Information'">
                                                <i class="fa fa-warning"></i>
                                                <span class="span-link">ID</span>
                                            </span>
                        </div>
                        <div class="vtb-mortgagee-header-capacity">
                                        <span *ngIf="isCapacityWarningForPrivateLenderVisible(mortgageeWrapper)">
                                            <i class="fa fa-warning" (click)="openCapacitySection(mortgageeWrapper)"></i>
                                            <span class="capacity-label">{{capacityTabLabel}}</span>
                                        </span>
                        </div>
                        <div class="width-30 actionbtns">
                            <dp-burger-menu *ngIf="!!mortgageeWrapper.matterParticipant"
                                            [items]="getVtbMortgageeBurgerMenuItems(i)"
                                            (itemClicked)="clickVtbMortgageeBurgerMenu($event, mortgageeWrapper)">
                            </dp-burger-menu>
                        </div>
                    </div>
                </div>
            </div>
            <!--</div>-->
            <!--<div  class="dp-accordion-content">-->

            <div class="form-group" *ngIf="isExpanded(mortgageeWrapper)">
                <div class="padding-top-10 col-lg-10 offset-lg-2">
                    <ul class="dppm-custom-tab">
                        <li [ngClass]="{active: mortgageeWrapper.selectedTab == 'Profile'}"
                            (click)="selectTab('Profile', mortgageeWrapper)" tabindex="0"
                            (keyDown.enter)="selectTab('Profile', mortgageeWrapper)">
                            <a>Profile</a>
                        </li>
                        <li *ngIf="mortgage.mortgageCapacityType === 'OTHER'"
                            [ngClass]="{active: mortgageeWrapper.selectedTab === 'Capacity-Share'}"
                            (click)="selectTab('Capacity-Share', mortgageeWrapper)"
                            tabindex="0"
                            (keyDown.enter)="selectTab('Capacity-Share', mortgageeWrapper)">
                            <a dp-province-field-label="matter.purchaser.capacity">Capacity</a>
                        </li>
                        <li [ngClass]="{active: mortgageeWrapper.selectedTab === 'ID-Details'}"
                            (click)="selectTab('ID-Details', mortgageeWrapper)" tabindex="0"
                            (keyDown.enter)="selectTab('ID-Details', mortgageeWrapper)">
                            <a>ID Verification</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-10 offset-lg-2 margin-top-20">
                    <div [ngSwitch]="mortgageeWrapper.selectedTab">
                        <ng-container *ngSwitchCase="'Profile'">
                            <dp-private-lender-info
                                [privateLenderWrapper]="mortgageeWrapper"
                                [matter]="matter"
                                [mortgage]="mortgage"
                                [matterSpecificDataReadOnly]="mortgageeWrapper.sourceContactIsLocked"
                                [signingOfficerVisible]="!mortgageeWrapper.primary"
                                [showMailingAddress]="true"
                                [isVTBMortgagee]="true"
                                [fieldPrefix]="'mortgage.' + mortgageIndex + '.mortgagee.privateLender'"
                                fieldIndexId="privateLenderIndexId"
                                [fieldIdentifier]="mortgageeWrapper?.matterParticipant?.identifier"
                                containerId="privateLenderContainer"
                                (privateLenderInfoChanged)="privateLenderInfoChanged()"></dp-private-lender-info>
                            <dp-mortgage-contact-info *ngIf="mortgageeWrapper.matterParticipant && mortgageeWrapper.primary"
                                                      [mortgage]="mortgage"
                                                      [matter]="matter"
                                                      [matterParticipant]="mortgageeWrapper.matterParticipant"
                                                      [isSourceContactLocked]="isSourceContactLocked(mortgageeWrapper)"
                                                      [isOutOfSync]="isOutOfSync(mortgageeWrapper)"
                                                      [isStale]="mortgageeWrapper.isStale"
                                                      [isSnapshotPrivateLenderGlobalMortgage]="isSnapshotPrivateLenderGlobalMortgage(mortgageeWrapper)"
                                                      [primaryContactType]="'PRIVATE_LENDER'"
                                                      [showMailingAddress]="false"
                                                      [contactRecordExists]="false"
                                                      [isVTBMortgagee]="true"
                                                      [signingOfficerVisible]="mortgageeWrapper.matterParticipant?.contact.gender === 'OTHERENTITY'"
                                                      (changed)="privateLenderInfoChanged()">
                            </dp-mortgage-contact-info>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Capacity-Share'">
                            <dp-capacityShare [matterParticipant]="mortgageeWrapper.matterParticipant"
                                              [readOnly]="capacityReadOnly(mortgageeWrapper)"
                                              (updateCapacity)="notifyChange()"
                                              [purchaserId]="mortgageeWrapper.matterParticipantId"></dp-capacityShare>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ID-Details'">
                            <dp-snapshotIdDetails [participant]="mortgageeWrapper.matterParticipant"
                                                  [client]="mortgageeWrapper.matterParticipant?.contact"
                                                  [idDetailsEditable]="!snapshotReadOnly(mortgageeWrapper)"
                                                  [idVerifiedEnterOnFieldsReadOnly]="snapshotReadOnly(mortgageeWrapper)"
                                                  (onDataChange)="privateLenderInfoChanged()"

                            >
                            </dp-snapshotIdDetails>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!--</div>-->
        </div>

    </ng-container>


    <div class="form-group">
        <div class="col-lg-10" [ngClass]="{'offset-lg-2':hasPrivateLenders()}">
            <ng-container *ngIf="isAddAnotherPrivateLenderVisible()">
                <span class="actionlink"><a href="javascript:void(0);" (click)="addVTBMortgagee()">{{getAddMortgageeButtonText()}} </a></span>
                <span class="selector fa fa-plus-square-o"></span>
            </ng-container>
            <span class="margin-left-20"></span>
            <span class="actionlink"><a href="javascript:void(0);" (click)="addVendorsAsMortgagees()">Add all vendors as mortgagee </a></span>
            <span class="selector fa fa-plus-square-o"></span>
            <ng-container *ngIf="hasPrivateLenders()">
            <span class="actionlink margin-left-20">
                                <span>
                                    <a href="javascript: void(0)" (click)="onClickPrintIdForms()"
                                    >
                                        Produce ID Forms and Declarations
                                    </a>
                                </span>
                            </span>
            </ng-container>.
        </div>
    </div>

    <div *dpShowByProvince="'matter.mortgage.titleDetailsSection'" class="padding-top-5">
        <label class="col-lg-2 control-label">Title Details</label>
        <div class="col-lg-2">
            <select class="form-control" id="titleDetailsType" name="titleDetailsType" [(ngModel)]="mortgage.mortgageContactInfo.titleDetailsType"
                    (ngModelChange)="onTitleDetailsChange()">
                <option *ngFor="let option of titleDetailsTypeOptions" [value]="option.value">{{ option.label }}</option>
            </select>
        </div>
        <div class="col-lg-8">
                        <textarea name="titleDetailsDescriptionAuto"
                                  id="titleDetailsDescriptionAuto"
                                  rows="2" cols="80"
                                  [ngModel]="matter.getPrivateLendersTitleDetails(mortgage)"
                                  [dp-read-only]="true"
                                  *ngIf="!mortgage.mortgageContactInfo.isTitleDetailsManual()"
                        >

                        </textarea>

            <textarea class="form-control"
                      id="titleDetailsDescription"
                      name="titleDetailsDescription"
                      *ngIf="mortgage.mortgageContactInfo.isTitleDetailsManual()"
                      rows="2" cols="80"
                      [(ngModel)]="mortgage.mortgageContactInfo.titleDetailsDescription"
                      (ngModelChange)="notifyChange()">
                        </textarea>
        </div>
    </div>
    <div class="clear padding-bottom-10"></div>


    <div class="form-group" *ngIf="isMoreThanOnePrivateLender()">
        <label class="col-lg-2 control-label" for="mortgageCapacityType" [dp-province-field-label]="'matter.mortgage.mortgagee.provinceBasedCapacityTitle'">Capacity</label>
        <div class="col-lg-10">
            <select class="form-control" id="mortgageCapacityType" name="mortgageCapacityType" [(ngModel)]="mortgage.mortgageCapacityType"
                    (change)="onMortgageCapacityChange($event.target.value)">
                <option *ngFor="let option of mortgageCapacityOptions" [value]="option.value">{{ option.label }}</option>
            </select>
        </div>
    </div>
    <ng-container *dpShowByProvince="'matter.mortgage.iscClientNumber'">
        <div class="form-group col-lg-12">
            <label class="col-lg-2 control-label">ISC Client Number</label>
            <div class="col-lg-4">
                <input id="iscClientNumber" name="iscClientNumber" class="form-control"
                       [(ngModel)]="mortgage.iscClientNumber" (change)="notifyChange()" maxlength="25">
            </div>
        </div>
    </ng-container>
    <div class="form-group">
        <div *ngIf="mortgage && mortgage.lenderReline">
            <label class="col-lg-2 control-label">Lender's RE: Line</label>
            <div class="col-lg-10">
                <input id="lenderReLine" name="lenderReLine" class="form-control" [ngModel]="mortgage.lenderReline"  maxlength="200">
            </div>
        </div>
    </div>
</div>
