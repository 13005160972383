import {api} from '../../../../common/api';

/** Application endpoint urls
 */
export const precedentApi = {

  precedents: `${ api }/accounts/{accountId}/precedents`,
  defaultPrecedent: `${ api }/system/precedents/defaults`

};
