<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="localAdjustment" id="otherFixedSoaForm" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : {{adjustmentType}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div *ngIf="context.matter.isPurchaseBC">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                           data-testId="otherFixedModal-debitType-label">Debit</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="debitTargetType"
                            name="debitTargetType"
                            [(ngModel)]="localAdjustment.debitType"
                            class="form-control"
                            data-testId="otherFixedModal-debitType-select"
                    >
                        <option *ngFor="let debitTargetType of debitTargetTypes" [value]="debitTargetType.value">
                            {{ debitTargetType.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div>
                <div class="vertical-align-label {{context.matter.isPurchaseBC ? 'col-lg-2' : 'col-lg-3'}}">
                    <label class="control-label"
                           data-testId="otherFixedModal-creditType-label">{{creditToLabel}}</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="creditTargetType"
                            name="creditTargetType"
                            [(ngModel)]="localAdjustment.creditType"
                            class="form-control"
                            data-testId="otherFixedModal-creditType-select"
                    >
                        <option *ngFor="let creditTargetType of creditTargetTypes" [value]="creditTargetType.value">
                            {{creditTargetType.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Adjustment heading</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           name="adjustmentHeading"
                           id="adjustmentHeading"
                           maxlength="38"
                           [(ngModel)]="localAdjustment.adjustmentHeading"
                           (ngModelChange)="onChangeAdjustmentHeading($event);"
                           trimmed
                           class="form-control"
                           dp-error-validator
                           [fieldKey]="'matter.soadj.other.fixed.description'"
                    />
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="isCreditPurchaserOrVendor">
            <div class="form-group" *ngIf="isCreditPurchaserOrVendor">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">{{ itemAdjAmtLabel() }}</label>
                    </div>
                    <ng-container *ngIf="showItemAdjAmountSelect()">
                        <div class="col-lg-3" *ngIf="displayTaxFields()">
                            <select type="text"
                                    id="itemAdjustmentHeading"
                                    name="itemAdjustmentHeading"
                                    [(ngModel)]="localAdjustment.amountHeading"
                                    class="form-control"
                            >
                                <option *ngFor="let itemHeadingType of itemHeadingTypes" [value]="itemHeadingType.value">
                                    {{itemHeadingType.label}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3" *ngIf="!displayTaxFields()">
                            <input type="text"
                                   id="itemAdjAmtRO"
                                   name="itemAdjAmtRO"
                                   [dp-read-only]="true"
                                   [ngModel]="localAdjustment.amountHeading"
                                   class="form-control"
                            >
                        </div>
                    </ng-container>
                    <div class="col-lg-2">
                        <input type="text"
                               id="itemAdjAmt"
                               name="itemAdjAmt"
                               [(ngModel)]="localAdjustment.amount"
                               (ngModelChange)="updateHSTWarning()"
                               class="form-control currency-align-right"
                               dp-currency
                               dp-error-validator
                               [fieldKey]="'matter.soadj.other.fixed.adj.amount'"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group col-lg-12" *ngIf="isOtherProratedOnPercentageInterest()">
                <div class="vertical-align-label col-lg-3"><label class="control-label">Purchaser's portion based on <br>percentage interest</label></div>
                <div class="col-lg-3 padding-left-7">
                    <input type="text" id="purchaserPortionBasedOnPercentageInterest" name="purchaserPortionBasedOnPercentageInterest" class="form-control text-right"
                           [(ngModel)]="purchaserPortionBasedOnPercentageInterest"
                           [ngClass]="{'padding-right-30' : (context.matter.isCondoCorporation  || context.project.isCondo)}"
                           padding-zero="true"
                           dp-percentage
                           [dp-read-only]="true"
                           [padding-zero]="true"
                           [max-decimals] = 9
                           [max-int] = 99.999999999
                    />
                    <button
                        type="button"
                        id="unitLevelPlanButton"
                        tabIndex="0"
                        (click)="selectUnitLevelPlan()" class="button-glyph">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </div>
                <div class="col-lg-2">
                    <label class="control-label col-lg-12 text-left">%</label>
                </div>
            </div>


            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label" [dp-province-field-label]="'matter.soa.other-fixed.modal.adjustFor'">Adjust for</label>
                    </div>
                    <div class="col-lg-1" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'">
                        <select type="text"
                                id="taxType"
                                name="taxType"
                                [(ngModel)]="localAdjustment.taxType"
                                class="form-control"
                                [disabled]="localAdjustment.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'"
                        >
                            <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                                {{taxType.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyTax"
                                name="applyTax"
                                [(ngModel)]="localAdjustment.applyTax"
                                (change)="checkHSTWarning();"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>
                    <ng-container *ngIf="displayTaxFields()">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">{{localAdjustment.taxType}} percentage is</label>
                        </div>
                        <div class="col-lg-1" *ngIf="context.soaConsiderationTaxes && localAdjustment.taxType == context.soaConsiderationTaxes.rateType">
                            <input type="text"
                                   id="hstPercentage"
                                   name="hstPercentage"
                                   [(ngModel)]="localAdjustment.hstRate"
                                   class="form-control"
                                   dp-percentage
                                   [disabled]="localAdjustment.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'"
                            />
                        </div>
                        <div class="col-lg-1" *ngIf="localAdjustment.taxType == 'GST'  && context.defaultPstRate">
                            <input type="text"
                                   id="gstPercentage"
                                   name="gstPercentage"
                                   [(ngModel)]="localAdjustment.gstRate"
                                   class="form-control"
                                   dp-percentage
                                   [disabled]="localAdjustment.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'"
                            />
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="form-group" *ngIf="isAdjustForPstVisible()">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Adjust for PST</label>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyPst"
                                name="applyPst"
                                [(ngModel)]="localAdjustment.applyPst"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>

                    <ng-container *ngIf="displayPstPercentage()">
                        <div class="vertical-align-label {{context.matter.isPurchaseBC ? 'col-lg-2' : 'col-lg-3'}}">
                            <label class="control-label">PST percentage is</label>
                        </div>
                        <div class="col-lg-1">
                            <input type="text"
                                   id="pstPercentage"
                                   name="taxPercentage"
                                   [(ngModel)]="localAdjustment.pstRate"
                                   class="form-control"
                                   dp-percentage
                                   [disabled]="localAdjustment.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'"
                            />
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="form-group" *ngIf="context.purchaseIsOfANewHomeFromABuilder && isCreditVendor">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Is amount additional consideration to vendor ?</label>
                    </div>
                    <div class="col-lg-4">
                        <select type="text"
                                id="amountAdditionalConsiderationToVendor"
                                name="amountAdditionalConsiderationToVendor"
                                [(ngModel)]="localAdjustment.amountAdditionalConsiderationToVendor"
                                class="form-control"
                                (change)="checkHSTWarning()"
                                dp-error-validator
                                [fieldKey]="'matter.soadj.other.fixed.amount.additional.consideration.to.vendor.sale.price'"
                        >
                            <option *ngFor="let aacvo of amountAdditionalConsiderationToVendorOptions" [value]="aacvo.value">
                                {{aacvo.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="context.project && isAdjustmentPaidOnInterimClosingVisibleWithProject()">
                <div class="col-lg-12"  *dpProjectShowByProvince="'project.soa.modal.adjustmentPaidOnInterimClosing';project:context.project">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Adjustment to be paid by purchaser on interim closing</label>
                    </div>
                    <div class="col-lg-4">
                        <select type="text"
                                id="adjustmentPaidOnInterimClosing"
                                name="adjustmentPaidOnInterimClosing"
                                [(ngModel)]="localAdjustment.adjustmentPaidOnInterimClosing"
                                class="form-control"
                        >
                            <option *ngFor="let noYesOption of noYesOptions" [value]="noYesOption.value">
                                {{noYesOption.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="(!context.project) && localAdjustment.amountAdditionalConsiderationToVendor != 'NO' && isCreditVendor">
                <div *dpShowByProvince="'ps.matter.soa.modal.adjustmentPaidOnInterimClosing'" >
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Adjustment to be paid by purchaser on interim closing</label>
                    </div>
                    <div class="col-lg-4">
                        <select type="text"
                                id="adjustmentPaidOnInterimClosingOnProjectSale"
                                name="adjustmentPaidOnInterimClosingOnProjectSale"
                                [(ngModel)]="localAdjustment.adjustmentPaidOnInterimClosing"
                                class="form-control"
                        >
                            <option *ngFor="let noYesOption of noYesOptions" [value]="noYesOption.value">
                                {{noYesOption.label}}
                            </option>
                        </select>
                    </div>

                </div>
            </div>

        </div>

        <div style="border-top: 1px solid #cccccc;">
            <div class="col-lg-12 form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{localAdjustment.adjustmentHeading}}</label>
                </div>
            </div>

            <div *ngFor="let item of localAdjustment.items;let i = index;" >
                <div class="col-lg-12 form-group">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-5">
                        <input type="text"
                               id="item-heading-{{i}}-{{deleteIndex}}"
                               name="item-heading-{{i}}-{{deleteIndex}}"
                               maxlength="38"
                               [(ngModel)]="item.heading"
                               class="form-control"
                        />
                        <div class="actionbtns">
                            <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeItem(item);" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2" *ngIf="!isOtherProratedOnPercentageInterest()">
                        <input type="text"
                               id="item-amount-{{i}}-{{deleteIndex}}"
                               name="item-amount-{{i}}-{{deleteIndex}}"
                               [(ngModel)]="item.amount"
                               [significant-currency-digits] = "9"
                               class="form-control currency-align-right"
                               dp-currency
                               allow-blank="true"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="localAdjustment.items.length < maxItems">
                <div class="col-lg-1"></div>
                <div class="col-lg-11">
                    <span class="actionlink">
                        <span>
                            <a href="javascript:void(0);" (click)="addNewItem();">
                            Add another Item
                            </a>
                        </span>
                        <span class="selector fa fa-plus-square-o"></span>
                    </span>
                </div>
            </div>
        </div>

        <div class="row col-lg-12 form-group" *ngIf="displayNote()">
            <div class="col-lg-1"></div>
            <div class="col-lg-7 alert alert-info alert-info-other-fixed" >
                <div class="col-lg-1">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                </div>
                <div class="col-lg-11">
                    <span>
                      {{noteText()}}
                    </span>
                </div>
            </div>
        </div>

        <!-- and now adjustment details -->

        <div *ngFor="let line of details">
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                <label *ngIf="line.value && line.value.length > 0" class="control-label" [ngClass]="{'col-lg-4': line.pushValueToRight, 'col-lg-2': !line.pushValueToRight}">{{line.value}}</label>
            </div>
        </div>

        <div class="col-lg-12" *ngIf="localAdjustment && localAdjustment.creditType === noAdjText">
            <div class="col-lg-1"></div>
            <label class="control-label col-lg-3 text-left">
                No Adjustment
            </label>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>
                <ng-container *ngIf="isOtherFixed()">
                    <button type="button" class="secondary-button" (click)="retrievePredefined(this.context.provinceCode, this.context.statementAdjustmentKey)">
                        <span>Retrieve Predefined</span>
                    </button>
                    <button type="button" class="secondary-button" (click)="savePredefined()">
                        <span>Save Predefined</span>
                    </button>
                </ng-container>
            </div>
            <ng-container *ngIf="!isOtherProratedOnPercentageInterest()">
                <div *ngIf="context.project">
                    <div class="col-lg-2" *dpProjectShowByProvince="'project.soa.modal.adj.fixed.infoOnly';project:context.project">
                        <dp-checkbox fieldId="checkBoxInfoOnly">
                            <input type="checkbox"
                                   id="checkBoxInfoOnly"
                                   name="checkBoxInfoOnly"
                                   [(ngModel)]="localAdjustment.infoOnly"
                                   [checked]="localAdjustment.infoOnly"/>
                        </dp-checkbox>

                        <label class="control-label vertical-align-label display-inline" >Info Only</label>
                    </div>
                </div>
                <div *ngIf="!context.project">
                    <div class="col-lg-2" *dpShowByProvince="'ps.matter.soa.modal.adj.fixed.infoOnly'">
                        <dp-checkbox fieldId="checkBoxInfoOnlyForProjectSale">
                            <input type="checkbox"
                                   id="checkBoxInfoOnlyForProjectSale"
                                   name="checkBoxInfoOnlyForProjectSale"
                                   [(ngModel)]="localAdjustment.infoOnly"
                                   [checked]="localAdjustment.infoOnly"/>
                        </dp-checkbox>

                        <label class="control-label vertical-align-label display-inline" >Info Only</label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

