import {Component, Inject} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {FilterTypeValue, MatterListFilter} from '../matter-list-filter';
import {MatterService} from '../../matter.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ApplicationError, FieldError} from '../../../core/application-error';
import {DPError} from '../../../shared/error-handling/dp-error';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DefaultMatterListFilterName} from '../../../shared-main/constants';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class SaveMatterListFilterModalContext {
  matterListFilter: MatterListFilter;
  isMassUpdate: boolean;
}

@Component({
  selector: 'dp-save-matter-list-filter-modal',
  templateUrl: 'save-matter-list-filter-modal.component.html',
  providers: [ ErrorService, DialogService ]
})
@AutoUnsubscribe()
export class SaveMatterListFilterModal extends ModalComponent<SaveMatterListFilterModalContext> {
  matterListFilter: MatterListFilter;

  constructor(public dialog: MatDialogRef<SaveMatterListFilterModal>,
              public dialogService: DialogService,
              public errorService: ErrorService,
              public matterService: MatterService,
              @Inject(MAT_DIALOG_DATA) context?: SaveMatterListFilterModalContext
            ) {
              super(dialog, context);
  }

  ngOnInit() {
    if (this.context.matterListFilter) {
      this.matterListFilter = this.context.matterListFilter;
    } else {
      this.matterListFilter = new MatterListFilter();
    }
  }

  ok() {
    // Try to create a matterListFilter
    if (this.context.isMassUpdate) {
      this.matterListFilter.selectedProjectIds = [ 'ALL' ];
      this.matterListFilter.provinceCodeFilters = [ 'ALL' ];
      this.matterListFilter.matterTypeFilters = [ 'ALL' ];
    }
    this.matterListFilter.matterListFilterName = this.matterListFilter && this.matterListFilter.matterListFilterName.trim();
    this.matterService.addMatterListfilter(this.matterListFilter).subscribe((matterListFilter: MatterListFilter) => {
      this.dialog.close({matterListFilter: matterListFilter, action: 'ok'});
    }, (error: ApplicationError) => {
      if (error.errorCode === 'app.filterNameExists') { //Duplicate filter name
        this.replaceExistingFilterDialog(error);
      } else {
        this.handleApiErrors(error);
        this.dialog.close({matterListFilter: this.matterListFilter, action: 'failed'});
      }
    });
  }

  replaceExistingFilterDialog(error: ApplicationError) {
    // this.matterListFilter.id = Number(error.message);
    //popup to replace filter dialog
    this.dialogService.confirm('Confirmation', 'Filter ' + this.matterListFilter.matterListFilterName + ' already exists. Do you want to replace it?', false, 'OK').subscribe(res => {
      if (res) { //Replace the existing filter
        this.replaceExistingFilter(error.message);
      } else { //Give up replacing the existing filter
        this.dialog.close({matterListFilter: this.matterListFilter, action: 'cancel'});
      }
    });
  }

  async replaceExistingFilter(id: string) {
    let matterListFilters: MatterListFilter[];
    if (this.context.isMassUpdate) {
      matterListFilters = await this.matterService.getAllMatterListFilter(FilterTypeValue.matter).toPromise();
      if (matterListFilters) {
        const matterFilter = matterListFilters.find(item => item.matterListFilterName == this.matterListFilter.matterListFilterName);
        this.matterListFilter.selectedProjectIds = matterFilter.selectedProjectIds;
        this.matterListFilter.matterListFilterSortBy = matterFilter.matterListFilterSortBy;
        this.matterListFilter.matterListFilterSortByOrder = matterFilter.matterListFilterSortByOrder;
        this.matterListFilter.provinceCodeFilters = matterFilter.provinceCodeFilters;
        this.matterListFilter.matterTypeFilters = matterFilter.matterTypeFilters;
      }

    }
    this.matterService.updateMatterListFilter(this.matterListFilter, id).subscribe(res => {
      this.dialog.close({matterListFilter: res, action: 'ok'});
    }, (error) => {
      this.dialog.close({matterListFilter: this.matterListFilter, action: 'failed'});
    });

  }

  cancel() {
    const result = {matterListFilter: this.matterListFilter, action: 'cancel'};
    this.dialog.close(result);
  }

  handleApiErrors(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.errorService.addDpSaveError(DPError.createCustomDPError(fieldError.errorCode, fieldError.errorCode + ':' + fieldError.message, null, 'ERROR'));
      });
    } else {
      this.errorService.addDpSaveError(DPError.createCustomDPError(error.errorCode, error.errorCode + ':' + error.errorMessage, null, 'ERROR'));
    }
  }

  // If the filter name is same "All Matters" or "My Recent" or empty, it should disable Ok button
  get isOkButtonDisabled(): boolean {
    return !this.matterListFilter.matterListFilterName || (this.matterListFilter.matterListFilterName && !this.matterListFilter.matterListFilterName.trim())
      || this.matterListFilter.matterListFilterName === DefaultMatterListFilterName.ALL_MATTERS
      || this.matterListFilter.matterListFilterName === DefaultMatterListFilterName.MY_RECENT;
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }
}
