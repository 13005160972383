<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo"></span></a>
            </div>
            <div class="vertical-login-bar">
            </div>
            <div class="login-dtls-header">Practice made Perfect</div>
            <div class="login-dtls">
                Whether practicing in the area of real estate, corporate, wills, or estate law, Unity® Practice Management will make it
                easier for you to seamlessly connect with industry service providers, key stakeholders, other legal
                professionals and customers.
            </div>
        </div>
        <div class="login-card forgot-pwd-card" [ngClass]="{'forgot-pwd-card-error' : !!errorMessage}"></div>
        <form #setupPasswordForm="ngForm" autocomplete="off" novalidate>
            <div class="login-card-input">
                <span class="forgot-pwd-header" *ngIf="!user.passwordChangeRequired">Set a new password</span>
                <span class="forgot-pwd-header" *ngIf="user.passwordChangeRequired">Your password has expired, please set a new password</span>
                <div class="padding-bottom-20"
                     [ngClass]="{'padding-bottom-25' :passwordModel.errors && (passwordModel.dirty || passwordModel.touched) && passwordModel.errors.password && passwordModel.errors.password.errors}">

                    <label class="login-label" for="password">Password<sup>1</sup></label>
                    <div>
                        <input type="password"
                               id="password"
                               name="password"
                               class="form-control"
                               [(ngModel)]="password"
                               password
                               required
                               #passwordModel="ngModel">
                    </div>
                    <div
                        *ngIf="passwordModel.errors && (passwordModel.dirty || passwordModel.touched) && passwordModel.errors.password && passwordModel.errors.password.errors"
                        class="col-lg-12 login-error">
                        <span class="error-input-img"></span>{{passwordModel.errors.password.errors[0]}}
                    </div>

                </div>
                <div class="padding-bottom-20">

                    <label class="login-label" for="passwordRetype">Password</label>
                    <div>
                        <input type="password"
                               id="passwordRetype"
                               name="passwordRetype"
                               class="form-control"
                               [(ngModel)]="passwordRetype"
                               password
                               equal="password"
                               required
                               #passwordRetypeModel="ngModel">
                    </div>
                    <div
                        *ngIf="passwordRetypeModel.errors && (passwordRetypeModel.dirty || passwordRetypeModel.touched) && !passwordRetypeModel.errors.equal"
                        class="col-lg-12 login-error">
                        <span class="error-input-img"></span>Re-entered password must match New Password
                    </div>
                </div>
            </div>
            <div class="setup-pwd-help">
                <sup>1</sup> Please make sure that the password has a minimum of 8 characters. No spaces allowed.
                Allowed characters are: A to
                Z, a to z, 0 to 9 and special characters. The password cannot have more than 2 consecutive characters
                being the same letter, number
                or special character- example - Mississauga#21 is acceptable but Baaad#14 is not acceptable.
            </div>
            <div>
                <button type="submit" (click)="updateProfile()"
                        class="primary-btn login-btn setup-pwd-btn" style="">NEXT
                </button>
            </div>
            <div class="error-msg setup-msg-error" *ngIf="errorMessage">
                <span class="error-img"></span> <span>{{errorMessage}}</span>
            </div>
        </form>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>
<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>
