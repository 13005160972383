import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {ContactQueryService} from '../contact/contact-query.service';
import {TabsService} from '../core/tabs.service';
import {DialogService} from '../shared/dialog/dialog.service';
import {ContactService} from '../shared-main/contact.service';
import {ErrorService} from '../shared/error-handling/error-service';
import {LockScreenService} from '../core/lock-screen.service';
import {DocumentProfile} from '../admin/document-profile/document-profile';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {Utils} from '../matters/shared/utils';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {DPError} from '../shared/error-handling/dp-error';
import {ApplicationError, FieldError} from '../core/application-error';
import {StatusBarMessages} from '../shared-main/status-bar-messages';
import {Account} from '../admin/accounts/shared/account';
import {StatusBarService} from '../shared-main/status-bar.service';
import {ProvinceCode} from '../admin/accounts/shared/account-province';
import {Project} from './shared/project';
import {projectApi} from './shared/project-api';
import {projectResponseKey} from './shared/project-response-key';
import {ProjectTab} from './shared/project-tab';
import {DocumentRegistration} from './shared/document-registration';
import {TeraviewConfig} from '../admin/docket/teraview-config';
import {SpecialComment} from '../shared/modal/special-comment';
import {projectConsts} from './shared/project-consts';
import {TeraviewImportFileType} from './shared/project-types';

import {ProjectAdjustmentConfig} from './project-adjustments/project-adjustment-config';
import {ConsiderationTaxes} from '../matters/consideration-ltt/consideration-taxes';
import {Tax_RATE} from '../shared-main/province-based-dropdowns';
import {TaxRateService} from '../matters/consideration-ltt/tax-rate.service';
import moment from 'moment';
import {Subscription} from 'rxjs/index';
import {ProjectSoAdjFieldCodeValidation} from '../matters/statement-adjustment/project-soadj-field-code-validation';
import {ProjectSoAdjFieldCode} from './shared/project-soa-adj-field-code';
import {DuplicatePinMatterInfo} from './shared/duplicate-pin-matter-info';
import {ProjectAdjustmentActionType} from '../matters/statement-adjustment/modals/project-delete-soa/project-delete-soa.modal.component';
import {ProgressionStatus, StatementAdjustment} from '../matters/statement-adjustment/statement-adjustment';
import {Matter, matterResponseKey} from '../matters/shared';
import * as _ from 'lodash';
import {StatementAdjustmentService} from '../matters/statement-adjustment/statement-adjustment.service';
import {ProjectMatterCacheService} from '../core/project-matter-cache.service';
import {SESSION_ID_REQ_PARAM} from '../common';
import {HoldbackSummary} from '../matters/shared/advance-holdback/matter-holdback';
import {DuplicateLotMatterInfo} from './shared/duplicate-lot-matter-info';
import {DuplicateAddressMatterInfo} from './shared/duplicate-address-matter-info';
import {DuplicateUnitMatterInfo} from './shared/duplicate-unit-matter-info';
import {deliveryOfCashToCloseValues} from '../shared-main/constants';

// This is a sharable service which is used for all the project API.
@Injectable()
export class ProjectService {

  constructor(private http: HttpClient,
              private dialogService: DialogService,
              private contactService: ContactService,
              private documentProfileService: DocumentProfileService,
              private contactQueryService: ContactQueryService,
              private lockScreenService: LockScreenService,
              private tabsService: TabsService,
              private errorService: ErrorService,
              public statusBarService: StatusBarService,
              public taxRateService: TaxRateService,
              public statementAdjustmentService: StatementAdjustmentService,
              public projectMatterCacheService: ProjectMatterCacheService) {
  }

  // this method is for getting  all the projects from database
  getProjects(searchView: string, query: string, filterProjects?: string[], sortQuery?: string, sortType?: string, page?: number, perPage?: number): Observable<Project[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string;

    let searchQuery = this.createProjectSearchRequest(query, filterProjects, sortQuery, sortType);
    url = `${ projectApi.projectsForAccount(accountId) }?` + searchQuery;
    if (page) {
      url += `&page=${ page }&per_page=${ perPage }`;
    }

    return this.http.get(url).map((res) => {

      return res[ projectResponseKey.projects ].map((item) => {
        return new Project(item);
      });
    });
  }

  //This method is for creating project search request with all filters.
  createProjectSearchRequest(query: string, filterProjects?: string[], sortQuery?: string, sortType?: string): string {
    let url: string;
    let urlQuery = query;
    urlQuery = Utils.escapeSearchText(urlQuery);
    let sort: string = sortQuery ? sortQuery : 'lastUpdatedTimeStamp';
    let sortingType: string = sortType ? sortType : 'DESC';
    let filter: string;
    let filterFlag: boolean = false;
    if (Array.isArray(filterProjects)) {
      filter = !filter ? '' : Utils.addCommaToFilter(filter);
      if (filterProjects.length > 0) {
        filterFlag = true;
        filter += filterProjects.join(',');
      }
    }

    if (urlQuery != null && urlQuery != '' && urlQuery) {
      filter = Utils.addCommaToFilter(filter);
      filter += 'ANYprojectRecordNumber_EQ_*' + urlQuery + '*|projectName_EQ_*' + urlQuery + '*';
    }

    if (sort != null) {
      sort = 'sort=' + sort + '|' + sortingType;
    }

    url = urlQuery || filterFlag ?
      `${ sort }&filter=${ filter }&filterType=ALL`
      : `${ sort }`;

    return url;
  }

  // get project by id from backend
  getProject(id: number, lockProject = true, lockScreen?: boolean): Observable<Project> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(`${ projectApi.projectForAccount(accountId, id) }` + '?lockRequired=' + lockProject, lockScreen)
    .map((res) => {
      const project: Project = new Project(res[ 'Project' ]);
      return project;
    });
  }

  getProjectByProjectRecordNumber(projectRecordNumber: string, lockScreen?: boolean): Observable<any> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(`${ projectApi.projectsForAccount(accountId) }?filter=projectRecordNumber_EQ_${ Utils.escapeSearchText(projectRecordNumber) }`, lockScreen ? lockScreen : false)
    .map((res) => {
      return res[ 'Projects' ];
    });
  }

  updateProject(id: number, project: any): Observable<any> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = `${ projectApi.projectForAccount(accountId, id) }`;
    return this.http.put(url, project)
    .map((res) => {
      const project: Project = new Project(res[ projectResponseKey.project ]);
      project.dirty = false;
      return project;
    });
  }

  addProject(project: any): Observable<any> {

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.postWithNoCatch(projectApi.createProject(accountId), JSON.stringify(project))
    .map((res) => {
      const project: Project = new Project(res[ projectResponseKey.project ]);
      project.dirty = false;
      return project;
    });

  }

  initProject(project: Project): Observable<boolean> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let obsProjectArray: any[] = [];

    if (project.defaultDocumentProfileId) {
      obsProjectArray.push(this.documentProfileService.getById(project.defaultDocumentProfileId, accountId).catch(error => Observable.of(error)));
    } else {
      obsProjectArray.push(this.documentProfileService
      .getDefaultDocumentProfileOfLoggedInUser(accountId, project.provinceCode).catch(error => Observable.of(error)));
    }
    obsProjectArray.push(this.taxRateService.cachedConsiderationTaxRate(project.provinceCode).catch(error => Observable.of(error)));

    this.lockScreenService.lockForUpdate = true;
    return forkJoin(obsProjectArray).finally(() => {
      this.lockScreenService.lockForUpdate = false;
    }).flatMap(results => {
      if (results) {
        results.forEach((result: any[]) => {
          if (result && result instanceof DocumentProfile) {
            project.documentProfile = result;
            project.defaultDocumentProfileId = result.id;
          } else if (result && result[ 0 ] instanceof ConsiderationTaxes) {
            this.setupTaxRateOnProject(project, result);
          }
        });
      }
      if (results && !project.isProjectExisting()) {
        this.setUpNewProject(project);
      }
      return Observable.of(true);
    });
  }

  setUpNewProject(project: Project): void {
    project.status = 'ACTIVE';
    project.fullyInitialized = true;
    project.useSameDocketIdentifier = true;
    project.updateDocumentExecutionDate = true;
    project.teraviewDocket = projectConsts.teraviewDocketType.ALL;
    project.insertFileNoIntoTeraviewDocket = false;
    project.docRegistration = new DocumentRegistration();
    project.teraviewConfiguration = new TeraviewConfig();
    project.projectAdjustmentConfig = new ProjectAdjustmentConfig();
    project.projectAdjustmentConfig.initializeAllDefaultFooterMessages(project.taxType, project.provinceCode);

    if (project.provinceCode == 'AB') {
      project.deliveryOfCashToClose = deliveryOfCashToCloseValues.depositedIntoYourAccount;
      project.interestRate = 'ATB Financial prime plus 3%';
    }
  }

  setupTaxRateOnProject(project: Project, considerationTaxes: ConsiderationTaxes[]) {
    let considerationTax = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
    if (considerationTax) {
      project.taxType = considerationTax.rateType;
    }
  }

  validateProject(project: Project, newProjectRecordNumber?: string, customErrorService?: ErrorService): Observable<boolean> {
    let projectRecordNumber = newProjectRecordNumber ? newProjectRecordNumber : project.projectRecordNumber;
    let errorService = customErrorService ? customErrorService : this.errorService;
    let observable: Observable<boolean> = new Observable<boolean>(observer => {

      if (project.anyErrorsExistOnProject(errorService)) {
        this.errorService.openErrorFooterNotification();
        observer.next(false);
      } else if (project.id && !newProjectRecordNumber) {
        observer.next(true);
      } else {
        // if it passes validation , check project record number duplication
        errorService.removeDpSaveError('project.projectDetails.uniqueProjectNo');
        this.getProjectByProjectRecordNumber(projectRecordNumber, true)
        .subscribe(
          (projects: any) => {
            if (projects.length > 0) {
              errorService.addDpSaveError(DPError.createDPError('project.projectDetails.uniqueProjectNo'));
              this.errorService.openErrorFooterNotification();
              observer.next(false);
            } else {
              observer.next(true);
            }
          }, (error: ApplicationError) => {
            this.handleApiErrors(error, customErrorService);
            observer.next(false);
          });
      }
    });
    return observable;
  }

  updateProjectAfterSave(updatedProject: Project, errorService: ErrorService, oldProject?: Project): Observable<Project> {
    this.updateAfterProjectSave(updatedProject);
    return this.initProject(updatedProject).map(isProjectInitialized => {
      if (isProjectInitialized) {
        updatedProject.dirty = false;
        this.statusBarService.currentHelpText = StatusBarMessages.project.UPDATE;
        this.preserveDataFromOldProject(updatedProject, oldProject);
        return updatedProject;
      }
    }, (error: any) => {
      this.handleApiErrors(error, errorService);
      return undefined;
    });
  }

  // for UI fields
  preserveDataFromOldProject(newProject: Project, oldProject: Project) {
    if (newProject && newProject.statementConfiguration && newProject.statementConfiguration.statementOfAccount &&
      oldProject && oldProject.statementConfiguration && oldProject.statementConfiguration.statementOfAccount) {
      if (newProject.statementConfiguration && newProject.statementConfiguration.statementOfAccount && newProject.statementConfiguration.statementOfAccount.soaTemplates) {
        newProject.statementConfiguration.statementOfAccount.soaSelectedTemplate = newProject.statementConfiguration.statementOfAccount.soaTemplates.find(tp => tp.matterType == 'SALE' && !!tp.defaultTemplate);
      }
      newProject.statementConfiguration.statementOfAccount.subjectToHstType = oldProject.statementConfiguration.statementOfAccount.subjectToHstType;
      // Reverted code as it is breaking saving project
      newProject.statementConfiguration.statementOfAccount.disbursementsConfig.forEach(item => item.populateConfigAccountCodeArray(newProject.statementConfiguration.pcLawCodesCombinedData));
    }
  }

  saveProject(project: Project, loggedUserAccount?: Account, customErrorService?: ErrorService): Observable<Project> {
    try {

      // Clean up Needs to be last method be called before updating or saving
      let errorService = customErrorService ? customErrorService : this.errorService;

      if (project) {
        project.cleanUpProjectBeforeSaving();
      }

      if (project.id && project.id > 0) {
        return this.updateProject(project.id, project).flatMap(
          (updatedProject: Project) => {
            return this.updateProjectAfterSave(updatedProject, errorService, project).map((updatedAndInitProject: Project) => {
              return updatedAndInitProject;
            });
          });
      } else {

        project.id = undefined;

        return this.addProject(project).flatMap((updatedProject: Project) => {
          this.updateAfterProjectSave(updatedProject);

          return this.initProject(updatedProject).map(isProjectInitialized => {
            if (isProjectInitialized) {
              updatedProject.dirty = false;
              this.statusBarService.currentHelpText = StatusBarMessages.project.CREATE;
              return updatedProject;
            }
          }, (error: any) => {
            this.handleApiErrors(error, errorService);
            return undefined;
          });
        });
      }
    } catch (err) {
      if (project && !!project.massUpdateTransactionId) {
        this.rollBackProjectUpdateTransaction(project.massUpdateTransactionId).subscribe();
      }
      return undefined;
    }
  }

  updateAfterProjectSave(project: Project): void {
    this.saveProjectTabState(project);
  }

  public saveProjectTabState(project: Project) {
    let projectTab = this.tabsService.activeTab as ProjectTab;

    projectTab.backEndProject = new Project(project);
    this.syncUpdatedProjectModelWithProjectList(project);

  }

  public syncUpdatedProjectModelWithProjectList(project: Project) {
    if (this.tabsService.anchorTab && this.tabsService.anchorTab.isProject()) {
      let projectListTab: ProjectTab = this.tabsService.openTabs[ 0 ] as ProjectTab;
      if (projectListTab && projectListTab.projectListState) {
        projectListTab.projectListState.rows.forEach(function (m: Project, index, projectList) {
          if (m.id === project.id) {
            projectList[ index ] = project;
          }
        });
      }
    }
  }

  public handleApiErrors(error: any, customErrorService: ErrorService): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {

        customErrorService.addDpSaveError(DPError.createCustomDPError(fieldError.errorCode, this.getErrorMessageForSaveProject(fieldError), null, 'ERROR'));
      });
    } else if (error && error.errorCode == 'app.cannotSaveDataWithCreditCardNumbers') {
      // already handled
    } else {
      customErrorService.addDpSaveError(DPError.createCustomDPError(error.errorCode, this.getErrorMessageForSaveProject(error), null, 'ERROR'));
    }
    this.errorService.openErrorFooterNotification();
  }

  public getErrorMessageForSaveProject(fieldError: FieldError): string {
    if (!fieldError) {
      return '';
    }
    let errorMessage: string;
    const currentTime: string = moment().format('MMMM Do YYYY, h:mm:ss a');
    if (fieldError.errorCode == 'System.error') {
      errorMessage = 'Unexpected System Error encountered, please contact Customer Service.'
        + '<br><span class=\'grey-text\'>' + currentTime + ' ' + fieldError.errorCode + ': ' + fieldError.message + '</span>';
    } else {
      errorMessage = fieldError.errorCode + ': ' + fieldError.message;
    }
    return errorMessage;
  }

  createNewProject(provinceCode: ProvinceCode): Observable<Project> {
    let newProject: Project = new Project();
    newProject.id = 0;
    newProject.tempIdForNewProject = -(new Date()).getTime();
    newProject.provinceCode = provinceCode;
    newProject.projectRecordNumber = '';
    newProject.dirty = true;
    return Observable.of(newProject);
  }

  getProjectLockStatus(id: number): Observable<boolean> {
    return this.http.get(`${ projectApi.projects }/${ id }/lockstatus`)
    .map((res) => {
      return res;
    });
  }

  unlockProject(id: number): Observable<Project> {
    if (id < 0) {
      return Observable.of(null);
    }
    return this.http.get(`${ projectApi.projects }/${ id }/unlock`)
    .map((res) => {
      return new Project(res[ 'Project' ]);
    });
  }

  getCustomPickList(type: string): Observable<SpecialComment[]> {
    return this.http.get(`${ projectApi.customPickList }?type=${ type }`)
    .map((res) => {
      let specialComments = [];
      if (res[ 'CUSTOMPICKLISTS' ]) {
        res[ 'CUSTOMPICKLISTS' ].forEach((customUnit) => {
          specialComments.push(new SpecialComment(customUnit));
        });
      }
      return specialComments;
    });
  }

  generatePinListXmlFile(projectId: number, teraviewImportFileType: TeraviewImportFileType, importFileName: string, sourceInstrumentNumber: string, propertyBlock: string, startPropertyNumber: string, endPropertyNumber: string): Observable<String> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = projectApi.generatePinListXML
    .replace('{accountId}', accountId)
    .replace('{projectId}', projectId.toString())
    .replace('{teraviewImportFileType}', teraviewImportFileType);
    url += '?importFileName=' + importFileName;
    url += '&sourceInstrumentNumber=' + sourceInstrumentNumber;
    url += '&propertyBlock=' + propertyBlock;
    url += '&startPropertyNumber=' + startPropertyNumber;
    url += '&endPropertyNumber=' + endPropertyNumber;

    return this.http.get(url)
    .map((res) => {
      return res[ 'TeraviewXmlImportFile' ];
    });
  }

  getPinListXmlUrl(projectId: number, teraviewImportFileType: TeraviewImportFileType, fileName: string) {
    return `${ window.location.protocol }//${ window.location.host }` +
      projectApi.downloadGeneratedPinListXML
      .replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId))
      .replace('{projectId}', projectId.toString())
      .replace('{teraviewImportFileType}', teraviewImportFileType)
      + `?fileName=${ fileName }`;
  }

  callOperationAfterProjectSaved(project: Project, callback: Function): void {
    //If matter is not saved then user has to first save the matter
    if (project.dirty || !project.id) {
      this.saveProjectBeforeCalling(callback);
    } else {
      callback();
    }
  }

  private saveProjectBeforeCalling(callback: Function): void {
    this.dialogService.confirm('Confirmation', 'In order to proceed, the project must first be saved', false, 'Save').subscribe(res => {
      if (res && res == true) {
        let projectTab = this.tabsService.activeTab as ProjectTab;
        if (projectTab && projectTab.projectComponent) {
          let subscription: Subscription = projectTab.projectComponent.validateAndSaveProject().subscribe((result: boolean) => {
            if (result) {
              //Once project is saved then call operation after the authentication
              callback();
              subscription.unsubscribe();
            }
          });
        }
      } else {
        console.log('cancel');
      }
    });
  }

  requestFieldCode(projectId: number, matterId?: number): Observable<number> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.firstAvailableFieldCode(accountId, projectId) }`;
    if (matterId) {
      url = `${ url }?matterId=${ matterId }`;
    }
    return this.http.get(url)
    .map((res: any) => {
      return res[ 'ProjectSoAdjFieldCode' ].fieldCode;
    });
  }

  validateFieldCode(fieldCode: number, projectId: number, projectSoAdjFieldCodeValidation: ProjectSoAdjFieldCodeValidation): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.validateFieldCode(accountId, projectId, fieldCode) }`;
    return this.http.post(url, projectSoAdjFieldCodeValidation)
    .map((res) => {
      return res[ 'SUCCESS' ];
    });
  }

  getProjectSoaFieldCodes(projectId: number): Observable<ProjectSoAdjFieldCode[]> {

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.getProjectFieldCodes(accountId, projectId) }`;
    return this.http.get(url)
    .map((res) => {
      return this.mapSoaFieldCodeRespose(res[ 'ProjectSoAdjFieldCodes' ]);
    });
  }

  getProjectMattersSoaFieldCodes(projectId: number): Observable<ProjectSoAdjFieldCode[]> {

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.getProjectMattersFieldCodes(accountId, projectId) }`;
    return this.http.get(url)
    .map((res) => {
      return this.mapSoaFieldCodeRespose(res[ 'ProjectSoAdjFieldCodes' ]);
    });
  }

  getMatterSoaFieldCodes(projectId: number, matterId: number): Observable<ProjectSoAdjFieldCode[]> {

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.getMatterFieldCodes(accountId, projectId, matterId) }`;
    return this.http.get(url)
    .map((res) => {
      return this.mapSoaFieldCodeRespose(res[ 'ProjectSoAdjFieldCodes' ]);
    });
  }

  saveProjectSoaFieldCodes(projectId: number, fieldCodes: ProjectSoAdjFieldCode[]): Observable<ProjectSoAdjFieldCode[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.saveProjectFieldCodes(accountId, projectId) }`;
    return this.http.post(url, fieldCodes)
    .map((res) => {
      return this.mapSoaFieldCodeRespose(res[ 'ProjectSoAdjFieldCodes' ]);
    });
  }

  saveMatterSoaFieldCodes(projectId: number, matterId: number, fieldCodes: ProjectSoAdjFieldCode[]): Observable<ProjectSoAdjFieldCode[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.saveMatterFieldCodes(accountId, projectId, matterId) }`;
    return this.http.post(url, fieldCodes)
    .map((res) => {
      return this.mapSoaFieldCodeRespose(res[ 'ProjectSoAdjFieldCodes' ]);
    });
  }

  mapSoaFieldCodeRespose(response: any): ProjectSoAdjFieldCode[] {
    let projectSoAdjFieldCodes = [];
    response.forEach((projectSoAdjFieldCode) => {
      projectSoAdjFieldCodes.push(new ProjectSoAdjFieldCode(projectSoAdjFieldCode));
    });
    return projectSoAdjFieldCodes;
  }

  saveMatterFieldCode(matter: Matter): void {
    if (matter.isProjectSale) {
      let soaFieldCodes: number[] = matter.getMatterSoAdjFieldCodes();
      let result = _.uniq(soaFieldCodes);
      let matterSoAdjFieldCodes: ProjectSoAdjFieldCode[] = [];
      result.forEach((soaFieldCode) => {
        let projectSoAdjFieldCode = new ProjectSoAdjFieldCode();
        projectSoAdjFieldCode.fieldCode = soaFieldCode;
        projectSoAdjFieldCode.unityProjectId = matter.unityProjectId;
        projectSoAdjFieldCode.matterId = matter.id;
        matterSoAdjFieldCodes.push(projectSoAdjFieldCode);
      });
      this.saveMatterSoaFieldCodes(matter.unityProjectId, matter.id, matterSoAdjFieldCodes).subscribe();
    }

  }

  getDuplicatePins(projectId: number): Observable<DuplicatePinMatterInfo[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.duplicatePins(accountId, projectId) }`;
    return this.http.get(url).map((res) => {
      let data: any[] = res[ 'DuplicatePinMatterInfoList' ];
      return data ? data.map(info => {
        return new DuplicatePinMatterInfo(info);
      }) : [];
    });
  }

  getDuplicateLots(projectId: number): Observable<DuplicateLotMatterInfo[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.duplicateLots(accountId, projectId) }`;
    return this.http.get(url).map((res) => {
      let data: any[] = res[ 'DuplicateDataMatterInfoList' ];
      return data ? data.map(info => {
        return new DuplicateLotMatterInfo(info);
      }) : [];
    });
  }

  getDuplicateAddresses(projectId: number): Observable<DuplicateAddressMatterInfo[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.duplicateAddresses(accountId, projectId) }`;
    return this.http.get(url).map((res) => {
      let data: any[] = res[ 'DuplicateStreetAddressInfoList' ];
      return data ? data.map(info => {
        return new DuplicateAddressMatterInfo(info);
      }) : [];
    });
  }

  getDuplicateUnits(projectId: number): Observable<DuplicateUnitMatterInfo[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.duplicateUnits(accountId, projectId) }`;
    return this.http.get(url).map((res) => {
      let data: any[] = res[ 'DuplicateUnitLevelInfoList' ];
      return data ? data.map(info => {
        return new DuplicateUnitMatterInfo(info);
      }) : [];
    });
  }

  deleteProjectStatementOfAdjustment(targetMatter: Matter, projectAdjustmentActionType: ProjectAdjustmentActionType) {
    //ToDo complete this under US DPPMP-18361
    switch (projectAdjustmentActionType) {
      case 'ALL_ADJUSTMENTS':
        targetMatter._interimAdjustments = [];
        targetMatter._finalAdjustments = [];
        //ToDo recreate SalePrice and Deposit if needed
        break;
      case 'ALL_ADJUSTMENTS_EXCEPT_DEPOSIT':
        _.remove(targetMatter.interimStatementAdjustments, (adj: StatementAdjustment) => adj.isDepositAdjustment());
        _.remove(targetMatter.finalStatementAdjustments, (adj: StatementAdjustment) => adj.isDepositAdjustment());
        break;
      case 'ALL_ADJUSTMENTS_EXCEPT_SALE_PRICE_AND_DEPOSIT':
        _.remove(targetMatter.interimStatementAdjustments, (adj: StatementAdjustment) => !adj.isSalePrice() && !adj.isDepositAdjustment());
        _.remove(targetMatter.finalStatementAdjustments, (adj: StatementAdjustment) => !adj.isSalePrice() && !adj.isDepositAdjustment());
        // refresh Sale Price as some adj add considerations e.g. TARION
        break;
      default:
        break;
    }
  }

  async copyProjectStatementOfAdjustment(targetMatter: Matter, sourceProject: Project, projectAdjustmentActionType: ProjectAdjustmentActionType): Promise<void> {
    this.deleteProjectStatementOfAdjustment(targetMatter, projectAdjustmentActionType);

    let sourceMatter: Matter = await this.projectMatterCacheService.getMatter(sourceProject.templateMatterId).toPromise();

    targetMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
    sourceMatter.interimStatementAdjustments.forEach((adj: StatementAdjustment) => {
      targetMatter.interimStatementAdjustments.push(this.statementAdjustmentService.addMassUpdateAdjustment(adj, adj.fieldCode, targetMatter));
    });

    targetMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
    sourceMatter.finalStatementAdjustments.forEach((adj: StatementAdjustment) => {
      targetMatter.finalStatementAdjustments.push(this.statementAdjustmentService.addMassUpdateAdjustment(adj, adj.fieldCode, targetMatter));
    });
  }

  exportDepositListCSV(project: Project, holdbackType: string): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.holdbackReport(accountId, project.id, holdbackType, project.provinceCode) }&${ SESSION_ID_REQ_PARAM }=` + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId);
    window.open(this.http.encodePipe(this.http.normalizeUrl(url)), '_blank');
  }

  getMattersHoldbackSummary(projectId: number): Observable<HoldbackSummary[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.holdbackSummary(accountId, projectId) }`;
    return this.http.get(url)
    .map((res) => {
      let holdbackSummaryArray: HoldbackSummary[] = [];
      if (res[ 'NotReleasedHoldbackSummary' ] && res[ 'NotReleasedHoldbackSummary' ].length) {
        res[ 'NotReleasedHoldbackSummary' ].forEach((summary) => {
          holdbackSummaryArray.push(new HoldbackSummary(summary));
        });
      }
      return holdbackSummaryArray;
    });

  }

  beginProjectUpdateTransaction(projectId: number, massUpdateTransactionType: string, selectedMatterCount?: number): Observable<number> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.projectBeginTransaction(accountId, projectId) }`;
    if (!projectId) {
      return Observable.of(undefined);
    } else {
      return this.http.post(url, {
          massUpdateTransactionType: massUpdateTransactionType,
          selectedMatterCount: selectedMatterCount
        }
      ).map((res) => {
        return res[ 'MassUpdateTransaction' ];
      });
    }
  }

  rollBackProjectUpdateTransaction(transactionId: number): Observable<boolean> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.projectRollBackTransaction(accountId, transactionId) }`;
    return this.http.post(url, '')
    .map((res) => {
      return true;
    });
  }

  commitProjectUpdateTransaction(transactionId: number): Observable<boolean> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.projectCommitTransaction(accountId, transactionId) }`;
    return this.http.post(url, '')
    .map((res) => {
      return true;
    });
  }

  getProjectMatters(projectId: number): Observable<Matter[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.getAllProjectMatters(accountId, projectId) }`;
    return this.http.get(url).map((res) => {
      return res[ matterResponseKey.matters ].map((item) => {
        return new Matter(item);
      });
    });
  }
}
