import {Component, Inject, ViewChild} from '@angular/core';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SoAdjAmountPaidOnInterimClosing} from './so-adj-amount-paid-on-interim-closing';
import {SelectItem} from 'primeng/api';
import {CurrencyPipe} from '@angular/common';
import {StatementAdjustment} from '../../statement-adjustment';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {ProvinceCode} from '../../../../admin/accounts/shared/base-province';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {Utils} from '../../../shared/utils';
import {ModalResult} from '../../../../shared-main/enums';
import {StatementAdjustmentUtil} from '../../statement-adjustment-util';
import {Matter} from '../../../shared';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class AmountPaidOnInterimClosingModalContext {
  public closingDate: string;
  public amountPaidOnInterimClosing: SoAdjAmountPaidOnInterimClosing;
  public statementAdjustments: StatementAdjustment[];
  public salePriceAdjustment: SalePriceAdjustment;
  public statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  public adjustmentStatusMode: string;
  public provinceCode: ProvinceCode;
  public matter: Matter;
}

@Component({
  selector: 'dp-amount-paid-on-interim-closing-modal-content',
  templateUrl: 'amount-paid-on-interim-closing.modal.component.html',
  providers: [ErrorService, DialogService, CurrencyPipe]
})
export class AmountPaidOnInterimClosingModalComponent extends ModalComponent<AmountPaidOnInterimClosingModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  amountPaidOnInterimClosing: SoAdjAmountPaidOnInterimClosing;
  closingDateLabel: string = 'Closing Date';
  isClosingDateValid: boolean;
  creditPurchaserWithOptions: SelectItem[] = [];

  constructor(
    public dialog: MatDialogRef<AmountPaidOnInterimClosingModalComponent>,
    public dialogService: DialogService,
    public currencyPipe: CurrencyPipe,
    @Inject(MAT_DIALOG_DATA) context?: AmountPaidOnInterimClosingModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.amountPaidOnInterimClosing) {
      this.amountPaidOnInterimClosing = new SoAdjAmountPaidOnInterimClosing(this.context.amountPaidOnInterimClosing);
    } else {
      this.amountPaidOnInterimClosing = new SoAdjAmountPaidOnInterimClosing();
      this.amountPaidOnInterimClosing.adjustmentHeading = 'Amount Paid on Interim Closing';
      this.amountPaidOnInterimClosing.creditPurchaserWith = 'UNADJUSTED_BALANCE';
    }
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate, '???????');
      this.isClosingDateValid = !this.closingDateLabel.includes('?');
    }
    this.createCreditPurchaserWithDropDownOptions();
  }

  createCreditPurchaserWithDropDownOptions(): void {
    this.creditPurchaserWithOptions.push({
      label: `Unadjusted Balance : ${this.geFormattedCurrencyValue(this.unadjustedBalance)}`,
      value: 'UNADJUSTED_BALANCE'
    });
    this.creditPurchaserWithOptions.push({
      label: `Unadjusted Balance (including credits to P & V) : ${this.geFormattedCurrencyValue(this.unadjustedBalanceIncludingCredits)}`,
      value: 'UNADJUSTED_BALANCE_INCLUDING_CREDITS'
    });
    this.creditPurchaserWithOptions.push({
      label: `BDOC in Interim Statement of Adjustments : ${this.geFormattedCurrencyValue(this.amountBDOCInInterimSOA)}`,
      value: 'BDOC'
    });
  }

  get unadjustedBalanceIncludingCredits(): number {
    return StatementAdjustmentUtil.unadjustedBalanceIncludingCredits(this.context.salePriceAdjustment);
  }

  get amountBDOCInInterimSOA(): number {
    return StatementAdjustmentUtil.balanceDueOnClosingAmountInInterimSOA(this.context.matter);
  }

  get unadjustedBalance(): number {
    return StatementAdjustmentUtil.unadjustedBalance(this.context.salePriceAdjustment, this.depositAdjustment);
  }

  get depositAdjustment(): StatementAdjustment {
    return this.context.statementAdjustments.find((adj) => adj.isDepositAdjustment() && adj.isAdjustmentStatusFinal());
  }

  close(): void {
    this.dialog.close();
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.amountPaidOnInterimClosing);
    }
  }

  checkForErrors() {
    if (
      !this.amountPaidOnInterimClosing.adjustmentHeading ||
      this.amountPaidOnInterimClosing.adjustmentHeading.length == 0
    ) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.amount.paid.on.interim.closing.heading');
    }
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  getCreditPurchaserAmount(): number {
    return StatementAdjustmentUtil.amountPaidOnInterimClosing(this.amountPaidOnInterimClosing, this.context.matter);
  }

  geFormattedCurrencyValue(currencyValue: number): string {
    if (
      currencyValue != undefined &&
      !isNaN(currencyValue) &&
      currencyValue != null &&
      currencyValue.toString().trim() != ''
    ) {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }
}
