import {Injectable} from '@angular/core';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {SESSION_STORAGE_KEYS} from './session-storage-keys';
import {DocumentProfile} from '../admin/document-profile/document-profile';
import {Matter} from '../matters/shared/matter';
import {StatementConfigService} from '../admin/shared/statement-config.service';
import {StatementConfig} from '../admin/shared/statement-config';
import {SoaTrustLedgerCollection} from '../matters/shared/soa-trustledger-collection';
import {currentMatter} from '../matters/shared/current-matter';
import {TaxRateService} from '../matters/consideration-ltt/tax-rate.service';
import {SoaFeeRate} from '../matters/consideration-ltt/soa-fee-rate';
import {Observable} from 'rxjs';
import {SoaExportConfig} from '../matters/shared/soa-export-config';

import {matterApi, MatterTypesValue} from '../matters/shared';
import {HttpClient} from '../core';
import {api} from '../common/api';
import {JurisdictionDepartment} from '../admin/jurisdiction-departments/jurisdiction-departments';
import {JurisdictionDepartmentsService} from '../admin/jurisdiction-departments';
import {ProgressionStatus} from '../matters/statement-adjustment/statement-adjustment';
import {SoaTemplate} from '../admin/soa/soa-template';
import {TitleInsuranceConfiguration} from '../integrations/title-insurance-configuration';
import {TitleInsuranceConfigurationService} from '../integrations/title-insurance-configuration.service';
import {ConsiderationTaxes} from '../matters/consideration-ltt/consideration-taxes';
import {map} from 'rxjs/operators';

@Injectable()
export class SoaTrustLedgerHelperService {

  constructor(private documentProfileService: DocumentProfileService,
              private soaConfigService: StatementConfigService,
              private taxRateService: TaxRateService,
              private http: HttpClient,
              private jurisdictionDepartmentsService: JurisdictionDepartmentsService, public titleInsuranceConfigurationService: TitleInsuranceConfigurationService) {

  }

  async updateMatterSoaTrustLedgerConfig(statementConfigs: StatementConfig[], documentProfiles: DocumentProfile, taxRates: SoaFeeRate[],
                                         matterReference?: Matter, finalSoaExportConfig?: SoaExportConfig, accDepartments?: JurisdictionDepartment[],
                                         interimSoaExportConfig ?: SoaExportConfig, updateRecordingsForNS?: boolean): Promise<void> {
    let matter = matterReference ? matterReference : currentMatter.value;
    let statementConfig: StatementConfig = this.assignStatementConfig(matter, statementConfigs);
    if (statementConfig && statementConfig.statementOfAccount) {
      let defaultTemplate: SoaTemplate;
      if (!matter.primarySoaTemplateId) {
        defaultTemplate = statementConfig.statementOfAccount.soaTemplates.find(item => !!item.defaultTemplate &&
          item.applicableMatterTypeCode == (matter.isCustomMatter() ? matter.customMatterTypeName : !!matter.isMatterTypeDischarge ? MatterTypesValue.DISCHARGE : matter.matterType));
      } else {
        let primarySoaTemplate: SoaTemplate = statementConfig.statementOfAccount.soaTemplates.find(soaTemplate => soaTemplate.id == matter.primarySoaTemplateId);
        if (matter && matter.soaTrustLedgerCollection) {
          if (primarySoaTemplate) {
            matter.soaTrustLedgerCollection.soaStatementConfiguration = statementConfig;
          } else {
            matter.soaTrustLedgerCollection.soaStatementConfiguration = await this.fetchStatementConfigBySoaTemplateId(matter.primarySoaTemplateId);
          }
          defaultTemplate = matter.soaTrustLedgerCollection.soaStatementConfiguration.statementOfAccount.soaTemplates.find(item => item.id == matter.primarySoaTemplateId);
        }
      }

      if (defaultTemplate) {
        statementConfig.statementOfAccount.soaSelectedTemplate = defaultTemplate;
        matter.primarySoaTemplateId = defaultTemplate.id;
      } else {
        let soaTemplate: SoaTemplate = new SoaTemplate();
        soaTemplate.applicableMatterTypeCode = matter.isCustomMatter() ? matter.customMatterTypeName : !!matter.isMatterTypeDischarge ? MatterTypesValue.DISCHARGE : matter.matterType;
        statementConfig.statementOfAccount.soaSelectedTemplate = soaTemplate;
      }

      matter.statementConfigurationId = statementConfig.id;
      // this.statementConfig = new StatementConfig(statementConfig);
      //statementConfig.statementOfAccount.filterFees(matter.matterType);
      if (!matter.soaTrustLedgerCollection) {
        matter.soaTrustLedgerCollection = this.createNewSoatrustLedgerCollection(matter, statementConfig, documentProfiles, taxRates, accDepartments);
        matter.soaTrustLedgerCollection.isResetSoaFeeCalculatedOnInclusivePriceComplete(!matter.isMatterExisting());
      } else if (matter.isProjectSaleMatterNoInitWithSoaTL()) {
        matter.soaTrustLedgerCollection = this.createNewSoatrustLedgerCollection(matter, statementConfig, documentProfiles, taxRates, accDepartments);
        matter.soaTrustLedgerCollection.isResetSoaFeeCalculatedOnInclusivePriceComplete(!matter.isMatterExisting());
        this.updateExistingSoaTrustLedgerCollection(matter, statementConfig, documentProfiles, matter.soaTrustLedgerCollection);
        if (matter && matter.isProjectSale && matter.project && (matter.isProjectConfigDocForSoa() || matter.isProjectConfigDocForTrustLedger())) {
          matter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;
          this.updateExistingSoaTrustLedgerCollection(matter, statementConfig, documentProfiles, matter.soaTrustLedgerCollection);
          matter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
        }
      } else {
        //the matter in soaTrustLedgerCollection may lost during stringify operation from changing tab
        //Error stack TypeError: Cannot read property 'matterType' of null
        //at SoaTrustLedgerCollection.populateAccountCodeArrayInDisbursements
        this.updateSoaTrustLdegeCollection(matter, statementConfig, documentProfiles, taxRates, accDepartments);
        matter.secondarySoaSheets.forEach(sheet => {
          let soaTrustLedgerCollectionSheet = matter.secondarySoaSheetsCollection.find(collection => collection.sheetId == sheet.id);
          if (soaTrustLedgerCollectionSheet) {
            let statementConfigObj = statementConfigs.find(sc => sc.id == sheet.statementConfigurationId);
            let template = statementConfigObj.statementOfAccount.soaTemplates.find(item => item.id == sheet.associatedTemplateId);
            let statementConfigSheet = new StatementConfig(statementConfigObj);
            statementConfigSheet.statementOfAccount.soaSelectedTemplate = template;
            soaTrustLedgerCollectionSheet.matter = matter;
            soaTrustLedgerCollectionSheet.statementConfig = statementConfigSheet;
            soaTrustLedgerCollectionSheet.soaStatementConfiguration = statementConfigSheet;
            if (!soaTrustLedgerCollectionSheet.accountDepartments && accDepartments) {
              soaTrustLedgerCollectionSheet.accountDepartments = accDepartments;
            }
            soaTrustLedgerCollectionSheet.progressionStatus = ProgressionStatus.FINAL;
            // DPPMP-39119 found matter.soaTrustLedgerCollection.soaFeesRates was undefined.
            // then updateF9MortgageRegistrationFeeTrustLedgerMB failed to set the correct value
            // updateSoaTaxRate method will update matter.soaTrustLedgerCollection.soaFeesRates, it should put before updateExistingSoaTrustLedgerCollection
            this.updateSoaTaxRate(matter, taxRates, soaTrustLedgerCollectionSheet);
            this.updateExistingSoaTrustLedgerCollection(matter, statementConfigSheet, documentProfiles, soaTrustLedgerCollectionSheet);
          }
        });
        console.log('SoaTrustLedgerHelperService : updateMatterSoaTrustLedgerConfig : Updated :completed');
      }
      this.setUpdateSupplemetalTaskExpenditureInTrustLedger(matter.soaTrustLedgerCollection.statementConfig, matter);
    }
    if (matter.soaTrustLedgerCollection) {
      if (finalSoaExportConfig) {
        matter.soaTrustLedgerCollection._finalSoaExportConfig = finalSoaExportConfig;
      }
      if (interimSoaExportConfig) {
        matter.soaTrustLedgerCollection._interimSoaExportConfig = interimSoaExportConfig;
      }
    }
    ;

    if (updateRecordingsForNS) {
      matter.calculateRecordings();
    }
    console.log('SoaTrustLedgerHelperService : updateMatterSoaTrustLedgerConfig : Updated : Exit');
  }

  async fetchStatementConfigBySoaTemplateId(primarySoaTemplateId: number): Promise<StatementConfig> {
    return await this.soaConfigService.getStatementConfigBySoaTemplateId(primarySoaTemplateId).toPromise();
  }

  updateSoaTrustLdegeCollection(matter: Matter, statementConfig: StatementConfig, documentProfiles: DocumentProfile, taxRates: SoaFeeRate[], accDepartments?: JurisdictionDepartment[]): void {
    matter.soaTrustLedgerCollection.matter = matter;
    matter.soaTrustLedgerCollection.statementConfig = statementConfig;
    if (!matter.soaTrustLedgerCollection.accountDepartments && accDepartments) {
      matter.soaTrustLedgerCollection.accountDepartments = accDepartments;
    }
    matter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
    // DPPMP-39119 found matter.soaTrustLedgerCollection.soaFeesRates was undefined.
    // then updateF9MortgageRegistrationFeeTrustLedgerMB failed to set the correct value
    // updateSoaTaxRate method will update matter.soaTrustLedgerCollection.soaFeesRates, it should put before updateExistingSoaTrustLedgerCollection
    this.updateSoaTaxRate(matter, taxRates, matter.soaTrustLedgerCollection);

    this.updateExistingSoaTrustLedgerCollection(matter, statementConfig, documentProfiles, matter.soaTrustLedgerCollection);
    if (matter && matter.isProjectSale && matter.project && (matter.isProjectConfigDocForSoa() || matter.isProjectConfigDocForTrustLedger())) {
      matter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;
      this.updateExistingSoaTrustLedgerCollection(matter, statementConfig, documentProfiles, matter.soaTrustLedgerCollection);
      matter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;

    }
  }

  createNewSoatrustLedgerCollection(matter: Matter, statementConfig: StatementConfig, documentProfiles: DocumentProfile, taxRates: SoaFeeRate[], accDepartments?: JurisdictionDepartment[], sheetId?: number): SoaTrustLedgerCollection {
    let soaTrustLedgerCollection = new SoaTrustLedgerCollection(matter);
    if (matter.isProjectSaleMatterNoInitWithSoaTL()) {
      soaTrustLedgerCollection = new SoaTrustLedgerCollection(matter, matter.matterSoas, matter.matterTrustLedgers);
    }

    soaTrustLedgerCollection.statementConfig = new StatementConfig(statementConfig);
    soaTrustLedgerCollection.soaStatementConfiguration = new StatementConfig(statementConfig);
    soaTrustLedgerCollection.accountDepartments = accDepartments;
    soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
    if (sheetId) {
      soaTrustLedgerCollection.sheetId = sheetId;
    }
    this.updateSoaTaxRate(matter, taxRates, soaTrustLedgerCollection);
    if (matter.isProjectSaleMatterNoInitWithSoaTL()) {
      if (!matter.matterSoas || matter.matterSoas.filter(item => item.progressionStatus == ProgressionStatus.FINAL).length == 0) {
        soaTrustLedgerCollection.createNewSoaStructure();
      }
      if (!matter.matterTrustLedgers || matter.matterTrustLedgers.filter(item => item.progressionStatus == ProgressionStatus.FINAL).length == 0) {
        soaTrustLedgerCollection.createNewTrustLedgerStructure();
      }
    } else {
      soaTrustLedgerCollection.createNewSoaStructure();
      soaTrustLedgerCollection.createNewTrustLedgerStructure();
    }

    this.updateNewSoaTrustLedgerCollection(matter, statementConfig, documentProfiles, soaTrustLedgerCollection);
    if (matter && matter.isProjectSale && matter.project && (matter.isProjectConfigDocForSoa() || matter.isProjectConfigDocForTrustLedger())) {
      soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;

      if (matter.isProjectSaleMatterNoInitWithSoaTL()) {
        if (matter.isProjectConfigDocForSoa() && (!matter.matterSoas || matter.matterSoas.filter(item => item.progressionStatus == ProgressionStatus.INTERIM).length == 0)) {
          soaTrustLedgerCollection.createNewSoaStructure();
        }
        if (matter.isProjectConfigDocForTrustLedger() && (!matter.matterTrustLedgers || matter.matterTrustLedgers.filter(item => item.progressionStatus == ProgressionStatus.INTERIM).length == 0)) {
          soaTrustLedgerCollection.createNewTrustLedgerStructure();
        }
      } else {
        if (matter.isProjectConfigDocForSoa()) {
          soaTrustLedgerCollection.createNewSoaStructure();
        }
        if (matter.isProjectConfigDocForTrustLedger()) {
          soaTrustLedgerCollection.createNewTrustLedgerStructure();
        }
      }

      this.updateNewSoaTrustLedgerCollection(matter, statementConfig, documentProfiles, soaTrustLedgerCollection);
      //Set the mode back to final
      soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
    }

    soaTrustLedgerCollection.combinedAccountCodeDataArr = statementConfig.pcLawCodesCombinedData;
    console.log('SoaTrustLedgerHelperService : updateMatterSoaTrustLedgerConfig : New :completed');
    //DPPMP-39489 initial updateSupplemetalTaskExpenditureInTrustLedger and add SupplementalTaskExpenditures for Trust Ledger
    this.setUpdateSupplemetalTaskExpenditureInTrustLedger(soaTrustLedgerCollection.statementConfig, matter);
    soaTrustLedgerCollection.updateSupplementalTaskExpenditures();
    return soaTrustLedgerCollection;
  }

  assignStatementConfig(matter: Matter, statementConfigs: StatementConfig[]): StatementConfig {
    let statementConfig: StatementConfig;
    if (matter.isProjectSale && statementConfigs && statementConfigs.length > 1) {
      let matterStatementConfig = statementConfigs.find(sc => sc.id != matter.projectStatementConfigurationId);
      let projectStatementConfig = statementConfigs.find(sc => sc.id == matter.projectStatementConfigurationId);
      // if(matterStatementConfig){
      //     statementConfig = matterStatementConfig;
      //     if(projectStatementConfig && statementConfig.statementOfAccount && statementConfig.statementOfAccount.soaSelectedTemplate){
      //         statementConfig.statementOfAccount.soaSelectedTemplate.fees = projectStatementConfig.statementOfAccount.fees;
      //         statementConfig.statementOfAccount.soaSelectedTemplate.disbursementsConfig = projectStatementConfig.statementOfAccount.disbursementsConfig;
      //     }
      // } else {
      //     statementConfig = projectStatementConfig;
      // }
      statementConfig = projectStatementConfig ? projectStatementConfig : matterStatementConfig;
    } else if (matter.statementConfigurationId) {
      let matterStatementConfig = statementConfigs.find(sc => sc.id == matter.statementConfigurationId);
      statementConfig = matterStatementConfig ? matterStatementConfig : statementConfigs[ 0 ];
    } else {
      statementConfig = statementConfigs[ 0 ];
    }
    return statementConfig;
  }

  updateNewSoaTrustLedgerCollection(matter: Matter, statementConfig: StatementConfig, documentProfiles: DocumentProfile, soaTrustLedgerCollection: SoaTrustLedgerCollection): void {
    this.updateFeesLabelBasedOnDefaultProfile(true, (statementConfig.trustLedger && statementConfig.trustLedger.trustLedgerEntries), matter, documentProfiles, soaTrustLedgerCollection);
    soaTrustLedgerCollection.updateTrustLedgerLegalFeeName();

    soaTrustLedgerCollection.addLTT();
    soaTrustLedgerCollection.removeNRST();
    soaTrustLedgerCollection.addNRST();
    soaTrustLedgerCollection.updateF9DefaultAmount();
    soaTrustLedgerCollection.addOrRemoveEmpSoaAndTrustLedger();
    soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
    soaTrustLedgerCollection.addOrRemoveTitleRegistrationSoaAndTrustLedger();
    soaTrustLedgerCollection.addOrRemoveFeeForAllMortgages();
    soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
    soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
    soaTrustLedgerCollection.updateTrustLedgeMortgageLinesName();
    soaTrustLedgerCollection.updateERegAndRegisterCharges();
    soaTrustLedgerCollection.populateAccountCodeArrayInDisbursements();
    soaTrustLedgerCollection.setSoaAdditionalConfigAccountCode();
    soaTrustLedgerCollection.populateAccountCodeArrayInOtherCharges();

    if (statementConfig.trustLedger && statementConfig.trustLedger.allF9Values) {
      matter.autoInsertAllF9Values = true;
      soaTrustLedgerCollection.insertUpdateF9TrustLedgerValues();
      //matter.soaTrustLedgerCollection.updateAllF9ForTrustLedger();
    }
  }

  updateExistingSoaTrustLedgerCollection(matter: Matter, statementConfig: StatementConfig, documentProfiles: DocumentProfile, soaTrustLedgerCollection: SoaTrustLedgerCollection): void {
    if (matter.autoInsertAllF9Values) {
      soaTrustLedgerCollection.updateF9TrustLedger();
    }
    this.updateFeesLabelBasedOnDefaultProfile(false, false, matter, documentProfiles, soaTrustLedgerCollection);
    //soaTrustLedgerCollection.updateTrustLedgerLegalFeeName();
    if (soaTrustLedgerCollection.combinedAccountCodeDataArr && (soaTrustLedgerCollection.combinedAccountCodeDataArr.length == 0 || matter.isProjectSale)) {
      soaTrustLedgerCollection.combinedAccountCodeDataArr = statementConfig.pcLawCodesCombinedData;
      soaTrustLedgerCollection.populateAccountCodeArrayInDisbursements();
      soaTrustLedgerCollection.populateAccountCodeArrayInOtherCharges();
    }

    if (matter.isMatterProvinceMB) {
      soaTrustLedgerCollection.updateF9ManitobaLtt();
      soaTrustLedgerCollection.updateF9TitleRegistrationFeeTrustLedgerMB();
      soaTrustLedgerCollection.updateF9MortgageRegistrationFeeTrustLedgerMB();
    }

    if (matter.isMatterProvinceSK) {
      soaTrustLedgerCollection.updateF9SaskTitleRegTrustLedger();
      soaTrustLedgerCollection.updateF9SaskMortgageRegTrustLedger();
      soaTrustLedgerCollection.addOrUpdateTitleRegistrationToSOA(true);
      soaTrustLedgerCollection.addOrUpdateMortgageRegistrationToSOA(true);
      this.updateSoftwareTransactionChargeSK(matter);
    }
  }

  //for SK mortgage matter, the readonly field for 'Software Transaction Charge' item needs to be corrected to false
  private updateSoftwareTransactionChargeSK(matter: Matter) {
    if (matter.isMatterProvinceSK && matter.isMortgage && Array.isArray(matter.matterSoas)) {
      matter.matterSoas.filter(soa => soa.isSoftwareTransactionCharge()).forEach(soa => soa.readOnly = false);
    }
  }

  fetchSoaConfigData(matter: Matter): Observable<any> {
    let backEndCalls: any [] = [];
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    backEndCalls.push(this.soaConfigService
    .getStatementConfig(id, matter.statementConfigurationId, matter.provinceCode));
    let closingDate: string = matter.getSoaTaxRateEffectiveDate();
    backEndCalls.push(this.documentProfileService.getDefaultProfileForAccountFromCache(id, matter.provinceCode));
    backEndCalls.push(this.taxRateService.getSoaFeesRates(closingDate, matter.provinceCode));
    backEndCalls.push(this.jurisdictionDepartmentsService.getDepartmentPairs(null, matter.provinceCode));
    if (matter.id > 0) {
      backEndCalls.push(this.getSoaExportConfig(matter.id));
    }

    return Observable.forkJoin(backEndCalls).pipe(map((responseData: any[]) => {
      // TODO : Better we loop through response data and check isntance rather than relying on order
      let statementConfig: StatementConfig = responseData && responseData.length > 0 && responseData[ 0 ] ? new StatementConfig(responseData[ 0 ]) : undefined;
      let documentProfile: DocumentProfile = responseData && responseData.length > 1 && responseData[ 1 ] ? new DocumentProfile(responseData[ 1 ]) : undefined;
      let soaFeeRates: SoaFeeRate [] = responseData && responseData.length > 2 && responseData[ 2 ] ? responseData[ 2 ].map(
        item => new SoaFeeRate(item)) : [];
      let accDepartments: JurisdictionDepartment[] = responseData && responseData.length > 3 && responseData[ 3 ] ? responseData[ 3 ].map(item => new JurisdictionDepartment(item)) : [];
      let soaExportConfig: SoaExportConfig = responseData && responseData.length > 4 && responseData[ 4 ] ? new SoaExportConfig(responseData[ 4 ]) : undefined;
      return ({
        statementConfig: statementConfig,
        documentProfile: documentProfile,
        soaFeeRates: soaFeeRates,
        soaExportConfig: soaExportConfig,
        accDepartments: accDepartments
      });
    }));

  }

  setMatterSOAConfig(matter: Matter): Observable<any> {
    this.fetchSoaConfigData(matter).subscribe(obs => {
      this.updateMatterSoaTrustLedgerConfig([ obs.statementConfig ], obs.documentProfile, obs.soaFeeRates, matter, obs.soaExportConfig, obs.accDepartments);
    });
    return Observable.of(true);
  }

  async updateMatterSOAConfig(matter: Matter, primarySoaTemplateId ?: number): Promise<void> {
    let obs: any = await this.fetchSoaConfigData(matter).toPromise();
    if (obs) {
      let considerationTaxes: ConsiderationTaxes[] = await this.taxRateService.cachedConsiderationTaxRate(matter.provinceCode).toPromise();
      if (considerationTaxes) {
        matter.matterHst = undefined;
        this.taxRateService.setUpTaxRateOnMatter(matter, considerationTaxes);
      }

      let statementConfig: StatementConfig = obs.statementConfig;
      matter.statementConfigurationId = statementConfig.id;
      let defaultTemplate: SoaTemplate;
      if (primarySoaTemplateId) {
        defaultTemplate = statementConfig.statementOfAccount.soaTemplates.find(item => item.id == primarySoaTemplateId);
      } else {
        defaultTemplate = statementConfig.statementOfAccount.soaTemplates.find(item => !!item.defaultTemplate &&
          item.applicableMatterTypeCode == (matter.isCustomMatter() || matter.isOpportunityMatter() ? matter.customMatterTypeName : !!matter.isMatterTypeDischarge ? MatterTypesValue.DISCHARGE : matter.matterType));
      }
      if (defaultTemplate) {
        statementConfig.statementOfAccount.soaSelectedTemplate = defaultTemplate;
        matter.primarySoaTemplateId = defaultTemplate.id;
      } else {
        statementConfig.statementOfAccount.soaSelectedTemplate = null;
        matter.primarySoaTemplateId = null;
        matter.feeCalculatedOnInclusivePriceFlag = false;
        if (matter.soaTrustLedgerCollection) {
          matter.soaTrustLedgerCollection.soaFeesCalculatedOnAllInclusivePriceValue = 0;
        }

      }
      this.updateSoaTaxRate(matter, obs.soaFeeRates, matter.soaTrustLedgerCollection);
      matter.soaTrustLedgerCollection.accountDepartments = obs.accDepartments;
      await this.updateSoaConfigurations(statementConfig, matter.soaTrustLedgerCollection, matter, null);

    }
  }

  async updateSoaConfigurations(statementConfig: StatementConfig, soaTrustLedgerCollection: SoaTrustLedgerCollection, matter: Matter, provinceHstRateSlab: ConsiderationTaxes): Promise<void> {
    if (statementConfig && soaTrustLedgerCollection) {
      soaTrustLedgerCollection.statementConfig = statementConfig;
      soaTrustLedgerCollection.soaStatementConfiguration = statementConfig;

      //this.matter.autoInsertAllF9Values = this.statementConfig.trustLedger.allF9Values;
      soaTrustLedgerCollection.receivedOnAccount = 0;
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId).toString();
      matter.deductEcFeeFromSoa = false;
      let isDeductFeeConfigChange: boolean = false;
      if (matter && matter.matterTitleInsurance &&
        (matter.matterTitleInsurance.policyTotal || matter.matterTitleInsurance.fctPremium || matter.matterTitleInsurance.grandTotal)) {
        this.titleInsuranceConfigurationService.getTitleInsuranceConfiguration(accountId)
        .finally(() => soaTrustLedgerCollection.resetSoaStructure(provinceHstRateSlab))
        .subscribe((res: TitleInsuranceConfiguration) => {
          if (res.id) {
            isDeductFeeConfigChange = matter.isDeductFeeConfigChange(res);
            //resetTitleInsurance = resetTitleInsurance || isDeductFeeConfigChange;
            matter.updateDeductFeeConfigChange(res);
          }
        });

      } else {
        soaTrustLedgerCollection.resetSoaStructure(provinceHstRateSlab);
      }
      //updating the account codes array in reset
      soaTrustLedgerCollection.populateAccountCodeArrayInDisbursements();
      soaTrustLedgerCollection.populateAccountCodeArrayInOtherCharges();
      if (!soaTrustLedgerCollection.isSoaTitleInsuranceFeeExist() && statementConfig && statementConfig.isTitleInsuranceForSoa()) {
        soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
      }
      if (soaTrustLedgerCollection.isResetSoaFeeCalculatedOnInclusivePriceComplete()) {
        soaTrustLedgerCollection.resetFee();
        soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
      }
    }

  }

  setUpdateSupplemetalTaskExpenditureInTrustLedger(statementConfig: StatementConfig, matter: Matter) {

    if (matter.updateSupplemetalTaskExpenditureInTrustLedger == null && statementConfig && statementConfig.trustLedger) {
      matter.updateSupplemetalTaskExpenditureInTrustLedger = matter.isProjectSale ? false : statementConfig.trustLedger.trustLedgerExpenditures;
    }

  }

  updateSoaTaxRate(matter: Matter, data: SoaFeeRate[], soaTrustLedgerCollection: SoaTrustLedgerCollection): void {
    // TODO : BACK END IN CASE DATA NOT AVAILABLE IS SENDING NULL OBJECT ARRAY BACK
    if (data && data.length > 0 && data[ 0 ] && Object.keys(data[ 0 ]).length > 0 && soaTrustLedgerCollection) {
      soaTrustLedgerCollection.soaFeesRates = data.map(function (item) {
        return new SoaFeeRate(item);
      });
      //soaTrustLedgerCollection.updateERegAndRegisterCharges()
    }
    /*else{
     // TODO: NEED TO THIS DEFAULT TAX RATE AT BACK END...
     this.taxRateSubscription = this.taxRateService.getSoaFeesRates(Constants.DEFAULT_TAX_RATE_DATE).subscribe((data : SoaFeeRate[]) => {
     if(data && data.length > 0 && data[0] && Object.keys(data[0]).length > 0  && currentMatter.value.soaTrustLedgerCollection) {
     currentMatter.value.soaTrustLedgerCollection.soaFeesRates = data.map(function(item) {
     return new SoaFeeRate(item);
     });
     currentMatter.value.soaTrustLedgerCollection.updateERegAndRegisterCharges(currentMatter.value.getRegisteredInstruments())
     }
     });
     }*/

  }

  private updateFeesLabelBasedOnDefaultProfile(updateFeeLabel: boolean, updateFirmName: boolean, matter: Matter, documentProfile: DocumentProfile, soaTrustLedgerCollection: SoaTrustLedgerCollection): void {
    if (documentProfile) {
      soaTrustLedgerCollection.documentProfile = documentProfile;
      if (documentProfile.firmDocumentProfile && updateFirmName) {
        soaTrustLedgerCollection.updateTrustLedgerLegalFeeName();
      }
      if (updateFeeLabel) {
        soaTrustLedgerCollection.updateFeeLabelBasedOnDefaultProfile();
      }
    }

  }

  // get Soa Export Config by matter id from backend
  getSoaExportConfig(matterId: number, selectedSoaMatterSheet?: string): Observable<any> {
    let url = matterApi.soaExport(matterId) + '?selectedSoaMatterSheet=' + (selectedSoaMatterSheet ? selectedSoaMatterSheet : 'PRIMARY');
    console.log(' getSoaExportConfig ' + url);
    return this.http.get(url)
    .map((res) => {
      const soaExportConfig: SoaExportConfig = new SoaExportConfig(res[ 'SoaExportConfig' ]);
      return soaExportConfig;
    });
  }

  createSoaExportConfig(matterIds: number[], selectedSoaMatterSheet: string): Observable<SoaExportConfig[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.post(`${ api }/accounts/${ accountId }/matters/soaExport?selectedSoaMatterSheet=${ selectedSoaMatterSheet }`, matterIds)
    .map((res) => {
      const data: any[] = res[ 'SoaExportConfig' ];
      let soaExportConfigList: SoaExportConfig[] = [];
      if (Array.isArray(data)) {
        data.forEach(val => {
          soaExportConfigList.push(new SoaExportConfig(val));
        });
        return soaExportConfigList;
      }

    });
  }

}
