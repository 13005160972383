import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalResult} from '../../../../shared-main/enums';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Matter} from '../../../shared';
import Utils from '../../../../shared-main/utils';
import {StatementAdjustmentUtil} from '../../statement-adjustment-util';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {StatementAdjustment} from '../../statement-adjustment';
import {SoaRealtyTaxAdjustmentUtil} from '../realty-tax/soa-realty-tax-adjustment-util';
import {TaxesPaidDuringOccupancyUtil} from './taxes-paid-during-occupancy-util';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class TaxesPaidDuringOccupancyModalContext {
  matter: Matter;
  existingTaxPortion: number;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-taxes-paid-during-occupancy-modal-content',
  templateUrl: 'taxes-paid-during-occupancy.modal.component.html',
  providers: [ErrorService, DialogService]
})
export class TaxesPaidDuringOccupancyModalComponent
  extends ModalComponent<TaxesPaidDuringOccupancyModalContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = '';
  localExistingTaxPortion: number;
  taxInterimOccupancyAdj: StatementAdjustment;
  interimOccupancyKey: string = StatementAdjustmentKey.INTERIM_OCCUPANCY_FEE;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TaxesPaidDuringOccupancyModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TaxesPaidDuringOccupancyModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.closingDateLabel = Utils.formatDateString(this.context.matter.getFinalClosingDate(), true, '');
    this.taxInterimOccupancyAdj = StatementAdjustmentUtil.findByKey(
      this.context.matter.interimStatementAdjustments,
      this.interimOccupancyKey
    );
    this.localExistingTaxPortion = this.getTaxPortionOfOccupancyFeesValue();
  }

  ok(): void {
    this.dialog.close(this.localExistingTaxPortion);
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  calculateVendorShare(): number {
    return TaxesPaidDuringOccupancyUtil.calculateShareForTaxPortion(this.localExistingTaxPortion, this.matter);
  }

  calculateDateDiffForTaxPortion(): string {
    return TaxesPaidDuringOccupancyUtil.calculateDateDiffForTaxPortion(this.matter);
  }

  //this is a year-based value
  getTaxPortionOfOccupancyFeesValue(): number {
    if (this.isInterimOccupancyFeeSoaTaxesTotalGtZero) {
      return this.interimOccupancyFeeTaxesTotal * 12;
    } else if (this.context.existingTaxPortion) {
      //this value should be updated if interimOccupancyFee.taxesTotal get updated with Formula: interimOccupancyFee.taxesTotal * 12
      //(when the interimOccupancyFeeSoa modal page closed)
      return this.context.existingTaxPortion;
    }
    return 0;
  }

  //check whether the taxesTotal field in soa Interim Occupancy Fee has value and GT 0
  get isInterimOccupancyFeeSoaTaxesTotalGtZero(): boolean {
    return (
      this.matter &&
      (this.matter.isMatterProvinceON || this.matter.isMatterProvinceAB) &&
      this.interimOccupancyFeeTaxesTotal &&
      this.interimOccupancyFeeTaxesTotal > 0
    );
  }

  //this is a month-based value
  get interimOccupancyFeeTaxesTotal(): number {
    return (
      this.taxInterimOccupancyAdj &&
      this.taxInterimOccupancyAdj.soAdjInterimOccupancyFee &&
      this.taxInterimOccupancyAdj.soAdjInterimOccupancyFee.taxesTotal
    );
  }

  get matter(): Matter {
    return this.context.matter;
  }

  getOccupancyDate(): string {
    return SoaRealtyTaxAdjustmentUtil.getOccupancyDateForRealtyTax(this.context.matter);
  }
}
