import {Component, Inject, OnInit} from '@angular/core';
import {Matter, MatterService, OpportunityMatterStatusValue} from '../../matters';
import {LockScreenService} from '../../core/lock-screen.service';
import {CopyOpportunityMatterService} from '../../matters/copy-opportunity-matter.service';
import {OpportunityCopyParam} from '../../matters/shared/opportunity-copy-param';
import {ModalResult} from '../../shared-main/enums';
import {StickyNotesModal} from '../../shared-main/sticky-notes/sticky-notes.modal.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class CopyToExistingMatterContext {
  existingMatter: Matter;
  opportunityMatter: Matter;
  matterService: MatterService;
  copyOpportunityMatterService: CopyOpportunityMatterService;
}

@Component({
  selector: 'dp-copy-to-existing-matter-modal-content',
  templateUrl: 'copy-to-existing-matter.modal.component.html',
  styleUrls: ['./copy-to-existing-matter.modal.component.scss']
})
export class CopyToExistingMatterComponent extends ModalComponent<CopyToExistingMatterContext> implements OnInit {
  copyParams: OpportunityCopyParam = new OpportunityCopyParam();

  constructor(
    public dialog: MatDialogRef<CopyToExistingMatterComponent>,
    public lockScreenService: LockScreenService,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: CopyToExistingMatterContext
  ) {
    super(dialog, context);
    this.copyParams.initializeFromOpportunity(this.context.opportunityMatter, this.context.existingMatter);
  }

  ok() {
    this.startImport();
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  ngOnInit() {}

  async startImport() {
    try {
      this.lockScreenService.lockForUpdate = true;
      await this.context.copyOpportunityMatterService.updateMatterFromOpportunity(
        this.context.opportunityMatter,
        this.context.existingMatter,
        this.copyParams
      );
      let isMatterValid = await this.context.matterService
        .validateMatter(this.context.existingMatter, null, this.errorService)
        .toPromise();
      if (isMatterValid) {
        //await this.context.matterService.lockMatter(this.context.existingMatter.id).toPromise();
        let savedMatter: Matter = await this.context.matterService.saveMatter(this.context.existingMatter).toPromise();
        if (savedMatter) {
          savedMatter = await this.context.matterService
            .updateMatterDocumentsFromOpportunity(this.context.existingMatter.id, this.context.opportunityMatter.id)
            .toPromise();
          if (savedMatter) {
            if (!this.context.opportunityMatter.opportunity.isClosedDuplicate()) {
              this.context.opportunityMatter.opportunity.opportunityStatus =
                OpportunityMatterStatusValue.closedConverted;
            }
            this.dialog.close({action: ModalResult.OK, matter: savedMatter});
          }
        }
      }
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  openStickyNotesModal(input: any): void {
    const opportunityTopicKey: string = input.opportunityTopicKey;
    const matterTopicKey: string = input.matterTopicKey;
    const opportunityNote = !!input.copy
      ? this.context.opportunityMatter.getMatterTopicByKey(opportunityTopicKey)
        ? '\n\n' + this.context.opportunityMatter.getMatterTopicByKey(opportunityTopicKey).topicNote
        : ''
      : '';
    const matterNote = this.context.existingMatter.getMatterTopicByKey(matterTopicKey)
      ? this.context.existingMatter.getMatterTopicByKey(matterTopicKey).topicNote
      : '';
    if (opportunityNote || matterNote) {
      this.dialogService.matDialogContent({
        content: StickyNotesModal,
        context: {
          notes: matterNote ? `${matterNote}${opportunityNote}` : opportunityNote,
          hideDeleteButton: true
        },
      });
    }
  }
}
