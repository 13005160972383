<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{context.modalTitle}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 padding-bottom-20">
            <label class="control-label bold">Select additional data to be copied</label>
        </div>
        <div class="form-group col-lg-12">
            <dp-checkbox fieldId="mortgageData">
                <input id="mortgageData"
                       name="mortgageData"
                       type="checkbox"
                       [(ngModel)]="mortgageData"
                />
            </dp-checkbox>
            <label class="control-label padding-left-10 display-inline" for="mortgageData" >Copy existing mortgage info?</label>
        </div>
        <div class="form-group col-lg-12" *dpShowByProvince="'copy.matter.target.fireInsurance';matterType:context.targetMatterType;code:context.sourceMatter.provinceCode">
            <dp-checkbox fieldId="fireInsuranceData">
                <input id="fireInsuranceData"
                       name="fireInsuranceData"
                       type="checkbox"
                       [(ngModel)]="fireInsuranceData"
                />
            </dp-checkbox>
            <label class="control-label padding-left-10 display-inline" for="fireInsuranceData" >Copy fire insurance info?</label>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn"
                    class="dp-btn"
                    (click)="createMatter()" >
                <span>Ok</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
