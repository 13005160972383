<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-10">
            <label [innerHTML]="context.title" class="modal-title"></label>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body">
        <iframe height="100%" width="100%" [src]="sanitizedThirdPartyUrl" name="iframe"></iframe>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
