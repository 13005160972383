import {
  MatterNotificationConfig,
  RecipientRole
} from '../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config';
import {Matter} from './shared/matter';
import {MatterNotification, NotificationType, NotificationTypeValue} from './shared/matter-notification';
import Utils from '../shared-main/utils';
import {Injectable} from '@angular/core';
import {MatterParticipant} from './shared/matter-participant';
import {AppConfig} from '../../app/shared-main/app-configuration';
import {Contact} from './shared';
import {NotificationOptOutService} from '../main/notification-opt-out.service';
import {MatterWorkItemTask} from './shared/matter-work-item';
import {ContactUtil} from './shared/contact-util';
import {ProvinceCode} from '../admin/accounts/shared/base-province';
import {provinceBasedLawClerkTitle, provinceBasedLawyerTitle} from '../shared-main/province-based-dropdowns';
import {PROVINCE_CODES} from './shared/user-province';
import {Account} from '../admin/accounts/shared/account';
import {AccountService} from '../admin/accounts/account.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';

@Injectable()
export class MatterNotificationUtilService {
  constructor(
    public appConfig: AppConfig,
    public notificationOptOutService: NotificationOptOutService,
    public accountService: AccountService
  ) {
  }

  /**
   * In new matter all potential notifications will be added in pending (or muted) status even if they are Inactive in account configuration
   * @param {Matter} matter
   * @param {MatterNotificationConfig[]} matterNotificationConfigs
   */
  initializeNotificationOnMatterCreation(matter: Matter, matterNotificationConfigs: MatterNotificationConfig[]) {
    // Initializing the array in case of this method is called more than once
    if (this.isNotificationApplicable(matter) && !matter.isOpportunityMatter()) {
      matterNotificationConfigs.forEach(notificationConfig => {
        this.createMatterNotificationConfig(notificationConfig, matter, NotificationTypeValue.basic);
      });
      this.calculateNotificationsStatuses(matter);
    }
  }

  private createMatterNotificationConfig(notificationConfig: MatterNotificationConfig, matter: Matter, notificationType: NotificationType, taskIdentifier?: number, matterWorkItemTask?: MatterWorkItemTask): void {
    let applicableMatterTypes: string = this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ notificationConfig.recipientRole ] ?
      this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ notificationConfig.recipientRole ][ 'APPLICABLE_MATTER_TYPES' ] :
      undefined;

    let isExternalNotification: boolean = this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ notificationConfig.recipientRole ] ?
      this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ notificationConfig.recipientRole ][ 'IS_EXTERNAL_NOTIFICATION' ] :
      undefined;

    if (!applicableMatterTypes ||
      (matter.isCustomMatter() && applicableMatterTypes.indexOf('C') >= 0) ||
      (!matter.isCustomMatter() && applicableMatterTypes.indexOf(matter.matterTypeCode) >= 0)) {
      let isCompleted = matterWorkItemTask && matterWorkItemTask.isStatusCompleted() && (notificationConfig.triggerEvent == 'STARTED' || notificationConfig.triggerEvent == 'OVERDUE' || notificationConfig.triggerEvent == 'ON_HOLD');
      let matterNotification: MatterNotification = new MatterNotification();
      matter.matterNotifications.push(matterNotification);
      matterNotification.configNotificationId = notificationConfig.id;
      matterNotification.precedentId = notificationConfig.notificationPrecedentId;
      matterNotification.recipientRole = notificationConfig.recipientRole;
      matterNotification.triggerEvent = notificationConfig.triggerEvent;
      matterNotification.notificationType = notificationType ? notificationType : NotificationTypeValue.basic;
      matterNotification.taskIdentifier = taskIdentifier;
      if (matterNotification.isTaskNotification()) {
        matterNotification.notificationStatus = notificationConfig.active && ((!!isExternalNotification && matter.sendExternalNotifications == 'YES') || (!isExternalNotification && matter.sendInternalNotifications == 'YES')) && !isCompleted ? 'PENDING' : 'MUTED';
      } else {
        matterNotification.notificationStatus = notificationConfig.active && ((!!isExternalNotification && matter.sendExternalNotifications == 'YES') || (!isExternalNotification && matter.sendInternalNotifications == 'YES')) ? 'PENDING' : 'MUTED';
      }
    }
  }

  isNotificationApplicable(matter: Matter): boolean {
    return !matter.isProjectSale && !matter.templateForProject && this.appConfig && this.appConfig.isMatterNotificationsEnabled;
  }

  initializeTaskNotificationOnMatterCreation(matter: Matter) {
    if (this.isNotificationApplicable(matter)) {
      if (matter.isNewMatter()) {
        let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId).toString();
        this.accountService.getCachedShallowAccount(accountId).subscribe((account: Account) => {
          matter.sendExternalNotifications = 'NO';
          matter.sendInternalNotifications = 'NO';
          if (account && account.sendExternalNotifications) {
            matter.sendExternalNotifications = account.sendExternalNotifications;
          }
          if (account && account.sendInternalNotifications) {
            matter.sendInternalNotifications = account.sendInternalNotifications;
          }
        });
      } else {
        matter.matterWorkItems.forEach(workItem => {
          workItem.tasksNotificationConfig.filter(item => !!item.active).forEach((matterNotificationConfig: MatterNotificationConfig) => {
            this.createMatterNotificationConfig(matterNotificationConfig, matter, NotificationTypeValue.task, matterNotificationConfig.id);
          });
        });
        this.calculateNotificationsStatuses(matter);

      }

    }
  }

  updateMatterNotificationOnMatterCreation(matter: Matter) {
    if (this.isNotificationApplicable(matter)) {
      matter.matterNotifications.filter((matterNotifications) => matterNotifications.isBasicNotification() && (matterNotifications.isPending() ||
        (matterNotifications.isReady() && !matterNotifications.notificationContent))).forEach((matterNotification, index) => {
        let isExternalNotification: boolean = this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ matterNotification.recipientRole ] ?
          this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ matterNotification.recipientRole ][ 'IS_EXTERNAL_NOTIFICATION' ] :
          undefined;

        if ((!!isExternalNotification && matter.sendExternalNotifications == 'YES') || (!isExternalNotification && matter.sendInternalNotifications == 'YES')) {
          matterNotification.notificationStatus = 'PENDING';
        } else {
          matterNotification.notificationStatus = 'MUTED';
        }
      });
    }
  }

  updateTaskNotificationOnMatterCreation(matter: Matter) {

    let isNewNotificationCreated = false;

    if (this.isNotificationApplicable(matter)) {

      let matterNotificationConfig: MatterNotificationConfig[] = [];
      matter.matterWorkItems.forEach(item => {
        matterNotificationConfig.push(...(item.tasksNotificationConfig || []));
      });

      matter.matterNotifications.slice(0).filter((matterNotifications) => matterNotifications.isTaskNotification() && (matterNotifications.isPending() ||
        (matterNotifications.isReady() && !matterNotifications.notificationContent))).forEach((notification, index) => {
        let matterWorkItemTask = matterNotificationConfig.find(config => config.id == notification.taskIdentifier);
        if (!matterWorkItemTask || notification && notification.isCancelled()) {
          (<any>matter.matterNotifications).remove(notification);
        }
      });

      matter.matterWorkItems.forEach(workItem => {
        workItem.tasksNotificationConfig.forEach((matterNotificationConfig: MatterNotificationConfig) => {
          let matterNotification = matter.matterNotifications.find(mn => mn.taskIdentifier == matterNotificationConfig.id);
          let matterWorkItemTask = workItem.matterWorkItemTasks.find(wi => wi.id == matterNotificationConfig.taskIdentifier);
          if (!matterNotification && !!matterNotificationConfig.active) {
            this.createMatterNotificationConfig(matterNotificationConfig, matter, NotificationTypeValue.task, matterNotificationConfig.id, matterWorkItemTask);
            isNewNotificationCreated = true;
          } else if (matterNotification) {
            let isExternalNotification: boolean = this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ matterNotification.recipientRole ] ?
              this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ matterNotification.recipientRole ][ 'IS_EXTERNAL_NOTIFICATION' ] :
              undefined;
            if ((!!isExternalNotification && matter.sendExternalNotifications == 'YES') || (!isExternalNotification && matter.sendInternalNotifications == 'YES')) {
              if (matterWorkItemTask && matterWorkItemTask.isStatusCompleted() && (matterNotification.isPending() || matterNotification.isManualCancelled() || matterNotification.isReady() || matterNotification.isMuted())
                && (matterNotification.triggerEvent == 'STARTED' || matterNotification.triggerEvent == 'OVERDUE' || matterNotification.triggerEvent == 'ON_HOLD')) {
                matterNotification.notificationStatus = 'MUTED';
              } else if (!!matterNotificationConfig.active) {
                if (matterNotification.isMuted() || matterNotification.isManualCancelled()) {
                  matterNotification.notificationStatus = 'PENDING';
                }
              } else {
                matterNotification.notificationStatus = 'CANCELLED';
              }
            } else {
              if (matterNotification && (matterNotification.isPending() || (matterNotification.isReady() && !matterNotification.notificationContent))) {
                matterNotification.notificationStatus = 'MUTED';
              }
            }
          }
        });
      });
    }

    if (isNewNotificationCreated) {
      this.updateNotificationsPerLatestParticipantsInMatter(matter);
    }
  }

  calculateNotificationsStatuses(matter: Matter) {
    if (matter.matterNotifications && this.isNotificationApplicable(matter)) {
      this.updateNotificationsPerLatestParticipantsInMatter(matter);
      this.updateTaskNotificationOnMatterCreation(matter);
      this.updateMatterNotificationOnMatterCreation(matter);

      //Processing the notifications in pending & ready status (ready because they can go back to pending).
      let notificationsForEvaluation: MatterNotification[] = matter.matterNotifications.filter(notification => notification.isOutstanding());

      notificationsForEvaluation.forEach(notification => {

        if (notification.isRealEstateAgentNotification() && this.isAgentOptedOut(matter)) {
          notification.notificationStatus = 'OPTED_OUT';
          notification.notificationPendingReason = 'Will not send - Opted Out';
        } else if ((notification.isCondoCorpNotification() || notification.isManagementCompanyCorpNotification()) && matter.matterPropertyWithCondo && !matter.matterPropertyWithCondo.isPropertyCondominium()) {
          notification.notificationStatus = 'MUTED';
        } else {
          let roleBasedRules = this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ notification.recipientRole ] ? this.roleBaseRules(matter.provinceCode, matter.isOpportunityMatter())[ notification.recipientRole ][ 'RULES' ] : undefined;
          let triggerBasedRules = this.TriggerBasedRules[ notification.recipientRole + '.' + notification.triggerEvent ] ? this.TriggerBasedRules[ notification.recipientRole + '.' + notification.triggerEvent ][ 'RULES' ] : undefined;
          let taskBasedRules = this.NotificationCommonRule[ 'TASK_VALIDATION' ];

          let notificationRules;
          let allConditionsPassed: boolean = false;
          let roleBasedConditionsPassed: boolean = false;
          let taskStatusBasedConditionsPassed: boolean = false;
          if (notification.isTaskNotification()) {
            notificationRules = {...roleBasedRules, ...taskBasedRules};

            roleBasedConditionsPassed = Object.keys({...roleBasedRules}).every(rule => {
              return !!{...roleBasedRules}[ rule ].eval(matter, notification);
            });
            taskStatusBasedConditionsPassed = Object.keys({...taskBasedRules}).every(rule => {
              return !!{...taskBasedRules}[ rule ].eval(matter, notification);
            });

            allConditionsPassed = Object.keys(notificationRules).every(rule => {
              return !!notificationRules[ rule ].eval(matter, notification);
            });
          } else if (roleBasedRules || triggerBasedRules) {
            notificationRules = {...roleBasedRules, ...triggerBasedRules};
            allConditionsPassed = Object.keys(notificationRules).every(rule => {
              return !!notificationRules[ rule ].eval(matter, notification);
            });
          }

          if (matter.isClosed() && notification.triggerEvent == 'STARTED') {
            notification.notificationStatus = 'PENDING';
            notification.notificationPendingReason = 'No longer applicable';
            notification.dataReadyExpectStatus = false;
          } else if (allConditionsPassed) {
            notification.notificationStatus = 'READY';
            notification.dataReadyExpectStatus = true;
          } else {
            notification.dataReadyExpectStatus = (notification.isTaskNotification() && roleBasedConditionsPassed && !taskStatusBasedConditionsPassed);
            notification.notificationStatus = 'PENDING';
            if (notificationRules) {
              let statusNotificationRule = Object.keys(notificationRules).find(rule => !!notificationRules[ rule ].statusRule && !notificationRules[ rule ].eval(matter));
              if (notificationRules[ statusNotificationRule ]) {
                notification.notificationPendingReason = notificationRules[ statusNotificationRule ].message;
              } else {
                let notificationRule = Object.keys(notificationRules).find(rule => !notificationRules[ rule ].eval(matter, notification));
                if (notificationRules[ notificationRule ]) {
                  notification.notificationPendingReason = matter.isCustomMatter() && notificationRules[ notificationRule ].customMatterMessage ? notificationRules[ notificationRule ].customMatterMessage : notificationRules[ notificationRule ].message;
                }
              }
            }

          }
        }
      });
    }
  };

  updateNotificationsPerLatestParticipantsInMatter(matter: Matter) {
    this.associateParticipantWithNotifications(matter, 'REALESTATEAGENT', matter.realEstateAgent);
    this.associateParticipantWithNotifications(matter, 'ASSIGNED_SOLICITOR', matter.solicitor);
    this.associateParticipantWithNotifications(matter, 'ASSIGNED_LAW_CLERK', matter.lawClerk);
    this.associateParticipantWithNotifications(matter, 'ASSIGNED_OPPORTUNITY_ASSIGNEE', matter.opportunityAssignee);
    this.associateParticipantWithNotifications(matter, 'OTHER_SIDE_SOLICITOR', matter.otherPartySolicitor);
    this.associateParticipantWithNotifications(matter, 'OTHER_SIDE_LAW_CLERK', matter.otherPartyLawClerk);
    let primaryClient: MatterParticipant;
    if (matter.mainClients.length > 0) {
      primaryClient = matter.mainClients.find(value => value.primary);
    }
    this.associateParticipantWithNotifications(matter, 'CLIENT', primaryClient);
    this.associateParticipantWithNotifications(matter, 'CONDO_CORPORATION', matter.condoCorporation);
    this.associateParticipantWithNotifications(matter, 'MANAGEMENT_COMPANY', matter.managementCompany);
    this.associateParticipantWithNotifications(matter, 'INSURANCE_COMPANY_OR_BROKER', (matter.isFireInsuranceContactBroker ?
      matter.brokerMatterParticipant :
      matter.insurerMatterParticipant));
    this.recreateSigningOfficersNotifications(primaryClient, matter);

  }

  /**
   * This method associates given participant to it's corresponding notifications. It also checks if notifications were associated with an old participant
   * and if now it has been changed then deletes old notifications and creates new ones.
   * @param {Matter} matter
   * @param {RecipientRole} notificationRecipientRole
   * @param {MatterParticipant or ContactInfo} associatedEntity
   */
  associateParticipantWithNotifications(matter: Matter, notificationRecipientRole: RecipientRole, associatedEntity: any) {
    let notificationsByRecipientRole: MatterNotification[] =
      matter.matterNotifications.filter(value => value.recipientRole == notificationRecipientRole && !value.orphaned && (value.isBasicNotification() || value.isTaskNotification()));
    let primarySigner: MatterParticipant;
    if (notificationRecipientRole == 'CLIENT') {
      let pSigner = matter.getAllSigners(associatedEntity).find(item => !!item.primary);
      primarySigner = (pSigner && pSigner.contact && pSigner.contact.firstEmail && Utils.validateEmail(pSigner.contact.firstEmail)) ? pSigner : associatedEntity;
    }

    if (notificationsByRecipientRole.length > 0) {
      notificationsByRecipientRole.forEach(notification => {
        let entity = notification.isTaskNotification() && primarySigner ? primarySigner : associatedEntity;

        //currently assigning source contact id as recipientIdentifier
        if (entity && !notification.recipientIdentifier && !notification.isSent()) {
          //if notification doesn't have recipientIdentifier already then assigning one
          this.updateRecipientNameAndIdentifierFromMatterParticipant(notification, entity);
        } else if (entity && !(notification.isAssociatedWithParticipant(entity) || notification.isAssociatedWithOtherSideLawClerk(entity))) {
          /*
             If participant has been changed then cancelling the notifications created for old participant and adding new notifications for the new
             one. Old notifications should show as cancelled. if notification has been already sent then it won't be cancelled or revoked
          */

          if (notification.canBeCloned()) {
            let clonedNotification: MatterNotification = notification.clone();
            if (notification.isTaskNotification()) {
              clonedNotification.notificationType = NotificationTypeValue.task;
              clonedNotification.taskIdentifier = notification.taskIdentifier;
            } else {
              clonedNotification.notificationType = NotificationTypeValue.basic;
            }

            //Associating notification with new participant
            this.updateRecipientNameAndIdentifierFromMatterParticipant(clonedNotification, entity);
            //if notification is not MUTED then mark the new notification in pending status. it will be evaluated again.
            if (clonedNotification.notificationStatus != 'MUTED' && !clonedNotification.isManualCancelled()) {
              clonedNotification.notificationStatus = 'PENDING';
            }
            matter.matterNotifications.push(clonedNotification);
          }

          //Cancelling current notification
          notification.cancelIfPossible();
          //Making current notification inapplicable
          notification.orphaned = true;

        } else if (!entity) {
          notification.recipientIdentifier = null;
          if (!notification.isSent()) {
            notification.recipientName = null;
          }
        }
      });

    }
  }

  updateRecipientNameAndIdentifier(notification: MatterNotification, contact: Contact): void {
    notification.recipientIdentifier = this.getRecipientIdentifier(contact);
    notification.recipientName = contact.contactFullNameStartWithFirstName;
  }

  updateRecipientNameAndIdentifierFromMatterParticipant(notification: MatterNotification, matterParticipant: MatterParticipant): void {
    if (matterParticipant) {
      this.updateRecipientNameAndIdentifier(notification, matterParticipant.contact);
    }
  }

  /*
  * Construct the signing officers notifications.
  * @param {MatterParticipant} parentMP
  * @param {Matter} matter
  * */
  recreateSigningOfficersNotifications(parentMP: MatterParticipant, matter: Matter): void {
    /*
    * The process is to keep the existing notifications for existing signing officers
    * Add new notifications for new signing officers
    * Remove the rest
    * */
    let existingNotifications: MatterNotification[] = [];
    let newNotifications: MatterNotification[] = [];
    let parentRecipientIdentifier: number;

    if (parentMP) {
      parentRecipientIdentifier = this.getRecipientIdentifier(parentMP.contact);
      let parentNotifications: MatterNotification[] = matter.matterNotifications.filter(item => item.recipientIdentifier == parentRecipientIdentifier && !item.isCancelled());
      let childrenMps = matter.getChildMatterParticipants(parentMP);

      childrenMps.forEach((childMp) => {
        // If the contact has a lastName and an id or sourceContactId
        if (childMp.contact && childMp.contact.lastName && this.getRecipientIdentifier(childMp.contact)) {
          parentNotifications.forEach((parentNotification) => {
            let existingNotification = this.findChildNotification(parentNotification, childMp, matter);
            if (existingNotification) {
              this.updateRecipientNameAndIdentifier(existingNotification, childMp.contact);
              existingNotifications.push(existingNotification);

            } else {
              let newChildNotification = this.addNewChildNotification(parentNotification, childMp.contact);
              newNotifications.push(newChildNotification);
            }
          });
        }
      });
    }

    // Remove all children notification except sent ones
    this.removeAllSignersNotifications(matter);
    //Add if not exists
    this.addToMatterNotifications([ ...existingNotifications, ...newNotifications ], matter);
  }

  addToMatterNotifications(notifications: MatterNotification[], matter: Matter): void {
    notifications.forEach((notification) => {
      if (!matter.matterNotifications.find(item =>
        item.recipientIdentifier == notification.recipientIdentifier &&
        item.notificationType == notification.notificationType &&
        item.recipientRole == notification.recipientRole &&
        item.triggerEvent == notification.triggerEvent)) {
        matter.matterNotifications.push(notification);
      }
    });
  }

  addNewChildNotification(parentNotification: MatterNotification, contact: Contact): MatterNotification {
    let childNotification: MatterNotification = parentNotification.clone();
    if (childNotification.isMuted()) {
      childNotification.notificationStatus = 'MUTED';
    } else {
      childNotification.notificationStatus = 'PENDING';
    }
    childNotification.parentRecipientIdentifier = parentNotification.recipientIdentifier;
    childNotification.notificationType = NotificationTypeValue.signer;
    this.updateRecipientNameAndIdentifier(childNotification, contact);
    return childNotification;
  }

  findChildNotification(parentNotification: MatterNotification, childMp: MatterParticipant, matter: Matter): MatterNotification {
    return matter.matterNotifications.find(item =>
      item.recipientIdentifier == this.getRecipientIdentifier(childMp.contact) &&
      item.recipientRole == parentNotification.recipientRole &&
      item.triggerEvent == parentNotification.triggerEvent &&
      item.parentRecipientIdentifier == parentNotification.recipientIdentifier);
  }

  getRecipientIdentifier(contact: Contact): number {
    return contact.sourceContactId ? contact.sourceContactId : contact.id;
  }

  removeAllSignersNotifications(matter: Matter): void {
    matter.matterNotifications = matter.matterNotifications.filter(notification => !notification.isSignerNotification() || notification.isSent());
  }

  validateParticipantEmail = (matter: Matter, notification: MatterNotification): boolean => {
    if (matter.mainClients.length > 0) {
      let primaryClient: MatterParticipant = matter.mainClients.find(value => value.primary);
      if (notification.isSignerNotification() && matter.getChildMatterParticipants(primaryClient)) {
        let childMp = matter.getChildMatterParticipants(primaryClient).find(mp => mp.contact.id == notification.recipientIdentifier || mp.contact.sourceContactId == notification.recipientIdentifier);
        return childMp && childMp.contact && childMp.contact.firstEmail && Utils.validateEmail(childMp.contact.firstEmail);
      } else if (notification.isTaskNotification() && matter.getAllSigners(primaryClient).length > 0) {
        let childMp = matter.getAllSigners(primaryClient).find(mp => !!mp.primary);
        return (childMp && childMp.contact && childMp.contact.firstEmail && Utils.validateEmail(childMp.contact.firstEmail)) || (primaryClient && primaryClient.contact && primaryClient.contact.firstEmail && Utils.validateEmail(primaryClient.contact.firstEmail));
      } else {
        return primaryClient && primaryClient.contact && primaryClient.contact.firstEmail && Utils.validateEmail(primaryClient.contact.firstEmail);
      }
    }

    return false;
  };

  validateAgentEmail = (matter: Matter): boolean => {
    let mp = matter.realEstateAgent;
    return mp && mp.contact && mp.contact.firstEmail && Utils.validateEmail(mp.contact.firstEmail);
  };

  validateCondoCorpEmail = (matter: Matter): boolean => {
    let mp = matter.condoCorporation;
    return mp && mp.contact && mp.contact.firstEmail && Utils.validateEmail(mp.contact.firstEmail);
  };

  validateManagementCompanyEmail = (matter: Matter): boolean => {
    let mp = matter.managementCompany;
    return mp && mp.contact && mp.contact.firstEmail && Utils.validateEmail(mp.contact.firstEmail);
  };

  validateInsurerBrokerEmail = (matter: Matter): boolean => {
    let mp = (matter.isFireInsuranceContactBroker ?
      matter.brokerMatterParticipant :
      matter.insurerMatterParticipant);
    return mp && mp.contact && mp.contact.firstEmail && Utils.validateEmail(mp.contact.firstEmail);
  };

  isAgentOptedOut(matter): boolean {
    let mp = matter.realEstateAgent;
    return mp && mp.contact && mp.contact.firstEmail && ContactUtil.isOptedOutEmail(mp.contact, this.notificationOptOutService);
  }

  validateTask = (matter: Matter, notification: MatterNotification): boolean => {
    let matterWorkItemTasks: MatterWorkItemTask[] = [];
    let tasksNotificationConfig: MatterNotificationConfig[] = [];
    matter.matterWorkItems.forEach(item => {
      matterWorkItemTasks.push(...(item.matterWorkItemTasks || []));
      tasksNotificationConfig.push(...(item.tasksNotificationConfig || []));
    });
    let taskNotificationConfig = tasksNotificationConfig.find(tasksNotification => notification && tasksNotification.id == notification.taskIdentifier);
    let matterWorkItemTask = matterWorkItemTasks.find(matterWorkItemTask => matterWorkItemTask && taskNotificationConfig && matterWorkItemTask.id == taskNotificationConfig.taskIdentifier);
    return matterWorkItemTask && notification &&
      (matterWorkItemTask.taskNotificationStatus == notification.taskNotificationStatus
        || !!notification.notificationContent // that means the notification has been sent before regardless the task status has been changed or not
        || (matterWorkItemTask.isOverDue() && notification.taskNotificationStatus == 'OVERDUE'));
  };

  validateClientPropertyOrDescription = (matter: Matter): boolean => {
    if (matter.isCustomMatter()) {
      return matter.matterPropertyWithCondo && !!matter.matterPropertyWithCondo.legalDescriptionSummary;
    } else {
      return matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && matter.matterPropertyWithCondo.address.addressLine1;
    }
  };

  // checks email only for non-Unity user
  validateOtherSideSolicitorContactInfo = (matter: Matter, notification: MatterNotification): boolean => {
    if (matter && matter.otherPartySolicitor && matter.otherPartySolicitor.contact) {
      if (matter.otherPartySolicitor.contact.isStaffProfile) {
        return true; // will be handled by an internal message and not by email
      } else {
        return matter.otherPartySolicitor.contact.firstEmail && Utils.validateEmail(matter.otherPartySolicitor.contact.firstEmail);
      }
    }
    return false;
  };

  // checks email only for non-Unity user
  validateOtherPartyLawClerkContactInfo = (matter: Matter, notification: MatterNotification): boolean => {
    if (matter && matter.otherPartyLawClerk && matter.otherPartyLawClerk.contact) {
      if (matter.otherPartyContactInfo.lawClerkId) {
        return true; // will be handled by an internal message and not by email
      } else {
        return matter.otherPartyContactInfo.lawClerkEmail && Utils.validateEmail(matter.otherPartyContactInfo.lawClerkEmail);
      }
    }
    return false;
  };

  NotificationCommonRule: any = {
    PROPERTY_ADDRESS: {
      eval: (matter: Matter): boolean => {
        return matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address.addressLine1;
      }, message: 'Missing Street Address'
    },
    PROPERTY_ADDRESS_OR_DESCRIPTION: {
      eval: this.validateClientPropertyOrDescription,
      message: 'Missing Street Address',
      customMatterMessage: 'Missing Description'
    },
    PRIMARY_CLIENT: {
      eval: (matter: Matter): boolean => {
        return matter.mainClients.length > 0;
      }, message: 'Missing Primary Client'
    },
    PROSPECT: {
      eval: (matter: Matter): boolean => {
        return matter.mainClients.length > 0;
      }, message: 'Missing Primary Prospect'
    },
    MATTER_ACTIVE: {
      eval: (matter: Matter): boolean => {
        return !!matter.isMatterActive;
      }, message: 'Pending Matter Not Active'
    },
    CLOSED_FLAG_YES: {
      eval: (matter: Matter): boolean => {
        return matter.isClosed();
      }, message: 'Pending Status', statusRule: true
    },
    TASK_VALIDATION: {
      TASK_STATUS_VALIDATION: {
        eval: this.validateTask,
        message: 'Pending Task Status'
      }
    },
    NOT_APPLICABLE: {
      eval: (matter: Matter): boolean => {
        return true;
      }, message: 'Not applicable'
    }
  };

  roleBaseRules(provinceCode: ProvinceCode, isOpportunity: boolean = false): any {
    return {
      'REALESTATEAGENT': {
        RULES: {
          REAL_ESTATE_AGENT: {
            eval: (matter: Matter): boolean => {
              return !!matter.realEstateAgent;
            }, message: 'Missing Real Estate Agent'
          },
          REAL_ESTATE_AGENT_EMAIL: {eval: this.validateAgentEmail, message: 'Missing Email Address'},
          PROPERTY_ADDRESS: this.NotificationCommonRule[ 'PROPERTY_ADDRESS' ],
          PRIMARY_CLIENT: this.NotificationCommonRule[ 'PRIMARY_CLIENT' ]
        },
        APPLICABLE_MATTER_TYPES: 'PS', //Purchase, Sale
        IS_EXTERNAL_NOTIFICATION: true

      },
      'CLIENT': {
        RULES: {
          PRIMARY_CLIENT: isOpportunity ? this.NotificationCommonRule[ 'PROSPECT' ] : this.NotificationCommonRule[ 'PRIMARY_CLIENT' ],
          PRIMARY_CLIENT_EMAIL: {eval: this.validateParticipantEmail, message: 'Missing Email Address'},
          PROPERTY_ADDRESS_OR_DESCRIPTION: isOpportunity ? this.NotificationCommonRule[ 'NOT_APPLICABLE' ] : this.NotificationCommonRule[ 'PROPERTY_ADDRESS_OR_DESCRIPTION' ]
        },
        APPLICABLE_MATTER_TYPES: 'PSMCO',
        IS_EXTERNAL_NOTIFICATION: true
      },
      'CONDO_CORPORATION': {
        RULES: {
          CONDO_CORPORATION: {
            eval: (matter: Matter): boolean => {
              //Condo corporation matter participant is always present. So we have to check if it has a source contact id
              return matter.condoCorporation && matter.condoCorporation.hasSourceContactId();
            }, message: 'Missing Condo Corporation'
          },
          CONDO_CORPORATION_EMAIL: {eval: this.validateCondoCorpEmail, message: 'Missing Email Address'},
          PROPERTY_ADDRESS: this.NotificationCommonRule[ 'PROPERTY_ADDRESS' ],
          PRIMARY_CLIENT: this.NotificationCommonRule[ 'PRIMARY_CLIENT' ]
        },
        APPLICABLE_MATTER_TYPES: 'PSM', //Purchase, Sale
        IS_EXTERNAL_NOTIFICATION: true
      },
      'MANAGEMENT_COMPANY': {
        RULES: {
          MANAGEMENT_COMPANY: {
            eval: (matter: Matter): boolean => {
              return !!matter.managementCompany;
            }, message: 'Missing Management Company'
          },
          MANAGEMENT_COMPANY_EMAIL: {eval: this.validateManagementCompanyEmail, message: 'Missing Email Address'},
          PROPERTY_ADDRESS: this.NotificationCommonRule[ 'PROPERTY_ADDRESS' ],
          PRIMARY_CLIENT: this.NotificationCommonRule[ 'PRIMARY_CLIENT' ]
        },
        APPLICABLE_MATTER_TYPES: 'PSM', //Purchase, Sale
        IS_EXTERNAL_NOTIFICATION: true
      },
      'INSURANCE_COMPANY_OR_BROKER': {
        RULES: {
          INSURANCE_COMPANY_OR_BROKER: {
            eval: (matter: Matter): boolean => {
              return !!(matter.isFireInsuranceContactBroker ?
                matter.brokerMatterParticipant :
                matter.insurerMatterParticipant);
            }, message: 'Missing Insurance Company or Broker'
          },
          REAL_ESTATE_AGENT_EMAIL: {eval: this.validateInsurerBrokerEmail, message: 'Missing Email Address'},
          PROPERTY_ADDRESS: this.NotificationCommonRule[ 'PROPERTY_ADDRESS' ],
          PRIMARY_CLIENT: this.NotificationCommonRule[ 'PRIMARY_CLIENT' ]
        },
        APPLICABLE_MATTER_TYPES: 'PSM', //Purchase, Sale,
        IS_EXTERNAL_NOTIFICATION: true
      },
      'ASSIGNED_SOLICITOR': {
        RULES: {
          ASSIGNED_SOLICITOR: {
            eval: (matter: Matter): boolean => {
              return !!matter.solicitor;
            }, message: isOpportunity ?
              'Missing Requested Solicitor' :
              'Missing Assigned ' + provinceBasedLawyerTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ]
          }
        },
        APPLICABLE_MATTER_TYPES: 'PSMCO',
        IS_EXTERNAL_NOTIFICATION: false
      },
      'ASSIGNED_LAW_CLERK': {
        RULES: {
          ASSIGNED_LAW_CLERK: {
            eval: (matter: Matter): boolean => {
              return !!matter.lawClerk;
            }, message: 'Missing Assigned ' + provinceBasedLawClerkTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ]
          }
        },
        APPLICABLE_MATTER_TYPES: 'PSMCO',
        IS_EXTERNAL_NOTIFICATION: false
      },
      'ASSIGNED_OPPORTUNITY_ASSIGNEE': {
        RULES: {
          ASSIGNED_OPPORTUNITY_ASSIGNEE: {
            eval: (matter: Matter): boolean => {
              return !!matter.opportunityAssignee;
            }, message: 'Missing Assignee'
          }
        },
        APPLICABLE_MATTER_TYPES: 'O',
        IS_EXTERNAL_NOTIFICATION: true
      },
      'OTHER_SIDE_SOLICITOR': {
        RULES: {
          OTHER_SIDE_SOLICITOR: {
            eval: (matter: Matter): boolean => {
              return !!matter.otherPartySolicitor;
            }, message: 'Missing Other Side ' + provinceBasedLawyerTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ]
          },
          OTHER_SIDE_SOLICITOR_EMAIL: {eval: this.validateOtherSideSolicitorContactInfo, message: 'Missing Email Address'}
        },
        APPLICABLE_MATTER_TYPES: 'PSMCO',
        IS_EXTERNAL_NOTIFICATION: true

      },
      'OTHER_SIDE_LAW_CLERK': {
        RULES: {
          OTHER_SIDE_LAW_CLERK: {
            eval: (matter: Matter): boolean => {
              return !!matter.otherPartyLawClerk;
            }, message: 'Missing Other Side ' + provinceBasedLawClerkTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ]
          },
          OTHER_SIDE_LAW_CLERK_EMAIL: {eval: this.validateOtherPartyLawClerkContactInfo, message: 'Missing Email Address'}
        },
        APPLICABLE_MATTER_TYPES: 'PSMCO',
        IS_EXTERNAL_NOTIFICATION: true
      }
    };
  };

  TriggerBasedRules: any = {
    'REALESTATEAGENT.STARTED': {
      RULES: {
        MATTER_ACTIVE: this.NotificationCommonRule[ 'MATTER_ACTIVE' ]
      }
    },
    'REALESTATEAGENT.FINISHED': {
      RULES: {
        CLOSED_FLAG_YES: this.NotificationCommonRule[ 'CLOSED_FLAG_YES' ]
      }
    },
    'CLIENT.STARTED': {
      RULES: {
        MATTER_ACTIVE: this.NotificationCommonRule[ 'MATTER_ACTIVE' ]
      }
    },
    'CLIENT.FINISHED': {
      RULES: {
        CLOSED_FLAG_YES: this.NotificationCommonRule[ 'CLOSED_FLAG_YES' ]
      }
    }
  };
}

