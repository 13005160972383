<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="soAdjExpenseOccupancyPeriod">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Total <span dp-province-field-label="matter.soa.commonExpenses">Common Expenses</span> During Occupancy Period</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12" >
                <dp-checkbox fieldId="overrideGrossAnnualExpense">
                    <input type="checkbox"
                            id="overrideGrossAnnualExpense"
                            name="overrideGrossAnnualExpense"
                            [(ngModel)]="soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense"
                            (ngModelChange)="onOverrideGrossAnnualExpenseChange()"

                    >
                </dp-checkbox>

                <label class="control-label" >Override gross annual <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span> for entire project</label>
            </div>
        </div>
        <div class="form-group" *ngIf="soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense">
            <div class="col-lg-12" >
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Total annual <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span> for project</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           id="totalExpense"
                           name="totalExpense"
                           dp-currency
                           class="form-control"
                           [(ngModel)]="soAdjExpenseOccupancyPeriod.totalExpense"
                    />
                </div>
                <div class="col-lg-3">
                    <label class="control-label text-left">at {{getUnitLevelPlanCondominiumTotalSharePercentage()}} share</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           id="unitLevelPlanCondominiumTotalExpenses"
                           name="unitLevelPlanCondominiumTotalExpenses"
                           dp-currency
                           [dp-read-only]="true"
                           class="form-control"
                           [ngModel]="getUnitLevelPlanCondominiumTotalShareExpenses()"/>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Above total in effect from</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="effectiveFromType"
                            name="effectiveFromType"
                            class="form-control"
                            [(ngModel)]="soAdjExpenseOccupancyPeriod.effectiveFromType"
                            (ngModelChange)="onEffectiveFromTypeChange()"
                    >
                        <option *ngFor="let effectiveFromType of effectiveFromTypeDropDown" [value]="effectiveFromType.value">
                            {{effectiveFromType.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6" *ngIf="isTotalEffectiveFromVisible()">
                    <dp-partial-date
                        [fullDateRequired]="true"
                        dp-error-validator
                        statusBarHelp
                        [inputDate]="soAdjExpenseOccupancyPeriod.totalEffectiveFrom"
                        [fieldKey]="'matter.soadj.expense.occupancy.period.from.date'"
                        (dateChange)="onDateChangeTotalEffectiveFrom($event)">
                    </dp-partial-date>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">To</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="effectiveTillType"
                            name="effectiveTillType"
                            class="form-control"
                            [(ngModel)]="soAdjExpenseOccupancyPeriod.effectiveTillType"
                            (ngModelChange)="onEffectiveTillTypeChange()"
                    >
                        <option *ngFor="let effectiveTillType of effectiveTillTypeDropDown" [value]="effectiveTillType.value">
                            {{effectiveTillType.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6" *ngIf="isTotalEffectiveTillVisible()">
                    <dp-partial-date
                        [fullDateRequired]="true"
                        dp-error-validator
                        statusBarHelp
                        [inputDate]="soAdjExpenseOccupancyPeriod.totalEffectiveTill"
                        [fieldKey]="'matter.soadj.expense.occupancy.period.till.date'"
                        (dateChange)="onDateChangeTotalEffectiveTill($event)">
                    </dp-partial-date>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="!soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Use <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span> amount from <br/>Property/Teranet Connect Tab?</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="useAmountFromPropertyTab"
                            name="useAmountFromPropertyTab"
                            class="form-control"
                            [(ngModel)]="soAdjExpenseOccupancyPeriod.useAmountFromPropertyTab"
                            (ngModelChange)="onChangeUseAmountFromPropertyTab()"
                    >
                        <option *ngFor="let useAmountFromPropertyTab of useAmountFromPropertyTabDropDown" [value]="useAmountFromPropertyTab.value">
                            {{useAmountFromPropertyTab.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="!soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Amount of <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span></label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           id="expensesAmount"
                           name="expensesAmount"
                           class="form-control"
                           dp-currency
                           [dp-read-only]="isExpensesAmountVisible"
                           [(ngModel)]="soAdjExpenseOccupancyPeriod.expensesAmount"
                    />
                    <button *ngIf="isExpensesAmountVisible"
                            type="button"
                            id="unitLevelPlanBtn"
                            tabIndex="-1"
                            (click)="selectUnitLevelPlan()" class="button-glyph">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="adjustmentHeadingVisible()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Adjustment heading</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           maxlength="38"
                           id="adjustmentHeading"
                           name="adjustmentHeading"
                           class="form-control"
                           [(ngModel)]="soAdjExpenseOccupancyPeriod.adjustmentHeading"
                    />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Charge purchaser for month of <br/>
                        {{chargePurchaserLabel()}}</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="expensePaid"
                            name="expensePaid"
                            class="form-control"
                            [(ngModel)]="soAdjExpenseOccupancyPeriod.chargePurchaser"
                    >
                        <option *ngFor="let chargePurchaser of soAdjExpenseOccupancyPeriodChargePurchaserOptions" [value]="chargePurchaser.value">
                            {{chargePurchaser.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" dp-province-field-label="matter.soa.expense.taxtype">Adjust for</label>
                </div>
                <div class="col-lg-2" *ngIf="context.matter.isMatterProvinceON">
                    <select type="text"
                            id="format"
                            name="format"
                            class="form-control"
                            [(ngModel)]="soAdjExpenseOccupancyPeriod.adjustedFor"
                            (change)="updateTaxRate(soAdjExpenseOccupancyPeriod.adjustedFor)"

                    >
                        <option *ngFor="let adjustedFor of adjustedForDropDown" [value]="adjustedFor.value">
                            {{adjustedFor.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-2 text-left">
                    <div *ngIf="context.matter.isMatterProvinceON" style="display:inline-block ">
                        <span class="inline-block width-3percent;">?</span>
                    </div>
                    <div style="display:inline-block ">
                        <select type="text"
                                id="format1"
                                name="format1"
                                class="form-control inline-block margin-left-5 width-91percent"
                                [(ngModel)]="soAdjExpenseOccupancyPeriod.isExpenseOccupancyAdjusted"
                        >
                            <option *ngFor="let adjusted of adjustedDropDown"
                                    [value]="adjusted.value">
                                {{adjusted.label}}
                            </option>
                        </select>
                    </div>

                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="soAdjExpenseOccupancyPeriod.isExpenseOccupancyAdjusted == 'YES'">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{soAdjExpenseOccupancyPeriod.adjustedFor}} percentage is</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="adjustPercentage"
                           name="adjustPercentage"
                           class="form-control"
                           [(ngModel)]="soAdjExpenseOccupancyPeriod.adjustPercentage"
                           dp-percentage
                    />
                </div>
                <label class="control-label col-lg-1 text-left">%</label>
            </div>
        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <label class="control-label">
                        {{soAdjExpenseOccupancyPeriod.adjustmentHeading}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-left">
                    Monthly <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span>
                </label>
                <label class="control-label col-lg-2 text-right">
                    {{getMonthlyCommonExpenses()}}
                </label>
            </div>
            <div class="col-lg-12" *ngIf="soAdjExpenseOccupancyPeriod.isExpenseOccupancyAdjusted === 'YES'">
                <label class="control-label col-lg-3 text-left">
                    {{soAdjExpenseOccupancyPeriod.adjustedFor}} calculated at
                    {{soAdjExpenseOccupancyPeriod.adjustPercentage}}%
                </label>

                <label class="control-label col-lg-2 text-right">
                    {{getSoAdjExpenseOccupancyPeriodTax()}}
                </label>
            </div>
            <div class="col-lg-12 margin-top-minus-3">
                <label class="control-label col-lg-3 text-left" style="white-space: pre-line; line-height: 23px">
                    Total <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span>
                </label>
            </div>
            <div class="col-lg-12 margin-top-minus-3">
                <label class="control-label col-lg-3 text-left">
                    {{getTotalExpensePeriod()}}
                </label>

                <label class="control-label col-lg-2 text-right">
                    {{getSoAdjExpenseOccupancyPeriodTotal()}}
                </label>
            </div>



            <div class="col-lg-12" >
                <label class="control-label col-lg-3 text-left">
                    Credit Vendor
                </label>
                <label class="control-label col-lg-3 text-right">

                </label>
                <label class="control-label col-lg-4 text-right">
                    {{getSoAdjExpenseOccupancyPeriodTotal()}}
                </label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button type="button"
                    (click)="delete()"
                    *ngIf="allowDelete"
                    class="secondary-button">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
