import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ParcelTitleNoOfIndividualShares} from '../../shared-main/constants';

export class ParcelTitle extends BaseEntity {
  id: number;
  titleNumber: string;
  parcelTitleValue: number;
  numberOfIndividualShares: string;
  parcelLegalDescriptionId: number;

  constructor(parcelTitle?: ParcelTitle) {
    super(parcelTitle);
    if (!parcelTitle) {
      this.numberOfIndividualShares = ParcelTitleNoOfIndividualShares;
    }

  }

}
