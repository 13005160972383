<div class="dp-dropdown-menu" style="display:inline-block;float: none;margin-right: 5px;"
     (keyDown.enter)="openMenu($event)"
     (keyDown.arrowup)="handleArrowUp($event)"
     (keyDown.arrowdown)="handleArrowDown($event)"
     (keyDown.arrowleft)="handleArrowLeft($event)"
     (keyDown.arrowright)="handleArrowRight($event)"
     (keyDown.esc)="handleEsc($event)"
     (keyDown.tab)="handleTab($event)"
     (keyDown.shift.tab)="handleTab($event)"
     (keydown.f9)="handleF9($event)"
     [ngClass]="{'active': active, 'dropdown-open-left': openSubMenuOnLeft}"

>
    <button
        class="drop-down-button dropbtn select-button dp-btn-table {{customColor ? 'dp-btn-table-' + customColor : 'dp-btn-table-green'}}  {{addTabStopClass ? excelTabStopClassName : ''}}
           {{addDpReadOnlyNotDisabledClass ? 'not-disabled' : ''}} "
        *ngIf="buttonLabel"
        [ngClass]="{'focus-first': focusFirst}"
        [disabled]="disabled"
        (blur)="emitClose();burgerOnBlur($event);"
        (click)="handleBurgerClick($event)" [attr.tabindex]="0"   >
        <i *ngIf="!buttonLabelOnLeft" class="fa fa-bars"  aria-hidden="false"></i>
        <span>{{buttonLabel}}</span>
        <i *ngIf="buttonLabelOnLeft" class="fa fa-bars"  [ngClass]="{'padding-left-5' : buttonLabelOnLeft}" aria-hidden="false"></i>
        <div class="clear"></div>
    </button>
    <a *ngIf="!buttonLabel"
       (click)="handleBurgerClick($event)"
       class="toggleBurger  {{addTabStopClass ? excelTabStopClassName : ''}}"
       [ngClass]="{'active': active}"
       [attr.tabindex]="tabIndex"
       (blur)="emitClose();burgerOnBlur($event)"
       (focus)="emitFocus()"
    >
        <i class="fa fa-bars"></i>
    </a>

    <div class="dropdown-component-type-list"
         [ngClass]="{'list-with-scroll-bar' : firstLevelScrolling}"
         [ngStyle]="{'min-width': getMenuWidth(firstLevelMenuIndex)}" *ngIf="active">
        <div class="dropdown-content" *ngFor="let item of dropDownItems; let i = index">
            <!-- first level menu -->

            <button *ngIf="!item.hidden"
                    [attr.tabindex]="0"  class="dropdown-menu-item"
                    (mouseenter)="setFocus(i)" (focus)="setFocus(i); burgerOnFocused($event);"
                    [ngClass]="{'selected': isSelected(i),
                        'last-item': (i==dropDownItems.length-1),
                        'image' : (item.items && item.items.length>0 && !openSubSubMenuOnLeft),
                        'arrow-left' : (item.items && item.items.length>0 && openSubSubMenuOnLeft),
                        'disabled' : !!item.isDisabled,
                        'topline' : item.topline,
                        'underline' : item.underline, 'first-item' : (i==0) ,'imageCssClass-padding' : isImageCssClassExisit && !item.isImageCssClassDefined,'not-disabled' : addDpReadOnlyNotDisabledClass }"
                    (click)="handleItemClick($event,i)">
                <span *ngIf="item.isImageCssClassDefined" [ngClass]="item.isImageCssClassDefined"></span>
                <span class="menu-item-text">{{itemText(item)}}</span></button>
            <!-- second level menu -->
            <div class="dropdown-component-type-list select-button-list"
                 [ngClass]="{'list-with-scroll-bar' : secondLevelScrolling}"
                 [ngStyle]="{'min-width': getMenuWidth(secondLevelMenuIndex), 'max-width': getMenuWidth(secondLevelMenuIndex),  'left': getSubSubMenuLeft(secondLevelMenuIndex), 'top' : getSubSubMenuTop(secondLevelMenuIndex, item.items.length) }"
                 (mouseenter)="setFocus(i)"
                 (mouseleave)="setFocus(-1); setSubFocus(-1);"
                 *ngIf="(item.items &&  item.items.length>0 && isSelected(i))">
                <div class="dropdown-sub-content" style="display: block;"  *ngFor="let subitem of item.items; let j = index">
                    <button *ngIf="!subitem.hidden"
                            [ngClass]="{'selected':  isSubSelected(j),
                            'image' : (subitem.items &&  subitem.items.length>0 && !openSubSubMenuOnLeft),
                            'arrow-left' : (subitem.items &&  subitem.items.length>0 && openSubSubMenuOnLeft),
                            'disabled' : !!subitem.isDisabled ,
                            'topline' : subitem.topline,
                            'underline' : subitem.underline}"
                            (click)="handleSubItemClick($event,j)" (mouseenter)="setSubFocus(j)" (focus)="setSubFocus(j); burgerOnFocused($event);" >
                        <span class="menu-item-text">{{subitem.text}}</span>
                    </button>
                    <!-- third level menu -->
                    <div class="dropdown-component-type-list select-button-list"
                         [ngClass]="{'list-with-scroll-bar' : thirdLevelScrolling}"
                         [ngStyle]="{'min-width': getMenuWidth(thirdLevelMenuIndex) , 'max-width': getMenuWidth(thirdLevelMenuIndex), 'left': getSubSubMenuLeft(thirdLevelMenuIndex) }"
                         (mouseenter)="setSubFocus(j)" (mouseleave)="setSubSubFocus(-1);"
                         style="min-width: 95px;" *ngIf="(subitem.items &&  subitem.items.length>0 && isSubSelected(j))">
                        <div class="dropdown-sub-sub-content" style="display: block;"  *ngFor="let subsubitem of subitem.items; let k = index">
                            <button *ngIf="!subsubitem.hidden"
                                    (mouseenter)="setSubSubFocus(k)" (click)="handleSubSubItemClick($event,k)"
                                     [ngClass]="{'selected':  isSubSubSelected(k),
                                     'disabled' : !!subsubitem.isDisabled,
                                     'topline' : subsubitem.topline,
                                     'underline' : subsubitem.underline}"
                                     (focus)="setSubSubFocus($event,k); burgerOnFocused($event);">
                                <span class="menu-item-text">{{subsubitem.text}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
