import {AppMode, EnvironmentConfig, KeycloakConfig} from '../environment';

export class CaProdEnvironment implements EnvironmentConfig {
  appMode = AppMode.CA;
  tcolUrl = 'https://account.apicanada.com/register';
  keycloak: KeycloakConfig = {
    clientId: 'unity-spa',
    domain: 'https://sso.dyedurham.dev',
    logoutRedirectUri: 'https://dyedurham.ca',
    realm: 'dyedurham_sso'
  };
  //TODO: Waiting for URL from Faranak UCBA-2034
  ltsaWebBaseUrl: string = 'https://appsuat.ltsa.ca';
  landingPageUrl: string = '';
  //TODO: Needs to go through an approval process with GoCardless before go live details are given
  goCardlessApiUrl: string = '<setup for prod once GoCardless have approved>';
  goCardlessAuthRedirectUri: string = '<setup for prod once GoCardless have approved>';
  goCardlessClientId: string = '<setup for prod once GoCardless have approved>';
}
