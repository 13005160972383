<form class="form-horizontal" id="teranetSearchForm" #teranetSearchForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-10">
                <h1>Teranet Connect &trade;: Search Parcel Register</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>

        <div class="row modal-body">
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-2" for="lroList">LRO</label>
                <div class="col-lg-4">
                    <select class="form-control" id="lroList"
                            name="lroList" [(ngModel)]="teranetSearchRequest.selectedLro" [fieldKey]="'teranet.searchParcelRegister.missingLRO'" dp-error-validator>
                        <option *ngFor="let lro of lroList" [ngValue]="lro">
                            {{lro.officeNameAndNumber }}
                        </option>
                    </select>

                </div>
            </div>

            <div class="form-group col-lg-12">
                <span class="radio display-inline" (click)="onSearchOptionClick('SEARCH_BY_ADDRESS')">
                          <input class="form-control" type="radio" name="searchType" [(ngModel)]="teranetSearchRequest.searchType"
                                 value="SEARCH_BY_ADDRESS" />
                          <label class="control-label col-lg-2 text-left">Search by Address:</label>
                </span>
            </div>
            <div [dp-read-only-block]="!isSearchOptionEnabled('SEARCH_BY_ADDRESS')" [elementRefComp]="elementRef">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="streetNo">Street No.</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="streetNo" id="streetNo" maxlength="5"
                               [fieldKey]="'teranet.searchParcelRegister.missingStreetNumber'" dp-error-validator
                               [(ngModel)]="teranetSearchRequest.streetNumber"/>
                    </div>
                    <label class="control-label col-lg-2" for="streetName">Street Name</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="streetName" id="streetName" dpPlaceholder="Do not include Street, St., Road, Rd."
                               [fieldKey]="'teranet.searchParcelRegister.missingStreetName'" dp-error-validator
                               maxlength="255" uppercased
                               [(ngModel)]="teranetSearchRequest.streetName"
                        />
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="unitNo">Unit No.</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="unitNo" id="unitNo" maxlength="255"
                               [(ngModel)]="teranetSearchRequest.unitNumber"/>
                    </div>
                </div>
            </div>


            <div class="form-group col-lg-12">
            <span class="radio" (click)="onSearchOptionClick('SEARCH_BY_NAME')">
                      <input class="form-control" type="radio" name="searchType" [(ngModel)]="teranetSearchRequest.searchType"
                             value="SEARCH_BY_NAME" />
                      <label class="control-label col-lg-2 text-left" style="display: initial">Search by name:</label>
            </span>
            </div>
            <div [dp-read-only-block]="!isSearchOptionEnabled('SEARCH_BY_NAME')" [elementRefComp]="elementRef">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="lastName">Last (or Corporate) Name</label>
                    <div class="col-lg-10">
                        <input type="text" class="form-control" name="lastName" id="lastName" maxlength="50"
                               [fieldKey]="'teranet.searchParcelRegister.missingLastName'" dp-error-validator
                               uppercased [(ngModel)]="teranetSearchRequest.lastName"/>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="firstName">First Name</label>
                    <div class="col-lg-10">
                        <input type="text" class="form-control" name="firstName" id="firstName" maxlength="50"
                               uppercased [(ngModel)]="teranetSearchRequest.firstName"/>
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-12" *ngIf="isSearchNameOptions && isSearchOptionEnabled('SEARCH_BY_NAME')">
                <label class="control-label col-lg-2" for="searchNameOptions">Edit above or Select a different name to search</label>
                <div class="col-lg-10 margin-top-10" >
                    <select
                        id="searchNameOptions"
                        name="searchNameOptions"
                        class="form-control"
                        [(ngModel)]="selectedVendorForSearch"
                        (ngModelChange)="dataSelectedSearchName($event)">
                        <option *ngFor="let vendor of vendors" [ngValue]="vendor">
                            {{vendor.fullOrBusinessName.toUpperCase()}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group col-lg-12">
                <span class="radio display-inline" (click)="onSearchOptionClick('SEARCH_BY_PIN')">
                          <input class="form-control" type="radio" name="searchType" [(ngModel)]="teranetSearchRequest.searchType"
                                 value="SEARCH_BY_PIN" />
                          <label class="control-label col-lg-2 text-left">Search by PIN:</label>
                </span>
            </div>
            <div [dp-read-only-block]="!isSearchOptionEnabled('SEARCH_BY_PIN')" [elementRefComp]="elementRef">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="pin">PIN</label>
                    <div class="col-lg-4">
                        <p-autoComplete id="pin" name="pin" [(ngModel)]="teranetSearchRequest.pin" tabIndexIgnore dp-pinFormat [persistFormattedPin] = "true"
                                        [suggestions]="propertyPins" maxlength="10"
                                        (completeMethod)="updatePinList($event)"
                                        [fieldKey]="'teranet.searchParcelRegister.invalidPin'" dp-error-validator
                                        [minLength]="1" [dropdown]="isPinDropdown"  (onDropdownClick)="handlePinDropdownClick()"
                                        (focusout)="validatePinFormat()"
                                        class="dropdown-arrow dropdown-arrow-refferedBy">
                            <ng-template let-pin pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                      <span class="omnibarLi100">
                                          {{pin}}
                                      </span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </div>

            <div class="form-group col-lg-12">
            <span class="radio display-inline" (click)="onSearchOptionClick('SEARCH_BY_INSTRUMENT')">
                      <input class="form-control" type="radio" name="searchType" [(ngModel)]="teranetSearchRequest.searchType"
                             value="SEARCH_BY_INSTRUMENT" />
                      <label class="control-label col-lg-3 text-left">Search by Instrument No:</label>
            </span>
            </div>
            <div [dp-read-only-block]="!isSearchOptionEnabled('SEARCH_BY_INSTRUMENT')" [elementRefComp]="elementRef">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="instrumentNo">Instrument No.</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="instrumentNo" id="instrumentNo" maxlength="11"
                               [fieldKey]="'teranet.searchParcelRegister.missingInstrument'" dp-error-validator
                               uppercased [(ngModel)]="teranetSearchRequest.instrumentNumber"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="searchParcelRegister()"
                        [disabled]="isSearchButtonDisabled"
                        class="dp-btn keyboard-save-action">
                    <span>Search</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

        <div class="col-xs-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>


</form>
