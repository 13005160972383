<!-- modal -->
<form class="form-horizontal" id="dp-tax-rates">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1 *ngIf="isOpenFromStatementOfAdjustment()">{{context.taxRateType}} Rate for Sale Price and Rebate related adjustments</h1>
                <h1 *ngIf="isOpenFromStatementOfAccount()">Tax Rate for Statement of Account</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-lg-12">
            <form class="form-horizontal">
                <div class="form-group">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <div class="col-lg-12 vertical-align-label">
                                <label class="control-label text-center" *ngIf="isOpenFromStatementOfAdjustment()" >
                                    The rate selected will be applied to the Sale Price, the {{isProvinceNSOrNB ? context.taxRateType : 'GST/'+context.taxRateType}}, the  {{isProvinceNSOrNB ? context.taxRateType : 'GST/'+context.taxRateType}} Rebate and any Additional Consideration
                                    being factored into the  {{isProvinceNSOrNB ? context.taxRateType : 'GST/'+context.taxRateType}} Rebate calculation. For any other newly-added adjustment, the selected rate will
                                    serve as the default-but may be overridden for any particular adjustment</label>
                                <label class="control-label text-center" *ngIf="isOpenFromStatementOfAccount()" >The rate selected will be applied to Statement of Account</label>
                            </div>
                        </div>
                        <div class="form-group col-lg-12 text-center" *ngIf="isNotConfirmBox()">
                            <ul class="clear">
                                <li *ngFor="let taxRateSlab of taxRateSlabs; let i= index">
                                    <span class="radio">
                                        <input type="radio"
                                               id="taxRateSlab_{{i}}"
                                               name="taxRateSlab"
                                               [value]="taxRateSlab"
                                               (change)="updateTaxRateSlab(taxRateSlab)"
                                               [(ngModel)]="considerationTaxRateSlab"
                                        >
                                        <label *ngIf="isOpenFromStatementOfAdjustment()" tabindex="0" class="focus-first" for="taxRateSlab_{{i}}">
                                            {{getStatementOfAdjustmentTaxRateLabel(taxRateSlab)}}
                                        </label>
                                        <label *ngIf="isOpenFromStatementOfAccount() " tabindex="0" class="focus-first" for="taxRateSlab_{{i}}">
                                            {{getStatementOfAccountTaxRateLabel(taxRateSlab)}}
                                        </label>

                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div class="form-group col-lg-12 padding-top-10" *ngIf="!isNotConfirmBox()">
                            <div class="col-lg-3 vertical-align-label">
                                <label class="control-label" >Confirm the rate of {{context.taxRateType}}/GST to be applied to the statement of Adjustments:</label>
                            </div>
                            <div class="col-lg-3">
                                <select type="text"
                                        id="hstGst"
                                        name="hstGst"
                                        class="form-control"
                                        [(ngModel)]="adjustmentHst"
                                        (ngModelChange)="updateTaxRate($event)">
                                    <option *ngFor="let taxRateSlab of taxRateSlabs" [value]="taxRateSlab.hstRate">
                                        {{getSoaConfirmBoxTaxRateLabel(taxRateSlab)}}
                                    </option>
                                </select>
                                <!-- Remove following link after testing user story 2022 -->
                                <!--<a href="javascript:void(0);" (click)="deleteDocumentProfile()">delete document profile (for test, removed later on)</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn">
                <span>Save</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</form>



