import {Component, Inject, OnInit} from '@angular/core';
import {Precedent} from '../../shared/precedent';
import {PrecedentService} from '../../mortgages/mortgage/precedent/precedent.service';
import {PrecedentTypes} from '../../shared/mortgage-precedent-type';
import Utils from '../../../shared-main/utils';
import {User} from '../../shared';
import {BulkSharing} from './bulk-sharing';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class ShareDocumentsModalContext {
  documentShareMode: string;
  bulkSharing: BulkSharing;
  projectName: string;
}

@Component({
  selector: 'dp-share-documents-modal',
  templateUrl: 'share-documents-modal.component.html'
})
export class ShareDocumentsModalComponent extends ModalComponent<ShareDocumentsModalContext> implements OnInit {
  messagePrecedents: Precedent[] = [];
  selectedPrecedent: any;
  message: string;
  header: string = '';

  constructor(
    public dialog: MatDialogRef<ShareDocumentsModalComponent>,
    public messagePrecedentService: PrecedentService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ShareDocumentsModalContext
  ) {
    super(dialog, context);
  }

  ok(): void {
    if (this.isShareDocumentsModeProjectDocSharing()) {
      this.notify();
    } else if (this.isShareDocumentsModeBulkSharing()) {
      this.share();
    } else {
      this.close();
    }
  }

  share() {
    this.context.bulkSharing.sharingMessageText = this.message;
    this.dialog.close({bulkSharing: this.context.bulkSharing});
  }

  notify() {
    this.dialog.close({projectDocSharingMessage: this.message});
  }

  close() {
    this.dialog.close();
  }

  isShareDocumentsModeBulkSharing(): boolean {
    return this.context.documentShareMode == 'BULK_SHARING';
  }

  isShareDocumentsModeProjectDocSharing(): boolean {
    return this.context.documentShareMode == 'PROJECT_DOC_SHARING';
  }

  ngOnInit() {
    if (this.isShareDocumentsModeBulkSharing()) {
      this.header =
        "Selecting 'Share' below will make the selected documents available to the other side Lawyers and Law Clerks on the selected matters.\n" +
        'A notification will be sent to them using the message entered below.';
    } else if (this.isShareDocumentsModeProjectDocSharing()) {
      this.header =
        "Other side's legal representatives, with whom documents have previously been shared, will be notified that there are new,  updated or revoked common documents for this project " +
        (this.context.projectName ? this.context.projectName : '');
    }
    this.getMessagePrecedents();
    this.updateEditorMessage();
  }

  async getMessagePrecedents(): Promise<void> {
    //Get precedents from backend
    let precedents: Precedent[] = await this.messagePrecedentService.getPrecedents().toPromise();
    if (precedents) {
      this.messagePrecedents = precedents.filter((precedent) => precedent.precedentType === PrecedentTypes.MESSAGE);
    }
  }

  updateEditorMessage(): void {
    this.message = '';
    if (this.selectedPrecedent) {
      let messagePrecedent = this.getSelectedPrecedent();
      if (messagePrecedent) {
        this.message = messagePrecedent.description;
      }
    } else {
      if (this.isShareDocumentsModeProjectDocSharing()) {
        this.message = 'We are notifying you that the set of common documents for the project has changed.';
      }
    }
    this.message += this.unityMessageBlock;
  }

  getSelectedPrecedent() {
    return this.messagePrecedents.find((item) => item.id == this.selectedPrecedent);
  }

  get unityMessageBlock(): string {
    let sessionUser: User = Utils.getUserFromSession();
    return '\n\n' + sessionUser.unitySignatureBlock;
  }
}
