<!-- modal -->
<form class="form-horizontal" >
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10">Email Invitee</div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body min-height-400">
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Invitee</label>
            </div>
            <div class="col-lg-8">
                <dp-multi-select [multiSelectData]="eventInvitees"
                                 [showLabelInTitle]="true"
                                 [inputName]="'Selected Invitees'"
                                 [title]="'No invitees selected'"
                                 [multiSelectedTitle]="selectedInviteeList"
                                 [defaultToAll]="false"
                                 [multiSelectDataSelected]="getMultiSelectDataSelected()"
                                 (updateMultiSelect)="updateInviteeSelection($event)"
                                 class="invitee-multi-select">
                </dp-multi-select>

            </div>
        </div>
        <div class="form-group" >
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Message Precedent</label>
            </div>
            <div class="col-lg-8">
                <select class="form-control" id="messagePrecedents"  name="messagePrecedents"
                        [(ngModel)]="seletedPrecedentId"
                        (ngModelChange)="updatePrecedent($event)"
                       >
                    <option value=""></option>
                    <option *ngFor="let precedent of messagePrecedents" [value]="precedent.id">
                        {{precedent.precedentName}}
                    </option>
                </select>
            </div>
        </div>


        <ng-container *ngIf="selectedPrecedent">
            <div class="form-group"  statusBarHelp tabindex="0" >
                <div class="col-lg-2 vertical-align-label">
                    <!--<label class="control-label text-right">Precedent Text</label>-->
                </div>

                <div class="col-lg-8 precedent-text">
                    <textarea name="precedentText" id="precedentText" rows="30" cols="80"
                              [dp-read-only]="true"  [innerHTML]="precedentText"></textarea>
                </div>
            </div>

        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"  [disabled]="!selectedInvitees || selectedInvitees && !selectedInvitees.length" id="okBtnSave" class="dp-btn" (click)="initiateEmailForInvitee()">
                <span>Send</span>
            </button>

            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
