import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {EFormType} from './alto-eform-request';

export class AltoEFormStaleFlag extends BaseEntity {

  id: number;

  eFormType: EFormType;

  unityDataUpdatedDate: string;

}
