<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="soAdjCreditVendorTaxRebate">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{getModalTitle()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Adjustment heading</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="adjustmentHeading"
                           name="adjustmentHeading"
                           class="form-control"
                           dp-error-validator
                           fieldKey="matter.creditVendorTaxRebate.modal.adjustmentHeading"
                           [(ngModel)]="soAdjCreditVendorTaxRebate.adjustmentHeading"
                    />
                </div>
            </div>
        </div>
        <div class="form-group" >
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Amount</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="amount"
                           name="amount"
                           dp-read-only="true"
                           dp-currency
                           class="form-control"
                           [ngModel]="soAdjCreditVendorTaxRebate.amount"
                    />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"  dp-province-field-label="matter.soa.vendorTaxRebate.adjustFor">Adjust for</label>
                </div>
                <ng-container *ngIf="context.matter.isMatterProvinceON">
                    <div class="col-lg-2" >
                        <select type="text"
                                id="adjustedFor"
                                name="adjustedFor"
                                class="form-control"
                                [(ngModel)]="soAdjCreditVendorTaxRebate.adjustedFor"
                                (change)="adjustTaxRatePercentage()"
                        >
                            <option *ngFor="let adjustedFor of adjustedForDropDown" [value]="adjustedFor.value">
                                {{adjustedFor.label}}
                            </option>
                        </select>
                    </div>
                </ng-container>

                <div class="col-lg-2 text-left">
                    <span *ngIf="context.matter.isMatterProvinceON" class="inline-block width-3percent;">?</span>
                    <select type="text"
                            id="format1"
                            name="format1"
                            class="form-control inline-block margin-left-5 width-91percent"
                            [(ngModel)]="soAdjCreditVendorTaxRebate.isAdjusted"
                    >
                        <option *ngFor="let isAdjusted of isAdjustedDropDown"
                                [value]="isAdjusted.value">
                            {{isAdjusted.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="isPercentageVisible()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{getPercentageLabel()}}</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="percentage"
                           name="percentage"
                           class="form-control"
                           [(ngModel)]="soAdjCreditVendorTaxRebate.percentage"
                           dp-percentage
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Show adjustment as</label>
                </div>
                <div class="col-lg-5">
                    <select type="text"
                            id="showAdjustmentAs"
                            name="showAdjustmentAs"
                            class="form-control"
                            [(ngModel)]="soAdjCreditVendorTaxRebate.showAdjustmentAs"
                    >
                        <option *ngFor="let showAdjustmentAs of showAdjustmentAsDropDown"
                                [value]="showAdjustmentAs.value">
                            {{showAdjustmentAs.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    >{{soAdjCreditVendorTaxRebate.adjustmentHeading}}</label>
                </div>
            </div>

            <div class="col-lg-12  form-group" *ngIf="context.matter.isMatterProvinceON" >
                <div class="col-lg-4">
                    <label class="control-label"
                    >HST Rebate Federal Portion</label>
                </div>
                <div class="col-lg-6">
                    <label class="control-label col-lg-4 text-right">
                        {{(salePriceAdjustment ? salePriceAdjustment.calculateFederalRebatePortion(federalHstRate, context.salePrice) : 0) |
                        currency:CAD:'symbol' }}
                    </label>
                </div>
            </div>
            <div class="col-lg-12  form-group" *ngIf="context.matter.isMatterProvinceON">
                <div class="col-lg-4">
                    <label class="control-label"
                    >HST Rebate Provincial Portion</label>
                </div>
                <div class="col-lg-6" >
                    <label class="control-label col-lg-4 text-right">
                    {{(salePriceAdjustment ? salePriceAdjustment.calculateOntarioRebatePortion(provinceHstRate, context.salePrice) : 0) |
                    currency:CAD:'symbol' }}
                    </label>
                </div>
            </div>
            <div class="col-lg-12  form-group">
                <div class="col-lg-4">
                    <label class="control-label"
                    >Amount</label>
                </div>
                <div class="col-lg-6" >
                    <label class="control-label col-lg-4 text-right">
                    {{(salePriceAdjustment ? salePriceAdjustment.totalRebatePortion(provinceHstRate, federalHstRate, context.salePrice): 0) |
                    currency:CAD:'symbol'}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12  form-group" *ngIf="isPercentageVisible()">
                <div class="col-lg-4">
                    <label class="control-label"
                    >{{soAdjCreditVendorTaxRebate.getTotalRebatePortionTaxLabel()}}</label>
                </div>
                <div class="col-lg-6" >
                    <label class="control-label col-lg-4 text-right">
                    {{(salePriceAdjustment ?
                        salePriceAdjustment.calculateTotalRebatePortionTax(provinceHstRate, federalHstRate, context.salePrice,
                        soAdjCreditVendorTaxRebate.isAdjusted === 'YES'?soAdjCreditVendorTaxRebate.percentage:null): 0) |
                    currency:CAD:'symbol'}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12  form-group">
                <div class="col-lg-4">
                    <label class="control-label"
                    >{{getCreditVendorLabel()}}</label>
                </div>
                <div class="col-lg-6">
                    <label class="control-label text-right"
                           [ngClass]="creditVendorTaxRebatePosition() == 2 ? 'col-lg-2' : (creditVendorTaxRebatePosition() == 6 ? 'col-lg-6':'col-lg-4')">
                    {{(salePriceAdjustment ? salePriceAdjustment.totalRebatePortionWithTax(provinceHstRate,federalHstRate,context.salePrice,
                        soAdjCreditVendorTaxRebate.isAdjusted === 'YES'?soAdjCreditVendorTaxRebate.percentage:null): 0) |
                    currency:CAD:'symbol'}}
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button *ngIf="!isAdded && allowDelete" type="button" class="secondary-button" (click)="delete()">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <div style="padding: 5px 10px 0px 5px;">
        <dp-modal-error #modalErrorComponent></dp-modal-error>
    </div>
</form>
