import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {ItemizedCreditToVendorPurchaserItem} from '../sale-price-adjustment/itemized-credit-to-vendor-purchaser-item';
import {ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD, ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT} from '../../../shared-main/constants';

//for Itemized Credits to Vendor/Purchaser
export class StatementAdjustmentDisplayItemizedCreditBuilder {

  static getDisplayItems(statementAdjustment: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder, federalHstRate: number, provinceHstRate: number): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    if (statementAdjustment && statementAdjustment.soAdjItemizedCreditToVendorPurchaser && statementAdjustment.soAdjItemizedCreditToVendorPurchaser.creditItems) {
      const creditItems: ItemizedCreditToVendorPurchaserItem [] =
        statementAdjustment && statementAdjustment.soAdjItemizedCreditToVendorPurchaser && statementAdjustment.soAdjItemizedCreditToVendorPurchaser.creditItems;
      //mimic the implementation of deposit adjustments for handling too many items
      if (creditItems.length >= ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD) {
        //display the first 8 items
        creditItems.slice(0, ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT).forEach(creditItem => {
          sadItemDetails.push(mainBuilder.getNewItemLine(creditItem.description, mainBuilder.getCurrency(creditItem.amount, 2, true)));
        });
        //display three lines of dot
        [ 1, 2, 3 ].forEach(_ => sadItemDetails.push(mainBuilder.getNewItemLine(`.`, ``)));
        //display the last item
        const lastItem = creditItems[ creditItems.length - 1 ];
        sadItemDetails.push(mainBuilder.getNewItemLine(lastItem.description, mainBuilder.getCurrency(lastItem.amount, 2, true)));
      } else {
        statementAdjustment.soAdjItemizedCreditToVendorPurchaser.creditItems.forEach(creditItem => {
          sadItemDetails.push(mainBuilder.getNewItemLine(creditItem.description, mainBuilder.getCurrency(creditItem.amount, 2, true)));
        });
      }
    }

    let totalCredits = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.total();

    // let divizer = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.divizer();
    if (statementAdjustment.soAdjItemizedCreditToVendorPurchaser.divideTotal != 'NO') {
      sadItemDetails.push(mainBuilder.getNewItemLine('Total Credits', mainBuilder.getCurrency(totalCredits)));
      let lessHstComponentLabel: string = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.getLessHstComponentLabel(mainBuilder.currencyPipe);
      let lessHstComponentAmount: string = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.getLessHstComponentAmount(mainBuilder.currencyPipe, federalHstRate, provinceHstRate);
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ lessHstComponentLabel }`, lessHstComponentAmount));
    }
    sadItemDetails.push(mainBuilder.getNewItemLine(statementAdjustment.soAdjItemizedCreditToVendorPurchaser.getCreditNoteHeading(), ''));

    return sadItemDetails;
  }
}
