import {Injectable} from '@angular/core';
import {HttpClient} from '../core/httpClient.service';
import {Observable} from 'rxjs';
import {matterApi} from '../matters/shared/matter-api';
import {ParagraphTemplate} from '../matters/shared/paragraph-template';

@Injectable()
export class ParagraphTemplateService {
  private static readonly PARAGRAPH_TEMPLATES: string = 'ParagraphTemplates';

  constructor(private http: HttpClient) {
  }

  getParagraphTemplates(): Observable<ParagraphTemplate[]> {
    let url: string = `${ matterApi.paragraphTemplates }`;
    return this.http.get(url)
    .map((res) => {
      let paragraphTemplates: ParagraphTemplate[] = [];
      let data = res[ ParagraphTemplateService.PARAGRAPH_TEMPLATES ];
      data.forEach(item => {
        paragraphTemplates.push(new ParagraphTemplate(item));
      });
      return paragraphTemplates;
    });
  }

  getParagraphTemplateByGender(gender: string): Observable<ParagraphTemplate> {
    //As currently backend doesn't have template for blank gender so returning the template for male for now.
    if (gender === 'QUESTION') {
      gender = 'MALE';
    }

    return this.getParagraphTemplates().map((paragraphTemplates: ParagraphTemplate[]) => {
      let paragraphTemplate: ParagraphTemplate = paragraphTemplates.find((p: ParagraphTemplate) => {
        return p.gender === gender;
      });

      return paragraphTemplate;
    });
  }
}
