<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{context.thirdParty}} Login</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div class="col-lg-12">
            <div class="col-lg-12 text-center boldfont padding-bottom-30 padding-top-10">
                Please enter your {{context.thirdParty}} credentials to proceed.
            </div>
        </div>

        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label col-lg-4">User Name</label>
                <div class="col-lg-6">
                    <input type="text" class="form-control" [(ngModel)]="context.userName" trimmed />
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label col-lg-4">Password</label>
                <div class="col-lg-6">
                    <input type="password" class="form-control" [(ngModel)]="context.password"/>
                </div>
            </div>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn"
                    [disabled]="okBtnDisabled"
                    (click)="ok()">
                <span>Proceed</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
