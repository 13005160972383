import {Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {SelectItem} from 'primeng/api';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import Utils from '../../../../shared-main/utils';
import {SoAdjTenancyPrepaid} from '../../model/so-adj-tenancy-prepaid';
//import {TaxType} from "../../../shared/tax-types";
import {ModalResult} from '../../../../shared-main/enums';
import {StatementOfAdjustmentDisplayItemLine} from '../../model';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {RentInterestRateModalComponent} from './rent-interest-rate.modal.component';
import {tenancyDropDowns} from '../../model/so-adj-drop-downs';
import {RentInterestRate} from '../../model/rent-interest-rate';
import {SoAdjTenancyPrepaidUtil} from '../../model/so-adj-tenancy-prepaid-util';
import {InterestDateCalendarYearItem} from '../../../../shared-main/interest-date-calendar-year-item';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {Matter} from '../../../shared';
import {calculationMethodTypeForPrePaidTenancyOptions} from '../../../../shared-main/province-based-dropdowns';
import {TabsService} from '../../../../core';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {DocumentProfile} from '../../../../admin/document-profile/document-profile';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class TenancyPrepaidModalContext {
  closingDate: string;
  adjustment: SoAdjTenancyPrepaid;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  defaultHstRate: number;
  defaultGstRate: number;
  defaultPstRate: number;
  viewContainerRef: ViewContainerRef;
  currentMatter: Matter;
  soaConsiderationTaxes: ConsiderationTaxes;
  sourceFromProject: boolean;
  docProfile: DocumentProfile;
}

@Component({
  selector: 'dp-tenancy-prepaid-modal-content',
  templateUrl: 'tenancy-prepaid.modal.component.html',
  providers: [ErrorService, DialogService]
})
export class TenancyPrepaidModalComponent extends ModalComponent<TenancyPrepaidModalContext> implements OnInit {
  context: TenancyPrepaidModalContext;

  rentInterestRates: RentInterestRate[] = [];
  localAdj: SoAdjTenancyPrepaid;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  taxTypes: SelectItem[];
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  unitOccupiedTypes: SelectItem[] = tenancyDropDowns.tenantUnitOccupiedTypes;
  calculationMethodType: SelectItem[];
  printingFormatType: SelectItem[] = tenancyDropDowns.printingFormatType;
  closingDateLabel: string = '';
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TenancyPrepaidModalComponent>,
    public dialogService: DialogService,
    public tabService: TabsService,
    @Inject(MAT_DIALOG_DATA) context?: TenancyPrepaidModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.localAdj);
    }
  }

  checkForErrors() {
    /*DPPMP-2335
        IF <Date from which interest is calculated> IS Not a valid Date when VISIBLE THEN Display Error: "The commencement date is invalid"
        IF <Date from which interest is calculated> IS VISIBLE AND <Date from which interest is calculated> Greater Than or Equal to <Adjustment Date> THEN
        Display Error: "The commencement date must be less than the Adjustment date"
     */
    if (
      this.localAdj.interestCalculatedFromDate &&
      this.localAdj.isNotValidDate(this.localAdj.interestCalculatedFromDate)
    ) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.prepaid.from.date.INVALID');
    }
    if (this.localAdj.receiveCreditWithInterestBoolean && this.localAdj.performInterestCalculationBoolean) {
      //<Date from which interest is calculated> IS  VISIBLE
      if (this.localAdj.isNotValidDate(this.localAdj.interestCalculatedFromDate)) {
        this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.prepaid.from.date.INVALID');
      } else {
        if (this.localAdj.getDateDiff(this.localAdj.interestCalculatedFromDate, this.context.closingDate) <= 0) {
          this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.prepaid.from.date.greater.adj');
        }
      }
    }
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  ngOnInit() {
    this.dialogService.tabStateService = this.tabService;
    this.taxTypes = Utils.loadTaxSelectItem(this.context.soaConsiderationTaxes.rateType, this.context.defaultPstRate);
    this.calculationMethodType = calculationMethodTypeForPrePaidTenancyOptions[this.context.currentMatter.provinceCode];
    if (this.context.adjustment) {
      this.localAdj = new SoAdjTenancyPrepaid(this.context.adjustment);
    } else {
      this.localAdj = new SoAdjTenancyPrepaid();
      this.localAdj.unitOccupied = 'SILENT';
      this.localAdj.areaOccupiedDescription = '';
      this.localAdj.prepaidRentAmount = 0;
      this.localAdj.receiveCreditWithInterest = false;
      this.localAdj.performInterestCalculation = false;
      this.localAdj.calculationMethod =
        this.calculationMethodType.length > 0 ? this.calculationMethodType[0].value : '';
      this.localAdj.printingFormat = 'FINAL_AMOUNT_ONLY';
      this.localAdj.interestAmount = 0;
      this.localAdj.interestRate = 0.0;
      this.localAdj.taxType = this.context.soaConsiderationTaxes.provinceDefaultTaxRate;
      this.localAdj.applyTax = false;
      this.localAdj.applyPst = false;
      this.localAdj.hstRate = this.context.defaultHstRate;
      this.localAdj.gstRate = this.context.defaultGstRate;
      this.localAdj.pstRate = this.context.defaultPstRate;
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  onDateChange(event: any, dateType: string): void {
    this.localAdj[dateType] = event.rawDate;
    this.clearCachedDetails();
  }

  /*    getDateToSave(event: any) : string {
          let date : string;
          date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;
          return date;
      }*/

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0) {
      this.cachedDetails = this.context.statementAdjustmentDisplayUtil.getDisplayItems(
        this.localAdj,
        this.context.closingDate,
        true
      );
      this.cachedDetails.pop(); // remove last one about credit (it has another formatting on main "Statement of Adjustment" screen)
      this.updateCalculatedInterest();
    }
    return this.cachedDetails;
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  updateInterestRateFromSOAProfile() {
    let rentInterestRateFromSoaProfile =
      this.context.docProfile.statementOfAdjustmentsProfile.interestRateOnPrepaidRent;
    if (rentInterestRateFromSoaProfile && this.localAdj.interestRate != rentInterestRateFromSoaProfile) {
      this.localAdj.interestRate = rentInterestRateFromSoaProfile;
    }
  }

  generateF9HelpInterestRate() {
    let rentInterestRateFromSoaProfile =
      this.context.docProfile.statementOfAdjustmentsProfile.interestRateOnPrepaidRent;
    return (
      'F9 = ' +
      rentInterestRateFromSoaProfile +
      '%.  Note: to change the default percentage which will be inserted using the F9 key, Edit on the SOA Prefs Tab of the Document Profile'
    );
  }

  viewInterestRateTable() {
    this.dialogService.matDialogContent(
      {
        content: RentInterestRateModalComponent,
        context: {
          rentInterestRate: this.context.statementAdjustmentDisplayUtil.rentInterestRates
        },
        modalGrid: 5
      },
      this.context.viewContainerRef
    );
  }

  updateCalculatedInterest(): void {
    if (this.localAdj.receiveCreditWithInterestBoolean) {
      if (this.localAdj.performInterestCalculationBoolean) {
        let items: InterestDateCalendarYearItem[] = [];
        if (this.localAdj.calculationMethod == 'FLAT_INTEREST_RATE') {
          items.push(
            ...SoAdjTenancyPrepaidUtil.calculateRatesForFlatInterestRate(
              this.localAdj.interestCalculatedFromDate,
              this.context.closingDate,
              this.localAdj.interestRate,
              this.localAdj.prepaidRentAmount,
              this.context.currentMatter.isPaysForDateOfClosingVendor
            )
          );
        } else {
          items.push(
            ...SoAdjTenancyPrepaidUtil.calculateRatesBasedOnOntarioResidentialTenanciesAct(
              this.localAdj.interestCalculatedFromDate,
              this.context.closingDate,
              this.localAdj.prepaidRentAmount,
              this.context.statementAdjustmentDisplayUtil.rentInterestRates,
              this.context.currentMatter.isPaysForDateOfClosingVendor
            )
          );
        }
        this.localAdj.calculatedInterest = 0;
        items.forEach((itm) => (this.localAdj.calculatedInterest += itm.rateAmount));
      }
    }
  }

  validCalculations(): boolean {
    if (this.localAdj.receiveCreditWithInterestBoolean && this.localAdj.performInterestCalculationBoolean) {
      if (Utils.isNotValidDate(this.localAdj.interestCalculatedFromDate)) {
        return false;
      }
      return this.localAdj.getDateDiff(this.localAdj.interestCalculatedFromDate, this.context.closingDate) >= 0;
    }
    return true;
  }

  get adjustmentDescription(): string {
    let result: string = provinceBasedFieldLabels
      .get('matter.soa.prePaidRent', this.context.currentMatter.provinceCode)
      .toUpperCase();
    if (
      this.localAdj.unitOccupied == 'SPECIFY' &&
      this.localAdj.areaOccupiedDescription &&
      this.localAdj.areaOccupiedDescription.length > 0
    ) {
      result += ' - ' + this.localAdj.areaOccupiedDescription;
    }
    return result;
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }
}
