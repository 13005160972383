import {Component, Inject, OnInit} from '@angular/core';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class SimpleCredentialsModalContext {
  thirdParty: string;
  userName: string;
  password: string;
}

@Component({
  selector: 'dp-simple-credentials-modal-content',
  templateUrl: 'simple-credentials.modal.component.html',
  providers: [ErrorService]
})
export class SimpleCredentialsModalComponent extends ModalComponent<SimpleCredentialsModalContext> implements OnInit {
  constructor(
    public dialog: MatDialogRef<SimpleCredentialsModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: SimpleCredentialsModalContext
  ) {
    super(dialog, context);

    // ToDo Remove this after ALTO credentials requirements are completed
    this.context.thirdParty = 'ALTO';
  }

  ngOnInit(): void {}

  get okBtnDisabled(): boolean {
    return !this.context.userName || !this.context.password;
  }

  ok(): void {
    this.dialog.close(this.context);
  }

  close(): void {
    this.dialog.close();
  }
}
