import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';

export class SoAdjAmountPaidOnInterimClosing extends BaseEntity {
  id: number;
  adjustmentHeading: string;
  creditPurchaserWith: string;

  // infoOnly only for UI, after close modal, it will add it to SOA
  infoOnly: boolean;
}
