<div id="mortgageTermForm" *ngIf="mortgageTerm">
    <div class="form-group" >
        <ng-container *dpShowByProvince="'matter.mortgage.term.regularPayments'">
          <div class="col-lg-2 vertical-align-label">
              <label class="control-label">Regular Payments?</label>
          </div>
          <div class="col-lg-4">
              <div class="col-lg-3 padding-left-0">
                  <select
                          class="form-control"
                          name="regularPayments" id="regularPayments"
                          [(ngModel)]="mortgageTerm.regularPayments"
                          (change)="notifyChange(); updateRegularPayments();">
                      <option *ngFor="let item of yesNoItemsRegularPayments" [value]="item.value">{{item.label}}</option>
                  </select>
              </div>
          </div>
        </ng-container>

        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Principal</label>
        </div>
        <div class="col-lg-2">
            <input type="text" name="principal" id="principal" class="form-control" [(ngModel)]="mortgageTerm.principal"
                   (blur)="mortgagePrincipalOnBlur();"
                   dp-default-currency
                   dp-currency
                   [fieldKey]="'mortgage.term.principal'"
                   statusBarHelp
                   (keyup)="notifyChange()" (keydown.f9)="captureF9ForPrincipal($event)"/>
        </div>
        <div class="col-lg-2" *ngIf="isNetAdvanceVisible()">
            <a tabindex="0" href="javascript: void(0)" (click)="openNetAdvanceModal();"><span>Net&nbsp;Advance=</span><span
                    *ngIf="mortgage.mortgageTerm.calculationMethod==='MANUAL_EDIT' || mortgage.mortgageTerm.calculationMethod==null || mortgage.mortgageTerm.calculationMethod==''">Manual&nbsp;Entry</span><span
                    *ngIf="mortgage.mortgageTerm.calculationMethod==='FULL_AMOUNT'" class="display-block">Principal&nbsp;Amount</span><span
                    *ngIf="mortgage.mortgageTerm.calculationMethod==='SPECIFY_AMOUNT' || mortgage.mortgageTerm.calculationMethod==='CALCULATE_ADVANCE'" class="display-block"
            >{{mortgage.mortgageTerm.totalNetAdvance | currency:CAD:'symbol' }}</span><span
                    *ngIf="mortgage.mortgageTerm.calculationMethod==='NO_ADVANCE'">Nil</span></a>
        </div>
    </div>
    
    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label"> Alternate Principal Wording</label>
      </div>
      <div class="col-lg-4 ">
        <input type="text"
          id="alternatePrincipalWording" 
          class="form-control" 
          name="alternatePrincipalWording" 
          maxlength="45" 
          [(ngModel)]="mortgageTerm.alternatePrincipalWording"
          (ngModelChange)="enableSave()"
          trimmed/>
      </div>
    </div>

    <div class="row border-bottom my-3 mx-1" *ngIf="matter.isMatterProvinceBC "></div>
    
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Interest only?</label>
        </div>
        <div class="col-lg-4">
            <div class="col-lg-3 padding-left-0">
                <select
                        class="form-control"
                        name="interestOnly" id="interestOnly"
                        [(ngModel)]="mortgageTerm.interestOnly"
                        (change)="interestOnlyChanged()">
                    <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">{{getVariableRateLabel(mortgageTerm)}}</label>
        </div>
        <div class="col-lg-2" *ngIf="mortgageTerm.variableInterestRate !== 'YES'">
            <input
                    type="text"
                    id="Interest" #interest
                    name="Interest"
                    class="form-control"
                    [ngStyle]="{display:displayNumber()}"
                    (keyup)="updatePercentageValue($event)"
                    (blur)="interestValueChanged()"
                    [(ngModel)]="mortgageTerm.interest"
                    [fieldKey]="'mortgage.term.interest'"
                    statusBarHelp
                    (keydown.f9)="captureF9ForInterestField()"
                    (change)="notifyChange()"
                    dp-percentage
                    max-decimals = 5
            >
            <input [hidden]="true"
                   type="text" #interestNIL
                   id="InterestNIL"
                   name="InterestNIL"
                   class="form-control"
                   readonly="true"
                   value="NIL"
                   [ngStyle]="{display:displayNIL()}"
                   (keydown.f9)="captureF9ForInterestNILField()"
                   (change)="notifyChange()">
        </div>
        <div class="col-lg-4" *ngIf="mortgageTerm.variableInterestRate === 'YES'">
            <input type="text" name="variableRate" maxlength="100" id="variableRate"
                   class="form-control"
                   [(ngModel)]="mortgageTerm.variableRate"
                   (change)="notifyChange()"
                   trimmed
            />
        </div>
        <div class="col-lg-2 vertical-align-label" *ngIf="mortgageTerm.variableInterestRate != 'YES'">
            <label class="control-label text-left">% per annum</label>
        </div>
    </div>
    <div class="form-group">
        <ng-container *dpShowByProvince="'matter.mortgage.term'">
          <div class="col-lg-2 vertical-align-label">
              <label class="control-label">Variable interest rate?</label>
          </div>
          <div class="col-lg-4">
              <div class="col-lg-3 padding-left-0">
                  <select
                          class="form-control"
                          name="variableInterestRate" id="variableInterestRate"
                          [(ngModel)]="mortgageTerm.variableInterestRate"
                          (change)="variableInterestRateChange()">
                      <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                  </select>
              </div>
          </div>
        </ng-container>

        <ng-container *dpShowByProvince="'matter.mortgage.term.interestActStatement'">

          <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Interest Act Statement</label>
          </div>
          <div class="col-lg-4">
            <input type="text"
              name="interestActStatement" 
              id="interestActStatement" 
              class="form-control" 
              trimmed
              maxlength="45"
              [(ngModel)]="mortgageTerm.interestActStatement"
              (ngModelChange)="enableSave()"/>
          </div>
        </ng-container>


        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Calculated</label>
        </div>
        <div class="col-lg-4">

            <p-autoComplete
                [(ngModel)]="mortgageTerm.uiLabel"
                dp-error-validator
                tabIndexIgnore
                [fieldKey]="'mortgage.'+mortgageIndex+'.term.compundingPeriod'"
                [suggestions]="filteredCalculated"
                (completeMethod)="filterCalculated($event)"
                (focusout)="setVariablestoCalculateMortgage()"
                (ngModelChange)="calculatedChanged()"
                [minLength]="1"  [maxlength]="50"
                [dropdown]="true"
                (keydown.space)="utils.handleSpaceKeyDropdown($event, mortgageTerm.calculated, handleDropdownClickCalculated)"
                (keydown.enter)="utils.handleEnterKeyDropdown($event, mortgageTerm.calculated, handleDropdownClickCalculated)"
                (onDropdownClick)="handleDropdownClickCalculated()">
                <ng-template let-calculatedLabel pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix">
                            <span class="omnibarLi100">
                                {{calculatedLabel}}
                            </span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>

    <div class="row border-bottom my-3 mx-1" *ngIf="matter.isMatterProvinceBC "></div>

    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label"> Mortgage Terms</label>
      </div>
      <div class="col-lg-4">
        <select
          class="form-control"
          id="mortgageTermType"
          name="mortgageTermType" 
          [(ngModel)]="mortgageTerm.mortgageTermType"
          (change)="notifyChange()">
          <option *ngFor="let item of mortgageTermsOptions" [value]="item.value">{{item.label}}</option>
        </select>
      </div>

      <ng-container *ngIf="mortgageTerm.mortgageTermType === 'STANDARD' ">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">D F Number</label>
        </div>
        <div class="col-lg-4" >
            <p-autoComplete
                [(ngModel)]="mortgageTerm.sctNo"
                dp-error-validator
                tabIndexIgnore
                [suggestions]="sctNumbers"
                [fieldKey]="'mortgage.term.sctNo'"
                (ngModelChange)="enableSave();notifyChange()"
                [minLength]="1"  [maxlength]="30"
                [dropdown]="isSCTNoAvailable && isLenderFormsNotEmpty"
                (onDropdownClick)=" handleDropdownClickSCTNo($event)">
                <ng-template let-sctNo pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix">
                            <span class="omnibarLi100">
                                {{sctNo}}
                            </span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
      </ng-container>

    </div>

    <div class="form-group" *ngIf="!matter.isMatterProvinceBC && mortgage.loanType == 'ARRANGED'">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Collateral mortgage?</label>
        </div>
        <div class="col-lg-4">
            <div class="col-lg-3 padding-left-0">
                <select
                        class="form-control"
                        name="collateralMortgage" id="collateralMortgage"
                        [(ngModel)]="mortgageTerm.collateralMortgage"
                        maxlength="40"
                        (change)="notifyChange()">
                    <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" *ngIf="mortgageTerm.collateralMortgage == 'YES'">Collateral</label>
        </div>
        <div class="col-lg-4">
            <input type="text" name="collateral" *ngIf="mortgageTerm.collateralMortgage == 'YES'" id="collateral" class="form-control"
                   [(ngModel)]="mortgageTerm.collateral" maxlength="40"
                   (change)="notifyChange()"
                   trimmed
            />
        </div>
    </div>
    
    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label">Additional or Modified Terms </label>
      </div>
      <div class="col-lg-10 ">
        <textarea
          id="additionalOrModifiedTerms" 
          class="form-control" 
          name="additionalOrModifiedTerms" 
          maxlength="500"
          cols="3" 
          [(ngModel)]="mortgageTerm.additionalOrModifiedTerms"
          (ngModelChange)="enableSave()"
          trimmed>
        </textarea>
      </div>
    </div>

    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label">Prior Encumbrances </label>
      </div>
      <div class="col-lg-10 ">
        <textarea
          id="priorEncumbrances" 
          class="form-control" 
          name="priorEncumbrances" 
          maxlength="500"
          cols="3" 
          [(ngModel)]="mortgageTerm.priorEncumbrances"
          (ngModelChange)="enableSave()"
          trimmed>
        </textarea>

      </div>
    </div>
    
    <div class="row border-bottom my-3 mx-1" *ngIf="matter.isMatterProvinceBC "></div>

    <div class="form-group" *ngIf="!matter.isMatterProvinceBC ">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Taxes paid through mtg?</label>
        </div>
        <div class="col-lg-4">
            <div class="col-lg-3 padding-left-0">
                <select
                        class="form-control"
                        name="taxesPaidMtg" id="taxesPaidMtg"
                        [(ngModel)]="mortgageTerm.taxesPaidThroughMortgage"
                        (change)="taxesPaidThroughMortgageChange()">
                    <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" [dp-province-field-label]="'matter.mortgage.term.provinceBasedStandardMortgageFormTitle'">SCT No.</label>
        </div>
        <div class="col-lg-2" *dpShowByProvince="'matter.mortgage.term.sctNo'">
            <p-autoComplete
                [(ngModel)]="mortgageTerm.sctNo"
                dp-error-validator
                tabIndexIgnore
                [suggestions]="sctNumbers"
                [fieldKey]="'mortgage.term.sctNo'"
                (ngModelChange)="enableSave();notifyChange()"
                [minLength]="1"  [maxlength]="30"
                [dropdown]="isSCTNoAvailable && isLenderFormsNotEmpty"
                (onDropdownClick)=" handleDropdownClickSCTNo($event)">
                <ng-template let-sctNo pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix">
                            <span class="omnibarLi100">
                                {{sctNo}}
                            </span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
        
        <div class="col-lg-2" *dpShowByProvince="'matter.mortgage.term.standardMortgageForm'">
            <p-autoComplete
                [(ngModel)]="mortgageTerm.sctNo"
                dp-error-validator
                tabIndexIgnore
                [suggestions]="sctNumbers"
                [fieldKey]="'mortgage.term.sctNo'"
                statusBarHelp
                (keydown.f9)="captureF9ForSctNo()"
                (ngModelChange)="populateFilerInfoForMB()"
                [minLength]="1"  [maxlength]="24"
                [dropdown]="isSCTNoAvailable && isLenderFormsNotEmpty"
                (onDropdownClick)=" handleDropdownClickSCTNoAB($event)">
                <ng-template let-sctNo pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix">
                            <span class="omnibarLi100">
                                {{sctNo}}
                            </span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>

        <div class="col-lg-2 vertical-align-label">
            <label class="control-label text-left"><a (click)="openMortgageUrl()"
                                                      *ngIf="isMortgageeUrlAvailable()">Mortgagee's website</a></label>
        </div>
    </div>
    <div class="form-group">
        <ng-container *dpShowByProvince="'matter.mortgage.term'">
          <div class="col-lg-2 vertical-align-label">
              <label class="control-label">Other</label>
          </div>
          <div class="col-lg-1">
              <select
                      class="form-control"
                      name="other" id="other"
                      [(ngModel)]="mortgageTerm.other"
                      (change)="otherMortgageChange()">
                  <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
              </select>
          </div>
          <div class="col-lg-3">
              <input type="text" name="otherValue" maxlength="30" id="otherValue"
                  class="form-control" [(ngModel)]="mortgageTerm.otherText"
                  [fieldKey]="'mortgage.term.otherText'"
                  statusBarHelp
                  (keydown.f9)="captureF9ForOtherField()" (change)="notifyChange()"
                  trimmed/>
          </div>
        </ng-container>
        <div class="col-lg-2 vertical-align-label" *dpShowByProvince="'matter.mortgage.term.sctFiledBy'">
            <label class="control-label">SCT Filed by</label>
        </div>
        <div class="col-lg-4" *dpShowByProvince="'matter.mortgage.term.sctFiledBy'">
            <input type="text" name="sctFiledBy" maxlength="100" id="sctFiledBy" class="form-control"
                   [(ngModel)]="mortgageTerm.sctFiledBy"
                   (change)="notifyChange()"
                   trimmed/>
        </div>
    </div>
    <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes() && !matter?.isMatterProvinceBC">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Alternate frequency?</label>
        </div>
        <div class="col-lg-1">
            <select
                    class="form-control"
                    name="alternateFrequency" id="alternateFrequency"
                    [(ngModel)]="mortgageTerm.alternateFrequency"
                    (change)="notifyChange();updateAlternateFrequency();">
                <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
            </select>
        </div>

    </div>
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Assignment of Rents?</label>
        </div>
        <div class="col-lg-4">
            <div class="col-lg-3 padding-left-0">
                <select
                        class="form-control"
                        name="rentAssignments" id="rentAssignments"
                        [(ngModel)]="mortgageTerm.assignmentOfRents"
                        (change)="notifyChange()">
                    <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <ng-container *ngIf="matter?.isMatterProvinceBC && mortgageTerm.assignmentOfRents === 'YES'">
          <div class="col-lg-2 vertical-align-label">
            <label class="control-label"> A/R Page and Paragraph Number</label>
          </div>
          <div class="col-lg-4 ">
            <input type="text"
              id="pageParagraphNumber" 
              class="form-control" 
              name="pageParagraphNumber" 
              maxlength="50" 
              [(ngModel)]="mortgageTerm.pageParagraphNumber"
              (ngModelChange)="enableSave()"
              trimmed/>
          </div>
        </ng-container>

        <div class="col-lg-2 vertical-align-label" *dpShowByProvince="'matter.mortgage.term.semiAnnualEquivalent'">
            <label class="control-label">Semi-annual equivalent</label>
        </div>
        <div class="col-lg-2" *dpShowByProvince="'matter.mortgage.term.semiAnnualEquivalent'">
            <input id="semiAnnual" name="semiAnnual"
                   [(ngModel)]="mortgageTerm.semiAnnualEquivalent"
                   [max-int]= 999.99999
                   dp-percentage
                   [max-decimals]= 5
                   [padding-zero]="true"
                   class="form-control"
                   (change)="notifyChange()"/>
        </div>
        <div *dpShowByProvince="'matter.mortgage.term.insuranceAmount'">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Insurance Amount</label>
            </div>
            <div class="col-lg-4">
                <select
                        class="form-control"
                        name="insuranceAmount" id="insuranceAmount"
                        [(ngModel)]="mortgageTerm.insuranceAmount"
                        (change)="notifyChange()">
                    <option *ngFor="let item of insuranceAmountOptions" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group" *dpShowByProvince="'matter.mortgage.term.standardMortgageForm.generalSecurityAgreement'">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">General Security Agreement?</label>
        </div>
        <div class="col-lg-1">
            <select
                    class="form-control"
                    name="generalSecurityAgreement" id="generalSecurityAgreement"
                    [(ngModel)]="mortgageTerm.generalSecurityAgreement"
                    (change)="notifyChange()">
                <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
            </select>
        </div>
    </div>

    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label"> Floating Charge</label>
      </div>
      <div class="col-lg-1">
        <select
          class="form-control"
          id="floatingCharge"
          name="floatingCharge" 
          [(ngModel)]="mortgageTerm.floatingCharge"
          (change)="notifyChange()">
          <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
        </select>
      </div>
    </div>

    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label"> Current or Running Account</label>
      </div>
      <div class="col-lg-1">
        <select
          class="form-control"
          id="currentRunningAccount"
          name="currentRunningAccount" 
          [(ngModel)]="mortgageTerm.currentRunningAccount"
          (change)="notifyChange()">
          <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
        </select>
      </div>
    </div>

    <div class="row border-bottom my-3 mx-1" *ngIf="matter.isMatterProvinceBC "></div>
    
    <div class="form-group" *ngIf="matter.isMatterProvinceBC ">
      <div class="col-lg-2 vertical-align-label">
        <label class="control-label">Place of Payment</label>
      </div>
      <div class="col-lg-10 ">
        <textarea
          id="placeOfPayment" 
          class="form-control" 
          name="placeOfPayment" 
          maxlength="255"
          cols="3" 
          [(ngModel)]="mortgageTerm.placeOfPayment"
          (ngModelChange)="enableSave()"
          trimmed>
        </textarea>

      </div>
      
    </div>

    <div class="form-group margin-top-20" *ngIf="!matter.isMatterProvinceBC">
        <div class="col-lg-6">
            <div class="title">Payments (as registered)</div>
        </div>
        <div class="col-lg-6" *ngIf="mortgageTerm.alternateFrequency == 'YES'">
            <div class="title">Alternate Frequency</div>
        </div>
    </div>

    <div class="form-group col-md-12 margin-left-0 margin-right-0 padding-left-0 padding-right-0" >
    <!-- Regular Payment Section -->
    <div class="col-md-6">

        <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes() && !matter.isMatterProvinceBC ">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Payment Frequency</label>
            </div>
            <div class="col-md-8">
                <p-autoComplete
                        [(ngModel)]="mortgageTerm.paymentFrequency"
                        dp-error-validator
                        tabIndexIgnore
                        [fieldKey]="'mortgage.'+mortgageIndex+'.term.paymentFrequency'"
                        [suggestions]="filteredPaymentFrequency"
                        (completeMethod)="filterPaymentFrequency($event)"
                        (focusout)="setVariablestoCalculateMortgage()"
                        (ngModelChange)="enableSave(); setVariablestoCalculateMortgage();"
                        [minLength]="1"
                        [maxlength]="50"
                        [dropdown]="true"
                        (keydown.space)="utils.handleSpaceKeyDropdown($event, mortgageTerm.paymentFrequency, handleDropdownClick)"
                        (keydown.enter)="utils.handleEnterKeyDropdown($event, mortgageTerm.paymentFrequency, handleDropdownClick)"
                        (onDropdownClick)="handleDropdownClickPaymentFrequency()">
                    <ng-template let-paymentFrequencyLabel pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix">
                            <span class="omnibarLi100">
                                {{paymentFrequencyLabel}}
                            </span>
                        </li>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>

        <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">
                  {{matter.isMatterProvinceBC ? 'Payment Dates' : 'Payments Due'}}
                </label>
            </div>
            <div class="col-md-8">
                <input type="text" name="paymentDue" maxlength="45" id="paymentDue" class="form-control" [(ngModel)]="mortgageTerm.paymentsDue"
                    [dynamicHelpCallBackFunction]="dynamicF9ForpaymentsDueField()"
                    statusBarHelp
                    (keydown.f9)="captureF9ForpaymentsDueField()" (change)="notifyChange()"
                    trimmed/>
            </div>

        </div>

        <div class="form-group">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Interest Adj. Date</label>
            </div>
            <div class="col-md-8">
                <dp-partial-date #idAdjDate (dateChange)="onDateChangeAdjDate($event)"
                                [inputDate]="mortgageTerm.interestAdjustmentDate"
                                [dateParent]="mortgageTerm"
                                [fieldKey]="'mortgage.'+mortgageIndex+'.term.intAdjustmentDate'"
                                [dateFieldName]="'interestAdjustmentDate'"
                                [customF9ExternalDate]="getCustomInterestAdjustmentDate()">
                </dp-partial-date>
            </div>
        </div>

        <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">First Payment Date</label>
            </div>
            <div class="col-md-8">
                <dp-partial-date #idPaymentDate (dateChange)="onDateChangePaymentDate($event)"
                                 [dateParent]="mortgageTerm"
                                 [inputDate]="mortgageTerm.firstPaymentDate"
                                 [fieldKey]="'mortgage.'+mortgageIndex+'.term.firstPaymentDate'"
                                 [dateFieldName]="'firstPaymentDate'"
                                 [customF9ExternalDate]="getCustomFirstPaymentDate()">
                </dp-partial-date>
            </div>

        </div>

        <div class="form-group">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label"  [dp-province-field-label]="'matter.mortgage.term.maturityDate'">
                  Maturity Date
                </label>
            </div>
            <div class="col-md-8">
                <dp-partial-date #idMaturityDate (dateChange)="onDateChangeMaturityDate($event)"
                                [inputDate]="mortgageTerm.maturityDate"
                                [dateParent]="mortgageTerm"
                                [fieldKey]="'mortgage.'+mortgageIndex+'.term.maturityDate'"
                                [dateFieldName]="'maturityDate'"
                                [customF9ExternalDate]="getCustomMaturityDate()">
                </dp-partial-date>
                <label class="control-label" *ngIf="mortagageTermPeriod">Term : {{mortagageTermPeriod}}</label>
            </div>
        </div>

        <div class="form-group" *ngIf="matter.isMatterProvinceBC">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label" >
                  Balance Due Date
                </label>
            </div>
            <div class="col-md-8">
                <dp-partial-date #idBalanceDueDate (dateChange)="onDateChangeMaturityDate($event)"
                                [inputDate]="mortgageTerm.balanceDueDate"
                                [dateParent]="mortgageTerm"
                                [fieldKey]="'mortgage.'+mortgageIndex+'.term.balanceDueDate'"
                                [dateFieldName]="'balanceDueDate'"
                                [customF9ExternalDate]="getCustomMaturityDate()">
                </dp-partial-date>
                <label class="control-label" *ngIf="mortagageTermPeriod">Term : {{mortagageTermPeriod}}</label>
            </div>
        </div>

        <div class="form-group" *ngIf="autoCalcRegularPaymentsAvailable">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Auto-Calculated<br>Regular Payments?</label>
            </div>
            <div class="col-md-8" style="padding-top: 8px;">
                <dp-checkbox fieldId="autoCalRegPayment">
                    <input type="checkbox" id="autoCalRegPayment"
                       (change)="regularPaymentAutoCalculatedChanged()"
                       name="autoCalRegPayment"
                       [disabled]="mortgageTerm.isInterestOnlyYes()"
                       [(ngModel)]="mortgageTerm.regularPaymentAutoCalculated"/>
                </dp-checkbox>
                <label class="control-label text-left padding-top-0">Yes</label>
            </div>
        </div>

        <div class="form-group col-md-12" *ngIf="mortgageTerm.variableInterestRate != 'YES' && getCalculatedDropDownValue(this.mortgageTerm.calculated)!=''">
            <div *ngIf="mortgageTerm.regularPaymentAutoCalculated && !mortgageTerm.isInterestOnlyYes()" style="height:30px">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Amortization Period</label>
                </div>
                <div class="col-lg-2">
                    <input type="text" numberOnly class="form-control inline-block"
                            dp-error-validator
                            [fieldKey]="'mortgage.'+mortgageIndex+'.term.amortization'"
                            [(ngModel)]="mortgageTerm.regularPaymentAmortizationPeriod"
                            (blur)="setVariablestoCalculateMortgage()" (change)="enableSave()"
                            maxlength="3"/>
                </div>
                <div class="col-lg-6 vertical-align-label">
                    <label class="control-label text-left">years</label>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label" [dp-province-field-label]="'matter.mortgage.term.regularPayment'">
                  Regular Payments
                </label>
            </div>
            <div class="col-md-8">
                <input type="text" name="regularPayment" id="regularPayment" class="form-control" *ngIf="!mortgageTerm.regularPaymentAutoCalculated"
                    [(ngModel)]="mortgageTerm.regularPayment"
                    dp-default-currency
                    dp-currency
                    [fieldKey]="'mortgage.'+mortgageIndex+'.term.regularPayment'"
                    statusBarHelp
                    (keyup)="calculateTotal()"
                    (change)="notifyChange()"
                />
                <input type="text" name="regularPaymentCalculated" id="regularPaymentCalculated" class="form-control"
                    *ngIf="mortgageTerm.regularPaymentAutoCalculated"
                    [ngModel]="getRegularPaymentCalculated()"
                    dp-default-currency
                    dp-currency
                    [dp-read-only]="mortgageTerm.regularPaymentAutoCalculated"
                />
            </div>
            <div class="col-md-4 vertical-align-label"  *ngIf="isAmortizationScheduleVisible()">
                <a href="javascript:void(0);" class="control-link" (click)="doMatterSaveAndOpenAmortizationScheduleModal('REGULAR_PAYMENTS');">Amortization Schedule</a>
            </div>
        </div>

        <div class="form-group" *ngIf="matter.isMatterProvinceBC">
          <div class="col-md-4 vertical-align-label">
            <label class="control-label">Taxes paid through mtg?</label>
          </div>
          <div class="col-md-2 padding-left-0">
            <select
              class="form-control"
              name="taxesPaidMtg" id="taxesPaidMtg"
              [(ngModel)]="mortgageTerm.taxesPaidThroughMortgage"
              (change)="taxesPaidThroughMortgageChange()">
                <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
            </select>
          </div>
          
        </div>

        <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes() && mortgageTerm.taxesPaidThroughMortgage == 'YES' && !matter?.isMatterProvinceBC">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Tax Portion</label>
            </div>
            <div class="col-md-8">
                <input type="text" name="taxPortion" id="taxPortion"
                    class="form-control" [(ngModel)]="mortgageTerm.taxPortion"
                    dp-default-currency
                    dp-currency
                    (keyup)="calculateTotal()"
                    (change)="notifyChange()"/>
            </div>
        </div>

        <div class="form-group" *ngIf="mortgageTerm.other == 'YES' && isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">{{mortgageTerm.otherText ? mortgageTerm.otherText : '??????'}}</label>
            </div>
            <div class="col-md-8">
                <input type="text" name="otherPayments"
                    dp-default-currency
                    dp-currency
                    (keyup)="calculateTotal()"
                    id="otherPayments" class="form-control" [(ngModel)]="mortgageTerm.otherPayment"
                    (change)="notifyChange()"/>
            </div>

        </div>
        <div class="form-group" *ngIf="(mortgageTerm.taxesPaidThroughMortgage == 'YES'|| mortgageTerm.other == 'YES')&& isRegularPaymentsDefaultOrYes() && !matter?.isMatterProvinceBC">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Total</label>
            </div>
            <div class="col-md-8">
                <input type="text" readonly="true" name="totalValue"
                    id="totalValue"
                    [dp-currency]="allowMaxLength"
                    class="form-control" [(ngModel)]="mortgageTerm.total"
                    (change)="notifyChange()"/>
            </div>
        </div>
    </div>

    <!-- Alternate Payment Section -->
    <div class="col-md-6" *ngIf="mortgageTerm.alternateFrequency == 'YES'">
        <div class="form-group">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Alt. Payment Frequency</label>
            </div>
            <div class="col-md-8">
                <p-autoComplete
                        [(ngModel)]="mortgageTerm.alternatePaymentFrequency"
                        dp-error-validator
                        tabIndexIgnore
                        [fieldKey]="'mortgage.'+mortgageIndex+'.term.alternate.paymentFrequency'"
                        [suggestions]="filteredPaymentFrequency"
                        (completeMethod)="filterPaymentFrequency($event)"
                        (focusout)="setAltVariablestoCalculateMortgage()"
                        (ngModelChange)="enableSave(); setAltVariablestoCalculateMortgage();"
                        [minLength]="1"
                        [maxlength]="50"
                        [dropdown]="true"
                        (keydown.space)="utils.handleSpaceKeyDropdown($event, mortgageTerm.alternatePaymentFrequency, handleDropdownClick)"
                        (keydown.enter)="utils.handleEnterKeyDropdown($event, mortgageTerm.alternatePaymentFrequency, handleDropdownClick)"
                        (onDropdownClick)="handleDropdownClick($event)"

                    >
                    <ng-template let-paymentFrequencyLabel pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix">
                            <span class="omnibarLi100">
                                {{paymentFrequencyLabel}}
                            </span>
                        </li>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Alt. Payments Due</label>
            </div>
            <div class="col-md-8">
                <input type="text" name="altPaymentDue" maxlength="25" id="altPaymentDue" class="form-control"
                        [(ngModel)]="mortgageTerm.alternatePaymentsDue"
                        (change)="notifyChange()"
                        trimmed/>
            </div>
        </div>
        <div class="form-group">
            <div style="height:30px">&nbsp;</div>
        </div>
        <div class="form-group">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Alt. First Payment Date</label>
            </div>
            <div class="col-md-8">
                <dp-partial-date #idAltPaymentDate
                                [inputDate]="mortgageTerm.alternateFirstPaymentDate"
                                [fieldKey]="'mortgage.'+mortgageIndex+'.term.altPaymentDate'"
                                (dateChange)="onDateChangeAltPaymentDate($event)">
                </dp-partial-date>
            </div>
        </div>
        <div class="form-group">
            <div style="height:30px">&nbsp;</div>
        </div>
        <div class="form-group"  *ngIf="isRegularPaymentsDefaultOrYes() && mortgageTerm.variableInterestRate != 'YES' &&  getCalculatedDropDownValue(this.mortgageTerm.calculated)!=''">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Auto-Calculated<br>Alt&nbsp;Regular&nbsp;Payments?</label>
            </div>
            <div class="col-md-8" style="padding-top: 8px;">
                <dp-checkbox fieldId="autoCalAltRegPayment">
                    <input type="checkbox"
                        id="autoCalAltRegPayment"
                        (change)="alternatePaymentAutoCalculatedChanged()"
                        [disabled]="mortgageTerm.isInterestOnlyYes()"
                        name="autoCalAltRegPayment"
                        [(ngModel)]="mortgageTerm.alternatePaymentAutoCalculated"
                    />
                </dp-checkbox>
                <label class="control-label text-left padding-top-0">Yes</label>
            </div>
        </div>
        <div class="form-group"  *ngIf="mortgageTerm.variableInterestRate != 'YES'">
            <div *ngIf="mortgageTerm.alternatePaymentAutoCalculated && !mortgageTerm.isInterestOnlyYes()"  style="height:30px">
                <div class="col-lg-4 vertical-align-label padding-right-0">
                    <label class="control-label">Amortization Period</label>
                </div>
                <div class="col-lg-2 padding-left-10">
                    <input type="text" numberOnly class="form-control inline-block"
                        dp-error-validator
                        [fieldKey]="'mortgage.'+mortgageIndex+'.term.alternate.amortization'"
                        (blur)="setAltVariablestoCalculateMortgage()" (change)="enableSave()"
                        [(ngModel)]="mortgageTerm.alternatePaymentAmortizationPeriod"
                        maxlength="3"/>
                </div>
                <div class="col-lg-6 vertical-align-label">
                    <label class="control-label text-left">years</label>
                </div>
            </div>
        </div>
        <div class="form-group"  *ngIf="isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label">Alt. Regular Payments</label>
            </div>
            <div class="col-md-4">
                <input type="text" name="altRegularPayments" *ngIf="!mortgageTerm.alternatePaymentAutoCalculated"
                        id="altRegularPayments" class="form-control"
                        [(ngModel)]="mortgageTerm.alternateRegularPayment"
                        dp-default-currency
                        dp-currency
                        [fieldKey]="'mortgage.'+mortgageIndex+'.term.alternateRegularPayment'"
                        statusBarHelp
                        (keyup)="calculateAltTotal()"
                        (change)="notifyChange()"
                />
                <input type="text" name="altRegularPaymentsCalculated"
                        *ngIf="mortgageTerm.alternatePaymentAutoCalculated" id="altRegularPaymentsCalculated"
                        class="form-control"
                        [ngModel]="getAltRegularPaymentCalculated()"
                        dp-default-currency
                        dp-currency
                        [dp-read-only]="mortgageTerm.alternatePaymentAutoCalculated"
                />

            </div>
            <div class="col-md-4 vertical-align-label" *ngIf="isAmortizationScheduleVisible()" >
                <a href="javascript:void(0);" class="control-link" (click)="doMatterSaveAndOpenAmortizationScheduleModal('ALT_PAYMENTS');">Amortization Schedule</a>
            </div>
        </div>
        <div class="form-group" *ngIf="isRegularPaymentsDefaultOrYes() && mortgageTerm.taxesPaidThroughMortgage == 'YES'">
            <div class="col-md-4 vertical-align-label">
                    <label class="control-label">Alt. Tax Portion</label>
            </div>
            <div class="col-md-8">
                <input type="text" name="altTaxPortion"
                    dp-default-currency
                    dp-currency
                    (keyup)="calculateAltTotal()"
                    id="altTaxPortion" class="form-control" [(ngModel)]="mortgageTerm.alternateTaxPortion"
                    (change)="notifyChange()"/>
            </div>
        </div>
        <div class="form-group"  *ngIf="mortgageTerm.other == 'YES' && isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                    <label class="control-label">Alt. {{mortgageTerm.otherText ? mortgageTerm.otherText :
                        '??????'}}</label>
                </div>
                <div class="col-md-8">
                    <input type="text" name="altOtherValue"
                            dp-default-currency
                            [dp-currency]="allowMaxLength"
                            (keyup)="calculateAltTotal()"
                            id="altOtherValue" class="form-control" [(ngModel)]="mortgageTerm.alternateOtherPayment"
                            (change)="notifyChange()"/>
                </div>
        </div>
        <div class="form-group" *ngIf="(mortgageTerm.taxesPaidThroughMortgage == 'YES'|| mortgageTerm.other == 'YES')&& isRegularPaymentsDefaultOrYes()">
            <div class="col-md-4 vertical-align-label">
                <label class="control-label" >Alt.Total</label>
            </div>
            <div class="col-md-8">
                <input type="text" [dp-currency]="allowMaxLength" readonly="true" name="altTotal" id="altTotal"
                    class="form-control"
                    [(ngModel)]="mortgageTerm.alternateTotal"
                    (change)="notifyChange()"/>
            </div>
        </div>
    </div>
    </div>
    <dp-mortgage-guarantor [matter]="matter" [mortgage]="mortgage"></dp-mortgage-guarantor>
    <ng-container *dpShowByProvince="'matter.mortgage.term.additionalMortgageDetails'">
        <div class="form-group fields-group margin-bottom-0">
            <div class="col-lg-12 padding-top-20 margin-bottom-0">
                <div class="main-level">
                    <span class="title min-height-30">Additional Mortgage Details</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="form-group col-lg-6">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Box 6 Additional Provisions</label>
                </div>
                <!--The additionalClause length is 64k for now-->
                <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="box6AdditionalProvisions"
                              name="box6AdditionalProvisions"
                              maxlength="64000"
                              [fieldKey]="'mortgage.term.box6AdditionalProvisions'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.box6AdditionalProvisions"
                              (ngModelChange)="notifyChange()"
                              (click)="updateCursorPositionBox6($event)"
                              (keyup)="updateCursorPositionBox6($event)">
                    </textarea>
                </div>
                <div class="col-lg-4 offset-lg-4 padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Insert Precedent"
                           (click)="insertPrecedent('BOX6')"/>
                </div>
                <div class="col-lg-4  padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Save As Precedent"
                           [dp-read-only]="!mortgageTerm.box6AdditionalProvisions"
                           (click)="saveAsPrecedent('BOX6')"/>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Mortgage Description</label>
                </div>
                <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="mortgageDescription"
                              name="mortgageDescription"
                              maxlength="130"
                              [fieldKey]="'mortgage.term.mortgageDescription'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.mortgageDescription"
                              (ngModelChange)="notifyChange()"
                              (click)="updateCursorPositionMtgDesc($event)"
                              (keyup)="updateCursorPositionMtgDesc($event)">
                    </textarea>
                </div>
                <div class="col-lg-4 offset-lg-4  padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Insert Precedent"
                           (click)="insertPrecedent('MTG')"/>
                </div>
                <div class="col-lg-4  padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Save As Precedent"
                           [dp-read-only]="!mortgageTerm.mortgageDescription"
                           (click)="saveAsPrecedent('MTG')"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="form-group col-lg-6">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Loan Description</label>
                </div>
                <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="loanDescription"
                              name="loanDescription"
                              maxlength="130"
                              [fieldKey]="'mortgage.term.loanDescription'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.loanDescription"
                              (ngModelChange)="notifyChange()"
                              (click)="updateCursorPositionLoanDesc($event)"
                              (keyup)="updateCursorPositionLoanDesc($event)">
                    </textarea>
                </div>
                <div class="col-lg-4 offset-lg-4 padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Insert Precedent"
                           (click)="insertPrecedent('LOAN')"/>
                </div>
                <div class="col-lg-4  padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Save As Precedent"
                           [dp-read-only]="!mortgageTerm.loanDescription"
                           (click)="saveAsPrecedent('LOAN')"/>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <div class="col-lg-4 vertical-align-label">
                    <label class="control-label">Prime is defined as</label>
                </div>
                <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="primeIsDefinedAs"
                              name="primeIsDefinedAs"
                              maxlength="130"
                              [fieldKey]="'mortgage.term.primeIsDefinedAs'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.primeIsDefinedAs"
                              (ngModelChange)="notifyChange()"
                              (click)="updateCursorPositionPrimeText($event)"
                              (keyup)="updateCursorPositionPrimeText($event)">
                    </textarea>
                </div>
                <div class="col-lg-4 offset-lg-4  padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Insert Precedent"
                           (click)="insertPrecedent('PRIME')"/>
                </div>
                <div class="col-lg-4  padding-top-10">
                    <input type="button"
                           class="primary-button"
                           value="Save As Precedent"
                           [dp-read-only]="!mortgageTerm.primeIsDefinedAs"
                           (click)="saveAsPrecedent('PRIME')"/>
                </div>
            </div>
        </div>
    </ng-container>
    <dp-user-defined-fields [topicName]="'MORTGAGES_TERMS'" [mortgageId] = "mortgage.id" (onChange)="enableSave()">
    </dp-user-defined-fields>
</div>

