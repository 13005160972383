import {Contact} from './contact';
import * as _ from 'lodash';

/**
 * This util class contains a limited subset of the name modifiers needed in the UI. All name modifier logic for document generation is on the backend.
 */
export class NameModifierUtil {

  static applyModifier(contacts: Contact[], modifier: string): string {
    let formattedString = '';

    switch (modifier) {
      case 'Q': {
        formattedString = this.modifierQ(contacts);
        break;
      }
      case 'M': {
        formattedString = this.modifierM(contacts);
        break;
      }
    }
    return formattedString;
  }

  //Modifier Q will return a unique list of names separated by a "/"
  //Currently supports private lender contacts only
  static modifierQ(contacts: Contact[]): string {
    return _.uniq(contacts.map(contact => {
      //Private lender replaced with client therefore checking both PRIVATE_LENDER_ORGANIZATION (for any existing contact) & ORGANIZATION
      if (contact.contactType == 'PRIVATE_LENDER_ORGANIZATION' || contact.contactType == 'ORGANIZATION') {
        return contact.organizationName;
      } else if (contact.contactType == 'PRIVATE_LENDER_PERSON' || contact.contactType == 'PERSON') {
        return contact.lastName;
      } else {
        return '';
      }
    }).filter(name => name)).join('/');
  }

  //Modifier M will return a unique list of names separated using "and" and "," as separators
  //Currently supports private lender contacts only
  static modifierM(contacts: Contact[]): string {
    let formattedNames: string = '';
    let names: string[] = _.uniq(contacts.map(contact => {
      //Private lender replaced with client therefore checking both PRIVATE_LENDER_ORGANIZATION (for any existing contact) & ORGANIZATION
      if (contact.contactType == 'PRIVATE_LENDER_ORGANIZATION' || contact.contactType == 'ORGANIZATION') {
        return contact.organizationName;
      } else if (contact.contactType == 'PRIVATE_LENDER_PERSON' || contact.contactType == 'PERSON') {
        return contact.surnameLastFullName;
      } else {
        return '';
      }
    }).filter(name => name));

    if (names.length == 1) {
      formattedNames = names[ 0 ];
    } else if (names.length == 2) {
      formattedNames = names[ 0 ] + ' and ' + names[ 1 ];
    } else if (names.length > 2) {
      //all names except last will be separated by comma
      const commaSeparatedNames: string = names.slice(0, -1).join(', ');
      //last name in list will be concatenated using "and"
      formattedNames = commaSeparatedNames + ' and ' + names.slice(-1)[ 0 ];
    }

    return formattedNames;
  }

}
