import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Utils} from './utils';

const propertyIgnoreList = [ 'id', 'initials', 'lastname' ];

export class ContactName extends BaseEntity {

  constructor(contactName?: ContactName) {
    super(contactName);
    if (contactName) {
      for (let prop in contactName) {
        if (contactName.hasOwnProperty(prop)) {
          this[ prop ] = contactName[ prop ];
        }
      }
    }
  }

  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  initials: string;
  formerNames: string;

  get fullName(): string {
    return (this.lastName ? this.lastName + ', ' : '') +
      (this.firstName ? this.firstName + ' ' : '') +
      (this.middleName ? this.middleName : '');
  }

  get createAdditionalNameFromSigner(): string {
    if (this.firstName) {
      return this.firstName + ' ' +
        (this.middleName ? this.middleName + ' ' : '') +
        (this.lastName ? this.lastName : '');
    } else {
      return (this.lastName ? this.lastName : '');
    }

  }

  get surnameLastFullName(): string {
    return ((this.firstName ? this.firstName + ' ' : '') +
      (this.middleName ? this.middleName + ' ' : '') +
      (this.lastName ? this.lastName : '')).trim();
  }

  get surnameLastFormatFullName(): string {
    return Utils.capitalizeTypedName(this.surnameLastFullName);
  }

  get firstLastNames(): string {
    return ((this.firstName ? this.firstName + ' ' : '') +
      (this.lastName ? this.lastName : '')).trim();
  }

  get firstMiddleNames(): string {
    return (this.firstName ? this.firstName : '') +
      (this.middleName ? ' ' + this.middleName : '');
  }

  get fullNameLastInUpper(): string {
    return (this.lastName ? this.lastName.toUpperCase() + ', ' : '') +
      (this.firstName ? this.firstName + ' ' : '') +
      (this.middleName ? this.middleName : '');
  }

  // equals(other: ContactName): boolean {
  //
  //     return this.firstName === other.firstName
  //            && this.middleName === other.middleName
  //            && this.lastName === other.lastName;
  // }

  // updateFrom(source: ContactName): void {

  //     this.firstName = source.firstName;
  //     this.middleName = source.middleName;
  //     this.lastName = source.lastName;
  // }

  update(source: ContactName): void {
    this.firstName = source.firstName;
    this.middleName = source.middleName;
    this.lastName = source.lastName;
    this.formerNames = source.formerNames;
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }

  setLastNameFromDisplayName(displayName: string): void {
    if (displayName) {
      const contactName = Utils.capitalizeTypedName(displayName);
      const contactNameSplit = contactName.split('"');
      this.lastName = Array.isArray(contactNameSplit) && contactNameSplit.length > 2
        ? contactNameSplit[ 1 ] : null;
    } else {
      this.lastName = null;
    }
  }

  get firstLetterFirstNameFormat(): string {
    return this.firstName ? this.firstName[ 0 ] + '. ' : '';
  }

  get firstLetterMiddleNameFormat(): string {
    return this.middleName ? this.middleName[ 0 ] + '. ' : '';
  }

  get firstNamePlusSpace(): string {
    return this.firstName ? this.firstName + ' ' : '';
  }

  get lastNamePlusSpace(): string {
    return this.lastName ? this.lastName + ' ' : '';
  }

  get lastNameFormat(): string {
    return this.lastName ? this.lastName : '';
  }

  get firstNameFormat(): string {
    return this.firstName ? this.firstName : '';
  }
}


