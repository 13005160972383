import {TemplateStopCode} from './template-stop-code';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DocumentProductionUtil} from '../shared/document-production/document-production-util';
import {Matter} from '../shared/matter';

export type ProducibleDocType = 'WORD' | 'WORDPERFECT' | 'RTF';

export class DocumentProductionData {

  matterId: number;
  templates: Array<TemplateStopCode> = [];
  produceFileType: ProducibleDocType = 'WORD';
  supplementalTaskCategoryId: number;
  matterDocumentIds: number[] = [];

  // A clean up maybe required for matterId as we are adding the matter object
  matter: Matter;

  constructor(documentProductionData?: DocumentProductionData) {
    if (documentProductionData) {
      for (let prop in documentProductionData) {
        if (documentProductionData.hasOwnProperty(prop)) {
          this[ prop ] = documentProductionData[ prop ];
        }
      }

      this.templates = [];
      if (documentProductionData.templates) {
        for (let dt of documentProductionData.templates) {
          this.templates.push(new TemplateStopCode(dt));
        }
      }
    }
  }

  isTemplateRequestedWordOrWordPerfect(templateId: string | number): boolean {
    if (this.templates.length > 0 && templateId) {
      let template = this.templates.find(item => item.templateId == Number(templateId));
      return (template && template.requestedFileTypes.findIndex(fileType => fileType == 'WORD' || fileType == 'WORDPERFECT') > -1);
    } else {
      return false;
    }

  }

  /**
   * This method is used to create the DocumentProductionData for the first time request for producing documents
   * from matter ids and selected template ids
   * @param _matterId
   * @param _templateIds
   * @returns {DocumentProductionData}
   */
  static of(_matterId: number, _templateIds: Array<string>, _produceFileType?: string, isLenderDocument?: boolean, customTemplateNameMap?: any[]): DocumentProductionData {
    let documentProductionData: DocumentProductionData = new DocumentProductionData();
    documentProductionData.matterId = _matterId;
    documentProductionData.produceFileType = <ProducibleDocType>_produceFileType;
    if (_templateIds) {
      for (let templateId of _templateIds) {
        let templateStopCode: TemplateStopCode = DocumentProductionData.createDocGenRequestForSelectedTemplate(templateId, _produceFileType, isLenderDocument, customTemplateNameMap);
        documentProductionData.templates.push(templateStopCode);
      }
    }

    return documentProductionData;
  }

  static createDocGenRequestForSelectedTemplate(templateId: string, _produceFileType?: string, isLenderDocument?: boolean, customTemplateNameMap?: any[]): TemplateStopCode {
    //For multi-priority template array has concatenated template id and mortgage index therefore splitting it below
    let templateIdArr: string[] = templateId.toString().split('-');
    let templateStopCode: TemplateStopCode = new TemplateStopCode();
    templateStopCode.templateId = parseInt(templateIdArr[ 0 ]);
    templateStopCode.requestedFileTypes.push(...DocumentProductionUtil.getRequestedFileTypesFromOutputType(_produceFileType));

    if (templateIdArr.length >= 2) {
      if (templateIdArr.length === 3) {
        if (templateIdArr[ 2 ] == 'A') {
          templateStopCode.affidavitIndex = parseInt(templateIdArr[ 1 ]);
        } else if (templateIdArr[ 2 ] == 'HB') {
          templateStopCode.holdbackIndex = parseInt(templateIdArr[ 1 ]);
        }
      } else {
        templateStopCode.mortgageIndex = parseInt(templateIdArr[ 1 ]);
      }
    }
    if (isLenderDocument) {
      templateStopCode.lenderDocument = true;
    }
    if (customTemplateNameMap && customTemplateNameMap.length > 0) {
      let customTemplate = customTemplateNameMap.find(m => m.templateId == templateStopCode.templateId);
      if (customTemplate) {
        templateStopCode.customTemplateName = customTemplate.customTemplateName;
        templateStopCode.isForm = customTemplate.isForm;
        templateStopCode.mortgageType = customTemplate.mortgageType;
        if (customTemplate.mortgageIndex != undefined) {
          templateStopCode.mortgageIndex = customTemplate.mortgageIndex;
        }
        if (customTemplate.holdbackIndex) {
          templateStopCode.holdbackIndex = customTemplate.holdbackIndex;
        }
      }

    }

    return templateStopCode;
  }

  getTemplateName(templateStopCode: TemplateStopCode): string {

    if (!templateStopCode) {
      return '';
    }

    let templateName = templateStopCode.templateName;

    if (!templateName) {
      return '';
    }

    if (templateName.toLowerCase().includes('m#')) {
      return templateName.replace('#', templateStopCode.mortgageIndex.toString());
    } else if (templateName.toLowerCase().includes('x#')) {
      return templateName.replace('#', (templateStopCode.mortgageIndex + 1).toString());
    } else if (templateName.toLowerCase().includes('o#') || templateName.toLowerCase().includes('h#')) {
      return templateName.replace('#', templateStopCode.holdbackIndex.toString());
    }

    return templateName;

  }

}

export class MultipleTemplatesDocument extends DocumentProductionData {

  templateIds: number[] = [];
  masterMaterDocuments: MasterDocument[] = [];
  mergedDocumentName: string;
  documentCategory: string;

  constructor(multipleTemplatesDocument?: MultipleTemplatesDocument) {
    super(multipleTemplatesDocument);
    if (multipleTemplatesDocument) {
      for (let prop in multipleTemplatesDocument) {
        if (multipleTemplatesDocument.hasOwnProperty(prop)) {
          this[ prop ] = multipleTemplatesDocument[ prop ];
        }
      }
      this.masterMaterDocuments = [];
      if (multipleTemplatesDocument.masterMaterDocuments) {
        for (let dt of multipleTemplatesDocument.masterMaterDocuments) {
          this.masterMaterDocuments.push(new MasterDocument(dt));
        }
      }
    }

  }

}

export class MasterDocument extends BaseEntity {
  masterDocumentId: number;
  fileType: string;

  constructor(masterDocument?: MasterDocument) {
    super(masterDocument);
  }
}

// ToDo investigate if DocumentTemplate can be reused here - for now use what backend needs here
export class TemplateProductionDescriptor extends BaseEntity {
  templateName: string;
  templateId: number;
  requestedFileTypes: string[] = [];
}

export class DocumentPurchasersData extends BaseEntity {
  matterId: number;
  matterParticipantIDs: number[] = [];
  template: TemplateProductionDescriptor;
}
