<div class="dppm-md-loading-bg">
    <div class="loading-bg" id="loading-dnd-bg">
        <div class="gradient-box">
            <div class="loading-box" style="width: 430px !important;top:30% !important;">
                <span class="unity-logo"></span>
                <span class="loading-text" style="left:0% !important;right: 0% !important;font-size: 24px !important;top: 69.69% !important;">
                    You may close this window at any time. ....
                </span>
            </div>
        </div>
    </div>
</div>