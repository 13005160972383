import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export const TippInstallmentConstants = {
  janInstallmentAmount: 1,
  febInstallmentAmount: 2,
  marInstallmentAmount: 3,
  aprInstallmentAmount: 4,
  mayInstallmentAmount: 5,
  junInstallmentAmount: 6,
  julInstallmentAmount: 7,
  augInstallmentAmount: 8,
  sepInstallmentAmount: 9,
  octInstallmentAmount: 10,
  novInstallmentAmount: 11,
  decInstallmentAmount: 12

};

export class PropertyTaxesTippInstallment extends BaseEntity {
  janInstallmentAmount: number;
  febInstallmentAmount: number;
  marInstallmentAmount: number;
  aprInstallmentAmount: number;
  mayInstallmentAmount: number;
  junInstallmentAmount: number;
  julInstallmentAmount: number;
  augInstallmentAmount: number;
  sepInstallmentAmount: number;
  octInstallmentAmount: number;
  novInstallmentAmount: number;
  decInstallmentAmount: number;

  constructor(propertyTaxesInstallment?: PropertyTaxesTippInstallment) {
    super(propertyTaxesInstallment);
    if (!propertyTaxesInstallment) {
      this.janInstallmentAmount = 0;
      this.febInstallmentAmount = 0;
      this.marInstallmentAmount = 0;
      this.aprInstallmentAmount = 0;
      this.mayInstallmentAmount = 0;
      this.junInstallmentAmount = 0;
      this.julInstallmentAmount = 0;
      this.augInstallmentAmount = 0;
      this.sepInstallmentAmount = 0;
      this.octInstallmentAmount = 0;
      this.novInstallmentAmount = 0;
      this.decInstallmentAmount = 0;
    }
  }

  get totalTippInstallments(): number {
    return (Number(this.janInstallmentAmount) + Number(this.febInstallmentAmount) + Number(this.marInstallmentAmount)
      + Number(this.aprInstallmentAmount) + Number(this.mayInstallmentAmount) + Number(this.junInstallmentAmount) + Number(this.julInstallmentAmount) + Number(this.augInstallmentAmount)
      + Number(this.sepInstallmentAmount) + Number(this.octInstallmentAmount) + Number(this.novInstallmentAmount) + Number(this.decInstallmentAmount));
  }

}
