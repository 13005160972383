<div class="col-lg-12">
    <form #fireInsuForm="ngForm"
          role="form"
          class="form-horizontal matter-purchaser"
    >
        <div class="form-group"  *ngIf="!contact">
            <label class="col-lg-2"></label>
            <div class="col-lg-10">
                <p-autoComplete
                                id="insurerbroker-autocomplete"
                                placeholder="Search by Name, Address or Add new"
                                name="insurerbroker-autocomplete"
                                [(ngModel)]="selectedInsurerBroker"
                                [class.autocomplete-searching]="insurersBrokersLoading"
                                [suggestions]="insurersBrokers"
                                (completeMethod)="searchInsurerBrokers($event)"
                                (onSelect)="selectInsurerOrBroker()"
                                field="organizationName"
                                [minLength]="0"
                                [delay]="1000"
                                maxlength="75"
                                scrollHeight="550px"
                                (focusout)="cleanupFireInsuranceField()"
                                autocomplete="off" class="autocomplete-search-icon">
                    <ng-template let-insurerBrokers pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                            [ngClass]="{'bold-font' : isAddNew(insurerBrokers)}"
                            [disableItem]="insurerBrokers">
                                            <span
                                                class="col-lg-4 text-overflow">{{ insurerBrokers.displayName ? insurerBrokers.displayName : insurerBrokers.organizationName }}</span>
                            <span class="col-lg-2 text-overflow">{{ insurerBrokers.alternateName }}</span>
                            <span class="col-lg-6 text-overflow">{{ insurerBrokers.mailingAddress && insurerBrokers.mailingAddress.addressTextWithoutCountryAndPostalCode }}</span>
                        </li>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
        <div class="form-group" [hidden]="true">
            <label class="control-label col-lg-2">Communicate with the insurance company or broker?</label>
            <div class="col-lg-4">
                <select class="form-control"
                        id="insurerOrBroker"
                        name="insurerOrBroker"
                        #focusOnLoad
                        [(ngModel)]="matter.fireInsuranceContactInfo.insuranceBrokerType"
                        (ngModelChange)="onInsurerOrBrokerTypeChange()">
                    <option *ngFor="let insuranceBrokerType of insuranceBrokerTypes"
                            [value]="insuranceBrokerType.value">
                        {{insuranceBrokerType.label}}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group" [ngClass]="{'shutter-bg': shutterBackground, 'shutter-bg-closed': shutterBackgroundClosed}" [hidden]="!contact">
            <div>
                <div *ngIf="isBroker()" class="shutter-arrow left-7" (click)="toggleContactSnapshot(selectedBroker)">
                    <div *ngIf="isExpanded(selectedBroker)"><img src="/assets/img/minus.svg" alt=""/></div>
                    <div *ngIf="!selectedBroker.editMode && !isExpanded(selectedBroker)"><img
                        src="/assets/img/plus.svg"/></div>
                </div>
                <div *ngIf="!isBroker()" class="shutter-arrow left-7" (click)="toggleContactSnapshot(selectedInsurer)">
                    <div *ngIf="isExpanded(selectedInsurer)"><img src="/assets/img/minus.svg" alt=""/></div>
                    <div *ngIf="!selectedInsurer.editMode && !isExpanded(selectedInsurer)"><img
                        src="/assets/img/plus.svg"/></div>
                </div>
                <div id="insurerBroker_container" >
                    <div class="form-group">

                        <div class="col-lg-2 control-label"
                             (click)="isBroker() ? toggleContactSnapshot(selectedBroker): toggleContactSnapshot(selectedInsurer)">
                            {{getInsurerBrokerLabel()}}
                        </div>
                        <div class="col-lg-10">
                            <!--The following is Broker-->
                            <div *ngIf="isBroker()">
                                <input type="text"
                                       class="form-control rightPadding350 readonly-input-field"
                                       readonly="readonly"
                                       (keydown.f9)="toggleContactSnapshot(selectedBroker)"
                                       (click)="toggleContactSnapshot(selectedBroker)"
                                       fieldKey="matter.fireInsurance.shutter"
                                       statusBarHelp
                                       *ngIf="!selectedBroker.editMode"
                                       [value]="selectedBroker.dataModel.organizationName? selectedBroker.dataModel.organizationName: selectedBroker.dataModel"
                                       autocomplete="off"/>

                                <!-- Action buttons -->
                                <div class="actionbtns">
                                    <div class="width-60">
                                        <span *ngIf="showGlobalWindow()">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                        <span
                                            *ngIf="showAddressBookWindow()">
                                            <i class="fa fa-address-book" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div class="width-60">
                                        <span *ngIf="selectedBroker.isStale">
                                            <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                        </span>
                                    </div>

                                    <div class="width-30 actionbtns">
                                        <dp-burger-menu *ngIf="!selectedBroker.editMode"
                                                        [openSubMenuOnLeft]="true"
                                                        [allowPopUp]="true"
                                                        [items]="getBrokerOrInsurerBurgerMenuItems(selectedBroker)"
                                                        [disabledItems]="getDisabledItems(selectedBroker)"
                                                        (itemClicked)="clickBrokerOrInsurerBurgerMenu($event, selectedBroker)">
                                        </dp-burger-menu>
                                    </div>
                                </div>

                            </div>
                            <!--The following is Insurer-->
                            <div *ngIf="!isBroker()"  >
                                <input type="text"
                                       class="form-control rightPadding350 readonly-input-field"
                                       readonly="readonly"
                                       (keydown.f9)="toggleContactSnapshot(selectedInsurer)"
                                       (click)="toggleContactSnapshot(selectedInsurer)"
                                       fieldKey="matter.fireInsurance.shutter"
                                       statusBarHelp
                                       *ngIf="!selectedInsurer.editMode"
                                       [value]="selectedInsurer.dataModel.organizationName? selectedInsurer.dataModel.organizationName: selectedInsurer.dataModel"
                                       autocomplete="off"/>

                                <!-- Action buttons -->
                                <div class="actionbtns">
                                    <div class="width-60">
                                        <span *ngIf="showGlobalWindow()">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                        </span>
                                        <span
                                            *ngIf="showAddressBookWindow()">
                                            <i class="fa fa-address-book" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div class="width-60">
                                    <span *ngIf="selectedInsurer.isStale">
                                        <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                    </span>
                                    </div>
                                    <div class="width-30 actionbtns">
                                        <dp-burger-menu *ngIf="!selectedInsurer.editMode"
                                                        [openSubMenuOnLeft]="true"
                                                        [allowPopUp]="true"
                                                        [items]="getBrokerOrInsurerBurgerMenuItems(selectedInsurer)"
                                                        [disabledItems]="getDisabledItems(selectedInsurer)"
                                                        (itemClicked)="clickBrokerOrInsurerBurgerMenu($event, selectedInsurer)">
                                        </dp-burger-menu>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-12" *ngIf="isBroker()? isExpanded(selectedBroker) : isExpanded(selectedInsurer)">
                        <div class="right margin-bottom-20"
                             *ngIf="isBroker()?selectedBroker.isOwnedBySystemAccount :selectedInsurer.isOwnedBySystemAccount">
                            <span *ngIf="!isLocked">
                                <a href="javascript:void(0);"
                                   (click)="editAsPrivateCopyAction(isBroker()?selectedBroker:selectedInsurer)">
                                    Edit as Private Copy
                                </a>
                            </span>
                            <span *ngIf="isLocked">
                                <a href="javascript:void(0);">
                                    Edit as Private Copy
                                </a>
                            </span>
                        </div>
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="isLocked">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The insurance contact is currently locked by {{lockedByUserFullName()}}.
                                </p>
                            </div>
                        </div>
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="isOutOfSync">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{isBroker()?selectedBroker.outOfSyncPreMsg:selectedInsurer.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openSourceContactTabWrapper()" class="line-height-adjust">
                                        {{isBroker() ? selectedBroker.outOfSyncUnderlineMsg : selectedInsurer.outOfSyncUnderlineMsg}}</a>
                                    {{isBroker() ? selectedBroker.outOfSyncPostFixMsg : selectedInsurer.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div *ngIf="contact">
                            <div class="form-group"  *ngIf="!snapshotReadOnly">
                                <label class="control-label col-lg-2">Company Name</label>
                                <div class="col-lg-10">
                                    <input type="text" *ngIf="!isBroker()"
                                           id="organizationName"
                                           [dp-read-only]="snapshotReadOnly"
                                           name="organizationName"
                                           class="form-control"
                                           [(ngModel)]="selectedInsurer.matterParticipant.contact.organizationName"
                                           (change)="dataChange()"
                                           />
                                    <input type="text" *ngIf="isBroker()"
                                           id="organizationName"
                                           [dp-read-only]="snapshotReadOnly"
                                           name="organizationName"
                                           class="form-control"
                                           [(ngModel)]="selectedBroker.matterParticipant.contact.organizationName"
                                           (change)="dataChange()"
                                    />
                                </div>
                            </div>
                            <div>
                                <dp-address-form [address]="contact.mailingAddress"
                                                 class="inner-shutter"
                                                 [secondaryShutter]="true"
                                                 [addressSummaryLabel]="'Address'"
                                                 (onChange)="addressEmitChange()"
                                                 (updateAddress)="updateAddress($event)"
                                                 [fieldPrefix]="'fireInsuranceContactInfo.address'"
                                                 [populateDefaultProvince]="false"
                                                 [disabled]="snapshotReadOnly" [primaryShutter]="true">
                                </dp-address-form>
                            </div>

                            <div class="form-group">
                                <label class="control-label col-lg-2">Work Phone</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="workPhone"
                                           [dp-read-only]="snapshotReadOnly"
                                           name="workPhone"
                                           class="form-control"
                                           [(ngModel)]="contact.workPhone"
                                           (change)="dataChange()"
                                           maxlength="50"/>
                                </div>
                                <label class="control-label col-lg-2">Cell Phone</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="cellPhone"
                                           [dp-read-only]="snapshotReadOnly"
                                           name="cellPhone"
                                           class="form-control"
                                           [(ngModel)]="contact.cellPhone"
                                           (change)="dataChange()"
                                           maxlength="50"/>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-2">Fax</label>
                                <div class="col-lg-4">
                                    <input type="text"
                                           id="fax"
                                           [dp-read-only]="snapshotReadOnly"
                                           name="fax"
                                           class="form-control"
                                           [(ngModel)]="contact.faxPhone"
                                           (change)="dataChange()"
                                           maxlength="50"/>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label col-lg-2">Email</label>
                                <div class="col-lg-10">
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        dp-email-field
                                        class="form-control"
                                        [(ngModel)]="contact.email"
                                        fieldKey="matter.fireInsurance.email"
                                        [dp-error-validator]="{isEmail : true, atLeastOneEmail : true}"
                                        [dp-read-only]="snapshotReadOnly"
                                        (change)="dataChange()"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="form-group" id="dear" *ngIf="contact">
            <label class="control-label col-lg-2">Dear</label>
            <div class="col-lg-4">
              <input type="text"
                name="letterSalutation"
                class="form-control"
                [(ngModel)]="matter.fireInsuranceContactInfo.dear"
                (change)="enableSave()"
                maxlength="30"
                fieldKey="matter.fireInsuranceContactInfo.dear"
                statusBarHelp
                (keydown.f9)="handleF9OnDear()"
                trimmed/>
            </div>
            <ng-container *ngIf="matter.isMatterProvinceBC">
              <label class="control-label col-lg-2">Agent</label>
              <div class="col-lg-4">
                <input
                  type="text"
                  id="agent"
                  name="agent"
                  class="form-control"
                  [(ngModel)]="matter.fireInsuranceContactInfo.agent"
                  maxlength="100"
                  trimmedFselect
                  (change)="enableSave()">
              </div>
            </ng-container> 
        </div>
        <div class="form-group" id="agent" *ngIf="contact && !matter.isMatterProvinceBC">
          <label class="control-label col-lg-2">Agent</label>
          <div class="col-lg-4">
            <input
              type="text"
              id="agent"
              name="agent"
              class="form-control"
              [(ngModel)]="matter.fireInsuranceContactInfo.agent"
              maxlength="100"
              trimmedFselect
              (change)="enableSave()">
          </div>
        </div>

        <!--The following is addition company Insurer name-->
        <div class="form-group" *ngIf="isBroker()" [hidden]="!contact">
            <div class="col-lg-2 control-label">
                Company (Insurer)
            </div>
            <div class="col-lg-10">
                <input type="text"
                       class="form-control rightPadding350 readonly-text-field"
                       readonly="readonly"
                       *ngIf="matter.fireInsuranceContactInfo.companyInsurerName"
                       [value]="matter.fireInsuranceContactInfo.companyInsurerName"
                       autocomplete="off"/>
                <p-autoComplete *ngIf="!matter.fireInsuranceContactInfo.companyInsurerName"
                                id="insurer-autocomplete"
                                placeholder="Search by Name, Address or Add new"
                                name="insurer-autocomplete"
                                [(ngModel)]="selectedAdditionalInsurer"
                                [class.autocomplete-searching]="additionalInsurersLoading"
                                [suggestions]="additionalInsurers"
                                (completeMethod)="searchAdditionalInsurer($event)"
                                (focusout)="cleanupAdditionalInsurerField(selectedAdditionalInsurer)"
                                field="organizationName"
                                [minLength]="0"
                                [delay]="1000"
                                maxlength="75"
                                scrollHeight="550px"
                                (onSelect)="selectAdditionalInsurer()"
                                autocomplete="off">
                    <ng-template let-insurer pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                            [ngClass]="{'bold-font' : isAddNew(insurer)}"
                            [disableItem]="insurer">
                            <span class="col-lg-4 text-overflow">{{ insurer.displayName ? insurer.displayName : insurer.organizationName }}</span>
                            <span class="col-lg-2 text-overflow">{{ insurer.alternateName }}</span>
                            <span
                                class="col-lg-6 text-overflow">{{ insurer.mailingAddress && insurer.mailingAddress.addressTextWithoutCountryAndPostalCode }}</span>
                        </li>
                    </ng-template>
                </p-autoComplete>
                <!-- Action buttons -->
                <div class="header-trash-button" *ngIf="matter.fireInsuranceContactInfo.companyInsurerName">
                    <span>
                        <i class="fa fa-trash" (click)="deleteAdditionalInsurer()" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        </div>

        <!-- Fire Insurance Information -->
        <div class="fields-group">
            <div class="main-level">
                <span class="title" dp-province-field-label= "matter.fire-insurance.information.label">Fire Insurance Information</span>
            </div>


            <div class="form-group">
                <label class="control-label col-lg-2">Documentation</label>
                <div class="col-lg-4">
                    <select class="form-control"
                            id="documentationType"
                            name="documentationType"
                            (ngModelChange)="enableSave()"
                            [(ngModel)]="matter.fireInsuranceContactInfo.documentationType">
                        <option *ngFor="let documentationType of documentationTypes"
                                [value]="documentationType.value">
                            {{documentationType.label}}
                        </option>
                    </select>
                </div>

                <label class="control-label col-lg-2">{{ documentationTypeLabel }}</label>
                <div class="col-lg-4" *ngIf="showInputField()">
                    <input type="text"
                           name="documentationValue"
                           (ngModelChange)="enableSave()"
                           [(ngModel)]="matter.fireInsuranceContactInfo.documentationValue"
                           maxlength="30" trimmed
                           class="form-control"/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-lg-2">Expiry Date</label>
                <div class="col-lg-4">
                    <dp-partial-date #partialExpiryDate
                                     [dateParent]="'fireInsurance'"
                                     [fieldKey]="'matter.fireInsurance.expiryDate'"
                                     [inputDate]="matter.fireInsuranceContactInfo.expiryDate"
                                     [customF9ExternalDate]="getCustomExpiryDate()"
                                     [dateFieldName]="'fireInsuranceExpiryDate'"
                                     dp-error-validator
                                     (dateChange)="onDateChangeExiryDate($event)">
                    </dp-partial-date>
                </div>

                <label class="control-label col-lg-2">Coverage</label>
                <div class="col-lg-4">
                    <input type="text"
                           name="coverage"
                           [(ngModel)]="matter.fireInsuranceContactInfo.coverage"
                           (ngModelChange)="enableSave()"
                           dp-default-currency
                           dp-currency
                           class="form-control text-right"/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-lg-2">Guaranteed Replacement Coverage</label>
                <div class="col-lg-1">
                    <select class="form-control"
                            id="guaranteedReplacementCoverage"
                            name="guaranteedReplacementCoverage"
                            (ngModelChange)="enableSave()"
                            [(ngModel)]="matter.fireInsuranceContactInfo.guaranteedReplacementCoverage">>
                        <option *ngFor="let guaranteedReplacementCoverage of guaranteedReplacementCoverageS"
                                [value]="guaranteedReplacementCoverage.value">
                            {{guaranteedReplacementCoverage.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group" *ngIf="matter.isMatterProvinceBC">
              <label class="control-label col-lg-2">Cost to obtain binder</label>
              <div class="col-lg-4">
                <input
                  id="costToObtainBinder"
                  name="costToObtainBinder"
                  class="form-control"
                  (ngModelChange)="enableSave()"
                  [(ngModel)]="matter.fireInsuranceContactInfo.costToObtainBinder"
                  dp-currency />
              </div>
            </div>

            <!-- GST Section -->
            <div class="form-group" *ngIf="matter.isMatterProvinceBC">
              <label class="control-label col-lg-2">Subject to GST?</label>
              <div class="col-lg-1">
                <select
                  id="subjectToGst"
                  name="subjectToGst"
                  class="form-control"
                  [(ngModel)]="matter.fireInsuranceContactInfo.subjectToGst"
                  (ngModelChange)="enableDisableGSTPercentage()"
                  >
                    <option *ngFor="let subjectToGSTOption of subjectToGSTOptions"
                      [value]="subjectToGSTOption.value">
                      {{subjectToGSTOption.label}}
                    </option>
                </select>
              </div>

              <div *ngIf="isSubjectToGst">
                <label class="control-label col-lg-2">GST percentage is</label>
                <div  class="col-lg-1 text-left">
                    <input
                      id="taxRate"
                      name="taxRate"
                      class="form-control"
                      [(ngModel)]="matter.fireInsuranceContactInfo.taxRate"
                      (ngModelChange)="enableSave()"
                      dp-percentage
                      [dp-read-only]="matter.isMatterProvinceBC"
                      />
                </div>
                <label class="padding-top-10">%</label>
              </div>
            </div>

            <div class="form-group" *ngIf="isSubjectToGst">
              <label class="control-label col-lg-2">GST value</label>
              <div class="col-lg-4">
                <input
                  id="costToObtainBinderCostTaxRate"
                  name="costToObtainBinderCostTaxRate"
                  class="form-control"
                  [(ngModel)]="costToObtainBinderCostTaxRate"
                  dp-currency
                  disabled/>
              </div>
            </div>

            <div class="form-group" *ngIf="isSubjectToGst && matter.isMatterProvinceBC">
              <label class="control-label col-lg-2">Total</label>
              <div class="col-lg-4">
                <input
                  id="costToObtainBinderTotalCost"
                  class="form-control"
                  name="costToObtainBinderTotalCost"
                  [(ngModel)]="costToObtainBinderTotalCost"
                  dp-currency
                  disabled
                  />
              </div>
            </div>
        </div>

        <!-- Fire Insurance Information -->
        <div class="fields-group" *ngIf="isInterestOfMortgageesNotedOnPolicyVisible() || isMassUpdateTab()">
            <div class="main-level">
                <span *ngIf="!matter.isSale" class="title" dp-province-field-label="matter.fire-insurance.mortgageesInterest.label">Have the interest of all the mortgagees been noted on the policy?</span>
                <span *ngIf="matter.isSale" class="title">Have the interest of all the VTB mortgagees been noted on the policy?</span>
            </div>
            <ng-container *ngIf="isMassUpdateTab()">
                <ng-container *ngFor="let number of [0,1,2,3,4,5,6,7]">
                        <div class="form-group">
                            <label *ngIf="matter.isSale" class="control-label col-lg-2">VTB Mortgage</label>
                            <div class="col-xs-1">
                                <select class="form-control"
                                        id="interestMortgagee_{{number}}"
                                        name="interestMortgagee_{{number}}"
                                        (change)="massUpdateInterestOnMortgageesChanged($event.target.value,number)"
                                >
                                    <option *ngFor="let option of mortgageeInterestOnPolicyOptions" [value]="option.value">{{option.label}}</option>
                                </select>
                            </div>
                        </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!isMassUpdateTab() && !isSaving()">
                <ng-container *ngFor="let mortgage of matter.mortgages; let i=index">
                    <ng-container *ngIf="isInsuranceAvailable(mortgage)">
                        <div class="form-group">
                            <span *ngIf="!matter.isSale" class="control-label col-lg-2">
                                <label>{{getMortgageOrder(i+1)}}</label>&nbsp;
                                <label dp-province-field-label="provinceBasedMortgagee">Mortgagee</label>
                            </span>
                            <span *ngIf="matter.isSale" class="control-label col-lg-2">
                                <label>VTB {{getMortgageOrder(i+1)}} Mortgage</label>
                            </span>
                            <div class="col-xs-1">
                                <select class="form-control"
                                        id="interestMortgagee_{{mortgage.identifier}}"
                                        name="interestMortgagee_{{mortgage.identifier}}"
                                        [(ngModel)]="mortgage.mortgageeInterestNotedOnPolicy"
                                        (change)="interestOnMortgageesChanged()"
                                        >
                                    <option *ngFor="let option of mortgageeInterestOnPolicyOptions" [value]="option.value">{{option.label}}</option>
                                </select>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>

        <dp-user-defined-fields [topicName]="'FIRE_INSURANCE'" (onChange)="enableSave()">
        </dp-user-defined-fields>

    </form>
</div>
