import {Injectable} from '@angular/core';
import {
  StewartTitleAuthenticationModal
} from '../../main/manage-thirdparty-credentials/stewart-title/stewart-title-authentication.modal.component';
import {StewartTitleUser} from './stewart-title-user';
import {DialogService} from '../../shared/dialog/dialog.service';
import {StewartTitleService} from './stewart-title-service';
import {User} from '../../matters/shared/user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {Observable} from 'rxjs/Rx';
import {ThirdPartyCredentialsComponent} from '../../main/manage-thirdparty-credentials/third-party-credentials.component';

declare var jQuery: any;

@Injectable()
export class StewartTitleAuthenticationService {

  constructor(private dialogService: DialogService, private stewartTitleService: StewartTitleService) {
  }

  openStewartTitleAuthentication(stewartTitleUser: StewartTitleUser, message?: string): Observable<any> {
    let observable: Observable<any> = new Observable<any>(observer => {
      this.dialogService.matDialogContent({
        content: StewartTitleAuthenticationModal,
        context: {
          stewartTitleUser: stewartTitleUser,
          message: message
        },
        onFulfillment: (result: any) => {
          observer.next(result);
          jQuery('body').removeClass('modal-open');
        }
      });
    });
    return observable;
  }

  saveStewartTitleUser(stewartTitleUser: StewartTitleUser): Observable<StewartTitleUser> {
    let observable: Observable<StewartTitleUser> = new Observable<StewartTitleUser>(observer => {
      this.stewartTitleService.saveStewartTitleUser(stewartTitleUser).subscribe((savedStewartTitleUser: StewartTitleUser) => {
        observer.next(new StewartTitleUser(savedStewartTitleUser));
        this.dialogService.confirm('Confirmation', ThirdPartyCredentialsComponent.getSuccessMessage('Stewart Title'), true, 'OK').subscribe(res => {
          if (res) {
          }
        });
      });
    });
    return observable;
  }

  revalidateStewartTitleUser(stewartTitleUser: StewartTitleUser) {
    let loggedInUser: User = new User(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
    let message: string = '';
    if (loggedInUser && loggedInUser.userAccountProfileId === stewartTitleUser.userAccountProfileId) {
      message = 'Please re-validate your Stewart Title credentials to continue working on this order.';
    } else if (stewartTitleUser.solicitorName && stewartTitleUser.solicitorName.fullName) {
      message = 'Please re-validate ' + stewartTitleUser.solicitorName.firstLastNames + '\'s Stewart Title credentials to continue working on this order.';
    }

    this.openStewartTitleAuthentication(stewartTitleUser, message).subscribe(result => {
      if (result && result.refreshToken) {
        stewartTitleUser.refreshToken = result.refreshToken;
        stewartTitleUser.accessToken = result.accessToken;
        this.saveStewartTitleUser(stewartTitleUser).subscribe((savedUser: StewartTitleUser) => {

        });
      }
    });
  }
}
