<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" id="componentSoaSavePredefinedForm">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{this.context.modalHeader}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">

        <div class="form-group" >
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Name</label>
                </div>
                <div class="col-lg-6">
                    <input type="text"
                           name="name"
                           id="name"
                           maxlength="38"
                           [(ngModel)]="adjustmentTemplate.name"
                           trimmed
                           class="form-control"
                           dp-error-validator
                           [fieldKey]="'matter.soadj.component.template.name'"
                    />
                </div>
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="save()">
                <span>Save</span>
            </button>
            <button type="button" id="cancelBtn" class="dp-btn dp-btn-cancel" (click)="cancel()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

