import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Document} from '../../matters/document-production/document';

export type StatementStatusType = 'PAYOUT_REQUEST_SENT' | 'PAYOUT_REQUEST_ACCEPTED' | 'PAYOUT_REQUEST_CANCELLED';

export class PayoutStatementStatus extends BaseEntity {

  id: number;
  payoutStatementId: number;
  thirdPartyDocument: Document;
  statusId: string;
  statementStatus: string;
  statusReceivedTimestamp: string;
  reasonForCancellation: string;
  thirdPartyDocumentName: string;
  thirdPartyDocumentId: number;
  statusDisplayNotes: string;
  thirdPartyDocumentDeleted: boolean;

  get formattedStatusDisplayNotes() {
    return this.statusDisplayNotes && this.statusDisplayNotes.replace(/\\n|\\r\\n|\\n\\r|\\r/g, '<br>');
  }

  get statementStatusText(): string {
    switch (this.statementStatus) {
      case 'PAYOUT_REQUEST_SENT':
        return 'Payout Request Sent';
      case 'PAYOUT_REQUEST_CANCELLED':
        return 'Payout Request Cancelled';
      default :
        return this.statementStatus;
    }
  }

  isCancelled(): boolean {
    return this.statementStatus == 'PAYOUT_REQUEST_CANCELLED' || (this.statusId == '080' || this.statusId == '80');
  }

  isReturnedToSolicitor(): boolean {
    return this.statusId == '000' || this.statusId == '00';
  }

  isPayoutCancellationAllowed(): boolean {
    return this.statusId != '040' && this.statusId != '11' && this.statusId != '12' && this.statusId != '050' && this.statusId != '40' && this.statusId != '50';
  }

  isReadyToImport(): boolean {
    return this.statusId && this.thirdPartyDocumentId && (this.statusId == '020' || this.statusId == '20' || this.statusId == '050' || this.statusId == '50');
  }

  isPaperClipIconAvailable(): boolean {
    return this.statusId && this.thirdPartyDocumentId && (this.statusId == '020' || this.statusId == '20' || this.statusId == '050' || this.statusId == '50' || this.statusId == '11' || this.statusId == '12');
  }

  isDischargeRegistered(): boolean {
    return this.statusId && (this.statusId == '050' || this.statusId == '50');
  }

  isAuthorizationDischarge(): boolean {
    return this.statusId && (this.statusId == '040' || this.statusId == '40');
  }

  isPayoutRequestSent(): boolean {
    return this.statementStatus === 'PAYOUT_REQUEST_SENT';
  }

  isFundsPaid(): boolean {
    return this.statusId && (this.statusId == '040' || this.statusId == '40' || this.statusId == '12' || this.statusId == '050' || this.statusId == '50');
  }
}
