<form class="form-horizontal" id="dp-referral-import-container">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Referral Import</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <form class="form-horizontal padding-5" #ReferralImportForm="ngForm">
        <mat-tab-group  #referralImportTabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="updateTabReviewStatus($event)">
            <mat-tab label="&#x2713; Deal Data">
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-referral-import-matter-data *ngIf="matter && referral"
                                                        [matter]="matter"
                                                        [originalMatter]="originalMatter"
                                                        [referral]="referral"
                                                        [referralDataPropagation]="referralDataPropagation"
                        >
                        </dp-referral-import-matter-data>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="(isTabReviewed(tabKeys.partiesTab) ? '&#x2713; ' : '') + 'Parties'">
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-referral-import-parties *ngIf="matter && referral"
                                                        [matter]="matter"
                                                        [originalMatter]="originalMatter"
                                                        [referral]="referral"
                                                        [referralDataPropagation]="referralDataPropagation"
                        >
                        </dp-referral-import-parties>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="(isTabReviewed(tabKeys.referrerTab) ? '&#x2713; ' : '') + 'Referrer'">
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-referral-import-referrer *ngIf="matter && referral"
                                                     [matter]="matter"
                                                     [originalMatter]="originalMatter"
                                                     [referral]="referral"
                        >
                        </dp-referral-import-referrer>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="(isTabReviewed(tabKeys.notesTab) ? '&#x2713; ' : '') + 'Notes'">
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-referral-import-notes *ngIf="matter && referral"
                                                     [matter]="matter"
                                                     [referral]="referral"
                        >
                        </dp-referral-import-notes>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="(isTabReviewed(tabKeys.attachmentsTab) ? '&#x2713; ' : '') + 'Attachments'">
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-unity-connect-import-attachment *ngIf="matter && referral"
                                                  [selectedDocuments]="referral?.selectedDocuments"
                                                  [documents]="referral?.documents"
                        >
                        </dp-unity-connect-import-attachment>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn dp-btn-green">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>

        </div>

    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
