import {Injectable} from '@angular/core';
import {LockScreenService} from '../../core/lock-screen.service';
import Utils from '../utils';
import {DriverLicenceInfo} from './driver-licence.info';

@Injectable()
export class DriverLicenceParsingService {

  private codeReader: any;

  constructor(public lockScreenService: LockScreenService) {

  }

  parseDriverLicence(file: File, parsingCompleteCallBack: any, failToParseCallBack: any) {

    let t0 = performance.now();
    this.lockScreenService.lockForUpdate = true;
    // Apply a timeout of 60 seconds to decodeDriverLicence

    import('@zxing/library').then(module => {
      this.codeReader = new module.BrowserPDF417Reader();
      let decodeWithTimeout = Utils.timeoutPromise(60000, this.decodeDriverLicence(file));
      // Wait for the promise to get resolved
      decodeWithTimeout.then((driverLicenceInfo: DriverLicenceInfo) => {
        this.lockScreenService.lockForUpdate = false;
        if (driverLicenceInfo) {
          let t1 = performance.now();
          console.log('decodeDriverLicence took ' + (t1 - t0) / 1000 + ' seconds.');
          if (parsingCompleteCallBack) {
            parsingCompleteCallBack(file, driverLicenceInfo);
          }
        } else {
          if (failToParseCallBack) {
            failToParseCallBack(file);
          }
        }

      });

      // Wait for the promise to get rejected or timed out
      decodeWithTimeout.catch(error => {
        this.lockScreenService.lockForUpdate = false;
        // Deal with error
        if (failToParseCallBack) {
          failToParseCallBack(file);
        }
      });

    }).catch(error => {
      this.lockScreenService.lockForUpdate = false;
      if (failToParseCallBack) {
        failToParseCallBack(file);
      }
    });

  }

  async decodeDriverLicence(file: File): Promise<DriverLicenceInfo> {
    let bmp: ImageBitmap = await createImageBitmap(file);
    return this.decodeDriverLicenceImage(bmp, 0);
  }

  async decodeDriverLicenceImage(bmp: ImageBitmap, yStart: number = 0): Promise<DriverLicenceInfo> {
    let canvas = document.createElement('canvas');
    canvas.width = bmp.width;
    canvas.height = bmp.height;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(bmp, 0, yStart, canvas.width, canvas.height, 0, 0, canvas.width * 0.7, canvas.height * 0.7);

    let img = document.createElement('img');
    img.src = canvas.toDataURL('image/jpg', 1.0);

    try {
      if (this.codeReader) {
        let result = await this.codeReader.decodeFromImage(img);
        if (result) {
          return this.parseDriverLicenceText(result.getText());
        }
      }
    } catch (err) {
      console.log(err);
      if (yStart + 500 < bmp.height) {
        yStart += 500; //Crop the image from top
        return this.decodeDriverLicenceImage(bmp, yStart);
      } else {
        return null;
      }
    }

  }

  //https://www.dynamsoft.com/blog/imaging/extract-data-pdf417-driver-licences/
  parseDriverLicenceText(data: string): DriverLicenceInfo {
    console.log(data);
    if (this.isBCDL(data)) {
      return this.parseBCDL(data);
    } else {
      return this.parseOtherPrivincesDL(data);
    }

  }

  isBCDL(data: string): boolean {
    return data.startsWith('%BC');
  }

  //BC has a different Driver licence format & has to be handled differently
  parseBCDL(data: string): DriverLicenceInfo {
    let driverLicenceInfo = new DriverLicenceInfo();
    data = this.fixBCDataIssueIfExists(data);
    let dataArray = data.split('^');
    if (dataArray && dataArray.length) {
      console.log(dataArray);
      if (dataArray.length >= 1) {
        driverLicenceInfo.placeOfIssue = this.extractPlaceOfIssueBC(dataArray[ 0 ]);
      }
      if (dataArray.length >= 2) {
        this.extractNameBC(dataArray[ 1 ], driverLicenceInfo);
      }
      if (dataArray.length >= 3) {
        this.extractAddressBC(dataArray[ 2 ], driverLicenceInfo);
      }
      if (dataArray.length >= 4) {
        this.extractOtherInfoBC(dataArray[ 3 ], driverLicenceInfo);
      }

    }
    return driverLicenceInfo;
  }

  fixBCDataIssueIfExists(data: string): string {
    /*
     * BC driver's licence has a bug with long place of issue city.
     * There is no ^ between the city name and the driver's name
     * The following is a fix for this issue by inserting the ^ on index 15 if it's not exists.
     */
    if (data.indexOf('^') > 15) {
      data = data.substring(0, 15) + '^' + data.substring(16);
    }
    return data;
  }

  parseOtherPrivincesDL(data: string): DriverLicenceInfo {
    let dataArray = data.split('\n');
    let driverLicenceInfo = new DriverLicenceInfo();
    driverLicenceInfo.placeOfIssue = this.extractPlaceOfIssue(dataArray);
    driverLicenceInfo.surName = this.extractDataByCode(dataArray, 'DCS');
    //First name code can be DAC or DBP And sometimes it's not preset
    driverLicenceInfo.firstName = this.extractDataByCode(dataArray, 'DAC');
    if (!driverLicenceInfo.firstName) {
      driverLicenceInfo.firstName = this.extractDataByCode(dataArray, 'DBP');
    }
    //Middle name code can be DAD or DBQ And sometimes it's not preset
    driverLicenceInfo.middleName = this.extractDataByCode(dataArray, 'DAD');
    if (!driverLicenceInfo.middleName) {
      driverLicenceInfo.middleName = this.extractDataByCode(dataArray, 'DBQ');
    }
    driverLicenceInfo.name = this.extractDataByCode(dataArray, 'DCT');
    driverLicenceInfo.genderCode = this.extractDataByCode(dataArray, 'DBC');
    driverLicenceInfo.birthDate = this.extractDataByCode(dataArray, 'DBB');
    driverLicenceInfo.licenceNumber = this.extractDataByCode(dataArray, 'DAQ');
    driverLicenceInfo.addressLine1 = this.extractDataByCode(dataArray, 'DAG');
    driverLicenceInfo.city = this.extractDataByCode(dataArray, 'DAI');
    driverLicenceInfo.provinceCode = this.extractDataByCode(dataArray, 'DAJ');
    driverLicenceInfo.postalCode = this.extractDataByCode(dataArray, 'DAK');
    driverLicenceInfo.expiryDate = this.extractDataByCode(dataArray, 'DBA');
    return driverLicenceInfo;
  }

  // List of jurisdictions and their codes.
  // source information from https://www.leadtools.com/help/sdk/v20/barcode/api/l-aamva-jurisdiction.html
  jurisdictions = [
    {code: '636028', label: 'British Columbia'},
    {code: '636048', label: 'Manitoba'},
    {code: '636017', label: 'New Brunswick'},
    {code: '636016', label: 'Newfoundland'},
    {code: '636013', label: 'Nova Scotia'},
    {code: '636012', label: 'Ontario'},
    {code: '604426', label: 'Prince Edward Island'},
    {code: '604428', label: 'Quebec'},
    {code: '636044', label: 'Saskatchewan'},
    {code: '604429', label: 'Yukon'},
    {code: '604432', label: 'Alberta'},
    {code: '604433', label: 'Nunavut'}
  ];

  extractPlaceOfIssue(dataArray: string[]): string {
    let jurisdiction = '';
    if (dataArray && dataArray.length > 1) {
      let data = dataArray[ 1 ];
      this.jurisdictions.forEach((item) => {
        if (data.includes(item.code)) {
          jurisdiction = item.label;
        }
      });
      return jurisdiction;
    } else {
      return '';
    }
  }

  extractDataByCode(dataArray: string[], code: string): string {
    let text = dataArray.find(txt => txt.startsWith(code));
    if (text) {
      text = text.slice(code.length).trim();
      text = text.replace(/(^,)|(,$)/g, ''); //remove trailing commas
      return text;
    }
    return '';
  }

  extractPlaceOfIssueBC(data: string): string {
    let placeOfIssue = '';
    if (data) {
      placeOfIssue = data.substring(3, data.length);
    }
    return placeOfIssue;
  }

  extractNameBC(data: string, driverLicenceInfo: DriverLicenceInfo): void {
    let dataArray = data.split('$');
    if (dataArray.length >= 1) {
      driverLicenceInfo.surName = dataArray[ 0 ].replace(',', '');
    }
    if (dataArray.length >= 2) {
      driverLicenceInfo._firstName = dataArray[ 1 ];
    }
  }

  extractAddressBC(data: string, driverLicenceInfo: DriverLicenceInfo): void {
    let dataArray = data.split('$');
    if (dataArray.length >= 1) {
      driverLicenceInfo.addressLine1 = dataArray[ 0 ];
    }
    if (dataArray.length >= 2) {
      driverLicenceInfo.city = dataArray[ 1 ].split(' ')[ 0 ];
      driverLicenceInfo.provinceCode = 'BC';
      driverLicenceInfo.postalCode = dataArray[ 1 ].substring(dataArray[ 1 ].length - 7, dataArray[ 1 ].length); //last 7 charachters
    }

  }

  extractOtherInfoBC(data: string, driverLicenceInfo: DriverLicenceInfo): void {
    let dataArray = data.split('=');
    if (dataArray.length >= 1) {
      driverLicenceInfo.licenceNumber = dataArray[ 0 ].substring(dataArray[ 0 ].length - 7, dataArray[ 0 ].length); //last 7 digits
    }
    if (dataArray.length >= 2) {
      let expirationYear = '20' + dataArray[ 1 ].substring(0, 2);
      let expirationMonth = dataArray[ 1 ].substring(2, 4);
      let expirationDay = dataArray[ 1 ].substring(dataArray[ 1 ].length - 2, dataArray[ 1 ].length);
      driverLicenceInfo.expiryDate = expirationYear + expirationMonth + expirationDay;
      driverLicenceInfo.birthDate = dataArray[ 1 ].substring(4, dataArray[ 1 ].length);

    }
  }
}
