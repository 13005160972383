import {AppMode, EnvironmentConfig, KeycloakConfig} from '../environment';

export class AuDevEnvironment implements EnvironmentConfig {
  appMode = AppMode.AU;
  tcolUrl = '';
  keycloak: KeycloakConfig = {
    clientId: 'unity-spa',
    domain: 'https://dev-auth.globalx.com.au',
    logoutRedirectUri: 'https://dyedurham.com.au/',
    realm: 'GlobalX'
  };
  ltsaWebBaseUrl: string = '';
  landingPageUrl: string = '';
  goCardlessApiUrl: string = 'https://connect-sandbox.gocardless.com/';
  goCardlessClientId: string = 'umqWZWz796Js1b_kPzT3VnzpevYiVM-Wke6V0ivPs-kOpyfaC8fJ5joeukjPOMd2';
  goCardlessAuthRedirectUri: string = '/gocardless/redirect';
}
