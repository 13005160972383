import {Matter} from '../../../shared/matter';
import {ComponentAdjustmentItem, SoAdjComponent} from '../../model/so-adj-component';
import {Component, Inject, ViewChild} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {dropDowns, getCalculationMethodOptions} from '../../../shared/matter-drop-downs';
import {ModalResult} from '../../../../shared-main/enums';
import {TemplateCodeValues} from '../../../shared/evaluate-template-codes';
import {Utils} from '../../../shared/utils';
import {TemplateCodeService} from '../../../template.code.service';
import {AdjustmentTemplateRetrievePredefinedModalComponent} from '../adjustment-template/adjustment-template-retrieve-predefined.modal.component';
import {AdjustmentTemplateSavePredefinedModalComponent} from '../adjustment-template/adjustment-template-save-predefined.modal.component';
import {StatementAdjustment} from '../../statement-adjustment';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {AdjustmentTemplate} from '../../model/adjustment-template';
import {templateCodes} from '../../../../shared-main/constants';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class ComponentAdjustmentModalContext {
  public closingDate: string;
  public componentSoa: SoAdjComponent;
  public matter: Matter;
  public soAdjId: number;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-component-adjustment',
  templateUrl: './component-adjustment.modal.component.html',
  styleUrls: ['./component-adjustment-modal.component.scss'],
  providers: [CurrencyPipe, ErrorService]
})
export class ComponentAdjustmentModalComponent extends ModalComponent<ComponentAdjustmentModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  context: ComponentAdjustmentModalContext;
  componentAdjustmentOptions: SelectItem[];
  useAdjustmentHeadingOptions: SelectItem[];
  componentSoaLocal: SoAdjComponent;
  currentTimeStamp = Date.now();
  calculationMethodOptions: any[];
  creditNoteCalculationOptions: any[];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<ComponentAdjustmentModalComponent>,
    public currencyPipe: CurrencyPipe,
    public templateCodeService: TemplateCodeService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ComponentAdjustmentModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.newAdj = !this.context.componentSoa;
    this.componentSoaLocal = new SoAdjComponent(this.context.componentSoa);
    this.componentAdjustmentOptions = dropDowns.componentAdjustmentOptions;
    this.useAdjustmentHeadingOptions = dropDowns.yesNoOnly;

    if (!this.componentSoaLocal.creditType) {
      this.componentSoaLocal.creditType = 'VENDOR';
    }

    if (!this.componentSoaLocal.useAdjustmentHeading) {
      this.componentSoaLocal.useAdjustmentHeading = 'YES';
    }

    if (!this.componentSoaLocal.creditNoteCalculationMethod) {
      this.componentSoaLocal.creditNoteCalculationMethod = 'MANUAL_ENTRY';
      this.componentSoaLocal.creditNoteAmount = 0;
    }
    this.calculationMethodOptions = this.getCalculationMethodOptions();
    this.creditNoteCalculationOptions = this.getCreditNoteCalculationMethods();

    if (this.componentSoaLocal.items.length == 0) {
      this.addItem();
    }
  }

  getCalculationMethodOptions(): any[] {
    let options = getCalculationMethodOptions;
    if (this.context.matter.isMatterProvinceAB) {
      return this.getCalculationMethodOptionsAB(options);
    } else {
      return options;
    }
  }

  getCalculationMethodOptionsAB(options: any[]): any[] {
    options.forEach((option) => {
      if (option.value == 'DERIVED_AMOUNTS') {
        option.label = 'SALE PRICE, DEPOSIT, GST - DERIVED AMOUNTS';
      }
      if (option.subOptions && option.subOptions.length) {
        option.subOptions.forEach((subOption) => {
          if (subOption.value == '10167') {
            subOption.label = '10167 : (Sale Price, including GST) - (amount determined in #10159)';
          }
          if (subOption.value == '10031') {
            subOption.label = '10031 : From Direction to Pay: Amount payable to payee in top field';
          }
          if (subOption.value == '10036') {
            subOption.label = '10036 : (GST/HST on sale price) - (GST/HST Rebate)';
          }
          subOption.label = subOption.label
            .replace('GST/HST', 'GST')
            .replace('Balance Due on Closing', 'Cash to Close')
            .replace('BDOC', 'Cash to Close')
            .replace('Common Expenses', 'Condominium Fees');
        });
        option.subOptions = option.subOptions.filter(
          (subOption) =>
            subOption.value != '10221' &&
            subOption.value != '10222' &&
            subOption.value != '10223' &&
            subOption.value != '10224' &&
            subOption.value != '10225' &&
            subOption.value != '10226'
        );
      }
    });
    return options;
  }

  getCreditNoteCalculationMethods(): any[] {
    let otherAmountCodes = templateCodes.otherAmountCodes.filter(
      (value) => !value.doNotApplyFor || value.doNotApplyFor != 'creditNote'
    );
    otherAmountCodes = otherAmountCodes.filter((item) => item.value != '10037' && item.value != '10198');
    let options = [
      {label: 'Manual Entry', value: 'MANUAL_ENTRY'},
      {
        label: 'SALE PRICE, DEPOSIT - DATA ENTERED DIRECTLY BY USER',
        value: 'MANUAL_AMOUNTS',
        subOptions: templateCodes.manualAmountCodes
      },
      {
        label: 'SALE PRICE, DEPOSIT - DERIVED AMOUNTS',
        value: 'DERIVED_AMOUNTS',
        subOptions: templateCodes.derivedAmountCodes
      },
      {
        label: 'INTERIM OCCUPANCY FEE ADJUSTMENT',
        value: 'INTERIM_OCCUPANCY_ADJ',
        subOptions: templateCodes.interimOccupanyAdjCodes
      },
      {label: 'OTHER AMOUNTS', value: 'OTHER_AMOUNTS', subOptions: otherAmountCodes}
    ];
    if (this.context.matter.isMatterProvinceAB) {
      return this.getCalculationMethodOptionsAB(options);
    } else {
      return options;
    }
  }

  addItem(): void {
    this.currentTimeStamp = Date.now();
    let item = new ComponentAdjustmentItem();
    item.calculationMethod = 'MANUAL_ENTRY';
    item.amount = 0;
    this.componentSoaLocal.items.push(item);
  }

  removeItem(selectedItem: ComponentAdjustmentItem): void {
    this.currentTimeStamp = Date.now();
    this.dialogService.confirm('Confirmation', 'Do you wish to delete this item?', false, 'Delete').subscribe((res) => {
      if (res) {
        (<any>this.componentSoaLocal.items).remove(selectedItem);
        if (this.componentSoaLocal.items.length == 0) {
          this.addItem();
        }
      }
    });
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpSaveError();
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.cleanAdjustmentBeforeClosing();
      this.dialog.close({action: ModalResult.OK, componentSoa: this.componentSoaLocal});
    }
  }

  checkForErrors() {
    if (this.componentSoaLocal.useAdjustmentHeading == 'YES' && !this.componentSoaLocal.adjustmentHeading) {
      this.modalErrorComponent.createDPSaveError('matter.soadj.component.description');
    }
  }

  cleanAdjustmentBeforeClosing(): void {
    if (this.componentSoaLocal.useAdjustmentHeading == 'NO') {
      this.componentSoaLocal.adjustmentHeading = '';
    }
    //DPPMP-37886 After confirm with BA, if there is no itemName, we still save this item
    //this.componentSoaLocal.items = this.componentSoaLocal.items.filter(value => value.itemName);
  }

  close(): void {
    this.dialog.close({action: ModalResult.Cancel});
  }

  delete(): void {
    this.dialog.close({action: ModalResult.Delete});
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  get isCreditNoteAmountReadOnly(): boolean {
    return this.componentSoaLocal.isTemplateCodeUsedForCreditNoteCalculation;
  }

  isItemAmountReadOnly(item: ComponentAdjustmentItem): boolean {
    return item.isTemplateCodeUsedForCalculation || !item.itemName;
  }

  onChangeItemCalculationMethod(item: ComponentAdjustmentItem) {
    if (item.isTemplateCodeUsedForCalculation) {
      this.templateCodeService
        .evaluateTemplateCodes(this.context.matter, [item.calculationMethod])
        .subscribe((templateCodeResponse: TemplateCodeValues) => {
          item.amount = Utils.convertAmountToNumberWithSign(
            templateCodeResponse.getValueByTemplateCode(item.calculationMethod)
          );
        });
    } else {
      item.amount = 0;
    }
  }

  onChangeCreditNoteCalculationMethod() {
    if (this.componentSoaLocal.isTemplateCodeUsedForCreditNoteCalculation) {
      this.templateCodeService
        .evaluateTemplateCodes(this.context.matter, [this.componentSoaLocal.creditNoteCalculationMethod])
        .subscribe((templateCodeResponse: TemplateCodeValues) => {
          this.componentSoaLocal.creditNoteAmount = Utils.convertAmountToNumberWithSign(
            templateCodeResponse.getValueByTemplateCode(this.componentSoaLocal.creditNoteCalculationMethod)
          );
        });
    } else {
      this.componentSoaLocal.creditNoteAmount = 0;
    }
  }

  retrievePredefined() {
    this.dialogService.matDialogContent({
      content: AdjustmentTemplateRetrievePredefinedModalComponent,
      context: {
        provinceCode: this.context.matter.provinceCode,
        itemKey: StatementAdjustmentKey.COMPONENT_ADJUSTMENT
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action == ModalResult.OK) {
            this.updateSoAdjComponent(result.adjustmentTemplate);
          }
        }
      }
    });
  }

  async updateSoAdjComponent(componentAdjustmentTemplate: AdjustmentTemplate) {
    if (
      componentAdjustmentTemplate &&
      componentAdjustmentTemplate.statementOfAdjustment &&
      componentAdjustmentTemplate.statementOfAdjustment.soAdjComponent
    ) {
      this.componentSoaLocal = new SoAdjComponent(componentAdjustmentTemplate.statementOfAdjustment.soAdjComponent);

      let templateCodes: string[] = this.componentSoaLocal.extractTemplateCodesFromComponentAdj();

      let templateCodeResponse: TemplateCodeValues = await this.templateCodeService
        .evaluateTemplateCodes(this.context.matter, templateCodes)
        .toPromise();
      if (
        this.componentSoaLocal.isTemplateCodeUsedForCreditNoteCalculation &&
        templateCodeResponse.hasCode(this.componentSoaLocal.creditNoteCalculationMethod)
      ) {
        this.componentSoaLocal.creditNoteAmount = Utils.convertAmountToNumberWithSign(
          templateCodeResponse.getValueByTemplateCode(this.componentSoaLocal.creditNoteCalculationMethod)
        );
      }

      if (Array.isArray(this.componentSoaLocal.items) && this.componentSoaLocal.items.length > 0) {
        this.componentSoaLocal.items.forEach((item) => {
          if (item.isTemplateCodeUsedForCalculation && templateCodeResponse.hasCode(item.calculationMethod)) {
            item.amount = Utils.convertAmountToNumberWithSign(
              templateCodeResponse.getValueByTemplateCode(item.calculationMethod)
            );
          }
        });
      }
    }
  }

  savePredefined() {
    let statementAdjustment = new StatementAdjustment(
      this.context.matter.adjustmentStatusMode,
      this.context.matter.provinceCode
    );
    statementAdjustment.itemKey = StatementAdjustmentKey.COMPONENT_ADJUSTMENT;
    statementAdjustment.soAdjComponent = new SoAdjComponent(this.componentSoaLocal);
    if (this.context.soAdjId) {
      statementAdjustment.id = this.context.soAdjId;
    }
    this.dialogService.matDialogContent({
      modalGrid: 6,
      content: AdjustmentTemplateSavePredefinedModalComponent,
      context: {
        statementAdjustment: statementAdjustment,
        modalHeader: 'Save Predefined Component Adjustment'
      }
    });
  }
}
