import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DocumentProductionData} from '../document-production-data';
import {StopCodesCommonComponent} from './stop-codes-common.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class StopCodeModalContext {
  documentProductionData: DocumentProductionData;
}

@Component({
  selector: 'dp-stop-codes',
  templateUrl: 'stop-codes-modal.component.html'
})
export class StopCodesModalComponent extends ModalComponent<StopCodeModalContext> implements OnInit {
  @ViewChild('dpStopCodesCommon') dpStopCodesCommon: StopCodesCommonComponent;

  constructor(
    public dialog: MatDialogRef<StopCodesModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: StopCodeModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {}

  get documentProductionData(): DocumentProductionData {
    return this.context.documentProductionData;
  }

  onClickOk(): void {
    this.dpStopCodesCommon.copyValueFromWrapperToDocumentStopCode();
    this.dialog.close(this.documentProductionData);
  }

  onClickCancel(): void {
    this.dpStopCodesCommon.clearStopCodeValues();
    this.dialog.close(this.documentProductionData);
  }
}
