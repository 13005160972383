import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class StewartAssystDownloadDocResponse extends BaseEntity {
  documentNumber: number;
  totalNumberOfVersions: number;
  successfulDocumentVersions: SuccessfulDocument[] = [];
  failedDocumentVersions: FailedDocument[] = [];

  constructor(s?: StewartAssystDownloadDocResponse) {
    super(s);
    if (s) {
      if (s.successfulDocumentVersions) {
        for (let d of s.successfulDocumentVersions) {
          this.successfulDocumentVersions.push(new SuccessfulDocument(d));
        }
      }
      if (s.failedDocumentVersions) {
        for (let d of s.failedDocumentVersions) {
          this.failedDocumentVersions.push(new FailedDocument(d));
        }
      }
    }
  }

  get areAllDocumentsDownloaded(): boolean {
    return this.totalNumberOfVersions == this.successfulDocumentVersions.length;
  }

  get arePartialDocumentsDownloaded(): boolean {
    return this.successfulDocumentVersions.length > 0 && this.totalNumberOfVersions > this.successfulDocumentVersions.length;
  }
}

export class SuccessfulDocument extends BaseEntity {
  documentVersion: number;
}

export class FailedDocument extends BaseEntity {
  documentVersion: number;
  errorMessage: string;
}
