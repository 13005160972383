import {SoAdjVacantOccupancyTax} from '../../model/so-adj-vacant-occupancy-tax';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, Inject} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalResult} from '../../../../shared-main/enums';
import Utils from '../../../../shared-main/utils';
import {StatementOfAdjustmentDisplayItemLine} from '../../model';
import {StatementAdjustmentDisplayVacantOccupancyTaxBuilder} from '../../builders/statement-adjustment-display-vacant-occupancy-tax-builder';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class VacantOccupancyTaxModalContext {
  closingDate: string;
  adjustment: SoAdjVacantOccupancyTax;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
}

@Component({
  selector: 'dp-so-adj-vacant-occupancy-tax-modal-content',
  templateUrl: './vacant-occupancy-tax.modal.component.html',
  providers: [ErrorService]
})
export class VacantOccupancyTaxModalComponent extends ModalComponent<VacantOccupancyTaxModalContext> {
  localAdj: SoAdjVacantOccupancyTax;

  closingDateLabel: string = '';
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<VacantOccupancyTaxModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: VacantOccupancyTaxModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.adjustment) {
      this.localAdj = new SoAdjVacantOccupancyTax(this.context.adjustment);
    } else {
      this.localAdj = new SoAdjVacantOccupancyTax();
      this.localAdj.assessmentAmount = 0.0;
      this.localAdj.interestRate = 1.0;
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    return StatementAdjustmentDisplayVacantOccupancyTaxBuilder.getDisplayItems(
      this.localAdj,
      true,
      this.context.statementAdjustmentDisplayUtil
    );
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  calculateVacantOccupancyTax(): string {
    return StatementAdjustmentDisplayVacantOccupancyTaxBuilder.calculateVacantOccupancyTaxAmount(this.localAdj).toFixed(
      2
    );
  }

  ok() {
    this.dialog.close(this.localAdj);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res: boolean) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.newAdj;
  }

  cancel(): void {
    this.dialog.close(ModalResult.Cancel);
  }
}
