import {AvailableDocument, ChargeOnTitle, LtsaOrderPack, Order, OrderLabel} from './ltsa-order';
import {Injectable} from '@angular/core';
import {OrderProductType, SearchType} from '../ltsa/ltsa-constants';
import {Utils} from '../../matters/shared/utils';
import moment from 'moment/moment';
import {uniqBy} from 'lodash';
import {DocumentCost} from '../document-cost/document-cost.type';
import {UNKNOWN_PRICE} from '../document-cost/document-cost.constants';
import {DecimalPipe} from '@angular/common';
import {OrderPlacement} from './ltsa-order-placement';
import {Observable} from 'rxjs';
import {LtsaService} from './ltsa-service';

@Injectable()
export class OrderService {

  constructor(private decimalPipe: DecimalPipe,
              private ltsaService: LtsaService) {
  }

  public fetchAvailableDocuments(orderPack: LtsaOrderPack, estimatedDocumentCosts: { [ key: string ]: DocumentCost }): AvailableDocument[] {
    const availableDocuments: AvailableDocument[] = [];
    const docOrPlanOrders: Order[] = [];

        const chargeCost = estimatedDocumentCosts ? this.decimalPipe.transform(estimatedDocumentCosts.charge?.totalCost + estimatedDocumentCosts.ltsaServiceFee.totalCost, '1.2-2') : UNKNOWN_PRICE;
        const planCost = estimatedDocumentCosts ? this.decimalPipe.transform(estimatedDocumentCosts.plan?.totalCost + estimatedDocumentCosts.ltsaServiceFee.totalCost, '1.2-2') : UNKNOWN_PRICE;

    orderPack.orders.forEach(order => {
      if (OrderProductType.DOC_OR_PLAN !== order.productType) {
        order.chargesOnTitle?.filter(charge => this.isRegistered(charge))
        .forEach(charge => {
          availableDocuments.push(new AvailableDocument('charge',
            charge.registrationNumber,
            Utils.convertStringToTitleCase(charge.natureOfCharge),
            chargeCost,
            charge.status,
            this.formatDate(charge.registrationDate),
            charge.holderOfCharge));
        });
        order.associatedPlans?.forEach(plan => {
          availableDocuments.push(new AvailableDocument('plan',
            plan.planNumber,
            Utils.convertStringToTitleCase(plan.planType),
            planCost));
        });
      } else {
        docOrPlanOrders.push(order);
      }
    });

    const uniqDocs: AvailableDocument[] = uniqBy(availableDocuments, 'criteria');
    docOrPlanOrders.forEach(order => {
      let orderedDocument: AvailableDocument = uniqDocs.find(document => document.criteria === order.criteria);
      if (orderedDocument) {
        orderedDocument.ordered = true;
        orderedDocument.isSelected = false;
        orderedDocument.orders.push(order);
      }
    });
    return uniqDocs;
  }

  public purchaseAvailableDocument(availableDocument: AvailableDocument, matterId: number, groupId: number): Observable<Order> {
    const orderPlacement = new OrderPlacement(
      matterId,
      OrderProductType.DOC_OR_PLAN,
      availableDocument.criteria,
      SearchType.INITIAL,
      false,
      groupId
    );
    return this.ltsaService.placeOrder(orderPlacement);
  }

  public orderStatusLabel(order: Order): OrderLabel {
    const endsWithNumberInParentheses = /\(\d+\)$/;
    if (endsWithNumberInParentheses.test(order.displayName)) {
      return OrderLabel.RE_ORDERED;
    }
    return OrderLabel.ORDERED;
  }

  private isRegistered(charge: ChargeOnTitle) {
    return charge.status == 'REGISTERED';
  }

  private formatDate(date: string): string {
    if (!date) {
      return '';
    }
    return moment(date).format('MMMM DD, YYYY');
  }
}
