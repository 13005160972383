import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {ChecklistTemplateConfig} from './checklist-template-config';
import {checklistTemplateAPI} from '../../shared-main/supplemental-task-category/checklist-template-api';
import {HttpClient} from '../../core';
import {ProvinceCode} from '../accounts/shared/account-province';

@Injectable()
export class ChecklistTemplateService {
  constructor(public httpClient: HttpClient) {

  }

  getAllChecklistTemplates(accountId: number, applicableProvinceCode?: ProvinceCode, applicableMatterTypeCode?: string): Observable<ChecklistTemplateConfig[]> {
    let url: string = checklistTemplateAPI.getAllChecklistTemplates(String(accountId));
    if (applicableProvinceCode || applicableMatterTypeCode) {
      url += '?filter=';
      if (applicableProvinceCode) {
        url += `applicableProvinceCode_EQ_${ applicableProvinceCode }`;
      }
      if (applicableMatterTypeCode) {
        if (applicableProvinceCode) {
          url += ',';
        }
        url += `applicableMatterTypeCode_EQ_${ applicableMatterTypeCode }`;
      }
    }

    return this.httpClient.get(url)
    .map((res) => {
      let checklistTemplates: ChecklistTemplateConfig[] = [];
      let data = res[ 'ChecklistTemplateConfigs' ];
      data.forEach(item => {
        checklistTemplates.push(new ChecklistTemplateConfig(item));
      });
      return checklistTemplates;
    });
  }

  getChecklistTemplateById(accountId: number, checklistTemplateId: number): Observable<ChecklistTemplateConfig> {
    let url: string = checklistTemplateAPI.getChecklistTemplateById(String(accountId), String(checklistTemplateId));
    return this.httpClient.get(url)
    .map((res) => {
      return new ChecklistTemplateConfig(res[ 'ChecklistTemplateConfig' ]);
    });
  }

  getDefaultChecklistTemplate(accountId: number, applicableProvinceCode: ProvinceCode, applicableMatterTypeCode: string): Observable<ChecklistTemplateConfig> {
    let url: string = checklistTemplateAPI.getDefaultChecklistTemplate(String(accountId));
    if (applicableProvinceCode) {
      url += '?province=' + applicableProvinceCode;
    }
    if (applicableMatterTypeCode) {
      url += '&matterType=' + applicableMatterTypeCode;
    }
    return this.httpClient.get(url)
    .map((res) => {
      return new ChecklistTemplateConfig(res[ 'ChecklistTemplateConfig' ]);
    });
  }

  saveChecklistTemplate(accountId: number, checklistTemplate: ChecklistTemplateConfig): Observable<ChecklistTemplateConfig> {
    if (checklistTemplate.id) {
      return this.updateChecklistTemplate(accountId, checklistTemplate);
    } else {
      return this.createChecklistTemplate(accountId, checklistTemplate);
    }
  }

  private createChecklistTemplate(accountId: number, checklistTemplate: ChecklistTemplateConfig): Observable<ChecklistTemplateConfig> {
    let url = checklistTemplateAPI.createChecklistTemplate(String(accountId));
    return this.httpClient.post(url, JSON.stringify(checklistTemplate))
    .map((res) => {
      return new ChecklistTemplateConfig(res[ 'ChecklistTemplateConfig' ]);
    });
  }

  private updateChecklistTemplate(accountId: number, checklistTemplate: ChecklistTemplateConfig): Observable<ChecklistTemplateConfig> {
    let url = checklistTemplateAPI.updateChecklistTemplate(String(accountId), String(checklistTemplate.id));
    return this.httpClient.put(url, JSON.stringify(checklistTemplate))
    .map((res) => {
      return new ChecklistTemplateConfig(res[ 'ChecklistTemplateConfig' ]);
    });
  }
}
