import {Telephone} from '../telephone';
import {Address} from '../address';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {TelephoneTypes} from '../telephone-types';

export class ReferredClient extends BaseEntity {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: string;
  email: string;
  telephones: Telephone[] = [];
  address: Address;

  constructor(referredClient?: ReferredClient) {
    super(referredClient);
    if (referredClient) {
      this.telephones = [];
      if (referredClient.telephones) {
        this.populatePhones(referredClient, [ TelephoneTypes.home, TelephoneTypes.cell, TelephoneTypes.work ]);
      }
      if (referredClient.address) {
        this.address = new Address(referredClient.address);
      }
    }
  }

  populatePhones(referredClient: ReferredClient, phoneTypes: string[]): void {
    for (const phoneType of phoneTypes) {
      let phone: Telephone = referredClient.telephones.find((t: Telephone) => t.phoneTypeCode === phoneType);
      if (phone) {
        this.telephones.push(new Telephone(phone));
      } else {
        phone = new Telephone();
        phone.phoneTypeCode = phoneType;
        this.telephones.push(phone);
      }
    }
  }

  getFullName(): string {
    return this.lastName + ', ' + this.firstName + (this.middleName ? ' ' + this.middleName : '');
  }

  surnameLastFullName(): string {
    return ((this.firstName ? this.firstName + ' ' : '') +
      (this.middleName ? this.middleName + ' ' : '') +
      (this.lastName ? this.lastName : '')).trim();
  }

  getPhone(): string {
    let phone: Telephone = this.telephones.find(phone => !!phone.telephoneNumber);
    return phone ? phone.telephoneNumber : '';
  }

  get homePhone(): string {
    return this.getPhoneByType(TelephoneTypes.home).telephoneNumber;
  }

  set homePhone(phone: string) {
    this.getPhoneByType(TelephoneTypes.home).telephoneNumber = phone;

  }

  getPhoneByType(type: string): Telephone {
    if (!this.telephones) {
      this.telephones = [];
    }

    let phone: Telephone = this.telephones.find((t: Telephone) => t.phoneTypeCode === type);
    if (!phone) {
      phone = new Telephone();
      phone.phoneTypeCode = type;
      this.telephones.push(phone);
    }
    return phone;
  }
}
