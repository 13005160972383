import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {Contact} from '../../../shared/contact';
import {Matter} from '../../../shared/matter';
import {MatterParticipant} from '../../../shared/matter-participant';
import {Cirf} from '../../../shared/cirf/cirf';
import {ModalResult} from '../../../../shared-main/enums';
import {CirfHelperService} from '../../../shared/cirf/cirf-helper.service';
import {OpportunitiesService} from '../../../../opportunities/opportunities.service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {WelcomeClientPrecedentModalComponent} from './welcome-client-precedent-modal.component';
import {DocumentProductionComponent, DocumentsForThisMatterComponent} from '../../../document-production';
import {MatterWorkItemsComponent} from '../../../matter-overview/matter-work-items.component';
import * as _ from 'lodash';
import {DocumentProductionService} from '../../../document-production/document-production.service';
import {SharedDocumentsPackage} from '../../../document-production/shared-documents-package';
import {Precedent} from '../../../shared/precedent';
import {SharedDocument} from '../../../../share-documents/shared-document';
import {Document} from '../../../document-production/document';
import {messages} from '../../../../common';
import {EmailFieldService, EmailKeys} from '../../../../shared-main/email-field/email-field-service';
import {PrecedentTypes} from '../../../shared/mortgage-precedent-type';
import {PrecedentService} from '../../../mortgages/mortgage/precedent/precedent.service';
import {Subject} from 'rxjs/Subject';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class WelcomeClientModalContext {
  matterCopy: Matter;
  originalMatter: Matter;
  cirf: Cirf;
  participant: MatterParticipant;
}

@Component({
  selector: 'welcome-client-modal-content',
  templateUrl: './welcome-client-modal.component.html',
  styleUrls: [ './welcome-client-modal.component.scss' ]
})
export class WelcomeClientModalComponent extends ModalComponent<WelcomeClientModalContext> implements OnInit {
  sendCirf = true;
  assignTasks = false;
  shareDocs = false;
  shareExisting = false;
  localMatter: Matter;
  documentTemplateIds: string[] = [];
  updatedCirf: Cirf;
  saveMatterRequired: boolean;
  cirfPrecedentId: number;

  @ViewChild('workItemsComponent') workItemsComponent: MatterWorkItemsComponent;
  @ViewChild('documentsForThisMatterComponent') documentsForThisMatterComponent: DocumentsForThisMatterComponent;
  @ViewChild('documentProductionComponent') documentProductionComponent: DocumentProductionComponent;

  constructor(public dialog: MatDialogRef<WelcomeClientModalComponent>,
              public dialogService: DialogService,
              public cirfHelperService: CirfHelperService,
              public opportunitiesService: OpportunitiesService,
              public documentProductionService: DocumentProductionService,
              public emailFieldService: EmailFieldService,
              public precedentService: PrecedentService,
              @Inject(MAT_DIALOG_DATA) context?: WelcomeClientModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.originalMatter && !!this.context.originalMatter.isMatterTypeDischarge) {
      this.sendCirf = false;
    }
    this.loadPrecedents();
  }

  async loadPrecedents(): Promise<void> {
    const precedents: Precedent[] = await this.precedentService.getPrecedents().toPromise();
    if (precedents && precedents.length) {
      const cirfPrecedent = precedents.find(precedent => precedent.precedentType === PrecedentTypes.CIRF && this.context.originalMatter.derivedMatterType === precedent.precedentName);
      if (cirfPrecedent) {
        this.cirfPrecedentId = cirfPrecedent.id;
      }
    }
  }

  initializeDocumentTab() {

  }

  get selectedContact(): Contact {
    const primaryClient = this.cirfHelperService.getPrimaryClient(this.context.originalMatter);
    return this.context.participant ? this.context.participant && this.context.participant.contact : primaryClient && primaryClient.contact;
  }

  get participant(): MatterParticipant {
    return this.context.participant ? this.context.participant : this.cirfHelperService.getPrimaryClient(this.context.originalMatter);
  }

  async send() {
    const continueWithoutTasks: boolean = await this.validateAssignTasksSelection();

    if (!continueWithoutTasks) {
      return;
    }
    const continueWithoutSharingDocs: boolean = await this.validateDocShareSelection();
    if (!continueWithoutSharingDocs) {
      return;
    }
    if (!this.sendCirf && !this.assignTasks && !this.shareDocs) {
      return;
    }

    if (this.sendCirf && !this.assignTasks && !this.shareDocs) {
      this.saveMatterRequired = false;
      await this.updateCirfAndSendEmail();
      this.dialog.close({action: ModalResult.OK, updatedCirf: this.updatedCirf});
    } else if (this.assignTasks || this.shareDocs) {
      this.saveMatterRequired = true;
      if (this.shareDocs && !this.shareExisting) {
        this.produceDocuments();
      } else {
        this.openPrecedentSelectionModal();
      }
    }
  }

  async updateCirfAndSendEmail(): Promise<void> {
    this.updatedCirf = await this.opportunitiesService.generateCIRFPackage(this.context.cirf).toPromise();
    await this.cirfHelperService.prepareAndSendCirfEmail(this.updatedCirf, this.context.originalMatter, null, this.context.participant);
    const primaryClient = this.cirfHelperService.getPrimaryClient(this.context.originalMatter);
    primaryClient.welcomePackagePrecedentId = this.cirfPrecedentId;
    primaryClient.welcomePackageStatus = 'SENT_WITH_CIRF';
  }

  openPrecedentSelectionModal() {
    this.dialogService.matDialogContent({
      content: WelcomeClientPrecedentModalComponent,
      context: {
        mainClientName: !this.context.participant ? this.selectedContact.contactFullNameStartWithFirstName : '',
        participantName: this.context.participant ? this.selectedContact.contactFullNameStartWithFirstName : '',
        matter: this.context.originalMatter,
        setTextToCirfPrecedent: this.sendCirf
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === ModalResult.OK) {
            if (result.welcomePackagePrecedentId) {
              if (this.context.participant) {
                this.context.participant.welcomePackagePrecedentId = result.welcomePackagePrecedentId;
                this.context.participant.welcomePackageStatus = this.sendCirf ? 'SENT_WITH_CIRF' : 'SENT_WITHOUT_CIRF';
              } else {
                const primaryClient = this.cirfHelperService.getPrimaryClient(this.context.originalMatter);
                primaryClient.welcomePackagePrecedentId = result.welcomePackagePrecedentId;
                primaryClient.welcomePackageStatus = this.sendCirf ? 'SENT_WITH_CIRF' : 'SENT_WITHOUT_CIRF';
              }
            }
            this.updateAndCloseModal((result.messagePrecedent ? result.messagePrecedent : ''));

          }
        }
      }
    });
  }

  async updateAndCloseModal(messagePrecedent: string): Promise<void> {
    try {
      if (this.sendCirf) {
        await this.updateCirfAndSendEmail();
      }
      if (this.assignTasks) {
        this.context.originalMatter.matterWorkItems = [ ...this.context.matterCopy.matterWorkItems ];
        this.sendTaskAssignmentEmail(messagePrecedent);
      }
      if (this.shareDocs) {
        await this.shareDocuments(messagePrecedent);
      }
      this.dialog.close({action: ModalResult.OK, updatedCirf: this.updatedCirf, saveMatterRequired: this.saveMatterRequired});
    } catch (e) {
      if (e && e.errorCode) {
        this.dialogService.confirmDialog('Welcome Package Error', messages.sharedDocumentsMessages.serverError, true, 'OK');
      } else {
        this.dialog.close({action: ModalResult.OK, updatedCirf: this.updatedCirf});
      }
    }

  }

  get stepNumberForSharingDocs(): string {
    if (!!this.context.originalMatter.isMatterTypeDischarge) {
      return this.isParticipantMainClient ? '2' : '1';
    } else {
      return '3';

    }
  }

  sendTaskAssignmentEmail(messagePrecedent: string) {
    const matterWorkItem = this.context.originalMatter.matterWorkItems.find(matterWork => {
      return matterWork.matterWorkItemTasks.some(itemTask => {
        return itemTask.isAssigned() && itemTask.assignedToParticipantEmail === this.selectedContact.email;
      });
    });
    if (matterWorkItem) {
      const matterWorkItemTask = matterWorkItem.matterWorkItemTasks.find(itemTask => {
        return itemTask.isAssigned() && itemTask.assignedToParticipantEmail === this.selectedContact.email;
      });
      if (matterWorkItemTask) {
        this.sendEmailAndCloseModal(matterWorkItemTask.inviteGuid, this.selectedContact.email, messagePrecedent);
      }
    }
  }

  async shareDocuments(messagePrecedent: string): Promise<void> {
    const selectedMatterDocuments: Document[] = await this.buildMatterDocumentSelection();
    // console.table(selectedMatterDocuments);
    const sharedDocumentsPackage: SharedDocumentsPackage = this.initializeSharedDocumentsPackage(selectedMatterDocuments);
    // console.table(sharedDocumentsPackage);
    await this.saveSharedDocPackageAndSendEmail(sharedDocumentsPackage, messagePrecedent);
  }

  async validateAssignTasksSelection(): Promise<boolean> {
    if (this.assignTasks) {
      const noChangesOnTasks = _.isEqual(this.context.originalMatter.matterWorkItems, this.context.matterCopy.matterWorkItems);
      if (noChangesOnTasks) {
        const ignoreTasks = await this.dialogService.confirm('Information', 'No tasks were selected.  Do you wish to proceed without assigning tasks?', false, 'Ok', 'No').toPromise();
        if (ignoreTasks) {
          this.assignTasks = false;
        } else {
          return Promise.resolve(false);
        }
      }
    }
    return Promise.resolve(true);
  }

  async validateDocShareSelection(): Promise<boolean> {
    if (this.shareDocs) {
      if (this.shareExisting && this.documentsForThisMatterComponent) {
        if (this.documentsForThisMatterComponent.selectedDocuments.length === 0) {
          const ignoreShareExisting = await this.dialogService.confirm('Information', 'No documents were selected.  Do you wish to proceed without sharing documents?', false, 'Ok', 'No').toPromise();
          if (ignoreShareExisting) {
            this.shareDocs = false;
          } else {
            return Promise.resolve(false);
          }
        }
      } else {
        if (this.documentProductionComponent &&
          this.documentProductionComponent.documentProductionTemplate &&
          this.documentProductionComponent.documentProductionTemplate.documentTemplateIds) {
          if (this.documentProductionComponent.documentProductionTemplate.documentTemplateIds.length === 0) {
            const ignoreShareProduced = await this.dialogService.confirm('Information', 'No documents were selected to be produced.  Do you wish to proceed without sharing documents?', false, 'Ok', 'No').toPromise();
            if (ignoreShareProduced) {
              this.shareDocs = false;
            } else {
              return Promise.resolve(false);
            }
          }
        }
      }
    }
    return Promise.resolve(true);
  }

  produceDocuments() {
    if (this.shareDocs && !this.shareExisting) {
      if (this.documentProductionComponent &&
        this.documentProductionComponent.documentProductionTemplate &&
        this.documentProductionComponent.documentProductionTemplate.documentTemplateIds) {
        if (this.documentProductionComponent.documentProductionTemplate.documentTemplateIds.length > 0) {
          this.documentTemplateIds = [ ...this.documentProductionComponent.documentProductionTemplate.documentTemplateIds ];
          let validationSubject = new Subject<boolean>();
          this.documentProductionComponent.documentProductionTemplate.validateMatterAndProduceDocuments(this.documentTemplateIds, validationSubject);
          if (this.documentTemplateIds) {
            validationSubject.subscribe(isDocumentGenCompleted => {
              if (isDocumentGenCompleted) {
                this.documentProductionComponent.documentProductionTemplate.startPullingProducedDocuments(this.documentTemplateIds).subscribe(isDocumentProduced => {
                  if (isDocumentProduced) {
                    this.openPrecedentSelectionModal();
                  }
                });
              }
            });
          }
        }
      }
    }
  }

  async buildMatterDocumentSelection(): Promise<Document[]> {
    let selectedMatterDocuments: Document[] = [];
    if (this.shareDocs) {
      if (this.shareExisting && this.documentsForThisMatterComponent) {
        selectedMatterDocuments.push(...this.documentsForThisMatterComponent.matterDocuments.filter(matterDocument => {
          return this.documentsForThisMatterComponent.selectedDocuments.find(selectedDocument => selectedDocument.documentId === matterDocument.id);
        }));
      } else {
        if (this.documentProductionComponent &&
          this.documentProductionComponent.documentProductionTemplate &&
          this.documentTemplateIds.length) {
          selectedMatterDocuments = await this.getProducedDocuments();
        }
      }
    }
    return Promise.resolve(selectedMatterDocuments);
  }

  async getProducedDocuments(): Promise<Document[]> {
    const selectedMatterDocuments: Document[] = [];
    const documents: Document[] = await this.documentProductionService.getDocuments(this.context.originalMatter.id, true).toPromise();
    //console.table(documents);
    if (documents && documents.length) {
      this.documentTemplateIds.forEach(concatenatedTemplateId => {
        let templateId = 0;
        if (concatenatedTemplateId.indexOf('-') > -1) {
          templateId = +concatenatedTemplateId.split('-')[ 0 ];
        } else {
          templateId = +concatenatedTemplateId;
        }
        const doc = documents.find(value => value.templateId === templateId);
        if (doc) {
          selectedMatterDocuments.push(doc);
        }
      });
    }
    return Promise.resolve(selectedMatterDocuments);
  }

  initializeSharedDocumentsPackage(selectedMatterDocuments: Document[]): SharedDocumentsPackage {
    const sharedDocumentsPackage = new SharedDocumentsPackage();
    sharedDocumentsPackage.matterId = this.context.originalMatter.id;
    sharedDocumentsPackage.packageType = 'CONNECT';
    sharedDocumentsPackage.recipientDearName = this.selectedContact.dear ? this.selectedContact.dear : this.selectedContact.contactFullNameStartWithFirstName;
    sharedDocumentsPackage.recipientName = this.selectedContact.contactFullNameStartWithFirstName;
    sharedDocumentsPackage.connectRecipientRole = this.participant.primary ? ('Primary ' + this.context.originalMatter.mainClientTitle) : this.context.originalMatter.mainClientTitle;
    sharedDocumentsPackage.connectRecipientRoleType = 'CLIENT';
    sharedDocumentsPackage.connectRecipientEmail = this.selectedContact.email;
    sharedDocumentsPackage.documents = selectedMatterDocuments.map(doc => {
      const sharedDoc: SharedDocument = new SharedDocument();
      sharedDoc.id = doc.id;
      sharedDoc.sharedDocumentType = doc.isThirdPartyDocument ? 'THIRD_PARTY' : 'UNITY';
      sharedDoc.name = doc.documentName;
      sharedDoc.originalName = doc.originalDocumentName;
      return sharedDoc;
    });
    return sharedDocumentsPackage;
  }

  async saveSharedDocPackageAndSendEmail(sharedDocumentsPackage: SharedDocumentsPackage, message: string): Promise<void> {
    const savedSharedDocPackage = await this.documentProductionService.generateShareDocsPackage(sharedDocumentsPackage).toPromise();
    if (savedSharedDocPackage) {
      this.sendEmailAndCloseModal(savedSharedDocPackage.guid, savedSharedDocPackage.connectRecipientEmail, message);
    }
  }

  sendEmailAndCloseModal(guid: string, email: string, messagePrecedent: string) {
    const baseUrl = this.cirfHelperService.getUnityConnectBaseUrl(guid);
    const subject: string = this.emailFieldService.getMailSubject(EmailKeys.matterOpening);
    const dearText = this.selectedContact && this.selectedContact.dear ? this.selectedContact.dear : '';
    const body = this.cirfHelperService.createCIRFEmailBody(baseUrl, messagePrecedent, dearText);
    this.emailFieldService.openLocalEmailClient(email, subject, body).subscribe(value => {
      //this.dialog.close({action: ModalResult.OK, updatedCirf: updatedCirf});

    });
  }

  cancel() {
    this.dialog.close({action: ModalResult.Cancel, updatedCirf: this.updatedCirf});
  }

  get isParticipantMainClient(): boolean {
    if (!this.context.participant) {
      return true;
    }
    const primaryClient = this.cirfHelperService.getPrimaryClient(this.context.originalMatter);
    return this.context.participant.matterParticipantId == primaryClient.matterParticipantId;
  }

}

