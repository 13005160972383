<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{getModalTitle()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body min-height-460">
        <div *ngIf="closingDatePayment">
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-2"
                       dp-province-field-label="lateClosingInterestModal.cashToClose"
                       [label-province-code]="matterProvince"
                        >Cash to close</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="cashToClose"
                           name="cashToClose"
                           [(ngModel)]="closingDatePayment.cashToClose"
                           (ngModelChange)="syncCashToClose()"
                           [dp-read-only]="closingDatePayment.sameAsSoa"
                           dp-currency
                           class="form-control"/>
                </div>
                <div class="col-lg-6">
                    <dp-checkbox fieldId="cashToCloseCheckBox">
                        <input type="checkbox"
                               name="cashToCloseCheckBox"
                               id="cashToCloseCheckBox"
                               [(ngModel)]="closingDatePayment.sameAsSoa"
                               (ngModelChange)="syncCashToClose()"
                               [checked]="closingDatePayment.sameAsSoa"/>
                    </dp-checkbox>
                    <label for="cashToCloseCheckBox">same as Statement of Adjustment</label>
                </div>
            </div>


            <div class="form-group col-lg-12">
                <label class="control-label col-lg-2">Delayed Amount {{context.matter.isClosingDayTypeCashDifference() ? '(e.g. mtg
                    proceeds)':''}}</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="delayedAmount"
                           name="delayedAmount"
                           (ngModelChange)="syncDelayedAmount()"
                           [(ngModel)]="closingDatePayment.delayedAmount"
                           [dp-read-only]="!isAllowedToManualUpdateDelayedAmount"
                           dp-currency
                           allow-negative="true"
                           class="form-control"/>
                </div>
                <div class="col-lg-6" *ngIf="!context.matter.isClosingDayTypeNil() && !context.matter.isSale">
                    <dp-checkbox fieldId="delayedAmountCheckBox">
                        <input type="checkbox"
                               name="delayedAmountCheckBox"
                               [disabled]="closingDatePayment.autoCalculate"
                               id="delayedAmountCheckBox"
                               [(ngModel)]="closingDatePayment.delayedAmountSameAsTabG"
                               (ngModelChange)="syncDelayedAmount()"
                               [checked]="closingDatePayment.delayedAmountSameAsTabG"/>
                    </dp-checkbox>
                    <label for="delayedAmountCheckBox">same as Mortgages Tab</label>
                </div>
            </div>


            <div class="form-group col-lg-12" *ngIf="!context.matter.isClosingDayTypeNil()">
                <label class="control-label col-lg-2"
                       dp-province-field-label="lateClosingInterestModal.cashDifference"
                       [label-province-code]="matterProvince">Cash Difference</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="cashDifference"
                           name="cashDifference"
                           [(ngModel)]="closingDatePayment.cashDifference"
                           allow-negative="true"
                           (ngModelChange)="syncSegmentAdjustment()"
                           [dp-read-only]="!closingDatePayment.autoCalculate"
                           dp-currency
                           class="form-control"/>
                </div>
                <div class="col-lg-6">
                    <dp-checkbox fieldId="cashDifferenceCheckBox">
                        <input type="checkbox"
                               name="cashDifferenceCheckBox"
                               id="cashDifferenceCheckBox"
                               [(ngModel)]="closingDatePayment.autoCalculate"
                               (ngModelChange)="syncSegmentAdjustment()"
                               [checked]="closingDatePayment.autoCalculate"/>
                    </dp-checkbox>
                    <label for="cashDifferenceCheckBox">do not auto-calculate</label>
                </div>
            </div>


            <div class="form-group col-lg-12">
                <label class="control-label col-lg-2">Registered Mortgage Amount</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="registeredAmount"
                           name="registeredAmount"
                           [(ngModel)]="closingDatePayment.registeredMortgageAmount"
                           [dp-read-only]="closingDatePayment.registeredMortgageAmountSameAsTabG"
                           dp-currency
                           class="form-control"/>
                </div>
                <div class="col-lg-6" *ngIf="!context.matter.isSale">
                    <dp-checkbox fieldId="registeredAmountCheckBox">
                        <input type="checkbox"
                               name="registeredAmountCheckBox"
                               id="registeredAmountCheckBox"
                               [(ngModel)]="closingDatePayment.registeredMortgageAmountSameAsTabG"
                               (ngModelChange)="syncMortgageAmount()"
                               [checked]="closingDatePayment.registeredMortgageAmountSameAsTabG"/>
                    </dp-checkbox>
                    <label for="registeredAmountCheckBox">same as Mortgages Tab</label>
                </div>
            </div>


            <div class="col-lg-12 padding-top-10 padding-bottom-10 border-top-2">

                <div class="form-group col-lg-12">
                    <ng-container *ngIf="closingDatePayment.isLateClosing()">
                        <label class="control-label col-lg-2"
                               dp-province-field-label="lateClosingInterestModal.lateClosingDate"
                               [label-province-code]="matterProvince">Late Closing Date</label>
                        <div class="col-lg-4">
                            <dp-partial-date
                                #lateClosingDate
                                [fullDateRequired]="true"
                                [fieldKey]="'modal.closingDatePayment.lateClosingDate'"
                                dp-error-validator
                                [inputDate]="closingDatePayment.lateClosingDate"
                                (dateChange)="onDateChangeLateClosingDate($event)">
                            </dp-partial-date>
                        </div>
                    </ng-container>
                </div>

            </div>

            <ng-container *ngIf="closingDatePayment.isLateClosing()">

                <div class="form-group col-lg-12 padding-left-15" *ngIf="closingDatePayment.isLateClosingDateValid()">
                    <label class="control-label text-left">{{closingDatePayment.daysToCalculate}}</label>
                </div>

                <div class="form-group col-lg-12">
                    <table class="closingPaymentTable" *ngIf="showClosingPaymentTable()">
                        <thead>
                        <th>
                            Principal Amount
                        </th>
                        <th>

                        </th>
                        <th>
                            Interest Rate
                        </th>
                        <th>
                            Effective from
                        </th>
                        <th>
                            To
                        </th>
                        <th>
                            Late Interest Amount
                        </th>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let item of closingDatePayment.lateClosingInterests;let i = index;let last = last">
                            <tr>
                                <td>
                                    {{principalAmountText(item)}}
                                </td>
                                <td>
                                    <dp-dropdown-menu style="display:inline-block;" [addTabStopClass]="true"
                                                      [dropDownItems]="[editButtonLabel, deleteButtonLabel]"
                                                      (itemClicked)="burgerMenuClicked($event, i , item)"
                                    ></dp-dropdown-menu>
                                </td>
                                <td>
                                    {{formatInterestRate(item.interestRate)}}
                                </td>
                                <td>
                                    {{formatDate(item.fromDate)}}
                                </td>
                                <td>
                                    {{formatDate(item.toDate, last)}}
                                </td>
                                <td>
                                    {{ calculateInterest(item)}}
                                </td>
                            </tr>
                        </ng-container>

                        </tbody>
                    </table>
                    <div class="form-group col-lg-12 padding-top-10 padding-left-15" *ngIf="showAddLateClosingInterestLink()">
                        <a class="text-left inline-block width-25-percent" (click)="openLateClosingModal()"> {{closingDatePayment && closingDatePayment.lateClosingInterests.length > 0 ? 'Add Another Interest Range' : 'Add Late Closing Interest Range'}}</a>

                        <ng-container *ngIf="closingDatePayment.isLateClosingDateValid() && closingDatePayment && closingDatePayment.lateClosingInterests.length > 0">
                            <span class="inline-block text-right" style="width:57%">
                                <label class="control-label" dp-province-field-label="lateClosingInterestModal.lateInterestAmtTotal"
                                                            [label-province-code]="matterProvince">Late Interest Amount Total</label>
                            </span>
                            <div class="inline-block padding-left-5">
                                <input type="text"
                                       id="lateInterestAmountTotal{i+currentTimeStamp}}"
                                       name="lateInterestAmountTotal{{i+currentTimeStamp}}"
                                       [ngModel]="getInterestAmountTotal()"
                                       dp-read-only="true"
                                       class="form-control padding-left-5  width-100"/>
                            </div>
                        </ng-container>

                    </div>
                    <div class="form-group col-lg-12 padding-top-10 padding-left-15">
                         <textarea class="form-control"
                                   *ngIf="closingDatePayment.isLateClosingDateValid()"
                                   rows="4"
                                   id="lateInterestDescription"
                                   name="lateInterestDescription"
                                   [innerHTML]="getLateInterestDescription()"
                         >
                        </textarea>

                        <ng-container *ngIf="closingDatePayment && closingDatePayment.lateClosingInterests.length > 0">
                            <ng-container *dpShowByProvince="'lateClosingInterestModal.interestEstimateOn';code:context.matter.provinceCode">
                                <div class="padding-top-10" *ngIf="closingDatePayment.isLateClosingDateValid()">
                                    <span class="inline-block text-right" style="width:82%">
                                        <label class="control-label" dp-province-field-label="lateClosingInterestModal.lateInterestAmtTotal"
                                               [label-province-code]="matterProvince">Interest Re Delay</label>
                                    </span>
                                    <div class="inline-block padding-left-5">
                                        <input type="text"
                                               id="interestReDelay"
                                               name="interestReDelay"
                                               [ngModel]="getInterestAmountTotal()"
                                               dp-read-only="true"
                                               class="form-control padding-left-5  width-100"/>
                                    </div>
                                </div>

                                <div class="form-group inline-block padding-left-5 col-lg-12 padding-top-10">
                                    <span class="inline-block text-right col-lg-6" style="width:74%">
                                        <label class="control-label">{{getInterestEstimateOnLabel()}}</label>
                                    </span>
                                    <input type="text"
                                           id="interestEstimateOn"
                                           name="interestEstimateOn"
                                           [(ngModel)]="closingDatePayment.interestEstimateDays"
                                           maxlength="4"
                                           dp-noWhiteSpace
                                           numberOnly
                                           (blur)="verifyInterestEstimateDays()"
                                           class="form-control padding-left-5 width-50 col-lg-1 text-right"/>
                                    <span class="col-lg-1 width-40  padding-top-8">days</span>
                                    <input type="text"
                                           id="estimatedInterest"
                                           name="estimatedInterest"
                                           [ngModel]="getEstimatedInterest()"
                                           dp-default-currency
                                           dp-currency
                                           dp-read-only="true"
                                           class="form-control padding-left-5 width-100 col-lg-4"/>
                                </div>
                                <div *ngIf="closingDatePayment.isLateClosingDateValid()">
                                    <span class="inline-block text-right" style="width:82%">
                                        <label class="control-label">Difference</label>
                                    </span>
                                    <div class="inline-block  padding-left-5">
                                        <input type="text"
                                               id="difference"
                                               name="difference"
                                               [ngModel]="getDifference()"
                                               dp-default-currency
                                               dp-currency
                                               allow-negative="true"
                                               dp-read-only="true"
                                               class="form-control padding-left-5 width-100"/>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>


                </div>

            </ng-container>
        </div>
    </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                    <span>Ok</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>

</div>
