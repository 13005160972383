import {Account} from '../accounts/shared/account';
import {Component, ElementRef, Inject, ViewChild, ViewChildren} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {AuthZService} from '../../core/authz/auth-z.service';
import {AccountService} from '../accounts/account.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {TrustAccountsService} from './trust-accounts.service';
import {TrustAccount} from './trust-account';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {AddEditTrustAccountModal} from './add-edit-trust-account-modal.component';
import {ProvinceCode} from '../accounts/shared/account-province';
import {Project} from '../../projects/shared/project';

class TrustAccountsModalContext {
  account: Account;
  configurationForProvince: ProvinceCode;
  isForm4BankAccount?: boolean = false;
  project?: Project;
}

@Component({
  selector: 'dp-trust-accounts-modal-content',
  templateUrl: 'trust-accounts-modal.component.html',
  providers: [DialogService],
  styleUrls: ['./trust-accounts-modal.component.scss']
})
export class TrustAccountsModal extends ModalComponent<TrustAccountsModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('addNewBtn') addNewBtn: ElementRef;
  @ViewChildren('trustAccountRows') trustAccountRows;

  account: Account;
  trustAccounts: TrustAccount[] = [];
  fundsCode: {} = {INTO_TRUST_ACCOUNT: 'Into Account', BY_CHEQUE: 'By Cheque'};
  burgerMenuItems: string[] = ['Edit', 'Delete'];
  isDirty: boolean = false;
  selectedRowIndex = -1;
  project: Project;
  isForm4BankAccount: boolean = false;
  firmTrustAccountOptions: any[] = [];

  constructor(
    public dialog: MatDialogRef<TrustAccountsModal, TrustAccountsModalContext>,
    public authZService: AuthZService,
    public dialogService: DialogService,
    public trustAccountsService: TrustAccountsService,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) context?: TrustAccountsModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.account) {
      this.account = new Account(this.context.account);
    } else {
      let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      this.accountService.getAccount(loggedUserId).subscribe((account: Account) => {
        this.account = new Account(account);
      });
    }
    this.project = this.context.project ? new Project(this.context.project) : new Project();
    this.isForm4BankAccount = this.context.isForm4BankAccount ? this.context.isForm4BankAccount : false;
  }

  ngAfterViewInit() {
    this.loadProjectTrustAccounts();
    this.loadTrustAccounts();
  }

  loadProjectTrustAccounts() {
    this.trustAccounts = [];

    this.trustAccountsService
      .getTrustAccounts(this.getId(), this.context.configurationForProvince, this.project.id)
      .subscribe((res: any) => {
        this.trustAccounts = res;
        if (this.trustAccounts.length > 0) {
          this.selectedRowIndex = 0;
          this.setFocusOnRow(0);
        } else {
          this.addNewBtn.nativeElement.focus();
        }
      });
  }

  async loadTrustAccounts() {
    let firmTrustAccounts: TrustAccount[] = await this.trustAccountsService
      .getTrustAccounts(this.getId(), this.context.configurationForProvince)
      .toPromise();
    firmTrustAccounts.forEach((account) => {
      this.firmTrustAccountOptions.push({
        text: this.formatTrustAccountDetails(account),
        key: account.id,
        data: account
      });
    });
  }

  formatTrustAccountDetails(trustAccount: TrustAccount): string {
    return (
      trustAccount.mortgageeName + ' - ' + (trustAccount.trustAccountNumber ? trustAccount.trustAccountNumber : '')
    );
  }

  setDirtyFlag(dirty: boolean) {
    this.isDirty = dirty;
  }

  selectFirmTrustAccount(event) {
    if (event && event.data) {
      let trustAccount: TrustAccount = new TrustAccount(event.data);
      trustAccount.clearAllIds();
      trustAccount.projectId = this.project.id;
      trustAccount.defaultForProject = this.trustAccounts && this.trustAccounts.length == 0;
      this.trustAccountsService.saveTrustAccount(this.getId(), trustAccount).subscribe((res: TrustAccount) => {
        this.trustAccounts.push(res);
        this.setDirtyFlag(false);
      });
    }
  }

  isRowSelected(index: number): boolean {
    return this.selectedRowIndex === index;
  }

  getId(): number {
    return this.account ? this.account.id : +sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }

  ok(): void {
    this.close();
  }

  close(): void {
    this.dialog.close();
  }

  addNewTrustAccount(): void {
    this.openEditor(null);
  }

  openEditor(trustAccount: TrustAccount) {
    let trustAccountCopy: TrustAccount = null;
    // If trustAccount is not null, it should to pass a copy.
    if (trustAccount) {
      trustAccountCopy = new TrustAccount(trustAccount);
    }
    this.dialogService.matDialogContent({
      content: AddEditTrustAccountModal,
      context: {
        account: this.account,
        trustAccount: trustAccountCopy,
        configurationForProvince: this.context.configurationForProvince,
        projectId: this.project.id,
        isForm4BankAccount: this.isForm4BankAccount,
        defaultForProject: !!(this.trustAccounts.length == 0)
      },
      onFulfillment: (result: any) => {
        console.log('trustAccount editor| onFulfillment | result=', result);
        if (trustAccount == null && result) {
          this.trustAccounts.push(result);
        } else if (trustAccount && result) {
          //Update the old trust
          const trustIndex = this.trustAccounts.findIndex((item) => item.id === trustAccountCopy.id);
          if (trustIndex > -1) {
            // Replace the old Trust with the new onr
            this.trustAccounts.splice(trustIndex, 1, result);
          }
        }
        this.setFocusOnRow(this.selectedRowIndex);
      },
      onRejection: (result: any) => {
        console.log('trustAccount editor| onRejection | result=', result);
        this.setFocusOnRow(this.selectedRowIndex);
      }
    });
  }

  setFocusOnRow = (index) => {
    setTimeout(() => {
      let elem = this.trustAccountRows._results[index].nativeElement.children[0];
      elem.children[0].focus();
    }, 300);
  };

  onKeyPress(event: KeyboardEvent): void {
    if (event.keyCode === 40) {
      //Down arrow key
      event.preventDefault();
      if (this.selectedRowIndex < this.trustAccounts.length - 1) {
        this.toggleTrustAccountRowSelect(this.selectedRowIndex + 1);
      }
    } else if (event.keyCode === 38) {
      //Up arrow key
      event.preventDefault();
      if (this.selectedRowIndex > 0) {
        this.toggleTrustAccountRowSelect(this.selectedRowIndex - 1);
      }
    } else if (event.keyCode === 13) {
      event.preventDefault();
      this.openEditor(this.trustAccounts[this.selectedRowIndex]);
    } else if (event.keyCode == 46) {
      event.preventDefault();
      if (this.selectedRowIndex > -1) {
        this.deleteTrustAccount(this.selectedRowIndex);
      }
    }
  }

  toggleTrustAccountRowSelect(index: number) {
    this.selectedRowIndex = index;
    this.setFocusOnRow(index);
  }

  deleteTrustAccount(index: number) {
    // same delete rules as account trust
    // https://confluence/pages/viewpage.action?spaceKey=DPPM01&title=DPPM-T010-E020-U020-130+-+Manage+Trust+Accounts+on+Lawyers+Staff+Profile
    // If the Trust Account that is being removed is flagged as the "Default Account" - when it is removed from the list of selected accounts, the next
    // one on that list is automatically made the default account.
    // If the Trust Account being removed is the "Default Account" and is also the last account on the list of selected accounts, when it is removed the first one on the list is automatically made the default account.
    // If the Trust Account being removed is the only account on the list of selected accounts I am still able to remove it.  A Default Account is only required if there are selected accounts.
    let trustAccount = this.trustAccounts[index];
    this.dialogService
      .confirm('Confirmation', 'Are you sure that you would like to delete this Trust Account?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          if (trustAccount.defaultForProject) {
            if (this.trustAccounts[index + 1]) {
              this.changeDefaultAccount(this.trustAccounts[index + 1]);
            } else if (this.trustAccounts[0] && this.trustAccounts.length > 1) {
              this.changeDefaultAccount(this.trustAccounts[0]);
            }
          }
          this.trustAccountsService.deleteTrustAccount(this.getId(), trustAccount).subscribe((response: any) => {
            this.trustAccounts.splice(index, 1);
          });
        }
      });
  }

  changeDefaultAccount(trustAccount: TrustAccount) {
    this.trustAccounts.forEach((ta) => {
      if (ta.defaultForProject) {
        ta.defaultForProject = false;
        this.trustAccountsService.saveTrustAccount(this.getId(), ta).subscribe((_: any) => {});
      }
    });
    let updatedTrustAccount: TrustAccount = this.trustAccounts.find((ta) => ta == trustAccount);
    updatedTrustAccount.defaultForProject = true;
    this.trustAccountsService.saveTrustAccount(this.getId(), updatedTrustAccount).subscribe((_: any) => {
      this.loadProjectTrustAccounts();
    });
  }

  burgerMenuClicked(event, i) {
    if (event == 'Edit') {
      this.openEditor(this.trustAccounts[i]);
    }
    if (event == 'Delete') {
      this.deleteTrustAccount(i);
    }
  }
}
