import {CommonModule} from '@angular/common';
import {GoCardlessRedirectComponent} from './gocardless-redirect.component';
import {InjectionToken, NgModule} from '@angular/core';
import {GoCardlessService} from './gocardless.service';
import {RouterModule} from '@angular/router';
import {goCardlessRoutes} from './gocardless.routes';

export const GOCARDLESS_API_URL = new InjectionToken<string>('GOCARDLESS_API_URL');
export const UNITY_API_URL = new InjectionToken<string>('UNITY_API_URL');

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(goCardlessRoutes)
  ],
  declarations: [
    GoCardlessRedirectComponent
  ],
  providers: [
    GoCardlessService
  ],
  exports: [
    GoCardlessRedirectComponent
  ]
})
export class GoCardlessModule {
}