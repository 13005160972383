import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {Observable} from 'rxjs/Observable';
import {saleCommissionApi} from './sale-commission-config-api';
import {dropDowns} from '../../matters/shared/matter-drop-downs';

@Injectable()
export class SaleCommissionConfigService {

  constructor(private http: HttpClient) {
  }

  getSaleCommission(accountId: string): Observable<any> {
    let url = saleCommissionApi.getSaleCommissionApi.replace('{accountId}', accountId);
    return this.http.get(url).map((res) => res[ 'ConfigurationType' ]);
  }

  saveSaleCommission(configurationTypeDto, accountId: string) {
    let url = saleCommissionApi.saveSaleCommissionApi.replace('{accountId}', accountId);
    return this.http.put(url, JSON.stringify(configurationTypeDto));
  }

  getCommissionDefaultValue(accountId: string, matter) {
    return this.getSaleCommission(accountId).subscribe(config => {
        if (config && config.configurationOptionValues && config.configurationOptionValues.length > 0) {
          if (!matter.isTemplateMatterForMassUpdate) {
            if (config.configurationOptionValues[ 0 ].configurationValue) {
              matter.commissionPaidTo = config.configurationOptionValues[ 0 ].configurationValue;
            } else {
              matter.commissionPaidTo = dropDowns.CommissionPaidToOptions[ 0 ].value;
            }
          }
        }
      },
      error => {
        console.log('Failed to retrieve SaleCommisson config option for this account:', error);
      }
    );
  }
}
