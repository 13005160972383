<!-- modal -->
<form class="form-horizontal" >
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10">Document Sharing</div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-lg-12 padding-top-10">
            <div class="col-lg-2 text-right">
                <label class="control-label"></label>
            </div>
            <div class="col-lg-8"><label class="control-label">{{staticText}}</label></div>
        </div>
        <div class="form-group"  statusBarHelp tabindex="0" >
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label text-right">Message</label>
            </div>
            <div class="col-lg-8" >
                    <textarea class="form-control" style="height: 200px"
                              name="unitySignatureBlock"
                              id="unitySignatureBlock"
                              [(ngModel)]="message">
                    </textarea>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtnSave" class="dp-btn" (click)="sendDeclineMessage()">
                <span>Send</span>
            </button>

            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
