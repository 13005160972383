<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-7">
            <h1>{{context.title}}</h1>
        </div>

    </div>
    <div class="row modal-body view-port" *ngIf="docuSignUrl">
        <iframe id="docuSignContent" height="100%" width="100%" [src]="docuSignUrl" #docuSignFrame
                ></iframe>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
        </div>
    </div>
</div>
