import moment from 'moment';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Component, Inject, ViewChild} from '@angular/core';
import {
  COMMON_EXPENSES,
  CONDOMINIUM_FEES,
  STRATA_FEES,
  SoAdjExpenseOccupancyPeriod,
  TotalEffectiveTypes
} from './so-adj-expense-occupancy-period';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {Matter} from '../../../shared/matter';
import SharedUtils from '../../../../shared-main/utils';
import {Utils} from '../../../shared/utils';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {UnitLevelPlanModalComponent} from '../../../property-teranet/unit-level-plan/unit-level-plan.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {MatterService} from '../../../matter.service';
import {SelectUnitLevelPlanResult} from '../../../shared/select-unit-level-plan-result';
import {StatementAdjustment} from '../../statement-adjustment';
import Moment = moment.Moment;
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class TotalDuringOccupancyPeriodModalContext {
  public closingDate: string;
  public soAdjExpenseOccupancyPeriod: SoAdjExpenseOccupancyPeriod;
  public provinceHstRateSlab: ConsiderationTaxes;
  public matter: Matter;
  public isProjectTab: boolean = true;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-total-during-occupancy-period',
  templateUrl: './total-during-occupancy-period.modal.component.html',
  // providers   : [CurrencyPipe, ErrorService]
  providers: [ErrorService, MatterService]
})
export class TotalDuringOccupancyPeriodModal extends ModalComponent<TotalDuringOccupancyPeriodModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = 'Closing Date';
  soAdjExpenseOccupancyPeriod: SoAdjExpenseOccupancyPeriod;
  adjustedDropDown: SelectItem[] = dropDowns.soAdjExpenseOccupancyPeriodAdjustedOptions;
  adjustedForDropDown: SelectItem[];
  effectiveFromTypeDropDown: SelectItem[] = dropDowns.effectiveFromTypeOptions;
  effectiveTillTypeDropDown: SelectItem[] = dropDowns.effectiveTillTypeOptions;
  useAmountFromPropertyTabDropDown: SelectItem[] = dropDowns.useAmountFromPropertyTabOptions;
  soAdjExpenseOccupancyPeriodChargePurchaserOptions: SelectItem[] =
    dropDowns.soAdjExpenseOccupancyPeriodChargePurchaserOptions;
  expensesAmount: number;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TotalDuringOccupancyPeriodModal>,
    public errorService: ErrorService,
    public matterService: MatterService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TotalDuringOccupancyPeriodModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.adjustedForDropDown = SharedUtils.loadTaxSelectItem(
      this.context.provinceHstRateSlab.rateType,
      this.context.provinceHstRateSlab.hstProvincialPortion
    );

    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }

    if (!this.context.soAdjExpenseOccupancyPeriod) {
      this.soAdjExpenseOccupancyPeriod = new SoAdjExpenseOccupancyPeriod();
      this.newAdj = true;
      // this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense = false;
    } else {
      this.soAdjExpenseOccupancyPeriod = new SoAdjExpenseOccupancyPeriod(this.context.soAdjExpenseOccupancyPeriod);
    }
    // if(this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense === undefined ){
    //     this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense = false;
    // }
    if (!this.soAdjExpenseOccupancyPeriod.effectiveFromType) {
      this.soAdjExpenseOccupancyPeriod.effectiveFromType = TotalEffectiveTypes.NO_START_DATE;
    }
    if (!this.soAdjExpenseOccupancyPeriod.effectiveTillType) {
      this.soAdjExpenseOccupancyPeriod.effectiveTillType = TotalEffectiveTypes.NO_END_DATE;
    }
    if (!this.soAdjExpenseOccupancyPeriod.adjustedFor) {
      this.soAdjExpenseOccupancyPeriod.adjustedFor = this.adjustedForDropDown[0].value;
    }
    if (!this.soAdjExpenseOccupancyPeriod.isExpenseOccupancyAdjusted) {
      this.soAdjExpenseOccupancyPeriod.isExpenseOccupancyAdjusted = 'NO';
    }
    if (
      this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTab === undefined ||
      this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTab == true
    ) {
      this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTab = true;
      if (!this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense) {
        this.soAdjExpenseOccupancyPeriod.expensesAmount =
          this.context.matter.matterPropertyWithCondo &&
          this.context.matter.matterPropertyWithCondo.condominiumTotalExpenses &&
          this.context.matter.matterPropertyWithCondo.condominiumTotalExpenses.valueOf();
      } else {
        this.soAdjExpenseOccupancyPeriod.expensesAmount = 0;
      }
    }

    if (this.soAdjExpenseOccupancyPeriod.chargePurchaser === undefined) {
      this.soAdjExpenseOccupancyPeriod.chargePurchaser = true;
    }
    if (!this.soAdjExpenseOccupancyPeriod.adjustmentHeading) {
      this.soAdjExpenseOccupancyPeriod.adjustmentHeading = this.context.matter.isMatterProvinceON
        ? COMMON_EXPENSES
        : this.context.matter.isMatterProvinceBC
          ? STRATA_FEES
          : CONDOMINIUM_FEES;
    }
    if (!this.soAdjExpenseOccupancyPeriod.adjustPercentage) {
      this.soAdjExpenseOccupancyPeriod.adjustPercentage = this.context.matter.soaHst;
    }
  }

  getUnitLevelPlanCondominiumTotalSharePercentage(): string {
    let percentageShareOfTotalRealtyTaxesNumber: Number = this.context.matter.getCondominiumTotalSharePercentage();
    return SharedUtils.formatPercentageValue(
      percentageShareOfTotalRealtyTaxesNumber && percentageShareOfTotalRealtyTaxesNumber.valueOf()
    );
  }

  getUnitLevelPlanCondominiumTotalExpenses(): Number {
    return this.context.matter.getUnitLevelPlanCondominiumTotalExpenses();
  }

  getUnitLevelPlanCondominiumTotalShareExpenses(): Number {
    return (
      this.soAdjExpenseOccupancyPeriod &&
      this.soAdjExpenseOccupancyPeriod.getUnitLevelPlanCondominiumTotalShareExpenses(
        this.context.matter.getCondominiumTotalSharePercentage()
      )
    );
  }

  isTotalEffectiveFromVisible(): boolean {
    return this.soAdjExpenseOccupancyPeriod.effectiveFromType === TotalEffectiveTypes.ENTER_OWN_DATE;
  }

  isTotalEffectiveTillVisible(): boolean {
    return this.soAdjExpenseOccupancyPeriod.effectiveTillType === TotalEffectiveTypes.ENTER_OWN_DATE;
  }

  adjustmentHeadingVisible(): boolean {
    return (
      !this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense &&
      !this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTabConvertToBoolean
    );
  }

  //TODO confirm BA if it needs  full data
  onDateChangeTotalEffectiveFrom(event): void {
    if (SharedUtils.isFullDate(event.rawDate)) {
      if (event.rawDate !== this.soAdjExpenseOccupancyPeriod.totalEffectiveFrom) {
        this.soAdjExpenseOccupancyPeriod.totalEffectiveFrom = event.rawDate;
        this.getTotalExpensePeriod();
      }
    }
  }

  updateTaxRate(rateType: string) {
    if (rateType === 'HST') {
      this.soAdjExpenseOccupancyPeriod.adjustPercentage = this.context.matter.soaHst;
    } else {
      this.soAdjExpenseOccupancyPeriod.adjustPercentage = this.context.matter.soaFederalHst;
    }
  }

  //TODO confirm BA if it needs  full data
  onDateChangeTotalEffectiveTill(event): void {
    if (SharedUtils.isFullDate(event.rawDate)) {
      if (event.rawDate !== this.soAdjExpenseOccupancyPeriod.totalEffectiveTill) {
        this.soAdjExpenseOccupancyPeriod.totalEffectiveTill = event.rawDate;
        this.getTotalExpensePeriod();
      }
    }
  }

  selectUnitLevelPlan(): void {
    let cea: StatementAdjustment;
    if (!this.context.matter.templateForProject) {
      cea = this.context.matter.statementOfAdjustments.find((item) => item.isCommonExpenseAdjustmentFromProperty());
    }
    this.dialogService.matDialogContent({
      content: UnitLevelPlanModalComponent,
      context: {
        propertyJurisdiction: this.context.matter.matterPropertyWithCondo.jurisdiction,
        unitLevelPlanOrg: this.context.matter.matterPropertyWithCondo,
        currentMatter: this.context.matter,
        commonExpenseAdj: cea,
        isReadOnly: false,
        inSecondModal: true,
        isUnitLevelPinReadOnly: true
      },
      onFulfillment: (selectUnitLevelPlanResult: SelectUnitLevelPlanResult) => {
        if (!this.context.matter.templateForProject) {
          this.matterService.updateMatterWithUnitLevelPlanChange(this.context.matter, selectUnitLevelPlanResult);
        } else {
          if (selectUnitLevelPlanResult && selectUnitLevelPlanResult.plan) {
            this.context.matter.matterPropertyWithCondo.isCondominium = 'YES';
            this.context.matter.updateUnitLevelPlanCreated(selectUnitLevelPlanResult.plan);
          }
        }
        //If soAdjExpenseOccupancyPeriod is not in matter, it need separataly pdate
        // if(!this.context.soAdjExpenseOccupancyPeriod){
        this.soAdjExpenseOccupancyPeriod.updateSoAdjExpenseOccupancyPeriod(
          this.context.matter.getUnitLevelPlanCondominiumTotalExpenses(),
          this.context.matter.getUnitLevelPlanCondominiumTotalSharePercentage(),
          this.context.matter.soAdjExpenseOccupancyPeriodClosedate,
          this.context.matter.soAdjExpenseOccupancyPeriodOccupancyDate
        );
        // }
      }
    });
  }

  chargePurchaserLabel(): string {
    let occupancyDate: Moment = moment(this.context.matter.soAdjExpenseOccupancyPeriodClosedate, 'YYYY/MM/DD');
    return occupancyDate.format('MMMM') + ', ' + occupancyDate.format('YYYY');
  }

  getMonthlyCommonExpenses(): string {
    return this.soAdjExpenseOccupancyPeriod.getMonthlyCommonExpenses(
      this.context.matter.getUnitLevelPlanCondominiumTotalExpenses(),
      this.context.matter.getCondominiumTotalSharePercentage()
    );
  }

  getSoAdjExpenseOccupancyPeriodTax(): string {
    return this.soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTax(
      this.context.matter.getUnitLevelPlanCondominiumTotalExpenses(),
      this.context.matter.getCondominiumTotalSharePercentage()
    );
  }

  getSoAdjExpenseOccupancyPeriodTotal(): string {
    return this.soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTotal(
      this.context.matter.getUnitLevelPlanCondominiumTotalExpenses(),
      this.context.matter.getCondominiumTotalSharePercentage(),
      this.context.matter.soAdjExpenseOccupancyPeriodClosedate,
      this.context.matter.soAdjExpenseOccupancyPeriodOccupancyDate
    );
  }

  getTotalExpensePeriod(): string {
    return this.soAdjExpenseOccupancyPeriod.getTotalExpensePeriod(
      this.context.matter.soAdjExpenseOccupancyPeriodOccupancyDate,
      this.context.matter.soAdjExpenseOccupancyPeriodClosedate
    );
  }

  get isExpensesAmountVisible(): boolean {
    return this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTabConvertToBoolean;
  }

  onChangeUseAmountFromPropertyTab() {
    if (this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTabConvertToBoolean) {
      this.soAdjExpenseOccupancyPeriod.expensesAmount = Number(
        this.context.matter.matterPropertyWithCondo.condominiumTotalExpenses
      );
    }
  }

  close(): void {
    this.dialog.close();
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.soAdjExpenseOccupancyPeriod = new SoAdjExpenseOccupancyPeriod();
          this.dialog.close({soAdjExpenseOccupancyPeriod: undefined});
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  ok(): void {
    this.checkErrorsOnModal();
    this.soAdjExpenseOccupancyPeriod.cleanupSoAdjExpenseOccupancyPeriod();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close({soAdjExpenseOccupancyPeriod: this.soAdjExpenseOccupancyPeriod});
    }
  }

  checkErrorsOnModal() {
    this.modalErrorComponent.removeAllDpSaveError();
    if (
      this.soAdjExpenseOccupancyPeriod.isOutOfRange(
        this.context.matter.soAdjExpenseOccupancyPeriodClosedate,
        this.context.matter.soAdjExpenseOccupancyPeriodOccupancyDate
      )
    ) {
      this.modalErrorComponent.createDPSaveError(
        'matter.project.adjustment.commonExpense.occupancyPeriod.commencementDate'
      );
    }
  }

  onEffectiveFromTypeChange() {
    if (this.soAdjExpenseOccupancyPeriod.effectiveFromType == TotalEffectiveTypes.NO_START_DATE) {
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.from.date');
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.from.date.INVALID');
    }
    this.getTotalExpensePeriod();
  }

  onEffectiveTillTypeChange() {
    if (this.soAdjExpenseOccupancyPeriod.effectiveTillType == TotalEffectiveTypes.NO_END_DATE) {
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.till.date');
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.till.date.INVALID');
    }
    this.getTotalExpensePeriod();
  }

  onOverrideGrossAnnualExpenseChange() {
    if (
      !this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense &&
      this.soAdjExpenseOccupancyPeriod.useAmountFromPropertyTabConvertToBoolean
    ) {
      this.soAdjExpenseOccupancyPeriod.expensesAmount = Number(
        this.context.matter.matterPropertyWithCondo.condominiumTotalExpenses
      );
    }
    if (!this.soAdjExpenseOccupancyPeriod.overrideGrossAnnualExpense) {
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.from.date');
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.from.date.INVALID');
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.till.date');
      this.modalErrorComponent.removeDpFieldError('matter.soadj.expense.occupancy.period.till.date.INVALID');
    }
  }
}
