<!-- modal -->
<div class="modal-header">
    <div class=" col-lg-12">
        <div class="col-lg-10"><h1>Download Accounting Export Files</h1></div>
        <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
    </div>
</div>
<div class="modal-body">
    <div class="col-lg-12 margin-top-10">
        <div class="templates-table">
            <table class="table standard-table" tabindex="-1"  #scrollable>
                <thead>
                <tr>
                    <th>Matter Type</th>
                    <th>Matter ID</th>
                    <th>Sheet Name</th>
                    <th>User Name</th>
                    <th>Date Added to Accounting Export</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="loading">
                    <td>
                        <div id="spinner">
                            <img src="assets/img/spinner.gif"/>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let  soaExportConfig of soaExportConfigs; let i=index;"
                    id="soaExportConfigArray{{i}}"  tabindex="0">
                    <ng-container>
                        <td>{{soaExportConfig.matterType}}</td>
                        <td>{{soaExportConfig.uiDisplayField}}</td>
                        <td>{{soaExportConfig.matterSoaSheetDetails}}</td>
                        <td>{{exportedByUser(soaExportConfig)}}</td>
                        <td>{{exportedTimeStamp(soaExportConfig,'MMM DD ,YYYY hh:mm A')}}</td>
                    </ng-container>
                </tr>
                <tr *ngIf="soaExportConfigs.length == 0 && !fileDownloaded" class="loading-row">
                    <td colspan="11" class="text-center"><div class="no-match-found">No Accounting export record to be created</div></td>
                </tr>
                <tr *ngIf="soaExportConfigs.length == 0 && fileDownloaded" class="loading-row">
                    <td colspan="11" class="text-center">
                        <div class="no-match-found">The <ng-container *ngIf="accountNationalSetting && accountNationalSetting.accountingSoftware != 'Cosmolex'">zip</ng-container>
                            file downloaded successfully. Proceed with import to Accounting Software.</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-lg-12" *ngIf="soaExportConfigs.length > 0 && accountNationalSetting && accountNationalSetting.accountingSoftware != 'Cosmolex'">
        <p>WARNING: A UnityAccounting.zip file will be created. This file must be extracted in order to import to your accounting software.</p>
        <p style="padding-left: 65px">After successfully downloading the zip file, Unity will clear the list.</p>
    </div>
    <div class="col-lg-12">
        <div class="row padding-top-20">
            <div class="col-lg-12 text-center">
                <ul style="margin: 0 auto; width: 980px;">
                    <li style="display:inline-block;float: left;">
                        <button type="button" class="dp-btn"  [disabled]="soaExportConfigs.length == 0" (click)="downloadAccountingData()">
                            <span>Download Unity accounting<ng-container *ngIf="accountNationalSetting && accountNationalSetting.accountingSoftware != 'Cosmolex'"> zip</ng-container> file</span>
                        </button>

                    </li>
                    <ng-container>
                        <li style="display:inline-block;float: left;padding-left: 15px;padding-top: 7px;">
                            <dp-checkbox class="checkbox-position" fieldId="master-checkbox-account" [hasTabIndex]="true"><input type="checkbox"
                                    id="master-checkbox-account" [(ngModel)]="isEsiLawFormat" [checked]="isEsiLawFormat" />
                            </dp-checkbox>
                            {{(appConfig && appConfig.isEsiLawIntegrationEnabled) ? "Unity® Accounting Export" : "esiLaw Export"}}
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <div class="col-lg-12 buttons">
        <button type="button"  [ngClass]="fileDownloaded ? 'dp-btn': 'dp-btn dp-btn-cancel'" (click)="close()">
            <span>Close</span>
        </button>
    </div>
</div>





