/**
 * Created by asvetl01 on 11/9/2016.
 */
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';
import {configApi} from './config-api';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {DialogService} from '../shared/dialog/dialog.service';
import {AppConfig} from '../shared-main/app-configuration';
import Utils from "../shared-main/utils";

@Injectable()
export class UtilsService {

  private _dndBgImagePathStyle: string = 'url(/assets/img/bg/dnd_bg_image_00.png) no-repeat';

  constructor(private http: HttpClient,
              public dialogService: DialogService,
              public appConfig: AppConfig) {
  }

  setUserSentimentForAIResponse(partitionkey: string, rowkey: string, fdbk: any) {
    var payload = {
      partitionKey: partitionkey,
      rowKey: rowkey,
      userSentiment: fdbk
    };

    return this.http.put(`${ configApi.addSentimentToAIResponse }`, payload);
  }

  getTnCAcceptanceFlagForUser() {
    let userId = Utils.getAuthenticatedUserId();
    let payload = {
      userId: userId
    };
    return this.http.post(`${ configApi.getTnCAcceptanceCheckApi }`, payload);
  }

  getFAQDocument() {
    return this.http.get(`${ configApi.getFAQLink }`);
  }

  getTermsnConditionsContent() {
    return this.http.get(`${ configApi.getTermsConditionsContent }`);
  }

  SaveTnCAcceptanceForUser() {
    let userId = Utils.getAuthenticatedUserId();
    let payload = {
      userId: userId
    };
    return this.http.post(`${ configApi.saveTnCAcceptanceApi }`, payload);
  }

  callAIChatAssitant(querytext: any) {
    let userId = Utils.getAuthenticatedUserId();
    let payload = {
      prompt: querytext,
      userId: userId
    };
    return this.http.post(`${ configApi.chatAIApi }`, payload, true);
  }

  ClearAIChatSession() {
    let userId = Utils.getAuthenticatedUserId();
    let payload = {
      userId: userId
    };
    return this.http.post(`${ configApi.clearchatAISession }`, payload, true);
  }

  getApiVersion(): Observable<any> {
    return this.http.get(`${ configApi.apiVersion }`);

  }

  getDoceboConfiguration(): Observable<any> {
    let userId = Utils.getAuthenticatedUserId();
    let url = configApi.doceboConfiguration(userId);
    return this.http.get(`${ url }`)
    .map((response) => {
      if (response && response[ 'DoceboSamlSSO' ]) {
        let doceboSamlSSO: any = response[ 'DoceboSamlSSO' ];
        return doceboSamlSSO;
      }
    });
  }

  getDoceboSamlSSO(): Observable<any> {
    let userId = Utils.getAuthenticatedUserId();
    let sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId);
    let url = configApi.doceboSAMLSSOApi(userId, sessionId);
    return this.http.get(`${ url }`)
    .map((response) => {
      if (response && response[ 'DoceboSamlDetails' ]) {
        let doceboSamlDetails: any = response[ 'DoceboSamlDetails' ];
        return doceboSamlDetails;
      }
    });
  }

  getUnityStatusUrl() {
    return 'https://unity.status.io/';
  }

  compareClientVersionToApiVersion(clientVersion: string, serverVersion: string) {
    if (clientVersion != '' && serverVersion != '') {
      var serverVersionWithoutBuild = serverVersion.split('-')[ 0 ];
      var serverVersionSegments = serverVersionWithoutBuild.split('.');
      var serverMajorVersion = serverVersionSegments[ 0 ];
      var serverFeatureVersion = serverVersionSegments[ 1 ];

      var clientVersionWithoutBuild = clientVersion.split('-')[ 0 ];
      var clientVersionSegments = clientVersionWithoutBuild.split('.');
      var clientMajorVersion = clientVersionSegments[ 0 ];
      var clientFeatureVersion = clientVersionSegments[ 1 ];

      if (clientMajorVersion != serverMajorVersion || clientFeatureVersion < serverFeatureVersion) {
        this.dialogService.confirm('Out of Date Version', 'A newer version of the application is available, refresh to retrieve the latest' +
          ' version', true, 'Refresh Browser')
        .subscribe(res => {
          // Reload the app after closing the popup
          location.reload();
        });
      }
    }
  }

  getUnityDriveInstallUrl(): Observable<string> {
    return this.http.get(`${ configApi.unityDriveInstallParam }`)
    .map((res) => {
      if (res && res[ 'UnityDriveInstall' ]) {
        let params = res[ 'UnityDriveInstall' ];
        return params && params[ 'url' ];
      }
    });
  }

  set dndBgImagePathStyle(bgImagePathStyle: string) {
    this._dndBgImagePathStyle = bgImagePathStyle;
  }

  get dndBgImagePathStyle(): string {
    return this._dndBgImagePathStyle;
  }

  get currentYear(): number {
    return new Date().getFullYear();
  }

  isMatterTypeDisabled(matterTypeCode: string) {
    if (this.appConfig.isHiddenForBCProvince && (matterTypeCode === 'PURCHASE'
      || matterTypeCode === 'SALE'
      || matterTypeCode === 'MORTGAGE'
      || matterTypeCode === 'DISCHARGE')) {
      return true;
    }
    return false;
  }
}