import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type FilterType = 'MATTER' | 'OPPORTUNITY';

export const FilterTypeValue = {
  matter: <FilterType>'MATTER',
  opportunity: <FilterType>'OPPORTUNITY'
};

export class MatterListFilter extends BaseEntity {
  constructor(matterListFilter?: MatterListFilter) {
    super(matterListFilter);
  }

  id: number;
  customerAccountId: number;
  matterListFilterName: string;
  matterListFilterSortBy: string;
  matterListFilterSortByOrder: string;
  matterTypeFilters: string[];
  provinceCodeFilters: string[];
  matterStatusFilters: string[];
  actionRequiredFilters: string[];
  taskStatusFilter: string;
  taskDescription: string;
  selectedLawyerContactIds: string[];
  selectedClerkContactIds: string[];
  selectedProjectIds: string[];
  isReadOnly: boolean;
  closingDayFilter: number;
  filterType: FilterType;
  //Opportunity Fields
  selectedAssigneeIds: string[];
  selectedOpportunityStatuses: string[];
  selectedOpportunitySources: string[];

  isMatterFilter(): boolean {
    return this.filterType == FilterTypeValue.matter;
  }

  isOpportunityFilter(): boolean {
    return this.filterType == FilterTypeValue.opportunity;
  }
}

export class ServerMatterListFilter extends BaseEntity {
  constructor(serverMatterListFilter?: ServerMatterListFilter) {
    super(serverMatterListFilter);
  }

  id: number;
  customerAccountId: number;
  matterListFilterName: string;
  matterListFilterSortBy: string;
  matterListFilterSortByOrder: string;
  matterTypeFilters: string;
  provinceCodeFilters: string;
  matterStatusFilters: string;
  actionRequiredFilters: string;
  taskStatusFilter: string;
  taskDescription: string;
  selectedLawyerContactIds: string;
  selectedClerkContactIds: string;
  selectedProjectIds: string;
  closingDayFilter: number;
  filterType: FilterType;
  //Opportunity Fields
  selectedAssigneeIds: string;
  selectedOpportunityStatuses: string;
  selectedOpportunitySources: string;
}
