import {AppMode, EnvironmentConfig, KeycloakConfig} from '../environment';

export class AuProdEnvironment implements EnvironmentConfig {
  appMode = AppMode.AU;
  tcolUrl = '';
  keycloak: KeycloakConfig = {
    clientId: 'unity-spa',
    domain: 'https://auth.globalx.com.au',
    logoutRedirectUri: 'https://dyedurham.com.au/',
    realm: 'GlobalX'
  };
  ltsaWebBaseUrl: string = '';
  landingPageUrl: string = '';
  //TODO: Needs to go through an approval process with GoCardless before go live details are given
  goCardlessApiUrl: string = '<setup for prod once GoCardless have approved>';
  goCardlessAuthRedirectUri: string = '<setup for prod once GoCardless have approved>';
  goCardlessClientId: string = '<setup for prod once GoCardless have approved>';
}
