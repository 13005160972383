<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-10">
            <h1>{{context.title}}</h1>
        </div>
        <button *ngIf="showCloseButton" type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body view-port" *ngIf="syngrafiiUrl">
        <iframe id="syngrafiiContent" height="100%" width="100%" [src]="syngrafiiUrl" #syngrafiiFrame>
        </iframe>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
        </div>
    </div>
</div>
