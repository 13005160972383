import {Component, Input, OnInit} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatterSupplementalTaskCategory} from '../../shared/matter-supplemental-task-category';
import {MatterSupplementalTaskField} from '../../shared/matter-supplemental-task-field';
import {currentMatter, Matter} from '../../shared';
import {MatterParticipant} from '../../shared/matter-participant';
import {MatterParticipantRole} from '../../shared/matter-participant-role-types';
import {StaffProfilesService} from '../../../admin/staff-profiles/staff-profiles.service';
import {BurgerMenuExtendedItem} from '../../shared/burger-menu-extended-item';
import {CategoryFieldType, DirectionReFundsBurgerMenuTypes} from '../../../shared-main/constants';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import Utils from '../../../shared-main/utils';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {DirectionRefundMaxF9Length} from './supplemental-task-constants';

@Component({
  selector: 'dp-supplemental-task-direction-refunds',
  templateUrl: 'supplemental-task-direction-refunds.component.html',
  providers: [ CurrencyPipe ]
})

export class SupplementalTaskDirectionRefundsComponent implements OnInit {
  @Input() matterSupplementalTask: MatterSupplementalTaskCategory;
  @Input() currentMatter: Matter;

  loggedInUserLawFirmName: string;
  calculatedAmountForBalancePayable: number;
  burgerMenuItems: any[] = [];
  burgerBalanceMenuItems: any[] = [];
  //payBills : any[];
  isDocumentProfileAvailable: boolean = false;

  constructor(public dialogService: DialogService,
              public staffProfilesService: StaffProfilesService,
              public documentProfileService: DocumentProfileService) {
  }

  ngOnInit(): void {

    this.documentProfileService.getById(currentMatter.value.documentProfileId, sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId), false, currentMatter.value).subscribe((profile: DocumentProfile) => {
      this.isDocumentProfileAvailable = true;
      this.loggedInUserLawFirmName = profile.firmDocumentProfile.firmName;
      // For -- Funds payable to:
      SupplementalTaskDirectionRefundsComponent.createFundsPayableToMenuItems(this.burgerMenuItems, this.payableToFrom, this.currentMatter, this.loggedInUserLawFirmName);
      // For -- Balance of funds payable to:
      SupplementalTaskDirectionRefundsComponent.createBalanceFundsPayableToMenuItems(this.burgerBalanceMenuItems, this.payableToFrom, this.currentMatter, this.loggedInUserLawFirmName);
    });

    if (this.directionRefundsCategoryFields.length === 0) {
      this.createDirectionRefundsField('DIRECTION_REFUNDS_BALANCE_PAYABLE');
      this.createDirectionRefundsField('DIRECTION_REFUNDS_BALANCE_PAYABLE');
    }

    if (this.directionRefundsCategoryFields.length === 1) {
      this.createDirectionRefundsField('DIRECTION_REFUNDS_BALANCE_PAYABLE');
    }

    if (!this.balanceDueOnClosingField) {
      this.createDirectionRefundsField('DIRECTION_REFUNDS_BALANCE_DUE', 'BALANCE_DUE');
    }
  }

  createDirectionRefundsField(fieldType: CategoryFieldType, fieldLabel?: string, fieldValue?: string) {
    let categoryField = new MatterSupplementalTaskField();
    categoryField.fieldValue = fieldValue ? fieldValue : '';
    categoryField.fieldLabel = fieldLabel ? fieldLabel : '';
    categoryField.fieldDataType = 'AMOUNT';
    categoryField.fieldType = fieldType;
    this.matterSupplementalTask.categoryFields.push(categoryField);
  }

  get directionRefundsCategoryFields(): MatterSupplementalTaskField[] {
    return this.matterSupplementalTask.categoryFields.filter(categoryField => categoryField.fieldType === 'DIRECTION_REFUNDS_BALANCE_PAYABLE');
  }

  get balanceDueOnClosingField(): MatterSupplementalTaskField {
    return this.matterSupplementalTask.categoryFields.find(categoryField => categoryField.fieldType === 'DIRECTION_REFUNDS_BALANCE_DUE');
  }

  get supplementalCategoryPayBillsOrDebts(): MatterSupplementalTaskCategory[] {
    return this.currentMatter.supplementalTasks.filter(supplementalTask => supplementalTask.categoryName === 'PAY BILLS OR DEBTS');
  }

  populateCategoryField(event, categoryField: MatterSupplementalTaskField, isFundsPayableTo: boolean) {
    let keySplit: string[] = event && event.key && event.key.split('~');
    if (Array.isArray(keySplit) && keySplit.length > 0) {
      if (keySplit[ 0 ] && keySplit[ 0 ].length > 200) {
        categoryField.fieldLabel = keySplit[ 0 ].slice(0, 200);
      } else {
        categoryField.fieldLabel = keySplit[ 0 ];
      }
      if (isFundsPayableTo && keySplit.length > 1) {
        categoryField.fieldValue = keySplit[ 1 ];
      }
    }
  }

  participants(role: MatterParticipantRole): MatterParticipant[] {
    if (role == 'PURCHASER') {
      return this.currentMatter.purchasers;
    } else if (role == 'VENDOR') {
      return this.currentMatter.vendors;
    }
  }

  get payableToFrom(): string {
    return this.loggedInUserLawFirmName ? this.loggedInUserLawFirmName + ', in trust' : '';
  }

  calculateBalancePayableTo(): string {

    let balanceDue: MatterSupplementalTaskField = this.balanceDueOnClosingField;
    let amountPayableTo: number = 0;
    let calculatedAmount: number = 0;

    this.directionRefundsCategoryFields.forEach((categoryField, index) => {
      if (index > 0) {
        amountPayableTo = amountPayableTo + (categoryField.fieldValue ? Number(categoryField.fieldValue) : 0);
      }
    });
    this.calculatedAmountForBalancePayable = Number(balanceDue.fieldValue) - amountPayableTo;

    this.directionRefundsCategoryFields[ 0 ].fieldValue = this.calculatedAmountForBalancePayable > 999999999.99 ?
      this.trimAmount(this.calculatedAmountForBalancePayable).toString() : this.calculatedAmountForBalancePayable.toString();
    //this.directionRefundsCategoryFields[0].fieldValue = (Number(balanceDue.fieldValue) - amountPayableTo).toString() ;

    return this.directionRefundsCategoryFields[ 0 ].fieldValue;
  }

  trimAmount(amount: number): number {
    if (amount > 999999999.99) {
      this.calculatedAmountForBalancePayable = Number(amount.toString().substring(0, (amount.toString().length - 1)));
      this.trimAmount(this.calculatedAmountForBalancePayable);
    }
    return this.calculatedAmountForBalancePayable;
  }

  fieldValueBlur(field: MatterSupplementalTaskField) {
    if (!field.fieldLabel) {
      field.fieldValue = null;
    }
  }

  static createBurgerMenuItem(text: string, key: string, type?: string): BurgerMenuExtendedItem {
    const burgerMenuExtendedItem = new BurgerMenuExtendedItem();
    burgerMenuExtendedItem.text = text;
    burgerMenuExtendedItem.key = key;
    if (type) {
      burgerMenuExtendedItem.type = type;
    }
    return burgerMenuExtendedItem;
  }

  static createBalanceFundsPayableToMenuItems(burgerMenuItems: any, payableToFrom: string, matter: Matter, loggedInUserLawFirmName: string) {
    // For option 1
    if (matter.isPurchase) {
      const payableToFromSoa: string = matter.getPayableToFromSoa(loggedInUserLawFirmName);
      if (payableToFromSoa) {
        burgerMenuItems.push({text: payableToFromSoa, key: payableToFromSoa});
      }
    } else {
      if (payableToFrom) {
        burgerMenuItems.push(this.createBurgerMenuItem(payableToFrom, payableToFrom));
      }
    }

    // For Sale and Mortgage matter option 2
    if (!matter.isPurchase) {
      const otherSideClientFirmName: string = matter.otherSideClientFirmName;
      if (otherSideClientFirmName) {
        burgerMenuItems.push(this.createBurgerMenuItem(otherSideClientFirmName, otherSideClientFirmName));
      }
    }

    // For Sale and Mortgage matter option 3 : For purchase matter option 2
    const mainClientsName: string = matter.mainClientsName;
    if (mainClientsName) {
      burgerMenuItems.push(this.createBurgerMenuItem(mainClientsName, mainClientsName));
    }
    // For Sale and Mortgage matter option 4 : For purchase matter option 3
    const otherPartyParticipantsName: string = matter.otherPartyParticipantsName;
    if (otherPartyParticipantsName) {
      burgerMenuItems.push(this.createBurgerMenuItem(otherPartyParticipantsName, otherPartyParticipantsName));
    }
    // Only for purchase matter option 4 and 5
    if (matter.isPurchase) {
      // For option 4
      if (loggedInUserLawFirmName && matter.totalFeesFromSoa > 0) {
        burgerMenuItems.push({text: matter.totalFeesOption, key: loggedInUserLawFirmName + '~' + matter.totalFeesFromSoa});
      }
    }
  }

  static createFundsPayableToMenuItems(burgerMenuItems: any, payableToFrom: string, matter: Matter, loggedInUserLawFirmName: string,
                                       createBalanceFundsPayableTo: boolean = true, separator: string = '~') {
    let payBills: BurgerMenuExtendedItem[] = [];
    if (createBalanceFundsPayableTo) {
      this.createBalanceFundsPayableToMenuItems(burgerMenuItems, payableToFrom, matter, loggedInUserLawFirmName);
    }
    // For option 5
    if (matter.supplementalCategoryPayBillsOrDebts.length > 0) {
      matter.supplementalCategoryPayBillsOrDebts.forEach(supplementalTaskCategory => {

        let categoryFieldForValue = supplementalTaskCategory.categoryFields.find(categoryField => categoryField.fieldType === 'FEE_AMOUNT');
        let categoryFieldForLabel = supplementalTaskCategory.categoryFields.find(categoryField => categoryField.fieldType === 'DETAILS');

        payBills.push(this.createBurgerMenuItem(categoryFieldForLabel.fieldValue + ' = ' + Utils.formattedCurrencyValue(Number(categoryFieldForValue.fieldValue))
          , categoryFieldForLabel.fieldValue + separator + categoryFieldForValue.fieldValue, DirectionReFundsBurgerMenuTypes.OTHER_SUPPLEMENTAL_TASK));

      });
      if (Array.isArray(payBills) && payBills.length > 0) {
        const otherSupplementalTaskBurgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
        otherSupplementalTaskBurgerMenuItem.text = DirectionReFundsBurgerMenuTypes.OTHER_SUPPLEMENTAL_TASK;
        otherSupplementalTaskBurgerMenuItem.type = DirectionReFundsBurgerMenuTypes.OTHER_SUPPLEMENTAL_TASK;
        otherSupplementalTaskBurgerMenuItem.items = payBills;
        burgerMenuItems.push(otherSupplementalTaskBurgerMenuItem);
      }
    }
  }

  generateF9HelpBalancePayableTo(categoryField: MatterSupplementalTaskField): string {
    if (this.currentMatter.isPurchase) {
      return this.generateF9HelpBalancePayableToForPurchase(categoryField);
    } else {
      return this.generateF9HelpBalancePayableToForNotPurchase();
    }
  }

  generateF9HelpBalancePayableToForNotPurchase(): string {
    let balancePayableTo: string;
    if (!this.currentMatter.otherSideClientFirmName && !this.currentMatter.mainClientsName && !this.currentMatter.otherPartyParticipantsName) {
      balancePayableTo = 'F9 = ' + this.payableToFrom;
    } else {
      const names: string[] = [ 'Use F9 key to Toggle between ' + '(' + this.payableToFrom + ')' ];
      if (this.currentMatter.otherSideClientFirmName) {
        names.push('(' + this.currentMatter.otherSideClientFirmName + ')');
      }
      if (this.currentMatter.mainClientsName) {
        names.push('(' + this.currentMatter.mainClientsName + ')');
      }
      if (this.currentMatter.otherPartyParticipantsName) {
        names.push('(' + this.currentMatter.otherPartyParticipantsName + ')');
      }

      balancePayableTo = Utils.concatenateNames(names);
    }

    if (balancePayableTo && (balancePayableTo.length > DirectionRefundMaxF9Length)) {
      balancePayableTo = balancePayableTo.slice(0, DirectionRefundMaxF9Length) + '...';
    }
    return balancePayableTo;
  }

  handleF9BalancePayableTo(categoryField: MatterSupplementalTaskField) {
    if (this.currentMatter.isPurchase) {
      this.handleF9BalancePayableToForPurchase(categoryField);
    } else {
      this.handleF9BalancePayableToForNotPurchase(categoryField);
    }
  }

  handleF9BalancePayableToForNotPurchase(categoryField: MatterSupplementalTaskField) {
    const names: string[] = [];
    if (this.payableToFrom) {
      names.push(this.payableToFrom);
    }
    if (this.currentMatter.otherSideClientFirmName) {
      names.push(this.currentMatter.otherSideClientFirmName);
    }
    if (this.currentMatter.mainClientsName) {
      names.push(this.currentMatter.mainClientsName);
    }
    if (this.currentMatter.otherPartyParticipantsName) {
      names.push(this.currentMatter.otherPartyParticipantsName);
    }

    let idx: number = -1;
    if (categoryField && categoryField.fieldLabel) {
      idx = names.findIndex((name: string) => name === categoryField.fieldLabel);
    }

    if (idx >= names.length - 1) {
      idx = -1;
    }
    categoryField.fieldLabel = names[ idx + 1 ];
  }

  get showAmountField(): boolean {
    return this.matterSupplementalTask && (this.matterSupplementalTask.showPaymentAmounts == null || this.matterSupplementalTask.showPaymentAmounts == true);
  }

  generateF9HelpBalancePayableToForPurchase(categoryField: MatterSupplementalTaskField): string {
    let balancePayableTo: string;
    const mainClientsName: string = this.currentMatter.mainClientsName;
    const payableToFromSoa: string = this.currentMatter.getPayableToFromSoa(this.loggedInUserLawFirmName);
    if (categoryField && categoryField.fieldLabel && payableToFromSoa && mainClientsName) {
      if (categoryField.fieldLabel === payableToFromSoa) {

        balancePayableTo = 'F9 =  (' + mainClientsName + ')';

      } else if (categoryField.fieldLabel === mainClientsName) {
        balancePayableTo = 'F9 = (' + payableToFromSoa + ')';
      } else {
        balancePayableTo
          = 'F9 = Toggle between  (' + payableToFromSoa + ') and ('
          + mainClientsName + ')';
      }
    } else {
      balancePayableTo
        = 'F9 = Toggle between  (' + payableToFromSoa + ') and ('
        + mainClientsName + ')';
    }
    return balancePayableTo;
  }

  handleF9BalancePayableToForPurchase(categoryField: MatterSupplementalTaskField) {
    const mainClientsName: string = this.currentMatter.mainClientsName;
    const payableToFromSoa: string = this.currentMatter.getPayableToFromSoa(this.loggedInUserLawFirmName);
    if (payableToFromSoa || mainClientsName) {
      categoryField.fieldLabel = categoryField && categoryField.fieldLabel
        ? categoryField.fieldLabel === payableToFromSoa
          ? mainClientsName ? mainClientsName : ''
          : payableToFromSoa ? payableToFromSoa : ''
        : payableToFromSoa;

    }
  }
}
