import {BaseEntity} from '../shared/BaseEntity/base-entity';
import {EnvelopeDocument} from '../../app/event/envelope-document';

export type SyngrafiiPackageType = 'VIDEO_SIGNING_ROOM' | 'SEQUENTIAL';

export class SigningEnvelope extends BaseEntity {
  constructor(signingEnvelope?: SigningEnvelope) {
    super(signingEnvelope);
    if (!signingEnvelope) {
      this.envelopeDocuments = [];
    } else {
      this.envelopeDocuments = [];
      if (Array.isArray(signingEnvelope.envelopeDocuments)) {
        for (let ed of signingEnvelope.envelopeDocuments) {
          if (!ed.deleted) {
            this.envelopeDocuments.push(new EnvelopeDocument(ed));
          }

        }
      }
    }
  }

  id: number;
  thirdPartyEnvelopeId: string;
  envelopSubject: string;
  envelopeStatus: string;
  digitalSigningPlatform: string;
  packageType: SyngrafiiPackageType;
  //includedDocumentIds : number[];
  envelopeDocuments: EnvelopeDocument[];
  userAcknowledgementStatus: string;

  isEnvelopeSent(): boolean {
    return this.envelopeStatus == 'SENT';
  }

  isEnvelopeCompleted(): boolean {
    return this.envelopeStatus == 'COMPLETED';
  }

  isEnvelopeFailed(): boolean {
    return this.envelopeStatus == 'FAILED';
  }

  needToDisplayNotification(): boolean {
    return this.userAcknowledgementStatus == 'PENDING';
  }

  isEnvelopeCreated(): boolean {
    return this.envelopeStatus == 'CREATED';
  }
}
