import {Instrument} from '../../../shared-main/teranet/instrument';

export class ParcelAdjacentAnalysisInstrument {
  parcelRegisterId: number;
  lro: string;
  pin: string;
  instrument: Instrument;
  surnameFrom: string;
  selected: boolean;
}

export class ParcelAdjacentAnalysis {
  surname: string;
  primaryParcelData: ParcelAdjacentAnalysisInstrument;
  adjacentParcelData: ParcelAdjacentAnalysisInstrument;

}
