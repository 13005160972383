<div class="selectedParcelRegister">
    {{headerMessage}}
</div>
<div class="teranet-connect-header" [dp-read-only-block]="isMatterEditingDisabledAfterPostClosingDate">
    <div class="row">
        <div class="col-lg-3">
            <div id="parcelContainer" class="col-xs-12 leftSidePanel margin-top-10">
                <p-treeTable [value]="parcelNodes" selectionMode="single"  [(selection)]="selectedNode" (onNodeSelect)="openPinDetails($event)" class="parcelTable" >
                    <ng-template let-rowNode let-rowData="rowData" pTemplate="body" field="hasPdf">
                        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                            <span style="display: contents;" *dpShowByProvince="'matters.property.teranetConnect.pdf'">
                                <td class="parcelTableTd1 treeTable" *ngIf="rowData.hasPdf" (click)="openParcelRegisterPdf(rowData.parcel)">
                                    <i class="fa fa-file-pdf-o"></i>
                                </td>
                                <td class="parcelTableTd1 treeTable" *ngIf="!rowData.hasPdf">Adj</td>
                            </span>
                            <td field="displayPin" class="parcelTableTd2 treeTable" *dpShowByProvince="'matters.property.teranetConnect.pin'">
                                {{rowData.displayPin}}</td>
                            <td field="lt" class="parcelTableTd3 treeTable" *dpShowByProvince="'matters.property.teranetConnect.lt'">
                                {{rowData.lt}}</td>
                            <td field="certificateTitle" class="parcelTableTd2-3-4-AB treeTable"
                                *dpShowByProvince="'matter.subjectProperty.title'">{{rowData.certificateTitle}}</td>

                            <span style="display: contents;" class="parcelTableTd4" *dpShowByProvince="'matters.property.teranetConnect.nodeBurgerMenu'">
                                <td *ngIf="!rowData.parcel.isAdjacentParcel && rowData.canHaveMap">
                                    <dp-burger-menu style="display: inline-block; float: left; position: inherit;"
                                        [items]="getOrderMapItems(rowData.parcel)"
                                        (itemClicked)="clickOrderMapBurgerMenu(rowData.parcel, $event)" [allowPopUp]="false"
                                        (menuOpened)="openedOrderMapBurgerMenu(node)">
                                    </dp-burger-menu>
                                </td>
                                <td *ngIf="rowData.parcel.isAdjacentParcel && rowData.parcel.adjacentParcelAnalysisAvailable">
                                    <span>
                                        <i class="fa fa-warning" style="color: #ffcc00;" (click)="viewAdjacentParcelAnalysis()"></i>
                                    </span>
                                </td>
                            </span>
                        </tr>
                    </ng-template>

                    <!-- <ng-template let-rowNode let-rowData="rowData" pTemplate="body" styleClass="parcelTableTd4"
                        *dpShowByProvince="'matters.property.teranetConnect.nodeBurgerMenu'">
                        <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                        <td *ngIf="!rowData.parcel.isAdjacentParcel && rowData.canHaveMap">
                            <dp-burger-menu style="display: inline-block; float: left; position: inherit;"
                                [items]="getOrderMapItems(rowData.parcel)"
                                (itemClicked)="clickOrderMapBurgerMenu(rowData.parcel, $event)" [allowPopUp]="false"
                                (menuOpened)="openedOrderMapBurgerMenu(node)">
                            </dp-burger-menu>
                        </td>
                        <td *ngIf="rowData.parcel.isAdjacentParcel && rowData.parcel.adjacentParcelAnalysisAvailable">
                            <span>
                                <i class="fa fa-warning" style="color: #ffcc00;" (click)="viewAdjacentParcelAnalysis()"></i>
                            </span>
                        </td>
                        </tr>
                    </ng-template> -->
                </p-treeTable>
            </div>
        </div>
        <div class="col-lg-9">
            <div class="tabs-nav form-group tabs-top-header">
                <div class="col-lg-12">
                    <ul>
                        <li [ngClass]="navTopSelected === 'PropertyDescription' ? 'active' : ''">
                            <span (click)="navTopSelected = 'PropertyDescription'">Property Description</span>
                            <div class="nav-arrow"></div>
                        </li>
                        <li [ngClass]="navTopSelected === 'PropertyDetails' ? 'active' : ''" *dpShowByProvince="'matter.subjectProperty.propertyDetails'">
                            <span (click)="navTopSelected = 'PropertyDetails'">Property Details</span>
                            <div class="nav-arrow"></div>
                        </li>
                        <li [ngClass]="navTopSelected === 'Qualifiers' ? 'active' : ''" *dpShowByProvince="'matter.subjectProperty.qualifiers'">
                            <span (click)="navTopSelected = 'Qualifiers'">Qualifiers</span>
                            <div class="nav-arrow"></div>
                        </li>
                        <li [ngClass]="navTopSelected === 'Owners' ? 'active' : ''">
                            <span (click)="navTopSelected = 'Owners'">Owners</span>
                            <div class="nav-arrow"></div>
                        </li>
                    </ul>
                    <div class="text-right matter-component action-buttons" >
                        <ul class="matter">
                            <li class="dropdown no-border" [class.active]="actionButtonFlagForAddOrder" (blur)="resetActionButtonFlagForAddOrder()"
                                [ngClass]="{'padding-top-15' : matter.isMatterProvinceAB || matter.isMatterProvinceMB}"
                            >
                                <button id="actionButton" class="dropbtn select-button dp-btn-green focus-first"
                                        (click)="toggleActionButtonFlagForAddOrder($event)"
                                        *dpShowByProvince="'matter.subjectProperty.addAndOrder'"
                                >
                                    <i class="fa fa-bars"  aria-hidden="false"></i>
                                    <span style="color:#fff">Add / Order</span>
                                    <div class="clear"></div>
                                </button>
                                <span *dpShowByProvince="'matter.subjectProperty.spin'" >
                                    <a href="javascript:void(0);" id="spin"
                                        (click)="openExternalUrl()">{{urlLabel}}
                                    </a>
                                </span>
                                <div class="dropdown-matter-type-list select-button-list">
                                    <div class="dropdown-content" [ngClass]="{'underline' : selectedParcelRegister  && !selectedParcelRegister.isAdjacentParcel}">
                                        <button (click)="addTeranetOrder()">Parcel Register</button>
                                    </div>
                                    <div *ngIf="selectedParcelRegister && !selectedParcelRegister.isAdjacentParcel" class="dropdown-content">
                                        <button (click)="orderOrViewMapOnSelectedParcel(false)">{{viewOrOrderMapsForSelectedParcelRegister(false)}} map without roads and railways</button>
                                    </div>
                                    <div *ngIf="selectedParcelRegister && !selectedParcelRegister.isAdjacentParcel" class="dropdown-content">
                                        <button (click)="orderOrViewMapOnSelectedParcel(true)">{{viewOrOrderMapsForSelectedParcelRegister(true)}} map with roads and railways</button>
                                    </div>
                                </div>

                            </li>
                            <li class="dropdown no-border" [class.active]="actionButtonFlag" (blur)="resetActionButtonFlag()">
                                <button class="dp-btn-white select-button" (click)="toggleActionButtonFlag($event)"
                                        *dpShowByProvince="'matter.subjectProperty.billing'"
                                >
                                    <i class="fa fa-bars"  aria-hidden="false"></i>
                                    <span>Billing</span>
                                </button>
                                <ng-container  *dpShowByProvince="'matter.subjectProperty.spin'">
                                    <button #fileUpload class="btn dp-btn" tabindex="0"
                                            (click)="addTeranetUpload()">Upload</button>
                                    <input style="display: none;"
                                           #fileUploadInput
                                           id="file-upload"
                                           type="file"
                                           multiple="true"
                                           [accept]="acceptedFileExtensions"
                                           (change)="initiateUpload()"
                                    />
                                </ng-container>
                                <ng-container *ngIf="showWizardFields">
                                    <input style="display: none;"
                                           #fileUploadInput
                                           id="file-upload-wizard"
                                           type="file"
                                           multiple="true"
                                           [accept]="acceptedFileExtensions"
                                           (change)="initiateUpload()"
                                    />
                                </ng-container>
                                <div class="dropdown-matter-type-list select-button-list">
                                        <div class="dropdown-content">
                                            <button
                                                (click)="openViewUnityChargesModal()"
                                                [disabled]="matter.teranetDocket.docketSystemId==undefined">
                                                View charges incurred through Unity</button>
                                        </div>
                                        <div class="dropdown-content">
                                            <button (click)="viewTeranetDocketSummary()"
                                                    [disabled]="matter.teranetDocket.docketSystemId==undefined" >
                                                View Teranet Docket Summary</button>
                                        </div>
                                        <div class="dropdown-content">
                                            <button (click)="checkTeranetBalance()">Check Teranet Account Balance</button>
                                        </div>
                                        <div class="dropdown-content">
                                            <button (click)="rateSheet()">Teranet Connect&trade;:  Rate Sheet</button>
                                        </div>
                                    </div>
                            </li>
                            <li class="dropdown no-border" [class.active]="actionButtonFlagForImportData" (blur)="resetActionButtonFlagForImportData()" *dpShowByProvince="'matter.subjectProperty.importData'">
                                <button id="actionButtonIM" class="dp-btn-white select-button"
                                        [disabled] = "selectedParcelRegister && selectedParcelRegister.isAdjacentParcel"
                                        (click)="toggleActionButtonFlagForImportData($event)"
                                        *dpShowByProvince="'matter.properties.LTSA.importDataBtn'">
                                    <i class="fa fa-bars"  aria-hidden="false"></i>
                                    <span>Import Data</span>
                                    <div class="clear"></div>
                                </button>
                                <div class="dropdown-matter-type-list select-button-list">
                                    <div class="dropdown-content">
                                        <button [disabled]="!selectedParcelRegister" (click)="openImportPropertyData()">Property Data</button>
                                    </div>
                                    <div class="dropdown-content"
                                         *ngIf="isImportMortgageMenuOptionApplicable()">
                                        <button (click)="openImportTeranetMortgageModal()">Existing Mortgage Data</button>
                                    </div>
                                    <div class="dropdown-content"
                                         *ngIf="selectedParcelRegister && !selectedParcelRegister.isRegistryProperty">
                                        <button (click)="openOwnerImportTeranetModal()">Owner Data</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="clear"></div>
            </div>
            <div class="col-lg-12">

                <div class="form-horizontal tabs-item-top">

                    <div class="qualifiersDiv" [hidden]="navTopSelected != 'PropertyDescription'" data-testid="teranetConnect-propertyDescription-div">
                        <pre>{{selectedParcelRegister?.propertyDescription}}</pre>
                    </div>
                    <div [hidden]="navTopSelected != 'PropertyDetails'">
                        <ng-container *dpShowByProvince="'matter.teranetProperty.propertyDetails'">
                            <div class="propertyDetailsDiv">
                                <div class="col-lg-12 margin-bottom-10" *ngIf="selectedParcelRegister && selectedParcelRegister.propertyRemarks">
                                    <div class="col-lg-2 text-right">
                                        Property Remarks:
                                    </div>
                                    <div class="col-lg-10">{{selectedParcelRegister?.propertyRemarks}}</div>
                                </div>
                                <div class="col-lg-12 margin-bottom-10">
                                    <div class="col-lg-2 text-right">
                                        Estate:
                                    </div>
                                    <div class="col-lg-10">{{selectedParcelRegister?.estate}}</div>
                                </div>
                                <div class="col-lg-12 margin-bottom-10">
                                    <div class="col-lg-2 text-right">
                                        Qualifier:
                                    </div>
                                    <div class="col-lg-10">{{selectedParcelRegister?.qualifier}}</div>
                                </div>
                                <div class="col-lg-12 margin-bottom-10">
                                    <div class="col-lg-2 text-right">
                                        Recently:
                                    </div>
                                    <div class="col-lg-10">{{selectedParcelRegister?.recently}}</div>
                                </div>
                                <div class="col-lg-12 margin-bottom-10">
                                    <div class="col-lg-2 text-right">
                                        PIN Creation Date:
                                    </div>
                                    <div class="col-lg-10">{{selectedParcelRegister?.pinCreationDate}}</div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *dpShowByProvince="'matter.subjectProperty.propertyDetails.linc'">
                            <div class="top-table-wrapper margin-top-25">
                                <table class="table tc-table">
                                    <thead class="fixed-position margin-top-minus-35">
                                    <tr>
                                        <th class="width500">LINC</th>
                                        <th class="width270">Short Description</th>
                                    </tr>
                                    </thead>
                                    <tbody *ngIf="selectedParcelRegister">
                                        <tr *ngFor="let spinPropertyDetail of selectedParcelRegister.propertyDetails">
                                            <td class="width500">{{spinPropertyDetail.lincNumber}}</td>
                                            <td class="width270">{{spinPropertyDetail.shortDescription}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                        </ng-container>
                    </div>
                    <div class="qualifiersDiv" [hidden]="navTopSelected != 'Qualifiers'">
                        <ul *ngIf="selectedParcelRegister">
                            <li class="padding-bottom-5" *ngFor="let qualifier of selectedParcelRegister.titleQualifiers">
                                {{qualifier}}
                            </li>
                        </ul>
                    </div>
                    <div [hidden]="navTopSelected != 'Owners'">
                        <div class="top-table-wrapper margin-top-25">
                            <table class="table tc-table">
                                <thead class="fixed-position margin-top-minus-35">
                                <tr>
                                    <th class="width500" data-testid="teranetConnect-ownerTitle-th">Owners</th>
                                    <th class="{{capacityFromColClass}}" dp-province-field-label="matter.teranet.connect.capacity">Capacity</th>
                                    <th class="width120" *dpShowByProvince="'matter.subjectProperty.owner.share'">Share</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="selectedParcelRegister">
                                <tr *ngFor="let owner of selectedParcelRegister.propertyOwners"
                                    (click)="handleOwnerRowSelect(owner, $event)"
                                    [ngClass]="{'active': isOwnerSelected(owner)}" >

                                    <td class="width500" data-testid="teranetConnect-ownerFullName-td">{{owner.fullName}}</td>
                                    <td class="{{capacityFromColClass}}" data-testid="teranetConnect-ownerCapacity-td">{{getCapacityCode(owner)}}</td>
                                    <td *dpShowByProvince="'matter.subjectProperty.owner.share'" data-testid="teranetConnect-ownerShare-td">{{owner.share}}</td>

                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="tabs-nav form-group tabs-bottom-header">
            <div class="col-lg-12">
                <ul>
                    <li [ngClass]="{'active' : navBottomSelected === 'Instruments'}" >
                        <span (click)="navBottomSelected = 'Instruments'"  [dp-province-field-label]="'matter.property.instruments'">Instruments</span>
                        <div class="nav-arrow"></div>
                    </li>
                    <li [ngClass]="{'disabled': selectedParcelRegister && selectedParcelRegister.isAdjacentParcel , 'active' : navBottomSelected === 'Writs'}"
                        *dpShowByProvince="'matter.subjectProperty.writs'">
                        <span (click)="writLinkClick();">Writs</span>
                        <div class="nav-arrow"></div>
                    </li>
                </ul>

                <div class="clear"></div>
            </div>

            <div class="clear"></div>
        </div>
    </div>

    <div class="col-lg-12">

        <div class="tabs-item-bottom form-horizontal">
            <div [hidden]="navBottomSelected != 'Instruments'">
                <div class="col-lg-12 padding-top-10 padding-left-3 solid-White-bg min-height-40">
                    <div class="col-lg-12">
                        <div class="col-lg-8 padding-top-10 padding-left-0"
                             *ngIf="selectedParcelRegister && selectedParcelRegister.deletedInstrumentsIncluded">
                            <dp-checkbox fieldId="showDeletedID">
                                <input type="checkbox" id="showDeletedID" [(ngModel)]="selectedParcelRegister.showDeletedInstruments"
                                       [attr.checked]="selectedParcelRegister.showDeletedInstruments? 'checked' : null"
                                       (ngModelChange)="fillInstrumentTable(true)"
                                />
                            </dp-checkbox>
                            <label (click)="toggleShowDeletedInstrument()" [dp-province-field-label]="'matter.property.showDeletedInstruments'">Show Deleted Instruments</label>
                        </div>
                        <div class="col-lg-4 text-right matter-component action-buttons margin-top-10">
                            <div class="dropdown dropdown-open-left" [class.active]="actionButtonFlagForInstrument"  >
                                <button class="dp-btn-white dropbtn select-button" id="actionBtn" name="actionBtn"
                                        (click)="toggleActionButtonFlagForInstrument($event)"
                                        [disabled]="parcelRegisters.length == 0"
                                        *dpShowByProvince="'matter.subjectProperty.action'"
                                >
                                    <i class="fa fa-bars"  aria-hidden="false"></i>
                                    <span>Actions</span>
                                </button>
                                <div class="dropdown-matter-type-list select-button-list">
                                    <div class="dropdown-content">
                                        <button [disabled]="selectedInstrumentsIndexes.length == 0" (click)="openOrderInstrument()" >Order Selected Instruments</button>
                                    </div>
                                    <div class="dropdown-content">
                                        <button (click)="addNewInstrument()" >Add Instrument</button>
                                    </div>
                                    <div class="dropdown-content">
                                        <button (click)="checkStatusOfInstruments()" >Check Status of Unavailable Instruments</button>
                                    </div>
                                    <div class="dropdown-content">
                                        <button (click)="viewAdjacentParcelAnalysis()">Adjacent Analysis</button>
                                    </div>
                                    <div class="dropdown-content" *ngIf="isRequisitionApplicable">
                                        <button (click)="addOffTitleRequistion()">Add "off-title" Requisition</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <p *ngIf="isUndertakingsDisabled()" class="linked-matter-text">Due to linking with sale matter {{matter?.matterLink?.linkedMatterNumber}}, undertakings to be created and maintained in the sale matter.</p>
                </div>
                <div class="col-xs-12 top-50 bottom-table-wrapper">
                    <table class="table tc-table table-vertical-align-top" tabindex="-1" dp-table-key-commands (click)="resetActionButtonFlagForInstrument()" [mfData]="instrumentArray" #mf="mfDataTable" [mfRowsOnPage]="50">
                        <thead class="z-index-1 fixed-position margin-top-minus-35">
                        <tr>
                            <th class="width70 padding-left-3"
                                *dpShowByProvince="'matter.subjectProperty.imageCheckbox'"
                            >
                                <dp-checkbox (click)="toggleSelectAllInstruments()">
                                    <input type="checkbox" [(ngModel)]="selectAllInstruments"
                                    [disabled]="parcelRegisters.length == 0"/>
                                </dp-checkbox>
                            </th>
                            <th class="{{instrumentNofromColClass}}"
                                [dp-province-field-label]="'matter.property.instrumentNo'"
                                data-testid="teranetConnect-instrumentNo-th">Instrument No.</th>
                            <th class="width20" *dpShowByProvince="'matter.subjectProperty.adjacentParcelAnalysis'"></th>
                            <th class="width30" *dpShowByProvince="'matter.subjectProperty.instDropdownMenu'"></th>
                            <th class="{{typefromColClass}}" [dp-province-field-label]="'matter.property.type'"
                                data-testid="teranetConnect-type-th">Type/Remark
                            </th>
                            <th class="width100 text-right" *dpShowByProvince="'matter.subjectProperty.amount'">Amount</th>
                            <th class="width170" *dpShowByProvince="'matter.subjectProperty.partiesFrom'">Parties From</th>
                            <th class="width30" *dpShowByProvince="'matter.subjectProperty.spin.instDropdownMenu'"></th>
                            <th class="{{partiesTofromColClass}}"
                                [dp-province-field-label]="'matter.property.partiesTo'"
                                data-testid="teranetConnect-partiesTo-th"
                            >Parties To
                            </th>
                            <th [ngClass]="(isRequisitionApplicable)? 'width70': 'width210' " *dpShowByProvince="'matter.subjectProperty.certCred'">Cert/Cred</th>
                            <th class="{{requisitionStatusfromColClass}}"
                                *ngIf="shouldDisplayUndertakingStatus()"
                                [dp-province-field-label]="'matter.property.reqStatus'"
                                data-testid="teranetConnect-reqStatus-th"
                                >Req Status</th>
                        </tr>
                        </thead>
                        <tbody *ngIf="selectedParcelRegister">
                        <tr *ngFor="let inst of mf.data; let i=index;" [attr.tabindex]="0"
                            (click)="toggleInstrumentRowSelect(i, mf.getPage())"
                            [ngClass]="{'active':isInstrumentSelected(i, mf.getPage()) }">
                            <div class="popover-markup">
                                <span class="tool-tip-icon" [ngClass]="{'tree':inst.isAttachmentWrapper , 'treeLast': inst.isAttachmentWrapper&&inst.last }">
                                <td class="width70" [ngClass]="{'padding-left-30': inst.isAttachmentWrapper}" *dpShowByProvince="'matter.subjectProperty.imageCheckbox'">
                                    <div *ngIf="inst.primaryDocument.isDocumentAvailableForView">
                                        <div  (click)="viewInstrument(inst)">
                                            <i class="fa fa-file-pdf-o font-20" aria-hidden="true"></i>

                                        </div>
                                    </div>
                                    <div *ngIf="inst.primaryDocument.isDocumentNotAvailable"><i class="fa fa-ban font-20" aria-hidden="true"></i></div>
                                    <div *ngIf="inst.primaryDocument.isDocumentAvailableForDownload">
                                        <dp-checkbox>
                                            <input type="checkbox"
                                                   [attr.checked]="isInstrumentSelected(i, mf.getPage()) ? 'checked' : null"  />
                                        </dp-checkbox>
                                    </div>
                                </td>
                                <td class="{{instrumentNofromColClass}}" [ngClass]="{'padding-left-20': inst.isAttachmentWrapper}"
                                    data-testid="teranetConnect-instrumentNo-td">
                                    {{inst.instrumentNumber}}

                                    <br>
                                    {{instrumentDateAsDate(inst)}}
                                </td>
                                <td  class="width20" *dpShowByProvince="'matter.subjectProperty.adjacentParcelAnalysis'">
                                    <span *ngIf="inst.instrument && inst.instrument.adjacentParcelAnalysisAvailable">
                                            <i class="fa fa-warning" style="color: #ffcc00;" (click)="viewAdjacentParcelAnalysis()"></i>
                                    </span>
                                </td>
                                <td class="width30" *dpShowByProvince="'matter.subjectProperty.instDropdownMenu'">
                                        <dp-dropdown-menu
                                            [dropDownItems]="inst.instrumentBurgerMenuItems"
                                            (itemClicked)="clickInstrumentsBurgerMenu(inst, $event)"
                                            (menuOpened)="instrumentsDropdownMenuOpen($event)"
                                        >
                                        </dp-dropdown-menu>
                                    <div class="margin-left-30" *ngIf="!inst.hover"></div>
                                </td>
                                <td class="{{typefromColClass}}" data-testid="teranetConnect-typeContext-td">
                                    {{inst.typeContext}}<br>
                                    <div>
                                        <ul *ngIf="inst.instrument && inst.instrument.remarks"
                                            data-testid="teranetConnect-remark-ul">
                                            <li [ngClass]="{'padding-bottom-5':inst.instrument.remarks && inst.instrument.remarks.length>1}"
                                                class="padding-bottom-5"
                                                *ngFor="let remark of inst.instrument.remarks">
                                                {{ remark }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="width100 text-right padding-right-10" *dpShowByProvince="'matter.subjectProperty.amount'">
                                    <span *ngIf="!inst.isAttachmentWrapper">{{inst.amountFormatted}}</span></td>
                                <td class="width170" *dpShowByProvince="'matter.subjectProperty.partiesFrom'">
                                    <div>
                                        <ul *ngIf="inst.instrument && inst.instrument.partiesFrom">
                                            <li [ngClass]="{'padding-bottom-5':inst.instrument.partiesFrom && inst.instrument.partiesFrom.length>1}" class="width150" *ngFor="let partyFrom of inst.instrument.partiesFrom">
                                                {{partyFrom}}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="width40" *dpShowByProvince="'matter.subjectProperty.spin.instDropdownMenu'"
                                    data-testid="teranetConnect-instDropdownMenu-td">
                                    <dp-dropdown-menu
                                        [dropDownItems]="inst.instrumentBurgerMenuItems"
                                        (itemClicked)="clickInstrumentsBurgerMenu(inst, $event)"
                                        (menuOpened)="instrumentsDropdownMenuOpen($event)"
                                        *ngIf="isSpinInstrumentMenuVisible(inst.instrument)"
                                        [disabled]="isUndertakingsDisabled()"
                                    >
                                    </dp-dropdown-menu>
                                </td>
                                <td class="{{partiesTofromColClass}}"
                                    data-testid="teranetConnect-partiesTo-td">
                                    <div>
                                        <ul *ngIf="inst.instrument && inst.instrument.partiesTo"
                                            data-testid="teranetConnect-partiesTo-ul">
                                            <li [ngClass]="{'padding-bottom-5':inst.instrument.partiesTo && inst.instrument.partiesTo.length>1}" *ngFor="let partyTo of inst.instrument.partiesTo">
                                                {{partyTo}}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td [ngClass]="isRequisitionApplicable? 'width70': null " *dpShowByProvince="'matter.subjectProperty.certCred'">{{inst.instrument?.instrumentStatus}}</td>
                               <td *ngIf="isRequisitionApplicable" class="text-right width190" data-testid="teranetConnect-requisitionStatus-td">{{inst.requisitionStatus}}</td>
                                <td *dpShowByProvince="'matter.subjectProperty.spin.undertakingStatus'"
                                    class="text-right width190"
                                    data-testid="teranetConnect-underTakingStatus-td">{{ inst.undertakingStatus }}</td>
                                </span>
                                <span class="tool-tip-message" *ngIf="getInstrumentHoverText(inst)">
                                    <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                    <span>{{getInstrumentHoverText(inst)}}</span>
                                </span>
                            </div>

                        </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="7">
                                    <mfBootstrapPaginator [rowsOnPageSet]="[10,25,100]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </div>


            </div>
            <div [hidden]="navBottomSelected != 'Writs' || (selectedParcelRegister && selectedParcelRegister.isAdjacentParcel)">
                <div class="form-group margin-top-10">
                    <div class="col-lg-12 padding-top-10 padding-bottom-20 solid-White-bg">
                     <div class="col-lg-8 padding-top-10" >

                            </div>
                        <div class="col-lg-4 text-right matter-component action-buttons margin-top-10">
                            <div class="dropdown dropdown-open-left" [class.active]="actionButtonFlagForWrits"   >
                                <button class="dp-btn-white dropbtn select-button" id="writsActionBtn" name="actionBtn"
                                        (click)="toggleActionButtonFlagForWrit($event)"
                                        [disabled]="parcelRegisters.length == 0" >
                                    <i class="fa fa-bars"  aria-hidden="false"></i>
                                    <span>Actions</span>
                                </button>
                                <div class="dropdown-matter-type-list select-button-list">
                                    <div class="dropdown-content">
                                        <button [disabled]="selectedNameIndexes.length == 0" (click)="orderNameWritSearch()">Order Name Search</button>
                                    </div>
                                    <div class="dropdown-content">
                                        <button [disabled]="selectedWritExecutions.length == 0" (click)="orderWritsModal()">Order Writs</button>
                                    </div>
                                    <div class="dropdown-content">
                                        <button (click)="addNameWritSearch()">Add Name for Writ Search</button>
                                    </div>
                                    <div class="dropdown-content">
                                        <button (click)="addNumberWritSearch()">Add Writ By Number</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="view-matter bottom-table-wrapper">
                    <table class="standard-table" dp-table-key-commands (click)="resetActionButtonFlagForWrit()">
                        <thead class="fixed-position">
                        <tr>
                            <th class="tc-1"><div>Party's Name</div></th>
                            <th class="tc-3"></th>
                            <th class="tc-4"><div>Date of Cert</div></th>
                            <th class="tc-5"><div>EO</div></th>
                            <th class="tc-6"><div>Search Status/Writ Details</div></th>
                            <th class="tc-7"><div>Declaration Type(s)<br><span class="col-lg-4 noPadding">Client</span><span class="col-lg-4 noPadding">Solicitor</span></div></th>
                        </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let writWrapper of writsCollection.writsWrapper; let i=index;">
                                <tr (click)="toggleNameRowSelect(i)"
                                    id="writ_row_{{i}}" tabindex="0"
                                    [ngClass]="{'active':isNameRowSelected(i) }" >
                                    <td class="tc-1" *ngIf="!isUserAddedWrit(writWrapper.primaryWrit)">
                                        <div class="inline-block padding-right-10" *ngIf="writWrapper.primaryWrit.writCertificate?.isDocumentAvailableForView" (click)="viewWritExecutionCertificate(writWrapper.primaryWrit.writCertificate.id)">
                                            <i class="fa fa-file-pdf-o font-20 padding-left-3" aria-hidden="true"></i>
                                        </div>
                                        <div class="inline-block padding-right-10" *ngIf="!(writWrapper.primaryWrit.writCertificate?.isDocumentAvailableForView)">
                                            <dp-checkbox>
                                                <input type="checkbox"
                                                       [attr.checked]="isNameRowSelected(i)? 'checked' : null"/>
                                            </dp-checkbox>
                                        </div>
                                        <div class="inline-block max-width-300">{{writWrapper.primaryWrit.partyName}}
                                            ({{getPartyOriginForDisplay(writWrapper.primaryWrit)}})</div>
                                    </td>
                                    <td class="tc-1" *ngIf="isUserAddedWrit(writWrapper.primaryWrit)">
                                        <div class="padding-left-10">User Added Writs</div>
                                    </td>
                                    <td class="tc-3">
                                        <div class="tc-3" *ngIf="!isUserAddedWrit(writWrapper.primaryWrit)">
                                            <dp-dropdown-menu
                                                [dropDownItems]="writWrapper.writBurgerMenuItems"
                                                (itemClicked)="clickWritBurgerMenu(writWrapper.primaryWrit, $event)"
                                                (menuOpened)="resetAllActionButtons()"
                                                [menuSize]="[1.5]">
                                            </dp-dropdown-menu>
                                        </div>
                                    </td>
                                    <td class="tc-4"><div class="tc-4">{{writWrapper.primaryWrit.certificateDate}}</div></td>
                                    <td class="tc-5"><div class="tc-5">{{writWrapper.primaryWrit.enforcementOffice}}</div></td>
                                    <td class="tc-6">
                                            <div class="col-lg-6" *ngIf="!(writWrapper.primaryWrit.writDetails)">not searched</div>
                                            <div class="col-lg-6" *ngIf="(writWrapper.primaryWrit.writDetails)">{{writWrapper.primaryWrit.writDetails}}</div>
                                    </td>
                                    <td class="tc-7">
                                        <div class="tc-7">
                                            <div class="col-lg-4"><i *ngIf="isClientDeclaration(writWrapper.primaryWrit)" style="color:#1fa345" class="fa fa-check"></i><span *ngIf="!isClientDeclaration(writWrapper.primaryWrit) && !isSolicitorDeclaration(writWrapper.primaryWrit)">none</span></div>
                                            <div class="col-lg-4"><i *ngIf="isSolicitorDeclaration(writWrapper.primaryWrit)" style="color:#1fa345" class="fa fa-check"></i></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngFor="let writExecution of writWrapper.primaryWrit.writExecutions; let i=index;" (click)="toggleExecutionRowSelect(writExecution)" [ngClass]="{'active':isExecutionRowSelected(writExecution)}">
                                    <td class="tc-1">
                                        <div class="display-inline padding-right-10 padding-left-20" *ngIf="writExecution.executionCertificate?.isDocumentAvailableForView" (click)="viewWritExecutionCertificate(writExecution.executionCertificate.id)">
                                            <i class="fa fa-file-pdf-o font-20" aria-hidden="true"></i>
                                        </div>
                                        <div class="display-inline padding-right-10 padding-left-20" *ngIf="!(writExecution.executionCertificate?.isDocumentAvailableForView)">
                                            <dp-checkbox>
                                                <input type="checkbox"
                                                       [attr.checked]="isExecutionRowSelected(writExecution) ? 'checked' : null"/>
                                            </dp-checkbox>
                                        </div>
                                        <div class="display-inline">{{writExecution.partyName}}</div>
                                    </td>
                                    <td class="tc-3">
                                        <div class="tc-3">
                                            <dp-dropdown-menu
                                                [dropDownItems]="writExecution.writExecutionBurgerMenuItems"
                                                (itemClicked)="clickExecutionBurgerMenu(writExecution, $event)">
                                            </dp-dropdown-menu>
                                        </div>
                                    </td>
                                    <td class="tc-4"><div class="tc-4">{{writExecution.certificateDate}}</div></td>
                                    <td class="tc-5"><div class="tc-5">{{writExecution.enforcementOffice}}</div></td>
                                    <td class="tc-6"><div class="tc-6">{{writExecution.executionDetails}}</div></td>
                                    <td class="tc-7">
                                        <div class="tc-7">
                                            <div class="col-lg-4"><i *ngIf="isClientDeclarationParagraph(writExecution)" style="color:#1fa345" class="fa fa-check"></i></div>
                                            <div class="col-lg-4"><i *ngIf="isSolicitorDeclarationParagraph(writExecution)" style="color:#1fa345" class="fa fa-check"></i></div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
             </div>

        </div>
    </div>
</div>
