import {Instrument} from './instrument';
import {Mortgage} from '../../matters/shared/mortgage';
import {Matter} from '../../matters/shared/matter';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TeranetImportMortgage extends BaseEntity {

  registrationDate: string;
  registrationNo: string;
  mortgageesText: string;
  existingMortgageId: number;
  mortgageDispositionType: string;
  isMortgageDispositionType: boolean;
  instrumentType: string;

  constructor(teranetImportMortgage?: TeranetImportMortgage) {
    super(teranetImportMortgage);
  }

  mapInstrumentData(instrument: Instrument): void {
    this.registrationDate = instrument.instrumentDate;
    this.registrationNo = instrument.instrumentNumber;
    this.instrumentType = instrument.type;
    let mortgagees = instrument.partiesTo ? instrument.partiesTo.filter(partyTo => partyTo && partyTo.trim()) : [];
    if (mortgagees.length > 0) {
      this.mortgageesText = mortgagees.map(item => item && item.toString().toUpperCase()).join(' and ');
    }
  }

  isMortgageMatchingFound(teranetImportMortgage: TeranetImportMortgage): boolean {
    return teranetImportMortgage && (this.compareRegistrationDate(teranetImportMortgage) && this.compareRegistrationNo(teranetImportMortgage)
      && this.compareMortgagee(teranetImportMortgage));
  }

  compareRegistrationDate(teranetImportMortgage: TeranetImportMortgage): boolean {
    return ((this.registrationDate && teranetImportMortgage.registrationDate &&
      this.registrationDate == teranetImportMortgage.registrationDate) || (!this.registrationDate && !teranetImportMortgage.registrationDate));
  }

  compareRegistrationNo(teranetImportMortgage: TeranetImportMortgage): boolean {
    return ((this.registrationNo && teranetImportMortgage.registrationNo &&
      this.registrationNo == teranetImportMortgage.registrationNo) || (!this.registrationNo && !teranetImportMortgage.registrationNo));
  }

  compareMortgagee(teranetImportMortgage: TeranetImportMortgage): boolean {
    return (this.mortgageesText == teranetImportMortgage.mortgageesText);
  }

  mapMortgageData(mortgage: Mortgage, matter: Matter): void {
    this.registrationDate = mortgage.mortgageRequestDate;
    this.registrationNo = mortgage.mortgageRequestNo;
    this.instrumentType = mortgage.financingType;
    this.existingMortgageId = mortgage.id;
    this.mortgageDispositionType = mortgage.mortgageDispositionType;
    this.isMortgageDispositionType = !mortgage.isMortgageDispositionBlank();
    this.mortgageesText = matter.getMortgageeDisplayName(mortgage.id);
  }
}
