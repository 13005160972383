import {Component, Inject} from '@angular/core';
import {OwnerData} from './teranet-owner-import.modal.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class TeranetOwnerGenderImportContext {
  ownerData: OwnerData;
}

@Component({
  selector: 'dp-owner-gender-import-modal-content',
  templateUrl: 'teranet-owner-gender-import.modal.component.html',
  styleUrls: ['./teranet-owner-gender-import.modal.component.scss']
})
export class TeranetOwnerGenderImportModal extends ModalComponent<TeranetOwnerGenderImportContext> {
  constructor(
    public dialog: MatDialogRef<TeranetOwnerGenderImportModal>,
    @Inject(MAT_DIALOG_DATA) context?: TeranetOwnerGenderImportContext
  ) {
    super(dialog, context);
  }

  organization() {
    if (this.context.ownerData) {
      this.context.ownerData.genderType = 'OTHERENTITY';
    }
    this.dialog.close(this.context.ownerData);
  }

  person() {
    this.dialog.close(this.context.ownerData);
  }

  cancel() {
    this.dialog.close();
  }
}
