<form class="form-horizontal container-fluid custom-modal-container" id="vacantOccTaxForm" *ngIf="localAdj">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Vacant Occupancy Tax</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Current Value Assessment amount</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="asessmentAmount"
                           name="asessmentAmount"
                           [(ngModel)]="localAdj.assessmentAmount"
                           class="form-control"
                           significant-currency-digits="9"  max-decimals="2" dp-default-currency
                           dp-currency
                           allow-negative="false"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Interest Rate</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="interestRate"
                           name="interestRate"
                           [(ngModel)]="localAdj.interestRate"
                           class="form-control"
                           dp-percentage [padding-zero]="true"
                           [max-decimals]=2

                    />
                </div>
                <div class="col-lg-1">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>






        <!-- adjustment report -->
        <div style="border-top: 1px solid #cccccc;">
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">
                        {{localAdj.adjustmentDescription}}
                        <!--
                        <span *ngIf="localAdj.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'">
                            <sup>1</sup>
                        </span>
                        <span *ngIf="localAdj.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'">
                            <sup>2</sup>
                        </span>
                        -->

                    </label>
                </div>
            </div>

            <div *ngFor="let line of details">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                    <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                </div>
            </div>
            <ng-container>
                <div class="col-lg-12  form-group">
                    <div class="col-lg-4">
                        <label class="control-label"
                        ><span>Credit {{purchaserLabel}}</span></label>
                    </div>
                    <div class="col-lg-3 text-right">
                        <span>{{calculateVacantOccupancyTax() | currency:CAD:'symbol'}}</span>
                    </div>
                    <div class="col-lg-3"></div>
                </div>
            </ng-container>

        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="cancel()">
                <span>Cancel</span>
            </button>
            <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                <span>Delete</span>
            </button>

        </div>
    </div>
</form>
