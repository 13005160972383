import {TitleInsuranceBaseUser} from '../title-insurance-base-user';

export class TitlePlusUser extends TitleInsuranceBaseUser {
  instanceType: string;

  constructor(s?: TitlePlusUser) {
    super(s);
    this.instanceType = 'TITLE_PLUS';
  }
}
