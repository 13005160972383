import {DropdownModel} from './../../shared-main/dropdown-model';
import {MatterParticipant} from './../shared/matter-participant';
import {ApplicableProvisionOptionsTypes, FamilyLawAct, FlaData, MaritalStatusTypes} from './../shared/fla-data';
import {ChangeDetectorRef, Component, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import * as _ from 'lodash';
import {Subject} from 'rxjs/Subject';
import {PurchaserService} from './purchaser.service';
import {
  ClientInfoWillDocuments,
  Contact,
  currentMatter,
  DuplicateProspectActionTakenTypes,
  GetGlobalSaveModelService,
  Matter, MatterSectionKeyEnum, MatterTypeEnum,
  MatterUtil,
  Utils as SharedUtils,
  WillDocumentsToProduce
} from '../shared';
import {ContactCommandService} from '../../contact/contact-command.service';
import {ContactQueryService} from '../../contact/contact-query.service';
import {TabsService} from '../../core';
import {MatterContactInfo} from '../shared/matter-contact-info';
import {Address, SameAsAddressOption} from '../shared/address';
import {AppConfig} from '../../shared-main/app-configuration';
import {PurchaserCapacity} from './capacity/purchaser-capacity';
import {DialogService} from '../../shared/dialog/dialog.service';
import {dropDowns} from '../shared/matter-drop-downs';
import {PurchasersTitleModalComponent} from './title/purchasers-title.component';
import {MatterParticipantWrapper} from '../shared/matter-participant-wrapper';
import {AddressTypes} from '../shared/address-types';
import {BankAccount} from '../shared/BankAccount';
import {ContactTab} from '../../contact/contact-tab';
import {CleanUpMatterComponent} from '../../shared-main/cleanUpMatterData.guard';
import {PurchaserFamilyLawAct} from './family-law-act/purchaser-family-law-act';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {StatusBarMessages} from '../../shared-main/status-bar-messages';
import {CommonContactDialogComponent} from '../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {MatterTab} from '../matter-tab';
import {Subscription} from 'rxjs/Subscription';
import {
  Constants,
  FlaErrorValues,
  FlaStatementType,
  MainClientFlaErrorValues,
  SnapshotBurgerMenuActions
} from '../../shared-main/constants';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {ContactService} from '../../shared-main/contact.service';
import {ContactSuggestionSalutationEnum} from '../../contact/contact-suggestion/contact-suggestion-salutation-enum.enum';
import {ContactSuggestion} from '../../contact/contact-suggestion/contact-suggestion';
import {DocumentProductionService} from '../document-production/document-production.service';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {MatterParticipantService} from '../matter-participant-service';
import {ErrorValidator} from '../../shared-main/error-validator';
import {DdlTypeForYesNo} from '../shared/mortgage';
import {noYesOptions} from '../mortgages/mortgage/dropdown-options';
import {IvfGeneratorBase} from '../shared/ivf-generator-base';
import {AddressFormComponent} from '../../shared-main/address-Form';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {SuggestedSpouseModal} from '../suggested-spouse-modal/suggested-spouse-modal.component';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {Cirf} from '../shared/cirf/cirf';
import {CirfMatterData} from '../shared/cirf/cirf-matter-data';
import {MatterService} from '../matter.service';
import {TitleDetailsUtil} from '../shared/title-details-util';
import {PrecedentService} from '../mortgages/mortgage/precedent/precedent.service';
import {OpportunitiesService} from '../../opportunities/opportunities.service';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {OpportunityView} from '../../opportunities/opportunities-list/opportunity-view';
import {vendorWarrantyOptions} from '../../shared-main/province-based-dropdowns';
import {SpouseType, SpouseTypes} from '../suggested-spouse-modal/suggested-spouse';
import {CirfHelperService} from '../shared/cirf/cirf-helper.service';
import {MatterCleanUpUtil} from '../shared/matter-utils/matter-clean-up-util';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {ContactChangesListener} from '../../contact/contact-changes-listener';
import {MatterParticipantUtil} from '../shared/matter-utils/matter-participant-util';
import {WelcomeClientModalComponent} from './cirf/welcome-client/welcome-client-modal.component';
import {DuplicateProspectModalResult, ModalResult} from '../../shared-main/enums';
import {messages} from '../../common';
import {DuplicateContactData} from '../../opportunity-matter/duplicate-prospect/duplicate-contact-data';
import {DuplicateProspectModalComponent} from '../../opportunity-matter/duplicate-prospect/duplicate-prospect.modal.component';
import {ContactUtil} from '../shared/contact-util';
import {RemovedReconciledBorrowerRelatedData} from '../../shared-main/telus/stewart-mortgage-instruction';
import {ProvinceApplicableGenders} from '../../shared-main/province-applicable-gender';
import {DEFAULT_LABEL, provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {WillAssetAndDebtsUtil} from '../assets-debts-gifts/will-assets-debts-util';
import {PttService} from '../property-transfer-tax/ptt.service';

declare var jQuery: any;
const GENDERS = [
  {label: '', value: ''},
  {label: '??????', value: 'Not sure'},
  {label: 'Male', value: 'Male'},
  {label: 'Female', value: 'Female'}
];

export const defaultTab = 'Profile';

const ContactDetails = [
  {
    label: 'Profile',
    route: 'profile'
  },
  {
    label: 'Capacity',
    route: undefined
  },
  {
    label: 'Family Law Act',
    route: undefined
  },
  {
    label: 'ID Verification',
    route: 'id-details'
  },
  {
    label: 'PTT',
    route: undefined
  }
];

/**
 * ### Notification: Please synchronize MainSideParticipantDetailsComponent if you modify purchaser###
 */
@Component({
  selector: 'dp-purchaser',
  templateUrl: 'purchaser.component.html',
  styleUrls: [
    './purchaser.component.scss'
  ],
  providers: [ PurchaserService, EmailFieldService ]
})

/**
 * The purchaser of a matter is the client purchasing the service. Initially created as a component dealing with a PURCHASE matter's purchaser, we're
 * extending the meaning of this component to a matter main client. As such, we're dealing with
 * - purchasers for a PURCHASE matter
 * - vendors for a SALE matter
 * - mortgagors for a MORTGAGE matter
 * - testators for a WILL matter
 */
@FocusFirstElementDecorator()
export class PurchaserComponent extends IvfGeneratorBase implements OnInit, CleanUpMatterComponent {
  //TODO: this will be renamed to MatterClientComponent, a more generic term,
  // applicable to all types of matters. Until then, purchaser should be understood as mainClient in this component's context

  @ViewChild('postClosingAddressForPurchase') postClosingAddressFormComponent: AddressFormComponent;
  @Input() showWizardFields: boolean = false;

  isWillClientInfo: boolean = false;
  subscription: any;
  // the first place, anyway, look into removing this instance variable
  purchaserSaveModel: any;

  /** These are clients, not purchasers, necessarily (differ with the matter type): TODO: rename to mainClientWrappers */
  selectedClientPurchasers: Array<MatterParticipantWrapper>;

  globalSaveFlag: boolean;
  searchTermStreamClient = new Subject<string>();
  utils: any;
  clientPurchasers: any;
  clientPurchasersLoading = false;
  autoCompleteEmptyFlag: any;
  individualClient: Contact;
  genders: any[] = [];
  flaStatements: FlaData;
  matterPurchasersCapacityOptions: any[];
  init: boolean = false;
  residingAtSubjectPropertys: DropdownModel[] = dropDowns.residingAtSubjectPropertyOptions;
  titleDetailTypes: DropdownModel[] = dropDowns.titleDetails;
  postClosingAddressCopyOptions: SameAsAddressOption[] = [];
  witnessKnowsTransferorOptions: DdlTypeForYesNo[];

  checkGenStatusIntervalID: any[] = []; // docId, interval (Tuples only in TS3.0)

  contactSalutationSuggestion: ContactSuggestion;
  salEnum = ContactSuggestionSalutationEnum;

  fieldKey: string;
  purchaserContainerId: string = MainClientFlaErrorValues.containerId;
  purchaserFieldPrefix: string = MainClientFlaErrorValues.fieldPrefix;
  purchaserFieldIndexId: string = FlaErrorValues.fieldIndexId;
  flaMatterParticipantSpouseFieldId = FlaErrorValues.flaMatterParticipantSpouseFieldId;
  flaSpouseNameFieldId = FlaErrorValues.flaSpouseNameFieldId;

  preClosingAddressFlag: boolean;
  postClosingAddressFlag: boolean;
  _matter: Matter;
  documentProfile: DocumentProfile;
  dearf9TextIndex: number = 0;

  isConsentSpouseModalVisible: boolean = false;

  defaultDocumentProfile: DocumentProfile;

  cirfBurgerMenuItems: BurgerMenuExtendedItem[] = [];

  executionOptions: DropdownModel[] = dropDowns.executionOptions;
  documents: ClientInfoWillDocuments;
  documentsToProduce: WillDocumentsToProduce;
  willAssetsDebtsUtil: WillAssetAndDebtsUtil = new WillAssetAndDebtsUtil();
  PTT_ERROR_TOPIC: string = 'PTT';
  PERCENT_ACQUIRED_ERROR: string = 'Total interest for all borrowers must not exceed 100%';
  BC_PN_CERTIFICATE_ERROR: string = 'BC PN Certificate Number is missing';
  APPLICABLE_PTT_PARTICIPANT_ROLES: MatterParticipantRoleTypes[] = [ 'PURCHASER', 'MORTGAGOR' ];

  constructor(public route: ActivatedRoute,
              public logger: Logger,
              public childGetsFromParent: GetGlobalSaveModelService,
              public purchaserService: PurchaserService,
              public matterService: MatterService,
              public tabsService: TabsService,
              public contactQueryService: ContactQueryService,
              public contactService: ContactService,
              public contactCommandService: ContactCommandService,
              public tabsStateService: TabsService,
              public dialogService: DialogService,
              public emailFieldService: EmailFieldService,
              public changeDetectorRef: ChangeDetectorRef,
              public appConfig: AppConfig,
              public documentProfileService: DocumentProfileService,
              public errorService: ErrorService,
              public statusBarService: StatusBarService,
              public lockScreenService: LockScreenService,
              public documentProductionService: DocumentProductionService,
              public matterParticipantService: MatterParticipantService,
              public staffProfilesService: StaffProfilesService,
              public ngZone: NgZone,
              public precedentService: PrecedentService,
              public opportunitiesService: OpportunitiesService,
              public cirfHelperService: CirfHelperService,
              public documentProfileCache: DocumentProfileCache,
              public contactChangesListener: ContactChangesListener,
              public tabService: TabsService,
              public pttService: PttService) {
    super(documentProductionService, dialogService, statusBarService);
    this.emailFieldService.matter = this.matter;
    this.emailFieldService.key = EmailKeys.matterOpening;
  }

  // when calling ngOnInit without passing skipCirfLoading, its defulte value is false which in line with the name "skipCirfLoading"
  ngOnInit(excludeCapacityCheckWarning?: boolean, skipCirfLoading?: boolean): void {
    this.setRouteData();
    //this.tabsStateService.activeTab.section = this.route.routeConfig.path;
    this.purchaserSaveModel = [];
    this.selectedClientPurchasers = [];
    this.utils = new SharedUtils();
    this.genders = GENDERS;
    this.init = true;
    this.witnessKnowsTransferorOptions = noYesOptions;
    // this.purchaserOpening(this.matter.matterParticipants);

    //Initialize the matter info structure, if not present yet
    if (!this.matter.matterContactInfo.preClosingAddress || this.matter.matterContactInfo.preClosingAddress && this.matter.matterContactInfo.preClosingAddress.isEmpty) {
      this.preClosingAddressFlag = true;
    }

    if (!this.matter.matterContactInfo.postClosingAddress || this.matter.matterContactInfo.postClosingAddress && this.matter.matterContactInfo.postClosingAddress.isEmpty) {
      this.postClosingAddressFlag = true;
    }

    if (!this.matter.matterContactInfo.isDeveloperTransferor) {
      this.matter.matterContactInfo.isDeveloperTransferor = DpBooleanValueTypes.N_y;
    }

    this.matter.setUpMatterContactInfo();
    //If the purchasersCapacity is null, it will hit 500 error
    //I am not sure which place is the best place. Maybe we can put it in new Matter constructor or save matter
    // For when save matter, initMainClientsCapacity will be called
    this.matter.initMainClientsCapacity();

    if (!this.isOpportunityMatter() && !this.matter?.isWillMatter()) {
      this.matter.addDirectDepositInstruction(this.matter.mainClientType);
    }

    this.createPostClosingAddressCopyActionOptions();

    // AutoComplete
    this.searchTermStreamClient = this.refreshSubject(this.searchTermStreamClient);
    this.searchTermStreamClient
    .switchMap((term: string) => {
      this.clientPurchasersLoading = true;
      if (term.trim() === '') {
        this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;

      } else {
        this.autoCompleteEmptyFlag = false;
        let genderSearch = this.matter.isMatterProvinceBC ? ProvinceApplicableGenders.getGendersForProvince(this.matter.provinceCode) : null;
        return this.purchaserService.getClientPurchaser(term, false, false, genderSearch);
      }
    })
    .subscribe(
      (purchasers: any) => {
        this.clientPurchasersLoading = false;
        this.clientPurchasers = purchasers;
      },
      error => {
        this.logger.error('clientPurchasers search error: ', error);
        this.clientPurchasersLoading = false;
      }
    );

    this.contactSalutationSuggestion = new ContactSuggestion();
    //This is an Observable which will notify the changes in javascript object.
    if (!this.subscription) {
      this.subscription = this.childGetsFromParent.getItem$
      .subscribe(() => {
        this.initPurchaserComponent(skipCirfLoading ? false : true);
      });
    }

    this.individualClient = new Contact();
    this.individualClient.gender = 'MALE';
    this.individualClient.canadianResidentFlag = 'Y/n';
    this.individualClient.activeFlag = DpBooleanValueTypes.Y_n;
    this.individualClient.genderFlag = '?'; // FIXME: should this be deceasedGender
    this.individualClient.estateStatusFlag = '?';
    this.individualClient.executingUnderSealFlag = '???';
    this.individualClient.poaDateString = ' ';

    this.updateAllSuggestions();

    // For copy link matter,  it should not add main client Tenure waring message into this tab and should add the link active tab.
    if (!excludeCapacityCheckWarning) {
      this.checkCapacityWarningMessage(this.matter.purchasersCapacity);
    }
    if (this.matter && !this.matter.isOpportunityMatter()) {
      this.getDocumentProfileForDearFields();
    }
    this.getDearf9TextStartingIndex();
    if (this.matter.isCustomMatter()) {
      if (this.matter.isCustomMatter() && this.matter.matterContactInfo.postClosingAddress.isEmpty) {
        this.matter.matterContactInfo.postClosingAddress = new Address(this.matter.matterContactInfo.preClosingAddress);
        this.matter.matterContactInfo.postClosingAddress.addressTypeCode = AddressTypes.mailing;
      }
      this.matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
    }
    if (this.matter.receiveCustomerIndividualLetterFlag == undefined) {
      this.matter.receiveCustomerIndividualLetterFlag = false;
    }
  }

  setRouteData() {
    if (this.matter?.isWillMatter()) {
      this.route.data.subscribe((data) => this.isWillClientInfo = data.isWillClientInfo);
    }
  }

  get isSpouseAdded(): boolean {
    return !!this.matter.matterParticipants.find(participant => participant.matterParticipantRole === MatterParticipantRoleTypes.TESTATOR_SPOUSE);
  }

  get participantCirfs(): Cirf[] {
    return this.activeMatterTab.participantCirfs;
  }

  set participantCirfs(cirfs: Cirf[]) {
    this.activeMatterTab.participantCirfs = cirfs;
  }

  getParticipantCirf(matterParticipant: MatterParticipant): Cirf {
    return this.participantCirfs.find(cirf => cirf.matterParticipantId == matterParticipant.matterParticipantId);
  }

  get matterCirf(): Cirf {
    return this.activeMatterTab.matterCirf;
  }

  set matterCirf(cirf: Cirf) {
    this.activeMatterTab.matterCirf = cirf;
  }

  //fetches all Cirfs for Matter from back-end
  async loadCirfs(): Promise<void> {

    if (!this.matter.isMatterExisting()) {
      return;
    }

    let cirfs: Cirf[] = await this.opportunitiesService.getMatterCirfs(this.matter.id).toPromise();

    //assign Matter Cirf
    this.setMatterCirf(cirfs.find(cirf => cirf.isMatterCirf()));

    //assign Participant Cirfs
    let participantCirfs: Cirf[] = cirfs.filter(cirf => cirf.isParticipantCirf());
    this.participantCirfs = participantCirfs;

    //add references to participant Cirfs to matter participant wrappers.
    this.selectedClientPurchasers.forEach(client => {
      let participantCirf: Cirf = this.participantCirfs.find(cirf => cirf.matterParticipantId == client.matterParticipantId);
      client.participantCirf = participantCirf;
      client.setMatterAndCirfFields(this.matter, this.matterCirf);
    });
  }

  get activeMatterTab(): MatterTab {
    return this.tabsService && this.tabsService.activeTab as MatterTab;
  }

  //handle updated Cirf from back-end
  updateCirf(updatedCirf: Cirf): void {
    if (updatedCirf.isMatterCirf()) {
      this.setMatterCirf(updatedCirf);
    } else if (updatedCirf.isParticipantCirf()) {
      let index: number = this.participantCirfs.findIndex(cirf => cirf.matterParticipantId == updatedCirf.matterParticipantId);
      if (index > -1) { //update existing cirf if found
        this.participantCirfs[ index ] = updatedCirf;
      } else {
        this.participantCirfs.push(updatedCirf);
      }

      //assign cirf reference to matter participant wrapper
      let participantWrapper: MatterParticipantWrapper = this.selectedClientPurchasers.find(client => client.matterParticipantId == updatedCirf.matterParticipantId);
      participantWrapper.participantCirf = updatedCirf;
    }
  }

  initPurchaserComponent(isLoadCirfs?: boolean): void {
    if (this.contactSalutationSuggestion) {
      this.contactSalutationSuggestion = new ContactSuggestion();
    }
    //Close existing shutters before switching to a new matter, to release any locks when changing to a different tab
    // open at the same topic.
    this.closeAllOpenedPurchaserShutters();
    //this.matter = matter;
    this.purchaserOpening();
    this.initiateEmailFieldService();
    this.checkEmailSameAsPrimaryContact();
    this.createPostClosingAddressCopyActionOptions();
    if (!this.isOpportunityMatter()) {
      this.loadFlaStatements();
    }

    //initPurchaserComponent gets called when by Cirf import propagation so need to load cirfs here
    if (isLoadCirfs && !this.isOpportunityMatter()) {
      this.loadCirfs();
    }
  }

  isOpportunityMatter(): boolean {
    return this.matter && this.matter.isOpportunityMatter();
  }

  propogateImportChangesAction(needUpdateSuggestions: boolean = false) {
    // currentMatter.value = new Matter(matter);
    let primaryClientWrapper: MatterParticipantWrapper = this.selectedClientPurchasers.find(item => item && item.matterParticipant && item.matterParticipant.primary);
    if (primaryClientWrapper) {
      this.updateMatterFieldsFromPrimaryContact(primaryClientWrapper.matterParticipant.contact, false, false);
    }
    this.matter.reCalculateClientReLine();
    if (needUpdateSuggestions) {
      this.updateAllSuggestions(true, true, false, false);
    }
  }

  get matter(): Matter {
    return this._matter ? this._matter : currentMatter.value;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  checkEmailSameAsPrimaryContact() {

    if (!this.selectedClientPurchasers || this.selectedClientPurchasers.length === 0) {
      return;
    }
    let purchaser: MatterParticipantWrapper =
      _.find(this.selectedClientPurchasers, (item: MatterParticipantWrapper) => {
        return item.primary;
      });

    if ((this.matter.matterContactInfo.emailSameAsPrimaryContact) &&
      (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact &&
        this.matter.matterContactInfo.email !== purchaser.matterParticipant.contact.email)) {
      this.matter.matterContactInfo.email = purchaser.matterParticipant.contact.email;
    }
  }

  ngOnDestroy(): void {

    super.ngOnDestroy();
    this.userCancelProduceDocFlag = true;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.closeAllOpenedPurchaserShutters();
  }

  initiateEmailFieldService(): void {
    this.emailFieldService.matter = this.matter;
    this.emailFieldService.key = EmailKeys.matterOpening;
  }

  clickPurchaserBurgerMenu(event, participantWrapper: MatterParticipantWrapper, index: number = undefined) {
    this.logger.info('clickBurgerMenu | event:', event);
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE: {
        this.deleteClient(participantWrapper, true, index);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_UP: {
        this.moveClientUp(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_DOWN: {
        this.moveClientDown(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT: {
        this.updateSnapshot(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.ADD_SPOUSE: {
        this.addSpouseClicked(participantWrapper);
        break;
      }

      case SnapshotBurgerMenuActions.SEND_PARTICIPANT_CIRF:
      case SnapshotBurgerMenuActions.SEND_PARTICIPANT_WELCOME_PACKAGE: {
        this.initiateParticipantCIRFRequest(participantWrapper.matterParticipant);
        break;
      }
      case SnapshotBurgerMenuActions.REVIEW_PROCESS_INTAKE: {
        this.openCIRFImportDataModal(participantWrapper.participantCirf);
        break;
      }
      case SnapshotBurgerMenuActions.CANCEL_REQUEST: {
        this.cancelRequest(participantWrapper.participantCirf);
        break;
      }
      case SnapshotBurgerMenuActions.RESEND_CIRF_WELCOME_EMAIL:
      case SnapshotBurgerMenuActions.RESEND_CIRF_EMAIL: {
        this.updateCirfWithMatterDataSaveAndSendEmail(participantWrapper.participantCirf, participantWrapper.matterParticipant);
        break;
      }
      case SnapshotBurgerMenuActions.RE_OPEN_REQUEST: {
        this.reOpenRequest(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.CIRF_INFO: {
        this.openProcessCIRFModal(participantWrapper.participantCirf);
        break;
      }

      default: {
        break;
      }
    }
  }

  addSpouseClicked(participantWrapper?: MatterParticipantWrapper) {
    if (this.matter?.isWillMatter() && !participantWrapper) {
      participantWrapper = this.selectedClientPurchasers?.find(s => s.matterParticipant.matterParticipantRole === MatterParticipantRoleTypes.TESTATOR);
      if (!participantWrapper) {
        return;
      }
    }
    MatterParticipantWrapper.clearSelectedClientsIfNotSelected(this.selectedClientPurchasers);
    this.addSpouseClient(participantWrapper);
  }

  public loadFlaStatements(): void {
    if (!this.matter.isMatterProvinceBC) {
      this.purchaserService.getFlaStatements(this.matter.provinceCode, this.matter.matterType).subscribe((flaStatements: FlaData) => {
        this.flaStatements = flaStatements;
      });
    }
  }

  public getGenderValue(v) {
    switch (v) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      default :
        return '';

    }
  }

  getDeceasedString(clientPurchaser) {
    //privateLender is Object not Class. So we need new the class
    let contact = clientPurchaser ? new Contact(clientPurchaser) : null;
    return contact ? contact.getDeceasedString() : '';
  }

  // initialize create and edit contact.
  purchaserOpening(): void {
    this.buildPurchaserStructures();
    this.globalSaveFlag = true;
  }

  /**
   * The list of main clients for this matter (based on the matter type, purchasers, morgagors or sellers)
   */
  get clients(): MatterParticipant[] {
    //TODO: cache this, it's called multiple times when the page is parsed (each time filtering through the participants list)
    return this.matter.mainClients;
  }

  // This method is for fill the purchaser tab data and create
  //  the autocomplete (contact) dynamically .
  buildPurchaserStructures(): void {
    this.matterPurchasersCapacityOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(this.clients.length, this.matter.provinceCode);

    this.purchaserSaveModel = [];

    //These are purchasers and already come back sorted by priority from the matter getter
    let matterParticipantSorted: MatterParticipant[] = this.clients || [];

    this.selectedClientPurchasers = [];
    let length: number = matterParticipantSorted.length;

    for (let i: number = 0; i < length; i++) {

      let contact: Contact = matterParticipantSorted[ i ].contact;
      matterParticipantSorted[ i ].contact = new Contact(contact);

      //remove the idInfoEnteredBy and enteredOn
      // matterParticipantSorted[i].contact.idInfoEnteredBy = undefined;
      // matterParticipantSorted[i].contact.enteredOn = undefined;
      const matterParticipantWrapper: MatterParticipantWrapper
        = this.createWrapperFromMatterParticipant(matterParticipantSorted[ i ]);
      matterParticipantWrapper.showLabel = true;
      this.selectTab(defaultTab, matterParticipantWrapper);
      this.selectedClientPurchasers.push(matterParticipantWrapper);

      // let isStale: boolean = false;
      let {sourceContactId}: Contact = matterParticipantSorted[ i ].contact;
      if (sourceContactId) {
        this.contactQueryService
        .getContactForMatter(sourceContactId)
        .subscribe((sourceContact: Contact) => {
          this.matterParticipantService.updateParticipantWrapperState(matterParticipantWrapper, sourceContact);
        });
      }
    }

    if (length === 0) {
      this.initializedPurchaser();

    }
    // this.selectedClientPurchasers.forEach(item=>{item.showLabel = true});
    this.updateAllSuggestions();
  }

  /**
   * This method creates matter participant wrapper from existing matter participant
   * @param matterParticipant
   * @returns {MatterParticipantWrapper}
   */
  createWrapperFromMatterParticipant(matterParticipant: MatterParticipant): MatterParticipantWrapper {
    return MatterParticipantWrapper.createWrapperFromMatterParticipant(matterParticipant, defaultTab);
  }

  togglePurchasersCapacity(): void {
    let index: number;
    index = this.matterPurchasersCapacityOptions.map(m => m.value).indexOf(this.matter.purchasersCapacity);
    index = (index + 1) % this.matterPurchasersCapacityOptions.length;
    this.matter.purchasersCapacity = this.matterPurchasersCapacityOptions[ index ].value;
  }

  commaDelimitedNames(participants: MatterParticipantWrapper[], field?: string, includeSurname?: boolean): string {
    if (!participants || participants.length === 0) {
      return '';
    }

    let finalNames: string[] = participants.map((purchaser: MatterParticipantWrapper) => {
      let matterParticipant = purchaser.matterParticipant;

      if (!matterParticipant) {
        return '';
      }

      if (matterParticipant.contact.gender === '??????'
        || matterParticipant.contact.gender === 'QUESTION'
        || matterParticipant.contact.gender === 'MALE'
        || matterParticipant.contact.gender === 'FEMALE'
        || matterParticipant.contact.gender === 'MALEPOA'
        || matterParticipant.contact.gender === 'FEMALEPOA') {

        if (field === 'dear') {
          if (includeSurname === true) {
            return matterParticipant.getSalutationFirsLastName('lastName', true);
          } else {
            return matterParticipant.getSalutationFirsLastName('firstName', true);
          }
        } else {
          return matterParticipant.getSalutationFirsLastName();
        }

      } else if (matterParticipant.contact.gender === 'CORPORATION' || matterParticipant.contact.gender === 'OTHERENTITY') {
        // ELSEIF (Purchaser Gender IN ('Corporation', 'Other Entity') THEN Results = Results + <Company Name>
        return matterParticipant.contact.organizationName;
      } else if (matterParticipant.contact.gender === 'ESTATE') {
        // ELSE IF (Purchaser Gender IN ('Estate') THEN Results = Results + Estate of <Surname of Deceased>
        return 'Estate of ' + matterParticipant.contact.lastName;
      }
    }).filter(value => value != '');

    if (finalNames.length > 1) {
      let lastName = finalNames.pop();
      if (field === 'dear') {
        let dearText = finalNames.join(', ');
        if (lastName) {
          if (includeSurname === true) {
            dearText = dearText + ' and ' + lastName;
          } else {
            dearText = dearText + ' & ' + lastName;
            if (dearText === ' & ') {
              return '';
            }
          }
        }
        dearText = dearText.substring(0, 75);
        return dearText;
      } else {
        if (lastName) {
          return finalNames.join(', ') + ' and ' + lastName;
        } else {
          return finalNames.join(', ');
        }
      }
    } else {
      return finalNames[ 0 ];
    }
  }

  updatePurchasersCapacities(purchasersSetting: string): void {
    this.enableSave();
    this.selectedClientPurchasers.forEach((purchaser: MatterParticipantWrapper) => {
      if (purchaser.matterParticipant) {
        this.setPurchaserCapacityAndShare(purchaser.matterParticipant, purchasersSetting);
      }
    });
    if ('OTHER' !== purchasersSetting) {
      this.selectedClientPurchasers.filter(item => item.expanded).map(
        selectedClient => {
          selectedClient.selectedTab = 'Profile';
        }
      );
    } else {
      if (this.matter.isMatterProvinceMBorSK && this.matter.purchasersCapacity != 'OTHER') {
        this.selectedClientPurchasers.forEach((purchaser: MatterParticipantWrapper) => {
          if (purchaser.matterParticipant) {
            purchaser.matterParticipant.purchaserShare = 0 + '';
          }
        });
      }
    }

    this.checkCapacityWarningMessage(purchasersSetting);
  }

  checkCapacityWarningMessage(purchasersCapacitySetting: string): void {
    let asTenantsFieldKey: string = 'matter.mainClient.capacity.asTenants';
    let registeredOwnerFieldKey: string = 'matter.mainClient.capacity.registeredOwner';

    if (this.matter.isMatterProvinceMBorSK || this.matter.isMatterProvinceAB) {
      asTenantsFieldKey = 'matter.mainClient.tenure.asTenants';
      registeredOwnerFieldKey = 'matter.mainClient.tenure.registeredOwner';
    }
    if (!this.matter.isSale || this.matter.isMatterProvinceMBorSK) {
      if (this.clients.length < 2
        && (purchasersCapacitySetting === 'JOINT_TENANTS'
          || purchasersCapacitySetting === 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES'
          || purchasersCapacitySetting === 'TENANTS_IN_COMMON_SPLIT_SHARES')) {

        this.errorService.addDpFieldError(DPError.createDPError(asTenantsFieldKey));
      } else {
        this.errorService.removeDpFieldError(asTenantsFieldKey);
      }

      if (this.clients.length > 1 && purchasersCapacitySetting === 'REGISTERED_OWNER') {
        this.errorService.addDpFieldError(DPError.createDPError(registeredOwnerFieldKey));
      } else {
        this.errorService.removeDpFieldError(registeredOwnerFieldKey);
      }
    }
  }

  setPurchaserCapacityAndShare(purchaser: MatterParticipant, matterPurchasersSetting: string) {
    switch (matterPurchasersSetting) {
      case 'UNSPECIFIED_CAPACITY':
        purchaser.purchaserCapacity = null;
        purchaser.purchaserShare = null;
        break;

      case 'JOINT_TENANTS':
        purchaser.purchaserCapacity = 'JOINT_TENANTS';
        purchaser.purchaserShare = null;
        break;

      case 'TENANTS_IN_COMMON_SPLIT_SHARES':
        purchaser.purchaserCapacity = 'TENANTS_IN_COMMON';
        //This value also gets changed when the purchasers are added or removed from the matter, see updatePurchasersSharesIfNeeded()
        purchaser.purchaserShare = PurchaserCapacity.getDynamicValueInterest(this.clients.length);
        break;

      case 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES':
        purchaser.purchaserCapacity = 'TENANTS_IN_COMMON';
        purchaser.purchaserShare = null;
        break;

      case 'REGISTERED_OWNER':
        purchaser.purchaserCapacity = 'REGISTERED_OWNER';
        purchaser.purchaserShare = null;
        break;

      case 'OTHER':
        let tempPrevValue: string = purchaser.purchaserShare;
        purchaser.purchaserShare = purchaser.previousPurchaserShareValue ? purchaser.previousPurchaserShareValue : '';
        if (purchaser.purchaserShare === '' && !!tempPrevValue) {
          purchaser.purchaserShare = tempPrevValue;
        }
        break;
    }
  }

  /**
   * This method is to be invoked whenever the number of purchasers changes, so as to have the dynamic text on the share updated accordingly, if needed
   */
  updatePurchasersSharesIfNeeded(): void {
    this.matterPurchasersCapacityOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(this.clients.length, this.matter.provinceCode);
    if (this.matter.purchasersCapacity === 'TENANTS_IN_COMMON_SPLIT_SHARES') {
      this.selectedClientPurchasers.forEach((purchaser: MatterParticipantWrapper) => {
        let matterParticipant = purchaser.matterParticipant;
        if (matterParticipant) {
          matterParticipant.purchaserCapacity = 'TENANTS_IN_COMMON';
          matterParticipant.purchaserShare = PurchaserCapacity.getDynamicValueInterest(this.clients.length);
        }
      });
    }

  }

  public cleanUpMatterFieldsFromPrimaryContact(): void {
    let mcInfo: MatterContactInfo = this.matter.matterContactInfo;
    mcInfo.envelopeSalutation = null;
    mcInfo.envelopeSalutationContinued = null;
    mcInfo.dearText = null;
  }

  public updateMatterFieldsFromPrimaryContact(primaryContact: Contact, initialPrimarySelection = false, updateAllSuggestions: boolean = true) {
    this.matter.updateMatterContactInfoFromPrimaryContact(primaryContact, initialPrimarySelection);
    this.updatePostClosingAddressProvinceForSale();
    if (updateAllSuggestions) {
      this.updateAllSuggestions(false, true);
    }
    this.createPostClosingAddressCopyActionOptions();
  }

  validateWillDelete() {
    let testatorSpouse = this.matter?.matterParticipants?.find((mp) => mp?.matterParticipantRole === 'TESTATOR_SPOUSE');
    if (this.willAssetsDebtsUtil.areParticipantsUsedInAssets([ testatorSpouse?.matterParticipantId ])) {
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(
          '',
          'You are referencing spouse in other section(s) of this matter. Please reassign those fields first before proceeding with the deletion.',
          'Client Info',
          'ERROR'
        )
      );
      return false;
    }
    return true;
  }

  async deleteClient(purchaser: MatterParticipantWrapper, needConfirm: boolean = true, index: number = undefined): Promise<void> {
    if ([ 'TESTATOR', 'TESTATOR_SPOUSE' ].includes(purchaser?.matterParticipant?.matterParticipantRole) && !this.validateWillDelete()) {
      return;
    }
    let clientTitle: string;
    if (this.matter.isWillMatter()) {
      clientTitle = MatterUtil.capitalizeWord(`${ (this.mainClientTitle(index).toLowerCase()) }`);
    } else {
      clientTitle = `${ this.matter && this.matter.mainClientTitle && this.matter.mainClientTitle.toLowerCase() }`;
    }
    let deleteConfirmationMessage = `Are you sure you would like to delete this ${ clientTitle }?`;
    deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, purchaser.matterParticipant, this.matter.mainClientType);
    let res;
    if (needConfirm) {
      res = await this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').toPromise();
    }
    if (!needConfirm || res) {
      if (this.matter && purchaser && purchaser.matterParticipant && this.documentProductionService && this.contactQueryService) {
        this.documentProductionService.tryToRevokePackage(this.matter, purchaser.matterParticipant, this.contactQueryService);
      }
      this.enableSave();
      this.documentProductionService.tryToRevokePackage(this.matter, purchaser.matterParticipant, this.contactQueryService);
      await this.removeClientMatterParticipant(purchaser);
      if (this.matter.isWillMatter() && purchaser.matterParticipant.matterParticipantRole === 'TESTATOR') {
        let testatorSpouse = this.matter.matterParticipants.find((mp) => mp.matterParticipantRole === 'TESTATOR_SPOUSE');
        let spouseWrapper = new MatterParticipantWrapper();
        spouseWrapper.matterParticipant = testatorSpouse;
        await this.removeClientMatterParticipant(spouseWrapper);
      }
      this.handleContactChange(purchaser, true);
    }
    this.checkCapacityWarningMessage(this.matter.purchasersCapacity);

  }

  unAssignTask(matterParticipant: MatterParticipant, matter: Matter) {
    if (matterParticipant && matter && matter.matterWorkItems) {
      let participants = matter.getChildMatterParticipants(matterParticipant);
      participants = participants.filter(item => item.isSigningOfficer);
      participants.push(matterParticipant);
      matter.matterWorkItems.forEach(item => {
        if (item.matterWorkItemTasks && item.matterWorkItemTasks.length > 0) {
          item.matterWorkItemTasks.forEach(task => {
            if (participants.find(participant => task.assignedToParticipantId == participant.matterParticipantId)) {
              task.unAssignTask();
            }
          });
        }
      });
    }
  }

  async removeClientMatterParticipant(purchaser: MatterParticipantWrapper, keepContactLocked?: boolean, removedReconciledBorrowerList?: RemovedReconciledBorrowerRelatedData[]): Promise<void> {
    if (purchaser && purchaser.matterParticipant) {
      this.unAssignTask(purchaser.matterParticipant, this.matter);
      let consentingSpouseParticipant = purchaser.matterParticipant.getMatterConsentingSpouseParticipantByParentParticipant(this.matter);
      if (consentingSpouseParticipant) {
        // For the link update, we shouldn't change lock status
        if (consentingSpouseParticipant.contact && consentingSpouseParticipant.contact.sourceContactId && !keepContactLocked) {
          this.contactService.unlockContact(consentingSpouseParticipant.contact.sourceContactId).subscribe((res) => {
            this.logger.info('consentingSpouseParticipant has been unlocked now');
          });
        }
        this.matterParticipantService.removeConsentingSpouseSigningOfficerCleanup(this.matter, consentingSpouseParticipant, keepContactLocked);
        this.matter.deleteMatterParticipant(consentingSpouseParticipant);
      }
    }

    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact && !keepContactLocked) {
      this.tabsService.unLockSourceContact(purchaser);
    }
    this.matter.matterParticipants.filter(selectedSigningOfficer => selectedSigningOfficer.parentParticipantId === purchaser.matterParticipant.matterParticipantId).forEach(matterParticipant => {
      this.tabsService.unLockSourceContactForParticipant(matterParticipant);
    });
    if (this.matter.isMatterProvinceNB) {
      this.matter.removeSpouseParticipantSpouseInfoForNB(purchaser.matterParticipant);
    }
    else {
      let currentSpouse: MatterParticipant;
      // we generate ids on the client side. If there is matterParticipant, it should have a matterParticipantId
      if (purchaser && purchaser.matterParticipant) {
        currentSpouse = this.matter.getSpouseParticipant(purchaser.matterParticipant.matterParticipantId);
      }
      if (currentSpouse) {
        currentSpouse.deleteSpouse();
      }
    }

    this.matter.matterParticipants.filter(selectedSigningOfficer => selectedSigningOfficer.parentParticipantId === purchaser.matterParticipant.matterParticipantId).forEach(matterParticipant => {
      (<any>this.matter.matterParticipants).remove(matterParticipant);
    });

    // this.matter.deleteMatterParticipantById(purchaser.matterParticipant.matterParticipantId);

    let matterParticipant: MatterParticipant = this.matter.matterParticipants.find(item => item.matterParticipantId == purchaser.matterParticipant.matterParticipantId);
    (<any>this.matter.matterParticipants).remove(matterParticipant);

    this.matter.removeReconciledBorrowersByMatterParticipant(matterParticipant, removedReconciledBorrowerList);

    let selectedClientPurchaserIndex: number = this.selectedClientPurchasers.findIndex(selectedClientPurchaser => selectedClientPurchaser === purchaser);
    if (purchaser && purchaser.matterParticipant) {
      //Clean all FLA error message
      this.matterParticipantService.removeFlaSpouseNotAllowedErr(purchaser.matterParticipant.matterParticipantId, true, this.matter.provinceCode);
      this.matterParticipantService.removeFlaSpouseNameRequiredErr(purchaser.matterParticipant.matterParticipantId, true, this.matter.provinceCode);
    }
    // this.errorService.removeDpFieldError(this.purchaserFlaSpouseNameRequiredFieldKey,
    //     this.flaSpouseNameFieldId + purchaser.matterParticipant.matterParticipantId);
    // this.errorService.removeDpFieldError(this.purchaserFlaSpouseNotAllowedFieldKey,
    //     this.flaMatterParticipantSpouseFieldId + purchaser.matterParticipant.matterParticipantId);
    const fieldId = purchaser.matterParticipant && purchaser.matterParticipant.matterParticipantId != null ? 'gender_' + purchaser.matterParticipant.matterParticipantId : 'gender';
    let fieldKeyGenderWithoutIndex: string = this.purchaserFieldPrefix + '.genderChangeProhibited';
    this.errorService.removeDpFieldError(fieldKeyGenderWithoutIndex, fieldId);

    this.selectedClientPurchasers.splice(selectedClientPurchaserIndex, 1);

    if (this.selectedClientPurchasers.length === 0) {
      this.initializedPurchaser();
      this.matter.resetMatterContactInfo();
      this.updatePostClosingAddressProvinceForSale();

    } else if (!this.primaryPurchaser && this.selectedClientPurchasers.length > 0) {
      this.setAsPrimaryPurchaser(this.selectedClientPurchasers[ 0 ], false);
    }

    this.matter.reCalculateClientReLine();
    this.updatePurchasersSharesIfNeeded();

    this.matterParticipantService.updateOtherMatterParticipantsPriorities(this.matter, purchaser.matterParticipantPriority);
    this.matterParticipantService.updateWrappersFlaErrorMessage(this.selectedClientPurchasers, this.matter.mainClientType, true, this.matter.provinceCode, this.matter);
    await this.updateAllSuggestions(true, true, this.selectedClientPurchasers && this.selectedClientPurchasers.length == 2);
    if (this.matter?.isPurchaseBC || this.matter?.isMortgageBC) {
      this.percentAcquiredChanged();
    }
  }

  showCapacityWarningForPurchaser(purchaser: MatterParticipant): boolean {
    if (this.matter.mainClients.length > 1 && this.matter && !this.matter.isCustomMatter()) {
      //There is only purchaserShare in AB, NB or NS.
      if (this.matter.isMatterProvinceABorNBorNS) {
        return purchaser && this.matter.purchasersCapacity === 'OTHER' && !purchaser.purchaserShare;
      } else if (this.matter.isMatterProvinceMBorSK) {
        return purchaser && this.matter.purchasersCapacity === 'OTHER' && (!purchaser.purchaserShare || (purchaser.purchaserShare == '0'));
      } else {
        return purchaser && this.matter.purchasersCapacity === 'OTHER' && (!purchaser.purchaserCapacity || !purchaser.purchaserShare);
      }
    } else {
      return false;
    }
  }

  isConsentedSpouseSelectedForNB(participant: MatterParticipant) {
    if (participant.hasFla() && participant.familyLawActs[ 0 ].isConsentedSpouseSelectedForNB()) {
      return true;
    }

    return false;

  }

  showIdWarningForPurchaser(purchaser: MatterParticipant): boolean {
    let consentedSpouseContact = purchaser && purchaser.consentingSpouseContact;
    return purchaser && purchaser.contact && !this.isOpportunityMatter() &&
      (!purchaser.contact.enteredOn || !purchaser.contact.idInfoEnteredBy ||
        ((!this.matter.isMatterProvinceNB || this.isConsentedSpouseSelectedForNB(purchaser))
          && purchaser.flaStatementWithConsentedSpouse && consentedSpouseContact &&
          (!consentedSpouseContact.enteredOn || !consentedSpouseContact.idInfoEnteredBy
            || !this.hasAtLeastOneSpouseIdDocumentValid(purchaser))));
  }

  isParticipantCirf(purchaser: MatterParticipant): boolean {
    return this.getParticipantCirf(purchaser) ? true : false;
  }

  public hasAtLeastOneSpouseIdDocumentValid(purchaser: MatterParticipant): boolean {
    let consentedSpouseContact = purchaser.consentingSpouseContact;
    let consentedSpouseFla: FamilyLawAct = purchaser.getConsentedSpouseFamilyLawAct();
    let result: boolean = false;
    if (consentedSpouseFla) {
      if (consentedSpouseContact && Array.isArray(consentedSpouseContact.identificationRecords) && consentedSpouseContact.identificationRecords.length > 0) {
        consentedSpouseContact.identificationRecords[ 0 ].identificationDocuments.forEach((doc) => {
          if (doc.identificationTypeCode && doc.identificationNumber && doc.nameOnId) {
            result = true;
          }
        });
      }
    }

    return result;
  }

  // // will be part of new user story
  // closePurchaser(index) {
  //   this.selectedClientPurchasers.splice(index, 1);

  //   if (this.selectedClientPurchasers.length === 0) {
  //     this.initializePurchaser();
  //   }
  // }
  // Adds new contact in the list
  addNewPurchaser(): MatterParticipantWrapper {
    let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    matterParticipantWrapper.editMode = true;
    matterParticipantWrapper.selectedTab = defaultTab;
    matterParticipantWrapper.setMatterAndCirfFields(this.matter, this.matterCirf);
    matterParticipantWrapper.showLabel = false;
    this.selectedClientPurchasers.push(matterParticipantWrapper);

    //For AB if more than one purchaser added then FLA tab should be unselected for all of them
    if (this.matter.provinceCode == 'AB' && this.selectedClientPurchasers.length > 1) {
      this.selectedClientPurchasers.filter(mp => mp.selectedTab == 'Family-Law-Act').forEach(mp => {
        mp.selectedTab = defaultTab;
      });
    }

    setTimeout(() => {
      jQuery('#editablePurchaser span input').focus();
    }, 100);

    if (this.matter?.isWillMatter()) {
      this.addSpouseClient(matterParticipantWrapper);
    }
    return matterParticipantWrapper;
  }

  /**
   * Initialize the first purchaser option
   */
  initializedPurchaser(): void {
    let firstMatterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    firstMatterParticipantWrapper.setMatterAndCirfFields(this.matter, this.matterCirf);
    firstMatterParticipantWrapper.showLabel = false;
    firstMatterParticipantWrapper.editMode = true;
    firstMatterParticipantWrapper.selectedTab = defaultTab;

    if (this.matter?.isWillMatter()) {
      firstMatterParticipantWrapper.matterParticipant = this.addWillParticipantFields(new MatterParticipant());
    }
    this.selectedClientPurchasers.push(firstMatterParticipantWrapper);
  }

  showPurchasersTitleDetails(): void {
    this.dialogService.matDialogContent({
      content: PurchasersTitleModalComponent,
      context: {purchasers: this.clients, flaStatements: this.flaStatements},

    });
  }

  /**
   * Retrieves the UI wrapper for the purchaser that's currently in edit mode
   */
  getEditedPurchaser(): MatterParticipantWrapper {
    let editedPurchaser: MatterParticipantWrapper = this.selectedClientPurchasers.find((purchaser: MatterParticipantWrapper) => {
      return purchaser.editMode;
    });
    return editedPurchaser;
  }

  cleanupMainClientField(wrapper: MatterParticipantWrapper): void {
    if (wrapper && typeof wrapper.dataModel === 'string') {
      wrapper.dataModel = undefined;
      this.clientPurchasersLoading = false;
    }
  }

  // select the contact form list of contacts
  dataSelectedClient(selectedContact, i: number): void {
    this.enableSave();
    //The selected client (from the drop-down list) would be set here through ng-model attr on the autocomplete control
    let activePurchaserWrapper: MatterParticipantWrapper = this.selectedClientPurchasers[ i ];
    if (activePurchaserWrapper.dataModel.id === undefined) {
      // Now take care for Add new client
      if (activePurchaserWrapper.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1) {
        const participant = this.matter.createMatterParticipant(this.matter.mainClientType);
        const outsideSpouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(participant, this.matter.mainClientType, this.matter);
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            jQuery('#autocomplete-client .ui-autocomplete-input').blur();
          });

        });
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            matterParticipant: participant,
            spouseNameOptions: outsideSpouseNameOptions,
            flaStatements: this.flaStatements,
            isRelatedPurchaser: true,
            isMainClient: true,
            contactType: 'CLIENT',
            contactName: activePurchaserWrapper.dataModel.displayName,
            cacheDocumentProfile: this.documentProfileCache.cachedDocumentProfile,
            matterParticipantRole: (this.matter && this.matter.isWillMatter() && i && i === 1) ? MatterParticipantRoleTypes.TESTATOR_SPOUSE : this.matter.mainClientType
          },
          onFulfillment: async (result) => {
            if (result) {
              if (result.action === 'save') {
                if (this.needConfirmCurrentPrimaryAddressForSaleOrMortgage(result.contact)) {
                  this.confirmCurrentPrimaryAddressForSaleOrMortgage(result.contact.primaryAddress);
                }
                // wait finish contact and contactAssociations setup, the following code can work well
                activePurchaserWrapper.matterParticipant = await this.updateOnPurchaserClientCreated(activePurchaserWrapper, result.contact, participant, false, false);
                this.updateTenureDefaultValue(activePurchaserWrapper.matterParticipant);
                activePurchaserWrapper.setMatterAndCirfFields(this.matter, this.matterCirf);
                activePurchaserWrapper.showLabel = true;
                activePurchaserWrapper.editMode = false;
                this.updateAllSuggestions(false, true, this.selectedClientPurchasers && this.selectedClientPurchasers.length == 2);
                this.selectTab(defaultTab, activePurchaserWrapper);
                if (this.needUpdateContactInfoEmail(activePurchaserWrapper.matterParticipant)) {
                  this.updateMatterContactInfoEmail(result.contact);
                }
              }
              this.matterParticipantService.updateWrappersFlaErrorMessage(this.selectedClientPurchasers,
                this.matter.mainClientType, true, this.matter.provinceCode, this.matter);
              this.checkCapacityWarningMessage(this.matter.purchasersCapacity);
            }
          },
          fullScreen: true,

        });
        activePurchaserWrapper.dataModel = '';
      } else {
        // Case No records found and help text
        activePurchaserWrapper.dataModel = undefined;
      }
    } else {
      this.selectExistingContact(activePurchaserWrapper, i);
    }
  }

  //Revert to reducing get source contact
  async selectExistingContact(activePurchaserWrapper: MatterParticipantWrapper, i: number, participantPriority?: number, participantIndex?: number) {
    activePurchaserWrapper.editMode = false;
    let contact: Contact = await this.contactQueryService.getContactForMatter(activePurchaserWrapper.dataModel.id).toPromise();
    await this.createMatterParticipantForClient(contact, activePurchaserWrapper, i, null, null, null, participantPriority, participantIndex);
    if (this.matter.isFlaApplicableForEstate(activePurchaserWrapper.matterParticipant)) {
      activePurchaserWrapper.matterParticipant.setGendeEstateOtherFlaDefaultValue();
    }
    if (this.matter.isMatterProvinceONorABorMBorSKorNSorNBorBC || this.matter.isCustomMatter()) {
      this.openSuggestedSpouseModal(activePurchaserWrapper);
    }
  }

  needUpdateContactInfoEmail(matterParticipant: MatterParticipant): boolean {
    return this.matter.matterContactInfo.emailSameAsPrimaryContact && matterParticipant && matterParticipant.primary;
  }

  openSuggestedSpouseModal(activePurchaserWrapper: MatterParticipantWrapper) {
    if (!this.matter || (this.matter.isWillMatter() && !this.showAddSpouseButton(activePurchaserWrapper.matterParticipant)) || this.matter.isOpportunityMatterAndTypeWill()) {
      return;
    }
    if (activePurchaserWrapper?.matterParticipant?.contact?.spouses?.length > 0) {
      this.dialogService.matDialogContent({
        content: SuggestedSpouseModal,
        context: {
          spouses: activePurchaserWrapper.matterParticipant.contact.spouses,
          contactLabel: this.matter.mainClientTitle,
          provinceCode: this.matter.provinceCode,
          flaStatements: this.flaStatements,
          isCustomMatter: this.matter.isCustomMatter(),
          isOpportunityMatter: this.isOpportunityMatter(),
          disableAddClient: this.selectedClientPurchasers.length >= this.appConfig.getMaxNumberOfPurchasers(),
          isWillMatter: this.matter.isWillMatter()
        },
        showModalBehind:true,
        onFulfillment: async (result) => {
          if (result && result.selectedSpouse && result.selectedSpouse.spouse) {
            let spouseSourceContact: Contact = await this.contactQueryService.getContactForMatter(result.selectedSpouse.spouse.id).toPromise();
            if (result.selectedSpouse.familyLawActStatementType) {
              activePurchaserWrapper.addSuggestedSpouseAsFlaConsentedSpouse(spouseSourceContact, result.selectedSpouse.familyLawActStatementType, this.matter, result.selectedSpouse.spouseType);
            } else {
              this.addSuggestedSpouseAsClient(activePurchaserWrapper, spouseSourceContact, result.selectedSpouse.spouseType);
            }
          }
        },

      });
    }
  }

  async createMatterParticipantForClient(contact: Contact, activePurchaserWrapper: MatterParticipantWrapper, i: number,
                                         hideConfirmCurrentPrimaryAddressDialog?: boolean, isMassUpdate?: boolean, excludeCapacityCheckWarning?: boolean,
                                         participantPriority?: number, participantIndex?: number): Promise<void> {
    if (this.needConfirmCurrentPrimaryAddressForSaleOrMortgage(contact) && !hideConfirmCurrentPrimaryAddressDialog) {
      this.confirmCurrentPrimaryAddressForSaleOrMortgage(contact.primaryAddress);
    }
    this.updateAddressInProjectSaleMatter(contact.primaryAddress);
    if (!activePurchaserWrapper.dataModel) {
      activePurchaserWrapper.dataModel = {};
    }
    activePurchaserWrapper.dataModel.fullName = contact.genericFullName;

    activePurchaserWrapper.matterParticipant = this.createNewMatterPurchaserContact(contact, (this.matter?.isWillMatter() && this.isWillClientInfo) ? activePurchaserWrapper.matterParticipant : null, true, isMassUpdate, participantPriority, participantIndex);

    this.updateTenureDefaultValue(activePurchaserWrapper.matterParticipant);
    activePurchaserWrapper.setMatterAndCirfFields(this.matter, this.matterCirf);
    activePurchaserWrapper.showLabel = true;
    //  create check for primary contact on 0th index
    // for current user story it will change in future
    if (i === 0) {
      activePurchaserWrapper.primary = true;
      this.updateMatterFieldsFromPrimaryContact(contact, true);
    }
    this.selectTab(defaultTab, activePurchaserWrapper);
    if (contact.contactAssociations) {
      // wait finish contact and contactAssociations setup, the following code can work well
      await this.matterParticipantService.createMatterParticipantAssociatedContactForClient(contact, activePurchaserWrapper.matterParticipant, this.matter).then(() => {
        if (!isMassUpdate && !this.showWizardFields) {
          this.toggleContactSnapshot(activePurchaserWrapper);
        }
      });
    } else if (!isMassUpdate && !this.showWizardFields) {
      this.toggleContactSnapshot(activePurchaserWrapper);
    }
    this.matterParticipantService.updateWrappersFlaErrorMessage(this.selectedClientPurchasers,
      this.matter.mainClientType, true, this.matter.provinceCode, this.matter);
    this.matter.reCalculateClientReLine();
    // For copy link matter,  it should not add main client Tenure waring message into this tab and should add the link active tab.
    if (!excludeCapacityCheckWarning) {
      this.checkCapacityWarningMessage(this.matter.purchasersCapacity);
    }
    this.updateAllSuggestions(false, true, this.selectedClientPurchasers && this.selectedClientPurchasers.length == 2);
    if (this.needUpdateContactInfoEmail(activePurchaserWrapper.matterParticipant)) {
      this.updateMatterContactInfoEmail(contact);
    }
  }

  updateMatterContactInfoEmail(contact: Contact): void {
    if (contact) {
      this.matter.matterContactInfo.email = contact.email;
    }
  }

  updateTenureDefaultValue(matterParticipant: MatterParticipant) {
    if (this.matter.isMatterProvinceMBorSK && this.matter.purchasersCapacity == 'OTHER') {
      if (!matterParticipant.purchaserShare) {
        matterParticipant.purchaserShare = 0 + '';
      }
    }
  }

  // this method is called from
  // child(add client  contact pop up) after creating one new contact.
  async updateOnPurchaserClientCreated(activePurchaserWrapper: MatterParticipantWrapper, contact: Contact, outsideParticipant: MatterParticipant, isContactSelected: boolean, isSpouse: boolean): Promise<MatterParticipant> {
    let newMatterParticipant = this.createNewMatterPurchaserContact(contact, outsideParticipant, isContactSelected);
    if (this.matter?.isWillMatter() && isSpouse) {
      newMatterParticipant.matterParticipantRole = MatterParticipantRoleTypes.TESTATOR_SPOUSE;
    }
    // wait finish contact and contactAssociations setup, the following code can work well
    await this.matterParticipantService.createMatterParticipantAssociatedContactForClient(contact, newMatterParticipant, this.matter);
    this.matterPurchasersCapacityOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(this.clients.length, this.matter.provinceCode);
    if (isSpouse) {
      const matterParticipantWrapper: MatterParticipantWrapper
        = this.createWrapperFromMatterParticipant(newMatterParticipant);

      matterParticipantWrapper.setMatterAndCirfFields(this.matter, this.matterCirf);
      matterParticipantWrapper.showLabel = true;
      this.selectedClientPurchasers.push(matterParticipantWrapper);
      this.matterParticipantService.updateParticipantWrapperState(matterParticipantWrapper, contact);
    } else {
      this.matterParticipantService.updateParticipantWrapperState(activePurchaserWrapper, contact);
    }
    this.matter.reCalculateClientReLine();

    this.enableSave();
    return newMatterParticipant;
  }

  // Add the function for setting familyLawActVisible
  async toggleContactSnapshot(participantWrapper: MatterParticipantWrapper): Promise<void> {
    if ('TESTATOR_SPOUSE' === participantWrapper?.matterParticipant?.matterParticipantRole) {
      if (participantWrapper.matterParticipant.contact.id) {
        participantWrapper.matterParticipant.contact = new Contact(await this.contactQueryService.getContact(participantWrapper.matterParticipant.contact.id).toPromise());
      }
    }
    this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, participantWrapper);

    if (participantWrapper && participantWrapper.matterParticipant) {
      // build spouseNameOptions
      participantWrapper.spouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(participantWrapper.matterParticipant, this.matter.mainClientType, this.matter);
    }
  }

  flaFlagVisible(matterParticipant: MatterParticipant): boolean {
    return this.matter && !this.matter.isCustomMatter() && !this.matter.isWillMatter()
      && PurchaserFamilyLawAct.isFamilyLawActVisibleForParticipant(matterParticipant, false, this.matter.provinceCode, this.matter.mainClients.length, this.matter.matterType);
  }

  getAllFamilyLawActAlerts(matterParticipant: MatterParticipant, matter: Matter): string[] {
    return PurchaserFamilyLawAct.getFamilyLawActAlerts(matterParticipant, this.matter.mainClientType, matter, this.matter.provinceCode);
  }

  setAsPrimaryPurchaser(purchaser: MatterParticipantWrapper, updateAllSuggestions: boolean = true): void {
    if (!purchaser.primary) {

      if (purchaser.matterParticipant && purchaser.matterParticipant.contact) {
        this.updatePreClosingFromPrimaryPurchaserPrimaryAddress(purchaser.matterParticipant.contact.primaryAddress);
      }

      if (this.primaryPurchaser) {
        let currentPrimaryPurchaser: MatterParticipantWrapper = this.primaryPurchaser;
        currentPrimaryPurchaser.primary = false;
      }
      purchaser.primary = true;

      this.updateMatterFieldsFromPrimaryContact(purchaser.matterParticipant.contact, false, updateAllSuggestions);

      this.enableSave();
      if (updateAllSuggestions) {
        this.updateAllSuggestions();
      }
    }
  }

  updatePostClosingAddressProvinceForSale() {
    if (this.matter.isSale && this.postClosingAddressFormComponent
      && (this.matter.matterContactInfo.residingAtSubjectProperty == DpBooleanValueTypes.NO)) {
      //AddressFormComponent uses [(ngModel)]="localProvinceName", we should update ngModel if the address was update.
      this.postClosingAddressFormComponent.updateLocalProvinceName();
    }
  }

  confirmCurrentPrimaryAddressForSaleOrMortgage(address: Address): void {
    this.matter.matterContactInfo.residingAtSubjectProperty = 'Y_n';
    // let address : Address = saler && saler.matterParticipant.contact.primaryAddress;
    let addressDetail = address.addressLine1 ? address.addressLine1 + '<br>' : '';
    addressDetail += address.addressLine2 ? address.addressLine2 + '<br>' : '';
    addressDetail += address.city ? address.city : '';
    addressDetail += address.provinceName ? (address.city ? ', ' : '') + address.provinceName + '<br>' : (address.city ? '<br>' : '');
    addressDetail += address.country ? address.country : '';
    addressDetail += address.postalCode ? (address.country ? ', ' : '') + address.postalCode + '<br>' : (address.country ? '<br>' : '');

    this.dialogService.confirm('Confirmation', 'The current default address recorded for this ' + SharedUtils.convertStringToTitleCase(this.localizedTopicName) + ' is:<br><br><br>' +
      addressDetail +
      '<br><br>Is this also the address of the subject property?', false, 'Yes', 'No').subscribe(res => {

      if (res == true) {
        this.matter.matterContactInfo.residingAtSubjectProperty = 'YES';
        this.matter.matterPropertyWithCondo.address.update(address);
      } else {
        this.matter.matterContactInfo.residingAtSubjectProperty = 'NO';
        this.matter.matterContactInfo.postClosingAddress.update(address);
      }
      this.updatePostClosingAddressProvinceForSale();

    });
  }

  updateAddressInProjectSaleMatter(address: Address) {
    if (this.matter.isProjectSale) {
      this.matter.matterContactInfo.residingAtSubjectProperty = 'NO';
      this.matter.matterContactInfo.postClosingAddress.update(address);
    }
  }

  needConfirmCurrentPrimaryAddressForSaleOrMortgage(contact: Contact): boolean {
    let result = false;
    if (!this.matter.isProjectSale && (this.isSaleMatter || this.isMortgageMatter) && this.isVeryFirstClient && contact.primaryAddress && !contact.primaryAddress.isEmpty && this.isSubjectPropertyAddressEmpty) {
      result = true;
    }
    return result;
  }

  get isVeryFirstClient(): boolean {
    return this.selectedClientPurchasers && this.selectedClientPurchasers.length == 1;
  }

  get isSaleMatter(): boolean {
    return this.matter.isSale;
  }

  get isMortgageMatter(): boolean {
    return this.matter.isMortgage;
  }

  get isSubjectPropertyAddressEmpty(): boolean {
    if (this.matter.matterProperties && this.matter.matterPropertyWithCondo) {
      return this.matter.matterPropertyWithCondo.address && this.matter.matterPropertyWithCondo.address.isEmpty;
    }
    return true;
  }

  get primaryPurchaser(): MatterParticipantWrapper {
    return this.selectedClientPurchasers.find((purchaserEntry: MatterParticipantWrapper) => {
      return purchaserEntry.primary;
    });
  }

  get primaryContact(): any {
    let pPurchaser: MatterParticipantWrapper = this.primaryPurchaser;
    return pPurchaser && pPurchaser.matterParticipant && pPurchaser.matterParticipant.contact;
  }

  get address(): Address {
    return this.matter.matterContactInfo.preClosingAddress;
  }

  get postAddress(): Address {
    return this.matter.matterContactInfo.postClosingAddress;
  }

  mainClientTitle(index: number): string {
    if (this.matter?.isWillMatter()) {
      switch (index) {
        case 0:
          return 'Testator';
        case 1:
          return 'Spouse';
        default:
          return '';
      }
    }
    return this.matter.mainClientTitle + ' ' + (index + 1);
  }

  willClientInfoTitle(index: number): string {
    switch (index) {
      case 0:
        return 'My Client';
      case 1:
        return 'Spouse';
      default:
        return '';
    }
  }

  showPostAddress(): boolean {
    return (this.matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode === ''
      || this.matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode === null);
  }

  showClientPurchaserHelpText(purchaser: MatterParticipantWrapper) {
    if (!purchaser.dataModel) {
      this.searchTermStreamClient.next('');
    }
  }

  // This will act on Update Matter with Contact Data button click on dialog
  public updateSnapshot(selectedPurchaser: MatterParticipantWrapper): void {
    this.matterParticipantService.updateMatterContactFromSourceContact(selectedPurchaser, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.purchaserCallbackOnUpdateSourceContact, this.matter);
    this.handleContactChange(selectedPurchaser, false, true);
  }

  purchaserCallbackOnUpdateSourceContact = (result: boolean, matterParticipantWrapper: MatterParticipantWrapper, oldSnapshotReference: Contact, sourceContact: Contact) => {
    if (result) {
      this.enableSave();
      matterParticipantWrapper.matterParticipant.contact.idInfoEnteredBy = null;
      matterParticipantWrapper.matterParticipant.contact.contactIdInfoEnteredBy = null;
      matterParticipantWrapper.matterParticipant.contact.enteredOn = null;
      let contactNameChanged: boolean = sourceContact.reLineName != matterParticipantWrapper.matterParticipant.contact.reLineName;
      const primaryContact: boolean = matterParticipantWrapper.matterParticipant.primary;
      this.statusBarService.currentHelpText = StatusBarMessages.contact.UPDATE;
      if (contactNameChanged) {
        this.matter.reCalculateClientReLine();
      } else {
        this.matter.reCalculateTrustLedgerPurchaserLine();
      }
      if (primaryContact) {
        this.updateMatterFieldsFromPrimaryContact(sourceContact, false);
      }
      this.matterPurchasersCapacityOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(this.clients.length, this.matter.provinceCode);
      matterParticipantWrapper.spouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(matterParticipantWrapper.matterParticipant, this.matter.mainClientType, this.matter);

    }
  };

  public getMatterParticipantByContactId(contactId: number): MatterParticipant {

    let participant: MatterParticipant = this.matter.matterParticipants.find((mp: MatterParticipant) => {
      return mp.contact.id === contactId;
    });

    return participant;
  }

  isExpanded(purchaser: MatterParticipantWrapper): boolean {
    return purchaser.expanded;
  }

  //Test the gender value
  isCorpAndOtherEntity(purchaser: MatterParticipantWrapper): boolean {
    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact && purchaser.matterParticipant.contact.gender) {
      switch (purchaser.matterParticipant.contact.gender) {
        case 'CORPORATION':
        case 'OTHERENTITY':
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  isEstate(purchaser: MatterParticipantWrapper): boolean {
    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact && purchaser.matterParticipant.contact.gender) {
      return purchaser.matterParticipant.contact.gender === 'ESTATE';
    } else {
      return false;
    }
  }

  isGenderValuesMaleFemaleBlankPoa(purchaser: MatterParticipantWrapper): boolean {
    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact && purchaser.matterParticipant.contact.gender) {
      switch (purchaser.matterParticipant.contact.gender) {
        case 'MALE':
        case 'FEMALE':
        case 'MALEPOA':
        case 'FEMALEPOA':
        case 'QUESTION':
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  isGenderPoaOnly(purchaser: MatterParticipantWrapper): boolean {
    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact && purchaser.matterParticipant.contact.gender) {
      return purchaser.matterParticipant.contact.gender === 'MALEPOA' || purchaser.matterParticipant.contact.gender === 'FEMALEPOA';
    } else {
      return false;
    }
  }

  getParticipant(matterParticipantId: number): MatterParticipant {
    let matterParticipant: MatterParticipant = _.find(this.matter.matterParticipants, (mp: MatterParticipant) => {
      return mp.matterParticipantId === matterParticipantId;
    });
    return matterParticipant;
  }

  //TODO It is better to refactor this method with common MatterParticipant method
  createNewMatterPurchaserContact(contact: Contact, outsideParticipant: MatterParticipant, isContactSelected: boolean, isMassUpdate?: boolean,
                                  matterParticipantPriority?: number, participantIndex?: number): MatterParticipant {
    let person: any = {};
    let participant: MatterParticipant = null;

    //Creating snapshot from source contact to be added into matter participant
    let snapshot: Contact = new Contact();
    snapshot.createNewContactClone(contact);
    snapshot.snapshotFlag = true;
    if (isMassUpdate) {
      snapshot.sourceContactId = contact.sourceContactId;
    } else {
      snapshot.sourceContactId = contact.id;
    }

    person.contact = snapshot;
    person.matterParticipantRole = this.matter.mainClientType;
    if (matterParticipantPriority >= 0) {
      person.matterParticipantPriority = matterParticipantPriority;
      let higherPriorityMatterParticipants = this.matter.mainClients.filter(mp => mp.matterParticipantPriority >= matterParticipantPriority);
      if (higherPriorityMatterParticipants && higherPriorityMatterParticipants.length) {
        higherPriorityMatterParticipants.forEach((mp) => {
          mp.matterParticipantPriority++;
        });
      }
    } else {
      person.matterParticipantPriority = this.getMaxMatterParticipantPriority() + 1;
    }

    if (!this.matter.matterParticipants) {
      this.matter.matterParticipants = [];
    }

    if (this.clients.length === 0) {
      person.primary = true;
      this.updateMatterFieldsFromPrimaryContact(contact, true);
      this.updatePreClosingFromPrimaryPurchaserPrimaryAddress(contact.primaryAddress);
    }

    participant = new MatterParticipant(person);
    if (this.matter?.isWillMatter()) {
      this.addWillParticipantFields(participant);
    }
    if (outsideParticipant) {
      participant.matterParticipantId = outsideParticipant.matterParticipantId;
      participant.familyLawActs = outsideParticipant.familyLawActs;
      participant.purchaserCapacity = outsideParticipant.purchaserCapacity;
      participant.purchaserShare = outsideParticipant.purchaserShare;
    }

    // DPPMP-12342
    // Purchaser - ID Flag not clearing when add new contact
    // Purchaser - ID Flag clearing when select an existing contact
    if (isContactSelected) {
      participant.contact.idInfoEnteredBy = null;
      participant.contact.contactIdInfoEnteredBy = null;
      participant.contact.enteredOn = null;
    }
    participant.contact.lastSyncedFromSource = contact.lastUpdatedTimeStamp;
    if (participantIndex >= 0 && participantIndex < this.matter.matterParticipants.length) {
      this.matter.matterParticipants.splice(participantIndex, 0, participant);
    } else {
      this.matter.matterParticipants.push(participant);
    }
    this.setPurchaserCapacityAndShare(participant, this.matter.purchasersCapacity);
    this.updatePurchasersSharesIfNeeded();
    participant.sourceContact = contact;
    this.purchaserSaveModel.push(participant);

    return participant; //TODO: type the instance earlier, once the id property is clarified
  }

  isClientGenders(vGender: string): boolean {
    if (vGender) {
      return vGender in {
        'QUESTION': 0,
        'MALE': 1,
        'FEMALE': 2,
        'MALEPOA': 3,
        'FEMALEPOA': 4,
        'ESTATE': 5,
        'CORPORATION': 6,
        'OTHERENTITY': 7
      };
    } else {
      return false;
    }
  }

  public getMaxMatterParticipantPriority(): number {
    var max: number = 0;
    for (let i = 0; i < this.selectedClientPurchasers.length; i++) {
      let purchaser: MatterParticipantWrapper = this.selectedClientPurchasers[ i ];
      if (!purchaser.matterParticipant) {
        continue;
      }
      if (!purchaser.matterParticipantPriority) {
        continue;
      }
      if (purchaser.matterParticipantPriority > max) {
        max = purchaser.matterParticipantPriority;
      }
    }
    return max;
  }

  // this method will enable save after change in any filed.

  enableSave(): void {
    if (this.matter?.isMatterProvinceBC) {
      this.pttService.updatePropertyTransferTax(this.matter);
    }
    this.matter.dirty = !this.tabsService.isLinkedMatterDirty();
  }

  updateFamilyLawActFormSave(): void {
    this.enableSave();
    this.updateAllSuggestions();
  }

  residingAtSubjectPropertyChange(): void {
    this.matter.updateMatterOnIsResidingFlagChange();
    if (this.postClosingAddressFormComponent) {
      this.postClosingAddressFormComponent.setDropdown();
    }
    this.updatePostClosingAddressProvinceForSale();
    this.enableSave();
  }

  // autocomplete
  // PURCHASER
  // Omnibar methods to get contacts
  search(event): void {
    let entered: string = event.query;
    if (entered) {
      this.searchTermStreamClient.next(entered);
    }
  }

  getClient(): Contact {
    return this.individualClient;
  }

  moveClientUp(selectedPurchaser: MatterParticipantWrapper): void {
    console.log('moveClientUp', selectedPurchaser);
    this.reorderPurchaser(selectedPurchaser, true);
  }

  moveClientDown(selectedPurchaser: MatterParticipantWrapper): void {
    console.log('moveClientDown', selectedPurchaser);
    this.reorderPurchaser(selectedPurchaser, false);
  }

  /**
   * This method re-arranges the order of purchasers.
   * The selected purchaser is moved up or down in the list based on moveUp flag
   * @param selectedPurchaser
   * @param moveUp
   */
  public reorderPurchaser(selectedPurchaser: MatterParticipantWrapper, moveUp: boolean): void {
    if (this.selectedClientPurchasers && this.selectedClientPurchasers.length > 1) {
      //Closing the shutter box if opened
      this.closeAllOpenedPurchaserShutters();

      let selectedPurchaserIndex: number = _.findIndex(this.selectedClientPurchasers, function (p: MatterParticipantWrapper) {
        return p === selectedPurchaser;
      });

      let otherPurchaserIndex: number;

      //Calculating the index of other purchaser in relative to selected index based on moving direction
      if (moveUp) {
        otherPurchaserIndex = selectedPurchaserIndex - 1;
      } else {
        otherPurchaserIndex = selectedPurchaserIndex + 1;
      }

      if (otherPurchaserIndex >= 0 && otherPurchaserIndex < this.selectedClientPurchasers.length) {
        let otherPurchaser: MatterParticipantWrapper = this.selectedClientPurchasers[ otherPurchaserIndex ];

        //Swapping selected and other purchasers in the array that is displayed on UI
        this.selectedClientPurchasers[ selectedPurchaserIndex ] = this.selectedClientPurchasers[ otherPurchaserIndex ];
        this.selectedClientPurchasers[ otherPurchaserIndex ] = selectedPurchaser;

        //Swapping selected and other purchaser's priority which is saved in database
        let selectedPurchaserPriority: number = selectedPurchaser.matterParticipantPriority;
        selectedPurchaser.matterParticipantPriority = otherPurchaser.matterParticipantPriority;
        otherPurchaser.matterParticipantPriority = selectedPurchaserPriority;

        //Recalculating client re line based on new order
        this.matter.reCalculateClientReLine();
        //Enabling global save button
        this.enableSave();
        this.updateAllSuggestions(false, true);
      } else {
        console.error('The index of other purchaser is out of bounds.', otherPurchaserIndex);
      }
    }
  }

  closeAllOpenedPurchaserShutters(): void {
    if (this.selectedClientPurchasers) {
      this.selectedClientPurchasers.forEach((purchaser: MatterParticipantWrapper) => {
        purchaser.expanded = false;
        this.matterParticipantService.unlockParticipantOnTabClose(purchaser, this.matter);
      });
    }
  }

  get directDepositOptions() {
    return dropDowns.directDepositOptions;
  }

  handleDirectDepositOption(ddOption: string) {
    if (ddOption == 'YES') {
      this.matter.directDepositInstructionPurchaser.bankAccount = new BankAccount();
    } else {
      this.errorService.removeDpFieldError(`directDeposit.bankNumber`);
      this.errorService.removeDpFieldError(`directDeposit.transitNumber`);
    }

    this.enableSave();
  }

  emailChange(value: string): void {
    this.enableSave();
    if (value) {
      this.setPrimaryContactEmail();
    }

    this.validateEmail();
  }

  validateEmail() {
    if (this.matter.matterContactInfo.emailSameAsPrimaryContact || !this.matter.matterContactInfo.email || ErrorValidator.areEmailAddressesValid(this.matter.matterContactInfo.email, true)) {
      this.errorService.removeDpFieldError('matter.mainClient.matterContactInfo.email');
    } else {
      this.errorService.addDpFieldError(DPError.createDPError('matter.mainClient.matterContactInfo.email'));
    }
  }

  public setPrimaryContactEmail(): void {
    if (!this.selectedClientPurchasers || this.selectedClientPurchasers.length === 0) {
      return;
    }

    let purchaser: MatterParticipantWrapper =
      _.find(this.selectedClientPurchasers, (item: MatterParticipantWrapper) => {
        return item.primary;
      });

    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact) {
      this.matter.matterContactInfo.email = purchaser.matterParticipant.contact.email;
    }
  }

  cleanUpMatter(): boolean {
    MatterCleanUpUtil.cleanUpBankAccount(this.matter);
    return true;
  }

  getDefaultQuery(type: string): any {
    return {query: type};
  }

  addSpouseClient(originalPurchaserWrapper: MatterParticipantWrapper): void {
    if (originalPurchaserWrapper && originalPurchaserWrapper.matterParticipant && originalPurchaserWrapper.matterParticipant.contact) {
      let originalPurchaserFla: FamilyLawAct;

      let spouseParticipant = new MatterParticipant();
      spouseParticipant.contact = new Contact();
      spouseParticipant.contact.initializeContactIdDetails();
      originalPurchaserWrapper.matterParticipant.contact.updateSpouseContactWithReferenceContact(spouseParticipant.contact);
      if (this.matter.isMatterProvinceNB) {
        if (!originalPurchaserWrapper.matterParticipant.hasFla()) {
          originalPurchaserWrapper.matterParticipant.initFlaForNB();
        }
        originalPurchaserFla = new FamilyLawAct(originalPurchaserWrapper.matterParticipant.familyLawActs[ 0 ]);
        originalPurchaserWrapper.setSpouseDefaultValuesForNB(spouseParticipant);
      } else {
        originalPurchaserWrapper.setSpouseDefaultValues(spouseParticipant);
      }
      if (this.matter?.isWillMatter()) {
        this.addWillParticipantFields(spouseParticipant);
        spouseParticipant.matterParticipantRole = MatterParticipantRoleTypes.TESTATOR_SPOUSE;
      }
      const outsideSpouseNameOptions = PurchaserFamilyLawAct.buildSpouseNameOptions(spouseParticipant, this.matter.mainClientType, this.matter);
      this.dialogService.matDialogContent({
        content: CommonContactDialogComponent,
        context: {
          matter: this.matter,
          matterParticipant: spouseParticipant,
          flaStatements: this.flaStatements,
          spouseNameOptions: outsideSpouseNameOptions,
          referencePurchaser: originalPurchaserWrapper.matterParticipant.contact,
          isRelatedPurchaser: true,
          contactType: 'CLIENT',
          isMainClient: true,
          cacheDocumentProfile: this.documentProfileCache.cachedDocumentProfile,
          showRelationshipGenders: true,
          matterParticipantRole: this.matter?.isWillMatter() ? MatterParticipantRoleTypes.TESTATOR_SPOUSE : originalPurchaserWrapper.matterParticipant.matterParticipantRole
        },
        onFulfillment: async (result) => {
          if (result) {
            if (result.action === 'save') {
              // wait finish contact and contactAssociations setup, the following code can work well
              let newMatterParticipant: MatterParticipant = await this.updateOnPurchaserClientCreated(originalPurchaserWrapper, result.contact, spouseParticipant, false, true);
              // Although there is not FLA section in AB UI when there  are two other side clients, it still can build
              // spouse relationship in fla
              if (this.matter.isMatterProvinceAB) {
                originalPurchaserWrapper.matterParticipant.addSpouseForAB(newMatterParticipant);
              } else if (this.matter.isMatterProvinceNB) {
                //If original matterParticipant is not selected as spouse, it needs to put back the old FLA data.
                //Need to confirm with BA
                if (originalPurchaserWrapper.matterParticipant.hasFla()
                  && originalPurchaserWrapper.matterParticipant.familyLawActs[ 0 ].spouseMatterParticipantId != spouseParticipant.matterParticipantId) {
                  originalPurchaserWrapper.matterParticipant.familyLawActs[ 0 ].copy(originalPurchaserFla);
                }
              } else {
                if (spouseParticipant.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE')) {
                  if (Array.isArray(originalPurchaserWrapper.matterParticipant.familyLawActs)) {
                    originalPurchaserWrapper.matterParticipant.familyLawActs.forEach((fla: FamilyLawAct) => {
                      if (fla.familyLawActStatementType !== 'OTHER' && fla.familyLawActStatementType !== 'MATTER_PARTICIPANT_SPOUSE') {
                        originalPurchaserWrapper.matterParticipant.deleteFamilyLawAct(fla.familyLawActStatementType);
                      }
                    });
                  } else {
                    originalPurchaserWrapper.matterParticipant.addFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
                  }
                }
              }
              this.updateAllSuggestions(false, true, this.selectedClientPurchasers && this.selectedClientPurchasers.length == 2);
            } else { // Click cancel button
              if (this.matter.isMatterProvinceNB) {
                //If it is cancel, it needs to put back the old FLA data and clean up Spouse Participant Spouse Info
                if (spouseParticipant.hasFla() && spouseParticipant.familyLawActs[ 0 ].spouseMatterParticipantId) {
                  this.matter.removeSpouseParticipantSpouseInfoForNB(spouseParticipant);
                }
                originalPurchaserWrapper.matterParticipant.familyLawActs[ 0 ].copy(originalPurchaserFla);
              } else {
                //Fix regression bug (can't saving matter), remove spouse fla of Spouse Participant
                if (spouseParticipant.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE') && spouseParticipant.spouseParticipantId) {
                  const newSpouse: MatterParticipant
                    = this.matter.getMatterParticipantByFullNameExcludingSelected(spouseParticipant.spouseParticipantId, spouseParticipant);
                  if (newSpouse) {
                    newSpouse.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
                  }
                }
                originalPurchaserWrapper.matterParticipant.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
              }
            }

            this.matterParticipantService.updateWrappersFlaErrorMessage(this.selectedClientPurchasers,
              this.matter.mainClientType, true, this.matter.provinceCode, this.matter);
            this.checkCapacityWarningMessage(this.matter.purchasersCapacity);
          }
        },
        fullScreen: true,

      });
    }
  }

  private addSpouseFlaParticipantForNB(matterParticipant: MatterParticipant, spouseType: SpouseType, spouseParticipantId: number) {
    matterParticipant.familyLawActs = [];
    matterParticipant.familyLawActs.push(new FamilyLawAct());
    matterParticipant.familyLawActs[ 0 ].familyLawActStatementType = FlaStatementType.MARITAL_STATUS_NB;
    if (spouseType == SpouseTypes.AS_A_PURCHASER) {
      matterParticipant.familyLawActs[ 0 ].maritalStatus = MaritalStatusTypes.MARRIED;
    } else if (spouseType == SpouseTypes.AS_A_FORMER_PURCHASER) {
      matterParticipant.familyLawActs[ 0 ].maritalStatus = MaritalStatusTypes.NOT_MARRIED;
    }
    matterParticipant.familyLawActs[ 0 ].propertyOccupiedAsMaritalHome = DpBooleanValueTypes.YES;
    matterParticipant.familyLawActs[ 0 ].applicableProvision = ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY;
    matterParticipant.familyLawActs[ 0 ].spouseMatterParticipantId = spouseParticipantId;
  }

  async addSuggestedSpouseAsClient(originalPurchaserWrapper: MatterParticipantWrapper, suggestedSpouse: Contact, spouseType: SpouseType): Promise<void> {
    const spouseParticipant = new MatterParticipant();
    spouseParticipant.contact = new Contact();

    // AB only uses existing spouse relationship and can't create spouse relationship
    if (!this.matter.isMatterProvinceAB && !this.matter.isCustomMatter()) {
      if (this.matter.isMatterProvinceNB) {
        //For original matterParticipant
        this.addSpouseFlaParticipantForNB(originalPurchaserWrapper.matterParticipant, spouseType, spouseParticipant.matterParticipantId);
        //For new spouse matterParticipant
        this.addSpouseFlaParticipantForNB(spouseParticipant, spouseType, originalPurchaserWrapper.matterParticipant.matterParticipantId);
      } else {
        originalPurchaserWrapper.matterParticipant.addFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
        originalPurchaserWrapper.matterParticipant.setFlaDefaultValuesForSuggestedSpouse(spouseParticipant);
      }
    }
    PurchaserFamilyLawAct.buildSpouseNameOptions(spouseParticipant, this.matter.mainClientType, this.matter);
    // originalPurchaserWrapper.matterParticipant.contact.updateSpouseContactWithReferenceContact(participant.contact);
    // wait finish contact and contactAssociations setup, the following code can work well
    const spouseMatterParticipant: MatterParticipant = await this.updateOnPurchaserClientCreated(originalPurchaserWrapper, suggestedSpouse, spouseParticipant, true, true);
    const spouseWrapper: MatterParticipantWrapper = this.selectedClientPurchasers.find(item => item.matterParticipant == spouseMatterParticipant);
    if (spouseWrapper) {
      this.selectTab(defaultTab, spouseWrapper);
      if (!this.showWizardFields) {//Not to open the Tab shutter if it called from Wizard
        this.toggleContactSnapshot(spouseWrapper);
      }
    }

    this.updateAllSuggestions(false, true, this.selectedClientPurchasers && this.selectedClientPurchasers.length == 2);
    this.matterParticipantService.updateWrappersFlaErrorMessage(this.selectedClientPurchasers,
      this.matter.mainClientType, true, this.matter.provinceCode, this.matter);
    this.checkCapacityWarningMessage(this.matter.purchasersCapacity);
  }

  showAddSpouseButton(participant: MatterParticipant): boolean {
    if (participant && this.matter) {
      if (this.matter?.isOpportunityMatterAndTypeWill()) {
        return false;
      }
      return participant.onShowAddSpouseButton(this.selectedClientPurchasers && this.selectedClientPurchasers.length,
        this.matter.isWillMatter() ? this.appConfig.getMaxNumberOfTestatorsForWill() : this.appConfig.getMaxNumberOfPurchasers(), this.matter.provinceCode);
    }
    return false;
  }

  onClickPrintIdForms() {

    if (this.matter.dirty) {
      this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
        if (res == true) {
          let matterTab = this.tabsService.activeTab as MatterTab;
          if (matterTab && matterTab.matterComponent) {
            let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
              if (result) {
                let participantList: MatterParticipant[] = this.matter.getParticpantForPrintingIdVerification();
                this.openPrintIdFormsModal(this.matter.id, participantList);
                subscription.unsubscribe();
              }
            });
          }
        }
      });
    } else {
      let participantList: MatterParticipant[] = this.matter.getParticpantForPrintingIdVerification();
      this.openPrintIdFormsModal(this.matter.id, participantList);
    }
  }

  isSelectedTabIdDetails(selectedTab: string): boolean {
    return (selectedTab === 'ID-Details');
  }

  selectTab(selectedTab: string, selectPurchaser: MatterParticipantWrapper, isConsentSpouseModalVisible?: boolean): void {
    selectPurchaser.selectedTab = selectedTab || defaultTab;
    this.isConsentSpouseModalVisible = isConsentSpouseModalVisible;

    if (this.isSelectedTabIdDetails(selectPurchaser.selectedTab)
      && selectPurchaser.matterParticipant
      && selectPurchaser.matterParticipant.contact
      && selectPurchaser.matterParticipant.contact.isDirty) {
      selectPurchaser.matterParticipant.contact.initializeContactIdDetails();
    }
    setTimeout(function () {
      jQuery('.mat-modal-dialog').find('input[type=text],dp-checkbox label,textarea,select').filter(':visible:first')
      .focus();
    }, 500);
  }

  isCapacityVisible() {
    //If mainClients Length is greater 1, show Capacity
    return this.matter.mainClients.length > 1 && this.matter.purchasersCapacity === 'OTHER';
  }

  isFamilyLawActVisible(matterParticipant: MatterParticipant) {
    return PurchaserFamilyLawAct.isFamilyLawActVisibleForParticipant(matterParticipant, false, this.matter.provinceCode, this.matter.mainClients.length, this.matter.matterType);
  }

  onClickFlaWarningIcon(selectPurchaserWrapper: MatterParticipantWrapper): void {
    if (!selectPurchaserWrapper.expanded) {
      this.toggleContactSnapshot(selectPurchaserWrapper);
    }
    this.selectTab('Family-Law-Act', selectPurchaserWrapper);
  }

  onClickCapacityWarningIcon(selectPurchaserWrapper: MatterParticipantWrapper): void {
    if (!selectPurchaserWrapper.expanded) {
      this.toggleContactSnapshot(selectPurchaserWrapper);
    }
    this.selectTab('Capacity-Share', selectPurchaserWrapper);
  }

  onClickIdWarningIcon(selectPurchaserWrapper: MatterParticipantWrapper): void {
    if (!selectPurchaserWrapper.expanded) {
      this.toggleContactSnapshot(selectPurchaserWrapper);
    }
    let isIncompleteClientID: boolean;
    let isIncompleteSpousalIDInfo: boolean;

    if (selectPurchaserWrapper && selectPurchaserWrapper.matterParticipant) {
      const matterParticipant: MatterParticipant = selectPurchaserWrapper.matterParticipant;
      isIncompleteClientID = matterParticipant.contact && (!matterParticipant.contact.enteredOn || !matterParticipant.contact.idInfoEnteredBy);
      let consentedSpouseContact: Contact = matterParticipant.consentingSpouseContact;
      isIncompleteSpousalIDInfo
        = (matterParticipant.flaStatementWithConsentedSpouse && consentedSpouseContact
        && (!consentedSpouseContact.enteredOn
          || !consentedSpouseContact.idInfoEnteredBy
          || !this.hasAtLeastOneSpouseIdDocumentValid(matterParticipant)));
    }
    if (isIncompleteSpousalIDInfo && !isIncompleteClientID) {
      this.selectTab('Family-Law-Act', selectPurchaserWrapper, true);

    } else {
      this.selectTab('ID-Details', selectPurchaserWrapper);
    }
  }

  openSourceContactTab(purchaser: MatterParticipantWrapper): void {
    this.contactQueryService.getContactForOpeningTab(purchaser.matterParticipant.contact.sourceContactId).subscribe((res: any) => {
      let source: Contact = res.contact;
      const contactDetails: any = ContactDetails.find((item: any) => {
        return item.label === purchaser.selectedDetailsTabText;
      });
      const subRoute: string = contactDetails && contactDetails.route;
      const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/' + source.contactType, source.contactType, subRoute);
      this.tabsStateService.openTab(contactTab);
    });
  }

  onSnapshotChange(purchaser: MatterParticipantWrapper): void {
    this.matter.reCalculateClientReLine();
    if (purchaser && purchaser.matterParticipant && purchaser.matterParticipant.contact) {
      if (purchaser.matterParticipant.primary) {
        this.updateMatterFieldsFromPrimaryContact(purchaser.matterParticipant.contact, false, false);
      }
    }

    this.enableSave();
  }

  updateSuggestions(): void {
    setTimeout(() => {
      this.updateAllSuggestions(false, true);
    }, 100);

    this.enableSave();
  }

  //------- Contact Salutation

  handleDropdownClickSal = (event) => {
    this.contactSalutationSuggestion.resetSuggestions(ContactSuggestionSalutationEnum.salutation);
    this.contactSalutationSuggestion.initSuggestionsForMatterParticipants(this.selectedClientPurchasers, ContactSuggestionSalutationEnum.salutation, this.matter);
  };

  handleDropdownClickSal2 = (event) => {
    this.contactSalutationSuggestion.resetSuggestions(ContactSuggestionSalutationEnum.salutationContinued);
    this.contactSalutationSuggestion.initSuggestionsForMatterParticipants(this.selectedClientPurchasers, ContactSuggestionSalutationEnum.salutationContinued, this.matter);
  };

  handleDropdownClickDear = (event) => {
    this.contactSalutationSuggestion.resetSuggestions(ContactSuggestionSalutationEnum.dear);
    this.contactSalutationSuggestion.initSuggestionsForMatterParticipants(this.selectedClientPurchasers, ContactSuggestionSalutationEnum.dear, this.matter);
  };

  getDocumentProfileForDearFields() {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.documentProfileService.getById(this.matter.documentProfileId, accountId, true, this.matter).subscribe((documentProfile: DocumentProfile) => {
      this.documentProfile = documentProfile;
      if (documentProfile && documentProfile.firmDocumentProfile && !!documentProfile.firmDocumentProfile.sameAsDefaultProfileFlag) {
        this.documentProfileService.getDefaultProfileForAccountFromCache(accountId).subscribe((defaultDocumentProfile: DocumentProfile) => {
          this.defaultDocumentProfile = defaultDocumentProfile;
        });
      }
    });
  }

  getDearf9TextStartingIndex() {
    // get starting index of DearF9 toggle value depending on value selected in dearText dropdown
    if (this.contactSalutationSuggestion.getShowDropDown(this.salEnum.dear)) {
      let filteredSuggestions: string[] = this.contactSalutationSuggestion.getFilteredSuggestions(this.salEnum.dear);
      let index: number = filteredSuggestions.findIndex(item => item == this.matter.matterContactInfo.dearText);
      if (index > -1 && index < (filteredSuggestions.length - 1)) {
        this.dearf9TextIndex = index + 1;
      }
    }
  }

  onDearF9(): void {
    if (this.contactSalutationSuggestion.getShowDropDown(this.salEnum.dear)) {
      let filteredSuggestions: string[] = this.contactSalutationSuggestion.getFilteredSuggestions(this.salEnum.dear);
      if (filteredSuggestions.length == 1) {
        this.matter.matterContactInfo.dearText = filteredSuggestions[ 0 ];
      } else if (filteredSuggestions.length > 1 && (this.dearf9TextIndex < filteredSuggestions.length)) {
        this.matter.matterContactInfo.dearText = filteredSuggestions[ this.dearf9TextIndex ];
        this.dearf9TextIndex = this.dearf9TextIndex + 1;
      } else {
        this.dearf9TextIndex = 0;
        this.matter.matterContactInfo.dearText = filteredSuggestions[ 0 ];
      }

    } else {
      this.matter.matterContactInfo.dearText = this.getDefaultDearFieldsCode();
    }
  }

  getDefaultDearFieldsCode(): string {
    if (this.documentProfile && this.documentProfile.miscDocumentProfile && this.documentProfile.miscDocumentProfile.defaultDearFieldsCode) {
      return this.documentProfile.miscDocumentProfile.defaultDearFieldsCode;
    }
    return '';
  }

  get generateF9HelpForDear(): string {
    if (this.contactSalutationSuggestion.getShowDropDown(this.salEnum.dear)) {
      let filteredSuggestions: string[] = this.contactSalutationSuggestion.getFilteredSuggestions(this.salEnum.dear);
      let f9Text: string = '';
      if (filteredSuggestions.length == 1) {
        f9Text = 'F9 = ' + filteredSuggestions[ 0 ];

      } else if (filteredSuggestions.length > 1) {
        f9Text = 'F9 = Toggle between ';
        filteredSuggestions.forEach((item, i) => {
          if (i == (filteredSuggestions.length - 1)) {
            f9Text = f9Text + 'and ' + item;
          } else if (i == (filteredSuggestions.length - 2)) {
            f9Text = f9Text + item + ' ';
          } else {
            f9Text = f9Text + item + ', ';
          }

        });
      }
      return f9Text;
    } else {
      return 'F9 = ' + this.getDefaultDearFieldsCode();
    }
  }

  updateAllSuggestions(purchaserDelete: boolean = false, isPurchaserUpdated: boolean = false, retrieveConfig: boolean = false, needTimeOut: boolean = true): Observable<void> {
    return ContactUtil.updateAllSuggestions(this.selectedClientPurchasers, this.matter, this.contactSalutationSuggestion, this.documentProfile,
      purchaserDelete, isPurchaserUpdated, retrieveConfig, needTimeOut);
  }

  //DPPMP-11475
  // if primary purchaser changed or primary address in the current primary purchaser changed, overwrite the pre-closing address with the changed address
  updatePreClosingFromPrimaryPurchaserPrimaryAddress(addressObj: Address) {
    if (addressObj && addressObj instanceof Address) {
      let preClosingAddress = new Address(this.matter.matterContactInfo.preClosingAddress);
      {//copy the primary address's data to preclosing address like street, city, province, country, postalcode
        preClosingAddress.addressLine1 = addressObj.addressLine1;
        preClosingAddress.addressLine2 = addressObj.addressLine2;
        preClosingAddress.city = addressObj.city;
        preClosingAddress.provinceName = addressObj.provinceName;
        preClosingAddress.provinceCode = addressObj.provinceCode;
        preClosingAddress.country = addressObj.country;
        preClosingAddress.postalCode = addressObj.postalCode;
      }
      this.matter.matterContactInfo.preClosingAddress = preClosingAddress;
      if (this.matter.isCustomMatter() && this.matter.matterContactInfo.postClosingAddress.isEmpty && !this.matter.matterContactInfo.preClosingAddress.isEmpty) {
        this.matter.matterContactInfo.postClosingAddress = new Address(preClosingAddress);
        this.matter.matterContactInfo.postClosingAddress.addressTypeCode = AddressTypes.mailing;
        this.matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
      }
    }
  }

  get vendorWarrantyOptions() {
    return this.matter && vendorWarrantyOptions[ this.matter.provinceCode ];
    ;
  }

  survivalAfterClosingVisible() {
    return this.matter.uffiWarrantyCode === 'LIMITED_TIME' && this.matter.isSale;
  }

  get localizedTopicName(): string {
    switch (this.matter.matterType) {
      case MatterTypeEnum.PURCHASE :
        return provinceBasedFieldLabels.get('matter.title.purchaser', this.matter.provinceCode).toUpperCase();
      case MatterTypeEnum.SALE     :
        return provinceBasedFieldLabels.get('matter.title.vendor', this.matter.provinceCode).toUpperCase();
      case MatterTypeEnum.MORTGAGE :
        return MatterSectionKeyEnum.MORTGAGOR;
      case MatterTypeEnum.WILL :
        return MatterSectionKeyEnum.CLIENT_INFO;
      default         :
        return null; //Shouldn't happen, but need the case
    }
  }

  get topicName(): string {
    switch (this.matter.matterType) {
      case MatterTypeEnum.PURCHASE :
        return MatterSectionKeyEnum.PURCHASER;
      case MatterTypeEnum.SALE     :
        return MatterSectionKeyEnum.VENDOR;
      case MatterTypeEnum.MORTGAGE :
        return MatterSectionKeyEnum.MORTGAGOR;
      case MatterTypeEnum.WILL :
        return MatterSectionKeyEnum.CLIENT_INFO;
      default         :
        return null; //Shouldn't happen, but need the case
    }
  }

  focusOnCapacity() {
    //DPPMP-13228 In IE 11 when you first load page the select box appears wider when opened the first time, issue then corrects itself when opened a
    // 2nd time.  This is caused by the focus being on an another field and then clicking on the select box to open it.. to remedy the issue we blur
    // the focus when we rollover the select box.

    if (this.utils.isIE11()) {
      jQuery('input').blur();
    }
  }

  get currentSectionName(): string {
    if (this.matter?.isPurchase) {
      return 'Purchasers';
    }
    if (this.matter?.isSale) {
      return 'Vendors';
    }
    if (this.matter?.isMortgage) {
      return 'Mortgagors';
    }
    if (this.matter?.isWillMatter()) {
      return 'Testators';
    }

  }

  get subjectAddress(): Address {
    // For now, we only think about matterProperties.length is one. The business logic of multi-matterProperties is not clear
    // In the future, the following may be changed.
    return Array.isArray(this.matter.matterProperties) && this.matter.matterProperties.length > 0 && this.matter.matterProperties[ 0 ].address;
  }

  createPostClosingAddressCopyActionOptions() {
    this.postClosingAddressCopyOptions = TitleDetailsUtil.createPostClosingAddressCopyActionOptions(this.matter);
  }

  purchasePostClosingAddressChanged(event) {
    this.createPostClosingAddressCopyActionOptions();
    if (this.matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.manuallyEntered) {
      this.enableSave();
    } else if (event.sameAs) {
      this.enableSave();
    }
  }

  preClosingAddressChanged() {
    if (!this.preClosingAddressFlag) {
      this.enableSave();
    }
    this.preClosingAddressFlag = false;
  }

  postClosingAddressChanged() {
    if (!this.postClosingAddressFlag) {
      this.enableSave();
    }
    this.postClosingAddressFlag = false;
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  get getTitleDetails(): string {
    return TitleDetailsUtil.getAndUpdateTitleDetails(this.matter);
  }

  get getTitleDetailsAB(): string {
    // TODO: need to refactor this code since it setting value in a getter
    return this.matter.generateAndUpdateTitleDetailsAB();
  }

  get getTitleDetailsSkMb(): string {
    return TitleDetailsUtil.generateAndUpdateTitleDetailsSkMb(this.matter);
  }

  get getTitleDetailsNbNs(): string {
    return TitleDetailsUtil.generateAndUpdateTitleDetailsNbNs(this.matter);
  }

  onTitleDetailsChange(): void {
    this.enableSave();
  }

  get isCapacitySelectVisible(): boolean {
    return !this.matter.isMatterProvinceAB
      || !this.matter.isSale
      || (this.matter.mainClients && this.matter.mainClients.length > 1)
      || (this.matter.isSale && (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB));
  }

  clientOrSolicitorLabel(): string {
    if (this.matter.isPurchase) {
      return provinceBasedFieldLabels.get('provinceBasedClientOrSolicitorLabelPurchase', this.matter.provinceCode);
    }
    return provinceBasedFieldLabels.get('provinceBasedClientOrSolicitorLabel', this.matter.isSale ? this.matter.provinceCode : DEFAULT_LABEL);
  }

  postClosingAddressLabel(): string {
    return this.matter.isProjectSale && this.matter.isMatterProvinceAB ? 'Mailing Address <br/>(Pre & Post Closing)'
      : (this.matter.matterContactInfo && this.matter.matterContactInfo.residingAtSubjectProperty && this.matter.matterContactInfo.residingAtSubjectProperty.startsWith('Y')
        ? 'Post-Closing Address' : 'Pre & Post <br/>Closing Address');
  }

  isMassUpdateTab(): boolean {
    return this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMassUpdateSubType();
  }

  getIdAlertsMsg(matterParticipant: MatterParticipant): string {
    let alertsMsg = '';
    if (matterParticipant) {
      let consentedSpouseContact = matterParticipant.consentingSpouseContact;
      const isIncompleteClientID: boolean = matterParticipant.contact && (!matterParticipant.contact.enteredOn || !matterParticipant.contact.idInfoEnteredBy);
      const isIncompleteSpousalIDInfo: boolean
        = (matterParticipant.flaStatementWithConsentedSpouse && consentedSpouseContact
        && (!consentedSpouseContact.enteredOn
          || !consentedSpouseContact.idInfoEnteredBy
          || !this.hasAtLeastOneSpouseIdDocumentValid(matterParticipant)));

      if (isIncompleteClientID || isIncompleteSpousalIDInfo) {
        if (isIncompleteClientID && isIncompleteSpousalIDInfo) {
          alertsMsg = 'Incomplete Client ID and Spousal ID Information';
        } else if (isIncompleteClientID) {
          alertsMsg = 'Incomplete Client ID Information';
        } else if (isIncompleteSpousalIDInfo) {
          return 'Incomplete Spousal ID Information';
        }
      }
    }
    return alertsMsg;
  }

  getParticipantCirfStatusMessage(matterParticipant: MatterParticipant): string {
    let cirf: Cirf = this.getParticipantCirf(matterParticipant);
    let displayText: string = cirf.statusDisplay + ' ' + cirf.statusDisplayPreposition + ' ' + SharedUtils.getMediumDateFormat(cirf.lastUpdatedTimeStamp);
    return displayText;
  }

  checkClientBeforeValidateAndSendCirf(isIndividual: boolean, participant?: MatterParticipant): void {
    if (!isIndividual) {
      this.dialogService.confirm('Information', 'Client details cannot be collected for corporations or estates.  Do you still wish to continue?', false, 'Ok', 'No').subscribe(
        res => {
          if (res) {
            this.validateAndSendCIRFRequest(participant);
          }
        }
      );
    } else {
      this.validateAndSendCIRFRequest(participant);
    }
  }

  saveMatterBeforeValidateAndSendCIRFRequest(isIndividual: boolean, participant?: MatterParticipant): void {
    if (this.matter.dirty) {
      this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
        if (res && res == true) {
          let matterTab = this.tabsStateService.activeTab as MatterTab;
          if (matterTab && matterTab.matterComponent) {
            let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
              if (result) {
                this.checkClientBeforeValidateAndSendCirf(isIndividual, participant);
                subscription.unsubscribe();
              }
            });
          }
        }
      });
    } else {
      this.checkClientBeforeValidateAndSendCirf(isIndividual, participant);
    }
  }

  saveMatter() {
    const matterTab = this.tabsStateService.activeTab as MatterTab;
    if (matterTab && matterTab.matterComponent) {
      const subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
        if (result) {
          subscription.unsubscribe();
        }
      });
    }
  }

  initiateMatterCIRFRequest(): void {
    if (!this.primaryPurchaser || !this.primaryPurchaser.matterParticipant || (this.matter.matterContactInfo && (!this.matter.matterContactInfo.email ||
      (this.matter.matterContactInfo.email && this.matter.matterContactInfo.email.trim() == '')))) {
      this.dialogService.confirm('Error', 'A primary contact with a valid email address is required before initiating this request', true, 'Ok').subscribe();
    } else if (this.matter.matterContactInfo && this.matter.matterContactInfo.email && this.primaryPurchaser.matterParticipant
      && this.primaryPurchaser.matterParticipant.contact && !this.primaryPurchaser.matterParticipant.contact.isIndividual) {
      this.saveMatterBeforeValidateAndSendCIRFRequest(false);
    } else if (this.matter.matterContactInfo && !!this.matter.matterContactInfo.email && this.matter.matterContactInfo.email.trim() != '') {
      this.saveMatterBeforeValidateAndSendCIRFRequest(true);
    }
  }

  initiateParticipantCIRFRequest(participant: MatterParticipant): void {
    if (!participant || !participant.contact) {
      return;
    }

    if (!participant.contact.email || participant.contact.email.trim() == '') {
      this.dialogService.confirm('Error', 'A valid email address for this client is required before initiating this request.', true, 'Ok').subscribe();
    } else {
      this.saveMatterBeforeValidateAndSendCIRFRequest(participant.contact.isIndividual, participant);
    }
  }

  private getDocumentProfileLawFirmName(): string {
    return this.cirfHelperService.getDocumentProfileLawFirmName(this.documentProfile, this.defaultDocumentProfile);

  }

  validateAndSendCIRFRequest(participant?: MatterParticipant): void {
    if (this.getDocumentProfileLawFirmName() && this.hasValidAddressOrDescriptionForCirf()) {
      const cirf = new Cirf();
      cirf.matterId = this.matter.id;
      cirf.cirfType = participant ? 'PARTICIPANT' : 'MATTER';
      cirf.cirfMatterData = new CirfMatterData();
      this.updateCirfWithMatterDataSaveAndSendEmail(cirf, participant);
    } else {
      this.showCIRFErrorMessage();
    }
  }

  hasValidAddressOrDescriptionForCirf(): boolean {
    if (this.matter?.isWillMatter()) {
      return true;
    }
    return this.matter && this.matter.isCustomMatter() ?
      this.matter.matterPropertyWithCondo && !!this.matter.matterPropertyWithCondo.legalDescriptionSummary
      : this.matter.matterProperties[ 0 ] && this.matter.matterProperties[ 0 ].address && !!this.matter.matterProperties[ 0 ].address.addressLine1;
  }

  showCIRFErrorMessage(): void {
    let errorMessage = 'The following information is required before a Client Intake Request Form can be submitted:<br><br>';
    if (!this.getDocumentProfileLawFirmName()) {
      errorMessage += '- Firm name is missing<br>';
    }
    if (this.matter && this.matter.isCustomMatter()) {
      if (!(this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.legalDescriptionSummary)) {
        errorMessage += '- Matter Description is missing<br>';
      }
    } else {
      if (!(this.matter.matterProperties[ 0 ] && this.matter.matterProperties[ 0 ].address && this.matter.matterProperties[ 0 ].address.addressLine1) && !this.matter?.isWillMatter()) {
        errorMessage += '- Property Address is missing<br>';
      }
    }
    this.dialogService.confirmDialog('Error', errorMessage, true, 'OK');
  }

  async updateCirfWithMatterDataSaveAndSendEmail(cirf: Cirf, participant?: MatterParticipant) {
    const welcomePackagePrecedentId = participant ? participant.welcomePackagePrecedentId : this.matter.primaryMainClient && this.matter.primaryMainClient.welcomePackagePrecedentId;
    const welcomePackageStatus = participant ? participant.welcomePackageStatus : this.matter.primaryMainClient && this.matter.primaryMainClient.welcomePackageStatus;
    if (cirf) {
      // old workflow
      if (this.matter.isProjectSale ||
        // when trying create a new CIRF and already have an individual CIRF for main client
        !participant && this.doesPrimaryClientHaveAnIndividualCirf ||
        // this is for the case when resending the email with updated data
        cirf.id ||
        // this is for the case when a welcome package has been sent but not with a CIRF
        welcomePackageStatus === 'SENT_WITHOUT_CIRF') {
        cirf = await this.cirfHelperService.updateCirfWithMatterDataAndSave(this.matter, cirf, participant);
        if (cirf) {
          this.updateCirf(cirf);
          this.sendEmail(cirf, participant, welcomePackageStatus === 'SENT_WITHOUT_CIRF' ? null : welcomePackagePrecedentId); // do not use welcome precedent on resending CIRF email
        }
      } else {
        cirf = await this.cirfHelperService.updateCirfWithMatterData(this.matter, cirf, participant);
        await this.openWelcomeClientModal(cirf, participant);
      }
    } else if (welcomePackagePrecedentId && welcomePackageStatus === 'SENT_WITHOUT_CIRF') {
      await this.resendEmailWithoutACirf(participant);
    }
  }

  // this is the special case when a welcome package has been sent to a participant but without a CIRF and now we only need an email with previous welcome package to be sent
  async resendEmailWithoutACirf(participant?: MatterParticipant) {
    const welcomePackagePrecedentId = participant ? participant.welcomePackagePrecedentId : this.matter.primaryMainClient && this.matter.primaryMainClient.welcomePackagePrecedentId;
    if (welcomePackagePrecedentId) {
      let messagePrecedent: string = await this.cirfHelperService.getCirfPrecedentTextByMatterType(this.matter, welcomePackagePrecedentId);
      const selectedContact = participant ? participant.contact : this.matter.primaryMainClient && this.matter.primaryMainClient.contact;
      const sharedDocumentsPackages = await this.documentProductionService.getSharedDocumentPackageSummary(this.matter.id).toPromise();
      let guid = '';
      // check for a sharedDocPackage
      let sharedDocumentsPackage = sharedDocumentsPackages.find(sharedDocumentsPackages => sharedDocumentsPackages.connectRecipientEmail === selectedContact.email);
      if (sharedDocumentsPackage) {
        guid = sharedDocumentsPackage.guid;
      }
      // it could be that only a task has been assigned
      if (!guid) {
        const matterWorkItem = this.matter.matterWorkItems.find(matterWork => {
          return matterWork.matterWorkItemTasks.some(itemTask => {
            return itemTask.isAssigned() && itemTask.assignedToParticipantEmail === selectedContact.email;
          });
        });
        if (matterWorkItem) {
          const matterWorkItemTask = matterWorkItem.matterWorkItemTasks.find(itemTask => {
            return itemTask.isAssigned() && itemTask.assignedToParticipantEmail === selectedContact.email;
          });
          if (matterWorkItemTask) {
            guid = matterWorkItemTask.inviteGuid;
          }
        }
      }
      if (guid) {
        const baseUrl = this.cirfHelperService.getUnityConnectBaseUrl(guid);
        const subject: string = this.emailFieldService.getMailSubject(EmailKeys.matterOpening);
        const dearText = selectedContact && selectedContact.dear ? selectedContact.dear : '';
        const body = this.cirfHelperService.createCIRFEmailBody(baseUrl, messagePrecedent, dearText);
        this.emailFieldService.openLocalEmailClient(selectedContact.email, subject, body).subscribe(value => {
        });
      } else {
        console.error('Could not find a proper GUID (task ar shared package) to re-send welcome package.');
        this.errorService.addDpSaveError(DPError.createCustomDPError('serverError', messages.mailToErrorMessages.couldNotFindTaskOrSharedPackage, 'ERROR', 'ERROR'));
      }
    } else {
      console.error('Could not find a welcome precedent id to re-send welcome package.');
    }
  }

  async sendEmail(cirf: Cirf, participant: MatterParticipant, precedentId: number): Promise<void> {
    const isEmailSent = await this.cirfHelperService.prepareAndSendCirfEmail(cirf, this.matter, precedentId, participant);
    if (isEmailSent) {
      this.updateCirf(cirf);
    }
  }

  // new Welcome Client workflow
  // allows sending CIRF + SharedDocs + Appointment in one package
  async openWelcomeClientModal(cirf: Cirf, participant?: MatterParticipant): Promise<void> {
    if (cirf) {
      const matterCopy = new Matter(this.matter);
      this.dialogService.matDialogContent({
        content: WelcomeClientModalComponent,
        widthXl: true,
        context: {
          matterCopy: matterCopy,
          originalMatter: this.matter,
          cirf: cirf,
          participant: participant
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action === ModalResult.OK) {
              if (result.updatedCirf) {
                this.updateCirf(result.updatedCirf);
              }
              if (result.saveMatterRequired) {
                this.saveMatter();
              }
              this.dialogService.confirmDialog('Welcome Package', messages.sharedDocumentsMessages.emailSent, true, 'OK');
            } else if (result.action === ModalResult.Cancel) {
              if (result.updatedCirf) {
                this.updateCirf(result.updatedCirf);
              }
            }
          }
        }
      });
    }
  }

  get doesPrimaryClientHaveAnIndividualCirf(): boolean {
    if (this.matter && this.matter.primaryMainClient) {
      const cirf = this.participantCirfs.find(participantCirf => participantCirf.matterParticipantId === this.matter.primaryMainClient.matterParticipantId);
      return !!cirf;
    }
    return false;
  }

  get cirfButtonLabel(): string {
    if (this.matter.isProjectSale ||
      // sent a Welcome Package from main client burger menu (not from main button -> in that case this button is not visible anyway)
      (this.doesPrimaryClientHaveAnIndividualCirf) ||
      // sent a Welcome Package from main menu but without including a CIRF
      (this.matter.primaryMainClient && this.matter.primaryMainClient.welcomePackagePrecedentId && this.matter.primaryMainClient.welcomePackageStatus === 'SENT_WITHOUT_CIRF')) {
      return 'Send Information Request Form to Primary Client';
    }
    return 'Send Welcome Package';
  }

  refreshSubject(subject: Subject<string>): Subject<string> {
    return SharedUtils.refreshSubject(subject);
  }

  ngAfterViewInit() {
    if (this.matter.opportunity && this.matter.opportunity.sourceReferralId
      && Array.isArray(this.matter.duplicateProspectDataList) && this.matter.duplicateProspectDataList.length > 0
      && this.activeMatterTab
      && !this.activeMatterTab.duplicateProspectCheckPerformed) {
      this.resolveDuplicateProspectModal(this.matter.duplicateProspectDataList);
      this.activeMatterTab.duplicateProspectCheckPerformed = true;
    }
  }

  setMatterCirf(cirf: Cirf): void {
    this.matterCirf = cirf;
    this.initiateCirfBurgerMenu();

    //update reference to Matter Cirf in wrappers
    this.selectedClientPurchasers.forEach(client => {
      client.matterCirf = this.matterCirf;
    });
  }

  initiateCirfBurgerMenu(): void {
    this.cirfBurgerMenuItems = [];
    if (this.matterCirf) {
      if (this.matterCirf.status === 'SUBMITTED') {
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'REVIEW_PROCESS_INTAKE', 'Review / Process Intake', '');
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'CIRF_INFO', 'Info', '');
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'CANCEL_REQUEST', 'Cancel Request', '');
      }
      if (this.matterCirf.status === 'OUTSTANDING' || this.matterCirf.status === 'INPROGRESS') {
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'CANCEL_REQUEST', 'Cancel Request', '');
        const resendLabel = this.primaryPurchaser.matterParticipant.welcomePackagePrecedentId ? 'Re-send Welcome Email' : 'Re-send email';
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'RESEND_CIRF_EMAIL', resendLabel, '');
      }
      if (this.matterCirf.status === 'CANCELLED' || this.matterCirf.status === 'EXPIRED') {
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'RE_OPEN_REQUEST', 'Re-Open Request', '');
      }
      if (this.matterCirf.status === 'PROCESSED') {
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'REVIEW_PROCESS_INTAKE', 'Review / Process Intake', '');
        BurgerMenuExtendedItem.createMenuItem(this.cirfBurgerMenuItems, 'CIRF_INFO', 'Info', '');
      }
    }
  }

  performCirfActionDropDown(clickedMenuOption: BurgerMenuExtendedItem): void {
    switch (clickedMenuOption.key) {
      case 'REVIEW_PROCESS_INTAKE':
        this.openCIRFImportDataModal();
        break;
      case 'CANCEL_REQUEST':
        this.cancelRequest();
        break;
      case 'RE_OPEN_REQUEST':
        this.reOpenRequest();
        break;
      case 'CIRF_INFO':
        this.openProcessCIRFModal();
        break;
      case 'RESEND_CIRF_EMAIL':
        //matter data may already been changed, need to update the cirf with latest matter data and send the email
        this.updateCirfWithMatterDataSaveAndSendEmail(this.matterCirf);
        break;
      default:
        console.log('Burger menu key not valid');
        break;
    }
  }

  openCIRFImportDataModal(participantCirf?: Cirf): void {
    let matterTab = this.tabsService.activeTab as MatterTab;
    if (matterTab && matterTab.matterComponent) {
      matterTab.matterComponent.openCIRFImportDataModal(participantCirf ? participantCirf.guid : this.matterCirf.guid, this);
    }
  }

  async cancelRequest(participantCirf?: Cirf): Promise<void> {
    let cirf: Cirf = participantCirf ? participantCirf : this.matterCirf;
    let canBeCancelled = cirf && [ 'OUTSTANDING', 'SUBMITTED', 'INPROGRESS' ].indexOf(cirf.status) > -1;
    if (canBeCancelled) {
      const opportunityView: OpportunityView = new OpportunityView();
      opportunityView.matterId = this.matter.id;
      opportunityView.id = cirf.id;
      let updateCirf = await this.opportunitiesService.cancelCirf(opportunityView).toPromise();
      if (updateCirf) {
        this.updateCirf(updateCirf);
      }
    }
  }

  reOpenRequest(participantWrapper?: MatterParticipantWrapper): void {
    let cirf: Cirf = participantWrapper && participantWrapper.participantCirf && participantWrapper.participantCirf.isParticipantCirf() ? participantWrapper.participantCirf : this.matterCirf;

    if (cirf.status === 'CANCELLED' || cirf.status === 'EXPIRED') {
      if (cirf.isMatterCirf()) {
        if (!this.primaryPurchaser || !this.primaryPurchaser.matterParticipant || (this.matter.matterContactInfo && (!this.matter.matterContactInfo.email ||
          (this.matter.matterContactInfo.email && this.matter.matterContactInfo.email.trim() == '')))) {
          this.dialogService.confirm('Error', 'A primary contact with a valid email address is required before initiating this request', true, 'Ok').subscribe();
        } else {
          this.updateCirfWithMatterDataSaveAndSendEmail(cirf);
        }
      } else if (cirf.isParticipantCirf()) {
        let participant: MatterParticipant = participantWrapper && participantWrapper.matterParticipant;

        if (!participant || !participant.contact) {
          return;
        }

        if (!participant.contact.email || participant.contact.email.trim() == '') {
          this.dialogService.confirm('Error', 'A valid email address for this client is required before initiating this request.', true, 'Ok').subscribe();
        } else {
          this.updateCirfWithMatterDataSaveAndSendEmail(cirf, participant);
        }
      }

    }
  }

  openProcessCIRFModal(participantCirf?: Cirf) {
    this.activeMatterTab.matterComponent.openProcessCIRFModal(participantCirf ? participantCirf.guid : this.matterCirf.guid);
  }

  uffiWarrantyCodeChanged(): void {
    this.matter.isPurchaseTypeValueChanged = false;
    this.enableSave();
  }

  isCustomerIndividualLetterCheckboxVisible(): boolean {
    if (!this.matter.isProjectSale && this.selectedClientPurchasers && !this.matter.isCustomMatter() && !this.isOpportunityMatter()) {
      if (this.matter.receiveCustomerIndividualLetterFlag) {
        this.matter.receiveCustomerIndividualLetterFlag = this.selectedClientPurchasers.length > 1;
      }
      return this.selectedClientPurchasers.length > 1;
    }
    return false;
  }

  individualTitleDetailsChange(event) {
    if (event) {
      this.matter.matterContactInfo.residingAtSubjectProperty = DpBooleanValueTypes.NO;
      this.residingAtSubjectPropertyChange();
    }
    this.enableSave();
  }

  handleContactChange(participantWrapper: MatterParticipantWrapper, isDeleted?: boolean, checkForChildren?: boolean): void {
    if (this.matter && participantWrapper && participantWrapper.matterParticipant && participantWrapper.matterParticipant.contact) {
      this.contactChangesListener.handleContactChange(this.matter, participantWrapper.matterParticipant, this.matter.mainClientType, this.tabsService, isDeleted, null, checkForChildren);
    }
  }

  get reLineTextLabel(): string {
    return this.matter && this.matter.isMatterTypeDischarge ? 'Mortgagor\'s RE: Line' : 'Borrower\'s RE: Line';
  }

  get isUffiApplicable(): boolean {
    return this.matter && !this.matter.isMatterTypeDischarge;
  }

  async createdMissSignersForReplaceProspect(duplicateProspectData: DuplicateContactData): Promise<MatterParticipant[]> {
    this.activeMatterTab.softDeleteSignSourceContactIdList = [];
    let signers: MatterParticipant[] = [];
    if (duplicateProspectData && duplicateProspectData.replacedSourceContactId
      && Array.isArray(duplicateProspectData.duplicateSourceContacts)
      && duplicateProspectData.existingProspectMatterParticipant && duplicateProspectData.existingProspectMatterParticipant.contact) {
      if (duplicateProspectData.existingProspectMatterParticipant.contact.isCorporation) {
        let sourceContact: Contact = duplicateProspectData.duplicateSourceContacts.find(item => item.id == duplicateProspectData.replacedSourceContactId);
        //duplicateSourceContacts which backend send back don't include contactAssociations
        sourceContact = await this.contactQueryService.getContactForMatter(sourceContact.sourceContactId).toPromise();
        signers = this.matter.getChildSigners(duplicateProspectData.existingProspectMatterParticipant)
        .filter(signer => {
          let ret = true;
          if (sourceContact && Array.isArray(sourceContact.contactAssociations) && signer.contact && signer.contact.contactName) {
            let found = sourceContact.contactAssociations.find(association => {
              return (association && association.associatedContact && association.associatedContact.contactName)
                && (association.associatedContact.contactName.lastName == signer.contact.contactName.lastName
                  && association.associatedContact.contactName.firstName == signer.contact.contactName.firstName);

            });
            // if we find same names, don't need  to add this signer which comes from referral  and soft deleted this referral signer. We use the found singer.
            // if we can't find same names, need to add this referral signer
            ret = found ? false : true;

            if (found) {
              this.activeMatterTab.softDeleteSignSourceContactIdList.push(signer && signer.contact && signer.contact.sourceContactId);
            }
          }
          return ret;
        });

      }
    }
    return signers;
  }

  async getSourceSigners(signerParticipants: MatterParticipant[]): Promise<Contact[]> {
    let getContactObservables: Observable<any>[];
    let signerSourceContacts: Contact[] = [];
    if (signerParticipants && signerParticipants.length > 0) {
      getContactObservables = signerParticipants.filter(item => item && item.contact && item.contact.sourceContactId)
      .map(signerParticipant => this.contactQueryService.getContactForMatter(signerParticipant.contact.sourceContactId));
    }
    if (getContactObservables) {
      signerSourceContacts = await Observable.forkJoin(getContactObservables).toPromise();
    }
    return signerSourceContacts;
  }

  async replaceProspect(duplicateProspectData: DuplicateContactData) {
    let oldPrimaryFlag: boolean;
    let oldParticipantIndex: number;
    let oldParticipantPriority: number;
    let signers: MatterParticipant[] = await this.createdMissSignersForReplaceProspect(duplicateProspectData);
    let sourceContact: Contact = null;
    let signerSourceContacts: Contact[] = [];

    if (Array.isArray(signers) && signers.length > 0) {
      sourceContact = await this.contactQueryService.getContact(duplicateProspectData.replacedSourceContactId).toPromise();
      if (sourceContact && sourceContact.locked) {
        await this.dialogService.confirm('Error', ContactUtil.getConcurrencyIssuesErrorMsg(sourceContact, signers.map(item => item.contact)), true, 'Ok').toPromise();
        return;
      } else {
        signerSourceContacts = await this.getSourceSigners(signers);
      }
    }
    // we copy vendors (Project Sale Matter) to vendors (Project Sale Matter)
    if (duplicateProspectData && duplicateProspectData.existingProspectMatterParticipant) {
      if (this.selectedClientPurchasers && this.selectedClientPurchasers.length > 0) {
        let oldMatterParticipantWrapper
          = this.selectedClientPurchasers.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == duplicateProspectData.existingProspectMatterParticipant.matterParticipantId);
        oldParticipantIndex = this.matter.matterParticipants.findIndex(item => item.matterParticipantId == duplicateProspectData.existingProspectMatterParticipant.matterParticipantId);
        if (oldMatterParticipantWrapper && oldMatterParticipantWrapper.matterParticipant) {
          oldParticipantPriority = oldMatterParticipantWrapper.matterParticipant.matterParticipantPriority;
          oldPrimaryFlag = oldMatterParticipantWrapper.matterParticipant.primary;
          if (this.activeMatterTab
            && oldMatterParticipantWrapper.matterParticipant
            && oldMatterParticipantWrapper.matterParticipant.contact
            && oldMatterParticipantWrapper.matterParticipant.contact.sourceContactId) {
            this.activeMatterTab.softDeleteSourceContactIdList.push(oldMatterParticipantWrapper.matterParticipant.contact.sourceContactId);
            let oldSourceContact = await this.contactQueryService.getContactForMatter(oldMatterParticipantWrapper.matterParticipant.contact.sourceContactId).toPromise();
            //Remove signers relation ship of the parent contact because this parent contact will be soft deleted
            if (oldSourceContact && oldSourceContact.contactAssociations && oldSourceContact.contactAssociations.length > 0) {
              oldSourceContact.contactAssociations = [];
              await this.contactCommandService.updateContact(oldSourceContact, true);
            }
          }
          if (this.activeMatterTab.softDeleteSignSourceContactIdList
            && this.activeMatterTab.softDeleteSignSourceContactIdList.length > 0) {
            this.activeMatterTab.softDeleteSourceContactIdList.push(...this.activeMatterTab.softDeleteSignSourceContactIdList);
          }
          await this.deleteClient(oldMatterParticipantWrapper, false);
        }
      }

      let matterParticipantWrapper: MatterParticipantWrapper = this.addNewPurchaser();
      let index = this.selectedClientPurchasers.findIndex(item => item == matterParticipantWrapper);
      if (matterParticipantWrapper && index >= 0) {
        matterParticipantWrapper.dataModel
          = duplicateProspectData.duplicateSourceContacts.find(item => item.id == duplicateProspectData.replacedSourceContactId);

        await this.selectExistingContact(matterParticipantWrapper, index, oldParticipantPriority, oldParticipantIndex);
        if (signerSourceContacts && signerSourceContacts.length > 0) {
          MatterParticipantUtil.addSigningOfficer(this.matter, matterParticipantWrapper.matterParticipant, signerSourceContacts);
          this.enableSave();
        }
        if (oldPrimaryFlag) {
          this.setAsPrimaryPurchaser(matterParticipantWrapper);
        }
        if (matterParticipantWrapper && matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.contact) {
          matterParticipantWrapper.matterParticipant.contact.duplicateProspectActionTaken = DuplicateProspectActionTakenTypes.REPLACE;
        }
      }
    }
  }

  async resolveDuplicateProspectModal(duplicateProspectDataList: DuplicateContactData[]): Promise<void> {
    if (Array.isArray(duplicateProspectDataList) && duplicateProspectDataList.length > 0) {
      for (let i = 0; i < duplicateProspectDataList.length; i++) {
        await this.openDuplicateProspectModal(duplicateProspectDataList[ i ]).toPromise();
      }

      let result = await this.activeMatterTab.matterComponent.validateAndSaveMatter().toPromise();
      if (result && this.activeMatterTab.softDeleteSourceContactIdList && this.activeMatterTab.softDeleteSourceContactIdList.length > 0) {
        this.activeMatterTab.softDeleteSourceContactIdList.forEach(sourceContactId => {
          this.contactService.softDeleteContact(sourceContactId);
        });
      }
      this.activeMatterTab.softDeleteSourceContactIdList = [];
    }
  }

  openDuplicateProspectModal(duplicateProspectDataList): Observable<void> {
    let validationSubject = new Subject<void>();
    this.dialogService.matDialogContent({
      content: DuplicateProspectModalComponent,
      context: {
        duplicateProspectData: duplicateProspectDataList
      },
      onFulfillment: async (result) => {
        switch (result) {
          case DuplicateProspectModalResult.KEEP:
            duplicateProspectDataList.existingProspectMatterParticipant.contact.duplicateProspectActionTaken
              = DuplicateProspectActionTakenTypes.KEEP;
            this.enableSave();
            validationSubject.next();
            validationSubject.complete();
            break;
          case DuplicateProspectModalResult.REPLACE:
            duplicateProspectDataList.existingProspectMatterParticipant.contact.duplicateProspectActionTaken
              = DuplicateProspectActionTakenTypes.REPLACE;
            this.enableSave();
            await this.replaceProspect(duplicateProspectDataList);
            validationSubject.next();
            validationSubject.complete();
            break;
          default:
            validationSubject.next();
            validationSubject.complete();
            break;
        }
      },

    });
    return validationSubject;
  }

  addWillParticipantFields(participant: MatterParticipant): MatterParticipant {
    participant.participantType = 'WILL';
    participant.remainsClause = '';
    participant.otherConcerns = '';
    participant.visuallyImpaired = false;
    participant.wishesForTheirRemains = 'BURIAL';
    participant.bodyOrganDonation = 'NOT_SPECIFIED';
    participant.willSubTypes = [];
    return participant;
  }

  shouldDisplayActionLink(): boolean {
    return (!this.getEditedPurchaser() && this.selectedClientPurchasers?.length < this.appConfig?.getMaxNumberOfPurchasers() &&
      !this.matter?.isOpportunityMatterAndTypeWill() &&
      !this.matter?.isWillMatter()) || (this.matter?.isWillMatter() && this.selectedClientPurchasers?.length === 1 && !this.getEditedPurchaser());
  }

  getLabelByProvinceCode(key: string): string {
    return provinceBasedFieldLabels.get(key, this.matter?.provinceCode);
  }

  getDirectDepositLabelByMatterType(): string {
    switch (this.matter?.matterType) {
      case 'PURCHASE':
        return 'matter.purchase.directDeposit';
      case 'MORTGAGE':
        return 'matter.mortgage.directDeposit';
      default:
        return 'matter.purchase.directDeposit';
    }
  }

  percentAcquiredChanged() {
    this.errorService.removeDpfieldErrorsByPartialKey('propertyPercentBeingAcquired-*');
    const purchasers: MatterParticipant[] = this.matter?.matterParticipants.filter(mp => this.APPLICABLE_PTT_PARTICIPANT_ROLES.includes(mp.matterParticipantRole));
    const percentSum: number = purchasers?.map(mp => !!mp.propertyPercentBeingAcquired ? mp.propertyPercentBeingAcquired : 0)?.reduce((a, b) => Number(a) + Number(b), 0);
    if (percentSum > 100) {
      purchasers.forEach((p, index) => {
        if (index > 0) {
          let error: DPError = DPError.createCustomDPError(
            `propertyPercentBeingAcquired-${ index }`, this.PERCENT_ACQUIRED_ERROR, this.PTT_ERROR_TOPIC, 'ERROR');
          error.groupId = this.PTT_ERROR_TOPIC;
          this.errorService.addDpFieldErrorWithNoDupBubble(error, []);
        }
      });
    } else {
      purchasers.forEach((p, index) => {
        if (index > 0) {
          this.errorService.removeDpFieldError(`propertyPercentBeingAcquired-${ index }`);
        }
      });
    }
    this.enableSave();
  }
}

