import {ReferredClient} from './referred-client';
import {Telephone} from '../telephone';
import {Address} from '../address';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {TelephoneTypes} from '../telephone-types';

export class ReferralForm extends BaseEntity {

  id: number;
  // Submitter
  referringAgentFirstName: string;
  referringAgentMiddleName: string;
  referringAgentLastName: string;
  referringAgentRole: string;
  referringAgentTelephones: Telephone[] = [];
  referringAgentEmail: string;
  referringAgentCompany: string;

  // Parties
  referredClients: ReferredClient[] = [];

  // Transaction
  closingDate: string;
  propertyAddress: Address;
  transactionType: string;
  purchaseType: string;
  purchasePrice: number;
  depositAmount: number;
  otherSolicitorId: number;
  otherSolicitorName: string;
  otherLawFirmId: number;
  otherLawFirmName: string;
  realEstateAgentId: number;
  realEstateAgentName: string;
  realEstateBrokerageFirmId: number;
  realEstateBrokerageFirmName: string;
  totalCommissionAmount: number;
  newMortgageAmount: number;
  referralNotes: string;
  mortgageNotes: string;

  //Receving
  receivingSolicitorId: number;
  receivingSolicitorName: string;
  receivingSolicitorAddress: string;
  receivingLawFirmId: number;
  receivingLawFirmName: string;

  constructor(referral?: ReferralForm) {
    super(referral);
    if (referral) {
      this.referringAgentTelephones = [];
      if (referral.referringAgentTelephones) {
        this.populatePhones(referral, [ TelephoneTypes.home, TelephoneTypes.cell, TelephoneTypes.work ]);
      }
      if (referral.propertyAddress) {
        this.propertyAddress = new Address(referral.propertyAddress);
      }

      this.referredClients = [];
      if (Array.isArray(referral.referredClients)) {
        for (let cc of referral.referredClients) {
          this.referredClients.push(new ReferredClient(cc));
        }
      }
    }
  }

  populatePhones(referral: ReferralForm, phoneTypes: string[]): void {
    for (const phoneType of phoneTypes) {
      let phone: Telephone = referral.referringAgentTelephones.find((t: Telephone) => t.phoneTypeCode === phoneType);
      if (phone) {
        this.referringAgentTelephones.push(new Telephone(phone));
      } else {
        phone = new Telephone();
        phone.phoneTypeCode = phoneType;
        this.referringAgentTelephones.push(phone);
      }
    }
  }

  getReferringAgentFullName(): string {
    return this.referringAgentLastName + ', ' + this.referringAgentFirstName + (this.referringAgentMiddleName ? ' ' + this.referringAgentMiddleName : '');
  }

  getReferringAgentPhone(): string {
    let phone: Telephone = this.referringAgentTelephones.find(phone => !!phone.telephoneNumber);
    return phone ? phone.telephoneNumber : '';
  }

  getReferringAgentRole(): string {
    return this.referringAgentRole ? this.referringAgentRole.replace('USER_', '') : '';
  }

}
