import {Injectable} from '@angular/core';
import {HttpClient} from '../../core';
import {accountApi} from '../accounts/shared/account-api';
import {RemoteSigningConfiguration} from './remote-signing-configuration';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class RemoteSigningConfigurationService {
  constructor(private http: HttpClient) {

  }

  getRemoteSigningDefault(accountId: string, provinceCode: string): Observable<RemoteSigningConfiguration> {
    let url = accountApi.accountRemoteSigningDefault(accountId, provinceCode);
    return this.http.get(url)
    .map((res) => {
      let data = res[ 'REMOTE_SIGNING_CONFIG' ];
      return new RemoteSigningConfiguration(data);
    });
  }

  setRemoteSigningDefault(remoteSigningDefault: RemoteSigningConfiguration, accountId: string): Observable<RemoteSigningConfiguration> {
    let url = accountApi.setAccountRemoteSigningDefault(accountId, remoteSigningDefault && remoteSigningDefault.provinceCode);
    return this.http.put(url, JSON.stringify(remoteSigningDefault))
    .map((res) => {
      let data = res[ 'REMOTE_SIGNING_CONFIG' ];
      return new RemoteSigningConfiguration(data);
    });

  }
}
