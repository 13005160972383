<form class="form-horizontal" id="selectPropertyOwnerForm" #selectPropertyOwnerForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect &trade;: Search By Name - {{teranetSearchRequest.firstName ? teranetSearchRequest.firstName + ' ' + teranetSearchRequest.lastName : teranetSearchRequest.lastName}}</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="onCancel()">&times;</button>
            </div>
        </div>
        <div class="modal-body">
            <div class="col-lg-12">
                <form class="form-horizontal">
                    <div class="sticky-header">
                        <div class="row">
                            <div class="col-lg-12 margin-left-15">
                                Select desired name from below:
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 margin-top-10 table-index" >
                        <div>
                            <table class="table dp-teranet-table" tabindex="-1" (keydown)="onKeyPress($event)">
                                <tbody>
                                <tr *ngFor="let row of propertyOwners;let i=index;" (click)="updateSelectedItem(i)">
                                    <td [ngClass]="isRowSelected(i)? 'dp-teranet-row-active': 'dp-teranet-row'">
                                        <span id="propertyOwner-{{i}}" class="noCaret"
                                               [ngClass]="isRowSelected(i)? 'dp-teranet-row-active': 'dp-teranet-row'"
                                               (dblclick)="onSelectPropertyOwner()">
                                            {{row.lastName + ' ' + row.firstName}}
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" class="dp-btn dp-btn-cancel" (click)="backToSearch()">
                    <span>Back to Search Criteria</span>
                </button>
                <button type="button" class="dp-btn keyboard-save-action" (click)="onSelectPropertyOwner()">
                    <span>Select</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="onCancel()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

        <div class="col-sm-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>
</form>
