<form class="form-horizontal" id="dp-cirf-import-container">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Client Intake Request Form Data Import</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <form class="form-horizontal padding-5" #CirfImportForm="ngForm">
        <mat-tab-group  #cirfImportTabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="onSelectTabChange($event)">
            <mat-tab label="&#x2713; Clients" >
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-cirf-import-owner *ngIf="matter && cirf"
                                              [matter]="matter"
                                              [originalMatter]="originalMatter"
                                              [cirf]="cirf"
                                              [cirfClientToMatterParticipantMap]="cirfClientToMatterParticipantMap"
                                              [cirfClientsWithSpouseImport]="cirfClientsWithSpouseImport"
                        >
                        </dp-cirf-import-owner>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="cirf.isMatterCirf()" [label]="(isTabReviewed(tabKeys.matterDataTab) ? '&#x2713; ' : '') + 'General Client Data'" >
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-cirf-import-matter-data *ngIf="matter && cirf"
                                                 [matter]="matter"
                                                 [cirf]="cirf"
                        >
                        </dp-cirf-import-matter-data>
                    </div>
                </div>
            </mat-tab>
            <mat-tab  *ngIf="cirf.isMatterCirf() && !cirf.isCustomMatter() && (matter.isPurchase || matter.isMortgage)"
                    [label]="(isTabReviewed(tabKeys.newMortgageTab) ? '&#x2713; ' : '') + 'New Mortgages'" >
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-cirf-import-mortgage *ngIf="matter && cirf"
                                                 [matter]="matter"
                                                 [cirf]="cirf"
                                                 [cirfDataPropagation]="cirfDataPropagation"
                                                 [mortgageType]="mortgageTypes.New"
                        >
                        </dp-cirf-import-mortgage>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="cirf.isMatterCirf() && !cirf.isCustomMatter() && (matter.isSale || matter.isMortgage)"
                    [label]="(isTabReviewed(tabKeys.existingMortgageTab) ? '&#x2713; ' : '') + 'Existing Mortgages'"  >
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-cirf-import-mortgage *ngIf="matter && cirf"
                                                 [matter]="matter"
                                                 [cirf]="cirf"
                                                 [cirfDataPropagation]="cirfDataPropagation"
                                                 [mortgageType]="mortgageTypes.Existing"
                        >
                        </dp-cirf-import-mortgage>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="cirf.isMatterCirf() && !cirf.isCustomMatter() && matter && !matter.isSale && !matter.isWillMatter()"
                    [label]="(isTabReviewed(tabKeys.insuranceTab) ? '&#x2713; ' : '') + 'Fire Insurance'"  >
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-cirf-import-insurance *ngIf="matter && cirf"
                                                 [matter]="matter"
                                                 [cirf]="cirf"
                                                 [cirfDataPropagation]="cirfDataPropagation"
                        >
                        </dp-cirf-import-insurance>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="(isTabReviewed(tabKeys.attachmentsTab) ? '&#x2713; ' : '') + 'Attachments'"  >
                <div class="modal-body">
                    <div class="col-lg-12">
                        <dp-unity-connect-import-attachment *ngIf="cirf"
                                                    [documents]="cirf?.documents"
                                                   [selectedDocuments]="cirf?.selectedDocuments"
                        >
                        </dp-unity-connect-import-attachment>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn">
                <span>Apply Changes</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>

        </div>

    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
