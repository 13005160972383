import {Component, Inject, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SoaHeat} from '../../model/heat-soa';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {PartialDateComponent} from '../../../../shared/partial-date/partial-date.component';
import Utils from '../../../../shared-main/utils';
import {Utils as Utils2} from '../../../shared/utils';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {ModalResult} from '../../../../shared-main/enums';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AdjustmentBaseModalComponent} from '../adjustment-base.modal.component';

class HeatSoaModalContext {
  salePrice: string;
  closingDate: string;
  soaHeat: SoaHeat;
  provinceHstRateSlab: ConsiderationTaxes;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  sourceFromProject: boolean;
  vendorLabel: string;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
}

@Component({
  selector: 'dp-heat-soa-modal-content',
  templateUrl: './heat-soa.modal.component.html',
  providers: [ErrorService]
})
export class HeatSoaModal extends AdjustmentBaseModalComponent<SoaHeat, HeatSoaModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('partialCommencementDate') partialCommencementDate: PartialDateComponent;
  context: HeatSoaModalContext;
  heatTypeList: SelectItem[];
  heatUnitMeasurement: SelectItem[];
  heatAdjustTypeList: SelectItem[];
  heatYesNoAdjustList: SelectItem[];
  noYesOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;
  closingDateLabel: string = 'Closing Date';
  localAdjustment: SoaHeat;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<HeatSoaModal>,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: HeatSoaModalContext
  ) {
    super(dialog, dialogService, context);
    this.statementAdjustmentDisplayUtil = this.context.statementAdjustmentDisplayUtil;
  }

  ngOnInit() {
    this.heatTypeList = dropDowns.heatTypes;
    this.heatUnitMeasurement = dropDowns.heatUnitMeasurementTypes;
    this.heatAdjustTypeList = Utils.loadTaxSelectItem(
      this.context.provinceHstRateSlab.rateType,
      this.context.provinceHstRateSlab.hstProvincialPortion
    );
    this.heatYesNoAdjustList = dropDowns.heatAdjustFor;
    if (!this.context.soaHeat) {
      this.localAdjustment = new SoaHeat();
      this.newAdj = true;
    } else {
      this.localAdjustment = new SoaHeat(this.context.soaHeat);
    }
    if (
      !this.localAdjustment.adjustType &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.provinceDefaultTaxRate
    ) {
      this.localAdjustment.adjustType = this.context.provinceHstRateSlab.provinceDefaultTaxRate;
    }
    if (this.context.closingDate) {
      this.closingDateLabel = Utils2.formatDate(this.context.closingDate);
    }
  }

  changeHeatAdjustType() {
    if (this.localAdjustment.adjustFor == 'YES') {
      this.localAdjustment.percentage = this.getDefaultRate(this.localAdjustment.adjustType);
    } else {
      this.localAdjustment.percentage = 0;
    }
  }

  changeAdjustFor() {
    if (this.localAdjustment.adjustFor == 'YES') {
      this.changeHeatAdjustType();
    } else {
      this.localAdjustment.percentage = 0;
    }
  }

  getDefaultRate(type): number {
    if (
      type == this.context.provinceHstRateSlab.rateType &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.hstRate
    ) {
      return this.context.provinceHstRateSlab.hstRate;
    } else if (
      type == 'GST' &&
      this.context.provinceHstRateSlab &&
      this.context.provinceHstRateSlab.hstFederalPortion
    ) {
      return this.context.provinceHstRateSlab.hstFederalPortion;
    } else {
      return this.context.provinceHstRateSlab.hstRate;
    }
  }

  checkForErrors() {
    this.modalErrorComponent.removeDpFieldError('matter.soa.soaheat.capacity');
    this.modalErrorComponent.removeDpFieldError('matter.soa.soaheat.capacity.max');
    this.modalErrorComponent.removeDpFieldError('matter.soa.soaheat.costper');
    if (this.localAdjustment.heatType === 'FUEL_OIL' || this.localAdjustment.heatType === 'PROPANE') {
      if (this.localAdjustment.tankCapacity <= 0) {
        this.modalErrorComponent.createDPFieldError('matter.soa.soaheat.capacity');
      }
      if (this.localAdjustment.tankCapacity > 99999.99) {
        this.modalErrorComponent.createDPFieldError('matter.soa.soaheat.capacity.max');
      }
      if (this.localAdjustment.costPerLitre <= 0) {
        this.modalErrorComponent.createDPFieldError('matter.soa.soaheat.costper');
      }
    }
  }

  ok(): void {
    this.checkForErrors();

    if (!this.modalErrorComponent.anyErrorExist() || this.localAdjustment.noCostAdjustment) {
      this.clearAmounts();
      this.dialog.close(this.localAdjustment);
    }
  }

  clearAmounts(): void {
    if (this.localAdjustment.noCostAdjustment) {
      this.localAdjustment.costPerLitre = 0;
      this.localAdjustment.tankCapacity = 0;
      this.localAdjustment.adjustFor = 'NO';
      this.localAdjustment.percentage = 0;
      this.localAdjustment.carbonTaxAmount = 0;
      this.localAdjustment.adjustForCarbonTax = 'N_y';
    }
    if (this.localAdjustment.adjustForCarbonTax !== 'YES') {
      this.localAdjustment.carbonTaxAmount = 0;
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }
}
