import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {accountApi} from '../admin/accounts/shared/account-api';
import {HttpClient} from './httpClient.service';
import {MatterRestrictedPeriod} from '../admin/accounts/shared/matter-restricted-period';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import moment from 'moment';

@Injectable()
export class MatterRestrictedPeriodService {
  constructor(public httpClient: HttpClient) {
  }

  private matterRestrictedPeriods: MatterRestrictedPeriod[];

  loadMatterRestrictedTimePeriods(): void {
    let url = accountApi.restrictedPeriods();
    this.httpClient.get(url)
    .map((res) => {
      let data = res[ 'MatterRestrictionPeriods' ];
      if (data) {
        this.matterRestrictedPeriods = data.map(item => new MatterRestrictedPeriod(item));
      }
    }).subscribe();
  }

  getCreatedMattersCount(provinceCode: string): Observable<number> {

    let provinceRestrictedPeriod = this.getRestictedPeriodByProvinceCode(provinceCode);
    if (provinceRestrictedPeriod && provinceRestrictedPeriod.startDate && provinceRestrictedPeriod.endDate && provinceRestrictedPeriod.provinceCode) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      let url = accountApi.createdMattersCount(accountId, moment(provinceRestrictedPeriod.startDate).format('YYYY-MM-DD'),
        moment(provinceRestrictedPeriod.endDate).format('YYYY-MM-DD'), provinceRestrictedPeriod.provinceCode);
      return this.httpClient.get(url)
      .map((res) => {
        return res[ 'CreatedMattersCount' ];
      });
    } else {
      return Observable.of(0);
    }

  }

  isTodayInRestrictedPeriod(provinceCode: string): boolean {
    let provinceRestrictedPeriod = this.getRestictedPeriodByProvinceCode(provinceCode);
    if (provinceRestrictedPeriod && provinceRestrictedPeriod.startDate && provinceRestrictedPeriod.endDate) {
      let today = moment(new Date());
      let startDateMoment = moment(provinceRestrictedPeriod.startDate);
      let endDateMoment = moment(provinceRestrictedPeriod.endDate);
      return moment(today).isBetween(startDateMoment, endDateMoment) ||
        today.format('YYYY-MM-DD') == startDateMoment.format('YYYY-MM-DD') ||
        today.format('YYYY-MM-DD') == endDateMoment.format('YYYY-MM-DD');
    } else {
      return false;
    }
  }

  getRestictedPeriodByProvinceCode(provinceCode: string): MatterRestrictedPeriod {
    return this.matterRestrictedPeriods && this.matterRestrictedPeriods.length ?
      this.matterRestrictedPeriods.find(period => period.provinceCode == provinceCode) : null;
  }

  clearMatterRestrictedPeriods(): void {
    this.matterRestrictedPeriods = [];
  }
}
