import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {Component, Inject} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalResult} from '../../../../shared-main/enums';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import Utils from '../../../../shared-main/utils';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class SoaNetSalePriceAdjustmentModalContext {
  public closingDate: string;
  public salePriceAdjustment: SalePriceAdjustment;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public addedFlag: boolean;
  public rateType: string;
  public totalConsideration: number;
  public vendorShare: number;
  public sourceFromProject: boolean;
  public newAdj: boolean = false;
}

@Component({
  selector: 'dp-net-sale-price-soa-modal-content',
  templateUrl: './soa-net-sale-price-adjustment.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class SoaNetSalePriceAdjustmentModalComponent extends ModalComponent<SoaNetSalePriceAdjustmentModalContext> {
  soaSalePriceMinusNetSalePrice: SalePriceAdjustment;
  closingDateLabel: string = 'Closing Date';

  constructor(
    public dialog: MatDialogRef<SoaNetSalePriceAdjustmentModalComponent>,
    public currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: SoaNetSalePriceAdjustmentModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  ok(): void {
    this.dialog.close({action: 'OK'});
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.context.newAdj;
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (currencyValue != undefined && !isNaN(currencyValue) && currencyValue.toString().trim() != '') {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }
}
