import {SalePriceAdjustmentAlbertaStrategy} from './sale-price-adjustment-alberta-strategy';
import Utils from '../../../shared-main/utils';
import {ItemizedCreditToVendorPurchaserItem} from './itemized-credit-to-vendor-purchaser-item';
import {CurrencyPipe} from '@angular/common';
import * as _ from 'lodash';

export class ItemizedCreditToVendorPurchaserABStrategy extends SalePriceAdjustmentAlbertaStrategy {

  calculateHstPortion(hstPortion: number, isTaxOut?: boolean, returnAsIs?: boolean, excludeAdditionalConsiderationsInclHst: boolean = false): number {
    let result = 0;
    result = this.calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst) * (hstPortion / 100);

    if (returnAsIs) {
      return result; // rounding is not needed to avoid extra penny in certain cases
    } else {
      return Utils.roundCurrencyTCStyle(result); // rounding is needed to avoid extra penny in certain cases
    }
  }

  calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst: boolean = false): number {
    if (this.isNotInclusivePrice() && !this.isDivideTotalBy105()) {
      return Number(this.total(excludeAdditionalConsiderationsInclHst).toFixed(2));
    } else {
      return super.calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst);
    }
  }

  // Itemized credit adjustment do the calculations based on matter's sale price adjustment "netOutHstFromHSTSalePrice". Therefore make sure it is pre-seeded
  // from Sale price adjustment to itemized adjustment before calling any calculations.
  totalNetSalePrice(federalHstPortion: number, provinceHstPortion: number, excludeAdditionalVendorConsid: boolean = false, excludeAdditionalConsiderationsInclHst: boolean = false): number {
    if (this.adjustmentType === 'NO_ADJUSTMENT' || this.adjustmentType === 'SILENT') {
      return 0;
    }
    if (this.isDivideTotalNot()) {
      return Utils.roundCurrency(this.total(excludeAdditionalConsiderationsInclHst));
    } else {
      if (this.isDivideSalePrice() && this.isDivideTotalBy10320()) {
        // In case of DivideSalePrice AND DivideTotalBy10320, derived net sale price won't include rebate therefore making rebate flags false
        this.federalPortion = false;
        this.provincialPortion = false;
        const derivedNetSalePriceWithoutRebate = this.calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst);

        // Rebate added separately though it's disabled and not included in derivedNetSalePrice
        const currValue = Number(this.evaluateFederalRebate(federalHstPortion));
        let result = Utils.roundCurrency(derivedNetSalePriceWithoutRebate + currValue);
        result = Utils.roundCurrency(result + currValue);
        return result;
      } else {
        this.federalPortion = !this.isDivideSalePrice() && !this.isDivideTotalBy105();
        this.provincialPortion = !this.isDivideSalePrice() && !this.isDivideTotalBy105();
        return super.totalNetSalePrice(federalHstPortion, provinceHstPortion, excludeAdditionalVendorConsid, excludeAdditionalConsiderationsInclHst);
      }
    }
  }

  lessHstComponentValue(federalHstPortion: number, provinceHstPortion: number, excludeAdditionalVendorConsid: boolean = false, excludeAdditionalConsiderationsInclHst: boolean = false): number {
    return this.totalNetSalePrice(federalHstPortion, provinceHstPortion, excludeAdditionalVendorConsid, excludeAdditionalConsiderationsInclHst) - this.total();
  }

  isDivideTotalBy105(): boolean {
    return (this.divideTotal === '105_LESS_GST_COMPONENT' || this.divideTotal === '105_SHOW_CALCULATION_ONLY');
  }

  isDivideTotalBy10320(): boolean {
    return (this.divideTotal === '10320_LESS_GST_COMPONENT' || this.divideTotal === '10320_SHOW_CALCULATION_ONLY');
  }

  divizer(): string {
    if (this.isDivideTotalBy10320()) {
      return '1.0320';
    } else if (this.isDivideTotalBy105()) {
      return '1.05';
    } else {
      return '';
    }
  }

  isDivideTotalNot(): boolean {
    return this.divideTotal === 'NO';
  }

  isRebatePortionEnabled(): boolean {
    return (this.isDivideSalePrice() && this.isDivideTotalBy10320()) || (!this.isDivideSalePrice() && !this.isDivideTotalBy105());
  }

  total(excludeAdditionalConsiderationsInclHst: boolean = false): number {
    if (!this.creditItems || this.creditItems.length === 0) {
      return 0.0;
    }
    const total = this.creditItems.reduce((sum: number, creditItem: ItemizedCreditToVendorPurchaserItem): number => {
      return sum + Utils.toNumber(creditItem.amount);
    }, 0.0);
    return total;
  }

  getLessHstComponentLabel(currencyPipe: CurrencyPipe): string {
    let label: string = '';
    if (this.divideTotal === '10320_LESS_GST_COMPONENT' || this.divideTotal === '105_LESS_GST_COMPONENT') {
      label += 'Less GST component'; //This is an Alberta label
    }
    return label + '(' + this.getFormattedCurrencyValue(currencyPipe, this.total()) + '/' + this.divizer() + ')';
  }

  getLessHstComponentAmount(currencyPipe: CurrencyPipe, federalHstRate: number, provinceHstRate: number): string {
    if (this.divideTotal === '10320_LESS_GST_COMPONENT' || this.divideTotal === '105_LESS_GST_COMPONENT') {
      // Multiplying with -1 to change it from -ve to +ve for display
      const lessHstAmount = -1 * this.lessHstComponentValue(federalHstRate, provinceHstRate);
      return this.getFormattedCurrencyValue(currencyPipe, lessHstAmount);
    } else {
      return '';
    }
  }

  getFormattedTotal(currencyPipe: CurrencyPipe): string {
    return this.getFormattedCurrencyValue(currencyPipe, this.total());
  }

  getFormattedCurrencyValue(currencyPipe: CurrencyPipe, currencyValue: number): string {
    if (currencyValue !== undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() !== '') {
      return currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  getCreditNoteHeading(): string {
    if (this.adjustmentType === 'NO_ADJUSTMENT') {
      return 'No Adjustment';
    }
    if (this.adjustmentType === 'CREDIT_TO_PARTY') {
      return 'Credit ' + _.capitalize(this.creditsTo);
    }
    return '';
  }
}
