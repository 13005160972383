import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {Observable} from 'rxjs';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {userAccountProfilesApi} from '../shared/user-account-profiles-api';
import {StaffProfiles} from '../staff-profiles/staff-profiles';
import {AuthenticatedUser} from '../../core/authenticated-user';

@Injectable()
export class UserSessionService {
  constructor(private httpClient: HttpClient) {}

  initUserSession(authenticatedUserResponse: any): void {
    let authenticatedUserObj: AuthenticatedUser =
      AuthenticatedUser.createAuthenticatedUserFromApiResponse(authenticatedUserResponse);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.authenticatedUser, JSON.stringify(authenticatedUserObj));
    sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(authenticatedUserResponse));
    sessionStorage.setItem(SESSION_STORAGE_KEYS.userLogin, authenticatedUserResponse.username);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.accountId, authenticatedUserResponse.customerAccountId);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.legalFirmId, authenticatedUserResponse.legalFirmId);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.userDocumentProfileId, authenticatedUserResponse.documentProfileId);

    sessionStorage.setItem(SESSION_STORAGE_KEYS.userContactId, authenticatedUserResponse.contactId);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.userId, authenticatedUserResponse.userId);
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.userInitiatedResetsAllowed,
      authenticatedUserResponse.userInitiatedResetsAllowed
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.disablePostClosedMattersEdit,
      authenticatedUserResponse.disablePostClosedMattersEdit
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.disableMatterEditAfterClosingDays,
      authenticatedUserResponse.disableMatterEditAfterClosingDays
    );
    sessionStorage.setItem(SESSION_STORAGE_KEYS.fctEnabled, authenticatedUserResponse.fctEnabled); // Customer Account level flag to show or hide FCT EMP functionality to user
    sessionStorage.setItem(SESSION_STORAGE_KEYS.isConveyancingUser, authenticatedUserResponse.conveyancingCustomer);
  }

  getActiveUserSessions(accountId: string): Observable<StaffProfiles[]> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url: string = userAccountProfilesApi.loggedInUsers.replace('{accountId}', accountId);
    return this.httpClient.get(url).map((res) => {
      let staffProfilesList: StaffProfiles[] = [];
      if (Array.isArray(res['StaffProfiles'])) {
        staffProfilesList = res['StaffProfiles'].map((item) => {
          return new StaffProfiles(item);
        });
      }
      return staffProfilesList;
    });
  }

  logUserOut(userId: string, accountId: string): Observable<boolean> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url: string = userAccountProfilesApi.logUserOut.replace('{accountId}', accountId).replace('{id}', userId);
    return this.httpClient.post(url, null).map((res) => {
      if (res['SUCCESS']) {
        return true;
      }
    });
  }
}
