<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Manage Templates</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="margin-top-0">
                <div class="fixed-header padding-top-20">
                    <div class="col-lg-5 padding-top-10 boldfont padding-left-20">
                        {{accountFileFolder?.templateFolderNameWithProcessorType}}
                        <div class="padding-top-5 semiboldfont">
                            <a target=”_blank” href="{{linkToDocGenDocumentation}}">Document Production and Field Codes</a>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <ul class="float-right">
                            <li  class="float-left">
                                <button type="button" class="dp-btn dp-btn-cancel"
                                        [disabled]="!this.selectedTemplateIds || this.selectedTemplateIds && !this.selectedTemplateIds.length"
                                        (click)="downloadSelectedTemplates()">
                                    <span>Download Selected Templates</span>
                                </button>
                            </li>
                            <li  class="float-left">
                                <button type="button" class="dp-btn dp-btn-cancel" (click)="importDoProcessTemplates()" *ngIf="!isSystemUser()">
                                    <span>Import Unity Templates</span>
                                </button>
                            </li>
                            <li  class="float-left">
                                <dp-dropdown-menu
                                    [dropDownItems]="actionDropDownItems"
                                    [customColor]="'white'"
                                    [openSubMenuOnLeft]="true"
                                    (itemClicked)="selectFiles($event)"
                                    [buttonLabel]="'Upload'"
                                    [disabled]="context.isMatterReadOnlyAccess "
                                    [addFocus]="true">
                                </dp-dropdown-menu>
                            </li>
                        </ul>
                        <input style="display: none;"
                               #fileUploadMasters
                               id="file-upload-masters"
                               type="file"
                               multiple="true"
                               [accept]="getAcceptedFileExtensionsByUploadType('MASTER')"
                               (change)="initiateUpload(false, 'MASTER')"
                        />
                        <input style="display: none;"
                               #fileUploadIncludes
                               id="file-upload-includes"
                               type="file"
                               multiple="true"
                               [accept]="getAcceptedFileExtensionsByUploadType('INCLUDE')"
                               (change)="initiateUpload(true, 'INCLUDE')"
                        />

                        <input style="display: none;"
                               #fileUploadWord_DPS_LTR
                               id="file-upload-word-dps-ltr"
                               type="file"
                               multiple="false"
                               [accept]="getAcceptedFileExtensionsByUploadType('DOTX')"
                               (change)="initiateUpload(false, 'DOTX')"
                        />
                        <input style="display: none;"
                               #fileUploadRtfMasters
                               id="file-upload-rtf-masters"
                               type="file"
                               multiple="false"
                               [accept]="getAcceptedFileExtensionsByUploadType('RTF')"
                               (change)="initiateUpload(false, 'RTF')"
                        />
                        <input style="display: none;"
                               #fileUploadRtfIncludes
                               id="file-upload-rtf-includes"
                               type="file"
                               multiple="false"
                               [accept]="getAcceptedFileExtensionsByUploadType('RTF_INCLUDE')"
                               (change)="initiateUpload(false, 'RTF_INCLUDE')"
                        />
                    </div>
                </div>

                <table class="standard-table margin-left-10 margin-top-80 width-auto"  tabindex="-1" [mfData]="rows" #mf="mfDataTable" [mfRowsOnPage]="3000" >
                    <thead class="sticky-table-header">
                    <tr>
                        <th class="col0">
                            <dp-checkbox fieldId="multiTemplateSelectBox" [hasTabIndex]="true">
                                <input type="checkbox" id="multiTemplateSelectBox" name="multiTemplateSelectBox" #multiTemplateSelect
                                       [(ngModel)]="allTemplatesSelected"
                                       (change)="toggleSelectMultipleTemplates(multiTemplateSelect.checked)"

                                /></dp-checkbox>
                        </th>
                        <th class="col1" (click)="sortFileName.sort();" id="sortFileName">
                            <div class="wrap-header">
                                <span style="width: 100%;">Name
                        <dp-default-sorter #sortFileName by="documentTemplateFile.fileName" defaultSort="counter" [singleState]="true"></dp-default-sorter>
                                </span>
                            </div>

                        </th>
                        <th class="col2"></th>
                        <th class="col3">
                            <div class="wrap-header">Description
                                <!--<dp-default-sorter #sortDescription-->
                                                   <!--by="template.description" [singleState]="true"></dp-default-sorter>-->
                            </div>
                        </th>
                        <th class="col4">Date</th>
                        <th class="col5">Type</th>
                        <th class="col6">Matter Type</th>
                        <th class="col7">Multi Client Template</th>

                    </tr>
                    </thead>
                    <tbody>

                    <tr *ngFor="let row of mf.data; let i = index;" [ngClass]="{'focus-first': i==0}"
                        (keydown)="tableKeyCommands(i,$event)"
                        tabindex="0">
                        <td class="col0">
                            <dp-checkbox fieldId="selectedTemplate_{{i}}" [hasTabIndex]="false">
                                <input type="checkbox" id="selectedTemplate_{{i}}" name="selectedTemplate_{{i}}"
                                       (change)="clickRow(row)"
                                       [dp-read-only]="row.documentTemplateFile.isInfected || row.documentTemplateFile.isVirusScanPending"
                                       [checked]="isSelected(row.documentTemplateFile.id)"/>
                            </dp-checkbox>
                        </td>
                        <td class="col1">
                            <div
                                *ngIf="row.documentTemplateFile.isInfected || row.documentTemplateFile.isVirusScanPending" class="inline-block"><i
                                class="virus-icon padding-right-5"
                                [ngClass]="row.documentTemplateFile.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>

                                <span class="matter-locked-message virus-message">
                                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                                   <span *ngIf="row.documentTemplateFile.isInfected">
                                      We have detected this file is infected with a virus.
                                  </span>
                                      <span *ngIf="row.documentTemplateFile.isVirusScanPending">
                                      Please wait... we are currently scanning this file for viruses.
                                  </span>
                                </span>

                            </div>

                            <span title="{{row.documentTemplateFile.fileName}}" >
                                <div class="wrap-template-name">
                                    {{getTemplateFileName(row.documentTemplateFile)}}
                                </div>

                            </span>
                        </td>
                        <td class="col2">
                            <dp-burger-menu *ngIf="row"
                            [dpItems]="row.burgerMenuItems"
                            (itemClicked)="clickBurgerMenu(row.documentTemplateFile, $event)"
                            [disabled]="context.isMatterReadOnlyAccess"
                            ></dp-burger-menu>
                        </td>
                        <td class="col3 text-overflow">
                            <span *ngIf="isMasterFile(row.documentTemplateFile)"
                                  title="{{row.documentTemplateFile.documentTemplate.description}}" >
                                {{row.documentTemplateFile.documentTemplate.description}}
                            </span>
                        </td>
                        <td class="col4">{{row.documentTemplateFile.lastUpdatedTimeStamp | dpDateFormatPipe : ['noDay']}}</td>
                        <td class="col5">{{getFileType(row.documentTemplateFile)}}</td>
                        <td class="col6" >
                            <div *ngIf="isMasterFile(row.documentTemplateFile)" class="popover-markup">
                                <span class="tool-tip-icon">
                                    {{convertMatterTypeCodesToString(row.documentTemplateFile.documentTemplate.applicableMatterTypeCodes , true)}}
                                </span>
                                <span class="tool-tip-message">
                                    <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                    <span class="message-text">{{convertMatterTypeCodesToString(row.documentTemplateFile.documentTemplate.applicableMatterTypeCodes, false)}}</span>
                                </span>
                            </div>
                        </td>
                        <td class="col7 text-center">
                            <span>{{isMultiClietTemplate(row)? 'Yes': ''}}</span>
                        </td>
                    </tr>

                    <tr *ngIf="rows.length === 0" class="loading-row">
                        <td colspan="6" class="text-center col-not-found">
                            <div class="no-match-found">No Documents Found.</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 bbottom-tbuttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="close()">
                <span>OK</span>
            </button>

        </div>
    </div>

</div>
