import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {

    if (!value) {
      return value;
    }

    if (value.toLowerCase() === 'question') {
      return '??????';
    }

    return _.capitalize(value);

    // let output: string = value.substr(1, value.length - 1).toLowerCase();
    // return value[0].toUpperCase() + output;
  }
}
