import {ErrorService} from '../../../../shared/error-handling/error-service';
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';
import {Contact} from '../../../shared/contact';
import {contactTypeMapping, GenderTypes} from '../../../../contact/contact-type-mapping';
import {contactDropDowns} from '../../../../contact/contact-drop-downs';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {ApplicationError} from '../../../../core/application-error';
import {ContactCommandService} from '../../../../contact/contact-command.service';
import {BorrowerData} from '../../../../integrations/mortgage-deal/lender-deal-data.component';
import {ContactService} from '../../../../shared-main/contact.service';
import {DocumentProfileCache} from '../../../../shared-main/document-profile-cache.service';
import {Matter} from '../../../shared/matter';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {DocumentProfile} from '../../../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../../../admin/document-profile/document-profile-edit/document-profile.service';
import {Address} from '../../../shared/address';
import {TelephoneTypes} from '../../../shared/telephone-types';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {dropDowns} from '../../../../shared-main/address-Form/drop-downs';
import {AccountService} from '../../../../admin/accounts/account.service';
import {currentMatter, MatterParticipant} from '../../../shared';
import {EstateStatusTypeValue} from '../../../../shared-main/teranet/teranet-property-owner';
import {ContactUtil} from '../../../shared/contact-util';
import {OwnerData} from './teranet-owner-import.modal.component';
import {ContactNameUtil} from '../../../shared/contact-name-util';
import Utils from '../../../../shared-main/utils';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class TeranetOwnerContactContext {
  matter: Matter;
  parcelRegisterName: string;
  isMatterSaleOrMortgage: boolean;
  isModalOpenFromLenderData: boolean;
  signingOfficers: string[];
  lenderContact: Contact;
  lenderSnapShotContact: Contact;
  lenderData: BorrowerData;
  address: Address;
  participant: MatterParticipant;
  isWizard: boolean;
  ownerData: OwnerData;
}

@Component({
  selector: 'dp-owner-contact-modal-content',
  templateUrl: 'teranet-owner-contact.modal.component.html',
  providers: [ErrorService]
})
export class TeranetOwnerContactModal
  extends ModalComponent<TeranetOwnerContactContext>
  implements OnInit, AfterViewInit
{
  genders: SelectItem[];
  contact: Contact;
  selectedContactTypeMapping: any;
  isCreateContact: boolean = false;
  parcelRegisterFullName: string;
  estateStatus: SelectItem[];
  genderEstateCode: SelectItem[];
  contactAddress: Address;
  documentProfileFirmAddress: Address;
  addressTypeDd = dropDowns.addressTypes;
  shouldCreateSourceContact: boolean = false;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<TeranetOwnerContactModal>,
    public contactService: ContactService,
    public contactQueryService: ContactQueryService,
    public documentProfileCache: DocumentProfileCache,
    public documentProfileService: DocumentProfileService,
    public accountService: AccountService,
    public errorService: ErrorService,
    public contactCommandService: ContactCommandService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetOwnerContactContext
  ) {
    super(dialog, context);
  }

  get matter(): Matter {
    return currentMatter.value;
  }

  ngAfterViewInit() {
    if (this.isContactReadOnly) {
      this.modalErrorComponent.createCustomDPSaveError(
        'none',
        'The borrower is currently locked by ' + this.contact.lockUserName,
        'CONTACT',
        'ERROR'
      );
    }
  }

  ngOnInit() {
    if (this.isCreateSourceContactAutoEnabled()) {
      this.shouldCreateSourceContact = true;
    }
    this.setDocumentProfileCache();
    this.estateStatus = contactDropDowns.ESTATE_STATUS;
    this.genderEstateCode = contactDropDowns.GENDER_ESTATE_CODE;
    if (this.context.lenderContact && this.context.lenderContact.contactType == 'GUARANTOR_PERSON') {
      this.selectedContactTypeMapping = _.find(
        contactTypeMapping.CONTACTTYPES,
        (contactTypeObj) => contactTypeObj.contactKey === 'GUARANTOR'
      );
    } else {
      this.selectedContactTypeMapping = _.find(
        contactTypeMapping.CONTACTTYPES,
        (contactTypeObj) => contactTypeObj.contactKey === 'CLIENT'
      );
    }
    if (this.selectedContactTypeMapping) {
      this.genders = this.matter.isMatterProvinceBC
        ? this.selectedContactTypeMapping.genders.filter((item) => item.value != GenderTypes.ESTATE)
        : this.selectedContactTypeMapping.genders;
    }

    this.parcelRegisterFullName = this.context.parcelRegisterName;
    if (this.context.lenderContact && this.context.isModalOpenFromLenderData) {
      this.contact = new Contact(this.context.lenderContact);
      if (this.contact.isBlankMaleFemale) {
        this.genders = contactDropDowns.GENDER_ESTATE_CODE;
      } else {
        this.genders = [{label: 'Corporation', value: 'CORPORATION'}];
      }
    } else {
      this.contact = new Contact();
      ContactUtil.createOwnerDataContact(this.contact, this.context.ownerData, this.context.matter);
    }
    if (this.context.address || this.matter.isPurchaseBC) {
      this.contact.address = [];
      this.contactAddress = new Address(this.context.address);
      this.contactAddress.primaryAddress = true;
      this.contact.address.push(this.contactAddress);
    }

    if (!this.isContactReadOnly && this.context.lenderSnapShotContact) {
      this.contact = new Contact(this.context.lenderSnapShotContact);
    } else if (
      this.context.lenderContact &&
      this.context.isModalOpenFromLenderData &&
      this.context.lenderContact.id > 0 &&
      !this.context.lenderContact.locked
    ) {
      this.contact = new Contact();
      this.contact.createNewContactClone(this.context.lenderContact);
      //Snap shot shouldn't copied idInfoEnteredBy and enteredOn from source contact. They belong to matter level information
      this.contact.idInfoEnteredBy = null;
      this.contact.contactIdInfoEnteredBy = null;
      this.contact.enteredOn = null;
      this.contact.snapshotFlag = true;
      this.contact.sourceContactId = this.context.lenderContact.id;
      this.contact.lastSyncedFromSource = this.context.lenderContact.lastSyncedFromSource;
    }
  }

  setDocumentProfileCache(): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (this.context.matter.documentProfileId) {
      this.documentProfileService
        .getById(this.context.matter.documentProfileId, accountId, true, this.context.matter)
        .subscribe((documentProfile: DocumentProfile) => {
          if (documentProfile) {
            this.documentProfileCache.cacheDocumentProfile(documentProfile);
            this.setDocumentProfileLawFirmAddress(accountId, documentProfile);
          }
        });
    }
  }

  async setDocumentProfileLawFirmAddress(accountId: string, documentProfile: DocumentProfile) {
    this.documentProfileFirmAddress = await documentProfile.getDocumentProfileFirmAddress(
      accountId,
      this.context.matter.provinceCode,
      this.documentProfileService,
      this.accountService
    );
    // console.log(">>>> documentProfileFirmAddress value: ", this.documentProfileFirmAddress)
  }

  setAddressesDefaultValue() {
    this.contact.setClientAddressDefaultValue(this.documentProfileFirmAddress);
    this.contact.setClientIdBusinessAddressDefaultValue(this.documentProfileCache);
  }

  setDefaultValue() {
    this.contact.initializeContactIdDetails();
    this.contact.setIdDetailsDefaultLabel();
    this.contact.getPhone(TelephoneTypes.home);
    this.contact.getPhone(TelephoneTypes.work);
    this.contact.getPhone(TelephoneTypes.cell);
    this.contact.getPhone(TelephoneTypes.fax);
    this.setAddressesDefaultValue();
    this.contact.nameOnDocuments = this.contact.reLineName;
  }

  createContact(): void {
    //Clean redundant data
    ContactUtil.createOwnerDataFullContact(this.contact, this.documentProfileFirmAddress, this.documentProfileCache);
    if (
      (this.contact.id && this.contact.id > 0) ||
      (this.contact.sourceContactId && this.contact.sourceContactId > 0)
    ) {
      this.dialog.close(this.contact);
    } else if (
      this.context.lenderData &&
      this.context.lenderData.borrower &&
      this.context.lenderData.borrower.isGuranator()
    ) {
      this.contact.snapshotFlag = true;
      this.dialog.close(this.contact);
    } else {
      this.contact.address.forEach((addr) => {
        addr.normalizeAddressLines(); // dppmp-26882
      });
      this.contactQueryService.createContact(this.contact).subscribe(
        (contact: Contact) => {
          let snapShotContact = new Contact();
          snapShotContact.createNewContactClone(contact);
          snapShotContact.snapshotFlag = true;
          snapShotContact.sourceContactId = contact.id;
          snapShotContact.lastSyncedFromSource = contact.lastSyncedFromSource;
          this.dialog.close(snapShotContact);
        },
        (error: ApplicationError) => {
          console.log(error);
          this.createApiError(error);
        }
      );
    }
  }

  createApiError(error): void {
    if (error.fieldErrors && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((error) => {
        this.modalErrorComponent.createCustomDPSaveError(
          'none',
          error.message ? error.message : error.code,
          'CONTACT',
          'ERROR'
        );
      });
    }
  }

  ok(): void {
    if (this.contact.isValidContact(this.errorService)) {
      if (
        this.isCreateContact ||
        (this.isMatterPurchase && this.isMatterLinked) ||
        this.context.isMatterSaleOrMortgage ||
        (this.context.isModalOpenFromLenderData &&
          this.context.lenderData &&
          this.context.lenderData.borrower &&
          !this.context.lenderData.borrower.isGuranator())
      ) {
        this.createContact();
      } else {
        this.dialog.close(this.contact);
      }
    } else {
      const companyNameError = this.modalErrorComponent.getDPError('contact.companyName');
      if (companyNameError) {
        companyNameError.errorMessage = 'Company Name is required';
      }
    }
  }

  get isMatterPurchase(): boolean {
    return !this.context.isMatterSaleOrMortgage;
  }

  get isMatterLinked(): boolean {
    return this.context.matter && !!this.context.matter.matterLink;
  }

  close(): void {
    this.dialog.close();
  }

  get surNameLabel() {
    if (this.contact.isEstate) {
      return 'Surname of Deceased';
    } else {
      return 'Surname';
    }
  }

  get firstNameLabel() {
    if (this.contact.isEstate) {
      return 'First Name of Deceased';
    } else {
      return 'First Name';
    }
  }

  get middleNameLabel() {
    if (this.contact.isEstate) {
      return 'Middle Name(s) of Deceased';
    } else {
      return 'Middle Name(s)';
    }
  }

  get orgNameLabel() {
    if (this.contact.isOtherEntity) {
      return 'Name';
    } else {
      return 'Company Name';
    }
  }

  onGenderChangeRefactorWork(newGender) {
    this.contact.updateNameIfGenderChange(this.contact.gender, this.contact.gender);
    this.contact.rebuildContactWithGenderChange(
      this.contact.gender,
      newGender,
      this.selectedContactTypeMapping.contactKey
    );
    this.contact.gender = newGender;
    if (this.matter.isMatterProvinceBC) {
      if (this.contact.isMalePoaOrFemalePoa) {
        this.contact.firstName = '';
        this.contact.middleName = '';
      } else if (this.contact.isMaleOrFemaleOrBlankGender()) {
        let names: string[] = ContactNameUtil.parseNamesFromParcelRegister(
          Utils.toMixedCase(this.context.ownerData.parcelRegisterName),
          this.matter.provinceCode
        );
        this.contact.firstName = names[1];
        this.contact.middleName = names[2];
      }
    }
  }

  get isContactReadOnly(): boolean {
    return this.contact.locked || this.contact.isOwnedBySystemAccount;
  }

  get headerTitle(): string {
    if (this.context && this.context.isModalOpenFromLenderData) {
      return (
        (this.context.lenderContact && this.context.lenderContact.id > 0 ? 'Edit New ' : 'Add New ') +
        (this.context.lenderData && this.context.lenderData.borrower && this.context.lenderData.borrower.isGuranator()
          ? 'Guarantor'
          : 'Borrower')
      );
    } else {
      return 'Add New Record';
    }
  }

  get contactTitle(): string {
    if (this.context && this.context.isModalOpenFromLenderData) {
      return (
        (this.context.lenderData && this.context.lenderData.borrower && this.context.lenderData.borrower.isGuranator()
          ? 'Guarantor from Lender Data: '
          : 'Borrower from Lender Data: ') +
        this.parcelRegisterFullName +
        (this.context.lenderContact && this.context.lenderContact.isCorporation ? ' (Corporation)' : ' (Individual)')
      );
    } else {
      return (
        provinceBasedFieldLabels.get('teranet.owner.contact.modal.pre.notification', this.context.matter.provinceCode) +
        this.parcelRegisterFullName +
        this.getEstateAdmin()
      );
    }
  }

  isCreateSourceContactAutoEnabled(): boolean {
    return this.context.matter && !!this.context.matter.matterLink;
  }

  isGenderDisabled(): boolean {
    return (
      this.context &&
      this.contact &&
      ((this.context.isModalOpenFromLenderData && this.contact.isCorporation) || this.contact.isEstate)
    );
  }

  getEstateAdmin(): string {
    let text = '';
    if (
      this.context &&
      !this.context.isModalOpenFromLenderData &&
      this.context.matter &&
      this.context.matter.isMatterProvinceMB &&
      this.context.ownerData &&
      this.context.ownerData.isEstate &&
      this.context.ownerData.estateStatus
    ) {
      if (this.context.ownerData && this.context.ownerData.estateStatus == EstateStatusTypeValue.INTESTATE) {
        text = `<br>${this.getAdminLabel()}(s) of ${this.context.parcelRegisterName}'s estate from TPR: `;
      } else {
        text = `<br>${this.getAdminLabel()}(s) under last will of ${this.context.parcelRegisterName} from TPR: `;
      }
      if (
        this.context.ownerData &&
        this.context.ownerData.estateTrustees &&
        this.context.ownerData.estateTrustees.length
      ) {
        text += this.context.ownerData.estateTrustees.map((item) => item.displayName).join(', ');
      }
    }
    return text;
  }

  getAdminLabel(): string {
    return this.context.ownerData && this.context.ownerData.estateStatus == EstateStatusTypeValue.INTESTATE
      ? 'Administrator'
      : 'Executor';
  }

  onScroll(event) {
    event.stopImmediatePropagation();
  }
}
