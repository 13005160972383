import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthZService} from './authz/auth-z.service';
import {AccessGuardHelper} from './access-guard-key';
import {state} from '@angular/animations';

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(
    private authZService: AuthZService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let authKeys = AccessGuardHelper.getAccessGuardKey(state.url);
    this.authZService.setStateUrl(state);
    if (authKeys && authKeys.length) {
      let accessFlag = false;
      authKeys.forEach((authKey) => {
        if (this.authZService.hasAccess(authKey)) {
          accessFlag = true;
        }
      });
      if (accessFlag) {
        return true;
      } else {
        this.router.navigate(['/main/home']);
        return false;
      }
    } else {
      this.router.navigate(['/main/home']);
      return false;
    }
  }
}
