import {Component, Inject} from '@angular/core';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {SelectItem} from 'primeng/api';
import {DocumentProfile} from '../../../../admin/document-profile/document-profile';
import {soaDropDowns} from '../../../../admin/document-profile/document-profile-edit/soa/soa-drop-downs';
import {DocumentProfileSoa} from '../../../../admin/document-profile/document-profile-edit/soa';
import {StatementAdjustment} from '../../statement-adjustment';
import {soAdjFormats} from '../../model/so-adj-drop-downs';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class StatementAdjustmentHeadingModalContext {
  statementAdjustmentHeadingMatter: StatementOfAdjustmentHeading;
  statementAdjustmentHeadingProject: StatementOfAdjustmentHeading;
  documentProfile: DocumentProfile;
  defaultDocumentProfile: DocumentProfile;
  matterType: string;
  isGlobalFormatVisible: boolean;
  isGlobalFormatReadonly: boolean;
  hasMultipleSOAHeadings: boolean;
  isSoaHeadingWithVendorSolicitor: boolean;
  isProjectSaleMatter: boolean;
  selectedStatementAdjustment: StatementAdjustment;
}

@Component({
  selector: 'dp-statement-adjustment-heading',
  templateUrl: 'statement-adjustment-heading.component.html',
  styleUrls: ['./statement-adjustment-heading.component.scss']
})
export class StatementAdjustmentHeadingComponent extends ModalComponent<StatementAdjustmentHeadingModalContext> {
  statementOfAdjustmentHeading: StatementOfAdjustmentHeading;
  otherHeadingVendorNames: SelectItem[] = [];
  otherHeadingsPurchaserNames: SelectItem[] = [];
  addressData: SelectItem[] = [];
  adjustDate: SelectItem[] = [];
  paperSizes: SelectItem[];
  globalFormats: any[] = soaDropDowns.PROJECTSOAGLOBALFORMATS;
  selectedglobalFormats: string[] = [];

  constructor(
    public dialog: MatDialogRef<StatementAdjustmentHeadingComponent>,
    @Inject(MAT_DIALOG_DATA) context?: StatementAdjustmentHeadingModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.matterType == 'SALE') {
      this.otherHeadingVendorNames = soaDropDowns.OTHERHEADINGSVENDORSALENAMES;
      this.otherHeadingsPurchaserNames = soaDropDowns.OTHERHEADINGSPURCHASERSALENAMES;
    } else {
      this.otherHeadingVendorNames = soaDropDowns.OTHERHEADINGSVENDORNAMES;
      this.otherHeadingsPurchaserNames = soaDropDowns.OTHERHEADINGSPURCHASERNAMES;
    }
    this.addressData = soaDropDowns.ADDRESSDATA;
    this.adjustDate = soaDropDowns.ADJUSTDATE;
    this.paperSizes = soaDropDowns.PAPERSIZES;

    if (!this.context.statementAdjustmentHeadingMatter) {
      this.statementOfAdjustmentHeading = new StatementOfAdjustmentHeading();
      this.initHeading();
      this.updateAllGlobalFormat(true);
    } else {
      this.statementOfAdjustmentHeading = new StatementOfAdjustmentHeading(
        this.context.statementAdjustmentHeadingMatter
      );
      if (!this.statementOfAdjustmentHeading.interimStatementOfAdjustmentHeader) {
        this.statementOfAdjustmentHeading.interimStatementOfAdjustmentHeader =
          'INTERIM ' + this.statementOfAdjustmentHeading.statementOfAdjustmentHeader;
      }
    }
    this.initializeGlobalFormats();
  }

  initHeading(): void {
    if (this.soaProfile != null) {
      this.statementOfAdjustmentHeading.copyFromSoaProfile(this.soaProfile, this.context.isProjectSaleMatter);
    }
  }

  ok(): void {
    this.dialog.close(this.statementOfAdjustmentHeading);
  }

  close(): void {
    this.dialog.close();
  }

  get soaProfile(): DocumentProfileSoa {
    if (this.context.documentProfile != null && this.context.documentProfile.statementOfAdjustmentsProfile != null) {
      if (this.context.documentProfile.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag) {
        if (
          this.context.defaultDocumentProfile != null &&
          this.context.defaultDocumentProfile.statementOfAdjustmentsProfile != null
        ) {
          return this.context.defaultDocumentProfile.statementOfAdjustmentsProfile;
        }
      } else {
        return this.context.documentProfile.statementOfAdjustmentsProfile;
      }
    }
    return null;
  }

  dynamicHelpF9SOAHeading = (type) => this.soaProfile && 'F9 = ' + this.soaProfile[type];

  updateSOAHeading(statementAdjustmentHeading: string, documentProfileType: string): void {
    if (this.soaProfile != null) {
      this.statementOfAdjustmentHeading[statementAdjustmentHeading] = this.soaProfile[documentProfileType];
    }
  }

  initializeGlobalFormats(): void {
    if (this.statementOfAdjustmentHeading.printInUpperCase) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[0].value);
    }
    if (this.statementOfAdjustmentHeading.printInBold) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[1].value);
    }
    if (this.statementOfAdjustmentHeading.underline) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[2].value);
    }
    if (this.statementOfAdjustmentHeading.lastLineCreditVendorOrCreditPurchaser) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[3].value);
    }
    if (this.statementOfAdjustmentHeading.followAdjustmentWithBlankLine) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[4].value);
    }
    if (this.statementOfAdjustmentHeading.printDollarSignsOnlyForFirstCreditOfEachColumn) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[5].value);
    }
    if (this.statementOfAdjustmentHeading.doNotPrintDollarSignsInSubtotalColumn) {
      this.selectedglobalFormats.push(soaDropDowns.PROJECTSOAGLOBALFORMATS[6].value);
    }
  }

  updateMultiSelectGlobalFormat(obj: any[]): void {
    if (obj.length > 0) {
      this.statementOfAdjustmentHeading.printInUpperCase =
        obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[0].value) >= 0;
      this.statementOfAdjustmentHeading.printInBold = obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[1].value) >= 0;
      this.statementOfAdjustmentHeading.underline = obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[2].value) >= 0;
      this.statementOfAdjustmentHeading.lastLineCreditVendorOrCreditPurchaser =
        obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[3].value) >= 0;
      this.statementOfAdjustmentHeading.followAdjustmentWithBlankLine =
        obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[4].value) >= 0;
      this.statementOfAdjustmentHeading.printDollarSignsOnlyForFirstCreditOfEachColumn =
        obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[5].value) >= 0;
      this.statementOfAdjustmentHeading.doNotPrintDollarSignsInSubtotalColumn =
        obj.indexOf(soaDropDowns.PROJECTSOAGLOBALFORMATS[6].value) >= 0;
    } else {
      this.updateAllGlobalFormat(false);
    }
  }

  updateAllGlobalFormat(val: boolean): void {
    this.statementOfAdjustmentHeading.printInUpperCase = val;
    this.statementOfAdjustmentHeading.printInBold = val;
    this.statementOfAdjustmentHeading.underline = val;
    this.statementOfAdjustmentHeading.lastLineCreditVendorOrCreditPurchaser = val;
    this.statementOfAdjustmentHeading.followAdjustmentWithBlankLine = val;
    this.statementOfAdjustmentHeading.printDollarSignsOnlyForFirstCreditOfEachColumn = val;
    this.statementOfAdjustmentHeading.doNotPrintDollarSignsInSubtotalColumn = val;
  }

  statementAdjustmentHeadingDialogTitle(): string {
    let sahdt: string = 'Statement of Adjustments Heading Info';
    if (this.context.isGlobalFormatVisible) {
      sahdt += ' and ' + (this.context.isProjectSaleMatter ? 'View' : 'Global') + ' Format';
    }
    return sahdt;
  }

  onAdoptProjectHeadingChange(): void {
    if (this.statementOfAdjustmentHeading.adoptProjectHeading && this.context.statementAdjustmentHeadingProject) {
      this.statementOfAdjustmentHeading.adoptProjectHeadingFrom(this.context.statementAdjustmentHeadingProject);
    }
  }

  get customFormatVisible(): boolean {
    return (
      this.context.selectedStatementAdjustment &&
      this.context.selectedStatementAdjustment.soAdjHeading &&
      this.context.selectedStatementAdjustment.soAdjHeading.customFormatSettingType ==
        soAdjFormats.adjustmentFormatSettingType_ApplyCustomFormat.value
    );
  }
}
