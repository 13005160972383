import {Project} from '../../projects/shared/project';
import {Matter} from '../shared';
import {StatementAdjustmentUtil} from './statement-adjustment-util';
import {DepositOnOccupancyCalculatedBasedOn, StatementAdjustmentKey} from './statement-adjustment-constants';
import {SalePriceOptionValue} from '../property-teranet/deposit/deposit-modal-constants';
import {OCCUPANCY_FEES_CALCULATED_BASED_ON} from '../../shared-main/constants';
import * as _ from 'lodash';
import Utils from '../../shared-main/utils';

/**
 * Utility for calculating deposit adjustment using provided matter/project. Interim BDOC amount is required for Final mode.
 */
export class DepositAdjustmentCalculationUtil {

  private readonly projectOccupancyFeesCalBasedOn: string;
  private readonly isInterim: boolean = true;
  private readonly project: Project;

  constructor(private matter: Matter) {
    if (matter) {
      this.project = matter.project;
      this.isInterim = !matter.isAdjustmentStatusModeFinal;
      this.projectOccupancyFeesCalBasedOn = this.project && this.project.projectCondo ? this.project.projectCondo.occupancyFeesCalculatedBasedOn : null;
    }
  }

  /**
   * How to use this method:
   * 1. From StatementAdjustComponent, first switch to Interim mode and invoke this method
   * 2. Take the value of Interim Balance Due on Closing
   * 3. Switch to Final Mode again from within StatementAdjustComponent
   * 4. Call this method again with the interim BDOC amount
   *
   * Note: when calling this method for Final SOA, interimBDOC amount is mandatory.
   *
   * @param {number} interimBDOC
   */
  calculateDepositAdjustmentByStatusMode(interimBDOC?: number) {

    const interimBDOCAmount = Number(interimBDOC);
    if (!this.isInterim && !_.isFinite(interimBDOCAmount)) {
      throw new Error('When current adjustmentMode is Final, argument interimBDOC must be a valid number.');
    }

    let adj = StatementAdjustmentUtil.findByKey(this.isInterim ? this.matter.interimStatementAdjustments : this.matter.finalStatementAdjustments, StatementAdjustmentKey.DEPOSIT);
    if (adj) {
      if (this.isInApplicableProvinces()) {
        // first need to recalc using current sale price adjustment, whose changes may also affect
        // our calculation
        this.recalculateSalePriceTotal();

        // recalc entire deposit adjustment based on above sale price total
        this.recalculateDepositAdjustment(interimBDOCAmount);
      }
      adj.amount = this.matter.calculateCreditPurchaserForDeposit();
    }
  }

  isInApplicableProvinces(): boolean {
    return this.matter.isMatterProvinceAB || this.matter.isMatterProvinceON;
  }

  private recalculateSalePriceTotal() {
    if (this.matter.extraDepositConfig.salePriceTotalOption == SalePriceOptionValue.AS_PER_AGREEMENT || this.matter.extraDepositConfig.salePriceTotalOption == SalePriceOptionValue.INCLUDING_CREDITS_TO_PV) {
      this.matter.extraDepositConfig.salePriceTotal = this.matter.getSalePriceIncludingCreditsToPV(this.matter.extraDepositConfig.salePriceTotalOption);
    }
  }

  private recalculateDepositAdjustment(interimBDOCAmount?: number) {

    if (this.matter.extraDepositConfig.depositOnOccupancyFlag
      && this.matter.extraDepositConfig.depositOnOccupancyCalBasedOn != DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA) {
      // D17 depends on D18, D21 and etc...
      this.recalculateDeferredPurchaseMonies();
      this.recalculateSalePriceTotal();
      this.recalculateDepositOnOccupancyAmount(interimBDOCAmount);
    } else if (this.projectOccupancyFeesCalBasedOn != OCCUPANCY_FEES_CALCULATED_BASED_ON.phantomMortgage && this.matter.extraDepositConfig.deferredPurchaseMoniesFlag) {
      // D18 depends on D17, D21 and etc...
      this.recalculateDepositOnOccupancyAmount(interimBDOCAmount);
      this.recalculateSalePriceTotal();
      this.recalculateDeferredPurchaseMonies();
    } else if (!this.matter.extraDepositConfig.depositOnOccupancyFlag && !this.matter.extraDepositConfig.deferredPurchaseMoniesFlag) {
      // D21 depends on D17, D18, and etc...
      this.recalculateDepositOnOccupancyAmount(interimBDOCAmount);
      this.recalculateDeferredPurchaseMonies();
      this.recalculateSalePriceTotal();
    } else {
      // no obvious dependency among computation for D17, D18 and D21
      this.recalculateDepositOnOccupancyAmount(interimBDOCAmount);
      this.recalculateDeferredPurchaseMonies();
      this.recalculateSalePriceTotal();
    }
  }

  recalculateDeferredPurchaseMonies(): void {
    if (this.isProjectOccupancyFeeBasedOnDeferredMoney() && this.matter.extraDepositConfig.deferredPurchaseMoniesFlag) {
      this.matter.extraDepositConfig.deferredPurchaseMoniesAmount = this.matter.calculateDeferredPurchaseMoniesAmount(this.matter.extraDepositConfig, this.projectOccupancyFeesCalBasedOn);
      this.matter.recalculateAllInterimOccupancyFee(this.project);
      this.matter.recalculateAllFinalEarlyPossessionFee();
    }
  }

  private isProjectOccupancyFeeBasedOnDeferredMoney(): boolean {
    return this.projectOccupancyFeesCalBasedOn === OCCUPANCY_FEES_CALCULATED_BASED_ON.deferredPurchaseMonies;
  }

  private recalculateDepositOnOccupancyAmount(interimBDOCAmount: number = 0.0): void {

    if (this.isInterim === false) {
      // this only apply to Final deposit
      if (this.matter.extraDepositConfig.depositOnOccupancyFlag) {
        if (this.matter.extraDepositConfig.depositOnOccupancyCalBasedOn == DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA) {
          // DPPMP-28748 - according to Dwayne:
          // this value should always be the Interim-side BDOC amount (obtained at modal opening, which does not change for the modal's life)
          this.matter.setOccupancyDepositAmount(interimBDOCAmount);

        } else {
          // 'unadjusted balance' is selected, use story's attached excel sheet
          this.matter.setOccupancyDepositAmount(this.matter.calculateDepositOnOccupancy(this.matter.extraDepositConfig, this.projectOccupancyFeesCalBasedOn));
        }
      }
    }
  }

  initVTBMortgageMonies(matter: Matter, projectOccupancyFeesCalBasedOn?: string): number { // project only
    let money = 0.0;
    if (!projectOccupancyFeesCalBasedOn) {
      const projectCondo = matter.project ? matter.project.projectCondo : null;
      projectOccupancyFeesCalBasedOn = projectCondo ? projectCondo.occupancyFeesCalculatedBasedOn : null;
    }
    if (projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.phantomMortgage) {
      const m1 = matter.getFirstPurchaserMortgage();
      money = m1 ? m1.mortgageTerm.principal : 0.0;

    } else if (projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.deferredPurchaseMonies) {
      const m2 = matter.getFirstVTBMortgage();
      money = m2 ? m2.mortgageTerm.principal : 0.0;
    } // else not specified in story, default to zero
    return Utils.toNumber(money);
  }
}
