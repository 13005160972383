import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {StatementOfAdjustmentPayable} from '../../model/statement-adjustment-payable';
import {ModalF9HelpComponent} from '../../../../shared/modal-f9-help/modal-f9-help.component';
import {Matter} from '../../../shared/matter';
import {DocumentProfile} from '../../../../admin/document-profile/document-profile';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import * as _ from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class SoaPayableToContext {
  payable: StatementOfAdjustmentPayable;
  matter: Matter;
  docProfile: DocumentProfile;
  defaultDocProfile: DocumentProfile;
  templateMatter: Matter;
}

@Component({
  selector: 'dp-soa-payable-to-modal-content',
  templateUrl: 'soa-payable-to.modal.component.html',
  styleUrls: ['./soa-payable-to.modal.component.scss']
})
export class SoaPayableToComponent extends ModalComponent<SoaPayableToContext> implements OnInit {
  isDirty: boolean = false;
  payableTo: StatementOfAdjustmentPayable;
  f9Value: string;

  @ViewChild('modalF9HelpComponent') modalF9HelpComponent: ModalF9HelpComponent;

  constructor(
    public dialog: MatDialogRef<SoaPayableToComponent>,
    @Inject(MAT_DIALOG_DATA) context?: SoaPayableToContext
  ) {
    super(dialog, context);
  }

  ok() {
    if (this.isDirty) {
      this.dialog.close({action: 'MODIFIED', payableTo: this.payableTo});
    } else {
      this.close();
    }
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    if (this.context.payable == undefined) {
      this.context.payable = new StatementOfAdjustmentPayable();
      this.payableTo = new StatementOfAdjustmentPayable();
      this.payableTo.adoptProjectSoaPayableTo = false;
    } else {
      this.payableTo = _.cloneDeep(this.context.payable);
      if (this.payableTo.adoptProjectSoaPayableTo == null) {
        this.payableTo.adoptProjectSoaPayableTo = false;
      }
    }
    this.f9Value = this.context.matter.getStatementOfAdjustmentPayableTo(
      this.context.docProfile,
      this.context.defaultDocProfile
    );
  }

  markDirty() {
    this.isDirty = true;
  }

  generateF9(): string {
    if (this.f9Value && this.f9Value.length > 0) {
      return 'F9 = ' + this.f9Value;
    }
    return '';
  }

  handleF9() {
    if (this.payableTo.payableTo !== this.f9Value) {
      this.payableTo.payableTo = this.f9Value;
      this.isDirty = true;
    }
  }

  toggleAdoptProjectSoaPayableTo() {
    this.payableTo.adoptProjectSoaPayableTo = !this.payableTo.adoptProjectSoaPayableTo;
    this.changePayableToProjectSettings();
  }

  changePayableToProjectSettings() {
    // when checkbox adoptProjectSoaPayableTo is checked we update the payableTo object from project template matter data
    // when checkbox adoptProjectSoaPayableTo is unchecked, we explicitly leave the data as is per story requirements
    if (this.payableTo.adoptProjectSoaPayableTo) {
      this.payableTo.payableTo = this.context.templateMatter.statementOfAdjustmentPayable.payableTo;
      this.payableTo.excludePrefix = this.context.templateMatter.statementOfAdjustmentPayable.excludePrefix;
      this.payableTo.excludeSuffix = this.context.templateMatter.statementOfAdjustmentPayable.excludeSuffix;
    }

    this.markDirty();
  }
}
