import {Matter} from '../matter';
import {EventData, EventType, TypeOfEventStatuseValue} from '../../../event/event-data';
import SharedUtils from '../../../shared-main/utils';
import Utils from '../../../shared-main/utils';
import {DpBooleanValueTypes} from '../dp-boolean';
import * as _ from 'lodash';
import {MatterSupplementalTaskField} from '../matter-supplemental-task-field';
import {MatterUndertaking} from '../matter-undertaking';
import {MatterSupplementalTaskCategory} from '../matter-supplemental-task-category';
import {CategoryFieldType} from '../../../shared-main/constants';
import {AdvanceMatterHoldbackConfig, HOLDBACKSTATUS} from '../advance-holdback/matter-holdback';
import {MatterWorkItemTask, WorkItemTaskStatusValues} from '../matter-work-item';
import {EventTypes} from '../../../event/event.constants';

export class MatterEventUtils {

  static createUpdateMatterEvents(matter: Matter) {

    MatterEventUtils.createMatterOpeningEvents(matter);
    MatterEventUtils.createUpdateUndertakingEvents(matter);
    MatterEventUtils.createUpdateSupplementalTaskEvents(matter);
    MatterEventUtils.refreshHolbackEvents(matter);
    MatterEventUtils.createUpdateWorkitemTaskEvents(matter);

    if (matter.matterEvents.length > 0) {
      MatterEventUtils.removeOrphanEvents(matter);
    }
  }

  static createMatterOpeningEvents(matter: Matter) {
    const closingDate: string = matter.matterCloseDate;
    const occupancyDate: string = matter.occupancyDate;
    const requisitionDate: string = matter.requisitionDate;

    MatterEventUtils.processMatterOpeningEvent(closingDate, 'CLOSING', matter);

    if (matter.isPurchase || matter.isProjectSale) {
      // OccupancyDate event has been extended to PURCHASE and Project Sale matters
      // for ON - Project Sale matter, requisitionDate is displayed as occupancyDate at Unity matterOpening page
      //For AB - Project Sale matter, occupancyDate is used at Unity matterOpening page.
      MatterEventUtils.processMatterOpeningEvent((matter.isProjectSale && matter.isMatterProvinceON ? requisitionDate : occupancyDate), 'OCCUPANCY', matter);
    }

    if (matter.isPurchase) {
      MatterEventUtils.processMatterOpeningEvent(requisitionDate, 'REQUISITION', matter);
    }
  }

  static createUpdateUndertakingEvents(matter: Matter) {
    matter.matterUndertakings.forEach(undertaking => {
      const existingEvent: EventData = MatterEventUtils.getExistingEvent(undertaking.id, 'UNDERTAKING', matter.matterEvents);
      if (existingEvent) {
        if (!undertaking.followupDate) {
          matter.matterEvents.splice(matter.matterEvents.indexOf(existingEvent), 1);
        }
        MatterEventUtils.updateEventFromUndertaking(existingEvent, undertaking);

      } else {
        matter.matterEvents.push(MatterEventUtils.createUndertakingEvent(undertaking));
      }
    });
  }

  static updateEventFromUndertaking(matterEvent: EventData, undertaking: MatterUndertaking) {
    if (undertaking.matterUndertakingStatus === 'OUTSTANDING') {
      matterEvent.eventStatus = 'PENDING';
    } else if (undertaking.matterUndertakingStatus === 'FULFILLED') {
      matterEvent.eventStatus = 'CLOSED';
    }
    matterEvent.startDate = undertaking.followupDate;
    matterEvent.setEventDescription(undertaking.subject);
  }

  static createUndertakingEvent(undertaking: MatterUndertaking) {
    const matterEvent: EventData = new EventData();
    MatterEventUtils.updateEventFromUndertaking(matterEvent, undertaking);
    matterEvent.eventType = EventTypes.UNDERTAKING;
    matterEvent.triggerId = undertaking.id;
    return matterEvent;
  }

  static getExistingEvent(triggerId: number, eventType: EventType, events: EventData[]): EventData {
    return events.find(event =>
      event.triggerId &&
      event.triggerId === triggerId &&
      event.eventType === eventType);
  }

  static createUpdateSupplementalTaskEvents(matter: Matter) {
    matter.supplementalTasks.forEach(suppTask => {
      suppTask.categoryFields
      .filter(categoryField => categoryField.fieldType === 'TICKLER_GROUP')
      .forEach(categoryField => {
        categoryField.subFields
        .filter(subfield => subfield.fieldType === 'TICKLER_DATE')
        .forEach(subField => {
          MatterEventUtils.processSupplementalTaskEvent(suppTask, categoryField, matter);
        });
      });
    });
  }

  private static processSupplementalTaskEvent(suppTask: MatterSupplementalTaskCategory, taskField: MatterSupplementalTaskField, matter: Matter) {
    const existingEvent: EventData = MatterEventUtils.getExistingEvent(suppTask.id, 'SUPPLEMENTAL', matter.matterEvents);
    const ticklerDate: string = MatterEventUtils.getFieldByType(taskField, 'TICKLER_DATE').fieldValue;

    if (existingEvent) {
      if (!ticklerDate) {
        matter.matterEvents.splice(matter.matterEvents.indexOf(existingEvent), 1);
      } else {
        MatterEventUtils.updateSupplementalEventStatusAndDescription(suppTask, taskField, existingEvent);
        existingEvent.startDate = MatterEventUtils.getFieldByType(taskField, 'TICKLER_DATE').fieldValue;
      }
    } else {
      matter.matterEvents.push(MatterEventUtils.createSupplementalTaskEvent(suppTask, taskField));
    }
  }

  static createSupplementalTaskEvent(suppTask: MatterSupplementalTaskCategory, taskField: MatterSupplementalTaskField): EventData {
    const matterEvent: EventData = new EventData();
    MatterEventUtils.updateSupplementalEventStatusAndDescription(suppTask, taskField, matterEvent);
    matterEvent.eventType = EventTypes.SUPPLEMENTAL_TASKS;
    matterEvent.triggerId = suppTask.id;
    matterEvent.startDate = MatterEventUtils.getFieldByType(taskField, 'TICKLER_DATE').fieldValue;

    return matterEvent;
  }

  static updateSupplementalEventStatusAndDescription(suppTask: MatterSupplementalTaskCategory, taskField: MatterSupplementalTaskField, matterEvent: EventData) {
    const completedField: MatterSupplementalTaskField = MatterEventUtils.getFieldByType(taskField, 'HAS_TASK_COMPLETED');
    if (completedField && completedField.fieldValue === 'YES') {
      matterEvent.eventStatus = 'CLOSED';
    } else {
      matterEvent.eventStatus = 'PENDING';
    }
    const ticklerNotes: string = MatterEventUtils.getFieldByType(taskField, 'TICKLER_NOTES').fieldValue;
    if (ticklerNotes) {
      matterEvent.setEventDescription(ticklerNotes);
    } else {
      matterEvent.setEventDescription(suppTask.categoryName);
    }
  }

  static getFieldByType(field: MatterSupplementalTaskField, categoryFieldType: CategoryFieldType): MatterSupplementalTaskField {
    return field && field.subFields && field.subFields.find(subField => subField.fieldType === categoryFieldType);
  }

  static refreshHolbackEvents(matter: Matter) {

    // advanceHoldbackEvent and otherHoldback share the same eventType, but advanceHoldbackEvent does not have triggerId (multiple Advance Holdback => only one Advance Holdback Event)
    let hbaEvent: EventData = matter.matterEvents.find(event => event.eventType == EventTypes.HOLDBACK && event.triggerId == null);
    let hboEvents: EventData[] = matter.matterEvents.filter(event => event.eventType == EventTypes.HOLDBACK && event.triggerId);

    let hbaEventNeeded: boolean = false;
    let neededHboEventsTriggerIds: number[] = [];

    if (matter.holdbacks && matter.holdbacks.length > 0 && matter.advanceMatterHoldbackConfig && matter.advanceMatterHoldbackConfig.releaseDate) {
      let advanceMatterHoldbackConfig: AdvanceMatterHoldbackConfig = matter.advanceMatterHoldbackConfig;

      if (matter.advanceHoldbacks && matter.advanceHoldbacks.length > 0) {
        let advanceHbReleaseDate: string = advanceMatterHoldbackConfig.releaseDate;
        let advanceHbReleaseStats: HOLDBACKSTATUS = advanceMatterHoldbackConfig.releaseStatus;
        // if advanceHoldback exists, and release date is full, then create one and only one advance holdback event
        if (Utils.isValidDate(advanceHbReleaseDate)) {
          // add/update the advance holdback event
          hbaEventNeeded = true;
          if (hbaEvent != null) {
            MatterEventUtils.updateAdvanceHoldbackEvent(hbaEvent, advanceHbReleaseDate, advanceHbReleaseStats);
          } else {
            matter.matterEvents.push(MatterEventUtils.createNewAdvanceHoldbackEvent(advanceHbReleaseDate, advanceHbReleaseStats));
          }
        }
      }
    }

    // remove the staled Advance Holdback Event
    if (!hbaEventNeeded && hbaEvent != null) {
      matter.matterEvents.splice(matter.matterEvents.indexOf(hbaEvent), 1);

    }

    if (matter.otherHoldbacks && matter.otherHoldbacks.length) {
      // create/update other holdback events
      matter.otherHoldbacks.forEach(hbo => {
        if (hbo != null && Utils.isValidDate(hbo.releaseDate)) {
          // add new other holdback event
          neededHboEventsTriggerIds.push(hbo.id);
          let hboEvent: EventData = hboEvents.find(event => hbo.id === event.triggerId);
          if (hboEvent != null) {
            MatterEventUtils.updateOtherHoldbackEvent(hboEvent, hbo.releaseDate, hbo.releaseStatus, hbo.otherHoldbackType);
          } else {
            matter.matterEvents.push(MatterEventUtils.createNewOtherHoldbackEvent(hbo.releaseDate, hbo.releaseStatus, hbo.otherHoldbackType, hbo.id));
          }
        }
      });
    }
    // remove the staled Other Holdback Events
    if (hboEvents && hboEvents.length > 0) {
      hboEvents.filter(hboEvent => neededHboEventsTriggerIds.indexOf(hboEvent.triggerId) < 0)
      .forEach(staledHboEvent => {
        matter.matterEvents.splice(matter.matterEvents.indexOf(staledHboEvent), 1);
      });
    }
  }

  static createNewAdvanceHoldbackEvent(releaseDate: string, releaseStatus: HOLDBACKSTATUS): EventData {
    const matterEvent: EventData = new EventData();
    matterEvent.eventDescription = 'Construction\\Builders Lien';
    if (releaseStatus === 'RELEASED') {
      matterEvent.eventStatus = 'COMPLETED';
    }
    matterEvent.eventType = EventTypes.HOLDBACK;
    matterEvent.startDate = releaseDate;
    return matterEvent;
  }

  static updateAdvanceHoldbackEvent(matterEvent: EventData, releaseDate: string, releaseStatus: HOLDBACKSTATUS) {
    if (releaseStatus === 'RELEASED') {
      matterEvent.eventStatus = 'COMPLETED';
    } else {
      matterEvent.eventStatus = null;
    }
    matterEvent.startDate = releaseDate;
  }

  static createNewOtherHoldbackEvent(releaseDate: string, releaseStatus: HOLDBACKSTATUS, otherHoldbackType: string, matterHoldbackId: number): EventData {
    const matterEvent: EventData = new EventData();
    matterEvent.setEventDescription(otherHoldbackType);
    if (releaseStatus === 'RELEASED') {
      matterEvent.eventStatus = 'COMPLETED';
    }
    matterEvent.eventType = EventTypes.HOLDBACK;
    matterEvent.startDate = releaseDate;
    matterEvent.triggerId = matterHoldbackId;
    return matterEvent;
  }

  static updateOtherHoldbackEvent(matterEvent: EventData, releaseDate: string, releaseStatus: HOLDBACKSTATUS, otherHoldbackType: string) {
    matterEvent.setEventDescription(otherHoldbackType);
    if (releaseStatus === 'RELEASED') {
      matterEvent.eventStatus = 'COMPLETED';
    } else {
      matterEvent.eventStatus = null;
    }
    matterEvent.startDate = releaseDate;
  }

  private static removeOrphanEvents(matter: Matter) {
    const undertakingIds: number[] = matter.matterUndertakings.map(undertaking => undertaking.id);
    const supplementalTaskIds: number[] = matter.supplementalTasks.map(task => task.id);
    let workItemTaskIds = this.getMatterWorkItemsTasks(matter).map(task => task.id);

    _.remove(matter.matterEvents, (event) => {
      return (event.eventType === EventTypes.UNDERTAKING && undertakingIds.indexOf(event.triggerId) < 0) ||
        (event.eventType === EventTypes.SUPPLEMENTAL_TASKS && supplementalTaskIds.indexOf(event.triggerId) < 0) ||
        (event.eventType === EventTypes.WORKITEMTASK && workItemTaskIds.indexOf(event.triggerId) < 0);
    });
  }

  /**
   * Creates/updates events based on the specified event type.
   */
  static processMatterOpeningEvent(date: string, eventType: EventType, matter: Matter) {
    const existingEvent: EventData = matter.matterEvents.find(event => event.eventType === eventType);

    if (!existingEvent) {
      if (SharedUtils.isFullDate(date)) {
        // Create event if it doesn't exist already
        let newEvent: EventData = MatterEventUtils.createMatterOpeningEvent(date, eventType, DpBooleanValueTypes.isTrue(matter.requisitionSubmitted));
        matter.matterEvents.push(newEvent);
        this.updateEventStatus(eventType, matter, newEvent);
      }
    } else {
      if (SharedUtils.isFullDate(date) && existingEvent.startDate !== date) {
        // Update due date if event already exists
        existingEvent.startDate = date;
      } else if (!SharedUtils.isFullDate(date)) {
        // If date is removed or partial, then remove any existing events
        (<any>matter.matterEvents).remove(existingEvent);
      }
      this.updateEventStatus(eventType, matter, existingEvent);
    }
  }

  static updateEventStatus(eventType: string, matter: Matter, eventData: EventData) {

    if (eventData) {
      // Set requisition event status to complete or pending
      if (eventType === EventTypes.REQUISITIONS) {
        if (eventData.eventStatus === 'PENDING' && DpBooleanValueTypes.isTrue(matter.requisitionSubmitted)) {
          eventData.eventStatus = 'COMPLETED';
        } else if (eventData.eventStatus === 'COMPLETED' && !DpBooleanValueTypes.isTrue(matter.requisitionSubmitted)) {
          eventData.eventStatus = 'PENDING';
        }
      }

      // Set closing event status to complete or pending
      if (eventType === EventTypes.CLOSING) {
        if (eventData.eventStatus === 'PENDING' && DpBooleanValueTypes.isTrue(matter.closed)) {
          eventData.eventStatus = 'COMPLETED';
        } else if (eventData.eventStatus === 'COMPLETED' && !DpBooleanValueTypes.isTrue(matter.closed)) {
          eventData.eventStatus = 'PENDING';
        }
      }
    }
  }

  static createMatterOpeningEvent(date: string, eventType: EventType, isRequisitionSubmitted: boolean): EventData {
    const matterEvent: EventData = new EventData();
    matterEvent.startDate = date;
    matterEvent.eventStatus = 'PENDING';
    if (eventType == EventTypes.REQUISITIONS && isRequisitionSubmitted) {
      matterEvent.eventStatus = 'COMPLETED';
    }
    matterEvent.eventType = eventType;
    matterEvent.setEventDescription(matterEvent.getDefaultEventDescription());
    return matterEvent;
  }

  static getMatterWorkItemsTasks(matter: Matter): MatterWorkItemTask[] {
    let workItemTasks = [];
    if (matter.matterWorkItems) {
      matter.matterWorkItems.forEach((workItem) => {
        workItemTasks = [ ...workItemTasks, ...workItem.matterWorkItemTasks ];
      });
    }
    return workItemTasks;
  }

  static createUpdateWorkitemTaskEvents(matter: Matter) {
    let workItemTasks = this.getMatterWorkItemsTasks(matter);
    workItemTasks.forEach((task) => {
      let existingEvent: EventData = MatterEventUtils.getExistingEvent(task.id, EventTypes.WORKITEMTASK, matter.matterEvents);
      if (existingEvent) {
        if (!task.dueDate || task.dueDate && !Utils.isValidDate(task.dueDateString)) {
          (<any>matter.matterEvents).remove(existingEvent);
        } else {
          MatterEventUtils.updateEventFromWorkitemTask(existingEvent, task);
        }
      } else {
        if (task.name && task.dueDate && Utils.isValidDate(task.dueDateString)) {
          matter.matterEvents.push(MatterEventUtils.createWorkitemTaskEvent(task));
        }
      }
    });
  }

  static updateEventFromWorkitemTask(matterEvent: EventData, task: MatterWorkItemTask) {
    if (task.status == WorkItemTaskStatusValues.completed) {
      matterEvent.eventStatus = TypeOfEventStatuseValue.COMPLETED;
    } else {
      matterEvent.eventStatus = TypeOfEventStatuseValue.PENDING;
    }
    matterEvent.startDate = task.dueDateString;
    matterEvent.setEventDescription(task.name);
    matterEvent.eventType = EventTypes.WORKITEMTASK;
    matterEvent.triggerId = task.id;
    if (!task.isAssignedToClient()) {
      matterEvent.scheduledForSolicitorId = task.assignedToParticipantId;
    }

  }

  static createWorkitemTaskEvent(task: MatterWorkItemTask) {
    const matterEvent: EventData = new EventData();
    MatterEventUtils.updateEventFromWorkitemTask(matterEvent, task);
    return matterEvent;
  }

}
