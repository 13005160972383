import {FocusFirstElementModalDecorator} from '../../shared-main/focus-first-element-modal-decorator';
import {Component, Inject, OnDestroy, ViewChild} from '@angular/core';
import {DocumentProductionService} from './document-production.service';
import {Observable} from 'rxjs/Observable';
import {ContentDialogConfig, DialogConfigParams, DialogService} from '../../shared/dialog/dialog.service';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {DocumentProductionData, MasterDocument, MultipleTemplatesDocument} from './document-production-data';
import {DPError} from '../../shared/error-handling/dp-error';
import {Document} from './document';
import {TemplateStopCode} from './template-stop-code';
import {StopCodesMultipleMatterModalComponent} from './stop-codes/stop-codes-multiple-matter-modal.component';
import {Matter} from '../shared/matter';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class DocumentTemplateMergeContext {
  selectedMatter: Matter;
  templateIdList: any[] = [];
  outputType: string;
  supplementalTaskCategoryId: number;
}

@FocusFirstElementModalDecorator()
@Component({
  selector: 'document-template-merge-content',
  templateUrl: './document-template-merge.component.html',
  providers: [ErrorService]
})
export class DocumentTemplateMergeComponent extends ModalComponent<DocumentTemplateMergeContext> implements OnDestroy {
  outputFileType: string = '.pdf / .docx / .wpd';
  fileName: string = '';
  documentGenerationStage: string = 'ORDER_TEMPLATES';
  generatedDocuments: any[] = [];
  templatesStopCode: TemplateStopCode[] = [];
  checkGenStatusIntervalID: any;
  activeListItem: number = -1;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  requestData: MultipleTemplatesDocument;

  constructor(
    public dialog: MatDialogRef<DocumentTemplateMergeComponent>,
    public documentProductionService: DocumentProductionService,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: DocumentTemplateMergeContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.outputType == 'WORD') {
      this.outputFileType = '.docx';
    } else if (this.context.outputType == 'PDF') {
      this.outputFileType = '.pdf';
    } else if (this.context.outputType == 'WORDPERFECT') {
      this.outputFileType = '.wpd';
    } else if (this.context.outputType == 'WORD_AND_PDF') {
      this.outputFileType = '.pdf / .docx';
    } else if (this.context.outputType == 'WORDPERFECT_AND_PDF') {
      this.outputFileType = '.pdf / .wpd';
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    window.clearInterval(this.checkGenStatusIntervalID);
  }

  get isDocumentOrderStage(): boolean {
    return this.documentGenerationStage == 'ORDER_TEMPLATES';
  }

  get isDocumentProduceStage(): boolean {
    return this.documentGenerationStage == 'PRODUCE';
  }

  get isDocumentGeneratedStage(): boolean {
    return this.documentGenerationStage == 'GENERATED';
  }

  get isDocumentFailedStage(): boolean {
    return this.documentGenerationStage == 'FAILED';
  }

  get templateIdList(): any[] {
    return this.context ? this.context.templateIdList : [];
  }

  cancel(): void {
    this.dialog.close();
  }

  ok(): void {
    this.dialog.close();
  }

  selectTemplate(template: any): void {
    this.templateIdList.map((item) => {
      item.active = false;
    });
    template.active = true;
  }

  setActiveIndex(index): void {
    this.activeListItem = index;
  }

  moveUp(): void {
    let activeTemplate: any = this.templateIdList.find((item) => item.active);
    (<any>this.templateIdList).moveUp(activeTemplate);
    this.selectTemplate(activeTemplate);
  }

  moveDown(): void {
    let activeTemplate: any = this.templateIdList.find((item) => item.active);
    (<any>this.templateIdList).moveDown(activeTemplate);
    this.selectTemplate(activeTemplate);
  }

  produce(): void {
    this.errorService.clearAllSaveErrors();
    this.templatesStopCode = [];
    if (!this.fileName || (this.fileName && this.fileName.trim() == '')) {
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(
          'matter.documentProduction.multipleTemplate.fileName',
          'File Name is required',
          '',
          'ERROR'
        )
      );
    } else {
      this.fileNameRemoveSpecialChars();
      this.checkDocuments().subscribe((documents) => {
        if (documents && documents.length > 0) {
          const contentModalConfig: DialogConfigParams = {
            title: 'Document Production',
            message:
              'The document ' + documents[0].documentName + ' already exist.  Do you want to replace the document?',
            hideCancelBtn: false,
            customConfirmBtnText: 'Replace'
          };
          this.dialogService.confirmCustomDialog(contentModalConfig).subscribe((res) => {
            if (res && this.context.outputType == 'PDF') {
              this.produceDocuments();
            } else if (res) {
              let document = documents.find((item) => item.isWordOrWordPerfect && item.isDocumentOpened);
              if (document) {
                let lockedMessage = this.documentProductionService.getDocLockedErrorMessage(document);
                this.dialogService.confirmDialog('Document Production Error', lockedMessage, true, 'OK');
              } else {
                this.produceDocuments();
              }
            }
          });
        } else {
          this.produceDocuments();
        }
      });
    }
  }

  produceDocuments(): void {
    let docTemplateIds = this.context.templateIdList.map((template) => {
      return template.templateIdWithMortgageOrAffidavitIndex;
    });

    let documentProductionData = DocumentProductionData.of(
      this.context.selectedMatter.id,
      docTemplateIds,
      this.context.outputType
    );
    let multipleTemplatesDocumentObj = new MultipleTemplatesDocument();
    multipleTemplatesDocumentObj.matterId = documentProductionData.matterId;
    multipleTemplatesDocumentObj.templates = documentProductionData.templates;
    multipleTemplatesDocumentObj.produceFileType = documentProductionData.produceFileType;
    multipleTemplatesDocumentObj.mergedDocumentName = this.fileName;
    multipleTemplatesDocumentObj.supplementalTaskCategoryId = this.context.supplementalTaskCategoryId;
    this.requestData = multipleTemplatesDocumentObj;
    this.sendDocumentProductionRequest();
  }

  sendDocumentProductionRequest(reqData?: MultipleTemplatesDocument): void {
    let request: MultipleTemplatesDocument = reqData ? reqData : this.requestData;
    this.documentProductionService
      .produceMultipleTemplateDocuments(request)
      .subscribe((multipleTemplatesDocument: MultipleTemplatesDocument) => {
        if (
          multipleTemplatesDocument &&
          multipleTemplatesDocument.templates &&
          multipleTemplatesDocument.templates.length > 0
        ) {
          const contentModalConfig: ContentDialogConfig = {
            content: StopCodesMultipleMatterModalComponent,
            context: {
              stopCodeData: [{documentProductionData: multipleTemplatesDocument}],
              matters: [this.context.selectedMatter]
            },
            onFulfillment: (result: any[]) => {
              if (result && result.length > 0) {
                // need to transfer stop code values to reqData
                let data = result[0].documentProductionData;
                data.templates.forEach((templateFromResponse) => {
                  let matchingTemplate = this.requestData.templates.find((t) =>
                    this.matchTemplate(t, templateFromResponse)
                  );
                  matchingTemplate.stopCodes = templateFromResponse.stopCodes;
                });

                this.sendDocumentProductionRequest(this.requestData);
              }
            }
          };
          this.dialogService.matDialogContent(contentModalConfig);
        } else if (multipleTemplatesDocument.masterMaterDocuments) {
          this.documentGenerationStage = 'PRODUCE';
          this.documentStatusPolling(multipleTemplatesDocument.masterMaterDocuments);
        }
      });
  }

  matchTemplate(template: TemplateStopCode, templateFromResponse: TemplateStopCode): boolean {
    if (template.templateId != templateFromResponse.templateId) {
      return false;
    }

    if (templateFromResponse.mortgageIndex != null) {
      return template.mortgageIndex === templateFromResponse.mortgageIndex;
    } else if (templateFromResponse.holdbackIndex != null) {
      return template.holdbackIndex === templateFromResponse.holdbackIndex;
    }

    return true;
  }

  openDocument(document: any, event): void {
    if (document && document.documentType == 'WORD') {
      this.openWordForEdit(document.matterDocumentId, document.documentName, event);
    } else if (document && document.documentType == 'WORDPERFECT') {
      this.openWordPerfectForEdit(document);
    } else if (document && document.documentType == 'PDF') {
      this.openPdfFile(document.matterDocumentId);
    }
  }

  openWordPerfectForEdit(doc: any): void {
    this.documentProductionService
      .getDocument(this.context.selectedMatter.id, String(doc.matterDocumentId))
      .subscribe((data) => {
        let isLockedByUser: boolean = data.isLockedByUser;
        if (isLockedByUser) {
          console.log('This document is already opened......');
          this.dialogService.confirmDialog(
            'Document Production Error',
            'Document ' +
              doc.documentName +
              ' is already open in Word Perfect. Please' +
              ' select' +
              ' document from task bar.',
            true,
            'OK'
          );
        } else {
          this.documentProductionService.openDocumentForEdit(
            String(doc.matterDocumentId),
            doc.documentName,
            'WORDPERFECT'
          );
        }
      });
  }

  documentStatusPolling(documentTypes: MasterDocument[]): void {
    let documentStatusCalls = [];
    this.generatedDocuments = [];
    documentTypes.forEach((item) => {
      documentStatusCalls.push(() => {
        return this.documentProductionService.getAccountDocumentStatus(item.masterDocumentId);
      });
      this.generatedDocuments.push({
        documentName:
          this.fileName + (item.fileType == 'WORD' ? '.docx' : item.fileType == 'WORDPERFECT' ? '.wpd' : '.pdf'),
        documentType: item.fileType,
        matterDocumentId: item.masterDocumentId
      });
    }, this);
    this.checkGenStatusIntervalID = window.setInterval(() => {
      Observable.forkJoin(documentStatusCalls.map((it) => it())).subscribe((documentStatusList) => {
        if (documentStatusList) {
          let documentStatus = documentStatusList.find((item) => item !== 'COMPLETED' && item !== 'FAILED');
          if (!documentStatus) {
            window.clearInterval(this.checkGenStatusIntervalID);
            this.documentGenerationStage = documentStatusList.indexOf('FAILED') > -1 ? 'FAILED' : 'GENERATED';
          }
        }
      });
    }, 2000);
  }

  checkDocuments(): Observable<Document[]> {
    return this.documentProductionService.getDocuments(this.context.selectedMatter.id).map((data) => {
      if (data) {
        let filterList = [];
        if (this.context.outputType == 'WORD' || this.context.outputType == 'WORD_AND_PDF') {
          filterList.push(this.fileName + '.docx');
        }
        if (this.context.outputType == 'WORDPERFECT' || this.context.outputType == 'WORDPERFECT_AND_PDF') {
          filterList.push(this.fileName + '.wpd');
        }
        if (
          this.context.outputType == 'PDF' ||
          this.context.outputType == 'WORD_AND_PDF' ||
          this.context.outputType == 'WORDPERFECT_AND_PDF'
        ) {
          filterList.push(this.fileName + '.pdf');
        }

        // if document has FAILED state documentName === null. That's why we must do null checking
        return data.filter(
          (item) =>
            filterList.findIndex(
              (i) => item.documentName != null && item.documentName.toLowerCase() === i.toLowerCase()
            ) > -1
        );
      } else {
        return [];
      }
    }, this);
  }

  fileNameRemoveSpecialChars(): void {
    const regex = /[\\\<\>\:\"\/\|\?\*]/g;
    if (this.fileName) {
      this.fileName = this.fileName.replace(regex, '').trim();
    }
  }

  delete(): void {
    let templateId: any = this.templateIdList.find((item) => item.active);
    if (templateId) {
      this.dialogService
        .confirm('Confirmation', 'Are you sure you would like to delete this item?', false, 'Delete')
        .subscribe((res) => {
          if (res) {
            (<any>this.templateIdList).remove(templateId);
          }
        });
    }
  }

  openWordForEdit(documentId: string, documentName: string, event): void {
    this.documentProductionService.getDocument(this.context.selectedMatter.id, documentId).subscribe((data) => {
      let isLockedByUser: boolean = data.isLockedByUser;
      if (isLockedByUser) {
        let documentType: string = this.isWordPerfectFile(documentName) ? 'Word Perfect' : 'MS-Word';
        this.dialogService.confirmDialog(
          'Document Production Error',
          'Document ' +
            documentName +
            ' is already open in ' +
            documentType +
            '. Please select' +
            ' document from task bar.',
          true,
          'OK'
        );
      } else {
        this.documentProductionService.openDocumentForEdit(documentId, documentName);
      }
    });
    event.stopPropagation();
    event.preventDefault();
  }

  openPdfFile(doc: any) {
    this.documentProductionService.downloadFileInNewWindow(this.context.selectedMatter.id, Number(doc));
  }

  isWordPerfectFile(filename: string) {
    return filename && filename.toLowerCase().endsWith('.wpd');
  }

  ngAfterViewInit() {}
}
