import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '../../core/httpClient.service';
import {LabelValue} from './label-value';

@Component({
  selector: 'dp-is-multi-select',
  templateUrl: './is-multi-select.component.html'
})
export class DPPMInfiniteScrollMultiSelectComponent implements OnInit {

  @Input('multiSelectData') multiSelectData: LabelValue[];

  // any pre-selected values to be initially checked
  @Input('multiSelectDataSelected') multiSelectDataSelected: string[];

  // root name and id for checkbox box inputs and labels with appended index value
  @Input('inputName') inputName: string;

  // display name shown in the multi-select
  @Input('title') title: string;

  // display values shown in the multi-select in the title
  @Input('showLabelInTitle') showLabelInTitle: boolean;

  @Input('url') url: string;

  @Input('resultMapKey') resultMapKey: string;

  @Input('resultConverter') resultConverter: (o: any) => LabelValue;

  // event to emit the selected values
  @Output() updateMultiSelect = new EventEmitter();

  selectedValues: string[] = [];

  public pageSubscription: Subscription;
  public pageNo: number = 1;
  public pageSize: number = 20;
  public listEndReached: boolean = false;
  public loading: boolean = false;

  constructor(public http: HttpClient) {
  }

  ngOnInit(): void {
    for (let i = 0; i < this.multiSelectDataSelected.length; i++) {
      this.selectedValues.push(this.multiSelectDataSelected[ i ]);
    }
    if (this.selectedValues.length < 1) {
      this.selectedValues.push(this.multiSelectData[ 0 ].value);
    }
    this.page();
  }

  dataFilter(n): void {
    if (!this.isSelected(n)) {
      /// if  not already selected we add it to list
      if (n === 'ALL') {
        this.selectedValues = [];
      }

      if (this.isSelected('ALL')) {
        this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === 'ALL'), 1);
      }

      this.selectedValues.push(n);

    } else {
      // if  selected then we remove it from list
      this.selectedValues.splice(this.selectedValues.findIndex((m: string) => m === n), 1);
    }
    this.updateMultiSelect.emit(this.selectedValues);
    // console.log(this.selectedValues);
  }

  isSelected(n: string): boolean {
    return !!this.selectedValues.find((m: string) => m === n);
  }

  page(): void {
    if (this.pageSubscription) {
      this.pageSubscription.unsubscribe();
      this.loading = false;
    }
    this.loading = true;
    this.pageSubscription = this.http.get(`${ this.url }&page=${ this.pageNo }&per_page=${ this.pageSize }`)
    .map(res => res[ this.resultMapKey ])
    .subscribe((data: any[]) => {
      data.forEach(o => {
        this.multiSelectData.push(this.resultConverter(o));
      });

      if (data != undefined) {
        if (data.length < this.pageSize) {
          this.listEndReached = true;
        } else {
          this.listEndReached = false;
          this.incrementPage();
        }
      } else {
        this.listEndReached = true;
      }

      this.loading = false;

    });
  }

  getNextPage(): void {
    if (!this.listEndReached) {
      this.page();
    }
  }

  incrementPage(): void {
    this.pageNo = this.pageNo + 1;
  }

}
