
<div class="" tabindex="0">
    <div class="col-lg-12">
        <div class="form-horizontal">
            <div class="form-group" >
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Details</label>
                </div>
                <div class="col-lg-10">
                    <input type="text" class="form-control" id="soa_details" name="soa_details"
                           [(ngModel)]="matterSupplementalTask.detailField.fieldValue"
                           maxlength="200"
                           statusBarHelp
                    >
                </div>
            </div>

            <div class="form-group" >
                <div  class="col-lg-6 padding-top-10 padding-bottom-5" >
                    <ng-container *dpShowByProvince="'matter.stc.showPaymentAmounts';code:currentMatter.provinceCode">
                        <dp-checkbox fieldId="showPaymentAmounts"><input type="checkbox"
                                                                         id="showPaymentAmounts"
                                                                         name="showPaymentAmounts"
                                                                         [(ngModel)]="matterSupplementalTask.showPaymentAmounts"
                                                                         t></dp-checkbox>

                        <label class="control-label vertical-align-label display-inline" >Check to show payment amounts</label>
                    </ng-container>
                </div>

                <div class="col-lg-2 text-right">
                    <ng-container *ngIf="showAmountField">
                        <label class="control-label" [dp-province-field-label]="'matter.stc.balanceDueOnClosingTitle'">Balance Due on Closing</label>
                    </ng-container>
                </div>
                <div class="col-lg-4">
                    <ng-container *ngIf="showAmountField">
                        <input type="text" class="form-control"
                               dp-currency
                               dp-default-currency
                               allow-negative="true"
                               [(ngModel)]="balanceDueOnClosingField.fieldValue"
                               id="balanceDueOnClosing"
                               name="balanceDueOnClosing">
                    </ng-container>
                </div>

            </div>
            <div class="form-group col-lg-10">
                <label class="control-label">Balance of funds payable to:</label>
            </div>

            <ng-container *ngFor="let field of directionRefundsCategoryFields; let i=index;">
                <div class="form-group col-lg-10" *ngIf="i === 1">
                    <label class="control-label">Funds payable to:</label>
                </div>
                <div class="form-group">
                    <div class="col-lg-6">
                        <div class="padding-table"  *ngIf="i === 0">
                            <input type="text"
                                   trimmed
                                   statusBarHelp
                                   [dynamicHelpCallBackFunction] = "generateF9HelpBalancePayableTo(field)"
                                   (keydown.f9)="handleF9BalancePayableTo(field)"
                                   [(ngModel)]="field.fieldLabel"
                                   maxlength="200"
                                   name="fundsPayableTo-{{i}}"
                                   class="form-control"
                            >
                        </div>
                        <div class="padding-table"  *ngIf="i > 0">
                            <input type="text"
                                   trimmed
                                   [(ngModel)]="field.fieldLabel"
                                   id="fundsPayableTo-{{i}}"
                                   name="fundsPayableTo-{{i}}"
                                   maxlength="200"
                                   (blur)="fieldValueBlur(field)"
                                   class="form-control"
                            >
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div  class="width-20 matter-component" *ngIf="i === 0">
                            <li style="display:inline-block;float: none;margin-right: 5px;">
                                <dp-dropdown-menu [dropDownItems]="burgerBalanceMenuItems"
                                                  (itemClicked)="populateCategoryField($event, field, false)"
                                                  [buttonLabel]=""
                                                  [openSubMenuOnLeft]="false"
                                                  [addFocus]="true"
                                                  [menuSize]="[1.5, 1.5]"></dp-dropdown-menu>
                            </li>

                        </div>
                        <div  class="width-20 matter-component" *ngIf="i > 0">
                            <li style="display:inline-block;float: none;margin-right: 5px;">
                                <dp-dropdown-menu [dropDownItems]="burgerMenuItems"
                                                  (itemClicked)="populateCategoryField($event, field,  true)"
                                                  [buttonLabel]=""
                                                  [openSubMenuOnLeft]="false"
                                                  [addFocus]="true"
                                                  [menuSize]="[1.5, 1.5]"></dp-dropdown-menu>
                            </li>

                        </div>
                    </div>
                    <div class="col-lg-4">
                        <ng-container *ngIf="showAmountField">
                        <div class="padding-table" *ngIf="i === 0" >
                        <input type="text"
                                [ngModel]="calculateBalancePayableTo()"
                                id="amount-{{i}}"
                                name="amount-{{i}}"
                                [dp-read-only]= true
                                [allow-negative]="true"
                                dp-currency
                                class="form-control"
                        >
                        </div>
                        <div class="padding-table" *ngIf="i > 0" >
                            <input type="text"
                                   [(ngModel)]="field.fieldValue"
                                   id="amounti-{{i}}"
                                   name="amounti-{{i}}"
                                   dp-currency
                                   allow-negative="true"
                                   (blur)="fieldValueBlur(field)"
                                   class="form-control"
                            >
                        </div>
                        </ng-container>
                    </div>

                </div>
            </ng-container>

            <div class="form-group padding-bottom-100" *ngIf="directionRefundsCategoryFields.length < 21">
                <div class="col-lg-2">
                    <span class="actionlink">
                        <span>
                            <a href="javascript:void(0);" (click)="createDirectionRefundsField('DIRECTION_REFUNDS_BALANCE_PAYABLE')">
                                Add Another
                            </a>
                        </span>
                        <span class="selector fa fa-plus-square-o"></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
