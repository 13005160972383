import {Component, Inject} from '@angular/core';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {FieldCodeService} from '../../shared-main/field-code.service';
import {FieldCodeMapping, FieldMetaData} from '../../matters/shared/field-code-mapping';
import {DialogService} from '../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class TeranetChangeFieldCodeContext {
  fieldMetaData: FieldMetaData;
  fieldCodeService: FieldCodeService;
}

@Component({
  selector: 'dp-teranet-change-field-code-modal',
  templateUrl: './teranet-change-field-code.modal.component.html'
})
@FocusFirstElementDecorator()
export class TeranetChangeFieldCodeModal extends ModalComponent<TeranetChangeFieldCodeContext> {
  fieldCode: string;

  constructor(
    public dialog: MatDialogRef<TeranetChangeFieldCodeModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetChangeFieldCodeContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {}

  ok(): void {
    if (this.fieldCode && this.context.fieldMetaData) {
      let fieldCode = this.context.fieldCodeService.getFieldCode(this.context.fieldMetaData);
      let fieldCodeMapping;
      if (fieldCode) {
        fieldCodeMapping = this.context.fieldCodeService.fieldCodeMappings.find((item) => item.fieldCode == fieldCode);
        this.dialogService
          .confirm('ERROR', 'Field Code mapping already exist , Do you want to override it? ', false, null, null, true)
          .subscribe((res) => {
            if (res) {
              this.updateFeildCodeMapping(fieldCodeMapping);
            }
          });
      } else {
        fieldCodeMapping = this.context.fieldCodeService.fieldCodeMappings.find(
          (item) => item.fieldCode == this.fieldCode
        );
        if (fieldCodeMapping) {
          this.dialogService
            .confirm(
              'ERROR',
              'Field Code is already mapped to some other element , Do you want to override it? ',
              false,
              null,
              null,
              true
            )
            .subscribe((res) => {
              if (res) {
                this.updateFeildCodeMapping();
              }
            });
        } else {
          this.updateFeildCodeMapping();
        }
      }
    } else {
      this.dialog.close();
    }
  }

  updateFeildCodeMapping(fieldCodeMapping?: FieldCodeMapping): void {
    if (!fieldCodeMapping) {
      fieldCodeMapping = new FieldCodeMapping();
    }
    let fieldCodeMappings = [];
    fieldCodeMapping.fieldCode = this.fieldCode;
    fieldCodeMapping.fieldMetaData = this.context.fieldMetaData;
    fieldCodeMapping.fieldMetaDataJson = JSON.stringify(fieldCodeMapping.fieldMetaData);
    fieldCodeMappings.push(fieldCodeMapping);
    this.context.fieldCodeService
      .updateFieldCodeMapping(fieldCodeMappings)
      .finally(() => {
        this.dialog.close();
      })
      .subscribe();
  }

  close(): void {
    this.dialog.close();
  }
}
