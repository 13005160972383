<form class="form-horizontal" id="courierInstrumentForm" #courierInstrumentForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect &trade;: Add Instruments to Courier Request</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="onCancel()">&times;</button>
            </div>

        </div>

        <div class="row modal-body">

            <div class="col-sm-12">
                <table class="table courier-instrument-table" tabindex="0" (keydown)="onKeyPress($event)">
                    <thead class="z-index-1 fixed-position margin-top-minus-35">
                    <tr>
                        <th class="width-20-percent">Instrument No.</th>
                        <th class="width-20-percent">Date</th>
                        <th class="width-10-percent">Pages</th>
                        <th class="width-50-percent">Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let inst of instrumentOptions; let i=index;" [attr.tabindex]="-1" (click)="selectInstrument(i)"
                        id="instrumentArray{{i}}" [ngClass]="{'active': isRowSelected(i)}">
                        <td class="width-20-percent">{{inst.instrumentNumber}}</td>
                        <td class="width-20-percent">
                            {{inst.instrumentDate}}
                        </td>
                        <td class="width-10-percent">
                            {{inst.instrumentDocumentPageCount}}
                        </td>
                        <td class="width-50-percent">
                            {{inst.typeWithPartyTo}}
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </div>

        <div class="row modal-footer ">
            <button class="dp-btn" type="button" (click)="proceed()" [disabled]="instrumentOptions.length==0">
                Select
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="onCancel()">
                Cancel
            </button>
        </div>
    </div>
</form>


