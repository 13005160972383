import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type FileUseType = 'LAW_FIRM_LOGO' | 'USER_PROFILE_PIC';

export class CustomerFile extends BaseEntity {

  id: number;

  customerAccountId: number;

  uploadedFileName: string;

  fileTitle: string;

  fileUseType: FileUseType;

}
