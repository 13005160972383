<!-- modal -->
<form class="form-horizontal" id="dp-teraview" *ngIf="teraviewConfig">
    <div class="modal-header margin-bottom-20">
         <div class="col-lg-12">
             <div class="col-lg-10">
                 <h1>Configure Teraview {{account? '- ' + account.publicAccountId + ' ' + account.name : ''}}</h1>
             </div>
             <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
         </div>
     </div>

    <form class="form-horizontal padding-left-20">
        <mat-tab-group>
            <mat-tab label="Docket ID" *ngIf="!isPartOfProject">
                <div class="modal-body">
                    <div class="form-group padding-top-5  padding-bottom-10">
                        <div class="col-lg-12">
                            <label class="control-label"> When saving a transaction record the Teraview Docket ID field in "File Opening" Tab is blank:</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.docketIdType = 'BLANK'">
                                  <input type="radio" [checked]="teraviewConfig.docketIdType == 'BLANK'">
                                  <label>Do nothing and leave it blank</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.docketIdType = 'MATTER_NO'">
                                  <input type="radio" [checked]="teraviewConfig.docketIdType == 'MATTER_NO'">
                                  <label>Insert the Matter No. into the Teraview Docket ID field (10 Characters maximum)</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.docketIdType = 'FILE_NO'">
                                  <input type="radio" [checked]="teraviewConfig.docketIdType == 'FILE_NO'">
                                  <label>Insert the File No. into the Teraview Docket ID field (10 Characters maximum)</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.docketIdType = 'PSM_WITH_MATTER_NO'">
                                  <input type="radio" [checked]="teraviewConfig.docketIdType == 'PSM_WITH_MATTER_NO'">
                                  <label>Insert P, S or M plus the Matter No. into the Teraview Docket ID field (10 Characters maximum)</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.docketIdType = 'PSM_WITH_FILE_NO'">
                                  <input type="radio" [checked]="teraviewConfig.docketIdType == 'PSM_WITH_FILE_NO'">
                                  <label>Insert P, S or M plus the File No. into the Teraview Docket ID field (10 Characters maximum)</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group padding-top-10">
                        <div class="col-lg-12">
                            <p class="alert alert-warning error padding1rem margin-bottom-0">
                                <label class="control-label text-left padding-top-0" style="line-height: 16px;"> <i class="fa fa-exclamation-triangle"
                                                                                                                    aria-hidden="true"></i> Note: This setting does not apply
                                    to
                                    the Projects module.<br> <span style="padding-left:50px;">For Projects there is one
                                    option only (Insert File No. into Teraview Docket ID field ) which can be enabled from within the Project record.</span>
                                    </label>
                            </p>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Export Document Name">
                <div class="modal-body">
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.documentNameType = 'DEFAULT'">
                                  <input type="radio" [checked]="teraviewConfig.documentNameType == 'DEFAULT'">
                                  <label>Use default (matter no. and document type e.g 1022-22 Transfer)</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.documentNameType = 'CUSTOM'">
                                  <input type="radio" [checked]="teraviewConfig.documentNameType == 'CUSTOM'">
                                  <label>Define custom document name</label>
                                 </span>
                        </div>
                    </div>

                    <div *ngIf="teraviewConfig.documentNameType == 'CUSTOM'">
                        <div class="form-group col-lg-12">
                            <label class="control-label">Choose the fields you wish to appear in the Document Name - then re-arrange in the desired
                                sequence</label>
                        </div>
                        <div class="form-group col-xs-12 col-lg-5 margin-left-10">
                            <div class="control-label col-xs-12 padding-bottom-20 text-left" for="notInGroupSelect">Available Fields</div>
                            <div class="col-xs-12">

                                <select size="7" class="form-control background-none" id="notInGroupSelect" name="notInGroupSelect"
                                        [(ngModel)]="selectedUserIDArr[0]">
                                    <option *ngFor="let input of availableGroupList;"
                                            [value]="input">{{input}}
                                    </option>
                                </select>

                            </div>
                        </div>

                        <div class="form-group col-xs-12 col-lg-2 arrowDiv" style="padding-top : 60px;">

                            <div class="col-xs-12 text-center ">
                                <button class="dp-btn dp-btn-cancel dp-btn-small" type="button"
                                        (click)="moveItemRight()"
                                        [disabled]="!selectedUserIDArr[0]">
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="col-xs-12 text-center padding-top-10">
                                <button class="dp-btn dp-btn-cancel dp-btn-small" type="button"
                                        (click)="moveItemLeft()"
                                        [disabled]="!selectedUserIDArr[1]">
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                </button>
                            </div>


                        </div>

                        <div class="form-group col-xs-12 col-lg-5">
                            <div class="control-label col-xs-3 padding-bottom-20  text-left" for="inGroupSelect">Selected Fields</div>
                            <div class="col-xs-9 text-right">

                                <input
                                        type="button"
                                        class="action primary-button"
                                        [disabled]="selectedGroupList.length == 0 || currentSelectedIndex == 0 || currentSelectedIndex < 0"
                                        (click)="moveUp()"
                                        value="Move Up">
                                <input
                                        type="button"
                                        class="action primary-button"
                                        [disabled]="selectedGroupList.length == 0 || currentSelectedIndex == (selectedGroupList.length - 1) || currentSelectedIndex < 0"
                                        (click)="moveDown()"
                                        value="Move Down">

                            </div>
                            <div class="col-xs-12">

                                <select size="7" class="form-control background-none" id="inGroupSelect" name="inGroupSelect"
                                        [(ngModel)]="selectedUserIDArr[1]">
                                    <option *ngFor="let selected of selectedGroupList;"
                                            [value]="selected">{{selected}}
                                    </option>
                                </select>

                            </div>
                        </div>
                        <div class="form-group padding-top-10">
                            <div class="col-lg-12">
                                <p class="alert alert-warning error padding1rem margin-bottom-0">
                                    <label class="control-label text-left padding-top-0" style="line-height: 16px;"> <i class="fa fa-exclamation-triangle"
                                                                                                                        aria-hidden="true"></i> Note: Teraview's document name
                                        field is limited to a maximum of 40 characters <br> <span style="padding-left:50px;">If the limit is exceeded then Unity
                                        will truncate the
                                    field at 40
                                        characters.</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-tab>
            <mat-tab label="Acknowledgement & Direction Legal Description" *ngIf="!isPartOfProject">
                <div class="modal-body">
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.legalDescriptionType = 'PARCEL_REGISTER'">
                                  <input type="radio" [checked]="teraviewConfig.legalDescriptionType == 'PARCEL_REGISTER'">
                                  <label>The legal description contained in the Parcel Register</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="teraviewConfig.legalDescriptionType = 'PROPERTY_TERANET'">
                                  <input type="radio" [checked]="teraviewConfig.legalDescriptionType == 'PROPERTY_TERANET'">
                                  <label>The legal description as entered in "Property/Teranet"</label>
                                 </span>
                        </div>
                    </div>
                    <div class="form-group padding-top-10">
                        <div class="col-lg-12">
                            <p class="alert alert-warning error padding1rem margin-bottom-0">
                                <label class="control-label text-left padding-top-0" style="line-height: 16px;"> <i class="fa fa-exclamation-triangle"
                                                                                                                    aria-hidden="true"></i> Note: The setting does not
                                    apply to the Projects module <br> <span style="padding-left:50px;">For Projects, the default is always the legal description as entered in "Property/Teranet".</span>
                                    </label>
                            </p>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn">
                <span>{{okBtnLabel}}</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</form>
