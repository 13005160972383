import {Component, Input, OnInit} from '@angular/core';
import {Matter} from '../shared/matter';
import {MatterTab} from '../matter-tab';
import {TabsService} from '../../core';
import {ChecklistTemplateConfig} from '../../admin/checklist-templates/checklist-template-config';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {MatterWorkItemsUtilsService} from './matter-work-items-utils.service';
import * as _ from 'lodash';
import {
  AssignedTaskStatusOptions,
  MatterWorkItem,
  MatterWorkItemTask,
  TaskStatusOptions,
  WorkItemTaskStatusValues
} from '../shared/matter-work-item';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import moment from 'moment';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {MatterParticipant, User} from '../shared';
import {Utils as MoreUtils} from '../../matters/shared/utils';
import Utils from '../../shared-main/utils';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {Subscription} from 'rxjs';
import {AssignedTaskModalComponent} from '../../../app/matters/matter-overview/assigned-task-modal.component';
import {EmailData, EmailFieldService, EmailKeys, EmailTypes} from '../../../app/shared-main/email-field/email-field-service';
import {ErrorValidator} from '../../../app/shared-main/error-validator';
import {UUIDUtil} from '../../../app/main/uuid-util';
import {CirfHelperService} from '../shared/cirf/cirf-helper.service';
import {MatterParticipantInfo, MatterParticipantUtil} from '../shared/matter-utils/matter-participant-util';
import {
  MatterNotificationConfigModalComponent
} from '../../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config.modal.component';
import {Account} from '../../admin/accounts/shared/account';
import {AccountService} from '../../admin/accounts/account.service';
import {ChecklistTemplateService} from '../../admin/checklist-templates/checklist-template.service';

declare var jQuery: any;

@Component({

  selector: 'dp-matter-work-items',
  styleUrls: [
    './matter-work-items.component.scss'
  ],
  templateUrl: 'matter-work-items.component.html'
})

export class MatterWorkItemsComponent implements OnInit {
  _matter: Matter;
  availableCheckListTemplates: ChecklistTemplateConfig[] = [];
  categoryBurgerMenuOptions: BurgerMenuExtendedItem[] = [];
  taskBurgerMenuOptions: BurgerMenuExtendedItem[] = [];
  taskStatusOptions: SelectItem[];
  assignedTaskStatusOptions: SelectItem[];
  staffProfiles: StaffProfiles[] = [];
  moveTextKeys = {
    moveUp: 'MOVE_UP',
    moveDown: 'MOVE_Down'
  };
  yesNoItems: SelectItem[] = [
    {value: 'YES', label: 'Yes'},
    {value: 'NO', label: 'No'}
  ];
  loggedInUserInitials: string;

  @Input() usedInModal: boolean = false;
  @Input() modalMatter: Matter;
  @Input() matterParticipant: MatterParticipant;
  account: Account;

  constructor(public tabsService: TabsService,
              public dialogService: DialogService,
              public errorService: ErrorService,
              public staffProfilesService: StaffProfilesService,
              public matterWorkItemsUtilsService: MatterWorkItemsUtilsService,
              public emailFieldService: EmailFieldService,
              public checklistService: ChecklistTemplateService,
              public cirfHelperService: CirfHelperService, public accountService: AccountService) {

  }

  async ngOnInit() {
    this.buildCategoryBurgerMenu();
    this.buildTaskBurgerMenu();
    this.taskStatusOptions = TaskStatusOptions;
    this.assignedTaskStatusOptions = AssignedTaskStatusOptions;
    this.loadStaffProfiles();

    let account = await this.accountService.getShallowAccount(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId).toString()).toPromise();
    this.account = new Account(account);

    // let checklistTemplate = await this.checklistService.getChecklistTemplateById(this.account.id, this.matter.appliedChecklistTemplateConfigId).toPromise();
    // this.matter.matterWorkItems = this.matterWorkItemsUtilsService.buildWorkItemsFromChecklistTemplate(checklistTemplate);
    //
    // this.updateMatterWorkItemsShutters();
  }

  async loadStaffProfiles(): Promise<void> {
    this.staffProfiles = [];
    const id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.staffProfiles = await this.staffProfilesService.getStaffProfiles(id).toPromise();
  }

  buildCategoryBurgerMenu(): void {
    BurgerMenuExtendedItem.createMenuItem(this.categoryBurgerMenuOptions, 'ADD_TASK', 'Add Task to Category', this.addTask, false, null);
    BurgerMenuExtendedItem.createMenuItem(this.categoryBurgerMenuOptions, 'ASSIGN_ALL_TASK', 'Assign All Tasks in Category', this.assignAllTask, false, null, true);
    if (!this.usedInModal) {
      BurgerMenuExtendedItem.createMenuItem(this.categoryBurgerMenuOptions, 'DELETE', 'Delete Category', this.deleteCategory);
    }
  }

  buildTaskBurgerMenu(): void {
    BurgerMenuExtendedItem.createMenuItem(this.taskBurgerMenuOptions, 'ASSIGN_TASK', 'Assign Task', this.assignTask, false, null);
    BurgerMenuExtendedItem.createMenuItem(this.taskBurgerMenuOptions, 'UN_ASSIGN_TASK', 'Unassign Task', this.unAssignTask, true, null, true);
    if (!this.usedInModal) {
      BurgerMenuExtendedItem.createMenuItem(this.taskBurgerMenuOptions, 'CONFIGURE_TASK', 'Configure Correspondence', this.configureTask, false, null, true);
      BurgerMenuExtendedItem.createMenuItem(this.taskBurgerMenuOptions, 'DELETE', 'Delete Task', this.deleteTask);
    }
  }

  get activeMatterTab(): MatterTab {
    return this.tabsService && this.tabsService.activeTab as MatterTab;
  }

  get matter(): Matter {
    const matter = this.activeMatterTab && this.activeMatterTab.matter;
    if (this.usedInModal) {
      return this.modalMatter ? this.modalMatter : matter;
    } else {
      if (matter != this._matter) {
        this._matter = matter;
        this.initializeComponent();
      }
      return matter;
    }
  }

  async initializeComponent(): Promise<void> {
    if (this.matter && !this.matter.isOpportunityMatter()) {
      this.availableCheckListTemplates = await this.matterWorkItemsUtilsService.getAllChecklistTemplates(this.matter);
    }
    this.getLoggedInUserStaffProfile();
    this.initiateEmailFieldService();

  }

  getLoggedInUserStaffProfile(): void {
    this.staffProfilesService.getLoggedInStaffProfile().subscribe(
      (staffProfiles: StaffProfiles) => {
        if (staffProfiles && staffProfiles.contact) {
          this.loggedInUserInitials = staffProfiles.contact.initials;
        }
      });
  }

  initiateEmailFieldService(): void {
    this.emailFieldService.matter = this.matter;
    this.emailFieldService.key = EmailKeys.matterOpening;
  }

  updateMatterWorkItemsShutters(): void {
    if (this.matter && this.matter.matterWorkItems) {
      this.matter.matterWorkItems.forEach((workItem) => {
        workItem.expanded = workItem.numberOfTasks != workItem.numberOfCompletedTasks;
        if (workItem.matterWorkItemTasks) {
          workItem.matterWorkItemTasks.forEach((task) => {
            task.visible = workItem.expanded;
          });
        }
      });
    }
  }

  get filteredCheckListTemplates(): ChecklistTemplateConfig[] {
    let checkListTemplates: ChecklistTemplateConfig[] = [];
    if (this.availableCheckListTemplates && this.availableCheckListTemplates.length) {
      let sortedTemplates = _.orderBy(this.availableCheckListTemplates, [ template => template.name.toLowerCase() ], [ 'asc' ]);
      sortedTemplates.forEach((template: ChecklistTemplateConfig) => {
        if (template.active || (this.matter.appliedChecklistTemplateConfigId && this.matter.appliedChecklistTemplateConfigId == template.id)) {
          checkListTemplates.push(template);
        }

      });
    }
    return checkListTemplates;
  }

  isToggleButtonDisabled(): boolean {
    return !(this.matter && this.matter.matterWorkItems && this.matter.matterWorkItems.length && this.matter.matterWorkItems.some(workItem => !!workItem.numberOfTasks));
  }

  toggleDetails(): void {
    //If any category is closed, then expand all. Else close all shutters
    let expandAll: boolean = this.matter.matterWorkItems.some(item => !item.expanded);
    this.matter.matterWorkItems.forEach((item) => {
      item.expanded = expandAll;
      if (item.matterWorkItemTasks) {
        item.matterWorkItemTasks.forEach((task) => {
          task.visible = expandAll;
        });
      }
    });
  }

  async applyDifferentChecklistTemplate() {
    let toProceed = true;
    if (this.matter.matterWorkItems && this.matter.matterWorkItems.length) {
      toProceed = await this.dialogService.confirm('WARNING',
        'Do you wish to continue as this will remove any existing checklist categories and tasks regardless of status.', false).toPromise();
    }

    if (toProceed) {

      let checklistTemplate = await this.checklistService.getChecklistTemplateById(this.account.id, this.matter.appliedChecklistTemplateConfigId).toPromise();
      if (checklistTemplate && this.matter) {
        this.matter.matterWorkItems = this.matterWorkItemsUtilsService.buildWorkItemsFromChecklistTemplate(checklistTemplate);
        this.updateMatterWorkItemsShutters();
        this.removeAllErrors();
        this.enableSave();
      }
    }

  };

  clickCategoryBurgerMenu(clickedMenuOption: BurgerMenuExtendedItem, workItem: MatterWorkItem) {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(workItem);
    }
  }

  updateCategoryBurgerMenu(workItem: MatterWorkItem) {
    let assignAllTasksMenuOption = this.categoryBurgerMenuOptions.find(item => item.key == 'ASSIGN_ALL_TASK');
    if (assignAllTasksMenuOption) {
      assignAllTasksMenuOption.isDisabled = (workItem && workItem.matterWorkItemTasks && !workItem.matterWorkItemTasks.some(item => !item.assignedToParticipantEmail && !item.assignedToParticipantId));
    }

  }

  updateTaskBurgerMenu(task: MatterWorkItemTask) {
    let assignTaskMenuOption = this.taskBurgerMenuOptions.find(item => item.key == 'ASSIGN_TASK');
    if (assignTaskMenuOption) {
      assignTaskMenuOption.isDisabled = (!!task.assignedToParticipantEmail || !!task.assignedToParticipantId);
    }
    let unAssignTaskMenuOption = this.taskBurgerMenuOptions.find(item => item.key == 'UN_ASSIGN_TASK');
    if (unAssignTaskMenuOption) {
      unAssignTaskMenuOption.isDisabled = (!task.assignedToParticipantEmail && !task.assignedToParticipantId);
    }
  }

  clickTaskBurgerMenu(clickedMenuOption: BurgerMenuExtendedItem, task: MatterWorkItemTask, workItem: MatterWorkItem) {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(workItem, task);
    }
  }

  toggleShutter(item: MatterWorkItem): void {
    if (item) {
      item.expanded = !item.expanded;
      item.matterWorkItemTasks.forEach(sds => sds.visible = !sds.visible);
    }
  }

  updateEditMode(item: any, id: string): void {
    if (item.name) {
      item.isEditMode = !item.isEditMode;
      if (item.isEditMode) {
        this.setFocus(id);
      }
    }
    this.validateItemName(item, id);
  }

  validateItemName(item: any, id: string): void {

    this.errorService.removeDpFieldError(`work-item.${ id }.name`);
    if (!item.name) {
      item.isEditMode = true;
      this.errorService.addDpFieldError(DPError.createCustomDPError(`work-item.${ id }.name`, 'Name is required.', 'Overview > Tasks', 'ERROR'));
    } else {
      if (item instanceof MatterWorkItem) {
        let sameNameItems = this.matter.matterWorkItems.filter(workItem => workItem.name == item.name);
        if (sameNameItems && sameNameItems.length > 1) {
          item.isEditMode = true;
          this.errorService.addDpFieldError(DPError.createCustomDPError(`work-item.${ id }.name`, 'Duplicate Category Name.', 'Overview > Tasks', 'ERROR'));
        }
      }
    }
  }

  removeItemErrors(item: any, id: string): void {
    this.errorService.removeDpFieldError(`work-item.${ id }.name`);
    this.errorService.removeDpFieldError(`work-item.task.dueDate_dueDate_${ id }`);
  }

  removeAllErrors(): void {
    this.errorService.removeDpfieldErrorByCoreErrorElementKey('work-item.');
  }

  setFocus(id: string): void {
    setTimeout(() => {
      jQuery('#' + id).focus();
    }, 100);
  }

  enableSave(): void {
    this.matter.isDirty = true;
  }

  addCategory(): void {
    if (this.matter && !this.matter.matterWorkItems) {
      this.matter.matterWorkItems = [];
    }
    let matterWorkItem = new MatterWorkItem();
    matterWorkItem.isEditMode = true;
    this.matter.matterWorkItems.push(matterWorkItem);
    this.setFocus(`cat${ matterWorkItem.identifier }`);
    this.enableSave();
  }

  getMaxCatOrder(): number {
    return this.matter && this.matter.matterWorkItems ? this.matter.matterWorkItems.length : 0;
  }

  deleteCategory = async (workItem: MatterWorkItem) => {
    let toProceed = await this.dialogService.confirm('WARNING', 'Are you sure you would like to delete this category?', false).toPromise();
    if (toProceed) {
      (<any>this.matter.matterWorkItems).remove(workItem);
      this.removeItemErrors(workItem, `cat${ workItem.identifier }`);
      this.enableSave();
    }
  };

  addTask = (workItem: MatterWorkItem) => {
    if (workItem.name) {
      workItem.expanded = true;
      if (!workItem.matterWorkItemTasks) {
        workItem.matterWorkItemTasks = [];
      }
      let subtasks = workItem.matterWorkItemTasks;
      subtasks.forEach((task) => {
        task.visible = true;
      });
      let checklisttask = new MatterWorkItemTask();
      checklisttask.status = WorkItemTaskStatusValues.notStarted;
      checklisttask.visible = true;
      checklisttask.isEditMode = true;

      workItem.matterWorkItemTasks.push(checklisttask);
      this.setFocus(`task${ checklisttask.identifier }`);
      this.enableSave();
    } else {
      this.dialogService.confirm('WARNING', 'Please enter category name before adding a task.', true)
      .subscribe(() => {
        this.setFocus(`cat${ workItem.identifier }`);
      });
    }
  };

  deleteTask = async (workItem: MatterWorkItem, task: MatterWorkItemTask) => {
    let toProceed = await this.dialogService.confirm('WARNING', 'Are you sure you would like to delete this task?', false).toPromise();
    if (toProceed) {
      workItem.tasksNotificationConfig.slice(0).filter(item => item.taskIdentifier == task.id).forEach(
        config => {
          (<any>workItem.tasksNotificationConfig).remove(config);
        }
      );
      (<any>workItem.matterWorkItemTasks).remove(task);
      this.removeItemErrors(task, `task${ task.identifier }`);
      this.enableSave();
    }
  };

  getCatIndex(workItem: MatterWorkItem): number {
    if (this.matter && this.matter.matterWorkItems && this.matter.matterWorkItems.length) {
      return this.matter.matterWorkItems.findIndex(item => item.name == workItem.name);
    } else {
      return -1;
    }
  }

  getTaskIndex(task: MatterWorkItemTask, workItem: MatterWorkItem): number {
    if (workItem && workItem.matterWorkItemTasks && workItem.matterWorkItemTasks.length) {
      return workItem.matterWorkItemTasks.findIndex(item => item.id == task.id);
    } else {
      return -1;
    }
  }

  scrollIntoView(id: number): void {
    let elementId = `row_${ id }`;
    Utils.scrollIntoView(elementId, 'tableContainer');
  }

  isMoveUpDisabled(): boolean {
    let selectedWorkItem = this.getSelectedWorkItem();
    if (selectedWorkItem) {
      return selectedWorkItem.equals(this.matter.matterWorkItems[ 0 ]); //First WorkItem
    } else {
      let selectedTask = this.getSelectedTask();
      if (selectedTask) {
        let workItem = this.getTaskParent(selectedTask);
        return this.getTaskIndex(selectedTask, workItem) == 0; //First task of the category
      } else {
        return true;
      }
    }
  }

  isMoveDownDisabled(): boolean {
    let selectedWorkItem = this.getSelectedWorkItem();
    if (selectedWorkItem) {
      return this.getCatIndex(selectedWorkItem) == this.matter.matterWorkItems.length - 1; //Last Matter Work Item
    } else {
      let selectedTask = this.getSelectedTask();
      if (selectedTask) {
        let workItem = this.getTaskParent(selectedTask);
        return this.getTaskIndex(selectedTask, workItem) == workItem.matterWorkItemTasks.length - 1; //Last task ot the category
      } else {
        return true;
      }
    }
  }

  getSelectedWorkItem(): MatterWorkItem {
    if (this.matter && this.matter.matterWorkItems && this.matter.matterWorkItems.length) {
      return this.matter.matterWorkItems.find(workItem => workItem.isSelected);
    } else {
      return null;
    }
  }

  getSelectedTask(): MatterWorkItemTask {
    if (this.matter && this.matter.matterWorkItems && this.matter.matterWorkItems.length) {
      let selectedTask = null;
      for (let workItem of this.matter.matterWorkItems) {
        if (workItem.matterWorkItemTasks && workItem.matterWorkItemTasks.length) {
          selectedTask = workItem.matterWorkItemTasks.find(task => task.isSelected);
          if (selectedTask) {
            break;
          }
        }
      }
      return selectedTask;
    } else {
      return null;
    }
  }

  getTaskParent(task: MatterWorkItemTask): MatterWorkItem {
    if (this.matter && this.matter.matterWorkItems && this.matter.matterWorkItems.length && task) {
      return this.matter.matterWorkItems.find(workItem => workItem.matterWorkItemTasks && workItem.matterWorkItemTasks.includes(task));
    }
    return null;
  }

  move(direction: string): void {
    let selectedWorkItem = this.getSelectedWorkItem();
    if (selectedWorkItem) {
      direction == 'UP' ? this.moveUp(selectedWorkItem) : this.moveDown(selectedWorkItem);
    } else {
      let selectedTask = this.getSelectedTask();
      if (selectedTask) {
        let parentCat = this.getTaskParent(selectedTask);
        direction == 'UP' ? this.moveUp(parentCat, selectedTask) : this.moveDown(parentCat, selectedTask);
      }
    }
  }

  moveUp = (workItem: MatterWorkItem, task?: MatterWorkItemTask) => {
    if (task) {
      //Move Task Up
      let taskIndex = this.getTaskIndex(task, workItem);
      if (taskIndex > 0) {
        this.moveItemInList(workItem.matterWorkItemTasks, taskIndex, -1);
        this.scrollIntoView(task.identifier);
        this.enableSave();
      }
    } else {
      //Move Category Up
      let workItemIndex = this.getCatIndex(workItem);
      if (workItemIndex > 0) {
        this.moveItemInList(this.matter.matterWorkItems, workItemIndex, -1);
        this.scrollIntoView(workItem.identifier);
        this.enableSave();
      }
    }
  };

  moveItemInList(list: any[], oldIndex: number, step: number): void {
    if (list && list.length) {
      list.splice(oldIndex + step, 0, list.splice(oldIndex, 1)[ 0 ]);
    }
  }

  moveDown = (workItem: MatterWorkItem, task?: MatterWorkItemTask) => {
    if (task) {
      //Move Task Down
      let taskIndex = this.getTaskIndex(task, workItem);
      if (taskIndex < workItem.matterWorkItemTasks.length - 1) {
        this.moveItemInList(workItem.matterWorkItemTasks, taskIndex, 1);
        this.scrollIntoView(task.identifier);
        this.enableSave();
      }
    } else {
      //Move Category Down
      let workItemIndex = this.getCatIndex(workItem);
      if (workItemIndex < this.matter.matterWorkItems.length - 1) {
        this.moveItemInList(this.matter.matterWorkItems, workItemIndex, 1);
        this.scrollIntoView(workItem.identifier);
        this.enableSave();
      }
    }
  };

  onDueDateChanged(event, task: MatterWorkItemTask): void {
    if (event.year && event.month && event.day) {
      let tempDateString: string = `${ event.year }/${ event.month }/${ event.day }`;
      if (!MoreUtils.isNotValidDate(tempDateString)) {
        if (tempDateString != task.dueDate) {
          task.dueDate = tempDateString;
          this.enableSave();
        }
      }
    } else if (!event.year && !event.month && !event.day) {
      task.dueDate = null;
      this.enableSave();
    }
  }

  onStatusChanged(task: MatterWorkItemTask): void {
    task.completedDate = task.isStatusCompleted() ? moment().format('YYYY/MM/DD') : null;
    if (task.isStatusCompleted()) {
      let loggedInUser = new User(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
      task.completedByUserId = loggedInUser.id;
      task.completedByUserInitials = this.loggedInUserInitials;
    } else {
      task.completedByUserId = null;
      task.completedByUserInitials = '';
    }

    this.enableSave();
  }

  dynamicHelpForDueDate(): string {
    return 'F9 = Calendar';
  }

  primaryClient(): MatterParticipant {
    return this.matter && this.matter.mainClients && this.matter.mainClients.find(item => !!item.primary && !!item.contact);
  }

  primaryContactEmail(): string {
    return MatterParticipantUtil.primaryContactEmail(this.matter);
  }

  checkPrimaryClient(): string {
    let message;
    if (!this.primaryClient()) {
      message = 'Task can not be assigned as there is no primary client available on matter';
    } else if (this.primaryClient().contact && (!this.primaryContactEmail() || !ErrorValidator.areEmailAddressesValid(this.primaryContactEmail(), true))) {
      message = (this.matter.mainClients && this.matter.mainClients.length > 1 ? 'Primary Client does not have a valid email address' : 'Client does not have a valid email address');
    }
    if (!!message) {
      this.dialogService.confirm('Error', message, true, 'Ok').subscribe();
    }
    return message;
  }

  checkClient(client: MatterParticipantInfo, matterParticipant: MatterParticipant): string {
    let message;

    if (matterParticipant && matterParticipant.contact) {
      if (!client) {// signing officer is not all
        message = 'Task can not be assigned as there is no ' + matterParticipant.contact.signingOfficerTypeText + '.';
      } else if (!client.matterParticipant || (!client.matterParticipant.contact || !Utils.validateEmail(client.matterParticipant.contact.firstEmail))) {
        message = (matterParticipant.contact.signingOfficerType ? matterParticipant.contact.signingOfficerTypeText : 'Client')
          + ' does not have a valid email address';
      }

    } else {
      message = 'Task can not be assigned as there is no client.';
    }
    if (!!message) {
      this.dialogService.confirm('Error', message, true, 'Ok').subscribe();
    }
    return message;
  }

  assignAllTask = (workItem: MatterWorkItem, task?: MatterWorkItemTask) => {
    if (workItem) {
      if (this.usedInModal) {
        if (this.matterParticipant) {
          //If no signing officer then do not show corp, other entity , estate and POA .
          let client: MatterParticipantInfo = MatterParticipantUtil.contactFieldWithEmailInfo(this.matterParticipant, this.matter);
          if (!this.checkClient(client, this.matterParticipant) && client && client.matterParticipant) {
            const inviteGuid = 'T' + UUIDUtil.getUUID();
            workItem.matterWorkItemTasks.filter(item => !item.assignedToParticipantEmail && !item.assignedToParticipantId).forEach(item => item.assignTaskToClient(client.matterParticipant, client.email, inviteGuid));
          }
        }
      } else {
        let inviteGuid = 'T' + UUIDUtil.getUUID();
        this.openAssignedTaskModal(workItem.matterWorkItemTasks.filter(item => !item.assignedToParticipantEmail && !item.assignedToParticipantId), inviteGuid, true);
      }
    }
  };

  assignTask = (workItem: MatterWorkItem, task?: MatterWorkItemTask) => {
    if (task) {
      if (this.usedInModal) {
        if (this.matterParticipant) {
          //If no signing officer then do not show corp, other entity , estate and POA .
          let client: MatterParticipantInfo = MatterParticipantUtil.contactFieldWithEmailInfo(this.matterParticipant, this.matter);
          if (!this.checkClient(client, this.matterParticipant) && client && client.matterParticipant) {
            const inviteGuid = 'T' + UUIDUtil.getUUID();
            [ task ].forEach(item => item.assignTaskToClient(client.matterParticipant, client.email, inviteGuid));
          }
        }
      } else {
        let inviteGuid = 'T' + UUIDUtil.getUUID();
        this.openAssignedTaskModal([ task ], inviteGuid);
      }
    }
  };

  unAssignTask = (workItem: MatterWorkItem, task?: MatterWorkItemTask) => {
    if (task) {
      task.unAssignTask();
      this.matter.dirty = true;
    }
  };

  configureTask = (workItem: MatterWorkItem, task?: MatterWorkItemTask) => {
    if (this.matter) {
      let matterTypeCode = this.matter.isCustomMatter() ? 'C' : this.matter.isMatterTypeDischarge ? 'D' : this.matter.matterTypeCode;
      this.dialogService.matDialogContent({
        content: MatterNotificationConfigModalComponent,
        context: {
          account: this.account,
          isTaskNotification: true,
          workItem: workItem,
          taskId: task.id,
          taskName: task.name,
          applicableMatterTypeCode: matterTypeCode,
          matterType: this.matter.matterType,
          provinceCode: this.matter.provinceCode,
          matterNotifications: this.matter.matterNotifications
        },
        onFulfillment: (result: any) => {
          if (result) {
            this.enableSave();
          }
        }
      });
    }
  };

  openAssignedTaskModal(matterWorkItemtasks: MatterWorkItemTask[], inviteGuid: string, multipleTasks?: boolean): void {

    if (this.staffProfiles.length == 0) {
      this.loadStaffProfiles().then(() => {
        this.openAssignedTask(matterWorkItemtasks, inviteGuid, null, multipleTasks);
      });
    } else {
      this.openAssignedTask(matterWorkItemtasks, inviteGuid, null, multipleTasks);
    }

  }

  openAssignedTask(matterWorkItemtasks: MatterWorkItemTask[], inviteGuid: string, isResend?: boolean, multipleTasks?: boolean): void {
    let assignedToStaffName = '';
    if (isResend && matterWorkItemtasks.length) {
      assignedToStaffName = this.getAssignedContactName(matterWorkItemtasks[ 0 ].assignedToParticipantId, true);
    }
    let emailData;
    if (!multipleTasks && matterWorkItemtasks && matterWorkItemtasks.length > 0) {
      emailData = new EmailData();
      emailData.taskName = matterWorkItemtasks[ 0 ].name;
      emailData.dueDate = matterWorkItemtasks[ 0 ].dueDate;
    }
    let subject: string = this.emailFieldService.getMailSubject(null, EmailTypes.isTaskAssignment, emailData);
    this.dialogService.matDialogContent({
      content: AssignedTaskModalComponent,
      context: {
        matter: this.matter,
        matterWorkItemtasks: matterWorkItemtasks,
        subject: subject,
        staffProfiles: this.staffProfiles,
        assignedToStaffName: assignedToStaffName,
        multipleTasks: multipleTasks
      },
      onFulfillment: (result) => {
        if (result) {
          if (isResend) {
            this.postMatterSaveAssign(result, inviteGuid, subject, null, null, isResend);
          } else {
            this.matter.dirty = true;
            let client;
            if (result.assignedTo == 'STAFF') {
              matterWorkItemtasks.forEach(item => item.assignTaskToStaff(result.assignedContactId ? result.assignedContactId : result.unityMessage.recipientUserId));
              // matterWorkItemtasks.forEach(item => item.assignTaskToStaff(result.unityMessage.recipientUserId));
            } else {
              if (this.matter && this.matter.matterParticipants) {
                client = this.matter.matterParticipants.find(item => item.matterParticipantId == result.selectedClientId);
                if (client && client.contact) {
                  matterWorkItemtasks.forEach(item => item.assignTaskToClient(client, client.contact.firstEmail, inviteGuid));
                }
              }
            }
            let matterTab = this.tabsService.activeTab as MatterTab;
            if (matterTab && matterTab.matterComponent) {
              let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((isMatterSaved: boolean) => {
                if (isMatterSaved) {
                  this.postMatterSaveAssign(result, inviteGuid, subject, null, client);
                  subscription.unsubscribe();
                }
              });
            }
          }
        }
      },

    });
  }

  getTaskContactFullName(task: MatterWorkItemTask) {
    if (task && task.assignedToParticipantId && this.matter && this.matter.matterParticipants) {
      let participant: MatterParticipant = this.matter.matterParticipants.find(item => item.matterParticipantId == task.assignedToParticipantId);
      return this.getClientContactFullName(participant);
    }
    return '';
  }

  getClientContactFullName(participant: MatterParticipant) {
    return participant && participant.contact && participant.contact.contactFullNameStartWithFirstName ? participant.contact.contactFullNameStartWithFirstName : '';
  }

  getClientContactEmail(participant: MatterParticipant) {
    return participant && participant.contact && participant.contact.firstEmail ? participant.contact.firstEmail : '';
  }

  getTaskParticipant(task: MatterWorkItemTask) {
    return task && task.assignedToParticipantId && this.matter && this.matter.matterParticipants && this.matter.matterParticipants.find(item => item.matterParticipantId == task.assignedToParticipantId);
  }

  postMatterSaveAssign(result: any, inviteGuid: string, subject: string, task?: MatterWorkItemTask, client?: MatterParticipant, isResend?: boolean): void {
    if (result.assignedTo == 'STAFF') {
      if (!!isResend || (this.matter && this.matter.sendInternalNotifications == 'YES')) {
        let userId = Utils.getAuthenticatedUserId();
        this.matterWorkItemsUtilsService.postAssignedWorkItemMessage(result.unityMessage, Number(userId)).subscribe(() => {
          this.dialogService.confirm('Information', 'An internal Unity message has been sent to the assignee.', true)
          .subscribe(() => {
          });
        });
      }
    } else {
      if (!!isResend || (this.matter && this.matter.sendExternalNotifications == 'YES')) {
        const baseUrl = this.cirfHelperService.getUnityConnectBaseUrl(inviteGuid);
        let participant: MatterParticipant = task ? this.getTaskParticipant(task) : client;
        const contactEmail = participant ? this.getClientContactEmail(participant) : '';
        const contactFullName = participant ? this.getClientContactFullName(participant) : '';
        let messageBody = task ? this.createResendEmailBodyForClient(contactFullName, task.name) : this.createAssignedEmailBody(contactFullName, baseUrl, result.message);
        this.emailFieldService.openLocalEmailClient(contactEmail, subject, messageBody).subscribe();
      }
    }
  }

  createAssignedEmailBody(recipientName: string, link: string, selectedPrecedentDesc ?: string): string {
    //If recipientName is null, it should change '' to avoid "Dear null"
    let emailBody = 'Dear' + (recipientName ? ' ' + recipientName : '') + ',' + '\n\n';
    if (selectedPrecedentDesc) {
      emailBody += this.formatPrecedentToRemoveRichTextTags(selectedPrecedentDesc) + '\n\n';
    } else {
      emailBody += 'We are notifying you that a task, or tasks, for this transaction have been assigned to you.\n\n'
        + 'Please click on the link below to see these tasks.'
        + 'If you have not previously, you will need to register through UnityC, your secure connection to Canadian law offices. '
        + 'Once you have registered, you can log in to UnityC to:\n\n'
        + '       - Connect with Canadian law offices\n'
        + '       - Provide information about files\n'
        + '       - Access Shared documents\n'
        + '       - Get status updates\n\n';
    }

    emailBody += link + '\n\n'
      + 'The UnityC link is only for the benefit of the email recipient. Please do not forward or otherwise distribute this email.';
    return emailBody;
  }

  createResendEmailBodyForClient(recipientName: string, taskName: string): string {
    return 'Dear' + (recipientName ? ' ' + recipientName : '') + ',' + '\n\n' + 'Re: ' + taskName + '\n\n';
  }

  formatPrecedentToRemoveRichTextTags(precedentContent: string) {
    return precedentContent.replace(/<strong>/gi, '').replace(/<\/strong>/gi, '')  //Bold Tag
    .replace(/<u>/gi, '').replace(/<\/u>/gi, '') // Underline tag
    .replace(/<em>/gi, '').replace(/<\/em>/gi, '') // Italic Tags
    .replace(/&nbsp;/gi, ' ') // Replacing with space
    .replace(/<p>/gi, '').replace(/<\/p>/gi, '').trim(); // Paragraph Tag
  }

  selectRow(catIdx: number, taskIndex?: number): void {
    this.unselectAllItems();
    if (taskIndex >= 0) {
      this.matter.matterWorkItems[ catIdx ].matterWorkItemTasks[ taskIndex ].isSelected = true;
    } else {
      this.matter.matterWorkItems[ catIdx ].isSelected = true;
    }

  }

  unselectAllItems(): void {
    if (this.matter && this.matter.matterWorkItems) {
      this.matter.matterWorkItems.forEach((workItem) => {
        workItem.isSelected = false;
        if (workItem.matterWorkItemTasks) {
          workItem.matterWorkItemTasks.forEach((task) => {
            task.isSelected = false;
          });
        }
      });
    }

  }

  getAssignedContactName(contactId: number, resend?: boolean): string {
    let staffProfile = this.staffProfiles.find(item => item.contactId == contactId);
    if (staffProfile) {
      return resend ? staffProfile.surnameLastFullName : staffProfile.firstNameLastNameShort;
    } else {
      return '';
    }
  }

  sendQuickNotification(task: MatterWorkItemTask): void {
    if (task && task.isAssigned()) {
      if (task.isAssignedToClient()) {
        this.handleSendClientNotification(task);
      } else {
        this.handleSendStaffNotification(task);
      }
    }
  }

  clientContactEmail(matterParticipantInfo: MatterParticipantInfo): string {
    return matterParticipantInfo && matterParticipantInfo.email;
  }

  handleSendClientNotification(task: MatterWorkItemTask): void {
    // let primaryClient: MatterParticipant = this.primaryClient();
    // if(!primaryClient ||
    //     (primaryClient.contact && !this.primaryContactEmail()) ||
    //     (primaryClient.contact && !ErrorValidator.areEmailAddressesValid(this.primaryContactEmail(), true))){
    //     this.dialogService.confirm('ERROR', 'A Primary client with a valid email address is required.', true);
    //     return;
    // }
    let client: MatterParticipant = this.matter.matterParticipants.find((item => item.matterParticipantId == task.assignedToParticipantId));
    let matterParticipantInfo = client ? MatterParticipantUtil.contactFieldWithEmailInfo(client, this.matter) : null;
    if (!client ||
      (client.contact && !this.clientContactEmail(matterParticipantInfo)) ||
      (client.contact && !ErrorValidator.areEmailAddressesValid(this.clientContactEmail(matterParticipantInfo), true))) {
      this.dialogService.confirm('ERROR', client.contact.fullName + ' with a valid email address is required.', true);
      return;
    }
    let emailData = new EmailData();
    emailData.taskName = task && task.name;
    emailData.dueDate = task && task.dueDate;
    let subject: string = this.emailFieldService.getMailSubject(null, EmailTypes.isTaskAssignment, emailData);
    let result: any = {};
    result.assignedTo = 'CLIENT';
    this.postMatterSaveAssign(result, task.inviteGuid, subject, task);
    this.dialogService.confirm('Email Opened', 'An email to this client has been opened in your email application. Please make sure you complete and send the email before continuing.', true);
  }

  async handleSendStaffNotification(task: MatterWorkItemTask): Promise<void> {
    if (this.staffProfiles.length == 0) {
      await this.loadStaffProfiles();
    }
    let staff = this.staffProfiles.find(item => item.id == task.assignedToParticipantId);
    if (staff && !staff.isContactActive) {
      this.dialogService.confirm('ERROR', 'Assigned Staff Member is Inactive.', true);
      return;
    }
    this.openAssignedTask([ task ], task.inviteGuid, true);

  }

  updateInternalNotification(event): void {
    this.matter.sendInternalNotifications = event.checked ? 'YES' : 'NO';
    this.enableSave();
  }

  updateExternalNotification(event): void {
    if (event.checked) {
      this.dialogService.confirm('Warning', 'No matter status correspondence will be sent automatically. Please turn on the applicable matter status correspondence from the Correspondence Tab', true).subscribe();
    }
    this.matter.sendExternalNotifications = event.checked ? 'YES' : 'NO';
    this.enableSave();
  }
}
