import {BaseEntity} from '../../shared/BaseEntity/base-entity';

//Users from account integration partners such as Zoom, Microsoft Teams, and Google
export class AccountIntegrationUser extends BaseEntity {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;

  get fullName(): string {
    return (this.isNullOrEmpty(this.firstName) ? '' : this.firstName) + ' ' + (this.isNullOrEmpty(this.lastName) ? '' : this.lastName);
  }

  isNullOrEmpty(name: string): boolean {
    return (name == 'null' || name === undefined || name === null || name === '');
  }
}
