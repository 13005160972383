import {Component, Inject, ViewChild} from '@angular/core';
import {LockScreenService} from '../../core/lock-screen.service';
import {teranetConnectLinks} from '../../shared-main/teranet/teranet-constants';
import {Logger} from '@nsalaun/ng-logger';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {ManageTeranetUserModal} from '../../main/manage-thirdparty-credentials/manage-teranet-user.modal.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {FieldError} from '../../core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import Utils from "../../shared-main/utils";

declare var jQuery: any;

class TeranetConnectContext {}

@Component({
  selector: 'dp-teranet-connect-modal',
  templateUrl: 'teranet-connect.modal.component.html',
  providers: [ErrorService]
})
export class TeranetConnectModal extends ModalComponent<TeranetConnectContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  tcCredentials: TeranetUser[] = [];
  tcCredential: TeranetUser;
  tcPassword: string;
  accountId: string;
  userId: string;
  formDisabled: boolean = true;
  tcCredentialID: number;

  constructor(
    public dialog: MatDialogRef<TeranetConnectModal>,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    public lockScreenService: LockScreenService,
    public logger: Logger,
    public errorSerice: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetConnectContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.userId = Utils.getAuthenticatedUserId();
    this.teranetService.getTeranetUsers().subscribe(
      (teranetUserData: TeranetUser[]) => {
        console.log(teranetUserData);
        if (teranetUserData.length) {
          this.tcCredentials = teranetUserData;
          this.tcCredential = this.tcCredentials[0];
          this.tcCredentialID = this.tcCredentials[0].id;
          this.formDisabled = false;
          jQuery('#password').focus();
        } else {
          //debugger;
          this.modalErrorComponent.createDPFieldError('matter.propertyTeranet.noCredentials');
        }
      },
      (error) => {}
    );
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpFieldError();
    this.lockScreenService.lockForUpdate = true;
    //debugger;
    this.tcCredential = this.tcCredentials.find((user) => {
      return user.id == this.tcCredentialID;
    });
    this.tcCredential.teranetPassword = this.tcPassword;
    console.log(this.tcCredential);
    this.teranetService
      .getTeranetBalance(this.tcCredential)
      .finally(() => (this.lockScreenService.lockForUpdate = false))
      .subscribe(
        (teranetResult: TeranetUser) => {
          this.dialog.close({
            action: 'OK',
            teranetUser: teranetResult
          });
        },
        (error) => {
          if (this.getExpiredPassword(error)) {
            this.dialog.close({
              action: 'PASSWORD_EXPIRED',
              teranetUser: this.tcCredential
            });
          } else {
            if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
              error.fieldErrors.forEach((fieldError: FieldError) => {
                this.modalErrorComponent.createCustomDPFieldError(
                  fieldError.errorCode,
                  fieldError.errorCode + ':' + fieldError.message,
                  null,
                  'ERROR'
                );
              });
            } else {
              this.modalErrorComponent.createCustomDPFieldError(
                error.errorCode,
                error.errorCode + ':' + error.errorMessage,
                null,
                'ERROR'
              );
            }
          }
        }
      );
  }

  getExpiredPassword(error: any): boolean {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      return error.fieldErrors.find((fieldError: FieldError) => fieldError.errorCode === 'DAW21045');
    }
    return false;
  }

  addCredential(): void {
    let teranetUser: TeranetUser = new TeranetUser();
    let action = 'Add';
    this.dialogService.matDialogContent({
      content: ManageTeranetUserModal,
      context: {
        action: action,
        teranetUser: teranetUser,
        accountId: this.accountId,
        userId: this.userId
      },
      onFulfillment: (result: any) => {
        console.log(result);
        if (result && result.action === 'OK') {
          if (action === 'Add') {
            teranetUser = result.teranetUser;
            this.tcCredentials.push(teranetUser);
            this.tcCredential = teranetUser;
            this.tcCredentialID = teranetUser.id;
            this.formDisabled = false;
            this.modalErrorComponent.removeAllDpFieldError();
          }
        }
      }
    });
  }

  forgetPassword(): void {
    this.teranetService.getTeranetForgotPasswordLink().subscribe(
      (forgotPassLink: string) => {
        let passwordUrl = forgotPassLink;
        //console.log(passwordUrl);
        window.open(passwordUrl, '_blank');
      },
      (error) => {}
    );
  }

  faq(): void {
    window.open(teranetConnectLinks.faq, '_blank');
  }

  rateSheet(): void {
    window.open(teranetConnectLinks.fees, '_blank');
  }

  close(): void {
    this.dialog.close();
  }

  tabToHeaderLink(): void {
    jQuery('#headerLink').focus();
  }
}
