<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Copy Opportunity Data To Existing Matter {{context.existingMatter.matterRecordNumber}}</h1>
            </div>
            <button (click)="close()" class="btn-close pull-right" type="button">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-lg-12 padding-left-20">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-4">
                <label class="control-label">Opportunity {{context?.opportunityMatter?.matterRecordNumber}}</label>
            </div>
            <div class="col-lg-1 text-center padding-left-15">
                <dp-checkbox fieldClass="top-8" fieldId="revertAll">
                    <input [checked]="copyParams?.allSelected"
                           (change)="copyParams?.revertActionOnAllRecords()"
                           id="revertAll"
                           type="checkbox"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-4">
                <label class="control-label">Matter {{context?.existingMatter?.matterRecordNumber}}</label>
            </div>
        </div>
        <div class="col-lg-12 form-group padding-top-10">
            <hr/>
        </div>
        <div class="col-lg-12 padding-left-20">
            <dp-copy-to-existing-matter-deal-details-content
                (openStickyNote)="openStickyNotesModal($event)"
                [copyParams]="copyParams"
                [existingMatter]="context?.existingMatter"
                [opportunityMatter]="context?.opportunityMatter">
            </dp-copy-to-existing-matter-deal-details-content>

            <dp-copy-to-existing-matter-my-client-content
                (openStickyNote)="openStickyNotesModal($event)"
                [copyParams]="copyParams"
                [existingMatter]="context?.existingMatter"
                [opportunityMatter]="context?.opportunityMatter">
            </dp-copy-to-existing-matter-my-client-content>

            <dp-copy-to-existing-matter-other-side-content
                (openStickyNote)="openStickyNotesModal($event)"
                [copyParams]="copyParams"
                [existingMatter]="context?.existingMatter"
                [opportunityMatter]="context?.opportunityMatter">
            </dp-copy-to-existing-matter-other-side-content>

            <dp-copy-to-existing-matter-mortgage-content
                [copyParams]="copyParams"
                [existingMatter]="context?.existingMatter"
                [opportunityMatter]="context?.opportunityMatter">
            </dp-copy-to-existing-matter-mortgage-content>

            <dp-copy-to-existing-matter-note-content
                (openStickyNote)="openStickyNotesModal($event)"
                [copyParams]="copyParams"
                [existingMatter]="context?.existingMatter"
                [opportunityMatter]="context?.opportunityMatter">
            </dp-copy-to-existing-matter-note-content>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button (click)="ok()" class="dp-btn" id="okBtn" type="button">
                <span>OK</span>
            </button>
            <button (click)="close()" class="dp-btn dp-btn-cancel" type="button">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
