import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {Observable} from 'rxjs/index';
import {DocumentViewModeValue, UserConfig, UserProvincialConfiguration} from '../matters/shared/user-config';
import {HttpClient} from '../core/httpClient.service';
import {ServerMatterListFilter} from '../matters/matter-list/matter-list-filter';
import {MatterType} from '../matters/shared/matter-specific-type';
import {ProvinceCode} from '../admin/accounts/shared/base-province';
import {ColorStyle} from './constants';
import Utils from "./utils";

@Injectable()
export class UserConfigurationService {

  constructor(private http: HttpClient) {
  }

  userConfig: UserConfig;
  inProgressConfig: UserConfig;
  queuedConfig: UserConfig;
  maxPageFontPercentage = 130;
  minPageFontPercentage = 100;
  fontStep = 5;

  initializeUserConfig(uc: UserConfig) {
    this.userConfig = new UserConfig(uc);
  }

  getUserConfiguration(): Observable<UserConfig> {
    let userId: string = Utils.getAuthenticatedUserId();

    if (!userId) throw new Error(`Unable to fetch user configuration because user id was not found`);

    return this.http
    .get(userAccountProfilesApi.userConfiguration)
    .map((res: UserConfig) => {
      this.userConfig = new UserConfig(res[ 'UserConfig' ]);
      return this.userConfig;
    });
  }

  //if there is already a request in progress, any subsequent requests are queued (we only keep the latest queued request)
  updateUserConfiguration(userConfig?: UserConfig): void {
    let userConfiguration: UserConfig = userConfig ? userConfig : this.userConfig;

    if (this.inProgressConfig) { //put request in queued state
      this.queuedConfig = userConfiguration;
    } else { //submit to back end directly, no other requests in progress
      this.inProgressConfig = userConfiguration;
      this.executeUpdate();
    }
  }

  executeUpdate(): void {
    this.http.post(userAccountProfilesApi.userConfiguration, JSON.stringify(this.inProgressConfig), true).finally(() => {
      if (this.queuedConfig) { //handle any queued request
        this.inProgressConfig = this.queuedConfig;
        this.queuedConfig = null;
        this.executeUpdate();
      } else { //the in progress request has been completed
        this.inProgressConfig = null;
      }
    }).subscribe((res) => {
        this.userConfig = new UserConfig(res[ 'UserConfig' ]);
      }
    );
  }

  set statementAdjustmentShowOnlySummary(value: boolean) {
    if (this.userConfig) {
      if (this.userConfig.statementAdjustmentShowOnlySummary != value) {
        this.userConfig.statementAdjustmentShowOnlySummary = value;
        this.updateUserConfiguration();
      }
    }
  }

  get statementAdjustmentShowOnlySummary(): boolean {
    return this.userConfig ? this.userConfig.statementAdjustmentShowOnlySummary : false;
  }

  updateSoaDefaultDocumentTemplateName(newTemplateName: string, province: ProvinceCode, matterType: MatterType) {
    let upc: UserProvincialConfiguration = this.userConfig.userProvincialConfigurations.find(upc => upc.provinceCode == province && upc.matterType == matterType);
    if (upc) {
      upc.soaDefaultDocumentTemplate = newTemplateName;
      this.updateUserConfiguration();
    }
  }

  updateWizardPayoutDefaultTemplateName(newTemplateName: string, province: ProvinceCode, matterType: MatterType) {
    let upc: UserProvincialConfiguration = this.userConfig.userProvincialConfigurations.find(upc => upc.provinceCode == province && upc.matterType == matterType);
    if (upc) {
      upc.wizardPayoutDefaultTemplate = newTemplateName;
      this.updateUserConfiguration();
    }
  }

  updateTrustLedgerDefaultDocumentTemplateName(newTemplateName: string, province: ProvinceCode, matterType: MatterType) {
    let upc: UserProvincialConfiguration = this.userConfig.userProvincialConfigurations.find(upc => upc.provinceCode == province && upc.matterType == matterType);
    if (upc) {
      upc.trustLedgerDefaultDocumentTemplate = newTemplateName;
      this.updateUserConfiguration();
    }
  }

  getUserProvincialConfiguration(province: ProvinceCode = 'ON', matterType: MatterType = 'PURCHASE'): UserProvincialConfiguration {
    if (this.userConfig) {
      const upc: UserProvincialConfiguration = this.userConfig.getUserProvincialConfiguration(province, matterType);
      return upc;
    }
    return null;
  }

  getSoaDefaultDocumentTemplateName(province: ProvinceCode = 'ON', matterType: MatterType = 'PURCHASE'): string {
    if (this.userConfig) {
      const upc: UserProvincialConfiguration = this.userConfig.getUserProvincialConfiguration(province, matterType);
      if (upc) {
        return upc.soaDefaultDocumentTemplate;
      } else {
        console.warn('! No User Configuration found for SOA based on Province: %s, MatterType; %s', province, matterType);
      }
    }
    return null;
  }

  getWizardPayoutDefaultTemplateName(province: ProvinceCode = 'ON', matterType: MatterType = 'PURCHASE'): string {
    if (this.userConfig) {
      const upc: UserProvincialConfiguration = this.userConfig.getUserProvincialConfiguration(province, matterType);
      if (upc) {
        return upc.wizardPayoutDefaultTemplate;
      } else {
        console.warn('! No User Configuration found for Wizard Payout based on Province: %s, MatterType; %s', province, matterType);
      }
    }
    return null;
  }

  getTrustLedgerDefaultDocumentTemplateName(province: ProvinceCode = 'ON', matterType: MatterType = 'PURCHASE'): string {
    if (this.userConfig) {
      const upc: UserProvincialConfiguration = this.userConfig.getUserProvincialConfiguration(province, matterType);
      if (upc) {
        return upc.trustLedgerDefaultDocumentTemplate;
      } else {
        console.warn('! No User Configuration found for TL based on Province: %s, MatterType; %s', province, matterType);
      }
    }
    return null;
  }

  get defaultMatterListFilter(): ServerMatterListFilter {
    if (this.userConfig) {
      return this.userConfig.defaultMatterListFilter;
    } else {
      this.getUserConfiguration().subscribe(res => {
        if (res) {
          return res.defaultMatterListFilter;
        }
      });
    }

    // return this.userConfig && this.userConfig.defaultMatterListFilter;
  }

  set defaultMatterListFilter(serverMatterListFilter: ServerMatterListFilter) {
    if (this.userConfig) {
      this.userConfig.defaultMatterListFilter = serverMatterListFilter;
      this.updateUserConfiguration();
    }

  }

  get defaultOpportunityListFilter(): ServerMatterListFilter {
    if (this.userConfig) {
      return this.userConfig.defaultOpportunityListFilter;
    } else {
      this.getUserConfiguration().subscribe(res => {
        if (res) {
          return res.defaultOpportunityListFilter;
        }
      });
    }
  }

  set defaultOpportunityListFilter(serverMatterListFilter: ServerMatterListFilter) {
    if (this.userConfig) {
      this.userConfig.defaultOpportunityListFilter = serverMatterListFilter;
      this.updateUserConfiguration();
    }

  }

  set fontSize(value: number) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.fontSize = value;
    }
  }

  get fontSize(): number {
    return this.userConfig && this.userConfig.configValues && this.userConfig.configValues.fontSize ? Number(this.userConfig.configValues.fontSize) : 100;
  }

  increasePageFont(): void {
    if (Number(this.fontSize) < this.maxPageFontPercentage) {
      this.fontSize = Number(this.fontSize) + this.fontStep;
      this.updateUserConfiguration();
    }
  }

  decreasePageFont(): void {
    if (Number(this.fontSize) > this.minPageFontPercentage) {
      this.fontSize = Number(this.fontSize) - this.fontStep;
      this.updateUserConfiguration();
    }
  }

  resetPageFont(): void {
    this.fontSize = 100;
    this.updateUserConfiguration();
  }

  set sidebarFontSize(value: number) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.sidebarFontSize = value;
    }
  }

  get sidebarFontSize(): number {
    return this.userConfig && this.userConfig.configValues && this.userConfig.configValues.sidebarFontSize ? Number(this.userConfig.configValues.sidebarFontSize) : 100;
  }

  set selectedLandingPage(value: string) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.selectedLandingPage = value;
    }
  }

  get selectedLandingPage(): string {
    return this.userConfig && this.userConfig?.configValues?.selectedLandingPage ? this.userConfig.configValues.selectedLandingPage : '';
  }

  set sidebarFontColor(value: ColorStyle) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.sidebarFontColor = value;
    }
  }

  get sidebarFontColor(): ColorStyle {
    return this.userConfig && this.userConfig.configValues && this.userConfig.configValues.sidebarFontColor ? this.userConfig.configValues.sidebarFontColor : 'dp-black';
  }

  set isSidebarFontBold(value: boolean) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.isSidebarFontBold = value;
    }
  }

  get isSidebarFontBold(): boolean {
    return this.userConfig && this.userConfig.configValues && this.userConfig.configValues.isSidebarFontBold && this.userConfig.configValues.isSidebarFontBold.toString() == 'true'; //The value comes from the backend as string
  }

  set isFieldCodesVisible(value: boolean) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.isFieldCodesVisible = value;
    }
  }

  get isFieldCodesVisible(): boolean {
    return this.userConfig && this.userConfig.configValues && this.userConfig.configValues.isFieldCodesVisible && this.userConfig.configValues.isFieldCodesVisible.toString() == 'true';
  }

  set keepCheckmarkAfterProducingDocument(value: boolean) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.keepCheckmarkAfterProducingDocument = value;
    }
  }

  get keepCheckmarkAfterProducingDocument(): boolean {
    return this.userConfig && this.userConfig.configValues &&
      this.userConfig.configValues.keepCheckmarkAfterProducingDocument &&
      this.userConfig.configValues.keepCheckmarkAfterProducingDocument.toString() == 'true'; //The value comes from the backend as string
  }

  get documentViewModeForMatterDocument(): string {
    if (this.userConfig && this.userConfig.configValues
      && this.userConfig.configValues.documentViewModeForMatterDocument
      && this.userConfig.configValues.documentViewModeForMatterDocument == DocumentViewModeValue.folder) {
      return DocumentViewModeValue.folder;
    } else {
      return DocumentViewModeValue.list;
    }
  }

  set documentViewModeForMatterDocument(value: string) {
    if (this.userConfig && this.userConfig.configValues) {
      this.userConfig.configValues.documentViewModeForMatterDocument = value;
    }
  }
}
