import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import {PinLegalDescription} from '../../../title-insurance/pin-legal-description';
import {PurposeOfPayoutType} from './assyst-payout-request.modal.component';

export class PayoutRequestData extends BaseEntity {
  solicitorId: number;
  mattterId: number;
  mortgageId: number;
  pinLegalDescriptions: PinLegalDescription[];
  purposeForPayout: PurposeOfPayoutType;
  purposeOfPayoutOther: string;
  acknowledgementOfConsent: string;
  reasonForCancellation: string;
  fileReferenceNumber: string;
  landRegistryOffice: string;

  constructor(payoutRequestData?: PayoutRequestData) {
    super(payoutRequestData);
    if (payoutRequestData) {
      if (payoutRequestData.pinLegalDescriptions) {
        this.pinLegalDescriptions = payoutRequestData.pinLegalDescriptions.map(item => new PinLegalDescription(item));
      }
    }
  }

}
