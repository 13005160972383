<form class="form-horizontal container-fluid custom-modal-container border-radius">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Payout Request History</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body" style="min-height: 150px" >
        <ng-container>
            <div class="col-lg-12" *ngFor="let item of payoutHistoryWrapperItems; let i=index">
                <div [ngClass]="{'shutter-bg': item.expanded , 'shutter-bg-closed': !item.expanded}">
                    <div class = "form-group">
                        <div class="shutter-arrow" (click)="togglePayoutHistoryShutter(item)" >

                            <div *ngIf="item.expanded"> <img src="/assets/img/minus.svg" alt="" /></div>
                            <div *ngIf="!item.expanded"><img src="/assets/img/plus.svg" alt="" /></div>
                        </div>
                        <div class="col-lg-12 margin-left-30 margin-top-10 margin-bottom-20 bold" >
                            Payout Statement Request ID: {{item.payoutStatement.vendorReferenceNumber}}
                        </div>
                        <ng-container *ngIf="item.expanded">
                            <div class="col-lg-12 margin-left-20 margin-bottom-5" *ngFor="let payoutStatementStatus of item.payoutStatement?.payoutStatementStatuses; let j=index">
                                <div class="col-lg-12">
                                    <div class="col-lg-6">Payout Status: {{payoutStatementStatus.statementStatusText}}</div>
                                    <div class="col-lg-3">{{getStatusReceivedDate(payoutStatementStatus.statusReceivedTimestamp)}}</div>
                                    <div class="col-lg-3" *ngIf="payoutStatementStatus.thirdPartyDocumentId && !payoutStatementStatus.thirdPartyDocumentDeleted">
                                        <span>
                                            <a href="javascript: void(0)" (click)="downloadDocument(payoutStatementStatus.thirdPartyDocumentId)">
                                                {{payoutStatementStatus.thirdPartyDocumentName}}
                                            </a>
                                        </span>
                                    </div>
                                    <div class="col-lg-3" *ngIf="payoutStatementStatus.thirdPartyDocumentId && !!payoutStatementStatus.thirdPartyDocumentDeleted">
                                        {{getDocumentDeletedText(payoutStatementStatus.thirdPartyDocumentName)}}
                                    </div>
                                </div>
                                <div class="col-lg-12 margin-top-10 margin-bottom-20" *ngIf="payoutStatementStatus.statusDisplayNotes">
                                    <div class="col-lg-1 bold padding-left-20">Notes:</div>
                                    <div class="col-lg-10">
                                        <span style="white-space: pre-wrap;" [innerHtml]= "payoutStatementStatus.formattedStatusDisplayNotes"></span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>



        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-12 buttons">
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Close</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
