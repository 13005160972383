import {Component, OnInit} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Matter} from '../shared/matter';
import {currentMatter} from '../shared/current-matter';
import {DirectionReFund} from './direction-re-funds';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {
  DirectionReFundBalanceBurgerMenuTypes,
  DirectionReFundsBurgerMenuTypes,
  DirectionReFundTypes,
  MaxfundsPayableToItems
} from '../../shared-main/constants';
import {SupplementalTaskDirectionRefundsComponent} from '../supplemental-task/manage-task/supplemental-task-direction-refunds.component';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {MatterParticipant} from '../shared/matter-participant';
import {Mortgage} from '../shared/mortgage';
import Utils from '../../shared-main/utils';
import {constValues} from '../shared/const-values';
import {ProgressionStatus, StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {DialogService} from '../../shared/dialog/dialog.service';
import {DirectionRefundMaxF9Length} from '../supplemental-task/manage-task/supplemental-task-constants';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {NetOutHstSalePriceType, SalePriceAdjustment} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {Tax_RATE} from '../../shared-main/province-based-dropdowns';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import {SalePriceAdjustmentFactory} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {DocumentProductionService} from '../document-production/document-production.service';
import {DirectionReFundsPreview} from './model/direction-re-funds-preview';
import {Subscription} from 'rxjs';
import {DocumentProfileSoa} from '../../admin/document-profile/document-profile-edit/soa';
import {TrustLedgerMatter} from '../trust-ledger/trust-ledger-matter';
import {SoaTrustLedgerConfigKeys} from '../shared/soa-trustledger-collection';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {MassUpdateData} from '../../shared/tabbing/mass-update-tab';
import {TabsService} from '../../core';
import {MatterCleanUpUtil} from '../shared/matter-utils/matter-clean-up-util';

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-direction-re-funds',
  templateUrl: 'direction-re-funds.component.html',
  styleUrls: [
    './direction-re-funds.styles.scss'
  ],
  providers: [ CurrencyPipe ]
})

export class DirectionReFundsComponent implements OnInit {
  // balanceOfFundsPayableTo : DirectionReFund;
  balanceBurgerMenuItems: BurgerMenuExtendedItem[] = [];
  balanceBurgerMenuItemsWithoutDelete: BurgerMenuExtendedItem[] = [];
  balanceBurgerMenuItemsWithAll: BurgerMenuExtendedItem[] = [];
  fundsPayableToBurgerMenuItems: BurgerMenuExtendedItem[] = [];
  fundsPayableToBurgerMenuItemsWithoutDelete: BurgerMenuExtendedItem[] = [];
  fundsPayableToBurgerMenuItemsWithAll: BurgerMenuExtendedItem[] = [];
  loggedInUserLawFirmName: string;
  statementOfAdjustmentPayableTo: string;
  amountSeparator: string = '#:';
  considerationTaxes: ConsiderationTaxes;
  //balanceDueOnClosing : number;
  //preview
  previewDownloadSubscriber: Subscription;
  paperSizeCode: string;
  sufficientAuthority: string;
  vendorRELine: string;
  vendorDataCode: string;
  purchaserDataCode: string;
  addressDataCode: string;
  getGlobalMatterSub: Subscription;
  _matter: Matter;

  constructor(public documentProfileService: DocumentProfileService,
              public documentProfileCache: DocumentProfileCache,
              public currencyPipe: CurrencyPipe,
              public globalSaveModelService: GetGlobalSaveModelService,
              public dialogService: DialogService,
              public taxRateService: TaxRateService,
              public documentProductionService: DocumentProductionService,
              public tabService: TabsService) {
  }

  ngOnInit(): void {
    this.getGlobalMatterSub = this.globalSaveModelService.getItem$
    .subscribe(() => {
      if (this.isProjectSale && this.matter.isStatementAdjustmentInterimAndFinal()) {
        this.matter._directionReFundsStatusMode = this.matter.selectedProgressionStatus;
      } else {
        this.matter._directionReFundsStatusMode = ProgressionStatus.FINAL;
      }

      console.log('ngOnInit::DirectionReFundsComponent');
      this.taxRateService.cachedConsiderationTaxRate(this.matter.provinceCode)
      .subscribe(
        (ct: ConsiderationTaxes[]) => {
          if (ct && ct.length > 0) {
            this.considerationTaxes = ct.find(item => item.instanceType == Tax_RATE.HST_RATE);
          }
          this.initDirectionReFunds();
        });
    });

  }

  isValidDate(dateValue: string): boolean {
    return Utils.isValidDate(dateValue);
  }

  get matter(): Matter {
    return this._matter ? this._matter : currentMatter.value;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  get payableToFrom(): string {
    return this.loggedInUserLawFirmName ? this.loggedInUserLawFirmName + ', in trust' : '';
  }

  //Because matter reference will be changed, balanceOfFundsPayableTo should be property
  get balanceOfFundsPayableTo(): DirectionReFund {
    return this.matter.balanceOfFundsPayableTo;
  }

  isMoveUpDisabled(): boolean {
    const selectedIndex: number = this.matter.filteredDirectionReFunds.findIndex(directReFund => directReFund.isSelected);
    return selectedIndex <= 1;
  }

  isMoveDownDisabled(): boolean {
    const selectedIndex: number = this.matter.filteredDirectionReFunds.findIndex(directReFund => directReFund.isSelected);
    return selectedIndex <= 0 || selectedIndex >= this.matter.filteredDirectionReFunds.length - 1;
  }

  initDirectionReFunds() {
    if (Array.isArray(this.matter.filteredDirectionReFunds) && this.matter.filteredDirectionReFunds.length >= 2
      && this.matter.filteredDirectionReFunds[ 0 ].directionReFundType === DirectionReFundTypes.BALANCE_OF_FUNDS_PAYABLE_TO
      && this.matter.filteredDirectionReFunds[ 1 ].directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO) {
      console.log('this.matter.filteredDirectionReFunds[0]', this.matter.filteredDirectionReFunds[ 0 ]);
    } else {
      this.matter.clearFileredDirectionReFunds();
      // Add the default balanceOfFundsPayableTo item
      // this.createBalanceOfFundsPayableTo();
      // Add the default fundsPayableTo item. fundsPayableTo always start form the second one
      this.createFundsPayableTo(1);
    }

    MatterCleanUpUtil.cleanFundsPayableToWithoutReFundName(this.matter.filteredDirectionReFunds);

    this.documentProfileService.getById(this.matter.documentProfileId, sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId), false, this.matter).subscribe((profile: DocumentProfile) => {
      this.statementOfAdjustmentPayableTo = this.matter.getStatementOfAdjustmentPayableTo(profile, this.documentProfileCache.cachedDefaultDocumentProfile);
      this.loggedInUserLawFirmName = profile.firmDocumentProfile.firmName;
      // Build all BurgerMenuOptions list
      this.getFundsPayableToBurgerMenu();
      this.getBalanceOfFundsPayableToBurgerMenu();
      // Setup balanceOfFundsPayableTo and  FundsPayableTo BurgerMenuOptions
      this.updateFundsPayableToBurgerMenuOptions();
      this.updateBalanceOfFundsPayableToBurgerMenuOptions();
      this.setDefaultFocusItem();
      this.loadStatementOfAdjustmentsProfile(profile);
    });

    // this.balanceDueOnClosing = this.matter.calculateBalanceDueOnClosingCreditPurchaser();
  }

  async loadStatementOfAdjustmentsProfile(docProfile: DocumentProfile): Promise<void> {
    if (docProfile && docProfile.statementOfAdjustmentsProfile) {
      let dps: DocumentProfileSoa = docProfile.statementOfAdjustmentsProfile;
      if (docProfile.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag && docProfile.sourceDocumentProfileId) {
        let docProf: DocumentProfile = await this.documentProfileService.getById(docProfile.sourceDocumentProfileId, sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId),
          false, this.matter).toPromise();
        if (docProf) {
          dps = docProf.statementOfAdjustmentsProfile;
        }
      }
      this.paperSizeCode = dps.directionOfFundsPaperSizeCode;
      this.sufficientAuthority = dps.wordingDirectionFunds;
      this.vendorDataCode = dps.otherHeadingsVendorDataCode;
      this.purchaserDataCode = dps.otherHeadingsPurchaserDataCode;
      this.addressDataCode = dps.otherHeadingsAddressDataCode;
    }
  }

  get balanceDueOnClosing() {
    if (this.matter.isMatterProvinceABorMBorSK) {
      if (this.matter.soaTrustLedgerCollection && this.matter.soaTrustLedgerCollection.receiptTotal) {
        return this.matter.soaTrustLedgerCollection.receiptTotal.valueOf();
      }
      return 0;
    }
    return this.matter.calculateBalanceDueOnClosingCreditPurchaser();
  }

  setDefaultFocusItem() {
    if (Array.isArray(this.matter.filteredDirectionReFunds) && this.matter.filteredDirectionReFunds.length > 0
      && !this.matter.filteredDirectionReFunds.find(item => item.isSelected)) {
      this.matter.filteredDirectionReFunds[ 0 ].isSelected = true;
    }
  }

  updateFundsPayableToBurgerMenuOptions() {
    let fundsPayableTos: DirectionReFund[]
      = this.matter.filteredDirectionReFunds.filter(item => item.directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO);
    if (Array.isArray(fundsPayableTos) && fundsPayableTos.length === 1
      && !fundsPayableTos[ 0 ].amount && !fundsPayableTos[ 0 ].reFundName) {
      this.fundsPayableToBurgerMenuItems = this.fundsPayableToBurgerMenuItemsWithoutDelete;
    } else {
      this.fundsPayableToBurgerMenuItems = this.fundsPayableToBurgerMenuItemsWithAll;
    }
  }

  updateBalanceOfFundsPayableToBurgerMenuOptions() {
    if (this.balanceOfFundsPayableTo && !this.balanceOfFundsPayableTo.reFundName) {
      this.balanceBurgerMenuItems = this.balanceBurgerMenuItemsWithoutDelete;
    } else {
      this.balanceBurgerMenuItems = this.balanceBurgerMenuItemsWithAll;
    }

  }

  deleteBalanceOfFundsPayableTo() {
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this row?', false, 'Delete').subscribe(res => {
      if (res) {
        this.matter.filteredDirectionReFunds.shift();
        // this.createBalanceOfFundsPayableTo();
        this.updateBalanceOfFundsPayableToBurgerMenuOptions();
        this.enableSave();
      }
    });
  }

  // createBalanceOfFundsPayableTo() {
  //     this.balanceOfFundsPayableTo = new DirectionReFund();
  //     this.balanceOfFundsPayableTo.directionReFundType = DirectionReFundTypes.BALANCE_OF_FUNDS_PAYABLE_TO;
  //     this.matter.filteredDirectionReFunds.unshift(this.balanceOfFundsPayableTo);
  // }

  createFundsPayableTo(index: number): DirectionReFund {
    const fundsPayableTo: DirectionReFund = new DirectionReFund();
    fundsPayableTo.directionReFundType = DirectionReFundTypes.FUNDS_PAYABLE_TO;
    fundsPayableTo.progressionStatus = this.isProjectConfigDocForDirectionReFund ? this.matter._directionReFundsStatusMode : ProgressionStatus.FINAL;
    this.matter.filteredDirectionReFunds.splice(index, 0, fundsPayableTo);

    return fundsPayableTo;
  }

  getBalanceOfFundsPayableToBurgerMenu() {
    this.balanceBurgerMenuItems = [];
    // Delete Option
    this.createBalanceDeleteOptionBurgerMenuItems();
    //Option 1 (LAW_FIRM) and Option 2 (VENDOR)
    this.createLawFirmVendorBurgerMenuItems(true);

    this.balanceBurgerMenuItemsWithAll = this.balanceBurgerMenuItems;

    if (Array.isArray(this.balanceBurgerMenuItems) && this.fundsPayableToBurgerMenuItems.length > 0) {
      this.balanceBurgerMenuItemsWithoutDelete
        = this.balanceBurgerMenuItemsWithAll.filter(item => item.type !== DirectionReFundBalanceBurgerMenuTypes.DELETE);
    }
  }

  createBalanceDeleteOptionBurgerMenuItems() {
    const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = 'Delete';
    burgerMenuItem.type = DirectionReFundBalanceBurgerMenuTypes.DELETE;
    burgerMenuItem.underline = true;
    this.balanceBurgerMenuItems.unshift(burgerMenuItem);
  }

  createLawFirmVendorBurgerMenuItems(addToBalanceBurgerMenu: boolean) {
    const mainClientsNamesArray: string[] = this.matter.mainClientsNamesArray;
    const mainTypeName: string = this.matter.mainClientTypeName;
    const mainClientsName: string = Utils.concatenateNames(mainClientsNamesArray, mainTypeName);

    let burgerMenuLawFirmItemName: string;
    if (this.matter.isSale) {
      if (this.matter.statementOfAdjustmentPayable && this.matter.statementOfAdjustmentPayable.payableTo) {
        burgerMenuLawFirmItemName = this.matter.statementOfAdjustmentPayable.payableTo;
      } else if (this.statementOfAdjustmentPayableTo) {
        burgerMenuLawFirmItemName = this.statementOfAdjustmentPayableTo;
      }
    } else if (this.loggedInUserLawFirmName) {
      burgerMenuLawFirmItemName = this.loggedInUserLawFirmName + ', in trust';
    }

    if (burgerMenuLawFirmItemName) {
      const burgerMenuLawFirmItem: BurgerMenuExtendedItem =
        SupplementalTaskDirectionRefundsComponent.createBurgerMenuItem(burgerMenuLawFirmItemName,
          burgerMenuLawFirmItemName);
      if (addToBalanceBurgerMenu) {
        burgerMenuLawFirmItem.type = DirectionReFundBalanceBurgerMenuTypes.LAW_FIRM;
        this.balanceBurgerMenuItems.push(burgerMenuLawFirmItem);
      } else {
        burgerMenuLawFirmItem.type = DirectionReFundsBurgerMenuTypes.LAW_FIRM;
        burgerMenuLawFirmItem.topline = true;
        this.fundsPayableToBurgerMenuItems.push(burgerMenuLawFirmItem);
      }
    }

    if (mainClientsName) {
      const burgerMenuVendorItem: BurgerMenuExtendedItem =
        SupplementalTaskDirectionRefundsComponent.createBurgerMenuItem(mainClientsName, mainClientsName);
      burgerMenuVendorItem.key = Utils.concatenateNames(mainClientsNamesArray);
      if (addToBalanceBurgerMenu) {
        burgerMenuVendorItem.type = DirectionReFundBalanceBurgerMenuTypes.VENDOR;
        this.balanceBurgerMenuItems.push(burgerMenuVendorItem);
      } else {
        burgerMenuVendorItem.type = DirectionReFundsBurgerMenuTypes.VENDOR;
        this.fundsPayableToBurgerMenuItems.push(burgerMenuVendorItem);
      }
    }
  }

  clickBalanceOfFundsPayableToBurgerMenu(clickedMenuOption: BurgerMenuExtendedItem) {
    switch (clickedMenuOption.type) {
      case DirectionReFundBalanceBurgerMenuTypes.DELETE:
        this.deleteBalanceOfFundsPayableTo();
        break;

      case DirectionReFundBalanceBurgerMenuTypes.LAW_FIRM:
        this.popupLawFirmFundsPayable(this.balanceOfFundsPayableTo, clickedMenuOption);
        break;
      case DirectionReFundBalanceBurgerMenuTypes.VENDOR:
        this.popupVendorFundsPayable(this.balanceOfFundsPayableTo, clickedMenuOption);
        break;
      default :
        break;
    }

  }

  popupLawFirmFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      directionReFund.reFundName = clickedMenuOption.text;
      //directionReFund.amount = 0;
    }
    this.enableSave();
  }

  popupVendorFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      directionReFund.reFundName = clickedMenuOption.key;
      //directionReFund.amount = 0;
    }
    this.enableSave();
  }

  popupItemWithAmountInFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption && clickedMenuOption.key) {
      let arrayOfStrings: string[] = clickedMenuOption.key.split(this.amountSeparator);
      if (Array.isArray(arrayOfStrings) && arrayOfStrings.length === 2) {
        directionReFund.reFundName = arrayOfStrings[ 0 ];
        if (arrayOfStrings[ 1 ]) {
          directionReFund.amount = Number(arrayOfStrings[ 1 ]);
        } else {
          directionReFund.amount = 0;
        }
      }
    }
    this.enableSave();
  }

  popupTotalFeesFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      if (clickedMenuOption.key) {
        directionReFund.amount = Number(clickedMenuOption.key);
      } else {
        directionReFund.amount = 0;
      }
      // If there is no Firm Name stated in user's document profile then,
      // editable field will be populated with text "Unspecified Law Firm"
      // and $ field will be populated with $value
      if (this.payableToFrom) {
        directionReFund.reFundName = this.payableToFrom;
      } else {
        directionReFund.reFundName = 'Unspecified Law Firm';
      }
    }
    this.enableSave();
  }

  popupVendorCommissionPayableFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      if (clickedMenuOption.key) {
        directionReFund.amount = Number(clickedMenuOption.key);
      } else {
        directionReFund.amount = 0;
      }
      const realEstateBroker: MatterParticipant = this.matter.realEstateBroker;
      if (realEstateBroker && realEstateBroker.contact && realEstateBroker.contact.organizationName) {
        directionReFund.reFundName = realEstateBroker.contact.organizationName;
      } else {
        directionReFund.reFundName = 'Unknown R.E. Broker';
      }
    }
    this.enableSave();
  }

  popupPurchaserCommissionPayableFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      if (clickedMenuOption.key) {
        directionReFund.amount = Number(clickedMenuOption.key);
      } else {
        directionReFund.amount = 0;
      }
      const purchaserRealEstateBroker: MatterParticipant = this.matter.purchaserRealEstateBroker;
      if (purchaserRealEstateBroker && purchaserRealEstateBroker.contact && purchaserRealEstateBroker.contact.organizationName) {
        directionReFund.reFundName = purchaserRealEstateBroker.contact.organizationName;
      } else {
        directionReFund.reFundName = 'Unknown R.E. Broker';
      }
    }
    this.enableSave();
  }

  // When available, if Menu option 6 is selected, then editable field will be populated
  // with text ''TARION" and the $ field will be populated with $ amount
  popupTarionFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      directionReFund.reFundName = 'TARION';
      if (clickedMenuOption.key) {
        directionReFund.amount = Number(clickedMenuOption.key);
      } else {
        directionReFund.amount = 0;
      }
    }
    this.enableSave();
  }

  popupSalePriceAdjustmentFundsPayable(directionReFund: DirectionReFund, clickedMenuOption: BurgerMenuExtendedItem) {
    if (directionReFund && clickedMenuOption) {
      directionReFund.reFundName = clickedMenuOption.text;
      if (clickedMenuOption.key) {
        directionReFund.amount = Number(clickedMenuOption.key);
      } else {
        directionReFund.amount = 0;
      }
    }
    this.enableSave();
  }

  getFundsPayableToBurgerMenu() {
    this.fundsPayableToBurgerMenuItems = [];
    // Add Option
    this.createAddOptionBurgerMenuItems();
    // Delete Option
    this.createDeleteOptionBurgerMenuItems();

    //Option 1 (LAW_FIRM) and Option 2 (VENDOR)
    this.createLawFirmVendorBurgerMenuItems(false);

    //Option 3 (EXISTING_MORTGAGE and CHEQUE_PAYABLE_TO)
    const existingMortgages: Mortgage[] = this.matter.existingMortgages;
    if (Array.isArray(existingMortgages)) {
      existingMortgages.forEach(mortgage => {
        this.createExistingMortgageBurgerMenuItem(mortgage);
        this.createChequePayableToBurgerMenuItem(mortgage);
      });
    }

    //Option 4 (TOTAL_FEE)
    this.createTotalFeeBurgerMenuItems();
    //Option 5 (COMMISSION_PAYABLE)
    if (this.matter.commissionBasedOnFixedPercentageOfSalePrice) {
      if (this.matter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
        this.createVendorCommissionPayableBurgerMenuItems();
        this.createPurchaserCommissionPayableBurgerMenuItems();
      } else if (this.matter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY) {
        this.createVendorCommissionPayableBurgerMenuItems();

      }
    }
    //Option 6 (TARION)
    this.createTarionWarrantyBurgerMenuItems();

    this.createSalePriceMenuItems();
    this.createInterimOccupancyFeeMenuItems();
    this.createReserveFundMenuItems();
    this.createProratedAmount();

    //Option 7 (lateInterestAmountTotal)
    this.createLateInterestAmountTotal();
    //Option 8 (Supplemental Task)
    this.createOtherSupplementalTask();
    if (this.matter.isSale || this.matter.isProjectSale) {
      this.createPropertyTaxPaidFromTrustAccountMenuItems();
    }

    this.fundsPayableToBurgerMenuItemsWithAll = this.fundsPayableToBurgerMenuItems;
    if (Array.isArray(this.fundsPayableToBurgerMenuItems) && this.fundsPayableToBurgerMenuItems.length > 0) {
      this.fundsPayableToBurgerMenuItemsWithoutDelete
        = this.fundsPayableToBurgerMenuItems.filter(item => item.type !== DirectionReFundsBurgerMenuTypes.DELETE);
    }
  }

  createLateInterestAmountTotal(): void {
    if (!this.matter.isMatterProvinceON && this.matter.closingDatePayment && this.matter.closingDatePayment.lateInterestAmountTotal > 0) {
      this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.LATE_CLOSING_INTEREST,
        `${ this.vendorsName }(Late Closing Interest) = ${ Utils.formattedCurrencyValue(this.matter.closingDatePayment.lateInterestAmountTotal) }`,
        `${ this.vendorsName }${ this.amountSeparator }${ this.matter.closingDatePayment.lateInterestAmountTotal }`,
        this.fundsPayableToBurgerMenuItems
      );
    }

  }

  createOtherSupplementalTask(): void {
    SupplementalTaskDirectionRefundsComponent.createFundsPayableToMenuItems(this.fundsPayableToBurgerMenuItems, this.payableToFrom, this.matter, this.loggedInUserLawFirmName, false, this.amountSeparator);
  }

  createAddOptionBurgerMenuItems() {
    const burgerMenuAddItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
    burgerMenuAddItem.text = 'Add';
    burgerMenuAddItem.type = DirectionReFundsBurgerMenuTypes.ADD;
    this.fundsPayableToBurgerMenuItems.push(burgerMenuAddItem);
  }

  createDeleteOptionBurgerMenuItems() {
    const burgerMenuDeleteItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
    burgerMenuDeleteItem.text = 'Delete';
    burgerMenuDeleteItem.type = DirectionReFundsBurgerMenuTypes.DELETE;
    burgerMenuDeleteItem.underline = true;
    this.fundsPayableToBurgerMenuItems.push(burgerMenuDeleteItem);
  }

  clickFundsPayableToBurgerMenu(clickedMenuOption: BurgerMenuExtendedItem, directionReFund: DirectionReFund, index: number) {
    switch (clickedMenuOption.type) {
      //Menu ADD Option
      case DirectionReFundsBurgerMenuTypes.ADD:
        this.addFundsPayable(index + 1);
        break;
      //Menu DELETE Option
      case DirectionReFundsBurgerMenuTypes.DELETE:
        this.deleteFundsPayable(index);
        break;
      //Menu Option 1
      case DirectionReFundsBurgerMenuTypes.LAW_FIRM:
        this.popupLawFirmFundsPayable(directionReFund, clickedMenuOption);
        break;
      //Menu Option 2
      case DirectionReFundsBurgerMenuTypes.VENDOR:
        this.popupVendorFundsPayable(directionReFund, clickedMenuOption);
        break;
      //Menu Option 3
      case DirectionReFundsBurgerMenuTypes.EXISTING_MORTGAGE:
      case DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT:
      case DirectionReFundsBurgerMenuTypes.INTERIM_OCCUPANCY_FEES:
      case DirectionReFundsBurgerMenuTypes.INTERIM_OCCUPANCY_FEES_AND_TARION:
      case DirectionReFundsBurgerMenuTypes.RESERVE_FUND:
      case DirectionReFundsBurgerMenuTypes.PRORATE_AMOUNT:
      case DirectionReFundsBurgerMenuTypes.CHEQUE_PAYABLE_TO:
      case DirectionReFundsBurgerMenuTypes.LATE_CLOSING_INTEREST:
      case DirectionReFundsBurgerMenuTypes.OTHER_SUPPLEMENTAL_TASK:
      case DirectionReFundsBurgerMenuTypes.TAX_DEPARTMENT:
        this.popupItemWithAmountInFundsPayable(directionReFund, clickedMenuOption);
        break;
      //Menu Option 4
      case DirectionReFundsBurgerMenuTypes.TOTAL_FEE:
        this.popupTotalFeesFundsPayable(directionReFund, clickedMenuOption);
        break;
      //Menu Option 5 REALESTATEBROKER
      case DirectionReFundsBurgerMenuTypes.VENDOR_COMMISSION_PAYABLE:
        this.popupVendorCommissionPayableFundsPayable(directionReFund, clickedMenuOption);
        break;
      case DirectionReFundsBurgerMenuTypes.PURCHASER_COMMISSION_PAYABLE:
        this.popupPurchaserCommissionPayableFundsPayable(directionReFund, clickedMenuOption);

        break;
      //Menu Option 6
      case DirectionReFundsBurgerMenuTypes.TARION:
        this.popupTarionFundsPayable(directionReFund, clickedMenuOption);
        break;
      default :
        break;
    }
  }

  addFundsPayable(position: number) {
    let fundsPayableTos: DirectionReFund[]
      = this.matter.filteredDirectionReFunds.filter(item => item.directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO);
    if (Array.isArray(fundsPayableTos) && fundsPayableTos.length > MaxfundsPayableToItems) {
      this.dialogService.confirm('Information', 'A maximum of 20 "Funds payable to" may be added', true, 'OK').subscribe(res => {
      });

    } else {
      const directionReFund: DirectionReFund = this.createFundsPayableTo(position);
      this.selectFundsPayableToItem(directionReFund);
      this.updateFundsPayableToBurgerMenuOptions();
      this.enableSave();
    }
  }

  deleteFundsPayable(position: number) {
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this row?', false, 'Delete').subscribe(res => {
      if (res) {
        if (position > 0 && position < this.matter.filteredDirectionReFunds.length) {
          this.matter.filteredDirectionReFunds.splice(position, 1);
        }

        if (this.matter.filteredDirectionReFunds.length === 1) {
          this.createFundsPayableTo(1);
          this.updateFundsPayableToBurgerMenuOptions();
        }
        this.enableSave();
      }
    });
  }

  selectFundsPayableToItem(directionReFund: DirectionReFund) {
    this.matter.filteredDirectionReFunds.forEach(item => item.isSelected = false);
    directionReFund.isSelected = true;
  }

  selectableItem(directionReFund: DirectionReFund): boolean {
    return directionReFund && directionReFund.isSelected;
  }

  createExistingMortgageBurgerMenuItem(mortgage: Mortgage) {
    let burgerMenuItemText: string;
    let mortgageePrimaryPrivateLenderName: string;

    if (mortgage.isMortgageDispositionDischarged() || mortgage.isMortgageDispositionBridgeFinancing()) {
      mortgageePrimaryPrivateLenderName = this.matter.getMortgageeOrPrimaryPrivateLenderName(mortgage);
      // "Existing <Mortgage Number> Mtg:
      burgerMenuItemText = 'Existing ' + this.matter.getExistingMortgageDisplayOrder(mortgage) + ' Mtg:';
      // "Existing <Mortgage Number> Mtg: <Field value of Mortgagee OR Primary Other Lender as the case may be> = <Value of Amount Payable to discharge this mortgage>"
      burgerMenuItemText += (mortgageePrimaryPrivateLenderName ? ' ' + mortgageePrimaryPrivateLenderName + ' ' : '')
        + ' = ' + Utils.formattedCurrencyValue(mortgage.amountPayableToDischarge);
      const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
      burgerMenuItem.text = burgerMenuItemText;
      if (mortgageePrimaryPrivateLenderName) {
        burgerMenuItem.key = mortgageePrimaryPrivateLenderName + this.amountSeparator + mortgage.amountPayableToDischarge;
      } else {
        burgerMenuItem.key = 'Unknown Mortgagee/Private Lender' + this.amountSeparator + mortgage.amountPayableToDischarge;
      }
      burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.EXISTING_MORTGAGE;
      this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
    }
  }

  createChequePayableToBurgerMenuItem(mortgage: Mortgage) {
    let burgerMenuItemText: string;
    let mortgageePrimaryPrivateLenderName: string;

    if (mortgage.mortgagePayout && mortgage.mortgagePayout.separateChequeForDischargeFee === 'YES') {
      mortgageePrimaryPrivateLenderName = this.matter.getMortgageeOrPrimaryPrivateLenderName(mortgage);
      // "<Mortgage Number> Mtg Discharge Fee to
      const mortgageNumber = this.matter.getExistingMortgageDisplayOrder(mortgage);
      burgerMenuItemText = mortgageNumber + ' Mtg Discharge Fee to ';
      if (mortgage.mortgagePayout.chequePayableTo || mortgage.mortgagePayout.dischargeAmount) {
        const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
        // "<Mortgage Number> Mtg Discharge Fee to <value of 'Cheque payable to' field in Mortgage Payout Calculation section of
        // Existing Mortgage (Tab F)> : $ <value of 'Amount' field>"
        if (mortgage.mortgagePayout.chequePayableTo) {
          burgerMenuItem.key = mortgage.mortgagePayout.chequePayableTo;
          burgerMenuItemText += mortgage.mortgagePayout.chequePayableTo + ' = '
            + Utils.formattedCurrencyValue(mortgage.mortgagePayout.dischargeAmount);
        }
          // "<Mortgage Number> Mtg Discharge Fee to <Field value of Mortgagee OR Primary Other Lender
        // as the case may be> : $ <value of 'Amount' field>"
        else if (mortgageePrimaryPrivateLenderName) {
          burgerMenuItem.key = mortgageePrimaryPrivateLenderName;
          burgerMenuItemText += mortgageePrimaryPrivateLenderName + ' = '
            + Utils.formattedCurrencyValue(mortgage.mortgagePayout.dischargeAmount);
        }
        // "<Mortgage Number> Mtg Discharge Fee : $ <value of 'Amount' field>"
        else {
          burgerMenuItem.key = 'Unknown Discharge Fee recipient ';
          burgerMenuItemText = mortgageNumber + ' Mtg Discharge Fee ';
          burgerMenuItemText += '= ' + Utils.formattedCurrencyValue(mortgage.mortgagePayout.dischargeAmount);
        }

        burgerMenuItem.text = burgerMenuItemText;
        // When either of 'Cheque payable to' or  Mortgagee/Other Lender are not known,
        // then the editable field will be populated with text
        // "Unknown Discharge Fee recipient" $ Amount will be populated in the $ field
        burgerMenuItem.key += this.amountSeparator + mortgage.mortgagePayout.dischargeAmount;
        burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.CHEQUE_PAYABLE_TO;
        this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
      }

    }
  }

  createTotalFeeBurgerMenuItems() {
    const totalFee = this.matter.soaTrustLedgerCollection && this.matter.soaTrustLedgerCollection.total();
    if (this.matter.soaTrustLedgerCollection && totalFee > 0) {
      const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();

      burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.TOTAL_FEE;
      burgerMenuItem.text = 'Total Fees and Disbursements from ' + this.matter.soaPrimarySheetDetail + ' Statement of Account ' + ' = '
        + Utils.formattedCurrencyValue(totalFee);
      burgerMenuItem.key = totalFee + '';

      this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
    }
    this.matter.secondarySoaSheetsCollection.forEach(collection => {
      if (collection.total() > 0) {
        const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
        let secondarySoaSheet = this.matter.secondarySoaSheets.find(sheet => sheet.id == collection.sheetId);
        burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.TOTAL_FEE;
        burgerMenuItem.text = 'Total Fees and Disbursements from ' + (secondarySoaSheet && secondarySoaSheet.details ? secondarySoaSheet.details : '') + ' Statement of Account ' + ' = '
          + Utils.formattedCurrencyValue(collection.total());
        burgerMenuItem.key = collection.total() + '';

        this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
      }
    });
  }

  createVendorCommissionPayableBurgerMenuItems() {
    if (this.matter.brokerCommission && this.isVendorCommission()) {
      const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();

      burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.VENDOR_COMMISSION_PAYABLE;
      burgerMenuItem.text
        = 'Balance of Vendor\'s Real Estate Commission Owing '
        + ' = ' + Utils.formattedCurrencyValue(this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker);
      burgerMenuItem.key = this.matter.brokerCommission.vendorCommission.commissionPayableToVendorBroker + '';

      this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
    }
  }

  createPurchaserCommissionPayableBurgerMenuItems() {
    if (this.matter.brokerCommission && this.isPurchaserCommission()) {
      const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();

      burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.PURCHASER_COMMISSION_PAYABLE;
      burgerMenuItem.text
        = 'Balance of Purchaser\'s Real Estate Commission Owing '
        + ' = ' + Utils.formattedCurrencyValue(this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker);
      burgerMenuItem.key = this.matter.brokerCommission.purchaserCommission.commissionPayableToPurchaserBroker + '';

      this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
    }
  }

  isVendorCommission(): boolean {
    const label = this.matter.getCoreCommissionPayableToVendorBrokerLabel();
    if (label) {
      return label.indexOf('Commission') > -1 ? true : false;
    } else {
      return false;
    }
  }

  isPurchaserCommission(): boolean {
    const label = this.matter.getCoreCommissionPayableToPurchaserBrokerLabel();
    if (label) {
      return label.indexOf('Commission') > -1 ? true : false;
    } else {
      return false;
    }
  }

  calculateTarionWarrantyFee(tarionWarranty: StatementAdjustment): number {
    let soAdjTarionWarrantyCreditVendorFee: number = 0;
    if (tarionWarranty && tarionWarranty.soAdjTarionWarranty) {
      if (tarionWarranty.soAdjTarionWarranty.amountAdditionalConsiderationToVendor === 'NO') {
        soAdjTarionWarrantyCreditVendorFee = tarionWarranty.soAdjTarionWarranty.enrolmentFeePlusTax;
      }
      if (tarionWarranty.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE') {
        soAdjTarionWarrantyCreditVendorFee = tarionWarranty.soAdjTarionWarranty.enrolmentFee;
      }
    }
    return soAdjTarionWarrantyCreditVendorFee;
  }

  createTarionWarrantyBurgerMenuItems() {
    const tarionWarrantys: StatementAdjustment[] = this.matter.getTarionWarrantyStatementAdjustments();
    if (Array.isArray(tarionWarrantys) && tarionWarrantys.length > 0) {
      const tarionWarranty: StatementAdjustment = tarionWarrantys[ 0 ];
      if (tarionWarranty.soAdjTarionWarranty) {
        if (tarionWarranty.soAdjTarionWarranty.creditType === 'VENDOR') {
          const soAdjTarionWarrantyCreditVendorFee: number
            = this.calculateTarionWarrantyFee(tarionWarranty);
          const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();

          burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.TARION;
          burgerMenuItem.text = 'TARION Enrolment Fee ' + ' = '
            + Utils.formattedCurrencyValue(soAdjTarionWarrantyCreditVendorFee);
          burgerMenuItem.key = soAdjTarionWarrantyCreditVendorFee + '';
          this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
        }

        if (tarionWarranty.soAdjTarionWarranty.creditType === 'PURCHASER'
          || tarionWarranty.soAdjTarionWarranty.creditType === 'NO_ADJUSTMENT') {
          const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();

          burgerMenuItem.type = DirectionReFundsBurgerMenuTypes.TARION;
          burgerMenuItem.text = 'TARION Enrolment Fee = $ 0.00';
          burgerMenuItem.key = 0 + '';
          this.fundsPayableToBurgerMenuItems.push(burgerMenuItem);
        }
      }

    }
  }

  enableSave(doNotSetMassUpdateDirty: boolean = false) {
    this.matter.dirty = true;
    if (!doNotSetMassUpdateDirty) {
      this.setDirtyForMassUpdate();
    }
  }

  moveUp(): void {
    const selectedIndex: number = this.matter.filteredDirectionReFunds.findIndex(directReFund => directReFund.isSelected);
    if (selectedIndex > 1) {
      const upItem: DirectionReFund = this.matter.filteredDirectionReFunds[ selectedIndex - 1 ];
      this.matter.filteredDirectionReFunds[ selectedIndex - 1 ] = this.matter.filteredDirectionReFunds[ selectedIndex ];
      this.matter.filteredDirectionReFunds[ selectedIndex ] = upItem;
      this.enableSave();
    }
  }

  moveDown(): void {
    const selectedIndex: number = this.matter.filteredDirectionReFunds.findIndex(directReFund => directReFund.isSelected);
    if (selectedIndex > 0 && selectedIndex < this.matter.filteredDirectionReFunds.length - 1) {
      const downItem: DirectionReFund = this.matter.filteredDirectionReFunds[ selectedIndex + 1 ];
      this.matter.filteredDirectionReFunds[ selectedIndex + 1 ] = this.matter.filteredDirectionReFunds[ selectedIndex ];
      this.matter.filteredDirectionReFunds[ selectedIndex ] = downItem;
      this.enableSave();
    }
  }

  onBalanceChange() {
    this.updateBalanceOfFundsPayableToBurgerMenuOptions();
    this.enableSave();
  }

  onFundsPayableToChange() {
    let fundsPayableTos: DirectionReFund[]
      = this.matter.filteredDirectionReFunds.filter(item => item.directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO);
    if (Array.isArray(fundsPayableTos) && fundsPayableTos.length === 1) {
      this.updateFundsPayableToBurgerMenuOptions();
    }
    this.enableSave();
  }

  dynamicHelpFunction(): string {
    let balancePayableTo: string;
    balancePayableTo = 'F9 = ' + this.payableToFrom;

    if (balancePayableTo && (balancePayableTo.length > DirectionRefundMaxF9Length)) {
      balancePayableTo = balancePayableTo.slice(0, DirectionRefundMaxF9Length) + '...';
    }
    return balancePayableTo;
  }

  handleF9BalancePayableToAction() {
    this.balanceOfFundsPayableTo.reFundName = this.payableToFrom;
  }

  fieldValueBlur(directionReFund: DirectionReFund) {
    if (!directionReFund.reFundName) {
      directionReFund.amount = null;
    }
  }

  calculateBalancePayableTo(): number {
    this.matter.updateBalanceOfFundsPayableTo();
    if (this.balanceOfFundsPayableTo.amount) {
      this.updateBalanceOfFundsPayableToBurgerMenuOptions();
    }
    return this.balanceOfFundsPayableTo.amount;
  }

  get isProjectSale(): boolean {
    return (this.matter && this.matter.isProjectSale);
  }

  selectProgressionType(type: string) {
    this.matter._directionReFundsStatusMode = (type == 'Interim' ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    this.matter.selectedProgressionStatus = this.matter._directionReFundsStatusMode;
    this.enableSave(true);
  }

  get isProjectConfigDocForDirectionReFund(): boolean {
    return this.matter && this.matter.isProjectConfigDocForDirectionReFund();
  }

  createSalePriceMenuItems() {
    let spAdj: SalePriceAdjustment = this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment;
    if (spAdj && this.matter.considerationLtt.salePriceAdjustment.isInclusivePrice()) {

      let federalHstRate: number = spAdj.calculateHstPortion(Number(this.considerationTaxes.hstFederalPortion));
      let provinceHstRate: number = this.matter && this.matter.isMatterProvinceMBorSK ? 0 : spAdj.calculateHstPortion(Number(this.considerationTaxes.hstProvincialPortion));

      this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } on Sale Price) = ${ Utils.formattedCurrencyValue(federalHstRate + provinceHstRate) }`,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } on Sale Price)${ this.amountSeparator }${ federalHstRate + provinceHstRate }`,
        this.fundsPayableToBurgerMenuItems
      );

      let federalRebate: number = spAdj.calculateRebate(this.considerationTaxes.hstFederalPortion);
      let provincialRebate: number = spAdj.calculateProvincialRebate(this.considerationTaxes.hstProvincialPortion);
      this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Rebate) = ${ Utils.formattedCurrencyValue(federalRebate + provincialRebate) }`,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Rebate)${ this.amountSeparator }${ federalRebate + provincialRebate }`,
        this.fundsPayableToBurgerMenuItems
      );

      this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } on Sale Price minus ${ this.considerationTaxes.provinceDefaultTaxRate } Rebate) = ${ Utils.formattedCurrencyValue(federalHstRate + provinceHstRate - federalRebate - provincialRebate) }`,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } on Sale Price minus ${ this.considerationTaxes.provinceDefaultTaxRate } Rebate)${ this.amountSeparator }${ federalHstRate + provinceHstRate - federalRebate - provincialRebate }`,
        this.fundsPayableToBurgerMenuItems
      );

      if (this.matter.isMatterProvinceON) {
        this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT,
          `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Ontario portion) = ${ Utils.formattedCurrencyValue(provinceHstRate) }`,
          `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Ontario portion)${ this.amountSeparator }${ provinceHstRate }`,
          this.fundsPayableToBurgerMenuItems
        );

        this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT,
          `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Rebate Ontario portion) = ${ Utils.formattedCurrencyValue(provincialRebate) }`,
          `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Rebate Ontario portion)${ this.amountSeparator }${ provincialRebate }`,
          this.fundsPayableToBurgerMenuItems
        );
      }
    } else if (this.isProjectSale) {
      // a what-if scenario
      let spAdjFake: SalePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, spAdj);
      spAdjFake.netOutHstFromHSTSalePrice = <NetOutHstSalePriceType>constValues.netOutHst.YES_FACTOR_IN_HST_REBATE;
      let federalRebate: number = spAdjFake.calculateRebate(this.considerationTaxes.hstFederalPortion);
      let provincialRebate: number = spAdjFake.calculateProvincialRebate(this.considerationTaxes.hstProvincialPortion);
      this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.SALE_PRICE_ADJUSTMENT,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Rebate) = ${ Utils.formattedCurrencyValue(federalRebate + provincialRebate) }`,
        `${ this.vendorsName }(${ this.considerationTaxes.provinceDefaultTaxRate } Rebate)${ this.amountSeparator }${ federalRebate + provincialRebate }`,
        this.fundsPayableToBurgerMenuItems
      );
    }
  }

  createInterimOccupancyFeeMenuItems() {
    // do not use statementAdjustments here, use interimStatementAdjustments as project.docRegistration.docsForDirection might be set to NO
    // which means that interim/final options are not visible for this component which might be disconnected from statement of adjustment progression status
    let soAdjInterimOccFee: StatementAdjustment[] = this.matter.interimStatementAdjustments.filter(soAdj => soAdj.isInterimOccupancyFee());
    let ifFinalOccFeePresent = this.matter.finalStatementAdjustments.some(soAdj => soAdj.isFinalOccupancyFee());
    if (!ifFinalOccFeePresent && Array.isArray(soAdjInterimOccFee) && soAdjInterimOccFee.length > 0) { // only when no Final Occupancy Fees are available but we have INterim Occupancy Fees
      let soAdjInterimOccFeeTotal: number = soAdjInterimOccFee.filter(soAdj => soAdj.isInterimOccupancyFee())
      .reduce(function (sum, adj) {
        return Number(sum) + Number(adj.amount);
      }, 0);

      if (soAdjInterimOccFeeTotal > 0) {
        this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.INTERIM_OCCUPANCY_FEES,
          `Interim Occupancy Fee From Statement of Adjustments : ${ Utils.formattedCurrencyValue(soAdjInterimOccFeeTotal) }`,
          `${ this.vendorsName }${ this.amountSeparator }${ soAdjInterimOccFeeTotal }`,
          this.fundsPayableToBurgerMenuItems
        );
        // throw Tarion into mixture
        let tarionAdj: StatementAdjustment[] = this.matter.allStatementAdjustments.filter(soAdj => soAdj.isTarionWarranty());
        if (Array.isArray(tarionAdj) && tarionAdj.length > 0) {
          let tarionEnrolmentFeesTotal: number = tarionAdj.reduce(function (sum, adj) {
            return Number(sum) + Number(adj.soAdjTarionWarranty.enrolmentFee);
          }, 0);

          this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.INTERIM_OCCUPANCY_FEES_AND_TARION,
            `Occupancy and TARION Enrolment Fees : ${ Utils.formattedCurrencyValue(soAdjInterimOccFeeTotal + tarionEnrolmentFeesTotal) }`,
            `Occupancy and TARION Enrolment Fees${ this.amountSeparator }${ soAdjInterimOccFeeTotal + tarionEnrolmentFeesTotal }`,
            this.fundsPayableToBurgerMenuItems
          );
        }
      }
    }
  }

  get statementAdjustment(): StatementAdjustment[] {
    if (this.matter._directionReFundsStatusMode === 'FINAL') {
      return this.matter.finalStatementAdjustments;
    }
    if (this.matter._directionReFundsStatusMode === 'INTERIM') {
      return this.matter.interimStatementAdjustments;
    }
    return [];
  }

  createReserveFundMenuItems() {
    let soAdjReserveFund: StatementAdjustment[] = this.statementAdjustment.filter(soAdj => soAdj.isReserveFundAdjustment());
    if (Array.isArray(soAdjReserveFund) && soAdjReserveFund.length > 0) {
      let soAdjReserveFundTotal: number = soAdjReserveFund.reduce(function (sum, adj) {
        return Number(sum) + Number(adj.amount);
      }, 0);

      if (soAdjReserveFundTotal > 0) {

        this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.INTERIM_OCCUPANCY_FEES_AND_TARION,
          `Reserve Fund from Statement of Adjustments : ${ Utils.formattedCurrencyValue(soAdjReserveFundTotal) } ${ this.getCondoCorporationName() }`,
          `Reserve Fund from Statement of Adjustments ${ this.getCondoCorporationName() }${ this.amountSeparator }${ soAdjReserveFundTotal }`,
          this.fundsPayableToBurgerMenuItems
        );
      }
    }
  }

  createPropertyTaxPaidFromTrustAccountMenuItems() {
    if (this.matter.soaTrustLedgerCollection) {
      let paidTaxItems: TrustLedgerMatter[] = this.matter.soaTrustLedgerCollection.matterTrustLedgers.filter(item => item.itemKey == SoaTrustLedgerConfigKeys.L1_TAX_PAID_FROM_TRUST_ACCOUNT);
      if (Array.isArray(paidTaxItems) && paidTaxItems.length > 0) {
        for (let i: number = 0; i < paidTaxItems.length; i++) {
          let paidTaxItemName = paidTaxItems[ i ].itemName.slice(5);
          this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.TAX_DEPARTMENT,
            paidTaxItemName + ` = ${ Utils.formattedCurrencyValue(paidTaxItems[ i ].itemValue) }`,
            paidTaxItemName + `${ this.amountSeparator }${ paidTaxItems[ i ].itemValue }`,
            this.fundsPayableToBurgerMenuItems
          );
        }
      }
    }
  }

  getCondoCorporationName(): string {
    let condoCorp = this.matter.matterParticipants.find(mp => mp.matterParticipantRole === 'CONDO_CORPORATION');
    return (condoCorp) ? condoCorp.contact.displayName : 'Unknown Condo Corporation';
  }

  createProratedAmount() {
    //if (! this.matter.isAdjustAsAtSpecify()) { // only when adjustment is at Closing date and NOT at a specified date
    let soAdjProrated: StatementAdjustment[] = this.matter.allStatementAdjustments.filter(soAdj => soAdj.isOtherProrated() && soAdj.amount > 0 && !soAdj.soAdjOtherProrated.infoOnly);
    if (Array.isArray(soAdjProrated) && soAdjProrated.length > 0) {
      let menuAdjProrated: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
      menuAdjProrated.type = DirectionReFundsBurgerMenuTypes.PRORATE_AMOUNT;
      menuAdjProrated.text = `Prorated Amount Based on Closing Date`;
      menuAdjProrated.key = `Prorated Amount Based on Closing Date`;
      menuAdjProrated.items = [];

      for (let i: number = 0; i < soAdjProrated.length; i++) {
        this.addNewMenuItem(DirectionReFundsBurgerMenuTypes.PRORATE_AMOUNT,
          `${ i + 1 }.${ soAdjProrated[ i ].soAdjOtherProrated.heading } = ${ Utils.formattedCurrencyValue(soAdjProrated[ i ].amount) }`,
          `${ soAdjProrated[ i ].soAdjOtherProrated.heading }${ this.amountSeparator }${ soAdjProrated[ i ].amount }`,
          menuAdjProrated.items
        );
      }
      this.fundsPayableToBurgerMenuItems.push(menuAdjProrated);
    }
    //}
  }

  addNewMenuItem(type: string, text: string, key: string, toList: BurgerMenuExtendedItem[]) {
    if (!Array.isArray(toList)) {
      toList = [];
    }
    const burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
    burgerMenuItem.type = type;
    burgerMenuItem.text = text;
    burgerMenuItem.key = key;
    toList.push(burgerMenuItem);
  }

  get vendorsName(): string {
    let names: string = this.matter.mainClientsName;
    return (names) ? names : 'Unknown Vendor';
  }

  dynamicMenu(): number[] {
    return [ Math.min(1 + (this.vendorsName.length / 20), 3), 1.7 ];
  }

  ngOnDestroy() {
    // keeping status mode to FINAL so any readings of matter's adjustments are pointing to the list of final adjustments
    if (this.matter) {
      this.matter.adjustmentStatusMode = ProgressionStatus.FINAL;
    }
  }

  ngAfterViewInit() {
  }

  formatCurrency(value: number): string {
    return (value != undefined && value != null && !isNaN(value)) ? this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2') : '';
  }

  get isPaymentAmountsDisplayed(): boolean {
    return (this.matter.isMatterProvinceABorMBorSK ? this.matter.paymentAmountsDisplayed : true);
  }

  isClosingDateNotValid(): boolean {
    let closingDate = this.matter.matterCloseDate;
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    return (!closingDate || (closingDate && !pattern.test(closingDate)));
  }

  previewDirectionReFunds(): void {
    if (this.isClosingDateNotValid()) {
      this.dialogService.confirm('Information', 'Cannot view or print until a valid closing date is entered on Matter Opening topic.', true);
    } else {
      if (this.matter && this.matter.directionReFunds && this.matter.directionReFunds.length > 0) {
        let directionReFundsPreview = this.generateDirectionReFundsPreviewObject();
        this.previewDownloadSubscriber = this.documentProductionService.generateDirectionReFundsPreview(this.matter.id, directionReFundsPreview).subscribe(data => {
        });
      }
    }
  }

  generateDirectionReFundsPreviewObject(): DirectionReFundsPreview {
    let directionReFundsPreview = new DirectionReFundsPreview(this.matter);
    directionReFundsPreview.closingDate = this.matter.getClosingDate();
    directionReFundsPreview.firmName = this.loggedInUserLawFirmName;
    directionReFundsPreview.paperSizeCode = this.paperSizeCode;
    directionReFundsPreview.totalName = 'TOTAL';//'Balance Due on Closing';
    directionReFundsPreview.totalAmount = this.formatCurrency(this.balanceDueOnClosing);
    directionReFundsPreview.sufficientAuthority = this.sufficientAuthority;
    directionReFundsPreview.vendorRELine = this.matter.clientReLine ? this.matter.clientReLine : '';
    directionReFundsPreview.purchaserRELine = this.matter.purchaserContactInfo ? this.matter.purchaserContactInfo.reline : '';
    directionReFundsPreview.vendorDataCode = this.vendorDataCode;
    directionReFundsPreview.purchaserDataCode = this.purchaserDataCode;
    directionReFundsPreview.addressDataCode = this.addressDataCode;
    directionReFundsPreview.interimDirectionReFunds = this.matter.isDirectionReFundsInterim;
    directionReFundsPreview.hasInterimAndFinal = this.isProjectSale && this.isProjectConfigDocForDirectionReFund;
    return directionReFundsPreview;
  }

  onChangeOfPaymentAmountDisplayed(): void {
    //once this checkbox has been checked/unchecked,
    //there is corresponding change in the mortgage payout modal page
    //related checkbox fields hide/show there, and initial value is unchecked
    if (this.matter && Array.isArray(this.matter.mortgages)) {
      this.matter.getExistingMortgagesToBeDischarged()
      .filter(mortgage => !!mortgage.mortgagePayout)
      .forEach(mortgage => {
        mortgage.mortgagePayout.payoutEqualsTrustBalance = false;

      });
    }

    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.makePayToYouTLEditable();
    }
    this.enableSave();
  }

  setDirtyForMassUpdate(): void {
    if (this.massUpdateData) {
      if (this.matter.isProjectConfigDocForDirectionReFund()) {
        this.massUpdateData.directionReFundSheetModified(this.matter._directionReFundsStatusMode);
      } else {
        this.massUpdateData.directionReFundSheetModified(ProgressionStatus.FINAL); // when ProjConfig is not set for Interim/Final dirReFunds are stored on matter._finalDirectionReFunds but matter._directionReFundsStatusMode is set to INTERIM
      }
    }
  }

  get massUpdateData(): MassUpdateData {
    return this.tabService && this.tabService.massUpdateData;
  }
}
