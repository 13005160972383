import { BaseEntity } from "../../shared/BaseEntity/base-entity";

export class MatterUtility extends BaseEntity {
    id: number;
    matterId: number;
    accountNumber: number;
    currentBillingStartDate: string;
    currentBillingEndDate: string;
    prePaymentAmount: number;
    utilityAmount: number;
    utilitiesPaidInFull: string;
    baseUtilityAdjustment: number;
    estimatedPercentageIncrease: number;
    totalAmountFormatted: string;

    constructor(matterUtility?: MatterUtility) {
        super(matterUtility);
    }
}
