<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Form 4 Charges</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" for="textReferencingAgreement">Text referencing Agreement (optional)</label>
                </div>
                <div class="col-lg-6">
                    <input type="text"
                           id="textReferencingAgreement"
                           name="textReferencingAgreement"
                           class="form-control"
                           [(ngModel)]="soAdjForm4ChargeLocal.textReferencingAgreement"
                           maxlength="50"
                    />
                </div>
        </div>
        <div class="form-group">
            <div class="col-lg-3 vertical-align-label">
                <label class="control-label" for="numberOfForm4">Number of Form 4s issued</label>
            </div>
            <div class="col-lg-2">
                <input type="text"
                       id="numberOfForm4"
                       name="numberOfForm4"
                       class="form-control"
                       [(ngModel)]="soAdjForm4ChargeLocal.numberOfForm4Issued"
                       maxlength="3"
                       numberOnly
                       [dp-read-only]="!soAdjForm4ChargeLocal.overrideCalculatedValue"
                />
            </div>
            <div class="col-lg-7">
                <div class="checkbox-align-modal float-left">
                    <dp-checkbox fieldId="overrideCalculatedValue">
                        <input type="checkbox"
                               id="overrideCalculatedValue"
                               name="overrideCalculatedValue"
                               [(ngModel)]="soAdjForm4ChargeLocal.overrideCalculatedValue"
                               (ngModelChange)="onOverrideCalculatedValue($event)"
                        />
                    </dp-checkbox>
                </div><label class="control-label text-left float-left" for="overrideCalculatedValue"><span>Override calculated value</span></label>
                <div class="clear"></div>
            </div>
        </div>
        <div class="form-group">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" for="costPerForm4">Cost per Form 4</label>
                </div>
                <div class="col-lg-2">
                    <input type="text" class="form-control" id="costPerForm4" name="costPerForm4" [(ngModel)]="soAdjForm4ChargeLocal.costPerForm4"
                           dp-default-currency
                           dp-currency
                           significant-currency-digits="4" />
                </div>
        </div>
        <div class="form-group">
            <div class="col-lg-3 vertical-align-label"><label class="control-label">Adjust for</label></div>
            <div class="col-lg-1">
                <select type="text" id="taxType" name="taxType" class="form-control" [ngModel]="soAdjForm4ChargeLocal.adjustedFor"
                        (ngModelChange)="taxTypeChange($event)">
                    <option *ngFor="let taxTypeItem of taxTypeDropDown" [value]="taxTypeItem.value">
                        {{taxTypeItem.label}}
                    </option>
                </select>
            </div>
            <div class="width-15 float-left text-center"><label class="control-label">?</label></div>
            <div class="col-lg-1">
                <select type="text" id="adjustForType" name="adjustForType" class="form-control" [ngModel]="soAdjForm4ChargeLocal.adjustedForType"
                        (ngModelChange)="adjustForTypeChange($event)">
                    <option *ngFor="let adjustForItem of adjustForTypeDropDown" [value]="adjustForItem.value">
                        {{adjustForItem.label}}
                    </option>
                </select>
            </div>
            <ng-container *ngIf="soAdjForm4ChargeLocal.adjustedForType=='YES'">
                <div
                    class="col-lg-2 vertical-align-label"><label class="control-label">{{soAdjForm4ChargeLocal.adjustedForTypeLabel}} percentage</label></div>
                <div class="col-lg-1">
                    <input type="text" class="form-control" id="percentageAdjustedFor" name="percentageAdjustedFor" [(ngModel)]="soAdjForm4ChargeLocal.percentageAdjustedFor"
                           dp-percentage [max-decimals] = 3 [max-int] = 99.999 [padding-zero]="true"   [dp-read-only]="soAdjForm4ChargeLocal.adjustedForType=='NO'"
                    />
                </div>
                <div class="col-lg-1 vertical-align-label">
                    <label class="control-label text-left">%</label>
                </div>
                <div class="clear"></div>
            </ng-container>
        </div>
        <div class="form-group">
            <div
                class="col-lg-3 vertical-align-label"><label class="control-label"><ng-container *ngIf="soAdjForm4ChargeLocal.adjustedFor=='GST'">Adjust for PST?</ng-container></label></div>
            <div class="col-lg-1">

            </div>
            <div class="width-15 float-left text-center"><label class="control-label"></label></div>
            <div class="col-lg-1">
                <ng-container *ngIf="soAdjForm4ChargeLocal.adjustedFor=='GST'">
                    <select type="text" id="adjustForPst" name="adjustForPst" class="form-control" [ngModel]="soAdjForm4ChargeLocal.adjustForPst"
                            (ngModelChange)="adjustForPstChange($event)">
                    <option *ngFor="let adjustForItem of adjustForTypeDropDown" [value]="adjustForItem.value">
                        {{adjustForItem.label}}
                    </option>
                    </select>
                </ng-container>
            </div>
            <ng-container
                *ngIf="soAdjForm4ChargeLocal.adjustForPst=='YES' && soAdjForm4ChargeLocal.adjustedFor=='GST'">
                <div
                    class="col-lg-2 vertical-align-label"><label class="control-label">PST
                    percentage</label></div>
                <div class="col-lg-1">
                    <input type="text" class="form-control" id="percentageAdjustedForPst" name="percentageAdjustedForPst" [(ngModel)]="soAdjForm4ChargeLocal.percentageAdjustedForPst"
                           dp-percentage [max-decimals] = 3 [max-int] = 99.999 [padding-zero]="true"
                           [dp-read-only]="(soAdjForm4ChargeLocal.adjustedFor=='GST' &&  soAdjForm4ChargeLocal.adjustForPst=='NO') || (soAdjForm4ChargeLocal.adjustedFor=='HST' &&  soAdjForm4ChargeLocal.adjustedForType=='NO' && soAdjForm4ChargeLocal.adjustForPst=='NO')"
                    />
                </div>
                <div class="col-lg-1 vertical-align-label">
                    <label class="control-label text-left">%</label>
                </div>
                <div class="clear"></div>
            </ng-container>
        </div>
        <div class="padding-top-10 padding-left-5  modal-separator-line">
            <div class="col-lg-12 form-group">
                <div class="col-lg-6">
                    <label class="control-label">FORM 4 CHARGES</label>
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <div class="col-lg-6">
                    <label class="control-label">{{soAdjForm4ChargeLocal.textReferencingAgreement}}</label>
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label">{{soAdjForm4ChargeLocal.numberOfForm4Issued}} Form 4s issued @ {{soAdjForm4ChargeLocal.costPerForm4 | currency:'CAD':'symbol-narrow'}}</label>
                </div>
                <div class="col-lg-3  text-right">
                    <label class="control-label">{{ soAdjForm4ChargeLocal.calculateForm4SubTotal | currency:'CAD':'symbol-narrow' }}</label>
                </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="soAdjForm4ChargeLocal.adjustedForType=='YES' || soAdjForm4ChargeLocal.adjustForPst=='YES'">
                <div class="col-lg-3">
                    <label class="control-label">{{soAdjForm4ChargeLocal.taxAmountLabel}}</label>
                </div>
                <div class="col-lg-3  text-right">
                    <label class="control-label">{{ soAdjForm4ChargeLocal.calculateTaxAmount  | currency:'CAD':'symbol-narrow' }}</label>
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label">Credit Vendor</label>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3  text-right">
                    <label class="control-label">{{ soAdjForm4ChargeLocal.calculateForm4Total  | currency:'CAD':'symbol-narrow' }}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button *ngIf="!isAdded && allowDelete" type="button" class="secondary-button" (click)="delete()">
                <span>Delete</span>
            </button>
        </div>
    </div>
</form>
