import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {FctFiData} from './fct-fi-data';
import {FctIdType} from './fct-id-type';

export class FctBusinessRules extends BaseEntity {
  fctFiData: FctFiData[] = [];
  fctIdTypes: FctIdType[] = [];

  constructor(f?: FctBusinessRules) {
    super(f);

    if (f.fctFiData && Array.isArray(f.fctFiData)) {
      f.fctFiData.forEach(fctFiData => {
        this.fctFiData.push(new FctFiData(fctFiData));
      });
    }

    if (f.fctIdTypes && Array.isArray(f.fctIdTypes)) {
      f.fctIdTypes.forEach(fctIdType => {
        this.fctIdTypes.push(new FctIdType(fctIdType));
      });
    }

  }
}
