import {Mortgage} from '../mortgage';
import {Matter} from '../matter';
import {MatterTypesValue} from '../index';
import {MatterParticipant} from '../matter-participant';
import Utils from '../../../shared-main/utils';
import {PurchaserCapacity} from '../../purchaser/capacity';
import {AddressTypes} from '../address-types';
import {ContactInfo} from '../contact-info';

export class MatterTitleDetailsUtil {

  static getPrivateLendersTitleDetails(matter: Matter, mortgage: Mortgage): string {
    if (matter) {
      if (matter.isMatterProvinceAB || matter.isMatterProvinceBC) {
        return MatterTitleDetailsUtil.getPrivateLendersTitleDetailsAB(matter, mortgage);
      } else if (matter.isMatterProvinceSK || matter.isMatterProvinceMB) {
        return MatterTitleDetailsUtil.getPrivateLendersTitleDetailsSK(matter, mortgage);
      } else if (matter.isMatterProvinceNB || matter.isMatterProvinceNS) {
        return MatterTitleDetailsUtil.getPrivateLendersTitleDetailsNBOrNS(matter, mortgage);
      }
    }
  }

  static getPrivateLendersTitleDetailsAB(matter: Matter, mortgage: Mortgage): string {
    if (matter && mortgage) {
      const privateLenders: MatterParticipant[] = matter.getPrivateLenders(mortgage);
      const primaryPrivateLender: MatterParticipant = matter.primaryPrivateLender(mortgage);
      if (Array.isArray(privateLenders) && privateLenders.length > 0) {
        let clientNames = [];
        privateLenders.forEach(item => {
          let genericName = item.contact && item.contact.genericName ? item.contact.genericName.toUpperCase() : '';
          clientNames.push(genericName);
        });
        let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
        formattedClientName = formattedClientName + (privateLenders.length == 1 ? ' of ' : '');
        formattedClientName = formattedClientName + (privateLenders.length == 2 ? ', both of ' : '');
        formattedClientName = formattedClientName + (privateLenders.length >= 3 ? ', all of ' : '');
        formattedClientName = formattedClientName
          + (primaryPrivateLender
          && primaryPrivateLender.contact
          && primaryPrivateLender.contact.mailingAddress
          && !primaryPrivateLender.contact.mailingAddress.isEmpty
            ? ' ' + primaryPrivateLender.contact.mailingAddress.addressTextWithProvinceName
            : '__________________________');
        // export type MortgageCapacityType = 'SILENT' | 'JOINT' | 'COMMON_SPECIFIED' | 'COMMON_UNSPECIFIED' | 'OTHER';
        if (privateLenders.length > 1) {
          switch (mortgage.mortgageCapacityType) {
            case 'SILENT':
              formattedClientName += ', as Not Specified';
              break;
            case 'JOINT':
              formattedClientName += ', on Joint Account';
              break;
            case 'COMMON_SPECIFIED':
              formattedClientName += ', as Joint Tenants';
              break;
            case 'COMMON_UNSPECIFIED':
              formattedClientName += ', as Tenants in Common';
              break;
            default:
              break;
          }
        }

        mortgage.mortgageContactInfo.titleDetailsDescription = formattedClientName;
      } else {
        mortgage.mortgageContactInfo.titleDetailsDescription = null;
      }
      return mortgage.mortgageContactInfo.titleDetailsDescription;
    }
  }

  static getPrivateLendersTitleDetailsSK(matter: Matter, mortgage: Mortgage): string {
    if (matter && mortgage) {
      const privateLenders: MatterParticipant[] = matter.getPrivateLenders(mortgage);
      const primaryPrivateLender: MatterParticipant = matter.primaryPrivateLender(mortgage);
      if (Array.isArray(privateLenders) && privateLenders.length > 0) {
        let clientNames = [];
        privateLenders.forEach(item => {
          let genericName = item.contact && item.contact.genericName ? Utils.convertToTitleCase(item.contact.genericName) : '';
          if (mortgage.mortgageCapacityType === 'OTHER') {
            genericName = genericName + ' as to a ' + item.purchaserShare + ' interest';
          }
          clientNames.push(genericName);
        });
        let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
        formattedClientName = formattedClientName + (privateLenders.length == 1 ? ' of ' : '');
        formattedClientName = formattedClientName + (privateLenders.length == 2 ? ', both of ' : '');
        formattedClientName = formattedClientName + (privateLenders.length >= 3 ? ', all of ' : '');
        formattedClientName = formattedClientName + '\r';

        formattedClientName = formattedClientName
          + (primaryPrivateLender
          && primaryPrivateLender.contact
          && primaryPrivateLender.contact.mailingAddress
          && !primaryPrivateLender.contact.mailingAddress.isEmpty
            ? primaryPrivateLender.contact.mailingAddress.addressTextWithProvinceName
            : '__________________________');
        formattedClientName = formattedClientName + ' \r';
        // export type MortgageCapacityType = 'SILENT' | 'JOINT' | 'COMMON_SPECIFIED' | 'COMMON_UNSPECIFIED' | 'OTHER';
        if (privateLenders.length > 1) {
          switch (mortgage.mortgageCapacityType) {
            case 'JOINT':
              formattedClientName += 'as joint tenants';
              break;
            case 'COMMON_SPECIFIED':
              let capacityLabel = `as tenants in common (each ${ PurchaserCapacity.getDynamicValueInterest(privateLenders.length) })`;
              formattedClientName += capacityLabel;
              break;
            case 'COMMON_UNSPECIFIED':
              formattedClientName += 'as tenants in common';
              break;
            default:
              break;
          }
        }
        // since whole method is used for SK/MB
        // for SK, titleDetailType and titleDetailsDescription fields are hidden, so can set to default value
        // for MB, user can choose titleDetailType to Manual, then  we need to keep the manual value
        if (!mortgage.mortgageContactInfo.isTitleDetailsManual() ||
          mortgage.mortgageContactInfo.titleDetailsDescription == null) {
          mortgage.mortgageContactInfo.titleDetailsDescription = formattedClientName;
        }

      } else {
        mortgage.mortgageContactInfo.titleDetailsDescription = null;
      }
      return mortgage.mortgageContactInfo.titleDetailsDescription;
    }
  }

  static getPrivateLendersTitleDetailsNBOrNS(matter: Matter, mortgage: Mortgage): string {
    return MatterTitleDetailsUtil.getTitleDetailsNBOrNS(mortgage, matter.getPrivateLenders(mortgage), matter.primaryPrivateLender(mortgage));
  }

  static getTitleDetailsNBOrNS(mortgage: Mortgage, participants: MatterParticipant[], primaryParticipant: MatterParticipant): string {
    if (mortgage) {
      if (Array.isArray(participants) && participants.length > 0) {
        let clientNames = [];
        participants.forEach(item => {
          let genericName = item.contact && item.contact.genericName ? item.contact.genericName.toUpperCase() : '';
          if (mortgage.mortgageCapacityType === 'OTHER') {
            genericName = genericName + ' as to a ' + item.purchaserShare + ' interest';
          }
          clientNames.push(genericName);
        });
        let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
        formattedClientName = formattedClientName + (participants.length == 1 ? ' of ' : '');
        formattedClientName = formattedClientName + (participants.length == 2 ? ', both of ' : '');
        formattedClientName = formattedClientName + (participants.length >= 3 ? ', all of ' : '');

        formattedClientName = formattedClientName
          + (primaryParticipant
          && primaryParticipant.contact
          && primaryParticipant.contact.mailingAddress
          && !primaryParticipant.contact.mailingAddress.isEmpty
            ? primaryParticipant.contact.mailingAddress.addressTextWithProvinceName
            : '__________________________');
        // export type MortgageCapacityType = 'SILENT' | 'JOINT' | 'COMMON_SPECIFIED' | 'COMMON_UNSPECIFIED' | 'OTHER';
        if (participants.length > 1) {
          switch (mortgage.mortgageCapacityType) {
            case 'SILENT':
              formattedClientName += ', as Not Specified';
              break;
            case 'JOINT':
              formattedClientName += ', as Joint Tenants';
              break;
            case 'COMMON_UNSPECIFIED':
              formattedClientName += ', as Tenants in Common';
              break;
            default:
              break;
          }
        }
        mortgage.mortgageContactInfo.titleDetailsDescription = formattedClientName;
      } else {
        mortgage.mortgageContactInfo.titleDetailsDescription = null;
      }
      return mortgage.mortgageContactInfo.titleDetailsDescription;
    }
  }

  static getMortgageeTitleDetailsNBOrNS(matter: Matter, mortgage: Mortgage): string {
    if (matter) {
      const participants: MatterParticipant[] = matter.mortgagees.filter(mortgageItem => mortgageItem.mortgageId === mortgage.id);
      return MatterTitleDetailsUtil.getTitleDetailsNBOrNS(mortgage, participants, Array.isArray(participants) && participants.length > 0 ? participants[ 0 ] : null);
    }
  }

  static setDefaultTitleDetailsNBOrNS(matter: Matter, mortgage: Mortgage) {
    if (matter && matter.isMatterProvinceNBorNS && mortgage.isNewMortgage()) {
      if (mortgage.isMortgageeAnInstitution()) {
        MatterTitleDetailsUtil.getMortgageeTitleDetailsNBOrNS(matter, mortgage);
        mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
      }
    }
  }

  static setDefaultTitleDetailsSK(matter: Matter, mortgage: Mortgage) {
    if (matter) {
      if (mortgage.isNewMortgage()) {
        if (mortgage.isMortgageeAnInstitution()) {
          MatterTitleDetailsUtil.getMortgageeTitleDetailsSK(matter, mortgage);
        } else if (mortgage.isMortgageePrivateLender()) {
          MatterTitleDetailsUtil.getPrivateLendersTitleDetailsSK(matter, mortgage);
        }
        // the following field is N/A for SK, but shows in MB (PSM)
        if (mortgage.mortgageContactInfo && !mortgage.mortgageContactInfo.titleDetailsType) {
          mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
        }
      }
    }
  }

  static setDefaultTitleDetailsAB(matter: Matter, mortgage: Mortgage) {
    if (matter && matter.isMatterProvinceAB && mortgage.isNewMortgage()) {
      switch (matter.matterType) {
        // Purchase matter does not have Title Details fields in mortgage UI.
        // However it needs to set default value for DocGen with an "Institution" lender type.
        case MatterTypesValue.PURCHASE:
          if (mortgage.isMortgageeAnInstitution()) {
            MatterTitleDetailsUtil.getMortgageeTitleDetailsAB(matter, mortgage);
            mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
          }
          break;
        case MatterTypesValue.SALE:
          // Sale matter does not have Title Details fields in mortgage UI.
          // It only has private lenders
          // However it needs to set default value for DocGen with an "Other Lender" lender type.
          MatterTitleDetailsUtil.getPrivateLendersTitleDetailsAB(matter, mortgage);
          mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
          break;
        case MatterTypesValue.MORTGAGE:
          if (mortgage.isMortgageeAnInstitution()) {
            MatterTitleDetailsUtil.getMortgageeTitleDetailsAB(matter, mortgage);
            mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
          }
          break;
        default :
          break;
      }
    }
  }

  static getMortgageeTitleDetailsAB(matter: Matter, mortgage: Mortgage): string {
    if (matter) {
      const mortgagees: MatterParticipant[] = matter.mortgagees.filter(mortgageItem => mortgageItem.mortgageId === mortgage.id);
      if (Array.isArray(mortgagees) && mortgagees.length > 0) {
        let clientNames = [];
        mortgagees.forEach(item => {
          let genericName = item.contact && item.contact.genericName ? item.contact.genericName.toUpperCase() : '';
          clientNames.push(genericName);
        });
        let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
        formattedClientName = formattedClientName + (mortgagees.length == 1 ? ' of ' : '');
        formattedClientName = formattedClientName + (mortgagees.length == 2 ? ', both of ' : '');
        formattedClientName = formattedClientName + (mortgagees.length >= 3 ? ', all of ' : '');
        formattedClientName = formattedClientName
          + (mortgagees[ 0 ].contact
          && mortgagees[ 0 ].contact.mailingAddress
          && !mortgagees[ 0 ].contact.mailingAddress.isEmpty
            ? ' ' + mortgagees[ 0 ].contact.mailingAddress.addressTextWithProvinceName
            : '__________________________');
        // export type MortgageCapacityType = 'SILENT' | 'JOINT' | 'COMMON_SPECIFIED' | 'COMMON_UNSPECIFIED' | 'OTHER';
        if (mortgagees.length > 1) {
          switch (mortgage.mortgageCapacityType) {
            case 'SILENT':
              formattedClientName += ', as Not Specified';
              break;
            case 'JOINT':
              formattedClientName += ', on Joint Account';
              break;
            case 'COMMON_SPECIFIED':
              formattedClientName += ', as Joint Tenants';
              break;
            case 'COMMON_UNSPECIFIED':
              formattedClientName += ', as Tenants in Common';
              break;
            default:
              break;
          }
        }

        mortgage.mortgageContactInfo.titleDetailsDescription = formattedClientName;
      } else {
        mortgage.mortgageContactInfo.titleDetailsDescription = null;
      }
      return mortgage.mortgageContactInfo.titleDetailsDescription;
    }
  }

  static getMortgageeTitleDetailsSK(matter: Matter, mortgage: Mortgage): string {
    if (matter) {
      const mortgagees: MatterParticipant[] = matter.mortgagees.filter(mortgageItem => mortgageItem.mortgageId === mortgage.id);
      if (Array.isArray(mortgagees) && mortgagees.length > 0) {
        let clientNames = [];
        mortgagees.forEach(item => {
          let genericName = item.contact && item.contact.genericName ? Utils.convertToTitleCase(item.contact.genericName) : '';
          if (mortgage.mortgageCapacityType === 'OTHER') {
            genericName = genericName + ' as to a ' + item.purchaserShare + ' interest';
          }
          clientNames.push(genericName);
        });
        let formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
        formattedClientName = formattedClientName + (mortgagees.length == 1 ? ' of ' : '');
        formattedClientName = formattedClientName + (mortgagees.length == 2 ? ', both of ' : '');
        formattedClientName = formattedClientName + (mortgagees.length >= 3 ? ', all of ' : '');
        formattedClientName = formattedClientName + '\r';

        formattedClientName = formattedClientName
          + (mortgagees[ 0 ].contact
          && mortgagees[ 0 ].contact.mailingAddress
          && !mortgagees[ 0 ].contact.mailingAddress.isEmpty
            ? ' ' + mortgagees[ 0 ].contact.mailingAddress.addressTextWithProvinceName
            : '__________________________');
        formattedClientName = formattedClientName + '\r';
        // export type MortgageCapacityType = 'SILENT' | 'JOINT' | 'COMMON_SPECIFIED' | 'COMMON_UNSPECIFIED' | 'OTHER';
        if (mortgagees.length > 1) {
          switch (mortgage.mortgageCapacityType) {
            case 'JOINT':
              formattedClientName += 'as joint tenants';
              break;
            case 'COMMON_SPECIFIED':
              let capacityLabel = `as tenants in common (each ${ PurchaserCapacity.getDynamicValueInterest(mortgagees.length) })`;
              formattedClientName += capacityLabel;
              break;
            case 'COMMON_UNSPECIFIED':
              formattedClientName += 'as tenants in common';
              break;
            default:
              break;
          }
        }
        mortgage.mortgageContactInfo.titleDetailsDescription = formattedClientName;
      } else {
        mortgage.mortgageContactInfo.titleDetailsDescription = null;
      }
      return mortgage.mortgageContactInfo.titleDetailsDescription;
    }
  }

  static generateAndUpdateTitleDetailsAB(matter: Matter): string {
    if (matter && matter.matterContactInfo) {
      if (matter.isMatterProvinceAB && !matter.isTitleDetailsManual()) {
        if (matter.isIndividualTitleDetails()) {
          MatterTitleDetailsUtil.generateAndUpdateIndividualTitleDetailsAB(matter);
        } else {
          MatterTitleDetailsUtil.generateAndUpdateCommonTitleDetailsAB(matter);
        }
      }
      return matter.matterContactInfo.titleDetails;
    }
  }

  static generateAndUpdateCommonTitleDetailsAB(matter: Matter) {
    let formattedClientName: string = '';
    if (matter.mainClients && matter.mainClients.length > 0) {
      let clientNames = [];
      matter.mainClients.forEach(item => {
        let genericName = item.contact && item.contact.genericName ? item.contact.genericName.toUpperCase() : '';
        clientNames.push(matter.purchasersCapacity == 'OTHER' && item.purchaserShare ? genericName + ' ' + item.purchaserShare : genericName);
      });
      formattedClientName = clientNames.length < 2 ? clientNames.join(', ') : clientNames.slice(0, -1).join(', ') + ' and ' + clientNames.slice(-1);
      switch (matter.mainClients.length) {
        case 1 :
          formattedClientName += ' of ';
          break;
        case 2 :
          formattedClientName += ', both of ';
          break;
        default: // 3 or more main clients
          formattedClientName += ', all of ';
      }
      if (!matter.isResidingPpropertyNoABMBSKTabB()) {
        formattedClientName += ((matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address && !matter.matterPropertyWithCondo.address.isEmpty)
          ? (matter.matterPropertyWithCondo.address.addressTextWithProvinceName) : ('__________________________'));
      } else {
        if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.subjectAddress && matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.address
          && !matter.matterPropertyWithCondo.address.isEmpty) {
          formattedClientName += matter.matterPropertyWithCondo.address.addressTextWithProvinceName;
        } else if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.preClosingAddress && matter.matterContactInfo.preClosingAddress
          && !matter.matterContactInfo.preClosingAddress.isEmpty) {
          formattedClientName += matter.matterContactInfo.preClosingAddress.addressTextWithProvinceName;
        } else if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode == AddressTypes.manuallyEntered && matter.matterContactInfo.postClosingAddress
          && !matter.matterContactInfo.postClosingAddress.isEmpty) {
          formattedClientName += matter.matterContactInfo.postClosingAddress.addressTextWithProvinceName;
        } else {
          formattedClientName += '__________________________';
        }
      }
      if (matter.purchasersCapacity == 'JOINT_TENANTS' && matter.mainClients.length > 1) {
        formattedClientName = formattedClientName + ' as joint tenants';
      } else if (matter.purchasersCapacity == 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES' && matter.mainClients.length > 1) {
        formattedClientName = formattedClientName + ' as tenants in common';
      }
    }
    matter.matterContactInfo.titleDetails = formattedClientName;
  }

  static generateAndUpdateIndividualTitleDetailsAB(matter: Matter, contactInfo?: ContactInfo) {
    let formattedClientAddressName: string = '';
    const partcipants = contactInfo ? matter.otherSideClients : matter.mainClients;
    const capacity = contactInfo ? matter.otherPartiesCapacity : matter.purchasersCapacity;
    if (partcipants && partcipants.length > 0) {
      let clientNameAddresses = [];
      let allPrimaryAddressPresent: boolean = partcipants.every(item => item.contact && item.contact.primaryAddress && !item.contact.primaryAddress.isEmpty);
      partcipants.forEach(item => {
        let genericName = item.contact && item.contact.genericName ? item.contact.genericName.toUpperCase() : '';
        let clientNameAndPurchaserShare = capacity == 'OTHER' && item.purchaserShare ? genericName + ' ' + item.purchaserShare + '%' : genericName;
        let clientNameAddress: string;
        //When all client's primary address is present - HAPPY CUSTOMER of 456 King Street, South, Calgary, Alberta  C1C 1C1 as to 70%  and JOY CUSTOMER of 243, King West, Calgary, Alberta  A1A 1A1 as to 30%
        //When at least one client's primary address is not populated - HAPPY CUSTOMER 70% of __________________________ and JOY CUSTOMER
        // 30% of 243, King West, Calgary, Alberta  A1A 1A1
        if (capacity == 'OTHER' && allPrimaryAddressPresent) {
          clientNameAddress = genericName + ' of ' + item.contact.primaryAddress.addressTextWithProvinceName + (item.purchaserShare ? ' as to ' + item.purchaserShare + '%' : '');
        } else {
          clientNameAddress = clientNameAndPurchaserShare + ' of '
            + ((item.contact && item.contact.primaryAddress && !item.contact.primaryAddress.isEmpty)
              ? item.contact.primaryAddress.addressTextWithProvinceName : ('__________________________'));
        }

        clientNameAddresses.push(clientNameAddress);

      });
      formattedClientAddressName = clientNameAddresses.length < 2 ? clientNameAddresses.join(', ') : clientNameAddresses.slice(0, -1).join(', ') + ' and ' + clientNameAddresses.slice(-1);

      if (capacity == 'JOINT_TENANTS' && partcipants.length > 1) {
        formattedClientAddressName = formattedClientAddressName + ', as joint tenants';
      } else if (capacity == 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES' && partcipants.length > 1) {
        formattedClientAddressName = formattedClientAddressName + ', as tenants in common';
      }
    }
    if (contactInfo) {
      contactInfo.titleDetails = formattedClientAddressName;
    } else {
      matter.matterContactInfo.titleDetails = formattedClientAddressName;
    }

  }
}
