const isFunction = function (fn) {
  return typeof fn === 'function';
};

/**
 * This decorator is created as a modified version of the original AutoUnsubscribeDecorator, which is still used by other components.
 *
 * This tries to address the issue where sudo properties (such as get matter(){...}) containing some business logic and cause errors
 * when the AutoUnsubscribeDecorator tries to do auto-unsubscribe.
 *
 * @param {{event : string}} config
 * @return {(c: any) => void}
 * @constructor
 */
export function AutoUnsubscribeDecorator2(config: { event: string } = {event: 'ngOnDestroy'}): (c: any) => void {

  const event = config.event ? config.event : 'ngOnDestroy';

  return function (constructor: any) {
    let original = constructor.prototype[ event ];
    if (!isFunction(original)) {
      throw new Error(constructor.name + ' is using @AutoUnsubscribeTest but does not implement OnDestroy');
    }
    constructor.prototype[ event ] = function () {
      for (let propName in this) {
        try {
          const pDesc = Object.getOwnPropertyDescriptor(this, propName);
          if (!pDesc || pDesc.get) {
            // pDesc is undefined if propName points to a function, and
            // existence of 'get' indicates a getter function
            continue;
          }
          const property = this[ propName ];
          if (property && isFunction(property.unsubscribe)) {
            property.unsubscribe();
            console.log(`unsubscribed for ${ propName }`);
          }
        } catch (err) {
          console.error(`error retrieving property: ${ propName }`, err);
        }

      }
      if (isFunction(original)) {
        try {
          original.apply(this, arguments);
        } catch (error) {
          console.error('Error executing event handler ' + event, error);
        }
      }
    };
  };
}
