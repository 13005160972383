<div class="container-fluid custom-modal-container">
    <div class="col-lg-12 form-group" style="border-bottom: 2px solid #cccccc">
        <div class="col-lg-1">
        </div>
        <div class="col-lg-4 text-center padding-left-15">
            <dp-checkbox fieldId="copyToNotesCheckbox">
                <input type="checkbox"
                       id="copyToNotesCheckbox"
                       (change)="copyAllPartiesToNotes($event)"
                />
            </dp-checkbox>
            <label class="control-label padding-left-10 display-inline" for="copyToNotesCheckbox">Copy all parties to {{matter.mainClientTypeName}} sticky notes.</label>
        </div>
        <div class="col-lg-2">
            <div class="form-group main-level">
                <button id="openStickyNotes"
                        type="button"
                        class="function-icons"
                        (click)="openStickyNotesModal()">
                    <i class="svg-icon sticky-note pointerCursor"></i>
                </button>
                <h6 class="h6-sticky-notes">Sticky Notes</h6>
            </div>
        </div>
    </div>
    <div class="col-lg-12 panel-group">
        <div *ngFor="let referralClientInfo of referralImportData;let i = index;" class="row">
            <div *ngIf="i === 0" class="form-group">
                <div class="col-lg-12 text-left">
                    <label class="control-label">{{getClientCategoryNameByRole(referralClientInfo.referredClient.role)}}</label>
                </div>
            </div>

            <div class="form-group">
            <dp-accordion [expanded]="true">

                <div class="dp-accordion-header" >
                    <div class="row  row-group">
                        <label class="control-label col-lg-6 text-left padding-left-40">{{referralClientInfo.referredClient.getFullName()}}</label>
                    </div>
                </div>
                <div class="dp-accordion-content row padding-left-30">
                    <div class="col-lg-12 form-group margin-top-15">
                        <div class="col-lg-1 text-right">
                            <label class="control-label">Name</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text"
                                   id="referredClientName{{i}}"
                                   name="referredClientName{{i}}"
                                   class="form-control"
                                   [ngModel]="referralClientInfo.referredClient?.getFullName()"
                                   [dp-read-only]="true"
                            />
                        </div>
                        <div class="col-lg-1"></div>
                        <div class="col-lg-2"
                             *ngIf="!referralClientInfo.clientName">
                            <select id="clientName{{i}}"
                                    name="clientName{{i}}"
                                    class="form-control"
                                    [(ngModel)]="referralClientInfo.clientContactId"
                                    (change)="updateClientName(referralClientInfo ,i)"
                            >
                                <option *ngFor="let client of referralClientInfo.clients" [value]="client.value">
                                    {{client.label}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-4" *ngIf="referralClientInfo.clientName">
                            <input type="text"
                                   id="clientFullName{{i}}"
                                   name="clientFullName{{i}}"
                                   class="form-control"
                                   [ngModel]="referralClientInfo.clientName"
                                   [dp-read-only]="true"
                            />
                            <div class="actionbtns">
                                <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeClientName(referralClientInfo)" aria-hidden="true"></i>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="{{referralClientInfo.isNewRecord() && !referralClientInfo.clientName ? 'col-lg-4' : 'col-lg-2'}}"
                             *ngIf="referralClientInfo.isNewRecord() && !referralClientInfo.clientName">
                            <p-autoComplete
                                id="clientSearch{{i}}"
                                name="clientSearch{{i}}"
                                [class.autocomplete-searching]="referralClientInfo.clientSearchOptionsLoading"
                                [suggestions]="referralClientInfo.clientSearchOptions"
                                (completeMethod)="search($event, referralClientInfo)"
                                [(ngModel)]="referralClientInfo.dataModel"
                                field="fullName"
                                [minLength]="0"
                                [delay]="1000"
                                maxlength="75"
                                (onSelect)="dataSelectedClient(referralClientInfo)"
                                autocomplete="off" class="autocomplete-search-icon"
                                (focusout)="clearClient(referralClientInfo)">


                                <ng-template let-clientContact pTemplate="item">
                                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                        [ngClass]="{'bold-font' : isAddNew(clientContact)}"
                                        [disableItem]="clientContact">
                                <span class="col-lg-5 text-overflow"
                                      *ngIf="referralClientInfo.autoCompleteEmptyFlag == true">{{ clientContact.displayName }}</span>
                                        <span class="text-overflow"
                                              [ngClass]="{'col-lg-12':isAddNew(clientContact), 'col-lg-5':!isAddNew(clientContact)}"
                                              *ngIf="referralClientInfo.autoCompleteEmptyFlag == false">{{ clientContact.displayName }}</span>
                                        <span class="col-lg-4 text-overflow"
                                              *ngIf="referralClientInfo.autoCompleteEmptyFlag == false">{{ getAddressValue(clientContact) }}</span>
                                        <!--<span class="col-lg-3 text-overflow"-->
                                              <!--*ngIf="referralClientInfo.autoCompleteEmptyFlag == false && clientContact.gender  != 'QUESTION'">{{-->
                                                <!--getGenderValue(clientContact.gender) }}</span>-->
                                    </li>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <ng-container *ngIf="referralClientInfo.clientContact">
                        <div class="col-lg-12 form-group" style="border-bottom: 2px solid #cccccc">
                            <div class="col-lg-1">
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group main-level">
                                    <label class="control-label">
                                        Referral Data
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="allFields{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="allFields{{i}}"
                                           [(ngModel)]="referralClientInfo.allFieldsSelected"
                                           (change)="updateAllFields($event , referralClientInfo)"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group main-level">
                                    <label class="control-label">Matter Data</label>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">First Name</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralFirstName{{i}}"
                                       name="referralFirstName{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.firstName"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="fNameC{{i}}" fieldClass="top-8"
                                             *ngIf="referralClientInfo.referredClient.firstName">
                                    <input type="checkbox"
                                           id="fNameC{{i}}"

                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="updateContactDetailsFromReferral(referralClientInfo ,$event,'contactName', 'firstName')"
                                           id="fNameC{{i}}"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="fNameMatter"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.contactName.firstName"
                                       name="fNameMatter"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Middle Name</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralMiddleName{{i}}"
                                       name="referralMiddleName{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.middleName"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="mNameC{{i}}" fieldClass="top-8">
                                    <input type="checkbox"

                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="updateContactDetailsFromReferral(referralClientInfo ,$event,'contactName', 'middleName')"
                                           id="mNameC{{i}}"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="mNameMatter"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.contactName.middleName"
                                       name="lNameMatter"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Last Name</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralLastName{{i}}"
                                       name="referralLastName{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.lastName"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="lNameC{{i}}" fieldClass="top-8">
                                    <input type="checkbox"

                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="updateContactDetailsFromReferral(referralClientInfo ,$event,'contactName', 'lastName')"
                                           id="lNameC{{i}}"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="lNameMatter"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.contactName.lastName"
                                       name="lNameMatter"
                                       class="form-control"/>
                            </div>

                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Role</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralRole{{i}}"
                                       name="referralRole{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.role"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">E-Mail</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralEmail{{i}}"
                                       name="referralEmail{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.email"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="emailC{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="updateContactDetailsFromReferral(referralClientInfo ,$event,'contact', 'email')"
                                           id="emailC{{i}}"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="emailMatter"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.email"
                                       name="emailMatter"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Phone</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralPhone{{i}}"
                                       name="referralPhone{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.homePhone"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="workPhoneCheckbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="workPhoneCheckbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="phoneChange(referralClientInfo, $event, 'homePhone')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="workPhoneMatter{{i}}"
                                       name="workPhoneMatter{{i}}"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.homePhone"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Address 1</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralAddress1{{i}}"
                                       name="referralAddress1{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.address.addressLine1"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="address1Checkbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="address1Checkbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="addressChange(referralClientInfo, $event, 'addressLine1')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="address1Matter{{i}}"
                                       name="address1Matter{{i}}"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.addressLine1"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Address 2</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralAddress2{{i}}"
                                       name="referralAddress2{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.address.addressLine2"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="address2Checkbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="address2Checkbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="addressChange(referralClientInfo, $event, 'addressLine2')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="address2Matter{{i}}"
                                       name="address2Matter{{i}}"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.addressLine2"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">City</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralCity{{i}}"
                                       name="referralCity{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.address.city"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="cityCheckbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="cityCheckbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="addressChange(referralClientInfo, $event, 'city')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="cityMatter{{i}}"
                                       name="cityMatter{{i}}"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.city"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Province</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralProvince{{i}}"
                                       name="referralProvince{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.address.provinceCode"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="provinceCheckbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="provinceCheckbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="addressChange(referralClientInfo, $event, 'provinceCode')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="provinceMatter{{i}}"
                                        name="provinceMatter{{i}}"
                                        [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.provinceCode"
                                        (change)="updateProvinceData(referralClientInfo)"
                                >
                                    <option *ngFor="let province of provinceOptions"
                                            [value]="province.value">{{province.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Postal Code</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralPostalCode{{i}}"
                                       name="referralPostalCode{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.address.postalCode"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="postalCodeCheckbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="postalCodeCheckbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="addressChange(referralClientInfo, $event, 'postalCode')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="postalCodeMatter{{i}}"
                                       name="postalCodeMatter{{i}}"
                                       [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.postalCode"
                                       class="form-control"/>
                            </div>
                        </div>
                        <div class="col-lg-12 form-group padding-top-10">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Country</label>
                            </div>
                            <div class="col-lg-3">
                                <input type="text"
                                       id="referralCountry{{i}}"
                                       name="referralCountry{{i}}"
                                       [dp-read-only]="true"
                                       [ngModel]="referralClientInfo.referredClient.address.country"
                                       class="form-control"/>
                            </div>
                            <div class="col-lg-1 text-center padding-left-15">
                                <dp-checkbox fieldId="countryCheckbox{{i}}" fieldClass="top-8">
                                    <input type="checkbox"
                                           id="countryCheckbox{{i}}"
                                           [checked]="referralClientInfo.allFieldsSelected"
                                           (change)="addressChange(referralClientInfo, $event, 'country')"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="countryMatter{{i}}"
                                        name="countryMatter{{i}}"
                                        [(ngModel)]="selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.country"
                                >
                                    <option *ngFor="let country of countryOptions"
                                            [value]="country.value">{{country.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </dp-accordion>
            </div>

            <div *ngIf="isClientCategoryDifferentAfter(i)" class="margin-top-20">
                <div class="col-lg-12 text-left" style="border-top: 2px solid #cccccc">
                    <label class="control-label" class="control-label margin-top-10 margin-bottom-10">{{getOtherSideClientCategoryName(i)}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
