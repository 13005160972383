import {Matter} from '../shared/matter';
import {MatterUtil} from '../shared/matter-util';
import {BaseTaxAdjustmentOn} from '../../projects/project-adjustments/project-tax-adjustment-config';
import {MatterTax} from '../shared/property-taxes';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import Utils from '../../shared-main/utils';
import moment from 'moment';

export class PaidByVendorStatementAdjustmentUtil {

  static isPurchaserShareFromOccupancyDataAvailable(matter: Matter): boolean {
    //formula: AND(E13<>"",E15<>"",YEAR(E13)=YEAR(E14))
    //since E15 (interim/final adjustAtDate is already needed for creating the adjustment, so it will already true in this case
    //OccupancyDate not null AND yearOccupancyDate == yearClosingDate
    const occupancyDate: string = MatterUtil.getOccupancyDate(matter);
    let result: boolean = occupancyDate && matter.matterCloseDate && (parseInt(occupancyDate) === parseInt(matter.matterCloseDate));
    // console.log(">> closing date is %s, occupancyDate is %s, there year value are same ? %s", matter.matterCloseDate, occupancyDate, result);
    return result;
  }

  //should always return array with two parts
  static getPurchaserShareInfoLabels(matter: Matter): string[] {
    let labels: string[] = [];
    //first line
    labels.push('Purchaser\'s share from occupancy');
    //formula for second line:
    // "to " & TEXT(DATE(YEAR(E14),12,31), "MMMM DD, YYYY (") & (DATE(YEAR(E14),12,31)-E13)+1 & " days)"
    labels.push(`to December 31, ${ parseInt(matter.matterCloseDate) } (${ PaidByVendorStatementAdjustmentUtil.getDaysDiffFromOccupancyDateToYearEndOfClosingDate(matter) + 1 } days)`);
    return labels;
  }

  //DATE(YEAR(E14),12,31)-E13
  static getDaysDiffFromOccupancyDateToYearEndOfClosingDate(matter: Matter): number {
    const daysDiff: number = Utils.daysDiff(MatterUtil.getOccupancyDate(matter), Utils.getYearEndDate(matter.matterCloseDate));
    return daysDiff;
  }

  //DATE(YEAR(E14),12,31)-DATE(YEAR(E14),1,1)
  static getDaysDiffFromYearBeginOfOccupancyDateToYearEndOfClosingDate(matter: Matter): number {
    const daysDiff: number = Utils.daysDiff(Utils.getYearStartDate(MatterUtil.getOccupancyDate(matter)), Utils.getYearEndDate(matter.matterCloseDate));
    return daysDiff;
  }

  static getUnitShareAmount(matter: Matter, propertyTax: MatterTax) {
    if (propertyTax && propertyTax.adjustmentBasedOnPercentageOfTotalTaxes === 'YES' && matter && matter.projectTaxAdjustmentConfig) {
      if (matter.projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.percentageOfTaxesForProject
        || matter.projectTaxAdjustmentConfig.taxAdjustmentType == BaseTaxAdjustmentOn.EqualDivisionAmongUnits) {
        return ((propertyTax.purchaserPortionOfTotalTax * this.calculateVendorHasPaidProject(propertyTax)) / 100);
      }
    }
    return '';
  }

  static calculateVendorHasPaidProject(propertyTax: MatterTax): number {
    return propertyTax.vendorWillHavePaid;
  }

  /**
   * Formula:
   =IF(AND(E13<>"",E14<>"",YEAR(E13)=YEAR(E14)),
   IF(E38<>"",
   (E38/365)*((DATE(YEAR(E14),12,31)-E13)+1 ),
   ELSE
   (E36/((DATE(YEAR(E14),12,31)-DATE(YEAR(E14),1,1))+1 ))*((DATE(YEAR(E14),12,31)-E13)+1 )),
   "")
   */
  static getPurchaserShareAmtValue(matter: Matter, propertyTax: MatterTax): string {
    // console.log(">> calculate purchaser share amount ...")
    const closingDate: string = matter.matterCloseDate; //E13
    const closingDateYear: number = parseInt(matter.matterCloseDate);
    const occupancyDate: string = MatterUtil.getOccupancyDate(matter); //E14
    const unitShareAmt = PaidByVendorStatementAdjustmentUtil.getUnitShareAmount(matter, propertyTax); //E38

    if (closingDate && occupancyDate) {
      const closingDateYear: number = parseInt(closingDate);
      const occupancyDateYear: number = parseInt(occupancyDate);
      if (closingDateYear == occupancyDateYear) {
        let calculatedAmt: number;
        if (unitShareAmt) {
          var currentYearFirst = moment([ closingDateYear, 0, 1 ]);
          var nextYearFirstDay = moment([ closingDateYear + 1, 0, 1 ]);
          let numberOfDaysInYear = nextYearFirstDay.diff(currentYearFirst, 'days');
          //Formula: (E38/365)*((DATE(YEAR(E14),12,31)-E13)+1 ),
          calculatedAmt =
            (Number(+unitShareAmt) / numberOfDaysInYear) * Number(PaidByVendorStatementAdjustmentUtil.getDaysDiffFromOccupancyDateToYearEndOfClosingDate(matter) + 1);
          // console.log("--- 1. unitShareAmt has value, so calculated result is: %s", calculatedAmt);
        } else {
          //Formula: (E36/((DATE(YEAR(E14),12,31)-DATE(YEAR(E14),1,1))+1 ))*((DATE(YEAR(E14),12,31)-E13)+1 ))
          const vendorHasPaidAmt: number = this.calculateVendorHasPaidProject(propertyTax); //E36
          calculatedAmt =
            Number(vendorHasPaidAmt / Number(PaidByVendorStatementAdjustmentUtil.getDaysDiffFromYearBeginOfOccupancyDateToYearEndOfClosingDate(matter) + 1)) *
            Number(this.getDaysDiffFromOccupancyDateToYearEndOfClosingDate(matter) + 1);
          // console.log("--- 2. unitShareAmt has no value, so calculated result is: %s", calculatedAmt);
        }
        return calculatedAmt + '';
      }
    }
    // console.log("--- 3. either closingDate or OccupancyDate is empty, so calculated result is blank");
    return '';
  }

  static isUnitShareAvailable(matter: Matter, propertyTax: MatterTax): boolean {
    return (propertyTax.adjustmentBasedOnPercentageOfTotalTaxes === 'YES' && (matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject || matter.isBaseTaxAdjustmentEqualDivisionAmongUnits));
  }

  static getFormattedCurrencyValue(currencyPipe: CurrencyPipe, currencyValue: number): string {
    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      return currencyPipe.transform(currencyValue, 'CAD', 'symbol', '1.2-2').replace('CA', '');
    } else {
      return currencyPipe.transform('0.00', 'CAD', 'symbol', '1.2-2').replace('CA', '');
      ;
    }

  }

  //should always return array with two parts
  static getUnitShareInfoLabels(matter: Matter, propertyTax: MatterTax, currencyPipe: CurrencyPipe, decimalPipe: DecimalPipe): string[] {
    let labels: string[] = [];
    //first line
    labels.push('Unit Share Calculated at');
    let formattedPurchaserPortaion: string = decimalPipe.transform(propertyTax.purchaserPortionOfTotalTax, '1.8-9');
    let secondLine: string = `${ formattedPurchaserPortaion } % of ${ this.getFormattedCurrencyValue(currencyPipe, this.calculateVendorHasPaidProject(propertyTax)) }`;
    labels.push(secondLine);
    return labels;
  }

  static calculateVendorShareForPaidByVendor(matter: Matter, propertyTax: MatterTax): number {
    return propertyTax.calculateVendorShareForPaidByVendor(matter.projectTaxAdjustmentConfig, true, matter);
  }

}
