import {StatementAdjustment} from '../statement-adjustment';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';

export class StatementAdjustmentDisplayHstOtherBuilder {

  static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder, rateType: string): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let adjustedProvinceHstRate: number = (mainBuilder.matter && mainBuilder.matter.isMatterProvinceMBorSK) ? 0 : mainBuilder.provinceHstRate;

    if (item.hstSalePrice.calculateUsingType == 'CREDIT_AMOUNT') {

      item.salePriceAdjustmentHSTFactor.netOutHstFromHSTSalePrice = 'YES_FACTOR_IN_HST_REBATE';

      sadItemDetails.push(mainBuilder.getNewItemLine(`Tax out Price`,
        mainBuilder.getCurrency(item.salePriceAdjustmentHSTFactor.isSalePriceFormatNSP() ?
          (item.salePriceAdjustmentHSTFactor.totalNetSalePrice(mainBuilder.federalHstRate, adjustedProvinceHstRate)) :
          item.hstSalePrice.creditAmount)
      ));

      sadItemDetails.push(mainBuilder.getNewItemLine(`${ rateType } calculated at ${ mainBuilder.getPercentage(mainBuilder.federalHstRate + adjustedProvinceHstRate) }`,
        mainBuilder.getCurrency((item.salePriceAdjustmentHSTFactor.calculateHstPortion(mainBuilder.federalHstRate) +
          item.salePriceAdjustmentHSTFactor.calculateHstPortion(adjustedProvinceHstRate)))
      ));

      sadItemDetails.push(mainBuilder.getNewItemLine(`Less ${ rateType } Rebate`,
        mainBuilder.getCurrency((item.salePriceAdjustmentHSTFactor.calculateProvincialRebate(adjustedProvinceHstRate) + item.salePriceAdjustmentHSTFactor.calculateRebate(mainBuilder.federalHstRate)))
      ));

      sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ mainBuilder.vendorLabel }`, ``));
    }
    if (item.hstSalePrice.calculateUsingType == 'TAX_OUT_PRICE') {

      item.salePriceAdjustmentHSTFactor.netOutHstFromHSTSalePrice = 'NO';

      sadItemDetails.push(mainBuilder.getNewItemLine(`Tax out Price`, mainBuilder.getCurrency(item.hstSalePrice.creditAmount)));
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ rateType } calculated at ${ mainBuilder.getPercentage(mainBuilder.federalHstRate + adjustedProvinceHstRate) }`,
        mainBuilder.getCurrency(item.salePriceAdjustmentHSTFactor.totalTaxOutHST(mainBuilder.federalHstRate, adjustedProvinceHstRate))));
      sadItemDetails.push(mainBuilder.getNewItemLine(`Less ${ rateType } Rebate`, mainBuilder.getCurrency(item.salePriceAdjustmentHSTFactor.totalTaxOutHSTRebate(mainBuilder.federalHstRate, adjustedProvinceHstRate))));

      sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ mainBuilder.vendorLabel }`, ``));
    }

    return sadItemDetails;
  }
}


