import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '../core';
import {opportunitiesApi} from './opportunities-api';
import {SESSION_STORAGE_KEYS} from '../shared';
import {Cirf, CirfStatusType} from '../matters/shared/cirf/cirf';
import {OpportunityView} from './opportunities-list/opportunity-view';
import {OpportunitiesListState} from './opportunities-list/opportunities-list-state';
import {Matter, Utils} from '../matters/shared';
import {MatterHelperService} from '../shared-main/matter-helper.service';
import {Referral} from '../matters/shared/referral/referral';

@Injectable()
export class OpportunitiesService {

  activeOpportunities: number = 0;

  unreadMessagesCount: number = 0;

  constructor(private http: HttpClient,
              public matterHelperService: MatterHelperService
  ) {

  }

  getOpportunitiesByStatus(status: CirfStatusType, accountId?: string): Observable<Cirf[]> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url: string = `${ opportunitiesApi.opportunitiesByStatus(accountId, status) }`;

    return this.http.get(url).map((res) => {
      return res[ 'Cirfs' ].map((item: Cirf) => {
        return new Cirf(item);
      });
    });
  }

  getAllCirfs(accountId?: string): Observable<Cirf[]> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    let url: string = `${ opportunitiesApi.accountCirfs(accountId) }`;

    return this.http.get(url).map((res) => {
      return res[ 'Cirfs' ].map((item: Cirf) => {
        return new Cirf(item);
      });
    });
  }

  getOpportunities(opportunitiesListState: OpportunitiesListState, perPage?: number, opportunityId?: number, isUnityC?: boolean): Observable<OpportunityView[]> {

    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    let searchQuery: string;
    if (opportunityId) {
      searchQuery = 'sort=lastUpdateOn|DESC&filter=ANYid_EQ_' + opportunityId + '&filterType=ALL';
    } else {
      searchQuery = this.createMatterSearchRequest(opportunitiesListState, isUnityC);
    }
    let url: string = `${ opportunitiesApi.opportunities(accountId) }?${ searchQuery }`;
    if (opportunitiesListState.pageNo) {
      url = url += `&page=${ !opportunityId ? opportunitiesListState.pageNo : 1 }&per_page=${ perPage }`;
    }
    /*if (opportunitiesListState.readUnreadSelection === 'UNREAD') {
        url = url += `&filter=ANYunread_EQ_true`;
    }*/
    return this.http.get(url).map((res) => {

      this.activeOpportunities = Number(res[ 'OpportunitiesCount' ]);

      return res[ 'Opportunities' ].map((item: Cirf) => {
        return new OpportunityView(item);
      });
    });
  }

  getOpportunitiesByMatter(matterId: number): Observable<OpportunityView[]> {
    let url: string = `${ opportunitiesApi.getOpportunitiesByMatter(matterId) }`;
    return this.http.get(url).map((res) => {
      return res[ 'Opportunities' ].map((item: OpportunityView) => {
        return new OpportunityView(item);
      });
    });
  }

  createMatterSearchRequest(opportunitiesListState: OpportunitiesListState, isUnityC?: boolean): string {
    let url: string;
    let searchText = opportunitiesListState.searchText; // matterNo or materReLine
    searchText = Utils.escapeSearchText(searchText);
    let sort: string = opportunitiesListState.sortQuery ? opportunitiesListState.sortQuery : 'lastUpdatedTimeStamp';
    let sortingType: string = opportunitiesListState.sortType ? opportunitiesListState.sortType : 'DESC';
    let filter: string = '';
    let filterFlag: boolean = false;

    if (Array.isArray(opportunitiesListState.selectedLawyers)) {
      let count: number = filter.length;
      filter = this.matterHelperService.createMatterSearchRequestForContacts(opportunitiesListState.selectedClerks, filter, 'LawClerk', 'lawClerk');
      if (filter.length > count) {
        filterFlag = true;
      }
    }

    if (Array.isArray(opportunitiesListState.selectedClerks)) {
      let count: number = filter.length;
      filter = this.matterHelperService.createMatterSearchRequestForContacts(opportunitiesListState.selectedLawyers, filter, 'Lawyer', 'lawyer');
      if (filter.length > count) {
        filterFlag = true;
      }
    }

    if (searchText != null && searchText != '' && searchText) {
      filter = Utils.addCommaToFilter(filter);
      if (isUnityC) {
        filter += 'ANYmatterSearchView.matterRecordNumber_EQ_' + searchText + '*|matterReline_EQ_*' + searchText + '*';

      } else {
        filter += 'ANYmatterSearchView.matterRecordNumber_EQ_' + searchText + '*|matterSearchView.clientReline_EQ_*' + searchText + '*';
      }
    }

    if (Array.isArray(opportunitiesListState.selectedStatuses) && opportunitiesListState.selectedStatuses.length > 0) {
      if (opportunitiesListState.selectedStatuses.indexOf('ALL') < 0) {
        filter = Utils.addCommaToFilter(filter);
        let statusFilter: string = Utils.escapeSearchText(this.replaceStatusForDeclinedShareAndReferral((opportunitiesListState.selectedStatuses).join('!')));
        filter += 'ANYstatus_IN_' + statusFilter;
        filterFlag = true;
      }
    }

    if (Array.isArray(opportunitiesListState.selectedTypes) && opportunitiesListState.selectedTypes.length > 0) {
      if (opportunitiesListState.selectedTypes.indexOf('ALL') < 0) {
        filter = Utils.addCommaToFilter(filter);
        filter += 'ANYopportunityType_IN_' + opportunitiesListState.selectedTypes.join('!');
        filterFlag = true;
      }
    }
    if (opportunitiesListState.readUnreadSelection === 'UNREAD') {
      filter = Utils.addCommaToFilter(filter);
      filter += 'ANYunread_EQ_true';
      filterFlag = true;
    }

    if (sort != null) {
      sort = 'sort=' + sort + '|' + sortingType;
    }

    url = searchText || filterFlag ?
      `${ sort }&filter=${ filter }&filterType=ALL`
      : `${ sort }`;

    return url;
  }

  //Replacing the UI maintained status DECLINED_REFERRAL/DECLINED_SHARE with DECLINED
  replaceStatusForDeclinedShareAndReferral(statusFilter: string): string {
    return statusFilter.replace('DECLINED_REFERRAL', 'DECLINED').replace('DECLINED_SHARE', 'DECLINED');
  }

  getNumberOfOpportunitiesByStatus(status: CirfStatusType): Observable<number> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = `${ opportunitiesApi.numberOfOpportunitiesByStatus(accountId, status) }`;

    return this.http.get(url).map((res) => {
      return (res && Number(res[ 'OpportunitiesCount' ]));
    });
  }

  getNumberOfActiveOpportunities(): Observable<void> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = `${ opportunitiesApi.numberOfOpportunitiesByStatus(accountId, 'SUBMITTED') }`;
    if (!this.http.isMassUpdateModeEnabled() && accountId) {
      return this.http.get(url).map((res) => {
        if (res) {
          this.activeOpportunities = Number(res[ 'OpportunitiesCount' ]);
          if (Number(res[ 'UnreadMessagesCount' ]) >= 0) {
            this.unreadMessagesCount = Number(res[ 'UnreadMessagesCount' ]);
          }
        }
      });
    }

    return Observable.of();
  }

  updateUnreadMessagesCount(count: number): void {
    this.unreadMessagesCount = count;
  }

  generateCIRFPackage(cirf: Cirf): Observable<Cirf> {
    let url = opportunitiesApi.cirfs(cirf.matterId);
    return this.http.post(url, cirf)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        return new Cirf(res[ 'Cirf' ]);
      }
    });
  }

  generateReadUnreadStatus(opportunityView: OpportunityView) {
    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    let url = opportunitiesApi.readUnreadStatus(accountId);
    return this.http.put(url, opportunityView)
    .map((res) => {
      if (res[ 'SUCCESS' ]) {
        return res;
      }
    });
  }

  getCIRFByPackageGUIDForUnity(guid: string, matterId: number): Observable<Cirf> {
    let url = opportunitiesApi.getCIRFByPackageGUIDForUnity(matterId, guid);
    return this.http.get(url)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        return new Cirf(res[ 'Cirf' ]);
      }
    });
  }

  reOpenCirf(cirf: OpportunityView): Observable<Cirf> {
    let url: string = opportunitiesApi.reOpenCirf(cirf.matterId, cirf.id);

    return this.http.put(url, cirf)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        return new Cirf(res[ 'Cirf' ]);
      }
    });
  }

  cancelCirf(cirf: OpportunityView): Observable<Cirf> {
    let url: string = opportunitiesApi.cancelCirf(cirf.matterId, cirf.id);

    return this.http.put(url, cirf)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        return new Cirf(res[ 'Cirf' ]);
      }
    });
  }

  setCirfProcessed(cirf: OpportunityView): Observable<Cirf> {
    let url: string = opportunitiesApi.completeCIRF(cirf.matterId, cirf.id);

    return this.http.put(url, cirf)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        return new Cirf(res[ 'Cirf' ]);
      }
    });
  }

  completeCIRFRequest(cirfId: number, matterId: number): Observable<Cirf> {
    let url = opportunitiesApi.completeCIRF(matterId, cirfId);
    return this.http.put(url, null)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        return new Cirf(res[ 'Cirf' ]);
      }
    });
  }

  getMatterCirf(matterId: number): Observable<Cirf> {
    let url = opportunitiesApi.matterCirf(matterId);
    return this.http.get(url)
    .map((res) => {
      if (res[ 'Cirf' ]) {
        // console.table(res['Cirf']);
        return new Cirf(res[ 'Cirf' ]);
      }
    })
    .catch((err) => {
      if (err.errorCode === 'app.cirf.cannot.find.cirf.for.matter') {
        // console.log('app.cirf.cannot.find.cirf.for.matter');
        return Observable.of(null);
      }
      throw err;
    });
  }

  getMatterCirfs(matterId: number): Observable<Cirf[]> {
    let url = opportunitiesApi.cirfs(matterId);
    return this.http.get(url).map((res) => {
      return res[ 'Cirfs' ].map((item: Cirf) => {
        return new Cirf(item);
      });
    });
  }

  getReferralById(referralId: number): Observable<Referral> {

    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    let url: string = `${ opportunitiesApi.referralById(accountId, referralId) }`;

    return this.http.get(url).map((res) => {
      if (res[ 'Referral' ]) {
        return new Referral(res[ 'Referral' ]);
      }
    });
  }

  reOpenReferral(referral: OpportunityView): Observable<Referral> {
    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = opportunitiesApi.reOpenReferral(accountId, referral.id);

    return this.http.put(url, referral)
    .map((res) => {
      if (res[ 'Referral' ]) {
        return new Referral(res[ 'Referral' ]);
      }
    });
  }

  opportunityMatterByReferral(referral: OpportunityView): Observable<Matter> {
    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = opportunitiesApi.opportunityMatterByReferral(accountId, referral.id);

    return this.http.get(url)
    .map((res) => {
      if (res[ 'Matter' ]) {
        return new Matter(res[ 'Matter' ]);
      }
    });
  }

  cancelReferral(referral: OpportunityView): Observable<Referral> {
    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = opportunitiesApi.declineReferral(accountId, referral.id);

    return this.http.put(url, referral)
    .map((res) => {
      if (res[ 'Referral' ]) {
        return new Referral(res[ 'Referral' ]);
      }
    });
  }

  acceptReferral(matterId: number, referralId: number): Observable<Referral> {
    let url: string = opportunitiesApi.acceptReferral(matterId, referralId);

    return this.http.put(url, null)
    .map((res) => {
      if (res[ 'Referral' ]) {
        return new Referral(res[ 'Referral' ]);
      }
    });
  }

  downloadReferralDocument(referralId: number, documentId: number): void {
    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let sessionId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId);
    let url = opportunitiesApi.downloadReferralDocument(accountId, sessionId, referralId, documentId);
    window.open(url, '_blank');
  }

  getOpportunityById(opportunitiesListState: OpportunitiesListState, data: OpportunityView): Observable<OpportunityView> {
    return this.getOpportunities(opportunitiesListState, 50, data.id).map(opportunityViews => {
      if (opportunityViews && opportunityViews.length > 0) {
        let updatedOpportunityView: OpportunityView = opportunityViews.find(item => item.id == data.id);
        return updatedOpportunityView;
      }
    });
    //return;
  }
}
