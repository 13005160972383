import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomPickListService} from './custom-pick-list.service';
import {SpecialComment} from './special-comment';
import {ErrorService} from '../error-handling/error-service';
import {DPError} from '../error-handling/dp-error';
import {AutoComplete} from 'primeng/autocomplete';
import {Utils} from '../../matters/shared';
import {Subscription} from 'rxjs';

@Component({
  selector: 'dp-autocomplete-custom-pick-list',
  templateUrl: './autocomplete-custom-pick-list.component.html',
  styleUrls: [
    './custom-pick-list.styles.scss'
  ]
})
export class AutoCompleteCustomPickListComponent implements OnInit {
  @ViewChild('customPickList') customPickList: AutoComplete;

  @Input() public id: string;
  @Input() public name: string;
  @Input() public minLength: string;
  @Input() public maxLength: string;
  @Input() public scrollHeight: string;
  @Input() public delay: string;
  @Input() public errorService: ErrorService;
  @Input() public pickListType: string;

  @Input() public parentModel: string;
  @Output() parentModelChange: EventEmitter<string>;

  @Output() onPickListEdit = new EventEmitter<string>();

  @Input() orderNum: number;

  searchSubscription: Subscription;

  inputValue: string;
  tabKey: boolean = false;
  utils;
  // The following is current logic for p-autoComplete.
  // If 'onDropdownClick' is click, it will create two trigger - one is 'onDropdownClick'; the other is 'completeMethod'.
  // So 'suggestions' value depends on the later respond form server side. So it messes up the dropdown list.
  // Use onDropdownClick to make sure to display the 'suggestions' by 'onDropdownClick'.
  onDropdownClick: boolean = false;

  public pickListValueObject: any;

  public pickListSuggestions: SpecialComment[];

  constructor(public customPickListService: CustomPickListService) {
    this.parentModelChange = new EventEmitter<string>();
    this.utils = new Utils();
  }

  ngOnInit(): void {
    if (this.parentModel && this.parentModel != null && this.parentModel.trim() != '') {
      this.pickListValueObject = this.parentModel;
    } else {
      this.pickListValueObject = null;
    }
    this.inputValue = this.parentModel;
  }

  handleOnFocus(event) {
    event.target.nextElementSibling.tabIndex = '-1';
  }

  onKeyDown(event: KeyboardEvent) {
    this.tabKey = (event.key === 'Tab');
    let customPickList: any = this.customPickList;
    if (event.key === 'ArrowDown' && !customPickList.panelVisible) {
      this.populateAllSuggestions(event);
    }
  }

  populateSearchSuggestions(event) {
    this.searchSubscription = this.customPickListService.getPickListAutocomplete(event.query, this.pickListType)
    .subscribe(
      (res: SpecialComment[]) => {
        if (!this.onDropdownClick) {
          this.pickListSuggestions = res;
        }
      });
  }

  populateAllSuggestions = (event) => {
    this.tabKey = false;
    this.onDropdownClick = true;
    this.customPickListService.getPickListDropdown(this.pickListType)
    .subscribe(
      (res: SpecialComment[]) => {
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        this.onDropdownClick = false;
        this.pickListSuggestions = res;
        this.customPickList.show();
      },
      error => {
        if (this.searchSubscription) {
          this.searchSubscription.unsubscribe();
        }
        this.onDropdownClick = false;
      });
  };

  handleShowPickListEditDialog(event) {
    this.pickListValueObject = '';
    // this.doCreditCardCheck(event);
    if (event.id === undefined) {
      setTimeout(() => {
        if (!this.tabKey) {
          this.onPickListEdit.emit(this.id);
          this.pickListValueObject = undefined;
          this.updateParentModel(undefined);
        }

      }, 0);
    } else {
      this.updateParentModel(event);
    }
  }

  doCreditCardCheck(event): void {
    if (this.errorService) {
      if (event.customPickListItemValue === 'Credit Card') {
        this.errorService.addDpFieldError(DPError.createDPError(this.name));
      } else {
        this.errorService.removeDpFieldError(this.name);
      }
    }
  }

  updateParentModel(event) {
    // DPPMP-7389
    // This event(ngModelChange) is triggered while 'tab' key is pressed to off-focus
    // In this case, the last selected data is passed in which confused me as that data
    // was selected by clicked from the dropdown.
    // In order to handle this issue, we need to delay this handling to the next event loop,
    // so 'tab' key can be detected by onKeyDown event handler. pickListValueObject
    setTimeout(() => {
      if (!this.tabKey || (this.inputValue === null)) {
        if (typeof event === 'object') {
          this.parentModel = event.customPickListItemValue;
          this.pickListValueObject = this.parentModel;
        } else {
          this.parentModel = event;
          this.pickListValueObject = this.parentModel;
        }
        this.inputValue = this.parentModel;
      } else {
        this.parentModel = this.inputValue;
        this.pickListValueObject = this.parentModel;
        this.inputValue = null;
      }

      this.parentModelChange.emit(this.parentModel);
    }, 0);
  }

  setDefaultType(comment: SpecialComment): void {
    this.pickListSuggestions.push(comment);
    this.pickListValueObject = comment.customPickListItemValue;
  }

  setValue(comment: SpecialComment): void {
    this.pickListValueObject = comment.customPickListItemValue;
    this.updateParentModel(comment);
  }

}

