<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Select the appropriate spousal statement</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row" id="fla-data">
            <div class="col-lg-12 form-group">
                <ul>
                    <li>
                        <dp-checkbox fieldId="notMatrimonialHome">
                            <input type="checkbox"
                                   id="notMatrimonialHome"
                                   name="notMatrimonialHome"
                                   [checked]="notMatrimonialHome"
                                   (ngModelChange)="setFlaSpouseType($event, 'NOT_MATRIMONIAL_HOME')"
                                   [(ngModel)]="notMatrimonialHome"
                            />
                        </dp-checkbox>
                        <p>{{getDescription("NOT_MATRIMONIAL_HOME")}}</p>
                    </li>
                    <li>
                        <dp-checkbox fieldId="spouseBySeparationAgreement">
                            <input type="checkbox"
                                   id="spouseBySeparationAgreement"
                                   name="spouseBySeparationAgreement"
                                   [checked]="spouseBySeparationAgreement"
                                   (ngModelChange)="setFlaSpouseType($event, 'SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT')"
                                   [(ngModel)]="spouseBySeparationAgreement"
                            />
                        </dp-checkbox>
                        <p>{{getDescription("SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT")}}</p>
                    </li>
                    <li>
                        <dp-checkbox fieldId="spouseByVirtueOfOrder">
                            <input type="checkbox"
                                   id="spouseByVirtueOfOrder"
                                   name="spouseByVirtueOfOrder"
                                   [checked]="spouseByVirtueOfOrder"
                                   (ngModelChange)="setFlaSpouseType($event, 'SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER')"
                                   [(ngModel)]="spouseByVirtueOfOrder"
                            />
                        </dp-checkbox>
                        <p>{{getDescription("SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER")}}</p>
                    </li>
                    <li>
                        <dp-checkbox fieldId="designatedAnotherProperty">
                            <input type="checkbox"
                                   id="designatedAnotherProperty"
                                   name="designatedAnotherProperty"
                                   [checked]="designatedAnotherPropertyAsMatrimonialHome"
                                   (ngModelChange)="setFlaSpouseType($event, 'DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME')"
                                   [(ngModel)]="designatedAnotherPropertyAsMatrimonialHome"
                            />
                        </dp-checkbox>
                        <p>{{getDescription("DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME")}}</p>


                    </li>
                </ul>
            </div>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="ok"
                    (click)="ok()"
                    class="dp-btn keyboard-save-action">
                <span>Ok</span>
            </button>
            <button type="button"
                    id="cancel"
                    (click)="cancel()"
                    class="dp-btn secondary-button">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
