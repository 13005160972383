import {Tab} from '../shared/tabbing/tab';

export class ExportFileManagerTab extends Tab {

  subRoute: string;
  subRouteId: number;

  eRegManagerFilter: ERegManagerFilter;

  constructor(src?: ExportFileManagerTab) {
    super(src);
  }

  get tabTypeForDisplay(): string {
    return this.type;
  }
}

export class ERegManagerFilter {
  selectedProjectId: number = 0;
  selectedStatusFilter: string = 'GENERATED';
  selectedShowFilter: string = 'SHOW_ALL';
}
