import {Component, Input, OnInit} from '@angular/core';
import {Matter} from '../../shared';
import {Referral} from '../../shared/referral/referral';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ReferralForm} from '../../shared/referral/referral-form';
import {ReferredClient} from '../../shared/referral/referred-client';
import {MatterTopic} from '../../shared/matter-topic';
import {StickyNotesModal} from '../../../shared-main/sticky-notes/sticky-notes.modal.component';
import {Contact} from '../../shared/contact';
import {MatterParticipant} from '../../shared/matter-participant';
import {Observable, Subject} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {cirfDropDowns} from '../../purchaser/cirf/cirf-import-data/cirf-drop-downs';
import {CommonContactDialogComponent} from '../common-contact-dialog/common-contact-dialog.component';
import {MatterParticipantWrapper} from '../../shared/matter-participant-wrapper';
import {PurchaserService} from '../../purchaser/purchaser.service';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {TabsService} from '../../../core/tabs.service';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ContactService} from '../../../shared-main/contact.service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {Constants} from '../../../shared-main/constants';
import {Utils} from '../../shared/utils';
import {
  UnitConnectDataPropagation,
  UnitConnectDataPropagationCmdType
} from '../../purchaser/cirf/cirf-import-data/unit-connect-data-propagation';
import SharedUtils from '../../../shared-main/utils';
import {Address} from '../../shared/address';

declare var jQuery: any;

export class ReferralImportDataHelper {

  id: number;
  fullName: string;
  //clientContactId not populated always. it gets pouplated if exising participant selected for import.
  clientContactId: string;
  itemType: string;
  clientName: string;
  dataModel: any;
  clientContact: Contact;//Snapshot contact of selected participant
  referredClient: ReferredClient;

  participantCopy: MatterParticipant;
  allFieldsSelected: boolean;

  clients: any[] = [];
  searchTermStreamClient = new Subject<string>();
  clientSearchOptionsLoading = false;
  autoCompleteEmptyFlag: any;
  clientSearchOptions: any;

  isNewRecord(): boolean {
    return (this.clientContactId == 'New');
  }

  isNew(): boolean {
    return (this.itemType == 'NEW');
  }

  isExisting(): boolean {
    return (this.itemType == 'EXISTING');
  }

  isBuyer(): boolean {
    return this.referredClient && this.referredClient.role === 'BUYER';
  }

  isOwner(): boolean { //refinance
    return this.referredClient && this.referredClient.role === 'OWNER';
  }
}

@Component({
  selector: 'dp-referral-import-parties',
  templateUrl: 'referral-import-parties.component.html'
})

export class ReferralImportPartiesComponent implements OnInit {
  @Input() matter: Matter;
  @Input() originalMatter: Matter; //original matter used for reverting changes
  @Input() referral: Referral;
  @Input() referralDataPropagation: UnitConnectDataPropagation;

  referralImportData: ReferralImportDataHelper[] = [];

  // countryOptions and provinceOptions same with cirf
  countryOptions: SelectItem[] = cirfDropDowns.countryOptions;
  provinceOptions: SelectItem[] = cirfDropDowns.provinceOptions;

  constructor(public dialogService: DialogService,
              public purchaserService: PurchaserService,
              public contactQueryService: ContactQueryService,
              public tabsService: TabsService,
              public contactService: ContactService,
              public errorService: ErrorService
  ) {
  }

  ngOnInit() {
    this.mapReferralDataToOwnerData(this.referral);

  }

  get referralForm(): ReferralForm {
    return this.referral.referralForm;
  }

  get mainClientTopic(): MatterTopic {
    return this.matter.getMatterTopicByKey(this.matter.mainClientType);
  }

  // Purchase matter - buyers to Purchasers (client), sellers to Vendors & Solicitor (other side)
  // Sale matter - sellers to Vendors (client), buyers to Purchasers & Solicitor (other side)
  // Refinance / Mortgage matter - owners to Mortgagors (client)
  getMatterClients(referralClientInfo: ReferralImportDataHelper): MatterParticipant[] {
    return this.isReferredContactMainClient(referralClientInfo) ? this.matter.mainClients : this.matter.otherSideClients;
  }

  // Purchase matter - buyers to Purchasers (client), sellers to Vendors & Solicitor (other side)
  // Sale matter - sellers to Vendors (client), buyers to Purchasers & Solicitor (other side)
  // Refinance / Mortgage matter - owners to Mortgagors (client)
  getMatterParticipantRole(referralClientInfo: ReferralImportDataHelper) {
    return this.isReferredContactMainClient(referralClientInfo) ? this.matter.mainClientType : this.matter.otherSideClientType;
  }

  // Purchase matter - buyers to Purchasers (client), sellers to Vendors & Solicitor (other side)
  // Sale matter - sellers to Vendors (client), buyers to Purchasers & Solicitor (other side)
  // Refinance / Mortgage matter - owners to Mortgagors (client)
  isReferredContactMainClient(referralClientInfo: ReferralImportDataHelper): boolean {
    return (this.matter.isPurchase && (referralClientInfo.isBuyer() || referralClientInfo.isOwner()))
      || (this.matter.isSale && !referralClientInfo.isBuyer())
      || (this.matter.isMortgage);
  }

  openStickyNotesModal(): void {
    this.dialogService.matDialogContent({
      content: StickyNotesModal,
      context: {
        notes: this.mainClientTopic.topicNote
      },
      onFulfillment: (result) => {
        if (result && result.status == 'OK') {
          this.mainClientTopic.topicNote = result.notes;
        } else if (result && result.status == 'DELETE') {
          this.mainClientTopic.topicNote = '';
        }
      }
    });
  }

  getClientDataNotes(): string {
    let clientDataNotes: string = '------------------------------------\n' +
      '   Parties From Referral\n' +
      '------------------------------------\n';

    for (let i: number = 0; i < this.referralForm.referredClients.length; i++) {
      let referredClient: ReferredClient = this.referralForm.referredClients[ i ];
      clientDataNotes += '\nParty ' + (i + 1);
      clientDataNotes += '\n-First Name: ' + Utils.returnValidString(referredClient.firstName);
      clientDataNotes += '\n-Middle Name: ' + Utils.returnValidString(referredClient.middleName);
      clientDataNotes += '\n-Last Name: ' + Utils.returnValidString(referredClient.lastName);
      clientDataNotes += '\n-Phone: ' + Utils.returnValidString(referredClient.homePhone);
      clientDataNotes += '\n-Role: ' + Utils.returnValidString(referredClient.role);
      clientDataNotes += '\n-E-Mail: ' + Utils.returnValidString(referredClient.email);
      clientDataNotes += '\n-Address 1: ' + Utils.returnValidString(referredClient.address.addressLine1);
      clientDataNotes += '\n-Address 2: ' + Utils.returnValidString(referredClient.address.addressLine2);
      clientDataNotes += '\n-City: ' + Utils.returnValidString(referredClient.address.city);
      clientDataNotes += '\n-Province: ' + Utils.returnValidString(referredClient.address.provinceCode);
      clientDataNotes += '\n-Postal Code: ' + Utils.returnValidString(referredClient.address.postalCode);
      clientDataNotes += '\n-Country: ' + Utils.returnValidString(referredClient.address.country) + '\n';
    }

    return clientDataNotes;
  }

  copyAllPartiesToNotes(event): void {
    if (event.srcElement.checked) {
      if (this.mainClientTopic.topicNote && this.mainClientTopic.topicNote.length > 0) {
        this.mainClientTopic.topicNote += '\n';
      } else {
        this.mainClientTopic.topicNote = '';
      }
      this.mainClientTopic.topicNote += this.getClientDataNotes();
    } else {
      this.mainClientTopic.topicNote = this.mainClientTopic.topicNote.replace(this.getClientDataNotes(), '');
    }
  }

  mapReferralDataToOwnerData(referral: Referral) {
    this.referralImportData = [];

    if (referral && referral.referralForm) {
      referral.referralForm.referredClients.forEach(clientInfo => {
        let ownerData = new ReferralImportDataHelper();
        ownerData.referredClient = clientInfo;

        ownerData.dataModel = ownerData.referredClient.getFullName();
        let matterClients: MatterParticipant[] = this.getMatterClients(ownerData);
        ownerData.clientContactId = matterClients && matterClients.length > 0 ? '' : 'New';
        this.referralImportData.push(ownerData);

        ownerData.clients.push({'label': 'Select Client you are replacing', 'value': ''});
        matterClients.forEach(item => {
          ownerData.clients.push({'label': item.contact.genericFullName, 'value': item.identifier});
        });
        if (this.isReferredContactMainClient(ownerData)) {
          ownerData.clients.push({'label': 'Add new Client', 'value': 'New'});
        } else {
          ownerData.clients.push({'label': 'Add new Other Party', 'value': 'New'});
        }

        ownerData.searchTermStreamClient
        .switchMap((term: string) => {
          ownerData.clientSearchOptionsLoading = true;
          if (term.trim() === '') {
            ownerData.autoCompleteEmptyFlag = true;
            let observable = Observable.create((observer) => {
              setTimeout(() => {
                observer.next();
              }, 10);
            });

            return observable;

          } else {
            ownerData.autoCompleteEmptyFlag = false;
            if (this.isReferredContactMainClient(ownerData)) {
              return this.purchaserService.getClientPurchaser(term, false, false);
            } else {
              return this.contactService.getActiveContactListPerPage(term, 'CLIENT', false, 1, 15, 'ACTIVE');

            }

          }
        })
        .subscribe(
          (clients: any) => {
            ownerData.clientSearchOptionsLoading = false;
            ownerData.clientSearchOptions = clients;
          },
          error => {
            ownerData.clientSearchOptionsLoading = false;
          }
        );

      });
    }

    // Group main clients and other side clients
    this.referralImportData.sort((a, b) => {
      if (this.isReferredContactMainClient(a) && !this.isReferredContactMainClient(b)) {
        return -1;
      } else if (!this.isReferredContactMainClient(a) && this.isReferredContactMainClient(b)) {
        return 1;
      }
      return 0;
    });
  }

  addressChange(referralClientInfo: ReferralImportDataHelper, event: any, addressField: string): void {
    if (event.srcElement.checked) {
      this.selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress[ addressField ] = referralClientInfo.referredClient.address[ addressField ];
      if (addressField == 'provinceCode') {
        let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == referralClientInfo.referredClient.address.provinceCode);
        if (provinceOption) {
          this.selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.provinceName = provinceOption.label;
        }
      }
    } else {
      this.selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress[ addressField ] = referralClientInfo.participantCopy.contact.mailingAddress[ addressField ];
      if (addressField == 'provinceCode') {
        this.selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress.provinceName = referralClientInfo.participantCopy.contact.mailingAddress.provinceName;
      }
    }
  }

  updateProvinceData(referralClientInfo: ReferralImportDataHelper) {
    let selectedAddress: Address = this.selectedParticipantFromMatter(referralClientInfo).contact.mailingAddress;
    if (selectedAddress) {
      let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == selectedAddress.provinceCode);
      selectedAddress.provinceName = provinceOption && provinceOption.label;
    }
  }

  phoneChange(referralClientInfo: ReferralImportDataHelper, event: any, phoneType: string): void {
    if (event.srcElement.checked) {
      this.selectedParticipantFromMatter(referralClientInfo).contact[ phoneType ] = referralClientInfo.referredClient[ phoneType ];
    } else {
      this.selectedParticipantFromMatter(referralClientInfo).contact[ phoneType ] = referralClientInfo.participantCopy.contact[ phoneType ];
    }
  }

  getAddressValue(contact: Contact) {
    if (contact && contact.mailingAddress) {
      return contact.mailingAddress.addressTextWithoutCountryAndPostalCode;
    } else {
      return '';
    }
  }

  // autocomplete
  // PURCHASER
  // Omnibar methods to get contacts
  search(event, item: ReferralImportDataHelper): void {
    let entered: string = event.query;
    item.searchTermStreamClient.next(entered);
  }

  clearClient(item: ReferralImportDataHelper): void {
    item.dataModel = '';
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  dataSelectedClient(item: ReferralImportDataHelper): void {

    let selectedContact = item.dataModel;
    if (selectedContact && selectedContact.id != undefined) {
      this.contactQueryService.getContactForMatter(selectedContact.id)
      .subscribe((contact: Contact) => {
        item.itemType = 'NEW';
        let participant: MatterParticipant = this.addMatterParticipantWithSelectedContact(contact, item, true);
        this.useParticipantForImportingReferralData(participant, item);
        this.referralDataPropagation.addToDataPropagationCommands(
          this.isReferredContactMainClient(item) ? UnitConnectDataPropagationCmdType.CIRF_REFERRAL_IMPORT_MAIN_CLIENT : UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_CLIENT,
          null, true);

      });
    } else if (selectedContact && selectedContact.displayName && selectedContact.displayName.indexOf('Add new record for') > -1) {
      let tempParticipant = this.matter.createMatterParticipant(this.getMatterParticipantRole(item));
      this.dialogService.matDialogContent({
        content: CommonContactDialogComponent,
        context: {
          matter: this.matter,
          contactType: 'CLIENT',
          matterParticipant: tempParticipant,
          isOtherParty: !this.isReferredContactMainClient(item),
          contactName: item.referredClient.surnameLastFullName(),
          matterParticipantRole: this.getMatterParticipantRole(item),
          reducedContactDetails: true
        },
        onFulfillment: (result) => {
          if (result && result.action === 'save') {
            item.itemType = 'NEW';
            // If it is other side client and without create new source contact, we pass false as withCloning
            let withCloning: boolean = this.isReferredContactMainClient(item) ? true : result.createdNewClient;
            let participant: MatterParticipant = this.addMatterParticipantWithSelectedContact(result.contact, item, withCloning);
            this.useParticipantForImportingReferralData(participant, item);
            this.referralDataPropagation.addToDataPropagationCommands(
              this.isReferredContactMainClient(item) ? UnitConnectDataPropagationCmdType.CIRF_REFERRAL_IMPORT_MAIN_CLIENT : UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_CLIENT,
              null, true);
          } else {
            item.dataModel = '';
          }
        },

      });

    }
  }

  addMatterParticipantWithSelectedContact(selectecContact: Contact, referralClientInfo: ReferralImportDataHelper, withCloning: boolean): MatterParticipant {
    let participant: MatterParticipant = this.matter.addMatterParticipant(selectecContact, withCloning, this.getMatterParticipantRole(referralClientInfo));
    participant.contact.lastSyncedFromSource = selectecContact.lastUpdatedTimeStamp;
    participant.primary = (this.getMatterClients(referralClientInfo).length == 1);
    let maxPriority: number = this.getMatterClients(referralClientInfo).length == 0 ? 0 : Math.max(...this.getMatterClients(referralClientInfo).map(matterParticipant => {
      return Number(matterParticipant.matterParticipantPriority);
    }));
    maxPriority = isNaN(maxPriority) ? 0 : maxPriority;
    participant.matterParticipantPriority = maxPriority + 1;
    return participant;
  }

  async updateClientName(item: ReferralImportDataHelper, index: number): Promise<void> {
    item.clientName = '';
    if (item.isNewRecord()) {
      let currentComp = this;
      setTimeout(() => {
        jQuery('#clientSearch' + index).find('input').focus();
        jQuery('#clientSearch' + index).find('input').keyup();
        // currentComp.searchTermStreamClient.next(item.dataModel);
      }, 100);
      item.itemType = 'NEW';
      if (!item.dataModel) {
        item.dataModel = item.fullName;
      }

    } else if (item.clientContactId != undefined && item.clientContactId != '') {
      let client = item.clients.find(obj => obj.value == item.clientContactId);
      if (client) {
        item.clientName = client.label;
        item.itemType = 'EXISTING';
        let matterParticipant = this.getMatterClients(item).find(obj => obj.identifier == Number(item.clientContactId));
        await this.useParticipantForImportingReferralData(matterParticipant, item);
      }
    }
  }

  updateReferralImportDataFieldsForParticipantCopy(matterParticipant: MatterParticipant, referralImportData: ReferralImportDataHelper, matterParticipantCopy: MatterParticipant) {
    referralImportData.participantCopy = matterParticipantCopy;
    referralImportData.clientContact = matterParticipant.contact;
    matterParticipant.contact.isDirty = true;
    referralImportData.clientName = matterParticipant.contact.genericName;
  }

  async useParticipantForImportingReferralData(matterParticipant: MatterParticipant, referralImportData: ReferralImportDataHelper): Promise<void> {
    this.errorService.clearAllSaveErrors();
    if (matterParticipant) {
      let matterParticipantCopy = new MatterParticipant(matterParticipant);
      if (matterParticipant.contact && matterParticipant.contact.sourceContactId) { // With sourceContact
        //Creating a copy before the participant is locked to use it if the flow proceeds.
        let isContactStale: boolean = await this.checkContactStaleOrClearFlag(matterParticipant, referralImportData);
        let isSourceContactLocked: boolean;
        if (!isContactStale) {
          isSourceContactLocked = await this.checkContactForLocking(matterParticipant);
        }
        if (!isContactStale && (isSourceContactLocked || matterParticipant.sourceContactLockAcquired)) {
          //Using the original participant copy created above without any flags changed for locking.
          this.updateReferralImportDataFieldsForParticipantCopy(matterParticipant, referralImportData, matterParticipantCopy);
        } else {
          if (referralImportData.isNew()) {
            let participantIndex = this.matter.matterParticipants.findIndex(item => item.equals(matterParticipant));
            this.matter.matterParticipants.splice(participantIndex, 1);

          }
        }
      } else { // Without sourceContact
        this.updateReferralImportDataFieldsForParticipantCopy(matterParticipant, referralImportData, matterParticipantCopy);
      }
    }
  }

  selectedParticipantFromMatter(referralClientInfo: ReferralImportDataHelper): MatterParticipant {
    return this.matter.matterParticipants.find(item => referralClientInfo.participantCopy && item.matterParticipantId === referralClientInfo.participantCopy.matterParticipantId);
  }

  async removeClientName(item: ReferralImportDataHelper): Promise<void> {
    let matterParticipant = this.selectedParticipantFromMatter(item);
    this.tabsService.releaseContactLock(matterParticipant);
    this.updateOrRemoveParticipant(item);

    item.clientName = undefined;
    item.clientContactId = '';
    item.clientContact = null;
    item.participantCopy = null;

    item.allFieldsSelected = null;

    this.referralDataPropagation.removeDataPropagationCommand(
      this.isReferredContactMainClient(item) ? UnitConnectDataPropagationCmdType.CIRF_REFERRAL_IMPORT_MAIN_CLIENT : UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_CLIENT);
  }

  releaseContactLocks(): void {
    // Release the lock only it the participant is selected and participant copy is present.
    this.referralImportData.filter(item => item.participantCopy).forEach(item => {
      let matterParticipant = this.selectedParticipantFromMatter(item);
      this.tabsService.releaseContactLock(matterParticipant);
    });
  }

  getmatterParticipantTitle(referralImportData: ReferralImportDataHelper) {
    if (this.getMatterParticipantRole(referralImportData) === 'VENDOR') {
      return this.matter.otherPartyTitle;
    } else {
      return this.matter.mainClientTitle;
    }
  }

  async checkContactStaleOrClearFlag(matterParticipant: MatterParticipant, referralImportData: ReferralImportDataHelper): Promise<boolean> {
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.sourceContactId && !matterParticipant.sourceContactLockAcquired) {
      let sourceContact: Contact = await this.contactQueryService.getContactForMatter(Number(matterParticipant.contact.sourceContactId)).toPromise();
      if (sourceContact) {
        let matterParticipant = this.getMatterClients(referralImportData).find(client => client.contact.sourceContactId == sourceContact.id);
        let source: Contact = new Contact(sourceContact);
        let isStale = matterParticipant.contact.isStaleContact(source);
        let isContactStaleFlagClearedWithoutUpdatingMatter = matterParticipant.contact.isContactStaleFlagClearedWithoutUpdatingMatter(source);
        if (isStale || isContactStaleFlagClearedWithoutUpdatingMatter) {
          let message = (this.getmatterParticipantTitle(referralImportData)) + ' ' + matterParticipant.contact.genericFullName + ' can not be' +
            ' updated as the contact is out' +
            ' of sync.';
          this.errorService.addDpSaveError(DPError.createCustomDPError('none', message, 'CONTACT', 'ERROR'));
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      }
    }

  }

  async checkContactForLocking(participant: MatterParticipant): Promise<boolean> {
    if (participant && participant.contact && participant.contact.sourceContactId && !participant.sourceContactLockAcquired) {
      let matterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.matterParticipant = participant;
      let sourceContact = await this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).toPromise();
      if (sourceContact.locked) {
        let message = sourceContact.genericFullName + ' is currently locked by ' + sourceContact.lockedByUser.fullName;
        this.errorService.addDpSaveError(DPError.createCustomDPError('none', message, 'CONTACT', 'ERROR'));
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }

  }

  updateOrRemoveParticipant(item: ReferralImportDataHelper): void {
    let matterParticipant = this.selectedParticipantFromMatter(item);
    let participantIndex = this.matter.matterParticipants.findIndex(item => item.equals(matterParticipant));
    if (participantIndex > -1) {
      if (item.isExisting() && item.participantCopy) {
        this.matter.matterParticipants[ participantIndex ] = new MatterParticipant(item.participantCopy);
      } else {
        this.matter.matterParticipants.splice(participantIndex, 1);
      }
    }
  }

  updateAllFields(event, referralImportData: ReferralImportDataHelper): void {
    this.updateContactDetailsFromReferral(referralImportData, event, 'contactName', 'firstName');
    this.updateContactDetailsFromReferral(referralImportData, event, 'contactName', 'lastName');
    this.updateContactDetailsFromReferral(referralImportData, event, 'contactName', 'middleName');
    this.updateContactDetailsFromReferral(referralImportData, event, 'contact', 'email');

    this.phoneChange(referralImportData, event, 'homePhone');

    this.addressChange(referralImportData, event, 'addressLine1');
    this.addressChange(referralImportData, event, 'addressLine2');
    this.addressChange(referralImportData, event, 'city');
    this.addressChange(referralImportData, event, 'provinceCode');
    this.addressChange(referralImportData, event, 'postalCode');
    this.addressChange(referralImportData, event, 'country');

  }

  updateContactDetailsFromReferral(referralImportData: ReferralImportDataHelper, event, field: string, subField?: string): void {
    this.selectedParticipantFromMatter(referralImportData).contact.isDirty = true;
    if (event.srcElement.checked) {
      if (field == 'contactName') {
        this.selectedParticipantFromMatter(referralImportData).contact.contactName[ subField ] = referralImportData.referredClient[ subField ];
      } else if (field == 'contact') {
        this.selectedParticipantFromMatter(referralImportData).contact[ subField ] = referralImportData.referredClient[ subField ];
      }
    } else {
      if (field == 'contactName') {
        this.selectedParticipantFromMatter(referralImportData).contact.contactName[ subField ] = referralImportData.participantCopy.contact.contactName[ subField ];
      } else if (field == 'contact') {
        this.selectedParticipantFromMatter(referralImportData).contact[ subField ] = referralImportData.participantCopy.contact[ subField ];
      }
    }
  }

  //It will show a separate line between main clients and other side clients.
  isClientCategoryDifferentAfter(clientIndex: number): boolean {
    if (clientIndex + 1 < this.referralImportData.length) {
      return this.isReferredContactMainClient(this.referralImportData[ clientIndex ]) !== this.isReferredContactMainClient(this.referralImportData[ clientIndex + 1 ]);
    }
    return false;
  }

  getOtherSideClientCategoryName(clientIndex: number): string {
    if (clientIndex + 1 < this.referralImportData.length && this.referralImportData[ clientIndex + 1 ].referredClient) {
      return this.getClientCategoryNameByRole(this.referralImportData[ clientIndex + 1 ].referredClient.role);
    }
    return '';
  }

  getClientCategoryNameByRole(role: string): string {
    if (role) {
      return SharedUtils.capitalizeWord(role + 's');
    } else {
      return '';
    }
  }
}
