import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MatterTitleInsurance} from '../../matters/shared/matter-title-insurance';
import {GetResultResponse} from './get-result-response';

export class StewartGetResultResponse extends BaseEntity {
  getResultResponse: GetResultResponse;
  matterTitleInsurance: MatterTitleInsurance;

  constructor(t?: StewartGetResultResponse) {
    super(t);
    if (t) {

      if (t.getResultResponse) {
        this.getResultResponse = new GetResultResponse(t.getResultResponse);
      }

      if (t.matterTitleInsurance) {
        this.matterTitleInsurance = new MatterTitleInsurance(t.matterTitleInsurance);
      }
    }
  }
}


