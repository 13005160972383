import {api} from '../../common/api';

/** Application endpoint urls
 */
export const docketApi = {

  teraviewConfigurations: `${ api }/accounts/{accountId}/teraviewConfigurations`

};

