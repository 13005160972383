<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Convert To Matter</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div>
            <div class="form-group" *ngIf="!context.onlyAllowImportToExisting">
                <div *ngIf="!isClosedDuplicate()" class="col-lg-3 offset-lg-2">
                    <span class="radio display-inline" (click)="changeConvertToMatter(true)">
                      <input type="radio"
                             name="newMatterConversion" id="newMatterConversion"
                             [checked]="isConvertToNewMatter"
                             [dp-read-only]="isLinkedMatters()"
                      >
                      <label for="newMatterConversion"> Create New Matter</label>
                    </span>
                </div>
                <div class="col-lg-4 offset-lg-2">
                    <span class="radio display-inline" (click)="changeConvertToMatter(false)">
                      <input type="radio"
                             name="newMatterConversion" id="existingMatterConversion"
                             [checked]="!isConvertToNewMatter"
                             [dp-read-only]="isLinkedMatters()"
                      >
                      <label for="existingMatterConversion"> Attach to Existing Matter</label>
                    </span>
                </div>
            </div>
            <ng-container *ngIf="isConvertToNewMatter">
                <div class="col-lg-12 text-center boldfont padding-top-20 padding-bottom-30">
                    <span *ngIf="!isLinkedMatters()">
                        Enter details for the new matter to be created. Note that some of the opportunity's data may be excluded if you create a matter of a type different than that of the opportunity.
                    </span>
                    <span *ngIf="isLinkedMatters()">
                        Opportunity indicates that our firm is representing both sides. Selecting Create Matters will create linked Purchase and Sale matters.
                    </span>
                </div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Matter Type *</label>
                    <div class="col-lg-2">
                        <select class="form-control" #matterTypeCtrl id="matterType" name="matterType"
                                [(ngModel)]="selectedMatterTypeCode"
                                fieldKey="convertToMatter.matterType"
                                [dp-read-only]="isLinkedMatters()"
                                dp-error-validator
                                (change)="updateMatterType()">
                            <option [disabled]="matterType.label.indexOf(comingSoonOption)>-1" *ngFor="let matterType of matterTypeOptions" [value]="matterType.value">{{matterType.label}}</option>
                        </select>
                    </div>
                    <div class="col-lg-1"></div>
                    <ng-container *ngIf="isProvinceVisible()">
                        <label class="control-label col-lg-2">Province *</label>
                        <div class="col-lg-2">
                            <select class="form-control" id="province" name="province" *ngIf="!isProvinceReadOnly()"
                                    [(ngModel)]="selectedProvinceCode"
                                    fieldKey="convertToMatter.provinceCode"
                                    dp-error-validator
                            >
                                <option *ngFor="let province of provinceOptions" [value]="province.value">{{province.label}}</option>
                            </select>
                            <input *ngIf="isProvinceReadOnly()"
                                   class="form-control"
                                   [dp-read-only]="true"
                                   [value]="selectedProvinceCode"
                            />
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isLinkedMatters()">
                        <label class="control-label col-lg-2">Linked Matter Type</label>
                        <div class="col-lg-2">
                            <input class="form-control"
                               [dp-read-only]="true"
                               [value]="linkedMatterPrefix" />
                        </div>
                    </ng-container>
                </div>
                <div class="form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" for="newMatterNo">{{matterTypePrefix}}Matter No. *</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="newMatterNo"
                               name="newMatterNo"
                               class="form-control"
                               maxlength="25"
                               [(ngModel)]="matterRecordNumber"
                               dp-uppercase-preserve-cursor-position
                               dp-matterNoFilter
                               [fieldKey]="'convertToMatter.matterNo'"
                               dp-error-validator
                               (focusout)="populateFileAccounting()"
                        />
                    </div>
                    <ng-container *ngIf="isLinkedMatters()">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label" for="newLinkedMatterNo">{{linkedMatterPrefix}}Matter No. *</label>
                        </div>
                        <div class="col-lg-3">
                            <input type="text"
                                   id="newLinkedMatterNo"
                                   name="newLinkedMatterNo"
                                   class="form-control"
                                   maxlength="25"
                                   [(ngModel)]="linkedMatterRecordNumber"
                                   dp-uppercase-preserve-cursor-position
                                   dp-matterNoFilter
                                   [fieldKey]="'convertToMatter.linkedMatterNo'"
                                   dp-error-validator
                                   (focusout)="populateFileAccounting(true)"
                            />
                        </div>
                    </ng-container>
                </div>
                <div class="form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" for="fileNo">{{matterTypePrefix}}File No.</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="fileNo"
                               name="fileNo"
                               [(ngModel)]="fileNumber"
                               class="form-control"
                               maxlength="50"
                               trimmed
                        />
                    </div>
                    <ng-container *ngIf="isLinkedMatters()">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label" for="linkedFileNumber">{{linkedMatterPrefix}}File No.</label>
                        </div>
                        <div class="col-lg-3">
                            <input type="text"
                                   id="linkedFileNumber"
                                   name="linkedFileNumber"
                                   [(ngModel)]="linkedFileNumber"
                                   class="form-control"
                                   maxlength="50"
                                   trimmed
                            />
                        </div>
                    </ng-container>
                </div>
                <div class="form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label" for="accountingNo">{{matterTypePrefix}}Accounting No.</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="accountingNo"
                               name="accountingNo"
                               [(ngModel)]="accountingNumber"
                               class="form-control"
                               maxlength="100"
                               trimmed
                        />
                    </div>
                    <ng-container *ngIf="isLinkedMatters()">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label" for="linkedAccountingNumber">{{matterTypePrefix}}Accounting No.</label>
                        </div>
                        <div class="col-lg-3">
                            <input type="text"
                                   id="linkedAccountingNumber"
                                   name="linkedAccountingNumber"
                                   [(ngModel)]="linkedAccountingNumber"
                                   class="form-control"
                                   maxlength="100"
                                   trimmed
                            />
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="!isConvertToNewMatter">
                <div class="col-lg-12 text-center boldfont padding-top-20 padding-bottom-30">
                    Excluded: Closed, Inactive and matters you have not been granted access to.
                </div>
                <div class="form-group col-lg-12">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">Matter</label>
                        </div>
                        <div class="col-lg-10" *ngIf="selectedExistingMatter.matterRecordNumber">
                            <input type="text" id="matter"
                                   class="form-control"
                                   [value]="(selectedExistingMatter.matterType ? selectedExistingMatter.matterType.charAt(0) : '')+'     '+(selectedExistingMatter.matterRecordNumber)+'      '+
                           (selectedExistingMatter.clientReLine ? selectedExistingMatter.clientReLine : '')+'     '+(selectedExistingMatter.provinceCode)+'      '+(getMatterPropertyAddress(selectedExistingMatter.matterProperties))+'      '+(selectedExistingMatter.matterCloseDate ? (selectedExistingMatter.matterCloseDate | detaileddate) : '')"
                                   dp-read-only="true"
                                   autocomplete="off"
                            />
                            <span class="trash-icon">
                                <i class="fa fa-trash"
                                   (click)="removeSelectedMatter()"
                                   aria-hidden="true"
                                   tabindex="0"
                                   (keyup.enter)="removeSelectedMatter()"
                                >
                                </i>
                            </span>
                        </div>
                        <div class="col-lg-10 padding-bottom-200" *ngIf="selectedExistingMatter && !selectedExistingMatter.matterRecordNumber">
                            <p-autoComplete
                                            [placeholder]="'Start typing to search for desired Matter No., Client RE: Line, or Property Address.'"
                                            id="matterSearch"
                                            name="matterSearch"
                                            [(ngModel)]="matterSerachText"
                                            [class.autocomplete-searching]="matterSearchLoading"
                                            [suggestions]="matterList"
                                            (completeMethod)="search($event)"
                                            field="matter"
                                            [minLength]="0"
                                            [delay]="1000"
                                            maxlength="75"
                                            scrollHeight="200px"
                                            (onSelect)="dataSelectedMatter(matterSerachText)"
                                            autocomplete="off" class="autocomplete-search-icon">
                                <ng-template let-matterData pTemplate="item">
                                    <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="matterData">
                                            <span class="col-lg-4 text-overflow"
                                                  *ngIf="autoCompleteEmptyFlag">{{ matterData.displayName }}</span>
                                        <div style="width:3%;display: inline-block">
                                <span *ngIf="!autoCompleteEmptyFlag && matterData.locked" class="matter-lock-icon">
                                                <i class="fa fa-lock"></i>
                                </span>
                                        </div>
                                        <span class="text-overflow" style="width:2%;display: inline-block"
                                              *ngIf="!autoCompleteEmptyFlag">{{ matterData.matterType ? matterData.matterType.charAt(0) : ''}}</span>
                                        <span class="text-overflow" style="width:20%;display: inline-block"
                                              *ngIf="!autoCompleteEmptyFlag">{{ matterData.matterRecordNumber}}</span>
                                        <span class="text-overflow" style="width:20%;display: inline-block"
                                              *ngIf="!autoCompleteEmptyFlag">{{matterData.clientReLine}}</span>
                                        <span class="text-overflow" style="width:5%;display: inline-block"
                                              *ngIf="!autoCompleteEmptyFlag">{{matterData.provinceCode}}</span>
                                        <span class="text-overflow" style="width:40%;display: inline-block"
                                              *ngIf="!autoCompleteEmptyFlag">{{getMatterPropertyAddress(matterData.matterProperties)}}</span>
                                        <span class="text-overflow" style="width:10%;display: inline-block"
                                              *ngIf="!autoCompleteEmptyFlag" [innerHTML]="matterData.matterCloseDate | detaileddate"></span>
                                    </li>
                                </ng-template>
                            </p-autoComplete>
                        </div>

                </div>
            </ng-container>

        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn dp-btn-ignore-disabled" (click)="convertMatter()">
                <span *ngIf="!isConvertToNewMatter">Attach to Matter</span>
                <span *ngIf="isConvertToNewMatter">Create Matter{{isLinkedMatters() ? 's':''}}</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
