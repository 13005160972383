import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';

export const PRECEDENT_PURCHASER_NAME = 'PURCHASE';
export const PRECEDENT_SALE_NAME = 'SALE';
export const PRECEDENT_MORTGAGE_NAME = 'MORTGAGE';

export class Precedent extends BaseEntity {

  id: number;
  precedentName: string;
  description: string;
  precedentType: string;
  customerAccountId: number;
  provinceCode: ProvinceCode;
  active: boolean;

  constructor(precedent?: Precedent) {
    super();
    if (precedent) {
      for (let prop in precedent) {
        if (precedent.hasOwnProperty(prop)) {
          this[ prop ] = precedent[ prop ];
        }
      }

    }

  }

}
