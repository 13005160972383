/**
 *  use this to keep in synch mortgages and Statement of Adjustment 'VTB Mortgage' type of adjustment where applicable
 */
import {Matter} from '../matters/shared/matter';
import {ProgressionStatus, StatementAdjustment} from '../matters/statement-adjustment/statement-adjustment';
import {SoAdjVTBMortgage} from '../matters/statement-adjustment/model/index';
import {
  RESERVE_FOR_VTB_MTG_TEXT,
  StatementAdjustmentAmountTypes,
  StatementAdjustmentKey
} from '../matters/statement-adjustment/statement-adjustment-constants';
import {DepositAdjustmentCalculationUtil} from '../matters/statement-adjustment/deposit-adjustment-calculation-util';
import {DpBooleanValueTypes} from '../matters/shared/dp-boolean';
import {SoajFieldCodeService} from './soaj-field-code.service';
import {Mortgage} from '../matters/shared/mortgage';
import {UUIDUtil} from '../main/uuid-util';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class MortgageSoAdjService {

  constructor(public soajFieldCodeService: SoajFieldCodeService) {

  }

  updateStatementOfAdjustment(matter: Matter, updateHeading?: boolean): void {
    if (!matter.isTemplateMatterForMassUpdate) {
      if (matter.isProjectSale) {
        this.updateStatementOfAdjustmentForProjectSale(matter);
      } else {
        this.updateStatementOfAdjustmentForNonProjectSale(matter, updateHeading);
      }
      this.updateInterimOccupancyFeeAdj(matter);
      this.updateDeferredPurchaseMoniesForDepositAdjustments(matter);
    }
  }

  updateStatementOfAdjustmentForProjectSale(matter: Matter): void {
    if (matter.allStatementAdjustments) {
      matter.mortgages.forEach(mrtg => {
        if (mrtg.isLoanTypeBackToVendor() || (mrtg.isLoanTypeArranged() && mrtg.autoCreateVtbMortgageAdjustment == DpBooleanValueTypes.YES)) {
          this.updateMortgageAdjustment(matter, mrtg);
        }
      });
    }

  }

  updateMortgageAdjustment(matter: Matter, mortgage: Mortgage): void {
    let vtbAdj = matter.allStatementAdjustments.find(soAdj => soAdj.isVTBMortgage() && soAdj.soAdjVTBMortgage && soAdj.soAdjVTBMortgage.mortgageId == mortgage.id);
    if (vtbAdj) {
      this.updateAdjustmentData(vtbAdj, matter, mortgage);
      if (vtbAdj.linkId) {
        let linkedAdj = matter.allStatementAdjustments.find(soAdj => soAdj.linkId == vtbAdj.linkId && soAdj.id != vtbAdj.id);
        if (linkedAdj) {
          this.updateAdjustmentData(linkedAdj, matter, mortgage);
        }
      }
    }
  }

  updateAdjustmentData(vtbAdj: StatementAdjustment, matter: Matter, mortgage: Mortgage): void {
    vtbAdj.amount = matter.getMortgagePrincipalByMortgageId(mortgage.id);
    vtbAdj.description = vtbAdj.soAdjVTBMortgage.getAdjustmentDescription(mortgage.mortgagePriority, matter, mortgage);
    vtbAdj.soAdjVTBMortgage.heading = vtbAdj.description;
  }

  rearrangeAdjustments(matter: Matter): void {
    if (matter.isProjectSale && !matter.isTemplateMatterForMassUpdate) {
      let backedupAdjVTBMortgages = [];
      matter.allStatementAdjustments.filter(soAdj => soAdj.soAdjVTBMortgage)
      .forEach((adj) => {
        backedupAdjVTBMortgages.push(new StatementAdjustment(adj.adjustmentStatus, matter.provinceCode, adj));
      });
      this.unassignAllVTBMortgagesAdjs(matter._interimAdjustments);
      this.unassignAllVTBMortgagesAdjs(matter._finalAdjustments);

      matter.mortgages.forEach(mrtg => {
        if (mrtg.isLoanTypeBackToVendor() || (mrtg.isLoanTypeArranged() && mrtg.autoCreateVtbMortgageAdjustment == DpBooleanValueTypes.YES)) {
          this.assignVTBMortgageAdj(matter, mrtg);
        }
      });

      this.restoreBackupData(backedupAdjVTBMortgages, matter);
    }
  }

  restoreBackupData(removeAdjVTBMortgages: StatementAdjustment[], matter: Matter): void {
    let vtbMortgagesAdjs = matter.allStatementAdjustments.filter(soAdj => soAdj.soAdjVTBMortgage);
    vtbMortgagesAdjs.forEach((newAdj) => {
      let oldAdjs = removeAdjVTBMortgages.find(oldAdj => oldAdj.soAdjVTBMortgage && newAdj.soAdjVTBMortgage
        && oldAdj.soAdjVTBMortgage.mortgageId == newAdj.soAdjVTBMortgage.mortgageId
        && oldAdj.adjustmentStatus == newAdj.adjustmentStatus);
      if (oldAdjs) {
        newAdj.soAdjVTBMortgage.firstInput = oldAdjs.soAdjVTBMortgage.firstInput;
        newAdj.soAdjVTBMortgage.secondInput = oldAdjs.soAdjVTBMortgage.secondInput;
        newAdj.infoOnly = oldAdjs.infoOnly;
      }
    });
  }

  assignVTBMortgageAdj(matter: Matter, mrtg: Mortgage): void {

    let reservedForVTBMortgageAdjs = matter.allStatementAdjustments.filter(adj => adj.isReserveForVTBMtg());
    let sortedReservedForVTBMortgageAdjs = _.sortBy(reservedForVTBMortgageAdjs, adj => Number(adj.fieldCode));
    if (sortedReservedForVTBMortgageAdjs && sortedReservedForVTBMortgageAdjs.length) {
      //If a reserved adj is found
      this.generateMortgageAdjData(sortedReservedForVTBMortgageAdjs[ 0 ], mrtg, matter, sortedReservedForVTBMortgageAdjs[ 0 ].adjustmentStatus);
      if (sortedReservedForVTBMortgageAdjs[ 0 ].linkId) {
        let linkedAdj = matter.allStatementAdjustments.find(adj => adj.linkId == sortedReservedForVTBMortgageAdjs[ 0 ].linkId && adj.id != sortedReservedForVTBMortgageAdjs[ 0 ].id);
        if (linkedAdj) {
          this.generateMortgageAdjData(linkedAdj, mrtg, matter, linkedAdj.adjustmentStatus);
        }

      }

    } else {
      let newSoAdj: StatementAdjustment = new StatementAdjustment(ProgressionStatus.FINAL, matter.provinceCode);
      this.generateMortgageAdjData(newSoAdj, mrtg, matter, ProgressionStatus.FINAL);
      newSoAdj.fieldCode = this.soajFieldCodeService.requestAdjustmentFieldCode();
      matter.createStatementAdjustmentOrder(newSoAdj);
      matter.statementOfAdjustments.push(newSoAdj);
      if (matter.interimStatementAdjustments && matter.interimStatementAdjustments.length) {
        this.copyInterimFinalAdjustment(newSoAdj, matter);
      }
    }

  }

  copyInterimFinalAdjustment(existingStatementAdjustment: StatementAdjustment, matter: Matter): void {
    if (matter.interimStatementAdjustments && matter.interimStatementAdjustments.length) {
      let statementAdjustment = new StatementAdjustment(ProgressionStatus.INTERIM, matter.provinceCode, existingStatementAdjustment);
      statementAdjustment.adjustmentStatus = ProgressionStatus.INTERIM;
      statementAdjustment.clearAllIds();
      statementAdjustment.id = UUIDUtil.getUUID();
      statementAdjustment.linkId = UUIDUtil.getUUID();
      existingStatementAdjustment.linkId = statementAdjustment.linkId;
      matter.adjustmentStatusMode = ProgressionStatus.INTERIM;
      matter.createStatementAdjustmentOrder(statementAdjustment);
      matter.statementOfAdjustments.push(statementAdjustment);
      matter.adjustmentStatusMode = ProgressionStatus.FINAL;

    }

  }

  generateMortgageAdjData(adj: StatementAdjustment, mrtg: Mortgage, matter: Matter, mode: string): void {
    adj.soAdjVTBMortgage = new SoAdjVTBMortgage();
    adj.soAdjVTBMortgage.mortgageId = mrtg.id;
    adj.itemKey = StatementAdjustmentKey.VTB_MORTGAGE;
    adj.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
    adj.soAdjVTBMortgage.heading = adj.soAdjVTBMortgage.getAdjustmentDescription(mrtg.mortgagePriority, matter, mrtg);
    adj.addedFlag = false;

    adj.amount = matter.getMortgagePrincipalByMortgageId(mrtg.id);
    adj.description = adj.soAdjVTBMortgage.getAdjustmentDescription(mrtg.mortgagePriority, matter, mrtg);
    adj.adjustmentStatus = mode;
  }

  unassignAllVTBMortgagesAdjs(statementAdjustments: StatementAdjustment[]): void {
    let removeAdjVTBMortgages = statementAdjustments.filter(adj => adj.isVTBMortgage());
    if (removeAdjVTBMortgages && removeAdjVTBMortgages.length) {
      removeAdjVTBMortgages.forEach(soAdjVTBM => {

        if (soAdjVTBM.sourceProjectAdjustmentId) {
          soAdjVTBM.itemKey = StatementAdjustmentKey.RESERVE_FOR_VTB_MTG;
          soAdjVTBM.soAdjVTBMortgage = null;
          soAdjVTBM.addedFlag = true;
          soAdjVTBM.amount = null;
          soAdjVTBM.description = RESERVE_FOR_VTB_MTG_TEXT;
        } else {
          let idx = statementAdjustments.findIndex(soAdj => soAdj.id == soAdjVTBM.id);
          statementAdjustments.splice(idx, 1);
          this.soajFieldCodeService.removeFromFieldCodeList(soAdjVTBM.fieldCode);
        }

      });
    }
  }

  updateStatementOfAdjustmentForNonProjectSale(matter: Matter, updateHeading?: boolean): void {

    if ((matter.isPurchase || matter.isSale) && matter.statementOfAdjustments) {

      let existingsoAdjVTBMortgages = matter.statementOfAdjustments.filter(soAdj => soAdj.isVTBMortgage() && soAdj.soAdjVTBMortgage);
      matter.mortgages.forEach(mrtg => {

        if ((matter.isPurchase && mrtg.isLoanTypeBackToVendor()) || matter.isSale) {

          let existingsoAdjVTBMortgageIndex: number = -1;
          if (existingsoAdjVTBMortgages && existingsoAdjVTBMortgages.length) {
            existingsoAdjVTBMortgageIndex = existingsoAdjVTBMortgages.findIndex(soAdj => soAdj.soAdjVTBMortgage.mortgageId == mrtg.id);
          }

          if (existingsoAdjVTBMortgageIndex < 0) {

            let newSoAdj: StatementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode);
            newSoAdj.soAdjVTBMortgage = new SoAdjVTBMortgage();
            newSoAdj.soAdjVTBMortgage.mortgageId = mrtg.id;
            newSoAdj.itemKey = StatementAdjustmentKey.VTB_MORTGAGE;
            newSoAdj.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
            newSoAdj.soAdjVTBMortgage.heading = newSoAdj.soAdjVTBMortgage.getAdjustmentDescription(mrtg.mortgagePriority);

            newSoAdj.amount = matter.getMortgagePrincipalByMortgageId(mrtg.id);
            newSoAdj.description = newSoAdj.soAdjVTBMortgage.getAdjustmentDescription(mrtg.mortgagePriority);
            newSoAdj.adjustmentStatus = matter.adjustmentStatusMode ? matter.adjustmentStatusMode : ProgressionStatus.FINAL;
            matter.statementOfAdjustments.push(newSoAdj);

          } else {
            // update the amount and description
            // TODO: need to fix this logic.. Don't Undersand why we are update heading in below manner
            let adjIndex = matter.statementOfAdjustments.findIndex(soAdj => soAdj.id == existingsoAdjVTBMortgages[ existingsoAdjVTBMortgageIndex ].id);
            if (adjIndex < matter.statementOfAdjustments.length) {
              matter.statementOfAdjustments[ adjIndex ].amount = matter.getMortgagePrincipalByMortgageId(mrtg.id);
              if (updateHeading) {
                matter.statementOfAdjustments[ adjIndex ].description =
                  matter.statementOfAdjustments[ adjIndex ].soAdjVTBMortgage.getAdjustmentDescription(mrtg.mortgagePriority);
                matter.statementOfAdjustments[ adjIndex ].soAdjVTBMortgage.heading = matter.statementOfAdjustments[ adjIndex ].description;
              }
            }

          }
        }
      });

      // remove orphaned adjustments if
      // 1. mortgage loan type was changed on Purchase matters to something other than BackToVendor OR
      // 2. mortgage got removed
      let removeAdjVTBMortgages = existingsoAdjVTBMortgages.filter(soAdj =>
        matter.mortgages.findIndex(mrtg => {
          if (matter.isSale) {
            return mrtg.id == soAdj.soAdjVTBMortgage.mortgageId;
          }
          if (matter.isPurchase) {
            return mrtg.id == soAdj.soAdjVTBMortgage.mortgageId && mrtg.isLoanTypeBackToVendor();
          }
        }) < 0
      );

      removeAdjVTBMortgages.forEach(soAdjVTBM => {
        let idx = matter.statementOfAdjustments.findIndex(soAdj => soAdj.id == soAdjVTBM.id);
        matter.statementOfAdjustments.splice(idx, 1);
      });
    }

  }

  updateInterimOccupancyFeeAdj(matter: Matter): void {
    if (matter.isProjectSale) {
      matter.recalculateAllInterimOccupancyFee(matter.project);
    }
  }

  // Update DeferredPurchaseMonies for Deposit Adjustments
  updateDeferredPurchaseMoniesForDepositAdjustments(matter: Matter): void {
    if (matter && (matter.isProjectSale || matter.templateForProject || (matter.project && matter.isPurchase && !!matter.matterLink))) {
      const util = new DepositAdjustmentCalculationUtil(matter);
      // Update Mortgage value on Monies
      matter.extraDepositConfig.vtbMortgageMonies = util.initVTBMortgageMonies(matter);
      // Recalculate DeferredPurchaseMonies
      util.recalculateDeferredPurchaseMonies();
    }
  }
}
