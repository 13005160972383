import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ContactFieldsDiff} from './contact-field-diff';
import {contactDropDowns} from '../../contact/contact-drop-downs';

export const writeToMaxLength: number = 6;

export const DepartmentPropertyLabel = {
  payableToLine1: 'Payable to',
  phone: 'Phone',
  fax: 'Fax',
  email: 'Email Address',
  fee: 'Fee',
  urlForWebForm: 'URL for web form',
  hst: 'HST',
  hstRegistrationNum: 'HST Registration No',
  WRITETO: 'Write to',
  comments: 'Comments'
};

export class Department extends BaseEntity {
  constructor(department?: Department) {
    super(department);
    if (department) {
      for (let prop in department) {
        if (department.hasOwnProperty(prop)) {
          this[ prop ] = department[ prop ];
        }
      }

      this.addressLines = [];
      if (Array.isArray(department.addressLines)) {
        department.addressLines.forEach(item => {
          this.addressLines.push(item);
        });
      }
    } else {
      this.addressLines = [];
    }

    for (let i: number = this.addressLines.length; i < writeToMaxLength; i++) {
      this.addressLines.push('');
    }

    if (!this.hst) {
      this.hst = 'NO';
    }
  }

  id: number;
  jurisdictionId: number;
  departmentName: string;
  departmentPriority: number;
  addressLines: string[];
  phone: string;
  fax: string;
  email: string;
  hst: string;
  hstRegistrationNum: string;
  fee: number;
  payableToLine1: string;
  payableToLine2: string;
  urlForWebForm: string;
  proxyForGlobal: boolean;
  proxyEdited: boolean;
  globalDepartmentId: number;
  comments: string;

  get isProxySameAsGlobal(): boolean {
    return this.proxyForGlobal && !this.proxyEdited;
  }

  get isProxyCopyOfGlobal(): boolean {
    return this.proxyForGlobal && this.proxyEdited;
  }

  static copyDepartmentData(globalDepartment: Department, targetDepartment: Department): void {
    targetDepartment.departmentName = globalDepartment.departmentName;
    targetDepartment.departmentPriority = globalDepartment.departmentPriority;
    targetDepartment.addressLines = globalDepartment.addressLines;
    targetDepartment.phone = globalDepartment.phone;
    targetDepartment.fax = globalDepartment.fax;
    targetDepartment.email = globalDepartment.email;
    targetDepartment.comments = globalDepartment.comments;
    targetDepartment.hst = globalDepartment.hst;
    targetDepartment.hstRegistrationNum = globalDepartment.hstRegistrationNum;
    targetDepartment.fee = globalDepartment.fee;
    targetDepartment.payableToLine1 = globalDepartment.payableToLine1;
    targetDepartment.payableToLine2 = globalDepartment.payableToLine2;
    targetDepartment.urlForWebForm = globalDepartment.urlForWebForm;
  }

  determineDepartmentDiff(checkFields: string[], updatedDepartment: Department, currentDepartment: Department): any {
    let diffs: ContactFieldsDiff[] = [];
    if (!Array.isArray(checkFields) || !updatedDepartment) {
      return diffs;
    }

    checkFields.forEach(property => {
      if (updatedDepartment.hasOwnProperty(property)) {
        if (!currentDepartment || !currentDepartment.hasOwnProperty(property)) {
          if (updatedDepartment[ property ]) {
            diffs.push(this.createDepartmentFieldsDiff(DepartmentPropertyLabel[ property ],
              this.changeValueToLabel(property, updatedDepartment),
              ''));
          }
        } else if (updatedDepartment[ property ] != currentDepartment[ property ]) {
          diffs.push(this.createDepartmentFieldsDiff(DepartmentPropertyLabel[ property ],
            this.changeValueToLabel(property, updatedDepartment),
            this.changeValueToLabel(property, currentDepartment)));
        }
      } else {
        if (property == 'WRITETO') {
          const nameDiffs: ContactFieldsDiff[]
            = this.determineAddressDiff(updatedDepartment && updatedDepartment.addressLines, currentDepartment && currentDepartment.addressLines);
          if (Array.isArray(nameDiffs) && nameDiffs.length) {
            diffs.push(...nameDiffs);
          }
        }

      }

    });
    return diffs;
  }

  determineAddressDiff(updatedAddressLines: string[], currentAddressLines: string[]): ContactFieldsDiff[] {
    let diffs: ContactFieldsDiff[] = [];
    if (!updatedAddressLines && !currentAddressLines) {
      return diffs;
    }

    if (!updatedAddressLines && currentAddressLines) {
      diffs.push(this.createDepartmentFieldsDiff('Write to', '', this.formatAddress(currentAddressLines)));
    }

    if (updatedAddressLines && !currentAddressLines) {
      diffs.push(this.createDepartmentFieldsDiff('Write to', this.formatAddress(updatedAddressLines), ''));
    }

    if (updatedAddressLines && currentAddressLines) {
      if (this.formatAddress(updatedAddressLines) !== this.formatAddress(currentAddressLines)) {
        diffs.push(this.createDepartmentFieldsDiff('Write to', this.formatAddress(updatedAddressLines), this.formatAddress(currentAddressLines)));
      }
    }

    return diffs;
  }

  formatAddress(addressLines: string[]): string {
    let address: string = '';
    address = addressLines.map(item => item && item.trim()).join(' ');
    return address;
  }

  createDepartmentFieldsDiff(fieldName: string, updatedValue: any, globalValue: any): ContactFieldsDiff {
    return ContactFieldsDiff.createContactFieldsDiff(fieldName, updatedValue, globalValue);
  }

  changeValueToLabel(property: string, department: Department): string {
    let label: string = department[ property ];
    switch (property) {
      case 'hst':
        if (department[ property ]) {
          label = this.getLabelByValue(contactDropDowns.hstOptions, department[ property ]);
        }
        break;
      default :
        break;
    }
    return label;
  }

  getLabelByValue(dropDownItems, value: string): string {
    let label: string = '';
    let labelItem;
    labelItem = dropDownItems.find(item => item.value === value);
    if (labelItem) {
      label = labelItem.label;
    }
    return label;
  }

  isDepartmentPrivateCopySameAsGlobal(other: Department): boolean {
    // console.log("Jurisdiction.isDepartmentPrivateCopySameAsGlobal(), this:", JSON.stringify(this, this.departmentPrivateCopySameAsGlobalReplacer), ", other:", JSON.stringify(other, this.departmentPrivateCopySameAsGlobalReplacer));
    return JSON.stringify(this, this.departmentPrivateCopySameAsGlobalReplacer) === JSON.stringify(other, this.departmentPrivateCopySameAsGlobalReplacer);
  }

  departmentPrivateCopySameAsGlobalReplacer(key, val): any {
    if (key !== 'proxyEdited') {
      return val;
    }
  }

  addressLineEqualSix(): boolean {
    return Array.isArray(this.addressLines) && (this.addressLines.length == 6);
  }
}
