<!--modal-->
<div class="custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1> Import Unity Templates</h1>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12 ">
                <label class="control-label">Warning. The following templates(s) will be replaced without the option to rollback.</label>
            </div>
        </div>

        <div class="form-group" style="padding-top: 20px">
            <div class="col-lg-3 height-100" style="overflow-y: scroll; border: solid 1px;">
                <table class="standard-table">
                    <tbody>
                    <tr *ngFor="let documentTemplateFile of context.existingTemplatesToCopy;let i=index;">
                        <td>{{documentTemplateFile.fileName}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12" style="padding-top: 20px;padding-bottom: 20px">
                <label class="control-label col-lg-12">Ensure you have a backup copy of these documents.</label>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <label class="control-label col-lg-12">Are you sure you want to proceed?</label>
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons" >
            <button type="button"
                    (click)="copyTemplates()"
                    class="dp-btn dp-btn-cancel">
                <span>Proceed</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>
