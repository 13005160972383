import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../../shared';
import {Project} from '../../../../projects/shared/project';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ItemizedCreditToVendorPurchaserONStrategy} from '../../sale-price-adjustment/itemized-credit-to-vendor-purchaser-on-strategy';
import {SelectItem} from 'primeng/api';
import {ItemizedCreditToVendorPurchaserItem} from '../../sale-price-adjustment/itemized-credit-to-vendor-purchaser-item';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {SalePriceAdjustmentFactory} from '../../sale-price-adjustment/sale-price-adjustment-factory';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import * as _ from 'lodash';
import {CurrencyPipe} from '@angular/common';
import {StatementAdjustmentUtil} from '../../statement-adjustment-util';
import {ItemizedCreditAdjustmentMessage} from '../../statement-adjustment-constants';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ItemizedCreditToVendorPurchaserABStrategy} from '../../sale-price-adjustment/itemized-credit-to-vendor-purchaser-ab-strategy';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class ItemizedCreditToVendorPurchaserModalContext {
  closingDate: string;
  itemizedCreditAdjustment: SalePriceAdjustment;
  salePriceAdjustment: SalePriceAdjustment;
  project: Project;
  matter: Matter;
  public provinceHstRateSlab: ConsiderationTaxes;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-itemized-credit-to-vendor-purchaser-content',
  templateUrl: 'itemized-credit-to-vendor-purchaser.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class ItemizedCreditToVendorPurchaserModalComponent
  extends ModalComponent<ItemizedCreditToVendorPurchaserModalContext>
  implements OnInit
{
  itemizedCreditAdjustmentBackup: SalePriceAdjustment;
  itemizedCreditAdjustmentLocal: ItemizedCreditToVendorPurchaserONStrategy | ItemizedCreditToVendorPurchaserABStrategy;
  creditsToOptions: SelectItem[];
  adjustmentTypeOptions: SelectItem[];
  divideTotalOptions: SelectItem[];
  automaticallyInsertTotalOptions: SelectItem[];
  currentTimeStamp = Date.now();
  newAdj: boolean = false;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<ItemizedCreditToVendorPurchaserModalComponent>,
    public currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ItemizedCreditToVendorPurchaserModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.newAdj = !this.context.itemizedCreditAdjustment;
    this.itemizedCreditAdjustmentLocal = this.context.itemizedCreditAdjustment
      ? SalePriceAdjustmentFactory.getItemizedCreditToVendorPurchaserAdjustment(
          this.context.matter.adjustmentStatusMode,
          this.context.matter.provinceCode,
          this.context.itemizedCreditAdjustment
        )
      : SalePriceAdjustmentFactory.getItemizedCreditToVendorPurchaserAdjustment(
          this.context.matter.adjustmentStatusMode,
          this.context.matter.provinceCode
        );
    this.itemizedCreditAdjustmentBackup = SalePriceAdjustmentFactory.getItemizedCreditToVendorPurchaserAdjustment(
      this.context.matter.adjustmentStatusMode,
      this.context.matter.provinceCode,
      this.itemizedCreditAdjustmentLocal
    );
    this.itemizedCreditAdjustmentLocal.netOutHstFromHSTSalePrice =
      this.context.salePriceAdjustment.netOutHstFromHSTSalePrice;
    this.creditsToOptions = dropDowns.creditsToOptions;
    this.divideTotalOptions = dropDowns.itemizedCreditAdjustmentDivideTotalOptions[this.context.matter.provinceCode];
    this.automaticallyInsertTotalOptions = dropDowns.yesNoOnly;
    if (!this.itemizedCreditAdjustmentLocal.creditsTo) {
      this.itemizedCreditAdjustmentLocal.creditsTo = 'VENDOR';
    }
    this.buildAdjustmentTypeDDOptions();

    if (!this.itemizedCreditAdjustmentLocal.adjustmentType) {
      this.itemizedCreditAdjustmentLocal.adjustmentType = 'CREDIT_TO_PARTY';
    }

    if (!this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal) {
      this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal = 'NO';
    }

    if (!this.itemizedCreditAdjustmentLocal.divideTotal) {
      this.itemizedCreditAdjustmentLocal.divideTotal = 'NO';
    }

    if (!this.itemizedCreditAdjustmentLocal.creditItems || this.itemizedCreditAdjustmentLocal.creditItems.length == 0) {
      this.addNewCredit();
    }
  }

  async ok() {
    this.modalErrorComponent.removeAllDpSaveError();
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      await this.showWarningMessageBeforeClosingModal();
      this.cleanAdjustmentBeforeClosing();
      this.dialog.close({action: 'OK', soAdjItemizedCreditToVendorPurchaser: this.itemizedCreditAdjustmentLocal});
    }
  }

  cleanAdjustmentBeforeClosing(): void {
    this.itemizedCreditAdjustmentLocal.creditItems = this.itemizedCreditAdjustmentLocal.creditItems.filter(
      (value) => value.description
    );
  }

  checkForErrors() {
    if (!this.itemizedCreditAdjustmentLocal.adjustmentHeading) {
      this.modalErrorComponent.createDPSaveError('matter.project.adjustment.itemized.credit.adjustmentHeading');
    }

    // If creditsTo or automaticallyInsertTotal field is changed then only validating if it can override the SalePriceAdjustment additional
    // consideration.
    if (
      this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal === 'YES' &&
      this.itemizedCreditAdjustmentLocal.creditsTo == 'VENDOR' &&
      (this.itemizedCreditAdjustmentLocal.creditsTo !== this.itemizedCreditAdjustmentBackup.creditsTo ||
        this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal !==
          this.itemizedCreditAdjustmentBackup.automaticallyInsertTotal)
    ) {
      // Additional Consideration Amount of a Sale Price Adjustment allowed to override only if it's value manually entered in Sale Price Adjustment
      if (
        this.context.matter.isAnyAdjustmentWithFirstAdditionalConsideration(true) ||
        this.context.matter.isAnyAdjustmentWithSecondAdditionalConsideration(true) ||
        this.context.matter.isAnyAdjustmentWithThirdAdditionalConsideration(true) ||
        this.isAnyExistingOtherItemizedAdjWithAdditionalConsideration()
      ) {
        this.modalErrorComponent.createDPSaveError(
          'matter.soadj.itemized.credit.amount.additional.consideration.to.vendor'
        );
      }
    }
  }

  isAnyExistingOtherItemizedAdjWithAdditionalConsideration(): boolean {
    return StatementAdjustmentUtil.isItemizedAdjAvailableWithConsiderationVedorPurchase(
      this.context.matter.allStatementAdjustments,
      this.itemizedCreditAdjustmentLocal.creditsTo
    );
  }

  async showWarningMessageBeforeClosingModal() {
    let needToShowWarningMsgModal: boolean = false;
    //Case 1: move from  onChangeAutomaticallyInsertTotal()
    if (
      this.itemizedCreditAdjustmentLocal.creditsTo == 'VENDOR' &&
      this.itemizedCreditAdjustmentBackup.creditsTo == 'VENDOR'
    ) {
      //this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal != 'YES') {
      if (
        this.itemizedCreditAdjustmentBackup.automaticallyInsertTotal == 'YES' &&
        this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal != 'YES'
      ) {
        //when field <Itemized credits to> == 'Vendor' and field <Automatically insert total into Additional ...> change from 'YES' to 'NO'
        needToShowWarningMsgModal = true;
      }
    }
    //Case 2: moved from onChangeCreditsTo()
    if (
      this.itemizedCreditAdjustmentBackup.automaticallyInsertTotal == 'YES' &&
      this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal == 'YES'
    ) {
      if (
        this.itemizedCreditAdjustmentBackup.creditsTo == 'VENDOR' &&
        this.itemizedCreditAdjustmentLocal.creditsTo != 'VENDOR'
      ) {
        needToShowWarningMsgModal = true;
      }
    }
    if (needToShowWarningMsgModal) {
      await this.showWarningMessageOnChangeVendorConsideration();
    }
  }

  async showWarningMessageOnChangeVendorConsideration() {
    let warningMsg = ItemizedCreditAdjustmentMessage.VENDOR_CONSIDERATION_REMOVE_MSG.replace(
      '<TOTAL_CREDITS>',
      this.itemizedCreditAdjustmentLocal.getFormattedTotal(this.currencyPipe)
    );
    await this.dialogService.confirm('Information', warningMsg, true).toPromise();
  }

  cancel(): void {
    this.dialog.close({action: 'CANCEL'});
  }

  async delete() {
    if (
      this.itemizedCreditAdjustmentLocal.creditsTo == 'VENDOR' &&
      this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal == 'YES'
    ) {
      await this.showWarningMessageOnChangeVendorConsideration();
    }
    this.dialog.close({action: 'DELETE'});
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  onChangeCreditsTo(): void {
    this.buildAdjustmentTypeDDOptions();
    //the warning message only supposed to show up when close the modal page
    //moved to  showWarningMessageBeforeClosingModal()
    // if(this.itemizedCreditAdjustmentLocal.creditsTo != 'VENDOR' && this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal == 'YES') {
    //     this.showWarningMessageOnChangeVendorConsideration();
    // }
  }

  onChangeAutomaticallyInsertTotal(): void {
    //the warning message only supposed to show up when close the modal page
    //moved to  showWarningMessageBeforeClosingModal()
    // if(this.itemizedCreditAdjustmentLocal.creditsTo == 'VENDOR' && this.itemizedCreditAdjustmentLocal.automaticallyInsertTotal != 'YES') {
    //     this.showWarningMessageOnChangeVendorConsideration();
    // }
  }

  buildAdjustmentTypeDDOptions(): void {
    this.adjustmentTypeOptions = dropDowns.itemizedCreditAdjustmentTypeOptions.map((selectedItem) =>
      this.formatAdjustmentTypeLabel(selectedItem)
    );
  }

  formatAdjustmentTypeLabel(selectedItem: SelectItem): SelectItem {
    let formattedLabel = selectedItem.label;
    if (this.itemizedCreditAdjustmentLocal.creditsTo) {
      formattedLabel = selectedItem.label.replace(
        '{PARTY}',
        _.capitalize(this.itemizedCreditAdjustmentLocal.creditsTo)
      );
    }

    return {label: formattedLabel, value: selectedItem.value} as SelectItem;
  }

  addNewCredit(): void {
    this.currentTimeStamp = Date.now();
    let credit = new ItemizedCreditToVendorPurchaserItem();
    this.itemizedCreditAdjustmentLocal.creditItems.push(credit);
  }

  removeCreditItem(selectedCredit: ItemizedCreditToVendorPurchaserItem): void {
    this.currentTimeStamp = Date.now();
    this.dialogService.confirm('Confirmation', 'Do you wish to delete this item?', false, 'Delete').subscribe((res) => {
      if (res) {
        (<any>this.itemizedCreditAdjustmentLocal.creditItems).remove(selectedCredit);
        if (this.itemizedCreditAdjustmentLocal.creditItems.length === 0) {
          this.addNewCredit();
        }
      }
    });
  }

  get federalHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstFederalPortion
      ? this.context.provinceHstRateSlab.hstFederalPortion
      : 0;
  }

  get provinceHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstProvincialPortion
      ? this.context.provinceHstRateSlab.hstProvincialPortion
      : 0;
  }

  getAutomaticallyInsertLabel(): string {
    if (this.itemizedCreditAdjustmentLocal.creditsTo == 'PURCHASER') {
      return 'Automatically insert total into credits to Purchaser field in sale price adjustment?';
    } else {
      return 'Automatically insert total into Additional Consideration in sale price adjustment?';
    }
  }

  getLessHstComponentLabel(): string {
    return this.itemizedCreditAdjustmentLocal.getLessHstComponentLabel(this.currencyPipe);
  }

  getLessHstComponentAmount(): string {
    return this.itemizedCreditAdjustmentLocal.getLessHstComponentAmount(
      this.currencyPipe,
      this.federalHstRate,
      this.provinceHstRate
    );
  }

  get creditNoteHeading(): string {
    return this.itemizedCreditAdjustmentLocal.getCreditNoteHeading();
  }
}
