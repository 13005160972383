import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {chicagoTitleCancellationOptions} from '../../../shared-main/chicago-title/chicago-title-constants';
import {ChicagoCancelRequest} from '../../../shared-main/chicago-title/chicago-cancel-request';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class ChicagoTitleCancellationContext {
  //empty context
}

@Component({
  selector: 'dp-chicago-title-cancellation-modal',
  templateUrl: 'chicago-title-cancellation.modal.component.html',
  providers: [ErrorService]
})
export class ChicagoTitleCancellationModalComponent
  extends ModalComponent<ChicagoTitleCancellationContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  cancellationReasonOptions: SelectItem[] = chicagoTitleCancellationOptions;
  chicagoCancelRequest: ChicagoCancelRequest;

  constructor(
    public dialog: MatDialogRef<ChicagoTitleCancellationModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: ChicagoTitleCancellationContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.chicagoCancelRequest = new ChicagoCancelRequest();
  }

  close(): void {
    this.dialog.close();
  }

  isAdditionalDetailsVisible(): boolean {
    return this.chicagoCancelRequest.cancellationReason == 'OTHER';
  }

  cancelOrder(): void {
    this.modalErrorComponent.removeDpFieldError('integrations.chicagoTitle.cancelReason.required');

    if (this.chicagoCancelRequest.cancellationReason == 'OTHER' && !this.chicagoCancelRequest.cancellationText) {
      this.modalErrorComponent.createDPFieldError('integrations.chicagoTitle.cancelReason.required');
    } else {
      this.dialog.close({chicagoCancelRequest: this.chicagoCancelRequest});
    }
  }
}
