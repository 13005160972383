<form class="form-horizontal container-fluid custom-modal-container border-radius">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{getModalHeader()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body min-height-220">
        <ng-container *ngIf="!isModalOpenForCancellation()">
            <div class="col-lg-12 info-container">
                <span class="padding-right-5">
                    <img src="/assets/img/info.png" alt="" class="padding-bottom-3">
                </span>
                Expected payout date must be later than the current date. If a statement is required sooner, please
                contact a BMO branch.
            </div>
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Purpose of the payout?</label>
                </div>
                <div class="col-lg-4">
                    <select id="purposeOfPayout" name="purposeOfPayout" [(ngModel)]="purposeOfPayout"
                        (change)="changePuposeOfPayoutOptions(purposeOfPayout)" class="form-control">
                        <option *ngFor="let purposeOfPayout of puposeOfPayoutOptions" [value]="purposeOfPayout.value">
                            {{purposeOfPayout.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-5" *ngIf="purposeOfPayout == 'OTHER'">
                    <input type="text" id="purposeOfPayoutOther" name="purposeOfPayoutOther"
                        [(ngModel)]="purposeOfPayoutOther" maxlength="100" class="form-control"
                        [fieldKey]="'integrations.telusAssyst.payoutOtherLC'"
                        dp-error-validator />
                </div>
            </div>

            <div class="col-lg-12" *ngIf="isLandTitleOfficeDropdownVisible() && !isModalOpenForCancellation()">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'assyst.landTitleOffice'">LRO
                        Location</label>
                </div>
                <div class="col-lg-6">
                    <select id="landRegistryOffice" name="landRegistryOffice" [(ngModel)]="landRegistryOffice"
                        class="form-control" [fieldKey]="'integrations.telusAssyst.landRegistryOffice'"
                        dp-error-validator>
                        <option *ngFor="let registryOffice of registryOffices" [value]="registryOffice.value">
                            {{registryOffice.label}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Acknowledgement of Consent*</label>
                </div>
                <div class="col-lg-2">
                    <select id="acknowledgementOfConsent" name="acknowledgementOfConsent"
                        [(ngModel)]="acknowledgementOfConsent" class="form-control">
                        <option *ngFor="let acknowledgementOfConsent of acknowledgementOfConsentOptions"
                            [value]="acknowledgementOfConsent.value">
                            {{acknowledgementOfConsent.label}}
                        </option>
                    </select>
                </div>
                <div>
                    <label class="commonExpense-disclaimer">
                        <img src="/assets/img/info.png" class="commonExpense-icon-text" alt="">
                    </label>
                    <span class="commonExpense-disclaimer-message"> Please provide your acknowledgement that you have
                        received consent
                        from the mortgagor to submit a Payout Request on their behalf.
                    </span>
                </div>

            </div>


            <div *ngIf="!appConfig.isDropdownOptionsEnableForAssystPayout">
                <div class="col-lg-12 info-container">
                    <span class="padding-right-5">
                        <img src="/assets/img/info.png" alt="" class="padding-bottom-3">
                    </span>
                    If the payout statement being requested is for a <span
                        class="bold vertical-align-top">refinance</span> where the pre-existing lender and new lender
                    are the same,
                    the payout request must be processed at the branch and cannot be completed through Unity® Lender
                    Centre.
                    All requests submitted through Unity® Lender Centre are assessed as a full discharge and will
                    include
                    all prepayment penalties if applicable for the loan being discharged.
                    To ensure refinance payout statements are calculated correctly,
                    please follow the existing process and contact your client's branch for any requests related to
                    refinancing.
                </div>
            </div>

            <div *ngIf="purposeOfPayout == 'CDC_RELOCATION' || purposeOfPayout == 'REFINANCE_WITH_BMO'">
                <div class="col-lg-12 info-container">
                    <span class="padding-right-5">
                        <input type="hidden"
                            [fieldKey]="'integrations.telusAssyst.acknowledgementCheckboxLC'"
                            dp-error-validator>
                        <dp-checkbox fieldId="acknowledgeAssystPayoutRequest">
                            <input id="acknowledgeAssystPayoutRequest" name="acknowledgeAssystPayoutRequest"
                                type="checkbox" [checked]="isAcknowledgeCheckboxChecked"
                                (change)="acknowledgeCheckboxChange($event)">
                        </dp-checkbox>*
                    </span>

                    <span class="padding-left-40 acknowledge-message">
                        {{acknowledgeMessage}}
                    </span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isModalOpenForCancellation()">
            <div>
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Reason for Payout Request cancellation?</label>
                    </div>
                    <div class="col-lg-6">
                        <input type="text" id="reasonForCancellation" name="reasonForCancellation" maxlength="100"
                            [(ngModel)]="reasonForCancellation" class="form-control"
                            [fieldKey]="'assyst.payout.request.reasonForCancellation'" dp-error-validator />
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-12 buttons">
                <button type="button" id="okBtn" class="dp-btn" *ngIf="!isModalOpenForCancellation()"
                    (click)="processSubmitPayoutRequest()">
                    <span>Submit Request</span>
                </button>
                <button type="button" id="okBtn" class="dp-btn" *ngIf="isModalOpenForCancellation()"
                    (click)="cancelPayoutRequest()">
                    <span>Submit Cancellation</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>