export class MortgageeEnclosureConfig {
  constructor(mec?: MortgageeEnclosureConfig) {

    if (mec) {
      for (let prop in mec) {
        if (mec.hasOwnProperty(prop)) {
          this[ prop ] = mec[ prop ];
        }
      }
      this.mortgageeEnclosureConfigItems = [];
      if (Array.isArray(mec.mortgageeEnclosureConfigItems)) {
        for (let i: number = 0; i < mec.mortgageeEnclosureConfigItems.length; i++) {
          this.mortgageeEnclosureConfigItems[ i ] = new MortgageeEnclosureConfigItem(mec.mortgageeEnclosureConfigItems[ i ]);
        }
      }
    }
  }

  id: number;
  customerAccountId: number;
  mortgageeEnclosureConfigItems: MortgageeEnclosureConfigItem[];

}

export class MortgageeEnclosureConfigItem {
  constructor(meci?: MortgageeEnclosureConfigItem) {

    if (meci) {
      for (let prop in meci) {
        if (meci.hasOwnProperty(prop)) {
          this[ prop ] = meci[ prop ];
        }
      }
    }
  }

  id: number;
  enclosureName: string;
  interimEnclosureType: string;
  finalEnclosureType: string;

}
