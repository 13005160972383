import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {Contact, Jurisdiction, JurisdictionService, SameAsAddressOption, User} from '../../matters';
import {Observable, Subject} from 'rxjs';
import {contactTypeMapping} from '../../contact/contact-type-mapping';
import * as _ from 'lodash';
import {Logger} from '@nsalaun/ng-logger';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ContactCommandService} from '../../contact/contact-command.service';
import {ApplicationError, TabsService} from '../../core';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {SelectItem} from 'primeng/api';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorDirectiveMapping} from '../../shared/error-handling/error-directive-mapping';
import {CaslInfo} from '../../matters/shared/casl-info';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {commonLabels} from '../../common/common-labels';
import {dropDowns} from '../../admin/accounts/shared/account-drop-downs';
import {UserProvince} from '../../matters/shared/user-province';
import {Account} from '../../admin/accounts/shared/account';
import {RegisterService} from '../../login/first-login/register/register.service';
import {AuthZService} from '../../core/authz/auth-z.service';
import {Environment} from '../../../environments/environment';
import {FeatureService} from '../../feature-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {Roles} from "../../core/authz/roles";

class PersonalDetailsContext {
  userStaffProfile: StaffProfiles;
  staffProfileAddressDd: SameAsAddressOption[];
  tabsService: TabsService;
  account: Account;
}

@Component({
  selector: 'dp-personal-details-modal',
  templateUrl: 'personal-details.modal.component.html',
  styleUrls: ['./personal-details.modal.styles.scss'],
  providers: [ErrorService]
})
export class PersonalDetailsModalComponent extends ModalComponent<PersonalDetailsContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  keycloakEditProfileLink = `${this.environment.config.keycloak.domain}/auth/realms/${this.environment.config.keycloak.realm}/account/#/personal-info`;
  staffProfileAddressCopyOptions: SameAsAddressOption[] = [];
  selectedJurisdiction: Jurisdiction;
  searchTermJurisdiction = new Subject<string>();
  jurisdictionAutoCompleteEmptyFlag: boolean = false;
  jurisdictions: any;
  jurisdictionsLoading: boolean = false;
  contactTypeLawyer: any;
  contactTypeLawClerk: any;
  contactTypeOther: any;
  selectedContactType: any;
  staffProfile: StaffProfiles;
  genders: SelectItem[];
  isCaslRequestedByDisabled: boolean = true;
  isCaslInfoDirty: boolean = false;
  staffProfilesOptions = [];
  myContactId: number = 0;
  commonLabels = commonLabels;
  defaultProvinceOptions: SelectItem[];
  notaryOrCommissionerSelect: SelectItem[];
  insertNameIntoRegDocumentsSelect: SelectItem[];
  messageDelegateUsers: SelectItem[] = [];
  sharingMessageOptions: SelectItem[] = dropDowns.SharingMessageOptions;

  constructor(
    public dialog: MatDialogRef<PersonalDetailsModalComponent>,
    public jurisdictionService: JurisdictionService,
    public contactCommandService: ContactCommandService,
    public staffProfilesService: StaffProfilesService,
    public logger: Logger,
    public registerService: RegisterService,
    public errorService: ErrorService,
    public featureService: FeatureService,
    public authZService: AuthZService,
    public ngZone: NgZone,
    public environment: Environment,
    @Inject(MAT_DIALOG_DATA) context?: PersonalDetailsContext
  ) {
    super(dialog, context);
  }

  get contact(): Contact {
    return this.staffProfile.contact;
  }

  get user(): User {
    return this.staffProfile.user;
  }

  get account(): Account {
    return this.context.account;
  }

  ngOnInit() {
    this.myContactId = +sessionStorage.getItem(SESSION_STORAGE_KEYS.userContactId);
    this.staffProfile = new StaffProfiles(this.context.userStaffProfile);
    this.selectedJurisdiction = this.contact.jurisdiction;
    this.contactTypeLawClerk = _.find(
      contactTypeMapping.CONTACTTYPES,
      (contactTypeObj) => contactTypeObj.contactKey === 'LAWCLERK'
    );
    this.contactTypeLawyer = _.find(
      contactTypeMapping.CONTACTTYPES,
      (contactTypeObj) => contactTypeObj.contactKey === 'LAWYER'
    );
    this.contactTypeOther = _.find(
      contactTypeMapping.CONTACTTYPES,
      (contactTypeObj) => contactTypeObj.contactKey === 'OTHER'
    );
    this.genders = this.contactTypeLawyer.genders;
    this.staffProfileAddressCopyOptions = this.context.staffProfileAddressDd;
    this.searchTermJurisdiction
      .switchMap((term: string) => {
        this.jurisdictionsLoading = true;
        this.jurisdictionAutoCompleteEmptyFlag = false;
        if (term.trim() === '') {
          this.jurisdictionAutoCompleteEmptyFlag = true;
          return Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
        } else {
          return this.jurisdictionService.getJurisdictions(term);
        }
      })
      .subscribe(
        (jurisdictions: any[]) => {
          this.jurisdictionsLoading = false;
          this.jurisdictions = jurisdictions;
        },
        (error) => {
          this.jurisdictionsLoading = false;
          this.logger.error('', error);
        }
      );

    if (this.contact.contactType === 'LAWCLERK') {
      this.selectedContactType = this.contactTypeLawClerk;
      // this.contact.privateFlag = true;
    }
    if (this.contact.contactType === 'SOLICITOR') {
      this.selectedContactType = this.contactTypeLawyer;
      // this.setSolicitorPrivateFlag();
    }
    if (this.contact.contactType === 'OTHER') {
      this.selectedContactType = this.contactTypeOther;
      // this.contact.privateFlag = true;
    }

    this.staffProfilesService.getStaffProfiles(String(this.staffProfile.customerAccountId)).subscribe((res) => {
      this.staffProfilesOptions = res
        .filter((staffProfiles) => staffProfiles.contactStatus == 'Y_n' || staffProfiles.contactStatus == 'YES')
        .sort((left: StaffProfiles, right: StaffProfiles) => {
          //We put the current one at the top of the list
          if (left.userName == this.contact.contactName.fullName) {
            return -1;
          } else {
            return left.surnameLastFullName.toUpperCase() < right.surnameLastFullName.toUpperCase() ? -1 : 1;
          }
        });
    });
    this.defaultProvinceOptions = this.getDefaultProvinceOptions();
    this.notaryOrCommissionerSelect = dropDowns.notaryOrCommissionerOptions;
    this.insertNameIntoRegDocumentsSelect = dropDowns.yesNoOptions;
    this.contact.createContactProvinceCapacities(this.account, this.contact);
    this.getStaffProfiles();
  }

  // if(this.account.accountTypeCode === 'LAW_FIRM' || this.account.accountTypeCode === 'NOTARY' )
  /**
   * We use the follow back end logic for UI
   * if((solicitor.getContactType() == ContactType.SOLICITOR && !solicitor.isPrivateFlag()
   * && (customerAccount.getAccountTypeCode() != AccountType.LAW_FIRM && customerAccount.getAccountTypeCode() != AccountType.NOTARY
   * && !customerAccount.getPublicAccountId().equals(systemAccountId)
   * )) ||
   * (solicitor.getContactType() == ContactType.LAWCLERK && !solicitor.isPrivateFlag())) {
   *                errors.reject(Codes.CONTACT_CANNOT_BE_PUBLIC.getErrorCode(), Codes.CONTACT_CANNOT_BE_PUBLIC.getErrorMessage());
   *            }
   */
  // setSolicitorPrivateFlag() {
  //     if(this.contact.contactType === "SOLICITOR") {
  //         this.contact.privateFlag = true;
  //         if ( this.account.accountTypeCode === 'LAW_FIRM' ||  this.account.accountTypeCode === 'NOTARY' || this.hasSystemRole()){
  //                 this.contact.privateFlag = false;
  //         }
  //     }
  // }

  hasSystemRole(): boolean {
    return (
      this.authZService.hasRole(Roles.ROLE_SYSTEM_ADMINISTRATOR) ||
      this.authZService.hasRole(Roles.ROLE_SYSTEM_USER)
    );
  }

  async getStaffProfiles() {
    let allStaffProfiles: StaffProfiles[] = await this.staffProfilesService
      .getStaffProfiles(this.account.id.toString())
      .toPromise();
    for (let i = 0; i < allStaffProfiles.length; i++) {
      if (
        allStaffProfiles[i].userId &&
        allStaffProfiles[i].userId != this.user.loginId &&
        allStaffProfiles[i].userStatus != 'SUSPENDED'
      ) {
        this.messageDelegateUsers.push({
          label: allStaffProfiles[i].userName,
          value: allStaffProfiles[i].dppmUserId
        });
      }
    }
  }

  updateMultiSelectFilters(obj: number[]): void {
    this.user.messagingDelegateUserIds = obj;
  }

  userAccessibleProvincesOptions(): SelectItem[] {
    let userProvincesOptions: SelectItem[] = [];
    if (this.account.accountProvinces && this.account.accountProvinces.length > 0) {
      this.account.accountProvinces.forEach((accountProvince) => {
        let userProvince: any = dropDowns.accessibleProvinces.find(
          (existingProvince) => existingProvince.value == accountProvince.provinceCode && accountProvince.enabled
        );
        if (userProvince != null) {
          userProvincesOptions.push(userProvince);
        }
      });
    }
    return userProvincesOptions;
  }

  userAccessibleProvinceCodes(): string {
    return this.staffProfile.user.getEnabledUserProvinceCodes().join(',');
  }

  getDefaultProvinceOptions(): SelectItem[] {
    //Adding blank options
    let defaultProvinceOptions: SelectItem[] = [{label: ' ', value: 'QUESTION'}];
    let userAccessibleProvinces: UserProvince[] = this.staffProfile.user.userEnabledProvinces;
    if (userAccessibleProvinces) {
      //Adding ProvinceOptions based on selected accessibleProvinces
      defaultProvinceOptions.push(
        ...userAccessibleProvinces.map((selectedProvince) =>
          this.userAccessibleProvincesOptions().find(
            (accessibleProvinceOption) => accessibleProvinceOption.value === selectedProvince.provinceCode
          )
        )
      );
    }
    return defaultProvinceOptions;
  }

  formatInitials(): void {
    if (this.contact.initials) {
      if (this.contact.contactType === this.contactTypeLawyer.contactType) {
        this.contact.initials = this.contact.initials.toUpperCase();
      } else {
        this.contact.initials = this.contact.initials.toLowerCase();
      }
    }
  }

  // Code for omnibar search jurisdiction
  searchJurisdiction(event): void {
    let entered: string = event.query;
    this.searchTermJurisdiction.next(entered);
  }

  checkIfJurisdictionEmpty(selectedJurisdiction: string): void {
    if (!selectedJurisdiction || selectedJurisdiction.length == 0) {
      this.contact.jurisdiction = null;
    }
  }

  // On jurisdiction input data select event
  jurisdictionOnSelect(): void {
    if (this.selectedJurisdiction.id === undefined) {
      this.selectedJurisdiction = undefined;
    } else {
      let jurisdiction = new Jurisdiction();
      jurisdiction.id = this.selectedJurisdiction.id;
      jurisdiction.jurisdictionName = this.selectedJurisdiction.jurisdictionName;
      this.contact.jurisdiction = jurisdiction;

      if (this.selectedJurisdiction.city) {
        this.contact.city = this.selectedJurisdiction.city.name;
        if (this.selectedJurisdiction.city.municipality) {
          this.contact.municipality = this.selectedJurisdiction.city.municipality.name;
        }
      }
    }
  }

  ok(): void {
    if (this.staffProfile.isValid(this.errorService, true)) {
      if (
        this.contact.contactType &&
        this.contact.contactProvinceCapacities &&
        this.contact.contactType === 'LAWCLERK'
      ) {
        this.contact.contactProvinceCapacities.forEach((e, i) => {
          if (e.provinceCapacity == 'COMMISSIONER' && (!e.commissionExpiry || e.commissionExpiry == '//')) {
            this.errorService.addDpSaveError(
              DPError.createDPError('personaldetails.commissionerDate.MISSING', 'commissionerDate_' + i)
            );
          }
        });
      }

      if (this.contact.contactName && this.contact.contactName.lastName && this.contact.contactType === 'OTHER') {
        this.saveStaffProfile();
      } else if (this.contact.contactName && this.contact.contactName.lastName && this.contact.contactName.initials) {
        this.contactCommandService
          .getContactByNameAndInitials(this.contact.contactName, this.selectedContactType)
          .subscribe(
            (contactList) => {
              if (contactList && contactList.length > 0) {
                if (contactList.length == 1 && contactList[0].id == this.staffProfile.contact.id) {
                  this.saveStaffProfile();
                } else {
                  this.errorService.addDpSaveError(
                    DPError.createCustomDPError(
                      'Server Error',
                      'Another contact with the same name and initials exists',
                      'ERROR',
                      'ERROR'
                    )
                  );
                }
              } else {
                this.saveStaffProfile();
              }
            },
            (error: ApplicationError) => {
              console.log(error);
            }
          );
      }
    }
  }

  public saveStaffProfile(): void {
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.errorService.removeDpSaveError('admin.staffProfile.initials');
      this.staffProfilesService.saveMyProfile(this.staffProfile).subscribe(
        (staffProfiles: StaffProfiles) => {
          this.staffProfile = staffProfiles;
          this.registerService.setRegisterUserInSessionStorage(staffProfiles.user);
          this.context.userStaffProfile.caslInfo = staffProfiles.caslInfo;
          this.dialog.close(staffProfiles);
        },
        (e: ApplicationError) => {
          if (e && e.fieldErrors && e.fieldErrors.length > 0) {
            e.fieldErrors.forEach((e) => {
              if (e.errorCode == 'app.duplicateStaffProfileInitials') {
                let errorKey = 'admin.staffProfile.initials';
                let errorDirectiveMapping = ErrorDirectiveMapping[errorKey];
                this.errorService.addDpSaveError(
                  DPError.createCustomDPError(
                    errorKey,
                    'Initials already exist.',
                    errorDirectiveMapping.ERROR_TOPIC,
                    errorDirectiveMapping.ERROR_TYPE
                  )
                );
              } else if (e.errorCode == 'app.duplicateStaffProfileEmail') {
                let errorKey = 'admin.staffProfile.email.MISSING';
                let errorDirectiveMapping = ErrorDirectiveMapping[errorKey];
                this.errorService.addDpSaveError(
                  DPError.createCustomDPError(
                    errorKey,
                    'Email already exists.',
                    errorDirectiveMapping.ERROR_TOPIC,
                    errorDirectiveMapping.ERROR_TYPE
                  )
                );
              }
            });
          }
        }
      );
    }
  }

  close(): void {
    this.dialog.close();
  }

  public isInitialMandatory(): boolean {
    return (
      this.contact.contactType && (this.contact.contactType === 'LAWCLERK' || this.contact.contactType === 'SOLICITOR')
    );
  }

  get caslInfo() {
    // return this.staffProfiles.caslInfo;
    if (!this.staffProfile.caslInfo) {
      this.staffProfile.caslInfo = new CaslInfo();
    }
    return this.staffProfile.caslInfo;
  }

  handleCaslInfoChange(): void {
    this.isCaslInfoDirty = true;
    this.caslInfo.caslRequestedById = this.myContactId; // staffProfileId is actually contactId
    this.isCaslRequestedByDisabled = this.myContactId && this.myContactId > 0;
  }

  get isCaslInfoAvailable(): boolean {
    return this.caslInfo.caslEmailOptOut !== undefined && this.caslInfo.caslEmailOptOut !== null;
  }

  onDateChangeCommissioner(event, index): void {
    const tmpDate = event.rawDate;
    if (event !== this.contact.contactProvinceCapacities[index].commissionExpiry) {
      this.contact.contactProvinceCapacities[index].commissionExpiry = tmpDate;
    }
  }

  isSsoEnabled() {
    return this.featureService.isSSOEnabled;
  }
}
