import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';
import {SoAdjTenancyCurrent} from '../model/so-adj-tenancy-current';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';

export class StatementAdjustmentDisplayTenancyCurrentBuilder {
  static getDisplayItems(soAdjTenancyCurrent: SoAdjTenancyCurrent, closingDate: string, mainBuilder: StatementAdjustmentDisplayBuilder, taxRateType): StatementOfAdjustmentDisplayItemLine[] {
    soAdjTenancyCurrent.provinceCode = mainBuilder.matter.provinceCode;
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let isPaysForDateOfClosingVendor: boolean = mainBuilder.paysForDateOfClosing === 'VENDOR';
    let share: number = soAdjTenancyCurrent.getVendorsShare(closingDate, isPaysForDateOfClosingVendor);

    sadItemDetails.push(mainBuilder.getNewItemLine('Rental amount', mainBuilder.getCurrency(soAdjTenancyCurrent.rentalAmount)));

    if (soAdjTenancyCurrent.applyTaxBoolean) {

      if (soAdjTenancyCurrent.taxType == taxRateType) {
        sadItemDetails.push(mainBuilder.getNewItemLine(
          `${ taxRateType } calculated at ${ mainBuilder.getPercentage(soAdjTenancyCurrent.hstRate) }`,
          mainBuilder.getCurrency(soAdjTenancyCurrent.hstOrGstValueFromRentalAmount)
        ));
      }

      if (mainBuilder.matter && !mainBuilder.matter.isMatterProvinceAB) {
        if (soAdjTenancyCurrent.taxType == 'GST') {
          sadItemDetails.push(mainBuilder.getNewItemLine(
            `GST calculated at ${ mainBuilder.getPercentage(soAdjTenancyCurrent.gstRate) }`,
            mainBuilder.getCurrency(soAdjTenancyCurrent.hstOrGstValueFromRentalAmount)
          ));
        }
      }

      if (soAdjTenancyCurrent.applyPstBoolean) {
        sadItemDetails.push(mainBuilder.getNewItemLine(
          `PST calculated at ${ mainBuilder.getPercentage(soAdjTenancyCurrent.pstRate) }`,
          mainBuilder.getCurrency(soAdjTenancyCurrent.pstValueFromRentalAmount)
        ));
      }
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(
      soAdjTenancyCurrent.vendorCollectedRentBoolean ?
        `Tenant has paid rent for period` :
        `${ mainBuilder.purchaserLabel } to collect rent for period`,
      ''
    ));

    sadItemDetails.push(mainBuilder.getNewItemLine(
      `commencing ${ mainBuilder.formattedDate(soAdjTenancyCurrent.commencementDate) }`,
      ''
    ));

    ;
    sadItemDetails.push(mainBuilder.getNewItemLine(
      `${ mainBuilder.vendorLabel }'s share for ${ soAdjTenancyCurrent.getNumberOfDaysElapsedStr(closingDate, isPaysForDateOfClosingVendor) }`,
      share >= 0 ? mainBuilder.getCurrency(share) : mainBuilder.cannotCalculateAmount, undefined, 'Tenancy.Share'
    ));

    sadItemDetails.push(mainBuilder.getNewItemLine(
      `Credit ${ soAdjTenancyCurrent.creditType == StatementAdjustmentAmountTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel }`,
      ''
    ));

    return sadItemDetails;
  }

}
