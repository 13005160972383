<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" *ngIf="interestSoa">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>{{getModalHeader()}}</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at
                            {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Interest Calculation for period</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text" id="interestCalcType" name="interestCalcType"
                            (change)="onInterestCalculationChange()" [(ngModel)]="interestSoa.interestCalculationPeriod"
                            [dp-read-only]="isInterestOnDeferredMoniesAdjustment()" class="form-control">
                            <option *ngFor="let interestCalculationType of interestCalculationTypes"
                                [value]="interestCalculationType.value">
                                {{interestCalculationType.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Is interest credit to {{purchaserLabel}} or
                            {{vendorLabel}}?</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text" id="purchaserVendorType" name="purchaserVendorType"
                            [(ngModel)]="interestSoa.isInterestCreditTo"
                            [dp-read-only]="isInterestOnDeferredMoniesAdjustment()" class="form-control">
                            <option *ngFor="let purchaserOrVendorType of purchaserOrVendorTypes"
                                [value]="purchaserOrVendorType.value">
                                {{purchaserOrVendorType.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Items on which Interest needs to be calculated</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" id="items" maxlength="26" [(ngModel)]="interestSoa.itemName" dp-uppercase
                            name="items" class="form-control" />
                    </div>

                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">{{isInterestOnDeferredMoniesAdjustment() ? 'Principal amount' :
                            'Enter
                            Principal Amount'}}</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" id="principalAmount" name="principalAmounts"
                            [(ngModel)]="interestSoa.principalAmount"
                            [dp-read-only]="isInterestOnDeferredMoniesAdjustment()" class="form-control" dp-currency />


                    </div>

                </div>
            </div>
            <div class="form-group"
                *ngIf="isInterestOnDeferredMoniesAdjustment() && !isProjectOrProjectSaleProvinceAB()">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Same interest rate as interim?</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text" id="sameInterestAsInterim" name="sameInterestAsInterim"
                            (change)="onSameInterestRateAsInterimChange()"
                            [(ngModel)]="interestSoa.sameInterestRateAsInterim" class="form-control">
                            <option *ngFor="let yesNoOption of yesNoOptions" [value]="yesNoOption.value">
                                {{yesNoOption.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">{{isInterestOnDeferredMoniesAdjustment() ? 'Interest Rate' : 'Enter
                            interest
                            Rate (%)'}}</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" id="interestRate" name="interestRate" [(ngModel)]="interestSoa.interestRate"
                            [dp-read-only]="isInterestOnDeferredMoniesAdjustment() && isSameInterestRateAsInterim()"
                            class="form-control" dp-percentage />
                    </div>

                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Interest to be calculated for period from</label>
                    </div>
                    <div class="col-lg-2">
                        <dp-partial-date #fromDate
                            [disableAll]="interestSoa.interestCalculationPeriod == 'COMMENCING_ON_ADJUSTMENT_DATE' || interestSoa.interestCalculationPeriod =='FORM_INTERIM_CLOSING_TO_ADJUSTMENT_DATE' || interestSoa.interestCalculationPeriod =='COMMENCING_ON_INTERIM_CLOSING_DATE'"
                            (dateChange)="onDateChange($event ,'interestCalculatedFrom')"
                            [fieldKey]="'matter.statementAdjustment.interestFromDate'" dp-error-validator
                            [inputDate]="interestSoa.interestCalculatedFrom">
                        </dp-partial-date>
                    </div>
                    <div class="col-lg-1 vertical-align-label">
                        <label class="control-label text-center">to</label>
                    </div>
                    <div class="col-lg-2">
                        <dp-partial-date #toDate
                            [disableAll]="interestSoa.interestCalculationPeriod == 'ENDING_ON_ADJUSTMENT_DATE' || interestSoa.interestCalculationPeriod == 'ENDING_ON_INTERIM_CLOSING_DATE' || interestSoa.interestCalculationPeriod =='FORM_INTERIM_CLOSING_TO_ADJUSTMENT_DATE'"
                            (dateChange)="onDateChange($event , 'interestCalculatedTo')"
                            [fieldKey]="'matter.statementAdjustment.interestToDate'" dp-error-validator
                            [inputDate]="interestSoa.interestCalculatedTo">
                        </dp-partial-date>
                    </div>
                </div>
            </div>
            <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
                <div class="col-lg-12">
                    <div class="col-lg-4">
                        <label class="control-label">
                            INTEREST ON {{interestSoa.itemName}}
                        </label>
                    </div>
                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">
                        Principal amount
                    </label>
                    <label class="control-label col-lg-2 text-right">
                        {{geFormattedCurrencyValue(interestSoa.principalAmount)}}
                    </label>
                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">
                        Interest at {{interestSoa.interestRate | number:'1.2-3'}}% calculated
                    </label>

                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">
                        from {{formattedDate(interestSoa.interestCalculatedFrom)}} to
                    </label>

                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">
                        {{formattedDate(interestSoa.interestCalculatedTo)}} ({{calculateDateDiff()}} days)
                    </label>
                    <label class="control-label col-lg-2 text-right">
                        {{interest}}
                    </label>
                </div>
                <ng-container *ngIf="isCreditToPurchaser">
                    <div class="col-lg-12  form-group">
                        <div class="col-lg-4">
                            <label class="control-label"><span>Credit {{purchaserLabel}}</span></label>
                        </div>
                        <div class="col-lg-3 text-right">
                            <span>{{interest}}</span>
                        </div>
                        <div class="col-lg-3"></div>
                    </div>
                </ng-container>
                <ng-container *ngIf="isCreditToVendor">
                    <div class="col-lg-12  form-group">
                        <div class="col-lg-4">
                            <label class="control-label"><span> Credit {{vendorLabel}}</span></label>
                        </div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3 text-center">
                            <span>{{interest}}</span>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" id="okBtn" (click)="ok()" class="dp-btn keyboard-save-action">
                    <span>OK</span>
                </button>
                <button type="button" (click)="close()" class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </form>
</div>