export const commonLabels = {
  caslOptIn: 'Please click this button if you would like to continue receive marketing emails such as promotional material or industry related updates from Unity® Practice Management.  I understand I can unsubscribe at any time by editing my profile.',
  caslOptOut: 'Click this button if you would like to unsubscribe from receiving marketing emails such as promotional material or industry related updates from Unity® Practice Management.  Please note you will continue to receive product-related communications as permitted by law.'
};

export const undertakingTypes = {
  mortgage: {text: 'Mortgage', key: 'MORTGAGE'},
  vendorsLienCaveat: {text: 'Vendor\'s Lien Caveat', key: 'VENDORSLIENCAVEAT'},
  writ: {text: 'Writ', key: 'WRIT'},
  other: {text: 'Other', key: 'OTHER'}
};
