<ng-container *ngIf ="!showWizardFields">
    <div class="col-lg-10 mortgage-link">
        <div class="clear"></div>
    </div>
    <div class="col-lg-2 mortgage-right-delete">
        <div class="col-lg-9"></div>
        <div class="col-lg-3">
            <dp-burger-menu
                [items]="burgerMenuItems()"
                (itemClicked)="burgerMenuClicked(mortgage , $event)"
            >
            </dp-burger-menu>
        </div>
    </div>
    <div class="clear"></div>
    <div class="margin-top-10">
        <div class="form-group">
            <ng-container  *ngTemplateOutlet="financingType"></ng-container>
        </div>
        <ng-container  *ngTemplateOutlet="mortgageRegistration"></ng-container>

        <ng-container *dpShowByProvince="'matter.mortgage.assignmentRequestNo'">
            <div class="form-group" *ngIf="isAssignmentRequestNumberVisible()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" dp-province-field-label="provinceBasedAssignmentRegNoTitle"></label>
                </div>
                <div class="col-lg-4">
                    <input
                        class="form-control"
                        maxlength="20"
                        [(ngModel)]="mortgage.assignmentRequestNo"
                        (change)="notifyChange();markUndertakingDirty();"
                        trimmed
                    />
                </div>
            </div>
        </ng-container>

        <ng-container  *ngTemplateOutlet="relatedInstruments"></ng-container>

        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" dp-province-field-label="typeOfMortgagee">Type of Mortgagee</label>
            </div>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.mortgageeType"
                    (change)="onMortgageeTypeChange(mortgage);">
                <option *ngFor="let item of mortgageeTypes" [ngValue]="item.value">{{item.label}}</option>
            </select>
        </div>

            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Correspond with?</label>
            </div>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.mortgageCorrespondenceType"
                    (change)="notifyChange()">
                    <option *ngFor="let item of correspondenceItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>

        <div class="form-group">
            <ng-container  *ngTemplateOutlet="mortgageLoanNo"></ng-container>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Are we obtaining a
                    <br/><span dp-province-field-label="provinceBasedDischargeStatement">discharge statement?</span></label>
            </div>
            <div class="col-lg-1">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.statementForInformation"
                    (change)="notifyChange()">
                    <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>

        <div class="form-group">
            <ng-container  *ngTemplateOutlet="collateralMortgage"></ng-container>
        </div>

        <div class="form-group" *ngIf="isAmountPayableApplicable()">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" dp-province-field-label="amountPayableToDischarge">Amount Payable to<br/>discharge this<br/>mortgage</label>
            </div>
            <div class="col-lg-4">
                <input type="text" id="amountPayableToDischarge" name="amountPayableToDischarge"
                       class="form-control text-right padding-right-30" [(ngModel)]="mortgage.amountPayableToDischarge"
                       dp-currency
                       [fieldKey]="'matter.existingMortgages.amountPayable'"
                       statusBarHelp
                       (keydown.f9)="openMortgagePayout()"
                       [dp-read-only]="true"/>
                <button  type="button"
                         tabIndex="-1"
                         id="amountPayableToDischargeBtn"
                         tabIndex="-1"
                         (click)="openMortgagePayout()" class="button-glyph"
                         class="button-glyph">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </div>
            <div class="col-lg-6 vertical-align-label text-left">
                <label class="control-label text-left">{{mortgagePayOutLabel}}</label>
            </div>
        </div>

        <div class="form-group" *ngIf="(mortgage.isMortgageDispositionRemain())">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Approximate principal outstanding</label>
            </div>
            <div class="col-lg-4">
                <input type="text" id="amountPayableDischarge" name="amountPayableDischarge" (change)="notifyChange()"
                       class="form-control text-right" [(ngModel)]="mortgage.amountPayableToDischarge" dp-currency/>
            </div>
            <div class="col-lg-6 vertical-align-label text-left">
                <label class="control-label text-left">{{mortgagePayOutLabel}}</label>
            </div>

        </div>

        <div class="form-group"  *ngIf="(mortgage.isMortgageDispositionBridgeFinancing() || mortgage.isMortgageDispositionDischarged())">
            <ng-container *dpShowByProvince="'matter.mortgage.existingMortgage.dischargePreparedBy'">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" >Who is preparing the discharge</label>
                </div>
                <div class="col-lg-4">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.dischargePreparedBy"
                        (change)="notifyChange()">
                        <option *ngFor="let item of dischargeRegisteredByItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </ng-container>
        </div>

        <div class="form-group"  *ngIf="(mortgage.isMortgageDispositionBridgeFinancing() || mortgage.isMortgageDispositionDischarged())">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" dp-province-field-label="dischargeRegisteredBy">Discharge is to be<br/>registered by</label>
            </div>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.dischargeRegisteredBy"
                    (change)="onDischargeRegisteredByChange()">
                    <option *ngFor="let item of dischargeRegisteredByItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>

        <ng-container *ngIf="showUndertakingRelatedFields()">

            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Undertaking Status</label>
                </div>
                <div class="col-lg-4">
                    <select class="form-control" id="tabL.undertakingStatus" name="undertakingStatus"
                            [(ngModel)]="getAssociatedUndertaking().matterUndertakingStatus" (ngModelChange)="notifyChange()">
                        <option *ngFor="let option of matterUndertakingStatusOptions" [value]="option.value">{{option.label}}</option>
                    </select>
                </div>
            </div>


            <div class="form-group" *ngIf="this.matter && (getAssociatedUndertaking().matterUndertakingStatus === 'FULFILLED')">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" dp-province-field-label="provinceBasedDischargedByInstrumentNo">Discharged by Instrument No.</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="tabL.dischargedByInstrumentNo"
                           name="tabL.dischargedByInstrumentNo"
                           class="form-control"
                           [(ngModel)]="getAssociatedUndertaking().dischargedByInstrumentNo"
                           (keyup)="notifyChange()"
                           maxlength="100"/>
                </div>

                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">registered on</label>
                </div>
                <div class="col-lg-4">
                    <dp-partial-date
                        #registeredOnDate
                        id="registeredOn_{{mortgage.identifier}}"
                        [inputDate]="getRegisteredDate()"
                        (dateChange)="onRegisteredDate($event);"
                        [fieldKey]="'tabL.undertaking.registeredDate'"
                        [dp-error-validator]="{data: {}}"
                        [fullDateRequired]="true"
                        [identifierId]="'registeredOn_'+mortgage.identifier"
                        [groupId]="mortgage.getDischargedMortgageGroupId()"
                    >
                    </dp-partial-date>
                </div>
            </div>
        </ng-container>

        <div class="form-group" *ngIf="mortgage.teranetSuggestedMortgagees">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" dp-province-field-label="provinceBasedMortgageeNameTitle"></label>
            </div>
            <div class="col-lg-10">
                <input type="text" id="teranetConnectMortgagee" name="teranetConnectMortgagee" dp-read-only="true"
                       class="form-control" [(ngModel)]="mortgage.teranetSuggestedMortgagees"
                />
            </div>
        </div>
        <ng-container  *ngTemplateOutlet="mortgageeDetails"></ng-container>
        <!--<dp-mortgagee #mortgageeComponent
                      [matter]="matter"
                      [mortgage]="mortgage"
                      [payoutStatement]="payoutStatement"
                      (payoutStatementRequested)="payoutStatementRequested($event)"
                      (onChange)="notifyChange($event)">

        </dp-mortgagee>-->
        <dp-user-defined-fields [topicName] = "'EXISTING_MORTGAGE'" (onChange)="notifyChange()" [mortgageId]="mortgage.id" *ngIf="mortgage">
        </dp-user-defined-fields>
    </div>
</ng-container>
<ng-container *ngIf ="!!showWizardFields">
    <div [dp-read-only-block]="dpReadOnlyBlock" [elementRefComp]="elementRef">
        <div class="form-group form-horizontal text-right padding-top-10">
            <ng-container  *ngTemplateOutlet="financingType"></ng-container>
        </div>
        <div class="form-group form-horizontal col-lg-12 padding-top-10">
            <ng-container  *ngTemplateOutlet="mortgageRegistration"></ng-container>
        </div>
        <div class="form-group form-horizontal col-lg-12">
            <ng-container  *ngTemplateOutlet="relatedInstruments"></ng-container>
        </div>
        <div class="form-group form-horizontal col-lg-12">
            <ng-container  *ngTemplateOutlet="mortgageLoanNo"></ng-container>
        </div>
        <div class="form-group form-horizontal col-lg-12">
            <ng-container  *ngTemplateOutlet="collateralMortgage"></ng-container>
        </div>
        <div class="form-group form-horizontal col-lg-12">
            <ng-container  *ngTemplateOutlet="mortgageeDetails"></ng-container>
        </div>
    </div>




</ng-container>
<ng-template #financingType>
    <div class = "col-md-3 vertical-align-label" >
        <div class="col-lg-12 fullLength-autocomplete" *dpShowByProvince="'matter.mortgage.existingMortgage.mortgageTo'">
            <p-autoComplete id="omniSearchFinancingType"
                            name="omniSearchFinancingType" field="label"
                            [(ngModel)]="selectedFinancingType"
                            (ngModelChange)="updateFinancingType($event)"
                            (focusout)="updateValueOnFocusOut($event , mortgage)"
                            [class.autocomplete-searching]="financingTypeLoading"
                            [suggestions]="financingTypeSuggestions"
                            (completeMethod)="omniSearchFinancingType($event)"
                            (onSelect)="dataSelectedFinancingType($event)"
                            [dropdown]="true"
                            [class.dropdown-arrow-refferedBy]="true"
                            (onDropdownClick)="handleDropdownClickSearchFinancingType();"
                            maxlength="100" scrollHeight="350px"
                            minLength="0" delay="1000"
                            statusBarHelp
                            class="autocomplete-search-icon">
                <ng-template let-financingType pTemplate="item" >
                    <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="financingType">
                                    <span class="omnibarLi100">
                                        {{ financingType.label }}
                                    </span>
                    </li>
                </ng-template>>
            </p-autoComplete>
        </div>

    </div>

    <div class="col-lg-3 vertical-align-label" *dpShowByProvince="'matter.mortgage.existingMortgage.mortgageDispositionType'">
        <label class="control-label no-wrap text-center"  dp-province-field-label="provinceBasedMortgageIsToTitle"></label>
        <select
            class="form-control"
            [ngClass]="{'focus-first': !showWizardFields}"
            [(ngModel)]="mortgage.mortgageDispositionType"
            [dp-read-only]="!!showWizardFields && !!matter.isMatterTypeDischarge"
            (change)="onMortgageDispositionTypeChange(mortgage)">
            <option *ngFor="let item of mortgageDispositionTypeItems" [value]="item.value">{{item.label}}</option>
        </select>
    </div>

    <div class="col-lg-3 vertical-align-label" *dpShowByProvince="'matter.mortgage.existingMortgage.dischargeRegisteredBy'">
        <ng-container>
            <label
                class="control-label no-wrap text-center"
            >
                {{ IS_TO }}
            </label>
            <select
                class="form-control"
                [(ngModel)]="mortgage.dischargeRegisteredBy"
                [dp-read-only]="!!showWizardFields && !!matter.isMatterTypeDischarge"
                (change)="onDischargeRegisteredByChange()">
                <option *ngFor="let item of dischargeRegisteredByItems" [value]="item.value">{{item.label}}</option>
            </select>
        </ng-container>
    </div>

    <div class="col-lg-2 vertical-align-label" *ngIf="mortgage.isMortgageDispositionAssumed()">
        <label class="control-label">
            Principal of<br/>Mtg Assumed
        </label>
    </div>
    <div class="col-lg-2" *ngIf="mortgage.isMortgageDispositionAssumed()">
        <input type="text" name="principal" id="principal" class="form-control"
               [(ngModel)]="mortgage.principalOfMortgageAssumed"
               dp-default-currency
               dp-currency
               (change)="notifyChange()"/>
    </div>

    <div class="col-lg-2 vertical-align-label" *ngIf="mortgage.isMortgageDispositionRemain()">
        <label class="control-label">
            Priority after closing
        </label>
    </div>
    <div class="col-lg-2" *ngIf="mortgage.isMortgageDispositionRemain()">
        <select
            class="form-control"
            [(ngModel)]="mortgage.mortgagePriority"
            (change)="notifyChange()">
            <option *ngFor="let item of mortgagePriorities" [value]="item.value">{{item.label}}</option>
        </select>
    </div>
    <div class="col-lg-2 vertical-align-label" *ngIf="mortgage.isMortgageDispositionRemain() && isRequestPostponementVisible">
        <label class="control-label  text-left">(req postponement)</label>
    </div>
</ng-template>
<ng-template #mortgageRegistration>
    <div class="form-group" *ngIf="!mortgage.isMortgageDispositionBlank()">
        <ng-container *ngIf="isMortgageRequestNumberVisible()">
            <div class="vertical-align-label" [ngClass]="mortgageRequestNoLineLabelCss()">
                <label class="control-label"  dp-province-field-label="mortgageRegNo">Mortgage Reg. No.</label>
            </div>
            <div [ngClass]="mortgageRequestNoLineInputCss()">
                <input
                    class="form-control"
                    maxlength="41" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                    [(ngModel)]="mortgage.mortgageRequestNo"
                    (change)="notifyChange();markUndertakingDirty();"
                    trimmed
                />
            </div>
            <ng-container *ngIf="showRollBookNumberAndFramePageNumber()">
                <div class="vertical-align-label" [ngClass]="mortgageRequestNoLineLabelCss()">
                    <label class="control-label">Roll Book Number</label>
                </div>
                <div [ngClass]="mortgageRequestNoLineInputCss()">
                    <input type="text"
                           trimmed
                           numberOnly
                           name="rollBookNumber"
                           id="rollBookNumber"
                           class="form-control" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                           [(ngModel)]="mortgage.rollBookNumber"
                           (change)="notifyChange()"
                           maxlength="4"/>
                </div>
                <div class="vertical-align-label padding-left-0" [ngClass]="mortgageRequestNoLineLabelCss()">
                    <label class="control-label">Frame Page Number</label>
                </div>
                <div [ngClass]="mortgageRequestNoLineInputCss()">
                    <input type="text"
                           trimmed
                           numberOnly
                           name="framePageNumber"
                           id="framePageNumber"
                           class="form-control" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                           [(ngModel)]="mortgage.framePageNumber"
                           (change)="notifyChange()"
                           maxlength="4"/>
                </div>
            </ng-container>
        </ng-container>

        <div class="col-lg-6" *ngIf="!(mortgage.isMortgageDispositionBridgeFinancing() || mortgage.isMortgageDispositionDischarged() ) &&
            (mortgage.isMortgageDispositionBridgeFinancing() || mortgage.isMortgageDispositionDischarged() || mortgage.isMortgageDispositionAssumed())">
        </div>

        <ng-container *ngIf="isMortgageRequestDateVisible()">
            <div class="vertical-align-label" [ngClass]="mortgageRequestNoLineLabelCss()">
                <label class="control-label" dp-province-field-label="mortgageRegDate">Mortgage Reg. Date</label>
            </div>
            <div [ngClass]="mortgageRequestNoLineInputCss()">
                <dp-partial-date #mortgageRegDate
                                 [showFormattedDateLabel]="false"
                                 (dateChange)="onDateChangeMortgageRegDate($event)"
                                 [fieldKey]="'existingMortgage.'+ mortgageIndex +'.mortgageRegDate.MISSING'"
                                 dp-error-validator
                                 statusBarHelp
                                 [groupId]="mortgage.notBlankDispositionTypeGroupId"
                                 [inputDate]="mortgage.mortgageRequestDate">
                </dp-partial-date>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #relatedInstruments>
    <ng-container *dpShowByProvince="'matter.mortgage.relatedInstruments'">
        <div id="existingMortgageRegistrationDateMISSING_container" class="margin-left-80 margin-top-10" *ngIf="isRelatedInstrumentsVisible()">
            <div class="form-group">
                <div class="col-lg-2 margin-left-5 margin-bottom-minus-14">
                    <label class="control-label" dp-province-field-label="provinceBasedRelatedInstruments">Related Instruments</label>
                </div>
            </div>
            <div [ngClass]="{'margin-top-minus-10': showRollBookNumberAndFramePageNumber()}">
                <table class="table standard-table">
                    <thead>
                    <tr>
                        <th [ngClass]="{'width-20-percent': showRollBookNumberAndFramePageNumber(), 'width-30-percent': !showRollBookNumberAndFramePageNumber()}" dp-province-field-label="provinceBasedInstrumentType">Instrument Type</th>
                        <th class="width-25-percent">Registration Number</th>
                        <ng-container *ngIf="showRollBookNumberAndFramePageNumber()">
                            <th class="width-10-percent">Roll Book Number</th>
                            <th class="width-10-percent">Frame Page Number</th>
                        </ng-container>
                        <th [ngClass]="{'width-30-percent': showRollBookNumberAndFramePageNumber(), 'width-40-percent': !showRollBookNumberAndFramePageNumber()}">Registration Date</th>
                        <th class="width-5-percent"></th>
                    </tr>
                    </thead>
                    <tbody class="table-tbody">
                    <tr *ngFor="let selectedRelatedInstrumentWrapper of relatedInstrumentWrappers; let i=index">
                        <td [ngClass]="{'width-25-percent': showRollBookNumberAndFramePageNumber(), 'width-40-percent': !showRollBookNumberAndFramePageNumber()}">
                            <div>
                                <p-autoComplete id="omniSearchRelatedInstrument_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                                name="omniSearchRelatedInstrument_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                                field="label"
                                                [(ngModel)]="selectedRelatedInstrumentWrapper.dataModel"
                                                (ngModelChange)="updateRelatedInstrument($event, selectedRelatedInstrumentWrapper)"
                                                (focusout)="updateRelatedInstrumentValueOnFocusOut($event , selectedRelatedInstrumentWrapper)"
                                                [class.autocomplete-searching]="instrumentTypeLoading"
                                                [suggestions]="relatedInstrumentSuggestions"
                                                (completeMethod)="omniSearchRelatedInstrument($event)"
                                                (onSelect)="dataselectedRelatedInstrumentWrapper($event, selectedRelatedInstrumentWrapper)"
                                                [dropdown]="true"
                                                [class.dropdown-arrow-refferedBy]="true"
                                                (onDropdownClick)="handleDropdownClickSearchRelatedInstrument();"
                                                [disabled]="isOpenedFromWizardBeforeMatterSave()"
                                                maxlength="100" scrollHeight="350px"
                                                minLength="0" delay="1000"
                                                statusBarHelp
                                                class="autocomplete-search-icon">
                                    <ng-template let-relatedInstrument pTemplate="item" >
                                        <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="relatedInstrument">
                                            <span class="omnibarLi100">
                                                {{ relatedInstrument.label }}
                                            </span>
                                        </li>
                                    </ng-template>>
                                </p-autoComplete>

                            </div>
                        </td>
                        <td class="width-20-percent">
                            <div>
                                <input
                                    class="form-control"
                                    maxlength="20" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                                    [(ngModel)]="selectedRelatedInstrumentWrapper.selectedMortgageInstrument.registrationNumber"
                                    (change)="notifyChange();markUndertakingDirty();"
                                    trimmed
                                />
                            </div>
                        </td>
                        <ng-container *ngIf="showRollBookNumberAndFramePageNumber()">
                            <td class="width-10-percent">
                                <div>
                                    <input
                                        class="form-control"
                                        numberOnly
                                        maxlength="4" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                                        name="rollBookNumber_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                        id="rollBookNumber_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                        [(ngModel)]="selectedRelatedInstrumentWrapper.selectedMortgageInstrument.rollBookNumber"
                                        (change)="notifyChange();"
                                        trimmed
                                    />
                                </div>

                            </td>
                            <td class="width-10-percent">
                                <div>
                                    <input
                                        class="form-control"
                                        numberOnly
                                        maxlength="4" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                                        name="framePageNumber_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                        id="framePageNumber_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                        [(ngModel)]="selectedRelatedInstrumentWrapper.selectedMortgageInstrument.framePageNumber"
                                        (change)="notifyChange()"
                                        trimmed
                                    />
                                </div>

                            </td>
                        </ng-container>
                        <td [ngClass]="{'width-30-percent': showRollBookNumberAndFramePageNumber(), 'width-40-percent': !showRollBookNumberAndFramePageNumber()}">
                            <div>
                                <dp-partial-date #registrationDate
                                                 id="registrationDate_{{selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                                 [identifierId]="'registrationDate_'+ selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id"
                                                 [containerId]="'existingMortgageRegistrationDateMISSING_container'"
                                                 (dateChange)="onDateChangeRegistrationDate($event, selectedRelatedInstrumentWrapper)"
                                                 [fieldKey]="'existingMortgage.'+ mortgageIndex +'.registrationDate.MISSING'"
                                                 [dp-error-validator]="{data: {identifier: selectedRelatedInstrumentWrapper.selectedMortgageInstrument.id}}"
                                                 statusBarHelp
                                                 [groupId]="mortgage.notBlankDispositionTypeGroupId"
                                                 [inputDate]="selectedRelatedInstrumentWrapper.selectedMortgageInstrument.registrationDate">
                                </dp-partial-date>
                            </div>
                        </td>
                        <td class="width-5-percent">
                            <div *ngIf="i!=0">
                            <span>
                                <i class="fa fa-trash"  (click)="removeRelatedInstrument(selectedRelatedInstrumentWrapper)"
                                   aria-hidden="true"></i>
                            </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group padding-bottom-10 margin-top-minus-15 margin-left-5">
                <div class="col-lg-3">
                    <span class="actionlink">
                        <span>
                            <a href="javascript:void(0);" (click)="addRelatedInstrument()" dp-province-field-label="provinceBasedAddAnotherRelatedInstrument">
                                Add another related instrument
                            </a>
                        </span>
                        <span class="selector fa fa-plus-square-o"></span>
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #mortgageLoanNo>
    <div [ngClass]="showWizardFields? 'form-group' : ''">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" dp-province-field-label="provinceBasedMortgageLoanNo"></label>
        </div>
        <div [ngClass]="showWizardFields? 'col-lg-4 padding-left-6' : 'col-lg-4'">
            <input class="form-control" maxlength="30" [(ngModel)]="mortgage.loanNo"
                [disabled]="isOpenedFromWizardBeforeMatterSave()" (change)="notifyChange()" trimmed />
        </div>
    </div>
</ng-template>
<ng-template #collateralMortgage>
    <div [ngClass]="showWizardFields? 'form-group' : ''">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" dp-province-field-label="existing.mortgage.collateral-mortgage"></label>
        </div>
        <div class="col-lg-4">
            <div class="col-lg-3 padding-left-0">
                <select class="form-control" name="collateralMortgage" id="collateralMortgage"
                    [(ngModel)]="mortgage.collateralMortgage" maxlength="40" (change)="notifyChange()">
                    <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #mortgageeDetails>
    <div class="form-horizontal">
        <dp-mortgagee #mortgageeComponent
                      [matter]="matter"
                      [mortgage]="mortgage"
                      [isOpenedFromWizard]="showWizardFields" [dpReadOnlyBlock]="dpReadOnlyBlock"
                      [payoutStatement]="payoutStatement"
                      [mortgageeLabel]="getMortgageeLabel"
                      mortgageType="CHARGE"
                      (payoutStatementRequested)="payoutStatementRequested($event)"
                      (onChange)="notifyChange($event)" [isOpenedFromWizard]="showWizardFields">

        </dp-mortgagee>
    </div>

</ng-template>

