<form class="form-horizontal container-fluid custom-modal-container" id="finalFeeAdjForm" *ngIf="localAdjustment" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <ng-container *ngIf="isFinalOccupancyFee()">
                    <h1>Adjustment Type: Occupancy (Final Closing)</h1>
                </ng-container>
                <ng-container *ngIf="isFinalEarlyPossessionFee()">
                    <h1>Early Possession Fees (Final Closing)</h1>
                </ng-container>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <ng-container *ngIf="isFinalOccupancyFee()">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="localAdjustment">
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <ng-container *ngIf="isFinalOccupancyFee() && !isNonCondoPotlON()">
                            <label class="control-label">Use amount from interim occupancy adjustment?</label>
                        </ng-container>
                        <ng-container *ngIf="isFinalOccupancyFee() && isNonCondoPotlON()">
                            <label class="control-label">Use amount from the interim early possession fee adjustment?</label>
                        </ng-container>
                        <ng-container *ngIf="isFinalEarlyPossessionFee()">
                            <label class="control-label">Use amount from interim early possession adjustment?</label>
                        </ng-container>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="useAmountFromInterim"
                                name="useAmountFromInterim"
                                [(ngModel)]="localAdjustment.useAmountFromInterim"
                                (ngModelChange)="onUseAmountFromInterim()"
                                class="form-control"
                        >
                            <option *ngFor="let uaff of useAmountFromFinalTypes" [value]="uaff.value">
                                {{uaff.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div  class="form-group">
                <div  class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <ng-container *ngIf="isFinalOccupancyFee()">
                            <label class="control-label">Amount of monthly occupancy fees</label>
                        </ng-container>
                        <ng-container *ngIf="isFinalEarlyPossessionFee()">
                            <label class="control-label">Amount of monthly early possession fees</label>
                        </ng-container>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               name="amount"
                               [(ngModel)]="localAdjustment.amount"
                               [dp-read-only]="localAdjustment.useAmountFromInterim === 'YES' || localAdjustment.useAmountFromInterim === 'YES_EXCLUDING_TAXES'"
                               dp-currency
                               dp-default-currency
                               class="form-control text-right"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <ng-container *ngIf="isFinalOccupancyFee()">
                            <label class="control-label">Purchaser has paid occupancy fees to</label>
                        </ng-container>
                        <ng-container *ngIf="isFinalEarlyPossessionFee()">
                            <label class="control-label">Has Purchaser paid early possession fees for month of {{closingDateMonthAndYear}}</label>
                        </ng-container>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="purchaserHasPaidFee"
                                name="purchaserHasPaidFee"
                                [(ngModel)]="localAdjustment.purchaserHasPaidFee"
                                (ngModelChange)="onUseAmountFromInterim()"
                                class="form-control"
                        >
                            <option *ngFor="let phpf of purchaserHasPaidFeeTypes" [value]="phpf.value">
                                {{phpf.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="localAdjustment.purchaserHasPaidFee === 'OTHER_ENTER_OWN_DATE'">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <ng-container *ngIf="isFinalOccupancyFee()">
                            <label class="control-label">Date to which Purchaser has paid occupancy fees</label>
                        </ng-container>
                        <ng-container *ngIf="isFinalEarlyPossessionFee()">
                            <label class="control-label">Date to which Purchaser has paid early possession fees</label>
                        </ng-container>
                    </div>
                    <div class="col-lg-7">
                        <dp-partial-date #paidOnDate
                                         name="paidOnDateId"
                                         id="paidOnDateId"
                                         [disableAll]="localAdjustment.purchaserHasPaidFee !== 'OTHER_ENTER_OWN_DATE'"
                                         (dateChange)="onDateChange($event ,'paidOn')"
                                         [fieldKey]="'matter.soadj.final.occupancy.fee.paidOn.date.empty'"
                                         dp-error-validator
                                         [inputDate]="localAdjustment.paidOn"
                        >
                        </dp-partial-date>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{getLabelForAdjustTaxType()}}</label>
                    </div>
                    <div class="col-lg-1" *ngIf=" isFinalOccupancyFee() && !context.matter.isMatterProvinceAB">
                        <ng-container>
                            <select type="text"
                                    id="taxType"
                                    name="taxType"
                                    [(ngModel)]="localAdjustment.taxType"
                                    (ngModelChange)="updateTaxRate($event)"
                                    class="form-control"
                            >
                                <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                                    {{taxType.label}}
                                </option>
                            </select>
                        </ng-container>

                    </div>
                    <div class="col-lg-1">
                        <div class="col-lg-2" *ngIf=" isFinalOccupancyFee() && !context.matter.isMatterProvinceAB">
                            <label class="control-label text-left">?</label>
                        </div>
                        <div class="col-lg-10">
                            <select type="text"
                                    id="applyTax"
                                    name="applyTax"
                                    [(ngModel)]="localAdjustment.applyTax"
                                    class="form-control"
                            >
                                <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                    {{yesNo.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group" *ngIf="localAdjustment.isTaxApplicable">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{localAdjustment.taxType}} percentage is</label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="hstPercentage"
                               name="hstPercentage"
                               [(ngModel)]="localAdjustment.hstRate"
                               class="form-control"
                               dp-percentage
                        />
                    </div>
                    <div class="col-lg-1">
                        <label class="control-label text-left">%</label>
                    </div>
                </div>
            </div>

            <!-- adjustment summary -->
            <div style="border-top: 1px solid #cccccc;">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <ng-container *ngIf="isFinalOccupancyFee()">
                            <label class="control-label" style="font-weight : bold">
                                OCCUPANCY FEES <ng-container *ngIf="localAdjustment.useAmountFromInterim === 'YES_EXCLUDING_TAXES'">(EXCLUDING TAXES)</ng-container>
                            </label>
                        </ng-container>
                        <ng-container *ngIf="isFinalEarlyPossessionFee()">
                            <label class="control-label" style="font-weight : bold">
                                EARLY POSSESSION FEES
                            </label>
                        </ng-container>

                    </div>
                </div>

                <div *ngFor="let line of details; let i = index">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                        <ng-container *ngIf="line.value && line.value.length > 0">
                            <label *ngIf="i + 1  < details.length" class="control-label" [ngClass]="{'col-lg-4': line.pushValueToRight, 'col-lg-2': !line.pushValueToRight}">{{line.value}}</label>
                            <label *ngIf="i + 1 == details.length" class="control-label" [ngClass]="{'col-lg-6': line.pushValueToRight, 'col-lg-4': !line.pushValueToRight}">{{line.value}}</label>
                        </ng-container>
                    </div>
                </div>

            </div>

        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
            <button type="button"
                    *ngIf="allowDelete"
                    (click)="delete()"
                    class="secondary-button">
                <span>Delete</span>
            </button>
        </div>
    </div>
</div>
<dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
