import * as _ from 'lodash';
import {contactTypeMapping} from './contact-type-mapping';

export class ContactTypeUtil {

  static getLocalizedMapping(contactType: String, provinceCode: any): any {
    let contactMapping = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === contactType);
    contactMapping = this.shallowCopyContactMapping(contactMapping);
    if (contactMapping.localizedLabels?.[ provinceCode ]) {
      contactMapping.label = contactMapping.localizedLabels[ provinceCode ];
    }
    return contactMapping;
  }

  static shallowCopyContactMapping(contactMapping) {
    return {...contactMapping};
  }
}
