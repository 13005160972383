import {ErrorService} from '../../shared/error-handling/error-service';
import {Component, Inject} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {DialogService} from '../../shared/dialog/dialog.service';
import {AccountIntegrationCredential, AccountIntegrationTypes} from './account-integration-credential';
import {ThirdPartyIntegrationService} from '../../shared-main/third-party/third-party-integration.service';
import {Account} from '../accounts/shared/account';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class RenameCredentialModalContext {
  accountIntegrationCredential: AccountIntegrationCredential;
  account: Account;
}

@Component({
  selector: 'dp-rename-credential-modal',
  templateUrl: 'rename-credential.modal.component.html',
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class RenameCredentialModal extends ModalComponent<RenameCredentialModalContext> {
  accountIntegrationCredential: AccountIntegrationCredential;
  credentialLabel: string;

  constructor(
    public dialog: MatDialogRef<RenameCredentialModal>,
    public dialogService: DialogService,
    public logger: Logger,
    public errorService: ErrorService,
    public thirdPartyIntegrationService: ThirdPartyIntegrationService,
    @Inject(MAT_DIALOG_DATA) context?: RenameCredentialModalContext
  ) {
    super(dialog, context);
    this.accountIntegrationCredential = new AccountIntegrationCredential(this.context.accountIntegrationCredential);
  }

  saveCredential(): void {
    if (this.accountIntegrationCredential.integrationType == AccountIntegrationTypes.zoom) {
      this.thirdPartyIntegrationService
        .updateAccountIntegrationCredential(
          AccountIntegrationTypes.zoom,
          this.context.account.id,
          this.accountIntegrationCredential
        )
        .subscribe((accountIntegrationCredential: AccountIntegrationCredential) => {
          this.dialog.close({
            action: 'OK',
            accountIntegrationCredential: accountIntegrationCredential
          });
        });
    }
    if (this.accountIntegrationCredential.integrationType == AccountIntegrationTypes.syngrafii) {
      this.thirdPartyIntegrationService
        .updateAccountIntegrationCredential(
          AccountIntegrationTypes.syngrafii,
          this.context.account.id,
          this.accountIntegrationCredential
        )
        .subscribe((accountIntegrationCredential: AccountIntegrationCredential) => {
          this.dialog.close({
            action: 'OK',
            accountIntegrationCredential: accountIntegrationCredential
          });
        });
    }
    //TODO: add other integration types in future
  }

  cancel(): void {
    this.dialog.close({action: 'Cancel'});
  }
}
