import {DocumentProfileTemplateFolder} from '../document-profile-template-folder';
import {DocumentProfileService} from '../../document-profile.service';
import {Component, Inject, OnInit} from '@angular/core';
import {FocusFirstElementDecorator} from '../../../../../shared-main/focus-first-element-decorator';
import {DialogService} from '../../../../../shared/dialog/dialog.service';
import {AppConfig} from '../../../../../shared-main/app-configuration';
import {DocumentTemplate, DocumentTemplateFile} from '../../../../../matters/document-production/document-template';
import {SESSION_STORAGE_KEYS} from '../../../../../shared/session-storage-keys';
import {AccountService} from '../../../../accounts/account.service';
import {CustomKeyCodesEnum} from '../../../../../common';
import * as _ from 'lodash';
import {ImportDoProcessTemplateConfirmationModalComponent} from '../import-doprocess-template/import-doprocess-template-confirm.modal.component';
import {ApplicationError} from '../../../../../core';
import {ImportTemplate, TemplateData} from '../../../../../matters/document-production/import-doprocess-template';
import {DocumentTemplateCategory} from '../../../../../matters/document-production/document-template-category';
import {Account} from '../../../../accounts/shared/account';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export class ImportDoProcessTemplateModalContext {
  accountFileFolder: DocumentProfileTemplateFolder;
  existingDocumentTemplates: DocumentTemplateFile[];
  account: Account;
}

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-import-doprocess-template-content',
  templateUrl: 'import-doprocess-template.modal.component.html',
  styleUrls: ['./import-doprocess-template.modal.scss'],
  providers: [DocumentProfileService]
})
export class ImportDoProcessTemplateModalComponent
  extends ModalComponent<ImportDoProcessTemplateModalContext>
  implements OnInit
{
  systemAccountFileFolder: DocumentProfileTemplateFolder;

  rows: DocumentTemplateFile[] = [];
  filteredRows: DocumentTemplateFile[] = [];
  documentTemplateIds: string[] = [];
  categories: DocumentTemplateCategory[] = [];
  categoryFilter: string = 'ALL';

  constructor(
    public dialog: MatDialogRef<ImportDoProcessTemplateModalComponent>,
    public documentProfileService: DocumentProfileService,
    public accountService: AccountService,
    public dialogService: DialogService,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: ImportDoProcessTemplateModalContext
  ) {
    super(dialog, context);
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    this.retrieveDoProcessTemplates();
    this.accountService
      .getSystemTemplateFolder(
        this.context.accountFileFolder.provinceCode,
        this.context.accountFileFolder.templateProcessorType
      )
      .subscribe((data) => {
        this.systemAccountFileFolder = data;
      });
  }

  sortData(data: DocumentTemplateFile[]): DocumentTemplateFile[] {
    return _.sortBy(data, (item: DocumentTemplateFile) => {
      return item.lastUpdatedTimeStamp ? item.lastUpdatedTimeStamp : ''; //Includes come after master files with the same name, add a
      // suffix for sorting
    }).reverse();
  }

  get accountId(): string {
    return this.context.account
      ? this.context.account.id.toString()
      : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }

  retrieveDoProcessTemplates(): void {
    this.documentProfileService
      .getSystemDocTemplateFiles(
        this.context.accountFileFolder.provinceCode,
        this.context.accountFileFolder.templateProcessorType
      )
      .subscribe((data: DocumentTemplateFile[]) => {
        data = this.sortData(data);
        this.rows = data;
        this.filteredRows = this.rows;
        this.loadCategories(data);
      });
  }

  loadCategories(data: DocumentTemplateFile[]) {
    this.categories = [];
    data.forEach((item) => {
      if (item.documentTemplate) {
        for (let i = 0; i < item.documentTemplate.documentTemplateCategories.length; i++) {
          let element = this.categories.find((cat) => {
            return cat.categoryDescription == item.documentTemplate.documentTemplateCategories[i].categoryDescription;
          });
          if (element == undefined) {
            this.categories.push(item.documentTemplate.documentTemplateCategories[i]);
          }
        }
      }
    });
  }

  isIncludeFileExist(): boolean {
    let rowIndex = this.rows.findIndex((row) => row.include);
    return rowIndex != -1;
  }

  onCategoryChange($event) {
    console.log('onCategoryChange: ', $event);
    if (this.categoryFilter == 'ALL') {
      this.filteredRows = this.rows;
    } else if (this.categoryFilter == 'Include') {
      this.filteredRows = this.rows.filter((row) => row.include);
    } else {
      let filterByCategory: DocumentTemplateFile[] = [];
      this.rows.forEach((row) => {
        if (row.documentTemplate && row.documentTemplate.documentTemplateCategories.length > 0) {
          let categoryExist: DocumentTemplateCategory = row.documentTemplate.documentTemplateCategories.find(
            (cat) => cat.categoryDescription === this.categoryFilter
          );
          if (categoryExist) {
            filterByCategory.push(row);
          }
        }
      });
      this.filteredRows = filterByCategory;
    }
  }

  isSelected(docId: string): boolean {
    return !!this.documentTemplateIds.find((id: string) => id === docId);
  }

  selectTemplate(isCheckBoxSelected: boolean, row: DocumentTemplateFile) {
    let rowIndex = this.documentTemplateIds.findIndex((id) => id == row.id.toString());
    if (isCheckBoxSelected) {
      if (rowIndex == -1) {
        this.documentTemplateIds.push(row.id.toString());
      }
    } else {
      if (rowIndex > -1) {
        this.documentTemplateIds.splice(rowIndex, 1);
      }
    }
  }

  tableKeyCommands(index, event): void {
    let charCode = event.charCode ? event.charCode : event.keyCode ? event.keyCode : event.which ? event.which : 0;

    if (charCode === CustomKeyCodesEnum.Down) {
      event.preventDefault();
      this.keyCommandToSelectNext(index);
    }
    if (charCode === CustomKeyCodesEnum.Up) {
      event.preventDefault();
      this.keyCommandToSelectPrev(index);
    }
  }

  keyCommandToSelectNext(index) {
    if (index < this.rows.length) {
      jQuery(document.activeElement).next('tr').focus();
    }
  }

  keyCommandToSelectPrev(index) {
    if (index > 0) {
      jQuery(document.activeElement).prev('tr').focus();
    }
  }

  multiSelectDocumentsForImport(isCheckBoxSelected: boolean): void {
    if (isCheckBoxSelected) {
      for (let i = 0; i < this.filteredRows.length; i++) {
        if (!this.filteredRows[i].isInfected && !this.filteredRows[i].isVirusScanPending) {
          this.documentTemplateIds.push(this.filteredRows[i].id.toString());
        }
      }
    } else {
      this.documentTemplateIds = [];
    }
    console.log(this.documentTemplateIds);
  }

  importTemplateConfirmationModal() {
    if (this.existingTemplatesToCopy.length > 0) {
      this.dialogService.matDialogContent({
        content: ImportDoProcessTemplateConfirmationModalComponent,
        context: {
          selectedDocumentTemplateFiles: this.selectedTemplateToCopy,
          targetAccountFileFolder: this.context.accountFileFolder,
          account: this.context.account,
          existingTemplatesToCopy: this.existingTemplatesToCopy,
          importTemplate: this.createImportTemplate()
        },
        onFulfillment: (result: any) => {
          this.close();
        }
      });
    } else {
      this.copyTemplates();
    }
  }

  copyTemplates() {
    this.documentProfileService
      .copyDocumentTemplatesToAccountFileFolder(
        this.accountId,
        this.context.accountFileFolder.id,
        this.createImportTemplate()
      )
      .subscribe(
        (res: DocumentTemplate[]) => {
          this.close();
        },
        (error: ApplicationError) => {
        }
      );
  }

  createImportTemplate(): ImportTemplate {
    let importTemplate: ImportTemplate = new ImportTemplate();
    let templateDataArray: TemplateData[] = [];
    this.selectedTemplateToCopy.forEach((template) => {
      let templateData = new TemplateData();
      templateData.systemTemplateId = template.id;
      let templateExist = this.context.existingDocumentTemplates.find(
        (existingTemplate) => existingTemplate.fileName === template.fileName
      );
      if (templateExist) {
        templateData.existingTemplateId = templateExist.id;
      }
      templateDataArray.push(templateData);
    });
    importTemplate.templates = templateDataArray;
    return importTemplate;
  }

  get selectedTemplateToCopy(): DocumentTemplateFile[] {
    return this.rows.filter((item) => this.documentTemplateIds.indexOf(item.id.toString()) > -1);
  }

  get templatesToCopy(): string[] {
    let templatesToCopy: string[] = [];
    this.selectedTemplateToCopy.map((item) => templatesToCopy.push(item.fileName));
    return templatesToCopy;
  }

  get existingTemplatesToCopy(): DocumentTemplateFile[] {
    return this.context.existingDocumentTemplates.filter((item) => this.templatesToCopy.indexOf(item.fileName) > -1);
  }

  downloadTemplateDocument = (docTemplateFile: DocumentTemplateFile) => {
    console.log('docTemplateFile:' + docTemplateFile);
    this.documentProfileService.downloadSystemDocTemplateFile(docTemplateFile.id);
  };

  ngAfterViewInit() {}
}
