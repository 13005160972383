import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {TarionWarrantyRate} from './tarion-warranty-rate';

export class TarionWarrantyEnrolmentPeriod extends BaseEntity {
  id: number;

  displayName: string;
  isDefault: boolean;

  rates: TarionWarrantyRate[];
}
