import {AfterViewInit, Component, OnInit, Renderer2} from '@angular/core';
import {StatementAdjustment} from './statement-adjustment';
import {Matter} from '../shared/matter';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {Form4AddressedTo} from '../mortgages/mortgage/dropdown-options';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import * as _ from 'lodash';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {SalePriceAdjustment} from './sale-price-adjustment/sale-price-adjustment';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {StatementConfigService} from '../../admin/shared/statement-config.service';
import {StatementAdjustmentConfig} from '../../admin/statement-adjustment/statement-adjustment-config';

import {Subscription} from 'rxjs/Subscription';
import {StatementOfAdjustmentPayable} from './model/statement-adjustment-payable';
import {StatementAdjustmentDisplayBuilder} from './builders/statement-adjustment-display-builder';
import {ErrorService} from '../../shared/error-handling/error-service';
import {RentInterestRate} from './model/rent-interest-rate';
import {TarionWarrantyEnrolmentPeriod} from './model/tarion-warranty-enrolment-period';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {StatementAdjustmentUtil} from './statement-adjustment-util';
import {BrokerCommissionComponent} from '../broker-commission/broker-commission.component';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {Utils as MoreUtils} from '../../matters/shared/utils';
import {UUIDUtil} from '../../main/uuid-util';
import {Tax_RATE} from '../../shared-main/province-based-dropdowns';
import {SalePriceAdjustmentFactory} from './sale-price-adjustment/sale-price-adjustment-factory';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {MatterTab} from '../matter-tab';
import {TabsService} from '../../core/tabs.service';
import {ProgressionStatus, StatementAdjustmentOrder} from '../../matters/statement-adjustment/statement-adjustment';
import {Event, NavigationEnd, Router} from '@angular/router';
import {Project} from '../../projects/shared/project';
import {SoajFieldCodeService} from '../../shared-main/soaj-field-code.service';

import {AutoUnsubscribeDecorator2} from '../../shared-main/AutoUnsubscribeDecorator2';

import {soAdjProjectFooters} from './model/so-adj-drop-downs';
import {SoAdjHeading} from './model/soa-adj-custom-format';
import {MassUpdateTab} from '../../shared/tabbing/mass-update-tab';
import {taxRateTypeValues} from '../shared/matter-tax-rate';
import {MatterStatementAdjustmentUtil} from '../shared/matter-utils/matter-statement-adjustment-util';
import {SoaModal} from './ui/soa-modal';
import {SoaStateUtil} from './ui/soa-state-util';
import {SoaFulfillment} from './ui/soa-fulfillment';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

declare var jQuery: any;

@Component({
  selector: 'dp-matter-statement-adjustment',
  templateUrl: 'statement-adjustment.component.html',
  styleUrls: [
    './statement-adjustment.component.scss'
  ],
  providers: [ DecimalPipe, CurrencyPipe, PercentPipe, BrokerCommissionComponent ]
})
@FocusFirstElementDecorator()
@AutoUnsubscribeDecorator2()
@AutoUnsubscribe()
export class StatementAdjustmentComponent implements OnInit, AfterViewInit {

  matterEstTaxIncrease: string;
  actionButtonFlag: boolean = false;
  clickListener: Function;
  addActionButtonFlag: boolean = false;
  addSubActionButtonFlag: boolean = false;

  getGlobalMatterSub: Subscription;
  printBalanceDueOnClosingAtBottomOfPage: boolean;
  taxRateServiceSub: Subscription;
  documentProfileSub: Subscription;

  // table key commands
  activeIndexFromTabKey: number = 0;
  adjustmentType: string = ProgressionStatus.FINAL;

  constructor(public router: Router,
              public documentProfileCache: DocumentProfileCache,
              public taxRateService: TaxRateService,
              public decimalPipe: DecimalPipe,
              public currencyPipe: CurrencyPipe,
              public percentPipe: PercentPipe,
              public renderer: Renderer2,
              public soaConfigService: StatementConfigService,
              public errorService: ErrorService,
              public brokerCommissionComponent: BrokerCommissionComponent,
              public documentProfileService: DocumentProfileService,
              public tabService: TabsService,
              public soajFieldCodeService: SoajFieldCodeService,
              public soaUtils: SoaStateUtil,
              public soaModalService: SoaModal,
              public soaFulfillmentService: SoaFulfillment
  ) {
    this.clickListener = renderer.listen('document', 'click', (event) => {
      /// need to close up any menues that are left open when we click in other places
      this.resetActionButtonFlag();
      this.resetAddActionButtonFlag();
    });
  }

  ngOnInit() {
    this.soaUtils.triggerUpdate$.subscribe(() => {
      if (this.statementAdjustmentDisplayUtil) {
        this.setUpStatementOfAdjustment();
      }
    });
    // Set then subscribe
    this.soaUtils.setBrokerCommissionComponent(this.brokerCommissionComponent);
    this.soaUtils.brokerCommissionComponent$.subscribe(brokerCommissionComponent => this.brokerCommissionComponent = brokerCommissionComponent);

    this.getGlobalMatterSub = this.router.events.subscribe((section: Event) => {
      if (section instanceof NavigationEnd) {
        this.setUpStatementOfAdjustment();
      }
    });

    // Any Async Methods which you need invoke as part ng init of statement of Adjustments should go in below method.
    // DPPMP-36709 open the TaxRateModal only  if accessed to Statement of Adjustments, So showTaxRateModal as true.
    this.initSoAdjComponent(true);
  }

  get matter() {
    return this.soaUtils.matter;
  }

  setLocalInstanceMatter(matter: Matter) {
    this.soaUtils.setLocalInstanceMatter(matter);
  }

  get soaConsiderationTaxes(): ConsiderationTaxes {
    return this.soaUtils.soaConsiderationTaxes;
  }

  get statementAdjustmentDisplayUtil() {
    return this.soaUtils.statementAdjustmentDisplayUtil;
  }

  get soaConsiderationTaxesInterim() {
    return this.soaUtils.soaConsiderationTaxesInterim;
  }

  get soaConsiderationTaxesFinal() {
    return this.soaUtils.soaConsiderationTaxesFinal;
  }

  get matterDocumentProfile() {
    return this.soaUtils.matterDocumentProfile;
  }

  async updateDisplayItems(ignoreOutOfRangeWarnings?: boolean): Promise<void> {
    return this.soaUtils.updateDisplayItems(ignoreOutOfRangeWarnings);
  }

  get isMassUpdate(): boolean {
    return this.soaUtils.isMassUpdate;
  }

  //selectedIndex call orderedStatementDisplayItems, so it is a heavy getter
  get selectedIndex(): number {
    return _.findIndex(this.soaUtils.orderedStatementDisplayItems, statementAdjustmentObj => statementAdjustmentObj.soaItem && statementAdjustmentObj.soaItem.isSelected == true);
  }

  get salePriceAdjustment(): SalePriceAdjustment {
    return (this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment);
  }

  get hstRate(): number {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstRate ? this.soaConsiderationTaxes.hstRate : 0);
  }

  get isProjectSale(): boolean {
    return (this.matter && this.matter.isProjectSale);
  }

  get project(): Project {
    return this.soaUtils.project;
  }

  set project(project: Project) {
    this.soaUtils.setLocalInstanceProject(project);
  }

  isInterestRateOnDeferredPurchaseMoniesVisible(): boolean {
    return this.soaUtils.isInterestRateOnDeferredPurchaseMoniesVisible();
  }

  isForm4AddressedToFieldVisible(): boolean {
    return this.matter && this.matter.isProjectSale && this.matter.matterPropertyWithCondo && (this.matter.matterPropertyWithCondo.isCondominium === DpBooleanValueTypes.YES) && !this.isMassUpdate && !(this.matter.provinceCode == 'AB');
  }

  // Propagation Related Methods
  isFooterDescriptionEmpty(statementAdjustmentConfig: StatementAdjustmentConfig): boolean {
    return statementAdjustmentConfig ? statementAdjustmentConfig.description.trim() === '' : true;
  }

  //This method initializes Statement of adjustment component (ngOnInit) synchronously. As there are some backend calls (like evaluate codes) which need to be
  // synchronous for some flows like mass update, propogation, therefore putting all ngInit code in this method.
  // DPPMP-36709 open the TaxRateModal only  if accessed to Statement of Adjustments, So add an opption showTaxRateModal as parameter.
  async initSoAdjComponent(showTaxRateModal?: boolean): Promise<void> {
    let currentDirtyState: boolean = this.matter.dirty;

    if (this.matter && this.matter.project && this.matter.project.templateMatterId) {
      this.soaUtils.getProjectTemplateMatter(this.matter.project.templateMatterId);
    }

    this.soajFieldCodeService.generateMissingSoajFieldCodes();

    this.matter.updateStatusMode(this.matter.selectedProgressionStatus);
    this.soaUtils.setStatementAdjustmentDisplayUtil(new StatementAdjustmentDisplayBuilder(this.decimalPipe, this.currencyPipe, this.percentPipe));
    this.statementAdjustmentDisplayUtil.documentProfileCache = this.documentProfileCache;

    if (!this.matter.adjustAsAtClosingDateFlag) {
      if (this.matter.isMatterProvinceBC) {
        this.matter.adjustAsAtClosingDateFlag = 'ADJUSTMENT_DATE';
      } else {
      this.matter.adjustAsAtClosingDateFlag = 'CLOSING_DATE';
    }
    }
    if (!this.matter.isProjectSale && !this.matter.adjustAsAtClosingDate && !this.matter.isMatterProvinceBC) {
      this.matter.adjustAsAtClosingDate = MoreUtils.todaysDate();
    }
    if (!this.matter.adjustAsAtClosingDateFlagInterim) {
      if (this.matter.isMatterProvinceBC) {
        this.matter.adjustAsAtClosingDateFlagInterim = 'ADJUSTMENT_DATE';
      } else {
      this.matter.adjustAsAtClosingDateFlagInterim = 'OCCUPANCY_DATE';
      }
    }
    if (!this.matter.isProjectSale && !this.matter.adjustAsAtClosingDateInterim) {
      this.matter.adjustAsAtClosingDateInterim = MoreUtils.todaysDate();
    }

    if (this.matter.matterStatementOfAdjustmentFooters && this.matter.matterStatementOfAdjustmentFooters.length == 0 && !this.soaUtils.isProjectOrProjectSale) {
      let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      let statementAdjustmentConfigList: StatementAdjustmentConfig[] = await this.soaConfigService.getStatementAdjustmentConfig(id, this.matter.provinceCode).toPromise();

      if (statementAdjustmentConfigList) {
        this.matter.matterStatementOfAdjustmentFooters = statementAdjustmentConfigList;
        this.matter.matterStatementOfAdjustmentFooters.map(function (item) {
          item.id = null;
          item.instanceType = 'matterSoaFooter';
          item.footerProgressionStatusAvailability = soAdjProjectFooters.applyToBoth.value;
        });
      }
    }

    if (!this.matter.soaHst) {
      this.matter.soaHst = this.matter.matterHst;
      this.matter.soaFederalHst = this.matter.matterFederalHst;
      this.matter.soaProvincialHst = this.matter.matterProvincialHst;
    }

    if (!this.matter.considerationLtt) {
      this.matter.createNewConsiderationLtt();
      this.matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    } else if (!this.matter.considerationLtt.salePriceAdjustment) {
      this.matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    }

    let twepList: TarionWarrantyEnrolmentPeriod[] = this.taxRateService.getCachedTarionWarrantyEnrolmentPeriods();
    if (twepList && twepList.length > 0) {
      this.statementAdjustmentDisplayUtil.setTarionWarrantyEnrolmentPeriods(twepList);
      await this.updateDisplayItems();
    }

    let hcraFeeList: TarionWarrantyEnrolmentPeriod[] = this.taxRateService.getCachedHCRAFeeEnrolmentPeriods();
    if (hcraFeeList && hcraFeeList.length > 0) {
      this.statementAdjustmentDisplayUtil.setHCRAFeeEnrolmentPeriods(hcraFeeList);
      await this.updateDisplayItems();
    }

    // ngOnInit does not get called on injected components
    this.brokerCommissionComponent.setLocalInstanceMatter(this.matter);
    this.brokerCommissionComponent.initCommissionPaidToFields();
    await this.brokerCommissionComponent.initCommissionBasedSalePrice();

    await this.setUpStatementOfAdjustment();

    // Need to push this call below Setup Since SetupSOA method configure taxes required by below method openTaxRateModal
    let openTaxRateConfirmationBox = (this.matter.soaHst && this.matter.matterHst && this.matter.soaHst != this.matter.matterHst);
    // DPPMP-36709 open the TaxRateModal only  if accessed to Statement of Adjustments
    if (showTaxRateModal && openTaxRateConfirmationBox && !this.isThisPurchaseMatterLinkedToProjectSale()) {
      this.soaModalService.openTaxRateModal(openTaxRateConfirmationBox);
    }
    if (this.isMassUpdate && ((this.tabService.activeTab as MassUpdateTab).massUpdateType === 'TOPIC_A_K_M_N')) {

      let statementOfAdjustmentPayable = new StatementOfAdjustmentPayable();
      statementOfAdjustmentPayable.adoptProjectSoaPayableTo = false;
      statementOfAdjustmentPayable.excludePrefix = false;
      statementOfAdjustmentPayable.excludeSuffix = false;
      statementOfAdjustmentPayable.payableTo = '';
      this.matter.statementOfAdjustmentPayable = statementOfAdjustmentPayable;
      this.matter.adjustAsAtClosingDateFlag = 'SPECIFY';
      this.matter.adjustAsAtClosingDateFlagInterim = 'SPECIFY';
      this.matter.adjustAsAtClosingDate = MoreUtils.todaysDate();
      this.matter.adjustAsAtClosingDateInterim = MoreUtils.todaysDate();
    }
    await this.soaUtils.loadInterestRates(this.matter);
    const hasInterim = this.project && this.project.isStatementOfAdjustmentInterim();
    if (hasInterim && this.matter.isAdjustmentStatusModeFinal) {
      // user may be switching from matter-property tab to here,
      // if the current adjustment mode is Final, we need to first calculate the interim deposit SOA
      // then switch to Final
      await this.soaUtils.recalculateDepositSOAOnAdjustmentChanges();
    }
    await this.soaUtils.initSoaFooters();

    if (!this.isMassUpdate) { //don't set dirty flag for initializing while in mass update mode
      this.soaUtils.setDirtyFlag(currentDirtyState); // initialization completed, reset flag to prevent any behind the scenes operations to set the flag to true.
    }

    this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
    if (this.matter.isPurchase && this.matter.matterLink && this.tabService.activeTab && this.tabService.activeTab.isMatter()
      && (this.tabService.activeTab as MatterTab).linkedMatter && (this.tabService.activeTab as MatterTab).linkedMatter.isProjectSale
      && this.matter.selectedProgressionStatus != this.matter.adjustmentStatusMode) {
      this.matter.adjustmentStatusMode = this.matter.selectedProgressionStatus;
      await this.updateDisplayItems();
      console.log(this.matter.adjustmentStatusMode);
    }
    MatterStatementAdjustmentUtil.generateMissingStatementOfAdjustmentsOrder(this.matter);
  }

  isThisPurchaseMatterLinkedToProjectSale(): boolean {
    let matterTab = this.tabService.activeTab as MatterTab;
    let matterComponent = matterTab && matterTab.matterComponent;
    return this.matter.isPurchase && this.matter.matterLink && matterComponent && matterComponent.isLinkedMatterProjectSale();
  }

  async setUpStatementOfAdjustment(): Promise<void> {
    if (this.brokerCommissionComponent) {
      this.brokerCommissionComponent.setLocalInstanceMatter(this.matter);
    }
    this.statementAdjustmentDisplayUtil.setMatter(this.matter);
    this.loadCachedRentInterestRates();
    if (this.matter && this.matter.isMatterProvinceMB) {
      await this.soaUtils.loadManitobaTiers();
    }
    this.loadCachedConsiderationTaxRate();
    await this.initLocalVariables(); // to help with switching between two matters that are opened at the same level
    await this.updateDisplayItems();
    this.matter.statementOfAdjustments.filter(item => item.isHSTOtherFactor()).forEach(hstFactor => {
      if (hstFactor.hstSalePrice && !hstFactor.salePriceAdjustmentHSTFactor) {
        hstFactor.createDefaultSalePriceAdjustmentHSTFactor(this.matter.adjustmentStatusMode); //ToDo not sure if this is right as it overwrites data saved to db (?!?!)...I'm moving it for now but it needs review
      }
    });
  }

  loadCachedRentInterestRates(): void {
    this.taxRateServiceSub = this.taxRateService.cachedRentInterestRates(this.matter.provinceCode)
    .subscribe(
      (rirList: RentInterestRate[]) => {
        if (rirList && rirList.length > 0) {
          let rentInterestRates = rirList.map(r => new RentInterestRate(r));
          let rentInterestRatesSorted = _.sortBy(rentInterestRates, [ 'index' ]);
          this.statementAdjustmentDisplayUtil.setRentInterestRates(rentInterestRatesSorted);
        }
      }
    );
  }

  loadCachedConsiderationTaxRate(): void {
    this.taxRateService.cachedConsiderationTaxRate(this.matter.provinceCode)
    .subscribe(
      (considerationTaxes: ConsiderationTaxes[]) => {
        this.soaUtils.setTaxRateSlabs(considerationTaxes.filter(item => item.instanceType == Tax_RATE.HST_RATE));
        if (considerationTaxes && considerationTaxes.length > 0) {
          this.soaUtils.setOntarioTaxRateSlab(TaxRateService.getLatestOntarioTaxRateSlabFromConsiderationTaxes(
            considerationTaxes
          ));
          this.soaUtils.setTorontoTaxRateSlab(TaxRateService.getLatestTorontoTaxRateSlabFromConsiderationTaxes(
            considerationTaxes
          ));
          let provinceHstRateSlab = this.taxRateService.findConsiderationHstRateAccordingToEffectiveDate(considerationTaxes, this.matter.getSoaTaxRateEffectiveDate());
          if (this.matter.soAdjustmentTaxRateInterim) {
            this.soaUtils.setSoaConsiderationTaxesInterim(new ConsiderationTaxes());
            this.soaConsiderationTaxesInterim.hstRate = this.matter.soAdjustmentTaxRateInterim.hstRate;
            this.soaConsiderationTaxesInterim.hstFederalPortion = this.matter.soAdjustmentTaxRateInterim.federalHstRate;
            this.soaConsiderationTaxesInterim.hstProvincialPortion = this.matter.soAdjustmentTaxRateInterim.provincialHstRate;
            this.soaConsiderationTaxesInterim.rateType = this.matter.matterTaxType ? this.matter.matterTaxType : provinceHstRateSlab.rateType;
          } else if (provinceHstRateSlab) {
            this.soaUtils.setSoaConsiderationTaxesInterim(provinceHstRateSlab);
            this.matter.updateMatterTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.INTERIM);
            this.matter.matterTaxType = provinceHstRateSlab.rateType;
          }

          if (this.matter.soAdjustmentTaxRateFinal) {
            this.soaUtils.setSoaConsiderationTaxesFinal(new ConsiderationTaxes());
            this.soaConsiderationTaxesFinal.hstRate = this.matter.soAdjustmentTaxRateFinal.hstRate;
            this.soaConsiderationTaxesFinal.hstFederalPortion = this.matter.soAdjustmentTaxRateFinal.federalHstRate;
            this.soaConsiderationTaxesFinal.hstProvincialPortion = this.matter.soAdjustmentTaxRateFinal.provincialHstRate;
            this.soaConsiderationTaxesFinal.rateType = this.matter.matterTaxType ? this.matter.matterTaxType : provinceHstRateSlab.rateType;
          } else if (provinceHstRateSlab) {
            this.soaUtils.setSoaConsiderationTaxesFinal(provinceHstRateSlab);
            this.matter.updateMatterTaxRate(taxRateTypeValues.soAdjustment, ProgressionStatus.FINAL);
            this.matter.matterTaxType = provinceHstRateSlab.rateType;
          }
          this.updateDisplayItems();
        }
      });
  }

  async initLocalVariables(): Promise<void> {
    this.soaUtils.getClosingDate();
    if (this.matter.documentProfileId) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      if (this.documentProfileSub) {
        this.documentProfileSub.unsubscribe();
      }
      let documentProfile = await this.documentProfileService.getById(this.matter.documentProfileId, accountId, false, this.matter).toPromise<DocumentProfile>();
      if (documentProfile) {
        this.soaUtils.setMatterDocumentProfile(documentProfile);
        if (this.matterDocumentProfile.statementOfAdjustmentsProfile) {
          this.matterEstTaxIncrease = this.matterDocumentProfile.statementOfAdjustmentsProfile.estTaxIncrease;
          this.printBalanceDueOnClosingAtBottomOfPage = this.matterDocumentProfile.statementOfAdjustmentsProfile.printBalanceFlag;
          this.soaUtils.setPaperSizeCode(this.matter.statementOfAdjustmentHeading && this.matter.statementOfAdjustmentHeading.paperSizeCode ?
            this.matter.statementOfAdjustmentHeading.paperSizeCode : this.matterDocumentProfile.statementOfAdjustmentsProfile.paperSizeCode);
        }
        this.statementAdjustmentDisplayUtil.matterDocumentProfile = this.matterDocumentProfile;
        this.soaUtils.initHeading();
        this.initPayableTo();
      }
    }

    if (this.matter.isProjectSale && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
      if (this.matter.form4AddressedTo == null) {
        this.matter.form4AddressedTo = Form4AddressedTo.OFFERORS;
      }
    } else {
      this.matter.form4AddressedTo = null;
    }
  }

  initPayableTo() {
    if (this.matter.statementOfAdjustmentPayable == null) {
      this.matter.resetStatementOfAdjustmentPayable(this.matterDocumentProfile, this.documentProfileCache.cachedDefaultDocumentProfile);
    }
  }

  async enableSave(doNotUpdateLinkedAdjustment?: boolean): Promise<void> {
    await this.updateDisplayItems(doNotUpdateLinkedAdjustment);
    this.soaUtils.setDirtyFlag(true);
  }

  public resetAddActionButtonFlag(): void {
    this.addActionButtonFlag = false;
    this.addSubActionButtonFlag = false;
  }

  public resetActionButtonFlag(): void {
    this.actionButtonFlag = false;
  }

  setActiveIndex(e) {
    if (typeof e === 'number') {
      this.activeIndexFromTabKey = e - 1;
    } else {
      this.activeIndexFromTabKey = Number(e.currentTarget.rowIndex) - 1;
    }
  }

  ngOnDestroy() {
    // we need to have this mode set to default to final .. please do not comment this line
    // TODO: code to be removed once we have uniform story in place
    if (this.matter) {
      this.matter.adjustmentStatusMode = ProgressionStatus.FINAL;
    }
    this.soaUtils.clearLocalInstanceMatter();
    this.soaUtils.clearLocalInstanceProject();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let fixedHeaderHeight: number = jQuery('div.fixed-header-statement-adjustments.fixed-element-for-scrolling').outerHeight();
      let tableHeaderHeight: number = jQuery('thead.fixed-element-for-scrolling').outerHeight() - 2;
      jQuery('#soaTable').css('margin-top', fixedHeaderHeight + tableHeaderHeight + 'px');
      // To avoid NG0100 Expression Changed After Checked
      this.activeIndexFromTabKey = -1;
    });
  }

  generateStatementAdjustmentOrderForNewMatter(adjustment: StatementAdjustment, matter: Matter, templateMatter: Matter): void {
    if (matter.isAdjustmentStatusModeFinal) {
      let statementAdjustmentOrder = new StatementAdjustmentOrder();
      statementAdjustmentOrder.adjustmentId = adjustment.id;
      statementAdjustmentOrder.sourceProjectAdjustmentId = adjustment.sourceProjectAdjustmentId;
      statementAdjustmentOrder.adjustmentIndex = templateMatter.finalStatementAdjustments.findIndex(adj => adj.id === adjustment.sourceProjectAdjustmentId);
      matter.finalStatementAdjustmentOrders.push(statementAdjustmentOrder);
    } else if (!matter.isAdjustmentStatusModeFinal) {
      let statementAdjustmentOrder = new StatementAdjustmentOrder();
      statementAdjustmentOrder.adjustmentId = adjustment.id;
      statementAdjustmentOrder.sourceProjectAdjustmentId = adjustment.sourceProjectAdjustmentId;
      statementAdjustmentOrder.adjustmentIndex = templateMatter.interimStatementAdjustments.findIndex(adj => adj.id === adjustment.sourceProjectAdjustmentId);
      matter.interimStatementAdjustmentOrders.push(statementAdjustmentOrder);
    }

  }

  async addProjectLevelStatementOfAdjustments(matter: Matter, templateMatter: Matter): Promise<void> {

    if (matter.isProjectSale && matter.project.templateMatterId && matter.finalStatementAdjustments.length == 0 && templateMatter) {

      let currentAdjustmentStatusMode: string = matter.adjustmentStatusMode; // DPPMP-32526
      matter.copyCondoExpensesIntoUnitLevelPlan(templateMatter.matterPropertyWithCondo, false);

      // Interim Changes

      //Applied Interim
      matter.adjustmentStatusMode = ProgressionStatus.INTERIM;

      for (let item of templateMatter.interimStatementAdjustments.filter(item => !!item.applyToAdjustmentRecord)) {
        let adjustment = await this.soaUtils.addProjectLevelAdjustment(item, matter, (this.isMassUpdate ? null : item.applyToAdjustmentRecord), templateMatter);
        this.generateStatementAdjustmentOrderForNewMatter(adjustment, matter, templateMatter);
        matter.interimStatementAdjustments.push(adjustment);
      }

      // UnApplied Interim
      if (!this.isMassUpdate) {
        for (let item of templateMatter.interimStatementAdjustments.filter(item => !item.applyToAdjustmentRecord)) {
          let adjustment = await this.soaUtils.addProjectLevelAdjustment(item, matter, (this.isMassUpdate ? null : item.applyToAdjustmentRecord), templateMatter);
          this.generateStatementAdjustmentOrderForNewMatter(adjustment, matter, templateMatter);
          matter.interimStatementAdjustmentsUnApplied.push(adjustment);
        }
      }

      for (let item of matter.interimStatementAdjustments) {
        await this.soaFulfillmentService.applyAdjustmentUpdates(item, matter, templateMatter);
      }
      await this.updateSalePriceAdjForNewMatterRecord(matter, templateMatter);
      this.updateDisplayItemsAndDateUpdatesForAdjustment(matter);

      // Final Changes
      matter.adjustmentStatusMode = ProgressionStatus.FINAL;

      for (let item of templateMatter.finalStatementAdjustments.filter(item => !!item.applyToAdjustmentRecord)) {
        let adjustment = await this.soaUtils.addProjectLevelAdjustment(item, matter, (this.isMassUpdate ? null : item.applyToAdjustmentRecord), templateMatter);
        this.generateStatementAdjustmentOrderForNewMatter(adjustment, matter, templateMatter);
        matter.finalStatementAdjustments.push(adjustment);
      }

      for (let item of matter.finalStatementAdjustments) {
        await this.soaFulfillmentService.applyAdjustmentUpdates(item, matter, templateMatter);
      }

      await this.updateSalePriceAdjForNewMatterRecord(matter, templateMatter);
      matter.recalculateForm4Charges();
      this.updateDisplayItemsAndDateUpdatesForAdjustment(matter);

      // UnApplied Final
      if (!this.isMassUpdate) {
        for (let item of templateMatter.finalStatementAdjustments.filter(item => !item.applyToAdjustmentRecord)) {
          let adjustment = await this.soaUtils.addProjectLevelAdjustment(item, matter, (this.isMassUpdate ? null : item.applyToAdjustmentRecord), templateMatter);
          this.generateStatementAdjustmentOrderForNewMatter(adjustment, matter, templateMatter);
          matter.finalStatementAdjustmentsUnApplied.push(adjustment);
        }
      }

      this.matter.statementOfAdjustmentHeading = templateMatter.statementOfAdjustmentHeading;
      if (this.matter.statementOfAdjustmentHeading) {
        this.matter.statementOfAdjustmentHeading.id = undefined;
        this.matter.statementOfAdjustmentHeading.adoptProjectHeading = true; // for a Project Sale Matter this is set by default
      }
      if (this.isProjectSale && templateMatter.matterStatementOfAdjustmentFooters && templateMatter.matterStatementOfAdjustmentFooters.length > 0 && this.matter.matterStatementOfAdjustmentFooters) {
        let matterStatementOfAdjustmentFooter: StatementAdjustmentConfig;
        if (this.matter.matterStatementOfAdjustmentFooters.length == 1 && this.isFooterDescriptionEmpty(this.matter.matterStatementOfAdjustmentFooters[ 0 ])) {
          this.matter.matterStatementOfAdjustmentFooters.splice(0, 1);
        }
        for (let i: number = 0; i < templateMatter.matterStatementOfAdjustmentFooters.length; i++) {
          if (templateMatter.matterStatementOfAdjustmentFooters[ i ].applyByDefault) {
            matterStatementOfAdjustmentFooter = new StatementAdjustmentConfig(templateMatter.matterStatementOfAdjustmentFooters[ i ]);
            matterStatementOfAdjustmentFooter.id = undefined;
            matterStatementOfAdjustmentFooter.projectFooterId = templateMatter.matterStatementOfAdjustmentFooters[ i ].id;
            matterStatementOfAdjustmentFooter.condition = 'UNCONDITIONAL';
            this.matter.matterStatementOfAdjustmentFooters.push(matterStatementOfAdjustmentFooter);
          }
        }
        if (this.matter.matterStatementOfAdjustmentFooters.length == 0) {
          matterStatementOfAdjustmentFooter = new StatementAdjustmentConfig();
          matterStatementOfAdjustmentFooter.footerProgressionStatusAvailability = soAdjProjectFooters.applyToBoth.value;
          matterStatementOfAdjustmentFooter.condition = 'UNCONDITIONAL';
          this.matter.matterStatementOfAdjustmentFooters.push(matterStatementOfAdjustmentFooter);
        }
      }

      if (matter.isMatterProvinceON) {
        matter.copyCondoExpensesIntoUnitLevelPlan(templateMatter.matterPropertyWithCondo, true);
      }
      if (currentAdjustmentStatusMode != matter.adjustmentStatusMode) {
        matter.updateStatusMode(currentAdjustmentStatusMode);
      }
    }
  }

  applyAdjustmentFormatUpdates(statementAdjustment: StatementAdjustment, matter: Matter): void {
    let matterStatementAdjustment: StatementAdjustment = matter.statementOfAdjustments.find(item => item.sourceProjectAdjustmentId == statementAdjustment.id);
    if (matterStatementAdjustment && statementAdjustment.soAdjHeading) {
      matterStatementAdjustment.soAdjHeading = new SoAdjHeading(statementAdjustment.soAdjHeading);
    }
  }

  updateProjectLevelStatementOfAdjustmentsFormat(matter: Matter, templateMatter: Matter): void {
    matter.adjustmentStatusMode = ProgressionStatus.INTERIM;
    let addedInterimAdjustments = [];
    addedInterimAdjustments.push(...templateMatter.interimStatementAdjustments.filter(item => item.applyToAdjustmentRecord && matter.interimStatementAdjustments.some(finalAdj => (!!finalAdj.sourceProjectAdjustmentId && !!item.id && finalAdj.sourceProjectAdjustmentId == item.id))));
    if (addedInterimAdjustments && addedInterimAdjustments.length > 0) {
      addedInterimAdjustments.forEach(item => {
        this.applyAdjustmentFormatUpdates(item, matter);
      });
      this.updateDisplayItemsAndDateUpdatesForAdjustment(matter);
    }
    matter.adjustmentStatusMode = ProgressionStatus.FINAL;
    let addedFinalAdjustments = [];
    addedFinalAdjustments.push(...templateMatter.finalStatementAdjustments.filter(item => item.applyToAdjustmentRecord && matter.finalStatementAdjustments.some(finalAdj => (!!finalAdj.sourceProjectAdjustmentId && !!item.id && finalAdj.sourceProjectAdjustmentId == item.id))));
    if (addedFinalAdjustments && addedFinalAdjustments.length > 0) {
      addedFinalAdjustments.forEach(item => {
        this.applyAdjustmentFormatUpdates(item, matter);
      });
      this.updateDisplayItemsAndDateUpdatesForAdjustment(matter);
    }
  }

  //When switch between anchor tab and matter Tabs,  the matter includes UnApplied soa.  we will remove the duplicate items.
  removeExistingStatementAdjustmentsUnApplied(statementAdjustmentsUnAppliedList: StatementAdjustment[], addedInterimAdjustments: StatementAdjustment[]) {
    if (Array.isArray(statementAdjustmentsUnAppliedList) && Array.isArray(addedInterimAdjustments)) {
      statementAdjustmentsUnAppliedList.forEach(unApply => {
        let index = addedInterimAdjustments.findIndex(item => unApply.sourceProjectAdjustmentId == item.sourceProjectAdjustmentId);
        if (index > -1) {
          addedInterimAdjustments.splice(index, 1);
        }
      });
    }
  }

  async updateProjectLevelStatementOfAdjustments(matter: Matter, templateMatter: Matter): Promise<void> {

    if (matter.isProjectSale && matter.project.templateMatterId && templateMatter) {

      // Interim Changes
      matter.adjustmentStatusMode = ProgressionStatus.INTERIM;
      let addedInterimAdjustments: StatementAdjustment[] = [];

      let templateUnAppliedInterimAdjustments = templateMatter.interimStatementAdjustments.filter(item => !matter.allStatementAdjustments.some(interimAdj => (!!interimAdj.sourceProjectAdjustmentId && !!item.id && interimAdj.sourceProjectAdjustmentId == item.id)))
      .filter(adj => !matter.interimStatementAdjustments.some(interimAdj => (!!interimAdj.linkId && !!adj.linkId && interimAdj.linkId == adj.linkId)))
      .filter(adj => !adj.linkId || !matter.finalStatementAdjustments.some(matterFinalAdj => !!matterFinalAdj.applyToAdjustmentRecord && templateMatter.finalStatementAdjustments.some(finalAdj => !!finalAdj.linkId && !!adj.linkId && finalAdj.linkId == adj.linkId && matterFinalAdj.sourceProjectAdjustmentId == finalAdj.id)))
      .filter(adj => !matter.interimStatementAdjustments.some(matterInterimAdj => !!matterInterimAdj.applyToAdjustmentRecord && templateMatter.finalStatementAdjustments.some(finalAdj => !!finalAdj.linkId && !!adj.linkId && finalAdj.linkId == adj.linkId && matterInterimAdj.sourceProjectAdjustmentId == finalAdj.id)));

      for (let item of templateUnAppliedInterimAdjustments) {
        if (!item.isSalePrice() && !item.isDepositAdjustment() && !item.isSalePriceAdjustmentHeading()) {
          let adjustment = await this.soaUtils.addProjectLevelAdjustment(item, matter, false, templateMatter);
          addedInterimAdjustments.push(adjustment);
        }
      }

      if (addedInterimAdjustments && addedInterimAdjustments.length > 0) {
        this.removeExistingStatementAdjustmentsUnApplied(matter.interimStatementAdjustmentsUnApplied, addedInterimAdjustments);
        matter.interimStatementAdjustmentsUnApplied.push(...addedInterimAdjustments);
      }
      await this.updateStatementDisplayItemOrder();
      // Final Changes
      matter.adjustmentStatusMode = ProgressionStatus.FINAL;
      let addedFinalAdjustments: StatementAdjustment[] = [];
      let templateUnAppliedFinalAdjustments = templateMatter.finalStatementAdjustments.filter(item => !matter.allStatementAdjustments.some(finalAdj => (!!finalAdj.sourceProjectAdjustmentId && !!item.id && finalAdj.sourceProjectAdjustmentId == item.id)))
      .filter(adj => !matter.finalStatementAdjustments.some(matterFinalAdj => (!!matterFinalAdj.linkId && !!adj.linkId && matterFinalAdj.linkId == adj.linkId)))
      .filter(adj => !adj.linkId || !matter.interimStatementAdjustments.some(matterInterimAdj => !!matterInterimAdj.applyToAdjustmentRecord && templateMatter.interimStatementAdjustments.some(interimAdj => !!interimAdj.linkId && !!adj.linkId && interimAdj.linkId == adj.linkId && matterInterimAdj.sourceProjectAdjustmentId == interimAdj.id)))
      .filter(adj => !matter.finalStatementAdjustments.some(matterFinalAdj => !!matterFinalAdj.applyToAdjustmentRecord && templateMatter.interimStatementAdjustments.some(interimAdj => !!interimAdj.linkId && !!adj.linkId && interimAdj.linkId == adj.linkId && matterFinalAdj.sourceProjectAdjustmentId == interimAdj.id)));

      for (let item of templateUnAppliedFinalAdjustments) {
        if (!item.isSalePrice() && !item.isDepositAdjustment() && !item.isSalePriceAdjustmentHeading()) {
          let adjustment = await this.soaUtils.addProjectLevelAdjustment(item, matter, false, templateMatter);
          addedFinalAdjustments.push(adjustment);
        }
      }

      if (addedFinalAdjustments && addedFinalAdjustments.length > 0) {
        this.removeExistingStatementAdjustmentsUnApplied(matter.finalStatementAdjustmentsUnApplied, addedFinalAdjustments);
        matter.finalStatementAdjustmentsUnApplied.push(...addedFinalAdjustments);
      }
      this.updateProjectLevelStatementOfAdjustmentsFormat(matter, templateMatter);
      await this.updateStatementDisplayItemOrder();
    }
  }

  addMassUpdateAdjustment(adjustment: StatementAdjustment, fieldCode: number, matter: Matter): StatementAdjustment {
    let statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, adjustment);
    statementAdjustment.clearAllIds();
    statementAdjustment.applyToAdjustmentRecord = adjustment.isSalePrice() || adjustment.isDepositAdjustment() ? true : adjustment.applyToAdjustmentRecord;
    statementAdjustment.matterId = null;
    statementAdjustment.id = UUIDUtil.getUUID();
    if (statementAdjustment.matterTax) {
      statementAdjustment.matterTax.id = UUIDUtil.getUUID();
    }
    statementAdjustment.fieldCode = fieldCode;
    return statementAdjustment;
  }

  async updateSalePriceAdjForNewMatterRecord(matter: Matter, templateMatter: Matter): Promise<void> {
    let statementAdjustment = matter.statementOfAdjustments.find(item => item.isSalePrice());
    if (statementAdjustment && statementAdjustment.isSalePrice() && templateMatter && templateMatter.matterPropertyWithCondo && templateMatter.considerationLtt) {

      if (this.soaConsiderationTaxes) {
        let salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, templateMatter.provinceCode, templateMatter.considerationLtt.salePriceAdjustment);
        if (salePriceAdjustment) {
          salePriceAdjustment.id = undefined;
          if (this.matter.isAdjustmentStatusModeFinal) {
            salePriceAdjustment.salePriceAdjustmentHeadings.forEach(salePriceAdjustmentHeading => {
              let newSalePriceHeadingId = UUIDUtil.getUUID();
              let salePriceHeadingAdjustments: StatementAdjustment[] = matter.allStatementAdjustments
              .filter(item => item.salePriceAdjustmentHeadingId == salePriceAdjustmentHeading.id);

              salePriceAdjustmentHeading.id = newSalePriceHeadingId;
              salePriceAdjustmentHeading.salePriceAdjustmentHeadingItems.forEach(item => {
                item.id = undefined;
              });

              if (salePriceHeadingAdjustments) {
                salePriceHeadingAdjustments.forEach(salePriceHeadingAdj => {
                  salePriceHeadingAdj.salePriceAdjustmentHeadingId = newSalePriceHeadingId;
                });
              }
            });
          }
        }

        // Store Orginal Mode
        let originalMatterAdjustmentStatusModeMatter = matter.considerationLtt.salePriceAdjustment.matterAdjustmentStatusMode;
        let originalMatterAdjustmentStatusModeSPA = salePriceAdjustment.matterAdjustmentStatusMode;

        // Update Mode
        matter.considerationLtt.salePriceAdjustment.matterAdjustmentStatusMode = matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
        salePriceAdjustment.matterAdjustmentStatusMode = matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;

        // Get Current Value of additional consideration and restore them on SPA
        salePriceAdjustment.additionalConsiderationsInclHst = matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst;
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate = matter.considerationLtt.salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebate;
        salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax = matter.considerationLtt.salePriceAdjustment.additionalVendorConsidNotEligibleForTaxRebatePlusTax;
        salePriceAdjustment.additionalRebatePurchaserNotEligible = matter.considerationLtt.salePriceAdjustment.additionalRebatePurchaserNotEligible;

        // Restore original mode back
        matter.considerationLtt.salePriceAdjustment.matterAdjustmentStatusMode = originalMatterAdjustmentStatusModeMatter;
        salePriceAdjustment.matterAdjustmentStatusMode = originalMatterAdjustmentStatusModeSPA;

        await this.soaFulfillmentService.onSalePriceUpdate(salePriceAdjustment, true);

        let existingConsiderationFromTarionOrOtherFixedTM = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.isAdjustmentStatusModeFinal
          ? templateMatter.finalStatementAdjustments : templateMatter.interimStatementAdjustments);
        let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.statementOfAdjustments);
        if (existingConsiderationFromTarionOrOtherFixedTM && !existingConsiderationFromTarionOrOtherFixed) {
          StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments, true);
        }
        ;
      }
    }
  }

  updateDisplayItemsAndDateUpdatesForAdjustment(matter: Matter): void {
    matter.updateAdjustments(matter.getClosingDate());
    this.soaUtils.updateStatementAdjustmentDisplayItems(matter);
  }

  async updateStatementDisplayItemOrder(): Promise<void> {
    if (this.matter && this.matter.isProjectSale) {
      // Update Display Items so all un applied adjustments get added to display builder list
      await this.updateDisplayItems();

      let statementAdjustmentOrders = this.matter.isAdjustmentStatusModeFinal ? this.matter.finalStatementAdjustmentOrders : this.matter.interimStatementAdjustmentOrders;

      // Loop through all display items and see if any new un applied adjustments needs to be order
      this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems.forEach(item => {
        let adjustment = item.soaItem;
        let isAdjustmentOrdered = statementAdjustmentOrders.some(statementAdjustmentOrder => !!statementAdjustmentOrder.adjustmentId && !!adjustment.id && statementAdjustmentOrder.adjustmentId == adjustment.id);
        // if adjustment is already no need to updates its order index
        if (adjustment && !isAdjustmentOrdered && adjustment.sourceProjectAdjustmentId) {

          let statementAdjustmentOrder = statementAdjustmentOrders.find(stAdjustmentOrder => stAdjustmentOrder.sourceProjectAdjustmentId == adjustment.sourceProjectAdjustmentId);
          // First try to find if adjustment was already order but not applied then we just need to update its new adjustment id.
          if (statementAdjustmentOrder) {
            statementAdjustmentOrder.adjustmentId = adjustment.id;
          } else if (adjustment.sourceProjectAdjustmentId) {
            // But if this is un applied adjustment interacting with matter for first time
            // we need to find its order related to its parent in project.
            // Get Project Index
            let statementAdjustmentOrderObject = this.soaUtils.findOrderInTemplateMatterForAdjustment(adjustment);
            if (statementAdjustmentOrderObject) {
              this.soaUtils.createStatementAdjustmentOrder(adjustment, statementAdjustmentOrderObject);
            } else {
              this.soaUtils.createStatementAdjustmentOrder(adjustment);
            }
          }
        }
      });
      this.matter.isAdjustmentStatusModeFinal ? this.matter.finalStatementAdjustmentOrders = statementAdjustmentOrders : this.matter.interimStatementAdjustmentOrders = statementAdjustmentOrders;
    }
  }

  addNewBtnFocus(index: number) {
    this.soaUtils.setLastActiveRowId(jQuery(document.activeElement).closest('tr').attr('id'));

    let statementDisplayItems = this.soaUtils.orderedStatementDisplayItems;
    for (let i = 0; i < statementDisplayItems.length; i = i + 1) {
      if (statementDisplayItems[ i ] && statementDisplayItems[ i ].soaItem) {
        statementDisplayItems[ i ].soaItem.isSelected = false;
      }
    }
    if (statementDisplayItems[ index ] && statementDisplayItems[ index ].soaItem) {
      statementDisplayItems[ index ].soaItem.isSelected = true;
    }

  }

}
