<!-- modal -->
<form class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <h1 style="display: inline-block;">My Credentials</h1>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="row modal-body margin-right-0" [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">

        <div class="leftSide inline-block">
            <div class="col-lg-12 left-nav credentials-left-nav" tabindex="-1">
                <ul>
                    <li *ngFor="let section of unityCredentialSections" class="{{ (section.active ? 'active': 'inactive')}}">
                        <a (click)="openCredentialSection(section)" innerHtml="{{section.title}}"></a>
                    </li>
                </ul>
            </div>

        </div>
        <div class="rightSide inline-block">
            <div *ngIf="activeSection.isChangePassword()">
                <div class="col-lg-12 fields-group margin-bottom-0 padding-left-15 padding-right-15">
                    <div class="form-group main-level">
                        <span class="title min-height-30 text-left">Change Password</span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="col-lg-7">
                        <div class="form-group">
                            <label class="control-label col-lg-4">New Password</label>
                            <div class="col-lg-6">
                                <input type="password" id="newPwd" name="newPwd" #newPassword
                                       class="form-control focus-first"
                                       (keyup)="checkPassword($event)"
                                       fieldKey="user.password" [(ngModel)]="user.password"
                                       [dp-error-validator]="{elementFunction : checkNewPasswordValidity}"                        />
                            </div>
                        </div>
                        <div class="form-group" *ngIf="errorMessages && errorMessages.length > 0">
                            <div class="col-lg-4"></div>
                            <div class="col-lg-8">
                                <ul>
                                    <li class="padding-bottom-10" style="line-height: 15px;" *ngFor="let error of errorMessages">{{error}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-4">Re-enter Password</label>
                            <div class="col-lg-6">
                                <input type="password" id="password" name="rePwd" class="form-control" [(ngModel)]="user.retypePassword"
                                       fieldKey="user.retypePassword"
                                       [dp-error-validator]="{elementFunction : checkReEnterPasswordValidity}"
                                       (keyup)="checkReTypePassword($event)"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <p class="control-label text-left padding-top-0" style="line-height: 15px;">
                            Please make sure that the password has a minimum of 8 characters. No spaces allowed. Allowed characters are: A to
                            Z, a to z, 0 to 9 and special characters. The password cannot have more than 2 consecutive characters being the same letter, number
                            or special character- example - Mississauga#21 is acceptable but Baaad#14 is not acceptable.
                        </p>
                    </div>

                </div>
            </div>


            <div *ngIf="activeSection.isEmailAndUserId()">
                <div class="col-lg-12 fields-group margin-bottom-0 padding-left-15 padding-right-15">
                    <div class="form-group main-level">
                        <span class="title min-height-30 text-left">Email Address and User ID</span>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="col-lg-7">
                        <div class="form-group">
                            <label class="control-label col-lg-4">Email Address</label>
                            <div class="col-lg-6">
                                <input type="text" name="contactEmail" id="contactEmail" #emailField
                                       class="form-control" maxlength="50" [(ngModel)]="emailId"
                                       fieldKey="staffProfile.contact.email"
                                       [dp-error-validator]="{isEmail : true}"
                                       />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-4">User ID</label>
                            <div class="col-lg-6">
                                <input type="text" name="userId" class="form-control"
                                       fieldKey="user.id" [(ngModel)]="loginId"
                                       dp-error-validator />
                            </div>
                        </div>
                    </div>

                </div>


            </div>

            <div *ngIf="activeSection.isTrustedPhones()">
                <div class="col-lg-12 fields-group margin-bottom-0 padding-left-15 padding-right-15">
                    <div class="form-group main-level">
                        <span class="title min-height-30 text-left">Manage Trusted Phone Number(s)</span>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div *ngIf="trustedPhones && trustedPhones.length == 2" class="col-lg-12">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-lg-10 padding-bottom-10 padding-top-20 boldfont">
                            To add a new phone number you must first remove an exising number
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div *ngFor="let phone of trustedPhones; let i=index" class="form-group">
                            <div class="col-lg-2 text-right padding-top-10">
                                Trusted Phone {{i + 1}}
                            </div>
                            <div class="col-lg-4">
                                <input type="text" name="contactEmail"
                                       class="form-control"
                                       [dp-read-only]="true"
                                       value="******{{phone.telephoneNumber | slice: -4}}"

                                />
                            </div>
                            <div class="col-lg-3 padding-top-5 padding-left-30">
                                <i
                                   class="fa fa-trash font-20"
                                   [ngClass]="{'grey-text': newPhone.telephoneNumber}"
                                   (click)="confirmDeletePhoneNumber(phone)"
                                   aria-hidden="true"
                                   tabindex="0"
                                   (keyup.enter)="confirmDeletePhoneNumber(phone)"
                                >
                                </i>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="trustedPhones && trustedPhones.length < 2" class="col-lg-12">
                        <div class="col-lg-2">
                        </div>
                        <div class="col-lg-10 padding-bottom-10 padding-top-20 boldfont">
                            To add a phone number enter in "Add new" and select Send Passcode to verify
                        </div>
                    </div>

                    <div *ngIf="trustedPhones && trustedPhones.length < 2" class="col-lg-12">
                        <div class="form-group">
                            <div class="col-lg-2 text-right padding-top-10">
                                Add New
                            </div>
                            <div class="col-lg-4">
                                <input type="text" name="newPhoneNumber" id="newPhoneNumber" #newPhoneNo
                                       class="form-control"
                                       [dp-read-only]="isSendPasscodeTimeout"
                                       [(ngModel)]="newPhone.telephoneNumber"
                                       dp-phone-number
                                       fieldKey="user.newPhone"
                                       dp-error-validator
                                       (keyup)="showPasscode=false"
                                       (keyup.enter)="requestAuthChallenge()"
                                />
                            </div>
                            <div class="col-lg-3 padding-left-30">
                                <button [disabled]="isSendPasscodeTimeout || disableSendPasscodeButton" type="button" class="dp-btn"
                                        (click)="requestAuthChallenge()"
                                >Send Passcode</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="showPasscode && !disableSendPasscodeButton"  class="col-lg-12 padding-top-30">
                    <div class="col-lg-9 offset-lg-2 padding-bottom-7  padding-bottom-10">
                        <p class="padding-5 boldfont">A passcode has been sent via text to the phone number ending in {{newPhone.telephoneNumber | slice: -4}}.  Please enter it below and select
                            Submit Passcode.
                            If you did not receive the text, the Send Passcode option will be re-enabled shortly, and you will be able to select it to request another passcode.</p>
                    </div>
                    <div class="col-lg-12 padding-bottom-6 ">
                        <div class="form-group">
                            <div class="col-lg-2">
                            </div>
                            <div class="col-lg-2">
                                <input name="code" id="code" type="text"
                                       class="form-control"
                                       [(ngModel)]="passcode"
                                       fieldKey="user.passcode"
                                       dp-error-validator
                                       (keyup.enter)="verifyAuthChallenge()"
                                />
                            </div>
                            <div  class="col-lg-3 ">
                                <button *ngIf="passcode" type="button" class="dp-btn"
                                        (click)="verifyAuthChallenge()">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button *ngIf="activeSection.isChangePassword()" class="dp-btn" id="okBtn" type="button"
                    [disabled]="!user.password || !user.retypePassword"
                    (click)="updatePassword()">
                <span>Save Password</span>
            </button>
            <button *ngIf="activeSection.isEmailAndUserId()" id="okBtn" class="dp-btn" type="button"
                    (click)="updateEmailAndUser()">
                <span>Save Email & User ID</span>
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
