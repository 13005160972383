<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                    <h1>Adjustment Type : Taxes Paid During Occupancy</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="padding-left-100">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-middle"><label class="control-label">Yearly Tax portion of Occupancy fees</label></div>
                <div class="col-lg-4">
                    <input type="text"
                           id="taxPortionOccupancyFees"
                           name="taxPortionOccupancyFees"
                           class="form-control text-right"
                           [(ngModel)]="localExistingTaxPortion"
                           dp-currency
                           dp-default-currency
                           [dp-read-only]="isInterimOccupancyFeeSoaTaxesTotalGtZero"
                    />
                </div>
                <div *ngIf="isInterimOccupancyFeeSoaTaxesTotalGtZero" class="col-lg-1 padding-top-8"
                     title="Edit from Interim Occupancy Fees adjustment"><i class="fa fa-info-circle font-14" aria-hidden="true"></i></div>
            </div>

            <div class="padding-top-10" >
                <div class="col-lg-12 row">
                    <div class="col-lg-3">
                            <label class="control-label bold-font">
                                TAXES PAID DURING OCCUPANCY
                            </label>
                    </div>
                </div>
                <div class="col-lg-12 row">
                    <div class="col-lg-6">
                        <label class="control-label">Tax Portion of occupancy payment {{localExistingTaxPortion | currency:'CAD':'symbol-narrow'}}</label>
                    </div>

                </div>
                <div class="col-lg-12 row">
                    <div class="col-lg-3">
                        <label class="control-label text-left">Unit share from {{getOccupancyDate()  | date : 'MMMM dd, yyyy'}}</label>
                    </div>
                    <div class="col-lg-3  text-right"><br>

                    </div>
                </div>
                <div class="col-lg-12 row">
                    <div class="col-lg-3">
                        <label class="control-label text-left">to Final Closing ({{calculateDateDiffForTaxPortion()}} days)</label>
                    </div>
                    <div class="col-lg-3  text-right"><br>
                        <label class="control-label">{{calculateVendorShare() | currency:'CAD':'symbol-narrow'}}</label>
                    </div>
                </div>
                <div class="col-lg-12 row" >
                    <div class="col-lg-3">
                        <label class="control-label">Credit Purchaser</label>
                    </div>
                    <div class="col-lg-2"></div>

                    <div class="col-lg-4 text-right" >
                        <label class="control-label">{{calculateVendorShare() | currency:'CAD':'symbol-narrow'}}</label>
                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="ok()"
                        class="dp-btn keyboard-save-action">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
                <button type="button"
                        (click)="delete()"
                        *ngIf="allowDelete"
                        class="secondary-button">
                    <span>Delete</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
