import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import {MatterNotificationConfig} from '../manage-messaging-notifications/matter-notification-config/matter-notification-config';

export class ChecklistTemplateConfig extends BaseEntity {
  id: number;
  name: string;
  accountId: number;
  applicableMatterTypeCode: string;
  applicableProvinceCode: string;
  active: boolean;
  defaultTemplate: boolean;
  categories: ChecklistCategory[];
  isDirty: boolean = false;

  constructor(checklistTemplate?: ChecklistTemplateConfig) {
    super(checklistTemplate);
    if (checklistTemplate) {
      if (checklistTemplate.categories) {
        this.categories = [];
        checklistTemplate.categories.forEach((item) => {
          this.categories.push(new ChecklistCategory(item));
        });
      }
    } else {
      this.categories = [];
    }
  }

  get activeText(): string {
    return this.active ? 'Yes' : 'No';
  }

  get defaultText(): string {
    return this.defaultTemplate ? 'Yes' : '';
  }
}

export class ChecklistCategory extends BaseEntity {
  id: number;
  name: string;
  tasks: ChecklistTask[];
  tasksNotificationConfig: MatterNotificationConfig[];
  order: number;

  constructor(checklistCategory?: ChecklistCategory) {
    super(checklistCategory);
    if (checklistCategory) {
      this.tasks = [];
      if (checklistCategory.tasks) {
        checklistCategory.tasks.forEach((item) => {
          this.tasks.push(new ChecklistTask(item));
        });
      }
      this.tasksNotificationConfig = [];
      if (checklistCategory.tasksNotificationConfig) {
        checklistCategory.tasksNotificationConfig.forEach((item) => {
          this.tasksNotificationConfig.push(new MatterNotificationConfig(item));
        });
      }
    } else {
      this.tasks = [];
    }

  }

  isAnyNotificationActive(taskId: number): boolean {
    return this.tasksNotificationConfig && this.tasksNotificationConfig.some(item => taskId && !!item.active && item.taskIdentifier == taskId);
  }

}

export class ChecklistTask extends BaseEntity {
  id: number;
  name: string;
  order: number;

  constructor(checklistTask?: ChecklistTask) {
    super(checklistTask);
  }
}

export class ChecklistWrapperItem {
  id: number;
  parentItem: ChecklistWrapperItem;
  order: number;
  name: string;
  expanded: boolean;
  visible: boolean = true;
  burgerMenuItems: BurgerMenuExtendedItem[] = [];
  isEditMode: boolean;
  isAnyNotificationActive: boolean;

  isCategory(): boolean {
    return !this.parentItem;
  }

  isTask(): boolean {
    return !!this.parentItem;
  }
}
