export const TeranetInstrumentMenuActions = {
  VIEW_INST_LABEL: 'View Instrument',
  REQUEST_INST_LABEL: 'Request Instrument',
  COURIER_INST_LABEL: 'Courier Instrument',
  REQUISITION_INST_LABEL: 'Requisition Selected Instrument',
  EDIT_REQUISITION_LABEL: 'Edit Requisition',
  DELETE_REQUISITION_LABEL: 'Delete Requisition',
  VIEW_ATTACHMENT_LABEL: 'View Attachment'
};

export const SpinInstrumentMenuActions = {
  IMPORT_EXISTING_MORTGAGE_LABEL: 'Import into Existing Mortgages Page',
  IMPORT_EXISTING_MORTGAGE_TO_BE_DISCHARGED_LABEL: 'to be discharged',
  IMPORT_EXISTING_MORTGAGE_TO_REMAIN_LABEL: 'to remain',
  IMPORT_EXISTING_MORTGAGE_TO_BE_ASSUMED_LABEL: 'to be assumed',
  ADD_PERMITTED_REGISTRATION_LABEL: 'Add as Permitted Registration',
  ADD_UNDERTAKING_LABEL: 'Add as Undertaking on Undertaking Page',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

export const LtsaInstrumentMenuActions = {
  REMOVE: 'Remove',
  LEAVE: 'Leave',
  EDIT: 'Edit',
  DELETE: 'Delete'
};

export const TeranetWritMenuActions = {
  VIEW_WRIT_EXECUTION_LABEL: 'View Execution Certificate',
  VIEW_WRIT_LABEL: 'View Writ Detail',
  REDO_SEARCH_LABEL: 'Redo Search',
  DECLARATION_LABEL: 'Declaration(s)',
  ADDITIONAL_PARAGRAPH_LABEL: 'Additional Paragraph',
  BLANK_PARAGRAPH_LABEL: 'Blank Paragraph',
  EDIT_PARAGRAPH_LABEL: 'Edit Paragraph'
};

export const AdjudicationListTableMenuActions = {
  OPEN: 'Open',
  COMPLETE_ADJUDICATION: 'Complete Adjudication'
};

export const ContactListTableMenuActions = {
  OPEN: 'Open',
  COPY: 'Copy',
  EDIT_AS_PRIVATE_COPY: 'Edit as Private Copy',
  EDIT_JURISDICTION_AS_PRIVATE_COPY: 'Edit Jurisdiction as Private Copy'
};

export const RelationshipsTableMenuActions = {
  OPEN: 'Open',
  REMOVE: 'Remove Relationship'
};
