// Route : ['FieldCode1', 'FieldCode2']

export const FieldCodeRouteMapping = [
  {route: 'matterOpening', fieldCodes: [ 'M10001', 'M10002' ]},
  {route: 'purchaser', fieldCodes: [ 'P20001', 'P20002' ]},
  {route: 'mortgages/mortgage', fieldCodes: [ 'M40001' ]},
  {route: 'mortgages/mortgage', fieldCodes: [ 'M40002' ]},
  {route: 'mortgages/mortgage', fieldCodes: [ 'M40003' ]},
  {route: 'mortgages/mortgage', fieldCodes: [ 'M40004' ]},
  {route: 'mortgages/mortgage', fieldCodes: [ 'M40005' ]},
  {route: 'statementOfAdjustments', fieldCodes: [ 'M50001' ]},
  {route: 'statementOfAdjustments', fieldCodes: [ 'M50002' ]},
  {route: 'statementOfAdjustments', fieldCodes: [ 'M50003' ]},
  {route: 'statementOfAdjustments', fieldCodes: [ 'M50004' ]},
  {route: 'statementOfAdjustments', fieldCodes: [ 'M50005' ]},
  {route: 'statementOfAdjustments', fieldCodes: [ 'M50006' ]},
  {route: 'titleInsurance', fieldCodes: [ 'M220001' ]}
];
