<div class="container-fluid custom-modal-container">
    <div class="col-lg-12 form-group">
        <div class="col-lg-1">
        </div>
        <div class="col-lg-5 text-center padding-left-15">
            <dp-checkbox fieldId="copyToNotesCheckbox">
                <input type="checkbox"
                       id="copyToNotesCheckbox"
                       (change)="copyAllDealDataToNotes($event)"
                />
            </dp-checkbox>
            <label class="control-label padding-left-10 display-inline" for="copyToNotesCheckbox">Copy deal data to Matter Opening sticky notes.</label>
        </div>
        <div class="col-lg-2">
            <div class="form-group main-level">
                <button id="openStickyNotes"
                        type="button"
                        class="function-icons"
                        (click)="openStickyNotesModal()">
                    <i class="svg-icon sticky-note pointerCursor"></i>
                </button>
                <h6 class="h6-sticky-notes">Sticky Notes</h6>
            </div>
        </div>
    </div>
    <div class="row modal-body form-horizontal">
        <div class="col-lg-12 form-group" style="border-bottom: 2px solid #cccccc">
            <div class="col-lg-1">
            </div>
            <div class="col-lg-4">
                <div class="form-group main-level">
                    <label class="control-label">
                        Referral Data
                    </label>
                </div>
            </div>
            <div class="col-lg-1 text-center padding-left-10">
                <dp-checkbox fieldId="allFields" fieldClass="top-8">
                    <input type="checkbox"
                           id="allFields"
                           [(ngModel)]="copyAllFieldsChecked"
                           (change)="copyAllMatterFields($event)"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-4">
                <div class="form-group main-level">
                    <label class="control-label">Matter Data</label>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12 form-group padding-top-10">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Solicitor Name</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="receivingSolicitorNameReferral"
                   name="receivingSolicitorNameReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.receivingSolicitorName"
                   class="form-control"/>
        </div>
        <div class="col-lg-1 text-center padding-left-15">
            <dp-checkbox fieldId="receivingSolicitorNameCheckbox" fieldClass="top-8">
                <input type="checkbox"
                       id="receivingSolicitorNameCheckbox"
                       [checked]="copyAllFieldsChecked"
                       (change)="copySolicitorName($event)"
                />
            </dp-checkbox>
        </div>
        <div class="col-lg-4">
            <select type="text"
                    id="solicitorsDropDown"
                    name="solicitorsDropDown"
                    class="form-control"
                    [(ngModel)]="matter.selectedSolicitorId"
                    (ngModelChange)="onSolicitorChange($event)">
                <option *ngFor="let solicitor of solicitorsDropDownList" [value]="solicitor.value">
                    {{solicitor.label}}
                </option>
            </select>
        </div>
    </div>

    <div class="col-lg-12 form-group padding-top-10">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Closing Date</label>
        </div>
        <div class="col-lg-3">
            <dp-partial-date id="closingDateReferral"
                             [inputDate]="referralForm.closingDate"
                             [dateFormat]="'MMM DD YYYY'"
                             [disableAll]="true"
                             [fieldKey]="'closingDateReferral'">
            </dp-partial-date>
        </div>
        <div class="col-lg-1 text-center padding-left-15">
            <dp-checkbox fieldId="closingDateC" fieldClass="top-8">
                <input type="checkbox"
                       [checked]="copyAllFieldsChecked"
                       (change)="copyClosingDate($event)"
                       id="closingDateC"
                />
            </dp-checkbox>
        </div>
        <div class="col-lg-4">
            <dp-partial-date id="closingDateMatter"
                             [inputDate]="matter.matterCloseDate"
                             [dateFormat]="'MMM DD YYYY'"
                             (dateChange)="onClosingDateChange($event)"
                             [fieldKey]="'modal.referral.matterCloseDate'"
                             dp-error-validator>
            </dp-partial-date>
        </div>
    </div>

    <div class="col-lg-12 form-group padding-top-10">
        <dp-accordion [expanded]="true" [secondary]="true">
            <div class="dp-accordion-header">
                <div class="row  row-group">
                    <label class="control-label col-lg-3 text-left padding-left-60">Property Address</label>
                </div>
            </div>
            <div class="dp-accordion-content row">
                <div class="col-lg-12 form-group padding-top-10">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Address 1</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="address1Referral"
                               name="address1Referral"
                               [dp-read-only]="true"
                               [ngModel]="referralPropertyAddress.addressLine1"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="address1Checkbox" fieldClass="top-8">
                            <input type="checkbox"
                                   id="address1Checkbox"
                                   [checked]="copyAllFieldsChecked"
                                   (change)="copyPropertyAddressField($event, 'addressLine1')"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="address1Matter"
                               name="address1Matter"
                               [(ngModel)]="matterPropertyAddress.addressLine1"
                               class="form-control"/>
                    </div>
                </div>

                <div class="col-lg-12 form-group padding-top-10">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Address 2</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="address2Referral"
                               name="address2Referral"
                               [dp-read-only]="true"
                               [ngModel]="referralPropertyAddress.addressLine2"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="address2Checkbox" fieldClass="top-8">
                            <input type="checkbox"
                                   id="address2Checkbox"
                                   [checked]="copyAllFieldsChecked"
                                   (change)="copyPropertyAddressField($event, 'addressLine2')"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="address2Matter"
                               name="address2Matter"
                               [(ngModel)]="matterPropertyAddress.addressLine2"
                               class="form-control"/>
                    </div>

                </div>

                <div class="col-lg-12 form-group padding-top-10">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">City</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="referralCity"
                               name="referralCity"
                               [dp-read-only]="true"
                               [ngModel]="referralPropertyAddress.city"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="cityCheckbox" fieldClass="top-8">
                            <input type="checkbox"
                                   id="cityCheckbox"
                                   [checked]="copyAllFieldsChecked"
                                   (change)="copyPropertyAddressField($event, 'city')"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="matterCity"
                               name="matterCity"
                               [(ngModel)]="matterPropertyAddress.city"
                               class="form-control"/>
                    </div>
                </div>

                <div class="col-lg-12 form-group padding-top-10">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Province</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="referralProvince"
                               name="referralProvince"
                               [dp-read-only]="true"
                               [ngModel]="referralPropertyAddress.provinceCode"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="ProvinceCheckbox" fieldClass="top-8">
                            <input type="checkbox"
                                   id="ProvinceCheckbox"
                                   [checked]="copyAllFieldsChecked"
                                   (change)="copyPropertyAddressField($event, 'provinceCode')"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="matterProvince"
                                name="matterProvince"
                                [(ngModel)]="matterPropertyAddress.provinceCode"
                                (change)="updateProvinceData()"
                        >
                            <!--　province.label is for provinceName. province.value is for provinceCode which the ngModel value and view value are both province.value-->
                            <option *ngFor="let province of provinceOptions"
                                    [value]="province.value">{{province.value}}
                            </option>
                        </select>

                    </div>
                </div>

                <div class="col-lg-12 form-group padding-top-10">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Postal Code</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="referralPostalCode"
                               name="referralPostalCode"
                               [dp-read-only]="true"
                               [ngModel]="referralPropertyAddress.postalCode"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="PostalCodeCheckbox" fieldClass="top-8">
                            <input type="checkbox"
                                   id="PostalCodeCheckbox"
                                   [checked]="copyAllFieldsChecked"
                                   (change)="copyPropertyAddressField($event, 'postalCode')"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="matterPostalCode"
                               name="matterPostalCode"
                               [(ngModel)]="matterPropertyAddress.postalCode"
                               class="form-control"/>
                    </div>

                </div>

                <div class="col-lg-12 form-group padding-top-10">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Country</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               id="referralCountry"
                               name="referralCountry"
                               [dp-read-only]="true"
                               [ngModel]="referralPropertyAddress.country"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-1 text-center padding-left-15">
                        <dp-checkbox fieldId="CountryCheckbox" fieldClass="top-8">
                            <input type="checkbox"
                                   id="CountryCheckbox"
                                   [checked]="copyAllFieldsChecked"
                                   (change)="copyPropertyAddressField($event, 'country')"
                            />
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-4">
                        <input type="text"
                               id="matterCountry"
                               name="matterCountry"
                               [(ngModel)]="matterPropertyAddress.country"
                               class="form-control"/>
                    </div>
                </div>
            </div>
        </dp-accordion>
    </div>

    <div class="col-lg-12 form-group padding-top-10">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Transaction Type</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="transactionTypeReferral"
                   name="transactionTypeReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.transactionType"
                   class="form-control"/>
        </div>
    </div>

    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">{{txnType}} is of</label>
        </div>
        <div class="col-lg-3">
            <select class="form-control"
                    id="purchaseTypeReferral"
                    name="purchaseTypeReferral"
                    [dp-read-only]="true"
                    [ngModel]="referralForm.purchaseType">
                <option *ngFor="let referralPurchaseOfOption of referralPurchaseOfOptions" [value]="referralPurchaseOfOption.value">
                    {{referralPurchaseOfOption.label}}
                </option>
            </select>
        </div>
        <div class="col-lg-1 text-center padding-left-15">
            <dp-checkbox fieldId="purchaseTypeCheckbox" fieldClass="top-8">
                <input type="checkbox"
                       id="purchaseTypeCheckbox"
                       [checked]="copyAllFieldsChecked"
                       (change)="copyPurchaseType($event)"
                />
            </dp-checkbox>
        </div>
        <div class="col-lg-4">
            <select class="form-control"
                    id="purchaseTypeMatter"
                    name="purchaseTypeMatter"
                    [ngModel]="matter.matterPropertyWithCondo.purchaseIsOfCode">
                <option *ngFor="let purchaseType of purchaseTypes" [value]="purchaseType.value">
                    {{purchaseType.label}}
                </option>
            </select>
        </div>
    </div>

    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Purchase Price</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="purchasePriceReferral"
                   name="purchasePriceReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.purchasePrice"
                   dp-default-currency
                   dp-currency
                   class="form-control"/>
        </div>
        <ng-container *ngIf="isPurchasePriceApplicable()">
            <div class="col-lg-1 text-center padding-left-15">
                <dp-checkbox fieldId="purchasePriceCheckbox" fieldClass="top-8">
                    <input type="checkbox"
                           id="purchasePriceCheckbox"
                           [checked]="copyAllFieldsChecked"
                           (change)="copyPurchasePrice($event, 'purchasePrice')"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-4" *ngIf="matter.considerationLtt && matter.considerationLtt.salePriceAdjustment">
                <input type="text"
                       id="purchasePriceMatter"
                       name="purchasePriceMatter"
                       [(ngModel)]="matter.considerationLtt.salePriceAdjustment.agreementSalePrice"
                       dp-default-currency
                       dp-currency
                       class="form-control"/>
            </div>
        </ng-container>
        <ng-container *ngIf="!isPurchasePriceApplicable()">
            <div class="col-lg-4 offset-lg-1 vertical-align-label">
                <label class="control-label text-left">Sale price info already exists. Changes must be made on matter.</label>
            </div>
        </ng-container>
    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Deposit Amount</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="depositAmountReferral"
                   name="depositAmountReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.depositAmount"
                   dp-default-currency
                   dp-currency
                   class="form-control"/>
        </div>
        <ng-container *ngIf="isDepositAmountApplicable()">
            <div class="col-lg-1 text-center padding-left-15">
                <dp-checkbox fieldId="depositAmountCheckbox" fieldClass="top-8">
                    <input type="checkbox"
                           id="depositAmountCheckbox"
                           [checked]="copyAllFieldsChecked"
                           (change)="copyDepositAmount($event, 'depositAmount')"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-4">
                <input type="text"
                       id="depositAmountMatter"
                       name="depositAmountMatter"
                       [(ngModel)]="matter.matterPropertyWithCondo.depositAmount"
                       dp-default-currency
                       dp-currency
                       class="form-control"/>
            </div>
        </ng-container>

        <ng-container *ngIf="!isDepositAmountApplicable()">
            <div class="col-lg-4 offset-lg-1 vertical-align-label">
                <label class="control-label text-left">Deposit info already exists. Changes must be made on matter.</label>
            </div>
        </ng-container>

    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Other Solicitor</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="otherSolicitorReferral"
                   name="otherSolicitorReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.otherSolicitorName"
                   class="form-control"/>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-4" *ngIf="!matter.otherPartySolicitor">
            <p-autoComplete
                id="solicitor"
                name="solicitor"
                #solicitor
                [delay]="700"
                [placeholder]="otherSolicitorNameHelpText"
                [(ngModel)]="selectedOtherSideSolicitor"
                (ngModelChange)="onOtherSolicitorChange($event)"
                [class.autocomplete-searching]="otherSideSolicitorsLoading"
                (onSelect)="dataOtherSelectedSolicitor()"
                (focusout)="onLeavingSolicitorOmniBarWithoutFreeInput()"
                [suggestions]="otherSideSolicitorOptions"
                [class.dropdown-arrow-refferedBy]="true"
                [dropdown]="selectedFirmHasSolicitors"
                (onDropdownClick)="handleDropDownClickOtherSideSolicitorPickList($event)"
                (completeMethod)="searchOtherSideSolicitor($event)"
                field="firstLastNames"
                [minLength]="0"
                scrollHeight="530px"
                autocomplete="off"
                [ngClass]="{'autocomplete-search-icon': !selectedFirmHasSolicitors && !manualEntryOfSolicitor}"
            >
                <ng-template let-solicitor pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [ngClass]="{'bold-font' : isAddNew(solicitor)}"
                        [disableItem]="solicitor">
                        <div *ngIf="solicitor.instanceCreationTime">
                            <span class="col-lg-3 text-overflow">{{solicitor.fullName}}</span>
                            <span class="col-lg-2 text-overflow">{{solicitor.firmName}}</span>
                            <span class="col-lg-5 text-overflow">{{solicitor.mailingAddress?.addressTextWithProvinceName}}</span>
                        </div>
                        <div *ngIf="!solicitor.instanceCreationTime">
                            <span class="col-lg-6 text-overflow">{{solicitor.displayName}}</span>
                        </div>
                        <span class="col-lg-2 text-overflow">{{solicitor.contactName?.initials}}</span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>

        <ng-container *ngIf="matter.otherPartySolicitor">
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       dp-read-only="true"
                       [value]="getContactDisplayName(matter.otherPartySolicitor)"
                       autocomplete="off"/>
                <div class="actionbtns">
                    <div class="width-20">
                        <span>
                            <i class="fa fa-trash padding-top-8" (click)="deleteOtherPartySolicitor()" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Other Law Firm</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="otherLawFirmNameReferral"
                   name="otherLawFirmNameReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.otherLawFirmName"
                   class="form-control"/>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-4" *ngIf="!matter.otherPartyLawFirm">
            <p-autoComplete
                id="lawFirm"
                name="lawFirm"
                maxlength="200"
                inputStyleClass="law-firm-autocomplete"
                placeholder="Search by Name, Address or Add new"
                [class.autocomplete-searching]="otherSideLawFirmsLoading"
                [suggestions]="otherSideLawFirmOptions"
                (completeMethod)="searchOtherSideLawFirm($event)"
                field="legalFirmName"
                (onSelect)="dataSelectedOtherSideLawFirm()"
                [(ngModel)]="selectedOtherSideLawFirm"
                (focusout)="onLeavingLawFirmOmniBarWithoutFreeInput()"
                scrollHeight="530px"
                autocomplete="off" class="autocomplete-search-icon">
                <ng-template let-lawfirm pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [ngClass]="{'bold-font' : isAddNew(lawfirm)}"
                        [disableItem]="lawfirm">
                        <div *ngIf="lawfirm.instanceCreationTime">
                            <span class="col-lg-6 text-overflow">{{lawfirm.legalFirmName}}</span>
                            <span class="col-lg-6 text-overflow">{{lawfirm.mailingAddress?.addressTextWithProvinceName}}</span>
                        </div>
                        <div *ngIf="!lawfirm.instanceCreationTime">
                            <span class="col-lg-6 text-overflow">{{lawfirm.displayName}}</span>
                        </div>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
        <ng-container *ngIf="matter.otherPartyLawFirm">
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       dp-read-only="true"
                       [value]="getContactDisplayName(matter.otherPartyLawFirm)"
                       autocomplete="off"/>
                <div class="actionbtns">
                    <div class="width-20">
                            <span>
                                <i class="fa fa-trash padding-top-8" (click)="deleteOtherLawFirm()" aria-hidden="true"></i>
                            </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Real Estate Agent</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="realEstateAgentNameReferral"
                   name="realEstateAgentNameReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.realEstateAgentName"
                   class="form-control"/>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-4" *ngIf="!matter.realEstateAgentParticipant">
            <p-autoComplete
                id="realEstateAgent-autocomplete"
                name="realEstateAgent-autocomplete"
                placeholder='Search by "Surname, First Name", Email Address or Add new'
                [(ngModel)]="selectedRealEstateAgent"
                [class.autocomplete-searching]="realEstateAgentsLoading"
                [suggestions]="realEstateAgents"
                (completeMethod)="searchRealEstateAgent($event)"
                field="surnameLastFullName"
                maxlength="100"
                scrollHeight="550px"
                (onSelect)="dataSelectedRealEstateAgent()"
                [minLength]="0"
                (focusout)="onLeavingRealEstateAgentOmniBarWithoutFreeInput()"
                autocomplete="off"
                delay="1000" class="autocomplete-search-icon">

                <ng-template let-realEstateAgent pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [disableItem]="realEstateAgent"
                        [ngClass]="{'font-style-italic': realEstateAgent?.fullName == constantNoResultValue,
                                                'bold-font' : isAddNew(realEstateAgent) }">
                                                <span class="col-lg-4 text-overflow"
                                                      *ngIf="realEstateAgent.displayName != null">
                                                        {{realEstateAgent.displayName }}</span>
                        <span class="col-lg-4  text-overflow"
                              *ngIf="realEstateAgent.fullName != null && realEstateAgent.displayName!=realEstateAgent.fullName">
                                                        {{realEstateAgent.fullName}}</span>
                        <span class="col-lg-3  text-overflow">
                                                        {{realEstateAgent.parentOrganizationName}}</span>
                        <span class="col-lg-4  text-overflow">
                                                        {{realEstateAgent.email}}</span>
                    </li>
                </ng-template>

            </p-autoComplete>
        </div>
        <ng-container *ngIf="matter.realEstateAgentParticipant">
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       dp-read-only="true"
                       [value]="getRealAgentContactDisplayName(matter.realEstateAgentParticipant)"
                       autocomplete="off"/>
                <div class="actionbtns">
                    <div class="width-20">
                            <span>
                                <i class="fa fa-trash padding-top-8" (click)="deleteRealEstateAgent()" aria-hidden="true"></i>
                            </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Real Estate Brokerage</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="realEstateBrokerageFirmNameReferral"
                   name="realEstateBrokerageFirmNameReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.realEstateBrokerageFirmName"
                   class="form-control"/>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-4" *ngIf="!realEstateBroker  && !isRealEstateBrokerReadOnly">
            <p-autoComplete id="realEstateBroker-autocomplete"
                            name="realEstateBroker-autocomplete"
                            placeholder="Search by Name, Address or Add new"
                            [(ngModel)]="selectedRealEstateBroker"
                            (ngModelChange)="onSelectedRealEstateBrokerChange($event)"
                            [class.autocomplete-searching]="realEstateBrokersLoading"
                            [suggestions]="realEstateBrokers"
                            (completeMethod)="searchRealEstateBroker($event)"
                            field="organizationName"
                            maxlength="100"
                            scrollHeight="550px"
                            (focusout)="onLeavingRealEstateBrokerOmniBarWithoutFreeInput($event)"
                            [minLength]="0"
                            delay="1000" class="autocomplete-search-icon">
                <ng-template let-realEstateBroker pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [disableItem]="realEstateBroker"
                        [ngClass]="{'font-style-italic': realEstateBroker?.displayName  == constantNoResultValue || realEstateBroker?.organizationName == constantNoResultValue ,
                                            'bold-font' : isAddNew(realEstateBroker)}">
                                             <span class="col-lg-12 text-overflow"
                                                   *ngIf="realEstateBrokerAutoCompleteEmptyFlag == true">{{ realEstateBroker.displayName }}</span>
                        <span class="col-lg-6 text-overflow"
                              *ngIf="realEstateBrokerAutoCompleteEmptyFlag == false">
                                                {{ realEstateBroker.displayName ? realEstateBroker.displayName : realEstateBroker.organizationName }}
                                            </span>
                        <span class="col-lg-6 text-overflow"
                              *ngIf="realEstateBrokerAutoCompleteEmptyFlag == false">
                                                {{getContactDisplayMailingAddress(realEstateBroker)}}
                                            </span>

                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
        <ng-container *ngIf="realEstateBroker || isRealEstateBrokerReadOnly">
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       dp-read-only="true"
                       [value]="realEstateBroker"
                       autocomplete="off"/>
                <div class="actionbtns" *ngIf="!isRealEstateBrokerReadOnly">
                    <div class="width-20">
                        <span>
                            <i class="fa fa-trash padding-top-8" (click)="deleteRealEstateBroker()" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isSale">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Total Commissions</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="totalCommissionsReferral"
                   name="totalCommissionsNameReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.totalCommissionAmount"
                   dp-default-currency
                   dp-currency
                   class="form-control"/>
        </div>
        <ng-container *ngIf="isTotalCommissionApplicable()">
            <div class="col-lg-1 text-center padding-left-15">
                <dp-checkbox fieldId="totalCommissionAmountCheckbox" fieldClass="top-8">
                    <input type="checkbox"
                           id="totalCommissionAmountCheckbox"
                           [checked]="copyAllFieldsChecked"
                           (change)="copyTotalCommissions($event, 'principal')"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-4">
                <input type="text"
                       id="totalCommissionAmountMatter"
                       name="totalCommissionAmountMatter"
                       [(ngModel)]="matter.brokerCommission.commissionBeforeHst"
                       dp-default-currency
                       dp-currency
                       class="form-control"/>
            </div>
        </ng-container>
        <ng-container *ngIf="!isTotalCommissionApplicable() && matter.isSale">
            <div class="col-lg-4 offset-lg-1 vertical-align-label">
                <label class="control-label text-left">Commission info already exists. Changes must be made on matter.</label>
            </div>
        </ng-container>
    </div>
    <div class="col-lg-12 form-group padding-top-10" *ngIf="matter.isPurchase || matter.isMortgage">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">New Mortgage Amount</label>
        </div>
        <div class="col-lg-3">
            <input type="text"
                   id="newMortgageAmountReferral"
                   name="newMortgageAmountReferral"
                   [dp-read-only]="true"
                   [ngModel]="referralForm.newMortgageAmount"
                   dp-default-currency
                   dp-currency
                   class="form-control"/>
        </div>

        <ng-container *ngIf="!isMortgageAlreadyExist()">
            <div class="col-lg-1 text-center padding-left-15" *ngIf="matterMortgage">
                <dp-checkbox fieldId="principalCheckbox" fieldClass="top-8">
                    <input type="checkbox"
                           id="principalCheckbox"
                           [checked]="copyAllFieldsChecked"
                           (change)="copyPrincipal($event, 'principal')"
                    />
                </dp-checkbox>
            </div>
            <ng-container *ngIf="!matterMortgage">
                <div class="col-lg-1"></div>
                <div class="col-lg-3">
                    <select id="mortgage"
                            name="mortggage"
                            class="form-control"
                            [(ngModel)]="referralMortgageWrapper.selectedMortgageOption"
                            (change)="updateSelectedMortgage()"
                    >
                        <option *ngFor="let mortgageOption of mortgageOptions" [value]="mortgageOption.value">
                            {{mortgageOption.label}}
                        </option>
                    </select>
                </div>
            </ng-container>
            <div class="col-lg-3" *ngIf="matterMortgage">
                <input type="text"
                       id="selectedMortgageDescription"
                       name="selectedMortgageDescription"
                       class="form-control"
                       [ngModel]="referralMortgageWrapper.selectedMortgageDescription"
                       [dp-read-only]="true"
                />
                <div class="actionbtns">
                    <div class="width-20">
                                <span>
                                    <i class="fa fa-trash padding-top-8" (click)="removeSelectedMortgage()" aria-hidden="true"></i>
                                </span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="matterMortgage">
                <div class="col-lg-3">
                    <input type="text"
                           id="principalMatter"
                           name="principalMatter"
                           [(ngModel)]="matterMortgage.mortgageTerm.principal"
                           dp-default-currency
                           dp-currency
                           class="form-control"/>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isMortgageAlreadyExist()">
            <div class="col-lg-4 offset-lg-1 vertical-align-label">
                <label class="control-label text-left">Mortgage {{originalMatter.mortgages.length > 1 ? '(s)' : ''}} already exists. Changes must be made on matter.</label>
            </div>
        </ng-container>
    </div>


</div>
