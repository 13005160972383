import {Contact} from '../../matters/shared/contact';
import {ContactSuggestionSalutationInterface} from './contact-suggestion-salutation-interface';
import {MatterParticipantWrapper} from '../../matters/shared/matter-participant-wrapper';
import {ContactSuggestionBase} from './contact-suggestion-base';
import {
  EnvelopeSalutationFormats,
  EnvelopeSalutationFormatType
} from '../../admin/document-profile/document-profile-edit/misc/EnvelopeSalutationFormats';
import {ContactSuggestion} from './contact-suggestion';
import {ContactName} from '../../matters';
import {AmpPlusSpace, AndPlusSpace, TitleMiss, TitleMr, TitleMrs, TitleMs} from '../../shared-main/constants';
import {GenderTypes} from '../contact-type-mapping';
import {MatterParticipantUtil} from '../../matters/shared/matter-utils/matter-participant-util';
import Utils from '../../shared-main/utils';
import {ContactSuggestionSalutationEnum} from './contact-suggestion-salutation-enum.enum';

export class ContactSuggestionSalutation extends ContactSuggestionBase implements ContactSuggestionSalutationInterface {

  initSuggestions(contact: Contact): Map<EnvelopeSalutationFormatType, string> {
    let result = new Map<EnvelopeSalutationFormatType, string>();

    let firstLetterFirstName = contact.contactName.firstName ? contact.contactName.firstName[ 0 ] + '. ' : '';
    let firstName = contact.contactName.firstName ? contact.contactName.firstName + ' ' : '';
    let lastName = contact.contactName.lastName ? contact.contactName.lastName : '';
    let middleName = contact.contactName.middleName ? contact.contactName.middleName + ' ' : '';
    let firstLetterMiddleName = contact.contactName.middleName ? contact.contactName.middleName[ 0 ] + '. ' : '';

    if (!contact.contactName.lastName) {
      firstName = firstName.trim();
      firstLetterFirstName = firstLetterFirstName.trim();
      middleName = middleName.trim();
    }

    switch (contact.gender) {
      case 'MALE':
        if (contact.contactName.firstName || contact.contactName.lastName) {
          // result.push(`Mr. ${firstLetterFirstName}${lastName}`);
          // result.push(`Mr. ${firstName}${lastName}`);
          // result.push(`${firstName}${lastName}`);
          // result = _.uniq(result); move to getSuggestionsArrayFormMap()

          result.set(EnvelopeSalutationFormats.MR_FLFN_LN, `Mr. ${ firstLetterFirstName }${ lastName }`);
          result.set(EnvelopeSalutationFormats.MR_FN_LN, `Mr. ${ firstName }${ lastName }`);
          result.set(EnvelopeSalutationFormats.FN_LN, `${ firstName }${ lastName }`);
        }
        break;
      case 'FEMALE':
        if (contact.contactName.firstName || contact.contactName.lastName) {
          // ['Ms.', 'Mrs.', 'Miss'].forEach((title) => {
          //     result.push(`${title} ${firstLetterFirstName}${lastName}`);
          //     result.push(`${title} ${firstName}${lastName}`);
          // });
          // result.push(`${firstName}${lastName}`); move to getSuggestionsArrayFormMap()

          result.set(EnvelopeSalutationFormats.MS_FLFN_LN, `Ms. ${ firstLetterFirstName }${ lastName }`);
          result.set(EnvelopeSalutationFormats.MS_FN_LN, `Ms. ${ firstName }${ lastName }`);

          result.set(EnvelopeSalutationFormats.MRS_FLFN_LN, `Mrs. ${ firstLetterFirstName }${ lastName }`);
          result.set(EnvelopeSalutationFormats.MRS_FN_LN, `Mrs. ${ firstName }${ lastName }`);

          result.set(EnvelopeSalutationFormats.MISS_FLFN_LN, `Miss ${ firstLetterFirstName }${ lastName }`);
          result.set(EnvelopeSalutationFormats.MISS_FN_LN, `Miss ${ firstName }${ lastName }`);

          result.set(EnvelopeSalutationFormats.FN_LN, `${ firstName }${ lastName }`);

        }
        break;
      case 'MALEPOA':
      case 'FEMALEPOA':
        //result.push(`${firstName}${lastName}`);
        result.set(EnvelopeSalutationFormats.FN_LN, `${ firstName }${ lastName }`);
        break;

      case 'QUESTION':
        if (contact.contactName.middleName) {
          //result.push(`${firstName}${firstLetterMiddleName}${lastName}`);
          result.set(EnvelopeSalutationFormats.FN_FLMN_LN, `${ firstName }${ firstLetterMiddleName }${ lastName }`);
        } else {
          //result.push(`${firstName}${lastName}`);
          result.set(EnvelopeSalutationFormats.FN_LN, `${ firstName }${ lastName }`);
        }
        break;
      case 'ESTATE':
        //result.push(`Estate of ${firstName}${lastName}`);
        result.set(EnvelopeSalutationFormats.ESTATE_OF_FN_LN, `Estate of ${ firstName }${ lastName }`);
        break;
      case 'CORPORATION':
        if (contact.organizationName)
          //result.push(`${contact.organizationName}`);
        {
          result.set(EnvelopeSalutationFormats.ORGANIZATION_NAME, `${ contact.organizationName }`);
        }
        break;
      default:
        break;
    }
    return result;
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be male and female
   *  The following rules will be applied when there are 2 clients with same last name:
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  female client with a first name of "Jane Mary", middle name of "Eva" and last name "Doe"
   *   Mr. J. Doe & Ms. J. Doe
   *   Mr. J. Doe & Ms. J. Doe
   *   Mr. J. Doe and Ms. J. Doe
   *   Mr. J. Doe & Mrs. J. Doe
   *   Mr. J. Doe and Mrs. J. Doe
   *   Mr. & Ms. J. Doe
   *   Mr. and Ms. J. Doe
   *   Mr. & Mrs. J. Doe
   *   Mr. and Mrs. J. Doe
   *   Mr. & Ms. J. & J. Doe
   *   Mr. and Ms. J. & J. Doe
   *   Mr. and Ms. J. and J. Doe
   *   Mr. & Mrs. J. & J. Doe
   *   Mr. and Mrs. J. & J. Doe
   *   Mr. and Mrs. J. and J. Doe
   *   Mr. & Ms. John Richard & Jane Mary Doe
   *   Mr. and Ms. John Richard & Jane Mary Doe
   *   Mr. and Ms. John Richard and Jane Mary Doe
   *   Mr. & Mrs. John Richard & Jane Mary Doe
   *   Mr. and Mrs. John Richard & Jane Mary Doe
   *   Mr. and Mrs. John Richard and Jane Mary Doe
   *   John Richard & Jane Mary Doe
   *   John Richard and Jane Mary Doe
   *   John Richard Doe & Jane Mary Doe
   *   John Richard Doe and Jane Mary Doe
   */
  create2DiffGenderClientsWithSameLastName(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        let titleMs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMs : TitleMr;
        let titleMrs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMrs : TitleMr;

        // Mr. J. Doe & Ms. J. Doe
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Ms. J. Doe
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace + titleMs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe & Mrs. J. Doe
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMrs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Mrs. J. Doe
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MRS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace + titleMrs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. & Ms. J. Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MS_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AmpPlusSpace + titleMs2 + client1Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. and Ms. J. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MS_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AndPlusSpace + titleMs2 + client1Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. & Mrs. J. Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AmpPlusSpace + titleMrs2 + client1Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. and Mrs. J. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MRS_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AndPlusSpace + titleMrs2 + client1Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. & Ms. J. & J. Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MS_MFLFN_AMP_FFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AmpPlusSpace + titleMs2 + client1Name.firstLetterFirstNameFormat + AmpPlusSpace + client2Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. and Ms. J. & J. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MS_MFLFN_AMP_FFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AndPlusSpace + titleMs2 + client1Name.firstLetterFirstNameFormat + AmpPlusSpace + client2Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. and Ms. J. and J. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MS_MFLFN_AND_FFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AndPlusSpace + titleMs2 + client1Name.firstLetterFirstNameFormat + AndPlusSpace + client2Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. & Mrs. J. & J. Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFLFN_AMP_FFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AmpPlusSpace + titleMrs2 + client1Name.firstLetterFirstNameFormat + AmpPlusSpace + client2Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. and Mrs. J. & J. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MRS_MFLFN_AMP_FFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AndPlusSpace + titleMrs2 + client1Name.firstLetterFirstNameFormat + AmpPlusSpace + client2Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. and Mrs. J. and J. Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MRS_MFLFN_AND_FFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AndPlusSpace + titleMrs2 + client1Name.firstLetterFirstNameFormat + AndPlusSpace + client2Name.firstLetterFirstNameFormat + client1Name.lastNameFormat);
        // Mr. & Ms. John Richard & Jane Mary Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MS_MFN_AMP_FFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AmpPlusSpace + titleMs2 + client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // Mr. and Ms. John Richard & Jane Mary Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MS_MFN_AMP_FFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AndPlusSpace + titleMs2 + client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // Mr. and Ms. John Richard and Jane Mary Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MS_MFN_AND_FFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + AndPlusSpace + titleMs2 + client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // Mr. & Mrs. John Richard & Jane Mary Doe
        result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFN_AMP_FFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AmpPlusSpace + titleMrs2 + client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // Mr. and Mrs. John Richard & Jane Mary Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MRS_MFN_AMP_FFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AndPlusSpace + titleMrs2 + client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // Mr. and Mrs. John Richard and Jane Mary Doe
        result.set(EnvelopeSalutationFormats.MR_AND_MRS_MFN_AND_FFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + AndPlusSpace + titleMrs2 + client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // John Richard & Jane Mary Doe
        result.set(EnvelopeSalutationFormats.FN_AMP_FN_LN,
          client1Name.firstNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // John Richard and Jane Mary Doe
        result.set(EnvelopeSalutationFormats.FN_AND_FN_LN,
          client1Name.firstNamePlusSpace + AndPlusSpace + client2Name.firstNamePlusSpace + client1Name.lastNameFormat);
        // John Richard Doe & Jane Mary Doe
        result.set(EnvelopeSalutationFormats.FN_LN_AMP_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // John Richard Doe and Jane Mary Doe
        result.set(EnvelopeSalutationFormats.FN_LN_AND_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);

      }

    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be male and female
   *  The following rules will be applied when there are 2 clients with different last name:
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  female client with a first name of "Jane" and last name "Public"
   *   Mr. J. Doe & Ms. J. Public
   *   Mr. J. Doe and Ms. J. Public
   *   Mr. J. Doe & Mrs. J. Public
   *   Mr. J. Doe and Mrs. J. Public
   *   Mr. John Richard Doe & Ms. Jane Public
   *   Mr. John Richard Doe and Ms. Jane Public
   *   Mr. John Richard Doe & Mrs. Jane Public
   *   Mr. John Richard Doe and Mrs. Jane Public
   *   John Richard Doe & Jane Public
   *   John Richard Doe and Jane Public
   *
   * @param result
   * @param client1
   * @param client2
   */
  create2DiffGenderClientsWithDiffLastName(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        let titleMs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMs : TitleMr;
        let titleMrs2: string = (client2.gender === GenderTypes.FEMALE) ? TitleMrs : TitleMr;
        // Mr. J. Doe & Ms. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Ms. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace + titleMs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe & Mrs. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MRS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMrs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Mrs. J. Public
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MRS_FFLFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace + titleMrs2 + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. John Richard Doe & Ms. Jane Public EnvelopeSalutationFormats.MR_MFN_MLN_AMP_MS_FFN_FLN
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AMP_MS_FFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMs2 + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // Mr. John Richard Doe and Ms. Jane Public
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AND_MS_FFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace + titleMs2 + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // Mr. John Richard Doe & Mrs. Jane Public
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AMP_MRS_FFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace + titleMrs2 + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // Mr. John Richard Doe and Mrs. Jane Public
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AND_MRS_FFN_FLN,
          ContactSuggestion.getTitleMr(client1.gender, false) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace + titleMrs2 + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // John Richard Doe & Jane Public
        result.set(EnvelopeSalutationFormats.FN_LN_AMP_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // John Richard Doe and Jane Public
        result.set(EnvelopeSalutationFormats.FN_LN_AND_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);

      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be male and female
   *  The following changes will be applied when there are two clients same Gender.
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  male client with a first name of "Michael Luke", middle name of "Matthew" and last name "Brown"
   *   Mr. J. Doe & Mr. M. Brown
   *   Mr. J. Doe and Mr. M. Brown
   *   Mr. John Richard Doe & Mr. Michael Luke Brown
   *   Mr. John Richard Doe and Mr. Michael Luke Brown
   *   John Richard Doe & Michael Luke Brown
   *   John Richard Doe and Michael Luke Brown
   *   Female Clients - Note that the requirement will be stated given the example of a female client with a first name of "Jane", middle name of "Josephine" and last name of "Doe",
   *   female client with a first name of "Mary", middle name of "Magdalene" and last name "Brown":
   *   Ms. J. Doe & Ms. M. Brown
   *   Ms. J. Doe and Ms. M. Brown
   *   Mrs. J. Doe & Mrs. M. Brown
   *   Mrs. J. Doe and Mrs. M. Brown
   *   Miss J. Doe & Miss M. Brown
   *   Miss J. Doe and Miss M. Brown
   *   Ms. Jane Doe & Ms. Mary Brown
   *   Ms. Jane Doe and Ms. Mary Brown
   *   Mrs. Jane Doe & Mrs. Mary Brown
   *   Mrs. Jane Doe and Mrs. Mary Brown
   *   Miss Jane Doe & Miss Mary Brown
   *   Miss Jane Doe and Miss Mary Brown
   *   Jane Doe & Mary Brown
   *   Jane Doe and Mary Brown
   *   @param result
   *   @param client1
   *   @param client2
   */
  create2SameGenderClients(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        // Mr. J. Doe & Mr. M. Brown
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_MR_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace
          + ContactSuggestion.getTitleMr(client1.gender, true) + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        // Mr. J. Doe and Mr. M. Brown
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_MR_MFLFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace
          + ContactSuggestion.getTitleMr(client1.gender, true) + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        if (client1.gender == GenderTypes.FEMALE) {
          // Mrs. J. Doe & Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MRS_MFLFN_MLN_AMP_MRS_MFLFN_MLN,
            TitleMrs + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace
            + TitleMrs + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
          // Mrs. J. Doe and Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MRS_MFLFN_MLN_AND_MRS_MFLFN_MLN,
            TitleMrs + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace
            + TitleMrs + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
          // Miss J. Doe & Miss M. Brown
          result.set(EnvelopeSalutationFormats.MISS_MFLFN_MLN_AMP_MISS_MFLFN_MLN,
            TitleMiss + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AmpPlusSpace
            + TitleMiss + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
          // Miss J. Doe and Miss M. Brown
          result.set(EnvelopeSalutationFormats.MISS_MFLFN_MLN_AND_MISS_MFLFN_MLN,
            TitleMiss + client1Name.firstLetterFirstNameFormat + client1Name.lastNamePlusSpace + AndPlusSpace
            + TitleMiss + client2Name.firstLetterFirstNameFormat + client2Name.lastNameFormat);
        }
        // Mr. John Richard Doe & Mr. Michael Luke Brown
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AMP_MR_MFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace
          + ContactSuggestion.getTitleMr(client1.gender, true) + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // Mr. John Richard Doe and Mr. Michael Luke Brown
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AND_MR_MFN_MLN,
          ContactSuggestion.getTitleMr(client1.gender, true) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace
          + ContactSuggestion.getTitleMr(client1.gender, true) + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        if (client1.gender == GenderTypes.FEMALE) {
          // Mrs. J. Doe & Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MRS_MFN_MLN_AMP_MRS_MFN_MLN,
            TitleMrs + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace
            + TitleMrs + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
          // Mrs. J. Doe and Mrs. M. Brown
          result.set(EnvelopeSalutationFormats.MRS_MFN_MLN_AND_MRS_MFN_MLN,
            TitleMrs + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace
            + TitleMrs + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
          // Miss J. Doe & Miss M. Brown
          result.set(EnvelopeSalutationFormats.MISS_MFN_MLN_AMP_MISS_MFN_MLN,
            TitleMiss + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace
            + TitleMiss + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
          // Miss J. Doe and Miss M. Brown
          result.set(EnvelopeSalutationFormats.MISS_MFN_MLN_AND_MISS_MFN_MLN,
            TitleMiss + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace
            + TitleMiss + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        }
        // John Richard Doe & Michael Luke Brown
        result.set(EnvelopeSalutationFormats.FN_LN_AMP_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AmpPlusSpace + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // John Richard Doe and Michael Luke Brown
        result.set(EnvelopeSalutationFormats.FN_LN_AND_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace + AndPlusSpace + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The gender should only be blank and male/female
   *  The following changes will be applied when there are two clients <Blank> gender and male/female.
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Richard", middle name of "Joshua" and last name of "Doe",
   *  <Blank> client with a first name of "Joe" and last name "Blank"
   *   Mr. J. Doe & Joe Blank
   *   Mr. J. Doe and Joe Blank
   *   Mr. John Richard Doe & Joe Blank
   *   Mr. John Richard Doe and Joe Blank
   *   John Richard Doe & Joe Blank
   *   John Richard Doe and Joe Blank
   *   * @param result
   * @param client1
   * @param client2
   */
  create2DiffBlankAndMaleOrFemaleGenderClients(result: Map<EnvelopeSalutationFormatType, string>, client1: Contact, client2: Contact) {
    if (result && client1 && client2) {
      let client1Name: ContactName = client1.contactName;
      let client2Name: ContactName = client2.contactName;
      if (client1Name && client2Name) {
        // Mr. J. Doe & Joe Blank
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AMP_FN_LN,
          ContactSuggestion.getTitleMr(client1.gender) + (ContactSuggestion.getTitleMr(client1.gender) ? client1Name.firstLetterFirstNameFormat : client1Name.firstNamePlusSpace) + client1Name.lastNamePlusSpace
          + AmpPlusSpace
          + ContactSuggestion.getTitleMr(client2.gender) + (ContactSuggestion.getTitleMr(client2.gender) ? client2Name.firstLetterFirstNameFormat : client2Name.firstNamePlusSpace) + client2Name.lastNameFormat);
        // Mr. J. Doe and Joe Blank
        result.set(EnvelopeSalutationFormats.MR_MFLFN_MLN_AND_FN_LN,
          ContactSuggestion.getTitleMr(client1.gender) + (ContactSuggestion.getTitleMr(client1.gender) ? client1Name.firstLetterFirstNameFormat : client1Name.firstNamePlusSpace) + client1Name.lastNamePlusSpace
          + AndPlusSpace
          + ContactSuggestion.getTitleMr(client2.gender) + (ContactSuggestion.getTitleMr(client2.gender) ? client2Name.firstLetterFirstNameFormat : client2Name.firstNamePlusSpace) + client2Name.lastNameFormat);
        // Mr. John Richard Doe & Joe Blank
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AMP_FN_LN,
          ContactSuggestion.getTitleMr(client1.gender) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace
          + AmpPlusSpace
          + ContactSuggestion.getTitleMr(client2.gender) + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // Mr. John Richard Doe and Joe Blank
        result.set(EnvelopeSalutationFormats.MR_MFN_MLN_AND_FN_LN,
          ContactSuggestion.getTitleMr(client1.gender) + client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace
          + AndPlusSpace
          + ContactSuggestion.getTitleMr(client2.gender) + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // John Richard Doe & Joe Blank
        result.set(EnvelopeSalutationFormats.FN_LN_AMP_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace
          + AmpPlusSpace
          + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
        // John Richard Doe and Joe Blank
        result.set(EnvelopeSalutationFormats.FN_LN_AND_FN_LN,
          client1Name.firstNamePlusSpace + client1Name.lastNamePlusSpace
          + AndPlusSpace
          + client2Name.firstNamePlusSpace + client2Name.lastNameFormat);
      }
    }
  }

  /**
   *  The following item should add to drop down list
   *  After confirm with BA, the order on how the clients are entered will be followed when displaying the order of the items.
   *  The following rules will be applied when there are more than two individual clients (male/female).
   *  Note that the requirement will be stated given the example of a male client with a first name of "John Henry", middle name of "Joshua" and last name of "Smith",
   *  female client with a first name of "Maria", middle name of "Thelma" and last name "Board"
   *  and male client with a first name of "Anthony" , middle name "Robert" and last name "Adams"
   *   Mr. J. Smith, Ms. M. Board & Mr. A. Adams
   *   Mr. J. Smith, Ms. M. Board and Mr. A. Adams
   *   Mr. John Henry Smith, Ms. Maria Board & Mr. Anthony Adams
   *   Mr. John Henry Smith, Ms. Maria Board and Mr. Anthony Adams
   *   John Henry Smith, Maria Board & Anthony Adams
   *   John Henry Smith, Maria Board and Anthony Adams
   *   @param result
   *   @param participants
   */
  createIndividualClients(result: Map<EnvelopeSalutationFormatType, string>, wrappers: Array<MatterParticipantWrapper>) {
    if (MatterParticipantUtil.isIndividualClients(wrappers)) {
      let tmpWrappers = wrappers.filter(item => item && item.matterParticipant && item.matterParticipant.contact && item.matterParticipant.contact.contactName);
      //Mr. J. Smith format
      let suggestionMrFlfnLn: string[] = ContactSuggestion.createMaleOrFemaleParticipantsSuggestion(tmpWrappers, EnvelopeSalutationFormats.MR_FLFN_LN);
      //Mr. John Henry Smith
      let suggestionMrFnLn: string[] = ContactSuggestion.createMaleOrFemaleParticipantsSuggestion(tmpWrappers, EnvelopeSalutationFormats.MR_FN_LN);
      //Mr. John Henry Smith
      let suggestionFnLn: string[] = ContactSuggestion.createMaleOrFemaleParticipantsSuggestion(tmpWrappers, EnvelopeSalutationFormats.FN_LN);
      // Mr. J. Smith, Ms. M. Board & Mr. A. Adams
      result.set(EnvelopeSalutationFormats.TITLE_FLFN_LN_JOIN_WITH_AMP, Utils.concatenateNames(suggestionMrFlfnLn, null, '&'));
      // Mr. J. Smith, Ms. M. Board and Mr. A. Adams
      result.set(EnvelopeSalutationFormats.TITLE_FLFN_LN_JOIN_WITH_AND, Utils.concatenateNames(suggestionMrFlfnLn, null, 'and'));
      // Mr. John Henry Smith, Ms. Maria Board & Mr. Anthony Adams
      result.set(EnvelopeSalutationFormats.TITLE_FN_LN_JOIN_WITH_AMP, Utils.concatenateNames(suggestionMrFnLn, null, '&'));
      // Mr. John Henry Smith, Ms. Maria Board and Mr. Anthony Adams
      result.set(EnvelopeSalutationFormats.TITLE_FN_LN_JOIN_WITH_AND, Utils.concatenateNames(suggestionMrFnLn, null, 'and'));
      // John Henry Smith, Maria Board & Anthony Adams
      result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AMP, Utils.concatenateNames(suggestionFnLn, null, '&'));
      // John Henry Smith, Maria Board and Anthony Adams
      result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AND, Utils.concatenateNames(suggestionFnLn, null, 'and'));
    }

  }

  initMatterParticipantsSuggestions(wrappers: Array<MatterParticipantWrapper>): Map<EnvelopeSalutationFormatType, string> {
    let result = new Map<EnvelopeSalutationFormatType, string>();
    let participants: MatterParticipantWrapper[] = Array.isArray(wrappers) ? wrappers.filter(item => item.matterParticipant && item.matterParticipant.contact) : [];
    if (Array.isArray(participants) && participants.length > 0) {
      //DPPMP-47841 Add concatenatedValue into drop down list
      let concatenatedValue = this.allParticipantsArePersonsOrBlank(participants) ? ContactSuggestion.getDefaultSuggestions(participants, ContactSuggestionSalutationEnum.salutation) : '';
      if (Array.isArray(concatenatedValue) && concatenatedValue.length > 0) {
        result.set(EnvelopeSalutationFormats.CONCATENATED_VALUE, Utils.concatenateNames(concatenatedValue));
      }

      if (participants.length === 1) {
        if (participants[ 0 ].matterParticipant) {
          let resultTmp = this.initSuggestions(participants[ 0 ].matterParticipant.contact);
          resultTmp.forEach((v, k) => result.set(k, v));
          return result;
        }
      }
      if (MatterParticipantUtil.is2DiffMaleOrFemaleGenderClientsWithSameLastName(participants)) {
        this.create2DiffGenderClientsWithSameLastName(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.is2DiffMaleAndFemaleGenderClientsWithDiffLastName(participants)) {
        this.create2DiffGenderClientsWithDiffLastName(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.is2SameMaleOrFemaleGenderClients(participants)) {
        this.create2SameGenderClients(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.is2DiffBlankAndMaleOrFemaleGenderClientsWithDiffLastName(participants)) {
        this.create2DiffBlankAndMaleOrFemaleGenderClients(result, participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact);
      } else if (MatterParticipantUtil.isIndividualClients(participants)) {
        this.createIndividualClients(result, participants);
      } else if (MatterParticipantUtil.excludeCorpEntityAndOneMaleFemaleBlank(participants)) {
        let wrapper: MatterParticipantWrapper = participants.find(item => item.matterParticipant && item.matterParticipant.contact
          && item.matterParticipant.contact.isMaleOrFemaleOrBlankGender());
        if (wrapper && wrapper.matterParticipant && wrapper.matterParticipant.contact) {
          let resultTmp = this.initSuggestions(wrapper.matterParticipant.contact);
          resultTmp.forEach((v, k) => result.set(k, v));
          return result;
        }
      } else if (participants.length >= 1 && !MatterParticipantUtil.includeCorpEntity(participants) && (this.allParticipantsArePersons(participants) || this.isAnyParticipantWithoutGender(participants))) {
        //second client purchasers just a place holder
        if (participants.length === 2 && !participants[ 1 ].matterParticipant) {
          if (participants[ 0 ].matterParticipant) {
            let resultTmp = this.initSuggestions(participants[ 0 ].matterParticipant.contact);
            resultTmp.forEach((v, k) => result.set(k, v));
            return result;
          }
        } else if (participants.length === 2 && participants[ 0 ].matterParticipant && participants[ 1 ].matterParticipant &&
          this.contactsOfOppositeGender(participants[ 0 ].matterParticipant.contact, participants[ 1 ].matterParticipant.contact)) {
          let c0 = participants[ 0 ].matterParticipant.contact;
          let c1 = participants[ 1 ].matterParticipant.contact;

          let maleFirstLetterFirstName, maleFirstName, maleLastName, femaleFirstLetterFirstName,
            femaleFirstName, femaleLastName, femaleFirstNameOnly: string;

          if (c0.gender === 'MALE' || c0.gender === 'MALEPOA') {
            maleFirstLetterFirstName = c0.contactName.firstName ? c0.contactName.firstName[ 0 ] + '. ' : '';
            maleFirstName = c0.contactName.firstName ? c0.contactName.firstName + ' ' : '';
            femaleFirstName = c1.contactName.firstName ? 'and ' + c1.contactName.firstName + ' ' : '';
            femaleFirstNameOnly = c1.contactName.firstName ? c1.contactName.firstName : '';
          } else {
            maleFirstLetterFirstName = c1.contactName.firstName ? c1.contactName.firstName[ 0 ] + '. ' : '';
            maleFirstName = c1.contactName.firstName ? c1.contactName.firstName + ' ' : '';
            femaleFirstName = c0.contactName.firstName ? 'and ' + c0.contactName.firstName + ' ' : '';
            femaleFirstNameOnly = c0.contactName.firstName ? c0.contactName.firstName : '';
          }

          // check if purchasers click FLA
          if ((participants[ 0 ].matterParticipant.matterParticipantId === participants[ 1 ].matterParticipant.spouseParticipantId ||
              participants[ 1 ].matterParticipant.matterParticipantId === participants[ 0 ].matterParticipant.spouseParticipantId) &&
            (c0.contactName.lastName === c1.contactName.lastName) && !this.anySpouseIsPOAOrEstate(c0, c1)) {
            // result.push(`Mr. & Mrs. ${maleFirstLetterFirstName}${c0.contactName.lastName}`);
            // result.push(`Mr. & Mrs. ${maleFirstName}${c0.contactName.lastName}`);
            // result.push(`${maleFirstName}${femaleFirstName}${c0.contactName.lastName}`);
            result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFLFN_MLN, `Mr. & Mrs. ${ maleFirstLetterFirstName }${ c0.contactName.lastName }`);
            result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFN_MLN, `Mr. & Mrs. ${ maleFirstName }${ c0.contactName.lastName }`);
            result.set(EnvelopeSalutationFormats.MFN_MLN, `${ maleFirstName }${ femaleFirstName }${ c0.contactName.lastName }`);
          } else {
            // check if purchasers have same last name for potential spousal situation
            let firstNamesAndLastNameWithoutSalutation: string = '';
            if ((participants[ 0 ].matterParticipant.contact.lastName === participants[ 1 ].matterParticipant.contact.lastName ||
                participants[ 1 ].matterParticipant.contact.lastName === participants[ 0 ].matterParticipant.contact.lastName) &&
              (c0.contactName.lastName === c1.contactName.lastName) && !this.anySpouseIsPOAOrEstate(c0, c1)) {
              // result.push(`Mr. & Mrs. ${maleFirstName} & ${femaleFirstNameOnly} ${c0.contactName.lastName}`);
              // result.push(`Mr. & Mrs. ${maleFirstLetterFirstName}${c0.contactName.lastName}`);
              result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFN_AMP_FFN_MLN, `Mr. & Mrs. ${ maleFirstName } & ${ femaleFirstNameOnly } ${ c0.contactName.lastName }`);
              result.set(EnvelopeSalutationFormats.MR_AMP_MRS_MFLFN_MLN, `Mr. & Mrs. ${ maleFirstLetterFirstName }${ c0.contactName.lastName }`);
              firstNamesAndLastNameWithoutSalutation = `${ maleFirstName }& ${ femaleFirstNameOnly } ${ c0.contactName.lastName }`;
            }

            // For MALEPOA or FEMALEPOA we don't add items into matter.dear drop down list
            let mapSg0 = (c0.gender != GenderTypes.MALEPOA && c0.gender != GenderTypes.FEMALEPOA) ? this.initSuggestions(c0) : null;
            let mapSsg1 = (c1.gender != GenderTypes.MALEPOA && c1.gender != GenderTypes.FEMALEPOA) ? this.initSuggestions(c1) : null;
            let sg0 = ContactSuggestion.getSuggestionsArrayFormMap(mapSg0);
            let sg1 = ContactSuggestion.getSuggestionsArrayFormMap(mapSsg1);

            if (this.anySpouseIsPOAOrEstate(c0, c1)) {
              // if (sg0 && sg0.length > 0) {
              //     sg0.forEach(p => {
              //         result.push(p)
              //     })
              // }
              if (mapSg0) {
                mapSg0.forEach((v, k) => result.set(k, v));
              }
              // if (sg1 && sg1.length > 0) {
              //     sg1.forEach(p => {
              //         result.push(p)
              //     })
              // }
              if (mapSsg1) {
                mapSsg1.forEach((v, k) => result.set(k, v));
              }
            } else {
              let item1: string = sg0[ 0 ]; // Mr. J. Smith
              let item2: string = sg0[ sg0.length - 1 ]; // John Smith
              let itemWithPrefixFullName: string;
              if (c0.contactName.firstName) {
                itemWithPrefixFullName = sg0[ 1 ]; // Mr. John Smith
              } else {
                itemWithPrefixFullName = sg0[ 0 ]; // Mr. Smith
              }

              if (sg1 && sg1.length > 0) {

                item1 += ' & ' + sg1[ 0 ];
                item2 += ' & ' + sg1[ sg1.length - 1 ];
                if (c0.contactName.firstName) {
                  itemWithPrefixFullName += ' & ' + sg1[ 1 ];
                } else {
                  itemWithPrefixFullName += ' & ' + sg1[ 0 ]; //Only Mr. Smith
                }

              }
              if (item1 && item1.length > 0) {
                //result.push(item1);
                result.set(EnvelopeSalutationFormats.TITLE_FLFN_LN_JOIN_WITH_AMP, item1);
              }
              if (itemWithPrefixFullName && itemWithPrefixFullName.length > 0 && (item1 != itemWithPrefixFullName)) {
                //result.push(itemWithPrefixFullName);
                result.set(EnvelopeSalutationFormats.TITLE_FN_LN_JOIN_WITH_AMP, itemWithPrefixFullName);
              }
              if (firstNamesAndLastNameWithoutSalutation && firstNamesAndLastNameWithoutSalutation.length > 0) {
                //result.push(firstNamesAndLastNameWithoutSalutation);
                result.set(EnvelopeSalutationFormats.MFN_AMP_FFN_MLN, firstNamesAndLastNameWithoutSalutation);
              }
              if (item2 && item2.length > 0) {
                //result.push(item2);
                result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AMP, item2);
              }
            }
          }
        } else {
          let name: string[] = [];
          let nameWithTitle: string[] = [];
          let fullNameWithTitle: string[] = [];
          let participantsWithoutGender: string[] = [];
          let allParticipantsWithoutGender: boolean = participants.every(i => i.matterParticipant.contact.gender === 'QUESTION');
          if (allParticipantsWithoutGender) {
            participants.forEach(p => {
              let participantSuggestion = ContactSuggestion.getSuggestionsArrayFormMap(this.initSuggestions(p.matterParticipant.contact));
              let participantName = participantSuggestion ? participantSuggestion.toString() : '';
              participantsWithoutGender.push(participantName);
            });
          } else {
            participants.forEach(p => {
              if (p.matterParticipant) {
                if (p.matterParticipant.contact.gender === 'MALE' || p.matterParticipant.contact.gender === 'FEMALE') {

                  nameWithTitle.push(this.getName(p.matterParticipant.contact, true, true));
                  fullNameWithTitle.push(this.getName(p.matterParticipant.contact, true, false));
                  name.push(this.getName(p.matterParticipant.contact, false, false));

                }
                if (p.matterParticipant.contact.gender === 'QUESTION') {
                  let participantSuggestionTmp = ContactSuggestion.getSuggestionsArrayFormMap(this.initSuggestions(p.matterParticipant.contact));

                  let participantName = participantSuggestionTmp ? participantSuggestionTmp.toString() : '';
                  nameWithTitle.push(participantName);
                  fullNameWithTitle.push(participantName);
                  name.push(participantName);
                }
              }
            });
          }
          let nameWithTitleMergeList;
          let fullNameWithTitleMergeList;
          if (nameWithTitle && nameWithTitle.length > 0) {
            nameWithTitleMergeList = this.mergeListOfNames(nameWithTitle, ' & ');
            //result.push(nameWithTitleMergeList);
            result.set(EnvelopeSalutationFormats.TITLE_FLFN_LN_JOIN_WITH_AMP, nameWithTitleMergeList);
          }
          if (fullNameWithTitle && fullNameWithTitle.length > 0) {
            fullNameWithTitleMergeList = this.mergeListOfNames(fullNameWithTitle, ' & ');
            if (nameWithTitleMergeList != fullNameWithTitleMergeList) {
              //result.push(fullNameWithTitleMergeList);
              result.set(EnvelopeSalutationFormats.TITLE_FN_LN_JOIN_WITH_AMP, fullNameWithTitleMergeList);
            }
          }
          if (name && name.length > 0) {
            //result.push(this.mergeListOfNames(name, ' & '));
            result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AMP, this.mergeListOfNames(name, ' & '));

          }
          if (participantsWithoutGender && participantsWithoutGender.length > 0) {
            // result.push(this.mergeListOfNames(participantsWithoutGender, ' and '));
            result.set(EnvelopeSalutationFormats.FN_LN_JOIN_WITH_AND, this.mergeListOfNames(participantsWithoutGender, ' and '));
          }
        }
      }
    }
    //Returning Unique String combinations created above for Envelope salutation
    // return Array.from(new Set(result));
    return result;
  }

}
