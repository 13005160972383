<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1>Unity® Lender Centre data changes</h1>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group padding-left-50">
            <div class="col-lg-12">
                <div class="main-level">
                        <span class="col-lg-10 margin-bottom-0">
                            You have made the following changes that may have an impact on your Payout Request(s):
                        </span>
                </div>
            </div>
        </div>
        <ul class="padding-left-100 font-16" style="list-style-type: circle" *ngFor="let message of context.fieldsUpdatedAfterAssystPayoutSubmission">
            <li>{{message}}</li>
        </ul>
        <div class="form-group  padding-left-50">
            <div class="col-lg-12">
                <span class="col-lg-10 padding-top-20 margin-bottom-0">
                    Please consider re-submitting Unity® Lender Centre request(s) to ensure they reflect the changes above.
                </span>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    (click)="close()"
                    (keyup.enter)="close()"
                    tabindex="0"
                    class="dp-btn dp-btn-green">
                <span>OK</span>
            </button>
        </div>

    </div>

</div>
