import {CurrencyPipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Mortgage} from '../../../shared/mortgage';
import {Utils} from '../../../shared/utils';
import {CommonContactDialogComponent} from '../../../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {MatterParticipant} from '../../../shared/matter-participant';
import {MortgageeComponent} from './mortgagee.component';
import {MatterParticipantWrapper} from '../../../shared/matter-participant-wrapper';
import {Contact} from '../../../shared/contact';
import {SnapshotBurgerMenuActions} from '../../../../shared-main/constants';
import {AddressTypes} from '../../../shared/address-types';
import {Matter} from '../../../shared';
import * as _ from 'lodash';

@Component({
  selector: 'dp-vtb-mortgagee',
  templateUrl: 'vtb-mortgagee.component.html',
  styleUrls: [ './vtb-mortgagee.style.scss' ],
  providers: [ CurrencyPipe ]
})
//Extending MortgageeComponent for leveraging existing methods
export class VtbMortgageeComponent extends MortgageeComponent implements OnInit {

  ngOnInit(): void {

  }

  //Overrides the parent class input method.
  @Input()
  set mortgage(mortgage: Mortgage) {
    this._mortgage = mortgage;
    this.initializeState();
  }

  get mortgage(): Mortgage {
    return this._mortgage;
  }

  initializeState(): void {
    this.utils = new Utils();
    this.initializeVTBMortgageeWRappers();
    this.initializeMortgageTitleDetailsType();
  }

  initializeVTBMortgageeWRappers(): void {
    const vtbMortgagees: MatterParticipant[] = this.matter.getPrivateLenders(this.mortgage) || [];
    this.matterPrivateLenders = [];
    this.initializeMortgageCapacity();
    vtbMortgagees.forEach(vtbMortgagee => {
      const matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.matterParticipant = vtbMortgagee;
      matterParticipantWrapper.selectedTab = 'Profile';
      matterParticipantWrapper.dataModel = vtbMortgagee.contact;
      matterParticipantWrapper.editMode = false;
      this.matterPrivateLenders.push(matterParticipantWrapper);
    });
    this.updateCommonSpecifiedCapacity();
    this.matter.reCalculateLenderReLine(this.mortgage);
  }

  addVTBMortgagee(): void {
    const participant = new MatterParticipant();
    this.setPrivateLenderCapacityAndShare(participant, this.mortgage.mortgageCapacityType);
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        mortgage: this.mortgage,
        matterParticipant: participant,
        contactType: 'CLIENT',
        matterParticipantRole: 'PRIVATE_LENDER',
        isVTBMortgagee: true
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {
            this.silentAddVTBMortgagee(this.matter, this.mortgage, participant, result.contact);
            this.enableSave();
          }

        }
      },
      fullScreen: true,
      
    });
  }

  silentAddVTBMortgagee(matter: Matter, mortgage: Mortgage, participant: MatterParticipant, contact: Contact): void {
    this.createVtbMortgageeWrapper(contact, participant);
    if (matter.isProjectSale) {
      this.mortgageSoAdjService.rearrangeAdjustments(matter);
      this.mortgageSoAdjService.updateStatementOfAdjustment(matter);
      if (this.matter) {
        this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(mortgage);
      }

    }

  }

  cleanChildMatterParticipantsAddress(parentParticipant: MatterParticipant) {
    if (parentParticipant) {
      let childrenParticipants = this.matter.getChildMatterParticipants(parentParticipant);
      childrenParticipants.forEach((signerMp: MatterParticipant) => {
        if (signerMp && signerMp.contact) {
          signerMp.contact.address = [];
        }
      });
    }
  }

  async createVtbMortgageeWrapper(contact: Contact, participant: MatterParticipant, sourceParticipant?: MatterParticipant, cleanChildMatterParticipantsAddress: boolean = false): Promise<void> {
    const mortgageeWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    this.matterPrivateLenders.push(mortgageeWrapper);
    mortgageeWrapper.editMode = false;
    mortgageeWrapper.selectedDetailsTabIndex = 0;
    mortgageeWrapper.selectedTab = 'Profile';
    mortgageeWrapper.matterParticipant
      = this.matter.addMatterParticipant(contact, false, 'PRIVATE_LENDER', this.mortgage.id);

    if (sourceParticipant) {
      let sourceChildrenParticipants = this.matter.getChildMatterParticipants(sourceParticipant);
      sourceChildrenParticipants.forEach((signerMp: MatterParticipant) => {
        this.matterParticipantService.copyAndUpdateSignerMatterParticipant(signerMp, mortgageeWrapper.matterParticipant, this.matter);
      });
    } else {
      await this.matterParticipantService.createMatterParticipantAssociatedContactForClient(contact, mortgageeWrapper.matterParticipant, this.matter, undefined, true);
    }
    mortgageeWrapper.matterParticipant.purchaserCapacity = participant.purchaserCapacity;
    mortgageeWrapper.matterParticipant.primary = participant.primary;
    mortgageeWrapper.dataModel = mortgageeWrapper.matterParticipant.contact;
    //Since vendor doesn't have service or report address but Vtb mortgagee (public lenders) should have that, therefore initializing them.
    mortgageeWrapper.matterParticipant.contact.initializeAddressesWithDefaultProvince([ AddressTypes.serviceAddress, AddressTypes.reportAddress ],
      this.documentProfileCache, this.matter && this.matter.provinceCode);
    mortgageeWrapper.matterParticipant.contact.addSecondaryAddressesForMortgagee();
    this.matter.setPrivateLenderPrimaryFlag(mortgageeWrapper.matterParticipant, this.matterPrivateLenders);
    this.updateCommonSpecifiedCapacity();
    this.matter.reCalculateLenderReLine(this.mortgage);
    if (cleanChildMatterParticipantsAddress) {
      this.cleanChildMatterParticipantsAddress(mortgageeWrapper.matterParticipant);
    }
    this.setVtbMortgageeExpanded(mortgageeWrapper, false);
  }

  getVtbMortgageeBurgerMenuItems(index: number): any {
    let menuItems = [];
    if (this.matterPrivateLenders.length > 1) {
      if (index > 0) {
        menuItems.push(SnapshotBurgerMenuActions.MOVE_UP);
      }

      if (index < this.matterPrivateLenders.length - 1) {
        menuItems.push(SnapshotBurgerMenuActions.MOVE_DOWN);
      }
    }
    menuItems.push(SnapshotBurgerMenuActions.DELETE);
    return menuItems;
  }

  setVtbMortgageeExpanded(participantWrapper: MatterParticipantWrapper, expandedTarget: boolean): void {
    if (!participantWrapper || participantWrapper.expanded === expandedTarget) {
      return;
    }
    participantWrapper.expanded = expandedTarget;
    return;
  }

  clickVtbMortgageeBurgerMenu(event, participantWrapper: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE: {
        this.deleteVtbMortgagee(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_UP: {
        this.moveUpVtbMortgagee(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_DOWN: {
        this.moveDownVtbMortgagee(participantWrapper);
        break;
      }
      default: {
        break;
      }
    }
  }

  deleteVtbMortgagee(vtbMortgagee: MatterParticipantWrapper): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this mortgagee from this matter?', false, 'Delete').subscribe(res => {
      if (res) {
        if (this.matter && this.documentProductionService && this.contactQueryService && vtbMortgagee && vtbMortgagee.matterParticipant) {
          this.documentProductionService.tryToRevokePackage(this.matter, vtbMortgagee.matterParticipant, this.contactQueryService);
        }
        this.silentDeleteVtbMortgagee(vtbMortgagee);
        this.enableSave();
        this.setFocusAfterDelete();
      }
    });
  }

  silentDeleteVtbMortgagee(vtbMortgagee: MatterParticipantWrapper): void {
    let childMatterParticipants = this.matter.getChildMatterParticipants(vtbMortgagee.matterParticipant);
    if (childMatterParticipants) {
      childMatterParticipants.forEach(item => {
        this.matter.removeMatterParticipant(item);
      });
    }
    this.matter.removeMatterParticipant(vtbMortgagee.matterParticipant);
    (<any>this.matterPrivateLenders).remove(vtbMortgagee);
    if (this.matterPrivateLenders.length > 0) {
      if (vtbMortgagee.primary) {
        this.matterPrivateLenders[ 0 ].primary = true;
      }
    } else {
      //currently only in MB, user can see the titleDetails data at tab G, and make changes
      //but the logic is generic
      if (this.mortgage.mortgageContactInfo && this.mortgage.mortgageContactInfo.isTitleDetailsManual()) {
        this.mortgage.mortgageContactInfo.titleDetailsDescription = null;
        this.mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
      }
    }

    if (this.matterPrivateLenders.length < 2) {
      this.mortgage.mortgageCapacityType = 'SILENT';
    }

    this.updateCommonSpecifiedCapacity();
    this.matter.reCalculateLenderReLine(this.mortgage);
    if (this.matter.isProjectSale) {
      this.mortgageSoAdjService.rearrangeAdjustments(this.matter);
      this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
      this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
    }
  }

  moveUpVtbMortgagee(selectedMortgagee: MatterParticipantWrapper): void {
    this.matter.reorderParticipantByMortgage(selectedMortgagee.matterParticipant, true, this.mortgage);
    this.reorderVtbMortgageeWrappers();
    this.matter.reCalculateLenderReLine(this.mortgage);
    this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
    this.notifyChange();
  }

  moveDownVtbMortgagee(selectedPrivateLender: MatterParticipantWrapper): void {
    this.matter.reorderParticipantByMortgage(selectedPrivateLender.matterParticipant, false, this.mortgage);
    this.reorderVtbMortgageeWrappers();
    this.matter.reCalculateLenderReLine(this.mortgage);
    this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
    this.notifyChange();
  }

  public reorderVtbMortgageeWrappers(): void {
    const reorderedPrivateLenderWrappers: MatterParticipantWrapper[] = [];
    this.matter.getPrivateLenders(this.mortgage).forEach((p: MatterParticipant) => {
      const matched: MatterParticipantWrapper = this.matterPrivateLenders.find((w: MatterParticipantWrapper) => w.matterParticipantId === p.matterParticipantId);
      if (matched) {
        this.setVtbMortgageeExpanded(matched, false);
        reorderedPrivateLenderWrappers.push(matched);
      }
    });
    this.matterPrivateLenders = reorderedPrivateLenderWrappers;
  }

  toggleVtbMortgageeSnapshot(participantWrapper: MatterParticipantWrapper): void {
    if (participantWrapper && participantWrapper.matterParticipant) {
      this.setVtbMortgageeExpanded(participantWrapper, !participantWrapper.expanded);
    }
  }

  getAddMortgageeButtonText(): string {
    if (this.hasPrivateLenders()) {
      return 'Add another Mortgagee';
    } else {
      return 'Add Mortgagee';
    }
  }

  addVendorsAsMortgagees(): void {
    if (this.matterPrivateLenders.length > 0) {
      this.dialogService.confirm('Confirmation', 'Please note that any mortgagees previously entered will be removed and replaced with the vendors', false).subscribe(res => {
        if (res) {
          this.deleteExistingVtbMortgagees();
          this.copyVendorsToMortgagees();
        }
      });
    } else {
      this.copyVendorsToMortgagees();
    }
  }

  copyVendorsToMortgagees(): void {
    if (this.matter.mainClients.length > 0) {
      this.matter.mainClients.forEach(vendor => {
        let contact = new Contact();
        contact.createNewContactCloneWithOptions(vendor.contact, true, this.documentProfileCache, this.matter.provinceCode);
        //Since vendor doesn't have service or report address but Vtb mortgagee (public lenders) should have that, therefore initializing them.
        contact.initializeAddresses([ AddressTypes.serviceAddress, AddressTypes.reportAddress ]);
        contact.addSecondaryAddressesForMortgagee();
        //DPPMP-51282 It is necessary to keep the Gender the same as Vendors.
        //this.mapVendorGenderToVTBMortgagee(contact, vendor.contact);
        const participant = new MatterParticipant();
        this.createVtbMortgageeWrapper(contact, participant, vendor, true);
      });
      if (this.matter.isProjectSale) {
        this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
        this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
      }
      this.enableSave();
    }
  }

  mapVendorGenderToVTBMortgagee(mortgagee: Contact, vendor: Contact): void {
    if (vendor.gender === 'MALEPOA'
      || vendor.gender === 'FEMALEPOA'
      || vendor.gender === 'ESTATE'
    ) {
      mortgagee.gender = 'QUESTION';
    } else if (vendor.gender === 'CORPORATION') {
      mortgagee.gender = 'OTHERENTITY';
    } else {
      mortgagee.gender = vendor.gender;
    }
    mortgagee.initialLocalGender();
  }

  deleteExistingVtbMortgagees(): void {
    let selectedMatterParticipants: MatterParticipant[] = _.filter(this.matter.matterParticipants, matterParticipant => matterParticipant.matterParticipantRole === 'PRIVATE_LENDER' && matterParticipant.mortgageId == this.mortgage.id);
    for (let i = 0; i < selectedMatterParticipants.length; i++) {
      let childMatterParticipants = this.matter.getChildMatterParticipants(selectedMatterParticipants[ i ]);
      if (childMatterParticipants) {
        childMatterParticipants.forEach(item => {
          this.matter.removeMatterParticipant(item);
        });
      }
    }
    this.matter.deleteAllMatterParticipantByRole('PRIVATE_LENDER', this.mortgage.id);
    this.matterPrivateLenders = [];
    //Remove old mortgage capacity and re-initialize it
    this.mortgage.mortgageCapacityType = null;
    this.initializeMortgageCapacity();
    //Lender reline should get updated on deletion
    this.matter.reCalculateLenderReLine(this.mortgage);
  }

  get capacityTabLabel(): string {
    if (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceNBorNS) {
      return 'Tenure/Share';
    } else {
      return 'Capacity';
    }
  }

  privateLenderInfoChanged(): void {
    this.notifyChange();
    this.updateLenderReLine();
    if (this.matter.isProjectSale) {
      this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
      this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
    }

  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgage: Mortgage) {
    this.matter = matter;
    this.mortgage = mortgage;
  }
}
