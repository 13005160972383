<!-- modal -->
<form class="form-horizontal">
    <div class="container-fluid custom-modal-container">
        <div class="modal-header margin-bottom-10">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>{{context.isTaskNotification ? 'Configure Task Status Correspondence for ' + (context.taskName ? context.taskName : '') : 'Matter Status Correspondence'}}</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
            <div class="clear"></div>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <div class="fields-group margin-top-0">
                    <form class="form-horizontal">
                        <div class="view-table access-inner-table">
                            <ng-container *ngIf="!context.isTaskNotification">
                                <table class="standard-table col-lg-12 access-profile-table table-striped"
                                       dp-table-key-commands>
                                    <thead>
                                    <tr>
                                        <th class="table-cell-1">
                                            Role
                                        </th>
                                        <th class="table-cell-2">
                                            Matter status
                                        </th>
                                        <th class="table-cell-3">
                                            Active
                                        </th>
                                        <th class="table-cell-4">Precedent Message</th>
                                        <th class="table-cell-5">
                                            Last Updated
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr *ngFor="let row of accountMatterNotificationConfigs; let i = index;">
                                        <td class="table-cell-1">{{row.getRecipientRoleLabel(account && account.accountProvinces.length &&account.accountProvinces[0].provinceCode)}}</td>
                                        <td class="table-cell-2">{{row.triggerEventLabel}}</td>
                                        <td class="table-cell-3">
                                            <dp-checkbox class="checkbox-position"
                                                         fieldId="active-checkbox_{{row.id}}"
                                                         [hasTabIndex]="true"

                                            >
                                                <input type="checkbox"
                                                       id="active-checkbox_{{row.id}}"
                                                       name="active-checkbox_{{row.id}}"
                                                       [(ngModel)]=row.active
                                                       [checked]=row.active
                                                />
                                            </dp-checkbox>
                                        </td>
                                        <td class="table-cell-4">
                                            <select  class="form-control"
                                                    [(ngModel)]="row.notificationPrecedentId"
                                                    name="notificationPrecedents_{{row.id}}">
                                                <option *ngFor="let opt of notificationPrecedents"
                                                        [ngValue]="opt.id" [hidden]="!opt.active">
                                                    {{opt.precedentName}}
                                                </option>
                                            </select>
                                        </td>
                                        <td class="table-cell-5">{{row.lastUpdatedTimeStamp | dpDateFormatPipe : ['noDay']}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-container *ngIf="context.isTaskNotification">
                                <div class="form-group">
                                    <div class="fixed-title">
                                        <div class="form-group main-level">
                                            <span class="title min-height-30 margin-bottom-0 padding-left-15">Select checkbox for those role- status
                                        combinations for which you want correspondence sent.</span>
                                        </div>
                                    </div>
                                </div>
                                <table class="standard-table fixed-header-table"
                                       dp-table-key-commands>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <ng-container *ngFor="let header of taskNotificationStatues; let i = index;">
                                            <th>
                                                {{header}}
                                            </th>
                                        </ng-container>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let row of taskNotificationsRows; let j = index;">
                                        <td>{{row.recipient.label}}</td>
                                        <ng-container *ngFor="let statusCol of row.statusCols; let  k = index;">
                                            <td>
                                                <dp-checkbox fieldId="isActive-{{j}}-{{k}}">
                                                    <input type="checkbox"
                                                           [(ngModel)]="statusCol.active"
                                                           id="isActive-{{j}}-{{k}}"
                                                           name="isActive-{{j}}-{{k}}"
                                                    />
                                                </dp-checkbox>
                                                <select type="text"
                                                        id="precedent-{{j}}-{{k}}"
                                                        name="precedent-{{j}}-{{k}}"
                                                        class="form-control"
                                                        style="padding-left: 10px;display: inline-block;width: 150px;margin-left: 10px;"
                                                        [disabled]="isNotificationPrecedentDisabled(statusCol)"
                                                        [(ngModel)]="statusCol.precedent"
                                                >
                                                    <option *ngFor="let notificationPrecedent of notificationPrecedents"
                                                            [value]="notificationPrecedent.id" [hidden]="!notificationPrecedent.active">
                                                        {{notificationPrecedent.precedentName}}
                                                    </option>
                                                </select>
                                            </td>
                                        </ng-container>
                                    </tr>
                                    </tbody>
                                </table>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="saveBtn"
                        [disabled]="false"
                        (click)="save()"
                        class="dp-btn">
                    <span>{{context.isTaskNotification ? 'Ok' :'Save'}}</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
