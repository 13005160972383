<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Unity<sup>&reg;</sup> Accounting</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()" #accountingModalClose>
                <span>&times;</span>
            </button>
        </div>
    </div>

    <div class="modal-body">
        <div class="row modal-body">
            <p>Unity<sup>&reg;</sup> Accounting is an all-in-one trust and general ledger accounting module for your law firm. The feature-rich module helps you improve your firm’s financial health and performance and maximize your law firm's efficiency. Designed for Canadian legal professionals with law society compliance, Unity<sup>&reg;</sup> Accounting is the #1 choice for thousands of legal professionals across Canada.</p>
            <br>
            <p>When using the Unity<sup>&reg;</sup> Accounting module, you can seamlessly:</p>
            <br>
            <ul class="accounting-feature-list">
                <li><span>Transfer Statement of Accounts data in real-time</span></li>
                <li><span>Capture all fees and disbursements, and</span></li>
                <li><span>Simplify financial reporting tasks.</span></li>
            </ul>
            <br>
            <p>Find out how our subscription bundles can save you money on your accounting software.</p>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12">
            <button type="button"
                    id="contactSalesAccountingButton"
                    (click)="goToLink('https://share.hsforms.com/1ssXYBiaDQyO4miVrZJZE5w5li52')"
                    class="dp-btn">
                <span>Contact Sales</span>
            </button>
            <button type="button"
                    id="launchAccountingButton"
                    (click)="goToLink('Unity-Accounting:open')"
                    class="dp-btn">
                <span>Launch Application</span>
            </button>
        </div>
    </div>
</div>
