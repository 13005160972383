import {Contact} from '../../matters/shared/contact';
import {ContactSuggestionSalutationInterface} from './contact-suggestion-salutation-interface';
import {MatterParticipantWrapper} from '../../matters/shared/matter-participant-wrapper';
import {ContactSuggestionBase} from './contact-suggestion-base';
import {Matter, MatterParticipant} from '../../matters';
import {MatterParticipantRole} from '../../matters/shared/matter-participant-role-types';
import {
  EnvelopeSalutationFormats,
  EnvelopeSalutationFormatType
} from '../../admin/document-profile/document-profile-edit/misc/EnvelopeSalutationFormats';

export class ContactSuggestionSalutationContinued extends ContactSuggestionBase implements ContactSuggestionSalutationInterface {

  getAssociationParticipants(contact: Contact, matter: Matter, parentParticipant: MatterParticipant): MatterParticipant[] {
    let associationParticipants: MatterParticipant[] = [];
    let matterParticipantRole: MatterParticipantRole = contact.getContactAssociationParticipantRoleByGender();
    if (matterParticipantRole) {
      associationParticipants = matter.getContactAssociationParticipantsByParentParticipantId(matterParticipantRole, parentParticipant.matterParticipantId);
      if (contact.gender === 'CORPORATION') { //If contact.gender === 'CORPORATION', only  the identified signing officers are tagged as signers will be concatenated
        associationParticipants = associationParticipants.filter(item => item.signingMatter);
      }
    }
    return associationParticipants;
  }

  addContactAssociations(contact: Contact, result: Map<EnvelopeSalutationFormatType, string>, matter: Matter, parentParticipant: MatterParticipant) {
    if (!matter && contact && contact.gender === 'ESTATE' && Array.isArray(contact.contactAssociations) && (contact.contactAssociations.length == 1)) {
      //The Envelope Salutation cont item will be modified to display “c/o” + <Space> + [Estate Trustee #1] + “,” + <Space> + “Estate Trustee”.
      // This will be the first item that will be displayed in the dropdown. This will ONLY be displayed if there is only one Estate Trustee.
      // For contact level
      if (contact.contactAssociations[ 0 ] && contact.contactAssociations[ 0 ].associatedContact && contact.contactAssociations[ 0 ].associatedContact.genericName) {
        let cont: string = 'c/o  ' + contact.contactAssociations[ 0 ].associatedContact.genericName + ', Estate Trustee';
        result.set(EnvelopeSalutationFormats.JOIN_ASSOCIATED_CONTACT, cont);
      }
    }
    if (contact && Array.isArray(contact.contactAssociations) && contact.contactAssociations.length > 0
      && matter && parentParticipant) {
      if (contact.gender === 'MALEPOA' || contact.gender === 'FEMALEPOA' || contact.gender === 'ESTATE' || contact.gender === 'CORPORATION') {
        let cont: string;
        let associationParticipants: MatterParticipant[] = matter.getAssociationParticipants(contact, parentParticipant);
        if (associationParticipants && associationParticipants.length > 0) {
          cont = 'c/o  ';

          associationParticipants.forEach((participant, index) => {
            if (participant.contact) {
              if (index) {
                if (index == associationParticipants.length - 1) {
                  cont += ' and ';
                } else {
                  cont += ', ';
                }
              }
              cont += participant.contact.genericName;
              if (contact.gender === 'CORPORATION' && participant.associatedContactTitle) {
                cont += ', ' + participant.associatedContactTitle;
              }
            }

          });
          switch (contact.gender) {
            case 'MALEPOA':
            case 'FEMALEPOA':
              cont += ', Power of Attorney';
              break;
            case 'ESTATE':
              cont += ', Estate Trustee';
              break;
            default:
              break;
          }
          //result.push(cont);
          result.set(EnvelopeSalutationFormats.JOIN_ASSOCIATED_CONTACT, cont);
        }
      }

    }
  }

  initSuggestions(contact: Contact, matter?: Matter, parentParticipant?: MatterParticipant): Map<EnvelopeSalutationFormatType, string> {
    let result = new Map<EnvelopeSalutationFormatType, string>();

    // TODO remove old  attorneyName1, estateTrustee1 and signingOfficer1
    switch (contact.gender) {
      case 'MALEPOA':
      case 'FEMALEPOA':
        this.addContactAssociations(contact, result, matter, parentParticipant);
        break;
      case 'ESTATE':
        this.addContactAssociations(contact, result, matter, parentParticipant);
        break;
      case 'CORPORATION':
        this.addContactAssociations(contact, result, matter, parentParticipant);
        break;
      default:
        break;
    }
    return result;
  }

  initMatterParticipantsSuggestions(wrappers: Array<MatterParticipantWrapper>, matter: Matter): Map<EnvelopeSalutationFormatType, string> {
    let result = new Map<EnvelopeSalutationFormatType, string>();
    let participants: MatterParticipantWrapper[] = Array.isArray(wrappers) ? wrappers.filter(item => item.matterParticipant && item.matterParticipant.contact) : [];
    if (Array.isArray(participants) && participants.length > 0) {
      if (participants.length === 1) {
        if (participants[ 0 ].matterParticipant) {
          return this.initSuggestions(participants[ 0 ].matterParticipant.contact, matter, participants[ 0 ].matterParticipant);
        }
      }
      if (participants.length >= 1 && this.allParticipantsArePersons(participants)) {
        //second client purchasers just a place holder
        if (participants.length === 2 && !participants[ 1 ].matterParticipant) {
          if (participants[ 0 ].matterParticipant) {
            return this.initSuggestions(participants[ 0 ].matterParticipant.contact);
          }
        }
        /*else if (participants.length === 2 && participants[0].matterParticipant && participants[1].matterParticipant) {
            let c0 = participants[0].matterParticipant.contact;
            let c1 = participants[1].matterParticipant.contact;

            if ((c0.gender === 'MALEPOA' || c0.gender === 'FEMALEPOA') && (c1.gender === 'MALEPOA' || c1.gender === 'FEMALEPOA')) {

                // purchasers are spouses and FLA statement 'MATTER_PARTICIPANT_SPOUSE' is in the list
                if ((participants[0].matterParticipant.matterParticipantId === participants[1].matterParticipant.spouseParticipantId ||
                        participants[1].matterParticipant.matterParticipantId === participants[0].matterParticipant.spouseParticipantId   ) &&
                    (c0.contactName.lastName === c1.contactName.lastName)) {

                    if (participants[0].matterParticipant.primary && c0.attorneyName1) {
                        result.push(`c/o ${c0.attorneyName1}, Attorney`);
                    }

                    if (participants[1].matterParticipant.primary && c1.attorneyName1) {
                        result.push(`c/o ${c1.attorneyName1}, Attorney`);
                    }

                }
            }
        }*/
      }
    }

    return result;
  }
}
