import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class EnvelopeDocument extends BaseEntity {
  constructor(envelopeDocument?: EnvelopeDocument) {
    super(envelopeDocument);
  }

  id: number;
  sourceDocumentId: number;
  sentTimeStamp: number;
  requiredAction: string;
  sharedDocumentType: string;
  deleted: boolean;

  isDocumentUpdated(): boolean {
    return this.requiredAction == 'UPDATED';
  }

  isDocumentDeleted(): boolean {
    return this.requiredAction == 'DELETED';
  }

}
