<section *ngIf="!hasErrors">
    <div class="row" *ngIf="isConveyancingUser">
        <div class="col-md-8">
            <div class="row" style="padding: 20px; background-color: #f5f6f7;">
                <div class="col-md-6" style="padding: 0px 10px 0px 0px;">
                    <div class="div-border" style=" background: #FFF; border-radius: 10px; padding: 0px;">
                        <div>
                            <table width="100%" style="margin-bottom:-30px;">
                                <tr>
                                    <td>
                                        <p class="content_title title-upcoming"
                                            style="margin-left: 16px; margin-top: 24px;">
                                            Upcoming
                                            <sup><i class="far fa-question-circle unity-text-color fs-mt"></i></sup>
                                        </p><br />
                                    </td>
                                    <td valign="middle">
                                        <!-- <a (click)="filterCall('events')"
                                        class="btn-right btn-bg nav_viewall unity-text-color pd-mt-12"
                                        rel="noopener">{{eventType}}</a> -->
                                        <select (change)="ChangeFilterForUpcomingWidget($event)"
                                            class="btn-right btn-bg nav_viewall unity-text-color pd-mt-12">
                                            <option *ngFor="let opt of filterEvents" [value]="opt.Status">{{opt.Status}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="c_title" style="padding-top: 10px;
                    padding-bottom: 6px;
                    padding-left: 15px;
                    padding-right: 16px;
                    border-top: 1px solid #E1E4E6; background: #F5F6F7;">
                            <ul class="date-picker">
                                <li [ngClass]="activeSunday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(1)">
                                        <p class="text-day">Sun</p>
                                        <span class="text-date align-center" rel="noopener">{{sunday}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="activeMonday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(2)">
                                        <p class="text-day">Mon</p>
                                        <span class="text-date align-center" rel="noopener">{{monday}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="activeTuesday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(3)">
                                        <p class="text-day">Tue</p>
                                        <span class="text-date align-center" rel="noopener">{{tuesday}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="activeWednesday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(4)">
                                        <p class="text-day">Wed</p>
                                        <span class="text-date align-center" rel="noopener">{{wednesday}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="activeThursday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(5)">
                                        <p class="text-day">Thur</p>
                                        <span class="text-date align-center" rel="noopener">{{thursday}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="activeFriday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(6)">
                                        <p class="text-day">Fri</p>
                                        <span class="text-date align-center" rel="noopener">{{friday}}</span>
                                    </a>
                                </li>
                                <li [ngClass]="activeSaturday ? 'active' : 'danger'">
                                    <a (click)="thisWeekData(7)">
                                        <p class="text-day">Sat</p>
                                        <span class="text-date align-center" rel="noopener">{{saturday}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <div class="upcoming-date" style="height: 30px;">
                            {{upcomingDisplayDate | date:'EEEE, MMMM d'}}
                        </div>

                        <div *ngIf="showLoadingIndicatorForEvent" class="text-center">
                            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                            <br>
                            Please wait while the data being prepared
                        </div>
                        <div class="matters_section" id="thisWeekData_area" style="padding: 14px;">
                            <div class="row">

                                <div class="col-3 event-loader"
                                    *ngIf="!showLoadingIndicatorForEvent && this.ThisWeekDataArray.length > 0">
                                    <p *ngIf="!this.showThisweekEventStatus" class="time-part">All Day</p>
                                </div>


                                <div class="col-12 pdr-mt">
                                    <table style="width:100%;">
                                        <tr *ngFor="let thisWeekData of ThisWeekDataArray | slice:0:WeekDataDefaultVisibleCount; let i=index"
                                            (click)="showMatterDetails(i, 'allDay')"
                                            style="border-bottom: 1px solid #E1E4E6 !important;">
                                            <td class="upcoming-events">
                                                <p class="event-name trim-text-upcoming">{{ thisWeekData.clientReLine }}
                                                </p>
                                            </td>
                                            <td class=" allday-events upcoming-eventtype">
                                                <p class="event-type trim-text">
                                                    {{ thisWeekData.eventType | eventTypeDisplay }} <span
                                                        *ngIf="thisWeekData.eventStatus == 'COMPLETED'"
                                                        style="float: right; margin-right: 5px;">&#10004;</span></p>
                                            </td>
                                        </tr>
                                    </table>


                                    <button *ngIf="WeekDataDefaultVisibleCount < ThisWeekDataArray.length"
                                        class="btn-right btn-bg nav_viewall "
                                        (click)="WeekDataDefaultVisibleCount = ThisWeekDataArray.length">Show
                                        {{ThisWeekDataArray.length - 5}}
                                        More</button>
                                    <button
                                        *ngIf="WeekDataDefaultVisibleCount >= ThisWeekDataArray.length && ThisWeekDataArray.length > 5"
                                        class="btn-right btn-bg nav_viewall"
                                        (click)="WeekDataDefaultVisibleCount = 5">Show
                                        Less</button>
                                </div>
                                <div *ngIf="this.showThisweekEventStatus" class="text-center">
                                    No Events
                                </div>
                                <div class="col-12 pd-mt-15">
                                    <div class="row" *ngFor="let thisWeekData of AppointmentDataArray; let i=index"
                                        (click)="showMatterDetails(i, 'appt')">
                                        <div class="col-3 pd-mt">
                                            <p class="time-part">{{ thisWeekData.appointmentTime}}</p>
                                        </div>
                                        <div class="col-9 pdr-mt">
                                            <p class="event-today-name trim-text">
                                                {{ thisWeekData.name }}</p>
                                            <p class="event-today-status trim-text">
                                                {{ thisWeekData.eventDescription }}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <br />

                    <div class="div-border matter-widget"
                        style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 20px;">
                        <div class="mt-0">
                            <table width="100%">
                                <tr>
                                    <td>
                                        <p class="content_title" style="margin-left: -2px;">Matters
                                            <sup><i class="far fa-question-circle unity-text-color fs-mt"></i></sup>
                                        </p>
                                    </td>
                                    <td>
                                        <!-- <a (click)="filterCall('matters')"
                                    class="btn-right btn-bg nav_viewall unity-text-color mr-2-mt-15"
                                    rel="noopener">{{matterType}}</a> -->
                                        <select (change)="ChangeFilterForMattersWidget($event)"
                                            class="btn-right btn-bg nav_viewall unity-text-color pd-mt-12">
                                            <option *ngFor="let opt of filterMatters" [value]="opt.Status">
                                                {{opt.Status}}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <nav #mattersNav class="nav-tabs nav" role="tablist">
                            <a #clerkTabHeader (click)="toogleTabActive(mattersNav, $event, mattersNavContent)"
                                role="tab" data-tab-id="1" class="nav-link nav-item active">By Clerk</a>
                            <a #lawyerHeader (click)="toogleTabActive(mattersNav, $event, mattersNavContent)" role="tab"
                                data-tab-id="2" class="nav-link nav-item">By Lawyer</a>
                            <a #cityHeader (click)="toogleTabActive(mattersNav, $event, mattersNavContent)" role="tab"
                                data-tab-id="3" class="nav-link nav-item">By City</a>
                        </nav>
                        <div #mattersNavContent class="mt-2 tab-content">
                            <div class="tab-pane d-block" role="tabpanel" data-tab-id="1">
                                <div class="over_content py-3 px-3 px-sm-0">
                                    <div #clerkChart class="text-center">
                                        <div *ngIf="this.showLoadingIndicatorForMatter" class="text-center">
                                            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                            <br>
                                            Please wait while the chart is being prepared
                                        </div>
                                    </div>
                                    <div *ngIf="this.showClerkChart" class="text-center p-20">
                                        No Matters
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane d-none" role="tabpanel" data-tab-id="2">
                                <div class="document_content py-3 px-3 px-sm-0">
                                    <div #lawyerChart class="text-center">
                                        <div *ngIf="showLoadingIndicatorForMatter" class="text-center">
                                            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                            <br>
                                            Please wait while the chart is being prepared
                                        </div>
                                    </div>
                                    <div *ngIf="this.showLawyerChart" class="text-center p-20">
                                        No Matters
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane d-none" role="tabpanel" data-tab-id="3">
                                <div class="document_content py-3 px-3 px-sm-0">
                                    <div #cityChart class="text-center">
                                        <div *ngIf="showLoadingIndicatorForMatter" class="text-center">
                                            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                            <br>
                                            Please wait while the chart is being prepared
                                        </div>
                                    </div>
                                    <div *ngIf="this.showCityChart" class="text-center p-20">
                                        No Matters
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6" style="padding: 0px 0px 0px 10px;">
                    <div class="div-border"
                        style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 20px;">
                        <div>
                            <p class="content_title" style="margin-left: -2px;">
                                Closed Matter Revenue<span class="white-space"><sup><i
                                            class="far fa-question-circle unity-text-color fs-mt"></i></sup></span>
                            </p>
                            <div *ngIf="this.showLoadingIndicatorForMatterRevenue" class="text-center"
                                style="margin-top: 15px;">
                                <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                <br>
                                Please wait while the data being prepared
                            </div>
                            <div #MatterRevenue>

                            </div>
                            <div *ngIf="!this.showRevenue" class="text-center">
                                No Data
                            </div>
                            <br />
                        </div>
                    </div>

                    <br />

                    <!-- <div *ngIf="!ShowTransactionsWidget" class="div-border" -->
                    <div class="div-border"
                        style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 1px;">
                        <p style="display: block;" class="content_title mt-20-ml-2">
                            Transaction Portfolio<span style="white-space: nowrap;"><sup><i
                                        class="far fa-question-circle unity-text-color fs-mt"></i></sup></span>
                        </p>
                        <div style="height: auto; display: block;">
                            <nav #transNav class="nav-tabs nav" role="tablist">
                                <a #transPastMonthTabHeader (click)="toogleTabActive(transNav, $event, transNavContent)"
                                    role="tab" data-tab-id="1" class="nav-link nav-item active">Past Month</a>
                                <a #transYearlyTabHeader (click)="toogleTabActive(transNav, $event, transNavContent)"
                                    role="tab" data-tab-id="2" class="nav-link nav-item">Yearly</a>
                            </nav>
                            <div #transNavContent class="mt-2 tab-content">
                                <div class="tab-pane d-block" role="tabpanel" data-tab-id="1">
                                    <div class="over_content piechart-spacing  py-3 px-3 px-sm-0"
                                        style="margin-top: 0px;">
                                        <div #thisMonthTransaction>
                                        </div>
                                        <div *ngIf="this.showLoadingIndicatorForOpeningForecast" class="text-center"
                                            style="margin-top: 150px;">
                                            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                            <br>
                                            Please wait while the chart is being prepared
                                        </div>
                                        <div *ngIf="this.showThisMonthNoTransaction" class="text-center p-20">
                                            No Transactions
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane d-none" role="tabpanel" data-tab-id="2">
                                    <div class="document_content piechart-spacing py-3 px-3 px-sm-0"
                                        style="margin-top: 0px; ">
                                        <div #thisYearTransaction class="text-center">
                                        </div>
                                        <div *ngIf="showLoadingIndicatorForOpeningForecast" class="text-center"
                                            style="margin-top: 150px;">
                                            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                            <br>
                                            Please wait while the chart is being prepared
                                        </div>
                                        <div *ngIf="this.showThisYearNoTransaction" class="text-center p-20">
                                            No Transactions
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <br *ngIf="ShowTransactionsWidget" /> -->
                    <br />

                    <div class="div-border"
                        style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 1px;">
                        <p style="display: block;" class="content_title mt-20-ml-2">
                            <span style="white-space: nowrap;"> Matter Types
                            </span>
                        </p>
                        <nav #typesNav class="nav-tabs nav" role="tablist">
                            <a #typesPastMonthTabHeader (click)="toogleTabActive(typesNav, $event, typesNavContent)"
                                role="tab" data-tab-id="1" class="nav-link nav-item active">Past Month</a>
                            <a #typesYearlyTabHeader (click)="toogleTabActive(typesNav, $event, typesNavContent)"
                                role="tab" data-tab-id="2" class="nav-link nav-item">Yearly</a>
                        </nav>
                        <div #typesNavContent class="mt-2 tab-content">
                            <div class="tab-pane d-block" role="tabpanel" data-tab-id="1">
                                <div class="over_content piechart-spacing  py-3 px-3 px-sm-0" style="margin-top: 0px;">
                                    <div #pastMonthMatterTypes>
                                    </div>
                                    <div *ngIf="this.showLoadingIndicatorForForecast" class="text-center"
                                        style="margin-top: 150px;">
                                        <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                        <br>
                                        Please wait while the chart is being prepared
                                    </div>
                                    <div *ngIf="this.showThisMonthNoMatterTypes" class="text-center">
                                        No Transactions
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane d-none" role="tabpanel" data-tab-id="2">
                                <div class="document_content piechart-spacing py-3 px-3 px-sm-0"
                                    style="margin-top: 0px; ">
                                    <div #thisYearMatterTypes class="text-center">
                                    </div>
                                    <div *ngIf="this.showLoadingIndicatorForForecast" class="text-center"
                                        style="margin-top: 150px;">
                                        <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                        <br>
                                        Please wait while the chart is being prepared
                                    </div>
                                    <div *ngIf="this.showThisYearNoMatterTypes" class="text-center">
                                        No Transactions
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Other Widgets now Hidden -->
                <!-- <div class="col-md-4">
                <div class="div-border"
                    style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 1px;">
                    <p class="content_title mt-20-ml-2">Closings
                        <sup><i class="far fa-question-circle unity-text-color fs-mt"></i></sup>
                    </p><br />

                    <div *ngIf="showLoadingIndicatorForForecast" class="text-center p-20">
                        <lib-widget-loader compClass="lds-ring loaderMarginF"></lib-widget-loader>
                        <br>
                        Please wait while the closing forecast being prepared
                    </div>
                    <div *ngIf="this.matterClosingForcastData.length != 0" class="text-center">
                        <div #ClosingForecast style="height:300px;">

                        </div>
                    </div>
                    <div *ngIf="this.showNoMatterClosingForcastData" class="text-center">
                        Data not available
                    </div>
                </div>

                <br />

                <div class="div-border"
                    style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 1px;">
                    <p class="content_title mt-20-ml-2">Matters Opened
                        <sup><i class="far fa-question-circle unity-text-color fs-mt"></i></sup>
                    </p><br />

                    <div *ngIf="showLoadingIndicatorForOpeningForecast" class="text-center p-20">
                        <lib-widget-loader compClass="lds-ring loaderMarginF"></lib-widget-loader>
                        <br>
                        Please wait while the chart is being prepared
                    </div>
                    <div *ngIf="!this.showNoOpeningForecast && !showLoadingIndicatorForOpeningForecast" class="legend"
                        #OpeningForecast style="height:300px;">

                    </div>
                    <div *ngIf="this.showNoOpeningForecast" class="text-center">
                        Access is denied
                    </div>
                </div>

                <br />

                <div *ngIf="ShowReferalsWidget" class="div-border"
                    style="background: #FFF; border-radius: 10px; padding-left: 16px; padding-right: 16px; padding-top: 20px;">
                    <div class="mt-0" id="referrals">
                        <table width="100%">
                            <tr>
                                <td>
                                    <p class="content_title" style="margin-left: -2px;">Referrals
                                        <sup><i class="far fa-question-circle unity-text-color fs-mt"></i></sup>
                                    </p>
                                </td>
                                <td>
                                    <select (change)="ChangeFilterForRefferalsWidget($event)"
                                        class="btn-right btn-bg nav_viewall unity-text-color pd-mt-12">
                                        <option *ngFor="let opt of filterReferrals" [value]="opt.Status">{{opt.Status}}</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <nav #refferalNav class="nav-tabs nav" role="tablist">
                        <a #refferalPastMonthTabHeader (click)="toogleTabActive(refferalNav, $event, refferalNavContent)"
                            role="tab" data-tab-id="1" class="nav-link nav-item active">By Name</a>
                        <a #refferalYearlyTabHeader (click)="toogleTabActive(refferalNav, $event, refferalNavContent)"
                            role="tab" data-tab-id="2" class="nav-link nav-item">By Type</a>
                    </nav>
                    <div #refferalNavContent class="mt-2 tab-content">
                        <div class="tab-pane d-block" role="tabpanel" data-tab-id="1">
                            <div *ngIf="this.showLoadingIndicatorForReferrals" class="text-center" style="margin-top: 15px;">
                                <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                <br>
                                Please wait while the data being prepared
                            </div>
                            <div class="over_content py-3 px-sm-0 margin-lr-5" style="padding-top: 1rem; padding-bottom: 1rem;">
                                <div class="row referral_divider" *ngFor="let names of referralsByName">

                                    <div class="col-sm-10 pl-0">
                                        <p class="float-left"><span
                                                class="refereal-text trim-text-referals">{{names.name}}&nbsp; </span></p>
                                    </div>

                                    <div class="col-sm-2 pr-0">
                                        <p class="float-right" style="margin-right: 5px;">
                                            <span class="fs-fw refereal-count">{{names.count}}</span>
                                        </p>
                                    </div>
                                </div>

                                <div *ngIf="this.showNoReferralsByName" class="text-center p-20">
                                    No Referrals
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane d-none" role="tabpanel" data-tab-id="2">
                            <div *ngIf="this.showLoadingIndicatorForReferrals" class="text-center" style="margin-top: 15px;">
                                <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
                                <br>
                                Please wait while the data being prepared
                            </div>
                            <div class="over_content py-3 px-sm-0 margin-lr-5" style="padding-top: 1rem; padding-bottom: 1rem;">
                                <div class="row referral_divider" *ngFor="let names of referralsByCategory">

                                    <div class="col-sm-10 pl-0">
                                        <p class="float-left"><span
                                                class="refereal-text trim-text-referals">{{names.name}}&nbsp; </span></p>
                                    </div>

                                    <div class="col-sm-2 pr-0">
                                        <p class="float-right" style="margin-right: 5px;">
                                            <span class="fs-fw refereal-count">{{names.count}}</span>
                                        </p>
                                    </div>
                                </div>

                                <div *ngIf="this.showNoReferralsByCategory" class="text-center p-20">
                                    No Referrals
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <br />
            </div> -->
            </div>
        </div>
        <div class="col-md-4" [innerHtml]="loggedInUserContent">
            <lib-widget-loader compClass="lds-ring"></lib-widget-loader>
        </div>
    </div>
    <div class="row" *ngIf="!isConveyancingUser">
        <div class="col-md-12" [innerHtml]="nonLoggedInUserContent"></div>
    </div>
</section>
<section *ngIf="hasErrors">
    <div style="height: 200px;">
        <h1 style="margin-top: 100px; text-align: center;">Error: Try again later!</h1>
    </div>
</section>
