import {BaseEntity} from '../../shared/BaseEntity/base-entity';

const propertyIgnoreList = [ 'id' ];

export class BasicUserInfo extends BaseEntity {
  firstName: string;
  lastName: string;
  id: number;
  loginId: string;

  constructor(user?: BasicUserInfo) {
    super(user);
    if (user) {
      for (let prop in user) {
        if (user.hasOwnProperty(prop)) {
          this[ prop ] = user[ prop ];
        }
      }
    }
  }

  get fullName(): string {
    return `${ this.firstName || '' } ${ this.lastName || '' }`.trim();
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }
}

