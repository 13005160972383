<div id="lendingInstitutions" *ngIf="lenders && lenders.length > 0">

    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1>Select Lender</h1></div>
            <button type="button" class="btn-close pull-right" (click)="onCancel()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="sticky-header">
            <div class="row">
                <div class=" col-lg-12">
                    <p style="text-align:center">
                        {{headerMessage}}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 margin-top-10 margin-left-5 margin-bottom-15 padding-right-30">
                    <div class="form-group">
                        <div class="col-lg-12">
                            <input class="form-control focus-first" id="search-lender" (keydown)="onKeyPress($event)"
                                   name="search-lender" [(ngModel)]="lenderSearchText" (ngModelChange)="locateLender()"
                                   placeholder="Search by Name"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 margin-top-10 table-index" >
            <div class="category-title">
            </div>
            <div>
                <table class="table dp-paragraph" (keydown)="onKeyPress($event)">
                    <tbody>
                    <tr *ngFor="let row of filteredCopyOfLenders;let i=index;"
                        (click)="updateSelectedItem(i)"
                        (dblclick)="onSelectLender()"
                        (focus)="updateSelectedItem(i)">
                        <td [ngClass]="{'dp-paragraph-copy-active': isRowSelected(i)}">
                            <span id="lenderName-{{i}}" [ngClass]="{'dp-paragraph-copy-active': isRowSelected(i)}">
                                {{row.institutionName}}
                            </span>
                        </td>
                        <td [ngClass]="{'dp-paragraph-copy-active': isRowSelected(i)}">
                            <span id="alternateName-{{i}}" [ngClass]="{'dp-paragraph-copy-active': isRowSelected(i)}">
                                {{row.alternateName}}
                            </span>
                        </td>
                        <td [ngClass]="{'dp-paragraph-copy-active': isRowSelected(i)}">
                            <span id="institutionNumber-{{i}}" [ngClass]="{'dp-paragraph-copy-active': isRowSelected(i)}">
                                {{row.institutionNumber}}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="filteredCopyOfLenders.length === 0" class="loading-row">
                        <td colspan="11" class="text-center"><div class="no-match-found">No Match Found</div></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn" (click)="onSelectLender()" [disabled]="selectedLenderIndex < 0">
                <span>Select</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" *ngIf="showDoNotLinkButton" (click)="doNotLinkLender()">
                <span>Do Not Link</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="onCancel()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
