<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class=" col-lg-12">
            <div *ngIf="!undertaking.onCCT" class="col-lg-10"><h1>Undertaking Editor</h1></div>
            <div *ngIf="undertaking.onCCT" class="col-lg-10"><h1>{{getEncumbranceTitle()}}</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body model-height">
        <div class="col-lg-12">
            <div class="form-group">
                <div class="margin-bottom-20" *ngIf="!undertaking.onCCT">
                    <label class="control-label col-lg-2" *ngIf="!isDefaultMatterUndertakingsForNB()">{{getSubjectLabel()}}</label>
                    <div class="col-lg-10" *ngIf="subjectEditable()">
                        <input class="form-control focus-first"
                               trimmed
                               id="subject1"
                               name="subject1"
                               statusBarHelp
                               fieldKey="matter.undertaking-details-modal.subject"
                               [(ngModel)]="undertaking.subject"
                        >
                    </div>
                    <div class="col-lg-10" *ngIf="!subjectEditable()">
                        <input class="form-control focus-first"
                               id="subject2"
                               name="subject2"
                               [ngModel]="subject"
                               maxlength="{{maxFieldLengthForSubject}}"
                               [readonly]="!subjectEditable()"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="undertaking.onCCT">
            <div class="form-group">
                <div class="margin-bottom-20">
                    <label class="col-lg-12" [dp-province-field-label]="'matter-undertaking-detail-modal-undertakingLabel'">
                        The solicitor for the vendor undertakes:
                    </label>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="undertaking.onCCT">
            <div class="form-group">
                <div class="margin-bottom-20">
                    <label class="control-label col-lg-2">Action to be taken</label>
                    <div class="col-lg-10">
                        <input class="form-control focus-first"
                               id="actionToBeTaken"
                               name="actionToBeTaken"
                               trimmed
                               maxlength="255"
                               [(ngModel)]="undertaking.actionToBeTaken"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="undertaking.onCCT">
            <div class="form-group">
                <div class="margin-bottom-20">
                    <label class="control-label col-lg-2" [dp-province-field-label]="'matter.undertaking.instrumentType'">Type of Instrument</label>
                    <div class="col-lg-10">
                        <input class="form-control"
                               id="instrumentType"
                               name="instrumentType"
                               trimmed
                               maxlength="255"
                               [(ngModel)]="undertaking.instrumentType"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="undertaking.onCCT">
            <div class="form-group">
                <div class="margin-bottom-20">
                    <label class="control-label col-lg-2" [dp-province-field-label]="'matter.undertaking.instrumentNo'">Instrument Number</label>
                    <div class="col-lg-10">
                        <input class="form-control"
                               id="instrumentNo"
                               name="instrumentNo"
                               trimmed
                               maxlength="255"
                               [(ngModel)]="undertaking.instrumentNo"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="undertaking.onCCT && !undertaking.isTypeVendorsLienCaveat()">
            <div class="form-group">
                <div class="margin-bottom-20">
                    <label class="control-label col-lg-2">In favour of</label>
                    <div class="col-lg-10">
                        <input class="form-control"
                               id="inFavourOf"
                               name="inFavourOf"
                               trimmed
                               maxlength="2000"
                               [(ngModel)]="undertaking.inFavourOf"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="undertaking.onCCT">
            <div class="form-group">
                <div class="margin-bottom-20">
                    <label class="control-label col-lg-2">Notes</label>
                    <div class="col-lg-10">
                        <input class="form-control"
                               id="notes"
                               name="notes"
                               trimmed
                               maxlength="255"
                               [(ngModel)]="undertaking.notes"
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" *ngIf="isAdditionalDataVisible()">
            <div class="form-group">
                <div class="margin-top-20">
                    <label class="col-lg-12">Additional data for preparation of Discharge:</label>
                </div>
            </div>
        </div>
        <div class="col-lg-12 padding-top-10" *ngIf="isAdditionalDataVisible()">
            <div class="form-group">
                <label class="control-label col-lg-2" [dp-province-field-label]="'matter.undertaking.dateOfInstrument'">Date of Instrument</label>
                <div class="col-lg-4">
                    <dp-partial-date #dateOfInstrument
                                     [inputDate]="undertaking.dateOfInstrument"
                                     (dateChange)="onInstrumentDate($event)"
                                     [fieldKey]="'modal.undertaking.dateOfInstrument'"
                                     [fullDateRequired]="true"
                                     dp-error-validator
                    >
                    </dp-partial-date>
                </div>
                <label class="control-label col-lg-2">Registration Date</label>
                <div class="col-lg-4">
                    <dp-partial-date #registeredDate
                                     id="registeredDate"
                                     [inputDate]="undertaking.registeredDate"
                                     (dateChange)="onRegisteredDate($event)"
                                     fieldKey="modal.undertaking.registeredDate"
                                     [fullDateRequired]="true"
                                     dp-error-validator
                                     statusBarHelp
                    >
                    </dp-partial-date>
                </div>
            </div>
        </div>
        <div class="row margin-top-20">
            <div class="col-lg-12">
                <p class="margin-top-20 margin-bottom-10">{{undertakingDescriptionLabel}}</p>
                <textarea *ngIf="!undertaking.onCCT"
                          [ngClass]="isDefaultMatterUndertakingsForNB()? 'scroll-bar-height-default-nb' : 'scroll-bar'"
                          name="description"
                          id="description"
                          rows="4"
                          #descriptionArea
                          cols="80"
                          statusBarHelp
                          [fieldKey]="descriptionFieldKey"
                          (focusout)="setDescriptionAreaFocusFlag()"
                          [(ngModel)]="undertaking.description">
                    </textarea>
                <textarea class="scroll-bar" *ngIf="undertaking.onCCT"
                          name="description"
                          id="description"
                          rows="4"
                          #descriptionArea
                          cols="80"
                          [disabled]="true"
                          (focusout)="setDescriptionAreaFocusFlag()"
                          >{{undertakingDescription}}
                    </textarea>
            </div>
        </div>

        <div class="col-lg-12 padding-top-20" *ngIf="!isDefaultMatterUndertakingsForNB()">
            <div class="form-group">
                <label class="control-label col-lg-2">Undertaking Status</label>
                <div class="col-lg-4">
                    <select class="form-control" id="undertakingStatus" name="undertakingStatus"
                            [(ngModel)]="undertaking.matterUndertakingStatus">
                        <option *ngFor="let matterUndertakingStatusOption of matterUndertakingStatusOptions"
                                [value]="matterUndertakingStatusOption.value">
                            {{matterUndertakingStatusOption.label}}
                        </option>
                    </select>
                </div>
                <label class="control-label col-lg-2">Follow-up Date</label>
                <div class="col-lg-4">
                    <dp-partial-date #followupDate
                                     [inputDate]="undertaking.followupDate"
                                     (dateChange)="onFollowupDate($event)"
                                     [fieldKey]="'modal.undertaking.followupDate'"
                                     [fullDateRequired]="true"
                                     dp-error-validator
                    >
                    </dp-partial-date>
                </div>
            </div>
        </div>

        <div class="col-lg-12" *ngIf="isUndertakingStatusFulfilled">
            <ng-container *dpShowByProvince="'matter.undertaking-detail-modal.discharged'">
                <div class="form-group">
                    <label class="control-label col-lg-2" [dp-province-field-label]="'matter.undertaking-detail-modal.discharged'">Discharged by Instrument No.</label>
                    <div class="col-lg-4">
                        <input type="text"
                               trimmed
                               id="dischargedByInstrumentNo"
                               name="dischargedByInstrumentNo"
                               class="form-control"
                               [(ngModel)]="undertaking.dischargedByInstrumentNo"
                               maxlength="40">
                    </div>
                    <label class="control-label col-lg-2">Registered on Date</label>
                    <div class="col-lg-4">
                        <dp-partial-date #registeredDate
                                         id="registeredDate"
                                         [inputDate]="undertaking.registeredDate"
                                         (dateChange)="onRegisteredDate($event)"
                                         fieldKey="modal.undertaking.registeredDate"
                                         [fullDateRequired]="true"
                                         dp-error-validator
                                         statusBarHelp
                        >
                        </dp-partial-date>
                    </div>
                </div>
            </ng-container>

        </div>

        <div class="col-lg-12 padding-top-20" *dpShowByProvince="'matter.undertaking-detail-modal.bottom.checkboxes'">
            <div class="form-group">
                <div class="col-lg-5">
                    <dp-checkbox fieldId="approvedForSoAdjFlag">
                        <input id="approvedForSoAdjFlag"
                               name="approvedForSoAdjFlag"
                               type="checkbox"
                               [(ngModel)]="undertaking.approvedForSoAdjFlag"
                               (ngModelChange)="approvedSOAFlagChange()"
                        />
                    </dp-checkbox>
                    <label class="margin-left-10" for="approvedForSoAdjFlag">Approved for Statement of Adjustments</label>
                </div>
                <div class="col-lg-5" *ngIf="undertaking.approvedForSoAdjFlag == true && !isDefaultMatterUndertakingsForNB()">
                    <dp-checkbox fieldId="purchaserUndertakingFlag">
                        <input id="purchaserUndertakingFlag"
                               name="purchaserUndertakingFlag"
                               type="checkbox"
                               [(ngModel)]="undertaking.purchaserUndertakingFlag"
                        />
                    </dp-checkbox>
                    <label class="margin-left-10" for="purchaserUndertakingFlag">Purchaser's Undertaking</label>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn keyboard-save-action" (click)="onOK()" #okButtonControl id="okBtn">
                <span>OK</span>
            </button>
            <button *ngIf="!undertaking.onCCT" type="button" class="secondary-button" (click)="onInsertPrecedent()">
                <span>Insert Precedent</span>
            </button>
            <button *ngIf="!undertaking.onCCT" type="button" class="secondary-button" (click)="onSavePrecedent()">
                <span>Save Precedent</span>
            </button>
            <button type="button" class="secondary-button" *ngIf="showDelete" (click)="onDelete()">
                <span>Delete</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</form>


