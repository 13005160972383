import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import Utils from '../../shared-main/utils';

export class RollNumber extends BaseEntity {

  city: string;

  municipality: string;

  map: string;

  subdivision: string;

  parcel: string;

  portion: string;

  jurisdiction: string;

  public static createDefaultRollNumber(): RollNumber {
    let rollNumber: RollNumber = new RollNumber();
    rollNumber.city = '';
    rollNumber.municipality = '';
    rollNumber.map = '';
    rollNumber.subdivision = '';
    rollNumber.parcel = '';
    rollNumber.portion = '';
    rollNumber.jurisdiction = '';
    return rollNumber;
  }

  isEmpty(): boolean {
    return Utils.isEmptyString(this.city)
      && Utils.isEmptyString(this.municipality)
      && Utils.isEmptyString(this.map)
      && Utils.isEmptyString(this.subdivision)
      && Utils.isEmptyString(this.parcel)
      && Utils.isEmptyString(this.portion)
      && Utils.isEmptyString(this.jurisdiction);
  }
}

