import {Component, DoCheck, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {CurrencyPipe} from '@angular/common';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import moment from 'moment';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../shared/matter-drop-downs';
import {StatementOfAdjustmentHeading} from './model/statement-adjustment-heading';
import {InterestSoa} from './interest-soa';
import {Project} from '../../projects/shared/project';
import {Matter} from '../shared/matter';
import Utils from '../../shared-main/utils';
import {StatementAdjustmentKey} from './statement-adjustment-constants';
import {booleanYesNoDropDowns} from '../../shared-main/constants';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {Tab} from '../../shared/tabbing';
import {TabsService} from '../../core';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class SoaInterestModalContext {
  public closingDate: string;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public interestSoa: InterestSoa;
  public isPaysForDateOfClosingVendor: boolean;
  public matter: Matter;
  public project: Project;
  public statementAdjustmentKey: string;
}

@Component({
  selector: 'dp-interest-soa-modal-content',
  templateUrl: './interest-soa.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class SoaInterestModal extends ModalComponent<SoaInterestModalContext> implements DoCheck {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = 'Closing Date';
  interestCalculationTypes: SelectItem[];
  purchaserOrVendorTypes: SelectItem[];
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.YesNo_DefaultYes;
  interestSoa: InterestSoa;
  interest: string = '0';
  _updateIntersetSoa: InterestSoa;

  constructor(
    public dialog: MatDialogRef<SoaInterestModal>,
    public currencyPipe: CurrencyPipe,
    public tabsService: TabsService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: SoaInterestModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (
      (this.context.project && this.context.project.isCondo) ||
      (this.context.matter && this.context.matter.isProjectSale && this.context.matter.isCondoCorporation)
    ) {
      this.interestCalculationTypes = dropDowns.interestCalculationProjectTypes;
    } else {
      this.interestCalculationTypes = dropDowns.interestCalculationTypes;
    }
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '???????');
    }
    this.purchaserOrVendorTypes = [
      {label: this.purchaserLabel, value: this.purchaserLabel},
      {
        label: this.vendorLabel,
        value: this.vendorLabel
      }
    ];

    if (!this.context.interestSoa) {
      this.interestSoa = new InterestSoa();
    } else {
      this.interestSoa = new InterestSoa(this.context.interestSoa);
    }

    if (this.isInterestOnDeferredMoniesAdjustment()) {
      if (!this.interestSoa.interestCalculationPeriod) {
        this.interestSoa.interestCalculationPeriod = 'FORM_INTERIM_CLOSING_TO_ADJUSTMENT_DATE';
        this.interestSoa.interestCalculatedFrom = this.context.closingDate;
      }

      if (!this.interestSoa.isInterestCreditTo) {
        this.interestSoa.isInterestCreditTo = this.vendorLabel;
      }

      if (!this.interestSoa.sameInterestRateAsInterim) {
        this.interestSoa.sameInterestRateAsInterim = DpBooleanValueTypes.Y_n;
      }

      if (this.context.project && this.context.project.projectCondo) {
        if (this.context.project.projectCondo.isOccupancyFeesCalculatedBasedOnPurchaseMonies) {
          this.interestSoa.itemName = 'DEFERRED PURCHASE MONIES';
        } else if (this.context.project.projectCondo.isOccupancyFeesCalculatedBasedOnPhantomMortgage) {
          this.interestSoa.itemName = 'VTB MORTGAGE';
        }
      }
    } else {
      if (!this.interestSoa.interestCalculationPeriod) {
        this.interestSoa.interestCalculationPeriod = 'COMMENCING_ON_ADJUSTMENT_DATE';
        this.interestSoa.interestCalculatedFrom = this.context.closingDate;
      }

      if (!this.interestSoa.isInterestCreditTo) {
        this.interestSoa.isInterestCreditTo = this.purchaserLabel;
      }
    }

    if (this.interestSoa.principalAmount == undefined) {
      this.interestSoa.principalAmount = 0;
    }
    if (this.interestSoa.interestRate == undefined) {
      this.interestSoa.interestRate = 0.0;
    }

    if (this.isInterestOnDeferredMoniesAdjustment()) {
      this.interestSoa.applyUpdatesBasedOnProjectSettings(this.context.matter, this.context.project);
    }

    this.interestSoa.applyMatterUpdatesOnInterestCalculationPeriod(this.context.matter);
    this.updateInterest();
    this._updateIntersetSoa = new InterestSoa(this.interestSoa);
  }

  ngDoCheck() {
    let isInterestUpdated = this.interestSoa.equals(this._updateIntersetSoa);
    if (!isInterestUpdated) {
      this.updateInterest();
      this._updateIntersetSoa = new InterestSoa(this.interestSoa);
    }
  }

  onInterestCalculationChange(): void {
    if (this.interestSoa.interestCalculationPeriod == 'COMMENCING_ON_ADJUSTMENT_DATE') {
      this.interestSoa.interestCalculatedFrom = this.context.closingDate;
      this.interestSoa.interestCalculatedTo = '';
    } else if (this.interestSoa.interestCalculationPeriod == 'ENDING_ON_ADJUSTMENT_DATE') {
      this.interestSoa.interestCalculatedFrom = '';
      this.interestSoa.interestCalculatedTo = this.context.closingDate;
    } else if (this.interestSoa.interestCalculationPeriod == 'ENDING_ON_INTERIM_CLOSING_DATE') {
      this.interestSoa.interestCalculatedFrom = '';
      if (this.context.matter && this.context.matter.isProjectSale) {
        //The "to" field is read-only and populated with the Occupancy date
        this.interestSoa.interestCalculatedTo = this.context.matter.getOccupancyDateForMatterType();
      } else {
        this.interestSoa.interestCalculatedTo = moment(this.context.matter.createdTimeStamp).format('YYYY/MM/DD');
      }
    } else if (this.interestSoa.interestCalculationPeriod == 'FORM_INTERIM_CLOSING_TO_ADJUSTMENT_DATE') {
      if (this.context.matter && this.context.matter.isProjectSale) {
        //The "From" field is read-only and populated with the Occupancy date
        this.interestSoa.interestCalculatedFrom = this.context.matter.getOccupancyDateForMatterType();
      } else {
        this.interestSoa.interestCalculatedFrom = moment(this.context.matter.createdTimeStamp).format('YYYY/MM/DD');
      }
      if (
        this.context.matter.adjustAsAtClosingDateFlag == 'CLOSING_DATE' ||
        this.context.matter.adjustAsAtClosingDateFlag == 'ADJUSTMENT_DATE'
      ) {
        this.interestSoa.interestCalculatedTo = this.context.closingDate;
      } else if (this.context.matter.adjustAsAtClosingDateFlag == 'OCCUPANCY_DATE') {
        this.interestSoa.interestCalculatedTo = this.context.matter.getOccupancyDateForMatterType();
      } else {
        this.interestSoa.interestCalculatedTo = this.context.closingDate; // this.context.matter.adjustAsAtClosingDate;
      }
    } else if (this.interestSoa.interestCalculationPeriod == 'COMMENCING_ON_INTERIM_CLOSING_DATE') {
      if (this.context.matter && this.context.matter.isProjectSale) {
        //The "From" field is read-only and populated with the Occupancy date
        this.interestSoa.interestCalculatedFrom = this.context.matter.getOccupancyDateForMatterType();
      } else {
        this.interestSoa.interestCalculatedFrom = moment(this.context.matter.createdTimeStamp).format('YYYY/MM/DD');
      }
      this.interestSoa.interestCalculatedTo = '';
    } else {
      this.interestSoa.interestCalculatedFrom = '';
      this.interestSoa.interestCalculatedTo = '';
    }
    this.modalErrorComponent.removeAllDpSaveError();
    // this.updateInterest();
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  get isProject(): boolean {
    return this.activeTab && this.activeTab.isProject();
  }

  get activeTab(): Tab {
    return this.tabsService && this.tabsService.activeTab;
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpSaveError();
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (
      !this.interestSoa.interestCalculatedFrom ||
      (this.interestSoa.interestCalculatedFrom && !pattern.test(this.interestSoa.interestCalculatedFrom))
    ) {
      //relax the validation of To field for InterestLumpSum Adjustment in Project matter only
      if (!(this.isProject && this.isInterestOnLumpSum())) {
        this.modalErrorComponent.createDPSaveError('matter.statementAdjustment.interestFromDate.MISSING');
      }
    }
    if (
      !this.interestSoa.interestCalculatedTo ||
      (this.interestSoa.interestCalculatedTo && !pattern.test(this.interestSoa.interestCalculatedTo))
    ) {
      //relax the validation of To field for InterestLumpSum Adjustment in Project matter only
      if (!(this.isProject && this.isInterestOnLumpSum())) {
        this.modalErrorComponent.createDPSaveError('matter.statementAdjustment.interestToDate.MISSING');
      }
    }
    if (
      !this.isProject &&
      this.interestSoa.interestCalculatedFrom &&
      this.interestSoa.interestCalculatedTo &&
      moment(this.interestSoa.interestCalculatedTo, 'YYYY/MM/DD') <
        moment(this.interestSoa.interestCalculatedFrom, 'YYYY/MM/DD')
    ) {
      this.modalErrorComponent.createDPSaveError('matter.statementAdjustment.fromToDate');
    }
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.interestSoa);
    }
  }

  close(): void {
    this.dialog.close();
  }

  getDateToSave(event): string {
    /*        let date : string;
            date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;
            return date;*/
    return event.rawDate;
  }

  onDateChange(event, dateType: string): void {
    const tmpDate = this.getDateToSave(event);
    this.interestSoa[dateType] = tmpDate;
    // this.updateInterest();
  }

  geFormattedCurrencyValue(currencyValue: number): string {
    if (
      currencyValue != undefined &&
      !isNaN(currencyValue) &&
      currencyValue != null &&
      currencyValue.toString().trim() != ''
    ) {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  formattedDate(dateStr: string): string {
    return dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
  }

  calculateDateDiff(): number {
    let dateDiff: number = this.interestSoa.calculateDateDiff(
      this.context.isPaysForDateOfClosingVendor,
      this.context.matter.provinceCode,
      this.isInterestOnDeferredMoniesAdjustment()
    );
    return this.interestSoa ? dateDiff : 0;
  }

  updateInterest(): void {
    this.interest =
      this.interestSoa && this.isInterestCalculatedFromAndToDateValid()
        ? this.geFormattedCurrencyValue(
            this.interestSoa.calculateSoAInterest(
              this.context.matter.provinceCode,
              this.context.isPaysForDateOfClosingVendor,
              this.isInterestOnDeferredMoniesAdjustment()
            )
          )
        : this.geFormattedCurrencyValue(0);
  }

  isInterestCalculatedFromAndToDateValid(): boolean {
    if (
      Utils.isNotValidDate(this.interestSoa.interestCalculatedFrom) ||
      Utils.isNotValidDate(this.interestSoa.interestCalculatedTo)
    ) {
      return false;
    } else {
      if (
        this.context.matter &&
        this.context.matter.isMatterProvinceAB &&
        this.context.matter.isPaysForDateOfClosingVendor
      ) {
        //allows fromDate = toDate if AB && isPaysForDateOfClosingVendor
        return !(
          moment(this.interestSoa.interestCalculatedTo, 'YYYY/MM/DD') <
          moment(this.interestSoa.interestCalculatedFrom, 'YYYY/MM/DD')
        );
      } else {
        return !(
          moment(this.interestSoa.interestCalculatedTo, 'YYYY/MM/DD') <=
          moment(this.interestSoa.interestCalculatedFrom, 'YYYY/MM/DD')
        );
      }
    }
  }

  get isCreditToPurchaser(): boolean {
    return this.interestSoa && this.interestSoa.isInterestCreditTo == this.purchaserLabel;
  }

  get isCreditToVendor(): boolean {
    return this.interestSoa.isInterestCreditTo == this.vendorLabel;
  }

  isProjectOrProjectSaleProvinceAB(): boolean {
    return (
      (this.context.matter.isProjectSale || this.context.matter.templateForProject) &&
      this.context.matter.isMatterProvinceAB
    );
  }

  isInterestOnDeferredMoniesAdjustment(): boolean {
    return this.context.statementAdjustmentKey == StatementAdjustmentKey.INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY;
  }

  isInterestOnLumpSum(): boolean {
    return this.context.statementAdjustmentKey == StatementAdjustmentKey.INTEREST_ON_LUMP_SUM;
  }

  isSameInterestRateAsInterim(): boolean {
    return this.interestSoa.isSameInterestRateAsInterim();
  }

  onSameInterestRateAsInterimChange(): void {
    this.interestSoa.applyUpdatesBasedOnProjectSettings(this.context.matter, this.context.project);
  }

  getModalHeader(): string {
    if (this.isInterestOnDeferredMoniesAdjustment() && this.context.project && this.context.project.projectCondo) {
      if (this.context.project.projectCondo.isOccupancyFeesCalculatedBasedOnPurchaseMonies) {
        return 'Adjustment Type: Interest on Deferred Monies During Occupancy';
      } else if (this.context.project.projectCondo.isOccupancyFeesCalculatedBasedOnPhantomMortgage) {
        return 'Adjustment Type: Interest on VTB During Occupancy';
      }
    } else {
      return 'Adjustment Type: Interest Calculation';
    }
  }
}
