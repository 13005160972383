import {MatterSectionsRouteTypes, Section} from '../../matters/shared/section';
import * as _ from 'lodash';
import {isNumeric} from 'rxjs/util/isNumeric';
import {currentMatter} from '../../matters/shared';
import {MatterTopic} from '../../matters/shared/matter-topic';

export const HOLDBACKS_SECTION_ROUTE_PATH: string = 'propertyTeranet/holdbacks';
export const WORKITEMS_ROUTE_PATH: string = 'overview/tasks';
export const OPPORTUNITY_WORKITEMS_ROUTE_PATH: string = 'opportunityOverview/tasks';
export const OPPORTUNITY_Notification_ROUTE_PATH: string = 'opportunityOverview/correspondence';

/**
 * Base class for any UI component that needs to offer section based navigation (the left-hand side vertical menu for splitting the page
 * into sections)
 */
export class SectionedComponent {
  sections: Section[];
  urlPath: string;
  sectionName: string;
  assetsAndDebtsChildRoutes: string[] = [ 'jointlyHeld', 'assetProperties', 'specificAssets', 'designatedBeneficiaries', 'loans', 'personalBelongings' ];
  partiesAndRolesChildRoutes: string[] = [ 'executorsAndTrustees', 'children', 'otherParties' ];
  residueAndTrustsRoutes: string[] = [ 'residueDistribution', 'commonDisaster' ];

  // after user refresh it will active the same tab by finding the index form url path.
  refreshSections(sections: Section[]): void {
    this.sections = sections;
    this.inactivateAllSections();
    this.sections[ this.getSelectedSectionIndex() ].active = true;
  }

  // to active one tab at a time this method will inactive all tabs.
  inactivateAllSections(): void {
    this.sections.forEach(section => {
      section.active = false;
    });
  }

  /**
   * Extract the index of the selected section (in the sections array) based on its name component in the active route
   * @returns {number}
   */
  getSelectedSectionIndex(): number {
    let tabName: string = this.getActiveSectionName().toLowerCase();

    // let index = _.findIndex(this.tabs, chr => chr.route.toLowerCase() === tabName);
    let index: number = _.findIndex(this.sections, function (chr: any) {
      return chr.route.toLowerCase() === tabName;
    });

    return (index > 0) ? index : 0;
  }

  // this fetch the section name from url string.
  // as it is not part of param se we have got it by splitting the url.
  // the position of the tabName will be the last non-numeric param
  getActiveSectionName(): string {
    this.urlPath = location.pathname;
    //console.warn("getActiveSectionName() | this.urlPath =", this.urlPath);
    if (this.urlPath.indexOf('mortgages/mortgage/') > -1) {
      return 'mortgages';
    } else if (this.urlPath.indexOf('existingMortgages/mortgage/') > -1) {
      return 'existingMortgages';
    } else if (this.urlPath.indexOf('vtbMortgages/mortgage/') > -1) {
      return 'vtbMortgages';
    } else if (this.urlPath.indexOf('subjectProperty') > -1) {
      //return 'propertyTeranet/subjectProperty';
      return 'propertyTeranet';
    } else if (this.urlPath.indexOf('teranetConnect') > -1 || this.urlPath.indexOf('holdbacks') > -1) {
      //return 'propertyTeranet/teranetConnect';
      return 'propertyTeranet';
    } else if (this.urlPath.indexOf(OPPORTUNITY_WORKITEMS_ROUTE_PATH) > -1 || this.urlPath.indexOf(OPPORTUNITY_Notification_ROUTE_PATH) > -1) {
      return MatterSectionsRouteTypes.OPPORTUNITY_OVERVIEW;
    } else if (this.isChildRouteOf(this.assetsAndDebtsChildRoutes, this.urlPath)) {
      return 'assetsGifts';
    } else if (this.isChildRouteOf(this.partiesAndRolesChildRoutes, this.urlPath)) {
      return 'partiesAndRoles';
    } else if (this.isChildRouteOf(this.residueAndTrustsRoutes, this.urlPath)) {
      return 'residueAndTrusts';
    } else {
      let urlArray = this.urlPath.split('/');

      //Route can have ids at the end for example /solicitor/1234 therefore returning the first non-numeric for section name
      for (let i = urlArray.length - 1; i >= 0; i--) {
        if (!isNumeric(urlArray[ i ])) {
          return urlArray[ i ];
        }
      }
    }

  }

  isChildRouteOf(sectionTabs: string[], urlPath: string): boolean {
    for (let a of sectionTabs) {
      if (urlPath?.indexOf(a) > -1) {
        return true;
      }
    }
    return false;
  }

  //This method return the route path from the URL for selected topic
  getActiveSectionRoutePath(): string {
    this.urlPath = location.pathname;
    //console.warn("getActiveSectionName() | this.urlPath =", this.urlPath);
    if (this.urlPath.indexOf('mortgages/mortgage/') > -1) {
      return this.urlPath.substr(this.urlPath.indexOf('mortgages/'), this.urlPath.length);
    } else if (this.urlPath.indexOf('existingMortgages/mortgage/') > -1) {
      return 'existingMortgages';
    } else if (this.urlPath.indexOf('vtbMortgages/mortgage/') > -1) {
      return this.urlPath.substr(this.urlPath.indexOf('vtbMortgages/'), this.urlPath.length);
    } else if (this.urlPath.indexOf('subjectProperty') > -1) {
      //return 'propertyTeranet/subjectProperty';
      return 'propertyTeranet';
    } else if (this.urlPath.indexOf('teranetConnect') > -1) {
      return 'propertyTeranet/teranetConnect';
    } else if (this.urlPath.indexOf('holdbacks') > -1) {
      return HOLDBACKS_SECTION_ROUTE_PATH;
    } else if (this.urlPath.indexOf(WORKITEMS_ROUTE_PATH) > -1) {
      return WORKITEMS_ROUTE_PATH;
    } else if (this.urlPath.indexOf(OPPORTUNITY_WORKITEMS_ROUTE_PATH) > -1) {
      return OPPORTUNITY_WORKITEMS_ROUTE_PATH;
    } else if (this.urlPath.indexOf(OPPORTUNITY_Notification_ROUTE_PATH) > -1) {
      return OPPORTUNITY_Notification_ROUTE_PATH;
    } else if (this.urlPath.indexOf('assetsGifts') > -1) {
      return this.urlPath.substring(this.urlPath.indexOf('assetsGifts/'), this.urlPath.length);
    } else if (this.urlPath.indexOf('partiesAndRoles') > -1) {
      return this.urlPath.substring(this.urlPath.indexOf('partiesAndRoles/'), this.urlPath.length);
    } else {
      let urlArray = this.urlPath.split('/');

      //Route can have ids at the end for example /solicitor/1234 therefore returning the first non-numeric for section name
      for (let i = urlArray.length - 1; i >= 0; i--) {
        if (!isNumeric(urlArray[ i ])) {
          return urlArray[ i ];
        }
      }
    }

  }

  setActiveSection(index: number) {
    this.inactivateAllSections();
    this.sections[ index ].active = true;
  }

  getPageHeader(): string {
    return this.sectionName;
  }

  getSectionByIndex(index: number): Section {
    return this.sections[ index ];
  }

  get activeSection(): Section {
    return this.sections ? this.sections.find(item => item.active) : undefined;
  }

  get activeSectionNotes(): string {
    let activeMatterTopic: MatterTopic;
    if (currentMatter && currentMatter.value && currentMatter.value.matterTopics && this.activeSection) {
      activeMatterTopic = currentMatter.value.matterTopics.find(item => item.matterTopicKey == this.activeSection.sectionKey);
    }
    return activeMatterTopic ? activeMatterTopic.topicNote : '';
  }

  updateActiveSectionStatus(matterTopicValue: string): void {
    let activeMatterTopic: MatterTopic;
    if (currentMatter && currentMatter.value && currentMatter.value.matterTopics && this.activeSection) {
      activeMatterTopic = currentMatter.value.matterTopics.find(item => item.matterTopicKey == this.activeSection.sectionKey);
      if (activeMatterTopic) {
        activeMatterTopic.topicStatus = matterTopicValue;
      } else {
        let matterTopicInfo = new MatterTopic();
        matterTopicInfo.topicStatus = matterTopicValue;
        matterTopicInfo.matterTopicKey = this.activeSection.sectionKey;
        if (currentMatter.value.matterTopics) {
          currentMatter.value.matterTopics.push(matterTopicInfo);
        } else {
          currentMatter.value.matterTopics = [];
          currentMatter.value.matterTopics.push(matterTopicInfo);
        }
      }
      currentMatter.value.dirty = true;
    }

  }

  updateActiveSectionNotes(notes: string): void {
    let activeMatterTopic: MatterTopic;
    if (currentMatter && currentMatter.value && currentMatter.value.matterTopics && this.activeSection) {
      activeMatterTopic = currentMatter.value.matterTopics.find(item => item.matterTopicKey == this.activeSection.sectionKey);
      if (activeMatterTopic) {
        activeMatterTopic.topicNote = notes;
        if (undefined == activeMatterTopic.topicStatus || activeMatterTopic.topicStatus == '' || activeMatterTopic.topicStatus == null) {
          activeMatterTopic.topicStatus = 'QUESTION';
        }
      } else {

        activeMatterTopic = new MatterTopic();
        activeMatterTopic.topicNote = notes;
        activeMatterTopic.topicStatus = 'QUESTION';
        activeMatterTopic.matterTopicKey = this.activeSection.sectionKey;

        if (currentMatter.value.matterTopics) {
          currentMatter.value.matterTopics.push(activeMatterTopic);
        } else {
          currentMatter.value.matterTopics = [];
          currentMatter.value.matterTopics.push(activeMatterTopic);
        }
      }
    }

  }
}

