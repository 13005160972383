import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class SoAdjVacantOccupancyTax extends BaseEntity {

  id: number;
  assessmentAmount: number;
  interestRate: number;

  get adjustmentDescription(): string {
    return 'VACANT OCCUPANCY TAX';
  }

}
