<div class="modal-header margin-bottom-20" >
    <div class="col-lg-12">
        <div class="col-lg-10">
            <h1>Declarations</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
    </div>
</div>
<div class="row modal-body form-horizontal padding-left-30 padding-right-30 min-height-270" style="overflow:hidden;">
    <div class="form-group">
        Re similar-name executions against: {{clientDeclaration.clientName}}<br><br>
        Prepare Declaration(s) of:<br>
    </div>
    <div class="form-group table-header-line">
        <div class="col-lg-2">
            <dp-checkbox fieldId="clientSelectedFlag">
                <input id="clientSelectedFlag"
                       name="clientSelectedFlag"
                       type="checkbox"
                       [(ngModel)]="isClientSelected"
                       [disabled]="isClientDeclarationExists"
                       #clientSelectedFlag>
            </dp-checkbox>
            Client
        </div>
        <div class="col-lg-10">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Name of Client</label>
                </div>
                <div class="col-lg-10">
                    <input class="form-control" id="client-name"
                           name="client-name"
                           maxlength="100"
                           [dp-read-only]="true"
                    [(ngModel)]="clientDeclaration.clientName">
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Gender</label>
                </div>
                <div class="col-lg-6"
                     id="client-gender">
                    <select [dp-read-only]="isGenderReadOnly"
                            class="form-control"
                            [(ngModel)]="clientDeclaration.gender"
                            (change)="genderSelectedFromList()">
                        <option *ngFor="let item of genderTypes" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="showClientFields">
                <div class="form-group" *ngFor="let signingOfficer of clientDeclaration.declarationSigningOfficers; let i=index " >
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">{{officerNameLabel(clientDeclaration.gender)}} (#{{i+1}})</label>
                    </div>
                    <div class="col-lg-10">
                        <input class="form-control" id="officerName{{i+1}}"
                               name="officerName{{i+1}}"
                               maxlength="100"
                               [(ngModel)]="clientDeclaration.declarationSigningOfficers[i].signingOfficerName">
                        <div class="actionbtns" *ngIf="!this.isGenderReadOnly">
                            <div class="width-20">
                                <span><i class="fa fa-trash"  (click)="deleteSigner(i)" aria-hidden="true"></i></span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xs-12 padding-top-10" *ngIf="!isGenderReadOnly">
                    <a href="javascript:void(0);" (click)="addSigningOfficer()">
                        Add Signing Officer
                        <span class="selector fa fa-plus-square-o"></span>
                    </a>

                </div>
            </div>
        </div>
    </div>


    <div class="form-group table-header-line">
        <div class="col-lg-2">
            <dp-checkbox fieldId="solicitorSelectedFlag">
                <input id="solicitorSelectedFlag"
                       name="clientSelectedFlag"
                       type="checkbox"
                       [(ngModel)]="isSolicitorSelected"
                       [disabled]="isSolicitorDeclarationExists"
                       #solicitorSelectedFlag>
            </dp-checkbox>
            Solicitor
        </div>
        <div class="col-lg-10">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Name of Solicitor</label>
                </div>
                <div class="col-lg-10" id="solicitor-name">
                    <p-autoComplete [(ngModel)]="solicitorDeclaration.solicitorName" tabIndexIgnore
                                    [suggestions]="solicitorDropDownList" maxlength="75"
                                    (completeMethod)="filterSolicitorNameList($event)"
                                    [minLength]="1"
                                    [disabled]="!isSolicitorSelected || isSolicitorDeclarationExists"
                                    [dropdown]="isSolicitorDropDown"
                                    (keydown.space)="utils.handleSpaceKeyDropdown($event, solicitorDeclaration.solicitorName, solicitorDropdownClick)"
                                    (keydown.enter)="utils.handleEnterKeyDropdown($event, solicitorDeclaration.solicitorName, solicitorDropdownClick)"
                                    (onDropdownClick)="solicitorDropdownClick($event)"
                                    class="dropdown-arrow dropdown-arrow-refferedBy">
                        <ng-template let-solicitorName pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                      <span class="omnibarLi100">
                                          {{solicitorName}}
                                      </span>
                            </li>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
        </div>
    </div>




</div>
<div class="modal-footer">
    <div class="col-lg-12 buttons">
        <button type="button" class="dp-btn" (click)="onOK()" [disabled]="!okEnabled" #okButtonControl id="okBtn">
            <span>OK</span>
        </button>
        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
            <span>Cancel</span>
        </button>
    </div>
</div>

