import {SoaExpenseAdjustment} from './soa-expense-adjustment';
import {Matter} from '../../../shared';
import {StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {StatementAdjustment} from '../../statement-adjustment';

export class SoaExpenseAdjustmentUtil {

  static calculateEstimatedTax(soaExpenseAdjustment: SoaExpenseAdjustment, soaCommonExpenseAmount: number): void {
    if (soaCommonExpenseAmount && soaExpenseAdjustment.applyTax === 'YES' && soaExpenseAdjustment.adjustPercentage) {
      soaExpenseAdjustment.taxAmount = Number(Number(Number(soaCommonExpenseAmount * soaExpenseAdjustment.numberOfMonthsCommonExpenses) * Number(soaExpenseAdjustment.adjustPercentage)) / 100);
    } else {
      soaExpenseAdjustment.taxAmount = 0;
    }

  }

  static calculateVendorShare(soaExpenseAdjustment: SoaExpenseAdjustment, soaCommonExpenseAmount: number): void {
    soaExpenseAdjustment.creditAmount = Number(Number(Number(soaCommonExpenseAmount * soaExpenseAdjustment.numberOfMonthsCommonExpenses) + Number(soaExpenseAdjustment.taxAmount)));
  }

  static updateCalculations(soaExpenseAdjustment: SoaExpenseAdjustment, soaCommonExpenseAmount: number): SoaExpenseAdjustment {
    this.calculateEstimatedTax(soaExpenseAdjustment, soaCommonExpenseAmount);
    this.calculateVendorShare(soaExpenseAdjustment, soaCommonExpenseAmount);
    return soaExpenseAdjustment;
  }

  static soaCommonExpenseAmount(soaExpenseAdjustment: SoaExpenseAdjustment, soaCommonExpenseAmount: number): number {
    if (soaExpenseAdjustment.isAdjustmentTypeSaleIncentive() && soaExpenseAdjustment.isAdditionalAmountApplicable()) {
      if (soaExpenseAdjustment.isOtherAmountSelected()) {
        return Number(soaExpenseAdjustment.additionalMonthlyAmount);
      } else {
        return soaCommonExpenseAmount != undefined ? (Number(soaCommonExpenseAmount) + Number(soaExpenseAdjustment.additionalMonthlyAmount)) : 0;
      }
    } else {
      return soaCommonExpenseAmount ? soaCommonExpenseAmount : 0;
    }

  }

  static expenseAdjustmentUpdate(soaExpenseAdjustment: SoaExpenseAdjustment, statementAdjustment: StatementAdjustment) {
    if (statementAdjustment && statementAdjustment.soaExpenseAdjustment) {
      statementAdjustment.soaExpenseAdjustment = new SoaExpenseAdjustment(soaExpenseAdjustment);
      statementAdjustment.amount = statementAdjustment.soaExpenseAdjustment.creditAmount;
      statementAdjustment.description = statementAdjustment.soaExpenseAdjustment.expenseType == StatementAdjustmentKey.RESERVE_FUND ? 'RESERVE FUND' : 'COMMON EXPENSES - SALES INCENTIVE';
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    }
  }

  static updateReserveFundAdjustment(atatementAdjustment: StatementAdjustment[], condoExpenseAmount: number): boolean {
    let result: boolean = false;
    let reserveFundSOAList: StatementAdjustment[] = atatementAdjustment.filter(adj => adj.itemKey === StatementAdjustmentKey.RESERVE_FUND);
    if (reserveFundSOAList) {
      reserveFundSOAList.forEach(reserveFundSOA => {
        this.updateCalculations(reserveFundSOA.soaExpenseAdjustment, condoExpenseAmount);
        this.expenseAdjustmentUpdate(reserveFundSOA.soaExpenseAdjustment, reserveFundSOA);
      });
      result = true;
    }
    return result;
  }

  static updateReserveFundAdjustments(matter: Matter, condoExpenseAmount: number): boolean {
    let result: boolean = false;
    if (matter && matter.interimStatementAdjustments) {
      result = this.updateReserveFundAdjustment(matter.interimStatementAdjustments, condoExpenseAmount);
    }
    if (matter && matter.finalStatementAdjustments) {
      result = this.updateReserveFundAdjustment(matter.finalStatementAdjustments, condoExpenseAmount);
    }
    return result;
  }

  static updateCommonExpenseAdjustments(matter: Matter, propertyTabCommonExpenseAmount: number) {
    matter.finalStatementAdjustments.filter(commonExpenseAdj => !!commonExpenseAdj.soaCommonExpense && !commonExpenseAdj.addedFlag).forEach(adj => {
      adj.soaCommonExpense.commonExpenseAmount = propertyTabCommonExpenseAmount;
      adj.updateFromCommonExpense(matter.getFinalClosingDate(), matter.isPaysForDateOfClosingVendor);
    });

    matter.interimStatementAdjustments.filter(commonExpenseAdj => !!commonExpenseAdj.soaCommonExpense && !commonExpenseAdj.addedFlag).forEach(adj => {
      adj.soaCommonExpense.commonExpenseAmount = propertyTabCommonExpenseAmount;
      adj.updateFromCommonExpense(matter.getInterimOccupancyDate(), matter.isPaysForDateOfClosingVendor);
    });

    if (matter.isProjectOrProjectSale) {
      matter.finalStatementAdjustments.filter(commonExpenseAdj => !!commonExpenseAdj.soaCommonExpense && commonExpenseAdj.isCommonExpenseAdjustment()).forEach(adj => {
        adj.soaCommonExpense.commonExpenseAmount = propertyTabCommonExpenseAmount;
        adj.updateFromCommonExpense(matter.getFinalClosingDate(), matter.isPaysForDateOfClosingVendor);
      });

      matter.interimStatementAdjustments.filter(commonExpenseAdj => !!commonExpenseAdj.soaCommonExpense && commonExpenseAdj.isCommonExpenseAdjustment()).forEach(adj => {
        adj.soaCommonExpense.commonExpenseAmount = propertyTabCommonExpenseAmount;
        adj.updateFromCommonExpense(matter.getInterimOccupancyDate(), matter.isPaysForDateOfClosingVendor);
      });
    }
  }

  static updateSalesIncentiveAdjustments(matter: Matter, propertyTabCommonExpenseAmount: number) {
    let salesIncentiveSOAList: StatementAdjustment[] = matter.allStatementAdjustments.filter(adj => adj.itemKey === StatementAdjustmentKey.SALES_INCENTIVE);
    if (salesIncentiveSOAList) {
      salesIncentiveSOAList.forEach(salesIncentiveSOA => {
        if (salesIncentiveSOA.soaExpenseAdjustment) {
          SoaExpenseAdjustmentUtil.updateCalculations(salesIncentiveSOA.soaExpenseAdjustment,
            SoaExpenseAdjustmentUtil.soaCommonExpenseAmount(salesIncentiveSOA.soaExpenseAdjustment, propertyTabCommonExpenseAmount));
          //this.onExpenseAdjustmentUpdate(salesIncentiveSOA.soaExpenseAdjustment, salesIncentiveSOA);
          salesIncentiveSOA.soaExpenseAdjustment = new SoaExpenseAdjustment(salesIncentiveSOA.soaExpenseAdjustment);
          salesIncentiveSOA.amount = salesIncentiveSOA.soaExpenseAdjustment.creditAmount;
          salesIncentiveSOA.description = salesIncentiveSOA.soaExpenseAdjustment.expenseType == StatementAdjustmentKey.RESERVE_FUND ? 'RESERVE FUND' :
            matter.isMatterProvinceON ? 'COMMON EXPENSES - SALES INCENTIVE' :
              matter.isMatterProvinceBC ? 'STRATA FEES - SALES INCENTIVE' : 'CONDOMINIUM FEES - SALES INCENTIVE';
          if (salesIncentiveSOA.soaExpenseAdjustment.isAdjustmentTypeSaleIncentive()) {
            salesIncentiveSOA.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
          } else {
            salesIncentiveSOA.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
          }
        }
      });
    }
  }

}
