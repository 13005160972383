import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SoaConfig} from '../../../admin/soa/soa-config';
import {DisbursementConfig} from './disbursement-config';
import {DialogService} from '../../../shared/dialog/dialog.service';
import * as _ from 'lodash';
import {StatementConfigService} from '../../../admin/shared/statement-config.service';
import {StatementConfig, StatementConfigContext} from '../../../admin/shared/statement-config';
import {MatterType} from '../../../matters/shared/index';
import {CustomKeyCodesEnum} from '../../../common';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {ProvinceCode} from '../../../admin/accounts/shared/account-province';

@Component({
  selector: 'dp-disbursement-config',
  templateUrl: './disbursement-config.component.html',
  styleUrls: [ './disbursement-config.scss' ]
})
export class DisbursementConfigComponent implements OnInit {

  @Input() statementConfig: StatementConfig;
  @Input() matterType: MatterType;
  @Input() taxRateType: string;
  @Input() provinceCode: string;
  @Input() context: StatementConfigContext = 'ACCOUNT';
  @Output() dirtyNotifier = new EventEmitter<string>();
  addButtonLabel: string = 'Add';
  deleteButtonLabel: string = 'Delete';
  selectedRowIndex: number = -1;
  burgerMenuItems: string[] = [ this.addButtonLabel, this.deleteButtonLabel ];
  burgerMenuItemWithoutDelete: string[] = [ this.addButtonLabel ];
  combinedAccountCodeArr: any[];

  constructor(public dialogService: DialogService, public soaConfigService: StatementConfigService) {
  }

  ngOnInit(): void {
    this.combinedAccountCodeArr = this.statementConfig.pcLawCodesCombinedData;
    this.populateAccountCodeArrayInDisbursementConfig();

  }

  populateAccountCodeArrayInDisbursementConfig() {
    this.soaConfig.disbursementsConfig.forEach(item => item.populateConfigAccountCodeArray(this.combinedAccountCodeArr));
  }

  get soaConfig(): SoaConfig {
    return this.statementConfig.statementOfAccount;
  }

  burgerMenuClicked(clickedMenuOption: string, disbConfig: DisbursementConfig): void {
    switch (clickedMenuOption) {
      case this.addButtonLabel:
        this.addDisbursementConfig(disbConfig);
        break;
      case this.deleteButtonLabel:
        this.deleteDisbursementConfig(disbConfig);
        break;
      default:
        return;
    }
  }

  addDisbursementConfig(selectedDisbConfig?: DisbursementConfig): void {
    let newDsbrsmntCfg = this.newDisbursementConfig();
    if (selectedDisbConfig) {
      let rowIndex = this.soaConfig.disbursementsConfig.findIndex(disbConfig => disbConfig == selectedDisbConfig);
      this.soaConfig.disbursementsConfig.splice(rowIndex, 0, newDsbrsmntCfg);
    } else {
      this.soaConfig.disbursementsConfig.push(newDsbrsmntCfg);
    }
    this.focusOnRow(newDsbrsmntCfg.identifier);
    this.dirtyNotifier.emit();
  }

  focusOnRow(identifier: number): void {
    setTimeout(() => {
      let elementForFocus: HTMLElement = document.getElementById(`disbursementName-${ identifier }`);
      if (elementForFocus) {
        elementForFocus.focus();
      }
    }, 200);
  }

  newDisbursementConfig(): DisbursementConfig {
    let disbConfig = new DisbursementConfig();
    disbConfig.code = disbConfig.identifier.toString();
    disbConfig.id = null;
    disbConfig.f9DefaultAmount = 0;
    disbConfig.readOnly = false;
    disbConfig.subjectToHstType = this.soaConfig.subjectToHstType;
    disbConfig.matterType = this.matterType;
    disbConfig.populateConfigAccountCodeArray(this.combinedAccountCodeArr);
    return disbConfig;
  }

  get isOnlyOneDisbursementConfigsoaConfigItem(): boolean {
    const disbursementConfig: DisbursementConfig[] = this.soaConfig && this.soaConfig.getDisbursementsLibrary(this.matterType);
    return Array.isArray(disbursementConfig) && disbursementConfig.length == 1;
  }

  deleteDisbursementConfig(selectedDisbConfig: DisbursementConfig): void {
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this Disbursements?', false, 'Delete').subscribe(res => {
      if (res) {
        //this.statementOfAccount.disbursementsConfig.splice(rowIndex, 1);'
        let foundDisbConfig = this.soaConfig.disbursementsConfig.find(disbConfig => disbConfig == selectedDisbConfig);
        if (foundDisbConfig) {
          foundDisbConfig.deleted = true;
        }
        this.dirtyNotifier.emit();

      }
    });
  }

  keyUpSelectedItem(event, disbConfig: DisbursementConfig) {
    if (event.keyCode == CustomKeyCodesEnum.Tab) {
      this.selectedItem(disbConfig);
    }

  }

  onKeyPress(event: KeyboardEvent) {
    if (event.keyCode === CustomKeyCodesEnum.Down || event.keyCode === CustomKeyCodesEnum.Up) {
      this.handleArrowKeys(event);
    }
  }

  handleArrowKeys(event): void {
    if (event.srcElement.id.startsWith('nameOfDisbursement-') === true) {
      event.preventDefault();
      if (event.keyCode === CustomKeyCodesEnum.Down) {
        if (this.selectedRowIndex < this.soaConfig.disbursementsConfig.length) {
          this.selectedRowIndex++;
        }
      } else if (event.keyCode === CustomKeyCodesEnum.Up) {
        if (this.selectedRowIndex > 0) {
          this.selectedRowIndex--;
        }
      }
      let elementId = 'nameOfDisbursement-' + this.selectedRowIndex;
      if (document.getElementsByName(elementId)) {
        document.getElementsByName(elementId)[ 0 ].focus();
      }
    }
  }

  selectedItem(disbConfig: DisbursementConfig): void {
    setTimeout(() => {
      for (let i = 0; i < this.soaConfig.disbursementsConfig.length; i = i + 1) {
        this.soaConfig.disbursementsConfig[ i ].isSelected = false;
      }
      disbConfig.isSelected = !disbConfig.isSelected;
    }, 100);
  }

  get selectedIndex(): number {
    return _.findIndex(this.soaConfig.getDisbursementsLibrary(this.matterType), disbConfig => disbConfig.isSelected == true);
  }

  moveUp(): void {
    let selectedDisbursementConfigIndex: number = _.findIndex(this.soaConfig.getDisbursementsLibrary(this.matterType), soaPurchaseObj => soaPurchaseObj.isSelected == true);
    if (selectedDisbursementConfigIndex > 0) {
      let upSoaPurchase: DisbursementConfig = this.soaConfig.getDisbursementsLibrary(this.matterType)[ selectedDisbursementConfigIndex - 1 ];
      let selectedSoaPurchase: DisbursementConfig = this.soaConfig.getDisbursementsLibrary(this.matterType)[ selectedDisbursementConfigIndex ];
      if (upSoaPurchase && selectedSoaPurchase) {
        let upDisbursementsConfigIndex: number = _.findIndex(this.soaConfig.disbursementsConfig, soaPurchaseObj => soaPurchaseObj.identifier == upSoaPurchase.identifier);
        let selectedDisbursementsConfigIndex: number = _.findIndex(this.soaConfig.disbursementsConfig, soaPurchaseObj => soaPurchaseObj.identifier == selectedSoaPurchase.identifier);
        this.soaConfig.disbursementsConfig[ upDisbursementsConfigIndex ] = selectedSoaPurchase;
        this.soaConfig.disbursementsConfig[ selectedDisbursementsConfigIndex ] = upSoaPurchase;
        this.dirtyNotifier.emit();
      }
    }
  }

  moveDown(): void {
    let selectedDisbursementConfigIndex: number = _.findIndex(this.soaConfig.getDisbursementsLibrary(this.matterType), soaPurchaseObj => soaPurchaseObj.isSelected == true);
    if (selectedDisbursementConfigIndex > -1 && selectedDisbursementConfigIndex != this.soaConfig.getDisbursementsLibrary(this.matterType).length - 1) {
      let upSoaPurchase: DisbursementConfig = this.soaConfig.getDisbursementsLibrary(this.matterType)[ selectedDisbursementConfigIndex + 1 ];
      let selectedSoaPurchase: DisbursementConfig = this.soaConfig.getDisbursementsLibrary(this.matterType)[ selectedDisbursementConfigIndex ];
      if (upSoaPurchase && selectedSoaPurchase) {
        let upDisbursementsConfigIndex: number = _.findIndex(this.soaConfig.disbursementsConfig, soaPurchaseObj => soaPurchaseObj.identifier == upSoaPurchase.identifier);
        let selectedDisbursementsConfigIndex: number = _.findIndex(this.soaConfig.disbursementsConfig, soaPurchaseObj => soaPurchaseObj.identifier == selectedSoaPurchase.identifier);
        this.soaConfig.disbursementsConfig[ upDisbursementsConfigIndex ] = selectedSoaPurchase;
        this.soaConfig.disbursementsConfig[ selectedDisbursementsConfigIndex ] = upSoaPurchase;
        this.dirtyNotifier.emit();
      }
    }

  }

  get resetMessage(): string {

    let msg: string = 'Do you wish to proceed to reset the ';
    switch (this.soaConfig.subjectToHstType) {
      case 'SUBJECT_TO_HST':
        msg += `Subject to ${ this.taxRateType }`;
        break;
      case 'NOT_SUBJECT_TO_HST':
        msg += `Not Subject to ${ this.taxRateType }`;
        break;
      case 'ADDITIONAL':
        msg += `Additional Charges Subject to ${ this.taxRateType }`;
        break;
      case 'SUBJECT_TO_PST':
        msg += `Subject to PST`;
        break;
      case 'SUBJECT_TO_GST_PST':
        msg += `Subject to GST and PST`;
        break;
    }
    return msg + ' disbursements to the default settings?';
  }

  reset(): void {
    let resetMessage = this.resetMessage;
    this.dialogService.confirm('Confirmation', resetMessage, false, 'Reset').subscribe(res => {
      if (res) {
        if (!this.context || this.context === 'ACCOUNT') { // when used on Account context reset with value from system account
          this.soaConfigService
          .getSystemStatementConfig(this.provinceCode)
          .subscribe((statementConfig: StatementConfig) => {
            this.applyStatementConfig(statementConfig);
          });
        }
        if (this.context && this.context === 'PROJECT') { // when used on Project context reset with value from current account
          let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
          this.soaConfigService
          .getStatementConfig(accountId, null, <ProvinceCode>this.provinceCode)
          .subscribe((statementConfig: StatementConfig) => {
            if (statementConfig.statementOfAccount && statementConfig.statementOfAccount.soaTemplates) {
              statementConfig.statementOfAccount.soaSelectedTemplate = statementConfig.statementOfAccount.soaTemplates.find(tp => tp.matterType == 'SALE' && !!tp.defaultTemplate);
            }
            this.applyStatementConfig(statementConfig);
          });
        }
      }

    });
  }

  private applyStatementConfig(statementConfig: StatementConfig) {
    if (statementConfig && statementConfig.statementOfAccount) {
      if ([ 'SUBJECT_TO_HST', 'ADDITIONAL', 'SUBJECT_TO_PST', 'SUBJECT_TO_GST_PST' ].indexOf(this.soaConfig.subjectToHstType) > -1) {
        this.soaConfig.initializeStatementOfAccountFromConfig(statementConfig.statementOfAccount, this.matterType, this.soaConfig.subjectToHstType);
        //EMP is not applicable for SK province
        if (this.provinceCode !== 'SK') {
          this.statementConfig.createEMP();
        }
      } else if (this.soaConfig.subjectToHstType == 'NOT_SUBJECT_TO_HST') {
        this.soaConfig.initializeStatementOfAccountFromConfig(statementConfig.statementOfAccount, this.matterType, 'NOT_SUBJECT_TO_HST');
        //EMP is not available under NOT_SUBJECT_TO_HST
        /*if (this.provinceCode !== 'SK') {
            this.statementConfig.createEMP();
        }*/
        this.statementConfig.createLTT();
        if (this.provinceCode !== 'NS') {
          this.statementConfig.createTitleInsurance();
        }
        this.statementConfig.createTitleMortgageRegFees();
        this.statementConfig.createNRST();
      }
      this.statementConfig.elrsafeesType = statementConfig.elrsafeesType;
      if (this.soaConfig) {
        if (this.soaConfig.disbursementsConfig) {
          let eRegDisgConfig = this.soaConfig.disbursementsConfig.find(item => item.isERegServiceCharge());
          if (eRegDisgConfig) {
            eRegDisgConfig.calculated = (this.statementConfig.elrsafeesType == 'CALCULATE_AUTOMATICALLY');
          }
          this.soaConfig.disbursementsConfig.filter(item => item.isRegistrationTransfer() || item.isRegistrationCharge()).forEach(
            item => {
              if (!this.statementConfig.isElrsaFeeTypeNA()) { // this will only apply for provinces where ElrsaFeeType makes sense
                item.calculated = (this.statementConfig.elrsafeesType == 'CALCULATE_AUTOMATICALLY');
              }
            }
          );
        }
      }
    }
    if (this.soaConfig && this.soaConfig.soaSelectedTemplate) {
      this.soaConfig.soaSelectedTemplate.disbursementsConfig = this.soaConfig.disbursementsConfig.filter(dc => dc.matterType == this.matterType);
      this.soaConfig.soaSelectedTemplate.fees = this.soaConfig.fees.filter(dc => dc.matterType == this.matterType);
    }
    this.populateAccountCodeArrayInDisbursementConfig();
    this.dirtyNotifier.emit();
  }

  mapDisbursementName(nameOfDisbursement: string): string {
    return nameOfDisbursement && nameOfDisbursement.includes(' HST') ? nameOfDisbursement.replace(' HST', ' ' + this.taxRateType) : (nameOfDisbursement ? nameOfDisbursement : '');
  }

  setFormDirty() {
    this.dirtyNotifier.emit();
  }

}

