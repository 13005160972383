import {Component, OnInit} from '@angular/core';
import {CustomKeyCodesEnum} from '../common/key-code-enum';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from '../messages/message.service';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {SESSION_ID_REQ_PARAM} from '../common/api';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {SharedDocument} from './shared-document';
import {SharedDocumentsPackage} from '../matters/document-production/shared-documents-package';
import {DialogService} from '../shared/dialog/dialog.service';
import {LinkSharedDocumentsModalComponent} from './link-shared-documents-modal.component';
import {Matter, matterSections, MatterSectionsRouteTypes} from '../matters/shared';

import {ModalResult} from '../shared-main/enums';

import EventUtils from '../event/event.utils';
import {MatterService} from '../matters/matter.service';
import {TabsComponent} from '../shared/tabbing/tabs.component';
import {TabsService} from '../core';
import {ShareDocumentsTab} from './share-documents.tab';
import {DocSharingMessage} from '../matters/document-production/doc-sharing-message';
import {AuthZService} from '../core/authz/auth-z.service';

declare var jQuery: any;

@Component({
  selector: 'dp-share-documents',
  templateUrl: 'share-documents.component.html',
  styleUrls: [
    './share-documents.component.scss'
  ]
})

export class ShareDocumentsComponent implements OnInit {

  sharedDocuments: SharedDocument[] = [];
  guid: string;
  sharedDocumentPackageId: number;
  linkedMatter: Matter;
  sharedDocumentsPackage: SharedDocumentsPackage;
  logoImageUrl: string;
  selectedMessageId: number = -1;

  constructor(public activatedRoute: ActivatedRoute,
              public dialogService: DialogService,
              public messageService: MessageService,
              public matterService: MatterService,
              public parentTabsComponent: TabsComponent,
              public tabsService: TabsService, public authZService: AuthZService) {
  }

  ngOnInit() {
    this.initSharePackageComponentState();
  }

  initSharePackageComponentState(): void {
    if (this.tabsService.activeTab && this.tabsService.activeTab.isShareDocuments() && (this.tabsService.activeTab as ShareDocumentsTab).shareDocumentPackage) {
      this.sharedDocumentsPackage = (this.tabsService.activeTab as ShareDocumentsTab).shareDocumentPackage;
      if (this.sharedDocumentsPackage) {
        this.guid = this.sharedDocumentsPackage.guid;
        this.sharedDocumentPackageId = this.sharedDocumentsPackage.id;

        this.selectedMessageId = this.sharedDocumentsPackage.getLatestDocSharingMessageId();
        if (this.sharedDocumentsPackage.documents && this.sharedDocumentsPackage.documents.length > 0) {
          this.sharedDocuments = [];
          this.sharedDocuments.push(...this.sharedDocumentsPackage.documents);
        }
        this.loadLinkedMatter();
      }
    }
  }

  async loadLinkedMatter(): Promise<void> {
    if (this.sharedDocumentsPackage.linkedMatterId) {
      this.linkedMatter = await this.matterService.getMatter(this.sharedDocumentsPackage.linkedMatterId, false).toPromise();
    }
  }

  tableKeyCommands(event): void {

    let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

    if (charCode === CustomKeyCodesEnum.Down) {
      event.preventDefault();

      this.keyCommandtoHiliteNextMatter(event);
    }
    if (charCode === CustomKeyCodesEnum.Up) {
      event.preventDefault();

      // hilite prev matter with up arrow
      this.keyCommandtoHilitePreviousMatter(event);
    }
  }

  keyCommandtoHiliteNextMatter(event) {

    if (document.activeElement.className === 'toggleBurger') {
      jQuery(event.target).closest('tr').next('tr').focus();
    } else {
      jQuery(document.activeElement).next('tr').focus();
    }
  }

  keyCommandtoHilitePreviousMatter(event) {

    if (document.activeElement.className === 'toggleBurger') {
      jQuery(event.target).closest('tr').prev('tr').focus();
    } else {
      jQuery(document.activeElement).prev('tr').focus();
    }
  }

  openFile(documentId: number): void {
    const parsedUrl = new URL(window.location.href);
    let baseUrl = parsedUrl.origin;
    let api: string = userAccountProfilesApi.downloadSharedDocument;
    let url = baseUrl + api.replace('{packageGuid}', '' + this.guid).replace('{documentId}', '' + documentId) + '?' + SESSION_ID_REQ_PARAM + '=' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId);
    window.open(url, '_blank');
    // we will update last viewed timestamp on UI when user open document which is already updated on backend due to above call....
    let sharedDocument = this.sharedDocuments.find(item => item.id == documentId);
    if (sharedDocument) {
      sharedDocument.lastViewedTimeStamp = new Date().getTime().toString();
      if (sharedDocument.lastViewedTimeStamp < sharedDocument.lastSharedTimeStamp) {
        // this is for the cases when local time is behind server time with a couple of minutes e.g. lastSharedTimeStamp = 12:45 PM while local time is 12:40 PM
        // since the document is viewed after sharing it => lastViewedTimeStamp needs to be greater or equal with lastSharedTimeStamp
        sharedDocument.lastViewedTimeStamp = sharedDocument.lastSharedTimeStamp;
      }
    }

  }

  isSharedDocumentsLinked() {
    return !!this.linkedMatter;
  }

  isSharedDocumentsPackageByUnityConnectUser() {
    return this.sharedDocumentsPackage && this.sharedDocumentsPackage.packageFromConnect;
  }

  //In Progress
  openLinkMatterModal() {
    this.dialogService.matDialogContent({
      content: LinkSharedDocumentsModalComponent,
      context: {
        sharedDocumentPackageId: this.sharedDocumentPackageId
      },
      onFulfillment: (result) => {
        if (result.action == ModalResult.OK) {
          this.linkedMatter = result.matterLinked;
          this.sharedDocumentsPackage.linkedMatterId = this.linkedMatter.id;
        }

      }
    });
  }

  async unlinkMatterLinkFromSharedDocsPackage(): Promise<void> {
    if (this.sharedDocumentsPackage) {
      if (await this.canAccessMatter(this.sharedDocumentsPackage.linkedMatterId)) {
        this.sharedDocumentsPackage = await this.matterService.unlinkMatterFromShareDocsPackage(this.sharedDocumentsPackage.guid).toPromise();
        //let oldSharedDocPackage = (this.tabsService.activeTab as ShareDocumentsTab).shareDocumentPackage;
        (this.tabsService.activeTab as ShareDocumentsTab).shareDocumentPackage = this.sharedDocumentsPackage;
        this.linkedMatter = undefined;
      }
    }

  }

  isMatterTabOpen(id: number): boolean {
    return this.tabsService.isMatterTabOpen(id);
  }

  showErrorMessage(errorMessage) {
    this.dialogService.confirm('ERROR', errorMessage, true);
  }

  hasFullAccessToMatter(matter: Matter): boolean {
    if (matter.isProjectSale) {
      return this.authZService.hasFullAccessToProjectMatters();
    } else {
      return this.authZService.hasFullAccessToConveyancingMatters();
    }
  }

  hasFullAccessToConveyancingOrProjectMatters(): boolean {
    return (this.authZService.hasFullAccessToProjectMatters() || this.authZService.hasFullAccessToConveyancingMatters());
  }

  async canAccessMatter(matterId: number): Promise<boolean> {
    let errorMessage = '';
    let matter: Matter = await this.matterService.getMatter(matterId, false).toPromise();
    if (matter) {
      if (matter.locked) {
        let matterLockedByUserName = (matter.lockedByUser && matter.lockedByUser.firstName ?
          matter.lockedByUser.firstName : '') + ' ' + (matter.lockedByUser && matter.lockedByUser.lastName ? matter.lockedByUser.lastName : '');
        errorMessage = 'The selected matter is currently locked by ' + matterLockedByUserName;
        this.showErrorMessage(errorMessage);
        return false;
      }
      if (!this.hasFullAccessToMatter(matter)) {
        errorMessage = 'Edit access to Matters is required. Please contact your Administrator.';
        this.showErrorMessage(errorMessage);
        return false;
      }
    }
    return true;
  }

  async openMatterRequest(): Promise<void> {
    let matter: Matter = await this.matterService.getMatter(this.linkedMatter.id, true).toPromise();
    if (matter) {
      const eventMatterType = EventUtils.getMatterTypeShortName((matter.matterType).toString());
      let section = matterSections.find((section) => {
        return (section.route == MatterSectionsRouteTypes.MATTER_OPENING) && (section.applicableFor.includes(eventMatterType));
      });
      this.parentTabsComponent.openMatterTab(matter, section);
      //this.openMatter(matter);
    }

  }

  get selectedMessage(): DocSharingMessage {
    return this.sharedDocumentsPackage ? this.sharedDocumentsPackage.getSelectedDocSharingMessage(this.selectedMessageId) : undefined;
  }

  openSelectedMessage(id: number): void {
    this.selectedMessageId = id;
  }

  get matterDescription(): string {
    if (this.sharedDocumentsPackage.matterInfo) {
      if (this.sharedDocumentsPackage.matterInfo.projectName) {
        if (this.sharedDocumentsPackage.matterInfo.matterDescription) {
          return this.sharedDocumentsPackage.matterInfo.projectName + ' - ' + this.sharedDocumentsPackage.matterInfo.matterDescription;
        } else {
          return this.sharedDocumentsPackage.matterInfo.projectName;
        }
      }
      if (this.sharedDocumentsPackage.matterInfo.matterDescription) {
        return this.sharedDocumentsPackage.matterInfo.matterDescription;
      }
    }
    return '';
  }

}
