import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {MessageService} from './message.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {SortEffectiveDate, UserMessage} from './user-messages';
import moment from 'moment';
import * as _ from 'lodash';
import {CkEditorUtil} from '../matters/shared/ckeditor-util';
import {TabsService} from '../core/tabs.service';
import {DialogService} from '../shared/dialog/dialog.service';
import {TabsComponent} from '../shared/tabbing';
import {LinkSharedDocumentsModalComponent} from '../share-documents/link-shared-documents-modal.component';
import {OpportunitiesService} from '../opportunities/opportunities.service';
import {MatterListUtil} from '../matters/matter-list/matter-list.util';
import {MessageListState, MessagesTab} from './messages-tab';
import {WindowRef} from '../shared/window.ref';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {FocusFirstElementDecorator} from '../shared-main/focus-first-element-decorator';
import {Environment} from '../../environments/environment';
import Utils from "../shared-main/utils";

declare var CKEDITOR: any;
declare var jQuery: any;

@Component({
  selector: 'dp-messages',
  templateUrl: 'messages.component.html',
  styleUrls: [ './messages.styles.scss' ]
})
@FocusFirstElementDecorator()
export class MessagesComponent implements OnInit {
  @ViewChild('search', {static: true, read: NgModel})
  searchControl: NgModel;

  categoryDropDown: SelectItem[] = [];
  userMessages: UserMessage[] = [];
  userUnreadMessage: UserMessage[] = [];
  userMessagesListLoading: boolean = true;
  userAllMessages: boolean = false;
  editor: any;
  selectedCategories: string[] = [ 'ALL' ];
  searchText: string;
  lastSearchText: string;
  sortType: string = 'DESC';
  sortQuery: string = SortEffectiveDate;
  // infinite scroll pagination
  pageNo: number = 1;
  pageSize: number = 50;
  listEndReached: boolean = false;
  loading: boolean = false;
  //Queue for throttling search events. See MatterListComponent.matterSearchEventQueue for more details
  messageSearchEventQueue = new Subject();
  // subscription to backend call for message list
  backendCallSubscription: any;
  messageSearchEventQueueSubscription: Subscription;
  searchChangeSubscription: Subscription;
  skipFirstRowAndFocus: boolean;

  constructor(
    public messageService: MessageService,
    public dialogService: DialogService,
    public tabsComponent: TabsComponent,
    public opportunitiesService: OpportunitiesService,
    public tabsStateService: TabsService,
    public window: WindowRef,
    public environment: Environment
  ) {
  }

  ngOnInit() {
    if (!this.editor) {
      this.editor = CKEDITOR.replace('messageInfo', {readOnly: true});
      CKEDITOR.config.width = '100%';
      CKEDITOR.config.height = '250px';
      CkEditorUtil.setUpEditor(this.editor);
    }

    this.categoryDropDown.push({
      label: 'Assignment',
      value: 'ASSIGNMENT'
    });
    this.categoryDropDown.push({
      label: 'Correspondence',
      value: 'NOTIFICATION'
    });
    this.categoryDropDown.push({
      label: 'Payout Statement',
      value: 'PAYOUT_STATEMENT'
    });

    if (this.environment.environment.toUpperCase() !== 'PROD' || this.environment.environment.toUpperCase() !== 'AU_PROD') {
      this.categoryDropDown.push({
        label: 'Payment Status',
        value: 'PAYMENT_STATUS'
      });
    }

    //Sorting and adding few cate
    this.categoryDropDown = _.sortBy(this.categoryDropDown, [ 'value' ]);
    this.categoryDropDown.unshift(
      {label: 'All Categories', value: 'ALL'},
      {label: 'Must Read', value: 'MUST_READ'},
      {label: 'Adjudication', value: 'ADJUDICATION'}
    );

    this.searchChangeSubscription = this.searchControl.valueChanges
    .debounceTime(500)
    .subscribe(this.onSearchTextChange);

    this.copyMessageListState();

    this.messageSearchEventQueueSubscription = this.messageSearchEventQueue
    .pipe(debounceTime(300))
    .subscribe(this.callMessageListApi);
    this.page();
  }

  ngAfterViewInit(): void {
    if (this.activeTab.messageListState) {
      // add scroll position to activetab object to retain scroll position when switching tabs
      this.window.nativeWindow.scrollTo(
        0,
        this.activeTab.messageListState.scrollY
      );
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      this.activeTab &&
      this.activeTab.messageListState &&
      this.window &&
      this.window.nativeWindow &&
      this.window.nativeWindow
    ) {
      this.activeTab.messageListState.scrollY =
        this.window.nativeWindow.scrollY;
    }
  }

  onSearchTextChange = (searchText: any) => {
    if (this.searchText != this.lastSearchText) {
      this.lastSearchText = this.searchText;
      if (searchText.keyCode != 9) {
        this.resetPage();
        this.page();
        this.skipFirstRowAndFocus = true;
      }
    }
  };

  copyMessageListState() {
    if (this.activeTab && this.activeTab.messageListState) {
      this.searchText = this.activeTab.messageListState.searchText;
      this.lastSearchText =
        this.activeTab.messageListState.lastSearchText;
      this.sortQuery = this.activeTab.messageListState.sortQuery;
      this.sortType = this.activeTab.messageListState.sortType;
    } else {
      this.activeTab.messageListState = new MessageListState();
      this.activeTab.messageListState.categories =
        this.selectedCategories;
      this.activeTab.messageListState.userAllMessages =
        this.userAllMessages;
      this.activeTab.messageListState.sortQuery = this.sortQuery;
      this.activeTab.messageListState.sortType = this.sortType;
      this.activeTab.messageListState.pageNo = this.pageNo;
      this.activeTab.messageListState.pageSize = this.pageSize;
    }
  }

  toggleSearchUserAllMessages(userAllMessagesStatus) {
    if (jQuery('.focus-first')) {
      jQuery('.focus-first').focus();
    }
    this.userAllMessages = userAllMessagesStatus;
    if (this.activeTab.messageListState) {
      this.activeTab.messageListState.userAllMessages =
        this.userAllMessages;
    }
    this.resetPage();
    this.page();
    // this.selectFirstRowAndFocus();
  }

  sortList(s): void {
    if (s === this.sortQuery) {
      if (this.sortType === 'ASC') {
        this.sortType = 'DESC';
      } else {
        this.sortType = 'ASC';
      }
    } else {
      this.sortType = 'ASC';
    }
    this.sortQuery = s;

    this.resetPage();
    this.page();

    // this.loadActionMessage();
  }

  resetPage() {
    this.userMessages = [];
    this.userUnreadMessage = [];
    this.pageNo = 1;
    this.listEndReached = false;
    this.editor.setData('');
  }

  get activeTab(): MessagesTab {
    return this.tabsStateService.activeTab as MessagesTab;
  }

  sortMessages(userMessages: UserMessage[]): UserMessage[] {
    let filteredUserMessage: UserMessage[] = [];

    let filteredMustReadList = userMessages.filter(
      (item) =>
        item.unityMessage &&
        item.unityMessage.isMustRead &&
        !item.isUserMessageRead
    );
    if (filteredMustReadList && filteredMustReadList.length > 0) {
      filteredUserMessage.push(
        ..._.sortBy(filteredMustReadList, [
          'unityMessage.effectiveDate'
        ]).reverse()
      );
    }

    let filteredUnReadedList = userMessages.filter(
      (item) =>
        item.unityMessage &&
        (!item.unityMessage.isMustRead ||
          (item.unityMessage.isMustRead && item.isUserMessageRead))
    );
    if (filteredUnReadedList && filteredUnReadedList.length > 0) {
      filteredUserMessage.push(
        ..._.sortBy(filteredUnReadedList, [
          'unityMessage.effectiveDate'
        ]).reverse()
      );
    }
    return filteredUserMessage;
  }

  getCategoryValue(type: string): string {
    if (this.categoryDropDown) {
      let category = this.categoryDropDown.find(
        (item) => item.value == type
      );
      return category ? category.label : '';
    } else {
      return '';
    }
  }

  formatMessage(item: UserMessage): void {
    if (
      item.unityMessage.message &&
      item.unityMessage.message.indexOf('\n') > -1
    ) {
      item.unityMessage.message = item.unityMessage.message.replace(
        /\n/gi,
        '<br>'
      );
    }
  }

  selectRow(item: UserMessage): void {
    this.userMessages.map((um) => {
      return (um.isSelected = false);
    });
    item.isSelected = true;
    this.formatMessage(item);
    this.editor.setData(item.unityMessage.message);
    if (!item.read) {
      item.read = moment().toDate();
      this.updateMessageReadFlag(item);
    }
    //It will be removed after finishing with the server side intergrate test
    // this.openShareDocumentTab(item.id);
  }

  updateMessageReadFlag(userMessage: UserMessage): void {
    if (userMessage) {
      let userId = Utils.getAuthenticatedUserId();
      let userMessageSaveObj = new UserMessage(userMessage);
      if (
        userMessageSaveObj.unityMessage &&
        userMessageSaveObj.unityMessage.additionalMessageData
      ) {
        userMessageSaveObj.unityMessage.additionalMessageData =
          JSON.stringify(
            userMessageSaveObj.unityMessage.additionalMessageData
          );
      }
      this.messageService
      .updateUserMessage(userId, userMessageSaveObj)
      .subscribe((userMessage: UserMessage) => {
        if (userMessage) {
          this.opportunitiesService.updateUnreadMessagesCount(
            this.userMessages.filter((item) => !item.read)
              .length
          );
        }
      });
    }
  }

  focusNextRow(nextItemIndex: number): void {
    if (nextItemIndex > -1 && nextItemIndex < this.userMessageList.length) {
      this.selectRow(this.userMessageList[ nextItemIndex ]);
    } else if (nextItemIndex > -1) {
      this.selectRow(this.userMessageList[ 0 ]);
    }
  }

  selectFirstRowAndFocus(): void {
    if (this.userMessageList && this.userMessageList.length > 0) {
      this.userMessageList.map((um) => {
        return (um.isSelected = false);
      });
    }
    this.editor.setData('');

    this.userUnreadMessage = this.userUnreadMessage.filter(
      (items) => !items.read
    );
  }

  updateMultiSelectFilters(obj: any[], searchType: string): void {
    if (searchType === 'category') {
      this.selectedCategories = obj;
      this.selectFirstRowAndFocus();
    }

    this.resetPage();
    this.page();
    console.log(this.selectedCategories);
  }

  get userMessageList(): UserMessage[] {
    return this.userMessages;
  }

  get selectedUserMessage(): UserMessage {
    return this.userMessages.find((item) => item.isSelected);
  }

  getMessageHeader(): string {
    return this.selectedUserMessage
      ? (this.selectedUserMessage.unityMessage.sourceMessageId
        ? this.selectedUserMessage.unityMessage.sourceMessageId +
        ' , '
        : '') +
      this.selectedUserMessage.unityMessage.messageSubject +
      ' , ' +
      this.selectedUserMessage.unityMessage.formattedEffectiveDate +
      ' , ' +
      this.getCategoryValue(
        this.selectedUserMessage.unityMessage.category
      )
      : '';
  }

  updateReadFlag(event): void {
    if (event) {
      this.selectedUserMessage.read = moment().toDate();
      if (!this.userAllMessages) {
        this.editor.setData(null);
      }
    } else {
      this.selectedUserMessage.read = undefined;
    }
    this.updateMessageReadFlag(this.selectedUserMessage);
  }

  openMatterTab(data: any): void {
    MatterListUtil.openMatterOppFromMessages(
      data,
      this.tabsStateService,
      this.tabsComponent,
      this.dialogService
    );
  }

  get showLinkToMatterButton(): boolean {
    return this.isLinkToMatterApplicable(this.selectedUserMessage);
  }

  isLinkToMatterApplicable(userMessage: UserMessage): boolean {
    return (
      userMessage &&
      userMessage.unityMessage &&
      userMessage.unityMessage.isNotificationMessage &&
      !userMessage.unityMessage.additionalMessageData
    );
  }

  async openLinkMatterAssignment(
    userMessage: UserMessage,
    doNotLoadMessages?: boolean
  ) {
    if (userMessage) {
      const userId = Utils.getAuthenticatedUserId();
      const sharedDocumentPackageId = await this.messageService
      .getSharedPackageByNotificationMessage(
        userId,
        userMessage.unityMessage.id.toString()
      )
      .toPromise();
      if (sharedDocumentPackageId) {
        this.dialogService.matDialogContent({
          content: LinkSharedDocumentsModalComponent,
          context: {
            sharedDocumentPackageId: sharedDocumentPackageId.id,
            isNotificationMessage: true
          },
          onFulfillment: (result) => {
            if (!doNotLoadMessages) {
              this.resetPage();
              this.page();
            }
          }
        });
      } else {
        console.error(
          'Information',
          'Could not find associated shared document package for message ' +
          userMessage.id,
          true
        );
      }
    }
  }

  get showOpenMatterButton(): boolean {
    return this.isOpenMatterButtonApplicable(this.selectedUserMessage);
  }

  isOpenMatterButtonApplicable(userMessage: UserMessage): boolean {
    return (
      userMessage &&
      userMessage.unityMessage &&
      (userMessage.unityMessage.isAssignmentMessage ||
        userMessage.unityMessage.isReferralMessage ||
        userMessage.unityMessage.isNotificationMessage ||
        userMessage.unityMessage.isPayoutStatementMessage ||
        userMessage.unityMessage.isPaymentStatusMessage) &&
      userMessage.unityMessage.additionalMessageData
    );
  }

  callMessageListApi = (): void => {
    this.getMessages();
  };

  incrementPage(): void {
    this.pageNo = this.pageNo + 1;
  }

  getNextPage(): void {
    if (!this.listEndReached) {
      this.skipFirstRowAndFocus = true;
      this.page();
    }
  }

  public getMessages(): void {
    this.userMessagesListLoading = true;
    let userId = Utils.getAuthenticatedUserId();

    this.backendCallSubscription = this.messageService
    .getUserSearchMessages(
      userId,
      this.searchText,
      !this.userAllMessages,
      this.sortQuery,
      this.sortType,
      this.pageNo,
      this.pageSize,
      this.selectedCategories
    )
    .subscribe(
      (userMessages: UserMessage[]) => {
        this.userMessagesListLoading = false;

        console.log('page ' + this.pageNo);

        if (
          Array.isArray(userMessages) &&
          userMessages.length > 0
        ) {
          //concat data
          this.userMessages =
            this.userMessages.concat(userMessages);
          this.userUnreadMessage = this.userMessages.filter(
            (item) => !item.isUserMessageRead
          );
          this.opportunitiesService.updateUnreadMessagesCount(
            this.userMessages.filter((item) => !item.read)
              .length
          );
          if (this.pageNo == 1 && !this.skipFirstRowAndFocus) {
            this.selectFirstRowAndFocus();
          } else {
            this.skipFirstRowAndFocus = false;
          }
          // set all the activetab object values to retain the contact list state
          if (!this.activeTab.messageListState) {
            this.activeTab.messageListState =
              new MessageListState();
          }
          this.activeTab.messageListState.tableData = [];
          this.userMessages.forEach((item) => {
            this.activeTab.messageListState.tableData.push(
              new UserMessage(item)
            );
          });

          this.activeTab.messageListState.searchText =
            this.searchText;
          this.activeTab.messageListState.lastSearchText =
            this.lastSearchText;
          this.activeTab.messageListState.sortType =
            this.sortType;
          this.activeTab.messageListState.sortQuery =
            this.sortQuery;
          this.activeTab.messageListState.pageNo =
            this.pageNo + 1;

          if (userMessages.length < this.pageSize) {
            this.listEndReached = true;
          } else {
            this.listEndReached = false;
            this.incrementPage();
          }
        } else {
          this.listEndReached = true;
          this.editor.setData('');
        }

        this.loading = false;
        setTimeout(() => {
          // setTimeout ensures height and position are accurate, if table row data does not reach the end of page we load more records to
          // invoke infinite scroll
          if (
            !this.listEndReached &&
            jQuery('.message-table  tr:last').offset().top +
            jQuery('.message-table  tr:last').height() <
            jQuery('.inspector-footer').offset().top
          ) {
            this.getNextPage();
          }
        }, 1000);
      },
      (error) => console.log(),
      () => console.log('completed')
    );
  }

  public page() {
    //Here we are checking if there is already a backend call in-progress and another call is made then unsuscribing to the first one (though it will be
    // completed on backend) but client side will discard it's result and subscribe to the latest backend call. This is used when multiple contact search
    // events have passed debounce and fired to backend.
    if (this.backendCallSubscription) {
      this.backendCallSubscription.unsubscribe();
      this.loading = false;
    }
    this.loading = true;
    //Raising another contact search event
    this.messageSearchEventQueue.next();
  }
}
