import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {Component, Inject} from '@angular/core';
import {Account} from '../../accounts/shared/account';
import {MatterNotificationConfig, TASK_NOTIFICATION_STATES} from './matter-notification-config';
import {Precedent} from '../../../matters/shared/precedent';
import {PrecedentTypes} from '../../../matters/shared/mortgage-precedent-type';
import {PrecedentService} from '../../../matters/mortgages/mortgage/precedent/precedent.service';
import {MatterNotificationConfigService} from './matter-notification-config.service';
import {AccountService} from '../../accounts/account.service';
import {ChecklistCategory} from '../../checklist-templates/checklist-template-config';
import {SelectItem} from 'primeng/api';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {UUIDUtil} from '../../../main/uuid-util';
import {MatterWorkItem} from '../../../matters/shared/matter-work-item';
import {MatterType} from '../../../matters';
import {ProvinceCode} from '../../accounts/shared/base-province';
import {MatterNotification} from '../../../matters/shared/matter-notification';

class MatterStatusNotificationsModalContext {
  account: Account;
  isTaskNotification: boolean;
  checklistTemplateCategory: ChecklistCategory;
  workItem: MatterWorkItem;
  taskId: number;
  taskName: string;
  applicableMatterTypeCode: string;
  matterType: MatterType;
  provinceCode: ProvinceCode;
  matterNotifications: MatterNotification[];
}

@Component({
  selector: 'matter-notification-config-modal-content',
  templateUrl: 'matter-notification-config.modal.component.html',
  styleUrls: ['./matter-notification-config.modal.style.scss'],
  providers: [ErrorService]
})
export class MatterNotificationConfigModalComponent extends ModalComponent<MatterStatusNotificationsModalContext> {
  account: Account;
  checked: boolean = true;
  accountMatterNotificationConfigs: MatterNotificationConfig[];
  notificationPrecedents: Precedent[] = [];
  notificationPrecedentsDropDownList: any[] = [];
  taskNotificationStatues: SelectItem[] = [];
  taskNotificationsRows: any = [];
  matterNotificationConfig: MatterNotificationConfig[] = [];

  constructor(
    public dialog: MatDialogRef<MatterNotificationConfigModalComponent>,
    public messagePrecedentService: PrecedentService,
    public matterNotificationConfigService: MatterNotificationConfigService,
    public accountService: AccountService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: MatterStatusNotificationsModalContext
  ) {
    super(dialog, context);
  }

  get tasksNotificationConfig(): MatterNotificationConfig[] {
    return this.context.checklistTemplateCategory
      ? this.context.checklistTemplateCategory.tasksNotificationConfig
      : this.context.workItem.tasksNotificationConfig;
  }

  ngOnInit() {
    this.account = new Account(this.context.account);
    this.getNotificationPrecedents();
    if (this.context.isTaskNotification) {
      this.taskNotificationStatues = [];
      TASK_NOTIFICATION_STATES.forEach((item) => {
        this.taskNotificationStatues.push(item.label);
      });
      if (this.tasksNotificationConfig) {
        this.matterNotificationConfig = this.tasksNotificationConfig.filter(
          (mc) => mc.taskIdentifier == this.context.taskId
        );
      }
      this.taskNotificationsRows = [];
      MatterNotificationConfig.defaultNotificationRecipients(
        this.context.provinceCode,
        this.context.matterType
      ).forEach((rc) => {
        if (rc.matterType.indexOf(this.context.applicableMatterTypeCode) > -1) {
          let matterNotificationConfigs = this.matterNotificationConfig.filter(
            (item) => item.recipientRole == rc.value
          );
          let statusCols: any = [];
          TASK_NOTIFICATION_STATES.forEach((st) => {
            let matterNotificationConfig = matterNotificationConfigs.find((item) => item.triggerEvent == st.value);
            if (matterNotificationConfig) {
              statusCols.push({
                key: rc.value,
                status: st.value,
                active: matterNotificationConfig.active,
                precedent: matterNotificationConfig.notificationPrecedentId,
                precedentName: '',
                matterNotificationConfigId: matterNotificationConfig.id
              });
            } else {
              statusCols.push({
                key: rc.value,
                status: st.value,
                active: false,
                precedent: undefined,
                precedentName: ''
              });
            }
          });
          this.taskNotificationsRows.push({recipient: rc, statusCols: statusCols});
          setTimeout(() => {
            this.refreshSelectedPrecedentsForMatterTask();
          }, 200);
        }
      });
    } else {
      this.getAccountMatterNotificationConfigs('' + this.context.account.id);
    }
  }

  close(): void {
    this.dialog.close();
  }

  save(): void {
    this.errorService.clearAllSaveErrors();
    if (this.context.isTaskNotification) {
      let statusCols: any = [];
      this.taskNotificationsRows.forEach((item) => {
        statusCols.push(...(item.statusCols || []));
      });
      if (statusCols.some((sc) => !!sc.active && !sc.precedent)) {
        this.errorService.addDpSaveError(
          DPError.createCustomDPError(
            '',
            'Please select Notification Precedent for active roles',
            'Task Status Notification',
            'ERROR'
          )
        );
      } else {
        this.taskNotificationsRows.forEach((row) => {
          if (row.statusCols) {
            let filteredNotifications = row.statusCols.filter(
              (col) => !!col.matterNotificationConfigId || !!col.active || !!col.precedent
            );
            if (filteredNotifications) {
              filteredNotifications.forEach((notification) => {
                let matterNotificationConfig: MatterNotificationConfig;
                if (notification.matterNotificationConfigId && this.tasksNotificationConfig) {
                  matterNotificationConfig = this.tasksNotificationConfig.find(
                    (item) => item.id == notification.matterNotificationConfigId
                  );
                }
                if (matterNotificationConfig) {
                  matterNotificationConfig.active = notification.active;
                  matterNotificationConfig.notificationPrecedentId = notification.precedent;
                } else if (notification.active || notification.precedent) {
                  matterNotificationConfig = new MatterNotificationConfig();
                  matterNotificationConfig.id = UUIDUtil.getUUID();
                  matterNotificationConfig.active = notification.active;
                  matterNotificationConfig.notificationPrecedentId = notification.precedent;
                  matterNotificationConfig.recipientRole = notification.key;
                  matterNotificationConfig.triggerEvent = notification.status;
                  matterNotificationConfig.customerAccountId = this.account.id;
                  matterNotificationConfig.taskIdentifier = this.context.taskId ? this.context.taskId : undefined;
                  this.tasksNotificationConfig.push(matterNotificationConfig);
                }
              });
            }
          }
        });
        this.dialog.close('Updated');
      }
    } else {
      this.matterNotificationConfigService
        .updateMatterNotificationConfig(this.accountMatterNotificationConfigs, '' + this.account.id)
        .subscribe((res: MatterNotificationConfig[]) => {
          this.dialog.close();
        });
    }
  }

  async getNotificationPrecedents(): Promise<void> {
    //Get precedents from backend
    this.notificationPrecedents = await this.messagePrecedentService
      .getPrecedents(null, this.context.account.id, PrecedentTypes.NOTIFICATION)
      .toPromise();
    if (this.context.matterType === 'WILL') {
      this.notificationPrecedents = this.notificationPrecedents?.filter((p) =>
        ['Client - Matter Closed', 'Client - Matter in Progress'].includes(p.precedentName)
      );
    }
    this.refreshNotificationPrecedentsDropDownList();
  }

  getPrecedentNameById(precedentID: number): string {
    let precedent = this.notificationPrecedents
      ? this.notificationPrecedents.find((precedent) => precedent.id == precedentID)
      : null;
    return precedent ? precedent.precedentName : '';
  }

  refreshNotificationPrecedentsDropDownList() {
    this.notificationPrecedentsDropDownList = [];
    this.notificationPrecedents.forEach((precedent) => {
      if (precedent.active) {
        this.notificationPrecedentsDropDownList.push({
          label: precedent.precedentName,
          value: precedent.id
        });
      }
    });
  }

  getAccountMatterNotificationConfigs(accountId: string) {
    return this.matterNotificationConfigService
      .getMatterNotificationConfig(accountId)
      .subscribe((res: MatterNotificationConfig[]) => {
        this.accountMatterNotificationConfigs = res;
        setTimeout(() => {
          this.refreshSelectedPrecedents(this.accountMatterNotificationConfigs);
        }, 200);
      });
  }

  refreshSelectedPrecedents(matterNotificationConfigs: MatterNotificationConfig[]) {
    if (
      matterNotificationConfigs &&
      matterNotificationConfigs.length > 0 &&
      this.notificationPrecedents &&
      this.notificationPrecedents.length > 0
    ) {
      matterNotificationConfigs.forEach((config) => {
        let selectedPrecedent = this.notificationPrecedents.find((item) => item.id == config.notificationPrecedentId);
        if (selectedPrecedent) {
          config.selectedPrecedent = selectedPrecedent.precedentName;
        }
      });
    }
  }

  refreshSelectedPrecedentsForMatterTask() {
    if (this.taskNotificationsRows && this.taskNotificationsRows.length > 0) {
      this.taskNotificationsRows.forEach((row) => {
        if (row.statusCols.length > 0) {
          row.statusCols.forEach((stc) => {
            if (stc.precedent) {
              stc.precedentName = this.getPrecedentNameById(stc.precedent);
            }
          });
        }
      });
    }
  }

  onSelectAccountMatterNotificationPrecedent(event, matterNotificationConfig: MatterNotificationConfig) {
    matterNotificationConfig.selectedPrecedent = event.label;
    matterNotificationConfig.notificationPrecedentId = event.value;
  }

  onSelectMatterTaskNotificationPrecedent(event, statusCol: any) {
    statusCol.precedent = event.value;
    statusCol.precedentName = event.label;
  }

  isNotificationPrecedentDisabled(statusCol): boolean {
    if (this.context.matterNotifications && this.context.matterNotifications.length) {
      return !!this.context.matterNotifications.find(
        (item) => item.isTaskNotification() && statusCol && statusCol.matterNotificationConfigId == item.taskIdentifier
      );
    }
    return false;
  }
}
