import moment from 'moment';
import {Moment} from 'moment';
import {CurrencyPipe, DatePipe, PercentPipe} from '@angular/common';
import {DocumentProcessorType} from '../admin/accounts/shared/account';
import {dropDowns} from '../admin/accounts/shared/account-drop-downs';
import {SelectItem} from 'primeng/api';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {Constants, ON_DEMAND_LABEL, ON_DEMAND_VALUE} from './constants';
import {User} from '../matters/shared/user';
import {CondominiumPlan} from '../matters/property-teranet/unit-level-plan/condominium-plan';
import {ProjectCondoPlan} from '../projects/project-condo/project-condo-plan';
import * as _ from 'lodash';
import {CondominiumExpense} from '../matters/property-teranet/unit-level-plan/condominium-expense';

declare var jQuery: any;

const dateRegularExpression_1950_2050 = /^(19[5-9][0-9]|20[0-4][0-9]|2050)[/](0?[1-9]|1[0-2])[/](0?[1-9]|[12][0-9]|3[01])$/;

const dateRegularExpression_1900_2050 = /^(19[0-9][0-9]|20[0-4][0-9]|2050)[/](0?[1-9]|1[0-2])[/](0?[1-9]|[12][0-9]|3[01])$/;

const datePattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/; //  YYYY/MM/DD

const ddMmYyyyPattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)(19|20)\d{2}$/; //  MM/DD/YYYY

const numberWords: string[] = [ 'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
  'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen', 'Twenty'
  // continue if required
];

//words that should remain as upper case
const upperCaseWords: string[] = [ 'PO', 'P.O.', 'RR', 'R.R.',
  'NE', 'NW', 'SE', 'SW', 'NE.', 'NW.', 'SE.', 'SW.',
  'E', 'W', 'N', 'S', 'E.', 'W.', 'N.', 'S.' ];

//words that should be converted to lower case
const lowerCaseWords: string[] = [ 'AND', 'OF', 'C/O' ];

export const isEmpty = (text: string): boolean => {
  return text == null || text.trim() == '';
};

export default class Utils {

  static getOrdinal(n: number): string {
    var suffix = [ 'th', 'st', 'nd', 'rd' ];

    return n + (suffix[ (n - 20) % 10 ] || suffix[ n ] || suffix[ 0 ]);
  }

  static getLongOrdinal(n: number, capitalize?: boolean): string {
    let ordinals: string[] = [ 'zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth' ];
    if (isNaN(n)) {
      return 'not a number';
    }
    if (n < 0) {
      return 'negative';
    }
    if (n < 19) {
      return capitalize ? ordinals[ n ].toUpperCase() : ordinals[ n ];
    }
    return 'more than 19';
  }

  static getOrdinalIndex(ordinalText: string): number {
    let ordinals: string[] = [ 'zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth' ];
    if (!ordinalText) {
      return -1;
    } else {
      return ordinals.findIndex(item => item.toUpperCase() == ordinalText.toUpperCase());
    }

  }

  static getOrdinalText(ordinalText: string): string {
    let ordinals: string[] = [ 'zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth' ];
    if (!ordinalText) {
      return '';
    } else {
      let ordinalIndex = ordinals.findIndex(item => item.toUpperCase() == ordinalText.toUpperCase());
      return ordinalIndex < 0 ? '' : this.getOrdinal(ordinalIndex);

    }

  }

  static cutToTwoDecimals(orgValue: number): number {
    return orgValue ? Number(orgValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[ 0 ]) : orgValue;
  }

  //this method can handle float value for days, for current Moment.js, it treat 91 and 91.25 equally
  static momentSubstractDays(currentMoment: Moment, days: number) {
    if (days) {
      let duration = moment.duration(days, 'days');
      currentMoment.subtract(duration.asMilliseconds(), 'ms');
    }
  }

  static addMomentDays(baseDateStr: string, days: number): string {
    let baseMoment: Moment = moment(baseDateStr, 'YYYY/MM/DD');
    if (days) {
      let duration = moment.duration(days, 'days');
      baseMoment.add(duration.asMilliseconds(), 'ms');
    }
    return baseMoment.format('YYYY/MM/DD');
  }

  static dateGreaterThanCurrentDate(date: string): boolean {
    if (this.isValidDate(date)) {
      return moment(date, 'YYYY/MM/DD').isAfter(moment());
    }
    return false;
  }

  static isDateBeforeCurrentDate(date: string): boolean {
    if (this.isValidDate(date)) {
      return moment(date, 'YYYY/MM/DD').isBefore(moment().format('YYYY/MM/DD'));
    }
    return false;
  }

  /**
   * checks for null or undefined
   * expects the input value to be in this format: YYYY/MM/DD
   *
   * @param {string} dateValue
   * @returns {boolean}
   */
  static isValidDate(dateValue: string): boolean {
    if (dateValue) {
      return dateRegularExpression_1950_2050.test(dateValue);
    }
    return false;
  }

  static isDateOnDemand(dateValue: string): boolean {
    return dateValue && (dateValue.trim().toUpperCase() == ON_DEMAND_VALUE
      || dateValue.trim().toUpperCase() == ON_DEMAND_LABEL
      || dateValue.replace(/[_ ]/g, '').toUpperCase() == 'ONDEMAND');
  }

  /**
   * checks for invalid input or partial dates
   * expects the input value to be in this format: YYYY/MM/DD
   * @param {string} dt
   * @returns {boolean}
   */
  static isNotValidDate(dt: string): boolean {
    return (dt == null || dt == '' || dt == undefined || dt.length < 10 || (dt && !datePattern.test(dt)));
  }

  /**
   * checks for invalid input or partial dates
   * expects the input value to be in this format: MM/DD/YYYY
   * @param {string} dt
   * @returns {boolean}
   */
  static isNotValidDdMmYyyyDate(dt: string): boolean {
    return (dt == null || dt == '' || dt == undefined || dt.length < 10 || (dt && !ddMmYyyyPattern.test(dt)));
  }

  static formatDateDayOfTheMonthYear(dt: string): string {
    let formatDate: string = 'the <DAY> day of <MONTH>, <YEAR>';
    if (this.isNotValidDate(dt)) {
      return formatDate;
    } else {
      let date = moment(dt, 'YYYY/MM/DD');
      return formatDate.replace('<DAY>', this.getOrdinal(date.date())).replace('<MONTH>', date.format('MMMM')).replace('<YEAR>', date.format('YYYY'));
    }
  }

  /**
   * returns number of days between [[from]] and [[to]]
   * for invalid inputs (i.e. null, undefined, partial dates) returns 0
   *
   * @param {string} from
   * @param {string} to
   * @returns {number}
   */
  static getDateDiff(from: string, to: string, ignoreMonths?: boolean): number {
    if (this.isNotValidDate(from) || this.isNotValidDate(to)) {
      return 0;
    } else {
      let fromDate = moment(from, 'YYYY/MM/DD');
      let toDate = moment(to, 'YYYY/MM/DD');
      if (ignoreMonths) {
        let mths: number = Math.trunc(toDate.diff(fromDate, 'M'));
        if (mths > 0) {
          fromDate = moment(from, 'YYYY/MM/DD').add(mths, 'M');
        }
      }
      return toDate.diff(fromDate, 'days');
    }
  }

  static getNumberOfDaysInEachYear(startDate: string, endDate: string) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const startYear = startDateObj.getFullYear();
    const endYear = endDateObj.getFullYear();
    const numberOfDaysInEachYear = {};

    for (let year = startYear; year <= endYear; year++) {
      const yearStartDate: any = new Date(year, 0, 1);
      const yearEndDate: any = new Date(year, 11, 31);

      if (year === startYear) {
        yearStartDate.setMonth(startDateObj.getMonth(), startDateObj.getDate());
      }
      if (year === endYear) {
        yearEndDate.setMonth(endDateObj.getMonth(), endDateObj.getDate());
      }

      const timeDifference = yearEndDate - yearStartDate;
      const daysInYear = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      numberOfDaysInEachYear[ year ] = daysInYear;
    }

    return numberOfDaysInEachYear;
  }

  static isGivenYearLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }

  /**
   * returns number of months between [[from]] and [[to]]
   * for invalid inputs (i.e. null, undefined, partial dates) returns 0
   *
   * @param {string} from
   * @param {string} to
   * @returns {number}
   */
  static getDateDiffMonths(from: string, to: string): number {
    if (this.isNotValidDate(from) || this.isNotValidDate(to)) {
      return 0;
    } else {
      let fromDate = moment(from, 'YYYY/MM/DD');
      let toDate = moment(to, 'YYYY/MM/DD');
      return toDate.diff(fromDate, 'months');
    }
  }

  /**
   * returns date of the last day of the month from input date
   * e.g. 2019/12/12 => 2019/12/31
   * @param dt
   */
  static lastDayOfMonthFromDate(dt: string): string {
    if (this.isNotValidDate(dt)) {
      return '';
    } else {
      return moment(dt, 'YYYY/MM/DD').endOf('month').format('YYYY/MM/DD');
    }
  }

  static lastDayOfPreviousMonthFromDate(dt: string): string {
    if (this.isNotValidDate(dt)) {
      return '';
    } else {
      return moment(dt, 'YYYY/MM/DD').add(-1, 'M').endOf('month').format('YYYY/MM/DD');
    }
  }

  /**
   * leap year
   * returns false for invalid inputs (i.e. null, undefined, partial dates)
   *
   * @param {string} dt
   * @returns {boolean}
   */
  static isLeapYear(dt: string): boolean {
    if (this.isNotValidDate(dt)) {
      return false;
    } else {
      return moment(dt).isLeapYear();
    }
  }

  //the input string should has format "YYYY/MM/DD", the output will be "MMMM DD, YYYY', otherwise return "";
  static formatDateString(dateStrValue: string, replaceInvalidDataWithDefaultValue: boolean, defaultValue?: string): string {
    if (dateStrValue) {
      if (datePattern.test(dateStrValue)) {
        return moment(dateStrValue, 'YYYY/MM/DD').format('MMMM DD, YYYY');
      }
    }
    return replaceInvalidDataWithDefaultValue ? defaultValue ? defaultValue : '' : dateStrValue;
  }

  // returns the start day of the week and end day of the week for a date - for example Dec 20, 2018 will return a range of Dec 16 - Dec 22
  static weekRange(utc): string {
    return moment(utc).day(0).format('MMM DD') + ' - ' + moment(utc).day(6).format('DD');

  }

  static getNumberOrDefault(input: number, dflt: number): number {
    if (input == undefined || input == null || isNaN(input)) {
      return dflt;
    }
    return Number(+input);
  }

  //the method support input as string or boolean
  static convertToBoolean(value): boolean {
    switch (typeof value) {
      case 'boolean' :
        return value;
      case 'string':
        return value.toLowerCase() === 'true';
      default:
        return Boolean(value);
    }
  }

  static capitalizeWord(word: string): string {
    if (!word) {
      return null;
    }

    if (word.length === 1) {
      return word[ 0 ].toUpperCase();
    } else {
      let name: string = word.toLowerCase();

      return name[ 0 ].toUpperCase() + name.substring(1);
    }

  }

  static concatenateNames(namesArray: string[], singularPrefix?: string, lastItemJoinString?: string): string {
    let namesList: string = null;

    if (!Array.isArray(namesArray) || namesArray.length === 0) {
      return null;
    }

    // It is better to creates a new array. Do not infect the original data
    let names: string[] = namesArray.filter(() => true);
    const namesLength = names.length;

    if (names.length === 1) {
      namesList = names[ 0 ];
    } else if (names.length > 1) {
      const lastItem: string = names[ names.length - 1 ];
      names.splice(names.length - 1, 1);
      namesList = names.join(', ');
      if (lastItemJoinString) {
        namesList += ' ' + lastItemJoinString + ' ' + lastItem;
      } else {
        namesList += ' and ' + lastItem;
      }
    }

    if (singularPrefix) {
      namesList = singularPrefix + (namesLength > 1 ? 's' : '') + ': ' + namesList;
    }
    return namesList;
  }

  static formatWithDecimals(value: number, numberOfDecimals: number): string {
    if (!value) {
      return '0.' + '0'.repeat(numberOfDecimals);
    }

    let valueString = value.toString();
    if (valueString.indexOf('.') > -1) {
      let parts: string[] = valueString.split('.');
      if (parts[ 1 ].length < numberOfDecimals) {
        const zeroLength = numberOfDecimals - parts[ 1 ].length;
        parts[ 1 ] = parts[ 1 ] + '0'.repeat(zeroLength);
      }
      valueString = parts[ 0 ] + '.' + parts[ 1 ];

    } else {
      valueString = valueString + '.' + '0'.repeat(numberOfDecimals);
    }

    return valueString;
  }

  static roundCurrency(currency: number): number {
    if (currency == null || currency == undefined) {
      return 0;
    } else {
      return Number.parseFloat(currency.toFixed(2));
    }
  }

  static roundCurrencyExcelStyle(currency: number): number {
    if (currency == null || currency == undefined) {
      return 0;
    } else {
      return Math.floor((Math.pow(10, 2) * currency) + 0.5) * Math.pow(10, -2);
    }
  }

  static roundCurrencyTCStyle(currency: number): number {
    if (currency == undefined || isNaN(currency)) {
      return 0;
    }
    if (currency * 100 == Number.MAX_SAFE_INTEGER) {
      return this.roundCurrency(currency);
    }

    // let leastSignificatDigits: number = 1e10 * currency - 1e10 * Math.floor(currency);

    let myRound: number = 100 * currency;
    let intPart: number = Math.floor(myRound);
    let fracPart: number = myRound - intPart;

    if (currency < 0) {
      intPart--;
    } else {

      if (fracPart >= 0.5) {
        intPart++;
      } else if (fracPart > 0.4999999990) {
        // TC does comparison like this
        // fracPart := abs (fracPart - 0.5);
        // if fracPart < 1.e-08 then Inc(i);
        // but in javascript Math.abs(fracPart - 0.5) will wipe out last digit that is crucial on this rounding calculation
        if (((myRound * 1e10 % (Math.floor(myRound) * 1e10) + 2) - 5e9) >= 0) { // if we're really close, like 1.e-10 close
          intPart++;
        }
      }
    }
    return this.roundCurrency(intPart / 100);
  }

  //capitalize first letter of each word
  static convertToTitleCase(word: string): string {
    return word.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
  }

  //converting text to mixed case for import from Spin, Teranet Connect, etc.
  static toMixedCase(text: string, separator: string = ' ', secondSeparator?: string): string {
    if (!text) {
      return text;
    }
    return text.split(separator).map(word => {
      if (word && (upperCaseWords.indexOf(word.toUpperCase()) > -1)) {
        return word.toUpperCase();
      } else if (word && (lowerCaseWords.indexOf(word.toUpperCase()) > -1)) {
        return word.toLocaleLowerCase();
      } else if (secondSeparator && word.indexOf(secondSeparator) > -1) {
        return Utils.toMixedCase(word, secondSeparator);
      } else {
        //addition to capitalize the part value, also need to uppercase the first character after ( OR -
        return _.capitalize(word).replace(/[\-\(][a-z]/g, match => match.toUpperCase());
      }
    }).join(' ');
  }

  static formattedCurrencyValue(currencyValue: number): string {
    const currencyPipe = new CurrencyPipe('en-CA');
    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      return currencyPipe.transform(currencyValue, 'CAD', 'symbol', '1.2-2').replace('CA', '');
    } else {
      return currencyPipe.transform('0.00', 'CAD', 'symbol', '1.2-2').replace('CA', '');
    }
  }

  static formatPercentageValue(percentage: number): string {
    const percentPipe = new PercentPipe('en-CA');
    if (percentage != undefined && !isNaN(percentage) && percentage != null && percentage.toString().trim() != '') {
      return percentPipe.transform(percentage / 100, '1.3');
    } else {
      return percentPipe.transform('0.00', '1.3');
    }
  }

  static formatSharesValue(shares: number): string {
    const percentPipe = new PercentPipe('en-CA');
    if (shares != undefined && !isNaN(shares) && shares != null && shares.toString().trim() != '') {
      return percentPipe.transform(shares / 100, '1.2').replace('%', '');
    } else {
      return percentPipe.transform('0.00', '1.2').replace('%', '');
    }
  }

  static roundNumber(value: number, numberOfDecimals ?: number): number {
    let result: number = value;
    let factor: number = 1;
    if (numberOfDecimals == null || numberOfDecimals < 0) {
      numberOfDecimals = 0;
    }
    if (value) {
      factor = Math.pow(10, numberOfDecimals);
      result = Math.round(value * factor) / factor;
    }
    return result;

  }

  //This method extract the queryParam with given name from given URL. if same param exists multiple times then it returns the first one
  static extractQueryParamFromUrl(url, name): string {
    if (!url) {
      return null;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[ 2 ]) {
      return '';
    }
    return decodeURIComponent(results[ 2 ].replace(/\+/g, ' '));
  }

  static getDisplayOrder(index: number): string {
    let displayOrder: string;

    if (index <= 0) {
      return null;
    }

    switch (index) {
      case 1 :
        displayOrder = '1st';
        break;
      case 2 :
        displayOrder = '2nd';
        break;
      case 3 :
        displayOrder = '3rd';
        break;
      default :
        displayOrder = index + 'th';
        break;
    }

    return displayOrder;
  }

  //validate against Telus allowed format if isCanadaOrUsa : " \(?[0-9]{3}\)?( |-)?[0-9]{3}( |-)?[0-9]{4}(\s?[xX]?[0-9]{0,4})? "
  static isValidTelusPhone(phoneNumber: string, isOptional: boolean = true, isCanadaOrUSA: boolean = true): boolean {
    if (isCanadaOrUSA) {
      let telusPhoneRegExp = /^\(?[0-9]{3}\)?( |-)?[0-9]{3}( |-)?[0-9]{4}(\s?[xX]?[0-9]{0,4})?$/;
      // if(phoneNumber){
      //     return telusPhoneRegExp.test(phoneNumber);
      // }else{
      //     return isOptional;
      // }
      return phoneNumber && phoneNumber.trim() ? telusPhoneRegExp.test(phoneNumber.trim()) : isOptional;
    } else {
      //Telus schema takes upto 14 characters for phone number
      return phoneNumber && phoneNumber.trim() ? (phoneNumber.trim().length <= 14) : isOptional;
    }

  }

  //validate against Telus allowed format if isCanada : " [A-Z]{1}[0-9]{1}[A-Z]{1} ?[0-9]{1}[A-Z]{1}[0-9]{1}"
  static isValidTelusPostalCode(postalCode: string, isOptional: boolean = true, isCanada: boolean = true, nonCanadaMaxLength: number = 7): boolean {
    if (isCanada) {
      let telusPostalCodeRegExp = /^[A-Z]{1}[0-9]{1}[A-Z]{1} ?[0-9]{1}[A-Z]{1}[0-9]{1}$/;
      // let result : boolean;
      // if(postalCode){
      //     result = telusPostalCodeRegExp.test(postalCode.toUpperCase());
      // }else{
      //     result = isOptional;
      // }
      // return result;
      return postalCode ? telusPostalCodeRegExp.test(postalCode.toUpperCase()) : isOptional;
    } else {
      //Telus schema takes up to 7 characters for postal code
      return postalCode ? (postalCode.trim().length <= nonCanadaMaxLength) : isOptional;
    }

  }

  static isValidTelusDate(dateValue: string, isOptional = false): boolean {
    if (dateValue && dateValue != '//') {
      return dateRegularExpression_1950_2050.test(dateValue);
    }
    return isOptional;
  }

  static isValidBirthDate(dateValue: string, isOptional = false): boolean {
    if (dateValue && dateValue != '//') {
      return dateRegularExpression_1900_2050.test(dateValue);
    }
    return isOptional;
  }

  //date date stored in format DD/MM/YYYy
  //UI display format is yyyy/MM/DD
  static convertDateToUIDisplayFormat(dateValue: string): string {
    if (dateValue && dateValue != '//') {
      dateValue = dateValue.split('/').reverse().join('/');
    }
    return dateValue;
  }

  static getDaysLabel(numberOfDays: number): string {
    let daysText = 'days';
    if (numberOfDays == 1) {
      return 'day';
    }
    return daysText;
  }

  static isEmptyString(value: string): boolean {

    if (value === undefined || value === null || value === '') {
      return true;
    } else {
      return false;
    }
  }

  static isEmptyOrUndefinedValue(item: CondominiumExpense): boolean {

    if (item.rollNumber !== '' || item.condominiumUnitType !== '' || item.condominiumExpense !== 0 || item.noUndividedShare !== '0.000000' && item.noUndividedShare != '0' || item.levelNumber) {
      return true;
    }
  }

  static isSpaceOnlyOrEmpty(value: string): boolean {

    if (value === undefined || value === null || value === '') {
      return true;
    } else {
      return value.replace(/\s/g, '').length === 0;
    }
  }

  static getProcessorTypeLabelByValue(processorType: DocumentProcessorType): string {
    let processorTypeOption = dropDowns.documentProcessorTypes.find(value => value.value == processorType);
    return processorTypeOption ? processorTypeOption.label : '';
  }

  static loadTaxSelectItem(taxRateType: string, defaultPstRate: number): SelectItem[] {
    if (defaultPstRate) {
      return [ {label: taxRateType, value: taxRateType}, {label: 'GST', value: 'GST'} ];
    } else {
      return [ {label: taxRateType, value: taxRateType} ];
    }
  }

  static convertToStringDate(date: Date): string {
    if (date) {
      let dt = new Date(date);
      let mm = ((dt.getMonth() + 1) < 10 ? '0' : '') + (dt.getMonth() + 1);
      let dd = (dt.getDate() < 10 ? '0' : '') + dt.getDate();
      let yyyy = dt.getFullYear();
      let format = yyyy + '/' + mm + '/' + dd;
      return format;
    }
    return '';
  }

  static convertStringCurrencyToNumber(str: String): number {
    return Number(str.replace(/[^0-9.-]+/g, ''));
  }

  static get serverTimezoneOffset(): string {
    const storedOffset = sessionStorage.getItem(SESSION_STORAGE_KEYS.timezoneOffset);
    return (storedOffset ? storedOffset : Constants.DEFAULT_SERVER_TIMEZONE_OFFSET);
  }

  static convertNumberToWord(num: number): string {
    return num <= numberWords.length ? numberWords[ num ] : '';
  }

  static getMonthName(m: string): string {
    return moment(m).format('MMMM');
  }

  static getMonthShortName(m: string): string {
    return moment(m).format('MMM');
  }

  static getYear(m: string): string {
    return moment(m).format('YYYY');
  }

  static getMonth(m: string): number {
    return moment(m).get('month');
  }

  static getDay(m: string): number {
    return moment(m).get('date');
  }

  static getDaysInMonth(m: string): number {
    return moment(m).daysInMonth();
  }

  static getFormattedDate(m: string, fmt: string, defaultForInvalidDate: string): string {
    if (Utils.isValidDate(m)) {
      return moment(m).format(fmt);
    }
    return defaultForInvalidDate;
  }

  // ToDo remove the one defined on matter.ts
  static getNumberofDaysMonth(closingDate: string): string {
    if (closingDate && datePattern.test(closingDate)) {
      let daysInMonths: string = (moment(closingDate, 'YYYY/MM/DD').daysInMonth()).toString();
      return daysInMonths;
    } else {
      return '';
    }
  }

  // ToDo remove the one defined on matter.ts
  static getElapsedDays(closingDate: string): string {
    if (closingDate && datePattern.test(closingDate)) {
      let closingDateStart = moment(closingDate.split('/')[ 0 ] + '/' + closingDate.split('/')[ 1 ] + '/01', 'YYYY/MM/DD');
      let closingDateEnd = moment(closingDate, 'YYYY/MM/DD');
      return (closingDateEnd.diff(closingDateStart, 'days')).toString();
    } else {
      return '';
    }
  }

  static getDateFromNumber(year: number, month: number, day: number): string {
    return moment(`${ year }/${ month }/${ day }`, 'YYYY/MM/DD').toISOString();
  }

  static getFormattedClientReLine(clientReLine: string) {
    if (clientReLine) {
      return clientReLine && clientReLine.length > 25 ? clientReLine.slice(0, 25) + '...' : clientReLine;
    } else {
      return '';
    }
  }

  static replaceAll(str: string, find: string, replaceWith: string): string {
    return str.replace(new RegExp(find, 'g'), replaceWith);
  }

  static isFullDate(dateValue: string): boolean {
    if (dateValue) {
      let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
      return (pattern.test(dateValue));
    } else {
      return false;
    }
  }

  static daysInCurrentYear(): number {
    return Utils.daysInYear(moment(new Date()).format('YYYY/MM/DD'));
  }

  static daysInGivenYear(year: number): number {
    return Utils.daysInYear(moment(new Date(year, 1, 1)).format('YYYY/MM/DD'));
  }

  static daysInYear(date: string): number {
    if (Utils.isValidDate(date)) {
      return Utils.isLeapYear(date) ? 366 : 365;
    }
    return NaN;
  }

  static cleanUpCurrencyFieldValue(newValue: string, digitCheckRegex: RegExp, maxDecimals: number = 2, significantCurrencyDigits: number = 9): string {
    return newValue && newValue.toString().replace(digitCheckRegex, '')
    .replace(/^\./, '0.') //replace the starting . with 0. , so user can save the typing of starting 0
    .replace(/(?!^)-/g, '')
    .replace(/(\.)(?=.*\1)/g, '') // remove multiple decimals
    .replace('(\.\d{0,' + maxDecimals + '}).*', '$1')
    .replace(new RegExp('(^[+-]?\\d{0,' + Number(significantCurrencyDigits) + '})\\d*'), '$1')
    .replace(/^0{2,}/, '0');
  }

  static getUserFromSession(): User {
    return JSON.parse((sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
  }

  static saveUserIntoSession(user: User): void {
    if (user) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(user));
    }
  }

  static getJurisdictionInitial = (jurisdictionValue: string): string => {
    return jurisdictionValue ? jurisdictionValue.match(/\b(\w)/g).join('').toUpperCase() : '';
  };

  static buildCondoPlanNumber = (condoPlan: CondominiumPlan | ProjectCondoPlan, isProvinceAB: boolean, jurisdictionValue: string): string => {
    let result = '';
    if (!isProvinceAB) {
      result += Utils.getJurisdictionInitial(jurisdictionValue);
    }
    result += Utils.getPlanNumber(condoPlan);
    return result;
  };

  private static getPlanNumber(condoPlan: CondominiumPlan | ProjectCondoPlan): string {
    let result: string = '';
    if (condoPlan.condominiumPlanType) {
      switch (condoPlan.condominiumPlanType) {
        case 'BARE_LAND_PLAN'                      :
          result += 'BLCP';
          break;
        case 'CONVENTIONAL_CONDOMINIUM_PLAN'       :
          result += 'CP';
          break;
        case 'LEASEHOLD_CONDOMINIUM_PLAN'          :
          result += 'LCP';
          break;
        case 'VACANT_LAND_CONDOMINIUM_PLAN'        :
          result += 'VLCP';
          break;
        case 'PHASED_CONDOMINIUM_PLAN'             :
          result += 'PCP';
          break;
        case 'COMMON_ELEMENTS_CONDOMINIUM_PLAN'    :
          result += 'CECP';
          break;
        case 'CONDOMINIUM_PLAN'                    :
          result += 'CP';
          break;
        case 'STANDARD_CONDOMINIUM_PLAN'           :
          result += 'SCP';
          break;
      }
      result += '  No. ';
      if (condoPlan.condominiumPlanNumber) {
        result += condoPlan.condominiumPlanNumber;
      } else {
        result += '??????????';
      }
    }
    return result;
  }

  static toNumber(value: any, defaultValue: number = 0.0): number {
    const num = Number(value);
    return _.isFinite(num) ? num : defaultValue;
  }

  //originalNumberString should only include number with string type
  private static incrementValue(originalNumberString: string, maxLength: number): string {
    let result: string = '';
    let oneAdded: boolean = false;
    //if the last number is 9
    if (originalNumberString.endsWith('9')) {
      for (let i = originalNumberString.length - 1; i >= 0; i--) {
        let charValue = originalNumberString.charAt(i);
        let numValue: number = +charValue;
        if (numValue == 9 && !oneAdded) {
          result = '0' + result;
        } else {
          if (!oneAdded) {
            numValue++;
            oneAdded = true;
          }
          result = numValue + result;
        }
      }
      if (!oneAdded) {
        result = '1' + result;
      }
      if (result.length > maxLength) {
        result = result.substr(0, maxLength);
      }
    } else { // if the last number isn't  9, then ++
      let lastCharValue: string = originalNumberString.charAt(originalNumberString.length - 1);
      let lastCharNum: number = +lastCharValue;
      let lastCharAddedOne: string = (++lastCharNum) + '';
      result = originalNumberString.substr(0, originalNumberString.length - 1) + lastCharAddedOne;
    }

    return result;

  }

  static massOpenIncrementPropertyValue(sourceValue: string, maxLength: number) {
    //preserve leading and trailing spaces for teraview legal description
    let sourceValueSplitSpaces: string[] = sourceValue && sourceValue.match(/^( *)(.*?)( *)$/);
    let result;
    if (sourceValueSplitSpaces && sourceValueSplitSpaces.length >= 4 && sourceValueSplitSpaces[ 2 ]) {
      result = this.massOpenIncrementValue(sourceValueSplitSpaces[ 2 ], maxLength);
    }
    //for Property values, if it is not valid for incrementing
    //we should return the original value not a blank
    if (result) {
      //add leading and trailing spaces back from teraview legal description
      return sourceValueSplitSpaces[ 1 ] + result + sourceValueSplitSpaces[ 3 ];
    } else {
      return sourceValue;
    }
  }

  // If the matter number or file no on the Template matter does not begin or end with numeric value, or both begins and ends with numeric values the field is
  // empty.
  // Else the matter number on the Template Matter is incremented as follows and displayed in this field.
  // If ends with numeric adds 1 to the ending numeric value
  // Else adds 1 to the beginning numeric value.
  // Examples - MATTER19 would become MATTER20,  9UNIT becomes 10UNIT
  // If the length is greater the maxLength, truncate the number '0'
  static massOpenIncrementValue(sourceValue: string, maxLength: number) {
    let ret: string = '';

    if (!sourceValue) {
      return ret;
    }
    if (sourceValue) {
      //Parse matter number or file no to get the beginning part + middle part + the end part
      //matterOrFileNumberParts[2] is middle part. If it it can't find and it will return '', its length should be 0
      let matterOrFileNumberParts: string[] = sourceValue.match(/^(\d*)(.*?)(\d*)$/);
      if (Array.isArray(matterOrFileNumberParts) && matterOrFileNumberParts.length == 4) {
        if ((matterOrFileNumberParts[ 1 ] && matterOrFileNumberParts[ 1 ] !== '')
          && (!matterOrFileNumberParts[ 3 ] || matterOrFileNumberParts[ 3 ] === '')) {
          if (maxLength >= sourceValue.length) {
            ret = this.incrementValue(matterOrFileNumberParts[ 1 ], maxLength - matterOrFileNumberParts[ 2 ].length) + matterOrFileNumberParts[ 2 ] + matterOrFileNumberParts[ 3 ];
          }
        }

        if ((matterOrFileNumberParts[ 3 ] && matterOrFileNumberParts[ 3 ] !== '')
          && (!matterOrFileNumberParts[ 1 ] || matterOrFileNumberParts[ 1 ] === '')) {
          if (maxLength >= sourceValue.length) {
            ret = matterOrFileNumberParts[ 1 ] + matterOrFileNumberParts[ 2 ] + this.incrementValue(matterOrFileNumberParts[ 3 ], maxLength - matterOrFileNumberParts[ 2 ].length);
          }
        }
      }
    }
    return ret;
  }

  //date format yyyy/mm/dd
  static getYearStartDate(date: string): string {
    if (date) {
      return `${ parseInt(date) }/01/01`;
    }
    return '';
  }

  //date form yyyy/mm/dd
  static getYearEndDate(date: string): string {
    if (date) {
      return `${ parseInt(date) }/12/31`;
    }
    return '';
  }

  static daysDiff(fromDate: string, toDate: string): number {
    if (fromDate && toDate) {
      return moment(toDate, 'YYYY/MM/DD').diff(moment(fromDate, 'YYYY/MM/DD'), 'days');
    }
    return null;
  }

  static ceiling(input: number, significance: number): number {
    if (isNaN(input) || isNaN(significance)) {
      return 0;
    }
    return Math.ceil(Number(input) / Number(significance)) * Number(significance);
  }

  static validateEmail(email: string): boolean {
    if (!email) {
      return false;
    }
    let re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return re.test(email.toLowerCase());
  }

  /**
   * intended for format ThirdParty date value, based on Moses, curently FCT and Telus are all EST
   *
   'short': equivalent to 'M/d/yy, h:mm a' (6/15/15, 9:03 AM).
   'medium': equivalent to 'MMM d, y, h:mm:ss a' (Jun 15, 2015, 9:03:01 AM).
   'long': equivalent to 'MMMM d, y, h:mm:ss a z' (June 15, 2015 at 9:03:01 AM GMT+1).
   'full': equivalent to 'EEEE, MMMM d, y, h:mm:ss a zzzz' (Monday, June 15, 2015 at 9:03:01 AM GMT+01:00).
   'shortDate': equivalent to 'M/d/yy' (6/15/15).
   'mediumDate': equivalent to 'MMM d, y' (Jun 15, 2015).
   'longDate': equivalent to 'MMMM d, y' (June 15, 2015).
   'fullDate': equivalent to 'EEEE, MMMM d, y' (Monday, June 15, 2015).
   'shortTime': equivalent to 'h:mm a' (9:03 AM).
   'mediumTime': equivalent to 'h:mm:ss a' (9:03:01 AM).
   'longTime': equivalent to 'h:mm:ss a z' (9:03:01 AM GMT+1).
   'fullTime': equivalent to 'h:mm:ss a zzzz' (9:03:01 AM GMT+01:00).

   Notes: if input value is Date timestamp number value, then the formatted value may change if local PC TZ change

   expected input date format is yyyy/MM/dd
   */
  static formatDateMonthNameFirst(value: string): string {
    //'mediumDate': equivalent to 'MMM d, y' (Jun 15, 2015) OR (Jul 1, 2013).
    let formattedValue = '';
    if (value) {
      formattedValue = new DatePipe('en-US').transform(value, 'mediumDate');
    }
    // console.log(">> Date String value %s formatted into %s", value, formattedValue);
    return formattedValue;
  }

  static formatDate(value: string, format: string = 'yyyy/MM/dd') {
    return new DatePipe('en-US').transform(value, format);
  }

  //all integration (3rd party) services use EST as timestamp
  static formatESTDateTimeMonthNameFirst(input: string): string {
    if (!input || input.trim() === '') {
      return '';
    } else {
      const tzIdx = input.search((/[+-]\d{2}\:\d{2}$/g));
      // DPPMP-23707: ensure there is a timezone component at end of "input".
      //              According to email with Moses, all integration (3rd party) services use EST as timestamp, we compensate for it here before
      //              localizing the date/time
      const srcDate = tzIdx < 0 ? input + Utils.serverTimezoneOffset : input;
      return moment.parseZone(srcDate).local().format('MMM DD, YYYY hh:mm A');
    }

  }

  static getLabelFromValue(value: string, dropdownArray: any[]): string {
    let item = dropdownArray.find(row => row.value == value);
    return item ? item.label : '';
  }

  /*
   * Sometimes a promise may take too long to resolve or reject.
   * The timeoutPromise() method returns a promise that resolves or rejects dependding on the time we pass as a parameter, with the value or reason from that promise.
   * @param ms: Max time to wait in ms
   * @param promise: Promise Function
   * @returns Promise if resolved or error if rejected
   */
  static timeoutPromise = (ms: number, promise: Promise<any>): Promise<any> => {

    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
      let id = setTimeout(() => {
        clearTimeout(id);
        reject('Timed out in ' + ms + 'ms.');
      }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
      promise,
      timeout
    ]);
  };

  static join(values: string[], separator: string = '', filterEmptyValue: boolean = true) {
    if (Array.isArray(values)) {
      return values.filter(value => filterEmptyValue ? !!value : true).join(separator);
    }
    return '';
  }

  /*
   * scrolls the element's parent container such that the element is visible to the user
   * Since each page scroll is different.
   * Can't use element.scrollToView() because of ie support and modals behaviour
   * @param {elementId} The id of the element that need to be scroll
   * @param {relativeToElementId} Relative to element id
   * @param {scrollableElementId} The id of the element that has scroll bar. If null, then we use scrollableClass or  window
   * @param {scrollableClass} The class of the element that has scroll bar. If null, then we use window
   */

  static scrollIntoView(elementId: string, relativeToElementId: string, scrollableElementId?: string, scrollableClass?: string): void {
    setTimeout(() => {
      let element = document.getElementById(elementId);
      let relativeToElement = document.getElementById(relativeToElementId);
      if (element && relativeToElement) {
        let elementTop = element.getBoundingClientRect().top;
        let relativeToTop = relativeToElement.getBoundingClientRect().top;
        if (scrollableElementId) {
          let scrollableElement = document.getElementById(scrollableElementId);
          if (scrollableElement) {
            scrollableElement.scroll(0, elementTop - relativeToTop - 50);
          }
        } else if (scrollableClass) {
          let classElements = document.getElementsByClassName(scrollableClass);
          if (classElements && classElements.length) {
            classElements[ 0 ].scroll(0, elementTop - relativeToTop - 50);
          }
        } else {
          //if(elementTop < relativeToTop+100 || elementTop > window.innerHeight-200){
          window.scrollTo(0, elementTop - relativeToTop - 50);
          //}

        }
      }
    }, 0);

  }

  static convertHtmlToString(html: string): string {
    var div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  static isWeekend(dateValue: string): boolean {
    let ret = false;
    if (this.isValidDate(dateValue)) {
      let date = moment(dateValue);
      if (date && (date.isoWeekday() == 6 || date.isoWeekday() == 7)) {
        ret = true;
      }
    }
    return ret;
  }

  static formattedCurrencyValueExt(currencyValue: number, allowParentheses: boolean = false): string {
    let currencyValueStr: string = '';
    currencyValueStr = Utils.formattedCurrencyValue(currencyValue);

    if (allowParentheses && currencyValue < 0) {
      currencyValueStr = '(' + currencyValueStr.replace('-', '') + ')';
    }

    return currencyValueStr;
  }

  static getAuthenticatedUserId(): string{
    const userJson= sessionStorage.getItem(SESSION_STORAGE_KEYS.authenticatedUser);
    if (userJson) {
      const user = JSON.parse(userJson);
      return user ? user.userId : '';
    }
    return '';
  }
}
