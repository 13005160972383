import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {Observable} from 'rxjs';
import {AccessGroup} from './access-groups';
import {api} from '../../common/api';
import {User} from '../../matters/shared/user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import Utils from "../../shared-main/utils";

/**
 *  Access Groups service defines all api calls for Access Groups component
 */
@Injectable()
export class AccessGroupsService {

  constructor(private http: HttpClient) {
  }

  updateAccessGroup(accessGroup: AccessGroup, accountId: string): Observable<any> {
    let url = `${ api }/accounts/${ accountId }/accessGroups/${ accessGroup.id }`;
    return this.http.put(url, accessGroup)
    .map((response) => {
      if (response) {
        return new AccessGroup(response[ 'AccessGroup' ]);
      }
    });
  }

  getAccessGroups(accountId: string): Observable<AccessGroup[]> {
    let url = `${ api }/accounts/${ accountId }/accessGroups`;
    return this.http.get(url)
    .map((res) => {
      let accessGroups: AccessGroup[] = [];
      let data = res[ 'AccessGroups' ];
      data.forEach(item => {
        accessGroups.push(new AccessGroup(item));
      });
      return accessGroups;
    });
  }

  getAccessGroupByID(accessGroupId: number, accountId: string): Observable<AccessGroup> {
    let url = `${ api }/accounts/${ accountId }/accessGroups/${ accessGroupId }`;
    return this.http.get(url)
    .map((res) => {
      return new AccessGroup(res[ 'AccessGroup' ]);
    });
  }

  getDefaultAccessGroup(accountId: string): Observable<AccessGroup> {
    let url = `${ api }/accounts/${ accountId }/defaultAccessGroup`;
    return this.http.get(url)
    .map((res) => {
      return new AccessGroup(res[ 'AccessGroup' ]);
    });
  }

  getUsers(accountId: string): Observable<User[]> {
    let url = `${ api }/accounts/${ accountId }/users`;
    return this.http.get(url)
    .map((res) => {
      let users: User[] = [];
      let data = res[ 'UsersForAccount' ];
      data.forEach(item => {
        users.push(new User(item));
      });
      return users;
    });
  }

  saveAccessGroup(accessGroup: AccessGroup, accountId: string): Observable<any> {
    let url = `${ api }/accounts/${ accountId }/accessGroups`;
    return this.http.post(url, accessGroup)
    .map((response) => {
      return response;

    });
  }

  getAccessGroupsAvailableForUser(): Observable<AccessGroup[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let userId = Utils.getAuthenticatedUserId();
    let url = `${ api }/accounts/${ accountId }/users/${ userId }/accessGroups`;
    return this.http.get(url)
    .map((res) => {
      let accessGroups: AccessGroup[] = [];
      let data = res[ 'AccessGroups' ];
      data.forEach(item => {
        accessGroups.push(new AccessGroup(item));
      });
      return accessGroups;
    });
  }
}
