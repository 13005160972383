export const ErrorDirectiveOrder = {
  'Matter Opening': {
    ERROR_ORDER_ID: 10
  },
  'Purchasers': {
    ERROR_ORDER_ID: 20
  },
  'Vendors': {
    ERROR_ORDER_ID: 20
  },
  'Mortgagors': {
    ERROR_ORDER_ID: 30
  },
  'Vendors & Solicitor': {
    ERROR_ORDER_ID: 40
  },
  'Other Solicitor': {
    ERROR_ORDER_ID: 50
  },
  'Purchasers & Solicitor': {
    ERROR_ORDER_ID: 60
  },
  'Property/Teranet Connect': {
    ERROR_ORDER_ID: 70
  },
  'Subject Property/SPIN': {
    ERROR_ORDER_ID: 70
  },
  'Property': {
    ERROR_ORDER_ID: 70
  },
  'Consideration & LTT': {
    ERROR_ORDER_ID: 80
  },
  'Compliance': {
    ERROR_ORDER_ID: 90
  },
  'Existing Mortgage(s)': {
    ERROR_ORDER_ID: 100
  },
  'New Mortgage': {
    ERROR_ORDER_ID: 110
  },
  'Mortgages': {
    ERROR_ORDER_ID: 120
  },
  'Condo Corporation': {
    ERROR_ORDER_ID: 130
  },
  'Fire Insurance': {
    ERROR_ORDER_ID: 140
  },
  'Requisitions': {
    ERROR_ORDER_ID: 150
  },
  'Undertakings Received': {
    ERROR_ORDER_ID: 160
  },
  'Statement of Adjustments': {
    ERROR_ORDER_ID: 170
  },
  'Supplemental Tasks': {
    ERROR_ORDER_ID: 180
  },
  'Statement of Account': {
    ERROR_ORDER_ID: 190
  },
  'Trust Ledger': {
    ERROR_ORDER_ID: 200
  },
  'Report to Purchaser': {
    ERROR_ORDER_ID: 210
  },
  'Executions Affidavits': {
    ERROR_ORDER_ID: 220
  },
  'Forms': {
    ERROR_ORDER_ID: 230
  },
  'Document Production': {
    ERROR_ORDER_ID: 240
  },
  'Matter Documents': {
    ERROR_ORDER_ID: 250
  },
  'Notes': {
    ERROR_ORDER_ID: 260
  },
  'Title Insurance': {
    ERROR_ORDER_ID: 270
  }

};
