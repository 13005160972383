import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ContactSummary extends BaseEntity {
  name: string;
  phone: string;
  email: string;
  summary: string;

  constructor(contactSummary?: ContactSummary) {
    super(contactSummary);
  }
}
