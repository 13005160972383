import {AppMode, EnvironmentConfig, KeycloakConfig} from '../environment';

export class CaTrainingEnvironment implements EnvironmentConfig {
  appMode = AppMode.CA;
  tcolUrl = 'https://account-t.apicanada.com/register';
  keycloak: KeycloakConfig = undefined;
  ltsaWebBaseUrl: string = 'https://appsuat.ltsa.ca';
  landingPageUrl: string = undefined;
  goCardlessApiUrl: string = 'https://connect-sandbox.gocardless.com/';
  goCardlessClientId: string = 'umqWZWz796Js1b_kPzT3VnzpevYiVM-Wke6V0ivPs-kOpyfaC8fJ5joeukjPOMd2';
  goCardlessAuthRedirectUri: string = '/gocardless/redirect';
}
