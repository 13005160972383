import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {DocumentTemplate, DocumentTemplateFile} from '../../matters/document-production/document-template';
import {Observable} from '../../../../node_modules/rxjs/Rx';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {tprTemplatesApi, tprTemplatesKey} from './tpr-templates-api';

@Injectable()
export class TprTemplatesService {

  constructor(private httpClient: HttpClient) {
  }

  getAllReportTemplates(): Observable<DocumentTemplateFile[]> {
    let url = tprTemplatesApi.tprXmlTemplates;
    return this.httpClient.get(url)
    .map((response) => {
      let result: DocumentTemplateFile[] = [];
      for (let dp of response[ tprTemplatesKey.tprTemplateFiles ]) {
        let tprTemplate = new DocumentTemplateFile(dp);
        result.push(tprTemplate);
      }
      return result;
    });
  }

  downloadReportTemplateFile(tprTemplateId: number) {
    let url: string = tprTemplatesApi.downloadTprXmlTemplate.replace('{tprXmlDocumentTemplateFileId}', '' + tprTemplateId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    window.open(url, '_blank');
  }

  editReportTemplateFile(accountId: number, tprTemplate: DocumentTemplate) {
    let url: string = tprTemplatesApi.tprXmlTemplate.replace('{tprXmlDocumentTemplateId}', '' + tprTemplate.docGenTemplateId);
    return this.httpClient.put(url, tprTemplate)
    .map((res) => {
      return new DocumentTemplate(res[ tprTemplatesKey.tprTemplate ]);
    });
  }

  //fix to use document template
  deleteReportTemplateFile(tprXmlDocumentTemplateId: number): Observable<any> {
    let url: string = tprTemplatesApi.deleteTprXmlTemplate.replace('{tprXmlDocumentTemplateFileId}', '' + tprXmlDocumentTemplateId);
    return this.httpClient.delete(url)
    .map((res) => {
      return res;
    });
  }
}
