import {inject, Injectable} from '@angular/core';
import {ParcelRegister} from '../../../shared-main/teranet/parcel-register';
import {AppConfig} from '../../../shared-main/app-configuration';
import {currentMatter} from '../current-matter';
import {Mortgage} from '../mortgage';
import {MatterMortgageUtil} from '../matter-utils/matter-mortgage-util';
import {UndertakingsConfigService} from '../../../admin/shared/undertaking-config.service';
import {MortgageSoAdjService} from '../../../shared-main/mortgage-so-adj.service';

@Injectable({
  providedIn: 'root'
})
export class ImportMortgageDataService {

  appConfig = inject(AppConfig);
  undertakingsConfigService = inject(UndertakingsConfigService);
  mortgageSoAdjService = inject(MortgageSoAdjService);

  get matter() {
    return currentMatter.value;
  }

  public isImportMortgageMenuOptionApplicable(selectedParcelRegister: ParcelRegister): boolean {
    return this.matter.isMatterProvinceON && (this.matter.isSale || this.matter.isMortgage) && selectedParcelRegister
      && selectedParcelRegister.instrumentsNonDeletedNonTransferType().length > 0;
  }

  importTeranetMortgageData(instruments: any[]) {
    if (instruments && instruments.length > 0) {
      let copiedInstruments = instruments.filter(item => item.isCopied);
      if (copiedInstruments && copiedInstruments.length > 0) {
        copiedInstruments.forEach(item => {
          if (item.matterMortgage && item.isCopied) {
            let existingMortgage: Mortgage;
            if (item.matterMortgage.existingMortgageId != undefined) {
              existingMortgage = this.matter.existingMortgages.find(mortgage => mortgage.id == item.matterMortgage.existingMortgageId);
            } else if (this.matter.existingMortgages.length < this.appConfig.getMaxNumberOfMortgages()) {
              existingMortgage = this.matter.addNewExistingMortgage();
            }
            if (existingMortgage) {

              if (item.matterMortgage.mortgageDispositionType) {
                existingMortgage.mortgageDispositionType = item.matterMortgage.mortgageDispositionType;
              }

              existingMortgage.mortgageRequestNo = item.matterMortgage.registrationNo != undefined ?
                item.matterMortgage.registrationNo : existingMortgage.mortgageRequestNo;

              existingMortgage.mortgageRequestDate = item.matterMortgage.registrationDate != undefined ?
                item.matterMortgage.registrationDate : existingMortgage.mortgageRequestDate;

              existingMortgage.financingType = item.matterMortgage.instrumentType != undefined ?
                MatterMortgageUtil.mapTeranetInstrumentTypeToMortgageFinancingType(item.matterMortgage.instrumentType, this.matter.provinceCode)
                : existingMortgage.financingType;

              existingMortgage.teranetSuggestedMortgagees = item.matterMortgage.mortgageesText != undefined ?
                item.matterMortgage.mortgageesText : existingMortgage.teranetSuggestedMortgagees;
              existingMortgage.undertakingDirty = true;

              if (existingMortgage.isMortgageDispositionRemain()) {
                let availableMortgagePriority = this.matter.availableMortgagePriority(existingMortgage ? existingMortgage.mortgagePriority : undefined);
                if (existingMortgage.mortgagePriority == undefined) {
                  existingMortgage.mortgagePriority = Math.min(...availableMortgagePriority.map(item => {
                    return Number(item.value);
                  }));
                }
              }
            }
          }
        });
        this.matter.updateUndertakings(this.undertakingsConfigService);
        this.matter.updateTrustLedgerAndStatementOfAdjustment(this.mortgageSoAdjService);
        this.matter.dirty = true;
      }
    }
  }
}
