<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1 *ngIf="!context.assignedToStaffName">Assigned Task Correspondence</h1>
                <h1 *ngIf="context.assignedToStaffName">Task Message</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group" *ngIf="context.assignedToStaffName">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Assigned To:</label>
            </div>
            <div class="col-lg-8" ><label class="control-label">{{context.assignedToStaffName}}</label></div>
        </div>
        <div class="form-group" *ngIf="(!context.matter || (context.matter && !context.matter.isOpportunityMatter())) && !context.assignedToStaffName">
            <div class="col-lg-2">
                <span class="radio">
                        <input type="radio"
                               id="staff"
                               name="staff"
                               value="STAFF"
                               [(ngModel)]="assignedTo"
                        />
                        <label for="staff">Assign to Staff</label>
                </span>
            </div>
            <div class="col-lg-10 text-left">
                <span class="radio">
                        <input type="radio"
                               id="client"
                               [dp-read-only]="isPrimaryClientIssue"
                               name="client"
                               value="CLIENT"
                               [(ngModel)]="assignedTo"
                        />
                        <label for="client">Assign to Client {{isPrimaryClientIssue ? ' -  (A primary client with a valid email address is required to assign to\n' +
                            '                    client)' : ''}}</label>
                </span>
            </div>
        </div>
        <ng-container *ngIf="!assignedToStaff()">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Select Client</label>
                </div>
                <div class="col-lg-8">
                    <select class="form-control" id="selectedClient" name="selectedClient"
                            [(ngModel)]="selectedClientId">
                        <option *ngFor="let client of clients" [value]="client.id">
                            {{client.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Message Precedent</label>
                </div>
                <div class="col-lg-8">
                    <select class="form-control" id="messagePrecedents" name="messagePrecedents"
                            [(ngModel)]="selectedPrecedent"
                            (change)="updateEditorMessage()">
                        <option value=""></option>
                        <option *ngFor="let precedent of messagePrecedents" [value]="precedent.id">
                            {{precedent.precedentName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right">Message</label>
                </div>
                <div class="col-lg-8">
                    <textarea class="form-control" rows="8"
                              [dp-read-only]="true"
                              name="unitySignatureBlock"
                              id="unitySignatureBlock"
                              [(ngModel)]="message">
                    </textarea>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="assignedToStaff()">
            <div class="form-group" *ngIf="!context.assignedToStaffName">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Select Staff</label>
                </div>
                <div class="col-lg-8">
                    <select class="form-control" id="staffList" name="staffList"
                            [(ngModel)]="unityMessage.recipientUserId"
                    >
                        <option value=""></option>
                        <option *ngFor="let staffOption of staffOptions" [value]="staffOption.value">
                            {{staffOption.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right">Message</label>
                </div>
                <div class="col-lg-8">
                    <textarea class="form-control" rows="8"
                              name="unitySignatureBlockStaff"
                              id="unitySignatureBlockStaff"
                              [(ngModel)]="unityMessage.message">
                    </textarea>
                </div>
            </div>


        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center padding-bottom-20">
            <label class="control-label red" *ngIf="!context.assignedToStaffName">Selecting Assign will also save the record</label>
        </div>
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="send()">
                <span>{{assignedToStaff() && !context.assignedToStaffName ? 'Assign' : 'Send'}}</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
