import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class FctPortalContext {
  title: string;
  securedUrl: string;
}

@Component({
  selector: 'dp-fct-portal-modal',
  templateUrl: 'fct-portal.modal.component.html'
})
export class FctPortalModal extends ModalComponent<FctPortalContext> implements OnInit {
  fctFileContainerUrl: SafeUrl;
  stopListening: Function;
  isFctDataDirty: boolean = false;
  @ViewChild('fctFrame') fctFrame: ElementRef;

  constructor(
    public dialog: MatDialogRef<FctPortalModal>,
    public dialogService: DialogService,
    public sanitizer: DomSanitizer,
    public renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) context?: FctPortalContext
  ) {
    super(dialog, context);
    this.stopListening = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnInit() {
    this.fctFileContainerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
  }

  handleMessage(event) {
    if (event && event.origin && this.context.securedUrl.indexOf(event.origin) > -1) {
      console.log('handleMessage    Origin: ' + event.origin + '  Data : ' + event.data);
      if (event.data == 'dirty') {
        this.dialogService
          .confirm(
            'Confirmation',
            'Are you sure you want to leave this page? All information entered will be lost if you proceed to navigate away from this screen. Do you wish to proceed?',
            false,
            'Leave this page',
            'Stay on this page'
          )
          .subscribe((res) => {
            if (res) {
              this.dialog.close({action: 'Close'});
            }
          });
      } else if (event.data == 'untouched') {
        this.dialog.close({action: 'Close'});
      }
    }
  }

  ngOnDestroy() {
    this.stopListening();
  }

  close(): void {
    this.fctFrame.nativeElement.contentWindow.postMessage('OK', this.context.securedUrl);
  }
}
