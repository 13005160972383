import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {catchError, map, shareReplay} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {parse, formatISO} from 'date-fns';

import {api} from '../../common';
import {Matter} from '../shared/matter';
import {isEmpty} from '../shared/matter-util';
import {PTTExemptionCode, PTT_EXEMPTION_LIST} from '../shared/matter-participant';
import {
  PTTExemption,
  PTTExemptionConfig,
  PropertyTransferTax,
  buildDefaultPtt
} from './property-transfer-tax';
import {
  calculateExemptionAmount,
  calculatePttAdditionalAmount,
  calculatePttNetAmount,
  getBorrowers,
  getPurchasers,
  roundCurrency
} from './ptt-utils';

@Injectable({providedIn: 'root'})
export class PttService {
  url = `${ api }/ptt/exemption-config`;

  constructor(private httpClient: HttpClient) {
  }

  getExemptionConfig(closingDate?: string) {
    let params = {};
    if (closingDate) {
      params = {exemptionStartDate: this.getISODate(closingDate)};
    }
    return this.httpClient.get<PTTExemption[]>(this.url, {params}).pipe(
      map((exemptions) =>
        exemptions.reduce((prev, curr) => {
          prev[ curr.exemptionCode ] = curr;
          return prev;
        }, {} as PTTExemptionConfig)
      ),
      shareReplay(2),
      catchError(this.handleError)
    );
  }

  private handleError() {
    return throwError(
      new Error('Error requesting PTT exemption configuration')
    );
  }

  private getISODate(cdate: string): string | null {
    try {
      const xdate = parse(cdate, 'yyyy/MM/dd', new Date());
      return formatISO(xdate);
    } catch (error) {
      return null;
    }
  }

  public updatePropertyTransferTax(matter: Matter, config?: PTTExemptionConfig, xcode?: PTTExemptionCode) {
    if (!(matter.isPurchaseBC || matter.isMortgageBC)) {
      return;
    }
    if (!matter.propertyTransferTax) {
      matter.propertyTransferTax = buildDefaultPtt();
    }

    const ptt = matter.propertyTransferTax;
    const fmv = matter.propertyModel?.purchasePrice || 0.0;
    if (fmv <= 0) {
      ptt.onePercentOnFirst200kAmount = 0.0;
      ptt.threePercentOnRemainderOver2MAmount = 0.0;
      ptt.twoPercentOnRemainderUpTo2MAmount = 0.0;
      ptt.twoPercentOnRemainderOver3MAmount = 0.0;
      ptt.additionalAmount = 0.0;
      ptt.exemptionAmount = 0.0;
      ptt.grossAmount = 0.0;
      ptt.netAmount = 0.0;

      return ptt;
    }

    this.calculateStandardPtt(ptt, fmv);
    this.calculateAditionalAmount(matter);
    this.calculateExemptionAmount(matter, fmv, config, xcode);
    ptt.netAmount = calculatePttNetAmount(ptt);
    matter.isDirty = true;

  }

  public recalculateForExemptionCode() {
  }

  calculateStandardPtt(ptt: PropertyTransferTax, price: number) {
    const MIN_TAXBASE = 200000;
    const FIRST_TAX_SEGMENT = 2000000;
    const SECOND_TAX_SEGMENT = 3000000;
    let base = price <= MIN_TAXBASE ? price : MIN_TAXBASE;
    ptt.onePercentOnFirst200kAmount = roundCurrency(base * 0.01);

    if (price > MIN_TAXBASE) {
      base = price < FIRST_TAX_SEGMENT ? price : FIRST_TAX_SEGMENT;
      ptt.twoPercentOnRemainderUpTo2MAmount = roundCurrency(
        (base - MIN_TAXBASE) * 0.02
      );
    } else {
      ptt.twoPercentOnRemainderUpTo2MAmount = 0;
    }

    if (price > FIRST_TAX_SEGMENT) {
      base = price - FIRST_TAX_SEGMENT;
      ptt.threePercentOnRemainderOver2MAmount = roundCurrency(base * 0.03);
    } else {
      ptt.threePercentOnRemainderOver2MAmount = 0;
    }

    if (price > SECOND_TAX_SEGMENT) {
      base = price - SECOND_TAX_SEGMENT;
      ptt.twoPercentOnRemainderOver3MAmount = roundCurrency(base * 0.02);
    } else {
      ptt.twoPercentOnRemainderOver3MAmount = 0;
    }

    ptt.grossAmount =
      ptt.onePercentOnFirst200kAmount +
      ptt.twoPercentOnRemainderUpTo2MAmount +
      ptt.threePercentOnRemainderOver2MAmount +
      ptt.twoPercentOnRemainderOver3MAmount;
  }

  calculateExemptionAmount(matter: Matter, fmv: number, config: PTTExemptionConfig, exCode?: PTTExemptionCode) {
    const ptt = matter.propertyTransferTax;
    if (!exCode) {
      exCode = this.findExemptionCode(matter);
    }
    ptt.exemptionCode = exCode;

    const standardPtt = ptt.grossAmount;
    const closingDate = matter.matterCloseDate;

    if (fmv <= 0 || standardPtt <= 0.0) {
      ptt.exemptionAmount = 0.0;
      ptt.netAmount = calculatePttNetAmount(ptt);
      return;
    }

    if (![ 'FTH', 'NEWLY_BUILT_HOME' ].includes(ptt.exemptionCode)) {
      ptt.exemptionAmount = 0.0;
      ptt.netAmount = calculatePttNetAmount(ptt);
      return;
    }

    if (config) {
      this.doCalculateExemptionAmount(ptt, matter, config);
    } else {
      this.getExemptionConfig(closingDate)
      .subscribe((config) => {
        this.doCalculateExemptionAmount(ptt, matter, config);
      }, () => {
        ptt.exemptionAmount = 0.0;
        ptt.netAmount = calculatePttNetAmount(ptt);
      });
    }

  }

  private doCalculateExemptionAmount(ptt: PropertyTransferTax, matter: Matter, config: PTTExemptionConfig) {
    const {grossAmount, exemptionCode} = ptt;
    const amout = calculateExemptionAmount(
      matter,
      grossAmount,
      exemptionCode,
      config
    );
    ptt.exemptionAmount = amout;
    ptt.netAmount = calculatePttNetAmount(ptt);
  }

  private findExemptionCode(matter: Matter) {
    const currentCode = matter.propertyTransferTax?.exemptionCode;
    const options = this.buildExceptionCodeOptions(matter);

    if (isEmpty(options)) {
      return currentCode;
    }

    if (options.findIndex((i) => i.code === currentCode) >= 0) {
      return currentCode;
    }

    if (options.length === 1) {
      return options[ 0 ].code;
    }
    const found = options.find((i) =>
      [ 'FTH', 'NEWLY_BUILT_HOME' ].includes(i.code)
    );
    return found ? found.code : undefined;
  }

  private buildExceptionCodeOptions(matter: Matter) {
    const allCodes = this.getMatterParticipantsForPtt(matter)
    .filter((i) => i.pttExemptionCode !== null)
    .map((i) => i.pttExemptionCode);
    const codes = Array.from(new Set(allCodes));

    if (!codes) {
      return [];
    }

    if (codes.length === 1) {
      return [ PTT_EXEMPTION_LIST.find((i) => i.code === codes[ 0 ]) ];
    }

    if (codes.length === 2 && codes.includes('NO_EXEMPTION')) {
      const only = codes.find((it) => it !== 'NO_EXEMPTION');
      return [ PTT_EXEMPTION_LIST.find((i) => i.code === only) ];
    }

    if (codes.length >= 2) {
      return PTT_EXEMPTION_LIST.filter((i) => codes.includes(i.code));
    }
    return [];
  }

  public getExemptionOptions(matter: Matter) {
    const allCodes = this.getMatterParticipantsForPtt(matter)
    .filter((i) => i.pttExemptionCode !== null)
    .map((i) => i.pttExemptionCode);
    const codes = Array.from(new Set(allCodes));
    if (!codes) {
      return [];
    }

    if (codes.length === 1) {
      return [ PTT_EXEMPTION_LIST.find((i) => i.code === codes[ 0 ]) ];
    }

    if (codes.length >= 2) {
      return PTT_EXEMPTION_LIST.filter((i) => codes.includes(i.code));
    }
    return [];
  }

  private getMatterParticipantsForPtt(matter: Matter) {
    if (matter.isMortgageBC) {
      return getBorrowers(matter);
    } else if (matter.isPurchaseBC) {
      return getPurchasers(matter);
    }
  }

  private calculateAditionalAmount(matter: Matter) {
    const additionalAmount = calculatePttAdditionalAmount(matter);
    const ptt = matter.propertyTransferTax;
    const prev = ptt.additionalAmount;
    if (prev !== additionalAmount) {
      ptt.additionalAmount = additionalAmount;
    }
  }
}
