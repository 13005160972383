<!-- modal -->
<div class="container-fluid custom-modal-container" id="depositForm">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1><span>Adjustment Type : {{modalTitle}}</span>
                <span *ngIf="context.modalSubTitle" class="modal-sub-title">{{context.modalSubTitle}}</span>
            </h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body form-horizontal" [ngStyle]="{'max-height': calculateModalBodyMaxHeight(), 'min-height': calculateModalBodyMinHeight()}">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12 fields-group margin-bottom-0 padding-top-0 margin-top-0 padding-left-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isCondoProjectInON()">
                <div class="form-group">
                    <label class="control-label col-lg-2">Bank Name</label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="bankName"
                                name="bankName"
                                (ngModelChange)="onBankNameChange($event)"
                                [dp-read-only]="isProjectConfigTab"
                                [(ngModel)]="selectedTrustAccount">
                            <option *ngFor="let trustAcct of trustAccounts" [ngValue]="trustAcct">
                                {{trustAcct.account.mortgageeName}} - {{trustAcct.account.trustAccountNumber}}
                            </option>
                        </select>
                    </div>
                    <label class="control-label col-lg-2">Account number</label>
                    <div class="col-lg-4">
                        <input type="text" id="bankAccountNumber" name="bankAccountNumber"
                               class="form-control"
                               [(ngModel)]="isProjectConfigTab ? selectedTrustAccount?.account.trustAccountNumber : extraDepositConfig.bankAccountNumber"
                               [dp-read-only]="(isProjectConfigTab || (selectedTrustAccount && !selectedTrustAccount.isAccountNumberEditable))"
                        />
                    </div>
                </div>
            </div>


            <div class="row" [hidden]="isForcedMultiDeposit">
                <div class="form-group">
                    <label class="control-label col-lg-2">Multiple Deposits?</label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="multiDeposit"
                                name="multiDeposit"
                                (change)="onMultipleDepositChange(true)"
                                [(ngModel)]="multipleDeposit">
                            <option *ngFor="let multipleDep of multipleDepositOptions" [value]="multipleDep.value">
                                {{multipleDep.label}}
                            </option>
                        </select>
                    </div>
                    <label class="control-label col-lg-2">Pay Deposit out of our Trust Account</label>
                    <div class="col-lg-1">
                        <select class="form-control"
                                id="payDepositOutOfTrust"
                                name="payDepositOutOfTrust"
                                (change)="onPayDepositOutOfTrustChange()"
                                [(ngModel)]="payDepositOutOfTrust">
                            <option *ngFor="let item of yesNoNyOptions"
                                    [value]="item.value">
                                {{item.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="multipleDeposit === 'NO'">
                <div class="form-group">
                    <label class="control-label col-lg-2">Amount of Deposit</label>
                    <div class="col-lg-4">
                        <input type="text" id="depositAmount" name="depositAmount" class="form-control text-padding-update"
                               style="text-align:right;"
                               [(ngModel)]="propDepositAmount"
                               (ngModelChange)="onDepositAmtChange()"
                               dp-currency/>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="multipleDeposit === 'YES'">
                <div class="form-group ">
                    <label class="control-label col-lg-2 vertical-align-label">Format of Adjustment</label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="adjustmentFormat"
                                name="adjustmentFormat"
                                (change)="onAdjustmentFormatChange()"
                                [(ngModel)]="adjustmentFormat">
                            <option *ngFor="let adjustmentFormatVal of adjustmentFormatOptions" [value]="adjustmentFormatVal.value">
                                {{adjustmentFormatVal.label}}
                            </option>
                        </select>
                    </div>
                    <ng-container *ngIf="isForcedMultiDeposit">
                        <label class="control-label col-lg-2">Pay Deposit out of our Trust Account</label>
                        <div class="col-lg-1">
                            <select class="form-control"
                                    id="payDepositOutOfTrust-MultiDeposit"
                                    name="payDepositOutOfTrust-MultiDeposit"
                                    (change)="onPayDepositOutOfTrustChange()"
                                    [(ngModel)]="payDepositOutOfTrust">
                                <option *ngFor="let item of yesNoNyOptions"
                                        [value]="item.value">
                                    {{item.label}}
                                </option>
                            </select>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="row" *ngIf="isForcedMultiDeposit">
                <div class="form-group adding-bottom-10">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 text-left">
                        <dp-checkbox fieldId="depositOnOccupancyFlag">
                            <input type="checkbox"
                                   id="depositOnOccupancyFlag"
                                   name="depositOnOccupancyFlag"
                                   [(ngModel)]="extraDepositConfig.depositOnOccupancyFlag"
                                   (ngModelChange)="onDepositOnOccupancyFlagChange()"
                            />
                        </dp-checkbox>
                        <label for="depositOnOccupancyFlag" class="control-label padding-left-10 display-inline">Deposit on occupancy</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="depositOnOccupancyAmount" name="depositOnOccupancyAmount"
                               class="form-control text-padding-update"
                               style="text-align:right;"
                               [(ngModel)]="extraDepositConfig.depositOnOccupancyAmount"
                               [dp-read-only]="extraDepositConfig.depositOnOccupancyFlag"
                               (change)="onDepositOnOccupancyFlagChange()"
                               dp-currency [allow-negative]="true"/>
                    </div>

                    <div class="col-lg-6" *ngIf="extraDepositConfig.depositOnOccupancyFlag">
                        <label class="control-label col-lg-4 vertical-align-label">Calculated based on</label>
                        <div class="col-lg-8">
                            <ng-container *ngFor="let depositOnOccupancyCalculatedBasedOnOption of depositOnOccupancyCalculatedBasedOnOptions; let i=index">
                                <span class="radio display-block padding-top-5"
                                      (click)="onDepositOnOccupancyCalBasedOnClick(depositOnOccupancyCalculatedBasedOnOption.value)">
                                <input id="depositOnOccupancyCalBasedOn_{{i}}"
                                       name="depositOnOccupancyCalBasedOn"
                                       type="radio"
                                       [checked]="extraDepositConfig.depositOnOccupancyCalBasedOn == depositOnOccupancyCalculatedBasedOnOption.value"/>
                                <label for="depositOnOccupancyCalBasedOn_{{i}}" dp-radio-label-event-directive>
                                    {{depositOnOccupancyCalculatedBasedOnOption.label}}
                                </label>
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isForcedMultiDeposit && isProjectOccupancyFeeBasedOnDeferredMoney()">
                <div class="form-group padding-bottom-10">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 text-left">
                        <dp-checkbox fieldId="deferredPurchaseMoniesFlag">
                            <input type="checkbox"
                                   id="deferredPurchaseMoniesFlag"
                                   name="deferredPurchaseMoniesFlag"
                                   [(ngModel)]="extraDepositConfig.deferredPurchaseMoniesFlag"
                                   (ngModelChange)="onDeferredPurchaseMoniesFlagChange()"
                            />
                        </dp-checkbox>
                        <label for="deferredPurchaseMoniesFlag" class="control-label padding-left-10 display-inline">Deferred purchase monies</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="deferredPurchaseMoniesAmount"
                               name="deferredPurchaseMoniesAmount"
                               class="form-control text-padding-update"
                               style="text-align:right;"
                               [(ngModel)]="extraDepositConfig.deferredPurchaseMoniesAmount"
                               [dp-read-only]="extraDepositConfig.deferredPurchaseMoniesFlag"
                               (change)="onDeferredPurchaseMoniesFlagChange()"
                               allow-negative="true"
                               dp-currency/>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isForcedMultiDeposit && (isProjectOccupancyFeeBasedOnPhantomMortgage() || matter.isAnyVTBMortgages())">
                <div class="form-group adding-bottom-10">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 text-right">
                        <label class="control-label" for="vtbMortgageMonies">{{vtbMortgageLabel}}</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="vtbMortgageMonies"
                               name="vtbMortgageMonies"
                               class="form-control text-padding-update"
                               style="text-align:right;"
                               [ngModel]="extraDepositConfig.vtbMortgageMonies"
                               readonly="readonly"
                               dp-currency/>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isForcedMultiDeposit">
                <div class="form-group adding-bottom-10">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 text-right">
                        <label class="control-label" for="sumMultipleDeposits">{{sumDepositsLabel}}</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="sumMultipleDeposits"
                               name="sumMultipleDeposits"
                               class="form-control text-padding-update"
                               style="text-align:right;"
                               [ngModel]="propSumOfDepositsAmount"
                               readonly="readonly"
                               dp-currency/>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isForcedMultiDeposit">
                <div class="form-group adding-bottom-10">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 text-right">
                        <label class="control-label" for="calculatedSalePriceTotal">Total</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="calculatedSalePriceTotal"
                               name="calculatedSalePriceTotal"
                               class="form-control text-padding-update"
                               style="text-align:right;"
                               [ngModel]="extraDepositConfig.calculatedSalePriceTotal"
                               readonly="readonly"
                               dp-currency/>
                    </div>
                    <div class="col-lg-6 padding-left-10 padding-top-10">
                        <span class="text-warning" *ngIf="extraDepositConfig.salePriceTotal !=
                        extraDepositConfig.calculatedSalePriceTotal">
                            <i class="fa fa-warning"></i>The Total does not match the Sale Price as per Agreement</span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isForcedMultiDeposit">
                <div class="form-group adding-bottom-10">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-2 text-right">
                        <select class="form-control"
                                id="salePriceOption"
                                name="salePriceOption"
                                (change)="onSalePriceOptionChange()"
                                [(ngModel)]="extraDepositConfig.salePriceTotalOption">
                            <option *ngFor="let opt of salePriceOptions" [value]="opt.value">
                                {{opt.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-2">
                        <input type="text" id="salePriceTotal"
                               name="salePriceTotal"
                               class="form-control text-padding-update"
                               style="text-align:right;"
                               [ngModel]="extraDepositConfig.salePriceTotal"
                               readonly="readonly"
                               dp-currency/>
                    </div>
                </div>
            </div>

            <div *ngIf="multipleDeposit === 'YES'" id="depositsContainer" class="row padding-top-20 padding-bottom-10">
                <div class="row col-lg-12">
                    <div class="col-lg-8">
                    </div>
                    <div class="col-lg-4" *ngIf="isGreenForm4Sent()">
                        <div class="col-lg-2" ></div>
                        <label class="control-label col-lg-5 vertical-align-label form-sent"
                            style="position: relative;left: 14px;width: 157px;margin-bottom: 10px;border: 1px solid #999;text-align: center">
                            Green = Form 4 Sent
                        </label>
                    </div>
                </div>
                <div class="row" *ngFor="let deposit of deposits; let i=index">
                    <div class="form-group">
                        <div class="col-lg-3 padding-left-0 padding-right-0">
                            <label class="control-label col-lg-1 vertical-align-label text-left">{{i + 1}}</label>
                            <div class="col-lg-11 padding-right-0">
                                <dp-partial-date
                                    id="depositDate_{{i+1}}"
                                    #depositDate
                                    [inputDate]="deposit.depositDate"
                                    [fieldKey]="'matter.propertyTeranet.depositDate'"
                                    [containerId]="'depositsContainer'"
                                    [fullDateRequired]="true"
                                    [identifierId]="'depositDate_'+(i+1)"
                                    dp-error-validator
                                    (dateChange)="onDepositDateChange($event, deposit)">
                                </dp-partial-date>
                            </div>
                        </div>
                        <label class="control-label col-lg-1 vertical-align-label">Description</label>
                        <div class="col-lg-4 padding-right-0 margin-right-0">
                            <div class="row padding-0 margin-0">
                                <div class="col-lg-10">
                                    <input #depositName type="text" id="dpName-{{deposit.identifier}}" maxlength="50" name="dpName-{{deposit.identifier}}"
                                           class="form-control" style="text-align: left; padding-right: 20px;"
                                           [dp-read-only]="!deposit.depositDate"
                                           [(ngModel)]="deposit.depositName" (blur)="checkDepositValue(deposit)"
                                    />
                                    <!--<div class="actionbtns">-->
                                        <!--<div class="width-20">-->
                                            <!--<span>-->
                                                <!--<i class="fa fa-trash" (click)="removeDeposit(deposit, i)" aria-hidden="true"></i>-->
                                            <!--</span>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                </div>
                                <div class="col-lg-2">
                                    <span *ngIf="deposit.isNH() || deposit.isNSF()"
                                          class="control-label vertical-align-label"
                                          [ngClass]="{'deposit-status-nh': deposit.isNH(), 'deposit-status-nsf': deposit.isNSF()}">
                                        {{deposit.depositStatus}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label class="control-label col-lg-2 vertical-align-label" [ngClass]="{'deposit-extra': deposit.extras}">
                                {{deposit.extras ? 'Extras' : 'Amount'}}
                            </label>
                            <div class="col-lg-5">
                                <input type="text" id="dpAmount-{{deposit.identifier}}" name="dpAmount-{{deposit.identifier}}"
                                       class="form-control deposit-amount-{{deposit.id}}"
                                       style="text-align:right;" (blur)="onDepositAmtChange()"
                                       [dp-read-only]="checkDepositValue(deposit) || !deposit.depositDate"
                                       [(ngModel)]="deposit.depositAmount"
                                       [ngClass]="{'form-sent': deposit.form4Sent }"
                                       [allow-negative]="false"
                                       dp-currency/>

                            </div>
                            <div class="col-lg-1">
                                <div class="width-30">
                                    <dp-burger-menu [items]="getMultiDepositBurgerMenuItems(i, deposit)"
                                                    [openSubMenuOnLeft]="false"
                                                    [openSubSubMenuOnLeft]="true"
                                                    (itemClicked)="clickMultiDepositBurgerMenu($event, i)">
                                    </dp-burger-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group" *ngIf="deposits.length < maxDeposits">
                    <div class="col-lg-4">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-11">
                            <span class="actionlink">
                                <span>
                                    <a href="javascript:void(0);" (click)="addNewDeposit()" id="addAnotherDeposit" tabindex="0">Add another Deposit</a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <fieldset *ngIf="occupancyDeposit && isCondoProjectInON_AB() && matter.isAdjustmentStatusModeFinal">
                <div class="row">
                    <div class="col">
                        <label class="control-label vertical-align-label">
                            Deposit paid on occupancy (for Final SOA only)
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group adding-bottom-10">
                        <div class="col-lg-3">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-11">
                                <dp-partial-date
                                    id="occupancyDate_deposit_modal"
                                    [disableAll]="true"
                                    [inputDate]="occupancyDeposit.depositDate"
                                    [fieldKey]="'matter.matterOpening.occupancyDate'"
                                    [fullDateRequired]="true"
                                    [identifierId]="'matter.matterOpening.occupancyDate'">
                                </dp-partial-date>
                            </div>
                        </div>
                        <label class="control-label col-lg-1 vertical-align-label">Description</label>
                        <div class="col-lg-4">
                            <input type="text" id="depositPaidOnOccupancyDescription"
                                   maxlength="50" name="depositPaidOnOccupancyDescription"
                                   class="form-control"
                                   [value]="occupancyDeposit.depositName" readonly="readonly"
                            />
                        </div>
                        <div class="col-lg-4">
                            <label class="control-label col-lg-2 vertical-align-label">Amount</label>
                            <div class="col-lg-5">
                                <input type="text" id="depositPaidOnOccupancyAmount" name="depositPaidOnOccupancyAmount"
                                       class="form-control"
                                       style="text-align:right;" readonly="readonly"
                                       [ngModel]="occupancyDeposit.depositAmount"
                                       [ngClass]="{'form-sent': occupancyDeposit.form4Sent }"
                                       allow-negative="true"
                                       dp-currency/>

                            </div>
                            <div class="col-lg-1">
                                <div class="width-30" *ngIf="getOccupancyDepositBurgerMenuItems().length > 0">
                                    <dp-burger-menu [items]="getOccupancyDepositBurgerMenuItems()"
                                                    [openSubMenuOnLeft]="false"
                                                    [openSubSubMenuOnLeft]="true"
                                                    (itemClicked)="clickOccupancyDepositBurgerMenu($event)">
                                    </dp-burger-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>


        <div class="row padding-top-10" style="border-top: 1px solid #cccccc;">
            <div class="row form-group">
                <div class="col-lg-12">
                    <label class="control-label">{{depositLabel}}</label>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-2">
                    <label class="control-label">Total Deposit{{usePluralForDepositLabel() ? 's' : ''}}</label>
                </div>
                <div class="col-lg-1">
                    <label class="control-label padding-left-10">{{(multipleDeposit === 'NO' ? propDepositAmount : getPropDepositTotalAmount()) |
                        currency:'USD':'symbol':'1.2-2'}}</label>
                </div>
            </div>
            <ng-container *dpShowByProvince="'matter.deposit.creditPurchaser'">
                <div class="row form-group">
                    <div class="col-lg-2">
                        <label class="control-label" dp-province-field-label="matter.deposit.creditPurchaser">Credit Purchaser</label>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-1">
                        <label class="control-label padding-left-10">{{(creditPurchaserForDeposit) |
                            currency:'USD':'symbol':'1.2-2'}}</label>
                    </div>
                </div>
            </ng-container>
        </div>


    </div>
    <div class="row modal-footer">
        <div class="col-lg-12">
            <div class="row padding-top-20">
                <div class="col-lg-10 text-center">
                    <button type="button" class="dp-btn keyboard-save-action" (click)="ok()" id="okBtn">
                        <span>OK</span>
                    </button>

                    <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                        <span>Cancel</span>
                    </button>

                    <button type="button" class="secondary-button" (click)="delete()">
                        <span>Reset</span>
                    </button>
                </div>
                <div class="col-lg-2 text-right">
                    <ng-container *ngIf="isDepositInfoOnlyVisible()">
                        <dp-checkbox fieldId="depositModalInfoOnly">
                            <input type="checkbox"
                                   id="depositModalInfoOnly"
                                   name="infoOnly"
                                   [(ngModel)]="infoOnly"
                            />
                        </dp-checkbox>
                        <label for="depositModalInfoOnly" class="control-label padding-left-10 display-inline">Info only</label>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
