import {User} from '../matters/shared/user';
import {Contact} from '../matters/shared/contact';
import {Account} from './accounts/shared/account';
import {AccountComponent} from './accounts/account/account.component';
import {UnSavedChangesComponent} from '../shared-main/unsavedchanges.guard';
import {StaffProfilesPersonalDetailsComponent} from './staff-profiles/personal-details/staff-profiles-personal-details.component';
import {Observable} from 'rxjs';
import {StaffProfiles} from './staff-profiles/staff-profiles';
import * as _ from 'lodash';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {DocumentProfile} from './document-profile/document-profile';
import {AccessProfile} from './staff-profiles/access-profile';
import {AccessRightsProfileEditComponent} from './access-rights-profiles/access-rights-profile-edit.component';
import {DocumentProfileSectionedComponent} from './document-profile/document-profile-sectioned.component';
import {SupplementalTaskCategory} from '../shared-main/supplemental-task-category/supplemental-task-category';
import {DocumentProfileTemplateFolder} from './document-profile/document-profile-edit/template-folder/document-profile-template-folder';
import {ConfigTab} from '../shared/tabbing/config-tab';
import {ChecklistTemplateConfig} from './checklist-templates/checklist-template-config';
import {ChecklistTemplateComponent} from './checklist-templates/checklist-template.component';
import Utils from "../shared-main/utils";

export class AdminTab extends ConfigTab {

  constructor(src?: AdminTab) {
    super(src);

    if (src) {
      for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
          this[ prop ] = src[ prop ];
        }
      }

      src.user && (this.user = new User(src.user));
      src.account && (this.account = new Account(src.account));
      src._staffProfiles && (this._staffProfiles = new StaffProfiles(src._staffProfiles));
      src._staffProfilesRef && (this._staffProfilesRef = new StaffProfiles(src._staffProfilesRef));
    }

  }

  user: User;
  accessProfile: AccessProfile;
  documentProfile: DocumentProfile;
  documentProfileRef: DocumentProfile;
  documentProfileTemplateFolder: DocumentProfileTemplateFolder;
  private _staffProfiles: StaffProfiles;
  private _staffProfilesRef: StaffProfiles;
  dirty: boolean = false;
  accountComponent: AccountComponent;
  isUserAccountReadOnly: boolean = false;
  staffProfileComponent: StaffProfilesPersonalDetailsComponent;
  checklistTemplate: ChecklistTemplateConfig;
  checklistTemplateComponent: ChecklistTemplateComponent;
  accessRightsProfileEditComponent: AccessRightsProfileEditComponent;
  documentProfileComponent: DocumentProfileSectionedComponent;
  component: UnSavedChangesComponent;
  subRoute: string;
  subRouteId: number;
  staffProfileState: string;
  readOnlyStaffProfile: boolean;
  showTitleAccountInfo: boolean = false;
  staffProfileDirty: {
    isPersonalDetailsDirty: boolean;
    isUnityUserDetailsDirty: boolean;
    isTrustAccountsDirty: boolean;
  } = {
    isPersonalDetailsDirty: false,
    isUnityUserDetailsDirty: false,
    isTrustAccountsDirty: false
  };
  isNewPersonalDetailsTab: boolean = false;
  isNewUnityUserDetailsTab: boolean = false;
  isCaslRequestedByDisabled: boolean = true;
  checklistTemplatesSelectedMatterTypeCode: string;

  get staffProfiles(): StaffProfiles {
    return this._staffProfiles;
  }

  set staffProfiles(staffProfiles: StaffProfiles) {
    this._staffProfiles = staffProfiles;
    this._staffProfilesRef = _.cloneDeep(this.staffProfiles);
  }

  get staffProfilesRef(): StaffProfiles {
    return this._staffProfilesRef;
  }

  get contact(): Contact {
    return this.staffProfiles && this.staffProfiles.contact;
  }

  refreshUserRef() {
    this._staffProfilesRef.user = _.cloneDeep(this._staffProfiles.user);
  }

  isStaffProfileDirty(): boolean {
    return this.staffProfiles && (this.staffProfileDirty.isPersonalDetailsDirty || this.staffProfileDirty.isUnityUserDetailsDirty || this.staffProfileDirty.isTrustAccountsDirty);
  }

  public hasDirtyModel(): boolean {
    //TODO: This will be extended to take into account all the possible model elements for different admin tabs
    return (this.account && this.account.isDirty) ||
      this.isStaffProfileDirty() ||
      (this.documentProfile && this.documentProfile.dirty) ||
      (this.supplementalTaskCategory && this.supplementalTaskCategory.isDirty) ||
      (this.checklistTemplate && this.checklistTemplate.isDirty) ||
      (this.accessProfile && this.accessProfile.isDirty);
  }

  delegateSave(): Observable<boolean> | boolean {
    //TODO: this should be handled polymorphically, through different admin tab subclasses
    if (this.accountComponent) {
      return this.accountComponent.save();
    }
    if (this.staffProfileComponent) {
      return this.staffProfileComponent.save();
    }
    if (this.accessRightsProfileEditComponent) {
      return this.accessRightsProfileEditComponent.saveAccessProfile();
    }
    if (this.documentProfileComponent) {
      return this.documentProfileComponent.saveDocProfile();
    }
    if (this.supplementalTaskCategoryComponent) {
      return this.supplementalTaskCategoryComponent.saveChanges();
    }
    if (this.checklistTemplateComponent) {
      return this.checklistTemplateComponent.saveChanges();
    }
  }

  get routeParams(): string[] {
    if (this.subRoute && this.subRouteId) {
      return [ this.route, this.id.toString(), this.subRoute, this.subRouteId.toString() ];
    } else if (this.subRoute) {
      return [ this.route, this.id.toString(), this.subRoute ];
    } else if (this.section) {
      return [ this.route, this.section ];
    } else {
      return [ this.route ];
    }
  }

  static createDocumentProfileTab(documentProfile: DocumentProfile, account: Account): AdminTab {
    let tab: AdminTab = new AdminTab();
    tab.tabType = 'admin';
    tab.account = account;
    tab.type = 'Document Profile';
    tab.subRoute = 'general';
    tab.documentProfile = documentProfile;
    tab.documentProfileRef = _.cloneDeep(documentProfile);
    tab.id = documentProfile.id || -(new Date()).getTime();
    tab.route = 'main/admin/document-profiles';
    return tab;
  }

  createSupplementalTaskCategoryTab(supplementalTaskCategory: SupplementalTaskCategory, account: Account): ConfigTab {
    let tab: AdminTab = new AdminTab();
    tab.tabType = 'admin';
    tab.account = account;
    tab.type = 'Extended Workflows Category';
    tab.supplementalTaskCategory = supplementalTaskCategory;
    tab.id = supplementalTaskCategory.id || -(new Date()).getTime();
    tab.route = 'main/admin/supplemental-task-category';
    return tab;
  }

  createChecklistTemplateConfigTab(checklistTemplateConfig: ChecklistTemplateConfig, account: Account): ConfigTab {
    let tab: AdminTab = new AdminTab();
    tab.tabType = 'admin';
    tab.account = account;
    tab.type = 'Checklist Template Config';
    tab.checklistTemplate = checklistTemplateConfig;
    tab.id = checklistTemplateConfig.id || -(new Date()).getTime();
    tab.route = 'main/admin/checklist-template';
    return tab;
  }

  static createStaffProfileAdminTab(staffProfiles: StaffProfiles, account: Account, type?: string, subRoute?: string, subRouteId?: number): AdminTab {
    let tab: AdminTab = new AdminTab();
    tab.tabType = 'admin';
    tab.id = staffProfiles.id || -(new Date()).getTime();
    tab.account = account;
    tab.staffProfiles = staffProfiles;
    tab.type = type || 'Staff Profile';
    tab.subRoute = subRoute || 'personal-details';
    tab.subRouteId = subRouteId;
    tab.route = 'main/admin/staff-profiles';
    tab.staffProfileState = staffProfiles.user ? 'ALL' : 'New';

    const currentUserId: string = Utils.getAuthenticatedUserId();
    tab.readOnlyStaffProfile = !!(staffProfiles && staffProfiles.user && staffProfiles.user.id === Number(currentUserId));
    tab.isNewPersonalDetailsTab = true;
    tab.isNewUnityUserDetailsTab = true;
    return tab;
  }

  static createAccountAdminTab(account: Account): AdminTab {
    let tab: AdminTab = new AdminTab();
    tab.tabType = 'admin';

    tab.account = account;
    tab.type = 'Account';
    if (account.id) {
      tab.route = 'main/accountadmin/account/' + account.id;
      tab.id = account.id;
      tab.title = account.name;

    } else {
      tab.route = 'main/admin/account/0';
      tab.id = 0;
    }

    return tab;
  }

  static createAccessRightsProfileAdminTab(type: string, id: number, account: Account): AdminTab {
    let tab: AdminTab = new AdminTab();
    tab.tabType = 'admin';
    tab.account = account;
    tab.id = id || -(new Date()).getTime();
    // tab.user = user;
    tab.type = 'Access Rights Profile';
    switch (type) {
      case 'create':
        tab.route = 'main/admin/create-access-rights-profile';
        break;
      case 'view':
        tab.route = 'main/admin/view-access-rights-profile/' + id;
        break;
      default:
      // should throw and exception
    }
    return tab;
  }

  isDirty(): boolean {
    let dirty: boolean = false;
    dirty = this.hasDirtyModel() || this.dirty;
    return dirty;
  }

  get title(): string {
    if (this.accessProfile && this.accessProfile.accessProfileName) {
      return this.accessProfile.accessProfileName;
    } else if (this.contact) {
      return this.contact.surnameLastFullName;
    } else if (this.documentProfile && this.documentProfile.profileName != null) {
      return this.documentProfile.profileName;
    } else if (this.staffProfiles && this.contact) {
      return this.contact.fullName;
    } else if (this.supplementalTaskCategory && this.supplementalTaskCategory.categoryName) {
      return this.supplementalTaskCategory.categoryName.toString();
    } else if (this.checklistTemplate && this.checklistTemplate.name) {
      return this.checklistTemplate.name;
    } else if (this.account && this.account.publicAccountId) {
      return this.account.publicAccountId.toString();
    }
  }

  /**
   * We need the setter so the property is not made readonly
   */
  set title(title: string) {
    //Ignore
  }

  deCoupleUIComponent(): void {
    this.accountComponent = null;
    this.documentProfileComponent = null;
    this.accessRightsProfileEditComponent = null;
    this.staffProfileComponent = null;
    this.component = null;
  }

  isTabOpenFromAccountList(): boolean {
    let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return (this.account && this.account.id != Number(loggedUserId));
  }
}

