import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {User} from '../../matters/shared/user';

export type NoteType = 'CUSTOMER_ACCOUNT' | 'MATTER' | 'CONTACT';

const notesListPropertyIgnoreList = [ 'id' ];

export class NotesList extends BaseEntity {
  id: number;
  noteType: NoteType;
  notes: JournalNote[] = [];

  constructor(notesList?: NotesList) {
    super(notesList);
    if (notesList) {
      this.notes = [];
      if (notesList.notes) {
        for (let ud of notesList.notes) {
          this.notes.push(new JournalNote(ud));
        }
      }
    } else {
      this.notes = [];
    }
  }
}

const propertyIgnoreList = [ 'id', 'notesListId', 'createdByUser' ];

export class JournalNote extends BaseEntity {
  id: number;
  noteBody: string;
  notesListId: number;
  createdByUser: User;
  userCreationTimestamp: number;
  noteHidden: boolean;

  get isNoteHidden(): boolean {
    return this.noteHidden;
  }

  constructor(journalNote?: JournalNote) {
    super(journalNote);
    if (journalNote) {
      this.createdByUser = new User(journalNote.createdByUser);
    } else {
      this.createdByUser = new User();
    }
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }
}
