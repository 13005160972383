import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';

export class SoaRealtyTaxAdjustment extends BaseEntity {
  id: number;
  purchaserPortionOfTotalTaxes: number;
  adjustmentBasedOnPercentage: string;
  previousYearTotalTaxes: number;
  realtyTaxAdjustmentYear: string;
  noOfDaysLeft: string;
  occupancyDate: string;
  creditAmount: number;
}
