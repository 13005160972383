import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {TprTemplatesService} from '../tpr-templates.service';
import {DocumentTemplate} from '../../../matters/document-production/document-template';

export class EditTprTemplateDetailsContext {
  documentTemplate: DocumentTemplate;
  accountId: number;
}

@Component({
  selector: 'dp-edit-tpr-template-details-modal-content',
  templateUrl: 'edit-tpr-template-details.modal.html'
})
export class EditTprTemplateDetailsComponent extends ModalComponent<EditTprTemplateDetailsContext> implements OnInit {
  documentTemplate: DocumentTemplate;

  constructor(
    public dialog: MatDialogRef<EditTprTemplateDetailsComponent, EditTprTemplateDetailsContext>,
    private exportTemplatesService: TprTemplatesService,
    @Inject(MAT_DIALOG_DATA) context?: EditTprTemplateDetailsContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.documentTemplate = new DocumentTemplate(this.context.documentTemplate);
  }

  save() {
    this.exportTemplatesService
      .editReportTemplateFile(this.context.accountId, this.documentTemplate)
      .subscribe((data) => {
        this.context.documentTemplate.description = this.documentTemplate.description;
        this.dialog.close();
      });
  }

  close() {
    this.dialog.close();
  }
}
