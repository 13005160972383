export class MortgageReport {

  id: number;
  generateReportingLetterTo: string;
  mortgageRegistrationDate: string;
  mortgageRegistrationNumber: string;
  rentAssignmentRegistrationDate: string;
  rentAssignmentRegistrationNumber: string;
  includeClause: string;
  mortgagePrepaymentPrivilege: string;
  pageNumber: string;
  additionalClause: string;

  constructor(mortgageReport?: MortgageReport) {
    if (mortgageReport) {
      for (let prop in mortgageReport) {
        if (mortgageReport.hasOwnProperty(prop)) {
          this[ prop ] = mortgageReport[ prop ];
        }
      }
    } else {
      this.includeClause = 'N/y';
    }

  }

}
