<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{this.context.title}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body min-height-360">
        <ng-container *ngIf="lateClosingInterest">
            <div class="form-group col-lg-12">
                <div class="col-lg-2">
                    <label class="control-label">
                        Principal Amount
                    </label>
                </div>
                <div class="col-lg-4">
                    <select class="form-control"
                            id="principalAmountOptions"
                            name="principalAmountOptions"
                            [(ngModel)]="lateClosingInterest.principalAmount">
                        <option *ngFor="let principalAmountOption of principalAmountOptions"
                                [value]="principalAmountOption.value">
                            {{ principalAmountOption.label }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <div class="col-lg-2">
                    <label class="control-label">
                        Interest Rate
                    </label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           *ngIf="lateClosingInterest.sameAs1stMortgage != true"
                           id="interestRate"
                           dp-percentage
                           [max-decimals]=3
                           [max-int]=999
                           padding-zero="true"
                           name="interestRate"
                           [(ngModel)]="lateClosingInterest.interestRate"
                           class="form-control"/>
                    <input type="text"
                           *ngIf="lateClosingInterest.sameAs1stMortgage == true"
                           [value]="getFormattedInterestRate()"
                           readonly="true"
                           class="form-control"/>
                </div>
                <ng-container *dpShowByProvince="'lateClosingInterestModal.sameAs1stMortgage'">
                    <div class="col-lg-2 padding-top-8" *ngIf="allowSameAs1stMortgageOption()">
                        <dp-checkbox fieldId="sameAs1stMortgage">
                            <input type="checkbox" id="sameAs1stMortgage"
                                   name="sameAs1stMortgage" (ngModelChange)="syncInterestRateFrom1stMortgage($event)"
                                   [(ngModel)]="lateClosingInterest.sameAs1stMortgage" />
                        </dp-checkbox>
                        <label class="control-label-ticker">Same as 1st Mortgage</label>
                    </div>
                </ng-container>
            </div>
            <div class="form-group col-lg-12 padding-bottom-20">
                <div class="col-lg-2">
                    <label class="control-label">
                        Effective from
                    </label>
                </div>
                <div class="col-lg-4">
                    <dp-partial-date
                        id="effectiveFrom"
                        [fullDateRequired]="true"
                        [fieldKey]="'modal.lateClosingInterest.fromDate'"
                        dp-error-validator
                        [inputDate]="lateClosingInterest.fromDate"
                        (dateChange)="onDateChangeEffectiveFromDate($event)">
                    </dp-partial-date>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>Ok</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
