import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import * as _ from 'lodash';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/observable/of';
import {Subject} from 'rxjs/Subject';

import '../../common/rxjs-operators';
import {messages} from '../../common';
import {Logger} from '@nsalaun/ng-logger';
import {CustomPickListService} from '../../shared/modal/custom-pick-list.service';
import {PartialDateComponent} from '../../shared/partial-date';
import {
  ContactService,
  currentMatter,
  dropDowns,
  GetGlobalSaveModelService,
  Jurisdiction,
  Matter,
  MatterParticipant,
  MatterUtil,
  MAX_CLIENT_ADDRESSES,
  OMNIBAR_PLACEHOLDERS,
  User,
  Utils
} from '../shared';
import {InsurerModalComponent} from './insurer';
import {InsurerService} from './insurer/insurer.service';
import {Insurer} from './insurer/insurer';
import {SpecialComment} from './';
import {MatterService} from '../matter.service';
import {JurisdictionService} from '../property-teranet/jurisdiction.service';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {Contact} from '../shared/contact';
import {PurchaserService} from '../purchaser/purchaser.service';
import {customPickListKey} from '../../shared/modal/custom-pick-list-key';
import {CustomPickListModalComponent} from '../../shared/modal/custom-pick-list-modal.component';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {StatusBarMessages} from '../../shared-main/status-bar-messages';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ActivatedRoute} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {StatementConfigService} from '../../admin/shared/statement-config.service';
import {SoaTrustLedgerCollection} from '../shared/soa-trustledger-collection';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {noYesOptions, yesNoOptions} from '../mortgages/mortgage/dropdown-options';
import {
  booleanYesNoDropDowns,
  Constants,
  DEFAULT_POSSESSION_TIME,
  DigitalSignaturePlatform,
  PROJECT_SALE_MATTER_TYPE
} from '../../shared-main/constants';
import {ContactQueryService} from '../../contact/contact-query.service';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {UserDefinedFieldService} from '../../shared-main/user-defined-field/user-defined-field-service';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';
import {SupplementalTaskService} from '../../shared-main/supplemental-task-category/supplemental-task-service';
import {SupplementalTaskCategory} from '../../shared-main/supplemental-task-category/supplemental-task-category';
import {MatterSupplementalTaskCategory} from '../shared/matter-supplemental-task-category';
import {MatterSupplementalTaskTemplate} from '../shared/matter-supplemental-task-template';
import {AccessGroup} from '../../admin/access-groups/access-groups';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {RequestResetModalComponent} from './request-reset-modal/request-reset-modal.component';
import {MatterTab} from '../matter-tab';
import {PrintTransactionModalComponent} from '../matter-list/modals/print-transaction-modal.component';
import {AccountService} from '../../admin/accounts/account.service';
import {Account} from '../../admin/accounts/shared/account';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {SoaTrustLedgerHelperService} from '../../shared/soa-trustledger-helper.service';
import {TitleInsuranceConfigurationService} from '../../integrations/title-insurance-configuration.service';
import {TitleInsuranceConfiguration} from '../../integrations/title-insurance-configuration';
import {PurchaserReport} from '../purchaser-report/purchaser-report';
import {Mortgage} from '../shared/mortgage';
import {AccessGroupsService} from '../../admin/access-groups/access-groups.service';
import {DocketService} from '../../admin/docket';
import {StewartTitleService} from '../../shared-main/stewart-title/stewart-title-service';
import {MatterComponent} from '../matter.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {MatterTitleInsurance} from '../shared/matter-title-insurance';
import {Address} from '../shared/address';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {DocumentProductionService} from '../document-production/document-production.service';
import {AppConfig} from '../../shared-main/app-configuration';
import SharedUtils from '../../shared-main/utils';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {
  DelayedInterestCalculationModalComponent
} from './delay-intereset-calculation/delayed-interest-calculation-modal.component';
import {LinkMatterModalComponent} from './link-matter-modal/link-matter-modal.component';
import {TabsComponent} from '../../shared/tabbing/tabs.component';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {actingForDropdownOptions, provinceBasedCashOnClosingDropDown} from '../../shared-main/province-based-dropdowns';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {DocumentProfileLawFirm} from '../../admin/document-profile/document-profile-edit/law-firm';
import {CopyMatterLinkDataService} from '../../matters/copy-matter-link-data.service';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {CustomEventModalComponent} from '../../event/custom-event/custom-event.modal.component';
import {EventData, TypeOfEventStatuseValue} from '../../event/event-data';
import {EventService} from '../../event/event.service';
import {AuthZService} from '../../core/authz/auth-z.service';
import {ModalResult} from '../../shared-main/enums';
import {OpportunitiesService} from '../../opportunities/opportunities.service';
import {MatterParticipantWrapper} from '../shared/matter-participant-wrapper';
import {MatterParticipantService} from '../matter-participant-service';
import {MassUpdateData, MassUpdateTab} from '../../shared/tabbing/mass-update-tab';
import {DPError} from '../../shared/error-handling/dp-error';
import {MatterCleanUpUtil} from '../shared/matter-utils/matter-clean-up-util';
import {AddressTypes} from '../shared/address-types';
import {MatterPollingService} from '../../../app/core/matter-polling.service';
import {UUIDUtil} from '../../main/uuid-util';
import {MatterOpeningUtil} from './matter-opening-util';
import {AutoComplete} from 'primeng/autocomplete';
import {SelectItem} from 'primeng/api';

declare var jQuery: any;

@Component({
  selector: 'dp-matter-opening',
  templateUrl: 'matter-opening.component.html',
  styleUrls: [
    './autocomplete.styles.scss'
  ],
  providers: [ PurchaserService, EmailFieldService ]
})

@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class MatterOpeningComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(InsurerModalComponent) insurerComponent: InsurerModalComponent;
  @ViewChild('autocompleteLawClerk') autocompleteLawClerk;
  @ViewChild('partialFileOpeningDate') partialFileOpeningDateComponent: PartialDateComponent;
  @ViewChild('partialDateOfAgrtOfPS') partialDateOfAgrtOfPSComponent: PartialDateComponent;
  @ViewChild('partialOccupancyDate') partialOccupancyDateComponent: PartialDateComponent;
  @ViewChild('partialRequisitionDate') partialRequisitionDateComponent: PartialDateComponent;
  @ViewChild('partialReleaseDate') partialReleaseDateComponent: PartialDateComponent;
  @ViewChild('partialClosingDate') partialClosingDateComponent: PartialDateComponent;
  @ViewChild('partialPurchaserOnDate') partialPurchaserOnDateComponent: PartialDateComponent;
  @ViewChild('partialInactiveDate') partialInactiveDateComponent: PartialDateComponent;
  @ViewChild('matterNo') matterNo: ElementRef;
  @ViewChild('realEstateBroker') realEstateBroker: AutoComplete;
  @ViewChild('realEstateAgent') realEstateAgent: AutoComplete;
  @ViewChild('lawClerkAutoComplete') lawClerkAutoComplete: AutoComplete;
  @ViewChild('solicitorAutoComplete') solicitorAutoComplete: AutoComplete;
  @Input() showWizardFields: boolean = false;
  @Input() showWizardFieldsReadOnly: boolean = false;
  @Output() updateSolicitorLawclerkInWizard = new EventEmitter<string>();

  protected readonly MatterParticipantRoleTypes = MatterParticipantRoleTypes;

  purchaseSaleAgreementDate: string = 'matter.matterOpening.purchaseSaleAgreementDate';
  fileOpeningDateSamplingMessage: string;
  purchaseSaleAgreementDateSamplingMessage: string;
  occupancyDateSamplingMessage: string;
  requisitionDateSamplingMessage: string;
  matterCloseDateSamplingMessage: string;
  purchaserOnDateSamplingMessage: string;
  fileInactiveDateSamplingMessage: string;
  count: number;
  // matter: any = currentMatter;
  errorMessage: string;
  matterNoFlag: boolean = false;
  // isMatterLocked: boolean = false;

  // DocumentProfile
  documentProfileId: number;
  documentProfileList: DocumentProfile[] = [];

  // Solicitor
  solicitors: any = [];

  // FeeQuote
  feeQuotes: SpecialComment[];
  selectedFeeQuote: SpecialComment;
  feeQuotesLoading = false;
  searchTermStreamFeeQuote: Subject<string> = new Subject<string>();
  feeQuotesSubscription: any;
  // counterFeeQuote: number = 0;

  // Auto complete
  // Jurisdiction
  // list of searched results based on input
  jurisdictions: any;
  jurisdictionsLoading = false;
  searchTermJurisdiction = new Subject<string>();

  // model mapped to input field
  selectedJurisdiction: Jurisdiction;
  // flag to display loading indicator while search is going on

  // flag to display no result found if there is no data for input values

  // flag to control displaying of help text 'start typing...'
  jurisdictionAutoCompleteEmptyFlag: boolean = false;

  // Special Comments
  specialComments: SpecialComment[];
  selectedSpecialComment: SpecialComment;
  specialCommentsLoading = false;
  searchTermStreamSpecialComment = new Subject<string>();
  specialCommentsSubscription: any;
  // counterSpecialComment: number = 0;
  pickListType: string;
  displayPickList: boolean = false;

  // Insurer
  insurers: Insurer[];
  componentInsurers: any;
  insurerDialog: boolean = false;
  insurerValue: any;
  insurerValues: any[];
  selectedInsurerIndex: number;
  selectedInsurerValue: string;
  // selectedInsurerValue: any;
  customInsurers: any[] = [];
  saveSubscription: Subscription;

  utils;
  matterUtils;
  yesNoItems: SelectItem[];
  cashOnClosingDateItems: SelectItem[];
  protocolClosingItems: SelectItem[];
  purchaserFinancingItems: SelectItem[];
  requisitionSubmittedOptions: SelectItem[];
  registrationMethods: SelectItem[];
  yesNoItemsCustom: SelectItem[];
  yesNoMatterFile: SelectItem[];
  actingForOptions: SelectItem[];
  digitalSigningPlatforms: SelectItem[];
  actingForLinkDropDownMenu: BurgerMenuExtendedItem[] = [];
  subscription: any;

  matterNoReadonly: boolean = false;
  matterNoOriginalValue: string;
  //statementConfig : StatementConfig;
  //only apply to MB, SK
  showPossessionTime: boolean = false;

  solicitorListLoaded: boolean = false;
  lawclerkListLoaded: boolean = false;
  eventSubscription: Subscription;

  omnibarPlaceholders: any = OMNIBAR_PLACEHOLDERS;

  //indicate whether in the middle of search default jurisdiction based on current Document profile
  searchDefaultJurisdictionInProgress: boolean = false;

  taxRateSubscription: Subscription;

  solicitorsList: Contact[] = [];
  inactiveSolicitorsList: Contact[] = [];
  clerksList: Contact[] = [];
  inactiveClerksList: Contact[] = [];
  solicitorsDropDownList: any[] = [];
  // Solicitor AutoComplete html use solicitorsSuggestions to avoid the original solicitorsDropDownList is messed up
  solicitorsSuggestions: any[] = [];
  clerksDropDownList: any[] = [];
  otherStaffList: any[];
  // LawClerks AutoComplete html use solicitorsSuggestions to avoid the original clerksDropDownList is messed up
  lawClerksSuggestions: any[] = [];
  witnessDropDownList: any[] = [];
  commissionerDropDownList: any[] = [];
  loggedInUserContact: Contact;
  loggedInUserId: number;

  userAccessGroups: AccessGroup[];
  orderBurgerMenuItems: BurgerMenuExtendedItem[] = [];
  userStaffProfile: StaffProfiles;
  account: Account;

  jurisdictionDepartments: JurisdictionDepartment[] = [];
  matterLinkSubscription: Subscription;
  searchTermRealEstateAgentSubscription: Subscription;
  searchTermJurisdictionSubscription: Subscription;

  contactTerminatedOptions: SelectItem[] = noYesOptions;
  isReleaseDateSameAsOccupancyDateOptions: SelectItem[] = yesNoOptions;
  occupancyCompletedOptions: SelectItem[] = noYesOptions;
  // isOccupancyDateVisible: boolean;
  _matter: Matter;
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  yesNoDefaultNoOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;

  preserveActionTabSection: boolean = false;

  selectedLawClerk: any; //It can be object or string;
  customLawClerkDPErrorKey = 'matter.matterOpening.lawClerk';
  lawClerkLoading = false;
  searchLawClerk = new Subject<string>();
  lawClerkSubscription: any;
  selectedSolicitor: any; //It can be object or string;
  customSolicitorDPErrorKey = 'matter.matterOpening.solicitorParticipant'; //'matter.matterOpening.solicitor" was used. So use 'solicitorParticipant'
  solicitorLoading = false;
  searchSolicitor = new Subject<string>();
  solicitorSubscription: any;

  coreInitializationAttempts: number = 0;
  coreInitializationMaxAttempts: number = 20;
  isApptShutterExpanded: boolean = false;

  constructor(public logger: Logger,
              public contactService: ContactService,
              public appConfig: AppConfig,
              public customPickListService: CustomPickListService,
              public childGetsFromParent: GetGlobalSaveModelService,
              public matterService: MatterService,
              public copyMatterLinkDataService: CopyMatterLinkDataService,
              public insurerService: InsurerService,
              public jurisdictionService: JurisdictionService,
              public docketService: DocketService,
              public contactQueryService: ContactQueryService,
              public purchaserService: PurchaserService,
              public documentProfileService: DocumentProfileService,
              public ngZone: NgZone,
              public dialogService: DialogService,
              public statusBarService: StatusBarService,
              public errorService: ErrorService,
              public route: ActivatedRoute,
              public tabService: TabsService,
              public soaConfigService: StatementConfigService,
              public documentProfileCache: DocumentProfileCache,
              public userDefinedFieldService: UserDefinedFieldService,
              public staffProfilesService: StaffProfilesService,
              public supplementalTaskService: SupplementalTaskService,
              public tabsComponent: TabsComponent,
              public emailFieldService: EmailFieldService,
              public soaTrustLedgerHelperService: SoaTrustLedgerHelperService,
              public titleInsuranceConfigurationService: TitleInsuranceConfigurationService,
              public taxRateService: TaxRateService,
              public accountService: AccountService,
              public accessGroupsService: AccessGroupsService,
              public documentProductionService: DocumentProductionService,
              public stewartTitleService: StewartTitleService,
              public authZService: AuthZService,
              public eventService: EventService,
              public lockScreenService: LockScreenService,
              public tabsService: TabsService,
              public matterParticipantService: MatterParticipantService,
              public elementRef: ElementRef,
              public opportunitiesService: OpportunitiesService, public matterPollingService: MatterPollingService) {
    this.utils = new Utils();
    this.matterUtils = new MatterUtil();
  }

  ngOnInit(): void {
    this.getAccountAndRelatedInformation();
    if (this.matter && this.matter.isTemplateMatterForMassUpdate) {
      this.matter.fileOpenDate = null;
      this.matter.interestRateSummary = null;
    }
    if (!this.preserveActionTabSection && !this.showWizardFields) {
      this.tabService.activeTab.section = this.route.routeConfig.path;
    }

    this.count = 0;

    // dropdown
    this.yesNoMatterFile = dropDowns.yesNoMatterFile;
    this.yesNoItems = dropDowns.NoYes_DefaultNo;

    this.cashOnClosingDateItems = provinceBasedCashOnClosingDropDown[ this.matterProvince ];
    this.purchaserFinancingItems = dropDowns.purchaserFinancing;
    this.protocolClosingItems = dropDowns.protocolClosing;
    this.requisitionSubmittedOptions = noYesOptions;
    this.yesNoItemsCustom = dropDowns.yesNoCustom1;
    this.registrationMethods = dropDowns.registrationMethodsType;

    this.showPossessionTime = SharedUtils.isFullDate(this.matter.matterCloseDate);

    // Insurer
    this.insurers = [];
    this.insurerValues = messages.insurerValues;
    this.insurerService
    .getInsurer(false) //supportFullIntegration
    .subscribe(
      (data: any[]) => {

        this.insurers = data[ 'Organizations' ];
        if (this.matter.insurer && !this.insurers.some(insurer => insurer.organizationName == this.matter.insurer)) {
          //adding manually entered insurer so it's available in the drop down.
          let otherInsurerOrg: Insurer = new Insurer();
          otherInsurerOrg.organizationName = this.matter.insurer;
          this.insurers.unshift(otherInsurerOrg);
        }
      }
    );
    this.accessGroupsService.getAccessGroupsAvailableForUser().subscribe((accessGroups: AccessGroup[]) => {
      this.userAccessGroups = [];
      let defaultAccessGroup = new AccessGroup();
      defaultAccessGroup.accessGroupName = 'Accessible by all users';
      defaultAccessGroup.id = null;
      this.userAccessGroups.push(defaultAccessGroup);
      _.sortBy(accessGroups.filter(ag => ag.accessGroupName != 'Users with unrestricted access' && ag.active),
        ag => ag.accessGroupName.toLocaleLowerCase()).forEach(ag => this.userAccessGroups.push(ag));

    });

    // This is an Observable which will notify the changes in javascript object
    if (!this.subscription) {
      this.subscription = this.childGetsFromParent.getItem$
      .subscribe(() => this.coreInitialization());
    }

    // FeeQuote
    this.searchTermStreamFeeQuote = this.refreshSubject(this.searchTermStreamFeeQuote);
    this.feeQuotesSubscription = this.searchTermStreamFeeQuote
    .switchMap((term: string) => {

      setTimeout(() => {
        this.feeQuotesLoading = true;
      }, 1);
      return this.customPickListService.getPickListAutocomplete(term, customPickListKey.FeeQuotes);

    })
    .subscribe(
      (specialComments: SpecialComment[]) => {

        this.feeQuotesLoading = false;
        if (specialComments.length > 1) {
          this.feeQuotes = specialComments;
        }
        return this.feeQuotes;

      });

    // autocomplete
    // Jurisdictions
    this.searchTermJurisdiction = this.refreshSubject(this.searchTermJurisdiction);
    this.searchTermJurisdictionSubscription = this.searchTermJurisdiction
    .switchMap((term: string) => {
      setTimeout(() => {
        this.jurisdictionsLoading = true;
      }, 1);
      this.jurisdictionAutoCompleteEmptyFlag = false;
      if (term.trim() === '') {
        this.jurisdictionAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;
      } else {
        return this.jurisdictionService.getJurisdictions(term, this.jurisdictionService.page, this.jurisdictionService.limit,
          this.jurisdictionService.sortQuery, this.jurisdictionService.sortingType, null, null, true);
      }
    })
    .subscribe(
      (jurisdictions: any[]) => {
        this.jurisdictionsLoading = false;
        return this.jurisdictions = jurisdictions;
      },
      error => console.log(),
      () => console.log('completed')
    );

    // SpecialComment
    this.searchTermStreamSpecialComment = this.refreshSubject(this.searchTermStreamSpecialComment);
    this.specialCommentsSubscription = this.searchTermStreamSpecialComment
    .switchMap((term: string) => {
      if (term && term.trim()) {
        this.specialCommentsLoading = true;
        return this.customPickListService.getPickListAutocomplete(term, customPickListKey.SpecialComments);
      } else {
        this.specialCommentsLoading = false;
      }
    })
    .subscribe(
      (specialComments: SpecialComment[]) => {
        this.specialCommentsLoading = false;
        return this.specialComments = specialComments;
      },
      err => this.logger.warn(err)
    );

    // Solicitor
    this.searchSolicitor = this.refreshSubject(this.searchSolicitor);
    this.solicitorSubscription = this.searchSolicitor
    .switchMap((term: string) => {
      if (term && term.trim()) {
        this.solicitorLoading = true;
        return this.getSolicitorOrLawClerkPickListAutocomplete(term, true);
      } else {
        this.solicitorLoading = false;
        return Observable.of();
      }
    })
    .subscribe(
      (solicitors) => {
        this.solicitorLoading = false;
        if (Array.isArray(solicitors)) {
          this.solicitorsSuggestions = solicitors;
        } else {
          this.solicitorsSuggestions = [];
        }
      },
      err => this.logger.warn(err)
    );

    // Law Clerk
    this.searchLawClerk = this.refreshSubject(this.searchLawClerk);
    this.lawClerkSubscription = this.searchLawClerk
    .switchMap((term: string) => {
      if (term && term.trim()) {
        this.lawClerkLoading = true;
        return this.getSolicitorOrLawClerkPickListAutocomplete(term, false);
      } else {
        this.lawClerkLoading = false;
        return Observable.of();
      }
    })
    .subscribe(
      (lawClerks) => {
        this.lawClerkLoading = false;
        if (Array.isArray(lawClerks)) {
          this.lawClerksSuggestions = lawClerks;
        } else {
          this.lawClerksSuggestions = [];
        }
      },
      err => this.logger.warn(err)
    );

    this.reloadDocumentProfileList();

    this.initializeStaffProfile();
    this.loadOrderBurgerMenuItems();
    this.loadActingForLinkDropDownMenu();

    this.matter.setDefaultValueForCashOnClosingDateField();

    this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService, false);
    // Move this.openReferralImportModalIfApplicable(); into matter.components.ts

  }

  /**
   * We filter solicitorsDropDownList for Autocomplete html suggestions
   * @param query
   */
  getSolicitorOrLawClerkPickListAutocomplete(query: string, isSolicitor: boolean): Observable<Contact[]> {
    let solicitorsDropDownList: any[] = isSolicitor ? this.solicitorsDropDownList : this.clerksDropDownList;
    return MatterOpeningUtil.getPickListAutocomplete(query, solicitorsDropDownList);
  }

  coreInitialization = () => {
    if (!this.matter.readyForUI && this.coreInitializationAttempts < this.coreInitializationMaxAttempts) {
      //If initialization not done, retry this a bit later
      this.coreInitializationAttempts++;
      setTimeout(this.coreInitialization, 100);
      return;
    }
    // To release any locks after saving a matter when the contact is not dirty
    // because backend releases the lock with contact is dirty and sourceContactLockAcquired is true
    //this.unLockRealEstateAgent();
    //this.matter = matter;
    this.openMatter(this.matter);
    this.actingForOptions = this.matter.isProjectSale && this.matter.isMatterProvinceAB
      ? actingForDropdownOptions[ this.matter.provinceCode ][ PROJECT_SALE_MATTER_TYPE ]
      : actingForDropdownOptions[ this.matter.provinceCode ][ this.matter.matterType ];

    // console.log('Enter childGetsFromParent');
    this.getLawClerkSolicitorList('SOLICITOR');
    this.getLawClerkSolicitorList('LAWCLERK');
    this.getLoggedInUserContact();
    this.initiateEmailFieldService(this.matter);
    this.loadActingForLinkDropDownMenu();

    setTimeout(() => {
      this.setPartialDatesEditMatter();
    }, 100);

  };

//verify whether any side of the linked Matters is Project Sale matter
  isMattersLinkedContainsPS(): boolean {
    return this.matter.isProjectSale || (this.linkedMatter && this.linkedMatter.isProjectSale);
  }

  get activeMatterTab(): MatterTab {
    return this.tabsService && this.tabsService.activeTab as MatterTab;
  }

  refreshSubject(subject: Subject<string>): Subject<string> {
    return Utils.refreshSubject(subject);
  }

  get isOccupancyDateVisible(): boolean {
    return this.matter && this.matter.needOccupancyDate;
  }

  get isOccupancyCompletedVisible(): boolean {
    return this.matter && this.matter.needOccupancyCompleted;
  }

  get isReleaseDateSameAsOccupancyDateVisible(): boolean {
    //Now showIsReleaseDateSameAsOccupancyDate is same as showOccupancyCompleted
    return this.matter && this.matter.needIsReleaseDateSameAsOccupancyDate;
  }

  //curently we assume matter id is not populated or negative value if not persisted
  //we many need to use other client side flag if client side assign matter id
  get isMatterNeverPersisted(): boolean {
    return this.matter && this.matter && (!this.matter.id || this.matter.id < 0);
  }

  resetInsurancePolicy(): void {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.titleInsuranceConfigurationService.getTitleInsuranceConfiguration(id)
    .subscribe((res: TitleInsuranceConfiguration) => {
      if (res.id) {
        let reportToPurchaser;
        if (!this.matter.reportToPurchaser) {
          this.matter.reportToPurchaser = new PurchaserReport();
        }
        reportToPurchaser = this.matter.reportToPurchaser;
        if (res.defaultSettingsInReportToPurchase == 'ENCLOSE_POLICY_WITH_REPORTING_LETTER') {
          reportToPurchaser.titleInsurancePolicy = 'ENCLOSED_REPORT';
        } else {
          reportToPurchaser.titleInsurancePolicy = 'TO_FOLLOW';
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    MatterCleanUpUtil.cleanUpTitleInsurance(this.matter);

    // To release any locks when this component is destroyed
  }

  initiateEmailFieldService(matter: Matter): void {
    this.emailFieldService.matter = matter;
    this.emailFieldService.key = EmailKeys.matterOpening;
  }

  get matterProvince(): ProvinceCode {
    if (this.matter) {
      return this.matter.provinceCode;
    }
    return 'ON';
  }

  getDefaultQuery(type: string): any {
    return {query: type};
  }

  /*
         selectedJurisdiction data should only be pulled from Document Profile in one of the following cases
         1) initialize for new matter (overwrite : no)
         2) document profile field in the matter has value changed (overwrite: yes)
         3) switch back to the unsaved new matter, which may already setup the defaultJurisdictionData (overwrite: no)

         Note: overwrite apply to (jurisdictionId === null || value)
     */
  setDocumentProfileCache(overwrite: boolean = false, updatePaysForDateOfClosing: boolean = false): void {
    this.searchDefaultJurisdictionInProgress = true;
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (this.matter.documentProfileId) {
      this.documentProfileService.getById(this.matter.documentProfileId, accountId, false, this.matter)
      .finally(() => this.searchDefaultJurisdictionInProgress = false)
      .subscribe((documentProfile: DocumentProfile) => {
        if (documentProfile) {
          this.documentProfileCache.cacheDocumentProfile(documentProfile);
          if (updatePaysForDateOfClosing) {
            this.matterService.initPaysForDateOfClosing(this.matter, documentProfile);
          }
          if (this.matter.jurisdictionId === undefined || overwrite == true) {
            this.populateJurisdictionData(accountId, documentProfile);
          }
          if (documentProfile.miscDocumentProfile && this.matter.isMatterProvinceAB && !this.matter.isProjectSale) {
            if (documentProfile.miscDocumentProfile.sameAsDefaultProfileFlag) {
              this.matter.interestRateSummary = this.interestRateSummaryFromCachedDefaultDocumentProfile;
            } else {
              this.matter.interestRateSummary = documentProfile.miscDocumentProfile.interestRateSummary;
            }

          }
          if (overwrite) {
            this.matterService.initSoaHeading(this.matter, documentProfile);
            this.matter.resetStatementOfAdjustmentPayable(documentProfile, this.documentProfileCache && this.documentProfileCache.cachedDefaultDocumentProfile);
          }
          //also need to update the Soa Fee Item Name
          if (this.matter.soaTrustLedgerCollection) {
            this.matter.soaTrustLedgerCollection.documentProfile = documentProfile;
            this.matter.soaTrustLedgerCollection.updateFeeLabelBasedOnDefaultProfile();
            if (this.matter.secondarySoaSheetsCollection) {
              this.matter.secondarySoaSheetsCollection.forEach(collection => {
                collection.updateFeeLabelBasedOnDefaultProfile();
              });
            }
            this.matter.soaTrustLedgerCollection.updateTrustLedgerLegalFeeName();
          }

          if (overwrite && !this.matter.isMatterExisting() && documentProfile.miscDocumentProfile) {

            if (documentProfile.miscDocumentProfile.sameAsDefaultProfileFlag == false) {
              this.matter.uffiWarrantyCode = documentProfile.miscDocumentProfile.uffiWarrantyCode;
            } else {
              this.matter.uffiWarrantyCode = (this.documentProfileCache && this.documentProfileCache.cachedDefaultDocumentProfile) ?
                this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile.uffiWarrantyCode : null;
            }
          }

        }
      });
    }

  }

  get interestRateSummaryFromCachedDefaultDocumentProfile() {
    return this.documentProfileCache && this.documentProfileCache.cachedDefaultDocumentProfile
    && this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile ?
      this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile.interestRateSummary : '';
  }

  populateJurisdictionData(accountId: string, currentDocumentProfile: DocumentProfile): void {

    if (!currentDocumentProfile || !currentDocumentProfile.firmDocumentProfile || currentDocumentProfile.firmDocumentProfile.sameAsDefaultProfileFlag) {
      this.documentProfileCache.getDefault(accountId).subscribe(defaultDocumentProfile => {
        if (defaultDocumentProfile) {
          this.syncSelectedJurisdictionWithDocProfile(defaultDocumentProfile.firmDocumentProfile);
        }
      });
    } else {
      this.syncSelectedJurisdictionWithDocProfile(currentDocumentProfile.firmDocumentProfile);
    }
  }

  syncSelectedJurisdictionWithDocProfile(firmDocProfile: DocumentProfileLawFirm): void {
    if (firmDocProfile && firmDocProfile.jurisdictionId) {
      const jurisdictionNameValue: string = firmDocProfile.jurisdictionName;
      const jurisdictionIdValue: number = firmDocProfile.jurisdictionId;
      this.selectedJurisdiction = new Jurisdiction({
        jurisdictionName: jurisdictionNameValue,
        id: jurisdictionIdValue
      } as Jurisdiction);
      this.matter.purchaserExecDocsAt = jurisdictionNameValue;
      this.matter.jurisdictionId = jurisdictionIdValue;
    }
  }

  // base on the condition of matter it it will give the values to the tab.
  openMatter(matter): void {
    if (this.matter.id || this.matter.isTemplateMatterForMassUpdate) {
      this.setMatterData();
      this.matterNoReadonly = true;
      this.reloadDocumentProfileList();
    } else {
      this.initializeMatter(matter);
      this.setDocumentProfileCache(false);
      this.matterNoReadonly = false;
    }

    // Reffered By
    // this.selectedReferredBy = matter.referredBy;
    if (matter) {
      this.errorService.provinceCode = matter.provinceCode;
      this.errorService.matterType = matter.isProjectSale ? 'z' : matter.matterType;
    }
  }

  initializeStaffProfile() {
    this.staffProfilesService.getLoggedInStaffProfile().subscribe(
      (staffProfiles: StaffProfiles) => {
        if (staffProfiles && staffProfiles.user && staffProfiles.user.documentProfileId) {
          this.userStaffProfile = staffProfiles;
          this.updateUserProvinceForSessionUser(staffProfiles.user);
        }
      },
      err => this.logger.warn(err)
    );
  }

  updateUserProvinceForSessionUser(user: User): void {
    if (user) {
      let sessionUser: User = SharedUtils.getUserFromSession();
      sessionUser.userProvinces = user.userProvinces;
      SharedUtils.saveUserIntoSession(sessionUser);
    }
  }

  initializeMatter(matter): void {

    // this.logger.info('initializeMatter');

    //this.matter = matter;

    //Added for defect DPPMP-10859
    if (!this.userStaffProfile) {
      this.initializeStaffProfile();
    }

    this.restoreComponentDataFromMatterData();

  }

  restoreComponentDataFromMatterData(): void {
    // Get jurisdiction from backend and add it into UI
    if (this.matter.isTemplateMatterForMassUpdate) {
      //for MassUpdate: Template matter, empty the selectedJurisdiction field
      this.selectedJurisdiction = null;
      this.matter.purchaserExecDocsAt = null;
      this.matter.jurisdictionId = null;
    } else {
      this.selectedJurisdiction = this.matter.purchaserExecDocsAt ?
        new Jurisdiction({jurisdictionName: this.matter.purchaserExecDocsAt} as Jurisdiction) : null;
    }

    // Special Comments
    this.selectedSpecialComment = new SpecialComment();

    if (this.matter.specialComments) {
      this.selectedSpecialComment.customPickListItemValue = this.matter.specialComments;
    } else {
      this.selectedSpecialComment = undefined;
    }

    // FeeQuote
    this.selectedFeeQuote = new SpecialComment();

    if (this.matter.feeQuote) {
      this.selectedFeeQuote.customPickListItemValue = this.matter.feeQuote;
    } else {
      this.selectedFeeQuote = undefined;
    }
  }

  setMatterData(): void {

    /*        // Get jurisdiction from backend and add it into UI
                this.selectedJurisdiction = this.matter.purchaserExecDocsAt ?
                                            new Jurisdiction({jurisdictionName : this.matter.purchaserExecDocsAt} as Jurisdiction) : null;

                // Special Comments
                this.selectedSpecialComment = new SpecialComment();

                if(this.matter.specialComments) {
                    this.selectedSpecialComment.customPickListItemValue = this.matter.specialComments;
                } else {
                    this.selectedSpecialComment = undefined;
                }

                // FeeQuote
                this.selectedFeeQuote = new SpecialComment();

                if(this.matter.feeQuote) {
                    this.selectedFeeQuote.customPickListItemValue = this.matter.feeQuote;
                } else {
                    this.selectedFeeQuote = undefined;
                }*/

    this.restoreComponentDataFromMatterData();

    if (!this.matterNoOriginalValue) {
      this.matterNoOriginalValue = this.matter.matterRecordNumber;
    }

    this.showPossessionTime = SharedUtils.isFullDate(this.matter.matterCloseDate);
    this.cashOnClosingDateItems = provinceBasedCashOnClosingDropDown[ this.matterProvince ];
  }

  // After the view is initialized
  ngAfterViewInit(): void {
    // set dates after matter opening view is initialized
    this.setPartialDatesEditMatter();

  }

  // DocumentProfile
  onSelectedDocumentProfileChange(event) {
    let updatePaysForDateOfClosing: boolean = false;
    if (event) {
      if (this.matter.documentProfileId != event) {
        updatePaysForDateOfClosing = true;
      }
      this.documentProfileId = event;
      this.matter.documentProfileId = event;
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      this.setDocumentProfileCache(true, updatePaysForDateOfClosing);
      this.updateMatterSupplementalTaskTemplates();
    }

    //this.reloadDocumentProfileList();
    this.enableSave();
  }

  // autocomplete Jurisdiction
  jurisdictionHelpText(): void {
    if (this.selectedJurisdiction === undefined
      || this.selectedJurisdiction === null
      || this.selectedJurisdiction.toString() === '') {
      this.searchTermJurisdiction.next('');
    }
  }

  // Code for omnibar search jurisdiction
  searchJurisdiction(event): void {
    let entered: string = event.query;
    this.searchTermJurisdiction.next(entered);
  }

  onPurchaserExecutionDocChange(event) {
    //Here handling case if purchaser executing doc is deleted/changed and not selected from auto-suggest.
    //If selected from auto-suggest list then dataSelectedJurisdiction() takes care of it.
    if (typeof event !== 'object') {
      this.matter.purchaserExecDocsAt = null;
      this.matter.jurisdictionId = null;
    }
  }

  // On jurisdiction input data select event
  dataSelectedJurisdiction(): void {
    this.enableSave();
    if (this.selectedJurisdiction.instanceName !== 'Jurisdiction') {
      this.selectedJurisdiction = null;
      this.matter.purchaserExecDocsAt = null;
      this.matter.jurisdictionId = null;
    } else {
      if (this.selectedJurisdiction.id) {
        this.matter.purchaserExecDocsAt = this.selectedJurisdiction.jurisdictionName;
        this.matter.jurisdictionId = this.selectedJurisdiction.id;
      } else {
        this.matter.purchaserExecDocsAt = null;
        this.matter.jurisdictionId = null;
      }
    }
  }

  // SpecialComment and Fee Quote
  searchSpecialComment(event): void {
    let entered: string = event.query;
    this.searchTermStreamSpecialComment.next(entered);
  }

  searchFeeQuote(event): void {
    let entered: string = event.query;
    this.searchTermStreamFeeQuote.next(entered);
  }

  handleF9AccountingNumber() {
    // continuously cycle through defaulting the field with the Matter No. then the File No
    if (this.matter.accountingNumber == this.matter.matterRecordNumber) {
      if (this.matter.fileNumber) {
        this.matter.accountingNumber = this.matter.fileNumber;
      }
    } else {
      this.matter.accountingNumber = this.matter.matterRecordNumber;
    }
  }

  generateF9AccountingNumber(): string {
    // Display different F9 help text for Accounting No. depending on whether Matter No. and File No. are the same or different
    if (this.matter.matterRecordNumber == this.matter.fileNumber || !this.matter.fileNumber) {
      return 'F9 = ' + this.matter.matterRecordNumber;
    } else {
      return 'F9 = Toggle between Matter No. (' + this.matter.matterRecordNumber + ') and File No. (' + this.matter.fileNumber + ')';
    }
  }

  generateF9HelpForFileNo(): string {
    return 'F9 = ' + this.matter.matterRecordNumber;
  }

  handleDropdownClickSpecialComment = (event) => { //use arrow function syntax to preserve 'this' binding
    this.customPickListService.getPickListDropdown(customPickListKey.SpecialComments)
    .subscribe(
      (res: SpecialComment[]) => {
        this.specialComments = res;
      });
    // TODO should use dispose()
    this.specialCommentsSubscription.remove();
  };

  handleDropdownClickFeeQuote = (event) => { //use arrow function syntax to preserve 'this' binding
    this.customPickListService.getPickListDropdown(customPickListKey.FeeQuotes)
    .subscribe(
      (res: SpecialComment[]) => {
        this.feeQuotes = res;
      },
      err => this.logger.warn(err)
    );
    // TODO should use dispose()
    this.feeQuotesSubscription.remove();
  };

  specialCommentHelpText(): void {
    // if (this.counterSpecialComment === 0) {
    if (!this.selectedSpecialComment) {
      this.searchTermStreamSpecialComment.next('');
      // this.counterSpecialComment = this.counterSpecialComment + 1;
    }
    // }
  }

  feeQuoteHelpText(): void {
    // if (this.counterFeeQuote === 0) {
    if (!this.selectedFeeQuote) {
      this.searchTermStreamFeeQuote.next('');
      // this.counterFeeQuote = this.counterFeeQuote + 1;
    }
    // }
  }

  onFeeQuoteChange(event): void {
    if (typeof event === 'object') {
      this.matter.feeQuote = event.customPickListItemValue;
    } else {
      this.matter.feeQuote = event;
    }
  }

  onSpecialCommentChange(event): void {
    if (typeof event === 'object') {
      this.matter.specialComments = event.customPickListItemValue;
    } else {
      this.matter.specialComments = event;
    }
  }

  dataSelectedSpecialComment(): void {
    // this.counterSpecialComment = 0;

    // if id not present and starts with Edit Special.. open PickList dialog
    if (this.selectedSpecialComment.id === undefined) {
      if (this.selectedSpecialComment.customPickListItemValue.indexOf('Edit Special Comments List') > -1) {
        this.pickListType = customPickListKey.SpecialComments;
        //this.displayPickList = true;
        this.openPickListModal();
        // this.selectedSpecialComment = undefined;
      }
    } else {
      // set special comments
      this.matter.specialComments = this.selectedSpecialComment.customPickListItemValue;
      this.enableSave();
    }
  }

  dataSelectedFeeQuote(): void {

    // this.counterFeeQuote = 0;

    if (this.selectedFeeQuote.id === undefined) {
      if (this.selectedFeeQuote.customPickListItemValue.indexOf('Edit Fee Quotes List') > -1) {
        this.pickListType = customPickListKey.FeeQuotes;
        //this.displayPickList = true;
        this.openPickListModal();
        // this.selectedFeeQuote = undefined;
      }
    } else {
      this.matter.feeQuote = this.selectedFeeQuote.customPickListItemValue;
      this.enableSave();
    }
  }

  // method to call when user closes the Custom Pick List dialog
  removePickList(event) {
    //this.displayPickList = false;
    switch (event.type) {
      case customPickListKey.FeeQuotes:
        this.selectedFeeQuote = undefined;
        break;

      case customPickListKey.SpecialComments:
        this.selectedSpecialComment = undefined;
        break;

      default:
        break;
    }
  }

  setPickListItem(comment: SpecialComment): void {
    //this.displayPickList = false;
    switch (this.pickListType) {
      case customPickListKey.FeeQuotes:
        this.feeQuotes.push(comment);
        this.selectedFeeQuote = comment;
        this.matter.feeQuote = comment ? comment.customPickListItemValue : '';
        this.enableSave();
        break;
      case customPickListKey.SpecialComments:
        this.specialComments.push(comment);
        this.selectedSpecialComment = comment;
        this.matter.specialComments = comment ? comment.customPickListItemValue : '';
        this.enableSave();
        break;
      default:
        break;
    }
  }

  /*   setCursorPositionStart(autocomplete) {
     let inputLawClerk = jQuery(this.el.nativeElement).find(`#${autocomplete} span input`)[0];
     let value = inputLawClerk.value;
     let selectionStart = inputLawClerk.selectionStart;
     let selectionEnd = inputLawClerk.selectionEnd;
     if (inputLawClerk.setSelectionRange) {
     // inputLawClerk.setSelectionRange(selectionStart, selectionStart);
     }
     }*/

  setDates(fieldName, date): void {
    if (!date) {
      date = '//';
    }
    let values: string[] = date.split('/');
    if (fieldName) {
      fieldName.dateDay = values[ 2 ];
      fieldName.dateMonth = values[ 1 ];
      fieldName.dateYear = values[ 0 ];
    }
    // fieldName.setValues(values[0], values[1]. values[2]);
  }

  setPartialDatesEditMatter(): void {
    // FIXME: why is this repeated for each field? And why is it in this component,
    // to start with? It should be encapsulated in the date component
    if (this.partialFileOpeningDateComponent) {
      this.partialFileOpeningDateComponent.setDate(this.matter.fileOpenDate);
    }
    if (this.partialDateOfAgrtOfPSComponent) {
      this.partialDateOfAgrtOfPSComponent.setDate(this.matter.purchaseSaleAgreementDate);
    }
    if (this.partialOccupancyDateComponent) {
      this.partialOccupancyDateComponent.setDate(this.matter.occupancyDate);
    }
    if (this.partialRequisitionDateComponent) {
      this.partialRequisitionDateComponent.setDate(this.matter.requisitionDate);
    }
    if (this.partialReleaseDateComponent) {
      this.partialReleaseDateComponent.setDate(this.matter.releaseDate);
    }
    if (this.partialClosingDateComponent) {
      this.partialClosingDateComponent.setDate(this.matter.matterCloseDate);
    }
    if (this.partialPurchaserOnDateComponent) {
      this.partialPurchaserOnDateComponent.setDate(this.matter.purchaserExecDocsDate);
    }
    if (this.partialInactiveDateComponent) {
      this.partialInactiveDateComponent.setDate(this.matter.fileInactiveDate);
    }
  }

  setOnDateUsingCloseDate(date): void {
    let closingDateDay = date.day;
    let closingDateMonth = date.month;
    let closingDateYear = date.year;
    let defaultDay = this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile.closingDateGreaterThan;
    if (!closingDateDay) {
      this.matter.purchaserExecDocsDate = '//';
      return;
    }
    if (defaultDay && Number(closingDateDay) <= Number(defaultDay)) {
      this.matter.purchaserExecDocsDate = closingDateYear + '//';
    } else if (defaultDay && Number(closingDateDay) > Number(defaultDay)) {
      this.matter.purchaserExecDocsDate = closingDateYear + '/' + closingDateMonth + '/';
    }
  }

  // FIXME: these should be encapsulated in the date component
  onDateChangeFileOpeningDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.fileOpenDate) {
      this.matter.fileOpenDate = tmpDate;
      // No Sampling
      // if (event && event.message && event.message.indexOf('Invalid') > -1) {
      this.fileOpeningDateSamplingMessage = event.message;
      // } else {
      //     this.fileOpeningDateSamplingMessage = undefined;
      // }
      this.enableSave();
    }
  }

  // FIXME: all of these should be encapsulated in the date component,
  // not handled in the aggregating component's logic
  onDateChangeDateOfAgreementOfPS(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.purchaseSaleAgreementDate) {
      this.matter.purchaseSaleAgreementDate = tmpDate;
      this.purchaseSaleAgreementDateSamplingMessage = event.message;
      this.enableSave();
    }
  }

  onDateChangeOccupancyDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.occupancyDate) {
      if (this.matter.requisitionDateCalculationConfig && this.matter.requisitionDateCalculationConfig.isAutoPopulateEnabled
        && !this.matter.requisitionDateCalculationConfig.isSourceSelectedAsClosingDate) {
        this.updateRequisitionDate(tmpDate);
      }
      this.matter.occupancyDate = tmpDate;
      this.occupancyDateSamplingMessage = event.message;
      this.matter.updateInterimAndFinalAdjustmentsOnDateChange();
      if (this.matter.isProjectSale && this.matter.isMatterProvinceAB) {
        this.matter.updateDepositOccupancyDate();
      }
      this.enableSave();
      this.matter.checkOutOfRangeWarning(this.errorService);
    }
  }

  onDateChangeRequisitionDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.requisitionDate) {
      this.matter.requisitionDate = tmpDate;
      this.requisitionDateSamplingMessage = event.message;
      this.matter.updateInterimAndFinalAdjustmentsOnDateChange();
      //In project sale matter 'requisition date' is renamed to 'occupancy date' and actual occupancy date is hidden.
      if (this.matter.project) {
        this.matter.updateDepositOccupancyDate();
      }
      this.matter.checkOutOfRangeWarning(this.errorService);
      this.enableSave();
    }
  }

  onDateChangeReleaseDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.releaseDate) {
      this.matter.releaseDate = tmpDate;
      this.enableSave();
    }
  }

  onDateChangeAdjustmentDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.adjustAsAtClosingDate) {
      this.matter.adjustAsAtClosingDate = tmpDate;
      this.matter.checkPropertyStrataMaintenanceFeeOutOfRange(this.errorService);
      this.enableSave();
    }
  }

  onDateChangeClosingDate(event): void {
    this.showPossessionTime = SharedUtils.isFullDate(event.rawDate);
    if (this.showPossessionTime && this.matter.possessionTime == null) {
      this.matter.possessionTime = DEFAULT_POSSESSION_TIME;
    }
    if (event.rawDate !== this.matter.matterCloseDate) {
      if (this.matter.requisitionDateCalculationConfig && this.matter.requisitionDateCalculationConfig.isAutoPopulateEnabled
        && this.matter.requisitionDateCalculationConfig.isSourceSelectedAsClosingDate && !this.matter.isProjectSale) {
        this.updateRequisitionDate(event.rawDate);
      }
      this.copyMatterLinkDataService.onDateChangeClosingDate(event.rawDate, this.matter, this.documentProfileCache.cachedDocumentProfile, this.errorService);
      this.matterCloseDateSamplingMessage = event.message;
      if (this.matter.isProjectSale) {
        this.matter.updateInterimAndFinalAdjustmentsOnDateChange();
      }
      this.enableSave();
    }
    this.matter.checkOutOfRangeWarning(this.errorService);

    if (!this.matter.hasPastClosingDate()) {
      this.matter.closed = DpBooleanValueTypes.N_y;
    }
  }

  updateRequisitionDate(closingDate) {
    if (this.matter.isMatterProvinceONOrNB && !this.matter.requisitionDate && this.matter.isConfigurationSourceDateEmptyOnMatter(closingDate)) {
      this.matter.requisitionDate = this.matter.calculateConfigDate(closingDate);
    }
  }

  toggleMatterCloseDateRemoval() {
    this.toggleRemovalPropagation('matterCloseDate');
    this.matter.matterCloseDate = '';
    this.onDateChangeClosingDate({rawDate: this.matter.matterCloseDate});
  }

  toggleOccupancyDateRemoval() {
    this.toggleRemovalPropagation('occupancyDate');
    this.matter.occupancyDate = '';
    this.onDateChangeOccupancyDate({rawDate: this.matter.occupancyDate});
  }

  toggleRequisitionDateRemoval() {
    this.toggleRemovalPropagation('requisitionDate');
    this.matter.requisitionDate = '';
    this.onDateChangeRequisitionDate({rawDate: this.matter.requisitionDate});
  }

  toggleRemovalPropagation(name: string) {
    let massUpdateData = this.getMassUpdateData();
    if (massUpdateData) {
      massUpdateData.toggleRemovalPropagation(name);
    }
  }

  onDateChangePurchaserOnDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.purchaserExecDocsDate) {
      this.enableSave();
      this.matter.purchaserExecDocsDate = tmpDate;
      // No Sampling
      if (event && event.message && event.message.indexOf('Invalid') > -1) {
        this.purchaserOnDateSamplingMessage = event.message;
      } else {
        this.purchaserOnDateSamplingMessage = undefined;
      }
    }
  }

  onDateChangeInactiveDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.matter.fileInactiveDate) {
      this.enableSave();
      this.matter.fileInactiveDate = tmpDate;
      this.fileInactiveDateSamplingMessage = event.message;
    }
  }

  getDateWithoutDay(event): string {
    let date: string;
    date = `${ ((event && event.year) ? event.year : '') }/${ ((event && event.month) ? event.month : '') }`;
    return date;
  }

  isMatterReadOnly(): boolean {
    return this.tabService.isLinkedMatterDirty() || this.tabService.isMatterProjectLocked(this.matter) || this.authZService.isMatterReadOnlyAccess();
  }

  enableSave(): void {
    this.matter.dirty = !this.isMatterReadOnly();
  }

  disableSave(): void {
    this.matter.dirty = false;
  }

  // Insurer
  selectInsurer(): void {
    this.insurerDialog = true;
    // this.matter.insurer = 'FCT';
    let selectedInsurerIndex = this.findInsurerIndexByValue(this.matter.insurer);
    if (this.matter.isOtherTitleInsurer()) {
      //Obey the old way, just change 3 to 4 because we add TitlePLUS
      selectedInsurerIndex = 4;  //based on current implementation there's no other way to set the index in this case
    }

    let matterTab = this.tabService.activeTab as MatterTab;
    let matterComponent: MatterComponent = null;
    if (matterTab && matterTab.matterComponent) {
      matterComponent = matterTab.matterComponent;
    }

    this.dialogService.matDialogContent({
      content: InsurerModalComponent,
      context: {
        selectedInsurerIndex: selectedInsurerIndex,
        isPropertyCommercial: (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isPurchaseCommercial()),
        isPropertyFarmLand: (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isPurchaseTypeFarmLand()),
        matter: this.matter,
        matterComponent: matterComponent
      },
      onFulfillment: (result) => {
        if (result) {
          const insurerChanged: boolean = (selectedInsurerIndex != parseInt(result.selectedInsurerIndex));
          this.selectedInsurerIndex = parseInt(result.selectedInsurerIndex);
          this.selectedInsurerValue = result.selectedInsurerValue;
          this.componentInsurers = result.insurers;
          this.updateTransTitleInsuredCode();

          this.matter.matterTitleInsurance.clearTitleInsurance = result.clearTitleInsurance;
          if (this.matter && this.matter.isInsurerFCT()) {
            this.matter.matterTitleInsurance.updateMmsDealFromExistingEmpMortgage(this.matter);
          }

          if (result.removeMainTitleInsurer) {
            this.removeMainTitleInsurance();
          } else {
            this.handleOtherTitleInsurance();
            this.enableSave();
          }
          if (insurerChanged) {
            //need to cleanup the propertyType, it may contains the value that no more valid for current TI user
            if (this.matter.matterPropertyWithCondo) {
              this.matter.matterPropertyWithCondo.titleInsurancePropertyType = 'QUESTION';
            }
            this.matter.soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
            if (this.matter.secondarySoaSheetsCollection) {
              this.matter.secondarySoaSheetsCollection.forEach(collection => {
                collection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
              });
            }
            if (this.matter.mortgages && this.matter.mortgages.some(mrg => !!mrg.isBlanketMortgage) && this.matter.isMatterProvinceAB) {
              this.matter.mortgages.filter(mrg => !!mrg.isBlanketMortgage).forEach(mortgage => {
                let blanketMortgageProperties = this.matter.getBlanketMortgageProperties(mortgage.id);
                blanketMortgageProperties.forEach(bmProperty => {
                  bmProperty.titleInsurancePropertyType = undefined;
                  bmProperty.titleInsuranceNumOfUnits = undefined;
                });
              });
            }
          }

          setTimeout(() => {
            this.resetInsurancePolicy();
          }, 0);

        }
      },

    });

    if (this.insurerComponent && this.insurerComponent.selectedInsurerIndex !== null) {
      this.matter.transactionTitleInsuredCode = this.yesNoItemsCustom[ 2 ].value;
    }
  }

  public handleOtherTitleInsurance(): void {

    if (this.matter.matterTitleInsurance && this.selectedInsurerValue === 'Other Title Insurer') {
      this.matter.matterTitleInsurance.initializeOtherTitleInsurance(this.matter);
    }
  }

  onSelectedInsurerChange($event) {
    const matterTitleInsurance = this.matter.matterTitleInsurance;
    const selectedInsurer: Insurer = this.insurers.find(insurer => insurer.organizationName == $event);
    if (matterTitleInsurance && selectedInsurer) {
      matterTitleInsurance.insurerName = selectedInsurer.organizationName;
      if (Array.isArray(selectedInsurer.address) && selectedInsurer.address.length > 0) {
        matterTitleInsurance.insurerAddress.update(selectedInsurer.address[ 0 ]);
      } else {
        matterTitleInsurance.insurerAddress = new Address();
        matterTitleInsurance.insurerAddress.addressTypeCode = AddressTypes.mailing;
      }
      matterTitleInsurance.phone = Utils.getPhoneNumbersBasedOnType(selectedInsurer.telephone, 'WORK', ' or ');
      matterTitleInsurance.fax = Utils.getPhoneNumbersBasedOnType(selectedInsurer.telephone, 'FAX', ' or ');
    } else if (matterTitleInsurance && !selectedInsurer) {
      matterTitleInsurance.insurerName = '';
      matterTitleInsurance.insurerAddress = new Address();
      matterTitleInsurance.insurerAddress.addressTypeCode = AddressTypes.mailing;
      matterTitleInsurance.phone = '';
      matterTitleInsurance.fax = '';
    }
    this.matter.soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
    if (this.matter.secondarySoaSheetsCollection) {
      this.matter.secondarySoaSheetsCollection.forEach(collection => {
        collection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
      });
    }
    this.enableSave();
  }

  findInsurerIndexByValue(value) {
    this.selectedInsurerIndex = this.insurerValues.indexOf(this.findInsurerMessageByValue(value));
    return this.selectedInsurerIndex;
  }

  findInsurerMessageByValue(value) {
    this.insurerValue = this.insurerValues.find(i => i.value === value);
    console.log('findInsurerMessageByValue, value=' + value + '-- return insurerValue:' + this.insurerValue);
    return this.insurerValue;
  }

  setPrivateInsurers(): void {
    this.customInsurers = [];
    // console.log(this.insurerComponent.insurers);
    //Now http://localhost:3000/api/v2/contacts/titleInsurers?supportFullIntegration=false always return 4
    for (let i: number = 0; i < this.componentInsurers.length; i++) {
      if (i >= 4) {
        // ToDo ... where is this.customInsurers used ?!?!
        //this.customInsurers.push(this.componentInsurers.insurers[i]);
        this.customInsurers.push(this.componentInsurers[ i ]);
      }
    }
  }

  updateTransTitleInsuredCode(): void {
    // console.log('updateTransTitleInsuredCode');
    // console.log(event);
    // console.log(this.insurerComponent.selectedInsurerValue);
    this.matter.insurer = this.selectedInsurerValue === 'Other Title Insurer' ? '' : this.selectedInsurerValue;

    if (this.selectedInsurerValue === '' || this.selectedInsurerValue == null) {
      this.selectedInsurerIndex = -1;
    }

    // console.log(this.insurerComponent.selectedInsurerIndex);
    this.setPrivateInsurers();

    // jQuery('input#insurer').focus();
    //Now the items change from 6 to 7
    switch (this.selectedInsurerIndex) {
      case 0:
      case 1:
      case 2:
      case 3:
        this.matter.transactionTitleInsuredCode = this.yesNoItemsCustom[ 2 ].value;
        break;
      case 4:
        //this.matter.value.insurer = '';
        // jQuery('input#insurer').val('');
        // this.getInsurerHelpText();
        this.matter.transactionTitleInsuredCode = this.yesNoItemsCustom[ 3 ].value;
        break;
      case 5:
        this.matter.transactionTitleInsuredCode = this.yesNoItemsCustom[ 4 ].value;
        //this.matter.value.insurer = '';
        break;
      case -1:
      case 6:
      default:
        this.matter.transactionTitleInsuredCode = this.yesNoItemsCustom[ 0 ].value;
        break;
    }
  }

  filterCustomInsurers(): void {
    this.setPrivateInsurers();
  }

  // // disabled Insurer input field
  // customDisable(): boolean {
  //     // console.log('customDisable');
  //     // console.log(this.insurerComponent);
  //     if (typeof this.insurerComponent !== 'undefined') {
  //
  //         // console.log('if');
  //         if (this.matter.insurer !== '') {
  //             this.selectedInsurerIndex = this.findInsurerIndexByValue(this.matter.insurer);
  //         } else {
  //             this.selectedInsurerIndex = 3;
  //         }
  //
  //         if (this.insurerComponent.selectedInsurerValue.trim().length !== 0) {
  //             this.matter.value.insurer = this.insurerComponent.selectedInsurerValue;
  //         }
  //
  //         if (this.selectedInsurerIndex !== null) {
  //             this.selectedInsurerValue = this.insurerComponent.selectedInsurerValue;
  //             return (parseInt(this.selectedInsurerIndex) > 3) ? true : false;
  //         } else {
  //             return true;
  //         }
  //     } else {
  //         // console.log('else');
  //         return false;
  //     }
  // }

  setInsurerField(item): void {
    // this.selectedInsurerValue = item;
    this.matter.insurer = item;
    this.insurerComponent.selectedInsurerValue = item;
  }

  public removeMainTitleInsurance(): void {
    this.matter.matterTitleInsurance = new MatterTitleInsurance();
    //Clear All MissingFieldErrors
    this.errorService.clearAllMissingFieldErrors();
    this.errorService.removeDpThirdPartyNotification('notification.titleInsurance.updateRequired');
    //Clear Third Party Notification for Stewart Title
    this.errorService.removeDpThirdPartyNotification('notification.stewartTitle.status');
    this.errorService.removeDpThirdPartyNotification('notification.stewartTitle.invalid');
    //Clear Third Party Notification for Chicago Title
    this.errorService.removeDpThirdPartyNotification('notification.chicagoTitle.status');
    this.errorService.removeDpThirdPartyNotification('notification.chicagoTitle.invalid');
    //Clear Third Party Notification for FCT
    this.errorService.removeDpThirdPartyNotification('notification.fctTitle.invalid');
    this.errorService.removeDpThirdPartyNotification('notification.fctTitle.status');

    //Remove any title insurance entries from Trust Ledger and Statement of Account
    const soaTrustLedgerCollection: SoaTrustLedgerCollection = this.matter.soaTrustLedgerCollection;
    soaTrustLedgerCollection.removeTitleInsuranceFromTrust();
    soaTrustLedgerCollection.removeTitleInsuranceFromSOA();
    if (this.matter.secondarySoaSheetsCollection) {
      this.matter.secondarySoaSheetsCollection.forEach(collection => {
        collection.removeTitleInsuranceFromTrust();
        collection.removeTitleInsuranceFromSOA();
      });
    }
    this.handleOtherTitleInsurance();
    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }
    this.saveSubscription = this.saveMatter().subscribe();
  }

  public saveMatter(): Observable<boolean> {
    let matterTab = this.tabService.activeTab as MatterTab;
    if (matterTab && matterTab.matterComponent) {

      return matterTab.matterComponent.validateAndSaveMatter().map(
        isMatterValidated => {
          return isMatterValidated;
        }
      );
    } else {
      return Observable.of(false);
    }
  }

  // custom keystroke for matter opening page
  customKeyStruckTextBox(event): void {

    if (event.keyCode === 120) {
      if (event.target.name === 'fileNo') {
        this.matter.fileNumber =
          this.matter.matterRecordNumber;
      } else if (event.target.name === 'accNo') {
        this.matter.accountingNumber =
          this.matter.matterRecordNumber;
      } else if (event.target.name === 'teraview') {

        this.matter.teraviewDocketIdentifier = this.generateTeraviewDocketIdentifierF9(this.matter.matterRecordNumber, this.matter.fileNumber);

      } else if (event.target.name === 'matterNo') {
        this.matterNoReadonly = false;
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {

            // IE hack to use backspace key in field without causing it to trigger back button
            this.matterNo.nativeElement.blur();
            this.matterNo.nativeElement.focus();
          });

        });
      }
    }
  }

  public generateTeraviewDocketIdentifierF9(matterRecordNumber: string, fileNumber: string): string {
    // If MATTER and File number are available and there length is less or equal 12 it will toggle between both values
    if (matterRecordNumber && fileNumber && matterRecordNumber.length <= 10 && fileNumber.length <= 10) {
      return this.matter.teraviewDocketIdentifier == matterRecordNumber ?
        fileNumber : matterRecordNumber;
    } else if (fileNumber && fileNumber.length <= 10) {
      // If MATTER is blank or length is more than 12 and File number is available and fileNumber is less or equal 12 it will only show file number
      return fileNumber;
    } else if (matterRecordNumber && matterRecordNumber.length <= 10) {
      // If File number is blank or length is more than 12 and MATTER is available and fileNumber is less or equal 12 it will only show MATTER
      return matterRecordNumber;
    } else {
      return '';
    }
  }

  handleF9Insurer() {
    this.selectInsurer();
  }

  clearMessages(event) {
    // this.errorMessages = [];
  }

  checkIfJurisdictionEmpty(selectedJurisdiction: string): void {
    if (!selectedJurisdiction || selectedJurisdiction.length == 0) {
      this.matter.purchaserExecDocsAt = null;
    }
  }

  public inActiveDocumentProfileList(documentProfiles: DocumentProfile[], id: number): boolean {
    return !!_.find(documentProfiles, (item: DocumentProfile) => {
      return item.id === id;
    });
  }

  public processDocumentProfileList(data: DocumentProfile[]): void {
    if (data.length === 1) {
      this.documentProfileList = data;
      //this.matter.value.documentProfileId = data[0].id;

      return;
    }

    if (this.matter.documentProfileId == null) {
      this.resetDocumentProfileList(data);
      // this.staffProfilesService.getLoggedInStaffProfile().subscribe(
      //     (staffProfiles : StaffProfiles) => {
      //         this.matter.value.documentProfileId = (staffProfiles && staffProfiles.user) ? staffProfiles.user.documentProfileId : null;
      //     });
      return;
    }
    if (!this.inActiveDocumentProfileList(data, +this.matter.documentProfileId)) {
      let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      this.documentProfileService.getById(this.matter.documentProfileId, id, false, this.matter)
      .subscribe((response: DocumentProfile) => {
        this.resetDocumentProfileList(data, response);
      });
    } else {
      this.resetDocumentProfileList(data);
    }
  };

  public resetDocumentProfileList(data: DocumentProfile[], deletedDocumentProfile: DocumentProfile = null): void {
    if (deletedDocumentProfile !== null) {
      deletedDocumentProfile.profileName += ' (Deleted)';
      deletedDocumentProfile.defaultFlag = deletedDocumentProfile.defaultProfileFlag;
      data.push(deletedDocumentProfile);
    }

    this.documentProfileList = _.filter(data, (item: DocumentProfile) => (!!item.active || (this.matter.documentProfileId && item.id == this.matter.documentProfileId)) &&
      item.defaultFlag === false && item.profileName !== null && item.provinceCode == this.matter.provinceCode
    );
    this.documentProfileList = _.sortBy(this.documentProfileList, item => item.profileName.toLowerCase());
    let defaultDocumentProfile: DocumentProfile = _.find(data, (item: DocumentProfile) =>
      item.defaultFlag === true && item.profileName != null && item.provinceCode == this.matter.provinceCode
    );
    this.documentProfileList.unshift(defaultDocumentProfile);
  }

  public reloadDocumentProfileList(): void {
    // DocumentProfileList
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let data: DocumentProfile[] = this.documentProfileService.getCachedDocumentProfileList(id);
    if (!!data && Array.isArray(data)) {
      this.processDocumentProfileList(data);
    } else {
      this.documentProfileList = [];
    }
  }

  // To be deleted after user story 2022 passes test
  deleteDocumentProfile() {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.documentProfileService.deleteDocumentProfile(this.matter.documentProfileId, id)
    .subscribe((response: any) => {
      this.statusBarService.currentHelpText = StatusBarMessages.documentProfile.DELETE;
    });
  }

  //move this to a date util class
  getTodayDate(): string {
    let today: Date = new Date();

    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    return (dd > 9 ? dd : '0' + dd) + ' / ' + (mm > 9 ? mm : '0' + mm) + ' / ' + today.getFullYear();
  }

  public openPickListModal(): void {
    this.dialogService.matDialogContent({
      content: CustomPickListModalComponent,
      context: {pickListType: this.pickListType},
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'select') {
            this.setPickListItem(result.comment);
          } else {
            this.removePickList(result);
          }
        }
      },
      onRejection: () => {
        this.removePickList({value: true, type: this.pickListType});
      },

    });
  }

  showOccupancyDate(): boolean {
    return MatterCleanUpUtil.isOccupancyDateVisible(this.matter);
  }

  generateF9HelpTeraview(): string {

    let teraviewDocketIdentifier: string;
    if (this.matter.teraviewDocketIdSameAsProjectField) {
      teraviewDocketIdentifier = 'F9 = (' + this.matter.teranetDocket.teraviewDocketIdentifier + ')';
    } else {
      if (this.matter.matterRecordNumber
        && this.matter.matterRecordNumber.length <= 10) {
        if (this.matter.fileNumber) {
          if (this.matter.fileNumber.length > 10) {
            teraviewDocketIdentifier = 'F9 = Matter No. (' + this.matter.matterRecordNumber + ')';
          } else {
            teraviewDocketIdentifier
              = this.matter.matterRecordNumber === this.matter.fileNumber
              ? 'F9 = Matter No. (' + this.matter.matterRecordNumber + ')'
              : 'F9 = Toggle between Matter No. (' + this.matter.matterRecordNumber + ') and '
              + 'File No. (' + this.matter.fileNumber + ')';
          }
        } else {
          teraviewDocketIdentifier = 'F9 = Matter No. (' + this.matter.matterRecordNumber + ')';
        }
      } else {
        teraviewDocketIdentifier = ' ';
      }
    }

    return teraviewDocketIdentifier;
  }

  handleF9Teraview() {
    if (!this.matter.teraviewDocketIdSameAsProjectField) {
      if (!this.matter.teranetDocket.docketSystemId) {
        //DPPMP-7430, If Matter no is greater than 10 characters - then F9 should do nothing and F9 help text is empty
        if (this.matter.matterRecordNumber
          && this.matter.matterRecordNumber.length <= 10) {
          //if File number is empty, or is identical to Matter No, or is greater than 10 characters, set  Teraview Docket ID to the value of Matter No
          if (!this.matter.fileNumber || this.matter.fileNumber.length > 10 || this.matter.fileNumber === this.matter.matterRecordNumber) {
            this.matter.teranetDocket.teraviewDocketIdentifier = this.matter.matterRecordNumber;
          } else {
            // toggle the Teraview Docket ID value between matter No and File No,
            // and if initial DocketID value is not any of the two values, set to value of matter No.
            if (this.matter.teranetDocket.teraviewDocketIdentifier &&
              this.matter.teranetDocket.teraviewDocketIdentifier === this.matter.matterRecordNumber) {
              this.matter.teranetDocket.teraviewDocketIdentifier = this.matter.fileNumber;
            } else {
              this.matter.teranetDocket.teraviewDocketIdentifier = this.matter.matterRecordNumber;
            }
          }
        } else if (this.matter.matterRecordNumber
          && this.matter.matterRecordNumber.length > 10) {
          this.matter.teranetDocket.teraviewDocketIdentifier = this.matter.matterRecordNumber.substr(0, 10);
        }
      }
    }

  }

  get isDocketIdentifierPresent(): boolean {
    if (this.matter && this.matter.teranetDocket && this.matter.teranetDocket.docketSystemId) {
      return true;
    } else {
      return false;
    }
  }

  loadAggregatedSolicitorParticipant(solicitorParticipantId: number) {
    if (Array.isArray(this.solicitorsList)) {
      let contact = this.solicitorsList.find(item => item.id === solicitorParticipantId);
      if (contact) {
        this.updateMatterSolicitorInfo(contact);
      }
    }
  }

  loadAggregatedLawclerkParticipant(lawclerkParticipantId: number) {
    if (Array.isArray(this.clerksList)) {
      this.updateMatterLawClerkInfo(this.clerksList.find(item => item.id === lawclerkParticipantId));
    }
  }

  loadAggregatedWitnessParticipant(witnessParticipantId: number) {
    if (Array.isArray(this.clerksList)) {
      let contact = this.clerksList.find(item => item.id === witnessParticipantId);
      if (contact) {
        this.matter.updateMatterWitnessInfo(contact);
      } else if (Array.isArray(this.solicitorsList)) {
        let contact = this.solicitorsList.find(item => item.id === witnessParticipantId);
        if (contact) {
          this.matter.updateMatterWitnessInfo(contact);
        }
      }
    }
  }

  loadAggregatedCommissionerParticipant(commissionerParticipantId: number) {
    if (Array.isArray(this.clerksList)) {
      let contact = this.clerksList.find(item => item.id === commissionerParticipantId);
      if (contact) {
        this.matter.updateMatterCommissionerInfo(contact);
      }
      if (Array.isArray(this.solicitorsList)) {
        let contact = this.solicitorsList.find(item => item.id === commissionerParticipantId);
        if (contact) {
          this.matter.updateMatterCommissionerInfo(contact);
        }
      }
    }
  }

  //LawClerk and Solicitor drop down implementation 7022
  async getLawClerkSolicitorList(type): Promise<void> {
    let inactiveList = type == 'SOLICITOR' ? this.inactiveSolicitorsList : this.inactiveClerksList;
    let filteredList: Contact[] = await MatterOpeningUtil.getLawClerkSolicitorList(type, this.contactService, inactiveList);

    if (filteredList) {
      if (type === 'SOLICITOR') {
        this.solicitorListLoaded = true;
        let solicitorParticipant: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.SOLICITOR);
        this.solicitorsList = filteredList;
        this.solicitorsDropDownList = MatterOpeningUtil.loadContactDropDownList(filteredList);
        if (this.matter.id) {
          this.loadAggregatedSolicitorParticipant(solicitorParticipant && solicitorParticipant.contactReferenceId);
        } else if (this.solicitorsList && this.solicitorsList.length == 1 && !solicitorParticipant) {
          this.updateMatterSolicitorInfo(this.solicitorsList[ 0 ]);
        } else {
          this.loadAggregatedSolicitorParticipant(solicitorParticipant && solicitorParticipant.contactReferenceId);
        }
        if (!this.matter.id && this.matter.isProjectSale && !this.matter.isTemplateMatterForMassUpdate && this.matter.project) {
          this.onSolicitorChange(this.matter.project.defaultSolicitorId);
        }

        this.updateWitnessAndCommissioners(type);
        this.updateMatterSolicitorError();
      }
      if (type === 'LAWCLERK') {
        this.lawclerkListLoaded = true;
        let lawclerkParticipant: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.LAWCLERK);
        this.clerksList = filteredList;
        this.clerksDropDownList = MatterOpeningUtil.loadContactDropDownList(filteredList);

        if (this.matter.id) {
          this.loadAggregatedLawclerkParticipant(lawclerkParticipant && lawclerkParticipant.contactReferenceId);
        } else if (this.clerksList && this.clerksList.length == 1 && !this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.LAWCLERK)) {
          this.updateMatterLawClerkInfo(this.clerksList[ 0 ]);
        } else {
          this.loadAggregatedLawclerkParticipant(lawclerkParticipant && lawclerkParticipant.contactReferenceId);
        }
        if (!this.matter.id && this.matter.isProjectSale && !this.matter.isTemplateMatterForMassUpdate && this.matter.project) {
          this.onLawClerkNameChange(this.matter.project.defaultLawClerkId);
        }
        this.updateWitnessAndCommissioners(type);
        this.updateMatterLawClerkError();
      }
    }

  }

  updateMatterLawClerkError() {
    let lawClerkParticipant: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.LAWCLERK);
    if (lawClerkParticipant) {
      this.errorService.removeDpFieldError(this.customLawClerkDPErrorKey);
      if (!this.clerksList.find(item => item.id == lawClerkParticipant.contactReferenceId)) {
        let inactiveLawClerkContact = this.inactiveClerksList.find(item => item.id == lawClerkParticipant.contactReferenceId);
        this.selectedLawClerk = inactiveLawClerkContact && inactiveLawClerkContact.getFullNameForContact(false).trim();
        let errorMessage = provinceBasedFieldLabels.provinceBasedLawClerkTitle[ this.matter.provinceCode ] + ' on the matter is inactive';
        this.errorService.addDpFieldError(DPError.createCustomDPError(this.customLawClerkDPErrorKey, errorMessage, 'Matter Opening', 'WARNING'));
      }
    }
  }

  updateMatterLawClerkInfo(contact: Contact) {
    if (contact) {
      this.matter.updateMatterLawClerkInfo(contact);
      //SetTimeout to avoid selectedLawClerk become 'object,object'
      setTimeout(() => {
        this.selectedLawClerk = contact.getFullNameForContact(false).trim();
      }, 10);
    }
  }

  updateMatterSolicitorError() {
    let solicitorParticipant: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.SOLICITOR);
    if (solicitorParticipant) {
      this.errorService.removeDpFieldError(this.customSolicitorDPErrorKey);
      if (!this.solicitorsList.find(item => item.id == solicitorParticipant.contactReferenceId)) {
        let inactiveSolicitorContact = this.inactiveSolicitorsList.find(item => item.id == solicitorParticipant.contactReferenceId);
        this.selectedSolicitor = inactiveSolicitorContact && inactiveSolicitorContact.getFullNameForContact(false).trim();
        this.errorService.addDpFieldError(
          DPError.createCustomDPError(
            this.customSolicitorDPErrorKey,
            provinceBasedFieldLabels.get('matter.opening.solicitor.inactive', this.matter.provinceCode),
            'Matter Opening',
            'WARNING'));
      }
    }
  }

  updateMatterSolicitorInfo(contact: Contact) {
    if (contact) {
      this.matter.updateMatterSolicitorInfo(contact);
      //SetTimeout to avoid selectedSolicitor become 'object,object'
      setTimeout(() => {
        this.selectedSolicitor = contact.getFullNameForContact(false).trim();
      }, 10);
    }
  }

  updateCommissionerDropDownList(type: string) {

    let commissionerParticipant: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.COMMISSIONER);
    this.loadAggregatedCommissionerParticipant(commissionerParticipant && commissionerParticipant.contactReferenceId);

    let filterBy: string = 'value';

    if (this.commissionerDropDownList.length == 0) {
      this.commissionerDropDownList.push({label: '', value: 'N/A'});
    }
    if (type === 'SOLICITOR') {
      this.commissionerDropDownList = _.unionBy(this.commissionerDropDownList,
        this.solicitorsList.map(c => <SelectItem>{
          label: c.contactName.surnameLastFullName,
          value: c.id
        }), filterBy);
    }
    if (type === 'LAWCLERK') {
      this.commissionerDropDownList = _.unionBy(this.commissionerDropDownList,
        this.clerksList
        .filter(c => Array.isArray(c.contactProvinceCapacities) &&
          c.contactProvinceCapacities
          .some(cpc => cpc.provinceCode == this.matter.provinceCode &&
            cpc.provinceCapacity == 'COMMISSIONER' &&
            (SharedUtils.dateGreaterThanCurrentDate(cpc.commissionExpiry) || c.id == this.matter.selectedCommissionerId)))
        .map(c => <SelectItem>{
          label: c.getFullNameForContact(false).trim(),
          value: c.id
        }), filterBy);
    }
    this.commissionerDropDownList = _.sortBy(this.commissionerDropDownList, 'label');

  }

  updateWitnessDropDownList(type: string) {

    let witnessParticipant: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.WITNESS);
    this.loadAggregatedWitnessParticipant(witnessParticipant && witnessParticipant.contactReferenceId);

    let filterBy: string = 'value';
    if (this.witnessDropDownList.length == 0) {
      this.witnessDropDownList.push({label: '', value: 'N/A'});
    }
    if (type === 'SOLICITOR') {
      this.witnessDropDownList = _.unionBy(this.witnessDropDownList,
        this.solicitorsList.map(c => <SelectItem>{
          label: c.contactName.surnameLastFullName,
          value: c.id,
          type: type
        }), filterBy);
    }
    if (type === 'LAWCLERK') {
      this.witnessDropDownList = _.unionBy(this.witnessDropDownList,
        this.clerksList.map(c => <SelectItem>{
          label: c.getFullNameForContact(false).trim(),
          value: c.id,
          type: type
        }), filterBy);
    }

    this.witnessDropDownList = _.sortBy(this.witnessDropDownList, 'label');
  }

  updateWitnessAndCommissioners(type: string) {
    this.updateWitnessDropDownList(type);
    this.updateCommissionerDropDownList(type);
  }

  searchLawClerkInDropDownList(event) {
    let entered: string = event.query;
    this.searchLawClerk.next(entered);
  }

  onLeavingLawClerkOmniBarWithoutFreeInput() {
    setTimeout(() => {
      if (!this.matter.selectedLawClerkId) {
        this.selectedLawClerk = null;
      } else {
        let selectedLawClerk = this.clerksDropDownList && this.clerksDropDownList.find(item => item.value == this.matter.selectedLawClerkId);
        if (selectedLawClerk && selectedLawClerk.label != this.selectedLawClerk) {
          this.selectedLawClerk = selectedLawClerk.label;
        }
      }
    }, 0);
  }

  dataSelectedLawClerk(event) {
    if (event.value !== this.matter.selectedLawClerkId) {
      this.errorService.removeDpFieldError(this.customLawClerkDPErrorKey);
      this.onLawClerkNameChange(event.value);
    } else {
      //SetTimeout to avoid selectedLawClerk become 'object,object'
      setTimeout(() => {
        this.selectedLawClerk = event.label;
      }, 0);

    }
  }

  refreshLawClerkList = () => {
    //If we don't refresh clerksDropDownList, the drop down list won't be showed up
    this.lawClerksSuggestions = this.clerksDropDownList.slice();
  };

  /**
   *  Click Space key, it alway show dropdown list no matter if the ngModel of p-autoComplete has value
   *  Select an item, click enter key p-autoComplete select this item automatically
   *  Use p-autoComplete to simulate a normal selection
   * @param event
   */
  displayLawClerkList(event: any) {
    event.stopImmediatePropagation();
    this.refreshLawClerkList();
    this.lawClerkAutoComplete.show();
  }

  // 7022
  onLawClerkNameChange(event) {

    //console.log("event:"+event);
    MatterCleanUpUtil.cleanUpLawClerk(this.matter);

    if (event !== 'N/A') {
      this.updateMatterLawClerkInfo(this.clerksList.find(clerk => clerk.id == event));
    } else {
      this.selectedLawClerk = '';
      this.matter.selectedLawClerkId = null;
    }

    this.onActingForChange();
    this.matter.isSolicitorOrLawClerkDirty = true;
    this.enableSave();

  }

  searchSolicitorInDropDownList(event): void {
    let entered: string = event.query;
    this.searchSolicitor.next(entered);
  }

  onLeavingSolicitorOmniBarWithoutFreeInput() {
    setTimeout(() => {
      if (!this.matter.selectedSolicitorId) {
        this.selectedSolicitor = null;
      } else {
        let selectedSolicitor = this.solicitorsDropDownList && this.solicitorsDropDownList.find(item => item.value == this.matter.selectedSolicitorId);
        if (selectedSolicitor && selectedSolicitor.label != this.selectedSolicitor) {
          this.selectedSolicitor = selectedSolicitor.label;
        }
      }
    }, 0);
  }

  dataSelectedSolicitor(event) {
    if (event.value !== this.matter.selectedSolicitorId) {
      this.errorService.removeDpFieldError(this.customSolicitorDPErrorKey);
      this.onSolicitorChange(event.value);
    } else {
      //SetTimeout to avoid selectedLawClerk become 'object,object'
      setTimeout(() => {
        this.selectedSolicitor = event.label;
      }, 0);

    }
  }

  refreshSolicitorList = () => {
    //If we don't refresh clerksDropDownList, the drop down list won't be showed up
    this.solicitorsSuggestions = this.solicitorsDropDownList.slice();
  };

  /**
   *  Click Space key, it alway show dropdown list no matter if the ngModel of p-autoComplete has value
   *  Select an item, click enter key p-autoComplete select this item automatically
   *  Use p-autoComplete to simulate a normal selection
   * @param event
   */
  displaySolicitorList(event: any) {
    event.stopImmediatePropagation();
    this.refreshSolicitorList();
    this.solicitorAutoComplete.show();
  }

  // 7022
  onSolicitorChange(event) {
    // console.log("event:"+event);
    MatterCleanUpUtil.cleanUpSolicitor(this.matter);

    if (event !== 'N/A') {
      this.updateMatterSolicitorInfo(this.solicitorsList.find(solicitor => solicitor.id == event));
    } else {
      this.selectedSolicitor = '';
      this.matter.selectedSolicitorId = null;
    }

    this.onActingForChange();
    this.matter.isSolicitorOrLawClerkDirty = true;
    this.enableSave();
  }

  // 7022
  getLoggedInUserContact(): void {
    this.staffProfilesService
    .getCachedLoggedInStaffProfile()
    //.map((profile: StaffProfiles) => profile.contact)
    .subscribe(
      (profile: StaffProfiles) => {
        if (profile.contact) {
          this.loggedInUserContact = new Contact(profile.contact);
          this.loggedInUserId = profile.user && profile.user.id;
        }
      });
  }

  // 7022
  generateF9HelpForLawClerk(): string {
    if (this.loggedInUserContact && this.loggedInUserContact.isLawClerk) {
      return 'F9 = Current User';
    }
    return '';
  }

  // 7022
  generateF9HelpForSolicitor(): string {
    if (this.loggedInUserContact && this.loggedInUserContact.isSolicitor) {
      return 'F9 = Current User';
    }
    return '';
  }

  // 7022
  onLawClerkF9(): void {
    if (this.loggedInUserContact && this.loggedInUserContact.isLawClerk) {
      // console.log("onLawClerkF9 do what ever needed here......");
      MatterCleanUpUtil.cleanUpLawClerk(this.matter);
      this.updateMatterLawClerkInfo(this.loggedInUserContact);
    }
  }

  // 7022
  onSolicitorF9(): void {
    if (!this.containsEmpMortgage() && this.loggedInUserContact && this.loggedInUserContact.isSolicitor) {
      // console.log("onSolicitorF9 do what ever needed here......");
      MatterCleanUpUtil.cleanUpSolicitor(this.matter);
      this.updateMatterSolicitorInfo(this.loggedInUserContact);
    }
  }

  generateF9HelpForInterestRateSummary(): string {
    if (this.loggedInUserContact && this.loggedInUserContact.isSolicitor) {
      return 'F9 = Enter the interest rate to apply on late delivery of funds by purchaser\'s solicitor';
    }
    return '';
  }

  onInterestRateSummaryF9(): void {
    if (this.matter.provinceCode) {
      switch (this.matter.provinceCode) {
        case 'AB':
          this.matter.interestRateSummary = this.documentProfileCache.cachedDocumentProfile && this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile ?
            this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile.sameAsDefaultProfileFlag ? this.interestRateSummaryFromCachedDefaultDocumentProfile :
              this.documentProfileCache.cachedDocumentProfile.miscDocumentProfile.interestRateSummary : '';
          break;
        case 'SK':
          this.matter.interestRateSummary = (this.matter.isMortgage ? '' : 'Bank of Canada Overnight Target Rate + 4%');
          break;
      }
    }

  }

  onWitnessChange(event) {
    MatterCleanUpUtil.cleanUpWitness(this.matter);
    if (event !== 'N/A') {
      let selectedWitness = this.witnessDropDownList.find(witness => witness.value == event);
      this.matter.updateMatterWitnessInfo(selectedWitness && selectedWitness.type && selectedWitness.type === 'SOLICITOR' ? this.solicitorsList.find(solicitor => solicitor.id == event) : this.clerksList.find(solicitor => solicitor.id == event));
    }
    this.enableSave();
  }

  onCommissionerChange(event) {
    MatterCleanUpUtil.cleanUpCommissioner(this.matter);
    if (event !== 'N/A') {
      let solicitorFind: Contact = this.solicitorsList.find(solicitor => solicitor.id == event);
      let lawClerkFind: Contact = this.clerksList.find(clerk => clerk.id == event);
      if (solicitorFind) {
        this.matter.updateMatterCommissionerInfo(solicitorFind);
      } else if (lawClerkFind && !solicitorFind) {
        this.matter.updateMatterCommissionerInfo(lawClerkFind);
      }

    }
    this.enableSave();
  }

  //Copy Matter No. to File No. and Accounting No. if they are blank.
  populateFileAccounting(): void {
    if (this.matter.matterRecordNumber && !this.matterNoReadonly) {
      if (!this.matter.fileNumber) {
        this.matter.fileNumber = this.matter.matterRecordNumber;
      }

      if (!this.matter.accountingNumber) {
        this.matter.accountingNumber = this.matter.matterRecordNumber;
      }
    }
  }

  loadOrderBurgerMenuItems(): void {
    this.addToBurgerMenu(this.orderBurgerMenuItems, 'Produce Debit Notice', this.openPrintDebitNotice);
    if (!this.matter.reset && this.userInitiatedResetsAllowed && !this.matter.trialTransaction && !this.matter.isMigratedMatter) {
      this.addToBurgerMenu(this.orderBurgerMenuItems, 'Reset', this.showResetConfirmation);
    }
  }

  addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], text: string, action?: any): void {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.action = action;
    burgerMenuItems.push(burgerMenuItem);
  }

  clickBurgerMenuItem(clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(this);
    }

  }

  async getUsersLockingDocuments(): Promise<User[]> {
    let documents = await this.documentProductionService.getDocuments(this.matter && this.matter.id).toPromise();
    let users: User[] = [];
    for (let doc of documents) {
      if (doc.lockedByUser) {
        let user = users.find(usr => usr.id == doc.lockedByUser.id);
        if (!user) {
          users.push(doc.lockedByUser);
        }
      }
    }
    return users;
  }

  showDocumentsOpenErrorMessage(users: User[]) {
    if (users && users.length == 1 && users[ 0 ].id == this.loggedInUserId) {
      this.dialogService.confirm('Error', messages.matter.documentsOpen, true).subscribe();
    } else if (users && users.length) {
      let errorMessage = 'Unable to Reset matter because the following users are currently editing some of its documents.<br><br>';
      for (let user of users) {
        errorMessage += user.fullName + '<br>';
      }
      this.dialogService.confirm('Error', errorMessage, true).subscribe();
    }
  }

  async showResetConfirmation(self: MatterOpeningComponent): Promise<void> {
    let usersLockingSomeDocuments = await self.getUsersLockingDocuments();
    if (usersLockingSomeDocuments && usersLockingSomeDocuments.length) {
      self.showDocumentsOpenErrorMessage(usersLockingSomeDocuments);
    } else if (!self.matter.isBilled()) {
      self.dialogService.confirm('Error', messages.matter.resetMatterUnAvailable, true).subscribe();
    } else {
      if (self.matter.dirty) {
        self.dialogService.confirmUnsavedChange(true).subscribe(
          (response: any) => {
            if (response == 'DONT_SAVE') {
              self.openRequestResetModal(self);
            } else if (response == 'CANCEL') {
            } else if (response == 'SAVE') {
              self.saveMatterForReset(false);
            }
          });
      } else {
        self.openRequestResetModal(self);
      }
    }
  }

  openRequestResetModal(self: MatterOpeningComponent): void {
    self.dialogService.matDialogContent({
      content: RequestResetModalComponent,
      context: {
        matter: self.matter
      },

      onFulfillment: (result) => {
        if (result) {
          console.log(result);
          self.matter = result;
          self.saveMatterForReset(true);

        }
      }

    });
  }

  saveMatterForReset(requestRefundFlag: boolean): void {
    let currentTab: MatterTab = this.tabService.activeTab as MatterTab;
    if (requestRefundFlag) {
      currentTab.matterComponent.isMatterRefunded = true;
    }
    currentTab.matterComponent.validateAndSaveMatter().subscribe((res) => {
      if (res && !requestRefundFlag) {
        this.openRequestResetModal(this);
      }
    });
  }

  get userInitiatedResetsAllowed(): boolean {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.userInitiatedResetsAllowed) == 'true';
  }

  get isMatterEditingDisabledAfterPostClosingDate(): boolean {
    return MatterUtil.isMatterEditingDisabledAfterPostClosingDate(this.matter);
  }

  openPrintDebitNotice(self: MatterOpeningComponent) {

    if (!self.matter.isBilled()) {
      self.dialogService.confirm('Error', messages.matter.produceDebitNoticeUnAvailable, true).subscribe();
    } else {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      self.accountService.getShallowAccount(accountId.toString()).subscribe((account: Account) => {
        self.account = new Account(account);
        self.dialogService.matDialogContent({
          content: PrintTransactionModalComponent,
          context: {
            matter: self.matter,
            account: self.account,
            noticeType: 'DEBIT'
          },
          onFulfillment: () => {

          },
          fullScreen: true
        });
      });

    }
  }

  handleF9OrderNumber() {
    this.openPrintDebitNotice(this);
  }

  containsEmpMortgage(): boolean {
    let empMortgages: Mortgage[] = this.matter.empMortgages;
    let solicitorExists;
    if (this.matter.selectedSolicitorId) {
      //Check if the solicitor exists in the dropdown list
      solicitorExists = this.solicitorsDropDownList.find((item) => {
        return item.value == this.matter.selectedSolicitorId;
      });
    }
    return (this.matter.selectedSolicitorId && solicitorExists && empMortgages && empMortgages.length > 0);
  }

  get linkedMatter(): Matter {
    return this.tabService.activeTab && this.tabService.activeTab.isMatter() && (this.tabService.activeTab as MatterTab).linkedMatter;
  }

  async onActingForChange(): Promise<void> {
    this.enableSave();
    await this.copyMatterLinkDataService.addSolicitorLawFirmLawClerk(this.matter, this.linkedMatter);
    this.updateSolicitorLawclerkInWizard.emit(this.matter.actingFor);
    if (this.matter && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
    }
    if (this.matter && this.matter.isMortgage) {
      if (this.isLenderReLineVisible()) { // It is Mortgagee Only or Both(Primary Client is mortgagee) so show “My Client’
        if (!this.matter.getMyClientMortgagee()) { //if there is none of “My Client" radio button selected
          MatterUtil.setFirstMortgageeMyClientFlag(this.matter);
        }
        if (!this.matter.lenderReLine) {
          this.matter.reCalculateMatterLenderReLine();
        }
      } else {
        //If I change the We Are Acting For to a choice other than Mortgagee Only or Both(Primary Client is mortgagee),
        // then “My Client" values are removed when I go to another topic or save the matter
        MatterUtil.resetAllMortgageesMyClientFlag(this.matter);
      }
    }
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  updateMatterSupplementalTaskTemplates(): void {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (this.matter.supplementalTasks && this.matter.supplementalTasks.length > 0) {
      this.matterService.getMatterDocumentProfile(this.matter).subscribe(matterDocumentProfile => {
        this.supplementalTaskService.getSupplementalTaskCategories(accountId, this.matter.provinceCode)
        .subscribe(
          (defaultCategories: SupplementalTaskCategory[]) => {
            this.matter.supplementalTasks.forEach((matterSupTask: MatterSupplementalTaskCategory) => {
              let defaultCategory: SupplementalTaskCategory = defaultCategories.find(value => value.id == matterSupTask.sourceSupplementalTaskCategoryId);
              if (defaultCategory && defaultCategory.categoryTemplates) {
                matterSupTask.categoryTemplates = [];

                defaultCategory.categoryTemplates.filter(categoryTemplate => categoryTemplate.templateFolderId == matterDocumentProfile.accountFileFolder.id).forEach(categoryTemplate => {
                  matterSupTask.categoryTemplates.push(MatterSupplementalTaskTemplate.createFromDefaultCategoryTemplate(categoryTemplate));
                });
              }
            });
          }
        );
      });
    }
  }

  doMatterSaveAndOpenMatterNoModal(): void {
    if (this.canChangeMatterNo()) {
      this.matterService.callOperationAfterMatterSaved(this.matter, () => {
        let matterTab = this.tabsService.activeTab as MatterTab;
        MatterOpeningUtil.openMatterNumberModal(this.matter, matterTab, this.documentProductionService, this.dialogService);
      });
    }
  }

  canChangeMatterNo(): boolean {
    let message = '';
    if (this.matter.isAnyMortgageEMP) {
      message += 'The matter contains Electronic Mortgage Processing information - the Matter No cannot be changed.<br><br>';
    }
    if (this.matter.isMainTitleInsurer()
      && this.matter.matterTitleInsurance
      && this.matter.matterTitleInsurance.dealId
      && !this.matter.matterTitleInsurance.isOrderStatusCancelled()) {
      message += 'Title Insurance has been initiated for the matter.  The Title Insurance order must be cancelled before the Matter No can be changed.<br><br>';
    }
    if (this.matter.isAnyEregCreatedOrUploaded()) {
      message += 'The Matter No cannot be changed when an E-Reg Form has been submitted/uploaded.<br><br>';
    }
    if (message) {
      this.dialogService.confirm('Error', message, true);
    }
    return !message;
  }

  openClosingDateEdit(): void {
    this.dialogService.matDialogContent({
      content: DelayedInterestCalculationModalComponent,
      context: {
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result != ModalResult.Cancel) {
          this.matter.addOrRemoveLateClosingInterest();
          this.matter.updateInterimOccupancyFeeAdjOnChange();
          this.enableSave();
        }
      },
      onRejection: (reject: any) => {

      },

    });
  }

  allowOpenClosingDateEdit(): boolean {
    return this.matter.isLateClosingAllowed();
  }

  editLateClosingInterest(): void {
    if (this.allowOpenClosingDateEdit()) {
      this.openClosingDateEdit();
    }
  }

  onCashClosingOptionChange(): void {
    this.matter.onCashClosingChange();
    this.enableSave();
  }

  loadActingForLinkDropDownMenu(): void {
    this.actingForLinkDropDownMenu = [];
    this.addToBurgerMenu(this.actingForLinkDropDownMenu, `Link to existing ${ this.matter.isPurchase ? 'Sale' : 'Purchase' } matter`, () => {
      this.openLinkMatterModal(false);
    });
    this.addToBurgerMenu(this.actingForLinkDropDownMenu, `Link to new ${ this.matter.isPurchase ? 'Sale' : 'Purchase' } matter`, () => {
      this.openLinkMatterModal(true);
    });
  }

  openLinkMatterModal = (linkToNewMatter) => {
    if (!this.validateVTBMortgages(linkToNewMatter)) {
      return;
    }

    this.matterService.callOperationAfterMatterSaved(this.matter, () => {
      this.dialogService.matDialogContent({
        content: LinkMatterModalComponent,
        context: {
          matter: this.matter,
          isNewMatter: linkToNewMatter,
          copyMatterLinkDataService: this.copyMatterLinkDataService
        },
        modalGrid: 6,
        onFulfillment: (response) => {
          if (response && response.sourceMatter && response.linkedMatter) {
            this.createLinkingOnCurrentMatter(response.sourceMatter, response.linkedMatter, linkToNewMatter);
          }
        }
      });
    });
  };

  validateVTBMortgages(linkToNewMatter: boolean): boolean {
    if (this.matter.isAnyVTBMortgages()) {
      if (linkToNewMatter) {
        if (this.matter.isProjectSale) {
          this.dialogService.confirm('Error', 'Cannot link this project sale matter until all purchaser mortgages have been removed.', true).subscribe();
        } else {
          this.dialogService.confirm('Error', 'Can\'t create and link to new matter:  VTB mortgages present on current matter.  Remove any VTB mortgages before linking', true).subscribe();
        }
      } else {
        this.dialogService.confirm('Error', 'Can\'t link to existing matter:  VTB mortgages present on matters.  Remove any VTB mortgages on both matters before linking.', true).subscribe();
      }
      return false;
    } else {
      return true;
    }
  }

  createLinkingOnCurrentMatter(sourceMatter: Matter, linkedMatter: Matter, linkToNewMatter: boolean): void {
    if (this.tabService.activeTab && this.tabService.activeTab.isMatter()) {
      let matterTab = this.tabService.activeTab as MatterTab;
      matterTab.linkedMatter = linkedMatter;
      matterTab.matter = sourceMatter;
      matterTab.backEndMatter = sourceMatter;
      this.openTabForLinkedMatter();  // Open Tab for new Linked Matter
    }
  }

  removeLinkedMatter(): void {
    let type: string = this.matter.isPurchase ? 'sale' : 'purchase';
    let message: string = 'Do you wish to remove the link to the ' + type + ' matter? <br> If you proceed, the purchase and the sale matter will be both' +
      ' saved with any unsaved modification and the link reference will be removed from both matters.';
    this.dialogService.confirm('Warning', message, false, 'Remove').subscribe(res => {
      if (res && res == true) {
        let linkedMatterTab: MatterTab = this.tabService.getTab(this.matter.matterLink.linkedMatterId, 'matter') as MatterTab;
        let activeTab: MatterTab = this.tabService.activeTab as MatterTab;
        if (this.saveSubscription) {
          this.saveSubscription.unsubscribe();
        }
        this.matter.matterLink = undefined;
        //when linked, the delayedAmountSameAsTabG may come from linked Purchase matter, after unlink, Sale matter's delayedAmountSameAsTabG should be always false;
        if (this.matter.isSale && this.matter.closingDatePayment) {
          this.matter.closingDatePayment.delayedAmountSameAsTabG = false;
        }
        this.saveSubscription = this.saveMatter().subscribe(isMatterSaved => {
          if (isMatterSaved) {

            // Update if there is LinkedMatter Open
            if (linkedMatterTab) {
              linkedMatterTab.matter.matterLink = undefined;
              linkedMatterTab.linkedMatter = undefined;
            } else {
              // if linked matter not opened then unlock it
              this.matterService.unlockMatter(activeTab.linkedMatter.id);
            }

            activeTab.linkedMatter = undefined;

          }
        });

      }
    });
  }

  openTabForLinkedMatter(): void {
    MatterOpeningUtil.openTabForLinkedMatter(this.matter, this.tabService, this.tabsComponent);
  }

  handleF9OnPossessionTime() {
    this.matter.possessionTime = DEFAULT_POSSESSION_TIME;
  }

  getOnClosingDateFieldLabel(): string {
    const part1 = provinceBasedFieldLabels.get('matter.matterOpening.onClosingDate', this.matter.provinceCode);
    const part2 = this.matter.isSale ? 'get' : 'pay';
    return `${ part1 }, ${ part2 }`;
  }

  get isReleaseDateVisible(): boolean {
    //in Template matter, the 'Enter the Key Release Date' field will show as long as:  isReleaseDateSameAsOccupancyDate field value is DpBooleanValueTypes.NO, and isReleaseDateSameAsOccupancyDate field should show all the time
    return (this.isReleaseDateSameAsOccupancyDateVisible || this.matter && this.matter.isTemplateMatterForMassUpdate) && (this.matter && this.matter.isReleaseDateSameAsOccupancyDate == DpBooleanValueTypes.NO);
  }

  get isRequisitionSubmittedVisible(): boolean {
    return this.matter && ((!this.matter.isMatterProvinceON && !this.matter.isMatterProvinceNB && !this.matter.isMatterProvinceNS) || !this.matter.isRequisitionDateEmpty);
  }

  addOrUpdateEvents(appointment: EventData): void {
    if (this.matter.isMatterExisting()) {
      if (this.matter && this.matter.appointments && this.matter.appointments.length) {
        this.matter.appointments.forEach((eventData) => {
          eventData.matterRecordNumber = this.matter.matterRecordNumber;
        });

      }
      this.openCustomEventModal(appointment);
    } else {
      this.matterService.callOperationAfterMatterSaved(this.matter, () => {
        this.openCustomEventModal(appointment);
      });
    }

  }

  openCustomEventModal(appointment: EventData): void {
    if (this.matter.dirty) {
      this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
        if (res && res == true) {
          const matterTab = this.tabService.activeTab as MatterTab;
          if (matterTab && matterTab.matterComponent) {
            const subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
              if (result) {
                // for the case when another user updated the same appointment while having this component open
                // use the latest one retrieved from backend on the last save
                if (appointment) {
                  const updatedAppointment = this.matter.matterEvents.find(appntmn => appntmn.id == appointment.id && appntmn.version > appointment.version);
                  if (updatedAppointment) {
                    this.openCustomEventModalAfterSave(updatedAppointment);
                  } else {
                    this.openCustomEventModalAfterSave(appointment);
                  }
                } else {
                  this.openCustomEventModalAfterSave(null);
                }
                subscription.unsubscribe();
              }
            });
          }
        } else {
          console.log('Matter not saved');
        }
      });
    } else {
      this.openCustomEventModalAfterSave(appointment);
    }
  }

  async openCustomEventModalAfterSave(appointment: EventData): Promise<void> {
    if (!this.otherStaffList) {
      let otherStaffList: Contact[] = await this.contactService.getSolicitorLawClerkList('OTHER', false).toPromise();
      this.otherStaffList = MatterOpeningUtil.loadContactDropDownList(otherStaffList);
    }
    let scheduledForList = _.uniqBy(this.solicitorsDropDownList.concat(this.clerksDropDownList).concat(this.otherStaffList), 'value');
    scheduledForList = _.sortBy(scheduledForList, item => item.label && item.label.toLowerCase());
    this.dialogService.matDialogContent({
      content: CustomEventModalComponent,
      context: {
        matter: this.matter,
        eventData: appointment,
        scheduledForList: scheduledForList
      },
      fullScreen: true,
      onFulfillment: (result) => {
        if (result.event) {
          if (appointment) { // We have to check for the appointment before looking for it's index
            let index = this.matter.matterEvents.findIndex(event => event.id == appointment.id);
            if (index > -1) {
              if (result.action && result.action == 'DELETE') {
                this.matter.matterEvents.splice(index, 1);
              } else {
                this.matter.matterEvents[ index ] = result.event;
              }
            }
          } else {
            if (result.action != 'DELETE') {
              //A user can create and delete an appointments without closing the modal
              //So before pushing the event to the list we make sure that event wasn't deleted
              this.matter.matterEvents.push(result.event);
            }
          }
        }
        let appointmentScheduledFlag: string = this.matter.appointmentScheduledFlag;
        if (this.matter && this.matter.appointments && this.matter.appointments.length) {
          if (this.matter.appointments.some(event => event.eventStatus == TypeOfEventStatuseValue.UNSPECIFIED || event.eventStatus == TypeOfEventStatuseValue.PERFORMED)) {
            this.matter.appointmentScheduledFlag = DpBooleanValueTypes.YES;
          } else {
            if (this.matter.appointments.every(event => event.eventStatus == TypeOfEventStatuseValue.CANCELLED || event.eventStatus == TypeOfEventStatuseValue.POSTPONED)) {
              this.matter.appointmentScheduledFlag = DpBooleanValueTypes.NO;
            } else {
              this.matter.appointmentScheduledFlag = DpBooleanValueTypes.N_y;
            }
          }
          // Only when appointmentScheduledFlag is changed, we enable save button.
          if (appointmentScheduledFlag != this.matter.appointmentScheduledFlag) {
            this.enableSave();
          }
        }
        this.matterPollingService.performDocuSignPolling(this.matter.appointments, (this.tabsService.activeTab as MatterTab));
      }
    });
  }

  get sortedAppointments(): EventData[] {
    if (this.matter && this.matter.appointments && this.matter.appointments.length) {
      return _.sortBy(this.matter.appointments, 'startDate');
    } else {
      return [];
    }
  }

  get matterEventTitle(): string {
    return this.matter && this.matter.appointments && this.matter.appointments.length ? 'Add/Edit Appointments' : 'Add Appointments';
  }

  canCreateAppointment(): boolean {
    return this.matter && this.matter.matterRecordNumber && this.solicitorListLoaded && this.lawclerkListLoaded;
  }

  registrationMethodChanges(): void {
    this.cleaupMatterProperty();
    this.enableSave();
  }

  cleaupMatterProperty(): void {
    if (this.matter.isProjectSale &&
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.condominiumExpenses
      && this.matter.matterPropertyWithCondo.condominiumExpenses.length) {
      this.matter.matterPropertyWithCondo.condominiumExpenses.forEach((condominiumExpense) => {
        condominiumExpense.overrideDescInd = false;
        condominiumExpense.teraviewLegalDescOverriddenType = null;
        condominiumExpense.teraviewLegalDesc = '';
      });

    }
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  get matter(): Matter {
    return this._matter ? this._matter : currentMatter.value;
  }

  set matter(value: Matter) {
    this._matter ? (this._matter = value) : (currentMatter.value = value);
  }

  changeOutOfProvince(): void {
    this.enableSave();
    if (this.matter && this.matter.outOfProvincePurchaserExecDocsAt) {
      this.selectedJurisdiction = null;
      this.matter.purchaserExecDocsAt = null;
      this.matter.jurisdictionId = null;
    }
  }

  get canBeConveyCaTransaction(): boolean {
    return (this.matter && this.matter.isPurchase && this.matter.isMatterProvinceON && this.matter.purchaseIsOfANewHomeFromABuilder);
  }

  async openConveyCa(): Promise<void> {
    let conveyCaUrl: string = await this.matterService.conveyCaUrl().toPromise();

    let form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = conveyCaUrl;
    form.style.display = 'none';
    form.name = 'convey_ca_form';

    let nameInput = document.createElement('input');
    nameInput.type = 'hidden';
    nameInput.name = 'name';
    nameInput.value = this.matter.conveyCaLogin;
    form.appendChild(nameInput);

    let pwdInput = document.createElement('input');
    pwdInput.type = 'hidden';
    pwdInput.name = 'password';
    pwdInput.value = this.matter.conveyCaPassword;
    form.appendChild(pwdInput);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  get massUpdateTab(): MassUpdateTab {
    return this.tabService && this.tabService.activeTab && (this.tabService.activeTab as MassUpdateTab);
  }

  getMassUpdateData(): MassUpdateData {
    if (this.massUpdateTab) {
      if (!this.massUpdateTab.massUpdateData) {
        this.massUpdateTab.massUpdateData = new MassUpdateData();
      }
      return this.massUpdateTab.massUpdateData;
    } else {
      return null;
    }
  }

  isMatterProvinceON_SK(): boolean {
    return this.matter && (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceON);
  }

  get closingDateLabelText(): string {
    return (this.matter && (this.matter.isCustomMatter()
      || this.matter.isMatterTypeDischarge
      || this.matter.isWillMatter()
      || (this.matter.isMatterProvinceBC && (this.matter.isSale || this.matter.isMatterProvinceBC)))) ? 'Completion Date' : 'Closing Date';
  }

  get possessionDateLabelText(): string {
    return (this.matter && (this.matter.isCustomMatter() || this.matter.isMatterTypeDischarge)) ? 'Completion Date' : 'Possession Date';
  }

  get executingDoctsAtText(): string {
    return this.matter.isMortgageBC
      ? 'Borrower executing doc\'ts at'
      : `${ this.matter.mainClientTitle } executing doc'ts at`;
  }

  isLinkBtnAvailable(): boolean {
    return !this.showWizardFields && (this.matter.isProjectSale ? (this.matter.isActingForBothPurchaseAndSaleMatters || this.matter.isActingForPurchaserVendorAndMortgageeMatters) : this.matter.isActingForBothPurchaseAndSaleMatters)
      && (this.appConfig.matterLinkingEnabledProvinces.indexOf(this.matter.provinceCode) >= 0);
  }

  isActingForSectionAvailable(): boolean {
    if (this.matter.isWillMatter()) {
      return false;
    }
    let result: boolean = !this.matter.isMatterProvinceON;
    if (!result) {
      result = this.matter.isMortgage ? true : this.appConfig.matterLinkingEnabledProvinces.indexOf(this.matter.provinceCode) >= 0;
    }
    return result;
  }

  isLinkedMatterProjectSale(): boolean {
    return this.matter && this.matter.matterLink && this.activeMatterTab && this.activeMatterTab.isMatter() && this.activeMatterTab.isLinkedMatterProjectSale();

  }

  isWizardMatterReadOnly(): boolean {
    return this.showWizardFields && this.showWizardFieldsReadOnly;
  }

  // getSelectedLawClerkLabel(): string {
  //         if(this.matter.selectedLawClerkId && !this.clerksDropDownList.find(item=>item.id === this.matter.selectedLawClerkId)) {
  //             let lawClerk: MatterParticipant = this.matter.getMatterParticipantByRole(MatterParticipantRoleTypes.LAWCLERK);
  //             return lawClerk && lawClerk.contact ? lawClerk.contact.getFullNameForContact(false) : '';
  //         } else {
  //             return '';
  //         }
  //
  // }

  getClosedLabel(): string {
    return this.matter && (this.matter.isCustomMatter() || this.matter.isWillMatter()) ? 'Completed ?' : 'Closed ?';
  }

  documentsBeSignedDigitally(): boolean {
    return this.matter && this.matter.isDocumentToBeSignedRemotely() && this.account && this.account.isDigitalSigningEnabled();
  }

  isLockboxCodeApplicable(): boolean {
    return this.matter && this.matter.isMatterProvinceON &&
      !this.matter.isMortgage && !this.matter.isCustomMatter();
  }

  isApptShutterVisible(): boolean {
    return this.matter && this.matter.appointments && this.matter.appointments.length > 1;
  }

  isApptScheduleFlagVisible(): boolean {
    return this.isApptShutterVisible() ? this.isApptShutterExpanded : true;
  }

  isAppointmentListVisible(): boolean {
    return this.isApptShutterVisible() ? this.isApptShutterExpanded : this.matter && this.matter.appointments && this.matter.appointments.length == 1;
  }

  toggleApptShutter(): void {
    this.isApptShutterExpanded = !this.isApptShutterExpanded;
  }

  getAppointmentsSummaryText(): string {

    let appointmentsSymmaryText = this.matter.appointmentScheduledFlag == DpBooleanValueTypes.YES ? 'Appt Scheduled' : 'No Appt. Scheduled';
    if (this.matter && this.matter.appointments && this.matter.appointments.length) {
      appointmentsSymmaryText += ` - ${ this.matter.appointments.length } Appointments`;
    }
    return appointmentsSymmaryText;
  }

  getAccountAndRelatedInformation(): void {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.accountService.getCachedShallowAccount(id).subscribe(account => {
      this.account = account;
      this.digitalSigningPlatforms = dropDowns.digitalSigningPlatformOptions.map(item => item);
      if (this.account && !this.account.isDocuSignAccessAvailable()) {
        this.digitalSigningPlatforms = this.digitalSigningPlatforms.slice(0).filter(ds => ds.value != DigitalSignaturePlatform.DOCU_SIGN);
      }
      if (this.account && !this.account.isSyngrafiiAccessAvailable()) {
        this.digitalSigningPlatforms = this.digitalSigningPlatforms.slice(0).filter(ds => ds.value != DigitalSignaturePlatform.SYNGRAFII);
      }
    });
  }

  isDigitalPlatformVisible(): boolean {
    return this.matter && this.matter.isDocumentToBeSignedDigitally() && this.account && this.account.isDigitalSigningAccessAvailable();
  }

  openReferredByModal(): void {
    MatterOpeningUtil.openReferredByModal(this.matter, this.dialogService)
    .subscribe((result) => {
      if (result) {
        this.enableSave();
      }
    });
  }

  get referredBy(): string {
    return MatterOpeningUtil.getReferredBy(this.matter);
  }

  /*
    * All the following conditions have to be satisfied
    * 'Closed?' = 'Yes'
    * 'Will Purchaser be residing at Subject property?' (for P matter) OR 'Does Vendor currently reside at the subject property?' (for S matter) = Y/n or Yes
    * At least one client populated on Tab B
    * Post-closing address and primary mailing address of all or some of the clients are different
    * 'Clients to receive individual letters' unchecked
    * */
  isUpdateClientAddressesVisible(): boolean {
    return this.matter && this.matter.isClosed() &&
      (this.matter.isPurchase || this.matter.isSale) &&
      this.matter.matterContactInfo && this.matter.matterContactInfo.isResideAtSubjectProperty &&
      this.getPostClosingAddress() && this.getPostClosingAddress().addressLine1 &&
      this.matter.mainClients && this.matter.mainClients.length &&
      (this.matter.mainClients.length == 1 || (this.matter.mainClients.length > 1 && !this.matter.receiveCustomerIndividualLetterFlag)) &&
      this.matter.mainClients.some(client => client.contact && client.contact.primaryAddress && !this.isAddressesEqual(client.contact.primaryAddress, this.getPostClosingAddress()));
  }

  getPostClosingAddress(): Address {
    if (this.matter && this.matter.matterContactInfo && this.matter.matterContactInfo.postClosingAddress) {
      if (this.matter.matterContactInfo.postClosingAddress.isSameAsPreClosingAddress()) {
        return this.matter.matterContactInfo.preClosingAddress;
      }
      if (this.matter.matterContactInfo.postClosingAddress.isSameAsSubjectProperty() && this.matter.matterProperties && this.matter.matterProperties.length) {
        return this.matter.matterPropertyWithCondo.address;
      }
      return this.matter.matterContactInfo.postClosingAddress;
    } else {
      return null;
    }
  }

  updateClientsAddressesIfApplicable(): void {
    if (this.isUpdateClientAddressesVisible()) {
      this.openUpdateClientAddressDialog();
    }
    this.enableSave();
  }

  updateMatterReadyFlag(): void {
    MatterUtil.updateMatterReadyFlag(this.matter);
    if (this.matter && this.matter.isFileInactive()) {
      this.matter.sendInternalNotifications = 'NO';
      this.matter.sendExternalNotifications = 'NO';
    }
  }

  isAddressesEqual(address1: Address, address2: Address): boolean {
    return address1.addressTextWithProvinceName == address2.addressTextWithProvinceName;
  }

  /**
   *  1. If all three addresses are populated and there is no 'Mailing Address' in the existing three addresses then do not update /replace any of the address for that client
   *  2. If all three addresses are populated and there is a 'Mailing Address' then replace that address with the post-closing address and make it 'primary'
   *  3. If there are less than three addresses without mailing address then add the post-closing address as mailing address and make the newly added address 'Primary'
   *  4. the post-closing address will be updated as 'Primary' address in the client contact record.
   *     Earlier 'Primary' address will be tagged as 'Other - Previous address' which was implemented in DPPMP-42689
   *
   */
  async openUpdateClientAddressDialog(): Promise<void> {
    let message: string;
    let mainClients: MatterParticipant[] = this.matter.mainClients;
    let participantsWithAllPopulatedAddressExcludeMailing: MatterParticipant[] = [];
    let participantsNeedChangeContactAddress: MatterParticipant[] = [];
    // Build 2 arrays.
    // one is if all three addresses are populated and there is no 'Mailing Address' in the existing three addresses then do not update /replace any of the address for that client
    // the other is the array which we need to change contact address
    mainClients.forEach(mp => {
      if (mp.contact) {
        if (this.isPopulatedAllAddressesExcludeMailing(mp)) {
          participantsWithAllPopulatedAddressExcludeMailing.push(mp);
        } else if (this.isPopulatedAllAddressesIncludeOneMailing(mp)
          || this.isPopulatedLessThan3Addresses(mp)
          || (mp.contact.primaryAddress && !this.isAddressesEqual(mp.contact.primaryAddress, this.getPostClosingAddress()))) {
          participantsNeedChangeContactAddress.push(mp);
        }
      }
    });

    message = this.buildUpdateMPAddressMessage(participantsNeedChangeContactAddress);
    let noUpdateMessage = this.buildNoUpdateMPAddressMessage(participantsWithAllPopulatedAddressExcludeMailing);
    if (noUpdateMessage) {
      message = noUpdateMessage + '<br><br>' + message;
    }
    this.dialogService.confirm('Update Client Address', message, false, 'Ok',
      'Cancel', true)
    .subscribe((res) => {
      if (res) {
        this.prepareToChangeAddress(participantsNeedChangeContactAddress);
      }
    });
  }

  buildUpdateMPAddressMessage(matterParticipantsToChangeAddress: MatterParticipant[]): string {
    let message = '';
    if (matterParticipantsToChangeAddress.length) {
      if (matterParticipantsToChangeAddress.length == this.matter.mainClients.length) {
        message = 'The primary address for all client contact records can be updated to the following: <br><br>';
      } else {
        message = 'For ';
        matterParticipantsToChangeAddress.forEach((mp, index) => {
          if (index) {
            if (index == matterParticipantsToChangeAddress.length - 1) {
              message += ' and ';
            } else {
              message += ', ';
            }
          }
          message += mp.contact.displayName;
        });
        message += ' the following address can be updated as primary contact address<br><br>';
      }
      message += this.getPostClosingAddress().addressTextWithoutCountry + '<br><br>';
      message += 'Click \'OK\' to update the contacts or click \'Cancel\' to leave the contact addresses unchanged.';
    }
    return message;
  }

  buildNoUpdateMPAddressMessage(participantsWithAllPopulatedAddressExcludeMailing: MatterParticipant[]): string {
    let plusS: string = participantsWithAllPopulatedAddressExcludeMailing.length > 1 ? 's ' : ' ';
    let message = '';
    if (participantsWithAllPopulatedAddressExcludeMailing.length > 0) {
      //Message Text - "For client(s) <clientX First Last Name> and <clientY First Last Name> address was not updated as there are no address section(s) available"
      message = 'For client' + plusS;
      participantsWithAllPopulatedAddressExcludeMailing.forEach((mp, index) => {
        if (index) {
          if (index == participantsWithAllPopulatedAddressExcludeMailing.length - 1) {
            message += ' and ';
          } else {
            message += ', ';
          }
        }
        message += mp.contact.surnameLastFullNameOrBusinessName;
      });
      message += ' address was not updated as there are no address section' + plusS + 'available ';
    }
    return message;
  }

  async prepareToChangeAddress(matterParticipantsWithDifferentAddress: MatterParticipant[]) {
    let matterParticipantsToChangeAddress: MatterParticipantWrapper[] = [];
    let staleOrLockedMatterParticipants: MatterParticipant[] = [];

    for (let matterParticipant of matterParticipantsWithDifferentAddress) {
      let sourceContact: Contact;
      if (matterParticipant.contact.sourceContactId) {
        sourceContact = await this.contactQueryService.getContactForMatter(Number(matterParticipant.contact.sourceContactId)).toPromise();
      }
      if (sourceContact) {
        if (matterParticipant.contact.isStaleContact(sourceContact) || sourceContact.lockedByUser) {
          staleOrLockedMatterParticipants.push(matterParticipant);
        } else {
          let participantWrapper: MatterParticipantWrapper = this.createParticipantWrapperFromMatterParticipant(matterParticipant, sourceContact);
          matterParticipantsToChangeAddress.push(participantWrapper);
        }
      } else {
        let participantWrapper: MatterParticipantWrapper = this.createParticipantWrapperFromMatterParticipant(matterParticipant);
        matterParticipantsToChangeAddress.push(participantWrapper);
      }
    }
    if (matterParticipantsToChangeAddress.length) {
      this.changeClientsAddresses(matterParticipantsToChangeAddress);
      this.enableSave();
    }
    if (staleOrLockedMatterParticipants.length) {
      this.showFailToUpdateAddressMessage(staleOrLockedMatterParticipants);
    }
  }

  showFailToUpdateAddressMessage(staleOrLockedMatterParticipants: MatterParticipant[]): void {
    if (staleOrLockedMatterParticipants.length) {
      let message = '';
      if (staleOrLockedMatterParticipants.length == 1) {
        message = `Contact record of ${ staleOrLockedMatterParticipants[ 0 ].contact.displayName } is not updated as the record is either out of sync or is locked.`;
      } else {
        message = 'Contact records of ';
        staleOrLockedMatterParticipants.forEach((mp, index) => {
          if (index) {
            if (index == staleOrLockedMatterParticipants.length - 1) {
              message += ' and ';
            } else {
              message += ', ';
            }
          }
          message += mp.contact.displayName;
        });
        message += ' are not updated as the records are either out of sync or locked.';
      }
      this.dialogService.confirm('WARNING', message, true);
    }
  }

  createParticipantWrapperFromMatterParticipant(matterParticipant: MatterParticipant, sourceContact?: Contact): MatterParticipantWrapper {
    let participantWrapper = new MatterParticipantWrapper();
    participantWrapper.matterParticipant = matterParticipant;
    if (sourceContact) {
      if (!participantWrapper.matterParticipant.sourceContact) {
        this.matterParticipantService.updateParticipantWrapperState(participantWrapper, sourceContact);
      }
    }
    return participantWrapper;
  }

  async changeClientsAddresses(matterParticipantWrappers: MatterParticipantWrapper[]): Promise<void> {
    for (let participantWrapper of matterParticipantWrappers) {
      if (participantWrapper.matterParticipant.contact && participantWrapper.matterParticipant.contact.sourceContactId) {
        await this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, participantWrapper);
        if (participantWrapper.matterParticipant.sourceContactLockAcquired) {
          this.changeContactAddress(participantWrapper.matterParticipant);
        }
      } else {
        this.changeContactAddress(participantWrapper.matterParticipant);
      }
    }
  }

  /**
   *  1. If all three addresses are populated and there is a 'Mailing Address' then replace that address with the post-closing address and make it 'primary'
   *  2. If there are less than three addresses without mailing address then add the post-closing address as mailing address and make the newly added address 'Primary'
   *  3. the post-closing address will be updated as 'Primary' address in the client contact record. Earlier 'Primary' address will be tagged as 'Other - Previous address'
   * @param mp
   */
  changeContactAddress(mp: MatterParticipant): void {
    if (this.isPopulatedAllAddressesIncludeOneMailing(mp)) {
      this.updateContactExistingMailingAddress(mp);
    } else if (this.isPopulatedLessThan3Addresses(mp)) {
      this.addContactMailingAddress(mp);
    } else {
      this.changeContactAddressByDefault(mp);
    }
  }

  /*
    * The post-closing address will be updated as 'Primary' address in the client contact record.
    * Earlier 'Primary' address will be tagged as 'Other - Previous address'
    * */
  changeContactAddressByDefault(mp: MatterParticipant): void {
    let previousPrimaryAddress = mp.contact.primaryAddress;

    let primaryAddressIndex = mp.contact.address.findIndex(ad => ad.primaryAddress);
    let newPrimaryAddress = new Address(this.getPostClosingAddress());
    newPrimaryAddress.id = UUIDUtil.getUUID();
    newPrimaryAddress.primaryAddress = true;
    newPrimaryAddress.addressTypeCode = previousPrimaryAddress.addressTypeCode;

    //If otherPreviousAddress found, remove it
    let otherPreviousAddress = mp.contact.otherPreviousAddress;
    if (otherPreviousAddress) {
      (<any>mp.contact.address).remove(otherPreviousAddress);
    }
    previousPrimaryAddress.addressTypeCode = AddressTypes.otherPreviousAddress;
    previousPrimaryAddress.primaryAddress = false;
    previousPrimaryAddress.setAddressHash();
    //Place newPrimaryAddress at the same index of the ealier primary address and shift the others down
    mp.contact.address.splice(primaryAddressIndex, 0, newPrimaryAddress);
    this.removeExtraNonPrimaryAddress(mp);
    mp.contact.isDirty = true;
    this.enableSave();

  }

  /**
   *  If all three addresses are populated and there is a 'Mailing Address'
   *  then replace that address with the post-closing address and make it 'primary'
   * @param mp
   */
  updateContactExistingMailingAddress(mp: MatterParticipant): void {
    if (mp && mp.contact && mp.contact.address) {
      let previousMailingAddressIndex = mp.contact.address.findIndex(ad => ad.addressTypeCode == AddressTypes.mailing);
      if (previousMailingAddressIndex > -1) {
        let previousMailingAddress = mp.contact.address[ previousMailingAddressIndex ];
        let newPrimaryAddress = new Address(this.getPostClosingAddress());
        newPrimaryAddress.id = UUIDUtil.getUUID();
        newPrimaryAddress.primaryAddress = true;
        newPrimaryAddress.addressTypeCode = previousMailingAddress.addressTypeCode;
        newPrimaryAddress.setAddressHash();
        mp.contact.address.forEach(item => item.primaryAddress = false);
        //Replace newPrimaryAddress at the same index of the existing mailing address and shift the old one down
        mp.contact.address.splice(previousMailingAddressIndex, 1, newPrimaryAddress);
        mp.contact.isDirty = true;
        this.enableSave();
      }
    }

  }

  /**
   *  If there are less than three addresses then add the post-closing address as mailing address and make the newly added address 'Primary'
   * @param mp
   */
  addContactMailingAddress(mp: MatterParticipant): void {
    if (mp && mp.contact) {
      if (!Array.isArray(mp.contact.address)) {
        mp.contact.address = [];
      }
      let mailingAddress: Address = _.find(mp.contact.address, (address: Address) => {
        return address.addressTypeCode === AddressTypes.mailing;
      });
      if (mailingAddress) {
        mailingAddress.addressTypeCode = AddressTypes.previousMailingAddress;
        mailingAddress.setAddressHash();
      }
      let newPrimaryAddress = new Address(this.getPostClosingAddress());
      newPrimaryAddress.id = UUIDUtil.getUUID();
      newPrimaryAddress.primaryAddress = true;
      newPrimaryAddress.addressTypeCode = AddressTypes.mailing;
      newPrimaryAddress.setAddressHash();
      mp.contact.address.forEach(item => item.primaryAddress = false);

      //Filter to get client Addresses
      let addressList: Address[] = mp.contact.address.filter(item => item.addressTypeCode != AddressTypes.clientIdBusiness
        && item.addressTypeCode != AddressTypes.serviceAddress
        && item.addressTypeCode != AddressTypes.reportAddress);
      //Check If there are less than three addresses
      if (addressList.length < MAX_CLIENT_ADDRESSES) {
        mp.contact.address.push(newPrimaryAddress);
      }
      //Find the this first empty address to replace it
      let emptyAddressIndex: number = addressList.findIndex(item => !this.isAddressesPopulated(item));
      if (emptyAddressIndex > -1) {
        //Replace newPrimaryAddress at the same index of the existing mailing address and shift the old one down
        mp.contact.address.splice(emptyAddressIndex, 1, newPrimaryAddress);
      }
      mp.contact.isDirty = true;
      this.enableSave();
    }

  }

  /*
    * Per requirements:
    * If a contact record already has 3 addresses and a new primary address is to be added, the last non-primary address will be removed
    * */
  removeExtraNonPrimaryAddress(mp: MatterParticipant): void {
    if (mp.contact && mp.contact.profileAddresses && mp.contact.profileAddresses.length > 3) {
      let lastProfileAddress = mp.contact.profileAddresses[ mp.contact.profileAddresses.length - 1 ];
      if (lastProfileAddress.isOtherPreviousAddress()) {
        let OtherPreviousAddressIndex = mp.contact.address.findIndex(address => address.isOtherPreviousAddress());
        lastProfileAddress = mp.contact.profileAddresses[ OtherPreviousAddressIndex - 2 ]; //-2 because the current one is the otherPreviousAddress and the one before it is the primary address
      }
      (<any>mp.contact.address).remove(lastProfileAddress);
    }
  }

  isAddressesPopulated(address: Address): boolean {
    return !!address.addressLine1;
  }

  /**    client address in the following. Check if all three addresses are populated and there is no 'Mailing Address'
   * @param matterParticipant
   */
  isPopulatedAllAddressesExcludeMailing(matterParticipant: MatterParticipant): boolean {
    let ret: boolean = false;
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.address
      && (matterParticipant.contact.address.every(item => item.addressTypeCode != AddressTypes.mailing))) { // exclude mailing address
      //Filter to get client Addresses
      let addressList: Address[] = matterParticipant.contact.address.filter(item => item.addressTypeCode != AddressTypes.clientIdBusiness
        && item.addressTypeCode != AddressTypes.serviceAddress
        && item.addressTypeCode != AddressTypes.reportAddress);
      //Check if all three addresses are populated
      if ((addressList.length > 2) && addressList.every(item => this.isAddressesPopulated(item))) {
        ret = true;
      }
    }

    return ret;
  }

  /**    client address in the following. Check if all three addresses are populated and there is a 'Mailing Address'
   * @param matterParticipant
   */
  isPopulatedAllAddressesIncludeOneMailing(matterParticipant: MatterParticipant): boolean {
    let ret: boolean = false;
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.address) {
      //Filter to get client Addresses
      let addressList: Address[] = matterParticipant.contact.address.filter(item => item.addressTypeCode != AddressTypes.clientIdBusiness
        && item.addressTypeCode != AddressTypes.serviceAddress
        && item.addressTypeCode != AddressTypes.reportAddress);
      let mailingAddress: Address = matterParticipant.contact.address.find(ad => ad.addressTypeCode == AddressTypes.mailing);
      //Check if all three addresses are populated and there is a 'Mailing Address'
      if ((addressList.length > 2) && addressList.every(item => this.isAddressesPopulated(item)) && mailingAddress) {
        ret = true;
      }
    }
    return ret;
  }

  /**    client address in the following. Check If there are less than three addresses without mailing address
   * @param matterParticipant
   */
  isPopulatedLessThan3Addresses(matterParticipant: MatterParticipant): boolean {
    let ret: boolean = false;
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.address) {
      //Filter to get client Addresses
      let addressList: Address[] = matterParticipant.contact.address.filter(item => item.addressTypeCode != AddressTypes.clientIdBusiness
        && item.addressTypeCode != AddressTypes.serviceAddress
        && item.addressTypeCode != AddressTypes.reportAddress);
      //Check If there are less than three addresses
      if (addressList.length < MAX_CLIENT_ADDRESSES) {
        ret = true;
      }
      let populateAddressList: Address[] = addressList.filter(item => this.isAddressesPopulated(item));
      if (populateAddressList.length < MAX_CLIENT_ADDRESSES) {
        ret = true;
      }
    }

    return ret;
  }

  isLenderReLineVisible(): boolean {
    return this.matter && this.matter.isMortgageMatterAndMortgageePrimary();
  }

  onWitnessJurisdictionChange(jurisdictionData: any): void {
    const witnessIndex = this.matter?.matterParticipants?.findIndex(participant => participant.matterParticipantRole === jurisdictionData.witnessMatterParticipantRole);
    if (witnessIndex > -1) {
      this.matter.matterParticipants.splice(witnessIndex, 1, jurisdictionData.matterParticipant);
    }
  }

  getParticipantByRole(role: MatterParticipantRole): MatterParticipant | undefined {
    return this.matter?.matterParticipants?.find(participant => participant.matterParticipantRole === role);
  }
}


