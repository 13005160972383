<!--modal-->
<div class="custom-modal-container form-horizontal"  (keydown)="produceF2($event)">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1>{{getTitle()}}</h1>
            </div>
        </div>
    </div>
    <div class="modal-body custom-modal-document">
        <div class="form-group">
            <div *ngIf="isDocumentMergeActive" class="col-lg-12 padding-top-10 position-fixed fixed-header-document-production-template z-index-1">
                <div class="col-lg-1 vertical-align-label">
                    <label class="control-label">
                        Category
                    </label>
                </div>
                <div class="col-lg-3">
                    <select class="form-control"
                            id="categoryFilter"
                            name="categoryFilter"
                            tabindex="0"
                            [(ngModel)]="categoryFilter"
                            (ngModelChange)="onCategoryChange()">
                        <option *ngFor="let category of categories"
                                [value]="category.id">
                            {{category.categoryDescription}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-6"></div>
                <div class="col-lg-2 vertical-align-label">
                    <button (click)="produceDocuments();" class="dp-btn-green">
                        <span>Produce (F2)</span>
                    </button>
                </div>

            </div>

            <div *ngIf="!isDocumentMergeActive" class="col-lg-12 padding-top-10 position-fixed z-index-1 width-98">
                <div class="col-lg-12 padding-bottom-10">
                    <div class="col-lg-3 vertical-align-label">
                        <button (click)="produceDocuments();" class="dp-btn-green">
                            <span>Produce (F2)</span>
                        </button>
                    </div>
                    <div class="col-lg-2 offset-lg-5">
                        <button (click)="produceAndShare();" class="dp-btn-white right" >
                            <span>Produce and Share</span>
                        </button>
                    </div>
                    <div class="col-lg-2">
                        <button (click)="share();" class="dp-btn-white">
                            <span>Share</span>
                        </button>
                    </div>
                </div>

                <div class="col-lg-12 padding-bottom-20">
                    <ul class="dppm-custom-tab">
                        <li (click)="selectTab('Documents')" [ngClass]="{active: isSelectedTabDocuments() }" >
                            <a>
                                <span>Documents ({{selectedDocumentTemplates?.length}} selected)</span>
                            </a>
                        </li>
                        <li *ngIf="isFormTabVisible()"  (click)="selectTab('Forms')" [ngClass]="{active: isSelectedTabForms() }" >
                            <a>
                                <span>Forms ({{selectedFormTemplates?.length}} selected)</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12" *ngIf="isSelectedTabDocuments()">
                    <div class="col-lg-1 vertical-align-label">
                        <label class="control-label">
                            Category
                        </label>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control"
                                id="categoryFilter"
                                name="categoryFilter"
                                tabindex="0"
                                [(ngModel)]="categoryFilter"
                                (ngModelChange)="onCategoryChange()">
                            <option *ngFor="let category of categories"
                                    [value]="category.id">
                                {{category.categoryDescription}}
                            </option>
                        </select>
                    </div>
                </div>

            </div>
            <div  *ngIf="rows && rows.length > 0"
                  class="col-lg-12 "
                  [ngClass]="isDocumentMergeActive ? 'padding-top-10' : 'margin-top-100'"
            >
                <table class="doc-template-matter-list-table fixed-header-table-document" dp-table-key-commands>
                    <thead>
                    <tr>
                        <th>
                            <ng-container *ngIf="!isDocumentMergeActive">
                                <dp-checkbox fieldId="templateCheckBoxAll">
                                    <input type="checkbox"
                                           id="templateCheckBoxAll"
                                           name="templateCheckBoxAll"
                                           [(ngModel)]="checkBoxAllTemplate"
                                           [checked]="checkBoxAllTemplate"
                                           (ngModelChange)="selectAllTemplate()"

                                    />
                                </dp-checkbox>
                            </ng-container>

                        </th>
                        <th *ngIf="!isSelectedTabForms()" (click)="sortTemplateList([0,1,2], 'fileName', 'sortStateFileNumber')">Master Template Name
                            <span *ngIf="sortStateFileNumber == 0"><span
                                class="padding-right-5 glyphicon glyphicon-stop"
                                aria-hidden="true"></span>Not Sorted</span>
                            <span *ngIf="sortStateFileNumber == 1" class="glyphicon glyphicon-triangle-top"
                                  aria-hidden="true"></span>
                            <span *ngIf="sortStateFileNumber == 2" class="glyphicon glyphicon-triangle-bottom"
                                  aria-hidden="true"></span></th>
                        <th (click)="sortTemplateList([0,1,2], 'description', 'sortStateDescriptionNumber')">
                            Description
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let templateRow of rows;let i=index;">
                        <tr [ngClass]="isTemplateSelected(templateRow) ? 'active' : 'null'"
                            (click)="selectTemplate(templateRow , $event)" (keydown.space)="selectTemplate(templateRow , $event)" >
                            <td>
                                <ng-container *ngIf="!isDocumentMergeActive">
                                    <dp-checkbox fieldId="templateCheckBox-{{i}}">
                                        <input type="checkbox"
                                               id="templateCheckBox-{{i}}"
                                               name="templateCheckBox-{{i}}"
                                               (click)="selectTemplate(templateRow ,  $event )"
                                               [attr.checked]="isTemplateSelected(templateRow)? 'checked' : null"
                                        />
                                    </dp-checkbox>
                                </ng-container>
                                <ng-container *ngIf="isDocumentMergeActive">
                                    <span class="radio">
                                    <input id="templateRadiobtn-{{i}}" type="radio"
                                           (click)="selectTemplate(templateRow ,  $event)"
                                            [checked]="isTemplateSelected(templateRow)"/>
                                    <label tabindex="0"></label>
                                    </span>
                                </ng-container>
                            </td>
                            <td *ngIf="!isSelectedTabForms()">
                                {{templateRow.template.fileName && templateRow.template.fileName.length > 40 ? (templateRow.template.fileName|
                                slice:0:38)+'....' :(templateRow.template.fileName)}}
                            </td>
                            <td [ngClass]="isSelectedTabForms()? 'width-925' : ''">
                                {{templateRow.template.description && templateRow.template.description.length > 80 ? (templateRow.template.description|
                                slice:0:78)+'....' :(templateRow.template.description)}}
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!rows.length" class="margin-bottom-100"></div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    (click)="cancel()"
                    class="dp-btn dp-btn-cancel">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
