<!-- modal -->
<form class="form-horizontal" >
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10">Document Sharing</div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body padding-bottom-100">
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Recipient</label>
            </div>
            <div class="col-lg-8">
                <p-autoComplete id="autocomplete-recipient"
                                name="autocomplete-recipient"
                                [(ngModel)]="selectedRecipient"
                                [class.dropdown-arrow-refferedBy]="true"
                                [suggestions]="recipientList"
                                #recipientAutoComplete
                                [readonly]="true"
                                [minLength]="0"
                                [delay]="1000"
                                maxlength="0"
                                scrollHeight="350px"
                                (keydown.space)="displayRecipients($event)"
                                (onSelect)="dataSelectedRecipient()"
                                [dropdown]="recipientList && recipientList.length > 0"
                                (onDropdownClick)="refreshRecipientList()"
                                autocomplete="off"
                                tabIndexIgnore>
                    <ng-template let-recipient pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                            [disableItem]="recipient">
                            <span class="col-lg-6 text-overflow">{{recipient.label? recipient.label.contactName : ''}}</span>
                            <span class="col-lg-6 text-overflow">{{recipient.label? recipient.label.typeName : ''}}</span>
                        </li>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
        <div class="form-group" >
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Documents</label>
            </div>
            <div class="col-lg-8 padding-top-8">
                <ng-container *ngFor="let item of context.documentNameList">
                    <span class="padding-right-10" *ngIf="documentUtility.doesValueNeedTriming(item, 20)">
                                    {{item | slice:0:17}}...
                                </span>
                    <span *ngIf="!documentUtility.doesValueNeedTriming(item, 20)">
                                   {{item}}
                                </span>
                </ng-container>
            </div>
        </div>
        <div class="form-group" >
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Message Precedent</label>
            </div>
            <div class="col-lg-8">
                <select class="form-control" id="messagePrecedents"  name="messagePrecedents"
                        [(ngModel)]="selectedPrecedent"
                        (change)="updateEditorMessage()">
                    <option value=""></option>
                    <option *ngFor="let precedent of messagePrecedents" [value]="precedent.id">
                        {{precedent.precedentName}}
                    </option>
                </select>
            </div>
        </div>

        <ng-container *ngIf="isSubjectVisible()">
            <div class="form-group" >
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Subject</label>
                </div>
                <div class="col-lg-8">
                    <input type="text" trimmed class="form-control" name="subject" id="subject"  [(ngModel)]="subject"  />
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isUnitySolicitorOrLawClerk()">
            <div class="form-group"  statusBarHelp tabindex="0" >
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right">Message</label>
                </div>
                <div class="col-lg-8" >
                    <textarea class="form-control"
                              name="unitySignatureBlock"
                              id="unitySignatureBlock"
                              [(ngModel)]="message">
                    </textarea>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedPrecedent && selectedRecipient && !isUnitySolicitorOrLawClerk()">
            <div class="form-group"  statusBarHelp tabindex="0" >
                <div class="col-lg-2 vertical-align-label">
                    <!--<label class="control-label text-right">Precedent Text</label>-->
                </div>

                <div class="col-lg-8 precedent-text">
                    <textarea name="precedentText" id="precedentText" rows="10" cols="80"
                              [dp-read-only]="true"  [innerHTML]="precedentText"></textarea>
                </div>
            </div>

        </ng-container>


    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtnSave" class="dp-btn" (click)="share()">
                <span>Share</span>
            </button>

            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
