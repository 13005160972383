import {ContactName} from './../matters/shared/contact-name';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';
import {Contact, matterApi, matterResponseKey} from '../matters/shared';
import {Address} from '../matters/shared/address';
import {Utils} from '../matters/shared/utils';
import * as _ from 'lodash';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';

@Injectable()
export class ContactCommandService {

  constructor(private httpClient: HttpClient) {
  }

  updateContact(contact: Contact, skipFieldsDataError: boolean = false): Observable<any> {

    return this.httpClient.put(`${ matterApi.contacts }/${ contact.id }`, contact, null, null, skipFieldsDataError)
    .map((res) => {
      return new Contact(res[ matterResponseKey.contact ]);
    });
  }

  getContact(id: number): Observable<Contact> {
    return this.httpClient
    .get(`${ matterApi.contacts }/${ id }`)
    .map((res) => {
      return new Contact(res[ matterResponseKey.contact ]);
    });
    //    .map((json: Contact) => {
    //        return new Contact(json);
    //    });
  }

  getContactByNameAndInitials(contactName: ContactName, selectedContactType: any): Observable<Contact[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    //Contact name and initials check should be only against staff-profiles
    let filterUrl: string = `?contactType=${ selectedContactType.contactListApiType }&filter=isStaffProfile_EQ_TRUE,contactName.lastName_EQ_${ Utils.escapeSearchText(contactName.lastName) },contactName.initials_EQ_${ contactName.initials }`;

    selectedContactType.isPrivate ? filterUrl = filterUrl + `,privateFlag_EQ_TRUE` : '';
    contactName.firstName ? filterUrl = filterUrl + `,contactName.firstName_EQ_${ Utils.escapeSearchText(contactName.firstName) }` : '';
    contactName.middleName ? filterUrl = filterUrl + `,contactName.middleName_EQ_${ Utils.escapeSearchText(contactName.middleName) }` : '';

    return this.httpClient.get(`${ matterApi.contactsList(accountId) }${ filterUrl }`)
    .map((res) => {
      return res[ selectedContactType.contactListReturnType ];
    });
  }

  getContactByOrganizationAndAddress(organizationName: string, address: Address, selectedContactType: any): Observable<Contact[]> {
    let filterUrl: string = `?organizationName=${ organizationName }&contactType=${ selectedContactType.contactType }&addressHash=${ address.addressHash }`;
    return this.httpClient.get(`${ matterApi.duplicateOrganization }${ filterUrl }`).map((res) => {
      return res[ selectedContactType.contactListReturnType ];
    });
  }

  getPrivateLawFirmByNameAndAddress(firmName: string, address: Address): Observable<Contact[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let filterUrl: string = `?contactType=LAW_FIRM&filter=legalFirmName_EQ_${ Utils.escapeSearchText(firmName) },address.addressHash_EQ_${ address.addressHash },privateFlag_EQ_true`;
    return this.httpClient.get(`${ matterApi.contactsList(accountId) }${ filterUrl }`)
    .map((res) => {
      return res[ 'Contacts' ];
    });
  }

  getGlobalLawFirmByNameAndAddress(firmName: string, address: Address): Observable<Contact[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let filterUrl: string = `?contactType=LAW_FIRM&filter=legalFirmName_EQ_${ Utils.escapeSearchText(firmName) },address.addressHash_EQ_${ address.addressHash },privateFlag_EQ_false`;
    return this.httpClient.get(`${ matterApi.contactsList(accountId) }${ filterUrl }`)
    .map((res) => {
      return res[ 'Contacts' ];
    });
  }

  getGlobalSolicitorByNameAndAddress(displayName: string, address: Address): Observable<Contact[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let filterUrl: string = `?contactType=SOLICITOR&filter=ALLdisplayName_EQ_${ Utils.escapeSearchText(displayName) }`;

    filterUrl = filterUrl + `,address.addressHash_EQ_${ address.addressHash },privateFlag_EQ_false`;

    return this.httpClient.get(`${ matterApi.contactsList(accountId) }${ filterUrl }`)
    .map((res) => {
      return res[ 'Contacts' ];
    });
  }

  getGlobalOrganizationNameAndAddress(organizationName: string, address: Address, contactType: string): Observable<Contact[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let filterUrl: string = `?contactType=${ contactType }&filter=ALLorganizationName_EQ_${ Utils.escapeSearchText(organizationName) },address.addressHash_EQ_${ address.addressHash }|privateFlag_EQ_false`;
    return this.httpClient.get(`${ matterApi.contactsList(accountId) }${ filterUrl }`)
    .map((res) => {
      return res[ 'Contacts' ];
    });
  }

  getGlobalMortgageeByNameAndBranchAndTransitNo(organizationName: string, transitNo: string, branchName: string): Observable<Contact[]> {
    transitNo = transitNo ? transitNo.trim() : null;
    branchName = branchName ? branchName.trim() : null;
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let filterUrl: string = `?contactType=MORTGAGEE&filter=ALLorganizationName_EQ_${ Utils.escapeSearchText(organizationName) },branch_EQ_${ Utils.escapeSearchText(branchName) },transitNo_EQ_${ Utils.escapeSearchText(transitNo) }|privateFlag_EQ_false`;
    return this.httpClient.get(`${ matterApi.contactsList(accountId) }${ filterUrl }`)
    .map((res) => {
      return res[ 'Contacts' ];
    });
  }

  /**
   * This method checks that passed contact exists into contact list but ignores global if proxy exists. As Private Copy of a Global Records are allowed to
   * have the same "Company Name" and "Address" as the Global Record they are linked to.
   * @param contactList
   * @param contact
   * @returns {boolean}
   */
  contactExistsInListExceptCopyOfGlobal(contactList: Contact[], contact: Contact): boolean {
    const dupContacts: Contact[] = _.filter(contactList, (c: Contact) => {
      //return c.id !== contact.id || (c.id === contact.id && !contact.isCopyOfGlobal)
      if (c.id !== contact.id) {
        if (contact.isProxyCopyOfGlobal) {
          return c.id !== contact.sourceContactId;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });

    return dupContacts.length > 0;
  }

  isGlobalSolicitorExists(solicitorId: string): Observable<boolean> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.httpClient.get(`${ matterApi.isGlobalSolicitorExists(accountId, solicitorId) }`)
    .map((res) => {
      return res[ 'GlobalSolicitorExists' ];
    });
  }

}
