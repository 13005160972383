import {Observable, Subject} from 'rxjs';
import {Contact} from './contact';
import {ReferredByInfo, ReferredByType} from './referred-by-info';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {customPickListKey} from '../../shared/modal/custom-pick-list-key';
import {SpecialComment} from '../../shared/modal/special-comment';
import {DialogService} from '../../shared/dialog/dialog.service';
import {CustomPickListModalComponent} from '../../shared/modal/custom-pick-list-modal.component';
import {GenderTypes} from '../../contact/contact-type-mapping';
import {ContactQueryService} from '../../contact/contact-query.service';
import {Utils} from './utils';
import {ContactService} from '../../shared-main/contact.service';
import {PurchaserService} from '../purchaser/purchaser.service';
import {CustomPickListService} from '../../shared/modal/custom-pick-list.service';

/**
 *  ReferralWrapper  includes autoComplete ngModel value and selected Referral class information
 */
export class ReferredByWrapper extends BaseEntity {
  // Reffered By
  //refferedByMatterParticipant : string;
  refferedByDataOptions: any;
  refferedByLabel: string;
  showRefferedByAttention: boolean;
  selectedReferredBy: any;
  selectedReferredByInputValue: string;
  referredByKeyDownCode: number;
  refferedByCompleteEmptyFlag: boolean = false;
  referredByLoading = false;
  searchTermrefferedBy: Subject<any> = new Subject<any>();
  refferedBySubscription: any;

  cachedAttentionList: Contact[];
  attentionList: Contact[];

  pickListType: string;

  selectedReferredByInfo: ReferredByInfo;

  isDirty: boolean = false;

  pageNo: number;
  ignoreEditReferredByListItem: boolean;

  utils;

  constructor(referredByWrapper?: ReferredByWrapper) {
    super(referredByWrapper);
    if (referredByWrapper) {
      if (referredByWrapper.selectedReferredByInfo) {
        this.selectedReferredByInfo = new ReferredByInfo(referredByWrapper.selectedReferredByInfo);
      }

      this.cachedAttentionList = [];
      if (Array.isArray(referredByWrapper.cachedAttentionList)) {
        for (let cachedAttention of referredByWrapper.cachedAttentionList) {
          this.cachedAttentionList.push(new Contact(cachedAttention));
        }
      }

      this.attentionList = [];
      if (Array.isArray(referredByWrapper.attentionList)) {
        for (let attention of referredByWrapper.attentionList) {
          this.attentionList.push(new Contact(attention));
        }
      }
    } else {
      this.cachedAttentionList = [];
      this.attentionList = [];
      this.selectedReferredByInfo = new ReferredByInfo();
    }

    this.utils = new Utils();
  }

  get referredByType(): string {
    return this.selectedReferredByInfo && this.selectedReferredByInfo.referredByType;
  }

  set referredByType(referredByType: string) {
    if (this.selectedReferredByInfo) {
      this.selectedReferredByInfo.referredByType = <ReferredByType>referredByType;
    }
  }

  get referredBy(): string {
    return this.selectedReferredByInfo && this.selectedReferredByInfo.referredBy;
  }

  set referredBy(referredBy: string) {
    if (this.selectedReferredByInfo) {
      this.selectedReferredByInfo.referredBy = referredBy;
    }
  }

  get referredBySourceContactId(): number {
    return this.selectedReferredByInfo && this.selectedReferredByInfo.sourceContactId;
  }

  set referredBySourceContactId(sourceContactId: number) {
    if (this.selectedReferredByInfo) {
      this.selectedReferredByInfo.sourceContactId = sourceContactId;
    }
  }

  get referredByAttention(): string {
    return this.selectedReferredByInfo && this.selectedReferredByInfo.referredByAttention;
  }

  set referredByAttention(referredByAttention: string) {
    if (this.selectedReferredByInfo) {
      this.selectedReferredByInfo.referredByAttention = referredByAttention;
    }
  }

  get attentionDropdownArrowShow(): boolean {
    return Array.isArray(this.attentionList) && this.attentionList.length > 0;
  }

  getShowRefferedByAttention(): boolean {
    switch (this.referredByType) {
      case 'REAL_ESTATE_BROKER':
        return false;
      case 'SURVEYOR':
        return true;
      case 'REAL_ESTATE_AGENT':
        return false;
      case 'CLIENT':
        return false;
      case'CONDO_CORP':
        return true;
      case 'INSURANCE_BROKER':
        return false;
      case 'MANAGE_COMP':
        return true;
      case 'MORTGAGE_BROKER':
        return true;
      case 'MORTGAGEE':
        return true;
      case 'SOLICITOR':
        return false;
      case 'MANUAL_ENTRY':
        return false;
      default:
        return false;
    }
  }

  // Reffered by
  getPlaceholderForReferrers(): string {
    switch (this.referredByType) {
      case 'REAL_ESTATE_BROKER':
        this.refferedByLabel = 'Real Estate Broker\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by Name, Address';
      case 'SURVEYOR':
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        this.refferedByLabel = 'Surveyor\'s Name';
        return 'Search by Name, Address';
      case 'REAL_ESTATE_AGENT':
        this.refferedByLabel = 'Real Estate Agent\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by "Surname, First Name", Email Address';
      case 'CLIENT':
        this.refferedByLabel = 'Client\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by "Surname, First Name"';
      case'CONDO_CORP':
        this.refferedByLabel = 'Condominium Corporation\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by Name, Number or Address';
      case 'INSURANCE_BROKER':
        this.refferedByLabel = 'Insurance Broker\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by Name, Address';
      case 'MANAGE_COMP':
        this.refferedByLabel = 'Management Company\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by Name, Address';
      case 'MORTGAGE_BROKER':
        this.refferedByLabel = 'Mortgage Broker\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by Name, Address';
      case 'MORTGAGEE':
        this.refferedByLabel = 'Mortgagee\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by Name, Address';

      case 'SOLICITOR':
        this.refferedByLabel = 'Solicitor\'s Name';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return 'Search by "Surname, First Name" or Address';
      case 'MANUAL_ENTRY':
        this.refferedByLabel = 'Referred By Manual Entry';
        this.showRefferedByAttention = this.getShowRefferedByAttention();
        return '';
      default:
        return '';
    }

  }

  isReferredByManual(): boolean {
    return this.referredByType === 'MANUAL_ENTRY';
  }

  getFieldForReferrerAutoComplete(): string {
    if (this.referredByType === 'REAL_ESTATE_BROKER' ||
      this.referredByType === 'CONDO_CORP' ||
      this.referredByType === 'MANAGE_COMP' ||
      this.referredByType === 'MORTGAGE_BROKER' ||
      this.referredByType === 'INSURANCE_BROKER' ||
      this.referredByType === 'MORTGAGEE' ||
      this.referredByType === 'SURVEYOR') {
      return 'organizationName';
    } else if (this.referredByType === 'REAL_ESTATE_AGENT') {
      return 'surnameLastFullName';
    } else if (this.referredByType === 'CLIENT') {
      return 'surnameLastFullName';
    } else if (this.referredByType === 'PRIVATE_LENDER') {
      return (this.selectedReferredBy && this.selectedReferredBy.gender == GenderTypes.OTHERENTITY)
        ? 'organizationName'
        : 'surnameLastFullName';
    } else if (this.referredByType === 'SOLICITOR') {
      return 'genericName';
    } else if (this.referredByType === 'MANUAL_ENTRY') {
      return 'customPickListItemValue';
    }
  }

  searchReferredBy(eventObject): void {
    if (this.isReferredByManual() && eventObject && eventObject.event && !eventObject.event.query) {
      return;
    }
    this.cachedAttentionList = [];
    this.attentionList = [];
    let entered: string = eventObject && eventObject.event && eventObject.event.query;
    this.searchTermrefferedBy.next({entered: entered, manualReferral: eventObject && eventObject.manualReferral});
  }

  referredByChange(): void {
    this.selectedReferredBy = null;
    this.cachedAttentionList = [];
    this.attentionList = [];
    this.referredBy = null;
    this.referredBySourceContactId = null;
    this.referredByAttention = null;
    this.enableSave();
  }

  cleanUpReferredBy(): void {
    if ((this.selectedReferredBy.toString()).trim() === '') {
      this.referredBy = '';
    } else {
      this.referredBy = (this.selectedReferredBy.id) ? this.selectedReferredBy[ this.getFieldForReferrerAutoComplete() ] : this.selectedReferredBy;
    }
  }

  recordReferredByKeydowncode(event): void {
    this.referredByKeyDownCode = event.keyCode;
  }

  onSelectedReferredByChange(event) {
    this.enableSave();
    if (typeof event === 'string') {
      this.selectedReferredByInputValue = event;
      this.referredBy = this.selectedReferredBy;
      this.referredBySourceContactId = null;
    }
  }

  // This is a workaround because of p-autoComplete's special behaviour which was found in DPPMP-9185.
  // While,
  //   1) an item was selected from dropdown, and the input box was displaying the name of the data item
  //   2) made some changes in the input box and left a non-empty string
  //   3) pressed 'Tab' key
  //   4) Displayed the LAST selected record
  // p-autoComplete's reaction:
  //   1) triggered 'ngModelChange' event with current selected data item
  //   2) triggered 'onSelect' event
  //   3) forwarded 'keydown' event of 'Tab' key to the caller, this component
  // The issue caused:
  //   Since 'ngModelChange' was triggered with current select data item and 'onSelect' was triggered as well,
  //   the effect is equivalent to mouse clicked the data item from the dropdown, and user's input change is lost.
  //   In this case, this component has no knowledge that these 'ngModelChange' and 'onSelect' events was triggered
  //   by mouse clicking or 'Tab' key pressing.
  // Workaround:
  //   1) record any user key input to a temp variable
  //   2) delay 'onSelect' handling by put it into a timer, so the handling will be after 'keydown' event. If the latest
  //      'keydown' was 'Tab', restore user's input from the temp variable.
  // Note:
  //   This issue is caused by p-autoComplete's behaviour, and every code using it is affected.
  dataSelectedReferredBy(dialogService: DialogService, contactQueryService?: ContactQueryService, callBack?: Function, manualReferral?: ReferredByWrapper): void {
    if (this.referredByKeyDownCode === 9) {
      this.selectedReferredBy = this.selectedReferredByInputValue;
      this.referredBy = this.selectedReferredBy;
      this.referredBySourceContactId = null;
    } else {
      if (this.referredByType === 'MANUAL_ENTRY') {
        this.dataSelectedReferredByForManualEntry(dialogService, manualReferral);
        this.referredBySourceContactId = null;
      } else {
        if (this.selectedReferredBy.id === undefined) {
          this.selectedReferredBy = undefined;
          this.referredBySourceContactId = null;
        } else {
          this.referredBySourceContactId = this.selectedReferredBy.id;
          this.referredBy = this.selectedReferredBy[ this.getFieldForReferrerAutoComplete() ];
          if (callBack && ContactQueryService) {
            callBack(this, contactQueryService);
          }
          this.selectedReferredBy = this.selectedReferredBy[ this.getFieldForReferrerAutoComplete() ];
        }
      }
    }
  }

  dataSelectedReferredByForManualEntry(dialogService: DialogService, manualReferral?: ReferredByWrapper): void {
    // open PickList dialog if id not present and starts with Edit...
    if (this.selectedReferredBy.id === undefined) {
      if (this.selectedReferredBy.customPickListItemValue.indexOf('Edit Referred By List') > -1) {
        this.selectedReferredBy = 'Edit Referred By List';
        this.pickListType = customPickListKey.ReferredBy;
        this.openPickListModal(dialogService, manualReferral);
      }
    } else {
      // set special comments
      this.referredBy = this.selectedReferredBy.customPickListItemValue;
      this.selectedReferredBy = this.selectedReferredBy.customPickListItemValue;
      this.enableSave();
    }
  }

  refferedByDataAddressNotEmpty(refferedByData: any): boolean {
    // "!!" can avoid to return "null" and return boolean
    return !!refferedByData && Array.isArray(refferedByData.address) && refferedByData.address.length > 0;
  }

  handleDropdownClickReferredByCustomPickList = (event) => { //use arrow function syntax to preserve 'this' binding
    if (!event) {
      return;
    }
    if (this.isReferredByManual()) {
      if (event.referredByWrapper && event.manualReferral && event.manualReferral.refferedByDataOptions) {
        event.referredByWrapper.refferedByDataOptions = event.manualReferral.refferedByDataOptions.slice();
      }
      event.referredByWrapper.refferedBySubscription.remove();

    }
  };

  isPersonVisible(refferedByData: any): boolean {
    if (!refferedByData) {
      return false;
    }

    return refferedByData.gender === 'QUESTION'
      || refferedByData.gender === 'MALE'
      || refferedByData.gender === 'FEMALE'
      || refferedByData.gender === 'MALEPOA'
      || refferedByData.gender === 'FEMALEPOA'
      || refferedByData.gender === 'ESTATE';

  }

  // method to call when user closes the Custom Pick List dialog
  removePickList(event) {
    if (!event) {
      return;
    }
    //this.displayPickList = false;
    switch (event.type) {
      case customPickListKey.ReferredBy:
        this.selectedReferredBy = undefined;
        break;

      default:
        break;
    }
  }

  setPickListItem(comment: SpecialComment): void {
    //this.displayPickList = false;
    switch (this.pickListType) {
      case customPickListKey.ReferredBy:
        this.refferedByDataOptions.push(comment);
        this.selectedReferredBy = comment ? comment.customPickListItemValue : '';
        this.referredBy = comment ? comment.customPickListItemValue : '';
        this.enableSave();
        break;
      default:
        break;
    }
  }

  public openPickListModal(dialogService: DialogService, manualReferral?: ReferredByWrapper): void {
    let referralByComments: SpecialComment[] = manualReferral.refferedByDataOptions.slice();
    referralByComments.shift();
    dialogService.matDialogContent({
      content: CustomPickListModalComponent,
      context: {
        pickListType: this.pickListType,
        referralByComments: referralByComments
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'select') {
            this.setPickListItem(result.comment);
          } else {
            this.removePickList(result);
          }

          if (result.refferedByDataOptions && manualReferral && manualReferral.refferedByDataOptions) {
            let tmp = manualReferral.refferedByDataOptions.shift();
            manualReferral.refferedByDataOptions
              = result.refferedByDataOptions;
            manualReferral.refferedByDataOptions.unshift(tmp);
            this.refferedByDataOptions = manualReferral.refferedByDataOptions;
          }
        }
      },
      onRejection: () => {
        this.removePickList({value: true, type: this.pickListType});
      },
      
    });
  }

  enableSave() {
    this.isDirty = true;
  }

  initReferredByModalWrapper(contactService: ContactService, purchaserService: PurchaserService,
                             customPickListService: CustomPickListService): void {
    this.attentionList = [];
    this.cachedAttentionList = [];
    // Reffered By
    this.searchTermrefferedBy = Utils.refreshSubject(this.searchTermrefferedBy);
    this.refferedBySubscription = this.searchTermrefferedBy.switchMap((event) => {
      let term = event && event.entered;
      let manualReferral = event && event.manualReferral;
      this.referredByLoading = true;
      this.refferedByCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.refferedByCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToRealEstateBroker()));
          }, 10);
        });

        return observable;

      } else {
        switch (this.referredByType) {
          case 'REAL_ESTATE_BROKER':
            return contactService.getContactsType(term, 'REALESTATEBROKER', true, null, true);
          case 'SURVEYOR':
            return contactService.getContactsType(term, 'SURVEYOR', true);
          case 'REAL_ESTATE_AGENT':
            return contactService.getContactsType(term, 'REALESTATEAGENT', true, null, true);
          case 'CLIENT':
            return purchaserService.getClientPurchaser(term, true);
          case 'CONDO_CORP':
            return contactService.getActiveContactListPerPage(term, 'CONDO_CORPORATION', true, 1, 15, 'ACTIVE');
          case 'INSURANCE_BROKER':
            return contactService.getActiveContactListPerPage(term, 'INSURANCE_BROKER', true, 1, 15, 'ACTIVE');
          case 'MANAGE_COMP':
            return contactService.getActiveContactListPerPage(term, 'MANAGEMENT_COMPANY', true, 1, 15, 'ACTIVE');
          case 'MORTGAGE_BROKER':
            return contactService.getActiveContactListPerPage(term, 'MORTGAGE_BROKER', true, 1, 15, 'ACTIVE');
          case 'MORTGAGEE':
            return contactService.getActiveContactListPerPage(term, 'MORTGAGEE', true, 1, 15, 'ACTIVE');
          case 'PRIVATE_LENDER':
            return contactService.getActiveContactListPerPage(term, 'PRIVATE_LENDER', true, 1, 15, 'ACTIVE');
          case 'SOLICITOR':
            return contactService.getActiveContactListPerPage(term, 'SOLICITOR', true, 1, 15, 'ACTIVE');
          case 'MANUAL_ENTRY':
            return customPickListService.searchForReferral(term, manualReferral && manualReferral.refferedByDataOptions);
          default:
            break;
        }
      }
    })
    .subscribe(
      (data: any[]) => {
        this.referredByLoading = false;
        return this.refferedByDataOptions = data;
      });
  }
}
