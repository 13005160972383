import {DialogService} from '../shared/dialog/dialog.service';
import {Observable} from 'rxjs';
import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';

export interface UnSavedChangesComponent {
  // unsavedChanges should contain logic to determine whether form is dirty or not
  // unsavedChanges method if return false that means user has some unsaved changes and a dialog should be prompted
  // unsavedChanges method if return true that means user has not made any changes and should be allowed route normally
  unsavedChanges: () => boolean | Observable<boolean>;
  // save changes method should contain logic to save the user form and move to next route selected by User
  // save user if return true user UnSavedChangesGuard will allow user to move to next route selected by User
  // save user if return false user UnSavedChangesGuard will not allow user to move to next route since there some error on form while saving.
  saveChanges?: () => boolean | Observable<boolean>;

  // Title which shows up in dailog box so that user know on which form he has unsaved changes .
  setUnsavedChangesFormMessage(): string;

  // show save button in dailog box or not and by default it will be true;
  saveBtnOption?: boolean;

  enableGuardCheck?(enable: boolean): void;

  guardCheckEnabled?(): boolean;
}

@Injectable()
export class UnSavedChangesGuard implements CanDeactivate<UnSavedChangesComponent> {

  constructor(private dialogService: DialogService) {

  }

  canDeactivate(component: UnSavedChangesComponent): boolean | Observable<boolean> {

    if (!component.unsavedChanges()) {
      if (component.guardCheckEnabled && !component.guardCheckEnabled()) {
        return true;
      }

      return this.dialogService
      .confirmUnsavedChange(component.saveBtnOption, component.setUnsavedChangesFormMessage())
      .flatMap((response: any) => {
        console.log(response);
        if (response == 'DONT_SAVE') {
          return Observable.of(true);
        } else if (response == 'CANCEL') {
          return Observable.of(false);
        } else if (response == 'SAVE') {
          let saveChanges: boolean | Observable<boolean> = component.saveChanges();
          if (!(saveChanges instanceof Observable)) {
            saveChanges = Observable.of(saveChanges);
          }
          return saveChanges;

        } else {
          return Observable.of(true);
        }

      });
    } else {

      return true;
    }

  }
}
