<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>Principal: Net Advance Details {{matter.isMatterProvinceBC ? '' : 'for Trust Ledger'}} </h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="cancelDialog()" >&times;</button>
    </div>
    <div class=" modal-body form-horizontal">
        <div class="form-group">
            <div class="col-lg-12">

                           <input type="text" class="form-control" [dp-read-only]="true"
                       value="{{ mortgageDisplayTextValue }}" />
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label"
                       for="calcMethod">Calculation Method</label>
            </div>
            <div class="col-lg-10">
             <select id="calcMethod" class="form-control focus-first" [(ngModel)]="mortgage.mortgageTerm.calculationMethod" (ngModelChange)="onCalcMethodChange($event)">
                 <option *ngFor="let calc of calculationMethodDropDown"
                         [value]="calc.value">
                     {{calc.label}}
                 </option>
             </select>
            </div>
        </div>
        <div class="form-group" *ngIf="mortgage.mortgageTerm.calculationMethod === 'SPECIFY_AMOUNT'">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label"
                       for="calcMethod">Enter the net advance amount</label>
            </div>
            <div class="col-lg-4">
                <input type="text" class="form-control" id="netAdvance-specify" name="netAdvance-specify" dp-default-currency
                       dp-currency [(ngModel)]="totalNASpecificAmount" />
            </div>
        </div>
        <table class="table margin-bottom-60 margin-top-40 net-advance-table"
               *ngIf="mortgage.mortgageTerm.calculationMethod === 'CALCULATE_ADVANCE' || mortgage.mortgageTerm.calculationMethod === 'SPECIFY_AMOUNT'">
            <tr><th style="width: 40%;">ITEM</th><th style="width: 20%;"></th><th style="width: 40%;">AMOUNT</th></tr>
            <tr><td>Principal of Mortgage</td><td></td><td><div class="currency-align-right">{{mortgage.mortgageTerm.principal |
                currency:'USD':true}}</div></td></tr>
            <tr *ngFor="let item of netAdvanceItems; let i=index; trackBy:trackByIndex;">
                <td><input type="text" trimmed id="itemName-{{i}}" name="itemName{{i}}" size="60" class="border-hide" maxlength = "100" [(ngModel)]="item.name"
                           (keydown)="nameInputKeyPress($event,i)" /></td>
                <td><dp-burger-menu [items]="burgerMenuItems" (itemClicked)="burgerMenuClicked($event, i)"></dp-burger-menu></td>
                <td><input type="text"  id="amountName-{{i}}" name="amountName-{{i}}" size="60" class="border-hide currency-align-right"
                           [(ngModel)]="item.amount"
                           allow-negative="true"
                           (blur)="validateTotal()"
                           dp-default-currency
                           dp-currency
                 /></td>
            </tr>
            <tr *ngIf="mortgage.mortgageTerm.calculationMethod==='SPECIFY_AMOUNT' && amountNotAdvanced()!=0"><td>Amount Not
                Advanced</td><td></td><td><div class="currency-align-right">{{amountNotAdvanced() |
                currency:'USD':true}}</div></td></tr>
            <tr><td>Net Advance</td><td></td><td>
                <div *ngIf="mortgage.mortgageTerm.calculationMethod==='CALCULATE_ADVANCE'"
                     class="currency-align-right">{{netAdvanceTotal() | currency:'USD':true}}</div>
                <div *ngIf="mortgage.mortgageTerm.calculationMethod==='SPECIFY_AMOUNT'" class="currency-align-right">{{ totalNetAdvanceOrZero | currency:'USD':true}}
            </div>
            </td>

            </tr>
        </table>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons text-center">
            <button type="button" id="okBtn"
                    class="dp-btn keyboard-save-action" (click)="closeDialog()">
                <span>OK</span>
            </button>
            <button type="button"
                    class="dp-btn dp-btn-cancel" (click)="cancelDialog()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
