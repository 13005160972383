import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Matter} from '../../../shared/index';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {ApplicationError} from '../../../../core/application-error';
import {FieldError} from '../../../../core';
import {TeranetCourierInstrumentRequest} from '../../../../shared-main/teranet/teranet-courier-instrument-request';
import {Instrument} from '../../../../shared-main/teranet/instrument';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {BurgerMenuExtendedItem} from '../../../shared/burger-menu-extended-item';
import {Address} from '../../../shared/address';
import {AddInstrumentToCourierModal} from './add-instrument-to-courier.modal.component';
import {DocumentProfileService} from '../../../../admin/document-profile/document-profile-edit/document-profile.service';
import {DocumentProfile} from '../../../../admin/document-profile/document-profile';
import {teranetConnectLinks} from '../../../../shared-main/teranet/teranet-constants';
import {DatePipe} from '@angular/common';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class CourierInstrumentContext {
  matter: Matter;
  teranetUser: TeranetUser;
  parcelRegister: ParcelRegister;
  instrumentId: number;
}

@Component({
  selector: 'dp-courier-instrument-modal',
  templateUrl: 'courier-instrument.modal.component.html',
  providers: [ErrorService, DatePipe]
})
export class CourierInstrumentModal extends ModalComponent<CourierInstrumentContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  teranetCourierRequest: TeranetCourierInstrumentRequest;
  courierInstruments: Instrument[] = [];
  copyOptions: Number[] = [];
  instrumentBurgerMenuItems: BurgerMenuExtendedItem[] = [];
  addressShutterClosed: boolean = true;
  feesLink: string = teranetConnectLinks.fees;

  constructor(
    public dialog: MatDialogRef<CourierInstrumentModal>,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    public errorService: ErrorService,
    public documentProfileService: DocumentProfileService,
    public datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) context?: CourierInstrumentContext
  ) {
    super(dialog, context);
  }

  getTeranetDocketInstrument(instrumentNumber: string) {
    return this.matter.teranetDocket.getPrimaryInstrument(instrumentNumber);
  }

  getTeranetDocketPrimaryParcel(instrumentNumber: string) {
    return this.matter.teranetDocket.getPrimaryParcelRegister(instrumentNumber);
  }

  get parcelRegister(): ParcelRegister {
    return this.context.parcelRegister;
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get teranetUser(): TeranetUser {
    return this.context.teranetUser;
  }

  get isProceedButtonDisabled(): boolean {
    return this.courierInstruments.length == 0;
  }

  ngOnInit() {
    //add initial instrument for courier request
    this.courierInstruments.push(
      this.parcelRegister.instruments.find((instrument) => instrument.id == this.context.instrumentId)
    );
    this.copyOptions = Array.from({length: 10}, (v, k) => k + 1); //array of numbers 1 to 10
    this.courierInstruments[0].numberCourierCopies = 1;

    this.teranetCourierRequest = new TeranetCourierInstrumentRequest();
    this.teranetCourierRequest.address = new Address();
    this.teranetCourierRequest.instrumentIds = [];

    this.initializeBurgerMenu();

    //get solicitor's name from matter to populate Attention field
    let solicitor = this.matter.solicitor;
    if (solicitor && solicitor.contact) {
      this.teranetCourierRequest.attention = solicitor.contact.firstLastNames;
    }

    //get default document profile where the courier address and delivery instructions from previous requests are saved
    this.documentProfileService.getDefault().subscribe((profile: DocumentProfile) => {
      if (!profile.firmDocumentProfile.courierAddress) {
        //No previous courier address found. Populate with firm address.
        if (profile.firmDocumentProfile.address) {
          this.teranetCourierRequest.address = new Address(profile.firmDocumentProfile.address);
          this.teranetCourierRequest.address.id = null;
        } else {
          this.addressShutterClosed = false;
        }
      } else {
        //re-using previous courier address
        this.teranetCourierRequest.address = profile.firmDocumentProfile.courierAddress;
      }

      if (profile.firmDocumentProfile.deliveryInstructions) {
        this.teranetCourierRequest.deliveryInstructions = profile.firmDocumentProfile.deliveryInstructions;
      }

      if (profile.firmDocumentProfile.firmName) {
        this.teranetCourierRequest.firmName = profile.firmDocumentProfile.firmName;
      }
    });
  }

  //Only have delete option in burger menu currently.
  initializeBurgerMenu(): void {
    let burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = 'Delete';
    burgerMenuItem.topline = false;
    burgerMenuItem.action = this.deleteInstrumentFromRequest;
    this.instrumentBurgerMenuItems.push(burgerMenuItem);
  }

  clickInstrumentsBurgerMenu(instrumentIndex: number, clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(instrumentIndex, this);
    }
  }

  deleteInstrumentFromRequest(instrumentIndex: number, self: CourierInstrumentModal): void {
    self.courierInstruments.splice(instrumentIndex, 1);
  }

  proceed(): void {
    if (this.isCourierRequestValid()) {
      this.checkForPreviousCourierRequests();
    }
  }

  checkForPreviousCourierRequests() {
    let getRequests: string = '';
    let msg: string = '';
    for (let i = 0; i < this.courierInstruments.length; i++) {
      let instrument: Instrument = this.getTeranetDocketInstrument(this.courierInstruments[i].instrumentNumber);
      if (instrument.courierDate) {
        getRequests =
          getRequests +
          'The instrument number ' +
          instrument.instrumentNumber +
          ' was previously requested for courier' +
          ' delivery on' +
          ' ' +
          this.datePipe.transform(instrument.courierDate, 'MMM dd, yyyy h:mm a') +
          '.';
      }
    }
    if (getRequests != '') {
      msg =
        ' Requesting it again may result in additional fees charged to your Teranet&nbsp;account. <br><br> Do you wish to request it&nbsp;again?';
      this.dialogService
        .confirm('Instrument was previously requested for courier delivery', getRequests + msg, false, 'Yes', 'No')
        .subscribe((res) => {
          if (res) {
            this.proceedToCourier();
          }
        });
    } else {
      this.proceedToCourier();
    }
  }

  //total number of instruments (including copies)
  orderSize(): number {
    let total: number = 0;
    if (this.courierInstruments) {
      for (let instrument of this.courierInstruments) {
        total = total + Number(instrument.numberCourierCopies);
      }
    }
    return total;
  }

  proceedToCourier(): void {
    this.teranetCourierRequest.teranetUser = this.teranetUser;
    let instrumentObj: Instrument;
    this.teranetCourierRequest.instrumentIds = [];
    //add multiple copies of instrument id to request if more than one copy requested for courier
    for (let instrument of this.courierInstruments) {
      instrumentObj = this.getTeranetDocketInstrument(instrument.instrumentNumber);
      this.getTeranetDocketPrimaryParcel(instrument.instrumentNumber);
      for (let i = 0; i < instrument.numberCourierCopies; i++) {
        this.teranetCourierRequest.instrumentIds.push(instrumentObj.id);
      }
    }

    let primaryParcelRegister = this.getTeranetDocketPrimaryParcel(instrumentObj.instrumentNumber);

    this.teranetService.requestToCourier(this.teranetCourierRequest, primaryParcelRegister.id).subscribe(
      (response: ParcelRegister) => {
        if (response) {
          this.dialog.close({
            parcelRegister: response
          });
        } else {
          console.log('Failure in courier request.');
        }
      },
      (error: ApplicationError) => {
        this.handleTeranetCourierRequestApiError(error);
      }
    );
  }

  handleTeranetCourierRequestApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  onCancel(): void {
    this.dialog.close();
  }

  //add instrument button clicked
  addInstrument(): void {
    this.modalErrorComponent.removeDpFieldError('teranet.courierInstrument.numberInstruments');
    if (this.orderSize() > 10) {
      this.modalErrorComponent.createDPFieldError('teranet.courierInstrument.numberInstruments');
    } else {
      this.dialogService.matDialogContent({
        content: AddInstrumentToCourierModal,
        context: {
          parcelRegister: this.parcelRegister,
          selectedCourierInstruments: this.courierInstruments
        },
        onFulfillment: (result: any) => {
          if (result && result.action === 'SELECTED') {
            result.selectedInstrument.numberCourierCopies = 1;
            this.courierInstruments.push(result.selectedInstrument);
          }
        }
      });
    }
  }

  isCourierRequestValid(): boolean {
    this.modalErrorComponent.removeAllDpFieldError();
    if (!this.teranetCourierRequest.attention) {
      this.modalErrorComponent.createDPFieldError('teranet.courierInstrument.missingAttention');
    }

    if (!this.teranetCourierRequest.address.addressLine1) {
      this.modalErrorComponent.createDPFieldError('courier.address.street');
    }
    if (!this.teranetCourierRequest.address.city) {
      this.modalErrorComponent.createDPFieldError('courier.address.city');
    }
    if (!this.teranetCourierRequest.address.provinceName) {
      this.modalErrorComponent.createDPFieldError('courier.address.provinceName');
    }
    if (!this.teranetCourierRequest.address.country) {
      this.modalErrorComponent.createDPFieldError('courier.address.country');
    }
    if (!this.teranetCourierRequest.address.postalCode) {
      this.modalErrorComponent.createDPFieldError('courier.address.postalCode');
    }

    if (this.orderSize() > 10) {
      this.modalErrorComponent.createDPFieldError('teranet.courierInstrument.numberInstruments');
    }
    return !this.errorService.hasErrors();
  }
}
