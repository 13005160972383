import {Component, Inject, ViewChild} from '@angular/core';
import moment from 'moment';
import {CurrencyPipe} from '@angular/common';
import {PropertyStrataMaintenanceFee} from '../../shared/property-strata-maintenance-fee';
import {DdlTypeForYesNo} from '../../shared/mortgage';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {MatterType, MatterTypesValue} from '../../shared';
import Utils from '../../../shared-main/utils';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class StrataMaintenanceFeeModalContext {
  strataMaintenanceFee: PropertyStrataMaintenanceFee;
  matterCloseDate: string;
  context: DdlTypeForYesNo[];
  matterType: MatterType;
}

@Component({
  selector: 'property-strata-maintenance-fee-modal-content',
  templateUrl: 'propery-strata-maintenance-fee.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class PropertyStrataMaintenanceFeeModal extends ModalComponent<StrataMaintenanceFeeModalContext> {
  yesNoItems: DdlTypeForYesNo[];
  closingDate: string;
  applicableMatterTypes: MatterType[] = ['PURCHASE', 'MORTGAGE', 'SALE'];
  MatterTypesValue = MatterTypesValue;

  pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<PropertyStrataMaintenanceFeeModal>,
    public dialogService: DialogService,
    public errorService: ErrorService,
    public currencyPipe: CurrencyPipe,
    @Inject(MAT_DIALOG_DATA) context?: StrataMaintenanceFeeModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.yesNoItems = [
      {
        label: 'Yes',
        value: 'YES'
      },
      {
        label: 'No',
        value: 'NO'
      }
    ];
  }

  close(): void {
    this.dialog.close({strataMaintenanceFee: this.context.strataMaintenanceFee, isUpdated: false});
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpFieldError();
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close({strataMaintenanceFee: this.context.strataMaintenanceFee, isUpdated: true});
    }
  }

  getEmptyCloseDateText() {
    return this.context.matterType == 'MORTGAGE' ? 'Completion Date' : 'Adjustment Date';
  }

  onDateChange(data, isStartDate = true) {
    if (isStartDate) {
      if (data.rawDate != this.context.strataMaintenanceFee.maintenanceStartDate) {
        this.context.strataMaintenanceFee.maintenanceStartDate = data.rawDate;
      }
    } else if (data.rawDate != this.context.strataMaintenanceFee.maintenanceEndDate) {
      this.context.strataMaintenanceFee.maintenanceEndDate = data.rawDate;
    }
  }

  getNoOfDays() {
    let dateFormat = 'YYYY/MM/DD';
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    let diffDays = -1;
    if (this.context?.matterCloseDate && pattern.test(this.context?.matterCloseDate)) {
      let date = moment(this.context.matterCloseDate, dateFormat);
      if (
        this.context?.strataMaintenanceFee?.isMaintenanceFeePaid &&
        this.context?.strataMaintenanceFee?.isMaintenanceFeePaid == 'YES' &&
        this.context?.strataMaintenanceFee?.maintenanceEndDate
      ) {
        let endDate = moment(this.context?.strataMaintenanceFee?.maintenanceEndDate, dateFormat);
        diffDays = endDate.diff(date, 'days') + 1;
      }
      if (
        this.context?.strataMaintenanceFee?.isMaintenanceFeePaid &&
        this.context?.strataMaintenanceFee?.isMaintenanceFeePaid == 'NO' &&
        this.context?.strataMaintenanceFee?.maintenanceStartDate
      ) {
        let startDate = moment(this.context?.strataMaintenanceFee?.maintenanceStartDate, dateFormat);
        diffDays = date.diff(startDate, 'days');
      }
    }
    return diffDays > -1 ? diffDays : '????';
  }

  get sellerShare() {
    let nde: number = this.getNumberOfDaysElapsed(
      this.context?.strataMaintenanceFee?.maintenanceStartDate,
      this.context?.matterCloseDate
    );
    if (this.paymentPeriodInDays > 0 && nde > 0 && this.paymentPeriodInDays >= nde) {
      let paymentPeriodInDays: number = this.paymentPeriodInDays;
      return this.currencyPipe.transform(this.amountForEachDay * nde, 'CAD', 'symbol-narrow', '1.2-2');
    } else {
      return '?????.??';
    }
  }

  get buyerShare() {
    let nde: number = this.getNumberOfDaysElapsed(
      this.context?.matterCloseDate,
      this.context?.strataMaintenanceFee?.maintenanceEndDate
    );
    if (this.paymentPeriodInDays > 0 && nde > 0 && this.paymentPeriodInDays >= nde) {
      let paymentPeriodInDays: number = this.paymentPeriodInDays;
      return this.currencyPipe.transform(this.amountForEachDay * nde, 'CAD', 'symbol-narrow', '1.2-2');
    } else {
      return '?????.??';
    }
  }

  get paymentPeriodInDays(): number {
    if (
      this.isNotValidDate(this.context?.strataMaintenanceFee?.maintenanceStartDate) ||
      this.isNotValidDate(this.context?.strataMaintenanceFee?.maintenanceEndDate)
    ) {
      return 0;
    } else {
      return (
        this.getDateDiff(
          this.context?.strataMaintenanceFee?.maintenanceStartDate,
          this.context?.strataMaintenanceFee?.maintenanceEndDate
        ) + 1
      );
    }
  }

  getNumberOfDaysElapsed(fromDate: string, closingDate: string): number {
    if (this.isNotValidDate(fromDate) || this.isNotValidDate(closingDate)) {
      return 0;
    }
    let nde: number = this.getDateDiff(fromDate, closingDate);
    return nde;
  }

  getDateDiff(from: string, to: string): number {
    return Utils.getDateDiff(from, to);
  }

  isNotValidDate(dt: string): boolean {
    return Utils.isNotValidDate(dt);
  }

  get amountForEachDay() {
    let numberOfDays = this.paymentPeriodInDays;
    if (numberOfDays > 0) {
      return this.context?.strataMaintenanceFee?.strataMaintenanceAmount / numberOfDays;
    }
    return 0;
  }

  checkForErrors() {
    if (this.context.strataMaintenanceFee.strataMaintenanceAmount < 0) {
      this.modalErrorComponent.createDPFieldError('matter.adjustment-type.strata-maintenance-fee.maintenance-amount');
    }
    if (this.isNotValidDate(this.context.strataMaintenanceFee.maintenanceStartDate)) {
      this.modalErrorComponent.createDPFieldError('matter.adjustment-type.strata-maintenance-fee.start-date');
    }
    if (this.isNotValidDate(this.context.strataMaintenanceFee.maintenanceEndDate)) {
      this.modalErrorComponent.createDPFieldError('matter.adjustment-type.strata-maintenance-fee.end-date');
    }
    if (this.getDateDiff(this.context.strataMaintenanceFee.maintenanceStartDate, this.context.matterCloseDate) < 0) {
      this.modalErrorComponent.createDPFieldError(
        this.context?.matterType == 'MORTGAGE'
          ? 'matter.adjustment-type.strata-maintenance-fee.start-date.greater.cls'
          : 'matter.adjustment-type.strata-maintenance-fee.start-date.greater.adj'
      );
    }
    if (this.getDateDiff(this.context.matterCloseDate, this.context.strataMaintenanceFee.maintenanceEndDate) < 0) {
      this.modalErrorComponent.createDPFieldError(
        this.context?.matterType == 'MORTGAGE'
          ? 'matter.adjustment-type.strata-maintenance-fee.end-date.less.cls'
          : 'matter.adjustment-type.strata-maintenance-fee.end-date.less.adj'
      );
    }
    if (
      this.getDateDiff(
        this.context.strataMaintenanceFee.maintenanceStartDate,
        this.context.strataMaintenanceFee.maintenanceEndDate
      ) <= 0
    ) {
      this.modalErrorComponent.createDPFieldError('matter.adjustment-type.strata-maintenance-fee.date.greater.expiry');
    }
  }
}
