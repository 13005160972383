<form class="form-horizontal" id="addInstrumentForm" #addInstrumentForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-10">
                <h1>Legal Description</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>

        <div class="row modal-body">
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-2" for="titleNumber">Title Number {{context.order}}</label>
                <div class="col-lg-4">
                    <input type="text" class="form-control" name="titleNumber" id="titleNumber" maxlength="20"
                           dp-error-validator
                           fieldKey="matter.propertyTeranet.titleRollNumber.titleNumber"
                           [(ngModel)]="titleRollNumber.parcelNumber"/>
                </div>
            </div>

            <div *ngFor="let item of localRollNumbers; let i=index;trackBy:trackByIdx;">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="rollNumber-{{i}}-{{rollNumberCurrentIndex}}">
                        Roll Number {{i+1}}
                    </label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control"
                               name="rollNumber-{{i}}-{{rollNumberCurrentIndex}}"
                               id="rollNumber-{{i}}-{{rollNumberCurrentIndex}}"
                               maxlength="20"
                               dp-error-validator
                               [fieldKey]="'matter.propertyTeranet.titleRollNumber.rollNumber.' + (i)"
                               [(ngModel)]="item.rollNumber"/>
                        <div *ngIf="i > 0" class="actionbtns">
                            <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeRollNumber(i);" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 offset-lg-2 padding-top-10 padding-bottom-10" >
                <span>
                    <a href="javascript:void(0);" (click)="addRollNumber()">
                        Add Another Roll
                    </a>
                </span>
                <span class="selector fa fa-plus-square-o"></span>
            </div>
            <div *ngIf="!isPropertyCondo()" class="form-group col-lg-12">
                <textarea rows="10"
                          [dp-read-only]="isTitleImported(titleRollNumber)"
                          class="form-control"
                          id="memo" name="memo"
                          [(ngModel)]="titleRollNumber.memo"></textarea>
            </div>
            <ng-container *ngIf="isPropertyCondo()">
                <div  class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="titleNumber">Unit Number</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="unitNumber" id="unitNumber" maxlength="5"
                               dp-error-validator
                               fieldKey="matter.propertyTeranet.titleRollNumber.unitNumber"
                               [(ngModel)]="titleRollNumber.unitNumber"/>
                    </div>
                    <label class="control-label col-lg-2" for="titleNumber">Unit Type</label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="unitType"
                                name="unitType"
                                [(ngModel)]="titleRollNumber.unitType"
                                >
                            <option *ngFor="let unitType of condoUnitTypes" [value]="unitType.value">
                                {{unitType.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div  class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="titleNumber">Percentage Interest</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="percentageInterest" id="percentageInterest"
                               dp-error-validator
                               fieldKey="matter.propertyTeranet.titleRollNumber.percentageInterest"
                               dp-percentage
                               [max-decimals]=6
                               [(ngModel)]="titleRollNumber.percentageInterest"/>
                    </div>
                </div>
                <div  class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="titleNumber">Common Element Fees</label>
                    <div class="col-lg-4">
                        <input type="text" class="form-control" name="commonElementFees" id="commonElementFees"
                               dp-error-validator
                               fieldKey="matter.propertyTeranet.titleRollNumber.commonElementFees"
                               significant-currency-digits=6
                               dp-currency
                               [(ngModel)]="titleRollNumber.commonElementFees"/>
                        <button type="button"  *ngIf="context.statementAdjustment"
                                id="editCommonElementFeesBtn"
                                tabIndex="-1"
                                (click)="clickCommonElementFeesEditBtn()" class="button-glyph">
                            <span class="glyphicon glyphicon-edit"></span>
                        </button>
                    </div>
                </div>
            </ng-container>


        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="ok()"
                        class="dp-btn">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

        <div class="col-xs-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>
</form>
