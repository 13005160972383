import {DocumentStopCode} from './document-stop-code';
import {DocumentTypeType} from './document';
import {MortgageType} from '../shared/mortgage';
import {DepositPrintFormatType} from '../shared/deposit';

export class TemplateStopCode {
  templateName: string;
  fileName: string;
  templateId: number;
  mortgageIndex: number;
  affidavitIndex: number;
  holdbackIndex: number;
  lenderDocument: boolean;
  customTemplateName: string;
  stopCodes: Array<DocumentStopCode> = [];
  requestedFileTypes: Array<DocumentTypeType> = [];
  isForm: boolean = false;
  mortgageType: MortgageType;
  currentAdjustmentDepositId: number;
  adjustmentDepositPrintFormat: DepositPrintFormatType;

  currentMatterSoaSheetId: number;

  constructor(templateStopCodes?: TemplateStopCode) {

    if (templateStopCodes) {
      for (let prop in templateStopCodes) {
        if (templateStopCodes.hasOwnProperty(prop)) {
          this[ prop ] = templateStopCodes[ prop ];
        }
      }

      this.stopCodes = [];

      if (templateStopCodes.stopCodes) {
        for (let sc of templateStopCodes.stopCodes) {
          this.stopCodes.push(new DocumentStopCode(sc));
        }
      }
    }
  }

}
