import {BaseEntity} from '../BaseEntity/base-entity';

export class SpecialComment extends BaseEntity {

  customer: any;

  customPickListItemSequence: number;

  customPickListItemValue: string;

  customPickListTypeCode: string;

  id: number;

  defaultType: boolean;
}
