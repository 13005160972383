<div tabindex="0">
    <div class="col-lg-12">
        <div>
            <form #trustLedgerForm="ngForm"
                  role="form" class="form-horizontal">
                <div tabindex="0">
                    <div class="fixed-header-statement-adjustments fixed-element-for-scrolling margin-top-minus-8" [ngClass]="isInterestRateOnDeferredPurchaseMoniesVisible() && isForm4AddressedToFieldVisible() ? 'margin-top-25' : ''">
                        <dp-soa-top-section
                                (addNewBtnFocus)="addNewBtnFocus($event)"
                        ></dp-soa-top-section>
                    </div>
                    <dp-soa-adjustment-table
                            [activeIndexFromTabKey]="activeIndexFromTabKey"
                            (setActiveIndex)="setActiveIndex($event)"
                            (addNewBtnFocus)="addNewBtnFocus($event)"
                    ></dp-soa-adjustment-table>
                    <br>
                    <dp-user-defined-fields [topicName]="'STATEMENT_OF_ADJUSTMENT'" (onChange)="enableSave()">
                    </dp-user-defined-fields>
                </div>
            </form>
        </div>
    </div>
</div>
<div>
    <span [title]="'Linked adjustment'"><img src="/assets/img/chain.png"/></span> <label class="control-label"> = Indicates the adjustment may also be accessed from another Tab</label>
</div>
