import {HttpClient} from '../../core';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {DocumentCost, DocumentCostResponse} from './document-cost.type';
import {documentCostApi} from './document-cost.constants';
import {map} from 'rxjs/operators';
import {currentMatter} from '../../matters/shared/current-matter';

@Injectable()
export class DocumentCostService {
  constructor(
    private http: HttpClient
  ) {
  }

    get matter() {
      return currentMatter.value
    }

  getDocumentCosts(): Observable<{ [ key: string ]: DocumentCost }> {
    return this.http.get(documentCostApi.documentCosts).pipe(
      map((documentCosts: DocumentCostResponse[]) => this.getDocumentCostObject(documentCosts))
    );
  }

  private getDocumentCostObject(documentCosts: DocumentCostResponse[]): { [ key: string ]: DocumentCost } {
    const documentCostObject: { [ key: string ]: DocumentCost } = {};

    documentCosts.forEach(cost => {
      const newCost: DocumentCost = {
        code: cost.code,
        type: cost.description,
        cost: cost.cost,
        gstApplicable: cost.gstApplicable,
        totalCost: cost.gstApplicable ? (cost.cost + (this.matter.matterFederalHst/100 * cost.cost)) : cost.cost
      };

      // Make codes meaningful here
      switch (cost.code) {
        case 'CABC_LTSA2':
          documentCostObject.charge = newCost;
          break;
        case 'CABC_LTSA7':
          documentCostObject.ltsaServiceFee = newCost;
          break;
        case 'CABC_LTSA12':
          documentCostObject.plan = newCost;
          break;
        default:
          documentCostObject[ cost.code ] = newCost;
          break;
      }
    });

    return documentCostObject;
  }
}
