import {MatterTab} from '../../matters/matter-tab';
import {Matter} from '../../matters';

export class WizardTab extends MatterTab {

  static createTab(): WizardTab {
    let tab: WizardTab = new WizardTab();
    tab.matter = new Matter();
    //tab.tabType = 'matter';
    tab.tabSubType = 'wizard';
    tab.tabType = 'wizard';
    tab.tabDisplayLabel = ' Discharge Wizard';
    tab.route = 'main/tabs/wizard';
    return tab;
  }

}
