import {Component, Inject} from '@angular/core';
import {Form54} from '../../shared/form54';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class Form54ModalContext {
  form54: Form54;
  purchasePrice: number;
  year: number;
}

@Component({
  selector: 'dp-form-54',
  templateUrl: 'form-54.modal.component.html'
})
export class Form54ModalComponent extends ModalComponent<Form54ModalContext> {
  form54: Form54;
  signedByOptions: SelectItem[] = [
    {label: 'Solicitor', value: 'SOLICITOR'},
    {label: 'Client', value: 'CLIENT'}
  ];
  endOfYearOptions: SelectItem[] = [];

  constructor(
    public dialog: MatDialogRef<Form54ModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: Form54ModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    //create a copy of form54 in case of cancellation
    this.form54 = new Form54(this.context.form54);

    this.endOfYearOptions.push({label: this.context.year - 1 + ' (the previous calendar year)', value: 'PREVIOUS'});
    this.endOfYearOptions.push({label: this.context.year + ' (the current calendar year)', value: 'CURRENT'});
  }

  onOk(): void {
    this.dialog.close({action: 'OK', updatedForm54: this.form54});
  }

  close(): void {
    this.dialog.close({action: 'Cancel'});
  }
}
