export const referralDropDowns = {
  provinceOptions: [
    {label: '', value: ''},
    {label: 'Alberta', value: 'AB'},
    {label: 'British Columbia', value: 'BC'},
    {label: 'Manitoba', value: 'MB'},
    {label: 'Newfoundland and Labrador', value: 'NL'},
    {label: 'New Brunswick', value: 'NB'},
    {label: 'Northwest Territories', value: 'NT'},
    {label: 'Nova Scotia', value: 'NS'},
    {label: 'Nunavut', value: 'NU'},
    {label: 'Ontario', value: 'ON'},
    {label: 'Prince Edward Island', value: 'PE'},
    {label: 'Quebec', value: 'QC'},
    {label: 'Saskatchewan', value: 'SK'},
    {label: 'Yukon', value: 'YT'}
  ],

  purchaseOfOptions: [
    {label: '', value: ''},
    {label: 'New Home', value: 'NEW_HOME'},
    {label: 'Resale', value: 'RESALE'}
  ],

  mortgageOptions: [
    {label: '', value: ''},
    {label: 'Add new mortgage', value: 'New'}
  ]
};
