<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" *ngIf="considerationPaidOnInterimClosing" >
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Adjustment Type : Additional Consideration Paid on Interim Closing</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-4  vertical-align-label">
                        <label class="control-label">Format of Adjustment</label>
                    </div>
                    <div class="col-lg-5">
                        <select type="text"
                                id="adjustmentFormat"
                                name="adjustmentFormat"
                                class="form-control"
                                [(ngModel)]="considerationPaidOnInterimClosing.adjustmentFormat"
                        >
                            <option *ngFor="let adjustmentFormat of adjustmentFormatOptions" [value]="adjustmentFormat.value">
                                {{adjustmentFormat.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-4 vertical-align-label">
                        <label class="control-label">Adjustment heading</label>
                    </div>
                    <div class="col-lg-5">
                        <input type="text"
                               name="adjustmentHeading"
                               id="adjustmentHeading"
                               maxlength="38"
                               [(ngModel)]="considerationPaidOnInterimClosing.adjustmentHeading"
                               trimmed
                               class="form-control"
                               dp-error-validator
                               [fieldKey]="'matter.soadj.consideration.paid.on.interim.closing.heading'"
                        />
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-4 vertical-align-label" >
                        <label class="control-label">Additional consideration eligible for {{getLabelTitle()}} Rebate<sup>1</sup></label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                               name="eligibleForHSTRebate"
                               id="eligibleForHSTRebate"
                               dp-currency
                               [(ngModel)]="eligibleForHSTRebateAmount"
                               [dp-read-only]="true"
                               class="form-control"
                        />
                    </div>
                    <label >
                        <span class="info-icon"><i class="fa fa-info-circle tarion-info-circle" aria-hidden="true"></i></span>
                        <span class="matter-locked-message">
                                    <div  class="col-lg-12">
                                        <table class="standard-table">
                                            <thead>
                                                <th class="width-70-percent"><b>ADJUSTMENT</b></th>
                                                <th class="width-30-percent"><b>AMOUNT</b></th>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let t of eligibleForHSTRebateItems">
                                                <td>{{t.title}}<sup>{{t.noteReferenceMark}}</sup></td>
                                                <td>{{t.value}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                    </label>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-4 vertical-align-label" >
                        <label class="control-label">Additional consideration not eligible for {{getLabelTitle()}} Rebate<sup>2</sup></label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                               name="notEligibleForHSTRebate"
                               id="notEligibleForHSTRebate"
                               [(ngModel)]="notEligibleForHSTRebateAmount"
                               dp-currency
                               [dp-read-only]="true"
                               class="form-control"
                        />
                    </div>
                    <label >
                        <span class="info-icon"><i class="fa fa-info-circle tarion-info-circle" aria-hidden="true"></i></span>
                        <span class="matter-locked-message">
                            <div  class="col-lg-12">
                                <table class="standard-table">
                                    <thead>
                                        <th class="width-70-percent"><b>ADJUSTMENT</b></th>
                                        <th class="width-30-percent"><b>AMOUNT</b></th>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let t of notEligibleForHSTRebateItems">
                                        <td>{{t.title}}<sup>{{t.noteReferenceMark}}</sup></td>
                                        <td>{{t.value}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </span>
                    </label>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12" *ngIf="!isProvinceAB()">
                    <div class="col-lg-4 vertical-align-label" >
                        <label class="control-label">Additional consideration not eligible for HST Rebate - incl. HST<sup>3</sup></label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                               name="notEligibleForHSTRebateInclHST"
                               id="notEligibleForHSTRebateInclHST"
                               [(ngModel)]="notEligibleForHSTRebateInclHSTAmount"
                               dp-currency
                               [dp-read-only]="true"
                               class="form-control"
                        />
                    </div>
                    <label>
                        <span class="info-icon"><i class="fa fa-info-circle tarion-info-circle" aria-hidden="true"></i></span>
                        <span class="matter-locked-message">
                            <div  class="col-lg-12">
                                <table class="standard-table">
                                    <thead>
                                        <th class="width-70-percent"><b>ADJUSTMENT</b></th>
                                        <th class="width-30-percent"><b>AMOUNT</b></th>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let t of notEligibleForHSTRebateInclHSTItems">
                                        <td>{{t.title}}<sup>{{t.noteReferenceMark}}</sup></td>
                                        <td>{{t.value}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </span>
                    </label>
                </div>
            </div>
            <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <label class="control-label"
                        >{{considerationPaidOnInterimClosing.adjustmentHeading}}</label>
                    </div>
                </div>

                <div class="col-lg-12  form-group" >
                    <div class="col-lg-4">
                        <label class="control-label"
                        >Credit Purchaser</label>
                    </div>
                    <div class="col-lg-6">
                        <label class="control-label col-lg-4 text-right">
                            {{getCreditPurchaserAmount() |  currency:CAD:'symbol' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>

            </div>
            <div class="col-lg-2">
                <dp-checkbox fieldId="checkBoxInfoOnly">
                    <input type="checkbox"
                           id="checkBoxInfoOnly"
                           name="checkBoxInfoOnly"
                           [(ngModel)]="considerationPaidOnInterimClosing.infoOnly"
                           [checked]="considerationPaidOnInterimClosing.infoOnly"/>
                </dp-checkbox>

                <label class="control-label vertical-align-label display-inline" >Info Only</label>
            </div>
        </div>
        <div style="padding: 5px 10px 0px 5px;">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </form>
</div>
