import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {currentMatter, Matter, MatterProperty, Utils as MoreUtils} from '../../shared';
import Utils from '../../../shared-main/utils';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {ProjectTab} from '../../../projects/shared/project-tab';
import {ApplicationError, TabsService} from '../../../core';
import {ProgressionStatus, StatementAdjustment, StatementAdjustmentOrder} from '../statement-adjustment';
import {MatterTax} from '../../shared/property-taxes';
import {ItemizedCreditAdjustmentMessage, StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../statement-adjustment-constants';
import {SoaExpenseAdjustmentUtil} from '../modals/reserve-fund/soa-expense-adjustment-util';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {StatementAdjustmentUtil} from '../statement-adjustment-util';
import {StatementOfAdjustmentDisplay} from '../../shared/statement-of-adjustment-display';
import {AuthZService} from '../../../core/authz/auth-z.service';
import {Project} from '../../../projects/shared/project';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SoajFieldCodeService} from '../../../shared-main/soaj-field-code.service';
import {StatementAdjustmentDisplayBalanceItem, StatementAdjustmentDisplayItem, StatementAdjustmentPreview} from '../model';
import {StatementAdjustmentDisplayBuilder} from '../builders/statement-adjustment-display-builder';
import moment from 'moment/moment';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {InterestRate} from '../model/interest-rate';
import {TaxRateService} from '../../consideration-ltt/tax-rate.service';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {MatterService} from '../../matter.service';
import {TemplateCodeService} from '../../template.code.service';
import {projectConsts, StatementAdjParameter} from '../../../projects/shared/project-consts';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import * as _ from 'lodash';
import {DPError} from '../../../shared/error-handling/dp-error';
import {ModalResult} from '../../../shared-main/enums';
import {SoaExpenseAdjustment} from '../modals/reserve-fund/soa-expense-adjustment';
import {BrokerCommissionComponent} from '../../broker-commission/broker-commission.component';
import {ItemizedCreditToVendorPurchaserONStrategy} from '../sale-price-adjustment/itemized-credit-to-vendor-purchaser-on-strategy';
import {ItemizedCreditToVendorPurchaserABStrategy} from '../sale-price-adjustment/itemized-credit-to-vendor-purchaser-ab-strategy';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {StatementAdjustmentConfig} from '../../../admin/statement-adjustment/statement-adjustment-config';
import {soAdjProjectFooters} from '../model/so-adj-drop-downs';
import {ConsiderationLtt} from '../../consideration-ltt/consideration-ltt';
import {StatementConfigService} from '../../../admin/shared/statement-config.service';
import {DepositAdjustmentCalculationUtil} from '../deposit-adjustment-calculation-util';
import {SalePriceAdjustmentFactory} from '../sale-price-adjustment/sale-price-adjustment-factory';
import {MatterExtraDepositConfig} from '../../shared/matter-extra-deposit-config';
import {Deposit} from '../../shared/deposit';
import {TrustAccountsService} from '../../../admin/trust-account/trust-accounts.service';
import {ManitobaLTTTier} from '../../consideration-ltt/manitoba-ltt-tier';
import {DocumentTypeType} from '../../document-production/document';
import {nonProjectSaleAdjustmentFooterMessages} from '../../../shared-main/constants';
import {DpProjectShowByProvince} from '../../../projects/shared/dp-project-show-by-province';
import {UUIDUtil} from '../../../main/uuid-util';

declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class SoaStateUtil {

  _matter: Matter;
  _componentMatterInstance: Matter;
  _project: Project;
  closingDate: string = '';
  lastActiveRowId: string;

  ignoreMassUpdateContext: boolean = false;
  initNewProjectSaleMatter: boolean = false;
  ontarioTaxRateSlab: ConsiderationTaxes;
  torontoTaxRateSlab: ConsiderationTaxes;
  soaConsiderationTaxesFinal: ConsiderationTaxes
  soaConsiderationTaxesInterim: ConsiderationTaxes
  taxRateSlabs: ConsiderationTaxes[];
  paperSizeCode: string;

  componentAdjTemplateCodeCall: any;
  _manitobaLTTTiers: ManitobaLTTTier[] = [];
  private _soaConsiderationTaxes: ConsiderationTaxes;

  private matterDocumentProfileSource = new BehaviorSubject<DocumentProfile>(null);
  matterDocumentProfile$ = this.matterDocumentProfileSource.asObservable();

  private statementAdjustmentDisplayUtilSource = new BehaviorSubject<StatementAdjustmentDisplayBuilder>(null);
  statementAdjustmentDisplayUtil$ = this.statementAdjustmentDisplayUtilSource.asObservable();

  private projectTemplateMatterSource = new BehaviorSubject<Matter>(null);
  projectTemplateMatter$ = this.projectTemplateMatterSource.asObservable();

  private brokerCommissionComponentSource = new BehaviorSubject<BrokerCommissionComponent>(null);
  brokerCommissionComponent$ = this.brokerCommissionComponentSource.asObservable();

  private activeTabSource = new BehaviorSubject<string>(null);
  activeTab$ = this.activeTabSource.asObservable();

  private triggerUpdateSubject = new BehaviorSubject<number>(0);
  triggerUpdate$ = this.triggerUpdateSubject.asObservable();

  private updateAddNewButtonOptionsSubject = new BehaviorSubject<string>(null);
  updateAddNewButtonOptions$ = this.updateAddNewButtonOptionsSubject.asObservable()

  constructor(
    public currencyPipe: CurrencyPipe,
    public tabService: TabsService,
    public authZService: AuthZService,
    public dialogService: DialogService,
    public soajFieldCodeService: SoajFieldCodeService,
    public taxRateService: TaxRateService,
    public errorService: ErrorService,
    public matterService: MatterService,
    public templateCodeService: TemplateCodeService,
    public decimalPipe: DecimalPipe,
    public percentPipe: PercentPipe,
    public documentProfileCache: DocumentProfileCache,
    public documentProfileService: DocumentProfileService,
    public soaConfigService: StatementConfigService,
    private trustAccountsService: TrustAccountsService
  ) {
  }

  ngOnInit() {
  }

  get matter(): Matter {
    if (this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject() && !(this.tabService.activeTab as ProjectTab).matter) {
      // we are creating dummy matter on project Tab so that getter which get invoked due to router events or unsubcription
      // works with dummy matter and do not throw error
      (this.tabService.activeTab as ProjectTab).matter = new Matter();
    }
    let matter = this._matter ? this._matter : (this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject() ?
      (this.tabService.activeTab as ProjectTab).matter : currentMatter.value);

    // we are creating component matter instance varaiable and storing copy of it and whenever matter changes we need
    // to invoke setup and for that we compare refernce and if they are different we are invoking setup.
    if (matter) {
      if (this._componentMatterInstance && this._componentMatterInstance != matter) {
        this._componentMatterInstance = matter;
        matter.updateStatusMode(matter.selectedProgressionStatus);
        this.triggerUpdateSubject.next(this.triggerUpdateSubject.getValue() + 1);
      } else if (!this._componentMatterInstance) {
        this._componentMatterInstance = matter;
        this.triggerUpdateSubject.next(this.triggerUpdateSubject.getValue() + 1);
      }
    }
    return matter;
  }

  get statementAdjustmentDisplayUtil(): StatementAdjustmentDisplayBuilder {
    return this.statementAdjustmentDisplayUtilSource.getValue();
  }

  setStatementAdjustmentDisplayUtil(statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder) {
    this.statementAdjustmentDisplayUtilSource.next(statementAdjustmentDisplayUtil);
  }

  setSoaConsiderationTaxesFinal(soaConsiderationTaxesFinal: ConsiderationTaxes) {
    this.soaConsiderationTaxesFinal = soaConsiderationTaxesFinal;
  }

  setSoaConsiderationTaxesInterim(soaConsiderationTaxesInterim: ConsiderationTaxes) {
    this.soaConsiderationTaxesInterim = soaConsiderationTaxesInterim;
  }

  get projectTemplateMatter(): Matter {
    return this.projectTemplateMatterSource.getValue();
  }

  setProjectTemplateMatter(projectTemplateMatter: Matter) {
    this.projectTemplateMatterSource.next(projectTemplateMatter);
  }

  get isMassUpdate(): boolean {
    return !this.ignoreMassUpdateContext && this.tabService.activeTab && this.tabService.activeTab.isMassUpdateSubType();
  }

  setIgnoreMassUpdateContext(ignoreMassUpdateContext: boolean) {
    this.ignoreMassUpdateContext = ignoreMassUpdateContext;
  }

  setInitNewProjectSaleMatter(initNewProjectSaleMatter: boolean) {
    this.initNewProjectSaleMatter = initNewProjectSaleMatter;
  }

  setOntarioTaxRateSlab(ontarioTaxRateSlab: ConsiderationTaxes) {
    this.ontarioTaxRateSlab = ontarioTaxRateSlab;
  }

  setTorontoTaxRateSlab(torontoTaxRateSlab: ConsiderationTaxes) {
    this.torontoTaxRateSlab = torontoTaxRateSlab;
  }

  setTaxRateSlabs(taxRateSlabs: ConsiderationTaxes[]) {
    this.taxRateSlabs = taxRateSlabs;
  }

  get brokerCommissionComponent(): BrokerCommissionComponent {
    return this.brokerCommissionComponentSource.getValue();
  }

  setBrokerCommissionComponent(brokerCommissionComponent: BrokerCommissionComponent) {
    this.brokerCommissionComponentSource.next(brokerCommissionComponent);
  }

  setPaperSizeCode(paperSizeCode: string) {
    this.paperSizeCode = paperSizeCode;
  }

  get activeTab(): string {
    return this.activeTabSource.getValue();
  }

  setActiveTab(activeTab: string) {
    this.activeTabSource.next(activeTab);
  }

  get purchaserLabel(): string {
    return (this.matter && this.matter.statementOfAdjustmentHeading ? this.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo : '');
  }

  get vendorLabel(): string {
    return (this.matter && this.matter.statementOfAdjustmentHeading ? this.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo : '');
  }

  get isProjectHstReductionInSalePriceAdjustment(): boolean {
    return this.project && this.project.projectAdjustmentConfig && this.project.projectAdjustmentConfig.hstReductionInSalePriceAdjustment;
  }

  get soaConsiderationTaxes(): ConsiderationTaxes {
    const soaConsiderationTaxes = this.matter.isAdjustmentStatusModeFinal ? this.soaConsiderationTaxesFinal : this.soaConsiderationTaxesInterim;
    if (!_.isEqual(this._soaConsiderationTaxes, soaConsiderationTaxes)) {
      this._soaConsiderationTaxes = soaConsiderationTaxes;
      this.triggerUpdateSubject.next(this.triggerUpdateSubject.getValue() + 1);
    }
    return soaConsiderationTaxes;
  }

  setSoaConsiderationTaxes(considerationTaxes: ConsiderationTaxes) {
    this.matter.isAdjustmentStatusModeFinal ?
      this.setSoaConsiderationTaxesFinal(considerationTaxes)
      : this.setSoaConsiderationTaxesInterim(considerationTaxes);
  }

  get project(): Project {
    return this._project ? this._project : this.isProjectSale ? this.matter.project : (this.isProjectConfigTab && (this.tabService.activeTab as ProjectTab).project);
  }

  get isProjectSale(): boolean {
    return (this.matter && this.matter.isProjectSale);
  }

  get isProjectConfigTab(): boolean {
    return (this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject());
  }

  get isProjectOrProjectSale(): boolean {
    return this.isProjectSale || this.isProjectConfigTab;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  clearLocalInstanceMatter() {
    this._matter = null;
  }

  setLocalInstanceProject(project: Project) {
    this._project = project;
  }

  clearLocalInstanceProject() {
    this._project = null;
  }

  get matterDocumentProfile(): DocumentProfile {
    return this.matterDocumentProfileSource.getValue();
  }

  setMatterDocumentProfile(matterDocumentProfile: DocumentProfile) {
    this.matterDocumentProfileSource.next(matterDocumentProfile);
  }

  setLastActiveRowId(lastActiveRowId: string) {
    this.lastActiveRowId = lastActiveRowId;
  }

  get adjustmentDate(): string {
    if (this.matter.isMatterProvinceBC) {
      return this.matter.adjustAsAtClosingDate;
    }
    return this.matter.getClosingDate();
  }

  get matterEstTaxIncrease(): string {
    return this.matterDocumentProfile.statementOfAdjustmentsProfile.estTaxIncrease;
  }

  get propertyModel(): MatterProperty {
    return this.matter.matterPropertyWithCondo;
  }

  get provinceHstRate(): number {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstProvincialPortion ? this.soaConsiderationTaxes.hstProvincialPortion : 0);
  }

  get federalHstRate(): number {
    return (this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstFederalPortion ? this.soaConsiderationTaxes.hstFederalPortion : 0);
  }

  public updateHstSalePriceAdjustment(): void {
    this.matter.updateHstSalePriceAdjustment(this.provinceHstRate, this.federalHstRate);
  }

  async loadManitobaTiers(): Promise<void> {
    this._manitobaLTTTiers = await this.taxRateService.getManitobaLTTTiers().toPromise();
  }

  get manitobaLTTTiers(): ManitobaLTTTier[] {
    return this._manitobaLTTTiers;
  }

  get isPOTL(): boolean {
    return (this.matter.matterPropertyWithCondo.isParcelOfTiedLand === 'YES' || this.matter.matterPropertyWithCondo.isParcelOfTiedLand === 'Y_n');
  }

  //selectedIndex call orderedStatementDisplayItems, so it is a heavy getter
  get selectedIndex(): number {
    return _.findIndex(this.orderedStatementDisplayItems, statementAdjustmentObj => statementAdjustmentObj.soaItem && statementAdjustmentObj.soaItem.isSelected == true);
  }

  get statementOfAdjustmentDisplayBalanceItems(): StatementAdjustmentDisplayBalanceItem[] {
    return this.statementAdjustmentDisplayUtil && this.statementAdjustmentDisplayUtil.statementOfAdjustmentBalanceDisplayItems;
  }

  get isProjectConfigForInterimForMatter(): boolean {
    return (this.matter && this.matter.project && this.matter.project.isStatementOfAdjustmentInterim());
  }

  get isProjectConfigForInterim(): boolean {
    return (this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject() && (this.tabService.activeTab as ProjectTab).project
      && (this.tabService.activeTab as ProjectTab).project.isStatementOfAdjustmentInterim());
  }

  get orderedStatementDisplayItems(): StatementAdjustmentDisplayItem[] {
    if (this.matter && this.matter.isProjectSale) {
      let statementAdjustmentDisplayItems: StatementAdjustmentDisplayItem[] = [];
      let statementAdjustmentOrders = this.matter.isAdjustmentStatusModeFinal ? this.matter.finalStatementAdjustmentOrders : this.matter.interimStatementAdjustmentOrders;

      if (statementAdjustmentOrders && statementAdjustmentOrders.length > 0 && this.statementAdjustmentDisplayUtil && Array.isArray(this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems) && this.projectTemplateMatter) {
        if (this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems.some(itm => itm.soaItem.adjustmentStatus !== this.matter.adjustmentStatusMode)) {
          this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems();
        }
        statementAdjustmentOrders
        .sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0)
        .forEach(item => {
          let statementOfAdjustmentDisplayItem = this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems.find(adj => adj.soaItem && !!item.adjustmentId && !!adj.soaItem.id && adj.soaItem.id == item.adjustmentId);
          if (statementOfAdjustmentDisplayItem) {
            statementAdjustmentDisplayItems.push(statementOfAdjustmentDisplayItem);
          }
        });
        return statementAdjustmentDisplayItems;
      } else if (this.statementAdjustmentDisplayUtil && Array.isArray(this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems)) {
        return this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems;
      } else {
        return [];
      }
    } else if (this.statementAdjustmentDisplayUtil && Array.isArray(this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems)) {
      return this.statementAdjustmentDisplayUtil.statementAdjustmentDisplayItems;
    } else {
      return [];
    }
  }

  getProjectTemplateMatter(projectTemplateMatterId: number, callback?: Function) {
    this.matterService.getMatter(this.matter.project.templateMatterId)
    .subscribe((templateMatter) => {
      if (templateMatter) {
        this.setProjectTemplateMatter(new Matter(templateMatter));
        if (callback) {
          callback();
        }

      }
    }, (error: ApplicationError) => {
      console.log(error);
    });
  }

  isAdjustDateNotValid(): boolean {
    if ((this.matter.isProjectSale || this.matter.templateForProject) && (this.matter.isCondoCorporation || this.matter.isPropertyParcelOfTiedLand())) {
      return !this.isInterimOccupancyDateValid() || !this.isFinalClosingDateValid();
    } else {
      let closingDate = this.adjustmentDate;
      let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
      return (!closingDate || (closingDate && !pattern.test(closingDate)));
    }
  }

  isValidDate(dateValue: string): boolean {
    return Utils.isValidDate(dateValue);
  }

  isInterimOccupancyDateValid(): boolean {
    return !this.matter.isAdjustmentStatusModeFinal ?
      this.isValidDate(this.matter.getInterimOccupancyDate()) :
      true;
  }

  isFinalClosingDateValid(): boolean {
    return this.matter.isAdjustmentStatusModeFinal ?
      this.isValidDate(this.matter.getFinalClosingDate()) :
      true;
  }

  updateMatterPropertyTax(propertyTax: MatterTax, statementAdjustment: StatementAdjustment) {

    if (propertyTax && statementAdjustment.isOriginPropertyTaxes()) {
      this.propertyModel.updatePropertyTaxes(propertyTax, this.matter.formattedAllTaxesAmount(propertyTax), this.matter.formattedTrustAmount(propertyTax), this.matter.provinceCode);
    } else {
      this.propertyModel.resetPropertyTaxes();
      this.removeStatementAdjustment(statementAdjustment, true);
    }
  }

  async enableSave(doNotUpdateLinkedAdjustment?: boolean): Promise<void> {
    await this.updateDisplayItems(doNotUpdateLinkedAdjustment);
    this.setDirtyFlag(true);
  }

  async updateDisplayItems(ignoreOutOfRangeWarnings?: boolean): Promise<void> {

    //Evaluating the value of any template codes used in matter
    this.updateComponentAdjTemplateCodes();

    if (this.project && this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment) {
      this.matter.considerationLtt.salePriceAdjustment.isProjectHstReductionInSalePriceAdjustment = this.isProjectHstReductionInSalePriceAdjustment;
    }
    this.statementAdjustmentDisplayUtil.setSoaConsiderationTaxes(this.soaConsiderationTaxes);
    await this.loadInterestRates(this.matter);

    this.statementAdjustmentDisplayUtil.setMatter(this.matter); // ToDo this should not be required
    this.statementAdjustmentDisplayUtil.matterDocumentProfile = this.matterDocumentProfile;
    // Before update soa display table, we need to update data firstly
    StatementAdjustmentUtil.updateInterestOnDepositUnderOldCondo(this.matter);
    this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems();
    if (!ignoreOutOfRangeWarnings) {
      this.checkOutOfRangeWarning();
    }
    this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayBalanceItems();
    if (!this.matter.statementOfAdjustmentDisplay) {
      this.matter.statementOfAdjustmentDisplay = new StatementOfAdjustmentDisplay();
    }

    this.matter.statementOfAdjustmentDisplay.updateData(this.statementAdjustmentDisplayUtil);
    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(this.matter);

  }

  async loadInterestRates(matter: Matter): Promise<void> {
    if (matter && Array.isArray(matter.interestRates) && matter.interestRates.length == 0) {
      this.matter.interestRates = [];
      let interestRates: InterestRate[] = await this.taxRateService.cachedInterestRates(matter.provinceCode).toPromise();
      matter.interestRates.push(...(interestRates || []));
    }
  }

  checkOutOfRangeWarning() {
    if (this.matter && !this.ignoreMassUpdateContext && !this.initNewProjectSaleMatter) {
      this.matter.checkOutOfRangeWarning(this.errorService);
    }
  }

  updateComponentAdjTemplateCodes(): void {
    let templateCodesInMatter: string[] = this.matter.getTemplateCodesUsedInMatter();
    if (templateCodesInMatter.length > 0) {
      // First we check if any timeout already set
      if (!!this.componentAdjTemplateCodeCall) {
        // if time out already set that means it coming again to evaluate
        // so we clear previous time out and push call for evaluate code on another timeout..
        clearTimeout(this.componentAdjTemplateCodeCall);
        this.componentAdjTemplateCodeCall = setTimeout(async () => {
          // we will reach here as last call and execute evaluate code
          clearTimeout(this.componentAdjTemplateCodeCall);
          this.calculateMatterTemplateCodesAndUpdateDisplayItems();
        }, 5);
      } else {
        this.componentAdjTemplateCodeCall = setTimeout(async () => {
          clearTimeout(this.componentAdjTemplateCodeCall);
          this.calculateMatterTemplateCodesAndUpdateDisplayItems();
        }, 5);
      }
    }
  }

  async calculateMatterTemplateCodesAndUpdateDisplayItems(): Promise<void> {
    await this.templateCodeService.calculateMatterTemplateCodes(this.matter);
    this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems();
    this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayBalanceItems();
    if (!this.matter.statementOfAdjustmentDisplay) {
      this.matter.statementOfAdjustmentDisplay = new StatementOfAdjustmentDisplay();
    }
    this.matter.statementOfAdjustmentDisplay.updateData(this.statementAdjustmentDisplayUtil);
    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(this.matter);
  }

  /*
	removes adjustments from matter, called from either burger menu or after modal close with ModalResult.Delete
	soAdj : adjustment to be removed
	fromModal: Modals have their own 'Are you sure you would like to delete this item?' confirmation prompt, use this to prevent duplicating of this confirmation
	removeFieldCode: by removing some adjustments an update on field codes is required
	 */
  removeStatementAdjustment(soAdj: StatementAdjustment, fromModal: boolean = false, removeFieldCode: boolean = false): void {
    const defaultConfirmMsg: string = 'Are you sure you would like to delete this item?';
    if (this.isProjectSale || this.isProjectConfigTab) {
      if (soAdj.isRealtyTax() && soAdj.matterTax && soAdj.matterTax.isPayOutOfTrustAccount0SetToYes()) {
        this.showCannotDeleteWarning();
      } else if (soAdj.isInterimEarlyPossessionFee() && !this.matter.isInterimEarlyPossessionFeeRemovable()) {
        this.dialogService.confirm('Information', 'In order to delete the "Early Possession Fees (Interim Closing)" adjustment, the "Deferred Portion of' +
          ' Purchase Price" adjustment must first be deleted.', true).subscribe();
      } else {
        let deleteConfirmMsg = this.isProjectConfigTab ? 'Are you sure you would like to delete this adjustment  \n\n' +
          'If you proceed, this adjustment will be deleted from the statement for each sale matter within the project, ' +
          'whether or not such adjustment has been applied therein. (Similarly to delete of the adjustment on the Matter)' : defaultConfirmMsg;

        if (soAdj.isReserveForVTBMtg() && this.isProjectConfigTab) {
          deleteConfirmMsg = 'Are you sure you would like to delete the reservation?  \n\n' +
            'If you proceed, this reservation will be deleted from the statement for each sale matter within the project.\n' +
            'Any existing VTB Mortgage adjustments on sale matters will not be affected.';
        }

        if (defaultConfirmMsg === deleteConfirmMsg && fromModal) { // default confirmation already prompted on modal
          this.removeAdjustmentAndUpdateMatter(soAdj, true, undefined, true);
        } else {
          this.dialogService.confirm('Confirmation', deleteConfirmMsg, false, 'Delete').subscribe(res => {
            if (res) {
              this.removeAdjustmentAndUpdateMatter(soAdj, true, undefined, true);
            }
          });
        }
      }
      if (removeFieldCode) {
        this.removeFromFieldCodeList(soAdj.fieldCode);
      }

    } else {
      if (fromModal) { // skip confirmation ...already completed on modal
        this.matter.removeStatementAdjustment(soAdj);
        this.updateStatementAdjustmentDisplayItems(this.matter);
        this.setDirtyFlag(true);
      } else {
        if (soAdj.isRealtyTax() && soAdj.matterTax && soAdj.matterTax.isPayOutOfTrustAccount0SetToYes()) {
          this.showCannotDeleteWarning();
        } else {
          this.dialogService.confirm('Confirmation', defaultConfirmMsg, false, 'Delete').subscribe(res => {
            if (res) {
              this.matter.removeStatementAdjustment(soAdj);
              this.matter.updateSalePriceConsiderationLTT(this.ontarioTaxRateSlab, this.torontoTaxRateSlab, this.soaConsiderationTaxes);
              this.updateHstSalePriceAdjustment();
              this.updateCommissionPayableToVendorBroker();
              this.updateStatementAdjustmentDisplayItems(this.matter);
              this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
              this.setDirtyFlag(true);
            }
          });
        }
      }
    }
  }

  async removeAdjustmentAndUpdateMatter(soAdj: StatementAdjustment, doNotUpdateLinkedAdjustment?: boolean, removeLinkedAdj: boolean = true, issueDeletePropagationCommand: boolean = false, doNotShowWarning: boolean = false): Promise<void> {
    await this.deleteAdjustmentAndUpdateSOADisplay(soAdj, doNotUpdateLinkedAdjustment, issueDeletePropagationCommand, doNotShowWarning);
    // Remove Linked Adjustment if Any...
    if (soAdj.linkId) {
      this.matter.adjustmentStatusMode = this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
      let linkedAdjustment = this.matter.statementOfAdjustments.find(item => soAdj.linkId && item.linkId == soAdj.linkId);
      if (linkedAdjustment && removeLinkedAdj) {
        await this.deleteAdjustmentAndUpdateSOADisplay(linkedAdjustment, doNotUpdateLinkedAdjustment, issueDeletePropagationCommand, true);
      }

      this.matter.adjustmentStatusMode = this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;

      this.updateStatementAdjustmentDisplayItems(this.matter);
    }
  }

  updateStatementAdjustmentDisplayItems(matter: Matter): void {
    // this.statementAdjustmentDisplayUtil must be updated in order to get UI refreshed.
    if (!this.statementAdjustmentDisplayUtil) {
      this.setStatementAdjustmentDisplayUtil(new StatementAdjustmentDisplayBuilder(this.decimalPipe, this.currencyPipe, this.percentPipe));
      this.statementAdjustmentDisplayUtil.setTarionWarrantyEnrolmentPeriods(this.taxRateService.getCachedTarionWarrantyEnrolmentPeriods());
      this.statementAdjustmentDisplayUtil.setHCRAFeeEnrolmentPeriods(this.taxRateService.getCachedHCRAFeeEnrolmentPeriods());

    }
    this.statementAdjustmentDisplayUtil.documentProfileCache = this.documentProfileCache;
    this.statementAdjustmentDisplayUtil.setMatter(matter);
    if (!this.isProjectConfigTab || !this.statementAdjustmentDisplayUtil.getSoaConsiderationTaxes()) {
      this.statementAdjustmentDisplayUtil.setSoaConsiderationTaxes(this.createConsiderationTaxes(matter));
    }
    this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems();
    this.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayBalanceItems();

    if (!matter.statementOfAdjustmentDisplay) {
      matter.statementOfAdjustmentDisplay = new StatementOfAdjustmentDisplay();
    }

    matter.statementOfAdjustmentDisplay.updateData(this.statementAdjustmentDisplayUtil);
  }

  createConsiderationTaxes(matter: Matter): ConsiderationTaxes {
    return this.matterService.createConsiderationTaxes(matter);
  }

  addToDataPropagationCommands(cmdType: string, cmd?: any, onlyOneNeededForSameCmdType: boolean = false, parameter?: any) {
    if (this.isProjectConfigTab) {
      let activeProjectTab = this.tabService && this.tabService.activeTab as ProjectTab;
      // We are only adding the command type for now because we are not able to add the ProjectDataPropagationService to the component
      //TODO try to add the ProjectDataPropagationService service to the component
      activeProjectTab.addToDataPropagationCommands(cmdType, cmd, onlyOneNeededForSameCmdType, parameter);
    }
  }

  updateExpenseAdjustmentsOnCommonExpenseRemove(soAdj: StatementAdjustment) {
    if (soAdj.isCommonExpenseAdjustment()) {
      let reserveFundSOAList: StatementAdjustment[] = this.matter.statementOfAdjustments.filter(adj => adj.itemKey === StatementAdjustmentKey.SALES_INCENTIVE);
      if (reserveFundSOAList) {
        reserveFundSOAList.forEach(reserveFundSOA => {
          SoaExpenseAdjustmentUtil.updateCalculations(reserveFundSOA.soaExpenseAdjustment, 0);
          this.onExpenseAdjustmentUpdate(reserveFundSOA.soaExpenseAdjustment, reserveFundSOA);
        });
      }
    }
  }

  onExpenseAdjustmentUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soaExpenseAdjustment) {
      statementAdjustment.soaExpenseAdjustment = new SoaExpenseAdjustment(result);
      statementAdjustment.amount = statementAdjustment.soaExpenseAdjustment.creditAmount;
      statementAdjustment.description = statementAdjustment.soaExpenseAdjustment.expenseType == StatementAdjustmentKey.RESERVE_FUND ? 'RESERVE FUND' :
        this.matter.isMatterProvinceON ? 'COMMON EXPENSES - SALES INCENTIVE' :
          this.matter.isMatterProvinceBC ? 'STRATA FEES - SALES INCENTIVE' : 'CONDOMINIUM FEES - SALES INCENTIVE';
      statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.isSalesIncentiveAdjustment() ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR;
    } else {
      let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      statementAdjustment.soaExpenseAdjustment = new SoaExpenseAdjustment(result);
      statementAdjustment.itemKey = statementAdjustment.soaExpenseAdjustment.expenseType;
      statementAdjustment.soaExpenseAdjustment.id = undefined;
      statementAdjustment.amount = result.creditAmount;
      statementAdjustment.description = statementAdjustment.soaExpenseAdjustment.expenseType == StatementAdjustmentKey.RESERVE_FUND ? 'RESERVE FUND' :
        this.matter.isMatterProvinceON ? 'COMMON EXPENSES - SALES INCENTIVE' :
          this.matter.isMatterProvinceBC ? 'STRATA FEES - SALES INCENTIVE' : 'CONDOMINIUM FEES - SALES INCENTIVE';
      statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.isSalesIncentiveAdjustment() ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR;
      statementAdjustment.addedFlag = true;
      this.selectedItem(statementAdjustment);
    }
  }

  async deleteAdjustmentAndUpdateSOADisplay(soAdj: StatementAdjustment, doNotUpdateLinkedAdjustment?: boolean, issueDeletePropagationCommand: boolean = false, doNotShowWarning: boolean = false): Promise<void> {

    if (soAdj.isOriginPropertyTaxes()) {

      this.propertyModel.resetPropertyTaxes();
      this.matter.removeStatementAdjustment(soAdj);
      this.removeOutOfRangeError(soAdj);
      await this.enableSave(doNotUpdateLinkedAdjustment);
      this.setFocusAfterModalClose();
      let statementAdjParameter = new StatementAdjParameter();
      statementAdjParameter.adjustmentId = soAdj.id;
      if (issueDeletePropagationCommand) {
        this.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentDelete, undefined, true, statementAdjParameter);
      }
    } else if (soAdj.canBeRemoved()) {
      this.matter.removeStatementAdjustment(soAdj);
      this.updateExpenseAdjustmentsOnCommonExpenseRemove(soAdj);
      this.updateHstSalePriceAdjustment();
      if (doNotShowWarning) {
        await this.customRemoveWithoutWarning(soAdj);
      } else {
        await this.customRemove(soAdj);
      }
      this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
      this.removeOutOfRangeError(soAdj);
      await this.enableSave(doNotUpdateLinkedAdjustment);
      this.setFocusAfterModalClose();
      let statementAdjParameter = new StatementAdjParameter();
      statementAdjParameter.adjustmentId = soAdj.id;
      if (issueDeletePropagationCommand) {
        this.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentDelete, undefined, true, statementAdjParameter);
      }
    }
    this.removeFromFieldCodeList(soAdj.fieldCode);
    if (soAdj.isInterimOccupancyFee()) {
      this.matter.recalculateAllFinalOccupancyFee();
    }
    if (soAdj.isInterimEarlyPossessionFee()) {
      this.matter.recalculateAllFinalEarlyPossessionFee();
    }
    if (this.matter && !this.matter.isProjectSale && this.matter.soaTrustLedgerCollection != null) {
      this.matter.soaTrustLedgerCollection.clearPropertyTaxPaidTrustLedgerStatementAdjustment(soAdj);
    }
    if (soAdj.isInterimOccupancyFee()) {
      StatementAdjustmentUtil.updateInterestOnDeferredMoniesDuringOccupancy(this.matter, this.project);
    }
  }

  async customRemove(soAdj: StatementAdjustment): Promise<void> {
    await this.customRemoveWarningIndicator(soAdj, false);
  }

  async customRemoveWithoutWarning(soAdj: StatementAdjustment): Promise<void> {
    await this.customRemoveWarningIndicator(soAdj, true);
  }

  async customRemoveWarningIndicator(soAdj: StatementAdjustment, doNotShowWarning: boolean): Promise<void> {
    if (soAdj && (soAdj.isOtherFixed() || soAdj.isOtherFixedPayableOnOccupancy() || soAdj.isOtherProratedOnPercentageInterest())) {
      // remove the property type error message if no other-fixed adjustment presence enforce the "purchaseIsOfCode == 'NEW_BUILDER_HOME'" rule
      if (this.errorService && !StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(this.matter.statementOfAdjustments)) {
        this.errorService.removeDpFieldError('matter.propertyteranet.soAdj.other.fixed');
      }
      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments);
      StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.matter.considerationLtt.salePriceAdjustment, this.matter.uniqueStatementAdjustments);
      await this.updateSalePriceConsiderationLTT();
      this.updateHstSalePriceAdjustment();
    }

    if (soAdj && soAdj.isTarionWarranty()) {
      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments);
      StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.matter.considerationLtt.salePriceAdjustment, this.matter.uniqueStatementAdjustments);
      await this.updateSalePriceConsiderationLTT();
    }

    if (soAdj && soAdj.isItemizedCreditToVendorPurchaser()) {
      if (!doNotShowWarning) {
        this.showWarningMessageOnItemizedAdjDelete(soAdj.soAdjItemizedCreditToVendorPurchaser);
      }
      this.updateMatterOnItemizedCreditAdjustmentDelete(this.matter.considerationLtt.salePriceAdjustment, soAdj);
      await this.updateSalePriceConsiderationLTT();

      this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
      this.updateMatterOnItemizedCreditAdjustmentDelete(this.matter.considerationLtt.salePriceAdjustment, soAdj);
      await this.updateSalePriceConsiderationLTT();

      // Reverting back mode..
      this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    }

    if (soAdj && soAdj.isCreditVendorTaxRebateAdjustment()) {
      await this.updateSalePriceConsiderationLTT();
    }
  }

  showWarningMessageOnItemizedAdjDelete(itemizedCreditAdjustment: ItemizedCreditToVendorPurchaserONStrategy | ItemizedCreditToVendorPurchaserABStrategy) {
    if (itemizedCreditAdjustment.creditsTo == 'VENDOR' && itemizedCreditAdjustment.automaticallyInsertTotal == 'YES') {
      const warningMsg = ItemizedCreditAdjustmentMessage.VENDOR_CONSIDERATION_REMOVE_MSG.replace('<TOTAL_CREDITS>', itemizedCreditAdjustment.getFormattedTotal(this.currencyPipe));
      this.dialogService.confirm('Information', warningMsg, true);
    }
  }

  updateMatterOnItemizedCreditAdjustmentDelete(salePriceAdjustment: SalePriceAdjustment, deletedItemizedAdjustment: StatementAdjustment): void {
    if (deletedItemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES') {
      if (deletedItemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'VENDOR') {
        salePriceAdjustment.additionalConsiderationsInclHst = 0;
      } else {
        salePriceAdjustment.creditsToPurchaserInclHst = 0;
      }
    }
  }

  async updateSalePriceConsiderationLTT(): Promise<void> {
    this.matter.updateChattelsConsiderationLTTFromSalePriceAdjustment();
    this.matter.updateSalePriceConsiderationLTT(this.ontarioTaxRateSlab, this.torontoTaxRateSlab, this.soaConsiderationTaxes);
    if (this.matter.isMatterProvinceAB) {
      this.matter.statementOfAdjustments.filter(sAdj => sAdj.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING && !sAdj.fieldCode).forEach(soaAdj => {
        let salePriceHeadingAdjFCAssign = this.matter.allStatementAdjustments.find(soa => soa.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING && soa.salePriceAdjustmentHeadingId == soaAdj.salePriceAdjustmentHeadingId && !!soa.fieldCode);
        if (salePriceHeadingAdjFCAssign && !!salePriceHeadingAdjFCAssign.fieldCode) {
          soaAdj.fieldCode = salePriceHeadingAdjFCAssign.fieldCode;
        } else {
          this.soajFieldCodeService.assignFieldCode(soaAdj, this.matter);
          let salePriceHeadingAdj = this.matter.allStatementAdjustments.find(soa => soa.itemKey == StatementAdjustmentKey.SALE_PRICE_ADJUSTMENT_HEADING && soa.salePriceAdjustmentHeadingId == soaAdj.salePriceAdjustmentHeadingId && !soa.fieldCode);
          if (salePriceHeadingAdj) {
            salePriceHeadingAdj.fieldCode = soaAdj.fieldCode;
          }
        }
      });
    }
    this.updateCommissionPayableToVendorBroker();
  }

  updateCommissionPayableToVendorBroker(): void {
    if (this.matter && this.matter.isSale && this.brokerCommissionComponent) {
      this.brokerCommissionComponent.updateCommissionPayableToVendorBroker();
    }
  }

  removeFromFieldCodeList(fieldCode): void {
    if (fieldCode) {
      if (this.isProjectConfigTab) {
        this.soajFieldCodeService.removeFromFieldCodeList(fieldCode);
      } else if (this.isProjectSale) {
        this.soajFieldCodeService.removeFromFieldCodeList(fieldCode);
      }
      this.validateFieldCode();
    }

  }

  validateFieldCode(): void {
    if ((this.isProjectConfigTab || this.isProjectSale) && this.matter && !this.matter.isPurchaseMatterLinkedProjectSale()) {
      this.errorService.removeMultipleDpFieldErrors('project.statementAdjustment.fieldCode.OutOfRange');
      this.checkForOutOfRangeFieldCodes();

      this.reloadAllFieldCode();
      let duplicateFieldCodes: number[] = this.getDuplicateFieldCodes();
      duplicateFieldCodes.forEach((fc) => {
        let soaWithDuplicateFieldCodes: StatementAdjustment[] = this.getSoaWithTheSameFieldCode(fc);
        this.filterOutLinkedStatementOfAdjustment(soaWithDuplicateFieldCodes, fc);
        if (soaWithDuplicateFieldCodes && soaWithDuplicateFieldCodes.length) {
          let message = `The field code ${ fc } is already in use on your Statement of Adjustments, Please enter another`;
          soaWithDuplicateFieldCodes.forEach((soa) => {
            this.showFieldCodeError(soa, message);
          });

        }
      });
    }
  }

  filterOutLinkedStatementOfAdjustment(originalList: StatementAdjustment[], fc: number): void {
    let duplicates = originalList.filter(item => item.fieldCode == fc);
    if (duplicates && duplicates.length == 2) {
      //Check if those 2 duplicates are linked adjustments
      if (duplicates[ 0 ].linkId && (duplicates[ 0 ].linkId == duplicates[ 1 ].linkId)) {
        (<any>originalList).remove(duplicates[ 0 ]);
        (<any>originalList).remove(duplicates[ 1 ]);
      }
    }
  }

  checkForOutOfRangeFieldCodes(): void {
    //For AB project sale matter, the new added salePriceHeading adjustment won't have the field code
    this.matter.allStatementAdjustments.filter(item => !item.isSalePriceAdjustmentHeading()).forEach((item) => {
      let fieldCode = Number(item.fieldCode);
      if (!fieldCode || !((fieldCode >= 5 && fieldCode <= 103) || (fieldCode >= 2100 && fieldCode <= 2999))) {
        let errorMessage = `The field code ${ fieldCode } is outside the range of codes allowed. Please enter an available number either between 7 and 103 or 2100 and 2999`;
        this.showFieldCodeError(item, errorMessage);
      }
    });
  }

  getSoaWithTheSameFieldCode(fieldCode: number): StatementAdjustment[] {
    let statementAdjustments: StatementAdjustment[] = [];
    let allStatementOfAdjustments = [ ...this.matter.finalStatementAdjustments, ...this.matter.interimStatementAdjustments ];
    if (allStatementOfAdjustments && allStatementOfAdjustments.length) {
      allStatementOfAdjustments.forEach((soa) => {
        if (soa.fieldCode == fieldCode) {
          statementAdjustments.push(soa);
        }
      });
    }
    return statementAdjustments;

  }

  getDuplicateFieldCodes(): number[] {
    let arr = [ ...this.soajFieldCodeService.projectSoAdjFieldCodes, ...this.soajFieldCodeService.matterSoAdjFieldCodes ];
    let sorted_arr = arr.slice().sort();
    let duplicates = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[ i + 1 ] == sorted_arr[ i ]) {
        duplicates.push(sorted_arr[ i ]);
      }
    }
    duplicates = _.uniq(duplicates);
    return duplicates;
  }

  reloadAllFieldCode(): void {
    let fieldCodes: number[] = [];
    let allStatementOfAdjustments = [ ...this.matter.finalStatementAdjustments, ...this.matter.interimStatementAdjustments ];
    if (this.isProjectSale) {
      allStatementOfAdjustments = allStatementOfAdjustments.filter(soa => !soa.sourceProjectAdjustmentId);
    }
    if (allStatementOfAdjustments && allStatementOfAdjustments.length) {
      allStatementOfAdjustments.forEach((soa) => {
        if (soa.fieldCode) {
          fieldCodes.push(Number(soa.fieldCode));
        }
      });
    }
    if (this.isProjectConfigTab && !this.isProjectSale) {
      this.tabService.activeTab.projectSoAdjFieldCodes = fieldCodes;
    } else if (this.isProjectSale) {
      if (this.isMassUpdate) {
        //  matterSoAdjFieldCodes contains also all the field codes from all matters that belong to the project this mass update applies to
        this.tabService.activeTab.matterSoAdjFieldCodes = _.union(this.tabService.activeTab.matterSoAdjFieldCodes, fieldCodes);
      } else {
        this.tabService.activeTab.matterSoAdjFieldCodes = fieldCodes;
      }
    }
  }

  showFieldCodeError(statementAdjustment: StatementAdjustment, errorMessage: string): void {
    if (!this.isFieldCodeReadOnly(statementAdjustment)) {
      this.errorService.addDpFieldError(DPError.createCustomDPError('project.statementAdjustment.fieldCode.OutOfRange.' + statementAdjustment.id, errorMessage,
        'Statement Of Adjustment', 'ERROR'));
    }
  }

  isFieldCodeReadOnly(soaItem: StatementAdjustment): boolean {
    return (soaItem.isSalePrice() || soaItem.isDepositAdjustment() || soaItem.sourceProjectAdjustmentId != undefined || soaItem.isSalePriceAdjustmentHeading());
  }

  setFocusAfterModalClose() {
    if (this.lastActiveRowId != '') {
      setTimeout(() => {
        jQuery('#' + this.lastActiveRowId).closest('tr').find('.toggleBurger').focus();
        MoreUtils.bringFieldIntoVisibleArea(document.activeElement);
      }, 200);
    }
  }

  removeOutOfRangeError(statementAdjustment: StatementAdjustment): void {
    this.errorService.removeDpFieldError('project.statementAdjustment.fieldCode.OutOfRange.' + statementAdjustment.id);
  }

  showCannotDeleteWarning() {
    this.dialogService.confirm('Warning', 'The Tax adjustment cannot be deleted when the value of the "Pay taxes out of our trust account" field has been set to "Yes"', true, 'Close').subscribe();
  }

  setDirtyFlag(dirty: boolean): void {
    this.matter.dirty = dirty;
    let projectTab = this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject() && (this.tabService.activeTab as ProjectTab);
    if (projectTab && projectTab.project && !projectTab.isProjectReadOnly(this.authZService)) {
      (this.tabService.activeTab as ProjectTab).project.dirty = dirty;
    }
  }

  /**
   * also adds statementAdjustment if not found on matter
   * @param {StatementAdjustment} statementAdjustment
   */
  selectedItem(statementAdjustment: StatementAdjustment): void {
    // after saving a matter adjustmentStatusMode gets set to FINAL, need to set it back to selectedProgressionStatus
    if (this.matter.isProjectSale) {
      this.matter.updateStatusMode(this.matter.selectedProgressionStatus);
    }
    statementAdjustment.adjustmentStatus = this.matter.adjustmentStatusMode ? this.matter.adjustmentStatusMode : ProgressionStatus.FINAL;
    this.matter.statementOfAdjustments.push(statementAdjustment);
    if (this.isProjectSale) {
      let statementAdjustmentOrders = this.matter.isAdjustmentStatusModeFinal ? this.matter.finalStatementAdjustmentOrders : this.matter.interimStatementAdjustmentOrders;
      if (statementAdjustmentOrders) {
        this.createStatementAdjustmentOrder(statementAdjustment);
      }
    }
    this.selectAndSetFocusAdjustment(statementAdjustment);
    if (this.project || this.isProjectSale) {
      statementAdjustment.fieldCode = this.soajFieldCodeService.requestAdjustmentFieldCode();
    }
  }

  createStatementAdjustmentOrder(adjustment: StatementAdjustment, selectedStatementAdjustmentOrderObject?: StatementAdjustmentOrder): void {
    this.matter.createStatementAdjustmentOrder(adjustment, selectedStatementAdjustmentOrderObject);
  }

  selectAndSetFocusAdjustment(statementAdjustment: StatementAdjustment): void {
    this.scrollIntoView(this.orderedStatementDisplayItems.length - 1);
    this.selectAdjustmentItem(statementAdjustment);
    this.setFocusToLastRow();
  }

  selectAdjustmentItem(statementAdjustment: StatementAdjustment): void {
    //this.orderedStatementDisplayItems is a heavy getter
    let orderedtDisplayItems: StatementAdjustmentDisplayItem[] = this.orderedStatementDisplayItems;
    for (let i = 0; i < orderedtDisplayItems.length; i = i + 1) {
      if (orderedtDisplayItems[ i ].soaItem) {
        orderedtDisplayItems[ i ].soaItem.isSelected = false;
      }
    }
    statementAdjustment.isSelected = !statementAdjustment.isSelected;
  }

  setFocusToLastRow() {
    setTimeout(function () {
      jQuery('#adjTable tbody tr:last-child').closest('tr').find('.toggleBurger').focus();
    }, 200);
  }

  scrollIntoView(index: number): void {
    let elementId = `adj_line_${ index }`;
    Utils.scrollIntoView(elementId, 'soaTable');
  }

  getPropertyTabTotalCondoExpenseAmount(): number {
    if (this.matter) {
      let result: number = 0;
      if (this.matter.isProjectSale && this.matter.matterPropertyWithCondo && !this.matter.matterPropertyWithCondo.isPropertyCondominium() && this.isPOTL) {
        result = +(this.matter.matterPropertyWithCondo.commonExpenses ? this.matter.matterPropertyWithCondo.commonExpenses.valueOf() : 0);
      } else if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.condominiumTotalExpenses) {
        result = this.matter.matterPropertyWithCondo.condominiumTotalExpenses.valueOf();
      } else if (this.matter.matterPropertyWithCondo && !this.matter.matterPropertyWithCondo.condominiumTotalExpenses && this.matter.isMatterProvinceMB) {
        let statementAdjustment = this.matter.statementOfAdjustments.find(soa => soa.isCommonExpenseAdjustmentFromProperty());
        let soaCommonExpense = statementAdjustment ? statementAdjustment.soaCommonExpense : undefined;
        if (soaCommonExpense) {
          result = soaCommonExpense.commonExpenseAmount;
        }
      }
      return result;
    } else {
      return 0;
    }
  }

  async initHeading(): Promise<void> {
    if (!this.matter.statementOfAdjustmentHeading) {
      let documentProfile = this.matterDocumentProfile;
      if (documentProfile != null && documentProfile.statementOfAdjustmentsProfile != null) {
        if (documentProfile.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag) {
          let defaultDocProfile: DocumentProfile = this.documentProfileCache.cachedDefaultDocumentProfile;
          if (!defaultDocProfile) {
            defaultDocProfile = await this.documentProfileService.getDefault().toPromise();
          }
          if (defaultDocProfile != null && defaultDocProfile.statementOfAdjustmentsProfile != null) {
            this.matter.createStatementAdjustmentHeading(defaultDocProfile, this.matter.isProjectSale);
          }
        } else {
          this.matter.createStatementAdjustmentHeading(documentProfile, this.matter.isProjectSale);
        }
      }
    }
  }

  async initSoaFooters(): Promise<void> {
    /*
		 * SOA Footers will be inherited from the configuration for a project or any type of matter
		 * except project sale as project sale matter will inherit the footers from it's parent project.
		 */
    if (this.matter.matterStatementOfAdjustmentFooters && this.matter.matterStatementOfAdjustmentFooters.length == 0 && (this.isProjectConfigTab || !this.isProjectSale)) {
      let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      let statementAdjustmentConfigList: StatementAdjustmentConfig[] = await (this.soaConfigService.getStatementAdjustmentConfig(id, this.matter.provinceCode)).toPromise();
      if (statementAdjustmentConfigList && statementAdjustmentConfigList.length) {
        this.matter.matterStatementOfAdjustmentFooters = statementAdjustmentConfigList;
        this.matter.matterStatementOfAdjustmentFooters.map(function (item) {
          item.id = null;
          item.instanceType = 'matterSoaFooter';
          item.footerProgressionStatusAvailability = soAdjProjectFooters.applyToBoth.value;
        });
      }
    }
  }

  updateSalePriceMinusNetSalePriceAdjustment() {
    let SalePriceAdjSOAList: StatementAdjustment[] = this.matter.statementOfAdjustments.filter(adj => adj.itemKey === StatementAdjustmentKey.SALE_PRICE_MINUS_NET_SALE_PRICE);
    if (SalePriceAdjSOAList) {
      SalePriceAdjSOAList.forEach(salePriceAdj => {
        this.onSalePriceMinusNetSalePriceUpdate(null, salePriceAdj);
      });
    }
  }

  onSalePriceMinusNetSalePriceUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (result && result == ModalResult.Delete) {
      if (statementAdjustment) {
        this.removeStatementAdjustment(statementAdjustment, true);
      } else {
        return;
      }
    } else if (statementAdjustment) {
      statementAdjustment.amount = this.calculateSalePriceMinusNetSalePrice(this.matter.considerationLtt);
      statementAdjustment.description = 'SALE PRICE MINUS NET SALE PRICE';
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    } else {
      let newAdjst = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      newAdjst.itemKey = StatementAdjustmentKey.SALE_PRICE_MINUS_NET_SALE_PRICE;
      newAdjst.amount = this.calculateSalePriceMinusNetSalePrice(this.matter.considerationLtt);
      newAdjst.description = 'SALE PRICE MINUS NET SALE PRICE';
      newAdjst.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
      newAdjst.addedFlag = true;
      this.selectedItem(newAdjst);
    }
  }

  calculateSalePriceMinusNetSalePrice(considerationLtt: ConsiderationLtt): number {
    return considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE' ? Number(considerationLtt.salePriceAdjustment.agreementSalePrice)
      - Number(considerationLtt.salePriceAdjustment.totalNetSalePrice(this.soaConsiderationTaxes.hstFederalPortion, this.soaConsiderationTaxes.hstProvincialPortion)) : 0;
  }

  // this method will need to be called on either Deposit or SalePrice modal close events
  // because the deposit adjustment now is dependant on Sale price adj for project condo
  async recalculateDepositSOAOnAdjustmentChanges(): Promise<void> {

    // just double check that interim mode applies
    if (!(this.project && this.project.isStatementOfAdjustmentInterim())) {
      return;
    }

    const wasFinal = (this.matter.selectedProgressionStatus == ProgressionStatus.FINAL);
    const wasAdjustmentModeFinal = this.matter.isAdjustmentStatusModeFinal;

    // Interim BDOC is calculated only on the fly when UI is switched to Interim mode,
    // it is needed for Final BDOC as per DPPMP-28748 - we have to trigger Interim calc for Balance
    // items and then switch back to initial adjustmentStatusMode
    await this.updateAdjustmentType('Interim', this.updateDepositCreditPurchaserForCurrentAdjustmentMode.bind(this), true);

    //Before calculating BDOC fetching template code values
    //await this.templateCodeService.calculateMatterTemplateCodes(this.matter);
    // need to push Interim BDOC to depositOnOccupancy
    const interimBDOCAmount = await this.calculateInterimBDOCAmount();

    if (this.matter.isDepositOnOccupancyCalBasedOnInterimBDOC()) {
      this.matter.setOccupancyDepositAmount(interimBDOCAmount);
    }

    if (wasFinal) {
      // Final SOA: force recalc of CreditPurchaser amount before updating display items
      await this.updateAdjustmentType('Final', this.updateDepositCreditPurchaserForCurrentAdjustmentMode.bind(this, interimBDOCAmount), true);
    }
    if (wasAdjustmentModeFinal) {
      this.matter.adjustmentStatusMode = ProgressionStatus.FINAL;
    }
  }

  private updateDepositCreditPurchaserForCurrentAdjustmentMode(interimBDOC?: number): void {
    // util requires interimBDOC if we are in Final Mode
    const util = new DepositAdjustmentCalculationUtil(this.matter);
    util.calculateDepositAdjustmentByStatusMode(interimBDOC);
  }

  getClosingDate(): void {
    this.closingDate = '';
    let closingDate = this.matter.isMatterProvinceBC ? this.matter.adjustAsAtClosingDate : this.matter.getClosingDate();
    if (closingDate) {
      let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
      if (pattern.test(closingDate)) {
        this.closingDate = moment(closingDate, 'YYYY/MM/DD').format('MMMM DD, YYYY');
      }
    }
  }

  // fnBeforeUpdateDispItems: allows optional/extra prep before we recalculate display items
  async updateAdjustmentType(type: string, fnBeforeUpdateDispItems?: Function, ignoreOutOfRangeWarnings?: boolean): Promise<void> {
    let mode = (type == ProgressionStatus.INTERIM || type == 'Interim') ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL;
    this.matter.updateStatusMode(mode);
    this.statementAdjustmentDisplayUtil.setMatter(this.matter);
    this.getClosingDate();
    if (typeof fnBeforeUpdateDispItems === 'function') {
      fnBeforeUpdateDispItems();
    }
    await this.updateDisplayItems(ignoreOutOfRangeWarnings);
    this.setDirtyFlag(true);
    this.validateFieldCode();
  }

  updateAddNewButtonOptions(type) {
    this.updateAddNewButtonOptionsSubject.next(type);
  }

  /** this method forces switch to "Interim" mode so that we can get the Interim BDOC value. */
  async calculateInterimBDOCAmount(): Promise<number> {

    const wasFinal = this.matter.isAdjustmentStatusModeFinal;
    if (wasFinal) { // switch to Interim to get the BDOC amount for deposit modal
      await this.updateAdjustmentType('Interim');
    }
    let amount = 0.0;
    const bdocItem = this.statementOfAdjustmentDisplayBalanceItems.find(item => item.key === 'balanceDueOnClosing');
    if (bdocItem) {
      amount = Utils.toNumber(Utils.convertStringCurrencyToNumber(bdocItem.leftValue));
    }

    if (wasFinal) {
      await this.updateAdjustmentType('Final');
    }
    return amount;
  }

  async resetSalePriceAdj(): Promise<void> {
    this.matter.matterPropertyWithCondo.soaPurchasePrice = 0;
    if (this.matter.considerationLtt) {
      this.matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    }
    this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    await this.updateSalePriceAdjOnReset();

    this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    await this.updateSalePriceAdjOnReset();

    this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
    this.setFocusAfterModalClose();
  }

  async updateSalePriceAdjOnReset(): Promise<void> {
    await this.updateSalePriceConsiderationLTT();
    this.updateCommissionPayableToVendorBroker();
    this.updateHstSalePriceAdjustment();
    this.updateSalePriceMinusNetSalePriceAdjustment();
    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(this.matter);
    await this.enableSave();
  }

  async resetDepositAdj(): Promise<void> {
    this.matter.matterPropertyWithCondo.removeNonOccupancyDeposits();
    this.matter.matterPropertyWithCondo.depositAmount = 0;
    this.matter.extraDepositConfig = new MatterExtraDepositConfig();
    //After confirm with BA, it needs to clean depositAmount for Occupancy Deposit.
    this.resetOccupancyDeposit();
    await this.updateDefaultFirmAccountOnMatter();
    this.matter.createUpdateDepositAdjustment();
    this.updateCommissionPayableToVendorBroker();
    await this.enableSave();
  }

  async updateDefaultFirmAccountOnMatter(): Promise<void> {
    const projId = this.matter.project ? this.matter.project.id : this.matter.unityProjectId;
    this.matter.extraDepositConfig.matterFirmTrustAccount = await this.trustAccountsService.getDefaultFirmAccountOnProject(projId, this.matter.provinceCode);
  }

  resetOccupancyDeposit() {
    if (this.matter.matterPropertyWithCondo) {
      const deposits = this.matter.matterPropertyWithCondo.deposits;
      if (Array.isArray(deposits)) {
        let occupancyDeposit: Deposit = deposits.find(d => d.paidOnOccupancy);
        if (occupancyDeposit) {
          //depositAmount is a string type
          occupancyDeposit.depositAmount = '0';
        }
      }
    }
  }

  setConsiderationLtt(result) {
    if (result.action == ModalResult.Cancel) {
      this.matter.considerationLtt = _.cloneDeep(result.prevConsiderationLtt);
    }
  }

  updateDataPropagation(originalSoa: StatementAdjustment, targetSoa: StatementAdjustment) {
    if (originalSoa && originalSoa.soAdjHeading && targetSoa && targetSoa.soAdjHeading) {
      if (!originalSoa.soAdjHeading.equals(targetSoa.soAdjHeading)) {
        let statementAdjParameter = new StatementAdjParameter();
        statementAdjParameter.adjustmentId = originalSoa.id;
        this.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentFormatSettings, undefined, false, statementAdjParameter);
      }
    }
  }

  soaDisplayItemsContainsOutOfRangeItem(): boolean {
    let item: StatementAdjustmentDisplayItem = this.orderedStatementDisplayItems.find(itm => itm.soaItem.isOutOfRange);
    return item != null;
  }

  generateStatementAdjustmentPreviewObject(outputFileType: DocumentTypeType): StatementAdjustmentPreview {
    let sap = new StatementAdjustmentPreview(this.matter);
    sap.interimAdjustment = !this.matter.isAdjustmentStatusModeFinal;
    sap.hasInterimAndFinal = (this.isProjectSale && this.isProjectConfigForInterimForMatter) || (this.isProjectConfigTab && this.isProjectConfigForInterim);
    sap.printBalanceDueOnClosingAtBottomOfPage = this.matterDocumentProfile.statementOfAdjustmentsProfile.printBalanceFlag;
    sap.paperSizeCode = this.paperSizeCode;
    sap.outputFileType = outputFileType;
    this.orderedStatementDisplayItems.forEach(item => {
      this.replaceZeroAmount(item);
    });
    sap.items.push(...this.orderedStatementDisplayItems.filter(itm => !(itm.soaItem.soAdjOtherProrated || itm.soaItem.soAdjOtherFixed || itm.soaItem.soAdjOtherFixedPayableOnOccupancy || itm.soaItem.soaExpenseAdjustment || itm.soaItem.isReserveForVTBMtg())
      || (itm.soaItem.soAdjOtherProrated && !itm.soaItem.soAdjOtherProrated.infoOnly) || (itm.soaItem.soAdjOtherFixed && !itm.soaItem.soAdjOtherFixed.infoOnly) ||
      (itm.soaItem.soaExpenseAdjustment && !itm.soaItem.soaExpenseAdjustment.infoOnly) ||
      (itm.soaItem.soAdjOtherFixedPayableOnOccupancy && !itm.soaItem.soAdjOtherFixedPayableOnOccupancy.infoOnly)));

    if (this.matter && this.matter.isProjectSale) {
      sap.items = sap.items.filter(itm => (itm.soaItem.applyToAdjustmentRecord || !itm.soaItem.sourceProjectAdjustmentId));
    }

    sap.balanceItems.push(...(this.statementOfAdjustmentDisplayBalanceItems || []));
    if (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceMB || this.matter.isMatterProvinceNB || this.matter.isMatterProvinceNS) {
      sap.balanceItems.push(this.statementAdjustmentDisplayUtil.populateSOASummaryInPreview());
    }

    sap.footers.unshift(...this.addSpecialFooters());

    let clonedMatter = new Matter(this.matter);
    clonedMatter.cleanUpMatterBeforeSaving(this.matterService);
    sap.matter = clonedMatter;
    return sap;
  }

  replaceZeroAmount(item: StatementAdjustmentDisplayItem) {
    if (item && item.soaItem && 'HST_ADJUSTMENT' === item.soaItem.itemKey) {
      if (item.itemLines) {
        item.itemLines.forEach(
          line => {
            if (line.value == '$0.00') {
              line.value = '';
            }
          }
        );
      }
    }
  }

  // add those very special TarionWarranty and OtherFixedAdjustment footers (late addition ?!?!)
  addSpecialFooters(): string[] {
    let result: string[] = [];
    let isTarionWarrantyAdjAvailableAndEligibleForTaxRebate = StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(this.matter.statementOfAdjustments);
    let isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate = StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(this.matter.statementOfAdjustments);

    let isHCRAAdjAvailableAndEligibleForTaxRebate = StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate(this.matter.statementOfAdjustments);
    let isHCRAAdjAvailableAndNotEligibleForTaxRebate = StatementAdjustmentUtil.isHCRAAdjAvailableAndNotEligibleForTaxRebate(this.matter.statementOfAdjustments);

    let isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate = StatementAdjustmentUtil.isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate(this.matter.statementOfAdjustments);
    let isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate = StatementAdjustmentUtil.isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate(this.matter.statementOfAdjustments);

    if (this.matter.isExtrasSubjectToGSTExists() || isTarionWarrantyAdjAvailableAndEligibleForTaxRebate || isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate || isHCRAAdjAvailableAndEligibleForTaxRebate) {
      this.addConsiderationFooter('1', result);
    }

    if (isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate || isOtherFixedWithAdditionalConsiderationToVendorNotEligibleForTaxRebate || isHCRAAdjAvailableAndNotEligibleForTaxRebate) {
      this.addConsiderationFooter('2', result);
    }

    let isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax = StatementAdjustmentUtil.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax(this.matter.statementOfAdjustments);
    if (isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax) {
      this.addConsiderationFooter('3', result);
    }

    return result;
  }

  addConsiderationFooter(considerationFooterReference: string, considerationFooters: string[]): void {
    if (this.isProjectOrProjectSale) {
      let selectedConfigFooterType: string;
      let selectedConfigFooterCustomText: string;
      switch (considerationFooterReference) {
        case '1' :
          selectedConfigFooterType = this.project.projectAdjustmentConfig.footer.footerType1;
          selectedConfigFooterCustomText = this.project.projectAdjustmentConfig.footer.footerValue1;
          break;
        case '2' :
          if (this.isFactorInHstRebateFooter()) {
            selectedConfigFooterType = this.project.projectAdjustmentConfig.footer.adjustedSalePriceFooterType2;
            selectedConfigFooterCustomText = this.project.projectAdjustmentConfig.footer.adjustedSalePriceFooterValue2;
          } else {
            selectedConfigFooterType = this.project.projectAdjustmentConfig.footer.unadjustedSalePriceFooterType2;
            selectedConfigFooterCustomText = this.project.projectAdjustmentConfig.footer.unadjustedSalePriceFooterValue2;
          }
          break;
        case '3' :
          if (this.isFactorInHstRebateFooter()) {
            selectedConfigFooterType = this.project.projectAdjustmentConfig.footer.adjustedSalePriceFooterType3;
            selectedConfigFooterCustomText = this.project.projectAdjustmentConfig.footer.adjustedSalePriceFooterValue3;
          } else {
            selectedConfigFooterType = this.project.projectAdjustmentConfig.footer.unadjustedSalePriceFooterType3;
            selectedConfigFooterCustomText = this.project.projectAdjustmentConfig.footer.unadjustedSalePriceFooterValue3;
          }
          break;
        default:
          selectedConfigFooterType = 'DEFAULT';
          break;
      }
      if (selectedConfigFooterType !== 'DONT_PRINT') {
        considerationFooters.push(this.formatFooterReference(considerationFooterReference, selectedConfigFooterCustomText));
      }
    } else {

      switch (considerationFooterReference) {
        case '1' :
          considerationFooters.push(
            this.formatFooterReference(considerationFooterReference,
              nonProjectSaleAdjustmentFooterMessages.CONSIDERATION_TYPE1).replace('{matterTaxType}', this.matter.matterTaxType.substring(0, 3)));
          break;
        case '2' :
          if (this.matter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'NO') {
            considerationFooters.push(
              this.formatFooterReference(considerationFooterReference, nonProjectSaleAdjustmentFooterMessages.CONSIDERATION_TYPE2_NO_HST)
            );
          } else {
            considerationFooters.push(
              this.formatFooterReference(considerationFooterReference,
                nonProjectSaleAdjustmentFooterMessages.CONSIDERATION_TYPE2_WITH_HST).replace('{matterTaxType}', this.matter.matterTaxType.substring(0, 3)));
          }
          break;
        case '3' :
          considerationFooters.push(this.formatFooterReference(considerationFooterReference, nonProjectSaleAdjustmentFooterMessages.CONSIDERATION_TYPE3));
          break;
        default:
          break;
      }
    }
  }

  isFactorInHstRebateFooter(): boolean {
    return (this.matter && this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment)
      ? this.matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate()
      : false;
  }

  formatFooterReference(ref: string, text: string): string {
    return `<p><sup> ${ ref }</sup> ${ text }<\p>`;
  }

  getInterestKey(): string {
    if ((this.project && this.project.isProjectProvinceON) || (this.matter && this.matter.isProjectSale && this.matter.isMatterProvinceON)) {
      return StatementAdjustmentKey.INTEREST_ON_LUMP_SUM;
    } else {
      return StatementAdjustmentKey.INTEREST;
    }
  }

  isInterestRateOnDeferredPurchaseMoniesVisible(): boolean {
    if (DpProjectShowByProvince.hiddenFieldRestrictions[ 'project.projectCondo.interestRateOnDeferredPurchaseMonies' ].some(item => item == this.matter.provinceCode)) {
      return false;
    }
    if (this.isMassUpdate) {
      return false;
    }
    if (this.isProjectSale && this.isProjectConfigForInterimForMatter) {
      return this.matter && this.matter.project && this.matter.project.projectCondo && this.matter.project.projectCondo.isInterestRateChargeableOnPurchaseMoniesNo;
    }
    if (this.isProjectConfigTab && this.isProjectConfigForInterim) {
      return this.project && this.project.projectCondo && this.project.projectCondo.isInterestRateChargeableOnPurchaseMoniesNo;
    }
  }

  async updateAdjustments(): Promise<void> {
    this.getClosingDate();
    this.matter.updateAdjustments(this.adjustmentDate, this.statementAdjustmentDisplayUtil);
    await this.updateDisplayItems();
    await this.enableSave();
  }

  async addProjectLevelAdjustment(adjustment: StatementAdjustment, matter: Matter, applyToAdjustmentRecord: boolean, templateMatter: Matter): Promise<StatementAdjustment> {
    let statementAdjustment = new StatementAdjustment(matter.adjustmentStatusMode, matter.provinceCode, adjustment);
    statementAdjustment.clearAllIds();
    statementAdjustment.applyToAdjustmentRecord = this.isMassUpdate ? null : (adjustment.isSalePrice() || adjustment.isDepositAdjustment() ? true : applyToAdjustmentRecord);
    statementAdjustment.sourceProjectAdjustmentId = adjustment.id;
    statementAdjustment.matterId = null;
    statementAdjustment.id = UUIDUtil.getUUID();
    if (statementAdjustment.matterTax) {
      statementAdjustment.matterTax.id = UUIDUtil.getUUID();
    }
    return statementAdjustment;
  }

  findOrderInTemplateMatterForAdjustment(adjustment: StatementAdjustment): StatementAdjustmentOrder {
    if (this.projectTemplateMatter && adjustment.sourceProjectAdjustmentId && this.matter) {
      let projectAdjustments = adjustment.isAdjustmentStatusFinal() ? this.projectTemplateMatter.finalStatementAdjustments : this.projectTemplateMatter.interimStatementAdjustments;
      let projectAdjustmentIndex = projectAdjustments.findIndex(adj => adj.id === adjustment.sourceProjectAdjustmentId);
      let statementAdjustmentOrders = adjustment.isAdjustmentStatusFinal() ? this.matter.finalStatementAdjustmentOrders : this.matter.interimStatementAdjustmentOrders;
      // Loop through parents adjustment in project until we find parent that is available on matter
      let parentStatementAdjustmentOrderObject: StatementAdjustmentOrder;
      for (let i = projectAdjustmentIndex - 1; i >= 0; i--) {
        let statementAdjustmentOrderObject = statementAdjustmentOrders.find(adj => !!adj.sourceProjectAdjustmentId && adj.sourceProjectAdjustmentId === projectAdjustments[ i ].id);
        if (statementAdjustmentOrderObject && statementAdjustmentOrderObject.adjustmentIndex > -1) {
          parentStatementAdjustmentOrderObject = statementAdjustmentOrderObject;
          break;
        }
      }
      return parentStatementAdjustmentOrderObject;
    }
  }
}
