import {TabsService} from '../../app/core/tabs.service';
import {DialogService} from '../../app/shared/dialog/dialog.service';
import {LockScreenService} from '../../app/core/lock-screen.service';
import {HttpClient} from '../../app/core/index';
import {Injectable} from '@angular/core';
import {ProjectMatterUpdateAction, ProjectMatterUpdateTransaction} from '../../app/matters/shared/project-matter-update-transaction';
import {Matter} from '../../app/matters/shared/matter';
import {MassUpdateTypes, Project} from '../../app/projects/shared/project';
import {MatterService} from '../../app/matters/matter.service';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';
import {MassUpdateProgressModalComponent} from '../../app/matters/mass-update-progress/mass-update-progress-modal.component';
import {ApplicationError} from '../../app/core/application-error';
import {Observable} from 'rxjs';
import {MassUpdateService} from '../../app/matters/mass-update.service';
import {CopyMatterLinkDataService} from '../../app/matters/copy-matter-link-data.service';
import {ProjectService} from '../../app/projects/project.service';
import {ProjectDataPropagationService} from '../../app/projects/project-data-propagation.service';
import {UUIDUtil} from '../../app/main/uuid-util';
import {AppConfig} from '../shared-main/app-configuration';
import {MatterParticipant} from './shared/matter-participant';
import {ErrorService} from '../../app/shared/error-handling/error-service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {projectApi} from '../projects/shared/project-api';

@Injectable()
export class ProjectMatterUpdateService {

  private matterSaveInProgressSubscriptions: Map<number, Subscription>;
  private pollingData: Subscription;
  private alreadyShowedMessageCount: number[] = [];

  constructor(private http: HttpClient,
              private dialogService: DialogService,
              private lockScreenService: LockScreenService,
              private tabsService: TabsService,
              private matterService: MatterService,
              private appConfig: AppConfig,
              private massUpdateService: MassUpdateService, private copyMatterLinkDataService: CopyMatterLinkDataService, private projectService: ProjectService,
              private projectDataPropagationService: ProjectDataPropagationService) {

    this.matterSaveInProgressSubscriptions = new Map<number, Subscription>();
  }

  setErrorService(errorServ: ErrorService) {
    this.matterService.errorService = errorServ;
  }

  async performProjectMatterUpdates(selectedMatterIds: number[], projectMatterUpdateTransaction: ProjectMatterUpdateTransaction, projectMatterUpdateMessages?: string[]): Promise<boolean> {
    try {
      if (!projectMatterUpdateMessages) {
        projectMatterUpdateMessages = [];
      }
      var checkPoint = new Date().getTime();

      projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' started at: ' + (new Date().toString()));
      if (this.tabsService.isAnyMatterOpenedInTab(selectedMatterIds)) {
        projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' cannot proceed as one or more selected matters already opened.');
        return Promise.resolve(true);
      } else {
        this.lockScreenService.lockForUpdate = true;
        // console.log(">> try to get the matterDetails for all selected matters");
        projectMatterUpdateMessages.push('.....Started loading matters from backend at ' + (new Date().toString()));
        let targetMatters: Matter[] = await this.matterService.getMattersWithDetail(selectedMatterIds, true).toPromise();

        if (!isNaN(projectMatterUpdateTransaction.tempErrorFlagIndex) && projectMatterUpdateTransaction.tempErrorFlagIndex <= targetMatters.length && projectMatterUpdateTransaction.tempErrorFlagIndex != 0) {
          targetMatters[ projectMatterUpdateTransaction.tempErrorFlagIndex - 1 ].matterPropertyWithCondo.interestEstate = 'TESTING';
        }
        if (!!projectMatterUpdateTransaction.massUpdateTransactionId) {
          targetMatters.forEach(matter => {
            matter.massUpdateTransactionId = projectMatterUpdateTransaction.massUpdateTransactionId;
          });
        }
        let linkedPurchaseMatters = await this.matterService.getLinkedPurchaseMatters(targetMatters);
        let targetMattersAndLinkedMatters = [ ...targetMatters, ...linkedPurchaseMatters ];
        projectMatterUpdateMessages.push('.....Time for loading matters from backend ' + (new Date().getTime() - checkPoint));
        // console.log("<< done with get matter details");
        if (targetMattersAndLinkedMatters.some(value => value.locked)) {
          projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' cannot proceed as one or more selected matters are locked.');
          this.matterService.unlockMatters(targetMattersAndLinkedMatters).subscribe();
          this.lockScreenService.lockForUpdate = false;
          return Promise.resolve(true);
        } else {
          if (Array.isArray(targetMatters) && targetMatters.length > 0) {
            let excludedMatters = this.getExcludedMatters(targetMatters, projectMatterUpdateTransaction);
            if (excludedMatters && excludedMatters.length) {
              this.removeAndUnlockExcludedMatters(excludedMatters, linkedPurchaseMatters, targetMatters);
            }

            projectMatterUpdateTransaction.targetMatters = targetMatters;
            projectMatterUpdateTransaction.linkedPurchaseMatters = linkedPurchaseMatters;
            projectMatterUpdateTransaction.totalMattersCount = targetMatters.length;
            if (targetMatters.length) {
              await this.executeProjectMatterUpdate(projectMatterUpdateTransaction, projectMatterUpdateMessages);
            } else {
              this.lockScreenService.lockForUpdate = false;
            }
            this.addExcludedMattersMessage(excludedMatters, projectMatterUpdateTransaction, projectMatterUpdateMessages);
            return Promise.resolve(true);
          }
        }
      }
    } catch (e) {
      this.lockScreenService.forceUnlock();
      return Promise.resolve(false);
    }
  }

  //This is core method for project matter update used by both fresh or restart matter update
  async executeProjectMatterUpdate(projectMatterUpdateTransaction: ProjectMatterUpdateTransaction, projectMatterUpdateMessages: string[]): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    this.matterSaveInProgressSubscriptions = new Map<number, Subscription>();
    //In case of restart filtering out successfully Processed MatterIds to get remainining matters. First time it will include all
    let targetMatters: Matter[] = projectMatterUpdateTransaction.getRemainingMattersIncludingFailed();
    if (targetMatters.length == 0) {
      return Promise.resolve(true);
    }

    projectMatterUpdateMessages.push('.....copying data into matters for ' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' started at ' + (new Date().toString()));

    let matterUpdateErrorMessages: any[] = [];

    //Enabling cache to handle duplicate api calls made during project matter update of multiple components
    this.http.enableMassUpdateMode();

    this.lockScreenService.lockForUpdate = false;
    this.showMatterUpdateProgressModal(projectMatterUpdateTransaction).subscribe(updateCompleted => {
      this.onMatterUpdateCompletion(projectMatterUpdateTransaction, matterUpdateErrorMessages, projectMatterUpdateMessages);
      returnSubject.next(true);
      returnSubject.complete();
    });

    await this.startProcessingMatterUpdatesInBackground(targetMatters, projectMatterUpdateTransaction, matterUpdateErrorMessages, projectMatterUpdateMessages);
  }

  //Remove isDirty flag of targetMatter matterParticipant
  removeIsDirtyFlagForMatterParticipant(targetMatter: Matter) {
    if (Array.isArray(targetMatter.matterParticipants)) {
      targetMatter.matterParticipants.forEach((participant: MatterParticipant) => {
        if (participant.contact) {
          participant.contact.isDirty = false;
        }
      });
    }
  }

  async startProcessingMatterUpdatesInBackground(targetMatters: Matter[], projectMatterUpdateTransaction: ProjectMatterUpdateTransaction, matterUpdateErrorMessages: any[], projectMatterUpdateMessages: string[]): Promise<void> {

    if (projectMatterUpdateTransaction.isProjectMatterUpdateActionMassUpdate() && projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType == MassUpdateTypes.IMPORT_MATTER_ADJ_INF
      && projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData) {
      projectMatterUpdateMessages.push(...this.massUpdateService.checkMatterAdjustmentInformationErrors(projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data, targetMatters));
    }
    this.lockScreenService.lockForUpdate = true;
    for (let i = 0; i < targetMatters.length; i++) {
      let targetMatter = targetMatters[ i ];
      console.log('>>>> iteration started for  %s', targetMatter.matterRecordNumber);

      //if project matter update stopped then stop processing any more matters. Whatever has been submitted will be processed
      if (projectMatterUpdateTransaction.isStopped() || projectMatterUpdateTransaction.shouldMassUpdateTransactionRolledBack()) {
        console.log('>>>> project matter update stopped in middle at ', projectMatterUpdateTransaction.completedMattersCount);
        break;
      }

      let errorMessagesForSingleMatter: any[] = [];
      try {
        await this.matterService.initMatter(targetMatter).toPromise();
        console.log('>>>> finished initMatter  %s', targetMatter.matterRecordNumber);

        if (projectMatterUpdateTransaction.isProjectMatterUpdateActionMassUpdate()) {
          if (projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType == MassUpdateTypes.IMPORT_MATTER_ADJ_INF && projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData) {
            errorMessagesForSingleMatter = await this.massUpdateService.importMatterAdjustmentInformation(targetMatter, projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData.data);
          } else {
            errorMessagesForSingleMatter = await this.massUpdateService.copyDataForMassUpdate(projectMatterUpdateTransaction.projectMassUpdateData.templateMatter, [ targetMatter ], projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType, projectMatterUpdateTransaction.projectMassUpdateData.massUpdateData);
            // Only IsDirty Flag of first targetMattter matterPaticipants are kept
            if (i != 0) {
              this.removeIsDirtyFlagForMatterParticipant(targetMatter);
            }
          }
        } else if (projectMatterUpdateTransaction.isProjectMatterUpdateActionProjectUpdate()) {
          await this.projectDataPropagationService.propagateToMatter(targetMatter);
        }

        console.log('>>>> finished copyDataForMatterUpdate  %s', targetMatter.matterRecordNumber);
      } catch (err) {
        let errorMessage = '' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' data cannot be copied into matter ' + targetMatter.matterRecordNumber + ' – cannot be saved because error - ' + err;
        errorMessagesForSingleMatter.push({
          matterId: targetMatter.id,
          errorMessage: errorMessage
        });
      }

      if (errorMessagesForSingleMatter && errorMessagesForSingleMatter.length > 0) {
        matterUpdateErrorMessages.push(...errorMessagesForSingleMatter);
        this.updateFailedMatterCount(projectMatterUpdateTransaction);
      } else {
        //ToDo: check if linked matter then go link flow else single update
        console.log('>>>> submitting saveMatter  %s', targetMatter.matterRecordNumber);
        if (projectMatterUpdateTransaction.isStopped() || projectMatterUpdateTransaction.shouldMassUpdateTransactionRolledBack()) {
          console.log('>>>> project matter update stopped in middle at ', projectMatterUpdateTransaction.completedMattersCount);
          break;
        }

        if (!projectMatterUpdateTransaction.shouldMassUpdateTransactionRolledBack()) {
          let isLinkedMatterUpdated = false;
          let linkedPurchaseMatter = projectMatterUpdateTransaction.linkedPurchaseMatters.find(matter => matter.matterLink && matter.matterLink.linkedMatterId == targetMatter.id);
          if (linkedPurchaseMatter) {
            linkedPurchaseMatter.massUpdateTransactionId = targetMatter.massUpdateTransactionId;
            // Wait for updating linked purchase matter
            isLinkedMatterUpdated = await this.copyMatterLinkDataService.updateLinkedPurchaseMatter(targetMatter, linkedPurchaseMatter);
          }
          this.saveMatter(targetMatter, linkedPurchaseMatter, isLinkedMatterUpdated, projectMatterUpdateTransaction, matterUpdateErrorMessages, projectMatterUpdateMessages);
        }
      }
    }

    this.lockScreenService.lockForUpdate = false;
  }

  saveMatter(targetMatter: Matter, linkedPurchaseMatter: Matter, isLinkedMatterUpdated: boolean, projectMatterUpdateTransaction: ProjectMatterUpdateTransaction,
             matterUpdateErrorMessages: any[], projectMatterUpdateMessages: any[]): void {
    //projectMatterUpdateTransaction.processedMattersCount++;
    if (linkedPurchaseMatter) {
      if (isLinkedMatterUpdated) {
        let matterSaveInProgressSubscription = this.matterService.updateMultipleMatter([ targetMatter, linkedPurchaseMatter ], undefined, undefined, true)
        .finally(() => {
          this.matterSaveInProgressSubscriptions.delete(targetMatter.id);
          this.onMatterUpdateCompletion(projectMatterUpdateTransaction, matterUpdateErrorMessages, projectMatterUpdateMessages);
        })
        .subscribe((savedMatters: Matter[]) => {
          projectMatterUpdateTransaction.markSuccessfulSend(targetMatter.id);
          this.projectService.saveMatterFieldCode(targetMatter);
        }, error => {
          this.handleFailedMatter(targetMatter, error, projectMatterUpdateTransaction, matterUpdateErrorMessages);
        });
        this.matterSaveInProgressSubscriptions.set(targetMatter.id, matterSaveInProgressSubscription);

      } else {
        let error = new ApplicationError();
        error.message = 'Copy linked purchase matter error.';
        this.handleFailedMatter(targetMatter, error, projectMatterUpdateTransaction, matterUpdateErrorMessages);
      }
    } else {
      //subscriptions
      let matterSaveInProgressSubscription = this.matterService.saveMatter(targetMatter, null, null, null, null, true)
      .finally(() => {
        this.matterSaveInProgressSubscriptions.delete(targetMatter.id);
        this.onMatterUpdateCompletion(projectMatterUpdateTransaction, matterUpdateErrorMessages, projectMatterUpdateMessages);
      })
      .subscribe((savedMatter: Matter) => {
        projectMatterUpdateTransaction.markSuccessfulSend(targetMatter.id);
        //only successful matter is unlocked rightaway
        this.projectService.saveMatterFieldCode(savedMatter);

      }, error => {
        this.handleFailedMatter(targetMatter, error, projectMatterUpdateTransaction, matterUpdateErrorMessages);
      });
      this.matterSaveInProgressSubscriptions.set(targetMatter.id, matterSaveInProgressSubscription);
    }
  }

  handleFailedMatter(targetMatter: Matter, error: ApplicationError, projectMatterUpdateTransaction: ProjectMatterUpdateTransaction, matterUpdateErrorMessages: any[]) {
    console.log('project matter update matter cannot be saved %s\', targetMatter.matterRecordNumber');
    let errorMessage = 'Matter ' + targetMatter.matterRecordNumber + ' – cannot be saved because error - ' + (error && error.message ? error.message : 'backend error');
    matterUpdateErrorMessages.push({
      matterId: targetMatter.id,
      errorMessage: errorMessage
    });
    this.updateFailedMatterCount(projectMatterUpdateTransaction);
  }

  updateFailedMatterCount(projectMatterUpdateTransaction: ProjectMatterUpdateTransaction): void {
    projectMatterUpdateTransaction.failedMatterCount++;
  }

  onMatterUpdateCompletion(projectMatterUpdateTransaction: ProjectMatterUpdateTransaction,
                           matterUpdateErrorMessages: any[], projectMatterUpdateMessages: string[]): void {
    if (!(this.matterSaveInProgressSubscriptions && this.matterSaveInProgressSubscriptions.size)) {
      if (projectMatterUpdateTransaction.isProjectMatterUpdateActionMassUpdate()
        && projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType == MassUpdateTypes.IMPORT_MATTER_ADJ_INF
        && !projectMatterUpdateTransaction.isStopped()) {
        projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' finished at ' + (new Date().toString()));
      } else if (matterUpdateErrorMessages && matterUpdateErrorMessages.length > 0) {
        projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' finished at ' + (new Date().toString()) + ', but it was not entirely successful. Number of matters' +
          ' successfully saved are ' + projectMatterUpdateTransaction.successfulMattersCount + ' and failed are ' + projectMatterUpdateTransaction.failedMatterCount);
        matterUpdateErrorMessages.forEach(item => {
          projectMatterUpdateMessages.push('.......... ' + item.errorMessage);
        });
      } else if (projectMatterUpdateTransaction.isStopped()) {
        //cancel inprogress subscriptions. matter will be unlocked when user exits project matter update
        //matterSaveInProgressSubscriptions.forEach(value => value.unsubscribe());
        //removing screenlock enforced by any project matter update methods
        this.lockScreenService.forceUnlock();
        projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' was stopped in middle at ' + (new Date().toString()) + '. Number of matters completed' +
          ' are:' + projectMatterUpdateTransaction.completedMattersCount + ' and incomplete are ' + (projectMatterUpdateTransaction.totalMattersCount - projectMatterUpdateTransaction.completedMattersCount));
      } else {
        if (this.isLogTransactionInfo(projectMatterUpdateTransaction)) {
          projectMatterUpdateMessages.push('.....' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + ' was successfully finished at ' + (new Date().toString()) + '. Number of matters' +
            ' successfully saved are ' + projectMatterUpdateTransaction.successfulMattersCount + ' and failed are ' + projectMatterUpdateTransaction.failedMatterCount);
        }
      }
      this.http.disableMassUpdateMode();
    }

  }

  isLogTransactionInfo(massUpdateTransaction: ProjectMatterUpdateTransaction): boolean {
    if (massUpdateTransaction.isTransactionFinished()) {
      return true;
    }
    if (this.alreadyShowedMessageCount.includes(massUpdateTransaction.successfulMattersCount)) {
      return false;
    }
    this.alreadyShowedMessageCount.push(massUpdateTransaction.successfulMattersCount);

    return massUpdateTransaction.successfulMattersCount != 0 && massUpdateTransaction.successfulMattersCount % 4 == 0;
  }

  showMatterUpdateProgressModal(projectMatterUpdateTransaction: ProjectMatterUpdateTransaction): Observable<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    this.dialogService.matDialogContent({
      content: MassUpdateProgressModalComponent,
      showModalOnTop: true,
      context: {
        projectMatterUpdateTransaction: projectMatterUpdateTransaction
      },
      onFulfillment: (result) => {
        returnSubject.next(true);
        returnSubject.complete();
      }
    });
    return returnSubject;
  }

  getExcludedMatters(targetMatters: Matter[], projectMatterUpdateTransaction: ProjectMatterUpdateTransaction): Matter[] {

    let excludedMatters: Matter[] = [];
    if (projectMatterUpdateTransaction.isProjectMatterUpdateActionMassUpdate() && projectMatterUpdateTransaction.projectMassUpdateData &&
      projectMatterUpdateTransaction.projectMassUpdateData.massUpdateType == MassUpdateTypes.NUMBER_OF_VTB_MORTGAGES) {
      excludedMatters = targetMatters.filter(matter => matter.matterLink && matter.matterLink.linkedMatterId);
    }
    // Print Any Generic Errors in Case of Import Flow

    return excludedMatters;
  }

  removeAndUnlockExcludedMatters(excludedMatters: Matter[], linkedPurchaseMatters: Matter[], targetMatters: Matter[]) {
    //Remove excluded matters from targetMatters
    for (let matter of excludedMatters) {
      (<any>targetMatters).remove(matter);
      this.matterService.unlockMatter(matter.id);
      let linkedPurchaseMatter = linkedPurchaseMatters.find(pm => pm.matterLink && pm.matterLink.linkedMatterId == matter.id);
      if (linkedPurchaseMatter) {
        (<any>linkedPurchaseMatters).remove(linkedPurchaseMatter);
        this.matterService.unlockMatter(linkedPurchaseMatter.id);
      }
    }
  }

  addExcludedMattersMessage(excludedMatters: Matter[], projectMatterUpdateTransaction: ProjectMatterUpdateTransaction, projectMatterUpdateMessages: string[]): void {
    if (excludedMatters && excludedMatters.length) {
      if (projectMatterUpdateTransaction.isProjectMatterUpdateActionMassUpdate()) {
        projectMatterUpdateMessages.push('<span class=\'padding-left-30 bold\'>' + excludedMatters.length +
          ' Project Sale Matter(s) excluded from ' + projectMatterUpdateTransaction.projectMatterUpdateMessageAction + '. For these matters, the Purchaser\'s Mortgage topic is disabled as they are linked to a purchase matter</span>');
        projectMatterUpdateMessages.push('<span class=\'padding-left-30 bold\'>Matters were not updates:</span>');
        for (let matter of excludedMatters) {
          projectMatterUpdateMessages.push('<span class=\'padding-left-40 bold\'> - ' + matter.matterRecordNumber + '</span>');
        }
      }
    }
  }

  createNewProjectMatterUpdateTransaction(totalMattersCount: number, projectMatterUpdateAction: ProjectMatterUpdateAction): ProjectMatterUpdateTransaction {
    let projectMatterUpdateTransaction = new ProjectMatterUpdateTransaction();
    projectMatterUpdateTransaction.id = UUIDUtil.getUUID();
    projectMatterUpdateTransaction.totalMattersCount = totalMattersCount;
    projectMatterUpdateTransaction.processedMattersCount = 0;
    projectMatterUpdateTransaction.successfulMattersCount = 0;
    projectMatterUpdateTransaction.failedMatterCount = 0;
    projectMatterUpdateTransaction.targetMatters = [];
    projectMatterUpdateTransaction.linkedPurchaseMatters = [];
    projectMatterUpdateTransaction.projectMatterUpdateAction = projectMatterUpdateAction;
    return projectMatterUpdateTransaction;
  }

  public checkMassUpdateTransactionInProgress(project: Project) {
    if (project.openTransactionsIds && project.openTransactionsIds.length > 0) {
      project.openTransactionsIds.map(transactionId => {
        let massUpdateTransaction = new ProjectMatterUpdateTransaction();
        massUpdateTransaction.massUpdateTransactionId = transactionId;
        this.createMassUpdatePolling(massUpdateTransaction);
      });
    }
  }

  public createMassUpdatePolling(projectMassUpdateTransaction: ProjectMatterUpdateTransaction) {

    if (!projectMassUpdateTransaction.massUpdateTransactionId) {
      return;
    }

    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ projectApi.projectTransactionInfo(accountId, projectMassUpdateTransaction.massUpdateTransactionId) }`;

    this.pollingData = Observable.interval(3000)
    .switchMap(() => this.http.get(url)).map((data) => data)
    .subscribe((data) => {
      let currentTransactionInfo = data.MassUpdateTransaction;
      projectMassUpdateTransaction.successfulMattersCount = currentTransactionInfo.updatedRecordsCount;
      projectMassUpdateTransaction.totalMattersCount = currentTransactionInfo.totalRecordsCount;
      projectMassUpdateTransaction.failedMatterCount = currentTransactionInfo.failedRecordsCount;
      projectMassUpdateTransaction.status = currentTransactionInfo.status;

      if (projectMassUpdateTransaction.isTransactionFinished()) {
        this.pollingData.unsubscribe();
      }

      if (currentTransactionInfo.failedRecordsCount > 0) {
        if (this.matterSaveInProgressSubscriptions.size > 0) {
          this.matterSaveInProgressSubscriptions.forEach(value => value.unsubscribe());
        }
      }
    });
  }

  public destroyMassUpdatePolling() {
    if (this.pollingData) {
      this.pollingData.unsubscribe();
    }
  }
}
