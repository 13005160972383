import {Component, OnInit} from '@angular/core';
import {ForgotPasswordService} from './forgot-password.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {messages} from '../../common/messages';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../main/utils.service';

@Component({
  selector: 'forgot-password-form',
  templateUrl: './forgot-password.component.html',
  styleUrls: [
    '../unity-login.styles.scss'
  ],
  providers: [ ForgotPasswordService ]
})
/**
 * Component with the functionality to reset the credentials
 */
export class ForgotPasswordComponent implements OnInit {

  publicAccountId: string;
  loginId: string;
  email: string;
  success: boolean = true;
  errorMessage: string = messages.FORGOT_PASSWORD.CREDENTIALS_INCORRECT;
  submitted: boolean = false;
  fromDataMissing: boolean = false;

  constructor(public forgotPasswordService: ForgotPasswordService,
              public route: ActivatedRoute, public utilsService: UtilsService,
              public dialogService: DialogService) {
  }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      console.log(params); // {order: "popular"}
      this.publicAccountId = params.publicAccountId;
      this.loginId = params.loginId;
    });
  }

  reset() {
    this.fromDataMissing = true;
    if (this.email && this.loginId && this.publicAccountId) {
      this.fromDataMissing = false;
      this.submitted = true;
      this.forgotPasswordService.resetPassword(this.email, this.loginId, this.publicAccountId).subscribe(
        (res) => {
          this.dialogService.confirm('Information', messages.FORGOT_PASSWORD.RESET_LINK_SENT, true);
          this.success = true;
          if (res.userStatus !== 'ACTIVE') {
            this.dialogService.confirm('Error', messages.FORGOT_PASSWORD.CREDENTIALS_LOCKED, true);
          }
        },
        (res) => {
          this.success = false;
          if (res.errorCode == 'app.accountLockedOut') {
            this.errorMessage = messages.FORGOT_PASSWORD.CREDENTIALS_LOCKED;
          } else if (res.errorCode == 'app.noTrustedPhones') {
            this.errorMessage = messages.FORGOT_PASSWORD.NO_TRUSTED_PHONE;
          }
        }
      );
    }
  }
}
