<div class="form-horizontal">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Cancel Title Insurance</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="row modal-body">
        <div class="form-group">
            <label class="control-label col-lg-3">Reason for Cancelling</label>
            <div class="col-lg-7">
                <select class="form-control" id="cancelReason"
                        name="cancelReason"  [(ngModel)]="chicagoCancelRequest.cancellationReason">
                    <option *ngFor="let reason of cancellationReasonOptions" [value]="reason.value">
                        {{reason.label}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group" *ngIf="isAdditionalDetailsVisible()">
            <label class="control-label col-lg-3" for="cancelText">Additional Details</label>
            <div class="col-lg-7">
                <textarea rows="3"
                          trimmed id="cancelText"
                          name="cancelText"
                          class="form-control"
                          [(ngModel)]="chicagoCancelRequest.cancellationText"
                          maxlength="256"
                          placeholder="Provide a reason"
                ></textarea>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row padding-top-20">
                <div class="col-lg-12 text-center">
                    <button type="button" class="dp-btn" [disabled]="!chicagoCancelRequest.cancellationReason" (click)="cancelOrder()">
                        <span>Cancel Order</span>
                    </button>
                    <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
