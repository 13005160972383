import {AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {WindowRef} from '../shared/window.ref';

declare var jQuery: any;

@Directive({
  selector: '[sticky-table-header]'
})
export class StickyTableHeaderDirective implements OnInit, AfterViewInit {
  //// you must assign id attribute to table
  element: Element;

  window: WindowRef;
  @Input() tableHeightVariance: number = 50;
  @Input() isStickyTableModalWindow: boolean = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = elementRef.nativeElement;

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    let tableHeightVar = this.tableHeightVariance;
    let isStickyTableModalWindow = this.isStickyTableModalWindow;

    let tableId = '#' + this.elementRef.nativeElement.id;
    setTimeout(function () {
      let tablePositionY: number = 0;
      let modalBodyHeight: number = 0;
      // set the table height based on the table position in the modal
      // table must be contained in its own form group, cannot be combined with other elements.
      let winHeight: number = this.window.innerHeight;
      let tablePosition: any = jQuery(tableId).position();
      if (tablePosition) {
        tablePositionY = tablePosition.top;
        if (isStickyTableModalWindow) {
          // get height of modal body area to fit table within
          modalBodyHeight = jQuery('.mat-modal-dialog:last .modal-content .modal-body').outerHeight();
        } else {

          // get height of window body area to fit table within
          modalBodyHeight = winHeight - jQuery('nav').height() - jQuery('.matter-header').height() - jQuery('.inspector-footer').height() - jQuery('.matter-title').height();
        }
      }

      let tableHeight: number = modalBodyHeight - tablePositionY - tableHeightVar;
      if (tableHeight == undefined || isNaN(tableHeight) || tableHeight < 400) {
        tableHeight = 400;
      }

      if (winHeight < 780 && isStickyTableModalWindow == true) {
        tableHeight = 200;
      }
      jQuery(tableId).addClass('sticky-table-header-directive');
      jQuery(tableId).wrap('<div class=\'table-wrapper\'></div>');
      jQuery(tableId).closest('div').height(tableHeight);
      jQuery(tableId).closest('div').css('overflow-y', 'auto');
      jQuery(tableId).closest('div').css('border-top', '1px solid #dadbdb');
      jQuery(tableId).closest('div').wrap('<div class=\'table-container\'></div>');
      jQuery(tableId + ' th').each(function () {
        let htm: string = jQuery(this).html();
        if (htm.includes('<div>')) {
          // nothing happens here
        } else {
          // add div tag to header for absolute placement
          jQuery(this).html(jQuery(this).html() + ' ' + '<div>' + jQuery(this).html()
            + '</div>');
        }
      });

    }, 1500);

  }

  ngOnDestroy() {

  }

  applyStickyTable() {

  }

}
