import {Address} from './address';
import * as parseAddress from 'parse-address-string';
import {PROVINCE_CODES} from './user-province';
import {dropDowns} from '../../shared-main/address-Form/drop-downs';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {provinces} from '../../shared-main/province';

export class AddressUtil {

  //the item in the array should not be part of the UnitNo after parsing
  static exclusiveUnitNoPrefixes: string[] = [
    'APT. NO.',
    'APT. #',
    'APT. ',
    'APT ',
    'APARTMENT NO.',
    'APARTMENT NUMBER',
    'APARTMENT #',
    'APARTMENT ',
    'SUITE NO.',
    'SUITE #',
    'SUITE ',
    'STE. NO.',
    'STE. #',
    'STE. ',
    'UNIT NO.',
    'UNIT #',
    'UNIT '
  ];

  static standaloneUnitNoPrefixes: string[] = [
    'APT. ',
    'APT ',
    'APARTMENT ',
    'SUITE ',
    'STE. ',
    'UNIT '
  ];

  //item in the array will be part of the UnitNo after parsing
  static inclusiveUnitNoPrefixes: string[] = [
    'PENTHOUSE NO.',
    'PENTHOUSE NUMBER',
    'PENTHOUSE #',
    'PENTHOUSE',
    'PH.',
    'PH#',
    'PH',
    'TH',
    'TOWNHOUSE',
    'TOWNHOUSE #'
  ];

  //the following value consider to be Unit identifier can not following just with characters, eg. 'THE'
  static ambiguousUnitNoPrefixes: string[] = [
    'PH',
    'TH'
  ];

  static poboxPrefixes: string[] = [
    'R.R',
    'RR#',
    'RR #',
    'RR',
    'R. R.',
    'P.O.',
    'P. O.',
    'BOX'
  ];

  static albertaPoBoxPrefixes: string[] = [
    'P.O.',
    'P. O.',
    'BOX'
  ];

  //the array contains the prefix that not treated as PoBox anymore
  //but at same time, may cause confusing with existing poboxPrefixes
  static nonPoboxPrefixes: string[] = [
    'PO BOX'
  ];

  //DPPMP-12240; Look for combinations of
  // 'SOUTH ', 'S ', 'S. ', 'SOUTHEAST ', 'SOUTH EAST ',  'SE ', 'SE. ', 'S.E.',
  // 'SOUTHWEST ', 'SOUTH WEST ',  'SW. ' 'SW ', 'S.W.',  'NORTH ', 'N. ', 'N ', 'NORTHEAST ', 'NORTH EAST ', 'N.E.',  'NE ', 'NE. ',
  // 'NORTHWEST ', 'NORTH WEST ', 'N.W.' , 'NW ', 'NW. ', 'EAST ', 'E. ', 'E ', 'WEST ', 'W. ', W ',
  static streetDirections: string[] = [
    'E', 'E\\.', 'W', 'W\\.', 'N', 'N\\.', 'S', 'S\\.',
    'EAST', 'WEST', 'NORTH', 'SOUTH',
    'QUEST', 'VEST', 'NORD', 'SUD',
    'SOUTHEAST', 'SOUTH EAST', 'SE', 'SE\\.', 'S\\.E\\.',
    'SOUTHWEST', 'SOUTH WEST', 'SW', 'SW\\.', 'S\\.W\\.',
    'NORTHEAST', 'NORTH EAST', 'NE', 'NE\\.', 'N\\.E\\.',
    'NORTHWEST', 'NORTH WEST', 'NW', 'NW\\.', 'N\\.W\\.'
  ];

  //DPPMP-12240; Look for combinations of
  // 'ROAD'. 'RD', 'RD.', 'STREET', 'ST.' ,'ST', 'STR ', 'STR', 'AVENUE', 'AVE.', 'AVE', 'AV', 'AV.',
  //'BOULEVARD', 'BLVD.', 'BLVD', 'COURT',  'CT.', 'CT' , 'CRT.', ' CRT', 'CRESCENT', 'CRES.', 'CRES', 'DRIVE', ' DR.', 'DR',
  //'HIGHWAY', 'HWY.', 'HWY', ' QUAY', 'CIRCLE', 'CIR', 'CIR.' , 'WAY', 'WA', 'WA.' , 'GATE', 'GT', 'GT.', 'LANE', 'LN', 'LN.',
  //'PLACE', 'PL', 'PL.', 'TRAIL', 'TR', TR.'
  static streetTypes: string[] = [
    'ROAD', 'RD', 'RD\\.', 'STREET', 'ST\\.', 'ST', 'STR', 'AVENUE', 'AVE\.', 'AVE', 'AV', 'AV\.',
    'BOULEVARD', 'BLVD\\.', 'BLVD', 'COURT', 'CT\\.', 'CT', 'CRT\\.', 'CRT', 'CRESCENT', 'CRES\\.', 'CRES', 'DRIVE', 'DR\\.', 'DR',
    'HIGHWAY', 'HWY\\.', 'HWY', 'QUAY', 'CIRCLE', 'CIR', 'CIR\\.', 'WAY', 'WA', 'WA\\.', 'GATE', 'GT', 'GT\\.', 'LANE', 'LN', 'LN\\.',
    'PLACE', 'PL', 'PL\\.', 'TRAIL', 'TR', 'TR\\.'
  ];

  static FRENCH_CHARACTERS: string = 'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ';

  static exclusiveCityNames: string[] = [
    'NORTH BATTLEFORD'

  ];

  static careOf: string[] = [ 'C/O', 'CARE OF ' ];

  static isCareOfInAddressLine(addressLine: string): boolean {
    let result: boolean = false;
    if (addressLine) {
      this.careOf.forEach(value => {
        if (addressLine.toUpperCase().indexOf(value) >= 0) {
          result = true;
        }
      });
    }
    return result;
  }

  static swapAddressLines(address: Address): void {
    let addressLine: string = address.addressLine1;
    address.addressLine1 = address.addressLine2;
    address.addressLine2 = addressLine;
  }

  static isCareOfInAddressLines(address: Address, lineNo?: number): boolean {
    if (!address) {
      return false;
    }
    if (!lineNo) {
      return address.addressLine1 && this.isCareOfInAddressLine(address.addressLine1) || address.addressLine2 && this.isCareOfInAddressLine(address.addressLine2);
    } else {
      switch (lineNo) {
        case 1:
          return address.addressLine1 && this.isCareOfInAddressLine(address.addressLine1);
        case 2:
          return address.addressLine2 && this.isCareOfInAddressLine(address.addressLine2);
        default:
          return false;
      }
    }
  }

  /*
  For ISC Backend parser provides jurisdiction reference in propertyAddress.city snd actual city name
  located in propertyAddress.addressLine1 or propertyAddress.addressLine2 depends on content of uploaded ISC PDF.
   */
  static parseOwnerAddressSK(ownerAddress: Address, IsForProperty?: boolean): Address {
    let parsedAddress = new Address();
    let careOfFlag: boolean;
    let addressString = '';
    if (ownerAddress) {
      careOfFlag = this.isCareOfInAddressLines(ownerAddress, 2);
      // Use addressLine1 only for city parsing when Care od reference found
      if (ownerAddress.addressLine2 && !careOfFlag) {
        addressString = ownerAddress.addressLine1 + ' ' + ownerAddress.addressLine2;
      } else {
        addressString = ownerAddress.addressLine1;
        if (IsForProperty) {
          careOfFlag = this.isCareOfInAddressLine(addressString);
        }
      }
      parsedAddress.city = this.parseCityInAddressLine(addressString);
      parsedAddress.addressLine1 = this.removeCityNameFromAddressLine(addressString, parsedAddress.city);
      if (careOfFlag && !IsForProperty) {
        parsedAddress.addressLine2 = ownerAddress.addressLine2;
      } else if (careOfFlag && IsForProperty) {
        if (!ownerAddress.addressLine2) {
          parsedAddress.addressLine1 = '';
        } else {
          parsedAddress.addressLine1 = this.removeCityNameFromAddressLine(addressString, parsedAddress.city);
        }
      }
      if (careOfFlag) {
        this.swapAddressLines(parsedAddress);
      }
      parsedAddress.postalCode = ownerAddress.postalCode;
      parsedAddress.country = ownerAddress.country;
      parsedAddress.provinceCode = ownerAddress.provinceCode;
      parsedAddress.provinceName = ownerAddress.provinceName;
    }
    return parsedAddress;
  }

  private static removeCityNameFromAddressLine(addressLine: string, cityName: string): string {
    return addressLine.replace(cityName, '').trim();
  }

  private static buildMatchExpression(beforeStr: string, valueArr: string[], afterStr: string): string {
    let result = beforeStr ? beforeStr : '';
    result += Array.isArray(valueArr) ? '(' + valueArr.join('|') + ')' : '';
    result += afterStr ? afterStr : '';
    return result;
  }

  static extraERegAddress(addressString: string): any {
    let eRegAddress: any = {};
    if (addressString) {
      eRegAddress.streetNumber = '';
      eRegAddress.streetNoSuffix = '';
      eRegAddress.streetName = '';

      let res = addressString.match(/\d(.*?)\ /g);
      if (res && res.length > 0) {
        let dashSeparatedNumbersPair = this.extractDashSeparatedNumbersPair(res[ 0 ]);
        let streetNumberValue: string = res[ 0 ];
        //Look for part like: 123-33 or 123-34a, eReg only interest the part after dash
        if (dashSeparatedNumbersPair) {
          let addressNumbers = dashSeparatedNumbersPair.split('-');
          if (Array.isArray(addressNumbers) && addressNumbers.length > 1) {
            // eRegAddress.streetNumber = addressNumbers[1].trim();
            streetNumberValue = addressNumbers[ 1 ].trim();
          }
        }
        let street = streetNumberValue.match(/\d+/g);
        if (street && street.length > 0) {
          eRegAddress.streetNumber = street[ 0 ];
        }
        const regex = new RegExp('[a-zA-Z' + this.FRENCH_CHARACTERS + '](.*?)$', 'g');
        let streetSuffix = streetNumberValue.match(regex);
        if (streetSuffix && streetSuffix.length > 0) {
          eRegAddress.streetNoSuffix = streetSuffix[ 0 ];
        }
        eRegAddress.streetName = addressString.replace(res[ 0 ], '');
      } else {
        eRegAddress.streetName = addressString;
      }

    }
    return eRegAddress;
  }

  static extraAddressFromString(addressString: string): Address {
    let address = new Address();
    // let addressPrefix : string = this.extractPrefix(addressString);
    // console.log(addressPrefix);
    parseAddress(addressString, function (err, addressObj) {
      if (addressObj) {
        if (addressObj.city) {
          address.city = addressObj.city;
        }
        if (addressObj.street_address1) {
          address.addressLine1 = addressObj.street_address1;
        }
        if (addressObj.street_address2) {
          address.addressLine2 = addressObj.street_address2;
        }
        if (address.city && addressString.indexOf(address.city) > -1) {
          let res = addressString.substr(0, addressString.indexOf(address.city)).split(',');
          if (res && res.length > 0 && res[ 0 ].trim() != '' && res[ 0 ].trim() != undefined && res[ 0 ].trim() != null) {
            address.addressLine1 = res[ 0 ];
          }

          if (res && res.length > 1 && res[ 1 ].trim() != '' && res[ 1 ].trim() != undefined && res[ 1 ].trim() != null) {
            address.addressLine2 = res[ 1 ];
          }
        }
        if (addressObj.postalCode) {
          address.postalCode = addressObj.postalCode;
        }
        address.provinceCode = 'ON';//required field when try to accesss STG TI
        address.provinceName = 'ONTARIO';

      }
    });
    return address;
  }

  // static extractPrefix(addressLine : string) : string {
  //     let addressPrefix : string = '';
  //     AddressUtil.exclusiveUnitNoPrefixes.forEach(item => {
  //         let regexStr = new RegExp(item + " (.*?)(\\s*)(\\;|\\ |\\,)", "i");
  //         let regexVal = addressLine.toUpperCase().match(regexStr);
  //         if(regexVal && regexVal.length > 0) {
  //             addressPrefix = regexVal[0];
  //         }
  //     });
  //     return addressPrefix;
  // }

  //Parsing jurisdiction department addresses from 6 line format to standard address format.
  //Logic is javascript equivalent of existing Delphi logic in TC.
  static parseDepartmentAddress(addressLines: string[], defaultProvinceCode: string, defaultProvinceName: string, skipDepartmentLine2?: boolean): Address {

    let parsedAddress = new Address();
    let provinceLine: number = 0;
    let postalCodeLine: number = 0;
    let addressLines1: string = skipDepartmentLine2 ? '' : addressLines[ 1 ];

    //Locate the line containing the province
    for (let line: number = 1; line < 6; line++) {
      let currentLine: string = addressLines[ line ];
      let j: number = 0;

      let x: number = currentLine.toUpperCase().indexOf(', ' + defaultProvinceName.toUpperCase()); //search for ', ' + 'ONTARIO'
      if (x >= 0) {
        j = defaultProvinceName.length + 2;
      }

      if (x < 0) {
        x = currentLine.toUpperCase().indexOf(' ' + defaultProvinceName.toUpperCase()); //search for ' ' + 'ONTARIO'
        if (x >= 0) {
          j = defaultProvinceName.length + 1;
        }
      }

      if (x < 0) {
        x = currentLine.toUpperCase().indexOf(', ' + defaultProvinceCode); //search for ', ' + 'ON'
        if (x >= 0) {
          j = defaultProvinceCode.length + 2;
        }
      }

      if (x < 0) {
        x = currentLine.toUpperCase().indexOf(' ' + defaultProvinceCode); //search for ' ' + 'ON'
        if (x >= 0) {
          j = defaultProvinceCode.length + 1;
        }
      }

      if (x >= 0 && j > 0) {
        //found the province if we reach here
        let currentLine2: string = currentLine.slice(x);
        currentLine2 = currentLine2.slice(j).trim();

        //check if the postal code was entered in the same line as the province
        if (this.isValidPostalCode(currentLine2)) {
          parsedAddress.postalCode = currentLine2;
        }

        currentLine = currentLine.slice(0, x).trim();
        parsedAddress.city = currentLine;
        parsedAddress.provinceName = defaultProvinceName;
        parsedAddress.provinceCode = defaultProvinceCode;
        parsedAddress.country = 'CANADA';

        postalCodeLine = line + 1;
        provinceLine = line;

        if (provinceLine == 2) {
          parsedAddress.addressLine1 = (addressLines1 == '' && skipDepartmentLine2) ? addressLines[ 2 ] : addressLines1;
        } else if (provinceLine == 3) {
          parsedAddress.addressLine1 = (addressLines1 == '' && skipDepartmentLine2) ? addressLines[ 2 ] : addressLines1;
          parsedAddress.addressLine2 = (addressLines1 == '' && skipDepartmentLine2) ? '' : addressLines[ 2 ];
        } else if (provinceLine == 4) {
          parsedAddress.addressLine1 = addressLines1 + (skipDepartmentLine2 ? '' : ', ') + addressLines[ 2 ];
          parsedAddress.addressLine2 = addressLines[ 3 ];
        } else if (provinceLine == 5) {
          parsedAddress.addressLine1 = addressLines1 + (skipDepartmentLine2 ? '' : ', ') + addressLines[ 2 ];
          parsedAddress.addressLine2 = addressLines[ 3 ] + ', ' + addressLines[ 4 ];
        }

        break; //exit loop
      }
    }

    //Check if postal code entered on line by itself
    if (postalCodeLine > 0 && postalCodeLine < 6 && !parsedAddress.postalCode) {
      if (this.isValidPostalCode(addressLines[ postalCodeLine ])) {
        parsedAddress.postalCode = addressLines[ postalCodeLine ];
      }
    }

    return parsedAddress;
  }

  static isValidPostalCode(postalCode: string): boolean {
    if (postalCode.length == 7 && postalCode[ 0 ].match(/[A-Z]/) && !isNaN(postalCode[ 1 ] as any) && postalCode[ 2 ].match(/[A-Z]/)
      && postalCode[ 3 ] == ' ' && !isNaN(postalCode[ 4 ] as any) && postalCode[ 5 ].match(/[A-Z]/) && !isNaN(postalCode[ 6 ] as any)) {
      return true;
    }

    return false;
  }

  /**
   * Extract the unit number if it's following one of the accepted unit number labels
   * @param {string} addressLine
   * @returns {string}
   */
  static extractPrefixedUnitNumber(addressLine1: string, separator?: string, addressLine2?: string, provinceCode?: string): string {
    return AddressUtil.parseAddressLine(this.getAddressLineForParsing(addressLine1, separator, addressLine2), false, provinceCode)[ 'unitNumber' ];
  }

  private static getAddressLineForParsing(addressLine1: string, separator?: string, addressLine2?: string): string {
    let addressLine = addressLine1 ? addressLine1 : '';

    if (addressLine2) {
      if (separator && addressLine1) {
        addressLine += separator + addressLine2;
      } else {
        addressLine += addressLine2;
      }
    }
    return addressLine;
  }

  static extractParsedAddress(addressLine1: string, separator?: string, addressLine2?: string, provinceCode?: string): string {
    return AddressUtil.parseAddressLine(this.getAddressLineForParsing(addressLine1, separator, addressLine2), false, provinceCode);
  }

  private static extractPrefixedUnitNumberComponents(addressLine: string): {} {
    let unitNumberComponents: any = {};
    if (!addressLine) {
      return unitNumberComponents;
    }

    let uppercasedAddressLine = addressLine.toUpperCase();
    let matchFound: boolean = false;
    for (let i = 0; i < AddressUtil.exclusiveUnitNoPrefixes.length; i++) {
      let prefix: string = AddressUtil.exclusiveUnitNoPrefixes[ i ];
      let prefixPosition = uppercasedAddressLine.indexOf(prefix);
      if (prefixPosition != 0 && this.standaloneUnitNoPrefixes.indexOf(prefix) > -1) {
        //if found specific prefix (like UNIT<space>) not at the beginning of the address, then need to match against (<space>UNIT<space>)
        prefixPosition = uppercasedAddressLine.indexOf(' ' + prefix);
      }

      if (prefixPosition > -1) {
        unitNumberComponents.unitPrefix = prefix;
        let partAfterPrefix = addressLine.substr(prefixPosition + prefix.length).trim();
        //any number/letter combination after the the prefix should be treat as Unit#
        let numberMatches = partAfterPrefix.match(/^\s*[a-zA-Z0-9]+\s*/);
        if (!numberMatches) {
          continue;
        }
        matchFound = true;
        if (numberMatches && Array.isArray(numberMatches) && numberMatches.length > 0) {
          unitNumberComponents.unitNumber = numberMatches[ 0 ].trim().match(/[a-zA-Z0-9]+/)[ 0 ];
          //Extract the full suite description using substring, to account for various numbers of spaces
          unitNumberComponents.unitDescription = addressLine.substring(prefixPosition,
            addressLine.indexOf(unitNumberComponents.unitNumber, prefixPosition) + unitNumberComponents.unitNumber.length).trim();
        } else {
          unitNumberComponents.unitDescription = unitNumberComponents.unitPrefix;
        }
        break;
      }

    }
    if (!matchFound) {
      for (let i = 0; i < AddressUtil.inclusiveUnitNoPrefixes.length; i++) {
        let prefix: string = AddressUtil.inclusiveUnitNoPrefixes[ i ];
        // let prefixPosition = uppercasedAddressLine.indexOf(prefix);
        let expression = '[ ,]+' + this.escapeRegExp(prefix) + '|^' + this.escapeRegExp(prefix);
        let rx = new RegExp(expression, 'i');
        let matches = rx.exec(addressLine);//uppercasedAddressLine.match(rx);
        // console.log("First Found prefix ["+ expression +"] in ["+ uppercasedAddressLine+"] at : " + (matches ? matches.index : '-1'));
        // let needAppearAtBeginning : boolean = AddressUtil.startsWithUnitNoPrefixes.indexOf(prefix) > -1;
        let prefixPosition = matches ? matches.index : -1;
        if (prefixPosition > -1) {
          // if ((needAppearAtBeginning && prefixPosition == 0) || (!needAppearAtBeginning && prefixPosition > -1)) {
          let rawPrefix: string = matches[ 0 ]; //rawPrefix include the ,or space with the real prefix
          unitNumberComponents.unitPrefix = rawPrefix.substring(rawPrefix.toUpperCase().indexOf(prefix));//take the prefix as is, not upperCasesd
          let partAfterPrefix = addressLine.substr(prefixPosition + matches[ 0 ].length);
          //any number/letter combination after the the prefix should be treat as Unit#
          let numberMatches = partAfterPrefix.match(/^\s*[a-zA-Z0-9]+\s*/);

          matchFound = true;
          if (numberMatches && Array.isArray(numberMatches) && numberMatches.length > 0) {
            //for PH or TH, need to make sure there is no case like only Character after it, like 'THE', 'THESE', 'PHONE' to be treated at Unit#
            if (AddressUtil.ambiguousUnitNoPrefixes.indexOf(prefix) > -1 && numberMatches[ 0 ].search(/^[A-Za-z]+\s*$/) > -1) {
              matchFound = false;
              unitNumberComponents = {};
              continue;
            }
            unitNumberComponents.unitNumber = unitNumberComponents.unitPrefix + numberMatches[ 0 ]; //+ " " + numberMatches[0].trim().match(/[a-zA-Z0-9]+/)[0];
            //Extract the full suite description using substring, to account for various numbers of spaces
            unitNumberComponents.unitDescription = addressLine.substr(prefixPosition, matches[ 0 ].length + numberMatches[ 0 ].length).trim();
          } else {
            unitNumberComponents.unitNumber = unitNumberComponents.unitPrefix;
            unitNumberComponents.unitDescription = unitNumberComponents.unitPrefix;
          }
          break;
        }

      }
    }
    return unitNumberComponents;

  }

  static escapeRegExp(string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  public static extractPoBoxData(addressLine: string, provinceCode?: string): { poBoxPrefix: string, poBoxNumber: string } {
    let poBoxPrefixes;
    if (provinceCode == PROVINCE_CODES.ALBERTA) {
      poBoxPrefixes = AddressUtil.albertaPoBoxPrefixes;
    } else {
      poBoxPrefixes = AddressUtil.poboxPrefixes;
    }

    if (!addressLine) {
      return null;
    }
    let addressLineUpper = addressLine.toUpperCase();
    let poPrefix: string;
    let prefixPos: number;

    for (let i = 0; i < poBoxPrefixes.length; i++) {
      poPrefix = poBoxPrefixes[ i ];
      let relatedNonPoboxPrefs: string[] = this.nonPoboxPrefixes.filter(nonPrefix => nonPrefix.indexOf(poPrefix) > -1);
      if (Array.isArray(relatedNonPoboxPrefs) && relatedNonPoboxPrefs.length > 0 && relatedNonPoboxPrefs.some(nonPoboxPref => addressLineUpper.indexOf(nonPoboxPref) > -1)) {
        // console.log("!! address %s contains the NonPobox Keyword %s, so stop parsing poBox data using PoBox Keyword [%s]", addressLine, relatedNonPoboxPrefs, poPrefix);
        continue;
      }

      prefixPos = addressLineUpper.indexOf(poPrefix);
      if (prefixPos > -1) {
        let partAfterPrefix: string = addressLine.substr(prefixPos + poPrefix.length).trim();
        let numberMatches = partAfterPrefix.match(/(^|^\#\s*|^\s*)\d+/);
        if (numberMatches && Array.isArray(numberMatches) && numberMatches.length > 0) {
          return {poBoxPrefix: poBoxPrefixes[ i ], poBoxNumber: numberMatches[ 0 ].trim().match(/\d+/)[ 0 ]};
        }
      }
    }
    return null;
  }

  //not more just number pair, the first part has been expanded to contains any characters
  //the second part also can contains AlphaNumber like 123-456A
  static extractDashSeparatedNumbersPair(addressLine: string): string {
    // let dashSeparatedNumbers = addressLine.trim().match(/^\d+\s*-\s*\d+/);
    //From Production Issue, TI got address starts with 3F3-400, then anything before number/character/space before dash is UnitNo.
    let dashSeparatedNumbers = addressLine.trim().match(/^[A-Za-z0-9 ]+\s*-\s*[A-Za-z0-9]+/);
    let addressWords = addressLine.split(' ');
    let isFirstWordWithDash = Array.isArray(addressWords) && addressWords.length > 0 && addressWords[ 0 ].indexOf('-') > 0;
    if (dashSeparatedNumbers && Array.isArray(dashSeparatedNumbers) && dashSeparatedNumbers.length > 0 && isFirstWordWithDash) {
      let valueContainsDash: string = dashSeparatedNumbers[ 0 ];
      let unitNoPrefix: string[] = AddressUtil.exclusiveUnitNoPrefixes.slice();
      unitNoPrefix.concat(AddressUtil.inclusiveUnitNoPrefixes);
      for (let i = 0; i < unitNoPrefix.length; i++) {
        let prefix: string = unitNoPrefix[ i ];
        if (valueContainsDash && valueContainsDash.toUpperCase().startsWith(prefix)) {
          //value like Unit 1  - 123 from (Unit 1 - 123 Yonge)
          //then not belong to dash pattern
          return '';
        }
      }
      return valueContainsDash;
    }
    // return dashSeparatedNumbers && Array.isArray(dashSeparatedNumbers) && dashSeparatedNumbers.length > 0 ? dashSeparatedNumbers[0] : '';
    return '';
  }

  /**
   * Extract the suite number, street number and street name from an address line
   *
   * @param {string} addressLine
   * @returns {any}: {unitNumber: '123', streetNumber: '234', streetName: 'Yonge'}
   */
  static parseAddressLine(addressLine: string, caseSensitive: boolean = false, provinceCode?: string): any {

    let addressComponents: any = {};
    if (addressLine) {
      //replace any non alphanumeric characters from the start of the addressline
      //handle '#@#1-1king street' as '1-1king street' as in US21267
      const regex = new RegExp('^\\s*[^A-Za-z0-9' + this.FRENCH_CHARACTERS + ']*\\s*');
      addressLine = addressLine.replace(regex, '');
      //remove the unexpected special characters from the address, may not needed right now
      // addressLine = addressLine.replace(/^[A-Za-z0-9#., \u0000-\u007F]$/g, '');
    }
    let dashSeparatedNumbersPair = this.extractDashSeparatedNumbersPair(addressLine);
    //Look for a 123-33 Toronto St format address first (suite number and street number at the front, dash separated
    if (dashSeparatedNumbersPair) {
      let addressNumbers = dashSeparatedNumbersPair.split('-');
      addressComponents.unitNumber = addressNumbers[ 0 ].trim();
      addressComponents.streetNumber = addressNumbers[ 1 ].trim();
      addressComponents.streetAddress = addressLine.replace(dashSeparatedNumbersPair, '').trim(); //need by FCT submit data
      addressComponents.streetName = this.cleanUpStreetName(addressLine.replace(dashSeparatedNumbersPair, '')).toUpperCase();
      return addressComponents;
    }

    //Try to isolate the suite number based on suite number label
    let prefixedUnitNumberComponents = this.extractPrefixedUnitNumberComponents(addressLine);
    if (prefixedUnitNumberComponents[ 'unitNumber' ]) {
      addressComponents.unitNumber = prefixedUnitNumberComponents[ 'unitNumber' ];
    }
    if (prefixedUnitNumberComponents[ 'unitDescription' ]) {
      //We get the suite part out of the way, as we'll continue to look for street number and name
      addressLine = addressLine.replace(prefixedUnitNumberComponents[ 'unitDescription' ], '');
    }
    //handle the left over of the pattern like; Unit 123 - 400 Yonge St. (after extract the Unit No portion Unit 123)
    addressLine = addressLine.replace(/^\s*-\s*/, '');
    //check the PO BOX
    let poBoxData: { poBoxPrefix: string, poBoxNumber: string } = this.extractPoBoxData(addressLine, provinceCode);
    if (poBoxData) {
      addressComponents.streetNumber = poBoxData.poBoxNumber;
      let poBoxPrefixLoc: number = addressLine.toUpperCase().indexOf(poBoxData.poBoxPrefix);
      addressLine = addressLine.toUpperCase().replace(poBoxData.poBoxPrefix, '');
      addressLine = this.getRemainingAsStreetName(addressLine, addressComponents.streetNumber, caseSensitive, poBoxPrefixLoc);
    }

    //Use the first number from the right as the street number if not found so far
    if (!addressComponents.streetNumber) {
      // let numberMatches = addressLine.match(/(^|\s|\#|\.)\d+(\s|$|,|;)/g);
      let numberMatches = addressLine.match(/(^\s*|^\#\s*|\,\s*|\.)\d+(\s|$|,|;)/g);
      if (numberMatches && Array.isArray(numberMatches) && numberMatches.length > 0) {
        addressComponents.streetNumber = numberMatches[ numberMatches.length - 1 ].trim().match(/\d+/)[ 0 ];
        addressLine = this.getRemainingAsStreetName(addressLine, addressComponents.streetNumber, caseSensitive);
      }
    }
    addressComponents.streetAddress = addressLine ? addressLine.trim() : '';
    addressComponents.streetName = this.cleanUpStreetName(addressLine);

    return addressComponents;

  }

  private static getNotEmptyDisplayableText(value: string, caseSensitive: boolean = false): string {
    if (value) {
      const regex = new RegExp('^\\W|[^A-Za-z0-9' + this.FRENCH_CHARACTERS + '_\\\\.]$', 'g');
      value = value.replace(regex, '');
      value = value.replace(/[\,;]/, ' ');
      value = value.replace('  ', ' ').trim();
      if (!caseSensitive) {
        value = value.toUpperCase();
      }
      return value;
    }
    return '';
  }

  //if there is text after the streetNumber, then remove the text before the streetNumber
  //if not caseSensitive, then the StreetName will be UpperCase, otherwise return case as input value
  private static getRemainingAsStreetName(addressValue: string, streetNumber: string, caseSensitive: boolean = false, streetNumberStartSearchLoc: number = 0): string {
    let textAfterStreetNumber = '';
    if (addressValue && streetNumber) {
      let streetNumLoc = addressValue.indexOf(streetNumber, streetNumberStartSearchLoc);
      if (streetNumLoc != -1) {
        textAfterStreetNumber = this.getNotEmptyDisplayableText(addressValue.substring(streetNumLoc + streetNumber.length), caseSensitive);
        if (textAfterStreetNumber.length > 0) {
          addressValue = textAfterStreetNumber;
        } else {
          addressValue = this.getNotEmptyDisplayableText(addressValue.substring(0, streetNumLoc), caseSensitive);
        }
      }
    }
    return addressValue;
  }

  private static cleanUpStreetName(streetName: string): string {
    if (!streetName) {
      return '';
    }
    const regex = new RegExp('^\\W|[^A-Za-z0-9' + this.FRENCH_CHARACTERS + '_\\\\.]$', 'g');
    let cleanedUpName: string = streetName.trim().replace(regex, '');

    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(E|E\\.|W|W\\.|N|N\\.|S|S\\.|O|O\\.)(\\s|\\,|$)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(EAST|WEST|NORTH|SOUTH)(\\s|\\,|$)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(Ouest|Vest|Nord|Sud)(\\s|\\,|$)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(SOUTHEAST|SOUTH EAST|SE|SE\\.|S\\.E\\.)(\\s|\\,|$)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(SOUTHWEST|SOUTH WEST|SW|SW\\.|S\\.W\\.)(\\s|\\,|$)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(NORTHEAST|NORTH EAST|NE|NE\\.|N\\.E\\.)(\\s|\\,|$)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(NORTHWEST|NORTH WEST|NW|NW\\.|N\\.W\\.)(\\s|\\,|$)", true);

    let streetDirectionMatchExp: string = this.buildMatchExpression('\\s', this.streetDirections, '(\\s|\\,|$)');
    // console.log("@@ streetDirectionMatchStr: " + streetDirectionMatchExp);
    cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, streetDirectionMatchExp, true);

    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(RD|RD\\.|ROAD)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(ST|ST\\.|STREET|STR)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(AVE|AVE\\.|AV|AV.|AVENUE)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(BOULEVARD|BLVD|BLVD\\.|COURT|CT\\.|CT)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(CRT|CRT\\.|CRESCENT|CRES|CRES\\.)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(DRIVE|DR\\.|DR)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(HIGHWAY|HWY|QUAY)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(CIRCLE|CIR|CIR\\.)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(WAY|WA|WA\\.)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(GATE|GT|GT\\.)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(LANE|LN|LN\\.)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(PLACE|PL|PL\\.)($|\\s|\\,)", true);
    // cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, "\\s(TRAIL|TR|TR\\.)($|\\s|\\,)", true);

    let streetTypeMatchExp: string = this.buildMatchExpression('\\s', this.streetTypes, '(\\s|\\,|$)');
    // console.log("@@ streetTypeMatchStr: " + streetTypeMatchExp);
    cleanedUpName = this.cleanupTextAfterStreetDirectionOrType(cleanedUpName, streetTypeMatchExp, true);

    cleanedUpName = cleanedUpName.replace(/[\,;]/, ' ').replace('  ', ' ');

    return cleanedUpName.trim().toUpperCase();
  }

  private static cleanupTextAfterStreetDirectionOrType(streetName: string, expression: string, ignoreCase?: boolean) {
    if (streetName && expression) {
      let matchFlags: string = ignoreCase ? 'gi' : 'g';
      let regex = new RegExp(expression, matchFlags);
      let splitStreetNames: string[] = streetName.split(regex);
      streetName = splitStreetNames[ 0 ];
    }
    return streetName;
  }

  public static populateParsedData(address: Address): void {
    if (address) {
      address.parsedUnitNo = '';
      address.parsedStreetNumber = '';
      address.parsedStreetName = '';
      address.parsedStreetAddress = '';
      if (address.addressLine1 || address.addressLine2) {
        let addressLine: string = address.addressLine1 ? (address.addressLine2 ? address.addressLine1 + ', ' + address.addressLine2 : address.addressLine1) : address.addressLine2;
        //the parsed Address data will be used to build the request to other party (FCT), need to keep the data as is, not convert to UpperCase
        let parsedAddressLines = AddressUtil.parseAddressLine(addressLine, true, address.provinceCode);
        if (parsedAddressLines) {
          address.parsedUnitNo = parsedAddressLines[ 'unitNumber' ] ? parsedAddressLines[ 'unitNumber' ].substring(0, 35) : ''; //"unitNumber" field has limitation of 35 charachters
          address.parsedStreetNumber = parsedAddressLines[ 'streetNumber' ] ? parsedAddressLines[ 'streetNumber' ].substring(0, 35) : ''; //"streetNumber" field has limitation of 35 charachters
          address.parsedStreetName = parsedAddressLines[ 'streetName' ];
          address.parsedStreetAddress = parsedAddressLines[ 'streetAddress' ];
          // console.dir(address);
        }
      }
    }
  }

  private static parseCityWithPOBoxInAddressLine(addressLine: string): string {
    let poBoxData: { poBoxPrefix: string, poBoxNumber: string } = AddressUtil.extractPoBoxData(addressLine);
    if (poBoxData) {
      if (addressLine.toUpperCase().includes('STN')) {
        let parsedSubStr = addressLine.substring(addressLine.indexOf('STN') + 3).trim();
        return addressLine.substring(addressLine.indexOf('STN'), addressLine.indexOf(' ')).trim();

      } else if (addressLine.toUpperCase().includes('STATION')) {
        let parsedSubStr = addressLine.substring(addressLine.indexOf('STATION') + 7).trim();
        return parsedSubStr.substring(parsedSubStr.indexOf(' ')).trim();
      } else {
        return addressLine.substring(addressLine.indexOf(poBoxData.poBoxNumber) + poBoxData.poBoxNumber.length).trim();
      }
    }
  }

  private static parseCityWithStreetTypeOrDirectionInAddressLine(addressLine: string): string {
    let regexStreetType = new RegExp(AddressUtil.buildMatchExpression('\\s', AddressUtil.streetTypes, '(\\s|\\,|$)'), 'i');
    let regexStreetDirection = new RegExp(AddressUtil.buildMatchExpression('\\s', AddressUtil.streetDirections, '(\\s|\\,|$)'), 'i');
    if (regexStreetDirection.test(addressLine)) {
      let regexStreetDirectionVal = regexStreetDirection.exec(addressLine)[ 0 ].trim();
      return addressLine.substring(addressLine.indexOf(regexStreetDirectionVal) + regexStreetDirectionVal.length).trim();
    } else if (regexStreetType.test(addressLine)) {
      let regexCityTypeVal = regexStreetType.exec(addressLine)[ 0 ].trim();
      return addressLine.substring(addressLine.indexOf(regexCityTypeVal) + regexCityTypeVal.length).trim();
    }
  }

  static parseCityInAddressLine(addressString: string): string {
    if (addressString.toUpperCase().includes('N/A')) {
      return addressString.replace('N/A', '').trim();
    } else {
      let poBoxData: { poBoxPrefix: string, poBoxNumber: string } = AddressUtil.extractPoBoxData(addressString);
      if (poBoxData) {
        return AddressUtil.parseCityWithPOBoxInAddressLine(addressString);
      } else {
        return AddressUtil.parseCityWithStreetTypeOrDirectionInAddressLine(addressString);
      }
    }
  }

  static extractCityFromAddressLine(addressLine: string): string {
    let isCityMatchesWithExclusiveList: boolean = false;
    let matchedCityName: string = '';
    for (let i = 0; i < AddressUtil.exclusiveCityNames.length; i++) {
      let cityName: string = AddressUtil.exclusiveCityNames[ i ];
      if (addressLine.toUpperCase().includes(cityName)) {
        isCityMatchesWithExclusiveList = true;
        matchedCityName = cityName;
        break;
      }
    }
    if (isCityMatchesWithExclusiveList && matchedCityName != '') {
      return addressLine.substring(addressLine.toUpperCase().indexOf(matchedCityName)).trim();
    } else {
      return AddressUtil.parseCityInAddressLine(addressLine);
    }
  }

  static addProvinceCodeAction(address: Address, localProvinceName: string): void {
    address.provinceCode = null;
    address.provinceName = localProvinceName;
    for (let i = 0; i < dropDowns.provinceCodeValidator.length; i++) {
      if (address.provinceName) {
        let provincename = address.provinceName.toUpperCase();
        let ddprovincename = dropDowns.provinceCodeValidator[ i ].label.toUpperCase();
        if (provincename === ddprovincename) {
          address.provinceCode = dropDowns.provinceCodeValidator[ i ].value;
        }
      }
    }
    address.setAddressHash();
  }

  static populateProvinceAction(address: Address, defaultForProvinceAndCountry: boolean, localProvinceName: string): void {
    if (address && address.provinceCode == undefined && defaultForProvinceAndCountry) {
      this.addProvinceCodeAction(address, localProvinceName);
    }
  }

  //Moving from defaultProvinceValue of AddressFormComponent, the logic don't do any change except to add some check
  //I don't understand why it return "" when it uses matterProvince
  static setDefaultProvinceValue(address: Address, defaultForProvinceAndCountry: boolean, cachedDocumentProfile: DocumentProfile, matterProvinceCode: string, localObject: any): string {
    if (!defaultForProvinceAndCountry) {
      localObject.localProvinceName = '';
      this.populateProvinceAction(address, defaultForProvinceAndCountry, localObject.localProvinceName);
      return '';
    }
    if (cachedDocumentProfile && cachedDocumentProfile.firmDocumentProfile && cachedDocumentProfile.firmDocumentProfile.address && cachedDocumentProfile.firmDocumentProfile.address.provinceName) {
      localObject.cachedDefaultProvinceName = cachedDocumentProfile.firmDocumentProfile.address.provinceName;
      localObject.localProvinceName = localObject.cachedDefaultProvinceName;
      this.populateProvinceAction(address, defaultForProvinceAndCountry, localObject.localProvinceName);
      return cachedDocumentProfile.firmDocumentProfile.address.provinceName;
    } else {
      if (localObject.cachedDefaultProvinceName) {
        return localObject.cachedDefaultProvinceName;
      } else {
        let province = provinces.find(province => province.code == matterProvinceCode);
        //Add check if province existing and avoid to break application
        if (province) {
          localObject.cachedDefaultProvinceName = province.name;
          localObject.localProvinceName = localObject.cachedDefaultProvinceName;
          this.populateProvinceAction(address, defaultForProvinceAndCountry, localObject.localProvinceName);
        }
        return ''; //I don't understand why it return ""
      }
    }
  }
}
