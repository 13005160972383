import {Component, Inject} from '@angular/core';
import * as _ from 'lodash';
import {LtsaUser} from '../../../shared-main/ltsa/ltsa-user';
import {LtsaService} from '../../../shared-main/ltsa/ltsa-service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export type ManageLtsaUserAction = 'Add' | 'Edit';

export class ManageLtsaUserModalContext {
  action: ManageLtsaUserAction;
  ltsaUser: LtsaUser;
  userId: string;
}

@Component({
  selector: 'dp-manage-ltsa-user-modal',
  templateUrl: 'manage-ltsa-user.modal.component.html'
})
@FocusFirstElementDecorator()
export class ManageLtsaUserModal extends ModalComponent<ManageLtsaUserModalContext> {
  ltsaUser: LtsaUser;

  constructor(public dialog: MatDialogRef<ManageLtsaUserModal>,
              public dialogService: DialogService,
              public ltsaService: LtsaService,
              @Inject(MAT_DIALOG_DATA) context?: ManageLtsaUserModalContext
  ) {
    super(dialog, context);
    this.ltsaUser = _.cloneDeep(this.context.ltsaUser);
  }

  get pageTitle(): string {
    return this.context.action === 'Edit' ? 'Edit Credential' : 'Add Credential';
  }

  saveUser(): void {
    this.ltsaService.saveLtsaUser(this.ltsaUser).subscribe(
      (ltsaUser: LtsaUser) => {
        this.context.ltsaUser = ltsaUser;
        this.dialog.close({
          action: 'OK',
          ltsaUser: ltsaUser
        });
      });
  }

  cancel(): void {
    this.dialogService.confirmUnsavedChange(true).subscribe(
      (response: any) => {
        if (response === 'DONT_SAVE') {
          this.dialog.close({action: 'Cancel'});
        } else if (response === 'SAVE') {
          this.saveUser();
        }
      });
  }
}
