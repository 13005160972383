<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Submit Request for Transaction Reset</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 padding-bottom-10 boldfont">
            {{matter.matterRecordNumber}}
        </div>
        <div class="col-lg-12 padding-bottom-20 boldfont">
            {{requestInfo}}
        </div>

        <div class="col-lg-12 padding-bottom-10">
            <p class="padding-bottom-10">In situations where it appears justified, Unity Software will cooperate in resetting the charge for this transaction to zero.</p>
            <p class="padding-bottom-10">Transaction fees will only be waived if your firm was not retained to act, but will not be waived due to failure to complete the transaction.</p>
            <p class="padding-bottom-10">By selecting "Submit Request", you are certifying that this reset meets the condition as described above.</p>
            <p class="padding-bottom-10">Unity reserves the right to investigate all Resets.</p>
        </div>
        <div class="col-lg-12 padding-bottom-10">
            <div class="col-lg-2">
                <label class="control-label"
                       for="certifiedBy">
                    Certified By
                </label>
            </div>
            <div class="col-lg-6">
            <input type="text"
                   id="certifiedBy"
                   name="certifiedBy"
                   class="form-control"
                   [(ngModel)]="certifiedBy"
            />
            </div>
        </div>


    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="submit()" [attr.disabled]="isValid? null: true">
                <span>Submit Request</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
