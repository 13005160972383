import {FlaData} from '../shared/fla-data';
import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class SpouseTypeSelectionModalContext {
  public flaStatements: FlaData;
}

@Component({
  selector: 'dp-spouse-type-selection-modal',
  templateUrl: './spouse-type-selection-modal.component.html',
  styleUrls: ['./spouse-type-selection-modal.component.scss'],
  providers: [ErrorService]
})
export class SpouseTypeSelectionModal extends ModalComponent<SpouseTypeSelectionModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  notMatrimonialHome: boolean;
  spouseBySeparationAgreement: boolean;
  spouseByVirtueOfOrder: boolean;
  designatedAnotherPropertyAsMatrimonialHome: boolean;
  statementType: string;

  constructor(
    public dialog: MatDialogRef<SpouseTypeSelectionModal>,
    @Inject(MAT_DIALOG_DATA) context?: SpouseTypeSelectionModalContext
  ) {
    super(dialog, context);
  }

  cleanAllCheckBox() {
    this.notMatrimonialHome = false;
    this.spouseBySeparationAgreement = false;
    this.spouseByVirtueOfOrder = false;
    this.designatedAnotherPropertyAsMatrimonialHome = false;
  }

  setFlaSpouseType(event, statementType: string): void {
    if (event) {
      this.cleanAllCheckBox();
      this.statementType = statementType;
    } else {
      this.statementType = null;
    }
  }

  public getDescription(itemName: string): string {
    let flaTypeDescription: string = '';
    if (this.context.flaStatements && this.context.flaStatements.getFlaStatementByTypeCode(itemName)) {
      flaTypeDescription = this.context.flaStatements.getFlaStatementByTypeCode(itemName).flaTypeDescription;
    }
    return flaTypeDescription;
  }

  cancel() {
    this.dialog.close();
  }

  ok() {
    this.modalErrorComponent.removeAllDpSaveError();
    if (
      this.notMatrimonialHome ||
      this.spouseBySeparationAgreement ||
      this.spouseByVirtueOfOrder ||
      this.designatedAnotherPropertyAsMatrimonialHome
    ) {
      this.dialog.close({statementType: this.statementType});
    } else {
      this.modalErrorComponent.createCustomDPSaveError(
        'none',
        'One of the above Spousal Status statement must be selected.',
        'Contact',
        'ERROR'
      );
    }
  }
}
