<div class="container-fluid custom-modal-container border-radius">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10"><h1 [dp-province-field-label]="'matter.undertaking.permittedRegistrations'">Permitted Registrations</h1></div>
                <button type="button" class="btn-close pull-right" (click)="cancelDialog()" >&times;</button>
            </div>
        </div>
        <div class="row modal-body">
            <div>
                <div class="form-group">
                        <div class="inp col-lg-10">
                            <input
                                tabindex="0"
                                [maxlength]="getPermittedRegistrationMaxLength()"
                                class="form-control"
                                placeholder=""
                                autofocus
                                [(ngModel)]="newListItemText"
                                (keyup.enter)="addNewListItem()">
                        </div>
                        <div class="addNew col-lg-2">
                            <input
                                tabindex="0"
                                type="button"
                                class="secondary-button"
                                (click)="addNewListItem()"
                                value="Add new"
                                [disabled]="!newListItemText"
                            >
                        </div>

                </div>
                <div class="main col-lg-12">
                    <ul #scrollable class="permittedRegistrationList">
                        <li *ngFor="let list of permittedRegistrationsLocal; let i=index;"
                            tabindex="0"
                            [ngClass]="{'active': activeListItem==i }"
                            (focus)="setActiveIndex(i)"
                            (dblclick)="editItem()"
                            (keydown)="onSelectionChange($event)"
                        >
                            <div class="view">
                                <label *ngIf="!activeEditing || activeListItem!=i">{{list.permittedRegistrationValue}}</label>
                                <input
                                    *ngIf="activeEditing && activeListItem==i"
                                    #commentEdit
                                    [attr.maxlength]="getPermittedRegistrationMaxLength()"
                                    class="edit"
                                    [value]="list.permittedRegistrationValue"
                                    (keyup.enter)="updateItem($event, commentEdit.value, i)"
                                    (blur)="updateItem($event, commentEdit.value, i)"
                                    id="commentEdit_{{i}}"
                                    (keydown)="cancelComment($event);"
                                >
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    <div class="btn2 col-lg-12 modal-footer">
        <div class="col-lg-8 offset-lg-2">
            <input
                type="button"
                class="dp-btn keyboard-save-action"
                (click)="ok()"
                value="Ok">
            <input
                type="button"
                class="secondary-button"
                [disabled]="activeListItem<0 || activeListItem==0 || permittedRegistrationsLocal.length==0"
                (click)="moveUp()"

                value="Move Up">
            <input
                type="button"
                class="secondary-button"
                [disabled]="activeListItem<0 || activeListItem==permittedRegistrationsLocal.length-1  || permittedRegistrationsLocal.length==0"
                (click)="moveDown()"
                value="Move Down">
            <input
                type="button"
                class="secondary-button"
                (click)="editItem()"
                [disabled]="(activeListItem<0) || permittedRegistrationsLocal.length==0"
                value="Edit">
            <input
                type="button"
                class="secondary-button"
                (click)="deleteItem()"
                [disabled]="activeListItem<0 || permittedRegistrationsLocal.length==0"
                value="Delete">
            <input
                type="button"
                class="secondary-button"
                (click)="cancelDialog()"
                value="Cancel">
        </div>
    </div>
</div>

