import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {SelectItem} from 'primeng/api';
import {MatterTypeInfo} from '../../admin/shared/matter-type-info';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {AccountService} from '../../admin/accounts/account.service';
import {DPError} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {
  Contact,
  ContactService,
  Matter,
  MatterProperty,
  MatterService,
  MatterType,
  MatterTypesValue,
  OpportunityMatterStatusValue
} from '../../matters';
import {LockScreenService} from '../../core/lock-screen.service';
import {CopyOpportunityMatterService} from '../../matters/copy-opportunity-matter.service';
import moment from 'moment';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs/Subject';
import {TabsService} from '../../core';
import {SoaTrustLedgerHelperService} from '../../shared/soa-trustledger-helper.service';
import * as _ from 'lodash';
import {MatterLink} from '../../matters/matter-opening/matter-link';
import {CopyMatterLinkDataService} from '../../matters/copy-matter-link-data.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {CopyToExistingMatterComponent} from '../copy-to-existing-matter/copy-to-existing-matter.modal.component';
import {ModalResult} from '../../shared-main/enums';
import {AppConfig} from '../../shared-main/app-configuration';
import {UtilsService} from '../../main/utils.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class ConvertToMatterModalContext {
  opportunityMatter: Matter;
  copyOpportunityMatterService: CopyOpportunityMatterService;
  copyMatterLinkDataService: CopyMatterLinkDataService;
  onlyAllowImportToExisting: boolean;
}

@Component({
  selector: 'dp-convert-to-matter-modal',
  templateUrl: 'convert-to-matter.modal.component.html',
  styleUrls: ['./convert-to-matter.modal.component.scss'],
  providers: [ErrorService]
})
export class ConvertToMatterModalComponent extends ModalComponent<ConvertToMatterModalContext> implements OnInit {
  selectedMatterTypeCode: string;
  selectedProvinceCode: ProvinceCode;
  matterTypeOptions: SelectItem[];
  provinceOptions: SelectItem[];
  matterRecordNumber: string;
  fileNumber: string;
  accountingNumber: string;
  linkedMatterRecordNumber: string;
  linkedFileNumber: string;
  linkedAccountingNumber: string;
  isConvertToNewMatter: boolean = true;
  userProvinces: string[] = [];
  selectedExistingMatter: Matter = new Matter();
  matterSerachText: any;

  searchTermStreamMatter: Subject<string> = new Subject<string>();
  matterList: any;
  autoCompleteEmptyFlag: boolean;
  matterSearchLoading: boolean;
  comingSoonOption: string = ' - Coming Soon';
  @ViewChild('matterTypeCtrl') matterTypeCtrl;

  constructor(
    public dialog: MatDialogRef<ConvertToMatterModalComponent>,
    public userStateService: UserStateService,
    public accountService: AccountService,
    public lockScreenService: LockScreenService,
    public matterService: MatterService,
    public tabsService: TabsService,
    public soaTrustLedgerHelperService: SoaTrustLedgerHelperService,
    public errorService: ErrorService,
    public contactService: ContactService,
    public dialogService: DialogService,
    public appConfig: AppConfig,
    public utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) context?: ConvertToMatterModalContext
  ) {
    super(dialog, context);
  }

  async createMatter() {
    await this.validateFields();
    if (!this.hasNoErrors()) {
      return;
    }
    this.lockScreenService.lockForUpdate = true;
    let createdMatter: Matter = await this.convertOpportunityToMatter();
    let createdLinkedMatter: Matter;
    if (this.isLinkedMatters()) {
      await this.copyAndSaveLinkMatters(createdMatter);
    } else {
      this.lockScreenService.lockForUpdate = false;
      if (createdMatter) {
        this.dialog.close({matter: createdMatter, linkedMatter: createdLinkedMatter});
      }
    }
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    if (!!this.context.onlyAllowImportToExisting) {
      this.isConvertToNewMatter = false;
    }
    if (this.context.opportunityMatter && this.context.opportunityMatter.opportunity) {
      this.selectedMatterTypeCode = this.context.opportunityMatter.customMatterTypeName;
      this.selectedProvinceCode = this.context.opportunityMatter.provinceCode;

      this.setCustomMatterType();
    }

    this.loadMatterTypeList();
    this.loadUserProvincesDropDown();
    this.initSearchMatter();

    if (this.isClosedDuplicate()) {
      this.changeConvertToMatter(false);
    }
  }

  loadUserProvincesDropDown(): void {
    this.provinceOptions = [];
    this.userProvinces = this.userStateService.getSortedEnabledUserProvinceCodes();
    if (this.userProvinces && this.userProvinces.length) {
      if (this.userProvinces.length == 1 && !this.selectedProvinceCode) {
        this.selectedProvinceCode = <ProvinceCode>this.userProvinces[0];
      } else {
        this.provinceOptions = [{label: ' ', value: ''}];
        this.provinceOptions.push(
          ...this.userProvinces.map((province) => <SelectItem>{label: province, value: province})
        );
      }
    }
  }

  // Province field is visible if the user has more than one province Or the the user has a single province which is not equal to the opportunity province
  isProvinceVisible(): boolean {
    return (
      this.userProvinces &&
      !this.isLinkedMatters() &&
      (this.userProvinces.length > 1 ||
        (this.userProvinces.length == 1 && this.selectedProvinceCode != <ProvinceCode>this.userProvinces[0]))
    );
  }

  isProvinceReadOnly(): boolean {
    return !!this.context.opportunityMatter.provinceCode;
  }

  async loadMatterTypeList(): Promise<void> {
    this.matterTypeOptions = [{label: ' ', value: ''}];
    let allMatterTypes: MatterTypeInfo[] = await this.accountService.getMatterTypesDetails().toPromise();
    if (allMatterTypes && allMatterTypes.length) {
      //Filter out the reserved but keep the system matter types
      let filteredMatterTypes = allMatterTypes.filter(
        (matterType) => !matterType.reserved || matterType.systemMatterType
      );
      this.matterTypeOptions.push(
        ...filteredMatterTypes.map(
          (matterType) =>
            <SelectItem>{
              label: matterType.matterTypeDescription,
              value: matterType.matterTypeCode
            }
        )
      );
      if (this.appConfig.isHiddenForBCProvince) {
        this.updateMatterTypeOptions(this.matterTypeOptions);
      }
    }
  }

  async validateFields(): Promise<void> {
    this.errorService.removeAllDpFieldError();
    this.errorService.clearAllSaveErrors();
    if (!this.selectedMatterTypeCode) {
      this.errorService.addDpFieldError(DPError.createDPError('convertToMatter.matterType'));
    }
    if (!this.selectedProvinceCode) {
      this.errorService.addDpFieldError(DPError.createDPError('convertToMatter.provinceCode'));
    }
    if (this.selectedProvinceCode && !this.userProvinces.find((pc) => pc == this.selectedProvinceCode)) {
      //Selected Province Code is not in the user provinces
      this.errorService.addDpFieldError(
        DPError.createCustomDPError(
          'convertToMatter.provinceCode',
          "You don't have access to the selected province.",
          null,
          'ERROR'
        )
      );
    }
    if (!this.matterRecordNumber || this.matterRecordNumber.trim().length == 0) {
      this.errorService.addDpFieldError(
        DPError.createCustomDPError('convertToMatter.matterNo', 'Matter No. is required.', null, 'ERROR')
      );
    }
    if (
      this.isLinkedMatters() &&
      (!this.linkedMatterRecordNumber || this.linkedMatterRecordNumber.trim().length == 0)
    ) {
      this.errorService.addDpFieldError(
        DPError.createCustomDPError('convertToMatter.linkedMatterNo', 'Linked Matter No. is required.', null, 'ERROR')
      );
    }
    if (
      this.isLinkedMatters() &&
      this.matterRecordNumber &&
      this.linkedMatterRecordNumber &&
      this.matterRecordNumber == this.linkedMatterRecordNumber
    ) {
      this.errorService.addDpFieldError(
        DPError.createCustomDPError('convertToMatter.linkedMatterNo', 'Matter No. has to be unique.', null, 'ERROR')
      );
    }
    if (this.matterRecordNumber) {
      let mattersWithDuplicateRecordNumbers = await this.matterService
        .getMatterByMatterRecordNumber(this.matterRecordNumber, false, null)
        .toPromise();
      if (mattersWithDuplicateRecordNumbers && mattersWithDuplicateRecordNumbers.length) {
        this.errorService.addDpFieldError(
          DPError.createCustomDPError(
            'convertToMatter.matterNo',
            'Another record with the same ID already exists.',
            null,
            'ERROR'
          )
        );
      }
    }
    if (this.isLinkedMatters() && this.linkedMatterRecordNumber) {
      let mattersWithDuplicateRecordNumbers = await this.matterService
        .getMatterByMatterRecordNumber(this.linkedMatterRecordNumber, false, null)
        .toPromise();
      if (mattersWithDuplicateRecordNumbers && mattersWithDuplicateRecordNumbers.length) {
        this.errorService.addDpFieldError(
          DPError.createCustomDPError(
            'convertToMatter.linkedMatterNo',
            'Another record with the same ID already exists.',
            null,
            'ERROR'
          )
        );
      }
    }
  }

  hasNoErrors(): boolean {
    return this.errorService.hasNoErrors();
  }

  async convertOpportunityToMatter(): Promise<Matter> {
    try {
      this.lockScreenService.lockForUpdate = true;
      let targetMatter: Matter;
      targetMatter = await this.matterService
        .createNewMatter(<MatterType>this.selectedMatterTypeCode, this.selectedProvinceCode)
        .toPromise();

      if (targetMatter) {
        targetMatter.matterRecordNumber = this.matterRecordNumber;
        targetMatter.fileNumber = this.fileNumber;
        targetMatter.accountingNumber = this.accountingNumber;
        targetMatter.fileOpenDate = moment(new Date()).format('YYYY/MM/DD');
        //need to init the matter, to get the soaTrustLedgerCollection ready
        let matterInitiated = await this.matterService.initMatter(targetMatter).toPromise();
        if (matterInitiated) {
          await this.context.copyOpportunityMatterService.copyMatterFromOpportunity(
            this.context.opportunityMatter,
            targetMatter,
            false
          );
          await this.copySoaConfigurationsInMatter(targetMatter);
          let isMatterValid = await this.matterService
            .validateMatter(targetMatter, null, this.errorService)
            .toPromise();
          if (isMatterValid) {
            targetMatter.id = null;
            targetMatter.matterSoas = [];
            this.context.opportunityMatter.matterSoas.forEach((item) => {
              item.id = undefined;
              targetMatter.matterSoas.push(item);
            });
            if (
              ((this.context.opportunityMatter.customMatterTypeName == targetMatter.matterType ||
                (targetMatter.isCustomMatter() &&
                  this.context.opportunityMatter.customMatterTypeName == targetMatter.customMatterTypeName)) &&
                !targetMatter.isMatterTypeDischarge) ||
              (this.context.opportunityMatter.customMatterTypeName == MatterTypesValue.DISCHARGE &&
                !!targetMatter.isMatterTypeDischarge)
            ) {
              targetMatter.soaTrustLedgerCollection.matter = targetMatter;
              targetMatter.soaTrustLedgerCollection.receivedOnAccount =
                this.context.opportunityMatter.soaTrustLedgerCollection.receivedOnAccount;
              targetMatter.soaTrustLedgerCollection.updateMatterSoasInCollection(targetMatter.matterSoas);
              targetMatter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
            }
            targetMatter = await this.matterService.saveMatter(targetMatter).toPromise();
            this.doPostMatterSaveActions(targetMatter);
            return targetMatter;
          } else {
            //Release the lock from source contacts.
            this.tabsService.releaseLockFromMatterParticipants(targetMatter);
            return null;
          }
        } else {
          return null;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  copyAndSaveLinkMattersAction(matter: Matter, linkedMatter: Matter): void {
    if (linkedMatter) {
      // Init Matter
      this.matterService.initMatter(linkedMatter).subscribe((isMatterUpdated) => {
        if (isMatterUpdated) {
          // Copy Data
          matter.isSolicitorOrLawClerkDirty = true; //update tab A Solicitor and LawClerk to linked matter tab c
          this.context.copyMatterLinkDataService
            .copyLinkedMatterFields(matter, linkedMatter)
            .subscribe((isCopyCompleted) => {
              if (isCopyCompleted) {
                // Save Linked Matter
                this.matterService
                  .validateMatter(linkedMatter, undefined, this.errorService)
                  .subscribe((linkedMatterValidated) => {
                    if (linkedMatterValidated) {
                      this.matterService
                        .saveMatter(linkedMatter, undefined, undefined, undefined, this.errorService)
                        .subscribe((updatedLinkedMatter) => {
                          this.context.copyMatterLinkDataService.copyFileNumber(updatedLinkedMatter, matter);
                          matter.addMatterLink(updatedLinkedMatter);
                          //validate and save source matter after copying fields to it from the linked matter
                          this.matterService
                            .validateMatter(matter, undefined, this.errorService)
                            .subscribe((sourceMatterValidated) => {
                              if (sourceMatterValidated) {
                                this.matterService
                                  .saveMatter(matter, undefined, undefined, undefined, this.errorService)
                                  .subscribe((updatedSourceMatter) => {
                                    this.lockScreenService.lockForUpdate = false;
                                    if (updatedSourceMatter && updatedLinkedMatter) {
                                      this.doPostMatterSaveActions(updatedLinkedMatter);
                                      this.dialog.close({
                                        matter: updatedSourceMatter,
                                        linkedMatter: updatedLinkedMatter
                                      });
                                    }
                                  });
                              }
                            });
                        });
                    }
                  });
              }
            });
        }
      });
    }
  }

  doPostMatterSaveActions(createdMatter: Matter): void {
    createdMatter.isFromOpportunity = false;
    this.context.opportunityMatter.opportunity.opportunityStatus = OpportunityMatterStatusValue.closedConverted;
    if (createdMatter && createdMatter.matterLink) {
      this.context.opportunityMatter.opportunity.associatedToLinkedMatters = true;
    }
  }

  async copySoaConfigurationsInMatter(targetMatter: Matter): Promise<void> {
    if (
      (this.context.opportunityMatter.customMatterTypeName == targetMatter.matterType &&
        !targetMatter.isMatterTypeDischarge) ||
      (this.context.opportunityMatter.customMatterTypeName == MatterTypesValue.DISCHARGE &&
        !!targetMatter.isMatterTypeDischarge)
    ) {
      targetMatter.statementConfigurationId = this.context.opportunityMatter.statementConfigurationId;
      this.lockScreenService.lockForUpdate = true;
      await this.soaTrustLedgerHelperService.updateMatterSOAConfig(
        targetMatter,
        this.context.opportunityMatter.primarySoaTemplateId
      );
      this.lockScreenService.lockForUpdate = false;
      if (targetMatter.soaTrustLedgerCollection && this.context.opportunityMatter.soaTrustLedgerCollection) {
        targetMatter.soaTrustLedgerCollection.feeCalculatedOnInclusivePriceSelection =
          this.context.opportunityMatter.soaTrustLedgerCollection.feeCalculatedOnInclusivePriceSelection;
        targetMatter.soaTrustLedgerCollection.soaFeesCalculatedOnAllInclusivePriceValue =
          this.context.opportunityMatter.soaTrustLedgerCollection.soaFeesCalculatedOnAllInclusivePriceValue;
      }
    }
  }

  changeConvertToMatter(updateFlag: boolean): void {
    if (!this.isLinkedMatters()) {
      this.isConvertToNewMatter = updateFlag;
      this.errorService.removeAllDpFieldError();
      this.errorService.clearAllSaveErrors();
    }
  }

  //Copy Matter No. to File No. and Accounting No. if they are blank.
  populateFileAccounting(isLinkedMatter?: boolean): void {
    if (isLinkedMatter) {
      if (this.linkedMatterRecordNumber) {
        if (!this.linkedFileNumber) {
          this.linkedFileNumber = this.linkedMatterRecordNumber;
        }

        if (!this.linkedAccountingNumber) {
          this.linkedAccountingNumber = this.linkedMatterRecordNumber;
        }
      }
    } else {
      if (this.matterRecordNumber) {
        if (!this.fileNumber) {
          this.fileNumber = this.matterRecordNumber;
        }

        if (!this.accountingNumber) {
          this.accountingNumber = this.matterRecordNumber;
        }
      }
    }
  }

  initSearchMatter() {
    // AutoComplete
    this.searchTermStreamMatter
      .switchMap((term: string) => {
        this.matterSearchLoading = true;
        if (term.trim() === '') {
          this.autoCompleteEmptyFlag = true;
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });

          return observable;
        } else {
          this.autoCompleteEmptyFlag = false;
          return this.matterService.getMattersForUpdatingFromReferral(term, this.userProvinces, true);
        }
      })
      .subscribe(
        (matterList: Matter[]) => {
          this.matterSearchLoading = false;
          if (matterList) {
            this.matterList = matterList.map((item) => new Matter(item));
          }
        },
        (error) => {
          this.matterSearchLoading = false;
        }
      );
  }

  getMatterPropertyAddress(matterProperties: MatterProperty[]): string {
    let primaryAddress = '';
    if (matterProperties && matterProperties.length > 0) {
      let matterProperty = matterProperties[0];
      if (
        matterProperty &&
        matterProperty.address.primaryAddress === true &&
        matterProperty.address.addressHash != null
      ) {
        primaryAddress = matterProperty.address.addressText === null ? ' ' : matterProperty.address.addressText;
      }
    }
    return primaryAddress;
  }

  // Matter Search
  search(event): void {
    let entered: string = event.query;
    this.searchTermStreamMatter.next(entered);
  }

  dataSelectedMatter(matter: Matter): void {
    this.selectedExistingMatter = new Matter(matter);
  }

  removeSelectedMatter(): void {
    this.selectedExistingMatter = new Matter();
    this.matterSerachText = '';
    this.errorService.removeAllDpFieldError();
    this.errorService.clearAllSaveErrors();
  }

  updateMatterType() {
    if (this.utilsService.isMatterTypeDisabled(this.selectedMatterTypeCode)) {
      this.matterTypeCtrl.nativeElement.value = undefined;
      this.selectedMatterTypeCode = undefined;
    }
  }

  convertMatter(): void {
    if (this.isConvertToNewMatter) {
      this.createMatter();
    } else {
      //Handle existing matter
      this.handleExistingMatter();
    }
  }

  async handleExistingMatter(): Promise<void> {
    if (this.selectedExistingMatter && this.selectedExistingMatter.id > 0) {
      let isMatterLockedOrOpen = this.matterService.isMatterLockedOrOpen(
        this.selectedExistingMatter,
        this.tabsService,
        this.errorService
      );
      if (!isMatterLockedOrOpen && this.errorService.hasNoErrors()) {
        const fullExistingMatter = await this.matterService.getMatter(this.selectedExistingMatter.id, true).toPromise();
        this.lockScreenService.lockForUpdate = true;
        try {
          await this.matterService.initMatter(fullExistingMatter).toPromise();
        } catch (e) {
          this.lockScreenService.lockForUpdate = false;
        } finally {
          this.lockScreenService.lockForUpdate = false;
        }
        try {
          this.dialogService.matDialogContent({
            content: CopyToExistingMatterComponent,
            context: {
              existingMatter: fullExistingMatter,
              opportunityMatter: this.context.opportunityMatter,
              matterService: this.matterService,
              copyOpportunityMatterService: this.context.copyOpportunityMatterService
            },
            modalGrid: 10,
            onFulfillment: (result) => {
              if (result.action === ModalResult.OK) {
                this.dialog.close({matter: result.matter});
              } else {
                this.matterService.unlockMatter(this.selectedExistingMatter.id);
              }
            },
            
          });
        } catch (e) {
          this.matterService.unlockMatter(this.selectedExistingMatter.id);
        } finally {
          this.lockScreenService.lockForUpdate = false;
        }
      }
    }
  }

  isLinkedMatters(): boolean {
    return this.context.opportunityMatter && this.context.opportunityMatter.isOppurtunityLinkedMatters();
  }

  get matterTypePrefix(): string {
    return this.isLinkedMatters() ? `${_.capitalize(this.selectedMatterTypeCode)} ` : '';
  }

  get linkedMatterPrefix(): string {
    return this.isLinkedMatters() ? `${_.capitalize(this.getLinkedMatterType())} ` : '';
  }

  getLinkedMatterType(): string {
    if (this.isLinkedMatters()) {
      return this.selectedMatterTypeCode == MatterTypesValue.PURCHASE
        ? MatterTypesValue.SALE
        : MatterTypesValue.PURCHASE;
    } else {
      return '';
    }
  }

  async copyAndSaveLinkMatters(matter: Matter): Promise<void> {
    let linkedMatter: Matter;
    if (matter) {
      linkedMatter = await this.matterService
        .createNewMatter(<MatterType>this.getLinkedMatterType(), matter.provinceCode)
        .toPromise();
      linkedMatter.matterRecordNumber = this.linkedMatterRecordNumber;
      linkedMatter.fileNumber = this.linkedFileNumber;
      linkedMatter.accountingNumber = this.linkedAccountingNumber;
      linkedMatter.actingFor = matter.isPurchase ? 'VENDOR_PURCHASER' : 'PURCHASER_VENDOR';

      // Create Matter Link Object
      linkedMatter.matterLink = new MatterLink();
      linkedMatter.matterLink.linkedMatterId = matter.id;
      let solicitors: Contact[] = await this.contactService.getSolicitorLawClerkList('SOLICITOR').toPromise();
      linkedMatter.initializeSolicitorLawClerk(solicitors, 'SOLICITOR');
      let lawClerks: Contact[] = await this.contactService.getSolicitorLawClerkList('LAWCLERK').toPromise();
      linkedMatter.initializeSolicitorLawClerk(lawClerks, 'LAWCLERK');
      await this.copyAndSaveLinkMattersAction(matter, linkedMatter);
    }
  }

  isClosedDuplicate(): boolean {
    return this.context.opportunityMatter.opportunity.isClosedDuplicate();
  }

  updateMatterTypeOptions(matterTypeOptions: SelectItem<any>[]) {
    matterTypeOptions.find((x) => x.value == 'PURCHASE').label = 'Purchase' + this.comingSoonOption;
    matterTypeOptions.find((x) => x.value == 'SALE').label = 'Sale' + this.comingSoonOption;
    matterTypeOptions.find((x) => x.value == 'MORTGAGE').label = 'Mortgage' + this.comingSoonOption;
    matterTypeOptions.find((x) => x.value == 'DISCHARGE').label = 'Discharge' + this.comingSoonOption;
  }

  setCustomMatterType() {
    if (
      this.appConfig.isHiddenForBCProvince &&
      (this.context.opportunityMatter.customMatterTypeName == 'PURCHASE' ||
        this.context.opportunityMatter.customMatterTypeName == 'SALE' ||
        this.context.opportunityMatter.customMatterTypeName == 'MORTGAGE' ||
        this.context.opportunityMatter.customMatterTypeName == 'DISCHARGE')
    ) {
      this.selectedMatterTypeCode = '';
    }
  }
}
