<div class="custom-pick-list">
    <p-autoComplete #customPickList
                    [id]="id"
                    [name]="name"
                    (keydown)="onKeyDown($event)"
                    [(ngModel)]="pickListValueObject"
                    (ngModelChange)="updateParentModel($event)"
                    [suggestions]="pickListSuggestions"
                    (completeMethod)="populateSearchSuggestions($event)"
                    [minLength]="minLength"
                    dp-error-validator
                    [fieldKey]="name"
                    [delay]="delay"
                    [maxlength]="maxLength"
                    placeholder=""
                    [scrollHeight]="scrollHeight"
                    (onSelect)="handleShowPickListEditDialog($event)"
                    (onFocus)="handleOnFocus($event)"
                    [dropdown]="true"
                    (keydown.space)="utils.handleSpaceKeyDropdown($event, pickListValueObject, populateAllSuggestions)"
                    (keydown.enter)="utils.handleEnterKeyDropdown($event, pickListValueObject, populateAllSuggestions)"

                    (onDropdownClick)="populateAllSuggestions($event)">
        <ng-template let-pickListValueObject pTemplate="item">
            <li class="ui-autocomplete-list-item ui-helper-clearfix">
                <span class="omnibarLi100">{{pickListValueObject.customPickListItemValue}}</span>
            </li>
        </ng-template>
    </p-autoComplete>
</div>
