import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ParcelTitle} from './parcel-title';
import {condoUnitTypeDropDownOptions} from '../../shared-main/province-based-dropdowns';
import {dropDowns} from './matter-drop-downs';

export class ParcelLegalDescription extends BaseEntity {
  id: number;
  static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity.
  parcelNumber: string;
  lotNumber: string;
  blockNumber: string;
  planNumber: string;
  unitNumber: string;
  extensionNumber: string;
  mineralRights: string;
  unitType: string;
  memo: string;
  rollNumbers: string[];
  percentageInterest: string;
  commonElementFees: number;
  parcelTitles: ParcelTitle[];
  mineral: boolean;
  excludeMineralParcels: boolean;
  qtrsec: string;
  sec: string;
  twp: string;
  rng: string;
  mdn: string;
  municipality: string;
  parcelFieldDesc: string;

  constructor(parcelLegalDescription?: ParcelLegalDescription) {
    super(parcelLegalDescription);
    if (parcelLegalDescription) {
      this.parcelTitles = [];
      if (parcelLegalDescription.parcelTitles) {
        for (let pt of parcelLegalDescription.parcelTitles) {
          this.parcelTitles.push(new ParcelTitle(pt));
        }
      }
      this.rollNumbers = [];
      if (Array.isArray(parcelLegalDescription.rollNumbers)) {
        this.rollNumbers.push(...parcelLegalDescription.rollNumbers);
      }
    } else {
      this.percentageInterest = '0.000000';
      this.commonElementFees = 0;
      this.parcelTitles = [];
      this.rollNumbers = [];
    }

  }

  generateParcelFieldValue(provinceCode: string): string {
    let titleText: string = this.noOfTitles() == 1 ? 'Title' : 'Titles';
    let unitType: string = this.unitType && this.unitType.length > 0 ? this.unitTypeText(provinceCode) : ' ';
    let unitNumber: string = this.unitNumber ? this.unitNumber : '';
    //let parcelNumber = this.parcelNumber ? this.parcelNumber : '';
    let parcelFieldValue = ' Unit #' + unitNumber + ' ' + unitType + ' (' + this.noOfTitles() + ' ' + titleText + ')';
    return parcelFieldValue;
  }

  get parcelFieldDescNonCondo(): string {
    let parcelType = this.mineral === undefined ? '' : this.mineral ? 'Mineral Parcel' : 'Surface Parcel';
    this.parcelFieldDesc = ((parcelType ? parcelType : '') + ' ' + (this.parcelNumber ? this.parcelNumber : '') + (this.lotNumber ? ' Lot ' + this.lotNumber : '') + (this.blockNumber ? ' Blk/Par ' + this.blockNumber : '')
      + (this.planNumber ? ' Plan No ' + this.planNumber : '') + (this.extensionNumber ? ' Extension ' + this.extensionNumber : '') + (this.mineralRights ? ' ' + this.mineralRightsText() : '')
      + (this.parcelTitles.length > 0 ? ' (' + this.noOfTitles() + ' Title(s))' : ''));
    return this.parcelFieldDesc;
  }

  get parcelFieldDescNonCondoWithPurchaseFarmLand(): string {
    let parcelType = this.mineral === undefined ? '' : this.mineral ? 'Mineral Parcel' : 'Surface Parcel';
    this.parcelFieldDesc = ((this.qtrsec ? this.qtrsec : '') + ' ' + (parcelType ? parcelType : '') + ' ' + (this.parcelNumber ? this.parcelNumber : '') + (this.sec ? ' Sec ' + this.sec : '')
      + (this.twp ? ' TWP ' + this.twp : '') + (this.rng ? ' Rge ' + this.rng : '') + ' ' + (this.mdn ? this.mdn : '') + (this.extensionNumber ? ' Extension ' + this.extensionNumber : '')
      + (this.mineralRights ? ' ' + this.mineralRightsText() : '') + (this.parcelTitles.length > 0 ? ' (' + this.noOfTitles() + ' Title(s))' : ''));
    return this.parcelFieldDesc;
  }

  mineralRightsText() {
    return dropDowns.isExceptionDescriptionOptions.find(item => item.value === this.mineralRights).label;

  }

  unitTypeText(provinceCode: string) {
    return condoUnitTypeDropDownOptions[ provinceCode ].find(item => item.value === this.unitType).label;
  }

  noOfTitles(): number {
    if (this.parcelTitles.length === 1) {
      if (this.parcelTitles[ 0 ].parcelTitleValue > 0 || (!!this.parcelTitles[ 0 ].titleNumber)) {
        return 1;
      }
      return 0;
    }
    return this.parcelTitles.length;
  }

  get titleRollNumber(): string {
    //Title separated from roll number with ': ' and roll numbers separated by '; '
    return `${ this.parcelNumber ? this.parcelNumber : '' }: ${ Array.isArray(this.rollNumbers) ? this.rollNumbers.filter(Boolean).join('; ') : '' }`;
  }

  // calculates the order of a roll number within a list of ParcelLegalDescriptions
  static getTitleRollNumberOrder(parcelLegalDescriptions: ParcelLegalDescription[], rollNumber: string, forParcelLegalDescription?: ParcelLegalDescription): number {
    let result: number = 0;
    if (parcelLegalDescriptions && parcelLegalDescriptions.length > 0) {
      for (let parcelLegalDescription of parcelLegalDescriptions) {
        for (let rn of parcelLegalDescription.rollNumbers) {
          if (rn === rollNumber) {
            if (!forParcelLegalDescription || forParcelLegalDescription && forParcelLegalDescription.id === parcelLegalDescription.id) {
              return result;
            }
          }
          result++;
        }
      }
    }
    return result;
  }
}
