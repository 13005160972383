import {Component, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {CurrencyPipe} from '@angular/common';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {SelectItem} from 'primeng/api';
import {SoAdjInterestOnDepositCondo, SoAdjInterestOnDepositCondoItem} from './so-adj-interest-on-deposit-condo';
import moment from 'moment';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {Matter} from '../shared/matter';
import {Project} from '../../projects/shared/project';
import {StatementOfAdjustmentHeading} from './model/statement-adjustment-heading';
import {dropDowns} from '../shared/matter-drop-downs';
import {InterestRateModal} from './interest-rate.modal.component';
import * as _ from 'lodash';
import Utils from '../../shared-main/utils';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var jQuery: any;

class InterestOnDepositCondoModalContext {
  public closingDate: string;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public soAdjInterestOnDepositCondo: SoAdjInterestOnDepositCondo;
  public isPaysForDateOfClosingVendor: boolean;
  public matter: Matter;
  public project: Project;
  public adjustmentKey: string;
}

@Component({
  selector: 'dp-interest-on-deposit-condo',
  templateUrl: './interest-on-deposit-condo.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class InterestOnDepositCondoModal extends ModalComponent<InterestOnDepositCondoModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  interestCalculationTypes: SelectItem[];
  printingFormatTypes: SelectItem[];
  soAdjInterestOnDepositCondo: SoAdjInterestOnDepositCondo;
  burgerMenuItemsEditDelete: BurgerMenuExtendedItem[] = [];
  editButtonLabel: string = 'Edit';
  deleteButtonLabel: string = 'Delete';
  depositAmount: number = 0;
  depositsInterest: number = 0;
  depositAmountToBeOptions: SelectItem[];
  interestCalculationPeriodOptions: SelectItem[];
  interestRows: any[] = [];
  minNumberOfItems: number = 1;

  infoOnly: boolean = false;

  constructor(
    public dialog: MatDialogRef<InterestOnDepositCondoModal>,
    public currencyPipe: CurrencyPipe,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: InterestOnDepositCondoModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    let burgerMenuExtendedItemEdit = new BurgerMenuExtendedItem();
    burgerMenuExtendedItemEdit.text = this.editButtonLabel;
    let burgerMenuExtendedItemDelete = new BurgerMenuExtendedItem();
    burgerMenuExtendedItemDelete.text = this.deleteButtonLabel;
    this.burgerMenuItemsEditDelete.push(burgerMenuExtendedItemEdit);
    this.burgerMenuItemsEditDelete.push(burgerMenuExtendedItemDelete);

    this.interestCalculationTypes = dropDowns.interestDepositCalculationProjectTypes;
    this.printingFormatTypes = dropDowns.interestDepositPrintingTypes;

    if (this.context.soAdjInterestOnDepositCondo) {
      this.soAdjInterestOnDepositCondo = new SoAdjInterestOnDepositCondo(this.context.soAdjInterestOnDepositCondo);
    } else {
      this.soAdjInterestOnDepositCondo = new SoAdjInterestOnDepositCondo();
      this.soAdjInterestOnDepositCondo.adjustmentType =
        this.adjustmentKey == 'INTEREST_ON_DEPOSIT_INTEREST_CONDO'
          ? 'INTEREST_ON_DEPOSITS_INTEREST'
          : this.adjustmentKey == 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO'
            ? 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO'
            : 'INTEREST_ON_DEPOSITS';
    }

    if (!this.soAdjInterestOnDepositCondo.printingFormat) {
      this.soAdjInterestOnDepositCondo.printingFormat = 'ITEMIZED_CALCULATION';
    }

    if (this.adjustmentKey == 'INTEREST_ON_DEPOSIT_INTEREST_CONDO') {
      if (!this.soAdjInterestOnDepositCondo.titleOnAdjustment) {
        this.soAdjInterestOnDepositCondo.titleOnAdjustment = 'INTEREST ON DEPOSITS INTEREST';
      }
      //let depositInterestAdjustment = this.matter.statementOfAdjustments.find(item => item.isInterestOnDepositCondo());
      if (this.isProvinceABWithProject()) {
        let interestOnDepositUnderOldCondo = this.matter.firstInterestOnDepositUnderOldCondoSoa;
        if (interestOnDepositUnderOldCondo) {
          //The "Unpaid interest on deposits" field is populated and in sync with the value from the "Interest on Deposit - Old Condo Act" adjustment
          this.depositsInterest = interestOnDepositUnderOldCondo.amount;
        }
      } else {
        let depositInterestAdjustment = this.matter.getDepositInterestAdjustment();
        if (depositInterestAdjustment) {
          this.depositsInterest = depositInterestAdjustment.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(
            this.matter,
            this.matter.isSelectedAdjustmentStatusModeFinal
          );
        }
      }
    } else if (this.adjustmentKey == 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO') {
      this.depositAmountToBeOptions = dropDowns.depositAmountToBeTypes;
      this.interestCalculationPeriodOptions = dropDowns.interestCalculationPeriodTypes;
      if (!this.soAdjInterestOnDepositCondo.titleOnAdjustment) {
        this.soAdjInterestOnDepositCondo.titleOnAdjustment = 'INTEREST ON DEPOSITS';
      }
      if (!this.soAdjInterestOnDepositCondo.depositAmountToBe) {
        this.soAdjInterestOnDepositCondo.depositAmountToBe = 'ENTER_MANUALLY';
        this.soAdjInterestOnDepositCondo.depositAmount = 0;
        //this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems[0].adjustmentDescription = 'Amount of deposit: 0'
      }
      if (!this.soAdjInterestOnDepositCondo.interestCalculationPeriod) {
        this.soAdjInterestOnDepositCondo.interestCalculationPeriod = 'INTERIM_CLOSING_TO_ADJ_DATE';
      }
      this.soAdjInterestOnDepositCondo.getStartDateForInterestOnDepositsForOldCondo(
        this.matter,
        this.soAdjInterestOnDepositCondo.interestCalculatedFromDate
      );
      this.soAdjInterestOnDepositCondo.getEndDateForInterestOnDepositsForOldCondo(
        this.matter,
        this.soAdjInterestOnDepositCondo.interestCalculatedToDate
      );

      if (this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.length < this.minNumberOfItems) {
        this.fillInEmptyItems(
          this.minNumberOfItems - this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.length
        );
      }
    } else {
      if (!this.soAdjInterestOnDepositCondo.titleOnAdjustment) {
        this.soAdjInterestOnDepositCondo.titleOnAdjustment = 'INTEREST ON DEPOSITS';
      }
      if (!this.soAdjInterestOnDepositCondo.depositsInterestFromDateType) {
        this.soAdjInterestOnDepositCondo.depositsInterestFromDateType =
          'INTERIM_CLOSING_AS_SPECIFIED_IN_OCCUPANCY_DATE';
      }
    }
    let depositAdjustment = this.matter.statementOfAdjustments.find((item) => item.isDepositAdjustment());
    if (depositAdjustment) {
      this.depositAmount = depositAdjustment.amount;
    }
    this.updateInterestRows();
  }

  getNewItem(): SoAdjInterestOnDepositCondoItem {
    let newItem = new SoAdjInterestOnDepositCondoItem();
    newItem.adjustmentDescription = '';
    return newItem;
  }

  addNewItem() {
    this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.push(this.getNewItem());
    this.focusLastLine();
  }

  removeItem(item: SoAdjInterestOnDepositCondoItem) {
    let index: number = this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.indexOf(item);
    if (index > -1 && !this.isAdjustmentDescriptionReadonly(index)) {
      this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.splice(index, 1);
      //this.deleteIndex++;
      //always at least 1 empty item
      if (this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.length < this.minNumberOfItems) {
        this.fillInEmptyItems(1);
      }
      this.focusLastLine();
    }
  }

  focusLastLine(): void {
    setTimeout(() => {
      this.setFocusOnElementById(
        `#item-heading-${this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.length - 1}`
      );
    }, 100);
  }

  setFocusOnElementById(elementId: string): void {
    jQuery(elementId).focus();
  }

  fillInEmptyItems(howMany: number) {
    for (let i: number = 0; i < howMany; i++) {
      this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.push(this.getNewItem());
    }
  }

  isAdjustmentDescriptionReadonly(index): boolean {
    return this.soAdjInterestOnDepositCondo.depositAmountToBe == 'TOTAL_DEPOSITS' && index == 0;
  }

  isFirstAdjustmentDescription(item: SoAdjInterestOnDepositCondoItem): boolean {
    return (
      this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.length > 0 &&
      this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems.indexOf(item) == 0
    );
  }

  updateInterestRows() {
    this.interestRows = [];
    let interestRow = {
      text: 'Amount of Deposit',
      amount: Utils.roundNumber(this.soAdjInterestOnDepositCondo.depositAmount, 2),
      readonly: true
    };
    this.interestRows.push(interestRow);
    this.interestRows.push(...this.generateInterestOnDepositForCondo());
  }

  setDefaultDescription(): void {
    this.soAdjInterestOnDepositCondo.interestOnDepositCondoItems[0].adjustmentDescription =
      'Amount of deposit: ' + this.soAdjInterestOnDepositCondo.depositAmount;
  }

  updateInterestCalculationPeriod(event: any) {
    this.soAdjInterestOnDepositCondo.interestCalculationPeriod = event;
    this.soAdjInterestOnDepositCondo.getStartDateForInterestOnDepositsForOldCondo(this.matter);
    this.soAdjInterestOnDepositCondo.getEndDateForInterestOnDepositsForOldCondo(this.matter);
    this.updateInterestRows();
  }

  onInterestCalculatedFromDateChange(event: any, matter: Matter) {
    this.soAdjInterestOnDepositCondo.getStartDateForInterestOnDepositsForOldCondo(matter, event.rawDate);
    this.updateInterestRows();
  }

  onInterestCalculatedToDateChange(event: any, matter: Matter) {
    this.soAdjInterestOnDepositCondo.getEndDateForInterestOnDepositsForOldCondo(matter, event.rawDate);
    this.updateInterestRows();
  }

  onDateChange(event, dateType: string): void {
    this.soAdjInterestOnDepositCondo[dateType] = event.rawDate;
  }

  isAmountOfDepositEditable(): boolean {
    return (
      this.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment() &&
      this.soAdjInterestOnDepositCondo.depositAmountToBe == 'ENTER_MANUALLY'
    );
  }

  updateDepositAmount(depositAmountToBe: string) {
    if (depositAmountToBe == 'ENTER_MANUALLY') {
      this.soAdjInterestOnDepositCondo.depositAmount = 0;
    } else {
      this.soAdjInterestOnDepositCondo.depositAmount = this.depositAmount;
      this.setDefaultDescription();
    }

    this.updateInterestRows();
  }

  isInterestCalculatedFromEditable(): boolean {
    return (
      this.soAdjInterestOnDepositCondo &&
      (this.soAdjInterestOnDepositCondo.interestCalculationPeriod == 'ENDING_ADJ_DATE' ||
        this.soAdjInterestOnDepositCondo.interestCalculationPeriod == 'ENTER_DATES' ||
        this.soAdjInterestOnDepositCondo.interestCalculationPeriod == 'ENDING_INTERIM_CLOSING_DATE')
    );
  }

  isInterestCalculatedToEditable(): boolean {
    return (
      this.soAdjInterestOnDepositCondo &&
      (this.soAdjInterestOnDepositCondo.interestCalculationPeriod == 'COMMENCING_ADJ_DATE' ||
        this.soAdjInterestOnDepositCondo.interestCalculationPeriod == 'ENTER_DATES' ||
        this.soAdjInterestOnDepositCondo.interestCalculationPeriod == 'COMMENCING_INTERIM_CLOSING_DATE')
    );
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (
      currencyValue != undefined &&
      !isNaN(currencyValue) &&
      currencyValue != null &&
      currencyValue.toString().trim() != ''
    ) {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  formattedDate(dateStr: string): string {
    return dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
  }

  get matter(): Matter {
    return this.context.matter;
  }

  generateInterestOnDepositForCondo(): any[] {
    if (!this.soAdjInterestOnDepositCondo) {
      return [];
    } else {
      if (this.soAdjInterestOnDepositCondo.isInterestOnDepositInterestAdjustment()) {
        return this.soAdjInterestOnDepositCondo.generateInterestOnDepositInterestForCondo(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        );
      } else if (this.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()) {
        return this.soAdjInterestOnDepositCondo.generateInterestOnDepositForOldCondo(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        );
      } else {
        return this.soAdjInterestOnDepositCondo.generateInterestOnDepositForCondo(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        );
      }
    }
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpSaveError();
    if (this.soAdjInterestOnDepositCondo.isInterestOnDepositAdjustment()) {
      if (
        this.soAdjInterestOnDepositCondo.isDepositDateInValid(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        )
      ) {
        this.modalErrorComponent.createDPSaveError('matter.project.adjustment.interest.depositDate.invalid');
      }
    } else if (this.soAdjInterestOnDepositCondo.isInterestOnDepositInterestAdjustment()) {
      if (
        this.soAdjInterestOnDepositCondo.areDatesInValidForInterestOnDepositInterest(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        )
      ) {
        this.modalErrorComponent.createDPSaveError('matter.project.adjustment.deposit.interest.depositDate.invalid');
      }
    } else if (this.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()) {
      if (
        this.soAdjInterestOnDepositCondo.areDatesInValidForInterestOnDepositUnderOldCondo(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        )
      ) {
        this.modalErrorComponent.createDPSaveError('matter.project.adjustment.deposit.interest.depositDate.invalid');
      }
    }

    if (
      !this.soAdjInterestOnDepositCondo.titleOnAdjustment ||
      (this.soAdjInterestOnDepositCondo.titleOnAdjustment &&
        this.soAdjInterestOnDepositCondo.titleOnAdjustment.trim() == '')
    ) {
      this.modalErrorComponent.createDPSaveError('matter.project.adjustment.interest.deposit.titleOnAdjustment');
    }
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.soAdjInterestOnDepositCondo);
    }
  }

  close(): void {
    this.dialog.close();
  }

  get totalInterestOnDeposit(): string {
    return this.soAdjInterestOnDepositCondo &&
      !this.soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()
      ? this.getFormattedCurrencyValue(
          this.soAdjInterestOnDepositCondo
            ? this.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(
                this.matter,
                this.matter.isSelectedAdjustmentStatusModeFinal
              )
            : 0
        )
      : this.getFormattedCurrencyValue(this.totalInterestOnDepositForOldCondo());
  }

  totalInterestOnDepositForOldCondo(): number {
    return this.interestRows
      .filter((item) => item.amount != undefined && !item.readonly)
      .reduce((sum, i) => {
        return sum + i.amount;
      }, 0);
  }

  getFormattedHeaderAmount(amount: string) {
    return amount ? '(' + this.getFormattedCurrencyValue(Number(amount)) + ')' : '';
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get adjustmentKey(): string {
    return this.context.adjustmentKey;
  }

  get adjustmentHeading(): string {
    if (
      this.adjustmentKey == 'INTEREST_ON_DEPOSITS_INTEREST' ||
      this.adjustmentKey == 'INTEREST_ON_DEPOSIT_INTEREST_CONDO'
    ) {
      if (this.context.matter.isProvinceABWithProject) {
        return 'Adjustment Type : Interests on Deposits Interest';
      } else {
        return 'Adjustment Type : Interests on Deposits Interest under new Condo Act';
      }
    } else if (this.adjustmentKey == 'INTEREST_ON_DEPOSIT_UNDER_OLD_CONDO') {
      return 'Adjustment Type : Interests on Deposits under Old Condo Act';
    } else {
      return 'Adjustment Type : Interests on Deposits Interest under new Condo Act';
    }
  }

  viewInterestRateTable(): void {
    let interestRateTable: any[];
    let rates: any[] = [];
    if (this.soAdjInterestOnDepositCondo) {
      if (this.soAdjInterestOnDepositCondo.isInterestOnDepositInterestAdjustment()) {
        interestRateTable = this.soAdjInterestOnDepositCondo.generateInterestOnDepositInterestForCondo(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal
        );
      } else {
        interestRateTable = this.soAdjInterestOnDepositCondo.generateInterestOnDepositForCondo(
          this.matter,
          this.matter.isSelectedAdjustmentStatusModeFinal,
          true
        );
      }
    }
    interestRateTable.forEach((itm) => {
      if (itm && itm.interestTableItem) {
        let entry = itm.interestTableItem.split('_');
        rates.unshift({from: entry[0], to: entry[1], rate: Number(entry[2])});
      }
    });

    if (rates.length > 0) {
      rates[0].to = 'Closing Date';
    } else if (this.matter.interestRates) {
      // populate the whole table if no deposit as been added so far
      const ratesOrdered = _.orderBy(
        this.matter.interestRates,
        (rate) => {
          return moment(rate.effectiveFrom).format('YYYYMMDD');
        },
        ['desc']
      );

      for (let i: number = 0; i < ratesOrdered.length; i++) {
        rates.push({
          from: moment(ratesOrdered[i].effectiveFrom).format('MMMM DD, YYYY'),
          to:
            i !== 0
              ? moment(ratesOrdered[i - 1].effectiveFrom)
                  .subtract(1, 'days')
                  .format('MMMM DD, YYYY')
              : '',
          rate: ratesOrdered[i].interestRate
        });
      }
    }

    this.dialogService.matDialogContent({
      content: InterestRateModal,
      context: {
        rates: rates
      },
      modalGrid: 3,
      onFulfillment: (result) => {}
    });
  }

  isProvinceABWithProject(): boolean {
    return this.context.matter.isProvinceABWithProject;
  }
}
