import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TitleInsurancePolicy extends BaseEntity {

  id: number;

  mortgageId: number;

  mortgageUniqueId: string;

  policyNo: string;

  insuredAmount: number;

  nameOfInsured: string;

  ownerPolicy: boolean;

  mortgagePriority: number;

  includePolicy: string;

  lenderPolicyAmount: number;

  matterPropertyId: number;

  // UI only
  lenderPolicyDescription: string;
  fromEMPMortgageWithPrincipalZero: boolean = false;
}
