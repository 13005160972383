<!-- modal -->
<form class="form-horizontal">
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1 *ngIf="context.action=='EDIT_DETAILS'">Edit Details</h1>
                <h1 *ngIf="context.action=='RENAME'">Rename</h1>
                <h1
                    *ngIf="context.action=='COPY'">Copy</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group" *ngIf="isTreeStructureVisible">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level"
                ><span class="title min-height-30 margin-bottom-0 padding-left-15">Change the name of the new file by entering a New File Name.</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="fileName">New File Name</label>
            </div>

            <div class="col-lg-6">
                <input type="text" trimmed class="form-control" maxlength="50" name="fileName" id="fileName"
                       [(ngModel)]="fileName" dp-error-validator
                       [fieldKey]="'matter.documentProduction.renameFile'"/>
            </div>
            <div class="col-lg-2 vertical-align-label"><label class="control-label text-left">.{{fileExtension}}</label>
            </div>
        </div>
        <div class="form-group" *ngIf="context.action=='EDIT_DETAILS'">
            <div class="col-lg-2 text-right">
                <label class="control-label" for="description">Description</label>
            </div>
            <div class="col-lg-6">
                <textarea style="border-left-width: 1.1px"
                          name="description"
                          id="description"
                          name="description"
                          [(ngModel)]="description"
                          maxlength="255"

                ></textarea>
            </div>
        </div>
        <div class="form-group padding-top-10" *ngIf="isTreeStructureVisible">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level"
                ><span class="title min-height-30 margin-bottom-0 padding-left-15">Choose which folder the file will be created in by selecting a Target Folder Directory.</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="isTreeStructureVisible">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="fileName">Target Folder Directory</label>
            </div>
            <div class="col-lg-10 margin-top-minus-10">
                <tree-root #treeCopy [nodes]="documentCopyNodes" [focused]="true" [options]="options"
                >
                    <ng-template #treeNodeTemplate let-node let-index="index">
                        <div *ngIf="!node.data.document">
                            <i class="fa fa-folder padding-right-5 padding-left-7" *ngIf="!node.isExpanded"></i>
                            <i class="fas fa-folder-open padding-right-5 padding-left-7" *ngIf="!!node.isExpanded"></i>
                            <label class="control-label padding-top-5 padding-bottom-5">{{ node.data.name }}</label>
                        </div>
                    </ng-template>
                </tree-root>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtnSave" class="dp-btn" (click)="save()">
                <span>Save</span>
            </button>

            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
