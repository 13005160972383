export const OntarioSalePriceAdjustmentConstants = {

  REBATE_CRITICAL_THRESHOLD: 450000,
  DEMINISHING_FEDERAL_THRESHOLD: 350000,
  FEDERAL_REBATE_THRESHOLD: 6300,
  ONTARIO_REBATE_THRESHOLD: 24000,
  FEDERAL_REBATE_MULTIPLIER: 4.5,
  FEDERAL_REBATE_PORTION: 0.36,
  PROVINCE_REBATE_PORTION: 0.75,
  DIVIDE_NET_OUT_MULTIPLIER: 1.13,
  DIVIDE_THRESH0LD_MULTIPLIER: 1.193,
  FEDERAL_REBATE_THRESH0LD: 1.133,

  // Sample : F_E_P_E - if federal portion is enable and Province portion is enabled

  TIER_1_THRESHOLD: {
    F_E_P_E: [
      {THRESHOLD_AMOUNT: 368200, MULTIPLIER: 1.052},
      {THRESHOLD_AMOUNT: 424850, MULTIPLIER: 1.133},
      {THRESHOLD_AMOUNT: 484500, MULTIPLIER: 1.193},
      {THRESHOLD_AMOUNT: 484500, MULTIPLIER: 1.13}
    ]
    ,
    F_E_P_D: [
      {THRESHOLD_AMOUNT: 389200, MULTIPLIER: 1.112},
      {THRESHOLD_AMOUNT: 508500, MULTIPLIER: 1.193},
      {THRESHOLD_AMOUNT: 508500, MULTIPLIER: 1.13}
    ],
    F_D_P_E: [
      {THRESHOLD_AMOUNT: 428000, MULTIPLIER: 1.07},
      {THRESHOLD_AMOUNT: 428000, MULTIPLIER: 1.13}
    ],
    F_D_P_D: [
      {THRESHOLD_AMOUNT: 0, MULTIPLIER: 1.13}
    ]
  }
};

export const AlbertaSalePriceAdjustmentConstants = {

  REBATE_CRITICAL_THRESHOLD: 450000,
  DEMINISHING_FEDERAL_THRESHOLD: 350000,
  FEDERAL_REBATE_THRESHOLD: 6300,
  DEMINISHING_REBATE_CALC_ADD_VALUE: 28350,

  FEDERAL_REBATE_MULTIPLIER: 4.5,
  FEDERAL_REBATE_PORTION: 0.36,
  PROVINCE_REBATE_PORTION: 0.75,
  DIVIDE_NET_OUT_MULTIPLIER: 1.05,
  DIVIDE_THRESH0LD_MULTIPLIER: 1.113,
  FEDERAL_REBATE_THRESH0LD: 1.133,

  GST_TIER_THRESHOLD: 1.032,

  // Sample : F_E => federal portion is enabled
  // Sample : F_D => federal portion is disabled

  TIER_1_THRESHOLD: {
    F_E: [
      {THRESHOLD_AMOUNT: 361200.09, MULTIPLIER: 1.032},
      {THRESHOLD_AMOUNT: 472500.00, MULTIPLIER: 1.113}
    ]
    ,
    F_D: [
      {THRESHOLD_AMOUNT: 0, MULTIPLIER: 1.05}
    ]
  }
};

export const ManitobaSalePriceAdjustmentConstants = {

  REBATE_CRITICAL_THRESHOLD: 450000,
  DEMINISHING_FEDERAL_THRESHOLD: 350000,
  FEDERAL_REBATE_THRESHOLD: 6300,
  DEMINISHING_REBATE_CALC_ADD_VALUE: 28350,

  FEDERAL_REBATE_MULTIPLIER: 4.5,
  FEDERAL_REBATE_PORTION: 0.36,
  PROVINCE_REBATE_PORTION: 0.75,
  DIVIDE_NET_OUT_MULTIPLIER: 1.05,
  DIVIDE_THRESH0LD_MULTIPLIER: 1.113,
  FEDERAL_REBATE_THRESH0LD: 1.133,

  GST_TIER_THRESHOLD: 1.032,

  // Sample : F_E => federal portion is enabled
  // Sample : F_D => federal portion is disabled

  TIER_1_THRESHOLD: {
    F_E: [
      {THRESHOLD_AMOUNT: 361200.09, MULTIPLIER: 1.032},
      {THRESHOLD_AMOUNT: 472500.00, MULTIPLIER: 1.113}
    ]
    ,
    F_D: [
      {THRESHOLD_AMOUNT: 0, MULTIPLIER: 1.05}
    ]
  }
};

export const NewBrunswickSalePriceAdjustmentConstants = {

  REBATE_CRITICAL_THRESHOLD: 450000,
  DEMINISHING_FEDERAL_THRESHOLD: 396200,
  FEDERAL_REBATE_THRESHOLD: 6300,
  DEMINISHING_REBATE_CALC_ADD_VALUE: 28350,
  CONSIDERATION_THRESHOLD: 350000,

  FEDERAL_REBATE_MULTIPLIER: 4.5,
  FEDERAL_REBATE_PORTION: 0.36,
  PROVINCE_REBATE_PORTION: 0,
  DIVIDE_NET_OUT_MULTIPLIER: 1.15,
  DIVIDE_THRESH0LD_MULTIPLIER: 1.213,

  // Sample : F_E => federal portion is enabled
  // Sample : F_D => federal portion is disabled

  TIER_1_THRESHOLD: {
    F_E: [
      {THRESHOLD_AMOUNT: 396200, MULTIPLIER: 1.132},
      {THRESHOLD_AMOUNT: 517000, MULTIPLIER: 1.213}
    ]
    ,
    F_D: [
      {THRESHOLD_AMOUNT: 0, MULTIPLIER: 1.15}
    ]
  }
};

export const NovaScotiaSalePriceAdjustmentConstants = {

  REBATE_CRITICAL_THRESHOLD: 450000,
  DEMINISHING_FEDERAL_THRESHOLD: 396200,
  FEDERAL_REBATE_THRESHOLD: 6300,
  DEMINISHING_REBATE_CALC_ADD_VALUE: 28350,
  CONSIDERATION_THRESHOLD: 350000,

  FEDERAL_REBATE_MULTIPLIER: 4.5,
  FEDERAL_REBATE_PORTION: 0.36,
  PROVINCE_REBATE_PORTION: 0,
  DIVIDE_NET_OUT_MULTIPLIER: 1.15,
  DIVIDE_THRESH0LD_MULTIPLIER: 1.213,

  // Sample : F_E => federal portion is enabled
  // Sample : F_D => federal portion is disabled

  TIER_1_THRESHOLD: {
    F_E: [
      {THRESHOLD_AMOUNT: 396200, MULTIPLIER: 1.132},
      {THRESHOLD_AMOUNT: 517000, MULTIPLIER: 1.213}
    ]
    ,
    F_D: [
      {THRESHOLD_AMOUNT: 0, MULTIPLIER: 1.15}
    ]
  }
};

export const SaskatchewanSalePriceAdjustmentConstants = {

  REBATE_CRITICAL_THRESHOLD: 450000,
  DEMINISHING_FEDERAL_THRESHOLD: 350000,
  FEDERAL_REBATE_THRESHOLD: 6300,
  PROVINCIAL_REBATE_THRESHOLD: 8820,
  DEMINISHING_REBATE_CALC_ADD_VALUE: 28350,

  FEDERAL_REBATE_MULTIPLIER: 4.5,
  PROVINCIAL_REBATE_MULTIPLIER: 0.42,
  FEDERAL_REBATE_PORTION: 0.36,
  PROVINCE_REBATE_PORTION: 0.75,
  DIVIDE_NET_OUT_MULTIPLIER: 1.05,
  DIVIDE_THRESH0LD_MULTIPLIER: 1.113,
  FEDERAL_REBATE_THRESH0LD: 1.133,

  GST_TIER_THRESHOLD: 1.032,

  // Sample : F_E => federal portion is enabled
  // Sample : F_D => federal portion is disabled

  TIER_1_THRESHOLD: {
    F_E: [
      {THRESHOLD_AMOUNT: 361200.09, MULTIPLIER: 1.032},
      {THRESHOLD_AMOUNT: 472500.00, MULTIPLIER: 1.113}
    ]
    ,
    F_D: [
      {THRESHOLD_AMOUNT: 0, MULTIPLIER: 1.05}
    ]
  }
};

export interface SalePriceAdjustmentThresholdItem {
  THRESHOLD_AMOUNT: number;
  MULTIPLIER: number;
}
