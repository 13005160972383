import {DocumentProfileLawFirm} from './document-profile-edit/law-firm';
import {DocumentProfileSoa} from './document-profile-edit/soa';
import {DocumentProfileMisc} from './document-profile-edit/misc';
import {DocumentProfileService} from './document-profile-edit/document-profile.service';
import {Observable} from 'rxjs';
import {DocumentProfileTemplateFolder} from './document-profile-edit/template-folder/document-profile-template-folder';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DocumentProcessorType} from '../accounts/shared/account';
import {ProvinceCode} from '../accounts/shared/account-province';
import {Account} from '../accounts/shared/account';
import {AccountService} from '../accounts/account.service';
import {Address} from '../../matters/shared/address';

export class DocumentProfile extends BaseEntity {

  constructor(documentProfile?: DocumentProfile) {
    super(documentProfile);

    this.firmDocumentProfile = new DocumentProfileLawFirm(this.firmDocumentProfile);
    this.accountFileFolder = new DocumentProfileTemplateFolder(this.accountFileFolder);
    this.statementOfAdjustmentsProfile = new DocumentProfileSoa(this.statementOfAdjustmentsProfile);
    this.miscDocumentProfile = new DocumentProfileMisc(this.miscDocumentProfile);
  }

  id: number;
  firmDocumentProfile: DocumentProfileLawFirm;
  accountFileFolder: DocumentProfileTemplateFolder;
  sameAsDefaultProfileFileFolder: boolean;
  statementOfAdjustmentsProfile: DocumentProfileSoa;
  miscDocumentProfile: DocumentProfileMisc;
  customerAccountId;
  profileName: string;
  // defaultProfileFlag is shown in back end DTO. It means if this document profile is default document profile
  defaultProfileFlag: boolean;
  profileType: string;
  // defaultFlag only for UI side
  defaultFlag: boolean;
  selectedRow: boolean;
  dirty: boolean;
  users: number;
  deleted: boolean;
  provinceCode: string;
  templateProcessorType: DocumentProcessorType;
  sourceDocumentProfileId: number;
  active: boolean;

  /**
   * This method returns the law firm document profile for this document profile.
   * If it is same as default profile then it fetches law firm from default profile and returns that.
   * We should use this method instead of accessing 'firmDocumentProfile' directly.
   * @returns {DocumentProfileLawFirm}
   */
  getLawFirmDocumentProfile(documentProfileService: DocumentProfileService, accountId: string): Observable<DocumentProfileLawFirm> {
    if (this.firmDocumentProfile && this.firmDocumentProfile.sameAsDefaultProfileFlag) {
      return documentProfileService.getDefault(accountId).map((defaultProfile: DocumentProfile) => {
        return defaultProfile.firmDocumentProfile;
      });
    } else {
      return Observable.of(this.firmDocumentProfile);
    }
  }

  get activeText(): string {
    return this.active ? 'Yes' : 'No';
  }

  /**
   * This method sets default values for documentProfile so it can be used in adding a new entity
   */
  clearForNew(): void {
    this.id = null;
    this.profileName = '';
    this.defaultProfileFlag = false;
    this.sameAsDefaultProfileFileFolder = true;
    if (this.firmDocumentProfile) {
      this.firmDocumentProfile.id = null;
      this.firmDocumentProfile.identifier = null;
      this.firmDocumentProfile.sameAsDefaultProfileFlag = true;
      this.firmDocumentProfile.sameAsAccountFlag = true;
    }

    if (this.statementOfAdjustmentsProfile) {
      this.statementOfAdjustmentsProfile.id = null;
      this.statementOfAdjustmentsProfile.identifier = null;
      this.statementOfAdjustmentsProfile.sameAsDefaultProfileFlag = true;
    }

    if (this.miscDocumentProfile) {
      this.miscDocumentProfile.id = null;
      this.miscDocumentProfile.identifier = null;
      this.miscDocumentProfile.sameAsDefaultProfileFlag = true;
    }
  }

  isDocumentProfileProccessorTypeWordPerfect(): boolean {
    return this.templateProcessorType == 'WORD_PERFECT';
  }

  //this method will return the firm address with consideration value of flags like 'same as default profile', 'same as account'
  //do use the address attribute in the firmDocumentProfile, since it only has value when it is not same as Default or same as Account.
  async getDocumentProfileFirmAddress(accountId: string, provinceCode: ProvinceCode, documentProfileService: DocumentProfileService, accountService: AccountService): Promise<Address> {
    // const documentProfile: DocumentProfile = await this.getById(docProfileId, accountId, ignoreCache).toPromise();
    if (this.firmDocumentProfile) {
      if (this.firmDocumentProfile.sameAsDefaultProfileFlag) {
        const defaultDocProfile = await documentProfileService.getDefaultProfileForAccountFromCache(accountId, provinceCode).toPromise();
        if (defaultDocProfile) {
          if (defaultDocProfile.firmDocumentProfile && defaultDocProfile.firmDocumentProfile.sameAsAccountFlag) {
            let account: Account = await accountService.getAccount(accountId).toPromise();
            return !!account ? account.mailingAddress : null;
          } else {
            return defaultDocProfile.firmDocumentProfile && defaultDocProfile.firmDocumentProfile.address;
          }
        } else {
          return null;
        }
      } else if (this.firmDocumentProfile.sameAsAccountFlag) {
        let account: Account = await accountService.getAccount(accountId).toPromise();
        return !!account ? account.mailingAddress : null;
      } else {
        return this.firmDocumentProfile && this.firmDocumentProfile.address;
      }
    } else {
      return null;
    }
  }
}

// {
//
//     "miscDocumentProfile": {
//       "id": 82,
//       "sourceMiscDocumentProfile": null,
//       "defaultProfileFlag": true,
//       "miscAddressFormatCode": "FORMAT1",
//       "charactersInSigningLine": 0,
//       "autoInsertFileNoFlag": "YES",
//       "autoCreateTeleFileFlag": "NO",
//       "defaultDearFieldsCode": "Sir or Madam",
//       "twoSigningLinesFlag": "NO",
//       "statutoryDeclarationsFlag": "NO",
//       "bindTheCorporationFlag": "NO",
//       "boldCorpNamesFlag": "NO",
//       "defaultTextPolarisMortgageCode": "FULL_INSURABLE_VALUE",
//       "autoCompletePlanningActFlag": "NO",
//       "wpPrintCommand": null,
//       "charactePerLineSOA": 45,
//       "characterPerLineTrustLedger": 45,
//       "displayJurisdictionSalesTax": "NO",
//       "replaceWithPowerOfSale": "NO",
//       "useSideBySideFlag": "YES",
//       "closingDateGreaterThan": 7,
//       "tabKeyStopsFlag": "NO",
//       "notesTopicFlag": "NO",
//       "underscoreFlag": "YES",
//       "declaredOrSwornCode": "DECLARED"
//     },
//     "firmDocumentProfile": {
//       "id": 72,
//       "sourceFirmDocumentProfile": null,
//       "defaultProfileFlag": false,
//       "firmName": null,
//       "jurisdictionName": null,
//       "sameAsAccountFlag": true,
//       "solePractionerTypeCode": null,
//       "cityLocatedInThe": null,
//       "cityOf": null,
//       "municipalityInThe": null,
//       "municipalityOf": null,
//       "docPreparedByLine1": null,
//       "docPreparedByLine2": null,
//       "docPreparedByLine3": null,
//       "docPreparedByLine4": null,
//       "docPreparedByLine5": null,
//       "docPreparedByLine6": null,
//       "lttPreparedByLine1": null,
//       "lttPreparedByLine2": null,
//       "hstRegNo": null,
//       "address": {
//         "id": 1,
//         "contact": null,
//         "addressTypeCode": "RESIDENCE",
//         "addressLine1": "ABC St",
//         "addressLine2": "Suite 100",
//         "city": "Toronto",
//         "postalCode": "M8K 5U9",
//         "provinceName": "Ontario",
//         "country": "Canada",
//         "primaryAddress": null,
//         "provinceCode": "ON"
//       },
//       "phone": null
//     },
//     "customerAccount": null,
//     "profileName": "TGYKLLYI",
//     "defaultProfileFlag": false,
//     "profileType": null
//   }
// }
