import Utils from './utils';
import moment from 'moment';
import {InterestDateCalendarYearItem} from './interest-date-calendar-year-item';

export class InterestRateUtils {

  static calculateInterestPerCalendarYear(fromDate: string, toDate: string, interestRate: number, amount: number, isPaysforDateClosingVendor?: boolean): InterestDateCalendarYearItem[] {

    let items: InterestDateCalendarYearItem[] = [];

    if (Utils.isNotValidDate(fromDate) || Utils.isNotValidDate(toDate)) {
      return items;
    } else {
      let fromYear: number = moment(fromDate).year();
      let toYear: number = moment(toDate).year();

      if (fromYear > toYear) {
        return items;
      } else {
        items.push(...this.splitDates(fromDate, toDate, isPaysforDateClosingVendor));
        items.forEach(item => {
          item.rate = interestRate;
          item.rateAmount = Utils.roundCurrency(InterestRateUtils.getInterestPerDay(interestRate, item.fromDate) * item.numberOfDays * amount / 100);
        });

      }
    }
    return items;
  }

  /**
   * splits fromDate_toDate interval into an array of dates per calendar year with calculated rates and numberOfDays
   * @param {string} fromDate
   * @param {string} toDate
   * @returns {InterestDateCalendarYearItem[]}
   */

  static splitDates(fromDate: string, toDate: string, isPaysforDateClosingVendor?: boolean): InterestDateCalendarYearItem[] {
    let items: InterestDateCalendarYearItem[] = [];
    let fromYear: number = moment(fromDate, 'YYYY/MM/DD').year();
    let toYear: number = moment(toDate, 'YYYY/MM/DD').year();

    let item: InterestDateCalendarYearItem = new InterestDateCalendarYearItem();
    item.fromDate = fromDate;

    if (fromYear == toYear) {
      item.toDate = toDate;
      let numOfDays: number = Utils.getDateDiff(item.fromDate, item.toDate);
      if (isPaysforDateClosingVendor) {
        numOfDays++;
      }
      item.numberOfDays = numOfDays;
      items.push(item);
    } else {
      item.toDate = `${ fromYear }/12/31`;
      item.numberOfDays = Utils.getDateDiff(item.fromDate, item.toDate) + 1;
      items.push(item);

      items.push(...InterestRateUtils.splitDates(`${ fromYear + 1 }/01/01`, toDate, isPaysforDateClosingVendor));
    }
    return items;
  }

  static getInterestPerDay(interestRate: number, forDate: string): number {
    if (Utils.isLeapYear(forDate)) {
      return interestRate / 366;
    }
    return interestRate / 365;
  }
}
