<div class="container-fluid custom-modal-container border-radius" (keyup.esc)="close()">
    <div class="modal-header">
        <div>
            <div class="col-lg-10"><h1>Add New Mortgage Broker</h1></div>
            <button *ngIf="context.showClose" type="button" class="btn-close pull-right" (click)="close()"><i class=" fa fa-times"></i></button>
        </div>
    </div>
    <div class="modal-body" [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">
        <div>
            <span class="section-title">General Information</span>
        </div>
        <div>
            <dp-new-manage-contact [contact]="mortgageBroker" [contactKey]="contactKey" (onDataChange)="dataChanged($event)"></dp-new-manage-contact>
        </div>
        <div>
            <span class="section-title">My Contacts</span>
        </div>
        <div>
            <dp-new-contact-private-info [contact]="mortgageBroker"></dp-new-contact-private-info>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-lg-12 text-center">
            <button type="button" class="dp-btn" (click)="saveMortgageBroker()"><span>Save</span></button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()"><span>Cancel</span></button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
