<!--modal-->
<div class="custom-modal-container form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Welcome Package</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <!--        SEND CIRF-->
        <ng-container *ngIf="context.originalMatter && !context.originalMatter.isMatterTypeDischarge">
            <div class="form-group col-lg-12">
                <label class="control-label">STEP1: Do you wish to request a CIRF from {{selectedContact?.contactFullNameStartWithFirstName}}</label>
            </div>
            <div class="form-group">
                <div class="radio col-lg-12">
                 <span (click)="sendCirf = true" class="radio display-inline">
                  <input [checked]="sendCirf" type="radio">
                  <label>Send CIRF</label>
                 </span>
                    <span (click)="sendCirf = false" class="radio display-inline padding-left-10">
                  <input [checked]="!sendCirf" type="radio">
                  <label>Do not send CIRF</label>
                 </span>
                </div>
            </div>
        </ng-container>



        <!--        SEND Assigned tasks-->
        <ng-container >
<!--            <ng-container *ngIf="isParticipantMainClient">-->
            <div class="form-group col-lg-12">
                <label class="control-label">STEP{{context.originalMatter && !!context.originalMatter.isMatterTypeDischarge? '1' : '2'}}: Do you wish to assign tasks to {{selectedContact?.contactFullNameStartWithFirstName}}</label>
            </div>
            <div class="form-group">
                <div class="radio col-lg-12">
                     <span (click)="assignTasks = true" class="radio display-inline">
                      <input [checked]="assignTasks" type="radio">
                      <label>Yes</label>
                     </span>
                     <span (click)="assignTasks = false" class="radio display-inline padding-left-10">
                      <input [checked]="!assignTasks" type="radio">
                      <label>No</label>
                     </span>
                </div>
            </div>
            <div *ngIf="assignTasks">
                <dp-matter-work-items
                    [usedInModal]="true"
                    [modalMatter]="context.matterCopy"
                    [matterParticipant]="participant"
                    #workItemsComponent
                ></dp-matter-work-items>
            </div>
        </ng-container>

        <!--        SEND Shared Packages-->
        <div class="form-group col-lg-12">
            <label class="control-label">STEP{{stepNumberForSharingDocs}}: Do you wish to share documents with {{selectedContact?.contactFullNameStartWithFirstName}}</label>
        </div>
        <div class="form-group">
            <div class="radio col-lg-12">
                 <span (click)="shareDocs = true" class="radio display-inline">
                  <input [checked]="shareDocs" type="radio">
                  <label>Yes</label>
                 </span>
                 <span (click)="shareDocs = false" class="radio display-inline padding-left-10">
                  <input [checked]="!shareDocs" type="radio">
                  <label>No</label>
                 </span>
            </div>
        </div>
        <ng-container *ngIf="shareDocs">
            <div class="form-group col-lg-12">
                <label class="control-label">Do you wish to produce documents before sharing, or share existing documents.</label>
            </div>
            <div class="form-group">
                <div class="radio col-lg-12">
                     <span (click)="shareExisting = false" class="radio display-inline">
                      <input [checked]="!shareExisting" type="radio">
                      <label>Produce & Share</label>
                     </span>
                    <span (click)="shareExisting = true" class="radio display-inline padding-left-10">
                      <input [checked]="shareExisting" type="radio">
                      <label>Share Existing</label>
                     </span>
                </div>
            </div>
            <div class="form-group col-lg-12">

                    <ng-container *ngIf="shareExisting">
                        <dp-matter-documents-for-this-matter
                            [isMaterOpening]="true"
                            #documentsForThisMatterComponent>
                        </dp-matter-documents-for-this-matter>
                    </ng-container>

                    <ng-container *ngIf="!shareExisting">
                        <dp-matter-document-production
                            [usedInModal]="true"
                        #documentProductionComponent>
                        </dp-matter-document-production>
                    </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button (click)="send()"
                    [disabled]="!sendCirf && !assignTasks && !shareDocs"
                    class="dp-btn keyboard-save-action"
                    id="okBtn"
                    type="button">
                <span>Send</span>
            </button>
            <button (click)="cancel()"
                    class="dp-btn dp-btn-cancel"
                    type="button">
                <span>Close</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
