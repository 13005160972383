import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[ngModel][capitalized]',
  host: {
    '(blur)': 'TextChange($event)'
  }
})
export class CapitalizedInput {

  constructor(private model: NgModel) {
  }

  TextChange(input) {
    if (this.model.value) {
      this.model.reset(_.capitalize(this.model.value));
      this.model.valueAccessor.writeValue(_.capitalize(this.model.value));
    }
  }
}
