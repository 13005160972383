import {EventEmitter, inject, Injectable} from '@angular/core';
import {ParcelRegister} from '../../../shared-main/teranet/parcel-register';
import {currentMatter} from '../current-matter';
import {MatterProperty} from '../matter-property';
import {AddressUtil} from '../address-util';
import {TeranetImportData} from '../../../shared-main/teranet/teranet-import-data';
import {CondominiumPlan} from '../../property-teranet/unit-level-plan/condominium-plan';
import Utils from '../../../shared-main/utils';
import {Address} from '../address';
import {ParcelLegalDescription} from '../parcel-legal-description';
import {UnitLevelPlanUtil} from '../../property-teranet/unit-level-plan/unit-level-plan-util';
import {CondominiumExpense} from '../../property-teranet/unit-level-plan/condominium-expense';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {JurisdictionDepartment} from '../../../admin/jurisdiction-departments/jurisdiction-departments';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {PROVINCE_CODES} from '../user-province';

@Injectable({
  providedIn: 'root'
})
export class ImportPropertyDataService {

  errorService = inject(ErrorService);

  get matter() {
    return currentMatter.value;
  }

  public importTeranetConnectData(
    teranetImportDataSP: TeranetImportData,
    ontarioTaxRateSlab: ConsiderationTaxes,
    torontoTaxRateSlab: ConsiderationTaxes,
    jurisdictionDepartments: JurisdictionDepartment[],
    selectedParcelRegister: ParcelRegister,
    showWizardFields: boolean,
    updateOwnerDataForWizard: EventEmitter<string>) {
    if (teranetImportDataSP) {
      this.matter.dirty = true;
      if (this.matter.matterPropertyWithCondo) {
        if (teranetImportDataSP.jurisdiction && ((this.matter.matterPropertyWithCondo.jurisdiction && teranetImportDataSP.jurisdiction.jurisdictionName != this.matter.matterPropertyWithCondo.jurisdiction.jurisdictionName) || (!this.matter.matterPropertyWithCondo.jurisdiction))) {
          this.matter.matterPropertyWithCondo.jurisdiction = teranetImportDataSP.jurisdiction;
          this.matter.updateMLTTAndLTTOnJurisdictionChange(ontarioTaxRateSlab, torontoTaxRateSlab);
          if (this.matter.matterPropertyWithCondo.jurisdiction.departments && this.matter.matterPropertyWithCondo.jurisdiction.departments.length) {
            this.matter.jurisdictionChange(jurisdictionDepartments, this.matter.matterPropertyWithCondo.jurisdiction);
          }
          if (this.matter.soaTrustLedgerCollection) {
            this.matter.soaTrustLedgerCollection.addOrUpdateCompliance();
            if (this.matter.secondarySoaSheetsCollection) {
              this.matter.secondarySoaSheetsCollection.forEach(collection => {
                collection.addOrUpdateCompliance();
              });
            }
          }
        }
        if (teranetImportDataSP.registryOffice && ((this.matter.matterPropertyWithCondo.registryOffice && teranetImportDataSP.registryOffice.officeNumber != this.matter.matterPropertyWithCondo.registryOffice.officeNumber) || (!this.matter.matterPropertyWithCondo.registryOffice))) {
          this.matter.matterPropertyWithCondo.registryOffice = teranetImportDataSP.registryOffice;
          this.matter.matterPropertyWithCondo.registryOfficeName = teranetImportDataSP.registryOffice.officeName;
        }
        // If it is this item ({label : 'N/y', value : 'N_y'}), we need update 'NO'.
        if (!teranetImportDataSP.isCondo && !this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
          this.matter.matterPropertyWithCondo.isCondominium = 'NO';
        }

        if (teranetImportDataSP.isCondo != this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
          this.matter.matterPropertyWithCondo.isCondominium = teranetImportDataSP.isCondo ? 'YES' : 'NO';
          if (this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
            if (!this.matter.condoCorporation) {
              this.matter.addCondominiumParticipant();
            }
            if (this.matter.soaTrustLedgerCollection) {
              this.matter.soaTrustLedgerCollection.addOrRemoveCondoFee();
              if (this.matter.secondarySoaSheetsCollection) {
                this.matter.secondarySoaSheetsCollection.forEach(collection => {
                  collection.addOrRemoveCondoFee();
                });
              }

            }
          } else if (this.matter.soaTrustLedgerCollection) {
            this.matter.soaTrustLedgerCollection.removeCondoFee();
            if (this.matter.secondarySoaSheetsCollection) {
              this.matter.secondarySoaSheetsCollection.forEach(collection => {
                collection.removeCondoFee();
              });
            }

          }
          this.matter.createUpdateAdjustmentCommonExp();
        }
        if (teranetImportDataSP.interestEstate != this.matter.matterPropertyWithCondo.interestEstate) {
          this.matter.matterPropertyWithCondo.interestEstate = teranetImportDataSP.interestEstate;
        }
        if (teranetImportDataSP.condominiumJurisdiction) {
          this.matter.matterPropertyWithCondo.condominiumJurisdiction = teranetImportDataSP.condominiumJurisdiction;
        }
        if (teranetImportDataSP.city) {
          this.matter.matterPropertyWithCondo.city = teranetImportDataSP.city;
        }
        if (teranetImportDataSP.municipality && !this.matter.isMatterProvinceMB) {
          this.matter.matterPropertyWithCondo.municipality = teranetImportDataSP.municipality;
        }
        if (teranetImportDataSP.registryOffice) {
          this.matter.matterPropertyWithCondo.registryOffice = teranetImportDataSP.registryOffice;
        }
        if (teranetImportDataSP.lastInstrumentNumber && !this.matter.isMatterProvinceMB) {
          this.matter.matterPropertyWithCondo.lastInstrumentNumber = teranetImportDataSP.lastInstrumentNumber;
        }

        // Preserve the parcelRegisterId, to prevent subsequent import of property data
        if (teranetImportDataSP.parcelRegisterId) {
          this.matter.matterPropertyWithCondo.importedParcelRegisterIds.push(teranetImportDataSP.parcelRegisterId);
        }

        if (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceBC) {
          this.importPropertyDataABorBC(teranetImportDataSP, selectedParcelRegister);
        } else if (this.matter.isMatterProvinceMB) {
          this.importPropertyDataMB(teranetImportDataSP, selectedParcelRegister);
        } else if (this.matter.isMatterProvinceSK) {
          this.importPropertyDataSK(teranetImportDataSP, selectedParcelRegister);
        } else {
          this.importPropertyDataON(teranetImportDataSP, selectedParcelRegister);
        }
        if (this.matter.matterPropertyWithCondo.isCondominium && !this.matter.isMatterProvinceMB) {
          this.matter.matterPropertyWithCondo.unitLevelPlan
            = UnitLevelPlanUtil.generateUnitLevelPlan(this.matter.matterPropertyWithCondo, this.matter.provinceCode);

        }
        if (this.matter.isMatterProvinceAB && !this.matter.isTitleDetailsManual()) {
          this.matter.generateAndUpdateTitleDetailsAB();
        }
      }
      if (showWizardFields) {
        updateOwnerDataForWizard.emit('PropertyImported');
      }
    }
  }

  public updateCondoChangesForImport(): void {
    if (this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
      this.matter.matterPropertyWithCondo.condominiumExpenses = [];
      this.matter.matterPropertyWithCondo.condominiumPlans = [];
      this.matter.matterPropertyWithCondo.condominiumTotalExpenses = 0;
      this.matter.matterPropertyWithCondo.condominiumJurisdiction = '';
      this.matter.matterPropertyWithCondo.pin = '';

    } else {
      this.matter.matterPropertyWithCondo.pin = '';
      this.matter.nonBlanketMatterProperty.filter(matterProperty => (matterProperty.isCondominium == undefined || !matterProperty.isCondominium)).forEach(item => {
        (<any>this.matter.matterProperties).remove(item);
      });
    }
    this.matter.matterPropertyWithCondo.isCondominium = this.matter.matterPropertyWithCondo.isPropertyCondominium() ? 'NO' : 'YES';
    if (this.matter.matterPropertyWithCondo.isCondominium === 'YES') {
      this.matter.onCondominumChange();
    }
  }

  public isParcelRegisterAlreadyImported(selectedParcelRegister: ParcelRegister): boolean {
    // We will keep the list of the Id's of the Imported Parcel Registers in the matter property
    // At this point we will check the  this.selectedParcelRegister.id to see if this is in the list of Imported Parcel Registers
    // if we find this parcel register has already been imported show the error message, otherwise continue with flow.
    return selectedParcelRegister.id && Array.isArray(this.matter.matterPropertyWithCondo.importedParcelRegisterIds) &&
      this.matter.matterPropertyWithCondo.importedParcelRegisterIds.length > 0 &&
      this.matter.matterPropertyWithCondo.importedParcelRegisterIds.indexOf(selectedParcelRegister.id) > -1;
  }

  private isFirstSpinImport(): boolean {
    return !this.matter.matterPropertyWithCondo.propertyTaxesSummary;
  }

  private importLinc(teranetImportDataSP: TeranetImportData) {
    if (this.matter.isMatterProvinceBC) {
      this.importBcLinc(teranetImportDataSP);
      return;
    }
    if (teranetImportDataSP.lincNumber) {
      const splitLincNumbers: string[] = teranetImportDataSP.lincNumber.split(',');
      if (splitLincNumbers.length > 0) {
        if (!this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
          let tmpSplitLincNumbers: string[] = splitLincNumbers;
          if (this.matter.nonBlanketMatterProperty.length === 1 && !this.matter.nonBlanketMatterProperty[ 0 ].lincNumber) {
            tmpSplitLincNumbers = splitLincNumbers.slice(1);
            this.matter.nonBlanketMatterProperty[ 0 ].lincNumber = splitLincNumbers[ 0 ].trim();
          }
          tmpSplitLincNumbers.forEach((lincNumber: string) => {
            let matterProperty: MatterProperty = new MatterProperty();
            matterProperty.address = new Address();
            matterProperty.matterPropertyCode = 'QUESTION';
            matterProperty.lincNumber = lincNumber.trim();
            this.matter.matterProperties.push(matterProperty);
          });
        }
      }
    }
  }

  private importBcLinc(teranetImportDataSP: TeranetImportData) {
    if (teranetImportDataSP.lincNumber) {
      this.matter.matterPropertyWithCondo.lincNumber = teranetImportDataSP.lincNumber;
    }
  }

  private getShortLegalDescriptionFromFirstImportedSpinParcel(): string {

    if (this.matter && this.matter.teranetDocket) {
      let parcelRegister: ParcelRegister = null;
      if (this.matter.teranetDocket.propertyImportedFromSpin && this.matter.teranetDocket.firstSpinPropertyParcelRegisterId) {
        parcelRegister = this.matter.teranetDocket.getParcelRegisterById(this.matter.teranetDocket.firstSpinPropertyParcelRegisterId);
      } else if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.propertyTaxesSummary) { //find original parcel register based on legal description if reference was missing for any reason
        parcelRegister = this.matter.teranetDocket.getParcelRegisterByLegalDescription(this.matter.matterPropertyWithCondo.propertyTaxesSummary);
      }

      if (parcelRegister && parcelRegister.propertyDetails && parcelRegister.propertyDetails.length) {
        return parcelRegister.getSpinShortLegalDescription();
      }
    }

    return '';
  }

  private updateShortLegalDescription(importedLegalDescription: string): void {
    // Short Description - prior to appending to any existing data in the matter, insert a space
    if (importedLegalDescription) {
      this.matter.matterPropertyWithCondo.shortLegalDescription
        = (this.matter.matterPropertyWithCondo.shortLegalDescription ? this.matter.matterPropertyWithCondo.shortLegalDescription + ' ' : '')
        + importedLegalDescription;
      if (this.matter.matterPropertyWithCondo.shortLegalDescription && this.matter.matterPropertyWithCondo.shortLegalDescription.length > 255) {
        this.matter.matterPropertyWithCondo.shortLegalDescription = this.matter.matterPropertyWithCondo.shortLegalDescription.slice(0, 255);
      }
    }
  }

  private appendParcelLegalDescription(parcelLegalDescription: ParcelLegalDescription) {
    if (!Array.isArray(this.matter.matterPropertyWithCondo.parcelLegalDescriptions)) {
      this.matter.matterPropertyWithCondo.parcelLegalDescriptions = [];
    }
    this.matter.matterPropertyWithCondo.parcelLegalDescriptions.push(parcelLegalDescription);
  }

  private appendToLegalDescriptionSummary(legalDescription: string) {
    if (!this.matter.matterPropertyWithCondo.legalDescriptionSummary) {
      this.matter.matterPropertyWithCondo.legalDescriptionSummary = legalDescription;
      this.matter.matterPropertyWithCondo.fullLegalDescription = legalDescription;
    } else {
      this.matter.matterPropertyWithCondo.legalDescriptionSummary += '\n\n' + legalDescription;
      this.matter.matterPropertyWithCondo.fullLegalDescription += '\n\n' + legalDescription;
    }
  }

  private validateParcelData(parcelLegalDescription: ParcelLegalDescription): boolean {
    return (!!parcelLegalDescription.unitType || !!parcelLegalDescription.parcelNumber || !!parcelLegalDescription.unitNumber || !!parcelLegalDescription.extensionNumber
      || parcelLegalDescription.commonElementFees > 0 || parcelLegalDescription.parcelTitles.length > 0);
  }

  private isParcelEmpty(parcelLegalDescription?: ParcelLegalDescription): boolean {
    if (parcelLegalDescription) {
      return !this.validateParcelData(parcelLegalDescription);
    } else {
      if (this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length > 0) {
        return !this.validateParcelData(this.matter.matterPropertyWithCondo.parcelLegalDescriptions[ 0 ]);
      } else {
        return (this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length == 0);
      }
    }
  }

  private isExistingParcelEmpty(): boolean {
    return (this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length == 1 && this.isParcelEmpty(this.matter.matterPropertyWithCondo.parcelLegalDescriptions[ 0 ]));
  }

  private isParcelNumberAddedOnMatter(parcelLegalDescription: ParcelLegalDescription): boolean {
    let parcelNumberIndex = this.matter.matterPropertyWithCondo.parcelLegalDescriptions.findIndex(item => item.parcelNumber === parcelLegalDescription.parcelNumber);
    return parcelNumberIndex > -1;
  }

  private isCondominiumPlanExistOnImportProperty(teranetImportDataSP: TeranetImportData): boolean {
    return teranetImportDataSP.condominiumPlans.length && !!teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanNumber;
  }

  private appendParcelForCondoProperty(teranetImportDataSP: TeranetImportData) {
    this.errorService.removeDpSaveError('teranet.thirdParty.property.importSK');
    if (Array.isArray(this.matter.matterPropertyWithCondo.parcelLegalDescriptions)) {

      let parcelLegalDescription = teranetImportDataSP.parcelLegalDescription;
      if (!!parcelLegalDescription.parcelNumber) {
        if (this.isExistingParcelEmpty()) {
          this.matter.matterPropertyWithCondo.parcelLegalDescriptions[ 0 ] = teranetImportDataSP.parcelLegalDescription;
        } else {
          if (this.isParcelNumberAddedOnMatter(teranetImportDataSP.parcelLegalDescription)) {
            this.matter.matterPropertyWithCondo.parcelLegalDescriptions.push(new ParcelLegalDescription(parcelLegalDescription));
          } else {
            this.errorService.addDpSaveError(DPError.createDPError('teranet.thirdParty.property.importSK'));

          }
        }
      }
    }
    if (this.errorService.hasNoErrors() && this.isCondominiumPlanExistOnImportProperty(teranetImportDataSP)) {
      if (teranetImportDataSP.condominiumPlans) {
        this.matter.matterPropertyWithCondo.condominiumPlans[ 0 ] = teranetImportDataSP.condominiumPlans[ 0 ];
      }
    }
  }

  private importPropertyDataABorBC(teranetImportDataSP: TeranetImportData, selectedParcelRegister: ParcelRegister) {
    let property = this.matter.matterPropertyWithCondo;
    if (property.address.addressTextWithoutCountry != (teranetImportDataSP.address as Address).addressTextWithoutCountry) {
      property.address = teranetImportDataSP.address as Address;
      if (property.address.addressLine1 && property.address.addressLine1.length > Address.ADDRESS_LINE_INCREASED_MAX_LENGTH) {
        property.address.normalizeAddressLines(Address.ADDRESS_LINE_INCREASED_MAX_LENGTH);
      }
      property.address.primaryAddress = true;
    }
    if (!property.isPropertyCondominium() && this.isFirstSpinImport()) {
      property.propertyDescriptionType = teranetImportDataSP.propertyDescriptionType;
    }

    let isNewAddedCondoPlanDuplicated: boolean = false;
    // It is condo. Ir needs to check if update condominiumExpenses
    if (property.isPropertyCondominium()
      && (teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanType
        || teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanNumber
        || teranetImportDataSP.condominiumExpenses[ 0 ].noUndividedShare
        || teranetImportDataSP.condominiumExpenses[ 0 ].unitNumber
        || teranetImportDataSP.lincNumber)) {

      if (Array.isArray(teranetImportDataSP.condominiumPlans) && teranetImportDataSP.condominiumPlans.length && !teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanType) {
        teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanType = 'CONVENTIONAL_CONDOMINIUM_PLAN'; //default to Conventional Condominium if the plan type is missing
      }

      let condoPlanForNewExpense: CondominiumPlan = teranetImportDataSP.condominiumPlans[ 0 ];
      const newCondoPlanNumber: string = Utils.buildCondoPlanNumber(condoPlanForNewExpense, true, null);
      if (Array.isArray(property.condominiumPlans)) {
        const existingCondoPlanWithSamePlanNumber: CondominiumPlan = property.condominiumPlans.find(existingCodoPlan => Utils.buildCondoPlanNumber(existingCodoPlan, true, null) == newCondoPlanNumber);
        if (existingCondoPlanWithSamePlanNumber) {
          condoPlanForNewExpense = existingCondoPlanWithSamePlanNumber;
          isNewAddedCondoPlanDuplicated = true;
        }
      }
      const needToAddNewCondoExpense: boolean = !!teranetImportDataSP.condominiumExpenses[ 0 ].noUndividedShare || !!teranetImportDataSP.condominiumExpenses[ 0 ].unitNumber || !!teranetImportDataSP.lincNumber;

      if (needToAddNewCondoExpense) {
        teranetImportDataSP.condominiumExpenses[ 0 ].condominiumPlan = condoPlanForNewExpense;
        teranetImportDataSP.condominiumExpenses[ 0 ].planNumber = newCondoPlanNumber;
        if (Array.isArray(property.condominiumExpenses)) {
          property.condominiumExpenses.push(new CondominiumExpense(teranetImportDataSP.condominiumExpenses[ 0 ]));

        } else {
          property.condominiumExpenses = teranetImportDataSP.condominiumExpenses;
        }
        if (teranetImportDataSP.lincNumber) {
          const splitLincNumbers: string[] = teranetImportDataSP.lincNumber.split(',');
          if (splitLincNumbers) {
            if (splitLincNumbers.length > 0) {
              property.condominiumExpenses[ property.condominiumExpenses.length - 1 ].lincNumber
                = splitLincNumbers[ 0 ];
            }
            if (splitLincNumbers.length > 1) {
              for (let i: number = 1; i < splitLincNumbers.length; i++) {
                let condominiumExpense: CondominiumExpense = new CondominiumExpense();
                condominiumExpense.lincNumber = splitLincNumbers[ i ];
                property.condominiumExpenses.push(new CondominiumExpense());
              }
            }
          }
          property.lincNumber = UnitLevelPlanUtil.generateLincNumber(property);
        }
      }
    }

    // It is condo. Ir needs to check if update condominiumPlans
    if (property.isPropertyCondominium()
      && (teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanNumber
        || teranetImportDataSP.condominiumPlans[ 0 ].condominiumPlanType)) {
      if (Array.isArray(property.condominiumPlans)) {
        if (!isNewAddedCondoPlanDuplicated) {
          property.condominiumPlans.push(teranetImportDataSP.condominiumPlans[ 0 ]);
        }
      } else {
        property.condominiumPlans = teranetImportDataSP.condominiumPlans;
      }

    }

    //track parcel register used for first Spin import
    if (this.matter.teranetDocket && this.isFirstSpinImport() && teranetImportDataSP.isLegalDescriptionUpdated && teranetImportDataSP.parcelRegisterId && !this.matter.teranetDocket.firstSpinPropertyParcelRegisterId) {
      this.matter.teranetDocket.firstSpinPropertyParcelRegisterId = teranetImportDataSP.parcelRegisterId;
      this.matter.teranetDocket.propertyImportedFromSpin = true;
    }

    // It is not  condo with the  Plan/Block/Lot property. Ir needs to check if update Plan/Block/Lot
    // Plan - prior to appending to any existing data in the matter, insert a ', '
    // Block - prior to appending to any existing data in the matter, insert a ', '
    // Lot - prior to appending to any existing data in the matter, insert a ', '
    if (!property.isPropertyCondominium() && teranetImportDataSP.isPlanBlockLot && this.isFirstSpinImport()) {
      if (teranetImportDataSP.plan) {
        property.plan
          = (property.plan ? property.plan + ', ' : '') + teranetImportDataSP.plan;
        if (property.plan && property.plan.length > 80) {
          property.plan = property.plan.slice(0, 80);
        }
      }
      if (teranetImportDataSP.block) {
        property.block
          = (property.block ? property.block + ', ' : '') + teranetImportDataSP.block;
        if (property.block && property.block.length > 80) {
          property.block = property.block.slice(0, 80);
        }
      }
      if (teranetImportDataSP.lot) {
        property.lot
          = (property.lot ? property.lot + ', ' : '') + teranetImportDataSP.lot;
        if (property.lot && property.lot.length > 80) {
          property.lot = property.lot.slice(0, 80);
        }
      }
    }

    if (!this.isFirstSpinImport() && (teranetImportDataSP.isLegalDescriptionUpdated || teranetImportDataSP.isShortDescriptionUpdated)) {
      if (!property.isPropertyCondominium()) {
        if (property.propertyDescriptionType == 'PLAN_BLOCK_LOT') { //2nd import where 1st import was plan/block/lot
          property.plan = '';
          property.block = '';
          property.lot = '';
          property.exceptionType = '';
          property.propertyDescriptionType = 'METES_AND_BOUNDS';

          if (this.matter.numberOfParcels == 1) {
            property.fullLegalDescription = 'PARCEL ONE:\n' + property.propertyTaxesSummary + '\n';
          }

          this.matter.numberOfParcels++;

          if (teranetImportDataSP.isLegalDescriptionUpdated) {
            property.fullLegalDescription = property.fullLegalDescription
              + '\nPARCEL '
              + Utils.convertNumberToWord(this.matter.numberOfParcels).toUpperCase()
              + ':\n'
              + selectedParcelRegister.propertyDescription + '\n';
          }

          if (this.matter.numberOfParcels == 2) { //only on 2nd import of a plan/block/lot or metes & bounds
            property.shortLegalDescription = this.getShortLegalDescriptionFromFirstImportedSpinParcel();
          }

          if (teranetImportDataSP.isShortDescriptionUpdated) {
            this.updateShortLegalDescription(selectedParcelRegister.getSpinShortLegalDescription());
          }

        } else if (property.propertyDescriptionType == 'METES_AND_BOUNDS') {
          if (this.matter.numberOfParcels == 1) {
            property.fullLegalDescription = 'PARCEL ONE:\n' + property.fullLegalDescription + '\n';
          }

          this.matter.numberOfParcels++;

          if (teranetImportDataSP.isShortDescriptionUpdated) {
            let shortLegalDescription: string = teranetImportDataSP.isPlanBlockLot ? selectedParcelRegister.getSpinShortLegalDescription() : teranetImportDataSP.shortLegalDescription;
            this.updateShortLegalDescription(shortLegalDescription);
          }

          if (teranetImportDataSP.isLegalDescriptionUpdated) {
            property.fullLegalDescription = property.fullLegalDescription
              + '\n' + 'PARCEL '
              + Utils.convertNumberToWord(this.matter.numberOfParcels).toUpperCase() + ':\n'
              + selectedParcelRegister.propertyDescription + '\n';
          }
        }
      } else { //for condos, just increment counter
        this.matter.numberOfParcels++;
      }
    }

    // It is not  condo with the  Plan/Block/Lot property or is a condo. Ir needs to check if update exceptionType and exceptionTypeDescription
    if ((property.isPropertyCondominium() || teranetImportDataSP.isPlanBlockLot) && this.isFirstSpinImport()) {
      if (teranetImportDataSP.exceptionType) {
        property.exceptionType = teranetImportDataSP.exceptionType;
      }
      if (teranetImportDataSP.exceptionTypeDescription) {
        property.exceptionTypeDescription = teranetImportDataSP.exceptionTypeDescription;
      }
    }

    if (this.isFirstSpinImport() && !property.isPropertyCondominium() && teranetImportDataSP.isMetesAndBounds) {
      if (teranetImportDataSP.shortLegalDescription) {
        this.updateShortLegalDescription(teranetImportDataSP.shortLegalDescription);
      }

      // Legal Description - prior to appending to any existing data in the matter, insert a blank line
      if (teranetImportDataSP.fullLegalDescription) {
        property.fullLegalDescription
          = (property.fullLegalDescription ? property.fullLegalDescription + '\n\n' : '')
          + teranetImportDataSP.fullLegalDescription;
      }
    }

    //import linc
    this.importLinc(teranetImportDataSP);
    // Title No. - prior to appending to any existing data in the matter, insert a ', '
    if (teranetImportDataSP.titleNumber) {
      property.titleNumber
        = (property.titleNumber ? property.titleNumber + ', ' : '')
        + teranetImportDataSP.titleNumber;
      if (property.titleNumber && property.titleNumber.length > 40) {
        property.titleNumber = property.titleNumber.slice(0, 40);
      }
    }

    if (selectedParcelRegister.propertyDescription && teranetImportDataSP.isLegalDescriptionUpdated) { //append to legal description from SPIN in case of multiple imports instead of replacing
      property.propertyTaxesSummary = property.propertyTaxesSummary ?
        property.propertyTaxesSummary + '\n\n' + selectedParcelRegister.propertyDescription : selectedParcelRegister.propertyDescription;
    }

    if (this.matter.isMatterProvinceBC && teranetImportDataSP.isLegalDescriptionUpdated) {
      property.fullLegalDescription = teranetImportDataSP.fullLegalDescription;
      property.partLot = teranetImportDataSP.fullLegalDescription;
    }
  }

  private async importPropertyDataMB(teranetImportDataSP: TeranetImportData, selectedParcelRegister: ParcelRegister): Promise<void> {
    if (this.matter.isMatterProvinceMB) {
      let property = this.matter.matterPropertyWithCondo;
      if (property.address.addressTextWithoutCountry != (teranetImportDataSP.address as Address).addressTextWithoutCountry) {
        property.address = teranetImportDataSP.address as Address;
        if (property.address.addressLine1 && property.address.addressLine1.length > Address.ADDRESS_LINE_INCREASED_MAX_LENGTH) {
          property.address.normalizeAddressLines(Address.ADDRESS_LINE_INCREASED_MAX_LENGTH);
        }
        property.address.primaryAddress = true;
      }
      if (teranetImportDataSP.registryOffice) {
        this.matter.matterPropertyWithCondo.landTitleOfficeLocation = teranetImportDataSP.registryOffice.region;
      }

      // It is condo. Ir needs to check if update condominiumExpenses
      if (property.isPropertyCondominium()) {
        if (teranetImportDataSP.parcelLegalDescription.parcelNumber
          || teranetImportDataSP.parcelLegalDescription.unitNumber
          || teranetImportDataSP.parcelLegalDescription.percentageInterest) {
          this.appendParcelLegalDescription(teranetImportDataSP.parcelLegalDescription);
        }

        if (teranetImportDataSP.condominiumPlanNumber) {
          this.matter.matterPropertyWithCondo.condominiumPlanNumber = teranetImportDataSP.condominiumPlanNumber;
        }

        this.appendToLegalDescriptionSummary(selectedParcelRegister.propertyDescription);

      } else {
        //Need to confirm BA about it
        if (teranetImportDataSP.parcelLegalDescription.memo || teranetImportDataSP.parcelLegalDescription.parcelNumber) {
          this.appendParcelLegalDescription(teranetImportDataSP.parcelLegalDescription);
        }
        let savedOverrideDescription: boolean = this.matter.matterPropertyWithCondo.overrideDescription;
        this.matter.matterPropertyWithCondo.overrideDescription = false;
        this.matter.matterPropertyWithCondo.formatLegalDescriptionSummary();
        this.matter.matterPropertyWithCondo.overrideDescription = savedOverrideDescription;
        this.matter.matterPropertyWithCondo.fullLegalDescription = this.matter.matterPropertyWithCondo.legalDescriptionSummary; // backup this value to be used if user decides to revert to imported data
      }
    }
  }

  private appendParcel(parcelLegalDescription: ParcelLegalDescription) {
    if (Array.isArray(this.matter.matterPropertyWithCondo.parcelLegalDescriptions)) {
      if (!!parcelLegalDescription.parcelNumber) {
        let parcelNumberIndex = this.matter.matterPropertyWithCondo.parcelLegalDescriptions.findIndex(item => item.parcelNumber === parcelLegalDescription.parcelNumber);
        if (parcelNumberIndex > -1) {
          this.matter.matterPropertyWithCondo.parcelLegalDescriptions[ parcelNumberIndex ] = parcelLegalDescription;
        } else {
          if (this.isExistingParcelEmpty()) {
            this.matter.matterPropertyWithCondo.parcelLegalDescriptions[ 0 ] = parcelLegalDescription;
          } else {
            this.matter.matterPropertyWithCondo.parcelLegalDescriptions.push(new ParcelLegalDescription(parcelLegalDescription));
          }

        }
      }
    }
  }

  private importPropertyDataSK(teranetImportDataSP: TeranetImportData, selectedParcelRegister: ParcelRegister) {
    let property = this.matter.matterPropertyWithCondo;
    if (property.address.addressTextWithoutCountry != (teranetImportDataSP.address as Address).addressTextWithoutCountry) {
      property.address = teranetImportDataSP.address as Address;
      if (property.address.addressLine1 && property.address.addressLine1.length > Address.ADDRESS_LINE_INCREASED_MAX_LENGTH) {
        property.address.normalizeAddressLines(Address.ADDRESS_LINE_INCREASED_MAX_LENGTH);
      }
      property.address.primaryAddress = true;
    }

    // It is condo. Ir needs to check if update condominiumPlanNumber
    if (property.isPropertyCondominium()) {
      if (teranetImportDataSP.parcelLegalDescription) {
        if (teranetImportDataSP.parcelLegalDescription.parcelNumber
          || teranetImportDataSP.parcelLegalDescription.unitNumber
          || teranetImportDataSP.parcelLegalDescription.percentageInterest) {
          this.appendParcelForCondoProperty(teranetImportDataSP);
        }
      }

    } else {
      if (selectedParcelRegister.iscImportData && selectedParcelRegister.iscImportData.farmLand) {
        this.matter.matterPropertyWithCondo.purchaseIsOfCode = 'FARM_LAND';
      } else {
        if (this.matter.matterPropertyWithCondo.purchaseIsOfCode === 'FARM_LAND') {
          this.matter.matterPropertyWithCondo.purchaseIsOfCode = 'QUESTION';
        }
      }
      if (teranetImportDataSP.parcelLegalDescription || teranetImportDataSP.parcelLegalDescription.parcelNumber) {
        this.appendParcel(teranetImportDataSP.parcelLegalDescription);
      }
    }
  }

  private importPropertyDataON(teranetImportDataSP: TeranetImportData, selectedParcelRegister: ParcelRegister) {
    let primaryProperty: MatterProperty = this.matter.matterPropertyWithCondo;

    if (this.matter.matterPropertyWithCondo.address.addressTextWithoutCountry !=
      teranetImportDataSP.address as string) {
      this.matter.matterPropertyWithCondo.address = AddressUtil.extraAddressFromString(teranetImportDataSP.address as string);
      this.matter.matterPropertyWithCondo.address.primaryAddress = true;
    }
    //There are different updating between ON and AB.
    if (teranetImportDataSP.unitLevelPlan) {
      this.matter.matterPropertyWithCondo.condominiumExpenses = teranetImportDataSP.condominiumExpenses;
    }

    if (teranetImportDataSP.condominiumPlans) {
      this.matter.matterPropertyWithCondo.condominiumPlans = teranetImportDataSP.condominiumPlans;
    }
    if (teranetImportDataSP.condominiumTotalExpenses) {
      this.matter.matterPropertyWithCondo.condominiumTotalExpenses = teranetImportDataSP.condominiumTotalExpenses;
    }

    if (Array.isArray(primaryProperty.condominiumPlans) && primaryProperty.condominiumPlans.length == 1) {
      const singleCondoPlan: CondominiumPlan = primaryProperty.condominiumPlans[ 0 ];
      //if matter only has one condoPlan, then all condoExpense in the matter will use derived planNumber from this condoPlan
      if (Array.isArray(primaryProperty.condominiumExpenses) && primaryProperty.condominiumExpenses.length > 0) {
        primaryProperty.condominiumExpenses.forEach(expense => {
          expense.planNumber = Utils.buildCondoPlanNumber(singleCondoPlan, false, primaryProperty.condominiumJurisdiction);
          expense.condominiumPlan = singleCondoPlan;
        });
      }
    }

    if (teranetImportDataSP.partLot) {
      this.matter.matterPropertyWithCondo.partLot = teranetImportDataSP.partLot;
    }
    if (teranetImportDataSP.plan) {
      this.matter.matterPropertyWithCondo.plan = teranetImportDataSP.plan;
    }
    if (this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
      this.matter.matterPropertyWithCondo.pin = teranetImportDataSP.pin;
    } else if (teranetImportDataSP.pin) {
      teranetImportDataSP.pin.split(',').forEach((item, index) => {
          if (index < this.matter.matterProperties.length) {
            this.matter.matterProperties[ index ].pin = item;
          } else {
            let matterProperty = new MatterProperty();
            matterProperty.address = new Address();
            matterProperty.matterPropertyCode = 'QUESTION';
            matterProperty.pin = item;
            this.matter.matterProperties.push(matterProperty);
          }
        }
      );
    }
    if (teranetImportDataSP.onPlan) {
      this.matter.matterPropertyWithCondo.onPlan = teranetImportDataSP.onPlan;
    }
    if (teranetImportDataSP.beingPart) {
      this.matter.matterPropertyWithCondo.beingPart = teranetImportDataSP.beingPart;
    }
    if (teranetImportDataSP.parcel) {
      this.matter.matterPropertyWithCondo.parcel = teranetImportDataSP.parcel;
    }
    if (teranetImportDataSP.section) {
      this.matter.matterPropertyWithCondo.section = teranetImportDataSP.section;
    }
    if (teranetImportDataSP.easementRightOfWay) {
      this.matter.matterPropertyWithCondo.easementRightOfWay = teranetImportDataSP.easementRightOfWay;
    }
    if (teranetImportDataSP.lastTransferNumber) {
      this.matter.matterPropertyWithCondo.lastTransferNumber = teranetImportDataSP.lastTransferNumber;
    }
    if (selectedParcelRegister.propertyDescription) {
      this.matter.matterPropertyWithCondo.propertyTaxesSummary = selectedParcelRegister.propertyDescription;
    }
  }

  static hasObjectAddress(provinceCode: string): boolean {
    return provinceCode == PROVINCE_CODES.ALBERTA
      || provinceCode == PROVINCE_CODES.SASKATCHEWAN
      || provinceCode == PROVINCE_CODES.MANITOBA
      || provinceCode == PROVINCE_CODES.BRITISH_COLOMBIA;
  }
}
