import {Injectable} from '@angular/core';
import {requisitionGroupsApi} from './requisition-groups-api';
import {Logger} from '@nsalaun/ng-logger';
import {HttpClient} from '../../core/httpClient.service';
import {configApi} from './../../main/config-api';
import {requisitionKey} from './requisition-key';
import {Observable} from 'rxjs';

@Injectable()
export class RequisitionGroupsService {
  constructor(private http: HttpClient, private logger: Logger) {
  }

  getRequisitionGroups(accountId: string): Observable<any> {
    let url = requisitionGroupsApi.configurationOptionValues.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return res[ requisitionKey.selectedConstrainedConfigurationTypeID ];
    });
  }

  saveRequisitionValues(values, accountId: string) {
    //console.log(values);
    let url = configApi.customerAccountConfiguration.replace('{accountId}', accountId);
    return this.http.put(url, JSON.stringify(values))
    .map((res) => {
      return res;
    });
  }

}
