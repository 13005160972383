<div class="form-horizontal">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Additional Information Required</h1>
                <h2>Please provide some additional information to complete your documents.</h2>
            </div>
            <button type="button" class="btn-close pull-right" (click)="onClickCancel()" >&times;</button>
        </div>
    </div>
    <div class="row modal-body">
        <div class="form-group" *ngIf="documentProductionData">
            <dp-stop-codes-common #dpStopCodesCommon
                             [documentProductionData]="documentProductionData"
                           > </dp-stop-codes-common>
            <div class="row margin-bottom-20">
                <div class="col-lg-12 text-center vertical-button">
                        <button type="button" class="dp-btn" (click)="onClickOk()">
                            <span>OK</span>
                        </button>
                        <button type="button" class="dp-btn dp-btn-cancel" (click)="onClickCancel()">
                            <span>Cancel</span>
                        </button>

                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</div>
