import {AddressDropdowns} from '../../shared-main/address-Form/drop-downs';

export const dropDowns = {

  staffProfileAddressCopyOptions: [
    AddressDropdowns.sameAsAccount,
    AddressDropdowns.manuallyEntered
  ],

  conditionType: [
    {label: 'Unconditional', value: 'UNCONDITIONAL'},
    {label: 'Taxes not fully paid', value: 'TAX_NOT_FULLY_PAID'},
    {label: 'Condo', value: 'CONDO'},
    {label: 'Non condo', value: 'NON_CONDO'},
    {label: 'New home from builder', value: 'NEW_HOME_FROM_BUILDER'},
    {label: 'Resale home', value: 'RESALE_HOME'}
  ]

};
