<div tabindex="0">
    <div class="col-lg-12 padding-bottom-40">
        <div>
            <form #ConsiderationLttForm="ngForm" id="ConsiderationLttForm" class="form-horizontal" autocomplete="off">
                <div tabindex="4">
                    <ng-container *ngIf="!isProjectSale">
                    <div class="form-group">
                        <label class="control-label col-lg-2 padding-top-2">Prepare LTT Affidavit to be executed by</label>
                        <div class="col-lg-10">
                            <select class="form-control"
                                    id="prepareLTT"
                                    (change)="enableSave()"
                                    [(ngModel)]="considerationLtt.lttAffidavitExecutedBy"
                                    name="prepareLTT">
                                <option *ngFor="let prepareLTTObj of prepareLTT" [value]="prepareLTTObj.value">
                                    {{prepareLTTObj.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-lg-2">LTT rate based on</label>
                        <div class="col-lg-10">
                            <select class="form-control"
                                    id="prepareLTTRate"
                                    (change)="onChangeLttRateBasedOn($event.target.value)"
                                    [(ngModel)]="considerationLtt.lttRateBasedOnType"
                                    dp-error-validator
                                    fieldKey="considerationLtt.lttRateBasedOn"
                                    name="prepareLTTRate">
                                <option *ngFor="let prepareLTTRateObj of prepareLTTRate" [value]="prepareLTTRateObj.value">
                                    {{prepareLTTRateObj.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="considerationLtt.lttRateBasedOnType === 'APPORTION'">
                        <label class="control-label col-lg-2 padding-top-2">Value of Single Family Residence</label>
                        <div class="col-lg-3">
                            <input type="text"
                                   [(ngModel)]="considerationLtt.valueOfSingleFamilyResidence"
                                   (keyup)="enableSave();calculateTaxRebate();"
                                   name="singleResidenceValue"
                                   dp-default-currency
                                   dp-currency
                                   class="form-control text-right"/>
                            <div *ngIf="checkSingleFamilyResidenceValue()">
                                <div class="error-wrapper" style="position:relative;"><i class="fa fa-exclamation-triangle"
                                                                                         aria-hidden="true"></i>
                                    <p class="error">The field "Value of Single Family Residence" must be completed for APPORTION transactions</p>
                                    <a class="btn-close"><b class="fa fa-times" aria-hidden="true"></b></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-group" *ngIf="considerationLtt.lttRateBasedOnType === 'APPORTION'">
                        <label class="control-label col-lg-2 padding-top-2">Remainder of land used for</label>


                        <div class="col-lg-10">
                            <input type="text"
                                   trimmed
                                   [(ngModel)]="considerationLtt.remainderOfLandUsedFor"
                                   [fieldKey]="'considerationLtt.remainderOfLandUsedFor'" statusBarHelp
                                   (keydown.f9)="handleF9RemainderOfLandUsedFor()"
                                   (keyup)="enableSave()"
                                   name="landPurpose"
                                   class="form-control"
                                   maxlength="38"/>
                        </div>

                    </div>
                    </ng-container>
                    <div class="form-group" *ngIf="isProjectSale">
                        <label class="control-label col-lg-2">Completion of Tax Info</label>
                        <div class="col-lg-10">
                            <select class="form-control"
                                    id="completionOfTaxInfo"
                                    (change)="onCompletionOfTaxInfoChange($event.target.value);"
                                    [(ngModel)]="considerationLtt.completionOfTaxInfoType"
                                    name="tableBelowOption">
                                <option *ngFor="let obj of completionOfTaxInfoOptions" [value]="obj.value">
                                    {{obj.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="!isProjectSale">
                        <label class="control-label col-lg-2">In the table below</label>
                        <div class="col-lg-10">
                            <select class="form-control"
                                    id="tableBelowOption"
                                    (change)="onTableOptionChange($event.target.value);calculateTaxRebate();"
                                    [(ngModel)]="considerationLtt.inTableBelowType"
                                    name="tableBelowOption">
                                <option *ngFor="let preparetableCalObj of prepareTableCalculation" [value]="preparetableCalObj.value">
                                    {{preparetableCalObj.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                        <div class="form-group main-level">
                            <span class="title min-height-30">The total consideration for this transaction is allocated as follows:</span>
                        </div>
                    </div>

                    <ng-container *ngIf="considerationLtt.inTableBelowType != 'DEFAULT'">

                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">a</div>
                                <label class="control-label text-left">Monies paid or to be paid in cash</label>
                            </div>

                            <div class="col-lg-3" *ngIf="!hideInput()">
                                <input type="text"
                                       [(ngModel)]="considerationLtt.moniesPaidOrToBePaidIn"
                                       [attr.readonly]="(CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE || isPurchasePriceTypeSoaForOptionA_EQ_F() || considerationLtt.moniesPaidOrToBePaidIn == null)? 'readonly' : null"
                                       name="monies"
                                       [fieldKey]="'considerationLtt.moniesPaidOrToBePaidIn'"
                                       statusBarHelp
                                       (keydown.f9)="handleF9Monies()"
                                       (ngModelChange)="updateRelatedAmounts('moniesPaidOrToBePaidIn')"
                                       dp-consideration-F8
                                       dp-currency
                                       dp-default-currency
                                       dp-error-validator
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"
                                       (blur)="calculateSubjectValueAndTotalConsideration();updatePurchasePrice()"/>
                                <button type="button"
                                        id="unitLevelPlanBtn" tabIndex="-1" class="button-glyph"
                                        *ngIf="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG && considerationLtt.moniesPaidOrToBePaidIn != null  && !inModalMode"
                                        (click)="openSalePriceModal()"
                                >
                                    <span class="glyphicon glyphicon-edit" *ngIf="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG"></span>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="col-lg-4 padding-left-0">
                                    <div class="alphabet-circle">b</div>
                                    <label class="control-label text-left  ">Mortgages</label>
                                </div>
                                <div class="col-lg-4 padding-left-0">
                                    <div class="alphabet-circle">i</div>
                                    <label class="control-label text-left  ">Assumed</label>
                                </div>
                            </div>
                            <div class=" col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.mortgagesAssumed"
                                       [attr.readonly]="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || considerationLtt.mortgagesAssumed == null  ? 'readonly' : null"
                                       (blur)="calculateSubjectValueAndTotalConsideration()"
                                       name="mortgages"
                                       (ngModelChange)="updateRelatedAmounts('mortgagesAssumed')"
                                       dp-consideration-F8
                                       dp-currency
                                       dp-default-currency
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="col-lg-4">
                                </div>
                                <div class="col-lg-8 padding-left-0">
                                    <div class="alphabet-circle">ii</div>
                                    <label class="control-label text-left  ">Given back to vendor</label>
                                </div>
                            </div>
                            <div class=" col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.mortgagesGivenBackToVendor"
                                       (blur)="calculateSubjectValueAndTotalConsideration()"
                                       [attr.readonly]="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || considerationLtt.mortgagesGivenBackToVendor == null ? 'readonly' : null"
                                       dp-default-currency
                                       name="vendor"
                                       (ngModelChange)="updateRelatedAmounts('mortgagesGivenBackToVendor')"
                                       dp-consideration-F8
                                       dp-currency
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">c</div>
                                <label class="control-label text-left  ">Property transferred in exchange</label>
                            </div>
                            <div class=" col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.propertyTransferredInExchange"
                                       (blur)="calculateSubjectValueAndTotalConsideration()"
                                       [attr.readonly]="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || considerationLtt.propertyTransferredInExchange == null   ? 'readonly'  : null"
                                       dp-default-currency
                                       name="propertyTransferred"
                                       (ngModelChange)="updateRelatedAmounts('propertyTransferredInExchange')"
                                       dp-consideration-F8
                                       dp-currency
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>


                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">d</div>
                                <label class="control-label text-left  ">Other consideration subject to tax</label>
                            </div>

                            <div class=" col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.otherConsiderationSubjectToTax"
                                       (blur)="calculateSubjectValueAndTotalConsideration()"
                                       [attr.readonly]="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || considerationLtt.otherConsiderationSubjectToTax == null  ? 'readonly' : null"
                                       dp-default-currency
                                       (ngModelChange)="updateRelatedAmounts('otherConsiderationSubjectToTax')"
                                       dp-consideration-F8
                                       name="otherTaxConsideration"
                                       dp-currency
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>


                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">e</div>
                                <label class="control-label text-left  ">Fair market value of the lands</label>
                            </div>
                            <div class=" col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.fairMarketValueOfLands"
                                       (blur)="calculateSubjectValueAndTotalConsideration()"
                                       [attr.readonly]="AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || considerationLtt.fairMarketValueOfLands == null ? 'readonly' : null"
                                       dp-default-currency
                                       (ngModelChange)="updateRelatedAmounts('fairMarketValueOfLands')"
                                       dp-consideration-F8
                                       name="marketValue"
                                       dp-currency
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">f</div>
                                <label class="control-label text-left  ">Value subject to LTT (total of (a) to (e))</label>
                            </div>
                            <ng-container *ngIf="!hideInput()">
                            <div class=" col-lg-3">
                                <input type="text"
                                       *ngIf="considerationLtt.valueSubjectToLttAToE1 != null"
                                       [(ngModel)]="considerationLtt.valueSubjectToLttAToE1"
                                       name="subjectValueA"
                                       (keydown.f9)="handleF9SubjectValueA()"
                                       readonly="true"
                                       [dp-currency]="allowMaxLength"
                                       class="form-control text-right"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>
                                <input value="BLANK" class="form-control text-right"
                                       name="subjectValueA_blank"
                                       readonly="true"
                                       *ngIf="considerationLtt.valueSubjectToLttAToE1 === null"
                                       [ngClass]="{'text-padding-update': AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG || CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || LINE_A_EQUAL_NET_SALE_PRICE}"/>
                                <button type="button"
                                        (click)="openSubjectValueModal()"
                                        *ngIf="CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG && considerationLtt.valueSubjectToLttAToE1 != null && !inModalMode"
                                        class="button-glyph" tabIndex="-1">
                                    <span class="glyphicon glyphicon-edit"></span>
                                </button>
                            </div>
                            <div class=" col-lg-3">
                                <input type="text"
                                       *ngIf="considerationLtt.valueSubjectToLttAToE2 != null"
                                       [(ngModel)]="considerationLtt.valueSubjectToLttAToE2"
                                       name="subjectValueE"
                                       readonly="true"
                                       [dp-currency]="allowMaxLength"
                                       class="form-control text-right"/>
                                <input value="BLANK" class="form-control text-right"
                                       name="subjectValueE_blank"
                                       readonly="true"
                                       *ngIf="considerationLtt.valueSubjectToLttAToE2 === null"/>
                            </div>
                            </ng-container>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">g</div>
                                <label class="control-label text-left  ">Value of all chattels</label>
                            </div>
                            <div class="col-lg-3">
                            </div>
                            <div class="col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.valueOfAllChattles"
                                       (blur)="calculateSubjectValueAndTotalConsideration();updateSoaTaxRetailFee();"
                                       [attr.readonly]="considerationLtt.valueOfAllChattles == null ? 'readonly' : null"
                                       dp-default-currency
                                       (ngModelChange)="updateRelatedAmounts('valueOfAllChattles')"
                                       dp-consideration-F8
                                       name="chattelValue"
                                       dp-currency

                                       class="form-control text-right"/>


                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">h</div>
                                <label class="control-label text-left  ">Other consid'n not included in (f) or (g) above</label>
                            </div>
                            <div class="col-lg-3">
                            </div>
                            <div class="col-lg-3" *ngIf="!hideInput()">
                                <input type="text"

                                       [(ngModel)]="considerationLtt.otherConsiderationNotInFOrG"
                                       name="otherConsideration"
                                       (blur)="calculateSubjectValueAndTotalConsideration()"
                                       [attr.readonly]="considerationLtt.otherConsiderationNotInFOrG == null ? 'readonly' : null"
                                       dp-default-currency
                                       (ngModelChange)="updateRelatedAmounts('otherConsiderationNotInFOrG')"
                                       dp-consideration-F8
                                       dp-currency
                                       class="form-control text-right"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4">
                                <div class="alphabet-circle">i</div>
                                <label class="control-label text-left  ">Total Consideration</label>
                            </div>
                            <div class="col-lg-3">
                            </div>
                            <div class="col-lg-3" *ngIf="!hideInput()">
                                <input type="text"
                                       *ngIf="considerationLtt.totalConsideration != null"
                                       [(ngModel)]="considerationLtt.totalConsideration"
                                       name="totalConsideration"
                                       readonly="true"
                                       [dp-currency]="allowMaxLength"
                                       class="form-control text-right"/>
                                <input value="BLANK" class="form-control text-right"
                                       name="totalConsideration_blank"
                                       readonly="true"
                                       *ngIf="considerationLtt.totalConsideration === null"/>
                            </div>
                        </div>
                        <div class="form-group padding-top-20" *ngIf="isPropertyTeranetJurisdictrionToronto">
                            <label class="control-label col-lg-2 padding-top-2">Apply City of Toronto Land Transfer Tax</label>
                            <div class="col-lg-1" *ngIf="!hideInput()">
                                <select class="form-control"
                                        id="torontoLandTransfer"
                                        (change)="onChangeApplyTorontoLtt();"
                                        [(ngModel)]="considerationLtt.applyCityOfTorontoLandTransferTax"
                                        name="torontoLandTransfer">
                                    <option *ngFor="let yesNoOptionObj of yesNoOptions" [value]="yesNoOptionObj.value">
                                        {{yesNoOptionObj.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <ng-container *ngIf="!inModalMode">
                            <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                                <div class="form-group main-level">
                                    <span class="title min-height-30">LTT Refund Entitlement</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-1">Ontario</label>
                                <div class="col-lg-1">
                                    <select class="form-control"
                                            id="ontarioLTT"
                                            (change)="onChangeLttOntario()"
                                            [(ngModel)]="considerationLtt.lttRefundEntitlementOntario"
                                            name="OntarioLTT">
                                        <option *ngFor="let yNOptionsObj of yNOptions" [value]="yNOptionsObj.value">
                                            {{yNOptionsObj.label}}
                                        </option>
                                    </select>
                                </div>
                                <label class="control-label col-lg-1"
                                       *ngIf="isPropertyTeranetJurisdictrionToronto && considerationLtt.applyCityOfTorontoLandTransferTax == 'YES' ">Toronto</label>
                                <div class="col-lg-2" *ngIf="isPropertyTeranetJurisdictrionToronto && considerationLtt.applyCityOfTorontoLandTransferTax == 'YES' ">
                                    <input class="form-control"
                                           id="torontoLTT"
                                           [(ngModel)]="considerationLtt.lttRefundEntitlementToronto"
                                           readonly="true"
                                           name="torontoLTT">
                                </div>
                                <label class="control-label col-lg-2" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes' ">Percentage Claimed</label>
                                <div class="col-lg-1" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes' ">
                                    <input type="text"
                                           [(ngModel)]="considerationLtt.percentageClaimed"
                                           (blur)="addPercentageSymbol($event);calculateRefund();"
                                           (keyup)="updatePercentageValue($event)"
                                           name="percentageClaimed"
                                           dp-percentage
                                           max-decimals = 3
                                           class="form-control padding-left-10  text-right"/>
                                </div>
                                <label class="control-label col-lg-1 text-left" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes' ">%</label>
                            </div>
                        </ng-container>
                        <div class="form-group" *ngIf="!hideInput()">
                            <div class="col-lg-5">
                                <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                                    <div class="form-group main-level">
                                        <span class="title min-height-30">Ontario</span>
                                    </div>
                                    <div class="clear"></div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label col-lg-4 ">Land Transfer Tax is</label>
                                    <div class="col-lg-8">
                                        <input type="text"

                                               name="landTransferTaxOnt"
                                               [(ngModel)]="considerationLtt.ontarioLandTransferTax"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes' ">
                                    <label class="control-label col-lg-4 ">Less LTT Refund</label>
                                    <div class="col-lg-8">
                                        <input type="text"

                                               name="LttRefundOnt"
                                               [(ngModel)]="considerationLtt.ontarioLessLttRefund"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes' ">
                                    <label class="control-label col-lg-4 ">Net LTT Payable</label>
                                    <div class="col-lg-8">
                                        <input type="text"

                                               name="netLTTPayableOnt"
                                               [(ngModel)]="considerationLtt.ontarioNetLttPayable"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1">
                            </div>
                            <div class="col-lg-6"
                                 *ngIf="isPropertyTeranetJurisdictrionToronto &&  considerationLtt.applyCityOfTorontoLandTransferTax == 'YES'">
                                <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                                    <div class="form-group main-level">
                                        <span class="title min-height-30">Toronto</span>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label class="control-label col-lg-4 ">Land Transfer Tax is</label>
                                    <div class="col-lg-8">
                                        <input type="text"

                                               name="landTransferTaxTor"
                                               [ngModel]="torontoLandTransferTax"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes'">
                                    <label class="control-label col-lg-4">Less LTT Refund</label>
                                    <div class="col-lg-8">
                                        <input type="text"

                                               name="LttRefundTor"
                                               [(ngModel)]="considerationLtt.torontoLessLttRefund"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="considerationLtt.lttRefundEntitlementOntario == 'Yes'">
                                    <label class="control-label col-lg-4">Net LTT Payable</label>
                                    <div class="col-lg-8">
                                        <input type="text"

                                               name="netLTTPayableTor"
                                               [ngModel]="considerationLtt.torontoNetLttPayable"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="showTorontoLTTMsg">
                                    <div class="col-lg-12 defaultCursor alert alert-info">
                                        <div class="col-lg-1 text-center icon"><i class="fa fa-info-circle font-14"  aria-hidden="false"></i></div>
                                        <div class="col-lg-11 msg font-13">{{torontoLandTransferTaxMessage}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!hideInput()">
                            <div class="col-lg-12 padding-top-20"
                                 *ngIf="isPropertyTeranetJurisdictrionToronto && considerationLtt.applyCityOfTorontoLandTransferTax == 'YES'">
                                <div class="form-group 0">
                                    <div class="col-lg-4 text-right">
                                        <label class="control-label">Total Ontario and Toronto LTT payable is</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <input type="text"
                                               [(ngModel)]="considerationLtt.totalOntarioTorontoLttPayable"
                                               name="totalOntarioToronto"
                                               readonly="true"
                                               dp-currency
                                               class="form-control text-right"/>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="!inModalMode">
                        <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                            <div class="form-group main-level">
                                <span class="title min-height-30">Ontario Non-Resident Speculation Tax (NRST)</span>
                            </div>
                        </div>
                        <div class="form-group col-lg-12">
                            <label class="control-label col-lg-3">Apply the Ontario NRST ?</label>
                            <div class="col-lg-1">
                                <select class="form-control"
                                        id="applyNrst"
                                        (change)="enableSave();"
                                        [(ngModel)]="considerationLtt.applyNrst"
                                        (change)="updateSoaTrustLedgerNrst()"
                                        name="applyNrst">
                                    <option *ngFor="let nYOptionsObj of nYOptions" [value]="nYOptionsObj.value">
                                        {{nYOptionsObj.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="considerationLtt.isNrstApplied()">
                            <div class="form-group col-lg-12">
                                <label class="control-label col-lg-3">Ontario Non-Resident Speculation Tax Amount is</label>
                                <div class="col-lg-3">
                                    <input type="text"
                                           [(ngModel)]="considerationLtt.nrstAmount"
                                           name="nrstAmount"
                                           (keyup)="enableSave()"
                                           statusBarHelp
                                           (blur)="updateSoaTrustLedgerNrst()"
                                           fieldKey="matter.considerationLtt.nrstTaxAmount"
                                           dp-currency
                                           class="form-control text-right"/>
                                </div>
                            </div>
                            <div class="form-group col-lg-12">
                                <label class="control-label col-lg-3">NRST Remittance Receipt No</label>
                                <div class="col-lg-3">
                                    <input type="text"
                                           [(ngModel)]="considerationLtt.nrstReceiptNo"
                                           statusBarHelp
                                           (keyup)="enableSave()"
                                           fieldKey="matter.considerationLtt.nrstReceiptNo"
                                           name="nrstReceiptNo"
                                           class="form-control"
                                           maxlength="33" />
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <dp-user-defined-fields [topicName]="'CONSIDERATION_LTT'" (onChange)="enableSave()">
                    </dp-user-defined-fields>

                </div>
            </form>
        </div>
    </div>
</div>
