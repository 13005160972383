import {Contact, MatterParticipant} from '../../matters/shared';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ActionTakenTypes {
  public static readonly DO_NOTHING: ActionTakenType = 'DO_NOTHING';
  public static readonly KEEP: ActionTakenType = 'KEEP';
  public static readonly REPLACE: ActionTakenType = 'REPLACE';
}

export type ActionTakenType = 'DO_NOTHING' | 'KEEP' | 'REPLACE';

export class DuplicateContactData extends BaseEntity {

  existingProspectMatterParticipant: MatterParticipant;
  duplicateSourceContacts: Contact[];
  actionTaken: ActionTakenType;
  replacedSourceContactId: number;
  genericName: string;

  constructor(duplicateProspectData?: DuplicateContactData) {
    super(duplicateProspectData);
    if (duplicateProspectData) {

      if (duplicateProspectData.existingProspectMatterParticipant) {
        this.existingProspectMatterParticipant = new MatterParticipant(duplicateProspectData.existingProspectMatterParticipant);
      }

      this.duplicateSourceContacts = [];
      if (Array.isArray(duplicateProspectData.duplicateSourceContacts)) {
        for (let i: number = 0; i < duplicateProspectData.duplicateSourceContacts.length; i++) {
          this.duplicateSourceContacts[ i ] = new Contact(duplicateProspectData.duplicateSourceContacts[ i ]);
        }
      }
    }
  }

  get replacedSourceContact(): Contact {
    return this.duplicateSourceContacts && this.duplicateSourceContacts.find(item => item.id == this.replacedSourceContactId);
  }

}
