import {Injectable} from '@angular/core';
import {MatterOpeningComponent} from '../matters/matter-opening/matter-opening.component';
import {HttpClient, TabsService} from '../core';
import {LockScreenService} from '../core/lock-screen.service';
import {Matter} from '../matters/shared/matter';
import {Subject} from 'rxjs/Subject';
import {ProjectTab} from './shared/project-tab';
import {constValues} from '../matters/shared';
import {Project} from './shared/project';
import {StatementOfAdjustmentPayable} from '../matters/statement-adjustment/model/statement-adjustment-payable';
import {projectConsts, StatementAdjParameter} from './shared/project-consts';
import {DpBooleanValueTypes} from '../matters/shared/dp-boolean';
import {ProgressionStatus, StatementAdjustment} from '../matters/statement-adjustment/statement-adjustment';
import {CondominiumPlan} from '../matters/property-teranet/unit-level-plan/condominium-plan';
import {ProjectCondoPlan} from './project-condo/project-condo-plan';
import {CondominiumExpense} from '../matters/property-teranet/unit-level-plan/condominium-expense';
import Utils from '../shared-main/utils';
import {SoaTrustLedgerHelperService} from '../shared/soa-trustledger-helper.service';
import {UnitLevelPlanUtil} from '../matters/property-teranet/unit-level-plan/unit-level-plan-util';
import {StatementOfAdjustmentHeading} from '../matters/statement-adjustment/model/statement-adjustment-heading';
import {DirectionReFund} from '../matters/direction-re-funds/direction-re-funds';
import {StatementAdjustmentComponent} from '../matters/statement-adjustment/statement-adjustment.component';
import {BrokerCommissionComponent} from '../matters/broker-commission/broker-commission.component';
import {StatementAdjustmentConfig} from '../admin/statement-adjustment/statement-adjustment-config';
import {soAdjProjectFooters} from '../matters/statement-adjustment/model/so-adj-drop-downs';
import {ERegStatus, ERegType, InterimTaxMultiplier} from '../shared-main/constants';
import {DepositAdjustmentCalculationUtil} from '../matters/statement-adjustment/deposit-adjustment-calculation-util';
import {StatementAdjustmentUtil} from '../matters/statement-adjustment/statement-adjustment-util';
import {MatterCleanUpUtil} from '../matters/shared/matter-utils/matter-clean-up-util';
import {ERegistrationForm} from '../matters/forms/eregistration/eregistrationform';
import {EregistrationUtil} from '../matters/forms/eregistration/eregistration-util';
import {ERegistrationJson} from '../matters/forms/eregistration/eregistrationjson';
import {SoAdjHeading} from '../matters/statement-adjustment/model/soa-adj-custom-format';

@Injectable()
export class ProjectDataPropagationService {

  constructor(public http: HttpClient,
              public lockScreenService: LockScreenService,
              public tabsService: TabsService,
              public soaTrustLedgerHelperService: SoaTrustLedgerHelperService,
              public matterOpeningComponent: MatterOpeningComponent, public brokerCommissionComponent: BrokerCommissionComponent, public statementAdjustmentComponent: StatementAdjustmentComponent) {
  }

  async callAsynchronously(callback: Function, targetMatter?: Matter, parameter?: any): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    callback(targetMatter, parameter);
    let serviceInstance = this;
    let scheduler = setInterval(() => {

      if (serviceInstance.http.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 50);
    return returnSubject.toPromise();
  }

  get activeProjectTab(): ProjectTab {
    return this.tabsService && this.tabsService.activeTab as ProjectTab;
  }

  async propagateToMatters(matters: Matter[]): Promise<void> {
    if (matters && matters.length) {
      for (let matter of matters) {
        await this.propagateToMatter(matter);
        if (this.tabsService.isMatterTabOpen(matter.id)) {
          let matterTab = this.tabsService.getMatterTab(matter.id);
          matterTab.matter = matter;
          matterTab.matter.dirty = false;
        }
      }
    }
    //Clear the list after processing it
    this.activeProjectTab.dataPropagationCommands = [];
    this.initStatementAdjustmentComponent(this.templateMatter);
  }

  async propagateToMatter(matter: Matter): Promise<void> {
    let dataPropagationCommands = this.activeProjectTab.dataPropagationCommands;
    if (dataPropagationCommands && dataPropagationCommands.length) {
      for (let dataPropagationCommand of dataPropagationCommands) {
        //In case we only have the type, we look for the command
        if (!dataPropagationCommand.command) {
          dataPropagationCommand.command = this.getPropagationCommand(dataPropagationCommand.type);
        }
        if (dataPropagationCommand.command) {
          await this.callAsynchronously(dataPropagationCommand.command, matter, dataPropagationCommand.parameter);
        }
      }
    }
  }

  getPropagationCommand(type: string): Function {
    switch (type) {
      case projectConsts.dataPropagationCmdType.SoaPayableTo :
        return this.updateSoaPayableTo;
      case projectConsts.dataPropagationCmdType.SoaHeading :
        return this.propagateSoaHeading;
      case projectConsts.dataPropagationCmdType.statementAdjustmentFormatSettings :
        return this.propagateSelectedStatementAdjustmentFormatSettings;
      case projectConsts.dataPropagationCmdType.statementAdjustmentFooters :
        return this.propagateSoaFooters;
      case projectConsts.dataPropagationCmdType.statementAdjustmentFooterDelete :
        return this.propagateStatementAdjustmentFooterDelete;
      case projectConsts.dataPropagationCmdType.statementAdjustmentFooterReset :
        return this.propagateStatementAdjustmentFooterReset;
      case projectConsts.dataPropagationCmdType.statementAdjustmentDelete :
        return this.propagateStatementAdjustmentDelete;
      case projectConsts.dataPropagationCmdType.realtyTaxCalculation :
        return this.propagateRealtyTaxCalculation;
      case projectConsts.dataPropagationCmdType.statementAdjustmentFieldCode :
        return this.propagateStatementAdjustmentFieldCode;
      case projectConsts.dataPropagationCmdType.statementOfAdjustmentReset :
        return this.resetStatementOfAdjustment;
      default:
        return null;
    }
  }

  updateDocumentProfile = async (targetMatter: Matter): Promise<void> => {
    let sourceProject = this.activeProjectTab.project;
    let documentProfileId = sourceProject.defaultDocumentProfileId;
    let isMethodCompleted = await this.initMatterOpeningComponent(targetMatter);
    if (isMethodCompleted) {
      this.matterOpeningComponent.onSelectedDocumentProfileChange(documentProfileId);
      console.log(targetMatter.documentProfileId, documentProfileId);
    }
  };

  updateCondoLocation = (targetMatter: Matter): void => {
    let sourceProject = this.activeProjectTab.project;
    let projectCondoLocation: string = sourceProject.projectCondo && sourceProject.projectCondo.location;

    if (targetMatter && targetMatter.matterPropertyWithCondo) {
      let oldJurisdictionInitial: string = Utils.getJurisdictionInitial(targetMatter.matterPropertyWithCondo.condominiumJurisdiction);
      let newJurisdictionInitial: string = Utils.getJurisdictionInitial(projectCondoLocation);

      targetMatter.matterPropertyWithCondo.condominiumJurisdiction = projectCondoLocation;

      //since location value(jurisdiction value)'s initial is used as start characters of the planNumber of the condoExpenses, so need to update it also
      let matterCondoExpenses: CondominiumExpense[] = targetMatter.matterPropertyWithCondo.condominiumExpenses;
      if (Array.isArray(matterCondoExpenses) && matterCondoExpenses.length > 0) {
        if (!oldJurisdictionInitial) {
          matterCondoExpenses.forEach(condoExpense => {
            if (condoExpense.planNumber) {
              condoExpense.planNumber = newJurisdictionInitial + condoExpense.planNumber;
            }
          });
        } else {
          matterCondoExpenses.forEach(condoExpense => {
            if (condoExpense.planNumber && condoExpense.planNumber.startsWith(oldJurisdictionInitial)) {
              condoExpense.planNumber = newJurisdictionInitial + condoExpense.planNumber.substring(oldJurisdictionInitial.length);
            }
          });
        }
        //after condoExpenses get updated, we need to refresh the unit/level/plan field in propety page
        targetMatter.refreshUnitLevelPlanValue();//since location is part of the unit/level/plan value, so refresh it

      }
    }
  };

  updateCondominiumPlan = (targetMatter: Matter): void => {
    let sourceProject = this.activeProjectTab.project;
    if (targetMatter && targetMatter.matterPropertyWithCondo) {
      let projectCondoPlans: ProjectCondoPlan[] = sourceProject.projectCondo.projectCondoPlans;
      let matterCondoPlans: CondominiumPlan[] = targetMatter.matterPropertyWithCondo.condominiumPlans;
      let matterCondoExpenses: CondominiumExpense[] = targetMatter.matterPropertyWithCondo.condominiumExpenses;

      if (Array.isArray(projectCondoPlans) && projectCondoPlans.length > 0) {
        if (projectCondoPlans.length == 1 && matterCondoPlans.length == 1) {
          //if project only has one condoPlan, then all condoExpense in the matter will use derived planNumber from this condoPlan
          //also sync with matter, so matter also has only one condoPlan.
          matterCondoPlans[ 0 ].condominiumPlanType = projectCondoPlans[ 0 ].condominiumPlanType;
          matterCondoPlans[ 0 ].condominiumPlanNumber = projectCondoPlans[ 0 ].condominiumPlanNumber;
          targetMatter.matterPropertyWithCondo.condominiumPlans = [ matterCondoPlans[ 0 ] ];
          if (Array.isArray(matterCondoExpenses) && matterCondoExpenses.length > 0) {
            matterCondoExpenses.forEach(expense => {
              expense.planNumber = Utils.buildCondoPlanNumber(matterCondoPlans[ 0 ], targetMatter.isMatterProvinceAB, targetMatter.matterPropertyWithCondo.condominiumJurisdiction);
              expense.condominiumPlan = matterCondoPlans[ 0 ];
            });
          }
        } else {
          //project has more than one condoPlan
          if (matterCondoPlans.length == 1) {
            //if matter currently only has one plan, and project has multiple plan,
            //All condoExpense in the matter's planNumber need to be set to undefined
            for (let i = 0; i < projectCondoPlans.length; i++) {
              if (i == 0) {
                matterCondoPlans[ 0 ].condominiumPlanType = projectCondoPlans[ i ].condominiumPlanType;
                matterCondoPlans[ 0 ].condominiumPlanNumber = projectCondoPlans[ i ].condominiumPlanNumber;
              } else {
                let newMatterCondoPlan = new CondominiumPlan();
                newMatterCondoPlan.id = undefined;
                newMatterCondoPlan.condominiumPlanType = projectCondoPlans[ i ].condominiumPlanType;
                newMatterCondoPlan.condominiumPlanNumber = projectCondoPlans[ i ].condominiumPlanNumber;
                matterCondoPlans.push(newMatterCondoPlan);
              }
            }

            if (Array.isArray(matterCondoExpenses) && matterCondoExpenses.length > 0) {
              matterCondoExpenses.forEach(expense => {
                expense.planNumber = undefined;
                expense.condominiumPlan = undefined;
              });
            }
          } else {
            // project has multiple condoPlans and matter also has multiple condoPlans
            // overwrite the matter's condoPlans, only keep the matter's condoPlan's id
            // As Per Heather double check with TC,
            // for each existing condoExpense, if the planNumber used exists in the updated condoPlan list, then keep it
            // if the used planNumber no more exists in the updated condoPlan list, set it to undefined.
            let newMatterCondoPlans: CondominiumPlan[] = [];
            let orgMatterCondoPlansLength: number = matterCondoPlans.length;
            for (let i = 0; i < projectCondoPlans.length; i++) {
              if (i < orgMatterCondoPlansLength) {
                newMatterCondoPlans.push(matterCondoPlans[ i ]);
                matterCondoPlans[ i ].condominiumPlanType = projectCondoPlans[ i ].condominiumPlanType;
                matterCondoPlans[ i ].condominiumPlanNumber = projectCondoPlans[ i ].condominiumPlanNumber;
              } else {
                let newMatterCondoPlan = new CondominiumPlan();
                newMatterCondoPlan.id = undefined;
                newMatterCondoPlan.condominiumPlanType = projectCondoPlans[ i ].condominiumPlanType;
                newMatterCondoPlan.condominiumPlanNumber = projectCondoPlans[ i ].condominiumPlanNumber;
                newMatterCondoPlans.push(newMatterCondoPlan);
              }
            }
            //overwrite matter's condoPlans with this newCreated condPlans
            targetMatter.matterPropertyWithCondo.condominiumPlans = newMatterCondoPlans;

            let validPlanNumbers: string[] = targetMatter.matterPropertyWithCondo.condominiumPlans
            .map(condoPlan => Utils.buildCondoPlanNumber(condoPlan, targetMatter.isMatterProvinceAB, targetMatter.matterPropertyWithCondo.condominiumJurisdiction));

            if (Array.isArray(matterCondoExpenses) && matterCondoExpenses.length > 0) {
              matterCondoExpenses.forEach(expense => {
                if (validPlanNumbers.indexOf(expense.planNumber) == -1) {
                  expense.planNumber = undefined; //if expense's existing planNumber not matched with any of the existing condoPlan, then blank it out, otherwise keep it, disregard the order of the condoPlan
                  expense.condominiumPlan = undefined;
                } else {
                  //in AB, in order to generate the proper unitLevelPlan value (under property), it is required to populate the condoPlan object in the condoExpense
                  expense.condominiumPlan = newMatterCondoPlans.find(condoPlan => Utils.buildCondoPlanNumber(condoPlan, targetMatter.isMatterProvinceAB, targetMatter.matterPropertyWithCondo.condominiumJurisdiction) == expense.planNumber);
                }
              });
            }
          }
        }
      }
      //unitLevelPlan field's value derived from CondoPlan+CondoExpense, so need to refresh after the change
      targetMatter.matterPropertyWithCondo.unitLevelPlan = UnitLevelPlanUtil.generateUnitLevelPlan(targetMatter.matterPropertyWithCondo, targetMatter.provinceCode);
    }
  };

  updateExceptionTypeOrDescription = (targetMatter: Matter): void => {
    let sourceProject = this.activeProjectTab.project;
    if (sourceProject.projectCondo && targetMatter.matterPropertyWithCondo) {
      targetMatter.matterPropertyWithCondo.exceptionType = sourceProject.projectCondo.exceptionType;
      if (sourceProject.projectCondo.exceptionType == 'YES') {
        targetMatter.matterPropertyWithCondo.exceptionTypeDescription = sourceProject.projectCondo.exceptionTypeDescription;
      } else {
        targetMatter.matterPropertyWithCondo.exceptionTypeDescription = '';
      }
    }
  };

  updatePotlFlag = (targetMatter: Matter): void => {
    let sourceProject = this.activeProjectTab.project;
    let potlFlag: boolean = sourceProject.potlFlag;
    if (targetMatter && targetMatter.matterPropertyWithCondo) {
      targetMatter.matterPropertyWithCondo.isParcelOfTiedLand = potlFlag ? 'YES' : 'NO';
    }
  };

  updatePotlPlanApplied = (targetMatter: Matter): void => {
    let sourceProject = this.activeProjectTab.project;
    if (sourceProject.potlFlag && sourceProject.potlPlanApplied) {
      //if potlFlag is true
      targetMatter.matterPropertyWithCondo.nameOfCondominiumPlan = sourceProject.condominiumPlanName;
    }

  };

  updateCommonExpensesForEntireCondo = (targetMatter: Matter): void => {
    let sourceProject = this.activeProjectTab.project;
    if (sourceProject.potlFlag) {
      //only applicable if potlFlag is true
      if (targetMatter && targetMatter.matterPropertyWithCondo && targetMatter.matterPropertyWithCondo.autoCalculateCommonExpense) {
        targetMatter.matterPropertyWithCondo.commonExpenses = Number(sourceProject.commonExpensesForEntireCondo) * Number(targetMatter.matterPropertyWithCondo.percentageInterest) / 100;
        targetMatter.updatePOTLAdjustmentsWithCommonExpense();
      }
    }
  };

  get sourceProject(): Project {
    return this.activeProjectTab && this.activeProjectTab.project;
  }

  get templateMatter(): Matter {
    return this.activeProjectTab && this.activeProjectTab.matter;
  }

  //this command need to be added when any of the following three fields value changed
  //1. General Info - dropDown field: Use Same Teraview Docket Id...
  //2. General Info - text Field: Teraview Docket Id
  //3. Doc & Registration - dropDown field: Registration Method
  updateRegistrationMethodOrUseSameTeraviewDocketId = async (targetMatter: Matter): Promise<void> => {
    if (this.sourceProject && this.sourceProject.isProjectProvinceON && this.sourceProject.docRegistration) {
      let isMethodCompleted = await this.initMatterOpeningComponent(targetMatter);
      if (isMethodCompleted) {
        let projectRegMethodValue: string = this.sourceProject.docRegistration.isRegistrationMethodElectronic() ? constValues.registrationMethodsType.electronic : constValues.registrationMethodsType.manual;
        if (projectRegMethodValue != targetMatter.registrationMethodCode) {
          console.log('> apply the registration method value %s to the target matter %s', projectRegMethodValue, targetMatter.matterRecordNumber);
          targetMatter.registrationMethodCode = projectRegMethodValue;
          this.matterOpeningComponent.registrationMethodChanges();
        }
        //the following changes are based on the joint status of dropDown registrationMethod and dropDown 'Use Same Teraview Docket Id' value
        if (targetMatter.registrationMethodCode == constValues.registrationMethodsType.electronic) {
          if (this.sourceProject.useSameDocketIdentifier) {
            if (targetMatter.teranetDocket) {
              //targetMatter's teraviewDocketId will be updated in real time and the field will be readonly
              console.log('> apply the project\'s teraviewDocketIdentifier value %s to target matter %s', this.sourceProject.teraviewDocketIdentifier.toUpperCase(), targetMatter.matterRecordNumber);
              targetMatter.teranetDocket.teraviewDocketIdentifier = this.sourceProject.teraviewDocketIdentifier.toUpperCase();
            }
          }
          //Notes: if useSameDocketIdentifier is false, and insertFileNo is True, then teraviewDocketIdentifier get value from matter fileNumber
          //and the change is implemented when matter is saved/cleanedup, so nothing need to do here
        }
      }
    }
  };

  updateInterestRate = (targetMatter: Matter): void => {
    if (this.sourceProject && this.sourceProject.isProjectProvinceAB) {
      targetMatter.interestRateSummary = this.sourceProject.interestRate;
    }
  };

  async initMatterOpeningComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.matterOpeningComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', matter && matter.matterRecordNumber);
        this.matterOpeningComponent.setLocalInstanceMatter(matter);
        this.matterOpeningComponent.ngOnInit();
      }
    });
  }

  async initStatementAdjustmentComponent(matter: Matter): Promise<void> {
    if (this.statementAdjustmentComponent) {
      this.statementAdjustmentComponent.setLocalInstanceMatter(matter);
      await this.statementAdjustmentComponent.initSoAdjComponent();
      await this.statementAdjustmentComponent.setUpStatementOfAdjustment();
    }

  }

  updateSoaPayableTo = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter.statementOfAdjustmentPayable && targetMatter.statementOfAdjustmentPayable.adoptProjectSoaPayableTo) {
      targetMatter.statementOfAdjustmentPayable = new StatementOfAdjustmentPayable(this.activeProjectTab.matter.statementOfAdjustmentPayable);
      targetMatter.statementOfAdjustmentPayable.id = null;
      targetMatter.statementOfAdjustmentPayable.adoptProjectSoaPayableTo = true;
    }
  };

  propagateIgnoreCreditsInDeedConsideration = async (targetMatter: Matter): Promise<void> => {
    if (this.sourceProject && this.sourceProject.docRegistration) {
      await this.initStatementAdjustmentComponent(targetMatter);
      targetMatter.recalculateConsiderationLTTBasedOnSalePrice(this.statementAdjustmentComponent.soaConsiderationTaxes, this.statementAdjustmentComponent.soaUtils.ontarioTaxRateSlab, this.statementAdjustmentComponent.soaUtils.torontoTaxRateSlab);
    }
  };

  propagateDirectionReFunds = async (targetMatter: Matter): Promise<void> => {
    if (this.sourceProject && this.sourceProject.docRegistration) {
      if (this.sourceProject.docRegistration.docsForDirection == DpBooleanValueTypes.YES) {
        if ((!targetMatter._interimDirectionReFunds || !targetMatter._interimDirectionReFunds.length)) {
          if (targetMatter.isSelectedAdjustmentStatusModeFinal) { //If Selected mode is Final
            //Create a new interim array
            targetMatter._interimDirectionReFunds = [];
            targetMatter._directionReFundsStatusMode = ProgressionStatus.INTERIM;
            targetMatter.updateBalanceOfFundsPayableTo();
            targetMatter._directionReFundsStatusMode = ProgressionStatus.FINAL;
          } else { //If Selected mode is Interim
            //Copy the final direction refund into the interim because we had one view
            targetMatter._interimDirectionReFunds = [];
            targetMatter.directionReFunds.forEach((dr) => {
              dr.progressionStatus = ProgressionStatus.INTERIM;
              targetMatter._interimDirectionReFunds.push(new DirectionReFund(dr));
            });
            //Create a new final array
            targetMatter._finalDirectionReFunds = [];
            targetMatter._directionReFundsStatusMode = ProgressionStatus.FINAL;
            targetMatter.updateBalanceOfFundsPayableTo();
            targetMatter._directionReFundsStatusMode = ProgressionStatus.INTERIM;
          }
        }

      } else {
        if (targetMatter.isSelectedAdjustmentStatusModeFinal) {
          targetMatter._interimDirectionReFunds = [];
        } else {
          targetMatter._finalDirectionReFunds = [];
          targetMatter._interimDirectionReFunds.forEach((dr) => {
            dr.progressionStatus = ProgressionStatus.FINAL;
          });

        }

      }
      MatterCleanUpUtil.cleanUpDirectionReFunds(targetMatter);
    }
  };

  propagateSoaTrustLedgerCollection = async (targetMatter: Matter): Promise<void> => {
    //Build the soaTrustLedgerCollection structure
    if (targetMatter.soaTrustLedgerCollection) {
      if (this.sourceProject && this.sourceProject.docRegistration) {

        //Create Interims if it they don't exists And keep the Finals
        let someInterimSoasExists = targetMatter.matterSoas.some(soaMatter => soaMatter.progressionStatus == ProgressionStatus.INTERIM);
        let someInterimTlExists = targetMatter.matterTrustLedgers.some(tl => tl.progressionStatus == ProgressionStatus.INTERIM);
        targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;

        if (this.sourceProject.docRegistration.docsForSoa == DpBooleanValueTypes.YES) {
          //If there is no Interim Soas, we create default ones, else we keep the existing ones
          if (!someInterimSoasExists) {
            targetMatter.soaTrustLedgerCollection.createNewSoaStructure();
          }
        } else {
          //Remove the Interims And keep the Finals
          targetMatter.soaTrustLedgerCollection.clearInterimFeesAndDisbursements();

          targetMatter.receivedOnAccountInterim = 0;
        }

        if (this.sourceProject.docRegistration.docsForTl == DpBooleanValueTypes.YES) {
          //If there is no Interim Trust Ledger, we create default ones, else we keep the existing ones
          if (!someInterimTlExists) {
            targetMatter.soaTrustLedgerCollection.createNewTrustLedgerStructure();
          }
        } else {
          //Remove the Interims And keep the Finals
          targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim = [];
        }

        if (!someInterimSoasExists || !someInterimTlExists) {
          this.soaTrustLedgerHelperService.updateNewSoaTrustLedgerCollection(targetMatter, targetMatter.soaTrustLedgerCollection.statementConfig, targetMatter.soaTrustLedgerCollection.documentProfile, targetMatter.soaTrustLedgerCollection);
        }
        targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
      }
      //Build the new matterSoas from the soaTrustLedgerCollection
      MatterCleanUpUtil.cleanUpStatementOfAccount(targetMatter);
      targetMatter.soaTrustLedgerCollection.matter = targetMatter;
      targetMatter.reCalculateTrustLedgerPaidToRealEstateBroker();
    }
  };

  propagateSoaHeading = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter.statementOfAdjustmentHeading && this.activeProjectTab.matter.statementOfAdjustmentHeading && targetMatter.statementOfAdjustmentHeading.adoptProjectHeading) {
      targetMatter.statementOfAdjustmentHeading = new StatementOfAdjustmentHeading(this.activeProjectTab.matter.statementOfAdjustmentHeading);
      targetMatter.statementOfAdjustmentHeading.id = null;
      targetMatter.statementOfAdjustmentHeading.adoptProjectHeading = true;
    }
  };

  propagateSelectedStatementAdjustmentFormatSettings = async (targetMatter: Matter, parameter: any): Promise<void> => {
    let statementAdjustmentId: number;
    if (parameter && (parameter as StatementAdjParameter).adjustmentId) {
      statementAdjustmentId = parameter.adjustmentId;
    }
    if (statementAdjustmentId) {
      await this.initStatementAdjustmentComponent(targetMatter);
      let targetStatementAdjustment: StatementAdjustment = targetMatter && targetMatter.allStatementAdjustments
        ? targetMatter.allStatementAdjustments.find(item => item.sourceProjectAdjustmentId == statementAdjustmentId)
        : null;
      let templateStatementAdjustment: StatementAdjustment = this.templateMatter && this.templateMatter.allStatementAdjustments
        ? this.templateMatter.allStatementAdjustments.find(item => item.id == statementAdjustmentId)
        : null;
      if (targetStatementAdjustment && templateStatementAdjustment && templateStatementAdjustment.soAdjHeading) {
        targetStatementAdjustment.soAdjHeading = targetStatementAdjustment.soAdjHeading ? targetStatementAdjustment.soAdjHeading : new SoAdjHeading();
        targetStatementAdjustment.soAdjHeading.headingLine1 = templateStatementAdjustment.soAdjHeading.headingLine1;
        targetStatementAdjustment.soAdjHeading.headingLine2 = templateStatementAdjustment.soAdjHeading.headingLine2;
        targetStatementAdjustment.soAdjHeading.headingLine3 = templateStatementAdjustment.soAdjHeading.headingLine3;
        targetStatementAdjustment.soAdjHeading.customFormatSettingType = templateStatementAdjustment.soAdjHeading.customFormatSettingType;
        targetStatementAdjustment.soAdjHeading.printInUpperCase = templateStatementAdjustment.soAdjHeading.printInUpperCase;
        targetStatementAdjustment.soAdjHeading.printInBold = templateStatementAdjustment.soAdjHeading.printInBold;
        targetStatementAdjustment.soAdjHeading.underline = templateStatementAdjustment.soAdjHeading.underline;
        targetStatementAdjustment.soAdjHeading.lastLineCreditVendorOrCreditPurchaser = templateStatementAdjustment.soAdjHeading.lastLineCreditVendorOrCreditPurchaser;
        targetStatementAdjustment.soAdjHeading.followAdjustmentWithBlankLine = templateStatementAdjustment.soAdjHeading.followAdjustmentWithBlankLine;

      }
    }

  };

  updateFooter(templateFooter: StatementAdjustmentConfig, targetFooter: StatementAdjustmentConfig) {
    targetFooter.description = templateFooter.description;
    //targetFooter.footerProgressionStatusAvailability = templateFooter.footerProgressionStatusAvailability;
  }

  addNewFooter(templateFooter: StatementAdjustmentConfig, targetMatter: Matter, availability ?: string, insertIndex ?: number) {
    let localFooter: StatementAdjustmentConfig = new StatementAdjustmentConfig(templateFooter);
    localFooter.id = undefined;
    localFooter.projectFooterId = templateFooter.id;
    if (availability) {
      localFooter.footerProgressionStatusAvailability = availability;
    } else {
      localFooter.footerProgressionStatusAvailability = soAdjProjectFooters.applyToNone.value;
    }
    localFooter.condition = 'UNCONDITIONAL';
    if (insertIndex != undefined) {
      targetMatter.matterStatementOfAdjustmentFooters.splice(insertIndex, 0, localFooter);
    } else {
      targetMatter.matterStatementOfAdjustmentFooters.push(localFooter);
    }
  }

  propagateSoaFooters = async (targetMatter: Matter): Promise<void> => {
    let targetFooter: StatementAdjustmentConfig;
    if (this.activeProjectTab.matter.matterStatementOfAdjustmentFooters && this.activeProjectTab.matter.matterStatementOfAdjustmentFooters.length > 0) {
      for (let i: number = 0; i < this.activeProjectTab.matter.matterStatementOfAdjustmentFooters.length; i++) {
        targetFooter = targetMatter.matterStatementOfAdjustmentFooters.find(item => item.projectFooterId == this.activeProjectTab.matter.matterStatementOfAdjustmentFooters[ i ].id);
        if (targetFooter) {
          this.updateFooter(this.activeProjectTab.matter.matterStatementOfAdjustmentFooters[ i ], targetFooter);
        } else {
          this.addNewFooter(this.activeProjectTab.matter.matterStatementOfAdjustmentFooters[ i ], targetMatter);
        }
      }
    }
  };

  propagateStatementAdjustmentFooterDelete = async (targetMatter: Matter, parameter: any): Promise<void> => {
    let projectFooterId: number;
    if (parameter && (parameter as StatementAdjParameter).adjustmentId) {
      projectFooterId = parameter.adjustmentId;
    }

    if (projectFooterId) {
      let statementAdjustmentConfig: StatementAdjustmentConfig;
      statementAdjustmentConfig = targetMatter.matterStatementOfAdjustmentFooters.find(item => item.projectFooterId == projectFooterId);
      if (statementAdjustmentConfig) {
        let index: number;
        index = targetMatter.matterStatementOfAdjustmentFooters.indexOf(statementAdjustmentConfig);
        if (index != -1) {
          targetMatter.matterStatementOfAdjustmentFooters.splice(index, 1);
        }
      }
    }
  };

  deleteAllProjectLevelFooters(targetMatter: Matter, projectLevelFooters: StatementAdjustmentConfig[]): void {
    let footer: StatementAdjustmentConfig;
    for (let i: number = 0; i < projectLevelFooters.length; i++) {
      footer = targetMatter.matterStatementOfAdjustmentFooters.find(item => item.projectFooterId == projectLevelFooters[ i ].projectFooterId);
      if (footer) {
        targetMatter.matterStatementOfAdjustmentFooters.splice(targetMatter.matterStatementOfAdjustmentFooters.indexOf(footer), 1);
      }
    }

  }

  propagateStatementAdjustmentFooterReset = async (targetMatter: Matter): Promise<void> => {
    /*targetMatter.matterStatementOfAdjustmentFooters.splice(0);
    if (targetMatter.matterStatementOfAdjustmentFooters && targetMatter.matterStatementOfAdjustmentFooters.length>0){
        projectLevelFooters = targetMatter.matterStatementOfAdjustmentFooters.filter(Item=> Item.projectFooterId && Item.projectFooterId>0);
        if (projectLevelFooters && projectLevelFooters.length>0){
            this.deleteAllProjectLevelFooters(targetMatter, projectLevelFooters);
        }
    }
    if (this.activeProjectTab.matter.matterStatementOfAdjustmentFooters && this.activeProjectTab.matter.matterStatementOfAdjustmentFooters.length>0){
        for (let i: number = 0; i<this.activeProjectTab.matter.matterStatementOfAdjustmentFooters.length; i++) {
            if (this.activeProjectTab.matter.matterStatementOfAdjustmentFooters[i].applyByDefault) {
                this.addNewFooter(this.activeProjectTab.matter.matterStatementOfAdjustmentFooters[i], targetMatter, soAdjProjectFooters.applyToBoth.value, i);
            }
        }
    }*/
  };

  propagateStatementAdjustmentDelete = async (targetMatter: Matter, parameter: any): Promise<void> => {
    let statementAdjustmentId: number;
    if (parameter && (parameter as StatementAdjParameter).adjustmentId) {
      statementAdjustmentId = parameter.adjustmentId;
    }
    if (statementAdjustmentId) {
      await this.initStatementAdjustmentComponent(targetMatter);
      let adjustments = targetMatter.finalStatementAdjustments ? targetMatter.finalStatementAdjustments.slice(0) : [];
      if (targetMatter.interimStatementAdjustments) {
        adjustments.push(...targetMatter.interimStatementAdjustments);
      }
      let statementAdjustment = adjustments.find(item => item.sourceProjectAdjustmentId == statementAdjustmentId);
      if (statementAdjustment) {
        targetMatter.adjustmentStatusMode = statementAdjustment.isAdjustmentStatusFinal() ? ProgressionStatus.FINAL : ProgressionStatus.INTERIM;
        if (statementAdjustment.isVTBMortgage()) {
          statementAdjustment.sourceProjectAdjustmentId = null;
          if (statementAdjustment.linkId) {
            let otherSheetAdjustment = statementAdjustment.adjustmentStatus == ProgressionStatus.FINAL ? targetMatter._interimAdjustments : targetMatter._finalAdjustments;
            let linkedAdj = otherSheetAdjustment.find(adj => adj.linkId == statementAdjustment.linkId);
            linkedAdj.sourceProjectAdjustmentId = null;
          }
        } else {
          await this.statementAdjustmentComponent.soaUtils.removeAdjustmentAndUpdateMatter(statementAdjustment, true);
        }
      }
    }

  };

  propagateIgnoreCreditInTarionAdjustment = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter && this.sourceProject) {
      await this.initStatementAdjustmentComponent(targetMatter);
      StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.statementOfAdjustments, this.statementAdjustmentComponent.statementAdjustmentDisplayUtil, this.sourceProject.projectAdjustmentConfig);
      // update additional consideration added by Tarion Warranty
      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.statementOfAdjustments);
      StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.uniqueStatementAdjustments);
    }
  };

  updateNonInitEregMattersForProject = async (matter: Matter): Promise<void> => {
    if (EregistrationUtil.isERegTransferApplicable(matter)) {
      let eRegTransfer: ERegistrationForm;
      let eRegTransferFound = matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_TRANSFER);
      if (!eRegTransferFound) {
        eRegTransfer = new ERegistrationForm();
        eRegTransfer.eregistrationType = ERegType.EREG_TRANSFER;
        eRegTransfer.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
        eRegTransfer.eregistrationData = new ERegistrationJson();
        matter.eRegistrationForms.push(eRegTransfer);
      }
    }
    if (matter.newOrEmpMortgages.length > 0) {
      matter.newOrEmpMortgages.forEach((mortgage, index) => {
        if (EregistrationUtil.isERegChargeApplicable(matter, mortgage)) {
          let eRegCharge: ERegistrationForm;
          let eRegChargeFound = matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_MORTGAGE && eRegTransfer.mortgageId === mortgage.id);
          if (!eRegChargeFound) {
            eRegCharge = new ERegistrationForm();
            eRegCharge.eregistrationType = ERegType.EREG_MORTGAGE;
            eRegCharge.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
            eRegCharge.eregistrationData = new ERegistrationJson();
            matter.eRegistrationForms.push(eRegCharge);
          }
        }
      });
    }
    if (EregistrationUtil.isERegDischargeApplicable(matter)) {
      matter.existingMortgages.forEach((mortgage, index) => {
        if (mortgage.isMortgageDispositionDischarged() || mortgage.isMortgageDispositionBridgeFinancing()) {
          let eRegDischarge: ERegistrationForm;
          let eRegDischargeFound = matter.eRegistrationForms.find(eRegTransfer => eRegTransfer.eregistrationType === ERegType.EREG_DISCHARGE && eRegTransfer.mortgageId === mortgage.id);
          if (!eRegDischargeFound) {
            eRegDischarge = new ERegistrationForm();
            eRegDischarge.eregistrationType = ERegType.EREG_DISCHARGE;
            eRegDischarge.eregistrationStatus = ERegStatus.EREG_INCOMPLETE;
            eRegDischarge.eregistrationData = new ERegistrationJson();
            matter.eRegistrationForms.push(eRegDischarge);
          }
        }
      });
    }
  };

  updateNonInitMatter = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter) {
      await this.initStatementAdjustmentComponent(targetMatter);
      targetMatter.updateMatterPropertyDepositAmount();
      let currentAdjustmentStatusMode: string = targetMatter.adjustmentStatusMode;
      targetMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
      for (let item of targetMatter.interimStatementAdjustments) {
        await this.statementAdjustmentComponent.soaFulfillmentService.applyAdjustmentUpdates(item, targetMatter, targetMatter);
      }
      await this.statementAdjustmentComponent.updateSalePriceAdjForNewMatterRecord(targetMatter, targetMatter);
      this.statementAdjustmentComponent.updateDisplayItemsAndDateUpdatesForAdjustment(targetMatter);
      // Final Changes
      targetMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
      for (let item of targetMatter.finalStatementAdjustments) {
        await this.statementAdjustmentComponent.soaFulfillmentService.applyAdjustmentUpdates(item, targetMatter, targetMatter);
      }

      await this.statementAdjustmentComponent.updateSalePriceAdjForNewMatterRecord(targetMatter, targetMatter);
      targetMatter.recalculateForm4Charges();
      this.statementAdjustmentComponent.updateDisplayItemsAndDateUpdatesForAdjustment(targetMatter);
      if (currentAdjustmentStatusMode != targetMatter.adjustmentStatusMode) {
        targetMatter.updateStatusMode(currentAdjustmentStatusMode);
      }
      if (targetMatter.matterPropertyWithCondo) {
        targetMatter.updateUnitLevelPlan(targetMatter.matterPropertyWithCondo);
      }
      if (targetMatter.soaTrustLedgerCollection && targetMatter.soaTrustLedgerCollection.matterTrustLedgers &&
        targetMatter.soaTrustLedgerCollection.matterTrustLedgers.some(mt => mt.itemKey == 'PAID_TO_CLIENT' || mt.itemKey == 'TRUST_LEDGER_LEGAL_FEES')) {
        targetMatter.soaTrustLedgerCollection.matterTrustLedgers.forEach(item => {
          if (item.itemKey == 'PAID_TO_CLIENT') {
            item.itemKey = 'PAID_TO_YOU_FEE';
          } else if (item.itemKey == 'TRUST_LEDGER_LEGAL_FEES') {
            item.itemKey = 'LEGAL_FEE';
          }
        });
      }
      if (targetMatter.soaTrustLedgerCollection && targetMatter.feeCalculatedOnInclusivePriceFlag) {
        targetMatter.soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
      }

      targetMatter.fullyInitialized = true;
    }
  };

  propagateRealtyTaxCalculation = async (targetMatter: Matter, parameter: any): Promise<void> => {
    if (targetMatter) {
      targetMatter.updateTaxAdjustmentsAfterSalePriceAdjustmentUpdate(InterimTaxMultiplier);
      targetMatter.updateInterimOccupancyFeeAdjOnChange();
    }
  };

  propagateStatementAdjustmentFieldCode = async (targetMatter: Matter): Promise<void> => {
    let templateAdjustments = [ ...this.templateMatter.finalStatementAdjustments, ...this.templateMatter.interimStatementAdjustments ];
    let targetAdjustments = [ ...targetMatter.finalStatementAdjustments, ...targetMatter.interimStatementAdjustments ];
    templateAdjustments.forEach((adjustment) => {
      let matterStatementOfAdjustment = targetAdjustments.find(adj => adj.sourceProjectAdjustmentId == adjustment.id);
      if (matterStatementOfAdjustment) {
        matterStatementOfAdjustment.fieldCode = adjustment.fieldCode;
      }
    });
  };

  condoOccupancyFeesCalculatedBasedOnChanged = (targetMatter: Matter): void => {
    if (targetMatter) {

      if (this.sourceProject && this.sourceProject.projectCondo && this.sourceProject.projectCondo.isOccupancyFeesCalculatedBasedOnPhantomMortgage) {
        targetMatter.extraDepositConfig.deferredPurchaseMoniesFlag = false;
        targetMatter.extraDepositConfig.deferredPurchaseMoniesAmount = 0;
      }

      let dacuUtil: DepositAdjustmentCalculationUtil = new DepositAdjustmentCalculationUtil(targetMatter);
      // Update Mortgage value on Monies
      targetMatter.extraDepositConfig.vtbMortgageMonies = dacuUtil.initVTBMortgageMonies(targetMatter);
      // Recalculate DeferredPurchaseMonies
      dacuUtil.recalculateDeferredPurchaseMonies();

      targetMatter.recalculateAllInterimOccupancyFee(this.sourceProject);
    }
  };

  initInterimStatementAdjustments = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter && this.sourceProject) {
      if (this.sourceProject.isStatementOfAdjustmentInterim()) {
        //targetMatter.createSalePriceInterimAdjustmentAndSyncWithFinal();
        //targetMatter.createDepositInterimAdjustmentAndSyncWithFinal();
        await this.statementAdjustmentComponent.updateProjectLevelStatementOfAdjustments(targetMatter, this.templateMatter);
      }
    }
  };

  synchProjectInterimSalePriceDeposit = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter && this.sourceProject && this.templateMatter) {

      let templateMatterSalePrice = this.templateMatter.interimStatementAdjustments.find(adj => adj.isSalePrice());
      let targetMatterSalePrice = targetMatter.interimStatementAdjustments.find(adj => adj.isSalePrice());
      if (templateMatterSalePrice && !targetMatterSalePrice) {
        // this could happen when Condo=NO and either POTL or Occupancy date were set to Yes which triggered initialization of interim adjustments
        targetMatter.createSalePriceInterimAdjustmentAndSyncWithFinal();
      }

      let templateMatterDeposit = this.templateMatter.interimStatementAdjustments.find(adj => adj.isDepositAdjustment());
      let targetMatterDeposit = targetMatter.interimStatementAdjustments.find(adj => adj.isDepositAdjustment());
      if (templateMatterDeposit && !targetMatterDeposit) {
        // this could happen when Condo=NO and either POTL or Occupancy date were set to Yes which triggered initialization of interim adjustments
        targetMatter.createDepositInterimAdjustmentAndSyncWithFinal();
      }

      targetMatter.interimStatementAdjustments.forEach(adj => {
        if (adj.isSalePrice() && adj.sourceProjectAdjustmentId !== templateMatterSalePrice.id) {
          adj.sourceProjectAdjustmentId = templateMatterSalePrice.id;
        }
        if (adj.isDepositAdjustment() && adj.sourceProjectAdjustmentId !== templateMatterDeposit.id) {
          adj.sourceProjectAdjustmentId = templateMatterDeposit.id;
        }
      });
    }
  };

  clearProjectInterimData = async (targetMatter: Matter): Promise<void> => {
    if (this.sourceProject && !this.sourceProject.isStatementOfAdjustmentInterim()) {
      this.templateMatter.interimStatementAdjustments.length = 0;
      //ToDo this might not be needed to confirm with Dwayne
      this.templateMatter.finalStatementAdjustments.forEach(adj => adj.linkId = undefined);
      this.templateMatter.selectedProgressionStatus = ProgressionStatus.FINAL;
    }
  };

  clearInterimData = async (targetMatter: Matter): Promise<void> => {
    if (targetMatter && this.sourceProject) {
      if (!this.sourceProject.isStatementOfAdjustmentInterim()) {
        targetMatter.interimStatementAdjustments.length = 0;
        //ToDo this might not be needed =>  to confirm with Dwayne
        targetMatter.finalStatementAdjustments.forEach(adj => adj.linkId = null);
        targetMatter.selectedProgressionStatus = ProgressionStatus.FINAL;
      }

      if (this.sourceProject.docRegistration.docsForDirection !== DpBooleanValueTypes.YES) {
        this.propagateDirectionReFunds(targetMatter);
      }

      if (targetMatter.soaTrustLedgerCollection && this.sourceProject.docRegistration) {
        if (this.sourceProject.docRegistration.docsForSoa !== DpBooleanValueTypes.YES) {
          targetMatter.soaTrustLedgerCollection.clearInterimFeesAndDisbursements();
          targetMatter.receivedOnAccountInterim = 0;
        }
        if (this.sourceProject.docRegistration.docsForTl != DpBooleanValueTypes.YES) {
          targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim = [];
        }
        MatterCleanUpUtil.cleanUpStatementOfAccount(targetMatter);
      }
    }
  };

  async updateLinkedStatementAdjustments(matter: Matter): Promise<void> {
    if (matter.allStatementAdjustments.some(item => !!item.linkId)) {
      await this.initStatementAdjustmentComponent(matter);
    }
  }

  resetStatementOfAdjustment = async (targetMatter: Matter, parameter: any): Promise<void> => {

    let selectedRemoveAdjustmentAction: string;
    if (parameter && (parameter as StatementAdjParameter).selectedRemoveAdjustmentAction) {
      selectedRemoveAdjustmentAction = parameter.selectedRemoveAdjustmentAction;
    }
    if (selectedRemoveAdjustmentAction == 'ALL_ADJUSTMENTS') {

      targetMatter.adjustments = [];
      let filteredAdjustmentsFinal = targetMatter._finalAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment());
      targetMatter._finalAdjustments = [];
      targetMatter._finalAdjustments.push(...filteredAdjustmentsFinal);

      let filteredAdjustmentsInterim = targetMatter._interimAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment());
      targetMatter._interimAdjustments = [];
      targetMatter._interimAdjustments.push(...filteredAdjustmentsInterim);

      await this.initStatementAdjustmentComponent(targetMatter);
      await this.statementAdjustmentComponent.soaUtils.resetSalePriceAdj();
      await this.statementAdjustmentComponent.soaUtils.resetDepositAdj();
    } else if (selectedRemoveAdjustmentAction == 'ALL_ADJUSTMENTS_EXCEPT_SALE_PRICE_AND_DEPOSIT') {

      targetMatter.adjustments = [];
      let filteredAdjustmentsFinal = targetMatter._finalAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment());
      targetMatter._finalAdjustments = [];
      targetMatter._finalAdjustments.push(...filteredAdjustmentsFinal);

      let filteredAdjustmentsInterim = targetMatter._interimAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment());
      targetMatter._interimAdjustments = [];
      targetMatter._interimAdjustments.push(...filteredAdjustmentsInterim);

      await this.initStatementAdjustmentComponent(targetMatter);

      if (targetMatter.considerationLtt && targetMatter.considerationLtt.salePriceAdjustment) {
        targetMatter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst = 0;
        targetMatter.considerationLtt.salePriceAdjustment.creditsToPurchaserInclHst = 0;
      }

      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.statementOfAdjustments, true);
      StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.uniqueStatementAdjustments, true);
      await this.statementAdjustmentComponent.soaUtils.updateSalePriceConsiderationLTT();

      await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();

      targetMatter.updateStatusMode(targetMatter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.statementOfAdjustments, true);
      StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.uniqueStatementAdjustments, true);
      await this.statementAdjustmentComponent.soaUtils.updateSalePriceConsiderationLTT();

      await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();
      targetMatter.updateStatusMode(targetMatter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

    } else if (selectedRemoveAdjustmentAction == 'ALL_ADJUSTMENTS_EXCEPT_DEPOSIT') {

      targetMatter.adjustments = [];
      let filteredAdjustmentsFinal = targetMatter._finalAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment());
      targetMatter._finalAdjustments = [];
      targetMatter._finalAdjustments.push(...filteredAdjustmentsFinal);

      let filteredAdjustmentsInterim = targetMatter._interimAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment());
      targetMatter._interimAdjustments = [];
      targetMatter._interimAdjustments.push(...filteredAdjustmentsInterim);

      await this.initStatementAdjustmentComponent(targetMatter);
      await this.statementAdjustmentComponent.soaUtils.resetSalePriceAdj();
      await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();

      targetMatter.updateStatusMode(targetMatter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

      await this.statementAdjustmentComponent.soaUtils.resetSalePriceAdj();
      await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();

      targetMatter.updateStatusMode(targetMatter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

    }

  };

}
