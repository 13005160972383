<!-- Offeror Dialog -->
<!-- Modal - Update From Contact Record Screen -->
<div class="container-fluid custom-modal-container" (keydown.esc)="cancelDialog();$event.stopPropagation()">
    <div class="row modal-header">
        <div class="col-lg-6" *ngIf="context.isEdit">
            <h1>Edit Contact</h1>
        </div>
        <ng-container *ngIf="!context.isEdit">
            <div class="col-lg-6" *ngIf="!context.isOtherParty && context.contactType != 'LAW_FIRM' && !context.isVTBMortgagee">
                <h1>Add New {{selectedContactType ? selectedContactType.label : 'Contact' }}</h1>
            </div>
            <div class="col-lg-6" *ngIf="context.contactType == 'LAW_FIRM'">
                <h1 *ngIf="isLawFirmVisible()">Add New Firm</h1>
                <h1 *ngIf="isSingleSolicitorVisible()">Add New {{solicitorLabel}}</h1>
                <h1 *ngIf="isLawFirmSolicitorVisible()">Add New {{solicitorLabel}}</h1>
            </div>
            <div class="col-lg-6" *ngIf="context.isOtherParty">
                <h1>{{otherPartyLabel}}</h1>
            </div>
            <div class="col-lg-10" *ngIf="context.isVTBMortgagee">
                <h1>Add New Mortgagee</h1>
            </div>
        </ng-container>
        <button type="button" class="btn-close pull-right" (click)="cancelDialog()">&times;</button>
    </div>

    <div class="row modal-body"
         [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">
        <div class="col-lg-12 fields-group margin-bottom-0" *ngIf="(selectedContactType && selectedContactType.contactKey == 'SURVEYOR')">
            <div class="form-group main-level">
                <span class="title min-height-30">Global Information</span>
            </div>
        </div>
        <div *ngIf="isProfileTab()">
            <div class="col-lg-12" *ngIf="!context.reducedContactDetails">
                <ul class="dppm-custom-tab">
                    <li [ngClass]="{active: selectedTab === 'Profile'}" >
                        <a (click)="selectTab('Profile')">Profile</a>
                    </li>
                    <li *ngIf="isCapacityVisible()" [ngClass]="{active: selectedTab === 'Capacity-Share'}">
                        <a (click)="selectTab('Capacity-Share')" dp-province-field-label="matter.purchaser.capacity">Capacity</a>
                    </li>
                    <ng-container *dpShowByProvince="'matter.common.contact.modal.flaTab'">
                        <li *ngIf="isFamilyLawActVisible()" [ngClass]="{active: selectedTab === 'Family-Law-Act'}">
                            <a (click)="selectTab('Family-Law-Act')" [label-province-code]="matter.provinceCode"
                               dp-province-field-label="participant.fla.tab.label">Family Law Act</a>
                        </li>
                    </ng-container>
                    <li *ngIf="isIdDetailsVisible()" [ngClass]="{active: selectedTab === 'ID-Details'}">
                        <a (click)="selectTab('ID-Details')">{{getIdDetailsLabel()}}</a>
                    </li>
                    <li *ngIf="isStatusCertificateVisible()" [ngClass]="{active: selectedTab === 'Status-Certificate'}">
                        <a (click)="selectTab('Status-Certificate')" [label-province-code]="contact.provinceCode"
                           dp-province-field-label="contact.condo.statusCertificateInformationTitle">Status Certificate Information</a>
                    </li>
                    <li *ngIf="isNotesSectionVisible()" [ngClass]="{active: selectedTab === 'Note'}">
                        <a (click)="selectTab('Notes')">{{contact.getNotesTitle()}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-12 margin-top-20" *ngIf="isContactComponentInitialized()">
                <div [ngSwitch]="selectedTab">
                    <ng-container *ngSwitchCase="'Profile'">
                    <dp-new-manage-contact
                                           #manageContactComponent
                                           [shouldCreateSourceContact]="shouldCreateSourceContact"
                                           [contact]="contact"
                                           [participant]="context.matterParticipant"
                                           [isRelatedPurchaser]="context?.isRelatedPurchaser"
                                           [referencePurchaserLastName]="context?.referencePurchaser?.lastName"
                                           [matter]="context.matter"
                                           [contactKey]="context.contactType"
                                           [matterParticipantRole]="context.matterParticipantRole"
                                           [provinceReadOnly]="provinceReadOnly"
                                           [canAddNewManagementCompanyFromSearch]="context.canAddNewManagementCompanyFromSearch"
                                           [closeOtherInfoShutter]="context.contactType==='CLIENT'"
                                           [isPurchaserSideAgent] = "context.isPurchaserSideAgent"
                                           [allClientDetailsAvailable]="isAllClientDetailsAvailable()"
                                           [isVisibleWithoutSourceContact] = "context.isVisibleWithoutSourceContact"
                                           [reducedContactDetails]="context.reducedContactDetails"
                                           [showRelationshipGenders]="context.showRelationshipGenders"
                                           [addSignerMatterParticipantToMatter]="false"
                                           [showMaleFemaleOnlyGenders]="context.showMaleFemaleOnlyGenders"
                                           [isOtherParty]="context.isOtherParty"
                                           [isVTBMortgagee]="context.isVTBMortgagee"
                                           [parentTab]="'CommonContactDialogComponent'"
                    >
                    </dp-new-manage-contact>
                        <div class="col-lg-12 margin-top-5" *ngIf="context.isSigningOfficer && this.context.matterParticipantRole == 'SIGNING_OFFICER'">
                            <label class="control-label col-lg-2 text-right margin-top-9 margin-left-65">Title or Office Held</label>
                            <div class="col-lg-4">
                                <input type="text"
                                       name="titleOfOfficeHeld1"
                                       [(ngModel)]="signingOfficeText"
                                       maxlength="100"
                                       class="form-control"/>
                            </div>
                        </div>
                    </ng-container>
                    <dp-capacityShare   *ngSwitchCase="'Capacity-Share'"
                                        [matterParticipant]="context.matterParticipant"
                                        [provinceCode]="context.matter?.provinceCode"
                                        [isPurchaserSharePercentage]="context.matter && context.matter.isMatterProvinceMBorSK"
                                        [purchaserId]="context.matterParticipant.matterParticipantId">
                    </dp-capacityShare>
                    <dp-familyLawAct    *ngSwitchCase="'Family-Law-Act'"
                                        [shouldCreateSourceContact]="shouldCreateSourceContact"
                                        [matter]="context.matter"
                                        [participant]="context.matterParticipant"
                                        [spouseNameOptions]="context.spouseNameOptions"
                                        [fieldPrefix]="'matter.matterParticipant.fla'"
                                        [fieldIdentifier]="context.matterParticipant.identifier"
                                        [clientType]="context.matterParticipantRole"
                                        [flaStatements]="context.flaStatements">
                    </dp-familyLawAct>
                    <dp-contact-id-details *ngSwitchCase="'ID-Details'" [matterParticipantRole]="context.matterParticipantRole" [contact]="contact" [contactKey]="context.contactType" [provinceCode]="getMatterProvince()"></dp-contact-id-details>
                    <dp-contact-status-cert-fields *ngSwitchCase="'Status-Certificate'" [contact]="contact"></dp-contact-status-cert-fields>
                    <!--The following is the contact notes section (with the add button and such)-->
                    <dp-contact-notes *ngSwitchCase="'Notes'" [contact]="contact" [matter]="context.matter"></dp-contact-notes>
                </div>
            </div>
        </div>
        <div *ngIf="(selectedContactType && selectedContactType.contactKey == 'SURVEYOR')">
            <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">My Contacts</span>
                </div>
            </div>
            <dp-new-contact-private-info
                #newContactPrivateInfoComponent
                [contact]="contact"
                [contactKey]="context.contactType">
            </dp-new-contact-private-info>
        </div>
        <div *ngIf="(selectedContactType && selectedContactType.contactKey == 'PRIVATE_CONTACT')">
            <dp-contact-private-basic-info
                [provinceCode]="context.matter?.provinceCode"
                [contact]="context.parentContact"
                [selectedPerson]="contact">
            </dp-contact-private-basic-info>
        </div>

        <!--Without any tab-->
        <div class="col-lg-12" *ngIf="(selectedContactType && selectedContactType.contactKey == 'LAW_FIRM')">
            <div *ngIf="isLawFirmVisible()">
                <dp-new-manage-contact
                    #manageContactComponent
                    [contact]="contact"
                    [matter]="context.matter"
                    [contactKey]="context.contactType"
                    [matterParticipantRole]="context.matterParticipantRole"
                    [closeOtherInfoShutter]="context.contactType==='CLIENT'">
                </dp-new-manage-contact>
            </div>
            <div *ngIf="isSingleSolicitorVisible()">
                <div class="form-horizontal overFlow offset-lg-1 col-lg-11">
                    <dp-contact-firm-single-solicitor
                        [fieldPrefix]="context.fieldPrefix"
                        [contact]="subContact"
                        [contactAddressType]="context.contactAddressType"
                        [solicitorAddressDd]="context.solicitorAddressDd">
                    </dp-contact-firm-single-solicitor>
                </div>
            </div>
            <div *ngIf="isLawFirmSolicitorVisible()">
                <div class="col-lg-12 fields-group margin-bottom-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30">Firm</span>
                    </div>
                    <dp-new-manage-contact
                        #manageContactComponent
                        [isSyncSolicitorDefaultValues]="true"
                        [contact]="contact"
                        [matter]="context.matter"
                        [isLawFirmOmniSearch]="true"
                        [contactKey]="context.contactType"
                        (lawFirmChange)="lawFirmChange($event)"
                        [matterParticipantRole]="context.matterParticipantRole"
                        [closeOtherInfoShutter]="context.contactType==='CLIENT'">
                    </dp-new-manage-contact>
                </div>
                <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30">New {{solicitorLabel}}</span>
                    </div>
                    <div class="form-horizontal overFlow offset-lg-1 col-lg-11" *ngIf="subContact != null">
                        <dp-contact-firm-single-solicitor
                            [fieldPrefix]="context.fieldPrefix"
                            [contact]="subContact"
                            [contactAddressType]="context.contactAddressType"
                            [solicitorAddressDd]="solicitorAddressDdOptions">
                        </dp-contact-firm-single-solicitor>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12" *ngIf="isCreateNewClientVisible()">
            <div class="form-group">
                <div class="col-lg-4  modal-create-new-client">
                    <div class="col-lg-1 padding-right-0">
                        <dp-checkbox fieldId="createdNewClient{{contact.identifier}}">
                            <input type="checkbox"
                                   id="createdNewClient{{contact.identifier}}"
                                   [dp-read-only]="isCreateSourceContactAutoEnabled()"
                                   [(ngModel)]="shouldCreateSourceContact"
                                   [checked]="shouldCreateSourceContact"/>
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-11 padding-left-4 text-left">
                        <label class="control-label-snapshot">{{createNewClientLabel}}</label>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="saveContact()"
                    [dp-read-only]="isMyOwnAccountLawFirm()"
                    class="dp-btn keyboard-save-action">
                <span>{{context.removeStatusCertificatePage? 'Create' : 'Save'}}</span>
            </button>
            <button type="button"
                    (click)="cancelDialog()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>
