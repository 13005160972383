import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {Matter} from '../shared/matter';
import {MatterType} from '../shared/index';
import {ErrorService} from '../../shared/error-handling/error-service';
import {LockScreenService} from '../../core/lock-screen.service';
import {MatterService} from '../matter.service';
import {TabsComponent} from '../../shared/tabbing/tabs.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {CopyMatterService} from '../copy-matter.service';
import {TabsService} from '../../core/tabs.service';
import {Contact} from '../shared/contact';
import {ContactQueryService} from '../../contact/contact-query.service';
import {MatterParticipant} from '../shared/matter-participant';

export class CopyMatterModalContext {
  public sourceMatter: Matter;
  public modalTitle: string;
  public targetMatterType: MatterType;
  public tabsComponent: TabsComponent;
  public copyMatterService: CopyMatterService;
  public tabsService: TabsService;
}

@Component({
  selector: 'copy-matter-modal',
  templateUrl: 'copy-matter.modal.component.html',
  styleUrls: ['./copy-matter.modal.styles.scss'],
  providers: [ErrorService]
})
export class CopyMatterModalComponent extends ModalComponent<CopyMatterModalContext> implements OnInit {
  mortgageData: boolean;
  fireInsuranceData: boolean;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<CopyMatterModalComponent>,
    public errorService: ErrorService,
    public lockScreenService: LockScreenService,
    public matterService: MatterService,
    public dialogService: DialogService,
    public contactQueryService: ContactQueryService,
    @Inject(MAT_DIALOG_DATA) context?: CopyMatterModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {}

  async createMatter(): Promise<void> {
    let entityLocked: boolean = await this.anyEntityLocked();

    if (!entityLocked) {
      try {
        this.lockScreenService.lockForUpdate = true;
        let matter = await this.matterService
          .createNewMatter(this.context.targetMatterType, this.context.sourceMatter.provinceCode)
          .toPromise();
        if (matter) {
          //need to init the matter, to get the soaTrustLedgerCollection ready
          matter.sourceMatterId = this.context.sourceMatter.id;
          await this.matterService.initMatter(matter).toPromise();
          matter.isCopyInProgress = true;
          await this.copyMatterDetails(this.context.sourceMatter, matter);
          matter.isCopyInProgress = false;
          matter.dirty = true;
          this.context.tabsService.activeTab.section = null;
          this.openMatter(matter);
          this.close();
        }
      } finally {
        this.lockScreenService.lockForUpdate = false;
      }
    }
  }

  async anyEntityLocked(): Promise<boolean> {
    if (this.context.sourceMatter.isCondoCorporationTabEnabled && this.context.sourceMatter.condoCorporation) {
      const sourceCondoCorporation: MatterParticipant = this.context.sourceMatter.condoCorporation;
      if (sourceCondoCorporation.contact && sourceCondoCorporation.contact.sourceContactId) {
        let condoContactLocked = await this.isContactLocked(
          'Condo Corporation',
          sourceCondoCorporation.contact.sourceContactId
        );
        if (condoContactLocked) {
          return true;
        }
      }
    }

    if (this.fireInsuranceData && this.context.sourceMatter.fireInsurerOrBrokerContact) {
      let fireInsContactLocked = await this.isContactLocked(
        'Fire Insurance',
        this.context.sourceMatter.fireInsurerOrBrokerContact.sourceContactId
      );
      if (fireInsContactLocked) {
        return true;
      }
    }

    if (this.context.sourceMatter.mainClients) {
      for (let mainClient of this.context.sourceMatter.mainClients) {
        let mainClientContactLocked = await this.isContactLocked(
          this.context.sourceMatter.mainClientTitle,
          mainClient.contact.sourceContactId
        );
        if (mainClientContactLocked) {
          return true;
        }
      }
    }
    return false;
  }

  async isContactLocked(fromTab: string, contactId: number) {
    let contact: Contact = await this.contactQueryService.getContactForMatter(contactId).toPromise();
    if (contact.locked) {
      let msg: string = `Contact ${contact.displayName} locked by ${contact.lockUserName}.  To complete copy, please close editing of this contact`;
      let response = await this.dialogService.confirm(fromTab, msg, true).toPromise();
      return true;
    }
    return false;
  }

  async copyMatterDetails(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.context.copyMatterService.copyMatterOpeningFields(sourceMatter, targetMatter);
    await this.context.copyMatterService.copySubjectPropertyFields(sourceMatter, targetMatter);

    if (sourceMatter.isCondoCorporationTabEnabled) {
      await this.context.copyMatterService.copyCondoCorporationFields(sourceMatter, targetMatter);
    }

    if (this.mortgageData) {
      await this.context.copyMatterService.copyMatterMortgages(sourceMatter, targetMatter);
    }
    if (this.fireInsuranceData) {
      await this.context.copyMatterService.copyMatterFireInsurance(sourceMatter, targetMatter);
    }
  }

  openMatter(matter: Matter): void {
    this.context.tabsComponent.openMatter(matter, () => {
      this.dialogService
        .confirm('Error', 'Your access to this matter has been removed by your administrator.', true)
        .subscribe();
    });
  }

  close() {
    this.matterService.unlockMatter(this.context.sourceMatter.id);
    this.dialog.close();
  }
}
