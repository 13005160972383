import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, Inject, ViewChild} from '@angular/core';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {TeranetWritNumberSearchRequest} from '../../../../shared-main/teranet/teranet-writ-number-search-request';
import {EnforcementOffice} from '../../../../shared-main/teranet/enforcement-office';
import {Utils} from '../../../shared/utils';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ApplicationError, FieldError} from '../../../../core/application-error';
import {Writ} from '../../../../shared-main/teranet/property-writ';
import {WritExecution} from '../../../../shared-main/teranet/property-writ-execution';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class WritNumberSearchModalContext {
  writNumberSearchRequest: TeranetWritNumberSearchRequest;
  enforcementOffices: EnforcementOffice[];
  searchedWrits: Writ[];
}

@Component({
  selector: 'dp-writ-number-search',
  templateUrl: 'writ-number-search.modal.component.html',
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class WritNumberSearchModal extends ModalComponent<WritNumberSearchModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<WritNumberSearchModal>,
    public teranetService: TeranetService,
    public errorService: ErrorService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: WritNumberSearchModalContext
  ) {
    super(dialog, context);
  }

  get enforcementOffices(): EnforcementOffice[] {
    return this.context.enforcementOffices;
  }

  get writNumberSearchRequest(): TeranetWritNumberSearchRequest {
    return this.context.writNumberSearchRequest;
  }

  onCancel(): void {
    this.dialog.close();
  }

  validateWritFormat(): void {
    this.modalErrorComponent.removeDpFieldError('teranet.searchWrit.invalidWrit');
    if (!Utils.isWritFormatValid(this.writNumberSearchRequest.writNumber)) {
      this.modalErrorComponent.createDPFieldError('teranet.searchWrit.invalidWrit');
    }
  }

  searchWritByNumber(): void {
    let duplicateWritExecution: WritExecution = null;
    for (let i: number = 0; i < this.context.searchedWrits.length; i++) {
      if (this.context.searchedWrits[i].writExecutions) {
        duplicateWritExecution = this.context.searchedWrits[i].writExecutions.find(
          (value) => value.executionNumber === this.writNumberSearchRequest.writNumber
        );
        if (duplicateWritExecution) {
          break;
        }
      }
    }
    if (duplicateWritExecution != null) {
      if (
        duplicateWritExecution.executionCertificate &&
        duplicateWritExecution.executionCertificate.isDocumentAvailableForView
      ) {
        this.dialogService
          .confirm(
            'Teranet Connect &trade;: Writ No. ' + duplicateWritExecution.executionNumber + ' Previously Ordered ',
            'The Writ No. ' +
              duplicateWritExecution.executionNumber +
              ' (enforcement office  ' +
              duplicateWritExecution.enforcementOffice +
              ') was previously ordered and cannot be ordered again.',
            true,
            'Close'
          )
          .subscribe((res) => {});
      } else {
        this.dialogService
          .confirm(
            'Teranet Connect &trade;: Writ No. ' + duplicateWritExecution.executionNumber + ' Already Exists ',
            'The Writ No. ' +
              duplicateWritExecution.executionNumber +
              ' (enforcement office  ' +
              duplicateWritExecution.enforcementOffice +
              ') already exists in the list. To order the writ, check the associated checkbox and click the "Order" button. ',
            true,
            'Close'
          )
          .subscribe((res) => {});
      }
    } else {
      this.writNumberSearchRequest.enforcementOfficeName = this.enforcementOffices.find(
        (value) => value.enforcementOfficeNumber === this.writNumberSearchRequest.enforcementOfficeNumber
      ).enforcementOfficeName;
      if (this.isSearchRequestValid()) {
        this.teranetService.writNumberSearch(this.writNumberSearchRequest).subscribe(
          (userAddedWrit: Writ) => {
            this.dialog.close(userAddedWrit);
          },
          (error: ApplicationError) => {
            this.handleTeranetSearchApiError(error);
          }
        );
      }
    }
  }

  handleTeranetSearchApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  isSearchRequestValid(): boolean {
    this.modalErrorComponent.removeAllDpFieldError();
    if (!Utils.isWritFormatValid(this.writNumberSearchRequest.writNumber)) {
      this.modalErrorComponent.createDPFieldError('teranet.searchWrit.invalidWrit');
    }
    return !this.errorService.hasErrors();
  }

  ngAfterViewInit() {}
}
