import {Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Address, Contact, ContactService, dropDowns, Matter} from '../../shared';
import {Referral} from '../../shared/referral/referral';
import {referralDropDowns} from './referral-drop-downs';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ReferralForm} from '../../shared/referral/referral-form';
import {MatterTopic} from '../../shared/matter-topic';
import {StickyNotesModal} from '../../../shared-main/sticky-notes/sticky-notes.modal.component';
import * as _ from 'lodash';
import moment from 'moment';
import {ErrorService} from '../../../shared/error-handling/error-service';
import Utils from '../../../shared-main/utils';
import {
  UnitConnectDataPropagation,
  UnitConnectDataPropagationCmdType
} from '../../purchaser/cirf/cirf-import-data/unit-connect-data-propagation';
import {Mortgage} from '../../shared/mortgage';
import {MortgageTerm} from '../../shared/mortgage-term';
import {BrokerCommission} from '../../broker-commission/broker-commission';

import {MatterParticipantWrapper} from '../../shared/matter-participant-wrapper';
import {Constants} from '../../../shared-main/constants';
import {Observable, Subject, Subscription} from 'rxjs';
import {MatterParticipant} from '../../shared/matter-participant';
import {CommonContactDialogComponent} from '../common-contact-dialog/common-contact-dialog.component';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {AddressTypes} from '../../shared/address-types';
import {SameAsAddressOption} from '../../shared/address';
import {Utils as ShareUtils} from '../../shared/utils';
import {constValues} from '../../shared/const-values';
import {MatterParticipantRoleTypes} from '../../shared/matter-participant-role-types';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DPError} from '../../../shared/error-handling/dp-error';
import {TabsService} from '../../../core/tabs.service';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';

export class ReferralMortgageWrapper {
  selectedMortgageOption: string; //populated if mortgage selected for import
  selectedMortgageDescription: string;
  mortgageId: number; //the matter mortgage

  isNewRecord(): boolean {
    return (this.selectedMortgageOption == 'New');
  }
}

export const realEstateBrokeragEerrorElementKey = 'realEstateBrokerage.readOnly';

@AutoUnsubscribe()
@Component({
  selector: 'dp-referral-import-matter-data',
  templateUrl: 'referral-import-matter-data.component.html'
})

export class ReferralImportMatterDataComponent implements OnInit {
  @Input() matter: Matter;
  @Input() originalMatter: Matter; //original matter used for reverting changes
  @Input() referral: Referral;
  @Input() referralDataPropagation: UnitConnectDataPropagation;

  solicitorsDropDownList: any[] = [];

  copyAllFieldsChecked: boolean;
  provinceOptions: SelectItem[] = referralDropDowns.provinceOptions;
  referralPurchaseOfOptions: SelectItem[] = referralDropDowns.purchaseOfOptions;
  purchaseTypes: SelectItem[] = dropDowns.purchaseType;

  referralMortgageWrapper: ReferralMortgageWrapper;
  mortgageOptions: SelectItem[] = referralDropDowns.mortgageOptions;

  //Other side solicitor
  otherSideSolicitorOptions: any;
  otherSideSolicitorsLoading = false;
  searchTermOtherSideSolicitor: Subject<string> = new Subject<string>();
  otherSideSolicitorAutoCompleteEmptyFlag: boolean = false;
  selectedOtherSideSolicitor: any; //can be contact or search text
  selectedFirmHasSolicitors: boolean;
  //Other side Law Firm
  selectedOtherSideLawFirm: any; //can be contact or search text;
  otherSideLawFirmOptions: any = [];
  otherSideLawFirmsLoading = false;
  searchTermOtherSideLawFirm: Subject<string> = new Subject<string>();
  otherSideLawFirmAutoCompleteEmptyFlag: boolean = false;

  contactAddressType: string = AddressTypes.solicitorAddress;
  solicitorAddressDd: SameAsAddressOption[] = [];

  // RealEstateAgent
  realEstateAgents: any;
  realEstateAgentValue: string;
  selectedRealEstateAgent: any; //can be contact or search text;
  realEstateAgentAutoCompleteEmptyFlag: boolean = false;
  realEstateAgentsLoading = false;
  searchTermRealEstateAgentSubject: Subject<string> = new Subject<string>();

  // RealEstateBroker
  realEstateBrokers: any = [];
  realEstateBrokerValue: string;
  selectedRealEstateBroker: any; //can be contact or search text;
  realEstateBrokerAutoCompleteEmptyFlag: boolean = false;
  realEstateBrokersLoading = false;
  searchTermRealEstateBroker: Subject<string> = new Subject<string>();

  public otherSideSolicitorSubscription: Subscription;
  public otherSideLawFirmSubscription: Subscription;
  public realEstateAgentSubscription: Subscription;
  public realEstateBrokerSubscription: Subscription;
  shareUtils: ShareUtils;

  isRealEstateBrokerReadOnly: boolean;

  constructor(public dialogService: DialogService,
              public documentProfileCache: DocumentProfileCache,
              public contactService: ContactService,
              public contactQueryService: ContactQueryService,
              public tabsService: TabsService,
              public errorService: ErrorService
  ) {
    this.shareUtils = new ShareUtils();
  }

  ngOnInit() {
    if (this.referral.referralForm.closingDate) {
      this.referral.referralForm.closingDate = this.formatDate(this.referral.referralForm.closingDate);
    }

    //populate mortgage wrappers
    this.initReferralMortgageWrapper();

    this.populateSolicitorList();

    if (!this.matter.brokerCommission) {
      this.matter.brokerCommission = new BrokerCommission();
      this.matter.depositHeldBy = constValues.depositHeldBy.VENDOR_BROKER;
      this.matter.commissionBasedOnFixedPercentageOfSalePrice = 'NO';
    }

    this.initOtherSideSolicitor();

    this.initOtherSideLawFirm();

    this.initRealEstateAgent();

    this.initRealEstateBroker();
  }

  initReferralMortgageWrapper() {
    this.referralMortgageWrapper = new ReferralMortgageWrapper();
    this.referralMortgageWrapper.selectedMortgageOption = '';
  }

  initOtherSideSolicitor() {
    this.otherSideSolicitorSubscription = this.searchTermOtherSideSolicitor.switchMap((term: string) => {
      this.otherSideSolicitorAutoCompleteEmptyFlag = false;
      this.otherSideSolicitorsLoading = true;
      if (term.trim() === '') {
        this.otherSideSolicitorAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'SOLICITOR-DISPLAYNAME-ADDRESS', false, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.otherSideSolicitorsLoading = false;
        this.otherSideSolicitorOptions = data;
      },
      error => {
        this.otherSideSolicitorsLoading = false;
      });

  }

  async initOtherSideLawFirm() {
    this.otherSideLawFirmSubscription = this.searchTermOtherSideLawFirm.switchMap((term: string) => {
      this.otherSideLawFirmAutoCompleteEmptyFlag = false;
      this.otherSideLawFirmsLoading = true;
      let page: number = 1;
      let perPage: number = 15;
      if (term.trim() === '') {
        this.otherSideLawFirmAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
        return observable;

      } else {
        return this.contactService.getContactsList(term.trim(), 'LAW_FIRM', false, 'ACTIVE', null, null, null, page, perPage);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.otherSideLawFirmsLoading = false;
        this.otherSideLawFirmOptions = data;
      },
      error => {
        this.otherSideLawFirmsLoading = false;
      });
    const otherPartyLawFirm: MatterParticipant = this.matter.otherPartyLawFirm;
    if (otherPartyLawFirm && otherPartyLawFirm.contact && otherPartyLawFirm.contact.id) {
      otherPartyLawFirm.sourceContact = await this.contactQueryService.getContactForMatter(otherPartyLawFirm.contact.id).toPromise();
    }
  }

  async initRealEstateAgent() {
    this.realEstateAgentSubscription = this.searchTermRealEstateAgentSubject.switchMap((term: string) => {
      setTimeout(() => {
        this.realEstateAgentsLoading = true;
      }, 1);
      this.realEstateAgentAutoCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.realEstateAgentAutoCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.shareUtils.getStartTypingToRealEstateAgent()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'REALESTATEAGENT', null, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.realEstateAgentsLoading = false;
        return this.realEstateAgents = data;
      });

    const realEstateAgentParticipant = this.matter.realEstateAgent;
    if (realEstateAgentParticipant && realEstateAgentParticipant.contact && realEstateAgentParticipant.contact.sourceContactId) {
      await this.useParticipantForImportingReferralData(realEstateAgentParticipant);

    }
  }

  initRealEstateBroker() {
    // RealEstateBroker
    this.realEstateBrokerSubscription = this.searchTermRealEstateBroker.switchMap((term: string) => {
      setTimeout(() => {
        this.realEstateBrokersLoading = true;
      }, 1);
      this.realEstateBrokerAutoCompleteEmptyFlag = false;

      if (term.trim() === '') {

        this.realEstateBrokerAutoCompleteEmptyFlag = true;

        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.shareUtils.getStartTypingToRealEstateBroker()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'REALESTATEBROKER', null, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.realEstateBrokersLoading = false;
        return this.realEstateBrokers = data;
      });

    if (this.matter.isPurchase && this.matter.selectedBrokerId && this.matter.realEstateBrokerName) {
      this.selectedRealEstateBroker = this.matter.realEstateBrokerName;
    }
  }

  copyAllMatterFields(event): void {
    this.copySolicitorName(event);
    this.copyClosingDate(event);
    this.copyFullPropertyAddress(event);

    if (this.matter.isPurchase || this.matter.isSale) {
      this.copyPurchaseType(event);
      this.copyPurchasePrice(event);
      this.copyDepositAmount(event);
    }

    if (this.matter.isSale) {
      this.copyTotalCommissions(event);
    }

    if ((this.matter.isPurchase || this.matter.isMortgage) && !this.isMortgageAlreadyExist()) {
      this.referralMortgageWrapper.selectedMortgageOption = 'New';
      this.updateSelectedMortgage();
      this.copyPrincipal(event);
    }
  }

  copyFullPropertyAddress(event: any): void {
    this.copyPropertyAddressField(event, 'addressLine1');
    this.copyPropertyAddressField(event, 'addressLine2');
    this.copyPropertyAddressField(event, 'city');
    this.copyPropertyAddressField(event, 'provinceCode');
    this.copyPropertyAddressField(event, 'postalCode');
    this.copyPropertyAddressField(event, 'country');
  }

  copyPropertyAddressField(event: any, addressField: string): void {
    if (this.referralPropertyAddress) {
      if (event.srcElement.checked) {
        this.matterPropertyAddress[ addressField ] = this.referralPropertyAddress[ addressField ];
        if (addressField == 'provinceCode') {
          let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == this.matterPropertyAddress.provinceCode);
          if (provinceOption) {
            this.matterPropertyAddress.provinceName = provinceOption.label;
          }
        }
      } else {
        this.matterPropertyAddress[ addressField ] = this.originalMatter.matterPropertyWithCondo.address[ addressField ];
        if (addressField == 'provinceCode') {
          this.matterPropertyAddress.provinceName = this.originalMatter.matterPropertyWithCondo.address.provinceName;
        }
      }
    }
  }

  updateProvinceData() {
    if (this.matterPropertyAddress) {
      let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == this.matterPropertyAddress.provinceCode);
      this.matterPropertyAddress.provinceName = provinceOption && provinceOption.label;

    }
  }

  copyPurchaseType(event): void {
    if (event.srcElement.checked) {
      if (!this.referralForm.purchaseType) {
        this.matter.matterPropertyWithCondo.purchaseIsOfCode = 'QUESTION';
      } else if (this.referralForm.purchaseType == 'RESALE') {
        this.matter.matterPropertyWithCondo.purchaseIsOfCode = 'RESALE_HOME';
      } else if (this.referralForm.purchaseType == 'NEW_HOME') {
        this.matter.matterPropertyWithCondo.purchaseIsOfCode = 'NEW_BUILDER_HOME';
      }
    } else {
      this.matter.matterPropertyWithCondo.purchaseIsOfCode = this.originalMatter.matterPropertyWithCondo.purchaseIsOfCode;
    }
  }

  copyPurchasePrice(event): void {
    if (this.isPurchasePriceApplicable()) {
      if (event.srcElement.checked) {
        if (this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment) {
          this.matter.considerationLtt.salePriceAdjustment.agreementSalePrice = this.referralForm.purchasePrice;
        }
      } else {
        if (this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment && this.originalMatter.considerationLtt && this.originalMatter.considerationLtt.salePriceAdjustment) {
          this.matter.considerationLtt.salePriceAdjustment.agreementSalePrice = this.originalMatter.considerationLtt.salePriceAdjustment.agreementSalePrice;
        }
      }
    }
  }

  //Disable import if any sale price info has been entered or changed from defaults.
  isPurchasePriceApplicable(): boolean {
    return this.originalMatter.matterPropertyWithCondo.isSalePriceInSoa() && !this.originalMatter.matterPropertyWithCondo.purchasePrice && !this.originalMatter.matterPropertyWithCondo.soaPurchasePrice
      && (this.originalMatter.considerationLtt && this.originalMatter.considerationLtt.salePriceAdjustment && this.originalMatter.considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice == 'NO');
  }

  //Disable import if any deposit amount exists or multiple deposits selected.
  isDepositAmountApplicable(): boolean {
    return !this.originalMatter.matterPropertyWithCondo.depositAmount && (!this.originalMatter.matterPropertyWithCondo.multipleDeposit || this.originalMatter.matterPropertyWithCondo.multipleDeposit == 'NO');
  }

  //For now only think about the new matter
  copyDepositAmount(event): void {
    if (this.isDepositAmountApplicable()) {
      if (event.srcElement.checked) {
        this.matter.matterPropertyWithCondo.depositAmount = this.referralForm.depositAmount;
      } else {
        this.matter.matterPropertyWithCondo.depositAmount = this.originalMatter.matterPropertyWithCondo.depositAmount;
      }
    }
  }

  copyPrincipal(event): void {
    if (!this.isMortgageAlreadyExist()) {
      if (event.srcElement.checked) {
        if (this.matterMortgageTerm) {
          this.matterMortgageTerm.principal = this.referralForm.newMortgageAmount;
        }

      } else {
        if (this.matterMortgageTerm) {
          this.matterMortgageTerm.principal = null;
        }
      }
    }
  }

  copyTotalCommissions(event): void {
    if (this.isTotalCommissionApplicable()) {
      if (event.srcElement.checked) {
        if (!this.matter.brokerCommission) {
          this.matter.brokerCommission = new BrokerCommission();
        }
        this.matter.depositHeldBy = constValues.depositHeldBy.VENDOR_BROKER;
        this.matter.commissionBasedOnFixedPercentageOfSalePrice = 'NO';
        this.matter.brokerCommission.commissionBeforeHst = this.referralForm.totalCommissionAmount;
      } else {
        if (this.originalMatter.brokerCommission) {
          this.matter.brokerCommission.commissionBeforeHst = this.originalMatter.brokerCommission.commissionBeforeHst;

        } else {
          this.matter.brokerCommission.commissionBeforeHst = null;

        }
      }
    }
  }

  get matterMortgageTerm(): MortgageTerm {
    return this.matterMortgage && this.matterMortgage.mortgageTerm;
  }

  get matterMortgage(): Mortgage {
    if (!this.referralMortgageWrapper) {
      this.initReferralMortgageWrapper();
    }

    return this.matter.mortgages.find(item => item.id === this.referralMortgageWrapper.mortgageId);
  }

  get referralPropertyAddress(): Address {
    return this.referral.referralForm.propertyAddress;
  }

  get matterPropertyAddress(): Address {
    return this.matter.matterPropertyWithCondo.address;
  }

  onClosingDateChange(event) {
    this.matter.matterCloseDate = event.rawDate;
  }

  copyClosingDate(event): void {
    if (event.srcElement.checked) {
      this.matter.matterCloseDate = this.referral.referralForm.closingDate;
    } else {
      this.matter.matterCloseDate = this.originalMatter.matterCloseDate;
    }
  }

  formatDate(date: string): string {
    return moment(date).format('YYYY/MM/DD');
  }

  get referralForm(): ReferralForm {
    return this.referral.referralForm;
  }

  get txnType(): string {
    return (this.matter && this.matter.txnType);
  }

  get matterOpeningTopic(): MatterTopic {
    return this.matter.getMatterTopicByKey('MATTER_OPENING');
  }

  openStickyNotesModal(): void {
    this.dialogService.matDialogContent({
      content: StickyNotesModal,
      context: {
        notes: this.matterOpeningTopic.topicNote
      },
      onFulfillment: (result) => {
        if (result && result.status == 'OK') {
          this.matterOpeningTopic.topicNote = result.notes;
        } else if (result && result.status == 'DELETE') {
          this.matterOpeningTopic.topicNote = '';
        }
      }
    });
  }

  getDealDataNotes(): string {
    let dealDataNotes: string = '-----------------------------------\n' +
      'Deal Data From Referral\n' +
      '-----------------------------------\n';

    dealDataNotes += this.createStickyNoteLine('Closing Date', Utils.formatDateString(this.referralForm.closingDate, true, ''));
    dealDataNotes += this.createStickyNoteLine('Address 1', this.referralPropertyAddress.addressLine1);
    dealDataNotes += this.createStickyNoteLine('Address 2', this.referralPropertyAddress.addressLine2);
    dealDataNotes += this.createStickyNoteLine('City', this.referralPropertyAddress.city);
    dealDataNotes += this.createStickyNoteLine('Province', this.referralPropertyAddress.provinceCode);
    dealDataNotes += this.createStickyNoteLine('Postal Code', this.referralPropertyAddress.postalCode);
    dealDataNotes += this.createStickyNoteLine('Country', this.referralPropertyAddress.country);
    dealDataNotes += this.createStickyNoteLine('Transaction Type', this.referralForm.transactionType);

    if (this.matter.isPurchase || this.matter.isSale) {
      dealDataNotes += this.createStickyNoteLine('Purchase is of', this.referralForm.purchaseType);
      dealDataNotes += this.createStickyNoteLine('Purchase Price', this.referralForm.purchasePrice);
      dealDataNotes += this.createStickyNoteLine('Deposit Amount', this.referralForm.depositAmount);
      dealDataNotes += this.createStickyNoteLine('Other Solicitor', this.referralForm.otherSolicitorName);
      dealDataNotes += this.createStickyNoteLine('Other Law Firm', this.referralForm.otherLawFirmName);
      dealDataNotes += this.createStickyNoteLine('Real Estate Agent', this.referralForm.realEstateAgentName);
      dealDataNotes += this.createStickyNoteLine('Real Estate Brokerage', this.referralForm.realEstateBrokerageFirmName);
      dealDataNotes += this.createStickyNoteLine('Total Commissions', this.referralForm.totalCommissionAmount);
    }

    if (this.matter.isPurchase || this.matter.isMortgage) {
      dealDataNotes += this.createStickyNoteLine('New Mortgage Amount', this.referralForm.newMortgageAmount);
    }

    dealDataNotes += '\n';

    return dealDataNotes;
  }

  //returns blank label if value is null
  createStickyNoteLine(label: string, value: string | number) {
    return '\n' + label + ': ' + (value ? value : '');
  }

  copyAllDealDataToNotes(event): void {
    if (event.srcElement.checked) {
      if (this.matterOpeningTopic.topicNote && this.matterOpeningTopic.topicNote.length > 0) {
        this.matterOpeningTopic.topicNote += '\n';
      } else {
        this.matterOpeningTopic.topicNote = '';
      }
      this.matterOpeningTopic.topicNote += this.getDealDataNotes();
    } else {
      this.matterOpeningTopic.topicNote = this.matterOpeningTopic.topicNote.replace(this.getDealDataNotes(), '');
    }
  }

  onSolicitorChange(event: any): void {
    this.referral.solicitorChanged = true;
  }

  copySolicitorName(event): void {
    if (event.srcElement.checked) {
      this.matter.selectedSolicitorId = this.referralForm.receivingSolicitorId;
    } else {
      this.matter.selectedSolicitorId = this.originalMatter.selectedSolicitorId;
    }

    this.onSolicitorChange(event);
  }

  populateSolicitorList(): void {
    this.contactService.getSolicitorLawClerkList('SOLICITOR')
    .subscribe(responseData => {
      let filteredList: Contact[] = responseData.filter(item => 'YES' === item.activeFlag || 'Y_n' === item.activeFlag || 'Y/n' === item.activeFlag);

      this.solicitorsDropDownList.push({
        label: '',
        value: 'N/A'
      });

      for (let i = 0; i < filteredList.length; i++) {
        this.solicitorsDropDownList.push({
          label: filteredList[ i ].contactName.surnameLastFullName,
          value: filteredList[ i ].id
        });
      }

      this.solicitorsDropDownList = _.sortBy(this.solicitorsDropDownList, function (i) {
        return i.label.toLowerCase();
      });

    });
  }

  isTotalCommissionApplicable(): boolean {
    return (this.matter.isSale &&
      (!this.originalMatter.brokerCommission || (!!this.originalMatter.brokerCommission &&
        (this.originalMatter.commissionBasedOnFixedPercentageOfSalePrice === '' || this.originalMatter.commissionBasedOnFixedPercentageOfSalePrice == null)
        && (!this.originalMatter.brokerCommission.commissionBeforeHst || this.originalMatter.brokerCommission.commissionBeforeHst === 0))));
  }

  isMortgageAlreadyExist(): boolean {
    return this.originalMatter.mortgages.length > 0;
  }

  updateSelectedMortgage(): void {
    if (this.referralMortgageWrapper.isNewRecord()) {
      this.addNewMortgageIntoMortgages();
      this.referralMortgageWrapper.selectedMortgageDescription = '1st New Mortgage';
    }

  }

  addNewMortgageIntoMortgages() {
    const mortgagePriority: number = this.matter.mortgages ? Number(this.matter.mortgages.length + 1) : 1;
    const matterMortgage = this.matter.createMortgage('NEW', 'UNITY', mortgagePriority);
    this.referralMortgageWrapper.mortgageId = matterMortgage.id;
    this.matter.mortgages.push(matterMortgage);
    this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_MORTGAGE_TERM_PRINCIPAL, null, true, this.matterMortgage.id);
    //If select allCheckBox before add new mortgage, it should call copyPrincipal to copy the value.
    if (this.copyAllFieldsChecked) {
      if (this.matterMortgageTerm) {
        this.matterMortgageTerm.principal = this.referralForm.newMortgageAmount;
      }
    }
  }

  removeSelectedMortgage(): void {
    if (this.matterMortgage) {
      this.referralDataPropagation.removeDataPropagationCommand(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_MORTGAGE_TERM_PRINCIPAL, this.matterMortgage.id);
      this.referralMortgageWrapper.selectedMortgageDescription = undefined;
      this.referralMortgageWrapper.selectedMortgageOption = '';
      this.matter.deleteMortgage(this.matterMortgage);
      this.referralMortgageWrapper.mortgageId = null;
    }

  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  //Other side Solicitor
  get otherSolicitorNameHelpText(): string {
    let helpText: string;

    if (this.selectedFirmHasSolicitors) {
      //If it is my own account, removing to add new solicitor
      if (this.isMyOwnAccountLawFirm()) {
        helpText = 'Select a Solicitor from the Law Firm';
      } else {
        helpText = 'Select a Solicitor from the Law Firm or to add a new one';
      }
    } else {
      helpText = 'Search by "Surname, First Name", Address or Add new';
    }

    return helpText;
  }

  isMyOwnAccountLawFirm(): boolean {
    return this.matter && this.matter.otherPartyLawFirm && this.matter.otherPartyLawFirm.isMyOwnAccountLawFirm();
  }

  onOtherSolicitorChange(event): void {
    if (event.displayName) {
      let str = event.displayName;
      str = str.replace(Constants.ADD_NEW_RECORD, '');
      str = str.replace(/"/g, '');
    }

    if (typeof this.selectedOtherSideSolicitor === 'string' && this.selectedOtherSideSolicitor.trim()) {
      this.handleDropDownClickOtherSideSolicitorPickList(event);
    }
  }

  handleDropDownClickOtherSideSolicitorPickList = (event) => { // to preserve 'this' context
    const otherPartyLawFirm = this.matter.otherPartyLawFirm;
    if (otherPartyLawFirm && otherPartyLawFirm.contact) {
      if (this.isMyOwnAccountLawFirm()) {
        //If it is my own account, removing to add new solicitor
        this.populateOtherSideSolicitor(otherPartyLawFirm.contact.sourceContactId, true);
      } else {
        this.populateOtherSideSolicitor(otherPartyLawFirm.contact.sourceContactId);
      }
    }
  };

  /**
   * populate solicitor drop down list
   * @param lawFirmId
   * @param removeAddNewRecord
   */
  populateOtherSideSolicitor(lawFirmId: number, removeAddNewRecord?: boolean): void {
    if (lawFirmId) {
      this.contactService.getSolicitorsForLawFirm(lawFirmId, true).subscribe(
        (data: any) => {
          this.otherSideSolicitorOptions = data;
          //It will add a new record when there is dynamic drop down list
          // But If it is my own account, Don't add new solicitor
          if (!removeAddNewRecord
            && typeof this.selectedOtherSideSolicitor === 'string' && this.selectedOtherSideSolicitor.trim()) {
            const addNewRecordData: any = {
              displayName: Constants.ADD_NEW_RECORD + `"${ this.selectedOtherSideSolicitor.trim() }"`
            };
            this.otherSideSolicitorOptions.unshift(addNewRecordData);

          }

        }
      );
    }

  }

  dataOtherSelectedSolicitor(): void {
    // If there is an existing solicitor in MatterParticipant array of matter, it needs to remove it.
    let solicitorMP: MatterParticipant = this.matter.otherPartySolicitor;
    if (solicitorMP) {
      this.matter.removeMatterParticipant(solicitorMP);
    }
    if (this.selectedOtherSideSolicitor.id === undefined) {
      // Now take care for adding a new solicitor
      if (this.selectedOtherSideSolicitor.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1) {
        // According to if there is an existing solicitor, it will call different methord
        const otherPartyLawFirm = this.matter.otherPartyLawFirm;
        if (otherPartyLawFirm) {
          this.createSolicitorWithExistingLawFirm(otherPartyLawFirm, this.selectedOtherSideSolicitor.displayName);
        } else {
          this.createSolicitorWithoutExistingLawFirm();
        }
      } else if (this.selectedOtherSideSolicitor.displayName.indexOf('No records available') === 0) {
        this.selectedOtherSideSolicitor = null;
      }
    } else {
      this.selectExistingSolicitor();
    }
  }

  createSolicitorWithExistingLawFirm(matterParticipant: MatterParticipant, displayName: string) {
    if (matterParticipant) {
      if (matterParticipant.contact && matterParticipant.contact.sourceContactId) {
        // Get this exising law firm source contact
        this.contactQueryService.getContactForMatter(matterParticipant.contact.sourceContactId).subscribe((sourceContact: Contact) => {
          if (!Array.isArray(sourceContact.subContacts)) {
            sourceContact.subContacts = [];
          }
          let solicitorContact: Contact = sourceContact.createSolicitorContact(this.documentProfileCache, this.matter && this.matter.provinceCode);
          solicitorContact.contactName.setLastNameFromDisplayName(displayName);
          solicitorContact.legalFirmId = matterParticipant.contact.sourceContactId;
          solicitorContact.organizationId = matterParticipant.contact.sourceContactId;
          sourceContact.subContacts.push(solicitorContact);
          const context = {
            matter: this.matter,
            contact: sourceContact,
            isOnlySolicitor: true,
            isLawFirm: false,
            contactType: 'LAW_FIRM',
            matterParticipantRole: this.matter.otherPartyMPRoleLawFirm,
            contactAddressType: this.contactAddressType,
            solicitorAddressDd: this.solicitorAddressDd,
            fieldPrefix: 'matter.otherParty'
          };
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: context,
            onFulfillment: (result) => {
              if (result) {
                if (result.action === 'save') {
                  if (result.contact && Array.isArray(result.contact.subContacts) && result.contact.subContacts.length > 0) {
                    // We have to recall source solicitor because the subcontact doesn't include the fully
                    // information
                    this.createSolicitorStructure(result.contact.subContacts[ result.contact.subContacts.length - 1 ].id);
                  }
                } else {
                  sourceContact.subContacts.pop();
                }
              }

            },
            fullScreen: false,

          });

        });
      }
    }
  }

  /**
   * We have to recall solicitor because the subcontact doesn't include the fully informtaion
   * @param sourceContactId
   */
  public createSolicitorStructure(sourceContactId: number): void {
    this.contactQueryService.getContactForMatter(sourceContactId).subscribe((sourceSolicitor: Contact) => {
      this.addOtherSideSolicitor(sourceSolicitor);
    });
  }

  createSolicitorWithoutExistingLawFirm() {
    const context = {
      matter: this.matter,
      isOnlySolicitor: false,
      isLawFirm: false,
      contactName: this.selectedOtherSideSolicitor.displayName,
      contactType: 'LAW_FIRM',
      matterParticipantRole: this.matter.otherPartyMPRoleLawFirm,
      contactAddressType: this.contactAddressType,
      solicitorAddressDd: [],
      fieldPrefix: 'matter.otherParty'

    };
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: context,
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {
            let snapshotContact: Contact;
            // Add law firm info
            if (result.contact) {
              this.addOtherSideLawFirm(result.contact);
            }
            // Add solicitor info
            // We have to recall solicitor because the source subcontact doesn't include the fully information
            if (result.contact && Array.isArray(result.contact.subContacts) && result.contact.subContacts.length > 0) {
              this.createSolicitorStructure(result.contact.subContacts[ result.contact.subContacts.length - 1 ].id);
            }
          }
        }
      },
      fullScreen: true,

    });

  }

  selectExistingSolicitor() {
    this.contactQueryService.getContactForMatter(this.selectedOtherSideSolicitor.id).subscribe((sourceSolicitor: Contact) => {
      if (sourceSolicitor) {
        this.addOtherSideSolicitor(sourceSolicitor);
        // Only when law firm is empty, it will get this solicitor's law firm
        if (!this.matter.otherPartyLawFirm) {
          // Populating the combo box
          this.contactQueryService.getContactForMatter(sourceSolicitor.legalFirmId).subscribe((sourceLawFirm: Contact) => {
            if (sourceLawFirm) {
              // Add the Law Firm MatterParticipant
              this.addOtherSideLawFirm(sourceLawFirm);
            }
          });
        }
      }
    });
  }

  addOtherSideSolicitor(sourceSolicitor: Contact) {
    const solicitorParticipant: MatterParticipant = this.matter.otherPartySolicitor;
    //Clean this.selectedSolicitor with new data Model
    //Remove old solicitorParticipant
    if (solicitorParticipant) {
      this.matter.removeMatterParticipant(solicitorParticipant);

    }
    this.matter.addMatterParticipant(sourceSolicitor,
      true,
      this.matter.otherPartyMPRoleSolicitor);
    this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_SOLICITOR, null, true);
    this.selectedFirmHasSolicitors = false;

  }

  //If there is drop down in solicitor, we stop the search. It will show  the solicitors  of this law firm  plus "Add New" Item.
  searchOtherSideSolicitor(event): void {
    if (!this.selectedFirmHasSolicitors) {
      let entered: string = event.query;
      this.searchTermOtherSideSolicitor.next(entered);
    }
  }

  onLeavingSolicitorOmniBarWithoutFreeInput() {
    this.selectedOtherSideSolicitor = null;
  }

  onLeavingLawFirmOmniBarWithoutFreeInput() {
    this.selectedOtherSideLawFirm = null;
  }

  getContactDisplayName(matterParticipant: MatterParticipant): string {
    if (matterParticipant && matterParticipant.contact) {
      return matterParticipant.contact.displayName ? matterParticipant.contact.displayName : matterParticipant.contact.organizationName;
    } else {
      return '';
    }
  }

  getRealAgentContactDisplayName(matterParticipant: MatterParticipant): string {
    if (matterParticipant && matterParticipant.contact) {
      return matterParticipant.contact.contactName ? matterParticipant.contact.contactName.fullName : '';
    } else {
      return '';
    }
  }

  deleteOtherPartySolicitor(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this other solicitor?', false, 'Delete').subscribe(res => {
      if (res) {
        this.deleteOtherPartySolicitorAction();
      }
    });
    this.selectedFirmHasSolicitors = true;
  }

  deleteOtherPartySolicitorAction(): void {
    const solicitor = this.matter.otherPartySolicitor;
    if (solicitor) {
      this.matter.deleteMatterParticipant(solicitor);
      this.selectedOtherSideSolicitor = null;
      this.referralDataPropagation.removeDataPropagationCommand(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_SOLICITOR);
    }

  }

  dataSelectedOtherSideLawFirm(): void {
    if (this.selectedOtherSideLawFirm.id === undefined) {
      // Now take care for adding ne law firm
      if (this.selectedOtherSideLawFirm.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1) {
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            isLawFirm: true,
            contactType: 'LAW_FIRM',
            contactName: this.selectedOtherSideLawFirm.displayName,
            matterParticipantRole: this.matter.otherPartyMPRoleLawFirm

          },
          onFulfillment: (result) => {
            if (result) {
              if (result.action === 'save' && result.contact) {
                this.addOtherSideLawFirm(result.contact);
              }
            }
          },
          fullScreen: false,

        });

      }

    } else {
      this.contactQueryService.getContactForMatter(this.selectedOtherSideLawFirm.id).subscribe((source: Contact) => {
        if (source) {
          this.addOtherSideLawFirm(source);
        }
      });
    }
  }

  addOtherSideLawFirm(sourceContact: Contact) {
    const lawFirmParticipant: MatterParticipant = this.matter.otherPartyLawFirm;
    //Remove old lawFirmParticipant
    if (lawFirmParticipant) {
      this.matter.removeMatterParticipant(lawFirmParticipant);
    }

    let snapshotContact: Contact;
    //According to backend requirement, UI set law firm snapshot subContact as []
    snapshotContact = new Contact(sourceContact);
    snapshotContact.subContacts = [];
    const lawFirm: MatterParticipant = this.matter.addMatterParticipant(sourceContact,
      true,
      this.matter.otherPartyMPRoleLawFirm);
    lawFirm.sourceContact = sourceContact;
    this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_FIRM, null, true);
    //If there is no otherPartySolicitor, it need update selectedFirmHasSolicitors
    if (!this.matter.otherPartySolicitor) {
      this.selectedFirmHasSolicitors = Array.isArray(sourceContact.subContacts) && sourceContact.subContacts.length > 0 ? true : false;
    }
    this.solicitorAddressDd = lawFirm.contact.createSolicitorAddressDdOptions(lawFirm.contact);
  }

  deleteOtherLawFirm() {
    let message: string;
    if (this.matter.otherPartySolicitor) {
      message = 'Proceed to remove other law firm and other solicitor information?';
    } else {
      message = 'Proceed to remove other law firm information?';
    }

    this.dialogService.confirm('Confirmation', message, false, 'Delete').subscribe(res => {
      if (res) {

        this.selectedFirmHasSolicitors = false;
        this.deleteOtherPartyLawFirmAction();

        this.deleteOtherPartySolicitorAction();
        this.solicitorAddressDd = [];
      }
    });
  }

  deleteOtherPartyLawFirmAction() {
    const lawFirm = this.matter.otherPartyLawFirm;
    if (lawFirm) {
      this.matter.deleteMatterParticipant(lawFirm);
      this.referralDataPropagation.removeDataPropagationCommand(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_FIRM);
      this.selectedOtherSideLawFirm = null;
    }

  }

  searchOtherSideLawFirm(event): void {
    let entered: string = event.query;
    this.searchTermOtherSideLawFirm.next(entered);
  }

  searchRealEstateAgent(event): void {
    let entered: string = event.query;
    if (entered === Constants.REAL_ESTATE_AGENT && this.selectedRealEstateAgent) {
      return;
    }
    this.searchTermRealEstateAgentSubject.next(entered);
  }

  Estate;

  dataSelectedRealEstateAgent(): void {
    if (this.selectedRealEstateAgent.id === undefined) {
      if (this.selectedRealEstateAgent.fullName && this.selectedRealEstateAgent.fullName.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
        this.selectedRealEstateAgent = null;
      } else {
        //In purchase matter, broker save in matter with id and name
        //In sale matter, broker save in MatterParticipant
        if (this.matter.isPurchase) {
          this.createNewPurchaseMatterAgent();
        } else if (this.matter.isSale) {
          this.createNewSaleMatterAgent();
        }
      }
    } else {
      if (this.matter.isSale) {
        this.deleteSaleMatterRealEstateBroker();
      }
      this.contactQueryService.getContactForMatter(this.selectedRealEstateAgent.id).subscribe((sourceContact: Contact) => {
        this.addRealEstateAgent(sourceContact);
      });
    }
  }

  createNewPurchaseMatterAgent() {
    if (this.matter.selectedBrokerId) {
      this.contactQueryService.getContactForMatter(this.matter.selectedBrokerId).subscribe((sourceContact: Contact) => {
        this.createNewRealEstateAgent(sourceContact);
      });
    } else {
      this.createNewRealEstateAgent(null);
    }

  }

  createNewSaleMatterAgent() {
    this.createNewRealEstateAgent(this.matter.realEstateBroker && this.matter.realEstateBroker.contact);
  }

  // For Purchaser Side Agent, it will show work phone, fax and email without source contact for new requirement
  createNewRealEstateAgent(broker: Contact) {
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        contactType: 'REALESTATEAGENT',
        brokerContact: broker,
        isPurchaserSideAgent: true,
        contactName: this.selectedRealEstateAgent.displayName
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {
            if (result.createdNewClient) {
              this.addRealEstateAgent(new Contact(result.contact));
            } else {
              this.addRealEstateAgent(new Contact(result.contact), true);
            }
          }
        }
      },
      onRejection: (reject: any) => {
        //@TODO Review onRejection - this is never called!
      },

    });
  }

  async useParticipantForImportingReferralData(matterParticipant: MatterParticipant): Promise<void> {
    this.errorService.removeMultipleDpFieldErrors(realEstateBrokeragEerrorElementKey);
    this.isRealEstateBrokerReadOnly = false;
    if (matterParticipant) {
      if (matterParticipant.contact && matterParticipant.contact.sourceContactId) { // With sourceContact
        //Before we make sure the agent is not stale or not locked, we let broker is readonly to avoid to modify broker when the agent maybe stale or locked.
        this.isRealEstateBrokerReadOnly = true;
        let isContactStale: boolean = await this.checkContactStaleOrClearFlag(matterParticipant);
        let isSourceContactLocked: boolean;
        if (!isContactStale) {
          isSourceContactLocked = await this.checkContactForLocking(matterParticipant);
        }
        if (!isContactStale && isSourceContactLocked) {
          this.isRealEstateBrokerReadOnly = false;
        }
      }
    }

  }

  async checkContactForLocking(participant: MatterParticipant): Promise<boolean> {
    if (participant && participant.contact && participant.contact.sourceContactId && !participant.sourceContactLockAcquired) {
      let matterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.matterParticipant = participant;
      let sourceContact = await this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).toPromise();
      if (sourceContact.locked) {
        let message = sourceContact.genericFullName + ' is currently locked by ' + sourceContact.lockedByUser.fullName
          + '. The real estate brokerage can not be modified.';
        this.errorService.addDpFieldError(DPError.createCustomDPError(realEstateBrokeragEerrorElementKey, message, 'CONTACT', 'ERROR'));
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }
  }

  async checkContactStaleOrClearFlag(matterParticipant: MatterParticipant): Promise<boolean> {
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.sourceContactId && !matterParticipant.sourceContactLockAcquired) {
      let sourceContact: Contact = await this.contactQueryService.getContactForMatter(Number(matterParticipant.contact.sourceContactId)).toPromise();
      if (sourceContact) {
        let source: Contact = new Contact(sourceContact);
        let isStale = matterParticipant.contact.isStaleContact(source);
        let isContactStaleFlagClearedWithoutUpdatingMatter = matterParticipant.contact.isContactStaleFlagClearedWithoutUpdatingMatter(source);
        if (isStale || isContactStaleFlagClearedWithoutUpdatingMatter) {
          let message = matterParticipant.contact.genericFullName +
            ' is out of sync. The real estate brokerage can not be modified.';
          this.errorService.addDpFieldError(DPError.createCustomDPError(realEstateBrokeragEerrorElementKey, message, 'CONTACT', 'ERROR'));
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      }
    }

  }

  addRealEstateAgent(sourceContact: Contact, isContactCreatedForMatterOnly: boolean = false) {
    //In purchase matter, broker save in matter with id and name
    //In sale matter, broker save in MatterParticipant
    if (this.matter.isPurchase) {
      this.addNewPurchaseMatterRealEstateAgent(sourceContact, isContactCreatedForMatterOnly);
    } else if (this.matter.isSale) {
      this.addNewSaleMatterRealEstateAgent(sourceContact, isContactCreatedForMatterOnly);
    }
  }

  //In purchase matter, broker save in matter with id and name
  async addNewPurchaseMatterRealEstateAgent(sourceContact: Contact, isContactCreatedForMatterOnly: boolean) {
    await this.useParticipantForImportingReferralData(this.addNewRealEstateAgentParticipant(sourceContact, isContactCreatedForMatterOnly));
    //If the contact is locked , this matter participant will be remove from matter. So it need to check if it is still in matter we can do continue work
    const agentMatterParticipant = this.matter.realEstateAgent;
    if (agentMatterParticipant) {
      this.matter.selectedBrokerId = sourceContact.organizationId;
      this.matter.realEstateBrokerName = sourceContact.parentOrganizationName;
      //Because the broker name and id is saved in matter, it needs to add them back to snapshot for stale check.
      // It also need to set purchaserSideAgent for distinguish the agent in broker commission of sale matter
      agentMatterParticipant.contact.sourceParentOrganizationId = this.matter.selectedBrokerId;
    }
  }

  //In sale matter, broker save in MatterParticipant
  async addNewSaleMatterRealEstateAgent(sourceContact: Contact, isContactCreatedForMatterOnly: boolean) {
    await this.useParticipantForImportingReferralData(this.addNewRealEstateAgentParticipant(sourceContact, isContactCreatedForMatterOnly));
    //If the contact is locked , this matter participant will be remove from matter. So it need to check if it is still in matter we can do continue work
    const agentMatterParticipant = this.matter.realEstateAgent;
    if (agentMatterParticipant) {
      agentMatterParticipant.contact.sourceParentOrganizationId = sourceContact.organizationId;
      if (sourceContact.organizationId) {
        this.contactQueryService.getContactForMatter(sourceContact.organizationId).subscribe(
          (brokerCompany: Contact) => {
            this.addSaleMatterRealEstateBroker(brokerCompany);
          }
        );
      }
    }
  }

  addNewRealEstateAgentParticipant(contact: Contact, isContactCreatedForMatterOnly: boolean): MatterParticipant {
    let realEstateAgentParticipant: MatterParticipant = this.matter.realEstateAgentParticipant;
    if (realEstateAgentParticipant) {
      this.matter.removeMatterParticipant(realEstateAgentParticipant);
    }

    return this.matter.addMatterParticipant(contact, !isContactCreatedForMatterOnly, MatterParticipantRoleTypes.REALESTATEAGENT);
  }

  deleteRealEstateAgent(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this real estate agent?', false, 'Delete').subscribe(res => {
      if (res) {
        this.deleteRealEstateAgentAction();
      }
    });
  }

  deleteRealEstateAgentAction(): void {
    let realEstateAgentParticipant: MatterParticipant = this.matter.realEstateAgentParticipant;
    if (realEstateAgentParticipant) {
      if (realEstateAgentParticipant.contact.sourceContactId) {
        this.contactQueryService.unlockContact(realEstateAgentParticipant.contact.sourceContactId);
        this.isRealEstateBrokerReadOnly = false;
        this.errorService.removeMultipleDpFieldErrors(realEstateBrokeragEerrorElementKey);
      }
      this.matter.removeMatterParticipant(realEstateAgentParticipant);
    }
    this.selectedRealEstateAgent = null;
    if (this.matter.isSale) {
      this.deleteRealEstateBrokerAction();

    }
  }

  get realEstateBroker(): string {
    return this.matter && this.matter.realEstateBroker && this.matter.realEstateBroker.contact
      ? this.matter.realEstateBroker.contact.organizationName
      : (this.matter && this.matter.realEstateBrokerName ? this.matter.realEstateBrokerName : undefined);
  }

  onLeavingRealEstateAgentOmniBarWithoutFreeInput() {
    this.selectedRealEstateAgent = null;
  }

  onLeavingRealEstateBrokerOmniBarWithoutFreeInput() {
    this.selectedRealEstateBroker = null;
  }

  searchRealEstateBroker(event): void {
    let entered: string = event.query;
    if (entered === Constants.REAL_ESTATE_BROKER && this.selectedRealEstateBroker) {
      return;
    }
    this.searchTermRealEstateBroker.next(entered);
  }

  onSelectedRealEstateBrokerChange() {
    if (this.selectedRealEstateBroker instanceof Contact) {
      this.selectExistingBroker(this.selectedRealEstateBroker.id);
    } else if (this.selectedRealEstateBroker.displayName
      && this.selectedRealEstateBroker.displayName.indexOf(Constants.ADD_NEW_RECORD) > -1) {
      this.addNewRealEstateBroker();
    } else {
      this.selectedRealEstateBroker = null;
    }
  }

  selectExistingBroker(sourceContactId: number) {
    this.contactQueryService.getContactForMatter(sourceContactId).subscribe(
      (brokerSourceContact: Contact) => {
        this.updateRealEstateBrokerWithAgent(brokerSourceContact);
      }
    );
  }

  addNewRealEstateBroker(): void {
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        contactType: 'REALESTATEBROKER',
        contactName: this.selectedRealEstateBroker.displayName
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {//if contact modal is closed after save
            this.updateRealEstateBrokerWithAgent(new Contact(result.contact));
          }
        }
      },

    });
  }

  updateRealEstateBrokerWithAgent(sourceContact: Contact): void {
    const agentParticipant: MatterParticipant = this.matter.realEstateAgent;
    if (agentParticipant && agentParticipant.contact) {
      agentParticipant.contact.isDirty = true;
    }
    this.updateAgentForBrokerChange(sourceContact.id);
    //In purchase matter, broker save in matter with id and name
    //In sale matter, broker save in MatterParticipant
    if (this.matter.isPurchase) {
      this.matter.realEstateBrokerName = sourceContact.organizationName;
      this.matter.selectedBrokerId = sourceContact.id;
    } else if (this.matter.isSale) {
      this.addSaleMatterRealEstateBroker(sourceContact);
    }
  }

  addSaleMatterRealEstateBroker(sourceContact: Contact) {
    let realEstateBrokerParticipant: MatterParticipant = this.matter.realEstateBroker;
    if (realEstateBrokerParticipant) {
      this.matter.removeMatterParticipant(realEstateBrokerParticipant);
    }
    this.matter.addMatterParticipant(sourceContact, true, MatterParticipantRoleTypes.REALESTATEBROKER);

    this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_REAL_ESTATE_BROKER_CHANGE, null, true);

  }

  getContactDisplayMailingAddress(contact: Contact): string {
    if (!contact || !contact.id || !contact.mailingAddress) {
      return '';
    }
    return contact.mailingAddress.addressTextWithoutCountryAndPostalCode;
  }

  get realEstateAgentContact(): Contact {
    return this.matter && this.matter.realEstateAgent && this.matter.realEstateAgent.contact;
  }

  deleteRealEstateBroker() {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this real estate brokerage?', false, 'Delete').subscribe(res => {
      if (res == true) {
        this.deleteRealEstateBrokerAction();
        //Ony set agent contact is dirty when it is delete from trash bin
        //If it select an agent which include a broker, it will delete the existing broker and add the broker in agent. we dn't set it dirty in this situation.
        if (this.realEstateAgentContact) {
          this.realEstateAgentContact.isDirty = true;
        }
      }
    });
  }

  deleteRealEstateBrokerAction() {
    this.selectedRealEstateBroker = undefined;
    this.updateAgentForBrokerChange(null);
    //In purchase matter, broker save in matter with id and name
    //In sale matter, broker save in MatterParticipant
    if (this.matter.isPurchase) {
      this.matter.realEstateBrokerName = undefined;
      this.matter.selectedBrokerId = undefined;
    } else if (this.matter.isSale) {
      this.deleteSaleMatterRealEstateBroker();
    }
  }

  updateAgentForBrokerChange(sourceParentOrganizationId: number) {
    let realEstateAgentParticipant: MatterParticipant = this.matter.realEstateAgentParticipant;
    if (realEstateAgentParticipant && realEstateAgentParticipant.contact) {
      realEstateAgentParticipant.contact.sourceParentOrganizationId = sourceParentOrganizationId;
    }
  }

  deleteSaleMatterRealEstateBroker() {
    let realEstateBrokerParticipant: MatterParticipant = this.matter.realEstateBroker;
    if (realEstateBrokerParticipant) {
      this.matter.removeMatterParticipant(realEstateBrokerParticipant);
      this.referralDataPropagation.removeDataPropagationCommand(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_REAL_ESTATE_BROKER_CHANGE);
    }
  }

  releaseContactLocks(): void {
    let realEstateAgent: MatterParticipant = this.matter.realEstateAgent;
    this.tabsService.releaseContactLock(realEstateAgent);
  }

  releaseContactLocksIfNotDirty(): void {
    let realEstateAgent: MatterParticipant = this.matter.realEstateAgent;
    this.tabsService.releaseContactLockIfNotDirty(realEstateAgent);
  }

  ngOnDestroy(): void {
  }
}
