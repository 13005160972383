import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
//import {TaxType} from "../../shared/tax-types";
import Utils from '../../../shared-main/utils';

export class SoAdjWithTax extends BaseEntity {

  taxType: string; //HST | GST
  applyTax: boolean; //YES | NO

  hstRate: number;
  gstRate: number;
  pstRate: number;

  applyPst: boolean;

  provinceCode: string;

  /**
   * as of May 2018 when bound to a "select" HTML element 'applyTax' magically transforms from boolean to string
   * @returns {boolean}
   */
  get applyTaxBoolean(): boolean {
    return Utils.convertToBoolean(this.applyTax);
  }

  get applyPstBoolean(): boolean {
    return Utils.convertToBoolean(this.applyPst);
  }

  getHstOrGstValueForAmount(amount: number, provinceCode ?: string): number {
    if (provinceCode) {
      this.provinceCode = provinceCode;
    }
    if (this.applyTaxBoolean) {
      if (this.provinceCode == 'AB') {
        return Number(Number(amount) * Number(this.hstRate) / 100);
      } else {
        if (this.taxType == 'GST') {
          return Number(Number(amount) * Number(this.gstRate) / 100);
        } else {
          return Number(Number(amount) * Number(this.hstRate) / 100);
        }
      }
    }
    return 0;
  }

  getPstValueForAmount(amount: number): number {
    if (this.applyPstBoolean) {
      return amount * this.pstRate / 100;
    }
    return 0;
  }

  isNotValidDate(dt: string): boolean {
    return Utils.isNotValidDate(dt);
  }

  getDateDiff(from: string, to: string): number {
    return Utils.getDateDiff(from, to);
  }
}
