import {Component, Inject, ViewChild} from '@angular/core';
import {Matter} from '../../shared';
import {Referral} from '../../shared/referral/referral';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ModalResult} from '../../../shared-main/enums';
import {
  UnitConnectDataPropagation,
  UnitConnectDataPropagationCmdType
} from '../../purchaser/cirf/cirf-import-data/unit-connect-data-propagation';
import {ReferralImportMatterDataComponent} from './referral-import-matter-data.component';
import {ReferralImportPartiesComponent} from './referral-import-parties.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';


class ReferralImportContainerModalContext {
  matter: Matter;
  referral: Referral;
}

@Component({
  selector: 'dp-referral-import-container-modal-content',
  templateUrl: 'referral-import-container.modal.component.html',
  providers: [ ErrorService ]
})

export class ReferralImportContainerModal extends ModalComponent<ReferralImportContainerModalContext> {
  originalMatter: Matter; //copy of original matter used to revert changes during import if needed when user "unchecks" a field. Will be injected into referral import tabs.
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild(ReferralImportMatterDataComponent) referralImportMatterDataComponent: ReferralImportMatterDataComponent;
  @ViewChild(ReferralImportPartiesComponent) referralImportPartiesComponent: ReferralImportPartiesComponent;

  selectedTabIndex: number = 0;
  tabsList: string[] = [];
  tabKeys = {
    dealDataTab: 'Deal Data',
    partiesTab: 'Parties',
    referrerTab: 'Referrer',
    notesTab: 'Notes',
    attachmentsTab: 'Attachments'
  };

  referralDataPropagation: UnitConnectDataPropagation;

  constructor(public dialog: MatDialogRef<ReferralImportContainerModal>,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) context?: ReferralImportContainerModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.originalMatter = new Matter(this.matter);
    this.referralDataPropagation = new UnitConnectDataPropagation();

    this.tabsList = [ this.tabKeys.dealDataTab, this.tabKeys.partiesTab, this.tabKeys.referrerTab, this.tabKeys.notesTab, this.tabKeys.attachmentsTab ];
    this.setTabReviewStatus(this.tabKeys.dealDataTab);
  }

  async ok(): Promise<void> {
    let notReviewedTabs = this.getNotReviewedTabs();
    if (!notReviewedTabs.length) {
      this.completeReferralImportAndClose();
    } else {
      let message = 'Are you sure you selected all relevant data from this referral?<br>The following tabs have not been reviewed yet:<br><br>';
      notReviewedTabs.forEach((tab, index) => {
        message += `${ tab }  <br>`;
      });
      let proceed: boolean = await this.dialogService.confirm('WARNING', message, false, null, null, true).toPromise();
      if (proceed) {
        this.completeReferralImportAndClose();
      }
    }
  }

  completeReferralImportAndClose(): void {
    if (this.referral.solicitorChanged) {
      this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_MP_SOLICITOR_CHANGE, null, true, this.matter.selectedSolicitorId);
    }

    if (this.isClosingDateDifferent()) {
      let newClosingDate: string = this.matter.matterCloseDate;
      //setting original closing date in matter so it can be updated through propagation instead.
      this.matter.matterCloseDate = this.originalMatter.matterCloseDate;
      this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_CLOSING_DATE, null, true, newClosingDate);
    }

    if (this.isDepositAmountDifferent()) {
      this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_DEPOSIT, null, true);
    }

    if (this.isAgreementSalePriceDifferent()) {
      this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_SALE_PRICE, null, true);
    }

    if (this.matter.isSale && this.isCommissionBeforeHstDifferent()) {
      this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_COMMISSION_BEFORE_HST_CHANGE, null, true);
    }

    if (Array.isArray(this.referral.selectedDocuments) && this.referral.selectedDocuments.length > 0) {
      this.referralDataPropagation.addToDataPropagationCommands(UnitConnectDataPropagationCmdType.REFERRAL_COPY_REFERRAL_DOCUMENT, null, true, this.referral, this.isDeferredAfterMatterSave());
    }

    if (this.isPurchaseIsOfCodeDifferent()) {
      this.matter.isPurchaseTypeValueChanged = true;
    }

    //If RealEstateAgent is not Dirty, unlock it.
    this.referralImportMatterDataComponent.releaseContactLocksIfNotDirty();

    this.dialog.close({
      action: ModalResult.OK,
      referralDataPropagation: this.referralDataPropagation
    });
  }

  isClosingDateDifferent(): boolean {
    return this.matter.matterCloseDate
      && (!this.originalMatter.matterCloseDate
        || (this.matter.matterCloseDate != this.originalMatter.matterCloseDate));
  }

  isDeferredAfterMatterSave(): boolean {
    return this.originalMatter.id <= 0;
  }

  isCommissionBeforeHstDifferent(): boolean {
    return this.matter.brokerCommission
      && (!this.originalMatter.brokerCommission
        || (this.matter.brokerCommission.commissionBeforeHst != this.originalMatter.brokerCommission.commissionBeforeHst));
  }

  isAgreementSalePriceDifferent(): boolean {
    return this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment
      && ((!this.originalMatter.considerationLtt || !this.originalMatter.considerationLtt.salePriceAdjustment)
        || this.matter.considerationLtt.salePriceAdjustment.agreementSalePrice != this.originalMatter.considerationLtt.salePriceAdjustment.agreementSalePrice);
  }

  isDepositAmountDifferent(): boolean {
    return this.matter.matterPropertyWithCondo
      && (!this.originalMatter.matterPropertyWithCondo
        || this.matter.matterPropertyWithCondo.depositAmount != this.originalMatter.matterPropertyWithCondo.depositAmount);
  }

  isPurchaseIsOfCodeDifferent(): boolean {
    return this.matter.matterPropertyWithCondo
      && (!this.originalMatter.matterPropertyWithCondo
        || this.matter.matterPropertyWithCondo.purchaseIsOfCode != this.originalMatter.matterPropertyWithCondo.purchaseIsOfCode);
  }

  close(): void {
    this.referralImportMatterDataComponent.releaseContactLocks();
    this.referralImportPartiesComponent.releaseContactLocks();
    this.dialog.close();
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get referral(): Referral {
    return this.context.referral;
  }

  updateTabReviewStatus(event): void {
    this.selectedTabIndex = event.index;
    this.setTabReviewStatus(this.tabsList[ this.selectedTabIndex ]);
  }

  setTabReviewStatus(tabKey: string): void {
    if (!this.referral.reviewStatus.find(key => key == tabKey)) {
      this.referral.reviewStatus.push(tabKey);
    }
  }

  getNotReviewedTabs(): string[] {
    let nonReviewedTabs = [];
    this.tabsList.forEach((tab) => {
      if (!this.referral.reviewStatus.find(key => key == tab)) {
        nonReviewedTabs.push(tab);
      }
    });
    return nonReviewedTabs;
  }

  isTabReviewed(tabKey: string): boolean {
    return !!this.referral.reviewStatus.find(key => key == tabKey);
  }

}
