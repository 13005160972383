<form class="container-fluid form-horizontal" *ngIf="mortgagePayout">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1 dp-province-field-label="matter.mortgage.mortgagee.mortgagePayout.calculation" [label-province-code]="provinceCode">
                    Mortgage Payout Calculation</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body" style="min-height: 400px">
        <div class="form-group col-lg-12" *ngIf="!matter.isTemplateMatterForMassUpdate">
            <dp-checkbox fieldId="useCalculatorField" [hasTabIndex]="true"
            ><input type="checkbox" id="useCalculatorField"
                    [checked]="mortgagePayout.useMortgagePayoutCalculator"
                    (click)="updateMortgagePayoutCheckBox()"/>
            </dp-checkbox>
            <label class="control-label vertical-align-label display-inline" for="useCalculatorField"
               dp-province-field-label="matter.mortgage.mortgagee.mortgagePayout.checkMortgagePayoutCalculator" [label-province-code]="provinceCode">
                Check this box to use mortgage payout calculator</label>
        </div>
        <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate && !matter.isMatterProvinceNBorNS">
            <div class="form-group col-lg-12"  *dpShowByProvince="payoutAmountCheckBoxKey;matterType:matterType;code:provinceCode">
                <dp-checkbox fieldId="payoutEqualsTrustBalanaceField" [hasTabIndex]="true"
                ><input type="checkbox"
                        id="payoutEqualsTrustBalanaceField"
                        name="payoutEqualsTrustBalanaceField"
                        [(ngModel)]="mortgagePayout.payoutEqualsTrustBalance"
                        [checked]="mortgagePayout.payoutEqualsTrustBalance"
                        [disabled]="!allowPayoutEnableEqualsTrustBalanceOption(mortgagePayout)"
                        (click)="updatePayoutEqualsTrustBalanceCheckBox()"/>
                </dp-checkbox>
                <label class="control-label vertical-align-label display-inline"
                       *ngIf="!isSameAsTrustLedgerBalanceCheckBoxDisplayed"
                       for="payoutEqualsTrustBalanaceField"
                       dp-province-field-label="matter.mortgage.mortgagee.mortgagePayout" [label-province-code]="provinceCode">
                    Check the box if payout amount is equal to the "Balance of Funds" on the Trust Ledger</label>
                <label class="control-label vertical-align-label display-inline"
                       for="payoutEqualsTrustBalanaceField"
                       *ngIf="isSameAsTrustLedgerBalanceCheckBoxDisplayed">
                    Check the box if payout amount is equal to the "Balance of Funds" on the Trust Ledger</label>
            </div>
        </ng-container>


        <ng-container *ngIf="!mortgagePayout.useMortgagePayoutCalculator">
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-3" dp-province-field-label="matter.mortgage.mortgagee.mortgagePayout.amountPayableToDischarge" [label-province-code]="provinceCode">
                    Amount payable to discharge this mortgage
                </label>
                <div class="col-lg-2">
                    <input type="text"
                           [(ngModel)]="mortgagePayout.amountPayableToDischarge"
                           name="payableAmount"
                           dp-currency
                           [dp-read-only]="isAmountPayableToDischargeFieldReadOnly()"
                           class="form-control text-right"/>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-3">
                    Inclusive of interest to
                </label>
                <div class="col-lg-4">
                    <dp-partial-date #interestDate
                                     [dateParent]="'mortgagePayout'"
                                     [dateFieldName]="'inclusiveOfInterestToDate'"
                                     [inputDate]="mortgagePayout.inclusiveOfInterestToDate"
                                     [dateFormat]="'MMM DD YYYY'"
                                     (dateChange)="onDateChange($event , 'inclusiveOfInterestToDate')"
                                     [fieldKey]="'mortgage.mortgagePayout.interestDate'"
                                     [customF9ExternalDate]="matterCloseDateParts"
                                     dp-error-validator>
                    </dp-partial-date>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="mortgagePayout.useMortgagePayoutCalculator">
            <div class="col-lg-12 fields-group  margin-top-0 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Principal and Interest</span>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-3">
                    Principal as at
                </label>
                <div class="col-lg-3">
                    <dp-partial-date #principalAt
                                     [dateParent]="'mortgagePayout'"
                                     [dateFieldName]="'principalDate'"
                                     [inputDate]="mortgagePayout.principalDate"
                                     [dateFormat]="'MMM DD YYYY'"
                                     (dateChange)="onDateChange($event , 'principalDate')"
                                     [fieldKey]="'mortgage.mortgagePayout.principalAt'"
                                     [customF9ExternalDate]="matterCloseDateParts"
                                     >
                    </dp-partial-date>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           [(ngModel)]="mortgagePayout.principalAmount"
                           name="principalAmount"
                           dp-currency
                           class="form-control text-right"/>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label text-right">
                        {{getFormattedCurrencyValue(mortgagePayout.principalAmount)}}
                    </label>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-3">
                    Plus
                </label>
                <div class="col-lg-2">
                    <select class="form-control"
                            name="plusOption" id="plusOption"
                            [(ngModel)]="mortgagePayout.mortgageInterestType"
                    >
                        <option *ngFor="let item of calculationPeriodOptions" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
                <div *ngIf="mortgagePayout.isPlusOptionPerDiem()">
                    <div class="col-lg-2">
                        <input type="text"
                               [(ngModel)]="mortgagePayout.interestPerDay"
                               name="plusPayment"
                               dp-currency
                               class="form-control text-right"/>
                    </div>
                    <div class="col-lg-2">
                        <label class="control-label text-left">
                            per day
                        </label>
                    </div>
                </div>
                <div *ngIf="!mortgagePayout.isPlusOptionPerDiem()">
                    <div class="col-lg-2">
                        <input type="text"
                               [(ngModel)]="mortgagePayout.interestPerAnnum"
                               dp-percentage
                               max-decimals = 5
                               name="plusPayment"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-2">
                        <label class="control-label text-left">
                            % per annum
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <label class="control-label col-lg-3">
                    {{formattedDate(mortgagePayout.principalDate)}}
                </label>
                <div class="col-lg-3">
                    <dp-partial-date #principalTo
                                     [inputDate]="mortgagePayout.perDiemCalculationPeriod"
                                     [dateFormat]="'MMM DD YYYY'"
                                     (dateChange)="onDateChange($event , 'perDiemCalculationPeriod')"
                                     [fieldKey]="'mortgage.mortgagePayout.principalTo'"
                                      >
                    </dp-partial-date>
                </div>
                <label class="control-label col-lg-2 text-left" *ngIf="mortgagePayout.perDiemCalculationPeriod && mortgagePayout.principalDate">
                    ({{mortgagePayout.calculateDateDiff()}} days)
                </label>
                <div class="col-lg-2  text-right" *ngIf="mortgagePayout.perDiemCalculationPeriod && mortgagePayout.principalDate">
                    <label class="control-label text-right" *ngIf="!mortgagePayout.isPlusOptionPerDiem()">
                        {{getFormattedCurrencyValue(mortgagePayout.calculateInterest())}}
                    </label>
                    <label class="control-label text-right" *ngIf="mortgagePayout.isPlusOptionPerDiem()">
                        {{getFormattedCurrencyValue(mortgagePayout.calculateInterestPerDiem())}}
                    </label>

                </div>
            </div>
            <div class="col-lg-12 fields-group  margin-top-0 margin-bottom-0" *dpShowByProvince="'matter.mortgage.existingMortgage.dischargeFeeSection';matterType:matterType;code:provinceCode">
                <div class="form-group main-level">
                    <span class="title min-height-30">Discharge Fee</span>
                </div>
            </div>
        </ng-container>

        <div class="form-group col-lg-12" *dpShowByProvince="'matter.mortgage.existingMortgage.separateChequeDischargeFee';matterType:matterType;code:provinceCode">
            <label class="control-label col-lg-3">
                Separate cheque for the Discharge Fee?
            </label>
            <div class="col-lg-2">
                <select class="form-control"
                        name="separateDischargeFeeCalc" id="separateDischargeFeeCalc"
                        [(ngModel)]="mortgagePayout.separateChequeForDischargeFee"
                        (change)="separateChequeForDischargeFeeChanged()"
                >
                    <option *ngFor="let item of yesNoItemsRegularPayments" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
            <div *ngIf="mortgagePayout.isDischargeFeeApplied() || mortgagePayout.useMortgagePayoutCalculator">
                <label class="control-label col-lg-3">
                    Amount
                </label>
                <div class="col-lg-2">
                    <input type="text"
                           [(ngModel)]="mortgagePayout.dischargeAmount"
                           name="payableAmountCalc"
                           id="payableAmountCalc"
                           dp-currency
                           class="form-control text-right"/>
                </div>
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="mortgagePayout.isDischargeFeeApplied()">
            <label class="control-label col-lg-3">
                Cheque Payable to
            </label>
            <div class="col-lg-7">
                <input type="text"
                       [(ngModel)]="mortgagePayout.chequePayableTo"
                       name="chequePayableCalc"
                       statusBarHelp
                       maxlength="75"
                       id="chequePayableCalc"
                       class="form-control" [dynamicHelpCallBackFunction]="generateF9HelpForChequePayableTo()"
                       (keydown.f9)="onChequePayableToF9()" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="isInsertSeparateChequeTLVisible()">
            <div class="col-lg-9 offset-lg-3">
                <div>
                    <dp-checkbox fieldId="insertSeparateChequeTL">
                        <input id="insertSeparateChequeTL"
                               name="insertSeparateChequeTL"
                               type="checkbox"
                               [(ngModel)]="mortgagePayout.insertSeparateChequeTL"
                        />
                    </dp-checkbox>
                    <label class="control-label padding-left-10 display-inline" for="insertSeparateChequeTL" >
                        Automatically insert/update Separate cheque for the Discharge Fee item into Trust Ledger<br>
                        <span class="margin-left-30">(Unchecking the field will remove the Separate cheque for the Discharge Fee from the Trust Ledger)</span>
                    </label>
                </div>
            </div>
        </div>
        <ng-container *ngIf="mortgagePayout.useMortgagePayoutCalculator">
            <div class="col-lg-12 fields-group  margin-top-0 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Additional Amount</span>
                </div>
            </div>
            <div *ngFor="let item of mortgagePayout.mortgagePayoutAdditionalPayments; let i = index">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-3">
                        Additional amount
                    </label>
                    <div class="col-lg-3">
                        <input type="text"
                               [(ngModel)]="item.additionalExplanation"
                               name="amountDescription-{{i}}"
                               maxlength="50"
                               id="amountDescription-{{i}}"
                               class="form-control"/>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                               [(ngModel)]="item.additionalAmount"
                               name="amoun-{{i}}"
                               id="amount-{{i}}"
                               dp-currency  [allow-negative]="true"
                               [fieldKey]="item.additionalAmount<0 ?'mortgagePayoutModal.total':''"
                               dp-error-validator
                               class="form-control text-right"/>
                    </div>
                    <div class="col-lg-2  text-right" >
                        <label class="control-label text-right">
                            {{getFormattedCurrencyValue(item.additionalAmount)}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-12" style="border-bottom: 2px solid #cccccc;border-top: 2px solid #cccccc;padding-bottom: 10px;">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-8">
                        Total:
                    </label>
                    <label class="control-label col-lg-2">
                        {{getFormattedCurrencyValue(mortgagePayout.total)}}
                    </label>
                </div>
                <div class="form-group col-lg-12" *ngIf="mortgagePayout.isDischargeFeeApplied()">
                    <label class="control-label col-lg-8">
                        Plus discharge fee of:
                    </label>
                    <label class="control-label col-lg-2">
                        {{getFormattedCurrencyValue(mortgagePayout.dischargeAmount)}}
                    </label>
                </div>
            </div>
        </ng-container>
    </div>
            <div class="modal-footer">
                <div class="row">
                <div class="btn2">
                    <button type="button"
                            id="okBtn"
                            (click)="ok()"
                            class="dp-btn">
                        <span>OK</span>
                    </button>
                    <button type="button"
                            (click)="close()"
                            class="dp-btn dp-btn-cancel">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
            </div>

    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</form>




