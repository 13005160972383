import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
// import { currentMatter } from './../../shared/current-matter';
import {dropDowns} from './../../shared/matter-drop-downs';
import {NgForm} from '@angular/forms';
import {MatterParticipant} from '../../shared/matter-participant';
import {FamilyLawAct, FlaData} from '../../shared/fla-data';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {DialogService} from '../../../shared/dialog/dialog.service';
// import {ConsentingSpouseIdModal} from "./consenting-spouse-id-verification/consenting-spouse-id.modal.component";
import {Matter} from '../../shared/matter';
// import { NameOfSpouseModal } from './consenting-spouse-id-verification/name-of-spouse-modal.component';
import {FlaErrorValues} from '../../../shared-main/constants';
import {MatterParticipantService} from '../../matter-participant-service';

@Component({
  selector: 'dp-familyLawAct-common',
  templateUrl: 'family-law-act.component.html',
  styleUrls: [
    './family-law-act.component.scss'
  ]
  // providers: [FlaService],
})

export class FamilyLawActComponent implements OnInit {
  @Input() shouldCreateSourceContact: boolean;
  @Input() matter: Matter;
  @Input() participant: MatterParticipant;
  @Input() spouseNameOptions: any[];
  @Input() flaStatements: FlaData;
  @Input() flaReadOnly: boolean;
  @Input() fieldPrefix: string;
  @Input() fieldIndexId: string;
  @Input() fieldIdentifier: string = '';
  @Input() containerId: string;
  @Input() matterTypeSensitive: boolean = false;
  @Input() clientType: string;
  @Input() isConsentSpouseModalVisible: boolean;

  @Output() saveFormFla: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('familyLawActForm') familyLawActForm: NgForm;

  flaSpouseNotAllowedFieldKey: string = FlaErrorValues.flaSpouseNotAllowedFieldKey;
  flaSpouseNotAllowedFieldKeyNB: string = FlaErrorValues.flaSpouseNotAllowedFieldKeyNB;
  spouseOwnerNotAllowedFieldKey: string = FlaErrorValues.spouseOwnerNotAllowedFieldKey;
  flaSpouseNameRequiredFieldKey: string = FlaErrorValues.flaSpouseNameRequiredFieldKey;
  flaSpouseNameRequiredFieldKeyNB: string = FlaErrorValues.flaSpouseNameRequiredFieldKeyNB;
  spouseOwnerNameRequiredFieldKey: string = FlaErrorValues.spouseOwnerNameRequiredFieldKey;
  flaMatterParticipantSpouseFieldId = FlaErrorValues.flaMatterParticipantSpouseFieldId;
  flaSpouseNameFieldId = FlaErrorValues.flaSpouseNameFieldId;
  genders: any[] = dropDowns.flaNameOfConsentedSpouseGenderType;
  fieldSpouseNameKey: string;

  public closedSpouseNameHint: boolean;
  public closedSingleSpouseNameHint: boolean;
  public closedParticipantSurname: boolean;

  constructor(public errorService: ErrorService,
              public dialogService: DialogService,
              public matterParticipantService: MatterParticipantService,
              public elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.fieldSpouseNameKey = this.fieldPrefix + '.matterParticipantSpouseNotAllowed_matterParticipantSpouse_' + this.fieldIdentifier;

    this.fieldIdentifier = this.fieldIdentifier ? this.fieldIdentifier : `${ this.participant.matterParticipantId }`;
    this.closedSpouseNameHint = false;
    this.closedSingleSpouseNameHint = false;
    this.closedParticipantSurname = false;
  }

  ngAfterViewInit() {
    this.familyLawActForm.valueChanges.subscribe(this.onValueChanges);
    if (this.isConsentSpouseModalVisible) {
      //this.openConsentedSpouseModal();
    }
  }

  //It is for current participant
  uncheckAllFlaExceptOtherAndCurrentItem(statementType: string): void {
    this.uncheckAllFlaExceptOtherAndCurrentItemForParticipant(this.participant, statementType);
  }

  get flaSpouseNameRequiredMsgKey(): string {
    switch (this.matter.provinceCode) {
      case 'NB':
        return this.fieldPrefix + this.flaSpouseNameRequiredFieldKeyNB;
      case 'SK':
      case 'MB':
        return this.fieldPrefix + this.spouseOwnerNameRequiredFieldKey;
      default:
        return this.fieldPrefix + this.flaSpouseNameRequiredFieldKey;
    }
  }

  get flaSpouseNotAllowedMsgKey(): string {
    switch (this.matter.provinceCode) {
      case 'NB':
        return this.fieldPrefix + this.flaSpouseNotAllowedFieldKeyNB;
      case 'SK':
      case 'MB':
        return this.fieldPrefix + this.spouseOwnerNotAllowedFieldKey;
      default:
        return this.fieldPrefix + this.flaSpouseNotAllowedFieldKey;
    }
  }

  addFlaSpouseNotAllowedError() {
    const fieldId = this.fieldIdentifier != null ? this.flaSpouseNameFieldId + this.fieldIdentifier : 'spouseName';
    this.errorService.addDpFieldError(DPError.createDPError(this.flaSpouseNotAllowedMsgKey, fieldId, this.containerId, null, this.fieldIndexId + '_' + this.fieldIdentifier));
  }

  removeFlaSpouseNotAllowedMError() {
    this.errorService.removeDpFieldError(this.flaSpouseNotAllowedMsgKey, this.flaSpouseNameFieldId + this.fieldIdentifier);
  }

  uncheckAllFlaExceptOtherAndCurrentItemForParticipant(participant: MatterParticipant, statementType: string): void {
    //If it is unselecting consent spouse check box and consent spouse participant exists, we remove this consenting spouse
    let familyLawAct: FamilyLawAct = participant.familyLawActs.find((fla: FamilyLawAct) => {
      return fla.familyLawActStatementType !== statementType
        && this.matter.getConsentSpouseParticipantByParentParticipantId(participant.matterParticipantId)
        && participant.isConsentedSpouse(fla);
    });
    if (familyLawAct) {
      this.matterParticipantService.removeConsentingSpouse(familyLawAct.consentedSpouseParticipant, this.matter);
    }
    this.uncheckAllFlaExceptOtherAndCurrentItemForParticipantWithoutConfirm(participant, statementType);

  }

  uncheckAllFlaExceptOtherAndCurrentItemForParticipantWithoutConfirm(participant: MatterParticipant, statementType: string): void {
    // let deleteFamilyLawActs : string[] = [];
    if (Array.isArray(participant.familyLawActs)) {
      participant.familyLawActs.slice(0).forEach((fla: FamilyLawAct) => {
        if (fla.familyLawActStatementType === 'MATTER_PARTICIPANT_SPOUSE' && fla.familyLawActStatementType !== statementType) {
          // this.errorService.removeDpFieldError(this.flaSpouseNotAllowedMsgKey, this.flaMatterParticipantSpouseFieldId + this.fieldIdentifier);
          this.removeFlaSpouseNotAllowedMError();
          this.removeFlaSpouseNameRequiredError();
        }
        if (this.matter.isMatterProvinceMB) {
          if (fla.familyLawActStatementType !== statementType) {
            if (fla.familyLawActStatementType === 'MATTER_PARTICIPANT_SPOUSE') {
              const currentSpouse: MatterParticipant = this.matter.getMatterParticipantByFullNameExcludingSelected(this.participant.spouseParticipantId, this.participant);
              if (currentSpouse) {
                currentSpouse.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
              }
              participant.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
            }
            participant.deleteFamilyLawAct(fla.familyLawActStatementType);
          }

        } else {
          if (fla.familyLawActStatementType !== 'OTHER' && fla.familyLawActStatementType !== statementType) {
            if (fla.familyLawActStatementType === 'MATTER_PARTICIPANT_SPOUSE' && !this.skipDeleteFamilyLawActParticipantSpouse(participant, fla, statementType)) {
              const currentSpouse: MatterParticipant = this.matter.getMatterParticipantByFullNameExcludingSelected(this.participant.spouseParticipantId, this.participant);
              if (currentSpouse) {
                currentSpouse.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
              }
              participant.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
            }
            if (!this.skipDeleteFamilyLawActNotSpouse(fla, statementType)
              && !this.skipDeleteFamilyLawActParticipantSpouse(participant, fla, statementType)
              && !this.skipDeleteFamilyLawActConsentedSpouse(participant, fla, statementType)) {
              participant.deleteFamilyLawAct(fla.familyLawActStatementType);
            }

          }
        }

      });

    }
  }

  // For Ontario, we support Multi-select for 'AUTHORIZED_BY_COURT' and 'NOT_SPOUSE'
  //So skip removing
  skipDeleteFamilyLawActNotSpouse(fla: FamilyLawAct, statementType: string): boolean {
    return (this.matter.isMatterProvinceON &&
      ((fla.familyLawActStatementType === 'AUTHORIZED_BY_COURT' && statementType === 'NOT_SPOUSE')
        || (fla.familyLawActStatementType === 'NOT_SPOUSE' && statementType === 'AUTHORIZED_BY_COURT')));
  }

  // For Ontario, we support Multi-select for 'MATTER_PARTICIPANT_SPOUSE' && "is not 'NOT_SPOUSE'" && "is not 'CONSENTED_SPOUSE'"
  //So skip removing
  skipDeleteFamilyLawActParticipantSpouse(participant: MatterParticipant, fla: FamilyLawAct, statementType: string): boolean {
    if (!participant || !fla || !this.matter) {
      return false;
    }

    let selectParticipantSpouse: boolean
      = statementType === 'MATTER_PARTICIPANT_SPOUSE'
      && ((fla.familyLawActStatementType !== 'NOT_SPOUSE') && (fla.familyLawActStatementType !== 'CONSENTED_SPOUSE'));
    let ownParticipantSpouse: boolean
      = fla.familyLawActStatementType === 'MATTER_PARTICIPANT_SPOUSE'
      && ((statementType !== 'NOT_SPOUSE') && (statementType !== 'CONSENTED_SPOUSE'));

    return this.matter.isMatterProvinceON && (selectParticipantSpouse || ownParticipantSpouse);
  }

  // For Ontario, we support Multi-select for 'CONSENTED_SPOUSE' && "is not 'NOT_SPOUSE'" && "is not 'MATTER_PARTICIPANT_SPOUSE'"
  //So skip removing
  skipDeleteFamilyLawActConsentedSpouse(participant: MatterParticipant, fla: FamilyLawAct, statementType: string): boolean {
    if (!participant || !fla || !this.matter) {
      return false;
    }

    let selectParticipantSpouse: boolean
      = statementType === 'CONSENTED_SPOUSE'
      && ((fla.familyLawActStatementType !== 'NOT_SPOUSE') && (fla.familyLawActStatementType !== 'MATTER_PARTICIPANT_SPOUSE'));
    let ownParticipantSpouse: boolean
      = fla.familyLawActStatementType === 'CONSENTED_SPOUSE'
      && ((statementType !== 'NOT_SPOUSE') && (statementType !== 'MATTER_PARTICIPANT_SPOUSE'));

    return this.matter.isMatterProvinceON && (selectParticipantSpouse || ownParticipantSpouse);
  }

  get isSinglePurchase(): boolean {
    return !Array.isArray(this.spouseNameOptions)
      || this.spouseNameOptions.length === 0
      || (this.spouseNameOptions.length === 1 && !this.spouseNameOptions[ 0 ].value);
  }

  getIsSingleItemAlert(): string {
    let alert = 'Cannot be selected with only one Purchaser';
    if (this.participant.matterParticipantRole === 'VENDOR') {
      alert = 'Cannot be selected with only one Vendor';
    }
    return alert;
  }

  public getDescription(itemName: string): string {
    // console.log("flaItem", flaItem );
    let flaTypeDescription: string = '';
    if (this.flaStatements && this.flaStatements.getFlaStatementByTypeCode(itemName)) {
      flaTypeDescription = this.flaStatements.getFlaStatementByTypeCode(itemName).flaTypeDescription;
    }
    return flaTypeDescription;
  }

  disableStatement(typeCode: string): boolean {
    return this.flaStatements
      ? this.flaStatements.getMaskingStatementTypes(typeCode).some((v: string) => this.participant.getFamilyLawActStatus(v))
      : false;
  }

  onSpouseChange(hasSpouse: boolean, statementType: string): void {
    if (hasSpouse) {
      this.participant.matterParticipantSpouse = true;

    } else {
      const currentSpouse: MatterParticipant = this.matter.getMatterParticipantByFullNameExcludingSelected(this.participant.spouseParticipantId, this.participant);
      if (currentSpouse) {
        currentSpouse.deleteSpouse();
      }
      this.participant.deleteSpouse();
    }
    this.uncheckAllFlaExceptOtherAndCurrentItem(statementType);

    if (this.isSinglePurchase) {
      // const fieldKey : string = this.flaSpouseNotAllowedMsgKey;
      // const fieldId = this.fieldIdentifier != null ? this.flaMatterParticipantSpouseFieldId + this.fieldIdentifier : 'matterParticipantSpouse';

      if (hasSpouse) {
        // const dpError : DPError = DPError.createDPError(fieldKey, fieldId , this.containerId  , null , this.fieldIndexId+'_'+ this.fieldIdentifier);
        // this.errorService.addDpFieldError(dpError);
        this.addFlaSpouseNotAllowedError();
      } else {
        // this.errorService.removeDpFieldError(fieldKey, fieldId);
        this.removeFlaSpouseNotAllowedMError();
      }
    } else {
      if (hasSpouse && !this.participant.spouseParticipantId) {
        this.addFlaSpouseNameRequiredError();
      } else {
        this.removeFlaSpouseNameRequiredError();
      }
    }
  }

  /**
   *  addFlaSpouseNameRequiredError can be used for participant or spouse participant. They only have different matterParticipantId.
   * @param matterParticipantId
   */
  addFlaSpouseNameRequiredError(matterParticipantId?: number) {
    const fieldIdentifier: string = matterParticipantId ? (matterParticipantId + '') : this.fieldIdentifier;
    const fieldKey: string = this.flaSpouseNameRequiredMsgKey;//this.fieldPrefix + this.flaSpouseNameRequiredFieldKey;
    const fieldId = fieldIdentifier != null ? this.flaSpouseNameFieldId + fieldIdentifier : 'spouseName';
    const dpError: DPError = DPError.createDPError(fieldKey, fieldId, this.containerId, null, this.fieldIndexId + '_' + fieldIdentifier);
    this.errorService.addDpFieldError(dpError);
  }

  /**
   *  removeFlaSpouseNameRequiredError can be used for participant or spouse participant. They only have different matterParticipantId
   * @param matterParticipantId
   */
  removeFlaSpouseNameRequiredError(matterParticipantId?: number) {
    const fieldIdentifier: string = matterParticipantId ? (matterParticipantId + '') : this.fieldIdentifier;
    const fieldKey: string = this.flaSpouseNameRequiredMsgKey;//this.fieldPrefix + this.flaSpouseNameRequiredFieldKey;
    const fieldId = fieldIdentifier != null ? this.flaSpouseNameFieldId + fieldIdentifier : 'spouseName';
    this.errorService.removeDpFieldError(fieldKey, fieldId);
  }

  onSpouseNameChange(spouseParticipantId: number): void {
    // Event passes string, convert it to number
    spouseParticipantId = +spouseParticipantId;
    //Only do something if the spouse changed
    const currentSpouse: MatterParticipant = this.matter.getMatterParticipantByFullNameExcludingSelected(this.participant.spouseParticipantId, this.participant);
    if (currentSpouse) {
      //The old spouse gets left behind if our participant gets a new one. Sad...
      currentSpouse.deleteSpouse();
    }

    const newSpouse: MatterParticipant = this.matter.getMatterParticipantByFullNameExcludingSelected(spouseParticipantId, this.participant);
    if (newSpouse) {
      if (newSpouse.spouseParticipantId) {
        const theThirdOne: MatterParticipant = this.matter.getMatterParticipantByFullNameExcludingSelected(newSpouse.spouseParticipantId, this.participant);
        if (theThirdOne) {
          theThirdOne.deleteSpouse();
        }
      }
      // this.errorService.removeDpFieldError(this.flaSpouseNotAllowedMsgKey,
      //                                      this.fieldIdentifier != null ? this.flaMatterParticipantSpouseFieldId + newSpouse.matterParticipantId : 'matterParticipantSpouse');
      this.removeFlaSpouseNotAllowedMError();

      this.removeFlaSpouseNameRequiredError();

      if (this.participant.getFamilyLawActStatus('MATTER_PARTICIPANT_SPOUSE')) {
        this.uncheckAllFlaExceptOtherAndCurrentItemForParticipant(newSpouse, 'MATTER_PARTICIPANT_SPOUSE');
        this.participant.setSpouseParticipant(newSpouse);
      }

    } else {
      this.participant.spouseParticipantId = undefined;
    }

    if (!this.isSinglePurchase) {
      if (this.participant.spouseParticipantId) {
        this.removeFlaSpouseNameRequiredError();
      } else {
        this.addFlaSpouseNameRequiredError();
      }
    }
  }

  public onConsentedSpouseChange(isConsentedSpouse: boolean, statementType: string) {
    //If it is unselecting check box and consent spouse participant exists, we need provide the confirm dialog
    let consentedSpouseParticipant: MatterParticipant = this.matter.getConsentSpouseParticipantByParentParticipantId(this.participant.matterParticipantId);
    if (!isConsentedSpouse && consentedSpouseParticipant) {
      this.dialogService.confirm('Confirmation', this.participant.getConsentedSpouseConfirmMsg(statementType), false, 'Continue')
      .subscribe(res => {
        if (res == true) {
          this.onConsentedSpouseChangeAction(isConsentedSpouse, statementType);
        } else {
          // When roll back , the UI check box can't be set back selected status by this.participant.setFamilyLawActValueByStatementType(statementType, true);
          // Try to use consentedSpouseNgModel.valueAccessor.writeValue(true). It can set checkbox as selected status.
          // Unfortunately the uncheckAllFlaExceptOtherAndCurrentItem can't unselected consenting spouse fla if we select other fla
          // We have to use the following way to implement roll back
          this.participant.setFamilyLawActValueByStatementType(statementType, false);
          setTimeout(() => {
            this.participant.setFamilyLawActValueByStatementType(statementType, true);
            let fla: FamilyLawAct = this.participant.getFamilyLawAct(statementType);
            fla.consentedSpouseParticipant = consentedSpouseParticipant;
          }, 10);
        }
      });

    } else {
      this.onConsentedSpouseChangeAction(isConsentedSpouse, statementType);
    }
  }

  onConsentedSpouseChangeAction(isConsentedSpouse: boolean, statementType: string): void {
    this.uncheckAllFlaExceptOtherAndCurrentItem(statementType);

    if (isConsentedSpouse) {
      this.participant.setFamilyLawActValueByStatementType(statementType, true);
    } else {
      let fla = this.participant.getFamilyLawAct(statementType);
      this.matterParticipantService.removeConsentingSpouse(fla && fla.consentedSpouseParticipant, this.matter);
      this.participant.setFamilyLawActValueByStatementType(statementType, false);
    }
  }

  //Todo remove it
  // addConsentedSpouse(spouseGender: string): void {
  //     this.participant.addConsentedSpouse(spouseGender);
  // }

  public onValueChanges = (event: any): void => {
    this.updateFormSaveStatus();
  };

  public updateFormSaveStatus(): void {
    if (this.familyLawActForm.dirty) {
      this.saveFormFla.emit();
    }
  }

  public onDateValueChanges = (event: any): void => {
    this.saveFormFla.emit();
  };

  // F9 Capture on <select> elements of the page
  captureKeyPress(event) {
    let index: number;

    // toggle through values in spouseName drop-down box
    if (event.srcElement.id.startsWith('spouseName_') === true) {
      index = this.spouseNameOptions.map(m => m.value).indexOf(this.participant.spouseParticipantId);
      index = (index + 1) % this.spouseNameOptions.length;
      this.participant.setSpouse(this.spouseNameOptions[ index ].value);
    }
    // toggle through values in gender drop-down box
    if (event.srcElement.id.startsWith('gender_') === true) {
      index = this.genders.map(m => m.value).indexOf(this.participant.gender);
      index = (index + 1) % this.genders.length;
      this.participant.gender = this.genders[ index ].value;
    }
  }

}
