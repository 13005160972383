import {AccountingItemKeys} from '../../admin/soa/soa-constants';

export type LandRegistryType = 'NA' | 'REGISTRY' | 'LAND_TITLE';

export class SoaFeeRate {

  id: number;
  effectiveDate: string;
  description: string;
  key: null;
  fees: number;
  province: string;
  salePriceFactor: number;
  extraParcelFactor: number;
  landRegistryType: LandRegistryType;

  constructor(soaFeeRate?: SoaFeeRate) {
    if (soaFeeRate) {
      for (let prop in soaFeeRate) {
        if (soaFeeRate.hasOwnProperty(prop)) {
          this[ prop ] = soaFeeRate[ prop ];
        }
      }

    }

  }

  isERegServiceCharge(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY ]);
  }

  isRegistrationTransfer(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY ]);

  }

  isRegistrationCharge(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY ]);

  }

  isTitleRegistrationFee(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.TITLE_REGISTRATION_FEE_KEY ]);
  }

  isRegistrationOfMortgage(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ]);
  }

  isRegistrationOfMortgageDischarge(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ]);
  }

  isRegistrationDischargeOfOther(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ]);
  }

  isRegisterVendorsLienCaveat(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY ]);
  }

  isDischargeVendorsLienCaveat(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY ]);
  }

  isLandTitleSearches(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.LAND_TITLE_SEARCHES ]);
  }

  isTitleSearch(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.TITLE_SEARCH ]);
  }

  isMLTTConfiguration(): boolean {
    return (this.key == AccountingItemKeys[ AccountingItemKeys.HST_MLTT_ADMIN_FEE ]);
  }
}
