import {dropDowns} from '../../../matters/shared';
import {DropdownModel} from './../../../shared-main/dropdown-model';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Contact} from './../../shared';
import {MatterParticipant} from '../../shared/matter-participant';
import {MatterParticipantSigningLocation} from '../../shared/matter-participant-signingLocation';
import {Jurisdiction} from '../../shared/jurisdiction';
import {Subject} from 'rxjs';
import {Utils} from '../../shared/utils';
import {JurisdictionService} from '../../property-teranet/jurisdiction.service';
import {currentMatter} from '../../shared/current-matter';
import moment from 'moment';
import {Matter} from '../../shared/matter';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Address} from '../../shared/address';
import {ContactService} from '../../../shared-main/contact.service';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {Logger} from '@nsalaun/ng-logger';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import {ContactName} from '../../shared/contact-name';
import {TitleDeed} from '../../shared/title-deed';
import {DropdownModule} from 'primeng/dropdown';

@Component({
  selector: 'dp-snapshotProfile',
  templateUrl: 'snapshot-profile.component.html',
  styleUrls: [
    './snapshot-profile.scss'
  ]
})

export class SnapshotProfileComponent implements OnInit {
  @Input() index: number;
  @Input() matterParticipant: MatterParticipant;
  @Input() purchaserExecDocsAt: string;
  @Input() matter: Matter;
  @Input() profileEditable: boolean;
  @Input() fieldPrefix: string;
  @Input() fieldIndexId: string;
  @Input() fieldIdentifier: string = '';
  @Input() containerId: string;
  @Output() primaryAddressChanged: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() onDataChange: EventEmitter<Address> = new EventEmitter<Address>();
  @Output() onSuggestionsChange: EventEmitter<Address> = new EventEmitter<Address>();

  // contact : Contact;
  estateStatus: DropdownModel[] = dropDowns.estate_Status;
  genderEstateCode: DropdownModel[] = dropDowns.gender_estate_code;
  corporateSealFlag: DropdownModel[] = dropDowns.corporateSealFlag;
  canadianResident: DropdownModule[] = dropDowns.canadianResident;

  // Auto complete
  // Jurisdiction
  // list of searched results based on input
  jurisdictions: any[][];
  searchTermJurisdiction: Subject<string>[];

  // model mapped to input field
  selectedJurisdiction: Jurisdiction[];

  // flag to display loading indicator while search is going on
  jurisdictionsLoading: boolean[] = [];

  // flag to display no result found if there is no data for input values

  // flag to control displaying of help text 'start typing...'
  jurisdictionAutoCompleteEmptyFlag: boolean[];

  titleDeedNameTypeOptions: DropdownModel[] = dropDowns.titleDeedNameTypeOptions;

  expanded: boolean = false;

  public utils: Utils;

  constructor(public fb: FormBuilder,
              public elementRef: ElementRef,
              public contactService: ContactService,
              public jurisdictionService: JurisdictionService,
              public dialogService: DialogService,
              public logger: Logger
  ) {
    this.utils = new Utils();
  }

  ngOnInit(): void {
    // this.submittedClient = false;
    // this.contact = this.matterParticipant.contact;
    // as for current user story addressTypeCode and primaryAddress hard
    // code form ui it will be dynamic in future user story.
    // this.resetForm();

    this.initializeSigningLocation();

    //initializeContactIdDetails should be called early to avoid trigger isDirty as true.
    if (this.contact) {
      this.contact.initializeContactIdDetails();
    }

  }

  get contact(): Contact {
    return this.matterParticipant && this.matterParticipant.contact;
  }

  public getGenderValue(v) {
    switch (v) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      case 'QUESTION':
        return '';
      default :
        return v;

    }
  }

  getFormattedDate(): string {
    return moment(this.contact.birthDate, 'YYYY/MM/DD').format('ddd MMM DD YYYY');
  }

  initializeSigningLocation(): void {
    const condition: boolean = !this.matterParticipant.signingLocations
      || (Array.isArray(this.matterParticipant.signingLocations)
        && this.matterParticipant.signingLocations.length === 0);
    switch (this.contact.gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
        if (condition) {
          this.matterParticipant.signingLocations.push(new MatterParticipantSigningLocation());
        }
        break;

      case 'CORPORATION':
      case 'OTHERENTITY':
      case 'MALEPOA':
      case 'FEMALEPOA':
      case 'ESTATE':
        if (condition && this.matter) {
          let child = this.matter.getChildMatterParticipants(this.matterParticipant);
          child.filter(item => item.isSigningOfficer).forEach(p => {
            let matterParticipantSigningLocation = new MatterParticipantSigningLocation();
            matterParticipantSigningLocation.signerMatterParticipantId = p.matterParticipantId;
            this.matterParticipant.signingLocations.push(matterParticipantSigningLocation);
          });
        }
        break;

      default :
        break;
    }

  }

  showPurchaserExecDocsAt(): string {
    let ret: string = '';
    if (this.purchaserExecDocsAt) {
      ret = '(' + this.purchaserExecDocsAt + ')';
    }
    return ret;
  }

  showLocationFieldSetLabel(): string {
    let label: string = '';
    switch (this.contact.gender) {
      case 'QUESTION':
      case 'MALE':
      case 'FEMALE':
        label = '';
        break;

      case 'CORPORATION':
      case 'OTHERENTITY':
        label = 'Signing Officer';
        break;

      case 'MALEPOA':
      case 'FEMALEPOA':
        label = 'Attorney';
        break;

      case 'ESTATE':
        label = 'Estate Trustee';
        break;

      default :
        label = '';
        break;
    }
    return label;
  }

  // autocomplete Jurisdiction
  jurisdictionHelpText(index: number): void {
    if (!this.selectedJurisdiction[ index ]) {
      this.searchTermJurisdiction[ index ].next('');
    }
  }

  // Code for omnibar search jurisdiction
  searchJurisdiction(index: number, event): void {
    let entered: string = event.query;
    if (event) {
      this.searchTermJurisdiction[ index ].next(entered);
    }

  }

  cleanJurisdiction(index: number, signingLocation: MatterParticipantSigningLocation): void {
    if ((this.selectedJurisdiction[ index ].toString()).trim() === '') {
      signingLocation.jurisdictionName = '';
    }
  }

  // On jurisdiction input data select event
  dataSelectedJurisdiction(index: number, signingLocation: MatterParticipantSigningLocation): void {

    const jurisdiction: Jurisdiction = this.selectedJurisdiction[ index ];
    if (jurisdiction == null || jurisdiction.id === undefined) {
      this.selectedJurisdiction[ index ] = undefined;
    } else {
      this.enableSave();
    }

    if (jurisdiction && jurisdiction.id) {
      signingLocation.jurisdictionName = jurisdiction.jurisdictionName;
      signingLocation.city = jurisdiction.city && jurisdiction.city.name;
      signingLocation.municipality = jurisdiction.city && jurisdiction.city.municipality && jurisdiction.city.municipality.name;
      signingLocation.provinceCode = jurisdiction.provinceCode;
    }
  }

  onChange(): void {
    this.enableSave();
  }

  enableSave(): void {
    if (currentMatter.value) {
      currentMatter.value.dirty = true;
    }
  }

  emitDataChange(event: Address) {
    this.onDataChange.emit(event);
    if (this.matterParticipant && this.matterParticipant.primary) {
      this.primaryAddressChanged.emit(event);
    }
    this.enableSave();
  }

  emitEnvelopeChange() {
    this.enableSave();
    this.onSuggestionsChange.emit();
  }

  onBlurJurisdiction(index: number, signingLocation: MatterParticipantSigningLocation) {
    setTimeout(() => {
      if (this.selectedJurisdiction[ index ] && typeof this.selectedJurisdiction[ index ] === 'string') {
        /*                this.selectedJurisdiction[index] = null;
                        signingLocation.city = null;
                        signingLocation.municipality = null;*/
        this.resetJurisdiction(index, signingLocation);
      }
    }, 200);
  }

  resetJurisdiction(index: number, signingLocation: MatterParticipantSigningLocation): void {
    this.selectedJurisdiction[ index ] = null;
    signingLocation.city = null;
    signingLocation.municipality = null;
    signingLocation.jurisdictionName = null;

  }

  //apply for ON only
  selectedJurisdictionHasMunicipalityValue(index: number): boolean {
    const jurisdiction: Jurisdiction = this.selectedJurisdiction[ index ];
    return jurisdiction
      && jurisdiction.city
      && jurisdiction.city.municipality
      && !this.utils.isEmptyString(jurisdiction.city.municipality.name);
  }

  get matterProvinceCode(): ProvinceCode {
    return currentMatter.value ? currentMatter.value.provinceCode : undefined;
  }

  isJurisdictionSelected(index: number): boolean {
    return this.selectedJurisdiction[ index ] && typeof this.selectedJurisdiction[ index ] != 'string';
  }

  showMunicipalityField(index: number): boolean {
    if (this.isJurisdictionSelected(index)) {
      //test whether the selected jurisdiction is Ontario Jurisdiction
      // console.log("selected Jurisdiction is from Province" + (this.selectedJurisdiction[index] && this.selectedJurisdiction[index].provinceCode))
      return this.selectedJurisdiction[ index ] && this.selectedJurisdiction[ index ].provinceCode == 'ON';
    } else {
      return true;
    }
  }

  deleteJurisdiction(index: number, signingLocation: MatterParticipantSigningLocation): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove the Jurisdiction and related fields from this matter?', false, 'Delete').subscribe(res => {
      if (res == true) {
        this.resetJurisdiction(index, signingLocation);
        this.enableSave();
        this.dialogService.setFocusOnAutocomplete('autocomplete-jurisdiction-' + index);
      }
    });
  }

  get jurisdictionPlaceHolderMsg(): string {
    return provinceBasedFieldLabels.get('matter.tabB.jurisdiction.placeHolderMsg', this.matterProvinceCode);
  }

  changeOutOfProvince(index: number, signingLocation: MatterParticipantSigningLocation): void {
    this.enableSave();
    if (this.matterParticipant.signingLocations[ index ].outOfProvince) {
      this.resetJurisdiction(index, signingLocation);
    }
  }

  isTitleDeedNameVisible(): boolean {
    if (this.titleDeedName && this.contact) {
      return this.contact.gender === 'QUESTION'
        || this.contact.gender === 'MALE'
        || this.contact.gender === 'FEMALE'
        || this.contact.gender === 'MALEPOA'
        || this.contact.gender === 'FEMALEPOA';
    }

    return false;
  }

  get contactName(): ContactName {
    return this.matterParticipant && this.matterParticipant.contact && this.matterParticipant.contact.contactName;
  }

  get titleDeedName(): TitleDeed {
    return this.matterParticipant && this.matterParticipant.titleDeed;
  }

  getTitleDeedName(): string {
    if (this.titleDeedName) {
      if (!this.titleDeedName.isManuallyEnter()) {
        return this.contactName ? this.contactName.fullName : '';
      } else {
        return this.titleDeedName.fullName;
      }
    }
    return '';
  }

  populateTitleDeedSurname() {
    if (this.titleDeedName && this.contactName) {
      this.titleDeedName.surName
        = this.contactName.lastName ? this.contactName.lastName.toUpperCase() : null;
    }
  }

  upperCaseTitleDeedSurname() {
    if (this.titleDeedName && this.titleDeedName.surName) {
      this.titleDeedName.surName = this.titleDeedName.surName.toUpperCase();
    }
    this.onChange();
  }

  //When select "Manually Enter" option, we force to open the titleDeed shutter
  openTdnShutterOnManualEnter() {
    if (this.titleDeedName.isManuallyEnter()) {
      this.expanded = true;
    }
    this.onChange();
  }

  // When select the title Deed Name type drop down, it should stop propagating click event.
  // When it is not manually enter, completely stop propagating click event.
  stopPropagateClickEvent(event): void {
    if (event.target instanceof HTMLSelectElement || !this.titleDeedName.isManuallyEnter()) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
