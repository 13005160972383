<div class="col-lg-12, form-horizontal" [dp-read-only-block]="isComplianceReadOnly()" [elementRefComp]="elementRef">

    <div class="col-lg-12 margin-top-minus-12" *ngIf="isComplianceReadOnly()">
        <p class="red">In order to mass update fields for the Compliance topic, a jurisdiction must first be selected in
            the Property topic.</p>
    </div>
    <div class="compliance-header col-lg-12" [ngClass]="{'margin-top-10': isComplianceReadOnly()}">
        Compliance (Search) Letters {{ matter.jurisdictionName ? 'for ': '' }}
        <a *ngIf="matter.jurisdictionName" (click)="openJurisdictionContact()">{{ matter.jurisdictionName }}</a>
        <div class="col-lg-6 vertical-align-label height-auto pull-right">
            <label class="control-label">
                No. of Unsatisfactory Compliances: {{unsatisfactoryCompliancesCount}}
            </label>
        </div>
    </div>

    <br>
    <table class="table standard-table compliance-table" [ngClass]="{'margin-top-10': isComplianceReadOnly()}">
        <thead>
        <tr>
            <th><div class="cell0"></div></th>
            <th><div class="cell1 padding-left-5">Department Name</div></th>
            <th><div class="cell2 padding-left-5">Write To</div></th>
            <th><div class="cell3 padding-left-5">Status</div></th>
            <th><div class="cell4-th padding-left-5">Remarks</div></th>
        </tr>
        </thead>
        <tbody class="xpath-parent-container">
        <tr *ngFor="let row of matter.compliances; let i=index" class="xpath-child-container">
            <td class="cell0">{{ i + 1 }} </td>
            <td>
                <div class="cell1">
                    <input type="text"
                           [(ngModel)]="row.departmentName"
                           name="departmentName{{i}}"
                           id="departmentName{{i}}"
                           [dp-read-only]="true"
                           class="form-control"
                    >
                </div>
            </td>
            <td>
                <div class="cell2">
                    <select class="form-control"
                            id="writeTo{{i}}"
                            name="writeTo{{i}}"
                            [(ngModel)]="row.writeTo"
                            (change)="onComplianceWriteToChange(row)"
                    >
                        <option *ngFor="let yesno of writeToOptions" [value]="yesno.value">
                            {{yesno.label}}
                        </option>
                    </select>
                </div>
            </td>
            <td>
                <div class="cell3">
                    <select class="form-control"
                            id="status{{i}}"
                            name="status{{i}}"
                            [(ngModel)]="row.status"
                            (change)="enableSave()"
                    >
                        <option *ngFor="let status of statusOptions" [value]="status.value">
                            {{status.label}}
                        </option>
                    </select>
                </div>
            </td>
            <td>
                <div class="cell4">
                    <input type="text"
                           [(ngModel)]="row.remarks"
                           dp-error-validator
                           maxlength="30"
                           id="remarks{{i}}"
                           name="remarks{{i}}"
                           class="form-control"
                           (keyup)="enableSave()"
                           trimmed
                    >
                </div>
            </td>

        </tr>
        </tbody>
    </table>
    <br>
    <dp-user-defined-fields [topicName] = "'COMPLIANCE'" (onChange)="enableSave()">
    </dp-user-defined-fields>
</div>
