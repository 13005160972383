import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import * as _ from 'lodash';
import {DocumentTemplateFile} from '../../matters/document-production/document-template';
import {ApplicationError, FieldError} from '../../core';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DocumentProfileService} from '../document-profile/document-profile-edit/document-profile.service';
import {SupplementalTaskCategory} from '../../shared-main/supplemental-task-category/supplemental-task-category';
import {CategoryTemplate} from '../../shared-main/supplemental-task-category/category-template';
import {ManageFolderTemplatesModalComponent} from '../document-profile/document-profile-edit/template-folder/manage-folder-templates-modal/manage-folder-templates.modal';
import {DialogService} from '../../shared/dialog/dialog.service';
import {DocumentProfileTemplateFolder} from '../document-profile/document-profile-edit/template-folder/document-profile-template-folder';
import {Account} from '../accounts/shared/account';

export class ManageMasterSupplementalTaskDocumentsModalComponentContext {
  supplementalTaskCategory: SupplementalTaskCategory;
  templateFolder: DocumentProfileTemplateFolder;
  account: Account;
}

@Component({
  selector: 'dp-manage-master-supplemental-task-category-modal',
  templateUrl: './manage-master-supplemental-task-documents.modal.component.html',
  providers: [ErrorService]
})
export class ManageMasterSupplementalTaskDocumentsModalComponent
  extends ModalComponent<ManageMasterSupplementalTaskDocumentsModalComponentContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  categoryName: string;
  notSelectedList: DocumentTemplateFile[] = [];
  selectedList: DocumentTemplateFile[] = [];

  selectedTemplateIDArr: number[] = [];
  /* This array hase 2 elements. element 0 for available ID (right side), Element 1 for selected ID (Left side)
  In case of multi-selection each element turns to an array of IDs for all highlighted items
  respectively for Left and Right side.
  */
  selectedTemplateIndex: number;
  tempCategoryTemplates: CategoryTemplate[];

  constructor(
    public dialog: MatDialogRef<ManageMasterSupplementalTaskDocumentsModalComponent>,
    public documentProfileService: DocumentProfileService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ManageMasterSupplementalTaskDocumentsModalComponentContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.retrieveDocumentFiles();
  }

  //Sort by the lower case fileName of DocumentTemplateFile
  sortList(DocumentTemplateFiles: DocumentTemplateFile[]): DocumentTemplateFile[] {
    return _.sortBy(DocumentTemplateFiles, (template: DocumentTemplateFile) => {
      return template.fileName.toLowerCase();
    });
  }

  retrieveDocumentFiles(): void {
    this.notSelectedList = [];
    this.selectedList = [];
    this.tempCategoryTemplates = this.context.supplementalTaskCategory.categoryTemplates;
    this.documentProfileService
      .getDocTemplateFiles(this.context.templateFolder.id)
      .subscribe((data: DocumentTemplateFile[]) => {
        data.forEach((file) => {
          if (
            !file.include &&
            file.documentTemplate &&
            file.documentTemplate.docGenTemplateId &&
            this.tempCategoryTemplates.find(
              (doc) => doc && doc.documentTemplateId && doc.documentTemplateId == file.documentTemplate.docGenTemplateId
            )
          ) {
            this.selectedList.push(file);
          } else if (!file.include && file.documentTemplate && file.documentTemplate.docGenTemplateId) {
            this.notSelectedList.push(file);
          }
        });
        //Sort by the lower case fileName of DocumentTemplateFile
        this.notSelectedList = this.sortList(this.notSelectedList);
      });
  }

  getTemplateByID(list: DocumentTemplateFile[], id: number): DocumentTemplateFile {
    let template: DocumentTemplateFile = list.find((temp) => {
      return temp.documentTemplate && temp.documentTemplate.docGenTemplateId == id;
    });
    return template;
  }

  get isDisableMoveRight(): boolean {
    return !this.selectedTemplateIDArr.length || (this.selectedTemplateIDArr.length && !this.selectedTemplateIDArr[0]);
  }

  get isDisableMoveLeft(): boolean {
    return !this.selectedTemplateIDArr.length || (this.selectedTemplateIDArr.length && !this.selectedTemplateIDArr[1]);
  }

  save() {
    this.updateSupplementalTaskCategoryTemplate();
    this.dialog.close('save');
  }

  changeSelectedTemplate(event) {
    console.log(event);
  }

  handleApiError(error: ApplicationError) {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        'documentProduction.categoryName',
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  delete() {
    let selectedTemplateIDs = this.selectedTemplateIDArr[1];
    if (Array.isArray(selectedTemplateIDs)) {
      selectedTemplateIDs.forEach((selectedTemplateID: number) => {
        (<any>this.selectedList).remove(
          this.selectedList.find((item) => {
            return item.documentTemplate.docGenTemplateId == selectedTemplateID;
          })
        );
      });
    }
  }

  moveDown() {
    let selectedTemplateIDs = this.selectedTemplateIDArr[1];
    if (Array.isArray(selectedTemplateIDs)) {
      selectedTemplateIDs
        .slice()
        .reverse()
        .forEach((selectedTemplateID: number) => {
          this.moveDownSingleItem(selectedTemplateID);
        });
    }
  }

  moveDownSingleItem(selectedTemplateID: number) {
    let oldIndex = this.selectedList.indexOf(
      this.selectedList.find((item) => {
        return item.documentTemplate.docGenTemplateId == selectedTemplateID;
      })
    );
    if (oldIndex != this.selectedList.length - 1) {
      let newIndex = oldIndex + 1;
      this.selectedList.splice(newIndex, 0, this.selectedList.splice(oldIndex, 1)[0]);
    }
  }

  moveUp() {
    let selectedTemplateIDs = this.selectedTemplateIDArr[1];
    if (Array.isArray(selectedTemplateIDs)) {
      selectedTemplateIDs.forEach((selectedTemplateID: number) => {
        this.moveUpSingleItem(selectedTemplateID);
      });
    }
  }

  moveUpSingleItem(selectedTemplateID: number) {
    let oldIndex = this.selectedList.indexOf(
      this.selectedList.find((item) => {
        return item.documentTemplate.docGenTemplateId == selectedTemplateID;
      })
    );
    if (oldIndex > 0) {
      let newIndex = oldIndex - 1;
      this.selectedList.splice(newIndex, 0, this.selectedList.splice(oldIndex, 1)[0]);
    }
  }

  moveTemplate(
    outList: DocumentTemplateFile[],
    inLlist: DocumentTemplateFile[],
    outIndex: number,
    inIndex: number,
    focusID
  ): void {
    let selectedTemplateIDs = this.selectedTemplateIDArr[outIndex];
    if (Array.isArray(selectedTemplateIDs)) {
      selectedTemplateIDs.forEach((selectedTemplateID: number) => {
        let template = this.getTemplateByID(outList, selectedTemplateID);
        if (template) {
          inLlist.push(template);
          this.selectedTemplateIDArr[inIndex] = template.documentTemplate.docGenTemplateId;
        }

        (<any>outList).remove(template);
      });
    }

    this.selectedTemplateIDArr[inIndex] = this.selectedTemplateIDArr[outIndex];

    if (outList.length) {
      this.selectedTemplateIDArr[outIndex] = outList[0].documentTemplate.docGenTemplateId;
    } else {
      this.selectedTemplateIDArr[outIndex] = null;
    }
    document.getElementById(focusID).focus();
  }

  updateSupplementalTaskCategoryTemplate() {
    /// check for items that are not selected and still remain in categoryTemplates List and remove them
    this.notSelectedList.forEach((file, i) => {
      let tempNotSelectedIndex: number = this.tempCategoryTemplates.findIndex(
        (catTemplate) =>
          catTemplate.documentTemplateName == file.documentTemplate.fileName &&
          catTemplate.templateFolderId == this.context.templateFolder.id
      );
      if (tempNotSelectedIndex >= 0) {
        this.tempCategoryTemplates.splice(tempNotSelectedIndex, 1);
      }
    });

    /// check for items that are selected and arrange them in categoryTemplates List
    this.selectedList.forEach((file, i) => {
      //   check if selected file exists already in cat template list
      let tempPrevCatIndex: number = this.tempCategoryTemplates.findIndex(
        (catTemplate) =>
          catTemplate.documentTemplateName == file.documentTemplate.fileName &&
          catTemplate.templateFolderId == this.context.templateFolder.id
      );
      if (tempPrevCatIndex >= 0) {
        let tempPrevCat: CategoryTemplate = this.tempCategoryTemplates[tempPrevCatIndex];
        tempPrevCat.templateDescription = file.documentTemplate.description;
        this.tempCategoryTemplates.splice(tempPrevCatIndex, 1);
        this.tempCategoryTemplates.splice(i, 0, tempPrevCat);
      } else {
        let tempCatTemplate: CategoryTemplate = new CategoryTemplate();
        tempCatTemplate.id = null;
        tempCatTemplate.supplementalTaskCategoryId = this.context.supplementalTaskCategory.id;
        tempCatTemplate.templateDescription = file.documentTemplate.description;
        tempCatTemplate.documentTemplateId = file.documentTemplate.docGenTemplateId;
        tempCatTemplate.documentTemplateName = file.documentTemplate.fileName;
        tempCatTemplate.templateFolderId = this.context.templateFolder.id;
        this.tempCategoryTemplates.splice(i, 0, tempCatTemplate);
      }
    });

    this.context.supplementalTaskCategory.categoryTemplates = this.tempCategoryTemplates;
  }

  close() {
    this.dialog.close();
  }

  manageTemplateFolder(): void {
    this.dialogService.matDialogContent({
      content: ManageFolderTemplatesModalComponent,
      context: {
        accountFileFolder: this.context.templateFolder,
        account: this.context.account
      },
      onFulfillment: (result) => {
        this.retrieveDocumentFiles();
      },
      fullScreen: false
    });
  }
}
