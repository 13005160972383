<!--modal-->
<div class="container-fluid custom-modal-container border-radius">
    <div class="row modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Import Owner</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="row modal-body view-port">
            <div class="form-group col-lg-12">
                <div class="col-lg-2 bold">Owner Name: </div>
                <div class="col-lg-10">{{this.context?.ownerData?.parcelRegisterName}}</div>
            </div>
        <div class="form-group col-lg-12">
            <div class="col-lg-12 bold">Is this owner a corporation/other entity or a person?</div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button (click)="organization()"
                    class="dp-btn keyboard-save-action"
                    id="organization"
                    type="button">
                <span>Corporation/Other Entity</span>
            </button>
            <button (click)="person()"
                    class="dp-btn-white"
                    id="personBtn"
                    type="button">
                <span>Person</span>
            </button>
            <button (click)="cancel()"
                    class="dp-btn dp-btn-cancel"
                    id="-cancel"
                    type="button">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>
