import {ContactTab} from './../../contact/contact-tab';
import {MatterTab} from '../../matters/matter-tab';
import {AdminTab} from '../../admin/admin-tab';
import {Tab} from './tab';
import {JurisdictionTab} from '../../contact/jurisdiction-tab';
import {ProjectTab} from '../../projects/shared/project-tab';
import {DepositManagerTab} from '../../deposit-manager/deposit-manager-tab';
import {ExportFileManagerTab} from '../../export-file-manager/export-file-manager.tab';
import {ShareDocumentsTab} from '../../share-documents/share-documents.tab';
import {DayAtGlanceTab} from '../../event/day-at-glance/dya-at-glance.tab';

export class TabFactory {

  /**
   * Factory method used to deserialize tabs from json structures
   * @param json
   * @returns {any}
   */
  static createTab(json?: Tab): Tab {
    if (json.tabType === 'matter') {
      return new MatterTab(json as MatterTab);
    } else if (json.tabType === 'project') {
      return new ProjectTab(json as ProjectTab);
    } else if (json.tabType === 'contact') {
      return new ContactTab(json as ContactTab);
    } else if (json.tabType === 'admin') {
      return new AdminTab(json as AdminTab);
    } else if (json.tabType === 'jurisdiction') {
      json[ 'jurisdictionDepartments' ] = null;
      return new JurisdictionTab(json as JurisdictionTab);
    } else if (json.tabType === 'depositManager') {
      return new DepositManagerTab(json as DepositManagerTab);
    } else if (json.tabType === 'exportFileManager') {
      return new ExportFileManagerTab(json as ExportFileManagerTab);
    } else if (json.tabType === 'shareDocuments') {
      return new ShareDocumentsTab(json as ShareDocumentsTab);
    } else if (json.tabType === 'dayAtGlance') {
      return new DayAtGlanceTab(json as DayAtGlanceTab);
    } else {
      //TODO: the other types here
      return new Tab(json);
    }
  }

}

export type TabType = 'matter' | 'contact' | 'admin' | 'project';

