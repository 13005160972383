import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';
import {WritPartyData} from './property-writ';

export type WritSearchType = 'ADD_NAME' | 'ORDER_NAME' | 'REDO_SEARCH'

export class TeranetWritSearchRequest extends BaseEntity {
  writId: number;
  matterId: number;
  parcelRegisterId: number;
  docketSystemId: number;
  lro: string;
  teranetUser: TeranetUser;
  reLine: string;
  partiesToSearch: WritPartyData[] = [];
  writSearchType: WritSearchType;
  enforcementOfficeNumber: string;
  enforcementOfficeName: string;

  get isAddNameSearch(): boolean {
    return this.writSearchType === 'ADD_NAME';
  }

  get isRedoNameSearch(): boolean {
    return this.writSearchType === 'REDO_SEARCH';
  }

}


