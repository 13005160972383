<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Send Welcome Package</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group col-lg-12" *ngIf="context.mainClientName">
            <label class="control-label text-center">Selecting "Send" below will generate a CIRF if selected, assign any selected tasks, and share any selected documents with client - {{context.mainClientName}}. A notification email will be generated and opened in your email client using the precedent selected below.</label>
        </div>

        <div class="form-group col-lg-12" *ngIf="context.participantName">
            <label class="control-label text-center">Selecting "Send" below will generate a CIRF if selected, and share selected documents with client - {{context.participantName}}. A notification email will be generated and opened in your email client using the precedent selected below.</label>
        </div>
        <div class="form-group col-lg-12">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Message Precedent</label>
            </div>
            <div class="col-lg-8">
                <select class="form-control" id="messagePrecedents"  name="messagePrecedents"
                        [(ngModel)]="selectedPrecedentId"
                        (change)="setMessage()">
                    <option value=""></option>
                    <option *ngFor="let precedent of precedents" [value]="precedent.id">
                        {{precedent.precedentName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group  col-lg-12">
            <div class="col-lg-2 vertical-align-label"></div>
            <div class="col-lg-8" >
                <textarea class="form-control" rows="12"
                          name="precedentMessage"
                          id="precedentMessage"
                          [dp-read-only]="true"
                          [(ngModel)]="message">
                </textarea>
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="cirfPrecedent && welcomePackagePrecedentId !== cirfPrecedent.id">
            <div class="col-lg-2 vertical-align-label"></div>
            <div class="col-lg-8">
                <span>
                    <a (click)="setTextToCirfPrecedent()" href="javascript:void(0);">Set Text to CIRF precedent</a>
                </span>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>Send</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
