import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[ngModel][dp-decimal]'
})

export class DecimalDirective implements OnInit {

  @Input('dp-decimal') format: string = '';
  integerLength: number;
  decimalLength: number;

  constructor(private model: NgModel, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const formatGroup = String(this.format).split('.');
    this.integerLength = Number(formatGroup[ 0 ]).valueOf();
    if (this.integerLength == 0) {
      this.integerLength = undefined;
    }
    this.decimalLength = formatGroup.length < 2 ? 0 : Number(formatGroup[ 1 ]).valueOf();

    this.model.valueChanges.subscribe(this.onValueChange);
  }

  private onValueChange = (value: string): void => {
    // No need to process empty string
    if (value == null || value === '') {
      return;
    }

    const strVal: string = String(value);
    const filteredValue = strVal.replace(/[^\d\.]/g, '').replace(/(\d*(\.\d*)?).*/g, '$1');

    let dotPos = -1;
    let newVal: string;
    if (filteredValue === '') {
      newVal = filteredValue;
    } else {
      dotPos = filteredValue.indexOf('.');
      if (dotPos === -1) {
        newVal = Number(filteredValue).toFixed().substring(0, this.integerLength);
      } else {
        newVal = Number(filteredValue.substring(0, dotPos)).toFixed().substring(0, this.integerLength)
          + (this.decimalLength
            ? ('.' + filteredValue.substring(dotPos + 1, dotPos + 1 + this.decimalLength))
            : '');
      }
    }

    if (newVal !== strVal) {
      const elm = this.elementRef.nativeElement;
      const startPos = elm.selectionStart + (dotPos === 0 ? 1 : 0);
      const endPos = elm.selectionEnd + (dotPos === 0 ? 1 : 0);

      this.model.valueAccessor.writeValue(newVal);
      elm.setSelectionRange(startPos, endPos);
      setTimeout(_ => {
        this.model.viewToModelUpdate(newVal);
      }, 0);
    }
  };

  @HostListener('blur') onBlur(): void {
    if (this.model.viewModel != null && this.model.viewModel !== '') {
      const curVal = String(this.model.viewModel);
      const newVal: string = Number(curVal).toFixed(this.decimalLength);
      if (newVal !== curVal) {
        this.model.valueAccessor.writeValue(newVal);
        setTimeout(_ => {
          this.model.viewToModelUpdate(newVal);
        }, 0);
      }
    }
  }
}
