import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {messages} from '../../../common/messages';
import {Telephone} from '../../shared/telephone';
import {Utils} from '../../shared/utils';

export class Insurer extends BaseEntity {

  id: number;

  user: any;

  customerAccount: any;

  nameOnDocuments: string;

  contactType: string;

  envelopeSalutationLine1: string;

  envelopeSalutationLine2: string;

  letterSalutation: string;

  dear: string;

  attention: string;

  email: string;

  isSnapshot: boolean;

  isPrivate: boolean;

  sourceContact: any;

  address: any[];

  telephone: Telephone[];

  canadianResidentFlag: string;

  additionalName1: any;

  additionalName2: any;

  gender: string;

  partyRole: any;

  organizationName: string;

  titleOfOfficeHeld1: any;

  titleOfOfficeHeld2: any;

  executingUnderSealFlag: any;

  websiteURL: string;

  additionalNotes: any;

  persons: any[];

  get insurerContactHtmlText(): string {

    let message: string = '';
    if (this.address && this.address.length > 0) {
      // message = this.address[0].addressLine1 ? this.address[0].addressLine1 : '' + this.address[0].addressLine2 ? '<br>' + this.address[0].addressLine2 : '' + this.address[0].city ? '<br>' + this.address[0].city : '' + ', ' + this.address[0].province ? this.address[0].province : '' + ', ' + this.address[0].postalCode ? this.address[0].postalCode : '';
      message += '<br>';
      message += this.address[ 0 ].addressLine1 ? this.address[ 0 ].addressLine1 : '';
      message += this.address[ 0 ].addressLine2 ? '<br>' + this.address[ 0 ].addressLine2 : '';
      message += this.address[ 0 ].city ? '<br>' + this.address[ 0 ].city : '';
      message += ', ';
      message += this.address[ 0 ].provinceName ? this.address[ 0 ].provinceName : '';
      message += ', ';
      message += this.address[ 0 ].postalCode ? this.address[ 0 ].postalCode : '';
    }
    if (this.telephone && this.telephone.length > 0) {
      if (messages.insurerValues && this.organizationName == messages.insurerValues.find(item => item.class == 'stewart').value) {
        message += '<br>Stewart Title Software Support: ' + this.telephone[ 0 ].telephoneNumber;
        if (this.telephone.length > 1) {
          message += '<br>Stewart Title Underwriting: ' + this.telephone[ 1 ].telephoneNumber;
        }
        if (this.telephone.length > 2) {
          message += ' or toll free at: ' + this.telephone[ 2 ].telephoneNumber;
        }

      } else {
        message += '<br>';
        let workPhoneNo = Utils.getPhoneNumbersBasedOnType(this.telephone, 'WORK', ' or ');
        if (workPhoneNo) {
          message += '<br>Tel: ' + workPhoneNo;//this.telephone[0].telephoneNumber;
        }
        let faxNo = Utils.getPhoneNumbersBasedOnType(this.telephone, 'FAX', ' or ');
        if (faxNo) {
          message += '<br>Fax: ' + faxNo;
        }
      }

    }
    if (this.websiteURL) {
      message += '<br><br>Website: ' + this.websiteURL;
    }
    return message;
  }

  get insurerTitlePlusHtmlText(): string {
    let message = 'TitlePLUS policies for commercial properties in Ontario with purchase prices and mortgage amounts up to $5 million can  now be ordered online at <a href="https://www.mytitleplus.ca/login" target="_blank">https://www.mytitleplus.ca/login</a>' +
      '<br><br>You can contact TitlePLUS' +
      '<br><br>Contact Info' +
      '<br><br>' + this.organizationName +
      ((Array.isArray(this.address) && this.address.length > 0 ? ('<br>' + this.address[ 0 ].addressLine1) : '')) +
      ((Array.isArray(this.address) && this.address.length > 0 ? ('<br>' + this.address[ 0 ].addressLine2) : '')) +
      ((Array.isArray(this.address) && this.address.length > 0
        ? ('<br>' + this.address[ 0 ].city + ', ' + this.address[ 0 ].provinceName + ' ' + this.address[ 0 ].postalCode)
        : '')) +
      '<br><br>Tel:' + Utils.getPhoneNumbersBasedOnTypeOrder(this.telephone, 'WORK', ' or ') +
      '<br>FAX:' + Utils.getPhoneNumbersBasedOnTypeOrder(this.telephone, 'FAX', ' or ') +
      '<br><br>Email: <a href="mailto:' + this.email + '" target="_blank">' + this.email + '</a>' +
      '<br>Website: <a href="http:/' + this.websiteURL + '" target="_blank">' + this.websiteURL + '</a>';
    return message;
  }
}
