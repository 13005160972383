import {Injectable} from '@angular/core';
import {AdjudicationRequest} from './adjudication-list/adjudication-request';
import {adjudicationApi} from './adjudication-api';
import {Utils} from '../matters/shared/utils';
import {Observable} from 'rxjs';
import {HttpClient} from '../core/httpClient.service';
import {AdjudicationResponseKey} from './adjudication-response-key';

const sortTypeFields = {
  'contactType': [ 'privateContact.contactType' ],
  'contact': [ 'privateContact.displayName',
    'privateContact.contactMailingAddress'
  ],
  'city': [ 'privateContact.contactMailingAddress.city' ],
  'province': [ 'privateContact.contactMailingAddress.provinceName' ],
  'account': [ 'customerAccount.publicAccountId',
    'customerAccount.name'
  ],
  'user': [ 'updatedByUser.lastName',
    'updatedByUser.firstName'
  ],
  'adjudicationRequestType': [ 'adjudicationRequestType' ],
  'lastUpdatedTimeStamp': [ 'lastUpdatedTimeStamp' ]
};

@Injectable()
export class AdjudicationService {
  constructor(private http: HttpClient) {
  }

  // this method is for getting  all adjudication from database
  getAdjudications(filterContactType?: string[],
                   filterProvince?: string[],
                   filterAdjudicationType?: string[],
                   sortQuery?: string,
                   sortType?: string,
                   page?: number,
                   perPage?: number,
                   searchText?: string): Observable<AdjudicationRequest[]> {

    let url: string;

    const sort: string = this.getSortUrl(sortQuery, sortType);
    let filter: string = '';

    filter = this.appendContactTypeFilter(filter, filterContactType);
    filter = this.appendProvinceFilter(filter, filterProvince, filterContactType);
    filter = this.appendAdjudicationTypeFilter(filter, filterAdjudicationType);
    filter = encodeURIComponent(filter);
    let urlQuery = Utils.escapeSearchText(searchText);
    let queryfilter = urlQuery && urlQuery.length > 0
      ? 'ANYglobalContact.displayName_EQ_*' + urlQuery + '*'
      + '|privateContact.displayName_EQ_*' + urlQuery + '*'
      + '|privateJurisdiction.jurisdictionName_EQ_' + urlQuery + '*'
      + '|globalJurisdiction.jurisdictionName_EQ_' + urlQuery + '*'
      : '';
    filter = filter && filter.length ? filter + ',' + queryfilter : queryfilter;

    url = filter ?
      `${ adjudicationApi.adjudicationRequests }?${ sort }&filter=${ filter }&filterType=ALL`
      : `${ adjudicationApi.adjudicationRequests }?${ sort }`;

    if (page) {
      url += `&page=${ page }&per_page=${ perPage }`;
    }

    return this.http.get(url).map((res) => {

      return res[ AdjudicationResponseKey.adjudicationRequests ].map((item) => {
        return new AdjudicationRequest(item);
      });
    });
  }

  private appendContactTypeFilter(initialFilter: string, filterTypes?: string[]): string {
    if (Array.isArray(filterTypes) && filterTypes.length == 1 && filterTypes[ 0 ] == 'LAW_FIRM') {
      //if user only pick 'LAW_FIRM', then return lawFirm requests with related Solicitor requests
      return this.appendFilter(initialFilter, 'adjudicationSourceType_IN_', [ 'LAW_FIRM_WITH_SOLICITORS' ]);
    }
    return this.appendFilter(initialFilter, 'adjudicationSourceType_IN_', filterTypes);
  }

  private appendProvinceFilter(initialFilter: string, filterType?: string[], filterContactType?: string[]): string {

    let addAny: string = ((filterContactType.indexOf('JURISDICTION') > -1) || (filterContactType.indexOf('DEPARTMENT') > -1)) ? 'ANY' : '';

    let provFilter: string = this.appendFilter(initialFilter, `${ addAny }privateContact.contactMailingAddress.provinceCode_IN_`, filterType);

    if (Array.isArray(filterContactType)) {
      if (filterContactType.indexOf('JURISDICTION') > -1) {
        // adjudication for jurisdiction
        provFilter = this.appendFilter(provFilter, '|privateJurisdiction.provinceCode_IN_', filterType, false);
      }
      if (filterContactType.indexOf('DEPARTMENT') > -1) {
        // adjudication for department -> there is no provinceCode at department level, using global jurisdiction that department was copied from
        provFilter = this.appendFilter(provFilter, '|globalJurisdiction.provinceCode_IN_', filterType, false);
      }
    }
    return provFilter;
  }

  private appendAdjudicationTypeFilter(initialFilter: string, filterType?: string[]): string {
    return this.appendFilter(initialFilter, 'adjudicationRequestType_IN_', filterType);
  }

  private appendFilter(initialFilter: string, filterName: string, filterType?: string[], addComma: boolean = true): string {
    let filter: string = initialFilter;
    if (Array.isArray(filterType)) {
      if (filterType.indexOf('ALL') > -1) {
        // No Filter
      } else {
        // for(let i = 0; i < filterContactType.length; i++) {
        filterType.forEach((item, i) => {
          if (i == 0) {
            if (addComma) {
              filter = Utils.addCommaToFilter(filter);
            }
            filter += filterName;
          }
          // Server side needs to escape that underscore with \
          // And \ needs another \ to escape \
          filter += item.replace(/_/g, '\\_');
          if (i < (filterType.length - 1)) {
            filter += '!';
          }
        });
      }
    }
    return filter;
  }

  private appendSort(sortFields: string[], sortType: string): string {
    if (!Array.isArray(sortFields) || sortFields.length === 0) {
      sortFields = [ 'lastUpdatedTimeStamp' ];
    }

    return 'sort=' + sortFields.map(item => item + '|' + sortType).join(',');
  }

  private getSortUrl(sortQuery: string, sortType: string): string {
    const sortFields = sortQuery ? sortTypeFields[ sortQuery ] : null;
    return this.appendSort(sortFields, sortType || 'DESC');
  }

  processAdjudicationRequest(adjudicationRequest: AdjudicationRequest) {
    let url: string
      = adjudicationApi.dealAdjudication(adjudicationRequest.id);
    return this.http.put(url, adjudicationRequest)
    .map((response) => {
      return response;
    });
  }

  getAdjudicationRequest(adjudicationRequest: AdjudicationRequest): Observable<AdjudicationRequest> {
    let url: string
      = adjudicationApi.getAdjudication(adjudicationRequest.privateOrganizationId);
    return this.http.get(url)
    .map((response) => {
      return new AdjudicationRequest(response[ 'AdjudicationRequest' ]);
    });
  }
}
