<div class="container-fluid custom-modal-container">
    <form class="form-horizontal">
        <div class="border-radius">
            <div class="row modal-header">
                <div class="col-lg-10"><h1>Form 54</h1></div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 padding-bottom-20">
                    <div class="form-group">
                        <label class="control-label col-lg-2 text-left">Form 54 to be signed by</label>
                        <div class="col-lg-2">
                            <select class="form-control" id="signedBy" name="signedBy"
                                    [(ngModel)]="form54.form54SignedBy">
                                <option *ngFor="let signedByOption of signedByOptions"
                                        [value]="signedByOption.value">
                                    {{signedByOption.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group col-lg-12">
                        <label class="control-label">That the land is being transferred:</label>
                    </div>
                    <div class="form-group">
                        <div class="radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setLandBeingTransferred('FOR_THE_TRUE_AND_ACTUAL_CONSIDERATION')">
                                <input type="radio" [checked]="form54.isTrueAndActualConsideration()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">for the true and actual consideration of {{this.context.purchasePrice | currency:'CAD':'symbol-narrow':'1.2-2'}}, excluding HST</label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-4 radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setLandBeingTransferred('AS_A_GIFT_OR_WITHOUT_CONSIDERATION')">
                                <input type="radio" [checked]="form54.isAsAGift()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">as a gift or without consideration</label>
                            </span>
                        </div>
                        <div class="col-lg-5 warningMessage padding-top-5" *ngIf="form54.isAsAGift()">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Note: Enter reason in Q.6 of document produced
                        </div>
                    </div>

                    <div class="form-group col-lg-12 padding-top-10">
                        <label class="control-label">There are no outstanding taxes or penalties as of:</label>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-2 radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setNoOutstandingTaxes('END_OF_YEAR')">
                                <input type="radio" [checked]="form54.isEndOfYear()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">the end of</label>
                            </span>
                        </div>
                        <div class="col-lg-3">
                            <select class="form-control"
                                    id="endOfYear"
                                    name="endOfYear"
                                    [dp-read-only]="!form54.isEndOfYear()"
                                    [(ngModel)]="form54.noOutstandingTaxesEndOfYear">
                                <option *ngFor="let endOfYearOption of endOfYearOptions"
                                        [value]="endOfYearOption.value">
                                    {{endOfYearOption.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setNoOutstandingTaxes('DATE_OF_ACQUIRING_TITLE')">
                                <input type="radio" [checked]="form54.isDateOfAcquiringTitle()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">the date of acquiring title</label>
                            </span>
                        </div>
                    </div>

                    <div class="form-group col-lg-12 padding-top-10">
                        <label class="control-label">The address for mailing future assessment and tax notices is:</label>
                    </div>
                    <div class="form-group">
                        <div class="radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setAddressForMailing('PURCHASER')">
                                <input type="radio" [checked]="form54.isPurchaserAddress()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">the mailing address of the purchaser</label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setAddressForMailing('SUBJECT_PROPERTY')">
                                <input type="radio" [checked]="form54.isSubjectPropertyAddress()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">the address of the subject property</label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setAddressForMailing('FIRST_MORTGAGE')">
                                <input type="radio" [checked]="form54.isFirstMortgageAddress()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">the mailing address of the first mortgage</label>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-4 radio padding-left-20">
                            <span fieldId="trueAndActualConsiderationCheckbox" (click)="form54.setAddressForMailing('OTHER')">
                                <input type="radio" [checked]="form54.isOtherAddress()"/>
                                <label class="control-label vertical-align-label display-inline padding-left-3">other</label>
                            </span>
                        </div>
                        <div class="col-lg-5 warningMessage padding-top-5" *ngIf="form54.isOtherAddress()">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> NOTE: Enter address in Q.12 of document produced
                        </div>
                    </div>

                    <div class="form-group col-lg-12 padding-top-10">
                        <label class="control-label">This transaction qualifies for an exemption under section:</label>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-2 padding-left-20">
                            <input class="form-control"
                                   id="realPropertyTransferTaxActExemptionSection"
                                   name="realPropertyTransferTaxActExemptionSection"
                                   maxlength="8"
                                   [(ngModel)]="form54.realPropertyTransferTaxActExemptionSection"
                            />
                        </div>
                        <div class="col-lg-5">
                            <label class="control-label padding-top-10">of the Real Property Transfer Tax Act</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-2 padding-left-20">
                            <input class="form-control"
                                   id="generalRegulationExemptionSection"
                                   name="generalRegulationExemptionSection"
                                   maxlength="8"
                                   [(ngModel)]="form54.generalRegulationExemptionSection"
                            />
                        </div>
                        <div class="col-lg-5">
                            <label class="control-label padding-top-10">of the General Regulation - Real Property Transfer Tax Act</label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row modal-footer">
                <div class="col-lg-12 buttons">
                    <div class="col-lg-12 text-center">
                        <button type="button"
                                class="dp-btn"
                                id="okBtn"
                                (click)="onOk()">
                            <span>OK</span>
                        </button>
                        <button type="button"
                                class="dp-btn dp-btn-cancel"
                                (click)="close()">
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

