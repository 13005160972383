<!-- modal -->
<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>Teranet Connect Import Vendor Data</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body form-horizontal" *ngIf="instruments && instruments.length > 0">
        <div class="col-lg-12 margin-bottom-20" style="border-bottom: 2px solid #cccccc">
            <div class="col-lg-5">
                <div class="form-group main-level"><label class="control-label">Instruments in Teranet Parcel Register</label>
                </div>
            </div>
            <div class="col-lg-2 text-center">
                <dp-checkbox fieldId="allField" fieldClass="top-8" >
                    <input type="checkbox"
                           [(ngModel)]="allFields"
                           (change)="updateAllFields(allFields)"
                           id="allField"
                    />
                </dp-checkbox>
            </div>
            <div class="col-lg-5">
                <div class="form-group main-level"><label class="control-label">Existing Mortgages from 'Existing Mortgages' tab</label>
                </div>
            </div>
        </div>
        <ng-container *ngFor="let item of instruments;let i = index;">
            <div class="col-lg-12 form-group">
                <div class="col-lg-5">
                    <textarea type="text" *ngIf="item.teranetConnectMortgage"
                           id="tcMortgage{{i}}"
                              style="height: 75px;"
                           [dp-read-only]="true"
                           [innerHtml]="getFormatted(item.teranetConnectMortgage)"
                           name="tcMortgage{{i}}"
                              class="form-control"></textarea>
                </div>

                <div class="col-lg-2 text-center padding-left-15">
                    <dp-checkbox fieldId="fieldSA{{i}}" fieldClass="top-8" *ngIf="item.teranetConnectMortgage && !item.isMortgageIdentical">
                        <input type="checkbox"
                               [(ngModel)]="item.isCopied"
                               [checked]="allFields"
                               (change)="updateMortgage(item)"
                               id="fieldSA{{i}}"
                               name="fieldSA{{i}}"
                        />
                    </dp-checkbox>
                </div>
                <div class="col-lg-5">
                   <textarea type="text" *ngIf="item.matterMortgage"
                             id="matterMortgage{{i}}"
                             style="height: 75px;"
                             [dp-read-only]="true"
                             [ngModel]="getFormatted(item.matterMortgage)"
                             name="matterMortgage{{i}}"
                             class="form-control"></textarea>
                </div>
            </div>
            <div class="col-lg-12 form-group text-right"
                 *ngIf="isMortgageIsToShow(item)">

                <div class="col-lg-2 right" >
                    <select  class="form-control"
                            [(ngModel)]="item.matterMortgage.mortgageDispositionType"
                           >
                        <option *ngFor="let item of mortgageDispositionTypeItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
                <div class="col-lg-2 vertical-align-label right">
                    <label class="control-label">Instrument is to</label>
                </div>
                <div style="float:right">
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12">
            <div class="row padding-top-20">
                <div class="col-lg-12 text-center">
                    <button type="button" class="dp-btn" (click)="ok()" id="okBtn" [disabled]="!isImportButtonEnabled">
                        <span>Import Selected</span>
                    </button>

                    <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
