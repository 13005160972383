import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';

export class TeranetViewDocketSummaryRequest extends BaseEntity {
  matterId: number; // needed for validation on API
  matterDocketId: number;
  docketSystemId: number;
  teranetUser: TeranetUser;
  startDate: string;
  endDate: string;

}

export class TeranetViewDocketSummaryResponse extends BaseEntity {
  description: string;
  amount: number;
  hstAmount: number;
  userName: string;
  date: string;

}

export class BillingTransactionInfoResponse extends BaseEntity {
  id: number;
  matterId: number;
  description: string;
  amount: number;
  taxType: string;
  taxAmount: number;
  transactionDate: string;
  billingTransactionType: string;

}


