import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export const LOGGING_OFF_SYNGRAFII = '/en/account/loggingoff';
export const LOGGED_OFF_SYNGRAFII = '/en/account/loggedoff';
export const SYNGRAFII_HOME_PAGE = '/en';

export class SyngrafiiAuthorizationUrl extends BaseEntity {
  syngrafiiBaseUrl: string;
  syngrafiiOAuthorizationUrlString: string;
}
