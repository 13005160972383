import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {FctMessage} from './fct-message';

export class SendTxnDataResponse extends BaseEntity {

  responseCode: number;
  url: string;
  serviceMessage: string;
  messages: FctMessage[] = [];

  constructor(t?: SendTxnDataResponse) {
    super(t);
    if (t && t.serviceMessage) {
      this.serviceMessage = t.serviceMessage;
    }
  }

  get isSuccessful(): boolean {
    return this.serviceMessage == null;
  }

}
