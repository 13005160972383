import {CirfHelperService} from '../../../shared/cirf/cirf-helper.service';
import {CirfConfigService} from '../../../../shared-main/cirf-config/cirf-config.service';

export class CirfImportBaseComponent {
  constructor(public cirfConfigService: CirfConfigService) {
  }

  isFieldHidden(fieldName: string, fieldValue: string, isUdf?: boolean): boolean {
    return fieldName && CirfHelperService.isFieldHidden(fieldName, fieldValue, this.cirfConfigService, isUdf);
  }
}
