import {api} from '../../common';

export const titlePlusApi = {
  titlePlusUser(userId: string): string {
    return `${ api }/integrations/titlePlus/users/${ userId }/titlePlusUsers`;
  },
  titlePlusAuthenticationUrl(): string {
    return `${ api }/public/integrations/titlePlus/authorizationUrl`;
  },
  solicitorTitlePlusUsers(accountId: string): string {
    return `${ api }/integrations/titlePlus/accounts/${ accountId }/solicitorTitlePlusUsers`;
  },
  titlePlusUsersForMatter(matterId: number): string {
    return `${ api }/integrations/titlePlus/matters/${ matterId }/titlePlusUsers`;
  },
  titlePlusCreateDeal(matterId: number, relevantUserTitlePlusUserId: number, solicitorTitlePlusUserId: number): string {
    return `${ api }/integrations/titlePlus/matters/${ matterId }/titlePlusUsers/relevantUser/${ relevantUserTitlePlusUserId }/solicitorUser/${ solicitorTitlePlusUserId }/submitDeal`;
  },
  titlePlusPolicyURL(matterId: number, titlePlusUserId: number): string {
    return `${ api }/integrations/titlePlus/matters/${ matterId }/titlePlusUsers/${ titlePlusUserId }/policyURL`;
  },
  titlePlusOrderStatus(matterId: number, titlePlusUserId: number, orderId: string): string {
    return `${ api }/integrations/titlePlus/matters/${ matterId }/titlePlusUsers/${ titlePlusUserId }/orderStatus/${ orderId }`;
  },
  titlePlusDownloadDocument(matterId: number, titlePlusUserId: number): string {
    return `${ api }/integrations/titlePlus/matters/${ matterId }/titlePlusUsers/${ titlePlusUserId }/fileDownload`;
  },
  titlePlusDeleteDocuments(matterId: number, titlePlusUserId: number): string {
    return `${ api }/integrations/titlePlus/matters/${ matterId }/titlePlusUsers/${ titlePlusUserId }/files`;
  }

};

export const titlePlusLinks = {
  premiumCalculator: 'https://titleplus.ca/premium-calculator/',
  universityHelpPage: 'https://www.lawpro.ca/contact/',
  residentialSearchRequirementsURL: 'https://titleplus.ca/wp-content/uploads/2021/06/Residential-Search-Requirements-–-Ontario-Final.pdf',
  homePage: 'http://www.titleplus.ca',
  myTitlePplus: 'http://mytitleplus.ca/'
};

export const titlePlusOrderStatus = {
  NOT_SUBMITTED: 'NotSubmitted',
  SUBMITTED: 'Submitted',
  UNDER_REVIEW: 'UnderReview',
  USER_ACTION_REQUIRED: 'UserActionRequired',
  ISSUED: 'Issued',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted'
};


