import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {DecimalPipe} from '@angular/common';

declare var jQuery: any;

@Directive({
  selector: '[ngModel][dp-default-number]',
  providers: [
    DecimalPipe
  ]
})

export class DefaultNumberDirective implements OnInit {
  /*
  Directive can be used where you need to have a default value of 0.00 if number text field is empty
  */
  @Input('significant-currency-digits') significantCurrencyDigits: number = 5;
  private digitCheckRegex: RegExp;

  constructor(private model: NgModel, private elementRef: ElementRef, private decimalPipe: DecimalPipe) {
  }

  ngOnInit(): void {
    this.model.valueChanges.subscribe(this.onValueChanges);
  }

  ngAfterViewInit(): void {
    jQuery(this.elementRef.nativeElement).focus();
    setTimeout(() => {
      jQuery(this.elementRef.nativeElement).blur();
    }, 100);
  }

  private onValueChanges = (newValue: string): void => {

    if (!jQuery(this.elementRef.nativeElement).is(':focus')) {
      if (newValue != undefined && newValue != null && !Number.isNaN(parseFloat(newValue))) {
        this.model.valueAccessor.writeValue(this.formatVal(newValue));
      } else {
        this.model.valueAccessor.writeValue(this.formatVal('0.00'));
      }

    } else {

      let ret: string = newValue && newValue.toString().replace(this.digitCheckRegex, '')
      .replace(/(?!^)-/g, '')
      .replace(/(\.\d{0,2}).*/, '$1')
      .replace(new RegExp('(^[+-]?\\d{0,' + Number(this.significantCurrencyDigits) + '})\\d*'), '$1')
      .replace(/^0{2,}/, '0');
      this.model.viewToModelUpdate(ret);
      this.model.valueAccessor.writeValue(ret);
    }

  };

  @HostListener('blur') onBlur(): void {
    let val: string = this.model.viewModel;
    if ((!val || (val && val.toString().trim() == '') || isNaN(Number(val))) && val != null || (val && val.toString().trim() == '0')) {
      this.model.viewToModelUpdate(0);
      this.model.valueAccessor.writeValue('0.00');
    } else {
      this.model.valueAccessor.writeValue(this.formatVal(val));
    }

  }

  private formatVal(val: string): string {
    return this.decimalPipe.transform(val, '1.0-2');
  }

}
