import {Injectable} from '@angular/core';
import {Matter} from './shared/matter';
import {Observable} from 'rxjs/Observable';
import {TemplateCodeValues} from './shared/evaluate-template-codes';
import {matterApi} from './shared/matter-api';
import {HttpClient} from '../core';
import {MatterService} from './matter.service';
import {StatementAdjustment} from './statement-adjustment/statement-adjustment';
import {Utils} from './shared/utils';
import {BaseEntity} from '../shared/BaseEntity/base-entity';

@Injectable()
export class TemplateCodeService {
  constructor(private http: HttpClient, private matterService: MatterService) {
  }

  private _cachedTemplateCodesValues: Map<Matter, TemplateCodeValues> = new Map<Matter, TemplateCodeValues>();

  // this is to evaluate codes on given matter.
  evaluateTemplateCodes(matter: Matter, templateCodes: string[]): Observable<TemplateCodeValues> {
    let url: string = matterApi.evaluateCodes;
    let clonedMatter = new Matter(matter);
    clonedMatter.cleanUpMatterBeforeSaving(this.matterService);
    BaseEntity.clearAllIdentifiers(clonedMatter);

    let templateCodesRequest: TemplateCodeValues = new TemplateCodeValues();
    templateCodesRequest.matter = clonedMatter;
    templateCodesRequest.templateCodes = templateCodes;

    let cachedKeys: Matter[] = Array.from(this._cachedTemplateCodesValues.keys());
    let keyOfCachedValue: Matter = cachedKeys.find(mt => mt.id === templateCodesRequest.matter.id && matter.selectedProgressionStatus === templateCodesRequest.matter.selectedProgressionStatus);
    if (keyOfCachedValue) {
      if (JSON.stringify(keyOfCachedValue) === JSON.stringify(templateCodesRequest.matter)) {
        return Observable.of(this._cachedTemplateCodesValues.get(keyOfCachedValue));
      }
    }
    return this.http.post(url, templateCodesRequest)
    .map((res) => {
      let result: TemplateCodeValues = new TemplateCodeValues(res[ 'TemplateCodeValues' ]);
      this._cachedTemplateCodesValues.set(clonedMatter, result);
      return result;
    });
  }

  async calculateMatterTemplateCodes(matter: Matter): Promise<void> {
    let templateCodesInMatter: string[] = matter.getTemplateCodesUsedInMatter();

    if (templateCodesInMatter.length > 0) {
      let templateCodeResponse: TemplateCodeValues = await this.evaluateTemplateCodes(matter, templateCodesInMatter).toPromise();
      this.updateTemplateCodesInMatter(matter, templateCodeResponse);
    }
  }

  updateTemplateCodesInMatter(matter: Matter, templateCodeResponse: TemplateCodeValues): void {
    this.updateTemplateCodesInAdjustments(matter, templateCodeResponse);
  }

  updateTemplateCodesInAdjustments(matter: Matter, templateCodeResponse: TemplateCodeValues): void {
    matter.statementOfAdjustments.filter(soa => soa.isComponentAdjustment()).forEach(soa => {
      this.updateTemplateCodesInComponentAdj(soa, templateCodeResponse);
    });
  }

  updateTemplateCodesInComponentAdj(soa: StatementAdjustment, templateCodeResponse: TemplateCodeValues): void {
    if (soa.soAdjComponent.isTemplateCodeUsedForCreditNoteCalculation && templateCodeResponse.hasCode(soa.soAdjComponent.creditNoteCalculationMethod)) {
      soa.soAdjComponent.creditNoteAmount = Utils.convertAmountToNumberWithSign(templateCodeResponse.getValueByTemplateCode(soa.soAdjComponent.creditNoteCalculationMethod));
      soa.amount = soa.soAdjComponent.creditNoteAmount;
    }

    soa.soAdjComponent.items.filter(item => item.isTemplateCodeUsedForCalculation && templateCodeResponse.hasCode(item.calculationMethod))
    .forEach(item => {
      item.amount = Utils.convertAmountToNumberWithSign(templateCodeResponse.getValueByTemplateCode(item.calculationMethod));
    });
  }
}
