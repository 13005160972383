// This is a sharable service which is used for all the matter API.
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {ContactInfoInstanceTypes} from '../matters/shared/contact-info-instance-types';
import {Contact} from '../matters/shared/contact';
import {SoaFeeRate} from '../matters/consideration-ltt/soa-fee-rate';
import {Address, FamilyLawAct, Matter, MatterProperty, RollNumber} from '../matters/shared';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {Observable} from 'rxjs/Observable';
import {MatterParticipant} from '../matters/shared/matter-participant';
import {ContactInfo} from '../matters/shared/contact-info';
import {DocumentProfile} from '../admin/document-profile/document-profile';
import {ErrorService} from '../shared/error-handling/error-service';
import SharedUtils from '../shared-main/utils';
import {ContactQueryService} from '../contact/contact-query.service';
import {LockScreenService} from '../core/lock-screen.service';
import {HttpClient} from '../core';
import {DialogService} from '../shared/dialog/dialog.service';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {TaxRateService} from '../matters/consideration-ltt/tax-rate.service';
import {PurchaserComponent} from '../matters/purchaser';
import {MatterParticipantWrapper} from '../matters/shared/matter-participant-wrapper';
import {VendorsSolicitorComponent} from '../matters/vendors-solicitor';
import {PropertyTeranetComponent} from '../matters/property-teranet';
import {CondoCorporationComponent} from './condo-corporation/condo-corporation.component';
import {AttentionInfoComponent} from './matter-opening/attention/attention-info.component';
import {SalePriceAdjustmentFactory} from '../matters/statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {UUIDUtil} from '../main/uuid-util';
import {MatterTax} from '../matters/shared/property-taxes';
import {CondominiumExpense} from '../matters/property-teranet/unit-level-plan/condominium-expense';
import {CondominiumPlan} from '../matters/property-teranet/unit-level-plan/condominium-plan';
import {MatterParticipantRoleTypes} from './shared/matter-participant-role-types';
import {FireInsuranceComponent} from './fire-insurance/fire-insurance.component';
import {FamilyLawActComponent} from '../matters/purchaser/family-law-act/family-law-act.component';
import {StatementAdjustmentComponent} from '../matters/statement-adjustment/statement-adjustment.component';
import {DpBooleanValueTypes} from './shared/dp-boolean';
import {MatterContactInfo} from './shared/matter-contact-info';
import {AddressTypes} from './shared/address-types';
import {StatementAdjustmentKey} from '../matters/statement-adjustment/statement-adjustment-constants';
import {SoaCommonExpense} from './statement-adjustment/model/common-expense-soa';
import {ClosingDatePayment} from './shared/closing-date-payment';
import {LateClosingInterest} from './shared/late-closing-interest';
import {DepositModalContextValue} from './property-teranet/deposit/deposit.modal.component';
import {AdvanceMatterHoldbackConfig, HOLDBACK_TYPE, MatterHoldback} from './shared/advance-holdback/matter-holdback';
import {MatterUndertaking} from './shared/matter-undertaking';
import {PermittedRegistration} from './shared/permitted-registration';
import {ParcelLegalDescription} from './shared/parcel-legal-description';
import {RollNumberPropertyTaxesResult} from './property-teranet/property-taxes/roll-number-property-taxes-result';
import {StatementAdjustment, StatementAdjustmentOrder} from './statement-adjustment/statement-adjustment';
import * as _ from 'lodash';
import {UndertakingsConfigService} from '../admin/shared/undertaking-config.service';
import {MatterIdCleaner} from '../../app/matters/matter-id-cleaner';
import {MatterService} from './matter.service';
import {MatterExtraDepositConfig} from './shared/matter-extra-deposit-config';
import {StatementOfAdjustmentHeading} from './statement-adjustment/model/statement-adjustment-heading';
import {StatementOfAdjustmentPayable} from './statement-adjustment/model';
import {StatementAdjustmentConfig} from '../admin/statement-adjustment/statement-adjustment-config';
import {DocumentProfileCache} from '../shared-main/document-profile-cache.service';
import {PROVINCE_CODES} from './shared/user-province';
import {MatterErrorUtil} from './shared/matter-utils/matter-error-util';
import {MatterParticipantService} from './matter-participant-service';
import {BorrowerMapping, RemovedReconciledBorrowerRelatedData} from '../shared-main/telus/stewart-mortgage-instruction';

@Injectable()
export class CopyMatterLinkDataService {

  constructor(private http: HttpClient,
              private dialogService: DialogService,
              private contactQueryService: ContactQueryService,
              private lockScreenService: LockScreenService,
              private documentProfileService: DocumentProfileService, private taxRateService: TaxRateService,
              public purchaserComponent: PurchaserComponent,
              public vendorsSolicitorComponent: VendorsSolicitorComponent,
              public propertyTeranetComponent: PropertyTeranetComponent,
              public condoCorporationComponent: CondoCorporationComponent,
              public fireInsuranceComponent: FireInsuranceComponent,
              public attentionInfoComponent: AttentionInfoComponent,
              public familyLawActComponent: FamilyLawActComponent,
              public statementAdjustmentComponent: StatementAdjustmentComponent,
              public matterService: MatterService,
              public documentProfileCache: DocumentProfileCache,
              public matterParticipantService: MatterParticipantService,
              public undertakingsConfigService: UndertakingsConfigService) {

  }

  copyMatterOpeningFields(sourceMatter: Matter, linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      // Matter Opening Fields
      linkedMatter.statementOfAdjustmentHeading = sourceMatter.statementOfAdjustmentHeading;
      if (linkedMatter.statementOfAdjustmentHeading) {
        linkedMatter.statementOfAdjustmentHeading.id = undefined;
      }
      linkedMatter.matterStatementOfAdjustmentFooters = sourceMatter.matterStatementOfAdjustmentFooters;
      if (linkedMatter.matterStatementOfAdjustmentFooters) {
        linkedMatter.matterStatementOfAdjustmentFooters.forEach(item => {
          item.id = undefined;
        });
      }
      linkedMatter.paysForDateOfClosing = sourceMatter.paysForDateOfClosing;
      if (sourceMatter.actingFor && !linkedMatter.isProjectSale) {
        linkedMatter.actingFor = sourceMatter.isPurchase ? 'VENDOR_PURCHASER' : 'PURCHASER_VENDOR';
        //For now the sourceMatter doesn't have matterLink because it is in middle of coping matter link data
        //Copy the solicitor and lawClerk of source Matter tab A to the linked matter tab c
        this.addSolicitorLawFirmLawClerkToLinkedMatterOtherPartySide(sourceMatter, linkedMatter);
        //If it selects the existing matter as a linked matter,
        //copy the solicitor and lawClerk of linked Matter tab A to the source matter tab c
        // The isSolicitorOrLawClerkDirty of linkedMatter and source matter can be true only select the existing matter to build the linked matter
        if (linkedMatter.isSolicitorOrLawClerkDirty) {
          this.addSolicitorLawFirmLawClerkToLinkedMatterOtherPartySide(linkedMatter, sourceMatter);
        }
      }
      if (sourceMatter.purchaseSaleAgreementDate) {
        linkedMatter.purchaseSaleAgreementDate = sourceMatter.purchaseSaleAgreementDate;
      }
      linkedMatter.occupancyDate = sourceMatter.occupancyDate;
      if (sourceMatter.protocolClosing) {
        linkedMatter.protocolClosing = sourceMatter.protocolClosing;
      }
      if (sourceMatter.interestRateSummary) {
        linkedMatter.interestRateSummary = sourceMatter.interestRateSummary;
      }
      if (sourceMatter.matterCloseDate !== linkedMatter.matterCloseDate) {
        this.documentProfileService.getById(linkedMatter.documentProfileId, sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId), false, linkedMatter).subscribe(cachedDocumentProfile => {
          this.onDateChangeClosingDate(sourceMatter.matterCloseDate, linkedMatter, cachedDocumentProfile);
        });
      }
      if (sourceMatter.possessionTime) {
        linkedMatter.possessionTime = sourceMatter.possessionTime;
      }
      linkedMatter.closingDatePayment = this.copyClosingDatePayment(sourceMatter.closingDatePayment, linkedMatter.id);
      if (sourceMatter.cashOnClosingDate) {
        linkedMatter.cashOnClosingDate = sourceMatter.cashOnClosingDate;
        linkedMatter.onCashClosingChange();
      }
    });

    if (linkedMatter.isMatterProvinceON) {
      linkedMatter.registrationMethodCode = sourceMatter.registrationMethodCode;
    }
  }

  copyClosingDatePayment(sourceClosingDatePayment: ClosingDatePayment, matterId: number): ClosingDatePayment {
    let newClosingDatePayment = new ClosingDatePayment(sourceClosingDatePayment);
    newClosingDatePayment.id = null;
    newClosingDatePayment.matterId = matterId;
    if (newClosingDatePayment.lateClosingInterests && newClosingDatePayment.lateClosingInterests.length) {
      newClosingDatePayment.lateClosingInterests.forEach((lateClosingInterest: LateClosingInterest) => {
        lateClosingInterest.id = null;
        lateClosingInterest.closingDatePaymentId = undefined;
      });
    }
    return newClosingDatePayment;
  }

  initPurchaserComponent(linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      if (this.purchaserComponent) {
        this.purchaserComponent.setLocalInstanceMatter(linkedMatter);
        //It should not add main client Tenure waring message into this tab and should add the link active tab.
        //For purchaserComponent, we keep source matter cirf. Don't need to replace with linked matter cirf.
        this.purchaserComponent.ngOnInit(true, true);
        this.purchaserComponent.initPurchaserComponent();
      }
    });
  }

  initVendorSolicitorComponent(linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      if (this.vendorsSolicitorComponent) {
        this.vendorsSolicitorComponent.setLocalInstanceMatter(linkedMatter);
        //It should not add other side client Tenure waring message into this tab and should add the link active tab.
        this.vendorsSolicitorComponent.ngOnInit(true);
        this.vendorsSolicitorComponent.initVendorSolicitorStructure();
      }
    });
  }

  initFireInsurerComponent(linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      if (this.fireInsuranceComponent) {
        this.fireInsuranceComponent.setLocalInstanceMatter(linkedMatter);
        this.fireInsuranceComponent.fireInsuranceOpening();
      }
    });
  }

  initPropertyTeranetComponent(linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      this.callSynchronously(() => {
        if (this.propertyTeranetComponent) {
          this.propertyTeranetComponent.setLocalInstanceMatter(linkedMatter);
          if (this.propertyTeranetComponent.brokerCommissionComponent) {
            this.propertyTeranetComponent.brokerCommissionComponent.setLocalInstanceMatter(linkedMatter);
          }
          this.propertyTeranetComponent.ngOnInit();
          this.propertyTeranetComponent.openProperty();
          this.propertyTeranetComponent.initiSoAdjOtherProrated();
          this.documentProfileService.getById(linkedMatter.documentProfileId, sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId), false, linkedMatter).subscribe(cachedDocumentProfile => {
            this.propertyTeranetComponent.initializeSoAdjUtil(cachedDocumentProfile);
          });
        }
      }).subscribe(isMethodCompleted => {
        if (isMethodCompleted) {
          this.initCondoCorporationComponent(linkedMatter);
        }
      });
    });
  }

  initCondoCorporationComponent(linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      if (this.condoCorporationComponent) {
        this.condoCorporationComponent.setLocalInstanceMatter(linkedMatter);
        this.condoCorporationComponent.ngOnInit();
        this.condoCorporationComponent.openCondoCorporation();
        this.setAttentionInfoComponentInCondoCorp(linkedMatter);
      }
    });
  }

  initStatementAdjustmentComponent(linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(async () => {
      if (this.statementAdjustmentComponent) {
        this.statementAdjustmentComponent.setLocalInstanceMatter(linkedMatter);
        await this.statementAdjustmentComponent.initSoAdjComponent();
        await this.statementAdjustmentComponent.setUpStatementOfAdjustment();
      }
    });
  }

  callSynchronously(callback: Function): Observable<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    callback();
    let serviceInstance = this;
    let scheduler = setInterval(() => {

      if (serviceInstance.http.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 500);
    return returnSubject;
  }

  //Adding a @ViewChild component in CondoCorp component
  setAttentionInfoComponentInCondoCorp(linkedMatter: Matter): void {
    this.condoCorporationComponent.attentionInfoComponent = this.attentionInfoComponent;
    //Below code is setting @Input variables into child component
    this.attentionInfoComponent.matter = linkedMatter;
    this.updateParentParticipantInAttentionInfoComponent(linkedMatter);
    //After setting input variables initializing child component
    this.condoCorporationComponent.attentionInfoComponent.buildAttentionWrapper();
  }

  //This method updates the input variaable for attention component. it should be called everytime input variable is changed.
  updateParentParticipantInAttentionInfoComponent(linkedMatter: Matter): void {
    if (linkedMatter.condoCorporation) {
      if (linkedMatter.condoCorporation.contact.isSelfManaged()) {
        this.attentionInfoComponent.parentMatterParticipant = linkedMatter.condoCorporation;
      } else if (linkedMatter.condoCorporation.contact.isManagedByManagementCompany() && linkedMatter.managementCompany) {
        this.attentionInfoComponent.parentMatterParticipant = linkedMatter.managementCompany;
      }
    }
  }

  copyStatementAdjustment(sourceMatter: Matter, linkedMatter: Matter): Observable<boolean> {
    if (sourceMatter.isPurchase && linkedMatter.isProjectSale) {
      return Observable.of(true);
    } else if (sourceMatter.isProjectSale && linkedMatter.isPurchase) {
      if (sourceMatter.isMatterProvinceAB) {
        linkedMatter.soAdjustmentTaxRateFinal.hstRate = sourceMatter.soAdjustmentTaxRateFinal.hstRate;
        linkedMatter.soAdjustmentTaxRateInterim.hstRate = sourceMatter.soAdjustmentTaxRateInterim.hstRate;
        linkedMatter.soAdjustmentTaxRateFinal.federalHstRate = sourceMatter.soAdjustmentTaxRateFinal.federalHstRate;
        linkedMatter.soAdjustmentTaxRateInterim.federalHstRate = sourceMatter.soAdjustmentTaxRateInterim.federalHstRate;
        linkedMatter.soAdjustmentTaxRateFinal.provincialHstRate = sourceMatter.soAdjustmentTaxRateFinal.provincialHstRate;
        linkedMatter.soAdjustmentTaxRateInterim.provincialHstRate = sourceMatter.soAdjustmentTaxRateInterim.provincialHstRate;
        linkedMatter.matterTaxType = sourceMatter.matterTaxType;
      }
      linkedMatter.adjustAsAtClosingDateFlag = sourceMatter.adjustAsAtClosingDateFlag;
      linkedMatter.adjustAsAtClosingDate = sourceMatter.adjustAsAtClosingDate;
      linkedMatter.adjustAsAtClosingDateFlagInterim = sourceMatter.adjustAsAtClosingDateFlagInterim;
      linkedMatter.adjustAsAtClosingDateInterim = sourceMatter.adjustAsAtClosingDateInterim;
      linkedMatter.extraDepositConfig = new MatterExtraDepositConfig(sourceMatter.extraDepositConfig);

      let soaPropertyTaxesByRollNumberMap: Map<number, MatterTax> = this.createSOAPropertyTaxesRollNumberMap(linkedMatter);

      linkedMatter._interimAdjustments = [];
      linkedMatter._finalAdjustments = [];

      let idMappings: Map<number, number> = new Map();

      sourceMatter._interimAdjustments.forEach(adj => {
        let updatedAdj = new StatementAdjustment(adj.adjustmentStatus, adj.provinceCode, adj);
        MatterIdCleaner.cleanAllIds(updatedAdj, idMappings, linkedMatter);
        linkedMatter._interimAdjustments.push(updatedAdj);
      });

      linkedMatter.interimStatementAdjustmentOrders = sourceMatter.interimStatementAdjustmentOrders.map(adj => {
        return new StatementAdjustmentOrder(adj);
      });

      sourceMatter._finalAdjustments.forEach(adj => {
        let updatedAdj = new StatementAdjustment(adj.adjustmentStatus, adj.provinceCode, adj);
        MatterIdCleaner.cleanAllIds(updatedAdj, idMappings, linkedMatter);
        linkedMatter._finalAdjustments.push(updatedAdj);
      });

      linkedMatter.finalStatementAdjustmentOrders = sourceMatter.finalStatementAdjustmentOrders.map(adj => {
        return new StatementAdjustmentOrder(adj);
      });

      linkedMatter.selectedProgressionStatus = sourceMatter.selectedProgressionStatus;

      if (sourceMatter.statementOfAdjustmentHeading) {
        linkedMatter.statementOfAdjustmentHeading = new StatementOfAdjustmentHeading(sourceMatter.statementOfAdjustmentHeading);
        linkedMatter.statementOfAdjustmentHeading.id = null;
      }
      if (sourceMatter.statementOfAdjustmentPayable) {
        linkedMatter.statementOfAdjustmentPayable = new StatementOfAdjustmentPayable(sourceMatter.statementOfAdjustmentPayable);
        linkedMatter.statementOfAdjustmentPayable.id = null;
      }
      if (sourceMatter.considerationLtt && sourceMatter.considerationLtt) {
        linkedMatter.considerationLtt.copyFrom(sourceMatter.considerationLtt);
        this.cleanupLinkedMatterSalePriceAdjustmentHeadings(linkedMatter);
      }

      linkedMatter.matterStatementOfAdjustmentFooters = [];
      if (sourceMatter.matterStatementOfAdjustmentFooters && sourceMatter.matterStatementOfAdjustmentFooters.length) {
        sourceMatter.matterStatementOfAdjustmentFooters.forEach((statementAdjustmentConfig) => {
          let newStatementAdjustmentConfig = new StatementAdjustmentConfig(statementAdjustmentConfig);
          newStatementAdjustmentConfig.id = null;
          linkedMatter.matterStatementOfAdjustmentFooters.push(newStatementAdjustmentConfig);
        });
      }

      this.retainPropertyTaxDataInLinkedMatter(linkedMatter, soaPropertyTaxesByRollNumberMap, true);

      linkedMatter.clearCircularReferencesBeforeStringify();
      let matterJson: string = JSON.stringify(linkedMatter);
      idMappings.forEach((newId, existingId) => {
        if (existingId && newId) {
          //Looking for exact match and everywhere in existingId to replace it with the newId
          let oldId = new RegExp('\\b' + existingId.toString() + '\\b', 'g');
          matterJson = matterJson.replace(oldId, newId.toString());
        }
      });
      linkedMatter = new Matter(JSON.parse(matterJson) as Matter);

      return this.callSynchronously(async () => {
        let isMethodCompleted: boolean = await this.initStatementAdjustmentComponent(linkedMatter).toPromise();
        if (isMethodCompleted) {
          await this.statementAdjustmentComponent.enableSave();
        }
      });
    } else {
      return this.callSynchronously(async () => {
        linkedMatter.suppressSkPstRebateCreditVendorWarning = !MatterErrorUtil.hasSkPstRebateCreditVendorWarning(sourceMatter);
        linkedMatter.suppressSkPstRebateCreditPurchaserWarning = !MatterErrorUtil.hasSkPstRebateCreditPurchaserWarning(sourceMatter);
        linkedMatter.suppressSkPstSalePriceCreditVendorWarning = !MatterErrorUtil.hasSkPstSalePriceCreditVendorWarning(sourceMatter);
        let isMethodCompleted: boolean = await this.initStatementAdjustmentComponent(linkedMatter).toPromise();

        if (isMethodCompleted) {
          linkedMatter.adjustAsAtClosingDateFlag = sourceMatter.adjustAsAtClosingDateFlag;
          linkedMatter.adjustAsAtClosingDate = sourceMatter.adjustAsAtClosingDate;
          let soaPropertyTaxesByRollNumberMap: Map<number, MatterTax> = this.createSOAPropertyTaxesRollNumberMap(linkedMatter);
          let addedAdjustments = linkedMatter.statementOfAdjustments.filter(soa => soa.addedFlag);
          for (let item of addedAdjustments) {
            await this.statementAdjustmentComponent.soaUtils.removeAdjustmentAndUpdateMatter(item);
          }
          if (sourceMatter.statementOfAdjustmentPayable) {
            linkedMatter.statementOfAdjustmentPayable = new StatementOfAdjustmentPayable(sourceMatter.statementOfAdjustmentPayable);
            linkedMatter.statementOfAdjustmentPayable.id = null;
          }
          let linkedMatterAddedFlagCopy = linkedMatter.statementOfAdjustments.filter(soa => !soa.addedFlag || (soa.addedFlag && soa.isSalePrice())
            || (soa.addedFlag && soa.isSalePriceAdjustmentHeading())).slice(0);
          linkedMatter.clearStatementOfAdjustments();
          for (let index = 0; index < sourceMatter.statementOfAdjustments.length; index++) {
            let statementAdjustment = sourceMatter.statementOfAdjustments[ index ];

            if (!statementAdjustment.addedFlag && linkedMatterAddedFlagCopy) {
              if (statementAdjustment.isAnyPropertyTaxes()) {
                let matterPropertyIndex = sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.matterTaxesByRollNumber ? sourceMatter.matterPropertyWithCondo.matterTaxesByRollNumber.findIndex(mp => mp.id == statementAdjustment.propertyMatterTaxId) : -1;
                if (matterPropertyIndex > -1 && linkedMatter.matterPropertyWithCondo && linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber && matterPropertyIndex < linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber.length) {
                  let matterProperty = linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber[ matterPropertyIndex ];
                  if (matterProperty) {
                    let statementOfAdjustmentFound = linkedMatterAddedFlagCopy.find(soa => soa.itemKey == statementAdjustment.itemKey && soa.propertyMatterTaxId ==
                      matterProperty.id);
                    if (statementOfAdjustmentFound) {
                      statementOfAdjustmentFound.infoOnly = statementAdjustment.infoOnly;
                      linkedMatter.statementOfAdjustments.splice(index, 0, statementOfAdjustmentFound);
                    }
                  }
                }
              } else {
                /*                                let statementOfAdjustmentFound = linkedMatterAddedFlagCopy.find((soa: StatementAdjustment) =>
                                                    (soa.itemKey == statementAdjustment.itemKey && !soa.isHstAdjustment() && !soa.isCommonExpenseAdjustmentFromProperty()) ||
                                                    (soa.isHstAdjustment() && statementAdjustment.isHstAdjustment() && soa.hstSalePrice && statementAdjustment.hstSalePrice && soa.hstSalePrice.hstType == statementAdjustment.hstSalePrice.hstType) ||
                                                    (soa.isCommonExpenseAdjustmentFromProperty() && // for MB on "Create separate Common Element Fee adjustments for each Title" we could have multiple CommonExpenses for each parcel description
                                                        (_.findIndex(linkedMatter.matterPropertyWithCondo.parcelLegalDescriptions, {id: soa.parcelLegalDescriptionId})) ===
                                                        (_.findIndex(sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions, {id: statementAdjustment.parcelLegalDescriptionId}))
                                                    )
                                                );*/
                let statementOfAdjustmentFound = linkedMatterAddedFlagCopy.filter(soa => soa.itemKey == statementAdjustment.itemKey)
                .find((soa: StatementAdjustment) => {
                    if (sourceMatter.isMatterProvinceMB && soa.isCommonExpenseAdjustmentFromProperty()) {
                      // for MB on "Create separate Common Element Fee adjustments for each Title" we could have multiple CommonExpenses for each parcel description
                      return (_.findIndex(linkedMatter.matterPropertyWithCondo.parcelLegalDescriptions, {id: soa.parcelLegalDescriptionId})) ===
                        (_.findIndex(sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions, {id: statementAdjustment.parcelLegalDescriptionId}));
                    }
                    if (soa.isHstAdjustment()) {
                      return soa.hstSalePrice && statementAdjustment.hstSalePrice && soa.hstSalePrice.hstType == statementAdjustment.hstSalePrice.hstType;
                    }

                    return true;
                  }
                );

                if (statementOfAdjustmentFound) {
                  statementOfAdjustmentFound.infoOnly = statementAdjustment.infoOnly;
                  if (!linkedMatter.statementOfAdjustments.find(adj => adj.id == statementOfAdjustmentFound.id)) {
                    linkedMatter.statementOfAdjustments.splice(index, 0, statementOfAdjustmentFound);
                  }
                }
              }
            } else if (statementAdjustment.isSalePriceAdjustmentHeading() && statementAdjustment.addedFlag) {
              let statementOfAdjustmentFound = linkedMatterAddedFlagCopy.find(soa => soa.isSalePriceAdjustmentHeading() && soa.addedFlag
                && !linkedMatter.statementOfAdjustments.some(adj => adj.id == soa.id));
              if (statementOfAdjustmentFound) {
                linkedMatter.statementOfAdjustments.splice(index, 0, statementOfAdjustmentFound);
              }
            } else if (statementAdjustment.isSalePrice() && statementAdjustment.addedFlag) {
              // Sale Price Option 2
              let statementOfAdjustmentFound = linkedMatterAddedFlagCopy.find(soa => soa.isSalePrice() && soa.addedFlag);
              if (statementOfAdjustmentFound) {
                linkedMatter.statementOfAdjustments.splice(index, 0, statementOfAdjustmentFound);
              }
            } else if (statementAdjustment.isHomeOwnersResidentFee()) {
              // Home Owner
              this.statementAdjustmentComponent.soaFulfillmentService.onHomeOwnersResidentFeeModalUpdate(statementAdjustment.soAdjHomeOwnersResidentFee, StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE);
            } else if (statementAdjustment.isTarionWarranty()) {
              // Tarion
              await this.statementAdjustmentComponent.soaFulfillmentService.onTarionWarrantyUpdate(statementAdjustment.soAdjTarionWarranty);
            } else if (statementAdjustment.isHCRAFee()) {
              // hcra
              await this.statementAdjustmentComponent.soaFulfillmentService.onHCRAFeeUpdate(statementAdjustment.soAdjHCRAFee);
            } else if (statementAdjustment.isVacantOccupancyTax()) {
              await this.statementAdjustmentComponent.soaFulfillmentService.onVacantOccupancyTaxUpdate(statementAdjustment.soAdjVacantOccupancyTax);
            } else if (statementAdjustment.isAssumedMortgage()) {
              // Assumed Mortgage
              if (statementAdjustment.soAdjAssumedMortgage) {
                statementAdjustment.soAdjAssumedMortgage.updateTotalAmount(linkedMatter.getClosingDate(), linkedMatter.isPaysForDateOfClosingVendor);
              }
              this.statementAdjustmentComponent.soaFulfillmentService.onAssumedMortgageUpdate(statementAdjustment.soAdjAssumedMortgage);
            } else if (statementAdjustment.isTenancyPrepaid()) {
              // Tenancy Prepaid
              this.statementAdjustmentComponent.soaFulfillmentService.onTenancyPrepiadUpdate(statementAdjustment.soAdjTenancyPrepaid);
            } else if (statementAdjustment.isTenancyCurrent()) {
              // Tenancy Current
              this.statementAdjustmentComponent.soaFulfillmentService.onTenancyCurrentUpdate(statementAdjustment.soAdjTenancyCurrent);
            } else if (statementAdjustment.isAnyPropertyTaxes() && !statementAdjustment.isOriginPropertyTaxes()) {
              let rollNumberPropertyTaxesResult = new RollNumberPropertyTaxesResult();
              rollNumberPropertyTaxesResult.propertyTax = new MatterTax(statementAdjustment.matterTax);
              rollNumberPropertyTaxesResult.infoOnly = statementAdjustment.infoOnly;
              this.updateDataFromLinkedMatterMap(rollNumberPropertyTaxesResult.propertyTax, soaPropertyTaxesByRollNumberMap, index);
              await this.statementAdjustmentComponent.soaFulfillmentService.onPropertyTaxUpdate(rollNumberPropertyTaxesResult, statementAdjustment.itemKey);
            } else if (statementAdjustment.isHstAdjustment() && statementAdjustment.hstSalePrice) {
              await this.statementAdjustmentComponent.soaFulfillmentService.onHSTModalUpdate(statementAdjustment.hstSalePrice);
            } else if ((statementAdjustment.isCommonExpenseAdjustment() || statementAdjustment.isOccupancyCommonExpenseAdjustment() || statementAdjustment.isPOTLCommonExpenseAdjustment()) && statementAdjustment.soaCommonExpense) {
              this.statementAdjustmentComponent.soaFulfillmentService.onCommonExpenseModalUpdate(statementAdjustment.soaCommonExpense, false, null, statementAdjustment.infoOnly);
            } else if (statementAdjustment.isWater()) {
              this.statementAdjustmentComponent.soaFulfillmentService.onWaterAdjustmentUpdate(statementAdjustment.soaWater);
            } else if (statementAdjustment.isHeat()) {
              this.statementAdjustmentComponent.soaFulfillmentService.onHeatAdjustmentUpdate(statementAdjustment.soaHeat);
            } else if (statementAdjustment.isTaxOther()) {
              this.statementAdjustmentComponent.soaFulfillmentService.onTaxAdjustmentUpdate(statementAdjustment.soAdjTaxOther);
            } else if (statementAdjustment.isHSTOtherFactor()) {
              this.statementAdjustmentComponent.soaFulfillmentService.onTaxFactorUpdate(statementAdjustment.hstSalePrice);
            } else if (statementAdjustment.isInterest() || statementAdjustment.isInterestOnLumpSum() || statementAdjustment.isInterestOnDeferredMoniesDuringOccupancy()) {
              this.statementAdjustmentComponent.soaFulfillmentService.onInterestAdjustmentUpdate(statementAdjustment.soAdjInterestCal, statementAdjustment.itemKey);
            } else if (statementAdjustment.isOtherFixed()) {
              await this.statementAdjustmentComponent.soaFulfillmentService.onOtherFixedAdjustmentUpdate(statementAdjustment.soAdjOtherFixed);
            } else if (statementAdjustment.isOtherFixedPayableOnOccupancy()) {
              await this.statementAdjustmentComponent.soaFulfillmentService.onOtherFixedPayableOnOccupancyAdjustmentUpdate(statementAdjustment.soAdjOtherFixedPayableOnOccupancy);
            } else if (statementAdjustment.isOtherProrated()) {
              this.statementAdjustmentComponent.soaFulfillmentService.onOtherProRatedAdjustmentUpdate(StatementAdjustmentKey.OTHER_PRORATED, statementAdjustment.soAdjOtherProrated);
            } else if (statementAdjustment.isOtherProratedOnPercentageInterest()) {
              await this.statementAdjustmentComponent.soaFulfillmentService.onOtherProRatedOnPercentageInterestAdjustmentUpdate(StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST, statementAdjustment.soAdjOtherProratedOnPercentageInterest);
            }
            await this.statementAdjustmentComponent.enableSave();
          }
        }
        linkedMatter.suppressSkPstRebateCreditVendorWarning = false;
        linkedMatter.suppressSkPstRebateCreditPurchaserWarning = false;
        linkedMatter.suppressSkPstSalePriceCreditVendorWarning = false;
      });
    }
  }

  retrieveReconciledBorrower(removedReconciledBorrowerList: RemovedReconciledBorrowerRelatedData[], mp: MatterParticipant) {
    if (Array.isArray(removedReconciledBorrowerList) && mp && mp.contact && mp.contact.sourceContactId) {
      let reconciledBorrowerData: RemovedReconciledBorrowerRelatedData = removedReconciledBorrowerList.find(item => item.sourceContactId == mp.contact.sourceContactId);
      if (reconciledBorrowerData) {
        if (reconciledBorrowerData.stewartMortgageInstruction && reconciledBorrowerData.reconciledBorrower) {
          if (!Array.isArray(reconciledBorrowerData.stewartMortgageInstruction.reconciledBorrowers)) {
            reconciledBorrowerData.stewartMortgageInstruction.reconciledBorrowers = [];
          }
          let reconciledBorrower = new BorrowerMapping(reconciledBorrowerData.reconciledBorrower);
          reconciledBorrower.matterParticipantId = mp.matterParticipantId;
          reconciledBorrower.id = null;
          reconciledBorrowerData.stewartMortgageInstruction.reconciledBorrowers.push(reconciledBorrower);
        }
      }
    }

  }

  copyVendorAndPurchaser(sourceMatter: Matter, linkedMatter: Matter): Observable<boolean> {

    return this.callSynchronously(() => {
      //Tenure
      if (sourceMatter.isPurchase) {
        linkedMatter.otherPartiesCapacity = sourceMatter.purchasersCapacity;
      } else if (sourceMatter.isSale) {
        linkedMatter.purchasersCapacity = sourceMatter.otherPartiesCapacity;
      }

      // In Case of Source Matter as Purchase - Linked Matter as Sale
      // we copy purchaser (Purchase Matter) to purchaserandvendor (Sale matter)
      //For ON and Sk, tab B and tab c both have FLA. We need to do the FLA sync for both
      //For AB and MB, only tab B has FlA. Only keep the tab B original FLA.
      if ((sourceMatter.mainClients && sourceMatter.mainClients.length > 0) || (linkedMatter.otherSideClients && linkedMatter.otherSideClients.length > 0)) {
        this.initVendorSolicitorComponent(linkedMatter).subscribe(isMethodCompleted => {
          if (isMethodCompleted) {
            linkedMatter.otherSideClients.forEach(mainClient => {
              if (this.vendorsSolicitorComponent.selectedOtherParties && this.vendorsSolicitorComponent.selectedOtherParties.length > 0) {
                let matterParticipantWrapper = this.vendorsSolicitorComponent.selectedOtherParties.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == mainClient.matterParticipantId);
                if (matterParticipantWrapper) {
                  this.vendorsSolicitorComponent.deleteOtherPartyParticipant(matterParticipantWrapper, true);
                }
              }
            });
            this.vendorsSolicitorComponent.selectedOtherParties = [];
            sourceMatter.mainClients.forEach((item, index) => {
              let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
              matterParticipantWrapper.dataModel = {};
              this.vendorsSolicitorComponent.selectedOtherParties.push(matterParticipantWrapper);
              //It should not add other side client Tenure waring message into this tab and should add the link active tab.
              this.vendorsSolicitorComponent.createOtherPartyParticipant(item.contact, matterParticipantWrapper, index, true, true);
              if (matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.contact) {
                matterParticipantWrapper.matterParticipant.primary = item.primary;
                matterParticipantWrapper.matterParticipant.contact.idInfoEnteredBy = item.contact.idInfoEnteredBy;
                matterParticipantWrapper.matterParticipant.contact.contactIdInfoEnteredBy = item.contact.contactIdInfoEnteredBy;
                matterParticipantWrapper.matterParticipant.contact.enteredOn = item.contact.enteredOn;
                matterParticipantWrapper.matterParticipant.contact.sourceContactId = item.contact.sourceContactId;
                matterParticipantWrapper.matterParticipant.contact.sourceParentOrganizationId = item.contact.sourceParentOrganizationId;
                matterParticipantWrapper.matterParticipant.sourceContact = item.sourceContact;
                matterParticipantWrapper.matterParticipant.contact.sourceParentOrganizationId = item.contact.sourceParentOrganizationId;
                matterParticipantWrapper.matterParticipant.contact.lastSyncedFromSource = item.contact.lastSyncedFromSource;
                matterParticipantWrapper.matterParticipant.purchaserCapacity = item.purchaserCapacity;
                matterParticipantWrapper.matterParticipant.purchaserShare = item.purchaserShare;
                if (sourceMatter.isMatterProvinceONorSK) {
                  this.updateFlaDataForONorSK(item, matterParticipantWrapper.matterParticipant, sourceMatter, linkedMatter);
                }
              }

            });
            if (linkedMatter.otherSideClients) {
              let primaryMatterParticipant = linkedMatter.otherSideClients.find(item => item.primary);
              if (primaryMatterParticipant) {
                let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
                matterParticipantWrapper.matterParticipant = primaryMatterParticipant;
                this.vendorsSolicitorComponent.setAsPrimaryOtherParty(matterParticipantWrapper);
              }
              if (linkedMatter.isMatterProvinceONorSK) {
                //After all Participants are  finished to build, we update the matterParticipantId and spouseMatterParticipantId of fla
                this.updateLinkFlaParticipantsIdsForONorSK(sourceMatter.mainClients, linkedMatter.otherSideClients);
              }
            }
          }

          if (sourceMatter.isPurchase) { //sync title details from Purchase (Purchasers) to Sale (Purchasers & Solicitor)
            linkedMatter.otherPartyContactInfo.titleDetailsType = sourceMatter.matterContactInfo.titleDetailsType;
            if (sourceMatter.isTitleDetailsManual()) {
              linkedMatter.otherPartyContactInfo.titleDetails = sourceMatter.matterContactInfo.titleDetails;
            }
          }

          this.vendorsSolicitorComponent.getTitleDetails; //needed to set matter.otherPartyContactInfo.titleDetails
        });
      }
      if ((sourceMatter.otherSideClients && sourceMatter.otherSideClients.length > 0) || (linkedMatter.mainClients && linkedMatter.mainClients.length > 0)) {
        let removedReconciledBorrowerList: RemovedReconciledBorrowerRelatedData[] = [];
        this.initPurchaserComponent(linkedMatter).subscribe(isMethodCompleted => {
          if (isMethodCompleted) {
            let mainClients = [];
            if (linkedMatter.mainClients) {
              linkedMatter.mainClients.forEach(mainClient => mainClients.push(new MatterParticipant(mainClient)));
            }
            linkedMatter.mainClients.forEach(mainClient => {
              if (this.purchaserComponent.selectedClientPurchasers && this.purchaserComponent.selectedClientPurchasers.length > 0) {
                let matterParticipantWrapper = this.purchaserComponent.selectedClientPurchasers.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == mainClient.matterParticipantId);
                if (matterParticipantWrapper) {
                  this.purchaserComponent.removeClientMatterParticipant(matterParticipantWrapper, true, removedReconciledBorrowerList);
                }
              }
            });
            this.purchaserComponent.selectedClientPurchasers = [];
            let matterParticipantMap: Map<number, number> = new Map(); // maps old matterPArticipantId to new ones to restore the ids for FamilyLawAct
            sourceMatter.otherSideClients.forEach((item, index) => {
              let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
              matterParticipantWrapper.dataModel = {};
              this.purchaserComponent.selectedClientPurchasers.push(matterParticipantWrapper);
              // createMatterParticipantForClient(contact : Contact ,  activePurchaserWrapper : MatterParticipantWrapper , i : number ,
              //     hideConfirmCurrentPrimaryAddressDialog? : boolean, isMassUpdate? : boolean, excludeCapacityCheckWarning ?: boolean)
              //It should not add main client Tenure waring message into this tab and should add the link active tab.
              this.purchaserComponent.createMatterParticipantForClient(item.contact, matterParticipantWrapper, index,
                true, true, true);
              this.retrieveReconciledBorrower(removedReconciledBorrowerList, matterParticipantWrapper.matterParticipant);
              if (matterParticipantWrapper.matterParticipant && matterParticipantWrapper.matterParticipant.contact) {
                if (mainClients && !sourceMatter.isMatterProvinceONorSK) {
                  let matterParticipant = mainClients.find(mainClient => mainClient.contact && mainClient.contact.sourceContactId == item.contact.sourceContactId);
                  if (matterParticipant && matterParticipant.familyLawActs) {
                    matterParticipantMap.set(matterParticipant.matterParticipantId, matterParticipantWrapper.matterParticipant.matterParticipantId);
                    let updatedFamilyLawActs = [];
                    matterParticipant.familyLawActs.forEach(familyLawActObj => {
                      let familyLawAct = new FamilyLawAct(familyLawActObj);
                      if (familyLawAct.consentedSpouse) {
                        familyLawAct.consentedSpouse = null;
                      }
                      familyLawAct.id = undefined;
                      updatedFamilyLawActs.push(familyLawAct);
                    });
                    matterParticipantWrapper.matterParticipant.familyLawActs = updatedFamilyLawActs;
                    let fla = matterParticipantWrapper.matterParticipant.getConsentedSpouseFamilyLawAct();
                    if (fla && fla.consentedSpouseParticipant && fla.consentedSpouseParticipant.contact) {
                      fla.consentedSpouseParticipant.matterParticipantId = UUIDUtil.getUUID();
                      fla.consentSpouseMatterParticipantId = fla.consentedSpouseParticipant.matterParticipantId;
                      let copyContact: Contact = new Contact();
                      copyContact.createNewContactClone(fla.consentedSpouseParticipant.contact);
                      fla.consentedSpouseParticipant.contact = copyContact;
                      fla.consentedSpouseParticipant.parentParticipantId = matterParticipantWrapper.matterParticipant.matterParticipantId;
                      linkedMatter.matterParticipants.push(fla.consentedSpouseParticipant);
                    }
                  }
                }
                if (sourceMatter.isMatterProvinceONorSK) {
                  this.updateFlaDataForONorSK(item, matterParticipantWrapper.matterParticipant, sourceMatter, linkedMatter);
                }
                matterParticipantWrapper.matterParticipant.purchaserCapacity = item.purchaserCapacity;
                matterParticipantWrapper.matterParticipant.purchaserShare = item.purchaserShare;
                matterParticipantWrapper.matterParticipant.contact.idInfoEnteredBy = item.contact.idInfoEnteredBy;
                matterParticipantWrapper.matterParticipant.contact.contactIdInfoEnteredBy = item.contact.contactIdInfoEnteredBy;
                matterParticipantWrapper.matterParticipant.contact.enteredOn = item.contact.enteredOn;
                matterParticipantWrapper.matterParticipant.primary = item.primary;
                matterParticipantWrapper.matterParticipant.contact.sourceContactId = item.contact.sourceContactId;
                matterParticipantWrapper.matterParticipant.sourceContact = item.sourceContact;
                matterParticipantWrapper.matterParticipant.contact.lastSyncedFromSource = item.contact.lastSyncedFromSource;

              }
            });
            if (linkedMatter.mainClients) {
              if (matterParticipantMap.size > 0 && linkedMatter.mainClients.length > 0 && !linkedMatter.isMatterProvinceONorSK) {
                // try to restore links to matterParticipantIds as old matterParticipantIds might be present on data copied over from old FamilyAct
                // this cannot be done at the moment of adding a matter participant as the matter participant referred might have not been copied over to the new list of matter participants for linkedMatter
                // another option would've been by using  matterParticipant.setSpouseParticipant()
                linkedMatter.mainClients.forEach(mp => {
                  if (mp.familyLawActs) {
                    mp.familyLawActs.forEach(fla => {
                      fla.matterParticipantId = mp.matterParticipantId;
                      fla.spouseMatterParticipantId = matterParticipantMap.get(fla.spouseMatterParticipantId);
                    });
                  }
                });
              }

              if (linkedMatter.isMatterProvinceONorSK) {
                //After all Participants are  finished to build, we update the matterParticipantId and spouseMatterParticipantId of fla
                this.updateLinkFlaParticipantsIdsForONorSK(sourceMatter.otherSideClients, linkedMatter.mainClients);
              }

              let primaryMatterParticipant = linkedMatter.mainClients.find(item => item.primary);
              if (primaryMatterParticipant) {
                let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
                matterParticipantWrapper.matterParticipant = primaryMatterParticipant;
                this.purchaserComponent.setAsPrimaryPurchaser(matterParticipantWrapper);
              }
            }
          }

          if (sourceMatter.isSale) {  //sync title details from Sale (Purchasers & Solicitor) to Purchase (Purchasers)
            linkedMatter.matterContactInfo.titleDetailsType = sourceMatter.otherPartyContactInfo.titleDetailsType;
            if (sourceMatter.otherPartyContactInfo && sourceMatter.otherPartyContactInfo.isTitleDetailsManual()) {
              linkedMatter.matterContactInfo.titleDetails = sourceMatter.otherPartyContactInfo.titleDetails;
            }
          }

          this.purchaserComponent.getTitleDetails; //needed for setting matter.matterContactInfo.titleDetails
        });
      }
      this.copyDocumentsExecutedAt(sourceMatter, linkedMatter);
      this.copyFileNumber(sourceMatter, linkedMatter);
    });
  }

  updateFlaDataForONorSK(sourceParticipant: MatterParticipant, linkParticipant: MatterParticipant, sourceMatter: Matter, linkedMatter: Matter) {
    linkParticipant.familyLawActs = [];
    sourceParticipant.familyLawActs.forEach(familyLawActObj => {
      let familyLawAct = new FamilyLawAct(familyLawActObj);
      if (familyLawAct.consentedSpouse) {
        familyLawAct.consentedSpouse = null;
      }
      familyLawAct.id = undefined;
      linkParticipant.familyLawActs.push(familyLawAct);
      if (linkParticipant.getConsentedSpouseFamilyLawAct()) {
        linkedMatter.removeMatterParticipant(familyLawAct.consentedSpouseParticipant);
        familyLawAct.consentedSpouseParticipant = null;
        familyLawAct.consentSpouseMatterParticipantId = null;
      }
    });
    if (linkParticipant.getConsentedSpouseFamilyLawAct()) {
      this.matterParticipantService.copyConsentingSpouseParticipant(sourceMatter, linkedMatter, sourceParticipant, linkParticipant, true);

    }
  }

  // The matterParticipantId and spouseMatterParticipantId of fla come from source matterParticipant
  // According to source matterParticipant and link matterParticipant have sourceContactId, we found the map rule to update fla participantIds
  getLinkParticipantIdBySourceFlaParticipantId(sourceClients: MatterParticipant[], linkClients: MatterParticipant[], flaMatterParticipantId: number): number {
    let sourceMp: MatterParticipant;
    let linkMp: MatterParticipant;

    if (flaMatterParticipantId) {
      sourceMp = sourceClients.find(item => item.matterParticipantId == flaMatterParticipantId);
      linkMp = sourceMp && sourceMp.matterParticipantId && sourceMp.contact && sourceMp.contact.sourceContactId
        ? linkClients.find(client => client.contact && client.contact.sourceContactId == sourceMp.contact.sourceContactId)
        : null;

    }
    return linkMp && linkMp.matterParticipantId;
  }

  // The matterParticipantId and spouseMatterParticipantId of fla come from source matterParticipant.
  // We should update them with link matterParticipant ids.
  updateLinkFlaParticipantsIdsForONorSK(sourceClients: MatterParticipant[], linkClients: MatterParticipant[]) {
    linkClients.forEach(mp => {
      if (mp.familyLawActs) {
        mp.familyLawActs.forEach(fla => {
          // the matterParticipantId and spouseMatterParticipantId of fla come from source matterParticipant
          // According to source matterParticipant and link matterParticipant have sourceContactId, we found the map rule to update fla participantIds
          fla.matterParticipantId = this.getLinkParticipantIdBySourceFlaParticipantId(sourceClients, linkClients, fla.matterParticipantId);
          fla.spouseMatterParticipantId = this.getLinkParticipantIdBySourceFlaParticipantId(sourceClients, linkClients, fla.spouseMatterParticipantId);
        });
      }
    });
  }

  copyCondominiumData(sourceMatter: Matter, linkedMatter: Matter): void {
    if (linkedMatter.condoCorporation) {
      this.condoCorporationComponent.removeCondoCorporation(false, !sourceMatter.condoCorporation);
    }
    if (sourceMatter.condoCorporation) {
      this.condoCorporationComponent.setContactInCondoCorporationWrapper(sourceMatter.condoCorporation.contact);
      this.updateLinkedParticipantWithSourceContactInfo(linkedMatter.condoCorporation, sourceMatter.condoCorporation);

      if (sourceMatter.managementCompany && sourceMatter.managementCompany.contact) {
        this.condoCorporationComponent.setContactInManagementWrapper(sourceMatter.managementCompany.contact, false);
        this.updateLinkedParticipantWithSourceContactInfo(linkedMatter.managementCompany, sourceMatter.managementCompany);
      }

      //After copying parent participants from source matter updating the input variable in child component again
      this.updateParentParticipantInAttentionInfoComponent(linkedMatter);

      if (sourceMatter.condoCorpAttention && sourceMatter.condoCorpAttention.contact) {
        this.attentionInfoComponent.setContactInAttentionWrapper(sourceMatter.condoCorpAttention.contact, !!sourceMatter.condoCorpAttention.contact.sourceContactId, false);
        this.updateLinkedParticipantWithSourceContactInfo(linkedMatter.condoCorpAttention, sourceMatter.condoCorpAttention);
      }

      if (!linkedMatter.condoCorporationDocumentation) {
        linkedMatter.setCondoCorporationDocumentation();
      }
    }
  }

  /**
   * As linked participant is created with source snapshot contact (to keep data in sync) so this method is copyin source original contact info
   * @param {MatterParticipant} linkedParticipant
   * @param {MatterParticipant} sourceParticipant
   */
  updateLinkedParticipantWithSourceContactInfo(linkedParticipant: MatterParticipant, sourceParticipant: MatterParticipant): void {
    if (linkedParticipant && linkedParticipant.contact) {
      linkedParticipant.contact.sourceContactId = sourceParticipant.contact.sourceContactId;
      linkedParticipant.contact.sourceParentOrganizationId = sourceParticipant.contact.sourceParentOrganizationId;
      linkedParticipant.sourceContact = sourceParticipant.sourceContact;
      linkedParticipant.contact.sourceParentOrganizationId = sourceParticipant.contact.sourceParentOrganizationId;
      linkedParticipant.contact.lastSyncedFromSource = sourceParticipant.contact.lastSyncedFromSource;
      linkedParticipant.attentionName = sourceParticipant.attentionName;
    }
  }

  copyFireInsuranceParticipants(sourceMatter: Matter, linkedMatter: Matter): void {
    if (sourceMatter.insurerMatterParticipant) {
      linkedMatter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.INSURER;
      this.fireInsuranceComponent.createNewMatterInsurerContact(sourceMatter.insurerMatterParticipant.contact);
      this.fireInsuranceComponent.populateFieldsFromInsurerOrBroker(sourceMatter.insurerMatterParticipant.contact);
      this.updateLinkedParticipantWithSourceContactInfo(linkedMatter.insurerMatterParticipant, sourceMatter.insurerMatterParticipant);
    } else if (sourceMatter.brokerMatterParticipant) {
      linkedMatter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.BROKER;
      this.fireInsuranceComponent.createNewMatterBrokerContact(sourceMatter.brokerMatterParticipant.contact);
      this.fireInsuranceComponent.populateFieldsFromInsurerOrBroker(sourceMatter.brokerMatterParticipant.contact);
      this.updateLinkedParticipantWithSourceContactInfo(linkedMatter.brokerMatterParticipant, sourceMatter.brokerMatterParticipant);
    }
  }

  copyMatterProperty(sourceMatter: Matter, linkedMatter: Matter): Observable<boolean> {
    return this.callSynchronously(() => {
      this.initPropertyTeranetComponent(linkedMatter).subscribe(isMethodCompleted => {
        let propertyTaxesByRollNumberMap: Map<number, MatterTax> = this.createPropertyTaxesRollNumberMap(linkedMatter);
        if (isMethodCompleted) {
          if (!linkedMatter.matterPropertyWithCondo) {
            linkedMatter.createMatterPropertyWithCondo();
          }
          linkedMatter.matterPropertyWithCondo.isCondominium = sourceMatter.matterPropertyWithCondo.isCondominium;
          //Delay Verify Pin Values. After finishing the copy, do "Pin" verification.
          this.propertyTeranetComponent.onCondoValueChange(linkedMatter.matterPropertyWithCondo.isCondominium, true);
          this.propertyTeranetComponent.condominiumChange((linkedMatter.isMatterProvinceAB && linkedMatter.isProjectSale));
          if (sourceMatter.matterPropertyWithCondo) {
            let sourceJurisdiction = sourceMatter.matterPropertyWithCondo.jurisdiction;
            let linkedJurisdiction = linkedMatter.matterPropertyWithCondo.jurisdiction;
            if ((sourceJurisdiction && !linkedJurisdiction)
              || (!sourceJurisdiction && linkedJurisdiction)
              || (sourceJurisdiction && linkedJurisdiction && sourceJurisdiction.id != linkedJurisdiction.id)) {
              if (linkedJurisdiction) {
                this.propertyTeranetComponent.removeJurisdictionRelatedFields();
              }
              if (sourceJurisdiction) {
                this.propertyTeranetComponent.selectedJurisdiction = sourceJurisdiction;
                this.propertyTeranetComponent.updateJurisdictionAndAction();
              }
            }
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.address) {
            if (!linkedMatter.matterPropertyWithCondo.address) {
              linkedMatter.matterPropertyWithCondo.address = new Address();
            }
            let sourceMatterAddress = new Address(sourceMatter.matterPropertyWithCondo.address);
            sourceMatterAddress.id = linkedMatter.matterPropertyWithCondo.address.id;
            linkedMatter.matterPropertyWithCondo.address = new Address(sourceMatterAddress);
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.purchaseIsOfCode = sourceMatter.matterPropertyWithCondo.purchaseIsOfCode;
            linkedMatter.isPurchaseTypeValueChanged = true;
          }

          if (sourceMatter.matterPropertyWithCondo.isPropertyCondominium()) {
            linkedMatter.matterPropertyWithCondo.exceptionType = sourceMatter.matterPropertyWithCondo.exceptionType;
            linkedMatter.matterPropertyWithCondo.exceptionTypeDescription = sourceMatter.matterPropertyWithCondo.exceptionTypeDescription;
          } else {
            //hiddenFieldRestrictions 'matter.subjectProperty.condoFields' :[{'AB' :'PSM'}, {'SK' :'PSM'}, {'MB' :'PSM'}, {'NS' : 'PSM'}]
            if (!linkedMatter.isInProvince([ PROVINCE_CODES.ALBERTA, PROVINCE_CODES.MANITOBA, PROVINCE_CODES.SASKATCHEWAN, PROVINCE_CODES.NOVA_SCOTIA ])) {
              linkedMatter.matterPropertyWithCondo.partLot = sourceMatter.matterPropertyWithCondo.partLot;
              linkedMatter.matterPropertyWithCondo.plan = sourceMatter.matterPropertyWithCondo.plan;
              linkedMatter.matterPropertyWithCondo.beingPart = sourceMatter.matterPropertyWithCondo.beingPart;
              linkedMatter.matterPropertyWithCondo.onPlan = sourceMatter.matterPropertyWithCondo.onPlan;
            }
          }

          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.isPlanBlockLot()) {
            this.propertyTeranetComponent.onPlanBlockLotSelection();
            linkedMatter.matterPropertyWithCondo.plan = sourceMatter.matterPropertyWithCondo.plan;
            linkedMatter.matterPropertyWithCondo.lot = sourceMatter.matterPropertyWithCondo.lot;
            linkedMatter.matterPropertyWithCondo.block = sourceMatter.matterPropertyWithCondo.block;
            linkedMatter.matterPropertyWithCondo.exceptionType = sourceMatter.matterPropertyWithCondo.exceptionType;
            linkedMatter.matterPropertyWithCondo.exceptionTypeDescription = sourceMatter.matterPropertyWithCondo.exceptionTypeDescription;
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.isMetesAndBound()) {
            this.propertyTeranetComponent.onMetisAndBoundSelection();
            linkedMatter.matterPropertyWithCondo.shortLegalDescription = sourceMatter.matterPropertyWithCondo.shortLegalDescription;
            linkedMatter.matterPropertyWithCondo.fullLegalDescription = sourceMatter.matterPropertyWithCondo.fullLegalDescription;
            linkedMatter.matterPropertyWithCondo.exceptionTypeDescription = sourceMatter.matterPropertyWithCondo.exceptionTypeDescription;
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.city = sourceMatter.matterPropertyWithCondo.city;
            this.propertyTeranetComponent.updatePropertyTaxPaidTrustLedger();
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.landTitleOfficeLocation = sourceMatter.matterPropertyWithCondo.landTitleOfficeLocation;
            this.propertyTeranetComponent.updateLandTitleOffice();
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.registryOfficeName = sourceMatter.matterPropertyWithCondo.registryOfficeName;

          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.isVendorToSupplyRPR = sourceMatter.matterPropertyWithCondo.isVendorToSupplyRPR;

          }

          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.isTitleSearchPerformed = sourceMatter.matterPropertyWithCondo.isTitleSearchPerformed;
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.isPropertyCondominium()) {
            if (!sourceMatter.isMatterProvinceSK) {
              linkedMatter.updateUnitLevelPlan(sourceMatter.matterPropertyWithCondo);
            }
            linkedMatter.matterPropertyWithCondo.unitLevelPlan = sourceMatter.matterPropertyWithCondo.unitLevelPlan;
            if (sourceMatter.matterPropertyWithCondo.condominiumExpenses) {
              let condominiumExpensesList: CondominiumExpense[] = [];
              sourceMatter.matterPropertyWithCondo.condominiumExpenses.forEach(item => {
                let condominiumExpenses = new CondominiumExpense(item);
                condominiumExpenses.id = undefined;
                if (condominiumExpenses.condominiumPlan) {
                  let condominiumPlan = new CondominiumPlan(condominiumExpenses.condominiumPlan);
                  condominiumPlan.id = undefined;
                  condominiumExpenses.condominiumPlan = condominiumPlan;
                }
                condominiumExpensesList.push(condominiumExpenses);
              });
              linkedMatter.matterPropertyWithCondo.condominiumExpenses = condominiumExpensesList;
              linkedMatter.matterPropertyWithCondo.condominiumTotalExpenses = sourceMatter.matterPropertyWithCondo.condominiumTotalExpenses;
            }
            if (sourceMatter.matterPropertyWithCondo.condominiumPlans) {
              let condominiumPlanList: CondominiumPlan[] = [];
              sourceMatter.matterPropertyWithCondo.condominiumPlans.forEach(item => {
                let condominiumPlan = new CondominiumPlan(item);
                condominiumPlan.id = undefined;
                condominiumPlanList.push(condominiumPlan);
              });
              linkedMatter.matterPropertyWithCondo.condominiumPlans = condominiumPlanList;
            }

            // Updating Common Expense
            let statementAdjustmentLinkedMatter = linkedMatter.statementOfAdjustments.find(soa => soa.isCommonExpenseAdjustmentFromProperty());
            let statementAdjustmentSourceMatter = sourceMatter.statementOfAdjustments.find(soa => soa.isCommonExpenseAdjustmentFromProperty());
            if (!statementAdjustmentLinkedMatter && statementAdjustmentSourceMatter) {
              linkedMatter.createUpdateAdjustmentCommonExp();
              statementAdjustmentLinkedMatter = linkedMatter.statementOfAdjustments.find(soa => soa.isCommonExpenseAdjustmentFromProperty());
            }
            if (statementAdjustmentSourceMatter && statementAdjustmentLinkedMatter) {
              let soaCommonExpense = new SoaCommonExpense(statementAdjustmentSourceMatter.soaCommonExpense);
              soaCommonExpense.id = undefined;
              this.propertyTeranetComponent.onUpdateCommonExpense(soaCommonExpense, statementAdjustmentLinkedMatter);
            }
          }
          if (sourceMatter.matterPropertyWithCondo && !sourceMatter.matterPropertyWithCondo.isPropertyCondominium()) {
            linkedMatter.matterProperties.slice(0).forEach((item, index) => {
              if (index > 0) {
                this.propertyTeranetComponent.removeMatterPropertyAndMatterTax(item);
              }
            });
            sourceMatter.matterProperties.forEach((item, index) => {
              if (index == 0) {
                linkedMatter.matterPropertyWithCondo.lincNumber = item.lincNumber;
                if (linkedMatter.matterPropertyWithCondo.rollNumber && item.rollNumber) {
                  linkedMatter.matterPropertyWithCondo.rollNumber.city = item.rollNumber.city;
                }
              } else if (index > 0) {
                let matterProperty = new MatterProperty(item);
                matterProperty.id = undefined;
                if (sourceMatter.isMatterProvinceON) { // For ON, keep the address and clean the id.
                  matterProperty.address.id = null;
                } else {
                  matterProperty.address = new Address();
                }

                linkedMatter.matterProperties.push(matterProperty);
              }
            });

          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.isMatterProvinceSK) {
            if (sourceMatter.matterPropertyWithCondo.rollNumber) {
              linkedMatter.matterPropertyWithCondo.rollNumber = new RollNumber(sourceMatter.matterPropertyWithCondo.rollNumber);
            }
            linkedMatter.matterPropertyWithCondo.rentalAgreements = sourceMatter.matterPropertyWithCondo.rentalAgreements;
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.rprApplicable = sourceMatter.matterPropertyWithCondo.rprApplicable;
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.surveyDate = sourceMatter.matterPropertyWithCondo.surveyDate;
          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.lastInstrumentNumber = sourceMatter.matterPropertyWithCondo.lastInstrumentNumber;
          }
          if (sourceMatter.matterPropertyWithCondo && (sourceMatter.surveyorMatterParticipant || linkedMatter.surveyorMatterParticipant)) {
            if (sourceMatter.surveyorMatterParticipant) {
              this.propertyTeranetComponent.selectedSurveyorContact = sourceMatter.surveyorMatterParticipant.contact;
            }
            this.propertyTeranetComponent.setMatterParticipantSurveyor();
            if (linkedMatter.surveyorMatterParticipant && linkedMatter.surveyorMatterParticipant.contact &&
              sourceMatter.surveyorMatterParticipant && sourceMatter.surveyorMatterParticipant.contact) {
              linkedMatter.surveyorMatterParticipant.contact.sourceContactId = sourceMatter.surveyorMatterParticipant.contact.sourceContactId;
              linkedMatter.surveyorMatterParticipant.contact.sourceParentOrganizationId = sourceMatter.surveyorMatterParticipant.contact.sourceParentOrganizationId;
              linkedMatter.surveyorMatterParticipant.sourceContact = sourceMatter.surveyorMatterParticipant.sourceContact;
              linkedMatter.surveyorMatterParticipant.contact.sourceParentOrganizationId = sourceMatter.surveyorMatterParticipant.contact.sourceParentOrganizationId;
              linkedMatter.surveyorMatterParticipant.contact.lastSyncedFromSource = sourceMatter.surveyorMatterParticipant.contact.lastSyncedFromSource;
              linkedMatter.surveyorMatterParticipant.contact.attention = sourceMatter.surveyorMatterParticipant.contact.attention;
            }
          }
          if (sourceMatter.matterPropertyWithCondo && (sourceMatter.matterPropertyWithCondo.propertyTax || sourceMatter.matterPropertyWithCondo.matterTaxesByRollNumber
            || linkedMatter.matterPropertyWithCondo.propertyTax || linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber)) {
            if (linkedMatter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber != undefined) {
              this.propertyTeranetComponent.removePropertyTaxes(!linkedMatter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber);
              this.propertyTeranetComponent.reCalculateStatementofAdjustment();
            }
            if (sourceMatter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber) {
              let rollNumberPropertyTaxesResult = new RollNumberPropertyTaxesResult();
              rollNumberPropertyTaxesResult.propertyTaxesByRollNumber = sourceMatter.matterTaxByRollNumbers();
              rollNumberPropertyTaxesResult.separateTaxAdjustmentByRollNumber = sourceMatter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber;
              this.propertyTeranetComponent.propagatePropertyTaxChanges(rollNumberPropertyTaxesResult);
            } else if (sourceMatter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber != undefined) {
              let rollNumberPropertyTaxesResult = new RollNumberPropertyTaxesResult();
              rollNumberPropertyTaxesResult.propertyTax = sourceMatter.matterPropertyWithCondo.matterTax;
              rollNumberPropertyTaxesResult.separateTaxAdjustmentByRollNumber = sourceMatter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber;
              this.propertyTeranetComponent.propagatePropertyTaxChanges(rollNumberPropertyTaxesResult);
            }
            if (linkedMatter.matterPropertyWithCondo.matterTax || linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber) {
              let matterTaxId = UUIDUtil.getUUID();
              let matterTax;
              // Update Statement of Adjustment for Primary pin
              if (linkedMatter.matterPropertyWithCondo.matterTax) {
                matterTax = new MatterTax(linkedMatter.matterPropertyWithCondo.matterTax);
                if (linkedMatter.statementOfAdjustments) {
                  let statementAdjustment = linkedMatter.statementOfAdjustments.find(item => item.isOriginPropertyTaxes() && item.propertyMatterTaxId === linkedMatter.matterPropertyWithCondo.matterTax.id);
                  if (statementAdjustment) {
                    statementAdjustment.propertyMatterTaxId = matterTaxId;
                  }

                }
              }
              if (linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber) {
                // Update Matter Tax for all pins
                let matterTaxesByRollNumberList: MatterTax[] = [];
                linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber.forEach(matterTaxesByRollNumber => {
                  if (matterTaxesByRollNumber) {
                    let matterTaxUpdated = new MatterTax(matterTaxesByRollNumber);
                    if (linkedMatter.matterPropertyWithCondo.matterTax && matterTaxesByRollNumber.id == linkedMatter.matterPropertyWithCondo.matterTax.id) {
                      //Update primary pin matter Tax in Matter Tax Roll Number
                      matterTaxUpdated.id = matterTaxId;
                    } else {
                      // Update Statement of Adjustment for Rest of the  pins
                      let matterTaxUpdatedId = UUIDUtil.getUUID();
                      if (linkedMatter.statementOfAdjustments) {
                        let statementAdjustment = linkedMatter.statementOfAdjustments.find(item => item.isOriginPropertyTaxes() && item.propertyMatterTaxId === matterTaxesByRollNumber.id);
                        if (statementAdjustment) {
                          statementAdjustment.propertyMatterTaxId = matterTaxUpdatedId;
                        }
                      }
                      // Update Matter Tax for all pins
                      matterTaxUpdated.id = matterTaxUpdatedId;

                    }
                    matterTaxesByRollNumberList.push(matterTaxUpdated);
                  }
                });
                linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber = matterTaxesByRollNumberList;

              }
              if (matterTax) {
                //Update primary pin matter Tax
                matterTax.id = matterTaxId;
                linkedMatter.matterPropertyWithCondo.matterTax = matterTax;
              }
              this.retainPropertyTaxDataInLinkedMatter(linkedMatter, propertyTaxesByRollNumberMap);
              this.propertyTeranetComponent.updatePropertyTaxPaidTrustLedger();
            }
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.purchasePrice != undefined && sourceMatter.considerationLtt) {
            let statementPriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(sourceMatter.adjustmentStatusMode, sourceMatter.provinceCode, sourceMatter.considerationLtt.salePriceAdjustment);
            statementPriceAdjustment.id = undefined;
            if (statementPriceAdjustment.salePriceAdjustmentHeadings) {
              statementPriceAdjustment.salePriceAdjustmentHeadings.forEach(item => {
                item.id = undefined;
                if (Array.isArray(item.salePriceAdjustmentHeadingItems) && item.salePriceAdjustmentHeadingItems.length > 0) {
                  item.salePriceAdjustmentHeadingItems.forEach(headingItem => headingItem.id = undefined);
                }
              });
            }
            let salePriceUpdate = {
              salePrice: sourceMatter.matterPropertyWithCondo.purchasePrice,
              salePriceSoa: sourceMatter.matterPropertyWithCondo.soaPurchasePrice,
              priceType: sourceMatter.matterPropertyWithCondo.purchasePriceType,
              salePriceAdjustment: statementPriceAdjustment,
              autoInsertHst: sourceMatter.autoInsertHst,
              affidavitTobeSignedBy: sourceMatter.matterPropertyWithCondo.affidavitTobeSignedBy,
              statementAdjustmentDisplayUtil: this.propertyTeranetComponent.statementAdjustmentDisplayUtil,
              overrideValueOfParcels: sourceMatter.matterPropertyWithCondo.overrideValueOfParcels
            };
            this.propertyTeranetComponent.onSalePriceUpdate(salePriceUpdate);
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.depositAmount != undefined) {
            let depositUpdate: DepositModalContextValue = {
              depositAmount: sourceMatter.matterPropertyWithCondo.depositAmount,
              multipleDeposit: sourceMatter.matterPropertyWithCondo.multipleDeposit,
              payDepositOutOfTrust: sourceMatter.matterPropertyWithCondo.payDepositOutOfTrust,
              adjustmentFormat: sourceMatter.matterPropertyWithCondo.adjustmentFormat,
              deposits: sourceMatter.matterPropertyWithCondo.deposits,
              closingDate: sourceMatter.getClosingDate()
            };
            this.propertyTeranetComponent.onDepositUpdate(depositUpdate);
            if (linkedMatter.matterPropertyWithCondo.deposits) {
              linkedMatter.matterPropertyWithCondo.deposits.forEach(item => item.id = undefined);
            }
          }
          if (sourceMatter.matterPropertyWithCondo && (sourceMatter.residentAssociationMatterParticipant || linkedMatter.residentAssociationMatterParticipant)) {
            if (!sourceMatter.residentAssociationMatterParticipant) {
              this.propertyTeranetComponent.clearResidentAssocaitionFromMatterProperty();
            } else {
              this.propertyTeranetComponent.selectedResidentAssociation = sourceMatter.residentAssociationMatterParticipant.contact;
              this.propertyTeranetComponent.setMatterParticipantResidenceAssociation();
              if (linkedMatter.residentAssociationMatterParticipant && linkedMatter.residentAssociationMatterParticipant.contact) {
                linkedMatter.residentAssociationMatterParticipant.contact.sourceContactId = sourceMatter.residentAssociationMatterParticipant.contact.sourceContactId;
                linkedMatter.residentAssociationMatterParticipant.contact.sourceParentOrganizationId = sourceMatter.residentAssociationMatterParticipant.contact.sourceParentOrganizationId;
                linkedMatter.residentAssociationMatterParticipant.sourceContact = sourceMatter.residentAssociationMatterParticipant.sourceContact;
                linkedMatter.residentAssociationMatterParticipant.contact.sourceParentOrganizationId = sourceMatter.residentAssociationMatterParticipant.contact.sourceParentOrganizationId;
                linkedMatter.residentAssociationMatterParticipant.contact.lastSyncedFromSource = sourceMatter.residentAssociationMatterParticipant.contact.lastSyncedFromSource;
              }
            }

          }
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.annualFee = sourceMatter.matterPropertyWithCondo.annualFee;
            this.propertyTeranetComponent.changeAnnualFee();
          }

          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.fiscalYearEndDate = sourceMatter.matterPropertyWithCondo.fiscalYearEndDate;
            this.propertyTeranetComponent.onFiscalYearEndDateUpdate();
          }
          this.propertyTeranetComponent.loadHomeOwnersResidentFeeSOA();
          if (sourceMatter.matterPropertyWithCondo) {
            linkedMatter.matterPropertyWithCondo.interestEstate = sourceMatter.matterPropertyWithCondo.interestEstate;

            linkedMatter.matterPropertyWithCondo.specify = sourceMatter.matterPropertyWithCondo.specify;

            linkedMatter.matterPropertyWithCondo.titleNumber = sourceMatter.matterPropertyWithCondo.titleNumber;

            linkedMatter.matterPropertyWithCondo.instrumentNumber = sourceMatter.matterPropertyWithCondo.instrumentNumber;

            linkedMatter.matterPropertyWithCondo.newHomeWarranty = sourceMatter.matterPropertyWithCondo.newHomeWarranty;

            linkedMatter.matterPropertyWithCondo.builderNumber = sourceMatter.matterPropertyWithCondo.builderNumber;

            linkedMatter.matterPropertyWithCondo.propertyNumber = sourceMatter.matterPropertyWithCondo.propertyNumber;

            linkedMatter.matterPropertyWithCondo.mobileHomeBrand = sourceMatter.matterPropertyWithCondo.mobileHomeBrand;

            linkedMatter.matterPropertyWithCondo.mobileHomeModel = sourceMatter.matterPropertyWithCondo.mobileHomeModel;

            linkedMatter.matterPropertyWithCondo.mobileHomeYear = sourceMatter.matterPropertyWithCondo.mobileHomeYear;

            linkedMatter.matterPropertyWithCondo.mobileHomeSerial = sourceMatter.matterPropertyWithCondo.mobileHomeSerial;

            linkedMatter.matterPropertyWithCondo.zoningMemorandum = sourceMatter.matterPropertyWithCondo.zoningMemorandum;

            linkedMatter.matterPropertyWithCondo.separateCommonElementFeeAdj = sourceMatter.matterPropertyWithCondo.separateCommonElementFeeAdj;

            linkedMatter.matterPropertyWithCondo.legalDescriptionSummary = sourceMatter.matterPropertyWithCondo.legalDescriptionSummary;

            if (linkedMatter.isMatterProvinceMB) {
              linkedMatter.matterPropertyWithCondo.overrideDescription = sourceMatter.matterPropertyWithCondo.overrideDescription;
              linkedMatter.matterPropertyWithCondo.fullLegalDescription = sourceMatter.matterPropertyWithCondo.fullLegalDescription;
              if (sourceMatter.matterPropertyWithCondo.fullLegalDescription && sourceMatter.matterPropertyWithCondo.fullLegalDescription.length > 0) {
                linkedMatter.matterPropertyWithCondo.importedParcelRegisterIds = [];
                linkedMatter.matterPropertyWithCondo.importedParcelRegisterIds.push(...(sourceMatter.matterPropertyWithCondo.importedParcelRegisterIds || []));
              }
            }

            if (linkedMatter.isMatterProvinceON) {

              linkedMatter.matterPropertyWithCondo.isParcelOfTiedLand = sourceMatter.matterPropertyWithCondo.isParcelOfTiedLand;
              linkedMatter.matterPropertyWithCondo.nameOfCondominiumPlan = sourceMatter.matterPropertyWithCondo.nameOfCondominiumPlan;
              if (linkedMatter.matterPropertyWithCondo.percentageInterest != sourceMatter.matterPropertyWithCondo.percentageInterest) {
                linkedMatter.matterPropertyWithCondo.percentageInterest = sourceMatter.matterPropertyWithCondo.percentageInterest;
                this.propertyTeranetComponent.onAutoCalculateCommonExpense(linkedMatter.matterPropertyWithCondo.percentageInterest);
              }

              if (linkedMatter.matterPropertyWithCondo.commonExpenses != sourceMatter.matterPropertyWithCondo.commonExpenses) {
                linkedMatter.matterPropertyWithCondo.commonExpenses = sourceMatter.matterPropertyWithCondo.commonExpenses;
                linkedMatter.updatePOTLAdjustmentsWithCommonExpense();
              }

              linkedMatter.matterPropertyWithCondo.registryOrLt = sourceMatter.matterPropertyWithCondo.registryOrLt;
              linkedMatter.matterPropertyWithCondo.parcel = sourceMatter.matterPropertyWithCondo.parcel;
              linkedMatter.matterPropertyWithCondo.section = sourceMatter.matterPropertyWithCondo.section;
              linkedMatter.matterPropertyWithCondo.easementRightOfWay = sourceMatter.matterPropertyWithCondo.easementRightOfWay;
              linkedMatter.matterPropertyWithCondo.municipality = sourceMatter.matterPropertyWithCondo.municipality;
              linkedMatter.matterPropertyWithCondo.rollNumberType = sourceMatter.matterPropertyWithCondo.rollNumberType;
              if (sourceMatter.matterPropertyWithCondo.rollNumber) {
                linkedMatter.matterPropertyWithCondo.rollNumber = new RollNumber(sourceMatter.matterPropertyWithCondo.rollNumber);
              }
              linkedMatter.matterPropertyWithCondo.lastTransferNumber = sourceMatter.matterPropertyWithCondo.lastTransferNumber;

              if (sourceMatter.matterPropertyWithCondo && !sourceMatter.matterPropertyWithCondo.isPropertyCondominium()) {
                linkedMatter.matterPropertyWithCondo.pin = sourceMatter.matterPropertyWithCondo.pin;
              }
            }
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.purchaserShareAmount != undefined) {
            linkedMatter.matterPropertyWithCondo.purchaserShareAmount = sourceMatter.matterPropertyWithCondo.purchaserShareAmount;
            let statementAdjustmentSource = sourceMatter.statementOfAdjustments.find(item => item.isHomeOwnersResidentFee() && !item.addedFlag);
            let statementAdjustmentLinked = linkedMatter.statementOfAdjustments.find(item => item.isHomeOwnersResidentFee() && !item.addedFlag);
            if (statementAdjustmentSource) {
              this.propertyTeranetComponent.onHomeOwnersResidentFeeModalUpdate(statementAdjustmentSource.soAdjHomeOwnersResidentFee, statementAdjustmentLinked);
            } else if (statementAdjustmentLinked) {
              linkedMatter.removeStatementAdjustment(statementAdjustmentLinked);
            }
          }
          if (sourceMatter.matterPropertyWithCondo) {
            this.copyCondominiumData(sourceMatter, linkedMatter);
          }
          if (sourceMatter.holdbacks.length) {
            this.copyHoldBackData(sourceMatter, linkedMatter);
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions) {
            this.copyLegalDescriptions(sourceMatter, linkedMatter);
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.depositHeldBy) {
            linkedMatter.depositHeldBy = sourceMatter.depositHeldBy;
            this.propertyTeranetComponent.onDepositHeldByChange(linkedMatter.depositHeldBy);
          }
          if (sourceMatter.matterPropertyWithCondo && sourceMatter.isMatterProvinceMB) {
            linkedMatter.matterPropertyWithCondo.propertyTax = sourceMatter.matterPropertyWithCondo.propertyTax;
          }
          //After finishing the copy, do "Pin" verification.
          if (linkedMatter.matterPropertyWithCondo.isCondominium != 'YES') {
            this.propertyTeranetComponent.verifyPinValues();
          }
        }
      });
    });
  }

  //Storing the Property Tax data in map to retain values of fields.
  createPropertyTaxesRollNumberMap(linkedMatter: Matter): Map<number, MatterTax> {
    let matterTaxesRollNumberMap: Map<number, MatterTax> = new Map<number, MatterTax>();
    if (linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber) {
      linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber.forEach((tax, index) => matterTaxesRollNumberMap.set(index, tax));
    }
    return matterTaxesRollNumberMap;
  }

  //Storing the Property Tax data from Statement of Adjustment in map to retain values of fields.
  createSOAPropertyTaxesRollNumberMap(linkedMatter: Matter): Map<number, MatterTax> {
    let matterTaxesRollNumberMap: Map<number, MatterTax> = new Map<number, MatterTax>();
    if (this.extractSoaPropertyTaxes(linkedMatter)) {
      this.extractSoaPropertyTaxes(linkedMatter).forEach((adj, index) => matterTaxesRollNumberMap.set(index, adj.matterTax));
    }
    return matterTaxesRollNumberMap;
  }

  //Updating the Linked matter with Retained values of fields (payOutOfTrustAccount/amountPaidFromTrustAccount).
  retainPropertyTaxDataInLinkedMatter(linkedMatter: Matter, matterTaxesRollNumberMap: Map<number, MatterTax>, retainPropertyTaxFromSOA ?: true) {
    if (retainPropertyTaxFromSOA) {
      let soaPropertyTaxes: StatementAdjustment[] = this.extractSoaPropertyTaxes(linkedMatter);
      if (soaPropertyTaxes) {
        soaPropertyTaxes.forEach((soaTax, index) => {
          this.updateDataFromLinkedMatterMap(soaTax.matterTax, matterTaxesRollNumberMap, index);
        });
      }
    } else {
      if (linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber) {
        linkedMatter.matterPropertyWithCondo.matterTaxesByRollNumber.forEach((tax, index) => {
          this.updateDataFromLinkedMatterMap(tax, matterTaxesRollNumberMap, index);
        });
      }
      if (linkedMatter.matterPropertyWithCondo.matterTax) {
        this.updateDataFromLinkedMatterMap(linkedMatter.matterPropertyWithCondo.matterTax, matterTaxesRollNumberMap, 0);
        this.propertyTeranetComponent.propertyModel.updatePropertyTaxes(linkedMatter.matterPropertyWithCondo.matterTax,
          linkedMatter.formattedAllTaxesAmount(linkedMatter.matterPropertyWithCondo.matterTax), linkedMatter.formattedTrustAmount(linkedMatter.matterPropertyWithCondo.matterTax), linkedMatter.provinceCode);
      }
    }

  }

  extractSoaPropertyTaxes(linkedMatter: Matter): StatementAdjustment[] {
    return linkedMatter.statementOfAdjustments.filter(item => item.addedFlag && item.isPropertyTax());
  }

  updateDataFromLinkedMatterMap(sourceMatterTax: MatterTax, matterTaxesRollNumberMap: Map<number, MatterTax>, mapKey: number) {
    if (matterTaxesRollNumberMap) {
      let linkedMatterTax = matterTaxesRollNumberMap.get(mapKey);
      if (linkedMatterTax) {
        sourceMatterTax.payOutOfTrustAccount = linkedMatterTax.payOutOfTrustAccount;
        sourceMatterTax.amountPaidFromTrustAccount = linkedMatterTax.amountPaidFromTrustAccount;
      } else {
        sourceMatterTax.payOutOfTrustAccount = 'N/y';
        sourceMatterTax.amountPaidFromTrustAccount = 0;
      }
    }
  }

  copyHoldBackData(sourceMatter: Matter, linkedMatter: Matter): void {
    if (sourceMatter.holdbacks.length) {
      let existingHoldBacks: MatterHoldback[] = linkedMatter.holdbacks;
      linkedMatter.holdbacks = [];
      let otherHoldBack: MatterHoldback[] = this.getHoldBackFromMatter(sourceMatter, HOLDBACK_TYPE.other);
      if (otherHoldBack) {
        otherHoldBack.forEach(matterHoldback => {
          linkedMatter.createHoldback(matterHoldback, HOLDBACK_TYPE.other, null, true);
        });
      }
      if (sourceMatter.isPurchase) {
        let advanceHoldBackToCopy: MatterHoldback[] = this.getHoldBackFromMatter(sourceMatter, HOLDBACK_TYPE.advance);
        if (advanceHoldBackToCopy) {
          advanceHoldBackToCopy.forEach(matterHoldback => {
            linkedMatter.createHoldback(matterHoldback, HOLDBACK_TYPE.advance, null, true);
          });
        }
        linkedMatter.advanceMatterHoldbackConfig = new AdvanceMatterHoldbackConfig(sourceMatter.advanceMatterHoldbackConfig);
        linkedMatter.advanceMatterHoldbackConfig.id = null;
      } else {
        let matterHoldbacksToRemove = this.removeHoldBacksFromMatter(sourceMatter, HOLDBACK_TYPE.advance);
        matterHoldbacksToRemove.forEach(holdback => (<any>sourceMatter.holdbacks).remove(holdback));
        let advanceHoldBackToCopy: MatterHoldback[] = existingHoldBacks.filter(holdBack => holdBack.holdbackType === HOLDBACK_TYPE.advance);

        if (advanceHoldBackToCopy) {
          advanceHoldBackToCopy.forEach(matterHoldback => {
            linkedMatter.holdbacks.push(matterHoldback);
            sourceMatter.createHoldback(matterHoldback, HOLDBACK_TYPE.advance, null, true);
          });
        }
        sourceMatter.advanceMatterHoldbackConfig = new AdvanceMatterHoldbackConfig(linkedMatter.advanceMatterHoldbackConfig);
        sourceMatter.advanceMatterHoldbackConfig.id = null;
      }
    }
    if (linkedMatter && linkedMatter.soaTrustLedgerCollection) {
      linkedMatter.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
    }
    if (sourceMatter && sourceMatter.soaTrustLedgerCollection) {
      sourceMatter.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
    }
  }

  removeHoldBacksFromMatter(matter: Matter, holdBackType: string): MatterHoldback[] {
    return matter.holdbacks.filter(holdBack => holdBack.holdbackType === holdBackType);
    //holdBacks.forEach(item =>  (<any>holdBacks).remove(item));
  }

  getHoldBackFromMatter(matter: Matter, holdBackType: string): MatterHoldback[] {
    return matter.holdbacks.filter(item => item.holdbackType === holdBackType);
  }

  copyLegalDescriptions(sourceMatter: Matter, linkedMatter: Matter): void {
    if (sourceMatter.isMatterProvinceMB) {
      this.copyLegalDescriptionsMB(sourceMatter, linkedMatter);
    }
    if (sourceMatter.isMatterProvinceSK) {
      this.copyLegalDescriptionsSK(sourceMatter, linkedMatter);
    }
  }

  copyLegalDescriptionsSK(sourceMatter: Matter, linkedMatter: Matter) {
    if (sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions) {
      linkedMatter.matterPropertyWithCondo.parcelLegalDescriptions = [];
      sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions.forEach(
        (parcelLegalDescription, index) => {
          let newLinkedParcelLegalDescription: ParcelLegalDescription = new ParcelLegalDescription(parcelLegalDescription);
          newLinkedParcelLegalDescription.id = UUIDUtil.getUUID();
          if (Array.isArray(newLinkedParcelLegalDescription.parcelTitles)) {
            newLinkedParcelLegalDescription.parcelTitles.forEach(title => {
              title.id = null;
              title.parcelLegalDescriptionId = null;
            });
          }
          linkedMatter.matterPropertyWithCondo.parcelLegalDescriptions[ index ] = _.cloneDeep(newLinkedParcelLegalDescription);
          this.propertyTeranetComponent.updateParcelValues();
        }
      );
    }
  }

  copyLegalDescriptionsMB(sourceMatter: Matter, linkedMatter: Matter) {
    if (sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions) {
      // do not delete parcelLegalDescription like this as it will remove also the associated matterTax
      // linkedMatter.matterPropertyWithCondo.parcelLegalDescriptions.forEach(
      //     (parcelLegalDescription) => {
      //         this.propertyTeranetComponent.propertyModel.deleteRollNumber(parcelLegalDescription, linkedMatter);
      //     }
      // );

      linkedMatter.matterPropertyWithCondo.parcelLegalDescriptions = [];

      sourceMatter.matterPropertyWithCondo.parcelLegalDescriptions.forEach(
        (parcelLegalDescription) => {
          let newLinkedParcelLegalDescription: ParcelLegalDescription = new ParcelLegalDescription(parcelLegalDescription);
          newLinkedParcelLegalDescription.id = UUIDUtil.getUUID();
          this.propertyTeranetComponent.addNewTitleRollNumberRequest =
            this.propertyTeranetComponent.propertyModel.updateTitleRollNumber(linkedMatter, this.propertyTeranetComponent.addNewTitleRollNumberRequest, null, newLinkedParcelLegalDescription, [], 0);
        }
      );

      let rollNumberPropertyTaxesResult: RollNumberPropertyTaxesResult = new RollNumberPropertyTaxesResult();
      let matterTaxAdj: StatementAdjustment = sourceMatter.getPropertyTaxSoa();
      rollNumberPropertyTaxesResult.infoOnly = matterTaxAdj ? matterTaxAdj.infoOnly : false;
      rollNumberPropertyTaxesResult.propertyTax = linkedMatter.propertyModel.matterTax;
      rollNumberPropertyTaxesResult.separateTaxAdjustmentByRollNumber = sourceMatter.propertyModel.separateTaxAdjustmentByRollNumber;
      rollNumberPropertyTaxesResult.propertyTaxesByRollNumber = linkedMatter.matterTaxByRollNumbers();
      this.propertyTeranetComponent.propagatePropertyTaxChanges(rollNumberPropertyTaxesResult);
      this.propertyTeranetComponent.onSeparateCommonElementFeeAdjChange(undefined);
    }

  }

  copyLinkedMatterFields(sourceMatter: Matter, linkedMatter: Matter): Observable<boolean> {
    if (linkedMatter.soaTrustLedgerCollection && !linkedMatter.soaTrustLedgerCollection.matter) {
      linkedMatter.soaTrustLedgerCollection.matter = linkedMatter;
    }
    if (linkedMatter.secondarySoaSheetsCollection) {
      linkedMatter.secondarySoaSheetsCollection.forEach(item => {
        if (!item.matter) {
          item.matter = linkedMatter;
        }
      });
    }
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    this.http.linkedMatterBackEndCallsCounter == 0;
    this.lockScreenService.lockForUpdate = true;
    this.copyUndertakings(sourceMatter, linkedMatter);
    this.copyMatterOpeningFields(sourceMatter, linkedMatter).finally(() => this.lockScreenService.lockForUpdate = false).subscribe(isMethodCompleted => {
      if (isMethodCompleted) {
        this.lockScreenService.lockForUpdate = true;
        this.copyVendorAndPurchaser(sourceMatter, linkedMatter).finally(() => this.lockScreenService.lockForUpdate = false).subscribe(isMethodCompleted => {
          if (isMethodCompleted) {
            //Copy Address should be after completing copying vendor and purchaser
            if (sourceMatter.isProjectSale || linkedMatter.isProjectSale) {
              this.copyPreClosingAddressForProjectSale(sourceMatter, linkedMatter);
            } else {
              // This was commented as per DPPMP-30297
              //this.copyPreClosingAddress(sourceMatter, linkedMatter);
            }
            this.lockScreenService.lockForUpdate = true;
            this.copyMatterProperty(sourceMatter, linkedMatter).finally(() => this.lockScreenService.lockForUpdate = false).subscribe(isMethodCompleted => {
              if (isMethodCompleted) {
                this.lockScreenService.lockForUpdate = true;
                // this.copyFireInsurance(sourceMatter, linkedMatter).finally(() => this.lockScreenService.lockForUpdate = false).subscribe(isMethodCompleted => {
                //     if (isMethodCompleted) {
                //         this.lockScreenService.lockForUpdate = true;
                this.copyStatementAdjustment(sourceMatter, linkedMatter).finally(() => this.lockScreenService.lockForUpdate = false).subscribe(isMethodCompleted => {
                  if (isMethodCompleted) {
                    linkedMatter.clearCircularReferencesBeforeStringify();
                    this.destroyComponentsAfterMatterLinking();
                    returnSubject.next(true);
                    returnSubject.complete();
                  }
                });
                //     }
                // });
              }
            });
          }
        });
      }
    });
    return returnSubject;
  }

  cleanupLinkedMatterSalePriceAdjustmentHeadings(linkedMatter: Matter) {

    if (linkedMatter && linkedMatter.considerationLtt && linkedMatter.considerationLtt.salePriceAdjustment
      && Array.isArray(linkedMatter.adjustments)
      && Array.isArray(linkedMatter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings)
      && linkedMatter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.length > 0) {
      //at this time, we need to assign each heading with new ID and update the related adjustment's salePriceAdjustmentHeadingId through matching previous identifier
      linkedMatter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings
      .forEach(heading => {
        const salePriceAdjustmentHeadingId: number = UUIDUtil.getUUID();
        //server expect headingId field value UI populated
        heading.id = salePriceAdjustmentHeadingId;
        linkedMatter.allStatementAdjustments //need to sync the ID with related final/interim statementAdjustment
        .filter(adj => adj.itemKey == 'SALE_PRICE_ADJUSTMENT_HEADING' && adj.salePriceAdjustmentHeadingId == heading.identifier) //since during copy, we lost the existing id, only can use identifier field(has the same value) to match
        .forEach(adj => adj.salePriceAdjustmentHeadingId = salePriceAdjustmentHeadingId);
        heading.identifier = salePriceAdjustmentHeadingId;
      });
    }
  }

  //only one way copy from Sale to Purchase
  copyUndertakings(sourceMatter: Matter, linkedMatter: Matter) {
    //DPPMP-43460: STOP the undertaking linking for ON
    if (sourceMatter.isMatterProvinceON) {
      return;
    }
    if (sourceMatter.isSale) {
      // When the sourceMatter is sale matter, copy  updateUndertakings from source matter to link matte.
      this.copyUndertakingsAction(sourceMatter, linkedMatter, true);
    } else if (sourceMatter.isPurchase) {
      // When the sourceMatter is purchaser matter, copy  updateUndertakings from link matter to source matte.
      this.copyUndertakingsAction(linkedMatter, sourceMatter, false);
    }
  }

  // only one way copy from Sale to Purchase
  copyUndertakingsAction(sourceMatter, destinationMatter, needSourceMatterUpdateUndertakings: boolean) {
    destinationMatter.matterUndertakings = [];
    destinationMatter.permittedRegistrations = [];

    if (needSourceMatterUpdateUndertakings) {
      //needed in case user saves directly from Existing Mortgages component.
      sourceMatter.updateUndertakings(this.undertakingsConfigService);
    }

    for (let undertaking of sourceMatter.matterUndertakings) {
      let newUndertaking: MatterUndertaking = new MatterUndertaking(undertaking);
      newUndertaking.id = UUIDUtil.getUUID();
      newUndertaking.instrumentId = null;
      destinationMatter.matterUndertakings.push(newUndertaking);
    }

    for (let permittedRegistration of sourceMatter.permittedRegistrations) {
      let newPermittedRegistration: PermittedRegistration = new PermittedRegistration(permittedRegistration);
      newPermittedRegistration.id = UUIDUtil.getUUID();
      newPermittedRegistration.instrumentId = null;
      destinationMatter.permittedRegistrations.push(newPermittedRegistration);
    }
  }

  //Sale: Purchasers & Solicitor - Documents Executed at
  //Purchase: Matter Opening - Purchaser executing doc'ts at
  copyDocumentsExecutedAt(sourceMatter: Matter, linkedMatter: Matter): void {
    let saleMatter: Matter = sourceMatter.isSale ? sourceMatter : linkedMatter;

    if (saleMatter && saleMatter.documentProfileId) {
      this.documentProfileService.getById(saleMatter.documentProfileId, sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId), saleMatter.isMatterTypeDischarge).subscribe(cachedDocumentProfile => {
        if (cachedDocumentProfile.miscDocumentProfile && cachedDocumentProfile.miscDocumentProfile.displayJurisdictionSalesTax == DpBooleanValueTypes.YES) {
          if (sourceMatter.isPurchase) {
            linkedMatter.vendorExecDocsAt = sourceMatter.purchaserExecDocsAt;
            linkedMatter.documentsExecutedAtJurisdictionId = sourceMatter.jurisdictionId;
          } else if (sourceMatter.isSale) {
            linkedMatter.purchaserExecDocsAt = sourceMatter.vendorExecDocsAt;
            linkedMatter.jurisdictionId = sourceMatter.documentsExecutedAtJurisdictionId;
          }
        }
      });
    }
  }

  copyPreClosingAddressForProjectSale(sourceMatter: Matter, linkedMatter: Matter): void {
    //ProjectSale: Purchasers & Solicitor Topic
    //Purchase: Purchasers Topic
    if (sourceMatter.isProjectSale && sourceMatter.otherPartyContactInfo) {
      if (!linkedMatter.matterContactInfo) {
        linkedMatter.matterContactInfo = new MatterContactInfo();
      }
      //ProjectSale: Purchasers & Solicitor - Will purchaser be residing at subject property?
      //Purchase: Purchasers - Will purchaser be residing at subject property?
      if (sourceMatter.otherPartyContactInfo.residingAtSubjectProperty != linkedMatter.matterContactInfo.residingAtSubjectProperty) {
        linkedMatter.matterContactInfo.residingAtSubjectProperty = sourceMatter.otherPartyContactInfo.residingAtSubjectProperty;
        linkedMatter.updateMatterOnIsResidingFlagChange();
      }
      //ProjectSale: Purchasers & Solicitor - Address for Service
      //Purchase: Purchasers - Pre-Closing Address
      if (sourceMatter.otherPartyContactInfo.serviceAddress) {
        let copiedAddress = sourceMatter.otherPartyContactInfo.serviceAddress.copyAddress();
        copiedAddress.addressTypeCode = null;
        linkedMatter.matterContactInfo.preClosingAddress = copiedAddress;
      }
    }
    //Purchase: Purchasers Topic
    //ProjectSale: Purchasers & Solicitor Topic
    if (sourceMatter.isPurchase && sourceMatter.matterContactInfo) {
      this.createOtherPartyContactInfo(linkedMatter);
      if (sourceMatter.matterContactInfo.residingAtSubjectProperty != linkedMatter.otherPartyContactInfo.residingAtSubjectProperty) {
        linkedMatter.otherPartyContactInfo.residingAtSubjectProperty = sourceMatter.matterContactInfo.residingAtSubjectProperty;
      }
      //Purchase: Purchasers - Pre-Closing Address
      //ProjectSale: Purchasers & Solicitor - Address for Service
      if (sourceMatter.matterContactInfo.preClosingAddress) {
        let serviceAddressIndex: number = linkedMatter.otherPartyContactInfo.addresses.findIndex((address: Address) => address.addressTypeCode == AddressTypes.serviceAddress);
        if (serviceAddressIndex > -1) {
          //Remove Service Address if exists
          linkedMatter.otherPartyContactInfo.addresses.splice(serviceAddressIndex, 1);
        }
        let copiedAddress = sourceMatter.matterContactInfo.preClosingAddress.copyAddress();
        copiedAddress.addressTypeCode = AddressTypes.serviceAddress;
        linkedMatter.otherPartyContactInfo.addresses.push(copiedAddress);
      }
    }
  }

  //Sale: Matter Opening - File No. --> Purchase: Vendors & Solicitor - File Number (one way)
  //Purchase: Matter Opening - File No. --> Sale: Purchasers & Solicitor - File Number (one way)
  copyFileNumber(sourceMatter: Matter, linkedMatter: Matter): void {
    this.createOtherPartyContactInfo(linkedMatter);
    linkedMatter.otherPartyContactInfo.fileNumber = sourceMatter.fileNumber;
  }

  //This method should remove all the components instantiated for linked matter.
  destroyComponentsAfterMatterLinking(): void {
    this.attentionInfoComponent.ngOnDestroy();
    this.condoCorporationComponent.completeSubscriptions();
  }

  // Matter Linking Changes - Start
  //per US17568, only for Purchase and Sale matters and Tab A: we are acting for both side
  async addOtherPartySolicitorLawClerkAndLawFirm(linkedMatter: Matter): Promise<void> {

    if (this.needToSyncSolicitorAndLawClerk(linkedMatter)) {

      // OtherParty Solicitor
      //Based on discussion with Praveen and Colin for story 17568 and Bug 25851
      //Pre-condition: in tab A, acting for 'Purchaser & Vendor' is selected
      //if tab A solicitor is selected, then tab C other solicitor get updated
      //if tab A solicitor is deselected, nothing happend at tab C (tab C retain the value), since we treat Tab A value status as interim status
      const solicitorParticipant: MatterParticipant = linkedMatter.solicitor;
      if (solicitorParticipant) {
        await this.addSolicitorToOtherPartyMatterParticipant(solicitorParticipant, linkedMatter);
      }

      //similar behavior as above solicitor field
      const lawClerkParticipant: MatterParticipant = linkedMatter.lawClerk;
      if (lawClerkParticipant) {
        this.addLawClerkToOtherPartyContactInfo(lawClerkParticipant.contact, linkedMatter);
      }

      // OtherPartyLawFirm, both solicitor and lawClerk selected should belong to same Firm
      if (lawClerkParticipant || solicitorParticipant) {
        let matterParticipantTemp: MatterParticipant = solicitorParticipant ? solicitorParticipant : lawClerkParticipant;
        await this.addLawFirmToOtherPartyMatterParticipant(matterParticipantTemp, linkedMatter);
        ///in case at Tab C, there is existing other solicitor selected from different law firm, if that solicitor is not from the same firm as selected Solicitor in tabA, then need to remove that solicitor
        if (matterParticipantTemp && matterParticipantTemp.contact && matterParticipantTemp.contact.legalFirmId) {
          const solicitorParticipantPrev: MatterParticipant = linkedMatter.otherPartySolicitor;
          if (solicitorParticipantPrev && solicitorParticipantPrev.contact && solicitorParticipantPrev.contact.legalFirmId && (matterParticipantTemp.contact.legalFirmId != solicitorParticipantPrev.contact.legalFirmId)) {
            linkedMatter.removeMatterParticipant(solicitorParticipantPrev);
          }
        }
      }

    }

  }

  //according to Dov, this feature is not Province based, will apply to non-Mortgage sourceMatter
  //as long as act for both side
  needToSyncSolicitorAndLawClerk(linkedMatter: Matter): boolean {
    return !linkedMatter.isMortgage && linkedMatter.isSolicitorActingForBoth;
  }

  async addSolicitorToOtherPartyMatterParticipant(solicitorParticipant: MatterParticipant, linkedMatter: Matter): Promise<void> {

    const solicitorParticipantPrev: MatterParticipant = linkedMatter.otherPartySolicitor;
    if (solicitorParticipantPrev) {
      linkedMatter.removeMatterParticipant(solicitorParticipantPrev);
    }
    let sourceSolicitor: Contact = await this.contactQueryService.getContactForMatter(solicitorParticipant.contactReferenceId).toPromise();
    if (sourceSolicitor) {
      linkedMatter.addMatterParticipant(sourceSolicitor,
        true,
        linkedMatter.otherPartyMPRoleSolicitor);
    }
  }

  addLawClerkToOtherPartyContactInfo(contact: Contact, linkedMatter: Matter): void {
    if (contact) {
      this.createOtherPartyContactInfo(linkedMatter);
      let otherPartyContactInfo: ContactInfo = linkedMatter.otherPartyContactInfo;

      // Populating the fields
      otherPartyContactInfo.lawClerkFaxPhone = contact.faxPhone;
      otherPartyContactInfo.lawClerkWorkPhone = contact.workPhone;
      otherPartyContactInfo.lawClerkCellPhone = contact.cellPhone;
      otherPartyContactInfo.lawClerkEmail = contact.email;
      otherPartyContactInfo.lawClerkName = contact.surnameLastFullName;
      otherPartyContactInfo.lawClerkId = contact.id;

    }
  }

  /**
   * It can copy the solicitor and lawClerk of source Matter tab A to the linked matter tab c
   * It can copy the solicitor and lawClerk of linked Matter tab A to the source matter tab c
   * @param sourceMatter
   * @param targetMatter
   */
  addSolicitorLawFirmLawClerkToLinkedMatterOtherPartySide(sourceMatter: Matter, targetMatter: Matter): void {
    // if the solicitor or law Clerk of tab A aren't changed, NOT overwrite the linked matter the change the solicitor or law Clerk of tab c
    if (targetMatter && sourceMatter && this.needToSyncSolicitorAndLawClerk(sourceMatter) && sourceMatter.isSolicitorOrLawClerkDirty) {
      const solicitorParticipant: MatterParticipant = sourceMatter.solicitor;
      const lawClerkParticipant: MatterParticipant = sourceMatter.lawClerk;
      // how to update OtherParty Solicitor, law firm and law clerk
      //if tab A solicitor/law clerk is selected, then the tab C of linked matter other solicitor get updated
      //if tab A solicitor/law clerk is deselected, nothing happen at tab C
      // OtherPartyLawFirm, both solicitor and lawClerk selected should belong to same Firm
      if (lawClerkParticipant || solicitorParticipant) {
        let matterParticipantTemp: MatterParticipant = solicitorParticipant ? solicitorParticipant : lawClerkParticipant;
        this.addLawFirmToOtherPartyMatterParticipant(matterParticipantTemp, targetMatter);
        ///in case at Tab C, there is existing other solicitor selected from different law firm, if that solicitor is not from the same firm as selected Solicitor in tabA, then need to remove that solicitor
        if (matterParticipantTemp && matterParticipantTemp.contact && matterParticipantTemp.contact.legalFirmId) {
          const solicitorParticipantPrev: MatterParticipant = targetMatter.otherPartySolicitor;
          if (solicitorParticipantPrev && solicitorParticipantPrev.contact && solicitorParticipantPrev.contact.legalFirmId && (matterParticipantTemp.contact.legalFirmId != solicitorParticipantPrev.contact.legalFirmId)) {
            targetMatter.removeMatterParticipant(solicitorParticipantPrev);
          }
        }
      }

      if (solicitorParticipant) {
        this.addSolicitorToOtherPartyMatterParticipant(solicitorParticipant, targetMatter);
      }

      if (lawClerkParticipant) {
        this.addLawClerkToOtherPartyContactInfo(lawClerkParticipant.contact, targetMatter);
      }

    }

  }

  // With linked matter, the solicitor, law clerk and law firm of matter opening should update the LINKED matter tab c if there are selected.
  // Without linked matter, the solicitor, law clerk and law firm of matter opening should update this matter itself tab c if there are selected.
  async addSolicitorLawFirmLawClerk(sourceMatter: Matter, linkedMatter: Matter): Promise<void> {
    if (sourceMatter && sourceMatter.matterLink && sourceMatter.matterLink.linkedMatterId) {
      this.addSolicitorLawFirmLawClerkToLinkedMatterOtherPartySide(sourceMatter, linkedMatter);
    } else {
      await this.addOtherPartySolicitorLawClerkAndLawFirm(sourceMatter);
    }
  }

  private createOtherPartyContactInfo(linkedMatter: Matter) {
    if (!linkedMatter.otherPartyContactInfo) {
      const tmpContactInfo: ContactInfo = new ContactInfo();
      tmpContactInfo.contactInfoType = linkedMatter.otherPartyContactInfoType;
      tmpContactInfo.instanceType = ContactInfoInstanceTypes.contactInfo;
      linkedMatter.contactInfo.push(tmpContactInfo);
    }
  }

  async addLawFirmToOtherPartyMatterParticipant(mp: MatterParticipant, linkedMatter: Matter): Promise<void> {
    // TODO: Matter Link Chnages
    const lawFirmPrev: MatterParticipant = linkedMatter.otherPartyLawFirm;
    if (lawFirmPrev) {
      linkedMatter.removeMatterParticipant(lawFirmPrev);
    }
    //TODO: Matter Link Chnages
    let sourceLawFirm: Contact = await this.contactQueryService.getContactForMatter(mp.contact.legalFirmId).toPromise();
    if (sourceLawFirm) {
      // Add the Law Firm MatterParticipant
      //According to backend requirement, UI set law firm snapshot subContact as []
      let tempFirmContact: Contact = new Contact(sourceLawFirm);
      tempFirmContact.subContacts = [];
      linkedMatter.addMatterParticipant(tempFirmContact,
        true,
        linkedMatter.otherPartyMPRoleLawFirm);
    }
  }

  onDateChangeClosingDate(closingDate, linkedMatter: Matter, cachedDocumentProfile: DocumentProfile, errorService?: ErrorService): void {
    if (closingDate !== linkedMatter.matterCloseDate) {
      linkedMatter.matterCloseDate = closingDate;
      // TODO: Matter Link Chnages
      if (linkedMatter.isProjectSale) {
        /* From Terry: on a project sale it does not need to default the 'executing docs on' field
           based on the “If closing date is greater than”.   Have checked TC just to make sure and it does not.
           So on a Project Sale it only updates the 'executing dates on' if Project's "Update Document Execution Date when Closing Date edited?" is Yes.
         */
        if (!linkedMatter.isTemplateMatterForMassUpdate && linkedMatter.project && linkedMatter.project.updateDocumentExecutionDate && SharedUtils.isFullDate(closingDate)) {
          linkedMatter.purchaserExecDocsDate = closingDate;
        }
      } else {
        if (cachedDocumentProfile) {
          let defaultDay = cachedDocumentProfile.miscDocumentProfile.closingDateGreaterThan;
          if (!defaultDay && cachedDocumentProfile.miscDocumentProfile && cachedDocumentProfile.miscDocumentProfile.sameAsDefaultProfileFlag
            && this.documentProfileCache && this.documentProfileCache.cachedDefaultDocumentProfile && this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile) {
            defaultDay = this.documentProfileCache.cachedDefaultDocumentProfile.miscDocumentProfile.closingDateGreaterThan;
          }

          this.setOnDateUsingCloseDate(closingDate, defaultDay, linkedMatter);
        }
      }
      linkedMatter.updateOnClosingDate(errorService);
      this.updateSoaTaxRate(linkedMatter);

    }

  }

  setOnDateUsingCloseDate(closingDate, defaultDay, linkedMatter: Matter): void {
    if (SharedUtils.isFullDate(closingDate) && closingDate.indexOf('/') > -1 && closingDate.split('/').length > 2) {
      let closingDateDay = closingDate.split('/')[ 2 ];
      let closingDateMonth = closingDate.split('/')[ 1 ];
      let closingDateYear = closingDate.split('/')[ 0 ];
      if (!closingDateDay) {
        linkedMatter.purchaserExecDocsDate = '//';
      } else {
        if (defaultDay && Number(closingDateDay) <= Number(defaultDay)) {
          linkedMatter.purchaserExecDocsDate = closingDateYear + '//';
        } else if (defaultDay && Number(closingDateDay) > Number(defaultDay)) {
          linkedMatter.purchaserExecDocsDate = closingDateYear + '/' + closingDateMonth + '/';
        }
      }
    }
  }

  updateSoaTaxRate(linkedMatter: Matter): void {
    let closingDate: string = linkedMatter.getSoaTaxRateEffectiveDate();
    // TODO: Matter Link Chnages
    this.taxRateService.getSoaFeesRates(closingDate, linkedMatter.provinceCode).subscribe((data: SoaFeeRate[]) => {
      // TODO : BACK END IN CASE DATA NOT AVAILABLE IS SENDING NULL OBJECT ARRAY BACK
      if (data && data.length > 0 && data[ 0 ] && Object.keys(data[ 0 ]).length > 0 && linkedMatter.soaTrustLedgerCollection) {
        linkedMatter.soaTrustLedgerCollection.soaFeesRates = data.map(function (item) {
          return new SoaFeeRate(item);
        });
        linkedMatter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
        if (linkedMatter.secondarySoaSheetsCollection) {
          linkedMatter.secondarySoaSheetsCollection.forEach(collection => {
            collection.updateERegAndRegisterCharges();
          });
        }
        linkedMatter.updateTitleRegistrationFee();
      }
    });
  }

  async updateLinkedPurchaseMatter(initiatedSourceMatter: Matter, linkedMatter: Matter): Promise<boolean> {
    let isMatterInitiated = await this.matterService.initMatter(linkedMatter).toPromise();
    if (isMatterInitiated) {
      let isMatterCopied = await this.copyLinkedMatterFields(initiatedSourceMatter, linkedMatter).toPromise();
      return isMatterCopied;
    } else {
      return false;
    }
  }

}
