import {Directive, Input} from '@angular/core';
import {NgModel} from '@angular/forms';
import {IdentificationDocument} from '../matters/shared/identification-document';

@Directive({
  selector: '[dp-creditCard]',
  host: {
    '(input)': 'onInput($event)',
    '(blur)': 'onBlur($event)'
  }
})

export class DPPMCreditCard {
  constructor(private model: NgModel) {
  }

  onInput() {
    if (this.model.value && this.isCreditCard()) {
      let val = this.model.value.replace(/\D/g, '');
      this.model.reset(val);
      this.model.valueAccessor.writeValue(val);
    }
  }

  onBlur() {
    if (this.model.value && this.isCreditCard()) {
      const val: string = this.model.value;
      let startval = '';
      let middleval = '';
      let endval = '';
      let stars = '';
      let scrubbed = '';
      if (val.length > 4) {
        startval = val.substring(0, 4);
        middleval = val.substring(4, 12);
        endval = val.substring(12, 16);
        scrubbed = startval + '*'.repeat(middleval.length) + endval;

        console.log(startval + ',' + middleval + ',' + endval);

        // update view
        this.model.valueAccessor.writeValue(scrubbed);
        // update model value
        this.model.viewToModelUpdate(scrubbed);
      }

    }
  }

  @Input('dp-creditCard') IDtype: string;

  private isCreditCard(): boolean {
    return IdentificationDocument.isCreditCardType(this.IDtype);
  }

}
