import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class LendingInstitution extends BaseEntity {

  id: number;
  institutionName: string;
  institutionNumber: string;
  alternateName: string;
  lenderName: string;
  depositsAccepted: boolean;
  mortgagesRegistered: boolean;
  // ui
  isDirty: boolean = false;

  get lenderNameDisplay(): string {
    return this.lenderName && this.lenderName.replace(/\|/g, '\n');
  }

  set lenderNameDisplay(input: string) {
    this.lenderName = input.replace(/(\r\n|\n|\r)/g, '|');
  }
}
