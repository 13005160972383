import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class AcceptTermsConditions extends BaseEntity {
  id: number;
  termsAndConditions: string;
  createdDate: Date;
}

export class AcceptTermsConditionsDTO extends BaseEntity {
  userId: number;
}
