import {userAccountProfilesApi} from '../../../admin/shared/user-account-profiles-api';

import {HttpClient} from '../../../core';
import {Injectable} from '@angular/core';
import {AcceptTermsConditions, AcceptTermsConditionsDTO} from './accept-terms-conditions';
import {SESSION_STORAGE_KEYS} from '../../../shared//session-storage-keys';

@Injectable()
export class AcceptTermsConditionsService {

  constructor(private http: HttpClient) {
  }

  getTermsAndConditions() {
    return this.http.get(userAccountProfilesApi.getTermsAndConditions)
    .map((res) => {
      return new AcceptTermsConditions(res[ 'TermsAndConditions' ]);
    });
  }

  setupTermsAndConditions(acceptTermsConditionsDTO: AcceptTermsConditionsDTO) {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = userAccountProfilesApi.setupTermsAndConditions.replace('{accountId}', accountId);
    return this.http.put(url, acceptTermsConditionsDTO)
    .map((response) => {
      return response[ 'User' ];
    });
  }
}

