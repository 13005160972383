import {Component, Inject, OnInit} from '@angular/core';
import {Matter} from '../../shared/matter';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatterService} from '../../matter.service';
import {MatterTab} from '../../matter-tab';
import {Subscription} from 'rxjs/index';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class MatterNoModalContext {
  public matter: Matter;
  public matterTab: MatterTab;
}

@Component({
  selector: 'dp-matter-no-modal',
  templateUrl: 'matter-no-modal.component.html',
  styleUrls: ['./matter-no-modal.styles.scss'],
  providers: [ErrorService]
})
export class MatterNoModalComponent extends ModalComponent<MatterNoModalContext> implements OnInit {
  newMatterNo: string;
  oldMatterNo: string;
  saveSubscription: Subscription;

  constructor(
    public dialog: MatDialogRef<MatterNoModalComponent>,
    public matterService: MatterService,
    public errorService: ErrorService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: MatterNoModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.oldMatterNo = this.context.matter.matterRecordNumber;
    this.newMatterNo = this.context.matter.matterRecordNumber;
  }

  submit() {
    if (this.isValid) {
      let message = `Proceed to change ${this.matterNoLabel} to ${this.newMatterNo}`;
      this.dialogService.confirm('Confirmation', message, false, 'Save', 'Cancel').subscribe((res) => {
        if (res) {
          this.saveMatter();
        }
      });
    } else {
      this.errorService.addDpSaveError(DPError.createDPError('matter.matterOpening.matterNo'));
    }
  }

  public saveMatter(): void {
    if (this.context.matterTab && this.context.matterTab.matterComponent) {
      this.context.matterTab.matterComponent
        .validateAndSaveMatter(undefined, null, null, this.newMatterNo, this.errorService)
        .subscribe(
          (res) => {
            if (res) {
              this.dialog.close(`The ${this.matterNoLabel} was successfully changed to ${this.newMatterNo}`);
            } else {
              this.context.matter.updateMatterRecordNumber(this.oldMatterNo);
            }
          },
          (error) => {
            this.context.matter.updateMatterRecordNumber(this.oldMatterNo);
          }
        );
    }
  }

  close() {
    this.dialog.close();
  }

  get isValid(): boolean {
    return this.newMatterNo != undefined && this.newMatterNo != '';
  }

  get matterNoLabel(): string {
    return this.isOpportunityMatter() ? 'Opportunity ID' : 'Matter No';
  }

  isOpportunityMatter(): boolean {
    return this.context && this.context.matter && this.context.matter.isOpportunityMatter();
  }
}
