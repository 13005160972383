import {HttpClient} from '../../core';
import {Observable} from 'rxjs';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {ltsaApi, OrderProductType, SearchType} from './ltsa-constants';
import {Injectable} from '@angular/core';
import {LtsaUser} from './ltsa-user';
import {OrderPlacement} from './ltsa-order-placement';
import {LtsaCredential} from './ltsa-credential';
import {AuthZService} from '../../core/authz/auth-z.service';
import {LtsaOrderPack, Order} from '../../shared-main/ltsa/ltsa-order';
import Utils from "../utils";

@Injectable()
export class LtsaService {
  private initialOrderGroup = 0;

  constructor(private http: HttpClient,
              public authZService: AuthZService) {
  }

  getUsers(): Observable<LtsaUser[]> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = ltsaApi.users.replace('{userId}', userId);
    return this.http.get(url)
    .map((res: Response) => {
      let users: LtsaUser[] = [];
      let data = res[ 'LtsaUser' ];
      data.forEach(item => {
        users.push(new LtsaUser(item));
      });
      return users;
    });
  }

  saveLtsaUser(ltsaUser: LtsaUser): Observable<LtsaUser> {
    if (ltsaUser.id) {
      return this.editLtsaUser(ltsaUser);
    } else {
      return this.createLtsaUser(ltsaUser);
    }
  }

  editLtsaUser(ltsaUser: LtsaUser): Observable<LtsaUser> {
    let userId = Utils.getAuthenticatedUserId();
    let id = ltsaUser.id.toString();
    let url: string = ltsaApi.user.replace('{userId}', userId).replace('{ltsaUserId}', id);
    return this.http.put(url, JSON.stringify(ltsaUser))
    .map((res) => {
      return new LtsaUser(res[ 'LtsaUser' ]);
    });
  }

  createLtsaUser(ltsaUser: LtsaUser): Observable<LtsaUser> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = ltsaApi.users.replace('{userId}', userId);
    return this.http.post(url, JSON.stringify(ltsaUser))
    .map((res) => {
      return new LtsaUser(res[ 'LtsaUser' ]);
    });
  }

  deleteLtsaUser(ltsaUser: LtsaUser): Observable<any> {
    let userId = Utils.getAuthenticatedUserId();
    let id = ltsaUser.id.toString();
    let url: string = ltsaApi.user.replace('{userId}', userId).replace('{ltsaUserId}', id);
    return this.http.delete(url);
  }

  authorize(body: LtsaCredential): Observable<any> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = ltsaApi.authorize.replace('{userId}', userId);
    return this.http.post(url, JSON.stringify(body));
  }

  getTitleSummariesByPID(pid: string, includeCancelledTitles: boolean, matterId: number, searchType?: SearchType): Observable<LtsaOrderPack> {
    pid = pid?.trim();
    let url: string = ltsaApi.titleSearch;
    let orderPlacement: OrderPlacement = new OrderPlacement(matterId, OrderProductType.PARCEL_INFO, pid, searchType ? searchType : SearchType.INITIAL, includeCancelledTitles, this.initialOrderGroup);
    return this.http.post(url, JSON.stringify(orderPlacement))
    .map((res) => {
      return res[ 'LtsaOrderPack' ];
    });
  }

  getTitleSummariesByTitleNumber(titleNumber: string, includeCancelledTitles: boolean, matterId: number, searchType?: SearchType): Observable<LtsaOrderPack> {
    titleNumber = titleNumber?.trim();
    let url: string = ltsaApi.titleSearch;
    let orderPlacement: OrderPlacement = new OrderPlacement(matterId, OrderProductType.TITLE, titleNumber, searchType ? searchType : SearchType.INITIAL, includeCancelledTitles, this.initialOrderGroup);
    return this.http.post(url, JSON.stringify(orderPlacement))
    .map((res) => {
      return res[ 'LtsaOrderPack' ];
    });
  }

  getOrdersForMatter(matterId: number): Observable<LtsaOrderPack[]> {
    let url: string = ltsaApi.getOrdersForMatter.replace('{matterId}', matterId.toString());
    return this.http.get(url)
    .map((res) => {
      return res[ 'LtsaOrderPacks' ];
    });
  }

  placeOrder(orderPlacement: OrderPlacement): Observable<Order> {
    return this.http.post(ltsaApi.placeOrder, JSON.stringify(orderPlacement))
    .map((res) => {
      return res[ 'LtsaOrder' ];
    });
  }

  getOrder(orderId: number): Observable<Order> {
    const url: string = ltsaApi.getOrder.replace('{id}', orderId.toString());
    return this.http.get(url)
    .map((res) => {
      return res[ 'LtsaOrder' ];
    });
  }

  downloadPdfDocument(documentId: number): void {
    let url: string = ltsaApi.getDocument.replace('{id}', documentId.toString());
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    window.open(url, '_blank');
  }
}
