import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class EsiLawAccountCode extends BaseEntity {

  id: number;
  accountNationSettingId: number;
  accountNumber: string;
  accountName: string;
  accountType: string;
  departmentCostCategoryCenter: string;
  status: string;

}
