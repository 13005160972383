import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ChicagoLawFirmAddress} from './chicago-lawfirm-address';

export class ChicagoLawFirm extends BaseEntity {
  name: string;
  address: ChicagoLawFirmAddress;

  constructor(c?: ChicagoLawFirm) {
    super(c);

    if (c && c.address) {
      this.address = new ChicagoLawFirmAddress(c.address);
    }
  }
}
