<form class="form-horizontal container-fluid custom-modal-container" id="depositForm">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>{{'CIRF Summary'}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="closeModal()">&times;</button>
    </div>
    <div class="row modal-body deal-row maximum-height" id="dealRow">
        <div class="header-info">
            <div>Client Information Request details for {{cirf?.cirfMatterData?.firmName}} on {{context.matterType}} of
                {{cirf?.cirfMatterData?.propertyAddressText}}</div>
        </div>
        <hr>

        <!-- Begin Client(s) Information -->
        <div *ngIf="cirfClients?.length">
            <div *ngFor="let client of cirfClients; let i=index">
                <div class="section-title">
                    <span *ngIf="cirfClients?.length == 1">Client Profile</span>
                    <span *ngIf="cirfClients?.length > 1">Client({{i+1}}) Profile</span>

                </div>
                <hr>
                <div class="items">
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.firstName', fieldValue:client.firstName}">
                        <div class="item-left">First Name:</div>
                        <div class="item-right">{{client.firstName}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.middleName', fieldValue:client.middleName}">
                        <div class="item-left">Middle Name:</div>
                        <div class="item-right">{{client.middleName}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.lastName', fieldValue:client.lastName}">
                        <div class="item-left">Last Name:</div>
                        <div class="item-right">{{client.lastName}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.gender', fieldValue:client.gender}">
                        <div class="item-left">Gender:</div>
                        <div class="item-right">{{getLabelFromValue(client.gender, genderOptions)}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.birthDate', fieldValue:client.birthDate}">
                        <div class="item-left">Birth Date:</div>
                        <div class="item-right">{{client.birthDate | date : 'mediumDate'}}</div>
                    </div>

                    <div class="item"
                        [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.email', fieldValue:client.email}">
                        <div class="item-left">Email:</div>
                        <div class="item-right">{{client.email}}</div>
                    </div>
                    <ng-container *ngIf="cirf?.isMatterPurchase()">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.firstTimeHomeBuyer', fieldValue:client.firstTimeHomeBuyer}">
                            <div class="item-left">Are you a first time home owner?:</div>
                            <div class="item-right">{{getLabelFromValue(client?.firstTimeHomeBuyer, firstTimeHomeBuyerOptions)}}</div>
                        </div>
                    </ng-container>



                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.isCanadianResident', fieldValue:client.isCanadianResident}">
                        <div class="item-left">Canadian Resident:</div>
                        <div class="item-right">{{getLabelFromValue(client.isCanadianResident, yesNoOptions)}}</div>
                    </div>
                    <div class="item" *ngIf="client.isCanadianResidentYes()">
                        <div class="item-left">Resident Status:</div>
                        <div class="item-right">{{getLabelFromValue(client.residentStatus,residentStatusOptions)}}</div>
                    </div>
                    <div class="item" *ngIf="client.isCanadianResidentYes() && getPermanentResidentCard(client.id)">
                        <div class="item-left">Permanent Resident Card:</div>
                        <div class="item-right">{{getPermanentResidentCard(client.id).documentName}} - {{getPermanentResidentCard(client.id).description}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.spousalStatus', fieldValue:client.spousalStatus}">
                        <div class="item-left">What is your spousal status?:</div>
                        <div class="item-right">{{getLabelFromValue(client.spousalStatus, spousalStatusOptions)}}</div>
                    </div>
                    <ng-container *ngIf="client.isSpousalStatusMarried()">
                        <div class="item" [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.isFamilyResidence', fieldValue:client.isFamilyResidence}">
                            <div class="item-left">Is this the family residence?:</div>
                            <div class="item-right">{{getLabelFromValue(client.isFamilyResidence, yesNoOptionsMandatory)}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="client.isSpousalStatusMarried()">
                        <div class="item" [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.isSpouseOnTitle', fieldValue:client.isSpouseOnTitle}">
                            <div class="item-left">Is spouse on title?:</div>
                            <div class="item-right">{{getLabelFromValue(client.isSpouseOnTitle, yesNoOptionsMandatory) }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="client.isSpouseNameVisible()">
                        <div class="item" [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.spouseName', fieldValue:client.spouseName}">
                            <div class="item-left">Spouse Name:</div>
                            <div class="item-right">{{client.spouseName}}</div>
                        </div>
                    </ng-container>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.phone1.telephoneNumber', fieldValue:client.homePhone}">
                        <div class="item-left">Home phone number:</div>
                        <div class="item-right">{{client.homePhone}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.phone2.telephoneNumber', fieldValue:client.cellPhone}">
                        <div class="item-left">Mobile phone number:</div>
                        <div class="item-right">{{client.cellPhone}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.phone3.telephoneNumber', fieldValue:client.workPhone}">
                        <div class="item-left">Work phone number:</div>
                        <div class="item-right">{{client.workPhone}}</div>
                    </div>
                    <div class="item"
                         [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.occupation', fieldValue:client.occupation}">
                        <div class="item-left">Occupation:</div>
                        <div class="item-right">{{client.occupation}}</div>
                    </div>
                    <div *ngFor="let udf of availableClientUdfFields(client)">
                        <div class="item">
                            <div class="item-left">{{udf.fieldLabel}}:</div>
                            <div class="item-right">{{udf.fieldValue}}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="sub-header">
                        (Primary) Address
                    </div>
                    <div class="sub-header-ruler"><hr></div>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.addressLine1', fieldValue:client.businessAddress?.addressLine1}">
                            <div class="item-left">Address Line1:</div>
                            <div class="item-right">{{client.businessAddress?.addressLine1}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.addressLine2', fieldValue:client.businessAddress?.addressLine2}">
                            <div class="item-left">Address Line2:</div>
                            <div class="item-right">{{client.businessAddress?.addressLine2}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.city', fieldValue:client.businessAddress?.city}">
                            <div class="item-left">City:</div>
                            <div class="item-right">{{client.businessAddress?.city}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.provinceCode', fieldValue:client.businessAddress?.provinceCode}">
                            <div class="item-left">Province:</div>
                            <div class="item-right">{{client.businessAddress?.provinceCode}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.postalCode', fieldValue:client.businessAddress?.postalCode}">
                            <div class="item-left">Postal Code:</div>
                            <div class="item-right">{{client.businessAddress?.postalCode}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.address1.country', fieldValue:client.businessAddress?.country}">
                            <div class="item-left">Country:</div>
                            <div class="item-right">{{client.businessAddress?.country}}</div>
                        </div>
                    </div>
                </div>


                <div>
                    <div class="sub-header">
                        Primary Identification
                    </div>
                    <div class="sub-header-ruler"><hr></div>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification1.identificationTypeCode', fieldValue:client.identifications[0]?.identificationTypeCode}">
                            <div class="item-left">Type of ID:</div>
                            <div class="item-right">{{client.identifications[0]?.identificationTypeCode}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification1.identificationNumber', fieldValue:client.identifications[0]?.identificationNumber}">
                            <div class="item-left">Number:</div>
                            <div class="item-right">{{client.identifications[0]?.identificationNumber}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification1.placeOfIssue', fieldValue:client.identifications[0]?.placeOfIssue}">
                            <div class="item-left">Place of Issue:</div>
                            <div class="item-right">{{client.identifications[0]?.placeOfIssue}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification1.expiryDate', fieldValue:client.identifications[0]?.expiryDate}">
                            <div class="item-left">Expiry Date:</div>
                            <div class="item-right">{{client.identifications[0]?.expiryDate}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification1.country', fieldValue:client.identifications[0]?.country}">
                            <div class="item-left">Country:</div>
                            <div class="item-right">{{client.identifications[0]?.country}}</div>
                        </div>
                        <div class="item" *ngIf="client.isDriverLicenceSelected(0) && getDriverLicenseFront(client.id)">
                            <div class="item-left">Front of the Driver's License:</div>
                            <div class="item-right">{{getDriverLicenseFront(client.id).documentName}} - {{getDriverLicenseFront(client.id).description}}</div>
                        </div>
                        <div class="item" *ngIf="client.isDriverLicenceSelected(0) && getDriverLicenseBack(client.id)">
                            <div class="item-left">Back of the Driver's License:</div>
                            <div class="item-right">{{getDriverLicenseBack(client.id).documentName}} - {{getDriverLicenseBack(client.id).description}}</div>
                        </div>
                        <ng-container *ngIf="client.identifications">
                            <div *ngFor="let udf of availableClientIdentificationUdfs(client.identifications[0], 0)">
                                <div class="item">
                                    <div class="item-left">{{udf.fieldLabel}}:</div>
                                    <div class="item-right">{{udf.fieldValue}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div>
                    <div class="sub-header">
                        Secondary Identification
                    </div>
                    <div class="sub-header-ruler"><hr></div>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification2.identificationTypeCode', fieldValue:client.identifications[1]?.identificationTypeCode}">
                            <div class="item-left">Type of ID:</div>
                            <div class="item-right">{{client.identifications[1]?.identificationTypeCode}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification2.identificationNumber', fieldValue:client.identifications[1]?.identificationNumber}">
                            <div class="item-left">Number:</div>
                            <div class="item-right">{{client.identifications[1]?.identificationNumber}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification2.placeOfIssue', fieldValue:client.identifications[1]?.placeOfIssue}">
                            <div class="item-left">Place of Issue:</div>
                            <div class="item-right">{{client.identifications[1]?.placeOfIssue}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification2.expiryDate', fieldValue:client.identifications[1]?.expiryDate}">
                            <div class="item-left">Expiry Date:</div>
                            <div class="item-right">{{client.identifications[1]?.expiryDate }}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.identification2.country', fieldValue:client.identifications[1]?.country}">
                            <div class="item-left">Country:</div>
                            <div class="item-right">{{client.identifications[1]?.country}}</div>
                        </div>
                        <div class="item" *ngIf="client.isDriverLicenceSelected(1) && getDriverLicenseFront(client.id)">
                            <div class="item-left">Front of the Driver's License:</div>
                            <div class="item-right">{{getDriverLicenseFront(client.id).documentName}} - {{getDriverLicenseFront(client.id).description}}</div>
                        </div>
                        <div class="item" *ngIf="client.isDriverLicenceSelected(1) && getDriverLicenseBack(client.id)">
                            <div class="item-left">Back of the Driver's License:</div>
                            <div class="item-right">{{getDriverLicenseBack(client.id).documentName}} - {{getDriverLicenseBack(client.id).description}}</div>
                        </div>
                        <ng-container *ngIf="client.identifications">
                            <div *ngFor="let udf of availableClientIdentificationUdfs(client.identifications[1], 1)">
                                <div class="item">
                                    <div class="item-left">{{udf.fieldLabel}}:</div>
                                    <div class="item-right">{{udf.fieldValue}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <hr>
            <div *ngIf="cirf?.cirfMatterData?.clientNotes"
                 [dp-cirf-field-visibility]="{configKey:'cirfClientInfo.clientNotes', fieldValue:cirf?.cirfMatterData?.clientNotes}">
                <div class="section-title">
                    <span *ngIf="cirfClients?.length == 1">Client Notes</span>
                    <span *ngIf="cirfClients?.length > 1">Client(s) Notes</span>
                </div>
                <hr>
                <div class="items">
                    <div class="item">
                        <div class="item-left"></div>
                        <div class="item-right notes" [innerHtml]="cirf?.cirfMatterData?.clientNotes"></div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <!-- EndClient(s) Information -->

        <!-- Begin Property Information -->
        <ng-container *ngIf="!cirf?.isCustomMatter()">
            <div class="section-title">
                <span>Transaction Details</span>
            </div>
            <hr>
            <div class="items">
                <div class="item" *ngIf="cirf?.isMatterPurchase()">
                    <div class="item-left"
                         [dp-province-field-label]="'matter.purchaser.capacity'"
                         [label-province-code]="cirf?.provinceCode" >Capacity:</div>
                    <div class="item-right">{{getLabelFromValue(matterContactInfo?.clientCapacity, matterPurchasersCapacityOptions)}}</div>
                </div>
                <div class="item">
                    <div class="item-left">{{cirf?.isMatterSale() || cirf?.isMatterMortgage() ? 'Do you reside at the subject property?:': 'Will you be residing at the subject property?:'}}</div>
                    <div class="item-right">{{getLabelFromValue(matterContactInfo?.residingAtSubjectProperty, yesNoOptionsMandatory)}}</div>
                </div>
                <div *ngFor="let udf of matterContactInfoUdfs">
                    <div class="item">
                        <div class="item-left">{{udf.fieldLabel}}:</div>
                        <div class="item-right">{{udf.fieldValue}}</div>
                    </div>
                </div>
                <div *ngIf="isClosingAddressVisible()">
                    <div class="sub-header" >
                        {{closingAddress}}
                    </div>
                    <div class="sub-header-ruler"><hr></div>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'matterContactInfo.address.addressLine1', fieldValue:preOrPostClosingAddress?.addressLine1}">
                            <div class="item-left">Address Line1:</div>
                            <div class="item-right">{{preOrPostClosingAddress?.addressLine1}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'matterContactInfo.address.addressLine2', fieldValue:preOrPostClosingAddress?.addressLine2}">
                            <div class="item-left">Address Line2:</div>
                            <div class="item-right">{{preOrPostClosingAddress?.addressLine2}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'matterContactInfo.address.city', fieldValue:preOrPostClosingAddress?.city}">
                            <div class="item-left">City:</div>
                            <div class="item-right">{{preOrPostClosingAddress?.city}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'matterContactInfo.address.provinceCode', fieldValue:preOrPostClosingAddress?.provinceCode}">
                            <div class="item-left">Province:</div>
                            <div class="item-right">{{preOrPostClosingAddress?.provinceCode}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'matterContactInfo.address.postalCode', fieldValue:preOrPostClosingAddress?.postalCode}">
                            <div class="item-left">Postal Code:</div>
                            <div class="item-right">{{preOrPostClosingAddress?.postalCode}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'matterContactInfo.address.country', fieldValue:preOrPostClosingAddress?.country}">
                            <div class="item-left">Country:</div>
                            <div class="item-right">{{preOrPostClosingAddress?.country}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="cirf && !cirf.isMatterSale()">
                    <div class="sub-header" >
                        {{cirf?.isMatterPurchase()? 'New' : 'Existing'}} Insurance Details
                    </div>
                    <div class="sub-header-ruler"><hr></div>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'fireInsuranceDetail.companyName', fieldValue:cirf?.cirfMatterData?.fireInsuranceDetail?.companyName}">
                            <div class="item-left">Name of Company:</div>
                            <div class="item-right">{{fireInsuranceDetail?.companyName}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'fireInsuranceDetail.policyNumber', fieldValue:cirf?.cirfMatterData?.fireInsuranceDetail?.policyNumber}">
                            <div class="item-left">Policy No.:</div>
                            <div class="item-right">{{fireInsuranceDetail?.policyNumber}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'fireInsuranceDetail.broker.name', fieldValue:cirf?.cirfMatterData?.fireInsuranceDetail?.insuranceBroker?.name}">
                            <div class="item-left">Name of Broker:</div>
                            <div class="item-right">{{fireInsuranceDetail?.insuranceBroker?.name}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'fireInsuranceBroker.phone1.telephoneNumber', fieldValue:cirf?.cirfMatterData?.fireInsuranceDetail?.insuranceBroker?.workPhone}">
                            <div class="item-left">Phone Number:</div>
                            <div class="item-right">{{fireInsuranceDetail?.insuranceBroker?.workPhone}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'fireInsuranceDetail.broker.email', fieldValue:cirf?.cirfMatterData?.fireInsuranceDetail?.insuranceBroker?.email}">
                            <div class="item-left">Email:</div>
                            <div class="item-right">{{fireInsuranceDetail?.insuranceBroker?.email}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'insuranceNotes', fieldValue:cirf?.cirfMatterData?.insuranceNotes}">
                            <div class="item-left">Fire Insurance Notes</div>
                            <div class="item-right notes" [innerHtml]="cirf?.cirfMatterData?.insuranceNotes"></div>
                        </div>
                        <div *ngFor="let udf of fireInsuranceUdfs">
                            <div class="item">
                                <div class="item-left">{{udf.fieldLabel}}:</div>
                                <div class="item-right">{{udf.fieldValue}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
        </ng-container>
        <!-- End Property Information -->

        <!-- Begin Matter Notes -->
        <div [dp-cirf-field-visibility]="{configKey:'matterNotes', fieldValue:cirf?.cirfMatterData?.matterNotes}">
            <div class="section-title">
                <span>General Matter Notes</span>
            </div>
            <hr>
            <div class="items">
                <div class="item">
                    <div class="item-left"></div>
                    <div class="item-right notes" [innerHtml]="cirf?.cirfMatterData?.matterNotes"></div>
                </div>
            </div>
            <hr>
        </div>
        <!-- End Matter Notes -->

        <!-- Begin General Attachments -->
        <div *ngIf="getGeneralDocuments()?.length">
            <div class="section-title">
                <span>Attachments</span>
            </div>
            <hr>
            <div class="items">
                <div class="item" *ngFor="let document of getGeneralDocuments()">
                    <div class="item-left"></div>
                    <div class="item-right">{{document.documentName}} - {{document.description}}</div>
                </div>
            </div>
            <hr>
        </div>
        <!-- End General Attachments -->

        <!-- Begin New Mortgage Information -->
        <div *ngIf="newMortgages?.length">
            <div *ngFor="let mortgage of newMortgages; let i=index">
                <div>
                    <div class="section-title">
                        <span *ngIf="newMortgages?.length == 1">New Mortgage Information</span>
                        <span *ngIf="newMortgages?.length > 1">New Mortgage #{{i+1}} Information</span>
                    </div>
                    <hr>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'newMortgage.bankName', fieldValue:mortgage.bankName}">
                            <div class="item-left">Name of Bank</div>
                            <div class="item-right">{{mortgage.bankName}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'newMortgage.referenceNumber', fieldValue:mortgage.referenceNumber}">
                            <div class="item-left">Reference No.</div>
                            <div class="item-right">{{mortgage.referenceNumber}}</div>
                        </div>
                        <ng-container *ngIf="mortgage.mortgageBroker">
                            <div class="item"
                                 [dp-cirf-field-visibility]="{configKey:'newMortgage.broker.name', fieldValue:mortgage.mortgageBroker?.name}">
                                <div class="item-left">Name of Broker</div>
                                <div class="item-right">{{mortgage.mortgageBroker?.name}}</div>
                            </div>
                            <div class="item"
                                 [dp-cirf-field-visibility]="{configKey:'newMortgage.phone1.telephoneNumber', fieldValue:mortgage.mortgageBroker?.workPhone}">
                                <div class="item-left">Phone Number</div>
                                <div class="item-right">{{mortgage.mortgageBroker?.workPhone}}</div>
                            </div>
                            <div class="item"
                                 [dp-cirf-field-visibility]="{configKey:'newMortgage.broker.email', fieldValue:mortgage.mortgageBroker?.email}">
                                <div class="item-left">Email</div>
                                <div class="item-right">{{mortgage.mortgageBroker?.email}}</div>
                            </div>
                        </ng-container>
                        <div *ngFor="let udf of availableMortgageUdfFields(mortgage, 'NEW')">
                            <div class="item">
                                <div class="item-left">{{udf.fieldLabel}}:</div>
                                <div class="item-right">{{udf.fieldValue}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
            </div>
            <div *ngIf="cirf?.cirfMatterData?.mortgageNotes">
                <div class="section-title">
                    <span *ngIf="newMortgages?.length == 1">New Mortgage Notes</span>
                    <span *ngIf="newMortgages?.length > 1">New Mortgage(s) Notes</span>
                </div>
                <hr>
                <div class="items"
                     [dp-cirf-field-visibility]="{configKey:'newMortgage.mortgageNotes', fieldValue:cirf?.cirfMatterData?.mortgageNotes}">
                    <div class="item">
                        <div class="item-left"></div>
                        <div class="item-right notes" [innerHtml]="cirf?.cirfMatterData?.mortgageNotes"></div>
                    </div>
                </div>
                <hr>
            </div>
        </div>
        <!-- End New Mortgage Information -->

        <!-- Begin Existing Mortgage Information -->
        <div *ngIf="existingMortgages?.length">
            <div *ngFor="let mortgage of existingMortgages; let i=index">
                <div>
                    <div class="section-title">
                        <span *ngIf="existingMortgages?.length == 1">Existing Mortgage Information</span>
                        <span *ngIf="existingMortgages?.length > 1">Existing Mortgage #{{i+1}} Information</span>
                    </div>
                    <hr>
                    <div class="items">
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'existingMortgage.bankName', fieldValue:mortgage.bankName}">
                            <div class="item-left">Name of Bank</div>
                            <div class="item-right">{{mortgage.bankName}}</div>
                        </div>
                        <div class="item"
                             [dp-cirf-field-visibility]="{configKey:'existingMortgage.referenceNumber', fieldValue:mortgage.referenceNumber}">
                            <div class="item-left">Reference No.</div>
                            <div class="item-right">{{mortgage.referenceNumber}}</div>
                        </div>
                        <ng-container *ngIf="mortgage.mortgageBroker">
                            <div class="item"
                                 [dp-cirf-field-visibility]="{configKey:'existingMortgage.broker.name', fieldValue:mortgage.mortgageBroker?.name}">
                                <div class="item-left">Name of Broker</div>
                                <div class="item-right">{{mortgage.mortgageBroker?.name}}</div>
                            </div>
                            <div class="item"
                                 [dp-cirf-field-visibility]="{configKey:'existingMortgage.phone1.telephoneNumber', fieldValue:mortgage.mortgageBroker?.workPhone}">
                                <div class="item-left">Phone Number</div>
                                <div class="item-right">{{mortgage.mortgageBroker?.workPhone}}</div>
                            </div>
                            <div class="item"
                                 [dp-cirf-field-visibility]="{configKey:'existingMortgage.broker.email', fieldValue:mortgage.mortgageBroker?.email}">
                                <div class="item-left">Email</div>
                                <div class="item-right">{{mortgage.mortgageBroker?.email}}</div>
                            </div>
                        </ng-container>
                        <div *ngFor="let udf of availableMortgageUdfFields(mortgage, 'EXISTING')">
                            <div class="item">
                                <div class="item-left">{{udf.fieldLabel}}:</div>
                                <div class="item-right">{{udf.fieldValue}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="getMortgageDocuments(mortgage.id)?.length">
                        <div class="sub-header" >
                            Mortgage Attachments
                        </div>
                        <div class="sub-header-ruler"><hr></div>
                        <div class="items">
                            <div class="item" *ngFor="let document of getMortgageDocuments(mortgage.id) ">
                                <div class="item-left"></div>
                                <div class="item-right">{{document.documentName}} - {{document.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div *ngIf="cirf?.cirfMatterData?.existingMortgageNotes">
                <div class="section-title">
                    <span *ngIf="existingMortgages?.length == 1">Existing Mortgage Notes</span>
                    <span *ngIf="existingMortgages?.length > 1">Existing Mortgage(s) Notes</span>
                </div>
                <hr>
                <div class="items"
                     [dp-cirf-field-visibility]="{configKey:'existingMortgage.mortgageNotes', fieldValue:cirf?.cirfMatterData?.existingMortgageNotes}">
                    <div class="item">
                        <div class="item-left"></div>
                        <div class="item-right notes" [innerHtml]="cirf?.cirfMatterData?.existingMortgageNotes"></div>
                    </div>
                </div>
            </div>

        </div>
        <!-- End Existing Mortgage Information -->

    </div>

    <div class="row modal-footer" >
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="closeModal()">
                <span>OK</span>
            </button>
        </div>
    </div>
</form>
