<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container"  *ngIf="localAdjustment" id="otherFixedSoaForm" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Heat</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-1"></div>
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Type of heat</label>
                </div>
                <div class="col-lg-3">
                    <select id="heatType" name="heatType" class="form-control"
                            [(ngModel)]="localAdjustment.heatType">
                        <option *ngFor="let heatSupplyType of heatTypeList" [value]="heatSupplyType.value">
                            {{heatSupplyType.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div *ngIf="localAdjustment.heatType=='FUEL_OIL' || localAdjustment.heatType=='PROPANE'">
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >Unit of Tank Measurement</label>
                    </div>
                    <div class="col-lg-3">
                        <select
                            id="unitMeasurement"
                            name="unitMeasurement"
                            class="form-control"
                            [(ngModel)]="localAdjustment.unitMeasurementType">
                            <option *ngFor="let  heatMeasurement of heatUnitMeasurement" [value]="heatMeasurement.value">
                                {{heatMeasurement.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >Capacity of tank (in {{localAdjustment.heatMeasurementLabel.toLowerCase()}})</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" class="form-control" maxlength="9"
                               id="tankCapacity"
                               name="tankCapacity"
                               dp-onlynumeric
                               dp-default-number
                               dp-error-validator
                               [fieldKey]="'matter.soa.soaheat.capacity'"
                               [(ngModel)]="localAdjustment.tankCapacity"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">
                            Cost per {{localAdjustment.heatMeasurementSingular}}
                        </label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" class="form-control" id="costPerLitre"
                               name="costPerLitre"
                               dp-default-currency
                               significant-currency-digits="5"
                               max-decimals="4"
                               dp-currency
                               dp-error-validator
                               [fieldKey]="'matter.soa.soaheat.costper'"
                               [(ngModel)]="localAdjustment.costPerLitre"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">
                            Adjust for carbon tax?
                        </label>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="adjustForCarbonTax"
                                name="adjustForCarbonTax"
                                [(ngModel)]="localAdjustment.adjustForCarbonTax"
                                class="form-control"
                        >
                            <option *ngFor="let noYesOption of noYesOptions" [value]="noYesOption.value">
                                {{noYesOption.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="localAdjustment.adjustForCarbonTax === 'YES'">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">
                            Tax amount per {{localAdjustment.heatMeasurementSingular}}
                        </label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" class="form-control"
                               id="carbonTaxAmnt"
                               name="carbonTaxAmnt"
                               dp-default-currency
                               significant-currency-digits="5"
                               max-decimals="4"
                               dp-currency
                               dp-error-validator
                               [fieldKey]="'matter.soa.soaheat.carbonTaxAmnt'"
                               [(ngModel)]="localAdjustment.carbonTaxAmount"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">
                            Adjust for
                        </label>
                    </div>
                    <div class="col-lg-1">
                        <select id=" heatAdjustType"
                                name="heatAdjustType"
                                class="form-control"
                                [(ngModel)]="localAdjustment.adjustType"
                                (change)="changeHeatAdjustType();"
                        >
                            <option *ngFor="let  heatAdjustType of  heatAdjustTypeList" [value]="heatAdjustType.value">
                                {{heatAdjustType.label}}
                            </option>
                        </select>
                    </div>
                    <div style="width: 6px;float: left;"><label class="control-label">?</label></div>
                    <div class="col-lg-1">
                        <select class="form-control"
                            id="heatYesNoAdjust"
                            name="heatYesNoAdjust"
                            [(ngModel)]="localAdjustment.adjustFor"
                            (change)="changeAdjustFor();">
                        <option *ngFor="let  heatYesNoAdjust of  heatYesNoAdjustList" [value]="heatYesNoAdjust.value">
                            {{heatYesNoAdjust.label}}
                        </option>
                    </select></div>
                </div>
            </div>
            <div class="form-group" *ngIf="localAdjustment.adjustFor=='YES'">
                <div class="col-lg-12">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label"
                        >{{localAdjustment.adjustType}} percentage is</label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="percentage"
                               name="percentage"
                               class="form-control"
                               [(ngModel)]="localAdjustment.percentage"
                               dp-percentage
                        />

                    </div>
                    <div class="col-lg-1 vertical-align-label">
                        <label class="control-label text-left">%</label>
                    </div>
                </div>
            </div>

        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd" >
            <div class="col-lg-12  form-group" >
                <div class="col-lg-4">
                    <label class="control-label">HEAT - {{ localAdjustment.heatTypeLabel.toUpperCase()}}
                    </label>
                </div>
            </div>
            <div *ngFor="let line of details">
                <div class="col-lg-12 form-group">
                    <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                    <label *ngIf="line.value && line.value.length > 0" class="control-label" [ngClass]="{'col-lg-4': line.pushValueToRight, 'col-lg-2': !line.pushValueToRight}">{{line.value}}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button type="button" class="dp-btn keyboard-save-action" (click)="ok()" id="okBtn">
                <span>OK</span>
            </button>

            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>

            <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent ></dp-modal-error>
</form>
