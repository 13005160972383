import {HttpClient} from '../../../core';
import {matterApi, Utils} from '../../shared';
import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';

@Injectable()
export class InsurerService {

  utils;

  constructor(private http: HttpClient, private logger: Logger) {
    this.utils = new Utils();
  }

  getInsurer(supportFullIntegration?: boolean) {
    let api = matterApi.insurers;
    if (supportFullIntegration === false) {
      api += '?supportFullIntegration=false';
    }
    return this.http.get(api)
    .map((response) => {
      return response;
    });
  }
}
