import {Component, Input, OnInit} from '@angular/core';
import {Cirf} from '../../../shared/cirf/cirf';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Contact, dropDowns, FamilyLawAct, FlaData, Matter, MatterParticipant} from '../../../shared';
import {Observable, Subject} from 'rxjs';
import {PurchaserService} from '../../purchaser.service';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {Constants, FlaStatementType} from '../../../../shared-main/constants';
import {CirfClientInfo} from '../../../shared/cirf/cirf-client-info';
import {MatterParticipantWrapper} from '../../../shared/matter-participant-wrapper';
import {TabsService} from '../../../../core';
import {GenderTypes} from '../../../../contact/contact-type-mapping';
import {SelectItem} from 'primeng/api';
import {IdentificationRecord} from '../../../shared/identification-record';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {CommonContactDialogComponent} from '../../../matter-opening/common-contact-dialog/common-contact-dialog.component';
import moment from 'moment';
import {ContactNameUtil} from '../../../shared/contact-name-util';
import {cirfDropDowns} from './cirf-drop-downs';
import {customPickListKey} from '../../../../shared/modal/custom-pick-list-key';
import {SpecialComment} from '../../../../shared/modal/special-comment';
import {CustomPickListService} from '../../../../shared/modal/custom-pick-list.service';
import {ApplicableProvisionOptionsType, ApplicableProvisionOptionsTypes, MaritalStatusType} from '../../../shared/fla-data';
import {DpBooleanValue, DpBooleanValueTypes} from '../../../shared/dp-boolean';
import Utils from '../../../../shared-main/utils';
import {MatterParticipantRoleTypes} from '../../../shared/matter-participant-role-types';
import {MatterTopic} from '../../../shared/matter-topic';
import {CirfImportStickyNotesHelperService} from './cirf-import-sticky-notes-helper.service';
import {CirfConfigService} from '../../../../shared-main/cirf-config/cirf-config.service';
import {CirfUdf} from '../../../shared/cirf/cirf-udf';
import {CIRF_CONFIG_KEYS} from '../../../../shared-main/cirf-config/cirf-config-keys';
import {CirfImportBaseComponent} from './cirf-import-base.component';

declare var jQuery: any;

export class CirfImportDataHelper {

  id: number;
  fullName: string;
  //clientContactId not populated always. it gets pouplated if exising participant selected for import.
  clientContactId: string;
  itemType: string;
  clientName: string;
  dataModel: any;
  clientContact: Contact;//Snapshot contact of selected participant
  cirfClient: CirfClientInfo;
  participantCopy: MatterParticipant;
  allFieldsSelected: boolean;
  residenceStatusSticky: string; //placeholder for residence status which will be used for a sticky in future stories
  flaCheckboxSelected: boolean;
  clientUdfs: CirfUdf[];
  primaryIdentificationUdfs: CirfUdf[];
  secondaryIdentificationUdfs: CirfUdf[];

  isNewRecord(): boolean {
    return (this.clientContactId == 'New');
  }

  isNew(): boolean {
    return (this.itemType == 'NEW');
  }

  isExisting(): boolean {
    return (this.itemType == 'EXISTING');
  }

  setFullName() {
    //this.fullName = this.cirfClient.lastName + (this.cirfClient.middleName ? ' , ' +this.cirfClient.middleName : '')+ (this.cirfClient.firstName ? ' ' + this.cirfClient.firstName : '')
    this.fullName = ((this.cirfClient.lastName ? this.cirfClient.lastName + ', ' : '') +
      (this.cirfClient.firstName ? this.cirfClient.firstName + ' ' : '') +
      (this.cirfClient.middleName ? this.cirfClient.middleName : '')).trim();
  }

}

@Component({
  selector: 'dp-cirf-import-owner',
  templateUrl: 'cirf-import-owner.modal.component.html'
})
export class CirfImportOwnerModalComponent extends CirfImportBaseComponent implements OnInit {

  @Input() originalMatter: Matter;
  @Input() matter: Matter;
  @Input() cirf: Cirf;
  @Input() cirfClientToMatterParticipantMap: Map<string, number>;
  @Input() cirfClientsWithSpouseImport: string[];

  cirfImportData: CirfImportDataHelper[] = [];
  clients: any[] = [];
  //@ViewChild('modalErrorComponent') modalErrorComponent : ModalErrorComponent;
  searchTermStreamClient = new Subject<string>();
  clientPurchasersLoading = false;
  autoCompleteEmptyFlag: any;
  clientPurchasers: any;
  flaStatements: FlaData;
  genderFilter: any[] = [ 'MALE', 'FEMALE', 'QUESTION' ];

  genderTypeOptions: SelectItem [] = dropDowns.gender_estate_code;
  canadianResidentOptions: SelectItem[] = dropDowns.canadianResident;
  residentStatusOptions: SelectItem[] = cirfDropDowns.residentStatusOptions;
  countryOptions: SelectItem[] = cirfDropDowns.countryOptions;
  provinceOptions: SelectItem[] = cirfDropDowns.provinceOptions;
  identificationOptions: SelectItem[] = [];
  yNOptions = dropDowns.ontarioOptions;

  constructor(
    public dialogService: DialogService, public purchaserService: PurchaserService, public contactQueryService: ContactQueryService,
    public tabsService: TabsService, public errorService: ErrorService, public customPickListService: CustomPickListService,
    public cirfConfigService: CirfConfigService,
    public cirfImportStickyNotesHelperService: CirfImportStickyNotesHelperService) {
    super(cirfConfigService);
  }

  isApplicableGender(gender: string): boolean {
    return (gender == GenderTypes.MALE || gender === GenderTypes.FEMALE || gender === GenderTypes.QUESTION);
  }

  get matterClients(): MatterParticipant[] {
    return this.matter.mainClients;
  }

  ngOnInit() {
    this.mapCIRFDataToOwnerData(this.cirf);
    this.clients.push({'label': 'Select Client you are replacing', 'value': ''});

    if (this.cirf.isMatterCirf()) {
      this.matterClients.forEach(item => {
        if (this.isApplicableGender(item.contact.gender)) {
          this.clients.push({'label': item.contact.genericFullName, 'value': item.identifier});
        }
      });
      this.clients.push({'label': 'Add new Client', 'value': 'New'});
    } else { //For Participant Cirfs, only allow user to update same participant. Can't add new.
      let participant: MatterParticipant = this.matterClients.find(client => client.matterParticipantId == this.cirf.matterParticipantId);
      if (this.isApplicableGender(participant.contact.gender)) {
        this.clients.push({'label': participant.contact.genericFullName, 'value': participant.identifier});
        if (this.cirfImportData && this.cirfImportData.length > 0 && participant) {
          // Default client name
          this.cirfImportData[ 0 ].clientContactId = String(participant.identifier);
          this.updateClientName(this.cirfImportData[ 0 ], 0);
        }
      }
    }

    this.searchTermStreamClient
    .switchMap((term: string) => {
      this.clientPurchasersLoading = true;
      if (term.trim() === '') {
        this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });

        return observable;

      } else {
        this.autoCompleteEmptyFlag = false;
        return this.purchaserService.getClientPurchaser(term, false, false, this.genderFilter);
        //return this.purchaserService.getClientPurchaser(term);
      }
    })
    .subscribe(
      (purchasers: any) => {
        this.clientPurchasersLoading = false;
        this.clientPurchasers = purchasers;
      },
      error => {
        this.clientPurchasersLoading = false;
      }
    );

    this.loadFlaStatements();
    this.getPickListDropdown();
  }

  getPickListDropdown() {
    this.customPickListService.getPickListDropdown(customPickListKey.IdentificationDocumentType, true)
    .subscribe(
      (res: SpecialComment[]) => {
        this.identificationOptions = [];
        res.forEach((item) => {
          this.identificationOptions.push({label: item.customPickListItemValue, value: item.customPickListItemValue});
        });
        this.cirfImportData.forEach((ownerData) => {
          if (ownerData.cirfClient && ownerData.cirfClient.identifications && ownerData.cirfClient.identifications.length) {
            ownerData.cirfClient.identifications.forEach((identification) => {
              if (!this.identificationOptions.find(idOption => idOption.value == identification.identificationTypeCode)) {
                this.identificationOptions.push({
                  label: identification.identificationTypeCode,
                  value: identification.identificationTypeCode
                });
              }
            });
          }
        });
      });
  }

  mapCIRFDataToOwnerData(cirf: Cirf) {
    this.cirfImportData = [];
    cirf.cirfClients.forEach(clientInfo => {
      let ownerData = new CirfImportDataHelper();
      ownerData.cirfClient = clientInfo;
      ownerData.setFullName();
      if (ownerData.cirfClient.birthDate) {
        ownerData.cirfClient.birthDate = this.formatDate(ownerData.cirfClient.birthDate);
      }
      ownerData.dataModel = ownerData.fullName;
      ownerData.clientContactId = this.matterClients && this.matterClients.length ? '' : 'New';
      this.loadUDfs(ownerData);
      this.cirfImportData.push(ownerData);
    });
  }

  formatDate(date: string): string {
    return moment(date).format('YYYY/MM/DD');
  }

  // autocomplete
  // PURCHASER
  // Omnibar methods to get contacts
  search(event): void {
    let entered: string = event.query;
    this.searchTermStreamClient.next(entered);
  }

  clearClient(item: CirfImportDataHelper): void {
    item.dataModel = '';
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  public getGenderValue(v) {
    switch (v) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      default :
        return v;

    }
  }

  dataSelectedClient(item: CirfImportDataHelper): void {

    let selectedContact = item.dataModel;
    if (selectedContact && selectedContact.id != undefined) {
      this.contactQueryService.getContactForMatter(selectedContact.id)
      .subscribe((contact: Contact) => {
        item.itemType = 'NEW';
        let participant: MatterParticipant = this.addMatterParticipantWithSelectedContact(contact);
        this.useParticipantForImportingCirfData(participant, item);
      });
    } else if (selectedContact && selectedContact.displayName && selectedContact.displayName.indexOf('Add new record for') > -1) {
      let tempParticipant = this.matter.createMatterParticipant(this.matter.mainClientType);
      this.dialogService.matDialogContent({
        content: CommonContactDialogComponent,
        context: {
          matter: this.matter,
          contactType: 'CLIENT',
          matterParticipant: tempParticipant,
          contactName: item.cirfClient.getFullName(),
          flaStatements: this.flaStatements,
          matterParticipantRole: this.matter.mainClientType,
          reducedContactDetails: true,
          gender: item.cirfClient.gender
        },
        onFulfillment: (result) => {
          if (result && result.action === 'save') {
            item.itemType = 'NEW';
            let participant: MatterParticipant = this.addMatterParticipantWithSelectedContact(result.contact);
            this.useParticipantForImportingCirfData(participant, item);
          } else {
            item.dataModel = '';
          }
        },
        
      });

    }
  }

  addMatterParticipantWithSelectedContact(selectedContact: Contact): MatterParticipant {
    let participant: MatterParticipant = this.matter.addMatterParticipant(selectedContact, true, this.matter.mainClientType);
    participant.contact.lastSyncedFromSource = selectedContact.lastUpdatedTimeStamp;
    participant.primary = (this.matterClients.length == 0);
    let maxPriority: number = 1;
    if (this.matterClients.length > 0) {
      maxPriority = Math.max(...
        this.matterClients
        .filter(matterParticipant => matterParticipant.matterParticipantId !== participant.matterParticipantId) // local matter participant already added, priority is not set yet so don't count this one in
        .map(matterParticipant => Number(matterParticipant.matterParticipantPriority))
      );
      maxPriority = isNaN(maxPriority) ? 1 : maxPriority + 1;
    }
    participant.matterParticipantPriority = maxPriority;
    return participant;
  }

  async updateClientName(item: CirfImportDataHelper, index: number): Promise<void> {
    item.clientName = '';
    if (item.isNewRecord()) {
      let currentComp = this;
      setTimeout(() => {
        jQuery('#clientSearch' + index).find('input').focus();
        jQuery('#clientSearch' + index).find('input').keyup();
        // currentComp.searchTermStreamClient.next(item.dataModel);
      }, 100);
      item.itemType = 'NEW';
      if (!item.dataModel) {
        item.dataModel = item.fullName;
      }

    } else if (item.clientContactId != undefined && item.clientContactId != '') {
      let client = this.clients.find(obj => obj.value == item.clientContactId);
      if (client) {
        item.clientName = client.label;
        item.itemType = 'EXISTING';
        let matterParticipant = this.matterClients.find(obj => obj.identifier == Number(item.clientContactId));
        await this.useParticipantForImportingCirfData(matterParticipant, item);
      }
    }
  }

  async useParticipantForImportingCirfData(matterParticipant: MatterParticipant, cirfImportData: CirfImportDataHelper): Promise<void> {
    this.errorService.clearAllSaveErrors();
    if (matterParticipant) {
      //Creating a copy before the participant is locked to use it if the flow proceeds.
      let matterParticipantCopy = new MatterParticipant(matterParticipant);
      let isContactStale: boolean = await this.checkContactStaleOrClearFlag(matterParticipant);
      let isSourceContactLocked: boolean;
      if (!isContactStale) {
        isSourceContactLocked = await this.checkContactForLocking(matterParticipant);
      }
      if (!isContactStale && (isSourceContactLocked || matterParticipant.sourceContactLockAcquired)) {
        //Using the original participant copy created above without any flags changed for locking.
        cirfImportData.participantCopy = matterParticipantCopy;
        cirfImportData.clientContact = matterParticipant.contact;
        matterParticipant.contact.isDirty = true;
        cirfImportData.clientName = matterParticipant.contact.genericName;
      } else {
        if (cirfImportData.isNew()) {
          let participantIndex = this.matter.matterParticipants.findIndex(item => item.equals(matterParticipant));
          this.matter.matterParticipants.splice(participantIndex, 1);

        }
      }
      this.cirfClientToMatterParticipantMap.set(cirfImportData.cirfClient.id, matterParticipant.matterParticipantId);
    }
  }

  selectedParticipantFromMatter(cirfClientInfo: CirfImportDataHelper): MatterParticipant {
    return this.matter.matterParticipants.find(item => cirfClientInfo.participantCopy && item.matterParticipantId === cirfClientInfo.participantCopy.matterParticipantId);
  }

  getIdRecord(cirfImportDataHelper: CirfImportDataHelper): IdentificationRecord {
    let matterParticipant: MatterParticipant = this.selectedParticipantFromMatter(cirfImportDataHelper);
    return matterParticipant && matterParticipant.contact && matterParticipant.contact.identificationRecords && matterParticipant.contact.identificationRecords.length && matterParticipant.contact.identificationRecords[ 0 ];
  }

  checkForDuplicateContactSelection(): string[] {
    let duplicateErrorList = [];
    if (this.cirfImportData.filter(item => item.isExisting()).length > 0) {
      let ownerDataList = this.cirfImportData.slice(0);
      this.cirfImportData.filter(item => item.isExisting() && item.clientContactId).forEach(item => {
        let ownerList = ownerDataList.filter(owner => owner.clientContactId == item.clientContactId);
        if (ownerList.length > 1) {
          duplicateErrorList.push('Multiple owners are replacing the same ' + (this.matter.isMortgage ? 'Mortgagor ' : 'Vendor ') + ownerList[ 0 ].clientName);
        }
        (<any>ownerDataList).remove(ownerList);
      });
    }
    if (this.cirfImportData.filter(item => item.isNewRecord() && !item.clientContact).length > 0) {
      this.cirfImportData.filter(item => item.isNewRecord() && !item.clientContact).forEach(owner => {
        duplicateErrorList.push('You indicated that a contact was to be added, however you did not complete this action for ' + owner.fullName);
      });
    }
    return duplicateErrorList;
  }

  async removeClientName(item: CirfImportDataHelper): Promise<void> {
    let matterParticipant = this.selectedParticipantFromMatter(item);
    await this.releaseContactLock(matterParticipant);
    this.updateOrRemoveParticipant(item);

    item.clientName = undefined;
    item.clientContactId = '';
    item.clientContact = null;
    item.participantCopy = null;
  }

  updateOrRemoveParticipant(item: CirfImportDataHelper): void {
    let matterParticipant = this.selectedParticipantFromMatter(item);
    if (matterParticipant) {
      let participantIndex = this.matter.matterParticipants.findIndex(item => item.equals(matterParticipant));
      if (participantIndex > -1) {
        if (item.isExisting() && item.participantCopy) {
          this.matter.matterParticipants[ participantIndex ] = new MatterParticipant(item.participantCopy);
        } else {
          this.removeFromSpouseImportList(item);
          this.matter.matterParticipants.splice(participantIndex, 1);
          this.cirfClientToMatterParticipantMap.delete(item.cirfClient.id);
        }
      }
    }
  }

  updateAllFields(event, cirfImportData: CirfImportDataHelper): void {
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contact', 'gender', false, 'cirfClientInfo.gender');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contactName', 'firstName', true, 'cirfClientInfo.firstName');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contactName', 'lastName', true, 'cirfClientInfo.lastName');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contactName', 'middleName', true, 'cirfClientInfo.middleName');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contact', 'birthDate', false, 'cirfClientInfo.birthDate');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contact', 'isCanadianResident', false, 'cirfClientInfo.isCanadianResident');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contact', 'residentStatus', false, 'cirfClientInfo.residentStatus');
    this.updateContactDetailsFromCIrf(cirfImportData, event, 'contact', 'email', true, 'cirfClientInfo.email');

    this.residentStatusChange(cirfImportData, event, 'cirfClientInfo.residentStatus');
    this.requestFlaChange(cirfImportData, event);
    this.phoneChange(cirfImportData, event, 'workPhone', 'cirfClientInfo.phone3.telephoneNumber');
    this.phoneChange(cirfImportData, event, 'cellPhone', 'cirfClientInfo.phone2.telephoneNumber');
    this.phoneChange(cirfImportData, event, 'homePhone', 'cirfClientInfo.phone1.telephoneNumber');
    this.occupationChange(cirfImportData, event, 'cirfClientInfo.occupation');
    this.addressChange(cirfImportData, event, 'addressLine1', true, 'cirfClientInfo.address1.addressLine1');
    this.addressChange(cirfImportData, event, 'addressLine2', true, 'cirfClientInfo.address1.addressLine2');
    this.addressChange(cirfImportData, event, 'city', true, 'cirfClientInfo.address1.city');
    this.addressChange(cirfImportData, event, 'provinceCode', false, 'cirfClientInfo.address1.provinceCode');
    this.addressChange(cirfImportData, event, 'postalCode', false, 'cirfClientInfo.address1.postalCode');
    this.addressChange(cirfImportData, event, 'country', false, 'cirfClientInfo.address1.country');

    if (cirfImportData.cirfClient.identifications && cirfImportData.cirfClient.identifications.length) {
      for (let i = 0; i < cirfImportData.cirfClient.identifications.length; i++) {
        this.identificationChange(cirfImportData, event, i, 'identificationTypeCode', false, `cirfClientInfo.identification${ i + 1 }.identificationTypeCode`);
        this.identificationChange(cirfImportData, event, i, 'identificationNumber', false, `cirfClientInfo.identification${ i + 1 }.identificationNumber`);
        this.identificationChange(cirfImportData, event, i, 'placeOfIssue', true, `cirfClientInfo.identification${ i + 1 }.placeOfIssue`);
        this.identificationChange(cirfImportData, event, i, 'expiryDate', false, `cirfClientInfo.identification${ i + 1 }.expiryDate`);
        this.identificationChange(cirfImportData, event, i, 'country', true, `cirfClientInfo.identification${ i + 1 }.country`);
      }
    }
  }

  updateContactDetailsFromCIrf(cirfImportData: CirfImportDataHelper, event, field: string, subField: string, isMixedCase?: boolean, configFieldName?: string): void {
    if (this.isFieldHidden(configFieldName, cirfImportData.cirfClient[ subField ])) {
      return;
    }
    let matterParticipant = this.selectedParticipantFromMatter(cirfImportData);
    if (matterParticipant && matterParticipant.contact) {
      matterParticipant.contact.isDirty = true;
      if (event.srcElement.checked) {
        if (field == 'contactName') {
          matterParticipant.contact.contactName[ subField ] = isMixedCase ? Utils.toMixedCase(cirfImportData.cirfClient[ subField ]) : cirfImportData.cirfClient[ subField ];
        } else if (field == 'contact') {
          if (subField === 'isCanadianResident' && (cirfImportData.cirfClient.isCanadianResident == 'YES' || cirfImportData.cirfClient.isCanadianResident == 'NO')) {
            matterParticipant.contact.canadianResidentFlag = cirfImportData.cirfClient[ subField ];
          } else if (subField === 'residentStatus' && cirfImportData.cirfClient.residentStatus != undefined) {
            matterParticipant.contact.residentStatus = cirfImportData.cirfClient[ subField ];
          } else {
            matterParticipant.contact[ subField ] = isMixedCase ? Utils.toMixedCase(cirfImportData.cirfClient[ subField ]) : cirfImportData.cirfClient[ subField ];
          }
        }
      } else {
        if (field == 'contactName') {
          matterParticipant.contact.contactName[ subField ] = cirfImportData.participantCopy.contact.contactName[ subField ];
        } else if (field == 'contact') {
          if (subField === 'isCanadianResident') {
            matterParticipant.contact.canadianResidentFlag = cirfImportData.participantCopy.contact.canadianResidentFlag;
          } else if (subField === 'residentStatus') {
            matterParticipant.contact.residentStatus = cirfImportData.participantCopy.contact.residentStatus;
          } else {
            matterParticipant.contact[ subField ] = cirfImportData.participantCopy.contact[ subField ];
          }
        }
      }
    }
  }

  onDateChangeBirthday(event, cirfImportData: CirfImportDataHelper) {
    let matterParticipant = this.selectedParticipantFromMatter(cirfImportData);
    if (matterParticipant && matterParticipant.contact) {
      matterParticipant.contact.birthDate = event.rawDate;
    }

  }

  onExpiryDateChange(event, cirfClientInfo: CirfImportDataHelper, identificationIndex: number) {
    this.getIdRecord(cirfClientInfo).identificationDocuments[ identificationIndex ].expiryDate = event.rawDate;
  }

  releaseContactLocks(): void {
    //Release the lock only it the participant is selected and participant copy is present.
    this.cirfImportData.filter(item => item.participantCopy).forEach(item => {
      let matterParticipant = this.selectedParticipantFromMatter(item);
      this.releaseContactLock(matterParticipant);
    });
  }

  releaseContactLock(matterParticipant: MatterParticipant): void {
    if (matterParticipant && matterParticipant.sourceContactLockAcquired) {
      let matterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.matterParticipant = matterParticipant;
      this.tabsService.unLockSourceContact(matterParticipantWrapper);
    }

  }

  async checkContactStaleOrClearFlag(matterParticipant: MatterParticipant): Promise<boolean> {
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.sourceContactId && !matterParticipant.sourceContactLockAcquired) {
      let sourceContact: Contact = await this.contactQueryService.getContactForMatter(Number(matterParticipant.contact.sourceContactId)).toPromise();
      if (sourceContact) {
        let matterParticipant = this.matterClients.find(mainClient => mainClient.contact.sourceContactId == sourceContact.id);
        let source: Contact = new Contact(sourceContact);
        let isStale = matterParticipant.contact.isStaleContact(source);
        let isContactStaleFlagClearedWithoutUpdatingMatter = matterParticipant.contact.isContactStaleFlagClearedWithoutUpdatingMatter(source);
        if (isStale || isContactStaleFlagClearedWithoutUpdatingMatter) {
          let message = (this.matter.isPurchase ? 'Purchaser ' : 'Vendor ') + matterParticipant.contact.genericFullName + ' can not be updated as the contact is out of sync.';
          this.errorService.addDpSaveError(DPError.createCustomDPError('none', message, 'CONTACT', 'ERROR'));
          return Promise.resolve(true);
        } else {
          return Promise.resolve(false);
        }
      }
    }

  }

  async checkContactForLocking(participant: MatterParticipant): Promise<boolean> {
    if (participant && participant.contact && participant.contact.sourceContactId && !participant.sourceContactLockAcquired) {
      let matterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.matterParticipant = participant;
      let sourceContact = await this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true).toPromise();
      if (sourceContact.locked) {
        let message = sourceContact.genericFullName + ' is currently locked by ' + sourceContact.lockedByUser.fullName;
        this.errorService.addDpSaveError(DPError.createCustomDPError('none', message, 'CONTACT', 'ERROR'));
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }

  }

  phoneChange(cirfClientInfo: CirfImportDataHelper, event: any, phoneType: string, configFieldName?: string): void {
    if (this.isFieldHidden(configFieldName, cirfClientInfo.cirfClient[ phoneType ])) {
      return;
    }
    let matterParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
    if (matterParticipant && matterParticipant.contact) {
      if (event.srcElement.checked) {
        matterParticipant.contact[ phoneType ] = cirfClientInfo.cirfClient[ phoneType ];
      } else {
        matterParticipant.contact[ phoneType ] = cirfClientInfo.participantCopy.contact[ phoneType ];
      }
    }
  }

  occupationChange(cirfClientInfo: CirfImportDataHelper, event: any, configFieldName?: string): void {
    if (this.isFieldHidden(configFieldName, cirfClientInfo.cirfClient.occupation)) {
      return;
    }
    if (event.srcElement.checked) {
      this.getIdRecord(cirfClientInfo).occupation = Utils.toMixedCase(cirfClientInfo.cirfClient.occupation);
    } else {
      this.getIdRecord(cirfClientInfo).occupation = cirfClientInfo.participantCopy.contact.identificationRecords[ 0 ].occupation;
    }
  }

  addressChange(cirfClientInfo: CirfImportDataHelper, event: any, addressField: string, isMixedCase?: boolean, configFieldName?: string): void {
    if (this.isFieldHidden(configFieldName, cirfClientInfo.cirfClient.businessAddress[ addressField ])) {
      return;
    }
    let matterParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
    if (matterParticipant && matterParticipant.contact) {
      if (event.srcElement.checked) {
        if (addressField == 'provinceCode') {
          if (cirfClientInfo.cirfClient.businessAddress.provinceCode) { //don't import blank province, will cause out of sync errors
            let provinceOption: SelectItem = this.provinceOptions.find(provinceOption => provinceOption.value == cirfClientInfo.cirfClient.businessAddress.provinceCode);
            if (provinceOption) {
              matterParticipant.contact.primaryAddress.provinceName = provinceOption.label;
            }
          }
        } else {
          matterParticipant.contact.primaryAddress[ addressField ] = isMixedCase ? Utils.toMixedCase(cirfClientInfo.cirfClient.businessAddress[ addressField ]) : cirfClientInfo.cirfClient.businessAddress[ addressField ];
        }
      } else {
        if (addressField == 'provinceCode') {
          matterParticipant.contact.primaryAddress.provinceName = cirfClientInfo.participantCopy.contact.primaryAddress.provinceName;
        } else {
          matterParticipant.contact.primaryAddress[ addressField ] = cirfClientInfo.participantCopy.contact.primaryAddress[ addressField ];
        }
      }
    }
  }

  identificationChange(cirfClientInfo: CirfImportDataHelper, event: any, identificationIndex: number, subField: string, isMixedCase?: boolean, configFieldName?: string): void {
    if (this.isFieldHidden(configFieldName, cirfClientInfo.cirfClient.getIdentification(identificationIndex)[ subField ])) {
      return;
    }
    if (event.srcElement.checked) {
      this.getIdRecord(cirfClientInfo).identificationDocuments[ identificationIndex ][ subField ] = isMixedCase ?
        Utils.toMixedCase(cirfClientInfo.cirfClient.getIdentification(identificationIndex)[ subField ]) : cirfClientInfo.cirfClient.getIdentification(identificationIndex)[ subField ];
    } else {
      this.getIdRecord(cirfClientInfo).identificationDocuments[ identificationIndex ][ subField ] = cirfClientInfo.participantCopy.contact.identificationRecords[ 0 ].identificationDocuments[ identificationIndex ][ subField ];
    }

  }

  requestFlaChange(cirfClientInfo: CirfImportDataHelper, event: any): void {
    if (this.cirf && !this.cirf.isParticipantCirf() && !this.cirf.isCustomMatter()) {
      cirfClientInfo.flaCheckboxSelected = event.srcElement.checked;
      let applyFla: boolean = event.srcElement.checked;
      if (this.cirfImportData.length == 1) {
        this.flaChange(cirfClientInfo, applyFla);
      } else {
        if (cirfClientInfo.cirfClient && cirfClientInfo.cirfClient.spouseId) {
          //Find the spouse
          let spouseClientInfo = this.cirfImportData.find(client => client.cirfClient.id == cirfClientInfo.cirfClient.spouseId);
          if (spouseClientInfo) {
            //Apply FLA only if the both parties choose to apply FLA
            let parties = [ cirfClientInfo, spouseClientInfo ];
            applyFla = parties.every(client => client.flaCheckboxSelected);
            parties.forEach((client) => {
              this.flaChange(client, applyFla);
            });
          }
        } else {
          this.flaChange(cirfClientInfo, applyFla);
        }
      }
    }
  }

  flaChange(cirfClientInfo: CirfImportDataHelper, applyFla: boolean): void {
    let selectedParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
    if (selectedParticipant) {
      if (applyFla) {
        selectedParticipant.familyLawActs = [];
        let familyLawAct: FamilyLawAct = this.getFlaBasedOnCirf(cirfClientInfo, selectedParticipant);
        if (familyLawAct) {
          selectedParticipant.familyLawActs.push(familyLawAct);
          if (selectedParticipant.isConsentedSpouse(familyLawAct)) {
            let consentSpouseParticipant = this.matter.getConsentSpouseParticipantByParentParticipantId(selectedParticipant.matterParticipantId);
            familyLawAct.consentSpouseMatterParticipantId = consentSpouseParticipant.matterParticipantId;
            familyLawAct.consentedSpouseParticipant = consentSpouseParticipant;
          }
          this.addToSpouseImportList(cirfClientInfo);
        }

      } else {
        this.removeFromSpouseImportList(cirfClientInfo);
        let consentingSpouseParticipant = this.matter.getConsentSpouseParticipantByParentParticipantId(selectedParticipant.matterParticipantId);
        if (consentingSpouseParticipant) {
          this.matter.deleteMatterParticipant(consentingSpouseParticipant);
        }
        selectedParticipant.familyLawActs = cirfClientInfo.participantCopy.familyLawActs;

        let fla = selectedParticipant.getConsentedSpouseFamilyLawAct();
        if (fla && fla.consentSpouseMatterParticipantId) {
          //From originalMatter to get consenting spouse and put it back
          let consentedSpouseParticipant: MatterParticipant = this.originalMatter.matterParticipants.find(item => item.matterParticipantId == fla.consentSpouseMatterParticipantId);
          consentedSpouseParticipant = new MatterParticipant(consentedSpouseParticipant);
          this.matter.matterParticipants.push(consentedSpouseParticipant);
          fla.consentedSpouseParticipant = consentedSpouseParticipant;
        }
      }
    }
  }

  removeFromSpouseImportList(cirfClientInfo: CirfImportDataHelper) {
    let selectedParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
    if (this.isFlaSpouseSupported() && selectedParticipant && selectedParticipant.isLinkedSpouse()) {
      let index: number = this.cirfClientsWithSpouseImport.indexOf(cirfClientInfo.cirfClient.id);
      if (index > -1) {
        this.cirfClientsWithSpouseImport.splice(index, 1);
      }
    }
  }

  addToSpouseImportList(cirfClientInfo: CirfImportDataHelper) {
    let selectedParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
    if (this.isFlaSpouseSupported() && selectedParticipant && selectedParticipant.isLinkedSpouse()) {
      this.cirfClientsWithSpouseImport.push(cirfClientInfo.cirfClient.id);
    }
  }

  //TODO: Future story to make this into sticky note
  residentStatusChange(cirfClientInfo: CirfImportDataHelper, event: any, configFieldName?: string): void {
    if (this.isFieldHidden(configFieldName, cirfClientInfo.cirfClient.residentStatus)) {
      return;
    }
    if (event.srcElement.checked) {
      cirfClientInfo.residenceStatusSticky = cirfClientInfo.cirfClient.residentStatus;
    } else {
      let selectedParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
      if (selectedParticipant && selectedParticipant.contact) {
        cirfClientInfo.residenceStatusSticky = selectedParticipant.contact.residentStatus;
      }

    }
  }

  getApplicableProvisionFromCirfClient(cirfClient: CirfClientInfo): ApplicableProvisionOptionsType {
    if (cirfClient.spousalStatus == 'MARRIED') {
      if (cirfClient.isSpouseOnTitle == DpBooleanValueTypes.YES) {
        return ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY;
      } else if (cirfClient.isSpouseOnTitle == DpBooleanValueTypes.NO) {
        return ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING;
      }
    }
    return null;
  }

  getFlaBasedOnCirf(cirfClientInfo: CirfImportDataHelper, parentParticipant: MatterParticipant): FamilyLawAct {
    let familyLawAct: FamilyLawAct = new FamilyLawAct();
    familyLawAct.matterParticipantId = cirfClientInfo.participantCopy.matterParticipantId;
    let cirfClient: CirfClientInfo = cirfClientInfo.cirfClient;

    if (this.matter.isMatterProvinceNB) {
      this.createFlaNB(cirfClient, familyLawAct, parentParticipant, cirfClientInfo.participantCopy);
    } else if (this.matter.isMatterProvinceNS || this.matter.isMatterProvinceSK) {
      this.createFlaNSOrSK(cirfClient, familyLawAct, parentParticipant);
    } else if (this.matter.isMatterProvinceMB) {
      this.createFlaMB(cirfClient, familyLawAct, parentParticipant);
    } else {
      this.createFlaDefault(cirfClient, familyLawAct, parentParticipant);
    }
    return familyLawAct.familyLawActStatementType ? familyLawAct : null;
  }

  createFlaDefault(cirfClient: CirfClientInfo, familyLawAct: FamilyLawAct, parentParticipant: MatterParticipant) {
    if (cirfClient.spousalStatus == 'NOT_MARRIED') {
      familyLawAct.familyLawActStatementType = 'NOT_SPOUSE';
    } else if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isSpouseOnTitle == 'YES' && cirfClient.spouseName) {
      familyLawAct.familyLawActStatementType = 'MATTER_PARTICIPANT_SPOUSE';
    } else if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isFamilyResidence == 'YES' && cirfClient.isSpouseOnTitle == 'NO') {
      this.initializeConsentedSpouse(cirfClient, familyLawAct, parentParticipant);
    } else if (cirfClient.spousalStatus == 'SEPARATED') {
      familyLawAct.familyLawActStatementType = 'SEPARATED_SPOUSE_NOT_FAMILY_RESIDENCE';
    }
  }

  createFlaNSOrSK(cirfClient: CirfClientInfo, familyLawAct: FamilyLawAct, parentParticipant: MatterParticipant) {
    if (cirfClient.spousalStatus == 'NOT_MARRIED') {
      familyLawAct.familyLawActStatementType = 'NOT_SPOUSE';
    } else if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isSpouseOnTitle == 'YES' && cirfClient.spouseName) {
      familyLawAct.familyLawActStatementType = 'MATTER_PARTICIPANT_SPOUSE';
    } else if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isFamilyResidence == 'YES' && cirfClient.isSpouseOnTitle == 'NO') {
      this.initializeConsentedSpouse(cirfClient, familyLawAct, parentParticipant);
    }
  }

  createFlaNB(cirfClient: CirfClientInfo, familyLawAct: FamilyLawAct, parentParticipant: MatterParticipant, participantCopy: MatterParticipant) {
    familyLawAct.familyLawActStatementType = FlaStatementType.MARITAL_STATUS_NB;
    if (cirfClient.spousalStatus == 'MARRIED') {
      familyLawAct.maritalStatus = <MaritalStatusType>cirfClient.spousalStatus;
    } else {
      familyLawAct.maritalStatus = 'NOT_MARRIED';
    }

    if (cirfClient.isFamilyResidence) {
      familyLawAct.propertyOccupiedAsMaritalHome = <DpBooleanValue>cirfClient.isFamilyResidence;
    }

    familyLawAct.applicableProvision = this.getApplicableProvisionFromCirfClient(cirfClient);
    if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isSpouseOnTitle == DpBooleanValueTypes.NO) {
      this.initializeConsentedSpouse(cirfClient, familyLawAct, parentParticipant, participantCopy);
    }
  }

  createFlaMB(cirfClient: CirfClientInfo, familyLawAct: FamilyLawAct, parentParticipant: MatterParticipant) {
    if (cirfClient.spousalStatus == 'NOT_MARRIED') {
      return;
    }

    if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isFamilyResidence == 'YES' && cirfClient.isSpouseOnTitle == 'NO') {
      this.initializeConsentedSpouse(cirfClient, familyLawAct, parentParticipant);
    }

    if (cirfClient.spousalStatus == 'MARRIED' && cirfClient.isSpouseOnTitle == 'YES' && cirfClient.spouseId) {
      if (this.matter.isPurchase || this.matter.isMortgage) {
        familyLawAct.familyLawActStatementType = 'MATTER_PARTICIPANT_SPOUSE';
      } else if (this.matter.isSale) {
        familyLawAct.familyLawActStatementType = 'SPOUSE_TRANSFEREE';
      }
    }
  }

  initializeConsentedSpouse(cirfClient: CirfClientInfo, familyLawAct: FamilyLawAct, parentParticipant: MatterParticipant, participantCopy?: MatterParticipant) {
    if (cirfClient.spouseName) {
      let spouse: Contact = new Contact();
      spouse.loadConsentedSpouseSettings();
      let names: string[] = ContactNameUtil.parseLastFirstMiddleNames(Utils.toMixedCase(cirfClient.spouseName));
      ContactNameUtil.convertStringArrayToContactName(names, spouse.contactName, true);
      spouse.gender = 'QUESTION';
      let consentedSpouseParticipant: MatterParticipant = parentParticipant.getMatterConsentingSpouseParticipantByParentParticipant(this.matter);
      if (consentedSpouseParticipant) {
        this.matter.deleteMatterParticipant(consentedSpouseParticipant);
      }
      this.matter.addMatterParticipant(spouse, true, MatterParticipantRoleTypes.CONSENTING_SPOUSE, null, parentParticipant);
    }

    //if client being updated to add consenting spouse, check if already linked to another spouse. If yes, then remove link from that other spouse.
    if (this.matter.isMatterProvinceNB && participantCopy) {
      let spouseParticipantId: number = participantCopy.getSpouseParticipantId(this.matter.provinceCode);
      let spouse: MatterParticipant = this.matterClients.find(client => client.matterParticipantId == spouseParticipantId);
      if (spouse) {
        return spouse.removeSpousalLink(this.matter.provinceCode);
      }
    } else {
      familyLawAct.familyLawActStatementType = 'CONSENTED_SPOUSE';
    }

  }

  getFlaDescriptionByType(typeCode: string): string {
    let flaTypeDescription: string = '';
    if (this.flaStatements && this.flaStatements.getFlaStatementByTypeCode(typeCode)) {
      flaTypeDescription = this.flaStatements.getFlaStatementByTypeCode(typeCode).flaTypeDescription;
    }
    return flaTypeDescription;
  }

  loadFlaStatements(): void {
    if (!this.matter.isMatterProvinceBC) {
      this.purchaserService.getFlaStatements(this.matter.provinceCode, this.matter.matterType).subscribe((flaStatements: FlaData) => {
        this.flaStatements = flaStatements;
      });
    }
  }

  getFlaForParticipant(matterParticipant: MatterParticipant): FamilyLawAct {
    if (matterParticipant && matterParticipant.familyLawActs && matterParticipant.familyLawActs.length && matterParticipant.familyLawActs[ 0 ]) {
      return matterParticipant.familyLawActs[ 0 ];
    }
    return null;
  }

  getFlaText(cirfClientInfo: CirfImportDataHelper): string {
    if (this.matter.isMatterProvinceNB) {
      return '';
    }
    let familyLawAct: FamilyLawAct = this.getFlaForParticipant(this.selectedParticipantFromMatter(cirfClientInfo));
    if (familyLawAct && familyLawAct.familyLawActStatementType) {
      return this.getFlaDescriptionByType(familyLawAct.familyLawActStatementType);
    }

    return '';
  }

  getFlaSpouseName(cirfClientInfo: CirfImportDataHelper, clientIndex: number): string {
    let selectedParticipant: MatterParticipant = this.selectedParticipantFromMatter(cirfClientInfo);
    let familyLawAct: FamilyLawAct = selectedParticipant.getConsentedSpouseFamilyLawAct();
    if (familyLawAct && familyLawAct.consentedSpouseParticipant && familyLawAct.consentedSpouseParticipant.contact) {
      return familyLawAct.consentedSpouseParticipant.contact.fullName;
    }

    if (this.isFlaSpouseSupported() && selectedParticipant && selectedParticipant.isLinkedSpouse()) {
      //determine if spousal import checkbox is checked
      let checkbox: HTMLInputElement = document.getElementById('flaCheckbox' + clientIndex) as HTMLInputElement;
      if (checkbox && checkbox.checked) {
        return cirfClientInfo.cirfClient.spouseName;
      } else {
        let spouseParticipantId: number = cirfClientInfo.participantCopy.getSpouseParticipantId(this.matter.provinceCode);
        let spouse: MatterParticipant = this.matterClients.find(client => client.matterParticipantId == spouseParticipantId);
        if (spouse) {
          return spouse.contact.surnameLastFullName;
        }
      }
    }

    return '';
  }

  isFlaSpouseSupported(): boolean {
    return this.matter.isMatterProvinceON || this.matter.isMatterProvinceMB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceNS || this.matter.isMatterProvinceNB;
  }

  openStickyNotesModal(matterInsuranceTopic: MatterTopic) {
    this.cirfImportStickyNotesHelperService.openStickyNotesModal(matterInsuranceTopic);
  }

  filterCirfClientInfoUdfs(cirfClient: CirfClientInfo): CirfUdf[] {
    return cirfClient && cirfClient.cirfClientInfoUdfs && cirfClient.cirfClientInfoUdfs.length ?
      cirfClient.cirfClientInfoUdfs.filter((udf, i) => !this.isFieldHidden(CIRF_CONFIG_KEYS.cirfClientInfoUdf + i, udf.fieldValue, true)) : [];
  }

  filterIdentificationUdfs(cirfClient: CirfClientInfo, index: number): CirfUdf[] {
    return cirfClient && cirfClient.getIdentification(index) && cirfClient.getIdentification(index).identificationUdfs && cirfClient.getIdentification(index).identificationUdfs.length ?
      cirfClient.getIdentification(index).identificationUdfs.filter((udf, i) => !this.isFieldHidden(CIRF_CONFIG_KEYS[ `identification${ index + 1 }Udf` ] + i, udf.fieldValue, true)) : [];
  }

  loadUDfs(ownerData: CirfImportDataHelper): void {
    ownerData.clientUdfs = this.filterCirfClientInfoUdfs(ownerData.cirfClient);
    ownerData.primaryIdentificationUdfs = this.filterIdentificationUdfs(ownerData.cirfClient, 0);
    ownerData.secondaryIdentificationUdfs = this.filterIdentificationUdfs(ownerData.cirfClient, 1);
  }

  getIdentificationUdfs(ownerData: CirfImportDataHelper, index: number): CirfUdf[] {
    return index == 0 ? ownerData.primaryIdentificationUdfs : ownerData.secondaryIdentificationUdfs;
  }
}

