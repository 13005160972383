import {Injectable} from '@angular/core';
import {Utils} from '../matters/shared';
import * as _ from 'lodash';

/**
 * Shared between MatterModule.MatterService and OpportunitiesModule.OpportunitiesService
 * */
@Injectable()
export class MatterHelperService {

  // generating URL filter for contacts like LawClerk or Lawyer
  createMatterSearchRequestForContacts(contacts: string[], filter: string, notAssignedField: string, assignedField: string): string {
    if (Array.isArray(contacts)) {
      if (contacts.indexOf('ALL') > -1) {
        //No Filter
      } else {
        let newFilter = this.getFilterForAssignedFields(contacts, notAssignedField, assignedField);

        if (newFilter.length > 0) {
          filter = Utils.addCommaToFilter(filter);
          filter += 'ANY' + newFilter;
        }
      }
    }
    return filter;
  }

  getFilterForAssignedFields(contacts: string[], notAssignedField: string, assignedField: string): string {
    let notAssignedFilter: string = '';
    let assignedFilter: string = '';
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[ i ] === 'N/A') {
        notAssignedFilter = `matterSearchView.hasAssignee_EQ_false`; // `matterSearchView.hasLawClerk_EQ_false`;
      } else {
        if (assignedFilter.length === 0) {
          assignedFilter = `matterSearchView.${ assignedField }SourceContactId_IN_`; // `matterSearchView.lawClerkSourceContactId_IN_`;
        }
        if (i == (contacts.length - 1)) {
          assignedFilter += contacts[ i ];
        } else {
          assignedFilter += contacts[ i ] + '!';
        }
      }
    }
    if (notAssignedFilter.length > 0 || assignedFilter.length > 0) {
      return (_.filter([ notAssignedFilter, assignedFilter ]).join('|'));
    }
    return '';
  }

  // option to search for a contact in multiple fields
  // e.g. opportunities use the concept of "Assignee" which includes solicitors and law clerks
  createMatterSearchRequestForContactsInMultipleFields(contacts: string[], filter: string, searchContactFields: SearchContactFields[]): string {
    if (searchContactFields && searchContactFields.length) {
      let newFilters = searchContactFields.map(value => {
        return this.getFilterForAssignedFields(contacts, value.notAssignedField, value.assignedField);
      }).filter(Boolean);
      if (newFilters.length > 0) {
        filter = Utils.addCommaToFilter(filter);
        filter += 'ANY' + newFilters.join('|');
      }
    }
    return filter;
  }
}

export class SearchContactFields {
  notAssignedField: string;
  assignedField: string;

  constructor(notAssignedField: string, assignedField: string) {
    this.notAssignedField = notAssignedField;
    this.assignedField = assignedField;
  }
}
