import {CustomTeraviewDocumentNameFields, ERegStatus, ERegType, ON_DEMAND_VALUE} from '../../../shared-main/constants';
import {ERegistrationJson, MatterPropertyInfo, MortgageInfo, ParticipantInfo} from './eregistrationjson';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {Matter} from '../../shared/matter';
import {Mortgage} from '../../shared/mortgage';
import {MatterParticipant} from '../../shared/matter-participant';
import {UnitLevelPlanUtil} from '../../property-teranet/unit-level-plan/unit-level-plan-util';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../shared/matter-drop-downs';
import {AddressUtil} from '../../shared/address-util';
import {MatterProperty} from '../../shared/matter-property';
import {CondominiumPlan} from '../../property-teranet/unit-level-plan/condominium-plan';
import {CondominiumExpense} from '../../property-teranet/unit-level-plan/condominium-expense';
import {AddressTypes} from '../../shared/address-types';
import {Address} from '../../shared/address';
import moment from 'moment';
import * as _ from 'lodash';
import {condoPlanTypeDropDownOptions} from '../../../shared-main/province-based-dropdowns';
import {Contact} from '../../shared';

const propertyIgnoreList = [ 'eregistrationStatus' ];

export class ERegistrationForm extends BaseEntity {

  eregistrationType: string;
  eregistrationData: ERegistrationJson;
  mortgageId: number;
  escrowInfo: string;
  includePlanningStatements: boolean;
  escrowInfoOverwritten: boolean;
  statementProvisionInfo: string;
  eregistrationJson: string;
  eregistrationStatus: string;
  eRegistrationFileName: string;
  lastUpdatedUser: string;
  lastUpdatedTimeStamp: string;
  id: number;
  isDirty: boolean;
  resetStatus: boolean = false;
  eregDocNameDirty: boolean;
  fileCreationTime: string;

  constructor(eReg?: ERegistrationForm) {
    super(eReg);
    if (eReg) {
      this.eregistrationData = new ERegistrationJson(eReg.eregistrationData);
    } else {
      this.isDirty = false;
    }
  }

  isTransfer(): boolean {
    return (this.eregistrationType == ERegType.EREG_TRANSFER);
  }

  isMortgage(): boolean {
    return (this.eregistrationType == ERegType.EREG_MORTGAGE);
  }

  isDischarge(): boolean {
    return (this.eregistrationType == ERegType.EREG_DISCHARGE);
  }

  isStatusCreated(): boolean {
    return (this.eregistrationStatus == ERegStatus.EREG_CREATED);
  }

  isStatusUploaded(): boolean {
    return (this.eregistrationStatus == ERegStatus.EREG_UPLOADED);
  }

  isStatusReady(): boolean {
    return (this.eregistrationStatus == ERegStatus.EREG_READY);
  }

  isStatusIncomplete(): boolean {
    return (this.eregistrationStatus == ERegStatus.EREG_INCOMPLETE);
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }

  // This method is at this time (June 1, 2018) a temporary fix to allow execution of various doc templates
  // from "Ack & Direction" button. I assume it will either be removed or implemented properly when the user story will be completed.
  getDocumentTemplateName(): string {
    if (this.isTransfer()) {
      return 'ackdirtransfer.docx';
    }
    if (this.isDischarge()) {
      return 'ackdirchargechargor.docx';
    }
    if (this.isMortgage()) {
      return 'ackdirchargechargor.docx';
    }
    return 'ackdirtransfer.docx';
  }

  //Move the following functions from ERegistrationModalComponent to here.
  //So ERegistrationModalComponent and ERegistrationComponent can share these functions
  updateFormDropDownsForEregistrationData(matter: Matter): void {

    if (this && this.isTransfer()
    ) {
      if (matter.isProjectSale) {
        this.eregistrationData.documentType = matter.eRegTransferDocumentTypeForProject;
      } else {
        if (this && this.eregistrationData && !this.eregistrationData.documentType) {
          let firstVendorContact: Contact = matter && matter.vendors && matter.vendors.length > 0 && matter.vendors[ 0 ] && matter.vendors[ 0 ].contact;
          if (matter && (matter.isPurchase || matter.isSale) && firstVendorContact && firstVendorContact.isEstate) {
            this.eregistrationData.documentType = 'TRANSFER_BY_PERSONAL';
          } else {
            this.eregistrationData.documentType = 'TRANSFER';
          }
        }
      }
    } else if (this && this.isMortgage()) {
      if (matter.isProjectSale) {
        this.eregistrationData.documentType = matter.eRegChargeDocumentTypeForProject;
      } else {
        if (this && this.eregistrationData && !this.eregistrationData.documentType) {
          this.eregistrationData.documentType = 'CHARGE';
        }
      }
    } else if (this && this.isDischarge()) {
      if (this && this.eregistrationData && !this.eregistrationData.documentType) {
        this.eregistrationData.documentType = 'DISCHARGE_OF_CHARGE';
      }
    }
    if (this && this.eregistrationData && !this.eregistrationData.actingFor) {
      this.eregistrationData.actingFor = matter.isSale ? 'PARTIES_FROM' : 'PARTIES_TO';
    }
    if (this && this.eregistrationData && !this.eregistrationData.feePayment) {
      this.eregistrationData.feePayment = 'PARTIES_TO';
    }
  }

  buildEregistrationData(matter: Matter, mortgageId: number, mortgageIndex: number, isModal: boolean, projectTemplateMatter: Matter): void {

    if (this.eregistrationData === undefined) {
      this.eregistrationData = new ERegistrationJson();
    }

    this.createMortgageInfo(matter, mortgageId);

    if (this.isMortgage() || this.isDischarge()) {
      this.mortgageId = mortgageId;
    }
    if (this.isDischarge()) {
      if (this.eregistrationData.isInstrumentExsMortgage) {
        this.eregistrationData.instrumentNo = this.getMortgageRequestNo(matter, mortgageId);
      }
      if (!this.isRelatedInstrumentRegNumberPresent(matter, mortgageId)) {
        this.eregistrationData.overrideMortgageRegistrationNo = undefined;
        this.eregistrationData.mortgageRegistrationNo = undefined;
      }
      if (this.isRelatedInstrumentRegNumberPresent(matter, mortgageId) && (this.eregistrationData.overrideMortgageRegistrationNo == undefined
        || this.eregistrationData.overrideMortgageRegistrationNo == null)) {
        this.eregistrationData.overrideMortgageRegistrationNo = true;
        this.eregistrationData.mortgageRegistrationNo = this.getMortgageRegistrationNumber(matter, mortgageId);
      }
      if (!this.eregistrationData.propertyPortion) {
        this.eregistrationData.propertyPortion = 'ALL';
      }
    }
    if (!isModal) {
      this.createPropertiesforMatter(matter);
      this.cleanUpMatterPropertiesInfo(matter);
      this.createTransfereeOrChargeeforMatter(matter, mortgageId);
    }

    this.isFirstVendorCorporation(matter, mortgageId, projectTemplateMatter);
    this.isFirstVendorMaleOrFemalePOA(matter);
    this.updateFormDropDownsForEregistrationData(matter);
    this.constructDocumentName(matter, mortgageId, mortgageIndex, this.eregistrationType);
  }

  isRelatedInstrumentRegNumberPresent(matter: Matter, mortgageId: number): boolean {
    const mortgage: Mortgage = this.getExistingMortgage(matter, mortgageId);
    return (mortgage && mortgage.relatedInstruments && mortgage.relatedInstruments.length && mortgage.relatedInstruments[ 0 ].registrationNumber) ? true : false;
  }

  createTransfereeOrChargeeforMatter(matter: Matter, mortgageId: number) {
    if (this.isTransfer()) {
      matter.purchasers.map(participant => {
        // let eReg : ERegSection = this.createERegSection(participant);
        this.populateParticipantAddress(participant, matter, mortgageId);
      });
    } else if (this.isMortgage()) {
      let mortgagees: MatterParticipant[] = this.getMortgagee(matter, mortgageId);
      if (Array.isArray(mortgagees)) {
        mortgagees.map(m => {
          this.populateParticipantAddress(m, matter, mortgageId);
        });
      }
    }
  }

  getMortgagee(matter: Matter, mortgageId: number): MatterParticipant[] {
    let participant: MatterParticipant[] = matter.matterParticipants.filter(m => (m.mortgageId === mortgageId)
      && (m.matterParticipantRole === 'MORTGAGEE' || m.matterParticipantRole === 'PRIVATE_LENDER'));
    return participant;

  }

  cleanUpMatterPropertiesInfo(matter: Matter): void {
    let availableClientIdentifier: any[] = [];
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium()) {
      //only the condoExpense with valid Pin number will be candidate for matterPropertiesInfo
      availableClientIdentifier = matter.matterPropertyWithCondo.condominiumExpenses.filter(condoExpense => condoExpense.pinNumber).map(condominiumExpense => {
        return condominiumExpense.clientSideIdentifier;
      });
    } else {
      availableClientIdentifier = matter.matterProperties.map(matterProperty => {
        return matterProperty.clientSideIdentifier;
      });
    }
    if (this.eregistrationData && this.eregistrationData.matterPropertiesInfo) {

      let matterPropertiesInfoNA = this.eregistrationData.matterPropertiesInfo.filter(item => availableClientIdentifier.indexOf(item.clientSideIdentifier) < 0);
      if (matterPropertiesInfoNA) {
        matterPropertiesInfoNA.forEach(item => {
          (<any>this.eregistrationData.matterPropertiesInfo).remove(item);
        });
      }
    }

  }

  isFirstVendorMaleOrFemalePOA(matter: Matter): void {
    if (this.eregistrationData && this.eregistrationData.statement29 == undefined) {
      if (this.eregistrationData && !this.isDischarge()) {
        let participants: MatterParticipant[] = this.isTransfer() ? matter.vendors : matter.purchasers;
        this.eregistrationData.statement29 = !(participants[ 0 ] && participants[ 0 ].contact ? (participants[ 0 ].contact.gender === ('MALEPOA' || 'FEMALEPOA')) : false);
      } else {
        this.eregistrationData.statement29 = true;
      }
    }
  }

  isFirstVendorCorporation(matter: Matter, mortgageId: number, projectTemplateMatter: Matter): void {
    if (this.eregistrationData && this.eregistrationData.statement10 == undefined) {
      if (this.isDischarge()) {
        this.isFirstMortgageeCorporationForDischarge(matter, mortgageId);
      } else {
        let participants: MatterParticipant[] = this.isTransfer() ? (projectTemplateMatter && matter.isProjectSale && this.isTransferFromThirdPartyOnProject(matter) ? projectTemplateMatter.transferors : matter.vendors) : this.getParticipantsForERegCharge(matter);
        this.eregistrationData.statement10 = (participants[ 0 ] && participants[ 0 ].contact ? (participants[ 0 ].contact.gender === 'CORPORATION') : false);
      }
    }
  }

  isTransferFromThirdPartyOnProject(matter: Matter): boolean {
    return matter.project && matter.project.docRegistration && matter.project.docRegistration.isTransferFromThirdPartySelected();
  }

  getParticipantsForERegCharge(matter: Matter): MatterParticipant [] {
    if (matter.isMortgage) {
      return matter.matterParticipants.filter(p => p.matterParticipantRole === 'MORTGAGOR');
    } else {
      return matter.purchasers;
    }

  }

  isFirstMortgageeCorporationForDischarge(matter: Matter, mortgageId: number): void {
    let existingMortgage: Mortgage = this.getExistingMortgage(matter, mortgageId);

    let participants: MatterParticipant[] = existingMortgage && existingMortgage.mortgageeType === 'INSTITUTION' ? matter.getMortgagees(existingMortgage)
      : matter.getPrivateLenders(existingMortgage);
    this.eregistrationData.statement10 = (participants[ 0 ] && participants[ 0 ].contact ?
      (participants[ 0 ].contact.gender === 'CORPORATION' || participants[ 0 ].contact.gender === 'OTHERENTITY') : false);
  }

  getMortgageRequestNo(matter: Matter, mortgageId: number): string {
    let existingMortgage: Mortgage = this.getExistingMortgage(matter, mortgageId);
    if (existingMortgage && existingMortgage.mortgageRequestNo) {
      return existingMortgage.mortgageRequestNo;
    }
  }

  getMortgageRegistrationNumber(matter: Matter, mortgageId: number): string {
    let existingMortgage: Mortgage = this.getExistingMortgage(matter, mortgageId);
    if (existingMortgage && existingMortgage.relatedInstruments) {
      return existingMortgage.relatedInstruments[ 0 ].registrationNumber;
    }
  }

  constructDocumentName(matter: Matter, mortgageId: number, mortgageIndex: number, eRegistrationType: string): void {
    if (this && this.eregistrationData && (!this.eregistrationData.documentName || !this.eregDocNameDirty)) {
      if (this.isDocumentNameTypeCustom(matter) && this.eregistrationData) {
        this.constructCustomDocumentName(matter, mortgageId, mortgageIndex, eRegistrationType);
      } else {
        this.constructDefaultDocumentName(matter, mortgageId, mortgageIndex, eRegistrationType);

      }
    }
  }

  isDocumentNameTypeCustom(matter: Matter): boolean {
    if (matter.isProjectSale && matter.project) {
      return matter.project.teraviewConfiguration && matter.project.teraviewConfiguration.isDocumentNameTypeCustom();
    } else {
      return matter.teraviewConfig && matter.teraviewConfig.isDocumentNameTypeCustom();
    }

  }

  populateDocumentNameForMortgage(matter: Matter, mortgageId: number, mortgageIndex: number, eRegistrationType: string): string {
    const mortgage: Mortgage = this.getMortgageByERegistrationType(matter, mortgageId, eRegistrationType);

    if (this.eregistrationData && mortgage) {
      let priority: number = this.isDischarge() ? (mortgageIndex + 1) : mortgage.mortgagePriority;
      return matter.matterRecordNumber + ' ' + this.ordinalPrefix(priority) +
        (this.isMortgage() ? ' Charge' : ' Discharge');
    }
  }

  constructDefaultDocumentName(matter: Matter, mortgageId: number, mortgageIndex: number, eRegistrationType: string): void {
    this.eregistrationData.documentName = this.isTransfer() ? matter.matterRecordNumber + ' Transfer'
      : (this.isMortgage() || this.isDischarge()) ? this.populateDocumentNameForMortgage(matter, mortgageId, mortgageIndex, eRegistrationType) : '';

  }

  isCustomDocumentNameFieldsExist(matter): boolean {
    if (matter.isProjectSale) {
      return matter.project.teraviewConfiguration && matter.project.teraviewConfiguration.customDocumentNameFields.length > 0;
    } else {
      return matter.teraviewConfig && matter.teraviewConfig.customDocumentNameFields.length > 0;
    }
  }

  getCustomDocumentNameFields(matter: Matter): string[] {
    if (matter.isProjectSale) {
      return matter.project.teraviewConfiguration && matter.project.teraviewConfiguration.customDocumentNameFields;
    } else {
      return matter.teraviewConfig && matter.teraviewConfig.customDocumentNameFields;
    }
  }

  constructCustomDocumentName(matter: Matter, mortgageId: number, mortgageIndex: number, eRegistrationType: string): void {
    let docName: string[] = [];
    const mortgage: Mortgage = this.getMortgageByERegistrationType(matter, mortgageId, eRegistrationType);
    if (this.isCustomDocumentNameFieldsExist(matter)) {
      let customDocumentNameFields: string[] = this.getCustomDocumentNameFields(matter);
      customDocumentNameFields.forEach(item => {
          let customTeraview: any = CustomTeraviewDocumentNameFields.find(field => field.label == item);

          if (customTeraview) {
            let matterPath = customTeraview.path.split('.');
            let pathValue: any;
            if (customTeraview.key == 'DOC_TYPE' && this.eregistrationData && this.eRegTransferOptions) {
              pathValue = this.isTransfer() ? 'Transfer' :
                this.ordinalPrefix(mortgageIndex + 1) + (this.isMortgage() ? ' Charge' : ' Discharge');
            } else if (customTeraview.key && customTeraview.key.indexOf('DATE') > -1) {
              let dateObject = matter[ customTeraview.path ];
              if (dateObject && dateObject.split('/').length > 2) {
                pathValue = moment(dateObject, 'YYYY/MM/DD').format('MMM' +
                  ' D, YYYY');
              }
            } else if (customTeraview.key && customTeraview.key == 'TERANET_DOCKET') {
              pathValue = matter.teranetDocket && matter.teranetDocket[ customTeraview.path ];
            } else {
              if (customTeraview.key && customTeraview.key == 'MORTGAGE' && matter.isMortgage && this.mortgageId) {
                let eregMortgage = matter.mortgages.find((mortgage) => {
                  return mortgage.id == this.mortgageId;
                });
                if (!eregMortgage) {
                  eregMortgage = matter.existingMortgages.find((mortgage) => {
                    return mortgage.id == this.mortgageId;
                  });
                }
                if (eregMortgage) {
                  matter.reCalculateLenderReLine(eregMortgage);
                  pathValue = eregMortgage.lenderReline;
                }

              } else {
                matterPath.forEach(path => {
                  if (!pathValue) {
                    pathValue = matter[ path ];
                  } else {
                    pathValue = pathValue[ path ];
                  }

                });
              }

            }

            if (pathValue) {
              if (customTeraview.key && customTeraview.key.indexOf('UNIT_LEVEL') > -1) {
                pathValue = UnitLevelPlanUtil.generateUnitLevelPlanForEReg(pathValue);
                if (pathValue) {
                  docName.push(pathValue);
                }
              } else {
                docName.push(pathValue);
              }

            }
          }
        }
      );
    }
    this.eregistrationData.documentName = docName.join('.').slice(0, 40);
  }

  createMortgageInfo(matter: Matter, mortgageId: number) {
    const mortgage: Mortgage = this.getMortgage(matter, mortgageId);

    if (this.isMortgage() && mortgage) {
      if (!this.eregistrationData.mortgageInfo && mortgageId) {
        this.eregistrationData.mortgageInfo = new MortgageInfo();
        this.eregistrationData.mortgageInfo.mortgageId = mortgageId;
        this.eregistrationData.mortgageInfo.payment = (mortgage.mortgageTerm && mortgage.mortgageTerm.regularPayment
          ? mortgage.mortgageTerm.regularPayment.toString() : '0');
        this.eregistrationData.mortgageInfo.calculationPeriod = mortgage.mortgageTerm ? mortgage.getCalculationPeriodFromMatter() : '';
        this.eregistrationData.mortgageInfo.paymentDate = mortgage.mortgageTerm && mortgage.mortgageTerm.paymentsDue ? mortgage.mortgageTerm.paymentsDue : '';

        this.eregistrationData.mortgageInfo.balanceDueDate = mortgage.mortgageTerm && mortgage.mortgageTerm.maturityDate ?
          this.validateDate(mortgage.mortgageTerm.maturityDate) ? this.formatDate(mortgage.mortgageTerm.maturityDate,
            'YYYY/MM/DD', 'DD/MM/YYYY') : mortgage.mortgageTerm.maturityDate : '';

        this.eregistrationData.mortgageInfo.interestAdjDate = mortgage.mortgageTerm && mortgage.mortgageTerm.interestAdjustmentDate && this.validateDate(mortgage.mortgageTerm.interestAdjustmentDate) ?
          this.formatDate(mortgage.mortgageTerm.interestAdjustmentDate, 'YYYY/MM/DD', 'DD/MM/YYYY') : '';
        this.eregistrationData.mortgageInfo.firstPaymentDate = mortgage.mortgageTerm && mortgage.mortgageTerm.firstPaymentDate && this.validateDate(mortgage.mortgageTerm.firstPaymentDate) ?
          this.formatDate(mortgage.mortgageTerm.firstPaymentDate, 'YYYY/MM/DD', 'DD/MM/YYYY') : '';

        this.eregistrationData.mortgageInfo.lastPaymentDate = mortgage.mortgageTerm && mortgage.mortgageTerm.maturityDate && this.validateDate(mortgage.mortgageTerm.maturityDate) ?
          this.formatDate(mortgage.mortgageTerm.maturityDate, 'YYYY/MM/DD', 'DD/MM/YYYY') : '';

        //this.eregistrationData.mortgageInfo.registeredInterest = mortgage.interestRate;
        if (mortgage.isEmpMortgage() && mortgage.mortgageTerm.registeredInterest != null && mortgage.mortgageTerm.registeredInterest !== '') {
          this.eregistrationData.mortgageInfo.interestRate = mortgage.mortgageTerm.registeredInterest;
        } else {
          this.eregistrationData.mortgageInfo.interestRate = mortgage.interestRate;
        }

        this.eregistrationData.mortgageInfo.interestAdjDateState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.firstPaymentDateState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.lastPaymentDateState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.paymentDueState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.interestRateState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.paymentDateState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.calculationPeriodState = 'DEFAULT';
        this.eregistrationData.mortgageInfo.balanceDueDateState = 'DEFAULT';

      } else {
        if (this.eregistrationData.mortgageInfo.interestAdjDateState === 'DEFAULT') {
          this.eregistrationData.mortgageInfo.interestAdjDate = mortgage.mortgageTerm && mortgage.mortgageTerm.interestAdjustmentDate && this.validateDate(mortgage.mortgageTerm.interestAdjustmentDate) ?
            this.formatDate(mortgage.mortgageTerm.interestAdjustmentDate, 'YYYY/MM/DD', 'DD/MM/YYYY') : '';
        }
        if (this.eregistrationData.mortgageInfo.firstPaymentDateState === 'DEFAULT') {
          this.eregistrationData.mortgageInfo.firstPaymentDate = mortgage.mortgageTerm && mortgage.mortgageTerm.firstPaymentDate && this.validateDate(mortgage.mortgageTerm.firstPaymentDate) ?
            this.formatDate(mortgage.mortgageTerm.firstPaymentDate, 'YYYY/MM/DD', 'DD/MM/YYYY') : '';
        }
        if (this.eregistrationData.mortgageInfo.lastPaymentDateState === 'DEFAULT') {
          this.eregistrationData.mortgageInfo.lastPaymentDate = mortgage.mortgageTerm && mortgage.mortgageTerm.maturityDate && this.validateDate(mortgage.mortgageTerm.maturityDate) ?
            this.formatDate(mortgage.mortgageTerm.maturityDate, 'YYYY/MM/DD', 'DD/MM/YYYY') : '';
        }
        if (this.eregistrationData.mortgageInfo.paymentDueState === 'DEFAULT') {
          this.eregistrationData.mortgageInfo.payment = (mortgage.mortgageTerm && mortgage.mortgageTerm.regularPayment
            ? mortgage.mortgageTerm.regularPayment.toString() : '0');
        }
        if (this.eregistrationData.mortgageInfo.interestRateState === 'DEFAULT') {
          if (mortgage.isEmpMortgage() && mortgage.mortgageTerm.registeredInterest != null && mortgage.mortgageTerm.registeredInterest !== '') {
            this.eregistrationData.mortgageInfo.interestRate = mortgage.mortgageTerm.registeredInterest;
          } else {
            this.eregistrationData.mortgageInfo.interestRate = mortgage.interestRate;
          }
        }
        if (this.eregistrationData.mortgageInfo.paymentDateState === 'DEFAULT') {
          this.eregistrationData.mortgageInfo.paymentDate = mortgage.mortgageTerm && mortgage.mortgageTerm.paymentsDue ? mortgage.mortgageTerm.paymentsDue : '';
        }
        if (this.eregistrationData.mortgageInfo.calculationPeriodState === 'DEFAULT') {
          this.eregistrationData.mortgageInfo.calculationPeriod = mortgage.mortgageTerm ? mortgage.getCalculationPeriodFromMatter() : '';

        }
        if (this.eregistrationData.mortgageInfo.balanceDueDateState === 'DEFAULT') {
          if (mortgage.mortgageTerm && mortgage.mortgageTerm.maturityDate) {
            if (mortgage.mortgageTerm.isMaturityDateOnDemand()) {
              this.eregistrationData.mortgageInfo.balanceDueDate = ON_DEMAND_VALUE;
            } else if (this.validateDate(mortgage.mortgageTerm.maturityDate)) {
              this.eregistrationData.mortgageInfo.balanceDueDate = this.formatDate(mortgage.mortgageTerm.maturityDate, 'YYYY/MM/DD', 'DD/MM/YYYY');
            } else {
              this.eregistrationData.mortgageInfo.balanceDueDate = '';
            }
          } else {
            this.eregistrationData.mortgageInfo.balanceDueDate = '';
          }
        }
      }
    }
  }

  getMortgage(matter: Matter, mortgageId: number): Mortgage {
    return matter.mortgages.find(m => m.id === mortgageId);
  }

  getExistingMortgage(matter: Matter, mortgageId: number): Mortgage {
    return matter.existingMortgages.find(m => m.id === mortgageId);
  }

  getMortgageByERegistrationType(matter: Matter, mortgageId: number, eRegistrationType: string) {
    if (eRegistrationType == ERegType.EREG_DISCHARGE) {
      return this.getExistingMortgage(matter, mortgageId);
    } else {
      return this.getMortgage(matter, mortgageId);
    }
  }

  ordinalPrefix(ordinal: number): string {
    return (ordinal === 1 ? ordinal + 'st' : ordinal === 2 ? ordinal + 'nd' : ordinal === 3 ? ordinal + 'rd' : ordinal + 'th');
  }

  validateDate(date: string): boolean {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    return pattern.test(date);
  }

  formatDate(date: string, inputFormat: string, outputFormat: string): string {
    return (date ? moment(date, inputFormat).format(outputFormat) : date);
  }

  get eRegTransferOptions(): SelectItem[] {
    let eRegTransferOptions: SelectItem[];
    if (this.isTransfer()
    ) {
      eRegTransferOptions = dropDowns.eRegTransfer;
    } else if (this.isMortgage()) {
      eRegTransferOptions = dropDowns.eRegMortgage;
    } else if (this.isDischarge()) {
      eRegTransferOptions = dropDowns.eRegDischarge;
    }
    return eRegTransferOptions;
  }

  populateMatterPropertiesInfo(matter: Matter, matterProperty: MatterProperty, provinceCode: string, condominiumExpense?: CondominiumExpense, propertyIndex?: number): void {
    let propertyId = condominiumExpense ? condominiumExpense.clientSideIdentifier : matterProperty.clientSideIdentifier;
    let matterPropertyWithCondo = matter.matterPropertyWithCondo;
    let matterPropertyWithCondoPlans: CondominiumPlan[];
    if (matter.matterPropertyWithCondo.condominiumPlans && matter.matterPropertyWithCondo.condominiumPlans.length > 0) {
      matterPropertyWithCondoPlans = matter.matterPropertyWithCondo.condominiumPlans;
    }
    let matterPropertyInfoFound = this.eregistrationData && this.eregistrationData.matterPropertiesInfo.find(p => p.clientSideIdentifier === propertyId);
    let matterPropertyInfo: MatterPropertyInfo;
    if (!matterPropertyInfoFound) {
      matterPropertyInfo = new MatterPropertyInfo();
      matterPropertyInfo.acceptAddress = true;
      if (matter.isProjectSale) {
        matterPropertyInfo.acceptLegalDescription = !matterProperty.overrideDescription;
      } else {
        matterPropertyInfo.acceptLegalDescription = true;
      }

      this.eregistrationData.matterPropertiesInfo.push(matterPropertyInfo);
    } else {
      matterPropertyInfo = matterPropertyInfoFound;
    }
    if (!matter.isProjectSale) {
      if (propertyIndex && propertyIndex > 0) {
        matterPropertyInfo.isLegalDescriptionEnabled = false;
      } else {
        matterPropertyInfo.isLegalDescriptionEnabled = true;
      }
    }
    matterPropertyInfo.clientSideIdentifier = propertyId;
    matterPropertyInfo.intresetEstate = matterPropertyWithCondo ? matterPropertyWithCondo.interestEstateLabel : '';
    if (matter.isProjectSale) {
      let isLegalDescriptionOverride: boolean = matterProperty.isPropertyCondominium() ? condominiumExpense.overrideDescInd : matterProperty.overrideDescription;
      if (!matterPropertyInfo.isLegalDescriptionEnabled && !isLegalDescriptionOverride) {
        matterPropertyInfo.legalDescription = undefined;
        matterPropertyInfo.acceptLegalDescription = undefined;
      }
      if (isLegalDescriptionOverride) {
        matterPropertyInfo.acceptLegalDescription = false;
        matterPropertyInfo.isLegalDescriptionEnabled = false;
        matterPropertyInfo.legalDescription = matterProperty.isPropertyCondominium() ? this.formatProjectSaleLegalDescription(matter, matterProperty, matterPropertyWithCondo, condominiumExpense)
          : this.formatProjectSaleLegalDescription(matter, matterProperty, matterPropertyWithCondo);
      } else {
        matterPropertyInfo.isLegalDescriptionEnabled = true;
      }
      if (matterPropertyInfo.acceptLegalDescription === undefined) {
        matterPropertyInfo.acceptLegalDescription = true;
      }
      if ((!matterPropertyInfo.legalDescription && !isLegalDescriptionOverride) || matterPropertyInfo.acceptLegalDescription) {
        matterPropertyInfo.legalDescription = matterProperty.isPropertyCondominium() ? this.formatProjectSaleLegalDescription(matter, matterProperty, matterPropertyWithCondo, condominiumExpense, true)
          : this.formatProjectSaleLegalDescription(matter, matterProperty, matterPropertyWithCondo, null, true);
      }

    } else {
      //we need to update the legalDescription with the latest data when it is not the first one with "No Condo"
      if (!matterPropertyInfo.legalDescription || (!matter.isCondoCorporation && propertyIndex && propertyIndex > 0)) {
        matterPropertyInfo.legalDescription = condominiumExpense ? UnitLevelPlanUtil.generateUnitLevelPlanFromExp(condominiumExpense, provinceCode, matter, matterProperty)
          : matterProperty.propertyTaxesSummary;
      }
    }

    let pin: string = condominiumExpense && condominiumExpense.pinNumber ? condominiumExpense.pinNumber.replace('-', '') : '';
    matterPropertyInfo.pin = pin ? (pin.substr(0, 5) + '-' + pin.substr(5, 9))
      : (matterProperty && matterProperty.pin ? (matterProperty.pin.substr(0, 5) + '-' + matterProperty.pin.substr(5, 9)) : '');

    this.populateAddressInMatterPropertyInfo(matterProperty, matterPropertyInfo);

  }

  formatProjectSaleLegalDescription(matter: Matter, matterProperty: MatterProperty, matterPropertyWithCondo: MatterProperty, condominiumExpense?: CondominiumExpense, defaultLegalDescription: boolean = false): string {
    return matterProperty.isPropertyCondominium() ? this.formatLegalDescriptionForCondo(matter, matterProperty, matterPropertyWithCondo, defaultLegalDescription, condominiumExpense) : this.formatLegalDescriptionForNonCondo(matterProperty, matterPropertyWithCondo, defaultLegalDescription);
  }

  formatLegalDescriptionForNonCondo(matterProperty: MatterProperty, propertyData: MatterProperty, defaultLegalDescription: boolean): string {
    let str: string[] = [];
    if (matterProperty.isLegalDescriptionOverrideFull()) {
      matterProperty.fullLegalDescription ? str.push(matterProperty.fullLegalDescription) : '';
    }
    if (matterProperty.isLegalDescriptionOverrideAppendedBelow()) {
      str.push(...this.getPropertyDataForNonCondo(propertyData));
      matterProperty.fullLegalDescription ? str.push(matterProperty.fullLegalDescription) : '';
      propertyData.city ? str.push(this.formatCity(propertyData.city)) : '';

    }
    if (matterProperty.isLegalDescriptionOverrideAppendedAbove()) {
      matterProperty.fullLegalDescription ? str.push(matterProperty.fullLegalDescription) : '';
      str.push(...this.getEasementAndCity(propertyData));
    }
    if (matterProperty.isLegalDescriptionApplyLegalDescEasement()) {
      str.push(...this.getPropertyDataForNonCondo(propertyData));
      str.push(...this.getEasementAndCity(propertyData));
    }
    if (matterProperty.isLegalDescriptionOverrideAppendedInBetween()) {
      str.push(...this.getPropertyDataForNonCondo(propertyData));
      matterProperty.fullLegalDescription ? str.push(matterProperty.fullLegalDescription) : '';
      str.push(...this.getEasementAndCity(propertyData));
    }
    if (defaultLegalDescription) {
      str.push(...this.getPropertyDataForNonCondo(propertyData));
      propertyData.easementRightOfWay ? str.push(propertyData.easementRightOfWay) : '';
    }
    return (defaultLegalDescription ? str.join(' , ') : str.join('\n'));
  }

  formatLegalDescriptionForCondo(matter: Matter, matterProperty: MatterProperty, propertyData: MatterProperty, defaultLegalDescription: boolean, condominiumExpense: CondominiumExpense): string {
    let str: string[] = [];
    if (condominiumExpense.isLegalDescriptionOverrideFull()) {
      condominiumExpense.teraviewLegalDesc ? str.push(condominiumExpense.teraviewLegalDesc) : '';
    }
    if (condominiumExpense.isLegalDescriptionOverrideAppendedBelow()) {
      str.push(...this.getPropertyDataForCondo(matter, propertyData, condominiumExpense));
      condominiumExpense.teraviewLegalDesc ? str.push(condominiumExpense.teraviewLegalDesc) : '';
      propertyData.city ? str.push(this.formatCity(propertyData.city)) : '';

    }
    if (condominiumExpense.isLegalDescriptionOverrideAppendedAbove()) {
      condominiumExpense.teraviewLegalDesc ? str.push(condominiumExpense.teraviewLegalDesc) : '';
      str.push(...this.getEasementAndCity(propertyData));
    }
    if (condominiumExpense.isLegalDescriptionApplyLegalDescEasement()) {
      str.push(...this.getPropertyDataForCondo(matter, propertyData, condominiumExpense));
      str.push(...this.getEasementAndCity(propertyData));
    }
    if (condominiumExpense.isLegalDescriptionOverrideAppendedInBetween()) {
      str.push(...this.getPropertyDataForCondo(matter, propertyData, condominiumExpense));
      condominiumExpense.teraviewLegalDesc ? str.push(condominiumExpense.teraviewLegalDesc) : '';
      str.push(...this.getEasementAndCity(propertyData));
    }
    if (defaultLegalDescription) {
      str.push(...this.getDefaultDescForCondo(matter, propertyData, condominiumExpense));
      propertyData.easementRightOfWay ? str.push(propertyData.easementRightOfWay) : undefined;
    }
    return (defaultLegalDescription ? str.join(' , ') : str.join('\n'));
  }

  getPropertyDataForCondo(matter: Matter, property: MatterProperty, condominiumExpense: CondominiumExpense): string[] {
    let str: string[] = [];
    property.parcel || property.section ? str.push((property.parcel ? property.parcel : '') + (property.section ? ' , ' + property.section : '')) : '';
    str.push(...this.getDefaultDescForCondo(matter, property, condominiumExpense));
    return str;
  }

  getDefaultDescForCondo(matter: Matter, property: MatterProperty, condominiumExpense: CondominiumExpense): string[] {
    let str: string[] = [];
    let condominiumPlan = UnitLevelPlanUtil.getCondominiumPlanByPlanNumber(matter, condominiumExpense.planNumber, property);
    if (condominiumPlan && condominiumExpense) {
      str.push((condominiumExpense.unitNumber ? 'Unit ' + condominiumExpense.unitNumber + ' , ' : '')
        + (condominiumExpense.levelNumber ? 'Level ' + condominiumExpense.levelNumber + ' , ' : '')
        + (condominiumPlan.condominiumPlanType ? (property.condominiumJurisdiction ? property.condominiumJurisdiction + ' ' : '') + this.getCondominiumPlanType(condominiumPlan.condominiumPlanType, matter.provinceCode) : '')
        + (condominiumPlan.condominiumPlanNumber ? ' No. ' + condominiumPlan.condominiumPlanNumber : ''));
    }
    return str;
  }

  getCondominiumPlanType(type: string, provinceCode: string): string {
    let condoPlanType: any = _.find(condoPlanTypeDropDownOptions[ provinceCode ], condoPlanTypeObj => condoPlanTypeObj.value === type);
    return condoPlanType ? condoPlanType.label + ' ' : '';
  }

  getPropertyDataForNonCondo(property: MatterProperty): string[] {
    let str: string[] = [];
    property.parcel || property.section ? str.push((property.parcel ? property.parcel : '') + (property.parcel && property.section ? ' , ' : '') + (property.section ? property.section : '')) : '';
    property.partLot || property.plan ? str.push((property.partLot ? property.partLot : '') + (property.partLot && property.plan ? ' , ' : '') + (property.plan ? property.plan : '')) : '';
    property.beingPart || property.onPlan ? str.push((property.beingPart ? property.beingPart : '') + (property.beingPart && property.onPlan ? ' , ' : '') + (property.onPlan ? property.onPlan : '')) : '';
    return str;
  }

  getEasementAndCity(property: MatterProperty): string[] {
    let str: string[] = [];
    property.easementRightOfWay ? str.push(property.easementRightOfWay) : '';
    property.city ? str.push(this.formatCity(property.city)) : '';
    return str;
  }

  formatCity(city: string): string {
    let cityInUppercase = city.toUpperCase();
    if (cityInUppercase.includes('OF')) {
      return cityInUppercase.split('OF')[ 1 ].trim();
    }
    return cityInUppercase.toUpperCase();
  }

  populateAddressInMatterPropertyInfo(matterProperty: MatterProperty, matterPropertyInfo: MatterPropertyInfo) {
    if (matterProperty && matterProperty.address) {
      let addressStr: string = '';
      if (matterProperty.address.addressLine1) {
        addressStr = addressStr + matterProperty.address.addressLine1;
        if (matterProperty.address.addressLine2) {
          addressStr = addressStr + ',' + matterProperty.address.addressLine2;
        }
      } else if (matterProperty.address.addressLine2) {
        addressStr = matterProperty.address.addressLine2;
      }
      let address = AddressUtil.extraERegAddress(addressStr);
      if (address) {
        if (matterPropertyInfo.acceptAddress) {
          matterPropertyInfo.streetName = address[ 'streetName' ];
          matterPropertyInfo.streetNo = address[ 'streetNumber' ];
          matterPropertyInfo.streetNoSuffix = address[ 'streetNoSuffix' ];
        } else {
          if (!matterPropertyInfo.streetName) {
            matterPropertyInfo.streetName = address[ 'streetName' ];
          }
          if (!matterPropertyInfo.streetNo) {
            matterPropertyInfo.streetNo = address[ 'streetNumber' ];
          }
          if (matterPropertyInfo.streetNoSuffix == null) {//user may choose to blank it out
            matterPropertyInfo.streetNoSuffix = address[ 'streetNoSuffix' ];
          }

        }

      }
    }
  }

  createPropertiesforMatter(matter: Matter) {
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium()) {
      matter.matterPropertyWithCondo.condominiumExpenses.map(condominiumExpense => {
        if (condominiumExpense.pinNumber) {
          this.populateMatterPropertiesInfo(matter, matter.matterPropertyWithCondo, matter.provinceCode, condominiumExpense);
        }
      });
    } else {
      matter.matterProperties.map((matterProperty, index) => {
        if (matterProperty.pin) {
          this.populateMatterPropertiesInfo(matter, matterProperty, matter.provinceCode, null, index);
        }
      });
    }
  }

  populateParticipantAddress(matterParticipant: MatterParticipant, matter, mortgageId: number): void {
    let participantAddress = this.eregistrationData.partyToAddressForService.find(p => p.participantId === matterParticipant.matterParticipantId);
    if (!participantAddress) {
      let participantAddress = new ParticipantInfo();
      participantAddress.participantId = matterParticipant.matterParticipantId;
      participantAddress.isAddressOverridden = false;
      participantAddress.addressForService = this.isTransfer() ? this.partyToAddressForServiceForERegTransfer(matter) :
        this.mortgageeAddressForService(matterParticipant, matter, mortgageId);
      this.eregistrationData.partyToAddressForService.push(participantAddress);
    } else {
      if (participantAddress.isAddressOverridden === false) {
        participantAddress.addressForService = this.isTransfer() ? this.partyToAddressForServiceForERegTransfer(matter) :
          this.mortgageeAddressForService(matterParticipant, matter, mortgageId);
      }
    }
  }

  partyToAddressForServiceForERegTransfer(matter: Matter): string {
    if (matter.matterType === 'PURCHASE') {
      return matter.postClosingAddress;
    } else {
      //if matter type sale then purchaser residing flag is in otherPartyContactInfo
      if ((matter.matterType === 'SALE' && matter.otherPartyContactInfo.isResideAtSubjectProperty) || (matter.matterType === 'MORTGAGE' && matter.matterContactInfo.isResideAtSubjectProperty)) {
        return matter.matterPropertyWithCondo.address.addressTextWithProvinceName;
      } else {
        let contact = matter.contactInfo.find(c => c.contactInfoType === 'PURCHASER');
        if (contact && contact.addresses && contact.addresses.length > 0) {
          let address = contact.addresses.find(a => a.addressTypeCode === AddressTypes.serviceAddress);
          if (address) {
            return address.addressTextWithProvinceName;
          }
        }
      }
    }
  }

  mortgageeAddressForService(matterParticipant: MatterParticipant, matter: Matter, mortgageId: number): string {
    const mortgage: Mortgage = matter.mortgages.find(mortgage => mortgage.id == mortgageId);
    if (mortgage) {
      if (mortgage.isCorrespondWithSolicitor(matter)) {
        if (mortgage && mortgage.mortgageContactInfo && mortgage.mortgageContactInfo.serviceAddress) {
          console.log('>> Mortgagee/Primary Private Lender in Morgage %s\'s service address sameAsAddresstypeCode is %s', mortgageId, mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode);
          if (mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode == 'SOLICITOR') {
            const mortgageSolicitorMp: MatterParticipant = matter.matterParticipants.find(mp => mp.matterParticipantRole == 'MORTGAGE_SOLICITOR');
            if (mortgageSolicitorMp && mortgageSolicitorMp.contact && mortgageSolicitorMp.contact.mailingAddress) {
              return mortgageSolicitorMp.contact.mailingAddress.addressTextWithProvinceName;
            }
            return undefined;
          }
          //if it is manually entered, then use the address value stored in the serviceAddress
          return mortgage.mortgageContactInfo.serviceAddress.addressTextWithProvinceName;
        }
        return undefined;
      } else {
        let primaryMortgagee: MatterParticipant = matterParticipant.matterParticipantRole === 'PRIVATE_LENDER' ? matter.primaryPrivateLender(matter.getMortgageById(mortgageId)) : matterParticipant;
        let primaryMortgageeAddress: Address = primaryMortgagee.contact.serviceAddress;
        if (primaryMortgageeAddress) {
          if (primaryMortgageeAddress.sameAsAddressTypeCode === AddressTypes.mailing) {
            let mailingAddress: Address = primaryMortgagee.contact.mailingAddress;
            return mailingAddress && mailingAddress.addressTextWithProvinceName;
          } else {
            return primaryMortgageeAddress.addressTextWithProvinceName;
          }
        } else {
          return undefined;
        }
      }
    }
    return undefined;
  }
}
