import {CurrencyPipe} from '@angular/common';
import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs/Subject';
import {ContactQueryService} from '../../contact/contact-query.service';
import {ContactTab} from '../../contact/contact-tab';
import {TabsService} from '../../core/tabs.service';
import {ContactService} from '../../shared-main/contact.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {yesNoOptions} from '../mortgages/mortgage/dropdown-options';
import {Contact} from '../shared/contact';
import {Logger} from '@nsalaun/ng-logger';
import {currentMatter} from '../shared/current-matter';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {Matter} from '../shared/matter';
import {Utils} from '../../matters/shared/utils';
import {MatterParticipant} from '../shared/matter-participant';
import {MatterParticipantWrapper} from '../shared/matter-participant-wrapper';
import {DdlTypeForYesNo} from '../shared/mortgage';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {SoaConstants} from '../../admin/soa/soa-constants';
import {CondoManagedType, CondoManagedTypeConstValue, Constants, SnapshotBurgerMenuActions} from '../../shared-main/constants';
import {CommonContactDialogComponent} from '../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {MatterParticipantService} from '../matter-participant-service';
import {AttentionInfoComponent} from '../matter-opening/attention/attention-info.component';
import {EmailFieldService, EmailKeys} from '../../shared-main/email-field/email-field-service';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import {dropDowns} from '../shared/matter-drop-downs';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {SelectItem} from 'primeng/api';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {condoCorpDocuments} from '../../shared-main/province-based-dropdowns';
import {DocumentProductionService} from '../document-production/document-production.service';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';

interface ManagedTypeOption {
  label: string;
  value: CondoManagedType;
}

const managedTypeOptions: ManagedTypeOption[] = [
  {label: '', value: CondoManagedTypeConstValue.QUESTION},
  {label: 'Self-managed', value: CondoManagedTypeConstValue.SELF_MANAGED},
  {label: 'Management company', value: CondoManagedTypeConstValue.MANAGEMENT_COMPANY}
];

export const provincesWithoutStatusCertificate = [ 'AB', 'SK', 'MB' ];

@Component({
  selector: 'dp-condo-corporation',
  templateUrl: './condo-corporation.component.html',
  styleUrls: [ './condo-corporation.styles.scss' ],
  providers: [ CurrencyPipe, EmailFieldService ]
})
@FocusFirstElementDecorator()
export class CondoCorporationComponent implements OnInit, OnDestroy {
  @ViewChild(AttentionInfoComponent) attentionInfoComponent: AttentionInfoComponent;

  public ngUnsubscribe: Subject<void> = new Subject<void>();
  public managedTypeOptions: ManagedTypeOption[];
  public useHstOptions: DdlTypeForYesNo[];
  public condoCorporationWrapper: MatterParticipantWrapper;
  public condoCorporationOptions: Contact[];
  public condoCorporationsLoading: boolean = false;
  public attentionOptions: Contact[];
  public managementCompanyWrapper: MatterParticipantWrapper;
  // public selectedAttention: MatterParticipantWrapper;//Selected attention gets added as a matter participant
  public managementCompanyOptions: Contact[];
  public managementCompaniesLoading: boolean = false;
  public executingUpdateFromContactRecord: boolean;
  public f9DefaultCondoFee: number = SoaConstants.CONDO_STATUS_CERT_DEFAULT_FEE;

  public provinceOptions: SelectItem[];
  public taxRateType: string;
  public condoDocumentsSection: boolean = true;
  public condoDocumentsList: any[] = [];
  public selectedCondoDocumentsList: any[] = [];
  public subjectToGSTOptions = dropDowns.yesNoOnly;
  utils;
  _matter: Matter;

  constructor(public route: ActivatedRoute,
              public tabsService: TabsService,
              public getGlobalSaveModelService: GetGlobalSaveModelService,
              public contactService: ContactService,
              public contactQueryService: ContactQueryService,
              public dialogService: DialogService,
              public tabsStateService: TabsService,
              public taxRateService: TaxRateService,
              public logger: Logger,
              public currencyPipe: CurrencyPipe,
              public elementRef: ElementRef,
              public emailFieldService: EmailFieldService,
              public userStateService: UserStateService,
              public documentProductionService: DocumentProductionService,
              public matterParticipantService: MatterParticipantService) {
    this.utils = new Utils();
  }

  ngOnInit(): void {
    //this.tabsService.activeTab.section = this.route.routeConfig.path;
    this.managedTypeOptions = managedTypeOptions;
    this.useHstOptions = yesNoOptions;
    this.condoCorporationOptions = [];
    this.attentionOptions = [];
    this.managementCompanyOptions = [];

    this.initProvince();

    this.getGlobalSaveModelService.getItem$.takeUntil(this.ngUnsubscribe).subscribe(() => {
      this.openCondoCorporation();
    });
  }

  openCondoCorporation(): void {
    //currentMatter.value = matter;
    //If two matters are opened and both are on same tab condo corporation then flipping b/w them will not call ngOnDestroy, therefore releasing
    // locks from older participants before creating new wrappers
    console.log('Releasing lock from ngOnInit for matter ' + this.matter.id);
    this.releaseLockFromMatterParticipantsOnCondoTab();
    this.buildCondoCorporationWrapper(this.matter.condoCorporation);
    this.buildManagementCompanyWrapper(this.matter.managementCompany);
    if (this.attentionInfoComponent) {
      this.attentionInfoComponent.matter = this.matter;
    }
    this.matter.updateCondoFeeOnSOA();
    this.condoDocumentsList = condoCorpDocuments[ this.matter.provinceCode ];
    if (this.matter.isMatterCondoDocumentsVisible) {
      if (this.matter.condoCorporationDocumentation) {
        this.selectedCondoDocumentsList = this.matter.condoCorporationDocumentation.condoDocuments;
      }
    }
    this.loadTaxRateType();
    this.initProvince();
  }

  //In case of page refresh angular doesn't call ngDestroy and it's releasing any locks taken on contact. That will leave contact permanently locked
  // therefore calling unlock code on unload as well
  @HostListener('window:unload', [ '$event' ])
  beforeUnloadHandler(event) {
    this.releaseLockFromMatterParticipantsOnCondoTab();
  }

  ngOnDestroy(): void {
    console.log('Releasing lock from ngOnDestroy for matter ' + (this.matter && this.matter.id));
    this.releaseLockFromMatterParticipantsOnCondoTab();
    this.completeSubscriptions();
  }

  completeSubscriptions(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initProvince() {
    if (this.condoCorporation) {
      this.provinceOptions = this.userStateService.getProvinceOptions();
      if (this.condoCorporation.contactType === 'CONDO_CORPORATION' && Array.isArray(this.provinceOptions)) {

        if (!this.condoCorporation.mailingAddress.provinceName) {
          const defaultOption: SelectItem = this.provinceOptions.find(item => item.value == this.userStateService.defaultProvinceCode);
          this.condoCorporation.mailingAddress.provinceName = defaultOption && defaultOption.label;
        }
      }
    }
  }

  get isProvinceVisible(): boolean {
    return !!((this.condoCorporation && this.condoCorporation.contactType === 'CONDO_CORPORATION')
      && Array.isArray(this.provinceOptions)
      && this.provinceOptions.length > 1);

  }

  releaseLockFromMatterParticipantsOnCondoTab(): void {
    let isCondoWithSourceContact = this.condoCorporationWrapper && this.isCondoParticipantCreatedWithSourceContact(this.condoCorporationWrapper.matterParticipant);
    console.log('Entering into method releaseLockFromMatterParticipantsOnCondoTab for matter ' + (this.matter && this.matter.id) + ' this.condoCorporationWrapper ' + this.condoCorporationWrapper + ' and isCondoWithSourceContact ' + isCondoWithSourceContact);
    if (this.condoCorporationWrapper && isCondoWithSourceContact) {
      this.condoCorporationWrapper.obsolete = true;
      console.log('Unlocking condo from matter ' + (this.matter && this.matter.id) + ' from wrapper matter ' + this.condoCorporationWrapper.matterParticipant.matterId);
      this.matterParticipantService.unlockParticipantOnTabClose(this.condoCorporationWrapper, this.matter);
    }

    //If managementCompanyWrapper shutter is open before leaving matter then close it and release the lock
    if (this.managementCompanyWrapper && this.managementCompanyWrapper.matterParticipant && this.managementCompanyWrapper.expanded) {
      this.managementCompanyWrapper.obsolete = true;
      //console.log("Unlocking management for matter "+this.matter.id);
      this.toggleManagementShutter();
    }
  }

  get matter(): Matter {
    let activeMatter: Matter = this._matter ? this._matter : currentMatter.value;
    this.emailFieldService.matter = activeMatter;
    this.emailFieldService.key = EmailKeys.matterOpening;
    return activeMatter;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  get isMatterCondoDocumentsVisible(): boolean {
    return this.matter && (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceMB || this.matter.isMatterProvinceSK);
  }

  get condoCorporation(): Contact {
    return this.condoCorporationWrapper && this.condoCorporationWrapper.matterParticipant ? this.condoCorporationWrapper.matterParticipant.contact : null;
  }

  get managementCompany(): Contact {
    return this.managementCompanyWrapper && this.managementCompanyWrapper.matterParticipant ? this.managementCompanyWrapper.matterParticipant.contact : null;
  }

  get primaryContact(): Contact {
    return this.condoCorporation;
  }

  get isManagementCompanyVisible(): boolean {
    return this.condoCorporation && this.condoCorporation.selfManagedManagementCompanyType === 'MANAGEMENT_COMPANY';
  }

  get totalFee(): number {
    if (this.condoCorporation && (this.condoCorporation.feesSubjectToHst == 'YES' || this.condoCorporation.feesSubjectToHst == 'Y_n')) {
      const tax = this.condoCorporation.feeForCondoStatusCertificate * this.matter.matterHst / 100;
      // multiplying by 1 to force fee to be a number, as it comes in as a string when the input is type text
      //noinspection PointlessArithmeticExpressionJS
      return this.condoCorporation.feeForCondoStatusCertificate * 1 + tax;
    } else if (this.condoCorporation) {
      return this.condoCorporation.feeForCondoStatusCertificate;
    } else {
      return 0;
    }

  }

  get manageCompanyParticipant(): MatterParticipant {
    return this.managementCompanyWrapper && this.managementCompanyWrapper.matterParticipant;
  }

  get condoCorporationParticipant(): MatterParticipant {
    return this.condoCorporationWrapper && this.condoCorporationWrapper.matterParticipant;
  }

  public buildCondoCorporationWrapper(condoCorporationParticipant: MatterParticipant): void {
    this.condoCorporationWrapper = new MatterParticipantWrapper();
    this.condoCorporationWrapper.matterParticipant = condoCorporationParticipant;
    //If condo participant has source contact then make wrapper readonly else editable
    if (this.isCondoParticipantCreatedWithSourceContact(condoCorporationParticipant)) {
      this.condoCorporationWrapper.editMode = false;
      if (this.condoCorporation.isManagementCompany) {
        this.matterParticipantService.updateParticipantStateOnTabOpening(this.matter, this.condoCorporationWrapper);
      } else {
        this.matterParticipantService.updateParticipantStateOnTabOpening(this.matter, this.condoCorporationWrapper, this.buildAttentionOptions);
      }
    } else {
      this.condoCorporationWrapper.editMode = true;
    }
  }

  //A condoCorporationParticipant (with blank contact) is created as soon as when property is set to condo (without even selecting actual source
  // contact). Therefore checking that condoCorporationParticipant should have a source contact id before using it.
  //ToDo: condoCorporationParticipant should not be created before selecting the source contact.
  isCondoParticipantCreatedWithSourceContact(condoCorporationParticipant: MatterParticipant): boolean {
    return condoCorporationParticipant && condoCorporationParticipant.hasSourceContactId();
  }

  public setContactInCondoCorporationWrapper(sourceContact: Contact): void {
    this.condoCorporationWrapper = new MatterParticipantWrapper();
    this.condoCorporationWrapper.editMode = false;
    //Since a blank condocorporation participant is created when property is set to condo therefore before adding new participant removing any existing
    // participant
    if (this.matter.condoCorporation) {
      this.matter.removeMatterParticipant(this.matter.condoCorporation);
    }
    this.condoCorporationWrapper.matterParticipant = this.matter.addMatterParticipant(sourceContact, true, 'CONDO_CORPORATION');
    //Setting management contact id into snapshot's sourceParentOrganizationId
    this.condoCorporationWrapper.matterParticipant.contact.sourceParentOrganizationId = sourceContact.organizationId;
    if (this.condoCorporation && this.condoCorporation.sourceContactId) {
      // Check if this attention belong to CONDO_CORPORATION or MANAGEMENT_COMPANY
      if (this.condoCorporation.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
        this.matterParticipantService.updateParticipantStateOnTabOpening(this.matter, this.condoCorporationWrapper);
      } else {
        this.matterParticipantService.updateParticipantStateOnTabOpening(this.matter, this.condoCorporationWrapper, this.buildAttentionOptions);
      }
      if (sourceContact.organizationId) {
        this.contactQueryService.getContactForMatter(sourceContact.organizationId).subscribe(
          (managementCompany: Contact) => {
            this.setContactInManagementWrapper(managementCompany, false);
            //Setting management contact id into snapshot's sourceParentOrganization. In this case no need to make condo dirty as source contact
            // would be referring to the same management company
            this.condoCorporation.sourceParentOrganizationId = this.managementCompanyWrapper.matterParticipant.sourceContact.id;
          }
        );
      }
    }
  }

  public buildManagementCompanyWrapper(managementCompanyParticipant: MatterParticipant): void {
    this.managementCompanyWrapper = new MatterParticipantWrapper();
    this.managementCompanyWrapper.editMode = false;
    if (managementCompanyParticipant) {
      this.managementCompanyWrapper.matterParticipant = managementCompanyParticipant;

      //If contact is already locked then no need to reload source contact and sync again
      if (this.managementCompany && this.managementCompany.sourceContactId && !this.managementCompanyWrapper.matterParticipant.sourceContactLockAcquired) {
        this.managementCompanyWrapper.isLoadingParticipantState = true;
        this.contactQueryService
        .getContactForMatter(this.managementCompany.sourceContactId)
        .subscribe((sourceContact: Contact) => {
          this.matterParticipantService.updateParticipantWrapperState(this.managementCompanyWrapper, sourceContact);
          this.managementCompanyWrapper.isLoadingParticipantState = false;
          // Check this attention belong to CONDO_CORPORATION or MANAGEMENT_COMPANY
          if (this.condoCorporation.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
            this.buildAttentionOptions(sourceContact.subContacts);
          }
        });
      }
    } else {

      //Initialize blank management wrapper
      this.managementCompanyWrapper.editMode = true;
    }
  }

  //If condo corporation added with a management company then shutter will be closed else opened
  public setContactInManagementWrapper(sourceContact: Contact, openShutter: boolean): void {
    this.managementCompanyWrapper = new MatterParticipantWrapper();
    this.managementCompanyWrapper.editMode = false;
    this.managementCompanyWrapper.matterParticipant = this.matter.addMatterParticipant(sourceContact, true, 'MANAGEMENT_COMPANY');
    // Check this attention belong to CONDO_CORPORATION or MANAGEMENT_COMPANY
    if (this.condoCorporation.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
      this.buildAttentionOptions(sourceContact.subContacts);
    }

    if (openShutter) {
      //if management participant added separately then open shutter and lock participant
      this.toggleManagementShutter();
    } else {
      //if management participant added as part of condo then just update wrapper state but don't open shutter or lock contact
      this.matterParticipantService.updateParticipantWrapperState(this.managementCompanyWrapper, sourceContact);
    }

  }

  public buildCondoCorporationContact(): Contact {
    const condoCorporationContact = new Contact();
    condoCorporationContact.setDefaultValues('CONDO_CORPORATION', 'CORPORATION');
    return condoCorporationContact;
  }

  public buildManagementCompanyContact(): Contact {
    const managementCompanyContact = new Contact();
    managementCompanyContact.setDefaultValues('MANAGEMENT_COMPANY', 'CORPORATION');
    return managementCompanyContact;
  }

  public getContactName(contact: Contact): string {
    if (contact) {
      if (contact.organizationName === 'No records available') {
        return contact.organizationName;
      } else if (contact.organizationName && contact.organizationName.indexOf(Constants.ADD_NEW_RECORD) > -1) {
        return contact.organizationName;
      } else {
        return (contact.isCorporation || contact.isGenderDepartment()) ? contact.organizationName : contact.fullNameStartWithFirstName;
      }

    }
    return '';
  }

  public getContactMailingAddress(contact: Contact): string {
    return contact.mailingAddress && contact.mailingAddress.addressTextWithoutCountryAndPostalCode;
  }

  public onContactChanged(contact: Contact): void {
    if (contact) {
      contact.isDirty = true;
      this.onCondoCorporationTabChanged();
    }
    if (this.matter) {
      this.matter.addCondoFeeOnSOA();
    }
  }

  public onAttentionContactChanged(contact: Contact): void {
    this.onCondoCorporationTabChanged();
  }

  public onSelfManagedChanged(contact: Contact, event): void {
    contact.isDirty = true;
    this.onCondoCorporationTabChanged();
    if (this.needDeleteOldAttention(contact, event)) {
      if (this.attentionInfoComponent) {
        this.attentionInfoComponent.removeAttention();
      }
      this.attentionOptions = [];
      this.updateAttention(event);
    }
  }

  public needDeleteOldAttention(contact: Contact, event): boolean {
    if ((event == CondoManagedTypeConstValue.QUESTION || event == CondoManagedTypeConstValue.SELF_MANAGED)
      && (contact.isManagedByManagementCompany())) {
      return true;
    }

    if ((event == CondoManagedTypeConstValue.MANAGEMENT_COMPANY)
      && (contact.isSelfManaged())) {
      return true;
    }
    return false;
  }

  public updateAttention(event) {
    if (event == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
      this.updateAttentionWithSourceContact(this.managementCompany && this.managementCompany.sourceContactId);

    } else {
      this.updateAttentionWithSourceContact(this.condoCorporation && this.condoCorporation.sourceContactId);
    }
  }

  public updateAttentionWithSourceContact(sourceContactId: number) {
    if (sourceContactId) {
      this.contactQueryService
      .getContactForMatter(sourceContactId)
      .subscribe((sourceContact: Contact) => {
        this.buildAttentionOptions(sourceContact.subContacts);
      });
    }
  }

  public onCondoCorporationTabChanged(): void {
    this.matter.dirty = true;
  }

  public searchCondoCorporations(event: { query: string }): void {
    if (event.query && event.query.trim()) {
      this.condoCorporationsLoading = true;
      this.contactService
      .getActiveContactListPerPage(event.query.trim(), 'CONDO_CORPORATION', false, 1, 15, 'ACTIVE', false, [ this.matter.provinceCode ], null, this.includeGlobalCondoCorporation)
      .subscribe(
        (condoCorporations: Contact[]) => {
          this.condoCorporationOptions = [];
          if (condoCorporations && Array.isArray(condoCorporations) && condoCorporations.length > 0) {
            // Add New item should be always in the first. So it should not join sorting list
            const index: number
              = condoCorporations.findIndex(item => item.organizationName && item.organizationName.indexOf(Constants.ADD_NEW_RECORD) > -1);
            if (index > -1) {
              const addNewContact: Contact = condoCorporations[ index ];
              condoCorporations.splice(index, 1);
              this.condoCorporationOptions = condoCorporations.sort(this.sortContactsByName);
              this.condoCorporationOptions.unshift(addNewContact);
            } else {
              this.condoCorporationOptions = condoCorporations && condoCorporations.sort(this.sortContactsByName);
            }
          }

          this.condoCorporationsLoading = false;
        },
        (error: any) => {
          this.condoCorporationsLoading = false;
          this.logger.error('Condo Corporations search error', error);
        }
      );
    }
  }

  get includeGlobalCondoCorporation(): boolean {
    return this.matter.provinceCode === 'BC';
  }

  public searchManagementCompanies(event: { query: string }): void {
    if (event.query && event.query.trim()) {
      this.managementCompaniesLoading = true;
      this.contactService
      .getActiveContactListPerPage(event.query.trim(), 'MANAGEMENT_COMPANY', true, 1, 15)
      .subscribe(
        (managementCompanies: Contact[]) => {
          this.managementCompanyOptions = managementCompanies && managementCompanies.sort(this.sortContactsByName);
          this.managementCompaniesLoading = false;
        },
        error => {
          this.managementCompaniesLoading = false;
          this.logger.error('Management Companies search error', error);
        }
      );
    }
  }

  public addNewCondoCorporation(condoWrapper: MatterParticipantWrapper) {
    let contactType = 'CONDO_CORPORATION';
    if (this.matter.isMatterProvinceBC) {
      contactType = 'STRATA_CORPORATION';
    }

    this.dialogService.matDialogContent({
      
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        contactType: contactType,
        canAddNewManagementCompanyFromSearch: true,
        contactName: condoWrapper && condoWrapper.dataModel && condoWrapper.dataModel.typedName,
        removeStatusCertificatePage: provincesWithoutStatusCertificate.indexOf(this.matter.provinceCode) !== -1
      },
      onFulfillment: (result) => {
        if (result.contact) {
          this.setContactInCondoCorporationWrapper(result.contact);
          this.matter.setCondoCorporationDocumentation();
        } else {
          this.condoCorporationWrapper.dataModel = '';
        }
      },
      onRejection: (reject: any) => {

      },
      fullScreen: true,
      
    });
  }

  public onCondoCorporationInputChange(): void {
    if (this.condoCorporationWrapper.dataModel && this.condoCorporationWrapper.dataModel.organizationName === 'No records available') {
      this.condoCorporationWrapper.dataModel = null;
    } else if (this.condoCorporationWrapper.dataModel && this.condoCorporationWrapper.dataModel.organizationName
      && this.condoCorporationWrapper.dataModel.organizationName.indexOf(Constants.ADD_NEW_RECORD) > -1) {
      this.addNewCondoCorporation(this.condoCorporationWrapper);
    } else if (this.condoCorporationWrapper.dataModel instanceof Contact) {
      this.contactQueryService.getContactForMatter(this.condoCorporationWrapper.dataModel.id).subscribe((sourceContact: Contact) => {
        if (sourceContact) {
          let dataModelContact: Contact = new Contact(sourceContact);
          this.condoCorporationWrapper.dataModel = null;
          this.setContactInCondoCorporationWrapper(dataModelContact);
          this.matter.setCondoCorporationDocumentation();
        }
      });
    } else {
      this.onCondoCorporationTabChanged();
      // It will break UI. So comment it. We don't need to dataModel to organizationName
      // this.condoCorporationWrapper.matterParticipant.contact.organizationName = this.condoCorporationWrapper.dataModel;
    }
  }

  public onManagementCompanyInputChange(): void {
    if (this.managementCompanyWrapper.dataModel && this.managementCompanyWrapper.dataModel.organizationName === 'No records available') {
      this.managementCompanyWrapper.dataModel = null;
    } else if (this.managementCompanyWrapper.dataModel instanceof Contact) {
      this.onCondoCorporationTabChanged();
      // Because the subcontact list was not sent to UI from the searched contact list,
      // we have to retired this ManagementCompany contact to get subcontact list
      this.contactQueryService.getContactForMatter(this.managementCompanyWrapper.dataModel.id).subscribe(
        (managementCompany: Contact) => {
          this.setContactInManagementWrapper(managementCompany, true);
          //If management company changed then setting the reference of new management company's id into condo snapshot
          this.condoCorporation.sourceParentOrganizationId = this.managementCompanyWrapper.matterParticipant.sourceContact.id;
        });
      //In this case making condo snapshot dirty as well because we want to propagate the new reference to the original condo corporation
      this.condoCorporation.isDirty = true;
    } else {
      this.onCondoCorporationTabChanged();
      if (this.managementCompanyWrapper.matterParticipant) {
        this.managementCompanyWrapper.matterParticipant.contact.organizationName = this.managementCompanyWrapper.dataModel;
      }
    }
  }

  public sortContactsByName = (left: Contact, right: Contact) => {
    return this.getContactName(left).localeCompare(this.getContactName(right), 'en', {sensitivity: 'base'});
  };

  public handleF9OnFeeForCondoStatusCertificate(contact: Contact) {
    this.condoCorporation.feeForCondoStatusCertificate = this.f9DefaultCondoFee;
    this.onContactChanged(contact);
  }

  buildAttentionOptions = (contacts?: Contact[]) => {

    let contactsFiltered: Contact[] = [];
    // As Server side requirement, remove condo corporation contact and DIRECTOR_OFFICER contact from attention list
    // Server side needs to have condo corporation contact in management company subcontacts list
    if (Array.isArray(contacts)) {
      contactsFiltered = contacts.filter(contact => ((contact.contactType != 'CONDO_CORPORATION') && (contact.contactType != 'DIRECTOR_OFFICER')));
    }
    this.attentionOptions = [];
    this.attentionOptions = contactsFiltered ? Array.from(contactsFiltered.sort(this.sortContactsByName)) : [];
    const addNewContact = new Contact();
    addNewContact.firstName = Constants.ADD_NEW_RECORD_ATTENTION;
    if (this.matter.provinceCode != 'BC') {
      const statusCertificateRequestContact = new Contact();
      statusCertificateRequestContact.firstName = 'Status Certificate Request';
      this.attentionOptions.unshift(statusCertificateRequestContact);
    }
    this.attentionOptions.unshift(addNewContact);
  };

  deleteCondoCorporation(): void {
    let corpCapitalizedName = provinceBasedFieldLabels.get('matter.corp.property.cap.name', this.matter.provinceCode);

    this.dialogService.confirm('Confirmation', 'Proceed to remove this ' + corpCapitalizedName + ' Corporation from this matter?', false, 'Delete').subscribe(
      (response) => {
        if (response) {
          this.removeCondoCorporation(true, true);
        }
      });
  }

  removeCondoCorporation(unlockSourceContact: boolean, removeDocumentation: boolean): void {
    if (this.condoCorporationWrapper && this.condoCorporationWrapper.matterParticipant && this.documentProductionService && this.contactQueryService) {
      this.documentProductionService.tryToRevokePackage(this.matter, this.condoCorporationWrapper.matterParticipant, this.contactQueryService);
      this.matterParticipantService.removeParticipant(this.condoCorporationWrapper, this.matter, unlockSourceContact);
    }

    //If management participant exists then remove that too on condo deletion
    if (this.managementCompanyWrapper && this.managementCompanyWrapper.matterParticipant) {
      this.matterParticipantService.removeParticipant(this.managementCompanyWrapper, this.matter);
    }
    this.onCondoCorporationTabChanged();
    if (this.matter.condoCorporationDocumentation && removeDocumentation) {
      this.matter.condoCorporationDocumentation = null;
      // this.condoDocumentsList = [];
      this.selectedCondoDocumentsList = [];
    }
    this.updateMatterDataOnCondoCorpDeletion();
  }

  updateMatterDataOnCondoCorpDeletion() {
    this.attentionInfoComponent.removeAttention();
    this.matter.updateCondoOnSOA();
  }

  deleteManagementCompany(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this Management Company from this matter?', false, 'Delete').subscribe(
      (response) => {
        if (response) {
          this.matterParticipantService.removeParticipant(this.managementCompanyWrapper, this.matter);
          this.condoCorporationWrapper.matterParticipant.contact.sourceParentOrganizationId = null;
          this.condoCorporation.isDirty = true;
          if (this.condoCorporation.selfManagedManagementCompanyType && this.attentionInfoComponent) {
            this.attentionInfoComponent.removeAttention();
          }
          this.onCondoCorporationTabChanged();
        }
      });
  }

  enableSave(): void {
    this.matter.dirty = true;
  }

  dynamicHelpForCondoFee = () => {

    return 'F9 = ' + this.getFormattedCurrencyValue(this.f9DefaultCondoFee) + '. If this field is $0.00, the fee in documents produced will be deemed to' +
      ' be ' + this.getFormattedCurrencyValue(this.f9DefaultCondoFee);
  };

  getFormattedCurrencyValue(currencyValue: number): string {

    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      let curVal: string = this.currencyPipe.transform(currencyValue, 'CAD', 'symbol', '1.2-2').replace('CA', '');
      if (curVal === '-$0.00') {
        curVal = '$0.00';
      }
      return curVal;
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol', '1.2-2').replace('CA', '');
    }

  }

  get condoCorporationDetailSectionTitle(): string {
    return this.condoCorporation && this.condoCorporation.isManagedByManagementCompany() ? 'Management Company Details' : 'Condo Corporation Details';
  }

  toggleManagementShutter(): void {
    this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, this.managementCompanyWrapper);
  }

  clickCondoCorporationBurgerMenuItem(event, condoWrapper: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE:
        this.deleteCondoCorporation();
        break;
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
        //If replacing condo corp then also removing any existing managementCompany snapshot
        if (this.matter.managementCompany) {
          this.matter.removeMatterParticipant(this.matter.managementCompany);
        }
        this.matterParticipantService.updateMatterContactFromSourceContact(condoWrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.condoWrapperCallbackOnReplaceSourceContact, this.matter);
        break;
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
        this.matterParticipantService.revertToGlobal(condoWrapper, this.condoWrapperCallbackOnRevertToGlobal);
        break;

    }
  }

  condoWrapperCallbackOnRevertToGlobal = (result, matterParticipantWrapper) => {
    //this sets the reverted contact into the matterParticipantWrapper
    this.setContactInCondoCorporationWrapper(matterParticipantWrapper._matterParticipant.sourceContact);
    this.matter.setCondoCorporationDocumentation();

    //If result true that means contact updated successfully
    if (result) {
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  condoWrapperCallbackOnClearUpdateFlag = (result, matterParticipantWrapper) => {
    //If result true that means contact updated successfully
    if (result) {
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  condoWrapperCallbackOnReplaceSourceContact = (result, matterParticipantWrapper, oldSnapshotReference: Contact) => {
    //If result true that means contact updated successfully
    if (result) {
      //When condo corporation is replaced with latest data then re-creating management participant snapshot.
      if (matterParticipantWrapper.matterParticipant.contact.sourceParentOrganizationId) {
        this.contactQueryService.getContactForMatter(matterParticipantWrapper.matterParticipant.contact.sourceParentOrganizationId).subscribe(
          (managementCompany: Contact) => this.setContactInManagementWrapper(managementCompany, false)
        );
      }
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  clickManagementBurgerMenuItem(event, managementWrapper: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE:
        this.deleteManagementCompany();
        break;
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
        this.matterParticipantService.updateMatterContactFromSourceContact(managementWrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.managementWrapperCallbackOnUpdateSourceContact, this.matter);
        break;
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
        this.matterParticipantService.revertToGlobal(managementWrapper, this.managementWrapperCallbackOnUpdateSourceContact);
        break;

    }
  }

  //This function called from common service after updating participant's snapshot from source contact
  managementWrapperCallbackOnUpdateSourceContact = (result, matterParticipantWrapper) => {
    //If result true that means contact updated successfully
    if (result) {
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  editManagementAsPrivateCopy(managementWrapper: MatterParticipantWrapper) {
    this.enableSave();
    this.matterParticipantService.editContactAsPrivateCopy(managementWrapper);
  }

  openSourceContactTab(wrapper: MatterParticipantWrapper): void {
    if (wrapper && wrapper.matterParticipant && wrapper.matterParticipant.contact) {
      this.contactQueryService.getContactForOpeningTab(wrapper.matterParticipant.contact.sourceContactId).subscribe((res: any) => {
        let source: Contact = res.contact;
        const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/' + source.contactType, source.contactType);
        this.tabsService.openTab(contactTab);
      });
    }
  }

  showContactInformationSection(): boolean {
    return this.condoCorporation
      && (this.condoCorporation.isSelfManaged()
        || (this.condoCorporation.isManagedByManagementCompany() && !!this.manageCompanyParticipant));
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.organizationName && contact.organizationName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  cleanupCondoCorpField(wrapper: MatterParticipantWrapper): void {
    if (wrapper && typeof wrapper.dataModel === 'string') {
      wrapper.dataModel = undefined;
      this.condoCorporationsLoading = false;
    }
  }

  loadTaxRateType(): void {
    if (this.matter && this.matter.matterTaxType) {
      this.taxRateType = this.matter.matterTaxType;
    } else {
      this.taxRateService.loadMatterTaxRates(this.matter).subscribe((provinceHstRateSlab) => {
        this.taxRateType = provinceHstRateSlab.rateType;
      });
    }

  }

  updateSelectedCondoDocumentsList(obj: any[]): void {
    this.selectedCondoDocumentsList = obj;
    this.matter.condoCorporationDocumentation.condoDocuments = this.selectedCondoDocumentsList;
    this.onCondoCorporationTabChanged();
  }

  get condoCorporationDocumentInput(): string {
    if (this.matter && this.matter.condoCorporationDocumentation) {
      let tax = this.matter.condoCorporationDocumentation.subjectToGst ? ' + GST' : '';
      let documentOrderdCost = this.currencyPipe.transform(this.matter.condoCorporationDocumentation.costOfDocumentsOrdered, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
      let selectedCondoDocuments: string = '';
      if (Array.isArray(this.selectedCondoDocumentsList)) {
        this.selectedCondoDocumentsList.forEach((selectedItem) => {
          this.condoDocumentsList.forEach((item) => {
            if (item.value == selectedItem) {
              selectedCondoDocuments += `${ item.label }; `;
            }
          });
        });
      }
      return `${ selectedCondoDocuments }Total Cost: ${ documentOrderdCost } ${ tax }`;
    }
    return null;

  }

  get isSubjectToGst(): boolean {
    return this.matter && this.matter.condoCorporationDocumentation && this.matter.condoCorporationDocumentation.subjectToGst == DpBooleanValueTypes.YES;
  }

  calcCondoCorporationDocumentTotal(): void {
    this.matter.updateCondoOnSOA();
    this.onCondoCorporationTabChanged();
  }

  get documentOrderdCostTaxRate(): number {
    return this.matter && this.matter.condoCorporationDocumentation && this.matter.condoCorporationDocumentation.costOfDocumentsOrdered ?
      this.matter.condoCorporationDocumentation.costOfDocumentsOrdered * this.matter.condoCorporationDocumentation.taxRate / 100 : 0;
  }

  get documentOrderdTotalCost(): number {
    return this.matter && this.matter.condoCorporationDocumentation && this.matter.condoCorporationDocumentation.costOfDocumentsOrdered ?
      this.matter.condoCorporationDocumentation.costOfDocumentsOrdered * (1 + this.matter.condoCorporationDocumentation.taxRate / 100) : 0;
  }

  get isCorporationPageEnabled(): boolean {
    return this.matter
      && (this.matter.isMatterProvinceBC
        || this.matter.isCondoCorporation
        || (!this.matter.isCondoCorporation
          && this.matter.isPropertyParcelOfTiedLand()));
  }

  ngAfterViewInit() {
  }

  editCondoCorporationAsPrivateCopy(condoCorporationWrapper: MatterParticipantWrapper) {
    this.enableSave();
    this.matterParticipantService.editContactAsPrivateCopy(condoCorporationWrapper);
  }
}
