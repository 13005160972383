import {dropDowns} from '../../../shared';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {Paragraph} from '../../../executions-affidavits/paragraph';
import {SelectItem} from 'primeng/api';
import {customPickListKey} from '../../../../shared/modal/custom-pick-list-key';
import {AutoCompleteCustomPickListComponent} from '../../../../shared/modal/autocomplete-custom-pick-list.component';
import {CustomPickListService} from '../../../../shared/modal/custom-pick-list.service';
import {ParagraphTemplateService} from '../../../../shared-main/paragraph-template.service';
import {SpecialComment} from '../../../../shared/modal/special-comment';
import {CustomPickListModalComponent} from '../../../../shared/modal/custom-pick-list-modal.component';
import {messages} from '../../../../common';
import {Writ} from '../../../../shared-main/teranet/property-writ';
import {WritExecution} from '../../../../shared-main/teranet/property-writ-execution';
import Utils from '../../../../shared-main/utils';
import {DecimalPipe} from '@angular/common';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export class WritSearchAddParagraphQuestionnaireModalContext {
  isClientSelected: boolean;
  isSolicitorSelected: boolean;
  writ: Writ;
  writExecution: WritExecution;
}

@Component({
  selector: 'dp-writ-search-add-paragraph-questionnaire-modal',
  styleUrls: ['./writ-search-add-paragraph-questionnaire.modal.component.scss'],
  templateUrl: 'writ-search-add-paragraph-questionnaire.modal.component.html',
  providers: [DialogService]
})
@FocusFirstElementDecorator()
export class WritSearchAddParagraphQuestionnaireModal
  extends ModalComponent<WritSearchAddParagraphQuestionnaireModalContext>
  implements OnInit
{
  paragraph: Paragraph;
  pickListType: string = customPickListKey.PlaintiffList;
  @ViewChild('pickPlaintiff') pickPlaintiff: AutoCompleteCustomPickListComponent;

  constructor(
    public dialog: MatDialogRef<WritSearchAddParagraphQuestionnaireModal>,
    public dialogService: DialogService,
    public customPickListService: CustomPickListService,
    public paragraphTemplateService: ParagraphTemplateService,
    public decimalPipe: DecimalPipe,
    @Inject(MAT_DIALOG_DATA) context?: WritSearchAddParagraphQuestionnaireModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.paragraph = new Paragraph();
    this.paragraph.debtorName = Utils.convertToTitleCase(this.writ.partyNameForDeclaration);
    this.paragraph.moreThanOneDebtor = 'NO';
    this.paragraph.plaintiffName = '';

    //pre-populate fields if this paragraph based on writ execution
    if (this.writExecution) {
      this.paragraph.executionNumber = this.writExecution.executionNumber;
      this.paragraph.plaintiffName = this.writExecution.plaintiffName;
      this.paragraph.judgmentAmount = this.writExecution.judgementsTotal
        ? this.convertToCurrencyFormat(this.writExecution.judgementsTotal)
        : '';
      this.paragraph.costAmount = this.convertToCurrencyFormat(this.writExecution.costsTotal);
      this.paragraph.costAmount =
        this.writExecution.costsTotal && this.writExecution.costsTotal > 0
          ? this.convertToCurrencyFormat(this.writExecution.costsTotal)
          : 'nil';
    }
  }

  get yesNoOptions(): SelectItem[] {
    return dropDowns.yesNoOnly;
  }

  get writ(): Writ {
    return this.context.writ;
  }

  get writExecution(): WritExecution {
    return this.context.writExecution;
  }

  convertToCurrencyFormat(amount: number): string {
    return this.decimalPipe.transform(amount, '1.2-2');
  }

  public openPickListModal(pl: AutoCompleteCustomPickListComponent): void {
    this.dialogService.matDialogContent({
      content: CustomPickListModalComponent,
      context: {pickListType: this.pickListType},
      onFulfillment: (result: any) => {
        if (result) {
          if (result.action === 'select') {
            this.pickPlaintiff.setValue(result.comment);
          }
        }
        jQuery('body').removeClass('modal-open');
        this.dialogService.setFocusOnAutocomplete('plaintiffName');
      }
    });
  }

  setPickListItem(comment: SpecialComment): void {
    setTimeout(() => {
      this.paragraph.plaintiffName = comment.customPickListItemValue;
    }, 100);
  }

  onOK(): void {
    this.dialog.close({paragraph: this.paragraph});
  }

  close(): void {
    this.dialogService.confirm('Confirmation', messages.declaration.cancelConfirmation, false).subscribe((res) => {
      if (res && res == true) {
        this.dialog.close({action: 'Cancel'});
      }
    });
  }

  get isClientSelected(): boolean {
    return this.context.isClientSelected;
  }

  get isSolicitorSelected(): boolean {
    return this.context.isSolicitorSelected;
  }

  costAmountF9() {
    this.paragraph.costAmount = 'nil';
  }

  ngAfterViewInit() {}
}
