import {BankAccount} from './BankAccount';
import {DpBooleanValue} from './dp-boolean';

export class DirectDepositInstruction {
  constructor(directDepositInstruction?: DirectDepositInstruction) {
    if (directDepositInstruction) {
      for (let prop in directDepositInstruction) {
        if (directDepositInstruction.hasOwnProperty(prop)) {
          this[ prop ] = directDepositInstruction[ prop ];
        }
      }

      if (this.bankAccount) {
        this.bankAccount = new BankAccount(directDepositInstruction.bankAccount);
      }
    } else {
      // this.bankAccount = new BankAccount();
    }

    if (!this.directDepositChoice) {
      this.directDepositChoice = 'N_y';
    }

  }

  directDepositType: string;
  directDepositDate: string;
  directDepositChoice: DpBooleanValue;
  bankAccount: BankAccount;

  cleanUpBankAccount(): void {
    if (this.directDepositChoice === 'NO' || this.directDepositChoice === 'N_y') {
      this.bankAccount = null;
      this.directDepositDate = null;
    }
  }

}
