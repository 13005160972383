import {Component, Inject, ViewChild} from '@angular/core';
import moment from 'moment';
import {CurrencyPipe} from '@angular/common';
import {DdlTypeForYesNo} from '../../shared/mortgage';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {Matter, MatterType, MatterTypesValue, currentMatter, dropDowns} from '../../shared';
import Utils from '../../../shared-main/utils';
import {MatterUtility} from '../../shared/matter-utility';
import {SelectItem} from 'primeng/api';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class PropertyUtilitiesModalContext {
  propertyUtilitiesFee: MatterUtility;
  matterCloseDate: string;
  currentMatter: Matter;
  context: DdlTypeForYesNo[];
  matterType: MatterType;
}

@Component({
  selector: 'property-utilities-modal-content',
  templateUrl: 'property-utilities.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class PropertyUtilitiesModal extends ModalComponent<PropertyUtilitiesModalContext> {
  yesNoItems: DdlTypeForYesNo[];
  BaseTaxAdjustmentListItems: any[];
  completionDateLabel: string = 'Completion Date';
  adjustmentDateLabel: string = 'Adjustment Date';
  applicableMatterTypes: MatterType[] = ['PURCHASE', 'MORTGAGE', 'SALE'];
  MatterTypesValue = MatterTypesValue;

  pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  matterUtility: MatterUtility;
  propertyUtilityFee: MatterUtility;
  doesUtilitiesHavePrePaymentList: SelectItem[];
  utilitiesPaidInFullList: SelectItem[];
  BaseTaxAdjustmentList: SelectItem[];
  previousYearSelected: boolean = false;
  prePaymentStatus: boolean = false;
  fullPaymentStatus: boolean = false;
  BaseTaxAdjustmentSelectedValue: any;
  utilitiesPaidInFullStatus: string;
  dateStatusLabel: string;
  saleMatterCloseDate: string;
  mortgageMatterCloseDate: string;
  matterClosedOrCompletedDate: string;

  constructor(
    public dialog: MatDialogRef<PropertyUtilitiesModal>,
    public dialogService: DialogService,
    public errorService: ErrorService,
    public currencyPipe: CurrencyPipe,
    @Inject(MAT_DIALOG_DATA) context?: PropertyUtilitiesModalContext
  ) {
    super(dialog, context);
    this.saleMatterCloseDate = this.matter.adjustAsAtClosingDate;
    this.mortgageMatterCloseDate = this.matter.matterCloseDate;
    this.adjustmentDateLabel = Utils.formatDateString(this.saleMatterCloseDate, true, '');
    this.completionDateLabel = Utils.formatDateString(this.mortgageMatterCloseDate, true, '');

    if (this.context.matterType !== 'MORTGAGE') {
      this.dateStatusLabel = this.adjustmentDateLabel || 'Adjustment Date';
      if (this.adjustmentDateLabel) {
        this.matterClosedOrCompletedDate = this.saleMatterCloseDate;
      }
    } else {
      this.dateStatusLabel = this.completionDateLabel || 'Completion Date';
      if (this.completionDateLabel) {
        this.matterClosedOrCompletedDate = this.mortgageMatterCloseDate;
      }
    }
  }

  ngOnInit(): void {
    this.yesNoItems = [
      {
        label: 'Yes',
        value: 'YES'
      },
      {
        label: 'No',
        value: 'NO'
      }
    ];
    this.BaseTaxAdjustmentListItems = [
      {
        label: 'Current Bill',
        value: 'Current Bill'
      },
      {
        label: 'Previous Bill',
        value: 'Previous Bill'
      }
    ];
    if (!this.matter.matterUtility && !this.matter.dirty) {
      this.propertyUtilityFee = new MatterUtility();
    } else {
      this.propertyUtilityFee = new MatterUtility(this.matter.matterUtility);
    }
    this.doesUtilitiesHavePrePaymentList = dropDowns.yesNo;
    this.utilitiesPaidInFullList = dropDowns.yesNo;
    this.BaseTaxAdjustmentList = this.BaseTaxAdjustmentListItems;
    this.BaseTaxAdjustmentSelectedValue =
      this.propertyUtilityFee.estimatedPercentageIncrease > 0 ? 'Previous Bill' : 'Current Bill';
    this.previousYearSelected = this.BaseTaxAdjustmentSelectedValue == 'Previous Bill' ? true : false;
    this.utilitiesPaidInFullStatus =
      this.propertyUtilityFee.utilitiesPaidInFull && this.propertyUtilityFee.utilitiesPaidInFull == 'Yes'
        ? 'Yes'
        : 'No';
    this.prePaymentStatus = this.propertyUtilityFee.prePaymentAmount > 0;
    this.fullPaymentStatus = !this.prePaymentStatus;
  }

  close(): void {
    this.dialog.close({
      propertyUtilitiesFee: this.matter.matterUtility,
      isUpdated: false
    });
  }
  get matter(): Matter {
    return currentMatter.value;
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpFieldError();
    this.checkForErrors();
    this.matter.dirty = true;
    this.matter.matterUtility.matterId = this.matter.id;
    this.matter.matterUtility.accountNumber = this.context.propertyUtilitiesFee.accountNumber;
    this.matter.matterUtility.utilityAmount = this.context.propertyUtilitiesFee.utilityAmount;
    this.matter.matterUtility.currentBillingEndDate = this.context.propertyUtilitiesFee.currentBillingEndDate;
    this.matter.matterUtility.currentBillingStartDate = this.context.propertyUtilitiesFee.currentBillingStartDate;
    this.matter.matterUtility.id = this.context.propertyUtilitiesFee.id;
    this.matter.matterUtility.estimatedPercentageIncrease =
      this.context.propertyUtilitiesFee.estimatedPercentageIncrease;
    this.matter.matterUtility.prePaymentAmount = this.context.propertyUtilitiesFee.prePaymentAmount;
    this.matter.matterUtility.utilitiesPaidInFull = this.context.propertyUtilitiesFee.utilitiesPaidInFull;
    this.matter.matterUtility.baseUtilityAdjustment = this.context.propertyUtilitiesFee.baseUtilityAdjustment;
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close({
        propertyUtilitiesFee: this.context.propertyUtilitiesFee,
        isUpdated: true
      });
    }
  }

  updateSelectedYearValue(event) {
    if (event.target.value == 'Previous Bill') {
      this.previousYearSelected = true;
    } else {
      this.previousYearSelected = false;
    }
  }

  updatePrepaymentValueStatus(event) {
    if (event.target.value == 'YES') {
      this.prePaymentStatus = true;
    } else {
      this.prePaymentStatus = false;
    }
  }

  updateFullPaymentValueStatus(event) {
    this.utilitiesPaidInFullStatus = event.target.value;
    if (event.target.value == 'YES') {
      this.fullPaymentStatus = true;
    } else {
      this.fullPaymentStatus = false;
    }
  }

  getEmptyCloseDateText() {
    return this.context.matterType == 'MORTGAGE' ? 'Completion Date' : 'Adjustment Date';
  }
  get adjustmentBasedOnFieldLabel(): string {
    return 'Adjustment Based on Adjusted Date ';
  }

  onDateChange(data, isStartDate = true) {
    if (isStartDate) {
      if (data?.rawDate != this.context.propertyUtilitiesFee.currentBillingStartDate) {
        this.context.propertyUtilitiesFee.currentBillingStartDate = data?.rawDate;
      }
    } else if (data?.rawDate != this.context.propertyUtilitiesFee.currentBillingEndDate) {
      this.context.propertyUtilitiesFee.currentBillingEndDate = data?.rawDate;
    }
  }

  getNumberOfDaysElapsed(fromDate: string, closingDate: string): number {
    if (this.isNotValidDate(fromDate) || this.isNotValidDate(closingDate)) {
      return 0;
    }
    let nde: number = this.getDateDiff(fromDate, closingDate);
    return nde;
  }

  getDateDiff(from: string, to: string): number {
    return Utils.getDateDiff(from, to);
  }

  isNotValidDate(dt: string): boolean {
    return Utils.isNotValidDate(dt);
  }

  checkForErrors() {
    if (this.context.propertyUtilitiesFee.utilityAmount <= 0) {
      this.modalErrorComponent.createDPFieldError('matter.propertyUtilitiesFee.utility-amount');
    }
    if (this.isNotValidDate(this.context.propertyUtilitiesFee.currentBillingStartDate)) {
      this.modalErrorComponent.createDPFieldError('matter.propertyUtilitiesFee.currentBillingStartDate');
    }
    if (this.isNotValidDate(this.context.propertyUtilitiesFee.currentBillingEndDate)) {
      this.modalErrorComponent.createDPFieldError('matter.propertyUtilitiesFee.currentBillingEndDate');
    }
    if (this.mortgageMatterCloseDate != '' || this.saleMatterCloseDate) {
      if (
        this.getDateDiff(this.matterClosedOrCompletedDate, this.context.propertyUtilitiesFee.currentBillingEndDate) < 0
      ) {
        this.modalErrorComponent.createDPFieldError(
          this.context?.matterType == 'MORTGAGE'
            ? 'matter.propertyUtilitiesFee.mortgage.endDate.greater.end'
            : 'matter.propertyUtilitiesFee.currentBillingEndDate.less.adj'
        );
      }
      if (
        this.getDateDiff(this.matterClosedOrCompletedDate, this.context.propertyUtilitiesFee.currentBillingStartDate) >
        0
      ) {
        this.modalErrorComponent.createDPFieldError(
          this.context?.matterType == 'MORTGAGE'
            ? 'matter.propertyUtilitiesFee.mortgage.commencementDate.greater.end'
            : 'matter.propertyUtilitiesFee.commencementDate.greater.end'
        );
      }
    }
    if (
      this.getDateDiff(
        this.context.propertyUtilitiesFee.currentBillingStartDate,
        this.context.propertyUtilitiesFee.currentBillingEndDate
      ) < 0
    ) {
      this.modalErrorComponent.createDPFieldError('matter.propertyUtilitiesFee.commencementDate.greater.expiry');
    }
  }
}
