import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {MatterTax} from '../../shared/property-taxes';
import {BaseTaxAdjustmentOn} from '../../../projects/project-adjustments/project-tax-adjustment-config';
import {StatementAdjustmentKey} from '../statement-adjustment-constants';
import Utils from '../../../shared-main/utils';
import {PropertyTaxesUtil} from '../../property-teranet/property-taxes/property-taxes-util';
import {Matter} from '../../shared/matter';
import {InterimTaxMultiplier} from '../../../shared-main/constants';

export class StatementAdjustmentDisplayPropertyTaxBuilder {

  static getDisplayItems(soAdj: MatterTax, mainBuilder: StatementAdjustmentDisplayBuilder, statementAdjustmentKey?: string): StatementOfAdjustmentDisplayItemLine[] {
    //debugger;
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let isBeginningOfOcc: boolean = statementAdjustmentKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY;
    let matter: Matter = mainBuilder.matter;

    if (soAdj.baseTaxAdjustmentTaxBillType == 'NOT_ASSESSED') {
      sadItemDetails.push(mainBuilder.getNewItemLine('Not Assessed', ''));
      sadItemDetails.push(mainBuilder.getNewItemLine('No Adjustment', ''));
    } else {
      soAdj.updatePurchaserPortionValue(mainBuilder.matter);
      let adjYear: string = '';
      if (soAdj.baseTaxAdjustmentTaxBillType === 'PREVIOUS') {
        adjYear = mainBuilder.getAdjustmentDateYear(false);
      } else {
        adjYear = mainBuilder.getAdjustmentDateYear(true);
      }

      sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.getPropertyTaxLabel(matter), mainBuilder.getCurrency(soAdj.totalTaxes)));

      if (soAdj.isPaidTaxesUnderTIPP) {
        this.createMonthlyBreakdownForTIPP(sadItemDetails, soAdj, adjYear, mainBuilder);
      }

      if ((soAdj.baseTaxAdjustmentTaxBillType == 'INTERIM' && !this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey)) || soAdj.baseTaxAdjustmentTaxBillType == 'PREVIOUS') {
        sadItemDetails.push(
          mainBuilder.getNewItemLine(`Estimated increase for ${ matter.isMatterProvinceNS ? 'Current fiscal year' : mainBuilder.getAdjustmentDateYear(true) }`,
            `${ mainBuilder.getPercentage(Number(soAdj.estimatedPercentageIncrease)) }`));
      }

      if ((soAdj.baseTaxAdjustmentTaxBillType == 'INTERIM' && !this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey)) || soAdj.baseTaxAdjustmentTaxBillType == 'PREVIOUS') {
        sadItemDetails.push(
          mainBuilder.getNewItemLine(`Estimated ${ matter.isMatterProvinceNS ? 'Current fiscal year' : mainBuilder.getAdjustmentDateYear(true) } taxes`,
            mainBuilder.getCurrency(soAdj.calculateEstimatedTaxes(InterimTaxMultiplier)), undefined, 'PropertyTax.EstimatedTaxAmount'));
      }

      if (this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey) && soAdj.adjustmentBasedOnPercentageOfTotalTaxes == 'YES'
        && (mainBuilder.matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject || mainBuilder.matter.isBaseTaxAdjustmentEqualDivisionAmongUnits)) {
        sadItemDetails.push(mainBuilder.getNewItemLine(`Total taxes for this unit (${ soAdj.purchaserPortionOfTotalTax.toFixed(2) }  % of  ${ mainBuilder.getCurrency(soAdj.calculateTotalTaxForTheUnit(InterimTaxMultiplier)) })`, mainBuilder.getCurrency(soAdj.calculateTotalTaxesForAdjBeginningOfYearUntilOcc(InterimTaxMultiplier))));
      }

      let calculatedVendorWillHavePaidProportionate: number = 0;
      if (mainBuilder.matter.isProjectProportionateShare && soAdj.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && (soAdj.baseTaxAdjustmentTaxBillType == 'INTERIM' || this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey))) {
        calculatedVendorWillHavePaidProportionate = Number(soAdj.vendorWillHavePaid) * Number(soAdj.purchaserPortionOfTotalTax) / 100;
      } else {
        calculatedVendorWillHavePaidProportionate = soAdj.vendorWillHavePaid;
      }

      let labelForVendor: string = soAdj.overrideVendorHasPaid && !Utils.isEmptyString(soAdj.overrideVendorHasPaidLabel) && (mainBuilder.matter.isProjectSale || mainBuilder.matter.templateForProject)
        ? soAdj.overrideVendorHasPaidLabel : `${ mainBuilder.vendorLabel } has paid`;
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ labelForVendor } ${ this.getVendorShare(statementAdjustmentKey, matter, soAdj, mainBuilder) }`, mainBuilder.getCurrency(calculatedVendorWillHavePaidProportionate)));

      if (PropertyTaxesUtil.isProportionateShareVisible(matter, soAdj) && !this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey)) {
        const labels: string[] = PropertyTaxesUtil.getProportionateShareLabels(matter, soAdj, mainBuilder.currencyPipe, mainBuilder.decimalPipe);
        if (Array.isArray(labels) && labels.length == 2) {
          sadItemDetails.push(mainBuilder.getNewItemLine(labels[ 0 ], ``));
          sadItemDetails.push(mainBuilder.getNewItemLine(labels[ 1 ], `${ PropertyTaxesUtil.getFormatedProportionateShareAmt(soAdj, mainBuilder.currencyPipe) }`));
        }
      }

      if (PropertyTaxesUtil.isProportionateShareVisibleForVendor(matter, soAdj) && !this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey)) {
        const labels: string[] = PropertyTaxesUtil.getProportionateShareLabelsForVendor(mainBuilder.vendorLabel, soAdj, mainBuilder.currencyPipe, mainBuilder.decimalPipe);
        if (Array.isArray(labels) && labels.length == 2) {
          sadItemDetails.push(mainBuilder.getNewItemLine(labels[ 0 ], ``));
          sadItemDetails.push(mainBuilder.getNewItemLine(labels[ 1 ], `${ mainBuilder.getCurrency(soAdj.calculateProportionateShareOfVendor()) }`));
        }
      }

      let dateForAdj: string = (isBeginningOfOcc) ? mainBuilder.matter.adjustmentDateForTaxBeginningOfYearUntilOcc : mainBuilder.matter.getClosingDate();
      let elapsedDays: string = matter.isMatterProvinceNS ? soAdj.getNSTaxationDaysForPropertyTax(dateForAdj, matter.isPaysForDateOfClosingVendor).toString()
        : soAdj.getDaysSinceBeginningOfYearForPropertyTax(dateForAdj, mainBuilder.matter.isPaysForDateOfClosingVendor);
      let calculatedVendorShare: number = soAdj.calculateVendorShare(InterimTaxMultiplier, dateForAdj, mainBuilder.matter.isPaysForDateOfClosingVendor, mainBuilder.matter.isProjectProportionateShare, isBeginningOfOcc, matter.provinceCode);

      if (isBeginningOfOcc) {
        calculatedVendorShare = soAdj.calculateVendorShareForTaxBeginningOfYearUntilOcc(InterimTaxMultiplier, dateForAdj, mainBuilder.matter.isPaysForDateOfClosingVendor, mainBuilder.matter.isProjectProportionateShare);
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ mainBuilder.vendorLabel }'s share to occupancy`, ''));
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ Utils.getFormattedDate(dateForAdj, 'MMMM DD, YYYY', '??????????') } (${ elapsedDays } days)`,
          mainBuilder.getCurrency(calculatedVendorShare)));
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ mainBuilder.vendorLabel }'s share for ${ elapsedDays } days`,
          mainBuilder.getCurrency(calculatedVendorShare)));
      }

      let creditLine: string = mainBuilder.getTaxesLabel(soAdj);
      if (isBeginningOfOcc) {
        creditLine = (PropertyTaxesUtil.calculateVendorHasPaidProjectForBeginningOfYearUntilOccp(soAdj, mainBuilder.matter) > soAdj.vendorShareAmount) ?
          'Credit ' + mainBuilder.vendorLabel :
          ((PropertyTaxesUtil.calculateVendorHasPaidProjectForBeginningOfYearUntilOccp(soAdj, mainBuilder.matter) === soAdj.vendorShareAmount) ? 'No Adjustment' : 'Credit ' + mainBuilder.purchaserLabel);
      }

      sadItemDetails.push(mainBuilder.getNewItemLine(
        creditLine,
        ''/*mainBuilder.getCurrency(mainBuilder.calculateVendorShareDifference(item.matterTax))*/
      ));

    }
    return sadItemDetails;
  }

  static createMonthlyBreakdownForTIPP(sadItemDetails: StatementOfAdjustmentDisplayItemLine[], soAdj: MatterTax, adjYear: string, mainBuilder: StatementAdjustmentDisplayBuilder) {

    if (soAdj.tippInstallment) {
      let tippMonthlyBreakDown: string [] = [];
      let currentMonthInstallment: number;
      let lastMonthInstallment: number = -1;
      for (let i: number = 0; i < 12; i++) {
        currentMonthInstallment = this.mapTIPPInstallmentByMonth(i, soAdj);
        if (currentMonthInstallment > 0) {
          if (currentMonthInstallment == lastMonthInstallment) {
            let breakDown: string = tippMonthlyBreakDown[ tippMonthlyBreakDown.length - 1 ];
            //breakDown = breakDown.substring(0,3) + ' to ' + this.mapMonthByIndex(i) + ' ' + adjYear + ' @ ' + mainBuilder.getCurrency(currentMonthInstallment);
            tippMonthlyBreakDown[ tippMonthlyBreakDown.length - 1 ] = breakDown.substring(0, 3) + ' to ' + this.mapMonthByIndex(i) + ' ' + adjYear + ' @ ' + mainBuilder.getCurrency(currentMonthInstallment);
            sadItemDetails[ sadItemDetails.length - 1 ].title = breakDown.substring(0, 3) + ' to ' + this.mapMonthByIndex(i) + ' ' + adjYear + ' @ ' + mainBuilder.getCurrency(currentMonthInstallment);

          } else {
            tippMonthlyBreakDown.push(this.mapMonthByIndex(i) + ' ' + adjYear + ' @ ' + mainBuilder.getCurrency(currentMonthInstallment));
            let monthlyBreakdownTitle: string = this.mapMonthByIndex(i) + ' ' + adjYear + ' @ ' + mainBuilder.getCurrency(currentMonthInstallment);

            sadItemDetails.push(mainBuilder.getNewItemLine(monthlyBreakdownTitle,
              ''
            ));

          }
          lastMonthInstallment = currentMonthInstallment;
        }
      }
    }
  }

  static mapTIPPInstallmentByMonth(index: number, soAdj: MatterTax): number {
    switch (index) {
      case 0 : {
        return soAdj.tippInstallment.janInstallmentAmount;
      }
      case 1 : {
        return soAdj.tippInstallment.febInstallmentAmount;
      }
      case 2: {
        return soAdj.tippInstallment.marInstallmentAmount;
      }
      case 3 : {
        return soAdj.tippInstallment.aprInstallmentAmount;
      }
      case 4 : {
        return soAdj.tippInstallment.mayInstallmentAmount;
      }
      case 5 : {
        return soAdj.tippInstallment.junInstallmentAmount;
      }
      case 6 : {
        return soAdj.tippInstallment.julInstallmentAmount;
      }
      case 7 : {
        return soAdj.tippInstallment.augInstallmentAmount;
      }
      case 8 : {
        return soAdj.tippInstallment.sepInstallmentAmount;
      }
      case 9 : {
        return soAdj.tippInstallment.octInstallmentAmount;
      }
      case 10 : {
        return soAdj.tippInstallment.novInstallmentAmount;
      }
      case 11 : {
        return soAdj.tippInstallment.decInstallmentAmount;
      }

    }
  }

  static mapMonthByIndex(index: number): string {
    switch (index) {
      case 0 : {
        return 'Jan';
      }
      case 1 : {
        return 'Feb';
      }
      case 2: {
        return 'Mar';
      }
      case 3 : {
        return 'Apr';
      }
      case 4 : {
        return 'May';
      }
      case 5 : {
        return 'Jun';
      }
      case 6 : {
        return 'Jul';
      }
      case 7 : {
        return 'Aug';
      }
      case 8 : {
        return 'Sep';
      }
      case 9 : {
        return 'Oct';
      }
      case 10 : {
        return 'Nov';
      }
      case 11 : {
        return 'Dec';
      }
    }
  }

  static getVendorShare(statementAdjustmentKey: string, matter: Matter, propertyTax: MatterTax, mainBuilder: StatementAdjustmentDisplayBuilder): string {
    if (!this.isRealtyTaxesPaidByVendor(statementAdjustmentKey) && PropertyTaxesUtil.isProjectOrPS(matter) &&
      (propertyTax.adjustmentBasedOnPercentageOfTotalTaxes == 'YES' && (propertyTax.baseTaxAdjustmentTaxBillType == 'INTERIM' || this.isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey))
        && (matter.isBaseTaxAdjustmentOnPercentageOfTaxesForProject || matter.isBaseTaxAdjustmentEqualDivisionAmongUnits))) {
      return ` (${ mainBuilder.addDecimalsToPercent(propertyTax.purchaserPortionOfTotalTax) }% of ${ mainBuilder.getCurrency(propertyTax.vendorWillHavePaid) })`;
    }
    return '';
  }

  static isRealtyTaxesPaidByVendor(statementAdjustmentKey: string): boolean {
    return (statementAdjustmentKey === StatementAdjustmentKey.REALTY_TAXES_PAID_BY_VENDOR);
  }

  static isAdjOfTypeTaxBeginningOfYearUntilOcc(statementAdjustmentKey: string): boolean {
    return (statementAdjustmentKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY);
  }

}
