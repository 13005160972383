import {SalePriceAdjustmentBase} from './sale-price-adjustment-base';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {OntarioSalePriceAdjustmentConstants} from './sale-price-adjustment-constants';

export class SalePriceAdjustmentOntarioStrategy extends SalePriceAdjustmentBase {

  readonly divideSalePriceFactor: number = 1.13;

  provinceCode: ProvinceCode = 'ON';

  calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst: boolean = false,
                               excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false): number {
    let deriveNetSalePrice = 0;
    let key = 'F_' + (this.federalPortion ? 'E' : 'D') + '_P_' + (this.provincialPortion ? 'E' : 'D');
    if (OntarioSalePriceAdjustmentConstants.TIER_1_THRESHOLD[ key ]) {
      let threshold = OntarioSalePriceAdjustmentConstants.TIER_1_THRESHOLD[ key ].find(item => item.THRESHOLD_AMOUNT >= this.total(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax));
      let multiplier = OntarioSalePriceAdjustmentConstants.DIVIDE_NET_OUT_MULTIPLIER;

      if (threshold && threshold.MULTIPLIER) {
        multiplier = threshold.MULTIPLIER;
      }

      let federalRebateTotal = Number(this.federalPortion &&
      (multiplier == OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESH0LD ||
        multiplier == OntarioSalePriceAdjustmentConstants.DIVIDE_THRESH0LD_MULTIPLIER)
        ?
        (Number(OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_MULTIPLIER) * Number(OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD))
        : '0');

      let provinceRebateTotal = Number(this.provincialPortion &&
      (multiplier == OntarioSalePriceAdjustmentConstants.DIVIDE_NET_OUT_MULTIPLIER ||
        multiplier == OntarioSalePriceAdjustmentConstants.DIVIDE_THRESH0LD_MULTIPLIER)
        ?
        Number(OntarioSalePriceAdjustmentConstants.ONTARIO_REBATE_THRESHOLD)
        : '0');

      let totalPrice: number = Number(this.total(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax)) + Number(federalRebateTotal) + Number(provinceRebateTotal);

      deriveNetSalePrice = Number(((this.isDivideSalePrice() ? this.total(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax) : totalPrice) / multiplier));// .toFixed(2))

    }

    return deriveNetSalePrice;
  }

  isRebatePortionEnabled(): boolean {
    return this.isFactorInHstRebate();
  }

  calculateRebate(hstPortion: number, excludeAdditionalConsiderationsInclHst: boolean = false,
                  excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false): number {
    if (!this.isRebatePortionEnabled()) {
      return 0;
    } else {
      if (this.federalPortion) {
        return this.evaluateFederalRebate(hstPortion, excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax);
      } else {
        return 0;
      }
    }
  }

  //Call this method if you need to calculate Federal rebate even if federalPortion disabled
  evaluateFederalRebate(hstPortion: number, excludeAdditionalConsiderationsInclHst: boolean = false,
                        excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false): number {
    let derivedNetSalePrice: number;
    derivedNetSalePrice = this.calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax);

    if (derivedNetSalePrice > OntarioSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD) {
      return 0;
    } else if (derivedNetSalePrice < OntarioSalePriceAdjustmentConstants.DEMINISHING_FEDERAL_THRESHOLD) {
      let netPrice: number = (derivedNetSalePrice * ((hstPortion / 100) * OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION));
      if ((OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * (-1)) > (netPrice * (-1))) {
        return OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD;
      } else {
        return netPrice;
      }
    } else {
      let netPrice: number = (derivedNetSalePrice * ((hstPortion / 100) * OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION));
      if ((OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * (-1)) > (netPrice * (-1))) {
        return OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * ((OntarioSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - derivedNetSalePrice) / 100000);
      } else {
        return netPrice * ((OntarioSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - derivedNetSalePrice) / 100000);
      }
    }
  }

  calculateFederalRebatePortion(federalTaxRate: number, netSalePrice: number): number {
    if (!this.netOutHstFromHSTSalePrice) {
      this.netOutHstFromHSTSalePrice = 'NO';
    }
    switch (this.netOutHstFromHSTSalePrice) {
      case 'NO' :
      case 'YES_DIVIDE_SALE_PRICE':
        if (netSalePrice > OntarioSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD) {
          return 0;
        }
        let rebateAmt: number = federalTaxRate && netSalePrice ? (federalTaxRate / 100) * OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION * netSalePrice : 0;
        let federalRebate = rebateAmt > OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD ? OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD : rebateAmt;
        if (netSalePrice < OntarioSalePriceAdjustmentConstants.DEMINISHING_FEDERAL_THRESHOLD) {
          return federalRebate;
        } else {
          return federalRebate * (OntarioSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - netSalePrice) / 100000;
        }
      case 'YES_FACTOR_IN_HST_REBATE':
        //adopt the same value from same itemLine as in Sale Price Adjustment
        return this.calculateRebate(federalTaxRate);
    }
    return 0;
  }

  calculateOntarioRebatePortion(ontarioTaxRate: number, salePrice: number): number {
    if (!this.netOutHstFromHSTSalePrice) {
      this.netOutHstFromHSTSalePrice = 'NO';
    }
    switch (this.netOutHstFromHSTSalePrice) {
      case 'NO' :
      case 'YES_DIVIDE_SALE_PRICE':
        //HST Rebate Federal Portion= Federal Tax Rate * (% Federal Portion) * Sale Price
        let ontarioRebatePortion: number = ontarioTaxRate && salePrice ? (ontarioTaxRate / 100) * OntarioSalePriceAdjustmentConstants.PROVINCE_REBATE_PORTION * salePrice : 0;
        return ontarioRebatePortion > OntarioSalePriceAdjustmentConstants.ONTARIO_REBATE_THRESHOLD ? OntarioSalePriceAdjustmentConstants.ONTARIO_REBATE_THRESHOLD : ontarioRebatePortion;
      case 'YES_FACTOR_IN_HST_REBATE':
        //adopt the same value from same itemLine as in Sale Price Adjustment
        return this.calculateProvincialRebate(ontarioTaxRate);
    }
    return 0;
  }

  calculateProvincialRebate(provinceHstPortion: number, isTaxOut?: boolean, excludeAdditionalConsiderationsInclHst: boolean = false,
                            excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false): number {
    if (!this.isRebatePortionEnabled()) {
      return 0;
    } else {
      let isTaxOutFlag: boolean = false;
      if (isTaxOut) {
        isTaxOutFlag = true;
      }
      if (this.provincialPortion) {
        return this.evaluateProvincialRebate(provinceHstPortion, isTaxOutFlag, excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax);
      } else {
        return 0;
      }
    }
  }

  //Call this method if you need to calculate Provincial rebate even if provincialPortion disabled
  evaluateProvincialRebate(provinceHstPortion: number, isTaxOutFlag?: boolean, excludeAdditionalConsiderationsInclHst: boolean = false,
                           excludeCreditsToPurchaserInclHst: boolean = false, excludeBuyDowns: boolean = false, excludeFromTax: boolean = false): number {
    let provinceCalculatedPortion: number = this.calculateHstPortion(provinceHstPortion, isTaxOutFlag, true, excludeAdditionalConsiderationsInclHst, excludeCreditsToPurchaserInclHst, excludeBuyDowns, excludeFromTax) * OntarioSalePriceAdjustmentConstants.PROVINCE_REBATE_PORTION;
    return provinceCalculatedPortion < OntarioSalePriceAdjustmentConstants.ONTARIO_REBATE_THRESHOLD ? provinceCalculatedPortion : OntarioSalePriceAdjustmentConstants.ONTARIO_REBATE_THRESHOLD;
  }

  federalRebatePortion(): number {
    return OntarioSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION;
  }

  calculateTotalRebatePortionTax(provinceHstRate: number, federalHstRate: number, salePrice: number, taxRate: number): number {
    return taxRate ? Math.round(this.totalRebatePortion(provinceHstRate, federalHstRate, salePrice) * taxRate) / 100 : 0;
  }

  totalRebatePortionWithTax(provinceHstRate: number, federalHstRate: number, salePrice: number, taxRate: number): number {
    return this.totalRebatePortion(provinceHstRate, federalHstRate, salePrice) + this.calculateTotalRebatePortionTax(provinceHstRate, federalHstRate, salePrice, taxRate);
  }
}
