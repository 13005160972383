import {TaxRateTier} from './tax-rate-tier';

export class ConsiderationTaxes {

  instanceType: string;
  lastUpdatedTimeStamp: number;
  id: number;
  effectiveDate: number;
  lttMinimum: number;
  lttMaximum: number;
  lttRebateMaximum: number;
  hstRate: number;
  hstProvincialPortion: number;
  hstFederalPortion: number;
  provinceCode: string;
  rateType: string;
  tiers: TaxRateTier[];

  constructor(considerationTaxes?: ConsiderationTaxes) {
    if (considerationTaxes) {
      for (let prop in considerationTaxes) {
        if (considerationTaxes.hasOwnProperty(prop)) {
          this[ prop ] = considerationTaxes[ prop ];
        }
      }

    }

  }

  get provinceDefaultTaxRate(): string {
    return (this.hstProvincialPortion > 0 ? this.rateTypeLabel : 'GST');
  }

  get rateTypeLabel(): string {
    return ConsiderationTaxes.calculateRateTypeLabel(this.rateType);
  }

  static calculateRateTypeLabel(rateType: string): string {
    if (rateType) {
      if (rateType === 'GST+PST') {
        return 'GST';
      }
    }
    return rateType;
  }
}
