<!-- modal -->
<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>{{headerTitle}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body form-horizontal" *ngIf="contact && contact.contactName" (scroll)="onScroll($event)">
        <div class="form-group  col-lg-12 padding-bottom-5">
            <div class="col-lg-12 padding-bottom-10" style="border: 2px solid #ddd;">
                <span class="info-icon col-lg-1 padding-right-5 padding-left-5 left padding-top-7">
                    <i class="fa fa-info-circle font-20 blue" aria-hidden="true"></i>
                </span>
                <label class="control-label col-lg-11 text-left line-height-18" [innerHTML]="contactTitle"></label>
                <label class="control-label col-lg-11 text-left margin-bottom-10"
                    *ngIf="context && context.isModalOpenFromLenderData && context.signingOfficers &&
                    context.signingOfficers.length > 0 && (context.lenderContact.isCorporation )">{{'Signing Officer(s) from lender Data: '+ context.signingOfficers.join(', ')}}
                </label>
            </div>
        </div>
        <div [dp-read-only-block]="isContactReadOnly">
            <div class="form-group  col-lg-12">
                <label class="control-label col-lg-2">Gender</label>
                <div class="col-lg-4">
                    <select class="form-control"
                            id="gender"
                            name="gender"
                            [disabled]="isGenderDisabled()"
                            [(ngModel)]="contact.localGender"
                            (ngModelChange)="onGenderChangeRefactorWork($event)"
                    >
                        <option *ngFor="let gender of genders" [value]="gender.value">
                            {{gender.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group col-lg-12" *ngIf="contact.isCorporation || contact.isOtherEntity">
                <label class="control-label col-lg-2">{{contact.isCorporation ? 'Company Name' : 'Name'}}*</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="orgName"
                           name="orgName"
                           [(ngModel)]="contact.organizationName"
                           class="form-control"
                           maxlength="150"/>
                </div>

            </div>

            <div *ngIf="contact.isIndividual || contact.isEstate">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">{{surNameLabel}}*</label>
                    <div class="col-lg-4">
                        <input type="text"

                               id="lastName"
                               name="lastName"
                               [(ngModel)]="contact.contactName.lastName"
                               class="form-control"
                               maxlength="100"/>
                    </div>

                </div>
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">{{firstNameLabel}}</label>
                    <div class="col-lg-4">
                        <input type="text"

                               id="firstName"
                               name="firstName"
                               [(ngModel)]="contact.contactName.firstName"
                               maxlength="50"
                               class="form-control"/>
                    </div>
                </div>
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">{{middleNameLabel}}</label>
                    <div class="col-lg-4">
                        <input type="text"

                               id="middleName"
                               name="middleName"
                               [(ngModel)]="contact.contactName.middleName"
                               maxlength="50"
                               class="form-control"/>
                    </div>

                </div>
            </div>
            <div class="form-group col-lg-12" *ngIf="(contact.isMalePoaOrFemalePoa || contact.isCorporation || contact.isOtherEntity || contact.isEstate) && !!context.isModalOpenFromLenderData">
                <dp-signing-officer #dpSigningOfficer [matter]="context.matter"
                                    [parentContact]="contact"
                                    [parentParticipant]="context.participant"
                                    [showOnlySignerName]="true"
                                    [empGuarantorSigningOfficers]="context.signingOfficers"
                                    [addSignerMatterParticipantToMatter]="(context.lenderData && context.lenderData.borrower && context.lenderData.borrower.isGuranator() ? false : undefined)"
                                    [isMatterSnapShot]="context.lenderData && context.lenderData.borrower && context.lenderData.borrower.isGuranator()"
                                    [errorTopic]="'<MAIN_TOPIC>'"
                                    >
                </dp-signing-officer>
            </div>
            <div *ngIf="contact.isMalePoaOrFemalePoa && !context.isModalOpenFromLenderData">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="attorney1">Name of Attorney(#1)</label>
                    <div class="col-lg-10">
                        <input type="text"
                               name="attorney1"
                               id="attorney1"
                               class="form-control"
                               [(ngModel)]="contact.attorneyName1"
                               maxlength="100"/>
                    </div>
                </div>

                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2" for="attorney2">Name of Attorney(#2)</label>
                    <div class="col-lg-10">
                        <input type="text" name="attorney2" id="attorney2" class="form-control"
                               [(ngModel)]="contact.attorneyName2"
                               maxlength="100"/>
                    </div>
                </div>
            </div>


            <div *ngIf="(contact.isCorporation || contact.isOtherEntity) && !context.isModalOpenFromLenderData">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">Signing Officer (#1)</label>
                    <div class="col-lg-6">
                        <input type="text"

                               id="signingOfficer_1"
                               name="signingOfficer1"
                               [(ngModel)]="contact.signingOfficer1"

                               maxlength="50" class="form-control"/>
                    </div>
                </div>

                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">Title or Office Held</label>
                    <div class="col-lg-6">
                        <input type="text"

                               id="titleOrOfficeHeld_1"
                               name="titleOfOfficeHeld1"
                               [(ngModel)]="contact.titleOfOfficeHeld1"
                               maxlength="50"
                               class="form-control"/>
                    </div>
                </div>

                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">Signing Officer (#2)</label>
                    <div class="col-lg-6">
                        <input type="text"

                               id="signingOfficer_2"
                               name="signingOfficer2"
                               [(ngModel)]="contact.signingOfficer2"
                               maxlength="50" class="form-control"/>
                    </div>
                </div>

                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2">Title or Office Held</label>
                    <div class="col-lg-6">
                        <input type="text"

                               id="titleOrOfficeHeld_2"
                               name="titleOfOfficeHeld2"
                               [(ngModel)]="contact.titleOfOfficeHeld2"
                               maxlength="50" class="form-control"/>
                    </div>
                </div>
            </div>

            <div *ngIf="contact.isEstate && !context.isModalOpenFromLenderData">
                <div class="form-group col-lg-12">
                    <dp-signing-officer [matter]="context.matter"
                                        [parentContact]="contact"
                                        [errorTopic]="'<MAIN_TOPIC>'"
                    >
                    </dp-signing-officer>
                </div>



                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2 vertical-align-label">Gender of Deceased</label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="genderEstate"
                                name="genderEstate"
                                [(ngModel)]="contact.deceasedGender">
                            <option *ngFor="let genderEstate of genderEstateCode"
                                    [value]="genderEstate.value">
                                {{genderEstate.label}}
                            </option>
                        </select>
                    </div>

                    <label class="control-label col-lg-2">Estate Status</label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                id="estate"
                                name="estate"
                                [disabled]="!!contact.estateStatusFlag"
                                [(ngModel)]="contact.estateStatusFlag">
                            <option *ngFor="let estate of estateStatus"
                                    [value]="estate.value">
                                {{estate.label}}
                            </option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="form-group col-lg-12" *ngIf="context.address || matter.isPurchaseBC">
                <dp-address-form [address]="contactAddress"
                                 [fieldPrefix]="'contact.address'"
                                 [showAddressTypeDropdown]="true"
                                 [addressDd]="addressTypeDd"
                                 [addressSummaryLabel]="'Address'">
                </dp-address-form>
            </div>

            <div class="form-group col-lg-12 padding-top-5" *ngIf="(isMatterPurchase && !isMatterLinked) && (context && !context.isModalOpenFromLenderData)">
                <div class="col-lg-2"></div>
                <div class="col-lg-4">
                    <dp-checkbox fieldId="master-checkbox" [hasTabIndex]="true"
                    ><input type="checkbox" id="master-checkbox"
                            [checked]="isCreateContact"
                            [(ngModel)]="isCreateContact"
                    />
                    </dp-checkbox>
                    <label class="control-label vertical-align-label display-inline">Create Contact</label>
                </div>
            </div>


        </div>
        <div class="col-lg-12" *ngIf="isCreateSourceContactAutoEnabled()">
            <div class="form-group">
                <div class="col-lg-4  modal-create-new-client">
                    <div class="col-lg-1 padding-right-0">
                        <dp-checkbox fieldId="createdNewClient">
                            <input type="checkbox"
                                   id="createdNewClient"
                                   [dp-read-only]="true"
                                   [(ngModel)]="shouldCreateSourceContact"
                                   [checked]="shouldCreateSourceContact"/>
                        </dp-checkbox>
                    </div>
                    <div class="col-lg-11 padding-left-4 text-left">
                        <label class="control-label-snapshot">Create New Client</label>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12">
            <div class="row padding-top-20">
                <div class="col-lg-12 text-center">
                    <button type="button" class="dp-btn" (click)="ok()" id="okBtn">
                        <span>OK</span>
                    </button>

                    <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
