import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DocumentProductionData} from '../document-production-data';
import {StopCodesCommonComponent} from './stop-codes-common.component';
import {Matter} from '../../shared/matter';
import {MatterService} from '../../matter.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class StopCodeModalContext {
  stopCodeData: any[];
  matters: Matter[];
}

@Component({
  selector: 'dp-stop-codes-multiple',
  templateUrl: 'stop-codes-multiple-matter-modal.component.html'
})
export class StopCodesMultipleMatterModalComponent extends ModalComponent<StopCodeModalContext> implements OnInit {
  currentMatterCounter: number = 0;

  @ViewChild('dpStopCodesCommon') dpStopCodesCommon: StopCodesCommonComponent;

  constructor(
    public dialog: MatDialogRef<StopCodesMultipleMatterModalComponent>,
    public matterService: MatterService,
    @Inject(MAT_DIALOG_DATA) context?: StopCodeModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {}

  get activeStopCodeMatter(): any {
    return this.context.stopCodeData && this.currentMatterCounter < this.context.stopCodeData.length
      ? this.context.stopCodeData[this.currentMatterCounter]
      : undefined;
  }

  get documentProductionData(): DocumentProductionData {
    return this.activeStopCodeMatter ? this.activeStopCodeMatter.documentProductionData : undefined;
  }

  get activeMatterNumber(): string {
    if (this.activeStopCodeMatter && this.context.matters && this.context.matters.length > 0) {
      let matter = this.context.matters.find(
        (item) => item.id == this.activeStopCodeMatter.documentProductionData.matterId
      );
      return matter ? matter.matterRecordNumber : '';
    } else {
      return '';
    }
  }

  prevMatter(): void {
    this.copyDocumentStopCode();
    this.currentMatterCounter =
      this.currentMatterCounter > 0 ? this.currentMatterCounter - 1 : this.currentMatterCounter;
  }

  nextMatter(): void {
    this.copyDocumentStopCode();
    this.currentMatterCounter =
      this.currentMatterCounter + 1 < this.context.stopCodeData.length
        ? this.currentMatterCounter + 1
        : this.currentMatterCounter;
  }

  get isOkVisible(): boolean {
    return this.currentMatterCounter == this.context.stopCodeData.length - 1;
  }

  copyDocumentStopCode(): void {
    this.dpStopCodesCommon.copyValueFromWrapperToDocumentStopCode();
  }

  onClickOk(): void {
    this.dpStopCodesCommon.copyValueFromWrapperToDocumentStopCode();
    this.dialog.close(this.context.stopCodeData);
  }

  onClickCancel(): void {
    this.clearStopCodeValues();
    this.dialog.close();
  }

  clearStopCodeValues(): void {
    this.context.stopCodeData.forEach((item) => {
      for (let template of item.documentProductionData.templates) {
        for (let stopCode of template.stopCodes) {
          stopCode.value = '';
        }
      }
    });
  }
}
