<form class="form-horizontal" id="manageTeranetUser" #testTeranetUserForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-lg-10">
                <h1>Test Login</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>

        <div class="row modal-body">
            <div class="form-group">
                <div class="col-lg-4 text-left vertical-align-label">
                    <label class="control-label">Password</label>
                </div>
                <div class="col-lg-6">
                    <input  type="password" class="form-control focus-first" id="teranet_user_pswd" name="teranet_user_pswd"
                            [(ngModel)]="password"
                            required

                    >
                </div>
            </div>
        </div>


        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn2"
                        (click)="testCredentials()"
                        [disabled]="testTeranetUserForm.form.invalid"
                        class="dp-btn">
                    <span>Proceed</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>


        </div>
        <div class="col-xs-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>
</form>
