import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {assumedMortgageDropDowns} from '../../model/so-adj-drop-downs';
import Utils from '../../../../shared-main/utils';
import {SelectItem} from 'primeng/api';
import {SoAdjVTBMortgage, StatementOfAdjustmentDisplayItemLine} from '../../model';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalResult} from '../../../../shared-main/enums';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {Matter} from '../../../shared';
import {Mortgage} from '../../../shared/mortgage';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class SoAdjVtbMortgageModalContext {
  closingDate: string;
  mortgagePriority: number;
  principalAmount: number;
  adjustment: SoAdjVTBMortgage;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  matter: Matter;
  mortgage: Mortgage;
}

@Component({
  selector: 'dp-so-adj-vtb-mortgage-modal-content',
  templateUrl: './so-adj-vtb-mortgage.modal.component.html'
})
export class SoAdjVtbMortgageModalComponent extends ModalComponent<SoAdjVtbMortgageModalContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  localAdj: SoAdjVTBMortgage;
  closingDateLabel: string = '';

  maxMortgageAllowed: number = 16; // Sale files can go up to 16, maybe it should use the same hardcoded value as matter.availableMortgagePriority()
  mortgagePriorityOptions: SelectItem[] = [];

  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  adjustmentDescription: string;

  constructor(
    public dialog: MatDialogRef<SoAdjVtbMortgageModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: SoAdjVtbMortgageModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.mortgagePriorityOptions = [];
    this.mortgagePriorityOptions.push(...assumedMortgageDropDowns.mortgagePriorityTypes);
    for (let i: number = 1; i <= this.maxMortgageAllowed; i++) {
      this.mortgagePriorityOptions.push({value: i, label: Utils.getLongOrdinal(i).toUpperCase()});
    }
    this.localAdj = new SoAdjVTBMortgage(this.context.adjustment);
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.localAdj);
    }
  }

  checkForErrors() {
    if (this.localAdj.heading.length == 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.vtb.mortgage.heading.empty');
    }
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    // cannot delete this type of adjustment from here
  }

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0) {
      this.cachedDetails = this.context.statementAdjustmentDisplayUtil.getDisplayItems(this.localAdj, '', true);
      this.cachedDetails.pop(); // remove last one about credit (it has another formatting on main "Statement of Adjustment" screen)
      this.adjustmentDescription = this.localAdj.getAdjustmentDescription(
        this.context.mortgagePriority,
        this.context.matter,
        this.context.mortgage
      );
    }
    return this.cachedDetails;
  }
}
