import {Paragraph} from './paragraph';
import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {messages} from '../../common/messages';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {Matter} from '../shared';
import {WritExecution} from '../../shared-main/teranet/property-writ-execution';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class ParagraphDetailModelContext {
  public paragraph: Paragraph;
  public teranetService: TeranetService;
  public matter: Matter;
  public writExecution: WritExecution;
  public isDeleteVisible: boolean;
}

@Component({
  selector: 'dp-paragraph-detail-modal',
  templateUrl: 'paragraph-detail.modal.component.html',
  providers: [DialogService]
})
@FocusFirstElementDecorator()
export class ParagraphDetailModel extends ModalComponent<ParagraphDetailModelContext> implements OnInit {
  paragraphUnTouchedCopy: Paragraph;

  constructor(
    public dialog: MatDialogRef<ParagraphDetailModel>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: ParagraphDetailModelContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    //Keeping an untouched copy to revert the changes if user clicks cancel
    this.paragraphUnTouchedCopy = new Paragraph(this.paragraph);
  }

  get paragraph(): Paragraph {
    return this.context.paragraph;
  }

  onOK(): void {
    this.dialog.close({action: 'OK'});
  }

  close(): void {
    if (this.isModalDirty) {
      this.dialogService.confirm('Confirmation', messages.declaration.cancelConfirmation, false).subscribe((res) => {
        if (res && res == true) {
          this.paragraph.copyValues(this.paragraphUnTouchedCopy);
          this.dialog.close({action: 'Cancel'});
        }
      });
    } else {
      this.dialog.close({action: 'Cancel'});
    }
  }

  get isModalDirty(): boolean {
    return !this.paragraphUnTouchedCopy.equals(this.paragraph);
  }

  onViewWritDetails(): void {
    this.context.teranetService.downloadTeranetDocumentAndDisplay(
      this.context.matter.id,
      this.context.writExecution.executionCertificate.id
    );
  }

  onDelete() {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this paragraph?', false, 'OK')
      .subscribe((res) => {
        if (res == true) {
          this.dialog.close({action: 'Delete'});
        }
      });
  }

  get showViewButton(): boolean {
    return this.context.writExecution ? true : false;
  }

  get showDeleteButton(): boolean {
    return this.context.isDeleteVisible;
  }

  ngAfterViewInit() {}
}
