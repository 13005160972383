import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class BillingIssue extends BaseEntity {
  errorCode: string;
  errorMessage: string;

  //if errorCode is populated with any value other than "unity.billing.unreachable" then it's considered as business error and matter shown in readOnly mode
  hasBusinessError(): boolean {
    return this.errorCode && this.errorCode.length > 0 && this.errorCode != 'unity.billing.unreachable';
  }
}
