<div class="dp-sticky-note">
    <div class="note_toolbar">
        <button type="button" class="btn-close" (click)="close()">&times;</button>
    </div>
    <div class="content">
        <textarea id="stickyContents"
                  name="stickyContents"
                  rows="1"
                  [(ngModel)]="data"
                  (blur)="onBlur()"
                  (keyup)="enableSave()"
        ></textarea>
    </div>
    <div class="footer">
        <i class="fa fa-trash padding-top-5" aria-hidden="true" (click)="deleteNote()">
        </i>
    </div>
</div>
