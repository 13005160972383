<div class="inspector-footer">
    <div class="error-box" *ngIf="errorList.length > 0">
        <div class="error-main">
            <ul>
                <li *ngFor="let dpError of errorList">
                    <div>
                        <i [ngClass]="(dpError.errorType && dpError.errorType == 'WARNING')? 'fa fa-exclamation-triangle yellow':'fa fa-times-circle red'"
                           aria-hidden="true">
                        </i>
                        <ng-container *ngIf="dpError.isInnerHTML">
                            <span [innerHTML]="getErrorMessage(dpError)"></span>
                        </ng-container>
                        <ng-container *ngIf="!dpError.isInnerHTML">
                            <span > {{getErrorMessage(dpError)}}</span>
                        </ng-container>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</div>

