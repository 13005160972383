import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ProjectAdjustmentFooter extends BaseEntity {

  constructor(projectAdjustmentFooter?: ProjectAdjustmentFooter) {
    super(projectAdjustmentFooter);
    if (!projectAdjustmentFooter) {
      this.footerType1 = 'DEFAULT';
      this.unadjustedSalePriceFooterType2 = 'DEFAULT';
      this.adjustedSalePriceFooterType2 = 'DEFAULT';
      this.unadjustedSalePriceFooterType3 = 'DEFAULT';
      this.adjustedSalePriceFooterType3 = 'DEFAULT';

    }
  }

  footerType1: string;
  footerValue1: string;
  unadjustedSalePriceFooterType2: string;
  unadjustedSalePriceFooterValue2: string;
  adjustedSalePriceFooterType2: string;
  adjustedSalePriceFooterValue2: string;
  unadjustedSalePriceFooterType3: string;
  unadjustedSalePriceFooterValue3: string;
  adjustedSalePriceFooterType3: string;
  adjustedSalePriceFooterValue3: string;
}
