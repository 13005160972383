<form class="form-horizontal" id="writNameSearch">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect &trade; Name Search</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="onCancel()">&times;</button>
            </div>

        </div>

        <div class="row modal-body">
            <div class="form-group padding-bottom-20">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right" for="enforcementOffice" >Enforcement Office:</label>
                </div>
                <div class="col-lg-4">
                    <select class="form-control"
                            id="enforcementOffice"
                            name="enforcementOffice"
                            [dp-read-only]="writSearchRequest.isRedoNameSearch"
                            [(ngModel)]="writSearchRequest.enforcementOfficeNumber"
                            (ngModelChange)="updateEOInWritSearchRequest($event)"
                    >
                        <option *ngFor="let eo of enforcementOffices" [value]="eo.enforcementOfficeNumber">
                            {{eo.enforcementOfficeName}}
                        </option>
                    </select>
                </div>
            </div>

            <div *ngFor="let partyData of writSearchRequest.partiesToSearch; let i=index;">
                <div class="form-group">
                    <div class="col-lg-2 offset-lg-1 vertical-align-label">
                        <select class="form-control" [dp-read-only]="writSearchRequest.isRedoNameSearch"
                                id="partyType-{{i}}"
                                name="partyType-{{i}}"
                                [(ngModel)]="partyData.partyType" (change)="onPartyTypeChange(i)">
                            <option *ngFor="let pt of partyTypes" [value]="pt.value">
                                {{pt.label}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="partyData.isPerson">
                        <div class="col-lg-1 vertical-align-label">
                            <label class="control-label text-right" for="lastName-{{i}}" >Last Name:</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text" id="lastName-{{i}}" name="lastName-{{i}}"
                                   maxlength="60"

                                   dp-uppercase
                                   [(ngModel)]="partyData.lastName"

                                   class="form-control"  >
                        </div>
                    </div>
                    <div class="form-group" *ngIf="partyData.isCompany">
                        <div class="col-lg-1 vertical-align-label">
                            <label class="control-label text-right" for="companyName-{{i}}" >Name:</label>
                        </div>
                        <div class="col-lg-4">
                            <input type="text" id="companyName-{{i}}" name="companyName-{{i}}"
                                   [maxlength]="120"

                                   dp-uppercase
                                   [(ngModel)]="partyData.givenName"
                                   dp-error-validator
                                   [fieldKey]="'writ.name.search.name.MISSING'"
                                   class="form-control"  >
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="partyData.isPerson">
                    <div class="col-lg-2 offset-lg-2 vertical-align-label">
                        <label class="control-label text-right" for="givenName-{{i}}" >Given Name(s):</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" id="givenName-{{i}}" name="givenName-{{i}}"
                               [maxlength]="60"

                               dp-uppercase
                               [(ngModel)]="partyData.givenName"
                               dp-error-validator
                               [fieldKey]="'writ.name.search.name.MISSING'"
                               class="form-control"  >
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-2 offset-lg-2 vertical-align-label">
                    <label class="control-label text-right" for="reLine" >RE: </label>
                </div>
                <div class="col-lg-4">
                    <input type="text" id="reLine" name="reLine"
                           maxlength="27"
                           dp-uppercase
                           [(ngModel)]="writSearchRequest.reLine"
                           class="form-control"  >
                </div>
            </div>
        </div>


        <div class="row modal-footer ">
            <div class="col-lg-12 text-right">
                <button id="okBtn" class="dp-btn" type="button" (click)="proceed()"  >
                    Proceed
                </button>
                <button class="dp-btn dp-btn-cancel" type="button" (click)="onCancel()">
                    Cancel
                </button>
            </div>
        </div>

        <div class="col-xs-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>
</form>
