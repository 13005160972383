<div class="row">
    <div class="col-lg-12">
        <div class="matter-title form-group">
            <h1>Manage Document Profile - {{documentProfileName}}
                <ul class="right margin-top-minus-10">
                    <li>
                        <button id="saveBtn"
                                type="button"
                                class="function-icons"
                                [ngClass]="{'icon-dirty' : isDirty}"
                                [disabled]="!isDirty"
                                (click)="save()">
                            <i class="svg-icon save margin-left-5" aria-hidden="true"></i>
                        </button>
                        <h6 [ngClass]="{'icon-dirty' : isDirty}">Save</h6>
                    </li>
                </ul>
            </h1>
        </div>
    </div>
    <div  class="col-lg-12 admin-padding" id="spinner2"
          *ngIf="( !soa && !soa.sameAsDefaultProfileFlag) || (!soaDefault && soa && soa.sameAsDefaultProfileFlag)">
        <div class="text-center">
            <img src="assets/img/spinner.gif"/>
        </div>
    </div>
    <div class="col-lg-12 admin-padding" *ngIf="((soa && !soa.sameAsDefaultProfileFlag)  || (soa && soa.sameAsDefaultProfileFlag && soaDefault))">
        <div class="form-horizontal row">
            <!-- estTaxIncrease | balanceDueOnClosing | soaHeading -->
            <div class="row padding1rem">
                <div class="col-lg-12 padding1rem">
                    <div class="col-lg-6  offset-lg-2">
                        <!-- printBalanceFlag -->
                        <div class="form-group">
                            <dp-checkbox fieldId="printBalanceFlag">
                                <input id="printBalanceFlag"
                                       name="printBalanceFlag"
                                       type="checkbox"
                                       [(ngModel)]="getSoaDocumentProfile().printBalanceFlag"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       #printBalanceFlag>
                            </dp-checkbox>
                            <label for="printBalanceFlag" dp-province-field-label="admin.documentProfile.soa.printBalanceFlag" [label-province-code]="account.selectedProvinceCode">
                                    Print Balance Due on Closing at the bottom of the page
                            </label>
                        </div>

                        <!-- includeVendorHstFlag -->
                        <div class="form-group">
                            <dp-checkbox fieldId="includeVendorHstFlag">
                                <input id="includeVendorHstFlag"
                                       name="includeVendorHstFlag"
                                       type="checkbox"
                                       [(ngModel)]="getSoaDocumentProfile().includeVendorHstFlag"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       #includeVendorHstFlag>
                            </dp-checkbox>
                            <label for="includeVendorHstFlag">{{getIncludeVendorTaxRateTypeLabel()}}</label>
                        </div>
                    </div>
                    <!-- Checkboxes -->
                    <div class="col-lg-3 offset-lg-1">
                        <!-- defaultProfileFlag -->
                        <div class="form-group">

                            <dp-checkbox fieldId="sameAsDefaultProfileFlag">
                                <input id="sameAsDefaultProfileFlag"
                                       name="sameAsDefaultProfileFlag"
                                       type="checkbox"
                                       [(ngModel)]="soa.sameAsDefaultProfileFlag"
                                       [disabled]="documentProfile.defaultProfileFlag"
                                       (ngModelChange)="sameAsDefaultProfileOnchange($event)"
                                       [title]="sameAsDefaultProfile.checked ? 'Check this box to adopt those settings in the firm defaults for all fields in this tab' : 'Editing within this tab is disabled because settings of firm defaults have been adopted'"
                                       #sameAsDefaultProfile>
                            </dp-checkbox>
                            <label for="sameAsDefaultProfileFlag">Same As Default Document Profile</label>
                        </div>


                    </div>
                    <!-- // END - Checkboxes -->
                </div>
            </div>
            <div class="row padding01rem padding-bottom-10">
                <div class="form-group">
                    <div class="col-lg-12 main-level">
                        <span class="title">Paper Size</span>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Statement of Adjustments
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="paperSizeCode"
                                        name="paperSizeCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().paperSizeCode">
                                    <option *ngFor="let papersize of paperSizes"
                                            [value]="papersize.value">
                                        {{papersize.label}}
                                    </option>
                                </select>

                            </div>
                        </div>
                    </div>
                    <div class="form-group" *dpShowByProvince="'admin.documentProfile.soa.directionOfFundsPaperSizeCode';code:account.selectedProvinceCode">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Direction re: Funds
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="directionOfFundsPaperSizeCode"
                                        name="directionOfFundsPaperSizeCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().directionOfFundsPaperSizeCode">
                                    <option *ngFor="let fundsPaperSize of paperSizes"
                                            [value]="fundsPaperSize.value">
                                        {{fundsPaperSize.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- //END - Paper size -->
                    </div>

                    <!-- estTaxIncrease | balanceDueOnClosing | soaHeading -->

                </div>
            </div>
            <!-- // END - estTaxIncrease | balanceDueOnClosing | soaHeading -->


            <!-- Headings -->
            <div class="row padding01rem">
                <div class="fields-group">
                    <div class="form-group main-level">
                        <span class="title">Headings</span>
                    </div>
                    <!-- Statement of Adjustments heading -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2 padding-top-2">
                                Statement of Adjustments heading
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="55"
                                       class="form-control"
                                       name="soaHeading"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().soaHeading"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- Vendor & Data fields -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Vendor
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="55"
                                       class="form-control"
                                       name="otherHeadingsVendor"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().otherHeadingsVendor"
                                />
                            </div>

                            <label class="control-label col-lg-2">
                                Data
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="otherHeadingsVendorDataCode"
                                        name="otherHeadingsVendorDataCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().otherHeadingsVendorDataCode">
                                    <option *ngFor="let vendor of otherHeadingVendorNames"
                                            [value]="vendor.value">
                                        {{vendor.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- //END - Vendor & Data fields -->

                    <!-- Purchaser & Data fields -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Purchaser
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="55"
                                       class="form-control"
                                       name="otherHeadingsPurchaser"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().otherHeadingsPurchaser"
                                />
                            </div>

                            <label class="control-label col-lg-2">
                                Data
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="otherHeadingsPurchaserDataCode"
                                        name="otherHeadingsPurchaserDataCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().otherHeadingsPurchaserDataCode">
                                    <option *ngFor="let purchaser of otherHeadingsPurchaserNames"
                                            [value]="purchaser.value">
                                        {{purchaser.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- //END - Purchaser & Data fields -->

                    <!-- Address & Data fields -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Address
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="55"
                                       class="form-control"
                                       name="otherHeadingsAddress"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().otherHeadingsAddress"
                                />
                            </div>
                            <label class="control-label col-lg-2">
                                Data
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="otherHeadingsAddressDataCode"
                                        name="otherHeadingsAddressDataCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().otherHeadingsAddressDataCode">
                                    <option *ngFor="let address of addressData"
                                            [value]="address.value">
                                        {{address.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- //END - Address & Data fields -->

                    <!-- Adjust date & Data fields -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Adjust date
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="55"
                                       class="form-control"
                                       name="otherHeadingsAdjustDate"
                                       [disabled]="sameAsDefaultProfile.checked"

                                       [(ngModel)]="getSoaDocumentProfile().otherHeadingsAdjustDate"
                                />
                            </div>

                            <label class="control-label col-lg-2">
                                Data
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="otherHeadingsAdjustDateDataCode"
                                        name="otherHeadingsAdjustDateDataCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().otherHeadingsAdjustDateDataCode">
                                    <option *ngFor="let address of adjustDate"
                                            [value]="address.value">
                                        {{address.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- //END - Adjust date & Data fields -->

                    <!-- Right column lists credits to -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2 padding-top-2">
                                Right column lists credits to
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="25"
                                       name="rightColumnListsCreditsTo"
                                       class="form-control"
                                       [fieldKey]="'admin.soaDocumentProfile.rightColumnListsCreditsTo'"
                                       statusBarHelp
                                       (keyup)="customKeyStruckRightColumnCreditsTo($event)"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().rightColumnListsCreditsTo"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- //END Right column lists credits to -->

                    <!-- Left column lists credits to -->
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2">
                                Left column lists credits to
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       maxlength="25"
                                       name="leftColumnListsCreditsTo"
                                       class="form-control"
                                       [fieldKey]="'admin.soaDocumentProfile.leftColumnListsCreditsTo'"
                                       statusBarHelp
                                       (keyup)="customKeyStruckLeftColumnCreditsTo($event)"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().leftColumnListsCreditsTo"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- //END Left column lists credits to -->
                </div>
                <!-- //END - Headings -->
            </div>
            <div class="row padding01rem">
                <div class="form-group main-level">
                    <span class="title">Other</span>
                </div>
                <div class="row">
                    <div class="form-group" *dpShowByProvince="'admin.documentProfile.soa.interestRateOnPrepaidRent';code:account.selectedProvinceCode">
                        <div class="col-lg-12">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Rate of Interest on Prepaid Rent</label>
                            </div>
                            <div class="col-lg-1">
                                <input type="text"
                                       id="interestRateOnPrepaidRent"
                                       name="interestRateOnPrepaidRent"
                                       [(ngModel)]="getSoaDocumentProfile().interestRateOnPrepaidRent"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       class="form-control"
                                       dp-percentage
                                       [max-decimals] = 2
                                       [max-int] = 99.99
                                       padding-zero="true"
                                />
                            </div>
                            <div class="col-lg-1">
                                <label class="control-label text-left">%</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2 padding-top-2">
                                Est'd increase in taxes over previous year
                            </label>
                            <div class="col-lg-1">
                                <input type="text"
                                       class="form-control"
                                       name="estTaxIncrease"
                                       id="estTaxIncrease"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().estTaxIncrease"
                                       dp-percentage
                                       [max-decimals] = 2
                                       [max-int] = 99.99
                                       padding-zero="true"
                                />
                            </div>
                            <div class="col-lg-1">
                                <label class="control-label text-left">%</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-lg-12">
                            <label class="control-label col-lg-2" [label-province-code]="account.selectedProvinceCode"
                                   dp-province-field-label="admin.documentProfile.soa.balanceDueOnClosing">
                                Balance due on closing
                            </label>
                            <div class="col-lg-4">
                                <input type="text"
                                       class="form-control"
                                       name="balanceDueOnClosing"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().balanceDueOnClosing"
                                       maxlength="76"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Wording for Direction re: Funds -->
                <div class="row margin-bottom-60">
                    <div class="col-lg-12">
                        <div class="form-group" *dpShowByProvince="'admin.documentProfile.soa.wordingDirectionFunds';code:account.selectedProvinceCode">
                            <label class="control-label col-lg-2">
                                Wording for Direction re: Funds
                            </label>
                            <div class="col-lg-10">
                                <textarea [(ngModel)]="getSoaDocumentProfile().wordingDirectionFunds"
                                          [disabled]="sameAsDefaultProfile.checked"
                                          [fieldKey]="'admin.soaDocumentProfile.wordingDirectionFunds'"
                                          statusBarHelp
                                          (keyup)="customKeyStruckDirection($event)"
                                          maxlength="228">
                                </textarea>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-2 padding-top-2">
                                Heading for Report to Vendor
                            </label>
                            <div class="col-lg-10">
                                <input type="text"
                                       class="form-control"
                                       [fieldKey]="'admin.soaDocumentProfile.headingReportVendor'"
                                       statusBarHelp
                                       (keyup)="customKeyStruckReportVendor($event)"
                                       [disabled]="sameAsDefaultProfile.checked"
                                       [(ngModel)]="getSoaDocumentProfile().headingReportVendor"
                                       maxlength="66"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-2 padding-top-2">
                                Show net sale price in HST On Sale Price and HST Rebate Adjustments?
                            </label>
                            <div class="col-lg-1">
                                <select class="form-control"
                                        id="showNetSalePriceInAdjustment"
                                        name="showNetSalePriceInAdjustment"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().showNetSalePriceInAdjustment">
                                    <option *ngFor="let option of showNetSalePriceInAdjustmentOptions"
                                            [value]="option.value">
                                        {{option.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-2">
                                Preferred terminology
                            </label>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="preferredTerminologyCode"
                                        name="preferredTerminologyCode"
                                        [disabled]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().preferredTerminologyCode">
                                    <option *ngFor="let option of preferredTerminology"
                                            [value]="option.value">
                                        {{option.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-lg-2">
                                Reference Statement <br>of Adjustments in <br>Funds Summary:
                            </label>
                            <div class="col-lg-1 padding-top-15">
                                <select class="form-control"
                                        id="referenceStatementOfAdjustments"
                                        name="referenceStatementOfAdjustments"
                                        [dp-read-only]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().referenceStatementOfAdjustments">
                                    <option *ngFor="let item of yesNoOptions"
                                            [value]="item.value">
                                        {{item.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group"  *dpShowByProvince="'matter.soa.paysForDateOfClosing';code:account.selectedProvinceCode">
                            <label class="control-label col-lg-2" [label-province-code]="account.selectedProvinceCode"
                                   [dp-province-field-label]="'matter.soa.paysForDateOfClosing'">
                                Pays for date of closing
                            </label>
                            <div class="col-lg-2">
                                <select class="form-control"
                                        [dp-read-only]="sameAsDefaultProfile.checked"
                                        [(ngModel)]="getSoaDocumentProfile().paysForDateOfClosing"
                                        name="paysForDateOfClosing"
                                        id="paysForDateOfClosing">
                                    <option *ngFor="let pdcOption of paysForDateOfClosing" [value]="pdcOption.value">
                                        {{pdcOption.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- //END - Wording for Direction re: Funds -->
            </div>
        </div>
    </div>
</div>
