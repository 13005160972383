import {Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {UtilsService} from '../utils.service';

@Component({
  selector: 'app-chatai',
  templateUrl: 'chat-AI.component.html',
  styleUrls: [ 'chat-AI.component.scss' ]
})
export class ChatAIComponent implements OnInit {

  @ViewChild('aiQuery') aiQueryText: ElementRef;
  @ViewChild('allmessages') allMessages: ElementRef;
  isLoading: boolean = false;
  onResize = new EventEmitter();
  isMinimized: boolean = false;
  isFocusNearBottom: boolean = true;
  copyMessage: string = 'Copy';
  showInfoLinks: boolean = false;
  faqDocumentLink: string = '';
  licenseTerms: string = 'https://dyedurham.ca/license/';

  messages: Message[] = [
    new Message(
      null,
      null,
      'bot',
      `Hi – I’m DeeDee, your AI Assistant. I can assist to help save you time and speed up some of your everyday tasks.`,
      this.getMessageTime(),
      false,
      null
    )
  ];

  conversation = new Subject<Message[]>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChatAIComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
    this.conversation.subscribe((val) => {
      this.messages = this.messages.concat(val);
    });

    this.addInitialMessage2ToChatbot();

    this.utilsService.getFAQDocument().subscribe((result: any) => {
      if (result && result.faq) {
        this.faqDocumentLink = result.faq.pdfUrl;
      }
    });
  }

  ngAfterViewChecked() {
    if (this.isFocusNearBottom) {
      this.scrollBottom();
    }
  }

  scrollBottom() {
    this.allMessages.nativeElement.scrollTop =
      this.allMessages.nativeElement.scrollHeight;
  }

  scrolled(event: any) {
    this.isFocusNearBottom = this.isUserFocusNearBottom();
  }

  isUserFocusNearBottom() {
    const threshold = 10;
    const position =
      this.allMessages.nativeElement.scrollTop +
      this.allMessages.nativeElement.offsetHeight;
    const height = this.allMessages.nativeElement.scrollHeight;
    return position > height - threshold;
  }

  onQueryTextFocus() {
    this.isFocusNearBottom = true;
    this.showInfoLinks = false;
  }

  addInitialMessage2ToChatbot() {
    this.isLoading = true;
    setTimeout(() => {
      const userMessage1 = new Message(
        null,
        null,
        'bot',
        `\u2022\tWe do not recommend sharing any sensitive, confidential, privileged or personal information here.\n\u2022\tInformation provided does not constitute legal or professional advice. Always double-check and make sure you are considering your own professional obligations.\n\u2022\tAn AI Assistant can and does make mistakes, using me is at your own risk, as-is, and as-available.`,
        this.getMessageTime(),
        false,
        null
      );
      this.conversation.next([ userMessage1 ]);
      this.isLoading = false;
      this.isFocusNearBottom = true;
      this.addInitialMessage3ToChatbot();
    }, 2000);
  }

  addInitialMessage3ToChatbot() {
    this.isLoading = true;
    setTimeout(() => {
      const userMessage2 = new Message(
        null,
        null,
        'bot',
        `Ask me a question below`,
        this.getMessageTime(),
        false,
        null
      );
      this.conversation.next([ userMessage2 ]);
      this.isLoading = false;
      this.isFocusNearBottom = true;
    }, 2000);
  }

  ResizeModal() {
    this.isMinimized = !this.isMinimized;
    this.showInfoLinks = this.isMinimized ? false : this.showInfoLinks;
    this.onResize.emit(this.isMinimized.toString());
  }

  updateUserSentiment(
    partitionkey: string,
    rowkey: string,
    sentiment: UserSentiment | null
  ) {
    this.utilsService
    .setUserSentimentForAIResponse(partitionkey, rowkey, sentiment)
    .subscribe((res: any) => {
      var indexToUpdate = this.messages.findIndex(
        (x) => x.partitionkey === res.partitionKey && x.rowkey === res.rowKey
      );
      this.messages[ indexToUpdate ].userSentiment = res.userSentiment;
    });
  }

  setNegativeUserSentiment(
    partitionkey: string,
    rowkey: string,
    sentiment: UserSentiment | null
  ) {
    if (!sentiment || sentiment === UserSentiment.positive) {
      sentiment = UserSentiment.negative;
    } else {
      sentiment = null;
    }
    this.updateUserSentiment(partitionkey, rowkey, sentiment);
  }

  setPositiveUserSentiment(
    partitionkey: string,
    rowkey: string,
    sentiment: UserSentiment | null
  ) {
    if (!sentiment || sentiment === UserSentiment.negative) {
      sentiment = UserSentiment.positive;
    } else {
      sentiment = null;
    }

    this.updateUserSentiment(partitionkey, rowkey, sentiment);
  }

  setTooltipHoverState(
    message: Message,
    type: 'up' | 'down' | 'copy',
    isHovering: boolean
  ) {
    if (type === 'up') {
      message.showGoodResponseTooltip = isHovering;
    } else if (type === 'down') {
      message.showBadResponseTooltip = isHovering;
    } else {
      message.showCopyTooltip = isHovering;
      this.copyMessage = 'Copy';
    }
  }

  CopyToClipboard(message: Message) {
    navigator.clipboard
    .writeText(message.content)
    .then(() => {
      this.copyMessage = 'Copied';
      message.showCopyTooltip = true;
    })
    .catch(() => {
      console.error('Unable to copy data to clipboard');
    });
  }

  CallAIAssitant() {
    const userQuery = this.aiQueryText.nativeElement.value.trim();
    this.aiQueryText.nativeElement.value = '';

    if (userQuery) {
      this.isLoading = true;
      const userMessage = new Message(
        null,
        null,
        'user',
        userQuery,
        this.getMessageTime(),
        false,
        null
      );
      this.conversation.next([ userMessage ]);

      this.utilsService.callAIChatAssitant(userQuery).subscribe(
        (result: any) => {
          this.isLoading = false;
          var botMessage = null;
          if (result.error) {
            botMessage = new Message(
              null,
              null,
              'bot',
              'Sorry, I am unable to process your query',
              this.getMessageTime(),
              true,
              null
            );
          } else {
            botMessage = new Message(
              result.partitionKey,
              result.rowKey,
              'bot',
              result.response,
              this.getMessageTime(),
              true,
              result.userSentiment
            );
          }
          this.conversation.next([ botMessage ]);
          this.isFocusNearBottom = true;
        },
        (error) => {
          this.isLoading = false;
          const botMessage = new Message(
            null,
            null,
            'bot',
            'Sorry, I am unable to process your query',
            this.getMessageTime(),
            true,
            null
          );
          this.conversation.next([ botMessage ]);
          this.isFocusNearBottom = true;
        }
      );
    }
  }

  getMessageTime() {
    var time = new Date();
    var hours = time.getHours();
    var minute = time.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    var currminute = minute < 10 ? '0' + minute : minute;
    var currTime = hours + ':' + currminute + ampm;
    return currTime;
  }

  closeModal() {
    this.dialogRef.close({data: 'closeAI'});
    this.utilsService.ClearAIChatSession().subscribe((result) => {
    });
  }

  infoBtnClick() {
    this.showInfoLinks = !this.showInfoLinks;
  }

  downloadFAQ(path: any) {
    path = path.replace(/ /g, '%20');
    window.open(path);
  }

  openLicenseTerms() {
    window.open(this.licenseTerms);
  }
}

export class Message {
  constructor(
    public partitionkey: any,
    public rowkey: any,
    public author: string,
    public content: string,
    public timenow: string,
    public isAIResponseMessage: boolean,
    public userSentiment: UserSentiment | null,
    public showGoodResponseTooltip: boolean = false,
    public showBadResponseTooltip: boolean = false,
    public showCopyTooltip: boolean = false
  ) {
  }
}

enum UserSentiment {
  positive = 'positive',
  negative = 'negative',
}