import {Component, Inject, OnInit} from '@angular/core';
import {Cirf} from '../../matters/shared/cirf/cirf';
import {SelectItem} from 'primeng/api';
import {CirfDocument} from '../../matters/shared/cirf/cirf-document';
import {CirfClientInfo} from '../../matters/shared/cirf/cirf-client-info';
import {CirfMatterContactInfo} from '../../matters/shared/cirf/cirf-matter-contact-info';
import {Address} from '../../matters/shared/address';
import {CirfInsuranceDetail} from '../../matters/shared/cirf/cirf-insurance-detail';
import {CirfMortgage, MortgageTypeValue} from '../../matters/shared/cirf/cirf-mortgage';
import {PurchaserCapacity} from '../../matters/purchaser/capacity/purchaser-capacity';
import {CirfUdf} from '../../matters/shared/cirf/cirf-udf';
import {CIRF_CONFIG_KEYS} from '../cirf-config/cirf-config-keys';
import {CirfConfigService} from '../cirf-config/cirf-config.service';
import {CirfIdentification} from '../../matters/shared/cirf/cirf-identification';
import Utils from '../utils';
import {DpBooleanValueTypes} from '../../matters/shared/dp-boolean';
import {CirfHelperService} from '../../matters/shared/cirf/cirf-helper.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export const cirfOptions = {
  yesNoOptions: [
    {label: '', value: 'QUESTION'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  yesNoOptionsMandatory: [
    {label: '', value: ''},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  yesNoOnlyOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  firstTimeHomeBuyerOptions: [
    {label: 'Yes', value: 'Yes'},
    {label: 'No', value: 'No'}
  ],
  genderOptions: [
    {label: '', value: 'QUESTION'},
    {label: 'male', value: 'MALE'},
    {label: 'female', value: 'FEMALE'}
  ],
  residentStatusOptions: [
    {label: '', value: ''},
    {label: 'Canadian Citizen', value: 'CANADIAN_CITIZEN'},
    {label: 'Permanent Resident', value: 'PERMANENT_RESIDENT'}
  ],
  spousalStatusOptions: [
    {label: '', value: ''},
    {label: 'Not Married', value: 'NOT_MARRIED'},
    {label: ' Married', value: 'MARRIED'},
    {label: ' Common Law', value: 'COMMON_LAW'},
    {label: ' Separated', value: 'SEPARATED'}
  ]
};

export const UploadedFileDescription = {
  permanentResidentCard: 'Permanent Resident Card',
  mortgageStatement: 'Mortgage Statement',
  mostRecentTaxBill: 'Most Recent Tax Bill',
  driverLicenseFront: "Driver's License Front",
  driverLicenseBack: "Driver's License Back"
};

export class PresentCirfModalComponentContext {
  cirf: Cirf;
  matterType: string;
  matterPurchasersCapacityOptions: any[]; //only display the summary and not allow create/update of any matters
}

/**
 *   Most of the code is copied from the unity-connect print-preview UI
 */

@Component({
  selector: 'dp-present-cirf-modal',
  templateUrl: './present-cirf-modal.component.html',
  styleUrls: ['./present-cirf-modal.component.scss']
})
export class PresentCirfModalComponent extends ModalComponent<PresentCirfModalComponentContext> implements OnInit {
  cirf: Cirf;
  cirfDocuments: CirfDocument[] = [];
  genderOptions: SelectItem[] = cirfOptions.genderOptions;
  yesNoOptions: SelectItem[] = cirfOptions.yesNoOptions;
  residentStatusOptions: any = cirfOptions.residentStatusOptions;
  spousalStatusOptions: any = cirfOptions.spousalStatusOptions;
  yesNoOptionsMandatory: any = cirfOptions.yesNoOptionsMandatory;
  firstTimeHomeBuyerOptions: any = cirfOptions.firstTimeHomeBuyerOptions;
  yesNoOnlyOptions: any = cirfOptions.yesNoOnlyOptions;
  matterPurchasersCapacityOptions: any;
  newMortgagesUdfs: CirfUdf[] = [];
  existingMortgagesUdfs: CirfUdf[] = [];
  fireInsuranceUdfs: CirfUdf[] = [];
  matterContactInfoUdfs: CirfUdf[] = [];

  constructor(
    public dialog: MatDialogRef<PresentCirfModalComponent>,
    public cirfConfigService: CirfConfigService,
    @Inject(MAT_DIALOG_DATA) context?: PresentCirfModalComponentContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.cirf = this.context.cirf;
    this.getCirfDocuments();
    if (this.cirf.cirfMatterData.provinceCode == 'AB') {
      this.matterPurchasersCapacityOptions =
        PurchaserCapacity.matterPurchasersCapacityOptions.ConnectCapacityOptions_AB;
    } else {
      this.matterPurchasersCapacityOptions = this.context.matterPurchasersCapacityOptions;
    }
    this.loadUdfs();
  }

  getCirfDocuments(): void {
    this.cirfDocuments = this.cirf && this.cirf.documents;
  }

  getPermanentResidentCard(clientId: string): CirfDocument {
    if (this.cirfDocuments && this.cirfDocuments.length) {
      return this.cirfDocuments.find(
        (doc) => doc.sourceId == clientId && doc.description == UploadedFileDescription.permanentResidentCard
      );
    } else {
      return null;
    }
  }

  getDriverLicenseFront(clientId: string): CirfDocument {
    if (this.cirfDocuments && this.cirfDocuments.length) {
      return this.cirfDocuments.find(
        (doc) => doc.sourceId == clientId && doc.description == UploadedFileDescription.driverLicenseFront
      );
    } else {
      return null;
    }
  }

  getDriverLicenseBack(clientId: string): CirfDocument {
    if (this.cirfDocuments && this.cirfDocuments.length) {
      return this.cirfDocuments.find(
        (doc) => doc.sourceId == clientId && doc.description == UploadedFileDescription.driverLicenseBack
      );
    } else {
      return null;
    }
  }

  getGeneralDocuments(): CirfDocument[] {
    return this.cirfDocuments.filter((doc) => !doc.sourceId);
  }

  getMortgageDocuments(mortgageId: string): CirfDocument[] {
    if (this.cirfDocuments && this.cirfDocuments.length) {
      return this.cirfDocuments.filter((doc) => doc.sourceId == mortgageId);
    } else {
      return null;
    }
  }

  isNotResidingAtSubjectProperty(): boolean {
    return this.matterContactInfo && this.matterContactInfo.residingAtSubjectProperty == DpBooleanValueTypes.NO;
  }

  isClosingAddressVisible(): boolean {
    return this.cirf.isMatterMortgage() ? this.isNotResidingAtSubjectProperty() : true;
  }

  get cirfClients(): CirfClientInfo[] {
    return this.cirf && this.cirf.cirfMatterData && this.cirf.cirfMatterData.cirfClients;
  }

  get matterContactInfo(): CirfMatterContactInfo {
    return this.cirf && this.cirf.cirfMatterData && this.cirf.cirfMatterData.matterContactInfo;
  }

  get preOrPostClosingAddress(): Address {
    return this.matterContactInfo && this.cirf.isMatterPurchase()
      ? this.matterContactInfo.preClosingAddress
      : this.matterContactInfo.postClosingAddress;
  }

  get fireInsuranceDetail(): CirfInsuranceDetail {
    return this.cirf && this.cirf.cirfMatterData && this.cirf.cirfMatterData.fireInsuranceDetail;
  }

  get mortgages(): CirfMortgage[] {
    return this.cirf && this.cirf.cirfMatterData && this.cirf.cirfMatterData.mortgages;
  }

  get newMortgages(): CirfMortgage[] {
    return this.cirf && this.cirf.cirfMatterData && this.cirf.cirfMatterData.newMortgages;
  }

  get existingMortgages(): CirfMortgage[] {
    return this.cirf && this.cirf.cirfMatterData && this.cirf.cirfMatterData.existingMortgages;
  }

  getLabelFromValue(value: string, dropdownArray: any[]): string {
    return Utils.getLabelFromValue(value, dropdownArray);
  }

  closeModal(): void {
    this.dialog.close();
  }

  loadUdfs() {
    this.fireInsuranceUdfs = [];
    if (
      this.cirf &&
      this.cirf.cirfMatterData &&
      this.cirf.cirfMatterData.fireInsuranceDetail &&
      this.cirf.cirfMatterData.fireInsuranceDetail.insuranceUdfs &&
      this.cirf.cirfMatterData.fireInsuranceDetail.insuranceUdfs.length
    ) {
      this.fireInsuranceUdfs.push(
        ...this.cirf.cirfMatterData.fireInsuranceDetail.insuranceUdfs.filter(
          (udf: CirfUdf, index: number) =>
            !this.isFieldHidden(CIRF_CONFIG_KEYS.fireInsuranceDetailUdf + index, udf.fieldValue, true)
        )
      );
    }
    this.matterContactInfoUdfs = [];
    if (
      this.cirf &&
      this.cirf.cirfMatterData &&
      this.cirf.cirfMatterData.matterContactInfo &&
      this.cirf.cirfMatterData.matterContactInfo.matterContactInfoUdfs
    ) {
      this.matterContactInfoUdfs.push(
        ...this.cirf.cirfMatterData.matterContactInfo.matterContactInfoUdfs.filter(
          (udf, index) => !this.isFieldHidden(CIRF_CONFIG_KEYS.matterContactInfoUdf + index, udf.fieldValue, true)
        )
      );
    }
  }

  availableMortgageUdfFields(cirfMortgage: CirfMortgage, mortgageType: string): CirfUdf[] {
    const fieldName =
      mortgageType === MortgageTypeValue.New ? CIRF_CONFIG_KEYS.newMortgageUdf : CIRF_CONFIG_KEYS.existingMortgageUdf;
    return cirfMortgage.mortgageUdfs.filter(
      (udf: CirfUdf, index: number) => !this.isFieldHidden(fieldName + index, udf.fieldValue, true)
    );
  }

  availableClientUdfFields(cirfClient: CirfClientInfo): CirfUdf[] {
    return cirfClient.cirfClientInfoUdfs.filter(
      (udf, index) => !this.isFieldHidden(CIRF_CONFIG_KEYS.cirfClientInfoUdf + index, udf.fieldValue, true)
    );
  }

  availableClientIdentificationUdfs(identification: CirfIdentification, identificationIndex: number): CirfUdf[] {
    return identification.identificationUdfs.filter(
      (udf, udfIndex) =>
        !this.isFieldHidden(
          CIRF_CONFIG_KEYS[`identification${identificationIndex + 1}Udf`] + udfIndex,
          udf.fieldValue,
          true
        )
    );
  }

  isFieldHidden(fieldName: string, fieldValue: string, isUdf?: boolean): boolean {
    return fieldName && CirfHelperService.isFieldHidden(fieldName, fieldValue, this.cirfConfigService, isUdf);
  }

  get closingAddress(): string {
    if (this.cirf) {
      if (this.cirf.isMatterPurchase()) {
        return '(Pre-Closing Address) Address 1';
      }
      if (this.cirf.isMatterMortgage()) {
        return '(Mailing Address) Address 1';
      }
    }
    return '(Post-Closing Address) Address 1';
  }
}
