import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment';
import {Moment} from 'moment';
import {PartialDateComponent} from '../../../../shared/partial-date';
import {GuarantorLastNameFieldCodes, ON_DEMAND_VALUE, ZERO_INTEREST} from '../../../../shared-main/constants';
import {SelectItem} from 'primeng/api';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Contact} from '../../../shared/contact';
import {Matter} from '../../../shared/matter';
import {Utils} from '../../../shared/utils';
import {MatterParticipant} from '../../../shared/matter-participant';
import {DdlTypeForYesNo, Mortgage, SctNoF9Value} from '../../../shared/mortgage';
import {MortgageTerm} from '../../../shared/mortgage-term';
import {dropDowns, mortgageeTypeOptions, noYesOptions, yesNoOptions} from '../dropdown-options';
import {NetAdvanceDialogComponent} from './net-advance.component';
import {AmortizationScheduleDialogComponent} from './amortization-schedule.component';
import {Subject} from 'rxjs/Subject';
import {MortgagePaymentService} from './mortgage-payment.service';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {IdentificationRecord} from '../../../shared/identification-record';
import {IdentificationDocument} from '../../../shared/identification-document';
import {GetGlobalSaveModelService} from '../../../shared/get-global-save-model.service';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {LenderFormsService} from '../../../forms/lender-forms/lender-forms-service';
import {ContactService} from '../../../../shared-main/contact.service';
import {Logger} from '@nsalaun/ng-logger';
import {MatterTab} from '../../../matter-tab';
import {Subscription} from 'rxjs/index';
import {TabsService} from '../../../../core/tabs.service';
import {MortgagePrecedentComponent} from '../precedent/mortgage-precedent.component';
import {PrecedentTypes} from '../../../shared/mortgage-precedent-type';
import {MortgageSoAdjService} from '../../../../shared-main/mortgage-so-adj.service';
import {DpBooleanValueTypes} from '../../../shared/dp-boolean';

declare var jQuery: any;

@Component({
  selector: 'dp-mortgage-term',
  templateUrl: 'mortgage-term.component.html',
  styleUrls: [ '../mortgage.styles.scss' ]
})
@FocusFirstElementDecorator()
export class MortgageTermComponent implements OnInit {

  @Input() matter: Matter;
  @Output() onChange = new EventEmitter();
  @ViewChild('interest') interestElement: ElementRef;
  @ViewChild('interestNIL') interestNILElement: ElementRef;
  @ViewChild('idAdjDate') partialInterestAdjustmentDateComponent: PartialDateComponent;
  @ViewChild('idPaymentDate') partialPaymentDateComponent: PartialDateComponent;
  @ViewChild('idAltPaymentDate') partialAltPaymentDateComponent: PartialDateComponent;
  @ViewChild('idMaturityDate') partialMaturityDateComponent: PartialDateComponent;

  selectedTab: string = 'Profile';
  yesNoItems: DdlTypeForYesNo[];
  yesNoItemsRegularPayments: DdlTypeForYesNo[];
  calculated: SelectItem[];
  insuranceAmountOptions: SelectItem[];
  paymentFrequency: SelectItem[];
  mortagageTermPeriod: string;
  allowMaxLength: boolean = true;
  selection: string = 'profile';
  currentTimeStamp = Date.now();
  scheduler: number = 0;
  filteredPaymentFrequency: any[];
  filteredAltPaymentFrequency: any[];
  filteredCalculated: any[];
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  utils;
  id: number = 0;
  matterId: number = 0;
  userSessionObj: any;
  sctNumbers: string[] = [];
  sctNumbersAndFilerInfoMap: Map<string, string> = new Map();
  previousLenderNames: string = '';
  box6SelectionStart: number = 0;
  mtgDescSelectionStart: number = 0;
  loanDescSelectionStart: number = 0;
  primeSelectionStart: number = 0;
  mortgageTermsOptions = dropDowns.mortgageTermsOptions;

  constructor(public dialogService: DialogService,
              public appConfig: AppConfig,
              public contactService: ContactService,
              public errorService: ErrorService,
              public globalSaveModelService: GetGlobalSaveModelService,
              public logger: Logger,
              public activatedRoute: ActivatedRoute,
              public mortgagePaymentService: MortgagePaymentService,
              public lenderFormsService: LenderFormsService,
              public mortgageSoAdjService: MortgageSoAdjService,
              public route: Router, public tabService: TabsService) {
    this.utils = new Utils();
  }

  ngOnInit(): void {

    this.activatedRoute
    .params
    .subscribe((params: Params) => {

      setTimeout(() => {
        this.id = +params[ 'mortgageId' ];
        this.initMortgageTerm();
      }, 100);

    });

    if (SESSION_STORAGE_KEYS && SESSION_STORAGE_KEYS.user) {
      this.userSessionObj = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user));
    }

    if (this.matter.isMatterProvinceBC) {
      this.f9Values.paymentDue = 'first day of each and every month';
    }
  }

  ngDoCheck() {
    if (this.previousLenderNames != this.lenderNames) {
      this.previousLenderNames = this.lenderNames;
      this.loadSCTNo();
    }
  }

  get lenderNames(): string {
    const mortgagee = this.matter.mortgagees.find(item => item.mortgageId == this.matter.mortgages[ this.id ].id);
    return mortgagee && mortgagee.contact && mortgagee.contact.lenderNames;
  }

  handleDropdownClickSCTNo(event): void {
    this.loadSCTNo();
  }

  handleDropdownClickSCTNoAB(event): void {
    this.loadSCTNo(true);
  }

  loadSCTNo(showSeveralOption?: boolean): void {
    this.lenderFormsService.getLenderForms(this.lenderNames, this.matter.provinceCode).subscribe(lenderForms => {
      this.sctNumbers = lenderForms.filter(item => item.sctNumber).map(item => item.sctNumber);
      lenderForms.filter(item => item.sctNumber).map(item => this.sctNumbersAndFilerInfoMap.set(item.sctNumber, item.sctFiler));
      if (showSeveralOption) {
        this.sctNumbers.unshift(SctNoF9Value.Several);
      }
      console.log(this.sctNumbers);
    });
  }

  populateFilerInfoForMB(): void {
    if (this.matter.isMatterProvinceMB && this.mortgage.isMortgageeAnInstitution() && this.mortgageTerm.sctNo) {
      let filerInfo = this.sctNumbersAndFilerInfoMap.get(this.mortgageTerm.sctNo);
      if (filerInfo) {
        this.mortgageTerm.sctFiledBy = filerInfo;
      } else {
        this.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
      }
    }
    this.enableSave();
    this.notifyChange();
  }

  get isLenderFormsNotEmpty(): boolean {
    return this.sctNumbers.length > 0;
  }

  get isSCTNoAvailable(): boolean {
    return this.mortgage.mortgageeType == mortgageeTypeOptions[ 1 ].value;
  }

  isMatterSame(): boolean {
    let isMatterSame = true;
    if (this.route.url) {
      let url: string = this.route.url;
      let urlId = url.substr(url.indexOf('matter/') + 7, url.length);
      if (urlId) {
        let matterId = urlId.substring(0, urlId.indexOf('/'));
        if (matterId) {
          isMatterSame = (Number(matterId) > 0 ? (Number(matterId) == this.matter.id) : (Number(matterId) == this.matter.tempIdForNewMatter));
        }
      }
    }
    return isMatterSame;

  }

  get mortgage(): Mortgage {
    return this.id >= this.matter.mortgages.length ? this.matter.mortgages[ 0 ] : this.matter.mortgages[ this.id ];
  }

  get mortgageId(): number {
    return this.id;
  }

  initMortgageTerm(): void {

    this.yesNoItems = noYesOptions;
    this.yesNoItemsRegularPayments = yesNoOptions;
    this.calculated = dropDowns.calculated;
    this.insuranceAmountOptions = dropDowns.insuranceAmount;

    //Only show "Other" as option if already selected. Not available for new matters.
    if (!this.mortgageTerm.isInsuranceAmountOther()) {
      this.insuranceAmountOptions = this.insuranceAmountOptions.filter(option => option.value != 'OTHER');
    }

    this.paymentFrequency = dropDowns.paymentFrequency;
    this.buildMortgageTermStructure();
    this.setMortageTerm();
    this.setAltVariablestoCalculateMortgage();
    this.setVariablestoCalculateMortgage();

    this.currentMatter.getGuarantors(this.mortgage).forEach((item: MatterParticipant) => {
      item.contact.initializeContactIdDetails();
    });
    // TODO: Temporary Fix for maturity date issue when you change mortgage
    //       Solution is to move maturity date logic out of partial date component
    if (this.mortgageTerm.maturityDate != ON_DEMAND_VALUE && this.partialMaturityDateComponent && !this.partialMaturityDateComponent.displayDate) {
      this.partialMaturityDateComponent.displayDate = true;
      this.partialMaturityDateComponent.setDate(this.mortgage.mortgageTerm.maturityDate);
    } else if (this.mortgageTerm.maturityDate == ON_DEMAND_VALUE && this.partialMaturityDateComponent && !!this.partialMaturityDateComponent.displayDate) {
      this.partialMaturityDateComponent.displayDate = false;
      this.partialMaturityDateComponent.setDate(this.mortgage.mortgageTerm.maturityDate);
    }

    this.logger.info('mortgage-term | ngOnInit | mortgage.mortgageTerm = ', this.mortgage.mortgageTerm);
    this.globalSaveModelService.getItem$.takeUntil(this.ngUnsubscribe).subscribe(() => {

      setTimeout(() => {
        this.logger.info('  --  this.mortgage.mortgageTerm.interestAdjustmentDate = ', this.mortgage.mortgageTerm.interestAdjustmentDate);

        if (this.partialInterestAdjustmentDateComponent) {
          // TODO: TEMP FIX as issue in partial date component (right fix is in date component)
          // which is holding date when user switch between mortgage tabs
          // in this case date component assign undefined/undefined/01 date to interestAdjustmentDate on tab switch when there is date in one of tabs
          if (this.mortgage.mortgageTerm.interestAdjustmentDate &&
            this.mortgage.mortgageTerm.interestAdjustmentDate.indexOf('/') > -1 &&
            this.mortgage.mortgageTerm.interestAdjustmentDate.split('/').some(dt => dt == 'undefined')) {
            this.mortgage.mortgageTerm.interestAdjustmentDate = null;
          }
          this.partialInterestAdjustmentDateComponent.setDate(this.mortgage.mortgageTerm.interestAdjustmentDate);
        }
        if (this.partialPaymentDateComponent) {
          this.partialPaymentDateComponent.setDate(this.mortgage.mortgageTerm.firstPaymentDate);
        }
        if (this.partialAltPaymentDateComponent) {
          this.partialAltPaymentDateComponent.setDate(this.mortgage.mortgageTerm.alternateFirstPaymentDate);
        }
        if (this.partialMaturityDateComponent) {
          this.partialMaturityDateComponent.setDate(this.mortgage.mortgageTerm.maturityDate);
        }
      }, 100);
    });
  }

  ngOnDestroy(): void {
    window.clearInterval(this.scheduler);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get mortgageTerm() {
    if (!this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm = new MortgageTerm();
    }
    return this.mortgage.mortgageTerm;
  }

  isRegularPaymentsDefaultOrYes(): boolean {
    return this.mortgageTerm.regularPayments == DpBooleanValueTypes.YES || this.mortgageTerm.regularPayments == DpBooleanValueTypes.Y_n;
  }

  buildMortgageTermStructure(): void {

    if (!this.mortgageTerm.regularPayments) {
      this.mortgageTerm.regularPayments = DpBooleanValueTypes.Y_n;
    }
    if (!this.mortgageTerm.interestOnly) {
      this.mortgageTerm.interestOnly = 'N_y';
    }
    if (!this.mortgageTerm.variableInterestRate) {
      this.mortgageTerm.variableInterestRate = 'N_y';
    }
    if (!this.mortgageTerm.collateralMortgage) {
      this.mortgageTerm.collateralMortgage = 'N_y';
    }
    if (!this.mortgageTerm.taxesPaidThroughMortgage) {
      this.mortgageTerm.taxesPaidThroughMortgage = 'N_y';
    }
    if (!this.mortgageTerm.alternateFrequency) {
      this.mortgageTerm.alternateFrequency = 'N_y';
    }
    if (!this.mortgageTerm.other) {
      this.mortgageTerm.other = 'N_y';
    }
    if (!this.mortgageTerm.assignmentOfRents) {
      this.mortgageTerm.assignmentOfRents = 'N_y';
    }
    if (!this.mortgageTerm.generalSecurityAgreement) {
      this.mortgageTerm.generalSecurityAgreement = 'N_y';
    }
    if (!this.mortgageTerm.principal) {
      this.mortgageTerm.principal = 0;
    }
    if (!this.mortgageTerm.regularPayment) {
      this.mortgageTerm.regularPayment = 0;
    }
    if (!this.mortgageTerm.taxPortion) {
      this.mortgageTerm.taxPortion = 0;
    }
    if (!this.mortgageTerm.alternateTaxPortion) {
      this.mortgageTerm.alternateTaxPortion = 0;
    }
    if (!this.mortgageTerm.alternateRegularPayment) {
      this.mortgageTerm.alternateRegularPayment = 0;
    }
    if (!this.mortgageTerm.otherPayment) {
      this.mortgageTerm.otherPayment = 0;
    }
    if (!this.mortgageTerm.alternateOtherPayment) {
      this.mortgageTerm.alternateOtherPayment = 0;
    }
    if (!this.mortgageTerm.alternateTotal) {
      this.mortgageTerm.alternateTotal = 0;
    }
    if (!this.mortgageTerm.calculationMethod) {
      this.mortgageTerm.calculationMethod = 'MANUAL_EDIT';
    }

    if (!this.mortgageTerm.insuranceAmount) {
      this.mortgageTerm.insuranceAmount = 'FULL_INSURABLE_VALUE';
    }
    // if (!this.mortgageTerm.interest) {
    //     this.mortgageTerm.interest = '0.00000';
    // }
    // if interest not set, set it to default value 0
    if (this.isInterestNotSet) {
      if (this.mortgage.id == -1) {
        this.mortgageTerm.interest = ZERO_INTEREST;
      }
    }
    if (this.mortgageTerm.netAdvanceItems == null) {
      this.mortgageTerm.netAdvanceItems = [];
    }
    if (!this.mortgageTerm.semiAnnualEquivalent) {
      this.mortgageTerm.semiAnnualEquivalent = '0.00000';
    }
    if (!this.mortgageTerm.sctFiledBy) {
      this.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
    }
    if (this.matter.isMatterProvinceBC && !this.mortgageTerm.id) {

      this.mortgageTerm.regularPayments ||= DpBooleanValueTypes.YES;
      this.mortgageTerm.variableInterestRate = DpBooleanValueTypes.YES;
      this.mortgageTerm.collateralMortgage = DpBooleanValueTypes.NO;
      this.mortgageTerm.alternateFrequency = DpBooleanValueTypes.N_y;
      this.mortgageTerm.floatingCharge ||= DpBooleanValueTypes.N_y;
      this.mortgageTerm.currentRunningAccount ||= DpBooleanValueTypes.N_y;
      this.mortgageTerm.placeOfPayment ||= 'Postal Address In Item 4';
      this.mortgageTerm.mortgageTermType ||= 'STANDARD';
      this.mortgageTerm.paymentFrequency ||= 'MONTHLY';

    }
  }

  get isInterestNotSet() {
    return this.mortgageTerm.interest === undefined;
  }

  variableInterestRateChange() {
    if (this.mortgageTerm.variableInterestRate == 'YES') {
      this.mortgageTerm.regularPaymentAutoCalculated = false;
      this.mortgageTerm.alternatePaymentAutoCalculated = false;
      this.clearAllPaymentError();
    }
    this.notifyChange();
  }

  isSelectedTabIdDetails(): boolean {
    return (this.selectedTab === 'ID-Details');
  }

  selectTab(selectedTab: string, guarantor: MatterParticipant): void {
    this.selection = selectedTab || 'Profile';
    if (selectedTab === 'idDetails') {
      guarantor.contact.initializeContactIdDetails();
    }
  }

  public updateIdentificationDocuments(identificationRecord: IdentificationRecord, nameOnId: string): void {
    if (identificationRecord.identificationDocuments) {
      identificationRecord.identificationDocuments.forEach((d: IdentificationDocument) => this.updateNameOnId(d, nameOnId));
    }
  }

  public updateNameOnId(identificationDocument: IdentificationDocument, nameOnId: string): void {
    if (identificationDocument.sameAsOfficerNameFlag) {
      identificationDocument.nameOnId = nameOnId;
    }
  }

  enableSave(): void {
    this.matter.dirty = true;
  }

  notifyChange(): void {
    this.onChange.emit();
  };

  onDateChangeAdjDate(event): void {
    this.enableSave();
    this.mortgageTerm.interestAdjustmentDate = this.getDateToSave(event);
    this.setMortageTerm();
  }

  onDateChangePaymentDate(event): void {
    this.enableSave();
    this.mortgageTerm.firstPaymentDate = this.getDateToSave(event);
  }

  onDateChangeAltPaymentDate(event): void {
    this.enableSave();
    this.mortgageTerm.alternateFirstPaymentDate = this.getDateToSave(event);
  }

  onDateChangeMaturityDate(event): void {
    this.enableSave();
    this.mortgageTerm.maturityDate = this.getDateToSave(event);
    this.setMortageTerm();
  }

  setMortageTerm(): void {

    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    if (this.mortgageTerm.interestAdjustmentDate && this.mortgageTerm.maturityDate &&
      pattern.test(this.mortgageTerm.interestAdjustmentDate) && pattern.test(this.mortgageTerm.maturityDate)) {
      let mortageTermInMonths = this.inMonths(this.mortgageTerm.interestAdjustmentDate, this.mortgageTerm.maturityDate);
      if (mortageTermInMonths > 0) {
        let years = Math.floor(mortageTermInMonths / 12);
        let months = mortageTermInMonths % 12;
        this.mortagageTermPeriod = (years > 0 ? (years + ' years ') : '') + (months > 0 ? (months + ' months ') : '');
      } else {
        this.mortagageTermPeriod = undefined;
      }
    } else {
      this.mortagageTermPeriod = undefined;
    }
    this.addPercentageSymbol();
  }

  openNetAdvanceModal(): void {
    this.dialogService.matDialogContent({
      content: NetAdvanceDialogComponent,
      context: {
        mortgage: this.mortgage,
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result) {
          this.mortgageTerm.netAdvanceItems = result.netAdvanceItems;
          this.updateTrustLedgerMortgageRow();
          this.matter.recalcuateDelayedInterestDelayedAmountChange();
        }
      }
    });
  }

  get getMortgageeName() {
    if (this.currentMatter.mortgagees && this.currentMatter.mortgagees[ this.id ] && this.currentMatter.mortgagees[ this.id ].contact) {
      return this.currentMatter.mortgagees[ this.id ].contact.displayName;
    } else {
      return '';
    }
  }

  convertFrequencyLabeltoUpperCase(freq): string {
    if (freq) {
      return freq.replace('-', '_').toUpperCase();
    } else {
      return '';
    }
  }

  get mortgagerNames() {
    let result: string = '';
    let counter: number = 0;
    let clients: MatterParticipant[] = [];
    if (this.matter.isPurchase || this.matter.isMortgage) {
      clients = this.matter.mainClients;
    } else if (this.matter.isSale) {
      //confirmed by Edmund, for Sale Matter, use the Purchasers' data (TAB C)
      clients = this.matter.purchasers;
    }
    if (clients && clients.length > 0) {
      clients.forEach(client => {

          const contact: Contact = client.contact;
          if (contact) {
            switch (contact.gender) {
              case 'CORPORATION':
              case 'OTHERENTITY':
                if (clients.length == 1) {
                  result = contact.displayName;
                } else {
                  if (counter == (clients.length - 1)) {
                    result += 'and ' + contact.displayName;
                  } else {
                    if (clients.length == 2) {
                      result += contact.displayName + ' ';
                    } else {
                      result += contact.displayName + ', ';
                    }
                  }
                }
                break;

              case 'MALE':
              case 'FEMALE':
              case 'QUESTION':
              case 'MALEPOA':
              case 'FEMALEPOA':
              case 'ESTATE':
              default:
                if (clients.length == 1) {
                  result = contact.firstLastNames;
                } else {
                  if (counter == (clients.length - 1)) {
                    result += 'and ' + contact.firstLastNames;
                  } else {
                    if (clients.length == 2) {
                      result += contact.firstLastNames + ' ';
                    } else {
                      result += contact.firstLastNames + ', ';
                    }
                  }
                }
            }
          }
          counter = counter + 1;
        }
      );
    } else {
      result = '';
    }
    return result;
  }

  doMatterSaveAndOpenAmortizationScheduleModal(type): void {
    if (this.matter.dirty) {
      this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
        if (res && res == true) {
          let matterTab = this.tabService.activeTab as MatterTab;
          if (matterTab && matterTab.matterComponent) {
            let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
              if (result) {
                setTimeout(() => {
                  this.openAmortizationScheduleModal(type);
                }, 100);

                subscription.unsubscribe();
              }
            });
          }
        } else {
          console.log('cancel');
        }
      });
    } else {
      this.openAmortizationScheduleModal(type);
    }
  }

  openAmortizationScheduleModal(type): void {
    const mortgageIndex = this.matter.mortgages.findIndex(item => this.mortgage.id == item.id);
    let amortizationObject: any;
    if (type == 'REGULAR_PAYMENTS') {
      amortizationObject = {
        principal: Number(this.mortgageTerm.principal),
        interest: Number(this.mortgageTerm.interest),
        calculatedType: this.getCalculatedDropDownValue(this.mortgageTerm.calculated),
        paymentFrequencyType: this.convertFrequencyLabeltoUpperCase(this.mortgageTerm.paymentFrequency),
        amortizationPeriod: Number(this.mortgageTerm.regularPaymentAmortizationPeriod),
        payment: Number(this.mortgageTerm.regularPayment),
        autocalculated: this.mortgageTerm.regularPaymentAutoCalculated,
        paymentType: 'REGULAR_PAYMENTS',
        firstPaymentDate: this.mortgageTerm.firstPaymentDate,
        maturityDate: this.mortgageTerm.maturityDate,
        numOfPayments: 0,
        selectedPayments: null,
        mortgagee: this.matter.getMortgageePrivateLenderNamesByMortgageIndex(mortgageIndex),
        mortgagor: this.mortgagerNames,
        preparedBy: this.userSessionObj.legalFirmName,
        matterId: this.currentMatter.id,
        mortgageeType: this.mortgage.mortgageeType,
        mortgagePriority: (this.mortgage && this.mortgage.mortgagePriority) ? this.mortgage.mortgagePriority : null
      };

    } else {
      amortizationObject = {
        principal: Number(this.mortgageTerm.principal),
        interest: Number(this.mortgageTerm.interest),
        calculatedType: this.getCalculatedDropDownValue(this.mortgageTerm.calculated),
        paymentFrequencyType: this.convertFrequencyLabeltoUpperCase(this.mortgageTerm.alternatePaymentFrequency),
        amortizationPeriod: Number(this.mortgageTerm.alternatePaymentAmortizationPeriod),
        payment: Number(this.mortgageTerm.alternateRegularPayment),
        autocalculated: this.mortgageTerm.alternatePaymentAutoCalculated,
        paymentType: 'ALT_PAYMENTS',
        firstPaymentDate: this.mortgageTerm.alternateFirstPaymentDate,
        maturityDate: this.mortgageTerm.maturityDate,
        numOfPayments: 0,
        selectedPayments: null,
        mortgagee: this.matter.getMortgageePrivateLenderNamesByMortgageIndex(mortgageIndex),
        mortgagor: this.mortgagerNames,
        preparedBy: this.userSessionObj.legalFirmName,
        matterId: this.currentMatter.id,
        mortgageeType: this.mortgage.mortgageeType,
        mortgagePriority: (this.mortgage && this.mortgage.mortgagePriority) ? this.mortgage.mortgagePriority : null
      };
    }

    this.dialogService.matDialogContent({
      content: AmortizationScheduleDialogComponent,
      context: {
        amortizationObject: amortizationObject
      },
    });
  }

  public updateTrustLedgerMortgageRow(): void {
    if (this.mortgage && this.mortgage.mortgageTerm && this.mortgage.mortgageTerm.calculationMethod === 'CALCULATE_ADVANCE') {
      this.mortgage.mortgageTerm.totalNetAdvance = this.mortgageTerm.reCalculateNetAdvance();
    }
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateTrustLedgeMortgageValues(this.mortgageIndex - 1, this.mortgageTerm, this.mortgage);
      let trustLedgerMatter = this.matter.soaTrustLedgerCollection.getMatterTrustLedgerByMortgageIndex(this.mortgageIndex - 1);
      if (this.mortgageTerm && (this.mortgageTerm.isCalculationMethodFSpecificAmount() ||
        this.mortgageTerm.isCalculationMethodAdvance())) {
        this.matter.soaTrustLedgerCollection.addNewTrustLedgerMortgageItems(trustLedgerMatter);
      } else if (trustLedgerMatter) {
        trustLedgerMatter.itemizedMatterTrustLedgers = [];
      }

    }
  }

  public inMonths(d1, d2): number {

    let months: number = 0;
    months = (d2.split('/')[ 0 ] - d1.split('/')[ 0 ]) * 12;
    months -= Number(d1.split('/')[ 1 ]) + 1;
    months += Number(d2.split('/')[ 1 ]);

    return months < 0 ? 0 : months + 1;
  }

  getDateToSave(event): string {
    if (typeof event == 'string' && event == ON_DEMAND_VALUE) {
      return event;
    }
    return event.rawDate;
  }

  updatePercentageValue($event): void {
    if (this.mortgageTerm.interest == null) {
      return;
    }

    this.enableSave();
  }

  onModelValueChange(): void {
    console.log('<<>> onModelValueChange ...');
  }

  interestValueChanged(): void {
    this.updateInterestRelatedFields();
    this.addPercentageSymbol();
    this.mortgageInterestOnBlur();
    this.addOrRemoveLateClosingInterest();
  }

  updateInterestRelatedFields(): void {
    this.matter.updateFirstMortgageRelatedFields();
  }

  addOrRemoveLateClosingInterest(): void {
    if (this.matter.closingDatePayment) {
      this.matter.closingDatePayment.updateEstimateInterestAmt();
      this.matter.addOrRemoveLateClosingInterest();
    }

  }

  addPercentageSymbol(): void {
    // console.log("<<>> addPercentageSymbol ...")
    if (this.mortgageTerm.interest == null) {
      return;
    }

    if (!this.mortgageTerm.interest || (this.mortgageTerm.interest &&
      this.mortgageTerm.interest.toString().trim() === '')) {
      this.mortgageTerm.interest = '0.00000';
    } else if (this.mortgageTerm.interest) {
      let value = String(this.mortgageTerm.interest);
      if (value.indexOf('.') > -1 && value.split('.').length === 2 && value.split('.')[ 1 ].length < 5) {
        let length = value.split('.')[ 1 ].length;
        for (let i = 0; i < (5 - length); i++) {
          this.mortgageTerm.interest = this.mortgageTerm.interest + '0';
        }
      }
      if (value.indexOf('.') > -1 && value.split('.')[ 0 ].length < 1) {
        this.mortgageTerm.interest = '0' + this.mortgageTerm.interest;
      }
      if (value.indexOf('.') < 0) {
        this.mortgageTerm.interest = this.mortgageTerm.interest + '.00000';
      }
    }
    this.setVariablestoCalculateMortgage();
    this.setAltVariablestoCalculateMortgage();
  }

  calculateTotal(): void {
    this.mortgageTerm.calculateTotal();
  }

  calculateAltTotal(): void {
    this.mortgageTerm.calculateAltTotal();

  }

  isMortgageeUrlAvailable(): boolean {
    return this.matter && this.matter.getMortgagees(this.mortgage).length > 0 && this.matter.getMortgagees(this.mortgage)[ 0 ].contact && this.matter.getMortgagees(this.mortgage)[ 0 ].contact.linkToStandardChargeItem ? true : false;

  }

  openMortgageUrl(): void {
    window.open(this.matter.getMortgagees(this.mortgage)[ 0 ].contact.linkToStandardChargeItem);
  }

  taxesPaidThroughMortgageChange(): void {
    this.notifyChange();
    this.mortgageTerm.taxPortion = 0;
    this.calculateTotal();
    if (this.mortgageTerm.alternateFrequency == 'YES') {
      this.mortgageTerm.alternateTaxPortion = 0;
      this.calculateAltTotal();
    }
  }

  otherMortgageChange(): void {
    this.notifyChange();
    this.mortgageTerm.otherPayment = 0;
    this.calculateTotal();
    if (this.mortgageTerm.alternateFrequency == 'YES') {
      this.mortgageTerm.alternateOtherPayment = 0;
      this.calculateAltTotal();
    }
  }

  get currentMatter(): Matter {
    return this.matter;
  }

  getContactFieldPrefix(): string {
    let index = _.findIndex(this.matter.mortgages, function (mortgage) {
      return mortgage.id == this.mortgage.mortgageId;
    });
    return 'matter.mortgage.' + index + '.terms';
  }

  /* Following is F9 code */
  f9Values: any = {
    otherText: 'Life Insurance Premium',
    paymentDue: 'first day of each month',
    insuranceAmount: [ 'FULL_INSURABLE_VALUE', 'SEE_STANDARD_CHARGE_TERMS', 'OTHER' ],
    paymentFrequency: [ 'MONTHLY', 'SEMI_MONTHLY', 'BI_WEEKLY', 'WEEKLY' ],
    altPaymentFrequency: [ 'MONTHLY', 'SEMI_MONTHLY', 'BI_WEEKLY', 'WEEKLY' ]
  };

  tempInterest: string; // Save mortgageTerm.interest to a variable for restoring the value when toggle back

  displayNumber(): string {
    return this.mortgageTerm.interest != null ? 'block' : 'none';
  }

  displayNIL(): string {
    return this.mortgageTerm.interest == null ? 'block' : 'none';
  }

  captureF9ForPrincipal(): void {
    if (this.mortgage.loanType === 'ARRANGED' || this.mortgage.loanType === 'BRIDGE') {
      this.openNetAdvanceModal();
    }
  }

  captureF9ForInterestField(): void {
    this.tempInterest = this.mortgageTerm.interest;
    this.mortgageTerm.interest = null;
    this.setRegularPaymentToZero();
    this.enableSave();
    setTimeout(() => {
      this.interestNILElement.nativeElement.focus();
    }, 50);
  }

  regularPaymentAutoCalculatedChanged(): void {
    this.enableSave();
    this.setRegularPaymentToZero();
    this.updateAmortPeriod();
  }

  alternatePaymentAutoCalculatedChanged(): void {
    this.enableSave();
    this.setRegularPaymentToZero();
    this.updateAmortPeriod();
  }

  setRegularPaymentToZero() {
    if (this.mortgageTerm.interest === null && this.mortgageTerm.regularPaymentAutoCalculated === true) {
      this.mortgageTerm.regularPayment = 0;
      this.mortgageTerm.regularPaymentCalculated = 0;
    }
    if (this.mortgageTerm.interest === null && this.mortgageTerm.alternatePaymentAutoCalculated === true) {
      this.mortgageTerm.alternateRegularPayment = 0;
      this.mortgageTerm.alternateRegularPaymentCalculated = 0;
    }
  }

  captureF9ForInterestNILField(): void {
    if (this.tempInterest) {
      this.mortgageTerm.interest = this.tempInterest;
    } else {
      this.mortgageTerm.interest = ZERO_INTEREST;
    }

    this.enableSave();
    setTimeout(() => {
      this.interestElement.nativeElement.focus();
    }, 50);
  }

  captureF9ForOtherField(): void {
    this.mortgageTerm.otherText = this.f9Values[ 'otherText' ];
    this.enableSave();
  }

  captureF9ForpaymentsDueField(): void {
    let paymentFrequency = this.mortgageTerm.paymentFrequency || '';
    if (paymentFrequency.toUpperCase() == 'MONTHLY') {
      this.mortgageTerm.paymentsDue = this.f9Values[ 'paymentDue' ];
      this.enableSave();
    }
  }

  captureF9ForSctNo(): void {
    this.mortgageTerm.sctNo = SctNoF9Value.Several;
  }

  dynamicF9ForpaymentsDueField(): string {
    let paymentFrequency = this.mortgageTerm.paymentFrequency || '';
    if (paymentFrequency.toUpperCase() == 'MONTHLY') {
      return 'F9 = ' + this.f9Values[ 'paymentDue' ];
    }
  }

  getCustomInterestAdjustmentDate(): any {
    if (this.matter.isMatterProvinceBC) {
      return this.getCustomMaturityDate();
    }
    let interestAdjustmentDate: any = {dd: '', mm: '', yy: '', type: 'InterestAdjustmentDate'};
    let matterCloseDate = this.matter.matterCloseDate;
    if (!matterCloseDate) {
      return interestAdjustmentDate;
    }

    let closingDate = (() => {
      let matterCloseDateParts = matterCloseDate.split('/');
      return {
        yy: matterCloseDateParts[ 0 ],
        mm: matterCloseDateParts[ 1 ],
        dd: matterCloseDateParts[ 2 ]
      };
    })();

    if (!closingDate.dd || !closingDate.mm || !closingDate.yy) {
      return interestAdjustmentDate;
    }

    // Logic for day
    if (this.mortgage && this.mortgage.loanType !== 'ASSUMED') {
      let paymentsDue = this.mortgageTerm.paymentsDue;
      if (paymentsDue && paymentsDue.trim()) {
        paymentsDue = paymentsDue.trim();
        if (paymentsDue.indexOf('first') >= 0) {
          interestAdjustmentDate.dd = '01';
        } else if (paymentsDue.length == 1) {
          let oneDigitDay = this.get1DigitDay(paymentsDue);
          if (+oneDigitDay >= 1 && +oneDigitDay <= 9) {
            interestAdjustmentDate.dd = oneDigitDay;
          } else {
            interestAdjustmentDate.dd = closingDate.dd;
          }
        } else if (paymentsDue.length >= 2) {
          let oneDigitDay = this.get1DigitDay(paymentsDue);
          let twoDigitDay = this.get2DigitDay(paymentsDue);
          if (+twoDigitDay >= 10 && +twoDigitDay <= 31) {
            interestAdjustmentDate.dd = twoDigitDay;
          } else if (+oneDigitDay >= 1 && +oneDigitDay <= 9) {
            interestAdjustmentDate.dd = oneDigitDay;
          } else {
            interestAdjustmentDate.dd = closingDate.dd;
          }
        } else {
          interestAdjustmentDate.dd = closingDate.dd;
        }
      } else {
        interestAdjustmentDate.dd = closingDate.dd;
      }
    } else {
      return {dd: '', mm: '', yy: '', type: 'InterestAdjustmentDate'};
    }

    // Logic for month
    if (+interestAdjustmentDate.dd >= +closingDate.dd) {
      interestAdjustmentDate.mm = closingDate.mm;
    } else if (closingDate.mm == '12') {
      interestAdjustmentDate.mm = '01';
    } else {
      interestAdjustmentDate.mm = +closingDate.mm + 1 + '';
    }

    // Logic for year
    if ((interestAdjustmentDate.mm == '01' || interestAdjustmentDate.mm == '1') && closingDate.mm == '12') {
      interestAdjustmentDate.yy = +closingDate.yy + 1 + '';
    } else {
      interestAdjustmentDate.yy = +closingDate.yy + '';
    }

    interestAdjustmentDate.dd = this.padFront(interestAdjustmentDate.dd);
    interestAdjustmentDate.mm = this.padFront(interestAdjustmentDate.mm);

    return interestAdjustmentDate;
  }

  getCustomFirstPaymentDate(): any {
    if (this.matter.isMatterProvinceBC) {
      return this.getCustomMaturityDate();
    }
    let firstPaymentMoment: Moment;
    let firstPaymentDate: any = {dd: '', mm: '', yy: '', type: 'FirstPaymentDate'};

    if (this.mortgageTerm.interestAdjustmentDate && this.mortgageTerm.paymentFrequency) {
      let paymentFrequency = this.mortgageTerm.paymentFrequency;
      let interestAdjustmentMoment: Moment = moment(this.mortgageTerm.interestAdjustmentDate, 'YYYY/MM/DD');

      // DPPMP-9636: || !interestAdjustmentMoment.month() delted from IF statement. January = 0 in Moments library
      if (!interestAdjustmentMoment.date() || !interestAdjustmentMoment.year()) {
        return firstPaymentDate;
      }

      firstPaymentMoment = moment(interestAdjustmentMoment.toDate());

      if (paymentFrequency.toUpperCase() == 'MONTHLY') {
        firstPaymentMoment.add(1, 'months');
      } else if (paymentFrequency.toUpperCase() == 'SEMI_MONTHLY') {
        if (interestAdjustmentMoment.date() == 1) {
          firstPaymentMoment.add(14, 'days');
        } else if (interestAdjustmentMoment.date() == 15) {
          let daysInMonth: number = firstPaymentMoment.daysInMonth();

          firstPaymentMoment.subtract(14, 'days').add(daysInMonth, 'days');
        } else {
          firstPaymentMoment = null;
        }
      } else if (paymentFrequency.toUpperCase() == 'BI_WEEKLY') {
        firstPaymentMoment.add(14, 'days');
      } else if (paymentFrequency.toUpperCase() == 'WEEKLY') {
        firstPaymentMoment.add(7, 'days');
      }
    }

    if (firstPaymentMoment) {
      firstPaymentDate.yy = firstPaymentMoment.year().toString();
      firstPaymentDate.mm = this.padFront((firstPaymentMoment.month() + 1).toString());
      firstPaymentDate.dd = this.padFront(firstPaymentMoment.date().toString());
    }

    return firstPaymentDate;
  }

  getCustomMaturityDate(): any {
    let maturityDate: any = {dd: '', mm: '', yy: '', type: 'MaturityDate'};

    if (this.mortgageTerm.interestAdjustmentDate) {
      let interestAdjustmentMoment: Moment = moment(this.mortgageTerm.interestAdjustmentDate, 'YYYY/MM/DD');

      if (interestAdjustmentMoment.date()) {
        maturityDate.dd = this.padFront(interestAdjustmentMoment.date().toString()),
          maturityDate.mm = this.padFront((interestAdjustmentMoment.month() + 1).toString()),
          maturityDate.yy = '';
      }
    }
    return maturityDate;
  }

  setFieldValue(field, elementId) {
    let values = this.f9Values[ elementId ];
    this.mortgageTerm[ field ] = values.shift();
    values.push(this.mortgageTerm[ field ]);
  }

  get1DigitDay(paymentsDue: string): string {
    return paymentsDue.charAt(0);
  }

  get2DigitDay(paymentsDue: string): string {
    return paymentsDue.substring(0, 2);
  }

  padFront(digits: string) {
    if (digits && digits.length == 1) {
      return '0' + digits;
    } else {
      return digits;
    }
  }

  updateAmortPeriod(): void {

    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.amortization');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.paymentFrequency.MISSING');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.paymentFrequency.INVALID');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.compundingPeriod');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.amortization');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.MISSING');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.INVALID');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.compundingPeriod');

    if (this.mortgageTerm.regularPaymentAutoCalculated) {
      this.mortgageTerm.regularPaymentCalculated = 0.00;
      this.mortgageTerm.regularPaymentAmortizationPeriod = !this.mortgageTerm.regularPaymentAmortizationPeriod ? 25 : this.mortgageTerm.regularPaymentAmortizationPeriod;
      this.setVariablestoCalculateMortgage();

    }
    if (this.mortgageTerm.alternatePaymentAutoCalculated) {
      this.mortgageTerm.alternateRegularPaymentCalculated = 0.00;
      this.mortgageTerm.alternatePaymentAmortizationPeriod = !this.mortgageTerm.alternatePaymentAmortizationPeriod ? 25 : this.mortgageTerm.alternatePaymentAmortizationPeriod;
      this.setAltVariablestoCalculateMortgage();
    }
  }

  getRegularPaymentCalculated(): number {
    return this.mortgageTerm.regularPaymentCalculated ? this.mortgageTerm.regularPaymentCalculated : this.mortgageTerm.regularPayment;
  }

  getAltRegularPaymentCalculated(): number {
    return this.mortgageTerm.alternateRegularPaymentCalculated ? this.mortgageTerm.alternateRegularPaymentCalculated : this.mortgageTerm.alternateRegularPayment;
  }

  public clearAllPaymentError(): void {
    this.clearAllRegularPaymentError();
    this.clearAllAltRegularPaymentError();
  }

  public clearAllRegularPaymentError(): void {
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.amortization');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.paymentFrequency.MISSING');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.paymentFrequency.INVALID');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.compundingPeriod');
  }

  public clearAllAltRegularPaymentError(): void {
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.amortization');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.MISSING');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.INVALID');
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.compundingPeriod');
  }

  interestOnlyChanged(): void {
    if (this.mortgageTerm && this.mortgageTerm.isInterestOnlyYes()) {
      this.mortgageTerm.calculated = 'ANNUALLY';
      this.mortgageTerm.regularPaymentAutoCalculated = false;
      this.mortgageTerm.alternatePaymentAutoCalculated = false;
      this.regularPaymentAutoCalculatedChanged();
      this.alternatePaymentAutoCalculatedChanged();
      this.calculatedChanged();
    } else {
      this.setVariablestoCalculateMortgage();
      this.setAltVariablestoCalculateMortgage();
    }
    this.notifyChange();

  }

  calculatedChanged(): void {
    this.setVariablestoCalculateMortgage();
    this.setAltVariablestoCalculateMortgage();
    this.doCalculationRules();
    this.enableSave();
  }

  setVariablestoCalculateMortgage() {

    this.clearAllRegularPaymentError();

    if (this.mortgageTerm.regularPaymentAutoCalculated) {

      let paymentFrequencyDrpDown = this.checkPaymentFrequency(this.mortgageTerm.paymentFrequency);
      if (this.mortgageTerm.principal >= 0 && this.mortgageTerm.principal != null
        && this.mortgageTerm.interest != null && paymentFrequencyDrpDown
        && this.mortgageTerm.regularPaymentAmortizationPeriod > 0
        && this.mortgageTerm.regularPaymentAmortizationPeriod != null
        && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != ''
        && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != null
        && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != 'NO_COMPOUNDING') {
        if (this.mortgageTerm.isInterestOnlyYes()) {
          this.mortgageTerm.regularPaymentCalculated =
            this.mortgagePaymentService.calculateMortgagePaymentForInterestOnly(this.mortgageTerm.principal, Number(this.mortgageTerm.interest), paymentFrequencyDrpDown.value);
        } else {
          this.mortgageTerm.regularPaymentCalculated =
            this.mortgagePaymentService.calculateMortgage(this.mortgageTerm.principal, this.mortgageTerm.regularPaymentAmortizationPeriod,
              Number(this.mortgageTerm.interest), paymentFrequencyDrpDown.value, this.getCalculatedDropDownValue(this.mortgageTerm.calculated));
        }

        this.mortgageTerm.regularPayment = this.mortgageTerm.regularPaymentCalculated;
        this.mortgageTerm.calculateTotal();
        this.mortgagePaymentService.calculateAmortizationSchedule(this.mortgageTerm.principal, Number(this.mortgageTerm.interest), this.mortgageTerm.regularPaymentAmortizationPeriod, this.mortgageTerm.regularPaymentCalculated, paymentFrequencyDrpDown.value, this.getCalculatedDropDownValue(this.mortgageTerm.calculated));
      } else {
        this.mortgageTerm.regularPaymentCalculated = 0.00;
        if (!this.mortgageTerm.regularPaymentAmortizationPeriod || this.mortgageTerm.regularPaymentAmortizationPeriod == null || this.mortgageTerm.regularPaymentAmortizationPeriod < 1 || isNaN(this.mortgageTerm.regularPaymentAmortizationPeriod)) {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.amortization'));
        }
        if (!this.mortgageTerm.paymentFrequency || this.mortgageTerm.paymentFrequency == null || (this.mortgageTerm.paymentFrequency && this.mortgageTerm.paymentFrequency.trim() == '')) {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.paymentFrequency.MISSING'));
        } else if (!paymentFrequencyDrpDown) {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.paymentFrequency.INVALID'));
        }
        if (this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == undefined || this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == null || this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == 'NO_COMPOUNDING') {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.compundingPeriod'));
        }
      }
    }
  }

  get mortgageIndex(): number {
    let index = this.matter.mortgages.findIndex(item => item.id == this.mortgage.id);
    return index >= 0 ? (Number(index) + 1) : index;
  }

  setAltVariablestoCalculateMortgage() {

    this.clearAllAltRegularPaymentError();

    if (this.mortgageTerm.alternatePaymentAutoCalculated && this.mortgageTerm.alternateFrequency == 'YES') {
      let altPaymentFrequencyDrpDown = this.checkPaymentFrequency(this.mortgageTerm.alternatePaymentFrequency);
      if (this.mortgageTerm.principal >= 0 && this.mortgageTerm.principal != null
        && this.mortgageTerm.interest != null && altPaymentFrequencyDrpDown
        && this.mortgageTerm.alternatePaymentAmortizationPeriod > 0
        && this.mortgageTerm.alternatePaymentAmortizationPeriod != null
        && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != ''
        && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != null
        && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != 'NO_COMPOUNDING') {
        if (this.mortgageTerm.isInterestOnlyYes()) {
          this.mortgageTerm.alternateRegularPaymentCalculated =
            this.mortgagePaymentService.calculateMortgagePaymentForInterestOnly(this.mortgageTerm.principal, Number(this.mortgageTerm.interest), altPaymentFrequencyDrpDown.value);
        } else {
          this.mortgageTerm.alternateRegularPaymentCalculated =
            this.mortgagePaymentService.calculateMortgage(this.mortgageTerm.principal, this.mortgageTerm.alternatePaymentAmortizationPeriod, Number(this.mortgageTerm.interest), altPaymentFrequencyDrpDown.value, this.getCalculatedDropDownValue(this.mortgageTerm.calculated));
        }

        this.mortgageTerm.alternateRegularPayment = this.mortgageTerm.alternateRegularPaymentCalculated;
        this.mortgageTerm.calculateAltTotal();
      } else {
        this.mortgageTerm.alternateRegularPaymentCalculated = 0.00;
        if (!this.mortgageTerm.alternatePaymentAmortizationPeriod || this.mortgageTerm.alternatePaymentAmortizationPeriod == null || this.mortgageTerm.alternatePaymentAmortizationPeriod < 1) {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.alternate.amortization'));
        }
        if (!this.mortgageTerm.alternatePaymentFrequency || this.mortgageTerm.alternatePaymentFrequency == null || (this.mortgageTerm.alternatePaymentFrequency && this.mortgageTerm.alternatePaymentFrequency.trim() == '')) {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.MISSING'));
        } else if (!altPaymentFrequencyDrpDown) {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.INVALID'));
        }
        if (this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == undefined || this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == null || this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == 'NO_COMPOUNDING') {
          this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.compundingPeriod'));
        }
      }
    }
  }

  checkPaymentFrequency(paymentFrequency: string): any {
    return dropDowns.paymentFrequency.find(item => paymentFrequency && paymentFrequency != null && item.label.toLowerCase() == paymentFrequency.toLowerCase());
  }

  getCalculatedDropDownValue(calculatedLabel: string): any {
    let calculatedSelected: any = dropDowns.calculated.find(item => calculatedLabel && calculatedLabel != null && item.value.toLowerCase() == calculatedLabel.toLowerCase());
    if (calculatedSelected == null || calculatedSelected == '' || calculatedSelected == undefined) {
      return '';
    } else {
      return calculatedSelected.value;
    }

  }

  updateAlternateFrequency(): void {
    if (this.mortgageTerm.alternateFrequency != 'YES') {
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.amortization');
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.MISSING');
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.alternate.paymentFrequency.INVALID');
    } else {
      this.setAltVariablestoCalculateMortgage();
    }

  }

  updateRegularPayments(): void {
    if (!this.isRegularPaymentsDefaultOrYes()) {
      this.clearAllPaymentError();
    } else {
      this.setVariablestoCalculateMortgage();
      this.setAltVariablestoCalculateMortgage();
    }
  }

  handleDropdownClickPaymentFrequency = () => this.filteredPaymentFrequency = this.paymentFrequency.map(item => item.label);

  filterPaymentFrequency(event) {
    this.filteredPaymentFrequency = this.paymentFrequency.map(item => item.label).filter(label => label && label.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
  }

  handleDropdownClickCalculated = () => this.filteredCalculated = this.calculated.map(item => item.label);

  filterCalculated(event) {
    this.filteredCalculated = this.calculated.map(item => item.label).filter(label => label && label.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
  }

  doCalculationRules() {
    // In the event the Calculated field contains a manually entered value then the following rules will be applied
    if (this.getCalculatedDropDownValue(this.mortgageTerm.calculated) == '') {
      this.mortgageTerm.regularPaymentAutoCalculated = false;
      this.mortgageTerm.alternatePaymentAutoCalculated = false;
    }
  }

  getFieldCodeMapForGuarantor(type: string, matterParticipant: MatterParticipant): any {
    if (type == 'LAST_NAME') {
      return {lastName: GuarantorLastNameFieldCodes[ this.mortgageIndex - 1 ][ this.matter.getGuarantors(this.mortgage).findIndex(item => item == matterParticipant) ]};

    }
  }

  get autoCalcRegularPaymentsAvailable(): boolean {
    return this.isRegularPaymentsDefaultOrYes() && this.mortgageTerm.variableInterestRate != 'YES' && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != '';

  }

  mortgagePrincipalOnBlur() {

    this.setVariablestoCalculateMortgage();
    this.setAltVariablestoCalculateMortgage();
    this.updateTrustLedgerMortgageRow();

    this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
    this.matter.recalcuateDelayedInterestRegMortgageAmountChange();
    this.matter.recalcuateDelayedInterestDelayedAmountChange();
    this.matter.recalculateProjectSaleConsiderationGivenBackToVendor();
    this.matter.updateMortgageRegistrationFee();
  }

  mortgageInterestOnBlur() {
    this.mortgageSoAdjService.updateInterimOccupancyFeeAdj(this.matter);
  }

  insertPrecedent(insertTo: string) {
    this.precedentCoreAction('INSERT', insertTo);
  }

  saveAsPrecedent(contentFrom: string) {
    this.precedentCoreAction('SAVE', contentFrom);
  }

  precedentCoreAction(precedentAction: string, insertTo: string) {
    let additionalClause: string = '';
    if (insertTo === 'BOX6') {
      additionalClause = this.mortgageTerm.box6AdditionalProvisions;
    } else if (insertTo === 'MTG') {
      additionalClause = this.mortgageTerm.mortgageDescription;
    } else if (insertTo === 'LOAN') {
      additionalClause = this.mortgageTerm.loanDescription;
    } else if (insertTo === 'PRIME') {
      additionalClause = this.mortgageTerm.primeIsDefinedAs;
    }
    this.dialogService.matDialogContent({
      content: MortgagePrecedentComponent,
      context: {
        additionalClause: additionalClause,
        action: precedentAction,
        caller: PrecedentTypes.MORTGAGE
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'ok') {//if  modal is closed after save
            if (precedentAction === 'INSERT' && result.additionalClause) {
              this.doInsertPrecedent(result.additionalClause, insertTo);
              this.notifyChange();
            }
          } else {
            //    For now, do nothing
          }
        }
      },

    });
  }

  updateCursorPositionBox6(e: MouseEvent): void {
    this.box6SelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  updateCursorPositionMtgDesc(e: MouseEvent): void {
    this.mtgDescSelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  updateCursorPositionLoanDesc(e: MouseEvent): void {
    this.loanDescSelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  updateCursorPositionPrimeText(e: MouseEvent): void {
    this.primeSelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  doInsertPrecedent(stringToInsert: string, insertTo: string): void {
    if (insertTo === 'BOX6') {
      const box6Text = this.mortgageTerm.box6AdditionalProvisions;
      this.mortgageTerm.box6AdditionalProvisions = this.box6SelectionStart ?
        `${ box6Text.slice(0, this.box6SelectionStart) } ${ stringToInsert } ${ box6Text.slice(this.box6SelectionStart, box6Text.length) }` : stringToInsert;
    } else if (insertTo === 'MTG') {
      const mtgDesc = this.mortgageTerm.mortgageDescription;
      this.mortgageTerm.mortgageDescription = (this.mtgDescSelectionStart ?
        `${ mtgDesc.slice(0, this.mtgDescSelectionStart) } ${ stringToInsert } ${ mtgDesc.slice(this.mtgDescSelectionStart, mtgDesc.length) }` : stringToInsert).slice(0, 130);
    } else if (insertTo === 'LOAN') {
      const loanDesc = this.mortgageTerm.loanDescription;
      this.mortgageTerm.loanDescription = (this.loanDescSelectionStart ?
        `${ loanDesc.slice(0, this.loanDescSelectionStart) } ${ stringToInsert } ${ loanDesc.slice(this.loanDescSelectionStart, loanDesc.length) }` : stringToInsert).slice(0, 130);
    } else if (insertTo === 'PRIME') {
      const primeText = this.mortgageTerm.primeIsDefinedAs;
      this.mortgageTerm.primeIsDefinedAs = (this.primeSelectionStart ?
        `${ primeText.slice(0, this.primeSelectionStart) } ${ stringToInsert } ${ primeText.slice(this.primeSelectionStart, primeText.length) }` : stringToInsert).slice(0, 130);
    }
  }

  isNetAdvanceVisible(): boolean {
    return !this.matter.isProjectSale && (this.mortgage.isLoanTypeArranged() || this.mortgage.isLoanTypeBridge());
  }

  ngAfterViewInit() {
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgageIndex: number) {
    this.matter = matter;
    this.id = mortgageIndex;
  }

  isAmortizationScheduleVisible(): boolean {
    return this.mortgageTerm && this.mortgageTerm.variableInterestRate != 'YES' && this.getCalculatedDropDownValue(this.mortgageTerm.calculated) != '' && !this.mortgageTerm.isInterestOnlyYes();
  }

  getVariableRateLabel(term: MortgageTerm) {
    return this.matter?.isMatterProvinceBC
      ? 'Interest Rate'
      : term.variableInterestRate == 'YES'
        ? 'Variable Rate'
        : "Interest";
  }
}
