import {ChecklistCategory, ChecklistTemplateConfig} from '../../admin/checklist-templates/checklist-template-config';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ChecklistTemplateService} from '../../admin/checklist-templates/checklist-template.service';
import {Matter, matterApi} from '../shared';
import {Injectable} from '@angular/core';
import {MatterWorkItem, MatterWorkItemTask, WorkItemTaskStatusValues} from '../shared/matter-work-item';
import * as _ from 'lodash';
import {UnityMessage} from '../../messages/unity-message';
import {Observable} from 'rxjs/Rx';
import {HttpClient} from '../../core';
import {MatterNotificationConfig} from '../../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config';
import {UUIDUtil} from '../../main/uuid-util';

@Injectable()
export class MatterWorkItemsUtilsService {
  constructor(public checklistTemplateService: ChecklistTemplateService, public http: HttpClient) {

  }

  getAllChecklistTemplates(matter: Matter): Promise<ChecklistTemplateConfig[]> {
    if (matter) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      return this.checklistTemplateService.getAllChecklistTemplates(Number(accountId), matter.provinceCode, matter.derivedMatterType).toPromise();
    }
  }

  getDefaultChecklistTemplate(matter: Matter): Promise<ChecklistTemplateConfig> {
    if (matter) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      return this.checklistTemplateService.getDefaultChecklistTemplate(Number(accountId), matter.provinceCode, matter.derivedMatterType).toPromise();
    }
  }

  buildWorkItemsFromChecklistTemplate(template: ChecklistTemplateConfig): MatterWorkItem[] {
    let matterWorkItems = [];
    if (template && template.categories && template.categories.length) {
      let sortedCategories = _.orderBy(template.categories, [ 'order' ], [ 'asc' ]);
      sortedCategories.forEach((category: ChecklistCategory) => {
        let workItem = new MatterWorkItem();
        workItem.name = category.name;
        if (category.tasks && category.tasks.length) {
          workItem.matterWorkItemTasks = [];
          workItem.tasksNotificationConfig = [];
          let sortedTasks = _.orderBy(category.tasks, [ 'order' ], [ 'asc' ]);
          sortedTasks.forEach((task) => {
            let workItemTask = new MatterWorkItemTask();
            workItemTask.id = UUIDUtil.getUUID();
            workItemTask.name = task.name;
            workItemTask.status = WorkItemTaskStatusValues.notStarted;
            workItem.matterWorkItemTasks.push(workItemTask);
            category.tasksNotificationConfig.filter(tc => tc.taskIdentifier == task.id).forEach((notification) => {
              let matterNotificationConfig = new MatterNotificationConfig(notification);
              matterNotificationConfig.id = UUIDUtil.getUUID();
              matterNotificationConfig.taskIdentifier = workItemTask.id;
              workItem.tasksNotificationConfig.push(matterNotificationConfig);
            });
          });
        }
        matterWorkItems.push(workItem);
      });
    }
    return matterWorkItems;
  }

  postAssignedWorkItemMessage(unityMessage: UnityMessage, userId: number): Observable<UnityMessage> {
    let url = `${ matterApi.sendUnityMessage(userId) }`;
    return this.http.post(url, unityMessage)
    .map((response) => {
      return response;
    });
  }
}
