<form class="form-horizontal">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-7">
                <h1>Teranet : Change Field Code</h1>
            </div>
        </div>
        <div class="row modal-body">
            <div class="form-group">
                <div class="col-lg-12">
                    <label class="control-label col-lg-4">Field Code</label>
                    <div class="col-lg-2">
                        <input type="text"
                               [(ngModel)]="fieldCode"
                               dp-uppercase
                               name="fieldCodeInput"
                               id="fieldCodeInput"
                               class="form-control"/>
                    </div>
                </div>
                <div class="col-lg-12">
                    <label class="control-label">Help :</label>
                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-1">?</label>
                    <label class="control-label col-lg-11 text-left line-height-18">Use ? when you have field code which is same across matter types
                        <br> For example: Matter No on Matter Opening tab has field code PA/1 for purchase, SA/2 for sale, MA/2 for mortgage matters...
                        <br> For such field you can add field code like <span class="boldfont">?A/2</span>
                        <br> ? represents your matter type which is P for purchase, S for sale, M for mortgage... </label>
                </div>
                <div class="col-lg-12">
                    <label class="control-label col-lg-1">#</label>
                    <label class="control-label col-lg-11 text-left line-height-18">Use # when you have field code which is same across mortgages
                        <br> For example: Mortgage Loan No on Mortgage tab has field code PG1/112 for mortgage 1, PG2/112  for mortgage 2, etc.
                        <br> For such field you can add field code like <span class="boldfont">?G#/112</span>
                        <br> ? represents your matter type which is P for purchase, S for sale, M for mortgage...
                        <br> # represents your mortgage number, indicating 1st mortgage, 2nd mortgage, etc. </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button id="okBtn" class="dp-btn" type="button" (click)="ok()" >
                OK
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </div>
</form>


