import {Matter} from '../../shared/matter';
import {MatterParticipant} from '../../shared/matter-participant';
import {MatterProperty} from '../../shared/matter-property';
import {DirectionReFund} from '../direction-re-funds';

export class DirectionReFundsPreview {
  //Header
  matterParticipants: MatterParticipant[];
  matterProperties: MatterProperty[] = [];
  vendorRELine: string;
  purchaserRELine: string;
  vendorDataCode: string;
  purchaserDataCode: string;
  addressDataCode: string;
  closingDate: string;
  //Items
  lines: DirectionReFund[] = [];
  //footer
  sufficientAuthority: string;
  totalName: string;
  totalAmount: string;
  firmName: string;
  //Pare size
  paperSizeCode: string;
  provinceCode: string;
  interimDirectionReFunds: boolean = false;
  hasInterimAndFinal: boolean = false;

  constructor(matter?: Matter) {
    if (matter) {
      this.lines = matter.filteredDirectionReFunds;
      this.matterParticipants = matter.matterParticipants;
      this.matterProperties = matter.matterProperties;
      this.provinceCode = matter.provinceCode;

    }
  }
}
