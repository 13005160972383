<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Additional Filters</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 padding-top-20">
            <div class="col-lg-4" >
                <div *ngIf="matterListState.lawyersList.length > 0">
                    <dp-multi-select [multiSelectData]="matterListState.lawyersList"
                         [inputName]="lawyerTitle"
                         [title]="'No '+ lawyerTitle +' Selected'"
                         [multiSelectedTitle]="lawyerTitleInPlural"
                         [showLabelInTitle]="true"
                         [splitLabelLawyersAndClerks]="true"
                         [multiSelectDataSelected]="matterListState.selectedLawyers"
                         (updateMultiSelect)="updateMultiSelectFilters($event,'lawyer')"
                         class="lawyer-multi-select"></dp-multi-select>
                </div>
            </div>
            <div class="col-lg-4">
                <div *ngIf="matterListState.clerksList.length > 0 ">
                    <dp-multi-select [multiSelectData]="matterListState.clerksList"
                         [inputName]="lawClerkTitle"
                         [title]="'No '+ lawClerkTitle +' Selected'"
                         [multiSelectedTitle]="'Multiple ' + lawClerkTitle +'s'"
                         [showLabelInTitle]="true"
                         [splitLabelLawyersAndClerks]="true"
                         [multiSelectDataSelected]="matterListState.selectedClerks"
                         (updateMultiSelect)="updateMultiSelectFilters($event,'clerk')"
                         class="clerk-multi-select"></dp-multi-select>
                </div>
            </div>
            <div class="col-lg-4">
                <div *ngIf="!context.isMassUpdate && matterListState.projectList.length > 0 &&
                matterListState.isProjectSaleMatterTypeFilterSelected">
                    <dp-multi-select [multiSelectData]="matterListState.projectList"
                                     [inputName]="'Project'"
                                     [title]="'No Projects Selected'"
                                     [multiSelectedTitle]="'Multiple Projects'"
                                     [showLabelInTitle]="true"
                                     [multiSelectDataSelected]="matterListState.selectedProjects"
                                     (updateMultiSelect)="updateMultiSelectFilters($event,'projects')"
                                     class="clerk-multi-select project-multi-select"></dp-multi-select>
                </div>
            </div>
        </div>
        <div class="col-lg-12 padding-top-10">
            <div class="col-lg-6" *ngIf="context.accountAccessibleProvinces && context.accountAccessibleProvinces.length > 1 && !context.isMassUpdate">
                <div class="col-lg-12 margin-left-10">
                    <dp-multi-select [multiSelectData]="context.provinceCode" [inputName]="'province'" [title]="'Province'"
                                     [showLabelInTitle]="false"
                                     [multiSelectDataSelected]="matterListState.selectedProvinceCode"
                                     (updateMultiSelect)="updateMultiSelectFilters($event,'province')"
                                     [listStyle]="true"></dp-multi-select>
                </div>

            </div>
        </div>
        <div class="col-lg-12 padding-top-10 padding-bottom-20 padding-top-30">
            <div class="col-lg-7" >
                <div class="filter-group">
                    <h5 class="boldfont">Action Required Filters</h5>
                    <div class="col-lg-12 margin-left-10">
                        <dp-multi-select [multiSelectData]="context.actionRequiredFilters" [inputName]="'action_required'"
                                         [title]="'Action Required Filters'"
                                         [showLabelInTitle]="false" [defaultToAll]="false"
                                         [multiSelectDataSelected]="matterListState.selectedActionRequiredFilters"
                                         (updateMultiSelect)="updateMultiSelectFilters($event,'action_required')"
                                         [listStyle]="true"></dp-multi-select>
                    </div>
                </div>
            </div>
            <div class="col-lg-5" >
                <div class="filter-group">
                    <h5 class="boldfont">Task-related Filters</h5>

                    <div class="form-group margin-left-10">
                        <label class="control-label padding-bottom-10">Select matters with one or more tasks whose status is</label>
                        <div class="col-lg-12" *ngFor="let taskFilter of taskStatusFilters">
                            <div class="radio col-lg-12">
                                 <span class="radio display-inline" (click)="changeTaskRelatedFilter(taskFilter)">
                                  <input type="radio" [checked]="matterListState.taskStatusFilter == taskFilter.value">
                                  <label>{{taskFilter.label}}</label>
                                 </span>
                            </div>
                        </div>
                    </div>

                    <h5 class="col-lg-12">Optionally limited to those where Task Description is</h5>
                    <div class="col-lg-12 margin-bottom-200 padding-top-10" >
                        <input type="text" class="form-control rightPadding482 preventDoubleClick inline-block"
                               *ngIf="matterListState.taskDescription"
                               [(ngModel)]="matterListState.taskDescription"
                               (change)="isMatterListStateUpdates=true"
                               id="selectedTask" />
                        <span class="trash-icon" *ngIf="matterListState.taskDescription">
                                <i class="fa fa-trash"
                                   (click)="removeSelectedTask()"
                                   aria-hidden="true"
                                   tabindex="0"
                                   (keyup.enter)="removeSelectedTask()"
                                >
                                </i>
                            </span>
                        <p-autoComplete *ngIf="!matterListState.taskDescription"
                                        placeholder='Search by Task Description'
                                        id="omniSearch"
                                        name="omniSearch"
                                        [(ngModel)]="taskSearchText"
                                        [class.autocomplete-searching]="tasksLoading"
                                        [suggestions]="taskSuggestions"
                                        (completeMethod)="searchTasks($event)"
                                        field="fullName"
                                        [minLength]="0"
                                        [disabled]="!matterListState.taskStatusFilter"
                                        delay="1000"
                                        maxlength="75"
                                        scrollHeight="500px"
                                        (focusout)="omniSearchFocusOut()"
                                        (onSelect)="dataSelectedTask(taskSearchText)"
                                        autocomplete="off" class="autocomplete-search-icon">

                            <ng-template let-task pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                    [disableItem]="task">
                                    <span class="text-overflow"
                                          *ngIf="!autoCompleteEmptyFlag">{{ task}}</span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>Apply</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>

