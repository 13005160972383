<form class="form-horizontal container-fluid custom-modal-container" id="totalFeeAdjForm" *ngIf="localAdjustment" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                 <h1>Adjustment Type: Total Occupancy Fees (or component) during Occupancy period</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <ng-container *ngIf="localAdjustment">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">Total</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="totalType"
                                name="totalType"
                                [(ngModel)]="localAdjustment.totalType"
                                (ngModelChange)="onTotalTypeChange()"
                                class="form-control"
                        >
                            <option *ngFor="let tt of totalTypes" [value]="tt.value">
                                {{tt.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">Credit {{purchaserLabel}} or {{vendorLabel}}?</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="creditTargetType"
                                name="creditTargetType"
                                [(ngModel)]="localAdjustment.creditTo"
                                (ngModelChange)="onCreditToChange()"
                                class="form-control"
                        >
                            <option *ngFor="let ctt of creditToTypes" [value]="ctt.value">
                                {{ctt.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{useAmountFromLabel}}</label>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="useAmountFrom"
                                name="useAmountFrom"
                                [(ngModel)]="localAdjustment.useAmountFrom"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div  class="form-group">
                <div  class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{amountOfMonthlyLabel}}</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text"
                               name="amount"
                               [(ngModel)]="localAdjustment.amount"
                               [dp-read-only]="localAdjustment.useAmountFromBoolean"
                               dp-currency
                               dp-default-currency
                               class="form-control text-right"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{purchaserHasPaidFeeLabel}}</label>
                    </div>
                    <div class="col-lg-6">
                        <select type="text"
                                id="purchaserHasPaidFee"
                                name="purchaserHasPaidFee"
                                [(ngModel)]="localAdjustment.purchaserHasPaidFee"
                                (ngModelChange)="onPurchaserHasPaidFeeChange()"
                                class="form-control"
                        >
                            <option *ngFor="let phpf of purchaserHasPaidFeeTypes" [value]="phpf.value">
                                {{phpf.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="isPaidToVisible || isPaidFromVisible">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{paidFromLabel}}</label>
                    </div>
                    <div class="col-lg-2" *ngIf="isPaidFromVisible">
                        <dp-partial-date #paidFrom
                                         name="paidFromId"
                                         id="paidFromId"
                                         (dateChange)="onDateChange($event ,'paidFrom')"
                                         [inputDate]="localAdjustment.paidFrom"
                        >
                        </dp-partial-date>
                    </div>
                    <div class="col-lg-1" *ngIf="isPaidToVisible && localAdjustment.purchaserHasPaidFee != 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT'">
                        <label class="control-label">{{paidToLabel}}</label>
                    </div>
                    <div class="col-lg-3" *ngIf="isPaidToVisible" [ngClass]="localAdjustment.purchaserHasPaidFee === 'USE_DATE_FROM_FINAL_OCCUPANCY_FEES_ADJUSTMENT' ? '' : 'margin-left-15'" >
                        <dp-partial-date #paidTo
                                         name="paidToId"
                                         id="paidToId"
                                         (dateChange)="onDateChange($event ,'paidTo')"
                                         [inputDate]="localAdjustment.paidTo"
                                         [disableAll]="isPaidToDisabled"
                        >
                        </dp-partial-date>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label" dp-province-field-label="matter.soa.expense.taxtype">Adjust for</label>
                    </div>
                    <div class="col-lg-1" *ngIf="context.matter.isMatterProvinceON" >
                        <ng-container>
                            <select type="text"
                                    id="taxType"
                                    name="taxType"
                                    [(ngModel)]="localAdjustment.taxType"
                                    (ngModelChange)="updateTaxRate($event)"
                                    class="form-control"
                            >
                                <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                                    {{taxType.label}}
                                </option>
                            </select>
                        </ng-container>

                    </div>
                    <div class="col-lg-1">
                        <div class="col-lg-2" *ngIf="context.matter.isMatterProvinceON">
                            <label class="control-label text-left">?</label>
                        </div>
                        <div class="col-lg-10">
                            <select type="text"
                                    id="applyTax"
                                    name="applyTax"
                                    [(ngModel)]="localAdjustment.applyTax"
                                    class="form-control"
                            >
                                <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                    {{yesNo.label}}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-group" *ngIf="localAdjustment.applyTaxBoolean">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">{{localAdjustment.taxType}} percentage is</label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="hstPercentage"
                               name="hstPercentage"
                               [(ngModel)]="localAdjustment.hstRate"
                               class="form-control"
                               dp-percentage
                        />
                    </div>
                    <div class="col-lg-1">
                        <label class="control-label text-left">%</label>
                    </div>
                </div>
            </div>

            <!-- adjustment summary -->
            <div style="border-top: 1px solid #cccccc;">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-10">
                            <label class="control-label" style="font-weight : bold">
                                {{this.localAdjustment.getDescription(isProjectOccFeeBasedOnPhantomMortgage, this.context.matter.provinceCode)}}
                            </label>
                    </div>
                </div>

                <div *ngFor="let line of details; let i = index">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-4 text-left">{{line.title}}</label>
                        <ng-container *ngIf="line.value && line.value.length > 0">
                            <label *ngIf="i + 1  < details.length" class="control-label" [ngClass]="{'col-lg-4': line.pushValueToRight, 'col-lg-2': !line.pushValueToRight}">{{line.value}}</label>
                            <label *ngIf="i + 1 == details.length" class="control-label" [ngClass]="{'col-lg-6': line.pushValueToRight, 'col-lg-4': !line.pushValueToRight}">{{line.value}}</label>
                        </ng-container>
                    </div>
                </div>
            </div>

        </ng-container>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
            <button type="button" *ngIf="allowDelete"
                    (click)="delete()"
                    class="dp-btn dp-btn-cancel">
                <span>Delete</span>
            </button>
        </div>
    </div>
</div>
<dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
