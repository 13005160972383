import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ContactSectionedService {

  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();

  constructor() {
  }

  provinceChange() {
    this.messageSource.next();
  }
}