import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Matter} from './matter';

@Injectable()
export class GetGlobalSaveModelService {

  // Observable global save from parent source
  changeMatterSubject = new BehaviorSubject<any>(Matter);

  // Observable  global save from parent stream
  getItem$ = this.changeMatterSubject.asObservable();

  // service command
  changeModel(matter: Matter) {
    this.changeMatterSubject.next(matter);
  }
}
