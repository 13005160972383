import {Contact, dropDowns, Matter, MatterParticipant, Utils} from '../../../shared';
import {Component, Inject, OnInit} from '@angular/core';
import {Declaration} from '../../../executions-affidavits/declaration';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {FocusFirstElementModalDecorator} from '../../../../shared-main/focus-first-element-modal-decorator';
import {messages} from '../../../../common';
import {SelectItem} from 'primeng/api';
import {Writ} from '../../../../shared-main/teranet/property-writ';

import SharedUtils from '../../../../shared-main/utils';
import {DeclarationSigningOfficer} from '../../../executions-affidavits/declarationSigningOfficer';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class WritSearchDeclarationsModalContext {
  public solicitorNames: string[];
  public writ: Writ;
  public matter: Matter;
}

@Component({
  selector: 'dp-writ-search-declarations-modal',
  templateUrl: './writ-search-declarations.modal.component.html',
  providers: [DialogService]
})
@FocusFirstElementModalDecorator()
export class WritSearchDeclarationsModal extends ModalComponent<WritSearchDeclarationsModalContext> implements OnInit {
  clientDeclaration: Declaration;
  solicitorDeclaration: Declaration;
  genderTypes: SelectItem[];
  isGenderReadOnly: boolean = false;
  showClientFields: boolean = false;
  officerNameOneLabel: string;
  officerNameTwoLabel: string;
  writContact: Contact;
  solicitorNames: string[] = [];
  solicitorDropDownList: string[] = [];
  isSolicitorDropDown: boolean = false;
  isClientSelected: boolean;
  isSolicitorSelected: boolean;
  utils;

  constructor(
    public dialog: MatDialogRef<WritSearchDeclarationsModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: WritSearchDeclarationsModalContext
  ) {
    super(dialog, context);
    this.utils = new Utils();
  }

  ngOnInit(): void {
    //find existing declarations if they exist
    this.clientDeclaration = this.matter.findClientDeclarationByWrit(this.writ);
    this.solicitorDeclaration = this.matter.findSolicitorDeclarationByWrit(this.writ);

    if (!this.clientDeclaration) {
      this.clientDeclaration = new Declaration();
      this.clientDeclaration.declarationType = 'CLIENT';
    }

    if (!this.solicitorDeclaration) {
      this.solicitorDeclaration = new Declaration();
      this.solicitorDeclaration.declarationType = 'SOLICITOR';
    }

    this.genderTypes = dropDowns.addClientGenderType;
    this.solicitorNames = this.context.solicitorNames;
    if (this.solicitorNames.length > 0) {
      this.isSolicitorDropDown = true;
    }

    if (this.isClientDeclarationExists) {
      this.isClientSelected = true;
      this.isGenderReadOnly = true;
      if (this.clientDeclaration && this.clientDeclaration.declarationSigningOfficers) {
        this.showClientFields = true;
      }
    }

    if (this.isSolicitorDeclarationExists) {
      this.isSolicitorSelected = true;
    }

    this.initializeDeclarations();
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get writ(): Writ {
    return this.context.writ;
  }

  get isClientDeclarationExists(): boolean {
    return this.matter.findClientDeclarationByWrit(this.writ) ? true : false;
  }

  get isSolicitorDeclarationExists(): boolean {
    return this.matter.findSolicitorDeclarationByWrit(this.writ) ? true : false;
  }

  initializeDeclarations(): void {
    this.clientDeclaration.writId = this.writ.id;
    //TODO: refactor so we don't have two separate Utils classes
    this.clientDeclaration.clientName = SharedUtils.convertToTitleCase(this.writ.partyNameForDeclaration);
    this.solicitorDeclaration.writId = this.writ.id;
    this.solicitorDeclaration.clientName = this.clientDeclaration.clientName;

    //handle these specific writ party origin types
    const partyOrigins: string[] = ['PURCHASER', 'VENDOR', 'MORTGAGOR', 'CONDO_CORPORATION', 'GUARANTOR'];
    if (partyOrigins.some((partyOrigin) => partyOrigin == this.context.writ.partyOrigin)) {
      let participants: MatterParticipant[] = this.matter.getParticipantsByRole(this.matter.mainClientType);

      //find the writ participant from the matter using name matching
      let writParticipant: MatterParticipant = participants.find((participant) => {
        if (
          this.writ.writPartyData.isCompany &&
          participant.contact &&
          !participant.contact.isPerson &&
          participant.contact.organizationName
        ) {
          return this.writ.givenName == participant.contact.organizationName.toUpperCase();
        } else if (
          this.writ.writPartyData.isPerson &&
          participant.contact &&
          participant.contact.isPerson &&
          participant.contact.lastName &&
          this.writ.lastName == participant.contact.lastName.toUpperCase()
        ) {
          const formattedGivenName =
            (participant.contact.firstName ? participant.contact.firstName : '') +
            ' ' +
            (participant.contact.middleName ? participant.contact.middleName : '').toUpperCase();
          return this.writ.givenName == this.writ.givenName;
        } else {
          return false;
        }
      });

      if (writParticipant && writParticipant.contact) {
        this.isGenderReadOnly = true;
        this.clientDeclaration.gender = writParticipant.contact.gender;
        this.clientDeclaration.participantId = this.fetchParticipantIdOfContact(writParticipant.contact);
        this.writContact = writParticipant.contact;
        this.setClientFieldsLabels(this.writContact, this.clientDeclaration.gender);
      }
    }
  }

  fetchParticipantIdOfContact(contact: Contact): number {
    let participant: MatterParticipant = this.matter.matterParticipants.find(
      (p) => p.contact.identifier == contact.identifier
    );
    if (participant) {
      return participant.matterParticipantId;
    } else {
      null;
    }
  }

  onOK(): void {
    if (this.isSolicitorSelected && !this.solicitorDeclaration.solicitorName) {
      this.dialogService.confirm('Error', 'The Name of Solicitor field must be completed.', true);
    } else {
      if (!this.clientDeclaration.gender) {
        this.clientDeclaration.gender = 'QUESTION';
      }

      if (!this.solicitorDeclaration.gender) {
        this.solicitorDeclaration.gender = 'QUESTION';
      }
      this.clientDeclaration.declarationSigningOfficers = this.clientDeclaration.declarationSigningOfficers.filter(
        (s) => s.signingOfficerName.trim() != ''
      );
      this.dialog.close({
        action: 'OK',
        clientDeclaration: this.clientDeclaration,
        solicitorDeclaration: this.solicitorDeclaration,
        isClientSelected: this.isClientSelected,
        isSolicitorSelected: this.isSolicitorSelected
      });
    }
  }

  close(action?: string): void {
    this.dialogService.confirm('Confirmation', messages.declaration.cancelConfirmation, false).subscribe((res) => {
      if (res && res == true) {
        this.dialog.close({action: 'Cancel'});
      }
    });
  }

  filterSolicitorNameList(event) {
    this.solicitorDropDownList = [];
    for (let i = 0; i < this.solicitorNames.length; i++) {
      let solicitorName = this.solicitorNames[i];
      if (solicitorName.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.solicitorDropDownList.push(solicitorName);
      }
    }
  }

  solicitorDropdownClick = (event) => {
    this.solicitorDropDownList = [];
    //mimic remote call
    //setTimeout(() => {
    //  this.solicitorDropDownList = [];
    for (let i = 0; i < this.solicitorNames.length; i++) {
      this.solicitorDropDownList.push(this.solicitorNames[i]);
    }
    //}, 100);
  };

  setClientFieldsLabels(contact: Contact, gender: string) {
    this.showClientFields = true;
    switch (gender) {
      case 'CORPORATION':
      case 'OTHERENTITY':
        if (contact) {
          this.addSigningOfficerFromContact(contact);
        } else {
          this.addSigningOfficer();
        }
        break;
      case 'ESTATE':
        if (contact) {
          this.addSigningOfficerFromContact(contact);
        } else {
          this.addSigningOfficer();
        }
        break;
      case 'FEMALEPOA':
      case 'MALEPOA':
        if (contact) {
          this.addSigningOfficerFromContact(contact);
        } else {
          this.addSigningOfficer();
        }
        break;
      default: //Male or Female
        this.showClientFields = false;
        break;
    }
  }

  genderSelectedFromList() {
    this.setClientFieldsLabels(this.writContact, this.clientDeclaration.gender);
  }

  get okEnabled(): boolean {
    //Only enabling OK if client or solicitor declaration didn't already exist and is now selected.
    if (
      (!this.isClientDeclarationExists && this.isClientSelected) ||
      (!this.isSolicitorDeclarationExists && this.isSolicitorSelected && this.solicitorDeclaration.solicitorName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  addSigningOfficerFromContact(contact: Contact) {
    this.clientDeclaration.declarationSigningOfficers = [];
    let participant: MatterParticipant = this.context.matter.matterParticipants.find((p) => p.contact.id == contact.id);
    if (participant) {
      let signingOfficers: MatterParticipant[] = this.context.matter.getChildSigners(participant);
      if (signingOfficers) {
        signingOfficers.forEach((s) =>
          this.addSigningOfficer(s.contact.contactFullNameStartWithFirstName, s.matterParticipantId)
        );
      }
    }
  }

  addSigningOfficer(name?: string, participantId?: number): void {
    let declarationSigningOfficer = new DeclarationSigningOfficer();
    declarationSigningOfficer.signingOfficerName = name ? name : '';
    declarationSigningOfficer.participantId = participantId ? participantId : null;
    this.clientDeclaration.declarationSigningOfficers.push(declarationSigningOfficer);
  }

  deleteSigner(index: number) {
    this.clientDeclaration.declarationSigningOfficers.splice(index, 1);
  }

  officerNameLabel(gender: string): string {
    return Utils.signingOfficerTypeText(gender);
  }

  ngAfterViewInit() {}
}
