import {Directive, ElementRef, Renderer2, SimpleChanges} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {CustomKeyCodesEnum} from '../common/key-code-enum';
import {WindowRef} from '../shared/window.ref';
import {EXCEL_TAB_STOP_CLASS_NAME} from './constants';
import {Utils} from '../matters/shared/utils';

declare var jQuery: any;

@Directive({
  selector: '[dp-table-excel-style]'
})

export class DpTableExcelStyleDirective {
  constructor(private el: ElementRef, private logger: Logger, private renderer: Renderer2, private window: WindowRef) {

  }

  activeIndexFromTabKey: number = -1;
  lastVisitedRowIndex: number = -1;
  previousIndexFromTabKey: number = -1;
  lastActiveIndexFromTdCell: number = -1;
  tableRowCount: number = 0;
  firstTab: boolean = false;
  lastTab: boolean = false;
  tableClassName: string = '';

  /*
  @HostListener('keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {

      console.log(event);


  }
*/

  ngOnInit() {

    let today = new Date().getTime();
    this.tableClassName = 'table-excel-style-' + today;

  }

  setTableTabIndexes() {
    jQuery('.' + this.tableClassName).attr('tabindex', '-1');
    jQuery('.' + this.tableClassName + ' tbody tr').attr('tabindex', '-1');
    jQuery('.' + this.tableClassName + ' tbody tr td').attr('tabindex', '-1');
    jQuery('.' + this.tableClassName + ' tbody tr td.' + EXCEL_TAB_STOP_CLASS_NAME + '').attr('tabindex', '0');
  }

  ngAfterViewInit(): void {

    jQuery(this.el.nativeElement).addClass('table-excel-style');
    jQuery(this.el.nativeElement).addClass(this.tableClassName);
    this.setTableTabIndexes();
    setTimeout(() => {
      this.initTableAttributes();
    }, 1000);
  }

  initTableAttributes() {

    this.tableRowCount = this.calculateTableRowCount();
    this.setTableTabIndexes();
    jQuery('.' + this.tableClassName).on('keydown', (e) => {
      this.tableKeyCommands(e);
    });
    jQuery('.' + this.tableClassName).on('focus click', 'tr', (e) => {

      if (e.type == 'focusin') {
        this.setActiveIndex(e);
        let activeRow = jQuery(event.target).closest('tr');
        if (jQuery(event.target).is('.' + this.tableClassName + ' tr:eq(' + ((activeRow[ 0 ].rowIndex)) + ') .' + EXCEL_TAB_STOP_CLASS_NAME + ':first')) {
          this.firstTab = true;
        } else {
          this.firstTab = false;
        }
        if (jQuery(event.target).is('.' + this.tableClassName + ' tr:eq(' + ((activeRow[ 0 ].rowIndex)) + ') .' + EXCEL_TAB_STOP_CLASS_NAME + ':last')) {
          this.lastTab = true;
        } else {
          this.lastTab = false;
        }
        if (jQuery(document.activeElement).val() != '' && document.activeElement.nodeName == 'INPUT') {
          // on inputs focus in and select the text inside the input
          this.callSelectionRange(document.activeElement);
        }

      } else if (e.type == 'click') {

        this.onSelectedChange(e);

      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges | changes:', changes);
  }

  calculateTableRowCount(): number {
    let tableRowCount = jQuery('.' + this.tableClassName + ' tbody tr:last').index() + 1;
    this.setTableTabIndexes();
    if (!isNaN(tableRowCount)) {
      return tableRowCount;
    } else {
      return 0;
    }
  }

  callSelectionRange(element) {
    if (jQuery(element).val() != '' && element.tagName == 'INPUT') {
      let cursorPosition = jQuery(element).val().length;
      this.setSelectionRange(jQuery(element), cursorPosition, cursorPosition);
    }
  }

  tableKeyCommands(event): void {
    this.setTableTabIndexes();
    let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

    if (charCode === CustomKeyCodesEnum.Tab) {

      /// do something with tabbing
      // get the active tr and active td cell
      let activeRow = jQuery(event.target).closest('tr');
      if (activeRow.length > 0 && activeRow[ 0 ]) {
        if (event.shiftKey) {
          if (this.firstTab) {
            event.preventDefault();
            jQuery('.' + this.tableClassName + ' tbody tr:eq(' + ((activeRow[ 0 ].rowIndex) - 1) + ') .' + EXCEL_TAB_STOP_CLASS_NAME).last().focus();
            this.callSelectionRange(document.activeElement);
          }

        } else {
          if (this.lastTab) {
            event.preventDefault();
            jQuery('.' + this.tableClassName + ' tbody tr:eq(' + ((activeRow[ 0 ].rowIndex) - 1) + ') .' + EXCEL_TAB_STOP_CLASS_NAME).first().focus();
            this.callSelectionRange(document.activeElement);
          }
        }
        this.callSelectionRange(document.activeElement);
      } else {

        jQuery(event.target).closest('tr').focus();
      }

    }

    if (charCode === CustomKeyCodesEnum.Down) {
      event.preventDefault();
      // hilite next row with down arrow
      this.keyCommandtoHiliteRow(event, 'down');

    }
    if (charCode === CustomKeyCodesEnum.Up) {
      event.preventDefault();
      this.keyCommandtoHiliteRow(event, 'up');
    }
  }

  keyCommandtoHiliteRow(event, dir) {
    let activeRow: any;
    let activeTdCell: any;
    let activeTdCellIndex: number = -1;
    let activeTrRowIndex: number = -1;
    if (this.activeIndexFromTabKey < this.calculateTableRowCount()) {
      if (document.activeElement.className === 'toggleBurger') {
        if (dir === 'up') {
          jQuery(event.target).closest('tr').prev('tr').find('.toggleBurger').focus();
        } else {
          if (jQuery(event.target).closest('tr').next('tr').find('.toggleBurger').is(':last-child')) {
            jQuery(event.target).closest('tr').next('tr').find('.toggleBurger').first().focus();
          } else {
            jQuery(event.target).closest('tr').next('tr').focus();
          }
        }
      } else {

        // get the active tr and active td cell
        activeRow = jQuery(event.target).closest('tr');
        activeTdCell = jQuery(event.target).closest('td');

        if (activeRow.length > 0 && activeRow[ 0 ]) {
          activeTrRowIndex = activeRow[ 0 ].rowIndex;
        }
        if (activeTdCell.length > 0 && activeTdCell[ 0 ]) {
          activeTdCellIndex = activeTdCell[ 0 ].cellIndex;

        }

        if (event.target.nodeName != 'TR') {
          this.lastActiveIndexFromTdCell = activeTdCellIndex;
        }

        if (dir === 'up') {
          if (activeTrRowIndex > -1) {
            activeTrRowIndex = activeTrRowIndex - 2;
          } else {
            activeTrRowIndex = 0;
          }
        }

        // check if cell below has input to focus into, if not then focus on the last input, or if not just focus on the row
        let target: string;
        let cursorPosition: number;
        if (activeTdCellIndex > -1 && jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ')').has('input').length > 0) {
          jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ') input').focus();
          target = '.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ') input';
          this.callSelectionRange(target);

        } else if (activeTdCellIndex > -1 && jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ')').has('select').length > 0) {
          jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ') select').focus();
          /*  condition to select last cell in row with input
          } else if(activeTdCellIndex>-1 && jQuery('.' + this.tableClassName + ' tbody tr:eq(' +
           (activeTrRowIndex) +
           ')').has("input").length>0) {
                 jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') input').last().focus();
                 this.callSelectionRange(document.activeElement); */
        } else if (activeTdCellIndex > -1 && jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ')').has('.excel-trustledger-cell-pair').length > 0) {
          //  trust ledger receipt and expenditures inputs are paired to focus on either input in row
          jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') .excel-trustledger-cell-pair').focus();
          this.callSelectionRange(document.activeElement);

        } else {
          if (dir === 'up') {

            if (jQuery(event.target).closest('tr').prev('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) +
              ')').has('input').length > 0) {
              jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (this.lastActiveIndexFromTdCell) + ') input').focus();
              target = '.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ') input';
              this.callSelectionRange(target);

            } else if (jQuery(event.target).closest('tr').prev('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) +
              ')').has('select').length > 0) {
              jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (this.lastActiveIndexFromTdCell) + ') select').focus();
            } else if (jQuery(event.target).closest('tr').prev('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) + ')').has('.toggleBurger').length > 0) {
              jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (this.lastActiveIndexFromTdCell) + ') .toggleBurger').focus();
            } else if (activeTrRowIndex > -1) {
              jQuery(event.target).closest('tr').prev('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) +
                ')').focus();
            }
          } else {
            if (jQuery(event.target).closest('tr').next('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) +
              ')').has('input').length > 0) {

              jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (this.lastActiveIndexFromTdCell) + ') input').focus();
              target = '.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (activeTdCellIndex) + ') input';
              cursorPosition = jQuery(target).val().length;
              this.callSelectionRange(target);

            } else if (jQuery(event.target).closest('tr').next('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) +
              ')').has('select').length > 0) {
              jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (this.lastActiveIndexFromTdCell) + ') select').focus();
            } else if (jQuery(event.target).closest('tr').next('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) + ')').has('.toggleBurger').length > 0) {

              jQuery('.' + this.tableClassName + ' tbody tr:eq(' + (activeTrRowIndex) + ') td:eq(' + (this.lastActiveIndexFromTdCell) + ') .toggleBurger').focus();

            } else if (activeTrRowIndex > -1) {

              jQuery(event.target).closest('tr').next('tr').find('td:eq(' + (this.lastActiveIndexFromTdCell) +
                ')').focus();
            }
          }
        }
      }
      Utils.bringFieldIntoVisibleArea(document.activeElement);
    }

  }

  keyCommandtoHilitePreviousRow(event) {
    if (this.activeIndexFromTabKey > -1) {
      if (document.activeElement.className === 'toggleBurger') {
        jQuery(event.target).closest('tr').prev('tr').focus();
      } else {
        jQuery(event.target).prev('tr').focus();
      }
    }
  }

  setActiveIndex(e) {
    this.previousIndexFromTabKey = this.activeIndexFromTabKey;
    this.activeIndexFromTabKey = Number(e.currentTarget.rowIndex) - 1;
    this.lastVisitedRowIndex = Number(e.currentTarget.rowIndex) - 1;

  }

  addClassToRow(e) {
    // when you focus on burger menu in row we add background color to row
    jQuery('tbody tr').removeClass('active-hover');
    jQuery(e.target).closest('tr').addClass('active-hover');
  }

  removeClassFromRow(e) {
    // when you blur on burger menu in row we remove background color to row
    jQuery(e.target).closest('tr').removeClass('active-hover');
  }

  onSelectedChange(event) {
    // console.log(event);
  }

  setSelectionRange(input, selectionStart, selectionEnd) {
/// set the cursor to the end of the field
    input = input[ 0 ];
    if (input.setSelectionRange) {
      if (!jQuery(input).hasClass('dp-currency')) {
        input.focus();
        input.setSelectionRange(selectionEnd, selectionEnd);
      }
    } else if (input.createTextRange) {
      let range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionEnd);
      range.select();
    }
  }

}
