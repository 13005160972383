import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {Matter} from '../../shared/matter';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ManageDocumentProductionCategoryModalComponent} from './manage-document-production-category.modal.component';
import {DocumentProductionService} from '../document-production.service';
import {DocumentTemplateCategory} from '../document-template-category';
import {MatterService} from '../../matter.service';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {MatterTypeUtil} from '../../../shared-main/matter-type/matter-type-util';
import {MatterTypeInfo} from '../../../admin/shared/matter-type-info';
import {AccountService} from '../../../admin/accounts/account.service';

export class ManageDocumentProductionCategoriesContext {
  matter: Matter;
  accountFileFolderId: number;
  matterTypeCode: string;
  accountId: number;
}

@Component({
  selector: 'dp-manage-document-production-categories-modal',
  templateUrl: 'manage-document-production-categories.modal.component.html',
  styleUrls: ['../document-production-template.component.scss']
})
export class ManageDocumentProductionCategoriesModalComponent
  extends ModalComponent<ManageDocumentProductionCategoriesContext>
  implements OnInit
{
  documentProductionCategories: DocumentTemplateCategory[];
  defaultCategoryId: number;
  accountFileFolderId: number;
  MatterTypeInfos: MatterTypeInfo[] = [];

  constructor(
    public dialog: MatDialogRef<ManageDocumentProductionCategoriesModalComponent>,
    public dialogService: DialogService,
    public matterService: MatterService,
    public documentProductionService: DocumentProductionService,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) context?: ManageDocumentProductionCategoriesContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.loadMatterTypeDetails();
    this.accountFileFolderId = this.context.matter ? null : this.context.accountFileFolderId;
    this.documentProductionService
      .getAllDocumentTemplateCategory(this.getMatterTypeCode(), this.accountId)
      .subscribe((data) => {
        this.documentProductionCategories = data;
        if (this.documentProductionCategories && this.documentProductionCategories.length) {
          let defaultCategory = this.documentProductionCategories.find((item) => item.defaultCategory);
          if (defaultCategory) {
            this.defaultCategoryId = defaultCategory.id;
          }
          this.sortData();
        }
      });
    if (this.context.matter) {
      this.matterService.getMatterDocumentProfile(this.context.matter).subscribe((matterDocumentProfile) => {
        if (matterDocumentProfile && matterDocumentProfile.accountFileFolder) {
          this.accountFileFolderId = matterDocumentProfile.accountFileFolder.id;
        }
      });
    } else {
      this.accountFileFolderId = this.context.accountFileFolderId;
    }
  }

  loadMatterTypeDetails() {
    this.accountService.getMatterTypesDetails().subscribe((result: MatterTypeInfo[]) => {
      this.MatterTypeInfos = result;
    });
  }

  addNewCategory() {
    if (this.accountFileFolderId) {
      this.addNewCategoryDialog();
    } else if (this.context.matter) {
      this.matterService.getMatterDocumentProfile(this.context.matter).subscribe((matterDocumentProfile) => {
        if (matterDocumentProfile && matterDocumentProfile.accountFileFolder) {
          this.accountFileFolderId = matterDocumentProfile.accountFileFolder.id;
          this.addNewCategoryDialog();
        }
      });
    }
  }

  addNewCategoryDialog() {
    this.dialogService.matDialogContent({
      content: ManageDocumentProductionCategoryModalComponent,
      context: {
        matterTypeCode: this.getMatterTypeCode(),
        categoryID: null,
        defaultCategoryID: this.defaultCategoryId,
        accountFileFolderId: this.accountFileFolderId,
        accountId: this.accountId,
        matterTypeInfos: this.MatterTypeInfos
      },
      onFulfillment: (result: any) => {
        if (result && result.documentTemplateCategory) {
          console.log(result);
          this.loadDocumentProductionCategories();
        }
      }
    });
  }

  openCategory(catID) {
    if (this.accountFileFolderId) {
      this.openCategoryDialog(catID);
    } else if (this.context.matter) {
      this.matterService.getMatterDocumentProfile(this.context.matter).subscribe((matterDocumentProfile) => {
        if (matterDocumentProfile && matterDocumentProfile.accountFileFolder) {
          this.accountFileFolderId = matterDocumentProfile.accountFileFolder.id;
          this.openCategoryDialog(catID);
        }
      });
    }
  }

  get accountId(): number {
    let accId = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    if (this.context.accountId) {
      accId = this.context.accountId;
    }
    return accId;
  }

  openCategoryDialog(catID) {
    this.dialogService.matDialogContent({
      content: ManageDocumentProductionCategoryModalComponent,
      context: {
        matterTypeCode: this.getMatterTypeCode(),
        categoryID: catID,
        defaultCategoryID: this.defaultCategoryId,
        accountFileFolderId: this.accountFileFolderId,
        accountId: this.accountId,
        matterTypeInfos: this.MatterTypeInfos
      },
      onFulfillment: (result: any) => {
        if (result && result.documentTemplateCategory) {
          console.log(result);
          this.loadDocumentProductionCategories();
        }
      }
    });
  }

  loadDocumentProductionCategories(): void {
    this.documentProductionService
      .getAllDocumentTemplateCategory(this.getMatterTypeCode(), this.accountId)
      .subscribe((data) => {
        this.documentProductionCategories = data;
        this.sortData();
      });
  }

  sortData(): void {
    this.documentProductionCategories = this.documentProductionCategories.sort(
      (a: DocumentTemplateCategory, b: DocumentTemplateCategory) => {
        if (a.defaultCategory) {
          return -1;
        } else {
          return a.categoryDescription.toLowerCase() < b.categoryDescription.toLocaleLowerCase() &&
            !a.defaultCategory &&
            !b.defaultCategory
            ? -1
            : 1;
        }
      }
    );
  }

  close() {
    this.dialog.close({
      documentTemplateCategories: this.documentProductionCategories
    });
  }

  getMatterTypeCode(): string {
    return this.context.matter ? this.context.matter.derivedMatterType : this.context.matterTypeCode;
  }

  getCategoryDescription(documentTemplateCategory: DocumentTemplateCategory): string {
    return MatterTypeUtil.getDocumentCategoryDescription(documentTemplateCategory, this.MatterTypeInfos);
  }
}
