import {Contact, Matter, MatterParticipant, Telephone} from '../matters/shared';
import {Injectable} from '@angular/core';
import {EventData} from '../event/event-data';
import {AppointmentUtil} from '../event/custom-event/appointment-util';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../matters/shared/matter-participant-role-types';
import {DialogService} from '../shared/dialog/dialog.service';
import {MatterTab} from '../matters/matter-tab';
import {TabsService} from '../core';
import {EventService} from '../event/event.service';
import {Observable} from 'rxjs/Observable';
import {ContactInfo} from '../matters/shared/contact-info';

@Injectable()
export class ContactChangesListener {

  meetingParticipantIdsForDelete: number[] = [];

  constructor(public dialogService: DialogService,
              public tabsService: TabsService,
              public eventService: EventService) {
  }

  async handleContactChange(matter: Matter, mp: MatterParticipant, matterParticipantRole: MatterParticipantRole, tabsService: TabsService, isDeleted?: boolean, contactInfo?: ContactInfo, checkForChildren?: boolean): Promise<void> {
    if (isDeleted) {
      await this.handleDeleteContact(matter, mp, matterParticipantRole, tabsService, contactInfo);
    } else {
      await this.handleUpdateContact(matter, mp, matterParticipantRole, tabsService, contactInfo, checkForChildren);
    }
  }

  async handleUpdateContact(matter: Matter, mp: MatterParticipant, matterParticipantRole: MatterParticipantRole, tabsService: TabsService, contactInfo?: ContactInfo, checkForChildren?: boolean): Promise<void> {
    let meetingParticipantIds = [];
    if (checkForChildren) {
      meetingParticipantIds = this.getMeetingParticipantIdAndChildren(matter, mp, matterParticipantRole, contactInfo);
    } else {
      meetingParticipantIds.push(AppointmentUtil.getMeetingParticipantId(mp && mp.contact, matterParticipantRole, contactInfo));
    }
    if (meetingParticipantIds && meetingParticipantIds.length) {
      let affectedEvents: EventData[] = this.getParticipantMatterEvents(meetingParticipantIds, matter);
      if (affectedEvents && affectedEvents.length) {
        let name = contactInfo ? contactInfo.lawClerkName : (mp && mp.contact ? mp.contact.getFullNameForContact(false) : '');
        let message = `${ name } is identified as a participant in a Virtual Signing Package.
                    Would you like to reflect these changes in the Virtual Signing Package(s)?
                    Note, any documents attached to the Virtual Signing Package will have to be updated manually.`;
        let toSave = await this.dialogService.confirm('WARNING', message, false).toPromise();
        if (toSave) {
          let syngrafiiEvents = affectedEvents.filter(event => event.isDigitalSignPlatformSyngrafii());
          // Use !! to filter ""
          if (syngrafiiEvents.length > 0 && !!mp.contact.getMobileNumber() && !Telephone.isPhoneValid(mp.contact.getMobileNumber())) {
            this.dialogService.confirm('ERROR', 'Unable to update Syngrafii package due to invalid cell phone number', true);
          } else {
            let isMatterSaved = false;
            for (let meetingParticipantId of meetingParticipantIds) {
              if (!isMatterSaved) {
                // Making sure we save once
                isMatterSaved = await this.saveMatter(tabsService);
              }
              let meetingParticipantEvents = this.getMatterEventsByMeetingParticipantId(matter, meetingParticipantId);
              if (meetingParticipantEvents && meetingParticipantEvents.length) {
                await this.updateAndSaveEvents(matter, meetingParticipantEvents, meetingParticipantId);
              }
            }
          }
        }
      }
    }
  }

  async handleDeleteContact(matter: Matter, mp: MatterParticipant, matterParticipantRole: MatterParticipantRole, tabsService: TabsService, contactInfo?: ContactInfo): Promise<void> {
    if (contactInfo) {
      let meetingParticipantId = AppointmentUtil.getMeetingParticipantId(null, null, contactInfo);
      if (meetingParticipantId) {
        this.meetingParticipantIdsForDelete = [ meetingParticipantId ];
      }
    }
    if (!this.meetingParticipantIdsForDelete || this.meetingParticipantIdsForDelete && !this.meetingParticipantIdsForDelete.length) {
      let meetingParticipantId = AppointmentUtil.getMeetingParticipantId(mp && mp.contact, matterParticipantRole, contactInfo);
      if (meetingParticipantId) {
        this.meetingParticipantIdsForDelete = [ meetingParticipantId ];
      }
    }
    if (this.meetingParticipantIdsForDelete && this.meetingParticipantIdsForDelete.length) {
      let isMatterSaved = false;
      for (let meetingParticipantId of this.meetingParticipantIdsForDelete) {
        let affectedEvents = this.getMatterEventsByMeetingParticipantId(matter, meetingParticipantId);
        if (affectedEvents && affectedEvents.length) {
          if (!isMatterSaved) {
            // Making sure we save once
            isMatterSaved = await this.saveMatter(tabsService);
          }
          await this.updateAndSaveEvents(matter, affectedEvents, meetingParticipantId, true);
        }
      }

      this.meetingParticipantIdsForDelete = [];
    }
  }

  getMatterEventsByMeetingParticipantId(matter: Matter, meetingParticipantId: number): EventData[] {
    let events: EventData[] = [];
    if (matter.appointments && matter.appointments.length) {
      matter.appointments
      .filter(appointment => appointment.isDigitalSignPlatformSyngrafii() || appointment.isDigitalSignPlatformDocuSign())
      .filter(appointment => !(appointment.signingEnvelope && (appointment.signingEnvelope.isEnvelopeSent() || appointment.signingEnvelope.isEnvelopeCompleted())))
      .map(appointment => {
        appointment.meetingParticipants.map(meetingParticipant => {
          if (meetingParticipantId == meetingParticipant.participantIdOnMatter) {
            events.push(appointment);
          }
        });
      });
    }

    return events;
  }

  async saveMatter(tabsService: TabsService): Promise<boolean> {
    let matterTab = tabsService.activeTab as MatterTab;
    if (matterTab && matterTab.matterComponent) {
      return await matterTab.matterComponent.validateAndSaveMatter().toPromise();
    } else {
      return false;
    }
  }

  async updateAndSaveEvents(matter: Matter, appointments: EventData[], meetingParticipantId: number, isDeleted?: boolean): Promise<void> {
    this.updateMeetingParticipantInEvents(appointments, meetingParticipantId, isDeleted);
    let updateEventsObservables = appointments.map((appointment: EventData) => this.eventService.updateEvent(matter.id, appointment, true));
    if (updateEventsObservables) {
      try {
        let updatedEvents = await Observable.forkJoin(updateEventsObservables).toPromise();
      } catch (error) {
        //Show message about event save error
      }
    }
  }

  updateMeetingParticipantInEvents(appointments: EventData[], meetingParticipantId: number, isDeleted?: boolean): void {
    appointments.forEach(event => {
      if (event.meetingParticipants && event.meetingParticipants.length) {
        let meetingParticipantIndex = event.meetingParticipants.findIndex(item => item.participantIdOnMatter == meetingParticipantId);
        if (meetingParticipantIndex > -1) {
          if (isDeleted) {
            event.meetingParticipants.splice(meetingParticipantIndex, 1);
          } else {
            event.meetingParticipants[ meetingParticipantIndex ].participantUpdated = true;
          }
        }
      }

    });
  }

  getParticipantMatterEvents(meetingParticipantIds: number[], matter: Matter): EventData[] {
    let events: EventData[] = [];
    if (meetingParticipantIds && meetingParticipantIds) {
      meetingParticipantIds.forEach((meetingParticipantId) => {
        let participantEvents = this.getMatterEventsByMeetingParticipantId(matter, meetingParticipantId);
        if (participantEvents && participantEvents.length) {
          events = [ ...events, ...participantEvents ];
        }
      });
    }
    return events;
  }

  getDeleteConfirmationMessage(participantName: string): string {
    return `${ participantName } is identified as a participant in a Virtual Signing Package.
            Are you sure you want to delete them from the Matter?
            Note, any documents attached to the Virtual Signing Package will have to be updated manually.`;
  }

  checkParticipantsEventsAndConstructDeleteMessage(defaultDeleteMessage: string, matter: Matter, mp: MatterParticipant, matterParticipantRole: MatterParticipantRole, contactInfo?: ContactInfo): string {
    this.meetingParticipantIdsForDelete = this.getMeetingParticipantIdAndChildren(matter, mp, matterParticipantRole, contactInfo);
    let affectedEvents: EventData[] = this.getParticipantMatterEvents(this.meetingParticipantIdsForDelete, matter);
    if (affectedEvents && affectedEvents.length) {
      return this.getDeleteConfirmationMessage(mp && mp.contact && mp.contact.getFullNameForContact(false));
    } else {
      return defaultDeleteMessage;
    }
  }

  getMeetingParticipantIdAndChildren(matter: Matter, mp: MatterParticipant, matterParticipantRole: MatterParticipantRole, contactInfo?: ContactInfo): number[] {
    let meetingParticipantIds: number[] = [];
    let meetingParticipantId = AppointmentUtil.getMeetingParticipantId(mp && mp.contact, matterParticipantRole, contactInfo);
    meetingParticipantIds.push(meetingParticipantId);

    //Check for Consenting Spouse
    let consentedSpouse: Contact = mp && mp.consentingSpouseContact;
    if (consentedSpouse && consentedSpouse.id) {
      let csMeetingParticipantId = AppointmentUtil.getMeetingParticipantId(consentedSpouse, MatterParticipantRoleTypes.CONSENTING_SPOUSE);
      meetingParticipantIds.push(csMeetingParticipantId);
    }
    //Check For Signing Officers
    let childMatterParticipants = matter.getChildSigners(mp);
    if (childMatterParticipants && childMatterParticipants.length) {
      childMatterParticipants.forEach(childMp => {
        let childMeetingParticipantId = AppointmentUtil.getMeetingParticipantId(childMp.contact, matterParticipantRole);
        meetingParticipantIds.push(childMeetingParticipantId);
      });
    }

    return meetingParticipantIds;
  }

}
