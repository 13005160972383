import {JurisdictionTab} from '../../contact/jurisdiction-tab';
import {Component, Inject} from '@angular/core';
import {ProcessJurisdictionActions} from '../shared/jurisdiction';
import {JurisdictionService} from '../property-teranet/jurisdiction.service';
import {PROVINCE_CODES} from '../shared/user-province';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class JurisdictionModalContext {
  jurisdictionTab: JurisdictionTab;
  defaultProvinceCode: string;
}

@Component({
  selector: 'dp-jurisdiction-modal.component',
  templateUrl: 'jurisdiction-modal.component.html'
})
export class JurisdictionModalComponent extends ModalComponent<JurisdictionModalContext> {
  constructor(
    public dialog: MatDialogRef<JurisdictionModalComponent>,
    public jurisdictionService: JurisdictionService,
    @Inject(MAT_DIALOG_DATA) context?: JurisdictionModalContext
  ) {
    super(dialog, context);
  }

  get title(): string {
    if (this.context.defaultProvinceCode == PROVINCE_CODES.NOVA_SCOTIA) {
      return 'Add new Community';
    } else {
      return 'Add new Jurisdiction';
    }
  }

  update(event): void {
    if (
      event == ProcessJurisdictionActions.SAVE &&
      this.context.jurisdictionTab &&
      this.context.jurisdictionTab.jurisdiction &&
      this.context.jurisdictionTab.jurisdiction.id
    ) {
      this.jurisdictionService.unlockJurisdiction(this.context.jurisdictionTab.jurisdiction.id);
    }
    this.dialog.close(event);
  }

  close() {
    this.dialog.close(ProcessJurisdictionActions.CANCEL);
  }
}
