import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {MortgagePaymentService} from './mortgage-payment.service';
import {ApplicationError} from '../../../../core/application-error';
import {FocusFirstElementModalDecorator} from '../../../../shared-main/focus-first-element-modal-decorator';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class AmortizationScheduleDialogContext {
  amortizationObject: any;
}

@FocusFirstElementModalDecorator()

@Component({
  selector: 'dp-amortization-schedule-dialog',
  templateUrl: 'amortization-schedule.component.html',
  styleUrls: [ './amortization-schedule.styles.scss' ],
  providers: [ ErrorService, MortgagePaymentService ]
})

export class AmortizationScheduleDialogComponent extends ModalComponent<AmortizationScheduleDialogContext> implements OnInit, OnDestroy {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  amortizationObject: any;
  showErrors: boolean = false;
  mpsSubscription: any;

  constructor(public dialog: MatDialogRef<AmortizationScheduleDialogComponent>,
              public errorService: ErrorService,
              public mortgagePaymentService: MortgagePaymentService,
              @Inject(MAT_DIALOG_DATA) context?: AmortizationScheduleDialogContext) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.amortizationObject = this.context.amortizationObject;
    console.log(this.amortizationObject);
    if (this.amortizationObject.autocalculated == false || this.amortizationObject.autocalculated == null) {
      this.amortizationObject.amortizationPeriod = 25;
    }
    if (this.amortizationObject.amortizationPeriod == null || this.amortizationObject.amortizationPeriod == 0 ||
      this.amortizationObject.calculatedType == null || this.amortizationObject.paymentFrequencyType == null || this.amortizationObject.paymentFrequencyType == '' ||
      this.amortizationObject.interest == '0.00000' || this.amortizationObject.payment == 0 || this.amortizationObject.principal == 0) {
      this.showErrors = true;
    } else {
      this.showErrors = false;
    }
    this.amortizationObject.selectedPayments = 'SIXTY_PAYMENTS';
    if (!this.amortizationObject.firstPaymentDate || this.amortizationObject.firstPaymentDate == undefined) {
      this.amortizationObject.firstPaymentDate = this.todaysDate;
    }
  }

  ngOnDestroy(): void {
    if (this.mpsSubscription) {
      this.mpsSubscription.unsubscribe();
    }
  }

  get todaysDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    let todaysdate = yyyy + '/' + mm + '/' + dd;
    return todaysdate;
  }

  selectPaymentOption(e) {
    this.errorService.removeDpSaveError('mortgage.terms.amortization.schedule');
    this.amortizationObject.selectedPayments = e.target.value;
    if (this.amortizationObject.selectedPayments == 'TO_MATURITY' && (this.amortizationObject.maturityDate == null || this.amortizationObject.maturityDate.length < 10)) {
      this.errorService.addDpSaveError(DPError.createDPError('mortgage.terms.amortization.schedule'));
    }
  }

  cancelDialog() {
    this.dialog.close();
  }

  closeDialog() {
    this.dialog.close();
  }

  validateErrorForSave(): void {
    if (this.amortizationObject.selectedPayments == 'ENTER_PAYMENTS' && (this.amortizationObject.numOfPayments == null || !this.amortizationObject.numOfPayments || this.amortizationObject.numOfPayments <= 0)) {
      this.errorService.addDpSaveError(DPError.createDPError('mortgage.terms.amortization.payments'));
    } else {
      this.errorService.removeDpSaveError('mortgage.terms.amortization.payments');
    }
    if (Number(this.amortizationObject.amortizationPeriod) > 0) {
      this.errorService.removeDpSaveError('mortgage.terms.amortizationPeriod');
    } else {
      this.errorService.addDpSaveError(DPError.createDPError('mortgage.terms.amortizationPeriod'));
    }
  }

  produce(): void {
    this.validateErrorForSave();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.mpsSubscription = this.mortgagePaymentService.postAmortizationSchedule(this.amortizationObject)
      .subscribe(data => {
          this.dialog.close();
        },
        (error: ApplicationError) => {
          if (error) {
            this.errorService.addDpSaveError(DPError.createCustomDPError('mortgage.terms.amortization.payments', 'Bad Request', 'Error', 'ERROR'));
          }
        }
      );
    }
  }

  isAnySaveErrors(): boolean {
    return this.errorService.hasErrors();
  }
}
