import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '../../core';
import {api} from '../../common';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {map} from 'rxjs/operators';
import {accountApi} from '../../admin/accounts/shared/account-api';

@Injectable()
export class PaymentsService {

  public onTokenVerified: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {
  }

  public requestPayment(invoiceId: number): Observable<any> {
    const url: string = `${ api }/payments/${ this.accountId }/${ invoiceId }`;
    return this.httpClient.post(url, {});
  }

  public checkIfPaymentProviderTokenExists(accountId: string): Observable<boolean> {
    const url = this.httpClient.normalizeUrl(accountApi.checkPaymentProviderToken(accountId));
    return this.httpClient.get(url).pipe(map((response) => {
      const hasToken = response.PaymentProviderToken;
      this.onTokenVerified.next(hasToken);
      return hasToken;
    }));
  }

  public clearToken() {
    this.onTokenVerified.next(false);
  }

  private get accountId(): string {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }
}
