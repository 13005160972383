import {Mortgage} from '../../../shared/mortgage';
import {Matter} from '../../../shared/matter';
import {Component, ElementRef, Inject, NgZone, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {dropDowns} from '../dropdown-options';
import {SelectItem} from 'primeng/api';
import {NetAdvance} from '../../../shared/net-advance';
import {CustomPickListModalComponent} from '../../../../shared/modal/custom-pick-list-modal.component';
import {customPickListKey} from '../../../../shared/modal/custom-pick-list-key';
import {SpecialComment} from '../../../matter-opening/';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import * as _ from 'lodash';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class NetAdvanceDialogContext {
  mortgage: Mortgage;
  matter: Matter;
}

@FocusFirstElementDecorator()

@Component({
  selector: 'dp-net-advance-dialog',
  templateUrl: 'net-advance.component.html',
  styleUrls: [ './net-advance.styles.scss' ]
})

export class NetAdvanceDialogComponent extends ModalComponent<NetAdvanceDialogContext> implements OnInit {
  mortgage: Mortgage;
  mortgageRef: Mortgage; // To be used if the user cancels the modal to reset the mortgage fields to the original
  matterRef: Matter; // To be used if the user cancels the modal to reset the matter fields to the original
  matter: Matter;
  totalNetAdvance: number = 0;
  calculationMethodDropDown: SelectItem[];
  mortgageeName: string = '';
  insertRowAboveLabel: string = 'Insert a row above';
  insertRowBelowLabel: string = 'Insert a row below';
  deleteButtonLabel: string = 'Delete row';
  totalNASpecificAmount: number = 0;
  selectFromListLabel: string = 'Select from List';
  pickListType: string = customPickListKey.MortgageAdvanceList;
  selectFromList: SpecialComment[];
  selectedFromList: SpecialComment;
  burgerMenuItems: string[] = [ this.insertRowAboveLabel, this.insertRowBelowLabel, this.deleteButtonLabel, this.selectFromListLabel ];
  netAdvanceItems: NetAdvance[] = [];

  constructor(public dialog: MatDialogRef<NetAdvanceDialogComponent, {netAdvanceItems: NetAdvance[]}>,
              public elementRef: ElementRef,
              public ngZone: NgZone,
              public dialogService: DialogService,
              public errorService: ErrorService,
              @Inject(MAT_DIALOG_DATA) context?: NetAdvanceDialogContext) {
    super(dialog, context);
  }

  get mortgageIndex(): number {
    let index = this.matter.mortgages.findIndex(item => item.id == this.mortgage.id);
    return index >= 0 ? (Number(index) + 1) : index;
  }

  ngOnInit(): void {
    this.mortgage = this.context.mortgage;
    this.matter = this.context.matter;
    this.mortgageRef = _.cloneDeep(this.mortgage);
    this.matterRef = _.cloneDeep(this.matter);
    this.buildCalculationMethodDropDownOptions();
    if (!this.mortgage.mortgageTerm.calculationMethod) {
      this.mortgage.mortgageTerm.calculationMethod = this.mortgage.insured == 'YES' ? 'CALCULATE_ADVANCE' : 'MANUAL_EDIT';
    }

    if (this.mortgage.mortgageTerm.totalNetAdvance == null) {
      this.mortgage.mortgageTerm.totalNetAdvance = 0;
    }

    if (this.matter.mortgagees && this.matter.mortgagees.length) {
      let mortgagee = this.matter.mortgagees.find(mortgagee => mortgagee.mortgageId == this.mortgage.id);
      if (mortgagee && mortgagee.contact) {
        this.mortgageeName = mortgagee.contact.displayName;
      }
    }

    if (this.mortgage.mortgageTerm && this.mortgage.mortgageTerm.netAdvanceItems && this.mortgage.mortgageTerm.netAdvanceItems.length > 0) {
      for (let i: number = 0; i < this.mortgage.mortgageTerm.netAdvanceItems.length; i++) {
        this.netAdvanceItems.push(new NetAdvance(this.mortgage.mortgageTerm.netAdvanceItems[ i ]));
      }
    } else {
      this.netAdvanceItems.push(new NetAdvance());
    }
    this.addInsuredItems();

    if (this.mortgage.mortgageTerm.isCalculationMethodFSpecificAmount()) {
      this.totalNASpecificAmount = this.mortgage.mortgageTerm.totalNetAdvance;
    }

    this.removeInputFocus();
    this.validateTotal();
  }

  ngOnDestroy(): void {
    this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.netAdvance.INVALID');
  }

  private buildCalculationMethodDropDownOptions() {
    this.calculationMethodDropDown = [ ...dropDowns.calcMethod ];
    const found = this.calculationMethodDropDown.find(i => i.value === 'MANUAL_EDIT');
    if (found) {
      found.label = this.matter.isMatterProvinceBC
        ? 'Manually edit Net Advance amount on Buyer Statement of Adjustments'
        : 'Manually edit Net Advance amount from Trust Ledger tab';
    }
  }

  cancelDialog() {
    this.matter.update(this.matterRef); // The matter is updated to match the the original state
    this.mortgage.update(this.mortgage); // The mortgage is updated to match the the original state
    this.dialog.close();
  }

  closeDialog() {
    let valid: boolean = this.validateTotal();
    if (valid) {
      this.matter.dirty = true;
      if (this.mortgage.mortgageTerm.calculationMethod === 'CALCULATE_ADVANCE') {
        this.mortgage.mortgageTerm.totalNetAdvance = this.netAdvanceTotal();
      } else if (this.mortgage.mortgageTerm.isCalculationMethodFSpecificAmount()) {
        this.mortgage.mortgageTerm.totalNetAdvance = this.totalNASpecificAmount;
      }
      this.dialog.close({
        netAdvanceItems: this.netAdvanceItems
      });
    }
  }

  deleteRowDialog(index: number) {
    this.dialogService.confirm('Confirmation', 'Are you sure you want to delete this row?', false, 'Delete').subscribe(res => {
      if (res == true) {
        this.netAdvanceItems.splice(index, 1);
      }
    });
  }

  cleanCurrencyAmount(index) {
    // hack to enable $0.00 value in rows above
    jQuery('#amountName-' + index).focus().focus();
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        jQuery('#amountName-' + index).blur();
      });
    });
  }

  addInsuredItems() {

    if (this.mortgage.insured == 'YES' && this.mortgage.mortgageTerm.calculationMethod == 'CALCULATE_ADVANCE') {
      let addItemInsured: boolean = true;
      let addItemPST: boolean = true;

      if (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceNBorNS) {
        addItemPST = false;
      }

      for (let i: number = 0; i < this.mortgage.mortgageTerm.netAdvanceItems.length; i++) {
        if (this.mortgage.mortgageTerm.netAdvanceItems[ i ].name === 'Less Insured Mortgage Fee') {
          addItemInsured = false;
        }
        if (this.mortgage.mortgageTerm.netAdvanceItems[ i ].name === 'Less PST on Insured Mortgage Fee') {
          addItemPST = false;
        }
      }
      if (addItemPST) {
        this.netAdvanceItems.unshift(new NetAdvance({name: 'Less PST on Insured Mortgage Fee', amount: 0}));
      }
      if (addItemInsured) {
        this.netAdvanceItems.unshift(new NetAdvance({name: 'Less Insured Mortgage Fee', amount: 0}));
      }
    } else {
      let netAdvanceItemIndex = this.netAdvanceItems.findIndex(item => item.name === 'Less Insured Mortgage Fee');
      if (netAdvanceItemIndex > -1) {
        this.netAdvanceItems.splice(netAdvanceItemIndex, 1);
      }
      let netAdvanceItemLessPstLessIndex = this.netAdvanceItems.findIndex(item => item.name === 'Less PST on Insured Mortgage Fee');
      if (netAdvanceItemLessPstLessIndex > -1) {
        this.netAdvanceItems.splice(netAdvanceItemLessPstLessIndex, 1);
      }
    }
  }

  burgerMenuClicked(clickedMenuOption: string, index: number): void {
    switch (clickedMenuOption) {
      case this.insertRowAboveLabel:

        if (index === 0) {
          this.netAdvanceItems.unshift(new NetAdvance());
        } else {
          this.netAdvanceItems.splice(index, 0, new NetAdvance());
        }
        this.cleanCurrencyAmount(index);
        break;
      case this.insertRowBelowLabel:
        if (index === (this.netAdvanceItems.length - 1)) {
          this.netAdvanceItems.push(new NetAdvance());
        } else {
          this.netAdvanceItems.splice((index + 1), 0, new NetAdvance());
        }

        break;
      case this.deleteButtonLabel:
        if (this.netAdvanceItems.length > 1) {
          this.deleteRowDialog(index);
        }

        break;
      case this.selectFromListLabel:
        this.openPickListModal(index);
        break;
      default:
        return;
    }
    this.removeInputFocus();
  }

  trackByIndex(index: number): number {
    return index;
  }

  amountNotAdvanced(): number {
    let amountNotAdvanced: number = this.totalNetAdvanceOrZero - this.netAdvanceTotal();
    if (isNaN(amountNotAdvanced)) {
      return Number(0);
    } else {
      return Number(amountNotAdvanced);
    }
  }

  get totalNetAdvanceOrZero() {
    if (isNaN(this.totalNASpecificAmount)) {
      return Number(0);
    } else {
      return Number(this.totalNASpecificAmount);
    }
  }

  get mortgageDisplayTextValue() {
    switch (this.mortgage.loanType) {
      case 'ARRANGED':
        if (this.mortgage.mortgageeType === 'PRIVATE_LENDER') {
          return 'Received from ' + this.getMortgagePriorityText() + ' mortgagee - ' + this.privateLendersNames;
        } else {
          return 'Received from ' + this.getMortgagePriorityText() + ' mortgagee - ' + this.mortgageeName;
        }
      case 'ASSUMED':
        return '';
      case 'BACK_TO_VENDOR':
        return '';
      case 'BRIDGE':
        return 'Received from Bridge Financing';
      default:
        return '';
    }
  }

  get privateLendersNames() {
    let names: string = '';
    if (this.matter.privateLenders[ 0 ] && this.matter.privateLenders[ 0 ].contact) {
      for (let i = 0; i < this.matter.privateLenders.length; i++) {
        if (i == (this.matter.privateLenders.length - 1)) {
          if (this.matter.privateLenders[ i ].contact.isContactOrganizationType()) {
            names = names + this.matter.privateLenders[ i ].contact.organizationName.trim();
          } else {
            names = names + this.matter.privateLenders[ i ].contact.fullNameStartWithFirstName.trim();
          }
        } else if (i == (this.matter.privateLenders.length - 2)) {
          if (this.matter.privateLenders[ i ].contact.isContactOrganizationType()) {
            names = names + this.matter.privateLenders[ i ].contact.organizationName.trim() + ' and ';
          } else {
            names = names + this.matter.privateLenders[ i ].contact.fullNameStartWithFirstName.trim() + ' and ';
          }
        } else {
          if (this.matter.privateLenders[ i ].contact.isContactOrganizationType()) {
            names = names + this.matter.privateLenders[ i ].contact.organizationName.trim() + ', ';
          } else {
            names = names + this.matter.privateLenders[ i ].contact.fullNameStartWithFirstName.trim() + ', ';
          }
        }
      }
    }
    return names;
  }

  validateTotal(): boolean {
    let valid: boolean = true;
    let total: number = this.netAdvanceTotal();
    if (total < 0) {
      valid = false;
      this.errorService.addDpFieldError(DPError.createDPError('mortgage.' + this.mortgageIndex + '.term.netAdvance.INVALID'));
    } else {
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.term.netAdvance.INVALID');
    }
    return valid;
  }

  netAdvanceTotal(): number {
    let subtotal: number = 0;
    let principal: number = this.mortgage.mortgageTerm.principal;

    for (let i = 0; i < this.netAdvanceItems.length; i++) {
      subtotal = Number(subtotal) + Number(this.netAdvanceItems[ i ].amount);
    }

    let total: number = Number(principal) + Number(subtotal);
    if (isNaN(total)) {
      return Number(0);
    } else {
      let returnValue: number = Number(total);
      return returnValue;
    }
  }

  nameInputKeyPress(event, i) {
    if (event.key === 'Delete') {
      this.netAdvanceItems[ i ].name = '';
    }

    if (event.key === 'Insert') {
      this.netAdvanceItems.splice(i + 1, 0, new NetAdvance());
    }
  }

  onCalcMethodChange(e) {
    if (e === 'SPECIFY_AMOUNT') {
      this.mortgage.mortgageTerm.totalNetAdvance = 0;
    }
    this.addInsuredItems();
    this.removeInputFocus();
  }

  removeInputFocus() {
    // ie hack to remove focus on fields when component loads and changing selection method
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        jQuery('input').blur();
      });
    });
  }

  setPickListItem(comment: SpecialComment, index: number): void {
    //this.displayPickList = false;
    switch (this.pickListType) {
      case customPickListKey.MortgageAdvanceList:
        //  this.netAdvanceItems.push(new NetAdvance({name: comment.customPickListItemValue, amount: 0}));
        this.netAdvanceItems[ index ].name = comment.customPickListItemValue;
        break;
      default:
        break;
    }
  }

  public openPickListModal(index): void {
    this.dialogService.matDialogContent({
      content: CustomPickListModalComponent,
      context: {pickListType: this.pickListType},
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'select') {
            this.setPickListItem(result.comment, index);
          }
          this.focusOnFirstElement();
        }
      },
    });
  }

  focusOnFirstElement(): void {
    let calcMethodElement = document.getElementById('calcMethod');
    if (calcMethodElement) {
      calcMethodElement.focus();
    }
  }

  getMortgagePriorityText(): string {
    return this.mortgage.mortgagePriority + this.ordinalPrefix(this.mortgage.mortgagePriority);
  }

  ordinalPrefix(ordinal: number): string {
    return (ordinal === 1 ? 'st' : ordinal === 2 ? 'nd' : ordinal === 3 ? 'rd' : 'th');
  }
}
