<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" *ngIf="amountPaidOnInterimClosing" >
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Adjustment Type : Amount Paid on Interim Closing</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>

        <div class="modal-body">
            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-4  vertical-align-label">
                        <label class="control-label">Credit Purchaser with</label>
                    </div>
                    <div class="col-lg-5">
                        <select type="text"
                                id="adjustmentFormat"
                                name="adjustmentFormat"
                                class="form-control"
                                [(ngModel)]="amountPaidOnInterimClosing.creditPurchaserWith"
                        >
                            <option *ngFor="let creditPurchaserWith of creditPurchaserWithOptions" [value]="creditPurchaserWith.value">
                                {{creditPurchaserWith.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-4 vertical-align-label">
                        <label class="control-label">Adjustment heading</label>
                    </div>
                    <div class="col-lg-5">
                        <input type="text"
                               name="adjustmentHeading"
                               id="adjustmentHeading"
                               maxlength="38"
                               [(ngModel)]="amountPaidOnInterimClosing.adjustmentHeading"
                               trimmed
                               class="form-control"
                               dp-error-validator
                               [fieldKey]="'matter.soadj.amount.paid.on.interim.closing.heading'"
                        />
                    </div>
                </div>
            </div>



            <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <label class="control-label"
                        >{{amountPaidOnInterimClosing.adjustmentHeading}}</label>
                    </div>
                </div>

                <div class="col-lg-12  form-group" >
                    <div class="col-lg-4">
                        <label class="control-label"
                        >Credit Purchaser</label>
                    </div>
                    <div class="col-lg-6">
                        <label class="control-label col-lg-4 text-right">
                            {{getCreditPurchaserAmount() |  currency:CAD:'symbol' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" >
                    <span>Delete</span>
                </button>

            </div>
            <div class="col-lg-2">
                <dp-checkbox fieldId="checkBoxInfoOnly">
                    <input type="checkbox"
                           id="checkBoxInfoOnly"
                           name="checkBoxInfoOnly"
                           [(ngModel)]="amountPaidOnInterimClosing.infoOnly"
                           [checked]="amountPaidOnInterimClosing.infoOnly"/>
                </dp-checkbox>

                <label class="control-label vertical-align-label display-inline" >Info Only</label>
            </div>
        </div>
        <div style="padding: 5px 10px 0px 5px;">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </form>
</div>
