<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-11">
                <h1>Adjacent Parcel Analysis</h1>
            </div>
            <div class="col-lg-1">
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>
    </div>

    <div class="modal-body">

        <div class="row col-lg-12 alert alert-info" style="padding: 20px 60px 20px 60px">
            <i class="fa fa-info-circle" aria-hidden="true" style="float:left; font-size:20px; vertical-align: middle;">&nbsp;&nbsp;&nbsp;</i>

            <span style="float:left">
                <p *ngFor="let ita of infoTextArea">{{ita}}</p>
            </span>
        </div>

        <div class="row col-lg-12" style="height: 10px;">
        </div>

        <div class="row col-lg-12 comparison"  *ngIf="isAdjacentParcelAnalysisAvailable">

            <table class="standard-table">
                <thead>
                <tr>
                    <th class="width-30-percent">Common Surname</th>
                    <th class="width-35-percent">Primary Parcel Register</th>
                    <th class="width-35-percent">Adjacent Parcel Register</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let paa of parcelAdjacentAnalysis; let i = index;">
                    <td>{{paa.surname}}</td>
                    <td>
                        <ul>
                            <li class="liPadded">
                                <span >{{paa.primaryParcelData.pin}}</span>
                            </li>

                            <li>
                                <div *ngIf="paa.primaryParcelData.instrument.instrumentDocument.isDocumentAvailableForView">
                                    <span (click)="viewInstrument(paa.primaryParcelData.instrument)">
                                        <i class="fa fa-file-pdf-o"></i>
                                    </span>
                                    {{paa.primaryParcelData.instrument.instrumentNumber}} - {{paa.primaryParcelData.instrument.instrumentDate}}
                                </div>
                                <div *ngIf="!paa.primaryParcelData.instrument.instrumentDocument.isDocumentAvailableForView">
                                    <dp-checkbox fieldId="primaryParcelDataCheckBox_{{i}}" [fieldClass]="{'focus-first' : i === focusFirst.primary}">
                                        <input type="checkbox"
                                               id="primaryParcelDataCheckBox_{{i}}"
                                               (change)="updateSelection(true,i)"
                                               [checked]="paa.primaryParcelData.selected"  />
                                    </dp-checkbox>
                                    <label for="primaryParcelDataCheckBox_{{i}}" >
                                        {{paa.primaryParcelData.instrument.instrumentNumber}} - {{paa.primaryParcelData.instrument.instrumentDate}}
                                    </label>
                                </div>
                            </li>
                            <li class="liPadded"><span *ngIf="paa.primaryParcelData.instrument.instrumentStatus == 'D'">*** Deleted ***</span>
                                {{paa.primaryParcelData.instrument.type}} {{paa.primaryParcelData.surnameFrom}}
                            </li>
                        </ul>

                    </td>
                    <td>
                        <ul>
                            <li class="liPadded">
                                <span>{{paa.adjacentParcelData.pin}}</span>
                            </li>

                            <li >
                                <div *ngIf="paa.adjacentParcelData.instrument.instrumentDocument.isDocumentAvailableForView">
                                    <span (click)="viewInstrument(paa.adjacentParcelData.instrument)">
                                        <i class="fa fa-file-pdf-o font-20" aria-hidden="true"></i>
                                    </span>
                                    {{paa.adjacentParcelData.instrument.instrumentNumber}} - {{paa.adjacentParcelData.instrument.instrumentDate}}
                                </div>
                                <div *ngIf="!paa.adjacentParcelData.instrument.instrumentDocument.isDocumentAvailableForView">
                                    <dp-checkbox fieldId="adjacentParcelDataCheckBox_{{i}}" [fieldClass]="{'focus-first' : i === focusFirst.adjacent}">
                                        <input type="checkbox"
                                               id="adjacentParcelDataCheckBox_{{i}}"
                                               (change)="updateSelection(false,i)"
                                               [checked]="paa.adjacentParcelData.selected"  />
                                    </dp-checkbox>
                                    <label for="adjacentParcelDataCheckBox_{{i}}">
                                        {{paa.adjacentParcelData.instrument.instrumentNumber}} - {{paa.adjacentParcelData.instrument.instrumentDate}}
                                    </label>
                                </div>
                            </li>
                            <li class="liPadded">
                                <span *ngIf="paa.adjacentParcelData.instrument.instrumentStatus == 'D'">*** Deleted ***</span>
                                {{paa.adjacentParcelData.instrument.type}} {{paa.adjacentParcelData.surnameFrom}}
                            </li>
                        </ul>

                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="row col-lg-12 text-center"  *ngIf="!isAdjacentParcelAnalysisAvailable">
            <span>No common surnames found against the Primary Properties where Adjacent Parcels were purchased.</span>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-10 buttons">
            <div  *ngIf="isAdjacentParcelAnalysisAvailable">
                <button
                    type="button"
                    id="orderBtn"
                    class="dp-btn"
                    [ngClass]="{'dp-btn-green' : anyInstrumentSelected }"
                    (click)="orderInstruments()"
                    [disabled]="!anyInstrumentSelected" >
                    <span>Order Selected Instrument</span>
                </button>

                <button type="button" id="cancelBtn" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
            </div>

            <div  *ngIf="!isAdjacentParcelAnalysisAvailable">
                <button type="button" id="okayBtn" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>OK</span>
                </button>
            </div>
        </div>
        <div class="col-lg-2">
                <span class="info-icon">
                    <i class="fa fa-info-circle font-14"  aria-hidden="false"></i><span>&nbsp;Disclaimer</span>
                </span>
            <span class="matter-locked-message">
                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                    <div class="disclaimer"> <span>
                        "Adjacent Parcel Analysis" searches for common <br>
                        surnames in the transfer-type documents in the <br>
                        Primary Parcel Register and in the Parcel<br>
                        Registers for the adjacent parcels selected.<br>
                        Adjacent Parcel Analysis is restricted to the <br>
                        automated records.<br><br>
                        - User should review any transfer-type<br>
                        documents that are not abstracted with party<br>
                        names.<br><br>
                        - User should consider whether it is necessary<br>
                        to perform a review of non-automated records.<br><br>
                        - All adjacent properties should be investigated<br>
                        for descriptive inconsistencies, if any, with<br>
                        description represented for the subject property.
                        </span>
                    </div>
                </span>

        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
