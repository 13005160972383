import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[ngModel][trimmed]',
  host: {
    '(blur)': 'TextChange($event)',
    '(focusout)': 'TextChange($event)' // for p-autoComplete
  }
})
export class TrimmedInput {

  constructor(private model: NgModel) {
  }

  TextChange(input) {
    if (this.model.value && (this.model.value != this.model.value.trim())) {
      this.model.reset(this.model.value.trim());
      this.model.valueAccessor.writeValue(this.model.value.trim());
    }
  }
}
