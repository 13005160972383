import {api, apiV3, SESSION_ID_REQ_PARAM} from '../../common/api';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';

/** Application endpoint urls
 * Data properties in the response payload
 *  //FIXME: these should be defined separately,
 * and also standardized (naming convention is inconsistent)
 */
// TODO: Since we are storing the APIs for the entire App, this needs to be moved to the shared module
export const matterApi = {

  matters: `${ apiV3 }/matters`,

  mattersForAccount(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/matters`;
  },

  saveUtility : `${api}/utility`,

  getUtility(utilityid: number): string { return `${api}/matters/${utilityid}/utility` },

  lockMattersByIds(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/matters/lock`;
  },

  unlockMattersByIds(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/matters/unlock`;
  },

  mattersIdsForAccount(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/matters/ids`;
  },

  associatedMattersAndOpportunitiesCountForAccount(accountId: string) {
    return `${ api }/accounts/${ accountId }/associatedmattersandopportunitiescount`;
  },

  exportMattersForAccount(accountId: string): string {
    return this.mattersForAccount(accountId) + '/exportToCsv';
  },

  exportMattersUsingReportTemplate(accountId: string): string {
    return this.mattersForAccount(accountId) + '/reportTemplates/{reportTemplateId}/exportToCsv';
  },

  recentlyAccessedMatters(userId: string): string {
    return `${ apiV3 }/users/${ userId }/recentlyAccessedMatters`;
  },

  matterListFilters(accountId: string): string {
    return `${ api }/accounts/${ accountId }/matterListFilters`;
  },

  referenceMatterType: `${ api }/reference/matter/matterTypes`,

  soaExport(matterId: number): string {
    return `${ api }/matters/${ matterId }/soaExport`;
  },

  getTemplates(matterId: number): string {
    return `${ api }/matter/${ matterId }/templates`;
  },

  documentTemplateCategories(accountId: string): string {
    return `${ api }/accounts/${ accountId }/documentTemplateCategories`;
  },

  generateDocument: `${ api }/matters/{matterId}/documents`,

  evaluateCodes: `${ api }/document/evaluateCodes`,

  generateDepositsForm4Document: `${ api }/matters/{matterId}/documents/depositForm4`,

  generateMultipleDepositsForm4ForSingleMatter: `${ api }/matters/{matterId}/documents/multipleDepositsForm4`,

  generateMultipleDepositsForm4: `${ api }/documents/multipleDepositsForm4`,

  generateDepositsForm4Preview: `${ api }/matters/{matterId}/documents/depositForm4Preview`,

  multipleTemplatesMasterDocument: `${ api }/matters/{matterId}/documentTemplates/multipleTemplatesMasterDocument`,

  produceMergeMultipleForms: `${ api }/matters/{matterId}/documents/forms/multiTemplateForm`,

  multipleTemplatesForPurchasers: `${ api }/matters/{matterId}/documentTemplates/mergedPDFFormDocument`,

  generateMultipleMatterDocuments: `${ api }/matters/{matterId}/mattersList/documents`,

  generateMergedMultipleDocuments: `${ api }/accounts/{accountId}/documentTemplates/{documentTemplateId}/mergedMattersDocument `,

  renameDocument: `${ api }/matters/{matterId}/documents/{documentId}/rename`,

  copyDocument: `${ api }/matters/{matterId}/documents/{documentId}/copy`,

  copyThirdPartyDocument: `${ api }/matters/{matterId}/thirdPartyDocuments/{thirdPartyDocumentId}/copy`,

  deleteDocument: `${ api }/matters/{matterId}/documents/{documentId}`,

  uploadDocument: `${ api }/matters/{matterId}/documents/upload`, //Not really a restlike URL, but we have the root taken by the generation operation

  uploadSpinDocument: `${ api }/integrations/spin/matters/{matterId}/documents/upload`,

  uploadTPRDocument: `${ api }/integrations/tpr/matters/{matterId}/documents/upload`,

  uploadISCDocument: `${ api }/integrations/isc/matters/{matterId}/documents/upload`,

  downloadWordDocument: `${ api }/matters/{matterId}/documents/{documentId}/download`,

  generateDocumentForSupplementalTaskCategory: `${ api }/matters/{matterId}/documents/supplementalTasks/{supplementalTaskCategoryId}`,

  getDocumentsForMatter: `${ api }/matters/{matterId}/documents`,

  getDocumentsMetaDataForMatter: `${ api }/matters/{matterId}/documents/metadata`,

  getDocument: `${ api }/matters/{matterId}/documents/{documentId}`,

  downloadZip: `${ api }/matters/{matterId}/documents/zip`,

  downloadDocument: `${ api }/matters/{matterId}/documents/{documentId}/view?${ SESSION_ID_REQ_PARAM }={sessionId}`,

  openDocumentInline: `${ api }/matters/{matterId}/documents/{documentId}/open?${ SESSION_ID_REQ_PARAM }={sessionId}`,

  generateIdentificationForm: `${ api }/matters/{matterId}/documents/identificationForms`,

  checkAccountDocumentStatus: `${ api }/accounts/{accountId}/documents/{documentId}/status `,

  getGovernmentSiteLinks: `${ api }/public/governmentSiteLinks`,

  systemTemplates: `${ api }/documentTemplates/systemTemplates`,

  formTemplates: `${ api }/system/formTemplates?matterType={matterType}&categories={category}&provinceCode={provinceCode}`,

  validateTPRForm: `${ api }/matters/{matterId}/eForms/{eFormType}/validate`,

  produceTPRForm: `${ api }/matters/{matterId}/eForms/{eFormType}/produce`,

  deleteTPRForm: `${ api }/matters/{matterId}/eForms/{eFormId}`,

  contacts: `${ api }/contacts`,

  filterByActiveUsers: `${ api }/public/contacts/filterByActiveUsers/`,

  contactsLockStatus(accountId: string): string {
    return `${ api }/accounts/${ accountId }/contacts/lockstatus`;
  },

  proxyForGlobal: `${ api }/contacts/proxy`,

  createContacts: `${ api }/accounts/*/contacts`,

  contactForMatter(contactId: number): string {
    return `${ api }/contacts/${ contactId }/contactForMatter`;
  },

  softDeleteContact(contactId: number): string {
    return `${ api }/contacts/${ contactId }`;
  },

  otherSideLawClerkContact(contactId: number): string {
    return `${ api }/contacts/lawClerk/${ contactId }`;
  },

  contactsList(accountId: string): string {
    return `${ api }/accounts/${ accountId }/contactsList`;
  },

  searchSolicitors(accountId: string): string {
    return `${ api }/accounts/${ accountId }/searchSolicitors`;
  },

  lendersContactList(accountId: string): string {
    return `${ api }/accounts/${ accountId }/lendersContactList`;
  },

  jurisdictions: `${ api }/public/jurisdictions`,

  departments: `${ api }/public/departments`,

  registryOffice: `${ api }/public/registryOffices`,

  telusPayoutRegistryOffice: `${ api }/reference/telusPayoutRegistryOffices/{provinceCode}`,

  customPickLists: `${ api }/customPickLists`,

  accountCustomPickLists(accountId: string): string {
    return `${ api }/accounts/${ accountId }/customPickLists`;
  },

  insurers: `${ api }/contacts/titleInsurers`,

  purchaser: `${ api }/matters/{id}/participants`,

  vendor: '/api/v2/contacts/partyContacts',

  genderCode: '/api/v2/reference/genderCodes',

  organizations(accountId: string): string {
    return `${ api }/accounts/${ accountId }/contacts/organizations`;
  },

  persons(accountId: string): string {
    return `${ api }/accounts/${ accountId }/contacts/persons`;
  },

  participants: `${ api }/matters/participants/`,

  flaStaements: `${ api }/reference/familyLawActStatementTypes`,

  municipalityConfigurationTypes: `${ api }/reference/municipalityConfigurations`,

  duplicateOrganization: `${ api }/contacts/duplicateOrganizations`,

  considerationTaxRate: `${ api }/reference/config/tax/taxRates`,

  soaFeesRate: `${ api }/reference/soaFeesRates`,

  soaFeesRateByKey(soaFeeRateKey: string): string {
    return `${ api }/reference/soaFeesRates/${ soaFeeRateKey }`;
  },

  requisitions(accountId: string): string {
    return `${ api }/accounts/${ accountId }/requisitions`;
  },

  produceRequisition(matterId: number): string {
    return `${ api }/matters/${ matterId }/requisitions`;
  },

  paragraphTemplateByGender: `${ api }/reference/paragraphTemplates/findByGender`,

  paragraphTemplates: `${ api }/reference/paragraphTemplates`,

  generateStatementOfAdjustmentPdfPreview: `${ api }/matters/{matterId}/documents/statementOfAdjustmentPdfPreview`,

  generateStatementOfAdjustmentEditablePreview: `${ api }/matters/{matterId}/documents/statementOfAdjustmentEditablePreview`,

  generateStatementOfAdjustmentPdfPreviewMatterDocument: `${ api }/matters/{matterId}/documents/statementOfAdjustment/pdf/generate`,

  generateDirectionReFundsPreview: `${ api }/matters/{matterId}/documents/directionReFundsPreview`,

  generateDirectionReFundsPreviewMatterDocument: `${ api }/matters/{matterId}/documents/directionReFunds/pdf/generate`,

  reportToPurchaserStatementOfAdjustmentPreview: `${ api }/matters/{matterId}/documents/reportToPurchaserStatementOfAdjustmentPreview`,

  generateAmortizationSchedule(matterId: string): string {
    return `${ api }/matters/${ matterId }/documents/amortizationSchedule`;
  },

  downloadERegXML: `${ api }/matters/{matterId}/eReg/xml`,

  downloadERegMortgageXML: `${ api }/matters/{matterId}/mortgages/{mortgageId}/eReg/xml`,

  downloadERegDischargeXML: `${ api }/matters/{matterId}/mortgages/{existingMortgageId}/discharge/eReg/xml`,

  openERegXML: `${ api }/{sessionId}/matters/{matterId}/documents/eReg/{eRegFormId}/{fileName}`,

  getERegXML: `${ api }/matters/{matterId}/documents/eReg/{eRegFormId}/XMLData`,

  lenders: `${ api }/public/lenders`,

  createLender: `${ api }/documentServices/lenders`,

  postLender: `${ api }/public/lenders/{id}?partialUpdate=false`,

  lenderForms: `${ api }/system/lenders`,

  postLenderForms: `${ api }/system/lenderForms/`,

  rentInterestRates: `${ api }/reference/config/rate/rentInterestRates`,

  landTransferTaxRates: `${ api }/reference/config/rate/landTransferTaxRates`,

  produceDocumentSingleTypeAsync: `${ api }/matters/{matterId}/produceDocumentSingleTypeAsync/{templateName}/type/PDF/eregforms/{eRegFormIndex}/mortgages/{mortgageIndex}`,

  tarionWarrantyEnrolmentPeriods: `${ api }/reference/config/rate/tarionWarrantyEnrolmentPeriods`,

  hcraEnrolmentPeriods: `${ api }/reference/config/rate/hcraEnrolmentPeriods`,

  unassignMortgageInstruction: `${ api }/matters/{matterId}/emp/{mortgageInstructionId}/unassign`,

  cancelMortgageInstruction: `${ api }/matters/{matterId}/emp/cancel`,

  convertToPDF: `${ api }/matters/{matterId}/documents/{documentId}/convertToPDF`,

  postTransactionHistoryCSV(accountId: string): string {
    return `${ api }/account/${ accountId }/transactionHistory/upload`;
  },

  zendeskUrl: `${ api }/public/zendeskUrl?zendeskUrlType=`,

  manitobaLTTTiers: `${ api }/reference/manitobaLTTTiers`,

  documentForTeraviewUpload: `${ api }/{sessionId}/matters/{matterId}/documents/{documentId}/pdf/{fileName}`,

  bulkUpdateForMatters(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/matters/bulkUpdate`;
  },

  asyncBulkUpdateForMatters(accountId: string): string {
    return `${ apiV3 }/accounts/${ accountId }/matters/asyncBulkUpdate`;
  },

  lockMatter(matterId: string): string {
    return `${ api }/matters/${ matterId }/lock`;
  },

  searchInstitutionByLenderKeywords(institutionName: string): string {
    return `${ api }/public/lenders/lookupByKeyword?institutionName=${ institutionName }`;
  },

  documentDataSet(matterId: number, documentId: number): string {
    return `${ api }/matters/${ matterId }/documents/${ documentId }/documentDataSet`;
  },
  depositManagerList(accountId: string): string {
    return `${ api }/accounts/${ accountId }/projects/deposits`;
  },

  updateDepositsForm4Date(accountId: string): string {
    return `${ api }/accounts/${ accountId }/projects/deposits/form4date`;
  },

  depositManagerListCSV(accountId: string): string {
    return `${ api }/accounts/${ accountId }/projects/deposits/csv`;
  },

  tarionManagerListCSV(accountId: string): string {
    return `${ api }/accounts/${ accountId }/projects/tarion/csv`;
  },

  setERegistrationBulkStatusUpdate: `${ api }/matters/{matterId}/eRegistrations/bulkUpdate`,

  interestRates: `${ api }/reference/interestRates`,

  templateAdjustments(accountId: string): string {
    return `${ api }/customerAccounts/${ accountId }/templateAdjustments`;
  },

  templateAdjustment(accountId: string, templateAdjustmentId: number): string {
    return `${ api }/customerAccounts/${ accountId }/templateAdjustments/${ templateAdjustmentId }`;
  },

  mtgHoldbackConfig(key: string, provinceCode: ProvinceCode, closingDate?: string): string {
    return `${ api }/reference/mtgHoldbackConfig/${ key }?provinceCode=${ provinceCode }${ closingDate ? '&effectiveDate=' + closingDate : '' }`;
  },

  conveyCaUrl: `${ api }/public/conveyCaUrl`,

  shareMatterDocumentPackage: `${ api }/matters/{matterId}/documents/sharedPackages`,

  sharedDocumentPackageSummary: `${ api }/matters/{matterId}/documents/sharedPackages/summary`,

  importCirfDocument: `${ api }/matters/{matterId}/cirfs/{cirfGuid}/documents/{documentId}/copyToMatter`,

  linkSharedDocument: `${ api }/sharedDocumentsPackages/{packageId}/linkToMatter/{matterId}`,

  unlinkLinkMatterFromSharedDocsPackage: `${ api }/unity/sharedDocumentsPackages/{packageGuid}/unlink`,

  isGlobalSolicitorExists(accountId: string, solicitorId: string): string {
    return `${ api }/accounts/${ accountId }/solicitor/${ solicitorId }/checkGlobalSolicitorExists`;
  },

  copyMatterDocument: `${ api }/matters/{matterId}/documents/copyFrom/{sourceMatterId}/{documentId}`,

  lockContact(contactId: string): string {
    return `${ api }/contacts/${ contactId }/lock`;
  },

  importTemplatesByCategory(accountId: string, accountFileFolderId: string, documentTemplateCategory: string): string {
    return `${ api }/accounts/${ accountId }/accountFileFolders/${ accountFileFolderId }/documentTemplateCategories/${ documentTemplateCategory }/import`;
  },

  copyReferralDocumentToMatter: `${ api }/matters/{matterId}/referrals/{referralId}/documents/{documentId}/copyToMatter`,

  toggleProjectDocumentAutoShare: `${ api }/projects/{projectId}/matters/{matterId}/documents/{documentId}/toggleAutoShare`,

  bulkShare: `${ api }/accounts/{accountId}/matters/documents/sharedPackages/bulkShare`,

  revokePackage: `${ api }/sharedDocumentsPackages/{packageGuid}/revoke`,

  revokeDocument: `${ api }/sharedDocumentsPackages/{packageGuid}/documents/{documentId}/revoke`,

  massUpdateTransactionById(accountId: string, id: number): string {
    return `${ api }/accounts/${ accountId }/matters/massUpdateTransactions/${ id }`;
  },

  docuSignPolling: `${ api }/matters/{matterId}/events/metadata`,

  documentMoveToSubfolder: `${ api }/matters/{matterId}/documents/{documentId}/moveToSubfolder`,

  copyMatterFromOpportunity(accountId: string, opportunityId: number): string {
    return `${ api }/accounts/${ accountId }/matters/fromOpportunity/${ opportunityId }`;
  },

  updateMatterFromOpportunity(accountId: string, matterId: number, opportunityId: number): string {
    return `${ api }/accounts/${ accountId }/matters/${ matterId }/fromOpportunity/${ opportunityId }`;
  },

  enableMatterEditingPostClosingFlag(accountId: number, matterId: number): string {
    return `${ api }/accounts/${ accountId }/matters/${ matterId }/enableMatterPostClosing`;
  },

  mattersAssociatedWithOpportunity(accountId: string, opportunityId: number): string {
    return `${ api }/accounts/${ accountId }/opportunity/${ opportunityId }/matters`;
  },

  duplicateOpportunities(accountId: string, opportunityId: number): string {
    return `${ api }/accounts/${ accountId }/opportunity/${ opportunityId }/duplicates`;
  },

  chargeTeranetConnect(id: string): string {
    return `${ api }/matters/${ id }/charge/teranetConnect`;
  },

  sendUnityMessage(userId: number): string {
    return `${ api }/users/${ userId }/messages`;
  },

  searchTaskNames(accountId: string): string {
    return `${ api }/accounts/${ accountId }/matters/tasks`;
  }

};

export const matterBaseUrl: string = 'main/tabs/matter';
export const opportunityBaseUrl: string = 'main/opportunity-matter/matter';
