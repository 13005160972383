import {MortgageCapacityType} from '../../shared/mortgage';
//import {provinceBasedMatterPurchasersCapacityOptions} from "../../../shared-main/province-based-dropdowns";
//import { mortgageCapacityDropDownOptions } from '../../../shared-main/province-based-dropdowns';

export class CapacityTypes {
  public static readonly UNSPECIFIED_CAPACITY = 'UNSPECIFIED_CAPACITY';
  public static readonly JOINT_TENANTS = 'JOINT_TENANTS';
  public static readonly TENANTS_IN_COMMON_SPLIT_SHARES = 'TENANTS_IN_COMMON_SPLIT_SHARES';
  public static readonly TENANTS_IN_COMMON_UNSPECIFIED_SHARES = 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES';
  public static readonly REGISTERED_OWNER = 'REGISTERED_OWNER';
  public static readonly OTHER = 'OTHER';
}

export class PurchaserCapacity {

  static defaultMatterCapacityValue: string = 'UNSPECIFIED_CAPACITY';

  static defaultMortgageCapacityValue: string = 'SILENT';

  static matterPurchasersCapacityOptions = {
    'MatterPurchasersCapacityOptions_Default': [
      {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (Capacity not to be specified)'},
      {value: 'JOINT_TENANTS', label: 'As joint tenants'},
      {
        value: 'TENANTS_IN_COMMON_SPLIT_SHARES',
        label: '---'
      },  //A label taking the number of purchasers will be calculated on the fly
      {value: 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES', label: 'As tenants in common (without specifying shares)'},
      {value: 'REGISTERED_OWNER', label: 'Registered owner'},
      {value: 'OTHER', label: 'Other (a response must be entered in the "Capacity" field)'}
    ],
    'MatterPurchasersCapacityOptions_AB': [
      {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (Capacity not to be specified)'},
      {value: 'JOINT_TENANTS', label: 'As joint tenants'},
      {value: 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES', label: 'As tenants in common'},
      {value: 'OTHER', label: 'Other (a response must be entered in the "Tenure/Share" field)'}
    ],
    'MatterPurchasersCapacityOptions_BC': [
      {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (Tenancy not to be specified)'},
      {value: 'JOINT_TENANTS', label: 'As joint tenants'},
      {value: 'OTHER', label: 'Fractional Interest (a response must be entered in the “Tenancy/Fractional Interest” field)'}
    ],
    'MatterPurchasersCapacityOptions_NB': [
      {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (Capacity not to be specified)'},
      {value: 'JOINT_TENANTS', label: 'As joint tenants'},
      {value: 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES', label: 'As tenants in common'},
      {value: 'OTHER', label: 'Other (a response must be entered in the "Tenure/Share" field)'}
    ],
    'ConnectCapacityOptions_AB': [
      {label: '', value: 'UNSPECIFIED_CAPACITY'},
      {label: 'I don\'t know', value: 'I_DONT_KNOW'},
      {label: 'As joint tenants', value: 'JOINT_TENANTS'},
      {label: 'As tenants in common', value: 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES'},
      {label: 'Other', value: 'OTHER'}
    ],
    'MatterPurchasersCapacityOptions_SK': [
      {value: 'UNSPECIFIED_CAPACITY', label: 'Not Specified'},
      {value: 'JOINT_TENANTS', label: 'As Joint Tenants'},
      {
        value: 'TENANTS_IN_COMMON_SPLIT_SHARES',
        label: '---'
      },  //A label taking the number of purchasers will be calculated on the fly
      {value: 'TENANTS_IN_COMMON_UNSPECIFIED_SHARES', label: 'Joint Tenants with no survivorship'},
      {value: 'OTHER', label: 'Other (a response must be entered in the "Tenure/Share" field)'}
    ],
    'MatterPurchasersCapacityOptions_MB': [
      {value: 'UNSPECIFIED_CAPACITY', label: 'Not Specified'},
      {value: 'JOINT_TENANTS', label: 'As Joint Tenants'},
      {
        value: 'TENANTS_IN_COMMON_SPLIT_SHARES',
        label: '---'
      },  //A label taking the number of purchasers will be calculated on the fly
      {value: 'OTHER', label: 'Other (a response must be entered in the "Tenure/Share" field)'}
    ]
  };

  static provinceBasedMatterPurchasersCapacityOptions = {
    'ON': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_Default,
    'NB': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_NB,
    'NS': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_Default,
    'AB': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_AB,
    'MB': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_MB,
    'SK': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_SK,
    'BC': PurchaserCapacity.matterPurchasersCapacityOptions.MatterPurchasersCapacityOptions_BC
  };

  static mortgageCapacityOptions = {
    'MortgageCapacityOptions_Default': [
      {label: 'Silent (Capacity not to be specified)', value: 'SILENT'},
      {label: 'On joint account with right of survivorship', value: 'JOINT'},
      {label: '{Label does not matter, will be set dynamically by the component}', value: 'COMMON_SPECIFIED'},
      {label: 'As tenants in common (without specifying shares)', value: 'COMMON_UNSPECIFIED'},
      {label: 'Other (a response must be entered in the "Capacity" field)', value: 'OTHER'}
    ],
    'MortgageCapacityOptions_AB': [
      {label: 'Not Specified', value: 'SILENT'},
      {label: 'As joint tenants', value: 'COMMON_SPECIFIED'},
      {label: 'On joint account', value: 'JOINT'},
      {label: 'As tenants in common', value: 'COMMON_UNSPECIFIED'} ],
    'MortgageCapacityOptions_SK': [
      {label: 'Not Specified', value: 'SILENT'},
      {label: 'As joint tenants', value: 'JOINT'},
      {label: '{Label does not matter, will be set dynamically by the component}', value: 'COMMON_SPECIFIED'},
      {label: 'As tenants in common', value: 'COMMON_UNSPECIFIED'},
      {label: 'Other (complete the "Tenure/Share" field for each individual)', value: 'OTHER'}
    ],
    'MortgageCapacityOptions_MB': [
      {label: 'Not Specified', value: 'SILENT'},
      {label: 'As joint tenants', value: 'JOINT'}
    ],
    // MortgageCapacityType doesn't include JOINT_TENANTS
    'MortgageCapacityOptions_NB_NS': [
      {label: 'Not Specified', value: 'SILENT'},
      {label: 'As joint tenants', value: 'JOINT'},
      {label: 'As tenants in common', value: 'COMMON_UNSPECIFIED'},
      {label: 'Other (a response must be entered in the "Tenure" field)', value: 'OTHER'} ],
    'MortgageCapacityOptions_BC': [
      {label: 'Not Specified', value: 'SILENT'},
      {label: 'As joint tenants', value: 'JOINT'} ]
  };

  static mortgageCapacityDropDownOptions = {
    'ON': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_Default,
    'NB': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_NB_NS,
    'NS': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_NB_NS,
    'AB': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_AB,
    'MB': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_MB,
    'SK': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_SK,
    'BC': PurchaserCapacity.mortgageCapacityOptions.MortgageCapacityOptions_BC
  };

  static defaultMortgageCapacityOptions: any[] = [
    {label: 'Silent (Capacity not to be specified)', value: 'SILENT'},
    {label: 'On joint account with right of survivorship', value: 'JOINT'},
    {label: '{Label does not matter, will be set dynamically by the component}', value: 'COMMON_SPECIFIED'},
    {label: 'As tenants in common (without specifying shares)', value: 'COMMON_UNSPECIFIED'},
    {label: 'Other (a response must be entered in the "Capacity" field)', value: 'OTHER'}
  ];

  /**
   * The list of capacity options for each purchaser in a matter (the value kept at individual MatterParticipant level)
   * These values are aligned with the server side enums used this property values are captured through.
   @type {Array}
   */
  static purchaserCapacityOptions: any[] = [
    {value: '', label: ''},
    {value: 'UNSPECIFIED_CAPACITY', label: 'Silent (capacity not to be specified)'},
    {value: 'ESTATE_WITH_WILL', label: 'Estate Trustee with a Will'},
    {value: 'ESTATE_WITHOUT_WILL', label: 'Estate Trustee without a Will'},
    {value: 'ESTATE_DURING_LITIGATION', label: 'Estate Trustee During Litigation'},
    {value: 'FIRM_NAME', label: 'Firm Name'},
    {value: 'GENERAL_PARTNER', label: 'General Partner'},
    {value: 'JOINT_ACCOUNT', label: 'Joint Account'},
    {value: 'JOINT_ACCOUNT_SURVIVORSHIP', label: 'Joint Account, Right of Survivorship'},
    {value: 'JOINT_TENANTS', label: 'Joint Tenants'},
    {value: 'LIFE_ESTATE', label: 'Life Estate'},
    {value: 'OFFICIAL_GUARDIAN', label: 'Official Guardian'},
    {value: 'PARTNER', label: 'Partner'},
    {value: 'PUBLIC_GUARDIAN_AND_TRUSTEE', label: 'Public Guardian and Trustee'},
    {value: 'REMAINDER', label: 'Remainder'},
    {value: 'REGISTERED_OWNER', label: 'Registered Owner'},
    {value: 'TRUSTEE_IN_BANKRUPTCY', label: 'Trustee in Bankruptcy'},
    {value: 'TENANTS_IN_COMMON', label: 'Tenants in Common'},
    {value: 'TRUSTEE', label: 'Trustee'}
  ];

  /**
   * Labels for the split share option, to be selected based on the number of participants on a matter
   * @type {Array}
   */
  static splitShareOptionLabels: string[] = [
    'as to a _______ interest',    //Same label when no participants are selected
    'as to a _______ interest',
    'as to a 50% interest',
    'as to a one-third interest',
    'as to a 25% interest',
    'as to a 20% interest',
    'as to a one-sixth interest',
    'as to a one-seventh interest',
    'as to a one-eighth interest',
    'as to a one-ninth interest',
    'as to a 10% interest',
    'as to a one-eleventh interest',
    'as to a one-twelfth interest',
    'as to a one-thirteenth interest',
    'as to a one-fourteenth interest',
    'as to a one-fifteenth interest'
  ];

  static splitShareOptionLabelsMbSK: string[] = [
    'as to an undivided _______ interest',    //Same label when no participants are selected
    'as to an undivided _______ interest',
    'as to an undivided 50% interest',
    'as to an undivided one-third interest',
    'as to an undivided 25% interest',
    'as to an undivided 20% interest',
    'as to an undivided one-sixth interest',
    'as to an undivided one-seventh interest',
    'as to an undivided one-eighth interest',
    'as to an undivided one-ninth interest',
    'as to an undivided 10% interest',
    'as to an undivided one-eleventh interest',
    'as to an undivided one-twelfth interest',
    'as to an undivided one-thirteenth interest',
    'as to an undivided one-fourteenth interest',
    'as to an undivided one-fifteenth interest'
  ];

  /**
   * Get the list of capacity options for a matter, where the split shares label is customized for the specified number of matter purchasers
   * @param numberOfPurchasers
   * @returns {{value: string, label: string}[]}
   */
  static getMatterPurchasersCapacityOptions(numberOfPurchasers: number, provinceCode: string): any[] {
    let optionLabel: string;
    if (provinceCode == 'MB' || provinceCode == 'SK') {
      optionLabel = 'Each ' + this.getDynamicValueInterestMbSK(numberOfPurchasers);

    } else {
      optionLabel = 'As tenants in common (each ' + this.getDynamicValueInterest(numberOfPurchasers) + ')';
    }

    return PurchaserCapacity.provinceBasedMatterPurchasersCapacityOptions[ provinceCode ] ? PurchaserCapacity.provinceBasedMatterPurchasersCapacityOptions[ provinceCode ].map(option =>
      option.value === 'TENANTS_IN_COMMON_SPLIT_SHARES' ?
        {value: 'TENANTS_IN_COMMON_SPLIT_SHARES', label: optionLabel} :
        option) : [];

  }

  static getMortgageCapacityOptions(numberOfPrivateLenders: number, provinceCode: string): any[] {
    if (provinceCode == 'AB' || provinceCode == 'MB') {
      return PurchaserCapacity.mortgageCapacityDropDownOptions[ provinceCode ];
    } else {
      return PurchaserCapacity.mortgageCapacityDropDownOptions[ provinceCode ].map(option =>
        option.value === 'COMMON_SPECIFIED'
          ? {value: 'COMMON_SPECIFIED', label: `As tenants in common (each ${ this.getDynamicValueInterest(numberOfPrivateLenders) })`}
          : option);
    }

  }

  static getDynamicValueInterest(numberOfParticipants: number): string {
    return numberOfParticipants < 16 ? this.splitShareOptionLabels[ numberOfParticipants ] : 'each as to a one-' + numberOfParticipants + 'th interest';
  }

  static getDynamicValueInterestMbSK(numberOfParticipants: number): string {
    return numberOfParticipants < 16 ? this.splitShareOptionLabelsMbSK[ numberOfParticipants ] : 'each as to a one-' + numberOfParticipants + 'th interest';
  }

  static getDynamicValueInterestNS(numberOfParticipants: number): string {
    return 'as tenants in common (each ' + this.getDynamicValueInterest(numberOfParticipants) + ')';
  }

  static getMortgageCapacityDescription(mortgageCapacity: MortgageCapacityType, numberOfPrivateLenders: number, provinceCode: string): string {
    return this.getMortgageCapacityOptions(numberOfPrivateLenders, provinceCode).find(option => option.value == mortgageCapacity).label;

  }
}
