<div class="container-fluid custom-modal-container border-radius">
    <div class="row modal-header">
        <div class="col-lg-12 padding-left-0">
            <div class="col-lg-10"><h1>{{title}}</h1></div>
            <button type="button" class="btn-close pull-right" (click)="closeDialog()">&times;</button>
        </div>
    </div>
    <div class="row modal-body"
         [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">
        <form #unitLevelPlanDialogForm="ngForm" class="form-horizontal col-lg-12">
            <div class="col-lg-12 fields-group  margin-top-0 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">{{condominiumTitle}}</span>
                </div>
            </div>
            <div class="form-group row" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan.location';code:currentMatter.provinceCode">
                <label class="col-lg-2 control-label text-right">Location</label>
                <div class="col-lg-10">
                    <input type="text"
                           trimmed
                           name="jurisdiction"
                           [dp-read-only]="isExpenseReadOnly || isProjectSaleMatterON || isProjectSaleMatterAB"
                           [dynamicHelpCallBackFunction]="generateF9HelpJurisdiction()"
                           statusBarHelp
                           [(ngModel)]="unitLevelPlan.condominiumJurisdiction" maxlength="50"
                           (keydown.F9)="f9KeyStruckOnJurisdiction($event)"
                           class="form-control"/>
                </div>
            </div>

            <div class="form-group row" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan.condominiumCorporationName';code:currentMatter.provinceCode">
                <label class="col-lg-2 control-label text-right">Name of Condominium Corporation</label>
                <div class="col-lg-10">
                    <input type="text"
                           trimmed
                           [dp-read-only]="isProjectSaleMatterAB"
                           name="condominiumCorporationName"
                           id="condominiumCorporationName"
                           [(ngModel)]="unitLevelPlan.condominiumCorporationName" maxlength="100"
                           class="form-control"/>
                </div>
            </div>

            <div class="form-group row" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan.exception';code:currentMatter.provinceCode">
                <label class="col-lg-2 control-label text-right">Exception Wordings?</label>
                <div class="col-lg-1">
                    <select
                        class="form-control"
                        id="exceptionYNOption" name="exceptionYNOption"
                        [(ngModel)]="unitLevelPlan.exceptionType"
                        [dp-read-only]="isProjectSaleMatter"
                    >
                        <option *ngFor="let exceptionYNType of exceptionYNOptions"
                                [value]="exceptionYNType.value">{{exceptionYNType.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-9" style="padding-right: 25px" *ngIf="unitLevelPlan.isExceptionForCondo()">
                    <div [dp-read-only-block]="isProjectSaleMatter">
                    <p-autoComplete
                        [(ngModel)]="unitLevelPlan.exceptionTypeDescription"
                        tabIndexIgnore
                        name="exceptionDescription"
                        [suggestions]="filteredExceptionTypeOptions"
                        (completeMethod)="filterExceptionTypeDescription($event)"
                        [minLength]="1"
                        [dropdown]="true"
                        (keydown.space)="sharedUtils.handleSpaceKeyDropdown($event, unitLevelPlan.exceptionTypeDescription, handleDropdownClick)"
                        (keydown.enter)="sharedUtils.handleEnterKeyDropdown($event,  unitLevelPlan.exceptionTypeDescription, handleDropdownClick)"
                        (onDropdownClick)="handleDropdownClickExceptionTypeDescription()">
                        <ng-template let-exceptionTypeDescriptionLabel pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                                    <span class="omnibarLi100">
                                                        {{exceptionTypeDescriptionLabel}}
                                                    </span>
                            </li>
                        </ng-template>
                    </p-autoComplete>
                    </div>
                </div>
            </div>

            <div class="form-group row" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan.planType';code:currentMatter.provinceCode">
                <div class="form-group row condo-plan"
                     *ngFor="let condominiumPlan of unitLevelPlan.condominiumPlans; let j=index">
                    <label class="control-label col-lg-2 text-right">
                        Type
                    </label>
                    <div class="col-lg-4 condo-plan-select">
                        <select
                            class="form-control"
                            [dp-read-only]="isExpenseReadOnly || isProjectSaleMatterON || isProjectSaleMatterAB"
                            id="type-{{j + 1}}" name="type-{{j + 1}}"
                            [(ngModel)]="condominiumPlan.condominiumPlanType"
                        >
                            <option *ngFor="let planType of condoPlanType"
                                    [value]="planType.value">{{planType.label}}
                            </option>
                        </select>

                    </div>

                    <label class="control-label col-lg-2 text-right">Plan No.</label>
                    <div class="col-lg-4">
                        <input type="text" maxlength="100"
                               [dp-read-only]="isExpenseReadOnly || isProjectSaleMatterON || isProjectSaleMatterAB"
                               trimmed
                               id="plan-{{j + 1}}" name="plan-{{j + 1}}"
                               [(ngModel)]="condominiumPlan.condominiumPlanNumber"
                               class="form-control"/>
                        <button type="button" *ngIf="j > 0 && !(isExpenseReadOnly||isProjectSaleMatterON || isProjectSaleMatterAB)"
                                (click)="removeCondominiumPlan(condominiumPlan)" class="textbox-glyphicon-icon">
                            <span class="glyphicon glyphicon-remove"></span>
                        </button>
                    </div>
                </div>
                <div class="form-group" *ngIf="!(isExpenseReadOnly || isProjectSaleMatterON || isProjectSaleMatterAB)">
                    <div class="col-lg-10 offset-lg-2">
                            <span class="actionlink" (click)="addNewCondominiumPlan();">
                                <span>
                                    <a href="javascript:void(0);">
                                        Add New
                                    </a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                            </span>
                    </div>
                </div>
            </div>
            <div class="form-group row padding-top-40">
                <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                     *dpShowByProvince="'matter.subjectProperty.lincNo';code:currentMatter.provinceCode">
                    <label class="control-label col-lg-2 text-right">LINC Number</label>
                </div>
                <div class="col-lg-1">
                    <label class="control-label col-lg-2 text-right">Unit Number</label>
                </div>
                <div class="col-lg-1" *dpShowByProvince="'matter.matterProperty.unitLevelPlan.level';code:currentMatter.provinceCode">
                    <label class="control-label col-lg-2 text-right">Level Number</label>

                </div>
                <div class="col-lg-2" *ngIf="unitLevelPlan.condominiumPlans.length > 1">
                    <label class="control-label  text-right">Plan Number</label>

                </div>
                <div class="col-lg-1" *dpShowByProvince="'matter.matterProperty.unitLevelPlan.dividedShares';code:currentMatter.provinceCode">
                    <label class="control-label col-lg-2 text-right">Number of Undivided Shares</label>
                </div>

                <div class="col-lg-3" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan.condominiumCorporationName';code:currentMatter.provinceCode">
                    <label class="control-label col-lg-2 text-right">{{accountNumberLabel}}</label>
                </div>

                <div *dpShowByProvince="'matter.matterProperty.unitLevelPlan.pin';code:currentMatter.provinceCode"
                     [ngClass]="getPinNumberColumnCssClass()">
                    <label class="control-label text-right">{{pinLabel}}</label>

                </div>

                <div class="col-lg-2"
                     *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                    <label class="control-label share-percent-label padding-top-0">% Share</label>
                    <label class="control-label text-right width30">Auto calc</label>
                </div>

                <div [ngClass]="getCommonExpensesColumnCssClass()">
                    <label class="control-label col-lg-2 text-right"
                           dp-province-field-label="matter.soa.commonExpenses">Common Expenses</label>
                </div>
                <div class="col-lg-2">
                    <label class="control-label  text-right">Unit Type</label>
                </div>
                <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                     *dpShowByProvince="'matter.matterProperty.unitLevelPlan.rollNumber';code:currentMatter.provinceCode">
                    <label class="control-label  text-right">Roll Number</label>
                </div>
                <div class="col-lg-1"
                     *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                    <label class="control-label  text-right">ID Number</label>
                </div>
                <div class="col-lg-1"
                     *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                    <label *ngIf="isOverrideDescIndVisible()" class="control-label text-left">Override
                        Description</label>
                </div>

            </div>
            <div class="form-group row condo-expense"
                 *ngFor="let condominiumExpense of unitLevelPlan.condominiumExpenses; let j=index"
                 [ngClass]="{'table-header-line' : j == 0}">
                <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                     *dpShowByProvince="'matter.subjectProperty.lincNo';code:currentMatter.provinceCode">
                    <input id="lincNumber-{{condominiumExpense.identifier}}" name="lincNumber-{{condominiumExpense.identifier}}"
                           uppercased dp-lincnumber trimmed
                           maxlength="200"
                           [(ngModel)]="condominiumExpense.lincNumber"
                           class="form-control"/>

                </div>
                <div class="col-lg-1 condo-expense-unit">
                    <input id="unitNumber-{{condominiumExpense.identifier}}" name="unitNumber-{{condominiumExpense.identifier}}"
                           maxlength="5" dp-trimmed uppercased
                           dp-alphanumeric-only
                           [dp-read-only]="isExpenseReadOnly || isUnitLevelPinReadOnly" [(ngModel)]="condominiumExpense.unitNumber"
                           (change)="teraviewLegalDescOverriddenTypeChanged(condominiumExpense)"
                           class="form-control">
                    <button type="button" *ngIf="j > 2 && !isExpenseReadOnly"
                            (click)="removeCondominiumExpense(condominiumExpense)" class="textbox-glyphicon-icon">
                        <span class="glyphicon glyphicon-remove"></span>
                    </button>
                </div>
                <div class="col-lg-1" *dpShowByProvince="'matter.matterProperty.unitLevelPlan.level';code:currentMatter.provinceCode">
                    <input id="levelNumber-{{condominiumExpense.identifier}}" [dp-read-only]="isExpenseReadOnly || isUnitLevelPinReadOnly"
                           name="levelNumber-{{condominiumExpense.identifier}}" maxlength="5"
                           (change)="teraviewLegalDescOverriddenTypeChanged(condominiumExpense)"
                           dp-trimmed uppercased
                           dp-alphanumeric-only
                           [(ngModel)]="condominiumExpense.levelNumber"
                           class="form-control"/>

                </div>
                <div class="col-lg-3" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan.condominiumCorporationName';code:currentMatter.provinceCode">
                    <input
                           id="assessmentAccountNumber-{{condominiumExpense.identifier}}"
                           name="assessmentAccountNumber-{{condominiumExpense.identifier}}"
                           maxlength="{{getPANMaxLength()}}"
                           dp-trimmed uppercased
                           dp-alphanumeric-only
                           [(ngModel)]="condominiumExpense.assessmentAccountNumber"
                           class="form-control"/>

                </div>
                <div class="col-lg-2" *ngIf="unitLevelPlan.condominiumPlans.length > 1">
                    <select
                        class="form-control"
                        [dp-read-only]="isExpenseReadOnly"
                        id="planNumber-{{condominiumExpense.identifier}}" name="planNumber-{{condominiumExpense.identifier}}"
                        (change)="planNumberChanged(condominiumExpense)"
                        [(ngModel)]="condominiumExpense.condominiumPlan">
                        <option *ngFor="let condoPlan of unitLevelPlan.condominiumPlans"
                                [ngValue]="condoPlan">{{getPlanNumber(condoPlan)}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-1" *dpShowByProvince="'matter.matterProperty.unitLevelPlan.dividedShares';code:currentMatter.provinceCode">
                    <input id="dividedShares-{{condominiumExpense.identifier}}" name="dividedShares-{{condominiumExpense.identifier}}"
                           [(ngModel)]="condominiumExpense.noUndividedShare"
                           [max-int]=99999.999999
                           dp-percentage
                           [max-decimals]=6
                           class="form-control padding-right-3"/>

                </div>
                <div *dpShowByProvince="'matter.matterProperty.unitLevelPlan.pin';code:currentMatter.provinceCode"
                     [ngClass]="getPinNumberColumnCssClass()">
                    <input
                           *ngIf="!isMatterProvinceNBorNS"
                           id="pinNumber-{{condominiumExpense.identifier}}" name="pinNumber-{{condominiumExpense.identifier}}"
                           #pinNumber="ngModel"
                           [(ngModel)]="condominiumExpense.pinNumber"
                           [dp-read-only]="isExpenseReadOnly || isUnitLevelPinReadOnly" [fieldKey]="pinFieldKeyBase +j"
                           dp-error-validator
                           (blur)="addCustomPinWarning($event.target.value, pinFieldKeyBase ,j)"
                           dp-pinFormat
                           class="form-control"/>
                    <!-- validatePinNB() only takes effect for NB  -->
                    <input *ngIf="isMatterProvinceNBorNS"
                           id="pinNumberNS-{{condominiumExpense.identifier}}" name="pinNumberNS-{{condominiumExpense.identifier}}"
                           maxlength="{{getPinMaxLength()}}"
                           #pinNumber="ngModel"
                           [(ngModel)]="condominiumExpense.pinNumber"
                           (blur)="validatePinNB($event.target.value, pinFieldKeyBase ,j)"
                           class="form-control"/>
                </div>
                <div class="col-lg-2"
                     *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                    <input id="sharePercent-{{condominiumExpense.identifier}}" name="sharePercent-{{condominiumExpense.identifier}}"
                           [(ngModel)]="condominiumExpense.sharePercentage"
                           [max-int]=99.999999999
                           dp-percentage
                           [max-decimals]=9
                           (blur)="onAutoCalcChange($event, j)"
                           [dp-read-only]="isCommonExpenseReadonly() && !isEditCommonExpenseCommandReCalculate()"
                           class="form-control padding-right-3 share-percent"/>
                    <label class="control-label padding-top-5">%</label>
                    <span class="tool-tip-icon">
                            <dp-checkbox fieldId="autoCalc-{{condominiumExpense.identifier}}">
                            <input type="checkbox"
                                   id="autoCalc-{{condominiumExpense.identifier}}"
                                   name="autoCalc-{{condominiumExpense.identifier}}"
                                   (change)="onAutoCalcChange($event, j)"
                                   [disabled]="isCommonExpenseReadonly() && !isEditCommonExpenseCommandReCalculate()"
                                   [(ngModel)]="condominiumExpense.autoCalcInd"
                            >
                        </dp-checkbox>
                        </span>
                        <span class="tool-tip-message left-150">
                            <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to auto-calculate common expenses based on percentage share</span>
                        </span>
                </div>

                <div [ngClass]="getCommonExpensesColumnCssClass()">
                    <input
                        [dp-read-only]="(isCommonExpenseReadonly() && !isEditCommonExpenseCommandReCalculate()) || isExpenseReadOnly || condominiumExpense.autoCalcInd"
                        id="commonExpenses-{{condominiumExpense.identifier}}"
                        name="commonExpenses-{{condominiumExpense.identifier}}"
                        [(ngModel)]="condominiumExpense.condominiumExpense"
                        (blur)="updateHelpText()" dp-currency
                        (focus)="updatecondominiumExpenseF9Help(j)"
                        (keydown.f9)="condominiumExpensef9Clicked(j)"
                        [fieldKey]="'matter.propertyteranet.condominiumExpense'+j"
                        statusBarHelp
                        class="form-control"/>

                </div>
                <div class="col-lg-2">
                    <select
                        class="form-control"
                        id="unitType-{{condominiumExpense.identifier}}" name="unitType-{{condominiumExpense.identifier}}"
                        [(ngModel)]="condominiumExpense.condominiumUnitType">
                        <option *ngFor="let unitType of condoUnitType"
                                [value]="unitType.value">{{unitType.label}}
                        </option>
                    </select>
                </div>
                <div [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-3'"
                     *dpShowByProvince="'matter.matterProperty.unitLevelPlan.rollNumber';code:currentMatter.provinceCode">
                    <input id="rollNumber-{{condominiumExpense.identifier}}" name="rollNumber-{{condominiumExpense.identifier}}"
                           uppercased
                           dp-alphanumeric-only
                           maxlength="20"
                           [(ngModel)]="condominiumExpense.rollNumber"
                           class="form-control"/>

                </div>
                <ng-container>
                    <div class="col-lg-1"
                         *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                        <input id="idNumber-{{condominiumExpense.identifier}}"
                               name="idNumber-{{condominiumExpense.identifier}}"
                               [(ngModel)]="condominiumExpense.idNumber"
                               maxlength="10"
                               class="form-control padding-right-3"/>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                    <div class="col-lg-1" *ngIf="isOverrideDescIndVisible()">
                        <span class="tool-tip-icon">
                            <dp-checkbox fieldId="overrideDescInd-{{condominiumExpense.identifier}}" class="margin-left-40">
                                <input type="checkbox"
                                       id="overrideDescInd-{{condominiumExpense.identifier}}"
                                       name="overrideDescInd-{{condominiumExpense.identifier}}"
                                       (change)="overrideDescChange($event, j)"
                                       [(ngModel)]="condominiumExpense.overrideDescInd">
                            </dp-checkbox>
                        </span>
                        <span class="tool-tip-message override-desc">
                            <div class="bubble-arrow override-desc"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                            <span>Check to Override Teraview Legal Description</span>
                        </span>
                    </div>
                </ng-container>

                <div class="col-lg-12 padding-top-10" *ngIf="condominiumExpense.overrideDescInd">
                    <div class="col-lg-2">
                        <label class="control-label padding-top-5">Override Teraview Legal Description</label>
                    </div>
                    <div class="col-lg-3">
                        <select
                            class="form-control"
                            id="teraviewLegalDescOverriddenType-{{condominiumExpense.identifier}}"
                            name="teraviewLegalDescOverriddenType-{{condominiumExpense.identifier}}"
                            [(ngModel)]="condominiumExpense.teraviewLegalDescOverriddenType"
                            (change)="teraviewLegalDescOverriddenTypeChanged(condominiumExpense)"
                        >
                            <option *ngFor="let teraviewLegalDescOverriddenType of teraviewLegalDescOverriddenTypes"
                                    [value]="teraviewLegalDescOverriddenType.value">
                                {{teraviewLegalDescOverriddenType.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-7">
                    <textarea rows="4"
                              class="form-control"
                              id="teraviewLegalDesc-{{condominiumExpense.identifier}}"
                              name="teraviewLegalDesc-{{condominiumExpense.identifier}}"
                              [dp-read-only]="condominiumExpense.isLegalDescriptionApplyLegalDescEasement()"
                              [(ngModel)]="condominiumExpense.teraviewLegalDesc"
                    >
                    </textarea>
                    </div>
                    <div class="col-lg-12">
                        <hr>
                    </div>
                </div>


            </div>
            <div class="form-group row padding-bottom-40">
                <ng-container *dpShowByProvince="'matter.matterProperty.unitLevelPlan.level';code:currentMatter.provinceCode">
                    <div [ngClass]="getAddNewColumnCssClass()" class="text-left">
                         <span class="actionlink"
                               *ngIf="unitLevelPlan.condominiumExpenses.length < 20 && !isExpenseReadOnly"
                               (click)="addCondominiumExpense();">
                                <span>
                                    <a href="javascript:void(0);">
                                        Add New
                                    </a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                           </span>

                    </div>
                    <div class="col-lg-3 text-right">
                        <label class="control-label">Total</label>

                    </div>
                </ng-container>
                <ng-container *dpShowByProvince="'matter.matterProperty.unitLevelPlan.rollNumber';code:currentMatter.provinceCode">
                    <div class="text-left" class="col-lg-4"
                         [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-5' : 'col-lg-4'">
                         <span class="actionlink"
                               *ngIf="unitLevelPlan.condominiumExpenses.length < 20 && !isExpenseReadOnly"
                               (click)="addCondominiumExpense();">
                                <span>
                                    <a href="javascript:void(0);">
                                        Add New
                                    </a>
                                </span>
                                <span class="selector fa fa-plus-square-o"></span>
                           </span>

                    </div>

                    <div class="text-right" [ngClass]="unitLevelPlan.condominiumPlans.length > 1 ? 'col-lg-2' : 'col-lg-1'">
                        <label class="control-label">Total</label>

                    </div>
                </ng-container>

                <div class="col-lg-2"
                     *ngIf="(currentMatter.isProjectSale || currentMatter.templateForProject) && currentMatter.isMatterProvinceON">
                    <input name="condominiumTotalSharePercentage"
                           [(ngModel)]="condominiumTotalSharePercentage"
                           readonly="true" class="form-control share-percent"/>
                    <label class="control-label padding-top-5">%</label>
                </div>

                <div [ngClass]="getTotalExpensesColumnCssClass()">
                    <input name="total"
                           [ngModel]="condominiumTotalExpenses"
                           readonly="true" class="form-control"
                           dp-currency
                           (keydown.f9)="f9Clicked()"
                    />
                    <button type="button" *ngIf="!inSecondModal && !(isExpenseReadOnly || isProjectSaleMatterON || !commonExpenseAdj)"
                            id="cmnExpBtn"
                            tabIndex="-1"
                            (click)="openCommonExpenseAdjustment()" class="button-glyph">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>

                </div>
                <ng-container
                    *dpShowByProvince="'matter.matterProperty.unitLevelPlan.percentageShareOfTotalRealtyTaxes';code:currentMatter.provinceCode">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Percentage share of total realty taxes</label>
                    </div>
                    <div class="col-lg-2">
                        <div class="col-lg-10 margin-left-minus-5">
                            <input
                                type="text"
                                id="percentageShareOfTotalRealtyTaxes"
                                name="percentageShareOfTotalRealtyTaxes"
                                class="form-control"
                                [max-int]=99.999999999
                                [(ngModel)]="unitLevelPlan.percentageShareOfTotalRealtyTaxes"
                                dp-percentage
                                max-decimals=9
                            >
                        </div>
                        <div class="col-lg-2">
                            <label class="control-label">%</label>
                        </div>

                    </div>
                    <!--<div class="percentage-width-10">-->
                    <!--<label class="control-label">%</label>-->

                    <!--</div>-->
                </ng-container>

                <div class="col-lg-2">

                </div>
            </div>
        </form>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button class="dp-btn keyboard-save-action" type="button" id="okBtn" (click)="saveUnitLevelPlanData()">
                <span>OK</span>
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="closeDialog()">
                <span>Cancel</span>
            </button>
            <ng-container *ngIf="isCommonExpenseReadonly()">
                <dp-dropdown-menu [dropDownItems]="multipleEditExpenseOptions"
                                  (itemClicked)="clickActionDropDown($event)"
                                  [buttonLabel]="'Allow Edit of Common Expenses Fields'"
                                  [openSubMenuOnLeft]="false"
                                  [openSubSubMenuOnLeft]="true"
                                  [addFocus]="true"></dp-dropdown-menu>
                <label class="commonExpense-disclaimer">
                    <img src="/assets/img/info.png"
                         class="commonExpense-icon-text"
                         alt="">
                </label>
                <span class="commonExpense-disclaimer-message">
                    An Interim Occupancy adjustment with Common Expenses and/or Taxes set to calculate automatically exists in the Statement of Adjustments.  Changes to this dialog which affect common expenses will affect the automatic calculation of common expenses in the Interim Occupancy adjustment. Changes to this dialog which affect the percentage interest will affect the automatic calculation of taxes in the Interim Occupancy adjustment.  To preserve existing amounts in the Interim SOA, UNITY can switch either or both of these items in the Interim Occupancy adjustment from automatic calculation to manual.
                </span>
            </ng-container>
            <div *ngIf="context.isPreviousMatterBtnVisible || context.isNextMatterBtnVisible" class="right">
                <button *ngIf="context.isPreviousMatterBtnVisible"
                        class="matter-nav-btn"
                        title="Previous Condo’s Units in this Project - Shift + Page Up"
                        (click)="goToPreviousMatter()">
                    <i class="svg-icon previous pointerCursor"></i>
                </button>
                <button *ngIf="context.isNextMatterBtnVisible"
                        class="matter-nav-btn"
                        title="Next Condo’s Units in this Project - Shift + Page Down"
                        (click)="goToNextMatter()">
                    <i class="svg-icon next padding-left-5 pointerCursor"></i>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent><</dp-modal-f9-help>
</div>
