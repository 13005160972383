<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Import Selected Unity Master Templates</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="margin-top-0">
                <div class="fixed-header">
                    <div class="col-lg-2 padding-top-10 padding-left-20">
                        <select class="form-control"
                                id="categoryFilter"
                                name="categoryFilter"
                                tabindex="0"
                                [(ngModel)]="categoryFilter"
                                (ngModelChange)="onCategoryChange($event)">
                            <option value='ALL' selected>All</option>
                            <option *ngIf="isIncludeFileExist()" value='Include'>Include</option>
                            <option *ngFor="let category of categories"
                                    [value]="category.categoryDescription"
                            >
                                {{category.categoryDescription}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-10 text-right margin-bottom-30">
                        <button type="button" class="dp-btn dp-btn-cancel" (click)="importTemplateConfirmationModal()"
                                [disabled]="documentTemplateIds.length < 1">
                            <span>Import</span>
                        </button>
                    </div>
                </div>

                <table class="standard-table margin-left-10 margin-top-80 width-auto"  tabindex="-1" [mfData]="filteredRows" #mf="mfDataTable" >
                    <thead class="sticky-table-header">
                    <tr>
                        <th class="col1">
                            <div class="wrap-header-nohover">
                                <dp-checkbox fieldId="multiSelectCheckBox" [hasTabIndex]="true">
                                    <input type="checkbox" id="multiSelectCheckBox" name="multiSelectCheckBox" #multiSelectCheckBox
                                           (change)="multiSelectDocumentsForImport(multiSelectCheckBox.checked)"
                                    /></dp-checkbox>
                            </div>
                        </th>

                        <th class="col2" (click)="sortFileName.sort();" id="sortFileName">
                            <div class="wrap-header">
                                <span style="width: 100%;">Name
                        <dp-default-sorter #sortFileName by="fileName" defaultSort="counter" [singleState]="true"></dp-default-sorter>
                                </span>
                            </div>

                        </th>
                        <th class="col3" (click)="sortDescription.sort();">
                            <div class="wrap-header">
                                <span style="width: 100%;">Description
                                    <dp-default-sorter #sortDescription by="documentTemplate.description" defaultSort="counter" [singleState]="true"></dp-default-sorter>
                                </span>
                            </div>
                        </th>
                        <th class="col4">
                            <div class="wrap-header" (click)="sortLastUpdatedTimeStamp.sort();" id="sortLastUpdatedTimeStamp">
                                <span style="width: 100%;">DP Version
                                    <!--<dp-default-sorter by="lastUpdatedTimestampForSorting"></dp-default-sorter>-->
                                    <dp-default-sorter #sortLastUpdatedTimeStamp by="lastUpdatedTimeStamp" [singleState]="true"></dp-default-sorter>
                                </span>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr *ngFor="let row of mf.data; let i = index;" [ngClass]="{'focus-first': i==0}"
                        (keydown)="tableKeyCommands(i,$event)"
                        tabindex="0">
                        <td class="col1" >
                            <div  class="table-cell-01 text-margin-dropdown">
                                <dp-checkbox fieldId="checkbox_{{i}}" [hasTabIndex]="false">
                                    <input type="checkbox" id="checkbox_{{i}}" name="checkbox" #multiSelecttemplate
                                           (change)="selectTemplate(multiSelecttemplate.checked , row);"
                                           [checked]="isSelected(row.id.toString())"/>
                                </dp-checkbox>
                            </div>
                        </td>
                        <td class="col2">
                            <div
                                *ngIf="row.isInfected || row.isVirusScanPending" class="inline-block"><i
                                class="virus-icon padding-right-5"
                                [ngClass]="row.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>

                                <span class="matter-locked-message virus-message">
                                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                                   <span *ngIf="row.isInfected">
                                      We have detected this file is infected with a virus.
                                  </span>
                                      <span *ngIf="row.isVirusScanPending">
                                      Please wait... we are currently scanning this file for viruses.
                                  </span>
                                </span>
                            </div>

                            <span title="{{row.fileName}}" >
                                <div class="wrap-template-name"><a href="javascript:void(0);" (click)="downloadTemplateDocument(row)">
                                    {{row.fileName}}
                                    </a>
                                </div>
                            </span>
                        </td>
                        <td class="col3 text-overflow">
                            <span title="{{row.documentTemplate ? row.documentTemplate.description:''}}" >
                                {{row.documentTemplate ? row.documentTemplate.description:''}}
                            </span>
                        </td>
                        <td class="col4">{{row.lastUpdatedTimestampForSorting | date:'mediumDate'}}</td>
                    </tr>

                    <tr *ngIf="rows.length === 0" class="loading-row">
                        <td colspan="6" class="text-center col-not-found">
                            <div class="no-match-found">No Documents Found.</div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 bbottom-tbuttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="close()">
                <span>Close</span>
            </button>

        </div>
    </div>

</div>
