import {Matter} from '../../../shared';
import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {FocusFirstElementModalDecorator} from '../../../../shared-main/focus-first-element-modal-decorator';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class WritSearchAddParagraphModalContext {
  public writId: number;
  public matter: Matter;
}

@Component({
  selector: 'dp-writ-search-add-paragraph-modal',
  templateUrl: './writ-search-add-paragraph.component.html',
  providers: [DialogService]
})
@FocusFirstElementModalDecorator()
export class WritSearchAddParagraphModal extends ModalComponent<WritSearchAddParagraphModalContext> implements OnInit {
  isClientSelected: boolean = true;
  isSolicitorSelected: boolean = true;

  constructor(
    public dialog: MatDialogRef<WritSearchAddParagraphModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: WritSearchAddParagraphModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.isClientSelected = this.isClientDeclaration;
    this.isSolicitorSelected = this.isSolicitorDeclaration;
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get writId(): number {
    return this.context.writId;
  }

  get isClientDeclaration(): boolean {
    return this.matter.findClientDeclarationByWritId(this.writId) ? true : false;
  }

  get isSolicitorDeclaration(): boolean {
    return this.matter.findSolicitorDeclarationByWritId(this.writId) ? true : false;
  }

  onOK(): void {
    this.dialog.close({
      action: 'OK',
      isClientSelected: this.isClientSelected,
      isSolicitorSelected: this.isSolicitorSelected
    });
  }

  close(action?: string): void {
    this.dialog.close({action: 'Cancel'});
  }

  get okEnabled(): boolean {
    return this.isClientSelected || this.isSolicitorSelected ? true : false;
  }

  ngAfterViewInit() {}
}
