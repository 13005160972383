import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import moment from 'moment';
import {EventAvailabilityState} from '../../event-list-state';
import Utils from '../../../shared-main/utils';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {EventService} from '../../event.service';
import {EventData} from '../../event-data';
import {PublicHolidayService} from '../../../shared-main/public-holiday/public-holiday.service';
import {Observable} from 'rxjs/Observable';
import {LockScreenService} from '../../../core/lock-screen.service';
import {AppConfig} from '../../../shared-main/app-configuration';
import {UserStateService} from '../../../shared-main/user-state/user-state.service';

declare var jQuery: any;

@Component({
  selector: 'dp-event-availability',
  templateUrl: 'event-availability-view.component.html',
  styleUrls: [
    './event-availability-view.component.scss'
  ]
})
export class EventAvailabilityViewComponent implements OnInit {

  headersText: any[] = [];
  headers: any[] = [];
  rows: any[] = [];
  headerText: string = '';
  currentStaffViewState: string = 'WEEK';
  _eventAvailabilityState: EventAvailabilityState;
  scrollEndDate: string;
  @Output() openAppointment = new EventEmitter();
  @Output() staffEventsData = new EventEmitter();

  @Input() set eventAvailabilityState(eventAvailabilityState: EventAvailabilityState) {
    if (eventAvailabilityState != this._eventAvailabilityState) {
      this._eventAvailabilityState = eventAvailabilityState;
      setTimeout(() => {
        this.createAvailabilityData();
      }, 10);

    }
  }

  @Input() printPreview: boolean = false;

  get eventAvailabilityState(): EventAvailabilityState {
    return this._eventAvailabilityState;

  }

  constructor(private eventService: EventService, private publicHolidayService: PublicHolidayService, private lockScreenService: LockScreenService, public appConfig: AppConfig, public userStateService: UserStateService) {

  };

  ngOnInit() {

  }

  onScroll(event: any) {
    if (this.eventAvailabilityState && this.eventAvailabilityState.isOpenForScheduleAssistant) {
      if (event.target.scrollLeft + event.target.offsetWidth === event.target.scrollWidth) {
        console.log('end');
        let endDate = this.headersText.slice(0).pop();
        let eventStartDate = moment(endDate.data).add(1, 'days');
        this.addScheduleDataOnScroll(eventStartDate.toString());
      }
      console.log(event.target.scrollLeft);
      if (event.target.scrollLeft == 0) {
        console.log('start');
        let startDate = this.headersText[ 1 ];
        let eventStartDate = moment(startDate.data).subtract(1, 'days');
        this.addScheduleDataOnScroll(eventStartDate.toString(), true);
      }
    }
  }

  async addScheduleDataOnScroll(eventStartDate: string, pushAtStart?: boolean): Promise<void> {
    this.lockScreenService.lockForUpdate = true;
    try {
      await this.addScheduleData(eventStartDate, pushAtStart);
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  ngOnDestroy() {

  }

  private get24HourFormat(time: any): number {
    let hour = Number(time.split(':')[ 0 ]);
    if (time.split(':')[ 1 ].split(' ')[ 1 ] == 'PM' && hour != 12) {
      hour = hour + 12;
    } else if (time.split(':')[ 1 ].split(' ')[ 1 ] == 'AM' && hour == 12) {
      hour = 0;
    }
    return hour;
  }

  private getMinFormat(time: any): number {
    return Number(time.split(':')[ 1 ].split(' ')[ 0 ]);
  }

  private calculateDayData(ev: any, startDate: any): any[] {
    let occupancyPercentageArray: any[] = [];
    let dataList = ev.data.slice(0);
    if (this.eventAvailabilityState.isOpenForScheduleAssistant && moment(startDate).isBetween(this.eventAvailabilityState.eventStartDate, this.eventAvailabilityState.eventEndDate, undefined, '[]')) {
      let eventDataScheduleAssistant = new EventData();
      eventDataScheduleAssistant.id = -1;
      eventDataScheduleAssistant.eventType = 'APPOINTMENT';
      eventDataScheduleAssistant.startDate = this.eventAvailabilityState.eventStartDate;
      eventDataScheduleAssistant.endDate = this.eventAvailabilityState.eventEndDate;
      eventDataScheduleAssistant.endTime = this.eventAvailabilityState.eventEndTime;
      eventDataScheduleAssistant.startTime = this.eventAvailabilityState.eventStartTime;
      if (!dataList) {
        dataList = [];
      }
      dataList.push(eventDataScheduleAssistant);
    }
    dataList.filter(item => item.eventType == 'APPOINTMENT').forEach(data => {
      let startHour = this.get24HourFormat(data.startTime);
      let startMin = this.getMinFormat(data.startTime);
      let endHour = this.get24HourFormat(data.endTime);
      let endMin = this.getMinFormat(data.endTime);

      if (moment(data.startDate).isBefore(startDate) && moment(data.endDate).isAfter(startDate)) {
        startHour = 0;
        startMin = 0;
        endHour = 24;
        endMin = 0;
      } else if (moment(data.startDate).isBefore(startDate) && moment(data.endDate).isSame(startDate)) {
        startHour = 0;
        startMin = 0;
      } else if (moment(data.startDate).isSame(startDate) && moment(data.endDate).isAfter(startDate)) {
        endHour = 24;
        endMin = 0;
      }

      if (startHour == endHour && moment(startDate).isSame(data.startDate) && moment(startDate).isSame(data.endDate)) {
        let diff = endMin - startMin;
        let diffPercentage = Utils.roundNumber((diff * 100) / 60, 0);
        occupancyPercentageArray[ startHour ] = diffPercentage;
        occupancyPercentageArray.push({
          'index': startHour,
          'diffPercentage': diffPercentage,
          'eventData': data,
          'startTime': 'YES',
          'endTime': 'YES',
          'scheduleTime': data.id == -1 ? 'YES' : 'NO'
        });
      } else {
        for (let i = startHour; i <= endHour; i++) {
          if (i == startHour) {
            let diff = 60 - startMin;
            let diffPercentage = Utils.roundNumber((diff * 100) / 60, 0);
            occupancyPercentageArray.push({
              'index': i,
              'diffPercentage': diffPercentage,
              'eventData': data,
              'startTime': 'YES',
              'scheduleTime': data.id == -1 ? 'YES' : 'NO'
            });
          } else if (i == endHour) {
            let diffPercentage = Utils.roundNumber((endMin * 100) / 60, 0);
            if (diffPercentage == 0) {
              let lastElement = occupancyPercentageArray.pop();
              lastElement.endTime = 'YES';
              occupancyPercentageArray.push(lastElement);
            }
            occupancyPercentageArray.push({
              'index': i,
              'diffPercentage': diffPercentage,
              'eventData': data,
              'endTime': 'YES',
              'scheduleTime': data.id == -1 ? 'YES' : 'NO'
            });
          } else {
            occupancyPercentageArray.push({
              'index': i,
              'diffPercentage': 100,
              'eventData': data,
              'scheduleTime': data.id == -1 ? 'YES' : 'NO'
            });
          }
        }
      }
    });
    return occupancyPercentageArray;
  }

  async createAvailabilityData(): Promise<void> {
    if (this.eventAvailabilityState) {
      this.headers = [];
      this.rows = [];
      this.headersText = [];
      this.currentStaffViewState = this.eventAvailabilityState.isDayView() ? 'DAY' : (this.eventAvailabilityState.isMonthView() ? 'MONTH' : 'WEEK');
      if (this.eventAvailabilityState.isOpenForScheduleAssistant) {
        this.headersText.push(' ');
        this.headers.push({'day': 'Resource'});
        await this.addScheduleData(this.eventAvailabilityState.eventStartDate);
      } else {
        let data = await this.refreshAvailabilityData(this.eventAvailabilityState.eventStartDate, this.currentStaffViewState);
        this.headerText = data.headerText;
        this.headers = data.headers;
        this.headers.unshift({'day': 'Resource'});
        this.rows = data.rows;
      }
    }
  }

  async addScheduleData(startDate: string, pushAtStart?: boolean): Promise<void> {
    for (let j = 0; j < 3; j++) {
      let eventStartDate;
      if (pushAtStart) {
        eventStartDate = moment(startDate).subtract(j, 'days');
        let data = await this.refreshAvailabilityData(eventStartDate.toString(), this.currentStaffViewState);
        this.headersText.shift();
        this.headersText.unshift({'text': data.headerText, 'data': eventStartDate.toString()});
        this.headersText.unshift(' ');

        this.headers.shift();
        this.headers.unshift(...data.headers);
        this.headers.unshift({'day': 'Resource'});

        if (this.rows.length == 0) {
          this.rows.push(...(data.rows || []));
        } else {
          data.rows.forEach((row, index) => {
            let firstElement = this.rows[ index ].data.shift();
            row.data.shift();
            this.rows[ index ].data.unshift(...row.data);
            this.rows[ index ].data.unshift(firstElement);
          }, this);
        }
      } else {
        eventStartDate = moment(startDate).add(j, 'days');
        let data = await this.refreshAvailabilityData(eventStartDate.toString(), this.currentStaffViewState);
        this.headersText.push({'text': data.headerText, 'data': eventStartDate.toString()});
        this.headers.push(...(data.headers || []));
        if (this.rows.length == 0) {
          this.rows.push(...(data.rows || []));
        } else {
          data.rows.forEach((row, index) => {
            row.data.shift();
            this.rows[ index ].data.push(...(row.data || []));
          }, this);
        }
      }
    }
  }

  async refreshAvailabilityData(eventStartDate: string, currentStaffViewState: string): Promise<any> {

    let headerText: string = '';
    let headers = [];
    let rows = [];
    let weekMonthDate;
    let numOfDays;
    if (currentStaffViewState == 'WEEK') {
      weekMonthDate = moment(eventStartDate).startOf('week');
      numOfDays = 6;

    } else if (currentStaffViewState == 'MONTH') {
      weekMonthDate = moment(eventStartDate).startOf('month');
      numOfDays = moment(eventStartDate).daysInMonth() - 1;
    } else if (currentStaffViewState == 'DAY') {
      weekMonthDate = moment(eventStartDate).startOf('day');
      numOfDays = 0;
    }
    let currentStaffViewStateValue = weekMonthDate;
    if (currentStaffViewState == 'MONTH') {
      headerText = moment(currentStaffViewStateValue).format('MMMM YYYY');
    }

    let startDate = moment(weekMonthDate).add(0, 'days').format('YYYY/MM/DD');
    let endDate = moment(weekMonthDate).add(numOfDays, 'days').format('YYYY/MM/DD');

    if (currentStaffViewState == 'WEEK') {
      headerText = moment(startDate).format('MMM D') + ' - ' + moment(endDate).format('MMM D, YYYY');
    }

    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let obsArray: any[] = [];
    this.eventAvailabilityState.resourceData.forEach(item => {
      obsArray.push(this.eventService.getEventByStaff(accountId, item.contactId, startDate, endDate, this.eventAvailabilityState.selectedEventTypes, this.eventAvailabilityState.selectedStatuses));
    });
    let results = await Observable.forkJoin(obsArray).toPromise();
    if (results) {
      let eventData: any[] = [];
      this.eventAvailabilityState.resourceData.forEach((rd, index) => {
        let eventList = results[ index ] ? EventData.filterOutCanclePostoneEvent((results[ index ] as []).map(d => new EventData(d))) : [];
        eventData.push({
          'solicitorId': rd.contactId,
          'resourceName': rd.resourceName,
          data: eventList
        });
      });

      if (currentStaffViewState == 'DAY') {

        headerText = moment(currentStaffViewStateValue).format('dddd MMMM Do, YYYY');
        // headers.push({'day': '12am'});
        for (let i = 7; i <= 11; i++) {
          headers.push({'day': i + 'am'});
        }
        headers.push({'day': '12pm'});
        for (let i = 1; i <= 10; i++) {
          headers.push({'day': i + 'pm'});
        }
        rows = [];
        let extraHeader: boolean = eventData.some(ed => ed.data && ed.data.length > 0);
        if (extraHeader && !this.eventAvailabilityState.isOpenForScheduleAssistant) {
          headers.push('');
        }
        eventData.forEach(ev => {
          let data = [];
          data.push({'resource': ev.resourceName});

          let appointments = ev.data.filter(item => item.eventType == 'APPOINTMENT');
          let nonAppointments = ev.data.filter(item => item.eventType != 'APPOINTMENT');

          let occupancyPercentageArray = this.calculateDayData(ev, startDate);
          for (let j = 7; j < 23; j++) {
            let objList = occupancyPercentageArray.filter(item => item.index == j && item.diffPercentage > 0);
            if (objList && objList.length == 1) {
              data.push({
                'resource': '',
                'occupancyPercentage': objList[ 0 ].diffPercentage,
                'startTime': objList[ 0 ].startTime,
                'endTime': objList[ 0 ].endTime,
                'scheduleTime': objList[ 0 ].scheduleTime,
                'popoverData': {'appointments': [ objList[ 0 ].eventData ]}
              });
            } else if (objList && objList.length > 1) {
              let occupancyPercentageObjList = [];
              let scheduleMultipleTime: string;
              objList.forEach(obj => {
                if (obj.scheduleTime == 'YES') {
                  scheduleMultipleTime = 'YES';
                }
                occupancyPercentageObjList.push({
                  'occupancyPercentage': obj.diffPercentage,
                  'startTime': obj.startTime,
                  'endTime': obj.endTime,
                  'scheduleTime': obj.scheduleTime,
                  'popoverData': {'appointments': [ obj.eventData ]}
                });
              });
              data.push({
                'resource': '',
                'isMultiple': true,
                'paddingTop': (Number((occupancyPercentageObjList.length - 1) * 15) + 5) + 'px',
                'occupancyPercentageList': occupancyPercentageObjList,
                'scheduleTime': scheduleMultipleTime
              });
            } else {
              data.push({'resource': '', 'occupancyPercentage': 0});
            }

          }
          if (extraHeader && !this.eventAvailabilityState.isOpenForScheduleAssistant) {
            extraHeader = true;
            data.push({
              'resource': '',
              'nonAppointments': nonAppointments,
              'appointments': appointments
            });
          }

          rows.push({'data': data});
        });

        this.staffEventsData.emit(eventData);

      } else {
        for (let i = 0; i <= numOfDays; i++) {
          let isCurrentDate = moment().isSame(new Date(moment(startDate).add(i, 'days').toDate()), 'day');
          let holidayString = this.publicHolidayService.getHolidayByFullDate(moment(startDate).add(i, 'days').toDate());
          let isWeekend = (moment(startDate).add(i, 'days').isoWeekday() == 6 || moment(startDate).add(i, 'days').isoWeekday() == 7);
          headers.push({
            'day': moment(startDate).add(i, 'days').format('ddd') + '<br>' + (numOfDays > 6 ? moment(startDate).add(i, 'days').format('DD') : moment(startDate).add(i, 'days').format('DD-MM-YYYY')),
            'isCurrentDay': isCurrentDate,
            'holidayString': holidayString,
            'isWeekend': isWeekend
          });
        }
        rows = [];
        eventData.forEach(ev => {
          let data = [];
          data.push({'resource': ev.resourceName});
          for (let i = 0; i <= numOfDays; i++) {
            let totalHoursOccupied = 0;
            let day = moment(startDate).add(i, 'days');
            let appointments = ev.data.filter(item => item.eventType == 'APPOINTMENT' && day.isBetween(item.startDate, item.endDate, undefined, '[]') && (!day.isSame(item.endDate) || (day.isSame(item.endDate) && item.endTime != '12:00 AM')));
            let nonAppointments = ev.data.filter(item => item.eventType != 'APPOINTMENT' && day.isSame(item.startDate));
            appointments.filter(item => (item.showStaffAvailabilityAs == 'OUT_OF_OFFICE' || item.showStaffAvailabilityAs == 'TENTATIVE' || item.showStaffAvailabilityAs == 'BUSY')).forEach(event => {
              if (day.isSame(event.startDate) && day.isSame(event.endDate)) {
                let startHour = this.get24HourFormat(event.startTime);
                let startMin = this.getMinFormat(event.startTime);
                let endHour = this.get24HourFormat(event.endTime);
                let endMin = this.getMinFormat(event.endTime);
                if (startMin > 0) {
                  startHour = startHour + (startMin <= 15 ? 0.25 : (startMin <= 30 ? 0.50 : 0.75));
                }
                if (endMin > 0) {
                  endHour = endHour + (endMin <= 15 ? 0.25 : (endMin <= 30 ? 0.50 : 0.75));
                }
                if (endHour > 8) {
                  if (startHour < 8) {
                    startHour = 8;
                  }
                  totalHoursOccupied = totalHoursOccupied + (endHour - startHour);
                }

              } else if (moment(event.startDate).isBefore(day) && moment(event.endDate).isAfter(day)) {
                totalHoursOccupied = 10;
              } else if (day.isSame(event.startDate) && moment(event.endDate).isAfter(day)) {
                let endHour = 18;
                let startHour = this.get24HourFormat(event.startTime);
                let startMin = this.getMinFormat(event.startTime);
                if (startMin > 0) {
                  startHour = startHour + (startMin <= 15 ? 0.25 : (startMin <= 30 ? 0.50 : 0.75));
                }
                if (startHour <= 8) {
                  totalHoursOccupied = 10;
                } else {
                  totalHoursOccupied = totalHoursOccupied + (endHour - startHour);
                }
              } else if (moment(event.startDate).isBefore(day) && day.isSame(event.endDate)) {
                let startHour = 8;
                let endHour = this.get24HourFormat(event.endTime);
                let endMin = this.getMinFormat(event.endTime);
                if (endMin > 0) {
                  endHour = endHour + (endMin <= 15 ? 0.25 : (endMin <= 30 ? 0.50 : 0.75));
                }

                if (endHour <= 8) {
                  totalHoursOccupied = 0;
                } else {
                  totalHoursOccupied = totalHoursOccupied + (endHour - startHour);
                }
              }
            });
            if (totalHoursOccupied >= 10) {
              data.push({
                'resource': '',
                'occupancyPercentage': '100%',
                'nonAppointments': nonAppointments,
                'appointments': appointments,
                'cssClass': 'busy'
              });
            } else {
              let value = Utils.roundNumber((totalHoursOccupied * 100) / 10, 0);
              data.push({
                'resource': '',
                'occupancyPercentage': value + '%',
                'nonAppointments': nonAppointments, 'appointments': appointments,
                'cssClass': value < 33 ? 'available' : (value < 66 ? 'not-so-busy' : 'busy')
              });
            }
            if (this.appConfig.isHiddenForBCProvince) {
              data.forEach(resource => {
                if (resource.nonAppointments?.length > 0) {
                  resource.nonAppointments.forEach(eventData => {
                    if (eventData.eventDescription == 'Closing Date') {
                      eventData.eventDescription = 'Completion Date';
                    } else if (eventData.eventDescription == 'Occupancy Date') {
                      eventData.eventDescription = 'Possession Date';
                    }
                  });
                  resource.nonAppointments = resource.nonAppointments.filter(eventData => eventData.eventDescription != 'Requisition Date');
                }
              });
            }

          }
          rows.push({'data': data});
        });
        this.staffEventsData.emit(eventData);
      }
    }

    return {'headerText': headerText, 'headers': headers, 'rows': rows};
  }

  openAppointmentModal(row: EventData): void {
    this.openAppointment.emit({'eventData': row});
  }

  getAppointmentHoverDescription(appointment: EventData): string {
    return appointment.getStaffAppointmentDescription();
  }
}

