import {Component, Inject, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../shared/dialog/dialog.service';
import {SalePriceAdjustment} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {ConsiderationTaxes} from './consideration-taxes';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../shared/error-handling/error-service';
import {StatementOfAdjustmentHeading} from '../statement-adjustment/model/statement-adjustment-heading';
import * as _ from 'lodash';
import {StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {ConsiderationLtt} from './consideration-ltt';
import {StatementAdjustmentDisplayBuilder} from '../statement-adjustment/builders/statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../statement-adjustment/model';
import {StatementAdjustmentUtil} from '../statement-adjustment/statement-adjustment-util';
import {StatementAdjustmentDisplaySalePriceBuilder} from '../statement-adjustment/builders/statement-adjustment-display-sale-price-builder';
import {constValues} from '../shared/const-values';
import {SalePriceAdjustmentFactory} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {SalePriceAdjustmentHeading} from './sale-price-adjustment-heading';
import {Matter} from '../shared/matter';
import {Utils} from '../shared/utils';
import {SalePriceAdjustmentHeadingModal} from './sale-price-adjustment-heading.modal';
import {UUIDUtil} from '../../main/uuid-util';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {TaxRateService} from './tax-rate.service';
import {ManitobaLTTTier} from './manitoba-ltt-tier';
import {ManitobaLttTierUtil} from './manitoba-ltt-tier-util';
import {Project} from '../../projects/shared/project';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
class ConsiderationSoaModalContext {
  public salePrice: string;
  public closingDate: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public statementOfAdjustments: StatementAdjustment[];
  public salePriceAdjustment: SalePriceAdjustment;
  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public considerationLtt: ConsiderationLtt;
  public priceType: string;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  matter: Matter;
  project: Project;
}

@Component({
  selector: 'dp-consideration-soa-modal-content',
  templateUrl: './consideration-soa.modal.component.html',
  styleUrls: ['./consideration-soa.modal.component.scss'],
  providers: [ErrorService]
})
export class ConsiderationSoaModal extends ModalComponent<ConsiderationSoaModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  netOutHst: SelectItem[];
  salePriceAdjustmentFormatItems: SelectItem[];
  closingDateLabel: string = 'Closing Date';
  salePriceAdjustmentLocal: SalePriceAdjustment;
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];

  isTarionWarrantyAdjAvailable: boolean = true;
  isHCRAAdjAvailable: boolean = true;
  isTarionWarrantyAdjAvailableAndEligibleForTaxRebate: boolean = false;
  isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate: boolean = false; // notice there is a NOT somewhere inside (but not mutually exclusive with the one before)
  isHCRAAdjAvailableAndEligibleForTaxRebate: boolean = false;
  isHCRAAdjAvailableAndNotEligibleForTaxRebate: boolean = false;
  isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax: boolean = false;
  isOtherFixedAdjAvailableAndNotEligibleForTaxRebate: boolean = false;
  isOtherFixedAdjAvailableAndEligibleForTaxRebate: boolean = false;
  isOtherFixedAdjAvailable: boolean = true;

  salePriceAdjustmentHeadings: SalePriceAdjustmentHeading[] = [];
  selectedAdjustmentHeadingRowIndex: number = -1;
  emptyAdjustmentHeadingsMenutems: BurgerMenuExtendedItem[] = [];
  adjustmentHeadingsMenutems: BurgerMenuExtendedItem[] = [];
  openSalePriceAdjustmentHeadingActions = {
    new: 'NEW',
    update: 'UPDATE'
  };

  superscript1: boolean = false;
  superscript2: boolean = false;
  superscript3: boolean = false;

  details1: StatementOfAdjustmentDisplayItemLine[] = [];
  details2: StatementOfAdjustmentDisplayItemLine[] = [];
  details3: StatementOfAdjustmentDisplayItemLine[] = [];
  // this is needed because TarionWarranty adjustments are calculated based on sale price which adds considerations which can change the TarrionWarranty again which...
  localTarionAdjustments: StatementAdjustment[] = [];
  manitobaLTTTiers: ManitobaLTTTier[] = [];

  constructor(
    public dialog: MatDialogRef<ConsiderationSoaModal>,
    public dialogService: DialogService,
    public taxRateService: TaxRateService,
    @Inject(MAT_DIALOG_DATA) context?: ConsiderationSoaModalContext
  ) {
    super(dialog, context);
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.matter.isMatterProvinceBC
      ? ' Seller'
      : this.context.statementAdjustmentHeading
        ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
        : '';
  }

  isTaxRateGst(): boolean {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.provinceDefaultTaxRate == 'GST';
  }

  get appliedTaxRate(): string {
    return this.isTaxRateGst() ? 'GST' : this.context.provinceHstRateSlab.rateType;
  }

  get federalHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstFederalPortion
      ? this.context.provinceHstRateSlab.hstFederalPortion
      : 0;
  }

  get provinceHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstProvincialPortion
      ? this.context.provinceHstRateSlab.hstProvincialPortion
      : 0;
  }

  isHstSalePriceExist(): boolean {
    if (this.matter && this.matter.isMatterProvinceSK) {
      return this.isGstOrPstSalePriceExist('GST');
    }
    if (this.matter && this.matter.isProjectOrProjectSale) {
      //we may have two sheets and the this.context.statementOfAdjustments contains the adjs from specfic sheet, for this testing, we need all adjustments instead
      return (
        Array.isArray(this.context.matter.allStatementAdjustments) &&
        this.context.matter.allStatementAdjustments.findIndex(
          (item) => item.hstSalePrice && item.hstSalePrice.isHstSalePrice()
        ) > -1
      );
    }
    return (
      this.context.statementOfAdjustments &&
      this.context.statementOfAdjustments.findIndex((item) => item.hstSalePrice && item.hstSalePrice.isHstSalePrice()) >
        -1
    );
  }

  get matter(): Matter {
    return this.context.matter;
  }

  isHstRebateCreditVendor(): boolean {
    if (this.matter && Array.isArray(this.matter.allStatementAdjustments)) {
      return this.matter.allStatementAdjustments.some((statementOfAdjustment) => {
        if (!this.matter.isMatterProvinceSK) {
          if (statementOfAdjustment.hstSalePrice) {
            if (
              statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' &&
              statementOfAdjustment.hstSalePrice.isVendorSelected
            ) {
              return true;
            }
          }
        } else {
          // GST & PST SK
          if (
            statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' &&
            statementOfAdjustment.hstSalePrice.isVendorSelected &&
            statementOfAdjustment.hstSalePrice.rateType === 'GST'
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return false;
  }

  isHstRebateCreditPurchaser(): boolean {
    if (this.matter && Array.isArray(this.matter.allStatementAdjustments)) {
      return this.matter.allStatementAdjustments.some((statementOfAdjustment) => {
        if (!this.matter.isMatterProvinceSK) {
          if (statementOfAdjustment.hstSalePrice) {
            if (
              statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' &&
              !statementOfAdjustment.hstSalePrice.isVendorSelected
            ) {
              return true;
            }
          }
        } else {
          // GST & PST SK
          if (
            statementOfAdjustment.hstSalePrice.hstType === 'HST_REBATE' &&
            !statementOfAdjustment.hstSalePrice.isVendorSelected &&
            statementOfAdjustment.hstSalePrice.rateType === 'GST'
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return false;
  }

  isHstRebatePriceExist(): boolean {
    if (this.matter && this.matter.isMatterProvinceSK) {
      return this.isGstOrPstRebatePriceExist('GST');
    }
    if (this.matter && this.matter.isProjectOrProjectSale) {
      //we may have two sheets and the this.context.statementOfAdjustments contains the adjs from specfic sheet, for this testing, we need all adjustments instead
      return (
        Array.isArray(this.matter.allStatementAdjustments) &&
        this.matter.allStatementAdjustments.findIndex((item) => item.hstSalePrice && item.hstSalePrice.isHstRebate()) >
          -1
      );
    }
    return (
      this.context.statementOfAdjustments &&
      this.context.statementOfAdjustments.findIndex((item) => item.hstSalePrice && item.hstSalePrice.isHstRebate()) > -1
    );
  }

  isGstOrPstSalePriceExist(rateType: string): boolean {
    if (
      rateType == 'PST' &&
      this.salePriceAdjustmentHeadings.length > 0 &&
      this.salePriceAdjustmentLocal.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE'
    ) {
      return true;
    }
    return (
      this.context.statementOfAdjustments &&
      this.context.statementOfAdjustments.findIndex(
        (item) => item.hstSalePrice && item.hstSalePrice.isHstSalePrice() && item.hstSalePrice.rateType === rateType
      ) > -1
    );
  }

  isGstOrPstRebatePriceExist(rateType: string): boolean {
    if (
      rateType == 'PST' &&
      this.salePriceAdjustmentHeadings.length > 0 &&
      this.salePriceAdjustmentLocal.salePriceAdjustmentFormat === 'CREDIT_VENDOR_INCLUSIVE'
    ) {
      let rebateToPurchaserExists =
        this.context.statementOfAdjustments &&
        this.context.statementOfAdjustments.findIndex(
          (item) =>
            item.hstSalePrice &&
            item.hstSalePrice.isHstRebate() &&
            !item.hstSalePrice.isVendorSelected &&
            item.hstSalePrice.rateType === rateType
        ) > -1;
      let rebateToVendorExists =
        this.context.statementOfAdjustments &&
        this.context.statementOfAdjustments.findIndex(
          (item) =>
            item.hstSalePrice &&
            item.hstSalePrice.isHstRebate() &&
            item.hstSalePrice.isVendorSelected &&
            item.hstSalePrice.rateType === rateType
        ) > -1;
      return rebateToPurchaserExists && rebateToVendorExists;
    }
    return (
      this.context.statementOfAdjustments &&
      this.context.statementOfAdjustments.findIndex(
        (item) => item.hstSalePrice && item.hstSalePrice.isHstRebate() && item.hstSalePrice.rateType === rateType
      ) > -1
    );
  }

  //Calculated read only field on UI for SK
  get homeAndExtrasExcludingLand(): number {
    return Number(this.salePriceAdjustmentLocal.agreementSalePrice) - Number(this.salePriceAdjustmentLocal.priceOfLand);
  }

  ngAfterViewInit(): void {
    this.addHstWarning();
  }

  getDefaultSalePriceAdjustmentFormatItems(): SelectItem[] {
    return [
      {value: 'CREDIT_VENDOR_NET_SALE_PRICE', label: 'Credit Vendor = Net Sale Price'},
      {
        value: 'CREDIT_VENDOR_INCLUSIVE',
        label: `Credit Vendor = ${this.context.provinceHstRateSlab.rateType} - Inclusive price in Agreement`
      }
    ];
  }

  ngOnInit() {
    this.isTarionWarrantyAdjAvailable = StatementAdjustmentUtil.anyTarrionAdjustments(
      this.context.statementOfAdjustments
    );

    this.isHCRAAdjAvailable = StatementAdjustmentUtil.anyHCRAAdjustments(this.context.statementOfAdjustments);

    this.isOtherFixedAdjAvailable = StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(
      this.context.statementOfAdjustments
    );

    if (this.isOtherFixedAdjAvailable) {
      this.isOtherFixedAdjAvailableAndEligibleForTaxRebate =
        StatementAdjustmentUtil.isOtherFixedWithAdditionalConsiderationToVendorEligibleForTaxRebate(
          this.context.statementOfAdjustments
        );
      this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax =
        StatementAdjustmentUtil.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax(
          this.context.statementOfAdjustments
        );
      this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebate =
        StatementAdjustmentUtil.isOtherFixedAdjNotEligibleForTaxRebate(this.context.statementOfAdjustments);

      this.superscript1 = this.isOtherFixedAdjAvailableAndEligibleForTaxRebate;
      this.superscript2 = this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebate;
      this.superscript3 = this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax;
    }

    if (this.isTarionWarrantyAdjAvailable) {
      this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate =
        StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(
          this.context.statementOfAdjustments
        );
      this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate =
        StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate(
          this.context.statementOfAdjustments
        );

      this.superscript1 = this.superscript1 || this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate;
      this.superscript2 = this.superscript2 || this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate;
    }

    if (this.isHCRAAdjAvailable) {
      this.isHCRAAdjAvailableAndEligibleForTaxRebate =
        StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate(this.context.statementOfAdjustments);
      this.isHCRAAdjAvailableAndNotEligibleForTaxRebate =
        StatementAdjustmentUtil.isHCRAAdjAvailableAndNotEligibleForTaxRebate(this.context.statementOfAdjustments);

      this.superscript1 = this.superscript1 || this.isHCRAAdjAvailableAndEligibleForTaxRebate;
      this.superscript2 = this.superscript2 || this.isHCRAAdjAvailableAndNotEligibleForTaxRebate;
    }
    let netOutHst = [];
    if (
      this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate ||
      this.isOtherFixedAdjAvailableAndEligibleForTaxRebate ||
      this.isHCRAAdjAvailableAndEligibleForTaxRebate
    ) {
      netOutHst = [
        {
          label: `Yes - factor in ${this.context.provinceHstRateSlab.rateType} rebate(s)`,
          value: constValues.netOutHst.YES_FACTOR_IN_HST_REBATE
        },
        {label: 'Yes - divide sale price by 1.13', value: constValues.netOutHst.YES_DIVIDE_SALE_PRICE}
      ];
    } else {
      netOutHst = [
        {label: 'No', value: 'NO'},
        {
          label: `Yes - factor in ${this.context.provinceHstRateSlab.rateType} rebate(s)`,
          value: constValues.netOutHst.YES_FACTOR_IN_HST_REBATE
        },
        {label: 'Yes - divide sale price by 1.13', value: constValues.netOutHst.YES_DIVIDE_SALE_PRICE}
      ];
    }

    this.netOutHst = _.cloneDeep(netOutHst);
    this.salePriceAdjustmentFormatItems = _.cloneDeep(this.getDefaultSalePriceAdjustmentFormatItems());

    this.netOutHst.map(function (elem) {
      if (elem.value && elem.value.indexOf('YES') > -1 && this.isTaxRateGst()) {
        elem.label = elem.label.replace(this.context.provinceHstRateSlab.rateType, 'GST');
      }
      if (
        elem.value &&
        elem.value.indexOf('DIVIDE') > -1 &&
        this.context.provinceHstRateSlab &&
        this.context.provinceHstRateSlab.hstRate != undefined
      ) {
        let taxRate;
        if (this.context.matter.isMatterProvinceMBorSK) {
          taxRate =
            this.context.provinceHstRateSlab.hstFederalPortion < 10
              ? '0' + this.context.provinceHstRateSlab.hstFederalPortion
              : this.context.provinceHstRateSlab.hstFederalPortion;
        } else {
          taxRate =
            this.context.provinceHstRateSlab.hstRate < 10
              ? '0' + this.context.provinceHstRateSlab.hstRate
              : this.context.provinceHstRateSlab.hstRate;
        }
        elem.label = 'Yes - divide sale price by 1.' + taxRate;
      }
      return elem;
    }, this);

    this.salePriceAdjustmentLocal = !this.context.salePriceAdjustment
      ? SalePriceAdjustmentFactory.getSalePriceAdjustment(
          this.context.matter.adjustmentStatusMode,
          this.context.matter.provinceCode
        )
      : SalePriceAdjustmentFactory.getSalePriceAdjustment(
          this.context.matter.adjustmentStatusMode,
          this.context.matter.provinceCode,
          this.context.salePriceAdjustment
        );

    this.salePriceAdjustmentLocal.isProjectHstReductionInSalePriceAdjustment =
      this.isProjectHstReductionInSalePriceAdjustment;

    if (!this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice) {
      this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice = 'NO';
    }
    if (!this.salePriceAdjustmentLocal.salePriceAdjustmentFormat) {
      this.salePriceAdjustmentLocal.salePriceAdjustmentFormat = 'CREDIT_VENDOR_NET_SALE_PRICE';
    }
    if (this.salePriceAdjustmentLocal.federalPortion == undefined) {
      this.salePriceAdjustmentLocal.federalPortion = true;
    }
    if (this.salePriceAdjustmentLocal.provincialPortion == undefined) {
      this.salePriceAdjustmentLocal.provincialPortion = true;
    }
    this.closingDateLabel = this.matter.isMatterProvinceBC ? 'Completion Date' : 'Closing Date';
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }

    if (this.isTarionWarrantyAdjAvailable || this.isOtherFixedAdjAvailable || this.isHCRAAdjAvailable) {
      this.localTarionAdjustments = this.context.statementOfAdjustments.filter((adj) => adj.isTarionWarranty());
      this.updateConsiderationsFromOtherAdjustments(); // make sure the amounts from Tarion or Other Fixed adjustment are properly populated
    }

    this.loadSalePriceAdjustmentHeadings();
    this.createAdjustmentHeadingsMenutems();
    this.taxRateService.getManitobaLTTTiers().subscribe((res) => {
      this.manitobaLTTTiers = res;
    });

    //updateFormatOfSalePriceAdjustmentOptions should be called after creating salePriceAdjustmentHeadings
    this.updateFormatOfSalePriceAdjustmentOptions();
  }

  getAddConsideration(): number {
    let addConsiderationDetail = this.details
      ? this.details.find((item) => item.key == 'SalePrice.AdditionalConsideration')
      : undefined;
    return addConsiderationDetail && addConsiderationDetail.value
      ? Number(addConsiderationDetail.value.replace('$', '').replace(',', ''))
      : 0;
  }

  isSkFormatResetRequired(): boolean {
    return this.context.matter.isMatterProvinceSK && this.salePriceAdjustmentHeadings.length > 0;
  }

  ok(): void {
    let hasError: boolean = false;
    let creditVendor = this.salePriceAdjustmentLocal.isSalePriceFormatNSP()
      ? this.salePriceAdjustmentLocal.totalNetSalePrice(this.federalHstRate, this.provinceHstRate)
      : this.salePriceAdjustmentLocal.agreementSalePrice;
    if (creditVendor < 0) {
      hasError = true;
      this.modalErrorComponent.createDPSaveError('matter.consideration.creditVendor');
    } else {
      this.modalErrorComponent.removeDpSaveError('matter.consideration.creditVendor');
      if (!this.salePriceAdjustmentLocal.isInclusivePrice()) {
        this.salePriceAdjustmentLocal.resetOnNotInclusivePrice(
          !(
            this.isTarionWarrantyAdjAvailable ||
            this.isHCRAAdjAvailable ||
            this.isOtherFixedAdjAvailable ||
            this.isExtraGSTExist() ||
            this.isSkFormatResetRequired()
          )
        ); // only reset when Tarion or other adj are not available
        if (this.context.matter.isMatterProvinceAB) {
          this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings = [];
          this.salePriceAdjustmentHeadings.forEach((item) => {
            this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings.push(new SalePriceAdjustmentHeading(item));
          });
        }
      }
      // this.dialog.close(this.salePriceAdjustmentLocal);
    }

    if (this.validateSalePrice()) {
      this.modalErrorComponent.removeDpSaveError('matter.soa.modal.salePriceSOA');
    } else {
      hasError = true;
      this.modalErrorComponent.createDPSaveError('matter.soa.modal.salePriceSOA');
    }

    if (!hasError) {
      if (!this.salePriceAdjustmentLocal.isInclusivePrice()) {
        this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings = this.salePriceAdjustmentHeadings;
      } else {
        this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings = [];
      }
      if (this.context.matter && this.context.matter.isMatterProvinceMB && this.context.matter.isPurchase) {
        let salePrice: number =
          this.considerationLtt && this.context.priceType != 'SALE_PRICE_AS_IN_SOA'
            ? this.context.matter.matterPropertyWithCondo
              ? this.context.matter.matterPropertyWithCondo.purchasePrice
              : 0
            : this.salePriceAdjustmentLocal.totalNetSalePrice(this.federalHstRate, this.provinceHstRate);
        if (this.context.matter.matterPropertyWithCondo) {
          this.context.matter.matterPropertyWithCondo.landTransferTax = ManitobaLttTierUtil.calculateLttTax(
            salePrice,
            this.manitobaLTTTiers
          );
        }
      }
      if (this.context.matter && this.context.matter.considerationLtt) {
        this.context.matter.considerationLtt.needToUpdateValueOfAllChattles =
          (this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice !=
            this.context.salePriceAdjustment.netOutHstFromHSTSalePrice &&
            this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice == 'NO') ||
          Number(this.salePriceAdjustmentLocal.chattelsIncludedInSalePrice) !=
            Number(this.context.salePriceAdjustment.chattelsIncludedInSalePrice);
      }
      this.dialog.close(this.salePriceAdjustmentLocal);
    }
  }

  get considerationLtt(): ConsiderationLtt {
    return this.context.considerationLtt;
  }

  get salePrice(): number {
    return this.salePriceAdjustmentLocal ? this.salePriceAdjustmentLocal.agreementSalePrice : 0;
  }

  validateSalePrice() {
    if (this.considerationLtt && this.context.priceType === 'SALE_PRICE_AS_IN_SOA') {
      if (this.considerationLtt.isCalculateABySubstractBThroughEFromFOrManualOrLINE_A()) {
        return this.considerationLtt.isSalePriceValid(this.salePrice);
      }
    }
    return true;
  }

  close(): void {
    this.dialog.close();
  }

  reset(): void {
    if (StatementAdjustmentUtil.canResetSalePriceAdjustment(this.context.matter)) {
      this.dialogService
        .confirm('Confirmation', 'Do you wish to reset this adjustment?', false, 'Reset')
        .subscribe((res) => {
          if (res) {
            this.dialog.close(
              SalePriceAdjustmentFactory.getSalePriceAdjustment(
                this.context.matter.adjustmentStatusMode,
                <ProvinceCode>this.context.provinceHstRateSlab.provinceCode
              )
            );
          }
        });
    } else {
      this.dialogService.confirm(
        'Information',
        'Unable to reset the Sale Price Adjustment because Additional Consideration is being shown using an alternate method.',
        true
      );
    }
  }

  checkSalePrice(checkSalePrice: number): boolean {
    return isNaN(checkSalePrice);
  }

  getPortionValues(): string {
    return (
      (this.salePriceAdjustmentLocal && this.salePriceAdjustmentLocal.federalPortion ? 'Federal Portion' : '') +
      (this.salePriceAdjustmentLocal &&
      this.salePriceAdjustmentLocal.provincialPortion &&
      this.salePriceAdjustmentLocal.federalPortion
        ? ' + '
        : '') +
      (this.salePriceAdjustmentLocal && this.salePriceAdjustmentLocal.provincialPortion ? 'Provincial Portion' : '')
    );
  }

  onNetOutHstFromHSTSalePriceChange() {
    if (this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice === DpBooleanValueTypes.NO) {
      this.salePriceAdjustmentLocal.resetOnNotInclusivePrice(false);
    }
    if (this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice != DpBooleanValueTypes.NO) {
      this.salePriceAdjustmentHeadings = [];
    }
    this.addHstWarning();
    this.updateConsiderationsFromOtherAdjustments();
    this.updateFormatOfSalePriceAdjustmentOptions();
  }

  getPstSkWarning(isHstSalePriceExist: boolean, isHstRebatePriceExist): string {
    let result: string = null;
    if (
      this.context.matter.matterPropertyWithCondo &&
      (this.context.matter.matterPropertyWithCondo.isNewHomeFromBuilder ||
        this.context.matter.matterPropertyWithCondo.isFarmLand) &&
      this.context.matter.matterPropertyWithCondo.purchasePrice > 0.0 &&
      !this.context.matter.isMortgage
    ) {
      if (!isHstSalePriceExist && !isHstRebatePriceExist) {
        result = `The PST on Sale Price and PST Rebate adjustments may be required`;
      } else if (!isHstSalePriceExist) {
        result = `The PST on Sale Price adjustment may be required`;
      } else if (!isHstRebatePriceExist) {
        result = `The PST Rebate adjustment may be required`;
      }
    }
    return result;
  }

  addHstWarning(): void {
    this.modalErrorComponent.removeAllDpFieldError();

    let warningMsgPST: string = null;
    if (this.matter.isMatterProvinceSK && this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice === 'NO') {
      warningMsgPST = this.getPstSkWarning(
        this.isGstOrPstSalePriceExist('PST'),
        this.isGstOrPstRebatePriceExist('PST')
      );
    }

    let warningMsg: string = null;

    if (this.salePriceAdjustmentLocal.isFactorInHstRebate()) {
      //Net out HST from HST inclusive sale Price = Yes - factor in HST rebate(s)
      if (this.salePriceAdjustmentLocal.isSalePriceFormatNSP()) {
        //                console.log(">> this.isHstRebatePriceExist() : %s, this.isHstSalePriceExist: %s, this.isRebateApplicableForAB: %s", this.isHstRebatePriceExist(), this.isHstSalePriceExist, this.isRebateApplicableForAB())
        //Credit Vendor = Net Sale Price AND HstSalePrice Adjustment not exists
        if (!this.isHstSalePriceExist() && !this.isHstRebatePriceExist() && this.isRebateApplicableForAB()) {
          //if both hstSalePrice adjustment and HstRebate adjustment not exists
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} on Sale Price and ${this.context.provinceHstRateSlab.rateType} Rebate adjustments may be required`;
        } else if (!this.isHstSalePriceExist()) {
          //if only Hst Sale Price adjustment not exists
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} on Sale Price adjustment may be required`;
        } else if (!this.isHstRebatePriceExist() && this.isRebateApplicableForAB()) {
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} Rebate adjustment may be required`;
        }
      } else if (this.salePriceAdjustmentLocal.isSalePriceFormatINCLUSIVE() && this.isRebateApplicableForAB()) {
        if (!this.isHstRebatePriceExist()) {
          //Credit Vendor = HST - Inclusive price in Agreement  AND HstRebate Adjustment not exists
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} Rebate adjustment may be required`;
        } else if (!this.isHstRebateCreditVendor()) {
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} Rebate - Credit to Vendor adjustment may be required`; //sync with checkHSTRebateToDisplayWarningMessage from matterErrorUtil
        } else if (!this.isHstRebateCreditPurchaser()) {
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} Rebate - Credit to Purchaser adjustment may be required`; //sync with checkHSTRebateToDisplayWarningMessage from matterErrorUtil
        }
      }
    } else if (this.salePriceAdjustmentLocal.isDivideSalePrice()) {
      //Net out HST from HST inclusive sale Price = Yes - divide sale price by 1.13
      //Based on the value of field 'Format of Sale Price Adjustment', display warning message if HstSalePrice Adjustment or Hst Rebate Adjustment not exists
      if (this.salePriceAdjustmentLocal.isSalePriceFormatNSP() && !this.isHstSalePriceExist()) {
        //Credit Vendor = Net Sale Price AND HstSalePrice Adjustment not exists
        if (this.matter.isMatterProvinceMBorSK) {
          warningMsg = `The GST on Sale Price adjustment may be required`;
        } else {
          warningMsg = `The ${this.context.provinceHstRateSlab.rateType} on Sale Price adjustment may be required`;
        }
      } else if (
        this.salePriceAdjustmentLocal.isSalePriceFormatINCLUSIVE() &&
        !this.isHstRebatePriceExist() &&
        this.isRebateApplicableForAB()
      ) {
        //Credit Vendor = HST - Inclusive price in Agreement  AND HstRebate Adjustment not exists
        warningMsg = `The ${this.context.provinceHstRateSlab.rateType} Rebate adjustment may be required`;
      }
    }

    if (warningMsg && this.context.matter.isMatterProvinceMBorSK) {
      warningMsg = warningMsg.replace(new RegExp('GST\\+PST', 'g'), 'GST');
    }

    if (warningMsgPST) {
      this.modalErrorComponent.createCustomDPFieldError('matter.consideration.soa.pst', warningMsgPST, null, 'WARNING');
    }

    if (warningMsg) {
      this.modalErrorComponent.createCustomDPFieldError('matter.consideration.soa.hst', warningMsg, null, 'WARNING');
    }
  }

  isRebateApplicableForAB(): boolean {
    return (
      (this.context.matter &&
        !this.context.matter.isMatterProvinceAB &&
        this.context.matter.isRebateReminderVisible()) ||
      (this.context.matter &&
        this.context.matter.isMatterProvinceAB &&
        this.federalHstRate > 0 &&
        this.salePriceAdjustmentLocal.calculateRebate(this.federalHstRate) > 0)
    );
  }

  updateConsiderationsFromOtherAdjustments(): void {
    this.details1 = [];
    this.details2 = [];
    this.details3 = [];

    if (this.isTarionWarrantyAdjAvailable || this.isOtherFixedAdjAvailable || this.isHCRAAdjAvailable) {
      if (
        this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate ||
        this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate ||
        this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax
      ) {
        StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(
          this.salePriceAdjustmentLocal,
          this.localTarionAdjustments,
          this.context.statementAdjustmentDisplayUtil,
          this.context.matter.projectAdjustmentConfig
        );
        StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(
          this.salePriceAdjustmentLocal,
          this.context.matter.statementOfAdjustments
        );
        StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(
          this.salePriceAdjustmentLocal,
          this.context.matter.uniqueStatementAdjustments
        );
      }

      this.details1 = StatementAdjustmentDisplaySalePriceBuilder.getDetails1(
        this.context.statementOfAdjustments,
        this.context.statementAdjustmentDisplayUtil
      );
      this.details2 = StatementAdjustmentDisplaySalePriceBuilder.getDetails2(
        this.context.statementOfAdjustments,
        this.context.statementAdjustmentDisplayUtil
      );
      this.details3 = StatementAdjustmentDisplaySalePriceBuilder.getDetails3(
        this.context.statementOfAdjustments,
        this.context.statementAdjustmentDisplayUtil
      );
    }
  }

  //not consider the amount of this portion
  get showRebateOntarioPortion(): boolean {
    return this.salePriceAdjustmentLocal && this.salePriceAdjustmentLocal.showRebateProvincialPortion();
  }

  //not consider the amount of this portion
  get showRebateFederalPortion(): boolean {
    return this.salePriceAdjustmentLocal && this.salePriceAdjustmentLocal.showRebateFederalPortion();
  }

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0 && this.context.statementAdjustmentDisplayUtil) {
      this.updateConsiderationsFromOtherAdjustments();
      this.cachedDetails = this.context.statementAdjustmentDisplayUtil.getDisplayItems(
        this.salePriceAdjustmentLocal,
        '',
        true
      );
      this.cachedDetails.pop(); // remove last one about credit (it has another formatting on main "Statement of Adjustment" screen)
    }
    return this.cachedDetails;
  }

  get showFormatOfSalePriceAdjustment1(): boolean {
    return (
      this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate ||
      this.isHCRAAdjAvailableAndNotEligibleForTaxRebate ||
      this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebate ||
      this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax
    );
  }

  isExtraGSTExist(): boolean {
    return this.salePriceAdjustmentHeadings && this.salePriceAdjustmentHeadings.length > 0;
  }

  hideCreditVendorSalePriceLabel(): boolean {
    return (
      ['AB', 'MB', 'SK', 'NB', 'NS'].indexOf(this.context.matter.provinceCode) > -1 &&
      this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice === DpBooleanValueTypes.NO &&
      this.isOtherFixedAdjAvailable
    );
  }

  get showAdditionalConsiderationDetails(): boolean {
    return (
      this.salePriceAdjustmentLocal.isInclusivePrice() ||
      this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate ||
      this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate ||
      this.isHCRAAdjAvailableAndEligibleForTaxRebate ||
      this.isHCRAAdjAvailableAndNotEligibleForTaxRebate ||
      this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebate ||
      this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax
    );
  }

  updateFormatOfSalePriceAdjustmentOptions() {
    if (this.salePriceAdjustmentLocal) {
      this.salePriceAdjustmentFormatItems.map(function (elem) {
        if (elem.value == 'CREDIT_VENDOR_INCLUSIVE') {
          // if it is isNotInclusivePrice and showAdditionalConsiderationDetails/isExtraGSTExist, it will show 'Sale Price in Agreement'
          if (
            this.salePriceAdjustmentLocal.isNotInclusivePrice() &&
            (this.showFormatOfSalePriceAdjustment1 || this.isExtraGSTExist())
          ) {
            elem.label = 'Sale Price in Agreement';
          } else {
            elem.label = this.getDefaultSalePriceAdjustmentFormatItems()[1].label;
          }
          if (this.isTaxRateGst() || this.context.matter.isMatterProvinceMBorSK) {
            elem.label = elem.label.replace(this.context.provinceHstRateSlab.rateType, 'GST');
          }
        }
        elem.label = elem.label.replace('Vendor', this.vendorLabel);
        return elem;
      }, this);
    }
  }

  isRowSelected(index: number): boolean {
    return this.selectedAdjustmentHeadingRowIndex == index;
  }

  updateRowIndex(index: number): void {
    this.selectedAdjustmentHeadingRowIndex = index;
  }

  loadSalePriceAdjustmentHeadings(): void {
    if (
      this.salePriceAdjustmentLocal &&
      this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings &&
      this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings.length
    ) {
      this.salePriceAdjustmentLocal.salePriceAdjustmentHeadings.forEach((adjustmentHeading) => {
        this.salePriceAdjustmentHeadings.push(new SalePriceAdjustmentHeading(adjustmentHeading));
      });
    }
  }

  addToBurgerMenu(
    burgerMenuItems: BurgerMenuExtendedItem[],
    text: string,
    action?: any,
    isDisabled?: boolean
  ): BurgerMenuExtendedItem {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.isDisabled = isDisabled;

    if (action) {
      burgerMenuItem.action = action;
    }
    burgerMenuItems.push(burgerMenuItem);
    return burgerMenuItem;
  }

  clickAdjustmentHeadingsMenutem(clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(clickedMenuOption);
    }
  }

  createAdjustmentHeadingsMenutems(): void {
    this.addToBurgerMenu(this.emptyAdjustmentHeadingsMenutems, 'Insert Row Above', this.insertRowAbove, true);
    this.addToBurgerMenu(this.emptyAdjustmentHeadingsMenutems, 'Insert Row Below', this.insertRowBelow, true);
    this.addToBurgerMenu(this.emptyAdjustmentHeadingsMenutems, 'Delete', this.deleteAdjustmentHeading, true);
    this.addToBurgerMenu(this.emptyAdjustmentHeadingsMenutems, 'Edit', this.editAdjustmentHeading);

    this.addToBurgerMenu(this.adjustmentHeadingsMenutems, 'Insert Row Above', this.insertRowAbove);
    this.addToBurgerMenu(this.adjustmentHeadingsMenutems, 'Insert Row Below', this.insertRowBelow);
    this.addToBurgerMenu(this.adjustmentHeadingsMenutems, 'Delete', this.deleteAdjustmentHeading);
    this.addToBurgerMenu(this.adjustmentHeadingsMenutems, 'Edit', this.editAdjustmentHeading);
  }

  insertRowAbove = (): void => {
    this.selectedAdjustmentHeadingRowIndex = this.selectedAdjustmentHeadingRowIndex
      ? this.selectedAdjustmentHeadingRowIndex--
      : 0;
    let salePriceAdjustmentHeading: SalePriceAdjustmentHeading = this.createNewSalePriceAdjustmentHeading();
    this.openSalePriceAdjustmentHeading(salePriceAdjustmentHeading, this.openSalePriceAdjustmentHeadingActions.new);
  };

  insertRowBelow = (): void => {
    this.selectedAdjustmentHeadingRowIndex++;
    let salePriceAdjustmentHeading: SalePriceAdjustmentHeading = this.createNewSalePriceAdjustmentHeading();
    this.openSalePriceAdjustmentHeading(salePriceAdjustmentHeading, this.openSalePriceAdjustmentHeadingActions.new);
  };

  createNewSalePriceAdjustmentHeading(): SalePriceAdjustmentHeading {
    let salePriceAdjustmentHeading: SalePriceAdjustmentHeading = new SalePriceAdjustmentHeading();
    salePriceAdjustmentHeading.id = UUIDUtil.getUUID();
    salePriceAdjustmentHeading.cost = 0;
    return salePriceAdjustmentHeading;
  }

  deleteAdjustmentHeading = (): void => {
    this.salePriceAdjustmentHeadings.splice(this.selectedAdjustmentHeadingRowIndex, 1);
    this.selectedAdjustmentHeadingRowIndex--;
    this.updateFormatOfSalePriceAdjustmentOptions();
  };

  editAdjustmentHeading = (): void => {
    if (this.selectedAdjustmentHeadingRowIndex == -1) {
      let salePriceAdjustmentHeading: SalePriceAdjustmentHeading = this.createNewSalePriceAdjustmentHeading();
      this.openSalePriceAdjustmentHeading(salePriceAdjustmentHeading, this.openSalePriceAdjustmentHeadingActions.new);
    } else {
      this.openSalePriceAdjustmentHeading(
        this.salePriceAdjustmentHeadings[this.selectedAdjustmentHeadingRowIndex],
        this.openSalePriceAdjustmentHeadingActions.update
      );
    }
  };

  openSalePriceAdjustmentHeading(salePriceAdjustmentHeading: SalePriceAdjustmentHeading, action?: string): void {
    this.dialogService.matDialogContent({
      content: SalePriceAdjustmentHeadingModal,
      context: {
        salePriceAdjustmentHeading: salePriceAdjustmentHeading,
        statementAdjustmentHeading: this.context.statementAdjustmentHeading,
        taxType: this.appliedTaxRate,
        provinceCode: this.context.matter.provinceCode,
        matterType: this.context.matter.matterType
      },
      onFulfillment: (result: SalePriceAdjustmentHeading) => {
        if (result) {
          salePriceAdjustmentHeading = new SalePriceAdjustmentHeading(result);
          if (action == this.openSalePriceAdjustmentHeadingActions.new) {
            this.salePriceAdjustmentHeadings.splice(
              this.selectedAdjustmentHeadingRowIndex,
              0,
              salePriceAdjustmentHeading
            );
          } else {
            this.salePriceAdjustmentHeadings[this.selectedAdjustmentHeadingRowIndex] = salePriceAdjustmentHeading;
          }
          this.updateFormatOfSalePriceAdjustmentOptions();
          this.addHstWarning();
        }
      },
      modalGrid: 10
    });
  }

  // get extrasCost() : number {
  //     let extras = 0;
  //     if(!this.salePriceAdjustmentLocal.isInclusivePrice() && this.salePriceAdjustmentHeadings && this.salePriceAdjustmentHeadings.length){
  //         this.salePriceAdjustmentHeadings.forEach((heading)=>{
  //             extras += Number(heading.cost);
  //         })
  //     }
  //     return extras;
  // }
  //
  // get totalConsiderationCost() : number {
  //     return Number(this.salePriceAdjustmentLocal.agreementSalePrice) + Number(this.extrasCost);
  // }

  get isAdditionalConsiderationsInclHstAvailable(): boolean {
    return (
      !this.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate &&
      !this.isTarionWarrantyAdjAvailableAndNotEligibleForTaxRebate &&
      // ! this.isTarionWarrantyAdjAvailable &&
      !this.isHCRAAdjAvailableAndEligibleForTaxRebate &&
      !this.isHCRAAdjAvailableAndNotEligibleForTaxRebate &&
      !this.isOtherFixedAdjAvailableAndEligibleForTaxRebate &&
      !this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebate &&
      !this.isOtherFixedAdjAvailableAndNotEligibleForTaxRebatePlusTax
    );
  }

  get isAdditionalConsiderationsReadOnly(): boolean {
    return (
      StatementAdjustmentUtil.isItemizedAdjAvailableWithConsideration(this.context.matter.allStatementAdjustments) &&
      this.salePriceAdjustmentLocal.additionalConsiderationsInclHst > 0
    );
  }

  get isCreditPurchaserReadOnly(): boolean {
    return (
      StatementAdjustmentUtil.isItemizedAdjAvailableWithConsiderationForPruchaser(
        this.context.matter.allStatementAdjustments
      ) && this.salePriceAdjustmentLocal.creditsToPurchaserInclHst > 0
    );
  }

  get isAdditionalConsiderationsAvailableOnOtherSheet(): boolean {
    return (
      this.context.matter &&
      (this.context.matter.isProjectSale || this.context.project) &&
      StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(
        this.context.matter.isAdjustmentStatusModeFinal
          ? this.context.matter.interimStatementAdjustments
          : this.context.matter.finalStatementAdjustments
      )
    );
  }

  additionalConsiderationsInclHstChange(): void {
    if (!this.isAdditionalConsiderationsReadOnly) {
      this.salePriceAdjustmentLocal.isMatterAdjustmentModeFinal()
        ? (this.salePriceAdjustmentLocal.interimAdditionalConsiderationsInclHst =
            this.salePriceAdjustmentLocal.additionalConsiderationsInclHst)
        : (this.salePriceAdjustmentLocal.finalAdditionalConsiderationsInclHst =
            this.salePriceAdjustmentLocal.additionalConsiderationsInclHst);
    }
  }

  totalConsiderationSubjectToPST(): number {
    return (
      Number(this.salePriceAdjustmentLocal.getTotalConsiderationCost(this.salePriceAdjustmentHeadings)) -
      Number(this.salePriceAdjustmentLocal.priceOfLand)
    );
  }

  totalConsiderationWithExtraGST(totalConsidrationAmount: number, extraGSTAmount: number): number {
    let totalConsiderationWithExtraGST = Number(totalConsidrationAmount) + Number(extraGSTAmount);
    if (this.context.matter && this.context.matter.isMatterProvinceABorMBorSK) {
      totalConsiderationWithExtraGST = totalConsiderationWithExtraGST - this.getAddConsideration();
    }
    this.salePriceAdjustmentLocal.totalConsiderationWithExtraTax = totalConsiderationWithExtraGST;
    return totalConsiderationWithExtraGST;
  }

  isMatterProvinceSK(): boolean {
    return this.context.matter && this.context.matter.isMatterProvinceSK;
  }

  get isProjectHstReductionInSalePriceAdjustment(): boolean {
    return (
      this.context.project &&
      this.context.project.projectAdjustmentConfig &&
      this.context.project.projectAdjustmentConfig.hstReductionInSalePriceAdjustment
    );
  }

  get projectHstReductionInSalePriceAdjustmentLabel(): string {
    if (
      this.context.matter.isMatterProvinceAB ||
      (this.isProjectHstReductionInSalePriceAdjustment && this.context.matter.isMatterProvinceON)
    ) {
      return `Reduction to ${this.matterTaxType}`;
    }
    return `Exclude from ${this.matterTaxType} calculation RE`;
  }

  get matterTaxType(): string {
    return ConsiderationTaxes.calculateRateTypeLabel(this.context.matter.matterTaxType);
  }

  get isResetDisabled(): boolean {
    return (
      this.context.matter &&
      !this.context.matter.isProjectSale &&
      this.context.project &&
      this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice != DpBooleanValueTypes.NO &&
      !this.isAdditionalConsiderationsInclHstAvailable
    );
  }
}
