<div *ngIf="isCorporationPageEnabled" class="form-horizontal">

    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">
                <span [dp-province-field-label]="'matter.corp.property.cap.name'">Condo</span>
                <span> Corporation</span>
            </label>
        </div>
        <div class="col-lg-10">
            <ng-container *ngIf="condoCorporationWrapper">
                <ng-container *ngIf="condoCorporationWrapper.editMode">
                    <p-autoComplete id="condo-corporation-autocomplete"
                                    name="condo-corporation-autocomplete"
                                    placeholder="Search by Name, Number, Address or Add new"
                                    [(ngModel)]="condoCorporationWrapper.dataModel"
                                    [class.autocomplete-searching]="condoCorporationsLoading"
                                    (ngModelChange)="onCondoCorporationInputChange()"
                                    [suggestions]="condoCorporationOptions"
                                    (completeMethod)="searchCondoCorporations($event)"
                                    minLength="0"
                                    maxlength="75"
                                    delay="1000"
                                    scrollHeight="350px"
                                    (focusout)="cleanupCondoCorpField(condoCorporationWrapper)"
                                    autocomplete="off" class="autocomplete-search-icon">
                        <ng-template let-contact pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                [ngClass]="{'bold-font' : isAddNew(contact)}"
                                [disableItem]="contact">
                                <span class="col-lg-4 text-overflow">{{getContactName(contact)}}</span>
                                <span class="col-lg-2 text-overflow">{{contact.abbreviatedName}}</span>
                                <span class="col-lg-6 text-overflow">{{getContactMailingAddress(contact)}}</span>
                            </li>
                        </ng-template>
                    </p-autoComplete>
                </ng-container>
                <ng-container *ngIf="!condoCorporationWrapper.editMode">
                    <input type="text" class="form-control header-name text-overflow" readonly [value]="getContactName(condoCorporation) || ''" />
                    <div class="actionbtns">
                        <div class="width-30 global-icon padding-right-40 padding-top-5">
                                                        <span *ngIf="condoCorporationWrapper.isGlobalIconVisible()">
                                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                                        </span>
                            <span *ngIf="condoCorporationWrapper.isAddressBookIconVisible()">
                                                            <i class="fa fa-address-book" aria-hidden="true"></i>
                                                        </span>
                        </div>
                        <div class="width-60 padding-right-40 padding-top-5">
                                                        <span *ngIf="condoCorporationWrapper.isStale">
                                                            <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                                        </span>
                        </div>

                        <div class="width-30 actionbtns">
                            <dp-burger-menu *ngIf="!condoCorporationWrapper.editMode && condoCorporationWrapper.matterParticipant"
                                            [openSubMenuOnLeft]="true"
                                            [allowPopUp]="true"
                                            [items]="condoCorporationWrapper.getBurgerMenuItemsForMatterParticipant()"
                                            [disabledItems]="condoCorporationWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                            (itemClicked)="clickCondoCorporationBurgerMenuItem($event, condoCorporationWrapper)">
                            </dp-burger-menu>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <ng-container *ngIf="condoCorporationWrapper && condoCorporationWrapper.matterParticipant && !condoCorporationWrapper.editMode">
        <div class="col-lg-12 text-right margin-bottom-20" *ngIf="condoCorporationWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount
                                     && !condoCorporationWrapper.isStale && !condoCorporationWrapper.isLockedElsewhere">
            <span>
                <a href="javascript:void(0);"
                   (click)="editCondoCorporationAsPrivateCopy(condoCorporationWrapper)">
                    Edit as Private Copy
                </a>
            </span>
        </div>
        <div class="form-group-warning-message col-lg-10 offset-lg-2"
             *ngIf="condoCorporationWrapper.isLockedElsewhere">
            <div class="col-lg-1">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div>
            <div class="col-lg-11">
                <p>
                    <span>The </span>
                    <span [dp-province-field-label]="'matter.corp.property.lc.name'"> condo</span>
                    <span> corporation contact is currently locked by {{condoCorporationWrapper.lockedByUserFullName}}.</span>
                </p>
            </div>
        </div>
        <div class="form-group-warning-message col-lg-10 offset-lg-2"
             *ngIf="condoCorporationWrapper.isStale && !condoCorporationWrapper.isLockedElsewhere">
            <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
            up-->
            <div class="col-lg-1">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </div>
            <div class="col-lg-11">
                <p>
                    {{condoCorporationWrapper.outOfSyncPreMsg}}
                    <a href="javascript:void(0);"
                       (click)="openSourceContactTab(condoCorporationWrapper)" class="line-height-adjust">
                        {{condoCorporationWrapper.outOfSyncUnderlineMsg}}</a> {{condoCorporationWrapper.outOfSyncPostFixMsg}}
                </p>
            </div>
        </div>


        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" [dp-province-field-label]="'matter.company.name.label'">Company Name</label>
            </div>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="condoCorporation.organizationName"
                       (change)="onContactChanged(condoCorporation)"
                       [readonly]="condoCorporationWrapper.isParticipantDataReadOnly"
                       trimmed />
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Abbreviated Name</label>
            </div>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control"
                       [(ngModel)]="condoCorporation.abbreviatedName"
                       (change)="onContactChanged(condoCorporation)"
                       [readonly]="condoCorporationWrapper.isParticipantDataReadOnly"
                       trimmed />
            </div>
        </div>

        <div class="form-group" *ngIf="isProvinceVisible">
            <label class="control-label col-lg-2">Province</label>
            <div class="col-lg-4">
                <select class="form-control"
                        [dp-read-only]="true"
                        id="province"
                        name="province"
                        [(ngModel)]="condoCorporation.mailingAddress.provinceName">
                    <option *ngFor="let province of provinceOptions"
                            [value]="province.label">{{province.label}}
                    </option>
                </select>
            </div>
        </div>


        <div class="form-group" *dpShowByProvince="'matter.condoCorporation.threeFess'">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" [label-province-code]="condoCorporation.provinceCode"
                       dp-province-field-label="contact.condo.feeForStatusCertificate">Fee for Status Certificate</label>
            </div>
            <div class="col-lg-4">
                <input type="text"
                       class="form-control text-right"
                       [(ngModel)]="condoCorporation.feeForCondoStatusCertificate"
                       (change)="onContactChanged(condoCorporation)"
                       (blur)="updateCondoFeeOnSOA();"
                       (keydown.f9)="handleF9OnFeeForCondoStatusCertificate(condoCorporation)"
                       [dynamicHelpCallBackFunction]="dynamicHelpForCondoFee()"
                       [readonly]="condoCorporationWrapper.isParticipantDataReadOnly"
                       statusBarHelp
                       dp-default-currency
                       dp-currency
                       significant-currency-digits="5" />
            </div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Is fee subject to {{taxRateType}}?</label>
            </div>
            <div class="col-lg-4">
                <select class="form-control"
                        [(ngModel)]="condoCorporation.feesSubjectToHst"
                        (change)="onContactChanged(condoCorporation);"
                        [disabled]="condoCorporationWrapper.isParticipantDataReadOnly"
                >
                    <option *ngFor="let option of useHstOptions" [value]="option.value">{{option.label}}</option>
                </select>
            </div>
        </div>

        <div class="form-group" *dpShowByProvince="'matter.condoCorporation.threeFess'">
            <ng-container *ngIf="condoCorporation.isFeeSubjectToHST()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Total Fee</label>
                </div>
                <div class="col-lg-4">
                    <input type="text" class="form-control text-right" [value]="totalFee | currency:'USD':true:'1.2-2'" [dp-read-only]="true" />
                </div>
            </ng-container>

        </div>


        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Self-managed?</label>
            </div>
            <div class="col-lg-4">
                <!--ngModelChange should put before ngModel because we need delete the old attention-->
                <select class="form-control"
                        (ngModelChange)="onSelfManagedChanged(condoCorporation, $event)"
                        [(ngModel)]="condoCorporation.selfManagedManagementCompanyType"
                        [disabled]="condoCorporationWrapper.isParticipantDataReadOnly">
                    <option *ngFor="let option of managedTypeOptions" [value]="option.value">{{option.label}}</option>
                </select>
            </div>
        </div>


        <ng-container *ngIf="isManagementCompanyVisible">
            <div class="main-level col-lg-12">
                <span class="title">{{condoCorporationDetailSectionTitle}}</span>
            </div>
            <div class="form-group"></div>
            <div  [ngClass]="{'shutter-bg': managementCompanyWrapper.expanded, 'shutter-bg-closed':
                  (isMatterLocked || !managementCompanyWrapper.editMode) && !managementCompanyWrapper.expanded}">


                <div class="form-group">
                    <div class="shutter-arrow" *ngIf="!managementCompanyWrapper.isLoadingParticipantState" (click)="toggleManagementShutter()">
                        <div *ngIf="managementCompanyWrapper.expanded"><img src="/assets/img/minus.svg" alt="" /></div>
                        <div *ngIf="(isMatterLocked || !managementCompanyWrapper.editMode) && !managementCompanyWrapper.expanded" ><img
                            src="/assets/img/plus.svg" /></div>
                    </div>

                    <div
                          class="col-lg-2 vertical-align-label"
                          (click)="toggleManagementShutter()"
                          [class.focus]="fieldHoverIndex===11"><label class="control-label">Management<br>Company*</label></div>

                    <div class="col-lg-10">
                        <input type="text"
                               class="form-control"
                               readonly="readonly"
                               *ngIf="!managementCompanyWrapper.editMode"
                               [value]="managementCompanyWrapper.matterParticipant?.contact?.displayName"
                               (click)="toggleManagementShutter()"
                               (keydown.f9)="toggleManagementShutter()"
                               autocomplete="off"/>

                        <p-autoComplete *ngIf="managementCompanyWrapper.editMode"
                                        id="management-company-autocomplete"
                                        name="management-company-autocomplete"
                                        placeholder="Search by Name, Address"
                                        [(ngModel)]="managementCompanyWrapper.dataModel"
                                        [class.autocomplete-searching]="managementCompaniesLoading"
                                        (ngModelChange)="onManagementCompanyInputChange()"
                                        [suggestions]="managementCompanyOptions"
                                        (completeMethod)="searchManagementCompanies($event)"
                                        minLength="0"
                                        maxlength="75"
                                        delay="1000"
                                        scrollHeight="350px"
                                        autocomplete="off" class="autocomplete-search-icon">
                            <ng-template let-contact pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix" [ngClass]="{'bold-font' : isAddNew(contact)}">
                                    <span class="col-lg-6 text-overflow">{{getContactName(contact)}}</span>
                                    <span class="col-lg-6 text-overflow">{{getContactMailingAddress(contact)}}</span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                        <div class="actionbtns">
                            <div class="width-30 global-icon padding-right-40 padding-top-5">
                                                        <span *ngIf="managementCompanyWrapper.matterParticipant && managementCompanyWrapper.isGlobalIconVisible()">
                                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                                        </span>
                                <span *ngIf="managementCompanyWrapper.isAddressBookIconVisible()">
                                                            <i class="fa fa-address-book" aria-hidden="true"></i>
                                                        </span>
                            </div>
                            <div class="width-60 padding-right-40 padding-top-5">
                                                        <span *ngIf="managementCompanyWrapper.isStale">
                                                            <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                                        </span>
                            </div>

                            <div class="width-30 actionbtns">
                                <dp-burger-menu *ngIf="!managementCompanyWrapper.editMode && managementCompanyWrapper.matterParticipant && !condoCorporationWrapper.isParticipantDataReadOnly"
                                                [openSubMenuOnLeft]="true"
                                                [allowPopUp]="true"
                                                [items]="managementCompanyWrapper.getBurgerMenuItemsForMatterParticipant()"
                                                [disabledItems]="managementCompanyWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                (itemClicked)="clickManagementBurgerMenuItem($event, managementCompanyWrapper)">
                                </dp-burger-menu>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="(managementCompanyWrapper.matterParticipant && managementCompanyWrapper.expanded)">
                    <div class="col-lg-12 margin-top-10">
                        <div class="right margin-bottom-20"
                             *ngIf="managementCompanyWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount
                                     && !managementCompanyWrapper.isStale && !managementCompanyWrapper.isLockedElsewhere">
                                    <span>
                                        <a href="javascript:void(0);"
                                           (click)="editManagementAsPrivateCopy(managementCompanyWrapper)">
                                            Edit as Private Copy
                                        </a>
                                    </span>
                        </div>
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="managementCompanyWrapper.isLockedElsewhere">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The management contact is currently locked by {{managementCompanyWrapper.lockedByUserFullName}}.
                                </p>
                            </div>
                        </div>
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="managementCompanyWrapper.isStale && !managementCompanyWrapper.isLockedElsewhere">
                            <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                            up-->
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{managementCompanyWrapper.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openSourceContactTab(managementCompanyWrapper)" class="line-height-adjust">
                                        {{managementCompanyWrapper.outOfSyncUnderlineMsg}}</a> {{managementCompanyWrapper.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <dp-condo-contact-detail-info
                            [secondaryShutter]="true"
                            [condoInfoSourceContact]="managementCompanyWrapper.matterParticipant.contact"
                            [isCondoDetailReadOnly]="managementCompanyWrapper.isParticipantDataReadOnly || condoCorporationWrapper.isParticipantDataReadOnly"
                            (detailChanged)="onContactChanged(managementCompanyWrapper.matterParticipant.contact)"
                        >
                        </dp-condo-contact-detail-info>
                    </div>
                </div>
            </div>
        </ng-container>


        <div *ngIf="condoCorporation.isSelfManaged()">
            <dp-condo-contact-detail-info
                [provinceReadOnly]="condoCorporation.isProvinceReadOnly"
                [provinceName]="condoCorporation.mailingAddress.provinceName"
                [condoInfoSourceContact]="condoCorporation"
                [isCondoDetailReadOnly]="condoCorporationWrapper.isParticipantDataReadOnly"
                (detailChanged)="onContactChanged(condoCorporation)"
            >
            </dp-condo-contact-detail-info>
        </div>


        <ng-container *ngIf="showContactInformationSection()">
            <div class="main-level col-lg-12">
                <span class="title">Contact Information</span>
            </div>
            <div class="form-group"></div>
            <div *ngIf="condoCorporation.isSelfManaged()">
                <dp-attention-info
                    [parentMatterParticipant]="condoCorporationParticipant"
                    [matter]="matter"
                    [attentionList]="attentionOptions"
                    (changed)="onContactChanged(condoCorporation)">
                </dp-attention-info>
            </div>
            <div *ngIf="condoCorporation.isManagedByManagementCompany() && manageCompanyParticipant">
                <dp-attention-info
                    [parentMatterParticipant]="manageCompanyParticipant"
                    [matter]="matter"
                    [attentionList]="attentionOptions"
                    (changed)="onAttentionContactChanged(condoCorporation)">
                </dp-attention-info>
            </div>
        </ng-container>

    </ng-container>

    <dp-user-defined-fields [topicName] = "'CONDO_CORPORATION'" (onChange)="enableSave()">
    </dp-user-defined-fields>

    <div *ngIf="matter && matter.condoCorporationDocumentation && condoCorporationWrapper && !condoCorporationWrapper.editMode" class="fields-group padding-top-20">
        <ng-container *dpShowByProvince="'matter.condoCorporation.documents'">
            <div class="main-level col-lg-12">
                <span class="title">
                    <span [dp-province-field-label]="'matter.corp.property.cap.name'">Condo</span>
                    <span> Corporation Documents</span>
                </span>
            </div>
            <div class="form-group" [ngClass]="{'shutter-bg': condoDocumentsSection, 'shutter-bg-closed': !condoDocumentsSection}">
                <div class="form-group col-lg-12">
                    <label class="control-label col-lg-2 padding-right-0">
                        <div class="shutter-arrow col-lg-2" (click)="condoDocumentsSection = !condoDocumentsSection">
                            <div *ngIf="condoDocumentsSection">
                                <img src="/assets/img/minus.svg" />
                            </div>
                            <div *ngIf="!condoDocumentsSection" >
                                <img src="/assets/img/plus.svg" />
                            </div>
                        </div>
                        <div class="control-label col-lg-10 float-right">
                                <span (click)="condoDocumentsSection = !condoDocumentsSection">
                                   <span [dp-province-field-label]="'matter.corp.property.cap.name'">Condo</span> <span> Documents </span>
                                </span>
                        </div>
                    </label>
                    <label class="control-label col-lg-10 text-left">
                        <input class="form-control" [value]="condoCorporationDocumentInput" [dp-read-only]="true" />
                    </label>
                </div>
                <div *ngIf="condoDocumentsSection" class="form-group col-lg-12">
                    <div class="col-lg-10 offset-lg-2">
                        <dp-multi-select [multiSelectData]="condoDocumentsList"
                                         [inputName]="'condoDocuments'" [title]="'condoDocuments'"
                                         [showLabelInTitle]="false"
                                         [defaultToAll]="false"
                                         [multiSelectDataSelected]="selectedCondoDocumentsList"
                                         (updateMultiSelect)="updateSelectedCondoDocumentsList($event)"
                                         [listStyle]="true"></dp-multi-select>
                    </div>
                    <div class="col-lg-10 offset-lg-2 padding-top-20">
                        <div class="col-lg-6 text-left">
                            <label class="control-label">Total Cost of Documents Ordered</label>
                        </div>
                        <div class="col-lg-2">
                            <input class="form-control"
                                   [(ngModel)]="matter.condoCorporationDocumentation.costOfDocumentsOrdered" dp-currency
                                   (change)="calcCondoCorporationDocumentTotal()" />
                        </div>
                    </div>
                    <div class="col-lg-10 offset-lg-2 padding-top-5">
                        <div class="col-lg-2 text-left">
                            <label class="control-label text-left">Subject to GST?</label>

                        </div>
                        <div class="col-lg-1 text-left">
                            <select class="form-control"
                                    [(ngModel)]="matter.condoCorporationDocumentation.subjectToGst"
                                    (change)="calcCondoCorporationDocumentTotal()" >
                                <option *ngFor="let subjectToGSTOption of subjectToGSTOptions" [value]="subjectToGSTOption.value">{{subjectToGSTOption.label}}</option>
                            </select>
                        </div>
                        <div *ngIf="isSubjectToGst" class="col-lg-2 text-center">
                            <label class="control-label">GST percentage is</label>
                        </div>
                        <div *ngIf="isSubjectToGst" class="col-lg-1 text-left">
                            <input class="form-control inline-block width-70-percent"
                                   [(ngModel)]="matter.condoCorporationDocumentation.taxRate"
                                   (change)="calcCondoCorporationDocumentTotal()"
                                   [dp-read-only]="matter.isMatterProvinceAB || matter.isMatterProvinceBC"
                                   dp-percentage  />
                            <label class="control-label inline-block text-left padding-top-0">%</label>
                        </div>
                        <div *ngIf="isSubjectToGst" class="col-lg-2">
                            <input class="form-control" [dp-read-only]="true" [(ngModel)]="documentOrderdCostTaxRate" dp-currency />
                        </div>
                    </div>
                    <div class="col-lg-10 offset-lg-2 padding-top-5">
                        <div class="col-lg-4"></div>
                        <div *ngIf="isSubjectToGst" class="col-lg-2">
                            <label class="control-label">Total</label>
                        </div>
                        <div *ngIf="isSubjectToGst" class="col-lg-2">
                            <input class="form-control" [dp-read-only]="true" [(ngModel)]="documentOrderdTotalCost" dp-currency />
                        </div>
                    </div>

                </div>
            </div>
        </ng-container>

    </div>
</div>
