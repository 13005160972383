import {DPError, ErrorType} from './dp-error';
import {Inject, Injectable} from '@angular/core';
import {ErrorTab} from './error-tab';
import * as _ from 'lodash';
import {Matter} from '../../matters/shared/matter';
import {MortgageMatterTopics, OpportunityMatterTopics, PurchaseMatterTopics, SaleMatterTopics} from '../../shared-main/constants';
import {MissingFieldTab} from '../missing-fields/missing-field-tab';
import {DpFooterNotification, DpFooterTypes} from './dp-footer-notification';
import {DPNotificationTab} from './dp-notification-tab';
import {ErrorDirectiveOrder} from '../../shared/error-handling/error-directive-order';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {opportunityMatterErrorMessages, provinceBasedErrorMessages} from './error-directive-mapping';
import Utils from '../../shared-main/utils';
import {FieldError} from '../../core/application-error';
import {ConsiderationTaxes} from '../../matters/consideration-ltt/consideration-taxes';
import {NotifierService} from 'angular-notifier';
import {UUID} from 'angular2-uuid';
import moment from 'moment';
import {TabsService} from '../../core';
import {MatterTab} from '../../matters/matter-tab';
import {ProjectTab} from '../../projects/shared/project-tab';
import {WizardTab} from '../tabbing/wizard-tab';
import {UserStateService} from '../../shared-main/user-state/user-state.service';

declare var jQuery: any;

@Injectable()
export class ErrorService {

  public tabService: TabsService; //set manually by tabs component and not through dependency injection because of a circular dependency
  public notificationBubbleCache: string[] = [];

  constructor(@Inject(NotifierService) public notifierService: NotifierService,
              @Inject(UserStateService) public userStateService: UserStateService) {
  }

  //the following define a few placeholder that being used in the error message which will be replaced based on the matter type
  static PH_ERROR_TOPIC_TAB_A: string = '<TAB_A_TOPIC>';
  static PH_ERROR_TOPIC_MAIN_CLIENT: string = '<MAIN_TOPIC>';
  static PH_ERROR_MSG_MAIN_CLIENT: string = '<MAIN_CLIENT>';
  static PH_ERROR_MSG_MAIN_CLIENT_LC: string = '<MAIN_CLIENT_LC>';
  static PH_ERROR_TOPIC_TAB_C: string = '<TAB_C_TOPIC>';
  static PH_ERROR_MSG_OTHER_PARTY: string = '<OTHER_PARTY>';
  static PH_ERROR_MSG_OTHER_PARTY_LC: string = '<OTHER_PARTY_LC>';
  static PH_ERROR_MSG_HST: string = '<HST>';
  static PH_EXISING_MORTGAGE_IDX_ORDINAL: string = '<EXT_MTG_IDX_ORDINAL>';
  static PH_ERROR_TOPIC_TAB_L: string = '<TAB_L_TOPIC>';
  static ERROR_PLACEHOLDERS: string[] = [ '<FIELD_LABEL>', '<TOPIC_LABEL>' ];
  //add any province based placeholders to this array
  static PROVINCE_BASED_PLACEHOLDERS = [ '<PROVINCE_PIN_LINC>', '<LAW_CLERK_EMAIL>', '<FIELD_LABEL>', '<TOPIC_LABEL>' ];
  static OPPORTUNITY_PLACEHOLDERS = [ '<FIELD_LABEL>' ];

  dpFooterNotifications: DpFooterNotification[] = [];
  missingFieldType: string;
  private _errorNotificationTemplate: any;
  focusedErrorElement: DPError;
  private _provinceCode: ProvinceCode;
  // Use z (small alphabet z) to denote it is applicable to Project Sale
  private _matterType: string;

  get errorTab(): ErrorTab {
    let errorTab: ErrorTab;
    if (this.dpFooterNotifications) {
      let pFooterNotification = this.dpFooterNotifications.find(item => item.dpFooterType == DpFooterTypes.ERROR);
      if (pFooterNotification && pFooterNotification.errorTab) {
        errorTab = pFooterNotification.errorTab;
      }
    }
    return errorTab;
  }

  set provinceCode(value: ProvinceCode) {
    this._provinceCode = value;
  }

  set matterType(value: string) {
    this._matterType = value;
  }

  set errorNotificationTemplate(value: any) {
    this._errorNotificationTemplate = value;
  }

  get provinceCode(): ProvinceCode {
    return this._provinceCode ? this._provinceCode : undefined;
  }

  get matterType(): string {
    return this._matterType ? this._matterType : (this.activeMatter ? this.activeMatter.matterType : undefined);
  }

  setMissingFieldType(missingFieldType: string): void {
    this.missingFieldType = missingFieldType;
  }

  get missingFieldTab(): MissingFieldTab {
    let missingFieldTab: MissingFieldTab;
    if (this.dpFooterNotifications) {
      let pFooterNotification = this.dpFooterNotifications.find(item => item.dpFooterType == this.missingFieldType);
      if (pFooterNotification && pFooterNotification.missingFieldTab) {
        missingFieldTab = pFooterNotification.missingFieldTab;
      }
    }
    return missingFieldTab;
  }

  setfocusErrorElement(dpError: DPError): void {
    this.focusedErrorElement = dpError;
  }

  resetfocusErrorElement(): void {
    this.focusedErrorElement = undefined;
  }

  get dpThirdPartyNotificationTab(): DPNotificationTab {
    let dpNotificationTab: DPNotificationTab;
    if (this.dpFooterNotifications) {
      let pFooterNotification = this.dpFooterNotifications.find(item => item.isThirdPartyNotificationType());
      if (pFooterNotification && pFooterNotification.dpThirdPartyNotificationTab) {
        dpNotificationTab = pFooterNotification.dpThirdPartyNotificationTab;
      }
    }
    return dpNotificationTab;
  }

  // Errors  Methods

  addDpFieldError(error: DPError) {
    if (this.errorTab) {
      let dpError: DPError = _.find(this.errorTab.fieldErrors, function (dpError: DPError) {
        return dpError.errorElementKey === error.errorElementKey;
      });
      if (!dpError) {
        this.errorTab.fieldErrors.unshift(error);
        this.showErrorNotificationMessageBubble(error);
      }
    }
  }

  addDpFieldErrorWithNoDupBubble(error: DPError, existingErrorsDisplayed: DPError[]) {
    if (this.errorTab) {
      let dpError: DPError = _.find(this.errorTab.fieldErrors, function (dpError: DPError) {
        return dpError.errorElementKey === error.errorElementKey;
      });
      if (!dpError) {
        this.errorTab.fieldErrors.unshift(error);
        if (Array.isArray(existingErrorsDisplayed) && existingErrorsDisplayed.findIndex(dpError => dpError.errorElementKey === error.errorElementKey) == -1) {
          this.showErrorNotificationMessageBubble(error);
        }
      }
    }
  }

  addDpSaveError(error: DPError) {
    this.replacePlaceHolderInError(error);
    if (this.errorTab) {
      let dpError: DPError = _.find(this.errorTab.saveErrors, function (dpError: DPError) {
        return dpError.errorElementKey === error.errorElementKey;
      });
      if (!dpError) {
        this.errorTab.saveErrors.unshift(error);
        this.showErrorNotificationMessageBubble(error);
      }
    }

  }

  removeDpFieldError(errorElementKey: string, fieldId?: string) {
    if (fieldId) {
      errorElementKey += '_' + fieldId;
    }
    if (this.errorTab && Array.isArray(this.errorTab.fieldErrors)) {
      let dpErrorIndex: number = _.findIndex(this.errorTab.fieldErrors, function (dpError: DPError) {
        return dpError.errorElementKey === errorElementKey ||
          dpError.errorElementKey === (errorElementKey + '.INVALID');
      });
      if (dpErrorIndex !== -1) {
        if (this.errorTab.fieldErrors[ dpErrorIndex ] && this.errorTab.fieldErrors[ dpErrorIndex ].bubbleNotificationId) {
          this.closeNotificationBubble(this.errorTab.fieldErrors[ dpErrorIndex ].bubbleNotificationId);
        }
        this.errorTab.fieldErrors.splice(dpErrorIndex, 1);
      }
    }
  }

  isAnyDpFieldError(errorElementKey: string, fieldId?: string): boolean {
    if (fieldId) {
      errorElementKey += '_' + fieldId;
    }
    if (this.errorTab && Array.isArray(this.errorTab.fieldErrors)) {
      let dpErrorIndex: number = _.findIndex(this.errorTab.fieldErrors, function (dpError: DPError) {
        return dpError.errorElementKey === errorElementKey ||
          dpError.errorElementKey === (errorElementKey + '.INVALID');
      });
      return (dpErrorIndex !== -1);
    }
    return false;
  }

  removeMultipleDpFieldErrors(errorElementKey: string): void {
    if (this.errorTab) {
      let dpErrors = this.errorTab.fieldErrors.filter((dpError: DPError) => {
        return dpError.errorElementKey.includes(errorElementKey);
      });

      if (dpErrors && dpErrors.length) {
        dpErrors.forEach((fieldError) => {
          if (fieldError && fieldError.bubbleNotificationId) {
            this.closeNotificationBubble(fieldError.bubbleNotificationId);
          }
          (<any>this.errorTab.fieldErrors).remove(fieldError);
        });
      }
    }
  }

  removeDpSaveError(errorElementKey: string, fieldId?: string) {
    if (fieldId) {
      errorElementKey += '_' + fieldId;
    }
    if (this.errorTab) {
      let dpErrorIndex: number = _.findIndex(this.errorTab.saveErrors, function (dpError: DPError) {
        return dpError.errorElementKey === errorElementKey ||
          dpError.errorElementKey === (errorElementKey + '.MISSING');
      });
      if (dpErrorIndex !== -1) {
        if (this.errorTab.saveErrors[ dpErrorIndex ] && this.errorTab.saveErrors[ dpErrorIndex ].bubbleNotificationId) {
          this.closeNotificationBubble(this.errorTab.saveErrors[ dpErrorIndex ].bubbleNotificationId);
        }
        this.errorTab.saveErrors.splice(dpErrorIndex, 1);
      }
    }
  }

  clearAllSaveErrors() {
    if (this.errorTab) {
      this.errorTab.saveErrors = [];
    }
  }

  hasErrors(): boolean {
    if (this.errorTab) {
      let dpError: DPError = _.find(this.errorTab.saveErrors.concat(this.errorTab.fieldErrors), function (dpError: DPError) {
        return dpError.errorType === 'ERROR';
      });

      return !!dpError;
    }

    return false;
  }

  hasNoErrors(): boolean {
    return !this.hasErrors();
  }

  removeDpfieldErrorByCoreErrorElementKey(coreErrorElementKey: string) {
    if (this.errorTab && Array.isArray(this.errorTab.fieldErrors)) {
      this.errorTab.fieldErrors = this.errorTab.fieldErrors.filter((item: DPError) => {
        return item.errorElementKey && item.errorElementKey.indexOf(coreErrorElementKey) !== 0;
      });
    }
  }

  getDPError(errorElementKey: string): DPError {

    let dpError: DPError;
    if (this.errorTab) {
      dpError = _.find(this.errorTab.fieldErrors.concat(this.errorTab.saveErrors), function (dpError: DPError) {
        return dpError.errorElementKey === errorElementKey ||
          dpError.errorElementKey === (errorElementKey + '.INVALID');
      });
    }
    return dpError;
  }

  removeAllDpFieldError() {
    if (this.errorTab) {
      this.errorTab.fieldErrors = [];
    }
  }

  removeErrorsInGroup(groupId: string) {
    if (groupId && this.errorTab) {
      this.errorTab.fieldErrors = _.filter(this.errorTab.fieldErrors, error => !(error.groupId == groupId || (error.groupId && error.groupId.startsWith(groupId + '-'))));
    }
  }

  getIndex(key: string, fieldId: string, containerId: string): number {
    let parentId = containerId ? '#' + containerId : '#' + key.split('.').join('').split('_')[ 0 ] + '_container';
    let fieldIdPrefix;
    if (fieldId && fieldId.toString().indexOf('_') > -1) {
      fieldIdPrefix = fieldId.split('_')[ 0 ];
    }

    let parent = jQuery(parentId);

    let element = jQuery('#' + fieldId)[ 0 ];
    let inputs = parent.find('[id^=' + fieldIdPrefix + ']');
    let index = element ? inputs.index(element) : -1;

    //Short circuit the logic and exit fast if the parent element is not found (the UI shows something else, not related to this fields)
    if (index < 0) {
      return -1;
    }

    let offset = parent.attr('attr-offset');
    if (offset) {
      index += Number.parseInt(offset);
    }
    return index + 1;
  }

  getErrorMessageWithLatestIndex(error: DPError): string {
    if (error.errorElementKey && error.fieldId) {
      let newIndex = this.getIndex(error.errorElementKey, error.fieldId, error.containerId);
      if (error.fieldIndexId) {
        newIndex = this.getIndex(error.errorElementKey, error.fieldIndexId, error.containerId);
      }
      //If the dom is not showing the current UI, the old (cached) index will be used
      if (newIndex >= 0) {
        error.errorIndex = newIndex;
      }
    }
    let errorMessageDetails = error.errorMessage;
    errorMessageDetails = error.errorIndex > 0 ? errorMessageDetails.replace('#', error.errorIndex.toString()) : error.errorMessage;
    return errorMessageDetails;

  }

  getTopicNameForTabL(matter: Matter): string {
    let topicMsgForTabL = 'Unknown';
    if (matter.isPurchase) {
      topicMsgForTabL = PurchaseMatterTopics.TAB_L_TOPIC;
    }
    if (matter.isSale) {
      topicMsgForTabL = SaleMatterTopics.TAB_L_TOPIC;
    }
    if (matter.isMortgage) {
      topicMsgForTabL = MortgageMatterTopics.TAB_L_TOPIC;
    }
    return topicMsgForTabL;
  }

  getTopicNameForTabC(matter: Matter): string {
    let topicMsgForTabC = 'Unknown';
    if (matter.isPurchase) {
      topicMsgForTabC = PurchaseMatterTopics.TAB_C_TOPIC;//"Vendors & Solicitor";
    }
    if (matter.isSale) {
      topicMsgForTabC = SaleMatterTopics.TAB_C_TOPIC;//"Purchasers & Solicitor";
    }
    if (matter.isMortgage) {
      topicMsgForTabC = MortgageMatterTopics.TAB_C_TOPIC;//"Other Solicitor";
    }
    return topicMsgForTabC;
  }

  getTopicNameForTabA(matter: Matter): string {
    if (matter.isOpportunityMatter()) {
      return OpportunityMatterTopics.TAB_A_TOPIC;//"Opportunity Detail"
    }
    return PurchaseMatterTopics.TAB_A_TOPIC;//"Matter Opening"
  }

  //this method will replace the PLACE_HOLDER in the message to the matter aware value
  createMatterTypeAwareMessage(matter: Matter, message: string, error?: DPError) {
    let replacedMsg = message;
    if (matter && message) {
      let mainClientTitle: string = matter.mainClientTitle;
      let otherPartyTitle: string = matter.otherPartyTitle;
      replacedMsg = message.replace(ErrorService.PH_ERROR_TOPIC_MAIN_CLIENT, mainClientTitle + 's');
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_MSG_MAIN_CLIENT, mainClientTitle);
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_MSG_MAIN_CLIENT_LC, mainClientTitle.toLowerCase());
      replacedMsg = Utils.replaceAll(replacedMsg, ErrorService.PH_ERROR_MSG_HST, ConsiderationTaxes.calculateRateTypeLabel(matter.matterTaxType));
      if (replacedMsg.indexOf(ErrorService.PH_EXISING_MORTGAGE_IDX_ORDINAL) > -1) {
        let existingMtgIdx: number = this.getExistingMortgageIdx(matter, error);
        replacedMsg = Utils.replaceAll(replacedMsg, ErrorService.PH_EXISING_MORTGAGE_IDX_ORDINAL, (existingMtgIdx == -1 ? '' : Utils.getOrdinal(existingMtgIdx)));
      }

      let topicMsgForTabA = this.getTopicNameForTabA(matter);
      let topicMsgForTabC = this.getTopicNameForTabC(matter);
      let topicMsgForTabL = this.getTopicNameForTabL(matter);
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_TOPIC_TAB_A, topicMsgForTabA);
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_TOPIC_TAB_C, topicMsgForTabC);
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_TOPIC_TAB_L, topicMsgForTabL);
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_MSG_OTHER_PARTY, otherPartyTitle);
      replacedMsg = replacedMsg.replace(ErrorService.PH_ERROR_MSG_OTHER_PARTY_LC, otherPartyTitle.toLowerCase());
    }
    if (error && this.provinceSubsituteCandidate(replacedMsg)) {
      let provinceCode = matter?.provinceCode ? matter.provinceCode : this.userStateService.defaultProvinceCode;
      return this.substituteProvinceText(error.errorElementKey, message, provinceCode, matter?.matterType);
    } else {
      return replacedMsg;
    }
  }

  provinceSubsituteCandidate(replaceMsg): boolean {
    return ErrorService.ERROR_PLACEHOLDERS.findIndex((element) => replaceMsg?.indexOf(element) > -1) != -1;
  }

  getExistingMortgageIdx(matter: Matter, error: DPError): number {
    if (matter && error && error.groupId) {
      if (Array.isArray(matter.existingMortgages) && matter.existingMortgages.length > 0) {
        if (error.mortgageId) {
          const mtgIdx: number = matter.existingMortgages.findIndex(mtg => mtg.identifier == error.mortgageId);
          if (mtgIdx >= 0) {
            return mtgIdx + 1;
          }
        } else {
          const mtgIdx: number = matter.existingMortgages.findIndex(mtg => mtg.identifier + '' == error.groupId || error.groupId.startsWith(mtg.identifier + '-'));
          if (mtgIdx >= 0) {
            return mtgIdx + 1;
          }
        }
      }
    }
    return -1;
  }

  substituteProvinceText(key: string, message: string, province: string, matterType: string) {
    ErrorService.PROVINCE_BASED_PLACEHOLDERS.forEach(placeHolder => {
      let placeHolderRegEx: RegExp = new RegExp(placeHolder, 'g');
      if (message && message.indexOf(placeHolder) > -1) {
        message = message.replace(placeHolderRegEx, provinceBasedErrorMessages.get(key, placeHolder, province, matterType));
      }
    });

    return message;
  }

  substituteOpportunityText(key: string, message: string): string {
    ErrorService.OPPORTUNITY_PLACEHOLDERS.forEach(placeHolder => {
      let placeHolderRegEx: RegExp = new RegExp(placeHolder, 'g');
      if (message && message.indexOf(placeHolder) > -1) {
        message = message.replace(placeHolderRegEx, opportunityMatterErrorMessages.get(key, placeHolder, this.matterType));
      }
    });

    return message;
  }

  closeErrorTab(): void {
    this.closeFooterNotification();
  }

  private replacePlaceHolderInError(error: DPError): void {
    if (error && error.errorTopic && error.errorTopic.length > 0) {
      error.errorTopic = error.errorTopic.indexOf('Mortgages') != -1 ? 'Mortgages' : error.errorTopic;
      error.errorTopic = error.errorTopic.indexOf('<TAB_B_TOPIC>') != -1 ? 'Purchasers' : error.errorTopic;
      //TODO refactor to replace error messages
      // error.errorTopic = error.errorTopic.indexOf('<TAB_C_TOPIC>') != -1  ? 'Vendors & Solicitor' : error.errorTopic;
      error.errorTopic = error.errorTopic.indexOf('<TAB_D_TOPIC>') != -1 ? provinceBasedFieldLabels.get('provinceBasedTabDTopic', this.provinceCode) : error.errorTopic;
      error.errorTopic = error.errorTopic.indexOf('<TAB_5_TOPIC>') != -1 ? 'Title Insurance' : error.errorTopic;
    }

  }

  // Ereg Missing Fields
  addDpMissingFieldError(error: DPError, skipMessageBubble?: boolean) {
    let orderId: number = 1000;

    if (error && error.errorTopic && error.errorTopic.length > 0) {
      /*            error.errorTopic = error.errorTopic.indexOf('Mortgages') != -1 ? 'Mortgages' : error.errorTopic;
                  error.errorTopic = error.errorTopic.indexOf('<TAB_B_TOPIC>') != -1  ? 'Purchasers' : error.errorTopic;
                  error.errorTopic = error.errorTopic.indexOf('<TAB_C_TOPIC>') != -1  ? 'Vendors & Solicitor' : error.errorTopic;
                  error.errorTopic = error.errorTopic.indexOf('<TAB_D_TOPIC>') != -1  ? provinceBasedFieldLabels.get('provinceBasedTabDTopic', this.provinceCode) : error.errorTopic;
                  error.errorTopic = error.errorTopic.indexOf('<TAB_5_TOPIC>') != -1  ? 'Title Insurance' : error.errorTopic;*/
      this.replacePlaceHolderInError(error);

      let foundMissingData = ErrorDirectiveOrder[ error.errorTopic ];
      orderId = foundMissingData ? foundMissingData.ERROR_ORDER_ID : 1000;
    }

    error.setOrder(orderId);

    if (this.missingFieldTab) {
      let dpError: DPError = _.find(this.missingFieldTab.missingFieldErrors, function (dpError: DPError) {
        return dpError.errorElementKey === error.errorElementKey;
      });
      if (!dpError) {
        this.missingFieldTab.missingFieldErrors.unshift(error);
        this.orderFooterNotification(this.missingFieldType);
        if (!skipMessageBubble) {
          this.showErrorNotificationMessageBubble(error);
        }

      }
    }
  }

  hasAnyMissingFieldErrors(): boolean {
    return (this.missingFieldTab && this.missingFieldTab.missingFieldErrors ? this.missingFieldTab.missingFieldErrors.length > 0 : false);
  }

  hasErrorsInMissingField(errType: ErrorType): boolean {
    if (this.missingFieldTab && this.missingFieldTab.missingFieldErrors && this.missingFieldTab.missingFieldErrors.length > 0) {
      return this.missingFieldTab.missingFieldErrors.filter(dpError => dpError.errorType == errType).length > 0;
    }
    return false;
  }

  removeDpMissingFieldError(errorElementKey: string, fieldId?: string) {
    if (fieldId) {
      errorElementKey += '_' + fieldId;
    }
    if (this.missingFieldTab) {
      let dpErrorIndex: number = _.findIndex(this.missingFieldTab.missingFieldErrors, function (dpError: DPError) {
        return dpError.errorElementKey === errorElementKey ||
          dpError.errorElementKey === (errorElementKey + '.INVALID');
      });
      if (dpErrorIndex !== -1) {
        this.missingFieldTab.missingFieldErrors.splice(dpErrorIndex, 1);
      }
    }
  }

  //used to remove the dpFieldError with Key match with the input key value which may contains * at begin or/and end
  removeDpMissingFieldErrorsByPartialKey(wildCardKeyValue: string) {
    if (this.missingFieldTab && Array.isArray(this.missingFieldTab.missingFieldErrors) && wildCardKeyValue) {
      this.missingFieldTab.missingFieldErrors = this.missingFieldTab.missingFieldErrors.filter((item: DPError) => !this.isFieldErrorKeyMatching(item.errorElementKey, wildCardKeyValue));
    }
  }

  clearAllMissingFieldErrors() {
    if (this.missingFieldTab) {
      this.missingFieldTab.missingFieldErrors = [];

    }
  }

  // Third Party Notifications

  addDpThirdPartyNotification(error: DPError) {

    if (error.errorTopic.substring(0, 3).toUpperCase() == 'STE') { //Stewart Title
      error.errorOrderId = 10;
    } else if (error.errorTopic.substring(0, 3).toUpperCase() == 'FCT') { //FCT
      error.errorOrderId = 20;
    } else if (error.errorTopic.substring(0, 3).toUpperCase() == 'CHI') {//Chicago Title
      error.errorOrderId = 30;
    } else if (error.errorTopic.substring(0, 3).toUpperCase() == 'ASS') { //Assyst Real Estate/Unity® Lender Centre
      error.errorOrderId = 40;
    } else {
      error.errorOrderId = 100;
    }
    if (this.dpThirdPartyNotificationTab) {
      let dpError: DPError = _.find(this.dpThirdPartyNotificationTab.notifications, function (dpError: DPError) {
        return dpError.errorElementKey === error.errorElementKey;
      });
      if (!dpError) {
        this.dpThirdPartyNotificationTab.notifications.unshift(error);
        this.orderThirdPartyFooterNotification(DpFooterTypes.THIRDPARTYNOTIFICATIONS);
        this.showErrorNotificationMessageBubble(error);
        //console.log("~~~ show the bubble for ThirdPartyError with notificationId %s", error.bubbleNotificationId)
      }
    }
  }

  hasAnyDpThirdPartyNotifications(): boolean {
    return (this.dpThirdPartyNotificationTab && this.dpThirdPartyNotificationTab.notifications ? this.dpThirdPartyNotificationTab.notifications.length > 0 : false);
  }

  removeDpThirdPartyNotification(errorElementKey: string, fieldId?: string, mortgageId?: number) {
    if (fieldId) {
      errorElementKey += '_' + fieldId;
    }
    if (this.dpThirdPartyNotificationTab) {
      let dpErrorIndex: number = _.findIndex(this.dpThirdPartyNotificationTab.notifications, function (dpError: DPError) {
        return (dpError.errorElementKey === errorElementKey ||
            dpError.errorElementKey === (errorElementKey + '.INVALID'))
          && (!mortgageId || mortgageId === dpError.mortgageId)
          ;
      });
      if (dpErrorIndex !== -1) {
        //console.log("~~~ close the bubble for ThirdPartyError with notificationId %s", this.dpThirdPartyNotificationTab.notifications[dpErrorIndex].bubbleNotificationId)
        this.closeNotificationBubble(this.dpThirdPartyNotificationTab.notifications[ dpErrorIndex ].bubbleNotificationId);
        this.dpThirdPartyNotificationTab.notifications.splice(dpErrorIndex, 1);
      }
    }
  }

  getThirdPartyNotificationByMortgageAndFieldCode(mortgageId: number, fieldCode: string): DPError {
    if (this.dpThirdPartyNotificationTab) {
      return _.find(this.dpThirdPartyNotificationTab.notifications, function (dpError: DPError) {
        return mortgageId === dpError.mortgageId && fieldCode === dpError.fieldCode;
      });
    }
    return null;
  }

  removeDpThirdPartyNotificationsByMortgageId(mortgageId: number) {
    if (this.dpThirdPartyNotificationTab && Array.isArray(this.dpThirdPartyNotificationTab.notifications)) {
      this.dpThirdPartyNotificationTab.notifications = _.filter(this.dpThirdPartyNotificationTab.notifications, error => mortgageId !== error.mortgageId);
    }
  }

  //used to remove the dpFieldError with Key match with the input key value which may contains * at begin or/and end
  removeDpThirdPartyNotificationByPartialKey(wildCardKeyValue: string) {
    if (this.dpThirdPartyNotificationTab && Array.isArray(this.dpThirdPartyNotificationTab.notifications) && wildCardKeyValue) {
      this.dpThirdPartyNotificationTab.notifications = this.dpThirdPartyNotificationTab.notifications.filter((item: DPError) => !this.isFieldErrorKeyMatching(item.errorElementKey, wildCardKeyValue));
    }
  }

  clearAllDpThirdPartyNotifications() {
    if (this.dpThirdPartyNotificationTab) {
      this.dpThirdPartyNotificationTab.notifications = [];
      //this.openFooterNotification(DpFooterTypes.THIRDPARTYNOTIFICATIONS);
    }
  }

  //used to remove the dpFieldError with Key match with the input key value which may contains * at begin or/and end
  removeDpfieldErrorsByPartialKey(wildCardKeyValue: string) {
    if (this.errorTab && Array.isArray(this.errorTab.fieldErrors) && wildCardKeyValue) {
      this.errorTab.fieldErrors = this.errorTab.fieldErrors.filter((item: DPError) => !this.isFieldErrorKeyMatching(item.errorElementKey, wildCardKeyValue));
    }
  }

  private isFieldErrorKeyMatching(fieldErrorKey: string, wildCardKeyValue: string): boolean {
    if (fieldErrorKey && wildCardKeyValue) {
      let match: boolean = false;
      if (wildCardKeyValue.startsWith('*') && wildCardKeyValue.endsWith('*')) {
        let keyValueSearch = wildCardKeyValue.substring(1, wildCardKeyValue.length - 1);
        match = fieldErrorKey.indexOf(keyValueSearch) != -1;
      } else if (wildCardKeyValue.startsWith('*')) {
        let keyValueSearch = wildCardKeyValue.substring(1);
        match = fieldErrorKey.endsWith(keyValueSearch);
      } else if (wildCardKeyValue.endsWith('*')) {
        let keyValueSearch = wildCardKeyValue.substring(0, wildCardKeyValue.length - 1);
        match = fieldErrorKey.startsWith(keyValueSearch);
      } else {
        match = fieldErrorKey === wildCardKeyValue;
      }
      return match;
    }
    return false;
  }

  closeFooterNotification(): void {
    if (this.dpFooterNotifications) {
      this.dpFooterNotifications.forEach(item => {
        item.isActive = false;
      });
    }

  }

  openFooterNotification(dpFooterType: string): void {
    this.closeFooterNotification();
    let pFooterNotification = this.dpFooterNotifications.find(item => item.dpFooterType == dpFooterType);
    if (pFooterNotification && pFooterNotification.notificationCount() > 0) {
      pFooterNotification.isActive = true;
    }
  }

  orderFooterNotification(dpFooterType: string): void {
    let pFooterNotification = this.dpFooterNotifications.find(item => item.dpFooterType == dpFooterType);
    if (pFooterNotification && pFooterNotification.notificationCount() > 0) {
      pFooterNotification.missingFieldTab.missingFieldErrors =
        _.sortBy(pFooterNotification.missingFieldTab.missingFieldErrors, [ 'errorOrderId' ]);
    }
  }

  orderThirdPartyFooterNotification(dpFooterType: string): void {
    let pFooterNotification = this.dpFooterNotifications.find(item => item.dpFooterType == dpFooterType);
    if (pFooterNotification && pFooterNotification.notificationCount() > 0) {
      pFooterNotification.dpThirdPartyNotificationTab.notifications =
        _.sortBy(pFooterNotification.dpThirdPartyNotificationTab.notifications, [ 'errorOrderId', 'errorTopic', 'errorMessage' ]);
    }
  }

  isAnyNotificationActive(): boolean {
    return this.dpFooterNotifications.some(item => item.isActive && (new DpFooterNotification(item)).notificationCount() > 0);
  }

  activeNotification(): DpFooterNotification {
    return this.dpFooterNotifications.find(item => item.isActive && (new DpFooterNotification(item)).notificationCount() > 0);
  }

  activeNotificationList(): DPError[] {
    let activeNotification = this.activeNotification();
    if (activeNotification && activeNotification.isMissingFieldType() && activeNotification.missingFieldTab) {
      return activeNotification.missingFieldTab.missingFieldErrors;
    } else if (activeNotification && activeNotification.isErrorType() && activeNotification.errorTab) {
      return activeNotification.errorTab.saveErrors.concat(activeNotification.errorTab.fieldErrors);
    } else if (activeNotification && activeNotification.isThirdPartyNotificationType() && activeNotification.dpThirdPartyNotificationTab) {
      return activeNotification.dpThirdPartyNotificationTab.notifications;
    } else {
      return [];
    }

  }

  totalNotificationCount(): number {
    let total: number = 0;
    this.dpFooterNotifications.forEach(item => {
      total = total + Number(new DpFooterNotification(item).notificationCount());
    });
    return total;
  }

  openNotification(): void {
    this.closeFooterNotification();
    if (this.dpFooterNotifications && this.dpFooterNotifications.length > 0) {
      let dpFooterNotification = this.dpFooterNotifications.find(item => (new DpFooterNotification(item)).notificationCount() > 0);
      if (dpFooterNotification) {
        dpFooterNotification.isActive = true;
      }
    }
  }

  setMissingFieldHeader(header: string): void {
    let dpFooterNotification = this.dpFooterNotifications.find(item => item.dpFooterType == this.missingFieldType);
    if (dpFooterNotification) {
      dpFooterNotification.notificationHeader = header;
    }

  }

  focusElement(): void {
    let currentComponent = this;
    let dpError: DPError = this.focusedErrorElement;
    if (dpError && dpError.fieldCode) {
      let focusedElement = jQuery('dppm-app').find('[fieldCode=\'' + dpError.fieldCode + '\']');
      //  if your field is within dp-accordion common component like postal code in address
      let dpAccordion = jQuery(focusedElement).parents('dp-accordion');

      // if your field is within custom accordion like  last name in offeror
      let dpCustomAccordion = jQuery(focusedElement).parents('.field_code_accordion_container');

      // if your field is within custom tabs like guarantor in  mortgage / term / report
      let dpFieldCodeTab = jQuery(focusedElement).parents('[field-code-tab-id]');

      // if  your field is within custom tabs then below code will help open custom tabs.
      if (dpFieldCodeTab && dpFieldCodeTab.length > 0) {
        let tabId = jQuery(dpFieldCodeTab).attr('field-code-tab-id');
        jQuery('.field-code-tabs').find('[field-code-tab-link=\'' + tabId + '\']').click();
      }

      if (dpAccordion && dpAccordion.length > 0) {
        // if  your field is within custom tabs then below code will open accordion.
        this.openAccordion(dpAccordion, '.accordion-wrapper .form-outline', focusedElement, dpError);

      } else if (dpCustomAccordion && dpCustomAccordion.length > 0) {
        this.openAccordion(dpCustomAccordion, '.field_code_accordion_input', focusedElement, dpError);
      } else {
        if (focusedElement && focusedElement.length > 0) {
          setTimeout(function () {
            if (focusedElement && focusedElement.length > 0) {
              currentComponent.setFocusOnErrorElement(dpError);
            }
          }, 200);

        }
      }
    }
  }

  invokeF9FunctionOnErrorFocus(): void {
    let currentComponent = this;
    setTimeout(function () {
      let dpError: DPError = currentComponent.focusedErrorElement;
      let element = jQuery('dppm-app').find('[fieldCode=\'' + dpError.fieldCode + '\']');
      let nextElementSibling = element[ 0 ].nextElementSibling;
      if (nextElementSibling && jQuery(nextElementSibling).hasClass('button-glyph')) {
        jQuery(nextElementSibling).click();
      }
      currentComponent.resetfocusErrorElement();
    }, 0);

  }

  setFocusOnErrorElement(dpError: DPError): void {
    let focusedElement = jQuery('dppm-app').find('[fieldCode=\'' + dpError.fieldCode + '\']');
    this.bringFieldIntoVisibleArea(focusedElement);
    focusedElement.focus();
    this.invokeF9FunctionOnErrorFocus();

  }

  openAccordion(dpAccordion: any, dpAccordionClass: any, focusedElement: any, dpError: DPError): void {
    let currentComponent = this;
    let wrapper = jQuery(dpAccordion).find(dpAccordionClass);
    if (wrapper) {
      let isShutterClosed = jQuery(dpAccordion).find('[class*=\'shutter-bg-closed\']');
      if (isShutterClosed && isShutterClosed.length > 0) {

        jQuery(wrapper).click();
        setTimeout(function () {
          if (focusedElement && focusedElement.length > 0) {
            currentComponent.setFocusOnErrorElement(dpError);
          }
        }, 200);
      } else {
        if (focusedElement && focusedElement.length > 0) {
          currentComponent.setFocusOnErrorElement(dpError);
        }
      }
    }
  }

  bringFieldIntoVisibleArea(target: any) {

    let errorBoxHeight = jQuery('.inspector-footer .error-box-open').height();

    var pos = jQuery(target).offset(),
      wX = jQuery(window).scrollLeft(), wY = jQuery(window).scrollTop(),
      wH = (jQuery(window).height() - 30), wW = jQuery(window).width(),
      oH = jQuery(target).outerHeight(), oW = jQuery(target).outerWidth();

    if (errorBoxHeight != null) {
      wH = wH - errorBoxHeight;
    }
    // check the edges
    if (wY + wH < pos.top) {
      let scroll = pos.top - (wY + wH);
      var y = jQuery(window).scrollTop();  //your current y position on the page
      jQuery(window).scrollTop(y + scroll + 150);
    } else if (wY > 0) {
      var y = jQuery(window).scrollTop();  //your current y position on the page
      jQuery(window).scrollTop(0);
    }
  }

  public getErrorMessageForSaveMatter(fieldError: FieldError): string {
    if (!fieldError) {
      return '';
    }
    let errorMessage: string;
    const currentTime: string = moment().format('MMMM Do YYYY, h:mm:ss a');
    if (fieldError.errorCode == 'System.error') {
      errorMessage = 'Unexpected System Error encountered, please contact Customer Service.'
        + '<br><span class=\'grey-text\'>' + currentTime + ' ' + fieldError.errorCode + ': ' + fieldError.message + '</span>';
    } else {
      errorMessage = fieldError.errorCode + ' error for ' + fieldError.field + ': ' + fieldError.message;
    }
    return errorMessage;
  }

  get activeMatter(): Matter {
    let activeTab = this.tabService.activeTab;
    if (activeTab && (activeTab.isMatter() || activeTab.isOpportunityMatter())) {
      return (activeTab as MatterTab).matter;
    } else if (activeTab && activeTab.isProject()) {
      return (activeTab as ProjectTab).matter;
    } else if (activeTab && activeTab.isWizard()) {
      return (activeTab as WizardTab).matter;
    } else {
      return null;
    }
  }

  showErrorNotificationMessageBubble(error: DPError): void {
    if (!this.isAnyNotificationActive() && this._errorNotificationTemplate && error) {
      const notificationId = UUID.UUID().toString();
      error.bubbleNotificationId = notificationId;
      let errorMessage: string = this.getErrorMessageWithLatestIndex(error);
      errorMessage = this.createMatterTypeAwareMessage(this.activeMatter, errorMessage, error);
      if (this.activeMatter && this.activeMatter.isOpportunityMatter()) {
        if (ErrorService.OPPORTUNITY_PLACEHOLDERS.some(placeHolder => errorMessage && errorMessage.indexOf(placeHolder) > -1)) {
          errorMessage = this.substituteOpportunityText(error.errorElementKey, error.errorMessage);
        }
      } else {
        if (ErrorService.PROVINCE_BASED_PLACEHOLDERS.some(placeHolder => errorMessage && errorMessage.indexOf(placeHolder) > -1)) {
          let provinceCode = this.provinceCode ? this.provinceCode : this.userStateService.defaultProvinceCode;
          errorMessage = this.substituteProvinceText(error.errorElementKey, error.errorMessage, provinceCode, this.matterType);
        }
      }
      if (error.showBubbleNotification) {
        this.notificationBubbleCache.push(error.errorElementKey);
        this.notifierService.show({
          id: notificationId,
          message: errorMessage,
          type: error.errorType,
          template: this._errorNotificationTemplate
        });
      }
    }
  }

  closeNotificationBubble(notificationId: string) {
    this.notifierService.hide(notificationId);
  }

  closeAllNotificationBubles() {
    this.notifierService.hideAll();
  }

  openErrorFooterNotification(): void {
    if (this.hasErrors()) {
      this.notifierService.hideAll();
      this.openFooterNotification('ERROR');
    }
  }

  get copyOfExistingErrors(): DPError[] {
    return this.errorTab && this.errorTab.fieldErrors && this.errorTab.fieldErrors.slice();
  }

}
