import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {SalePriceAdjustment} from './sale-price-adjustment';
import {SalePriceAdjustmentAlbertaStrategy} from './sale-price-adjustment-alberta-strategy';
import {SalePriceAdjustmentOntarioStrategy} from './sale-price-adjustment-ontario-strategy';
import {SalePriceAdjustmentBase} from './sale-price-adjustment-base';
import {SalePriceAdjustmentManitobaStrategy} from './sale-price-adjustment-manitoba-strategy';
import {SalePriceAdjustmentSaskatchewanStrategy} from './sale-price-adjustment-saskatchewan-strategy';
import {ItemizedCreditToVendorPurchaserONStrategy} from './itemized-credit-to-vendor-purchaser-on-strategy';
import {SalePriceAdjustmentNewBrunswickStrategy} from './sale-price-adjustment-newBrunswick-strategy';
import {SalePriceAdjustmentNovaScotiaStrategy} from './sale-price-adjustment-novaScotia-strategy';
import {ItemizedCreditToVendorPurchaserABStrategy} from './itemized-credit-to-vendor-purchaser-ab-strategy';

export class SalePriceAdjustmentFactory {

  public static getSalePriceAdjustment(adjustmentMode: string, provinceCode: ProvinceCode, salePriceAdjustment?: SalePriceAdjustment): SalePriceAdjustment {

    if (!provinceCode) {
      provinceCode = 'ON';
    }

    if (provinceCode === 'ON') {
      return new SalePriceAdjustmentOntarioStrategy(adjustmentMode, salePriceAdjustment as SalePriceAdjustmentOntarioStrategy);
    }
    if (provinceCode === 'AB') {
      return new SalePriceAdjustmentAlbertaStrategy(adjustmentMode, salePriceAdjustment);
    }
    if (provinceCode === 'MB') {
      return new SalePriceAdjustmentManitobaStrategy(adjustmentMode, salePriceAdjustment);
    }
    if (provinceCode === 'SK') {
      return new SalePriceAdjustmentSaskatchewanStrategy(adjustmentMode, salePriceAdjustment);
    }
    if (provinceCode === 'NB') {
      return new SalePriceAdjustmentNewBrunswickStrategy(adjustmentMode, salePriceAdjustment);
    }
    if (provinceCode === 'NS') {
      return new SalePriceAdjustmentNovaScotiaStrategy(adjustmentMode, salePriceAdjustment);
    }
    return new SalePriceAdjustmentBase(adjustmentMode, salePriceAdjustment);
  }

  // salePriceAdjustment - Matter's sale price adjustment
  public static getItemizedCreditToVendorPurchaserAdjustment(adjustmentMode: string, provinceCode: ProvinceCode, itemizedCreditToVendorPurchaserAdjustment?: SalePriceAdjustment): ItemizedCreditToVendorPurchaserONStrategy | ItemizedCreditToVendorPurchaserABStrategy {
    if (!provinceCode) {
      provinceCode = 'ON';
    }

    switch (provinceCode) {
      case 'ON':
        return new ItemizedCreditToVendorPurchaserONStrategy(adjustmentMode, itemizedCreditToVendorPurchaserAdjustment);
      case 'AB':
        return new ItemizedCreditToVendorPurchaserABStrategy(adjustmentMode, itemizedCreditToVendorPurchaserAdjustment);
      default:
        return new ItemizedCreditToVendorPurchaserONStrategy(adjustmentMode, itemizedCreditToVendorPurchaserAdjustment);
    }
  }
}
