import {Component, Inject, OnInit} from '@angular/core';
import {Address, Contact} from '../../shared';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export const LegacyModalOpenFrom = {
  signingOfficer: 'signingOfficer'
};

export class LegacyContactModalContext {
  suggestedContacts: any[];
  isOpportunity: boolean;
  isFromContactManagement: boolean;
  openFrom: string;
  snapshotContact: Contact;
}

@Component({
  selector: 'dp-legacy-contact-modal',
  templateUrl: 'legacy-contact-modal.component.html',
  styleUrls: ['./legacy-contact-modal.component.scss']
})
export class LegacyContactModalComponent extends ModalComponent<LegacyContactModalContext> implements OnInit {
  selectedContactId: number = -1;
  topicNotes: string;

  constructor(public dialog: MatDialogRef<LegacyContactModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: LegacyContactModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (
      this.context.suggestedContacts &&
      this.context.suggestedContacts.length == 1 &&
      !this.isOpenFromSigningOfficer()
    ) {
      this.selectedContactId = this.context.suggestedContacts[0].id;
      this.topicNotes = '';
      if (this.context.isOpportunity) {
        this.compareAddress(this.context.snapshotContact, this.context.suggestedContacts[0]);
      }
    }
  }

  createContact(): void {
    this.dialog.close({action: 'new'});
  }

  cancel(): void {
    this.dialog.close();
  }

  addContact(): void {
    if (this.isContactSelected()) {
      let contact = this.context.suggestedContacts.find((item) => item.id == this.selectedContactId);
      this.dialog.close({action: 'update', contact: contact, topicNotes: this.topicNotes});
    }
  }

  ngAfterViewInit() {
    console.log();
  }

  public getGenderValue(v) {
    switch (v) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      case 'QUESTION':
        return '';
      default:
        return v;
    }
  }

  get openingFrom(): string {
    return this.context.isFromContactManagement ? 'contact' : this.context.isOpportunity ? 'opportunity' : 'matter';
  }

  isContactSelected(): boolean {
    return this.selectedContactId != -1;
  }

  onContactSelected(suggestedContact: any): void {
    this.selectedContactId = suggestedContact.id;
    this.topicNotes = '';
    if (this.context.isOpportunity && !this.isOpenFromSigningOfficer()) {
      this.compareAddress(this.context.snapshotContact, suggestedContact);
    }
  }

  /*
   * If I am converting an Other Side on an Opportunity the snapshot may have an address (on a matter the snapshot does not have an address).
   * If the address is populated, and it is different than the Mailing Address (if populated) on the matched contact:
   * Before replacing the snapshot with the selected contact, the address on the snapshot is inserted in the Other Side Sticky Note
   * @param snapshotContact: Contact
   * @param suggestedContact (search suggested contact result )
   * */
  compareAddress(snapshotContact: Contact, suggestedContact: any): void {
    if (
      this.context.isOpportunity &&
      snapshotContact &&
      snapshotContact.mailingAddress &&
      snapshotContact.mailingAddress.addressLine1 &&
      Address.convertAddressToDisplayAddress(snapshotContact.mailingAddress) != suggestedContact.displayAddress
    ) {
      let contactName = snapshotContact.isCorporationOrOtherEntity
        ? snapshotContact.fullName
        : snapshotContact.surnameLastFullName;
      this.topicNotes += `\nAddress from converted snapshot for ${contactName}: ${snapshotContact.mailingAddress.addressTextWithProvinceName}`;
    }
  }

  getSnapShotName(): string {
    if (this.context.snapshotContact) {
      if (this.isOpenFromSigningOfficer()) {
        return this.context.snapshotContact.lastName;
      } else {
        if (this.context.snapshotContact.isCorporationOrOtherEntity) {
          return this.context.snapshotContact.fullName;
        } else {
          return (
            (this.context.snapshotContact.firstName ? this.context.snapshotContact.firstName + ', ' : '') +
            this.context.snapshotContact.lastName
          );
        }
      }
    } else {
      return '';
    }
  }

  isOpenFromSigningOfficer(): boolean {
    return this.context.openFrom == LegacyModalOpenFrom.signingOfficer;
  }
}
