import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {InsurerService} from './insurer.service';
import {messages} from '../../../common';
import {Insurer} from './insurer';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {Telephone} from '../../shared/telephone';
import {PhoneTypeCode} from '../../shared/telephone-types';
import {Matter} from '../../shared/matter';
import {Utils} from '../../shared/utils';
import {Subscription} from 'rxjs/index';
import {MatterComponent} from '../../matter.component';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {AuthZService} from '../../../core/authz/auth-z.service';
import {LockScreenService} from '../../../core/lock-screen.service';
import {ChicagoTitleService} from '../../../shared-main/chicago-title/chicago-title-service';
import {ChicagoError} from '../../../shared-main/chicago-title/chicago-error';
import {ApplicationError} from '../../../core/application-error';
import {FCT_ORDER_STATUS} from '../../shared/matter-title-insurance';
import {MatterTab} from '../../matter-tab';
import {TabsService} from '../../../core/tabs.service';
import {MatterService} from '../../matter.service';
import {ChicagoBaseResponse} from '../../../shared-main/chicago-title/chicago-base-response';
import {FocusFirstElementModalDecorator} from '../../../shared-main/focus-first-element-modal-decorator';
import {ChicagoTitleCancellationModalComponent} from '../../title-insurance/chicago-title-portal/chicago-title-cancellation.modal.component';
import {ChicagoCancelRequest} from '../../../shared-main/chicago-title/chicago-cancel-request';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {Permissions} from "../../../core/authz/permissions";

declare var $: any;
declare var jQuery: any;

export type InsurerType = 'STG' | 'FCT' | 'CTI' | 'TP';

export const insurerTypeValues = [
  {insurerType: 'FCT', value: 'FCT'},
  {insurerType: 'STG', value: 'Stewart Title'},
  {insurerType: 'CTI', value: 'Chicago Title'},
  {insurerType: 'TP', value: 'TitlePLUS'}
];

class InsurerModalComponentContext {
  selectedInsurerIndex: number;
  isPropertyCommercial: boolean;
  isPropertyFarmLand: boolean;
  matter: Matter;
  matterComponent: MatterComponent;
}

@FocusFirstElementModalDecorator()
@Component({
  selector: 'dp-insurer',
  templateUrl: 'insurer-modal.component.html',
  providers: [AuthZService, InsurerService, ChicagoTitleService]
})
@AutoUnsubscribe()
export class InsurerModalComponent extends ModalComponent<InsurerModalComponentContext> implements OnInit {
  @Output() onInsurer = new EventEmitter();

  radioSelected: boolean;
  insurerDialog: boolean;
  insurer: Insurer;
  insurers: Insurer[];
  shutterFlag: boolean[];
  insurerValues: any[];
  selectedInsurerIndex: number;
  selectedInsurerValue: string;
  header: string;
  saveSubscription: Subscription;
  removeMainTitleInsurer: boolean = false;
  clearTitleInsurance: boolean = false;

  insurersOrganizationNames = {
    STG: 'Stewart Title Guaranty Company',
    FCT: 'FCT',
    CTI: 'Chicago Title Insurance Company',
    TP: 'TitlePLUS'
  };

  constructor(
    public insurerService: InsurerService,
    public dialog: MatDialogRef<InsurerModalComponent>,
    public dialogService: DialogService,
    public lockScreenService: LockScreenService,
    public tabService: TabsService,
    public matterService: MatterService,
    public chicagoTitleService: ChicagoTitleService,
    @Inject(MAT_DIALOG_DATA) context?: InsurerModalComponentContext
  ) {
    super(dialog, context);
    if (this.context.selectedInsurerIndex > -1) {
      this.setSelectedInsurerIndex(this.context.selectedInsurerIndex);
    }
  }

  ngOnInit(): void {
    this.insurerDialog = false;
    this.header = 'Title Insurance';
    if (this.isPropertyCommercial() || (this.isPropertyFarmLand() && this.matter.provinceCode == 'AB')) {
      this.insurerValues = messages.insurerValues.slice(0).map((item) => {
        if (item.class == 'othertitle') {
          item.label = 'Other Title Insurer (Commercial)';
        }
        return item;
      });
    } else {
      this.insurerValues = messages.insurerValues.slice(0).map((item) => {
        if (item.class == 'othertitle') {
          item.label = 'Other Title Insurer';
        }
        return item;
      });
    }

    this.shutterFlag = [false, false, false];
    this.insurer = new Insurer();
    this.insurers = [];

    this.insurerService.getInsurer().subscribe(
      (data) => {
        //Add the insurers to the list in the order specified in the insurerValues message (we use that list for the desired sorting)
        messages.insurerValues.forEach((entry) => {
          let insurer = data['Organizations'].find((insurer) => insurer.organizationName == entry.organizationName);
          if (insurer) {
            this.insurers.push(insurer);
          }
        });
        setTimeout(() => {
          jQuery('label.focus-first').focus();
        }, 10);
      },

      (err) => console.log('Error', err),
      () => {}
    );
  }

  ngAfterViewInit(): void {
    this.setSelectedInsurerIndex(this.selectedInsurerIndex);
  }

  get matter(): Matter {
    return this.context.matter;
  }

  getOperationName(organizationName: string) {
    let operationName: any;
    switch (organizationName) {
      case this.insurersOrganizationNames.STG:
        operationName = Permissions.STEWART_NEXT_STEPS_INTEGRATIONS;
        break;
      case this.insurersOrganizationNames.CTI:
        operationName = Permissions.CHICAGO_TITLE_INTEGRATIONS;
        break;
      case this.insurersOrganizationNames.FCT:
        operationName = Permissions.FCT_INTEGRATIONS;
        break;
      case this.insurersOrganizationNames.TP:
        operationName = Permissions.TITLE_PLUS_INTEGRATIONS;
        break;
    }
    return operationName;
  }

  toggleShutterState(index: number): void {
    this.shutterFlag[index] = !this.shutterFlag[index];
  }

  closeModal(): void {
    this.insurerDialog = false;
    if (
      this.selectedInsurerIndex != null &&
      this.selectedInsurerIndex >= 0 &&
      this.selectedInsurerIndex < this.insurerValues.length
    ) {
      this.selectedInsurerValue = this.insurerValues[this.selectedInsurerIndex].value;
      this.dialog.close({
        selectedInsurerIndex: this.selectedInsurerIndex,
        selectedInsurerValue: this.selectedInsurerValue,
        insurers: this.insurers,
        removeMainTitleInsurer: this.removeMainTitleInsurer,
        clearTitleInsurance: this.clearTitleInsurance
      });
    }
  }

  getPhoneNumbersBasedOnType(phones: Telephone[], type: PhoneTypeCode): string {
    return Utils.getPhoneNumbersBasedOnTypeOrder(phones, type, ' or ');
  }

  isPropertyCommercial(): boolean {
    return this.context.isPropertyCommercial;
  }

  isPropertyFarmLand(): boolean {
    return this.context.isPropertyFarmLand;
  }

  updateInsurer(index: number): void {
    let currentIndex: number = this.selectedInsurerIndex;
    let insurer = new Insurer(this.insurers[index]);
    const maxInsurers = this.insurers && this.insurers.length;
    if (insurer && !this.isInsurerDisabled(insurer.organizationName)) {
      if (
        (this.isPropertyCommercial() || (this.isPropertyFarmLand() && this.matter.provinceCode == 'AB')) &&
        index < maxInsurers
      ) {
        let message: string = '';
        this.setSelectedInsurerIndex(-3);
        if (this.insurers) {
          if (insurer) {
            if (insurer.organizationName == 'FCT') {
              message =
                '<br><br>Contact Us<br><br>' +
                'Your Commercial Solutions Team is here to help <br>Monday to Friday 8:00 am ET – 8:00 pm ET.' +
                '<br><br> Phone: 905.287.3112 / 1.866.804.3112' +
                '<br><br>Email: <a href="mailto:commercialsolutions@fct.ca" target="_blank">commercialsolutions@fct.ca</a>' +
                '<br><br><a href="http://www.FCT.ca" target="_blank">FCT.ca</a>';
            } else if (insurer.organizationName == this.insurersOrganizationNames.TP) {
              message = new Insurer(insurer).insurerTitlePlusHtmlText;
            } else {
              message =
                '<br><br> You can contact ' +
                insurer.organizationName +
                '<br><br>Contact Info<br>' +
                insurer.insurerContactHtmlText;
            }
          }
        }
        setTimeout(() => {
          this.setSelectedInsurerIndex(currentIndex);
        }, 0);

        let confirmMsg: string =
          insurer.organizationName == this.insurersOrganizationNames.TP
            ? message
            : 'Commercial title insurance order submission is not currently supported in Unity.  If you wish to proceed' +
              ' with title insuring a commercial property then please select the “Other Title Insurer” option under the Matter' +
              ' Opening topic. ' +
              message;
        this.dialogService.confirm('Warning', confirmMsg, true, null, null, true).subscribe((res) => {
          if (res) {
            //this.insurers = this.insurers.slice(0);
          }
        });
        // check if user is switching from Stewart to another option (and Stewart was already selected when this modal was opened).
      } else if (
        currentIndex >= 0 &&
        this.insurerValues[currentIndex].value == this.insurersOrganizationNames.STG &&
        this.context.selectedInsurerIndex >= 0 &&
        this.insurerValues[this.context.selectedInsurerIndex].value == this.insurersOrganizationNames.STG
      ) {
        if (this.isTitleInsuranceOrdered() && !this.isTitleInsuranceCancelled()) {
          this.displayExistingTitleInsuranceWarning();
          this.undoRadioSelection(index);
        } else {
          if (this.matter.dirty) {
            this.handleUnsavedChanges(index, 'STG');
          } else {
            this.removeStewartConfirmation(index);
          }
        }
      } else if (this.isFctSelectedInsurer) {
        if (this.allowChangeFromFctToOtherTI()) {
          if (this.matter.dirty) {
            this.handleUnsavedChanges(index, 'FCT');
          } else {
            this.confirmRemoveFCTFromMatter(index);
          }
        } else {
          this.showExistingTitleInsuranceNeedToBeCancelledWarning('FCT');
          this.undoRadioSelection(index);
        }
        // check if user is switching from Chicago to another option (and Chicago was already selected when this modal was opened).
      } else if (this.isTitlePLUSSelectedInsurer) {
        let dealId = this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.dealId;
        if (this.isTitlePlusInsuranceOrdered() && !this.isTitlePlusInsuranceCancelled() && dealId) {
          this.showExistingTitleInsuranceNeedToBeCancelledWarning('TP');
          this.undoRadioSelection(index);
        } else {
          if (this.matter.dirty) {
            this.handleUnsavedChanges(index, 'TP');
          } else {
            this.removeTitlePlusConfirmation(index);
          }
        }
        // check if user is switching from Chicago to another option (and Chicago was already selected when this modal was opened).
      } else if (
        currentIndex >= 0 &&
        this.insurerValues[currentIndex].value == this.insurersOrganizationNames.CTI &&
        this.context.selectedInsurerIndex >= 0 &&
        this.insurerValues[this.context.selectedInsurerIndex].value == this.insurersOrganizationNames.CTI
      ) {
        if (this.isTitleInsuranceOrdered()) {
          this.displayExistingTitleInsuranceWarning();
          this.undoRadioSelection(index);
        } else {
          if (this.matter.dirty) {
            this.handleUnsavedChanges(index, 'CTI');
          } else {
            this.removeChicagoConfirmation(index);
          }
        }
        //Check if user is switching from Other Title Insurance to another option (and Other Title Insurance was already selected when modal was opened)
      } else if (
        currentIndex >= 0 &&
        this.insurerValues[currentIndex] &&
        this.insurerValues[currentIndex].class == 'othertitle' &&
        this.context.selectedInsurerIndex != -1 &&
        this.insurerValues[this.context.selectedInsurerIndex].class == 'othertitle'
      ) {
        //set flag to true because user has switched from Other Title Insurance to another insurer
        this.clearTitleInsurance = true;
        this.setSelectedInsurerIndex(index);
      } else if (
        this.clearTitleInsurance &&
        index == this.insurerValues.findIndex((insurer) => insurer.class == 'othertitle')
      ) {
        //set flag to false because user has switched back to Other Title Insurance
        this.clearTitleInsurance = false;
        this.setSelectedInsurerIndex(index);
      } else {
        this.setSelectedInsurerIndex(index);
      }
    }
  }

  undoRadioSelection(index: number): void {
    //revert to previously selected radio button
    const previousRadioButton: HTMLInputElement = <HTMLInputElement>(
      document.getElementById('insurer-' + this.selectedInsurerIndex)
    );
    const clickedRadioButton: HTMLInputElement = <HTMLInputElement>document.getElementById('insurer-' + index);
    previousRadioButton.checked = true;
    clickedRadioButton.checked = false;
  }

  cancel(): void {
    this.insurerDialog = false;
    this.dialog.close();
  }

  findInsurer(name: string): Insurer {
    return this.insurers.find((ins: Insurer) => ins.organizationName == name);
  }

  hasSelectedRadio(): boolean {
    return this.radioSelected;
  }

  setSelectedInsurerIndex(index: number) {
    this.selectedInsurerIndex = index;
    this.radioSelected =
      this.selectedInsurerIndex > -1 && this.insurerValues && this.selectedInsurerIndex < this.insurerValues.length;
  }

  /*    isDisabled(index : number): boolean {
          return this.insurers[index].organizationName == this.insurersOrganizationNames.FCT ? true : null;
               //this.insurers[index].organizationName == this.insurersOrganizationNames.CTI ? true : null;
      }*/

  public handleUnsavedChanges(index: number, insurerType: InsurerType): void {
    //prompt user to save matter if there are any unsaved changes before asking to remove Stewart Title insurer
    this.dialogService.confirmUnsavedChange(true).subscribe((response: any) => {
      if (response == 'DONT_SAVE' || response == 'CANCEL') {
        this.undoRadioSelection(index);
      } else if (response == 'SAVE') {
        if (this.context.matterComponent) {
          if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
          }
          this.saveSubscription = this.context.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
            if (result) {
              //Once matter is saved then remove Title insurance.
              if (insurerType === 'STG') {
                this.removeStewartConfirmation(index);
              } else if (insurerType === 'CTI') {
                this.removeChicagoConfirmation(index);
              } else if (insurerType === 'FCT') {
                this.confirmRemoveFCTFromMatter(index);
              } else if (insurerType === 'TP') {
                this.removeTitlePlusConfirmation(index);
              }
              this.saveSubscription.unsubscribe();
            } else {
              this.undoRadioSelection(index);
            }
          });
        }
      }
    });
  }

  public removeTitlePlusConfirmation(index: number): void {
    this.dialogService
      .confirm(
        'Confirmation',
        'Do you wish to remove TitlePLUS as the title insurer for this matter?',
        false,
        'Yes',
        'No'
      )
      .subscribe((res) => {
        if (res) {
          this.setSelectedInsurerIndex(index);
          this.removeMainTitleInsurer = true;
          this.closeModal();
        } else {
          this.undoRadioSelection(index);
        }
      });
  }

  public removeStewartConfirmation(index: number): void {
    this.dialogService
      .confirm(
        'Confirmation',
        'Do you wish to remove Stewart Title as the title insurer for this matter?',
        false,
        'Yes',
        'No'
      )
      .subscribe((res) => {
        if (res) {
          this.setSelectedInsurerIndex(index);
          this.removeMainTitleInsurer = true;
          this.closeModal();
        } else {
          this.undoRadioSelection(index);
        }
      });
  }

  public removeChicagoConfirmation(index: number): void {
    //cancellation modal only required if deal ID is present
    if (
      this.matter.matterTitleInsurance &&
      this.matter.matterTitleInsurance.dealId &&
      !this.matter.matterTitleInsurance.isChicagoCancelled()
    ) {
      this.dialogService.matDialogContent({
        modalGrid: 6,
        content: ChicagoTitleCancellationModalComponent,
        context: {},
        onFulfillment: (result: any) => {
          if (result && result.chicagoCancelRequest) {
            this.removeChicagoTitleInsurance(result.chicagoCancelRequest, index);
          } else {
            this.undoRadioSelection(index);
          }
        }
      });
    } else {
      this.dialogService
        .confirm(
          'Confirmation',
          'Do you wish to remove Chicago Title as the title insurer for this matter?',
          false,
          'Yes',
          'No'
        )
        .subscribe((res) => {
          if (res) {
            this.setSelectedInsurerIndex(index);
            this.removeMainTitleInsurer = true;
            this.closeModal();
          } else {
            this.undoRadioSelection(index);
          }
        });
    }
  }

  removeChicagoTitleInsurance(chicagoCancelRequest: ChicagoCancelRequest, index: number): void {
    if (this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.dealId) {
      this.lockScreenService.lockForUpdate = true;
      this.chicagoTitleService
        .cancelTitleInsurance(this.matter.id, this.matter.matterTitleInsurance.dealId, chicagoCancelRequest)
        .finally(() => (this.lockScreenService.lockForUpdate = false))
        .subscribe(
          (response: ChicagoBaseResponse) => {
            if (response) {
              if (response.isSuccessful) {
                this.setSelectedInsurerIndex(index);
                this.removeMainTitleInsurer = true;
                this.closeModal();
              } else {
                this.displayErrors(response.responseMessage, response.error);
                this.undoRadioSelection(index);
              }
            } else {
              console.log('Error calling Chicago Title.');
            }
          },
          (error: ApplicationError) => {
            console.log(error);
          }
        );
    } else {
      this.setSelectedInsurerIndex(index);
      this.removeMainTitleInsurer = true;
      this.closeModal();
    }
  }

  //clear the FCT TI data from the matter without interacting with FCT
  public confirmRemoveFCTFromMatter(index: number): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to remove FCT as the title insurer for this matter?', false, 'Yes', 'No')
      .subscribe((res) => {
        if (res) {
          this.setSelectedInsurerIndex(index);
          this.removeMainTitleInsurer = true;
          this.closeModal();
        } else {
          this.undoRadioSelection(index);
        }
      });
  }

  public removeMatterDocumentForRemovedTitleInsurance(removedInsurerType: InsurerType): void {
    if (removedInsurerType) {
      //TODO in the future story after download the documents from TitleInsurance
      //need to delete all the documents related to currentMatter and (deSelected) TitleInsurance
    }
  }

  public saveMatter(): void {
    let matterTab = this.tabService.activeTab as MatterTab;
    if (matterTab && matterTab.matterComponent) {
      if (this.saveSubscription) {
        this.saveSubscription.unsubscribe();
      }

      this.saveSubscription = matterTab.matterComponent.validateAndSaveMatter().subscribe();
    }
  }

  isTitleInsuranceOrdered(): boolean {
    return this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.isTitleInsuranceOrdered();
  }

  isTitlePlusInsuranceOrdered(): boolean {
    return (
      this.matter.matterTitleInsurance &&
      this.matter.matterTitleInsurance.invoiceOrderStatus != 'Not Submitted' &&
      this.matter.matterTitleInsurance.invoiceOrderStatus != 'unordered'
    );
  }

  isTitlePlusInsuranceCancelled(): boolean {
    return (
      (this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.invoiceOrderStatus == 'Cancelled') ||
      this.matter.matterTitleInsurance.invoiceOrderStatus == 'Deleted'
    );
  }

  isTitleInsuranceCancelled(): boolean {
    return this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.isOrderStatusCancelled();
  }

  get isFctSelectedInsurer(): boolean {
    let currentIndex: number = this.selectedInsurerIndex;
    return (
      currentIndex >= 0 &&
      this.insurerValues[currentIndex].value == this.insurersOrganizationNames.FCT &&
      this.context.selectedInsurerIndex >= 0 &&
      this.insurerValues[this.context.selectedInsurerIndex].value == this.insurersOrganizationNames.FCT
    );
  }

  get isTitlePLUSSelectedInsurer(): boolean {
    let currentIndex: number = this.selectedInsurerIndex;
    return (
      currentIndex >= 0 &&
      this.insurerValues[currentIndex].value == this.insurersOrganizationNames.TP &&
      this.context.selectedInsurerIndex >= 0 &&
      this.insurerValues[this.context.selectedInsurerIndex].value == this.insurersOrganizationNames.TP
    );
  }

  allowChangeFromFctToOtherTI(): boolean {
    if (
      this.matter.matterTitleInsurance &&
      this.matter.matterTitleInsurance &&
      this.matter.matterTitleInsurance.invoiceOrderStatus
    ) {
      let allowedFctOrderStatusList: FCT_ORDER_STATUS[] = ['Draft', 'Cancelled', 'Pending Cancellation'];
      return allowedFctOrderStatusList.some(
        (status) => status.toLowerCase() == this.matter.matterTitleInsurance.invoiceOrderStatus.toLowerCase()
      );
    }
    return true;
  }

  displayExistingTitleInsuranceWarning(): void {
    this.dialogService.confirm(
      'Error',
      'In order to change the title insurance provider, the existing order with Stewart Title (Deal ID: ' +
        this.matter.matterTitleInsurance.dealId +
        ') must be canceled. To cancel, select the Title Insurance tab and visit the Stewart Title portal.',
      true
    );
  }

  //parameter can be one of  'STG' | 'FCT' | 'CTI'
  showExistingTitleInsuranceNeedToBeCancelledWarning(insurerType: InsurerType): void {
    if (insurerType) {
      let insurerTypeValue = insurerTypeValues.find(
        (item) => item.insurerType.toLowerCase() == insurerType.toLowerCase()
      );
      let dealId = this.matter.matterTitleInsurance && this.matter.matterTitleInsurance.dealId;
      let errMsg = `In order to change the title insurance provider, the existing order with ${insurerTypeValue.value} (Deal ID: ${dealId}) must be canceled. To cancel, select the Title Insurance tab and visit the ${insurerTypeValue.value} portal.`;
      this.dialogService.confirm('Error', errMsg, true);
    }
  }

  displayErrors(responseMessage: String, errors: ChicagoError[]): void {
    let formattedError: string = responseMessage + ': ';
    if (errors.length > 0) {
      if (
        errors.some((error: ChicagoError) => error.errorCode == 10 || error.errorCode == 11 || error.errorCode == 12)
      ) {
        formattedError = 'Authentication failed. Please try again or contact Chicago Title.';
      } else {
        errors.forEach((error: ChicagoError) => {
          formattedError += error.errorMessage + '(' + error.errorCode + ')';
          formattedError += '\n';
        });
      }
      this.dialogService.confirm('Confirmation', formattedError, true);
    } else {
      this.dialogService.confirm('Confirmation', 'Unknown error. No message returned from Chicago Title.', true);
    }
  }

  isInsurerDisabled(organizationName: string): boolean {
    if (organizationName != null) {
      let insurer = messages.insurerValues.find((value) => value.organizationName === organizationName);
      if (insurer && insurer.applicableProvinces.indexOf(this.matter.provinceCode) == -1) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {}
}
