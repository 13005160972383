import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class ContactFieldsDiff extends BaseEntity {

  constructor(ContactFieldsDiff?: ContactFieldsDiff) {
    super(ContactFieldsDiff);
  }

  fieldName: string;
  updatedValue: string;
  globalValue: string;

  static createContactFieldsDiff(fieldName: string, updatedValue: any, globalValue: any): ContactFieldsDiff {
    const fieldsDiff: ContactFieldsDiff = new ContactFieldsDiff();
    fieldsDiff.fieldName = fieldName + '';

    fieldsDiff.updatedValue = updatedValue ? updatedValue + '' : '';
    fieldsDiff.globalValue = globalValue ? globalValue + '' : '';
    return fieldsDiff;
  }
}
