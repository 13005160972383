<!-- modal -->
<div class="container-fluid custom-modal-container" *ngIf="statementOfAdjustmentHeading" id="dp-statement-adjustment-heading">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>{{statementAdjustmentHeadingDialogTitle()}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class=" modal-body form-horizontal">
        <div class="col-lg-12">
            <form class="form-horizontal">
                <div class="form-group">
                    <div class="col-lg-12">
                        <div class="form-group" *ngIf="context.isGlobalFormatVisible && context.isProjectSaleMatter && this.context.statementAdjustmentHeadingProject">
                            <div class="col-lg-4 vertical-align-label">
                                <label class="control-label">Adopt Project SOA Heading</label>
                            </div>
                            <div class="col-lg-8">
                                <dp-checkbox fieldId="adoptProjectHeading">
                                    <input type="checkbox"
                                           id="adoptProjectHeading"
                                           name="adoptProjectHeading"
                                           [(ngModel)]="statementOfAdjustmentHeading.adoptProjectHeading"
                                           [checked]="statementOfAdjustmentHeading.adoptProjectHeading"
                                           (change)="onAdoptProjectHeadingChange()"
                                    />
                                </dp-checkbox>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="context.hasMultipleSOAHeadings">
                            <div class="col-lg-4 vertical-align-label">
                                <label class="control-label">Interim Statement of Adjustments heading</label>
                            </div>
                            <div class="col-lg-8">
                                <input type="text"
                                       id="interimAdjustmentHeader"
                                       name="interimAdjustmentHeader"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('soaHeading')"
                                       (keydown.f9)="updateSOAHeading('interimStatementOfAdjustmentHeader','soaHeading')"
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.interimStatementOfAdjustmentHeader"
                                       [readOnly]="statementOfAdjustmentHeading.adoptProjectHeading"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-4 vertical-align-label">
                                <label class="control-label" *ngIf="context.hasMultipleSOAHeadings">Final Statement of Adjustments heading</label>
                                <label class="control-label" *ngIf="!context.hasMultipleSOAHeadings">Enter Statement of Adjustments heading</label>
                            </div>
                            <div class="col-lg-8">
                                <input type="text"
                                       id="adjustmentHeader"
                                       name="adjustmentHeader"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('soaHeading')"
                                       (keydown.f9)="updateSOAHeading('statementOfAdjustmentHeader','soaHeading')"
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.statementOfAdjustmentHeader"
                                       [readOnly]="statementOfAdjustmentHeading.adoptProjectHeading"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >Vendor heading</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="vendorHeader"
                                       name="vendorHeader"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('otherHeadingsVendor')"
                                       class="form-control"
                                       (keydown.f9)="updateSOAHeading('vendorHeading','otherHeadingsVendor')"
                                       [(ngModel)]="statementOfAdjustmentHeading.vendorHeading"
                                       [readOnly]="context.isProjectSaleMatter"
                                       />
                            </div>
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >data</label>
                            </div>
                            <div class="col-lg-4">
                                <select type="text"
                                        id="vendorHeaderData"
                                        name="vendorHeaderData"
                                        class="form-control"
                                        [(ngModel)]="statementOfAdjustmentHeading.vendorDataCode"
                                        [dp-read-only]="context.isProjectSaleMatter"
                                >
                                    <option *ngFor="let vendorHeaderDropDown of otherHeadingVendorNames" [value]="vendorHeaderDropDown.value">
                                        {{vendorHeaderDropDown.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >Purchaser heading</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="purchaserHeader"
                                       name="purchaserHeader"
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('otherHeadingsPurchaser')"
                                       (keydown.f9)="updateSOAHeading('purchaserHeading','otherHeadingsPurchaser')"
                                       statusBarHelp
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.purchaserHeading"
                                       [readOnly]="context.isProjectSaleMatter"
                                />
                            </div>
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >data</label>
                            </div>
                            <div class="col-lg-4">
                                <select type="text"
                                        id="purchaserHeaderData"
                                        name="purchaserHeaderData"
                                        class="form-control"
                                        [(ngModel)]="statementOfAdjustmentHeading.purchaserDataCode"
                                        [dp-read-only]="context.isProjectSaleMatter"
                                >
                                    <option *ngFor="let vendorHeaderDropDown of otherHeadingsPurchaserNames" [value]="vendorHeaderDropDown.value">
                                        {{vendorHeaderDropDown.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >Address heading</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="addressHeader"
                                       name="addressHeader"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('otherHeadingsAddress')"
                                       (keydown.f9)="updateSOAHeading('addressHeading','otherHeadingsAddress')"
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.addressHeading"
                                       [readOnly]="context.isProjectSaleMatter"
                                />
                            </div>
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >data</label>
                            </div>
                            <div class="col-lg-4">
                                <select type="text"
                                        id="addressHeaderData"
                                        name="addressHeaderData"
                                        class="form-control"
                                        [(ngModel)]="statementOfAdjustmentHeading.addressDataCode"
                                        [dp-read-only]="context.isProjectSaleMatter"
                                >
                                    <option *ngFor="let vendorHeaderDropDown of addressData" [value]="vendorHeaderDropDown.value">
                                        {{vendorHeaderDropDown.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >Adjust date heading</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="adjustDateHeader"
                                       name="adjustDateHeader"
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('otherHeadingsAdjustDate')"
                                       (keydown.f9)="updateSOAHeading('adjustDateHeading','otherHeadingsAdjustDate')"
                                       statusBarHelp
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.adjustDateHeading"
                                       [readOnly]="context.isProjectSaleMatter"
                                />
                            </div>
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >data</label>
                            </div>
                            <div class="col-lg-4">
                                <select type="text"
                                        id="adjustDateHeaderData"
                                        name="adjustDateHeaderData"
                                        class="form-control"
                                        [(ngModel)]="statementOfAdjustmentHeading.adjustDateDataCode"
                                        [dp-read-only]="context.isProjectSaleMatter"
                                >
                                    <option *ngFor="let vendorHeaderDropDown of adjustDate" [value]="vendorHeaderDropDown.value">
                                        {{vendorHeaderDropDown.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >Right column lists credits to</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="rightColumn"
                                       name="rightColumn"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('rightColumnListsCreditsTo')"
                                       (keydown.f9)="updateSOAHeading('rightColumnListsCreditsTo','rightColumnListsCreditsTo')"
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.rightColumnListsCreditsTo"
                                       [readOnly]="context.isProjectSaleMatter"
                                />
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                >Left column lists credits to</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="leftColumn"
                                       name="leftColumn"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction]="dynamicHelpF9SOAHeading('leftColumnListsCreditsTo')"
                                       (keydown.f9)="updateSOAHeading('leftColumnListsCreditsTo','leftColumnListsCreditsTo')"
                                       class="form-control"
                                       [(ngModel)]="statementOfAdjustmentHeading.leftColumnListsCreditsTo"
                                       [readOnly]="context.isProjectSaleMatter"
                                />
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Paper Size</label>
                            </div>
                            <div class="col-lg-4">
                                <select class="form-control"
                                        id="paperSizeCode"
                                        name="paperSizeCode"
                                        [(ngModel)]="statementOfAdjustmentHeading.paperSizeCode"
                                        [dp-read-only]="context.isProjectSaleMatter"
                                >
                                    <option [value]=""></option>
                                    <option *ngFor="let papersize of paperSizes"
                                            [value]="papersize.value">
                                        {{papersize.label}}
                                    </option>
                                </select>
                            </div>

                        </div>
                        <div class="form-group" *ngIf="context.isSoaHeadingWithVendorSolicitor">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Include vendor's GST no.?</label>
                            </div>
                            <div class="col-lg-4">
                                <dp-checkbox fieldId="includeVendorGst">
                                    <input type="checkbox"
                                           id="includeVendorGst"
                                           name="includeVendorGst"
                                           [(ngModel)]="statementOfAdjustmentHeading.includeVendorGst"
                                           [checked]="statementOfAdjustmentHeading.includeVendorGst"
                                           [dp-read-only]="context.isProjectSaleMatter"
                                    />
                                </dp-checkbox>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="context.isSoaHeadingWithVendorSolicitor">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Include solicitor's name on signing line of Direction Re Funds?</label>
                            </div>
                            <div class="col-lg-4">
                                <dp-checkbox fieldId="includeSolicitorNameOnSigningLineOfDirectionReFunds">
                                    <input type="checkbox"
                                           id="includeSolicitorNameOnSigningLineOfDirectionReFunds"
                                           name="includeSolicitorNameOnSigningLineOfDirectionReFunds"
                                           [(ngModel)]="statementOfAdjustmentHeading.includeSolicitorNameOnSigningLineOfDirectionReFunds"
                                           [checked]="statementOfAdjustmentHeading.includeSolicitorNameOnSigningLineOfDirectionReFunds"
                                           [dp-read-only]="context.isProjectSaleMatter"
                                    />
                                </dp-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-lg-12 fields-group  margin-top-0 margin-bottom-0" *ngIf="customFormatVisible">
        <div class="form-group main-level">
            <span class="title min-height-30">Adjustment Format</span>
        </div>
        <div class=" modal-body form-horizontal">
            <dp-statement-adjustment-format
                [viewOnly]="true"
                [statementAdjustment]="context.selectedStatementAdjustment"
                [statementOfAdjustmentHeading]="context.statementAdjustmentHeadingMatter">
            </dp-statement-adjustment-format>
        </div>
    </div>
    <div class="col-lg-12 fields-group  margin-top-0 margin-bottom-0" *ngIf="context.isGlobalFormatVisible && !customFormatVisible">
        <div class="form-group main-level">
            <span class="title min-height-30">Global Format</span>
        </div>
        <div class="form-group">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <dp-multi-select [multiSelectData]="globalFormats" [inputName]="'global_format'"
                                 [title]="'No Global Format Selected'"
                                 [showLabelInTitle]="false" [defaultToAll]="false"
                                 [multiSelectDataSelected]="selectedglobalFormats"
                                 (updateMultiSelect)="updateMultiSelectGlobalFormat($event)"
                                 [disabled]="context.isGlobalFormatReadonly"
                                 [listStyle]="true"></dp-multi-select>
            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>



