import {ErrorService} from '../../shared/error-handling/error-service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Component, Inject, OnDestroy} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class CustomerBillingPortalModalContext {
  url: string;
}

@Component({
  selector: 'dp-customer-billing-portal-modal-content',
  templateUrl: 'customer-billing-portal.modal.component.html',
  providers: [ ErrorService ],
  styleUrls: [
    './customer-billing-portal.scss'
  ]
})

@AutoUnsubscribe()
export class CustomerBillingPortalModal extends ModalComponent<CustomerBillingPortalModalContext> implements OnDestroy {
  customerBillingPortalUrl: SafeUrl;

  constructor(public dialog: MatDialogRef<CustomerBillingPortalModal>,
              public sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) context?: CustomerBillingPortalModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.customerBillingPortalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.url);
  }

  cancel(): void {
    this.dialog.close();
  }

  ngOnDestroy() {
  }
}
