import {MatterSectionsTitles} from '../matters/shared/section';
import {MatterType} from '../matters/shared/matter-specific-type';
import {ProvinceCode} from '../admin/accounts/shared/base-province';
import {Matter} from '../matters';

export type LocalizationProvinceCode = ProvinceCode | 'DEFAULT';
export type LocalizationMatterType = MatterType | 'DEFAULT';

export type LocalizationEntry = {
  key: string;
  provinces: LocalizationProvinceEntry[];
}

export type LocalizationProvinceEntry = {
  province: LocalizationProvinceCode;
  matterTypes: LocalizationMatterTypeEntry[]
}

export type LocalizationMatterTypeEntry = {
  matterType: LocalizationMatterType;
  value: string;
};

export const SectionLocalizationMapping: LocalizationEntry[] = [
  {
    key: 'MATTER_OPENING', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'MORTGAGE', value: MatterSectionsTitles.MATTER_DETAILS},
          {matterType: 'PURCHASE', value: MatterSectionsTitles.MATTER_DETAILS},
          {matterType: 'SALE', value: MatterSectionsTitles.MATTER_DETAILS}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.OPPORTUNITY_DETAIL},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.MATTER_OPENING}
        ]
      }
    ]
  },
  {
    key: 'DUE_DILIGENCE', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.DUE_DILIGENCE}
        ]
      }
    ]
  },
  {
    key: 'MORTGAGOR', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.BORROWERS}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.MORTGAGORS}
        ]
      }
    ]
  },
  {
    key: 'OTHER_SOLICITOR', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'MORTGAGE', value: MatterSectionsTitles.OTHER_LAWYER_NOTARY}
        ]
      }
    ]
  },
  {
    key: 'PROPERTY', provinces: [
      {
        province: 'NB', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: 'Subject Property'}
        ]
      },
      {
        province: 'NS', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: 'Subject Property'}
        ]
      },
      {
        province: 'AB', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: 'Subject Property/SPIN'}
        ]
      },
      {
        province: 'MB', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: 'Subject Property/TPR'}
        ]
      },
      {
        province: 'SK', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: 'Subject Property/ISC'}
        ]
      },
      {
        province: 'BC', matterTypes: [
          {matterType: 'SALE', value: MatterSectionsTitles.PROPERTIES},
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.PROPERTIES}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.DEAL_DETAILS},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.PROPERTY_TERANET_CONNECT}
        ]
      }
    ]
  },
  {
    key: 'EXISTING_MORTGAGE', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'MORTGAGE', value: MatterSectionsTitles.EXISTING_CHARGE},
          {matterType: 'SALE', value: MatterSectionsTitles.EXISTING_CHARGE}
        ]
      }
    ]
  },
  {
    key: 'CONDO_CORPORATION', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'MORTGAGE', value: MatterSectionsTitles.STRATA_CORPORATION},
          {matterType: 'PURCHASE', value: MatterSectionsTitles.STRATA_CORPORATION},
          {matterType: 'SALE', value: MatterSectionsTitles.STRATA_CORPORATION}
        ]
      }
    ]
  },
  {
    key: 'FIRE_INSURANCE', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'SALE', value: MatterSectionsTitles.FIRE_INSURANCE},
          {matterType: 'MORTGAGE', value: MatterSectionsTitles.HOME_INSURANCE},
          {matterType: 'PURCHASE', value: MatterSectionsTitles.HOME_INSURANCE}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'SALE', value: MatterSectionsTitles.PURCHASER_FIRE_INSURANCE},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.FIRE_INSURANCE}
        ]
      }
    ]
  },
  {
    key: 'TRUST_LEDGER', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'MORTGAGE', value: MatterSectionsTitles.ORDER_TO_PAY_TRUST_RECONCILIATION},
          {matterType: 'PURCHASE', value: MatterSectionsTitles.ORDER_TO_PAY_TRUST_RECONCILIATION},
          {matterType: 'SALE', value: MatterSectionsTitles.ORDER_TO_PAY_TRUST_RECONCILIATION}
        ]
      },
      {
        province: 'AB', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.TRUST_LEDGER_RECONCILIATION}
        ]
      },
      {
        province: 'MB', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.TRUST_LEDGER_RECONCILIATION}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.TRUST_LEDGER}
        ]
      }
    ]
  },
  {
    key: 'DIRECTION_RE_FUNDS', provinces: [
      {
        province: 'AB', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction to Pay'}
        ]
      },
      {
        province: 'MB', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction to Pay'}
        ]
      },
      {
        province: 'SK', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction to Pay'}
        ]
      },
      {
        province: 'BC', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction to Pay'}
        ]
      },
      {
        province: 'ON', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction re Funds'}
        ]
      },
      {
        province: 'NB', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction re Funds'}
        ]
      },
      {
        province: 'NS', matterTypes: [
          {matterType: 'DEFAULT', value: 'Direction re Funds'}
        ]
      }
    ]
  },
  {
    key: 'EXECUTION_AFFIDAVITS', provinces: [
      {
        province: 'NS', matterTypes: [
          {matterType: 'DEFAULT', value: 'Judgement Declaration'}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.EXECUTION_AFFIDAVITS}
        ]
      }
    ]
  },
  {
    key: 'VENDOR', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'DEFAULT', value: 'Sellers'}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.VENDORS}
        ]
      }
    ]
  },
  {
    key: 'PURCHASER_SOLICITOR', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'SALE', value: MatterSectionsTitles.BUYER_LAWYER},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.CHARGES_TO_REMOVE}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.PURCHASERS_SOLICITOR}
        ]
      }
    ]
  },
  {
    key: 'EXISTING_MORTGAGE', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'DEFAULT', value: 'Buyers & Lawyer/Notary'}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.EXISTING_MORTGAGE}
        ]
      }
    ]
  },
  {
    key: 'UNDERTAKINGS', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'PURCHASE', value: MatterSectionsTitles.UNDERTAKINGS}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'SALE', value: MatterSectionsTitles.UNDERTAKINGS_GIVEN},
          {matterType: 'PURCHASE', value: MatterSectionsTitles.UNDERTAKINGS_RECEIVED},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.UNDERTAKINGS}
        ]
      }
    ]
  },
  {
    key: 'REQUISITIONS', provinces: [
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'SALE', value: MatterSectionsTitles.RESPONSE_TO_REQUISITIONS}
        ]
      }
    ]
  },
  {
    key: 'PURCHASER', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'PURCHASE', value: MatterSectionsTitles.BUYERS}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.MY_PROSPECTS},
          {matterType: 'CUSTOM', value: MatterSectionsTitles.MY_CLIENTS},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.PURCHASERS}
        ]
      }
    ]
  },
  {
    key: 'VENDOR_SOLICITOR', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'PURCHASE', value: MatterSectionsTitles.SELLERS_LAWYER_NOTARY}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'CUSTOM', value: MatterSectionsTitles.OTHER_SIDE},
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.OTHER_SIDE},
          {matterType: 'DEFAULT', value: MatterSectionsTitles.VENDORS_SOLICITORS}
        ]
      }
    ]
  },
  {
    key: 'STATEMENT_OF_ADJUSTMENT', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'PURCHASE', value: MatterSectionsTitles.STATEMENTS_OF_ADJUSTMENTS}
        ]
      },
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.STATEMENT_OF_ADJUSTMENTS}
        ]
      }
    ]
  },
  {
    key: 'REPORT_TO_PURCHASER', provinces: [
      {
        province: 'BC', matterTypes: [
          {matterType: 'PURCHASE', value: MatterSectionsTitles.REPORT_TO_BUYER}
        ]
      }
    ]
  },
  {
    key: 'REAL_ESTATE_BROKER', provinces: [
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'DEFAULT', value: MatterSectionsTitles.RE_BROKER_COMMISION}
        ]
      }
    ]
  },
  {
    key: 'MATTER_DOCUMENTS', provinces: [
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.OPPORTUNITY_DOCUMENTS}
        ]
      }
    ]
  },
  {
    key: 'OVERVIEW', provinces: [
      {
        province: 'DEFAULT', matterTypes: [
          {matterType: 'OPPORTUNITY', value: MatterSectionsTitles.WORK_ITEMS}
        ]
      }
    ]
  }
];

export class SectionLocalizationUtil {

  static getSectionTitle(key: string, matter?: Matter, provinceCode?: LocalizationProvinceCode, matterType?: LocalizationMatterType): string {
    matterType = SectionLocalizationUtil.getMatterType(matter, matterType);
    let treeResult = this.findInTree(key, provinceCode ? provinceCode : matter?.provinceCode, matterType);
    if (treeResult) {
      return treeResult;
    }
    if (MatterSectionsTitles[ key ]) {
      return MatterSectionsTitles[ key ];
    }
  }

  private static getMatterType(matter?: Matter, matterType?: LocalizationMatterType) {
    if (matter?.isCustomMatter()) {
      return 'CUSTOM';
    }
    if (matter?.matterType) {
      return matter.matterType;
    }
    if (matterType) {
      return matterType;
    }
  }

  static findInTree(key: string, provinceCode: LocalizationProvinceCode, matterType: LocalizationMatterType = 'DEFAULT'): string {
    let keyEntry = SectionLocalizationMapping.find(entry => entry.key === key);
    if (keyEntry) {
      let provinceEntry = keyEntry.provinces?.find(pEntry => pEntry.province === provinceCode);
      if (!provinceEntry) {
        if (provinceCode != 'DEFAULT') {
          return this.findInTree(key, 'DEFAULT', matterType);
        }
        return undefined;
      }
      let matterTypeEntry = provinceEntry.matterTypes?.find(mtEntry => mtEntry.matterType === matterType);
      if (!matterTypeEntry) {
        matterTypeEntry = provinceEntry.matterTypes?.find(matterTypeEntry => matterTypeEntry.matterType === 'DEFAULT');
      }
      if (matterTypeEntry) {
        return matterTypeEntry.value;
      }
      if (provinceCode != 'DEFAULT') {
        return this.findInTree(key, 'DEFAULT', matterType);
      }
    }
  }
}
