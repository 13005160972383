import {Injectable} from '@angular/core';
import {MatterTab} from './matter-tab';
import {TabsService} from '../core/tabs.service';
import {Matter} from './shared/matter';
import {DialogService} from '../shared/dialog/dialog.service';
import {LockScreenService} from '../core/lock-screen.service';
import {MatterService} from './matter.service';
import {Project} from '../../app/projects/shared/project';

@Injectable()
export class NavigateToMatterService {
  constructor(public tabsService: TabsService,
              private dialogService: DialogService,
              public lockScreenService: LockScreenService,
              public matterService: MatterService
  ) {

  }

  get activeMatterTab(): MatterTab {
    return this.tabsService && this.tabsService.activeTab as MatterTab;
  }

  get projectMatters(): Matter[] {
    return this.activeMatterTab ? this.activeMatterTab.sortedProjectMatters : null;
  }

  isPreviousMatterBtnVisible(sourceMatter: Matter): boolean {
    if (this.projectMatters && this.projectMatters.length > 1 && sourceMatter.id > 0) {
      return this.projectMatters.findIndex(matter => matter.id == sourceMatter.id) != 0;
    } else {
      return false;
    }

  }

  isNextMatterBtnVisible(sourceMatter: Matter): boolean {
    if (this.projectMatters && this.projectMatters.length > 1 && sourceMatter.id > 0) {
      return this.projectMatters.findIndex(matter => matter.id == sourceMatter.id) != this.projectMatters.length - 1;
    } else {
      return false;
    }

  }

  async goToPreviousMatter(sourceMatter: Matter, isTabReadOnly: boolean, ignoreModalCheck: boolean = false): Promise<number> {
    if (this.isPreviousMatterBtnVisible(sourceMatter)) {
      let currentMatterIndex = this.projectMatters.findIndex(matter => matter.id == sourceMatter.id);
      let targetMatter = this.projectMatters[ currentMatterIndex - 1 ];
      return this.goToMatter(sourceMatter, targetMatter, isTabReadOnly, ignoreModalCheck);
    }
  }

  async goToNextMatter(sourceMatter: Matter, isTabReadOnly: boolean, ignoreModalCheck: boolean = false): Promise<number> {
    if (this.isNextMatterBtnVisible(sourceMatter)) {
      let currentMatterIndex = this.projectMatters.findIndex(matter => matter.id == sourceMatter.id);
      let targetMatter = this.projectMatters[ currentMatterIndex + 1 ];
      return this.goToMatter(sourceMatter, targetMatter, isTabReadOnly, ignoreModalCheck);
    }
  }

  async goToMatter(sourceMatter: Matter, targetMatter: Matter, isTabReadOnly: boolean, ignoreModalCheck: boolean = false): Promise<number> {
    let openModals = document.body.querySelectorAll('.mat-modal-dialog');
    if (!openModals || openModals && !openModals.length || ignoreModalCheck) {
      if (this.tabsService.isMatterTabOpen(targetMatter.id)) {
        this.dialogService.confirm('Information', 'You cannot open next/previous matter in the project here, it\'s already open in another tab.', true);
        return Promise.resolve(null);
      } else {
        let toProceed: boolean;
        if (sourceMatter.isDirty) {
          toProceed = await this.askToSaveCurrentMatterAndOpenTargetMatter(targetMatter, isTabReadOnly);
          return toProceed ? Promise.resolve(targetMatter.id) : Promise.resolve(null);
        } else {
          this.tabsService.unlockMatter(this.activeMatterTab, this.matterService);
          toProceed = await this.loadTargetMatterIntoTheTab(targetMatter);
          return toProceed ? Promise.resolve(targetMatter.id) : Promise.resolve(null);
        }

      }
    }
    return Promise.resolve(null);

  }

  async askToSaveCurrentMatterAndOpenTargetMatter(targetMatter: Matter, isTabReadOnly: boolean): Promise<boolean> {
    let toProceed = await this.tabsService.removeMatterTab(this.activeMatterTab, null, isTabReadOnly, false, this.matterService);
    if (toProceed) {
      return this.loadTargetMatterIntoTheTab(targetMatter);
    }
  }

  async loadTargetMatterIntoTheTab(targetMatter: Matter): Promise<boolean> {

    try {
      this.lockScreenService.lockForUpdate = true;
      this.activeMatterTab.isMatterTabLoading = true;
      this.activeMatterTab.focusedOnLastVisitedElement = true;
      let matter: Matter = await this.matterService.getMatter(targetMatter.id).toPromise();
      if (matter) {
        if (matter.unityProjectId && this.activeMatterTab.matter.project && this.activeMatterTab.matter.project.id == matter.unityProjectId) {
          matter.project = new Project(this.activeMatterTab.matter.project);
        }
        this.activeMatterTab.initiateMatterTab(matter);
        return Promise.resolve(true);
      }
    } catch (err) {
      this.lockScreenService.lockForUpdate = false;
      return Promise.resolve(false);
    } finally {
      this.activeMatterTab.isMatterTabLoading = false;
      this.lockScreenService.lockForUpdate = false;
    }

  };
}
