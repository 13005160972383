export class AddressTypes {

  static readonly mailing: string = 'MAILING';
  static readonly business: string = 'BUSINESS';
  static readonly physical: string = 'PHYSICAL';
  static readonly billing: string = 'BILLING';
  static readonly postClosingAddress: string = 'POST_CLOSING_ADDRESS';
  static readonly preClosingAddress: string = 'PRE_CLOSING_ADDRESS';
  static readonly serviceAddress: string = 'SERVICE';
  static readonly reportAddress: string = 'REPORT';
  static readonly subjectAddress: string = 'SUBJECT';
  static readonly solicitorAddress: string = 'SOLICITOR';
  static readonly firmMailing: string = 'FIRM_MAILING';
  static readonly customerAccount: string = 'CUSTOMER_ACCOUNT';
  static readonly manuallyEntered: string = 'OTHER';
  static readonly registeredOffice: string = 'REGISTERED_OFFICE';
  static readonly residence: string = 'RESIDENCE';
  static readonly clientIdBusiness: string = 'CLIENT_ID_BUSINESS';
  static readonly otherPreviousAddress: string = 'OTHER_PREVIOUS_ADDRESS';
  static readonly previousMailingAddress: string = 'PREVIOUS_MAILING_ADDRESS';
  static readonly previousMailingAddressPtt: string = 'PREVIOUS_MAILING_ADDRESS_PTT';
}
