import Utils from '../../../shared-main/utils';

import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';
import {SoAdjWithTax} from './so-adj-with-tax';

export class SoAdjOtherProrated extends SoAdjWithTax {

  id: number;

  direction: string;
  heading: string;

  commencementDate: string;
  expiryDate: string;

  amountToBeProrated: number;
  amountReceived: number;
  vendorPaidFullAmount: boolean;

  infoOnly: boolean;

  get hstOrGstValueFromProrated(): number {
    return this.getHstOrGstValueForAmount(this.amountToBeProrated);
  }

  get pstAmountFromProrated(): number {
    return this.getPstValueForAmount(this.amountToBeProrated);
  }

  get amountToBeProratedPlusTax(): number {
    return +this.amountToBeProrated +
      this.hstOrGstValueFromProrated +
      this.pstAmountFromProrated;
  }

  get vendorHasReceivedTotal(): number {
    if (Utils.convertToBoolean(this.vendorPaidFullAmount)) {
      return this.amountToBeProratedPlusTax;
    } else {
      return this.amountReceived;
    }
  }

  get paymentPeriodInDays(): number {

    if (this.isNotValidDate(this.commencementDate) || this.isNotValidDate(this.expiryDate)) {
      return 0;
    } else {
      return this.getDateDiff(this.commencementDate, this.expiryDate) + 1;
    }
  }

  getNumberOfDaysElapsed(closingDate: string): number {
    let nde: number = this.getDateDiff(this.commencementDate, closingDate);
    return nde;
  }

  getNumberOfDaysElapsedStr(numberOfDays: number): string {
    if (numberOfDays >= 0) {
      return numberOfDays.toString();
    } else {
      return '???';
    }
  }

  getVendorsShare(closingDate: string, statementAdjustmentKey: string, paysForDateOfClosing: string): number {
    let isPaysForDateOfClosing: boolean = paysForDateOfClosing == 'VENDOR' ? true : false;
    let nde: number = this.getNumberOfDaysElapsed(closingDate);
    if (isPaysForDateOfClosing) {
      nde++;
    }
    if (this.paymentPeriodInDays > 0 && nde > 0 && this.paymentPeriodInDays >= nde) {
      let paymentPeriodinDays: number = this.paymentPeriodInDays;
      return (this.amountToBeProratedPlusTax / paymentPeriodinDays) * nde;
    } else {
      return -1;
    }
  }

  getCreditAmount(closingDate: string, statementAdjustmentKey: string, paysForDateOfClosing: string): number {
    if (this.getVendorsShare(closingDate, statementAdjustmentKey, paysForDateOfClosing) < 0) {
      return -1;
    } else {
      let creditAmount: number = this.getVendorsShare(closingDate, statementAdjustmentKey, paysForDateOfClosing) - this.vendorHasReceivedTotal;
      if (creditAmount < 0) {
        creditAmount *= (-1);
      }
      return Utils.roundCurrency(creditAmount);
    }
  }

  getCreditType(closingDate: string, statementAdjustmentKey: string, paysForDateOfClosing: string): string {

    // IF(AND(Calculations!C3='Pick List Values'!B2,OR(SUM(C27:C29)<=C30,C30>C31)),"Credit Vendor",
    // IF(AND(Calculations!C3='Pick List Values'!B3,OR(SUM(C27:C29)<C30),C30>C31),"Credit Purchaser",
    // IF(AND(Calculations!C3='Pick List Values'!B3,OR(SUM(C27:C29)>C30),C30<C31),"Credit Vendor",
    // "Credit Purchaser")))

    if (this.direction == 'PAYABLE' && (this.amountToBeProratedPlusTax <= this.vendorHasReceivedTotal || this.vendorHasReceivedTotal > this.getVendorsShare(closingDate, statementAdjustmentKey, paysForDateOfClosing))) {
      return StatementAdjustmentAmountTypes.VENDOR;
    } else if (this.direction == 'RECEIVABLE' && (this.amountToBeProratedPlusTax < this.vendorHasReceivedTotal || this.vendorHasReceivedTotal > this.getVendorsShare(closingDate, statementAdjustmentKey, paysForDateOfClosing))) {
      return StatementAdjustmentAmountTypes.PURCHASER;
    } else if (this.direction == 'RECEIVABLE' && (this.amountToBeProratedPlusTax > this.vendorHasReceivedTotal || this.vendorHasReceivedTotal < this.getVendorsShare(closingDate, statementAdjustmentKey, paysForDateOfClosing))) {
      return StatementAdjustmentAmountTypes.VENDOR;
    }
    return StatementAdjustmentAmountTypes.PURCHASER;
  }
}
