import {Directive, HostListener} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[dp-noWhiteSpace]'
})
export class NoWhiteSpaceDirective {

  // @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private model: NgModel) {
  }

  @HostListener('input') onInput(): void {
    const elValue: string = this.model.control.value;
    if (elValue) {
      const value: string = elValue.replace(/\s+/g, '');
      if (value !== elValue) {
        this.model.control.setValue(value);
      }
    }
  }

}


