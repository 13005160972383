export enum ModalResult {
  OK,
  Delete,
  Cancel,
  Skip
}

export enum DuplicateProspectModalResult {
  IGNORE,
  KEEP,
  REPLACE
}

