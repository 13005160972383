<form class="form-horizontal" id="manageTeranetUser" #manageTeranetUserForm="ngForm" >
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-lg-10">
                <h1>{{pageTitle}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>

        <div class="row modal-body">
            <div class="form-group">
                <div class="col-lg-4 text-left vertical-align-label">
                    <label class="control-label">Teranet Account Name*</label>
                </div>
                <div class="col-lg-6">
                    <input  class="form-control focus-first" id="teranet_account_name" name="teranet_account_name"
                            [(ngModel)]="teranetUser.teranetAccountName"
                            maxlength="20"
                            required
                            trimmed
                    >
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-4 text-left vertical-align-label">
                    <label class="control-label">Teranet User Name*</label>
                </div>
                <div class="col-lg-6">
                    <input  class="form-control focus-first" id="teranet_user_name" name="teranet_user_name"
                            [(ngModel)]="teranetUser.teranetLoginName"
                            maxlength="15"
                            required
                            trimmed
                    >
                </div>
            </div>
        </div>


        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn2"
                        (click)="saveUser()"
                        [disabled]="manageTeranetUserForm.form.invalid"
                        class="dp-btn">
                    <span>Save</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
</form>
