import {telusConfigurations} from '../../shared-main/telus/telus-constants';
import {requisitionDateLabel} from '../../shared-main/province-based-field-labels';

export const ErrorDirectiveMapping = {
  'matter.propertyteranet.pin': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Pin # is Invalid. (The PIN must contain 9 characters.)',
    ERROR_TOPIC: 'Property'
  },
  'matter.propertyteranet.pin.warningOnly': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Pin # is Invalid. (The PIN must contain 9 characters.)',
    ERROR_TOPIC: 'Property'
  },
  'privateContact.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for Private Contact # is required.',
    ERROR_TOPIC: 'My Contacts'
  },
  'privateContact.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name for Private Contact # is required.',
    ERROR_TOPIC: 'My Contacts'
  },
  'solicitorContact.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for <FIELD_LABEL> # is required.',
    ERROR_TOPIC: '<TOPIC_LABEL>'
  },
  'matter.otherParty.contact.firmSolicitor.lawFirm.firmName.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name for law firm is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.solicitorContact.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for <FIELD_LABEL> is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.bc.solicitorContact.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Surname for Lawyer/Notary required',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.mortgage.solicitorContact.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for <FIELD_LABEL> is required.',
    ERROR_TOPIC: '<TOPIC_LABEL>'
  },
  'solicitor.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for <FIELD_LABEL> is required.',
    ERROR_TOPIC: 'matter'
  },
  'offerorContact.lastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'offerorContact.organizationName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.fireInsurance.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: 'Fire Insurance'
  },
  'matter.fireInsurance.expiryDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Expiry Date is invalid.',
    ERROR_TOPIC: 'Fire Insurance'
  },
  'matter.fireInsurance.expiryDate.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Expiry Date is incomplete.',
    ERROR_TOPIC: 'Fire Insurance'
  },
  'matter.purchaserReport.transferRegistrationDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Transfer Registration Date is invalid.',
    ERROR_TOPIC: 'Report to Purchaser'
  },
  'matter.purchaserReport.commonExpensesPaymentDue': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Common Expenses - Payment Due Date is invalid.',
    ERROR_TOPIC: 'Report to Purchaser'
  },
  'matter.propertyteranet.pin1': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Pin 1 is invalid. (The PIN must contain 9 characters.)',
    ERROR_TOPIC: 'Property'
  },
  'matter.propertyteranet.pin1.warningOnly': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The Pin 1 is invalid. (The PIN must contain 9 characters.)',
    ERROR_TOPIC: 'Property'
  },
  'matter.propertyteranet.jurisdiction': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Freeform entry of a name is prohibited.  Jurisdictions must be selected from the contact list.',
    ERROR_TOPIC: 'Property'
  },
  'matter.propertyteranet.surveyor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Freeform entry of a name is prohibited.  Surveyors must be selected from the contact list.',
    ERROR_TOPIC: 'Property'
  },
  'matter.propertyteranet.soAdj.other.fixed': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: `One or more Other (Fixed Amount) adjustments exist with a selection in the "Is amount additional consideration to vendor?" field set to "Yes"`,
    ERROR_TOPIC: 'Property'
  },
  'matter.matterOpening.inactiveErrorForMatterOverviewStatusIsFlagged': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Matter cannot be made Inactive while Matter Status is Flagged.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.matterType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Matter Type is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.provinceCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.matterNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> is required.',
    ERROR_TOPIC: '<TAB_A_TOPIC>'
  },
  'matter.matterOpening.newMatterNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Matter No. is required.',
    ERROR_TOPIC: ' '
  },
  'matter.matterOpening.purchaseSaleAgreementDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> date is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.fileInactiveDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Inactive date is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.inactiveWarning': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'In order to view Inactive matters please change your filter settings on the Matters Page.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'property.surveyDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Survey date is invalid.',
    ERROR_TOPIC: 'Property'
  },
  'mortgage.#.contact.firmSolicitor.lawFirm.firmName.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for law firm is required.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'mortgage.#.solicitorContact.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname for solicitor is required.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'mortgage.#.commitmentDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commitment date is invalid.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'mortgage.#.registrationDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Mortgage Registration date is invalid.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'mortgage.#.report.rentAssignmentRegistrationDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Assignment of Rents Registration Date is invalid.',
    ERROR_TOPIC: '#s Mortgage Report'
  },
  'mortgage.#.term.intAdjustmentDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Interest Adj. date is invalid.',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.firstPaymentDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'First Payment date is invalid.',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.maturityDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Maturity date is invalid.',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.altPaymentDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Alt. First Payment date is invalid.',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'matter.matterOpening.fileOpeningDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> date is invalid.',
    ERROR_TOPIC: '<TAB_A_TOPIC>'
  },
  'mortgage.mortgageEmp.signageDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Mortgage signing date is invalid.',
    ERROR_TOPIC: 'Lawyer Data'
  },
  'matter.matterOpening.matterCloseDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> is invalid.',
    ERROR_TOPIC: '<TAB_A_TOPIC>'
  },
  'matter.matterOpening.matterCloseDate.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<FIELD_LABEL> is incomplete.',
    ERROR_TOPIC: '<TAB_A_TOPIC>'
  },
  'matter.matterOpening.matterCloseDate.MISSING': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<FIELD_LABEL> is missing.',
    ERROR_TOPIC: '<TAB_A_TOPIC>'
  },
  'matter.matterOpening.requisitionDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.releaseDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Release date is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.purchaserExecDocsDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> executing doc date is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.occupancyDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Occupancy date is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.realEstateBroker': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Freeform entry of a name is prohibited.  Real Estate Brokers must be selected from the contact list.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.realEstateAgent': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Freeform entry of a name is prohibited.  Real Estate Agents must be selected from the contact list.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'matter.matterOpening.matterLink': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Can\'t link to existing matter:  VTB mortgages present on matters.  Remove any VTB mortgages on both matters before linking.',
    ERROR_TOPIC: ''
  },
  'matter.mainClient.matterContactInfo.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'jurisdiction.jurisdictionDepartment.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'condoInfo.condoInfoSourceContact.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'lawclerk.lawClerkEmail': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<LAW_CLERK_EMAIL>\'s Email is invalid.',
    ERROR_TOPIC: '<FIELD_LABEL>'
  },
  'matter.mainClient.matterContactInfo.bank.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Bank Address.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.dateFundsDeposited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Funds Deposited date is invalid.',
    ERROR_TOPIC: '<MAIN_TOPIC>s'
  },
  'matter.mainClient.dateFundsDeposited.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Funds Deposited date is incomplete.',
    ERROR_TOPIC: '<MAIN_TOPIC>s'
  },
  'matter.otherParty.dateFundsDeposited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Funds Deposited date is invalid.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.dateFundsDeposited.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Funds Deposited date is incomplete.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.matterContactInfo.bank.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Bank Address.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'admin.lawFirmAddress.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for address.',
    ERROR_TOPIC: 'Manage Document Profile'
  },
  'admin.staffProfile.address.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invalid Postal Code for Mailing address.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.documentProfile.emptyProfileName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Document profile name must be completed.',
    ERROR_TOPIC: 'Document Profile'
  },
  'admin.documentProfile.uniqueProfileName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Document profile name already exists.',
    ERROR_TOPIC: 'Document Profile'
  },
  'admin.staffProfile.contact.defaultProvinceCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.soaConfig.soaTemplate.templateName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Duplicate Template Name.'
  },
  'admin.soa.modal.soaTemplate.templateName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Template Name is mandatory.'
  },
  'admin.soa.modal.soaTemplate.matterType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Matter Type is mandatory.'
  },
  'contact.status.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Status Certificate # address.',
    ERROR_TOPIC: 'Status Certificate Information'
  },
  'guarantor.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Guarantor # address.',
    ERROR_TOPIC: 'Mortgage'
  },
  'privateLender.#.mailing.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Private Lender # Mailing address.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'privateLender.#.service.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Private Lender # Service address.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'privateLender.#.report.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Private Lender # Report address.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'matter.mainClient.matterContactInfo.preClosingAddress.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Pre Closing Address.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.matterContactInfo.postClosingAddress.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Post Closing Address.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact for <MAIN_CLIENT> #.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient_lastNameRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname is required for <MAIN_CLIENT> #.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.consentingSpouseRequiredON': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Consenting Spouse\'s surname in Family Law Act section is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.consentingSpouseRequiredMB': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Consenting Spouse\'s surname in Homestead Status section is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.otherParty.consentingSpouseRequiredON': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Consenting Spouse\'s surname in Family Law Act section is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.mainClient.consentingSpouseRequiredAB': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Consenting Spouse\'s surname in Dower Status section is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.otherParty.consentingSpouseRequiredAB': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Consenting Spouse\'s surname in Dower status section is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.mainDeveloper.consentingSpouseRequiredAB': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Developer> Consenting Spouse\'s surname in Dower Status section is required.',
    ERROR_TOPIC: 'Developers'
  },
  'matter.mainClient.consentingSpouseRequiredSK': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Consenting Spouse\'s surname in Homestead Status section is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.otherParty.consentingSpouseRequiredSK': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Consenting Spouse\'s surname in Homestead Status section is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.mainClient.spouseNameRequired': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Name of Spouse is missing in selected FLA statement.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.spouseNameRequiredNB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Name of Spouse is missing in selected Marital status statement.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.spouseNameRequiredSKMB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Name of Spouse is missing in selected Homestead Status statement.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.otherParty.spouseNameRequired': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Name of Spouse is missing in selected FLA statement.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.spouseNameRequiredSKMB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Name of Spouse is missing in selected Homestead Status statement.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.mainClient.spouseOwnerNameRequired': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Name of Spouse is missing in selected Homestead Status statement.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.otherParty.spouseOwnerNameRequired': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Name of Spouse is missing in selected Homestead Status statement.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.mainClient.interspousalAgreementSpouseNameRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Spouse\'s surname in Homestead Status section is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.otherParty.interspousalAgreementSpouseNameRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Spouse\'s surname in Homestead Status section is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.matterParticipant.fla.matterParticipantSpouseNotAllowed': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected FLA statement should not apply if there is only one <MAIN_CLIENT_LC> who is an individual.',
    ERROR_TOPIC: '<MAIN_CLIENT>'
  },
  'matter.mainClient.matterParticipantSpouseNotAllowedNB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected Marital Status-Applicable Provision option should not apply if there is only one <MAIN_CLIENT_LC> who is an individual.',
    ERROR_TOPIC: '<MAIN_CLIENT>'
  },
  'matter.matterParticipant.fla.matterParticipantSpouseNotAllowedSKMB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected Homestead Status statement should not apply if there is only one <MAIN_CLIENT_LC> who is an individual.',
    ERROR_TOPIC: '<MAIN_CLIENT>'
  },

  'matter.matterParticipant.fla.interspousalAgreementSpouseNameRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> Spouse\'s surname in Homestead Status section is required.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },

  'matter.matterParticipant.fla.spouseNameRequired': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Name of Spouse is missing in selected FLA statement.',
    ERROR_TOPIC: 'MatterParticipant'
  },
  'matter.matterParticipant.fla.spouseNameRequiredNB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Name of Spouse is missing in selected Marital Status statement.',
    ERROR_TOPIC: 'MatterParticipant'
  },
  'matter.matterParticipant.fla.spouseNameRequiredSKMB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Name of Spouse is missing in selected Homestead Status statement.',
    ERROR_TOPIC: 'MatterParticipant'
  },
  'matter.mainClient.matterParticipantSpouseNotAllowed': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected FLA statement should not apply if there is only one <MAIN_CLIENT_LC> who is an individual.',
    ERROR_TOPIC: '<MAIN_CLIENT>'
  },
  'matter.mainClient.matterParticipantSpouseNotAllowedSKMB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected Homestead Status statement should not apply if there is only one <MAIN_CLIENT_LC> who is an individual.',
    ERROR_TOPIC: '<MAIN_CLIENT>'
  },
  'matter.mainClient.spouseOwnerNotAllowed': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected Homestead Status statement should not apply if there is only one <MAIN_CLIENT_LC> who is an individual.',
    ERROR_TOPIC: '<MAIN_CLIENT>'
  },
  'matter.otherParty.capacity.asTenants': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'There should be at least two <OTHER_PARTY>s for the selected Capacity.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.matterOpening.uniqueMatterNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Another record with the same ID already exists.',
    ERROR_TOPIC: '<TAB_A_TOPIC>'
  },
  'app.ContactNotFound': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Selected contact is no longer visible.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.insuranceBrokerType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Insurer or Broker? is a required field.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.lastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname is required.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.duplicateMailingAddress': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'There can be only one mailing address.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.departmentName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Department Name is required.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.signingOfficer': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Signing officer (*) name is required.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.companyName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name is required.',
    ERROR_TOPIC: 'General Information'
  },
  'contact.managementCompanyName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Management Company name is mandatory.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.uniqueCompanyName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This Contact already exists and cannot be overwritten.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.uniqueSurveyorName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This Surveyor already exists and cannot be overwritten.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Address #.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.service.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Address for Service.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.report.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Address for Report.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.statusCert.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name is required',
    ERROR_TOPIC: 'Status Certificate Information'
  },
  'contact.provinceCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Province identified for the Condominium Corporation does not match the Province of the Matter.',
    ERROR_TOPIC: 'Condo Corporation'
  },
  'jurisdiction.jurisdictionName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The <FIELD_LABEL> field must be completed.',
    ERROR_TOPIC: 'General'
  },
  'jurisdiction.communityName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Community field must be completed.',
    ERROR_TOPIC: 'General'
  },
  'otherParty.service.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Address for Service.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'vendor.mailing.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for address.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.matterParticipantSpouseNotAllowed': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected FLA statement should not apply if there is only one <OTHER_PARTY_LC> who is an individual.',
    ERROR_TOPIC: '<OTHER_PARTY>'
  },
  'matter.otherParty.matterParticipantSpouseNotAllowedSKMB': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected Homestead Status statement should not apply if there is only one <OTHER_PARTY_LC> who is an individual.',
    ERROR_TOPIC: '<OTHER_PARTY>'
  },
  'matter.otherParty.spouseOwnerNotAllowed': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected Homestead Status statement should not apply if there is only one <OTHER_PARTY_LC> who is an individual.',
    ERROR_TOPIC: '<OTHER_PARTY>'
  },

  'matter.otherParty_consentingSpouseRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Consenting Spouse\'s surname in Family Law Act1 section is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty_consentingSpouseRequiredSK': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<OTHER_PARTY> #> Spouse\'s surname in Homestead section is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.lawFirm.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for law firm Address.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.solicitor.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for solicitor Address.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact for <OTHER_PARTY> #.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'business.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Business Address.',
    ERROR_TOPIC: 'ID Details'
  },
  'spouse.business.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Consenting Spouse Business address.',
    ERROR_TOPIC: 'ID Details'
  },
  'modal.undertaking.followupDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Follow-up Date is invalid.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'modal.undertaking.dateOfInstrument': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date of Instrument is invalid.',
    ERROR_TOPIC: 'Undertakings'
  },
  'modal.undertaking.dateOfInstrument.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date of Instrument is incomplete.',
    ERROR_TOPIC: 'Undertakings'
  },
  'modal.undertaking.dateOfRegistration': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registration Date is invalid.',
    ERROR_TOPIC: 'Undertakings'
  },
  'modal.simulatedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Output Date is incomplete.',
    ERROR_TOPIC: 'Custom DP Date Codes'
  },
  'modal.simulatedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Output Date is invalid.',
    ERROR_TOPIC: 'Custom DP Date Codes'
  },
  'modal.closingDateFrom.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing Date is incomplete.',
    ERROR_TOPIC: 'Closing Date'
  },
  'modal.closingDateRange.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing Date Range To is incomplete.',
    ERROR_TOPIC: 'Closing Date'
  },
  'modal.undertaking.followupDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Follow-up Date is incomplete.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'modal.undertaking.registeredDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registered on Date is invalid.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'modal.closingDatePayment.lateClosingDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Delayed Closing Date is invalid.',
    ERROR_TOPIC: 'Late Closing Date'
  },
  'modal.closingDatePayment.lateClosingDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Delayed Closing Date is incomplete.',
    ERROR_TOPIC: 'Late Closing Date'
  },
  'modal.lateClosingInterest.fromDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Effective from Date is invalid.',
    ERROR_TOPIC: 'Effective from Date'
  },
  'modal.lateClosingInterest.fromDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Effective from Date is incomplete.',
    ERROR_TOPIC: 'Effective from Date'
  },
  'tabL.undertaking.registeredDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registered on Date is incomplete.',
    ERROR_TOPIC: '<EXT_MTG_IDX_ORDINAL> Existing Mortgage'
  },
  'tabL.undertaking.registeredDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registered on Date is invalid.',
    ERROR_TOPIC: '<EXT_MTG_IDX_ORDINAL> Existing Mortgage'
  },
  'modal.undertaking.registeredDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registered on Date is incomplete.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },

  'undertaking.followupDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Follow-up Date # is invalid.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'undertaking.followupDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Follow-up Date # is incomplete.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'undertaking.registeredDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registered on Date # is invalid.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'undertaking.registeredDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Registered on Date # is incomplete.',
    ERROR_TOPIC: '<TAB_L_TOPIC>'
  },
  'matter.propertyTeranet.depositDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Deposit Date # is invalid.',
    ERROR_TOPIC: 'Adjustment Deposit'
  },
  'matter.propertyTeranet.depositDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Deposit Date # is incomplete.',
    ERROR_TOPIC: 'Adjustment Deposit'
  },
  'matter.propertyTeranet.noCredentials': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In order to proceed, Teranet Connect credentials must be entered under \'My credentials\'. '
    //ERROR_TOPIC: "Adjustment Deposit",
  },
  'teranet.changePassword.passwordNotMatch': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Re-entered password must match New Password.'

  },
  'teranet.createDocket.docketIdAndName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In order to proceed with docket creation, both the Docket ID and Docket Name fields must be completed.'

  },
  'idVerification.enteredOn': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'On date is invalid.',
    ERROR_TOPIC: 'ID Verification'
  },
  'idVerification.enteredOn.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'On date is incomplete.',
    ERROR_TOPIC: 'ID Verification'
  },
  'contact.id-details.idVerification.enteredOn': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'On date is invalid.',
    ERROR_TOPIC: 'ID Details'
  },
  'contact.id-details.idVerification.enteredOn.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'On date is incomplete.',
    ERROR_TOPIC: 'ID Details'
  },
  'idVerification.dob': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is invalid.',
    ERROR_TOPIC: 'ID Verification'
  },
  'idVerification.dob.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is incomplete.',
    ERROR_TOPIC: 'ID Verification'
  },
  'idVerification.expiryDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> ID Verification > Expiry date is invalid.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'idVerification.expiryDate_INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<MAIN_CLIENT> #> ID Verification > Expiry date is incomplete.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'idrecord.birthDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is invalid.',
    ERROR_TOPIC: 'ID Verification'
  },
  'idrecord.birthDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is incomplete.',
    ERROR_TOPIC: 'ID Verification'
  },
  'contact.id-details.idrecord.birthDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is invalid.',
    ERROR_TOPIC: 'ID Details'
  },
  'contact.id-details.idrecord.birthDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is incomplete.',
    ERROR_TOPIC: 'ID Details'
  },
  'property.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for address.',
    ERROR_TOPIC: '<TOPIC_LABEL>'
  },
  'property.holdbacks.substantialCompletionDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> is incomplete',
    ERROR_TOPIC: 'Property'
  },
  'property.holdbacks.substantialCompletionDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> is invalid',
    ERROR_TOPIC: 'Property'
  },
  'property.holdbacks.releasedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Released Date is incomplete',
    ERROR_TOPIC: 'Property'
  },
  'property.holdbacks.releasedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Released Date is invalid',
    ERROR_TOPIC: 'Property'
  },
  'mortgage.#.serviceAddress.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Address for Service.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'mortgage.#.reportAddress.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Address for Report.',
    ERROR_TOPIC: '#s Mortgage'
  },
  'mortgage.#.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for address.',
    ERROR_TOPIC: '#s Mortgage'
  },
  // 1 st Mortgage Lawyer Data > Borrower(s) and Guarantor(s) not reconciled
  'mortgage.#.lawyerData.borrowerAndGuarantorNotReconciled': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Borrower(s) and Guarantor(s) not reconciled',
    ERROR_TOPIC: 'Mortgages - #s Mortgage Lawyer Data'
  },
  'contact.initials': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Initials is required.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.transitNo.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Transit No is required.',
    ERROR_TOPIC: 'Contact'
  },
  'contact.transitNo_INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Transit No. cannot be 00000',
    ERROR_TOPIC: 'Contact'
  },
  'contact.initials.duplicate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Initials already exist.',
    ERROR_TOPIC: 'Contact'
  },
  'matter.mortgages.1st.mortgagee.terms.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact for Guarantor #.',
    ERROR_TOPIC: 'Mortgages'
  },
  'project.docsReg.transferor.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact for Transferor #.',
    ERROR_TOPIC: 'Documents & Registration'
  },
  'matter.mortgage(s).privateLender.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact for Private Lender.',
    ERROR_TOPIC: 'Mortgages'
  },
  'matter.otherParty_solicitor.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact for <OTHER_PARTY>.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'matter.otherParty.capacity.registeredOwner': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected capacity is applicable only for Single <OTHER_PARTY>.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'contact.profile.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact.',
    ERROR_TOPIC: 'Profile'
  },
  'matter.search.closingDate.from': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing from date is invalid.',
    ERROR_TOPIC: 'Matter Search'
  },
  'matter.search.closingDate.to': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing to date is invalid.',
    ERROR_TOPIC: 'Matter Search'
  },
  'contact.profile_lastNameRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname is required.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.profile.birthDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is invalid.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.profile.birthDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birthdate is incomplete.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.residenceAssociation.fiscalYearEnd': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Fiscal Year End is invalid.',
    ERROR_TOPIC: 'ResidenceAssociation'
  },
  'contact.profile.poaDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'PoA date is invalid.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.profile.section13ReserveFundStudyDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Section 13 Reserve Fund Study Date is invalid.',
    ERROR_TOPIC: 'Status Certificate Information'
  },
  'contact.profile.section13ReserveFundStudyDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Section 13 Reserve Fund Study Date is incomplete.',
    ERROR_TOPIC: 'Status Certificate Information'
  },
  'contact.profile.enterDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Enter Date is invalid.',
    ERROR_TOPIC: 'Profile'
  },
  'contact.general-information.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record.  Please create another contact.',
    ERROR_TOPIC: 'General Information'
  },
  'matter.mainClient.offeror.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record. As appropriate, please remove and recreate Offeror.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.signingOfficer.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record. As appropriate, please remove and recreate signing officer.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.capacity.asTenants': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'There should be at least two <MAIN_CLIENT>s for the selected Capacity.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.capacity.registeredOwner': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected capacity is applicable only for Single <MAIN_CLIENT>.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.tenure.asTenants': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'There should be at least two <MAIN_CLIENT>s for the selected Tenure.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainClient.tenure.registeredOwner': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected tenure is applicable only for Single <MAIN_CLIENT>.',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'matter.mainSideParticipant.tenure.asTenants': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'There should be at least two developers for the selected Tenure.',
    ERROR_TOPIC: 'Developers'
  },
  'matter.mainSideParticipant.tenure.registeredOwner': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Selected tenure is applicable only for Single developer.',
    ERROR_TOPIC: 'Developers'
  },
  'admin.staffProfile.businessRole': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Business Role is required.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffProfile.lastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname is required.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffProfile.firstName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'First Name is required.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffProfile.email.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffProfile.email.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Email Address is required.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffProfile.brokerageIntegrationsEmail.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Brokerage Integrations Email is invalid.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffProfile.initials': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Initials are required.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'caslInfo.caslRequestedById.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'CASL Requested By is required.',
    ERROR_TOPIC: 'CASL Info'
  },
  'contact.firmSolicitor.lawFirm.firmName.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '<FIELD_LABEL> is required.',
    ERROR_TOPIC: '<FIELD_LABEL>'
  },
  'contact.firmSolicitor.solicitor.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Email Format.',
    ERROR_TOPIC: 'Solicitor'
  },
  'contact.firmSolicitor.solicitor.lastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Surname is required for Solicitor.',
    ERROR_TOPIC: 'Solicitor'
  },
  'contact.firmSolicitor.solicitor.unique': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Another Solicitor with the same name and address as Solicitor # already exists in the law firm.',
    ERROR_TOPIC: 'Solicitor'
  },
  'contact.lawclerk.unique': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This Law Clerk already exists and cannot be overwritten.',
    ERROR_TOPIC: 'Law Clerk'
  },
  'contact.firmSolicitor.lawFirm.firmName.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This Law Firm already exists and cannot be overwritten.',
    ERROR_TOPIC: 'Law Firm'
  },
  'account.publicAccountId.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Account Id is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.publicAccountId.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This Account already exists.',
    ERROR_TOPIC: 'Account'
  },
  'account.accountTypeCode.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Account Type is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.receiveReferrals.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Receives Referrals is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.accountTypeChange.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Account Type change is not possible since at least one of the Staff Profile users is attached to a matter.',
    ERROR_TOPIC: 'Account'
  },
  'account.provinceChange.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Account Accessible province change is not permitted since the Province is attached to at least one Staff Profile.',
    ERROR_TOPIC: 'Account'
  },
  'account.trialExpiryDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Trial Expiry Date is invalid.',
    ERROR_TOPIC: 'Account'
  },
  'massUpdate.adjustmentDate.dateToAdjust': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date is invalid.',
    ERROR_TOPIC: 'Mass Update'
  },
  'massUpdate.adjustmentDate.dateToAdjust.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date is incomplete.',
    ERROR_TOPIC: 'Mass Update'
  },
  'accessProfileName.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Access Profile Name already exists.',
    ERROR_TOPIC: 'Access Rights Profile'
  },
  'accessProfileName.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Access Profile Name is required.',
    ERROR_TOPIC: 'Access Rights Profile'
  },
  'user.profile.id': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'User ID is required.',
    ERROR_TOPIC: 'Add New User'
  },
  'user.profile.accessProfileId': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Access Rights Profile is required.',
    ERROR_TOPIC: 'Add New User'
  },
  'user.profile.contactId': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'User Profile is required.',
    ERROR_TOPIC: 'Add New User'
  },
  'user.profile.userType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'User Type is required.',
    ERROR_TOPIC: 'Add New User'
  },
  'app.noAccessProfileType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'User Type is required.',
    ERROR_TOPIC: 'Access Rights Profile'
  },
  'account.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Account Name is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.customerAccountStatus': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Account Status is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.firmName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Firm Name is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.workPhone': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Work Phone is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.primaryContact.lastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Primary Contact Surname is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.billingContact.lastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing Contact Surname is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.primaryContact.email.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Primary Contact Email is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.primaryContact.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Primary Contact Email is invalid.',
    ERROR_TOPIC: 'Account'
  },
  'matter.matterOpening.agentEmail': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Agent Email is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'account.billingContact.email.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing Contact Email is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.billingContact.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Billing Contact Email is invalid.',
    ERROR_TOPIC: 'Account'
  },
  'account.MAILING.street': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Street Address is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.MAILING.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'City is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.defaultProvinceCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.documentProcessorType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Document types to be used in the account is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.paymentMethodType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Payment Method is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.disableMatterEditAfterClosingDays': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '# Days in the Past Before Closing Date Locks Matter is required.',
    ERROR_TOPIC: 'Account'
  },

  'account.MAILING.provinceName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.MAILING.country': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Country is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.MAILING.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Postal Code is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.MAILING.postalCode.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Mailing Postal Code is invalid.',
    ERROR_TOPIC: 'Account'
  },
  'account.BILLING.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing Postal Code is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.BILLING.postalCode.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Billing Postal Code is invalid.',
    ERROR_TOPIC: 'Account'
  },
  'account.BILLING.street': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing Street Address is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.BILLING.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing City is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.BILLING.provinceName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing Province is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.BILLING.country': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Billing Country is required.',
    ERROR_TOPIC: 'Account'
  },
  'account.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: 'Account'
  },
  'documentProfile.templateFolder.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In order to proceed, a template folder name must be entered.',
    ERROR_TOPIC: 'Document Profile'
  },
  'documentProfile.templateFolder.duplicatedName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A template with the same name already exists. Please enter a different name.',
    ERROR_TOPIC: 'Document Profile'
  },
  'client.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: 'Profile'
  },
  'solicitor.contact.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.brokerageIntegrationsEmail.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Brokerage Integrations Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'offeror.#.person.contac.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Offeror Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.officer2.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Signing Officer\'s (#2) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.attorney1.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Attorney\'s (#1) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.attorney2.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Attorney\'s (#2) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.executor1.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Executor\'s (#1) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.executor2.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Executor\'s (#2) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.administrator1.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Administrator\'s (#1) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.administrator2.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Administrator\'s (#2) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.personalrep1.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Personal Rep\'s (#1) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.personalrep2.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Personal Rep\'s (#2) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.estatetrustee1.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Estate Trustee\'s (#1) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'solicitor.contact.estatetrustee2.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Estate Trustee\'s (#2) Email is invalid.',
    ERROR_TOPIC: 'Contact'
  },

  'solicitor.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: '<FIELD_LABEL>\'s Email is invalid.',
    ERROR_TOPIC: '<SOLICITOR_NAME>'
  },
  'lawclerk.email.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Law Clerk Email is invalid.',
    ERROR_TOPIC: 'Vendors & Solicitor'
  },
  'privateContact.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email for Private Contact # is invalid.',
    ERROR_TOPIC: 'Contact'
  },
  'privateLender.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email for Private Contact # is invalid.',
    ERROR_TOPIC: 'Mortgage'
  },
  'solicitorContact.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email for <FIELD_LABEL> Contact is invalid.',
    ERROR_TOPIC: 'Solicitors'
  },
  'solicitorContact.brokerageIntegrationsEmail': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Brokerage Integrations Email for <FIELD_LABEL> Contact is invalid.',
    ERROR_TOPIC: 'Solicitors'
  },
  'contactInfo.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.',
    ERROR_TOPIC: 'Mortgage'
  },
  'numberId1': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Unity strongly discourages the entry of any credit card details or other highly confidential information into the system.',
    ERROR_TOPIC: 'Mortgage'
  },
  'numberId2': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Unity strongly discourages the entry of any credit card details or other highly confidential information into the system.',
    ERROR_TOPIC: 'Mortgage'
  },
  'client_numberId1': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Unity strongly discourages the entry of any credit card details or other highly confidential information into the system.',
    ERROR_TOPIC: 'ID Details'
  },
  'client_numberId2': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Unity strongly discourages the entry of any credit card details or other highly confidential information into the system.',
    ERROR_TOPIC: 'ID Details'
  },
  'spouse.client_numberId1': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Unity strongly discourages the entry of any credit card details or other highly confidential information into the system.',
    ERROR_TOPIC: 'ID Details'
  },
  'spouse.client_numberId2': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Unity strongly discourages the entry of any credit card details or other highly confidential information into the system.',
    ERROR_TOPIC: 'ID Details'
  },
  'guarantor.address': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for address.',
    ERROR_TOPIC: 'Mortgage'
  },
  'condoCorporation.mailingAddress.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Mailing Address.',
    ERROR_TOPIC: 'Condo Corporation'
  },
  'condoCorporation.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Email Format.',
    ERROR_TOPIC: 'Condo Corporation'
  },
  'user.oldPassword': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Current Password is Mandatory.'
  },
  'user.oldPassword.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Current Password is incorrect, please try again.'
  },
  'user.password.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'New Password is Mandatory.'
  },
  'user.password.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'New Password is Invalid.'
  },
  'user.retypePassword.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Re-enter Password is Mandatory.'
  },
  'user.retypePassword.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Re-entered password must match New Password.'
  },
  'user.password.used': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Unable to update the password. You cannot reuse one of your last 5 passwords.'
  },
  'staffProfile.contact.email.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Email Address is required.'
  },
  'staffProfile.contact.email.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invalid Email Address.'
  },
  'user.id': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'User ID is required.'
  },
  'verifyPassword.password': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Current Password is required.'
  },
  'verifyPassword.password.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invalid Password.'
  },
  'user.newPhone': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invalid Phone Number - must be 10 digits including area code.'
  },
  'user.newPhone.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A Trusted Phone Number is required. Please add a new cell phone before deleting the existing one.'
  },
  'user.passcode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Passcode is required.'
  },
  'user.passcode.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Passcode has expired please request another.'
  },
  'user.passcode.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The entered Passcode is incorrect, please try again or select Send Passcode to request another.'
  },
  'verifyPasscode.passcode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Passcode is required.'
  },
  'existingMortgage.#.mortgageRegDate.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Related Inst. Reg. Date # is invalid.',
    ERROR_TOPIC: '#s Existing Mortgage '
  },
  'existingMortgage.#.registrationDate.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '# Related Inst. Reg. Date is invalid.',
    ERROR_TOPIC: '#s Existing Mortgage '
  },
  'mortgage.#.mortgagee.privateLender.genderChangeProhibited': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender is not permitted for an already saved record. Please create another contact for Private Lender #.',
    ERROR_TOPIC: '#s Mortgage Mortgagee'
  },
  'mortgage.#.mortgagee.privateLender.surname': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name for Private Lender # is Required.',
    ERROR_TOPIC: '#s Mortgage > Mortgagee'
  },
  'mortgage.#.term.amortization': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Regular Payment cannot be calculated until the Payment amortization period field has been completed',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.alternate.amortization': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Alternate Regular Payment cannot be calculated until the Alternate Frequency amortization period field has been completed',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.paymentFrequency.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Regular Payment cannot be calculated until the Payment Frequency field has been completed',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.paymentFrequency.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Regular Payment cannot be calculated until one of the provided Payment Frequency options has been chosen',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.alternate.paymentFrequency.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Alternate Regular Payment cannot be calculated until the Alternate Payment Frequency field has been completed',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.alternate.paymentFrequency.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Alternate Regular Payment cannot be calculated until one of the provided Alternate Payment Frequency options has been chosen',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.compundingPeriod': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Alternate Regular Payment and Regular Payment cannot be calculated until the Calculated field has been completed',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.paymentFrequencyOption': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Regular Payment cannot be calculated until one of the provided Payment Frequency options has been chosen',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.term.netAdvance.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Net advance cannot be negative',
    ERROR_TOPIC: '#s Mortgage Terms'
  },
  'mortgage.#.mortgagee.privateLender.mailing.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid postal code for address',
    ERROR_TOPIC: '#s Mortgage'
  },
  'contact.attention.gender': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This change in gender of Attention # is not permitted for an already saved record.  Please create another contact for Contact.',
    ERROR_TOPIC: 'My Contacts'
  },
  'matter.mainClientReport.transferRegistrationDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Transfer Registration Date is invalid.',
    ERROR_TOPIC: 'Report to <MAIN_TOPIC>'
  },
  'matter.requisition.requisitionDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Requisition Date is invalid.',
    ERROR_TOPIC: 'Requisition'
  },
  'matter.requisitionResponse.requisitionLetterDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Date of Requisition letter is invalid.',
    ERROR_TOPIC: 'Requisition Response'
  },
  'matter.requisitionResponse.requisitionDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Requisition Date is invalid.',
    ERROR_TOPIC: 'Requisition Response'
  },
  'matter.mainClientReport.commonExpensesPaymentDue': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Common Expenses - 1st Payment Due Date invalid.',
    ERROR_TOPIC: 'Report to <MAIN_TOPIC>'
  },
  'matter.trustLedger.negativeTotalAmount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The item\'s total is negative. Please adjust the itemized details.'
  },
  'matter.propertyTeranet.deposit': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The credit to <MAIN_CLIENT> cannot be less than zero.'
  },
  'matter.consideration.creditVendor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Credit to vendor cannot be less than zero.'
  },
  'supplementalTaskCategory.categoryName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Category Name is required.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'supplementalTaskCategory.userDefinedField': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Field Label is required for User-defined Field.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'supplementalTaskCategory.preDefinedField': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Field Label is required for Pre-defined Field.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'supplementalTaskCategory.categoryFields': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'At least 1 Pre-defined or User-defined Field is required.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'supplementalTaskCategory.appliesTo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'At least 1 of the option for "Task Used in" field should be selected.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'supplementalTaskCategory.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'This Extended Workflow Category already exists.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'supplementalTaskCategory.tickleGroupSubFields': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Field label is required for.',
    ERROR_TOPIC: 'Extended Workflows Category'
  },
  'mortgage.terms.amortization.schedule': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Maturity Date in the Mortgage tab must be completed in order to display the Amortization schedule'
  },
  'matter.supplementalTask.dateField.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: '# is incomplete.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'mortgage.terms.amortization.payments': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Number of Payments needs to be greater than zero'
  },
  'mortgage.terms.amortizationPeriod': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Amortization Period needs to be greater than zero'
  },
  'soa.commonExpenseAmount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The value for Amount of Common Expenses must be greater than 0.'
  },
  'soa.commonExpenseAmount.occupancy': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The value for Amount of Occupancy Fees must be greater than 0.'
  },
  'soa.adjustPercentage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The value for Amount of percentage must be between 0 and 100.'
  },
  'soa.tax.other.heading': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Heading Adjustment is required.'
  },
  'soa.tax.other.cost': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Cost of the item must be greater than 0.'
  },
  'soa.tax.totalTaxes': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The tax amount must be greater than 0.'
  },
  'matter.soa.soawater.currentwaterbill': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The water bill amount must be greater than $0.00'
  },
  'admin.manageAccessGroup.accessGroupName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Access Group name is required.'
  },
  'admin.manageAccessGroup.accessGroupName.DUPLICATE': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Access Group name must be unique. Please review and correct your entries.'
  },
  'matter.soa.soaheat.capacity': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The value for Tank Capacity must be greater than 0'
  },
  'matter.soa.soaheat.capacity.max': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The value for Tank Capacity cannot be greater than 99,999.99'
  },
  'matter.soa.soaheat.costper': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The value for Cost must be greater than 0'
  },
  'matter.soadj.other.fixed.description': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The description of the item must be entered'
  },
  'matter.soadj.component.description': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The description of the item must be entered'
  },
  'matter.soadj.component.template.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The name must be entered'
  },
  'matter.soadj.component.retrieve.template': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Please select a precedent'
  },
  'matter.soadj.other.fixed.adj.amount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Adjustment amount must be greater than $0.00'
  },
  'matter.soadj.other.fixed.adj.hst.rebate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Adjustment amount must be greater than $0.00'
  },
  'matter.soadj.other.fixed.amount.additional.consideration.to.vendor.include.hst': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Unable to show adjustment as additional consideration to vendor because Additional Consideration field in Sale Price adjustment already contains data which has been entered manually'
  },
  'matter.soadj.other.prorated.heading': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The heading must be entered'
  },
  'matter.soadj.consideration.paid.on.interim.closing.heading': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The heading must be entered'
  },
  'matter.soadj.amount.paid.on.interim.closing.heading': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The heading must be entered'
  },
  'matter.soadj.other.prorated.amount.prorated': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Adjustment amount must be greater than $0.00'
  },
  'matter.soadj.other.prorated.commencement.date.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The commencement date is invalid'
  },
  'matter.soadj.other.prorated.expiry.date.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date is invalid'
  },
  'matter.soadj.other.prorated.commencement.date.greater.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be less than Adjustment date'
  },
  'matter.soadj.other.prorated.expiry.date.less.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date must be greater than or equal to the Adjustment date'
  },
  'matter.soadj.other.prorated.commencement.date.greater.expiry': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date must be greater than the Commencement date'
  },

  'matter.soadj.tenancy.current.rental.amount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Rental amount must be greater than $0.00'
  },
  'matter.soadj.tenancy.current.commencement.date.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The commencement date is invalid'
  },
  'matter.soadj.tenancy.current.end.date.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date is invalid'
  },
  'matter.soadj.tenancy.current.commencement.date.greater.end': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The End Date must be greater than the Adjustment Date'
  },
  'matter.soadj.tenancy.current.commencement.date.greater.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be Less than adjustment date'
  },
  'matter.soadj.tenancy.prepaid.from.date': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The commencement date is invalid',
    ERROR_TOPIC: 'General Info'
  },
  'matter.soadj.tenancy.prepaid.from.date.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The commencement date is invalid.',
    ERROR_TOPIC: 'General Info'
  },
  'matter.soadj.tenancy.prepaid.from.date.greater.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be Less than the Adjustment date'
  },

  'matter.soadj.assumed.mortgage.mortgage.priority.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' A priority for the assumed mortgage must be selected'
  },
  'matter.soadj.assumed.mortgage.principal.date.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date is invalid'
  },
  'matter.soadj.assumed.mortgage.principal.date.greater.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be less than the Adjustment date'
  },
  'matter.soadj.assumed.mortgage.principal.amount.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Principal amount must be greater than $0.00'
  },
  'matter.soadj.assumed.mortgage.interest.amount.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Interest Amount must be greater than $0.00'
  },
  'matter.soadj.assumed.mortgage.interest.rate.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Interest Rate must be greater than $0.00'
  },

  'matter.soadj.tarion.warranty.amount.additional.consideration.to.vendor.sale.price': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The \'Yes (eligible for <HST> Rebate)\' response in the \'Additional Consideration\' field is not permitted unless the Sale Price adjustment is set to \'Factor in <HST> Rebate\''
  },
  'matter.soadj.tarion.warranty.amount.additional.consideration.to.vendor.include.hst': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Unable to show adjustment as additional consideration to vendor because Additional Consideration field in Sale Price adjustment already contains data which has been entered manually'
  },
  'matter.soadj.vtb.mortgage.heading.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Adjustment heading must be entered'
  },
  'matter.soadj.final.occupancy.fee.paidOn.date.empty': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The occupancy fees paid to date must not be later than the last day of the month of the Adjustment date'
  },
  'matter.soadj.final.occupancy.fee.paidOn.date.greater.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The occupancy fees paid to date must not be later than the last day of the month of the Adjustment date'
  },
  'matter.soadj.expense.occupancy.period.from.date': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Above total in effect from is invalid.'
  },
  'matter.soadj.expense.occupancy.period.from.date.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Above total in effect from is incomplete.'
  },
  'matter.soadj.expense.occupancy.period.till.date': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Above total in effect to is invalid.'
  },
  'matter.soadj.expense.occupancy.period.till.date.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Above total in effect to is incomplete.'
  },
  'teranet.searchParcelRegister.missingLastName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Last name must contain a minimum of 2 characters'
  },
  'teranet.searchParcelRegister.invalidPin': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The PIN Number is invalid.  The above PIN must be completed in 12345-6789 format.'
  },
  'teranet.thirdParty.property.importSK': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Unable to import the property details from ISC as the condominium plan you wish to import does not match the condominium plan contained in the matter.',
    ERROR_TOPIC: 'ISC'
  },
  'teranet.searchParcelRegister.missingStreetNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The address must contain a street number with a minimum of 2 characters'
  },
  'teranet.searchParcelRegister.missingStreetName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The address must contain a street name with a minimum of 2 characters'
  },
  'teranet.searchParcelRegister.missingInstrument': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Instrument No. is required'
  },
  'teranet.searchParcelRegister.missingLRO': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'LRO required'
  },
  'teranet.courierInstrument.missingAttention': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Attention must be completed.'
  },
  'teranet.courierInstrument.numberInstruments': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The total number of instruments and additional copies cannot be more than 10.'
  },
  'teranet.courierInstrument.address': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Delivery address must be completed in full.'
  },
  'billing.warningLevel.otherAmount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Other amount is required'
  },
  'matter.soa.taxotherfactor.adjustheader': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A heading must be entered.'
  },
  'matter.propertyTeranet.noOfUnits': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In order to proceed, the No. of Units must be selected',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'matter.propertyTeranet.noOfRooms': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In order to proceed, the No. of Rooms must be selected',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'billing.purchaseCredits.otherAmount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Other amount is required'
  },
  'billing.purchaseCredits.authorizedBy': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Authorized by is required'
  },
  'courier.address.street': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Street Address is required.'
  },
  'courier.address.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'City is required.'
  },
  'courier.address.provinceName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required.'
  },
  'courier.address.country': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Country is required.'
  },
  'courier.address.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Postal Code is required.'
  },
  'courier.address.postalCode.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Postal Code is invalid.'
  },
  'matter.statementAdjustment.interestFromDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commencement date is invalid.'
  },
  'matter.statementAdjustment.interestFromDate.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commencement date is required.'
  },
  'matter.statementAdjustment.interestToDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Termination  date is invalid.'
  },
  'matter.statementAdjustment.interestToDate.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Termination  date is required.'
  },
  'matter.statementAdjustment.fromToDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be less than termination date.'
  },
  'directDeposit.transitNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'No matching Transit No. found'
  },
  'directDeposit.bankNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'No matching Institution No. found'
  },
  'mortgage.mortgagePayout.interestDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Inclusive of interest to Date is invalid.'
  },
  'mortgage.mortgagePayout.interestDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Inclusive of interest to - Date is incomplete.',
    ERROR_TOPIC: 'Closing Date'
  },
  'mortgage.mortgagePayout.principalAt': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Principal as at Date is invalid.'
  },
  'mortgage.mortgagePayout.principalTo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Principal to Date is invalid.'
  },
  'writ.name.search.name.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The name is required to proceed.'
  },
  'writ.name.search.givenName.MoreThanThreeWords': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'No more than 3 Given Names are allowed for a Writ Name Search.'
  },
  'writ.name.search.eo.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The enforcement office is required to proceed.'
  },

  'matter.ereg.pin': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The PIN No is invalid/incomplete in Property / Teranet',
    ERROR_TOPIC: 'Property'
  },
  'matter.ereg.mortgageRegNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Existing mortgage registration no. has not been specified in Existing Mortgage(s)',
    ERROR_TOPIC: 'Existing Mortgages'
  },
  'matter.ereg.assignmentInstrumentNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Assignment instrument number has not been specified in Source Documents',
    ERROR_TOPIC: 'Existing Mortgages'
  },
  'matter.ereg.existingMortgage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of existing Mortgagee has not been specified in Existing Mortgage(s)',
    ERROR_TOPIC: 'Existing Mortgages'
  },
  'matter.ereg.existingMortgagesAddress': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Address for service has not been specified in Existing Mortgage(s)',
    ERROR_TOPIC: 'Existing Mortgages'
  },
  'matter.ereg.ltt': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Consideration for Transfer has not been specified in Consideration/LTT',
    ERROR_TOPIC: 'Consideration/LTT'
  },
  'matter.ereg.soa': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Sale price has not been specified in Statement of Adjustments',
    ERROR_TOPIC: 'Statement Of Adjustment'
  },
  'matter.ereg.soa.consideration': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Sale price has not been specified in E-Reg Consideration',
    ERROR_TOPIC: 'Statement Of Adjustment'
  },
  'matter.ereg.vendor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of Vendor has not been specified in Vendors & Solicitor',
    ERROR_TOPIC: 'Vendors & Solicitor'
  },
  'matter.ereg.vendor.address': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Vendor address for service has not been specified in Vendors & Solicitor',
    ERROR_TOPIC: 'Vendors & Solicitor'
  },
  'matter.ereg.purchaser.address': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Purchaser address for service has not been specified in Purchaser & Solicitor',
    ERROR_TOPIC: 'Purchaser & Solicitor'
  },
  'matter.ereg.Purchaser.purchase': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of Purchaser has not been specified in Purchasers',
    ERROR_TOPIC: 'Purchasers'
  },
  'matter.ereg.Purchaser.sale': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of Purchaser has not been specified in Purchasers & Solicitor',
    ERROR_TOPIC: 'Purchasers & Solicitor'
  },
  'matter.ereg.Purchaser.mortgage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of Mortgagor has not been specified in Mortgagors',
    ERROR_TOPIC: 'Mortgagors'
  },
  'matter.ereg.Purchaser.postClosingAddress': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Post Closing address has not been specified in Purchasers',
    ERROR_TOPIC: 'Purchaser'
  },
  'matter.ereg.purchaser.address.mortgage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Address for service has not been specified in Mortgagors',
    ERROR_TOPIC: 'Mortgagors'
  },
  'matter.ereg.purchaser.address.sale': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Address for service has not been specified in Purchasers & Solicitor',
    ERROR_TOPIC: 'Purchasers & Solicitor'
  },
  'matter.ereg.fairMarket': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Teraview does not permit the import of Fair Market Value specified in Consideration/LTT',
    ERROR_TOPIC: 'Consideration/LTT'
  },
  'matter.considerationLtt.modal.newSalePrice': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In Consideration & LTT the total of your values from "a" through "e" cannot be greater than the value in "f".',
    ERROR_TOPIC: 'Consideration/LTT'
  },
  'matter.considerationLtt.modal.newSalePriceSOA': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In Consideration & LTT the total of your values from "a" through "e" cannot be greater than the value in "f".',
    ERROR_TOPIC: 'Consideration/LTT'
  },
  'matter.soa.modal.salePriceSOA': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'In Consideration & LTT the total of your values from "a" through "e" cannot be greater than the value in "f".'
  },
  'matter.creditVendorTaxRebate.modal.adjustmentHeading': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Adjustment heading is required.'
  },
  'matter.ereg.documentName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Document name has not been specified in Document Identification'

  },
  'matter.ereg.instrument': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Instrument number has not been specified in source documents'

  },
  'teranet.searchWrit.invalidWrit': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Writ No. must be completed in the format xx-xxxxxxx and 10 characters in length. The last 7 digits must be numbers only.'
  },
  'considerationLtt.moniesPaidOrToBePaidIn': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The total of your values from "a" through "e" cannot be greater than the value in "f".',
    ERROR_TOPIC: 'Consideration/LTT'
  },
  'matter.teranetConnect.viewDocketSummary.startDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Start Date is missing'
  },
  'matter.teranetConnect.viewDocketSummary.endDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'End Date is missing'
  },
  'matter.teranetConnect.viewDocketSummary.otherDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Start Date can not be after the End Date'
  },
  'matter.teranetConnect.viewDocketSummary.otherDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date range selected must not be greater than 364 days'
  },
  'matter.ereg.principal.purchase': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Principal amount not entered in Mortgages',
    ERROR_TOPIC: 'Mortgages'
  },
  'matter.ereg.principal.sale': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Principal amount not entered in VTB Mortgage(s)',
    ERROR_TOPIC: 'VTB Mortgages'
  },
  'matter.ereg.principal.mortgage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Principal amount not entered in New Mortgages',
    ERROR_TOPIC: 'New Mortgages'
  },
  'matter.ereg.principal.project': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Principal amount not entered in Purchaser\'s Mortgage(s)',
    ERROR_TOPIC: 'Purchaser(s) Mortgages'
  },
  'matter.ereg.mortgage.purchase': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of mortgagee has not been specified in Mortgages',
    ERROR_TOPIC: 'Mortgages'
  },
  'matter.ereg.mortgage.sale': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of mortgagee has not been specified in VTB Mortgage(s)',
    ERROR_TOPIC: 'Mortgages'
  },
  'matter.ereg.mortgage.mortgage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of mortgagee has not been specified in New Mortgages',
    ERROR_TOPIC: 'Mortgages'
  },
  'matter.ereg.mortgage.project': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name of mortgagee has not been specified in Purchaser\'s Mortgage(s)',
    ERROR_TOPIC: 'Mortgages'
  },
  'admin.notes.addJournalNote': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Note cannot be empty'
  },
  'integrations.stewartTitle.closingDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing Date is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.discharge.stewartTitle.closingDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Completion Date is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.stewartTitle.possessionDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Possession Date is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.stewartTitle.propertyType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Title Insurance Property Type is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.purchasePrice': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Purchase Price is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.homeOwnerPolicyAmount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Home Owner Policy Amount is required.',
    ERROR_TOPIC: 'Title Insurance'
  },
  'integrations.stewartTitle.otherPartySolicitor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor is required.',
    ERROR_TOPIC: '<TAB_C_TOPIC>'
  },
  'integrations.stewartTitle.credentialsNotFound': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: `Stewart Title credentials are required to use this service and must be entered under 'My Integration Credentials'.`,
    ERROR_TOPIC: 'Integrations'
  },
  'integrations.stewartTitle.propertyAddress': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Street is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyCity': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property City is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },

  'integrations.stewartTitle.propertyPostalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Postal Code is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyCondoUnit': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Unit / Suite is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyCondo.Unit.Level': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Property Unit / Level is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyProvince': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Province is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyLegalDescriptionPin1': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Legal Description for PIN 1 is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.overrideLegalDescription': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Legal Description is required.',
    ERROR_TOPIC: 'Title Insurance'
  },
  'integrations.stewartTitle.propertyPins': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property PIN(s) is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.validCredentials': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Stewart Title credentials are required for at least one of the following: logged in user, law clerk, or solicitor.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.stewartTitle.solicitorStewartProfile': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s Stewart Title Profile ID is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.chicagoTitle.closingDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing Date is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.chicagoTitle.propertyType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Title Insurance Property Type is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.purchasePrice': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Purchase Price is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.solicitor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.chicagoTitle.solicitor.workPhone': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s work phone is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.chicagoTitle.solicitor.user': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor selected must have Unity credentials.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.chicagoTitle.lawclerk.user': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Law Clerk selected must have Unity credentials.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.chicagoTitle.propertyPin': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'PIN is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titleInsurance.partLot': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Part (lot) is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.planBlockLot': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Plan/Block/Lot is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.legalDescription': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Legal Description is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titleInsurance.plan': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Plan is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.propertyPlan': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Plan for each unit is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.propertyUnit': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Unit for each plan is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.propertyUnitPlan': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Unit No./Plan No. is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.mortgageRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Mortgage with a priority is required.',
    ERROR_TOPIC: 'New Mortgage'
  },
  'integrations.chicagoTitle.address': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Address is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.province': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'City is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Postal Code is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.chicagoTitle.cancelReason.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Reason is required.'
  },
  'integrations.fct.propertyType.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Title Insurance Property Type is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.UnitNo.required': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Number of Units is required for submitting a Title Insurance order',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.RoomNo.required': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Number of Rooms is required for submitting a Title Insurance order',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.propertyAddress.required': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Address Street is required for submitting a Title Insurance order',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.property.legalDesc.tooLong': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Legal Description exceeds 4000 characters.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.purchasePrice.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Purchase Price is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.purchaseIsOf.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Purchase is of is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.province.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is required',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.province.invalid': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is invalid',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.propertyPin.required': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Property PIN(s) is required for submitting a Title Insurance order',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.fct.new.mortgage.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Mortgage is missing',
    ERROR_TOPIC: 'New Mortgage'
  },
  'integrations.fct.validCredentials.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'FCT credentials are required for the solicitor.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.fct.mmsDealID.invalid': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'MMS Deal ID must be 11 characters in length',
    ERROR_TOPIC: 'Title Insurance'
  },
  'integrations.fct.lenderChanges.acknowledgment': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'To proceed, you must acknowledge that you have read the lender change notification.',
    ERROR_TOPIC: ''
  },
  'notification.stewartAssyst.mortgage.#.tooManyPins': {
    ERROR_TYPE: 'NOTIFICATION',
    ERROR_MESSAGE: 'Assyst Real Estate supports up to ' + telusConfigurations.MAX_PINS + ' <PROVINCE_PIN_LINC>s. Please note any <PROVINCE_PIN_LINC>s after <PROVINCE_PIN_LINC> ' + telusConfigurations.MAX_PINS + ' will not be submitted to Assyst Real Estate.',
    ERROR_TOPIC: 'Assyst Real Estate - #s Mortgage'
  },
  'notification.stewartAssyst.mortgage.#.tooManyPinsLC': {
    ERROR_TYPE: 'NOTIFICATION',
    ERROR_MESSAGE: 'Unity® Lender Centre supports up to ' + telusConfigurations.MAX_PINS + ' <PROVINCE_PIN_LINC>s. Please note any <PROVINCE_PIN_LINC>s after <PROVINCE_PIN_LINC> ' + telusConfigurations.MAX_PINS + ' will not be submitted to Unity® Lender Centre.',
    ERROR_TOPIC: 'Unity® Lender Centre - #s Mortgage'
  },
  'integrations.titlePlus.solicitorCredential': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s TitlePLUS Credential is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawSocietyNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Solicitor LSN is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.validCredentials': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'TitlePLUS credentials are required for at least one of the following: logged in user, law clerk, or solicitor.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.closingDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing Date is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.solicitor.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A Solicitor is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.solicitor.address.street': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s street address is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.solicitor.address.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s city is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.solicitor.address.province': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s province is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.solicitor.address.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s postal code is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawClerk.address.street': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s street address is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawClerk.address.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s city is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawClerk.address.province': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s province is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawClerk.address.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor\'s postal code is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawFirm.address.street': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Law Firm\'s street address is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawFirm.address.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Law Firm\'s city is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawFirm.address.province': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Law Firm\'s province is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.lawFirm.address.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Law Firm\'s postal code is required.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'integrations.titlePlus.propertyType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Title Insurance Property Type is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.unitNo': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Number of Units is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.address': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Street is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.city': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address City is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.province': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Province is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Postal Code is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.country': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Address Country is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.registryOffice': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Land Registry Office is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.municipality': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Municipality is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.purchasePrice': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Purchase Price is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.legalDescription': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Legal Description is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.propertyCondo.unit.number': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Property Condo Unit Number is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.propertyCondo.unit.level': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: ' Property Condo Level Number is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.titlePlus.property.pin': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'PIN 1 is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'supplementalTask.address.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invalid postal code'
  },
  'lendingInstitutions.activeMortgagee': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The selected Lending Institution has a centralized mortgage department that can be retrieved either by the institution\'s name or the central department\'s address.'
  },
  'lendingInstitutions.noActiveMortgagee': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The selected Lending Institution does not accept mortgages.'
  },

  'statementofadjustments.hstSalePrice.hstTypeSale': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The <HST> on Sale Price adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },
  'statementofadjustments.hstSalePrice.hstTypeRebate': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The <HST> Rebate adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },
  'statementofadjustments.hstSalePrice.hstTypeSale.creditVendor': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The <HST> on Sale Price - Credit to Vendor adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },
  'statementofadjustments.hstSalePrice.hstTypeRebate.creditPurchaser': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The <HST> Rebate - Credit to Purchaser adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },
  'statementofadjustments.hstSalePrice.hstTypeRebate.creditVendor': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The <HST> Rebate - Credit to Vendor adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },
  'statementofadjustments.pstSkSalePrice.hstTypeSale.creditVendor': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The PST on Sale Price - Credit to Vendor adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },
  'statementofadjustments.pstSkSalePrice.hstTypeRebate.creditPurchaser': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The PST Rebate - Credit to Purchaser adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },

  'statementofadjustments.pstSkSalePrice.hstTypeRebate.creditVendor': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'The PST Rebate - Credit to Vendor adjustment may be required',
    ERROR_TOPIC: 'Statement of Adjustments'
  },

  'events.eventList.eventDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Event Date is invalid.',
    ERROR_TOPIC: 'Event List'
  },
  'fireInsuranceContactInfo.address.postalCode': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Invalid Postal Code for Broker Address.',
    ERROR_TOPIC: 'Fire Insurance'
  },
  'personaldetails.commissionerDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commissioner Expiry is invalid.',
    ERROR_TOPIC: 'My Personal Details'
  },
  'personaldetails.commissionerDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commissioner Expiry is invalid.',
    ERROR_TOPIC: 'My Personal Details'
  },
  'personaldetails.commissionerDate.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commissioner Expiry is missing.',
    ERROR_TOPIC: 'My Personal Details'
  },
  'admin.staffprofiles.personaldetails.commissionerDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commissioner Expiry is invalid.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffprofiles.personaldetails.commissionerDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commissioner Expiry is invalid.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'admin.staffprofiles.personaldetails.commissionerDate.MISSING': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Commissioner Expiry is missing.',
    ERROR_TOPIC: 'Staff Profile'
  },
  'matter.soa.modal.salePrice.adjustmentHeading.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Adjustment heading is required.'
  },
  'matter.soa.modal.salePrice.adjustmentHeading.cost': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Cost is required.'
  },
  'integrations.stewartTitle.planBlockLot': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Plan/Block/Lot is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.legalDescription': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Legal Description is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.condominiumPlanNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Plan is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.planNumber.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Plan No. is required',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyUnit': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Unit is required',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.propertyUnitPlan': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Property Unit/Plan is required.',
    ERROR_TOPIC: '<TAB_D_TOPIC>'
  },
  'integrations.stewartTitle.matterLegalDescription': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Legal Description is required.',
    ERROR_TOPIC: '<TAB_5_TOPIC>'
  },
  'integrations.telusAssyst.loanNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Mortgage Loan Number is required',
    ERROR_TOPIC: 'Mortgage'
  },
  'integrations.telusAssyst.acknowledgementCheckbox': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Your request cannot be submitted until you have selected the confirmation of acknowledgement statement.',
    ERROR_TOPIC: 'Assyst Payout Request Submission'
  },
  'integrations.telusAssyst.acknowledgementCheckboxLC': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Your request cannot be submitted until you have selected the confirmation of acknowledgement statement.',
    ERROR_TOPIC: 'Unity® Lender Centre Payout Request Submission'
  },
  'integrations.telusAssyst.acknowledgementConsent': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor must obtain Borrowers Acknowledgement & Consent before requesting a Payout Statement from Lender.',
    ERROR_TOPIC: 'Assyst Payout Request Submission'
  },
  'integrations.telusAssyst.acknowledgementConsentLC': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Solicitor must obtain Borrowers Acknowledgement & Consent before requesting a Payout Statement from Lender.',
    ERROR_TOPIC: 'Unity® Lender Centre Payout Request Submission'
  },
  'integrations.telusAssyst.payoutOther': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Description for the Purpose of the payout is required.',
    ERROR_TOPIC: 'Assyst Payout Request Submission'
  },
  'integrations.telusAssyst.payoutOtherLC': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Description for the Purpose of the payout is required.',
    ERROR_TOPIC: 'Unity® Lender Centre Payout Request Submission'
  },
  'integrations.telusAssyst.landRegistryOffice': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Land Registry Office is required.'
  },
  'integrations.telusAssyst.collateralMortgage.yes': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'BMO Collateral Mortgage loan numbers are 11 digits and are prefixed with the numbers "91052"',
    ERROR_TOPIC: 'Mortgage'
  },
  'integrations.telusAssyst.collateralMortgage.no': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'BMO Conventional Mortgage loan numbers are 10 digits',
    ERROR_TOPIC: 'Mortgage'
  },

  'matter.propertTeranet.fiscalYearEndDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Fiscal Year End Date is invalid.',
    ERROR_TOPIC: 'Property'
  },
  'matter.propertTeranet.fiscalYearEndDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Fiscal Year End Date is incomplete.',
    ERROR_TOPIC: 'Property'
  },
  'docServices.lendersForm.institutionName': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Company Name is required.',
    ERROR_TOPIC: 'Lender'
  },
  'docServices.lendersForm.institutionNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Institution Number is required.',
    ERROR_TOPIC: 'Lender'
  }, 'docServices.lendersForm.institutionName.duplicate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Company name must be unique',
    ERROR_TOPIC: 'Lender'
  },
  'matter.propertyTeranet.titleRollNumber.titleNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Title Number is required.'
  },
  'matter.propertyTeranet.titleRollNumber.rollNumber': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Roll Number is required.'
  },
  'project.projectDetails.projectNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Project Record No. is required.',
    ERROR_TOPIC: 'General Info'
  },
  'project.projectDetails.condominium': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Is this a Condo? is required.',
    ERROR_TOPIC: 'General Info'
  },
  'project.projectDetails.deliveryOfCashToClose': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Delivery to client of Cash to Close: is required.',
    ERROR_TOPIC: 'General Info'
  },
  'project.projectDetails.uniqueProjectNo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Project Record No. already exists',
    ERROR_TOPIC: 'General Info'
  },
  'project.generalInfo.declarationRegDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Declaration Registration Date is invalid.',
    ERROR_TOPIC: 'General Info'
  },
  'project.generalInfo.declarationRegDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Declaration Registration Date is incomplete.',
    ERROR_TOPIC: 'General Info'
  },
  'contact.mortgagee.lenderLinkingNotCompleted': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'You must decide which lender forms, if any, are to be used for this mortgagee.  See message under Company Name',
    ERROR_TOPIC: 'Contact'
  },
  'contact.mortgagee.lenderLinkingNotCompleted.BC': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'You must decide which lender forms, if any, are to be used for this lender.  See message under Company Name',
    ERROR_TOPIC: 'Contact'
  },

  'project.projectCondo.declarationRegDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Declaration Registration Date is invalid.',
    ERROR_TOPIC: 'Condo'
  },

  'project.projectCondo.declarationRegDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Declaration Registration Date is incomplete.',
    ERROR_TOPIC: 'Condo'
  },

  'project.docsReg.hstPeriodFrom': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'HST Period: From is invalid.',
    ERROR_TOPIC: 'Condo'
  },

  'project.docsReg.hstPeriodFrom.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'HST Period: From is incomplete.',
    ERROR_TOPIC: 'Condo'
  },

  'project.docsReg.hstPeriodTo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'HST Period: To is invalid.',
    ERROR_TOPIC: 'Condo'
  },

  'project.docsReg.hstPeriodTo.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'HST Period: To is incomplete.',
    ERROR_TOPIC: 'Condo'
  },

  'project.docsReg.dateOfSignature': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date of signature is invalid.',
    ERROR_TOPIC: 'Condo'
  },

  'project.docsReg.dateOfSignature.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date of signature is incomplete.',
    ERROR_TOPIC: 'Condo'
  },
  'dpDateTimePicker.startDate.customEvent.eventData': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Start Date is invalid.'
  },
  'dpDateTimePicker.startDate.customEvent.eventData.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Start Date is incomplete.'
  },
  'dpDateTimePicker.endDate.customEvent.eventData': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'End Date is invalid.'
  },
  'dpDateTimePicker.endDate.customEvent.eventData.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'End Date is incomplete.'
  },
  'customEvent.eventData.scheduledFor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Scheduled For is Required'
  },
  'customEvent.eventData.eventDescription': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Subject is Required'
  },
  'customEvent.eventData.invitees': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'At least one invitee is required'
  },
  'customEvent.eventData.inviteesWithoutValidEmails': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'At least one invitee is missing a valid email address.'
  },
  'customEvent.eventData.inviteesDuplicateEmailsForSyngrafii': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Syngrafii package cannot be created because you have identified recipients with the same email address'
  },
  'customEvent.eventData.appointment.subtype': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Appointment Type is Required'
  },
  'customEvent.eventData.zoomUserId': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Selection of a Zoom Account is required when the Meeting Location is Zoom.'
  },
  'customEvent.eventData.msUserId': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Selection of a MS Teams Account is required when the Meeting Location is MS Teams.'
  },
  'customEvent.eventData.googleAccount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Selection of a Google Meet Account is required when the Meeting Location is Google Meet.'
  },
  'customEvent.informInvitee.email': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invitee does not have a valid email address.'
  },
  'matter.appointment.docuSign.document.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'At least one document is required in order to create the DocuSign Envelope'
  },
  'matter.appointment.syngrafii.document.required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'At least one document is required in order to create the Syngrafii Package'
  },
  'matter.otherParty.docsExecutedOn': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Documents ExecutedOn Date is invalid.'
  },
  'matter.purchaserReport.installmentDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'InstallmentDate Date # is invalid.',
    ERROR_TOPIC: 'Report to Purchaser'
  },
  'matter.purchaserReport.installmentDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'InstallmentDate Date # is invalid.',
    ERROR_TOPIC: 'Report to Purchaser'
  },
  'depositManagerList.filter.fromDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'From date is invalid.',
    ERROR_TOPIC: 'Deposit Manager List'
  },
  'depositManagerList.filter.toDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'To date is invalid.',
    ERROR_TOPIC: 'Deposit Manager List'
  },
  'form4.sentDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Form4 Sent Date is incomplete.'
  },
  'modal.form9.lawyerHasNoInterestInBorrowerAdvisedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which lawyer has no interest in borrower advised is invalid.'
  },
  'modal.form9.lawyerHasNoInterestInBorrowerAdvisedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which lawyer has no interest in borrower advised is incomplete.'
  },

  'modal.form9.principalAdvancedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date principal advanced is invalid.'
  },
  'modal.form9.principalAdvancedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date principal advanced is incomplete.'
  },
  'modal.form9.detailsOfExistingEncumbrancesProvidedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which details of existing encumbrances outstanding provided is invalid.'
  },
  'modal.form9.detailsOfExistingEncumbrancesProvidedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which details of existing encumbrances outstanding provided is incomplete.'
  },
  'modal.form9.prospectusProvidedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which prospectus provided is invalid.'
  },
  'modal.form9.prospectusProvidedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which prospectus provided is incomplete.'
  },
  'modal.form9.independentAppraisalProvidedDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which independent appraisal providedis invalid.'
  },
  'modal.form9.independentAppraisalProvidedDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date on which independent appraisal provided is incomplete.'
  },
  'matter.project.adjustment.interest.depositDate.invalid': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The date of deposit cannot be greater than occupancy date'
  },
  'matter.project.adjustment.deposit.interest.depositDate.invalid': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Occupancy date must precede date of closing'
  },
  'matter.project.adjustment.interest.deposit.titleOnAdjustment': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Title of Adjustment is required.'
  },
  'matter.project.adjustment.commonExpense.occupancyPeriod.commencementDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be less than End date'
  },
  'matter.soadj.itemized.credit.amount.additional.consideration.to.vendor': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Unable to insert total into Additional Consideration field in the Sale Price adjustment because additional consideration is being shown using an alternate method'
  },
  'matter.project.adjustment.itemized.credit.adjustmentHeading': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Adjustment Heading is required.'
  },
  'mortgageAdvance.advanceDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date of Advance is invalid.'
  },
  'mortgageAdvance.advanceDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date of Advance is incomplete.'
  },
  'mortgageAdvance.amount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Amount is required.'
  },
  'mortgageAdvance.holdbackPercentage': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Holdback percentage is required.'
  },
  'matterHoldback.otherHoldbackType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Type of Holdback is required.'
  },
  'matterHoldback.holdbackAmount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Holdback Amount is required.'
  },
  'matterHoldback.releaseDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Release Date is invalid.'
  },
  'matterHoldback.releaseDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Release Date is incomplete.'
  },
  'mortgagePayoutModal.total': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Payout total is negative. Please adjust the Payout amounts.'
  },
  'modal.residentialRentalRebateForm.dateApplicationSigned': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date application signed is invalid.'
  },
  'modal.residentialRentalRebateForm.dateApplicationSigned.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date application signed is incomplete.'
  },
  'modal.residentialRentalRebateForm.relevantDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Relevant Date advanced is invalid.'
  },
  'modal.residentialRentalRebateForm.relevantDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Relevant Date advanced is incomplete.'
  },
  'modal.residentialRentalRebateForm.gstHstFrom': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'GST/HST From is invalid.'
  },
  'modal.residentialRentalRebateForm.gstHstFrom.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'GST/HST From is incomplete.'
  },
  'modal.residentialRentalRebateForm.gstHstTo': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'GST/HST To is invalid.'
  },
  'modal.residentialRentalRebateForm.gstHstTo.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'GST/HST To is incomplete.'
  },
  'modal.residentialRentalRebateForm.typeOfApplication': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The type of application is mandatory.'
  },
  'modal.shared.documents.stale.contact': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The recipients details on the matter are out of sync with their contact record.'
  },
  'modal.referral.matterCloseDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Closing date is invalid.',
    ERROR_TOPIC: 'Matter Opening'
  },
  'modal.referral.matterCloseDate.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Closing date is incomplete.'
  },
  'modal.referral.matterCloseDate.MISSING': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Closing date is missing.'
  },
  'modal.judgementModal.judgementDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Judgement Date is invalid.'
  },
  'modal.judgementModal.judgementFillingDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Judgement Filling Date is invalid.'
  },
  'modal.newHomePstRebateForm.dataEntryDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Dated is incomplete',
    ERROR_TOPIC: 'Property'
  },
  'modal.newHomePstRebateForm.dataEntryDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Dated is invalid',
    ERROR_TOPIC: 'Property'
  },
  'modal.fla.courtOrderDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Date is incomplete',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'modal.fla.courtOrderDate': {
    ERROR_TYPE: 'Date',
    ERROR_MESSAGE: 'Date is invalid',
    ERROR_TOPIC: '<MAIN_TOPIC>'
  },
  'modal.willAssetAddress.postalCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Invalid Postal Code for asset property address'
  },
  'importDL.birthDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Birth date is invalid.'
  },
  'importDL.birthDate.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Birth Date is incomplete.'
  },
  'importDL.expryDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Expiry date is invalid.'
  },
  'importDL.expryDate.INVALID': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Expiry Date is incomplete.'
  },
  'checklistTemplate.name': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Template Name is required.',
    ERROR_TOPIC: 'Checklist Template'
  },
  'work-item.task.dueDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Due Date # is invalid.',
    ERROR_TOPIC: 'Overview > Tasks'
  },
  'work-item.task.dueDate.INVALID': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Due Date # is incomplete.',
    ERROR_TOPIC: 'Overview > Tasks'
  },
  'consentingSpouse.modal.email': {
    ERROR_TYPE: 'WARNING',
    ERROR_MESSAGE: 'Email is invalid.'
  },
  'convertToMatter.matterType': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Matter Type is Required'
  },
  'convertToMatter.provinceCode': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Province is Required'
  },
  'matter.workItem.taskAssignment.userNotSelected': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Please select staff'
  },
  'assyst.payout.request.reasonForCancellation': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Reason for Payout Request cancellation is required'
  },
  'assyst.payout.request.assystCredentialsRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A Solicitor with Assyst Credentials is required'
  },
  'assyst.payout.request.assystCredentialsRequiredLC': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A Solicitor with Unity® Lender Centre Credentials is required'
  },
  'task.assignment.miss.email': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A valid email address must be entered for this client before a task can be assigned to them.'
  },
  'share.document.assignment.missing.email': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'A valid email address must be entered for this client before sharing.'
  },
  'invitee.document.assignment.miss.email': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Valid email address must be entered for invitee before sending.'
  },
  'matter.condoCorporation_organizationNameRequired': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Name for <FIELD_LABEL> is required.',
    ERROR_TOPIC: '<FIELD_LABEL>'
  },
  'condo_corp.management_company_required': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Management Company is mandatory.',
    ERROR_TOPIC: '<FIELD_LABEL>'
  },
  'ltsa.authorize.request.invalidCredentials': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Credentials are invalid. Please try again or reset your password.'
  },
  'matter.matterTrustLedgers.rcvdOrDisbDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'Received or disbursed date is invalid.',
    ERROR_TOPIC: '<FIELD_LABEL>'
  },
  'matter.adjustment-type.strata-maintenance-fee.maintenance-amount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Strata maintenance amount must be greater than $0.00'
  },
  'matter.adjustment-type.strata-maintenance-fee.start-date': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The start date of maintenance period is invalid'
  },
  'matter.adjustment-type.strata-maintenance-fee.end-date': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date of maintenance period is invalid'
  },
  'matter.adjustment-type.strata-maintenance-fee.start-date.greater.cls': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The start date of maintenance period must be less than completion date'
  },
  'matter.adjustment-type.strata-maintenance-fee.end-date.less.cls': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date of maintenance period must be greater than or equal to the completion date'
  },
  'matter.adjustment-type.strata-maintenance-fee.start-date.greater.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The start date of maintenance period must be less than adjustment date'
  },
  'matter.adjustment-type.strata-maintenance-fee.end-date.less.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date of maintenance period must be greater than or equal to the adjustment date'
  },
  'matter.adjustment-type.strata-maintenance-fee.date.greater.expiry': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The end date must be greater than the start date of maintenance period'
  },
  'matter.propertyUtilitiesFee.utility-amount': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The utilities amount must be greater than $0.00'
  },
  'matter.propertyUtilitiesFee.currentBillingStartDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date is invalid'
  },
  'matter.propertyUtilitiesFee.currentBillingEndDate': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The End date is invalid'
  },
  'matter.propertyUtilitiesFee.currentBillingEndDate.less.adj': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The End date must be greater than the Adjustment date'
  },
  'matter.propertyUtilitiesFee.commencementDate.greater.expiry': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The End date must be greater than the Commencement date'
  },
  'matter.propertyUtilitiesFee.commencementDate.greater.end': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be less than or equal to Adjustment date'
  },
  'matter.propertyUtilitiesFee.mortgage.commencementDate.greater.end': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The Commencement date must be less or equal to Completion date'
  },
  'matter.propertyUtilitiesFee.mortgage.endDate.greater.end': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'The End date must be greater than the Completion date'
  },
  'lotr.transparencyDeclaration.fileReference': {
    ERROR_TYPE: 'ERROR',
    ERROR_MESSAGE: 'File Reference is required.'
  }
};

// Use z (small alphabet z) to denote it is applicable to Project Sale
export const provinceBasedErrorMessages = {
  'get': (key: string, placeHolder: string, provinceCode: string, matterType: string) => {
    key = key ? key
      .replace(/\.INVALID$|\.MISSING$/g, '')
      .replace(/\.\d+\./g, '.#.')
      .replace(/\d+/g, '#')
      + '.' + placeHolder.slice(1, -1) : placeHolder.slice(1, -1);

    if (provinceBasedErrorMessages[ key ]) {
      if (provinceBasedErrorMessages[ key ][ provinceCode ]) {
        let errMsg: string = provinceBasedErrorMessages[ key ][ provinceCode ];
        let matterTypeCode: string = matterType ? matterType.charAt(0) : '';
        if (matterTypeCode.length > 0 && errMsg.includes('|')) {
          let errMsgParts: string[] = errMsg.split('|');
          if (errMsgParts[ 0 ].includes(matterTypeCode)) {
            return errMsgParts[ 1 ];
          }
        } else {
          return errMsg; // if no matter type specified just return the text
        }

      }
      return provinceBasedErrorMessages[ key ][ 'DEFAULT' ];
    } else {
      return '';
    }

  },

  //Based on same key as the ErrorDirectiveMapping with the province based placeholder as the suffix.
  'notification.stewartAssyst.mortgage.#.tooManyPins.PROVINCE_PIN_LINC': {
    'DEFAULT': 'PIN',
    'AB': 'LINC'
  },
  'matter.matterOpening.matterCloseDate.FIELD_LABEL': {
    'DEFAULT': 'Closing date',
    'MB': 'PS|Possession date',
    'SK': 'PS|Possession date'
  },
  'matter.matterOpening.fileOpeningDate.FIELD_LABEL': {
    'DEFAULT': 'File opening'
  },
  'matter.matterOpening.matterNo.FIELD_LABEL': {
    'DEFAULT': 'Matter No.'
  },
  'matter.matterOpening.purchaseSaleAgreementDate.FIELD_LABEL': {
    'DEFAULT': 'Agrt of P&S',
    'AB': 'Purch Contract'
  },
  'property.holdbacks.substantialCompletionDate.FIELD_LABEL': {
    'DEFAULT': 'Substantial Completion Date',
    'ON': 'Substantial Performance Date'
  },
  'matter.matterOpening.requisitionDate.FIELD_LABEL': {
    'DEFAULT': requisitionDateLabel.requisitionDate,
    'ON': 'z|Occupancy Date',
    'NS': requisitionDateLabel.titleConditionDate
  },
  'contact.firmSolicitor.lawFirm.firmName.FIELD_LABEL': {
    'DEFAULT': 'Law Firm Name',
    'BC': 'Law/Notary Firm'
  },
  'lawclerk.lawClerkEmail.FIELD_LABEL': {
    'DEFAULT': 'Vendors & Solicitors',
    'BC': 'Buyers & Lawyer/Notary'
  },
  'lawclerk.lawClerkEmail.LAW_CLERK_EMAIL': {
    'DEFAULT': 'Law Clerk',
    'BC': 'Assistant',
    'NS': 'Paralegal'
  },
  'jurisdiction.jurisdictionName.FIELD_LABEL': {
    'DEFAULT': 'Jurisdiction',
    'NS': 'Community'
  },
  'matter.condoCorporation_organizationNameRequired.FIELD_LABEL': {
    'DEFAULT': 'Condo Corporation',
    'BC': 'Strata Corporation'
  },
  'condo_corp.management_company_required.FIELD_LABEL': {
    'DEFAULT': 'Condo Corporation',
    'BC': 'Strata Corporation'
  },
  'solicitor.email.SOLICITOR_NAME': {
    'DEFAULT': 'Vendors & Solicitor',
    'BC': 'Sellers & Lawyer/Notary'
  },
  'solicitor.email.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'solicitorContact.email_email_#.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'solicitorContact.surname_surname_#.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'solicitorContact.surname_surname_#.TOPIC_LABEL': {
    'DEFAULT': 'Solicitors',
    'BC': 'Lawyers/Notaries'
  },
  'matter.otherParty.solicitorContact.surname.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'matter.otherParty.solicitorContact.surname_surname_#.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'matter.mortgage.solicitorContact.surname_surname_#.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'matter.mortgage.solicitorContact.surname.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'matter.mortgage.solicitorContact.surname.TOPIC_LABEL': {
    'DEFAULT': 'Solicitors',
    'BC': 'Lawyers/Notaries'
  },
  'solicitor.surname_surname_#.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'solicitor.surname.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'solicitorContact.brokerageIntegrationsEmail_email_#.FIELD_LABEL': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'property.address.postalCode_#.TOPIC_LABEL': {
    'DEFAULT': 'Property',
    'BC': 'Properties'
  },
  'matter.matterTrustLedgers.rcvdOrDisbDate_partialRcvdOrDisbDate#.FIELD_LABEL': {
    'DEFAULT': 'Trust Ledger',
    'BC': 'Order to Pay/Trust Reconciliation'
  }
};

export const opportunityMatterErrorMessages = {
  'get': (key: string, placeHolder: string, matterType: string) => {
    key = key ? key.replace(/\.INVALID$|\.MISSING$/g, '').replace(/\.\d*\./g, '.#.') + '.' + placeHolder.slice(1, -1) : placeHolder.slice(1, -1);
    if (opportunityMatterErrorMessages[ key ]) {
      if (opportunityMatterErrorMessages[ key ][ matterType ]) {
        return opportunityMatterErrorMessages[ key ][ matterType ];
      }
      return opportunityMatterErrorMessages[ key ][ 'DEFAULT' ];
    }
    return '';
  },

  'matter.matterOpening.matterNo.FIELD_LABEL': {
    'DEFAULT': 'Matter No.',
    'OPPORTUNITY': 'Opportunity ID'
  },
  'matter.matterOpening.matterCloseDate.FIELD_LABEL': {
    'DEFAULT': 'Closing date',
    'OPPORTUNITY': 'Completion date'
  },
  'matter.matterOpening.fileOpeningDate.FIELD_LABEL': {
    'DEFAULT': 'File opening',
    'OPPORTUNITY': 'Opportunity opening'
  }
};

export const provinceBasedContactTypeErrorMessages = {

  get: (errorCode: string, contactType: string, provinceCode: string) => {
    if (provinceBasedContactTypeErrorMessages[ errorCode ] && provinceBasedContactTypeErrorMessages[ errorCode ][ contactType ]) {
      if (provinceBasedContactTypeErrorMessages[ errorCode ][ contactType ][ provinceCode ]) {
        return provinceBasedContactTypeErrorMessages[ errorCode ][ contactType ][ provinceCode ];
      }
      return provinceBasedContactTypeErrorMessages[ errorCode ][ contactType ][ 'DEFAULT' ];
    }
    return undefined;
  },

  'app.duplicateOrganizationNameAndAddress': {
    'CONDO_CORPORATION': {
      'DEFAULT': 'Another Condo corporation with the same name and address already exists.',
      'BC': 'Another Strata corporation with the same name and address already exists.'
    }
  }
};
