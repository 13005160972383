import {MatterType} from '../../../matters/shared/matter-specific-type';
import {dropDowns} from '../../accounts/shared/account-drop-downs';
import {ProvinceCode} from '../../accounts/shared/base-province';
import {provinceBasedLawClerkTitle, provinceBasedLawyerTitle} from '../../../shared-main/province-based-dropdowns';
import {PROVINCE_CODES} from '../../../matters/shared/user-province';

export type RecipientRole =
  'CLIENT'
  | 'REALESTATEAGENT'
  | 'CONDO_CORPORATION'
  | 'MANAGEMENT_COMPANY'
  | 'INSURANCE_COMPANY_OR_BROKER'
  | 'ASSIGNED_SOLICITOR'
  | 'ASSIGNED_OPPORTUNITY_ASSIGNEE'
  | 'ASSIGNED_LAW_CLERK'
  | 'OTHER_SIDE_SOLICITOR'
  | 'OTHER_SIDE_LAW_CLERK';
export type TriggerEvent = 'STARTED' | 'RECIPIENT_CHANGED' | 'OVERDUE' | 'FINISHED' | 'ON_HOLD';

export class MatterNotificationConfig {

  id: number;
  customerAccountId: number;
  notificationPrecedentId: number;
  recipientRole: RecipientRole;
  triggerEvent: TriggerEvent;
  active: boolean;
  lastUpdatedTimeStamp: string;
  taskIdentifier: number;
  //UI ONLY
  selectedPrecedent: any;

  constructor(matterNotificationConfig?: MatterNotificationConfig) {
    for (let prop in matterNotificationConfig) {
      if (matterNotificationConfig.hasOwnProperty(prop)) {
        this[ prop ] = matterNotificationConfig[ prop ];
      }
    }
  }

  getRecipientRoleLabel(provinceCode: ProvinceCode): string {
    let dropdown = this.matterNotificationConfigRecipientRoleOptions(provinceCode).filter(item => item.value === this.recipientRole);
    return dropdown.length === 1 ? dropdown[ 0 ].label : '';
  }

  matterNotificationConfigRecipientRoleOptions(provinceCode: ProvinceCode): any[] {
    return [ {label: 'Real Estate Agent', value: 'REALESTATEAGENT'},
      {label: 'Client', value: 'CLIENT'},
      {label: 'Assigned ' + provinceBasedLawyerTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ], value: 'ASSIGNED_SOLICITOR'},
      {
        label: 'Assigned ' + provinceBasedLawClerkTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ],
        value: 'ASSIGNED_LAW_CLERK'
      },
      {
        label: 'Other Side ' + provinceBasedLawyerTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ],
        value: 'OTHER_SIDE_SOLICITOR'
      },
      {
        label: 'Other Side ' + provinceBasedLawClerkTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ],
        value: 'OTHER_SIDE_LAW_CLERK'
      }
    ];
  }

  get triggerEventLabel(): string {
    let dropdown = dropDowns.triggerEventOptions.filter(item => item.value === this.triggerEvent);
    return dropdown.length === 1 ? dropdown[ 0 ].label : '';
  }

  //formatted in ROLE.TRIGGER_EVENT
  get metaDataKey(): string {
    return this.recipientRole + '.' + this.triggerEvent;
  }

  static defaultNotificationRecipients(provinceCode: ProvinceCode, matterType: MatterType): any[] {
    if (matterType === 'OPPORTUNITY') {
      return [
        {label: 'Assigned To', value: 'ASSIGNED_OPPORTUNITY_ASSIGNEE', matterType: [ 'O' ]},
        {label: 'Requested Solicitor', value: 'ASSIGNED_SOLICITOR', matterType: [ 'O' ]},
        {label: 'Prospect', value: 'CLIENT', matterType: [ 'O' ]},
        {
          label: 'Other Side ' + provinceBasedLawyerTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ],
          value: 'OTHER_SIDE_SOLICITOR',
          matterType: [ 'O' ]
        },
        {
          label: 'Other Side ' + provinceBasedLawClerkTitle[ provinceCode ? provinceCode : PROVINCE_CODES.ONTARIO ],
          value: 'OTHER_SIDE_LAW_CLERK',
          matterType: [ 'O' ]
        }
      ];
    }
    return [
      {label: 'Client', value: 'CLIENT', matterType: [ 'P', 'S', 'M', 'C', 'D', 'W' ]},
      {
        label: 'Assigned ' + provinceBasedLawyerTitle[ provinceCode ],
        value: 'ASSIGNED_SOLICITOR',
        matterType: [ 'P', 'S', 'M', 'C', 'D', 'W' ]
      },
      {
        label: 'Assigned ' + provinceBasedLawClerkTitle[ provinceCode ],
        value: 'ASSIGNED_LAW_CLERK',
        matterType: [ 'P', 'S', 'M', 'C', 'D', 'W' ]
      },
      {label: 'Other Side ' + provinceBasedLawyerTitle[ provinceCode ], value: 'OTHER_SIDE_SOLICITOR', matterType: [ 'P', 'S', 'M', 'C' ]},
      {
        label: 'Other Side ' + provinceBasedLawClerkTitle[ provinceCode ],
        value: 'OTHER_SIDE_LAW_CLERK',
        matterType: [ 'P', 'S', 'M', 'C' ]
      },
      {label: 'Real Estate Agent', value: 'REALESTATEAGENT', matterType: [ 'P', 'S' ]},
      {label: 'Condo Corporation', value: 'CONDO_CORPORATION', matterType: [ 'P', 'S', 'M' ]},
      {label: 'Management Company', value: 'MANAGEMENT_COMPANY', matterType: [ 'P', 'S', 'M' ]},
      {label: 'Insurance Broker/Company', value: 'INSURANCE_COMPANY_OR_BROKER', matterType: [ 'P', 'S', 'M' ]} ];
  }

}

// export const TASK_NOTIFICATION_RECIPIENTS: any[] = [
//     {label: 'Client', value: 'CLIENT' , matterType : ['P','S','M','C']},
//     {label: 'Real Estate Agent', value: 'REALESTATEAGENT'  , matterType : ['P','S']},
//     {label: 'Condo Corporation', value: 'CONDO_CORPORATION' , matterType : ['P','S','M']},
//     {label: 'Management Company', value: 'MANAGEMENT_COMPANY'  , matterType : ['P','S','M']},
//     {label: 'Insurance Broker/Company', value: 'INSURANCE_COMPANY_OR_BROKER' , matterType : ['P','S','M']}];

export const TASK_NOTIFICATION_STATES: any[] = [
  {label: 'InProgress/Assigned', value: 'STARTED'},
  {label: 'Completed', value: 'FINISHED'},
  {label: 'Overdue', value: 'OVERDUE'},
  {label: 'On Hold', value: 'ON_HOLD'} ];
