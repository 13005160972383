import {api, SESSION_ID_REQ_PARAM} from '../../common';
import {WebPackage} from 'src/app/matters/forms/bc-forms/web-package';

export const ltsaApi = {
  users: `${ api }/integrations/ltsa/users/{userId}/ltsaUsers`,
  user: `${ api }/integrations/ltsa/users/{userId}/ltsaUsers/{ltsaUserId}`,
  authorize: `${ api }/integrations/ltsa/users/{userId}/authUser`,
  getTitleSearchByPid: `${ api }/integrations/ltsa/titles?filter=parcelIdentifier:{pid},includeCancelledTitles:{includeCancelledTitles}`,
  getTitleSearchByTitleNumber: `${ api }/integrations/ltsa/titles?filter=titleNumber:{titleNumber},includeCancelledTitles:{includeCancelledTitles}`,
  placeOrder: `${ api }/integrations/ltsa/orders`,
  titleSearch: `${ api }/integrations/ltsa/orders/initial`,
  getOrdersForMatter: `${ api }/integrations/ltsa/{matterId}/orders`,
  getOrder: `${ api }/integrations/ltsa/orders/{id}`,
  getDocument: `${ api }/integrations/ltsa/orders/documents/{id}?${ SESSION_ID_REQ_PARAM }={sessionId}`,
  // LTSA Package Management Api
  packageRestApiUrl: `${ api }/integrations/ltsa/matter/{matterId}/packages`,
  postLTSAWebFilingApplicationRestApiUrl: `${ api }/integrations/ltsa/webfiling/matter/{matterId}/packages/{packageId}/web-file-applications`,
  deleteLTSAWebFilingApplicationRestApiUrl: `${ api }/integrations/ltsa/webfiling/matter/{matterId}/packages/{packageId}/web-file-applications/{id}`,
  viewLTSAPackageURL: `/wfc/package/{packageId}/view`,
  // LOTR
  lotrFilingRestApiUrl: `${ api }/integrations/ltsa/lotr/matter/{matterId}/filings`,
  lotrFilingByIdRestApiUrl: `${ api }/integrations/ltsa/lotr/matter/{matterId}/filings/{externalFilingId}`,
  viewLTSALotrFilingURL: `/lotr/td/create/{transparencyDeclarationId}`
};

export interface LtsaResponse {
  LtsaWebFilingPackages: WebPackage[];
}

export const forgotUrl = 'https://apps.ltsa.ca/iam/resetPasswordUsername';

export enum SearchType {
  INITIAL = 'Initial',
  PRE_CLOSE = 'Pre Close',
  POST_CLOSE = 'Post Close'
}

export enum OrderProductType {
  TITLE = 'title',
  COMMON_PROPERTY = 'commonProperty',
  PARCEL_INFO = 'parcelInfo',
  DOC_OR_PLAN = 'docOrPlan',
  APPLICATIONS = 'pendingApplications'
}

export enum OrderStatus {
  PROCESSING = 'Processing',
  FULFILLED = 'Fulfilled',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled'
}

export const LTSA_CODES = {
  LTSA_CONNECT_ERROR: 'external.connect.error',
  LTSA_USER_NOT_UNAUTHORIZED: 'external.unauthorized'
};
