import {Address} from '../../../matters/shared/address';
import {Telephone} from '../../../matters/shared/telephone';
import {TelephoneTypes} from '../../../matters/shared/telephone-types';
import {Contact} from '../../../matters/shared/contact';
import {AddressTypes} from '../../../matters/shared/address-types';
import * as _ from 'lodash';
import {DpBooleanValue, DpBooleanValueTypes} from '../../../matters/shared/dp-boolean';
import {AccountProvince, ProvinceCode} from './account-province';
import {DigitalSignaturePlatform} from '../../../shared-main/constants';

export type DocumentProcessorType = 'MS_WORD' | 'WORD_PERFECT' | 'RICH_TEXT_FORMAT';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  INACTIVE = 'INACTIVE',
  TEST = 'TEST'
}

export class Application {

  constructor(application?: Application) {
    if (application) {
      for (let prop in application) {
        if (application.hasOwnProperty(prop)) {
          this[ prop ] = application[ prop ];
        }
      }
    }

  }

  id: number;
  applicationName: string;
  onApproval: boolean = false;
  trailExpiryDate: string = '//';
  noOfTransactions: number = 0;
  applicationStatus: string = 'EXISTING';
  customerAccountId: number;
  active: boolean = true;
  cancellationDate: string = '';
  cancellationReason: string = '';
  subscriptionAmount: number = 0;
  subscriptionThreshold: number = 0;

  isUnityApplication(): boolean {
    return this.applicationName == 'UNITY_CONVEYANCING';
  }

  isUnityProjectsApplication(): boolean {
    return this.applicationName == 'UNITY_PROJECTS';
  }

  isUnityDriveApplication(): boolean {
    return this.applicationName == 'UNITY_DRIVE';
  }

  isConveyancerApplication(): boolean {
    return this.applicationName == 'THE_CONVEYANCER';
  }

  isUnityWillApplication(): boolean {
    return this.applicationName == 'UNITY_WILL';
  }

  isUnityWillAIApplication(): boolean {
    return this.applicationName == 'UNITY_WILL_AI';
  }

  isReactivatedApplication(): boolean {
    return [ 'FAST_COMPANY', 'WILL_BUILDER', 'ESTATE_A_BASE' ].includes(this.applicationName);
  }

}

export class Account {

  constructor(account?: Account) {

    if (account) {
      for (let prop in account) {
        if (account.hasOwnProperty(prop)) {
          this[ prop ] = account[ prop ];
        }
      }
      this.addresses = [];
      if (Array.isArray(account.addresses)) {
        for (let i: number = 0; i < account.addresses.length; i++) {
          this.addresses[ i ] = new Address(account.addresses[ i ]);
        }
      }
      this.telephones = [];
      if (Array.isArray(account.telephones)) {
        for (let j: number = 0; j < account.telephones.length; j++) {
          //Do not add duplicates for the account phones, each is supposed to be unique.
          // This will also clean up the duplicates introduced before
          if (!this.telephones.find(phone => phone.phoneTypeCode == account.telephones[ j ].phoneTypeCode)) {
            this.telephones.push(new Telephone(account.telephones[ j ]));
          }
        }
      }
      if (account.primaryContact) {
        this.primaryContact = new Contact(account.primaryContact);
      } else {
        this.createPrimaryContact();
      }
      if (account.billingContact) {
        this.billingContact = new Contact(account.billingContact);
      } else {
        this.createBillingContact();
      }
      this.applications = [];
      if (Array.isArray(account.applications)) {
        for (let j: number = 0; j < account.applications.length; j++) {
          this.applications[ j ] = new Application(account.applications[ j ]);
        }
      }
      this.accountProvinces = [];
      if (Array.isArray(account.accountProvinces)) {
        for (let j: number = 0; j < account.accountProvinces.length; j++) {
          this.accountProvinces[ j ] = new AccountProvince(account.accountProvinces[ j ]);
        }
      }
    } else {

      this.createPrimaryContact();
      this.createBillingContact();
      this.addresses = [];
      this.telephones = [];
      this.applications = [];
    }

  }

  id: number;
  accountCreatedDate: any;
  lastUpdatedTimeStamp: string;
  lastUpdatedUser: string;
  accountTypeCode: string;
  publicAccountId: number;
  name: string;
  customerAccountStatus: string;
  //Todo: Customer account should encapsulate legalfirm fields in firm object
  firmName: string;
  legalFirmId: number;
  barristerSolicitor: string;
  addresses: Address[];
  telephones: Telephone[];
  email: string;
  primaryContact: Contact;
  billingContact: Contact;
  paymentMethodType: string;
  //jurisdictionId : Number; // moved to AccountProvince
  //jurisdictionName : string;
  accountProvinces: AccountProvince[] = [];
  documentProcessorTypes: DocumentProcessorType[] = [];
  defaultProvinceCode: string;
  conveyancingCustomer: string;
  gstExempt: string;
  pstExempt: string;
  emailOptOut: string;
  pstExemptionNo: string;
  maxPurchaseCredit: number;
  maxCreditBalance: number;
  userInitiatedResetsAllowed: boolean;
  accountingInstructions: string;
  applications: Application[];
  isDirty: boolean = false;
  isValid: boolean = true;
  selectedProvinceCode: ProvinceCode;//UI field only. Keeping it in model so selected province can be retained when tabs are switched
  fctEnabled: boolean; // Flag to enable/disable FCT EMP functionality at account level
  showPrivateMortgageesOnly: DpBooleanValue;
  digitalSigningEnabled: boolean; //Flag to check if Docusign enabled for the account
  syngrafiiEnabled: boolean; //flag for checking if Syngrafii enabled at account level (mainly for beta use)
  enabledIntegrationsAccesses: string[] = [];
  maxMattersAllowedInRestrictionPeriod: number;
  sendExternalNotifications: string;
  sendInternalNotifications: string;
  receiveReferralsEnabled: DpBooleanValue;
  disablePostClosedMattersEdit: boolean;
  disableMatterEditAfterClosingDays: number;

  get enabledProvinces(): AccountProvince[] {
    return this.accountProvinces.filter(value => value.enabled);
  }

  //Getter function to convert list of accountProvinces to list of accessibleProvinces
  // get accessibleProvinces(): string[] {
  //     return this.accountProvinces.map(value => value.provinceCode);
  // }
  getEnabledProvincesCodes(): ProvinceCode[] {
    return this.accountProvinces.filter(value => value.enabled).map(value => value.provinceCode);
  }

  //Setter function to convert list of strings to list of entities
  // set accessibleProvinces(provinceCodes: string[]) {
  //     let updateAccountProvinces: AccountProvince[] = [];
  //     if(provinceCodes && provinceCodes.length > 0) {
  //         provinceCodes.forEach(value => {
  //             let accountProvince = this.accountProvinces.find(existingProvince => existingProvince.provinceCode == value);
  //             if(accountProvince == null) {
  //                 accountProvince = new AccountProvince();
  //                 accountProvince.provinceCode = value;
  //             }
  //             updateAccountProvinces.push(accountProvince);
  //         })
  //     }
  //     this.accountProvinces = updateAccountProvinces;
  // }
  setAccessibleProvinces(selectedProvinceCodes: string[]) {
    this.accountProvinces.forEach(accountProvince => {
      accountProvince.enabled = !!(selectedProvinceCodes && selectedProvinceCodes.findIndex(value => value == accountProvince.provinceCode) > -1);
    });
  }

  get defaultProvince(): AccountProvince {
    if (this.accountProvinces && this.accountProvinces.length) {
      return this.accountProvinces.find(province => province.provinceCode === this.defaultProvinceCode);
    }
    return null;
  }

  get mailingAddress(): Address {

    let mailingAddress: Address = _.find(this.addresses, (address: Address) => {
      return address.addressTypeCode === AddressTypes.mailing;
    });
    if (!mailingAddress) {
      mailingAddress = new Address();
      mailingAddress.addressTypeCode = 'MAILING';
      this.addresses.push(mailingAddress);
    }
    return mailingAddress;
  }

  get accountAddress(): Address {

    let billingAddress: Address = _.find(this.addresses, (address: Address) => {
      return address.addressTypeCode === AddressTypes.billing;
    });
    if (!billingAddress) {
      billingAddress = new Address();
      billingAddress.addressTypeCode = 'BILLING';
      billingAddress.sameAsAddressTypeCode = 'MAILING';
      this.addresses.push(billingAddress);
    }
    return billingAddress;
  }

  get billingAddress(): Address {
    let billingAddress: Address = this.accountAddress;

    if (billingAddress.sameAsAddressTypeCode === AddressTypes.mailing) {
      billingAddress = _.find(this.addresses, (address: Address) => {
        return address.addressTypeCode === AddressTypes.mailing;
      });
    }

    return billingAddress;
  }

  equals(other: Account): boolean {
    return JSON.stringify(this, this.replacer) === JSON.stringify(other, this.replacer);
  }

  //This method is to list the properties which should be ignored in stringify
  replacer(key, val): any {
    if (key !== 'isDirty' && key !== 'isValid' && key !== 'errorMessage' && key !== 'isAddressValid' && key !== 'identifier') {
      return val;
    }
  }

  isPrimaryContactEmpty(): boolean {

    return !this.primaryContact.contactName.lastName
      && !this.primaryContact.contactName.firstName
      && !this.primaryContact.contactName.middleName
      && !this.primaryContact.dear
      && !this.primaryContact.cellPhone
      && !this.primaryContact.workPhone
      && !this.primaryContact.email;

  }

  isBillingContactEmpty(): boolean {

    return !this.billingContact.contactName.lastName
      && !this.billingContact.contactName.firstName
      && !this.billingContact.contactName.middleName
      && !this.billingContact.dear
      && !this.billingContact.cellPhone
      && !this.billingContact.workPhone
      && !this.billingContact.email;

  }

  createPrimaryContact(): void {
    this.primaryContact = new Contact();
    this.primaryContact.gender = 'QUESTION';
    this.primaryContact.instanceType = 'person';
    this.primaryContact.contactType = 'PERSON';
    this.primaryContact.partyRole = 'CLIENT';
    this.primaryContact.activeFlag = DpBooleanValueTypes.Y_n;
    this.primaryContact.canadianResidentFlag = 'Y/n';
  }

  createBillingContact(): void {
    this.billingContact = new Contact();
    this.billingContact.gender = 'QUESTION';
    this.billingContact.instanceType = 'person';
    this.billingContact.contactType = 'PERSON';
    this.billingContact.partyRole = 'CLIENT';
    this.billingContact.activeFlag = DpBooleanValueTypes.Y_n;
    this.billingContact.canadianResidentFlag = 'Y/n';
  }

  get ispaymentMethodTransactionCredits(): boolean {
    return this.paymentMethodType === 'CREDIT_CARD';
  }

  get cellPhone(): string {
    return this.getPhone(TelephoneTypes.cell).telephoneNumber;
  }

  set cellPhone(phone: string) {
    this.getPhone(TelephoneTypes.cell).telephoneNumber = phone;
  }

  get workPhone(): string {
    return this.getPhone(TelephoneTypes.work).telephoneNumber;
  }

  set workPhone(phone: string) {
    this.getPhone(TelephoneTypes.work).telephoneNumber = phone;
  }

  get faxPhone(): string {
    return this.getPhone(TelephoneTypes.fax).telephoneNumber;
  }

  set faxPhone(phone: string) {
    this.getPhone(TelephoneTypes.fax).telephoneNumber = phone;
  }

  /**
   * Get the phone (for the specified type) from the list of phones, or create and return a new one if one doesn't already exists.
   * @param type
   * @returns {Telephone}
   */
  getPhone(type: string): Telephone {
    if (!this.telephones) {
      this.telephones = [];
    }

    let phone: Telephone = this.telephones.find((t: Telephone) => t.phoneTypeCode === type);
    if (!phone) {
      phone = new Telephone();
      phone.phoneTypeCode = type;
      this.telephones.push(phone);
    }
    return phone;
  }

  get isDocumentProcessorTypeSelected(): boolean {
    return !!(Array.isArray(this.documentProcessorTypes) && this.documentProcessorTypes.length > 0);
  }

  get isNewAccount(): boolean {
    return !this.id || this.id <= 0;
  }

  get hasMultipleAccessibleProvinces(): boolean {
    return this.enabledProvinces.length > 1;
  }

  isShowPrivateMortgageesOnly(): boolean {
    return this.showPrivateMortgageesOnly == DpBooleanValueTypes.YES || this.showPrivateMortgageesOnly == DpBooleanValueTypes.Y_n;
  }

  isOnAccessible(): boolean {
    return !!(this.accountProvinces && this.accountProvinces.find((item: AccountProvince) => item.provinceCode === 'ON' && item.enabled));
  }

  isDigitalSigningEnabled(): boolean {
    return this.digitalSigningEnabled || this.syngrafiiEnabled;
  }

  isDocuSignAccessAvailable(): boolean {
    return this.enabledIntegrationsAccesses && this.enabledIntegrationsAccesses.indexOf(DigitalSignaturePlatform.DOCU_SIGN) > -1;
  }

  isSyngrafiiAccessAvailable(): boolean {
    return this.enabledIntegrationsAccesses && this.enabledIntegrationsAccesses.indexOf(DigitalSignaturePlatform.SYNGRAFII) > -1;
  }

  isDigitalSigningAccessAvailable(): boolean {
    return this.isDocuSignAccessAvailable() || this.isSyngrafiiAccessAvailable();
  }

  isStatusActive(): boolean {
    return this.customerAccountStatus == 'ACTIVE';
  }

  setStatusActive(statusValue: boolean = true): void {
    this.customerAccountStatus = statusValue ? 'ACTIVE' : 'INACTIVE';
  }
}

