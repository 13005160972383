<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Scheduling Assistant{{context.eventAvailabilityState && context.eventAvailabilityState.subject ? ' - '+context.eventAvailabilityState.subject : ''}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 padding-top-10">
            <ng-container *ngIf="context.eventAvailabilityState">
                <div class="form-group margin-left-1 padding-bottom-5">
                    <dp-date-time-picker
                        [startDate]="context.eventAvailabilityState.eventStartDate"
                        [endDate]="context.eventAvailabilityState.eventEndDate"
                        [startTime]="context.eventAvailabilityState.eventStartTime"
                        [endTime]="context.eventAvailabilityState.eventEndTime"
                        [showAllDayEvent]="false"
                        [fieldKey]="'customEvent.eventData'"
                        [allowMultiDays]="context.eventAvailabilityState.isMultipleDateAvailable"
                        (dateTimeChange)="dateTimeChange($event)">
                    </dp-date-time-picker>

                </div>
                <dp-event-availability [eventAvailabilityState]="context.eventAvailabilityState">
                </dp-event-availability>
            </ng-container>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    class="dp-btn dp-btn-green margin-right-10"
                    (click)="ok()">
                <span>Ok</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Close</span>
            </button>

        </div>
    </div>
</div>
