import {AfterViewInit, Component, DoCheck, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {CustomKeyCodesEnum} from '../../common';
import {Matter, MatterParticipant} from '../shared';
import {MatterService} from '../matter.service';
import {TabsService} from '../../core';
import {TabsComponent} from '../../shared/tabbing';
import {DialogConfigParams, DialogService} from '../../shared/dialog/dialog.service';
import {NgModel} from '@angular/forms';
import {Contact} from '../shared/contact';
import {Subscription} from 'rxjs';
import {MatterTab} from '../matter-tab';
import {WindowRef} from '../../shared/window.ref';
import {MatterListState} from '../shared/matter-list-state';
import {Tab} from '../../shared/tabbing/tab';
import {AuthZService} from '../../core/authz/auth-z.service';
import {ContactService} from '../../shared-main/contact.service';
import {dropDowns} from '../shared/matter-drop-downs';
import {PrintTransactionModalComponent} from './modals/print-transaction-modal.component';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Account} from '../../admin/accounts/shared/account';
import {AccountService} from '../../admin/accounts/account.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {FilterTypeValue, MatterListFilter, ServerMatterListFilter} from './matter-list-filter';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';
import {
  CLOSING_DAY_FILTER_MAXLENGTH,
  DefaultMatterListFilterName,
  MatterActionRequiredFilters,
  MatterClosingStatus,
  MatterOverviewStatusTypesValue,
  MatterStatus,
  PROJECT_SALE_MATTER,
  PROJECT_SALE_MATTER_OPTION_VALUE
} from '../../shared-main/constants';
import {SoaExportConfig} from '../shared/soa-export-config';
import {StatementConfig} from '../../admin/shared/statement-config';
import {SoaConfig} from '../../admin/soa/soa-config';
import {StatementConfigService} from '../../admin/shared/statement-config.service';
import {SoaTrustLedgerHelperService} from '../../shared/soa-trustledger-helper.service';
import {ExportToAccountingModalComponent} from './modals/export-to-accounting-modal.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {DocumentTemplateModalComponent} from './modals/document-template-modal.component';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {MatDialogRef} from '@angular/material/dialog';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {DocumentProductionService} from '../document-production/document-production.service';
import {ReportTemplate} from '../report-template/report-template';
import {ExportTemplatesService} from '../../admin/export-templates/export-templates.service';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {MatterType, MatterTypesValue} from '../index';
import {ProvinceCode} from '../../admin/accounts/shared/account-province';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {
  provinceBasedLawClerkTitle,
  provinceBasedLawyerTitle,
  provinceBasedLawyerTitleInPlural
} from '../../shared-main/province-based-dropdowns';
import {ProjectService} from '../../projects/project.service';
import {Project} from '../../projects/shared/project';
import {MassUpdateTab} from '../../shared/tabbing/mass-update-tab';
import {matterBaseUrl} from '../shared/matter-api';
import {ActivatedRoute, Router} from '@angular/router';
import {CopyMatterModalComponent} from '../copy-matter-modal/copy-matter.modal.component';
import {CopyMatterService} from '../copy-matter.service';
import {MatterOpeningComponent} from '../matter-opening';
import {CopyMatterLinkDataService} from '../copy-matter-link-data.service';
import {AttentionInfoComponent} from '../matter-opening/attention/attention-info.component';
import {MassUpdateService} from '../mass-update.service';
import {VendorsSolicitorComponent} from '../vendors-solicitor/vendors-solicitor.component';
import {StatementAdjustmentComponent} from '../statement-adjustment/statement-adjustment.component';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {MortgageeComponent} from '../mortgages/mortgage/mortgagee/mortgagee.component';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {PurchaserComponent} from '../purchaser';
import {CondoCorporationComponent} from '../condo-corporation/condo-corporation.component';
import {AdjustmentPropagationService} from '../adjustment-propagation.service';
import {PropertyTeranetComponent} from '../property-teranet';
import {BrokerCommissionComponent} from '../broker-commission/broker-commission.component';
import {StewartTitleService} from '../../shared-main/stewart-title/stewart-title-service';
import {FireInsuranceComponent} from '../fire-insurance/fire-insurance.component';
import {MortgageTermComponent} from '../mortgages/mortgage/term/mortgage-term.component';
import {RequisitionsService} from '../requisitions/requisitions.service';
import {FamilyLawActComponent} from '../purchaser/family-law-act/family-law-act.component';
import {MortgageReportComponent} from '../mortgages/mortgage/report/mortgage-report.component';
import {ExistingMortgageComponent} from '../mortgages/mortgage/existing-mortgage/existing-mortgage.component';
import {MortgageDetailComponent} from '../mortgages/mortgage/mortagage-detail/mortgage-detail.component';
import {messages} from '../../common/messages';
import {NavigateToMatterService} from '../navigate-to-matter.service';
import {UnitConnectImportService} from '../purchaser/cirf/cirf-import-data/unit-connect-import.service';
import {MortgageBrokerInfoComponent} from '../mortgages/mortgage/mortgage-broker-information/mortgage-broker-info.component';
import {Observable} from 'rxjs/Observable';
import {PROJECT_MATTER_INCOMPLETE_MSG_ADD} from '../../projects/shared/project-consts';
import {DirectionReFundsComponent} from '../direction-re-funds/direction-re-funds.component';
import {DPError, ErrorType} from '../../shared/error-handling/dp-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {bulkShareResultStatus, BulkSharingResponse} from './modals/bulk-sharing';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs/Rx';
import {ConsiderationLttComponent} from '../consideration-ltt/consideration-ltt.component';
import {ContactQueryService} from '../../contact/contact-query.service';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {ProjectMatterUpdateService} from '../../../app/matters/project-matter-update.service';
import {MatterTypeInfo} from '../../admin/shared/matter-type-info';
import {MatterListUtil} from './matter-list.util';
import {MatterAdditionalFiltersModalComponent} from './matter-additional-filters/matter-additional-filters.modal.component';
import {MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {WizardTab} from '../../shared/tabbing/wizard-tab';
import {AppConfig} from '../../shared-main/app-configuration';
import {PROVINCE_CODES} from '../shared/user-province';
import Utils from "../../shared-main/utils";

declare var jQuery: any;

// This component will be loaded when user click  on matter from nav bar
// This will be the default page which will loaded first and show the list of matters in a grid.

// ------ The start of matter list filter ------

// 1. Close Matters tab and reopen Matters tab  (Example: Matters tab -> Contacts tab -> Matters tab)
// All latest modified filter changes should be discarded. The Matters List page will start the last filter which filter values come from DB and default value.
// 2. Select a new matter filter  (Example: select one filter from "All Matters", "My Recent" and  other filter)
// All latest modified filter changes should be discarded. Then filter values come from DB and default value.
// ------ The end of matter list filter ------
// 3. Matters tab keeps open status (Example: Matters Tab -> Specific Matter -> Matter Tab)
// If Matters tab still open then the Matters list page should reflect the last filter + any modified filter changes.
// As this is a reload of the matters tab no modified filter changes will be applicable.

// So filter values that comes from the database are :
//     -	Type
//     -	Status
//     -	Action Required Filters
// Other values will be set to the default and will be:
// -	Matter Number, Client RE:Line or Address       Null
// -	Closing Date:                                  Null
// -    Lawyers:                                       Set as All Lawyers
// -    Clerks:                                        Set as All Clerks

// About Filter criteria section
// If "My Recent" is selected, Filter criteria section should be hidden
// If "All Matters" is selected, Filter criteria section should be hidden
// If other filter is selected, Filter criteria section should be expanded
// ------ The end of matter list filter ------

@Component({
  selector: 'dp-matter-list',
  templateUrl: 'matter-list.component.html',
  styleUrls: [
    './matter-list.styles.scss'
  ],
  providers: [ DocumentProfileCache, MatterService, RequisitionsService, CurrencyPipe, DecimalPipe, PercentPipe,
    StewartTitleService, CopyMatterLinkDataService, MassUpdateService, ConsiderationLttComponent, AdjustmentPropagationService,
    PurchaserComponent, VendorsSolicitorComponent, BrokerCommissionComponent, PropertyTeranetComponent, CondoCorporationComponent,
    AttentionInfoComponent, FireInsuranceComponent, FamilyLawActComponent, StatementAdjustmentComponent, MatterOpeningComponent,
    MortgageeComponent, ExistingMortgageComponent, MortgageDetailComponent,
    MortgageTermComponent, MortgageReportComponent, CopyMatterService, MortgageBrokerInfoComponent,
    NavigateToMatterService, UnitConnectImportService, DirectionReFundsComponent, ProjectMatterUpdateService
  ]
})
@AutoUnsubscribe()
@FocusFirstElementDecorator()
export class MatterListComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {

  allProvinces: string [] = [ 'ON', 'AB', 'MB', 'NS', 'BC', 'NB', 'SK' ];

  @ViewChild('search', {static: true, read: NgModel}) searchControl: NgModel;
  userAccessibleProvinces: string[];
  accountAccessibleProvinces: string[] = [];
  accessibleProjects: Project[] = [];

  //drop down options
  matterStatusList: any[] = dropDowns.matterStatuses;
  matterTypes: any[] = [];
  provinceCode: any[];
  actionRequiredFilters: any[] = dropDowns.matterActionRequiredFilters;
  activeBurgerMatter: Matter = null;
  activeIndexFromTabKey: number = -1;
  previousIndexFromTabKey: number = -1;
  lastVisitedRowIndex: number = -1;
  matterTypeFlag: boolean = false;
  actionButtonFlag: boolean = false;
  // radioStatus : string = 'ACTIVE';
  isMatterReadOnlyAccess: boolean = false;
  hasAccessToConveyancingMatters = false;
  isDefaultProvinceBC: boolean = false;
  isOnlyBCEnabled: boolean = false;

  // listeners
  clickListener: Function;
  loggedInUserProvinceForClerkList: string;

  //Queue for throttling client side matter search events. It is for handling rapid fire events, if user is typing too fast in matter search then don't
  // load api with too many calls.
  matterSearchEventQueue = new Subject();
  matterSearchEventQueueSubscription: Subscription;
  // subscription to backend call for matter list. It is used in addition to matterSearchEventQueue (which is used for throttling client side search
  // events) whereas  backendCallSubscription is used to track any in-progress backend calls.
  backendCallSubscription: Subscription;
  pageSize: number = 50;
  loading: boolean = false;

  searchViews: any[] = [];

  previousSearchText: string;

  searchControlSubscription: Subscription;
  account: Account;
  statementConfig: StatementConfig;
  multipleMatterOptions: any[] = [];
  DocumentProfileList: DocumentProfile[] = [];
  reportTemplates: ReportTemplate[] = [];
  AddNewMatterOptions: BurgerMenuExtendedItem[] = [];
  billedMattersForThisMonthText: string;
  newMatterListState: any;
  isAccountingSoftwareEsiLaw: boolean = false;

  constructor(public matterService: MatterService,
              public activatedRoute: ActivatedRoute,
              public userConfigurationService: UserConfigurationService,
              public parentTabsComponent: TabsComponent,
              public tabsStateService: TabsService,
              public dialogService: DialogService,
              public renderer: Renderer2, public lockScreenService: LockScreenService,
              public window: WindowRef, public soaTrustLedgerHelperService: SoaTrustLedgerHelperService,
              public contactService: ContactService, public soaConfigService: StatementConfigService,
              public contactQueryService: ContactQueryService,
              public userStateService: UserStateService,
              public exportTemplatesService: ExportTemplatesService,
              public authZService: AuthZService, public staffProfilesService: StaffProfilesService, public accountService: AccountService
    , public documentProfileService: DocumentProfileService, public statusBarService: StatusBarService, public documentProductionService: DocumentProductionService,
              public projectService: ProjectService,
              public copyMatterService: CopyMatterService,
              public statementAdjustmentComponent: StatementAdjustmentComponent,
              public directionReFundsComponent: DirectionReFundsComponent,
              public errorService: ErrorService,
              public router: Router, public appConfig: AppConfig) {

    this.clickListener = renderer.listen('document', 'click', (event) => {

      /// need to close up any menus that are left open when we click in other places
      this.resetMatterTypeFlag();
      this.resetActionButtonFlag();
      this.closeBurgerMenu(event);
      this.activeIndexFromTabKey = -1;
      this.filterAdditionalRequiredFiltersForBCIfNeeded();
    });

    //we can't just check full access of any item in the MATTERS permission group,
    //need to check whether has full access to either of the two permissions: CONVEYANCING and Project Matter in the Group
    //(since there are other permissions in the MATTERS group)
    this.isMatterReadOnlyAccess = !this.authZService.hasFullAccessToConveyancingMatters() && !this.authZService.hasFullAccessToProjectMatters();
    this.hasAccessToConveyancingMatters = this.authZService.hasFullAccessToConveyancingMatters();
    this.isDefaultProvinceBC = this.userStateService.defaultProvinceCode === 'BC';
    this.isOnlyBCEnabled = this.userStateService.isOnlyBCEnabled();
  }

  filterAdditionalRequiredFiltersForBCIfNeeded(): void {
    if (this.userStateService.isDefaultProvinceBC()) {
      this.actionRequiredFilters = this.actionRequiredFilters.filter(item => item.value !== MatterActionRequiredFilters.UNRESOLVED_REQUISITIONS);
    }
  }

  ngDoCheck(): void {
    //  if(!jQuery("body").hasClass("modal-open")) {
    //    this.removeListeners();
    // }
    // else {
    //    this.addListner();
    // }
  }

  tableKeyCommands(event): void {

    let charCode = (event.charCode) ? event.charCode : ((event.keyCode) ? event.keyCode : ((event.which) ? event.which : 0));

    if (charCode === CustomKeyCodesEnum.Enter && !this.isMassUpdateTab()) {
      // open matter from enter key
      this.keyCommandtoOpenMatter();
    }
    if (charCode === CustomKeyCodesEnum.SpaceBar) {
      if (event.target.id != 'search-by') {
        event.preventDefault();
      }
      // select matter with spacebar key
      this.keyCommandtoSelectMatter(event);
    }
    if (charCode === CustomKeyCodesEnum.Tab) {

      if (event.target.className == 'toggleBurger' && !event.shiftKey) {

        //  this.activeIndexFromTabKey = -1;
        this.setActiveIndex(-1);
        event.preventDefault();
        this.removeClassFromRows();
        jQuery('#search-by').focus();
      }

    }
    if (charCode === CustomKeyCodesEnum.Down) {
      event.preventDefault();
      this.removeClassFromRows();
      if (event.shiftKey) {
        /// select next matter with shift + down arrow
        this.keyCommandtoSelectNextMatter(event);
      } else {
        // hilite next matter with down arrow
        this.keyCommandtoHiliteNextMatter(event);

      }
    }
    if (charCode === CustomKeyCodesEnum.Up) {
      event.preventDefault();
      this.removeClassFromRows();

      if (event.shiftKey) {
        /// select prev matter with shift + up arrow
        this.keyCommandtoSelectPrevMatter(event);
      } else {
        // hilite prev matter with up arrow
        this.keyCommandtoHilitePreviousMatter(event);

      }

    }

  }

  keyCommandtoSelectMatter(event) {
    if (this.activeIndexFromTabKey < (this.matterListState.rows.length)) {
      this.handleRowSelect(this.matterListState.rows[ this.activeIndexFromTabKey ], event);
    }
  }

  keyCommandtoOpenMatter() {
    if (this.activeIndexFromTabKey < (this.matterListState.rows.length)) {
      this.openMatter(this.matterListState.rows[ this.activeIndexFromTabKey ]);
    }
  }

  keyCommandtoSelectNextMatter(event) {
    this.keyCommandtoSelectMatter(event);
    if (this.matterListState.selectedMatters.length > 0 && this.lastVisitedRowIndex < (this.matterListState.rows.length - 1)) {
      this.handleRowSelect(this.matterListState.rows[ this.lastVisitedRowIndex + 1 ], event);
      if (document.activeElement.className === 'toggleBurger') {
        jQuery(event.target).closest('tr').next('tr').focus();
      } else {
        jQuery(document.activeElement).next('tr').focus();
      }
    }
  }

  keyCommandtoHiliteNextMatter(event) {
    if (this.activeIndexFromTabKey < this.matterListState.rows.length) {
      if (document.activeElement.className === 'toggleBurger') {
        jQuery(event.target).closest('tr').next('tr').focus();
      } else {
        jQuery(document.activeElement).next('tr').focus();
      }
    }
  }

  keyCommandtoHilitePreviousMatter(event) {
    if (this.activeIndexFromTabKey > -1) {
      if (document.activeElement.className === 'toggleBurger') {
        jQuery(event.target).closest('tr').prev('tr').focus();
      } else {
        jQuery(document.activeElement).prev('tr').focus();
      }
    }
  }

  keyCommandtoSelectPrevMatter(event) {
    this.keyCommandtoSelectMatter(event);
    if (this.lastVisitedRowIndex > -1) {
      if (document.activeElement.className === 'toggleBurger') {
        jQuery(event.target).closest('tr').prev('tr').focus();
      } else {
        jQuery(document.activeElement).prev('tr').focus();
      }
      if (this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {
        this.handleRowSelect(this.matterListState.rows[ this.lastVisitedRowIndex - 1 ], event);
        jQuery(document.activeElement).prev('tr').focus();
      } else {
        this.handleRowSelect(this.matterListState.rows[ this.lastVisitedRowIndex ], event);
      }

    }
  }

  setActiveIndex(i) {
    console.log(i);
    this.previousIndexFromTabKey = this.activeIndexFromTabKey;
    this.activeIndexFromTabKey = i;
    this.lastVisitedRowIndex = i;
  }

  removeListeners() {
    this.clickListener();
    // this.keyCommandListener();
  }

  ngOnDestroy(): void {
    // Remove the keyCommand listeners
    this.removeListeners();
    if (this.backendCallSubscription) {
      this.backendCallSubscription.unsubscribe();
    }

    if (this.searchControlSubscription) {
      this.searchControlSubscription.unsubscribe();
    }

    //Though auto un-subscribe directive should take care of it but just putting here bcoz other subscriptions are added
    if (this.matterSearchEventQueueSubscription) {
      this.matterSearchEventQueueSubscription.unsubscribe();
    }

    if (this.accountService) {
      this.accountService.clearMatterTypesCache();
    }
  }

  isNoMatterSelected(): boolean {
    return this.matterListState.selectedMatters.length == 0;
  }

  ngOnInit(): void {
    if (this.tabsStateService.activeTab && this.tabsStateService.activeTab.redirectParams && this.tabsStateService.activeTab.redirectParams.redirect) {
      let matter = new Matter();
      matter.id = Number(this.tabsStateService.activeTab.redirectParams.redirect);
      this.matterService.getMatterLockStatus(matter.id).subscribe(() => {
        const section = this.tabsStateService.activeTab.redirectParams.section;
        this.tabsStateService.activeTab.redirectParams = undefined;
        this.openMatter(matter, section);
      }, () => {
        this.dialogService.confirm('Error',
          'You do not have access to this matter. Please contact your administrator', true).subscribe(() => {
          this.openMatterList();
        });
      });

    } else {
      this.openMatterList();
    }
  }

  openMatterList(): void {
    // Mass Update Flow
    // if(this.isMassUpdateTab()){
    //     // this.createMatterListStateForMassUpdate();
    // }
    // else {
    // Normal Flow
    let occupancy = this.matterStatusList.find(item => item.value == MatterClosingStatus.OCCUPANCY);
    if (!occupancy) {
      this.matterStatusList.push(...dropDowns.matterTopicClosingStatusType.slice(1));
    }
    this.alterMatterStatusListForBCIfNeeded();
    this.userStateService.getAccount().subscribe((account: Account) => {
      this.account = account;
      this.loadMatterProvincesFilter();
      this.getMatterTypeList(account);
      // this.initFilters();
      this.getProjectSaleListUpdateBurgerMenuAndFilterList();
      this.initSoaConfig();
      this.loadAllReportTemplates(this.matterListState.selectedProvinceCode);

    });

    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.documentProfileService.getDocumentProfileList(id).subscribe(
      (data: DocumentProfile[]) => {
        this.DocumentProfileList = data;
      });

    //Creating matter search event queue with debounce time. This is for handling rapid fire search events, if user is typing too fast in search box
    // then do not load api with too many calls. It will wait for given amount of time and emit the last search event.
    this.matterSearchEventQueueSubscription = this.matterSearchEventQueue.pipe(debounceTime(300)).subscribe(this.callMatterListApi);
    // }

    this.getBilledMattersForThisMonthText();

  }

  alterMatterStatusListForBCIfNeeded(): void {
    if (this.userStateService.isDefaultProvinceBC()) {
      this.matterStatusList = this.matterStatusList.filter(item => item.value != MatterClosingStatus.OCCUPANCY && item.value != MatterClosingStatus.ESCROW);
      this.matterStatusList.find(item => item.value == MatterClosingStatus.POST_CLOSING).label = 'Post Completion';
    }
  }

  createMatterListStateForMassUpdate(): void {
    if (this.tabsStateService.activeTab && (this.tabsStateService.activeTab as MassUpdateTab).matter && (this.tabsStateService.activeTab as MassUpdateTab).matter.project) {
      this.matterListState.selectedProjects.push((this.tabsStateService.activeTab as MassUpdateTab).matter.project.id.toString());
    }
    this.matterListState.selectedMatterStatuses.push(MatterStatus.ACTIVE);
    this.matterListState.selectedMatterStatuses.push(MatterStatus.INACTIVE); //massUpdate operation will apply to all the matters with same
    // projectId as templateMatter has
    this.addProjectSaleToSelectedMatterTypes();
    this.resetPage();
    this.page();
  }

  loadAllReportTemplates(selectedProvinceCode: string[]): void {

    if (this.isMassUpdateTab()) {
      return;
    }

    let provinceCode = this.userStateService.defaultProvinceCode;
    if (selectedProvinceCode && selectedProvinceCode.length == 1) {
      provinceCode = selectedProvinceCode[ 0 ];
    }
    this.exportTemplatesService.getAllReportTemplates(this.account.id, provinceCode)
    .finally(() => {
      this.createDropDownMenu();
      this.updateMultiMatterOptions();

    })
    .subscribe((reportTemplates: ReportTemplate[]) => {
      reportTemplates = this.sortData(reportTemplates);
      this.reportTemplates = reportTemplates;
    });
  }

  loadMatterProvincesFilter(): void {
    this.userAccessibleProvinces = this.userStateService.getSortedEnabledUserProvinceCodes();
    if (!this.appConfig.isUnityBCConveyancingEnabled) {
      this.userAccessibleProvinces = this.userAccessibleProvinces.filter(pc => pc != PROVINCE_CODES.BRITISH_COLOMBIA);
    }
    this.userStateService.getAccount().subscribe((account: Account) => {
      this.accountAccessibleProvinces = account.getEnabledProvincesCodes();
      this.provinceCode = [];
      this.provinceCode.push({label: 'All Provinces', value: 'ALL'});
      this.accountAccessibleProvinces.forEach((province) => {
        this.provinceCode.push({label: province, value: province});
      });
    });
  }

  sortData(data: ReportTemplate[]): ReportTemplate[] {
    return _.sortBy(data, (item: ReportTemplate) => {
      return item.templateName ? item.templateName.toLowerCase() : '';
    });
  }

  createDropDownMenu(): void {

    this.multipleMatterOptions = [];
    //For 'Open' option text changes dynamically therefore key is used.
    this.multipleMatterOptions.push({key: 'Open', text: 'Open'});
    if (!this.isMatterReadOnlyAccess) {
      /*this.multipleMatterOptions.push({text: "Assign To"});
      if (this.matterListState.selectedStatus === 'ACTIVE') {
          this.multipleMatterOptions.push({text: "Set To Inactive"});
      }
      else if (this.matterListState.selectedStatus === 'INACTIVE') {
          this.multipleMatterOptions.push({text: "Set To Active"});
      }*/

      this.multipleMatterOptions.push({
        text: 'Document Production and Sharing', items:
          [ {text: 'Multiple Documents'},
            {text: 'Single Document Mail Merge'} ]
      });
    }
    this.multipleMatterOptions.push({
      key: 'Export',
      text: (this.isAccountingSoftwareEsiLaw ? 'Sync 0 selected with ' + (this.appConfig.isEsiLawIntegrationEnabled ? 'Unity® Accounting' : 'esiLaw') : 'Create account export files for 0 selected')
    });

    if (this.reportTemplates.length) {
      let exportMenuItems = this.reportTemplates.map((reportTemplate) => {
        return {text: reportTemplate.templateName, id: reportTemplate.id, key: 'ExpotCSV'};
      });
      this.multipleMatterOptions.push({text: 'Export All To CSV', items: exportMenuItems});
    }

  }

  get lawClerkTitle(): string {
    let label = provinceBasedLawClerkTitle[ this.userStateService.defaultProvinceCode ];
    this.updateClerksListAllLabelOption(label);
    return label;
  }

  get lawyerTitle(): string {
    let label = provinceBasedLawyerTitle[ this.userStateService.defaultProvinceCode ];
    this.updateLawyerListAllLabelOption(label);
    return label;
  }

  get displayAddNew(): boolean {
    if (this.appConfig.isUnityBCConveyancingEnabled) {
      return !this.isMatterReadOnlyAccess &&
        !this.isDefaultProvinceBC;
    } else {
      return !this.isMatterReadOnlyAccess &&
        !this.isOnlyBCEnabled;
    }
  }

  get displayQuickOpen(): boolean {
    if (this.appConfig.isUnityBCConveyancingEnabled) {
      return this.hasAccessToConveyancingMatters &&
        !this.isDefaultProvinceBC;
    } else {
      return this.hasAccessToConveyancingMatters && (!this.isOnlyBCEnabled || this.isHiddenForBCProvince);
    }
  }

  get displayOpenNew(): boolean {
    return this.hasAccessToConveyancingMatters &&
      this.appConfig.isUnityBCConveyancingEnabled &&
      this.isDefaultProvinceBC;
  }

  updateClerksListAllLabelOption(label: string): void {
    //Update the first option of the clerksList according to the logged in user province
    //ie. "All Law Clerks" / "All Legal Assistance" / "All Conveyancer"
    if (this.loggedInUserProvinceForClerkList != this.userStateService.defaultProvinceCode
      && this.matterListState.clerksList && this.matterListState.clerksList.length) {
      this.matterListState.clerksList[ 0 ].label = `All ${ label }s`;
    }
    this.loggedInUserProvinceForClerkList = this.userStateService.defaultProvinceCode;
  }

  updateLawyerListAllLabelOption(label: string): void {
    //Update the first option of the LayersList according to the logged in user province
    //ie. "All Lawyer" / "All Lawyer/Notary"
    if (this.loggedInUserProvinceForClerkList != this.userStateService.defaultProvinceCode
      && !this.userStateService.isDefaultProvinceBC()
      && this.matterListState.lawyersList && this.matterListState.lawyersList.length) {
      this.matterListState.lawyersList[ 0 ].label = `All ${ label }s`;
    }
    this.loggedInUserProvinceForClerkList = this.userStateService.defaultProvinceCode;
  }

  initFilters(): void {
    if (!this.matterListState.isNew) {
      this.initMatterListFilter(this.matterListState.isNew);
      this.unCheckOpenedMatters();
      // if(this.isRecentMattersView) {
      //     this.resetPage();
      //     this.page();
      // }
    } else {
      this.initMatterListFilter(this.matterListState.isNew, true);
      this.matterListState.isNew = false;
      this.getFilterListforMultiSelect('SOLICITOR');
      this.getFilterListforMultiSelect('LAWCLERK');
      //We are already loading matter list in above call to initMatterListFilter function, so not sure why loading it again. Both calls are exactly same.
      /*this.resetPage();
      this.page();*/
    }
  }

  updateProjectListFilterState(): void {
    this.matterListState.projectList = [];
    if (this.authZService.hasReadOrFullAccessToProjectMatter() && this.accessibleProjects && this.accessibleProjects.length > 0) {
      this.matterListState.projectList.push({'label': 'All Projects', 'value': 'ALL'});
      this.matterListState.projectList.push(...this.accessibleProjects.map(item => {
        return {'label': item.projectRecordNumber, 'value': item.id};
      }));
    }
  }

  updateMultiMatterOptions(): void {
    if (this.multipleMatterOptions) {
      this.multipleMatterOptions.forEach((burgerMenuExtendedItem: BurgerMenuExtendedItem) => {
        this.enableDisableMultiMatterMenu(burgerMenuExtendedItem);
        this.updateMultiMenuText(burgerMenuExtendedItem);
      });
    }
  }

  updateMultiMenuText(burgerMenuExtendedItem: BurgerMenuExtendedItem): void {
    if (burgerMenuExtendedItem.key == 'Open') {
      if (this.isNoMatterSelected()) {
        burgerMenuExtendedItem.text = 'Open';
      } else {
        burgerMenuExtendedItem.text = 'Open ' + this.matterListState.selectedMatters.length + ' selected';
      }
    }
    if (burgerMenuExtendedItem.key == 'Export') {
      if (this.isNoMatterSelected()) {
        burgerMenuExtendedItem.text = (this.isAccountingSoftwareEsiLaw ? 'Sync 0 selected with ' + (this.appConfig.isEsiLawIntegrationEnabled ? 'Unity® Accounting' : 'esiLaw') : 'Create account export files for 0 selected');
      } else {
        if (this.isAllMattersSelected()) {
          burgerMenuExtendedItem.text = (this.isAccountingSoftwareEsiLaw ? 'Sync all selected with ' + (this.appConfig.isEsiLawIntegrationEnabled ? 'Unity® Accounting' : 'esiLaw') : 'Create account export files for all matters');
        } else {
          burgerMenuExtendedItem.text = (this.isAccountingSoftwareEsiLaw ? `Sync ${ this.matterListState.selectedMatters.length } selected with ` + (this.appConfig.isEsiLawIntegrationEnabled ? 'Unity® Accounting' : 'esiLaw') : `Create account export files for ${ this.matterListState.selectedMatters.length } selected`);
        }
      }
    }
  }

  enableDisableMultiMatterMenu(burgerMenuExtendedItem: BurgerMenuExtendedItem): void {
    if (burgerMenuExtendedItem.key == 'Open' || burgerMenuExtendedItem.text == 'Document Production and Sharing') {
      burgerMenuExtendedItem.isDisabled = this.isNoMatterSelected();
      this.enableDisableSubMenuOption(burgerMenuExtendedItem);
    } else if (burgerMenuExtendedItem.key == 'Export') {
      burgerMenuExtendedItem.isDisabled = this.isNoMatterSelected() || !this.isExportToAccountingEnabled();
    } else if (burgerMenuExtendedItem.text == 'Export All to CSV') {
      burgerMenuExtendedItem.isDisabled = !(this.matterListState.rows && this.matterListState.rows.length > 0);
    }
  }

  enableDisableSubMenuOption(burgerMenuExtendedItem: BurgerMenuExtendedItem) {
    //if there are sub menu then disabling them too
    if (burgerMenuExtendedItem.items && burgerMenuExtendedItem.items.length > 0) {
      burgerMenuExtendedItem.items.forEach(subMenu => {
        subMenu.isDisabled = this.isNoMatterSelected();
        this.enableDisableSubMenuOption(subMenu);
      });
    }
  }

  initSoaConfig(): void {
    let id = this.account ? this.account.id.toString() : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.soaConfigService
    .getStatementConfig(id)
    .subscribe((statementConfig: StatementConfig) => {
      this.statementConfig = statementConfig;
      if (this.statementConfig) {
        if (!this.statementConfig.statementOfAccount) {
          this.statementConfig.statementOfAccount = new SoaConfig();
        }
        if (statementConfig && statementConfig.accountNationalSetting && statementConfig.accountNationalSetting.accountingSoftware == 'ESILAW' && this.appConfig && this.appConfig.isEsiLawIntegrationEnabled) {
          this.isAccountingSoftwareEsiLaw = true;
        }
      }
    });
  }

  isExportToAccountingEnabled(): boolean {
    return this.statementConfig && this.statementConfig.statementOfAccount ?
      (this.statementConfig.statementOfAccount.accountingSoftware == 'PCLAW' || this.statementConfig.statementOfAccount.accountingSoftware == 'Cosmolex' ||
        this.statementConfig.statementOfAccount.accountingSoftware == 'ESILAW') : false;
  }

  ngAfterViewInit(): void {
    if (!this.matterListState.isNew) {
      this.window.nativeWindow.scrollTo(0, this.matterListState.scrollY);
    }
    if (this.searchControl) {
      this.searchControlSubscription = this.searchControl.valueChanges.debounceTime(500).subscribe(this.onSearchTextChange);
    }
  }

  unCheckOpenedMatters() {
    let unSelectMatters: Matter[] = [];
    for (let selectedMatter of this.matterListState.selectedMatters) {
      if (this.isMatterTabOpen(selectedMatter.id)) {
        unSelectMatters.push(selectedMatter);
      }
    }
    _.remove(this.matterListState.selectedMatters, function (matter: Matter) {
      return _.includes(unSelectMatters, matter);
    });
  }

  get matterListState(): MatterListState {
    let matterListState: MatterListState = this.tabsStateService && this.tabsStateService.activeTab && (this.tabsStateService.activeTab as MatterTab).matterListState;
    if (!matterListState) {
      matterListState = new MatterListState();
      matterListState.hasAccessToConveyancingMatter = this.authZService.hasReadAccessToConveyancingMatter();
      matterListState.hasAccessToProjectSaleMatter = this.authZService.hasReadAccessToProjectMatters();
      if (this.tabsStateService && this.tabsStateService.activeTab) {
        (this.tabsStateService.activeTab as MatterTab).matterListState = matterListState;
      }
    }
    return matterListState;
  }

  get massUpdateMatterListFilter(): MatterListFilter {
    let massUpdateMatterListFilter: MatterListFilter = this.tabsStateService && this.tabsStateService.activeTab && (this.tabsStateService.activeTab as MatterTab).massUpdateMatterListFilter;
    return massUpdateMatterListFilter;
  }

  set massUpdateMatterListFilter(matterListFilter: MatterListFilter) {
    if (this.tabsStateService && this.tabsStateService.activeTab) {
      (this.tabsStateService.activeTab as MatterTab).massUpdateMatterListFilter = matterListFilter;
    }
  }

  getProvincesDropDown(matterTypeCode: string): BurgerMenuExtendedItem[] {
    let provincesMenu: BurgerMenuExtendedItem[] = [];
    // let userAccessibleProvinces = matterTypeCode == MatterTypesValue.DISCHARGE ? this.allProvinces : this.userAccessibleProvinces;
    let userAccessibleProvinces = this.userAccessibleProvinces;
    if (userAccessibleProvinces) {
      for (let i = 0; i < userAccessibleProvinces.length; i++) {
        this.addToBurgerMenu(provincesMenu, userAccessibleProvinces[ i ], matterTypeCode, userAccessibleProvinces[ i ], null, this.addNewMatter);
      }
    }
    return provincesMenu;
  }

  get isHiddenForBCProvince() {
    return this.appConfig && this.appConfig.isHiddenForBCProvince;
  }

  getMatterTypeList(account: Account): void {

    this.matterService.getMatterTypesDetails(account)
    .subscribe(
      (data: MatterTypeInfo[]) => {
        this.matterTypes = [];
        if (this.authZService.hasReadAccessToConveyancingMatter()) {
          this.matterTypes.push({label: 'All Types', value: 'ALL'});
        }
        data.forEach(item => {
          let obj = {value: undefined, flag: undefined};
          obj.value = item;
          obj.flag = false;
          if (item.matterTypeCode == PROJECT_SALE_MATTER_OPTION_VALUE) {
            if (this.authZService.hasReadAccessToProjectMatters()) {
              this.matterTypes.push({label: item.matterTypeDescription, value: item.matterTypeCode});
            }
          } else if (this.authZService.hasReadAccessToConveyancingMatter()) {
            //P.S.M +  Custom types (including restricted types)
            if (this.isHiddenForBCProvince && !item.customMatterType && item.matterTypeCode !== 'WILL') {
              this.matterTypes.push({label: item.matterTypeDescription + ' - Coming Soon', value: item.matterTypeCode, disabled: true});
            } else {
              this.matterTypes.push({label: item.matterTypeDescription, value: item.matterTypeCode});
            }
          }

          if (item.matterTypeCode == PROJECT_SALE_MATTER_OPTION_VALUE) {
            if (this.authZService.hasFullAccessToProjectMatters()) {
              this.addProjectSaleToBurgerMenu();
            }
          } else if (!item.customMatterType || (item.customMatterType && !item.reserved)) {
            //conveyancing matter types exclude restricted matter types
            if (this.authZService.hasReadAccessToConveyancingMatter()) {
              this.matterListState.matterTypeRowData.push(obj);
            }
            if (this.authZService.hasFullAccessToConveyancingMatters()) {
              let provincesDropdown = this.getProvincesDropDown(item.matterTypeCode);
              if (provincesDropdown && provincesDropdown.length) {
                if (provincesDropdown.length == 1) {
                  this.addToBurgerMenu(this.AddNewMatterOptions, item.matterTypeDescription, item.matterTypeCode, provincesDropdown[ 0 ].text, null, this.addNewMatter);
                } else {
                  this.addToBurgerMenu(this.AddNewMatterOptions, item.matterTypeDescription, null, provincesDropdown[ 0 ].text, provincesDropdown);
                }
              }
            }
          }
        });
        this.initFilters();
      });
  }

  getProjectSaleListUpdateBurgerMenuAndFilterList(): void {
    if (this.authZService.hasReadOrFullAccessToProjectMatter()) {
      this.projectService.getProjects(null, '', [ 'status_IN_ACTIVE' ]).subscribe(
        (projects: Project[]) => {
          this.accessibleProjects = projects;
          if (this.accessibleProjects.length > 0) {
            //add menu option for project sale matter
            this.addProjectSaleToBurgerMenu();
            this.updateProjectListFilterState();
          }
        });
    }
  }

  addProjectSaleToBurgerMenu(): void {
    if (this.authZService.hasFullAccessToProjectMatters()) {
      let projectSubMenus: BurgerMenuExtendedItem[] = [];
      let projectMainBurgerMenuExtendedItem = this.AddNewMatterOptions.find(item => item.type == 'PROJECT_SALE_TOP_MENU');
      if (this.accessibleProjects.length > 0) {
        this.accessibleProjects.forEach(project => {
          this.addToBurgerMenu(projectSubMenus, project.projectRecordNumber, 'PROJECT_SALE_SUB_MENU', '' + project.id, null, this.addNewProjectMatter);
        });

      } else {
        this.addToBurgerMenu(projectSubMenus, 'No Project Exists', 'PROJECT_SALE_SUB_MENU', null);
      }
      if (projectMainBurgerMenuExtendedItem) {
        this.updateSubMenuItems(projectMainBurgerMenuExtendedItem, projectSubMenus);
      } else {
        this.addToBurgerMenu(this.AddNewMatterOptions, _.capitalize(PROJECT_SALE_MATTER), 'PROJECT_SALE_TOP_MENU', PROJECT_SALE_MATTER, projectSubMenus);
      }

    }
  }

  openWizardTemplate = (): void => {
    let wizardTabExists: Tab = this.parentTabsComponent.tabs.find(tab => tab.tabType == 'wizard');
    let wizardTab: WizardTab;
    if (!wizardTabExists) {
      wizardTab = WizardTab.createTab();
    } else {
      wizardTab = wizardTabExists as WizardTab;
    }
    this.tabsStateService.openTab(wizardTab);
    this.parentTabsComponent.orderTabsDropDownToTop(wizardTab);
  };

  addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], text: string, type: string, key: string, subMenuItems?: BurgerMenuExtendedItem[], action?: any, isDisabled?: boolean): BurgerMenuExtendedItem {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.type = type;
    burgerMenuItem.key = key;
    burgerMenuItem.isDisabled = isDisabled;
    if (subMenuItems) {
      burgerMenuItem.items = [];
      for (let i = 0; i < subMenuItems.length; i++) {
        burgerMenuItem.items.push(subMenuItems[ i ]);
      }
    }

    if (action) {
      burgerMenuItem.action = action;
    }
    burgerMenuItems.push(burgerMenuItem);
    return burgerMenuItem;
  }

  updateSubMenuItems(burgerMenuItem: BurgerMenuExtendedItem, subMenuItems: BurgerMenuExtendedItem[]): void {
    if (subMenuItems) {
      burgerMenuItem.items = [];
      for (let i = 0; i < subMenuItems.length; i++) {
        burgerMenuItem.items.push(subMenuItems[ i ]);
      }
    }
  }

  clickAddNewDropDown(clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(clickedMenuOption);
    }
  }

  updateSearchViewsAndSetToDefaultView = () => {
    this.matterService.getAllMatterListFilter(FilterTypeValue.matter)
    .subscribe(
      (data: MatterListFilter[]) => {
        this.updateSearchViews(data);
        if (this.isMassUpdateTab()) {
          this.currentSearchView = this.findAllMatterFilter();
        } else {
          this.currentSearchView = this.findMyRecentFilter();
        }
        this.resetPage();
        this.page();
      });
  };

  initMatterListFilter(isNew: boolean, refresh?: boolean): void {
    this.matterService.getAllMatterListFilter(FilterTypeValue.matter)
    .subscribe(
      (data: MatterListFilter[]) => {
        this.updateSearchViews(data);
        //TODO it will be changed with latest server change
        // this.updateDefaultFilter();
        if (this.isMassUpdateTab()) {
          if (this.massUpdateMatterListFilter) {
            const searchView = this.searchViews.find(item => item.matterListFilterName == this.massUpdateMatterListFilter.matterListFilterName);
            if (searchView) {
              this.currentSearchView = searchView;
            } else {
              this.currentSearchView = this.findAllMatterFilter();
            }
          } else {
            this.currentSearchView = this.findAllMatterFilter();
          }
          this.resetPage();
          this.page();
        } else {
          const defaultMatterListFilter: ServerMatterListFilter = this.userConfigurationService.defaultMatterListFilter;
          //Set default value
          if (defaultMatterListFilter && Array.isArray(this.searchViews) && this.searchViews.length > 0) {
            const searchView = this.searchViews.find(item => item.matterListFilterName == defaultMatterListFilter.matterListFilterName);
            if (searchView) {
              this.currentSearchView = searchView;
              if (isNew) {
                this.resetMatterListState();
                if (!this.isAllMattersView && !this.isRecentMattersView) {
                  this.updateMatterListState(this.currentSearchView);
                }

              }
            }
          } else {
            this.currentSearchView = this.findMyRecentFilter();
          }
          if (refresh) {
            this.resetPage();
            this.page();
          }
        }
        if (!this.isRecentMattersView) {
          this.matterListState.showFilters = true;
        }
      });
  }

  setDefaultMatterListFilter(matterListFilter: MatterListFilter) {
    if (!matterListFilter) {
      return;
    }
    this.userConfigurationService.defaultMatterListFilter = this.matterService.convertToServerData(matterListFilter);
  }

  // updateDefaultFilter() {
  //     let allMattersFilterView = this.searchViews.find(item=>item.matterListFilterName == 'all')
  //     if(!this.searchViews.find(item=>item.matterListFilterName == 'recent')) {
  //         let obj = {value : 'recent', label : 'My Recent'};
  //         this.searchViews.unshift(obj);
  //     }
  //     if(!allMattersFilterView) {
  //         let obj = {value : 'all', label : 'All Matters'};
  //         this.searchViews.unshift(obj);
  //     }
  // }

  get currentSearchView(): MatterListFilter {
    return this.matterListState.currentSearchView;
  }

  //For those saved filters, set the active/inactive indicators to false if the flagged or past was just selected
  cleanupMatterStatusFiltersForExistingFilter(matterListFilter: MatterListFilter) {
    if (matterListFilter && Array.isArray(matterListFilter.matterStatusFilters) && matterListFilter.matterStatusFilters.length > 1) {
      if (matterListFilter.matterStatusFilters.includes(MatterOverviewStatusTypesValue.FLAGGED) || matterListFilter.matterStatusFilters.includes(MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE)
        && (matterListFilter.matterStatusFilters.includes(MatterStatus.ACTIVE) || matterListFilter.matterStatusFilters.includes(MatterStatus.INACTIVE))) {
        let activeIndex = matterListFilter.matterStatusFilters.findIndex(item => item == MatterStatus.ACTIVE);
        if (activeIndex > -1) {
          matterListFilter.matterStatusFilters.splice(activeIndex, 1);
        }
        let inactiveIndex = matterListFilter.matterStatusFilters.findIndex(item => item == MatterStatus.INACTIVE);
        if (inactiveIndex > -1) {
          matterListFilter.matterStatusFilters.splice(inactiveIndex, 1);
        }
      }
    }
  }

  set currentSearchView(value) {
    this.cleanupMatterStatusFiltersForExistingFilter(value);
    this.matterListState.currentSearchView = value;
  }

  /**
   * Full refresh of the list, using the existing filters
   */
  refreshList(): void {
    this.resetPage();
    this.page();
  }

  page(): void {
    //Here we are checking if there is already a backend call in-progress and another call is made then unsuscribing to the first one (though it might be
    // completed on backend) but client side will discard it's result and subscribe to the latest backend call. This is used when multiple matter search
    // events have passed debounce and fired to backend.
    if (this.backendCallSubscription) {
      this.backendCallSubscription.unsubscribe();
      this.loading = false;
    }
    this.loading = true;
    this.matterSearchEventQueue.next();
  }

  async getMatterIds() {
    this.initializeMatterListStateBeforeCallApi();
    return await this.matterService.getMatterIds(this.newMatterListState);
  }

  callMatterListApi = (): void => {
    let isAllMatterSelected = this.isAllMattersSelected();
    this.initializeMatterListStateBeforeCallApi();
    this.backendCallSubscription = this.matterService.getMatters(this.currentSearchView && this.currentSearchView.matterListFilterName, this.newMatterListState, this.pageSize, this.newMatterListState.excludeDraft)
    .subscribe(
      (data: Matter[]) => {
        this.matterListState.rows = this.matterListState.rows.concat(data);
        if (data != undefined) {
          if (data.length < this.pageSize) {
            this.matterListState.listEndReached = true;
          } else {
            this.matterListState.listEndReached = false;
            this.incrementPage();
          }
        } else {
          this.matterListState.listEndReached = true;
        }

        if (this.currentSearchView && this.currentSearchView.matterListFilterName == DefaultMatterListFilterName.MY_RECENT) {
          this.matterListState.listEndReached = true;
        }
        this.updateMultiMatterOptions();
        if (isAllMatterSelected) {
          this.checkAllMatters();
        }

        this.loading = false;

        setTimeout(() => {
          // setTimeout ensures height and position are accurate, if table row data does not reach the end of page we load more records to
          // invoke infinite scroll
          if (!this.matterListState.listEndReached && (jQuery('.matter-inner-table table tbody tr:last').offset().top + jQuery('.matter-inner-table table tbody tr:last').height()) < (jQuery('.inspector-footer').offset().top)) {
            this.getNextPage();
          }
        }, 1000);

      }, (error) => {
        // ignore errors
        console.log(error);
        this.matterListState.listEndReached = true; // show no result
        this.loading = false; // stop spinner
      });

    this.getBilledMattersForThisMonthText();
  };

  initializeMatterListStateBeforeCallApi() {

    this.matterListState.excludeDraft = false;
    if (this.isMassUpdateTab()) {
      this.matterListState.selectedProjects = [];
      if ((this.tabsStateService.activeTab as MassUpdateTab).matter && (this.tabsStateService.activeTab as MassUpdateTab).matter.unityProjectId) {
        this.matterListState.selectedProjects.push((this.tabsStateService.activeTab as MassUpdateTab).matter.unityProjectId.toString());
      }
      this.matterListState.selectedMatterTypes = [ 'ALL' ];
      this.addProjectSaleToSelectedMatterTypes();
      this.matterListState.selectedProvinceCode = [ 'ALL' ];
      this.matterListState.excludeDraft = true;
    }
    if (this.currentSearchView && this.currentSearchView.matterListFilterSortBy) {
      this.matterListState.sortQuery = this.currentSearchView.matterListFilterSortBy;
    }
    if (this.currentSearchView && this.currentSearchView.matterListFilterSortByOrder) {
      this.matterListState.sortType = this.currentSearchView.matterListFilterSortByOrder;
    }

    this.newMatterListState = new MatterListState();
    this.newMatterListState.copyOfMatterListStateObject(this.matterListState);
    if (this.newMatterListState.selectedLawyers.includes('ALL_ACTIVE_LAWYERS') && !this.newMatterListState.selectedLawyers.includes('ALL')) {
      const filteredSelectedLawyers = this.newMatterListState.selectedLawyers.filter(items => items !== 'ALL_ACTIVE_LAWYERS');
      this.newMatterListState.selectedLawyers = filteredSelectedLawyers;
    }
    if (this.newMatterListState.selectedClerks.includes('ALL_ACTIVE_LAWCLERKS') && !this.newMatterListState.selectedClerks.includes('ALL')) {
      const filteredSelectedLawclerks = this.newMatterListState.selectedClerks.filter(items => items !== 'ALL_ACTIVE_LAWCLERKS');
      this.newMatterListState.selectedClerks = filteredSelectedLawclerks;
    }
  }

  // create the dynamic tab for matters page and emit the tabs to matters component.
  openMatter = (matter: Matter, section?: string): void => {
    MatterListUtil.openMatter(matter, this.tabsStateService, this.parentTabsComponent, this.dialogService, section);
  };

  // this method will create the url for the tab selected.
  getRoute(id: number): string {
    let type: string = 'SALE';
    let baseUrlMatterTabs: string = matterBaseUrl;
    let redirectRoute = `${ baseUrlMatterTabs }/${ id }/${ type }/`;
    return redirectRoute;
  }

  loadPrecedentMatter(row: Matter): void {
    if (row && row.lockedByUser) {
      //Show error message
      this.showPrecedentMatterError();
    } else {
      this.matterService.getMatter(row.id).subscribe(
        (matter: Matter) => {
          if (matter.locked) {
            //Show error message
            this.showPrecedentMatterError();
          } else {
            //Lock Linked Matter
            if (matter.matterLink && matter.matterLink.linkedMatterId) {
              this.matterService.lockMatter(matter.matterLink.linkedMatterId).subscribe();
            }
            (this.tabsStateService.activeTab as MassUpdateTab).matter = matter;
            (this.tabsStateService.activeTab as MassUpdateTab).massUpdateStage = 'SELECTION_LEVEL';
            this.matterListState.selectedMatters = [];
          }
        });
    }
  }

  showPrecedentMatterError() {
    this.dialogService.confirm('ERROR', 'The matter is currently locked.', true);
  }

  openMassUpdateMatterList(matter): void {
    // let id: number = +this.route.snapshot.params['id'];
    let redirectRoute: string = this.getRoute(matter.tempIdForNewMatter) + 'massUpdateMatterList';
    (this.tabsStateService.activeTab as MassUpdateTab).massUpdateStage = 'SELECTION_LEVEL';
    this.router.navigate([ redirectRoute ]);
  }

  checkMultipleMatters(): void {
    if (this.verifyProjectMatterFullyInitialized()) {
      const contentModalConfig: DialogConfigParams = {
        title: 'Error',
        message: this.verifyProjectMatterFullyInitialized(),
        hideCancelBtn: true,
        alignContentLeft: true
      };
      this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(() => {

      });
    } else {
      // we get max tab value and then add +1 because of anchor tab
      let totaltabstobeopened: number = this.tabsStateService.getMaxTabValue() - (this.tabsStateService.openTabs.length - 1);
      let resultDialog: any;
      // check if there is space to open all selected matters
      // if not enough space open dialog to let user know how many tabs will be opened
      if ((this.matterListState.selectedMatters.length > totaltabstobeopened) && (totaltabstobeopened > 0)) {
        this.dialogService.confirm('Information', 'A maximum of ' + this.tabsStateService.getMaxTabValue() + ' matters may be opened. <br> Only first ' + totaltabstobeopened + ' matters will be opened in the order of listing.', false)
        .subscribe(res => {
          resultDialog = res;
          if (resultDialog) {
            this.openMultipleMatterTabs(totaltabstobeopened);
          }
        });
      } else {
        this.openMultipleMatterTabs(totaltabstobeopened);
      }
    }
  }

  async sendDataToExport() {
    this.lockScreenService.lockForUpdate = true;
    let selectedMatterIds: number[] = [];
    if (this.isAllMattersSelected()) {
      if (this.currentSearchView && this.currentSearchView.matterListFilterName == DefaultMatterListFilterName.MY_RECENT) {
        this.matterListState.rows.map(matter => selectedMatterIds.push(matter.id));
      } else {
        selectedMatterIds = await this.getMatterIds();
      }

    } else {
      this.matterListState.selectedMatters.map(matter => selectedMatterIds.push(matter.id));
    }

    this.soaTrustLedgerHelperService.createSoaExportConfig(selectedMatterIds, 'ALL').subscribe(result => {
      if (result) {

        this.openExportAllModal(result);
      }
    });
  }

  openExportAllModal(soaExportConfigs: SoaExportConfig[]): void {
    this.lockScreenService.lockForUpdate = false;

    this.dialogService.matDialogContent({
      content: ExportToAccountingModalComponent,
      context: {
        soaExportConfigs: soaExportConfigs,
        account: this.account,
        isAccountingSoftwareEsiLaw: this.isAccountingSoftwareEsiLaw
      },
      onFulfillment: (result: any) => {
      },

    });
  }

  public openMultipleMatterTabs(totaltabstobeopened: number): void {
    let sequencedMatterList: any = [];
    this.matterListState.rows.forEach(item => {
      let obj = item as Matter;
      if (-1 !== this.matterListState.selectedMatters.indexOf(obj)) {
        sequencedMatterList.push(obj);
      }
    });
    if (this.verifyProjectMatterFullyInitialized()) {
      const contentModalConfig: DialogConfigParams = {
        title: 'Error',
        message: this.verifyProjectMatterFullyInitialized(),
        hideCancelBtn: true,
        alignContentLeft: true
      };
      this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(() => {

      });
    } else {
      this.parentTabsComponent.openMultipleMatters(sequencedMatterList, totaltabstobeopened);
    }

  }

  async checkAllMatters() {
    /// select/remove all matters when master checkbox is checked/unchecked

    if (this.matterListState.selectedMatters.length < (this.matterListState.rows.length - this.lockedoropenedMatters())) {
      this.matterListState.selectedMatters = [];
      this.matterListState.rows.forEach(item => {
        let obj = item as Matter;
        // only add matters that are not locked or opened
        if (!obj.locked && !this.isMatterTabOpen(obj.id) && !this.isLinkedMatterLocked(obj.id)) {
          this.matterListState.selectedMatters.push(obj);
        }
      });

      this.checkTemplateMatterContainsMatterOnlyClients();

    } else {
      this.matterListState.selectedMatters = [];
    }

    this.updateMultiMatterOptions();
  }

  // used to get number of locked or opened matters for checkAllMatters checkbox
  lockedoropenedMatters(): number {
    return MatterListUtil.getLockedoropenedMattersCount(this.matterListState, this.tabsStateService);
  }

  isAllMattersSelected(): boolean {
    return MatterListUtil.isAllMattersSelected(this.matterListState, this.tabsStateService);
  }

  toggleBurgerMenu(matter: Matter, event) {
    event.stopPropagation();
    if (!this.isMatterTabOpen(matter.id) && !matter.locked && !this.isLinkedMatterLocked(matter.id)) {
      if (this.isBurgerMenuActive(matter)) {

        // close menu
        this.activeBurgerMatter = null;
      } else {
        // need to empty the selected matters list when burger menu is selected
        //open menu

        this.matterListState.selectedMatters = [];
        this.activeBurgerMatter = matter;
        this.handleRowSelect(matter, event);
      }
    }
  }

  isBurgerMenuActive(matter: Matter) {

    return this.activeBurgerMatter == matter;
  }

  // filter the matter list based on matter status selected.
  // As there is no matterStatus as All so we need these if else for all
  // TODO remove hard code checking once we implement the values form property file.
  filterMatter(selectedOption): void {
    this.matterListState.radioStatus = selectedOption;
    if (selectedOption === 'ACTIVE') {
      this.matterListState.selectedStatus = 'ACTIVE';

    } else if (selectedOption === 'INACTIVE') {
      this.matterListState.selectedStatus = 'INACTIVE';

    } else {
      this.matterListState.selectedStatus = 'All';
    }
    this.resetPage();
    this.page();
  }

  isMatterTabOpen(id): boolean {
    return MatterListUtil.isMatterTabOpen(id, this.tabsStateService);
  }

  isLinkedMatterLocked(id): boolean {
    return MatterListUtil.isLinkedMatterLocked(id, this.tabsStateService);
  }

  closeBurgerMenufromMatter(matter: Matter, event) {
    if (this.activeBurgerMatter != null) {
      if (this.activeBurgerMatter.id != matter.id) {
        this.toggleBurgerMenu(this.activeBurgerMatter, event);
      }
    }

  }

  closeBurgerMenu(event) {
    if (this.activeBurgerMatter != null) {
      this.toggleBurgerMenu(this.activeBurgerMatter, event);
    }
  }

  handleRowSelect(data, event) {
    // prevent multiple click events from coming in
    event.preventDefault();
    //event.stopPropagation();

    // focus on row
    if (event.target.tagName === 'LABEL') {
      jQuery(event.target).closest('tr').focus();
    }
    if (this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {
      jQuery(event.target).closest('tr').focus();
    }
    /// close any open menus
    this.resetMatterTypeFlag();
    this.resetActionButtonFlag();
    let matter = data as Matter;

    /// close any open burger menus
    this.closeBurgerMenufromMatter(matter, event);
    // last visited row even if locked or already opened or cannot be selected we still need to keep track for key commands
    this.lastVisitedRowIndex = this.getindexofSelectedMatter(matter.id);
    this.activeIndexFromTabKey = this.getindexofSelectedMatter(matter.id);

    MatterListUtil.handleRowSelect(event, matter, this.previousIndexFromTabKey, this.matterListState, this.window, this.tabsStateService);
    /// if action menu is left opened with 0 matters selected
    if (this.matterListState.selectedMatters.length < 1) {
      this.resetActionButtonFlag();
    }

    this.checkTemplateMatterContainsMatterOnlyClients();
    this.updateMultiMatterOptions();
  }

  checkTemplateMatterContainsMatterOnlyClients(): void {
    if (this.isMassUpdateTab()) {
      let templateMatter = (this.tabsStateService.activeTab as MassUpdateTab).matter;
      if (templateMatter && templateMatter.isMatterProvinceAB
        && templateMatter.containsMatterOnlyClients()
        && this.matterListState.selectedMatters && this.matterListState.selectedMatters.length
        && this.matterListState.selectedMatters.some(matter => !!matter.matterLink)) {
        this.dialogService.confirm('Mass update contains one or more matter-only purchasers',
          'Linked project sale matters cannot be updated as the mass update contains one or more matter-only purchasers. ' +
          'To mass update a linked project sale matter, remove matter-only purchasers from mass update', true)
        .subscribe((res) => {
          this.matterListState.selectedMatters = this.matterListState.selectedMatters.filter(matter => !matter.matterLink);
        });
      }
    }
  }

  selectMultipleMatters(startIndex, endIndex) {
    if (startIndex < endIndex) {
      for (var i = startIndex; i < endIndex; i++) {
        // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already
        if (!this.isMatterTabOpen(this.matterListState.rows[ i ].id) && !this.matterListState.rows[ i ].locked
          && !this.isSelected(this.matterListState.rows[ i ]) && !this.isLinkedMatterLocked(this.matterListState.rows[ i ].id)) {
          this.matterListState.selectedMatters.push(this.matterListState.rows[ i ]);
        }
      }
    } else {
      for (var i = startIndex; i > (endIndex - 1); i--) {
        // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already
        if (!this.isMatterTabOpen(this.matterListState.rows[ i - 1 ].id) && !this.matterListState.rows[ i - 1 ].locked
          && !this.isSelected(this.matterListState.rows[ i - 1 ]) && !this.isLinkedMatterLocked(this.matterListState.rows[ i - 1 ].id)) {
          this.matterListState.selectedMatters.push(this.matterListState.rows[ i - 1 ]);
        }
      }
    }
  }

  getindexofSelectedMatter(id): number {
    return MatterListUtil.getindexofSelectedMatter(id, this.matterListState);
  }

  isSelected(matter: Matter): boolean {
    return MatterListUtil.isSelected(matter, this.matterListState);
  }

  getSurname(surname: MatterParticipant[]): string {
    if (surname.length > 0) {
      let surnameToDisplay: any = _.filter(surname, {'matterParticipantRole': 'PURCHASER', 'primary': true});
      if (surnameToDisplay.length > 0) {
        if (surnameToDisplay[ 0 ].contact.instanceType === 'person') {
          return (surnameToDisplay[ 0 ].contact.contactName) ?
            surnameToDisplay[ 0 ].contact.contactName.lastName : ' ';
        } else {
          return (surnameToDisplay[ 0 ].contact.organizationName) ?
            surnameToDisplay[ 0 ].contact.organizationName : ' ';
        }
      } else {
        return ' ';
      }
    } else {
      return ' ';
    }
  }

  getMatterStatusForDisplay(matterStatus: string): string {
    if (matterStatus === 'INACTIVE') {
      return 'Inactive';
    } else {
      return 'Active';
    }
  }

  getMatterTypeShortName(m: Matter): string {
    return MatterListUtil.getMatterTypeShortName(m);
  }

  getMatterRecordNumber(m: Matter): string {
    return MatterListUtil.getMatterRecordNumber(m);
  }

  getMatterRecordNumberHelpText(m: Matter): string {
    return MatterListUtil.getMatterRecordNumberHelpText(m);
  }

  isBillingCharge(billingTransactions): boolean {
    let billingObj = billingTransactions.filter(item => item.transactionType == 'CHARGE');
    return billingObj.length > 0;
  }

  isBillingRefund(billingTransactions): boolean {
    let billingObj = billingTransactions.filter(item => item.transactionType == 'REFUND');
    return billingObj.length > 0;
  }

  loadMatterRowBurgerMenuOptions(row: Matter): void {
    let rowBurgerMenu = row.matterBurgerMenu;
    if (rowBurgerMenu && rowBurgerMenu.length) {
      this.updateMenuItemsStatus(row, rowBurgerMenu);
    } else {
      this.addToBurgerMenu(rowBurgerMenu, 'Open', null, null, null, this.openMatter);
      if (!this.isMatterReadOnlyAccess && !(row.isPreBilled() && row.isCustomMatter())) {
        this.addToBurgerMenu(rowBurgerMenu, 'Print Debit Notice', null, 'DEBIT', null, this.openPrintDebitCreditNotice);
      }
      if (!this.isMatterReadOnlyAccess && row.reset) {
        this.addToBurgerMenu(rowBurgerMenu, 'Print Credit Notice', null, 'CREDIT', null, this.openPrintDebitCreditNotice);
      }
      if (!this.isMatterReadOnlyAccess && !row.refunded) {
        this.createCopyMenuItems(rowBurgerMenu, row);
      }

      // if(!isMatterReadOnlyAccess){
      //     menuItems.push('Assign To');
      // }
      // if(!isMatterReadOnlyAccess){
      //     menuItems.push('Copy');
      // }
      // if(row.isMatterActive && !isMatterReadOnlyAccess){
      //     menuItems.push('Set to Inactive');
      // }
      // if(!row.isMatterActive && !isMatterReadOnlyAccess){
      //     menuItems.push('Set to Active');
      // }
      // menuItems.push('Export to Accounting Program');
      // if(!isMatterReadOnlyAccess){
      //     menuItems.push('Export to Other Matter Type');
      // }

    }
  }

  updateMenuItemsStatus(matter: Matter, menu: BurgerMenuExtendedItem[]): void {
    let copyMenuItems = menu.filter(item => item.type == 'copy');
    if (copyMenuItems) {
      copyMenuItems.forEach((item) => {
        item.isDisabled = matter.locked || this.isMatterTabOpen(matter.id);
      });
    }
  }

  createCopyMenuItems(rowBurgerMenu: BurgerMenuExtendedItem[], matter: Matter): void {
    let isMenuItemDisabled = matter.locked || this.isMatterTabOpen(matter.id);
    if (matter.isPurchase) {
      this.addToBurgerMenu(rowBurgerMenu, 'Copy Purchase to a Sale as purchaser is now selling', 'copy', MatterTypesValue.SALE, null, this.copyMatter, isMenuItemDisabled);
      this.addToBurgerMenu(rowBurgerMenu, 'Copy Purchase to a Mortgage', 'copy', MatterTypesValue.MORTGAGE, null, this.copyMatter, isMenuItemDisabled);
    }
    if (matter.isMortgage) {
      this.addToBurgerMenu(rowBurgerMenu, 'Copy Mortgage to a Sale', 'copy', MatterTypesValue.SALE, null, this.copyMatter, isMenuItemDisabled);
      this.addToBurgerMenu(rowBurgerMenu, 'Copy Mortgage to a Mortgage', 'copy', MatterTypesValue.MORTGAGE, null, this.copyMatter, isMenuItemDisabled);
    }
    if (!matter.isCustomMatter() && !matter.isProjectSale) {
      this.addToBurgerMenu(rowBurgerMenu, 'Clone Matter', 'copy', matter.matterType, null, this.cloneMatter, isMenuItemDisabled);
    }

  }

  clickMatterRowDropDown(matter: Matter, clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(matter, clickedMenuOption);
    }
  }

  openPrintDebitCreditNotice = (matter: Matter, clickedMenuOption: BurgerMenuExtendedItem) => {
    if (!matter.isBilled()) {
      this.dialogService.confirm('Error', messages.matter.produceDebitNoticeUnAvailable, true).subscribe();
    } else {
      this.matterService.getMatter(matter.id, false).subscribe((matter: Matter) => {
        this.dialogService.matDialogContent({
          content: PrintTransactionModalComponent,
          context: {
            matter: matter,
            noticeType: clickedMenuOption.key,
            account: this.account
          },
          onFulfillment: () => {

          },
          fullScreen: true
        });

      });
    }
  };

  public resetMatterTypeFlag(): void {
    this.matterTypeFlag = false;
  }

  public toggleMatterTypeFlag(event): void {
    // stopPropagation mouse click listener so they don't interrupt these functions
    event.stopPropagation();
    event.preventDefault();
    this.resetActionButtonFlag();
    this.closeBurgerMenu(event);
    this.matterTypeFlag = !this.matterTypeFlag;
  }

  public resetActionButtonFlag(): void {
    this.actionButtonFlag = false;
  }

  public toggleActionButtonFlag(event): void {
    // stopPropagation mouse click listener so they don't interrupt these functions
    event.stopPropagation();
    this.resetMatterTypeFlag();
    this.closeBurgerMenu(event);
    this.actionButtonFlag = !this.actionButtonFlag;
  }

  // this will be handled in a better way as of now for
  //  new matter we are considering matter id is '0'
  addNewMatter = (selectedMenuItem: BurgerMenuExtendedItem): void => {
    this.matterService.createNewMatter((selectedMenuItem.type as MatterType), (selectedMenuItem.key as ProvinceCode)).subscribe(matter => {
      if (matter) {
        this.openMatter(matter);
      }

    });
  };

  addNewProjectMatter = (selectedMenuItem: BurgerMenuExtendedItem): void => {
    let projectId = Number(selectedMenuItem.key);
    //First check if project tab is open
    let project = this.tabsStateService.getProjectFromOpenTabs(projectId);
    if (project && project.locked) {
      this.showProjectErrorMessage(project.projectRecordNumber);
    } else {
      this.projectService.getProject(projectId, false).subscribe((project: Project) => {
        if (project.lockedByOthers) {
          //If project is opened by another user
          this.showProjectErrorMessage(project.projectRecordNumber);
        } else if (!project.fullyInitialized) {
          //If project is initialized
          this.showInitProjectErrorMessage(project);
        } else {
          this.matterService.createNewMatter('SALE', project.provinceCode, project).subscribe(matter => {
            if (matter) {
              this.openMatter(matter);
            }
          });
        }

      });
    }

  };

  showProjectErrorMessage(projectName: string): void {
    this.dialogService.confirm('Error', `Project Sale matters cannot be added to project ${ projectName } while it is being edited`, true);
  }

  showInitProjectErrorMessage(project: Project): void {
    this.dialogService.confirm('Error', PROJECT_MATTER_INCOMPLETE_MSG_ADD, false, 'Open Project').subscribe(result => {
      if (result) {
        this.parentTabsComponent.openProject(project);
      }
    });
  }

  onSearchTextChange = (searchText: string) => {
    if (this.matterListState.searchText != this.matterListState.lastSearchText) {
      this.matterListState.lastSearchText = this.matterListState.searchText;
      this.resetPage();
      this.page();
    }
  };

  searchInput(): void {
    if (this.matterListState.searchText == null || this.matterListState.searchText === '') {

    } else {
      this.page();
    }
  }

  updateStatusFilter(newSelectedStatuses: string[], oldSelectedStatuses: string[]) {
    // There are 4 statuses "ACTIVE", "INACTIVE", "FLAGGED" and "MATTER_PAST_CLOSING_DATE"
    // If user selects Flagged, Past Closing  and/or Ready both Active and Inactive options are automatically deselected.
    // If user select Active and/or Inactive, Then Flagged, Overdue, Ready and all matter closing status options are automatically deselected.
    // If user select any of matter closing status options both Active and Inactive options are automatically deselected.
    if (Array.isArray(newSelectedStatuses) && Array.isArray(oldSelectedStatuses) && newSelectedStatuses.length > 1) {
      let differentOnes: string[] = newSelectedStatuses.filter((newItem) => {
        return !oldSelectedStatuses.includes(newItem);
      });
      if (Array.isArray(differentOnes) && differentOnes.length > 0) {
        if (differentOnes[ 0 ] == MatterOverviewStatusTypesValue.FLAGGED
          || differentOnes[ 0 ] == MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE
          || differentOnes[ 0 ] == MatterOverviewStatusTypesValue.MATTER_READY) {
          //Don't use filter to avoid creating new instance
          this.removeStatusItems(newSelectedStatuses, [ MatterStatus.ACTIVE, MatterStatus.INACTIVE ]);
        } else if (differentOnes[ 0 ] == MatterStatus.ACTIVE
          || differentOnes[ 0 ] == MatterStatus.INACTIVE) {
          this.removeStatusItems(newSelectedStatuses, [ MatterOverviewStatusTypesValue.FLAGGED, MatterOverviewStatusTypesValue.MATTER_PAST_CLOSING_DATE, MatterOverviewStatusTypesValue.MATTER_READY ]);
          this.removeStatusItems(newSelectedStatuses, [ MatterClosingStatus.PENDING, MatterClosingStatus.ESCROW, MatterClosingStatus.IN_FUNDS, MatterClosingStatus.POST_CLOSING, MatterClosingStatus.OCCUPANCY ]);
        } else {
          this.removeStatusItems(newSelectedStatuses, [ MatterStatus.ACTIVE, MatterStatus.INACTIVE ]);
        }
      }
    }
    this.matterListState.selectedMatterStatuses = newSelectedStatuses;
  }

  removeStatusItems(newSelectedStatuses: string[], values: string[]) {
    if (values && values.length > 0) {
      values.forEach(value => {
        this.removeStatusItem(newSelectedStatuses, value);
      });
    }
  }

  removeStatusItem(selectedStatuses: string[], itemName: string): void {
    let itemIndex = selectedStatuses.findIndex(item => item == itemName);
    if (itemIndex > -1) {
      selectedStatuses.splice(itemIndex, 1);
    }
  }

  updateMultiSelectFilters(obj: any[], searchType: string): void {
    if (searchType === 'type') {
      this.matterListState.selectedMatterTypes = obj;
    }
    if (searchType === 'status') {
      this.updateStatusFilter(obj, this.matterListState.selectedMatterStatuses);
    }
    this.resetPage();
    this.page();
  }

  //New requirement, if the data is empty, it is validate date and needs refresh
  onStartDateChange(event) {
    this.matterListState.closingDayFilter = null;

    let closingDateFrom: string = this.getDateFormat(event);
    if (this.isEmptyDate(event)) {
      this.matterListState.closingDateFrom = '';
      this.resetPage();
      this.page();
    } else if (this.validateDate(closingDateFrom)) {
      this.matterListState.closingDateFrom = closingDateFrom;
      this.resetPage();
      this.page();
    } else {
      this.matterListState.closingDateFrom = '';
    }
  }

  onEndDateChange(event) {
    this.matterListState.closingDayFilter = null;

    let closingDateTo: string = this.getDateFormat(event);
    if (this.isEmptyDate(event)) {
      this.matterListState.closingDateTo = '';
      this.resetPage();
      this.page();
    } else if (this.validateDate(closingDateTo)) {
      this.matterListState.closingDateTo = closingDateTo;
      this.resetPage();
      this.page();
    } else {
      this.matterListState.closingDateTo = '';
    }
  }

  getDateFormat(event): string {
    let date: string;
    date = `${ ((event.year) ? event.year : '') }/${ ((event.month) ? event.month : '') }/${ ((event.day) ? event.day : '') }`;
    return date;
  }

  isEmptyDate(event): boolean {
    return !event.year && !event.month && !event.day;
  }

  validateDate(date: string): boolean {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    return pattern.test(date);
  }

  getFilterListforMultiSelect(type): any {
    this.contactService.getSolicitorLawClerkList(type)
    .subscribe(
      (data: Contact[]) => {
        let ret: any[] = [];
        if (type === 'SOLICITOR') {
          ret.push({
            label: `All ${ provinceBasedLawyerTitleInPlural[ this.userStateService.defaultProvinceCode ] }`,
            value: 'ALL'
          }, {
            label: `All Active ${ provinceBasedLawyerTitleInPlural[ this.userStateService.defaultProvinceCode ] }`,
            value: 'ALL_ACTIVE_LAWYERS'
          });
        }
        if (type === 'LAWCLERK') {
          ret.push({
            label: `All ${ this.lawClerkTitle }s`,
            value: 'ALL'
          }, {
            label: `All Active ${ this.lawClerkTitle }s`,
            value: 'ALL_ACTIVE_LAWCLERKS'
          });
        }
        ret.push({
          label: 'Not Assigned',
          value: 'N/A'
        });
        for (let i = 0; i < data.length; i++) {
          let middleName = data[ i ].contactName.middleName ? data[ i ].contactName.middleName + ' ' : '';
          if (data[ i ].activeFlag === 'NO') {
            ret.push({
              label: data[ i ].contactName.firstName + ' ' + middleName + data[ i ].contactName.lastName + ' (inactive)',
              value: data[ i ].id
            });
          } else {
            ret.push({
              label: data[ i ].contactName.firstName + ' ' + middleName + data[ i ].contactName.lastName,
              value: data[ i ].id
            });
          }
        }

        if (type === 'SOLICITOR') {
          this.matterListState.lawyersList = ret;
        }
        if (type === 'LAWCLERK') {
          this.matterListState.clerksList = ret;
        }

      });
  }

  sortMatterList(s): void {
    if (this.isRecentMattersView) {
      //if in 'My Recent' view, then shows the result from server as is (sorted by lastAccessedDate
      return;
    }

    if (s === this.matterListState.sortQuery) {
      if (this.matterListState.sortType === 'ASC') {
        this.matterListState.sortType = 'DESC';
      } else {
        this.matterListState.sortType = 'ASC';
      }
    } else {
      this.matterListState.sortType = 'ASC';
    }
    this.matterListState.sortQuery = s;
    this.currentSearchView.matterListFilterSortBy = s;
    this.currentSearchView.matterListFilterSortByOrder = this.matterListState.sortType;
    this.resetPage();
    this.page();
  }

  getNextPage(): void {
    if (!this.matterListState.listEndReached) {
      this.page();
    }
  }

  resetPage(): void {
    this.matterListState.selectedMatters = [];
    this.matterListState.rows = [];
    this.matterListState.pageNo = 1;
    this.matterListState.listEndReached = false;
  }

  incrementPage(): void {
    this.matterListState.pageNo = this.matterListState.pageNo + 1;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.matterListState.scrollY = this.window.nativeWindow.scrollY;
  }

  tabOnBurgerMenu(i) {
    return (i === this.lastVisitedRowIndex);
  }

  addClassToRow(i: number) {
    // when you focus on burger menu in row we add background color to row
    //        jQuery(e.target).parent().parent().parent().parent().addClass("active-hover");
    let selector = 'tr:eq(' + (i + 2) + ')';
    this.removeClassFromRows();
    jQuery(selector).addClass('active-hover');

  }

  removeClassFromRows() {
    // when you blur on burger menu in row we remove background color to row
    jQuery('tr').removeClass('active-hover');

  }

  blurMatterTypeDropdown(e) {
    /// close the menu when tabbed on last item in list
    jQuery(e.target).blur();
    this.resetMatterTypeFlag();
    this.resetActionButtonFlag();
  }

  onKeyUp() {
    //If matter list filter is 'My Recent', it will change to 'All Matters' after anything input in searchText field
    if (this.matterListState.searchText && this.currentSearchView && this.currentSearchView.matterListFilterName == DefaultMatterListFilterName.MY_RECENT) {
      this.currentSearchView = this.findAllMatterFilter();
    }
    //start search the matter based on existing filter
  }

  findAllMatterFilter(): MatterListFilter {
    if (Array.isArray(this.searchViews) && this.searchViews.length > 0) {
      return this.searchViews.find(item => item.matterListFilterName == DefaultMatterListFilterName.ALL_MATTERS);
    }
  }

  findMyRecentFilter() {
    if (Array.isArray(this.searchViews) && this.searchViews.length > 0) {
      return this.searchViews.find(item => item.matterListFilterName == DefaultMatterListFilterName.MY_RECENT);
    }
  }

  onSearchViewChange(event) {
    if (this.isMassUpdateTab()) {
      this.massUpdateMatterListFilter = event;
    }

    if (this.isAllMattersView || this.isRecentMattersView) {
      this.resetMatterListState();
    }

    if (this.isAllMattersView) {
      this.matterListState.showFilters = true;
      if (this.isProjectSaleInMatterTypes) {
        this.addProjectSaleToSelectedMatterTypes();
      }
    } else if (!this.isRecentMattersView) {// Neither "All Matters" nor "My Recent"
      this.updateMatterListState(event); //Use other filter to update matterListState
      this.matterListState.showFilters = true;
    }
    this.matterListState.searchText = '';
    if (!this.isMassUpdateTab()) {
      this.setDefaultMatterListFilter(this.currentSearchView);
    }
    this.resetPage();
    this.page();
    this.initFilters();
  }

  getAllMatterTypes(): any[] {
    return Array.isArray(this.matterTypes) && this.matterTypes.length > 0 ? this.matterTypes : [];
  }

  resetMatterListState() {
    this.matterListState.searchText = undefined;
    this.matterListState.closingDateFrom = '';
    this.matterListState.closingDateTo = '';
    this.matterListState.selectedMatterTypes = this.getAllMatterTypes().filter(i => !i.disabled).map(item => item.value);
    if (this.isAllMattersView && this.isProjectSaleInMatterTypes) {
      this.addProjectSaleToSelectedMatterTypes();
    }
    this.matterListState.selectedProvinceCode = this.provinceCode.map(item => item.value);
    this.loadAllReportTemplates(this.matterListState.selectedProvinceCode);
    this.matterListState.selectedMatterStatuses = [ MatterStatus.ACTIVE ];
    this.matterListState.selectedActionRequiredFilters = [];
    this.matterListState.selectedLawyers = [ 'ALL' ];
    this.matterListState.selectedClerks = [ 'ALL' ];
    this.matterListState.selectedProjects = [ 'ALL' ];
    this.matterListState.taskStatusFilter = '';
    this.matterListState.taskDescription = '';
  }

  updateMatterListState(matterListFilter: MatterListFilter) {
    if (!matterListFilter) {
      return;
    }

    this.matterListState.selectedMatterTypes = matterListFilter.matterTypeFilters;
    this.matterListState.selectedProvinceCode = matterListFilter.provinceCodeFilters;
    this.loadAllReportTemplates(this.matterListState.selectedProvinceCode);
    this.matterListState.selectedMatterStatuses = matterListFilter.matterStatusFilters;
    this.matterListState.selectedActionRequiredFilters = matterListFilter.actionRequiredFilters;
    this.matterListState.selectedLawyers = matterListFilter.selectedLawyerContactIds;
    this.matterListState.selectedClerks = matterListFilter.selectedClerkContactIds;
    this.matterListState.selectedProjects = matterListFilter.selectedProjectIds;
    this.matterListState.closingDayFilter = matterListFilter.closingDayFilter;
    this.matterListState.taskStatusFilter = matterListFilter.taskStatusFilter;
    this.matterListState.taskDescription = matterListFilter.taskDescription;
  }

  get isAllMattersView(): boolean {
    return this.isAllMatters(this.currentSearchView);
  }

  get isRecentMattersView(): boolean {
    return this.isMyRecent(this.currentSearchView);
  }

  get isProjectSaleInMatterTypes(): boolean {
    return this.matterTypes && this.matterTypes.find(matterType => matterType.label === PROJECT_SALE_MATTER);
  }

  addProjectSaleToSelectedMatterTypes() {
    if (Array.isArray(this.matterListState.selectedMatterTypes) && !this.matterListState.selectedMatterTypes.find(item => item == PROJECT_SALE_MATTER_OPTION_VALUE)) {
      this.matterListState.selectedMatterTypes.push(PROJECT_SALE_MATTER_OPTION_VALUE);
    }
  }

  isAllMatters(matterListFilter: MatterListFilter): boolean {
    return matterListFilter && matterListFilter.matterListFilterName === DefaultMatterListFilterName.ALL_MATTERS;
  }

  isMyRecent(matterListFilter: MatterListFilter): boolean {
    return matterListFilter && matterListFilter.matterListFilterName === DefaultMatterListFilterName.MY_RECENT;

  }

  searchInputPlaceHolder(): string {
    let placeHolderText: string = 'Matter No., Matter RE: Line, Address/Description';
    if (this.isAllMattersView) {
      if (!this.matterListState.searchText) {
        return placeHolderText;
      }
    } else {
      return placeHolderText;
    }
    return '';
  }

  get showFilters(): boolean {
    return this.matterListState.showFilters && !this.isRecentMattersView;
  }

  createMatterListFilter(): MatterListFilter {
    const matterListFilter: MatterListFilter = new MatterListFilter();
    matterListFilter.filterType = FilterTypeValue.matter;

    if (this.matterListState.sortQuery) {
      matterListFilter.matterListFilterSortBy = this.matterListState.sortQuery;
    }

    if (this.currentSearchView) {
      if (!this.currentSearchView.matterListFilterSortByOrder) {
        this.currentSearchView.matterListFilterSortByOrder = 'DESC';
      }
      matterListFilter.matterListFilterSortByOrder = this.currentSearchView.matterListFilterSortByOrder;
    }

    if (this.account && this.account.id) {
      matterListFilter.customerAccountId = this.account.id;
    }

    if (this.matterListState.selectedMatterStatuses) {
      matterListFilter.matterStatusFilters = this.matterListState.selectedMatterStatuses.slice();
    }

    if (this.matterListState.selectedMatterTypes) {
      matterListFilter.matterTypeFilters = this.matterListState.selectedMatterTypes.slice();
    }

    if (this.matterListState.selectedProvinceCode) {
      matterListFilter.provinceCodeFilters = this.matterListState.selectedProvinceCode.slice();
    }

    if (this.matterListState.selectedActionRequiredFilters) {
      matterListFilter.actionRequiredFilters = this.matterListState.selectedActionRequiredFilters.slice();
    }

    if (this.matterListState.selectedLawyers) {
      matterListFilter.selectedLawyerContactIds = this.matterListState.selectedLawyers.find(item => item == 'ALL') ? [ 'ALL' ] : this.matterListState.selectedLawyers.slice();
      //matterListFilter.selectedLawyerContactIds = ['ALL'];
    }

    if (this.matterListState.selectedClerks) {
      matterListFilter.selectedClerkContactIds = this.matterListState.selectedClerks.find(item => item == 'ALL') ? [ 'ALL' ] : this.matterListState.selectedClerks.slice();
      //matterListFilter.selectedClerkContactIds = ['ALL'];
    }

    if (this.matterListState.selectedProjects) {
      matterListFilter.selectedProjectIds = this.matterListState.selectedProjects.find(item => item == 'ALL') ? [ 'ALL' ] : this.matterListState.selectedProjects.slice();
      //matterListFilter.selectedClerkContactIds = ['ALL'];
    }

    if (this.matterListState.closingDayFilter || this.matterListState.closingDayFilter === 0) {
      matterListFilter.closingDayFilter = this.matterListState.closingDayFilter;
    }

    if (this.matterListState.taskStatusFilter) {
      matterListFilter.taskStatusFilter = this.matterListState.taskStatusFilter;
      if (this.matterListState.taskDescription) {
        matterListFilter.taskStatusFilter = this.matterListState.taskDescription;
      }
    }
    return matterListFilter;
  }

  // For Creating a  New Matter List Filter
  createNewMatterListFilter() {
    if (!this.currentSearchView.isReadOnly) {
      const matterListFilter: MatterListFilter = this.createMatterListFilter();
      MatterListUtil.createNewMatterListFilter(matterListFilter, this.dialogService, this.isMassUpdateTab(), this.updateMatterListFilterStatus);
    } else {
      this.dialogService.confirm('Error', 'The filter cannot be Saved because it contains a Province you do not have access to.', true);
    }

  }

  // For Updating  the existing Matter List Filter
  updateMatterListFilter() {
    if (!this.currentSearchView.isReadOnly) {
      const matterListFilter: MatterListFilter = this.createMatterListFilter();
      matterListFilter.matterListFilterName = this.currentSearchView.matterListFilterName;
      MatterListUtil.updateMatterListFilter(matterListFilter, this.currentSearchView.id, this.matterService, this.dialogService, this.updateMatterListFilterStatus);
    } else {
      this.dialogService.confirm('Error', 'The filter cannot be Updated.', true);
    }
  }

  deleteMatterListFilter() {
    if (!this.currentSearchView.isReadOnly) {
      MatterListUtil.deleteMatterListFilter(this.currentSearchView.matterListFilterName, this.currentSearchView.id, this.matterService, this.dialogService, this.updateSearchViewsAndSetToDefaultView);
    } else {
      this.dialogService.confirm('Error', 'The filter cannot be Deleted because it contains a Province you do not have access to.', true);
    }

  }

  updateMatterListFilterStatus = (matterListFilter: MatterListFilter) => {
    this.matterService.getAllMatterListFilter(FilterTypeValue.matter)
    .subscribe(
      (data: MatterListFilter[]) => {
        this.updateSearchViews(data);
        // this.updateDefaultFilter();
        if (matterListFilter) {
          const searchView = this.searchViews.find(item => matterListFilter.matterListFilterName == item.matterListFilterName);
          if (searchView) {
            this.currentSearchView = searchView;
            if (!this.isMassUpdateTab()) {
              this.setDefaultMatterListFilter(this.currentSearchView);
            } else {
              this.massUpdateMatterListFilter = searchView;
            }
          }
        }
        this.matterListState.showFilters = true;
      });
  };

  updateSearchViews(data: MatterListFilter[]) {
    this.searchViews = [];
    MatterListUtil.updateSearchViews(data, this.searchViews, this.isMassUpdateTab());
  }

  async clickActionDropDown(event) {
    //ToDo: For 'open' using key and for other options using text. this needs to be cleaned up.
    if (event.key == 'Open') {
      this.checkMultipleMatters();
    } else if (event.text == 'Multiple Documents') {
      this.processMultipleDocuments(false);
    } else if (event.text == 'Single Document Mail Merge') {
      this.processMultipleDocuments(true);
    } else if (event.key == 'Export') {
      await this.sendDataToExport();
    } else if (event.key == 'ExpotCSV') {
      this.exportAllToCSV(event.id);
    }
  }

  exportAllToCSV(templateId: number): void {
    this.matterService.exportMatters(templateId, this.matterListState);
  }

  verifyProjectMatterFullyInitialized(): string {
    let messageString: string;
    let selectedMatterInitCheckFailed = this.matterListState.selectedMatters.filter(item => !!item.unityProjectId && !item.fullyInitialized);
    if (selectedMatterInitCheckFailed && selectedMatterInitCheckFailed.length > 0) {
      messageString = '<div>Project for Selected Matter are in an Incomplete state.  Open the project for edit to complete the initialization.</div><div class="padding-top-10 padding-bottom-10"><span class="inline-block width-200 semi-bold">Matter Number</span>';
      selectedMatterInitCheckFailed.forEach(item => {
        messageString = messageString + ' <div class="padding-bottom-5"><span class="inline-block width-200">' + item.matterRecordNumber + '</span>';
      });
    }
    return messageString;

  }

  processMultipleDocuments(isMergeDocuments: boolean): void {
    if (this.matterListState && this.matterListState.selectedMatters) {
      if (_.uniqBy(this.matterListState.selectedMatters, 'matterType').length > 1) {
        this.dialogService.confirm('Error',
          'Matters of only one transaction type (i.e. Purchase, Sale or Mortgage) can be selected.', true).subscribe(() => {

        });
      } else if (_.uniqBy(this.matterListState.selectedMatters, 'documentProfileId').length > 1) {
        let messageString = '<div>Selected matters must belong to one document profile.</div><div class="padding-top-10 padding-bottom-10"><span class="inline-block width-200 semi-bold">Matter Number</span><span class="inline-block width-200 semi-bold">Document Profile</span></div>';
        this.matterListState.selectedMatters.forEach(item => {
          let documentProfile = this.DocumentProfileList ? this.DocumentProfileList.find(dp => dp.id == item.documentProfileId) : undefined;
          messageString = messageString + ' <div class="padding-bottom-5"><span class="inline-block width-200">' + item.matterRecordNumber + '</span><span class="inline-block width-200">' + (documentProfile ? documentProfile.profileName : '') + '</span></div>';
        });
        const contentModalConfig: DialogConfigParams = {
          title: 'Error',
          message: messageString,
          hideCancelBtn: true,
          alignContentLeft: true
        };
        this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(() => {

        });

      } else if (this.verifyProjectMatterFullyInitialized()) {
        const contentModalConfig: DialogConfigParams = {
          title: 'Error',
          message: this.verifyProjectMatterFullyInitialized(),
          hideCancelBtn: true,
          alignContentLeft: true
        };
        this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(() => {

        });
      } else {
        if (this.isAllMattersSelected() && !this.matterListState.listEndReached) {
          let dialogRef: MatDialogRef<any>;
          let docProdShareMessageTitle = 'Document Production and Sharing';
          let docProdShareMessage = 'To generate documents for more than ' + this.matterListState.selectedMatters.length.toString() + ' matters, please scroll to load additional matters before making your selections.';
          dialogRef = this.dialogService.confirmDialog(docProdShareMessageTitle, docProdShareMessage, false, 'Proceed');
          dialogRef.afterClosed().subscribe(res => {
            if (res) {
              this.openDocTemplates(isMergeDocuments);
            }
          });
        } else {
          this.openDocTemplates(isMergeDocuments);
        }
      }
    }
  }

  openDocTemplates(isMergeDocuments: boolean) {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.documentProfileService.getById(this.matterListState.selectedMatters[ 0 ].documentProfileId, accountId, false, this.matterListState.selectedMatters[ 0 ]).subscribe((documentProfile: DocumentProfile) => {
      if (documentProfile) {
        this.openDocTemplateList(isMergeDocuments, documentProfile);
      } else {
        const contentModalConfig: DialogConfigParams = {
          title: 'Error',
          message: 'Document Profile can not be loaded',
          hideCancelBtn: true,
          alignContentLeft: true
        };
        this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(() => {

        });
      }

    }, (error) => {
      const contentModalConfig: DialogConfigParams = {
        title: 'Error',
        message: 'Document Profile can not be loaded',
        hideCancelBtn: true,
        alignContentLeft: true
      };
      this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(() => {

      });
    });
  }

  shareResultNotificationHandler = (result: BulkSharingResponse) => {
    let message = 'All the specified documents have been produced and shared successfully. ';
    let messageType: ErrorType = 'NOTIFICATION';
    if (result.bulkSharingResult != bulkShareResultStatus.COMPLETED) {
      message = 'There were unexpected errors producing and/or sharing some of the specified documents. ';
      messageType = 'ERROR';
    }
    let dPError: DPError = DPError.createCustomDPError('bulkshare.result.notification', message, '', messageType);
    dPError.showBubbleNotification = true;
    this.errorService.showErrorNotificationMessageBubble(dPError);
  };

  openDocTemplateList(isMergeDocuments: boolean, documentProfile: DocumentProfile): void {
    let selectedMatterIds = this.matterListState.selectedMatters.map(item => {
      return item.id;
    });
    this.dialogService.matDialogContent({

      content: DocumentTemplateModalComponent,
      context: {
        selectedMatterId: this.matterListState.selectedMatters[ 0 ].id,
        applicableMatterTypeCode: this.matterListState.selectedMatters[ 0 ].derivedMatterType,
        provinceCode: this.matterListState.selectedMatters[ 0 ].provinceCode,
        matterIds: selectedMatterIds,
        isMergeDocuments: isMergeDocuments,
        isDocumentProfileProccessorTypeWordPerfect: documentProfile.isDocumentProfileProccessorTypeWordPerfect(),
        statementAdjustmentComponent: this.statementAdjustmentComponent,
        directionReFundsComponent: this.directionReFundsComponent,
        shareResultNotificationHandler: this.shareResultNotificationHandler
      },
      onFulfillment: (matterDocumentId: number) => {
        if (matterDocumentId && isMergeDocuments) {
          this.statusBarService.currentHelpText = 'Processing';
          let userCancelProduceDocFlag = false;
          let dialogRef: MatDialogRef<any>;
          dialogRef = this.dialogService.confirmDialog('Document Production', 'Document is being generated. Please wait.', true, 'Cancel');
          dialogRef.afterClosed().subscribe(res => {
            if (res === undefined) {
              console.log('>> popup closed programmatically due to pdf generated, popup return status', res);
            } else {
              userCancelProduceDocFlag = true;
              console.log('>> User cancel the pdf generation, popup return status', res);
              this.statusBarService.currentHelpText = 'Document generation cancelled';
            }
          });
          let checkGenStatusIntervalID = window.setInterval(() => {
            this.documentProductionService.getAccountDocumentStatus(matterDocumentId)
            .subscribe(data => {
              let generationProgressStatus: string = data;
              if (generationProgressStatus === 'COMPLETED' || userCancelProduceDocFlag) {
                window.clearInterval(checkGenStatusIntervalID);
                console.log('>> pdfGenerationProgressStatus ? ' + generationProgressStatus + ', userCancelPdfGeneration ? ' + userCancelProduceDocFlag);
                this.statusBarService.currentHelpText = '';
                dialogRef.close();

                if (!userCancelProduceDocFlag) {
                  const contentModalConfig: DialogConfigParams = {
                    title: 'Document Production',
                    message: 'Document generation is complete. Do you want to open the document?',
                    hideCancelBtn: false,
                    customConfirmBtnText: 'Open',
                    confirmFunction: () => {
                      if (documentProfile.isDocumentProfileProccessorTypeWordPerfect()) {
                        this.documentProductionService.getDocument(this.matterListState.selectedMatters[ 0 ].id, String(matterDocumentId))
                        .subscribe(data => {
                          let isLockedByUser: boolean = data.isLockedByUser;
                          if (isLockedByUser) {
                            console.log('This document is already opened......');
                            this.dialogService.confirmDialog('Document Production Error', 'Document ' + data.documentName + ' is already open in Word Perfect. Please' +
                              ' select' +
                              ' document from task bar.', true, 'OK');

                          } else {
                            this.documentProductionService.openDocumentForEdit(String(data.id), data.documentName, 'WORDPERFECT');
                          }
                        });
                      } else {
                        this.documentProductionService.openFileInNewWindow(this.matterListState.selectedMatters[ 0 ].id, matterDocumentId, true, false);
                      }
                    }
                  };
                  this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => {
                    console.log('>> Response from openPdfModal: ', res);
                  });
                }
              }
            });
          }, 500);

        }

      }
    });
  }

  isMassUpdateTab(): boolean {
    return this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMassUpdateSubType();
  }

  isMassUpdatePrecedentTab(): boolean {
    return this.isMassUpdateTab() && (this.tabsStateService.activeTab as MassUpdateTab).massUpdateStage == 'PRECEDENT_LEVEL';
  }

  isMassUpdateTabTypeSoaGlCodes(): boolean {
    return this.isMassUpdateTab() && (this.tabsStateService.activeTab as MassUpdateTab).isMassUpdateTabTypeSoaGlCodes();
  }

  isMassUpdateTabTypeStatementOfAccountOrTL(): boolean {
    return this.isMassUpdateTab() &&
      ((this.tabsStateService.activeTab as MassUpdateTab).isMassUpdateTabTypeStatementOfAccount()
        || (this.tabsStateService.activeTab as MassUpdateTab).isMassUpdateTabTypeTrustLedger());
  }

  removePrecedent(matters: Matter[]): Matter[] {
    let index = matters.findIndex(matter => {
      return matter.id === (this.tabsStateService.activeTab as MassUpdateTab).matter.id;
    });
    if (index !== -1) {
      matters.splice(index, 1);
    }
    return matters;
  }

  get loggedInUserId(): number {
    return Number(Utils.getAuthenticatedUserId());
  }

  copyMatter = async (matter: Matter, clickedMenuOption: BurgerMenuExtendedItem) => {
    let canCreateMatter = await this.matterService.checkMatterRestrictedPeriod(<MatterType>clickedMenuOption.key, matter.provinceCode);
    if (canCreateMatter) {
      this.openCopyMatterModal(matter, clickedMenuOption);
    } else {
      this.matterService.showRestrictionErrorMessage();
    }
  };

  openCopyMatterModal = (matter: Matter, clickedMenuOption: BurgerMenuExtendedItem) => {
    //get Full Matter Object
    this.lockScreenService.lockForUpdate = true;
    this.matterService.getMatter(matter.id)
    .finally(() => {
      this.lockScreenService.lockForUpdate = false;
    })
    .subscribe((sourceMatter: Matter) => {
      if (sourceMatter.lockedByUser && sourceMatter.lockedByUser.id != this.loggedInUserId) {
        let errorMessage = `Matter ${ sourceMatter.matterRecordNumber } can not be copied as it is locked for edit by ${ sourceMatter.lockedByUser.firstName } ${ sourceMatter.lockedByUser.lastName }`;
        this.showCopyMatterErrorMessage(errorMessage);
        this.showMatterLockAndDisableCopyMenuItems(sourceMatter);
      } else {
        this.dialogService.matDialogContent({
          content: CopyMatterModalComponent,
          context: {
            sourceMatter: sourceMatter,
            modalTitle: clickedMenuOption.text,
            targetMatterType: clickedMenuOption.key,
            tabsComponent: this.parentTabsComponent,
            tabsService: this.tabsStateService,
            copyMatterService: this.copyMatterService
          },
          modalGrid: 5,
          onFulfillment: (result) => {
            if (result) {

            }
          }
        });
      }

    });

  };

  isMatterCheckboxDisabled(matter: Matter): boolean {
    return this.isMatterTabOpen(matter.id) || this.isLinkedMatterLocked(matter.id);
  }

  cloneMatter = async (matter: Matter, clickedMenuOption: BurgerMenuExtendedItem) => {
    let canCreateMatter = await this.matterService.checkMatterRestrictedPeriod(matter.matterType, matter.provinceCode);
    if (canCreateMatter) {
      this.copyToSameMatterType(matter, clickedMenuOption);
    } else {
      this.matterService.showRestrictionErrorMessage();
    }
  };

  copyToSameMatterType = async (matter: Matter, clickedMenuOption: BurgerMenuExtendedItem) => {
    //Get the sourceMatter information without locking it
    let sourceMatter: Matter = await this.matterService.getMatter(matter.id, false).toPromise();
    if (sourceMatter.lockedByUser && sourceMatter.lockedByUser.id && sourceMatter.lockedByUser.id != this.loggedInUserId) {
      let errorMessage = `Matter ${ sourceMatter.matterRecordNumber } can not be copied as it is locked for edit by ${ sourceMatter.lockedByUser.firstName } ${ sourceMatter.lockedByUser.lastName }`;
      this.showCopyMatterErrorMessage(errorMessage);
      this.showMatterLockAndDisableCopyMenuItems(sourceMatter);
    } else {
      this.lockScreenService.lockForUpdate = true;
      let isAnyContactLocked = await this.isAnyMatterContactLocked(sourceMatter);
      this.lockScreenService.lockForUpdate = false;
      if (!isAnyContactLocked) {
        this.lockScreenService.lockForUpdate = true;
        let targetMatter = await this.copyMatterService.copyMatterWithSameMatterType(sourceMatter);
        this.lockScreenService.lockForUpdate = false;
        if (targetMatter) {
          targetMatter.dirty = true;
          this.openMatter(targetMatter);
        }
      }
    }

  };

  async isAnyMatterContactLocked(matter: Matter): Promise<boolean> {
    let locked = false;
    let contactCalls: Observable<Contact>[] = [];
    let lockedContacts: Contact[] = [];
    let matterParticipantsLockedByUsers = matter.matterParticipants.filter(mp => mp.contact && mp.contact.lockedByUser);
    if (matterParticipantsLockedByUsers && matterParticipantsLockedByUsers.length) {
      for (let mp of matterParticipantsLockedByUsers) {
        locked = true;
        lockedContacts.push(mp.contact);
      }
    }
    //Excluding OtherSide Lawclerk (OTHERPARTY_LAWCLERK) from the participants as it will copied as it is.
    let matterParticipantsWithSourceContactId = matter.matterParticipants.filter(mp => mp.contact && mp.contact.sourceContactId
      && MatterParticipantRoleTypes.OTHERPARTY_LAWCLERK != mp.matterParticipantRole);
    for (let mp of matterParticipantsWithSourceContactId) {
      contactCalls.push(this.contactQueryService.getContactForMatter(mp.contact.sourceContactId));
    }
    if (contactCalls && contactCalls.length) {
      let contacts: Contact[] = await forkJoin(contactCalls).toPromise();
      for (let contact of contacts) {
        if (contact.lockedByUser) {
          locked = true;
          lockedContacts.push(contact);
        }
      }
    }
    if (lockedContacts && lockedContacts.length) {
      let errorMessage = '';
      if (lockedContacts.length == 1) {
        errorMessage = `Matter ${ matter.matterRecordNumber } can not be copied due to contact ${ lockedContacts[ 0 ].displayName } locked for edit by ${ lockedContacts[ 0 ].lockUserName }.`;
      } else {
        errorMessage = `Matter ${ matter.matterRecordNumber } can not be copied due to:<br><br>`;
        for (let contact of lockedContacts) {
          errorMessage += `Contact ${ contact.displayName } locked for edit by ${ contact.lockUserName }<br>`;
        }
      }
      this.showCopyMatterErrorMessage(errorMessage);
    }
    return locked;
  }

  showCopyMatterErrorMessage(errorMessage: string) {
    this.dialogService.confirm('Error', errorMessage, true, '', '', true);

  }

  showMatterLockAndDisableCopyMenuItems(matter: Matter): void {
    let matterRow = this.matterListState.rows.find(row => row.id == matter.id);
    matterRow.locked = matter.locked;
    matterRow.lockedByUser = matter.lockedByUser;
  }

  onClosingInNDaysFilterChange() {
    // <input type="number" doesn't support maxlength
    let closingDayFilter: string = this.matterListState.closingDayFilter + ''; //Use + '' to change number to string
    if (closingDayFilter && closingDayFilter.length > CLOSING_DAY_FILTER_MAXLENGTH) {
      setTimeout(() => {
        // setTimeout ensures the view value to be updated
        this.matterListState.closingDayFilter = parseInt(closingDayFilter.slice(0, 3));
      }, 1);

    }
    this.matterListState.closingDateFrom = '';
    this.matterListState.closingDateTo = '';
    this.resetPage();
    this.page();
  }

  openFiltersModal(): void {
    this.dialogService.matDialogContent({
      content: MatterAdditionalFiltersModalComponent,
      context: {
        matterListState: this.matterListState,
        lawyerTitle: this.lawyerTitle,
        lawClerkTitle: this.lawClerkTitle,
        accountAccessibleProvinces: this.accountAccessibleProvinces,
        provinceCode: this.provinceCode,
        isMassUpdate: this.isMassUpdateTab(),
        actionRequiredFilters: this.actionRequiredFilters,
        defaultProvinceCode: this.userStateService.defaultProvinceCode
      },
      //widthXl: true,
      modalGrid: 10,
      onFulfillment: (result: any) => {
        if (result && result.isMatterListStateUpdates) {
          console.log(result.matterListState);
          if (result.isProvinceFilterUpdated) {
            this.loadAllReportTemplates(this.matterListState.selectedProvinceCode);
          }
          this.resetPage();
          this.page();
        }
      },

    });
  }

  resetFilters(): void {
    if (this.matterListState) {
      this.matterListState.selectedMatterTypes = [ 'ALL' ];
      this.matterListState.selectedMatterStatuses = [ 'ACTIVE' ];
      this.matterListState.closingDayFilter = null;
      this.matterListState.closingDateFrom = null;
      this.matterListState.closingDateTo = null;
      this.matterListState.selectedLawyers = [ 'ALL' ];
      this.matterListState.selectedClerks = [ 'ALL' ];
      this.matterListState.selectedProjects = [ 'ALL' ];
      this.matterListState.selectedProvinceCode = [ 'ALL' ];
      this.matterListState.selectedActionRequiredFilters = [];
      this.matterListState.taskStatusFilter = '';
      this.matterListState.taskDescription = '';
      this.matterListState.currentSearchView = this.searchViews.find(item => item.matterListFilterName == DefaultMatterListFilterName.ALL_MATTERS);
      this.resetPage();
      this.page();
    }
  }

  isAnyVisibleFiltersChanged(): boolean {
    return this.matterListState && (
      this.matterListState.selectedMatterTypes && !this.matterListState.selectedMatterTypes.includes('ALL') ||
      this.matterListState.selectedMatterStatuses && !(this.matterListState.selectedMatterStatuses.length == 1 && this.matterListState.selectedMatterStatuses[ 0 ] == 'ACTIVE') ||
      !!this.matterListState.closingDayFilter ||
      !!this.matterListState.closingDateFrom ||
      !!this.matterListState.closingDateTo
    );

  }

  isAnyHiddenFiltersChanged(): boolean {
    return this.matterListState && (
      this.matterListState.selectedLawyers && !this.matterListState.selectedLawyers.includes('ALL') ||
      this.matterListState.selectedClerks && !this.matterListState.selectedClerks.includes('ALL') ||
      this.matterListState.selectedProjects && !this.matterListState.selectedProjects.includes('ALL') ||
      this.matterListState.selectedProvinceCode && !this.matterListState.selectedProvinceCode.includes('ALL') ||
      this.matterListState.selectedActionRequiredFilters && this.matterListState.selectedActionRequiredFilters.length > 0 ||
      !!this.matterListState.taskStatusFilter || !!this.matterListState.taskDescription
    );
  }

  isResetFilterVisible(): boolean {
    return this.isAnyHiddenFiltersChanged() || this.isAnyVisibleFiltersChanged();
  }

  async getBilledMattersForThisMonthText(): Promise<void> {
    this.billedMattersForThisMonthText = await this.accountService.getBilledMattersForThisMonthText();
  }
}
