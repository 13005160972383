<form class="form-horizontal" id="purchaseParcelRegistersForm" #purchaseParcelRegistersForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect &trade;: {{searchTitle}}</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="onCancel()">&times;</button>
            </div>

        </div>

        <div class="row modal-body margin-top-40" [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">

            <div class="form-group col-lg-12">
                <label class="control-label col-lg-1" for="lro" >LRO: </label>
                <div class="col-lg-2">
                    <input type="text" id="lro" name="lro" [ngModel]="teranetSearchRequest.selectedLro.officeNameAndNumber"
                           [dp-read-only]="true"
                           tabindex="-1"
                           class="form-control "  >
                </div>
                <label class="control-label col-lg-2" for="searchedLabel" >{{searchLabel}}</label>
                <div class="col-lg-7 padding-right-15">
                    <input type="text" id="searchedLabel" name="searchedLabel"
                           [dp-read-only]="true"
                           tabindex="-1" [ngModel]="searchLabelValue"
                           class="form-control "  >
                </div>


            </div>

            <div *ngFor="let propertyPin of teranetSearchResponse.searchedProperties; let j=index">
                <div class="bordered-section col-lg-12 padding-20">
                    <div class="col-lg-12">
                        <div class="form-group col-lg-2 ">
                            <dp-checkbox fieldId="purchaseCheck_{{propertyPin.identifier}}" fieldClass="focus-first">
                                <input type="checkbox"
                                       id="purchaseCheck_{{propertyPin.identifier}}" name="purchaseCheck_{{propertyPin.identifier}}"
                                       [attr.checked]="propertyPin.purchasePin? 'checked' : null"
                                       [(ngModel)]="propertyPin.purchasePin"
                                >
                            </dp-checkbox>
                            <label class="control-label margin-bottom-7"
                                   (click)="propertyPin.purchasePin=!propertyPin.purchasePin">Purchase</label>
                        </div>

                        <label class="control-label col-lg-1" for="pin_{{propertyPin.identifier}}" >PIN: </label>
                        <div class="col-lg-1 margin-right-20">
                            <input type="text" id="pin_{{propertyPin.identifier}}" name="pin_{{propertyPin.identifier}}" [ngModel]="propertyPin.pin"
                                   [dp-read-only]="true"
                                   tabindex="-1"
                                   class="form-control width-percent-145"  >
                        </div>

                        <label class="control-label col-lg-1" for="regType_{{propertyPin.identifier}}" >Reg. Type: </label>
                        <div class="col-lg-1">
                            <input type="text" id="regType_{{propertyPin.identifier}}" name="regType_{{propertyPin.identifier}}" [ngModel]="propertyPin.registrationTypeForDisplay"
                                   [dp-read-only]="true"
                                   tabindex="-1"
                                   class="form-control "  >
                        </div>

                        <label class="control-label col-lg-1 margin-left-minus-20" for="status_{{propertyPin.identifier}}" >Status: </label>
                        <div class="col-lg-1">
                            <input type="text" id="status_{{propertyPin.identifier}}" name="status_{{propertyPin.identifier}}" [ngModel]="propertyPin.statusIndForDisplay"
                                   [dp-read-only]="true"
                                   tabindex="-1"
                                   class="form-control width-85 "  [ngClass]="{'inactive-pin' : !propertyPin.isActive}">
                        </div>

                        <label class="control-label col-lg-1" for="address_{{propertyPin.identifier}}" >Address: </label>
                        <div class="col-lg-3">
                            <input type="text" id="address_{{propertyPin.identifier}}" name="address_{{propertyPin.identifier}}" [ngModel]="propertyPin.fullAddress"
                                   [dp-read-only]="true"
                                   tabindex="-1"
                                   class="form-control "  >
                        </div>

                    </div>
                    <div class="form-group col-lg-12 padding-top-10">
                        <label class="control-label col-lg-3 margin-left-minus-5" for="legalDescription_{{propertyPin.identifier}}" >Legal Description: </label>
                        <div class="col-lg-9">
                            <textarea id="legalDescription_{{propertyPin.identifier}}" name="legalDescription_{{propertyPin.identifier}}"
                                      [dp-read-only]="true"
                                      tabindex="-1"  [ngModel]="propertyPin.legalDesc"
                                       class="form-control noHorizontalBar" >

                            </textarea>

                        </div>
                    </div>
                    <div class="col-lg-12 form-group">
                        <div class="col-lg-9 offset-lg-3 ">
                            <dp-checkbox fieldId="deletedInstruments_{{propertyPin.identifier}}" [hasTabIndex]="propertyPin.purchasePin" >
                                <input type="checkbox" id="deletedInstruments_{{propertyPin.identifier}}" name="deletedInstruments_{{propertyPin.identifier}}"
                                       [disabled]="!propertyPin.purchasePin"
                                       [attr.checked]="propertyPin.includeDeletedInstruments? 'checked' : null"
                                       [(ngModel)]="propertyPin.includeDeletedInstruments" >
                            </dp-checkbox>
                            <label class="control-label margin-bottom-7"
                                   (click)="propertyPin.includeDeletedInstruments = propertyPin.purchasePin? !propertyPin.includeDeletedInstruments : propertyPin.includeDeletedInstruments ">
                                Check to include deleted instruments in the parcel register</label>

                        </div>
                    </div>
                </div>



            </div>


        </div>
    </div>


    <div class="row modal-footer ">
        <div class="col-lg-4 text-left">
            <button class="dp-btn dp-btn-cancel" type="button" (click)="backToSearch()">
                Back to Search Criteria
            </button>
        </div>
        <div class="col-lg-4 offset-lg-4 text-right right">
            <button id="okBtn1" class="dp-btn keyboard-save-action" type="button" [disabled]="!isAnyParcelSelectedForPurchase()" (click)="purchase()"  >
                Purchase
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="onCancel()">
                Cancel
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>


