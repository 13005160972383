<div class="row" tabindex="0" class="padding-top-20">
    <div class="col-lg-12">
        <div class="form-group" *dpShowByProvince="'matters.purchaser.capacity.capacityShare'">
            <span class="col-lg-2 control-label" dp-province-field-label="matter.purchaser.capacity">
                Capacity
            </span>
            <div class="col-lg-4">
                <select class="form-control" name="purchaserCapacity-{{purchaserId}}" id="purchaserCapacity-{{purchaserId}}"
                        [(ngModel)]="matterParticipant.purchaserCapacity"
                        (change)="onChange()"
                        [dp-read-only]="readOnly">
                    <option *ngFor="let option of purchaserCapacityOptions" [value]="option.value">{{ option.label }}</option>
                </select>
            </div>
            <div class="col-lg-12 margin-top-20"></div>
        </div>
    </div>
    <div class="col-lg-12">
        <div class="form-group" *dpShowByProvince="'matters.purchaser.capacity.purchaserShare'">
            <span class="col-lg-2 control-label" dp-province-field-label="matter.purchaser.capacity.share">
                Share
            </span>
            <div class="col-lg-4" *ngIf="!isPurchaserSharePercentage">
                <input type="text" class="form-control" name="purchaserShare-{{purchaserId}}" maxlength="255" id="purchaserShare-{{purchaserId}}"
                       statusBarHelp
                       [dynamicHelpCallBackFunction]="dynamicF9ShareTenure()"
                       [(ngModel)]="matterParticipant.purchaserShare"
                       (keydown.f9)="updateShare()"
                           (change)="saveUserEntryShare()"
                       [dp-read-only]="readOnly"/>
            </div>
            <!--Only main client with MB/SK will show purchaserSharePercentage-->
            <ng-container *ngIf="isPurchaserSharePercentage">
                <div class="col-lg-4">
                    <input type="text" class="form-control" name="purchaserSharePercentage-{{purchaserId}}" id="purchaserSharePercentage-{{purchaserId}}"
                           [(ngModel)]="matterParticipant.purchaserShare"
                           (change)="saveUserEntryShare()"
                           [dp-read-only]="readOnly"
                           [padding-zero]="true"
                           dp-percentage
                           max-decimals = 5/>

                </div>
                <div class="col-lg-2">
                    <label class="control-label">
                        %
                    </label>
                </div>
            </ng-container>
        </div>
        <div class="form-group" *dpShowByProvince="'matters.purchaser.capacity.fractionalInterest'">
            <span class="col-md-2 control-label">
                Fractional Interest
            </span>
            <div class="col-md-4 display-flex align-center">
                <input type="text" class="form-control"
                       [(ngModel)]="fractionalInterestNumerator"
                       (change)="saveUserEntryShare()"
                       [dp-read-only]="readOnly"
                       dp-onlynumeric
                       maxlength="10"/>
                <label>/</label>
                <input type="text" class="form-control"
                       [(ngModel)]="fractionalInterestDenominator"
                       (change)="saveUserEntryShare()"
                       [dp-read-only]="readOnly"
                       dp-onlynumeric
                       maxlength="10"/>
            </div>
        </div>
    </div>
</div>
