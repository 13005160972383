import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
//import {TaxType} from "../../shared/tax-types";

export type StatementOfAdjustmentCreditType = 'VENDOR' | 'PURCHASER' | 'NO_ADJUSTMENT';
export type TarionSalePriceCalculationType =
  'NET_SALE_PRICE'
  | 'AGREED_SALE_PRICE'
  | 'NET_SALE_PRICE_LESS_REBATE'
  | 'NET_SALE_PRICE_SPECIFIED_SUM';
export type AdditionalVendorConsiderationType =
  'NO'
  | 'YES_ELIGIBLE_FOR_REBATE'
  | 'YES_NOT_ELIGIBLE_FOR_REBATE'
  | 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX';

export class SoAdjTarionWarranty extends BaseEntity {
  id: number;

  creditType: StatementOfAdjustmentCreditType;

  enrolmentPeriodId: number;
  builderRegistrationNumber: string;
  enrolmentNumber: string;
  applicableSection: string;

  taxType: string;

  hstRate: number;
  gstRate: number;
  pstRate: number;

  applyPst: boolean;

  salePriceUsedInCalculation: TarionSalePriceCalculationType;
  specificSum: number;
  amountAdditionalConsiderationToVendor: AdditionalVendorConsiderationType;
  isAdjustmentPaidByVendorOnInterimClosing: string;

  enrolmentFee: number;

  get adjustmentDescription(): string {
    return 'TARION WARRANTY CORPORATION';
  }

  get appliedTax(): number {
    if (this.taxType == 'GST') {
      return this.gstRate;
    }
    if (this.taxType == 'PST') {
      return this.pstRate;
    }
    return this.hstRate;
  }

  get enrolmentFeePlusTax(): number {
    return Number(this.enrolmentFee) + Number((this.enrolmentFee * Number(this.appliedTax)) / 100);
  }

  isAmountAdditionalConsiderationToVendorNo(): boolean {
    return (this.amountAdditionalConsiderationToVendor == 'NO');
  }
}
