import {BaseEntity} from '../shared/BaseEntity/base-entity';
import moment from 'moment';

export class UnityMessage extends BaseEntity {
  constructor(unityMessage?: UnityMessage) {
    super(unityMessage);
    if (
      unityMessage &&
      unityMessage.additionalMessageData &&
      typeof unityMessage.additionalMessageData == 'string'
    ) {
      this.additionalMessageData = JSON.parse(
        unityMessage.additionalMessageData
      );
    }
  }

  id: number;
  expiryDate: string;
  messageSubject: string;
  category: string;
  sentDate: number;
  message: string;
  author: string;
  messageTarget: string;
  origin: string;
  recipientCustomerAccountId: number;
  recipientUserId: number;
  sourceMessageId: string;
  effectiveDate: number;
  additionalMessageData: any;

  get isMustRead(): boolean {
    return this.category == 'MUST_READ';
  }

  get formattedEffectiveDate(): string {
    return moment(this.effectiveDate).format('MMM D YYYY hh:mm:ss A');
  }

  get isAssignmentMessage(): boolean {
    return this.category == 'ASSIGNMENT';
  }

  get isPaymentStatusMessage(): boolean {
    return this.category == 'PAYMENT_STATUS';
  }

  get isNotificationMessage(): boolean {
    return this.category == 'NOTIFICATION';
  }

  get isPayoutStatementMessage(): boolean {
    return this.category == 'PAYOUT_STATEMENT';
  }

  get isReferralMessage(): boolean {
    return this.category == 'REFERRAL';
  }
}
