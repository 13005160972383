<ng-container *ngIf="!showWizardFields">
    <div tabindex="0">
        <div class="col-lg-12">
            <div *ngIf="matter">
                <form #matterOpeningForm="ngForm"
                      role="form" class="form-horizontal"
                      (keyup)="enableSave()"
                      autocomplete="off">
                    <div tabindex="0">
                    <ng-container *ngIf="matter?.isWillMatter(); else notWillMatterOpening">
                        <div class="form-group">
                            <ng-container  *ngTemplateOutlet="matterRecordNumber"></ng-container>
                            <ng-container *ngTemplateOutlet="orderNoTemplate"></ng-container>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="fileNo" ></ng-container>
                            <ng-container *ngTemplateOutlet="documentProfile" ></ng-container>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="accountingNo"></ng-container>
                            <ng-container *ngTemplateOutlet="lastUpdated"></ng-container>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="fileOpening"></ng-container>
                            <ng-container *ngTemplateOutlet="lastUpdatedBy"></ng-container>
                        </div>
                        <div class="form-group">
                            <div class="col-lg-6"></div>
                            <ng-container *ngTemplateOutlet="accessGroupTemplate"></ng-container>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="lawclerkDetails"></ng-container>
                            <ng-container *ngTemplateOutlet="lawClerkInitials"></ng-container>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="solicitorDetails"></ng-container>
                            <ng-container *ngTemplateOutlet="solicitorInitials"></ng-container>
                        </div>

                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="closingDate"></ng-container>
                            <ng-container *ngTemplateOutlet="lockBoxCodeTemplate"></ng-container>
                        </div>
                        <ng-container *ngTemplateOutlet="closingDateWarning"></ng-container>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="completedDropdownTemplate"></ng-container>

                        </div>
                        <ng-container *ngTemplateOutlet="executingDoctsAt"></ng-container>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="willDocsSignedRemotely"></ng-container>

                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="addAppointmentTemplate"></ng-container>

                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="referralContent"></ng-container>

                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="isFileInactiveDropdownTemplate"></ng-container>
                        </div>

                        <div class="form-group">
                            <dp-user-defined-fields [topicName]="'MATTER_OPENING'">
                            </dp-user-defined-fields>
                        </div>

                    </ng-container>
                    <ng-template #notWillMatterOpening >
                        <div class="form-group">
                            <ng-container  *ngTemplateOutlet="matterRecordNumber"></ng-container>
                            <ng-container *ngIf="!isMatterNeverPersisted && matter?.isFullMatter()">
                                <ng-container *ngTemplateOutlet="orderNoTemplate"></ng-container>
                            </ng-container>
                        </div>

                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="fileNo" ></ng-container>
                            <ng-container *ngTemplateOutlet="documentProfile" ></ng-container>
                        </div>
                        <div class="form-group" *dpShowByProvince="'matter.matterOpening.buildNo'">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label"
                                       for="fileNo">Builder No.</label>
                            </div>
                            <div class="col-lg-4">
                                <input type="text"
                                       id="builderNo"
                                       name="builderNo"
                                       class="form-control"
                                       [(ngModel)]="matter.builderNumber"
                                       maxlength="20"
                                       trimmed
                                />

                            </div>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="accountingNo"></ng-container>
                            <ng-container *ngTemplateOutlet="lastUpdated"></ng-container>
                            </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="fileOpening"></ng-container>
                            <ng-container *ngTemplateOutlet="lastUpdatedBy"></ng-container>
                        </div>
                        <div class="form-group" *ngIf="userAccessGroups && userAccessGroups.length > 1">
                            <ng-container *ngTemplateOutlet="accessGroupTemplate"></ng-container>
                        </div>
                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="lawclerkDetails"></ng-container>
                            <ng-container *ngTemplateOutlet="lawClerkInitials"></ng-container>
                            </div>

                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="solicitorDetails"></ng-container>
                            <ng-container *ngTemplateOutlet="solicitorInitials"></ng-container>
                        </div>
                        <ng-container *ngIf="matter && !matter.isCustomMatter()">
                            <ng-container *dpShowByProvince="'matter.matterOpening.witness'">
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="witnessDropDown"
                                               dp-province-field-label="matter.matterOpening.witnessName">Witness Name</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <select type="text"
                                                id="witnessDropDown"
                                                name="witnessDropDown"
                                                class="form-control"
                                                [(ngModel)]="matter.selectedWitnessId"
                                                (ngModelChange)="onWitnessChange($event)">
                                            <option *ngFor="let witness of witnessDropDownList" [value]="witness.value">
                                                {{witness.label}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="witnessInitials"
                                               dp-province-field-label="matter.matterOpening.witness">Witness</label>
                                    </div>

                                    <div class="col-lg-4">
                                        <input type="text"
                                               trimmed
                                               id="witnessInitials"
                                               name="witnessInitials" class="form-control"
                                               [ngModel]="matter.witnessInitials"
                                               [dp-read-only]="true">

                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *dpShowByProvince="'matter.matterOpening.commissioner'">
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="commissionerDropDown">Commissioner Name</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <select type="text"
                                                id="commissionerDropDown"
                                                name="commissionerDropDown"
                                                class="form-control"
                                                [(ngModel)]="matter.selectedCommissionerId"
                                                (ngModelChange)="onCommissionerChange($event)">
                                            <option *ngFor="let commissioner of commissionerDropDownList"
                                                    [value]="commissioner.value">
                                                {{commissioner.label}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="commissionerInitials">Commissioner</label>
                                    </div>

                                    <div class="col-lg-4">
                                        <input type="text"
                                               trimmed
                                               id="commissionerInitials"
                                               name="commissionerInitials" class="form-control"
                                               [ngModel]="matter.commissionerInitials"
                                               [dp-read-only]="true">

                                    </div>
                                </div>
                            </ng-container>


                            <ng-container  *ngTemplateOutlet="actingForTemplate"></ng-container>

                            <div class="form-group" *dpShowByProvince="'matter.matterOpening.contactTerminated'">
                                <ng-container *dpShowByProvince="'matter.matterOpening.contactTerminated'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               dp-province-field-label="matter.matterOpening.contactTerminated"
                                               for="contactTerminated">
                                            P&S contact terminated?
                                        </label>
                                    </div>
                                    <div class="col-lg-1">
                                        <select class="form-control" id="contactTerminated"
                                                name="contactTerminated"
                                                [(ngModel)]="matter.contactTerminated"
                                                (change)="enableSave()">
                                            <option *ngFor="let option of contactTerminatedOptions"
                                                    [value]="option.value">
                                                {{option.label}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="form-group" *ngIf="!matter.isMortgageBC">
                                <ng-container *dpShowByProvince="'matter.matterOpening.dateOfAgrtPS'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               for="dateOfAgrt"
                                               dp-province-field-label="matter.matterOpening.dateOfAgrtPS">
                                            Date of Agrt of P&S
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <dp-partial-date
                                            #partialDateOfAgrtOfPS
                                            statusBarHelp
                                            [inputDate]="matter.purchaseSaleAgreementDate"
                                            [fieldKey]="'matter.matterOpening.purchaseSaleAgreementDate'"
                                            (dateChange)="onDateChangeDateOfAgreementOfPS($event)">
                                        </dp-partial-date>
                                    </div>
                                </ng-container>

                                <ng-container *dpShowByProvince="'matter.matterOpening.occupancyDate'">
                                    <div *ngIf="showOccupancyDate()">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label" for="occupancyDate"
                                                   dp-province-field-label="matter.matterOpening.occupancyDate">
                                                Occupancy Date
                                            </label>
                                        </div>
                                        <div class="col-lg-4">
                                            <dp-partial-date
                                                #partialOccupancyDate
                                                [inputDate]="matter.occupancyDate"
                                                fieldKey="matter.matterOpening.occupancyDate"
                                                [disableAll]="getMassUpdateData()?.isFieldMarkedForRemoval('occupancyDate') || isLinkedMatterProjectSale()"
                                                statusBarHelp
                                                dp-error-validator
                                                (dateChange)="onDateChangeOccupancyDate($event)">
                                            </dp-partial-date>
                                            <div *ngIf="matter?.isTemplateMatterForMassUpdate" class="padding-top-10">
                                                <dp-checkbox fieldId="removeOccupancyDate">
                                                    <input id="removeOccupancyDate"
                                                           name="removeOccupancyDate"
                                                           type="checkbox"
                                                           [checked]="getMassUpdateData()?.isFieldMarkedForRemoval('occupancyDate')"
                                                           (change)="toggleOccupancyDateRemoval()"
                                                    />
                                                </dp-checkbox>
                                                <label class="control-label padding-left-10 display-inline"
                                                       for="removeOccupancyDate">Remove date from matter</label>
                                            </div>

                                        </div>
                                    </div>
                                </ng-container>

                            </div>

                            <div class="form-group" *dpShowByProvince="'matter.matterOpening.adjustmentDate'">
                                <ng-container *dpShowByProvince="'matter.matterOpening.adjustmentDate'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               for="adjustmentDate">
                                            Adjustment Date
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <dp-partial-date
                                            #partialAdjustmentDate
                                            [inputDate]="matter.adjustAsAtClosingDate"
                                            fieldKey="matter.matterOpening.adjustmentDate"
                                            statusBarHelp
                                            (dateChange)="onDateChangeAdjustmentDate($event)">
                                        </dp-partial-date>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="form-group" *dpShowByProvince="'matter.matterOpening.requisitionDate'">
                                <div *ngIf="isOccupancyDateVisible">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               dp-province-field-label="matter.matterOpening.requisitionDate">
                                            Requisition Date
                                            <!--In project sale matter 'requisition date' is renamed to 'occupancy date' and actual occupancy date is hidden. -->
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <dp-partial-date
                                            #partialRequisitionDate
                                            [inputDate]="matter.requisitionDate"
                                            [disableAll]="getMassUpdateData()?.isFieldMarkedForRemoval('requisitionDate')"
                                            fieldKey="matter.matterOpening.requisitionDate"
                                            dp-error-validator
                                            statusBarHelp
                                            (dateChange)="onDateChangeRequisitionDate($event)">
                                        </dp-partial-date>
                                        <div *ngIf="matter?.isTemplateMatterForMassUpdate" class="padding-top-10">
                                            <dp-checkbox fieldId="removeRequisitionDate">
                                                <input id="removeRequisitionDate"
                                                       name="removeRequisitionDate"
                                                       type="checkbox"
                                                       [checked]="getMassUpdateData()?.isFieldMarkedForRemoval('requisitionDate')"
                                                       (change)="toggleRequisitionDateRemoval()"
                                                />
                                            </dp-checkbox>
                                            <label class="control-label padding-left-10 display-inline"
                                                   for="removeRequisitionDate">Remove date from matter</label>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *dpShowByProvince="'matter.matterOpening.requisitionSubmitted'">
                                    <!--Now use dpShowByProvince to contol if show base on matter and province-->
                                    <div *ngIf="isRequisitionSubmittedVisible">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label"
                                                   dp-province-field-label="matter.matterOpening.requisitionSubmitted"
                                                   for="requisitionSubmitted">
                                                Requisition Submitted?
                                            </label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control" id="requisitionSubmitted"
                                                    [dp-read-only]="matter.isMortgage"
                                                    name="requisitionSubmitted"
                                                    [(ngModel)]="matter.requisitionSubmitted"
                                                    (change)="enableSave()">
                                                <option *ngFor="let status of requisitionSubmittedOptions"
                                                        [value]="status.value">
                                                    {{status.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="form-group">
                                <div
                                    *ngIf="isReleaseDateSameAsOccupancyDateVisible || matter.isTemplateMatterForMassUpdate">
                                    <ng-container
                                        *dpShowByProvince="'matter.matterOpening.isReleaseDateSameAsOccupancyDate'">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">
                                                Is the Key Release Date the <br> same as the Occupancy <br> Date?
                                            </label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control" id="isReleaseDateSameAsOccupancyDate"
                                                    name="isReleaseDateSameAsOccupancyDate"
                                                    [(ngModel)]="matter.isReleaseDateSameAsOccupancyDate"
                                                    (change)="enableSave()">
                                                <option *ngFor="let option of isReleaseDateSameAsOccupancyDateOptions"
                                                        [value]="option.value">
                                                    {{option.label}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-lg-3"></div>
                                    </ng-container>
                                </div>
                                <div *ngIf="isOccupancyCompletedVisible || matter.isTemplateMatterForMassUpdate">
                                    <ng-container *dpShowByProvince="'matter.matterOpening.occupancyCompleted'">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label"
                                                   for="occupancyCompleted">
                                                Occupancy Completed?
                                            </label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control" id="occupancyCompleted"
                                                    name="occupancyCompleted"
                                                    [(ngModel)]="matter.occupancyCompleted"
                                                    (change)="enableSave()">
                                                <option *ngFor="let option of occupancyCompletedOptions"
                                                        [value]="option.value">
                                                    {{option.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="form-group" *dpShowByProvince="'matter.matterOpening.releaseDate'">
                                <div *ngIf="isReleaseDateVisible">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label">
                                            Enter the Key Release Date
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <dp-partial-date
                                            #partialReleaseDate
                                            [inputDate]="matter.releaseDate"
                                            fieldKey="matter.matterOpening.releaseDate"
                                            dp-error-validator
                                            statusBarHelp
                                            (dateChange)="onDateChangeReleaseDate($event)">
                                        </dp-partial-date>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container  *ngTemplateOutlet="description"></ng-container>

                        <div class="form-group">
                            <ng-container  *ngTemplateOutlet="closingDate"></ng-container>
                            <ng-container *ngTemplateOutlet="lockBoxCodeTemplate"></ng-container>
                        </div>
                        <ng-container *ngTemplateOutlet="closingDateWarning"></ng-container>


                        <ng-container *ngIf="matter">
                            <div class="form-group"
                                 *ngIf="matter.hasPastClosingDate() || matter.isTemplateMatterForMassUpdate">
                                <ng-container *ngTemplateOutlet="completedDropdownTemplate"></ng-container>
                                <div class="col-lg-3 padding-top-5">
                                    <a *ngIf="isUpdateClientAddressesVisible()" href="javascript:void(0);" (click)="openUpdateClientAddressDialog()">Update client addresses</a>
                                </div>
                                <ng-container *ngIf="matter.closed=='YES'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               for="fileClosingNo">
                                            File No
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="text"
                                               id="fileClosingNo"
                                               name="fileClosingNo"
                                               class="form-control"
                                               [(ngModel)]="matter.closingFileNumber"
                                               trimmed
                                               maxlength="50"
                                        />
                                    </div>
                                </ng-container>
                            </div>

                            <ng-container class="form-group" *ngIf="canBeConveyCaTransaction">
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="conveyCaTransaction">
                                            Convey.ca transaction?
                                        </label>
                                    </div>
                                    <div class="col-lg-1">
                                        <select class="form-control"
                                                id="conveyCaTransaction"
                                                name="conveyCaTransaction"
                                                [(ngModel)]="matter.conveyCaTransaction"
                                                (change)="enableSave()">
                                            <option *ngFor="let status of yesNoOptions"
                                                    [value]="status.value">
                                                {{status.label}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1">
                                    </div>
                                    <div class="col-lg-2"
                                         *ngIf="(matter.conveyCaTransaction == true || matter.conveyCaTransaction == 'true') && matter.conveyCaLogin && matter.conveyCaPassword">
                                <span class="actionlink">
                                    <span>
                                        <a href="javascript:void(0);" (click)="openConveyCa()" id="openConveyCa"
                                           tabindex="0">Go to Convey.ca</a>
                                    </span>
                                </span>
                                    </div>
                                </div>
                                <div class="form-group"
                                     *ngIf="matter.conveyCaTransaction == true || matter.conveyCaTransaction == 'true'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="conveyCaLogin">
                                            Convey.ca Login ID
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="password"
                                               id="conveyCaLogin"
                                               name="conveyCaLogin"
                                               class="form-control"
                                               maxlength="20"
                                               [(ngModel)]="matter.conveyCaLogin"
                                        />
                                    </div>
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="conveyCaPassword">
                                            Convey.ca Password
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="password"
                                               id="conveyCaPassword"
                                               name="conveyCaPassword"
                                               class="form-control"
                                               maxlength="20"
                                               [(ngModel)]="matter.conveyCaPassword"
                                        />
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *dpShowByProvince="'matter.matterOpening.realEstateBroker'">
                                <ng-container  *ngTemplateOutlet="realEstateBroker"></ng-container>
                            </ng-container>

                            <div class="form-group" *ngIf="matter && !matter.isCustomMatter()">
                                <ng-container *dpShowByProvince="'matter.matterOpening.registrationMethod'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               for="regMethod">
                                            Registration Method
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <select class="form-control"
                                                id="regMethod"
                                                name="regMethod"
                                                fieldKey="matter.matterOpening.registrationMethod"
                                                statusBarHelp
                                                [(ngModel)]="matter.registrationMethodCode"
                                                [dp-read-only]="matter.isProjectSale && !this.matter.isTemplateMatterForMassUpdate"
                                                (change)="registrationMethodChanges()">
                                            <option *ngFor="let status of registrationMethods"
                                                    [value]="status.value">
                                                {{status.label}}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>
                                <ng-container *dpShowByProvince="'matter.matterOpening.teraviewDocketIdentifier'">
                                    <ng-container *ngIf="matter.registrationMethodCode === 'Electronic'">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label"
                                                   for="teraview">
                                                Teraview Docket ID
                                            </label>
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text"
                                                   id="teraview"
                                                   name="teraview"
                                                   fieldKey="matter.matterOpening.teraviewDocketIdentifier"
                                                   statusBarHelp
                                                   class="form-control"
                                                   [dynamicHelpCallBackFunction]="generateF9HelpTeraview()"
                                                   (keydown.f9)="handleF9Teraview()"
                                                   [(ngModel)]="matter.teranetDocket.teraviewDocketIdentifier"
                                                   [dp-read-only]="isDocketIdentifierPresent || matter.teraviewDocketIdSameAsProjectField"
                                                   maxlength="10"
                                                   dp-uppercase
                                                   trimmed
                                            />
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <ng-container *ngTemplateOutlet="executingDoctsAt"></ng-container>
                            <ng-container *ngTemplateOutlet="willDocsSignedRemotely"></ng-container>
                            <ng-container *ngIf="documentsBeSignedDigitally()">
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="documentsToBeSignedDigitally">
                                            Will documents be signed digitally?
                                        </label>
                                    </div>
                                    <div class="col-lg-1">
                                        <select class="form-control"
                                                id="documentsToBeSignedDigitally"
                                                name="documentsToBeSignedDigitally"
                                                [(ngModel)]="matter.documentsToBeSignedDigitally"
                                                (change)="enableSave();">
                                            <option *ngFor="let status of yesNoDefaultNoOptions"
                                                    [value]="status.value">
                                                {{ status.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <ng-container *ngIf="isDigitalPlatformVisible()">
                                        <div class="col-lg-2 offset-lg-3 vertical-align-label">
                                            <label class="control-label" for="digitalPlatform">
                                                Signature Platform
                                            </label>
                                        </div>
                                        <div class="col-lg-4">
                                            <select class="form-control"
                                                    id="digitalPlatform"
                                                    name="digitalPlatform"
                                                    [(ngModel)]="matter.digitalSignaturePlatform"
                                                    (change)="enableSave();">
                                                <option *ngFor="let digitalSigningPlatform of digitalSigningPlatforms"
                                                        [value]="digitalSigningPlatform.value">
                                                    {{ digitalSigningPlatform.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="matter && !matter.isCustomMatter() && !matter.isMatterTypeDischarge">
                                <div class="form-group" *dpShowByProvince="'matter.matterOpening.protocolClosing'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               for="protocolClosing">
                                            Protocol Closing ?
                                        </label>
                                    </div>
                                    <div class="col-lg-2">
                                        <select class="form-control"
                                                id="protocolClosing"
                                                name="protocolClosing"
                                                [(ngModel)]="matter.protocolClosing"
                                                (change)="enableSave()">
                                            <option *ngFor="let itm of protocolClosingItems"
                                                    [value]="itm.value">
                                                {{itm.label}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="form-group" *dpShowByProvince="'matter.matterOpening.purchaserFinancing'">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label"
                                           for="purchaserFinancing">
                                        Purchaser Financing
                                    </label>
                                </div>
                                <div class="col-lg-2">
                                    <select class="form-control"
                                            id="purchaserFinancing"
                                            name="purchaserFinancing"
                                            [(ngModel)]="matter.purchaserFinancing"
                                            (change)="enableSave()">
                                        <option *ngFor="let itm of purchaserFinancingItems"
                                                [value]="itm.value">
                                            {{itm.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <ng-container *ngIf="matter && !matter.isCustomMatter() && !matter.isMatterTypeDischarge">
                                <div class="form-group" *dpShowByProvince="'matter.matterOpening.interestRateSummary'">
                                    <div>
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">Interest Rate</label>
                                        </div>
                                        <div class="col-lg-4">
                                            <input type="text"
                                                   dp-email-field
                                                   id="interestRateSummary"
                                                   name="interestRateSummary"
                                                   class="form-control"
                                                   statusBarHelp
                                                   [dynamicHelpCallBackFunction]="generateF9HelpForInterestRateSummary()"
                                                   (keydown.f9)="onInterestRateSummaryF9()"
                                                   [(ngModel)]="matter.interestRateSummary"
                                                   maxlength="50"/>
                                        </div>
                                        <ng-container *dpShowByProvince="'matter.matterOpening.interestRateBOC'">
                                            <div class="text-left padding-top-8">
                                                <a href="https://www.bankofcanada.ca/core-functions/monetary-policy/key-interest-rate/?page_moved=1"
                                                   target="_blank">Bank of Canada</a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="form-group" *dpShowByProvince="'matter.matterOpening.cashOnClosingDate'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               for="cashOnClosingDate">
                                            {{getOnClosingDateFieldLabel()}}
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <select class="form-control"
                                                id="cashOnClosingDate"
                                                name="cashOnClosingDate"
                                                [(ngModel)]="matter.cashOnClosingDate"
                                                (change)="onCashClosingOptionChange()">
                                            <option *ngFor="let itm of cashOnClosingDateItems"
                                                    [value]="itm.value">
                                                {{itm.label}}
                                            </option>
                                        </select>
                                        <button type="button"
                                                *ngIf="allowOpenClosingDateEdit()"
                                                tabIndex="-1"
                                                id="cashOnClosingDateEditBtn" class="button-glyph right-30 top-4"
                                                (click)="editLateClosingInterest()">
                                            <span class="glyphicon glyphicon-edit"></span>
                                        </button>
                                    </div>
                                    <ng-container
                                        *ngIf="matter.closingDatePayment && matter.closingDatePayment.isLateClosing()">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label"
                                                   dp-province-field-label="matter.matterOpening.lateClosing"
                                                   [label-province-code]="matterProvince"
                                            >
                                                Late Closing
                                            </label>
                                        </div>
                                        <div class="col-lg-4">
                                            <input class="form-control"
                                                   id="lateClosingText"
                                                   name="lateClosingText"
                                                   [ngModel]="matter.getLateClosingText()"
                                                   (keydown.f9)="editLateClosingInterest()"
                                                   dp-read-only="true"/>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngTemplateOutlet="addAppointmentTemplate"></ng-container>
                        <ng-container  *ngTemplateOutlet="titleInsurance"></ng-container>
                        <ng-container  *ngTemplateOutlet="referral"></ng-container>

                        <ng-container *ngIf="matter && !matter.isCustomMatter()">
                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label"
                                           for="feeQuote">
                                        Fee Quote
                                    </label>
                                </div>
                                <div class="col-lg-4 special-comment no-adjust-autocomplete"
                                     id="feeQuote" *ngIf="!matter.locked">

                                    <p-autoComplete id="autocomplete-feeQuote"
                                                    name="autocomplete-feeQuote"
                                                    [(ngModel)]="selectedFeeQuote"
                                                    [class.autocomplete-searching]="feeQuotesLoading"
                                                    [suggestions]="feeQuotes"
                                                    (completeMethod)="searchFeeQuote($event)"
                                                    field="customPickListItemValue"
                                                    [minLength]="0"
                                                    [delay]="1000" maxlength="100"
                                                    scrollHeight="350px"
                                                    (ngModelChange)="onFeeQuoteChange(selectedFeeQuote)"
                                                    (onSelect)="dataSelectedFeeQuote()"
                                                    (click)="feeQuoteHelpText()"
                                                    [dropdown]="!feeQuotesLoading"
                                                    fieldKey="matter.matterOpening.feeQuote"
                                                    statusBarHelp
                                                    (keydown.space)="utils.handleSpaceKeyDropdown($event, selectedFeeQuote, handleDropdownClickFeeQuote)"
                                                    (keydown.enter)="utils.handleEnterKeyDropdown($event, selectedFeeQuote, handleDropdownClickFeeQuote)"
                                                    (onDropdownClick)="handleDropdownClickFeeQuote($event)"
                                                    tabIndexIgnore>
                                        <ng-template let-quote pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="quote">
                                                <span class="omnibarLi100">{{quote.customPickListItemValue}}</span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div class="col-lg-4 special-comment no-adjust-autocomplete"
                                     id="feeQuote" *ngIf="matter.locked">
                                    <input type="text" value="{{matter.feeQuote}}" disabled class="form-control"/>

                                </div>

                            </div>

                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label"
                                           [class.focus]="fieldHoverIndex===31">
                                        Special Comments
                                    </label>
                                </div>
                                <div class="col-lg-10 special-comment no-adjust-autocomplete"
                                     id="special-comment" *ngIf="!matter.locked">
                                    <p-autoComplete id="autocomplete-specialComment"
                                                    name="autocomplete-specialComment"
                                                    [(ngModel)]="selectedSpecialComment"
                                                    [class.autocomplete-searching]="specialCommentsLoading"
                                                    [suggestions]="specialComments"
                                                    (completeMethod)="searchSpecialComment($event)"
                                                    field="customPickListItemValue"
                                                    [minLength]="0"
                                                    [delay]="1000"
                                                    maxlength="100"
                                                    scrollHeight="350px"
                                                    (keydown.space)="utils.handleSpaceKeyDropdown($event, selectedSpecialComment, handleDropdownClickSpecialComment)"
                                                    (keydown.enter)="utils.handleEnterKeyDropdown($event, selectedSpecialComment, handleDropdownClickSpecialComment)"
                                                    (onSelect)="dataSelectedSpecialComment()"
                                                    (ngModelChange)="onSpecialCommentChange(selectedSpecialComment)"
                                                    (click)="specialCommentHelpText()"
                                                    [dropdown]="!specialCommentsLoading"
                                                    (onDropdownClick)="handleDropdownClickSpecialComment($event)"
                                                    tabIndexIgnore>
                                        <ng-template let-comment pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                                [disableItem]="comment">
                                                <span>{{comment.customPickListItemValue}}</span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>

                                <div class="col-lg-10 special-comment no-adjust-autocomplete"
                                     id="special-comment" *ngIf="matter.locked">
                                    <input type="text" value="{{matter.specialComments}}" disabled
                                           class="form-control"/>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngTemplateOutlet="isFileInactiveDropdownTemplate"></ng-container>

                        <div class="row">
                            {{errorMessage}}
                        </div>

                        <dp-user-defined-fields [topicName]="'MATTER_OPENING'">
                        </dp-user-defined-fields>

                </ng-template>
                </div>
            </form>
        </div>
    </div>
</div>

</ng-container>
<ng-container *ngIf="!!showWizardFields">
    <div class="form-group form-horizontal col-lg-12 text-right">
        <ng-container  *ngTemplateOutlet="matterRecordNumber"></ng-container>
        <ng-container  *ngTemplateOutlet="closingDate"></ng-container>
    </div>
    <div class="form-group col-lg-12 text-right">
        <ng-container  *ngTemplateOutlet="solicitorDetails"></ng-container>
        <ng-container  *ngTemplateOutlet="lawclerkDetails"></ng-container>
    </div>
    <div class="form-horizontal col-lg-12 text-right">
        <ng-container  *ngTemplateOutlet="description"></ng-container>
    </div>
    <div class="form-horizontal col-lg-12 text-right" *ngIf="!matter?.isPurchaseBC">
        <ng-container  *ngTemplateOutlet="realEstateBroker"></ng-container>
    </div>
    <div class="form-horizontal col-lg-12 text-right" *ngIf="!appConfig.isHiddenForBCProvince">
        <ng-container  *ngTemplateOutlet="referral"></ng-container>
    </div>
    <div class="form-horizontal col-lg-12 text-right">
        <ng-container  *ngTemplateOutlet="titleInsurance"></ng-container>
    </div>
    <div class="form-horizontal col-lg-12 text-right" *ngIf="matter && !matter.isCustomMatter()">
        <ng-container  *ngTemplateOutlet="actingForTemplate"></ng-container>
    </div>
</ng-container>
<ng-template #matterRecordNumber>
    <div class = 'col-lg-2 vertical-align-label'>
        <label class="control-label" for="matterNo">Matter No.*</label>
    </div>
    <div class="col-lg-4 error-issue">
        <input type="text"
               id="matterNo"
               name="matterNo"
               class="form-control"
               [fieldCode]="'M10001'"
               maxlength="25"
               [(ngModel)]="matter.matterRecordNumber"
               [autofocus]="!matterNoReadonly"
               [dp-read-only]="matterNoReadonly"
               dp-uppercase-preserve-cursor-position
               dp-matterNoFilter
               [fieldKey]="!matter.matterRecordNumber ? 'matter.matterOpening.matterNo' : 'matter.matterOpening.uniqueMatterNo'"
               dp-error-validator
               statusBarHelp
               (focusout)="populateFileAccounting()"
               (keydown.f9)="doMatterSaveAndOpenMatterNoModal()"
        />
        <button *ngIf="!isMatterNeverPersisted && !showWizardFields" type="button"
                tabIndex="-1"
                id="matterNumberisMatterNeverPersistedBtn" class="button-glyph"
                (click)="doMatterSaveAndOpenMatterNoModal()">
            <span class="glyphicon glyphicon-edit"></span>
        </button>
    </div>
</ng-template>

<ng-template #lawclerkDetails>
    <div [ngClass]="showWizardFields? 'col-lg-2 vertical-align-label margin-top-5' : 'col-lg-2 vertical-align-label'">
        <label class="control-label" for="docProfile"
               dp-province-field-label="matter.matterOpening.lawClerkName">Law Clerk Name</label>
    </div>
    <div class="col-lg-4">
        <!--Put dp-error-validator in outside div to avoid the warning icon is blocked-->
        <div class="col-lg-4 z-index-6">
            <div [fieldKey]="customLawClerkDPErrorKey" dp-error-validator></div>
        </div>
        <p-autoComplete
            id="lawClerk-autocomplete"
            name="lawClerk-autocomplete"
            #lawClerkAutoComplete
            [(ngModel)]="selectedLawClerk"
            (focusout)="onLeavingLawClerkOmniBarWithoutFreeInput()"
            (completeMethod)="searchLawClerkInDropDownList($event)"
            [class.dropdown-arrow-refferedBy]="true"
            [class.border-bottom-color-refferedBy]="true"
            [suggestions]="lawClerksSuggestions"
            scrollHeight="550px"
            (onSelect)="dataSelectedLawClerk($event)"
            [minLength]="0"
            [delay]="1000"
            (keydown.f9)="onLawClerkF9()"
            [dropdown]="true"
            (onDropdownClick)="refreshLawClerkList()"
            autocomplete="off"
            (keydown.space)="displayLawClerkList($event)"
            [dynamicHelpCallBackFunction]="generateF9HelpForLawClerk()"
            statusBarHelp>

            <ng-template let-lawClerk pTemplate="item">
                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                    [disableItem]="lawClerk">
                    <span class="col-lg-8 text-overflow">{{ lawClerk.label }}</span>
                </li>
            </ng-template>

        </p-autoComplete>
    </div>
</ng-template>
<ng-template #solicitorDetails>
    <div [ngClass]="showWizardFields? 'col-lg-2 vertical-align-label margin-top-5' : 'col-lg-2 vertical-align-label'">
        <label class="control-label" for="docProfile" dp-province-field-label="matter.matterOpening.solicitorName">Solicitor Name</label>
    </div>
    <div class="col-lg-4 lawClerk-solicitor-class">
        <div [dp-read-only-block]="containsEmpMortgage()" [elementRefComp]="elementRef">
            <!--Put dp-error-validator in outside div to avoid the warning icon is blocked-->
            <div class="col-lg-4 z-index-6">
                <div [fieldKey]="customSolicitorDPErrorKey" dp-error-validator></div>
            </div>
            <p-autoComplete
                id="solicitor-autocomplete"
                name="solicitor-autocomplete"
                #solicitorAutoComplete
                [(ngModel)]="selectedSolicitor"
                (focusout)="onLeavingSolicitorOmniBarWithoutFreeInput()"
                (completeMethod)="searchSolicitorInDropDownList($event)"
                [class.dropdown-arrow-refferedBy]="true"
                [class.border-bottom-color-refferedBy]="true"
                [suggestions]="solicitorsSuggestions"
                scrollHeight="550px"
                (onSelect)="dataSelectedSolicitor($event)"
                [minLength]="0"
                [delay]="1000"
                (keydown.f9)="onSolicitorF9()"
                [dropdown]="true"
                (onDropdownClick)="refreshSolicitorList()"
                autocomplete="off"
                (keydown.space)="displaySolicitorList($event)"
                [disabled]="containsEmpMortgage()"
                [dynamicHelpCallBackFunction]="generateF9HelpForSolicitor()"
                statusBarHelp>

                <ng-template let-solicitor pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [disableItem]="solicitor">
                        <span class="col-lg-8 text-overflow">{{ solicitor.label }}</span>
                    </li>
                </ng-template>

            </p-autoComplete>
        </div>
    </div>
</ng-template>
<ng-template #closingDate>
    <div class = 'col-lg-2 vertical-align-label'>
        <ng-container *ngIf = "(!matter.provinceCode && !!showWizardFields) || (matter.isWillMatter() && !showWizardFields) ">
            <label class="control-label">{{closingDateLabelText}}</label>
        </ng-container>
        <ng-container *ngIf = "matter.provinceCode && !(matter.isWillMatter() && showWizardFields)">
            <ng-container *dpShowByProvince="'matter.matterOpening.closingDate'">
                <label class="control-label">
                    {{closingDateLabelText}}
                </label>
            </ng-container>
            <ng-container *dpShowByProvince="'matter.matterOpening.possessionTime'">
                <label class="control-label">
                    {{ possessionDateLabelText }}
                </label>
            </ng-container>
        </ng-container>

    </div>
    <div class="col-lg-4">
        <dp-partial-date
            #partialClosingDate
            [inputDate]="matter.matterCloseDate"
            [fieldCode]="'M10002'"
            [disableAll]="getMassUpdateData()?.isFieldMarkedForRemoval('matterCloseDate') || isLinkedMatterProjectSale() || isMatterEditingDisabledAfterPostClosingDate || isWizardMatterReadOnly()"
            [fieldKey]="'matter.matterOpening.matterCloseDate'"
            dp-error-validator
            statusBarHelp
            (dateChange)="onDateChangeClosingDate($event)"
            *ngIf="!(matter.isWillMatter() && showWizardFields)">
        </dp-partial-date>
        <div *ngIf="matter?.isTemplateMatterForMassUpdate" class="padding-top-10">
            <dp-checkbox fieldId="removeClosingDate">
                <input id="removeClosingDate"
                       name="removeClosingDate"
                       type="checkbox"
                       [checked]="getMassUpdateData()?.isFieldMarkedForRemoval('matterCloseDate')"
                       (change)="toggleMatterCloseDateRemoval()"
                />
            </dp-checkbox>
            <label class="control-label padding-left-10 display-inline" for="removeClosingDate">Remove
                date from matter</label>
        </div>
    </div>

    <div *ngIf="!showWizardFields">
        <ng-container *dpShowByProvince="'matter.matterOpening.possessionTime'">
            <ng-container *ngIf="showPossessionTime && matter && !matter.isCustomMatter()">
                <div class="col-lg-2 left-minus-105">
                    <span class="atIcon">@</span>
                    <input type="text"
                           id="possessionTime"
                           name="possessionTime"
                           class="form-control padding-left-3 padding-right-3 width-80"
                           [(ngModel)]="matter.possessionTime"
                           [fieldKey]="'matter.matterOpening.matterPossessionTime'"
                           (keydown.f9)="handleF9OnPossessionTime()"
                           trimmed
                           statusBarHelp
                           maxlength="20"
                    />
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<ng-template #realEstateBroker>
    <ng-container *dpIfApplicableForMatter="'matter.matterOpening.realEstateAgent'">
        <dp-real-estate-broker
            [matter]="matter"
            [checkForOptOutEmail]="true"
            [errorService]="errorService"
            (changed)="enableSave()"
            [isOpenedFromWizard]="showWizardFields"
        >
        </dp-real-estate-broker>
    </ng-container>
</ng-template>
<ng-template #referral>
    <div class="form-group" *ngIf="(matter?.isWillMatter() || !matter.isTemplateMatterForMassUpdate && !appConfig.isHiddenForBCProvince)">
        <ng-container *ngTemplateOutlet="referralContent"></ng-container>
    </div>
    </ng-template>
    <ng-template #referralContent>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label">Referral</label>
    </div>
    <div class="col-lg-10 error-issue">
        <input type="text"
               id="referral"
               name="referral"
               class="form-control"
               [ngModel]="referredBy"
               [dp-read-only]="true"
        />
        <button type="button"
                tabIndex="-1"
                id="referralBtn" class="button-glyph"
                (click)="openReferredByModal()">
            <span class="glyphicon glyphicon-edit"></span>
        </button>
    </div>
</ng-template>
<ng-template #titleInsurance>
    <ng-container *ngIf="matter && !matter.isCustomMatter() && !matter.isMatterTypeDischarge">
        <div class="form-group"
             *dpIfApplicableForMatter="'matter.matterOpening.transactionTitleInsured'">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="tranTitleInsure">
                    Is transaction title insured?
                </label>
            </div>
            <div class="col-lg-4">
                <input class="form-control"
                       id="tranTitleInsure"
                       name="tranTitleInsure"
                       [(ngModel)]="matter.transactionTitleInsuredCode"
                       (keydown.f9)="handleF9Insurer()"
                       fieldKey="matter.matterOpening.transactionTitleInsuredCode"
                       statusBarHelp
                       [dp-read-only]/>
                <button type="button"
                        tabIndex="-1"
                        id="tranTitleInsureBtn" class="button-glyph"
                        (click)="selectInsurer()">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>

            </div>
            <ng-container
                *ngIf="matter.transactionTitleInsuredCode && (matter.transactionTitleInsuredCode !== 'N/y') && (matter.transactionTitleInsuredCode !== 'NO')">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" for="insurer">Insurer</label>
                </div>
                <div class="col-lg-4" [ngSwitch]="matter.transactionTitleInsuredCode">
                    <select type="text" *ngSwitchCase="'Yes - with Other Title Insurer'"
                            id="insurer"
                            name="insurer"
                            class="form-control"
                            [(ngModel)]="matter.insurer"
                            (ngModelChange)="onSelectedInsurerChange($event)">
                        <option value=""></option>
                        <option *ngFor="let insurer of insurers" [value]="insurer.organizationName">
                            {{insurer.organizationName}}
                        </option>
                    </select>
                    <input type="text" *ngSwitchDefault
                           id="insurer"
                           name="insurer"
                           class="form-control"
                           [(ngModel)]="matter.insurer"
                           [dp-read-only]="true"
                           trimmed
                    />
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
<ng-template #description>
    <ng-container *ngIf="matter && matter.isCustomMatter()">
        <div class="form-group">
            <div>
                <label class="control-label col-lg-2">Description</label>
                <div class="col-lg-10">
                                        <textarea type="textarea" id="legalDescriptionSummary"
                                                  name="legalDescriptionSummary"
                                                  class="form-control" rows="5"
                                                  [(ngModel)]="matter.matterPropertyWithCondo.legalDescriptionSummary"
                                        ></textarea>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #actingForTemplate>
    <div *ngIf="isActingForSectionAvailable()">
        <div class="form-group" *dpShowByProvince="'matter.matterOpening.actingOnBehalfOf'">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label"
                       for="actingFor">
                    We are acting for
                </label>
            </div>
            <div class="col-lg-3">
                <select class="form-control" id="actingFor"
                        name="actingFor"
                        fieldKey="matter.matterOpening.actingFor"
                        statusBarHelp
                        [(ngModel)]="matter.actingFor"
                        (ngModelChange)="onActingForChange()"
                        [disabled]="matter.matterLink"
                >
                    <option *ngFor="let actingForOption of actingForOptions"
                            [value]="actingForOption.value">
                        {{actingForOption.label}}
                    </option>
                </select>
            </div>
            <div
                *ngIf="isLinkBtnAvailable()"
                class="col-lg-4">
                <div *ngIf="!matter.matterLink">
                    <ng-container *dpShowByProvince="'matter.matteropening.linkToMatter.btn'">
                        <button label="Link to Matter" class="dp-btn-white"
                                (click)="openLinkMatterModal(true)">
                            <span>Link to New Purchase Matter</span>
                        </button>
                    </ng-container>
                    <ng-container
                        *dpShowByProvince="'matter.matteropening.linkToMatter.burgerMenu'">
                        <dp-dropdown-menu [dropDownItems]="actingForLinkDropDownMenu"
                                          (itemClicked)="clickBurgerMenuItem($event)"
                                          [buttonLabel]="'Link to Matter'"
                                          [customColor]="'white'"
                        ></dp-dropdown-menu>
                    </ng-container>
                </div>
                <div *ngIf="matter.matterLink" class="padding-top-5">
                    <a (click)="openTabForLinkedMatter()">Open {{matter.matterLink.linkedMatterNumber}}
                        Matter</a>
                    <ng-container
                        *ngIf=" !matter.isMatterProvinceAB || !isMattersLinkedContainsPS() ">
                                        <span><i class="fa fa-trash pointerCursor"
                                                 (click)="removeLinkedMatter()"
                                                 aria-hidden="true"
                                                 [title]="'Remove Link'">
                                        </i></span>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="isLenderReLineVisible() && !matter.isMatterTypeDischarge && !this.showWizardFields">
                <div class="col-lg-2 offset-lg-1 vertical-align-label">
                    <label class="control-label" for="lenderReLine">
                        Lender's RE: Line
                    </label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           trimmed
                           id="lenderReLine"
                           name="lenderReLine" class="form-control"
                           maxlength="200"
                           [(ngModel)]="matter.lenderReLine" >
                </div>
            </ng-container>

        </div>
    </div>
</ng-template>

<ng-template #fileNo>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="fileNo">File No.</label>
    </div>
    <div class="col-lg-4">
        <input type="text"
               id="fileNo"
               name="fileNo"
               fieldKey="matter.matterOpening.fileNumber"
               statusBarHelp
               [dynamicHelpCallBackFunction]="generateF9HelpForFileNo()"
               class="form-control"
               (keydown.f9)="matter.fileNumber = matter.matterRecordNumber"
               [(ngModel)]="matter.fileNumber"
               maxlength="50"
               trimmed
        />

    </div>
</ng-template>

<ng-template #lastUpdated>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="lastUpdated">
            Last Updated
        </label>
    </div>
    <div class="col-lg-4">
        <input type="text"
               id="lastUpdated"
               name="lastUpdated"
               class="form-control"
               trimmed
               [ngModel]="matter.lastUpdatedTimeStamp | dpDateFormatPipe"
               [dp-read-only]="true"
        />
    </div>
</ng-template>

<ng-template #lastUpdatedBy>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label" for="lastUpdatedBy">Last Updated By</label>
    </div>
    <div class="col-lg-4">
        <input type="text" trimmed
               id="lastUpdatedBy"
               name="lastUpdatedBy"
               class="form-control"
               [(ngModel)]="matter.lastUpdatedUser"
               [dp-read-only]="true"
        />
    </div>
</ng-template>

<ng-template #fileOpening>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label" for="partialFileOpeningDate">
            File Opening Date
        </label>
    </div>
    <div class="col-lg-4">
        <dp-partial-date #partialFileOpeningDate
                         statusBarHelp
                         [inputDate]="matter.fileOpenDate"
                         [fieldKey]="'matter.matterOpening.fileOpeningDate'"
                         (dateChange)="onDateChangeFileOpeningDate($event)">
        </dp-partial-date>
    </div>
</ng-template>

<ng-template #documentProfile>
    <ng-container *ngIf="documentProfileList.length > 1">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" for="docProfile">Document Profile</label>
        </div>
        <div class="col-lg-4" [dp-read-only-block]="matter.isProjectSale && !this.matter.isTemplateMatterForMassUpdate" [elementRefComp]="elementRef">
            <!-- Remove following link after testing user story 2022 -->
            <!--<a href="javascript:void(0);" (click)="deleteDocumentProfile()">delete document profile (for test, removed later on)</a>-->
            <dp-document-profile-auto-complete-select
                [fieldValueName]="'id'"
                [fieldLabelName]="'profileName'"
                [autoCompleteSelectOptions]="documentProfileList"
                [defaultDocumentProfileId]="matter.documentProfileId"
                (onChange)="onSelectedDocumentProfileChange($event)"
            >
            </dp-document-profile-auto-complete-select>
        </div>
    </ng-container>
</ng-template>

<ng-template #solicitorInitials>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label" for="solicitorInitials"
               dp-province-field-label="matter.matterOpening.solicitor">Solicitor</label>
    </div>

    <div class="col-lg-4">
        <input type="text"
               trimmed
               id="solicitorInitials"
               name="solicitorInitials" class="form-control"
               [ngModel]="matter.solicitorInitials" [dp-read-only]="true">

    </div>
</ng-template>

<ng-template #lawClerkInitials>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="lawClerInitials"
               dp-province-field-label="provinceBasedLawClerkTitle">Law Clerk</label>
    </div>
    <div class="col-lg-4">
        <input type="text"
               trimmed
               id="lawClerInitials"
               name="lawClerInitials" class="form-control"
               [ngModel]="matter.lawClerkInitials" [dp-read-only]="true">
    </div>
</ng-template>

<ng-template #accountingNo>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="accNo">
            Accounting No.
        </label>
    </div>
    <div class="col-lg-4">
        <input type="text"
               id="accNo"
               name="accNo"
               fieldKey="matter.matterOpening.accountingNumber"
               statusBarHelp
               class="form-control"
               (keydown.f9)="handleF9AccountingNumber()"
               [dynamicHelpCallBackFunction]="generateF9AccountingNumber()"
               [(ngModel)]="matter.accountingNumber"
               maxlength="100"
               trimmed
        />
    </div>
</ng-template>

<ng-template #addAppointmentTemplate>
    <div [ngClass]="{'shutter-bg': isApptShutterExpanded && isApptShutterVisible(), 'shutter-bg-closed': !isApptShutterExpanded && isApptShutterVisible()}">
        <div *ngIf="isApptShutterVisible()" class="shutter-arrow preventDoubleClick" (click)="toggleApptShutter()">
            <div *ngIf="isApptShutterExpanded">
                <img src="/assets/img/minus.svg"/>
            </div>
            <div *ngIf="!isApptShutterExpanded">
                <img src="/assets/img/plus.svg"/>
            </div>
        </div>
        <div *ngIf="isApptShutterVisible()" class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label"
                       for="apptSched">
                    Appointments
                </label>
            </div>
            <div class="col-lg-4">
                <input class="form-control"
                       [dp-read-only]="true"
                       [value]="getAppointmentsSummaryText()"
                />
            </div>

        </div>
        <div class="form-group" *ngIf="isApptScheduleFlagVisible()">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label"
                       for="apptSched">
                    Appt. Sched.?
                </label>
            </div>
            <div class="col-lg-1">
                <select class="form-control"
                        id="apptSched"
                        name="apptSched"
                        [(ngModel)]="matter.appointmentScheduledFlag"
                        (change)="enableSave()">
                    <option *ngFor="let status of yesNoItems"
                            [value]="status.value">
                        {{status.label}}
                    </option>
                </select>
            </div>
            <div *ngIf="canCreateAppointment()" class="col-lg-2">
               <span>
                   <span>
                       <a href="javascript:void(0);" (click)="addOrUpdateEvents()">Add Appointment</a>
                   </span>
                   <span class="selector fa fa-plus-square-o padding-left-5"></span>
               </span>
            </div>
        </div>
        <div class="form-group" *ngIf="isAppointmentListVisible()">
            <div class="col-lg-10 offset-lg-2 padding-left-0">
                <div class="col-lg-12 padding-bottom-10 padding-left-0" *ngFor="let appointment of sortedAppointments">
                    <div class="col-lg-3 hyperlink-display"><a (click)="addOrUpdateEvents(appointment)">{{appointment.startDate | date:'fullDate'}}</a></div>
                    <div class="col-lg-6">{{appointment.eventDescription}}</div>
                    <div class="col-lg-2 text-capitalize">{{appointment.appointmentStatusLabel}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #orderNoTemplate>
    <div class="col-lg-2 vertical-align-label"><label class="control-label"
                                                      for="orderNo">
        Order No.
    </label></div>
    <div class="col-lg-4">
        <input type="text"
               id="orderNo"
               name="orderNo"
               class="form-control"
               [(ngModel)]="matter.matterOrderNumber"
               [dp-read-only]="true"
               fieldKey="matter.matterOpening.orderNumber"
               statusBarHelp
               trimmed
               (keydown.f9)="handleF9OrderNumber()"
               [dynamicHelpCallBackFunction]="'F9 = Produce Debit Notice'"
        />
        <dp-burger-menu *ngIf=" matter.matterOrderNumber"
                        class="orderBurgerMenu" [readOnlyOverRide]="true"
                        [dpItems]="orderBurgerMenuItems"
                        (itemClicked)="clickBurgerMenuItem($event)">
        </dp-burger-menu>
    </div>
</ng-template>

<ng-template #accessGroupTemplate>
    <div class="col-lg-2 vertical-align-label offset-lg-6">
        <label class="control-label" for="accessGroup">Access Group</label>
    </div>
    <div class="col-lg-4">
        <select type="text"
                id="accessGroup"
                name="accessGroup"
                class="form-control"
                [ngModel]="matter.accessGroupId ? matter.accessGroupId : ''"
                (ngModelChange)="matter.accessGroupId = $event ? $event : null; enableSave()"
        >
            <option *ngFor="let accessGroup of userAccessGroups"
                    [value]="accessGroup.id ? accessGroup.id : ''">
                {{accessGroup.accessGroupName}}
            </option>
        </select>
    </div>
</ng-template>

<ng-template #completedDropdownTemplate>
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="closed">
            {{getClosedLabel()}}
        </label>
    </div>
    <div class="col-lg-1">
        <select class="form-control"
                id="closed"
                name="closed"
                [(ngModel)]="matter.closed"
                (change)="updateClientsAddressesIfApplicable(); updateMatterReadyFlag()">
            <option *ngFor="let status of yesNoItems"
                    [value]="status.value">
                {{status.label}}
            </option>
        </select>
    </div>
</ng-template>

<ng-template #executingDoctsAt>
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" for="executingDocsAt">
                {{executingDoctsAtText}}
            </label>
        </div>
        <div class="col-lg-3 fullLength-autocomplete jurisdiction-class">
            <p-autoComplete id="jurisdiction"
                            name="jurisdiction"
                            [(ngModel)]="selectedJurisdiction"
                            [class.autocomplete-searching]="jurisdictionsLoading"
                            (ngModelChange)="onPurchaserExecutionDocChange($event)"
                            [suggestions]="jurisdictions"
                            (completeMethod)="searchJurisdiction($event)"
                            field="jurisdictionName"
                            [minLength]="0"
                            placeholder="Search by Name"
                            [delay]="1000"
                            maxlength="75"
                            id="executingDocsAt"
                            scrollHeight="350px"
                            [dp-read-only]="searchDefaultJurisdictionInProgress === true || matter.outOfProvincePurchaserExecDocsAt"
                            (onSelect)="dataSelectedJurisdiction(selectedJurisdiction)"
                            fieldKey="matter.matterOpening.executingDocsAt"
                            statusBarHelp
                            (keyup)="checkIfJurisdictionEmpty(selectedJurisdiction)"
                            class="autocomplete-search-icon">
                <ng-template let-jurisdiction pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [disableItem]="jurisdiction">
                        <div class="col-lg-4 text-overflow"
                             [ngClass]="{'font-style-italic': jurisdiction?.jurisdictionName  == constantNoResultValue}">
                            {{jurisdiction.jurisdictionName}}
                        </div>
                        <span class="col-lg-4 text-overflow"
                              *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                                {{jurisdiction?.city?.name}}
                                            </span>
                        <div class="col-lg-4 text-overflow"
                             *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                            {{jurisdiction?.city?.municipality?.name}}
                        </div>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
        <div class="col-lg-2 padding-top-5"
             *dpShowByProvince="'matter.matterOpening.outOfProvincePurchaserExecDocsAt'">

            <dp-checkbox fieldId="outOfProvincePurchaserExecDocsAt">
                <input id="outOfProvincePurchaserExecDocsAt"
                       name="outOfProvincePurchaserExecDocsAt"
                       type="checkbox"
                       [(ngModel)]="matter.outOfProvincePurchaserExecDocsAt"
                       (change)="changeOutOfProvince()"
                />
            </dp-checkbox>
            <label class="control-label display-inline" for="outOfProvincePurchaserExecDocsAt">
                Out of Province<br>
                <span class="padding-left-30">(unspecified)</span>
            </label>
            <span class="tool-tip-icon">
                                <i class="fa fa-info-circle padding-left-15 margin-top-minus-30 font-16 "
                                    aria-hidden="false"></i>
                            </span>
            <span class="tool-tip-message">
                                <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                <span>Enter the jurisdiction or to select another province start typing "Other Province..."<br>or if unknown check the "Out of Province" checkbox.</span>
                            </span>
        </div>
        <div class="vertical-align-label"
             [ngClass]="matter.isMatterProvinceON? 'col-lg-2' : 'col-lg-1'">
            <label class="control-label" for="purchaserOn">On</label>
        </div>
        <div class="col-lg-2">
            <dp-partial-date
                #partialPurchaserOnDate
                id="purchaserOn"
                [inputDate]="matter.purchaserExecDocsDate"
                fieldKey="matter.matterOpening.purchaserExecDocsDate"
                dp-error-validator
                statusBarHelp
                (dateChange)="onDateChangePurchaserOnDate($event)">
            </dp-partial-date>
        </div>
    </div>
</ng-template>

<ng-template #willDocsSignedRemotely>
    <div class="form-group" *ngIf="matter && matter.isDigitalSignatureEnabledInProvince(appConfig)">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" for="documentsToBeSignedRemotely">
                Will documents be signed remotely?
            </label>
        </div>
        <div class="col-lg-1">
            <select class="form-control"
                    id="documentsToBeSignedRemotely"
                    name="documentsToBeSignedRemotely"
                    [(ngModel)]="matter.documentsToBeSignedRemotely"
                    (change)="enableSave();">
                <option *ngFor="let status of yesNoDefaultNoOptions"
                        [value]="status.value">
                    {{ status.label }}
                </option>
            </select>
        </div>
    </div>
</ng-template>

<ng-template #isFileInactiveDropdownTemplate>
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label"
                   for="fileInactive">
                Is File inactive?
            </label>
        </div>
        <div class="col-lg-1">
            <select class="form-control"
                    id="fileInactive"
                    name="fileInactive"
                    [(ngModel)]="matter.matterStatus"
                    (change)="enableSave();updateMatterReadyFlag();">
                <option *ngFor="let status of yesNoMatterFile"
                        [value]="status.value">
                    {{ status.label }}
                </option>
            </select>
        </div>
        <ng-container *ngIf="!matter.isMatterActive || matter.isTemplateMatterForMassUpdate">
            <div class="offset-lg-3 col-lg-2 vertical-align-label">
                <label class="control-label"
                       for="closingDate">
                    Inactive Date
                </label>
            </div>
            <div class="col-lg-4">
                <dp-partial-date
                    #partialInactiveDate
                    [inputDate]="matter.fileInactiveDate"
                    [fieldKey]="'matter.matterOpening.fileInactiveDate'"
                    dp-error-validator=""
                    statusBarHelp
                    (dateChange)="onDateChangeInactiveDate($event)">
                </dp-partial-date>
            </div>
        </ng-container>
    </div>

    <div class="form-group" *ngIf="!matter.isMatterActive || matter.isTemplateMatterForMassUpdate">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label"
                   for="fileInactiveNotes">
                File inactive notes
            </label>
        </div>
        <div class="col-lg-10">
            <input type="text"
                   id="fileInactiveNotes"
                   name="fileInactiveNotes"
                   class="form-control"
                   [(ngModel)]="matter.fileInactiveNotes"
                   maxlength="100"
                   trimmed
            />
        </div>
    </div>
</ng-template>

<ng-template #closingDateWarning>
    <div class="form-group" *ngIf="isMatterEditingDisabledAfterPostClosingDate">
        <div class="col-lg-2"></div>
        <div class="col-lg-4" style="font-size: 12px">
            <i class="fa fa-warning" style="color: #ffcc00; line-height: 14px"></i>
            Closing date changes have been disabled for this matter because it is past its closing date. Please contact Unity customer support if you have any questions.
        </div>
        <div class="col-lg-6">
        </div>
    </div>
</ng-template>
<ng-template #lockBoxCodeTemplate>
    <ng-container *ngIf="isLockboxCodeApplicable()">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label"
                   for="lockboxCode">
                Lockbox Code
            </label>
        </div>
        <div class="col-lg-4">
            <input type="text"
                   id="lockboxCode"
                   name="lockboxCode"
                   class="form-control"
                   [(ngModel)]="matter.lockboxCode"
                   (change)="enableSave();"
                   maxlength="100"
            />
        </div>
    </ng-container>
</ng-template>
