import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../shared/matter';
import {ErrorService} from '../../shared/error-handling/error-service';
import {MatterIdCleaner} from '../matter-id-cleaner';
import {LockScreenService} from '../../core/lock-screen.service';
import {TabsService} from '../../core/tabs.service';
import {MatterService} from '../matter.service';
import {DPError} from '../../shared/error-handling/dp-error';
import {TabsComponent} from '../../shared/tabbing/tabs.component';
import {MatterProperty} from '../shared/matter-property';
import moment from 'moment';
import {MatterTab} from '../matter-tab';
import {
  booleanYesNoDropDowns,
  ERegStatus,
  FILE_NO_MAX_LENGTH,
  MATTER_RECORD_NUMBER_MAX_LENGTH
} from '../../shared-main/constants';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../shared';
import {Address} from '../shared/address';
import {DialogService} from '../../shared/dialog/dialog.service';
import Utils from '../../shared-main/utils';
import {CondominiumPlan} from '../property-teranet/unit-level-plan/condominium-plan';
import * as _ from 'lodash';
import {condoPlanTypeDropDownOptions} from '../../shared-main/province-based-dropdowns';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {CondominiumExpense} from '../property-teranet/unit-level-plan/condominium-expense';
import {SelectUnitLevelPlanResult} from '../shared/select-unit-level-plan-result';
import {StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {UnitLevelPlanUtil} from '../property-teranet/unit-level-plan/unit-level-plan-util';
import {EregistrationUtil} from '../forms/eregistration/eregistration-util';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var jQuery: any;

export class MassOpenProjectModalContext {
  public sourceMatter: Matter;
  public tabsComponent: TabsComponent;
  public tabsService: TabsService;
}

@Component({
  selector: 'mass-open-project-modal',
  templateUrl: 'mass-open-project-modal.component.html',
  styleUrls: ['./mass-open-project-modal.styles.scss'],
  providers: [ErrorService]
})
export class MassOpenProjectModalComponent extends ModalComponent<MassOpenProjectModalContext> implements OnInit {
  targetMatter: Matter;
  createMatterDisabled: boolean = false;
  matterRecordNumber: string;
  fileNumber: string;
  needAutoIncrementFileNo: boolean = true;
  autoIncrementPartLot: boolean = false;
  autoIncrementPlan: boolean = false;
  autoIncrementBeingPart: boolean = false;
  autoIncrementOnPlan: boolean = false;
  autoIncrementBlock: boolean = false;
  autoIncrementLot: boolean = false;
  teraviewLegalDescOverriddenTypes: SelectItem[];
  autoIncrementPin: boolean[] = [];
  currentTimeStamp = Date.now();
  condoUnitType: SelectItem[];
  unitLevelPlan: MatterProperty;
  globalLegalDescription: string = '';
  commonExpenseAdj: StatementAdjustment;
  exceptionYNOptions: SelectItem[] = booleanYesNoDropDowns.YesNo_DefaultBlank;
  condoPlanType: SelectItem[];
  filteredExceptionTypeOptions: any[];
  exceptionTypeOptions: SelectItem[];
  exceptionDescriptionOptions: SelectItem[];

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<MassOpenProjectModalComponent>,
    public errorService: ErrorService,
    public lockScreenService: LockScreenService,
    public tabsStateService: TabsService,
    public matterService: MatterService,
    public statusBarService: StatusBarService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: MassOpenProjectModalContext
  ) {
    super(dialog, context);
    for (let i = 0; i < this.context.sourceMatter.additionalMatterProperty.length; i++) {
      this.autoIncrementPin.push(false);
    }
  }

  ngOnInit(): void {
    this.initializeTargetMatter();
  }

  initializeTargetMatterTeraviewDocketId() {
    this.targetMatter.initializeTargetMatterTeraviewDocketId();
  }

  initializeTargetMatter(): void {
    //Disabling create matter button until target matter is copied
    this.createMatterDisabled = true;
    this.targetMatter = new Matter(this.context.sourceMatter);
    console.log(this.context.sourceMatter, this.targetMatter);
    this.targetMatter.billingTransactions = [];
    this.targetMatter.matterStatus = 'DEFAULT_ACTIVE';
    this.targetMatter.reset = false;
    this.targetMatter.soaTrustLedgerCollection = null;
    this.targetMatter.project = null;
    this.targetMatter.teraviewDocketId = '';
    this.targetMatter.teranetDocket = null;
    this.targetMatter.lastUpdatedTimeStamp = null;
    this.targetMatter.updatedTimeStamp = null;
    this.targetMatter.updatedByUser = null;
    this.targetMatter.lockedByUser = null;
    // According to Hardik's suggestion.
    // Clean un applied adjustments, it will get them back from project after save
    // If we don't clean up _finalAdjustmentsUnApplied and _interimAdjustmentsUnApplied, these array elements will become object
    this.targetMatter._finalAdjustmentsUnApplied = [];
    this.targetMatter._interimAdjustmentsUnApplied = [];
    this.targetMatter = MatterIdCleaner.clearMatterIds(this.targetMatter);
    this.targetMatter.matterRecordNumber = Utils.massOpenIncrementValue(
      this.context.sourceMatter && this.context.sourceMatter.matterRecordNumber,
      MATTER_RECORD_NUMBER_MAX_LENGTH
    );
    if (this.needAutoIncrementFileNo) {
      this.targetMatter.fileNumber = Utils.massOpenIncrementValue(
        this.context.sourceMatter && this.context.sourceMatter.fileNumber,
        FILE_NO_MAX_LENGTH
      );
    }
    this.targetMatter.fileOpenDate = moment(new Date()).format('YYYY/MM/DD');
    if (this.context.sourceMatter.matterPropertyWithCondo.isCondominium != 'YES') {
      if (this.autoIncrementPartLot) {
        this.targetMatter.matterPropertyWithCondo.partLot = Utils.massOpenIncrementPropertyValue(
          this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo.partLot,
          100
        );
      }
      if (this.autoIncrementPlan) {
        this.targetMatter.matterPropertyWithCondo.plan = Utils.massOpenIncrementPropertyValue(
          this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo.plan,
          100
        );
      }
      if (this.autoIncrementBeingPart) {
        this.targetMatter.matterPropertyWithCondo.beingPart = Utils.massOpenIncrementPropertyValue(
          this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo.beingPart,
          80
        );
      }
      if (this.autoIncrementOnPlan) {
        this.targetMatter.matterPropertyWithCondo.onPlan = Utils.massOpenIncrementPropertyValue(
          this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo.onPlan,
          80
        );
      }
      if (this.autoIncrementPin[0]) {
        this.targetMatter.matterPropertyWithCondo.pin = this.incrementPin(
          this.context.sourceMatter.matterPropertyWithCondo.pin
        );
      }
      if (this.autoIncrementBlock) {
        this.targetMatter.matterPropertyWithCondo.block = Utils.massOpenIncrementPropertyValue(
          this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo.block,
          100
        );
      }
      if (this.autoIncrementLot) {
        this.targetMatter.matterPropertyWithCondo.lot = Utils.massOpenIncrementPropertyValue(
          this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo.lot,
          100
        );
      }
      for (let x = 0; x <= this.context.sourceMatter.additionalMatterProperty.length; x++) {
        if (this.context.sourceMatter.additionalMatterProperty[x]) {
          if (this.autoIncrementPin[x + 1]) {
            this.targetMatter.additionalMatterProperty[x].pin = this.incrementPin(
              this.context.sourceMatter.additionalMatterProperty[x].pin
            );
          }
        }
      }
    }

    if (this.targetMatter.isPropertyCondo()) {
      if (this.targetMatter.isMatterProvinceON) {
        this.initializeUnitLevelPlanON(this.targetMatter.matterPropertyWithCondo);
      } else if (this.targetMatter.isMatterProvinceAB) {
        this.initializeUnitLevelPlanAB(this.targetMatter.matterPropertyWithCondo);
      }
    } else {
      if (this.targetMatter.isMatterProvinceAB) {
        this.initializeUnitLevelPlanAB(this.targetMatter.matterPropertyWithCondo);
      }
    }

    this.matterService.initMatter(this.targetMatter).subscribe(
      (isMatterInitialized) => {
        if (isMatterInitialized) {
          this.createMatterDisabled = false;
          if (this.targetMatter.project) {
            this.initializeTargetMatterTeraviewDocketId();
          }
        }
      },
      (error: any) => {
        this.errorService.addDpSaveError(
          DPError.createCustomDPError(error.errorCode, error.errorCode + ':' + error.errorMessage, null, 'ERROR')
        );
      }
    );
    if (this.targetMatter.isMatterProvinceON) {
      this.teraviewLegalDescOverriddenTypes = dropDowns.teraviewLegalDescOverriddenTypes;
    }

    if (this.targetMatter.isPropertyCondo() && this.targetMatter.isMatterProvinceON) {
    }
    this.condoPlanType = condoPlanTypeDropDownOptions[this.targetMatter.provinceCode];
    this.exceptionTypeOptions = dropDowns.isExceptionTypeOptions;
    this.exceptionDescriptionOptions = dropDowns.isExceptionDescriptionOptions;
  }

  syncUnitLevelPlanDataToTargetMatter(): void {
    if (this.targetMatter.isMatterProvinceAB && this.unitLevelPlan) {
      this.targetMatter.matterPropertyWithCondo.propertyDescriptionType = this.unitLevelPlan.propertyDescriptionType;
      this.targetMatter.matterPropertyWithCondo.plan = this.unitLevelPlan.plan;
      this.targetMatter.matterPropertyWithCondo.block = this.unitLevelPlan.block;
      this.targetMatter.matterPropertyWithCondo.lot = this.unitLevelPlan.lot;
      this.targetMatter.matterPropertyWithCondo.exceptionType = this.unitLevelPlan.exceptionType;
      this.targetMatter.matterPropertyWithCondo.exceptionTypeDescription = this.unitLevelPlan.exceptionTypeDescription;
      this.targetMatter.matterPropertyWithCondo.shortLegalDescription = this.unitLevelPlan.shortLegalDescription;
      this.targetMatter.matterPropertyWithCondo.fullLegalDescription = this.unitLevelPlan.fullLegalDescription;
    }
  }

  updateERegForms(): void {
    if (this.targetMatter.eRegistrationForms.length > 0) {
      this.targetMatter.eRegistrationForms.forEach(
        (eRegForm) => (eRegForm.eregistrationStatus = ERegStatus.EREG_INCOMPLETE)
      );
      EregistrationUtil.validateAndUpdateERegFormStatus(this.targetMatter, this.errorService);
    }
  }

  createMatter() {
    if (this.targetMatter.isPropertyCondo()) {
      this.saveUnitLevelPlanData();
    } else {
      //non-Condo
      this.syncUnitLevelPlanDataToTargetMatter();
    }

    this.modalErrorComponent.removeDpFieldError('matter.matterOpening.matterNo');
    this.errorService.removeDpSaveError('matter.matterOpening.uniqueMatterNo');

    if (!this.targetMatter.matterRecordNumber || this.targetMatter.matterRecordNumber.trim().length == 0) {
      this.modalErrorComponent.createDPFieldError('matter.matterOpening.newMatterNo');
    }

    if (this.modalErrorComponent.anyErrorExist()) {
      return;
    }

    this.createMatterDisabled = true;
    this.errorService.removeAllDpFieldError();
    let templateMatterTab = this.context.tabsService.activeTab as MatterTab;

    // Remove the matterLink for Mass open.
    this.targetMatter.matterLink = null;
    //Updating EReg Forms before matter creation as PIN's can be edited/Added in Modal
    this.updateERegForms();

    this.matterService
      .validateMatter(this.targetMatter, null, this.errorService)
      .finally(() => {
        this.createMatterDisabled = false;
      })
      .subscribe((matterValidated) => {
        if (matterValidated) {
          this.targetMatter.cleanUpMatterBeforeSaving(this.matterService);
          this.matterService.addMatter(this.targetMatter).subscribe(
            (matter: Matter) => {
              matter.project = this.context.sourceMatter.project;
              this.context.tabsComponent.removeMatterTab(templateMatterTab);
              this.context.tabsComponent.openMatter(matter);
              //New matter becomes the source for next mass open matter and re-initialize target matter
              this.context.sourceMatter = matter;
              this.initializeCondoExpenseAutoInc();
              this.initializeTargetMatter();
            },
            (error: any) => {
              this.errorService.addDpSaveError(
                DPError.createCustomDPError(error.errorCode, error.errorCode + ':' + error.errorMessage, null, 'ERROR')
              );
            }
          );
        }
      });
  }

  initializeCondoExpenseAutoInc(): void {
    if (
      this.context.sourceMatter.isPropertyCondo() &&
      this.context.sourceMatter.matterPropertyWithCondo.condominiumExpenses &&
      this.context.sourceMatter.matterPropertyWithCondo.condominiumExpenses.length
    ) {
      this.context.sourceMatter.matterPropertyWithCondo.condominiumExpenses.forEach((ce, index) => {
        ce.autoIncrementUnitNumber = this.unitLevelPlan.condominiumExpenses[index].autoIncrementUnitNumber;
        ce.autoIncrementLevelNumber = this.unitLevelPlan.condominiumExpenses[index].autoIncrementLevelNumber;
        ce.autoIncrementPinNumber = this.unitLevelPlan.condominiumExpenses[index].autoIncrementPinNumber;
      });
    }
  }

  close() {
    this.dialog.close();
  }

  addNewPIN(): void {
    this.currentTimeStamp = Date.now();
    let matterProperty = new MatterProperty();
    matterProperty.address = new Address();
    matterProperty.matterPropertyCode = 'QUESTION';
    matterProperty.isPinValid = true;
    this.targetMatter.matterProperties.push(matterProperty);
    this.autoIncrementPin.push(false);
  }

  overrideDescChange(matterProperty?: MatterProperty) {
    if (matterProperty) {
      if (!matterProperty.overrideDescription) {
        matterProperty.descriptionOverriddenType = null;
        matterProperty.fullLegalDescription = null;
      } else if (matterProperty.overrideDescription && !matterProperty.descriptionOverriddenType) {
        matterProperty.descriptionOverriddenType = dropDowns.teraviewLegalDescOverriddenTypes[0].value;
      }
    }
  }

  updateTeraviewLegalDescs(): void {
    if (this.targetMatter.isProjectSale && this.targetMatter.isMatterProvinceON) {
      this.targetMatter.updateTeraviewLegalDescs();
    }
  }

  incrementPin(pin: string): string {
    if (pin && pin.length === 9) {
      let firstHalf = pin.substring(0, 5);
      let secondHalf = pin.substring(5, 9);
      secondHalf = Utils.massOpenIncrementPropertyValue(secondHalf, 4);
      return firstHalf + secondHalf;
    }
    return pin;
  }

  autoIncrementUpdate(field: string, index?: number): void {
    switch (field) {
      case 'PartLot':
        if (this.autoIncrementPartLot) {
          this.targetMatter.matterPropertyWithCondo.partLot = Utils.massOpenIncrementPropertyValue(
            this.targetMatter && this.targetMatter.matterPropertyWithCondo.partLot,
            100
          );
        }
        break;
      case 'Plan':
        if (this.autoIncrementPlan) {
          this.targetMatter.matterPropertyWithCondo.plan = Utils.massOpenIncrementPropertyValue(
            this.targetMatter && this.targetMatter.matterPropertyWithCondo.plan,
            100
          );
        }
        break;
      case 'BeingPart':
        if (this.autoIncrementBeingPart) {
          this.targetMatter.matterPropertyWithCondo.beingPart = Utils.massOpenIncrementPropertyValue(
            this.targetMatter && this.targetMatter.matterPropertyWithCondo.beingPart,
            80
          );
        }
        break;
      case 'OnPlan':
        if (this.autoIncrementOnPlan) {
          this.targetMatter.matterPropertyWithCondo.onPlan = Utils.massOpenIncrementPropertyValue(
            this.targetMatter && this.targetMatter.matterPropertyWithCondo.onPlan,
            80
          );
        }
        break;

      case 'PIN1':
        if (this.autoIncrementPin[index]) {
          this.targetMatter.matterPropertyWithCondo.pin = this.incrementPin(
            this.targetMatter.matterPropertyWithCondo.pin
          );
        }
        break;
      case 'PIN':
        if (this.autoIncrementPin[index + 1]) {
          this.targetMatter.additionalMatterProperty[index].pin = this.incrementPin(
            this.targetMatter.additionalMatterProperty[index].pin
          );
        }
        break;
      case 'UnitNumber':
        if (this.unitLevelPlan.condominiumExpenses[index].autoIncrementUnitNumber) {
          this.unitLevelPlan.condominiumExpenses[index].unitNumber = Utils.massOpenIncrementPropertyValue(
            this.unitLevelPlan.condominiumExpenses[index].unitNumber,
            5
          );
          this.teraviewLegalDescOverriddenTypeChanged(this.unitLevelPlan.condominiumExpenses[index]);
        }
        break;
      case 'LevelNumber':
        if (this.unitLevelPlan.condominiumExpenses[index].autoIncrementLevelNumber) {
          this.unitLevelPlan.condominiumExpenses[index].levelNumber = Utils.massOpenIncrementPropertyValue(
            this.unitLevelPlan.condominiumExpenses[index].levelNumber,
            5
          );
          this.teraviewLegalDescOverriddenTypeChanged(this.unitLevelPlan.condominiumExpenses[index]);
        }
        break;
      case 'PinNumber':
        if (this.unitLevelPlan.condominiumExpenses[index].autoIncrementPinNumber) {
          this.unitLevelPlan.condominiumExpenses[index].pinNumber = Utils.massOpenIncrementPropertyValue(
            this.unitLevelPlan.condominiumExpenses[index].pinNumber,
            9
          );
        }
        break;
      case 'PlanNum':
        if (this.autoIncrementPlan) {
          this.unitLevelPlan.plan = Utils.massOpenIncrementPropertyValue(this.unitLevelPlan.plan, 100);
        }
        break;
      case 'BlockNum':
        if (this.autoIncrementBlock) {
          this.unitLevelPlan.block = Utils.massOpenIncrementPropertyValue(this.unitLevelPlan.block, 100);
        }
        break;
      case 'LotNum':
        if (this.autoIncrementLot) {
          this.unitLevelPlan.lot = Utils.massOpenIncrementPropertyValue(this.unitLevelPlan.lot, 100);
        }
        break;
    }
    this.updateTeraviewLegalDescs();
  }

  /******************************************************/

  isOverrideDescIndVisible(): boolean {
    return UnitLevelPlanUtil.isOverrideDescIndVisible(this.targetMatter);
  }

  getPlanNumber(condominiumPlan: CondominiumPlan) {
    return UnitLevelPlanUtil.getPlanNumber(condominiumPlan, this.targetMatter);
  }

  addCustomPinWarning(elementValue, errorElementKey, index): void {
    UnitLevelPlanUtil.addCustomPinWarning(elementValue, errorElementKey, index, this.modalErrorComponent);
  }

  updateTotalShare(): void {
    UnitLevelPlanUtil.updateTotalShare(this.unitLevelPlan);
  }

  onAutoCalcChange(event: any, index: number) {
    if (this.unitLevelPlan.condominiumExpenses[index].autoCalcInd) {
      this.updateCondominiumExpense(index);
    }
  }

  updateCondominiumExpense(index: number): void {
    UnitLevelPlanUtil.updateCondominiumExpense(index, this.unitLevelPlan, this.context.sourceMatter.project);
    this.statusBarService.currentHelpText = '';
  }

  updateTotalExpenses(): void {
    this.statusBarService.currentHelpText = '';
    UnitLevelPlanUtil.updateTotalExpenses(this.unitLevelPlan);
  }

  getPinNumberColumnCssClass(): string {
    return this.unitLevelPlan.condominiumPlans.length > 1 ? 'col-md-1' : 'col-md-3';
  }

  updatecondominiumExpenseF9Help(index: number): void {
    this.statusBarService.currentHelpText =
      'F9=' +
      (Number(this.unitLevelPlan.condominiumExpenses[index].sharePercentage) *
        this.targetMatter.project.projectAdjustmentConfig.totalMonthlyCommonExpenses) /
        100;
  }

  condominiumExpensef9Clicked(index: number): void {
    this.updateCondominiumExpense(index);
  }

  loadProjectCondoUnitTypes(): void {
    this.condoUnitType = UnitLevelPlanUtil.loadProjectCondoUnitTypes(this.context.sourceMatter.project);
  }

  overrideCondoDescChange(event: any, index: number) {
    UnitLevelPlanUtil.overrideCondoDescChange(index, this.unitLevelPlan);
  }

  initializeUnitLevelPlanON(matterProperty: MatterProperty) {
    if (matterProperty.isPropertyCondominium()) {
      this.unitLevelPlan = _.cloneDeep(matterProperty);
      this.checkAutoIncrement();
      if (this.unitLevelPlan.isCondominiumPlanEmpty()) {
        this.initializeCondoPlan();
      }
      if (this.unitLevelPlan.isCondominiumExpenseEmpty()) {
        this.initializeCondoExpense();
      } else {
        this.createCondominiumPlanObject();
      }
      this.setGlobalLegalDescription();
      this.loadProjectCondoUnitTypes();
    }
  }

  initializeUnitLevelPlanAB(matterProperty: MatterProperty) {
    if (matterProperty.isPropertyCondominium()) {
      this.unitLevelPlan = _.cloneDeep(matterProperty);
      this.checkAutoIncrement();
      if (!this.unitLevelPlan.exceptionTypeDescription) {
        this.unitLevelPlan.exceptionTypeDescription = '';
      }
      if (!this.unitLevelPlan.exceptionType) {
        this.unitLevelPlan.exceptionType = '';
      }
    } else {
      this.unitLevelPlan = _.cloneDeep(matterProperty);
    }

    if (this.unitLevelPlan.isCondominiumPlanEmpty()) {
      this.initializeCondoPlan();
    }
    if (this.unitLevelPlan.isCondominiumExpenseEmpty()) {
      this.initializeCondoExpense();
    } else {
      this.createCondominiumPlanObject();
    }
    this.loadProjectCondoUnitTypes();
  }

  checkAutoIncrement(): void {
    if (this.unitLevelPlan && this.unitLevelPlan.condominiumExpenses && this.unitLevelPlan.condominiumExpenses.length) {
      this.unitLevelPlan.condominiumExpenses.forEach((ce, index) => {
        if (ce.autoIncrementUnitNumber) {
          this.autoIncrementUpdate('UnitNumber', index);
        }
        if (ce.autoIncrementLevelNumber) {
          this.autoIncrementUpdate('LevelNumber', index);
        }
        if (ce.autoIncrementPinNumber) {
          this.autoIncrementUpdate('PinNumber', index);
        }
      });
    }
  }

  initializeCondoPlan(): void {
    UnitLevelPlanUtil.initializeCondoPlan(this.unitLevelPlan, this.targetMatter);
  }

  initializeCondoExpense(): void {
    UnitLevelPlanUtil.initializeCondoExpense(this.unitLevelPlan);
  }

  createCondominiumPlanObject(): void {
    UnitLevelPlanUtil.createCondominiumPlanObject(this.unitLevelPlan, this.targetMatter);
  }

  createCondominiumExpense(): CondominiumExpense {
    return UnitLevelPlanUtil.createCondominiumExpense();
  }

  teraviewLegalDescOverriddenTypeChanged(condominiumExpense: CondominiumExpense): void {
    UnitLevelPlanUtil.teraviewLegalDescOverriddenTypeChanged(condominiumExpense, this.targetMatter, this.unitLevelPlan);
  }

  setGlobalLegalDescription(): void {
    if (this.unitLevelPlan && this.unitLevelPlan.condominiumExpenses && this.unitLevelPlan.condominiumExpenses.length) {
      this.unitLevelPlan.condominiumExpenses.forEach((condominiumExpense) => {
        if (condominiumExpense.overrideDescInd && condominiumExpense.isLegalDescriptionApplyLegalDescEasement()) {
          this.globalLegalDescription = condominiumExpense.teraviewLegalDesc;
        }
      });
    }
  }

  addCondominiumExpense(): void {
    this.currentTimeStamp = Date.now();
    this.unitLevelPlan.condominiumExpenses.push(this.createCondominiumExpense());

    setTimeout(() => {
      jQuery('.condo-expense-unit input').filter(':visible:last').focus();
    }, 100);
  }

  saveUnitLevelPlanData(): void {
    if (this.targetMatter.isMatterProvinceAB) {
      UnitLevelPlanUtil.checkLincAndRollNumber(
        this.modalErrorComponent,
        this.unitLevelPlan,
        this.targetMatter.provinceCode
      );
    } else if (this.targetMatter.isMatterProvinceON) {
      this.checkOverrideDescription();
    }
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.updatePlanNumber();
      this.updateCommonExpense();
      let selectUnitLevelPlanResult: SelectUnitLevelPlanResult = this.createSelectUnitLevelPlanResult();
      this.matterService.updateMatterWithUnitLevelPlanChange(this.targetMatter, selectUnitLevelPlanResult);
    }
  }

  checkOverrideDescription(): void {
    UnitLevelPlanUtil.checkOverrideDescription(this.unitLevelPlan);
  }

  updatePlanNumber(): void {
    UnitLevelPlanUtil.updatePlanNumber(this.unitLevelPlan, this.targetMatter, true);
  }

  updateCommonExpense() {
    this.commonExpenseAdj = this.targetMatter.statementOfAdjustments.find((item) =>
      item.isCommonExpenseAdjustmentFromProperty()
    );
    if (this.commonExpenseAdj && this.commonExpenseAdj.soaCommonExpense) {
      this.commonExpenseAdj.soaCommonExpense.commonExpenseAmount = Number(this.unitLevelPlan.condominiumTotalExpenses);
      this.commonExpenseAdj.updateFromCommonExpense(
        this.targetMatter.getClosingDate(),
        this.targetMatter.isPaysForDateOfClosingVendor
      );
    }
  }

  createSelectUnitLevelPlanResult(): SelectUnitLevelPlanResult {
    let selectUnitLevelPlanResult = new SelectUnitLevelPlanResult();
    selectUnitLevelPlanResult.plan = this.unitLevelPlan;
    return selectUnitLevelPlanResult;
  }

  planNumerChanged(condominiumExpense: CondominiumExpense): void {
    if (condominiumExpense.condominiumPlan) {
      condominiumExpense.planNumber = this.getPlanNumber(condominiumExpense.condominiumPlan);
    }
    this.teraviewLegalDescOverriddenTypeChanged(condominiumExpense);
  }

  removeCondominiumExpense(condominiumExpense: CondominiumExpense): void {
    this.unitLevelPlan.removeCondominiumExpense(condominiumExpense);
    this.updateDPFieldErrors();
    this.updateTotalShare();
    this.updateTotalExpenses();
  }

  updateDPFieldErrors(): void {
    UnitLevelPlanUtil.updateDPFieldErrors(this.modalErrorComponent, this.unitLevelPlan);
  }

  getMainTitle(): string {
    if (
      this.targetMatter.isMatterProvinceON ||
      (this.targetMatter.isMatterProvinceAB &&
        this.targetMatter.matterPropertyWithCondo &&
        this.targetMatter.matterPropertyWithCondo.isPropertyCondominium())
    ) {
      return 'Property Detail';
    } else {
      return 'Legal Description';
    }
  }

  initPlanBlockLotFields() {
    this.unitLevelPlan.propertyDescriptionType = 'PLAN_BLOCK_LOT';
    const sourceMatterProperty = this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo;
    this.unitLevelPlan.plan = sourceMatterProperty ? sourceMatterProperty.plan : '';
    this.unitLevelPlan.block = sourceMatterProperty ? sourceMatterProperty.block : '';
    this.unitLevelPlan.lot = sourceMatterProperty ? sourceMatterProperty.lot : '';
    this.autoIncrementPlan = false;
    this.autoIncrementBlock = false;
    this.autoIncrementLot = false;
    this.unitLevelPlan.exceptionType = sourceMatterProperty ? sourceMatterProperty.exceptionType : '';
    this.unitLevelPlan.exceptionTypeDescription = sourceMatterProperty
      ? sourceMatterProperty.exceptionTypeDescription
      : '';
    //cleanup / disable the corresponding fields for METES_AND_BOUNDS
    this.unitLevelPlan.shortLegalDescription = '';
    this.unitLevelPlan.fullLegalDescription = '';
  }

  initMetesAndBoundsFields() {
    this.unitLevelPlan.propertyDescriptionType = 'METES_AND_BOUNDS';
    const sourceMatterProperty = this.context.sourceMatter && this.context.sourceMatter.matterPropertyWithCondo;
    this.unitLevelPlan.shortLegalDescription = sourceMatterProperty ? sourceMatterProperty.shortLegalDescription : '';
    this.unitLevelPlan.fullLegalDescription = sourceMatterProperty ? sourceMatterProperty.fullLegalDescription : '';
    //cleanup / disable the corresponding fields for PLAN_BLOCK_LOT
    this.unitLevelPlan.plan = '';
    this.unitLevelPlan.block = '';
    this.unitLevelPlan.lot = '';
    this.autoIncrementPlan = false;
    this.autoIncrementBlock = false;
    this.autoIncrementLot = false;
    this.unitLevelPlan.exceptionType = '';
    this.unitLevelPlan.exceptionTypeDescription = '';
  }

  initExceptionTypeDescription(event) {
    if (!(this.unitLevelPlan.exceptionType === 'YES')) {
      this.unitLevelPlan.exceptionTypeDescription = '';
    }
  }

  handleDropdownClickExceptionTypeDescription = () =>
    (this.filteredExceptionTypeOptions = this.exceptionDescriptionOptions.map((item) => item.label));

  filterExceptionTypeDescription(event) {
    this.filteredExceptionTypeOptions = this.exceptionDescriptionOptions
      .map((item) => item.label)
      .filter((label) => label && label.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
  }
}
