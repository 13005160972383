import {TrustLedgerMatter} from '../trust-ledger/trust-ledger-matter';
import {Mortgage} from './mortgage';
import {WillResidueRoleType} from './will-type-of-beneficiary';

export class MatterTypesValue {
  public static readonly PURCHASE: MatterType = 'PURCHASE';
  public static readonly SALE: MatterType = 'SALE';
  public static readonly MORTGAGE: MatterType = 'MORTGAGE';
  public static readonly CUSTOM: MatterType = 'CUSTOM';
  public static readonly OPPORTUNITY: MatterType = 'OPPORTUNITY';
  public static readonly DISCHARGE: MatterType = 'DISCHARGE';
  public static readonly WILL: MatterType = 'WILL';
}

export type OverrideMatterStatusType =
  'FLAGGED'
  | 'MATTER_INACTIVE'
  | 'MATTER_CLOSED'
  | 'MATTER_PAST_CLOSING_DATE'
  | 'MATTER_IN_PROGRESS'
  | 'MATTER_READY';

export type CommissionPaidToType = 'VENDOR_BROKER_ONLY' | 'PURCHASER_BROKER_ONLY' | 'BOTH_VENDOR_AND_PURCHASER_BROKER';
export type CommissionDisbursedToType = 'VENDOR_BROKER_ONLY' | 'PURCHASER_BROKER_ONLY' | 'BOTH_VENDOR_AND_PURCHASER_BROKER';

export type CommissionDisbursedByType = 'PURCHASER_LAWYER' | 'VENDOR_LAWYER';
export type BrokerReleaseExcessToType = 'PURCHASER_LAWYER' | 'VENDOR_LAWYER' | 'BUILDER' | 'DEVELOPER';

export type MatterType = 'PURCHASE' | 'SALE' | 'MORTGAGE' | 'CUSTOM' | 'OPPORTUNITY' | 'DISCHARGE' | 'WILL';
export type FctMortgageType = 'COLLATERAL' | 'CONVENTIONAL';
export type CashOnClosingDateType =
  'NIL'
  | 'CASH_TO_CLOSE'
  | 'CASH_DIFFERENCE'
  | 'BALANCE_TO_CLOSE'
  | 'BALANCE_OF_DOWN_PAYMENT';
export type PurchaserFinancingType = 'NEW_MORTGAGE' | 'CASH' | 'ASSUME_MORTGAGE';
export type ProtocolClosingType = 'UNDETERMINED' | 'YES' | 'NO';

export class TrustLedgerMatterMortgageObjectTemplate {
  trustLedgerMatter: TrustLedgerMatter;
  mortgage: Mortgage;
}

export type OpportunityMatterStatusType = 'QUALIFYING' | 'QUOTED' | 'CLOSED_CONVERTED' | 'CLOSED_INACTIVE' | 'CLOSED_DUPLICATE';
export const OpportunityMatterStatusValue = {
  qualifying: <OpportunityMatterStatusType>'QUALIFYING',
  quoted: <OpportunityMatterStatusType>'QUOTED',
  closedConverted: <OpportunityMatterStatusType>'CLOSED_CONVERTED',
  closedInactive: <OpportunityMatterStatusType>'CLOSED_INACTIVE',
  closedDuplicate: <OpportunityMatterStatusType>'CLOSED_DUPLICATE'
};
export type OpportunityMatterSourceType =
  'BLANK'
  | 'REQUEST_FOR_QUOTE'
  | 'GENERAL_INQUIRY'
  | 'REFERRAL'
  | 'INTERNALLY_IDENTIFIED'
  | 'CONVEYANCING_INSTRUCTIONS';
export const OpportunityMatterSourceValue = {
  blank: <OpportunityMatterSourceType>'BLANK',
  requestForQuote: <OpportunityMatterSourceType>'REQUEST_FOR_QUOTE',
  generalInquiry: <OpportunityMatterSourceType>'GENERAL_INQUIRY',
  referral: <OpportunityMatterSourceType>'REFERRAL',
  internallyIdentified: <OpportunityMatterSourceType>'INTERNALLY_IDENTIFIED',
  conveyancingInstructions: <OpportunityMatterSourceType>'CONVEYANCING_INSTRUCTIONS'
};

export type ClientInfoWillDocuments = {
  clientHasWill: boolean;
  clientHasPrimaryWill: boolean;
  clientHasSecondaryWill: boolean;
  clientHasPOAProperty: boolean;
  clientHasPOAPersonalCare: boolean;
}

export enum MatterTypeEnum {
  PURCHASE = 'PURCHASE',
  SALE = 'SALE',
  MORTGAGE = 'MORTGAGE',
  CUSTOM = 'CUSTOM',
  OPPORTUNITY = 'OPPORTUNITY',
  DISCHARGE = 'DISCHARGE',
  WILL = 'WILL'
}

export enum WillSubTypeEnum {
  WILL = 'WILL',
  PRIMARY_WILL = 'PRIMARY_WILL',
  SECONDARY_WILL = 'SECONDARY_WILL',
  POA_PROPERTY = 'POA_PROPERTY',
  POA_PERSONAL_CARE = 'POA_PERSONAL_CARE'
}

export enum WillDocumentToProduceType {
  WILL = 'WILL',
  POA = 'POA'
}

export enum WillDocumentToProduceSubType {
  WILL_FOR_SINGLE_PERSON = 'WILL_FOR_SINGLE_PERSON',
  MIRROR_WILL = 'MIRROR_WILL',
  POA_PROPERTY = 'POA_PROPERTY',
  POA_PERSONAL_CARE = 'POA_PERSONAL_CARE',
  POA_PROPERTY_RECIPROCAL = 'POA_PROPERTY_RECIPROCAL',
  POA_PERSONAL_CARE_RECIPROCAL = 'POA_PERSONAL_CARE_RECIPROCAL'
}

export type WillDocumentsToProduce = {
  willDocumentToProduceType: WillDocumentToProduceType;
  willDocumentToProduceSubType: WillDocumentToProduceSubType
  id?: number;
  willMatterId?: number;
}

export type WillPartyRoleType = 'EXECUTOR' | 'ALTERNATE_EXECUTOR' | 'CHILD' | 'GUARDIAN' | 'ALTERNATE_GUARDIAN';

export type WillRemainsType = {
  willSubType: WillSubTypeEnum;
  matterParticipantId?: number;
  id?: number;
}

export type WillPartyRole = {
  id?: number;
  willPartyRoleType: WillPartyRoleType;
  matterParticipantId?: number;
}

export type WillResidueRole = {
  id?: number;
  residueRoleType: WillResidueRoleType;
  willParticipantId?: number;
}
