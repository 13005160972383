<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">
        <div class="gradient-box">
            <div class="unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo"></span></a>
            </div>
            <div class="vertical-login-bar">
            </div>
            <div class="login-dtls-header">Practice made Perfect</div>
            <div class="login-dtls">
                Whether practicing in the area of real estate, corporate, wills, or estate law, Unity® Practice Management will make it
                easier for you to seamlessly connect with industry service providers, key stakeholders, other legal
                professionals and customers.
            </div>
        </div>
        <div class="login-card forgot-pwd-card" [ngClass]="{'forgot-pwd-card-error' : !success}"></div>
        <div class="login-card-input">
            <span class="forgot-pwd-header">Please complete the following to request password reset</span>
            <div class="padding-bottom-20" [ngClass]="{'padding-bottom-25' :!publicAccountId && fromDataMissing}">

                <label class="login-label" for="publicAccountId">Account Number</label>
                <div>
                    <input [(ngModel)]="publicAccountId" type="text" id="publicAccountId" name="publicAccountId"
                           class="form-control" required>
                </div>
                <div *ngIf="!publicAccountId && fromDataMissing" class="col-lg-12 login-error">
                    <span class="error-input-img"></span>Account Number is required
                </div>

            </div>
            <div class="padding-bottom-20" [ngClass]="{'padding-bottom-25' :!loginId && fromDataMissing}">

                <label class="login-label" for="loginId">User ID</label>
                <div>
                    <input [(ngModel)]="loginId" type="text" id="loginId" name="loginId" class="form-control" required>
                </div>
                <div *ngIf="!loginId && fromDataMissing" class="col-lg-12 login-error">
                    <span class="error-input-img"></span>User ID is required
                </div>
            </div>
            <div class="padding-bottom-20" [ngClass]="{'padding-bottom-25' :!email && fromDataMissing}">

                <label class="login-label" for="email">Email Address</label>
                <div>
                    <input [(ngModel)]="email" type="text" id="email" name="email" class="form-control" required>
                </div>
                <div *ngIf="!email && fromDataMissing" class="col-lg-12 login-error">
                    <span class="error-input-img"></span>Email Address is required
                </div>
            </div>
        </div>
        <div>
            <button type="submit" (click)="reset()" [disabled]="submitted && success" class="primary-btn login-btn forgot-pwd-btn">NEXT</button>
        </div>
        <div class="error-msg forgot-pwd-error" *ngIf="!success">
            <span class="error-img"></span> <span>{{errorMessage}}</span>
        </div>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>

