<div>
    <p-autoComplete
        #autoCompleteSelect
        [id]="autoCompleteIdOrName"
        [name]="autoCompleteIdOrName"
        [(ngModel)]="selectedAutoCompleteItem"
        (focusout)="onLeavingOmniBarWithoutFreeInput()"
        (completeMethod)="searchOnDropDownList($event)"
        [class.dropdown-arrow-refferedBy]="true"
        [class.border-bottom-color-refferedBy]="true"
        [suggestions]="autoCompleteSelectSuggestions"
        scrollHeight="550px"
        (onSelect)="dataSelected($event)"
        [minLength]="0"
        [delay]="1000"
        [dropdown]="true"
        (onDropdownClick)="displayOptionList()"
        autocomplete="off"
        (keydown.space)="displayOptionList($event)">
        <ng-template let-templateFolder pTemplate="item">
            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                [disableItem]="templateFolder">
                <span class="col-lg-8 text-overflow">{{ autoDisplayItem(templateFolder)}}</span>
            </li>
        </ng-template>

    </p-autoComplete>
</div>
