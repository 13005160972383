import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, Inject, ViewChild} from '@angular/core';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {DialogConfigParams, DialogService} from '../../../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {EnforcementOffice} from '../../../../shared-main/teranet/enforcement-office';
import {TeranetWritSearchRequest} from '../../../../shared-main/teranet/teranet-writ-search-request';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorDirectiveMapping} from '../../../../shared/error-handling/error-directive-mapping';
import {ApplicationError} from '../../../../core/application-error';
import {TeranetWritSearchResponse} from '../../../../shared-main/teranet/teranet-writ-search-response';
import {FieldError} from '../../../../core';
import {Writ} from '../../../../shared-main/teranet/property-writ';
import {currentMatter} from '../../../shared/current-matter';
import moment from 'moment';
import * as _ from 'lodash';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class WritNameSearchModalContext {
  writSearchRequest: TeranetWritSearchRequest;
  enforcementOffices: EnforcementOffice[];
  searchedWrits: Writ[];
}

@Component({
  selector: 'dp-writ-name-search',
  templateUrl: 'writ-name-search.modal.component.html',
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class WritNameSearchModal extends ModalComponent<WritNameSearchModalContext> {
  partyTypes: SelectItem[];
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  originalWritSearchRequest: TeranetWritSearchRequest;

  constructor(
    public dialog: MatDialogRef<WritNameSearchModal>,
    public teranetService: TeranetService,
    public errorService: ErrorService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: WritNameSearchModalContext
  ) {
    super(dialog, context);
    this.originalWritSearchRequest = _.cloneDeep(this.context.writSearchRequest);
  }

  ngOnInit(): void {
    this.partyTypes = [
      {label: 'Person', value: 'Person'},
      {label: 'Company', value: 'Company'}
    ];
  }

  get enforcementOffices(): EnforcementOffice[] {
    return this.context.enforcementOffices;
  }

  get writSearchRequest(): TeranetWritSearchRequest {
    return this.context.writSearchRequest;
  }

  onCancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  updateEOInWritSearchRequest(enforcementOfficeNumber: string): void {
    let eoOffice: EnforcementOffice = this.enforcementOffices.find(
      (eo) => eo.enforcementOfficeNumber == enforcementOfficeNumber
    );
    if (eoOffice) {
      this.writSearchRequest.lro = eoOffice.lroNumber;
      this.writSearchRequest.enforcementOfficeName = eoOffice.enforcementOfficeName;
      this.writSearchRequest.enforcementOfficeNumber = eoOffice.enforcementOfficeNumber;
    }
  }

  onPartyTypeChange(index: number): void {
    if (this.writSearchRequest.partiesToSearch[index].isCompany) {
      this.writSearchRequest.partiesToSearch[index].lastName = '';
      this.writSearchRequest.partiesToSearch[index].givenName = (
        this.originalWritSearchRequest.partiesToSearch[index].lastName +
        ' ' +
        this.originalWritSearchRequest.partiesToSearch[index].givenName
      ).trim();
    } else {
      this.writSearchRequest.partiesToSearch[index].lastName =
        this.originalWritSearchRequest.partiesToSearch[index].lastName;
      this.writSearchRequest.partiesToSearch[index].givenName =
        this.originalWritSearchRequest.partiesToSearch[index].givenName;
    }
  }

  proceed(): void {
    if (this.writSearchRequest.writSearchType === 'ADD_NAME') {
      this.duplicateNameCheck();
    } else {
      this.executeWritNameSearch();
    }
  }

  duplicateNameCheck(): void {
    let duplicateWrit: Writ = this.context.searchedWrits.find(
      (writ) =>
        writ.partyNameHash === this.writSearchRequest.partiesToSearch[0].partyNameHash &&
        writ.enforcementOffice == this.writSearchRequest.enforcementOfficeName
    );
    if (duplicateWrit != null) {
      let writTxt =
        'A Writ Search for ' +
        duplicateWrit.partyName +
        ' (enforcement office:  ' +
        duplicateWrit.enforcementOffice +
        ') was previously performed on ' +
        moment(duplicateWrit.searchTimeStamp).format('MMM DD, YYYY h:mm a') +
        '<br>' +
        '<br>' +
        'Do you wish to view the existing search or perform a new search?';
      const contentModalConfig: DialogConfigParams = {
        title: 'Teranet Connect &trade;: Writ Search Already Exists',
        message: writTxt,
        hideCancelBtn: false,
        customConfirmBtnText: 'View Existing Search',
        customBtnText: 'New Search',
        customReturnText: 'NEW'
      };
      this.dialogService.confirmCustomDialog(contentModalConfig).subscribe((res) => {
        if (res) {
          if (res.toString() === 'NEW') {
            this.writSearchRequest.writId = duplicateWrit.id;
            this.writSearchRequest.writSearchType = 'REDO_SEARCH';
            this.executeWritNameSearch();
          } else {
            this.dialog.close();
            this.teranetService.downloadTeranetDocumentAndDisplay(
              currentMatter.value.id,
              duplicateWrit.writCertificate.id
            );
          }
        }
      });
    } else {
      this.executeWritNameSearch();
    }
  }

  executeWritNameSearch(): void {
    this.writSearchRequest.enforcementOfficeName = this.enforcementOffices.find(
      (value) => value.enforcementOfficeNumber === this.writSearchRequest.enforcementOfficeNumber
    ).enforcementOfficeName;
    if (this.isSearchRequestValid()) {
      this.teranetService.writNameSearch(this.writSearchRequest).subscribe(
        (teranetWritSearchResponse: TeranetWritSearchResponse) => {
          this.dialog.close(teranetWritSearchResponse);
        },
        (error: ApplicationError) => {
          this.handleTeranetSearchApiError(error);
        }
      );
    }
  }

  handleTeranetSearchApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  isSearchRequestValid(): boolean {
    this.modalErrorComponent.removeAllDpFieldError();
    if (!this.writSearchRequest.enforcementOfficeNumber) {
      this.modalErrorComponent.createDPFieldError('writ.name.search.eo.MISSING');
    }

    let errorDirectiveMapping = ErrorDirectiveMapping['writ.name.search.name.MISSING'];
    this.writSearchRequest.partiesToSearch.forEach((partyToSearch, index) => {
      if (!partyToSearch.givenName || partyToSearch.givenName.trim().length < 2) {
        this.modalErrorComponent.createCustomDPFieldError(
          'writ.name.search.name.MISSING',
          errorDirectiveMapping.ERROR_MESSAGE,
          null,
          'ERROR'
        );
      }
      if (partyToSearch.isPerson && partyToSearch.givenName && partyToSearch.givenName.trim().split(' ').length > 3) {
        errorDirectiveMapping = ErrorDirectiveMapping['writ.name.search.givenName.MoreThanThreeWords'];
        this.modalErrorComponent.createCustomDPFieldError(
          'writ.name.search.givenName.MoreThanThreeWords',
          errorDirectiveMapping.ERROR_MESSAGE,
          null,
          'ERROR'
        );
      }
    });

    return !this.errorService.hasErrors();
  }

  ngAfterViewInit() {}
}
