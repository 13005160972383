<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Deferred Portion of Purchase Price</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12" >
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Title of Adjustment</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           id="titleOfAdjustment"
                           name="titleOfAdjustment"
                           maxlength="38"
                           class="form-control"
                           [(ngModel)]="soAdjPurchasePriceDeferredPortion.titleOfAdjustment"
                    />
                </div>
            </div>

        </div>
        <div class="form-group">
            <div class="col-lg-12" >
                <div class="col-lg-3">
                    <label class="control-label text-left">Amount of deferred purchase monies</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           id="amount"
                           name="amount"
                           dp-currency
                           [dp-read-only]="true"
                           class="form-control"
                           [ngModel]="soAdjPurchasePriceDeferredPortion.amount"/>
                </div>
            </div>
        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <label class="control-label">
                        {{soAdjPurchasePriceDeferredPortion.titleOfAdjustment}}
                    </label>
                </div>
            </div>

            <div class="col-lg-12" >
                <label class="control-label col-lg-3 text-left">
                    Credit Purchaser
                </label>
                <label class="control-label col-lg-3 text-right">

                </label>
                <label class="control-label col-lg-4 text-right">
                    {{getSoAdjPurchasePriceDeferredPortionTotal()}}
                </label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button type="button"
                    (click)="delete()"
                    *ngIf="allowDelete"
                    class="secondary-button">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
