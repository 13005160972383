export class DocumentStopCode {
  code: string;
  text: string;
  value: any;
  valid: boolean;
  index: number;
  clientName: string;
  multiClientStopIndex: string;

  constructor(documentStopCode?: DocumentStopCode) {
    if (documentStopCode) {
      for (let prop in documentStopCode) {
        if (documentStopCode.hasOwnProperty(prop)) {
          this[ prop ] = documentStopCode[ prop ];
        }
      }
    }
  }
}
