import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Matter} from './shared/matter';
import {MatterType, MatterUtil} from './shared/index';
import {Subject} from 'rxjs/Subject';
import {HttpClient, TabsService} from '../core';
import {MatterOpeningComponent} from './matter-opening/matter-opening.component';
import {LockScreenService} from '../core/lock-screen.service';
import {MassUpdateType, MassUpdateTypes} from '../projects/shared/project';
import {MatterSupplementalTaskCategory} from './shared/matter-supplemental-task-category';
import {DpBooleanValueTypes} from './shared/dp-boolean';
import {constValues} from './shared/const-values';
import {ActingForValues, CondoManagedTypeConstValue, ProjectAdjustmentImport} from '../shared-main/constants';
import {User} from './shared/user';
import {UserProvince} from './shared/user-province';
import Utils from '../shared-main/utils';
import {ProvinceCode} from '../admin/accounts/shared/base-province';
import {JournalNote, NotesList} from '../admin/account-notes/account-notes';
import {PurchaserComponent} from './purchaser/purchaser.component';
import {VendorsSolicitorComponent} from './vendors-solicitor/vendors-solicitor.component';
import {MatterParticipantWrapper} from './shared/matter-participant-wrapper';
import {FamilyLawActComponent} from './purchaser/family-law-act/family-law-act.component';
import {MatterParticipant} from './shared/matter-participant';
import {UserDefinedField} from '../shared-main/user-defined-field/user-defined-field';
import {AppConfig} from '../shared-main/app-configuration';
import {MassUpdateData, MassUpdateTab} from '../shared/tabbing/mass-update-tab';
import {CondoCorporationComponent} from './condo-corporation/condo-corporation.component';
import {FireInsuranceComponent} from './fire-insurance/fire-insurance.component';
import {AttentionInfoComponent} from './matter-opening/attention/attention-info.component';
import {Contact} from './shared/contact';
import {MatterParticipantRoleTypes} from './shared/matter-participant-role-types';
import {BrokerCommissionComponent} from './broker-commission/broker-commission.component';
import {MatterParticipantService} from './matter-participant-service';
import {MatterContactInfo} from './shared/matter-contact-info';
import {Address} from './shared/address';
import {DirectDepositInstruction} from './shared/direct-deposit-instruction';
import {BankAccount} from './shared/BankAccount';
import {ProgressionStatus, StatementAdjustment} from './statement-adjustment/statement-adjustment';
import {SoaMatter} from './statement-account/soa-matter';
import {TrustLedgerMatter} from './trust-ledger/trust-ledger-matter';
import * as _ from 'lodash';
import {PropertyTeranetComponent} from './property-teranet/property-teranet.component';
import {MatterProperty} from './shared/matter-property';
import {ContactService, RollNumber} from './shared';
import {Compliance} from './compliance/compliance';
import {JurisdictionDepartment} from '../admin/jurisdiction-departments/jurisdiction-departments';
import {JurisdictionDepartmentsService} from '../admin/jurisdiction-departments';

import {VendorCommission} from './broker-commission/vendor-commission';
import {PurchaserCommission} from './broker-commission/purchaser-commission';
import {BrokerCommission} from './broker-commission/broker-commission';
import {StatementAdjustmentComponent} from './statement-adjustment/statement-adjustment.component';
import {MassUpdateAdjustmentDateData} from '../projects/project-list/mass-update-adjustmentdate-modal/mass-update_adjustmentdate-data';
import {ErrorService} from '../shared/error-handling/error-service';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {StatementAdjustmentUtil} from './statement-adjustment/statement-adjustment-util';
import {MortgageeComponent} from './mortgages/mortgage/mortgagee/mortgagee.component';
import {Mortgage} from './shared/mortgage';
import {MortgageTerm} from './shared/mortgage-term';
import {UnitLevelPlanUtil} from './property-teranet/unit-level-plan/unit-level-plan-util';
import {VtbMortgageeComponent} from './mortgages/mortgage/mortgagee/vtb-mortgagee.component';
import {ExistingMortgageComponent} from './mortgages/mortgage/existing-mortgage/existing-mortgage.component';
import {MortgageDetailComponent} from './mortgages/mortgage/mortagage-detail/mortgage-detail.component';
import {MortgageTermComponent} from './mortgages/mortgage/term/mortgage-term.component';
import {MortgageReportComponent} from './mortgages/mortgage/report/mortgage-report.component';
import {MatterUndertaking} from './shared/matter-undertaking';
import {MortgageGuarantorComponent} from './mortgages/mortgage/mortgage-guarantor/mortgage-guarantor.component';
import {FormTemplate} from './forms/eregistration/form-template';
import {DocumentProductionService} from './document-production/document-production.service';
import {DocumentProductionData} from './document-production/document-production-data';
import {Document} from './document-production/document';
import {DocumentUtilityService} from './document-production/document-utility.service';
import {MatterTaxRate} from './shared/matter-tax-rate';
import {ConsiderationTaxes} from './consideration-ltt/consideration-taxes';
import {ConsiderationLttComponent} from './consideration-ltt/consideration-ltt.component';
import {DirectionReFund} from './direction-re-funds/direction-re-funds';
import {DirectionReFundsComponent} from './direction-re-funds/direction-re-funds.component';
import {HOLDBACK_TYPE} from './shared/advance-holdback/matter-holdback';
import {Deposit} from './shared/deposit';
import {DepositModalContextValue} from './property-teranet/deposit/deposit.modal.component';
import {SalePriceAdjustmentFactory} from '../../app/matters/statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {SalePriceAdjustment} from '../../app/matters/statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {MatterCleanUpUtil} from './shared/matter-utils/matter-clean-up-util';
import {BrokerContactTypes, BrokerMatterLevelInfo} from './shared/broker-matter-level-info';
import {MatterBrokerMatterLevelInfoUtil} from './shared/matter-utils/matter-broker-matter-level-info-util';
import {SoaTrustLedgerConfigKeys} from './shared/soa-trustledger-collection';
import {MortgageInstrument} from './shared/mortgage-instrument';

@Injectable()
export class MassUpdateService {

  constructor(private http: HttpClient,
              private lockScreenService: LockScreenService,
              public appConfig: AppConfig,
              public matterParticipantService: MatterParticipantService,
              public matterOpeningComponent: MatterOpeningComponent,
              public propertyTeranetComponent: PropertyTeranetComponent,
              public familyLawActComponent: FamilyLawActComponent,
              public condoCorporationComponent: CondoCorporationComponent,
              public attentionInfoComponent: AttentionInfoComponent,
              public fireInsuranceComponent: FireInsuranceComponent, public considerationLttComponent: ConsiderationLttComponent,
              public brokerCommissionComponent: BrokerCommissionComponent,
              public purchaserComponent: PurchaserComponent,
              public jurisdictionDepartmentsService: JurisdictionDepartmentsService,
              public vendorsSolicitorComponent: VendorsSolicitorComponent,
              public errorService: ErrorService,
              public mortgageSoAdjService: MortgageSoAdjService,
              public statementAdjustmentComponent: StatementAdjustmentComponent,
              public mortgageDetailComponent: MortgageDetailComponent,
              public mortgageTermComponent: MortgageTermComponent,
              public mortgageReportComponent: MortgageReportComponent,
              public mortgageeComponent: MortgageeComponent,
              public vtbMortgageeComponent: VtbMortgageeComponent,
              public mortgageGuarantorComponent: MortgageGuarantorComponent,
              public contactService: ContactService,
              public existingMortgageComponent: ExistingMortgageComponent,
              public documentProductionService: DocumentProductionService,
              public tabsService: TabsService,
              public documentUtility: DocumentUtilityService,
              public directionReFundsComponent: DirectionReFundsComponent) {
  }

  async callAsynchronously(callback: Function): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    await callback();
    let serviceInstance = this;
    let scheduler = setInterval(() => {

      if (serviceInstance.http.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 10);
    return returnSubject.toPromise();
  }

  async initMatterOpeningComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.matterOpeningComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.matterOpeningComponent.setLocalInstanceMatter(targetMatter);
        this.matterOpeningComponent.ngOnInit();
        //other init methods can be called here to prepare the data
        // this.initPurchaserComponent(targetMatter);
        // this.initVendorSolicitorComponent(targetMatter);
      }
    });
  }

  async initMortgageDetailComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageDetailComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.mortgageDetailComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.mortgageDetailComponent.initializeState();
      }
    });
  }

  async initMortgageTermComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageTermComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.mortgageTermComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.mortgageTermComponent.initMortgageTerm();
      }
    });
  }

  async initMortgageReportComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageReportComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.mortgageReportComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.mortgageReportComponent.initMortgageReport();
      }
    });
  }

  async initVtbMortgageeComponent(targetMatter: Matter, targetMortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.vtbMortgageeComponent) {
        this.vtbMortgageeComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgage);
        this.vtbMortgageeComponent.ngOnInit();
      }
    });
  }

  async initMortgageGuarantorComponentComponent(targetMatter: Matter, targetMortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageGuarantorComponent) {
        this.mortgageGuarantorComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgage);
        this.mortgageGuarantorComponent.ngOnInit();
      }
    });
  }

  async initMortgageeComponent(targetMatter: Matter, targetMortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      console.log('start initMortgageeComponent');
      if (this.mortgageeComponent) {
        this.mortgageeComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgage);
        this.mortgageeComponent.ngOnInit();
      }
      console.log('end initMortgageeComponent');
    });
  }

  async initAttentionInfoComponent(mortgageId: number, parentMatterParticipant: MatterParticipant, targetMatter: Matter, attentionList: Contact[]): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.attentionInfoComponent) {
        this.attentionInfoComponent.setLocalInstancesForMassUpdate(mortgageId, parentMatterParticipant, targetMatter, attentionList);
        this.attentionInfoComponent.buildAttentionWrapper();
      }

    });
  }

  async initExistingMortgageComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.existingMortgageComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.existingMortgageComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.existingMortgageComponent.initializeState();
      }
    });
  }

  async initPurchaserComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.purchaserComponent) {
        this.purchaserComponent.setLocalInstanceMatter(matter);
        this.purchaserComponent.ngOnInit();
        this.purchaserComponent.initPurchaserComponent();
      }
    });
  }

  async initVendorSolicitorComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.vendorsSolicitorComponent) {
        this.vendorsSolicitorComponent.setLocalInstanceMatter(matter);
        this.vendorsSolicitorComponent.ngOnInit();
        this.vendorsSolicitorComponent.initVendorSolicitorStructure();
      }
    });
  }

  async initSubjectPropertyComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.propertyTeranetComponent) {
        this.propertyTeranetComponent.setLocalInstanceMatter(targetMatter);
        this.propertyTeranetComponent.ngOnInit();
        this.propertyTeranetComponent.openProperty();
        this.propertyTeranetComponent.initiSoAdjOtherProrated();
      }
    });
  }

  async initCondoCorporationComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.condoCorporationComponent) {
        this.condoCorporationComponent.setLocalInstanceMatter(targetMatter);
        this.condoCorporationComponent.ngOnInit();
        this.condoCorporationComponent.openCondoCorporation();
        this.setAttentionInfoComponentInCondoCorp(targetMatter);
      }
    });
  }

  async initFireInsurerComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.fireInsuranceComponent) {
        this.fireInsuranceComponent.setLocalInstanceMatter(targetMatter);
        this.fireInsuranceComponent.fireInsuranceOpening();
      }
    });
  }

  async initBrokerCommissionComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.brokerCommissionComponent) {
        this.brokerCommissionComponent.setLocalInstanceMatter(targetMatter);
        this.brokerCommissionComponent.openBrokerCommission();
        if (targetMatter.isMatterProvinceAB) {
          this.brokerCommissionComponent.initForSaleMatterProvinceABAndBC();
        }
      }
    });
  }

  async initStatementAdjustmentComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.statementAdjustmentComponent) {
        this.statementAdjustmentComponent.soaUtils.setIgnoreMassUpdateContext(true); // do not apply all initializations for a mass update context
        this.statementAdjustmentComponent.setLocalInstanceMatter(matter);
        await this.statementAdjustmentComponent.initSoAdjComponent();
        await this.statementAdjustmentComponent.setUpStatementOfAdjustment();
      }
    });
  }

  //Adding a @ViewChild component in CondoCorp component
  setAttentionInfoComponentInCondoCorp(targetMatter: Matter): void {
    this.condoCorporationComponent.attentionInfoComponent = this.attentionInfoComponent;
    //Below code is setting @Input variables into child component
    this.attentionInfoComponent.matter = targetMatter;
    this.updateParentParticipantInAttentionInfoComponent(targetMatter);
    //After setting input variables initializing child component
    this.condoCorporationComponent.attentionInfoComponent.buildAttentionWrapper();
  }

  //This method updates the input variable for attention component. it should be called everytime input variable is changed.
  updateParentParticipantInAttentionInfoComponent(matter: Matter): void {
    if (matter.condoCorporation) {
      if (matter.condoCorporation.contact.isSelfManaged()) {
        this.attentionInfoComponent.parentMatterParticipant = matter.condoCorporation;
      } else if (matter.condoCorporation.contact.isManagedByManagementCompany() && matter.managementCompany) {
        this.attentionInfoComponent.parentMatterParticipant = matter.managementCompany;
      }
    }
  }

  getInitDocumentProfileId(provinceCode: ProvinceCode): number {
    let sessionUser: User = Utils.getUserFromSession();
    if (sessionUser && Array.isArray(sessionUser.userProvinces)) {
      let userProvinceForMatter: UserProvince = sessionUser.userProvinces.find(userProvince => userProvince.provinceCode == provinceCode);
      return userProvinceForMatter && userProvinceForMatter.documentProfileId;
    }
    return null;
  }

  isNotEmpty(value: string) {
    return value && value.toString().trim().length > 0;
  }

  get massUpdateTab(): MassUpdateTab {
    return this.tabsService.activeTab as MassUpdateTab;
  }

  isFieldMarkedForRemoval(fieldName: string): boolean {
    return this.massUpdateTab && this.massUpdateTab.massUpdateData && this.massUpdateTab.massUpdateData.isFieldMarkedForRemoval(fieldName);
  }

  get massUpdateData(): MassUpdateData {
    return this.massUpdateTab && this.massUpdateTab.massUpdateData;
  }

  /**
   * template matter is associated with Project (one to one)
   *
   *  target matter is the matter created based on Project (many to one)
   *
   */
  async massUpdateMatterOpeningFields(templateMatter: Matter, targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      console.log('>> Mass Update of MatterOpeningFields for matter: ', targetMatter.matterRecordNumber);
      // Matter Opening Fields
      targetMatter.statementOfAdjustmentHeading = templateMatter.statementOfAdjustmentHeading;
      if (targetMatter.statementOfAdjustmentHeading) {
        targetMatter.statementOfAdjustmentHeading.id = undefined;
      }
      targetMatter.matterStatementOfAdjustmentFooters = templateMatter.matterStatementOfAdjustmentFooters;
      if (targetMatter.matterStatementOfAdjustmentFooters) {
        targetMatter.matterStatementOfAdjustmentFooters.forEach(item => {
          item.id = undefined;
        });
      }

      //ON, AB - UI Field Name: File No.
      if (this.isNotEmpty(templateMatter.fileNumber)) {
        targetMatter.fileNumber = templateMatter.fileNumber.trim();
      }

      //ON, AB - UI Field Name: Document Profile
      let matterInitDocumentProfileId: number = this.getInitDocumentProfileId(targetMatter.provinceCode);
      console.log('>> Mass Update Matter %s with updated Document Profile id %s, init DocProfileId is %s', targetMatter.matterRecordNumber, templateMatter.documentProfileId + '', matterInitDocumentProfileId + '');
      if (templateMatter.documentProfileId != null && templateMatter.documentProfileId != matterInitDocumentProfileId) {
        console.log('>> user choose a different document profile, push the change to child matters ...');
        this.matterOpeningComponent.onSelectedDocumentProfileChange(templateMatter.documentProfileId);
      }

      //ON, AB - UI Field Name: Builder No.
      if (this.isNotEmpty(templateMatter.builderNumber)) {
        targetMatter.builderNumber = templateMatter.builderNumber.trim();
      }

      //ON, AB - UI Field Name: Accounting No.
      if (this.isNotEmpty(templateMatter.accountingNumber)) {
        targetMatter.accountingNumber = templateMatter.accountingNumber.trim();
      }

      //ON, AB - UI Field Name: File Opening Date
      if (this.isNotEmpty(templateMatter.fileOpenDate)) {
        targetMatter.fileOpenDate = templateMatter.fileOpenDate.trim();
      }

      //ON, AB - UI Field Name: Law Clerk Name (ON), Legal Assistant Name/Legal Assistant (AB)
      this.copyLawclerkParticipant(templateMatter, targetMatter);

      //ON, AB - UI Field Name: Solicitor Name
      this.copySolicitorParticipant(templateMatter, targetMatter);

      if (templateMatter.isMatterProvinceAB) {

        //AB - UI Field Name: Witness Name
        this.copyWitnessParticipant(templateMatter, targetMatter);

        //AB - UI Field Name: Commissioner Name
        this.copyCommissionerParticipant(templateMatter, targetMatter);

        //AB - UI Field Name: We are acting for, the field value default to VENDOR_ONLY, so will not push this default value to child matter
        //need to skip the linked target matter
        if (!targetMatter.matterLink && this.isNotEmpty(templateMatter.actingFor) && templateMatter.actingFor != ActingForValues.VENDOR_ONLY) {
          targetMatter.actingFor = templateMatter.actingFor;
          this.matterOpeningComponent.onActingForChange();
        }

      }

      //ON, AB - UI Field Name: Date of Agrt of P&S (ON), P&S contact terminated? (AB)
      //this field has default value N/y, will only update the target matter if field value is not the default value even user explicitly change back and forth and finally settle on this value :(
      if (this.isNotEmpty(templateMatter.contactTerminated) && templateMatter.contactTerminated != DpBooleanValueTypes.N_y) {
        targetMatter.contactTerminated = templateMatter.contactTerminated;
      }

      //ON, AB - UI Field Name: Date of Agrt of P&S (ON), Purch Contract Date (AB)
      if (this.isNotEmpty(templateMatter.purchaseSaleAgreementDate)) {
        targetMatter.purchaseSaleAgreementDate = templateMatter.purchaseSaleAgreementDate.trim();
      }
      //ON - UI Field Name: Occupancy Date
      if (this.matterOpeningComponent.showOccupancyDate() && (this.isNotEmpty(templateMatter.occupancyDate) || (!templateMatter.occupancyDate && this.isFieldMarkedForRemoval('occupancyDate')))) {
        // Not updating the values here in target matter (as it is already done in called method) which will also take care of downstream changes.
        // targetMatter.occupancyDate = templateMatter.occupancyDate;
        this.matterOpeningComponent.onDateChangeOccupancyDate({rawDate: templateMatter.occupancyDate});
      }
      //ON - UI Field Name: Requisition Date / Occupancy Date (PS-ON)
      if (this.matterOpeningComponent.isOccupancyDateVisible && (this.isNotEmpty(templateMatter.requisitionDate)) || (!templateMatter.requisitionDate && this.isFieldMarkedForRemoval('requisitionDate'))) {
        this.matterOpeningComponent.onDateChangeRequisitionDate({rawDate: templateMatter.requisitionDate});
      }
      //ON - UI Field Name: Is the Key Release Date the same as the Occupancy Date? , default value is Y_n
      if (templateMatter.isMatterProvinceON && this.isNotEmpty(templateMatter.isReleaseDateSameAsOccupancyDate) && templateMatter.isReleaseDateSameAsOccupancyDate != DpBooleanValueTypes.Y_n) {
        //also need to make sure the this dropdown is visiable in the target matter before push the change
        if (this.matterOpeningComponent.isReleaseDateSameAsOccupancyDateVisible) {
          targetMatter.isReleaseDateSameAsOccupancyDate = templateMatter.isReleaseDateSameAsOccupancyDate;
        }

        //ON - UI Field Name: Enter the Key Release Date, this field in template matter only shows if the field isReleaseDateSameAsOccupancyDate = No
        if (templateMatter.isReleaseDateSameAsOccupancyDate == DpBooleanValueTypes.NO && this.isNotEmpty(templateMatter.releaseDate)) {
          if (this.matterOpeningComponent.isReleaseDateVisible) { //check whether this field is visible in the target matter
            targetMatter.releaseDate = templateMatter.releaseDate;
          }
        }

      }

      //ON - UI Field Name: Closing Date
      if (this.isNotEmpty(templateMatter.matterCloseDate) || (!templateMatter.matterCloseDate && this.isFieldMarkedForRemoval('matterCloseDate'))) {
        this.matterOpeningComponent.onDateChangeClosingDate({rawDate: templateMatter.matterCloseDate});
      }
      //ON - UI Field Name: Closed?
      if (targetMatter.hasPastClosingDate() && this.isNotEmpty(templateMatter.closed) && templateMatter.closed != DpBooleanValueTypes.N_y) {
        targetMatter.closed = templateMatter.closed;
        if (templateMatter.isClosed && this.isNotEmpty(templateMatter.closingFileNumber)) {
          //since the closed(Yes) has been pushed to targetMatter, we can push the FileNo. field if it has value
          targetMatter.closingFileNumber = templateMatter.closingFileNumber.trim();
        }
      }

      //ON - UI Field Name: Occupancy Completed?
      //only update the target if user select an not-default value and this field is visible in the target matter
      if (this.isNotEmpty(templateMatter.occupancyCompleted) && templateMatter.occupancyCompleted != DpBooleanValueTypes.N_y && this.matterOpeningComponent.isOccupancyCompletedVisible) {
        targetMatter.occupancyCompleted = templateMatter.occupancyCompleted;
      }

      //ON - UI Field Name: Registration Method,
      // in the coming story, this field will be readonly, and take the value from parent Project (all the child matters has the same value)
      // so no massUpdate for this field

      //ON - UI Field Name: Teraview Docket ID
      if (this.isNotEmpty(templateMatter.registrationMethodCode) &&
        templateMatter.registrationMethodCode == constValues.registrationMethodsType.electronic &&
        templateMatter.teranetDocket &&
        this.isNotEmpty(templateMatter.teranetDocket.teraviewDocketIdentifier)
      ) {
        //only copy data from templateMatter, if templateMatter(source)'s registrationMethod is Electronic AND teraviewDocketId field has value
        if (targetMatter.registrationMethodCode == constValues.registrationMethodsType.electronic && //targetMatter registrationMethod is Electionic, so show the teraviewDocketId field
          !this.matterOpeningComponent.isDocketIdentifierPresent && //targetMatter teraviewDocketId field is not readOnly
          targetMatter.teranetDocket) {
          targetMatter.teranetDocket.teraviewDocketIdentifier = templateMatter.teranetDocket.teraviewDocketIdentifier.trim();
        }

      }
      //ON, AB - UI Field Name: Vendor executing doc'ts at
      if (this.isNotEmpty(templateMatter.purchaserExecDocsAt)) {
        targetMatter.purchaserExecDocsAt = templateMatter.purchaserExecDocsAt;
        targetMatter.jurisdictionId = templateMatter.jurisdictionId;
      }
      //ON, AB - UI Field Name: On
      if (this.isNotEmpty(templateMatter.purchaserExecDocsDate)) {
        targetMatter.purchaserExecDocsDate = templateMatter.purchaserExecDocsDate;
      }

      if (templateMatter.isMatterProvinceAB) {

        //AB - UI Field Name: Protocol Closing? , default value is UNDETERMINED
        if (this.isNotEmpty(templateMatter.protocolClosing) && templateMatter.protocolClosing != 'UNDETERMINED') {
          targetMatter.protocolClosing = templateMatter.protocolClosing;
        }
        //AB - UI Field Name: Purchaser Financing, default value is NEW_MORTGAGE
        if (this.isNotEmpty(templateMatter.purchaserFinancing) && templateMatter.purchaserFinancing != 'NEW_MORTGAGE') {
          targetMatter.purchaserFinancing = templateMatter.purchaserFinancing;
        }
        //AB - UI Field Name: Intereset Rate
        if (this.isNotEmpty(templateMatter.interestRateSummary)) {
          targetMatter.interestRateSummary = templateMatter.interestRateSummary.trim();
        }

        //AB - UI Field Name: ON Closing Date, default value is CASH_TO_CLOSE
        //BA check with TC, in TC this field and the related modal page data are all skipped in the mass update
        //so for now, this field and related modal page data are also excluded from the massUpdate.
      }

      //ON, AB - UI Field Name: Appt. Sched.?
      if (this.isNotEmpty(templateMatter.appointmentScheduledFlag) && templateMatter.appointmentScheduledFlag != DpBooleanValueTypes.N_y) {
        targetMatter.appointmentScheduledFlag = templateMatter.appointmentScheduledFlag;
      }

      // hide the referrers on the Mass Update / Matter Opening page

      //ON, AB - UI Field Name: Fee Quote
      if (this.isNotEmpty(templateMatter.feeQuote)) {
        targetMatter.feeQuote = templateMatter.feeQuote;
      }
      //ON, AB - UI Field Name: Special Comments
      if (this.isNotEmpty(templateMatter.specialComments)) {
        targetMatter.specialComments = templateMatter.specialComments;
      }
      //ON, AB - UI Field Name: Is File inactive?
      if (this.isNotEmpty(templateMatter.matterStatus) && templateMatter.matterStatus != constValues.yesNoMatterFile.DEFAULT) {
        targetMatter.matterStatus = templateMatter.matterStatus;
      }
      //ON, AB - UI Field Name: Inactive Date
      if (!targetMatter.isMatterActive && this.isNotEmpty(templateMatter.fileInactiveDate)) {
        targetMatter.fileInactiveDate = templateMatter.fileInactiveDate;
      }
      //ON, AB - UI Field Name: File inactive notes
      if (!targetMatter.isMatterActive && this.isNotEmpty(templateMatter.fileInactiveNotes)) {
        targetMatter.fileInactiveNotes = templateMatter.fileInactiveNotes;
      }
    });
  }

  async massUpdateNotes(templateMatter: Matter, targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      console.log('>> Mass Update of Notes for matter: ', targetMatter.matterRecordNumber);

      if (templateMatter.notesList) {
        //if matter doesn't have existing notes
        if (!targetMatter.notesList) {
          let newNotesList: NotesList = new NotesList(templateMatter.notesList);
          newNotesList.id = undefined;
          if (newNotesList.notes) {
            for (let note of newNotesList.notes) {
              note.id = undefined;
            }
          }
          targetMatter.notesList = newNotesList;
        } else {
          //matter has existing notes we append to it, as we need to preserve the order
          if (templateMatter.notesList.notes) {
            for (let note of templateMatter.notesList.notes) {
              let jn: JournalNote = new JournalNote(note);
              jn.id = undefined;
              targetMatter.notesList.notes.push(jn);
            }
          }
          targetMatter.notesList.noteType = templateMatter.notesList.noteType;
        }
      }
    });
  }

  async massUpdateSubjectPropertyPageFields(templateMatter: Matter, targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      console.log('>> Appling mass update for changes from SubjectProperty topic to matter: %s', targetMatter && targetMatter.matterRecordNumber);
      await this.massUpdateSubjectPropertyFields(templateMatter, targetMatter);
      // }
      // if(templateMatter.isMatterProvinceAB){
      //     console.log(">> Appling mass update for changes from SubjectProperty topic to matter: %s", targetMatter && targetMatter.matterRecordNumber);
      //     await this.massUpdateSubjectPropertyFields(templateMatter, targetMatter);
      // }
    });

  }

  updatePropertyJurisdiction(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for jurisdiction field change
    if (templateProperty.jurisdiction) {
      this.propertyTeranetComponent.removeJurisdictionRelatedFields();//mimic the deletion of existing jurisidction
      this.propertyTeranetComponent.selectedJurisdiction = templateProperty.jurisdiction;
      targetProperty.jurisdiction = templateProperty.jurisdiction;
      this.propertyTeranetComponent.dataSelectedJurisdiction();
    }
  }

  updatePropertyAddress(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for address related fields, exclude province/country which is deferred to be handled in the future story
    if (templateProperty.address) {
      const templateAddress: Address = templateProperty.address;
      let targetAddress: Address = targetProperty.address ? new Address(targetProperty.address) : new Address();
      if (this.isNotEmpty(templateAddress.addressLine1)) {
        targetAddress.addressLine1 = templateAddress.addressLine1;
      }
      if (this.isNotEmpty(templateAddress.addressLine2)) {
        targetAddress.addressLine2 = templateAddress.addressLine2;
      }
      if (this.isNotEmpty(templateAddress.city)) {
        targetAddress.city = templateAddress.city;
      }
      if (this.isNotEmpty(templateAddress.postalCode)) {
        targetAddress.postalCode = templateAddress.postalCode;
      }
      if (this.isNotEmpty(templateAddress.provinceName)) {
        targetAddress.provinceName = templateAddress.provinceName;
        targetAddress.provinceCode = templateAddress.provinceCode;
      }
      if (this.isNotEmpty(templateAddress.country)) {
        targetAddress.country = templateAddress.country;
      }
      this.propertyTeranetComponent.updateAddressFormChild({value: targetAddress});
    }
  }

  updatePropertyInterestEstate(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Interest/Estate
    if (this.isNotEmpty(templateProperty.interestEstate) && templateProperty.interestEstate != 'FEE_SIMPLE') {
      targetProperty.interestEstate = templateProperty.interestEstate;
      if (templateProperty.interestEstate == 'OTHER') {
        if (this.isNotEmpty(templateProperty.specify)) {
          targetProperty.specify = templateProperty.specify;
        }
      }
    }
  }

  updatePropertyProjectName(templateProperty: MatterProperty, targetProperty: MatterProperty, templateMatter: Matter) {
    //for field: Name of Project, default value : same as projectName
    const templateProjectName: string = templateMatter.project && templateMatter.project.projectName;
    if (this.isNotEmpty(templateProperty.projectName) && templateProperty.projectName != templateProjectName) {
      console.log('>> massUpdate the property\'s projectName %s, which is different from project\'s projectName %s', templateProperty.projectName, templateProjectName);
      targetProperty.projectName = templateProperty.projectName;
    }
  }

  updatePropertySurveyDate(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Survey Date
    if (this.isNotEmpty(templateProperty.surveyDate)) {
      targetProperty.surveyDate = templateProperty.surveyDate;
    }
  }

  updateCommonExpenses(templateProperty: MatterProperty, targetProperty: MatterProperty, targetMatter: Matter) {
    //for field: Common Expenses
    if (templateProperty.commonExpenses > 0) {
      targetProperty.commonExpenses = templateProperty.commonExpenses;
      targetMatter.updatePOTLAdjustmentsWithCommonExpense();
    }
  }

  updatePropertySurveyor(templateMatter: Matter, targetMatter: Matter) {
    //for field: Surveyor and related Attention dropdowns
    if (templateMatter.surveyorMatterParticipant) {
      this.propertyTeranetComponent.selectedSurveyorContact = templateMatter.surveyorMatterParticipant.contact; //attention value is embedded in surveyor contact
      this.propertyTeranetComponent.dataSelectedSurveyor();
      targetMatter.surveyorMatterParticipant.contact.sourceContactId = templateMatter.surveyorMatterParticipant.contact.sourceContactId;
    }
  }

  updatePropertyNewHomeWarranty(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: New Home Warranty
    if (this.isNotEmpty(templateProperty.newHomeWarranty)) {
      targetProperty.newHomeWarranty = templateProperty.newHomeWarranty;
    }
  }

  updatePropertyBuilderNumber(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: New Home Warranty
    if (this.isNotEmpty(templateProperty.builderNumber)) {
      targetProperty.builderNumber = templateProperty.builderNumber;
    }
  }

  updatePropertyPropertyNumber(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Property No.
    if (this.isNotEmpty(templateProperty.propertyNumber)) {
      targetProperty.propertyNumber = templateProperty.propertyNumber;
    }
  }

  updatePropertyCity(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for fielc: City
    if (this.isNotEmpty(templateProperty.city)) {
      targetProperty.city = templateProperty.city;
    }
  }

  updatePropertyITitleSearchPerformed(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Title Search Performed, default value 'N/y'
    if (this.isNotEmpty(templateProperty.isTitleSearchPerformed) && templateProperty.isTitleSearchPerformed != 'N/y') {
      targetProperty.isTitleSearchPerformed = templateProperty.isTitleSearchPerformed;
    }
  }

  updatePropertyResidentAssociation(templateMatter: Matter, targetMatter: Matter) {
    //for field: Surveyor and related Attention dropdowns
    let templateResidentAssociation: MatterParticipant = templateMatter.residentAssociationMatterParticipant;
    let targetResidentAssociation: MatterParticipant = targetMatter.residentAssociationMatterParticipant;
    if (templateResidentAssociation) {
      if (targetResidentAssociation) {
        targetMatter.removeMatterParticipant(targetResidentAssociation);
      }
      let newTargetResidentAssociation = targetMatter.addMatterParticipant(templateResidentAssociation.contact, true, 'RESIDENCE_ASSOCIATION');
      this.updateTemplateParticipantWithTargetContactInfo(newTargetResidentAssociation, templateResidentAssociation);
    }
  }

  updatePropertyFiscalYearEndDate(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Fiscal Year End
    if (this.isNotEmpty(templateProperty.fiscalYearEndDate)) {
      targetProperty.fiscalYearEndDate = templateProperty.fiscalYearEndDate;
    }
  }

  updatePropertyAnnualFee(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Annual Fee.
    if (templateProperty.annualFee) {
      targetProperty.annualFee = templateProperty.annualFee;
    }
  }

  updatePropertyInstrumentNumber(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    //for field: Instrument No.
    if (this.isNotEmpty(templateProperty.instrumentNumber)) {
      targetProperty.instrumentNumber = templateProperty.instrumentNumber;
    }
  }

  async massUpdateSubjectPropertyFields(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initSubjectPropertyComponent(targetMatter);
    console.log('>>massUpdate:: done with the initialization of SubjectProperty component');
    const templateProperty: MatterProperty = templateMatter.matterPropertyWithCondo;
    let targetProperty: MatterProperty = targetMatter.matterPropertyWithCondo;
    if (templateProperty && targetProperty) {
      if (templateMatter.isMatterProvinceON) {
        this.massUpdateSubjectPropertyFieldsON(templateProperty, targetProperty, templateMatter, targetMatter);
      }
      if (templateMatter.isMatterProvinceAB) {
        this.massUpdateSubjectPropertyFieldsAB(templateProperty, targetProperty, templateMatter, targetMatter);
      }
    }
  }

  massUpdateSubjectPropertyFieldsON(templateProperty: MatterProperty, targetProperty: MatterProperty, templateMatter: Matter, targetMatter: Matter) {
    //for jurisdiction field change
    this.updatePropertyJurisdiction(templateProperty, targetProperty);
    //for address related fields, exclude province/country which is deferred to be handled in the future story
    this.updatePropertyAddress(templateProperty, targetProperty);
    //for field: Interest/Estate
    this.updatePropertyInterestEstate(templateProperty, targetProperty);

    //for field: Name of Project, default value : same as projectName
    this.updatePropertyProjectName(templateProperty, targetProperty, templateMatter);

    //for fields only visible if isCondo == false
    if (templateProperty.isCondominium != DpBooleanValueTypes.YES && templateProperty.isCondominium != DpBooleanValueTypes.Y_n) {
      //for field: part(lot)
      if (this.isNotEmpty(templateProperty.partLot)) {
        targetProperty.partLot = templateProperty.partLot;
      }
      //for field: plan
      if (this.isNotEmpty(templateProperty.plan)) {
        targetProperty.plan = templateProperty.plan;
      }
      //for field: Being Part
      if (this.isNotEmpty(templateProperty.beingPart)) {
        targetProperty.beingPart = templateProperty.beingPart;
      }
      //for field: On Plan
      if (this.isNotEmpty(templateProperty.onPlan)) {
        targetProperty.onPlan = templateProperty.onPlan;
      }
    }

    //for POTL and condo as yes
    if (templateProperty.isCondominium != DpBooleanValueTypes.NO && templateProperty.isParcelOfTiedLand == DpBooleanValueTypes.YES) {
      if (this.isNotEmpty(templateProperty.nameOfCondominiumPlan)) {
        targetProperty.nameOfCondominiumPlan = templateProperty.nameOfCondominiumPlan;
      }
      if (this.isNotEmpty(templateProperty.percentageInterest)) {
        targetProperty.percentageInterest = templateProperty.percentageInterest;
      }

    }
    //for field: Parcel
    if (this.isNotEmpty(templateProperty.parcel)) {
      targetProperty.parcel = templateProperty.parcel;
    }
    //for field: Section
    if (this.isNotEmpty(templateProperty.section)) {
      targetProperty.section = templateProperty.section;
    }
    //for field: Easement/R of W
    if (this.isNotEmpty(templateProperty.easementRightOfWay)) {
      targetProperty.easementRightOfWay = templateProperty.easementRightOfWay;
    }

    //for field: common expenses
    this.updateCommonExpenses(templateProperty, targetProperty, targetMatter);

    //for fielc: City
    this.updatePropertyCity(templateProperty, targetProperty);

    //for field: Municipality
    if (this.isNotEmpty(templateProperty.municipality)) {
      targetProperty.municipality = templateProperty.municipality;
    }
    //for field: Registry Office
    if (this.isNotEmpty(templateProperty.registryOfficeName)) {
      targetProperty.registryOfficeName = templateProperty.registryOfficeName;
      targetProperty.registryOffice = templateProperty.registryOffice;
    }
    //for field: Roll Number, by sequence: city - municipality - map - subdivision - parcel - portion
    if (templateProperty.rollNumber) {
      if (!targetProperty.rollNumber) {
        targetProperty.rollNumber = RollNumber.createDefaultRollNumber();
      }
      //city
      if (this.isNotEmpty(templateProperty.rollNumber.city)) {
        targetProperty.rollNumber.city = templateProperty.rollNumber.city;
      }
      //municipality
      if (this.isNotEmpty(templateProperty.rollNumber.municipality)) {
        targetProperty.rollNumber.municipality = templateProperty.rollNumber.municipality;
      }
      //map
      if (this.isNotEmpty(templateProperty.rollNumber.map)) {
        targetProperty.rollNumber.map = templateProperty.rollNumber.map;
      }
      //subdivision
      if (this.isNotEmpty(templateProperty.rollNumber.subdivision)) {
        targetProperty.rollNumber.subdivision = templateProperty.rollNumber.subdivision;
      }
      //parcel
      if (this.isNotEmpty(templateProperty.rollNumber.parcel)) {
        targetProperty.rollNumber.parcel = templateProperty.rollNumber.parcel;
      }
      //portion
      if (this.isNotEmpty(templateProperty.rollNumber.portion)) {
        targetProperty.rollNumber.portion = templateProperty.rollNumber.portion;
      }
      //jurisdiction
      if (this.isNotEmpty(templateProperty.rollNumber.jurisdiction)) {
        targetProperty.rollNumber.jurisdiction = templateProperty.rollNumber.jurisdiction;
      }
    }

    //for field: Title Search Performed, default value 'N/y'
    this.updatePropertyITitleSearchPerformed(templateProperty, targetProperty);

    //for field: Last Transfer Number
    if (this.isNotEmpty(templateProperty.lastTransferNumber)) {
      targetProperty.lastTransferNumber = templateProperty.lastTransferNumber;
    }

    //for fields only visible if isCondo == false, PIN and LegalDescription related fields
    this.updatePinAndLegalDescriptionRelatedFields(templateProperty, targetProperty);

    //for field: Survey Date
    this.updatePropertySurveyDate(templateProperty, targetProperty);

    //for field: Surveyor and related Attention dropdowns
    this.updatePropertySurveyor(templateMatter, targetMatter);

    //for field: New Home Warranty
    this.updatePropertyNewHomeWarranty(templateProperty, targetProperty);

    //for field: Builder No.
    this.updatePropertyBuilderNumber(templateProperty, targetProperty);

    //for field: Property No.
    this.updatePropertyPropertyNumber(templateProperty, targetProperty);
  }

  updatePinAndLegalDescriptionRelatedFields(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    if (templateProperty.isCondominium != DpBooleanValueTypes.YES && templateProperty.isCondominium != DpBooleanValueTypes.Y_n) {
      //allowed to mass update the partial pin
      if (this.isNotEmpty(templateProperty.pin)) {
        targetProperty.pin = templateProperty.pin;
        //if pin changed, then we push the legaldescription related changes (regardless checked/unchecked of overrideDescription)
        this.updateLegalDescriptionRelatedFields(templateProperty, targetProperty);
      } else if (templateProperty.overrideDescription) {
        //OR is overrideDescription is true, then we massUpdate the legaldescription related fields value
        this.updateLegalDescriptionRelatedFields(templateProperty, targetProperty);
      }
    }
  }

  updateLegalDescriptionRelatedFields(templateProperty: MatterProperty, targetProperty: MatterProperty) {
    targetProperty.overrideDescription = templateProperty.overrideDescription;
    this.propertyTeranetComponent.overrideDescChange();
    targetProperty.descriptionOverriddenType = templateProperty.descriptionOverriddenType;
    this.propertyTeranetComponent.updateTeraviewLegalDescs(targetProperty);
    targetProperty.fullLegalDescription = templateProperty.fullLegalDescription;
  }

  massUpdateSubjectPropertyFieldsAB(templateProperty: MatterProperty, targetProperty: MatterProperty, templateMatter: Matter, targetMatter: Matter) {
    //for jurisdiction field change
    this.updatePropertyJurisdiction(templateProperty, targetProperty);
    //for address related fields, exclude province/country which is deferred to be handled in the future story
    this.updatePropertyAddress(templateProperty, targetProperty);
    //for field: Interest/Estate
    this.updatePropertyInterestEstate(templateProperty, targetProperty);

    //for field: Name of Project, default value : same as projectName
    this.updatePropertyProjectName(templateProperty, targetProperty, templateMatter);

    //for field: Vendor's Title Number
    if (this.isNotEmpty(templateProperty.titleNumber)) {
      targetProperty.titleNumber = templateProperty.titleNumber;
    }

    //for field: common expenses
    this.updateCommonExpenses(templateProperty, targetProperty, targetMatter);

    //for field: City
    this.updatePropertyCity(templateProperty, targetProperty);

    //for field: Land Title Office Location
    if (this.isNotEmpty(templateProperty.landTitleOfficeLocation)) {
      targetProperty.landTitleOfficeLocation = templateProperty.landTitleOfficeLocation;
    }

    //for field: Registry Office
    if (this.isNotEmpty(templateProperty.registryOfficeName)) {
      targetProperty.registryOfficeName = templateProperty.registryOfficeName;
    }

    //for field: Last Encumbrance No.
    if (this.isNotEmpty(templateProperty.lastInstrumentNumber)) {
      targetProperty.lastInstrumentNumber = templateProperty.lastInstrumentNumber;
    }

    //for field: Is the vendor to supply RPR?
    if (this.isNotEmpty(templateProperty.isVendorToSupplyRPR) && templateProperty.isVendorToSupplyRPR != DpBooleanValueTypes.N_y) {
      targetProperty.isVendorToSupplyRPR = templateProperty.isVendorToSupplyRPR;
    }

    //for field: RPR Applicable
    if (templateProperty.rprApplicable) {
      targetProperty.rprApplicable = templateProperty.rprApplicable;
    }

    //for fields only visible if isCondo == false
    if (templateProperty.isCondominium != DpBooleanValueTypes.YES && templateProperty.isCondominium != DpBooleanValueTypes.Y_n) {
      //for field: Title Search Performed, default value 'N/y'
      this.updatePropertyITitleSearchPerformed(templateProperty, targetProperty);

      //for field: Survey Date
      this.updatePropertySurveyDate(templateProperty, targetProperty);

      if (templateProperty.propertyDescriptionType == 'PLAN_BLOCK_LOT') {
        targetProperty.propertyDescriptionType = templateProperty.propertyDescriptionType;
        targetProperty.shortLegalDescription = '';
        targetProperty.fullLegalDescription = '';

        //for field: Plan Number
        if (this.isNotEmpty(templateProperty.plan)) {
          targetProperty.plan = templateProperty.plan;
        }
        //for field: Block Number
        if (this.isNotEmpty(templateProperty.block)) {
          targetProperty.block = templateProperty.block;
        }
        //for field: Lot Number
        if (this.isNotEmpty(templateProperty.lot)) {
          targetProperty.lot = templateProperty.lot;
        }
        //for field: Exception Wording?
        if (this.isNotEmpty(templateProperty.exceptionType)) {
          targetProperty.exceptionType = templateProperty.exceptionType;
          //for field: exceptionTypeDescription
          if (this.isNotEmpty(templateProperty.exceptionTypeDescription)) {
            targetProperty.exceptionTypeDescription = templateProperty.exceptionTypeDescription;
          }
        }
      }

      if (templateProperty.propertyDescriptionType == 'METES_AND_BOUNDS') {
        targetProperty.propertyDescriptionType = templateProperty.propertyDescriptionType;
        targetProperty.plan = '';
        targetProperty.lot = '';
        targetProperty.block = '';
        targetProperty.exceptionType = '';
        targetProperty.exceptionTypeDescription = '';

        //for field: Short legal description
        if (this.isNotEmpty(templateProperty.shortLegalDescription)) {
          targetProperty.shortLegalDescription = templateProperty.shortLegalDescription;
        }
        //for field: Full legal description
        if (this.isNotEmpty(templateProperty.fullLegalDescription)) {
          targetProperty.fullLegalDescription = templateProperty.fullLegalDescription;
        }
      }
    }

    if (templateProperty.isCondominium == DpBooleanValueTypes.YES) {
      //for field: Title Search Performed, default value 'N/y'
      this.updatePropertyITitleSearchPerformed(templateProperty, targetProperty);
      if (templateProperty.rprApplicable) {
        //for field: Survey Date
        this.updatePropertySurveyDate(templateProperty, targetProperty);
      }
    }

    //for field: Surveyor and related Attention dropdowns
    this.updatePropertySurveyor(templateMatter, targetMatter);

    //for field: New Home Warranty
    this.updatePropertyNewHomeWarranty(templateProperty, targetProperty);

    //for field: Builder No.
    this.updatePropertyBuilderNumber(templateProperty, targetProperty);

    //for field: Property No.
    this.updatePropertyPropertyNumber(templateProperty, targetProperty);

    //for field: Residents' Association
    this.updatePropertyResidentAssociation(templateMatter, targetMatter);

    //for field: Annual Fee
    this.updatePropertyAnnualFee(templateProperty, targetProperty);

    //for field: Fiscal Year End
    this.updatePropertyFiscalYearEndDate(templateProperty, targetProperty);

    //for field: Instrument No.
    this.updatePropertyInstrumentNumber(templateProperty, targetProperty);
  }

  /**
   * template matter is associated with Project (one to one)
   *
   *  target matter is the matter created based on Project (many to one)
   *
   */
  async massUpdateVendorPurchaserFields(templateMatter: Matter, targetMatter: Matter): Promise<boolean> {
    console.log('>> Mass Update of VendorPurchaser for matter: ');
    return await this.callAsynchronously(async () => {
      // targetMatter.purchasersCapacity = templateMatter.purchasersCapacity;
      // targetMatter.otherPartiesCapacity = templateMatter.otherPartiesCapacity;
      // we copy vendors (Project Sale Matter) to vendors (Project Sale Matter)
      await this.massUpdateMainClientFields(templateMatter, targetMatter);

      // we copy purchasers (Project Sale Matter) to purchaser (Project Sale Matter)
      await this.massUpdateOtherSideClientFields(templateMatter, targetMatter);
    });

  }

  async massUpdateMainClientFields(templateMatter: Matter, targetMatter: Matter) {
    // we copy vendors (Project Sale Matter) to vendors (Project Sale Matter)
    if (templateMatter.mainClients && templateMatter.mainClients.length > 0) {
      let isMethodCompleted: boolean = await this.initPurchaserComponent(targetMatter);
      if (isMethodCompleted) {
        targetMatter.mainClients.forEach(mainClient => {
          if (this.purchaserComponent.selectedClientPurchasers && this.purchaserComponent.selectedClientPurchasers.length > 0) {
            let matterParticipantWrapper = this.purchaserComponent.selectedClientPurchasers.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == mainClient.matterParticipantId);
            if (matterParticipantWrapper) {
              console.log('delete!!!');
              this.purchaserComponent.removeClientMatterParticipant(matterParticipantWrapper, true);
            }
          }
        });

        this.purchaserComponent.selectedClientPurchasers = [];

        templateMatter.mainClients.forEach((item, index) => {

          let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
          this.purchaserComponent.selectedClientPurchasers.push(matterParticipantWrapper);
          this.purchaserComponent.createMatterParticipantForClient(new Contact(item.contact), matterParticipantWrapper, index, true, true, true);
          this.matterParticipantService.updateMainClientParticipant(item, matterParticipantWrapper.matterParticipant, this.familyLawActComponent, true, templateMatter, targetMatter);
        });
        this.matterParticipantService.updateFlaSpouseId(templateMatter.mainClients, targetMatter.mainClients);
        if (this.purchaserComponent.selectedClientPurchasers) {
          let primaryWrapper: MatterParticipantWrapper = this.purchaserComponent.selectedClientPurchasers.find(item => item && item.matterParticipant.primary);
          if (primaryWrapper) {
            this.purchaserComponent.setAsPrimaryPurchaser(primaryWrapper);
          }
        }
        //It needs to wait mainClients' initalizing
        this.updateMainClientMatterFields(templateMatter, targetMatter);
      }

      // this.purchaserComponent.getTitleDetails; //needed for setting matter.matterContactInfo.titleDetails
    } else {
      this.updateMainClientMatterFields(templateMatter, targetMatter);
    }

  }

  updateMainClientDirectDepositInstructionPurchaserFields(templateMatter: Matter, targetDirectDepositInstructionPurchaser: DirectDepositInstruction) {
    if (templateMatter.directDepositInstructionPurchaser && targetDirectDepositInstructionPurchaser) {
      if (templateMatter.directDepositInstructionPurchaser.directDepositChoice) {
        targetDirectDepositInstructionPurchaser.directDepositChoice = templateMatter.directDepositInstructionPurchaser.directDepositChoice;
      }
      if (templateMatter.directDepositInstructionPurchaser.directDepositDate) {
        targetDirectDepositInstructionPurchaser.directDepositDate = templateMatter.directDepositInstructionPurchaser.directDepositDate;
      }
      if (templateMatter.directDepositInstructionPurchaser.bankAccount) {
        if (!targetDirectDepositInstructionPurchaser.bankAccount) {
          targetDirectDepositInstructionPurchaser.bankAccount = new BankAccount();
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.transitNumber) {
          targetDirectDepositInstructionPurchaser.bankAccount.transitNumber = templateMatter.directDepositInstructionPurchaser.bankAccount.transitNumber;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankNumber) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankNumber = templateMatter.directDepositInstructionPurchaser.bankAccount.bankNumber;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankName) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankName = templateMatter.directDepositInstructionPurchaser.bankAccount.bankName;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.accountNumber) {
          targetDirectDepositInstructionPurchaser.bankAccount.accountNumber = templateMatter.directDepositInstructionPurchaser.bankAccount.accountNumber;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.overrideBankAddress) {
          targetDirectDepositInstructionPurchaser.bankAccount.overrideBankAddress = templateMatter.directDepositInstructionPurchaser.bankAccount.overrideBankAddress;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.addressLine1) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.addressLine1 = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.addressLine1;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.addressLine2) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.addressLine2 = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.addressLine2;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.city) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.city = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.city;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.postalCode) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.postalCode = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.postalCode;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.country) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.country = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.country;
        }
        if (templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.provinceName) {
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.provinceName = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.provinceName;
          targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.provinceCode = templateMatter.directDepositInstructionPurchaser.bankAccount.bankAddress.provinceCode;
        }
        targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.id = null;
        targetDirectDepositInstructionPurchaser.bankAccount.bankAddress.setAddressHash();
      }

    }
  }

  updateMainClientMatterFields(templateMatter: Matter, targetMatter: Matter) {

    if (templateMatter && targetMatter) {
      if (templateMatter.clientReLine) {
        targetMatter.clientReLine = templateMatter.clientReLine;
      }
      if (templateMatter.matterContactInfo) {
        if (!targetMatter.matterContactInfo) {
          targetMatter.matterContactInfo = new MatterContactInfo();
        }
        if (!templateMatter.matterContactInfo.emailSameAsPrimaryContact) {
          targetMatter.matterContactInfo.emailSameAsPrimaryContact = templateMatter.matterContactInfo.emailSameAsPrimaryContact;
          if (templateMatter.matterContactInfo.email) {
            targetMatter.matterContactInfo.email = templateMatter.matterContactInfo.email;
          }
        }
        if (templateMatter.matterContactInfo.envelopeSalutation) {
          targetMatter.matterContactInfo.envelopeSalutation = templateMatter.matterContactInfo.envelopeSalutation;
        }
        if (templateMatter.matterContactInfo.envelopeSalutationContinued) {
          targetMatter.matterContactInfo.envelopeSalutationContinued = templateMatter.matterContactInfo.envelopeSalutationContinued;
        }
        if (templateMatter.matterContactInfo.dearText) {
          targetMatter.matterContactInfo.dearText = templateMatter.matterContactInfo.dearText;
        }

        if (!targetMatter.matterContactInfo.postClosingAddress) {
          targetMatter.matterContactInfo.postClosingAddress = new Address();
        }
        if (templateMatter.matterContactInfo.postClosingAddress.addressLine1) {
          targetMatter.matterContactInfo.postClosingAddress.addressLine1 = templateMatter.matterContactInfo.postClosingAddress.addressLine1;
        }
        if (templateMatter.matterContactInfo.postClosingAddress.addressLine2) {
          targetMatter.matterContactInfo.postClosingAddress.addressLine2 = templateMatter.matterContactInfo.postClosingAddress.addressLine2;
        }
        if (templateMatter.matterContactInfo.postClosingAddress.city) {
          targetMatter.matterContactInfo.postClosingAddress.city = templateMatter.matterContactInfo.postClosingAddress.city;
        }
        if (templateMatter.matterContactInfo.postClosingAddress.postalCode) {
          targetMatter.matterContactInfo.postClosingAddress.postalCode = templateMatter.matterContactInfo.postClosingAddress.postalCode;
        }
        if (templateMatter.matterContactInfo.postClosingAddress.provinceName) {
          targetMatter.matterContactInfo.postClosingAddress.provinceName = templateMatter.matterContactInfo.postClosingAddress.provinceName;
          targetMatter.matterContactInfo.postClosingAddress.provinceCode = templateMatter.matterContactInfo.postClosingAddress.provinceCode;
        }
        if (templateMatter.matterContactInfo.postClosingAddress.country) {
          targetMatter.matterContactInfo.postClosingAddress.country = templateMatter.matterContactInfo.postClosingAddress.country;
        }
        //ON,AB: if dropdown with not the default value Y/n, then do the propagation
        if (templateMatter.matterContactInfo.eligibleForTaxRebate && templateMatter.matterContactInfo.eligibleForTaxRebate != DpBooleanValueTypes.Y_n) {
          targetMatter.matterContactInfo.eligibleForTaxRebate = templateMatter.matterContactInfo.eligibleForTaxRebate;
        }
        targetMatter.matterContactInfo.postClosingAddress.id = null;
        targetMatter.matterContactInfo.postClosingAddress.setAddressHash();

        if (templateMatter.directDepositInstructionPurchaser && templateMatter.directDepositInstructionPurchaser.directDepositChoice === 'YES') {
          //If directDepositInstructionPurchaser exist , then remove it
          if (!Array.isArray(targetMatter.directDepositInstructions)) {
            targetMatter.directDepositInstructions = [];
          } else {
            if (targetMatter.directDepositInstructionPurchaser) {
              let directDepositInstructionPurchaser: DirectDepositInstruction = targetMatter.directDepositInstructions.find((item: DirectDepositInstruction) => {
                return item.directDepositType == targetMatter.mainClientType;
              });
              if (!directDepositInstructionPurchaser) {
                directDepositInstructionPurchaser = new DirectDepositInstruction();
                targetMatter.directDepositInstructions.push(directDepositInstructionPurchaser);
              }
              this.updateMainClientDirectDepositInstructionPurchaserFields(templateMatter, directDepositInstructionPurchaser);
            }
          }
        }
      }
    }
  }

  updateOtherPartyParticipant(templateParticipant: MatterParticipant, targetParticipant: MatterParticipant, templateMatter: Matter, targetMatter: Matter) {
    if (targetParticipant && targetParticipant.contact && templateParticipant && templateParticipant.contact) {
      //Other party doesn't include the ID details, so syncIdInfoEnteredByAndEnteredOn is false
      this.matterParticipantService.updateParticipantFla(templateParticipant, targetParticipant, this.familyLawActComponent, false, templateMatter, targetMatter);
      targetParticipant.primary = templateParticipant.primary;
      //Don't sync idInfoEnteredBy and enteredOn
      // targetParticipant.contact.idInfoEnteredBy = templateParticipant.contact.idInfoEnteredBy;
      // targetParticipant.contact.enteredOn = templateParticipant.contact.enteredOn;
      targetParticipant.contact.sourceContactId = templateParticipant.contact.sourceContactId;
      targetParticipant.contact.sourceParentOrganizationId = templateParticipant.contact.sourceParentOrganizationId;
      targetParticipant.sourceContact = templateParticipant.sourceContact;
      targetParticipant.contact.sourceParentOrganizationId = templateParticipant.contact.sourceParentOrganizationId;
      targetParticipant.contact.lastSyncedFromSource = templateParticipant.contact.lastSyncedFromSource;
      targetParticipant.purchaserCapacity = templateParticipant.purchaserCapacity;
      targetParticipant.purchaserShare = templateParticipant.purchaserShare;
      targetParticipant.sourceContactLockAcquired = templateParticipant.sourceContactLockAcquired;
    }
  }

  /**
   *
   * @param templateMatter
   * @param targetMatter
   */
  updateOtherSideOfferors(templateMatter: Matter, targetMatter: Matter) {
    let otherSideTemplateOfferors: MatterParticipant[] = templateMatter.offerors;
    let otherSideTargetOfferors: MatterParticipant[] = targetMatter.offerors;

    if (otherSideTemplateOfferors && otherSideTemplateOfferors.length > 0) {
      //Remove offerors of targetMatter
      if (otherSideTargetOfferors && otherSideTargetOfferors.length > 0) {
        otherSideTargetOfferors.forEach(matterParticipant => targetMatter.deleteMatterParticipant(matterParticipant));
      }
      otherSideTemplateOfferors.forEach(templateParticipant => {
        if (templateParticipant.contact) {
          let targetParticipant: MatterParticipant = new MatterParticipant();

          targetParticipant.contact = new Contact();
          targetParticipant.contact.createNewContactClone(templateParticipant.contact);
          targetParticipant.contact.sourceContactId = null;
          targetParticipant.contact.snapshotFlag = true;
          targetParticipant.matterParticipantRole = 'OFFEROR';
          targetParticipant.matterParticipantPriority = templateParticipant.matterParticipantPriority;
          targetMatter.matterParticipants.push(targetParticipant);
        }
      });
    }
  }

  async massUpdateOtherSideClientFields(templateMatter: Matter, targetMatter: Matter) {
    let otherSideClients: MatterParticipant[] = templateMatter.otherSideClients;
    let otherPartyLawFirm: MatterParticipant = templateMatter.otherPartyLawFirm;
    let otherPartySolicitor: MatterParticipant = templateMatter.otherPartySolicitor;
    await this.initVendorSolicitorComponent(targetMatter);
    // we copy purchasers (Project Sale Matter) to purchaser (Project Sale Matter)
    if ((otherSideClients && otherSideClients.length > 0)
      || (otherPartyLawFirm) || (otherPartySolicitor)) {
      if (otherSideClients && otherSideClients.length > 0) {
        if (this.vendorsSolicitorComponent.selectedOtherParties && this.vendorsSolicitorComponent.selectedOtherParties.length > 0) {
          targetMatter.otherSideClients.forEach(otherSideClient => {
            let matterParticipantWrapper = this.vendorsSolicitorComponent.selectedOtherParties.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == otherSideClient.matterParticipantId);
            if (matterParticipantWrapper) {
              this.vendorsSolicitorComponent.deleteOtherPartyParticipant(matterParticipantWrapper, true);
            }
          });
        }
        this.vendorsSolicitorComponent.selectedOtherParties = [];
        templateMatter.otherSideClients.forEach((item, index) => {
          let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
          matterParticipantWrapper.dataModel = {};
          this.vendorsSolicitorComponent.selectedOtherParties.push(matterParticipantWrapper);
          this.vendorsSolicitorComponent.createOtherPartyParticipant(item.contact, matterParticipantWrapper, index, true, true);
          this.updateOtherPartyParticipant(item, matterParticipantWrapper.matterParticipant, templateMatter, targetMatter);
        });

        this.matterParticipantService.updateFlaSpouseId(templateMatter.otherSideClients, targetMatter.otherSideClients);

        if (this.vendorsSolicitorComponent.selectedOtherParties) {
          let primaryWrapper: MatterParticipantWrapper = this.vendorsSolicitorComponent.selectedOtherParties.find(item => item && item.matterParticipant.primary);
          if (primaryWrapper) {
            this.vendorsSolicitorComponent.setAsPrimaryOtherParty(primaryWrapper);
          }
        }
      }

      if (otherPartyLawFirm) {
        this.massUpdateOtherSideLawFirm(templateMatter, targetMatter);
      }
      if (otherPartySolicitor) {
        this.massUpdateOtherSideSolicitor(templateMatter, targetMatter);
      }

      // this.vendorsSolicitorComponent.getTitleDetails; //needed to set matter.otherPartyContactInfo.titleDetails
    }
    //Update other side offerors
    this.updateOtherSideOfferors(templateMatter, targetMatter);
    //Update other side matter fields
    MatterUtil.updateOtherSideClientMatterFields(templateMatter, targetMatter, this.vendorsSolicitorComponent);

  }

  /**
   *  solicitor, law firm and law clerk aren't copied when it is mortgage matter with "We are acting for" starts with "Both"
   * @param matter
   */
  isMortgageWithBothActingFor(matter: Matter) {
    return matter && matter.isMortgage && (matter.actingFor === ActingForValues.BOTH_MORTGAGOR_PRIMARY || matter.actingFor === ActingForValues.BOTH_MORTGAGEE_PRIMARY);
  }

  massUpdateOtherSideLawFirm(templateMatter: Matter, targetMatter: Matter) {
    if (templateMatter.otherPartyLawFirm && templateMatter.otherPartyLawFirm.contact) {
      if (targetMatter.otherPartyLawFirm && targetMatter.otherPartyLawFirm.contact) {
        this.matterParticipantService.removeParticipant(this.vendorsSolicitorComponent.selectedLawFirm, targetMatter, false);
      }
      targetMatter.addMatterParticipant(templateMatter.otherPartyLawFirm.contact, true, targetMatter.otherPartyMPRoleLawFirm);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.otherPartyLawFirm, templateMatter.otherPartyLawFirm);
    }
  }

  massUpdateOtherSideSolicitor(templateMatter: Matter, targetMatter: Matter) {
    if (templateMatter.otherPartySolicitor && templateMatter.otherPartySolicitor.contact) {
      if (targetMatter.otherPartySolicitor && targetMatter.otherPartySolicitor.contact) {
        this.matterParticipantService.removeParticipant(this.vendorsSolicitorComponent.selectedSolicitor, targetMatter, false);
      }
      targetMatter.addMatterParticipant(templateMatter.otherPartySolicitor.contact, true, targetMatter.otherPartyMPRoleSolicitor);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.otherPartySolicitor, templateMatter.otherPartySolicitor);
    }
  }

  /**
   * template matter is associated with Project (one to one)
   *
   *  target matter is the matter created based on Project (many to one)
   *
   */
  async massUpdateCondoPageFields(templateMatter: Matter, targetMatter: Matter) {
    return await this.callAsynchronously(async () => {
      await this.massUpdateCondoFields(templateMatter, targetMatter);
    });
  }

  async massUpdateCondoFields(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initCondoCorporationComponent(targetMatter);

    //release the lock if contacts are not changed
    this.condoCorporationComponent.releaseLockFromMatterParticipantsOnCondoTab();

    const templateCondoCorporation: MatterParticipant = templateMatter.condoCorporation;
    const targetCondoCorporation: MatterParticipant = targetMatter.condoCorporation;
    if (templateCondoCorporation && templateCondoCorporation.contact) {
      let removeDocumentation: boolean = true;
      if (targetCondoCorporation) {
        if (targetCondoCorporation && targetCondoCorporation.contact
          && (templateCondoCorporation.contact.sourceContactId === targetCondoCorporation.contact.sourceContactId)) {
          removeDocumentation = false;
        }
        this.condoCorporationComponent.removeCondoCorporation(false, removeDocumentation);
      }
      //Add condoCorporation
      targetMatter.addMatterParticipant(new Contact(templateCondoCorporation.contact), true, 'CONDO_CORPORATION');
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.condoCorporation, templateMatter.condoCorporation);

      if (templateCondoCorporation.contact.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY
        && templateMatter.managementCompany
        && templateMatter.managementCompany.contact) {
        targetMatter.addMatterParticipant(new Contact(templateMatter.managementCompany.contact), true, 'MANAGEMENT_COMPANY');
        this.updateTemplateParticipantWithTargetParticipant(targetMatter.managementCompany, templateMatter.managementCompany);
      }

      if (templateMatter.condoCorpAttention && templateMatter.condoCorpAttention.contact) {
        if (templateCondoCorporation.contact.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
          targetMatter.addMatterParticipant(new Contact(templateMatter.condoCorpAttention.contact), true, MatterParticipantRoleTypes.MANAGEMENT_COMPANY_ATTENTION);
        } else {
          targetMatter.addMatterParticipant(new Contact(templateMatter.condoCorpAttention.contact), true, MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION);
        }
        this.updateTemplateParticipantWithTargetParticipant(targetMatter.condoCorpAttention, templateMatter.condoCorpAttention);
      }

      if (removeDocumentation && !targetMatter.condoCorporationDocumentation) {
        targetMatter.setCondoCorporationDocumentation();
      }
    }
  }

  /**
   * As target participant is created with template snapshot contact (to keep data in sync) so this method is copyin source original contact info
   * @param {MatterParticipant} templateParticipant
   * @param {MatterParticipant} targetParticipant
   */
  updateTemplateParticipantWithTargetContactInfo(targetParticipant: MatterParticipant, templateParticipant: MatterParticipant): void {
    this.matterParticipantService.updateTemplateParticipantWithTargetContactInfo(targetParticipant, templateParticipant);
  }

  async copyDataForMassUpdate(templateMatter: Matter, targetMatters: Matter[], massUpdateType: MassUpdateType, massUpdateData?: MassUpdateData): Promise<any[]> {

    let massUpdateErrorMessages: any[] = [];
    if (massUpdateType) {
      switch (massUpdateType.toString()) {
        case MassUpdateTypes.TOPIC_A_K_M_N:
          //targetMatter loaded without project data, since templateMatter and targetMatter share the same project, so ...
          targetMatters.forEach(targetMatter => targetMatter.project = templateMatter && templateMatter.project);
          let errorMessagesMassUpdateTopicsA_K_M_N = await this.callSynchronouslyMassUpdateTopicsA_K_M_N(templateMatter, targetMatters);
          massUpdateErrorMessages.push(...errorMessagesMassUpdateTopicsA_K_M_N);
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.NUMBER_OF_EXISTING_MORTGAGES:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateExistingMortgages(templateMatter, targetMatter, massUpdateData);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.NUMBER_OF_VTB_MORTGAGES:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateVTBMortgages(templateMatter, targetMatter, massUpdateData);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.FINAL_ADJUSTMENT_DATE:
          await this.massUpdateInterimOrFinalAdjustmentDate(templateMatter, targetMatters, massUpdateData, true);
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.INTERIM_ADJUSTMENT_DATE:
          await this.massUpdateInterimOrFinalAdjustmentDate(templateMatter, targetMatters, massUpdateData, false);
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.UPDATE_FORM4_BANK_ACCOUNT_INFO:
          targetMatters.forEach((targetMatter: Matter) => {
            this.updateForm4BankAccountInfo(templateMatter, targetMatter, massUpdateData);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.ADD_NEW_SUPPLEMENTAL_TASKS:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateSupplementalTasks(templateMatter, targetMatter);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.SOA_GL_CODES:
          let sourceGlCodes = new Map<string, string>();
          templateMatter.matterSoas.forEach(soaLine => {
            if (soaLine.accountCode) {
              sourceGlCodes.set(soaLine.itemName + '_' + soaLine.progressionStatus, !(soaLine.accountCode) ? ' ' : soaLine.accountCode);
            }
          });
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateSoaGlCodes(sourceGlCodes, targetMatter);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.SOA_GL_CODES_FINAL:
          let sourceFinalGlCodes = new Map<string, string>();
          templateMatter.matterSoas.forEach(soaLine => {
            if (soaLine.progressionStatus === ProgressionStatus.FINAL) {
              sourceFinalGlCodes.set(soaLine.itemName + '_' + soaLine.progressionStatus, !(soaLine.accountCode) ? ' ' : soaLine.accountCode);
            }
          });
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateSoaGlCodes(sourceFinalGlCodes, targetMatter);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.SOA_GL_CODES_INTERIM:
          let sourceInterimGlCodes = new Map<string, string>();
          templateMatter.matterSoas.forEach(soaLine => {
            if (soaLine.progressionStatus === ProgressionStatus.INTERIM) {
              sourceInterimGlCodes.set(soaLine.itemName + '_' + soaLine.progressionStatus, !(soaLine.accountCode) ? ' ' : soaLine.accountCode);
            }
          });
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateSoaGlCodes(sourceInterimGlCodes, targetMatter);
          });
          return Promise.resolve(massUpdateErrorMessages);

        case MassUpdateTypes.STATEMENT_OF_ACCOUNT:
        case MassUpdateTypes.STATEMENT_OF_ACCOUNT_INTERIM:
        case MassUpdateTypes.STATEMENT_OF_ACCOUNT_FINAL:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateStatementOfAccount(templateMatter, targetMatter, massUpdateType);
          });
          return Promise.resolve(massUpdateErrorMessages);

        case MassUpdateTypes.TRUST_LEDGER:
        case MassUpdateTypes.TRUST_LEDGER_INTERIM:
        case MassUpdateTypes.TRUST_LEDGER_FINAL:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateTrustLedger(templateMatter, targetMatter, massUpdateType);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.UNIT_TYPES:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massUpdateUnitTypes(massUpdateData.data, targetMatter);
          });
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.GST_HST_APPLICATION:
          targetMatters.forEach((targetMatter: Matter) => {
            this.massProduceGSTHSTForms(massUpdateData.data, targetMatter);
          });
          let matterIds: number[] = targetMatters.map(targetMatter => {
            return targetMatter.id;
          });
          this.checkDocProductionCompleteAndCollectErrors(matterIds, massUpdateData.data, massUpdateErrorMessages);
          return Promise.resolve(massUpdateErrorMessages);
        case MassUpdateTypes.TAX_RATE_OF_SOA_SOAJ:
          await this.massUpdateTaxRates(targetMatters, massUpdateData);
          return Promise.resolve(massUpdateErrorMessages);
      }
    }
    return Promise.resolve(undefined);
  }

  massUpdateUserDefinedFields(templateMatter: Matter, targetMatter: Matter) {
    console.log('>> Mass Update of UDF\'s for matter: ', targetMatter.matterRecordNumber);

    //only UDFs that are unrelated to mortgages are updated here. Mortgage UDFs updated as part of the mortgage mass update logic
    if (templateMatter.matterUserDefinedFields) {
      let matterUdfs: UserDefinedField[] = targetMatter.matterUserDefinedFields.filter(udf => !udf.isMortgageTopic());
      targetMatter.matterUserDefinedFields = targetMatter.matterUserDefinedFields.filter(udf => udf.isMortgageTopic()); //wipe out all existing UDFs except mortgage related ones
      templateMatter.matterUserDefinedFields.filter(udf => !udf.isMortgageTopic()).forEach(projectUdf => {
        targetMatter.matterUserDefinedFields.push(new UserDefinedField(projectUdf));
        if (matterUdfs) {
          targetMatter.matterUserDefinedFields.forEach(item => {
            for (let udf of matterUdfs) {
              if ((item.sourceUDFId == udf.sourceUDFId) && !item.fieldValue) {
                item.fieldValue = udf.fieldValue;
              }
            }
          });
        }
      });
    }

  }

  async massUpdateCompliance(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    if (templateMatter.matterCompliances && templateMatter.matterCompliances.length) {
      console.log('>> Mass Update Compliance: ', targetMatter.matterRecordNumber);
      if (targetMatter.matterCompliances && targetMatter.matterCompliances.length) {
        let defaultCompliances = await this.loadDefaultCompliances(templateMatter.provinceCode, templateMatter.matterType);
        defaultCompliances.forEach((compliance) => {
          let templateMatterComplianceIndex = templateMatter.matterCompliances.findIndex(mc => mc.departmentKey == compliance.departmentKey);
          let targetMatterComplianceIndex = targetMatter.matterCompliances.findIndex(mc => mc.departmentKey == compliance.departmentKey);

          if (templateMatter.matterCompliances[ templateMatterComplianceIndex ]) {
            if (templateMatter.matterCompliances[ templateMatterComplianceIndex ].writeTo != compliance.writeTo) {
              targetMatter.matterCompliances[ targetMatterComplianceIndex ].writeTo = templateMatter.matterCompliances[ templateMatterComplianceIndex ].writeTo;
            }
            if (templateMatter.matterCompliances[ templateMatterComplianceIndex ].status != compliance.status) {
              targetMatter.matterCompliances[ targetMatterComplianceIndex ].status = templateMatter.matterCompliances[ templateMatterComplianceIndex ].status;
            }
            if (templateMatter.matterCompliances[ templateMatterComplianceIndex ].remarks) {
              targetMatter.matterCompliances[ targetMatterComplianceIndex ].remarks = templateMatter.matterCompliances[ templateMatterComplianceIndex ].remarks;
            }
          }
        });

      } else {
        targetMatter.matterCompliances = [];
        templateMatter.matterCompliances.forEach((compliance) => {
          let matterCompliance = new Compliance(compliance);
          matterCompliance.id = null;
          targetMatter.matterCompliances.push(matterCompliance);
        });
      }
    }
  }

  async loadDefaultCompliances(provinceCode: ProvinceCode, matterType: MatterType): Promise<Compliance[]> {
    let res: JurisdictionDepartment[] = await this.jurisdictionDepartmentsService.getDepartmentPairs(null, provinceCode).toPromise();
    let jurisdictionDepartments = _.sortBy(res, [ 'departmentPriority' ]);
    let defaultCompliances = Compliance.jurisdictionDepartmentsToCompliances(jurisdictionDepartments, matterType);
    return Promise.resolve(defaultCompliances);
  }

  async validateComplianceTopic(templateMatter: Matter): Promise<boolean> {
    if (!templateMatter.isMatterProvinceON || !(templateMatter.compliances && templateMatter.compliances.length)) {
      return Promise.resolve(true);
    }

    if (templateMatter.compliances && templateMatter.compliances.length) {
      if (templateMatter.matterPropertyWithCondo && templateMatter.matterPropertyWithCondo.jurisdiction) {
        return Promise.resolve(true);
      } else {
        let isValid = true;
        let defaultCompliances = await this.loadDefaultCompliances(templateMatter.provinceCode, templateMatter.matterType);
        defaultCompliances.forEach((compliance) => {
          let matterCompliance = templateMatter.compliances.find(mc => mc.departmentKey == compliance.departmentKey);
          if (matterCompliance && (matterCompliance.writeTo != compliance.writeTo || matterCompliance.status != compliance.status || matterCompliance.remarks)) {
            isValid = false;
          }
        });
        return Promise.resolve(isValid);
      }
    }

  }

  addNewExistingMortgageToPs(matter: Matter): void {
    if (!matter.existingMortgages) {
      matter.existingMortgages = [];
    }
    if (matter.existingMortgages.length < this.appConfig.getMaxNumberOfMortgages()) {
      matter.addNewExistingMortgage();
    }
  }

  addNewVtbMortgageToPs(matter: Matter): void {
    if (!matter.mortgages) {
      matter.mortgages = [];
    }
    if (matter.mortgages.length < this.appConfig.getMaxNumberOfMortgages()) {
      matter.addNewVtbMortgage();
    }
  }

  massUpdateExistingMortgages(templateMatter: Matter, targetMatter: Matter, massUpdateData: MassUpdateData) {
    if (massUpdateData && massUpdateData.rangeOfMortgage) {
      if (!Array.isArray(targetMatter.existingMortgages)) {
        targetMatter.existingMortgages = [];
      }

      if (massUpdateData.rangeOfMortgage < targetMatter.existingMortgages.length) {
        for (let i = targetMatter.existingMortgages.length - 1; i >= massUpdateData.rangeOfMortgage; i--) {
          targetMatter.deleteExistingMortgageWithCleanup(i, targetMatter.existingMortgages[ i ]);
        }
      } else if (massUpdateData.rangeOfMortgage > targetMatter.existingMortgages.length) {
        const addNewExistingMortgageNumber: number = massUpdateData.rangeOfMortgage - targetMatter.existingMortgages.length;
        for (let i = 0; i < addNewExistingMortgageNumber; i++) {
          this.addNewExistingMortgageToPs(targetMatter);
          targetMatter.updateStatementOfAccount();
        }
      }
    }
  }

  massUpdateVTBMortgages(templateMatter: Matter, targetMatter: Matter, massUpdateData: MassUpdateData) {
    console.log('>> massUpdateVTBMortgages with range %s ', (massUpdateData && massUpdateData.rangeOfMortgage));
    if (massUpdateData && massUpdateData.rangeOfMortgage) {
      if (!Array.isArray(targetMatter.mortgages)) {
        targetMatter.mortgages = [];
      }

      if (massUpdateData.rangeOfMortgage == targetMatter.mortgages.length) {
        console.log('>> current Matter %s has %i Vtb Morgages, same as the range: %s, no action required.', targetMatter.matterRecordNumber, targetMatter.mortgages.length, massUpdateData.rangeOfMortgage);
      } else if (massUpdateData.rangeOfMortgage < targetMatter.mortgages.length) {
        console.log('>> current Matter %s has %i Vtb Morgages, more than the range: %s, need to delete the extra vtb mortgage', targetMatter.matterRecordNumber, targetMatter.mortgages.length, massUpdateData.rangeOfMortgage);
        for (let i = targetMatter.mortgages.length - 1; i >= massUpdateData.rangeOfMortgage; i--) {
          console.log('>> current Matter %s is deleting vtb mortgage %s', targetMatter.matterRecordNumber, targetMatter.mortgages[ i ].id);
          targetMatter.deleteVtbMortgage(targetMatter.mortgages[ i ], this.mortgageSoAdjService);
        }
      } else if (massUpdateData.rangeOfMortgage > targetMatter.mortgages.length) {
        console.log('>> current Matter %s has %i Vtb Morgages, less than the range: %s, need to add some vtb mortgage', targetMatter.matterRecordNumber, targetMatter.mortgages.length, massUpdateData.rangeOfMortgage);
        const addNewVtbMortgageNumber: number = massUpdateData.rangeOfMortgage - targetMatter.mortgages.length;
        for (let i = 0; i < addNewVtbMortgageNumber; i++) {
          this.addNewVtbMortgageToPs(targetMatter);
          targetMatter.updateStatementOfAccount();
          this.mortgageSoAdjService.rearrangeAdjustments(targetMatter);
          this.mortgageSoAdjService.updateStatementOfAdjustment(targetMatter);
        }
      }
    }
  }

  async updateAdjustmentDate(templateMatter: Matter, targetMatter: Matter, massUpdateData: MassUpdateData, isUpdateFinalAdjustmentDate: boolean = false) {
    console.log('>>update %s AdjustmentDate for matter %s', (isUpdateFinalAdjustmentDate ? 'Final' : 'Interim'), targetMatter.matterRecordNumber);
    if (massUpdateData && massUpdateData.data && massUpdateData.data instanceof MassUpdateAdjustmentDateData) {
      const massUpdateAdjustmentDateData: MassUpdateAdjustmentDateData = <MassUpdateAdjustmentDateData>massUpdateData.data;
      const updateType: string = massUpdateAdjustmentDateData.updateType;
      if (isUpdateFinalAdjustmentDate) {
        if (updateType == 'ADJUSTMENT_DATE' || updateType == 'CLOSING_DATE' || updateType == 'SPECIFY') {
          //the following data changes are happened in the Final Tab
          targetMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
          if (updateType == 'SPECIFY') {
            targetMatter.adjustAsAtClosingDateFlag = 'SPECIFY';
            targetMatter.adjustAsAtClosingDate = massUpdateAdjustmentDateData.dateValue;
          } else {
            if (targetMatter.isPurchaseBC) {
              targetMatter.adjustAsAtClosingDateFlag = 'ADJUSTMENT_DATE';
            } else {
              targetMatter.adjustAsAtClosingDateFlag = 'CLOSING_DATE';
            }
          }
        }
      } else {
        if (updateType == 'OCCUPANCY_DATE' || updateType == 'SPECIFY') {
          //the following data changes are happened in the Interim Tab
          targetMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
          if (updateType == 'SPECIFY') {
            targetMatter.adjustAsAtClosingDateFlagInterim = 'SPECIFY';
            targetMatter.adjustAsAtClosingDateInterim = massUpdateAdjustmentDateData.dateValue;
          } else {
            targetMatter.adjustAsAtClosingDateFlagInterim = 'OCCUPANCY_DATE';
          }
        }
      }
      await this.initStatementAdjustmentComponent(targetMatter);
      await this.statementAdjustmentComponent.soaUtils.updateAdjustments();

      if (massUpdateAdjustmentDateData.setRecordToInterimFinalClosing == 'YES') {
        targetMatter.selectedProgressionStatus = isUpdateFinalAdjustmentDate ? ProgressionStatus.FINAL : ProgressionStatus.INTERIM;
      }
    }
  }

  updateForm4BankAccountInfo(templateMatter: Matter, targetMatter: Matter, massUpdateData: MassUpdateData) {
    console.log('>>updateForm4BankAccountInfo for matter %s with trustAccount %s', targetMatter.matterRecordNumber, (massUpdateData && massUpdateData.selectedTrustAccount && massUpdateData.selectedTrustAccount.mortgageeName + ' - ' + massUpdateData && massUpdateData.selectedTrustAccount && massUpdateData.selectedTrustAccount.trustAccountNumber));
    if (targetMatter && targetMatter.extraDepositConfig && massUpdateData) {
      targetMatter.extraDepositConfig.setTrustAccountData(massUpdateData.selectedTrustAccount);
    }
  }

  async massUpdateTopicsA_K_M_N(templateMatter: Matter, targetMatter: Matter): Promise<any[]> {
    let errorMessages: any[] = [];
    console.log('>>>> started massUpdateTopicsA_K_M_N  %s', targetMatter.matterRecordNumber);
    this.lockScreenService.lockForUpdate = true;
    let isInitMatterOpeningMethodCompleted: boolean = await this.initMatterOpeningComponent(targetMatter);
    if (isInitMatterOpeningMethodCompleted) {
      await this.massUpdateMatterOpeningFields(templateMatter, targetMatter);
    }
    if (templateMatter.matterClosingStatus && templateMatter.matterClosingStatus != 'QUESTION') {
      targetMatter.matterClosingStatus = templateMatter.matterClosingStatus;
    }
    console.log('>>>> finished massUpdateMatterOpeningFields  %s', targetMatter.matterRecordNumber);
    await this.massUpdateVendorPurchaserFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateVendorPurchaserFields  %s', targetMatter.matterRecordNumber);
    await this.massUpdateSubjectPropertyPageFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateSubjectPropertyPageFields  %s', targetMatter.matterRecordNumber);
    await this.massUpdateCondoPageFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateCondoPageFields  %s', targetMatter.matterRecordNumber);
    await this.massUpdateFireInsuranceFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateFireInsuranceFields  %s', targetMatter.matterRecordNumber);
    await this.massUpdateBrokerCommissionFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateBrokerCommissionFields  %s', targetMatter.matterRecordNumber);
    let updateNotesDone: boolean = await this.massUpdateNotes(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateNotes  %s', targetMatter.matterRecordNumber);
    if (templateMatter.statementOfAdjustmentPayable.adoptProjectSoaPayableTo
      || templateMatter.statementOfAdjustmentPayable.excludePrefix
      || templateMatter.statementOfAdjustmentPayable.excludeSuffix
      || templateMatter.statementOfAdjustmentPayable.payableTo) {
      targetMatter.statementOfAdjustmentPayable = templateMatter.statementOfAdjustmentPayable;
    }
    this.massUpdateUserDefinedFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateUserDefinedFields  %s', targetMatter.matterRecordNumber);
    if (templateMatter.isMatterProvinceON) {
      let toMassUpdateCompliance = this.validateComplianceTopic(templateMatter);
      if (toMassUpdateCompliance) {
        await this.massUpdateCompliance(templateMatter, targetMatter);
      }
    }
    console.log('>>>> finished massUpdateCompliance  %s', targetMatter.matterRecordNumber);
    console.log('calling massUpdateMortgages');
    await this.massUpdateMortgages(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateMortgages  %s', targetMatter.matterRecordNumber);

    this.massUpdateOtherHoldback(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateOtherHoldback  %s', targetMatter.matterRecordNumber);

    let massUpdateStatementOfAdjustmentErrors = await this.massUpdateStatementOfAdjustment(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateStatementOfAdjustment  %s', targetMatter.matterRecordNumber);
    errorMessages.push(...massUpdateStatementOfAdjustmentErrors);
    this.lockScreenService.lockForUpdate = false;
    let massUpdateERegConsiderationErrors = await this.massUpdateERegConsiderationFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateERegConsiderationFields  %s', targetMatter.matterRecordNumber);
    errorMessages.push(...massUpdateERegConsiderationErrors);
    await this.massUpdateDirectionRefundFields(templateMatter, targetMatter);
    console.log('>>>> finished massUpdateDirectionRefundFields  %s', targetMatter.matterRecordNumber);
    console.log('>>>> finished massUpdateTopicsA_K_M_N  %s', targetMatter.matterRecordNumber);
    return errorMessages;
  }

  // moved from copyMatterLinkDataService
  copyLawclerkParticipant(templateMatter: Matter, targetMatter: Matter): void {
    if (templateMatter.lawClerk && templateMatter.lawClerk.contact) {
      MatterCleanUpUtil.cleanUpLawClerk(targetMatter);
      targetMatter.updateMatterLawClerkInfo(templateMatter.lawClerk.contact);
    }
  }

  copySolicitorParticipant(templateMatter: Matter, targetMatter: Matter): void {
    if (templateMatter.solicitor && templateMatter.solicitor.contact) {
      MatterCleanUpUtil.cleanUpSolicitor(targetMatter);
      targetMatter.updateMatterSolicitorInfo(templateMatter.solicitor.contact);
    }
  }

  copyWitnessParticipant(templateMatter: Matter, targetMatter: Matter): void {
    if (templateMatter.witness && templateMatter.witness.contact) {
      MatterCleanUpUtil.cleanUpWitness(targetMatter);
      targetMatter.updateMatterWitnessInfo(templateMatter.witness.contact);
    }
  }

  copyCommissionerParticipant(templateMatter: Matter, targetMatter: Matter): void {
    if (templateMatter.commissioner && templateMatter.commissioner.contact) {
      MatterCleanUpUtil.cleanUpCommissioner(targetMatter);
      targetMatter.updateMatterCommissionerInfo(templateMatter.commissioner.contact);
    }
  }

  massUpdateSupplementalTasks(templateMatter: Matter, targetMatter: Matter): void {

    if (Array.isArray(templateMatter.supplementalTasks) && templateMatter.supplementalTasks.length > 0) {

      if (!Array.isArray(targetMatter.supplementalTasks)) {
        targetMatter.supplementalTasks = [];
      }

      let updateSupplemetalTaskExpenditureInTrustLedger: boolean = templateMatter.updateSupplemetalTaskExpenditureInTrustLedger;
      templateMatter.supplementalTasks.forEach(supplementalTask => {

        let clonedTask: MatterSupplementalTaskCategory = MatterSupplementalTaskCategory.clone(supplementalTask);
        MatterSupplementalTaskCategory.setOrderNumber(clonedTask, targetMatter);
        //We add to the existing collection if there are some tasks there. It's intentional. The end result is meant to contain a mix of old and new.
        targetMatter.supplementalTasks.push(clonedTask);
        // Handle Down stream like Trust Ledger.
        if (updateSupplemetalTaskExpenditureInTrustLedger && targetMatter.soaTrustLedgerCollection) {
          targetMatter.soaTrustLedgerCollection.updateSupplementalTaskExpenditures();
        }
      });
    }

  }

  massUpdateSoaGlCodes(sourceGlCodes: Map<string, string>, targetMatter: Matter): void {
    targetMatter.matterSoas.forEach(soaLine => {
      let glCode = sourceGlCodes.get(soaLine.itemName + '_' + soaLine.progressionStatus);
      if (glCode) {
        soaLine.accountCode = glCode.trim();
      }
    });
  }

  massUpdateStatementOfAccount(templateMatter: Matter, targetMatter: Matter, massUpdateType: string): void {

    if (targetMatter.soaTrustLedgerCollection) {

      if (massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_INTERIM) {
        targetMatter.interimFeeCalculatedOnInclusivePriceFlag = templateMatter.interimFeeCalculatedOnInclusivePriceFlag;
        targetMatter.soaInterimFeesCalculatedOnAllInclusivePrice = templateMatter.soaInterimFeesCalculatedOnAllInclusivePrice;
        targetMatter.receivedOnAccountInterim = templateMatter.receivedOnAccountInterim;
        targetMatter.soaTrustLedgerCollection.clearInterimFeesAndDisbursements();
        templateMatter.soaTrustLedgerCollection._feesInterim.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._feesInterim.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsHSTInterim.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsHSTInterim.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsAdditionalInterim.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsAdditionalInterim.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsPSTInterim.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsPSTInterim.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsGSTandPSTInterim.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsGSTandPSTInterim.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsNotHSTInterim.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsNotHSTInterim.push(this.copyStatementOfAccount(soa));
        });

      } else if (massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_FINAL || massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT) {
        targetMatter.feeCalculatedOnInclusivePriceFlag = templateMatter.feeCalculatedOnInclusivePriceFlag;
        targetMatter.soaFeesCalculatedOnAllInclusivePrice = templateMatter.soaFeesCalculatedOnAllInclusivePrice;
        targetMatter.receivedOnAccount = templateMatter.receivedOnAccount;
        targetMatter.soaTrustLedgerCollection._feesFinal = [];
        targetMatter.soaTrustLedgerCollection._disbursementsHSTFinal = [];
        targetMatter.soaTrustLedgerCollection._disbursementsAdditionalFinal = [];
        targetMatter.soaTrustLedgerCollection._disbursementsPSTFinal = [];
        targetMatter.soaTrustLedgerCollection._disbursementsGSTandPSTFinal = [];
        targetMatter.soaTrustLedgerCollection._disbursementsNotHSTFinal = [];
        templateMatter.soaTrustLedgerCollection._feesFinal.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._feesFinal.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsHSTFinal.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsHSTFinal.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsAdditionalFinal.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsAdditionalFinal.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsPSTFinal.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsPSTFinal.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsGSTandPSTFinal.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsGSTandPSTFinal.push(this.copyStatementOfAccount(soa));
        });
        templateMatter.soaTrustLedgerCollection._disbursementsNotHSTFinal.forEach((soa) => {
          targetMatter.soaTrustLedgerCollection._disbursementsNotHSTFinal.push(this.copyStatementOfAccount(soa));
        });
      }

      if (massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_INTERIM) {
        targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
        if (templateMatter.soaTrustLedgerCollection && templateMatter.feeCalculatedOnInclusivePriceFlag) {
          targetMatter.soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
        }
      }
      if (massUpdateType == MassUpdateTypes.STATEMENT_OF_ACCOUNT_FINAL) {
        targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;
        if (templateMatter.soaTrustLedgerCollection && templateMatter.interimFeeCalculatedOnInclusivePriceFlag) {
          targetMatter.soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
        }
        targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
      }
    }
    MatterCleanUpUtil.cleanUpStatementOfAccount(targetMatter);
  }

  massUpdateTrustLedger(templateMatter: Matter, targetMatter: Matter, massUpdateType: string): void {

    if (targetMatter.soaTrustLedgerCollection) {
      targetMatter.autoInsertAllF9Values = templateMatter.autoInsertAllF9Values;
      targetMatter.autoInsertCashShortfall = templateMatter.autoInsertCashShortfall;

      if (massUpdateType == MassUpdateTypes.TRUST_LEDGER_INTERIM) {
        this.removeUserAddedTrustLedgerRows(targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim);
        templateMatter.soaTrustLedgerCollection._matterTrustLedgersInterim.filter(tl => tl.itemKey != SoaTrustLedgerConfigKeys.L1_TAX_PAID_FROM_TRUST_ACCOUNT).forEach((tl, index) => {
          if (!tl.itemKey) { //User Added Item
            //targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim.push(this.copyMatterTrustLedger(tl));
            targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim.splice(index, 0, this.copyMatterTrustLedger(tl));
          } else {
            let item = targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim.find(tlItem => tlItem.itemKey == tl.itemKey);
            if (item) {
              // we need to copy name in case of user defined config items
              if (item.itemKey && !isNaN(Number(item.itemKey))) {
                item.itemName = tl.itemName;
              }
              item.itemValue = tl.itemValue;
            } else {
              let trustLedgerMatter = this.copyMatterTrustLedger(tl);
              trustLedgerMatter.f9DefaultAmount = 0;
              //targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim.push(trustLedgerMatter);
              targetMatter.soaTrustLedgerCollection._matterTrustLedgersInterim.splice(index, 0, trustLedgerMatter);
            }
          }
        });

      } else if (massUpdateType == MassUpdateTypes.TRUST_LEDGER_FINAL || massUpdateType == MassUpdateTypes.TRUST_LEDGER) {
        this.removeUserAddedTrustLedgerRows(targetMatter.soaTrustLedgerCollection._matterTrustLedgersFinal);
        templateMatter.soaTrustLedgerCollection._matterTrustLedgersFinal.filter(tl => tl.itemKey != SoaTrustLedgerConfigKeys.L1_TAX_PAID_FROM_TRUST_ACCOUNT).forEach((tl, index) => {
          if (!tl.itemKey) { //User Added Item
            targetMatter.soaTrustLedgerCollection._matterTrustLedgersFinal.splice(index, 0, this.copyMatterTrustLedger(tl));
          } else {
            let item = targetMatter.soaTrustLedgerCollection._matterTrustLedgersFinal.find(tlItem => tlItem.itemKey == tl.itemKey);
            if (item) {
              // we need to copy name in case of user defined config items
              if (item.itemKey && !isNaN(Number(item.itemKey))) {
                item.itemName = tl.itemName;
              }
              item.itemValue = tl.itemValue;
            } else {
              let trustLedgerMatter = this.copyMatterTrustLedger(tl);
              trustLedgerMatter.f9DefaultAmount = 0;
              //targetMatter.soaTrustLedgerCollection._matterTrustLedgersFinal.push(trustLedgerMatter);
              targetMatter.soaTrustLedgerCollection._matterTrustLedgersFinal.splice(index, 0, trustLedgerMatter);
            }
          }

        });
      }

      if (targetMatter.autoInsertAllF9Values) {
        targetMatter.soaTrustLedgerCollection.insertUpdateAllF9();
        if (massUpdateType == MassUpdateTypes.TRUST_LEDGER_INTERIM) {
          targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.FINAL;
          targetMatter.soaTrustLedgerCollection.insertUpdateAllF9();
        }
        if (massUpdateType == MassUpdateTypes.TRUST_LEDGER_FINAL) {
          targetMatter.soaTrustLedgerCollection.progressionStatus = ProgressionStatus.INTERIM;
          targetMatter.soaTrustLedgerCollection.insertUpdateAllF9();
        }
      }

    }
    MatterCleanUpUtil.cleanUpStatementOfAccount(targetMatter);
  }

  removeUserAddedTrustLedgerRows(trustLedgers: TrustLedgerMatter[]): void {
    let userAddedRows = [];
    trustLedgers.forEach((tl) => {
      if (!tl.itemKey) {
        userAddedRows.push(tl);
      }
    });
    if (userAddedRows && userAddedRows.length) {
      userAddedRows.forEach((tl) => {
        (<any>trustLedgers).remove(tl);
      });

    }
  }

  copyMatterTrustLedger(sourceTL: TrustLedgerMatter): TrustLedgerMatter {
    let trustLedgerMatter = new TrustLedgerMatter(sourceTL);
    trustLedgerMatter.id = null;
    return trustLedgerMatter;
  }

  copyStatementOfAccount(sourceSoa: SoaMatter): SoaMatter {
    let soa = new SoaMatter(sourceSoa);
    soa.id = null;
    return soa;
  }

  async callSynchronouslyMassUpdateTopicsA_K_M_N(templateMatter: Matter, matters: Matter[]): Promise<any[]> {
    let massUpdateTopicsA_K_M_NErrorMessages: any[] = [];
    let counter = 1;
    if (templateMatter && Array.isArray(matters) && matters.length > 0) {
      for (let targetMatter of matters) {
        let errorMessages = await this.massUpdateTopicsA_K_M_N(templateMatter, targetMatter);
        massUpdateTopicsA_K_M_NErrorMessages.push(...errorMessages);
      }
    }
    return massUpdateTopicsA_K_M_NErrorMessages;
  }

  async massUpdateFireInsuranceFields(templateMatter: Matter, targetMatter: Matter) {
    return await this.callAsynchronously(async () => {
      console.log('>> Mass Update of Fire Insurance for matter: ');
      await this.massUpdateFireInsurance(templateMatter, targetMatter);
    });
  }

  async massUpdateInterimOrFinalAdjustmentDate(templateMatter: Matter, targetMatters: Matter[], massUpdateData: MassUpdateData, updateFinal: boolean = false) {
    if (templateMatter && Array.isArray(targetMatters) && targetMatters.length > 0) {
      for (let targetMatter of targetMatters) {
        await this.updateAdjustmentDate(templateMatter, targetMatter, massUpdateData, updateFinal);
      }
    }
  }

  async massUpdateFireInsurance(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initFireInsurerComponent(targetMatter);
    if (templateMatter.insurerMatterParticipant || templateMatter.brokerMatterParticipant) {
      if (targetMatter.insurerMatterParticipant) {
        this.fireInsuranceComponent.removeInsurer();
      }
      if (targetMatter.brokerMatterParticipant) {
        this.fireInsuranceComponent.removeBroker();
      }
      this.copyFireInsuranceParticipants(templateMatter, targetMatter);
    }
    this.copyFireInsuranceInfoData(templateMatter, targetMatter);
    this.copyMortgageeInterestNotedOnPolicies(templateMatter, targetMatter);
  }

  copyFireInsuranceInfoData(templateMatter: Matter, targetMatter: Matter): void {
    if (!targetMatter.fireInsuranceContactInfo) {
      targetMatter.copyFireInsuranceInfoData(templateMatter);
    } else {
      if (templateMatter.fireInsuranceContactInfo.coverage !== 0) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.coverage = templateMatter.fireInsuranceContactInfo.coverage;
        }
      }
      if (templateMatter.fireInsuranceContactInfo.insuranceBrokerType) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.insuranceBrokerType = templateMatter.fireInsuranceContactInfo.insuranceBrokerType;
        }
      }
      if (templateMatter.fireInsuranceContactInfo.mortgageSelected) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.mortgageSelected = templateMatter.fireInsuranceContactInfo.mortgageSelected;
        }
      }
      if (templateMatter.fireInsuranceContactInfo.expiryDate) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.expiryDate = templateMatter.fireInsuranceContactInfo.expiryDate;
        }
      }
      if (templateMatter.fireInsuranceContactInfo.documentationType) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.documentationType = templateMatter.fireInsuranceContactInfo.documentationType;
        }
      }
      if (templateMatter.fireInsuranceContactInfo.documentationValue) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.documentationValue = templateMatter.fireInsuranceContactInfo.documentationValue;
        }
      }
      if (templateMatter.fireInsuranceContactInfo.guaranteedReplacementCoverage) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.guaranteedReplacementCoverage = templateMatter.fireInsuranceContactInfo.guaranteedReplacementCoverage;
        }
      }
      //dear field
      if (this.isNotEmpty(templateMatter.fireInsuranceContactInfo.dear)) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.dear = templateMatter.fireInsuranceContactInfo.dear;
        }
      }
      //agent field
      if (this.isNotEmpty(templateMatter.fireInsuranceContactInfo.agent)) {
        if (targetMatter.fireInsuranceContactInfo) {
          targetMatter.fireInsuranceContactInfo.agent = templateMatter.fireInsuranceContactInfo.agent;
        }
      }
    }
  }

  copyMortgageeInterestNotedOnPolicies(templateMatter: Matter, targetMatter: Matter): void {
    for (let i = 0; i < targetMatter.mortgages.length; i++) {
      if (templateMatter.massUpdateMortgageeInterestNotedOnPolicies[ i ] !== 'QUESTION') {
        targetMatter.mortgages[ i ].mortgageeInterestNotedOnPolicy = templateMatter.massUpdateMortgageeInterestNotedOnPolicies[ i ];
      }
    }
  }

  copyFireInsuranceParticipants(templateMatter: Matter, targetMatter: Matter): void {
    if (templateMatter.insurerMatterParticipant) {
      targetMatter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.INSURER;
      this.fireInsuranceComponent.createNewMatterInsurerContact(templateMatter.insurerMatterParticipant.contact);
      this.fireInsuranceComponent.populateFieldsFromInsurerOrBroker(templateMatter.insurerMatterParticipant.contact);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.insurerMatterParticipant, templateMatter.insurerMatterParticipant);
    } else if (templateMatter.brokerMatterParticipant) {
      targetMatter.fireInsuranceContactInfo.insuranceBrokerType = MatterParticipantRoleTypes.BROKER;
      this.fireInsuranceComponent.createNewMatterBrokerContact(templateMatter.brokerMatterParticipant.contact);
      this.fireInsuranceComponent.populateFieldsFromInsurerOrBroker(templateMatter.brokerMatterParticipant.contact);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.brokerMatterParticipant, templateMatter.brokerMatterParticipant);
      targetMatter.fireInsuranceContactInfo.companyInsurerName = templateMatter.fireInsuranceContactInfo.companyInsurerName;
    }
  }

  async massUpdateBrokerCommissionFields(templateMatter: Matter, targetMatter: Matter) {
    return this.callAsynchronously(async () => {
      await this.massUpdateBrokerCommission(templateMatter, targetMatter);
    });
  }

  async massUpdateMortgages(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    console.log('calling applyNewMortgageChanges');
    await this.applyNewMortgageChanges(templateMatter, targetMatter);
    console.log('finished applyNewMortgageChanges');
    console.log('calling applyExistingMortgageChanges');
    await this.applyExistingMortgageChanges(templateMatter, targetMatter);
    console.log('finished applyExistingMortgageChanges');
  }

  async applyNewMortgageChanges(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    if (templateMatter.mortgages && templateMatter.mortgages.length && targetMatter.mortgages && targetMatter.mortgages.length) {
      for (let i = 0; i < templateMatter.mortgages.length; i++) {
        if (targetMatter.mortgages[ i ] && templateMatter.mortgages[ i ]) {
          let sourceMortgage = templateMatter.mortgages[ i ];
          let targetMortgage = targetMatter.mortgages[ i ];

          await this.initMortgageDetailComponent(targetMatter, i);
          this.applyMortgageDetailsChanges(sourceMortgage, targetMortgage);

          await this.initMortgageTermComponent(targetMatter, i);
          this.applyMortgageTermChanges(sourceMortgage.mortgageTerm, targetMortgage.mortgageTerm);

          await this.initMortgageReportComponent(targetMatter, i);
          this.applyMortgageReportChanges(sourceMortgage, targetMortgage);

          let privateLenders = templateMatter.getPrivateLenders(sourceMortgage);
          if (privateLenders && privateLenders.length) {
            await this.initVtbMortgageeComponent(targetMatter, targetMatter.mortgages[ i ]);
            this.applyVtbMortgageeChanges(templateMatter, targetMatter, sourceMortgage, targetMortgage);
            if (this.vtbMortgageeComponent.matterPrivateLenders) {
              let primaryWrapper: MatterParticipantWrapper = this.vtbMortgageeComponent.matterPrivateLenders.find(item => item && item.matterParticipant.primary);
              if (primaryWrapper) {
                this.vtbMortgageeComponent.setAsPrimaryPrivateLender(primaryWrapper);
              }
            }
          }
          let gurantors = templateMatter.getGuarantors(sourceMortgage);
          if (gurantors && gurantors.length) {
            await this.initMortgageGuarantorComponentComponent(targetMatter, targetMatter.mortgages[ i ]);
            this.applyMortgageGuarantorChanges(templateMatter, targetMatter, sourceMortgage, targetMortgage);

          }

          this.applyMortgageUdfChanges(templateMatter, targetMatter, sourceMortgage, targetMortgage);
        }
      }
    }
  }

  private applyMortgageUdfChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage) {
    templateMatter.matterUserDefinedFields.filter(udf => udf.mortgageId == sourceMortgage.id && udf.fieldValue).forEach(projectUdf => {
      let targetUdf: UserDefinedField = targetMatter.matterUserDefinedFields.find(targetUdf => targetUdf.sourceUDFId == projectUdf.sourceUDFId && targetUdf.mortgageId == targetMortgage.id);
      if (targetUdf) {
        targetUdf.fieldValue = projectUdf.fieldValue;
      } else { //UDF doesn't exist in matter so it will be created
        targetUdf = new UserDefinedField(projectUdf);
        targetUdf.mortgageId = targetMortgage.id;
        targetMatter.matterUserDefinedFields.push(targetUdf);
      }
    });
  }

  applyVtbMortgageeChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    //Remove existing private lenders
    if (this.vtbMortgageeComponent.matterPrivateLenders && this.vtbMortgageeComponent.matterPrivateLenders.length) {
      this.vtbMortgageeComponent.deleteExistingVtbMortgagees();
    }
    //Add New Private Lenders
    let templateMatterPrivateLenders = templateMatter.getPrivateLenders(sourceMortgage);
    if (templateMatterPrivateLenders && templateMatterPrivateLenders.length) {
      templateMatterPrivateLenders.forEach((mp: MatterParticipant) => {
        let participant = new MatterParticipant();
        participant.primary = mp.primary;
        let cloneContact: Contact = new Contact();
        cloneContact.createNewContactClone(mp.contact);
        cloneContact.sourceContactId = null;
        cloneContact.snapshotFlag = true;
        this.vtbMortgageeComponent.silentAddVTBMortgagee(targetMatter, targetMortgage, participant, cloneContact);
      });
      if (this.vtbMortgageeComponent.isMoreThanOnePrivateLender() && sourceMortgage.mortgageCapacityType != 'SILENT') {
        targetMortgage.mortgageCapacityType = sourceMortgage.mortgageCapacityType;
      }
      if (sourceMortgage.lenderReline) {
        targetMortgage.lenderReline = sourceMortgage.lenderReline;
      }
    }
  }

  applyMortgageGuarantorChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    //Remove existing gurantors
    let existingGurantors = this.mortgageGuarantorComponent.getGuarantorsOrTransferors();
    if (existingGurantors && existingGurantors.length) {
      targetMatter.deleteAllMatterParticipantByRole('GUARANTOR', targetMortgage.id);
    }
    //Add New gurantors
    let templateMatterGurantors = templateMatter.getGuarantors(sourceMortgage);
    if (templateMatterGurantors && templateMatterGurantors.length) {
      templateMatterGurantors.forEach((mp: MatterParticipant) => {
        let cloneContact: Contact = new Contact();
        cloneContact.createNewContactClone(mp.contact);
        cloneContact.sourceContactId = null;
        cloneContact.snapshotFlag = true;
        this.mortgageGuarantorComponent.silentAddGurantor(cloneContact);
      });
      if (!sourceMortgage.guarantorPartyToMortgage) {
        targetMortgage.guarantorPartyToMortgage = sourceMortgage.guarantorPartyToMortgage;
      }
    }
  }

  applyExistingMortgageMortgageeChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    //Remove existing mortgagees
    let existingMortgagees = targetMatter.getMortgagees(targetMortgage);
    if (existingMortgagees && existingMortgagees.length) {
      existingMortgagees.forEach((mortgageeMp) => {
        if (this.mortgageeComponent.selectedMortgagees && this.mortgageeComponent.selectedMortgagees.length > 0) {
          let matterParticipantWrapper = this.mortgageeComponent.selectedMortgagees.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == mortgageeMp.matterParticipantId);
          if (matterParticipantWrapper) {
            this.mortgageeComponent.silentDeleteMortgagee(matterParticipantWrapper);
          }
        }
      });
    }

    //Add New Mortgagees
    this.mortgageeComponent.selectedMortgagees = [];
    templateMatter.getMortgagees(sourceMortgage).forEach((item) => {

      let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.dataModel = {};
      this.mortgageeComponent.silentAddMortgagee(item.contact, matterParticipantWrapper);
      this.updateMatterParticipant(matterParticipantWrapper.matterParticipant, item);
      this.mortgageeComponent.selectedMortgagees.push(matterParticipantWrapper);
      this.updateTemplateParticipantWithTargetParticipant(matterParticipantWrapper.matterParticipant, item);
    });
  }

  updateMatterParticipant(destinationMp: MatterParticipant, sourceMp: MatterParticipant): void {
    destinationMp.sourceContact = sourceMp.sourceContact;
    destinationMp.contact.idInfoEnteredBy = sourceMp.contact.idInfoEnteredBy;
    destinationMp.contact.contactIdInfoEnteredBy = sourceMp.contact.contactIdInfoEnteredBy;
    destinationMp.contact.enteredOn = sourceMp.contact.enteredOn;
    destinationMp.contact.sourceContactId = sourceMp.contact.sourceContactId;
    destinationMp.sourceContact = sourceMp.sourceContact;
    destinationMp.contact.lastSyncedFromSource = sourceMp.contact.lastSyncedFromSource;
    destinationMp.attentionName = sourceMp.attentionName;
  }

  async applyExistingMortgageChanges(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    let templateMortgages = templateMatter.existingMortgages;
    let targetMortgages = targetMatter.existingMortgages;
    for (let i = 0; i < targetMortgages.length; i++) {
      if (targetMortgages[ i ] && templateMortgages[ i ]) {
        let templateMortgage = templateMortgages[ i ];
        let targetMortgage = targetMortgages[ i ];

        await this.initExistingMortgageComponent(targetMatter, i);
        this.applyExistingMortgageDetailsChanges(templateMortgage, targetMortgage);
        this.applyMortgagePayoutChangesForExistingMortgage(templateMortgage, targetMortgage, targetMatter);

        let templateMatterUndertaking = this.getMortgageAssociatedUndertaking(templateMortgage, templateMatter);
        let targetMatterUndertaking = this.getMortgageAssociatedUndertaking(targetMortgage, targetMatter);
        if (templateMatterUndertaking && targetMatterUndertaking) {
          this.applyExistingMortgageUndertakingChanges(templateMatterUndertaking, targetMatterUndertaking);
        }

        await this.initMortgageeComponent(targetMatter, targetMortgage);

        if (templateMortgage.isMortgageeAnInstitution()) {
          let mortgagees = templateMatter.getMortgagees(templateMortgage);
          if (mortgagees && mortgagees.length) {
            await this.initAttentionInfoComponent(targetMortgage.id, this.mortgageeComponent.institutionMortgageeMatterParticipant, targetMatter, this.mortgageeComponent.attentionList);
            this.mortgageeComponent.attentionInfoComponent = this.attentionInfoComponent;
            this.applyExistingMortgageMortgageeChanges(templateMatter, targetMatter, templateMortgage, targetMortgage);
            if (templateMortgage.includeAuthorizeSignOfficer != DpBooleanValueTypes.N_y) {
              targetMortgage.includeAuthorizeSignOfficer = templateMortgage.includeAuthorizeSignOfficer;
              if (templateMortgage.includeAuthorizeSignOfficer == DpBooleanValueTypes.YES && targetMortgage.mortgageContactInfo && templateMortgage.mortgageContactInfo) {
                targetMortgage.mortgageContactInfo.additionalName1 = templateMortgage.mortgageContactInfo.additionalName1;
                targetMortgage.mortgageContactInfo.additionalName2 = templateMortgage.mortgageContactInfo.additionalName2;
                targetMortgage.mortgageContactInfo.titleOfOfficeHeld1 = templateMortgage.mortgageContactInfo.titleOfOfficeHeld1;
                targetMortgage.mortgageContactInfo.titleOfOfficeHeld2 = templateMortgage.mortgageContactInfo.titleOfOfficeHeld2;
              }
            }

            if (this.mortgageeComponent.isInstitutionMortgageeSelected && !this.mortgageeComponent.isLawFirmVisible()) {
              let mortgageeAttention = templateMatter.getMatterParticipantByRoleAndMortgage('MORTGAGEE_ATTENTION', templateMortgage);
              if (mortgageeAttention) {
                await this.initAttentionInfoComponent(targetMortgage.id, this.mortgageeComponent.institutionMortgageeMatterParticipant, targetMatter, this.mortgageeComponent.attentionList);
                this.applyAttentionChanges(templateMatter, targetMatter, templateMortgage, targetMortgage);

              }
            }

          }
        }

        if (templateMortgage.isMortgageePrivateLender) {
          let otherLenders = templateMatter.getPrivateLenders(templateMortgage);
          if (otherLenders && otherLenders.length) {
            this.applyOtherLenderChanges(templateMatter, targetMatter, templateMortgage, targetMortgage);
            if (this.mortgageeComponent.matterPrivateLenders) {
              let primaryWrapper: MatterParticipantWrapper = this.mortgageeComponent.matterPrivateLenders.find(item => item && item.matterParticipant.primary);
              if (primaryWrapper) {
                this.mortgageeComponent.setAsPrimaryPrivateLender(primaryWrapper);
              }
            }
            if (templateMortgage.mortgageCapacityType) {
              targetMortgage.mortgageCapacityType = templateMortgage.mortgageCapacityType;
              this.mortgageeComponent.onMortgageCapacityChange(targetMortgage.mortgageCapacityType);
            }
          }
        }
        if (templateMortgage.isMortgageCorrespondWithSolicitor()) {

          let mortgageLegalFirm = templateMatter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', templateMortgage);
          if (mortgageLegalFirm) {
            this.applyMortgageLegalFirmChanges(templateMatter, targetMatter, templateMortgage, targetMortgage);
          }

          let mortgageSolicitor = templateMatter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', templateMortgage);
          if (mortgageSolicitor) {
            this.applyMortgageSolicitorChanges(templateMatter, targetMatter, templateMortgage, targetMortgage);
          }
        }

        this.applyMortgageUdfChanges(templateMatter, targetMatter, templateMortgage, targetMortgage);
      }
    }

  }

  applyMortgageLegalFirmChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    let sourceLegalFirm = templateMatter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', sourceMortgage);
    if (sourceLegalFirm) {
      this.mortgageeComponent.silentClearLawFirm();

      //Add New Mortgage Legal Firm
      let targetLegalFirm = targetMatter.addMatterParticipant(sourceLegalFirm.contact, true, 'MORTGAGE_LEGAL_FIRM', targetMortgage.id);
      this.updateTemplateParticipantWithTargetParticipant(targetLegalFirm, sourceLegalFirm);
    }

  }

  applyMortgageSolicitorChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage) {
    let templateMortgageSolicitor = templateMatter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', sourceMortgage);
    if (templateMortgageSolicitor && templateMortgageSolicitor.contact) {
      let targetMortgageSolicitor = targetMatter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', targetMortgage);
      if (targetMortgageSolicitor && targetMortgageSolicitor.contact) {
        this.matterParticipantService.removeParticipant(this.mortgageeComponent.selectedSolicitor, targetMatter, false);
      }
      targetMortgageSolicitor = targetMatter.addMatterParticipant(templateMortgageSolicitor.contact, true, 'MORTGAGE_SOLICITOR', targetMortgage.id);
      this.updateTemplateParticipantWithTargetParticipant(targetMortgageSolicitor, templateMortgageSolicitor);
    }

  }

  applyOtherLenderChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    //Remove existing other lenders
    let existingOtherLenders = targetMatter.getPrivateLenders(targetMortgage);
    if (existingOtherLenders && existingOtherLenders.length) {
      existingOtherLenders.forEach((mortgageeMp) => {
        if (this.mortgageeComponent.matterPrivateLenders && this.mortgageeComponent.matterPrivateLenders.length > 0) {
          let matterParticipantWrapper = this.mortgageeComponent.matterPrivateLenders.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == mortgageeMp.matterParticipantId);
          if (matterParticipantWrapper) {
            this.mortgageeComponent.silentDeletePrivateLender(matterParticipantWrapper);
          }
        }
      });
    }

    //Add New Private Lenders
    this.mortgageeComponent.matterPrivateLenders = [];
    templateMatter.getPrivateLenders(sourceMortgage).forEach((item) => {

      let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.dataModel = {};
      this.mortgageeComponent.silentAddPrivateLender(item.contact, matterParticipantWrapper);
      matterParticipantWrapper.primary = item.primary;
      this.mortgageeComponent.matterPrivateLenders.push(matterParticipantWrapper);
      this.updateTemplateParticipantWithTargetParticipant(matterParticipantWrapper.matterParticipant, item);
    });
  }

  applyAttentionChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    //Remove existing attention
    let existingAttention = targetMatter.getMatterParticipantByRoleAndMortgage('MORTGAGEE_ATTENTION', targetMortgage);
    if (existingAttention) {
      this.attentionInfoComponent.deleteAttention();
    }
    //Add New Attention

    let templateMatterAttention = templateMatter.getMatterParticipantByRoleAndMortgage('MORTGAGEE_ATTENTION', sourceMortgage);
    if (templateMatterAttention) {
      let cloneContact: Contact = new Contact();
      cloneContact.createNewContactClone(templateMatterAttention.contact);
      cloneContact.sourceContactId = templateMatterAttention.contact.sourceContactId;
      cloneContact.snapshotFlag = true;
      this.attentionInfoComponent.setContactInAttentionWrapper(cloneContact, true, false);
      this.attentionInfoComponent.attentionList.push(cloneContact);
    }
  }

  applyExistingMortgageDetailsChanges(templateMortgage: Mortgage, targetMortgage: Mortgage): void {
    if (templateMortgage.financingType) {
      targetMortgage.financingType = templateMortgage.financingType;
    }
    if (templateMortgage.mortgageDispositionType != 'QUESTION') {
      targetMortgage.mortgageDispositionType = templateMortgage.mortgageDispositionType;
      this.existingMortgageComponent.onMortgageDispositionTypeChange(targetMortgage, true);
    }
    if (templateMortgage.principalOfMortgageAssumed) {
      targetMortgage.principalOfMortgageAssumed = templateMortgage.principalOfMortgageAssumed;
    }
    if (templateMortgage.mortgageRequestNo) {
      targetMortgage.mortgageRequestNo = templateMortgage.mortgageRequestNo;
    }
    if (templateMortgage.mortgageRequestDate) {
      targetMortgage.mortgageRequestDate = templateMortgage.mortgageRequestDate;
    }
    if (templateMortgage.assignmentRequestNo) {
      targetMortgage.assignmentRequestNo = templateMortgage.assignmentRequestNo;
    }
    if (templateMortgage.mortgageeType) {
      targetMortgage.mortgageeType = templateMortgage.mortgageeType;
      this.existingMortgageComponent.onMortgageeTypeChange(targetMortgage);
    }
    if (templateMortgage.mortgageCorrespondenceType) {
      targetMortgage.mortgageCorrespondenceType = templateMortgage.mortgageCorrespondenceType;
    }
    if (templateMortgage.loanNo) {
      targetMortgage.loanNo = templateMortgage.loanNo;
    }
    if (templateMortgage.statementForInformation != DpBooleanValueTypes.Y_n) {
      targetMortgage.statementForInformation = templateMortgage.statementForInformation;
    }
    if (templateMortgage.amountPayableToDischarge) {
      targetMortgage.amountPayableToDischarge = templateMortgage.amountPayableToDischarge;
    }
    if (templateMortgage.dischargeRegisteredBy) {
      targetMortgage.dischargeRegisteredBy = templateMortgage.dischargeRegisteredBy;
    }
    if (templateMortgage.teranetSuggestedMortgagees) {
      targetMortgage.teranetSuggestedMortgagees = templateMortgage.teranetSuggestedMortgagees;
    }

    if (templateMortgage.teranetSuggestedMortgagees) {
      targetMortgage.teranetSuggestedMortgagees = templateMortgage.teranetSuggestedMortgagees;
    }
    if (templateMortgage.relatedInstruments && templateMortgage.relatedInstruments.length > 0 && this.isRelatedInstrumentDataExists(templateMortgage.relatedInstruments)) {
      targetMortgage.relatedInstruments = [];
      templateMortgage.relatedInstruments.forEach(item => {
        let relatedInstrumentTarget = new MortgageInstrument(item);
        relatedInstrumentTarget.id = undefined;
        targetMortgage.relatedInstruments.push(relatedInstrumentTarget);
      });
    }
    targetMortgage.undertakingDirty = true;
  }

  isRelatedInstrumentDataExists(relatedInstruments: MortgageInstrument[]): boolean {
    return relatedInstruments.some(relatedInstrument => !!relatedInstrument.instrumentType || !!relatedInstrument.registrationNumber || !!relatedInstrument.registrationDate);
  }

  applyMortgagePayoutChangesForExistingMortgage(templateMortgage: Mortgage, targetMortgage: Mortgage, targetMatter: Matter) {
    if (targetMortgage.mortgagePayout) {
      if (this.isMortgagePayoutCalculatorSelected(targetMortgage)) {
        if (templateMortgage.amountPayableToDischarge > 0) {
          targetMortgage.mortgagePayout.principalAmount = templateMortgage.mortgagePayout.amountPayableToDischarge;
        }
        if (this.isValidDate(templateMortgage.mortgagePayout.inclusiveOfInterestToDate)) {
          targetMortgage.mortgagePayout.principalDate = templateMortgage.mortgagePayout.inclusiveOfInterestToDate;
        }
      }
      if (this.isPayoutEqualsTrustBalanceSelected(targetMortgage)) {
        if (this.isValidDate(templateMortgage.mortgagePayout.inclusiveOfInterestToDate)) {
          targetMortgage.mortgagePayout.inclusiveOfInterestToDate = templateMortgage.mortgagePayout.inclusiveOfInterestToDate;
        }

      }
      if (!this.isMortgagePayoutCalculatorSelected(targetMortgage) && !this.isPayoutEqualsTrustBalanceSelected(targetMortgage)) {
        if (templateMortgage.amountPayableToDischarge > 0) {
          targetMortgage.mortgagePayout.amountPayableToDischarge = templateMortgage.mortgagePayout.amountPayableToDischarge;
        }
        if (this.isValidDate(templateMortgage.mortgagePayout.inclusiveOfInterestToDate)) {
          targetMortgage.mortgagePayout.inclusiveOfInterestToDate = templateMortgage.mortgagePayout.inclusiveOfInterestToDate;
        }
      }

    }
    if (!!templateMortgage.mortgagePayout.separateChequeForDischargeFee && templateMortgage.mortgagePayout.separateChequeForDischargeFee != 'N_y' && targetMatter.isMatterProvinceON) {
      targetMortgage.mortgagePayout.separateChequeForDischargeFee = templateMortgage.mortgagePayout.separateChequeForDischargeFee;
      targetMortgage.mortgagePayout.dischargeAmount = templateMortgage.mortgagePayout.dischargeAmount;
      targetMortgage.mortgagePayout.chequePayableTo = templateMortgage.mortgagePayout.chequePayableTo;
    }
    this.existingMortgageComponent.updateTrustLedgerAndMortgageDataOnPayoutChange(targetMortgage.mortgagePayout);

  }

  isMortgagePayoutCalculatorSelected(mortgage: Mortgage): boolean {
    return mortgage.mortgagePayout.useMortgagePayoutCalculator;
  }

  isPayoutEqualsTrustBalanceSelected(mortgage: Mortgage): boolean {
    return mortgage.mortgagePayout.payoutEqualsTrustBalance;
  }

  isValidDate(inclusiveOfInterestToDate: string): boolean {
    return this.existingMortgageComponent.formattedDate(inclusiveOfInterestToDate) != '';
  }

  applyExistingMortgageUndertakingChanges(templateMatterUndertaking: MatterUndertaking, targetMatterUndertaking: MatterUndertaking): void {
    if (templateMatterUndertaking.matterUndertakingStatus) {
      targetMatterUndertaking.matterUndertakingStatus = templateMatterUndertaking.matterUndertakingStatus;
    }
    if (templateMatterUndertaking.dischargedByInstrumentNo) {
      targetMatterUndertaking.dischargedByInstrumentNo = templateMatterUndertaking.dischargedByInstrumentNo;
    }
    if (templateMatterUndertaking.registeredDate) {
      targetMatterUndertaking.registeredDate = templateMatterUndertaking.registeredDate;
    }

  }

  getMortgageAssociatedUndertaking(mortgage, matter): MatterUndertaking {
    if (mortgage.isMortgageDispositionDischarged()) {
      if (matter && Array.isArray(matter.matterUndertakings)) {
        return matter.matterUndertakings.find(undertaking => undertaking.id == mortgage.undertakingId);
      }
    }
    return null;
  }

  applyMortgageDetailsChanges(templateMortgage: Mortgage, targetMortgage: Mortgage): void {
    targetMortgage.loanType = templateMortgage.loanType;
    this.mortgageDetailComponent.onMortgageLoanChange(targetMortgage);
    if (templateMortgage.insured != DpBooleanValueTypes.N_y) {
      targetMortgage.insured = templateMortgage.insured;
    }
    if (templateMortgage.autoIncludeVtbMortgageAmount != DpBooleanValueTypes.Y_n) {
      targetMortgage.autoIncludeVtbMortgageAmount = templateMortgage.autoIncludeVtbMortgageAmount;
    }
    if (templateMortgage.autoCreateVtbMortgageAdjustment != DpBooleanValueTypes.N_y) {
      targetMortgage.autoCreateVtbMortgageAdjustment = templateMortgage.autoCreateVtbMortgageAdjustment;
      this.mortgageDetailComponent.autoCreateVtbMortgageAdjustmentChanged();
    }
    if (templateMortgage.loanNo) {
      targetMortgage.loanNo = templateMortgage.loanNo;
    }
    if (templateMortgage.lienHoldback != DpBooleanValueTypes.N_y) {
      targetMortgage.lienHoldback = templateMortgage.lienHoldback;
    }
    if (templateMortgage.commitmentDate) {
      targetMortgage.commitmentDate = templateMortgage.commitmentDate;
    }
    if (templateMortgage.instructionsReceived != DpBooleanValueTypes.N_y) {
      targetMortgage.instructionsReceived = templateMortgage.instructionsReceived;
    }
    if (templateMortgage.finalReportSubmitted != DpBooleanValueTypes.N_y) {
      targetMortgage.finalReportSubmitted = templateMortgage.finalReportSubmitted;
    }
    if (templateMortgage.preliminaryReportSubmitted != DpBooleanValueTypes.N_y) {
      targetMortgage.preliminaryReportSubmitted = templateMortgage.preliminaryReportSubmitted;
    }
    if (templateMortgage.requestForFundsSubmitted != DpBooleanValueTypes.N_y) {
      targetMortgage.requestForFundsSubmitted = templateMortgage.requestForFundsSubmitted;
    }

  }

  applyMortgageReportChanges(sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    let sourceMortgageReport = sourceMortgage.mortgageReport;
    let targetMortgageReport = targetMortgage.mortgageReport;

    if (sourceMortgageReport.mortgageRegistrationDate) {
      targetMortgageReport.mortgageRegistrationDate = sourceMortgageReport.mortgageRegistrationDate;
    }
    if (sourceMortgageReport.mortgageRegistrationNumber) {
      targetMortgageReport.mortgageRegistrationNumber = sourceMortgageReport.mortgageRegistrationNumber;
    }
    if (sourceMortgageReport.includeClause != DpBooleanValueTypes.N_y) {
      targetMortgageReport.includeClause = sourceMortgageReport.includeClause;
    }
    if (sourceMortgageReport.mortgagePrepaymentPrivilege) {
      targetMortgageReport.mortgagePrepaymentPrivilege = sourceMortgageReport.mortgagePrepaymentPrivilege;
      this.mortgageReportComponent.prepaymentPrivilegeChange(true);
    }
    if (sourceMortgageReport.pageNumber) {
      targetMortgageReport.pageNumber = sourceMortgageReport.pageNumber;
    }
    if (sourceMortgageReport.additionalClause) {
      targetMortgageReport.additionalClause = sourceMortgageReport.additionalClause;
    }
    if (sourceMortgage.finalEnclosures && sourceMortgage.finalEnclosures.length) {
      targetMortgage.finalEnclosures = [];
      sourceMortgage.finalEnclosures.forEach((finalEnclosure) => {
        targetMortgage.finalEnclosures.push(finalEnclosure);
      });
    }
    if (sourceMortgage.finalEnclosuresDefaultsOverridden) {
      targetMortgage.finalEnclosuresDefaultsOverridden = sourceMortgage.finalEnclosuresDefaultsOverridden;
    }
  }

  applyMortgageTermChanges(sourceMortgageTerm: MortgageTerm, targetMortgageTerm: MortgageTerm): void {
    if (sourceMortgageTerm.regularPayments != DpBooleanValueTypes.Y_n) {
      targetMortgageTerm.regularPayments = sourceMortgageTerm.regularPayments;
      this.mortgageTermComponent.updateRegularPayments();
    }
    if (sourceMortgageTerm.principal) {
      targetMortgageTerm.principal = sourceMortgageTerm.principal;
      this.mortgageTermComponent.mortgagePrincipalOnBlur();
    }
    if (sourceMortgageTerm.interestOnly != DpBooleanValueTypes.N_y) {
      targetMortgageTerm.interestOnly = sourceMortgageTerm.interestOnly;
      this.mortgageTermComponent.interestOnlyChanged();
    }
    if (sourceMortgageTerm.interest) {
      targetMortgageTerm.interest = sourceMortgageTerm.interest;
      this.mortgageTermComponent.interestValueChanged();
    }
    if (sourceMortgageTerm.variableRate) {
      targetMortgageTerm.variableRate = sourceMortgageTerm.variableRate;
    }
    if (sourceMortgageTerm.variableInterestRate != DpBooleanValueTypes.N_y) {
      targetMortgageTerm.variableInterestRate = sourceMortgageTerm.variableInterestRate;
      this.mortgageTermComponent.variableInterestRateChange();
    }
    if (sourceMortgageTerm.calculated) {
      targetMortgageTerm.calculated = sourceMortgageTerm.calculated;
      this.mortgageTermComponent.calculatedChanged();
    }
    if (sourceMortgageTerm.collateralMortgage != DpBooleanValueTypes.N_y) {
      targetMortgageTerm.collateralMortgage = sourceMortgageTerm.collateralMortgage;
    }
    if (sourceMortgageTerm.collateral) {
      targetMortgageTerm.collateral = sourceMortgageTerm.collateral;
    }
    if (sourceMortgageTerm.taxesPaidThroughMortgage != DpBooleanValueTypes.N_y) {
      targetMortgageTerm.taxesPaidThroughMortgage = sourceMortgageTerm.taxesPaidThroughMortgage;
      this.mortgageTermComponent.taxesPaidThroughMortgageChange();
    }
    if (sourceMortgageTerm.sctNo) {
      targetMortgageTerm.sctNo = sourceMortgageTerm.sctNo;
    }
    if (sourceMortgageTerm.other != DpBooleanValueTypes.N_y) {
      targetMortgageTerm.other = sourceMortgageTerm.other;
      this.mortgageTermComponent.otherMortgageChange();
    }
    if (sourceMortgageTerm.otherText) {
      targetMortgageTerm.otherText = sourceMortgageTerm.otherText;
    }
    if (sourceMortgageTerm.sctFiledBy) {
      targetMortgageTerm.sctFiledBy = sourceMortgageTerm.sctFiledBy;
    }
    if (sourceMortgageTerm.alternateFrequency != DpBooleanValueTypes.N_y) {
      targetMortgageTerm.alternateFrequency = sourceMortgageTerm.alternateFrequency;
      this.mortgageTermComponent.updateAlternateFrequency();
    }
    if (sourceMortgageTerm.assignmentOfRents) {
      targetMortgageTerm.assignmentOfRents = sourceMortgageTerm.assignmentOfRents;
    }
    if (sourceMortgageTerm.semiAnnualEquivalent) {
      targetMortgageTerm.semiAnnualEquivalent = sourceMortgageTerm.semiAnnualEquivalent;
    }
    if (sourceMortgageTerm.insuranceAmount != 'FULL_INSURABLE_VALUE') {
      targetMortgageTerm.insuranceAmount = sourceMortgageTerm.insuranceAmount;
    }
    if (sourceMortgageTerm.generalSecurityAgreement) {
      targetMortgageTerm.generalSecurityAgreement = sourceMortgageTerm.generalSecurityAgreement;
    }
    if (sourceMortgageTerm.paymentFrequency) {
      targetMortgageTerm.paymentFrequency = sourceMortgageTerm.paymentFrequency;
      this.mortgageTermComponent.setVariablestoCalculateMortgage();
    }
    if (sourceMortgageTerm.paymentsDue) {
      targetMortgageTerm.paymentsDue = sourceMortgageTerm.paymentsDue;
    }
    if (sourceMortgageTerm.interestAdjustmentDate) {
      targetMortgageTerm.interestAdjustmentDate = sourceMortgageTerm.interestAdjustmentDate;
      this.mortgageTermComponent.setMortageTerm();
    }
    if (sourceMortgageTerm.firstPaymentDate) {
      targetMortgageTerm.firstPaymentDate = sourceMortgageTerm.firstPaymentDate;
    }
    if (sourceMortgageTerm.maturityDate) {
      targetMortgageTerm.maturityDate = sourceMortgageTerm.maturityDate;
      this.mortgageTermComponent.setMortageTerm();
    }
    if (sourceMortgageTerm.regularPaymentAutoCalculated) {
      targetMortgageTerm.regularPaymentAutoCalculated = sourceMortgageTerm.regularPaymentAutoCalculated;
      this.mortgageTermComponent.regularPaymentAutoCalculatedChanged();
    }
    if (sourceMortgageTerm.regularPaymentAmortizationPeriod) {
      targetMortgageTerm.regularPaymentAmortizationPeriod = sourceMortgageTerm.regularPaymentAmortizationPeriod;
      this.mortgageTermComponent.setVariablestoCalculateMortgage();
    }
    if (sourceMortgageTerm.regularPayment) {
      targetMortgageTerm.regularPayment = sourceMortgageTerm.regularPayment;
      this.mortgageTermComponent.calculateTotal();
    }
    if (sourceMortgageTerm.taxPortion) {
      targetMortgageTerm.taxPortion = sourceMortgageTerm.taxPortion;
      this.mortgageTermComponent.calculateTotal();
    }
    if (sourceMortgageTerm.otherPayment) {
      targetMortgageTerm.otherPayment = sourceMortgageTerm.otherPayment;
      this.mortgageTermComponent.calculateTotal();
    }
    if (sourceMortgageTerm.alternatePaymentFrequency) {
      targetMortgageTerm.alternatePaymentFrequency = sourceMortgageTerm.alternatePaymentFrequency;
      this.mortgageTermComponent.setAltVariablestoCalculateMortgage();
    }
    if (sourceMortgageTerm.alternatePaymentsDue) {
      targetMortgageTerm.alternatePaymentsDue = sourceMortgageTerm.alternatePaymentsDue;
    }
    if (sourceMortgageTerm.alternateFirstPaymentDate) {
      targetMortgageTerm.alternateFirstPaymentDate = sourceMortgageTerm.alternateFirstPaymentDate;
    }
    if (sourceMortgageTerm.alternatePaymentAutoCalculated) {
      targetMortgageTerm.alternatePaymentAutoCalculated = sourceMortgageTerm.alternatePaymentAutoCalculated;
      this.mortgageTermComponent.alternatePaymentAutoCalculatedChanged();
    }
    if (sourceMortgageTerm.alternatePaymentAmortizationPeriod) {
      targetMortgageTerm.alternatePaymentAmortizationPeriod = sourceMortgageTerm.alternatePaymentAmortizationPeriod;
      this.mortgageTermComponent.setAltVariablestoCalculateMortgage();
    }
    if (sourceMortgageTerm.alternateRegularPayment) {
      targetMortgageTerm.alternateRegularPayment = sourceMortgageTerm.alternateRegularPayment;
      this.mortgageTermComponent.calculateAltTotal();
    }
    if (sourceMortgageTerm.alternateTaxPortion) {
      targetMortgageTerm.alternateTaxPortion = sourceMortgageTerm.alternateTaxPortion;
      this.mortgageTermComponent.calculateAltTotal();
    }
    if (sourceMortgageTerm.alternateOtherPayment) {
      targetMortgageTerm.alternateOtherPayment = sourceMortgageTerm.alternateOtherPayment;
      this.mortgageTermComponent.calculateAltTotal();
    }

  }

  async massUpdateBrokerCommission(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initBrokerCommissionComponent(targetMatter);
    let reCalculateTrustLedgerPaidToRealEstateBroker: boolean = false;
    if (templateMatter.realEstateAgent) {
      reCalculateTrustLedgerPaidToRealEstateBroker = true;
      if (targetMatter.realEstateAgent) {
        this.matterParticipantService.removeParticipant(this.brokerCommissionComponent.realEstateAgentWrapper, targetMatter, false);
      }
      if (targetMatter.realEstateBroker) {
        this.matterParticipantService.removeParticipant(this.brokerCommissionComponent.realEstateBrokerWrapper, targetMatter, false);
      }
      targetMatter.addMatterParticipant(templateMatter.realEstateAgent.contact, true, MatterParticipantRoleTypes.REALESTATEAGENT);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.realEstateAgent, templateMatter.realEstateAgent);
    }
    if (templateMatter.realEstateBroker) {
      reCalculateTrustLedgerPaidToRealEstateBroker = true;
      if (targetMatter.realEstateBroker) {
        this.matterParticipantService.removeParticipant(this.brokerCommissionComponent.realEstateBrokerWrapper, targetMatter, false);
      }
      targetMatter.addMatterParticipant(templateMatter.realEstateBroker.contact, true, MatterParticipantRoleTypes.REALESTATEBROKER);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.realEstateBroker, templateMatter.realEstateBroker);
    }
    if (templateMatter.purchaserRealEstateAgent) {
      reCalculateTrustLedgerPaidToRealEstateBroker = true;
      if (targetMatter.purchaserRealEstateAgent) {
        this.matterParticipantService.removeParticipant(this.brokerCommissionComponent.purchaserRealEstateAgentWrapper, targetMatter, false);
      }
      if (targetMatter.purchaserRealEstateBroker) {
        this.matterParticipantService.removeParticipant(this.brokerCommissionComponent.purchaserRealEstateBrokerWrapper, targetMatter, false);
      }
      targetMatter.addMatterParticipant(templateMatter.purchaserRealEstateAgent.contact, true, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEAGENT);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.purchaserRealEstateAgent, templateMatter.purchaserRealEstateAgent);
    }
    if (templateMatter.purchaserRealEstateBroker) {
      reCalculateTrustLedgerPaidToRealEstateBroker = true;
      if (targetMatter.purchaserRealEstateBroker) {
        this.matterParticipantService.removeParticipant(this.brokerCommissionComponent.purchaserRealEstateBrokerWrapper, targetMatter, false);
      }
      targetMatter.addMatterParticipant(templateMatter.purchaserRealEstateBroker.contact, true, MatterParticipantRoleTypes.OTHERPARTY_REALESTATEBROKER);
      this.updateTemplateParticipantWithTargetParticipant(targetMatter.purchaserRealEstateBroker, templateMatter.purchaserRealEstateBroker);
    }
    if (reCalculateTrustLedgerPaidToRealEstateBroker) {
      targetMatter.reCalculateTrustLedgerPaidToRealEstateBroker();
    }
    this.updateBrokerCommissionFields(templateMatter, targetMatter);

    this.updateDearAndAttention(templateMatter, targetMatter);
  }

  updateDearAndAttention(templateMatter, targetMatter) {
    //Update listing Broker Dear or Broker Attention of targetMatter if there is Broker Dear or Broker Attention in templateMatter
    //Update purchaser Broker Dear or Broker Attention of targetMatter if commissionPaidTo is 'BOTH_VENDOR_AND_PURCHASER_BROKER' and there is Broker Dear or Broker
    // Attention in templateMatter
    if (Array.isArray(templateMatter.brokersMatterLevelInfo) && templateMatter.brokersMatterLevelInfo.length > 0) {
      templateMatter.brokersMatterLevelInfo.forEach((info: BrokerMatterLevelInfo) => {
        if (info.brokerContactType === BrokerContactTypes.SELLER_BROKER
          || (info.brokerContactType === BrokerContactTypes.PURCHASER_BROKER
            && templateMatter.commissionPaidToBothVendorAndPurchaserBroker)) {
          if (info.dear) {
            MatterBrokerMatterLevelInfoUtil.getBrokersMatterLevelInfo(targetMatter, info.brokerContactType).dear = info.dear;
          }

          if (info.attention) {
            MatterBrokerMatterLevelInfoUtil.getBrokersMatterLevelInfo(targetMatter, info.brokerContactType).attention = info.attention;
          }
        }
      });

    }

    //If commissionPaidTo === 'VENDOR_BROKER_ONLY' in templateMatter, it means PurchaserBrokersMatterLevelInfo should be removed
    if (templateMatter.commissionPaidToVendorBrokerOnly) {
      MatterBrokerMatterLevelInfoUtil.removePurchaserBrokersMatterLevelInfo(targetMatter);
    }
  }

  /**
   * Update brokerCommission fields when the commissionPaidTo of templateMatter is BOTH_VENDOR_AND_PURCHASER_BROKER
   * and the target matter is aslo BOTH_VENDOR_AND_PURCHASER_BROKER.
   * All commission fields of templateMatter which are edited will be updated to commission fields of the target matter
   * @param templateMatter
   * @param targetMatter
   */
  updateCommissionWithBothMatterInBothBrokerON(templateMatter: Matter, targetMatter: Matter) {
    // If commissionBasedOnFixedPercentageOfSalePrice is not null, it should be update. Otherwise do not do any update.
    if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice
      && templateMatter.commissionBasedOnFixedPercentageOfSalePrice !== '') {

      // commissionBasedOnFixedPercentageOfSalePrice is 'NO'
      if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO) {
        if (templateMatter.brokerCommission.commissionBeforeHst) {
          targetMatter.brokerCommission.commissionBeforeHst = templateMatter.brokerCommission.commissionBeforeHst;
        }
        if (templateMatter.brokerCommission.vendorCommission.vendorBrokerCommission
          && templateMatter.brokerCommission.vendorCommission.vendorBrokerCommission != 0) {
          targetMatter.brokerCommission.vendorCommission.vendorBrokerCommission = templateMatter.brokerCommission.vendorCommission.vendorBrokerCommission;
          // if(this.brokerCommissionComponent) {
          //     this.brokerCommissionComponent.setCommissionBeforeHst(false);
          // }
        }
        if (templateMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission
          && templateMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission != 0) {
          targetMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission = templateMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission;
          // if(this.brokerCommissionComponent) {
          //     this.brokerCommissionComponent.setCommissionBeforeHst(false);
          // }
        }
      }
      // commissionBasedOnFixedPercentageOfSalePrice is 'YES'
      else if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.YES || this.isCommissionYesHstInclusiveOrYesNetSalePrice(templateMatter)) {
        if (templateMatter.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission
          && templateMatter.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission != 0) {
          targetMatter.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission = templateMatter.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission;
        }
        if (templateMatter.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission
          && templateMatter.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission != 0) {
          targetMatter.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission = templateMatter.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission;
        }
      }

      if (templateMatter.brokerCommission.rateOfHstOnCommission) {
        targetMatter.brokerCommission.rateOfHstOnCommission = templateMatter.brokerCommission.rateOfHstOnCommission;
      }
      if (templateMatter.brokerCommission.appliedTowardsPaymentOfCommission !== DpBooleanValueTypes.Y_n) {
        targetMatter.brokerCommission.appliedTowardsPaymentOfCommission = templateMatter.brokerCommission.appliedTowardsPaymentOfCommission;
      }
      //only update if non-default value is selected
      if (templateMatter.depositHeldBy != '') {
        targetMatter.depositHeldBy = templateMatter.depositHeldBy;
      }
    }
  }

  updateCommissionWithBothMatterInBothBrokerAB(templateMatter: Matter, targetMatter: Matter) {

    if (templateMatter.brokerCommission.rateOfHstOnCommission) {
      targetMatter.brokerCommission.rateOfHstOnCommission = templateMatter.brokerCommission.rateOfHstOnCommission;
    }

    if (templateMatter.brokerCommission.vendorCommission.vendorBrokerCommission
      && templateMatter.brokerCommission.vendorCommission.vendorBrokerCommission != 0) {
      targetMatter.brokerCommission.vendorCommission.vendorBrokerCommission = templateMatter.brokerCommission.vendorCommission.vendorBrokerCommission;
    }
    if (templateMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission
      && templateMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission != 0) {
      targetMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission = templateMatter.brokerCommission.purchaserCommission.purchaserBrokerCommission;
    }
    if (templateMatter.brokerCommission.appliedTowardsPaymentOfCommission !== DpBooleanValueTypes.Y_n) {
      targetMatter.brokerCommission.appliedTowardsPaymentOfCommission = templateMatter.brokerCommission.appliedTowardsPaymentOfCommission;
    }
    //only update if non-default value is selected
    if (templateMatter.depositHeldBy != '') {
      targetMatter.depositHeldBy = templateMatter.depositHeldBy;
    }
  }

  /**
   * Update brokerCommission fields when the commissionPaidTo of templateMatter is null(it means it is same with VENDOR_BROKER_ONLY)
   * and the target matter is  BOTH_VENDOR_AND_PURCHASER_BROKER.
   * All commission fields of templateMatter which are edited will be updated to commission fields of the target matter
   * @param templateMatter
   * @param targetMatter
   */
  updateCommissionWithTemplateInVendorOnlyTargetInBothON(templateMatter: Matter, targetMatter: Matter) {
    // If commissionBasedOnFixedPercentageOfSalePrice is not null, it should be update. Otherwise do not do any update.
    if (targetMatter.commissionBasedOnFixedPercentageOfSalePrice
      && targetMatter.commissionBasedOnFixedPercentageOfSalePrice !== '') {
      this.updateCommissionWithTemplateInVendorOnlyTargetInBoth(templateMatter, targetMatter);
    }
  }

  updateCommissionWithTemplateInVendorOnlyTargetInBothAB(templateMatter: Matter, targetMatter: Matter) {
    this.updateCommissionWithTemplateInVendorOnlyTargetInBoth(templateMatter, targetMatter);
  }

  updateCommissionWithTemplateInVendorOnlyTargetInBoth(templateMatter: Matter, targetMatter: Matter) {
    if (templateMatter.brokerCommission.rateOfHstOnCommission) {
      targetMatter.brokerCommission.rateOfHstOnCommission = templateMatter.brokerCommission.rateOfHstOnCommission;
    }
    if (templateMatter.brokerCommission.appliedTowardsPaymentOfCommission !== DpBooleanValueTypes.Y_n) {
      targetMatter.brokerCommission.appliedTowardsPaymentOfCommission = templateMatter.brokerCommission.appliedTowardsPaymentOfCommission;
    }
  }

  /**
   * so use the following rule
   *
   *                                                |template matter  commissionBasedOnFixedPercentageOfSalePrice
   * ---------------------------------------------|--------------------------------------------------------------------------------------------------------
   * target    matter                              |                         |'' or null|   'NO'   | 'YES'               | yesHstInclusiveLabel| yesNetLabel|
   *                                                |        '' or null       |no update |   'NO'   | 'YES'               | yesHstInclusiveLabel|
   * yesNetLabel|
   *                                                |        'NO'             |no update |   'NO'   | 'YES'               | yesHstInclusiveLabel|
   * yesNetLabel|
   * commissionBasedOnFixedPercentageOfSalePrice    |        'YES'             |no update |   'NO'   | 'YES'               | yesHstInclusiveLabel|
   * yesNetLabel|
   *                                                |    yesHstInclusiveLabel |no update |   'NO'   | yesHstInclusiveLabel| yesHstInclusiveLabel|
   * yesNetLabel|
   *                                                |    yesNetLabel             |no update |   'NO'   | yesNetLabel         | yesHstInclusiveLabel|
   * yesNetLabel|
   * @param templateMatter
   * @param targetMatter
   */
  updateCommissionBasedOnFixedPercentageOfSalePrice(templateMatter: Matter, targetMatter: Matter) {
    if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice
      && templateMatter.commissionBasedOnFixedPercentageOfSalePrice !== '') {
      if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO) {
        targetMatter.commissionBasedOnFixedPercentageOfSalePrice = templateMatter.commissionBasedOnFixedPercentageOfSalePrice;
      } else if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.YES) {
        if (!targetMatter.commissionBasedOnFixedPercentageOfSalePrice
          || targetMatter.commissionBasedOnFixedPercentageOfSalePrice === ''
          || targetMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO) {
          targetMatter.commissionBasedOnFixedPercentageOfSalePrice = templateMatter.commissionBasedOnFixedPercentageOfSalePrice;
        }
      } else if (this.isCommissionYesHstInclusiveOrYesNetSalePrice(templateMatter)) {
        targetMatter.commissionBasedOnFixedPercentageOfSalePrice = templateMatter.commissionBasedOnFixedPercentageOfSalePrice;
      }
    }
  }

  /**
   * Update brokerCommission fields when the commissionPaidTo of templateMatter is VENDOR_BROKER_ONLY
   * In this situation, the target matter will set to VENDOR_BROKER_ONLY.
   * All commission fields of templateMatter which are edited will be updated to commission fields of the target matter
   * @param templateMatter
   * @param targetMatter
   */
  updateCommissionWithBothMatterInVendorOnlyON(templateMatter: Matter, targetMatter: Matter) {
    // If commissionBasedOnFixedPercentageOfSalePrice is not null, it should be update. Otherwise do not do any update.
    if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice
      && templateMatter.commissionBasedOnFixedPercentageOfSalePrice !== '') {
      // commissionBasedOnFixedPercentageOfSalePrice is 'NO'
      if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO) {
        if (templateMatter.brokerCommission.commissionBeforeHst) {
          targetMatter.brokerCommission.commissionBeforeHst = templateMatter.brokerCommission.commissionBeforeHst;
        }
      }
      // commissionBasedOnFixedPercentageOfSalePrice is 'YES'
      else if (templateMatter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.YES || this.isCommissionYesHstInclusiveOrYesNetSalePrice(templateMatter)) {
        if (templateMatter.brokerCommission.totalCommissionPercentage) {
          targetMatter.brokerCommission.totalCommissionPercentage = templateMatter.brokerCommission.totalCommissionPercentage;
        }
      }
      if (templateMatter.brokerCommission.rateOfHstOnCommission) {
        targetMatter.brokerCommission.rateOfHstOnCommission = templateMatter.brokerCommission.rateOfHstOnCommission;
      }
      if (templateMatter.brokerCommission.appliedTowardsPaymentOfCommission !== DpBooleanValueTypes.Y_n) {
        targetMatter.brokerCommission.appliedTowardsPaymentOfCommission = templateMatter.brokerCommission.appliedTowardsPaymentOfCommission;
      }

    }
  }

  /**
   * Update brokerCommission fields when the commissionPaidTo of templateMatter is VENDOR_BROKER_ONLY
   * In this situation, the target matter will set to VENDOR_BROKER_ONLY.
   * All commission fields of templateMatter which are edited will be updated to commission fields of the target matter
   * @param templateMatter
   * @param targetMatter
   */
  updateCommissionWithBothMatterInVendorOnlyAB(templateMatter: Matter, targetMatter: Matter) {
    if (templateMatter.brokerCommission.commissionBeforeHst) {
      targetMatter.brokerCommission.commissionBeforeHst = templateMatter.brokerCommission.commissionBeforeHst;
    }

    if (templateMatter.brokerCommission.rateOfHstOnCommission) {
      targetMatter.brokerCommission.rateOfHstOnCommission = templateMatter.brokerCommission.rateOfHstOnCommission;
    }
    if (templateMatter.brokerCommission.appliedTowardsPaymentOfCommission !== DpBooleanValueTypes.Y_n) {
      targetMatter.brokerCommission.appliedTowardsPaymentOfCommission = templateMatter.brokerCommission.appliedTowardsPaymentOfCommission;
    }

  }

  //Commission Based on a Fixed Percentage of Sale Price is Yes-HST-inclusive... or Yes-net sale price...
  isCommissionYesHstInclusiveOrYesNetSalePrice(templateMatter: Matter): boolean {
    return templateMatter && templateMatter.commissionBasedOnFixedPercentageOfSalePrice && templateMatter.commissionBasedOnFixedPercentageOfSalePrice.startsWith('Yes-');
  }

  needUpdateBrokerCommissionFields(templateMatter: Matter): boolean {
    if (templateMatter.brokerCommission && templateMatter.brokerCommission.vendorCommission && templateMatter.brokerCommission.purchaserCommission
      && (templateMatter.commissionPaidTo || (templateMatter.commissionBasedOnFixedPercentageOfSalePrice && templateMatter.commissionBasedOnFixedPercentageOfSalePrice !== ''))) {
      return true;
    }

    return false;
  }

  /**
   * Check targetMatter.brokerCommission, if it it null it should create it.
   * @param targetMatter
   * @returns {BrokerCommission|{id, totalCommissionPercentage, commissionBeforeHst, rateOfHstOnCommission, commissionInclHst, depositHeldBy,
   *     appliedTowardsPaymentOfCommission, vendorCommission, purchaserCommission}|any}
   */
  getTargetMatterBrokerCommission(targetMatter): BrokerCommission {
    if (!targetMatter.brokerCommission) {
      targetMatter.brokerCommission = new BrokerCommission(); //It also new vendorCommission and purchaserCommission
    } else {
      if (!targetMatter.brokerCommission.vendorCommission) {
        targetMatter.brokerCommission.vendorCommission = new VendorCommission();
      }
      if (!targetMatter.brokerCommission.purchaserCommission) {
        targetMatter.brokerCommission.purchaserCommission = new PurchaserCommission();
      }
    }
    if (!targetMatter.commissionPaidTo) {
      targetMatter.commissionPaidTo = constValues.commissionPaidTo.VENDOR_BROKER_ONLY;
    }
    return targetMatter.brokerCommission;
  }

  /**
   *
   * @param templateMatter
   * @param targetMatter
   */
  updateSeparateEntriesForCommissionPaidToTrustLedger(templateMatter: Matter, targetMatter: Matter): void {
    if (templateMatter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER
      && templateMatter.separateEntriesForCommissionPaidToTrustLedger !== DpBooleanValueTypes.Y_n) {
      targetMatter.separateEntriesForCommissionPaidToTrustLedger = templateMatter.separateEntriesForCommissionPaidToTrustLedger;
      targetMatter.reCalculateTrustLedgerPaidToRealEstateBroker();
    }
  }

  /**
   * TODO after statement of adjustment mass update finished, it will do enhancement.
   * For now,
   *  1. there are only "YES" and "NO" two values for commissionBasedOnFixedPercentageOfSalePrice and without sale price adjustment data of
   * templateMatter
   * 2. For recalculate all fields according to sale price adjustment data of targetMatter. In enhancement, we need mass update statement of adjustment
   * firstly, sale price adjustment data of targetMatter will update with templateMatter data. Then recalculate Broker Commission Fields base
   * on templateMatter data.
   *
   * @param templateMatter
   * @param targetMatter
   */
  updateBrokerCommissionFields(templateMatter: Matter, targetMatter: Matter) {
    if (templateMatter.isMatterProvinceON) {
      this.updateBrokerCommissionFieldsON(templateMatter, targetMatter);
    }
    if (templateMatter.isMatterProvinceAB) {
      this.updateBrokerCommissionFieldsAB(templateMatter, targetMatter);
    }
  }

  /**
   * TODO after statement of adjustment mass update finished, it will do enhancement.
   * For now,
   *  1. there are only "YES" and "NO" two values for commissionBasedOnFixedPercentageOfSalePrice and without sale price adjustment data of
   * templateMatter
   * 2. For recalculate all fields according to sale price adjustment data of targetMatter. In enhancement, we need mass update statement of adjustment
   * firstly, sale price adjustment data of targetMatter will update with templateMatter data. Then recalculate Broker Commission Fields base
   * on templateMatter data.
   *
   * @param templateMatter
   * @param targetMatter
   */
  updateBrokerCommissionFieldsON(templateMatter: Matter, targetMatter: Matter) {
    if (this.needUpdateBrokerCommissionFields(templateMatter)) {
      this.getTargetMatterBrokerCommission(targetMatter);
      this.updateCommissionBasedOnFixedPercentageOfSalePrice(templateMatter, targetMatter);
      // Need update the commissionPaidTo of target matter
      if (templateMatter.commissionPaidTo) {
        // Update commissionPaidTo
        targetMatter.commissionPaidTo = templateMatter.commissionPaidTo;
        //update Commission Fields when commissionBasedOnFixedPercentageOfSalePrice is not ''
        if ((templateMatter.commissionBasedOnFixedPercentageOfSalePrice && templateMatter.commissionBasedOnFixedPercentageOfSalePrice !== '')) {
          if (templateMatter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY) {
            this.updateCommissionWithBothMatterInVendorOnlyON(templateMatter, targetMatter);
          } else if (templateMatter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
            this.updateCommissionWithBothMatterInBothBrokerON(templateMatter, targetMatter);
          }
        }
      }
        // Not need the commissionPaidTo of target matter and keep the old value. So it can be the commissionPaidTo values are different between target
      // matter and template matter. It sounds a little weird. However TC does this way.
      else {
        //update Commission Fields when commissionBasedOnFixedPercentageOfSalePrice is not ''
        if ((templateMatter.commissionBasedOnFixedPercentageOfSalePrice && templateMatter.commissionBasedOnFixedPercentageOfSalePrice !== '')) {
          if (targetMatter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY) {
            this.updateCommissionWithBothMatterInVendorOnlyON(templateMatter, targetMatter);
            if (this.brokerCommissionComponent && templateMatter.commissionBasedOnFixedPercentageOfSalePrice == DpBooleanValueTypes.NO) {
              this.brokerCommissionComponent.calculateVendorOnlyFieldsForMassUpdate();
            }
          } else if (targetMatter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
            this.updateCommissionWithTemplateInVendorOnlyTargetInBothON(templateMatter, targetMatter);
          }
        }
      }

      MatterCleanUpUtil.cleanUpBrokerCommission(targetMatter);
      if (this.brokerCommissionComponent) {
        this.brokerCommissionComponent.setTotalCommissionPercentage(false);
        this.brokerCommissionComponent.setCommissionBeforeHst(false);
        this.brokerCommissionComponent.onCommissionBasedOnFixedPercentageOfSalePriceChange(false);
      }
    }

    //Do separateEntriesForCommissionPaidToTrustLedger update
    this.updateSeparateEntriesForCommissionPaidToTrustLedger(templateMatter, targetMatter);
  }

  updateBrokerCommissionFieldsAB(templateMatter: Matter, targetMatter: Matter) {
    this.getTargetMatterBrokerCommission(targetMatter);
    // Need update the commissionPaidTo of target matter
    if (templateMatter.commissionPaidTo) {
      // Update commissionPaidTo
      targetMatter.commissionPaidTo = templateMatter.commissionPaidTo;
      if (templateMatter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY) {
        this.updateCommissionWithBothMatterInVendorOnlyAB(templateMatter, targetMatter);
      } else if (templateMatter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
        this.updateCommissionWithBothMatterInBothBrokerAB(templateMatter, targetMatter);
      }
    }
      // Not need the commissionPaidTo of target matter and keep the old value. So it can be the commissionPaidTo values are different between target
    // matter and template matter. It sounds a little weird. However TC does this way.
    else {
      //update Commission Fields when commissionBasedOnFixedPercentageOfSalePrice is not ''
      if (targetMatter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY) {
        this.updateCommissionWithBothMatterInVendorOnlyAB(templateMatter, targetMatter);
        if (this.brokerCommissionComponent) {
          this.brokerCommissionComponent.calculateVendorOnlyFieldsForMassUpdate();
        }
      } else if (targetMatter.commissionPaidTo === constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
        this.updateCommissionWithTemplateInVendorOnlyTargetInBothAB(templateMatter, targetMatter);
      }
    }

    MatterCleanUpUtil.cleanUpBrokerCommission(targetMatter);
    if (this.brokerCommissionComponent) {
      this.brokerCommissionComponent.setTotalCommissionPercentage(false);
      this.brokerCommissionComponent.setCommissionBeforeHst(false);
      this.brokerCommissionComponent.updateCommissionPayableToVendorBroker();
    }

    //Do separateEntriesForCommissionPaidToTrustLedger update
    this.updateSeparateEntriesForCommissionPaidToTrustLedger(templateMatter, targetMatter);
  }

  updateTemplateParticipantWithTargetParticipant(targetParticipant: MatterParticipant, templateParticipant: MatterParticipant) {
    this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(targetParticipant, templateParticipant);
  }

  async initERegConsiderationComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.considerationLttComponent) {
        this.considerationLttComponent.setLocalInstanceMatter(targetMatter);
        this.considerationLttComponent.considerationLtt = targetMatter.considerationLtt;
        this.considerationLttComponent.loadConsiderationTaxRates();
      }
    });
  }

  async massUpdateERegConsiderationFields(templateMatter: Matter, targetMatter: Matter): Promise<any[]> {
    let errorMessages: any[] = [];
    if (this.isERegConsiderationSelectedForMassUpdate(templateMatter)) {

      await this.initERegConsiderationComponent(targetMatter);
      if (!this.isMatterApplicableForMassUpdate(templateMatter, targetMatter)) {
        errorMessages.push({
          matterId: targetMatter.id,
          errorMessage: 'Matter ' + targetMatter.matterRecordNumber + ' – failed to mass update EReg Considerations.'
        });
      } else {
        if (templateMatter.considerationLtt) {
          if (templateMatter.considerationLtt.completionOfTaxInfoType) {
            targetMatter.considerationLtt.completionOfTaxInfoType = templateMatter.considerationLtt.completionOfTaxInfoType;
            if (templateMatter.considerationLtt.completionOfTaxInfoType === 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2'
              || templateMatter.considerationLtt.completionOfTaxInfoType === 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE') {
              targetMatter.considerationLtt.mortgagesAssumed = 0;
              targetMatter.considerationLtt.mortgagesGivenBackToVendor = 0;
              targetMatter.considerationLtt.propertyTransferredInExchange = 0;
              targetMatter.considerationLtt.otherConsiderationSubjectToTax = 0;
              targetMatter.considerationLtt.fairMarketValueOfLands = 0;
              targetMatter.considerationLtt.valueOfAllChattles = 0;
              targetMatter.considerationLtt.otherConsiderationNotInFOrG = 0;
            }
            if (templateMatter.considerationLtt.completionOfTaxInfoType === 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2'
              || templateMatter.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY'
              || templateMatter.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE') {
              if (templateMatter.considerationLtt.moniesPaidOrToBePaidIn > 0) {
                targetMatter.considerationLtt.moniesPaidOrToBePaidIn = templateMatter.considerationLtt.moniesPaidOrToBePaidIn;
              }
            } else if (templateMatter.considerationLtt.completionOfTaxInfoType === 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE') {
              targetMatter.considerationLtt.moniesPaidOrToBePaidIn
                = this.considerationLttComponent.considerationLtt.getTotalNetSalePrice(targetMatter.ignoreCreditsInDeedConsiderationInON,
                targetMatter.isProjectHstReductionInSalePriceAdjustment, this.considerationLttComponent.soaConsiderationTaxes);

            }

            if (templateMatter.considerationLtt.mortgagesAssumed > 0) {
              targetMatter.considerationLtt.mortgagesAssumed = templateMatter.considerationLtt.mortgagesAssumed;
            }
            if (templateMatter.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE'
              || templateMatter.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY') {
              if (templateMatter.considerationLtt.mortgagesGivenBackToVendor > 0) {
                targetMatter.considerationLtt.mortgagesGivenBackToVendor = templateMatter.considerationLtt.mortgagesGivenBackToVendor;
              } else {
                targetMatter.recalculateProjectSaleConsiderationGivenBackToVendor();
              }
            } else {
              if (templateMatter.considerationLtt.mortgagesGivenBackToVendor > 0) {
                targetMatter.considerationLtt.mortgagesGivenBackToVendor = templateMatter.considerationLtt.mortgagesGivenBackToVendor;
              }
            }
            if (templateMatter.considerationLtt.propertyTransferredInExchange > 0) {
              targetMatter.considerationLtt.propertyTransferredInExchange = templateMatter.considerationLtt.propertyTransferredInExchange;
            }
            if (templateMatter.considerationLtt.otherConsiderationSubjectToTax > 0) {
              targetMatter.considerationLtt.otherConsiderationSubjectToTax = templateMatter.considerationLtt.otherConsiderationSubjectToTax;
            }
            if (templateMatter.considerationLtt.fairMarketValueOfLands > 0) {
              targetMatter.considerationLtt.fairMarketValueOfLands = templateMatter.considerationLtt.fairMarketValueOfLands;
            }
            if (templateMatter.considerationLtt.valueOfAllChattles > 0) {
              targetMatter.considerationLtt.valueOfAllChattles = templateMatter.considerationLtt.valueOfAllChattles;
            }
            if (templateMatter.considerationLtt.otherConsiderationNotInFOrG > 0) {
              targetMatter.considerationLtt.otherConsiderationNotInFOrG = templateMatter.considerationLtt.otherConsiderationNotInFOrG;
            }

          }

          targetMatter.considerationLtt.calculateSubjectValueAndTotalConsideration(this.considerationLttComponent.ontarioTaxRateSlab,
            this.considerationLttComponent.torontoTaxRateSlab,
            this.considerationLttComponent.considerationLtt.getTotalNetSalePrice(targetMatter.ignoreCreditsInDeedConsiderationInON,
              targetMatter.isProjectHstReductionInSalePriceAdjustment, this.considerationLttComponent.soaConsiderationTaxes));
        }

      }
    }
    return errorMessages;
  }

  isERegConsiderationSelectedForMassUpdate(templateMatter: Matter): boolean {
    return templateMatter.considerationLtt
      && this.isNotEmpty(templateMatter.considerationLtt.completionOfTaxInfoType)
      && (templateMatter.considerationLtt.completionOfTaxInfoType != 'DEFAULT');
  }

  isMatterApplicableForMassUpdate(templateMatter: Matter, targetMatter: Matter) {
    let totalValue = (
      Number(templateMatter.considerationLtt.mortgagesAssumed > 0 ? templateMatter.considerationLtt.mortgagesAssumed : targetMatter.considerationLtt.mortgagesAssumed)
      + Number(templateMatter.considerationLtt.mortgagesGivenBackToVendor > 0 ? templateMatter.considerationLtt.mortgagesGivenBackToVendor : targetMatter.considerationLtt.mortgagesGivenBackToVendor)
      + Number(templateMatter.considerationLtt.propertyTransferredInExchange > 0 ? templateMatter.considerationLtt.propertyTransferredInExchange : targetMatter.considerationLtt.propertyTransferredInExchange)
      + Number(templateMatter.considerationLtt.otherConsiderationSubjectToTax > 0 ? templateMatter.considerationLtt.otherConsiderationSubjectToTax : targetMatter.considerationLtt.otherConsiderationSubjectToTax)
      + Number(templateMatter.considerationLtt.fairMarketValueOfLands > 0 ? templateMatter.considerationLtt.fairMarketValueOfLands : targetMatter.considerationLtt.fairMarketValueOfLands));
    let chattelsIncludedInSalePrice = Utils.getNumberOrDefault(targetMatter.considerationLtt.salePriceAdjustment.chattelsIncludedInSalePrice, 0);
    let moniesPaidOrToBePaidIn = Number(targetMatter.considerationLtt.salePriceAdjustment
      .totalNetSalePrice(this.considerationLttComponent.soaConsiderationTaxes.hstFederalPortion, this.considerationLttComponent.soaConsiderationTaxes.hstProvincialPortion))
      - Number(chattelsIncludedInSalePrice) - Number(totalValue);
    return templateMatter.considerationLtt && targetMatter.considerationLtt && moniesPaidOrToBePaidIn >= 0;
  }

  async massUpdateStatementOfAdjustment(templateMatter: Matter, targetMatter: Matter): Promise<any[]> {

    let errorMessages: any[] = [];
    let adjLinkedToBeRemoved: number[] = [];
    let adjLinkedToBeAdded: number[] = [];
    await this.initStatementAdjustmentComponent(targetMatter);

    let newOrStatusUpdatedInterimAdj: any[] = [];
    templateMatter.interimStatementAdjustments.concat(templateMatter.interimStatementAdjustmentsUnApplied)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      if (!templateMatterAdj.sourceProjectAdjustmentId) {
        newOrStatusUpdatedInterimAdj.push(templateMatterAdj);
      } else if (templateMatterAdj.applyToAdjustmentRecord !== null) {
        let isAdjustmentApplied = targetMatter.interimStatementAdjustments.some(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId == templateMatterAdj.sourceProjectAdjustmentId);
        if (!isAdjustmentApplied) {
          newOrStatusUpdatedInterimAdj.push(templateMatterAdj);
        }
      }
    });

    let newOrStatusUpdatedFinalAdj: any[] = [];
    templateMatter.finalStatementAdjustments.concat(templateMatter.finalStatementAdjustmentsUnApplied)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      if (!templateMatterAdj.sourceProjectAdjustmentId) {
        newOrStatusUpdatedFinalAdj.push(templateMatterAdj);
      } else if (templateMatterAdj.applyToAdjustmentRecord !== null) {
        let isAdjustmentApplied = targetMatter.finalStatementAdjustments.some(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId == templateMatterAdj.sourceProjectAdjustmentId);
        if (!isAdjustmentApplied) {
          newOrStatusUpdatedFinalAdj.push(templateMatterAdj);
        }
      }
    });

    // restore Both from massUpdate when I and F only are on matter
    templateMatter.allStatementAdjustments
    .filter((adj: StatementAdjustment) => adj.applyToAdjustmentRecord !== null)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      targetMatter.allStatementAdjustments
      .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId === templateMatterAdj.sourceProjectAdjustmentId)
      .forEach(targetMatterAdj => {
        if (!targetMatterAdj.linkId && !!templateMatterAdj.linkId) {
          if (adjLinkedToBeAdded.indexOf(templateMatterAdj.linkId) < 0) {
            adjLinkedToBeAdded.push(templateMatterAdj.linkId);
          }
        }
      });
    });

    adjLinkedToBeAdded.forEach(linkId => {
      templateMatter.interimStatementAdjustments
      .filter(adj => adj.linkId == linkId)
      .forEach(adj => {
        if (targetMatter.interimStatementAdjustments.findIndex(targetMatterAdj => adj.sourceProjectAdjustmentId === targetMatterAdj.sourceProjectAdjustmentId) < 0) {
          let newAdj = this.statementAdjustmentComponent.addMassUpdateAdjustment(adj, adj.fieldCode, targetMatter);
          newAdj.linkId = linkId;
          newAdj.sourceProjectAdjustmentId = adj.sourceProjectAdjustmentId;
          newAdj.adjustmentStatus = ProgressionStatus.INTERIM;
          let idx = this.findMassUpdateAdjIndex(newAdj, templateMatter.interimStatementAdjustments, targetMatter.interimStatementAdjustments);
          targetMatter.interimStatementAdjustments.splice(idx + 1, 0, newAdj);
          this.findAndCreateAdjustmentOrder(adj, newAdj, templateMatter, targetMatter);
        }
        targetMatter.allStatementAdjustments
        .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!adj.sourceProjectAdjustmentId && adj.sourceProjectAdjustmentId === targetMatterAdj.sourceProjectAdjustmentId)
        .forEach(targetMatterAdj => targetMatterAdj.linkId = linkId);

      });
      templateMatter.finalStatementAdjustments
      .filter(adj => adj.linkId == linkId)
      .forEach(adj => {
        if (targetMatter.finalStatementAdjustments.findIndex(targetMatterAdj => adj.sourceProjectAdjustmentId === targetMatterAdj.sourceProjectAdjustmentId) < 0) {
          let newAdj = this.statementAdjustmentComponent.addMassUpdateAdjustment(adj, adj.fieldCode, targetMatter);
          newAdj.linkId = linkId;
          newAdj.sourceProjectAdjustmentId = adj.sourceProjectAdjustmentId;
          newAdj.adjustmentStatus = ProgressionStatus.FINAL;
          let idx = this.findMassUpdateAdjIndex(newAdj, templateMatter.finalStatementAdjustments, targetMatter.finalStatementAdjustments);
          targetMatter.finalStatementAdjustments.splice(idx + 1, 0, newAdj);
          this.findAndCreateAdjustmentOrder(adj, newAdj, templateMatter, targetMatter);
        }
        targetMatter.allStatementAdjustments
        .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!adj.sourceProjectAdjustmentId && adj.sourceProjectAdjustmentId === targetMatterAdj.sourceProjectAdjustmentId)
        .forEach(targetMatterAdj => targetMatterAdj.linkId = linkId);
      });
      // trying to address the issue where if an adj gets moved from B to F (from the interim SOA sheet) the link to source points to the project Interim adj still
      templateMatter.interimStatementAdjustments
      .filter(adj => adj.linkId == linkId)
      .forEach(adj => {
        let duplicates = targetMatter.interimStatementAdjustments
        .filter(targetMatterAdj => targetMatterAdj.linkId == linkId)
        .filter(targetMatterAdj => targetMatterAdj.sourceProjectAdjustmentId !== adj.sourceProjectAdjustmentId);
        if (Array.isArray(duplicates)) {
          duplicates.forEach(duplicAdj => {
              this.statementAdjustmentComponent.soaUtils.deleteAdjustmentAndUpdateSOADisplay(duplicAdj, true);
            }
          );
        }
      });

      templateMatter.finalStatementAdjustments
      .filter(adj => adj.linkId == linkId)
      .forEach(adj => {
        let duplicates = targetMatter.finalStatementAdjustments
        .filter(targetMatterAdj => targetMatterAdj.linkId == linkId)
        .filter(targetMatterAdj => targetMatterAdj.sourceProjectAdjustmentId !== adj.sourceProjectAdjustmentId);
        if (Array.isArray(duplicates)) {
          duplicates.forEach(duplicAdj => {
              this.statementAdjustmentComponent.soaUtils.deleteAdjustmentAndUpdateSOADisplay(duplicAdj, true);
            }
          );
        }
      });

    });

    console.log('massUpdateStatementOfAdjustment: ' + targetMatter.allStatementAdjustments);
    // if needed move back adjustment as per mass update
    templateMatter.interimStatementAdjustments
    .filter((adj: StatementAdjustment) => adj.applyToAdjustmentRecord !== null && !adj.linkId)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      let fnlStmtnAdj = targetMatter.finalStatementAdjustments
      .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId === templateMatterAdj.sourceProjectAdjustmentId)
      .find(targetMatterAdj => !targetMatterAdj.linkId);

      if (fnlStmtnAdj) {
        _.remove(targetMatter.finalStatementAdjustments, (adj) => adj.id == fnlStmtnAdj.id);
        fnlStmtnAdj.adjustmentStatus = ProgressionStatus.INTERIM;

        targetMatter.interimStatementAdjustments.push(fnlStmtnAdj);
      }
    });

    templateMatter.finalStatementAdjustments
    .filter((adj: StatementAdjustment) => adj.applyToAdjustmentRecord !== null && !adj.linkId)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      let intrmStmtnAdj = targetMatter.interimStatementAdjustments
      .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId === templateMatterAdj.sourceProjectAdjustmentId)
      .find(targetMatterAdj => !targetMatterAdj.linkId);

      if (intrmStmtnAdj) {
        _.remove(targetMatter.interimStatementAdjustments, (adj) => adj.id == intrmStmtnAdj.id);
        intrmStmtnAdj.adjustmentStatus = ProgressionStatus.FINAL;

        targetMatter.finalStatementAdjustments.push(intrmStmtnAdj);
      }
    });

    //debugger;
    // change back B to F/I as per mass update
    templateMatter.allStatementAdjustments
    .filter((adj: StatementAdjustment) => adj.applyToAdjustmentRecord !== null)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      targetMatter.allStatementAdjustments
      .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId === templateMatterAdj.sourceProjectAdjustmentId)
      .forEach(targetMatterAdj => {
        if (!!targetMatterAdj.linkId && !templateMatterAdj.linkId) {
          let linkedAdj = targetMatter.getLinkedAdjustment(targetMatterAdj);
          if (linkedAdj && adjLinkedToBeRemoved.indexOf(linkedAdj.id) < 0) {
            adjLinkedToBeRemoved.push(linkedAdj.id);
          }
          targetMatterAdj.linkId = undefined;
        }
      });
    });

    // clean adj that were changed from Interim/Final to Both on matter level but not on mass update
    if (adjLinkedToBeRemoved.length > 0) {
      adjLinkedToBeRemoved.forEach(idOfAdjToBeRemoved => {
        targetMatter.interimStatementAdjustments
        .filter(adj => adj.id === idOfAdjToBeRemoved)
        .forEach(adj => this.statementAdjustmentComponent.soaUtils.deleteAdjustmentAndUpdateSOADisplay(adj, true));
        _.remove(targetMatter.interimStatementAdjustments, (adj) => adj.id === idOfAdjToBeRemoved);
        targetMatter.finalStatementAdjustments
        .filter(adj => adj.id === idOfAdjToBeRemoved)
        .forEach(adj => this.statementAdjustmentComponent.soaUtils.deleteAdjustmentAndUpdateSOADisplay(adj, true));
        _.remove(targetMatter.finalStatementAdjustments, (adj) => adj.id === idOfAdjToBeRemoved);
      });
    }

    // copy data
    targetMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
    templateMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
    // update adjustments that were defined at project level and not set as "No Change" at mass update level
    let copyInterimStatementAdjustments: any[] = [];
    templateMatter.interimStatementAdjustments
    .filter((adj: StatementAdjustment) => adj.applyToAdjustmentRecord !== null)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      targetMatter.interimStatementAdjustments
      .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId === templateMatterAdj.sourceProjectAdjustmentId)
      .forEach(targetMatterAdj => {
        copyInterimStatementAdjustments.push({targetMatterAdj: targetMatterAdj, templateMatterAdj: templateMatterAdj});
      });
    });

    if (copyInterimStatementAdjustments) {
      for (let item of copyInterimStatementAdjustments) {
        await this.copyAdjustment(item.targetMatterAdj, item.templateMatterAdj, targetMatter, templateMatter, errorMessages);
      }
    }

    targetMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
    templateMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
    let copyFinalStatementAdjustments: any[] = [];
    templateMatter.finalStatementAdjustments
    .filter((adj: StatementAdjustment) => adj.applyToAdjustmentRecord !== null)
    .forEach((templateMatterAdj: StatementAdjustment) => {
      targetMatter.finalStatementAdjustments
      .filter(targetMatterAdj => !!targetMatterAdj.sourceProjectAdjustmentId && !!templateMatterAdj.sourceProjectAdjustmentId && targetMatterAdj.sourceProjectAdjustmentId === templateMatterAdj.sourceProjectAdjustmentId)
      .forEach(targetMatterAdj => {
        copyFinalStatementAdjustments.push({targetMatterAdj: targetMatterAdj, templateMatterAdj: templateMatterAdj});

      });
    });

    if (copyFinalStatementAdjustments) {
      for (let item of copyFinalStatementAdjustments) {
        await this.copyAdjustment(item.targetMatterAdj, item.templateMatterAdj, targetMatter, templateMatter, errorMessages);
      }
    }

    // add new adjustments that were only added at the mass update level and NOT at the project level
    let anyNewMassUpdateInterimAdj: boolean = newOrStatusUpdatedInterimAdj.length > 0;
    let anyNewMassUpdateFinalAdj: boolean = newOrStatusUpdatedFinalAdj.length > 0;

    if (anyNewMassUpdateInterimAdj || anyNewMassUpdateFinalAdj) {

      if (anyNewMassUpdateInterimAdj) {
        targetMatter.adjustmentStatusMode = ProgressionStatus.INTERIM; // this is OK as the matter is most likely not open
        templateMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
        newOrStatusUpdatedInterimAdj
        .forEach(adj => {
          let newAdj = this.statementAdjustmentComponent.addMassUpdateAdjustment(adj, adj.fieldCode, targetMatter);

          if (this.canAdjustmentBeApplied(newAdj, targetMatter)) {
            let idx = this.findMassUpdateAdjIndex(adj, templateMatter.interimStatementAdjustments, targetMatter.interimStatementAdjustments);
            targetMatter.interimStatementAdjustments.splice(idx + 1, 0, newAdj);
            this.findAndCreateAdjustmentOrder(adj, newAdj, templateMatter, targetMatter);
          } else {
            let title = 'Newly Added';
            if (this.statementAdjustmentComponent.statementAdjustmentDisplayUtil) {
              title = this.statementAdjustmentComponent.statementAdjustmentDisplayUtil.getAdjustmentTile(newAdj, targetMatter.provinceCode);
            }
            errorMessages.push({
              matterId: targetMatter.id,
              errorMessage: 'Matter ' + targetMatter.matterRecordNumber + ' – there was a conflict on the Statement of Adjustments due to the ' + title + ' adjustment'
            });
          }
        });

        targetMatter.interimStatementAdjustments
        .forEach(targetMatterAdj => {
          let obsIntermAdjs = templateMatter.interimStatementAdjustments
          .filter(adj => targetMatterAdj.sourceProjectAdjustmentId == adj.sourceProjectAdjustmentId);
          if (Array.isArray(obsIntermAdjs) && obsIntermAdjs.length < 1) {
            targetMatter._interimAdjustments = targetMatter.interimStatementAdjustments.filter(item => item !== targetMatterAdj);
          }
        });

        for (let item of targetMatter.interimStatementAdjustments) {
          await this.statementAdjustmentComponent.soaFulfillmentService.applyAdjustmentUpdates(item, targetMatter, templateMatter);
        }
        await this.statementAdjustmentComponent.soaFulfillmentService.onSalePriceUpdate(templateMatter.considerationLtt.salePriceAdjustment, true);
        this.statementAdjustmentComponent.updateDisplayItemsAndDateUpdatesForAdjustment(targetMatter);
      }

      if (anyNewMassUpdateFinalAdj) {
        targetMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
        templateMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
        newOrStatusUpdatedFinalAdj
        .forEach(adj => {
          if (this.canAdjustmentBeApplied(adj, targetMatter)) {
            let newAdj = this.statementAdjustmentComponent.addMassUpdateAdjustment(adj, adj.fieldCode, targetMatter);
            let idx = this.findMassUpdateAdjIndex(adj, templateMatter.finalStatementAdjustments, targetMatter.finalStatementAdjustments);
            targetMatter.finalStatementAdjustments.splice(idx + 1, 0, newAdj);
            this.findAndCreateAdjustmentOrder(adj, newAdj, templateMatter, targetMatter);
          } else {
            let title = 'Newly Added';
            if (this.statementAdjustmentComponent.statementAdjustmentDisplayUtil) {
              title = this.statementAdjustmentComponent.statementAdjustmentDisplayUtil.getAdjustmentTile(adj, targetMatter.provinceCode);
            }
            errorMessages.push({
              matterId: targetMatter.id,
              errorMessage: 'Matter ' + targetMatter.matterRecordNumber + ' – there was a conflict on the Statement of Adjustments due to the ' + title + ' adjustment'
            });
          }

        });

        targetMatter.finalStatementAdjustments
        .forEach(targetMatterAdj => {
          let obsFinalAdjs = templateMatter.finalStatementAdjustments
          .filter(adj => targetMatterAdj.sourceProjectAdjustmentId == adj.sourceProjectAdjustmentId);
          if (Array.isArray(obsFinalAdjs) && obsFinalAdjs.length < 1) {
            targetMatter._finalAdjustments = targetMatter.finalStatementAdjustments.filter(item => item !== targetMatterAdj);
          }
        });

        for (let item of targetMatter.finalStatementAdjustments) {
          await this.statementAdjustmentComponent.soaFulfillmentService.applyAdjustmentUpdates(item, targetMatter, templateMatter);
        }
        await this.statementAdjustmentComponent.soaFulfillmentService.onSalePriceUpdate(templateMatter.considerationLtt.salePriceAdjustment, true);
        targetMatter.recalculateForm4Charges();
        this.statementAdjustmentComponent.updateDisplayItemsAndDateUpdatesForAdjustment(targetMatter);
      }
    }

    targetMatter.adjustmentStatusMode = ProgressionStatus.INTERIM;
    StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.statementOfAdjustments);
    StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.uniqueStatementAdjustments);
    await this.statementAdjustmentComponent.soaUtils.updateSalePriceConsiderationLTT();
    targetMatter.adjustmentStatusMode = ProgressionStatus.FINAL;
    StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.statementOfAdjustments);
    StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(targetMatter.considerationLtt.salePriceAdjustment, targetMatter.uniqueStatementAdjustments);
    await this.statementAdjustmentComponent.soaUtils.updateSalePriceConsiderationLTT();
    targetMatter.adjustmentStatusMode = targetMatter.selectedProgressionStatus;
    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(targetMatter);

    return errorMessages;
  }

  async copyAdjustment(targetMatterAdj: StatementAdjustment, templateMatterAdj: StatementAdjustment, targetMatter: Matter, templateMatter: Matter, errorMessages: any[]): Promise<void> {
    let ignoredFieldsOnCopy: string[] = [ 'id', 'identifier', 'fieldCode', 'sourceProjectAdjustmentId', 'salePriceAdjustmentHeadingId' ];
    if (targetMatterAdj.isSalePrice()) {
      if (templateMatterAdj.isSalePrice() && targetMatterAdj.isSalePrice()) {
        if (!StatementAdjustmentUtil.canSalePriceBeApplied(targetMatterAdj, templateMatterAdj, targetMatter, templateMatter)) {
          let title = 'Sale Price Adjustment';
          if (this.statementAdjustmentComponent.statementAdjustmentDisplayUtil) {
            title = this.statementAdjustmentComponent.statementAdjustmentDisplayUtil.getAdjustmentTile(targetMatterAdj, targetMatter.provinceCode);
          }
          errorMessages.push({
            matterId: targetMatter.id,
            errorMessage: 'Matter ' + targetMatter.matterRecordNumber + ' – there was a conflict on the Statement of Adjustments due to the ' + title + ' adjustment'
          });
        } else {
          await this.statementAdjustmentComponent.updateSalePriceAdjForNewMatterRecord(targetMatter, templateMatter);
          this.statementAdjustmentComponent.updateDisplayItemsAndDateUpdatesForAdjustment(targetMatter);
        }
      }
    } else if (targetMatterAdj.isDepositAdjustment()) {
      targetMatter.matterPropertyWithCondo.depositAmount = templateMatter.matterPropertyWithCondo.depositAmount;
      targetMatter.extraDepositConfig = templateMatter.extraDepositConfig;
      targetMatter.matterPropertyWithCondo.adjustmentFormat = templateMatter.matterPropertyWithCondo.adjustmentFormat;
      targetMatter.matterPropertyWithCondo.multipleDeposit = templateMatter.matterPropertyWithCondo.multipleDeposit;
      targetMatter.matterPropertyWithCondo.deposits = templateMatter.matterPropertyWithCondo.deposits;
      targetMatter.matterPropertyWithCondo.deposits.forEach(item => {
        item.id = undefined;
      });

      targetMatterAdj.copyFrom(templateMatterAdj, ignoredFieldsOnCopy);
      await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();
      this.statementAdjustmentComponent.updateDisplayItemsAndDateUpdatesForAdjustment(targetMatter);
    } else {
      targetMatterAdj.copyFrom(templateMatterAdj, ignoredFieldsOnCopy);
    }
  }

  // find which project inherited adj was before the newly added adj
  findMassUpdateAdjIndex(adj: StatementAdjustment, massUpdateAdjList: StatementAdjustment[], targetMatterAdjList: StatementAdjustment[]): number {
    let lastProjectAdjIndex: number = 0;
    if (Array.isArray(massUpdateAdjList) && massUpdateAdjList && Array.isArray(targetMatterAdjList) && targetMatterAdjList) {
      let massUpdtSourceProjectAdjustmentId: number = 0;
      for (let i: number = 0; i < massUpdateAdjList.length; i++) {
        if (massUpdateAdjList[ i ].fieldCode === adj.fieldCode) {
          break;
        }
        if (massUpdateAdjList[ i ].sourceProjectAdjustmentId) {
          massUpdtSourceProjectAdjustmentId = massUpdateAdjList[ i ].sourceProjectAdjustmentId;
        }
      }
      lastProjectAdjIndex = targetMatterAdjList.findIndex(adj => adj.sourceProjectAdjustmentId === massUpdtSourceProjectAdjustmentId);
    }
    return (lastProjectAdjIndex >= 0 ? lastProjectAdjIndex : 1);
  }

  findAndCreateAdjustmentOrder(adjustmentTemplate: StatementAdjustment, adjustmentTarget: StatementAdjustment, templateMatter: Matter, targetMatter: Matter): void {

    let statementAdjustmentOrdersTarget = adjustmentTarget.isAdjustmentStatusFinal() ? targetMatter.finalStatementAdjustmentOrders : targetMatter.interimStatementAdjustmentOrders;
    let statementAdjustmentOrdersTemplate = adjustmentTemplate.isAdjustmentStatusFinal() ? templateMatter.finalStatementAdjustmentOrders : templateMatter.interimStatementAdjustmentOrders;
    let orderList = statementAdjustmentOrdersTemplate.sort((a, b) => Number(a.adjustmentIndex) < Number(b.adjustmentIndex) ? -1 : Number(a.adjustmentIndex) > Number(b.adjustmentIndex) ? 1 : 0);
    let statementAdjustmentOrderTemplateIndex = orderList.findIndex(item => item.adjustmentId == adjustmentTemplate.id);
    if (statementAdjustmentOrderTemplateIndex > 0) {
      let statementAdjustmentOrderTemplate = statementAdjustmentOrdersTemplate[ statementAdjustmentOrderTemplateIndex - 1 ];
      if (statementAdjustmentOrderTemplate) {
        let statementAdjustmentOrderObject = statementAdjustmentOrdersTarget.find(adj => !!adj.sourceProjectAdjustmentId && adj.sourceProjectAdjustmentId === statementAdjustmentOrderTemplate.sourceProjectAdjustmentId);
        if (statementAdjustmentOrderObject) {
          this.statementAdjustmentComponent.soaUtils.createStatementAdjustmentOrder(adjustmentTarget, statementAdjustmentOrderObject);
        }
      }
    }
    if (!statementAdjustmentOrdersTarget.some(adj => !!adj.adjustmentId && adj.adjustmentId === adjustmentTarget.id)) {
      this.statementAdjustmentComponent.soaUtils.createStatementAdjustmentOrder(adjustmentTarget);
    }
  }

  massUpdateUnitTypes(customUnitTypes: string[], targetMatter: Matter): void {
    if (targetMatter.matterPropertyWithCondo.condominiumExpenses) {
      if (targetMatter.matterPropertyWithCondo.condominiumExpenses.length == 0) {
        targetMatter.matterPropertyWithCondo.condominiumExpenses = [ UnitLevelPlanUtil.createCondominiumExpense(), UnitLevelPlanUtil.createCondominiumExpense(), UnitLevelPlanUtil.createCondominiumExpense() ];
      }
      for (let i = 0; i < targetMatter.matterPropertyWithCondo.condominiumExpenses.length; i++) {
        if (customUnitTypes[ i ] && customUnitTypes[ i ].length > 0) {
          targetMatter.matterPropertyWithCondo.condominiumExpenses[ i ].condominiumUnitType = customUnitTypes[ i ];
        }
      }
    }
  }

  massProduceGSTHSTForms(formTemplates: FormTemplate[], targetMatter: Matter): void {
    let customTemplateNameMap: any[];
    for (let i: number = 0; i < formTemplates.length; i++) {
      customTemplateNameMap = [];
      customTemplateNameMap.push({
        customTemplateName: formTemplates[ i ].templateName,
        templateId: formTemplates[ i ].id,
        isForm: true,
        mortgageIndex: undefined
      });
      this.documentProductionService.produceDocument(DocumentProductionData.of(targetMatter.id, [ String(formTemplates[ i ].documentTemplate.docGenTemplateId) ], 'PDF', true, customTemplateNameMap)).subscribe();
    }
  }

  checkDocProductionCompleteAndCollectErrors(matterIds: number[], formTemplates: FormTemplate[], ErrorMessages: any[]) {
    let docProdSubscription: any[] = [];

    let checkDocumentProductionSubscription: any[] = [];
    let templateIds: string[] = formTemplates.map(formtemplate => {
      return String(formtemplate.documentTemplate.docGenTemplateId);
    });

    matterIds.forEach(matterId => {
      checkDocumentProductionSubscription.push(
        this.scanDocumentProductionStatusAndErrors(matterId,
          DocumentProductionData.of(matterId, templateIds, 'PDF'),
          templateIds)
      );
    });

    let anyDocProdError: boolean = false;
    this.lockScreenService.lockForUpdate = true;
    Observable.forkJoin(checkDocumentProductionSubscription).finally(() => {
      this.lockScreenService.lockForUpdate = false;
    }).subscribe((documentProductionErrors: boolean[]) => {
      documentProductionErrors.forEach((isError) => {
        if (isError) {
          anyDocProdError = true;
        }
      });
      if (anyDocProdError) {
        ErrorMessages.push('Some documents were not created');
      }
    });

  }

  scanDocumentProductionStatusAndErrors(matterId: number, documentProductionData: DocumentProductionData, docTemplateIds: any[]): Observable<boolean> {
    let errors: string[] = [];
    return this.documentProductionService.getDocuments(matterId).flatMap(data => {
      data.forEach((document: Document) => {
        (docTemplateIds).forEach(id => {
          let templateIdArr: string[] = id.toString().split('-');
          let currentAffidavitIdx: number = null;
          let currentHoldbackIdx: number = null;
          let currentMortgageIdx: number = null;
          if (templateIdArr && templateIdArr.length > 1) {
            if (document.currentAffidavitIndex != null) {
              currentAffidavitIdx = parseInt(templateIdArr[ 1 ]);
            } else if (document.currentHoldbackIndex != null) {
              currentHoldbackIdx = parseInt(templateIdArr[ 1 ]);
            } else {
              currentMortgageIdx = parseInt(templateIdArr[ 1 ]);
            }
          }

          let templateId = parseInt(templateIdArr[ 0 ]);
          if (document.id === templateId) {
            if (!this.isDocumentProduceReady(document) && this.isDocumentSubmitted(document)) {
              errors.push('The document ' + document.documentName + ' is currently opened by ' + document.lockedByUser.loginId +
                ' and cannot be overwritten. Please ask ' + document.lockedByUser.loginId + ' to close document and try again.');
            }
          }
        });
      });
      return Observable.of(errors.length > 0);
    });
  }

  isDocumentSubmitted(document: Document): boolean {
    let status = this.documentUtility.mapStatus(document.status, false);
    if (status === 'Submitted') {
      return true;
    }
    return false;
  }

  isDocumentProduceReady(document: Document) {
    let status = this.documentUtility.mapStatus(document.status, false);
    if ((status === 'Available' || status === '' || status === 'Failed' || status === 'Self-referencing-include-file') && !document.lockedByUser) {
      return true;
    }
    return false;
  }

  canAdjustmentBeApplied(statementAdjustment: StatementAdjustment, matter: Matter): boolean {

    let adjustmentCanBeApplied = true;
    // TODO: Need to add condition for Prorated Based on Percentage Interest adjustment
    if (matter && matter.considerationLtt && matter.considerationLtt.salePriceAdjustment && (statementAdjustment.isOtherFixed() || statementAdjustment.isOtherFixedPayableOnOccupancy() || statementAdjustment.isTarionWarranty() || statementAdjustment.isOtherProratedOnPercentageInterest() || statementAdjustment.isItemizedCreditToVendorPurchaser())) {

      let isOtherFixedAdjAvailable: boolean = StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(matter.statementOfAdjustments);
      let aTarionWarrantyWithConsiderationAlreadyExists: boolean = StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(matter.statementOfAdjustments);
      let isItemizedAdjAvailableTemplateMatter = StatementAdjustmentUtil.isItemizedAdjAvailableWithConsiderationForPruchaser(matter.statementOfAdjustments);
      let isHCRAAdjAvailableAndWithConsideration = StatementAdjustmentUtil.isHCRAAdjAvailableAndWithConsideration(matter.statementOfAdjustments);
      let isSaleAdjustmentUpdated = matter.considerationLtt.salePriceAdjustment.id == undefined;

      if ((statementAdjustment.isOtherFixed() || statementAdjustment.isOtherFixedPayableOnOccupancy() || statementAdjustment.isOtherProratedOnPercentageInterest()) && statementAdjustment.soAdjOtherFixed) {
        // Other Fixed Validation
        adjustmentCanBeApplied = !((!matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && statementAdjustment.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && isItemizedAdjAvailableTemplateMatter
            && statementAdjustment.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && StatementAdjustmentUtil.isManualAdditionalConsiderations(matter) && !isSaleAdjustmentUpdated));
      } else if (statementAdjustment.isTarionWarranty() && statementAdjustment.soAdjTarionWarranty) {
        // Tarion Validation
        adjustmentCanBeApplied = !((!matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && statementAdjustment.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && isItemizedAdjAvailableTemplateMatter
            && statementAdjustment.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && StatementAdjustmentUtil.isManualAdditionalConsiderations(matter) && !isSaleAdjustmentUpdated));
      } else if (statementAdjustment.isHCRAFee() && statementAdjustment.soAdjHCRAFee) {
        // Tarion Validation
        adjustmentCanBeApplied = !((!matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && statementAdjustment.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && isItemizedAdjAvailableTemplateMatter
            && statementAdjustment.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && StatementAdjustmentUtil.isManualAdditionalConsiderations(matter) && !isSaleAdjustmentUpdated));
      } else if (statementAdjustment.isItemizedCreditToVendorPurchaser() && statementAdjustment.soAdjItemizedCreditToVendorPurchaser) {

        adjustmentCanBeApplied = !((!matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && statementAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES')
          || (matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate() && (isOtherFixedAdjAvailable || aTarionWarrantyWithConsiderationAlreadyExists || isHCRAAdjAvailableAndWithConsideration)
            && statementAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES'));
      } else {
        return adjustmentCanBeApplied;
      }
    }
    return adjustmentCanBeApplied;
  }

  async massUpdateTaxRates(targetMatters: Matter[], massUpdateData: MassUpdateData) {
    if (Array.isArray(targetMatters) && targetMatters.length > 0) {
      for (let targetMatter of targetMatters) {
        await this.updateTaxRates(massUpdateData.data, targetMatter);
      }
    }
  }

  async updateTaxRates(matterTaxRates: MatterTaxRate[], targetMatter: Matter): Promise<void> {
    if (matterTaxRates && matterTaxRates.length) {
      for (let taxRate of matterTaxRates) {
        if (taxRate.isSoAccountTaxRate()) {
          this.updateSoAccountTaxRate(taxRate, targetMatter);
        }
        if (taxRate.isSupplementalTaskTaxRate()) {
          this.updateSupplementalTasksTaxRate(taxRate, targetMatter);
        }
        if (taxRate.isSoAdjustmentTaxRate()) {
          await this.updateSoAdjustmentTaxRate(taxRate, targetMatter);
        }
      }
    }
  }

  async updateSoAdjustmentTaxRate(taxRate: MatterTaxRate, targetMatter: Matter): Promise<void> {
    //save the currentMatter's soAdjustmentStatusMode for later restore
    const targetMatterCurrentAdjustmentStatusMode: string = targetMatter.soaTrustLedgerCollection.progressionStatus;

    //update the SoAdj's tax rate under specific status mode
    let considerationTax = new ConsiderationTaxes();
    considerationTax.hstRate = taxRate.hstRate;
    considerationTax.hstFederalPortion = taxRate.federalHstRate;
    considerationTax.hstProvincialPortion = taxRate.provincialHstRate;
    considerationTax.rateType = targetMatter.matterTaxType;//rateType is not changed
    await this.initStatementAdjustmentComponent(targetMatter);
    targetMatter.adjustmentStatusMode = taxRate.progressionStatus;//need to set the value after initComponent to avoid lost the value during init
    await this.statementAdjustmentComponent.soaFulfillmentService.fulfillTaxRateModal(considerationTax);

    //restore
    targetMatter.adjustmentStatusMode = targetMatterCurrentAdjustmentStatusMode;
  }

  updateSoAccountTaxRate(taxRate: MatterTaxRate, targetMatter: Matter) {
    if (targetMatter.soaTrustLedgerCollection) {
      let considerationTax = new ConsiderationTaxes();
      considerationTax.hstRate = taxRate.hstRate;
      considerationTax.hstFederalPortion = taxRate.federalHstRate;
      considerationTax.hstProvincialPortion = taxRate.provincialHstRate;
      //Get the current progressionStatus
      let currentProgressionStatus = targetMatter.soaTrustLedgerCollection.progressionStatus;
      targetMatter.soaTrustLedgerCollection.progressionStatus = taxRate.progressionStatus;
      targetMatter.updateSoAccountTaxRate(considerationTax);
      targetMatter.soaTrustLedgerCollection.updateFeeBasedOnAllIncPrice();
      //Put back the current progressionStatus
      targetMatter.soaTrustLedgerCollection.progressionStatus = currentProgressionStatus;
    }
  }

  updateSupplementalTasksTaxRate(taxRate: MatterTaxRate, targetMatter: Matter) {
    let soAccountSupplementalTasks = targetMatter.supplementalTasks.filter(st => st.matterSoas && st.matterSoas.length);
    if (soAccountSupplementalTasks && soAccountSupplementalTasks.length) {
      for (let supplementalTask of soAccountSupplementalTasks) {
        let supplementalTaskTaxRate = targetMatter.getSupplementalTaskTaxRate(supplementalTask.id);
        supplementalTaskTaxRate.hstRate = taxRate.hstRate;
        supplementalTaskTaxRate.federalHstRate = taxRate.federalHstRate;
        supplementalTaskTaxRate.provincialHstRate = taxRate.provincialHstRate;
      }
    }
  }

  async initDirectionReFundsComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.directionReFundsComponent) {
        this.directionReFundsComponent.setLocalInstanceMatter(matter);
        await this.directionReFundsComponent.ngOnInit();
      }
    });
  }

  async massUpdateDirectionRefundFields(templateMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initDirectionReFundsComponent(targetMatter);
    let interimModified: boolean = this.massUpdateData && this.massUpdateData.directionReFundDirty && (!!this.massUpdateData.directionReFundDirty.find(progression => progression === ProgressionStatus.INTERIM));
    if (interimModified && templateMatter._interimDirectionReFunds) {
      targetMatter._interimDirectionReFunds = [];
      targetMatter._interimDirectionReFunds = templateMatter._interimDirectionReFunds.map(dirReFund => {
        let newDirRefund = new DirectionReFund(dirReFund);
        newDirRefund.id = undefined;
        newDirRefund.matterId = targetMatter.id;
        return newDirRefund;
      });
      this.directionReFundsComponent.calculateBalancePayableTo(); // updates MortgagePayout amount payable if this is set "Check this box if payout amount is equal to the "Balance of Funds" from Direction re Funds topic"
    }

    let finalModified: boolean = this.massUpdateData && this.massUpdateData.directionReFundDirty && (!!this.massUpdateData.directionReFundDirty.find(progression => progression === ProgressionStatus.FINAL));
    if (finalModified && templateMatter._finalDirectionReFunds) {
      targetMatter._finalDirectionReFunds = [];
      targetMatter._finalDirectionReFunds = templateMatter._finalDirectionReFunds.map(dirReFund => {
        let newDirRefund = new DirectionReFund(dirReFund);
        newDirRefund.id = undefined;
        newDirRefund.matterId = targetMatter.id;
        return newDirRefund;
      });
      this.directionReFundsComponent.calculateBalancePayableTo(); // updates MortgagePayout amount payable info if this is set "Check this box if payout amount is equal to the "Balance of Funds" from Direction re Funds topic"
    }
  }

  massUpdateOtherHoldback(templateMatter: Matter, targetMatter: Matter) {
    let otherHoldbacks = templateMatter.holdbacks.filter(hb => hb.isOtherHoldback());
    if (otherHoldbacks && otherHoldbacks.length) {
      for (let otherHoldback of otherHoldbacks) {
        targetMatter.createHoldback(otherHoldback, HOLDBACK_TYPE.other);
      }
      if (targetMatter.soaTrustLedgerCollection) {
        targetMatter.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
      }
    }
  }

  checkMatterAdjustmentInformationErrors(adjustmentImportData: any, targetMatters: Matter[]): any[] {
    let errorMessages: any[] = [];
    if (adjustmentImportData && adjustmentImportData.uploadOption == ProjectAdjustmentImport.DEPOSIT_ADJUSTMENT_INFORMATION) {
      for (let targetMatter of targetMatters) {
        let deposits: any[] = adjustmentImportData.filteredDeposits.get(targetMatter.fileNumber);
        if (!targetMatter.matterPropertyWithCondo.isDepositAdjustmentSingle) {
          let targetMatterDepositDates = targetMatter.matterPropertyWithCondo.deposits.map(tm => {
            return tm.depositDate;
          });
          let depositsAlreadyAvailable = deposits.filter(dp => targetMatterDepositDates.indexOf(dp.deposit.depositDate) > -1);
          if (depositsAlreadyAvailable.length) {
            if (!adjustmentImportData.errorMessages) {
              adjustmentImportData.errorMessages = [];
            }
            depositsAlreadyAvailable.forEach(dp => {
              adjustmentImportData.errorMessages.push({
                line: dp.line,
                row: dp.row,
                message: 'Deposit Date already exists on File Number ' + targetMatter.fileNumber
              });
            });
          }
        } else {
          deposits.forEach(dp => {
            adjustmentImportData.errorMessages.push({
              line: dp.line,
              row: dp.row,
              message: 'Multiple Deposit is set to "NO" on File Number ' + targetMatter.fileNumber
            });
          });
        }

      }
      if (adjustmentImportData.errorMessages && adjustmentImportData.errorMessages.length > 0) {
        errorMessages.push('....Rejected Lines');
        errorMessages.push(..._.sortBy(adjustmentImportData.errorMessages, 'line').map(item => {
          return ('Line:' + item.line + '  ' + item.message);
        }));
      }
    } else if (adjustmentImportData && adjustmentImportData.uploadOption == ProjectAdjustmentImport.SALE_PRICE_ADJUSTMENT_INFORMATION) {
      adjustmentImportData.rejectedMatterRecords = [];
      for (let targetMatter of targetMatters) {
        let salePriceUpdates: any[] = adjustmentImportData.salePriceDataToBeProcessed.filter(item => item.matterRecordNumber == targetMatter.fileNumber);
        salePriceUpdates.forEach(salePriceUpdate => {
          if ((salePriceUpdate.chattels || salePriceUpdate.additionalConsideration || salePriceUpdate.creditToPurchaser || salePriceUpdate.exclude || salePriceUpdate.buydownDescription
              || salePriceUpdate.buydownValue) && targetMatter.considerationLtt && targetMatter.considerationLtt.salePriceAdjustment
            && !targetMatter.considerationLtt.salePriceAdjustment.isInclusivePrice()) {
            if (!adjustmentImportData.errorMessages) {
              adjustmentImportData.errorMessages = [];
            }
            adjustmentImportData.errorMessages.push({
              line: salePriceUpdate.line,
              row: salePriceUpdate.row,
              message: 'Can not update Sale Price Adjustment as "Net out HST from HST inclusive sale price" is set to No for File Number ' + targetMatter.fileNumber
            });
          } else if (targetMatter.considerationLtt && targetMatter.considerationLtt.salePriceAdjustment
            && targetMatter.considerationLtt.salePriceAdjustment.isInclusivePrice() && salePriceUpdate.additionalConsideration
            && salePriceUpdate.additionalConsideration > 0 && (StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(targetMatter._finalAdjustments)
              || StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(targetMatter._interimAdjustments))) {
            adjustmentImportData.errorMessages.push({
              line: salePriceUpdate.line,
              row: salePriceUpdate.row,
              message: 'Can not update Sale Price Adjustment as Additional Consideration update has conflict on the Statement of Adjustments for File Number ' + targetMatter.fileNumber
            });
          }
        });

      }
      if (adjustmentImportData.errorMessages && adjustmentImportData.errorMessages.length > 0) {
        errorMessages.push('....Rejected Lines');
        errorMessages.push(..._.sortBy(adjustmentImportData.errorMessages, 'line').map(item => {
          return ('Line:' + item.line + '   ' + item.message);
        }));
      }
    }
    return errorMessages;
  }

  async importMatterAdjustmentInformation(targetMatter: Matter, adjustmentImportData: any): Promise<any[]> {
    let errorMessages: any[] = [];
    await this.initStatementAdjustmentComponent(targetMatter);
    if (adjustmentImportData && adjustmentImportData.uploadOption == ProjectAdjustmentImport.DEPOSIT_ADJUSTMENT_INFORMATION) {
      let deposits: any[] = adjustmentImportData.filteredDeposits.get(targetMatter.fileNumber).map(dp => {
          return new Deposit(dp);
        }
      );
      let targetMatterDepositDates = targetMatter.matterPropertyWithCondo.deposits.map(tm => {
        return tm.depositDate;
      });
      let filteredDeposits = deposits.filter(dp => targetMatterDepositDates.indexOf(dp.deposit.depositDate) < 0);
      if (this.statementAdjustmentComponent && filteredDeposits && filteredDeposits.length > 0 && !targetMatter.matterPropertyWithCondo.isDepositAdjustmentSingle) {
        filteredDeposits.forEach(item => {
          let deposit = new Deposit(item.deposit);
          if (!deposit.depositName) {
            deposit.updateDepositName(targetMatter.matterPropertyWithCondo ? targetMatter.matterPropertyWithCondo.adjustmentFormat : undefined);
          }
          targetMatter.addDepositToPrimaryProperty(deposit);
        });
        targetMatter.matterPropertyWithCondo.depositAmount = Deposit.calculateTotalDepositsIncludingExtras(targetMatter.matterPropertyWithCondo.deposits);
        await this.statementAdjustmentComponent.soaFulfillmentService.onDepositAdjustmentUpdate(({
          depositAmount: targetMatter.matterPropertyWithCondo.depositAmount,
          multipleDeposit: targetMatter.matterPropertyWithCondo.multipleDeposit,
          payDepositOutOfTrust: targetMatter.matterPropertyWithCondo.payDepositOutOfTrust,
          adjustmentFormat: targetMatter.matterPropertyWithCondo.adjustmentFormat,
          deposits: targetMatter.matterPropertyWithCondo.deposits,
          extraDepositConfig: targetMatter.extraDepositConfig
        } as DepositModalContextValue));
        if (!targetMatter.isSelectedAdjustmentStatusModeFinal) {
          let adjustmentStatusMode = targetMatter.adjustmentStatusMode;
          await this.statementAdjustmentComponent.soaUtils.updateAdjustmentType('Final');
          await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();
          await this.statementAdjustmentComponent.soaUtils.updateAdjustmentType('Interim');
          targetMatter.adjustmentStatusMode = adjustmentStatusMode;
        }
        await this.statementAdjustmentComponent.soaUtils.recalculateDepositSOAOnAdjustmentChanges();
        await this.statementAdjustmentComponent.soaUtils.updateDisplayItems();
      }

    } else if (adjustmentImportData && adjustmentImportData.uploadOption == ProjectAdjustmentImport.SALE_PRICE_ADJUSTMENT_INFORMATION) {

      let salePriceUpdates: any[] = adjustmentImportData.salePriceDataToBeProcessed.filter(item => item.matterRecordNumber == targetMatter.fileNumber);
      let spAdjustment: SalePriceAdjustment = targetMatter.considerationLtt && targetMatter.considerationLtt.salePriceAdjustment;
      let salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(targetMatter.adjustmentStatusMode, targetMatter.provinceCode, spAdjustment);
      let rejectedLines = [];
      if (adjustmentImportData.errorMessages) {
        rejectedLines.push(...adjustmentImportData.errorMessages.map(em => {
          return em.line;
        }));
      }
      let salePriceUpdatesToBeProcessed = salePriceUpdates.filter(spu => rejectedLines.indexOf(spu.line) < 0);
      if (salePriceUpdatesToBeProcessed && salePriceUpdatesToBeProcessed.length) {
        salePriceUpdatesToBeProcessed.forEach(salePriceUpdate => {
          if (!!salePriceUpdate.salePrice) {
            salePriceAdjustment.agreementSalePrice = salePriceUpdate.salePrice;
          }
          if (!!salePriceUpdate.chattels) {
            salePriceAdjustment.chattelsIncludedInSalePrice = salePriceUpdate.chattels;
          }
          if (!!salePriceUpdate.additionalConsideration) {
            salePriceAdjustment.additionalConsiderationsInclHst = salePriceUpdate.additionalConsideration;
            salePriceAdjustment.isMatterAdjustmentModeFinal() ? salePriceAdjustment.interimAdditionalConsiderationsInclHst = salePriceUpdate.additionalConsideration : salePriceAdjustment.finalAdditionalConsiderationsInclHst = salePriceUpdate.additionalConsideration;
          }
          if (!!salePriceUpdate.creditToPurchaser) {
            salePriceAdjustment.creditsToPurchaserInclHst = salePriceUpdate.creditToPurchaser;
          }
          if (!!salePriceUpdate.exclude) {
            salePriceAdjustment.excludeFromTax = salePriceUpdate.exclude;
          }
          if (!!salePriceUpdate.buydownDescription) {
            salePriceAdjustment.buyDowns = salePriceUpdate.buydownDescription;
          }
          if (!!salePriceUpdate.buydownValue && !!salePriceAdjustment.buyDowns) {
            salePriceAdjustment.otherItems = salePriceUpdate.buydownValue;
          }
        });
        await this.statementAdjustmentComponent.soaFulfillmentService.onSalePriceUpdateInterimAndFinal(salePriceAdjustment, false);
        await this.statementAdjustmentComponent.updateDisplayItems();
      }
    }

    return errorMessages;
  }
}
