import {AccountingItemKeys} from '../../../admin/soa/soa-constants';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class SoaFee extends BaseEntity {

  id: number;
  nameOfFees: string;
  f9DefaultAmount: number;
  readOnly: boolean;
  isSelected: boolean;
  deleted: boolean;
  calculated: boolean;
  code: string;
  matterType: string;
  enabled: boolean;

  constructor(soaFees?: SoaFee) {
    super(soaFees);
  }

  isFeeForMortgage(): boolean {
    return (this.code == AccountingItemKeys[ AccountingItemKeys.FEE_ARRANGED_MORTGAGE ]);
  }

  isFeeForAdditionalMortgage(): boolean {
    return (this.code == AccountingItemKeys[ AccountingItemKeys.FEE_ADDITIONAL_MORTGAGE ]);
  }

  isFeeForPurchaseTxn(): boolean {
    return (this.code == AccountingItemKeys[ AccountingItemKeys.FEE_PURCHASE_TRANSACTION_KEY ]);
  }

  isFeeForSaleTxn(): boolean {
    return (this.code == AccountingItemKeys[ AccountingItemKeys.FEE_SALE_TRANSACTION_KEY ]);
  }

  isFeeForMortgageTxn(): boolean {
    return (this.code == AccountingItemKeys[ AccountingItemKeys.FEE_MORTGAGE_TRANSACTION_KEY ]);
  }

  isFeeForDischargeTxn(): boolean {
    return (this.code == AccountingItemKeys[ AccountingItemKeys.FEE_DISCHARGE_TRANSACTION_KEY ]);
  }

  isFeeForParalegal(): boolean {
    return (this.code === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_PURCHASE ] ||
      this.code === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_SALE ] ||
      this.code === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_MORTGAGE ]);
  }

  // used for allowing groups of fees that would not allow other types to be interspersed with other types
  isPartOfGroupedFees(): boolean {
    return (this.isFeeForPurchaseTxn() && this.matterType === 'PURCHASE') ||
      (this.isFeeForMortgageTxn() && this.matterType === 'MORTGAGE') ||
      (this.isFeeForSaleTxn() && this.matterType === 'SALE') ||
      (this.isFeeForMortgage() && this.matterType === 'PURCHASE') ||
      (this.isFeeForAdditionalMortgage() && this.matterType === 'MORTGAGE') ||
      this.isFeeForParalegal();
  }
}


