import {TeranetUser} from './teranet-user';

export class TeranetOrderInstrumentRequest {

  orderedDocuments: TeranetOrderDocument[];
  docketSystemId: number;
  lro: string;
  teranetUser: TeranetUser;
  analysisStillNeeded: boolean;
}

export class TeranetOrderDocument {
  documentId: number;
  instrumentNumber: string;
  documentType: string;
}
