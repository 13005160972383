import Utils from '../../shared-main/utils';
import {isEmpty} from '../shared/matter-util';
import {Matter} from '../shared/matter';
import {
  PTTExemption,
  PTTExemptionConfig,
  PropertyTransferTax
} from './property-transfer-tax';
import {PTTExemptionCode} from '..';

export const calculatePttAdditionalAmount = (matter: Matter): number => {
  if (
    matter.propertyTransferTax &&
    matter?.propertyModel?.purchasePrice > 0.0
  ) {
    const {purchasePrice} = matter.propertyModel;
    const participants = matter.isPurchaseBC
      ? getPurchasers(matter)
      : getBorrowers(matter);
    const additionalAmount = participants
    .filter(
      (buyer) =>
        buyer.pttAdditionalTaxPayable &&
        (!buyer.pttBcPnExemption ||
          (buyer.pttBcPnExemption && isEmpty(buyer.pttBcPnCertificateNo)))
    )
    .map((b) => {
      const pp = (b.propertyPercentBeingAcquired || 0) / 100;
      return Utils.roundCurrency(purchasePrice * pp * 0.2);
    })
    .reduce((p, c) => p + c, 0);
    return additionalAmount;
  }
  return 0;
};

export const calculateExemptionAmount = (
  matter: Matter,
  standardPtt: number,
  code: PTTExemptionCode,
  cfg: PTTExemptionConfig
): number => {
  if (code === 'NO_EXEMPTION') {
    return 0.0;
  }

  if (!code || (code !== 'NEWLY_BUILT_HOME' && code !== 'FTH')) {
    return 0;
  }

  const fmv = matter.propertyModel?.purchasePrice;
  if (fmv <= 0 || standardPtt <= 0.0) {
    return 0;
  }

  const participants = matter.isPurchaseBC
    ? getPurchasers(matter)
    : getBorrowers(matter);

  const additionalAmount = participants
  .filter(({pttExemptionCode}) => pttExemptionCode === code)
  .map(({propertyPercentBeingAcquired, pttExemptionCode}) =>
    calculateExemption(
      fmv,
      standardPtt,
      cfg[ pttExemptionCode ],
      propertyPercentBeingAcquired,
      code
    )
  )
  .reduce((p, c) => p + c, 0);
  return additionalAmount;
};

export const getBorrowers = (matter: Matter) => {
  return matter.matterParticipants.filter((i) => i.isMortgagor);
};
export const getPurchasers = (matter: Matter) => {
  return matter.matterParticipants.filter((i) => i.isPurchaser);
};

export const calculatePttNetAmount = (ptt: Partial<PropertyTransferTax>) => {
  const {grossAmount, exemptionAmount = 0.0, additionalAmount = 0.0} = ptt;
  return grossAmount - exemptionAmount + additionalAmount;
};

export const roundCurrency = (currency: number): number => {
  return currency == null || currency == undefined
    ? 0
    : Number.parseFloat(currency.toFixed(2));
};

function calculateExemption(
  fmv: number,
  standardPtt: number,
  exp: PTTExemption,
  propertyPercentBeingAcquired: number,
  pttExemptionCode: PTTExemptionCode
) {
  const part = (propertyPercentBeingAcquired || 0) / 100;
  const {
    maxExemption,
    firstThreshold,
    secondThreshold,
    thirdThreshold,
    thresholdFactor
  } = exp;

  if (pttExemptionCode === 'FTH') {
    if (fmv <= firstThreshold) {
      return Utils.roundCurrency(standardPtt * part);
    } else if (fmv > firstThreshold && fmv <= secondThreshold) {
      return Utils.roundCurrency(maxExemption * part);
    } else if (fmv > secondThreshold && fmv < thirdThreshold) {
      const res = ((thirdThreshold - fmv) / thresholdFactor) * (part * maxExemption);
      return Utils.roundCurrency(res);
    } else if (fmv >= thirdThreshold) {
      return 0.0;
    }
  } else if (pttExemptionCode === 'NEWLY_BUILT_HOME') {
    if (fmv <= firstThreshold) {
      return Utils.roundCurrency(standardPtt * part);
    } else if (fmv > firstThreshold && fmv < secondThreshold) {
      const res = ((secondThreshold - fmv) / thresholdFactor) * (part * standardPtt);
      return Utils.roundCurrency(res);
    } else if (fmv >= secondThreshold) {
      return 0.0;
    }
  } else {
    return 0.0;
  }
}
