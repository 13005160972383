import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {RentInterestRate} from '../../model/rent-interest-rate';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class RentInterestRateModalContext {
  rentInterestRate: RentInterestRate[];
}

@Component({
  selector: 'dp-rent-interest-rate-modal',
  templateUrl: './rent-interest-rate.modal.component.html',
  styleUrls: ['./rent-interest-rate.modal.component.scss'],
  providers: [DialogService]
})
export class RentInterestRateModalComponent extends ModalComponent<RentInterestRateModalContext> implements OnInit {
  theList: RentInterestRate[] = [];

  constructor(
    public dialog: MatDialogRef<RentInterestRateModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: RentInterestRateModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.theList = this.context.rentInterestRate;
  }

  close() {
    this.dialog.close();
  }
}
