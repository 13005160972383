import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementAdjustment} from '../statement-adjustment';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import Utils from '../../../shared-main/utils';

export class StatementAdjustmentDisplayInterimClosingBuilder {

  static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder, modalDisplay?: boolean): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let details1 = [];
    let details2 = [];
    let details3 = [];

    if (item.soAdjConsiderationPaidOnInterimClosing && !item.soAdjConsiderationPaidOnInterimClosing.isCreditPurchaserOnly()) {
      details1 = StatementAdjustmentDisplayInterimClosingBuilder.getDetails1(mainBuilder.matter.uniqueStatementAdjustments, mainBuilder, modalDisplay);
      details2 = StatementAdjustmentDisplayInterimClosingBuilder.getDetails2(mainBuilder.matter.uniqueStatementAdjustments, mainBuilder, modalDisplay);
      details3 = StatementAdjustmentDisplayInterimClosingBuilder.getDetails3(mainBuilder.matter.uniqueStatementAdjustments, mainBuilder, modalDisplay);

      if (!item.soAdjConsiderationPaidOnInterimClosing.isCreditPurchaserOnly()) {
        this.updateDetailsTitle(item, details1, sadItemDetails);
        this.updateDetailsTitle(item, details2, sadItemDetails);
        this.updateDetailsTitle(item, details3, sadItemDetails);
      }
    }

    // let amount : number = StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(mainBuilder.salePriceAdjustment, mainBuilder.matter.statementOfAdjustments, null);
    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Purchaser`, null));

    return sadItemDetails;
  }

  static updateDetailsTitle(item: StatementAdjustment, details: StatementOfAdjustmentDisplayItemLine[], sadItemDetails: StatementOfAdjustmentDisplayItemLine[]) {
    if (Array.isArray(details)) {
      if (item.soAdjConsiderationPaidOnInterimClosing.isItemizedAdjustment()) {
        details.forEach(item => {
          if (item.title) {
            item.title = Utils.convertToTitleCase(item.title);
          }
        });
      } else if (item.soAdjConsiderationPaidOnInterimClosing.isItemizedAdjustmentHeadingsUpperCase()) {
        details.forEach(item => {
          if (item.title) {
            item.title = item.title.toUpperCase();
          }
        });
      }

      sadItemDetails.push(...details);
    }
  }

  // this is used on the sale price adjustment modal to display related TarionWarranty adjustments -> not needed on SOA Preview
  static getDetails1(statementOfAdjustments: StatementAdjustment[], mainBuilder: StatementAdjustmentDisplayBuilder, modalDisplay?: boolean): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let total: number = 0;

    statementOfAdjustments
    .filter(soAdj => soAdj.isTarionWarranty()
      && soAdj.soAdjTarionWarranty
      && soAdj.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjTarionWarranty.isAdjustmentPaidByVendorOnInterimClosing == 'YES')
    .forEach(soAdjTarion => {
      if (modalDisplay) {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdjTarion) }`, `(${ mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFee) } + ${ soAdjTarion.soAdjTarionWarranty.taxType })  ${ mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax) }`,
          false, '', '1'));
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdjTarion) }`, mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax),
          false, '', '1'));
      }
      total += Number(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax);
    });

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed()
      && soAdj.soAdjOtherFixed
      && soAdj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`, `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.totalAmount) }`,
        false, '', '1'));
      total += Number(soAdj.soAdjOtherFixed.totalAmount);

    });
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy()
      && soAdj.soAdjOtherFixedPayableOnOccupancy
      && soAdj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
        `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount) }`,
        false, '', '1'));
      total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount);

    });

    let ctsp: number = (mainBuilder.matter.isProjectSale || mainBuilder.matter.templateForProject) && mainBuilder.matter.isMatterProvinceAB
      ? Number(mainBuilder.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes)
      : Number(mainBuilder.matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherProratedOnPercentageInterest()
      && soAdj.soAdjOtherProratedOnPercentageInterest
      && soAdj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjOtherProratedOnPercentageInterest.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
        `${ mainBuilder.getCurrency(soAdj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(ctsp)) }`,
        false, '', '1'));
      total += Number(soAdj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(ctsp));

    });
    if (modalDisplay) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`Total Additional Consideration (eligible for ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } Rebate), including ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) }`,
        mainBuilder.getCurrency(total), false));
    }
    return sadItemDetails;
  }

  // this is used on the sale price adjustment modal to display related TarionWarranty adjustments -> not needed on SOA Preview
  static getDetails2(statementOfAdjustments: StatementAdjustment[], mainBuilder: StatementAdjustmentDisplayBuilder, modalDisplay?: boolean): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let total: number = 0;

    statementOfAdjustments
    .filter(soAdj => soAdj.isTarionWarranty()
      && soAdj.soAdjTarionWarranty
      && soAdj.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjTarionWarranty.isAdjustmentPaidByVendorOnInterimClosing == 'YES')
    .forEach(soAdjTarion => {
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdjTarion) }`,
        mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax),
        false, '', '2'));
      total += Number(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax);
    });

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed()
      && soAdj.soAdjOtherFixed
      && soAdj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.totalAmount) }`,
          false, '', '2'));

        total += Number(soAdj.soAdjOtherFixed.totalAmount);
      }
    );
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy()
      && soAdj.soAdjOtherFixedPayableOnOccupancy
      && soAdj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount) }`,
          false, '', '2'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount);
      }
    );
    let ctsp: number = (mainBuilder.matter.isProjectSale || mainBuilder.matter.templateForProject) && mainBuilder.matter.isMatterProvinceAB
      ? Number(mainBuilder.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes)
      : Number(mainBuilder.matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherProratedOnPercentageInterest()
      && soAdj.soAdjOtherProratedOnPercentageInterest
      && soAdj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE'
      && soAdj.soAdjOtherProratedOnPercentageInterest.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(ctsp)) }`,
          false, '', '2'));

        total += Number(soAdj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(ctsp));
      }
    );
    if (modalDisplay) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`Total Additional Consideration (not eligible for ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } Rebate)`,
        mainBuilder.getCurrency(total), false));
    }

    return sadItemDetails;
  }

  static getDetails3(statementOfAdjustments: StatementAdjustment[], mainBuilder: StatementAdjustmentDisplayBuilder, modalDisplay?: boolean): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let total: number = 0;

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed()
      && soAdj.soAdjOtherFixed
      && soAdj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'
      && soAdj.soAdjOtherFixed.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.totalAmount) }`,
          false, '', '3'));

        total += Number(soAdj.soAdjOtherFixed.totalAmount);
      }
    );

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy()
      && soAdj.soAdjOtherFixedPayableOnOccupancy
      && soAdj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'
      && soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentPaidOnInterimClosing == 'YES')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ this.getAdjustmentHeading(soAdj) }`,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount) }`,
          false, '', '3'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount);
      }
    );

    if (modalDisplay) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`Total Additional Consideration (not eligible for ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } Rebate incl. ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) })`, mainBuilder.getCurrency(total)));
    }
    return sadItemDetails;
  }

  static getAdjustmentHeading(statementAdjustment: StatementAdjustment) {
    return statementAdjustment.soAdjHeading && statementAdjustment.soAdjHeading.adjustmentHeadingType === 'CUSTOM' ? statementAdjustment.soAdjHeading.headingText : statementAdjustment.description;
  }

}
