import {Tab} from './tab';
import {Account} from '../../admin/accounts/shared/account';
import {SupplementalTaskCategory} from '../../shared-main/supplemental-task-category/supplemental-task-category';
import {SupplementalTaskCategoryComponent} from '../../admin/supplemental-task-categories/supplemental-task-category.component';
import {ChecklistTemplateConfig} from '../../admin/checklist-templates/checklist-template-config';

export class ConfigTab extends Tab {
  supplementalTaskCategoryComponent: SupplementalTaskCategoryComponent;
  supplementalTaskCategory: SupplementalTaskCategory;

  constructor(src?: ConfigTab) {
    super(src);

    if (src) {
      for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
          this[ prop ] = src[ prop ];
        }
      }

      src.account && (this.account = new Account(src.account));

    }

  }

  createSupplementalTaskCategoryTab(supplementalTaskCategory: SupplementalTaskCategory, account: Account): ConfigTab {
    // this is overwritten in subclasses
    return null;
  }

  createChecklistTemplateConfigTab(checklistTemplateConfig: ChecklistTemplateConfig, account: Account): ConfigTab {
    // this is overwritten in subclasses
    return null;
  }

}
