<div class="col-lg-12 padding-top-30 border-bottom-solid-2p">
    <div class="col-lg-10">
        <div class="bold font-16 line-height-25 margin-bottom-10">{{matterDescription}}</div>
    </div>
    <div class="col-lg-2 text-right">
        <button class="dp-btn-green" type="button" *ngIf="!isSharedDocumentsLinked() && !sharedDocumentsPackage?.isDeclined && hasFullAccessToConveyancingOrProjectMatters() "
                (click)="openLinkMatterModal()">
            <span>Link to a Matter</span>
        </button>
        <button class="dp-btn-green" type="button" *ngIf="isSharedDocumentsLinked() && hasFullAccessToConveyancingOrProjectMatters() && !isSharedDocumentsPackageByUnityConnectUser()"
                (click)="unlinkMatterLinkFromSharedDocsPackage()">
            <span>Unlink Matter</span>
        </button>
    </div>
</div>
<div class="col-lg-12 padding-top-10">
    <div class="col-lg-12 padding-bottom-10" *ngIf="isSharedDocumentsLinked()">
        <div class="col-lg-12 bold" >All documents shared for this transaction are linked to <a (click)="openMatterRequest()">{{this.linkedMatter.matterRecordNumber}}</a>
        </div>
    </div>
    <div class="col-lg-12 padding-bottom-10" *ngIf="sharedDocumentsPackage?.matterInfo?.propertyAddress?.addressLine1">
        <div class="col-lg-2 bold">Property Address</div>
        <div class="col-lg-10">
            {{sharedDocumentsPackage?.matterInfo?.propertyAddress?.addressTextWithProvinceName}}
        </div>
    </div>
    <div class="col-lg-12 padding-bottom-10" *ngIf="sharedDocumentsPackage?.matterInfo?.legalDescription && !sharedDocumentsPackage?.matterInfo?.isCustomMatter()">
        <div class="col-lg-2 bold">Legal Description</div>
        <div class="col-lg-10">{{sharedDocumentsPackage?.matterInfo?.legalDescription}}</div>
    </div>

    <div class="col-lg-12 padding-bottom-10">
        <ng-container *ngIf="sharedDocumentsPackage?.matterInfo?.occupancyDate">
            <div class="col-lg-2 bold">Occupancy Date</div>
            <div class="col-lg-4">{{sharedDocumentsPackage?.matterInfo?.occupancyDate | date : 'mediumDate'}}</div>
        </ng-container>
        <ng-container *ngIf="sharedDocumentsPackage?.matterInfo?.closingDate">
            <div class="col-lg-2 bold" *ngIf="!sharedDocumentsPackage?.matterInfo?.isCustomMatter()">Closing Date</div>
            <div class="col-lg-2 bold" *ngIf="sharedDocumentsPackage?.matterInfo?.isCustomMatter()">Completion Date</div>
            <div class="col-lg-4">{{sharedDocumentsPackage?.matterInfo?.closingDate | date : 'mediumDate'}}</div>
        </ng-container>
    </div>
    <div class="col-lg-12 padding-bottom-10" *ngIf="sharedDocumentsPackage?.matterInfo?.lawFirm">
        <div class="col-lg-2 bold">Law Firm Name</div>
        <div class="col-lg-10">{{sharedDocumentsPackage?.matterInfo?.lawFirm}}</div>
    </div>
    <div class="col-lg-12 padding-bottom-10">
        <ng-container *ngIf="sharedDocumentsPackage?.matterInfo?.vendorSolicitor?.summary">
            <div class="col-lg-2 bold">Solicitor</div>
            <div class="col-lg-4">{{sharedDocumentsPackage?.matterInfo?.vendorSolicitor?.summary}}</div>
        </ng-container>
        <ng-container *ngIf="sharedDocumentsPackage?.matterInfo?.lawClerk?.summary">
            <div class="col-lg-2 bold" dp-province-field-label="provinceBasedLawClerkTitle" [label-province-code]="sharedDocumentsPackage?.provinceCode"></div>
            <div class="col-lg-4">{{sharedDocumentsPackage?.matterInfo?.lawClerk?.summary}}</div>
        </ng-container>
    </div>
    <div class="col-lg-12 padding-bottom-10" *ngIf="sharedDocumentsPackage?.matterInfo?.projectSharingInformation">
        <div class="bold font-16 border-bottom-solid-2p padding-bottom-10">Project Information</div>
        <div class="col-lg-12 padding-top-10">
            <textarea class="message-text margin-top-20" [ngModel]="sharedDocumentsPackage?.matterInfo?.projectSharingInformation"></textarea>
        </div>
    </div>
</div>
<div class="col-lg-12 padding-top-20">
    <div class="bold font-16 border-bottom-solid-2p padding-bottom-10">Message History</div>
    <div class="col-lg-12 padding-top-10">
        <div class="col-lg-6">
            <table class="width-95-percent">
                <thead class="bold border-bottom-solid-2p">
                <th class="width-10-percent padding-bottom-10">Sent</th>
                <th class="width-90-percent padding-bottom-10">Subject</th>
                </thead>
                <tbody>
                <ng-container *ngFor="let docSharingMessage of sharedDocumentsPackage?.docSharingMessages">
                    <tr (click)="openSelectedMessage(docSharingMessage.id)" class="pointerCursor"
                        [ngClass]="{'boldfont': docSharingMessage.id == selectedMessageId}">
                        <td class="padding-right-10">{{docSharingMessage.lastUpdatedTimeStamp | date :
                            'medium'}}
                        </td>
                        <td class="padding-right-10">{{docSharingMessage.messageSubject}}</td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
        <div class="col-lg-6">
            <textarea class="message-text margin-top-20" [ngModel]="selectedMessage?.message"></textarea>
        </div>

    </div>
</div>
<div class="col-lg-12 padding-top-30">
    <div class="bold font-16 border-bottom-solid-2p padding-bottom-10">Shared Documents</div>
    <div class="col-lg-12 padding-bottom-10 view-table">
        <div class="col-lg-12">
            <div *ngIf="sharedDocumentsPackage.isSharingAvailable() && sharedDocumentsPackage.documents && sharedDocumentsPackage.documents.length ==0" class="bold">
                No document shared
            </div>
            <div *ngIf="sharedDocumentsPackage.isSharingRevoked()" class="bold">
                Shared Documents are no longer available
            </div>
            <div *ngIf="sharedDocumentsPackage.isSharingPartiallyRevoked()">
                A shared document is no longer available. Please see notification from the sender for details.
            </div>
            <table class="standard-table col-lg-12 sticky-table" [attr.tabindex]="-1"
                   (keydown)="tableKeyCommands($event)">

                <thead class="sticky-table-header">
                <tr>
                    <th>
                        <div class="wrap-header">File Name</div>
                    </th>
                    <th>
                        <div class="wrap-header"></div>
                    </th>
                    <th>
                        <div class="wrap-header">Description</div>
                    </th>
                    <th>
                        <div class="wrap-header">Date</div>
                    </th>

                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="sharedDocuments.length == 0">
                    No Documents available
                </ng-container>
                <ng-container *ngFor="let sharedDocument of sharedDocuments; let i = index;">
                    <tr [attr.tabindex]="0">
                        <td>
                            <span>
                                <a (click)="openFile(sharedDocument.id)">{{sharedDocument.name}}</a>
                            </span>
                        </td>
                        <td class="red bold">{{sharedDocument.docStatus}}</td>
                        <td>{{sharedDocument.description}}</td>
                        <td>{{sharedDocument.date | date : 'mediumDate'}}</td>

                    </tr>
                </ng-container>

                </tbody>
            </table>
        </div>
    </div>
</div>

