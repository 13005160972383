import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[dp-autoMoveNextOnMaxlength]'
})

export class AutoMoveNextOnMaxlengthDirective {
  @Input('dp-autoMoveNextOnMaxlength') nextId: string;
  @Input('maxlength') maxLength: number;

  constructor(private el: ElementRef) {
  }

  @HostListener('input') onInput(): void {
    // Todo use better solution to replace setTimeout
    setTimeout(() => {
      if (this.maxLength && this.maxLength > 0 && this.el.nativeElement.value.length >= this.maxLength) {
        const element: HTMLElement = this.el.nativeElement.ownerDocument.getElementById(this.nextId);
        if (element) {
          element.focus();
        }
      }
    }, 0);
  }
}



