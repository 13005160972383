import {StatementAdjustment} from '../statement-adjustment';

export class StatementAdjustmentDisplayBaseItem {
  key: string;                // a tag to locate a value in DocGen
  title: string;
  noteReferenceMark: string;  // for references to footers

  leftValue: string; // it could be "No Adjustment" or "$0.00" or "123.45"
  rightValue: string; // it could be "No Adjustment" or "$0.00" or "123.45"

  constructor(codeKey?: string) {
    this.key = codeKey;
  }
}

export class StatementAdjustmentDisplayItem extends StatementAdjustmentDisplayBaseItem {
  itemLines: StatementOfAdjustmentDisplayItemLine[];
  soaItem: StatementAdjustment;
}

export class StatementAdjustmentDisplayBalanceItem extends StatementAdjustmentDisplayBaseItem {

}

export type StatementOfAdjustmentDisplayItemLineType = 'None' | 'Ref1' | 'Ref2' | 'Ref3';

export class StatementOfAdjustmentDisplayItemLine {
  key: string;                // a tag to locate a value in DocGen
  title: string;
  value: string;
  pushValueToRight: boolean;
  noteReferenceMark: string;  // for references to footers
  hintType: StatementOfAdjustmentDisplayItemLineType;
  isBoldRequired: boolean;
}
