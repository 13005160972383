<div class="dppm-md-loading-bg">
    <div class="loading-bg" [ngStyle]="{'background': utilsService.dndBgImagePathStyle}">

        <div class="gradient-box">
            <div class="unity-logo-box">
                <a routerLink="/login/home"><span class="unity-login-logo"></span></a>
            </div>
            <ng-container *ngIf="router.url!='/login/privacy-policy'">
                <div class="vertical-login-bar">
                </div>
                <div class="login-dtls-header-ext">
                    Stay at the forefront of Digital ID Verification and Digital Signing with Unity&reg; Practice Management
                </div>
                <div i18n="@@login-blurb-description" class="login-dtls-ext">
                    Legal professionals in Ontario and Alberta can complete KYC and digital signing all from a single destination.
                    Everything you need - including <strong style="vertical-align:unset">FREE digital ID verification</strong> - is already available in Unity&reg; Practice Management.
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="router.url.indexOf('/login/home') != -1">
            <div class="login-card" [ngClass]="{'login-car-height-error' : errorMsg}">
            </div>

            <form [formGroup]="loginForm" *ngIf="user" (ngSubmit)="login(loginForm)">
                <div class="login-card-input">
                    <dp-form-field fieldId="publicAccountId" formTypeId="loginPage" [rootForm]="loginForm"
                                   [submitted]="submitted">
                        <label class="padding-bottom-3" for="publicAccountId">Account Number</label>
                        <div>
                            <input type="text" id="publicAccountId" (change)="submitted=false"
                                   formControlName="publicAccountId"
                                   class="form-control" required>
                        </div>
                    </dp-form-field>
                    <dp-form-field fieldId="loginId" formTypeId="loginPage" [rootForm]="loginForm"
                                   [submitted]="submitted">
                        <label class="padding-top-10 padding-bottom-3" for="loginId">User ID</label>
                        <div>
                            <input type="text" id="loginId" formControlName="loginId" (change)="submitted=false"
                                   class="form-control"
                                   required>
                        </div>
                    </dp-form-field>
                    <dp-form-field fieldId="password" formTypeId="loginPage" [rootForm]="loginForm"
                                   [submitted]="submitted">
                        <label class="padding-top-10 padding-bottom-3" for="password">Password</label>

                        <div>
                            <input type="password" id="password" formControlName="password" (change)="submitted=false"
                                   class="form-control"
                                   required>
                        </div>
                    </dp-form-field>
                    <div class="padding-top-25">
                        <dp-checkbox fieldId="rememberMe"><input type="checkbox" id="rememberMe"
                                                                 formControlName="rememberMe"/>
                        </dp-checkbox>
                        <span class="remember-me">Remember my Account Number and User ID</span>
                    </div>
                </div>
                <div>
                    <button type="submit" class="primary-btn login-btn">LOGIN</button>
                </div>
                <div class="forgot-pwd" [ngClass]="{'forgot-pwd-top-error' : errorMsg}">
                    <a class="forgot" routerLink="/login/forgot-password"
                       [queryParams]="{publicAccountId : loginForm && loginForm.value && loginForm.value.publicAccountId, loginId : loginForm && loginForm.value && loginForm.value.loginId}"
                    >Forgot your password?</a>
                </div>
                <div class="error-msg" *ngIf="errorMsg">
                    <span class="error-img"></span> <span>{{errorMsg}}</span>
                </div>
            </form>
            <div class="login-card-info" [ngClass]="{'login-card-info-height-error' : errorMsg}">
                If you forgot your Account Number or User ID, please contact your account administrator at your law firm.
                If you are the law firm administrator, please contact the <a href="https://dyedurham.ca/support/unity/" target="_blank">Unity&reg; support team</a> at 1-866-367-7648
                <span class="display-block">{{clientVersion}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="router.url==='/login/contact-us'">
            <div class="contact-us-card">
            </div>
            <div class="contact-us-info">
                <span class="contact-us-title">Contact Us</span>
                <p class="contact-us-gi"><b>General Inquiries:</b></p>
                <p class="contact-us-address">
                    Dye & Durham<br/>
                    11th Floor<br/>
                    25 York Street<br/>
                    Toronto, ON<br/>
                    M5J 2V5<br/>
                    Toll Free: 1-866-367-7648<br/>
                    <a href="mailto:unity.support@dyedurham.com">unity.support@dyedurham.com</a>
                </p>
            </div>
        </ng-container>
        <ng-container *ngIf="router.url==='/login/privacy-policy'">
            <div class="privacy-policy-box">
                <div [innerHTML]="privacyPolicyText"></div>
            </div>
        </ng-container>
        <div class="login-footer">
            <div class="login-footer-logo"></div>
            <div class="login-footer-links">
                <span class="footer-link about-unity"><a href="https://welcometounity.doprocess.com/" target="_blank">Learn More</a></span>
                <span class="footer-link contact-us"><a routerLink="/login/contact-us">Contact Us</a></span>
                <span class="footer-link privacy-policy"><a routerLink="/login/privacy-policy">Privacy Policy</a></span>
                <span class="footer-link terms"><a href="https://welcometounity.doprocess.com/unity-terms-of-use/"
                                                   target="_blank">Terms of Use</a></span>
                <span class="footer-link unity-status"><a href="{{utilsService.getUnityStatusUrl()}}" target="_blank">System Status</a></span>
            </div>
            <div class="login-footer-media">
                <span class="linked-in"><a href="https://www.linkedin.com/company/do-process/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a></span>
                <span class="youtube"><a href="https://www.youtube.com/doprocesslp" target="_blank"><i
                    class="fab fa-youtube"></i></a></span>
            </div>
            <div class="footer-copyright">&copy; Copyright {{utilsService.currentYear}} Dye & Durham Corporation
            </div>
        </div>
    </div>
</div>
<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>
