export const projectConsts = {
  registryLt: {
    defaultType: 'QUESTION',
    registry: 'REGISTRY',
    landTitles: 'LANDTITLES'
  },

  registrationMethods: {
    MANUAL: 'MANUAL',
    ELECTRONIC: 'ELECTRONIC'
  },

  transferDocumentTypes: {
    TRANSFER: 'TRANSFER'
  },

  chargeDocumentTypes: {
    CHARGE: 'CHARGE'
  },

  yesNoValues: {
    Yn: 'Y_n',
    Ny: 'N_y',
    YES: 'YES',
    NO: 'NO'
  },

  builderNameAddressSources: {
    SAME_AS_VENDOR_TAB: 'SAME_AS_VENDOR_TAB',
    MANUALLY_ENTERED: 'MANUALLY_ENTERED'
  },

  teraviewDocketType: {
    ALL: 'ALL',
    ONE_PER_DOCKET: 'ONE_PER_DOCKET'
  },

  countries: {
    CANADA: 'CANADA'
  },

  provinces: {
    ON: 'ON',
    AB: 'AB'
  },

  unitLvlPlanFieldLbl: {
    unitLevelPlan: 'Unit/Level/Plan',
    unitLevel: 'Unit, Level'
  },

  unitLvlPlanModalTitle: {
    unitLevelPlan: 'Unit/Level/Plan',
    unitLevel: 'Units and Level'
  },

  dataPropagationCmdType: {
    DocumentProfile: 'DocumentProfile',
    ignoreCreditInTarionAdjustment: 'ignoreCreditInTarionAdjustment',
    RegistrationMethodOrUseSameTeraviewDocketId: 'RegistrationMethodOrUseSameTeraviewDocketId',
    InterestRate: 'InterestRate',
    SoaPayableTo: 'SoaPayableTo',
    customUnitType: 'customUnitType',
    DirectionReFunds: 'DirectionReFunds',
    condoPlanChanged: 'condoPlanChanged',
    occupancyFeesCalculatedBasedOnChanged: 'occupancyFeesCalculatedBasedOnChanged',
    condoLocationChanged: 'condoLocationChanged',
    condoOccupancyFeesCalculatedBasedOnChanged: 'condoOccupancyFeesCalculatedBasedOnChanged',
    potlFlagChanged: 'potlFlagChanged',
    potlPlanApplied: 'potlPlanApplied',
    commonExpensesForEntireCondo: 'commonExpensesForEntireCondo',
    exceptionTypeChanged: 'exceptionTypeChanged',
    Soa: 'Soa',
    TrustLedger: 'TrustLedger',
    SoaHeading: 'SoaHeading',
    statementAdjustmentFormatSettings: 'statementAdjustmentFormatSettings',
    statementAdjustmentDelete: 'statementAdjustmentDelete',
    statementAdjustmentFooters: 'statementAdjustmentFooters',
    statementAdjustmentFooterDelete: 'statementAdjustmentFooterDelete',
    statementAdjustmentFooterReset: 'statementAdjustmentFooterReset',
    realtyTaxCalculation: 'realtyTaxCalculation',
    statementAdjustmentFieldCode: 'statementAdjustmentFieldCode',
    initInterimStatementAdjustments: 'initInterimStatementAdjustments',
    clearInterimData: 'clearInterimData',
    clearProjectInterimData: 'clearProjectInterimData',
    synchProjectInterimSalePriceDeposit: 'synchProjectInterimSalePriceDeposit',
    statementOfAdjustmentReset: 'statementOfAdjustmentReset',
    ignoreCreditsInDeedConsideration: 'IgnoreCreditsInDeedConsideration',
    updateNonInitMattersForProject: 'updateNonInitMattersForProject',
    updateNonInitEregMattersForProject: 'updateNonInitEregMattersForProject'
  }
};

export class StatementAdjParameter {
  adjustmentId: number;
  selectedRemoveAdjustmentAction: string;
}

export const PROJECT_MATTER_INCOMPLETE_MSG_ADD = 'Unable to add a matter under this project as Unity has it in an \'Incomplete\' state.  Open the project for edit to complete the initialization prior to adding a new project sale matter.';
export const PROJECT_MATTER_INCOMPLETE_MSG_OPEN = 'Unable to open a matter under this project as Unity has it in an \'Incomplete\' state.  Open the project for edit to complete the initialization prior to opening a project sale matter.';
export const PROJECT_MASS_UPDATE_INCOMPLETE_MSG_OPEN = 'Unable to perform mass update under this project as Unity has it in an \'Incomplete\' state.  Open the project for edit to complete the initialization prior to performing mass update.';
export const PROJECT_IMPORT_ADJ_INCOMPLETE_MSG_OPEN = 'Unable to perform Import Matter Adjustment Information under this project as Unity has it in an \'Incomplete\' state.  Open the project for edit to complete the initialization prior to performing mass update.';
export const PROJECT_COPY_MSG_OPEN = 'Unable to use this project as Unity has it in an \'Incomplete\' state. Open the project for edit to complete the initialization prior to copying the project.';
