import {Injectable} from '@angular/core';
import {Project} from '../projects/shared/project';
import {Matter, matterApi} from '../matters/shared';
import {HttpClient} from '../core/index';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {projectApi} from '../projects/shared/project-api';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class ProjectMatterCacheService {

  private _cachedProject: Map<number, Project> = new Map<number, Project>();
  private _cachedProjectMatters: Map<number, Matter> = new Map<number, Matter>();

  constructor(private http: HttpClient) {

  }

  getMatter(matterId: number): Observable<Matter> {
    let matter: Matter = this._cachedProjectMatters.get(matterId);
    if (matter) {
      let cachedMatter = new Matter(matter);
      return Observable.of(cachedMatter);
    } else {
      return this.getMatterById(matterId, false).map(matter => {
        this._cachedProjectMatters.set(matterId, matter);
        let updatedMatter = new Matter(matter);
        return updatedMatter;
      });
    }
  }

  getMatterById(id: number, lockMatter = true): Observable<any> {
    return this.http.get(`${ matterApi.matters }/${ id }` + '?lockRequired=' + lockMatter)
    .map((res) => {
      const matter: Matter = new Matter(res[ 'Matter' ]);
      return matter;
    });
  }

  getMatterFromCache(matterId: number): Matter {
    return this._cachedProjectMatters.get(matterId);
  }

  updateProjectCache(updateProject: Project): void {
    let project = new Project(updateProject);
    this._cachedProject.set(project.id, project);
  }

  updateMatterCache(updatedMatter: Matter): void {
    let matter = new Matter(updatedMatter);
    this._cachedProjectMatters.set(matter.id, matter);
  }

  removeMatterCache(matter: Matter): void {
    let removeMatterFromCache = new Matter(matter);
    this._cachedProjectMatters.delete(removeMatterFromCache.id);
  }

  getProject(projectId: number): Observable<Project> {
    let project: Project = this._cachedProject.get(projectId);
    if (project) {
      let cachedProject = new Project(project);
      return Observable.of(cachedProject);
    } else {
      return this.getProjectById(projectId, false).map(project => {
        this._cachedProject.set(projectId, project);
        let updatedproject = new Project(project);
        return updatedproject;
      });
    }
  }

  // get project by id from backend
  getProjectById(id: number, lockProject = true): Observable<Project> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(`${ projectApi.projectForAccount(accountId, id) }` + '?lockRequired=' + lockProject)
    .map((res) => {
      const project: Project = new Project(res[ 'Project' ]);
      return project;
    });
  }
}
