import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SoAdjTotalOccupancyFee} from '../model/so-adj-total-occupancy-fee';
import Utils from '../../../shared-main/utils';
import {OccupancyFeesCalculatedBasedOn} from '../../../projects/project-condo/project-condo';

export class StatementAdjustmentDisplayTotalOccupancyFeeBuilder {

  static getDisplayItems(soaAdj: SoAdjTotalOccupancyFee, toBeUsedOnModal: boolean, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    soaAdj.updateFromMatterAndProject(mainBuilder.matter, mainBuilder.matter.project);
    let isProjectOccFeeBasedOnPhantomMortgage: boolean =
      mainBuilder.matter && mainBuilder.matter.project && mainBuilder.matter.project.projectCondo && mainBuilder.matter.project.projectCondo.occupancyFeesCalculatedBasedOn === OccupancyFeesCalculatedBasedOn.phantomMortgage;

    if (soaAdj.amount) {
      let monthlyLine: string = soaAdj.getMonthlyLine(isProjectOccFeeBasedOnPhantomMortgage, mainBuilder.matter.provinceCode);
      if (!toBeUsedOnModal) {
        monthlyLine = monthlyLine.replace('of occupancy fees', ''); //A37
      }
      sadItemDetails.push(mainBuilder.getNewItemLine(
        monthlyLine,
        mainBuilder.getCurrency(soaAdj.amount)
      ));
    }

    if (Utils.convertToBoolean(soaAdj.applyTax)) {
      sadItemDetails.push(mainBuilder.getNewItemLine(
        `${ soaAdj.taxType } calculated at ${ mainBuilder.getPercentage(soaAdj.hstRate) }`,
        mainBuilder.getCurrency(Number(soaAdj.amount) * Number(soaAdj.hstRate) / 100)));
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(
      soaAdj.getTotalLine(isProjectOccFeeBasedOnPhantomMortgage, mainBuilder.matter.provinceCode),
      ''
    ));

    sadItemDetails.push(mainBuilder.getNewItemLine(
      soaAdj.getTotalComponentLine(),
      soaAdj.getTotalComponentAmountStr()
    ));

    if (toBeUsedOnModal) {
      sadItemDetails.push(mainBuilder.getNewItemLine(
        `Credit ${ soaAdj.creditTo === 'PURCHASER' ? mainBuilder.purchaserLabel : mainBuilder.vendorLabel }`,
        soaAdj.getTotalComponentAmountStr(),
        (soaAdj.creditTo === 'VENDOR')
      ));
    } else {
      sadItemDetails.push(mainBuilder.getNewItemLine(
        `Credit ${ soaAdj.creditTo === 'PURCHASER' ? mainBuilder.purchaserLabel : mainBuilder.vendorLabel }`,
        ''
      ));
    }

    return sadItemDetails;
  }
}
