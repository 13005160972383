import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProjectTaxAdjustmentConfig} from './project-tax-adjustment-config';
import {DpBooleanValue, DpBooleanValueTypes} from '../../matters/shared/dp-boolean';
import {ProjectAdjustmentFooter} from './project-adjustment-footer';

export const ProjectAdjustmentFooterMessages = {
  TYPE1: 'Adjustment constitutes Additional Consideration eligible for [TAX_TYPE] Rebate in the Sale Price Adjustment',
  UNADJUSTED_SALE_PRICE_TYPE2: 'Adjustment constitutes Additional Consideration in the Sale Price Adjustment',
  ADJUSTED_SALE_PRICE_TYPE2: 'Adjustment constitutes Additional Consideration (not eligible for [TAX_TYPE] Rebate) in the Sale Price Adjustment',
  UNADJUSTED_SALE_PRICE_TYPE3: 'Adjustment constitutes Additional Consideration in the Sale Price Adjustment',
  ADJUSTED_SALE_PRICE_TYPE3: 'Adjustment constitutes Additional Consideration in the Sale Price Adjustment'
};

export class ProjectAdjustmentConfig extends BaseEntity {

  constructor(projectAdjustments?: ProjectAdjustmentConfig) {
    super(projectAdjustments);
    if (projectAdjustments) {

      if (projectAdjustments.interimTaxAdjustment) {
        this.interimTaxAdjustment = new ProjectTaxAdjustmentConfig(projectAdjustments.interimTaxAdjustment);
      }

      if (projectAdjustments.finalTaxAdjustment) {
        this.finalTaxAdjustment = new ProjectTaxAdjustmentConfig(projectAdjustments.finalTaxAdjustment);
      }
      if (projectAdjustments.footer) {
        this.footer = new ProjectAdjustmentFooter(projectAdjustments.footer);
      }
    } else {
      this.interimTaxAdjustment = new ProjectTaxAdjustmentConfig();
      this.finalTaxAdjustment = new ProjectTaxAdjustmentConfig();
      this.ignoreCreditInTarionAdjustment = DpBooleanValueTypes.N_y;
      this.footer = new ProjectAdjustmentFooter();
    }
  }

  interimTaxAdjustment: ProjectTaxAdjustmentConfig;
  finalTaxAdjustment: ProjectTaxAdjustmentConfig;
  totalMonthlyCommonExpenses: number;
  ignoreCreditInTarionAdjustment: DpBooleanValue;
  hstReductionInSalePriceAdjustment: boolean;
  salePricePortionNotSubjectToHST: string;
  soaPaperSize: string;
  transactionFee: number;
  taxesSameAsInterim: boolean;
  footer: ProjectAdjustmentFooter;

  ignoreCreditInTarionAdjustmentisYes(): boolean {
    return (this.ignoreCreditInTarionAdjustment == 'YES');
  }

  initializeAllDefaultFooterMessages(taxType, province) {
    this.mapFooterMessage(taxType, 'TYPE1', 'DEFAULT');
    this.mapFooterMessage(taxType, 'UNADJUSTED_SALE_PRICE_TYPE2', 'DEFAULT');
    this.mapFooterMessage(taxType, 'ADJUSTED_SALE_PRICE_TYPE2', 'DEFAULT');
    if (province == 'ON') {
      this.mapFooterMessage(taxType, 'UNADJUSTED_SALE_PRICE_TYPE3', 'DEFAULT');
      this.mapFooterMessage(taxType, 'ADJUSTED_SALE_PRICE_TYPE3', 'DEFAULT');
    }
  }

  mapFooterMessage(taxType, footerType, event) {
    switch (footerType) {
      case 'TYPE1':
        event === 'DEFAULT' ?
          this.footer.footerValue1 = ProjectAdjustmentFooterMessages.TYPE1.replace('[TAX_TYPE]', taxType)
          : this.footer.footerValue1 = '';
        break;
      case 'UNADJUSTED_SALE_PRICE_TYPE2':
        event === 'DEFAULT' ?
          this.footer.unadjustedSalePriceFooterValue2 = ProjectAdjustmentFooterMessages.UNADJUSTED_SALE_PRICE_TYPE2 :
          this.footer.unadjustedSalePriceFooterValue2 = '';
        break;
      case 'ADJUSTED_SALE_PRICE_TYPE2' :
        event === 'DEFAULT' ?
          this.footer.adjustedSalePriceFooterValue2 = ProjectAdjustmentFooterMessages.ADJUSTED_SALE_PRICE_TYPE2.replace('[TAX_TYPE]', taxType) :
          this.footer.adjustedSalePriceFooterValue2 = '';
        break;
      case 'UNADJUSTED_SALE_PRICE_TYPE3':
        event === 'DEFAULT' ?
          this.footer.unadjustedSalePriceFooterValue3 = ProjectAdjustmentFooterMessages.UNADJUSTED_SALE_PRICE_TYPE3 :
          this.footer.unadjustedSalePriceFooterValue3 = '';
        break;
      case 'ADJUSTED_SALE_PRICE_TYPE3' :
        event === 'DEFAULT' ?
          this.footer.adjustedSalePriceFooterValue3 = ProjectAdjustmentFooterMessages.ADJUSTED_SALE_PRICE_TYPE3 :
          this.footer.adjustedSalePriceFooterValue3 = '';
        break;
    }
  }

}
