import {Component, Inject, Renderer2, ViewContainerRef} from '@angular/core';
import {ConsiderationSoaModal} from './consideration-soa.modal.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {FocusFirstElementModalDecorator} from '../../shared-main/focus-first-element-modal-decorator';
import {ConsiderationTaxes} from './consideration-taxes';
import {SalePriceAdjustment} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment';
import {StatementOfAdjustmentHeading} from '../statement-adjustment/model/statement-adjustment-heading';
import {StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {ConsiderationLtt} from './consideration-ltt';
import {StatementAdjustmentDisplayBuilder} from '../statement-adjustment/builders/statement-adjustment-display-builder';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {Matter} from '../shared/matter';
import {RentInterestRate} from '../statement-adjustment/model/rent-interest-rate';
import {TarionWarrantyEnrolmentPeriod} from '../statement-adjustment/model/tarion-warranty-enrolment-period';
import {TaxRateService} from './tax-rate.service';
import {Subscription} from 'rxjs/Subscription';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import * as _ from 'lodash';
import {DocumentProductionService} from '../document-production/document-production.service';
import {ActingForValues, AffidavitTobeSignedByOptionsValue} from '../../shared-main/constants';
import {SelectItem} from 'primeng/api';
import {AffidavitTobeSignedByOptions} from '../shared/matter-drop-downs';
import {SalePriceAdjustmentFactory} from '../statement-adjustment/sale-price-adjustment/sale-price-adjustment-factory';
import {TabsService} from '../../core';
import {ManitobaLTTTier} from './manitoba-ltt-tier';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class ConsiderationModalContext {
  public salePrice: string;
  public salePriceSoa: string;
  public priceType: string;
  public closingDate: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public salePriceAdjustment: SalePriceAdjustment;
  public statementOfAdjustments: StatementAdjustment[];
  public viewContainerRef: ViewContainerRef;
  public statementOfAdjustmentHeading: StatementOfAdjustmentHeading;
  public purchaseIsOfCode: string;
  public autoInsertHst: boolean;
  public txnType: string;
  public considerationLtt: ConsiderationLtt;
  matter: Matter;
  overrideValueOfParcels: boolean;
}

export type PriceType = 'SALE_PRICE_AS_IN_SOA' | 'SALE_PRICE';

@Component({
  selector: 'dp-consideration-modal-content',
  templateUrl: './consideration.modal.component.html',
  providers: [ErrorService, DecimalPipe, CurrencyPipe, PercentPipe, DocumentProductionService]
})
@FocusFirstElementModalDecorator()
@AutoUnsubscribe()
export class ConsiderationModal extends ModalComponent<ConsiderationModalContext> {
  newSalePrice: string = '0';
  newSalePriceSOA: string = '0';
  priceType: string;
  salePriceAdjustmentLocal: SalePriceAdjustment;
  purchaseIsOfCode: string = '';

  taxRateServiceSub: Subscription;
  tarionRatesSub: Subscription;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;

  affidavitTobeSignedBy: string;
  isAffidavitValueSignedByReadOnly: boolean;
  affidavitTobeSignedByOptions: SelectItem[] = AffidavitTobeSignedByOptions;
  newOverrideValueOfParcels: boolean;
  manitobaLTTTiers: ManitobaLTTTier[] = [];

  constructor(
    public dialog: MatDialogRef<ConsiderationModal>,
    public renderer2: Renderer2,
    public errorService: ErrorService,
    public dialogService: DialogService,
    public tabService: TabsService,
    public documentProfileCache: DocumentProfileCache,
    public decimalPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,
    public percentPipe: PercentPipe,
    public taxRateService: TaxRateService,
    @Inject(MAT_DIALOG_DATA) context?: ConsiderationModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.initializeSoAdjUtil();
    this.dialogService.tabStateService = this.tabService;
    this.priceType = !this.context.priceType ? 'SALE_PRICE_AS_IN_SOA' : this.context.priceType;
    this.newSalePrice = this.context.salePrice ? this.context.salePrice : '0';
    this.newOverrideValueOfParcels = this.context.overrideValueOfParcels ? this.context.overrideValueOfParcels : false;
    this.newSalePriceSOA = this.context.salePriceSoa ? this.context.salePriceSoa : '0';
    this.salePriceAdjustmentLocal = !this.context.salePriceAdjustment
      ? SalePriceAdjustmentFactory.getSalePriceAdjustment(
          this.context.matter.adjustmentStatusMode,
          this.context.matter.provinceCode
        )
      : SalePriceAdjustmentFactory.getSalePriceAdjustment(
          this.context.matter.adjustmentStatusMode,
          this.context.matter.provinceCode,
          this.context.salePriceAdjustment
        );
    this.purchaseIsOfCode = this.context.purchaseIsOfCode;
    if (!this.context.salePriceAdjustment) {
      //DPPMP-14535
      this.salePriceAdjustmentLocal.agreementSalePrice = Number(this.context.salePrice);
    }
    this.initAffidavitValueSignedBy();
    this.taxRateService.getManitobaLTTTiers().subscribe((res) => {
      this.manitobaLTTTiers = res;
    });
    this.context.autoInsertHst = this.isAutoInsertVisible() ? this.context.autoInsertHst : false;
  }

  onRPRChange(event) {
    this.newOverrideValueOfParcels = this.newOverrideValueOfParcels ? true : false;
  }

  //only applicable for AB
  initAffidavitValueSignedBy() {
    if (this.context.matter && this.context.matter.isMatterProvinceAB) {
      this.isAffidavitValueSignedByReadOnly = false;
      if (
        !this.context.matter.matterPropertyWithCondo ||
        !this.context.matter.matterPropertyWithCondo.affidavitTobeSignedBy
      ) {
        this.affidavitTobeSignedBy = AffidavitTobeSignedByOptionsValue.CLIENT;
      } else {
        this.affidavitTobeSignedBy = this.context.matter.matterPropertyWithCondo.affidavitTobeSignedBy;
      }
    }
  }

  initializeSoAdjUtil(): void {
    this.statementAdjustmentDisplayUtil = new StatementAdjustmentDisplayBuilder(
      this.decimalPipe,
      this.currencyPipe,
      this.percentPipe
    );
    this.statementAdjustmentDisplayUtil.documentProfileCache = this.documentProfileCache;
    this.statementAdjustmentDisplayUtil.setMatter(this.context.matter);
    this.statementAdjustmentDisplayUtil.setSoaConsiderationTaxes(this.context.provinceHstRateSlab);

    this.taxRateServiceSub = this.taxRateService
      .cachedRentInterestRates(this.statementAdjustmentDisplayUtil.matter.provinceCode)
      .subscribe((rirList: RentInterestRate[]) => {
        if (rirList && rirList.length > 0) {
          let rentInterestRates = rirList.map((r) => new RentInterestRate(r));
          let rentInterestRatesSorted = _.sortBy(rentInterestRates, ['index']);
          this.statementAdjustmentDisplayUtil.setRentInterestRates(rentInterestRatesSorted);
        }
      });

    this.tarionRatesSub = this.taxRateService
      .cachedTarionWarrantyEnrolmentPeriods()
      .subscribe((twepList: TarionWarrantyEnrolmentPeriod[]) => {
        if (twepList && twepList.length > 0) {
          this.statementAdjustmentDisplayUtil.setTarionWarrantyEnrolmentPeriods(twepList);
        }
      });
  }

  get isAffidavitTobeSignedByVisble(): boolean {
    return (
      !this.context.matter.isProjectSale ||
      (this.context.matter.isMatterProvinceAB &&
        (this.context.matter.actingFor === ActingForValues.VENDOR_MORTGAGEE ||
          this.context.matter.actingFor === ActingForValues.VENDOR_PURCHASER_MORTGAGEE))
    );
  }

  isTaxRateGst(): boolean {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.provinceDefaultTaxRate == 'GST';
  }

  get appliedTaxRate(): string {
    return this.isTaxRateGst() ? 'GST' : this.context?.provinceHstRateSlab?.rateType || '';
  }

  openSalePriceModal(): void {
    this.dialogService.matDialogContent(
      {
        content: ConsiderationSoaModal,
        context: {
          closingDate: this.context.closingDate,
          salePriceAdjustment: this.salePriceAdjustmentLocal,
          provinceHstRateSlab: this.context.provinceHstRateSlab,
          statementAdjustmentHeading: this.context.statementOfAdjustmentHeading,
          statementOfAdjustments: this.context.statementOfAdjustments,
          considerationLtt: this.context.considerationLtt,
          priceType: 'SALE_PRICE_AS_IN_SOA',
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          matter: this.context.matter
        },
        modalGrid: 6,
        onFulfillment: (result: SalePriceAdjustment) => {
          if (result != undefined && result != null) {
            this.salePriceAdjustmentLocal = result;
            this.newSalePriceSOA = this.salePriceAdjustmentLocal.getSalePrice(this.context.provinceHstRateSlab);
            let salePriceElement = this.renderer2.selectRootElement('#salePriceSOA', true);
            salePriceElement.focus();
          }
        }
      },
      this.context.viewContainerRef
    );
  }

  ok(): void {
    this.checkErrors();
    if (!this.errorService.hasErrors()) {
      this.dialog.close({
        salePrice: this.newSalePrice,
        salePriceSoa: this.newSalePriceSOA,
        priceType: this.priceType,
        salePriceAdjustment: this.salePriceAdjustmentLocal,
        autoInsertHst: this.context.autoInsertHst,
        affidavitTobeSignedBy: this.affidavitTobeSignedBy,
        statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
        overrideValueOfParcels: this.newOverrideValueOfParcels
      });
    }
  }

  checkErrors(): void {
    this.removeDPFieldErrorsInModal();
    if (!this.validateSalePrice()) {
      this.errorService.addDpFieldError(
        DPError.createDPError(
          this.isPriceTypeSalePriceSOA
            ? 'matter.considerationLtt.modal.newSalePriceSOA'
            : 'matter.considerationLtt.modal.newSalePrice'
        )
      );
    }
  }

  get considerationLtt(): ConsiderationLtt {
    return this.context.considerationLtt;
  }

  get isPriceTypeSalePriceSOA(): boolean {
    return this.priceType && this.priceType === 'SALE_PRICE_AS_IN_SOA';
  }

  get salePrice(): number {
    return this.isPriceTypeSalePriceSOA ? Number(this.newSalePriceSOA) : Number(this.newSalePrice);
  }

  validateSalePrice() {
    if (this.considerationLtt && this.considerationLtt.isCalculateABySubstractBThroughEFromFOrManualOrLINE_A()) {
      // let totalValueFromBToE = (
      //     Utils.getNumberOrDefault(this.considerationLtt.mortgagesAssumed, 0)
      //     + Utils.getNumberOrDefault(this.considerationLtt.mortgagesGivenBackToVendor, 0)
      //     + Utils.getNumberOrDefault(this.considerationLtt.propertyTransferredInExchange, 0)
      //     + Utils.getNumberOrDefault(this.considerationLtt.otherConsiderationSubjectToTax, 0)
      //     + Utils.getNumberOrDefault(this.considerationLtt.fairMarketValueOfLands, 0));
      //
      // let valueFromF = this.salePrice;
      // let possibleValueForFieldA : number =  valueFromF - totalValueFromBToE;
      //
      // return possibleValueForFieldA >= 0;
      return this.considerationLtt.isSalePriceValid(this.salePrice);
    }
    return true;
  }

  removeDPFieldErrorsInModal() {
    this.errorService.removeDpFieldError('matter.considerationLtt.modal.newSalePriceSOA');
    this.errorService.removeDpFieldError('matter.considerationLtt.modal.newSalePrice');
  }

  onPriceTypeChange(newValue: PriceType) {
    this.removeDPFieldErrorsInModal();
    this.priceType = newValue;
    if (
      this.context.matter &&
      this.context.matter.isMatterProvinceSK &&
      !this.isPriceTypeSalePriceSOA &&
      this.context.matter.matterPropertyWithCondo &&
      this.context.matter.matterPropertyWithCondo.totalValueOfParcels != undefined &&
      this.context.matter.matterPropertyWithCondo.totalValueOfParcels != null
    ) {
      this.newSalePrice = this.context.matter.matterPropertyWithCondo.totalValueOfParcels.toString();
    }
  }

  get optionNumber(): string {
    return this.priceType == 'SALE_PRICE' ? '1' : '2';
  }

  isAutoInsertVisible(): boolean {
    return !(
      this.context.matter &&
      this.context.matter.isMatterProvinceAB &&
      this.context.matter.isStatementAdjustmentInterimAndFinal()
    );
  }

  close(): void {
    this.dialog.close();
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}
