import {BaseEntity} from '../../shared/BaseEntity/base-entity';

const propertyIgnoreList = [ 'id', 'identificationRecordId', 'nameOnId' ];

export class IdentificationDocument extends BaseEntity {

  constructor(iddocument?: IdentificationDocument) {
    super(iddocument);
    if (iddocument) {
      for (let prop in iddocument) {
        if (iddocument.hasOwnProperty(prop)) {
          this[ prop ] = iddocument[ prop ];
        }
      }
    }
  }

  id: number;
  nameOnId: string;
  identificationRecordId: number;
  identificationNumber: string;
  placeOfIssue: string;
  expiryDate: string;
  identificationTypeCode: string;
  sameAsOfficerNameFlag: boolean;
  country: string;

  static update(source: IdentificationDocument, target?: IdentificationDocument): IdentificationDocument {
    if (source) {
      if (!target) {
        target = new IdentificationDocument();
      }

      target.nameOnId = source.nameOnId;
      target.identificationNumber = source.identificationNumber;
      target.placeOfIssue = source.placeOfIssue;
      target.expiryDate = source.expiryDate;
      target.identificationTypeCode = source.identificationTypeCode;
      target.sameAsOfficerNameFlag = source.sameAsOfficerNameFlag;
      target.country = source.country;

      return target;
    }
    return undefined;
  }

  public getFieldMaxLength(): number {
    if (this.isCreditCard()) {
      return 16;
    } else {
      return 50;
    }
  }

  public getFieldMinLength(): number {
    if (this.isCreditCard()) {
      return 15;
    }
  }

  public isCreditCardLengthValid(): boolean {
    return this.identificationNumber.length >= this.getFieldMinLength() && this.identificationNumber.length <= this.getFieldMaxLength();
  }

  public isCreditCard(): boolean {
    return IdentificationDocument.isCreditCardType(this.identificationTypeCode);
  }

  public static isCreditCardType(cardType: string): boolean {
    return cardType && /\b(credit card|amex|american express|visa|mastercard)\b/.test(cardType.toLowerCase());
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }

  isCanadianDriverLicence(): boolean {
    return this.identificationTypeCode == 'Driver\'s Licence - Canadian';
  }
}
