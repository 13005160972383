import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({selector: '[accordian-tab-anchor-fix]'})
export class AccordianTabAnchorFixDirective {
  private element: Element;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.element = elementRef.nativeElement;
  }

  ngAfterViewInit() {
    const tabAnchor: Element = this.element.querySelector('a[role="tab"]');
    if (tabAnchor) {
      this.renderer.setAttribute(tabAnchor, 'tabindex', '-1');
      this.renderer.setStyle(tabAnchor, 'outline', 'none');
    }
  }
}
