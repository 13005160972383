import {OrderProductType, SearchType} from './ltsa-constants';

export class OrderPlacement {
  matterId: number;
  productType: OrderProductType;
  criteria: string;
  searchType: SearchType;
  includeCancelledInfo: boolean;
  groupId: number;

  constructor(matterId: number, productType: OrderProductType, criteria: string, searchType: SearchType, includeCancelledInfo: boolean, groupId: number) {
    this.matterId = matterId;
    this.productType = productType;
    this.criteria = criteria;
    this.searchType = searchType;
    this.includeCancelledInfo = includeCancelledInfo;
    this.groupId = groupId;
  }

}