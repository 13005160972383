import {Contact} from '../shared/contact';
import {Component, Inject} from '@angular/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {SpouseType, SpouseTypes, SuggestedSpouse} from './suggested-spouse';
import {contactTypeMapping} from '../../contact/contact-type-mapping';
import {SelectItem} from 'primeng/api';
import {SpouseTypeSelectionModal} from '../spouse-type-selection-modal/spouse-type-selection-modal.component';
import {FlaData} from '../shared/fla-data';
import {FlaStatementType} from '../../shared-main/constants';
import {SpouseRelationship} from '../../contact/spouse-relationship';
import {SpouseStatusOptions} from '../../contact/contact-relationship';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';

class SuggestedSpouseModalContext {
  public spouses: SpouseRelationship[];
  public contactLabel: string;
  public provinceCode: string;
  public flaStatements: FlaData;
  public isOtherSide: boolean;
  public isCustomMatter: boolean;
  public disableAddClient: boolean;
  public isOpportunityMatter: boolean;
  public isWillMatter: boolean;
}

@Component({
  selector: 'dp-suggested-spouse-modal.component',
  templateUrl: './suggested-spouse-modal.component.html'
})
@FocusFirstElementDecorator()
export class SuggestedSpouseModal extends ModalComponent<SuggestedSpouseModalContext> {
  suggestedSpouses: SuggestedSpouse[] = [];
  selectedSpouseId: number;

  constructor(
    public dialog: MatDialogRef<SuggestedSpouseModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: SuggestedSpouseModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (Array.isArray(this.context.spouses) && this.context.spouses.length > 0) {
      this.context.spouses.forEach((item) => {
        let suggestedSpouse: SuggestedSpouse = new SuggestedSpouse();
        suggestedSpouse.spouseRelationship = new SpouseRelationship(item);
        this.suggestedSpouses.push(suggestedSpouse);
      });

      if (Array.isArray(this.suggestedSpouses) && this.suggestedSpouses.length > 1) {
        this.selectedSpouseId = this.suggestedSpouses[0].spouse && this.suggestedSpouses[0].spouse.id;
      }
    }
  }

  close(): void {
    this.dialog.close();
  }

  isProvinceSK(): boolean {
    return this.context.provinceCode == 'SK';
  }

  isProvinceNS(): boolean {
    return this.context.provinceCode == 'NS';
  }

  isProvinceMB(): boolean {
    return this.context.provinceCode == 'MB';
  }

  isProvinceAB(): boolean {
    return this.context.provinceCode == 'AB';
  }

  isProvinceNB(): boolean {
    return this.context.provinceCode == 'NB';
  }

  isProvinceBC(): boolean {
    return this.context.provinceCode == 'BC';
  }

  isCustomMatter(): boolean {
    return this.context.isCustomMatter;
  }

  isOpportunityMatter(): boolean {
    return this.context.isOpportunityMatter;
  }

  isWillMatter(): boolean {
    return this.context.isWillMatter;
  }

  selectedSpouseAsParticipant(familyLawActStatementType: string, spouseType?: SpouseType): void {
    let selectedSpouse: SuggestedSpouse;
    if (Array.isArray(this.suggestedSpouses)) {
      if (this.suggestedSpouses.length === 1) {
        selectedSpouse = this.suggestedSpouses[0];
      } else {
        selectedSpouse = this.suggestedSpouses.find((item) => item.spouse && item.spouse.id === this.selectedSpouseId);
      }
      selectedSpouse.familyLawActStatementType = familyLawActStatementType;
    }
    if (spouseType) {
      selectedSpouse.spouseType = spouseType;
    }
    this.dialog.close({selectedSpouse: selectedSpouse});
  }

  onClickSelectSpouseAsClient(): void {
    this.selectedSpouseAsParticipant(null, this.isProvinceNB() ? SpouseTypes.AS_A_PURCHASER : null);
  }

  onClickSelectSpouseAsFormerClient(): void {
    this.selectedSpouseAsParticipant(null, SpouseTypes.AS_A_FORMER_PURCHASER);
  }

  onClickSpouseAsConsented(): void {
    if (this.isProvinceNB()) {
      this.selectedSpouseAsParticipant(FlaStatementType.MARITAL_STATUS_NB, SpouseTypes.As_A_CONSENTING);
    } else {
      this.selectedSpouseAsParticipant('CONSENTED_SPOUSE');
    }
  }

  onClickSpouseAsFormConsented(): void {
    this.selectedSpouseAsParticipant(FlaStatementType.MARITAL_STATUS_NB, SpouseTypes.As_A_FORMER_CONSENTING);
  }

  onClickSpouseAsAuthorizedByCourt(): void {
    this.selectedSpouseAsParticipant('AUTHORIZED_BY_COURT');
  }

  onClickSpouseAsInterspousalAgreement(): void {
    this.selectedSpouseAsParticipant('INTERSPOUSAL_AGREEMENT');
  }

  getGenderLabel(suggestedSpouseGender: string): string {
    let genderLabel: string = '';
    const selectedContactTypeMapping = contactTypeMapping.CONTACTTYPES.find(
      (contactTypeObj) => contactTypeObj.contactKey === 'CLIENT'
    );

    const genders: SelectItem[] = selectedContactTypeMapping && selectedContactTypeMapping.genders;
    if (genders) {
      const gender = genders.find((item) => item.value == suggestedSpouseGender);
      if (gender) {
        genderLabel = gender.label;
      }
    }
    return genderLabel;
  }

  getSpousalStatusLabel(suggestedSpouse: SpouseRelationship): string {
    let option = SpouseStatusOptions.find((item) => item.value == (suggestedSpouse && suggestedSpouse.spousalStatus));
    return option ? option.label : '';
  }

  getDeceasedLabel(spouse: Contact): string {
    return spouse && spouse.getDeceasedString();
  }

  openSpouseTypeSelectionModal() {
    this.dialogService.matDialogContent({
      content: SpouseTypeSelectionModal,
      context: {
        flaStatements: this.context.flaStatements
      },
      onFulfillment: (result) => {
        if (result && result.statementType) {
          this.selectedSpouseAsParticipant(result.statementType);
        }
      }
    });
  }

  isAsConsentingSpouseButtonVisible() {
    //If it is AB, no matter main client or other side client(tab B or tab c) doesn't show As a consenting spouse button
    //If it isn't  AB, other side client(tab c) of province MB doesn't show As a consenting spouse button
    return (
      !this.isProvinceAB() &&
      !this.isProvinceBC() &&
      (!this.context.isOtherSide ||
        (this.context.isOtherSide && !this.isProvinceNS() && !this.isProvinceNB() && !this.isProvinceMB())) &&
      !this.isWillMatter()
    );
  }

  getClientLabel(): string {
    if (this.isWillMatter()) {
      return 'As a Client';
    }
    let ret: string = 'As a ' + this.context.contactLabel;
    if (this.context.contactLabel == 'Other Client') {
      ret = 'As an Other Client';
    }

    return ret;
  }

  getFormerSpouseLabel(): string {
    let ret: string = 'As a ' + this.context.contactLabel + ' (Former Spouse)';
    if (this.context.contactLabel == 'Other Client') {
      ret = 'As an Other Client (Former Spouse)';
    }

    return ret;
  }

  isSpouseAsFormerClientVisible(): boolean {
    return this.isProvinceNB() && !this.context.isOtherSide;
  }

  isSpouseAsFormConsentedVisible(): boolean {
    return this.isProvinceNB() && !this.context.isOtherSide;
  }

  isAsASpouseVisible(): boolean {
    return this.isProvinceNS() && !this.context.isOtherSide;
  }

  selectSuggestedSpouse(suggestedSpouse: SuggestedSpouse) {
    this.selectedSpouseId = suggestedSpouse.spouse && suggestedSpouse.spouse.id;
  }
}
