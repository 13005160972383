import {Injectable} from '@angular/core';
import {Contact, Matter} from '../shared';
import {Telephone} from '../shared/telephone';
import {EmailKeys} from '../../shared-main/email-field/email-field-service';
import Utils from '../../shared-main/utils';
import {ContactInfo} from '../shared/contact-info';
import {PhoneMortgageeSourceType} from '../../shared-main/constants';
import {MatterParticipant} from '../shared/matter-participant';
import {Mortgage} from '../shared/mortgage';
import {ContactQueryService} from '../../contact/contact-query.service';
import {TelephoneTypes} from '../shared/telephone-types';
import {OpportunityView} from '../../opportunities/opportunities-list/opportunity-view';
import * as _ from 'lodash';
import {OpportunitiesService} from '../../opportunities/opportunities.service';
import {EventData} from '../../event/event-data';
import {EventService} from '../../event/event.service';
import {MatterEventUtils} from '../shared/matter-utils/matter-event-utils';
import {MatterParticipantRole, MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';

export class InvolvedParty {
  role: string;
  name: string; //The name is not simple contact name. Sometime it appends other information.
  telephones: Telephone[] = [];
  emailKey: string;
  email: string;
  mortgage: Mortgage;
}

@Injectable()
export class MatterOverviewService {
  constructor(
    public contactQueryService: ContactQueryService,
    public opportunitiesService: OpportunitiesService,
    public eventService: EventService
  ) {
  }

  async addParticipantsToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    //Add main clients
    this.addMainClientsToInvolvedParties(matter, involvedParties);
    //Add other side clients
    this.addOtherSideClientsToInvolvedParties(matter, involvedParties);
    //Add other side Solicitor
    this.addOtherSideSolicitorToInvolvedParties(matter, involvedParties);
    //Add other law clerk
    this.addOtherSideLawClerkToInvolvedParties(matter, involvedParties);
    //Add Real Estate Agent and Broker
    await this.addRealEstateAgentAndBrokerToInvolvedParties(matter, involvedParties);
    //Add Mortgagees
    this.addMortgageesToInvolvedParties(matter, involvedParties);
    //Add Mortgage Solicitors
    this.addMortgageeSolicitorsToInvolvedParties(matter, involvedParties);
    //Add Mortgagees
    this.addMortgageBrokerToInvolvedParties(matter, involvedParties);
  }

  //All Clients (Topic B) are listed in physical order.
  addMainClientsToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    matter.mainClients.forEach((item, index) => {
      this.addParticipantToRecipient(involvedParties, index == 0 ? this.getMainClientTitle(matter) + '(s)' : '',
        this.getClientParticipantName(item),
        item && item.contact && item.contact.telephone,
        item && item.contact && item.contact.email,
        EmailKeys.matterOpening);
    });
  }

  getMainClientTitle(matter: Matter) {
    if (matter.isMortgage) {
      return provinceBasedFieldLabels.get('matter.overview.mortgagor.label', matter.provinceCode);
    } else {
      return matter.mainClientTitle;
    }
  }

  //All Clients (Topic C) are listed in physical order.
  addOtherSideClientsToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    matter.otherSideClients.forEach((item, index) => {
      this.addParticipantToRecipient(involvedParties, index == 0 ? matter.otherPartyTitle + '(s)' : '',
        this.getClientParticipantName(item),
        item && item.contact && item.contact.telephone,
        item && item.contact && item.contact.email,
        EmailKeys.matterOpening);
    });
  }

  addOtherSideSolicitorToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    const solicitor: MatterParticipant = matter.otherPartySolicitor;
    let participantName: string = '';
    if (solicitor && solicitor.contact && solicitor.contact.contactFullNameStartWithFirstName) {
      participantName = solicitor.contact.contactFullNameStartWithFirstName;
      const lawFirm: MatterParticipant = matter.otherPartyLawFirm;
      if (lawFirm && lawFirm.contact && lawFirm.contact.organizationName) {
        participantName = participantName + ' - ' + this.truncateString(lawFirm.contact.organizationName);
      }
      this.addParticipantToRecipient(involvedParties,
        this.getParticipantRole(matter),
        participantName,
        solicitor && solicitor.contact && solicitor.contact.telephone,
        solicitor && solicitor.contact && solicitor.contact.email,
        EmailKeys.vendorsSolicitor);
    }
  }

  getParticipantRole(matter: Matter): string {
    if (matter.isMortgage) {
      return `Other ${ provinceBasedFieldLabels.get('matter.title.solicitor', matter.provinceCode) }`;
    } else {
      return `${ matter.otherPartyTitle } ${ provinceBasedFieldLabels.get('matter.title.solicitor', matter.provinceCode) }`;
    }
  }

  getLawclerkTitle(matter: Matter): string {
    if (matter.isMortgage) {
      return `Other ${ provinceBasedFieldLabels.get('matter.title.solicitor', matter.provinceCode) }${ provinceBasedFieldLabels.get('matter.overview.lawclerkTitle', matter.provinceCode) }`;
    } else {
      return matter.otherPartyTitle + provinceBasedFieldLabels.get('matter.overview.lawclerkTitle', matter.provinceCode);
    }
  }

  addOtherSideLawClerkToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    const otherPartyContactInfo: ContactInfo = matter.otherPartyContactInfo;
    if (otherPartyContactInfo) {
      let participantName: string = '';
      if (otherPartyContactInfo.lawClerkName) {
        participantName = otherPartyContactInfo.lawClerkName;
        const lawFirm: MatterParticipant = matter.otherPartyLawFirm;
        if (lawFirm && lawFirm.contact && lawFirm.contact.organizationName) {
          participantName = participantName + ' - ' + this.truncateString(lawFirm.contact.organizationName);
        }
      }
      if (participantName) {
        this.addParticipantToRecipient(involvedParties, this.getLawclerkTitle(matter),
          participantName,
          otherPartyContactInfo.lawClerkTelephones,
          otherPartyContactInfo.lawClerkEmail,
          EmailKeys.vendorsSolicitor);
      }

    }
  }

  async addRealEstateAgentAndBrokerToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    const agent: MatterParticipant = matter.realEstateAgent;
    const broker: MatterParticipant = matter.realEstateBroker;
    const purchaseAgent: MatterParticipant = matter.purchaserRealEstateAgent;
    const purchaseBroker: MatterParticipant = matter.purchaserRealEstateBroker;

    if (agent && ((matter.isSale && broker) || (matter.isPurchase && matter.realEstateBrokerName))) {
      let participantName: string = this.getClientParticipantName(agent) + ' - ' + this.truncateString(broker ? this.getClientParticipantName(broker) : matter.realEstateBrokerName);

      this.addParticipantToRecipient(involvedParties,
        provinceBasedFieldLabels.get('matter.matterOverview.agentName', matter.provinceCode),
        participantName,
        agent && agent.contact && agent.contact.telephone,
        agent && agent.contact && agent.contact.email,
        EmailKeys.matterOpening);
    } else {
      if (agent) {
        this.addParticipantToRecipient(involvedParties,
          provinceBasedFieldLabels.get('matter.matterOverview.agentName', matter.provinceCode),
          this.getClientParticipantName(agent),
          agent && agent.contact && agent.contact.telephone,
          agent && agent.contact && agent.contact.email,
          EmailKeys.matterOpening);
      }
      if ((matter.isSale || matter.isPurchase) && broker) {
        this.addParticipantToRecipient(involvedParties,
          provinceBasedFieldLabels.get('matter.matterOverview.brokerName', matter.provinceCode),
          this.getClientParticipantName(broker),
          broker && broker.contact && broker.contact.telephone,
          broker && broker.contact && broker.contact.email,
          EmailKeys.matterOpening);
      }
      if (matter.isPurchase && matter.selectedBrokerId) {
        let brokerContact: Contact = await (this.contactQueryService.getContactForMatter(matter.selectedBrokerId)).toPromise();
        if (brokerContact) {
          this.addParticipantToRecipient(involvedParties, 'RE Broker',
            brokerContact.contactFullNameStartWithFirstName,
            brokerContact.telephone,
            brokerContact.email,
            EmailKeys.matterOpening);
        }
      }
    }
    if (matter?.commissionPaidToBothVendorAndPurchaserBroker) {
      const addParticipant = (agent, role) => {
        const participantFullName = this.constructParticipantFullName(
          this.getClientParticipantName(purchaseAgent),
          purchaseBroker ? this.getClientParticipantName(purchaseBroker) : ''
        );

        this.addParticipantToRecipient(
          involvedParties,
          role,
          participantFullName,
          agent?.contact?.telephone,
          agent?.contact?.email,
          EmailKeys.matterOpening
        );
      };

      if ((purchaseAgent || purchaseBroker) && matter?.isMatterProvinceBC) {
        addParticipant(purchaseAgent || purchaseBroker, purchaseAgent ? 'Selling Agent' : 'Selling Broker');
      }
    }
  }

  constructParticipantFullName(participantName: string, participantBrokerName: string): string {
    const names = [ participantName, participantBrokerName ].filter(name => name && name.trim() !== '');
    return names.join(' - ');
  }

  getAttentionTelephones(attentionMP: MatterParticipant, mortgageeMP: MatterParticipant): Telephone[] {
    let attentionTelephones: Telephone[] = [];
    if (attentionMP && attentionMP.contact && Array.isArray(attentionMP.contact.telephone)) {
      if (Array.isArray(attentionMP.contact.telephone)) {
        attentionTelephones = attentionMP.contact.telephone.map(item => {
          let telephone: Telephone = new Telephone(item);
          //Check if PhoneMortgageeSourceType.SAME_AS_MORTGAGEE
          if (telephone.telephoneNumber == PhoneMortgageeSourceType.SAME_AS_MORTGAGEE) {
            if (mortgageeMP && mortgageeMP.contact && mortgageeMP.contact.getPhone(item.phoneTypeCode)) {
              telephone.telephoneNumber = mortgageeMP.contact.getPhone(item.phoneTypeCode).telephoneNumber;
            } else {
              telephone.telephoneNumber = null;
            }
          }
          return telephone;
        });
      }
    }

    return attentionTelephones;
  }

  addMortgageesToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    matter.mortgages.concat(matter.existingMortgages)
    .slice()
    .sort((a: Mortgage, b: Mortgage) => a.mortgagePriority - b.mortgagePriority)
    .forEach((mortgage, index) => {
      let participantName: string = '';
      if (!mortgage.isCorrespondWithSolicitor(matter)) {
        let role = mortgage.isMortgageePrivateLender() ? 'PRIVATE_LENDER' : 'MORTGAGEE';
        const mortgageeMP = matter.getMatterParticipantByRoleAndMortgage(role, mortgage);
        if (mortgageeMP) {
          let attentionTelephones: Telephone[] = [];
          participantName = mortgageeMP && mortgageeMP.contact && mortgageeMP.contact.contactFullNameStartWithFirstName;
          const attentionMP: MatterParticipant = matter.getMatterParticipantByRoleAndMortgage('MORTGAGEE_ATTENTION', mortgage);
          // The attention of mortgagee is saved in MatterParticipant
          if (mortgage.isMortgageeAnInstitution()) {
            //Check if PhoneMortgageeSourceType.SAME_AS_MORTGAGEE
            attentionTelephones = this.getAttentionTelephones(attentionMP, mortgageeMP);
            if (attentionMP && attentionMP.contact && attentionMP.contact.contactName && attentionMP.contact.contactName.surnameLastFormatFullName) {
              participantName = attentionMP.contact.contactName.surnameLastFormatFullName + ' - ' + this.truncateString(participantName);
            }
          }
          //If it is ExistingMortgage, get the index of this.matter.existingMortgages
          let mortgageIndex = mortgage.isExistingMortgage() ? matter.existingMortgages.findIndex(item => item === mortgage) : index;
          const titleSuffix = this.resolveMortgageParticipantRoleSuffix(mortgage, matter);
          this.addParticipantToRecipient(
            involvedParties,
            this.getMortgageTitle(mortgage, mortgageIndex, matter) + titleSuffix,
            participantName,
            attentionMP ? attentionTelephones : mortgageeMP && mortgageeMP.contact && mortgageeMP.contact.telephone,
            attentionMP ? attentionMP && attentionMP.contact && attentionMP.contact.email : mortgageeMP && mortgageeMP.contact && mortgageeMP.contact.email,
            EmailKeys.mortgagee,
            mortgage
          );
        }
      }

    });
  }

  resolveMortgageParticipantRoleSuffix(mortgage: Mortgage, matter: Matter) {
    const {isMortgageBC, isMatterProvinceBC, isPurchase, isSale} = matter;
    const isExistingMortgage = mortgage.isExistingMortgage();

    let titleSuffix = isMortgageBC ? (isExistingMortgage ? ' Owner of Charge' : ' Lender') : ' Mortgagee';
    if ((isPurchase || isSale) && isMatterProvinceBC) {
      titleSuffix = ' Owner of Charge';
    }
    return titleSuffix;
  }

  addMortgageeSolicitorsToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    matter.mortgages.concat(matter.existingMortgages)
    .slice()
    .sort((a: Mortgage, b: Mortgage) => a.mortgagePriority - b.mortgagePriority)
    .forEach((mortgage, index) => {
      let participantName: string = '';
      if (mortgage.isCorrespondWithSolicitor(matter)) {
        let mortgageeSolicitorMP = matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', mortgage);
        if (mortgageeSolicitorMP && mortgageeSolicitorMP.contact) {
          participantName = mortgageeSolicitorMP.contact.contactFullNameStartWithFirstName;
          let legalFirm: MatterParticipant = matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', mortgage);
          if (legalFirm && legalFirm.contact && legalFirm.contact.organizationName) {
            participantName += ' - ' + this.truncateString(legalFirm.contact.organizationName);
          }
          let role = mortgage.isMortgageePrivateLender() ? 'PRIVATE_LENDER' : 'MORTGAGEE';
          let mortgageeMP = matter.getMatterParticipantByRoleAndMortgage(role, mortgage);
          if (mortgageeMP && mortgageeMP.contact && mortgageeMP.contact.contactFullNameStartWithFirstName) {
            participantName += ' - ' + this.truncateString(mortgageeMP.contact.contactFullNameStartWithFirstName);
          }

          //If it is ExistingMortgage, get the index of this.matter.existingMortgages
          let mortgageIndex = mortgage.isExistingMortgage ? matter.existingMortgages.findIndex(item => item === mortgage) : index;
          this.addParticipantToRecipient(involvedParties, this.getMortgageTitle(mortgage, mortgageIndex, matter) + this.getMortgageeSolicitorCorrectTailingText(matter),
            participantName,
            mortgageeSolicitorMP.contact.telephone,
            mortgageeSolicitorMP.contact.email,
            EmailKeys.mortgagee,
            mortgage);
        }
      }

    });
  }

  getMortgageeSolicitorCorrectTailingText(matter: Matter) {
    return matter?.isMatterProvinceBC ? ' Owner of Charge' : ' Solicitor';
  }

  addMortgageBrokerToInvolvedParties(matter: Matter, involvedParties: InvolvedParty[]) {
    matter.mortgages
    .slice()
    .sort((a: Mortgage, b: Mortgage) => a.mortgagePriority - b.mortgagePriority)
    .forEach((mortgage, index) => {
      let mortgageBrokerMP = matter.getMortgageBroker(mortgage);
      if (mortgageBrokerMP) {
        this.addParticipantToRecipient(involvedParties, this.getMortgageTitle(mortgage, index, matter) + ' Broker',
          mortgageBrokerMP && mortgageBrokerMP.contact && mortgageBrokerMP.contact.organizationName,
          mortgageBrokerMP && mortgageBrokerMP.contact && mortgageBrokerMP.contact.telephone,
          mortgageBrokerMP && mortgageBrokerMP.contact && mortgageBrokerMP.contact.email,
          EmailKeys.mortgagee,
          mortgage);
      }

    });
  }
  
  getMortgageTitle(mortgage: Mortgage, index: number, matter:Matter): string {
    const {isMortgage, isPurchase, isSale, isMatterProvinceBC} = matter;
    const isForBC = isMatterProvinceBC && (isMortgage || isPurchase || isSale)
    return mortgage.isExistingMortgage()
        ? Utils.getOrdinal(index + 1) + ` Existing ${isForBC ? "Reg" : "Mtge"}`
        : Utils.getOrdinal(mortgage.mortgagePriority) + ` New ${isForBC ? 'Mtge': 'Reg' }`;
}

  getClientParticipantName(matterParticipant: MatterParticipant): string {
    let participantName: string = '';
    if (matterParticipant && matterParticipant.contact && matterParticipant.contact.contactFullNameStartWithFirstName) {
      participantName = matterParticipant.contact.contactFullNameStartWithFirstName;
    }
    return participantName;
  }

  /**
   *
   * @param participantRole
   * @param participantName
   * @param telephones
   * @param email
   * @param emailKey
   */
  addParticipantToRecipient(involvedParties: InvolvedParty[], participantRole: string, participantName: string, telephones: Telephone[], email: string, emailKey: string, mortgage?: Mortgage) {
    let involvedParty: InvolvedParty = new InvolvedParty();
    involvedParty.role = participantRole;
    involvedParty.name = participantName;
    involvedParty.telephones = telephones;
    involvedParty.email = email;
    involvedParty.emailKey = emailKey;
    if (mortgage) {
      involvedParty.mortgage = mortgage;
    }
    involvedParties.push(involvedParty);
  }

  truncateString(text: string): string {
    if (text) {
      return text.length <= 30 ? text : text.substring(0, 30) + '...';
    } else {
      return '';
    }
  }

  getPartyPhones(telephones: Telephone[]): string {
    let orderedPhones = [];
    orderedPhones.push(telephones.find(phone => phone.phoneTypeCode == TelephoneTypes.work));
    orderedPhones.push(telephones.find(phone => phone.phoneTypeCode == TelephoneTypes.home));
    orderedPhones.push(telephones.find(phone => phone.phoneTypeCode == TelephoneTypes.cell));
    orderedPhones.push(telephones.find(phone => phone.phoneTypeCode == TelephoneTypes.fax));
    if (orderedPhones && orderedPhones.length) {
      let phones = orderedPhones.filter(phone => phone && phone.telephoneNumber).map(phone => phone.phoneWithLabel);
      return phones.join(', ');
    } else {
      return '';
    }

  }

  async getMatterOpportunities(matter: Matter): Promise<OpportunityView[]> {
    let opportunities = await this.opportunitiesService.getOpportunitiesByMatter(matter.id).toPromise();
    if (opportunities && opportunities.length) {
      opportunities = _.orderBy(opportunities, [ 'lastUpdateOn' ], [ 'asc' ]);
      let receivedOrOutstandingOrSubmittedItems = opportunities.filter(item => item.isPreferredOpportunity());
      let otherItems = opportunities.filter(item => !item.isPreferredOpportunity());
      return [ ...receivedOrOutstandingOrSubmittedItems, ...otherItems ];
    } else {
      return [];
    }
  }

  // gets appointments from API and the rest of the matter events from matter
  // it should only be used on initialization of components
  async getMatterEvents(matter: Matter): Promise<EventData[]> {
    // recreate the events if needed
    MatterEventUtils.createUpdateMatterEvents(matter);

    let events = matter.matterEvents;
    if (events) {
      return EventData.filterOutCanclePostoneEvent(events);
    } else {
      return [];
    }
  }

  getAppointmentScheduledFor(contactId: number, lawClerkSolicitorList: Contact[]): string {
    let scheduledFor = '';
    if (contactId && lawClerkSolicitorList && lawClerkSolicitorList.length) {
      let scheduledForContact = lawClerkSolicitorList.find(contact => contact.id == contactId);
      if (scheduledForContact) {
        scheduledFor = scheduledForContact.initials;
      }
    }
    return scheduledFor;
  }

  getFormatDetails(matter: Matter): string {
    if (matter.matterFlaggedReason) {
      return matter.matterFlaggedReason.split('\n').map(para => '<p class="padding-bottom-10">' + para + '</p>').join('');
    } else {
      return '';
    }

  }

  getSolicitorName(matter: Matter): string {
    return this.getParticipantContactName(matter, MatterParticipantRoleTypes.SOLICITOR);
  }

  getLawClerkName(matter: Matter): string {
    return this.getParticipantContactName(matter, MatterParticipantRoleTypes.LAWCLERK);
  }

  getParticipantContactName(matter: Matter, matterParticipantRole: MatterParticipantRole): string {
    const participant: MatterParticipant = matter && matter.matterParticipants && matter.matterParticipants.find(item => item.matterParticipantRole == matterParticipantRole);
    if (participant && participant.contact && participant.contact.contactName) {
      return participant.contact.contactName.surnameLastFullName;
    }
    return '';
  }

}
