<div class="container-fluid custom-modal-container border-radius">
    <div class="row modal-header">
        <div class="col-lg-12 padding-left-0">
            <div class="col-lg-10 padding-left-0"><h1>{{header}}</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="row modal-body">
        <div *ngFor="let purchaser of purchasers" class="purchasers-title-section">
            <div class="row">
                <div class="col-lg-12 boldfont margin-bottom-20">
                    <span class="h4">{{getPurchaserName(purchaser.contact.fullOrBusinessName)}}</span>
                </div>
                <div class="row">
                    <div class="col-lg-2 text-right">
                        DOB:
                    </div>
                    <div class="col-lg-10 padding-left-20">
                        {{formatDate(purchaser.contact.birthDateText)}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-2 text-right">
                        Gender:
                    </div>
                    <div class="col-lg-10 padding-left-20">
                        {{getPurchaserGenderLabel(purchaser)}}
                    </div>
                </div>

                <div class="row" *dpShowByProvince="'matter.titleDetails.capacity'">

                    <div class="col-lg-2 text-right">
                        Capacity:
                    </div>
                    <div class="col-lg-10 padding-left-20">
                        {{getPurchaserCapacity(purchaser)}}
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-2 text-right">
                        Share:
                    </div>
                    <div class="col-lg-10 padding-left-20">
                        {{purchaser.purchaserShare}}
                    </div>
                </div>

                <div class="row" *dpShowByProvince="'matter.titleDetails.fla'">

                    <div class="col-lg-2 text-right">
                        FLA:
                    </div>
                    <div class="col-lg-10 padding-left-20">
                        <div  *ngIf="purchaser.contact.gender == 'ESTATE'">
                            The deceased, {{purchaser.contact.surnameLastFullName}}, was at least eighteen years old and, at the time of
                            {{purchaser.contact.deceasedGender && purchaser.contact.deceasedGender.startsWith('F') ? 'her' : 'his'}} death, was **
                        </div>
                        <ng-container *ngIf="purchaser.contact.gender != 'ESTATE'">
                            <div *ngFor="let fla of purchaser.familyLawActs" class="fla-statement">
                                {{getFlaStatementDescription(fla)}}
                            </div>
                        </ng-container>

                    </div>
                </div>

                <div class="row">

                    <div class="col-lg-2 text-right">
                        Cdn Res:
                    </div>
                    <div class="col-lg-10 padding-left-20">
                        {{(purchaser.contact.canadianResidentFlag && purchaser.contact.canadianResidentFlag.startsWith('N')) ? 'No' : 'Yes'}}
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" (click)="close()" class="dp-btn focus-first keyboard-save-action">
                <span>OK</span>
            </button>
        </div>
    </div>
</div>
