<div *ngIf="!showWizardFields">
    <ng-container *ngIf="isMatterEditingDisabledAfterPostClosingDate">
        <div  class="property-teranet-header-title">
            <p class="property-teranet-header-font">
                The Property topic cannot be changed because the matter is past its closing date. Please contact Unity customer support if you have any questions.
            </p>
        </div>
    </ng-container>
    <div class="property-teranet-main-header">
        <ul class="dppm-custom-tab">
            <li routerLinkActive="active" [routerLink]="['subjectProperty']" (click)="isTeranetTabActive=false">
                <span>
                    Subject Property
                </span>
            </li>
            <li routerLinkActive="active" (click)="teranetConnectTabClicked()" *ngIf="isTeranetTabVisible" [ngClass]="{'active' : isTeranetTabActive}">
                <span class="{{tabLabFromColClass}}" dp-province-field-label="property.teranet.connect.tab.label" >
                    Teranet Connect
                </span >
            </li>
            <li routerLinkActive="active" *ngIf="isHoldbackTabVisible()" [routerLink]="['holdbacks']" (click)="isTeranetTabActive=false">
                <span>
                    Holdbacks
                </span >
            </li>
        </ul>
    </div>
    <div class="clear"></div>
    <router-outlet></router-outlet>
</div>
