<form class="form-horizontal" id="writNumberSearch">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect &trade; Writ No. Search</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="onCancel()">&times;</button>
            </div>
        </div>

        <div class="row modal-body">
            <div class="form-group padding-bottom-20">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right" for="enforcementOffice" >Enforcement Office:</label>
                </div>
                <div class="col-lg-4">
                    <select class="form-control"
                            id="enforcementOffice"
                            name="enforcementOffice"
                            [(ngModel)]="writNumberSearchRequest.enforcementOfficeNumber"
                    >
                        <option *ngFor="let eo of enforcementOffices" [value]="eo.enforcementOfficeNumber">
                            {{eo.enforcementOfficeName}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group padding-bottom-20">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right" for="writNumber" >Writ No.: </label>
                </div>
                <div class="col-lg-4">
                    <input type="text" id="writNumber" name="writNumber" placeholder="xx-xxxxxxx"
                           maxlength="10"
                           [(ngModel)]="writNumberSearchRequest.writNumber"
                           class="form-control" [fieldKey]="'teranet.searchWrit.invalidWrit'" dp-error-validator
                           (focusout)="validateWritFormat()">
                </div>
            </div>
            <div class="form-group padding-bottom-20">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right" for="reLine" >RE: </label>
                </div>
                <div class="col-lg-4">
                    <input type="text" id="reLine" name="reLine"
                           maxlength="27"
                           dp-uppercase
                           [(ngModel)]="writNumberSearchRequest.reLine"
                           class="form-control"  >
                </div>
            </div>
        </div>
        <div class="row modal-footer ">
            <div class="col-lg-12 text-right">
                <button id="okBtn" class="dp-btn" type="button" [disabled]="!writNumberSearchRequest.writNumber" (click)="searchWritByNumber()"  >
                    Proceed
                </button>
                <button class="dp-btn dp-btn-cancel" type="button" (click)="onCancel()">
                    Cancel
                </button>
            </div>
        </div>
        <div class="col-xs-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>
</form>

