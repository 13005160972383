<form class="form-horizontal" id="adminHomePage">
    <div class="modal-header">
        <div class="col-lg-12">
            <h1 style="display: inline-block;">Other Integration Services</h1>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="row modal-body padding-left-20 padding-right-20 padding-top-0">
        <div class="col-lg-12 fields-group margin-bottom-0 block">
            <div class="form-group main-level margin-bottom-0">
                <span class="title min-height-30 text-left">Title Insurance</span>
            </div>
            <div class="form-group">
                    <div class="col-lg-12 margin-bottom-10">
                        <ul class="col-lg-12"  *ngIf="isTitleInsuranceAvailable()">
                            <li [user-access]="{'operationName': authKeys.STEWART_NEXT_STEPS_INTEGRATIONS}"  class="col-lg-12" >
                                <div class="col-lg-4">
                                    <label class="control-label text-black">Stewart Title</label>
                                </div>
                                <div class="col-lg-8">
                                    <a class=" padding-right-10" (click)="openHomeToolsWindow()"  target="_blank">Home/Tools <i class="far fa-external-link"></i></a>
                                    <a class=" padding-right-10" (click)="openPremiumCalculator()"  target="_blank">Premium
                                        Calculator <i class="far fa-external-link"></i></a>
                                </div>

                            </li>
                            <li  [user-access]="{'operationName': authKeys.CHICAGO_TITLE_INTEGRATIONS}"  *ngIf="isChicagoTitleVisible()" class="col-lg-12" >
                                <div class="col-lg-4">
                                    <label class="control-label text-black">Chicago Title</label>
                                </div>
                                <div class="col-lg-8">
                                    <a class=" padding-right-10" href="{{localChicagoTitleLinks.quote}}" target="_blank">Premium Calculator
                                        <i class="far fa-external-link"></i></a>
                                </div>

                            </li>
                            <li [user-access]="{'operationName': authKeys.TITLE_PLUS_INTEGRATIONS}" *ngIf="isTitlePlusVisible() && hasTitlePLUSAccess()" class="col-lg-12" >
                                <div class="col-lg-4">
                                    <label class="control-label text-black">TitlePLUS</label>
                                </div>
                                <div class="col-lg-8" >
                                    <a class="padding-right-10" href="{{titlePlusPremiumCalculator}}" target="_blank">Premium Calculator <i class="far fa-external-link"></i></a><br>
                                </div>
                            </li>

                        </ul>
                        <div  class="form-group-warning-message-small col-lg-6 margin-bottom-0" *ngIf="!isTitleInsuranceAvailable()">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-5">
                                <p>{{titleInsuranceOrEmpNotSupportedMsg}}</p>
                            </div>
                        </div>
                    </div>
             </div>
        </div>

        <div class="col-lg-12 fields-group margin-bottom-0 block">
            <div class="form-group main-level margin-bottom-0 ">
                <span class="title min-height-30 text-left">Other Services</span>
            </div>
            <div class="form-group">
                <div class="col-lg-12 margin-bottom-10">
                    <ul class="col-lg-12" >
                        <li *ngIf="hasFCTAccess() && isFctEnabled()" (click)="openFctInfo('PAYOUTSTMT')" class="col-lg-12" >
                            <div class="col-lg-4">
                                <label class="control-label">LLC Payout Services</label>
                            </div>
                            <div class="col-lg-8">
                                LLC Payout Services is a product offering from FCT, it allows you to request, process and receive your payout statements online from a participating lender.
                            </div>
                        </li>
                        <li  *ngIf="showDownloadAccExpFileOpt && isStatementConfigAvailable" (click)="openAccountingExportModal()" class="col-lg-12"  >
                            <div class="col-lg-4">
                                <label class="control-label">Download Accounting Export Files</label>
                            </div>
                            <div class="col-lg-8">
                                Export all Journal entries
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>


    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button class="dp-btn" type="button" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>
