import {Directive} from '@angular/core';
import {NgModel} from '@angular/forms';

import {LINC_NUMBER_MAX_LENGTH_BC} from '../shared-main/constants';

@Directive({
  selector: '[ngModel][dp-pidnumber]',
  host: {
    '(keypress)': 'onInputChange($event.target.value)',
    '(paste)': 'onPaste($event)',
    '(blur)': 'onInputChange($event.target.value)'
  }
})
export class PidNumberDirective {

  constructor(private model: NgModel) {
  }

  onPaste($event: ClipboardEvent) {
    $event.preventDefault();
    if ($event.clipboardData) {
      let pid: string = $event.clipboardData.getData('text/plain');
      this.onInputChange(pid);
    }
  }

  onInputChange(value) {
    let pid = value.replace(/[\W_]+/g, '');
    const pidLength = pid.length;
    if (pidLength === 0) {
      pid = '';
    } else {
      pid = this.formatInput(pid);
    }
    this.model.control.setValue(pid.substring(0, LINC_NUMBER_MAX_LENGTH_BC), {emitEvent: false});
  }

  formatInput(value): string {
    value = value.substring(0, 25);
    value = value.replace(/\D/g, '');
    value = value.replace(/^([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})/, '$1 $2 $3 $4').trim();
    if ((value.length + 1) % 4 == 0 && value.length <= 9) {
      value += ' ';
    }
    return value;
  }
}
