import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {Matter} from '../../../shared/matter';
import {MatterProperty} from '../../../shared/matter-property';
import {RegistryOfficeService} from '../../registry-office/registry-office.service';
import {Jurisdiction, RegistryOffice} from '../../../shared/jurisdiction';
import {JurisdictionService} from '../../jurisdiction.service';
import {Observable, Subject} from 'rxjs';
import {AutoComplete} from 'primeng/autocomplete';
import {TeranetImportData} from '../../../../shared-main/teranet/teranet-import-data';
import {Address} from '../../../shared/address';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {UnitLevelPlanModalComponent} from '../../unit-level-plan/unit-level-plan.component';
import {StatementAdjustment} from '../../../statement-adjustment/statement-adjustment';
import {CondominiumExpense} from '../../unit-level-plan/condominium-expense';
import * as _ from 'lodash';
import {CondominiumPlan} from '../../unit-level-plan/condominium-plan';
import {UnitLevelPlanUtil} from '../../unit-level-plan/unit-level-plan-util';
import {ProvinceCode} from '../../../../admin/accounts/shared/base-province';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {
  condoPlanTypeOptions,
  ProvinceBasedInterestEstateOptions
} from '../../../../shared-main/province-based-dropdowns';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {ContactService} from '../../../../shared-main/contact.service';
import {Logger} from '@nsalaun/ng-logger';
import {ParcelLegalDescription} from '../../../shared/parcel-legal-description';
import {ParcelTitle} from '../../../shared/parcel-title';
import {Utils} from '../../../shared';
import {PROVINCE_CODES} from '../../../shared/user-province';
import {ImportPropertyDataService} from '../../../shared/services/import-property-data.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class TeranetImportContext {
  matter: Matter;
  selectedParcelRegister: ParcelRegister;
  isWizard?: boolean;
}

@Component({
  selector: 'dp-teranet-import-modal-content',
  templateUrl: 'teranet-import.modal.component.html',
  providers: [ErrorService, RegistryOfficeService],
  styleUrls: ['./teranet-import.modal.component.scss']
})
export class TeranetImportModal extends ModalComponent<TeranetImportContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  matterProperty: MatterProperty;
  teranetImportDataTC: TeranetImportData; //Teranet Connect
  teranetImportDataSP: TeranetImportData; //Subject Property
  interestEstates: SelectItem[];
  interestEstateLabel: string = '';
  // Jurisdiction
  jurisdictions: any;
  jurisdictionsLoading: boolean = false;
  searchTermJurisdiction = new Subject<string>();
  jurisdictionReadOnly: any;
  selectedJurisdiction: Jurisdiction;
  jurisdictionAutoCompleteEmptyFlag: boolean = false;
  @ViewChild('jurisdictionOmnibar') jurisdictionOmnibar: AutoComplete;
  @ViewChild('condoCorporationOmnibar') condoCorporationOmnibar: AutoComplete;

  condoTypes: SelectItem[] = condoPlanTypeOptions.condoPlanOptions_AB;
  mineralRightsOptions = dropDowns.isExceptionDescriptionOptions;
  condominiumPlanType: string;
  exceptionType: string;
  exceptionYNOptions: SelectItem[] = booleanYesNoDropDowns.YesNo_DefaultBlank;
  exceptionTypeDescriptions: SelectItem[] = dropDowns.unitPlanExceptionsTypes;
  filteredExceptionTypeOptions: any[];
  utils = new Utils();

  constructor(
    public dialog: MatDialogRef<TeranetImportModal>,
    public contactQueryService: ContactQueryService,
    public contactService: ContactService,
    public logger: Logger,
    public dialogService: DialogService,
    public appConfig: AppConfig,
    public jurisdictionService: JurisdictionService,
    public registryOfficeService: RegistryOfficeService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetImportContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.interestEstates = ProvinceBasedInterestEstateOptions[this.context.matter.provinceCode];

    // Jurisdictions

    this.searchTermJurisdiction
      .switchMap((term: string) => {
        this.jurisdictionsLoading = true;
        this.jurisdictionAutoCompleteEmptyFlag = false;
        if (term.trim() === '') {
          this.jurisdictionAutoCompleteEmptyFlag = true;
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
          return observable;
        } else {
          return this.jurisdictionService.getJurisdictions(term);
        }
      })
      .subscribe(
        (jurisdictions: any[]) => {
          this.jurisdictionsLoading = false;
          return (this.jurisdictions = jurisdictions);
        },
        (error) => {
          this.jurisdictionsLoading = false;
        }
      );

    if (this.context.matter && this.context.matter.matterPropertyWithCondo && this.parcelRegister) {
      this.matterProperty = new MatterProperty(this.context.matter.matterPropertyWithCondo);
      this.selectedJurisdiction = this.context.matter.matterPropertyWithCondo.jurisdiction;
      if (this.selectedJurisdiction) {
        this.jurisdictionReadOnly = true;
      }
      this.teranetImportDataTC = new TeranetImportData();
      this.teranetImportDataTC.provinceCode = this.context.matter.provinceCode;
      this.teranetImportDataTC.mapParcelRegisterData(new ParcelRegister(this.parcelRegister));
      this.teranetImportDataSP = new TeranetImportData();
      this.teranetImportDataSP.provinceCode = this.context.matter.provinceCode;
      this.teranetImportDataSP.mapSubjectPropertyData(this.matterProperty);
      this.teranetImportDataSP.parcelRegisterId = this.parcelRegister.id; //needed for first spin import
      this.updatePin(false);
      if (this.isUnitLevelPlanAlreadyAdded > -1 && this.teranetImportDataTC.pin) {
        this.teranetImportDataSP.unitLevelPlan =
          'Unit ' +
          this.unitNumber +
          ', Level ' +
          this.levelNumber +
          ', ' +
          this.teranetImportDataTC.pin.replace('-', '');
      }
      this.initializeProvinceSpecificImportData(this.context.matter.provinceCode);
    } else if (this.context.isWizard) {
      this.close();
    }
    if (this.teranetImportDataSP && this.interestEstates) {
      let interestEstate = this.interestEstates.find((item) => item.value == this.matterProperty.interestEstate);
      if (interestEstate && interestEstate.label) {
        this.interestEstateLabel = interestEstate.label.toLowerCase();
      }
    }
  }

  async initializeProvinceSpecificImportData(provinceCode: ProvinceCode): Promise<void> {
    if (PROVINCE_CODES.ALBERTA == provinceCode) {
      await this.initializeAlbertaImportFields();
    } else if (PROVINCE_CODES.MANITOBA == provinceCode) {
      await this.initializeImportManitobaFields();
    } else if (PROVINCE_CODES.SASKATCHEWAN == provinceCode) {
      await this.initializeImportSKFields();
    } else if (PROVINCE_CODES.BRITISH_COLOMBIA == provinceCode) {
      await this.initializeBCImportFields();
    } else {
      await this.initializeOntarioImportFields();
    }
    if (this.context.isWizard) {
      this.updateAllFields(true);
      this.ok();
    }
  }

  handleDropdownClickExceptionTypeDescription = () =>
    (this.filteredExceptionTypeOptions = this.exceptionTypeDescriptions.map((item) => item.label));

  filterExceptionTypeDescription(event) {
    this.filteredExceptionTypeOptions = this.exceptionTypeDescriptions
      .map((item) => item.label)
      .filter((label) => label && label.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
  }

  initCondominiumUnitType(): void {
    if (
      this.teranetImportDataTC.isCondo &&
      this.teranetImportDataTC.condominiumPlans &&
      this.teranetImportDataTC.condominiumPlans.length > 0 &&
      this.teranetImportDataTC.condominiumPlans[0].condominiumPlanType
    ) {
      let condoItem = this.condoTypes.find(
        (item) => item.value === this.teranetImportDataTC.condominiumPlans[0].condominiumPlanType
      );
      this.condominiumPlanType = condoItem && condoItem.label;
    }
    if (this.teranetImportDataTC.isCondo || this.teranetImportDataTC.isPlanBlockLot) {
      let exceptionType = this.exceptionYNOptions.find((item) => item.value === this.teranetImportDataTC.exceptionType);
      this.exceptionType = exceptionType && exceptionType.label;
    }

    if (
      this.teranetImportDataTC.isCondo &&
      !this.condominiumPlanType &&
      Array.isArray(this.teranetImportDataSP.condominiumPlans) &&
      this.teranetImportDataSP.condominiumPlans.length > 0
    ) {
      this.teranetImportDataSP.condominiumPlans[0].condominiumPlanType = 'CONVENTIONAL_CONDOMINIUM_PLAN';
    }
  }

  async initializeBCImportFields(): Promise<void> {
    this.teranetImportDataSP.lincNumber = this.matterProperty.lincNumber;
    if (this.parcelRegister.spinImportData.municipality) {
      await this.initializeImportJurisdiction(
        this.parcelRegister.spinImportData.municipality,
        this.context.matter.provinceCode,
        null,
        true
      );
    }
  }

  async initializeAlbertaImportFields(): Promise<void> {
    await this.initializeImportJurisdiction(
      this.parcelRegister.spinImportData.municipality,
      this.context.matter.provinceCode,
      null,
      true
    );
    this.initCondominiumUnitType();
  }

  async initializeImportManitobaFields(): Promise<void> {
    await this.initializeImportManitobaJurisdiction();
  }

  async initializeImportSKFields(): Promise<void> {
    await this.initializeImportSKJurisdiction();
    this.initCondominiumUnitType();
  }

  async initializeImportManitobaJurisdiction(): Promise<void> {
    let city: string = this.teranetImportDataTC.address ? (this.teranetImportDataTC.address as Address).city : null;
    if (city) {
      await this.initializeImportJurisdiction(city, this.context.matter.provinceCode);
    }
  }

  async initializeImportSKJurisdiction(): Promise<void> {
    let city: string = this.teranetImportDataTC.city ? this.teranetImportDataTC.city : null;
    if (city) {
      await this.initializeImportJurisdiction(city, this.context.matter.provinceCode, true);
    }
  }

  async initializeOntarioImportFields(): Promise<void> {
    await this.populateLroList();
  }

  get parcelRegister(): ParcelRegister {
    return this.context.selectedParcelRegister;
  }

  ok(): void {
    if (this.selectedJurisdiction && this.selectedJurisdiction.jurisdictionName) {
      this.teranetImportDataSP.jurisdiction = new Jurisdiction(this.selectedJurisdiction);
    }
    if (this.teranetImportDataSP.unitLevelPlan) {
      this.teranetImportDataSP.condominiumExpenses = this.matterProperty.condominiumExpenses;
      this.teranetImportDataSP.condominiumPlans = this.matterProperty.condominiumPlans;
      this.teranetImportDataSP.condominiumTotalExpenses = this.matterProperty.condominiumTotalExpenses;
      this.teranetImportDataSP.condominiumJurisdiction = this.matterProperty.condominiumJurisdiction;
      this.teranetImportDataSP.pin = UnitLevelPlanUtil.generatePinNumber(this.matterProperty);
    }

    if (!this.teranetImportDataSP.isCondo) {
      this.teranetImportDataSP.propertyDescriptionType = this.teranetImportDataTC.propertyDescriptionType;
    }

    //legal description considered updated only if one or more of these fields being imported (box is checked)
    //using notation like this['fieldPlan'] instead of this.fieldPlan because these properties are not defined in the component class, they are only in the HTML template, eg [(ngModel)]="fieldPlan"
    if (this.teranetImportDataSP.isPlanBlockLot) {
      if (this['fieldPlan'] || this['fieldBlock'] || this['fieldLot'] || this['allFields'] || this.context.isWizard) {
        this.teranetImportDataSP.isLegalDescriptionUpdated = true;
        this.teranetImportDataSP.isShortDescriptionUpdated = true;
      }
    } else if (this.teranetImportDataSP.isMetesAndBounds) {
      if (this['fieldFullLegalDescription'] || this['allFields'] || this.context.isWizard) {
        this.teranetImportDataSP.isLegalDescriptionUpdated = true;
      }
      if (this['fieldShortLegalDescription'] || this['allFields'] || this.context.isWizard) {
        this.teranetImportDataSP.isShortDescriptionUpdated = true;
      }
    } else if (this.teranetImportDataSP.isCondo) {
      if (
        this['fieldCondominiumPlanNumber'] ||
        this['fieldExpensesUnit'] ||
        this['allFields'] ||
        this.context.isWizard
      ) {
        this.teranetImportDataSP.isLegalDescriptionUpdated = true;
        this.teranetImportDataSP.isShortDescriptionUpdated = true;
      }
    }

    if (
      this.context.matter.isMatterProvinceBC &&
      (this['fieldFullLegalDescription'] || this['allFields'] || this.context.isWizard)
    ) {
      this.teranetImportDataSP.isLegalDescriptionUpdated = true;
    }
    this.dialog.close(this.teranetImportDataSP);
  }

  close(): void {
    this.dialog.close();
  }

  async populateLroList(): Promise<void> {
    let res: RegistryOffice[] = await this.registryOfficeService.getAllRegistryOffices().toPromise();
    if (res && res.length > 0) {
      let parsedJurisdiction: string = this.parseJurisdiction();
      if (parsedJurisdiction) {
        await this.initializeImportJurisdiction(parsedJurisdiction, this.context.matter.provinceCode);
      }
    }
  }

  async initializeImportJurisdiction(
    queryString: string,
    provinceCode,
    searchCity?: boolean,
    searchWithCityORJurisdictionName: boolean = false
  ): Promise<void> {
    let sort: string = 'id|ASC';
    let query: string;
    let escapeQueryString = Utils.escapeSearchText(queryString);

    if (searchCity) {
      query = 'city.name_EQ_' + escapeQueryString;
    } else if (searchWithCityORJurisdictionName) {
      query = 'ANYcity.name_EQ_' + escapeQueryString + '|jurisdictionName_EQ_' + escapeQueryString;
    } else {
      query = 'jurisdictionName_EQ_' + escapeQueryString;
    }
    query = query + `,provinceCode_EQ_` + provinceCode;
    let jurisdictions: Jurisdiction[] = await this.jurisdictionService.getAllJurisdictions(query, sort).toPromise();
    if (jurisdictions && jurisdictions.length > 0) {
      let jurisdiction: Jurisdiction;
      if (searchCity) {
        jurisdiction = jurisdictions.find(
          (item) =>
            item.city && item.city.name && item.city.name.toUpperCase().trim() == queryString.toUpperCase().trim()
        );
      } else if (searchWithCityORJurisdictionName) {
        jurisdiction = jurisdictions.find(
          (item) =>
            (item.city && item.city.name && item.city.name.toUpperCase().trim() == queryString.toUpperCase().trim()) ||
            item.jurisdictionName.toUpperCase().trim() == queryString.toUpperCase().trim()
        );
      } else {
        jurisdiction = jurisdictions.find(
          (item) =>
            item.jurisdictionName && item.jurisdictionName.toUpperCase().trim() == queryString.toUpperCase().trim()
        );
      }
      if (jurisdiction) {
        this.teranetImportDataTC.jurisdiction = new Jurisdiction(jurisdiction);
        this.teranetImportDataTC.registryOffice = jurisdiction.registryOffice;
        this.teranetImportDataTC.city = jurisdiction.city.name;
        this.teranetImportDataTC.municipality = jurisdiction.city.municipality.name;
      }
    }
  }

  public parseJurisdiction(): string {
    let parsedJurisdiction: string = '';
    if (
      this.parcelRegister &&
      this.parcelRegister.propertyDescription &&
      this.parcelRegister.propertyDescription.includes(';')
    ) {
      let descriptionSections: string[] = this.parcelRegister.propertyDescription
        .split(';')
        .map((section) => section.trim());

      if (descriptionSections.length > 0) {
        parsedJurisdiction = descriptionSections.pop();
        parsedJurisdiction = parsedJurisdiction.replace(/LONDON\/WESTMINSTER/g, 'LONDON');
        parsedJurisdiction = parsedJurisdiction.replace(/CITY|CITY OF|TOWN OF|REGION OF|VILLAGE OF|COUNTY OF/g, '');
      }
    }

    return parsedJurisdiction.trim();
  }

  get isAddressCheckboxVisible(): boolean {
    return (
      this.teranetImportDataTC.address &&
      (!this.matterProperty.address ||
        (ImportPropertyDataService.hasObjectAddress(this.context.matter.provinceCode)
          ? this.compareAddress
          : this.compareAddressString))
    );
  }

  get compareAddressString(): boolean {
    let matterPropertyAddress = this.matterProperty.address.addressTextWithoutCountry.replace(/ /g, '');
    let teranetImportDataTCAddress = (this.teranetImportDataTC.address as string).replace(/ /g, '');
    return matterPropertyAddress != teranetImportDataTCAddress;
  }

  get compareAddress(): boolean {
    let matterPropertyAddress =
      this.matterProperty.address &&
      this.matterProperty.address.addressTextWithoutCountry &&
      this.matterProperty.address.addressTextWithoutCountry.replace(/ /g, '');
    let teranetImportDataTCAddress =
      this.teranetImportDataTC.address &&
      (this.teranetImportDataTC.address as Address).addressTextWithoutCountry &&
      (this.teranetImportDataTC.address as Address).addressTextWithoutCountry.replace(/ /g, '');
    return matterPropertyAddress != teranetImportDataTCAddress;
  }

  get teranetImportDataSPAddress(): string {
    return (
      this.teranetImportDataSP &&
      this.teranetImportDataSP.address &&
      (ImportPropertyDataService.hasObjectAddress(this.context.matter.provinceCode)
        ? (this.teranetImportDataSP.address as Address).addressTextWithoutCountry
        : (this.teranetImportDataSP.address as string))
    );
  }

  // Code for omnibar search jurisdiction
  searchJurisdiction(event): void {
    let entered: string = event.query;
    this.searchTermJurisdiction.next(entered);
  }

  // On jurisdiction input data select event
  dataSelectedJurisdiction(): void {
    if (this.selectedJurisdiction.id === undefined) {
      this.selectedJurisdiction = undefined;
      this.matterProperty.registryOfficeName = '';
      this.matterProperty.registryOffice = undefined;
      this.matterProperty.city = '';
      //this.matterProperty.municipality = '';
    } else {
      this.jurisdictionReadOnly = true;
      // this.selectedJurisdiction.
      // if jurisdiction is selected, populate these fields from here
      this.updateMatterPropertyJurisdiction();
    }

    this.dialogService.setFocusOnAutocomplete('autocomplete-jurisdiction', true);
  }

  updateMatterPropertyJurisdiction(): void {
    if (this.selectedJurisdiction) {
      if (this.selectedJurisdiction.registryOffice) {
        this.matterProperty.registryOfficeName = this.selectedJurisdiction.registryOffice.officeName;
      }
      this.matterProperty.registryOffice = this.selectedJurisdiction.registryOffice;
      this.teranetImportDataSP.registryOffice = this.selectedJurisdiction.registryOffice;
      if (this.selectedJurisdiction.city) {
        this.matterProperty.city = this.selectedJurisdiction.city.name;
        this.teranetImportDataSP.city = this.selectedJurisdiction.city.name;
        if (this.selectedJurisdiction.city.municipality) {
          this.matterProperty.municipality = this.selectedJurisdiction.city.municipality.name;
          this.teranetImportDataSP.municipality = this.selectedJurisdiction.city.municipality.name;
        }
      }
    }
  }

  deleteJurisdiction(): void {
    this.dialogService
      .confirm('Confirmation', 'Proceed to remove the Jurisdiction ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.jurisdictionReadOnly = false;
          this.matterProperty.registryOfficeName = '';
          this.matterProperty.registryOffice = undefined;
          this.matterProperty.city = '';
          this.matterProperty.jurisdiction = undefined;
          this.matterProperty.municipality = '';
          this.selectedJurisdiction = null;
          this.teranetImportDataSP.registryOffice = undefined;
          this.teranetImportDataSP.municipality = '';
          this.teranetImportDataSP.city = '';
          this.dialogService.setFocusOnAutocomplete('autocomplete-jurisdiction');
        }
      });
  }

  onBlurJurisdiction(event) {
    setTimeout(() => {
      //console.warn("onBlurJurisdiction | selectedJurisdiction=", this.selectedJurisdiction);
      let fieldKey = this.jurisdictionOmnibar.el.nativeElement.getAttribute('fieldKey');
      if (this.selectedJurisdiction && typeof this.selectedJurisdiction === 'string') {
        this.selectedJurisdiction = null;
      }
    }, 200);
  }

  checkIfJurisdictionEmpty(selectedJurisdiction: string): void {
    if (!selectedJurisdiction || selectedJurisdiction.length === 0) {
      this.matterProperty.jurisdiction = null;
    }
  }

  updateJurisdictionPT(event): void {
    if (this.teranetImportDataTC && this.teranetImportDataTC.jurisdiction) {
      if (event) {
        this.selectedJurisdiction = new Jurisdiction(this.teranetImportDataTC.jurisdiction);
        this.teranetImportDataSP.city = this.teranetImportDataTC.city;
        this.teranetImportDataSP.municipality = this.teranetImportDataTC.municipality;
        this.teranetImportDataSP.registryOffice = this.teranetImportDataTC.registryOffice;
      } else {
        this.selectedJurisdiction = this.matterProperty.jurisdiction
          ? new Jurisdiction(this.matterProperty.jurisdiction)
          : null;
        this.teranetImportDataSP.city = this.matterProperty.city;
        this.teranetImportDataSP.municipality = this.matterProperty.municipality;
        this.teranetImportDataSP.registryOffice = this.matterProperty.registryOffice;
      }

      this.jurisdictionReadOnly = this.selectedJurisdiction != undefined && this.selectedJurisdiction != null;
    }
  }

  updateSubjectProperty(event, field: string, subField?: string): void {
    if (event && this.teranetImportDataTC[field]) {
      if (field == 'interestEstate') {
        let interestEstate = this.interestEstates.find(
          (item) => item.label.toLowerCase() == this.teranetImportDataTC[field].trim().toLowerCase()
        );
        if (interestEstate) {
          this.teranetImportDataSP[field] = interestEstate.value;
        }
      } else if (field == 'condominiumExpenses') {
        // Allow access sub-field
        this.teranetImportDataSP.condominiumExpenses[0][subField] =
          this.teranetImportDataTC.condominiumExpenses[0][subField];
      } else if (field == 'condominiumPlans') {
        // Allow access sub-field
        this.teranetImportDataSP.condominiumPlans[0][subField] = this.teranetImportDataTC.condominiumPlans[0][subField];
      } else if (
        field == 'block' ||
        field == 'lot' ||
        field == 'exceptionType' ||
        field == 'exceptionTypeDescription' ||
        field == 'exceptionTypeDescription'
      ) {
        // Allow the editing of the following fields once information is brought across to provide the user the opportunity to adjust
        // Avoid to lose adjustment data when all checkbox is selected
        if (!this.teranetImportDataSP[field]) {
          this.teranetImportDataSP[field] = this.teranetImportDataTC[field] as any;
        }
        this.teranetImportDataSP[field] = this.teranetImportDataTC[field] as any;
      } else if (field == 'parcelLegalDescription') {
        if (
          subField &&
          subField.includes('.') &&
          subField.includes('parcelTitles') &&
          this.teranetImportDataSP.parcelLegalDescription.parcelTitles.length > 0
        ) {
          let updatedSubField = subField.split('.')[1];
          this.teranetImportDataSP.parcelLegalDescription.parcelTitles[0][updatedSubField] =
            this.teranetImportDataTC.parcelLegalDescription.parcelTitles[0][updatedSubField];
        } else {
          this.teranetImportDataSP.parcelLegalDescription[subField] =
            this.teranetImportDataTC.parcelLegalDescription[subField];
        }
      } else if (field == 'address' && this.teranetImportDataTC.address instanceof Address) {
        let importedAddress: Address = new Address(this.teranetImportDataTC.address as Address);
        importedAddress.changeToMixedCase();
        this.teranetImportDataSP[field] = importedAddress;
      } else if (field == 'titleNumber') {
        this.teranetImportDataSP.titleNumber = this.teranetImportDataTC.titleNumber
          ? this.teranetImportDataTC.titleNumber.replace(/\W/g, '')
          : '';
      } else {
        this.teranetImportDataSP[field] = this.teranetImportDataTC[field];
      }
    } else if (!event && field == 'parcelLegalDescription') {
      if (
        subField &&
        subField.includes('.') &&
        subField.includes('parcelTitles') &&
        this.teranetImportDataSP.parcelLegalDescription.parcelTitles.length > 0
      ) {
        let updatedSubField = subField.split('.')[1];
        this.teranetImportDataSP.parcelLegalDescription.parcelTitles[0][updatedSubField] = null;
      } else {
        if (subField && subField == 'parcelNumber') {
          this.updateParcelNumberDependantFields();
        }
        if (subField && subField == 'mineral') {
          this.teranetImportDataSP.parcelLegalDescription.mineralRights = null;
        }
        this.teranetImportDataSP.parcelLegalDescription[subField] = null;
      }
    } else if (!event && field == 'address' && this.matterProperty[field]) {
      if (ImportPropertyDataService.hasObjectAddress(this.context.matter.provinceCode)) {
        this.teranetImportDataSP[field] = this.matterProperty[field];
      } else {
        this.teranetImportDataSP[field] = (this.matterProperty[field] as Address).addressTextWithoutCountry;
      }
    } else if (!event && field == 'condominiumExpenses') {
      this.teranetImportDataSP.condominiumExpenses[0][subField] = null;
    } else if (!event && field == 'condominiumPlans') {
      this.teranetImportDataSP.condominiumPlans[0][subField] = null;
    } else if (
      field == 'block' ||
      field == 'lot' ||
      field == 'exceptionType' ||
      field == 'exceptionTypeDescription' ||
      field == 'exceptionTypeDescription'
    ) {
      this.teranetImportDataSP[field] = null;
    } else if (!event) {
      this.teranetImportDataSP[field] = this.matterProperty[field];
    }
    this.updateBCStrataProperty(event, field);
  }

  private updateBCStrataProperty(event, field: string) {
    if (this.context.matter.isMatterProvinceBC) {
      if (event && field == 'isCondo') {
        this.teranetImportDataSP[field] = this.teranetImportDataTC[field];
      }
      if (!event && field == 'isCondo') {
        this.teranetImportDataSP[field] = this.matterProperty.isPropertyCondominium();
      }
    }
  }

  isTitleNumberDifferent(): boolean {
    if (this.teranetImportDataTC && this.matterProperty) {
      return (
        this.teranetImportDataTC.titleNumber &&
        this.teranetImportDataTC.titleNumber.replace(/\W/g, '') != this.matterProperty.titleNumber
      );
    } else {
      return true;
    }
  }

  updateParcelNumberDependantFields() {
    if (this.parcelRegister.iscImportData.farmLand) {
      this['fieldTcISCTwp'] = false;
      this['fieldISCParcelNumber'] = false;
      this['fieldTcISCQtrSec'] = false;
      this['fieldTcISCSec'] = false;
      this['fieldTcISCRng'] = false;
      this['fieldTcISCMdn'] = false;
      this.teranetImportDataSP.parcelLegalDescription.qtrsec = null;
      this.teranetImportDataSP.parcelLegalDescription.sec = null;
      this.teranetImportDataSP.parcelLegalDescription.twp = null;
      this.teranetImportDataSP.parcelLegalDescription.rng = null;
      this.teranetImportDataSP.parcelLegalDescription.mdn = null;
    } else {
      this['fieldTcISCPlanNumber'] = false;
      this['fieldTcISCBlockNumber'] = false;
      this['fieldTcISCLotNumber'] = false;
      this.teranetImportDataSP.parcelLegalDescription.lotNumber = null;
      this.teranetImportDataSP.parcelLegalDescription.planNumber = null;
      this.teranetImportDataSP.parcelLegalDescription.blockNumber = null;
    }
    this['fieldTcISCExtension'] = false;
    this.teranetImportDataSP.parcelLegalDescription.extensionNumber = null;
  }

  get registryOfficeTC(): string {
    return this.teranetImportDataTC && this.teranetImportDataTC.registryOffice
      ? this.teranetImportDataTC.registryOffice.shortOfficeName +
          ' (No. ' +
          this.teranetImportDataTC.registryOffice.formattedOfficeNumber +
          ')'
      : '';
  }

  get registryOfficeSP(): string {
    return this.teranetImportDataSP && this.teranetImportDataSP.registryOffice
      ? this.teranetImportDataSP.registryOffice.shortOfficeName +
          ' (No. ' +
          this.teranetImportDataSP.registryOffice.formattedOfficeNumber +
          ')'
      : '';
  }

  get registryOfficeMatterProperty(): string {
    return this.matterProperty && this.matterProperty.registryOffice
      ? this.matterProperty.registryOffice.shortOfficeName +
          ' (No. ' +
          this.matterProperty.registryOffice.formattedOfficeNumber +
          ')'
      : '';
  }

  get isPinAlreadyAdded(): boolean {
    return (
      this.extractPinList().findIndex(
        (item) => this.teranetImportDataTC && this.teranetImportDataTC.pin.replace('-', '') == item
      ) > -1
    );
  }

  get unitNumber(): string {
    if (this.teranetImportDataTC) {
      return this.teranetImportDataTC.unitNo;
    }
  }

  get levelNumber(): string {
    if (this.teranetImportDataTC) {
      return this.teranetImportDataTC.levelNo;
    }
  }

  get isUnitLevelPlanAlreadyAdded(): number {
    let condominiumExpenseIndex = -1;
    let unitNo = this.unitNumber;
    let levelNo = this.levelNumber;
    if (
      unitNo &&
      levelNo &&
      this.teranetImportDataTC.pin &&
      this.context.matter &&
      this.context.matter.matterPropertyWithCondo
    ) {
      condominiumExpenseIndex = this.context.matter.matterPropertyWithCondo.condominiumExpenses.findIndex(
        (item) =>
          item.pinNumber &&
          item.levelNumber == levelNo &&
          item.unitNumber == unitNo &&
          item.pinNumber.replace('-', '') == this.teranetImportDataTC.pin.replace('-', '')
      );
    }
    return condominiumExpenseIndex;
  }

  updateAllFields(event): void {
    if (this.context.matter.isMatterProvinceAB) {
      this.updateAllFieldsAB(event);
    }
    if (this.context.matter.isMatterProvinceMB) {
      this.updateAllFieldsMB(event);
    } else if (this.context.matter.isMatterProvinceSK) {
      this.updateAllFieldsSK(event);
    } else if (this.context.matter.isMatterProvinceBC) {
      this.updateAllFieldsBC(event);
    } else {
      this.updateAllFieldsON(event);
    }
  }

  updateAllFieldsBC(event): void {
    this.updateJurisdictionPT(event);
    this.updateSubjectProperty(event, 'address');
    if (this.teranetImportDataSP.isCondo) {
      if (this.condominiumPlanTC && this.condominiumPlanTC.condominiumPlanType) {
        this.updateSubjectProperty(event, 'condominiumPlans', 'condominiumPlanType');
      }
      this.updateSubjectProperty(event, 'condominiumPlans', 'condominiumPlanNumber');
      this.updateSubjectProperty(event, 'condominiumExpenses', 'unitNumber');
      this.updateSubjectProperty(event, 'condominiumExpenses', 'noUndividedShare');
    }
    this.updateSubjectProperty(event, 'fullLegalDescription');
    this.updateSubjectProperty(event, 'titleNumber');
    this.updateSubjectProperty(event, 'lincNumber');
    this.updateSubjectProperty(event, 'isCondo');
  }

  updateAllFieldsON(event): void {
    this.updateJurisdictionPT(event);
    this.updateSubjectProperty(event, this.context.matter.isMatterProvinceAB ? 'streetAddress' : 'address');
    this.updateSubjectProperty(event, 'interestEstate');
    this.updateSubjectProperty(event, 'partLot');
    this.updateSubjectProperty(event, 'plan');
    this.updateSubjectProperty(event, 'beingPart');
    this.updateSubjectProperty(event, 'onPlan');
    this.updateSubjectProperty(event, 'parcel');
    this.updateSubjectProperty(event, 'section');
    this.updateSubjectProperty(event, 'registryOffice');
    this.updateSubjectProperty(event, 'lastTransferNumber');
    this.updateSubjectProperty(event, 'lastInstrumentNumber');
    this.updateSubjectProperty(event, 'unitLevelPlan');
    this.updateUnitLevelPlan(event);
    if (this.teranetImportDataSP && !this.teranetImportDataSP.isCondo) {
      this.updateSubjectProperty(event, 'pin');
      this.updatePin(event);
    }
  }

  updateAllFieldsAB(event): void {
    this.updateJurisdictionPT(event);
    this.updateSubjectProperty(event, 'address');
    this.updateSubjectProperty(event, 'interestEstate');
    if (this.teranetImportDataSP.isCondo) {
      if (this.condominiumPlanTC && this.condominiumPlanTC.condominiumPlanType) {
        this.updateSubjectProperty(event, 'condominiumPlans', 'condominiumPlanType');
      }
      this.updateSubjectProperty(event, 'condominiumPlans', 'condominiumPlanNumber');
      this.updateSubjectProperty(event, 'condominiumExpenses', 'unitNumber');
      this.updateSubjectProperty(event, 'condominiumExpenses', 'noUndividedShare');
    }

    if (this.teranetImportDataSP.isCondo || this.teranetImportDataTC.isPlanBlockLot) {
      this.updateSubjectProperty(event, 'exceptionType');
      this.updateSubjectProperty(event, 'exceptionTypeDescription');
    }

    if (!this.teranetImportDataSP.isCondo && this.teranetImportDataTC.isPlanBlockLot) {
      this.updateSubjectProperty(event, 'plan');
      this.updateSubjectProperty(event, 'block');
      this.updateSubjectProperty(event, 'lot');
    }

    if (!this.teranetImportDataSP.isCondo && this.teranetImportDataTC.isMetesAndBounds) {
      this.updateSubjectProperty(event, 'shortLegalDescription');
      this.updateSubjectProperty(event, 'fullLegalDescription');
    }

    this.updateSubjectProperty(event, 'city');
    this.updateSubjectProperty(event, 'municipality');
    this.updateSubjectProperty(event, 'registryOffice');

    this.updateSubjectProperty(event, 'lincNumber');
    this.updateSubjectProperty(event, 'titleNumber');
    this.updateUnitLevelPlan(event);
    if (this.teranetImportDataSP && !this.teranetImportDataSP.isCondo) {
      this.updateSubjectProperty(event, 'pin');
      this.updatePin(event);
    }

    this.updateSubjectProperty(event, 'lastInstrumentNumber');
  }

  updateAllFieldsMB(event): void {
    this.updateJurisdictionPT(event);
    this.updateSubjectProperty(event, 'address');
    this.updateSubjectProperty(event, 'interestEstate');

    this.updateSubjectProperty(event, 'parcelLegalDescription', 'parcelNumber');
    if (this.teranetImportDataSP.isCondo) {
      this.updateSubjectProperty(event, 'condominiumPlanNumber');
      this.updateSubjectProperty(event, 'parcelLegalDescription', 'unitNumber');
      this.updateSubjectProperty(event, 'parcelLegalDescription', 'percentageInterest');
    }

    if (!this.teranetImportDataSP.isCondo) {
      this.updateSubjectProperty(event, 'parcelLegalDescription', 'memo');
    }

    this.updateSubjectProperty(event, 'city');
    this.updateSubjectProperty(event, 'registryOffice');
  }

  updateAllFieldsSK(event): void {
    this.updateJurisdictionPT(event);
    this.updateSubjectProperty(event, 'interestEstate');
    this.updateSubjectProperty(event, 'address');
    this.updateSubjectProperty(event, 'parcelLegalDescription', 'parcelNumber');
    this.updateSubjectProperty(event, 'parcelLegalDescription', 'extensionNumber');
    this.updateSubjectProperty(event, 'parcelLegalDescription', 'parcelTitles.titleNumber');
    this.updateSubjectProperty(event, 'parcelLegalDescription', 'parcelTitles.numberOfIndividualShares');
    this.updateSubjectProperty(event, 'city');
    this.updateSubjectProperty(event, 'registryOffice');
    if (this.teranetImportDataSP.isCondo) {
      this.updateSubjectProperty(event, 'condominiumPlans', 'condominiumPlanType');
      this.updateSubjectProperty(event, 'condominiumPlans', 'condominiumPlanNumber');
      this.updateSubjectProperty(event, 'parcelLegalDescription', 'unitNumber');
    }
    if (!this.teranetImportDataSP.isCondo) {
      if (this.parcelRegister.iscImportData.farmLand) {
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'qtrsec');
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'sec');
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'twp');
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'rng');
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'mdn');
      } else {
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'lotNumber');
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'blockNumber');
        this.updateSubjectProperty(event, 'parcelLegalDescription', 'planNumber');
      }
      this.updateSubjectProperty(event, 'parcelLegalDescription', 'mineral');
    }
  }

  updateUnitLevelPlan(event): void {
    if (
      this.context.matter &&
      this.context.matter.matterPropertyWithCondo &&
      this.context.matter.matterPropertyWithCondo.condominiumExpenses
    ) {
      this.matterProperty.condominiumExpenses = _.cloneDeep(
        this.context.matter.matterPropertyWithCondo.condominiumExpenses
      );
      this.matterProperty.condominiumPlans = _.cloneDeep(this.context.matter.matterPropertyWithCondo.condominiumPlans);
      this.matterProperty.condominiumJurisdiction = this.context.matter.matterPropertyWithCondo.condominiumJurisdiction;
      if (
        this.matterProperty.condominiumPlans.length == 1 &&
        this.context.matter.matterPropertyWithCondo.condominiumPlans.length == 1
      ) {
        this.matterProperty.condominiumPlans[0].condominiumPlanNumber =
          this.context.matter.matterPropertyWithCondo.condominiumPlans[0].condominiumPlanNumber;
      }
    } else {
      this.matterProperty.condominiumExpenses = [];
    }
    if (this.teranetImportDataTC.unitLevelPlan && event) {
      if (this.teranetImportDataTC.jurisdiction && this.teranetImportDataTC.jurisdiction.registryOffice) {
        this.matterProperty.condominiumJurisdiction =
          this.teranetImportDataTC.jurisdiction.registryOffice.shortOfficeName;
      }
      if (this.matterProperty.condominiumPlans.length <= 1 && this.teranetImportDataTC.plan) {
        if (this.matterProperty.condominiumPlans.length < 1) {
          let condominiumPlan = new CondominiumPlan();
          condominiumPlan.condominiumPlanType = 'STANDARD_CONDOMINIUM_PLAN';
          condominiumPlan.condominiumPlanNumber = this.teranetImportDataTC.planNumber;
          this.matterProperty.condominiumPlans.push(condominiumPlan);
        } else {
          this.matterProperty.condominiumPlans[0].condominiumPlanType =
            this.teranetImportDataTC &&
            this.teranetImportDataTC.propertyDescription &&
            this.teranetImportDataTC.propertyDescription.indexOf('CONDOMINIUM PLAN') > -1
              ? 'CONDOMINIUM_PLAN'
              : this.matterProperty.condominiumPlans[0].condominiumPlanType;
          this.matterProperty.condominiumPlans[0].condominiumPlanNumber = this.teranetImportDataTC.planNumber;
        }
      }

      let condominiumExpenseIndex = -1;
      let unitNo = this.unitNumber;
      let levelNo = this.levelNumber;
      if (
        unitNo &&
        levelNo &&
        this.teranetImportDataTC.pin &&
        this.context.matter &&
        this.context.matter.matterPropertyWithCondo
      ) {
        condominiumExpenseIndex = this.matterProperty.condominiumExpenses.findIndex(
          (item) =>
            item.pinNumber &&
            item.levelNumber == levelNo &&
            item.unitNumber == unitNo &&
            item.pinNumber.replace('-', '') == this.teranetImportDataTC.pin.replace('-', '')
        );
      }

      if (condominiumExpenseIndex < 0) {
        condominiumExpenseIndex = this.matterProperty.condominiumExpenses.findIndex(
          (item) => !item.levelNumber && !item.unitNumber && !item.pinNumber
        );
        let condominiumExpense =
          condominiumExpenseIndex > -1
            ? this.matterProperty.condominiumExpenses[condominiumExpenseIndex]
            : new CondominiumExpense();

        condominiumExpense.levelNumber = levelNo;
        condominiumExpense.unitNumber = unitNo;
        condominiumExpense.pinNumber = this.teranetImportDataTC.pin
          ? this.teranetImportDataTC.pin.replace('-', '')
          : undefined;
        if (condominiumExpenseIndex < 0) {
          condominiumExpense.condominiumUnitType = '';
          condominiumExpense.condominiumExpense = 0;
          this.matterProperty.condominiumExpenses.push(condominiumExpense);
        }
      }
    } else {
      this.teranetImportDataSP.pin = '';
    }
  }

  selectUnitLevelPlan(): void {
    let cea: StatementAdjustment = this.context.matter.statementOfAdjustments.find((item) =>
      item.isCommonExpenseAdjustmentFromProperty()
    );
    this.dialogService.matDialogContent({
      content: UnitLevelPlanModalComponent,
      context: {
        propertyJurisdiction: this.selectedJurisdiction,
        unitLevelPlanOrg: this.matterProperty,
        commonExpenseAdj: cea,
        currentMatter: this.context.matter,
        isReadOnly: true
      },
      onFulfillment: (unitLevelPlan) => {
        if (unitLevelPlan && unitLevelPlan.plan) {
          this.matterProperty.condominiumExpenses = unitLevelPlan.plan.condominiumExpenses;
        }
      }
    });
  }

  extractPinList(): string[] {
    let pinList: string[] = [];
    if (this.context.matter.matterProperties && this.context.matter.matterProperties.length > 0) {
      this.context.matter.matterProperties.forEach((item) => {
        if (item.pin && item.pin.indexOf(',') > -1) {
          pinList.push(...item.pin.split(','));
        } else if (item.pin) {
          pinList.push(item.pin);
        }
      });
    }
    return pinList;
  }

  updatePin(event): void {
    if (this.teranetImportDataSP && !this.teranetImportDataSP.isCondo) {
      let pinList: string[] = this.extractPinList();
      if (event && this.teranetImportDataTC.pin && pinList.indexOf(this.teranetImportDataTC.pin.replace('-', '')) < 0) {
        pinList.push(this.teranetImportDataTC.pin.replace('-', ''));
      }
      this.teranetImportDataSP.pin = pinList.filter((item) => item).join(', ');
    }
  }

  isLincNumberValidForImport() {
    if (this.teranetImportDataTC.lincNumber) {
      return this.teranetImportDataTC.lincNumber != this.matterProperty.lincNumber;
    }
    //nothing to import
    return false;
  }

  get teranetImportDataModallabel(): string {
    return provinceBasedFieldLabels.get('teranet.import.data.modal.head.label', this.context.matter.provinceCode);
  }

  onSelectedStreetAddress() {
    if (this.teranetImportDataTC.fieldStreetAddress) {
      this.teranetImportDataSP.address = this.teranetImportDataTC.address;
      if (this.context.matter.isMatterProvinceMB) {
        this.initializeImportManitobaJurisdiction();
      }
    }
  }

  containCondoExpensesTC(): boolean {
    return (
      Array.isArray(this.teranetImportDataTC.condominiumExpenses) &&
      this.teranetImportDataTC.condominiumExpenses.length > 0
    );
  }

  get condominiumExpenseTC(): CondominiumExpense {
    return this.containCondoExpensesTC() ? this.teranetImportDataTC.condominiumExpenses[0] : null;
  }

  containCondoExpensesSP(): boolean {
    return (
      Array.isArray(this.teranetImportDataSP.condominiumExpenses) &&
      this.teranetImportDataSP.condominiumExpenses.length > 0
    );
  }

  get condominiumExpenseSP(): CondominiumExpense {
    return this.containCondoExpensesSP() ? this.teranetImportDataSP.condominiumExpenses[0] : null;
  }

  containCondominiumPlansTC(): boolean {
    return (
      Array.isArray(this.teranetImportDataTC.condominiumPlans) && this.teranetImportDataTC.condominiumPlans.length > 0
    );
  }

  get condominiumPlanTC(): CondominiumPlan {
    return this.containCondominiumPlansTC() ? this.teranetImportDataTC.condominiumPlans[0] : null;
  }

  containCondominiumPlansSP(): boolean {
    return (
      Array.isArray(this.teranetImportDataSP.condominiumPlans) && this.teranetImportDataSP.condominiumPlans.length > 0
    );
  }

  get condominiumPlanSP(): CondominiumPlan {
    return this.containCondominiumPlansSP() ? this.teranetImportDataSP.condominiumPlans[0] : null;
  }

  get isPlanVisible(): boolean {
    return (
      (this.context.matter.isMatterProvinceON && !this.teranetImportDataSP.isCondo) ||
      (this.context.matter.isMatterProvinceAB && this.teranetImportDataTC.isPlanBlockLot)
    );
  }

  get isLegalDescriptionVisible() {
    return (
      (this.context.matter.isMatterProvinceAB &&
        !this.teranetImportDataSP.isCondo &&
        this.teranetImportDataTC.isMetesAndBounds) ||
      this.context.matter.isMatterProvinceBC
    );
  }

  get isMemoVisible() {
    return this.context.matter.isMatterProvinceMB && !this.teranetImportDataSP.isCondo;
  }

  get parcelLegalDescriptionSP(): ParcelLegalDescription {
    return this.teranetImportDataSP.parcelLegalDescription ? this.teranetImportDataSP.parcelLegalDescription : null;
  }

  get parcelTitleSP(): ParcelTitle {
    return this.teranetImportDataSP.parcelLegalDescription &&
      this.teranetImportDataSP.parcelLegalDescription.parcelTitles[0]
      ? this.teranetImportDataSP.parcelLegalDescription.parcelTitles[0]
      : null;
  }

  get isParcelRegisterLegalDescriptionVisible(): boolean {
    return (
      this.context.matter.isMatterProvinceON ||
      this.context.matter.isMatterProvinceSK ||
      (this.context.matter.isMatterProvinceAB &&
        (this.teranetImportDataSP.isCondo || !this.teranetImportDataTC.isMetesAndBounds)) ||
      (this.context.matter.isMatterProvinceMB && this.teranetImportDataSP.isCondo)
    );
  }
}
