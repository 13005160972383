import {Jurisdiction} from '../shared/jurisdiction';

export class VendorsSolicitorState {

  selectedJurisdiction: Jurisdiction;
  // selectedSolicitor : MatterParticipantWrapper;
  // showSolicitorDropDownArrow : boolean = false;
  // selectedLawFirm : MatterParticipantWrapper;
  // disableBarristerSolicitor : boolean = false;
  // disableAddress : boolean = false;
  selectedLawClerk: any;
}
