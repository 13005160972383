<div class="container-fluid custom-modal-container" >
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1 tabIndex="-1">Teranet Connect&trade;: Adjacent Lands ({{parcels ? parcels.length : 0}}) </h1>
        </div>
        <div class="col-lg-2">
            <button type="button" class="btn-close pull-right padding-top-5" (click)="close()" tabIndex="-1">x</button>
        </div>
    </div>
    <div class="row modal-body">
        <div class="row">
            <div class="col-lg-12 padding-top-10 form-group">
                <span *ngFor="let rBtn of radioButtons; let i=index" class="radio  padding-left-10 display-inline"  (click)="onSelectionRadioChange(rBtn.action)">
                    <input type="radio" [(checked)]="rBtn.active" >
                    <label>{{rBtn.label}}</label>
                </span>
            </div>
        </div>

        <div class="row">
            <table class="standard-table search-adjacent-parcel-table" (keydown)="onKeyPress($event)" [mfData]="parcels"  #mf="mfDataTable">
                <thead>
                    <tr>
                        <th class="table-cell-1"></th>
                        <th class="table-cell-2" (click)="sortPin.sort();">
                            <div class="wrap-header">
                                PIN
                                <dp-default-sorter #sortPin  by="pin" [singleState]="true"></dp-default-sorter>
                            </div>
                        </th>
                        <th class="table-cell-3" (click)="sortParcelType.sort();">
                            <div class="wrap-header">
                                Parcel Type
                                <dp-default-sorter #sortParcelType by="parcelType" [singleState]="true"></dp-default-sorter>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of mf.data;let i=index;" (click)="updateSelectedItem(i)" [ngClass]="{'dp-teranet-row-active': isRowSelected(row.i)}" title="{{row.hint}}">
                    <td>
                        <dp-checkbox fieldId="parcelSelected-{{i}}">
                            <input type="checkbox"
                                   id="parcelSelected-{{i}}"
                                   [(ngModel)]="row.selected"
                                   [checked]="row.selected"
                                   [disabled]="row.cannotBeOrdered"
                                   />
                        </dp-checkbox>
                    </td>

                    <td >
                        <span > {{row.pin}} </span>
                    </td>
                    <td >
                        <span > {{row.adjacentParcelTypeDescription}} </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row" style="padding: 10px;">



    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <div class="matter-component action-buttons" >
                <ul>
                    <li class="dropdown margin-right-0">
                        <button class="dp-btn" type="button" (click)="purchaseParcelRegisters()" [disabled]="!anyItemSelected">
                            <span>Order</span>
                        </button>
                    </li>

                    <li class="dropdown margin-left-10">
                        <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                            <span>Cancel</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
