import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {TeranetPropertyOwner} from '../../../../shared-main/teranet/teranet-property-owner';
import * as _ from 'lodash';
import {TeranetParcelRegisterSearchResponse} from '../../../../shared-main/teranet/teranet-parcel-register-search-response';
import {TeranetParcelRegisterSearchRequest} from '../../../../shared-main/teranet/teranet-parcel-register-search-request';
import {PurchaseParcelRegistersModal} from '../purchase-parcel-register/purchase-parcel-registers.modal.component';
import {Matter} from '../../../shared';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {ApplicationError} from '../../../../core/application-error';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {FieldError} from '../../../../core';
import {KeyCode, KeyCodesActiveForTable} from '../../../../shared-main/constants';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export class TeranetPropertyOwnersContext {
  teranetSearchRequest: TeranetParcelRegisterSearchRequest;
  teranetSearchResponse: TeranetParcelRegisterSearchResponse;
  matter: Matter;
  teranetUser: TeranetUser;
}

@Component({
  selector: 'dp-teranet-property-owners-modal',
  templateUrl: 'teranet-property-owners-modal.component.html',
  providers: [DialogService]
})
export class TeranetPropertyOwnersModal extends ModalComponent<TeranetPropertyOwnersContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  propertyOwners: TeranetPropertyOwner[] = [];
  selectedOwnerIndex: number = -1;

  constructor(
    public dialog: MatDialogRef<TeranetPropertyOwnersModal>,
    public dialogService: DialogService,
    public teranetService: TeranetService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetPropertyOwnersContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.propertyOwners = this.context.teranetSearchResponse.propertyOwners;
    if (this.propertyOwners.length > 0) {
      this.propertyOwners = _.sortBy(this.propertyOwners, (item) => item.lastName.toLowerCase());
      this.updateSelectedItem(0);
    }
  }

  //This is the previous search request that returned multiple names
  get teranetSearchRequest(): TeranetParcelRegisterSearchRequest {
    return this.context.teranetSearchRequest;
  }

  updateSelectedItem(index: number): void {
    this.selectedOwnerIndex = index;
  }

  isRowSelected(index: number): boolean {
    return this.selectedOwnerIndex === index;
  }

  searchParcelRegisterByOwnerName(selectedOwner: TeranetPropertyOwner): void {
    let searchRequestByOwner: TeranetParcelRegisterSearchRequest = new TeranetParcelRegisterSearchRequest();
    searchRequestByOwner.matterId = this.context.matter.id;
    searchRequestByOwner.lroNumber = this.teranetSearchRequest.lroNumber;
    searchRequestByOwner.selectedLro = this.teranetSearchRequest.selectedLro;
    searchRequestByOwner.teranetUser = this.teranetSearchRequest.teranetUser;
    searchRequestByOwner.searchType = 'SEARCH_BY_NAME'; //Search by name & owner are treated same way
    searchRequestByOwner.lastName = selectedOwner.lastName;
    searchRequestByOwner.firstName = selectedOwner.firstName;

    this.teranetService.searchParcelRegisterByOwnerName(searchRequestByOwner).subscribe(
      (teranetParcelRegisterSearchResponse: TeranetParcelRegisterSearchResponse) => {
        this.openParcelSearchResponseModel(searchRequestByOwner, teranetParcelRegisterSearchResponse);
      },
      (error: ApplicationError) => {
        this.handleTeranetSearchApiError(error);
      }
    );
  }

  onSelectPropertyOwner(): void {
    if (this.selectedOwnerIndex > -1 && this.selectedOwnerIndex < this.propertyOwners.length) {
      this.searchParcelRegisterByOwnerName(this.propertyOwners[this.selectedOwnerIndex]);
    }
  }

  openParcelSearchResponseModel(
    searchRequestByOwner: TeranetParcelRegisterSearchRequest,
    teranetParcelRegisterSearchResponse: TeranetParcelRegisterSearchResponse
  ): void {
    this.dialogService.matDialogContent({
      content: PurchaseParcelRegistersModal,
      context: {
        matter: this.context.matter,
        teranetUser: this.context.teranetUser,
        teranetSearchRequest: searchRequestByOwner,
        teranetSearchResponse: teranetParcelRegisterSearchResponse
      },
      onFulfillment: (result: any) => {
        if (result && result.action !== 'backToSearch') {
          this.dialog.close({action: result.action});
        }
      }
    });
  }

  onCancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  backToSearch(): void {
    this.dialog.close({action: 'backToSearch'});
  }

  onKeyPress(event: KeyboardEvent): void {
    if (KeyCodesActiveForTable.indexOf(event.keyCode) > -1) {
      this.handleArrowKeys(event);
    }
  }

  handleArrowKeys(event): void {
    event.preventDefault();
    if (event.keyCode === KeyCode.DownArrow) {
      if (this.selectedOwnerIndex < this.propertyOwners.length) {
        this.selectedOwnerIndex++;
      }
    } else if (event.keyCode === KeyCode.UpArrow) {
      if (this.selectedOwnerIndex > 0) {
        this.selectedOwnerIndex--;
      }
    } else if (event.keyCode === KeyCode.Enter) {
      this.onSelectPropertyOwner();
    }
    jQuery('#propertyOwner-' + this.selectedOwnerIndex).focus();
  }

  handleTeranetSearchApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }
}
