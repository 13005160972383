import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import Utils from '../../shared-main/utils';
import moment from 'moment';

export type MoreThanOneDebtorType = 'YES' | 'NO';

export class Paragraph extends BaseEntity {
  id: number;
  writExecutionId: number; //present if created from writ execution
  paragraphText: string;
  declarationId: number;
  debtorName: string;
  moreThanOneDebtor: MoreThanOneDebtorType;
  executionNumber: string;
  plaintiffName: string;
  judgmentAmount: string;
  costAmount: string;
  automaticallyCreatedDeclaration: boolean;
  solicitorName: string;

  //NS Fields
  judgementDate: string; //Accepts Partial Date
  judgementFillingDate: string; //Accepts Partial Date
  book: string;
  page: string;
  docNo: string;

  copyValues(sourceParagraph: Paragraph): void {
    if (sourceParagraph) {
      for (let prop in sourceParagraph) {
        if (sourceParagraph.hasOwnProperty(prop)) {
          this[ prop ] = sourceParagraph[ prop ];
        }
      }
    }
  }

  setParagraphTextFromTemplate(clientName: string, paragraphTemplateText: string, isSolicitorDeclaration: boolean) {
    if (paragraphTemplateText) {
      this.paragraphText = paragraphTemplateText.replace('$CLIENT_NAME$', clientName ? clientName : '')
      .replace('$DEBTOR_NAME$', this.debtorName ? this.debtorName : this.blankString(40))
      .replace('$EXECUTION_NUMBER$', this.executionNumber ? this.executionNumber : this.blankString(19))
      .replace('$PLAINTIFF$', this.plaintiffName ? this.plaintiffName : this.blankString(44))
      .replace('$JUDGEMENT_AMOUNT$', this.judgmentAmount ? this.judgmentAmount : this.blankString(14))
      .replace('$COSTS_AMOUNT$', this.costAmount ? this.costAmount : this.blankString(14));
    }

    if (this.moreThanOneDebtor === 'YES' && this.paragraphText) {
      this.paragraphText = this.paragraphText.replace('the judgment debtor', ' one of the judgment debtors');
    }

    if (isSolicitorDeclaration) {
      this.adjustParagraphTextForNonIndividualEntity(clientName);
    }
  }

  adjustParagraphTextForNonIndividualEntity(clientName: string) {
    if (this.paragraphText) {
      this.paragraphText = this.paragraphText.replace('I am', 'The said ' + clientName + ', is');
    }
  }

  adjustParagraphTextForIndividualEntity() {
    if (this.paragraphText) {
      this.paragraphText = this.paragraphText.replace(new RegExp(/The said (\w\s*)+, is/), 'I am');
    }
  }

  blankString(numOfUnderscores: number): string {
    let str: string = '';
    for (let i = 0; i < numOfUnderscores; i++) {
      str += '_';
    }
    return str;
  }

  get shortParagraphText(): string {
    if (this.executionNumber) {
      return `${ this.executionNumber } - ${ this.plaintiffName } - ${ this.judgmentAmount }`;
    } else {
      return this.paragraphText;
    }
  }

  getParagraphTextForNS(): string {
    let text = '';
    if (Utils.isValidDate(this.judgementDate) || this.plaintiffName || this.judgmentAmount) {
      if (Utils.isValidDate(this.judgementDate)) {
        text += moment(this.judgementDate, 'YYYY/MM/DD').format('MMMM DD, YYYY') + ' - ';
      }
      if (this.plaintiffName) {
        text += this.plaintiffName + ' - ';
      }
      if (this.judgmentAmount) {
        text += this.judgmentAmount;
      }
    } else {
      text = '___________________ || Book | - against - Page | Doc No. || ___________________ ';
    }

    return text;
  }
}
