
<div class="form-group">
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="responsibleSolicitor"
               [label-province-code]="user.defaultProvinceCode"
               dp-province-field-label="responsible.staff.solicitor">Responsible Solicitor
        </label>
    </div>
    <div class="col-lg-4 lawClerk-solicitor-class">
        <div [dp-read-only-block]="readOnly" >
            <!--Put dp-error-validator in outside div to avoid the warning icon is blocked-->
            <div class="col-lg-4 z-index-6">
                <div [fieldKey]="solicitorFieldKey" dp-error-validator ></div>
            </div>

            <p-autoComplete id="responsibleSolicitor"
                            name="responsibleSolicitor"
                            #solicitorAutoComplete
                            [dropdown]="true"
                            [minLength]="0"
                            maxlength="0"
                            scrollHeight="550px"
                            autocomplete="off"
                            delay="20"
                            [(ngModel)]="selectedSolicitor"
                            [suggestions]="solicitorsDropDownList"
                            (onSelect)="onSelectSolicitor($event)"
                            (onDropdownClick)="refreshSolicitorList()"
                            (keydown.space)="displaySolicitorList($event)">
                <ng-template let-solicitor pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [disableItem]="solicitor">
                        <span class="col-lg-8 text-overflow">{{ solicitor.label }}</span>
                    </li>
                </ng-template>

            </p-autoComplete>
        </div>
    </div>
</div>

<div class="form-group">
    <div class="col-lg-2 vertical-align-label">
        <label class="control-label"
               for="responsibleLawClerk"
               [label-province-code]="user.defaultProvinceCode"
               dp-province-field-label="responsible.staff.lawClerk">Responsible Law Clerk
        </label>
    </div>
    <div class="col-lg-4">
        <div [dp-read-only-block]="readOnly" >
            <div class="col-lg-4 z-index-6">
                <div [fieldKey]="lawClerkFieldKey" dp-error-validator ></div>
            </div>
            <p-autoComplete id="responsibleLawClerk"
                            name="responsibleLawClerk"
                            #lawClerkAutoComplete
                            [minLength]="0"
                            [dropdown]="true"
                            maxlength="0"
                            scrollHeight="550px"
                            delay="20"
                            autocomplete="off"
                            [(ngModel)]="selectedLawClerk"
                            [suggestions]="lawClerksDropDownList"
                            (onSelect)="onSelectLawClerk($event)"
                            (onDropdownClick)="refreshLawClerkList()"
                            (keydown.space)="displayLawClerkList($event)"
            >
                <ng-template let-lawClerk pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [disableItem]="lawClerk">
                        <span class="col-lg-8 text-overflow">{{ lawClerk.label }}</span>
                    </li>
                </ng-template>

            </p-autoComplete>
        </div>
    </div>
</div>
