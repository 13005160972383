import {Component, HostListener, Inject, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {Deposit, DepositPrintFormatType, DepositStatus} from '../../shared/deposit';
import {dropDowns} from '../../shared/matter-drop-downs';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../shared/dialog/dialog.service';
import * as _ from 'lodash';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {AppConfig} from '../../../shared-main/app-configuration';
import {MultiDepositRowBurgerMenu, SalePriceOptionValue} from './deposit-modal-constants';
import {
  DepositOnOccupancyCalculatedBasedOn,
  StatementAdjustmentKey
} from '../../statement-adjustment/statement-adjustment-constants';
import {Matter} from '../../shared/matter';
import {OCCUPANCY_FEES_CALCULATED_BASED_ON, OpenDepositAction} from '../../../shared-main/constants';
import {StatementAdjustment} from '../../statement-adjustment/statement-adjustment';
import {MatterService} from '../../matter.service';
import {TrustAccountsService} from '../../../admin/trust-account/trust-accounts.service';
import {TrustAccount} from '../../../admin/trust-account/trust-account';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import Utils from '../../../shared-main/utils';
import {Utils as Utils2} from '../../shared/utils';
import {MatterExtraDepositConfig} from '../../shared/matter-extra-deposit-config';
import {Form4SpecificationModalComponent} from '../../deposit-forms/form-4-specification.modal.component';
import {DepositAdjustmentCalculationUtil} from '../../../matters/statement-adjustment/deposit-adjustment-calculation-util';
import {TabsService} from '../../../core/tabs.service';
import {MatterTab} from '../../matter-tab';
import {AdjustmentPropagationService} from '../../adjustment-propagation.service';
import {Subscription} from 'rxjs';
import {DocumentProductionService} from '../../document-production/document-production.service';
import {ERegistrationService} from '../../forms/eregistration/eregistration.service';
import {DepositManagerService} from '../../../deposit-manager/deposit-manager-service';
import {DpBooleanValueTypes} from '../../shared/dp-boolean';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export interface DepositModalContextValue {
  multipleDeposit: string;
  payDepositOutOfTrust: string;
  depositAmount: number;
  adjustmentFormat: string;
  deposits: Deposit[];
  closingDate: string;
  infoOnly?: boolean;
  extraDepositConfig?: MatterExtraDepositConfig;
  depositStatementAdj?: StatementAdjustment;
}

class TrustAccountWrapper {
  userEnteredAcctNumber: string;

  constructor(public account: TrustAccount) {
    this.userEnteredAcctNumber = null;
  }

  get isAccountNumberEditable(): boolean {
    return this.account && _.isEmpty(this.account.trustAccountNumber);
  }
}

export class DepositModalContext implements DepositModalContextValue {
  multipleDeposit: string;
  payDepositOutOfTrust: string;
  depositAmount: number;
  adjustmentFormat: string;
  deposits: Deposit[];
  closingDate: string;
  infoOnly?: boolean = false;
  matter: Matter;
  isCondo?: boolean = false;
  isProjectRelated?: boolean = false; // project or project matter
  projectOccupancyFeesCalBasedOn?: OCCUPANCY_FEES_CALCULATED_BASED_ON; // from the project record
  interimBDOCAmount?: number;
  action?: OpenDepositAction;
  depositToBeFocused?: number; // depositId
  modalSubTitle?: string;
  hidePrintF4Menu?: boolean = false;
}

@Component({
  selector: 'dp-deposit-modal-content',
  templateUrl: 'deposit.modal.component.html',
  providers: [ErrorService],
  styleUrls: ['./deposit.modal.component.scss']
})
export class DepositModal extends ModalComponent<DepositModalContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = 'Closing Date';

  multipleDeposit: string;
  payDepositOutOfTrust: string;
  propDepositAmount: number = 0;

  /** DPPMP-30930: the total in the modal's bottom section "Total Deposits" */
  propDepositTotalAmount: number = 0;

  /** DPPMP-30930: the total in the modal's work sheet form, "Sub of deposits below", or "Total Deposits excluding extras" */
  propSumOfDepositsAmount: number = 0;

  adjustmentFormat: string;
  deposits: Deposit[] = [];
  occupancyDeposit: Deposit;
  infoOnly: boolean;

  extraDepositConfig: MatterExtraDepositConfig;

  // use this copy of the underlying matter to calculate SOA values etc.
  // Nov 06: Per clarification by BA, Changes under a 'statementStatusMode' (Final/Interim) do not trigger recalculation
  //         for the other mode until user clicks OK button to close the modal.
  matter: Matter;

  isForcedMultiDeposit: boolean;
  screenHeight: number;

  multipleDepositOptions: SelectItem[];
  adjustmentFormatOptions: SelectItem[];
  yesNoNyOptions: SelectItem[] = dropDowns.YesNoNyOptions;
  readonly salePriceOptions = [
    {value: SalePriceOptionValue.AS_PER_AGREEMENT, label: 'Sale price as per agreement'},
    {value: SalePriceOptionValue.INCLUDING_CREDITS_TO_PV, label: 'Sale price including credits to P & V'}
  ];
  readonly depositOnOccupancyCalculatedBasedOnOptions = [
    {value: DepositOnOccupancyCalculatedBasedOn.UNADJUSTED_BALANCE, label: 'Unadjusted balance'},
    {value: DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA, label: 'BDOC in Interim SOA'}
  ];

  trustAccounts: TrustAccountWrapper[] = [];
  selectedTrustAccount: TrustAccountWrapper;

  currentTimeStamp = Date.now();
  maxDeposits: number = 99;
  isDeleteWindowOpen = false;

  adjustmentPropagationService: AdjustmentPropagationService;

  documentProductionSubscription: Subscription;

  @ViewChildren('depositName') depositNameFields;
  @ViewChildren('depositDate') depositDateFields;

  constructor(
    public dialog: MatDialogRef<DepositModal>,
    public tabsService: TabsService,
    public dialogService: DialogService,
    public errorService: ErrorService,
    public documentProductionService: DocumentProductionService,
    public eRegistrationService: ERegistrationService,
    private matterService: MatterService,
    private trustAccountsService: TrustAccountsService,
    public depositManagerService: DepositManagerService,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: DepositModalContext
  ) {
    super(dialog, context);
    this.getScreenSize(undefined);
  }

  ngOnInit() {
    //debugger;
    this.initLocalMatter();
    if (this.tabsService.activeTab && this.tabsService.activeTab.isMatter()) {
      let component = (this.tabsService.activeTab as MatterTab).matterComponent;
      this.adjustmentPropagationService = component?.adjustmentPropagationService;
    }
    // isForcedMultiDeposit
    this.isForcedMultiDeposit = this.isCondoProjectInON_AB();

    this.multipleDepositOptions = dropDowns.multipleDepositOptions;
    this.adjustmentFormatOptions = dropDowns.adjustmentFormatOptions;
    this.maxDeposits = this.appConfig.maxNumberOfDeposits;

    this.multipleDeposit = !this.context.multipleDeposit ? 'NO' : this.context.multipleDeposit;
    if (this.isForcedMultiDeposit) {
      this.multipleDeposit = 'YES';
    }
    this.payDepositOutOfTrust = this.context.payDepositOutOfTrust
      ? this.context.payDepositOutOfTrust
      : DpBooleanValueTypes.N_y;

    this.extraDepositConfig = new MatterExtraDepositConfig(this.matter.extraDepositConfig);

    if (!this.extraDepositConfig.salePriceTotalOption) {
      this.extraDepositConfig.salePriceTotalOption = SalePriceOptionValue.AS_PER_AGREEMENT;
    }

    this.propDepositAmount =
      this.multipleDeposit == 'NO' && this.context.depositAmount ? this.context.depositAmount : 0;
    this.propDepositTotalAmount =
      this.multipleDeposit == 'YES' && this.context.depositAmount ? this.context.depositAmount : 0;
    this.adjustmentFormat = !this.context.adjustmentFormat ? 'ONE_ITEM_ADJ' : this.context.adjustmentFormat;

    this.infoOnly = !!this.context.infoOnly;

    // vtb mortgage principal, this should not be changed outside ngOnInit()
    const util = new DepositAdjustmentCalculationUtil(this.matter);
    this.extraDepositConfig.vtbMortgageMonies = util.initVTBMortgageMonies(
      this.matter,
      this.context.projectOccupancyFeesCalBasedOn
    );

    if (this.context.closingDate) {
      this.closingDateLabel = Utils2.formatDate(this.context.closingDate);
    }

    if (Array.isArray(this.context.deposits) && this.context.deposits.length > 0) {
      //Occupancy & non-occupancy separate out for this modal and re-combine on OK
      this.deposits = this.context.deposits.filter((d) => !d.paidOnOccupancy).map((d) => new Deposit(d));
      if (this.deposits.length > 1) {
        this.deposits.forEach((deposit, index) => {
          let depositName = deposit.depositName;
          if (depositName == undefined || depositName == null || depositName.length == 0) {
            this.deposits.splice(index, 1);
          }
        });
      }

      this.occupancyDeposit = new Deposit(this.context.deposits.find((d) => d.paidOnOccupancy));
    }

    if (this.deposits.length == 0) {
      this.currentTimeStamp = Date.now();
      let deposit = new Deposit();
      this.deposits.push(deposit);
    }

    this.recalculateDepositSheet();

    if (this.isCondoProjectInON()) {
      this.loadTrustAccounts();
    }
    if (this.matter.isMatterProvinceBC) {
      this.closingDateLabel = 'Adjustment Date';
    }
    if (this.matter.isSaleBC && this.context.closingDate) {
      this.closingDateLabel = Utils2.formatDate(this.context.closingDate);
    }
  }

  ngAfterViewInit() {
    if (this.context.action && this.context.action == 'ADD_NEW') {
      setTimeout(() => {
        jQuery('#addAnotherDeposit').focus();
      }, 1000);
    } else if (this.context.action && this.context.action == 'EDIT') {
      setTimeout(() => {
        jQuery('.deposit-amount-' + this.context.depositToBeFocused).focus();
      }, 1000);
    }
  }

  private loadTrustAccounts(): void {
    this.trustAccounts = [];

    // ensure we have tried all possible places to get project Id. may not be needed in future
    const projId = this.matter.project ? this.matter.project.id : this.matter.unityProjectId;

    this.trustAccountsService
      .getTrustAccounts(
        Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId)),
        this.matter.provinceCode,
        projId
      )
      .subscribe((res: TrustAccount[]) => {
        if (res) {
          res.forEach((acct) => {
            let trustAccountWrapper = new TrustAccountWrapper(acct);
            this.trustAccounts.push(trustAccountWrapper);
          });
        }
        if (this.isProjectConfigTab) {
          let defaultExists = this.trustAccounts.find((acct) => acct.account.defaultForProject);
          if (defaultExists) {
            this.selectedTrustAccount = defaultExists;
          }
        } else {
          if (this.extraDepositConfig.matterFirmTrustAccount) {
            let matterFirmAccountExists = this.trustAccounts.find(
              (acct) =>
                this.extraDepositConfig.matterFirmTrustAccount.id === acct.account.id &&
                this.extraDepositConfig.matterFirmTrustAccount.equals(acct.account)
            );

            if (matterFirmAccountExists) {
              this.selectedTrustAccount = matterFirmAccountExists;
            } else {
              let trustAccountWrapper = new TrustAccountWrapper(this.extraDepositConfig.matterFirmTrustAccount);
              this.trustAccounts.push(trustAccountWrapper);
              this.selectedTrustAccount = trustAccountWrapper;
            }

            // saved trust account entry is allowed to save without a valid account number
            // - weird but we need to handle here
            if (!this.selectedTrustAccount.account.trustAccountNumber) {
              this.selectedTrustAccount.userEnteredAcctNumber = this.extraDepositConfig.bankAccountNumber;
            }
          }
        }
        this.updateTrustAccountPropertiesOnMatter();
      });
  }

  updateTrustAccountPropertiesOnMatter() {
    if (this.selectedTrustAccount && this.selectedTrustAccount.account) {
      if (
        !this.isProjectConfigTab &&
        this.selectedTrustAccount.account.trustAccountNumber &&
        !this.extraDepositConfig.bankAccountNumber
      ) {
        this.extraDepositConfig.bankAccountNumber = this.selectedTrustAccount.account.trustAccountNumber;
      }
      this.extraDepositConfig.matterFirmTrustAccount = new TrustAccount(this.selectedTrustAccount.account);
    }
  }

  private initVTBMortgageMonies(): number {
    // project only
    let money = 0.0;
    if (this.context.projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.phantomMortgage) {
      const m1 = this.matter.getFirstPurchaserMortgage();
      money = m1 ? m1.mortgageTerm.principal : 0.0;
    } else if (
      this.context.projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.deferredPurchaseMonies
    ) {
      const m2 = this.matter.getFirstVTBMortgage();
      money = m2 ? m2.mortgageTerm.principal : 0.0;
    } // else not specified in story, default to zero
    return Utils.toNumber(money);
  }

  isCondoProjectInON_AB(): boolean {
    return (
      this.context.isProjectRelated &&
      (this.matter.isMatterProvinceON || this.matter.isMatterProvinceAB) &&
      this.context.isCondo
    );
  }

  isDepositInfoOnlyVisible(): boolean {
    return this.context.isProjectRelated && (this.matter.isMatterProvinceON || this.matter.isMatterProvinceAB);
  }

  isCondoProjectInON(): boolean {
    return this.context.isProjectRelated && this.matter.isMatterProvinceON && this.context.isCondo;
  }

  get isProjectConfigTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject();
  }

  onBankNameChange(value: TrustAccountWrapper): void {
    this.selectedTrustAccount = value;
    if (value) {
      this.extraDepositConfig.bankAccountNumber = value.account.trustAccountNumber;
      this.extraDepositConfig.matterFirmTrustAccount = new TrustAccount(value.account);
    } else {
      this.extraDepositConfig.bankAccountNumber = null;
      this.extraDepositConfig.matterFirmTrustAccount = undefined;
    }
  }

  ok(): void {
    if (!this.modalErrorComponent.anyErrorExist()) {
      if (this.multipleDeposit == 'NO') {
        this.deposits = [];
        this.adjustmentFormat = undefined;
      } else {
        this.propDepositAmount = 0;
      }
      this.recalculateDepositSheet();
      this.matter.recalculateForm4Charges();
      let depositAmount = this.multipleDeposit == 'NO' ? this.propDepositAmount : this.propDepositTotalAmount;
      let depositStatementAdj = this.matter.findStatementAdjustment(StatementAdjustmentKey.DEPOSIT);
      if (depositStatementAdj) {
        depositStatementAdj.amount = depositAmount;
      }
      this.dialog.close({
        depositAmount: depositAmount,
        multipleDeposit: this.multipleDeposit,
        payDepositOutOfTrust: this.payDepositOutOfTrust,
        adjustmentFormat: this.adjustmentFormat,
        deposits: this.createResultDeposits(),
        infoOnly: this.infoOnly,
        extraDepositConfig: this.extraDepositConfig,
        // this soa object contains potentially updated values of the underlying matter (with same id/matterId/projectId)
        depositStatementAdj: depositStatementAdj
      } as DepositModalContextValue);
    }
  }

  close(): void {
    this.dialog.close();
  }

  async delete(): Promise<void> {
    let response = await this.dialogService
      .confirm('Confirmation', 'Do you wish to reset this adjustment?', false, 'Reset')
      .toPromise();
    if (response) {
      this.deposits = [];
      this.propDepositAmount = 0;
      this.propDepositTotalAmount = 0;
      this.propSumOfDepositsAmount = 0;
      this.multipleDeposit = 'NO';
      this.extraDepositConfig = new MatterExtraDepositConfig();
      //After confirm with BA, it needs to clean depositAmount for Occupancy Deposit. depositAmount is a string type
      if (this.occupancyDeposit) {
        this.occupancyDeposit.depositAmount = '0';
      }
      await this.updateDefaultFirmAccountOnMatter();
      this.ok();
    }
  }

  async updateDefaultFirmAccountOnMatter(): Promise<void> {
    const projId = this.matter.project ? this.matter.project.id : this.matter.unityProjectId;
    this.extraDepositConfig.matterFirmTrustAccount = await this.trustAccountsService.getDefaultFirmAccountOnProject(
      projId,
      this.matter.provinceCode
    );
  }

  onPayDepositOutOfTrustChange(): void {
    //Todo
    //if yes, then we will need to insert configuration into TL or Soa
  }

  onMultipleDepositChange(updateDepositNames: boolean): void {
    if (updateDepositNames) {
      // reformat deposit names
      this.deposits.forEach((deposit) => {
        if (deposit.depositDate) {
          deposit.updateDepositName(this.adjustmentFormat);
        }
      });
    }

    let propCondo = this.matter.matterPropertyWithCondo;
    if (propCondo) {
      propCondo.multipleDeposit = this.multipleDeposit;
    }
    this.recalculateDepositSheet();
  }

  private recalculateSOAForLocalMatter() {
    let depositSoa = this.matter.findStatementAdjustment(StatementAdjustmentKey.DEPOSIT);
    if (depositSoa) {
      depositSoa.amount = this.matter.calculateCreditPurchaserForDeposit();
    }
    this.matterService.updateStatementAdjustmentDisplayItems(this.matter);
  }

  private initLocalMatter(): void {
    if (this.context.matter === undefined || this.context.matter === null) {
      throw new Error('DepositModalContext should contain a matter');
    }
    this.matter = new Matter(this.context.matter);
  }

  onDepositAmtChange() {
    // single deposit amount
    this.recalculateDepositSheet();
  }

  onAdjustmentFormatChange() {
    let propCondo = this.matter.matterPropertyWithCondo;
    if (propCondo) {
      propCondo.adjustmentFormat = this.adjustmentFormat;
    }
    this.deposits.forEach((deposit) => {
      deposit.updateDepositName(this.adjustmentFormat);
    });
    this.recalculateDepositSheet();
  }

  getDateToSave(event): string {
    return event.rawDate;
  }

  addNewDeposit(): void {
    this.currentTimeStamp = Date.now();
    let deposit = new Deposit();
    this.deposits.push(deposit);
    this.onMultipleDepositChange(false);
    this.calculateTotalDeposit();
  }

  removeDeposit(selectedDeposit: Deposit, index: number): void {
    this.isDeleteWindowOpen = true;
    this.currentTimeStamp = Date.now();
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this deposit row?', false, 'Delete')
      .subscribe((res) => {
        this.isDeleteWindowOpen = false;
        if (res) {
          //console.warn("removeDeposit | this.depositDateFields=", this.depositDateFields._results[index]);
          this.depositDateFields._results[index].removeDPFieldErrors();
          this.removeDepositFromList(selectedDeposit);
          console.warn('this.modalErrorComponent.errorList after: ', this.modalErrorComponent.errorList);
          if (this.deposits.length === 0) {
            this.currentTimeStamp = Date.now();
            let deposit = new Deposit();
            this.deposits.push(deposit);
          }
          this.onMultipleDepositChange(false);
          this.calculateTotalDeposit(); // this triggers recalc of the entire sheet
        } else {
          setTimeout(this.setFocusOnDepositNameField, 0, index);
        }
      });
  }

  setFocusOnDepositNameField = (index) => {
    this.depositNameFields._results[index].nativeElement.focus();
  };

  removeDepositFromList(selectedDeposit: Deposit): void {
    let selectedDepositIndex: number = _.findIndex(this.deposits, (deposit) => deposit === selectedDeposit);
    if (selectedDepositIndex !== -1) {
      this.deposits.splice(selectedDepositIndex, 1);
    }
  }

  private calculateTotalDeposit(): void {
    if (!this.multipleDeposit) {
      let condo = this.matter.matterPropertyWithCondo;
      if (condo) {
        condo.depositAmount = Utils.toNumber(this.propDepositAmount);
      }
    } else {
      // multiple deposits
      this.propDepositTotalAmount = Deposit.calculateTotalDepositsIncludingExtras(this.deposits);
      this.propSumOfDepositsAmount = Deposit.calculateTotalDepositsExcludingExtras(this.deposits);

      this.applyDepositsToLocalMatters();

      if (this.modalErrorComponent && this.propDepositTotalAmount < 0) {
        this.modalErrorComponent.createDPSaveError('matter.propertyTeranet.deposit');
      } else if (this.modalErrorComponent) {
        this.modalErrorComponent.removeDpSaveError('matter.propertyTeranet.deposit');
      }
    }
  }

  private applyDepositsToLocalMatters() {
    if (this.deposits) {
      this.matter.matterPropertyWithCondo.removeNonOccupancyDeposits();
      for (let i = 0; i < this.deposits.length; i++) {
        let deposit = new Deposit(this.deposits[i]);
        this.matter.addDepositToPrimaryProperty(deposit);
      }
    }
  }

  //Combine occupancy & non-occupancy deposits in result list
  createResultDeposits(): Deposit[] {
    let resultDeposits: Deposit[] = [];
    if (this.deposits) {
      // do not send blank deposit line items
      resultDeposits.push(...this.deposits.filter((dep) => !_.isEmpty(dep.depositName)));
    }
    if (this.occupancyDeposit) {
      resultDeposits.push(this.occupancyDeposit);
    }
    return resultDeposits;
  }

  checkDepositValue(deposit: Deposit): boolean {
    if (
      deposit.depositName === undefined ||
      deposit.depositName === null ||
      (deposit.depositName != null && deposit.depositName.toString().trim() === '')
    ) {
      deposit.depositAmount = '0.00';
      this.calculateTotalDeposit();
      return true;
    } else {
      return false;
    }
  }

  onDepositDateChange(dateEvent: string, deposit: Deposit): void {
    deposit.depositDate = this.getDateToSave(dateEvent);
    if (deposit.depositDate) {
      deposit.updateDepositName(this.adjustmentFormat);
    }
  }

  beforeDismiss(): boolean {
    return this.isDeleteWindowOpen;
  }

  getOccupancyDepositBurgerMenuItems(): any[] {
    let items: any[] = [];
    if (this.occupancyDeposit) {
      this.addPrintForm4BurgerMenuItem(items);
    }
    return items;
  }

  clickOccupancyDepositBurgerMenu(menuItem: any) {
    switch (menuItem) {
      case MultiDepositRowBurgerMenu.printForm4.key:
        this.openPrintForm4Modal(this.occupancyDeposit);
        break;
    }
  }

  getMultiDepositBurgerMenuItems(index: number, deposit: Deposit): any[] {
    const max = this.deposits.length;
    let items: any[] = [MultiDepositRowBurgerMenu.addAbove];

    let statusMenu = {
      text: MultiDepositRowBurgerMenu.status.text,
      key: MultiDepositRowBurgerMenu.status.key,
      items: []
    };

    if (this.context.isProjectRelated) {
      if (deposit.isNSF()) {
        statusMenu.items.push({
          text: MultiDepositRowBurgerMenu.statusClearNSF.text,
          key: MultiDepositRowBurgerMenu.statusClearNSF.key
        });
      } else {
        statusMenu.items.push({
          text: MultiDepositRowBurgerMenu.statusNSF.text,
          key: MultiDepositRowBurgerMenu.statusNSF.key
        });
      }
      if (deposit.isNH()) {
        statusMenu.items.push({
          text: MultiDepositRowBurgerMenu.statusClearNH.text,
          key: MultiDepositRowBurgerMenu.statusClearNH.key
        });
      } else {
        statusMenu.items.push({
          text: MultiDepositRowBurgerMenu.statusNH.text,
          key: MultiDepositRowBurgerMenu.statusNH.key
        });
      }
      items.push(statusMenu);

      this.addPrintForm4BurgerMenuItem(items);

      if (!!deposit.extras) {
        items.push({text: MultiDepositRowBurgerMenu.extrasClear.text, key: MultiDepositRowBurgerMenu.extrasClear.key});
      } else {
        items.push({text: MultiDepositRowBurgerMenu.extras.text, key: MultiDepositRowBurgerMenu.extras.key});
      }
    }

    if (this.context.isProjectRelated) {
      items.push({text: MultiDepositRowBurgerMenu.delete.text, key: MultiDepositRowBurgerMenu.delete.key});
    } else {
      if (this.multipleDeposit === 'YES') {
        items.push({text: MultiDepositRowBurgerMenu.delete.text, key: MultiDepositRowBurgerMenu.delete.key});
      }
    }

    if (index > 0) {
      items.push(MultiDepositRowBurgerMenu.moveUp);
    }
    if (index < max - 1) {
      items.push(MultiDepositRowBurgerMenu.moveDown);
    }

    return items;
  }

  addPrintForm4BurgerMenuItem(items: any[]): void {
    if (
      this.matter.provinceCode == 'ON' &&
      this.matter.matterPropertyWithCondo.isPropertyCondominium() &&
      !this.context.hidePrintF4Menu
    ) {
      items.push(MultiDepositRowBurgerMenu.printForm4);
    }
  }

  clickMultiDepositBurgerMenu(menuItem: any, index: number) {
    switch (menuItem) {
      case MultiDepositRowBurgerMenu.addAbove.key:
        let deposit = new Deposit();
        this.currentTimeStamp = Date.now();
        this.deposits.splice(index, 0, deposit);
        break;
      case MultiDepositRowBurgerMenu.moveDown.key:
        let tmp1 = this.deposits[index];
        this.deposits[index] = this.deposits[index + 1];
        this.deposits[index + 1] = tmp1;
        break;
      case MultiDepositRowBurgerMenu.moveUp.key:
        let tmp2 = this.deposits[index];
        this.deposits[index] = this.deposits[index - 1];
        this.deposits[index - 1] = tmp2;
        break;
      case MultiDepositRowBurgerMenu.statusNSF.key:
        this.deposits[index].depositStatus = DepositStatus.NSF;
        break;
      case MultiDepositRowBurgerMenu.statusNH.key:
        this.deposits[index].depositStatus = DepositStatus.NH;
        break;
      case MultiDepositRowBurgerMenu.statusClearNSF.key:
      case MultiDepositRowBurgerMenu.statusClearNH.key:
        this.deposits[index].depositStatus = null;
        break;
      case MultiDepositRowBurgerMenu.extras.key:
        this.deposits[index].extras = true;
        break;
      case MultiDepositRowBurgerMenu.extrasClear.key:
        this.deposits[index].extras = false;
        break;
      case MultiDepositRowBurgerMenu.printForm4.key:
        this.openPrintForm4Modal(this.deposits[index]);
        break;
      case MultiDepositRowBurgerMenu.delete.key:
        this.removeDeposit(this.deposits[index], index);
        break;
    }

    if (menuItem != MultiDepositRowBurgerMenu.printForm4.key && menuItem != MultiDepositRowBurgerMenu.delete.key) {
      this.deposits.forEach((dep) => {
        if (dep.depositDate) {
          dep.updateDepositName(this.adjustmentFormat);
        }
      });
      this.recalculateDepositSheet();
    }
  }

  openPrintForm4Modal(selectedDeposit: Deposit): void {
    let depositCopy: Deposit = new Deposit(selectedDeposit);
    this.dialogService.matDialogContent({
      content: Form4SpecificationModalComponent,
      context: {
        matter: this.matter,
        selectedDeposit: selectedDeposit,
        initiatedFromTab: 'Matter'
      },
      onFulfillment: (result: any) => {
        if (result && result.action == 'cancel') {
          this.revertChangesFromSelectedDeposit(depositCopy, selectedDeposit);
        } else if (result && result.action == 'OK') {
          let applyChangesTo: DepositPrintFormatType = selectedDeposit.depositPrintFormat;
          if (selectedDeposit.depositPrintFormat != 'SELECTED_DEPOSIT') {
            let form4Date: string = selectedDeposit.form4Date;
            //If applyChangesTo is not SELECTED_DEPOSIT then reverting any changes made to current deposit.
            this.revertChangesFromSelectedDeposit(depositCopy, selectedDeposit);
            this.updateForm4DateForApplicableDeposits(applyChangesTo, form4Date);
          }

          if (result.isPrintRequired) {
            this.printForm4(selectedDeposit);
          }

          //Form4 sent status should be updated after print document
          if (selectedDeposit.depositPrintFormat != 'SELECTED_DEPOSIT') {
            let isForm4Sent: boolean = selectedDeposit.form4Sent;
            this.updateForm4StatusForApplicableDeposits(applyChangesTo, isForm4Sent);
          }
        }
      }
    });
  }

  // This method first saves the matter then calls the depositManagerService.printForm4 to print and display the form
  printForm4(selectedDeposit: Deposit): void {
    // Before print, Set the deposit values, for the current deposit record to matter.
    let resultDeposits: Deposit[] = this.createResultDeposits();
    let clonedMatter = new Matter(this.matter);
    clonedMatter.extraDepositConfig = new MatterExtraDepositConfig(this.extraDepositConfig);
    clonedMatter.updateDepositsInMatter(resultDeposits);
    this.depositManagerService.printForm4(clonedMatter, selectedDeposit);
  }

  revertChangesFromSelectedDeposit(depositCopy: Deposit, selectedDeposit: Deposit): void {
    if (selectedDeposit.paidOnOccupancy) {
      this.occupancyDeposit = depositCopy;
    } else {
      let selectedDepositIndex = this.deposits.findIndex((value) => value == selectedDeposit);
      this.deposits[selectedDepositIndex] = depositCopy;
    }
  }

  updateForm4StatusForApplicableDeposits(applyChangesTo: DepositPrintFormatType, isForm4Sent: boolean) {
    let applicableDeposits: Deposit[] = this.getApplicableDeposits(applyChangesTo);
    applicableDeposits.forEach((otherDeposit) => {
      otherDeposit.form4Sent = isForm4Sent;
    });
  }

  updateForm4DateForApplicableDeposits(applyChangesTo: DepositPrintFormatType, form4Date: string) {
    let applicableDeposits: Deposit[] = this.getApplicableDeposits(applyChangesTo);
    applicableDeposits.forEach((otherDeposit) => {
      otherDeposit.form4Date = form4Date;
    });
  }

  getApplicableDeposits(applyChangesTo: DepositPrintFormatType): Deposit[] {
    let applicableDeposits: Deposit[] = [];
    if (
      applyChangesTo == 'AGGREGATE_DEPOSITS_EXCEPT_OCCUPANCY' ||
      applyChangesTo == 'ALL_DEPOSITS_ON_SINGLE_PAGE_EXCEPT_OCCUPANCY'
    ) {
      applicableDeposits = this.deposits.filter((d) => !d.paidOnOccupancy);
    } else if (applyChangesTo == 'FORM_NOT_SENT_ON_SEPARATE_PAGE' || applyChangesTo == 'FORM_NOT_SENT_ON_SINGLE_PAGE') {
      applicableDeposits = this.deposits.filter((d) => !d.form4Sent);
      if (!this.occupancyDeposit.form4Sent) {
        applicableDeposits.push(this.occupancyDeposit);
      }
    } else if (applyChangesTo == 'ALL_DEPOSITS_ON_SINGLE_PAGE') {
      applicableDeposits.push(...(this.deposits || []));
      applicableDeposits.push(this.occupancyDeposit);
    }
    return applicableDeposits;
  }

  calculateHeightAdjustment(maxValue: number, minValue: number, defaultAdjustmentValue: number = 250): number {
    let result: number = this.screenHeight - defaultAdjustmentValue;
    if (result > maxValue) {
      result = maxValue;
    } else if (result < minValue) {
      result = minValue;
    }
    return result;
  }

  calculateModalBodyMaxHeight(): string {
    let defaultHeight = this.modalErrorComponent && this.modalErrorComponent.anyErrorExist() ? 424 : 484;
    if (this.multipleDeposit == 'YES') {
      defaultHeight = defaultHeight + this.calculateHeightAdjustment(440, 180);
    }
    return `${defaultHeight}px`;
  }

  calculateModalBodyMinHeight(): string {
    let defaultHeight = 350;
    if (this.multipleDeposit == 'YES') {
      defaultHeight = this.calculateHeightAdjustment(660, 350);
    }
    return `${defaultHeight}px`;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event) {
    this.screenHeight = window.innerHeight;
  }

  get modalTitle(): string {
    return this.isCondoProjectInON_AB() ? 'Condominium Deposits' : 'Deposit';
  }

  onDepositOnOccupancyFlagChange(): void {
    if (this.extraDepositConfig.depositOnOccupancyFlag) {
      this.extraDepositConfig.deferredPurchaseMoniesFlag = false;
    }

    if (this.occupancyDeposit) {
      this.occupancyDeposit.depositAmount = this.extraDepositConfig.depositOnOccupancyAmount + '';
    }
    if (
      /*this.matter.isMatterProvinceAB && */ !this.extraDepositConfig.depositOnOccupancyCalBasedOn &&
      this.context.isCondo
    ) {
      this.extraDepositConfig.depositOnOccupancyCalBasedOn = DepositOnOccupancyCalculatedBasedOn.UNADJUSTED_BALANCE;
    }
    this.recalculateDepositSheet();
  }

  // this method has been refactored to handle all calculation of the Deposit Modal dialog and update "extraDepositConfig" object values
  // - all Deposit Modal UI change handlers should simply call this method - after maintaining UI field dependencies
  //   (e.g. checkbox A must be unchecked if checkbox B is selected, etc)
  private recalculateDepositSheet() {
    // step 1: determine total of deposits (D20)
    this.calculateTotalDeposit();

    // step 2: already done in ngOnInit() - determine VTB money amount (D19)

    // step 3: determine Sale Price Total (D22)
    if (this.isForcedMultiDeposit) {
      if (
        this.extraDepositConfig.salePriceTotalOption == SalePriceOptionValue.AS_PER_AGREEMENT ||
        this.extraDepositConfig.salePriceTotalOption == SalePriceOptionValue.INCLUDING_CREDITS_TO_PV
      ) {
        this.extraDepositConfig.salePriceTotal = this.matter.getSalePriceIncludingCreditsToPV(
          this.extraDepositConfig.salePriceTotalOption
        );
      } else {
        throw new Error(
          'DepositModal component: enum value for salePriceOption not supported yet: ' +
            this.extraDepositConfig.salePriceTotalOption
        );
      }
    }

    // step 4: calculating the rest of deposit modal data: D17, D18, and D21
    if (this.isForcedMultiDeposit) {
      if (
        this.extraDepositConfig.depositOnOccupancyFlag &&
        this.extraDepositConfig.depositOnOccupancyCalBasedOn != DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA
      ) {
        // D17 depends on D18, D21 and etc...
        this.recalcDeferredPurchaseMonies();
        this.recalculateSalePriceTotal();
        this.recalculateDepositOnOccupancyAmount();
      } else if (
        this.context.projectOccupancyFeesCalBasedOn != OCCUPANCY_FEES_CALCULATED_BASED_ON.phantomMortgage &&
        this.extraDepositConfig.deferredPurchaseMoniesFlag
      ) {
        // D18 depends on D17, D21 and etc...
        this.recalculateDepositOnOccupancyAmount();
        this.recalculateSalePriceTotal();
        this.recalcDeferredPurchaseMonies();
      } else if (
        !this.extraDepositConfig.depositOnOccupancyFlag &&
        !this.extraDepositConfig.deferredPurchaseMoniesFlag
      ) {
        // D21 depends on D17, D18, and etc...
        this.recalculateDepositOnOccupancyAmount();
        this.recalcDeferredPurchaseMonies();
        this.recalculateSalePriceTotal();
      } else {
        // no obvious dependency among computation for D17, D18 and D21
        this.recalculateDepositOnOccupancyAmount();
        this.recalcDeferredPurchaseMonies();
        this.recalculateSalePriceTotal();
      }
    }

    // step 5: update local matter instances with latest values from the modal
    this.applyExtraDepositValuesToLocalMatter();

    // step 6: recalulate SOA values for local matters
    this.recalculateSOAForLocalMatter();
  }

  onDepositOnOccupancyCalBasedOnClick(value: DepositOnOccupancyCalculatedBasedOn) {
    this.extraDepositConfig.depositOnOccupancyCalBasedOn = value;
    this.recalculateDepositSheet();
  }

  private applyExtraDepositValuesToLocalMatter() {
    this.matter.extraDepositConfig = new MatterExtraDepositConfig(this.extraDepositConfig);
  }

  onDeferredPurchaseMoniesFlagChange(): void {
    if (this.extraDepositConfig.deferredPurchaseMoniesFlag) {
      this.extraDepositConfig.depositOnOccupancyFlag = false;
    }
    this.recalculateDepositSheet();
  }

  private recalcDeferredPurchaseMonies(): void {
    if (
      this.isForcedMultiDeposit &&
      this.isProjectOccupancyFeeBasedOnDeferredMoney() &&
      this.extraDepositConfig.deferredPurchaseMoniesFlag
    ) {
      this.extraDepositConfig.deferredPurchaseMoniesAmount = this.matter.calculateDeferredPurchaseMoniesAmount(
        this.extraDepositConfig,
        this.context.projectOccupancyFeesCalBasedOn
      );
    }
    // this.recalculateDepositSheet();
  }

  recalculateSalePriceTotal(): void {
    this.extraDepositConfig.calculatedSalePriceTotal = this.matter.getCalculatedSalePriceTotal(
      this.extraDepositConfig,
      this.context.projectOccupancyFeesCalBasedOn
    );
  }

  recalculateDepositOnOccupancyAmount(): void {
    if (this.extraDepositConfig.depositOnOccupancyFlag) {
      if (
        this.extraDepositConfig.depositOnOccupancyCalBasedOn == DepositOnOccupancyCalculatedBasedOn.BDOC_IN_INTERIM_SOA
      ) {
        // DPPMP-28748 - according to Dwayne:
        // this value should always be the Interim-side BDOC amount (obtained at modal opening, which does not change for the modal's life)
        this.setOccupancyDepositAmount(this.context.interimBDOCAmount);
      } else {
        // 'unadjusted balance' is selected, use story's attached excel sheet
        this.setOccupancyDepositAmount(
          this.matter.calculateDepositOnOccupancy(this.extraDepositConfig, this.context.projectOccupancyFeesCalBasedOn)
        );
      }
    } // else the currency field is editable
  }

  //Updating occupancy deposit amount in both extraDepositConfig and occupancyDeposit. Not reusing same method from matter.ts as this component has it's
  // own local copies
  setOccupancyDepositAmount(amount: number): void {
    if (this.extraDepositConfig) {
      this.extraDepositConfig.depositOnOccupancyAmount = amount;
    }
    if (this.occupancyDeposit) {
      this.occupancyDeposit.depositAmount = '' + amount;
    }
  }

  get vtbMortgageLabel(): string {
    if (this.context.projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.deferredPurchaseMonies) {
      return 'VTB Mortgage Monies';
    } else if (this.isProjectRelatedDepositAB()) {
      return 'VTB Mortgage';
    } else {
      return 'Phantom mortgage monies';
    }
  }

  isProjectOccupancyFeeBasedOnDeferredMoney(): boolean {
    return this.context.projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.deferredPurchaseMonies;
  }

  isProjectOccupancyFeeBasedOnPhantomMortgage(): boolean {
    return this.context.projectOccupancyFeesCalBasedOn == OCCUPANCY_FEES_CALCULATED_BASED_ON.phantomMortgage;
  }

  onSalePriceOptionChange() {
    this.recalculateDepositSheet();
  }

  get sumDepositsLabel(): string {
    // DPPM-T022-E070-U120 - Statement of Adjustments - PR
    // * ELSE IF that Deposit is marked as 'NSF' or 'NH' THEN
    //      - The field labeled 'Amount' is relabeled to 'Extras' and highlighted
    //      - The field label 'Sum of deposits below' retains its label
    const showExtraLabel = this.deposits && this.deposits.some((dep) => dep.extras && !dep.isNSF() && !dep.isNH());
    return showExtraLabel ? 'Total Deposits, excluding extras' : 'Sum of deposits below';
  }

  get depositLabel(): string {
    return this.usePluralForDepositLabel() ? 'DEPOSITS' : 'DEPOSIT';
  }

  usePluralForDepositLabel() {
    return this.multipleDeposit != 'NO' && this.adjustmentFormat == 'ONE_ITEM_ADJ' && this.deposits.length > 1;
  }

  get creditPurchaserForDeposit(): number {
    let depositAmount: number;
    if (this.multipleDeposit === 'NO') {
      depositAmount = this.propDepositAmount;
    } else {
      depositAmount = this.propDepositTotalAmount;
      //After confirm with BA , only project and project sale matter (which are condo) in final status need to add depositOnOccupancy
      if (
        this.context.isProjectRelated &&
        this.context.isCondo &&
        this.matter &&
        this.matter.isAdjustmentStatusModeFinal
      ) {
        const depositOnOccupancy = Utils.getNumberOrDefault(
          this.extraDepositConfig && this.extraDepositConfig.depositOnOccupancyAmount,
          0.0
        );
        depositAmount = depositAmount + depositOnOccupancy;
      }
    }

    return depositAmount;
  }

  isGreenForm4Sent() {
    return this.context.isProjectRelated && this.context.isCondo && this.matter.isMatterProvinceON;
  }

  isProjectRelatedDepositAB(): boolean {
    return this.context.isProjectRelated && this.context.isCondo && this.matter.isMatterProvinceAB;
  }

  isProjectCondoFinalDepositOnOccupancyUnadjusted(): boolean {
    return (
      this.matter.project &&
      !this.matter.isProjectSale &&
      this.matter.isAdjustmentStatusModeFinal &&
      this.extraDepositConfig.depositOnOccupancyFlag &&
      this.extraDepositConfig.depositOnOccupancyCalBasedOn === DepositOnOccupancyCalculatedBasedOn.UNADJUSTED_BALANCE
    );
  }

  getDepositOnOccupancyAmountAsNumber(): number {
    let depositAmount: string = '0';
    if (this.occupancyDeposit && this.occupancyDeposit.depositAmount) {
      depositAmount = this.occupancyDeposit.depositAmount;
    }
    return Utils.toNumber(depositAmount);
  }

  getPropDepositTotalAmount(): number {
    if (this.isProjectCondoFinalDepositOnOccupancyUnadjusted()) {
      return this.propDepositTotalAmount + this.getDepositOnOccupancyAmountAsNumber();
    } else {
      return this.propDepositTotalAmount;
    }
  }
}
