import {DocumentProductionService} from '../document-production/document-production.service';
import {MatterParticipant} from './matter-participant';
import {PrintIdModal} from '../purchaser/print-id/print-id.modal.component';
import {currentMatter} from './current-matter';
import {PrintIdAction, PrintIdInfo} from '../purchaser/print-id/print-id-info';
import {DocumentIdentificationData} from '../purchaser/print-id/document-identification-data';
import {DocumentProductionUtil} from './document-production/document-production-util';
import {DialogConfigParams, DialogService} from '../../shared/dialog/dialog.service';
import {StatusBarService} from '../../shared-main/status-bar.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Component} from '@angular/core';

@Component({
  template: 'dp-ivf-generate-base'
})
export class IvfGeneratorBase {

  constructor(
    public documentProductionService: DocumentProductionService,
    public dialogService: DialogService,
    public statusBarService: StatusBarService) {

  }

  checkGenStatusIntervalID: any[] = [];
  userCancelProduceDocFlag = false;

  ngOnDestroy(): void {
    if (Array.isArray(this.checkGenStatusIntervalID)) {
      // need to stop pooling for generated IVF
      this.checkGenStatusIntervalID.forEach(chkStatus => window.clearInterval(chkStatus.interval));
    }
  }

  openGeneratedIVF(matterId: number, documentId: number, fileType: string): void {
    if (fileType === 'PDF') {
      this.documentProductionService.openFileInNewWindow(matterId, documentId, true, false);
    } else {

      this.documentProductionService.getDocument(matterId, documentId.toString())
      .subscribe(matterDocument => {
        this.documentProductionService.openDocumentForEdit(documentId.toString(), matterDocument.documentName, fileType);
      });
    }
  }

  openPrintIdFormsModal(matterId: number, participantList: MatterParticipant[]): void {

    // Fix the issue which can not open the window of PrintIdModal by initializing defaultViewContainer again
    this.dialogService.matDialogContent({
      content: PrintIdModal,
      context: {
        purchasers: participantList,
        selectedRecords: [],
        matter: currentMatter.value
      },
      onFulfillment: (result) => {
        if (result) {
          let selectedRecords: PrintIdInfo[] = result.selectedRecords;
          console.log('this.matter.value.id:' + matterId);

          // this.lockScreenService.lockForUpdate = true;
          this.statusBarService.currentHelpText = 'Processing';
          this.userCancelProduceDocFlag = false;
          switch (result.action) {
            case PrintIdAction.PRINT_ID:
            case PrintIdAction.PRINT_DECLARATION: {

              let printIdForm: boolean = result.action === PrintIdAction.PRINT_ID;
              // prepare for post action call
              let documentIdentificationData: DocumentIdentificationData = new DocumentIdentificationData();
              documentIdentificationData.matterId = matterId;
              documentIdentificationData.docProfileFileType = DocumentProductionUtil.getRequestedTemplateFileTypeFromOutputType(result.isDocumentProfileTypeWord, result.outputType);
              documentIdentificationData.requestedFileTypes.push(...DocumentProductionUtil.getRequestedFileTypesFromOutputType(result.outputType));

              let recordsToBePrinted = printIdForm ? selectedRecords : selectedRecords.filter(item => 'Yes' === item.similarNameDeclared);
              recordsToBePrinted = recordsToBePrinted.filter(item => item.identificationRecord && item.identificationRecord.id);
              if (recordsToBePrinted && recordsToBePrinted.length) {
                documentIdentificationData.identificationRecordIds = recordsToBePrinted.map(item => item.identificationRecord.id);
              }
              if (documentIdentificationData.identificationRecordIds && documentIdentificationData.identificationRecordIds.length) {
                let dialogRef: MatDialogRef<any> = this.showCancelDocGenerationPopup();
                this.documentProductionService.generateIdentificationForm(documentIdentificationData, printIdForm)
                .subscribe((documentIdentificationData: DocumentIdentificationData) => {

                  if (Array.isArray(documentIdentificationData.documentIds)) {
                    documentIdentificationData.documentIds.forEach(generatedDocument => {

                      let newInterval: number = Number(window.setInterval(() => {

                        this.documentProductionService.getIdentificationFormsStatus(currentMatter.value.id, generatedDocument.documentId)
                        .subscribe(data => {
                          let generationProgressStatus: string = data;
                          if (generationProgressStatus === 'COMPLETED' || this.userCancelProduceDocFlag) {
                            // stop timer
                            this.checkGenStatusIntervalID
                            .filter(statusInterval => statusInterval.docId == generatedDocument.documentId)
                            .forEach(statusInterval => window.clearInterval(statusInterval.interval));
                            console.log('>> pdfGenerationProgressStatus ? ' + generationProgressStatus + ', userCancelPdfGeneration ? ' + this.userCancelProduceDocFlag);
                            this.statusBarService.currentHelpText = '';
                            dialogRef.close();

                            if (!this.userCancelProduceDocFlag) {
                              const contentModalConfig: DialogConfigParams = {
                                title: 'ID Verification Documents',
                                message: DocumentProductionUtil.getGenerationCompleteMessage(generatedDocument.fileType),
                                hideCancelBtn: false,
                                customConfirmBtnText: 'Open',
                                confirmFunction: () => {
                                  this.openGeneratedIVF(matterId, generatedDocument.documentId, generatedDocument.fileType);
                                }
                              };
                              this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => {
                                console.log('>> Response from openPdfModal: ', res);
                              });
                            }
                          }
                        });
                      }, 2000)); //This has to be long intervals because the response timing is too long too.
                      this.checkGenStatusIntervalID.push({docId: generatedDocument.documentId, interval: newInterval});
                    });
                  }
                });
              } else {
                this.dialogService.confirmDialog('ERROR', 'The ID verification record is not complete', true);
              }

              break;
            }
          }
        }
      }
    });
  }

  showCancelDocGenerationPopup(): MatDialogRef<any> {
    // popup to cancel document generation
    let dialogRef: MatDialogRef<any>;
    dialogRef = this.dialogService.confirmDialog('ID Verification Documents', 'Document is being generated. Please wait.', true, 'Cancel');
    dialogRef.afterClosed().subscribe(res => {
      if (res === undefined) {
        console.log('>> popup closed programmatically due to pdf generated, popup return status', res);
      } else {
        this.userCancelProduceDocFlag = true;
        console.log('>> User cancel the pdf generation, popup return status', res);
        this.statusBarService.currentHelpText = 'Document generation cancelled';
      }
    });
    return dialogRef;
  }
}
