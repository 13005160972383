<div>
    <div class="sticky" [ngClass]="{'sticky-supplemental-task': matterSupplementalTaskCategoryId}">

        <div class="padding-top-20 padding-bottom-20 form-horizontal" *ngIf="selection === 'produceDocuments'"
             [style.paddingBottom]="documentUtility.tablePadding">
            <div class="form-group">
                <div class="col-lg-4 margin-left-5 margin-top-5">
                    <input type="text"
                           [title]="searchTile"
                           #search="ngModel"
                           id="search-by"
                           name="search-by"
                           class="form-control search-by focus-first border-visible"
                           [(ngModel)]="searchText"
                           [dpPlaceholder]="'Template Name and Description Search'"
                           trimmed
                           [ngClass]="{'font-style-normal': searchText}"
                    />
                </div>
            </div>
            <div class="form-group">
                <div class="form-group action-buttons">
                    <ul class="padding-top-10">
                        <ng-container *ngIf="!isMatterDocument() && !supTask">
                        <li class="width-7percent inline-block padding-bottom-10">
                            <label class="control-label"
                                   for="categoryFilter">
                                Category
                            </label>
                        </li>
                        <li class="width-21percent inline-block" *ngIf="matterTypeInfos && matterTypeInfos.length>0">
                            <select class="form-control"
                                    id="categoryFilter"
                                    name="categoryFilter"
                                    tabindex="0"
                                    [(ngModel)]="categoryFilter"
                                    (ngModelChange)="onCategoryChange($event)">
                                <option *ngFor="let category of categories"
                                        [value]="category.id"
                                >
                                    {{getCategoryDescription(category)}}
                                </option>
                            </select>
                        </li>
                        </ng-container>
                        <li class="inline-block width-22percent padding-left-10" *ngIf="!supTask && !usedInModal">
                            <dp-checkbox fieldId="setAsDef" (click)="toggleDefaultCat()">
                                <input type="checkbox"
                                       [attr.checked]="isDefaultCatSelected()? 'checked' : null"
                                       [disabled]="isDefaultCatSelected()"
                                />
                            </dp-checkbox>
                            <label (click)="toggleDefaultCat()">Set as default Category</label>
                        </li>
                        <li  class="inline-block  text-right padding-right-10 padding-bottom-10"  [ngClass]="{'width-9percent' : !supTask}">
                            <label class="control-label" for="outputTypeSelect"  *ngIf="!isRtfProfile && !usedInModal">
                                Output
                            </label>
                        </li>
                        <li class="inline-block " [ngClass]="{'width-15percent' : !supTask}">
                            <select class="form-control" *ngIf="!isRtfProfile && !usedInModal"
                                    id="outputTypeSelect"
                                    name="outputTypeSelect"
                                    [dp-read-only]="isMatterDisabled()"
                                    tabindex="0"
                                    [(ngModel)]="outputType">
                                <option *ngFor="let outputs of outputTypeOptions;"
                                        [value]="outputs.value">
                                    {{outputs.label}}
                                </option>
                            </select>
                        </li>
                        <li class="inline-block  padding-left-10 "  [ngClass]="{'width-15percent float-right' : supTask, 'width-13percent' : !supTask}">
                            <button type="button" *ngIf="!isRtfProfile && !usedInModal"
                                    title="Use 'Produce Merge' to generate one document from Multiple inout documents"
                                    [disabled]="documentTemplateIds.length < 2 || isMatterDisabled()"
                                    [tabindex]="documentTemplateIds.length < 2 || isMatterDisabled() ? '-1':'0'"
                                    (click)="produceMerge()"
                                    class="dp-btn dp-btn-white">
                                <span>Produce Merge</span>
                            </button>
                        </li>
                        <li class="dropdown padding-right-5 margin-right-0 inline-block" [ngClass]="{'width-15percent float-right' : supTask, 'width-13percent' : !supTask}">
                            <button label="Produce (F2)" class="standard-table-button dropbtn margin-left-0 margin-right-0" *ngIf="!usedInModal"
                                    [disabled]="documentTemplateIds.length == 0 || isMatterDisabled()"
                                    [tabindex]="documentTemplateIds.length == 0 || isMatterDisabled() ? '-1':'0'"
                                    (click)="produceDocument()">
                                <span>Produce (F2)</span>
                                <div class="clear"></div>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <!--<div class="loading" *ngIf="rows.length==0">Loading Documents...</div>-->
        </div>


    </div>



    <div *ngIf="selection === 'produceDocuments'" class="view-table row sticky-table-wrapper" [ngClass]="{'sticky-table-wrapper-supplemental-task': matterSupplementalTaskCategoryId}">
        <div class="col-lg-12">
            <table class="standard-table col-lg-12 sticky-table margin-top-70" [mfData]="rows" #mf="mfDataTable" [mfRowsOnPage]="3000" [attr.tabindex]="-1"
            (keydown)="tableKeyCommands($event)">
                <thead class="sticky-table-header" [ngClass]="{'sticky-table-header-supplemental-task': matterSupplementalTaskCategoryId}">
                <tr>
                    <th class="sticky-table-c1 padding-left-3">
                        <div class="wrap-header-nohover" *ngIf="!matter?.locked">
                            <dp-checkbox fieldId="multiSelectBox" [hasTabIndex]="true">
                                <input type="checkbox" id="multiSelectBox" name="multiSelect" #multiSelect
                                       [(ngModel)]="allDocumentsSelected"
                                         (change)="multiSelectDocuments(multiSelect.checked)"

                            /></dp-checkbox>
                        </div>
                    </th>
                    <th [hidden]="true">number


                    </th>
                    <th class="sticky-table-c2" (click)="sort($event, sortFileName)" id="sortFileName">
                        <div class="wrap-header"><span style="width: 100%;">Master Template Name
                        <dp-default-sorter #sortFileName by="template.fileName"  [singleState]="true"></dp-default-sorter></span></div>
                    </th>
                    <ng-container *ngIf="!usedInModal">
                        <th class="sticky-table-c3"></th>
                    </ng-container>
                    <th class="sticky-table-c4" (click)="sort($event, sortDescription)">
                        <div class="wrap-header">Description
                            <dp-default-sorter #sortDescription
                                               by="template.description" [singleState]="true"></dp-default-sorter>
                        </div>
                    </th>
                    <ng-container *ngIf="!usedInModal">
                        <th class="sticky-table-c5" (click)="sort($event, sortTimeStamp)">
                            <div class="wrap-header">
                                {{docTypeDisplayName}} Document
                                <dp-default-sorter #sortTimeStamp by="word.lastUpdatedTimeStamp" [singleState]="true"></dp-default-sorter>
                            </div>
                        </th>
                        <!-- <th class="sticky-table-c6" (click)="sortStatus.sort();"> -->
                        <th class="sticky-table-c6"  (click)="sort($event, sortTimeStamp2)">
                            <div class="wrap-header">

                                <!-- Status -->
                                PDF Document
                                <dp-default-sorter #sortTimeStamp2 by="pdf.lastUpdatedTimeStamp" [singleState]="true"></dp-default-sorter>
                            </div>
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody [ngClass] = "{'tbody-in-modal' : usedInModal}">
                <tr *ngFor="let row of mf.data; let i = index;"
                    (click)="clickRow($event,i,row);"
                    [ngClass]="{'active': isSelected(row.template.templateIdWithMortgageOrAffidavitIndex)}"
                    (focus)="setActiveIndex(i,row.template.templateIdWithMortgageOrAffidavitIndex);" tabindex="0">
                    <td class="sticky-table-c1 check-box-column padding-left-5" *ngIf="!row.template.isInfected && !row.template.isVirusScanPending">
                        <div [ngSwitch]="documentUtility.isDocumentLocked(row.word) || matter?.locked" class="table-cell-01 text-margin-dropdown check-box-column">
                            <div *ngSwitchCase="true" class="matter-lock-icon-div">
                            <span class="matter-lock-icon">
                              <i class="fa fa-lock"></i>
                            </span>
                                <span class="matter-locked-message">
                                                <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                               <span>
                                  Locked by: <u><i>{{ matter?.lockedByUser.firstName + ' ' + matter?.lockedByUser.lastName
                                       }}</i></u>
                              </span>
                            </span>
                            </div>
                            <div *ngSwitchDefault class="check-box-column">
                                <dp-checkbox fieldId="selected_{{i}}" [hasTabIndex]="false">
                                    <input type="checkbox" id="selected_{{i}}" name="selected"
                                           (change)="clickRow($event,i,row);"
                                           [checked]="isSelected(row.template.templateIdWithMortgageOrAffidavitIndex)"/>
                                </dp-checkbox>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="row.template.isInfected || row.template.isVirusScanPending" class="sticky-table-c1">
                            <div  class="table-cell-01 text-margin-dropdown">
                                    <i class="virus-icon margin-left-3"  [ngClass]="row.template.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>
                                    <span class="matter-locked-message virus-message">
                                                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                                   <span *ngIf="row.template.isInfected">
                                      We have detected this file is infected with a virus.
                                  </span>
                                      <span *ngIf="row.template.isVirusScanPending">
                                      Please wait... we are currently scanning this file for viruses.
                                  </span>
                                </span>
                                </div>
                    </td>
                    <td [hidden]="true">{{row.counter}}</td>
                    <td class="sticky-table-c2">

                       <span title="{{row.template.fileName}}" *ngIf="documentUtility.doesValueNeedTriming(row.template.fileName, 62)">
                               {{row.template.fileName | slice:0:60}}...

                       </span>

                        <span title="{{row.template.fileName}}" *ngIf="!documentUtility.doesValueNeedTriming(row.template.fileName, 62)">
                           {{row.template.fileName}}
                        </span>

                    </td>
                    <ng-container *ngIf="!usedInModal">
                        <td class="sticky-table-c3">
                            <dp-burger-menu *ngIf="row" #dpMenu
                                            [disabledItems]="getDisabledItems(row)"
                                            [items]="burgerMenuOptions"
                                            [menuSize]="[1.7]"
                                            (itemClicked)="burgerMenuClicked($event, row)"
                                            [addFocus]="true"
                                            (menuOpened)="documentUtility.burgerMenuOpen(row.template.templateIdWithMortgageOrAffidavitIndex,$event,documentTemplateIds)"
                                            ></dp-burger-menu>
                        </td>
                    </ng-container>
                    <td class="sticky-table-c4">
                        <span title="{{row.template.description}}" class="sticky-table-c4-wrap" *ngIf="documentUtility.doesValueNeedTriming(row.template.description, 80)">
                            {{row.template.description | slice:0:80}}...
                        </span>
                        <span title="{{row.template.description}}" class="sticky-table-c4-wrap"
                              *ngIf="!documentUtility.doesValueNeedTriming(row.template.description, 80)">
                            {{row.template.description}}
                        </span>

                    </td>
                    <ng-container *ngIf="!usedInModal">
                    <td class="sticky-table-c5">
                                <div class="hyperlink-display" *ngIf="row.word && row.word.status === 'COMPLETED' && !row.word.isInfected && !row.word.isVirusScanPending">
                                                                <a (click)="openWordForEdit(row.word.id,row.word.documentName, row.word.documentType, $event)"><img
                                                                    src="{{getWordDocsIcon(row.word.documentName)}}"
                                                                    [attr.data-documentName]=row.word.documentName
                                                                    [attr.data-documentType]=row.word.documentType
                                                                    alt="word Icon" class="word-icon" /><i alt="word Icon" id="word_{{row.word.id}}"
                                        [attr.data-documentName]=row.word.documentName [attr.data-documentType]=row.word.documentType [attr.data-id]=row.word.id>{{row.word.lastUpdatedTimeStamp| date:'mediumDate'}}, {{row.word.lastUpdatedTimeStamp| date:'shortTime'}}</i></a>
                                </div>


                                <div class="row">
                                        <span *ngIf="row.word && row.word.status &&  row.word.status !== 'COMPLETED' && !row.word.isInfected && !row.word.isVirusScanPending"
                                              class="col-lg-1 document-production-status-icon"><img id="word-icon_{{row.id}}"
                                                                                                    src="/assets/img/document-production-icon-{{documentUtility.mapStatus(row.word?.status ,false ).toLowerCase()}}.png"
                                                                                                    alt="{{documentUtility.mapStatus(row.word?.status , true)}}"/></span>
                                    <span *ngIf="row.word &&  row.word.status !== 'COMPLETED'  && !row.word.isInfected && !row.word.isVirusScanPending "
                                          class="col-lg-9 document-production-status-text">{{documentUtility.mapStatus(row.word?.status , true)}}</span>
                                    <div class="col-lg-1 clear"></div>
                                </div>


                                <!-- START // virus checking code -->
                                    <div *ngIf="row.word && (row.word.isInfected || row.word.isVirusScanPending)">
                                        <i class="virus-icon virus-on-document-icon margin-left-3"
                                           [ngClass]="row.word.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>
                                        <span class="matter-locked-message virus-message">
                                                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                                   <span *ngIf="row.word.isInfected">
                                      We have detected this file is infected with a virus.
                                  </span>
                                      <span *ngIf="row.word.isVirusScanPending">
                                      Please wait... we are currently scanning this file for viruses.
                                  </span>
                                </span>
                                        <img
                                            src="{{getWordDocsIcon(row.word.documentName)}}" [attr.data-documentName]="'INFECTED'" [attr.data-documentType]=row.word.documentType
                                            alt="word Icon" class="word-icon" /><i alt="word Icon" id="word_{{row.word.id}}"
                                            [attr.data-documentName]="'INFECTED'" [attr.data-documentType]=row.word.documentType
                                            [attr.data-id]=row.word.id>{{row.word.lastUpdatedTimeStamp| date:'mediumDate'}}, {{row.word.lastUpdatedTimeStamp| date:'shortTime'}}</i>
                                    </div>
                                 <!-- END // virus checking code -->





                    </td>
                    <td class="sticky-table-c6">
                        <div *ngIf="row.pdf && row.pdf.status === 'COMPLETED'  && !row.pdf.isInfected && !row.pdf.isVirusScanPending">
                            <a> <img src="assets/img/pdf.png" id="pdf_{{row.id}}" [attr.data-documentName]=row.pdf.documentName alt="pdf Icon" class="pdf-icon"
                            /><i alt="PDF Icon" id="pdf_{{row.pdf.id}}" [attr.data-id]=row.pdf.id
                                  [attr.data-documentName]=row.pdf.documentName>{{row.pdf.lastUpdatedTimeStamp| date:'mediumDate'}},
                                {{row.pdf.lastUpdatedTimeStamp| date:'shortTime'}}</i></a>
                        </div>

                        <div class="row">
                        <span *ngIf="row.pdf && row.pdf.status &&  row.pdf.status !== 'COMPLETED' && !row.pdf.isInfected && !row.pdf.isVirusScanPending "
                              class="col-lg-1 document-production-status-icon"><img
                            src="/assets/img/document-production-icon-{{documentUtility.mapStatus(row.pdf?.status ,false ).toLowerCase()}}.png"
                            alt="{{documentUtility.mapStatus(row.pdf?.status , true)}}"/></span>
                            <span *ngIf="row.pdf && row.pdf.status &&  row.pdf.status !== 'COMPLETED' && !row.pdf.isInfected && !row.pdf.isVirusScanPending"
                                  class="col-lg-9 document-production-status-text">{{documentUtility.mapStatus(row.pdf?.status , true)}}</span>
                            <div class="col-lg-1 clear"></div>
                        </div>

                        <!-- START // virus checking code -->
                        <div *ngIf="row.pdf && (row.pdf.isInfected || row.pdf.isVirusScanPending) ">
                            <i class="virus-on-document-icon margin-left-3"
                               [ngClass]="row.pdf.isInfected ? 'errorMessage far fa-exclamation-triangle' : 'warningMessage fas fa-exclamation-triangle'"></i>
                            <span class="matter-locked-message virus-message">
                                                    <span class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></span>
                                   <span *ngIf="row.pdf.isInfected">
                                      We have detected this file is infected with a virus.
                                  </span>
                                      <span *ngIf="row.pdf.isVirusScanPending">
                                      Please wait... we are currently scanning this file for viruses.
                                  </span>
                                </span>
                            <img src="assets/img/pdf.png" id="pdf_{{row.id}}" [attr.data-documentName]="'INFECTED'" alt="pdf Icon" class="pdf-icon"
                            /><i alt="PDF Icon" id="pdf_{{row.pdf.id}}" [attr.data-id]=row.pdf.id
                                 [attr.data-documentName]="'INFECTED'">&nbsp;{{row.pdf.lastUpdatedTimeStamp| date:'mediumDate'}},
                                {{row.pdf.lastUpdatedTimeStamp| date:'shortTime'}}</i>

                        </div>
                        <!-- END // virus checking code -->

                        <!-- for test automation -->
                        <div *ngIf="(row.word && row.word.id || row.pdf && row.pdf.id) && isGetDataSetDisplayed">
                            <a href="javascript:void(0)"
                               id="fetch-documentFields-{{matter?.id}}-{{row.pdf && row.pdf.id ? row.pdf.id : row.word.id}}"
                               (click)="retrieveDocumentDataSet($event, row)">Get DataSet</a>
                        </div>

                    </td>
                    </ng-container>
                </tr>

                <tr *ngIf="rows.length === 0" class="loading-row">
                    <td colspan="7" class="text-center">
                        <div class="no-match-found">No Documents Found.</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="bottom-tb" *ngIf="!isMatterDocument() && !usedInModal" [ngClass]="{'bottom-tb-supplemental-task': matterSupplementalTaskCategoryId}">
            <div class="col-lg-12">
                <div class="col-lg-4">
                <dp-checkbox fieldId="keepChecks">
                    <input type="checkbox" id="keepChecks"
                           [(ngModel)]="keepChecks"
                           (change)="updateKeepChecks()"
                           [disabled]="isMatterDisabled()"/>
                </dp-checkbox>
                Display checkmark after producing document
                </div>
                <div *ngIf="!matterSupplementalTaskCategoryId && hasTemplateAndCategoryManagementPermission() && !matter.isMatterTyeDischargeAndProvinceDisabled" class="col-lg-2 ">
                    <a (click)="manageTemplates()">Manage Templates</a>
                </div>
                <div *ngIf="!matterSupplementalTaskCategoryId && hasTemplateAndCategoryManagementPermission()  && !matter.isMatterTyeDischargeAndProvinceDisabled" class="col-lg-2">
                    <a (click)="manageCategories()">Manage Categories</a>
                </div>
                <div *ngIf="!matterSupplementalTaskCategoryId && !matter.isMatterTyeDischargeAndProvinceDisabled" class="col-lg-2">
                    <a (click)="zendeskRequest('LetterheadRequest')">Letterhead Request</a>
                </div>
                <div *ngIf="!matterSupplementalTaskCategoryId && !matter.isMatterTyeDischargeAndProvinceDisabled" class="col-lg-2">
                    <a (click)="zendeskRequest('MasterTemplateRequest')">Master Template Request</a>
                </div>
            </div>
            <div class="col-lg-12 margin-top-10 margin-left-15">
                <p class="relative-position top-5" *ngIf="matter?.isMortgage && matter?.newMortgages && matter?.newMortgages.length>1">
                    <span class="boldfont">DISCLAIMER: </span> <span> This matter contains more than one new mortgage being arranged.  Although Unity
                    supports this type of transaction, documents generated through Document Production may not contain the appropriate wording.  You should
                    thoroughly proofread documents which you produce and make the necessary revisions in your word processor before printing or otherwise releasing these documents.</span></p>
            </div>


        </div>
    </div>

</div>
