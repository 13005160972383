import {Component, Inject} from '@angular/core';
import {MatterParticipant} from '../../shared/matter-participant';
import {PurchaserCapacity} from '../capacity/purchaser-capacity';
import {DetailedDatePipe} from '../../shared/detailed-date.pipe';
import {dropDowns} from '../../shared/matter-drop-downs';
import {FamilyLawAct, FlaData} from '../../shared/fla-data';
import moment from 'moment';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class PurchasersTitleModalComponentContext {
  purchasers: MatterParticipant[];
  flaStatements: FlaData;
}

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-purchasers-title',
  templateUrl: 'purchasers-title.component.html',
  styleUrls: ['./purchasers-title.component.scss'],
  providers: [DetailedDatePipe]
})
export class PurchasersTitleModalComponent extends ModalComponent<PurchasersTitleModalComponentContext> {
  purchasers: MatterParticipant[];
  flaStatements: FlaData;
  header: string;

  constructor(
    public dialog: MatDialogRef<PurchasersTitleModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: PurchasersTitleModalComponentContext
  ) {
    super(dialog, context);
    this.purchasers = this.context.purchasers;
    this.flaStatements = this.context.flaStatements;
    this.header = 'Title Details';
   
  }

  getPurchaserName(name: string): string {
    if (name.indexOf(',') > -1) {
      let nameParts = name.split(',');
      name = nameParts[0].toUpperCase() + ',' + nameParts[1];
    } else {
      name = name.toUpperCase();
    }
    return name;
  }

  close(): void {
    this.dialog.close();
  }

  getPurchaserCapacity(purchaser: MatterParticipant): string {
    let capacityOption = PurchaserCapacity.purchaserCapacityOptions.find(
      (option) => option.value == purchaser.purchaserCapacity
    );
    return capacityOption ? capacityOption.label : '';
  }

  getPurchaserGenderLabel(purchaser: MatterParticipant): string {
    let genderOption = dropDowns.addClientGenderType.find((option) => option.value == purchaser.contact.gender);
    return genderOption ? genderOption.label : purchaser.contact.gender;
  }

  public getFlaStatementDescription(fla: FamilyLawAct): string {
    let description: string = '';

    let statementType = fla.familyLawActStatementType;

    if (this.flaStatements) {
      let flaStatementByTypeCode = this.flaStatements.getFlaStatementByTypeCode(statementType);
      if (flaStatementByTypeCode) {
        description = flaStatementByTypeCode.flaTypeDescription;
      }
    }

    if (statementType == 'OTHER') {
      description = fla.otherText;
    }

    if (statementType == 'MATTER_PARTICIPANT_SPOUSE' || statementType == 'CONSENTED_SPOUSE') {
      if (fla.consentedSpouseParticipant && fla.consentedSpouseParticipant.contact) {
        description = description.replace('<SPOUSE NAME>', `${fla.consentedSpouseParticipant.contact.firstLastNames}`);
      } else if (fla.spouseMatterParticipantId) {
        description = description.replace('<SPOUSE NAME>', `${this.getSpouseName(fla.spouseMatterParticipantId)}`);
      }
    } else if (statementType == 'AUTHORIZED_BY_COURT' && fla.instrumentNo) {
      description = description.replace('<INSTRUMENT No.>', fla.instrumentNo);
    }

    return description;
  }

  /**
   * Date formatter:
   * TODO: this duplicates detailed date pipe functionality, it should be moved into a common pipe once the modules are cleaned up
   * @param dateString
   */
  public formatDate(value: string): string {
    if (!value || value === '' || value === '//') {
      return '';
    }

    let segments: string[] = value.trim().split('/');

    if (segments[0] === '' || segments[1] === '' || segments[2] === '') {
      return '';
    }

    let month: string = segments[1];
    if (segments[1] !== '') {
      month = moment()
        .month(+segments[1] - 1)
        .format('MMM');
    }

    return `${month} ${segments[2]}, ${segments[0]}`;
  }

  beforeDismiss(): boolean {
    return false;
  }

  //Get Spouse name by matterParticipantId
  getSpouseName(matterParticipantId: number): string {
    let ret: string = null;
    if (Array.isArray(this.purchasers)) {
      const purchaser: MatterParticipant = this.purchasers.find((mp: MatterParticipant) => {
        return mp.matterParticipantId === matterParticipantId;
      });
      ret = purchaser && purchaser.contact ? purchaser.contact.fullNameStartWithFirstName : null;
    }
    return ret;
  }
}
