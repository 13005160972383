<dp-accordion class="col-lg-12" [expanded]="openForm" [primary]="primaryShutter" [offset]="offsetShutter" [offsetOther]="offsetShutterOther"
              [primaryOther]="primaryShutterOther" [secondary]="secondaryShutter" (shutterClicked)="emitShutterClicked($event)">
     <div class="dp-accordion-header">
            <div class="row row-group">
                <label class="control-label col-lg-12 text-left padding-left-50" [ngClass]="{ 'margin-left-10' : secondaryShutter }">
                    Other Contact Information ({{getCount()}}/4)
                </label>
            </div>
    </div>
    <div class="dp-accordion-content">
        <form class="form-horizontal" #otherContactInfoFieldsForm="ngForm">
            <div *ngFor="let field of otherContactInfoFields let i=index">
                <div class="form-group margin-bottom-10">
                    <label class="col-lg-2 control-label" for="contactType"></label>
                    <div class="col-lg-2">
                        <select id="contactType" class="form-control" id="fieldName_{{i}}" name="fieldName_{{i}}" (change)="emitChange($event)"
                                [(ngModel)]="field.fieldName"
                                [disabled]="disabled">
                            <option *ngFor="let fn of otherContactOptions" [value]="fn.value">
                                {{fn.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-8">
                        <input type="text" trimmed id="value_{{i}}" name="value_{{i}}" class="form-control" (change)="emitChange($event)" maxlength="50" [(ngModel)]="field.value"
                               [disabled]="disabled"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
</dp-accordion>
