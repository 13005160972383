import {Component, Inject, ViewChild} from '@angular/core';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import Utils from '../../../../shared-main/utils';
import {StatementOfAdjustmentDisplayItemLine} from '../../model';
import {ModalResult} from '../../../../shared-main/enums';
import {SelectItem} from 'primeng/api';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Project} from '../../../../projects/shared/project';
import {SoAdjHCRAFee} from '../../model/so-adj-hcra-fee';
import {StatementAdjustmentDisplayHcraFeeBuilder} from '../../builders/statement-adjustment-display-hcra-fee-builder';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class HCRAFeeModalContext {
  closingDate: string;
  adjustment: SoAdjHCRAFee;
  salePriceAdjustment: SalePriceAdjustment;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  aTarionWarrantyWithConsiderationAlreadyExists: boolean;
  isOtherFixedAdjAvailable: boolean;
  aHCRAWithConsiderationAlreadyExists: boolean;
  project: Project;
  taxRateType: string;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-so-adj-hcra-fee-modal-content',
  templateUrl: './hcra-fee.modal.component.html',
  providers: [ErrorService]
})
export class HCRAFeeModalComponent extends ModalComponent<HCRAFeeModalContext> {
  localAdj: SoAdjHCRAFee;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  closingDateLabel: string = '';
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  creditTypeOptions: SelectItem[] = [];
  enrolmentPeriodOptions: SelectItem[] = [];
  noYesOptions: SelectItem[] = [];
  netSalePrice: number = 0;
  agreedSalePrice: number = 0;
  netSalePriceLessRebate: number = 0;
  availableRebate: string;
  totalAmount: number = 0;
  salePriceAdj: SalePriceAdjustment;
  newAdj: boolean = false;
  amountAdditionalConsiderationToVendorOptions: SelectItem[] = [];

  constructor(
    public dialog: MatDialogRef<HCRAFeeModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: HCRAFeeModalContext
  ) {
    super(dialog, context);
  }

  get isSalePriceNetOutHstFromHSTSalePriceSetToNo(): boolean {
    return this.context.salePriceAdjustment && this.context.salePriceAdjustment.netOutHstFromHSTSalePrice == 'NO';
  }

  get provinceHstRate(): number {
    if (this.context && this.context.statementAdjustmentDisplayUtil) {
      return this.context.statementAdjustmentDisplayUtil.provinceHstRate;
    }
    return 0;
  }

  get federalHstRate(): number {
    if (this.context && this.context.statementAdjustmentDisplayUtil) {
      return this.context.statementAdjustmentDisplayUtil.federalHstRate;
    }
    return 0;
  }

  ngOnInit() {
    this.availableRebate = this.context.taxRateType;
    this.netSalePrice = this.context.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate);

    this.agreedSalePrice = this.context.salePriceAdjustment.agreementSalePrice;
    this.netSalePriceLessRebate =
      this.context.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate) -
      this.context.salePriceAdjustment.totalRebate(this.provinceHstRate, this.federalHstRate);

    if (this.context.salePriceAdjustment.federalPortion !== this.context.salePriceAdjustment.provincialPortion) {
      if (this.context.salePriceAdjustment.federalPortion) {
        this.availableRebate = 'GST';
      } else {
        this.availableRebate = 'PST';
      }
    }

    this.PopulateDropDowns();

    if (this.context.adjustment) {
      this.localAdj = new SoAdjHCRAFee(this.context.adjustment);
    } else {
      this.localAdj = new SoAdjHCRAFee();
      this.localAdj.creditType = 'VENDOR';
      this.localAdj.enrolmentPeriodId = 1;
      this.localAdj.amountAdditionalConsiderationToVendor = 'NO';
      this.localAdj.taxType = this.context.taxRateType;
      this.localAdj.hstRate = this.context.statementAdjustmentDisplayUtil.hstRate;
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  public PopulateDropDowns() {
    this.creditTypeOptions = [
      {
        label: this.context.statementAdjustmentDisplayUtil.vendorLabel,
        value: 'VENDOR'
      },
      {
        label: this.context.statementAdjustmentDisplayUtil.purchaserLabel,
        value: 'PURCHASER'
      },
      {
        label: 'No Adjustment',
        value: 'NO_ADJUSTMENT'
      }
    ];

    this.enrolmentPeriodOptions = this.context.statementAdjustmentDisplayUtil.hcraFeeEnrolmentPeriods.map((twep) => {
      return {label: twep.displayName, value: twep.id};
    });

    this.amountAdditionalConsiderationToVendorOptions = [
      {label: 'No', value: 'NO'},
      {label: 'Yes (eligible for ' + this.context.taxRateType + ' rebate)', value: 'YES_ELIGIBLE_FOR_REBATE'},
      {label: 'Yes (not eligible for ' + this.context.taxRateType + ' rebate)', value: 'YES_NOT_ELIGIBLE_FOR_REBATE'}
    ];

    this.noYesOptions = [
      {
        label: 'N/y',
        value: 'N_y'
      },
      {
        label: 'Yes',
        value: 'YES'
      },
      {
        label: 'No',
        value: 'NO'
      }
    ];
  }

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0) {
      this.cachedDetails = this.context.statementAdjustmentDisplayUtil.getDisplayItems(this.localAdj, '', true);
      this.totalAmount = StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFeePlusTax(
        this.localAdj,
        this.context.statementAdjustmentDisplayUtil
      );
    }
    return this.cachedDetails;
  }

  ok() {
    this.removeLocalErrors();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.localAdj.enrolmentFee = StatementAdjustmentDisplayHcraFeeBuilder.calculatedEnrolmentFee(
        this.localAdj,
        this.context.statementAdjustmentDisplayUtil
      );
      this.dialog.close(this.localAdj);
    }
  }

  cancel(): void {
    this.removeLocalErrors();
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  checkForErrors() {
    if (
      this.context.salePriceAdjustment.netOutHstFromHSTSalePrice === 'NO' &&
      this.localAdj.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
    ) {
      this.modalErrorComponent.createDPSaveError(
        'matter.soadj.tarion.warranty.amount.additional.consideration.to.vendor.sale.price'
      );
    }
    if (
      !this.context.isOtherFixedAdjAvailable &&
      !this.context.aTarionWarrantyWithConsiderationAlreadyExists &&
      !this.context.aHCRAWithConsiderationAlreadyExists &&
      this.context.salePriceAdjustment.additionalConsiderationsInclHst > 0 &&
      this.localAdj.amountAdditionalConsiderationToVendor != 'NO'
    ) {
      this.modalErrorComponent.createDPSaveError(
        'matter.soadj.tarion.warranty.amount.additional.consideration.to.vendor.include.hst'
      );
    }
  }

  removeLocalErrors(): void {
    this.modalErrorComponent.removeDpSaveError(
      'matter.soadj.tarion.warranty.amount.additional.consideration.to.vendor.sale.price'
    );
    this.modalErrorComponent.removeDpSaveError(
      'matter.soadj.tarion.warranty.amount.additional.consideration.to.vendor.include.hst'
    );
  }
}
