<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" id="depositForm">
        <div class="border-radius">
            <div class="row modal-header margin-bottom-20">
                <div class="col-lg-10"><h1>{{adjustmentDescription}}  </h1></div>
                <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 text-center"
                              style="border-bottom: none; margin-bottom: 0px; padding-bottom: 20px">Adjustment as at {{ this.dateStatusLabel }}</span>
                    </div>
                </div>
                <div *dpShowByProvince="'matter.propertyTaxes.separateTaxAdjustmentByRollNumber'">
                    <div class="col-lg-12" style="text-align: center ; padding-bottom: 15px"  *ngIf="context.matterTaxesByRollNumber && context.matterTaxesByRollNumber.length > 1">
                        <dp-checkbox fieldId="checkBoxOption">
                            <input type="checkbox"
                                   id="checkBoxOption"
                                   name="checkBoxOption"
                                   [disabled]="context.disableCheckBoxFromSoa"
                                   [(ngModel)]="separateTaxAdjustmentByRollNumber"  (ngModelChange)="createPropertyTaxByRollNumber($event)"
                            />
                        </dp-checkbox>
                        <label class="control-label vertical-align-label display-inline" >Create a separate tax adjustment for each Roll Number</label>
                    </div>
                </div>
                <div *dpShowByProvince="'matter.propertyTaxes.autoInsertTaxAdjustmentInSOA'">
                    <div class="col-lg-12" style="text-align: center ; padding-bottom: 15px"  *ngIf="context.matterProvinceCode == 'NS' && !context.hideAutoInsertFromModal ">
                        <dp-checkbox fieldId="autoInsertTaxAdjustmentInSOA">
                            <input type="checkbox"
                                   id="autoInsertTaxAdjustmentInSOA"
                                   name="autoInsertTaxAdjustmentInSOA"
                                   [(ngModel)]="autoInsertTaxAdjustment"
                            />
                        </dp-checkbox>
                        <label class="control-label vertical-align-label display-inline" >Automatically insert tax adjustment in the Statement of Adjustments</label>
                    </div>
                </div>

                <div class="col-lg-12" *ngIf="separateTaxAdjustmentByRollNumber" style="padding-bottom: 10px" >
                    <label class="control-label vertical-align-label display-inline" >Select Roll Number to edit:</label>
                </div>

                <div class="col-lg-12" *ngIf="separateTaxAdjustmentByRollNumber && context.matterTaxesByRollNumber && context.matterTaxesByRollNumber.length > 1" style="padding-bottom: 20px">
                    <ul class="dppm-custom-tab">
                        <li *ngFor="let matterTaxByRollNumber of matterTaxesByRollNumber; let i=index" [ngClass]="{active: propertyTax.id === matterTaxByRollNumber.matterTax.id}" >
                            <a (click)="activePropertyTax(matterTaxByRollNumber)">{{i+1 + '. ' + matterTaxByRollNumber.rollNumber}}</a>
                        </li>
                    </ul>
                </div>

                <dp-property-taxes-modal-content *ngIf="context.matterTaxesByRollNumber && context.matterTaxesByRollNumber.length > 1 &&  separateTaxAdjustmentByRollNumber"
                                                 [propertyTax]="propertyTax"
                                                 [closingDate]="context.closingDate"
                                                 [estTaxIncrease]="context.estTaxIncrease"
                                                 [matter] ="context.currentMatter"
                                                 [statementAdjustmentHeading]="context.statementAdjustmentHeading"
                                                 [matterProvinceCode]="context.matterProvinceCode"
                                                 [statementAdjustmentKey]="context.statementAdjustmentKey">
                </dp-property-taxes-modal-content>

                <dp-property-taxes-modal-content *ngIf="!separateTaxAdjustmentByRollNumber"
                                                 [propertyTax]="propertyTax"
                                                 [closingDate]="context.closingDate"
                                                 [estTaxIncrease]="context.estTaxIncrease"
                                                 [matter] ="context.currentMatter"
                                                 [statementAdjustmentHeading]="context.statementAdjustmentHeading"
                                                 [matterProvinceCode]="context.matterProvinceCode"
                                                 [realtyTaxesPaidByVendor] = "context.realtyTaxesPaidByVendor"
                                                 [statementAdjustmentKey]="context.statementAdjustmentKey">
                </dp-property-taxes-modal-content>


            </div>
            <div class="row modal-footer">
                <div class="col-lg-10 buttons">
                    <div class="col-lg-12 text-center">
                        <button type="button" class="dp-btn keyboard-save-action" (click)="ok()" id="okBtn">
                            <span>OK</span>
                        </button>

                        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                            <span>Cancel</span>
                        </button>

                        <button type="button" class="secondary-button" *ngIf="!context.hideDeleteFromModal && allowDelete" (click)="delete()">
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
                <div class="col-lg-2" *ngIf="isPropertyTax() && !context.currentMatter.isMatterProvinceBCorNBorNS">
                    <dp-checkbox fieldId="checkBoxInfoOnly">
                        <input type="checkbox"
                               id="checkBoxInfoOnly"
                               name="checkBoxInfoOnly"
                               [(ngModel)]="infoOnly"
                               [checked]="infoOnly"/>
                    </dp-checkbox>

                    <label class="control-label vertical-align-label display-inline" >Info Only</label>
                </div>
            </div>

            <dp-modal-error #modalErrorComponent></dp-modal-error>
            <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
        </div>
    </form>
</div>
