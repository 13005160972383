import {BeneficiaryType, WillAssetAndDebts} from '../shared/will-asset-and-debts';
import {currentMatter, Matter, MatterParticipant, WillPartyRole} from '../shared';
import {BeneficiariesOrCoOwners, WillBeneficiaryWrapperType} from '../shared/beneficiaries-or-coowners';
import {MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';

export class WillAssetAndDebtsUtil {

  get matter(): Matter {
    return currentMatter.value;
  }

  makeMatterDirty(): void {
    if (this.matter) {
      this.matter.dirty = true;
    }
  }

  createValuesInMatter(specificLegacy: WillAssetAndDebts): void {
    if (!this.matter?.willMatter?.willAssetAndDebts?.length) {
      this.matter.willMatter.willAssetAndDebts = [ specificLegacy ];
    } else {
      this.matter.willMatter.willAssetAndDebts = [ specificLegacy, ...this.matter.willMatter.willAssetAndDebts ];
    }
  }

  hasTestatorSpouse(): boolean {
    return this.matter?.matterParticipants.findIndex(
      (mp) => MatterParticipantRoleTypes.TESTATOR_SPOUSE === mp.matterParticipantRole) > -1;
  }

  updateValuesInMatter(specificAsset: WillAssetAndDebts): void {
    let currentIndex: number = this.matter?.willMatter?.willAssetAndDebts?.findIndex((asset) => asset.identifier == specificAsset.identifier);
    if (currentIndex >= 0) {
      this.matter.willMatter.willAssetAndDebts[ currentIndex ] = specificAsset;
    }
  }

  removeWillAssetsAndDebts(id: number): void {
    const index = this.getSelectedAssetIndex(id);
    if (index > -1) {
      this.matter?.willMatter?.willAssetAndDebts?.splice(index, 1);
    }
  }

  getSelectedAssetIndex(id: number): number {
    return this.matter?.willMatter?.willAssetAndDebts?.findIndex((asset) => asset.identifier === id);
  }

  mapMatterParticipantsToBeneficiariesOrCoOwners(wrapperType: WillBeneficiaryWrapperType): BeneficiariesOrCoOwners[] {
    return this.matter?.matterParticipants
    ?.filter((mp) => [ 'WILL_BENEFICIARY', 'TESTATOR_SPOUSE' ].includes(mp.matterParticipantRole))
    ?.filter((mp) => mp.contact.isActive)
    .map((mp) => this.createBeneficiariesOrCoOwners(mp, wrapperType));
  }

  createBeneficiariesOrCoOwners(mp: MatterParticipant, wrapperType: WillBeneficiaryWrapperType): BeneficiariesOrCoOwners {
    const beneficiary = new BeneficiariesOrCoOwners();
    beneficiary.wrapperType = wrapperType;
    beneficiary.beneficiaryOrCoOwner = mp;
    return beneficiary;
  }

  formatToMoney(amount: number): string {
    return !amount ? '' : `$${ amount }`;
  }

  isBeneficiaryTypeIndividual(beneficiaryType: BeneficiaryType): boolean {
    return 'INDIVIDUAL' === beneficiaryType;
  }

  isBeneficiaryTypeCharity(beneficiaryType: BeneficiaryType): boolean {
    return 'CHARITY' === beneficiaryType;
  }

  isMatterParticipantOtherParty(mp: MatterParticipant): boolean {
    return mp.participantType === 'WILL'
      && (!mp.willPartyRoles || mp.willPartyRoles.length === 0)
      && mp.matterParticipantRole === MatterParticipantRoleTypes.WILL_BENEFICIARY;
  }

  doesTestatorHasChild(): boolean {
    return this.matter?.matterParticipants?.flatMap(mp => mp?.willPartyRoles).filter(wp => wp?.willPartyRoleType === 'CHILD')?.length > 0;
  }

  updatePartiesAndRolesParticipantFieldsAllParticipants() {
    this.matter?.matterParticipants
    .filter(mp => mp.matterParticipantRole === 'TESTATOR_SPOUSE' || mp.matterParticipantRole === 'WILL_BENEFICIARY')
    .forEach(mp => this.updatePartiesAndRolesParticipantFields(mp));
  }

  updatePartiesAndRolesParticipantFields(matterParticipant: MatterParticipant) {
    this.matter?.willMatter.willAssetAndDebts.forEach(mp => {
      mp?.beneficiariesOrCoOwners
      .filter(f => f.beneficiaryOrCoOwner.matterParticipantId === matterParticipant.matterParticipantId)
      .forEach(b => {
        b.beneficiaryOrCoOwner.contact = matterParticipant.contact;
        b.beneficiaryOrCoOwner.excludeFromTerm = matterParticipant.excludeFromTerm;
        b.beneficiaryOrCoOwner.rtoExclude = matterParticipant.rtoExclude;
        b.beneficiaryOrCoOwner.willPartyRoles = matterParticipant.willPartyRoles;
        b.beneficiaryOrCoOwner.willRelationship = matterParticipant.willRelationship;
      });
    });
  }

  areParticipantsUsedInAssets(participantIds: number[]): boolean {
    const beneficiaryIds: number[] = this.matter?.willMatter?.willAssetAndDebts?.flatMap(a => a?.beneficiariesOrCoOwners?.map(b => b?.beneficiaryOrCoOwner?.matterParticipantId));
    return participantIds?.some(x => beneficiaryIds?.includes(x));
  }
}
