import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../../core';
import {Injectable} from '@angular/core';
import {matterApi, matterResponseKey, Utils} from '../../shared';
import * as _ from 'lodash';
import {RegistryOffice} from '../../shared/jurisdiction';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';

@Injectable()
export class RegistryOfficeService {
  constructor(private http: HttpClient) {
  }

  getRegistryOffices(page: number = 1, limit: number = 50, sort: string = 'officeName|ASC', query?: string): Observable<any[]> {
    let url: string;
    if (query) {
      query = Utils.escapeSearchText(query);
      let filter = query && query.length > 0 ? 'officeName_EQ_' + query + '*' : '';
      url = `${ matterApi.registryOffice }?page=${ page }&per_page=${ limit }&sort=${ sort }&filter=${ filter }&filterIgnoreCase=true&filterType=ALL`;
    } else {

      url = `${ matterApi.registryOffice }?&page=${ page }&per_page=${ limit }&sort=${ sort }`;
    }

    return this.http.get(url).map(
      (response) => {
        let data = response[ matterResponseKey.registryOffices ];
        let result: RegistryOffice[] = [];

        data.forEach(item => {
          result.push(_.cloneDeep(new RegistryOffice(item)));
        });

        if (result.length === 0) {
          return this.noRecordFound(result, 'officeName');
        }

        return result;
      }
    );
  }

  getAllRegistryOffices(provinceCode?: ProvinceCode, sortParam?: string): Observable<RegistryOffice[]> {
    let url: string;
    let sort: string = (sortParam && sortParam.length > 0) ? sortParam : 'officeName|ASC';

    url = `${ matterApi.registryOffice }?&sort=${ sort }`;
    if (provinceCode) {
      url = url + '&filter=provinceCode_EQ_' + provinceCode;
    }

    return this.http.get(url).map(
      (response) => {
        let data = response[ matterResponseKey.registryOffices ];
        let result: RegistryOffice[] = [];

        data.forEach(item => {
          result.push(new RegistryOffice(item));
        });

        if (result.length === 0) {
          return this.noRecordFound(result, 'officeName');
        }

        return result;
      }
    );
  }

  getRegistryOffice(id: number): Observable<RegistryOffice> {
    const url: string = `${ matterApi.registryOffice }/${ id }`;

    return this.http.get(url).map(
      (response) => {
        const data = response[ matterResponseKey.registryOffice ];
        return new RegistryOffice(data);
      }
    );
  }

  noRecordFound(data, filterKey) {
    let registryOffice: any = {};

    registryOffice[ filterKey ] = 'No records available';
    data.push(registryOffice);

    return data;
  }

  getTelusPayoutRegistryOffices(provinceCode: ProvinceCode): Observable<string[]> {
    let url = matterApi.telusPayoutRegistryOffice.replace('{provinceCode}', provinceCode);

    return this.http.get(url).map(
      (response) => {
        return response[ matterResponseKey.telusPayoutRegistryOffices ];
      }
    );
  }
}
