<div class="fields-group col-lg-12 margin-top-0">

    <div class="main-level">
        <span class="title">Welcome to Unity Wills.AI</span>
    </div>

    <div class="wills-ai-paragraph">
        <p>
            Unity Wills.AI is a cutting-edge tool which leverages artificial intelligence to streamline the drafting process, allowing you to provide personalized legal documents and
            solutions to your clients. We do not review the documents produced by AI or our other technology for legal sufficiency, nor do we provide legal, financial or tax advice,
            strategies, recommendations or opinions. By clicking “Enable AI” and using Unity Wills.AI, you acknowledge that certain issues may be too complex, unique or particular to
            be addressed by this service, and you assume all risks associated with your use of the templates generated by our service.
        </p>
    </div>

    <div class = "wills-ai-paragraph">
        <button type="button" (click)= "enableWillsAI()" [disabled]="false" class ="wills-ai-btn">
            <span>Enable AI</span>
        </button>
    </div>

</div>

