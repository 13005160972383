import {SalePriceAdjustmentBase} from './sale-price-adjustment-base';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {NovaScotiaSalePriceAdjustmentConstants, SalePriceAdjustmentThresholdItem} from './sale-price-adjustment-constants';
import Utils from '../../../shared-main/utils';

export class SalePriceAdjustmentNovaScotiaStrategy extends SalePriceAdjustmentBase {

  readonly divideSalePriceFactor: number = 1.15;

  provinceCode: ProvinceCode = 'NS';

  showRebateProvincialPortion(): boolean {
    return false; // no provincial rebate
  }

  getDivideSalePriceFactor(rate: number) {
    return Number(1 + rate / 100);
  }

  calculateDerivedNetSalePrice(excludeAdditionalConsiderationsInclHst: boolean = false): number {
    const total = this.total(excludeAdditionalConsiderationsInclHst);
    const multiplier = this.getMultiplier(this.getThreshold(total));
    const hasFederalRebate = this.federalPortion
      && multiplier == NovaScotiaSalePriceAdjustmentConstants.DIVIDE_THRESH0LD_MULTIPLIER;

    let federalRebateAdjustment = 0;
    if (hasFederalRebate) {
      federalRebateAdjustment = (Number(NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_MULTIPLIER) * Number(NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD));
    }

    const totalPrice: number = Number(total) + Number(federalRebateAdjustment);
    return Number((this.isDivideSalePrice() ? total : totalPrice) / multiplier);
  }

  calculateRebate(hstPortion: number): number {
    if (this.isDivideSalePrice() || !this.isInclusivePrice()) {
      return 0;
    } else {
      if (this.federalPortion) {
        let derivedNetSalePrice = this.calculateDerivedNetSalePrice();
        if (derivedNetSalePrice <= NovaScotiaSalePriceAdjustmentConstants.CONSIDERATION_THRESHOLD) {
          return Math.min(NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD, derivedNetSalePrice * (hstPortion / 100) * NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION);
        } else {
          if (derivedNetSalePrice < NovaScotiaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD) {
            return Math.min(NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD,
              NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD * (NovaScotiaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - derivedNetSalePrice) / 100000);
          } else {
            return 0;
          }
        }

      } else {
        return 0;
      }
    }
  }

  calculateFederalRebatePortion(federalTaxRate: number, netSalePrice: number): number {
    if (!this.netOutHstFromHSTSalePrice) {
      this.netOutHstFromHSTSalePrice = 'NO';
    }
    switch (this.netOutHstFromHSTSalePrice) {
      case 'NO' :
      case 'YES_DIVIDE_SALE_PRICE':
        if (netSalePrice > NovaScotiaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD) {
          return 0;
        }
        let rebateAmt: number = federalTaxRate && netSalePrice ? (federalTaxRate / 100) * NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION * netSalePrice : 0;
        let federalRebate = rebateAmt > NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD ? NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_THRESHOLD : rebateAmt;
        if (netSalePrice < NovaScotiaSalePriceAdjustmentConstants.DEMINISHING_FEDERAL_THRESHOLD) {
          return federalRebate;
        } else {
          return federalRebate * (NovaScotiaSalePriceAdjustmentConstants.REBATE_CRITICAL_THRESHOLD - netSalePrice) / 100000;
        }
      case 'YES_FACTOR_IN_HST_REBATE':
        // adopt the same value from same itemLine as in Sale Price Adjustment
        return this.calculateRebate(federalTaxRate);
      default:
        return 0;
    }
  }

  federalRebatePortion(): number {
    return NovaScotiaSalePriceAdjustmentConstants.FEDERAL_REBATE_PORTION;
  }

  calculateHstPortion(hstPortion: number, isTaxOut?: boolean, returnAsIs?: boolean): number {
    let result: number = 0;

    if (isTaxOut) {
      result = Number(this.agreementSalePrice) * (hstPortion / 100);

    } else if (this.isInclusivePrice()) {
      result = (hstPortion && hstPortion != 0.0) ? this.calculateDerivedNetSalePrice() * (hstPortion / 100) : 0;
    }

    if (returnAsIs) {
      return result; // rounding is not needed to avoid extra penny in certain cases
    } else {
      return Utils.roundCurrencyTCStyle(result); // rounding is needed to avoid extra penny in certain cases
    }

  }

  private getThreshold(calculatedTotal: number): SalePriceAdjustmentThresholdItem {
    const key = 'F_' + (this.isDivideSalePrice() ? 'D' : 'E');
    const thItems = NovaScotiaSalePriceAdjustmentConstants.TIER_1_THRESHOLD[ key ];
    return thItems.find(item => item.THRESHOLD_AMOUNT >= calculatedTotal);
  }

  private getMultiplier(tier1Threshold: SalePriceAdjustmentThresholdItem): number {

    let multiplier = NovaScotiaSalePriceAdjustmentConstants.DIVIDE_NET_OUT_MULTIPLIER;
    if (tier1Threshold !== undefined && tier1Threshold !== null && tier1Threshold.MULTIPLIER > 0) {
      multiplier = tier1Threshold.MULTIPLIER;
    }
    return multiplier;
  }

  private calculateNetSalePrice(hstPortion: number): number {
    return this.calculateDerivedNetSalePrice() - this.calculateFederalRebatePortion(hstPortion, this.agreementSalePrice);
  }

  totalNetSalePrice(federalHstPortion: number, provinceHstPortion: number, excludeAdditionalVendorConsid: boolean = false): number {
    let result: number = this.total();
    let currValue: number = Utils.roundCurrencyTCStyle((this.calculateHstPortion(federalHstPortion) + (this.calculateHstPortion(provinceHstPortion))));

    result = Utils.roundCurrency(result - currValue);
    currValue = Number(this.calculateRebate(federalHstPortion));

    result = Utils.roundCurrency(result + currValue);
    if (!excludeAdditionalVendorConsid) {
      currValue = Number(this.additionalVendorConsidNotEligibleForTaxRebate);
      result = Utils.roundCurrency(result + currValue);
      currValue = Number(this.additionalVendorConsidNotEligibleForTaxRebatePlusTax);
      result = Utils.roundCurrency(result + currValue);
    }
    return result;
  }

}
