import {MatterTitleInsurance} from '../../matters/shared/matter-title-insurance';
import {ChicagoBaseResponse} from './chicago-base-response';
import {ChicagoDocument} from './chicago-document';

export class ChicagoGetDocumentResponseObject extends ChicagoBaseResponse {
  matterTitleInsurance: MatterTitleInsurance;
  documents: ChicagoDocument[] = [];

  constructor(c?: ChicagoGetDocumentResponseObject) {
    super(c);

    if (c.matterTitleInsurance) {
      this.matterTitleInsurance = new MatterTitleInsurance(c.matterTitleInsurance);
    }

    if (c && c.documents) {
      for (let d of c.documents) {
        this.documents.push(new ChicagoDocument(d));
      }
    }
  }
}
