<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10" style="z-index: 5">
                <h1>Manage Extract Template Folder</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="margin-top-0">
                <div class="fixed-header">
                    <div class="col-lg-6 padding-top-10 boldfont padding-left-20"></div>

                    <div class="col-lg-6 text-right">
                        <button type="button" class="dp-btn dp-btn-cancel focus-first" (click)="fileUploadElRef.click()">
                            <span>Upload</span>
                        </button>
                        <input class="hidden"
                               #fileUploadElRef
                               type="file"
                               multiple="true"
                               [accept]="acceptedFileExtensions"
                               (change)="initiateUpload()"
                        />
                    </div>
                </div>

                <div>
                    <table class="standard-table margin-left-10 margin-top-80 width-auto"  tabindex="-1" [mfData]="rows" #mf="mfDataTable" >
                        <thead class="sticky-table-header">
                        <tr>
                            <th class="col1 wrap-header" (click)="sortFileName.sort();" id="sortFileName">
                                <span style="width: 100%;">Name
                                <dp-default-sorter #sortFileName by="documentTemplateFile.fileName" defaultSort="counter" [singleState]="true"></dp-default-sorter>
                                </span>
                            </th>
                            <th class="col2"></th>
                            <th class="col3" >
                                <div class="wrap-header">Description</div>
                            </th>
                            <th class="col4">Date</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngFor="let row of mf.data; let i = index;" [ngClass]="{'focus-first': i==0}"
                            (keydown)="tableKeyCommands(i,$event)"
                            tabindex="0">

                            <td class="col1">
                            <span title="{{row.reportTemplateFile.templateName}}" >
                                <div class="wrap-template-name">
                                    {{row.reportTemplateFile.templateName}}
                                </div>

                            </span>
                            </td>
                            <td class="col2">
                                <dp-burger-menu *ngIf="row"
                                                [dpItems]="row.burgerMenuItems"
                                                (itemClicked)="clickBurgerMenu(row.reportTemplateFile, $event)"
                                ></dp-burger-menu>
                            </td>
                            <td class="col3 text-overflow">
                            <span title="{{row.reportTemplateFile.description}}" >
                                {{row.reportTemplateFile.description}}
                            </span>
                            </td>
                            <td class="col4">{{row.reportTemplateFile.lastUpdatedTimeStamp | dpDateFormatPipe : ['noDay']}}</td>
                        </tr>

                        <tr *ngIf="rows.length === 0" class="loading-row">
                            <td colspan="6" class="text-center col-not-found">
                                <div class="no-match-found">No Templates Found.</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>OK</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
