import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ChicagoGetDocumentResponseObject} from './chicago-get-document-response-object';

export class ChicagoGetDocumentResponse extends BaseEntity {
  responseCode: number;
  responseDescription: string;
  responseObject: ChicagoGetDocumentResponseObject;

  constructor(c?: ChicagoGetDocumentResponse) {
    super(c);

    if (c && c.responseObject) {
      this.responseObject = new ChicagoGetDocumentResponseObject(c.responseObject);
    }
  }

  get isSuccessful(): boolean {
    return this.responseCode == 0;
  }

}
