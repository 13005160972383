<div class="container-fluid custom-modal-container customer-billing-portal-dialog">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-10">
            <h1>Customer Billing Portal</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
    </div>
    <div *ngIf="customerBillingPortalUrl" class="row modal-body view-port">
        <iframe class="iframe-format" [src]="customerBillingPortalUrl" name="iframe"></iframe>
    </div>
</div>
