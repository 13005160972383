export const TelusEmpMessages = {

  'EMP28':
    `Assyst Real Estate<sup>&reg;</sup> credentials for the selected solicitor cannot be found.`,

  'LCEMP28':
    `Unity® Lender Centre credentials for the selected solicitor cannot be found.`,

  'EMP32':
    `This mortgage cannot be removed since electronic mortgage instructions with a lower priority are assigned to this matter.<br/>
    You must unassign or cancel the electronic mortgage instructions in order to remove this mortgage.`,

  'EMP39':
    `The missing data must be completed in order to submit your matter data to FCT.<br/><br/>
    Would you like to open the FCT portal with read-only access?`,

  'EMP38':
    `The missing data must be completed in order to submit your matter data to Assyst Real Estate<sup>&reg;</sup>.<br/><br/>
    Would you like to proceed with retrieving the latest lender data and open the Assyst Real Estate<sup>&reg;</sup> portal with read-only access?`,

  'LC1EMP38':
    `The missing data must be completed in order to submit your matter data to Unity® Lender Centre.`,

  'LC2EMP38':
    `The missing data must be completed in order to submit your matter data to Unity® Lender Centre.<br/><br/>
    Would you like to proceed with retrieving the latest lender data and open the Unity® Lender Centre portal with read-only access?`,

  'EMP4': `The Assyst Real Estate<sup>&reg;</sup> credentials provided are invalid. <br><br>Please check to make sure you entered your credentials correctly (2).`,

  'LCEMP4': `The Unity® Lender Centre credentials provided are invalid. <br><br>Please check to make sure you entered your credentials correctly (2).`,

  'EMP54':
    `Document is not available as it was not generated in the portal.`,

  'EMP45':
    'Documents are currently being downloaded from Assyst Real Estate<sup>&reg;</sup> and saved to Matter Documents.',

  'LCEMP45':
    'Documents are currently being downloaded from Unity® Lender Centre and saved to Matter Documents.',

  'EMP46':
    'Documents have been successfully downloaded and are now available in Matter Documents.',

  'EMP47':
    'Documents that were successfully downloaded and are now available in Matter Documents.<br><br>' +
    'Go to \'Actions\'...\'Download Documents\' to re-download the document(s) that failed. ',

  'EMP48':
    'Document download from Assyst Real Estate<sup>&reg;</sup> failed.<br><br>' +
    'Go to \'Actions\'...\'Download Documents\' to re-download the document(s) that failed. ',

  'LCEMP48':
    'Document download from Unity® Lender Centre failed.<br><br>' +
    'Go to \'Actions\'...\'Download Documents\' to re-download the document(s) that failed. ',

  'EMP55':
    'The selected province does not match the property province.',

  'EMP56':
    'The property province is not in your list of accessible provinces.',

  'EMP57':
    'Please search and select a matter to assign mortgage instructions to.',

  'EMP58':
    'Mortgage instructions cannot be assigned to a matter at this time due to a missing mortgage priority. If the issue persists, please contact Assyst Real Estate support.',

  'LCEMP58':
    'Mortgage instructions cannot be assigned to a matter at this time due to a missing mortgage priority. If the issue persists, please contact Unity® Lender Centre support.',

  'EMP62':
    'Are you sure you would like to delete these mortgage instructions? Please note mortgage instructions will be removed from Unity only.',

  'EMP109':
    'Your mortgage instructions were closed by the lender. Please contact TELUS or the lender for more details (109)',

  'EMP990':
    'Your mortgage instructions were canceled by TELUS. Please contact TELUS for more details (990)',

  'EMP999':
    'Your mortgage instructions were canceled by lender. Please contact the lender for more details (999)',

  'EMPDEFAULTCANCEL':
    'The mortgage instructions were cancelled and cannot be assigned to a matter .'

};

export const FctEmpMessages = {
  'EMPXXX': 'unsuccessful, ask Moses for better messaging',

  'EMP008': 'An error was encountered while processing your request. Please try again and if the problem persists, contact Dye & Durham Support',

  'EMP012': 'The FCT credentials for {staffProfileName} are invalid.<br><br>' +
    'Please re-validate credentials to communicate with  FCT ( {FCT_error_code}) .',

  'EMP028': 'FCT credentials for the selected solicitor cannot be found.',

  'EMP042': 'There are currently no documents to download from FCT.',

  'EMP044': 'Please visit the FCT portal to request cancellation of this set of mortgage instructions.',

  'EMP045': 'Documents are currently being downloaded from FCT and saved to Matter Documents.',

  'EMP046': 'Documents have been successfully downloaded and are now available in Matter Documents.',

  'EMP047': 'Documents that were successfully downloaded and are now available in Matter Documents.<br><br>' +
    'Go to \'Actions\'...\'Download Documents\' to re-download the document(s) that failed. ',

  'EMP048': 'Document download from FCT failed.<br><br>' +
    'Go to \'Actions\'...\'Download Documents\' to re-download the document(s) that failed. ',

  'EMP053': 'The mortgage instructions (Deal ID: {DEAL_ID} - {MORTGAGE_PRIORITY} mortgage - {LENDER_NAME}) were cancelled and have been removed from your matter.',

  'EMP061': 'The mortgage instructions changed from being completed to active. All previously downloaded documents for this set of mortgage instructions have been removed from the matter.',

  'EMP063': 'Lender amendments were received and subsequently reversed. No action required.<br><br>' +
    'Would you like to proceed and open the FCT portal?',

  'EMP074': 'There are pending lender changes that must be completed.<br><br>' +
    'Would you like to open the FCT portal with read-only access?',

  'EMP076': 'The selected province does not match the property province.',

  'EMP077': 'The property province is not in your list of accessible provinces.',

  'EMP078': 'Please search and select a matter to assign mortgage instructions to.',

  'EMP080': 'Please associate your FCT credentials to your Unity user profile.',

  'EMP081': 'The mortgage instructions (Deal ID: {DEAL_ID} - {MORTGAGE_PRIORITY} mortgage - {LENDER_NAME}) were cancelled and have been removed from Unity.'

};

