import {api} from '../common/api';

const baseUrl: string = `${ api }/accounts/{accountId}/unityBilling`;

export const unityBillingApi = {
  baseUrl: baseUrl,
  purchaseCredits: `${ baseUrl }/purchaseCredits`,
  accountSummaryUrl: `${ baseUrl }/accountSummaryUrl`,
  currentBalance: `${ baseUrl }/currentBalance`,
  setupPaymentUrl: `${ baseUrl }/setupPaymentUrl`,
  sessionToken: `${ baseUrl }/sessionToken`,
  transactionReport: `${ baseUrl }/transactionReport?`,
  productPricing: `${ baseUrl }/productPricing?`,
  exportTransactionReportToCSV: `${ baseUrl }/transactionReport/exportToCsv?`,
  customerBillingPortalUrl: `${ baseUrl }/customerBillingPortalUrl`

};
