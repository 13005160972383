import {ApplicableProvisionOptionsTypes, FamilyLawAct, MaritalStatusTypes} from './fla-data';
import {Contact} from './contact';
import {MatterParticipantSigningLocation} from './matter-participant-signingLocation';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MatterParticipantRole, MatterParticipantRoleTypes} from './matter-participant-role-types';
import {UUIDUtil} from '../../main/uuid-util';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {FlaStatementType} from '../../shared-main/constants';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {TitleDeed} from './title-deed';
import {Matter} from './matter';
import {DpBooleanValue} from './dp-boolean';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {ContactTypes} from '../../contact/contact-type-mapping';
import {WillPartyRole, WillRemainsType, WillResidueRole} from './matter-specific-type';
import {RelationshipOptions} from './matter-drop-downs';
import {Address} from '..';

export type WelcomePackageStatusType = 'NOT_SENT' | 'SENT_WITH_CIRF' | 'SENT_WITHOUT_CIRF';
export type WillRelationshipType = 'BROTHER' | 'BROTHER_IN_LAW' | 'FATHER' | 'FATHER_IN_LAW' | 'FRIEND'
  | 'GRANDSON' | 'HUSBAND' | 'SON' | 'SON_IN_LAW' | 'SPOUSE' | 'STEP_FATHER' | 'UNCLE' | 'AUNT' | 'DAUGHTER'
  | 'DAUGHTER_IN_LAW' | 'GRANDDAUGHTER' | 'MOTHER' | 'MOTHER_IN_LAW' | 'SISTER' | 'SISTER_IN_LAW' | 'STEP_MOTHER' | 'WIFE';

export const WillHumanReadableToTypeMapping: { [ humanReadableName: string ]: WillRelationshipType } = {
  'Brother': 'BROTHER',
  'Brother-in-law': 'BROTHER_IN_LAW',
  'Father': 'FATHER',
  'Father-in-law': 'FATHER_IN_LAW',
  'Friend': 'FRIEND',
  'Grandson': 'GRANDSON',
  'Husband': 'HUSBAND',
  'Son': 'SON',
  'Son-in-law': 'SON_IN_LAW',
  'Spouse': 'SPOUSE',
  'Step Father': 'STEP_FATHER',
  'Uncle': 'UNCLE',
  'Aunt': 'AUNT',
  'Daughter': 'DAUGHTER',
  'Daughter-in-law': 'DAUGHTER_IN_LAW',
  'Granddaughter': 'GRANDDAUGHTER',
  'Mother': 'MOTHER',
  'Mother-in-law': 'MOTHER_IN_LAW',
  'Sister': 'SISTER',
  'Sister-in-law': 'SISTER_IN_LAW',
  'Step Mother': 'STEP_MOTHER',
  'Wife': 'WIFE'
};

export type TypeToHumanReadableMapping = { [K in WillRelationshipType]: string };
export const TypeToHumanReadableMapping: TypeToHumanReadableMapping = {
  'BROTHER': 'Brother',
  'BROTHER_IN_LAW': 'Brother-in-law',
  'FATHER': 'Father',
  'FATHER_IN_LAW': 'Father-in-law',
  'FRIEND': 'Friend',
  'GRANDSON': 'Grandson',
  'HUSBAND': 'Husband',
  'SON': 'Son',
  'SON_IN_LAW': 'Son-in-law',
  'SPOUSE': 'Spouse',
  'STEP_FATHER': 'Step Father',
  'UNCLE': 'Uncle',
  'AUNT': 'Aunt',
  'DAUGHTER': 'Daughter',
  'DAUGHTER_IN_LAW': 'Daughter-in-law',
  'GRANDDAUGHTER': 'Granddaughter',
  'MOTHER': 'Mother',
  'MOTHER_IN_LAW': 'Mother-in-law',
  'SISTER': 'Sister',
  'SISTER_IN_LAW': 'Sister-in-law',
  'STEP_MOTHER': 'Step Mother',
  'WIFE': 'Wife'
};
export type PTTExemptionCode = 'NONE' | 'NO_EXEMPTION' | 'TRANS_ROT_03' | 'TRANS_ROT_04' | 'RLTD_IND_PRNC_RSDNC' | 'RECREATINAL_RSDNC' |
  'FAMILY_FARM' | 'SURVIVORSHIP' | 'EXEC_OF_ESTATE' | 'SUB_DIV_MULT_LOT' | 'TRUSTEE_BNKRPCY' | 'VNDR_INTRST_AFS' | 'FEE_AFS' |
  'SEPARATION_AGREEMENT' | 'CHNG_JOINT_TENANCY' | 'LEASE' | 'FAMILY_FARM_CORP' | 'ESCHEAT_FTC' | 'TRANSFER_TO_MNCP' |
  'LIFE_EST_INTEREST' | 'REGISTERED_CHARITIES' | 'HEALTH_AUTH_EDU_INST' | 'TRUSTEE_SETTLOR' | 'CHANGE_TRUSTEE' | 'CROWN_GRANT' |
  'MINISTRIAL_ORDER' | 'MORTGAGEE_FEE_OWNER' | 'CANCELLATION_AGS' | 'LEASE_CONCURRENT_FEE' | 'VETERANS_LAND_ACT' | 'SUBDIVSN_SINGLE_LOT' |
  'CONVEYANCE_ERROR' | 'AMALGAMATION' | 'RLTD_INDV_DECEASED_ESTATE' | 'PRINCIPAL_RESIDENCE_TRUST_41' |
  'PRINCIPAL_RESIDENCE_TRUST_42' | 'ENVIRONMENTAL_CHARGE' | 'STRATA_PLAN_LIQUIDATOR' | 'FINAL_TAX_AGREEMENT' |
  'STRATA_PLAN_AMENDMENT' | 'LIFE_ESTATE_MORTGAGE_REGST' | 'NEWLY_BUILT_HOME' | 'FTH' | 'ADMIN';
export type PTTExemption = { code: PTTExemptionCode, description: string };
export const PTT_EXEMPTION_LIST: PTTExemption[] = [
  {code: 'NONE', description: ''},
  {code: 'NO_EXEMPTION', description: 'None - No Exemption Claimed'},
  {code: 'TRANS_ROT_03', description: '03 - Transitional Rate of Tax'},
  {code: 'TRANS_ROT_04', description: '04 - Transitional Rate of Tax'},
  {code: 'RLTD_IND_PRNC_RSDNC', description: '05 - Related Individual - Principal Residence'},
  {code: 'RECREATINAL_RSDNC', description: '06 - Recreational Residence'},
  {code: 'FAMILY_FARM', description: '07 - Family Farm'},
  {code: 'SURVIVORSHIP', description: '08 - Survivorship'},
  {code: 'EXEC_OF_ESTATE', description: '09 - Executor/Executrix of Estate'},
  {code: 'SUB_DIV_MULT_LOT', description: '10 - Subdivision - Multi lot'},
  {code: 'TRUSTEE_BNKRPCY', description: '12 - Trustee/bankruptcy'},
  {code: 'VNDR_INTRST_AFS', description: '13 - Vendor\'s interest under agreement for sale'},
  {code: 'FEE_AFS', description: '14 - Fee Simple/Agreement for Sale'},
  {code: 'SEPARATION_AGREEMENT', description: '15 - Separation Agreement - Related'},
  {code: 'CHNG_JOINT_TENANCY', description: '16 - Changing a joint tenancy to a tenancy in common/vice versa'},
  {code: 'LEASE', description: '17 - Lease'},
  {code: 'FAMILY_FARM_CORP', description: '18 - Family Farm Corporation'},
  {code: 'ESCHEAT_FTC', description: '19 - Escheat or Forfeit to Crown'},
  {code: 'TRANSFER_TO_MNCP', description: '20 - Transfer to Municipality, Regional District, etc.'},
  {code: 'LIFE_EST_INTEREST', description: '21 - Life Estate Interest'},
  {code: 'REGISTERED_CHARITIES', description: '22 - Registered Charities'},
  {code: 'HEALTH_AUTH_EDU_INST', description: '23 - Health Authorities, Educational Institution, etc'},
  {code: 'TRUSTEE_SETTLOR', description: '25 - Trustee/Settlor'},
  {code: 'CHANGE_TRUSTEE', description: '26 - Change in Trustee'},
  {code: 'CROWN_GRANT', description: '27 - Crown Grant'},
  {code: 'MINISTRIAL_ORDER', description: '28 - Ministerial Order'},
  {code: 'MORTGAGEE_FEE_OWNER', description: '29 - Mortgagee/Fee Simple Owner'},
  {code: 'CANCELLATION_AGS', description: '30 - Cancellation of Agreement for sale'},
  {code: 'LEASE_CONCURRENT_FEE', description: '31 - Lease/Concurrent fee simple'},
  {code: 'VETERANS_LAND_ACT', description: '32 - Veterans Land Act'},
  {code: 'SUBDIVSN_SINGLE_LOT', description: '34 - Subdivision - single lot'},
  {code: 'CONVEYANCE_ERROR', description: '35 - Conveyance error'},
  {code: 'AMALGAMATION', description: '38 - Amalgamation'},
  {code: 'RLTD_INDV_DECEASED_ESTATE', description: '40 - Related Individual - Deceased Estate'},
  {code: 'PRINCIPAL_RESIDENCE_TRUST_41', description: '41 - Principal Residence - Trust'},
  {code: 'PRINCIPAL_RESIDENCE_TRUST_42', description: '42 - Principal Residence - Trust'},
  {code: 'ENVIRONMENTAL_CHARGE', description: '43 - Environmental Charge'},
  {code: 'STRATA_PLAN_LIQUIDATOR', description: '45 - Strata Plan - Liquidator'},
  {code: 'FINAL_TAX_AGREEMENT', description: '46 - Final/Tax Agreement, Treaty First Nation'},
  {code: 'STRATA_PLAN_AMENDMENT', description: '47 - Strata Plan - Amendment'},
  {code: 'LIFE_ESTATE_MORTGAGE_REGST', description: '48 - Life Estate Where Mortgage is Registered'},
  {code: 'NEWLY_BUILT_HOME', description: '49 - Newly Built Home'},
  {code: 'FTH', description: 'FTH - First Time Home Buyers\' Program'},
  {code: 'ADMIN', description: 'ADMIN - Review Required: 1-888-355-2700'}
];

export class MatterParticipant extends BaseEntity {

  constructor(mp?: MatterParticipant) {
    super(mp);
    if (mp) {
      for (let prop in mp) {
        if (mp.hasOwnProperty(prop)
          && prop != 'consentedSpouseBackup' //consentedSpouseBackup is a map created for frontend logic, so it should not be copied while cloning
          // because javascript copies the map as an object and then we cannot call set/get functions on it.
        ) {
          this[ prop ] = mp[ prop ];
        }
      }
      this.contact = new Contact(mp.contact);
      // if(mp.borrower)
      // {
      //     this.borrower = new Borrower(mp.borrower);
      // }
      this.titleDeed = new TitleDeed(mp.titleDeed);
    }

    if (!this.matterParticipantId) {
      this.matterParticipantId = UUIDUtil.getUUID();

    }

    this.familyLawActs = [];
    if (mp && Array.isArray(mp.familyLawActs)) {
      mp.familyLawActs.forEach(flaItem => {
        this.familyLawActs.push(new FamilyLawAct(flaItem));
      });
    }

    this.signingLocations = [];
    if (mp && Array.isArray(mp.signingLocations)) {
      mp.signingLocations.forEach(signingLocation => {
        this.signingLocations.push(new MatterParticipantSigningLocation(signingLocation));
      });
    }

    //On initialization of mp adding the existing consented spouse to backup as it is used when user switches between FLA statements
    // if(this.getConsentedSpouseFamilyLawAct()) {
    //     this.consentedSpouseBackup.set(this.getConsentedSpouseFamilyLawAct().familyLawActStatementType, this.getConsentedSpouseFamilyLawAct().consentedSpouse);
    // }

    if (!this.titleDeed) {
      this.titleDeed = new TitleDeed();
    }
  }

  matterParticipantId: number;
  static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
  referenceStartDate: string;
  matterParticipantPriority: number;
  matterId: number;
  contactReferenceId: number;
  contact: Contact;
  primary: boolean;
  myClient: boolean = false;
  familyLawActs: FamilyLawAct[];
  matterParticipantRole: MatterParticipantRole;
  purchaserCapacity: string;
  purchaserShare: string;
  mortgageId: number;
  isAddedFromLenderData: boolean = false;
  overrideDefaultSigningLocationFlag: boolean;
  signingLocations: MatterParticipantSigningLocation[];
  // It is for save attention default value
  attentionName: string;
  previousPurchaserShareValue: string; //UI Only field to keep the user entered value for purchaserShare

  //This flag keeps the information of source contact locked at the participant level. As same user can open the same contact from different matters
  // or different participants with in the same matter but at a time it should be locked & editable only with one participant therefore keeping lock
  // status with participant. (Added it in matterparticipant instead of it's wrapper because then we can initialize right away when a participant is added)
  sourceContactLockAcquired: boolean;

  //Flag for indicating mortgagee link changed in participant snapshot. it is used by backend to propagate lender link changes to source contact
  updatedLenderLinkAutomatically: boolean;

  //TODO remove consentedSpouseBackup
  //This map keeps the backup of consented spouses. It's a map because multiple FLA statements can have their own consented spouses.
  // When an option is re-selected then it is used to show the previously added spouse information. Only maintained on frontend
  consentedSpouseBackup: Map<string, Contact> = new Map();

  titleDeed: TitleDeed;

  //borrower : Borrower;
  //reconciled: boolean = false;

  //source contact is aggregated in matter participant for getting it's proxy related data, so snapshot can be shown global/editAsPrivate accordingly.
  //It is only aggregated on frontend and it's not a fully aggregated entity but just have the fields related to proxy info. It is set while building the
  // participant wrapper structure or while adding new participant.
  private _sourceContact: Contact;

  parentParticipantId: number;

  signingMatter: boolean;

  associatedContactTitle: string;

  includeAuthorizeSignOfficer: DpBooleanValue;

  welcomePackagePrecedentId: number;
  welcomePackageStatus: WelcomePackageStatusType;

  addSignerToParentContact: boolean;

  //TODO: Create type
  participantType?: 'WILL' | 'MATTER';
  bodyOrganDonation?: 'NOT_SPECIFIED' | 'DONATE_ORGANS_FOR_TRANSPLANTATION' | 'DONATE_BODY_FOR_MEDICAL_EDUCATION_AND_BODY_RESEARCH';
  remainsClause?: string;
  otherConcerns?: string;
  visuallyImpaired?: boolean;
  wishesForTheirRemains?: 'BURIAL' | 'CREMATION' | 'COST_EFFECTIVE' | 'RELIGIOUS_TRADITIONS' | 'GREEN' | 'OTHER';
  willSubTypes?: WillRemainsType[];
  willPartyRoles?: WillPartyRole[];
  willRelationship?: WillRelationshipType;
  excludeFromTerm?: boolean;
  rtoExclude?: string;
  willResidueRoles?: WillResidueRole[];
  propertyPercentBeingAcquired: number;
  pttExemptionCode: PTTExemptionCode;
  pttAdditionalTaxPayable: boolean = false;
  pttBcPnExemption: boolean = false;
  pttBcPnCertificateNo: string;

  //returns the source contact for this matter participant.
  get sourceContact(): Contact {
    return this._sourceContact;
  }

  //Adding source contact object to matter participant.
  set sourceContact(c: Contact) {
    if (c) {
      this._sourceContact = new Contact(c);
      //Copying lender data from source contact to snapshot.
      if (this.isMortgagee) {
        this.copyLenderInfoInSnapshot(this._sourceContact);
      }
    } else {
      this._sourceContact = null;
    }
  }

  validationErrors(errors: string[], participantIndex: number): void {
    if (this.matterParticipantRole === 'OFFEROR') {
      this.validateOfferor(errors);
    }

    if (this.matterParticipantRole === 'GUARANTOR') {
      this.validateGuarantor(errors, participantIndex);
    }

  }

  get isGuarantor(): boolean {
    return this.matterParticipantRole === 'GUARANTOR';
  }

  get isPurchaser(): boolean {
    return this.matterParticipantRole === 'PURCHASER';
  }

  get isMortgagor(): boolean {
    return this.matterParticipantRole === 'MORTGAGOR';
  }

  get isMortgagee(): boolean {
    return this.matterParticipantRole === 'MORTGAGEE';
  }

  get isOfferor(): boolean {
    return this.matterParticipantRole === 'OFFEROR';
  }

  get isSigningOfficer(): boolean {
    return this.matterParticipantRole === 'SIGNING_OFFICER' || this.matterParticipantRole === 'ESTATE_TRUSTEE' || this.matterParticipantRole === 'POWER_OF_ATTORNEY';
  }

  get isLawClerk(): boolean {
    return this.matterParticipantRole === 'LAWCLERK';
  }

  get isRealEstateAgent(): boolean {
    return this.matterParticipantRole === 'REALESTATEAGENT';
  }

  get isRealEstateBroker(): boolean {
    return this.matterParticipantRole === 'REALESTATEBROKER';
  }

  get isOtherPartyRealEstateAgent(): boolean {
    return this.matterParticipantRole === 'OTHERPARTY_REALESTATEAGENT';
  }

  get isOtherPartyRealEstateBroker(): boolean {
    return this.matterParticipantRole === 'OTHERPARTY_REALESTATEBROKER';
  }

  get isPrivateLender(): boolean {
    return this.matterParticipantRole == 'PRIVATE_LENDER';
  }

  validateOfferor(errors: string[]): void {
    if (!this.offerorsLastNameValidity) {
      errors.push(`Offeror ${ this.matterParticipantPriority } Surname must be completed.`);
    }

    if (!this.offerorsOrgNameValidity) {
      errors.push(`Offeror ${ this.matterParticipantPriority } Name must be completed.`);
    }

    if (!this.offerorsOrgNameValidity) {
      errors.push(`Offeror ${ this.matterParticipantPriority } Name must be completed.`);
    }
  }

  validateGuarantor(errors: string[], participantIndex: number): void {
    if (!this.contact) {
      errors.push(`Contact is required for adding a guarantor participant`);
    } else {
      if (this.contact.isIndividual &&
        (!this.contact.contactName || !this.contact.contactName.lastName || this.contact.contactName.lastName.trim().length == 0)) {
        errors.push(`Guarantor last name should be populated.`);
      }

      if (this.contact.isCorporationOrOtherEntity &&
        (!this.contact.organizationName || this.contact.organizationName.trim().length == 0)) {
        errors.push(`Guarantor organization name should be populated.`);
      }
    }

  }

  cleanSigningLocationData() {
    if (this.overrideDefaultSigningLocationFlag !== true) {
      this.signingLocations = [];
    }
  }

  private inValidMatterParticipantSpouseNB(): boolean {
    return this.hasFla() && this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY && !this.familyLawActs[ 0 ].spouseMatterParticipantId;
  }

  private validateFlaAlertsForNB(alerts: string[]) {
    if (this.hasFla()) {
      if (!this.familyLawActs[ 0 ].maritalStatus && !this.familyLawActs[ 0 ].propertyOccupiedAsMaritalHome) {
        alerts.push(provinceBasedFieldLabels.get('participant.fla.indicator.label', 'NB') + ' is not selected.');
      }
      if (this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.RELEASED_BY_DEED) {
        alerts.push(provinceBasedFieldLabels.get('participant.fla.indicator.label', 'NB') + ' Instrument Number must be provided.');
      }

      if ((this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.DOMESTIC_CONTRACT
          || this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.RELEASED_BY_COURT_ORDER
          || this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.DISPOSITION_AUTHORIZED_BY_COURT_ORDER)
        && (!this.familyLawActs[ 0 ].courtOrderDate || this.familyLawActs[ 0 ].courtOrderDate === '//')) {

        alerts.push(provinceBasedFieldLabels.get('participant.fla.indicator.label', 'NB') +
          (this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.DOMESTIC_CONTRACT
            ? ' Date of domestic contract must be provided.' : ' Date of court order must be provided.'));
      }
    }
  }

  private validateConsentedSpouseLastNameAlertForNB(alerts: string[]) {
    if (this.isConsentedSpouseSelectedForNB()) {
      alerts.push(`${ this.contact.surnameLastFullName }'s consenting spouse's surname in ${ provinceBasedFieldLabels.get('participant.fla.indicator.label', 'NB') } section must be completed.`);
    }
  }

  getFlaAlerts(matter: Matter, isSingle: boolean, provinceCode?: string): string[] {
    const alerts: string[] = [];

    if (!isSingle) {
      if ((provinceCode == 'NB' && this.inValidMatterParticipantSpouseNB()) || (provinceCode != 'NB' && !this.validMatterParticipantSpouse)) {
        alerts.push(`Name of Spouse should be completed for ${ this.contact.surnameLastFullName }.`);
      }
    }

    if (provinceCode == 'NB') {
      this.validateFlaAlertsForNB(alerts);
    }

    if (!this.validConsentedSpouse(matter)) {
      if (provinceCode == 'NB') {
        this.validateConsentedSpouseLastNameAlertForNB(alerts);
      } else {
        alerts.push(`${ this.contact.surnameLastFullName }'s consenting spouse's surname in ${ provinceBasedFieldLabels.get('participant.fla.indicator.label', provinceCode) } section must be completed.`);
      }
    }

    if (!this.validMatterParticipantAuthorizedByCourt) {
      alerts.push(provinceBasedFieldLabels.get('participant.fla.indicator.label', provinceCode) + ' Instrument Number must be provided.');
    }

    if (isSingle) {
      if ((provinceCode == 'NB' && this.inValidMatterParticipantSpouseNB()) || (provinceCode != 'NB' && !this.validMatterParticipantSpouse)) {
        alerts.push(`Cannot be selected with only one Purchaser.`);
      }
    }

    if (!Array.isArray(this.familyLawActs) || this.familyLawActs.length === 0) {
      alerts.push(provinceBasedFieldLabels.get('participant.fla.indicator.label', provinceCode) + ' is not selected.');
    }

    // Todo: there should be a alert if there is no any ckecked item  in fla
    return alerts;
  }

  private get validMatterParticipantSingle(): boolean {
    return !this.matterParticipantSpouse;
  }

  private get validMatterParticipantAuthorizedByCourt(): boolean {
    return !this.authorizedByCourt || !!this.instrumentNo;
  }

  private get validMatterParticipantSpouse(): boolean {
    return !this.matterParticipantSpouse || !!this.spouseParticipantId;
  }

  private validConsentedSpouse(matter): boolean {
    return !this.getConsentedSpouseFamilyLawAct()
      || (this.consentingSpouseContact && !!this.consentingSpouseContact.lastName);
  }

  setFamilyLawActValueByStatementType(statementType: string, value) {
    this.toggleFamilyLawAct(statementType, value);
  }

  get notSpouse(): boolean {
    return this.getFamilyLawActStatus('NOT_SPOUSE');
  }

  set notSpouse(value: boolean) {
    this.toggleFamilyLawAct('NOT_SPOUSE', value);
  }

  get weAreNotSpouses(): boolean {
    return this.getFamilyLawActStatus(FlaStatementType.WE_ARE_NOT_SPOUSES);
  }

  set weAreNotSpouses(value: boolean) {
    this.toggleFamilyLawAct(FlaStatementType.WE_ARE_NOT_SPOUSES, value);
  }

  get notHomestead(): boolean {
    return this.getFamilyLawActStatus('NOT_HOMESTEAD');
  }

  set notHomestead(value: boolean) {
    this.toggleFamilyLawAct('NOT_HOMESTEAD', value);
  }

  get matterParticipantSpouse(): boolean {
    return this.getFamilyLawActStatus('MATTER_PARTICIPANT_SPOUSE');
  }

  set matterParticipantSpouse(value: boolean) {
    this.toggleFamilyLawAct('MATTER_PARTICIPANT_SPOUSE', value);
  }

  get maritalStatusNB(): boolean {
    return this.getFamilyLawActStatus(FlaStatementType.MARITAL_STATUS_NB);
  }

  get consentedSpouse(): boolean {
    return this.getFamilyLawActStatus('CONSENTED_SPOUSE');
  }

  set consentedSpouse(value: boolean) {
    this.toggleFamilyLawAct('CONSENTED_SPOUSE', value);
  }

  get notAsFamilyResidence(): boolean {
    return this.getFamilyLawActStatus('NOT_AS_FAMILY_RESIDENCE');
  }

  set notAsFamilyResidence(value: boolean) {
    this.toggleFamilyLawAct('NOT_AS_FAMILY_RESIDENCE', value);
  }

  get notMatrimonialHome(): boolean {
    return this.getFamilyLawActStatus('NOT_MATRIMONIAL_HOME');
  }

  get interspousalAgreement(): boolean {
    return this.getFamilyLawActStatus('INTERSPOUSAL_AGREEMENT');
  }

  set interspousalAgreement(value: boolean) {
    this.toggleFamilyLawAct('INTERSPOUSAL_AGREEMENT', value);
  }

  get spouseTransferee(): boolean {
    return this.getFamilyLawActStatus('SPOUSE_TRANSFEREE');
  }

  set spouseTransferee(value: boolean) {
    this.toggleFamilyLawAct('SPOUSE_TRANSFEREE', value);
  }

  set notMatrimonialHome(value: boolean) {
    this.toggleFamilyLawAct('NOT_MATRIMONIAL_HOME', value);
  }

  get separatedSpouseNotFamilyResidence(): boolean {
    return this.getFamilyLawActStatus('SEPARATED_SPOUSE_NOT_FAMILY_RESIDENCE');
  }

  set separatedSpouseNotFamilyResidence(value: boolean) {
    this.toggleFamilyLawAct('SEPARATED_SPOUSE_NOT_FAMILY_RESIDENCE', value);
  }

  get separatedSpouseReleasedRights(): boolean {
    return this.getFamilyLawActStatus('SEPARATED_SPOUSE_RELEASED_RIGHTS');
  }

  set separatedSpouseReleasedRights(value: boolean) {
    this.toggleFamilyLawAct('SEPARATED_SPOUSE_RELEASED_RIGHTS', value);
  }

  get authorizedByCourt(): boolean {
    return this.getFamilyLawActStatus('AUTHORIZED_BY_COURT');
  }

  set authorizedByCourt(value: boolean) {
    this.toggleFamilyLawAct('AUTHORIZED_BY_COURT', value);
  }

  get dispensingOrderWithConsent(): boolean {
    return this.getFamilyLawActStatus('DISPENSING_ORDER_WITH_CONSENT');
  }

  set dispensingOrderWithConsent(value: boolean) {
    this.toggleFamilyLawAct('DISPENSING_ORDER_WITH_CONSENT', value);
  }

  get spouseBySeparationAgreement(): boolean {
    return this.getFamilyLawActStatus(FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT);
  }

  set spouseBySeparationAgreement(value: boolean) {
    this.toggleFamilyLawAct(FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT, value);
  }

  get spouseByVirtueOfOrder(): boolean {
    return this.getFamilyLawActStatus(FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER);
  }

  set spouseByVirtueOfOrder(value: boolean) {
    this.toggleFamilyLawAct(FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER, value);
  }

  get designatedAnotherPropertyAsMatrimonialHome(): boolean {
    return this.getFamilyLawActStatus(FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME);
  }

  set designatedAnotherPropertyAsMatrimonialHome(value: boolean) {
    this.toggleFamilyLawAct(FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME, value);
  }

  get notOccupiedByAnyShareHolder(): boolean {
    return this.getFamilyLawActStatus(FlaStatementType.NOT_OCCUPIED_BY_ANY_SHAREHOLDER);
  }

  set notOccupiedByAnyShareHolder(value: boolean) {
    this.toggleFamilyLawAct(FlaStatementType.NOT_OCCUPIED_BY_ANY_SHAREHOLDER, value);
  }

  get other(): boolean {
    return this.getFamilyLawActStatus('OTHER');
  }

  set other(value: boolean) {
    this.toggleFamilyLawAct('OTHER', value);
  }

  get neitherResidedOnPropertySinceMarriage(): boolean {
    return this.getFamilyLawActStatus('NEITHER_RESIDED_ON_PROPERTY_SINCE_MARRIAGE');
  }

  set neitherResidedOnPropertySinceMarriage(value: boolean) {
    this.toggleFamilyLawAct('NEITHER_RESIDED_ON_PROPERTY_SINCE_MARRIAGE', value);
  }

  get consentedSpouseRegisteredInLTO(): boolean {
    return this.getFamilyLawActStatus('CONSENTED_SPOUSE_REGISTERED_IN_LTO');
  }

  set consentedSpouseRegisteredInLTO(value: boolean) {
    this.toggleFamilyLawAct('CONSENTED_SPOUSE_REGISTERED_IN_LTO', value);
  }

  get judgmentForDamagesBySpouse(): boolean {
    return this.getFamilyLawActStatus('JUDGMENT_FOR_DAMAGES_BY_SPOUSE');
  }

  set judgmentForDamagesBySpouse(value: boolean) {
    this.toggleFamilyLawAct('JUDGMENT_FOR_DAMAGES_BY_SPOUSE', value);
  }

  get gender(): string {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    return consentingSpouseContact ? consentingSpouseContact.gender : undefined;
  }

  set gender(gender: string) {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    if (consentingSpouseContact) {
      consentingSpouseContact.gender = gender;
    }
  }

  get surname(): string {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    return consentingSpouseContact ? consentingSpouseContact.lastName : undefined;
  }

  set surname(surname: string) {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    if (consentingSpouseContact) {
      consentingSpouseContact.lastName = surname;
    }
  }

  get firstName(): string {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    return consentingSpouseContact ? consentingSpouseContact.firstName : undefined;
  }

  set firstName(firstName: string) {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    if (consentingSpouseContact) {
      consentingSpouseContact.firstName = firstName;
    }
  }

  get middleName(): string {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    return consentingSpouseContact ? consentingSpouseContact.middleName : undefined;
  }

  set middleName(middleName: string) {
    const consentingSpouseContact: Contact = this.consentingSpouseContact;
    if (consentingSpouseContact) {
      consentingSpouseContact.middleName = middleName;
    }
  }

  get fullName(): string {
    return (this.firstName ? this.firstName + ' ' : '') +
      (this.middleName ? this.middleName + ' ' : '') +
      (this.surname ? this.surname : '');
  }

  get instrumentNo(): string {
    const fla: FamilyLawAct = this.flaStatementWithInstrumentNo;
    return fla ? fla.instrumentNo : undefined;
  }

  set instrumentNo(instrumentNo: string) {
    this.flaStatementWithInstrumentNo.instrumentNo = instrumentNo;
  }

  //Only one of these FLA statements can have instrument #
  get flaStatementWithInstrumentNo(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => value.familyLawActStatementType == 'AUTHORIZED_BY_COURT'
        || value.familyLawActStatementType == 'JUDGMENT_FOR_DAMAGES_BY_SPOUSE'
        || value.familyLawActStatementType == 'CONSENTED_SPOUSE_REGISTERED_IN_LTO' || value.familyLawActStatementType == 'INTERSPOUSAL_AGREEMENT'
      );
    }
  }

  get bookNumber(): string {
    const fla: FamilyLawAct = this.flaStatementWithBookNumber;
    return fla ? fla.bookNumber : undefined;
  }

  set bookNumber(bookNumber: string) {
    this.flaStatementWithBookNumber.bookNumber = bookNumber;
  }

  //Only one of these FLA statements can have instrument #
  get flaStatementWithBookNumber(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => value.familyLawActStatementType == FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME
        || value.familyLawActStatementType == FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER
      );
    }
  }

  get pageNumber(): string {
    const fla: FamilyLawAct = this.flaStatementWithPageNumber;
    return fla ? fla.pageNumber : undefined;
  }

  set pageNumber(pageNumber: string) {
    this.flaStatementWithPageNumber.pageNumber = pageNumber;
  }

  //Only one of these FLA statements can have instrument #
  get flaStatementWithPageNumber(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => value.familyLawActStatementType == FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME
        || value.familyLawActStatementType == FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER
      );
    }
  }

  get designationDate(): string {
    const fla: FamilyLawAct = this.flaStatementWithDesignationDate;
    return fla ? fla.designationDate : undefined;
  }

  set designationDate(designationDate: string) {
    this.flaStatementWithDesignationDate.designationDate = designationDate;
  }

  //Only one of these FLA statements can have registered on
  get flaStatementWithDesignationDate(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => value.familyLawActStatementType == FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME
        || value.familyLawActStatementType == FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT);
    }
  }

  get registeredOnDate(): string {
    const fla: FamilyLawAct = this.flaStatementWithRegisteredOnDate;
    return fla ? fla.registeredOnDate : undefined;
  }

  set registeredOnDate(registeredOnDate: string) {
    this.flaStatementWithRegisteredOnDate.registeredOnDate = registeredOnDate;
  }

  //Only one of these FLA statements can have registered on
  get flaStatementWithRegisteredOnDate(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => value.familyLawActStatementType == 'JUDGMENT_FOR_DAMAGES_BY_SPOUSE'
        || value.familyLawActStatementType == 'CONSENTED_SPOUSE_REGISTERED_IN_LTO' || value.familyLawActStatementType == 'INTERSPOUSAL_AGREEMENT' || value.familyLawActStatementType == 'AUTHORIZED_BY_COURT'
      );
    }
  }

  get otherText(): string {
    const fla: FamilyLawAct = this.getFamilyLawAct('OTHER');
    return fla ? fla.otherText : undefined;
  }

  set otherText(otherText: string) {
    this.setFamilyLawAct({familyLawActStatementType: 'OTHER', otherText: otherText});
  }

  get spouseParticipantId(): number {
    const fla: FamilyLawAct = this.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
    return fla ? fla.spouseMatterParticipantId : undefined;
  }

  set spouseParticipantId(id: number) {
    const fla: FamilyLawAct = this.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
    if (fla) {
      fla.spouseMatterParticipantId = id;
    }
  }

  // Setter function is not provided because setting spouse name triggers setting/deleting spouse of other partitipant(spouse),
  // and one setter function is not able to do all tasks.
  setSpouse(spouseParticipantId: number): void {
    let familyLawAct: FamilyLawAct = this.setFamilyLawAct({
      familyLawActStatementType: 'MATTER_PARTICIPANT_SPOUSE',
      spouseMatterParticipantId: spouseParticipantId
    });
    if (familyLawAct) {
      familyLawAct.relationshipCreatedInMatter = true;
    }
  }

  getSpouseParticipantIdNB(): number {
    const fla: FamilyLawAct = this.getFamilyLawAct('MARITAL_STATUS_NB');
    return fla && fla.isSpouseAParty() ? fla.spouseMatterParticipantId : undefined;
  }

  getSpouseParticipantId(provinceCode: string): number {
    if (provinceCode == 'NB') {
      return this.getSpouseParticipantIdNB();
    } else {
      return this.spouseParticipantId;
    }
  }

  setSpouseParticipantId(id: number, provinceCode: string): void {
    if (provinceCode == 'NB') {
      const fla: FamilyLawAct = this.getFamilyLawAct('MARITAL_STATUS_NB');
      if (fla) {
        fla.spouseMatterParticipantId = id;
      }
    } else {
      this.spouseParticipantId = id;
    }
  }

  removeSpousalLink(provinceCode): void {
    if (provinceCode == 'NB') {
      const fla: FamilyLawAct = this.getFamilyLawAct('MARITAL_STATUS_NB');
      if (fla) {
        fla.spouseMatterParticipantId = null;
      }
    } else {
      return this.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
    }
  }

  /**
   * This sets the passed in participant as this instance's spouse, and vice-versa (both ends of the spouse relationships are defined)
   * @param otherParticipant
   */
  setSpouseParticipant(spouseParticipant: MatterParticipant) {
    if (spouseParticipant) {
      this.setSpouse(spouseParticipant.matterParticipantId);
      spouseParticipant.setSpouse(this.matterParticipantId);
    } else {
      this.deleteSpouse();
    }
  }

  deleteSpouse(): void {
    this.deleteFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
  }

  getFamilyLawActStatus(statementType: string): boolean {
    return this.getFamilyLawActIndex(statementType) !== -1;
  }

  getSalutationFirsLastName(firstOrLastName?: string, includeSalutation?: boolean): string {
    let salutation: string = null;

    if (includeSalutation === true || !includeSalutation) {

      if (this.contact.gender === 'MALE' || this.contact.gender === 'MALEPOA') {
        salutation = 'Mr. ';
      }

      if (this.contact.gender === 'FEMALE' || this.contact.gender === 'FEMALEPOA') {
        if (this.matterParticipantSpouse) {
          salutation = 'Mrs. ';
        } else {
          salutation = 'Ms. ';
        }
      }
    }

    if (!firstOrLastName) {
      if (this.contact.firstName) {
        salutation += this.contact.firstName + ' ' + this.contact.lastName;
      } else {
        salutation += this.contact.lastName;
      }
    } else if (firstOrLastName === 'firstName') {
      if (this.contact.firstName) {
        salutation += this.contact.firstName;
      } else {
        salutation = '';
      }
    } else if (firstOrLastName === 'lastName') {
      salutation += this.contact.lastName;
    } else if (firstOrLastName === 'salutationOnly') {
      salutation = salutation;
    } else if (firstOrLastName === 'fullName') {
      if (this.contact.firstName) {
        salutation = this.contact.firstName + ' ' + this.contact.lastName;
      } else {
        salutation = this.contact.lastName;
      }
    }

    return salutation ? salutation.replace(null, '') : '';
  }

  private toggleFamilyLawAct(statementType: string, status: boolean): void {
    if (status) {
      this.addFamilyLawAct(statementType);
    } else {
      this.deleteFamilyLawAct(statementType);
    }
  }

  public addFamilyLawAct(statementType: string): void {
    this.setFamilyLawAct({familyLawActStatementType: statementType});
  }

  private setFamilyLawAct(fla: FamilyLawAct): FamilyLawAct {
    let currentFla: FamilyLawAct = this.getFamilyLawAct(fla.familyLawActStatementType);
    if (!currentFla) {
      currentFla = new FamilyLawAct(fla);
      this.familyLawActs.push(currentFla);
    } else {
      currentFla.merge(fla);
    }

    const dtNow: Date = new Date;
    currentFla.lastUpdatedTimeStamp = dtNow.toISOString();

    return currentFla;
  }

  public deleteFamilyLawAct(statementType: string): void {
    const idx: number = this.getFamilyLawActIndex(statementType);
    if (idx !== -1) {
      this.familyLawActs.splice(idx, 1);
    }
  }

  public getFamilyLawAct(statementType: string): FamilyLawAct {
    const idx: number = this.getFamilyLawActIndex(statementType);
    // return idx !== -1 ? this.familyLawActs[idx] : undefined;
    const familyLawAct: FamilyLawAct = idx !== -1 ? this.familyLawActs[ idx ] : undefined;
    return familyLawAct;
  }

  public getConsentedSpouseFamilyLawAct(): FamilyLawAct {
    return this.flaStatementWithConsentedSpouse;
  }

  public getMatterParticipantSpouseFamilyLawAct(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => value.familyLawActStatementType == 'MATTER_PARTICIPANT_SPOUSE' || value.familyLawActStatementType == 'MARITAL_STATUS_NB');
    }
  }

  get hasFlaStatementWithConsentedSpouse(): boolean {
    return this.consentedSpouse || this.consentedSpouseRegisteredInLTO || this.notMatrimonialHome || this.spouseBySeparationAgreement
      || this.spouseByVirtueOfOrder || this.designatedAnotherPropertyAsMatrimonialHome || this.maritalStatusNB;
  }

  //Only one of these FLA statements can have consented spouse so returning the one which has it
  get flaStatementWithConsentedSpouse(): FamilyLawAct {
    if (Array.isArray(this.familyLawActs)) {
      return this.familyLawActs.find(value => this.isConsentedSpouse(value));
    }
  }

  isConsentedSpouse(fla: FamilyLawAct): boolean {
    return fla && fla.isConsentedSpouse();
  }

  getConsentedSpouseConfirmMsg(familyLawActStatementType): string {
    return 'The spouse information will be removed. Do you still want to proceed?';
  }

  getConsentedSpouseTitle(familyLawActStatementType): string {
    let title: string;

    switch (familyLawActStatementType) {
      case 'CONSENTED_SPOUSE':
      case 'CONSENTED_SPOUSE_REGISTERED_IN_LTO':
        title = 'Consenting Spouse';
        break;
      case FlaStatementType.NOT_MATRIMONIAL_HOME:
      case FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_SEPARATION_AGREEMENT:
      case FlaStatementType.SPOUSE_NOT_MATRIMONIAL_INTEREST_BY_VIRTUE_OF_ORDER:
      case FlaStatementType.DESIGNATED_ANOTHER_PROPERTY_AS_MATRIMONIAL_HOME:
      case 'INTERSPOUSAL_AGREEMENT':
      case 'AUTHORIZED_BY_COURT':
        title = 'Name of Spouse';
        break;
      case 'MARITAL_STATUS_NB':
        let fla: FamilyLawAct = this.getConsentedSpouseFamilyLawAct();
        if (fla) {
          title = fla.getConsentingSpouseLabel();
        } else {
          title = 'Consenting Spouse';
        }
        break;
      default :
        title = 'Consenting Spouse';
        break;
    }

    return title;

  }

  private getFamilyLawActIndex(statementType: string): number {
    return Array.isArray(this.familyLawActs)
      ? this.familyLawActs.findIndex(fla => fla.familyLawActStatementType === statementType)
      : -1;
  }

  //For most provinces, the family law act for a linked spouse is MATTER_PARTICIPANT_SPOUSE. For NB it is MARITAL_STATUS_NB with applicableProvision SPOUSE_IS_A_PARTY.
  isLinkedSpouse(): boolean {
    return this.matterParticipantSpouse || (this.maritalStatusNB && this.hasFla() && this.familyLawActs[ 0 ].applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY);
  }

  // isConsentedSpouseNB(): boolean {
  //     return this.maritalStatusNB && this.hasFla() && this.familyLawActs[0].applicableProvision == ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING;
  // }

  incompleteFamilyLawAct(provinceCode: ProvinceCode, matter: Matter): boolean {
    return !this.isFlaStatementSelectedAndCompleted(provinceCode, matter);
  }

  noFlaStatementSelected(): boolean {
    return !Array.isArray(this.familyLawActs) || this.familyLawActs.length === 0;
  }

  isFlaStatementSelectedAndCompleted(provinceCode: ProvinceCode, matter: Matter): boolean {
    if (provinceCode == 'NB') {
      return this.isFlaStatementSelectedAndCompletedForNB(matter);
    } else {
      return this.isFlaStatementSelectedAndCompletedForNonNB(provinceCode, matter);
    }

  }

  isFlaStatementSelectedAndCompletedForNonNB(provinceCode: ProvinceCode, matter: Matter): boolean {
    if (this.noFlaStatementSelected()) {
      return false;
    } else {
      //try to find any incomplete Fla, even for the same type different province may need to complete different fields
      const incompleteFla: FamilyLawAct = this.familyLawActs.find(fla => {
        if (fla && fla.familyLawActStatementType) {
          //if fla statement missing the required data for specific type => return true;
          switch (fla.familyLawActStatementType) {
            case FlaStatementType.MATTER_PARTICIPANT_SPOUSE: //used in ON, SK, MB
              return !this.spouseParticipantId;
            case FlaStatementType.CONSENTED_SPOUSE: //used in ON, AB, SK, MB
              return !this.surname;
            case FlaStatementType.INTERSPOUSAL_AGREEMENT: //used only in SK
            case FlaStatementType.CONSENTED_SPOUSE_REGISTERED_IN_LTO: //used only in AB
              return !this.instrumentNo || (!this.registeredOnDate || this.registeredOnDate === '//') || !this.consentingSpouseContact || !this.consentingSpouseContact.lastName;
            case FlaStatementType.AUTHORIZED_BY_COURT: //used in ON, SK
              if (provinceCode && provinceCode == 'SK') {
                //SK has three fields under this type
                return !this.instrumentNo || (!this.registeredOnDate || this.registeredOnDate === '//') || !this.consentingSpouseContact || !this.consentingSpouseContact.lastName;
              }
              //ON only has one field under this type
              return !this.instrumentNo;
            case FlaStatementType.JUDGMENT_FOR_DAMAGES_BY_SPOUSE: //used only in AB
              return !this.instrumentNo || (!this.registeredOnDate || this.registeredOnDate === '//');
          }
        }
        return false;
      });
      return !incompleteFla;
    }
  }

  isFlaStatementSelectedAndCompletedForNB(matter: Matter): boolean {
    if (this.noFlaStatementSelected()) {
      return false;
    } else {
      //try to find any incomplete Fla, even for the same type different province may need to complete different fields
      const incompleteFla: FamilyLawAct = this.familyLawActs.find(fla => {
        if (fla) {

          //When both 'Marital Status' and 'Has property been occupied as marital home?',
          // are set to <blank> show warning and Flag (equivalent to no FLA statement selected in ON)
          if (!fla.maritalStatus && !fla.propertyOccupiedAsMaritalHome) {
            return true;
          }

          //if fla statement missing the required data for specific type => return true;
          switch (fla.applicableProvision) {
            case ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY: //check Name of Spouse
              return !fla.spouseMatterParticipantId;
            case ApplicableProvisionOptionsTypes.SPOUSE_IS_CONSENTING: //check consenting spouse surname
              return this.consentingSpouseContact && !this.consentingSpouseContact.lastName;
            case ApplicableProvisionOptionsTypes.RELEASED_BY_DEED:
              if (fla.maritalStatus == MaritalStatusTypes.MARRIED) {
                return !fla.instrumentNo || this.consentingSpouseContact && !this.consentingSpouseContact.lastName;
              } else {
                return !fla.instrumentNo;
              }
            case ApplicableProvisionOptionsTypes.DOMESTIC_CONTRACT:
            case ApplicableProvisionOptionsTypes.RELEASED_BY_COURT_ORDER:
            case ApplicableProvisionOptionsTypes.DISPOSITION_AUTHORIZED_BY_COURT_ORDER:
              if (fla.maritalStatus == MaritalStatusTypes.MARRIED) {
                return (!fla.courtOrderDate || fla.courtOrderDate === '//') || this.consentingSpouseContact && !this.consentingSpouseContact.lastName;
              } else {
                return (!fla.courtOrderDate || fla.courtOrderDate === '//');
              }
            default:
              if (fla.maritalStatus == MaritalStatusTypes.MARRIED && fla.applicableProvision != ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY) {
                return this.consentingSpouseContact && !this.consentingSpouseContact.lastName;
              }
              break;
          }
        }

        return false;
      });
      return !incompleteFla;
    }
  }

  private get offerorsOrgNameValidity(): boolean {

    return !(this.matterParticipantRole == 'OFFEROR' && this.contact.gender === 'OTHERENTITY' && (!this.contact.organizationName || this.contact.organizationName == '' ||
      (this.contact.organizationName &&
        this.contact.organizationName.toString().trim() == '')));
  }

  private get offerorsLastNameValidity(): boolean {

    return !(this.matterParticipantRole == 'OFFEROR' && this.contact.gender != 'OTHERENTITY' && (!this.contact.contactName.lastName || this.contact.contactName.lastName == '' ||
      (this.contact.contactName.lastName &&
        this.contact.contactName.lastName.toString().trim() == '')));

  }

  //This method enrols a contact into matter participant. it sets the corresponding fields of participant based on contact cloned or not.
  public enrolContactToParticipant(contactToBeEnrolled: Contact, withCloning: boolean): void {
    if (withCloning) {
      this.contact = new Contact();
      this.contact.createNewContactClone(contactToBeEnrolled);
      this.contact.snapshotFlag = true;
      if (!this.contact.canadianResidentFlag) { // another option is to set party_contact corespondent field as nullable
        this.contact.canadianResidentFlag = 'Y_n';
      }

      //Sub contacts should not be copied into snapshot as it will copy too many contacts. If needed in some particular case then that should be
      // handled separately
      this.contact.subContacts = [];
      //this.markSubContactSnapshot(this.contact);
      //trust accounts should not be copied into snapshot
      this.contact.trustAccounts = [];

      this.contact.sourceContactId = contactToBeEnrolled.id;

      //Copying original contact's parent organization id into the snapshot
      this.contact.sourceParentOrganizationId = contactToBeEnrolled.organizationId;

      //Keeping source contact reference with participant for showing snapshot as global or editAsPrivate
      this.sourceContact = contactToBeEnrolled;
      //Copying original contact's parent organization id into the snapshot
      this.contact.sourceParentOrganizationId = contactToBeEnrolled.organizationId;

      this.contact.lastSyncedFromSource = contactToBeEnrolled.lastUpdatedTimeStamp;
    } else {
      this.contact = contactToBeEnrolled;
      this.contact.snapshotFlag = true;
      this.contact.sourceContactId = null;
    }
  }

  //mark the subcontacts as snapshot flag true
  markSubContactSnapshot(sourceContact: Contact) {

    if (sourceContact) {

      if (Array.isArray(sourceContact.subContacts)) {
        for (let i: number = 0; i < sourceContact.subContacts.length; i++) {

          sourceContact.subContacts[ i ].snapshotFlag = true;

          this.markSubContactSnapshot(sourceContact.subContacts[ i ]);

        }
      }
    }
  }

  isMortgageeAttention(): boolean {
    return this.matterParticipantRole == MatterParticipantRoleTypes.MORTGAGEE_ATTENTION;
  }

  onShowAddSpouseButton(participantLength, maxNumberOfAppConfigParticipant, provinceCode?: ProvinceCode): boolean {
    if (provinceCode == 'NB') {
      return this.onShowAddSpouseButtonForNB(participantLength, maxNumberOfAppConfigParticipant);
    } else {
      return this.onShowAddSpouseButtonDefault(participantLength, maxNumberOfAppConfigParticipant);
    }
  }

  onShowAddSpouseButtonDefault(participantLength, maxNumberOfAppConfigParticipant): boolean {
    if ((participantLength > maxNumberOfAppConfigParticipant - 1)
      || (!!this.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE')
        || !!this.getConsentedSpouseFamilyLawAct()
        || (this.contact
          && (this.contact.gender === 'ESTATE'
            || this.contact.gender === 'CORPORATION'
            || this.contact.gender === 'OTHERENTITY')))) {
      return false;
    } else {
      return true;
    }
  }

  onShowAddSpouseButtonForNB(participantLength, maxNumberOfAppConfigParticipant): boolean {
    if ((participantLength > maxNumberOfAppConfigParticipant - 1)
      || this.isSpouseAPartySelectedForNB()
      || this.isConsentedSpouseSelectedForNB()
      || (this.contact
        && (this.contact.gender === 'ESTATE'
          || this.contact.gender === 'CORPORATION'
          || this.contact.gender === 'OTHERENTITY'))) {
      return false;
    } else {
      return true;
    }
  }

  setFlaDefaultValuesForSuggestedSpouse(spouseParticipant: MatterParticipant) {
    if (spouseParticipant) {
      spouseParticipant.setSpouse(this.matterParticipantId);
      this.setSpouse(spouseParticipant.matterParticipantId);
      if (this.getFamilyLawAct('MATTER_PARTICIPANT_SPOUSE')) {
        spouseParticipant.addFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
      }
    }
  }

  addSpouseForAB(newParticipant: MatterParticipant) {
    if (newParticipant) {
      this.addFamilyLawAct('MATTER_PARTICIPANT_SPOUSE');
      this.setFlaDefaultValuesForSuggestedSpouse(newParticipant);
    }
  }

  hasFla(): boolean {
    return this.familyLawActs && this.familyLawActs.length > 0;
  }

  isConsentedSpouseSelectedForNB(): boolean {
    return this.hasFla() && this.familyLawActs[ 0 ].isConsentedSpouseSelectedForNB();
  }

  isSpouseAPartySelectedForNB(): boolean {
    return this.hasFla() && this.familyLawActs[ 0 ].isSpouseAParty();
  }

  initFlaForNB() {
    if (!Array.isArray(this.familyLawActs)) {
      this.familyLawActs = [];
    }
    let familyLawAct: FamilyLawAct;
    if (this.familyLawActs.length == 0) {
      let familyLawAct: FamilyLawAct = new FamilyLawAct();
      familyLawAct.familyLawActStatementType = FlaStatementType.MARITAL_STATUS_NB;
      this.familyLawActs.push(familyLawAct);

    }
    familyLawAct = this.familyLawActs[ 0 ];
    //Set consentedSpouse default value
    // if(!familyLawAct.consentedSpouse){
    //     this.addConsentedSpouse(this.gender);
    // }
  }

  createConsentedSpouse(): Contact {
    let spouse = new Contact();
    spouse.loadConsentedSpouseSettings();
    return spouse;
  }

  getDefaultGender(): string {
    let defaultGender: string;
    switch (this.contact && this.contact.gender) {
      case 'MALE':
        defaultGender = 'FEMALE';
        break;
      case 'FEMALE':
        defaultGender = 'MALE';
        break;
      default:
        defaultGender = 'QUESTION';
        break;
    }
    return defaultGender;
  }

  //TODO remove it
  // addConsentedSpouse(spouseGender: string): void {
  //     if(!this.consentingSpouseContact) {
  //         let consentedSpouse = this.createConsentedSpouse();
  //
  //         //If backup already contains the consented spouse for selected FLA statement then copying the data from backup to new contact.
  //         //Cannot assign backup contact to new FLA statement as JPA will complaint if FLA is new but contact is existing. Therefore adding new contact
  //         // every time FLA statement is re-selected.
  //         if(this.consentedSpouseBackup.get(this.getConsentedSpouseFamilyLawAct().familyLawActStatementType)) {
  //             consentedSpouse.update(this.consentedSpouseBackup.get(this.getConsentedSpouseFamilyLawAct().familyLawActStatementType));
  //         }
  //
  //         this.consentingSpouseParticipant.contact = consentedSpouse;
  //         this.consentedSpouseBackup.set(this.getConsentedSpouseFamilyLawAct().familyLawActStatementType, consentedSpouse);
  //
  //     }
  //
  //     if (!spouseGender) {
  //         this.gender = this.getDefaultGender();
  //     }
  // }

  getFlaEstateOtherItemDefaultValue(): string {
    let defaultValue: string = '';

    if (this.contact && this.contact.contactName) {

      let deceasedGender = this.contact.deceasedGender === 'FEMALE' ? 'her' : 'his';

      defaultValue = 'The deceased '
        + this.contact.contactName.surnameLastFullName
        + ', was at least eighteen years old and, at the time of ' + deceasedGender + ' death,' +
        ' was/was not a spouse.';
    }
    return defaultValue;
  }

  isContactGenderEstate(): boolean {
    return !!(this.contact && this.contact.gender == 'ESTATE');
  }

  cleanUpFlaExceptOtherFla() {
    if (Array.isArray(this.familyLawActs)) {
      this.familyLawActs = this.familyLawActs.filter(item => item.familyLawActStatementType == FlaStatementType.OTHER);
    }
  }

  setGendeEstateOtherFlaDefaultValue() {
    this.addFamilyLawAct(FlaStatementType.OTHER);
    this.otherText = this.getFlaEstateOtherItemDefaultValue();
  }

  //Updating lender information from sourceContact into snapshot as that is only maintained at source contact. Doc gen will get latest data in snapshot
  // after saving matter once
  copyLenderInfoInSnapshot(sourceContact: Contact): void {
    this.contact.lenderInstitutionId = sourceContact.lenderInstitutionId;
    this.contact.lenderInstitutionName = sourceContact.lenderInstitutionName;
    this.contact.alternateName = sourceContact.alternateName;
    this.contact.institutionNo = sourceContact.institutionNo;
    this.contact.depositsAccepted = sourceContact.depositsAccepted;
    this.contact.mortgagesRegistered = sourceContact.mortgagesRegistered;
    this.contact.mortgageeLenderInstitutionLinkStatus = sourceContact.mortgageeLenderInstitutionLinkStatus;
    this.contact.lenderNames = sourceContact.lenderNames;
  }

  hasSnapshotWithoutSourceContact(): boolean {
    return this.contact && !this.contact.sourceContactId;
  }

  get signingOfficerParticipantRole(): MatterParticipantRole {
    if (this.contact && this.contact.isEstate) {
      return 'ESTATE_TRUSTEE';
    }
    if (this.contact && this.contact.isMalePoaOrFemalePoa) {
      return 'POWER_OF_ATTORNEY';
    }

    return 'SIGNING_OFFICER';
  }

  get consentingSpouseParticipant(): MatterParticipant {
    let familyLawAct: FamilyLawAct = this.getConsentedSpouseFamilyLawAct();
    return familyLawAct && familyLawAct.consentedSpouseParticipant;
  }

  get consentingSpouseContact(): Contact {
    let consentingSpouseParticipant: MatterParticipant = this.consentingSpouseParticipant;
    return consentingSpouseParticipant && consentingSpouseParticipant.contact;
  }

  getMatterConsentingSpouseParticipantByParentParticipant(matter: Matter): MatterParticipant {
    let consentingSpouseParticipant: MatterParticipant;
    if (this.matterParticipantId) {
      consentingSpouseParticipant = matter.getConsentSpouseParticipantByParentParticipantId(this.matterParticipantId);
    }
    return consentingSpouseParticipant;
  }

  get lNameFName(): string {
    if (!this.contact) {
      return '';
    }
    let lName = this.contact.lastName ? this.contact.lastName : '';
    let fName = this.contact.firstName ? this.contact.firstName : '';
    if (lName == '' && fName == '') {
      return '';
    }
    return lName + ', ' + fName;
  }

  isConsentingSpouseParticipant(): boolean {
    return this.matterParticipantRole === MatterParticipantRoleTypes.CONSENTING_SPOUSE;
  }

  isMyOwnAccountLawFirm(): boolean {
    const accountId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    const legFirmId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.legalFirmId));
    if (this.contact && this.contact.contactType == ContactTypes.LAW_FIRM) {
      return (this.contact.customerAccountId == accountId) && (this.contact.sourceContactId == legFirmId);
    }
    return false;
  }

  hasSourceContactId(): boolean {
    return !!(this.contact && this.contact.sourceContactId);
  }
}
