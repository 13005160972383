<div class="form-horizontal" hide-print="true" dp-printable-dialog >
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Teranet Connect &trade;:
                    <span *ngIf="!unityCharges">Teranet Docket Summary</span>
                    <span *ngIf="unityCharges">Billing Information</span>
                </h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-2 pull-right text-right print-padding-right">Date: {{today | date:'mediumDate'}}</div>
        <div class="col-lg-10 pull-left">
            <div class="col-lg-12 padding-bottom-5">{{matter.matterType}} Matter No: {{matter.matterRecordNumber}}</div>
            <div class="col-lg-12 padding-bottom-5">Docket ID: {{docketId}}</div>
            <div class="col-lg-12 padding-bottom-5">Docket Name: {{docketName}}</div>

            <div class="col-lg-12 padding-top-10 padding-bottom-20">
                <table style="width:100%">
                    <tr>
                        <td class="vertical-align-top">
                            <div class="inline-block padding-right-10 vertical-align-top noteClass">Note:</div>
                        </td>
                        <td>
                            <div *ngIf="!unityCharges" class="inline-block vertical-align-top afterNoteClass">
                            This summary shows only those transactions which occurred from
                            {{startDate | date : 'mediumDate'}} to {{endDate | date : 'mediumDate'}}.</div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div *ngIf="!unityCharges" class="inline-block vertical-align-top afterNoteClass">
                            Includes statutory services supplied by Teranet on behalf of the Ontario Government under exclusive licence.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div *ngIf="unityCharges" class="inline-block vertical-align-top">
                                Only charges incurred through Unity are shown.</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="col-lg-12">
            <table class="table tc-table">
                <thead>
                    <tr>
                        <th [ngClass]="{'col1': !unityCharges , 'col11' :unityCharges}">Description</th>
                        <th *ngIf="!unityCharges" class="col2">User Name</th>
                        <th class="col3">Amount</th>
                        <th class="col4">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tableData">
                        <td [ngClass]="{'col1': !unityCharges , 'col11' :unityCharges}">{{item.description}}</td>
                        <td *ngIf="!unityCharges" class="col2">{{item.userName}}</td>
                        <td *ngIf="!unityCharges" class="text-left col3">{{item.amount | currency: CAD :'symbol'}} + HST {{item.hstAmount | currency: CAD :
                            'symbol'}}</td>
                        <td *ngIf="unityCharges" class="text-left col3">{{item.amount | currency: CAD :'symbol'}} + {{item.taxType}} {{item.taxAmount |
                            currency: CAD :
                            'symbol'}}</td>
                        <td *ngIf="!unityCharges" class="col4">{{item.date | dpDateFormatPipe : ['noDay']}}</td>
                        <td *ngIf="unityCharges" class="col4">{{item.transactionDate | dpDateFormatPipe : ['noDay']}}</td>
                    </tr>
                    <tr>
                        <td>Total Charges:</td>
                        <td *ngIf="!unityCharges"></td>
                        <td *ngIf="!unityCharges" class="text-left">{{totalAmount | currency: CAD : 'symbol'}} + HST {{totalHstAmount | currency: CAD : 'symbol'}}</td>
                        <td *ngIf="unityCharges" class="text-left">{{totalAmount | currency: CAD : 'symbol'}} + {{totalTaxType}} {{totalHstAmount | currency: CAD :
                            'symbol'}}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td *ngIf="!unityCharges">HST No: {{hstRegNumber}}</td>
                        <td *ngIf="unityCharges">{{totalTaxType}} No: {{hstRegNumber}}</td>
                        <td *ngIf="!unityCharges"></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>

            </table>
        </div>



    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn focus-first" (click)="print()">
                <span>Print</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
