import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {StewartMortgageInstruction} from './stewart-mortgage-instruction';
import {ContactName} from '../../matters/shared/contact-name';

export type INTEGRATION_PARTNER = 'TELUS' | 'FCT';

export class SolicitorMortgageInstruction extends BaseEntity {
  userId: number;
  solicitorId: number;
  solicitorName: ContactName;
  stewartAssystMortgageInstructions: StewartMortgageInstruction[] = [];
  errorMessage: string;
  integrationPartner: INTEGRATION_PARTNER;

  constructor(s?: SolicitorMortgageInstruction) {
    super(s);
    if (s) {
      if (s.stewartAssystMortgageInstructions) {
        for (let p of s.stewartAssystMortgageInstructions) {
          let mortgageInstruction: StewartMortgageInstruction = new StewartMortgageInstruction(p);
          mortgageInstruction.solicitorId = s.solicitorId;
          this.stewartAssystMortgageInstructions.push(mortgageInstruction);
        }
      }
      if (s.solicitorName) {
        this.solicitorName = new ContactName(s.solicitorName);
      }
    }
  }

  get isSuccessful(): boolean {
    return !this.hasError;
  }

  get hasError(): boolean {
    return !!(this.errorMessage && this.errorMessage.length > 0);
  }

  get newMortgageInstructions(): StewartMortgageInstruction[] {
    return this.stewartAssystMortgageInstructions.filter(value => value.hasOnlyNewFileStatus);
  }
}
