<div class="container-fluid custom-modal-container" id="printableArea">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1 tabIndex="-1">Teranet Connect&trade;: {{modalCaption}}</h1>
        </div>
        <div class="col-lg-2">
            <button type="button" class="btn-close pull-right padding-top-5" (click)="close()" tabIndex="-1">x</button>
        </div>
    </div>
    <div class="row modal-body">
        <div class="row">
            <div class="col-lg-6 text-left margin-top-10">
                Subject Property Map - {{mapDetails && mapDetails.orderedOn | date:'MMM d, y hh:mm a' }}
            </div>
            <div class="col-lg-6 text-right">
                <button class="dp-btn dp-btn-cancel" type="button" (click)="viewAdjacentParcels()" >View Adjacent Lands</button>
                <a (click)="zoomIn()"> <i class="fa fa-search-plus" ></i></a>
                <a (click)="zoomOut()"><i class="fa fa-search-minus"></i></a>
                <button id="printBtn" class="dp-btn dp-btn-cancel" type="button" (click)="printMap()" tabindex="0">Print</button>
                <button class="dp-btn dp-btn-cancel" type="button" (click)="close()" tabindex="0">Close</button>
            </div>
        </div>
        <div class="row bufferZone"></div>
        <div class="row">
            <div class="mapImage" >
                <div class="draggableDiv">
                    <img src="{{imageUrl}}" [ngStyle]="imgStyle()" tabIndex="-1">
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="padding: 10px;">

        <div *ngIf="mapDetails && mapDetails != null" class="row">
            <div class="col-lg-1 " [ngStyle]="getStyle(0)" >

            </div>

            <div class="col-lg-2">
                {{legend1Text}}
            </div>

            <div class="col-lg-8">
                Scale: 0 <img src="/assets/img/scale.png" tabIndex="-1"> {{scaleMeters}} m
            </div>

            <div class="col-lg-1">

            </div>
        </div>

        <div *ngIf="mapDetails" class="row">
            <div class="col-lg-1" [ngStyle]="getStyle(1)">

            </div>

            <div class="col-lg-2">
                {{legend2Text}}
            </div>

            <div class="col-lg-8">
                {{mapDetails.disclaimer}}
            </div>

            <div class="col-lg-1">
                <img src="/assets/img/cardinal-points.png" tabIndex="-1">
            </div>
        </div>


    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>



