import {api} from '../../common';
import {SyngrafiiPackageType} from '../../event/signing-envelope';

export const thirdPartyIntegrationApi = {
  zoomAuthorizationUrl(): string {
    return `${ api }/public/integrations/zoom/authorizationUrl`;
  },
  microsoftAuthorizationUrl(): string {
    return `${ api }/public/integrations/microsoft/authorizationUrl`;
  },
  googleAuthorizationUrl(): string {
    return `${ api }/public/integrations/google/authorizationUrl`;
  },
  docuSignAuthorizationUrl(): string {
    return `${ api }/public/integrations/docusign/authorizationUrl`;
  },
  syngrafiiAuthorizationUrl(): string {
    return `${ api }/public/integrations/syngrafii/authorizationUrl`;
  },
  requestZoomAccessToken(accountId: number): string {
    return `${ api }/integrations/zoom/accounts/${ accountId }/accessToken/request`;
  },
  requestMicrosoftAccessToken(accountId: number): string {
    return `${ api }/integrations/microsoft/accounts/${ accountId }/accessToken/request`;
  },
  requestGoogleAccessToken(accountId: number): string {
    return `${ api }/integrations/google/accounts/${ accountId }/accessToken/request`;
  },
  requestDocuSignAccessToken(accountId: number): string {
    return `${ api }/integrations/docusign/accounts/${ accountId }/accessToken/request`;
  },
  requestSyngrafiiAccessToken(accountId: number): string {
    return `${ api }/integrations/syngrafii/accounts/${ accountId }/accessToken/request`;
  },
  getAccountIntegrationCredentials(integrationType: string, accountId: number): string {
    return `${ api }/integrations/${ integrationType }/accounts/${ accountId }/users `;
  },
  updateAccountIntegrationCredential(integrationType: string, accountId: number, accessGrantId: string): string {
    return `${ api }/integrations/${ integrationType }/accounts/${ accountId }/accessGrant/${ accessGrantId }`;
  },
  deleteAccountIntegrationCredential(integrationType: string, accountId: number, userId: number): string {
    return `${ api }/integrations/${ integrationType }/accounts/${ accountId }/user/${ userId }`;
  },
  updateSyngrafiiDefaultPackageType(accountId: number, packageType: SyngrafiiPackageType): string {
    return `${ api }/integrations/syngrafii/accounts/${ accountId }/defaultPackageType/${ packageType }`;
  },
  getUsers(integrationType: string, accountId: string): string {
    return `${ api }/integrations/${ integrationType }/accounts/${ accountId }/thirdPartyUsers `;
  },
  getUsersByAccessGrant(integrationType: string, accountId: string, accessGrantId: string): string {
    return `${ api }/integrations/${ integrationType }/accounts/${ accountId }/accessGrant/${ accessGrantId }/thirdPartyUsers `;
  },
  getSyngrafiiAccountConfiguration(accountId: string): string {
    return `${ api }/integrations/syngrafii/accounts/${ accountId }/config`;
  }
};
