import {DPError} from '../error-handling/dp-error';

export class MissingFieldTab {

  missingFieldErrors: DPError[] = [];

  constructor(missingFieldTab?: MissingFieldTab) {
    if (missingFieldTab) {
      for (let prop in missingFieldTab) {
        if (missingFieldTab.hasOwnProperty(prop)) {
          this[ prop ] = missingFieldTab[ prop ];
        }
      }
      this.missingFieldErrors = [];
      if (Array.isArray(missingFieldTab.missingFieldErrors)) {
        for (let i: number = 0; i < missingFieldTab.missingFieldErrors.length; ++i) {
          this.missingFieldErrors[ i ] = new DPError(missingFieldTab.missingFieldErrors[ i ]);
        }

      }

    }

  }
}


