<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>Registry Office</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="closeDialog()" >&times;</button>
    </div>
    <div class=" modal-body form-horizontal" infinite-scroll [scrollWindow]="false"  infiniteScrollDistance="3"
    infiniteScrollThrottle="500">
        <div class="form-group">
            <div class="col-lg-12 registry-table">

                    <table class="table" [mfData]="tableRegOfficeData" #mf="mfDataTable">
                        <thead><tr><th style="width: 22%;">Land Registry Office Name & Co.</th><th style="width: 50%;">Address</th></tr></thead>
                        <tbody>
                        <tr *ngFor="let row of mf.data; let i = index;"  (click)="onSelectedRow(row,$event)"
                            [ngClass]="{'active': isSelected(row)}">
                            <td style="width: 30%;">{{row.officeName}}</td><td style="width: 70%;">{{row.address ? row.address.addressText : ''}}</td>
                        </tr>
                        </tbody>
                    </table>

            </div>
        </div>
        <div *ngIf="loading" class="spinner">
            <img src="assets/img/spinner.gif"/> Loading...
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons text-center">
            <button type="button"
                    class="dp-btn focus-first keyboard-save-action" (click)="closeDialog()">
                <span>OK</span>
            </button>
            <button type="button"
                    class="dp-btn dp-btn-cancel" (click)="cancelDialog()">
                <span>Cancel</span>
            </button>
        </div>
    </div>

</div>
