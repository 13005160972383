import {UserDefinedField} from '../../../shared-main/user-defined-field/user-defined-field';
import {Matter} from '../matter';
import * as _ from 'lodash';

export class MatterUserDefinedFieldsUtil {

  static addNewMortgageUDFsToMatter(matter: Matter, udfsConfigTopic: UserDefinedField[], udfsMatterMortgageTopic: UserDefinedField[], mortgageId: number, topicName: string) {
    if (Array.isArray(udfsConfigTopic)) {
      // console.log("addNewMortgageUDFsToMatter: udfsMatterMortgageTopic=", udfsMatterMortgageTopic);
      for (let i: number = 0; i < udfsConfigTopic.length; ++i) {
        let configUDF: UserDefinedField = new UserDefinedField(udfsConfigTopic[ i ]);

        if (configUDF.active) { // && this.isMortgageTopic(configUDF.topicName)){
          configUDF.id = null;
          configUDF.instanceType = 'MATTER_UDF';
          configUDF.sourceUDFId = udfsConfigTopic[ i ].id;
          configUDF.userDefinedFieldOrder = udfsConfigTopic[ i ].userDefinedFieldOrder;
          configUDF.mortgageId = mortgageId;

          let foundInMatter = false;
          for (let j: number = 0; j < udfsMatterMortgageTopic.length; j++) {
            //console.log(` -> configUDF.id=${udfsConfigTopic[i].id} | matterUDF.sourceUDFId=${udfsMatterMortgageTopic[j].sourceUDFId}`);
            if (udfsConfigTopic[ i ].id == udfsMatterMortgageTopic[ j ].sourceUDFId) {
              foundInMatter = true;
              break;
            }
          }

          if (!foundInMatter && udfsConfigTopic[ i ].active) {
            matter.matterUserDefinedFields.push(configUDF);
          }
        }
      }
      matter.matterUserDefinedFields.sort((a: UserDefinedField, b: UserDefinedField) => {
        return a.userDefinedFieldOrder - b.userDefinedFieldOrder;
      });
    }
  }

  static initializeNewMortgageUserDefinedFields(matter: Matter, customerUDFs: UserDefinedField[], mortgageId: number) {
    if (Array.isArray(customerUDFs)) {
      console.log('initializeNewMortgageUserDefinedFields: this.matterUserDefinedFields=', matter.matterUserDefinedFields);
      for (let i: number = 0; i < customerUDFs.length; ++i) {
        let customerUDF: UserDefinedField = new UserDefinedField(customerUDFs[ i ]);

        if (customerUDF.active && matter.isMortgageTopic(customerUDF.topicName)) {
          customerUDF.id = null;
          customerUDF.instanceType = 'MATTER_UDF';
          customerUDF.sourceUDFId = customerUDFs[ i ].id;
          customerUDF.userDefinedFieldOrder = customerUDFs[ i ].userDefinedFieldOrder;
          customerUDF.mortgageId = mortgageId;
          matter.matterUserDefinedFields.push(customerUDF);
        }
      }
    }
  }

  static deleteMortgageUserDefinedFields(matter: Matter, mortgageId: number) {
    // console.log(`Deleting mortgage user defined fields from mortgage id ${mortgageId}`);
    _.remove(matter.matterUserDefinedFields, (udf) => {
      return udf.mortgageId === mortgageId && udf.instanceType === 'MATTER_UDF';
    });
  }
}
