import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {matterApi, matterResponseKey, Utils} from '../shared';
import {Jurisdiction} from '../shared/jurisdiction';
import {Department} from '../shared/department';

@Injectable()
export class JurisdictionService {
  constructor(private http: HttpClient) {
  }

  page: number = 1;
  limit: number = 15;
  sortQuery: string = 'jurisdictionName';
  sortingType: string = 'ASC';

  getJurisdictions(query?: string, page: number = this.page, limit: number = this.limit, sortQuery: string = this.sortQuery, sortingType: string = this.sortingType,
                   accessScopeSearch?: string, filterByProvinceCode?: string, loadLightWightedJurisdiction: boolean = false, jurisdictionStatus: string = 'ACTIVE'): Observable<any[]> {
    let url: string;
    let sort: string = '';
    let sortType: string = '';
    let filter = '';
    sortType = sortingType ? sortingType : 'ASC';
    sort = sortQuery ? sortQuery : 'jurisdictionName';
    sort = this.parseSort(sort, sortType);
    url = `${ matterApi.jurisdictions }?page=${ page }&per_page=${ limit }&sort=${ sort }`;
    if (query) {
      query = Utils.escapeSearchText(query);
      filter = query && query.length > 0 ? 'jurisdictionName_EQ_*' + query + '*' : '';

    }
    if (filterByProvinceCode) {
      let inclusion: string = filterByProvinceCode.includes('!') ? 'IN' : 'EQ';
      filter += (filter.length > 0 ? ',' : '') + `provinceCode_${ inclusion }_${ filterByProvinceCode }`;
    }

    filter = this.appendAccessScopeFilter(filter, accessScopeSearch);
    filter = this.appendJurisdictionStatusFilter(filter, jurisdictionStatus);

    url = filter && filter.length > 0 ?
      `${ matterApi.jurisdictions }?page=${ page }&per_page=${ limit }&sort=${ sort }&filter=${ filter }&filterType=ALL&loadLightWeighted=${ loadLightWightedJurisdiction }`
      : `${ matterApi.jurisdictions }?page=${ page }&per_page=${ limit }&sort=${ sort }&loadLightWeighted=${ loadLightWightedJurisdiction }`;

    return this.http.get(url).map(
      (response) => {
        let data = response[ matterResponseKey.jurisdictions ];
        let result: Jurisdiction[] = [];

        data.forEach(item => {
          result.push(new Jurisdiction(item));
        });

        if (result.length === 0) {
          return this.noRecordFound(result, 'jurisdictionName');
        }

        return result;
      }
    );
  }

  appendAccessScopeFilter(filter: string, privateGlobalQuery: string): string {
    let accessScopeFilter: string = '';

    if (privateGlobalQuery && privateGlobalQuery.length > 0) {
      switch (privateGlobalQuery.toUpperCase()) {
        case 'PRIVATE_GLOBAL':
          break;
        case 'GLOBAL':
          accessScopeFilter = 'ALLproxyForGlobal_EQ_true|proxyEdited_EQ_false';
          break;
        case 'PRIVATE':
          accessScopeFilter = 'ANYproxyForGlobal_EQ_false|proxyEdited_EQ_true';
          break;
        default :
          break;
      }
    }

    if (accessScopeFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + accessScopeFilter;
      } else {
        filter += accessScopeFilter;
      }
    }

    return filter;
  }

  appendJurisdictionStatusFilter(filter: string, jurisdictionStatuQuery: string): string {
    let jurisdictionStatusFilter: string = '';

    if (jurisdictionStatuQuery && jurisdictionStatuQuery.length > 0) {
      switch (jurisdictionStatuQuery.toUpperCase()) {
        case 'ALL':
          break;
        case 'ACTIVE':
          jurisdictionStatusFilter = 'activeFlag_IN_' + Utils.escapeSearchText(`Y_n`) + '!YES';
          break;
        case 'INACTIVE':
          jurisdictionStatusFilter = 'activeFlag_IN_' + Utils.escapeSearchText(`N_y`) + '!NO';
          break;
        default :
          break;
      }
    }

    if (jurisdictionStatusFilter.length > 0) {
      if (filter && filter.length > 0) {
        filter += ',' + jurisdictionStatusFilter;
      } else {
        filter += jurisdictionStatusFilter;
      }
    }

    return filter;
  }

  getAllJurisdictions(query?: string, sortQuery?: string): Observable<Jurisdiction[]> {
    let url: string = `${ matterApi.jurisdictions }`;
    if (sortQuery && sortQuery.trim().length > 0) {
      url += `?sort=${ sortQuery }`;
    }
    if (query && query.trim().length > 0) {

      url += `&filter=${ query }&filterIgnoreCase=true&filterType=ALL`;
    }

    return this.http.get(url).map(
      (response) => {
        let data = response[ matterResponseKey.jurisdictions ];
        let result: Jurisdiction[] = [];

        data.forEach(item => {
          result.push(new Jurisdiction(item));
        });

        return result;
      }
    );
  }

  // get jurisdiction by id from backend
  getJurisdiction(id: number): Observable<Jurisdiction> {
    return this.http.get(`${ matterApi.jurisdictions }/${ id }`)
    .map((res) => {
      const jurisdiction: Jurisdiction = new Jurisdiction(res[ 'Jurisdiction' ]);
      return jurisdiction;
    });
  }

  getReadonlyJurisdiction(id: number): Observable<Jurisdiction> {
    let url: string = `${ matterApi.jurisdictions }/${ id }?read_only=true`;
    return this.http.get(url)
    .map((res) => {
      const jurisdiction: Jurisdiction = new Jurisdiction(res[ 'Jurisdiction' ]);
      return jurisdiction;
    });
  }

  unlockJurisdiction(id: number) {
    return this.http.get(`${ matterApi.jurisdictions }/${ id }/unlock`)
    .map((res) => {
      return res;
    });
  }

  getJurisdictionLockStatus(id: number) {
    return this.http.get(`${ matterApi.jurisdictions }/${ id }/lockstatus`)
    .map((res) => {
      return res;
    });
  }

  noRecordFound(data, filterKey) {
    let jurisdiction: any = {};

    jurisdiction[ filterKey ] = 'No records available';
    data.push(jurisdiction);

    return data;
  }

  parseSort(sorting, sortType) {
    let res: any[] = [];
    let multipleSorting: string = '';
    if (sorting != null) {

      if (sorting.indexOf(',') > -1) {

        res = sorting.split(',');
        for (let i = 0; i < res.length; i++) {
          if (i == (res.length - 1)) {
            multipleSorting = multipleSorting + res[ i ] + '|' + sortType;
          } else {
            multipleSorting = multipleSorting + res[ i ] + '|' + sortType + ',';
          }

        }
        return multipleSorting;
      } else {
        return sorting + '|' + sortType;
      }
    } else {
      return '';
    }
  }

  getDepartment(id: number): Observable<Department> {
    return this.http.get(`${ matterApi.departments }/${ id }`)
    .map((res) => {
      return new Department(res[ 'Department' ]);
    });
  }
}
