<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>{{statementAdjustmentFormatDialogTitle()}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class=" modal-body form-horizontal">
        <dp-statement-adjustment-format
            [viewOnly]="context.viewOnly"
            [statementAdjustment]="statementAdjustment"
            [statementOfAdjustmentHeading]="context.statementAdjustmentHeadingMatter">
        </dp-statement-adjustment-format>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>
