<div class="matter-title form-group admin-list-container" style="top: 115px;">
    <div class="col-lg-12">

        <h1>Extended Workflows Categories {{account? '- ' + account.publicAccountId + ' ' + account.name : ''}}   <a (click)="goBackToAnchorTabPage()"
                                                                                                              class="pull-right header-link">Back to
            {{tabsStateService.anchorTab.isDocServices() ?  'Doc Services' :  'Administration'}} Menu</a></h1>

    </div>
</div>

<div class="padding-bottom-40">
    <div class="form-list">
        <div class="col-lg-12 text-right">
            <button label="Add New" class="standard-table-button dropbtn" (click)="createNewTask()">
                <i class=""  aria-hidden="false"></i> <span class="padding-top-5">Add New</span>
                <div class="clear"></div>
            </button>
        </div>
    </div>
    <div class="view-table">
        <div class="col-lg-12">
            <table class="standard-table col-lg-12 margin-top-40" [mfData]="tableData" #mf="mfDataTable">
                <thead>
                <tr>
                    <th>Category</th>
                    <th></th>
                    <th class="applies-to">Applies To</th>
                    <th>Auto Insert</th>
                    <th>Last Updated</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of mf.data; let i = index;">
                    <td>{{row.categoryName}}</td>
                    <td align="center">
                        <dp-burger-menu [items]="burgerMenuItems" (itemClicked)="burgerMenuClicked($event, row)"></dp-burger-menu>
                    </td>
                    <td class="applies-to" title="{{getAppliesToString(row)}}">{{getAppliesToString(row)}}</td>
                    <td>{{row.autoInsert}}</td>
                    <td>{{ row.lastUpdatedTimeStamp | date:'MMM d y'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

