import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {CategoryTemplate} from '../../shared-main/supplemental-task-category/category-template';

export class MatterSupplementalTaskTemplate extends BaseEntity {
  id: number;
  documentTemplateId: number;
  documentTemplateName: string;
  templateDescription: string;
  hasProducedDocument: boolean;
  preProduceValidationFlags: string[];

  static clone(source: MatterSupplementalTaskTemplate): MatterSupplementalTaskTemplate {
    let clonedCategoryTemplate: MatterSupplementalTaskTemplate = new MatterSupplementalTaskTemplate();
    for (let prop in source) {
      if (source.hasOwnProperty(prop)) {
        clonedCategoryTemplate[ prop ] = source[ prop ];
      }
    }
    clonedCategoryTemplate.id = null;
    return clonedCategoryTemplate;
  }

  static createFromDefaultCategoryTemplate(defaultCategoryTemplate: CategoryTemplate): MatterSupplementalTaskTemplate {
    let matterSupplementalTaskTemplate = new MatterSupplementalTaskTemplate();
    matterSupplementalTaskTemplate.documentTemplateId = defaultCategoryTemplate.documentTemplateId;
    matterSupplementalTaskTemplate.documentTemplateName = defaultCategoryTemplate.documentTemplateName;
    matterSupplementalTaskTemplate.templateDescription = defaultCategoryTemplate.templateDescription;
    matterSupplementalTaskTemplate.preProduceValidationFlags = defaultCategoryTemplate.preProduceValidationFlags;
    return matterSupplementalTaskTemplate;
  }
}

