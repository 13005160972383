<form class="form-horizontal" id="renameCredential" >
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-lg-6">
                <h1>Rename Credential</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>

        <div class="row modal-body">
            <div class="form-group">
                <div class="col-lg-4 text-left vertical-align-label">
                    <label class="control-label">Credential</label>
                </div>
                <div class="col-lg-6">
                    <input  class="form-control focus-first"
                            id="credential_label"
                            name="credential_label"
                            [(ngModel)]="accountIntegrationCredential.credentialLabel"
                            maxlength="100"
                            required
                            trimmed
                    >
                </div>
            </div>
        </div>


        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="saveCredential()"
                        [disabled]="!accountIntegrationCredential.credentialLabel"
                        class="dp-btn">
                    <span>Save</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
    </div>
</form>
