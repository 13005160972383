import {Injectable} from '@angular/core';
import {HttpClient} from '../core/httpClient.service';
import {Observable} from 'rxjs';
import {SESSION_ID_REQ_PARAM} from '../common/api';
import {BillingTransactionReport, PurchaseCreditsRequest, UnityBilling} from './unity-billing';
import {unityBillingApi} from './unity-billing-api';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {ProvinceCode} from '../admin/accounts/shared/base-province';
import {MatterType} from '../matters/shared';
import {MatterPricing} from './matter-pricing';
import moment from 'moment';

/**
 *  Access Groups service defines all api calls for Access Groups component
 */
@Injectable()
export class UnityBillingService {

  private cachedMatterPricing: Map<ProvinceCode, MatterPricing[]> = new Map<ProvinceCode, MatterPricing[]>();

  constructor(private http: HttpClient) {
  }

  getUnityBilling(): Observable<UnityBilling> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(unityBillingApi.baseUrl.replace('{accountId}', accountId))
    .map((res) => {
      let data = res[ 'UnityBilling' ];
      return new UnityBilling(data);
    });
  }

  createUnityBilling(unityBilling: UnityBilling): Observable<UnityBilling> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.post(unityBillingApi.baseUrl.replace('{accountId}', accountId), unityBilling)
    .map((res) => {
      let data = res[ 'UnityBilling' ];
      return new UnityBilling(data);
    });
  }

  updateUnityBilling(id: number, unityBilling: UnityBilling): Observable<UnityBilling> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = `${ unityBillingApi.baseUrl.replace('{accountId}', accountId) }/${ id }`;
    return this.http.put(url, unityBilling)
    .map((res) => {
      let data = res[ 'UnityBilling' ];
      return new UnityBilling(data);
    });
  }

  getSetupPaymentInfoUrl(): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.setupPaymentUrl.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return res[ 'SetupPaymentInfoUrl' ];
    });
  }

  purchaseCredits(purchaseCreditsRequest: PurchaseCreditsRequest) {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.purchaseCredits.replace('{accountId}', accountId);
    return this.http.post(url, purchaseCreditsRequest)
    .map((res) => {
      let data = res[ 'UnityBilling' ];
      return new UnityBilling(data);
    });
  }

  getCurrentBalance(): Observable<UnityBilling> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.currentBalance.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return new UnityBilling(res[ 'UnityBilling' ]);
    });
  }

  getAccountSummaryUrl(): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.accountSummaryUrl.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return res[ 'AccountSummaryUrl' ];
    });
  }

  getTransactionReport(dateType, transType, dateStart, dateEnd): Observable<any> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let modified = [];
    let url = unityBillingApi.transactionReport.replace('{accountId}', accountId);

    if (transType == 'DEBIT_CREDIT') {
      url = url + 'transactionTypes=DEBIT&transactionTypes=CREDIT';
    } else if (transType == 'CREDIT_PURCHASE') {
      url = url + 'transactionTypes=CREDIT_PURCHASE';
    } else if (transType == 'CREDIT_ADJUSTMENTS') {
      url = url + 'transactionTypes=GENERAL_CREDIT';
    }

    if (dateType == 'SPECIFIC') {
      if (dateStart != '') {
        url = url + '&startDate=' + dateStart;
      }
      if (dateEnd != '') {
        url = url + '&endDate=' + dateEnd;
      }
    }

    return this.http.get(url)
    .map((res) => {
      res[ 'BillingTransactionReport' ].forEach(item => {

        modified.push(new BillingTransactionReport(item));

      }, this);
      return (modified);
    });
  }

  exportTransactionReportToCsv(dateType, transType, dateStart, dateEnd) {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.exportTransactionReportToCSV.replace('{accountId}', accountId);
    let timeZoneOffset: string = moment().format('Z');
    url = url + `${ SESSION_ID_REQ_PARAM }=${ sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId) }&offset=${ this.http.encodePlusSign(timeZoneOffset) }`;

    if (transType == 'DEBIT_CREDIT') {
      url = url + '&transactionTypes=DEBIT&transactionTypes=CREDIT';
    } else if (transType == 'CREDIT_PURCHASE') {
      url = url + '&transactionTypes=CREDIT_PURCHASE';
    } else if (transType == 'CREDIT_ADJUSTMENTS') {
      url = url + '&transactionTypes=GENERAL_CREDIT';
    }

    if (dateType == 'SPECIFIC') {
      if (dateStart != '') {
        url = url + '&startDate=' + dateStart;
      }
      if (dateEnd != '') {
        url = url + '&endDate=' + dateEnd;
      }
    }
    window.open(this.http.encodePipe(this.http.normalizeUrl(url)), '_blank');
  }

  getMatterPricing(provinceCode: ProvinceCode, matterType: MatterType): Observable<MatterPricing> {
    let matterPricingList: MatterPricing[] = this.cachedMatterPricing.get(provinceCode);
    if (Array.isArray(matterPricingList)) {
      let matterPricing: MatterPricing = matterPricingList.find(mp => mp.matterType === matterType);
      if (matterPricing) {
        return Observable.of(matterPricing);
      }
    }
    return this.getNonCachedMatterPricing(provinceCode, matterType);
  }

  getNonCachedMatterPricing(provinceCode: ProvinceCode, matterType: MatterType): Observable<MatterPricing> {
    let matterPricingList: MatterPricing[] = [];
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.productPricing.replace('{accountId}', accountId) + `provinceCode=${ provinceCode }`;
    return this.http.get(url)
    .map((response) => {
      if (response && response[ 'ProductPricing' ] && response[ 'ProductPricing' ].length > 0) {
        let matterPricingResponse: MatterPricing[] = response[ 'ProductPricing' ];
        matterPricingList = matterPricingResponse.map((item) => {
          return new MatterPricing(item);
        });
        this.cachedMatterPricing.set(provinceCode, matterPricingList);
        return matterPricingList.find(mp => mp.matterType === matterType);
      }
    });
  }

  getCustomerBillingPortalUrl(): Observable<string> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url = unityBillingApi.customerBillingPortalUrl.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res: Response) => {
      return res[ 'CustomerBillingPortalUrl' ];
    });
  }
}
