import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {StgMessage} from './stewart-title-message';

export class GetResultResponse extends BaseEntity {
  messages: StgMessage[] = [];
  isValid: boolean;
  fileNo: string;
  eCFee: string;
  grandTotal: number;
  policyTotal: number;
  policySalesTax: number;
  orderStatusCategory: any;

  constructor(t?: GetResultResponse) {
    super(t);
    if (t) {
      if (t.messages) {
        for (let f of t.messages) {
          this.messages.push(new StgMessage(f));
        }
      }
    }
  }

}

