import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {
  TeranetParcelRegisterSearchRequest,
  TeranetSearchType
} from '../../../../shared-main/teranet/teranet-parcel-register-search-request';
import {Contact, Jurisdiction, Matter, Utils} from '../../../shared/index';
import {RegistryOffice} from '../../../shared/jurisdiction';
import {DocumentProfileCache} from '../../../../shared-main/document-profile-cache.service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {RegistryOfficeService} from '../../registry-office/registry-office.service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {TeranetParcelRegisterSearchResponse} from '../../../../shared-main/teranet/teranet-parcel-register-search-response';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {FieldError} from '../../../../core/index';
import {ApplicationError} from '../../../../core/application-error';
import {PurchaseParcelRegistersModal} from '../purchase-parcel-register/purchase-parcel-registers.modal.component';
import * as _ from 'lodash';
import {TeranetPropertyOwnersContext, TeranetPropertyOwnersModal} from './teranet-property-owners.modal.component';
import {MatterParticipant} from '../../../shared/matter-participant';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class TeranetSearchModalContext {
  matter: Matter;
  teranetUser: TeranetUser;
  documentProfileCache: DocumentProfileCache;
}

@Component({
  selector: 'dp-teranet-search-modal',
  templateUrl: 'teranet-search.modal.component.html',
  providers: [ErrorService, RegistryOfficeService]
})
export class TeranetSearchModal extends ModalComponent<TeranetSearchModalContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  lroList: RegistryOffice[] = [];
  teranetSearchRequest: TeranetParcelRegisterSearchRequest;
  vendors: Contact[] = [];
  propertyPins: string[] = [];
  selectedVendorForSearch: Contact;

  constructor(
    public dialog: MatDialogRef<TeranetSearchModal>,
    public registryOfficeService: RegistryOfficeService,
    public elementRef: ElementRef,
    public dialogService: DialogService,
    public teranetService: TeranetService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: TeranetSearchModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.teranetSearchRequest = new TeranetParcelRegisterSearchRequest();
    this.teranetSearchRequest.matterId = this.context.matter.id;
    this.teranetSearchRequest.teranetUser = this.teranetUser;
    this.populateDefaultAddressForSearch();
    this.populateLroList();
    this.populateDefaultNamesForSearch();
    this.updateVendorNameList();
    this.populateDefaultPinForSearch();
    this.updatePinList();
  }

  get teranetUser(): TeranetUser {
    return this.context.teranetUser;
  }

  populateDefaultAddressForSearch(): void {
    if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.address) {
      this.teranetSearchRequest.streetNumber = this.matter.matterPropertyWithCondo.address.streetNumber;
      this.teranetSearchRequest.streetName = this.matter.matterPropertyWithCondo.address.streetName;
      this.teranetSearchRequest.unitNumber = this.matter.matterPropertyWithCondo.address.unitNo;
    }
  }

  //The method sets the default value in LRO drop-down.
  // If jurisdiction is selected on matter property then set default LRO to that otherwise set the default
  // value based on document profile. If document profile is also not selected then default to first LRO
  populateDefaultLroValue() {
    //Explicitly setting the first one as default otherwise it shows blank
    if (this.lroList.length > 0) {
      this.teranetSearchRequest.selectedLro = this.lroList[0];
    }

    if (
      this.matter.matterPropertyWithCondo.jurisdiction &&
      this.matter.matterPropertyWithCondo.jurisdiction.registryOffice
    ) {
      this.teranetSearchRequest.selectedLro = this.findRegistryOfficeInLroList(
        this.matter.matterPropertyWithCondo.jurisdiction.registryOffice
      );
    } else {
      this.documentProfileCache.getDocumentProfileJurisdiction().subscribe((jurisdiction: Jurisdiction) => {
        if (jurisdiction && jurisdiction.registryOffice) {
          this.teranetSearchRequest.selectedLro = this.findRegistryOfficeInLroList(jurisdiction.registryOffice);
        }
      });
    }
  }

  findRegistryOfficeInLroList(regOffice: RegistryOffice): RegistryOffice {
    return this.lroList.find((lro: RegistryOffice) => {
      return regOffice.officeNumber === lro.officeNumber && regOffice.officeName === lro.officeName;
    });
  }

  get documentProfileCache(): DocumentProfileCache {
    return this.context.documentProfileCache;
  }

  populateDefaultPinForSearch() {
    if (this.matter.matterProperties && this.matter.matterProperties.length > 0) {
      this.teranetSearchRequest.pin = this.matter.matterProperties[0].formattedPin;
    }
  }

  populateDefaultNamesForSearch() {
    if (this.participantsForNameSearch && this.participantsForNameSearch.length > 0) {
      const firstVendor = this.participantsForNameSearch[0].contact;
      this.teranetSearchRequest.lastName = firstVendor.lastOrBusinessName
        ? firstVendor.lastOrBusinessName.toUpperCase()
        : '';
      this.teranetSearchRequest.firstName = firstVendor.firstMiddleNames
        ? firstVendor.firstMiddleNames.toUpperCase()
        : '';
    }
  }

  updateVendorNameList(event?) {
    this.vendors = [];
    if (this.participantsForNameSearch.length > 0) {
      this.participantsForNameSearch.forEach((vendor) => {
        this.vendors.push(vendor.contact);
      });
    }
  }

  get participantsForNameSearch(): MatterParticipant[] {
    if (this.matter.isSale || this.matter.isMortgage) {
      return this.matter.mainClients;
    } else {
      return this.matter.otherSideClients;
    }
  }

  updatePinList(event?) {
    this.propertyPins = [];
    if (
      this.matter &&
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.isPropertyCondominium()
    ) {
      if (
        this.matter.matterPropertyWithCondo.condominiumExpenses &&
        this.matter.matterPropertyWithCondo.condominiumExpenses.length > 0
      ) {
        this.matter.matterPropertyWithCondo.condominiumExpenses.forEach((mp) => {
          if (mp.pinNumber) {
            this.propertyPins.push(mp.pinNumber);
          }
        });
      }
    } else {
      if (this.matter.matterProperties.length > 1) {
        this.matter.matterProperties.forEach((mp) => {
          this.propertyPins.push(mp.formattedPin);
        });
      }
    }
  }

  handlePinDropdownClick() {
    this.updatePinList();
  }

  handleVendorNameDropdownClick() {
    this.vendors = [];
    //mimic remote call
    setTimeout(() => {
      this.updateVendorNameList();
    }, 100);
  }

  get isSearchNameOptions() {
    return this.participantsForNameSearch && this.participantsForNameSearch.length > 0;
  }

  get isPinDropdown() {
    return this.propertyPins && this.propertyPins.length > 1;
  }

  dataSelectedSearchName(event) {
    this.teranetSearchRequest.lastName = this.selectedVendorForSearch.lastOrBusinessName
      ? this.selectedVendorForSearch.lastOrBusinessName.toUpperCase()
      : '';
    this.teranetSearchRequest.firstName = this.selectedVendorForSearch.firstMiddleNames
      ? this.selectedVendorForSearch.firstMiddleNames.toUpperCase()
      : '';
  }

  populateLroList(): void {
    this.registryOfficeService
      .getAllRegistryOffices(this.context.matter ? this.context.matter.provinceCode : null)
      .subscribe((res: RegistryOffice[]) => {
        this.lroList = _.sortBy(res, 'formattedOfficeNumber');
        this.populateDefaultLroValue();
      });
  }

  close(): void {
    this.dialog.close({action: 'OK'});
  }

  cancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  isSearchOptionEnabled(searchOption: TeranetSearchType): boolean {
    return this.teranetSearchRequest.searchType === searchOption;
  }

  onSearchOptionClick(selectedOption: TeranetSearchType) {
    this.teranetSearchRequest.searchType = selectedOption;
  }

  get matter() {
    return this.context.matter;
  }

  get isSearchButtonDisabled(): boolean {
    return !this.teranetSearchRequest.searchType;
  }

  searchParcelRegister(): void {
    if (this.isSearchRequestValid()) {
      this.teranetService.searchParcelRegister(this.teranetSearchRequest).subscribe(
        (teranetParcelRegisterSearchResponse: TeranetParcelRegisterSearchResponse) => {
          if (
            teranetParcelRegisterSearchResponse.propertyOwners &&
            teranetParcelRegisterSearchResponse.propertyOwners.length > 1
          ) {
            this.openPropertyOwnersDataModel(teranetParcelRegisterSearchResponse);
          } else {
            if (
              teranetParcelRegisterSearchResponse.propertyOwners &&
              teranetParcelRegisterSearchResponse.propertyOwners.length === 1
            ) {
              this.teranetSearchRequest.firstName = teranetParcelRegisterSearchResponse.propertyOwners[0].firstName;
              this.teranetSearchRequest.lastName = teranetParcelRegisterSearchResponse.propertyOwners[0].lastName;
            }
            this.openParcelSearchResponseModel(teranetParcelRegisterSearchResponse);
          }
        },
        (error: ApplicationError) => {
          this.handleTeranetSearchApiError(error);
        }
      );
    }
  }

  handleTeranetSearchApiError(error: ApplicationError): void {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  validatePinFormat(): void {
    this.modalErrorComponent.removeDpFieldError('teranet.searchParcelRegister.invalidPin');
    if (!Utils.isFormattedPinValid(this.teranetSearchRequest.pin)) {
      this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.invalidPin');
    }
  }

  isSearchRequestValid(): boolean {
    this.modalErrorComponent.removeAllDpFieldError();
    if (!this.teranetSearchRequest.selectedLro) {
      this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.missingLRO');
    } else if (this.teranetSearchRequest.searchType === 'SEARCH_BY_ADDRESS') {
      if (!this.teranetSearchRequest.streetNumber || this.teranetSearchRequest.streetNumber.trim().length < 1) {
        this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.missingStreetNumber');
      }
      if (!this.teranetSearchRequest.streetName || this.teranetSearchRequest.streetName.trim().length < 2) {
        this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.missingStreetName');
      }
    } else if (this.teranetSearchRequest.searchType === 'SEARCH_BY_NAME') {
      if (!this.teranetSearchRequest.lastName || this.teranetSearchRequest.lastName.trim().length < 2) {
        this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.missingLastName');
      }
    } else if (this.teranetSearchRequest.searchType === 'SEARCH_BY_PIN') {
      if (!this.teranetSearchRequest.pin || !Utils.isFormattedPinValid(this.teranetSearchRequest.pin)) {
        this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.invalidPin');
      }
    } else if (this.teranetSearchRequest.searchType === 'SEARCH_BY_INSTRUMENT') {
      if (
        !this.teranetSearchRequest.instrumentNumber ||
        this.teranetSearchRequest.instrumentNumber.trim().length == 0
      ) {
        this.modalErrorComponent.createDPFieldError('teranet.searchParcelRegister.missingInstrument');
      }
    }

    return !this.errorService.hasErrors();
  }

  openParcelSearchResponseModel(teranetParcelRegisterSearchResponse: TeranetParcelRegisterSearchResponse): void {
    this.dialogService.matDialogContent({
      content: PurchaseParcelRegistersModal,
      context: {
        matter: this.matter,
        teranetUser: this.teranetUser,
        teranetSearchRequest: this.teranetSearchRequest,
        teranetSearchResponse: teranetParcelRegisterSearchResponse
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK') {
          this.close();
        } else if (result && result.action === 'Cancel') {
          this.cancel();
        }
      }
    });
  }

  openPropertyOwnersDataModel(teranetParcelRegisterSearchResponse: TeranetParcelRegisterSearchResponse): void {
    this.dialogService.matDialogContent({
      content: TeranetPropertyOwnersModal,
      context: <TeranetPropertyOwnersContext>{
        teranetSearchRequest: this.teranetSearchRequest,
        teranetSearchResponse: teranetParcelRegisterSearchResponse,
        matter: this.matter,
        teranetUser: this.teranetUser
      },
      onFulfillment: (result: any) => {
        if (result && (result.action === 'Cancel' || result.action === 'OK')) {
          this.dialog.close({action: result.action});
        }
      }
    });
  }
}
