<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Select Contact</h1>
            </div>
            <button *ngIf="!this.context.isWizard" type="button" class="btn-close pull-right" (click)="ignore()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-lg-12 padding-bottom-20 bottom-div">
            <p >A contact record may already exist for {{existingProspectName()}}. {{context && context.isWizard ? 'Would you like to use an existing contact or create a new one?' : 'You may replace it with one of the existing contacts.'}}</p>
        </div>
        <div class="container-div col-lg-12">
            <ng-container *ngFor="let suggestedContact of duplicateSourceContacts; let i=index">
                <div class="col-lg-12 row-div"
                     [ngClass]="{'active': selectedContactId == suggestedContact.id}"
                     (click)="onContactSelected(suggestedContact)">
                     <span style="width: 3%;min-height: 15px !important;"  class="radio padding-top-0">
                         <input id="contactSelected-{{suggestedContact.id}}"
                                type="radio"
                                name="radio-contactSelected-{{suggestedContact.id}}"
                                [checked]="selectedContactId == suggestedContact.id" (click)="selectedContactId = suggestedContact.id"  />
                         <label for="contactSelected-{{suggestedContact.id}}"></label></span>
                    <span style="width: 20%">{{suggestedContact.displayName}}</span>
                    <span style="width: 30%">{{suggestedContact.getDisplayAddress()}}</span>
                    <span style="width: 10%">{{suggestedContact.getGenderValue()}}</span>
                    <span style="width: 18%">{{suggestedContact.email}}</span>
                    <span style="width: 10%">{{suggestedContact.homePhone}}</span>
                </div>
            </ng-container>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="ignore"
                    (click)="ignore()"
                    class="dp-btn secondary-button">
                <span>{{this.context.isWizard ? 'Create New Contact' : 'Ignore for now'}}</span>
            </button>
            <button type="button" *ngIf="!this.context.isWizard"
                    id="keep"
                    (click)="Keep()"
                    class="dp-btn secondary-button">
                <span>Keep Prospect</span>
            </button>
            <button type="button"
                    id="replace"
                    (click)="replace()"
                    class="dp-btn secondary-button">
                <span>{{this.context.isWizard ? 'Use Selected Existing Contact' : 'Replace the prospect with selected client'}}</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>
