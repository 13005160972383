import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'textarea[autoresize]'
})

export class AutoresizableMultilineInput {

  @Input('autoresize') maxHeight: number;

  @Input('minHeight') minHeight: number;

  constructor(public element: ElementRef) {
  }

  @HostListener('input', [ '$event.target' ])
  onInput(textArea: HTMLTextAreaElement): void {
    this.scale();
  }

  ngAfterViewInit(): void {
    // Adding Time out so that value are bind to element and then after we can scale to get scroll height
    setTimeout(() => {
      this.scale();
    }, 100);
  }

  scale(): void {
    let textAreaInput = this.element.nativeElement;
    let scaledHeight;

    if (textAreaInput) {
      textAreaInput.style.overflow = 'hidden';
      textAreaInput.style.height = 'auto';

      if (this.maxHeight) {
        scaledHeight = Math.min(textAreaInput.scrollHeight, this.maxHeight);

      } else {
        scaledHeight = textAreaInput.scrollHeight;

      }

      if (this.minHeight) {
        scaledHeight = Math.max(scaledHeight, this.minHeight);
      }

      textAreaInput.style.height = scaledHeight + 'px';

    }

  }

}
