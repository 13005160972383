// This class is used for requesting UUIDs.

import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

export class UUIDUtil {

  //set during login
  static uuidService: any;
  static uuids: number[] = [];

  static UUID_REQUEST_COUNT: number = 50; //number of UUIDs in each request to back end
  static UUID_LOW_THRESHOLD: number = 20; //will request more UUIDs if below this threshold

  //need to inject the service at login or in main module (after refresh)
  static setService(service) {
    this.uuidService = service;
  }

  //Request additional UUIDs from backend
  static requestUUIDs() {
    if (this.uuidService) {
      this.uuidService.getUUIDsFromServer(UUIDUtil.UUID_REQUEST_COUNT).subscribe((res: number[]) => {
        this.uuids = this.uuids.concat(res);
      });
    }
  }

  static requestNIds(n: number): Observable<boolean> {
    let idsSubject = new Subject<boolean>();
    if (this.uuidService) {
      this.uuidService.getUUIDsFromServer(n).subscribe((res: number[]) => {
        this.uuids = this.uuids.concat(res);
        idsSubject.next(true);
        idsSubject.complete();
      });
    }
    return idsSubject;
  }

  //Return a UUID for use by UI objects.
  static getUUID(): number {
    //remove first UUID from array to return
    let uuid: number = this.uuids.shift();

    //get more UUIDS if below threshold
    if (this.uuids.length < UUIDUtil.UUID_LOW_THRESHOLD) {
      UUIDUtil.requestUUIDs();
    }

    return uuid;
  }

  /*
  All entities assigning ID on frontend should have a marker property called clientAssignedIdentifierEntity.
  any: used any in input parameter because there are some old entities who don't extends baseentity so there is no common type.
   */
  static isClientSideAssignedId(obj: any): boolean {
    //to check the static property 'clientAssignedIdentifierEntity'
    return obj.constructor && (<any>obj.constructor)[ 'clientAssignedIdentifierEntity' ];
  }
}
