<div *ngIf="parentMatterParticipant && parentMatterParticipant.contact">
    <div class="main-level" *ngIf="parentMatterParticipant.contact.isMortgagee">
        <span class="title">Contact Information </span>
    </div>
    <div>
        <div *ngIf="isAttentionDisplayedAsSelect && !isAttentionDisplayedAsDefaultValue">
            <label class="control-label col-lg-2">Attention</label>
            <div class="col-lg-4" >
                <select class="form-control"
                        id="Attention"
                        name="Attention"
                        [(ngModel)]="attentionWrapper && attentionWrapper.dataModel"
                        (ngModelChange)="onSelectedAttention()">
                    <option [ngValue]=""></option>
                    <option *ngFor="let attention of attentionList" [ngValue]="attention">
                        {{attention.subContactDisplayName}}
                    </option>
                </select>
            </div>
        </div>

        <div *ngIf="isAttentionDisplayedAsShutter && !isAttentionDisplayedAsDefaultValue"
             [ngClass]="{'shutter-bg': attentionWrapper.expanded,'shutter-bg-closed': !attentionWrapper.expanded}">
            <div class="form-group">
                <div class="shutter-arrow" (click)="toggleAttentionShutter()">
                    <div *ngIf="attentionWrapper.expanded"><img src="/assets/img/minus.svg" alt="" /></div>
                    <div *ngIf="!attentionWrapper.expanded" ><img src="/assets/img/plus.svg" /></div>
                </div>
                <span
                      class="col-lg-2 control-label"
                      (click)="toggleAttentionShutter()"
                      [class.focus]="fieldHoverIndex===11">Attention</span>
                <div>
                    <div class="col-lg-10 client-purchaser fullLength-autocomplete">
                        <input type="text"
                               id="attentionWrapper.name"
                               class="form-control rightPadding482"
                               readonly="readonly"
                               (click)="toggleAttentionShutter()"
                               (keydown.f9)="toggleAttentionShutter()"
                               [value]="attentionWrapper.matterParticipant.contact.fullName"
                               fieldKey="matter.mortgagee.attention.shutter"
                               statusBarHelp
                               autocomplete="off"/>
                        <div class="actionbtns">
                            <div class="width-60">
                                <span *ngIf="attentionWrapper.isStale">
                                    <span class="text-black out-of-sync" title="old"><img src="/assets/img/old-icon-18x18.png"/> </span>
                                </span>
                            </div>
                            <div class="width-30 actionbtns">
                                <dp-burger-menu
                                                [openSubMenuOnLeft]="true"
                                                [allowPopUp]="true"
                                                [items]="attentionWrapper.getBurgerMenuItemsForMatterParticipant()"
                                                [disabledItems]="attentionWrapper.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                (itemClicked)="clickAttentionBurgerMenuItem($event, attentionWrapper)">
                                </dp-burger-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="attentionWrapper.expanded">
                <div class="form-group">
                    <div class="col-lg-12 margin-top-10">
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="attentionWrapper.isLockedElsewhere">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The attention contact is currently locked by {{attentionWrapper.lockedByUserFullName}}.
                                </p>
                            </div>
                        </div>
                        <div class="form-group-warning-message col-lg-10 offset-lg-2"
                             *ngIf="attentionWrapper.isStale && !attentionWrapper.isLockedElsewhere">
                            <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                            up-->
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{attentionWrapper.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openAttentionSourceContactTab()" class="line-height-adjust">
                                        {{attentionWrapper.outOfSyncUnderlineMsg}}</a> {{attentionWrapper.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <dp-contact-private-basic-info
                    [provinceCode]="matter?.provinceCode"
                    [isPrivateContactReadOnly]="isPrivateContactReadOnly"
                    [selectedPerson]="attentionContact"
                    [contact]="parentMatterParticipant.contact"
                    [checkDataChange]="true"
                    (onDataChange)="emitChange()"
                >
                </dp-contact-private-basic-info>
            </ng-container>
        </div>

        <div *ngIf="isAttentionDisplayedAsDefaultValue">
            <div class="form-group">
                    <span
                        class="col-lg-2 control-label"
                        [class.focus]="fieldHoverIndex===11">Attention
                    </span>
                <div>
                    <div class="col-lg-10 client-purchaser fullLength-autocomplete">
                        <input type="text"
                               id="parentMatterParticipant.attention.name"
                               class="form-control rightPadding482"
                               readonly="readonly"
                               [value]="parentMatterParticipant.attentionName"
                               autocomplete="off"/>
                        <div class="actionbtns">
                            <div class="width-30 actionbtns">
                                    <span class="trash-icon">
                                        <i class="fa fa-trash"
                                           (click)="deleteAttention()"
                                           aria-hidden="true"
                                           [title]="'Delete Attention'"
                                           tabindex="0"
                                           (keyup.enter)="deleteAttention()"
                                        >
                                        </i>
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
