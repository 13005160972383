<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="account"
      id="dp-statement-adjustment-config">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1 *ngIf="isModalOpenFromMatter() && !context.isProject">SOA Footers for Matter No. {{matterRecordNo()}}</h1>
                <h1 *ngIf="context.isProject">SOA Footers for Project No. {{projectRecordNo()}}</h1>
                <h1 *ngIf="!isModalOpenFromMatter()">Configure Statement of Adjustment Footers {{account? '- ' +
                    account.publicAccountId + ' ' + account.name : ''}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-lg-12">
            <form class="form-horizontal">
                <div class="form-group">
                    <div class="col-lg-12 text-right">
                        <input *ngIf="!isProjectOrProjectSale()"
                               type="button"
                               class="secondary-button"
                               (click)="reset()"
                               value="Reset">
                        <input
                            type="button"
                            class="secondary-button"
                            [disabled]="(selectedIndex===0 || selectedIndex === -1)"
                            (click)="moveUp()"
                            value="Move Up">
                        <input
                            type="button"
                            class="secondary-button"
                            [disabled]="(selectedIndex===(statementAdjustmentConfigList.length -1) || selectedIndex === -1)"
                            (click)="moveDown()"
                            value="Move Down">
                        <input
                            type="button"
                            class="primary-button"
                            (click)="addStatementAdjustmentConfig($event)"
                            value="Add">

                    </div>
                </div>

                <div class="view-table">
                    <div class="form-group margin-top-20">
                        <table class="table soa-adjustment-table" (click)="closeAllEditor()" sticky-table-header
                               id="sticky-table-soa" [tableHeightVariance]="'100'">
                            <thead>
                            <tr>
                                <th>
                                    Description
                                </th>
                                <th></th>
                                <th>{{context.isProjectSale ? 'Apply' : 'Condition'}}</th>
                                <th>{{context.isProjectSale ? '' : isModalOpenFromMatter() ? 'Apply' : 'Apply by
                                    Default'}}
                                </th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let key of statementAdjustmentConfigList; let i=index"
                                id="row_{{i}}"
                                (click)="rowSelected(key)"
                                [ngClass]="{'selected-active' : key.isSelected}">
                                <td (click)="stopPropogationOnEditorClick($event,key)">
                                    <div id="description_{{key.identifier}}" *ngIf="!key.showEditor"
                                         (focus)="openEditor(key)"
                                         (click)="openEditor(key)"
                                         [innerHtml]="key.description"
                                         tabindex="0"></div>
                                    <textarea name="{{key.identifier}}" id="{{key.identifier}}" rows="2" cols="80"
                                              *ngIf="key.showEditor"
                                              [ngClass]="{'selected-active' : key.isSelected}"
                                              (focus)="openEditor(key)" (click)="openEditor(key)"
                                              style="border: none;"></textarea>
                                    <div class="row padding-top-10" *ngIf="key.showEditor">
                                        <ng-container *ngIf="context.isProject">
                                            <div class="col-lg-3">
                                                <input type="button"
                                                       class="dp-btn-white"
                                                       [disabled]="false"
                                                       value="Insert Precedent"
                                                       (click)="insertPrecedent('STATEMENT_ADJUSTMENT_FOOTER',key)"/>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="button"
                                                       class="dp-btn-white "
                                                       value="Save As Precedent"
                                                       [disabled]="isEmptyDescription"
                                                       (click)="saveAsPrecedent('STATEMENT_ADJUSTMENT_FOOTER',key)"/>
                                            </div>
                                        </ng-container>
                                        <div class="col-lg-3">
                                            <ng-container *ngIf="context.isProjectSale || context.isProject">
                                                <select type="text"
                                                        id="mergeCodes-{{key.identifier}}"
                                                        name="mergeCodes-{{key.identifier}}"
                                                        (change)="onMergeCodeChange($event , key)"
                                                        class="form-control"
                                                >
                                                    <ng-template ngFor let-item [ngForOf]="mergeCodeOptions">
                                                        <optgroup *ngIf="item.subOptions" label="{{item.label}}">
                                                            <option *ngFor="let child of item.subOptions"
                                                                    [value]="child.value">
                                                                {{child.label}}
                                                            </option>
                                                        </optgroup>
                                                        <option *ngIf="!item.subOptions" [value]="item.value">
                                                            {{item.label}}
                                                        </option>
                                                    </ng-template>
                                                </select>
                                            </ng-container>
                                            <ng-container *ngIf="!context.isProjectSale && !context.isProject">
                                                <a href="https://www.doprocess.com/codes/ON-codes.pdf"
                                                   target="_blank">Merge Codes</a>
                                            </ng-container>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="width-20">
                                                <span [dp-read-only-block]="isReadOnlyFooter(key)">
                                                    <i class="fa fa-trash" (focus)="closeAllEditor()" tabindex="0"
                                                       (keydown.Enter)="closeAllEditor();deleteStatementAdjustmentConfig(key)"
                                                       (click)="closeAllEditor();deleteStatementAdjustmentConfig(key)"
                                                       aria-hidden="true"></i>
                                                </span>
                                    </div>
                                </td>
                                <td>
                                    <!-- Any Non Project footer -->
                                    <div *ngIf="!isProjectOrProjectSale()">
                                        <select class="form-control"
                                                (focus)="closeAllEditor()"
                                                [(ngModel)]="key.condition"
                                                id="condition_{{key.identifier}}"
                                                name="condition_{{key.identifier}}">
                                            <option *ngFor="let ddOption of conditionList" [value]="ddOption.value">
                                                {{ddOption.label}}
                                            </option>
                                        </select>
                                    </div>
                                    <!-- Project footer -->
                                    <div *ngIf="context.isProject">
                                        <dp-checkbox fieldId="default_pr_{{key.identifier}}"><input type="checkbox"
                                                                                                    id="default_pr_{{key.identifier}}"
                                                                                                    [(ngModel)]="key.applyByDefault"
                                                                                                    (focus)="closeAllEditor()"
                                                                                                    name="default_pr_{{key.identifier}}">
                                        </dp-checkbox>

                                        <label class="control-label vertical-align-label display-inline">Apply to New
                                            Matters</label>
                                    </div>
                                    <!-- Project Sale Matter footer -->
                                    <div *ngIf="context.isProjectSale">
                                        <select class="form-control"
                                                (focus)="closeAllEditor()"
                                                [(ngModel)]="key.footerProgressionStatusAvailability"
                                                id="available_for_{{key.identifier}}"
                                                name="available_for_{{key.identifier}}">
                                            <option *ngFor="let avlOption of availabilityList"
                                                    [value]="avlOption.value">
                                                {{avlOption.label}}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <!-- Any Non Project footer -->
                                    <div *ngIf="!isProjectOrProjectSale()">
                                        <dp-checkbox fieldId="default_{{key.identifier}}"><input type="checkbox"
                                                                                                 id="default_{{key.identifier}}"
                                                                                                 [(ngModel)]="key.applyByDefault"
                                                                                                 (focus)="closeAllEditor()"
                                                                                                 name="default_{{key.identifier}}"
                                                                                                 #applybydefault>
                                        </dp-checkbox>

                                        <label class="control-label vertical-align-label display-inline">{{isModalOpenFromMatter()
                                            ? 'Apply' : 'Apply by Default'}}</label>
                                    </div>
                                    <!-- Project footer -->
                                    <div *ngIf="context.isProject">
                                        <select class="form-control"
                                                (focus)="closeAllEditor()"
                                                [(ngModel)]="key.footerProgressionStatusAvailability"
                                                id="available_for_pr_{{key.identifier}}"
                                                name="available_for_pr_{{key.identifier}}">
                                            <option *ngFor="let avlOption of availabilityList"
                                                    [value]="avlOption.value">
                                                {{avlOption.label}}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>Save</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</form>



