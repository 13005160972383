import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs/Rx';
import {debounceTime} from 'rxjs/internal/operators';

@Directive({
  selector: '[delayedValueChange]'
})
export class DelayedValueChangeDirective {
  @Output()
  public delayedValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public valueChangeDelay = 300;

  private stream: Subject<any> = new Subject<any>();
  private subscription: Subscription;

  constructor() {
    this.subscription = this.stream.pipe(debounceTime(this.valueChangeDelay)).subscribe((value: any) => this.delayedValueChange.next(value));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:keyup', [ '$event' ])
  public onKeyup(value: any): void {
    this.stream.next(value);
  }
}
