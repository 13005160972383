<form class="form-horizontal" id="precedentForm">

    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10"><h1>{{getTitle()}}</h1></div>
                <button  type="button" class="btn-close pull-right" (click)="onCancel()">&times;</button>
            </div>
        </div>
        <div class="row modal-body">
            <div class="form-group">
                <div class="">
                    <ul class="list-group scroll-bar" #precedentList (keydown)="onKeyPress($event)"  tabindex="0">
                        <li class="list-group-item"
                            *ngFor="let precedent of precedents; let i = index"
                            (click)="selectPrecedent(precedent)"
                            [ngClass]="{'active': isSelectedPrecedent(precedent)}"
                        >
            <span>
                {{precedent.precedentName}}
            </span>

                        </li>
                    </ul>
                </div>

            </div>
            <div class="form-group" *ngIf="!isInsert()">
                <div class="">
                    <label *ngIf="!showPrecedentName()" class="control-label col-lg-2">Clause Name</label>
                    <label *ngIf="showPrecedentName()" class="control-label col-lg-2">Precedent Name</label>
                </div>
                <div class="col-lg-4">
                    <input type="text"
                           id="precedentName"
                           name="precedentName"
                           #precedentNameInput
                           [(ngModel)]="precedentName"
                           (ngModelChange)="onPrecedentNameChange()"
                           maxlength="50"
                           class="form-control"/>
                </div>
            </div>

        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 text-center">
                <button type="button"
                        [disabled]="disableOKButton"
                        class="dp-btn keyboard-save-action"
                        id="okBtn"
                        (click)="onOK()">
                    <span>OK</span>
                </button>

                <button type="button" class="dp-btn dp-btn-cancel" (click)="onCancel()">
                    <span>Cancel</span>
                </button>

                <button *ngIf="isInsert()" type="button" class="dp-btn dp-btn-cancel" [disabled]="disableDeleteBtn" (click)="onDelete()">
                    <span>Delete</span>
                </button>
            </div>
        </div>

    </div>
</form>
