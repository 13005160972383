import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {dropDowns} from '../../shared/matter-drop-downs';
import * as _ from 'lodash';

export class SoaHeat extends BaseEntity {

  id: number;
  heatType: string;
  unitMeasurementType: string;
  tankCapacity: number;
  costPerLitre: number;
  adjustType: string;
  adjustFor: string;
  percentage: number;
  carbonTaxAmount: number;
  adjustForCarbonTax: string;

  constructor(soaHeat?: SoaHeat) {
    super(soaHeat);

    if (!this.heatType) {
      this.heatType = 'GAS';
    }
    if (!this.unitMeasurementType) {
      this.unitMeasurementType = 'LITRES';
    }
    if (!this.tankCapacity) {
      this.tankCapacity = 0;
    }
    if (!this.costPerLitre) {
      this.costPerLitre = 0;
    }
    if (!this.adjustFor) {
      this.adjustFor = 'NO';
    }
    if (!this.percentage) {
      this.percentage = 0;
    }
    if (!this.carbonTaxAmount) {
      this.carbonTaxAmount = 0;
    }
    if (!this.adjustForCarbonTax) {
      this.adjustForCarbonTax = 'N_y';
    }
  }

  get creditVendor(): number {
    return this.totalNet +
      this.totalCarbonTax +
      this.tax;
  }

  get totalNet(): number {
    return Number(this.costPerLitre) * Number(this.tankCapacity);
  }

  get totalCarbonTax(): number {
    return (this.adjustForCarbonTax === 'YES') ? Number(this.carbonTaxAmount) * Number(this.tankCapacity) : 0;
  }

  get tax(): number {
    return Math.round((Number(Number(this.totalNet) + Number(this.totalCarbonTax)) * Number(this.percentage) / 100) * 100) / 100;
  }

  get heatTypeLabel(): string {
    let sv = _.find(dropDowns.heatTypes, [ 'value', this.heatType ]);
    return sv.label;
  }

  get heatMeasurementLabel(): string {
    let sv = _.find(dropDowns.heatUnitMeasurementTypes, [ 'value', this.unitMeasurementType ]);
    return sv.label;
  }

  get heatTypeShortLabel(): string {
    if (this.heatTypeLabel === 'Fuel Oil') {
      return 'oil';
    } else {
      return this.heatTypeLabel.toLowerCase();
    }
  }

  get heatMeasurementSingular(): string {
    let str: string = this.heatMeasurementLabel;
    str = str.slice(0, -1);
    return str.toLowerCase();
  }

  get noCostAdjustment(): boolean {
    return this.heatType !== 'FUEL_OIL' && this.heatType !== 'PROPANE';
  }
}
