import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../core';
import {matterApi} from '../shared';
import {Injectable} from '@angular/core';

// This this a sharable service which is used for all the matter API.
@Injectable()
export class ParticipantService {

  constructor(private httpClient: HttpClient) {
  }

  get(id: number): Observable<any> {

    return this.httpClient.get(`${ matterApi.participants }/${ id }`)
    .map((res) => {
      return res[ 'Matter' ];
    });
  }
}
