<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{getTitle()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group col-lg-12 margin-bottom-20">
            Once the matters are linked, changes of common data in one matter will automatically update the other.
        </div>
        <div class="form-group col-lg-12">
            <fieldset >
                <legend>Link {{context.matter.isPurchase ? 'Purchase':'Sale'}} Matter {{context.matter.matterRecordNumber}} to this {{context.matter.isPurchase ? 'Sale':'Purchase'}} Matter</legend>

                        <div class="form-group col-lg-12">
                            <div class="col-lg-3 vertical-align-label"><label class="control-label"
                                                                              for="localMatterNo">{{context.isNewMatter ? 'New Matter No.' : 'Matter No.'}}</label>
                            </div>
                            <div class="col-lg-6">
                                <input type="text"
                                       id="localMatterNo"
                                       name="localMatterNo"
                                       dp-error-validator
                                       [fieldKey]="'matter.matterOpening.matterNo'"
                                       [(ngModel)]="matterRecordNumber"
                                       class="form-control"
                                       maxlength="25"
                                       dp-uppercase-preserve-cursor-position
                                       dp-matterNoFilter
                                       required
                                       trimmed
                                       (focusout)="populateFileNo()"
                                />
                            </div>
                            <div class="col-lg-3" *ngIf="!context.isNewMatter">
                                <button  *ngIf="!loading" class="dp-btn dp-btn-green" [disabled]="!matterRecordNumber || matterRecordNumber.length<2"
                                        (click)="findMatter();">Find</button>
                                <div id="spinner" *ngIf="loading">
                                    <img src="assets/img/spinner.gif"/>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-lg-12">
                            <div class="col-lg-9 offset-lg-3 padding-bottom-10" *ngIf="hasFullAccessToProjectMatters() && context.matter.isPurchase">
                                Projects Note: System will exclude project sale matters as<br>
                                linking to be initiated from the project sale matter
                            </div>
                            <div class="col-lg-3 vertical-align-label"><label class="control-label" for="localFileNo">{{context.isNewMatter ? 'New File No.' :
                                'File No.'}}</label>
                            </div>
                            <div class="col-lg-6">
                                <input type="text"
                                       id="localFileNo"
                                       name="localFileNo"
                                       [(ngModel)]="fileNumber"
                                       [dp-read-only]="!context.isNewMatter"
                                       class="form-control"
                                       maxlength="50"
                                       trimmed
                                />
                            </div>
                        </div>

                        <div class="form-group col-lg-12" *ngIf="!context.isNewMatter">
                            <div class="col-lg-3 vertical-align-label"><label class="control-label" for="localClient">Client</label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text"
                                       id="localClient"
                                       name="localClient"
                                       [(ngModel)]="clientReLine"
                                       [dp-read-only]="true"
                                       class="form-control"
                                       maxlength="50"
                                       trimmed
                                />
                            </div>
                        </div>

                        <div class="form-group col-lg-12" *ngIf="!context.isNewMatter">
                            <div class="col-lg-3 vertical-align-label"><label class="control-label" for="localClosingDate">Closing Date</label>
                            </div>
                            <div class="col-lg-9">
                                <input type="text"
                                       id="localClosingDate"
                                       name="localClosingDate"
                                       [(ngModel)]="closingDate"
                                       [dp-read-only]="true"
                                       class="form-control"
                                       maxlength="50"
                                       trimmed
                                />
                            </div>
                        </div>


            </fieldset>

        </div>



    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="submit()" [disabled]="!context.isNewMatter && !linkedMatter || isSubmitBtnDisabled">
                <span>{{getOkText()}}</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
