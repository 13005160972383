import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';

export type SearchRequestType = 'DOCKET_NAME' | 'DOCKET_ID';

export class TeranetDocketRequest extends BaseEntity {
  matterId: number;
  searchType: SearchRequestType;
  searchValue: string;
  teranetUser: TeranetUser;
}
