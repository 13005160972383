import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class ChicagoTitlePortalContext {
  securedUrl: string;
}

@Component({
  selector: 'dp-chicago-title-portal-modal',
  templateUrl: 'chicago-title-portal.modal.component.html'
})
export class ChicagoTitlePortalModal extends ModalComponent<ChicagoTitlePortalContext> implements OnInit {
  chicagoTitleFileContainerUrl: SafeUrl;
  @ViewChild('chicagoFrame') chicagoFrame: ElementRef;

  constructor(
    public dialog: MatDialogRef<ChicagoTitlePortalModal>,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) context?: ChicagoTitlePortalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.chicagoTitleFileContainerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.context.securedUrl);
  }

  close(): void {
    this.dialog.close({action: 'Close'});
  }
}
