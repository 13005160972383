import {Account} from '../accounts/shared/account';
import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {DialogService} from '../../shared/dialog/dialog.service';

import {AccountService} from '../accounts/account.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {TrustAccountsService} from './trust-accounts.service';
import {TrustAccount} from './trust-account';
import * as _ from 'lodash';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {Contact} from '../../matters/shared/contact';
import {Logger} from '@nsalaun/ng-logger';
import {Address} from '../../matters/shared/address';
import {ModalF9HelpComponent} from '../../shared/modal-f9-help/modal-f9-help.component';
import {ProvinceCode} from '../accounts/shared/account-province';

class AddEditTrustAccountModalContext {
  account: Account;
  trustAccount: TrustAccount;
  configurationForProvince: ProvinceCode;
  isForm4BankAccount: boolean;
  projectId: number;
  defaultForProject: boolean = false;
}

@Component({
  selector: 'dp-add-edit-trust-account-modal-content',
  templateUrl: 'add-edit-trust-account-modal.component.html',
  providers: [ErrorService, DialogService],
  styleUrls: ['./trust-accounts.scss']
})
export class AddEditTrustAccountModal extends ModalComponent<AddEditTrustAccountModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('modalF9HelpComponent') modalF9HelpComponent: ModalF9HelpComponent;

  transitNoDirty: boolean;
  institutionNoDirty: boolean;
  bankTrustCoLink: string;
  mailingAddress: Address;
  account: Account;
  trustAccount: TrustAccount;
  isDirty: boolean = false;
  searchErrorElementKey: string = 'admin.trustAccount.mortgageeSearch';

  constructor(
    public dialog: MatDialogRef<AddEditTrustAccountModal, AddEditTrustAccountModalContext>,
    public logger: Logger,
    public errorService: ErrorService,
    public dialogService: DialogService,
    public trustAccountsService: TrustAccountsService,
    public accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) context?: AddEditTrustAccountModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.account) {
      this.account = new Account(this.context.account);
    } else {
      let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      this.accountService.getAccount(loggedUserId).subscribe((account: Account) => {
        this.account = new Account(account);
      });
    }

    if (this.context.trustAccount) {
      this.trustAccount = this.context.trustAccount;
    } else {
      this.initTrustAccount();
    }

    this.searchMortgageeByInstitutionNoAndTransitNo(false);
  }

  onInstitutionNoChange() {
    this.onChange(event);
    this.institutionNoDirty = true;
  }

  onTransitNoChange() {
    this.onChange(event);
    this.transitNoDirty = true;
  }

  onChange(event) {
    this.isDirty = true;
  }

  initTrustAccount() {
    this.trustAccount = new TrustAccount();
    this.trustAccount.provinceCode = this.context.configurationForProvince;
    // init fields here
    this.trustAccount.id = null;
    this.trustAccount.receiveMortgageFundsCode = 'INTO_TRUST_ACCOUNT';
    this.trustAccount.customerAccountId = this.getId();
    this.mailingAddress = new Address();
    this.trustAccount.projectId =
      this.context.isForm4BankAccount && this.context.projectId && this.context.projectId > 0
        ? this.context.projectId
        : null;
    // defaultForProject only required on form4 bank accounts
    if (this.context.defaultForProject && this.context.isForm4BankAccount) {
      this.trustAccount.defaultForProject = true;
    }
  }

  setDirtyFlag(dirty: boolean) {
    this.isDirty = dirty;
  }

  isRowActive(row): boolean {
    return row.active == 'true' || row.active == true;
  }

  getId(): number {
    return this.account ? this.account.id : +sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }

  public handleUnsavedChanges(): void {
    this.dialogService.confirmUnsavedChange(true).subscribe((response: any) => {
      if (response == 'DONT_SAVE') {
        this.dialog.close();
      } else if (response == 'CANCEL') {
      } else if (response == 'SAVE') {
        this.ok();
      }
    });
  }

  cancelDialog() {
    if (this.isDirty) {
      this.handleUnsavedChanges();
    } else {
      this.dialog.close();
    }
  }

  ok(): void {
    if (this.isTrustAccountValid()) {
      this.trustAccountsService.saveTrustAccount(this.getId(), this.trustAccount).subscribe(
        (res: any) => {
          console.log('saveTrustAccount, res=', res);
          this.setDirtyFlag(false);
          this.dialog.close(res);
        },
        () => {}
      );
    }
  }

  close(): void {
    this.dialog.close();
  }

  public isTrustAccountValid(): boolean {
    let valid: boolean = true;
    return valid;
  }

  public searchMortgageeByInstitutionNoAndTransitNo(checkDirty: boolean) {
    // Do search only when both institutionNumber and transitNumber are not empty
    // 1. If it is existing one, do not need to check dirty,
    // 2. If it is new one, need to check dirty.
    //    Search when institutionNumber and transitNumber are changed.
    //    Do not search when tab key navigate institutionNumber and transitNumber fields.
    if (
      this.trustAccount.institutionNumber &&
      this.trustAccount.transitNumber &&
      (!checkDirty || this.institutionNoDirty || this.transitNoDirty)
    ) {
      this.trustAccountsService
        .getMortgageeByInstitutionNoAndTransitNo(this.trustAccount.institutionNumber, this.trustAccount.transitNumber)
        .subscribe(
          (mortgagee: Contact[]) => {
            const errorMessage: string = 'No matching Institution No and Transit No found.';
            if (Array.isArray(mortgagee)) {
              if (mortgagee.length > 0) {
                if (mortgagee.length > 1) {
                  this.logger.error(
                    'Search Mortgagee by Institution No and Transit No expects only one mortgagee but got more'
                  );
                }
                this.updateFieldsValue(mortgagee[0]);
                this.modalErrorComponent.removeDpSaveError(this.searchErrorElementKey);
              } else {
                this.modalErrorComponent.createCustomDPSaveError(
                  this.searchErrorElementKey,
                  errorMessage,
                  'Trust Account',
                  'WARNING'
                );
                this.cleanMortgageeIdAndNameAndMailingAddress();
              }
            } else {
              this.modalErrorComponent.createCustomDPSaveError(
                this.searchErrorElementKey,
                errorMessage,
                'Trust Account',
                'WARNING'
              );
              this.cleanMortgageeIdAndNameAndMailingAddress();
            }
          },
          (error) => {
            this.logger.error('Search mortgagee by Institution No and Transit No', error);
          }
        );
      // Clean Dirty flag
      this.institutionNoDirty = false;
      this.transitNoDirty = false;
    }
  }

  public updateFieldsValue(mortgagee: Contact) {
    if (!mortgagee) {
      return;
    }
    this.trustAccount.mortgageeId = mortgagee.id;
    this.trustAccount.mortgageeName = mortgagee.organizationName;
    this.trustAccount.mortgageeMailingAddress = mortgagee.mailingAddress;
  }

  padInstitutionNo() {
    if (this.trustAccount.institutionNumber) {
      this.trustAccount.institutionNumber = _.padStart(this.trustAccount.institutionNumber, 3, '0');
    }
    if (this.trustAccount.institutionNumber) {
      this.searchMortgageeByInstitutionNoAndTransitNo(true);
    } else {
      this.cleanMortgageeIdNameMailingAddressErrorMessage();
    }
  }

  padTransitNo() {
    if (this.trustAccount.transitNumber) {
      this.trustAccount.transitNumber = _.padStart(this.trustAccount.transitNumber, 5, '0');
    }
    if (this.trustAccount.transitNumber) {
      this.searchMortgageeByInstitutionNoAndTransitNo(true);
    } else {
      this.cleanMortgageeIdNameMailingAddressErrorMessage();
    }
  }

  cleanMortgageeIdAndNameAndMailingAddress(): void {
    this.trustAccount.mortgageeId = null;
    this.trustAccount.mortgageeMailingAddress = new Address();
    this.trustAccount.mortgageeName = null;
  }

  cleanMortgageeIdNameMailingAddressErrorMessage(): void {
    this.trustAccount.mortgageeId = null;
    this.trustAccount.mortgageeMailingAddress = new Address();
    this.trustAccount.mortgageeName = null;
    this.modalErrorComponent.removeDpSaveError(this.searchErrorElementKey);
  }

  // F9 Capture on "Trust Account Name" field
  handleF9OnTrustAccountName() {
    this.trustAccount.trustAccountName = this.account ? this.account.firmName : null;
  }

  dynamicF9HelpTrustAccountName = () => {
    return this.dynamicF9HelpFirmName();
  };

  dynamicF9HelpFirmName(): string {
    const firmName: string = this.account ? this.account.firmName : null;
    return 'F9 = ' + firmName;
  }

  // F9 Capture on "Or by Cheque Payable to" field
  handleF9OnChequePayableTo() {
    this.trustAccount.chequePayableTo = this.account ? this.account.firmName : null;
  }

  dynamicF9HelpChequePayableTo() {
    return this.dynamicF9HelpFirmName();
  }
}
