import {Component, Inject, OnInit} from '@angular/core';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ParcelLegalDescription} from '../../shared/parcel-legal-description';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {DPError} from '../../../shared/error-handling/dp-error';
import {DpBooleanValue, DpBooleanValueTypes} from '../../shared/dp-boolean';
import {UUIDUtil} from '../../../main/uuid-util';
import {SoaCommonExpenseModal} from '../../statement-adjustment/modals/common-expense/common-expenses-soa.modal.component';
import {StatementAdjustmentExpenseTypes} from '../../statement-adjustment/statement-adjustment-constants';
import {SoaCommonExpense} from '../../statement-adjustment/model/common-expense-soa';
import {StatementAdjustment} from '../../statement-adjustment/statement-adjustment';
import {dropDowns, Matter} from '../../shared';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {EditableRollNumber} from './editable-roll-number';
import {RollNumberMatterTax} from '../../shared/roll-number-matter-tax';
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class TitleRollNumberModalContext {
  titleRollNumber: ParcelLegalDescription;
  order: number;
  isCondominium: DpBooleanValue;
  statementAdjustment: StatementAdjustment;
  matter: Matter;
  soaConsiderationTaxes: ConsiderationTaxes;
}

@Component({
  selector: 'dp-title-roll-number-modal',
  templateUrl: 'title-roll-number.modal.component.html',
  providers: [ErrorService]
})
export class TitleRollNumberModal extends ModalComponent<TitleRollNumberModalContext> implements OnInit {
  titleRollNumber: ParcelLegalDescription;
  rollNumberCurrentIndex: number = 0;
  linkedMatterTaxToBeRemoved: number[] = [];
  localRollNumbers: EditableRollNumber[] = [];
  added: number = 0;
  condoUnitTypes: SelectItem[];
  rollNumberFromOtherTitles: string[] = [];

  constructor(
    public dialog: MatDialogRef<TitleRollNumberModal>,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: TitleRollNumberModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    if (this.context.titleRollNumber) {
      this.titleRollNumber = new ParcelLegalDescription(this.context.titleRollNumber);
    } else {
      this.titleRollNumber = new ParcelLegalDescription();
      this.titleRollNumber.id = UUIDUtil.getUUID();
    }
    if (this.titleRollNumber.rollNumbers.length === 0) {
      this.titleRollNumber.rollNumbers.push('');
    }
    this.condoUnitTypes = dropDowns.condoUnitType;

    this.titleRollNumber.rollNumbers.forEach((rollNumber) => {
      let editableRollNumber = new EditableRollNumber();
      editableRollNumber.rollNumber = rollNumber;

      let rollNumberOrder = ParcelLegalDescription.getTitleRollNumberOrder(
        this.context.matter.matterPropertyWithCondo.parcelLegalDescriptions,
        rollNumber,
        this.context.titleRollNumber
      );
      let rollNumberMatterTax: RollNumberMatterTax = this.context.matter
        .matterTaxByRollNumbers()
        .find(
          (matterTaxRollNumber) =>
            matterTaxRollNumber.matterTax && matterTaxRollNumber.matterTax.rollNumberOrder === rollNumberOrder
        );
      if (rollNumberMatterTax && rollNumberMatterTax.matterTax) {
        editableRollNumber.linkedMatterTaxId = rollNumberMatterTax.matterTax.id;
      }
      this.localRollNumbers.push(editableRollNumber);
    });
  }

  ok(): void {
    this.errorService.clearAllSaveErrors();
    if (!this.titleRollNumber.parcelNumber) {
      this.errorService.addDpSaveError(DPError.createDPError('matter.propertyTeranet.titleRollNumber.titleNumber'));
    }
    if (!this.localRollNumbers) {
      this.errorService.addDpSaveError(DPError.createDPError('matter.propertyTeranet.titleRollNumber.rollNumber'));
    } else {
      this.localRollNumbers.forEach((rollNumber: EditableRollNumber, idx: number) => {
        if (rollNumber.rollNumber === '') {
          this.errorService.addDpSaveError(
            DPError.createCustomDPError(
              `matter.propertyTeranet.titleRollNumber.rollNumber.${idx}`,
              `Roll Number ${idx + 1} is required`,
              'Roll Number',
              'ERROR'
            )
          );
        }
      });
      this.titleRollNumber.rollNumbers = [];
      this.localRollNumbers.forEach((editableRollNumber) =>
        this.titleRollNumber.rollNumbers.push(editableRollNumber.rollNumber)
      );
      let duplicates: string[] = this.titleRollNumber.rollNumbers.filter(
        (rollNumber: string, idx: number) => this.titleRollNumber.rollNumbers.lastIndexOf(rollNumber) !== idx
      );
      if (Array.isArray(duplicates) && duplicates.length > 0) {
        this.errorService.addDpSaveError(
          DPError.createCustomDPError(
            `matter.propertyTeranet.titleRollNumber.rollNumber.duplicate`,
            `Duplicated roll numbers: ${duplicates.join(', ')}`,
            'Roll Number',
            'ERROR'
          )
        );
      }
      let unique: string[] = _.uniq(this.titleRollNumber.rollNumbers);
      if (
        this.context &&
        this.context.matter &&
        this.context.matter.matterPropertyWithCondo &&
        this.context.matter.matterPropertyWithCondo.parcelLegalDescriptions
      ) {
        this.context.matter.matterPropertyWithCondo.parcelLegalDescriptions
          .filter((parcelLegalDescription) => parcelLegalDescription.id !== this.titleRollNumber.id)
          .forEach((parcelLegalDescription) => {
            unique.forEach((rollNumber) => {
              if (parcelLegalDescription.rollNumbers.indexOf(rollNumber) > -1) {
                this.errorService.addDpSaveError(
                  DPError.createCustomDPError(
                    `matter.propertyTeranet.titleRollNumber.rollNumber.duplicate`,
                    `Roll Number "${rollNumber}" already added on Title Number ${parcelLegalDescription.parcelNumber}.`,
                    'Roll Number',
                    'ERROR'
                  )
                );
              }
            });
          });
      }
    }
    if (this.errorService.hasNoErrors()) {
      this.titleRollNumber.rollNumbers = [];
      this.localRollNumbers.forEach((editableRollNumber) =>
        this.titleRollNumber.rollNumbers.push(editableRollNumber.rollNumber)
      );

      this.dialog.close({
        titleRollNumber: this.titleRollNumber,
        linkedMatterTaxToBeRemoved: this.linkedMatterTaxToBeRemoved,
        added: this.added
      });
    }
  }

  cancel(): void {
    this.dialog.close({action: 'Cancel'});
  }

  isPropertyCondo(): boolean {
    return this.context.isCondominium == DpBooleanValueTypes.YES;
  }

  clickCommonElementFeesEditBtn(): void {
    if (this.context.statementAdjustment) {
      let soaCommonExpense = this.context.statementAdjustment.soaCommonExpense;
      this.dialogService.matDialogContent({
        content: SoaCommonExpenseModal,
        context: {
          closingDate: this.context.matter.getClosingDate(),
          provinceHstRateSlab: this.context.soaConsiderationTaxes,
          soaCommonExpense: soaCommonExpense,
          expenseType: StatementAdjustmentExpenseTypes.COMMON_EXPENSE,
          statementAdjustmentHeading: this.context.matter.statementOfAdjustmentHeading,
          addedFlag: false,
          currentMatter: this.context.matter,
          isMBEditBtnVisible: false
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.soaCommonExpense) {
              if (soaCommonExpense) {
                this.context.statementAdjustment.soaCommonExpense = new SoaCommonExpense(result.soaCommonExpense);
                this.context.statementAdjustment.updateFromCommonExpense(
                  this.context.matter.getClosingDate(),
                  this.context.matter.isPaysForDateOfClosingVendor
                );
              }
            }
          }
        }
      });
    }
  }

  addRollNumber(): void {
    let editableRollNumber = new EditableRollNumber();
    editableRollNumber.rollNumber = '';
    this.localRollNumbers.push(editableRollNumber);
    this.added++;
  }

  removeRollNumber(index: number): void {
    if (index > 0) {
      if (this.localRollNumbers[index].linkedMatterTaxId) {
        this.linkedMatterTaxToBeRemoved.push(this.localRollNumbers[index].linkedMatterTaxId);
      } else {
        this.added--;
      }
      this.localRollNumbers.splice(index, 1);
      this.rollNumberCurrentIndex++;
    }
  }

  trackByIdx(idx: number): number {
    return idx;
  }

  get matter(): Matter {
    return this.context.matter;
  }

  isTitleImported(parcelLegalDescription: ParcelLegalDescription): boolean {
    if (
      parcelLegalDescription &&
      parcelLegalDescription.parcelNumber &&
      this.matter &&
      this.matter.teranetDocket &&
      Array.isArray(this.matter.teranetDocket.parcelRegisters)
    ) {
      return this.matter.teranetDocket.parcelRegisters.some(
        (parcelRegister) =>
          parcelRegister.titleNumber == parcelLegalDescription.parcelNumber &&
          this.matter.matterPropertyWithCondo.importedParcelRegisterIds.some(
            (importedId) => importedId == parcelRegister.id
          )
      );
    }
    return false;
  }
}
