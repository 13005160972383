<ng-container *ngIf ="!showWizardFields">
    <div class="wrapper-padding" *ngIf="mortgage">
        <div class="form-group">
            <ng-container *ngIf="matter && !matter.isMortgage && !matter.isSale">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Is Mtg - New/Assumed<br/>/VTB/Bridge Loan?</label>
                </div>
                <div class="col-lg-4">
                    <select
                        class="form-control loanType"
                        [(ngModel)]="mortgage.loanType"
                        (change)="onMortgageLoanChange(mortgage);">
                        <option *ngFor="let item of loanTypeItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="matter && matter.isProjectSale">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'matter.mortgage.loanType'"></label>
                </div>
                <div class="col-lg-4">
                    <select
                        class="form-control loanType"
                        [(ngModel)]="mortgage.loanType"
                        (change)="onMortgageLoanChange(mortgage);">
                        <option *ngFor="let item of loanTypeItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="!matter.isSale">
                <div *ngIf="isInsuredMortgageVisible()" class="col-lg-2 vertical-align-label">
                    <label class="control-label">High Ratio Insured Mortgage?</label>
                </div>
                <div class="col-lg-4" *ngIf="isInsuredMortgageVisible()">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.insured"
                        (change)="notifyChange()">
                        <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </ng-container>
            <ng-container *ngIf="matter.isSale && !matter.isProjectSale">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label"></label>
                </div>
                <div class="col-lg-4">
                    <div class="col-lg-3 padding-left-0">
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="matter && (matter.isMortgage || matter.isSale)">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">
                        Priority after closing
                    </label>
                </div>
                <div class="col-lg-4">
                    <div class="col-lg-3 padding-left-0">
                        <select
                            class="form-control"
                            [(ngModel)]="mortgage.mortgagePriority"
                            (change)="mortgagePriorityUpdated()">
                            <option *ngFor="let item of mortgagePriorities" [value]="item.value">{{item.label}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group" >
            <ng-container *ngIf="isBlanketMortgageApplicable()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Blanket Mortgage?</label>
                </div>
                <div class="col-lg-4">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.isBlanketMortgage"
                        (change)="notifyChange()">
                        <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </ng-container>
        </div >

        <div class="blanketMortgageDisclaimer" *ngIf="isBlanketMortgageDisclaimer()">
            <label>
                <i class="fa fa-exclamation-triangle font-15 padding-right-10" aria-hidden="true"></i>
                <span>WARNING: Only the subject property information will be communicated to {{filteredTitleInsuranceCompany()}}.
                    Additional property’s information must be manually entered in the {{filteredTitleInsuranceCompany()}} Portal prior
                    to submitting the Title Insurance Order.</span>
            </label>
        </div>

        <dp-blanket-mortgage *ngIf="isBlanketMortgageApplicable() && isBlanketMortgage()" [matter]="this.matter" (onChange)="notifyChange()" [mortgage]="this.mortgage">

        </dp-blanket-mortgage>
        <div class="form-group" *ngIf="matter.isProjectSale">
            <div *ngIf="isInsuredMortgageVisible()" class="col-lg-2 vertical-align-label">
                <label class="control-label">High Ratio Insured Mortgage?</label>
            </div>
            <div class="col-lg-4" *ngIf="isInsuredMortgageVisible()">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.insured"
                    (change)="notifyChange()">
                    <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
            <ng-container *ngIf="matter.isMatterProvinceON">
                <div class="col-lg-2 vertical-align-label" *ngIf="mortgage.isLoanTypeArranged()">
                    <label class="control-label">Automatically include VTB mortgage amount in E-Reg consideration ?</label>
                </div>
                <div class="col-lg-4" *ngIf="mortgage.isLoanTypeArranged()">
                    <div class="col-lg-3 padding-left-0">
                        <select
                            class="form-control"
                            [(ngModel)]="mortgage.autoIncludeVtbMortgageAmount"
                            (change)="notifyChange()">
                            <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="form-group" *ngIf="!matter.isSale">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">{{getTypeOfMortgageelabelByMatter()}}</label>
            </div>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.mortgageeType"
                    (ngModelChange)="checkMortgageContactInfoType()">
                    <option *ngFor="let item of mortgageeTypes" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" [dp-province-field-label]="'matter.mortgage.lienHoldback'">Construction Lien Holdback?</label>
            </div>
            <div class="col-lg-1" >

                <select
                    class="form-control"
                    [(ngModel)]="mortgage.lienHoldback"
                    (change)="changeLienHoldback()">
                    <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                </select>

            </div>
        </div>
        <div class="form-group" *ngIf="!matter.isSale">
            <div class="col-lg-2 vertical-align-label" *ngIf="isActingVisible()">
                <label class="control-label">Are we acting for<br/> the {{getMortgageeOrLenderLabel()}}?</label>
            </div>
            <div class="col-lg-4" *ngIf="isActingVisible()">
                <div class="col-lg-3 padding-left-0">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.acting"
                        (ngModelChange)="checkMortgageContactInfoType()">
                        <option *ngFor="let item of yesNoItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 vertical-align-label" *ngIf="isCorrespondVisible()">
                <label class="control-label">Correspond with?</label>
            </div>
            <div class="col-lg-4" *ngIf="isCorrespondVisible()">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.mortgageCorrespondenceType"
                    (ngModelChange)="checkMortgageContactInfoType()">
                    <option *ngFor="let item of correspondenceItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <ng-container  *ngTemplateOutlet="mortgageLoanNo"></ng-container>
        <div class="form-group" *ngIf="isDateOfCommitmentVisible() && (!matter.isSale || matter.isProjectSale)">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Date of Commitment</label>
            </div>
            <div class="col-lg-4">
                <dp-partial-date #partialCommitmentDate
                                 [inputDate]="mortgage.commitmentDate"
                                 [dateParent]="mortgage"
                                 [fieldKey]="'mortgage.'+mortgageIndex+'.commitmentDate'"
                                 statusBarHelp
                                 dp-error-validator
                                 [dateFieldName]="'commitmentDate'"
                                 (dateChange)="onDateChangeCommitmentDate($event)">
                </dp-partial-date>
            </div>
            <div class="col-lg-2 vertical-align-label" *ngIf="isAutoCreateVtbMortgageAdjustmentVisible()">
                <label class="control-label">Automatically create mtg. adjustment in Statement of Adjustments?</label>
            </div>
            <div class="col-lg-1" *ngIf="isAutoCreateVtbMortgageAdjustmentVisible()">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.autoCreateVtbMortgageAdjustment"
                    (change)="autoCreateVtbMortgageAdjustmentChanged()">
                    <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Mortgage instructions received?</label>
            </div>
            <div class="col-lg-4">
                <div class="col-lg-3 padding-left-0">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.instructionsReceived"
                        (change)="notifyChange()">
                        <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Final report submitted?</label>
            </div>
            <div class="col-lg-1">
                <select
                    class="form-control"
                    [(ngModel)]="mortgage.finalReportSubmitted"
                    (change)="notifyChange()">
                    <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                </select>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Preliminary report submitted?</label>
            </div>
            <div class="col-lg-4">
                <div class="col-lg-3 padding-left-0">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.preliminaryReportSubmitted"
                        (change)="notifyChange()">
                        <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>
            <div *dpShowByProvince="'matter.mortgage.detail.requestForFundsSubmitted'">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Request for Funds submitted?</label>
                </div>
                <div class="col-lg-1">
                    <select
                        class="form-control"
                        [(ngModel)]="mortgage.requestForFundsSubmitted"
                        (change)="notifyChange()">
                        <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="isInterimEncFieldVisible() && !matter.isSale">
            <ng-container *dpShowByProvince="'matter.mortgage.detail.interimEnclosures'">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Interim Enclosures</label>
                </div>
                <div class="col-lg-10">
                    <!--It will move to style to SCSS file-->
                    <input
                        [dp-read-only]
                        class="form-control wrap-input"
                        [ngModel]="getMortgageInterimEnclosures()"
                        (keydown.f9)="openInterimEncModal()"
                        [fieldKey]="'mortgage.interimEnclosures'"
                        statusBarHelp
                        (change)="notifyChange()">
                    <button type="button"
                            id="interimEncBtn"
                            (click)="openInterimEncModal()"
                            class="button-glyph" tabIndex="-1">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </div>
            </ng-container>
        </div>
        <ng-container  *ngTemplateOutlet="mortgageeDetails"></ng-container>
        <ng-container *ngIf="matter.isSale">
            <dp-vtb-mortgagee #mortgageeComponent [matter]="matter" [mortgage]="mortgage" (onChange)="enableSave()">

            </dp-vtb-mortgagee>
        </ng-container>

        <dp-user-defined-fields [topicName]="'MORTGAGES_MORTGAGEE'" [mortgageId]="mortgage.id" *ngIf="mortgage"
                                (onChange)="enableSave()">
        </dp-user-defined-fields>

    </div>
</ng-container>
<ng-container *ngIf ="!!showWizardFields">
    <div [dp-read-only-block]="dpReadOnlyBlock" [elementRefComp]="elementRef">
        <div class="form-group form-horizontal text-right">
            <ng-container  *ngTemplateOutlet="mortgageLoanNo"></ng-container>
        </div>
        <div class="form-group form-horizontal text-right">
            <ng-container  *ngTemplateOutlet="mortgageeDetails"></ng-container>
        </div>

    </div>

</ng-container>

<ng-template #mortgageLoanNo>
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">{{getMortgageLoanNoLabel()}}</label>
        </div>
        <div [ngClass]="showWizardFields? 'col-lg-4 padding-left-8' : 'col-lg-4'">
            <input
                class="form-control"
                maxlength="35"
                [(ngModel)]="mortgage.loanNo"
                (change)="notifyChange()"
                trimmed
            />
        </div>
        <ng-container *ngIf="isConstructionLienVisible()">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" [dp-province-field-label]="'matter.mortgage.lienHoldback'">Construction Lien Holdback?</label>
            </div>
            <div class="col-lg-1" >

                <select
                    class="form-control"
                    [(ngModel)]="mortgage.lienHoldback"
                    (change)="changeLienHoldback()">
                    <option *ngFor="let item of noYesItems" [value]="item.value">{{item.label}}</option>
                </select>

            </div>
        </ng-container>
    </div>

</ng-template>
<ng-template #mortgageeDetails>
    <ng-container *ngIf="!matter.isSale">
        <dp-mortgagee #mortgageeComponent [matter]="matter" [isOpenedFromWizard]="showWizardFields" [dpReadOnlyBlock]="dpReadOnlyBlock" [mortgage]="mortgage" (onChange)="enableSave()">

        </dp-mortgagee>
    </ng-container>
</ng-template>

