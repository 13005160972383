export const matterResponseKey = {

  matters: 'Matters',

  mattersIds: 'MattersIds',

  matter: 'Matter',

  referenceMatterType: 'MATTERTYPES',

  lawClerks: 'LawClerks',

  solicitors: 'Solicitors',

  contacts: 'Contacts',

  gender: 'GENDER',

  customPickLists: 'CUSTOMPICKLISTS',

  realEstateBrokers: 'Organizations',

  surveyors: 'Organizations',

  jurisdictions: 'Jurisdictions',

  jurisdiction: 'Jurisdiction',

  registryOffices: 'RegistryOffices',

  registryOffice: 'RegistryOffice',

  telusPayoutRegistryOffices: 'TelusPayoutRegistryOffices',

  contact: 'Contact',

  person: 'Person',

  persons: 'Persons',

  templates: 'Templates',

  documentTemplateCategories: 'DocumentTemplateCategories',

  metadata: 'Metadata',

  depositsManagerList: 'ProjectDepositView',
  depositsManagerTotalAmount: 'ProjectDepositTotalAmt',
  depositsManagerTotalCount: 'ProjectDepositTotalCnt',
  depositsManagerMatterIds: 'ProjectDepositMatterIds',

  templateAdjustment: 'TemplateAdjustment',

  MortgageHoldbackConfig: 'MortgageHoldbackConfig',

  AssociatedMatterAndOpportunityCount: 'AssociatedMatterAndOpportunityCount'
};
