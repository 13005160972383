import {userAccountProfilesApi} from '../../../admin/shared/user-account-profiles-api';

import {HttpClient} from '../../../core';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AuthChallenge} from './trusted-phone-number';
import {User} from '../../../matters/shared';

@Injectable()
export class TrustedPhoneNumberService {

  constructor(private httpClient: HttpClient) {
  }

  requestAuthChallenge(authChallenge: AuthChallenge, userId?: number): Observable<any> {
    let url = userAccountProfilesApi.requestAuthChallenge;
    if (userId) {
      url = userAccountProfilesApi.requestAuthChallenge.replace('currentUser', String(userId));
    }
    return this.httpClient.post(url, authChallenge)
    .map((res) => {
      return res[ 'SUCCESS' ];
    });
  }

  verifyAuthChallenge(authChallenge: AuthChallenge): Observable<any> {
    return this.httpClient.post(userAccountProfilesApi.verifyAuthChallenge, authChallenge)
    .map((res) => {
      return res;
    });
  }

  verifyAuthChallengeAndSave(authChallenge: AuthChallenge): Observable<User> {
    return this.httpClient.post(userAccountProfilesApi.verifyAuthChallengeAndSave, authChallenge)
    .map((res: any) => {
      return new User(res[ 'User' ]);
    });
  }

  deletePhoneNumber(authPhoneId: string): Observable<any> {
    let url = userAccountProfilesApi.authPhone.replace('{authPhoneId}', authPhoneId);
    return this.httpClient.delete(url)
    .map((res) => {
      return res;
    });
  }

}

