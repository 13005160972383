import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {DdlTypeForCorrespondence, DdlTypeForLoan, DdlTypeForYesNo, DdMortgageeType, Mortgage} from '../../../shared/mortgage';
import {
  correspondenceOptions,
  correspondenceOptionsMortgageBC,
  mortgageeTypeOptions,
  mortgageeTypeOptionsBC,
  noYesOptions,
  yesNoOptions
} from '../dropdown-options';
import {PartialDateComponent} from '../../../../shared/partial-date';
import {Matter} from '../../../shared/matter';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {Subject, Subscription} from 'rxjs';
import {Logger} from '@nsalaun/ng-logger';
import {ContactService} from '../../../../shared-main/contact.service';
import {AddressTypes} from '../../../shared/address-types';
import {LegalFirmService} from '../../../../admin/accounts/legal-firm/legal-firm.service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {InterimEnclosureModal} from '../interim-enclosure/interim-enclosure.modal.component';
import {MortgageeEnclosureService} from '../../../../admin/mortgagee-enclosure/mortgagee-enclosure.service';
import {MortgageeEnclosureConfig} from '../../../../admin/mortgagee-enclosure/mortgagee-enclosure-config';
import {TabsService} from '../../../../core/tabs.service';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {GetGlobalSaveModelService} from '../../../shared/get-global-save-model.service';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {ActivatedRoute, Params} from '@angular/router';
import {CurrencyPipe} from '@angular/common';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {ContactCommandService} from '../../../../contact/contact-command.service';
import {MatterService} from '../../../matter.service';
import {MatterParticipantService} from '../../../matter-participant-service';
import {MortgageeComponent} from '../mortgagee/mortgagee.component';
import {Section} from '../../../shared/section';
import {MtgLoanTypeOptions, ProvinceBasedLoanTypeOptions} from '../../../../shared-main/province-based-dropdowns';
import {MortgageSoAdjService} from '../../../../shared-main/mortgage-so-adj.service';
import {DpBooleanValueTypes} from '../../../shared/dp-boolean';
import {MatterHoldback} from '../../../shared/advance-holdback/matter-holdback';
import {MatterCleanUpUtil} from '../../../shared/matter-utils/matter-clean-up-util';
import {constValues} from '../../../../../app/matters/shared/const-values';
import {DEFAULT_LABEL, provinceBasedFieldLabels} from '../../../../../app/shared-main/province-based-field-labels';

declare var jQuery: any;

@Component({
  selector: 'dp-mortgage-detail',
  templateUrl: 'mortgage-detail.component.html',
  styleUrls: [ './mortgage-detail.styles.scss' ],
  providers: [ CurrencyPipe ]
})
@FocusFirstElementDecorator()
export class MortgageDetailComponent implements OnInit, AfterViewInit {

  //@Input() mortgage : Mortgage;
  @Input() matter: Matter;
  @Input() activeIndex: number;
  @Output() onChange = new EventEmitter();
  @ViewChild('partialCommitmentDate') partialCommitmentDateComponent: PartialDateComponent;
  @ViewChild('mortgageeComponent') mortgageeComponent: MortgageeComponent;
  @Input() showWizardFields: boolean = false; //Used only when opened from Wizard
  @Input() dpReadOnlyBlock: boolean = false; //Used only when opened from Wizard

  public ngUnsubscribe: Subject<void> = new Subject<void>();

  yesNoItems: DdlTypeForYesNo[];
  mortgageeTypes: DdMortgageeType[];
  noYesItems: DdlTypeForYesNo[];
  loanTypeItems: DdlTypeForLoan[];
  correspondenceItems: DdlTypeForCorrespondence[];
  utils: any;
  subscription: Subscription;
  clickListener: Function;
  enclosureConfig: MortgageeEnclosureConfig;
  mortgagePriority: any[] = [];
  assignedMortgagePriority: number;

  manualEntryOfSolicitor: boolean = false;
  id: number = 0;
  contactAddressType: string = AddressTypes.solicitorAddress;

  constructor(public contactQueryService: ContactQueryService,
              public contactService: ContactService,
              public matterService: MatterService,
              public legalFirmService: LegalFirmService,
              public logger: Logger,
              public appConfig: AppConfig,
              public globalSaveModelService: GetGlobalSaveModelService,
              public dialogService: DialogService,
              public tabsService: TabsService,
              public mortgageeEnclosureService: MortgageeEnclosureService,
              public errorService: ErrorService,
              public activatedRoute: ActivatedRoute,
              public contactCommandService: ContactCommandService,
              public currencyPipe: CurrencyPipe,
              public mortgageSoAdjService: MortgageSoAdjService,
              public tabsStateService: TabsService, public elementRef: ElementRef,
              public matterParticipantService: MatterParticipantService) {
  }

  ngOnInit(): void {

    this.activatedRoute
    .params
    .subscribe((params: Params) => {
      //console.log("mortgagee.component | ngOnInit | id=", this.id);

      //setTimeout(() => {
      this.id = +params[ 'mortgageId' ];
      this.initializeState();
      //}, 100);
    });

    /*  if(this.mortgageChangeDetector)
     {
     this.mortgageChangeDetector.unsubscribe();
     }

     this.mortgageChangeDetector = this.mortgageChange.getItem$.subscribe(() => this.initializeState())'*/
  }

  checkMortgageContactInfoType(): void {
    if (!this.isCorrespondVisible()) {
      this.mortgage.mortgageCorrespondenceType = '';
    }
    if (this.mortgageeComponent) {
      this.mortgageeComponent.checkMortgageContactInfoType();
    }
    this.mortgage.iscClientNumber = null;
  }

  get mortgage(): Mortgage {
    return !!this.showWizardFields || this.id >= this.matter.mortgages.length ? this.matter.mortgages[ 0 ] : this.matter.mortgages[ this.id ];
  }

  initializeInterimDefaultEnclosures() {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.mortgageeEnclosureService.getMortgageeEnclosuresConfigForAccount(id, this.matter.provinceCode).subscribe((config: MortgageeEnclosureConfig) => {
      this.enclosureConfig = new MortgageeEnclosureConfig(config);
      this.initializeMortgageInterimDefaultEnclosures();
    });
  }

  initializeMortgageInterimDefaultEnclosures() {
    if (this.mortgage && (!this.mortgage.interimEnclosures || (this.mortgage.interimEnclosures.length === 0 && !this.mortgage.interimEnclosuresDefaultsOverridden))) {
      this.loadInterimDefaultEnclosures();
      this.mortgage.interimEnclosuresDefaultsOverridden = true;
    }
  }

  initializeState(): void {
    this.yesNoItems = yesNoOptions;
    this.noYesItems = noYesOptions;
    this.loanTypeItems = this.matter.isProjectSale ? MtgLoanTypeOptions[ this.matter.provinceCode ] : ProvinceBasedLoanTypeOptions[ this.matter.provinceCode ];
    this.mortgageeTypes = this.matter?.isMatterProvinceBC ? mortgageeTypeOptionsBC : mortgageeTypeOptions;
    this.correspondenceItems = this.matter?.isMatterProvinceBC ? correspondenceOptionsMortgageBC : correspondenceOptions;

    this.initializeInterimDefaultEnclosures();
    /** Use directDepositInstructions instead of old structure.
     *  Comment the following code because it is not used.
     *  In case it will use it so just comment
     *  In case it will use it so just comment
     */
    // if(!this.matter.excessFundsDirectDepositAccount) {
    //     this.matter.excessFundsDirectDepositAccount = new BankAccount();
    // }
    //this.logger.info("mortgagee | ngOnInit | mortgage = ", this.mortgage);

    // this.globalSaveModelService.getItem$.takeUntil(this.ngUnsubscribe).subscribe((matter: Matter) => {
    //     this.logger.info("mortgagee | globalSaveModelService.getItem$ subscription | matter = ", matter.matterRecordNumber);
    //     console.log('globalSaveModelService '+matter.matterRecordNumber)
    //     this.matter = matter;
    //     this.initializeMortgageInterimDefaultEnclosures();
    //     // this.initializeInterimDefaultEnclosures();
    //     setTimeout(() => {
    //
    //
    //     }, 100);
    // });
    if (this.partialCommitmentDateComponent && this.mortgage) {
      this.partialCommitmentDateComponent.setDate(this.mortgage.commitmentDate);
    }

    this.mortgagePriority = this.matter.availableMortgagePriority(this.mortgage ? this.mortgage.mortgagePriority : undefined);
    this.assignedMortgagePriority = this.mortgage ? this.mortgage.mortgagePriority : undefined;
    if (this.matter && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateABProjectSaleReceivedFromPurchaserItemName();
    }
    if (!this.mortgage.isBlanketMortgage) {
      this.mortgage.isBlanketMortgage = 'N_y';
    }
  }

  ngOnChanges(changes: { [ propKey: string ]: SimpleChange }): void {
    if (changes[ 'matter' ]) {
      this.loanTypeItems = this.matter.isProjectSale ? MtgLoanTypeOptions[ this.matter.provinceCode ] : ProvinceBasedLoanTypeOptions[ this.matter.provinceCode ];
    }
  }

  ngAfterViewInit(): void {
  }

  get mortgagePriorities(): any[] {
    let mortgagePriority = this.mortgage ? this.mortgage.mortgagePriority : undefined;
    if (this.assignedMortgagePriority != mortgagePriority) {
      this.mortgagePriority = this.matter.availableMortgagePriority(mortgagePriority);
      if (this.mortgage) {
        let mortgagePriorityFound = this.mortgagePriority.find(item => item.value == mortgagePriority);
        if (mortgagePriorityFound) {
          this.mortgage.mortgagePriority = mortgagePriorityFound.value;
        }

      }
      this.assignedMortgagePriority = this.mortgage ? this.mortgage.mortgagePriority : undefined;
    }
    return this.mortgagePriority;
  }

  isActingVisible(): boolean {
    return this.mortgage.loanType == 'ARRANGED' || (this.matter.isMatterProvinceBC && this.mortgage.loanType === 'NEW');
  }

  isInsuredMortgageVisible(): boolean {
    return (this.matter.isProjectSale && this.matter.isMatterProvinceAB) || (this.matter.isMatterProvinceBC) ? this.mortgage.isLoanTypeNew() : this.mortgage.isLoanTypeArranged();
  }

  isBlanketMortgageApplicable(): boolean {
    return this.matter && !this.matter.isSale && this.matter.isMatterProvinceAB && this.mortgage.isLoanTypeArranged();
  }

  isBlanketMortgage(): boolean {
    return DpBooleanValueTypes.isTrue(this.mortgage.isBlanketMortgage);
  }

  isBlanketMortgageDisclaimer(): boolean {
    return this.isBlanketMortgage() && this.matter && (this.matter.insurer === constValues.titleInsuranceCompanies.FCT || this.matter.insurer === constValues.titleInsuranceCompanies.CHICAGO
      || (this.matter.insurer === constValues.titleInsuranceCompanies.STEWART && !!this.matter.isMultipleCondoPropBlanketMortgage));
  }

  filteredTitleInsuranceCompany(): string {
    if (this.matter) {
      if (this.matter.insurer === constValues.titleInsuranceCompanies.CHICAGO) {
        return constValues.titleInsuranceCompanies.CHICAGOSHORTNAME;
      } else {
        return this.matter.insurer;
      }
    }
  }

  isDateOfCommitmentVisible(): boolean {
    return this.mortgage.loanType != 'ASSUMED' && this.mortgage.loanType != 'BACK_TO_VENDOR' && this.mortgage.loanType != 'BRIDGE';
  }

  isCorrespondVisible(): boolean {
    return this.isActingVisible() && this.mortgage.acting == 'NO';
  }

  notifyChange(): void {
    this.onChange.emit();
  };

  isNewMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('mortgages') > -1);
  }

  get activeSection(): Section {
    return this.applicableSections
      ? this.applicableSections.find(item => item.active) : undefined;
  }

  get applicableSections(): Section[] {
    return this.matter
      ? this.matter.applicableSections
      : [];
  }

  mortgagePriorityUpdated(): void {
    this.notifyChange();
    //this.matter.mortgages = _.sortBy(this.matter.mortgages, ['mortgagePriority'], ['asc']);
    this.matter.mortgages.sort((a, b) => Number(a.mortgagePriority) < Number(b.mortgagePriority) ? -1 : Number(a.mortgagePriority) > Number(b.mortgagePriority) ? 1 : 0);
    this.matter.updateTrustLedgerMatterPriorityInTemplate(this.matter.buildTrustLedgerMatterMortgageObjectTemplate());
    // this.matter.soaTrustLedgerCollection && this.matter.soaTrustLedgerCollection.sortTrustLedgerMatter();
    this.matter.updateTrustLedgerItemNameForUpdatePriority();

    if (this.isNewMortgageActive()) {
      if (this.matter.soaTrustLedgerCollection) {
        this.matter.mortgages.forEach(item => {
          if (item.isUnityNewMortgage()) {
            this.matter.soaTrustLedgerCollection.updateAutogeneratedMortgageBrokerFees(item);
          }
        });
      }
      this.matter.sortTrustLedgerMatterByPriority();
    }
    if (this.matter.isSale) {
      this.mortgageSoAdjService.rearrangeAdjustments(this.matter);
      this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter, true);
    }
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateStatementOfAccountFeeForActingOnPriorityChange();
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach(collection => {
          collection.updateStatementOfAccountFeeForActingOnPriorityChange();
        });
      }
    }
  }

  get lowestAvailablePriority(): number {
    if (this.matter.mortgages) {
      let occupiedList = this.matter.mortgages && this.matter.mortgages.length > 0 ? this.matter.mortgages.map(mortgage => {
        return mortgage.mortgagePriority;
      }) : [];
      let list = this.mortgagePriority.filter(item => occupiedList.indexOf(item.value) < 0).map(item => {
        return item.value;
      });
      ;
      return Math.min(...list);
    } else {
      return 0;
    }
  }

  onDateChangeCommitmentDate(event): void {
    this.logger.info('onDateChangeCommitmentDate, event=', event);
    if (event && event.date && this.mortgage.commitmentDate !== this.getDateToSave(event)) {
      this.notifyChange();
    }
  }

  getDateToSave(event): string {
    /*        let date : string;
            date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;
            return date;*/
    return event.rawDate;
  }

  notifySolicitorAddressChange(): void {
    this.enableSave();
  }

  // this method will enable save after change in any filed.
  enableSave(): void {
    this.notifyChange();
  }

  isInterimEncFieldVisible(): boolean {
    return this.mortgage.loanType !== 'ASSUMED' && this.mortgage.loanType !== 'BACK_TO_VENDOR';
  }

  openInterimEncModal() {
    let isCondominium: string;
    if (this.matter.matterPropertyWithCondo) {
      isCondominium = this.matter.matterPropertyWithCondo.isCondominium;
    } else {
      isCondominium = 'NO';
    }
    this.dialogService.matDialogContent({
      content: InterimEnclosureModal,
      context: {
        interimEnclosures: this.mortgage.interimEnclosures,
        isCondo: isCondominium,
        enclosureConfig: this.enclosureConfig,
        isFinal: false,
        loadDefaultEnclosures: this.getMortgageInterimEnclosures() === '' ? true : false
      },
      onFulfillment: (result: any) => {
        if (result) {
          this.mortgage.interimEnclosures = result.selectedEnclosures;
          this.enableSave();
        }
      }
    });
  }

  loadInterimDefaultEnclosures() {
    let isCondominium: string;
    if (this.matter.matterPropertyWithCondo) {
      isCondominium = this.matter.matterPropertyWithCondo.isCondominium;
    } else {
      isCondominium = 'NO';
    }
    this.mortgage.interimEnclosures = [];
    if (this.enclosureConfig && this.enclosureConfig.mortgageeEnclosureConfigItems) {
      this.enclosureConfig.mortgageeEnclosureConfigItems.forEach((enclosure) => {
        if (enclosure.interimEnclosureType === 'CONDO_AND_NON_CONDO') {
          this.mortgage.interimEnclosures.push(enclosure.id);
        } else if (enclosure.interimEnclosureType === 'CONDO') {
          if (isCondominium === 'YES') {
            this.mortgage.interimEnclosures.push(enclosure.id);
          }
        } else if (enclosure.interimEnclosureType === 'NON_CONDO') {
          if (isCondominium !== 'YES') {
            this.mortgage.interimEnclosures.push(enclosure.id);
          }
        }
      });
    }
  }

  getMortgageInterimEnclosures(): string {
    let fieldNames: string[] = [];
    if (this.enclosureConfig && this.enclosureConfig.mortgageeEnclosureConfigItems) {
      if (this.mortgage.interimEnclosures) {
        this.enclosureConfig.mortgageeEnclosureConfigItems.forEach(item => {
          if (this.mortgage.interimEnclosures.indexOf(item.id) > -1) {
            fieldNames.push(item.enclosureName);
          }
        });
      } else {
        this.enclosureConfig.mortgageeEnclosureConfigItems.forEach(item => {
          if (this.mortgage.interimEnclosures.indexOf(item.id) > -1) {
            fieldNames.push(item.enclosureName);
          }
        });
      }
    }
    if (fieldNames.length > 0) {
      return fieldNames.join('; ');
    }
    return '';
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol', '1.2-2').replace('CA', '');
    }

  }

  onMortgageLoanChange(mortgage: Mortgage) {
    if (!this.isCorrespondVisible()) {
      this.mortgage.mortgageCorrespondenceType = '';
    }
    if (!this.validateMortgageLoanChange(mortgage)) {
      return;
    }

    this.notifyChange();
    this.checkForMortgageAdvance();
    this.matter.updateStatementOfAccountForMortgage(mortgage);
    if (this.matter.isMatterProvinceMBorSK && this.matter.isPurchase) {
      this.matter.updateMortgageRegistrationFee();
    }
    this.mortgageSoAdjService.rearrangeAdjustments(this.matter);
    this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
    if (this.matter && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateABProjectSaleReceivedFromPurchaserItemName();
      this.matter.soaTrustLedgerCollection.addTrustLedgerMortgageRowForAllMortgages();
    }
  }

  validateMortgageLoanChange(mortgage: Mortgage) {
    if (this.matter && this.matter.matterLink && mortgage && mortgage.isLoanTypeBackToVendor()) {
      let message = 'Linked matters don\'t support VTB Mortgages.  To add a VTB mortgage, remove the link with matter ' + this.matter.matterLink.linkedMatterNumber + '.';
      if (this.matter.isProjectSale) {
        message = 'Linked matter don\'t support Purchasers Mortgages.';
      }
      this.dialogService.confirm('Error', message, true).subscribe(res => {
        mortgage.loanType = this.matter.isProjectSale ? (this.matter.isMatterProvinceAB ? 'NEW' : 'ARRANGED') : 'ARRANGED';
      });
      return false;
    }
    return true;
  }

  isAutoCreateVtbMortgageAdjustmentVisible(): boolean {
    return this.mortgage && this.mortgage.isLoanTypeArranged() && this.matter && this.matter.isProjectSale && this.matter.isMatterProvinceON;
  }

  autoCreateVtbMortgageAdjustmentChanged(): void {
    this.mortgageSoAdjService.rearrangeAdjustments(this.matter);
    this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
    this.notifyChange();
  }

  getMortgageHoldbacks(): MatterHoldback[] {
    if (this.matter && this.matter.holdbacks && this.matter.holdbacks.length) {
      return this.matter.holdbacks.filter(holdback => holdback.mortgageId == this.mortgage.id);
    } else {
      return null;
    }
  }

  removeMortgageAdvances(): void {
    MatterCleanUpUtil.cleanUpMortgageAdvances(this.matter, this.mortgage.id);
  }

  changeLienHoldback(): void {
    if (!this.isMassUpdateTab()) {
      this.notifyChange();
      let mortgageAdvances = this.getMortgageHoldbacks();
      if (!this.mortgage.isMortgageLienHoldback() && mortgageAdvances && mortgageAdvances.length) {
        this.dialogService.confirm('Warning', 'All Advances and Holdbacks information for this Mortgage will be removed. Do you wish to proceed?',
          false, 'Proceed')
        .subscribe((result) => {
          if (result) {
            this.removeMortgageAdvances();
          } else {
            this.mortgage.lienHoldback = DpBooleanValueTypes.YES;
            if (this.matter.isProjectSale) {
              this.mortgage.loanType = 'ARRANGED';
            }
          }
        });
      }
    }
  }

  checkForMortgageAdvance(): void {
    let mortgageAdvances = this.getMortgageHoldbacks();
    if (this.matter.isProjectSale && !this.mortgage.isLoanTypeArranged() && mortgageAdvances && mortgageAdvances.length) {
      this.mortgage.lienHoldback = DpBooleanValueTypes.N_y;
      this.changeLienHoldback();
    }
  }

  isConstructionLienVisible(): boolean {
    return this.matter.isProjectSale && (this.mortgage.isLoanTypeArranged() || this.mortgage.isLoanTypeNew());
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgageIndex: number) {
    this.matter = matter;
    this.id = mortgageIndex;
  }

  isMassUpdateTab(): boolean {
    return this.tabsStateService && this.tabsStateService.activeTab && this.tabsStateService.activeTab.isMassUpdateSubType();
  }

  getTypeOfMortgageelabelByMatter(): string {
    if (!this.matter?.isMatterProvinceBC) {
      return provinceBasedFieldLabels.get('matter.mortgage.mortgageDetail.typeofMortgagee.label', DEFAULT_LABEL);
    }
    return provinceBasedFieldLabels.get('matter.mortgage.mortgageDetail.typeofMortgagee.label', this.matter?.provinceCode);
  }

  getMortgageeOrLenderLabel(): string {
    return this.matter?.isMatterProvinceBC ?
      provinceBasedFieldLabels.get('matter.mortgageDetail.mortgagee.label', this.matter?.provinceCode) :
      provinceBasedFieldLabels.get('matter.mortgageDetail.mortgagee.label', DEFAULT_LABEL);
  }

  getMortgageLoanNoLabel(): string {
    return this.matter?.isMortgageBC || this.matter?.isPurchaseBC ?
      provinceBasedFieldLabels.get('matter.mortgageDetail.loanNo.label', this.matter?.provinceCode) :
      provinceBasedFieldLabels.get('matter.mortgageDetail.loanNo.label', DEFAULT_LABEL);
  }
}
