import {StatementAdjustmentAmountTypes, StatementAdjustmentHSTTypes} from '../statement-adjustment-constants';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class HstSalePrice extends BaseEntity {
  id: number;
  creditType: string;
  soaFormatType: string;
  isVendorSelected: boolean;
  //this is being used as the adjustment Type so added the rateType field
  hstType: string;
  calculateUsingType: string;
  adjustmentHeading: string;
  creditAmount: number;
  cost: number;
  percentage: number;
  chargeType: string;
  //Added to identify the rate Type (GST/HST/PST)
  rateType: string;

  constructor(rateType: string, hstSalePrice?: HstSalePrice) {
    super(hstSalePrice);
    this.rateType = rateType;
  }

  getCreditType(statementOfAdjustmentHeadingCreditTo?: string): string {
    if (this.creditAmount > 0) {
      let localCreditTypeKey: string = statementOfAdjustmentHeadingCreditTo
        ? statementOfAdjustmentHeadingCreditTo.toUpperCase()
        : 'PURCHASER';
      return this.creditType && this.creditType.toUpperCase() == localCreditTypeKey
        ? StatementAdjustmentAmountTypes.PURCHASER
        : StatementAdjustmentAmountTypes.VENDOR;
    }
    return StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
  }

  isHstOtherFactor(): boolean {
    return this.hstType == StatementAdjustmentHSTTypes.HST_OTHER_FACTOR;
  }

  isHstRebate(): boolean {
    return this.hstType == StatementAdjustmentHSTTypes.HST_REBATE;
  }

  isHstSalePrice(): boolean {
    return this.hstType == StatementAdjustmentHSTTypes.HST_SALE_PRICE;
  }

  isRateTypePST(): boolean {
    return this.rateType === 'PST';
  }
}
