import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {currentMatter} from '../matters/shared/current-matter';
import {Router} from '@angular/router';
import {provinceBasedFieldLabels} from './province-based-field-labels';

/**
 * Selectively enable a field only for a subset of matter types (controlled through the fieldRestrictions definition below).
 * This directive will be enhanced to take into account other matter specific information, apart from the type (province being one likely candidate).
 *
 */
@Directive({selector: '[dp-province-field-label]'})
@AutoUnsubscribe()
export class DpProvinceFieldLabel implements OnInit, AfterViewInit {

  private _provinceLabel: string;
  private _provinceCode: string;

  @Input('dp-province-field-label')
  set provinceLabel(fieldCode: string) {
    if (fieldCode) {
      this._provinceLabel = fieldCode;
      this.updateLabel();
    }

  }

  //This will be used if the field is outside of a matter
  @Input('label-province-code')
  set provinceCode(fieldCode: string) {
    if (fieldCode) {
      this._provinceCode = fieldCode;
      this.updateLabel();
    }

  }

  constructor(private el: ElementRef, private router: Router) {
  }

  ngOnInit(): void {
    //This is needed for the cases where the tabs are switched and the component tree is reused (with new matter data), we need to re-evaluate, as
    //a matter province can be different
    this.router.events
    .subscribe((event) => {
      this.updateLabel();
    });
  }

  ngAfterViewInit(): void {
    this.updateLabel();
  }

  private updateLabel(): void {

    let provinceCode = this._provinceCode ? this._provinceCode : (currentMatter && currentMatter.value && currentMatter.value.provinceCode ? currentMatter.value.provinceCode : '');

    // First check if its project sale matter and check it there exists a entry for that key for that project province
    if (currentMatter && currentMatter.value && currentMatter && currentMatter.value.isProjectSale && this._provinceLabel && provinceBasedFieldLabels[ this._provinceLabel ] && provinceCode && provinceBasedFieldLabels[ this._provinceLabel ][ provinceCode + '-P' ]) {
      this.el.nativeElement.innerHTML = provinceBasedFieldLabels[ this._provinceLabel ][ provinceCode + '-P' ];
    } else if (this._provinceLabel && provinceBasedFieldLabels[ this._provinceLabel ] && provinceCode && provinceBasedFieldLabels[ this._provinceLabel ][ provinceCode ]) {
      // if it is not project sale matter then it work normally search just by province code
      this.el.nativeElement.innerHTML = provinceBasedFieldLabels[ this._provinceLabel ][ provinceCode ];
    } else if (this._provinceLabel && provinceBasedFieldLabels[ this._provinceLabel ] &&
      provinceBasedFieldLabels[ this._provinceLabel ][ 'DEFAULT' ]) {
      // if not it check for default
      this.el.nativeElement.innerHTML = provinceBasedFieldLabels[ this._provinceLabel ][ 'DEFAULT' ];
    }
  }

  ngOnDestroy() {
  }
}
