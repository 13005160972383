<form class="form-horizontal" id="addInstrumentForm" #addInstrumentForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-10">
                <h1>Teranet Connect &trade;: Search By Instrument No.</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>

        <div class="row modal-body">
            <div class="form-group col-lg-10">
                <label class="control-label col-lg-3" for="instrumentNumber">Instrument No. *</label>
                <div class="col-lg-6">
                    <input type="text" class="form-control" name="instrumentNumber" id="instrumentNumber" maxlength="20"
                           [(ngModel)]="teranetAddInstrumentRequest.instrumentNumber"/>
                </div>
            </div>
            <div class="form-group col-lg-10">
                <label class="control-label col-lg-3" for="instrumentDesc">Instrument Description<br/>(for your reference) </label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" name="instrumentDesc" id="instrumentDesc" maxlength="100"
                           [(ngModel)]="teranetAddInstrumentRequest.instrumentDesc"/>
                </div>
            </div>

        </div>

        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="addInstrument()"
                        [disabled]="!teranetAddInstrumentRequest.instrumentNumber"
                        class="dp-btn">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="cancel()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

        <div class="col-xs-12">
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </div>
</form>
