import {Injectable} from '@angular/core';
import {HttpClient} from '../core';
import {Observable} from 'rxjs/Observable';
import {SortEffectiveDate, UserMessage} from './user-messages';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {SharedDocumentsPackage} from '../matters/document-production/shared-documents-package';
import {SESSION_STORAGE_KEYS} from '../shared';
import {ShareDeclinedMessage} from './share-declined-message';
import {Utils} from '../matters/shared';

@Injectable()
export class MessageService {

  constructor(private http: HttpClient) {
  }

  getUserMessages(userId: string): Observable<UserMessage[]> {
    let url = userAccountProfilesApi.userMessages.replace('{userId}', userId);
    //According to Backend requirment, add to `${api}/users/{userId}/messages` api
    url += '?&paginationRequired=false';
    return this.http.get(url)
    .map((response) => {
      if (response && response[ 'UserMessages' ] && response[ 'UserMessages' ].length > 0) {
        return response[ 'UserMessages' ].map(item => {
          return new UserMessage(item);
        });
      } else {
        return [];
      }
    });
  }

  //http://localhost/api/v2/users/4/messages?filterIgnoreCase=true&filter=unityMessage.messageSubject_EQ_*text for search*,unityMessage.category_IN_SYSTEM!E%5C_NEWS!BETA%5C_RELEASE,read_EQ_NULL
  // &sort=unityMessage.messageSubject%7CASC&page=1&per_page=50
  getUserSearchMessages(userId: string, query?: string, unRead?: boolean, sortQuery?: string, sortType?: string, page?: number, perPage?: number, categorySearch?: string[]): Observable<UserMessage[]> {
    let url = userAccountProfilesApi.userMessages.replace('{userId}', userId) + '?filterIgnoreCase=true';
    let sort: string = sortQuery ? sortQuery : SortEffectiveDate;
    let readFilter: string;
    let searchFilter: string = '';
    let categoryFilter: string = '';
    let filters: string[] = [];
    let pageSize: number = perPage ? perPage : 50;

    if (query) {
      let urlQuery = Utils.escapeSearchText(query);
      searchFilter = 'ANYunityMessage.messageSubject_EQ_*' + urlQuery + '*|unityMessage.message_EQ_*' + urlQuery + '*';
      filters.push(searchFilter);
    }
    if (unRead) {
      readFilter = unRead ? 'read_EQ_NULL' : '';
      if (readFilter) {
        filters.push(readFilter);
      }
    }
    if (Array.isArray(categorySearch) && categorySearch.length > 0) {
      if (!categorySearch.find(item => item == 'ALL')) {
        let urlCategorySearch = Utils.escapeSearchText(categorySearch.join('!'));
        categoryFilter = 'unityMessage.category_IN_' + urlCategorySearch;
        filters.push(categoryFilter);
      }
    }

    if (filters.length > 0) {
      url += '&filter=' + filters.join(',');
    }

    sort = Utils.parseSort(sort, sortType);
    url += '&sort=unityMessage.' + sort;
    if (page) {
      url = url + `&page=${ page }&per_page=${ pageSize }`;
    }
    return this.http.get(url)
    .map((response) => {
      if (response && response[ 'UserMessages' ] && response[ 'UserMessages' ].length > 0) {
        return response[ 'UserMessages' ].map(item => {
          return new UserMessage(item);
        });
      } else {
        return [];
      }
    });
  }

  updateUserMessage(userId: string, userMessage: UserMessage): Observable<UserMessage> {
    let url = userAccountProfilesApi.userMessagesUpdate.replace('{userId}', userId).replace('{id}', userMessage.id.toString());
    return this.http.put(url, userMessage)
    .map((response) => {
      if (response && response[ 'UserMessage' ]) {
        return new UserMessage(response);
      }
    });
  }

  getSharedPackageByNotificationMessage(userId: string, unityMessageId: string): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.sharedPackageFromUnityMessage.replace('{userId}', userId).replace('{unityMessageId}', unityMessageId);
    return this.http.get(url)
    .map((response) => {
      if (response && response[ 'SharedDocumentsPackage' ]) {
        return new SharedDocumentsPackage(response[ 'SharedDocumentsPackage' ]);
      }
    });
  }

  getSharedDocuments(sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.getSharedDocuments + sharedDocumentGuid;
    return this.http.get(url, lockScreen)
    .map((response) => {
      if (response && response[ 'SharedDocumentsPackage' ]) {
        return new SharedDocumentsPackage(response[ 'SharedDocumentsPackage' ]);
      }
    });
  }

  getLogoFirm(packageGuid: string, fileId: number): string {
    return userAccountProfilesApi.getSharedDocPackageFirmLogo
    .replace('{packageGuid}', packageGuid)
    .replace('{fileId}', fileId.toString())
    .replace('{sessionId}', sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
  }

  changeSharedDocumentsPackageStatus(url: string, sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    return this.http.put(url, lockScreen)
    .map((response) => {
      if (response && response[ 'SharedDocumentsPackage' ]) {
        return new SharedDocumentsPackage(response[ 'SharedDocumentsPackage' ]);
      }
    });
  }

  declineSharedDocumentsPackage(sharedDocumentGuid: string, message: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.declineSharedDocumentsPackage
    .replace('{packageGuid}', sharedDocumentGuid);
    let shareDeclinedMessage = new ShareDeclinedMessage();
    shareDeclinedMessage.shareDeclinedMessageText = message;

    return this.http.put(url, shareDeclinedMessage, lockScreen)
    .map((response) => {
      if (response && response[ 'SharedDocumentsPackage' ]) {
        return new SharedDocumentsPackage(response[ 'SharedDocumentsPackage' ]);
      }
    });
  }

  processSharedDocumentsPackage(sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.processSharedDocumentsPackage
    .replace('{packageGuid}', sharedDocumentGuid);
    return this.changeSharedDocumentsPackageStatus(url, sharedDocumentGuid, lockScreen);
  }

  acknowledgeDeclinedSharedDocumentsPackage(sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.acknowledgeDeclinedSharedDocumentsPackage
    .replace('{packageGuid}', sharedDocumentGuid);
    return this.changeSharedDocumentsPackageStatus(url, sharedDocumentGuid, lockScreen);
  }

  reOpenSharedDocumentsPackage(sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.reOpenSharedDocumentsPackage
    .replace('{packageGuid}', sharedDocumentGuid);
    return this.changeSharedDocumentsPackageStatus(url, sharedDocumentGuid, lockScreen);
  }

  setReceivedSharedDocumentsPackage(sharedDocumentGuid: string, lockScreen?: boolean): Observable<SharedDocumentsPackage> {
    let url = userAccountProfilesApi.setReceiveSharedDocumentsPackage
    .replace('{packageGuid}', sharedDocumentGuid);
    return this.changeSharedDocumentsPackageStatus(url, sharedDocumentGuid, lockScreen);
  }
}
