<!-- modal -->
<form class="form-horizontal">
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1>Produce ID Forms and Declarations</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <table class="standard-table"  [mfData]="rows" #mf="mfDataTable" >
            <thead>
                <tr>

                    <th><div class="tc"></div>
                        <!-- by DPPMP-14494, treat all items as selected
                        <dp-checkbox fieldId="multiSelectBox"><input type="checkbox" id="multiSelectBox" name="multiSelect" #multiSelect
                                                                     (change)="multiSelectDocuments(multiSelect.checked)"
                                                                     [checked]="context.selectedRecords.length == mf.data.length"/></dp-checkbox>
                         -->
                    </th>

                    <th>
                        <div class="tc">Name</div></th>
                    <th>
                        <div class="tc">Similar Name Decl</div></th>
                    <th>
                        <div class="tc">Party Is</div>
                    </th>
                    <th>
                        <ul>
                        <li class="tc inline-block width-30-percent" style="margin-top: 18px;">Verified By</li>
                        <li class="inline-block width-20-percent">
                            <label class="control-label" for="outputTypeSelect">Output</label>
                        </li>
                        <li class="inline-block width-50-percent">
                            <select class="form-control"
                                    id="outputTypeSelect"
                                    name="outputTypeSelect"
                                    [(ngModel)]="context.outputType">
                                <option *ngFor="let item of outputTypeOptions"
                                        [value]="item.value">
                                    {{item.label}}
                                </option>
                            </select>
                        </li>
                        </ul>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of mf.data; let i = index;">

                    <td><div class="tc"></div>
                        <!--  by DPPMP-14494
                        <div class="tc"><dp-checkbox fieldId="check_{{i}}">
                            <input type="checkbox" id="check_{{i}}" name="check" #checkboxId
                                   (change)="selectToProduce(row, checkboxId.checked)"
                                   [checked]="isSelected(row)"/>
                        </dp-checkbox></div>
                        -->
                    </td>

                    <td><div class="tc text-overflow"><label style="margin: 3px 3px !important;" >{{row.name}}</label></div></td>
                    <td><div class="tc">{{row.similarNameDeclared}}</div></td>
                    <td>{{row.partyIs}}</td>
                    <td><div class="tc">{{row.verifiedBy}}</div></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn" [class]="!enablePrintIdBtn()? 'dp-btn disabled': 'dp-btn'" (click)="printId()" [disabled]="!enablePrintIdBtn()">
                <span>Produce ID Forms</span>
            </button>
            <button type="button" [class]="!enablePrintDeclarations()? 'dp-btn disabled': 'secondary-button'" (click)="printDeclarations()"
                    [disabled]="!enablePrintDeclarations()">
                <span>Produce Declarations</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>

</form>
