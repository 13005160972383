import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {currentMatter} from '../shared/current-matter';
import {dropDowns} from '../shared/matter-drop-downs';
import {TabsService} from '../../core/tabs.service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {Compliance} from './compliance';
import {JurisdictionDepartmentsService} from '../../admin/jurisdiction-departments/jurisdiction-departments.service';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {Matter} from '../shared/matter';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import * as _ from 'lodash';
import {JurisdictionTab} from '../../contact/jurisdiction-tab';
import {JurisdictionService} from '../property-teranet/jurisdiction.service';
import {TabsComponent} from '../../shared/tabbing/tabs.component';
import {LockScreenService} from '../../core/lock-screen.service';
import {ExplicitFocusFirstHelper} from '../../shared/explicit-focus-first-helper';
import {MatterService} from '../matter.service';

declare var jQuery: any;

@Component({
  selector: 'dp-compliance',
  templateUrl: 'compliance.component.html',
  styleUrls: [
    './compliance.styles.scss'
  ]
})

@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class ComplianceComponent extends ExplicitFocusFirstHelper implements OnInit, OnDestroy {

  jurisdictionDepartments: JurisdictionDepartment[] = [];
  writeToOptions: SelectItem[] = [];
  statusOptions: SelectItem[] = [];

  constructor(public jurisdictionDepartmentsService: JurisdictionDepartmentsService,
              public tabsStateService: TabsService,
              public elementRef: ElementRef,
              public tabsService: TabsService,
              public jurisdictionService: JurisdictionService,
              public matterService: MatterService,
              public lockScreenService: LockScreenService,
              public parentTabsComponent: TabsComponent) {
    super();

  }

  ngOnInit(): void {
    this.writeToOptions = dropDowns.complianceWriteToOptions;
    this.statusOptions = dropDowns.complianceStatusOptions;
    //Checking if there are existing compliances and populating if there are none.
    if (!Array.isArray(this.matter.matterCompliances) || !this.matter.matterCompliances.length) {
      // using FocusFirstElementDecorator to detect when to add focus after jurisdictionDepartmentsService returns matterCompliances
      this.jurisdictionDepartmentsService.getDepartmentPairs(null, this.matter.provinceCode).finally(() => {
        this.triggerFocus();
      }).subscribe(
        (res: JurisdictionDepartment[]) => {
          this.jurisdictionDepartments = _.sortBy(res, [ 'departmentPriority' ]);
          //Populating compliances with default compliances for case where no jurisdiction is specified
          this.matter.matterCompliances = Compliance.jurisdictionDepartmentsToCompliances(this.jurisdictionDepartments, this.matter.matterType);
        });

    }

    if (this.matter && this.matter.hasPropertyJurisdiction()) {
      // Because we update compliance according to the latest jurisdiction data, it will enable save button.
      this.matterService.updateCompliancesWithLatestJurisdiction(this.matter);
    }
  }

  get matter(): Matter {
    return currentMatter.value;
  }

  ngOnDestroy(): void {
  }

  // this method will enable save after a change in any field.
  enableSave(): void {
    this.matter.dirty = true;
  }

  get unsatisfactoryCompliancesCount(): number {
    let count: number = 0;
    for (let compliance of this.matter.matterCompliances) {
      if (compliance.writeTo == 'YES' && (compliance.status == 'NOT_OK' || compliance.status == 'QUESTION' || !compliance.status)) {
        count++;
      }
    }

    return count;
  }

  // Open Jurisdiction Contact in a tab
  openJurisdictionContact(): void {
    if (this.matter && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.jurisdiction) {
      this.lockScreenService.lockForUpdate = true;
      this.jurisdictionService.getJurisdiction(this.matter.matterPropertyWithCondo.jurisdiction.id).finally(() => {
        this.lockScreenService.lockForUpdate = false;
      }).subscribe(jurisdiction => {
        const jurisdictionTab = JurisdictionTab.createJurisdictionTab(jurisdiction, 'main/contacts/jurisdiction');
        jurisdictionTab.isJurisdictionReadOnly = true;
        this.tabsStateService.openTab(jurisdictionTab);
        this.parentTabsComponent.orderTabsDropDownToTop(jurisdictionTab);
      });
    }
  }

  onComplianceWriteToChange(compliance: Compliance): void {
    if (compliance && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.jurisdiction && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.addOrRemoveCompliance(compliance);
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach(collection => {
          collection.addOrRemoveCompliance(compliance);
        });
      }
    }

    this.enableSave();
  }

  ngAfterViewInit() {
  }

  isMassUpdateTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isMassUpdateSubType();
  }

  isComplianceReadOnly() {
    return this.isMassUpdateTab() && this.matter && this.matter.matterPropertyWithCondo && !this.matter.matterPropertyWithCondo.jurisdiction;
  }
}
