import {DialogService} from '../shared/dialog/dialog.service';
import {FirstFocusMatterUtil} from './first-focus-matter-util';
import {ErrorService} from '../shared/error-handling/error-service';
import {Observable} from 'rxjs/Observable';
import {currentMatter} from '../matters/shared';

declare var jQuery: any;
const isFunction = fn => typeof fn === 'function';

export function FocusFirstElementDecorator() {

  let componentDialogService: any;
  let componentErrorService: any;
  let focusWaitObservable: Observable<any>;

  return function (constructor: Function) {
    const original = constructor.prototype[ 'ngAfterViewInit' ];
    constructor.prototype[ 'ngAfterViewInit' ] = function () {
      jQuery('p-autocomplete .ui-autocomplete-dropdown').attr('tabindex', '-1');
      for (let prop in this) {
        const property = this[ prop ];
        if (property && (property instanceof DialogService)) {
          componentDialogService = property;
        }
        if (property && (property instanceof ErrorService)) {
          componentErrorService = property;
        }
        if (property && (property instanceof Observable) && prop && prop.indexOf('focusWaitObservable') > -1) {
          focusWaitObservable = property;
        }
      }
      let tabService = currentMatter.tabService;
      setTimeout(() => {
        if (componentErrorService && componentErrorService.focusedErrorElement) {
          componentErrorService.focusElement();
        } else if (tabService && tabService.activeTab && tabService.activeTab.isMatter() && tabService.activeTab.lastFocusedElementMetaData
          && !!tabService.activeTab.focusedOnLastVisitedElement) {
          // if we have any lastFocusedElementMetaData available
          // And also if we have flag set to focus on that field
          // then we call util to find element and focus on that field
          tabService.activeTab.focusedOnLastVisitedElement = false;
          FirstFocusMatterUtil.findAndFocusElementUsingElementMetaData(tabService);

        } else if (focusWaitObservable) {
          focusWaitObservable.subscribe(() => {
            setTimeout(() => {
              FirstFocusMatterUtil.setFocusOnFirstAvailableElement();
            }, 300);
          });
        } else {
          FirstFocusMatterUtil.setFocusOnFirstAvailableElement();
        }
      }, 0);

      jQuery('.matter-form-area').on('focus click', 'input', function (e) {
        if (componentDialogService) {
          componentDialogService.setCurrentTarget(e.target);
        }
        if (currentMatter.tabService) {
          currentMatter.tabService.setLastFocusedElementMetaData(e.target);
        }
      });
      jQuery('.matter-form-area').on('focus', 'select', function (e) {
        if (currentMatter.tabService) {
          currentMatter.tabService.setLastFocusedElementMetaData(e.target);
        }
      });
      jQuery('.matter-form-area').on('keypress', 'button', function (e) {
        if (componentDialogService) {
          componentDialogService.setCurrentTarget(e.target);
        }
      });
      jQuery('.matter-form-area').on('click', 'a', function (e) {
        if (componentDialogService) {
          componentDialogService.setCurrentTarget(e.target);
        }
        if (currentMatter.tabService) {
          currentMatter.tabService.setLastFocusedElementMetaData(e.target);
        }
      });
      jQuery('.matter-form-area').on('click', 'span[field-code-tab-link]', function (e) {
        FirstFocusMatterUtil.setFocusOnFirstAvailableElement();
      });
      jQuery('.matter-form-area').on('click', 'button', function (e) {
        if (componentDialogService) {
          componentDialogService.setCurrentTargetByPenButton(e.currentTarget);
        }
      });
      isFunction(original) && original.apply(this, arguments);
    };
  };
}
