import {EFormType} from './alto-eform-request';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class AltoEFormDocumentType extends BaseEntity {

  eFormType: EFormType;

  documentType: string;

  description: string;
}

export class AltoEFormDocument extends BaseEntity {

  altoEFormDocumentType: AltoEFormDocumentType;
  isSelected: boolean;
  quantity: number;
}
