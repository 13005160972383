import {Contact} from '../../matters/shared/contact';
import {ErrorService} from '../../shared/error-handling/error-service';
import {User} from '../../matters/shared/user';
import {AccessProfile} from './access-profile';
import {UserTrustAccount} from './user-trust-account';
import {DPError} from '../../shared/error-handling/dp-error';
import {CaslInfo} from '../../matters/shared/casl-info';

export type TokenStatus = 'NEW' | 'EXPIRED' | 'PASSWORD_PROVIDED' | 'COMPLETED';

export class StaffProfiles {
  constructor(staffProfiles?: StaffProfiles) {
    if (staffProfiles) {
      for (let prop in staffProfiles) {
        if (staffProfiles.hasOwnProperty(prop)) {
          this[ prop ] = staffProfiles[ prop ];
        }
      }

      if (staffProfiles.user) {
        this.user = new User(staffProfiles.user);
      }
      if (staffProfiles.accessProfile) {
        this.accessProfile = new AccessProfile(staffProfiles.accessProfile);
      }
      if (staffProfiles.contact) {
        this.contact = new Contact(staffProfiles.contact);
      }
      if (staffProfiles.caslInfo) {
        this.caslInfo = new CaslInfo(staffProfiles.caslInfo);
      } else {
        this.caslInfo = new CaslInfo();
      }

      if (staffProfiles.contact && Array.isArray(staffProfiles.contact.trustAccounts)) {
        this.contact.trustAccounts = [];
        staffProfiles.contact.trustAccounts.forEach((item) => {
          this.contact.trustAccounts.push(new UserTrustAccount(item));
        });
      }
    }

    // this.isDirty = false;
  }

  id: number;
  accessProfileId: number;
  accessProfileType: string;
  accessProfileName: string;
  accessProfile: AccessProfile;
  contact: Contact;
  contactStatus: string; //This has the same value as contact.activeFlag
  customerAccountId: string;
  role: string;
  // status : string;
  lastUpdatedTimeStamp: string;
  user: User;
  userId: string; // This is loginId
  dppmUserId: number;
  userName: string;
  userStatus: string;

  documentProfileName: string;
  caslInfo: CaslInfo;
  lastAccessedDate: Date;

  tokenStatus: TokenStatus;
  contactId: number;

  isValid(errorService: ErrorService, checkContactOnly?: boolean, newProfile?: boolean, isCaslInfoDirty?: boolean): boolean {
    errorService.clearAllSaveErrors();
    if (this.contact) {
      if (!this.contact.contactType) {
        errorService.addDpSaveError(DPError.createDPError('admin.staffProfile.businessRole'));
      }
      if (!this.contact.contactName || !this.contact.contactName.lastName) {
        errorService.addDpSaveError(DPError.createDPError('admin.staffProfile.lastName'));
      }
      if (!this.contact.contactName || !this.contact.contactName.firstName) {
        errorService.addDpSaveError(DPError.createDPError('admin.staffProfile.firstName'));
      }
      if (!this.contact.email) {
        errorService.addDpSaveError(DPError.createDPError('admin.staffProfile.email.MISSING'));
      }

      if (this.contact.contactType && (this.contact.contactType === 'LAWCLERK' || this.contact.contactType === 'SOLICITOR') && !this.contact.initials) {
        errorService.addDpSaveError(DPError.createDPError('admin.staffProfile.initials'));
      }

      /// validate commisioner expiry with date

      if (this.contact.contactType && this.contact.contactProvinceCapacities && (this.contact.contactType === 'LAWCLERK')) {
        this.contact.contactProvinceCapacities.forEach((e, i) => {
          if (e.provinceCapacity == 'COMMISSIONER' && (!e.commissionExpiry || e.commissionExpiry == '//')) {
            errorService.addDpSaveError(DPError.createDPError('admin.staffprofiles.personaldetails.commissionerDate.MISSING', 'commissionerDate_' + i));
          }

        });
      }

    }

    if (!checkContactOnly && this.user) {
      if (!this.user.loginId) {
        errorService.addDpSaveError(DPError.createDPError('user.profile.id'));
      }
      if (!this.user.accessProfileId) {
        errorService.addDpSaveError(DPError.createDPError('user.profile.accessProfileId'));
      }
      if (!this.user.defaultProvinceCode || this.user.defaultProvinceCode === 'QUESTION') {
        errorService.addDpSaveError(DPError.createDPError('admin.staffProfile.contact.defaultProvinceCode'));
      }
      this.validateDocumentProfileInUserProvince(errorService, this.user);
      // if(!this.user.contactId) {
      //     errorService.addDpSaveError(DPError.createDPError('user.profile.contactId'));
      // }
      // if(!this.misc.userType) {
      //     errorService.addDpSaveError(DPError.createDPError('user.profile.userType'));
      // }
    }

    // if (!newProfile || isCaslInfoDirty ){
    //    if (!this.caslInfo || (this.caslInfo.caslEmailOptOut && this.caslInfo.caslRequestedById === 0 && !this.caslInfo.caslRequestedByName)) {
    //     errorService.addDpSaveError(DPError.createDPError('caslInfo.caslRequestedById.MISSING'));
    //     console.log('error');
    //     }
    // }

    if (this.caslInfo && this.caslInfo.caslEmailOptOut !== undefined && this.caslInfo.caslEmailOptOut !== null && !this.caslInfo.caslRequestedById) {
      errorService.addDpSaveError(DPError.createDPError('caslInfo.caslRequestedById.MISSING'));
    }

    return errorService.hasNoErrors();
  }

  validateDocumentProfileInUserProvince(errorService: ErrorService, user: User) {
    if (user && user.userProvinces) {
      user.userProvinces.forEach(function (userProvince, index) {
        if (userProvince.enabled && !userProvince.documentProfileId) {
          errorService.addDpSaveError(DPError.createCustomDPError('admin.staffProfile.user.documentProfile.' + index,
            'Document Profile has not been specified for ' + userProvince.provinceName,
            'Staff Profile', 'ERROR'));

        }
      });
    }

  }

  get surnameLastFullName(): string {
    const splitedName = this.userName ? this.userName.split(', ') : [];
    return `${ splitedName[ 1 ] || '' } ${ splitedName[ 0 ] || '' }`;
  }

  get firstNameLastNameShort(): string {
    const splitedName = this.userName ? this.userName.split(', ') : [];
    return `${ splitedName[ 1 ] || '' } ${ splitedName[ 0 ] ? splitedName[ 0 ].charAt(0) : '' }`;
  }

  get isLawyer(): boolean {
    return this.role == 'SOLICITOR';
  }

  get isUserActive(): boolean {
    return this.userStatus == 'ACTIVE';
  }

  get isContactActive(): boolean {
    return this.contactStatus == 'Y_n' || this.contactStatus == 'Y/n' || this.contactStatus == 'YES';
  }
}
