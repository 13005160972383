import {ErrorDirectiveMapping} from './error-directive-mapping';

export type ErrorType = 'WARNING' | 'ERROR' | 'NOTIFICATION';
export type NotificationType = 'STEWART_ASSYST' | 'STEWART_TITLE';

export class DPError {

  errorType: ErrorType;
  errorTopic: string;
  errorMessage: string;
  errorElementKey: string;
  errorIndex: number;
  fieldId: string;
  fieldCode: string;
  containerId: string;
  mortgageId: number;
  groupId: string;
  fieldIndexId: string;
  notificationType: NotificationType;
  errorOrderId: number;
  //related bubble notification
  bubbleNotificationId: string;
  showBubbleNotification: boolean = true;

  //This is to pass any supplementary data required for error or notification, for example emp mortgage for download documents
  supplementaryData: any;

  isInnerHTML: boolean = false;

  constructor(dpError?: DPError) {

    if (dpError) {
      for (let prop in dpError) {
        if (dpError.hasOwnProperty(prop)) {
          this[ prop ] = dpError[ prop ];
        }
      }
    }
  }

  static createDPError(errorElementKey: string, fieldId?: string, containerId?: string, fieldCode?: string, fieldIndexId?: string, mortgageId?: number, groupId?: string, showBubbleNotification: boolean = true): DPError {
    let error: DPError = new DPError();
    let errKey = errorElementKey;
    let errorDef = ErrorDirectiveMapping[ errKey ];
    if (!errorDef) {
      // errKey = errKey + '.INVALID';
      errorDef = ErrorDirectiveMapping[ errKey + '.INVALID' ];
      if (errorDef) {
        errKey = errKey + '.INVALID';
      }
    }
    if (!errorDef && errorElementKey) {
      let errorKey = errorElementKey.replace(/\.-?\d*\./g, '.#.');
      errorDef = ErrorDirectiveMapping[ errorKey ];
    }
    if (errorDef) {
      let errorDirectiveMapping = errorDef;
      error.errorMessage = errorDirectiveMapping.ERROR_MESSAGE;
      error.errorElementKey = errKey;
      if (fieldId) {
        error.errorElementKey += '_' + fieldId;
      }
      error.errorTopic = this.getErrorTopic(errorDirectiveMapping.ERROR_TOPIC, errorElementKey);
      error.fieldId = fieldId;
      error.containerId = containerId;
      error.fieldIndexId = fieldIndexId;
      error.mortgageId = mortgageId;
      error.groupId = groupId;
      error.errorType = errorDirectiveMapping.ERROR_TYPE ? errorDirectiveMapping.ERROR_TYPE : 'ERROR';
      //console.log('Created errror for ', errorDef, errKey);

    }
    error.fieldCode = fieldCode;
    error.showBubbleNotification = showBubbleNotification;
    return error;
  }

  static getErrorTopic(errorTopic: string, errorElementKey: string): string {
    if (errorTopic && errorTopic.indexOf('#') > -1) {

      let matchString: string[] = errorElementKey.match(/\.\d*\./g);
      if (errorTopic.indexOf('#s') > -1 && matchString && matchString.length > 0) {
        let index = matchString[ 0 ].replace(/\./g, '');
        let ones = +index % 10, tens = +index % 100 - ones;
        return errorTopic.replace('#s', index + [ 'th', 'st', 'nd', 'rd' ][ tens === 10 || ones > 3 ? 0 : ones ]);
      } else if (errorTopic.indexOf('#') > -1 && matchString && matchString.length > 0) {

        return errorTopic.replace('#', matchString[ 0 ].replace(/\./g, ''));
      }
    }
    return errorTopic;
  }

  static createCustomDPError(errorElementKey: string, errorMessage: string, errorTopic: string, errorType: ErrorType,
                             errorIndex?: number, fieldId?: string, fieldCode?: string, fieldIndexId?: string, mortgageId?: number,
                             groupId?: string, containerId?: string, supplementaryData?: any, isInnerHTML: boolean = false): DPError {
    let error: DPError = new DPError();
    error.errorMessage = errorMessage;
    error.errorElementKey = errorElementKey;
    error.errorTopic = errorTopic;
    error.errorIndex = errorIndex;
    error.fieldId = fieldId;
    if (fieldId) {
      error.errorElementKey += '_' + fieldId;
    }
    error.errorType = errorType ? errorType : 'ERROR';
    error.fieldIndexId = fieldIndexId;
    error.mortgageId = mortgageId;
    error.groupId = groupId;
    error.fieldCode = fieldCode;
    error.containerId = containerId;
    error.supplementaryData = supplementaryData;
    error.isInnerHTML = isInnerHTML;
    return error;
  }

  setOrder(errorOrderId: number) {
    this.errorOrderId = errorOrderId;
  }

  getErrorMessage() {
    this.errorMessage = this.errorIndex > 0 ? this.errorMessage.replace('#', this.errorIndex.toString()) : this.errorMessage;
    console.log(this.errorIndex + '  ' + this.errorMessage);
    return this.errorMessage;
  }

  isTypeError(): boolean {
    return this.errorType == 'ERROR';
  }
}


