import {Directive, ElementRef, Input} from '@angular/core';
import {CirfConfigService} from './cirf-config.service';

@Directive({selector: '[dp-cirf-field-visibility]'})
export class DpCirfFieldVisibilityDirective {

  isUdf: boolean;
  configKey: string;
  fieldValue: string;

  constructor(
    private elementRef: ElementRef,
    private cirfConfigService: CirfConfigService) {
  }

  @Input('dp-cirf-field-visibility')
  set fieldVisibility(fieldConfig: any) {
    if (fieldConfig) {
      this.configKey = fieldConfig.configKey;
      this.fieldValue = fieldConfig.fieldValue;
      this.isUdf = fieldConfig.isUdf;
      this.setVisibility();
    }
  }

  private setVisibility() {
    if (this.cirfConfigService) {
      if (this.isUdf) {
        if (this.cirfConfigService.isUDFHidden(this.configKey) && !this.fieldValue) {
          this.elementRef.nativeElement.style.display = 'none';
        }
      } else {
        if (this.cirfConfigService.isFieldHidden(this.configKey) && !this.fieldValue) {
          this.elementRef.nativeElement.style.display = 'none';
        }
      }
    }
  }
}
