<div class="form-horizontal col-lg-12">
    <div class="fields-group" *ngIf="isHeaderVisible()">
        <div class="main-level">
            <span class="title">Additional Details</span>
        </div>
        <div class="xpath-parent-container">
            <div *ngFor="let udf of matter.getProvinceAwaredUserDefinedFields(topicName, mortgageId) let i=index">
                <div class="form-group margin-bottom-10  xpath-child-container" *ngIf="isUdfVisible(udf)">
                    <div class="col-lg-3 vertical-align-label">

                        <label class="control-label trim-at-160">
                            <span title="{{udf.fieldName}}">{{udf.fieldName}}</span>
                        </label>

                    </div>
                    <div class="col-lg-9">
                        <input type="text"
                               id="udfValue_{{i}}"
                               name="udfValue_{{i}}"
                               class="form-control"
                               [(ngModel)]="udf.fieldValue"
                               (ngModelChange)="onFieldValueChange(udf)"
                               maxlength="240"
                               [disabled]="matter.locked"
                               (change)="emitChange()"
                               trimmed
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
