import moment from 'moment';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {StatementAdjustmentAmountTypes, StatementAdjustmentExpenseTypes} from '../statement-adjustment-constants';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {CondominiumExpense} from '../../property-teranet/unit-level-plan/condominium-expense';

export type CommonExpensePaidType = 'YES' | 'NO';

export class SoaCommonExpense extends BaseEntity {
  id: number;
  commonExpenseAmount: number;
  isCommonExpensePaid: string;
  commonExpenseAdjustedFor: string;
  isCommonExpenseAdjusted: CommonExpensePaidType;
  adjustPercentage: string;
  expenseType: string;
  adjustmentFormat: string;
  usePotlCommonExpenseAmount: string;

  constructor(sce?: SoaCommonExpense) {
    super(sce);
    if (!sce) {
      this.usePotlCommonExpenseAmount = 'N_y';
    }
  }

  get isExpenseAdjusted(): boolean {
    return (this.isCommonExpenseAdjusted === 'YES' ? true : false);
  }

  get isExpensePaid(): boolean {
    return (this.isCommonExpensePaid === 'YES');
  }

  get vendorPurchaserLabel(): string {
    return (this.isCommonExpensePaid === 'YES' ? 'Paid by Vendor for month of ' : 'To be paid by Purchaser for month of ');
  }

  // ToDo clean this after regression testing is done for POTL Common Expense, Common Expense and Occupancy Fees
  // not valid as it's not using the settings from matter on "purchaser" and "vendor" naming
  // creditType(closingDate : string): string{
  //     if(this.getElapsedDays(closingDate) == 0 && this.isCommonExpensePaid === 'NO'){
  //         return StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
  //     }else{
  //         if(this.isOccupancyCommonExpense()){
  //             return this.isCommonExpensePaid === 'YES' ? 'Purchaser' : 'Vendor';
  //         }else{
  //             return this.isCommonExpensePaid === 'YES' ? 'Vendor' : 'Purchaser';
  //         }
  //     }
  // }

  adjustmentCreditType(closingDate: string, isPaysForDateOfClosingVendor?: boolean): string {
    if (this.getElapsedDays(closingDate, isPaysForDateOfClosingVendor) == 0 && this.isCommonExpensePaid === 'NO') {
      return StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
    } else {
      if (this.isOccupancyCommonExpense()) {
        return this.isCommonExpensePaid === 'YES' ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR;
      } else {
        return this.isCommonExpensePaid === 'YES' ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
      }
    }
  }

  appliedTaxRate(considerationTaxes: ConsiderationTaxes): string {
    return this.isTaxRateGst(considerationTaxes) ? 'GST' : considerationTaxes.rateType;
  }

  isTaxRateGst(considerationTaxes: ConsiderationTaxes): boolean {
    return (considerationTaxes.provinceDefaultTaxRate == 'GST');
  }

  appliedRate(considerationTaxes: ConsiderationTaxes): number {
    return considerationTaxes.hstRate;
  }

  appliedFederalRate(considerationTaxes: ConsiderationTaxes): number {
    return considerationTaxes.hstFederalPortion;
  }

  calculateEstimatedTax(): number {
    if (this.commonExpenseAmount && this.isExpenseAdjusted && this.adjustPercentage) {
      let tax: number = Number(Number(Number(this.commonExpenseAmount) * Number(this.adjustPercentage)) / 100);
      return Number(tax.toFixed(2));
    } else {
      return 0;
    }

  }

  calculateVendorShareDifference(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {

    if (this.adjustmentCreditType(closingDate, isPaysForDateOfClosingVendor) === StatementAdjustmentAmountTypes.VENDOR) {
      return this.isOccupancyCommonExpense() ? (this.calculateVendorShare(closingDate, isPaysForDateOfClosingVendor)) : (Number(this.commonExpenseAmount) + this.calculateEstimatedTax() - this.calculateVendorShare(closingDate, isPaysForDateOfClosingVendor));
    } else if (this.adjustmentCreditType(closingDate, isPaysForDateOfClosingVendor) === StatementAdjustmentAmountTypes.PURCHASER) {
      return this.isOccupancyCommonExpense() ? (Number(this.commonExpenseAmount) + this.calculateEstimatedTax() - this.calculateVendorShare(closingDate, isPaysForDateOfClosingVendor)) : (this.calculateVendorShare(closingDate, isPaysForDateOfClosingVendor));
    } else {
      return 0;
    }
  }

  calculateVendorShare(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    let elapsedDays = this.getElapsedDays(closingDate, isPaysForDateOfClosingVendor);
    let numberOfDays = this.numberOfDaysInMonth(closingDate);
    if (elapsedDays && !isNaN(elapsedDays) && closingDate && this.commonExpenseAmount) {
      return Number(Math.round((Number(Number(elapsedDays) / Number(numberOfDays)) * (Number(this.commonExpenseAmount) + this.calculateEstimatedTax())) * 100) / 100);
    } else {
      return 0;
    }
  }

  getElapsedDays(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    if (closingDate) {
      if (isPaysForDateOfClosingVendor) {
        return moment(closingDate, 'YYYY/MM/DD').date();
      } else {
        return moment(closingDate, 'YYYY/MM/DD').date() - 1;
      }
    } else {
      return 0;
    }
  }

  getElapsedDaysLabel(closingDate: string, isPaysForDateOfClosingVendor?: boolean): string {
    if (!closingDate) {
      return '???';
    }

    let elapsedDays = this.getElapsedDays(closingDate, isPaysForDateOfClosingVendor);
    if (isNaN(elapsedDays)) {
      return '???';
    } else {
      return elapsedDays.toString();
    }
  }

  numberOfDaysInMonth(closingDate: string): number {
    return moment(closingDate, 'YYYY/MM/DD').daysInMonth();
  }

  commonExpensePaidLabel(closingDate: string): string {
    if (closingDate) {
      return moment(closingDate, 'YYYY/MM/DD').format('MMMM, YYYY');
    } else {
      return '???????';
    }
  }

  isStandardCommonExpense(): boolean {
    return (this.expenseType == StatementAdjustmentExpenseTypes.COMMON_EXPENSE);
  }

  isPOTLCommonExpense(): boolean {
    return (this.expenseType == StatementAdjustmentExpenseTypes.POTL_COMMON_EXPENSE);
  }

  isOccupancyCommonExpense(): boolean {
    return (this.expenseType == StatementAdjustmentExpenseTypes.OCCUPANCY_FEES);
  }

  getCommonExpensePaidLabel(vendorLabel: string, purchaserLabel: string, cmmnExpensePaidDateLabel: string, isOccupancyFeeSoa: boolean): string {
    let label = '';
    if (isOccupancyFeeSoa) {
      if (this.isCommonExpensePaid === 'YES') {
        label = `Paid by ${ purchaserLabel } for month of `;
      } else {
        label = `Not paid by ${ purchaserLabel } for month of `;
      }
    } else {
      if (this.isCommonExpensePaid === 'YES') {
        label = `Paid by ${ vendorLabel } for month of `;
      } else {
        label = `To be paid by ${ purchaserLabel } for month of `;
      }
    }
    return `${ label } ${ cmmnExpensePaidDateLabel }`;
  }

  getCommonShareLabel(vendorLabel: string, purchaserLabel: string, isOccupancyFeeSoa: boolean): string {
    return isOccupancyFeeSoa ? purchaserLabel : (this.isCommonExpensePaid === 'YES') ? vendorLabel : purchaserLabel;
  }

  isFormatOfAdjustmentItemize(condominiumExpenses): boolean {
    let numberOfExpenses: number = condominiumExpenses.filter(item => item.condominiumExpense > 0).length;
    return (numberOfExpenses > 1 && this.adjustmentFormat == 'ITEMIZE_IF_MORE_THAN_ONE_UNIT');
  }

  getUnitLevelExpenses(condominiumExpenses): CondominiumExpense[] {
    return condominiumExpenses.filter(item => item.condominiumExpense > 0);
  }

}
