import {IGNORE_FIELDS} from '../../shared-main/constants';

export function Ignore(cls: any, name: string) {
  let clsName = cls.constructor.name;
  let list: string[];

  if (IGNORE_FIELDS.has(clsName)) {
    list = IGNORE_FIELDS.get(clsName);
  } else {
    list = [];
    IGNORE_FIELDS.set(clsName, list);
  }

  list.push(name);
}
