import {SoAdjWithTax} from './so-adj-with-tax';
import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class SoAdjPurchasePricePercentage extends SoAdjWithTax {
  id: number;
  creditType: string;
  adjustmentHeading: string;
  percent: number;
  percentOf: string;
  percentToMaxOf: number;
  creditAmount: number;
  items: SoAdjPurchasePricePercentageItem[] = [];

  constructor(copyFrom?: SoAdjPurchasePricePercentage) {
    super(copyFrom);

    if (copyFrom && Array.isArray(copyFrom.items)) {
      this.items = copyFrom.items.map(cpy => {
        return new SoAdjPurchasePricePercentageItem(cpy);
      });
    }
  }

  getTotalAmount(): number {
    return this.creditAmount + this.getHstOrGstValueForAmount(this.creditAmount) + this.getPstValueForAmount(this.creditAmount);
  }

}

export class SoAdjPurchasePricePercentageItem extends BaseEntity {
  heading: string;

  constructor(copyFrom?: SoAdjPurchasePricePercentageItem) {
    super(copyFrom);
  }
}

