import moment from 'moment';
import {SoaRealtyTaxAdjustmentUtil} from '../realty-tax/soa-realty-tax-adjustment-util';
import {Matter} from '../../../shared';

export class TaxesPaidDuringOccupancyUtil {

  static calculateDateDiffForTaxPortion(matter: Matter): string {
    // using final closing date becuase this is for a final adjustment only
    let closingDate: string = matter.getFinalClosingDate();
    let occupancyDate: string = this.getOccupancyDate(matter);
    if (closingDate && occupancyDate &&
      (moment(closingDate, 'YYYY/MM/DD') > moment(occupancyDate, 'YYYY/MM/DD'))) {
      let numofDays: number = moment(closingDate, 'YYYY/MM/DD').diff(moment(occupancyDate, 'YYYY/MM/DD'), 'days');
      return String(numofDays);
    } else {
      return '???';
    }

  }

  static getOccupancyDate(matter: Matter): string {
    return SoaRealtyTaxAdjustmentUtil.getOccupancyDateForRealtyTax(matter);
  }

  //the taxPortion value is year-based instead of month-based
  static calculateShareForTaxPortion(taxPortion: number, matter: Matter): number {
    if (this.calculateDateDiffForTaxPortion(matter) != '???' && taxPortion > 0) {
      return ((1 / 365) * taxPortion) * Number(this.calculateDateDiffForTaxPortion(matter));
    } else {
      return 0;
    }
  }

}
