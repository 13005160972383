import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {RegistryOfficeService} from './registry-office.service';
import {RegistryOffice} from '../../shared/jurisdiction';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class RegistryOfficeDialogContext {}

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-registry-office-dialog',
  templateUrl: 'registry-office.component.html',
  styleUrls: ['./registry-office.scss'],
  providers: [RegistryOfficeService]
})
export class RegistryOfficeDialogComponent
  extends ModalComponent<RegistryOfficeDialogContext>
  implements OnDestroy, OnInit
{
  pageNo: number = 1;
  tableRegOfficeData: RegistryOffice[] = [];
  getRegistryOfficeSubscription: any;
  selectedRegistryOffice: RegistryOffice[] = [];
  public pageSize: number = 50;
  public listEndReached: boolean = false;
  public loading: boolean = false;

  constructor(
    public dialog: MatDialogRef<RegistryOfficeDialogComponent>,
    public registryOfficeService: RegistryOfficeService,
    @Inject(MAT_DIALOG_DATA) context?: RegistryOfficeDialogContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.lookupRegistryOffice();
  }

  lookupRegistryOffice(): void {
    if (this.getRegistryOfficeSubscription) {
      this.getRegistryOfficeSubscription.unsubscribe();
      this.loading = false;
    }
    this.loading = true;
    (this.getRegistryOfficeSubscription = this.registryOfficeService
      .getAllRegistryOffices(this.context['matter'].provinceCode)
      .subscribe((response: any) => {
        if (response) {
          this.tableRegOfficeData = this.tableRegOfficeData.concat(response);

          if (response.length < this.pageSize) {
            this.listEndReached = true;
          } else {
            this.listEndReached = false;
            this.incrementPage();
          }
        } else {
          this.listEndReached = true;
        }
        this.loading = false;
      })),
      (error) => console.log(),
      () => console.log('completed');
  }

  ngOnDestroy(): void {
    this.closeDialog();
  }

  cancelDialog() {
    this.dialog.close();
  }

  closeDialog() {
    this.dialog.close({
      selectedRegistryOffice: this.selectedRegistryOffice
    });
  }

  incrementPage(): void {
    this.pageNo = this.pageNo + 1;
  }

  getNextPage(): void {
    console.log('next page');
    if (!this.listEndReached) {
      this.lookupRegistryOffice();
    }
  }

  isSelected(registry: RegistryOffice): boolean {
    return !!this.selectedRegistryOffice.find((m: RegistryOffice) => m.id === registry.id);
  }

  onSelectedRow(data, event) {
    // console.log(event);
    // prevent multiple click events from coming in
    event.preventDefault();
    event.stopPropagation();
    this.selectedRegistryOffice = [];
    this.selectedRegistryOffice.push(new RegistryOffice(data));
  }

  ngAfterViewInit() {}
}
