import {Injectable} from '@angular/core';
import {SoaStateUtil} from './soa-state-util';
import {ProgressionStatus, StatementAdjustment} from '../statement-adjustment';
import {SalePriceAdjustmentFactory} from '../sale-price-adjustment/sale-price-adjustment-factory';
import {RESERVE_FOR_VTB_MTG_TEXT, StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../statement-adjustment-constants';
import {HstSalePrice} from '../model/hst-sale-price-soa';
import {Matter, MatterProperty, Section} from '../../shared';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {
  OtherFixedCreditTypes, SoAdjAssumedMortgage,
  SoAdjOtherFixed,
  SoAdjOtherProrated, SoAdjTarionWarranty,
  SoAdjTaxOther,
  SoAdjTenancyCurrent,
  SoAdjTenancyPrepaid
} from '../model';
import {ModalResult} from '../../../shared-main/enums';
import {SoaWater, WaterSupplyType} from '../water-soa';
import {SoaHeat} from '../model/heat-soa';
import {TaxesPaidDuringOccupancyUtil} from '../modals/taxes-paid-during-occupancy/taxes-paid-during-occupancy-util';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {SoAdjInterimOccupancyFee} from '../model/so-adj-interim-occupancy-fee';
import {SoAdjInterimEarlyPossessionFee} from '../model/so-adj-interim-early-possession-fee';
import {SoAdjFinalOccupancyFee} from '../model/so-adj-final-occupancy-fee';
import {SoAdjFinalEarlyPossessionFee} from '../model/so-adj-final-early-possession-fee';
import {SoAdjTotalOccupancyFee} from '../model/so-adj-total-occupancy-fee';
import {UUIDUtil} from '../../../main/uuid-util';
import {Project} from '../../../projects/shared/project';
import {ProjectTab} from '../../../projects/shared/project-tab';
import {projectConsts, StatementAdjParameter} from '../../../projects/shared/project-consts';
import {Subscription} from 'rxjs/Subscription';
import {StatementAdjustmentUtil} from '../statement-adjustment-util';
import {TabsService} from '../../../core';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import {StatementOfAdjustmentHeading} from '../model/statement-adjustment-heading';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {MatterService} from '../../matter.service';
import {SoAdjCreditVendorTaxRebate} from '../modals/credit-vendor-tax-rebate/so-adj-credit-vendor-tax-rebate';
import {creditVendorTaxRebateShowTypes} from '../model/so-adj-drop-downs';
import {SoaCommonExpense} from '../model/common-expense-soa';
import {MatterTab} from '../../matter-tab';
import {SoaExpenseAdjustmentUtil} from '../modals/reserve-fund/soa-expense-adjustment-util';
import {SoaRealtyTaxAdjustment} from '../modals/realty-tax/soa-realty-tax-adjustment';
import {SoAdjPurchasePriceDeferredPortion} from '../model/so-adj-purchase-price-deferred-portion';
import {SoAdjAmountPaidOnInterimClosing} from '../modals/amount-paind-on-interim-closing/so-adj-amount-paid-on-interim-closing';
import {
  SoAdjConsiderationPaidOnInterimClosing
} from '../modals/consideration-paid-on-interim-closing/so-adj-consideration-paid-on-interim-closing';
import {SoAdjExpenseOccupancyPeriod} from '../modals/total-during-occupancy-period/so-adj-expense-occupancy-period';
import {InterestSoa} from '../interest-soa';
import {SoaRealtyTaxAdjustmentUtil} from '../modals/realty-tax/soa-realty-tax-adjustment-util';
import {ComponentAdjCreditTypeValue, SoAdjComponent} from '../model/so-adj-component';
import {SoAdjPurchasePricePercentage} from '../model/so-adj-purchase-price-percentage';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import {SoAdjHCRAFee} from '../model/so-adj-hcra-fee';
import {StatementAdjustmentDisplayHcraFeeBuilder} from '../builders/statement-adjustment-display-hcra-fee-builder';
import {SoAdjVacantOccupancyTax} from '../model/so-adj-vacant-occupancy-tax';
import {StatementAdjustmentDisplayVacantOccupancyTaxBuilder} from '../builders/statement-adjustment-display-vacant-occupancy-tax-builder';
import {StatementAdjustmentDisplayTarionWarrantyBuilder} from '../builders/statement-adjustment-display-tarion-warranty-builder';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {RollNumberPropertyTaxesResult} from '../../property-teranet/property-taxes/roll-number-property-taxes-result';
import {MatterTax} from '../../shared/property-taxes';
import moment from 'moment';
import {InterimTaxMultiplier} from '../../../shared-main/constants';
import {ManitobaLttTierUtil} from '../../consideration-ltt/manitoba-ltt-tier-util';
import {DepositModalContextValue} from '../../property-teranet/deposit/deposit.modal.component';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Deposit} from '../../shared/deposit';
import {ProjectTaxAdjustmentConfig} from '../../../projects/project-adjustments/project-tax-adjustment-config';

@Injectable({
  providedIn: 'root'
})
@AutoUnsubscribe()
export class SoaFulfillment {

  constructor(
    private soaUtils: SoaStateUtil,
    public errorService: ErrorService,
    public tabService: TabsService,
    public dialogService: DialogService,
    public documentProfileCache: DocumentProfileCache,
    public matterService: MatterService
  ) {
  }

  get matter(): Matter {
    return this.soaUtils.matter;
  }

  get project(): Project {
    return this.soaUtils.project;
  }

  get propertyModel(): MatterProperty {
    return this.soaUtils.propertyModel;
  }

  get matterDocumentProfile(): DocumentProfile {
    return this.soaUtils.matterDocumentProfile;
  }

  get federalHstRate(): number {
    return (this.soaUtils.soaConsiderationTaxes && this.soaUtils.soaConsiderationTaxes.hstFederalPortion ? this.soaUtils.soaConsiderationTaxes.hstFederalPortion : 0);
  }

  get provinceHstRate(): number {
    return (this.soaUtils.soaConsiderationTaxes && this.soaUtils.soaConsiderationTaxes.hstProvincialPortion ? this.soaUtils.soaConsiderationTaxes.hstProvincialPortion : 0);
  }

  get salePriceAdjustment(): SalePriceAdjustment {
    return (this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment);
  }

  get adjustmentDate(): string {
    return this.matter.getClosingDate();
  }

  async onPropertyTaxUpdate(result: RollNumberPropertyTaxesResult, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment, doNotUpdateLinkedAdjustment?: boolean): Promise<void> {
    if (statementAdjustment && statementAdjustment.isAnyPropertyTaxes() && result) {
      statementAdjustment.infoOnly = result.infoOnly;
      if (statementAdjustment.infoOnly) {
        statementAdjustment.amount = 0;
      }
    }
    if (result && result.propertyTaxesByRollNumber) {
      this.propertyModel.propertyTax = 'Multiple Taxes...';
      if (result.propertyTaxesByRollNumber.length > 0) {
        this.propertyModel.matterTaxesByRollNumber = [];
        this.propertyModel.matterTax = undefined;
        result.propertyTaxesByRollNumber.forEach(item => this.propertyModel.matterTaxesByRollNumber.push(item.matterTax));
        this.matter.createUpdateAdjustmentPropertyTaxesByRollNumber(result.infoOnly);
        result.propertyTaxesByRollNumber.forEach(item => {
          if (item.matterTax) {
            let statementOfAdjustment = this.matter.statementOfAdjustments.find(statementOfAdjustment => statementOfAdjustment.matterTax && statementOfAdjustment.matterTax.id == item.matterTax.id);
            if (statementOfAdjustment) {
              statementOfAdjustment.matterTax = item.matterTax;
            }
          }
        });
      }
      await this.soaUtils.enableSave(doNotUpdateLinkedAdjustment);
    } else {
      if (result && this.matter.matterPropertyWithCondo) {
        if (statementAdjustment && statementAdjustment.isOriginPropertyTaxes()) {
          this.soaUtils.updateMatterPropertyTax(result.propertyTax, statementAdjustment);
        }
        if (statementAdjustment && result.propertyTax) {
          statementAdjustment.matterTax = new MatterTax(result.propertyTax);
        } else if (statementAdjustment && !result.propertyTax) {
          this.soaUtils.removeStatementAdjustment(statementAdjustment, true);
        } else if (!statementAdjustment && result.propertyTax) {

          statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
          statementAdjustment.itemKey = statementAdjustmentKey;
          statementAdjustment.matterTax = new MatterTax(result.propertyTax);
          statementAdjustment.matterTax.id = UUIDUtil.getUUID();
          statementAdjustment.addedFlag = true;
          statementAdjustment.description = this.matter.getPropertyTaxAdjustmentDescription(statementAdjustmentKey, statementAdjustment.matterTax);
          if (statementAdjustment.isPropertyTax()) {
            statementAdjustment.infoOnly = result.infoOnly;
            if (statementAdjustment.infoOnly) {
              statementAdjustment.amount = 0;
            }
          }
          this.soaUtils.selectedItem(statementAdjustment);

        }
        if (statementAdjustment) {
          if (statementAdjustmentKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY) {
            this.matter.recalculateTaxBeginningOfYearUntilOcc(statementAdjustment);
          } else if (statementAdjustmentKey && statementAdjustmentKey === StatementAdjustmentKey.REALTY_TAXES_PAID_BY_VENDOR) {
            this.onTaxesPaidByVendorUpdate(result, statementAdjustment);
          } else {
            this.matter.createUpdateAdjustmentPropertyTaxes(statementAdjustment, null, null, result.infoOnly);
          }

          if (result.propertyTax && statementAdjustment.isOriginPropertyTaxes()) {
            this.soaUtils.updateMatterPropertyTax(result.propertyTax, statementAdjustment);
          }
        }
      }
      SoaExpenseAdjustmentUtil.updateSalesIncentiveAdjustments(this.matter, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
      SoaExpenseAdjustmentUtil.updateReserveFundAdjustments(this.matter, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
      SoaExpenseAdjustmentUtil.updateCommonExpenseAdjustments(this.matter, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
      await this.soaUtils.enableSave(doNotUpdateLinkedAdjustment);
      this.updatePropertyTaxPaidTrustLedgerStatementAdjustment(statementAdjustment);
    }
  }

  onTaxesPaidByVendorUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment && statementAdjustment.matterTax) {
      statementAdjustment.matterTax = new MatterTax(result.propertyTax);
      statementAdjustment.amount = statementAdjustment.matterTax.calculateVendorShareForPaidByVendor(this.matter.projectTaxAdjustmentConfig, true, this.matter);
      let adjustmentYearOptions = SoaRealtyTaxAdjustmentUtil.populateAdjustmentYearForTaxesPaidByVendor(this.matter);
      if (statementAdjustment.matterTax.adjustmentYearTaxesPaidByVendor && !adjustmentYearOptions.some(yearOptions => yearOptions.value == statementAdjustment.matterTax.adjustmentYearTaxesPaidByVendor)) {
        statementAdjustment.matterTax.adjustmentYearTaxesPaidByVendor = moment(SoaRealtyTaxAdjustmentUtil.getAdjustmentCalculationDate(this.matter.adjustmentStatusMode, this.matter)).year();
      }
      statementAdjustment.description = this.matter.getPropertyTaxAdjustmentDescription(statementAdjustment.itemKey, statementAdjustment.matterTax);
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    }
  }

  updatePropertyTaxPaidTrustLedgerStatementAdjustment(statementAdjustment: StatementAdjustment) {
    if (this.matter && this.matter.soaTrustLedgerCollection != undefined && this.matter.soaTrustLedgerCollection != null) {
      this.matter.soaTrustLedgerCollection.updatePropertyTaxPaidTrustLedgerStatementAdjustment(statementAdjustment);
      if (this.matter.isProjectSale) {
        this.matter.soaTrustLedgerCollection.createUpdatePropertyTaxPaidTrustLedgerStatementAdjustmentPS();
      }
    }
  }

  async onSalePriceUpdateInterimAndFinal(result: SalePriceAdjustment, doNotUpdateLinkedAdjustment: boolean): Promise<void> {
    const originalAdjustmentMode = this.matter.isAdjustmentStatusModeFinal ? 'Final' : 'Interim';
    if (this.soaUtils.isProjectOrProjectSale) {
      await this.soaUtils.updateAdjustmentType(this.matter.isAdjustmentStatusModeFinal ? 'Interim' : 'Final');
      result.matterAdjustmentStatusMode = this.matter.adjustmentStatusMode;
      await this.onSalePriceUpdate(result, doNotUpdateLinkedAdjustment);
      await this.soaUtils.updateAdjustmentType(originalAdjustmentMode);
      result.matterAdjustmentStatusMode = this.matter.adjustmentStatusMode;
    }
    await this.onSalePriceUpdate(result, doNotUpdateLinkedAdjustment);
    if (this.soaUtils.isProjectOrProjectSale) {
      await this.soaUtils.updateAdjustmentType(originalAdjustmentMode);
    }
    this.matterService.verifyPurchasePriceWithInstructionPurchasePrice(this.matter);
    this.propertyModel.calculateNRDTTax();
  }

  async onSalePriceUpdate(result: SalePriceAdjustment, doNotUpdateLinkedAdjustment: boolean): Promise<void> {
    this.matter.considerationLtt.salePriceAdjustment = result;
    let netSalePrice = this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment ? this.matter.considerationLtt.salePriceAdjustment.totalNetSalePrice(this.federalHstRate, this.provinceHstRate, true) : 0;
    this.matter.matterPropertyWithCondo.soaPurchasePrice = netSalePrice;
    await this.soaUtils.updateSalePriceConsiderationLTT();
    this.soaUtils.updateHstSalePriceAdjustment();
    this.matter.createAutomaticHstAdjustment(netSalePrice, this.federalHstRate, this.provinceHstRate);
    this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
    // apply any changes on SalePrice to TarionWarranty adjustments if exists
    if (StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(result, this.matter.statementOfAdjustments, this.soaUtils.statementAdjustmentDisplayUtil, this.matter.projectAdjustmentConfig)) {
      // now update in sale price adjustments TarionWarranty considerations
      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(result, this.matter.statementOfAdjustments);
      // now make sure net sale price has not changed that much that we`re on another set of TarionWarranty threshold
      StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(result, this.matter.statementOfAdjustments, this.soaUtils.statementAdjustmentDisplayUtil, this.matter.projectAdjustmentConfig);
      // maybe there's an updated consideration out of new TarionWarranty changes
      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(result, this.matter.statementOfAdjustments);
    }
    StatementAdjustmentUtil.updateOtherFixedAdj(result, this.matter.statementOfAdjustments, this.matter.statementOfAdjustmentHeading);
    StatementAdjustmentUtil.updateOtherFixedAdjPayableOnOccupancy(result, this.matter.statementOfAdjustments, this.matter.statementOfAdjustmentHeading);
    StatementAdjustmentUtil.updateOtherProRatedOnPercentageInterest(result, this.matter.statementOfAdjustments,
      this.matter.isMatterProvinceAB ? Number(this.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) : Number(this.matter.matterPropertyWithCondo.condominiumTotalSharePercentage),
      this.matter.statementOfAdjustmentHeading);
    StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(result, this.matter.uniqueStatementAdjustments);
    StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(result, this.matter.statementOfAdjustments);
    this.updateItemizedCreditAdjustmentsOnSalePriceUpdate(result);
    if (this.project && this.project.isStatementOfAdjustmentInterim()) {
      // new since DPPMP-28748, deposit calculation can be affected by sale price adj changes
      await this.soaUtils.recalculateDepositSOAOnAdjustmentChanges();
    }

    this.soaUtils.updateCommissionPayableToVendorBroker();
    SoaRealtyTaxAdjustmentUtil.updateRealtyTaxAdjustmentOnDateChange(this.matter);
    this.soaUtils.updateSalePriceMinusNetSalePriceAdjustment();
    SoaRealtyTaxAdjustmentUtil.updateRealtyTaxesPaidByVendor(this.matter);
    if (this.soaUtils.isProjectSale || this.matter.templateForProject) {
      this.matter.updateTaxAdjustmentsAfterSalePriceAdjustmentUpdate(InterimTaxMultiplier);
      this.matter.recalculateAllTaxBeginningOfYearUntilOcc();
    }
    this.matter.recalculateAllInterimOccupancyFee(this.project); // To do -> move this whenever needed
    // Update Manitoba Land Transfer Tax on Sale Price uPDATE
    if (this.matter && this.matter.isMatterProvinceMB && this.matter.isPurchase && this.matter.matterPropertyWithCondo) {
      this.matter.matterPropertyWithCondo.landTransferTax = ManitobaLttTierUtil.calculateLttTax(this.matter.matterPropertyWithCondo.purchasePrice, this.soaUtils.manitobaLTTTiers);
      this.matter.updateLTTOnStatementOfAccount();
    }
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
    }
    this.matter.updateBalanceOfFundsPayableTo();
    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(this.matter);
    await this.soaUtils.enableSave(doNotUpdateLinkedAdjustment);
  }

  updateItemizedCreditAdjustmentsOnSalePriceUpdate(result: SalePriceAdjustment): void {
    StatementAdjustmentUtil.updateItemizedCreditAdjustments(result, this.matter.statementOfAdjustments, this.federalHstRate, this.provinceHstRate);
    if (StatementAdjustmentUtil.getItemizedVendorAdjAvailableWithConsideration(this.matter.statementOfAdjustments)
      && !StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(this.matter.statementOfAdjustments) && !StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(this.matter.statementOfAdjustments)) {
      this.updateMatterOnItemizedCreditAdjustmentChange(result, StatementAdjustmentUtil.getItemizedVendorAdjAvailableWithConsideration(this.matter.statementOfAdjustments));
    }

    if (StatementAdjustmentUtil.getItemizedPurchaserAdjAvailableWithConsideration(this.matter.statementOfAdjustments)) {
      this.updateMatterOnItemizedCreditAdjustmentChange(result, StatementAdjustmentUtil.getItemizedPurchaserAdjAvailableWithConsideration(this.matter.statementOfAdjustments));
    }
  }

  async fulfillDepositModal(result): Promise<void> {
    await this.onDepositAdjustmentUpdate(result);
    await this.soaUtils.enableSave();
  }

  async onDepositAdjustmentUpdate(result: DepositModalContextValue): Promise<void> {
    this.matter.updateMatterDepositAdjustment(result);
    if (result.depositStatementAdj) {
      const index = this.matter.statementOfAdjustments.findIndex(adj => adj.itemKey == StatementAdjustmentKey.DEPOSIT);
      if (index == -1) {
        // don't think we'll be in here but just in case...
        this.matter.statementOfAdjustments.push(result.depositStatementAdj);
      } else {
        // the deposit SOA in 'result' is copied from this.matter when deposit modal is opened,
        // the modal may have updated its values so we just set it back
        this.matter.statementOfAdjustments[ index ] = result.depositStatementAdj;
      }
    }

    this.matter.createUpdateDepositAdjustment(result.infoOnly);

    const hasInterim = this.project && this.project.isStatementOfAdjustmentInterim();
    if (hasInterim) {
      // copy data
      let interimDepositAdj = StatementAdjustmentUtil.findByKey(this.matter.interimStatementAdjustments, StatementAdjustmentKey.DEPOSIT);
      let finalDepositAdj = StatementAdjustmentUtil.findByKey(this.matter.finalStatementAdjustments, StatementAdjustmentKey.DEPOSIT);
      if (this.matter.isAdjustmentStatusModeFinal) {
        StatementAdjustmentUtil.copyDepositAdjustmentData(finalDepositAdj, interimDepositAdj);
      } else {
        StatementAdjustmentUtil.copyDepositAdjustmentData(interimDepositAdj, finalDepositAdj);

      }

      // new since DPPMP-28748, deposit calculation can be affected by sale price adj changes
      await this.soaUtils.recalculateDepositSOAOnAdjustmentChanges();

      // don't need to call methods like statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems
      // because updateAdjustmentType() calls them

    } else {
      this.soaUtils.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems();
      this.soaUtils.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayBalanceItems();
    }
    this.matter.recalculateAllInterimOccupancyFee(this.project);
    this.matter.recalculateAllFinalEarlyPossessionFee();
    this.soaUtils.updateCommissionPayableToVendorBroker();
    this.matter.recalculateForm4Charges();
    this.matter.reCalculateTrustLedgerReceivedDepositHeldInTrust();//TL f9 amount is based on Deposit Amount
    this.matter.updateBalanceOfFundsPayableTo();

    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(this.matter);
    StatementAdjustmentUtil.updateInterestOnDepositUnderOldCondo(this.matter);
  }

  async fulfillHSTModal(result, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result) {
      await this.onHSTModalUpdate(result.hstSalePriceSoa, statementAdjustment);
    }

    await this.soaUtils.enableSave();
    this.soaUtils.setFocusAfterModalClose();
  }

  async onHSTModalUpdate(hstSalePriceSoa: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    let isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount: boolean = StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(this.matter.statementOfAdjustments);
    if (hstSalePriceSoa) {
      if (statementAdjustment) {
        // please make sure you review StatementAdjustmentDisplayBuilder -> "soaItem.hstSalePrice.creditAmount =" part where the amount is actually overwritten // ToDo refactoring needed here
        statementAdjustment.hstSalePrice = new HstSalePrice(hstSalePriceSoa.rateType, hstSalePriceSoa);
        statementAdjustment.statementOfAdjustmentCreditType = hstSalePriceSoa.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
        statementAdjustment.amount = this.getHstSalePriceAdjustmentAmount(hstSalePriceSoa, this.matter.considerationLtt.salePriceAdjustment);
        if (statementAdjustment.amount == 0) {
          statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
        }
      } else {
        this.createUpdateHstSalePrice(hstSalePriceSoa, this.matter.considerationLtt.salePriceAdjustment);
      }
    } else {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true);
    }
    // update sale price considerations only if there was a change on other fixed additional considerations
    if (isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount != StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(this.matter.statementOfAdjustments)) {
      StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments);
      StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.matter.considerationLtt.salePriceAdjustment, this.matter.uniqueStatementAdjustments);
    }
    this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
    await this.soaUtils.updateSalePriceConsiderationLTT();
  }

  // ToDo this is more like a "CREATE"
  createUpdateHstSalePrice(hstSalePriceSoa: HstSalePrice, salePriceAdjustment: SalePriceAdjustment): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.HST_ADJUSTMENT;
    statementAdjustment.hstSalePrice = new HstSalePrice(hstSalePriceSoa.rateType, hstSalePriceSoa);
    statementAdjustment.hstSalePrice.id = undefined;
    statementAdjustment.amount = this.getHstSalePriceAdjustmentAmount(hstSalePriceSoa, salePriceAdjustment);
    statementAdjustment.statementOfAdjustmentCreditType = hstSalePriceSoa.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
    if (statementAdjustment.amount == 0) {
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
    }
    statementAdjustment.addedFlag = true;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  getHstSalePriceAdjustmentAmount(hstSalePriceSoa: HstSalePrice, salePriceAdjustment: SalePriceAdjustment): number {
    return this.matter.getHstSalePriceAdjustmentAmount(hstSalePriceSoa, salePriceAdjustment, this.provinceHstRate, this.federalHstRate);
  }

  onTaxFactorUpdate(result: any, statementAdjustment?: StatementAdjustment): void {

    if (statementAdjustment) {
      let salePriceAdjustmentHstFactor = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      salePriceAdjustmentHstFactor.netOutHstFromHSTSalePrice = 'NO';
      salePriceAdjustmentHstFactor.salePriceAdjustmentFormat = 'CREDIT_VENDOR_NET_SALE_PRICE';
      salePriceAdjustmentHstFactor.federalPortion = true;
      salePriceAdjustmentHstFactor.provincialPortion = true;
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;

      statementAdjustment.hstSalePrice = new HstSalePrice(result.rateType, result);
      statementAdjustment.description = result.adjustmentHeading;

      if (result.calculateUsingType == 'CREDIT_AMOUNT') {
        statementAdjustment.amount = result.creditAmount;
        salePriceAdjustmentHstFactor.agreementSalePrice = statementAdjustment.amount;
        statementAdjustment.salePriceAdjustmentHSTFactor = salePriceAdjustmentHstFactor;
      } else if (result.calculateUsingType == 'TAX_OUT_PRICE') {
        salePriceAdjustmentHstFactor.agreementSalePrice = result.creditAmount;
        const provinceHstRateForMBorSK = 0;
        statementAdjustment.amount = salePriceAdjustmentHstFactor.taxOutCreditVendor(this.matter && this.matter.isMatterProvinceMBorSK ? provinceHstRateForMBorSK : this.provinceHstRate, this.federalHstRate);
        statementAdjustment.salePriceAdjustmentHSTFactor = salePriceAdjustmentHstFactor;

      }
    } else {
      this.createUpdateHstOtherFactor(result);
    }
  }

  createUpdateHstOtherFactor(hstSalePriceSoa: HstSalePrice): void {
    let salePriceAdjustmentHstFactor = SalePriceAdjustmentFactory.getSalePriceAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    salePriceAdjustmentHstFactor.netOutHstFromHSTSalePrice = 'NO';
    salePriceAdjustmentHstFactor.salePriceAdjustmentFormat = 'CREDIT_VENDOR_NET_SALE_PRICE';
    salePriceAdjustmentHstFactor.federalPortion = true;
    salePriceAdjustmentHstFactor.provincialPortion = true;

    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.FACTOR_IN_HST_REBATE;
    statementAdjustment.description = hstSalePriceSoa.adjustmentHeading;
    statementAdjustment.hstSalePrice = new HstSalePrice(hstSalePriceSoa.rateType, hstSalePriceSoa);
    statementAdjustment.hstSalePrice.id = undefined;

    if (hstSalePriceSoa && hstSalePriceSoa.calculateUsingType == 'CREDIT_AMOUNT' && this.salePriceAdjustment) {
      statementAdjustment.amount = hstSalePriceSoa.creditAmount;
      salePriceAdjustmentHstFactor.agreementSalePrice = statementAdjustment.amount;

    } else if (hstSalePriceSoa && hstSalePriceSoa.calculateUsingType == 'TAX_OUT_PRICE' && this.salePriceAdjustment) {
      salePriceAdjustmentHstFactor.agreementSalePrice = hstSalePriceSoa.creditAmount;
      const provinceHstRateForMBorSK = 0;
      statementAdjustment.amount = salePriceAdjustmentHstFactor.taxOutCreditVendor(this.matter && this.matter.isMatterProvinceMBorSK ? provinceHstRateForMBorSK : this.provinceHstRate, this.federalHstRate);

    }
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    statementAdjustment.addedFlag = true;

    statementAdjustment.salePriceAdjustmentHSTFactor = salePriceAdjustmentHstFactor;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  onTaxAdjustmentUpdate(taxOther: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment) {
      let soAdjTaxOther = new SoAdjTaxOther(taxOther);
      statementAdjustment.soAdjTaxOther = soAdjTaxOther;
      statementAdjustment.description = soAdjTaxOther.isTaxOnly() ? soAdjTaxOther.taxType + ' ON ' + soAdjTaxOther.adjustmentHeading : soAdjTaxOther.adjustmentHeading;
      statementAdjustment.amount = soAdjTaxOther.adjValue;
      if (this.matter && this.matter.statementOfAdjustmentHeading) {
        soAdjTaxOther.isVendorSelected = soAdjTaxOther.creditType == this.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo;
      }
      statementAdjustment.statementOfAdjustmentCreditType = soAdjTaxOther.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
    } else {
      let soAdj = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      soAdj.itemKey = StatementAdjustmentKey.TAX_OTHER;
      soAdj.soAdjTaxOther = new SoAdjTaxOther(taxOther);
      soAdj.soAdjTaxOther.id = undefined;
      soAdj.amount = soAdj.soAdjTaxOther.adjValue;
      soAdj.description = soAdj.soAdjTaxOther.isTaxOnly() ? soAdj.soAdjTaxOther.taxType + ' ON ' + soAdj.soAdjTaxOther.adjustmentHeading : soAdj.soAdjTaxOther.adjustmentHeading;
      soAdj.statementOfAdjustmentCreditType = soAdj.soAdjTaxOther.isVendorSelected ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
      soAdj.addedFlag = true;
      this.soaUtils.selectedItem(soAdj);
    }
  }

  async fulfillWaterModal(result, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result && result !== ModalResult.Cancel && result !== ModalResult.Delete) {
      this.onWaterAdjustmentUpdate(result, statementAdjustment);
      await this.soaUtils.enableSave();
    } else {
      if (result === ModalResult.Delete) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
        await this.soaUtils.enableSave();
      }
    }
    this.soaUtils.setFocusAfterModalClose();
  }

  onWaterAdjustmentUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment) {
      statementAdjustment.soaWater = new SoaWater(result);

      statementAdjustment.amount = result.getCredit(this.adjustmentDate);
      if (result.waterSupplyType == WaterSupplyType.FLAT_RATE) {
        statementAdjustment.description = 'FLAT RATE WATER';
        statementAdjustment.statementOfAdjustmentCreditType = result.getSoaCreditType(this.adjustmentDate);
      } else {
        statementAdjustment.description = 'WATER';
        statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
      }
    } else {
      this.createUpdateSoaWater(result);
    }
  }

  createUpdateSoaWater(soaWater: SoaWater): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.WATER;

    statementAdjustment.soaWater = new SoaWater(soaWater);
    statementAdjustment.soaWater.id = undefined;
    statementAdjustment.amount = soaWater.getCredit(this.adjustmentDate, this.matter.isPaysForDateOfClosingVendor);
    if (soaWater.isWaterSupplyTypeFlatRate) {
      statementAdjustment.description = 'FLAT RATE WATER';
      statementAdjustment.statementOfAdjustmentCreditType = soaWater.getSoaCreditType(this.adjustmentDate, this.matter.isPaysForDateOfClosingVendor);
    } else {
      statementAdjustment.description = 'WATER';
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
    }
    statementAdjustment.addedFlag = true;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  onHeatAdjustmentUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment) {
      statementAdjustment.soaHeat = new SoaHeat(result);
      if (result.heatType == 'GAS' || result.heatType == 'ELECTRIC') {
        statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
      } else {
        statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
      }
      statementAdjustment.amount = result.creditVendor;
      statementAdjustment.description = 'HEAT - ' + result.heatTypeLabel;
    } else {
      this.createUpdateSoaHeat(result);
    }
  }

  createUpdateSoaHeat(soaHeat: SoaHeat): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.HEAT;
    statementAdjustment.description = 'HEAT - ' + soaHeat.heatTypeLabel;
    statementAdjustment.soaHeat = new SoaHeat(soaHeat);
    statementAdjustment.soaHeat.id = undefined;
    statementAdjustment.amount = soaHeat.creditVendor;
    if (soaHeat.heatType == 'GAS' || soaHeat.heatType == 'ELECTRIC') {
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
    } else {
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    }
    statementAdjustment.addedFlag = true;
    this.soaUtils.selectedItem(statementAdjustment);

  }

  async onTaxesPaidDuringOccupancyUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment) {
      statementAdjustment.taxPortion = result;
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.TAXES_PAID_DURING_OCCUPANCY;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.taxPortion = result;
      this.soaUtils.selectedItem(statementAdjustment);
    }
    statementAdjustment.amount = TaxesPaidDuringOccupancyUtil.calculateShareForTaxPortion(statementAdjustment.taxPortion, this.matter);
    statementAdjustment.description = 'Taxes Paid During Occupancy';
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
    await this.soaUtils.enableSave();
  }

  async onForm4ChargeUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soAdjForm4Charge) {
      statementAdjustment.soAdjForm4Charge = result;
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.FORM_4_CHARGE;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjForm4Charge = result;
      this.soaUtils.selectedItem(statementAdjustment);
    }
    statementAdjustment.amount = statementAdjustment.soAdjForm4Charge.calculateForm4Total;
    statementAdjustment.description = 'Form 4 Charges';
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    await this.soaUtils.enableSave();
  }

  async fulfillItemizedCreditToVendor(result, statementAdjustmentKey: string, itemizedAdjustmentBackup: StatementAdjustment, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result.action == 'OK' && result.soAdjItemizedCreditToVendorPurchaser) {
      if (statementAdjustment && statementAdjustment.soAdjItemizedCreditToVendorPurchaser) {
        statementAdjustment.soAdjItemizedCreditToVendorPurchaser = SalePriceAdjustmentFactory.getItemizedCreditToVendorPurchaserAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, result.soAdjItemizedCreditToVendorPurchaser);
      } else {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = statementAdjustmentKey;
        statementAdjustment.addedFlag = true;
        statementAdjustment.soAdjItemizedCreditToVendorPurchaser = SalePriceAdjustmentFactory.getItemizedCreditToVendorPurchaserAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, result.soAdjItemizedCreditToVendorPurchaser);
        statementAdjustment.soAdjItemizedCreditToVendorPurchaser.id = undefined;
        this.soaUtils.selectedItem(statementAdjustment);
      }

      statementAdjustment.amount = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.totalNetSalePrice(this.federalHstRate, this.provinceHstRate);
      statementAdjustment.description = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.adjustmentHeading;
      statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjItemizedCreditToVendorPurchaser && statementAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'VENDOR'
        ? StatementAdjustmentAmountTypes.VENDOR : StatementAdjustmentAmountTypes.PURCHASER;
      statementAdjustment.infoOnly = statementAdjustment.soAdjItemizedCreditToVendorPurchaser.infoOnly;
      await this.updateMatterOnItemizedCreditAdjustmentChange(this.matter.considerationLtt.salePriceAdjustment, statementAdjustment, itemizedAdjustmentBackup);
    } else if (result.action == 'DELETE') {
      if (statementAdjustment) {
        (<any>this.matter.statementOfAdjustments).remove(statementAdjustment);
        this.soaUtils.updateMatterOnItemizedCreditAdjustmentDelete(this.matter.considerationLtt.salePriceAdjustment, statementAdjustment);
      }
    }
    await this.soaUtils.enableSave();
    this.soaUtils.setFocusAfterModalClose();
  }

  async updateMatterOnItemizedCreditAdjustmentChange(salePriceAdjustment: SalePriceAdjustment, itemizedAdjustment: StatementAdjustment, itemizedAdjustmentBackup?: StatementAdjustment): Promise<void> {
    let itemizedCreditToVendorPurchaserTotal: number = 0;
    if (itemizedAdjustment && itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser) {
      itemizedCreditToVendorPurchaserTotal = itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.total();
    }
    await this.updateItemizedCreditAdjAndSalePriceAdj(salePriceAdjustment, itemizedAdjustment, itemizedCreditToVendorPurchaserTotal, itemizedAdjustmentBackup);

    // Update Interim Sale Price when Final Sale Price got updated due to Itemized Credit Adjustment;
    this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    let salePriceSA: StatementAdjustment = this.matter.getSalePriceStatementAdjustment();
    if (salePriceSA) {
      await this.updateItemizedCreditAdjAndSalePriceAdj(salePriceAdjustment, itemizedAdjustment, itemizedCreditToVendorPurchaserTotal, itemizedAdjustmentBackup);
    }

    // Reverting back mode..
    this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    this.checkHSTRebateToDisplayWarningMessageForPSMOrProject();
  }

  /**
   * itemizedAdjustmentBackup: This should be sent for existing adjustments.
   */
  async updateItemizedCreditAdjAndSalePriceAdj(salePriceAdjustment: SalePriceAdjustment, itemizedAdjustment: StatementAdjustment, itemizedCreditToVendorPurchaserTotal: number, itemizedAdjustmentBackup?: StatementAdjustment): Promise<void> {
    if (this.checkSalePriceAdjUpdateRequired(itemizedAdjustment, itemizedAdjustmentBackup)) {
      if (itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'VENDOR' && itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES') {
        salePriceAdjustment.additionalConsiderationsInclHst = itemizedAdjustment.applyToAdjustmentRecord ? itemizedCreditToVendorPurchaserTotal : 0;
      } else if (itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'PURCHASER' && itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES') {
        salePriceAdjustment.creditsToPurchaserInclHst = itemizedAdjustment.applyToAdjustmentRecord ? itemizedCreditToVendorPurchaserTotal : 0;
      }

      if (itemizedAdjustmentBackup) {
        if (itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'VENDOR' && itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES'
          && (itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo != 'VENDOR' || itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal != 'YES')) {
          salePriceAdjustment.additionalConsiderationsInclHst = 0;
        }

        if (itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.creditsTo == 'PURCHASER' && itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal == 'YES'
          && (itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo != 'PURCHASER' || itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal != 'YES')) {
          salePriceAdjustment.creditsToPurchaserInclHst = 0;
        }
      }

      await this.soaUtils.updateSalePriceConsiderationLTT();
    }
  }

  checkSalePriceAdjUpdateRequired(itemizedAdjustment: StatementAdjustment, itemizedAdjustmentBackup?: StatementAdjustment): boolean {
    if (!itemizedAdjustmentBackup) {
      return true;
    } else {
      if (itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.creditsTo != itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.creditsTo ||
        itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal != itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.automaticallyInsertTotal ||
        itemizedAdjustment.soAdjItemizedCreditToVendorPurchaser.total() != itemizedAdjustmentBackup.soAdjItemizedCreditToVendorPurchaser.total()
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * When this Function used outside of StatementOfAdjustment Tab For Project or Project Sale, matter may not have appropriate values for
   * matter.selectedProgressionStatus, matter.adjustmentStatusMode, which are critical for correct behavior of  matter.checkHSTRebateToDisplayWarningMessage.
   * By the time of timeout even, matter acquires all correct values.
   * For standard PSM matter matter.selectedProgressionStatus and matter.adjustmentStatusMode always are FINAL and no timeout required.
   */
  checkHSTRebateToDisplayWarningMessageForPSMOrProject() {
    if (this.matter.isProjectOrProjectSale) {
      setTimeout(() => {
        this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
      }, 200);
    } else {
      this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
    }
  }

  async onInterimOccupancyFeeUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): Promise<void> {
    let taxesTotalChanged: boolean = false;
    if (result === ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjInterimOccupancyFee) {
      const orgValue: number = statementAdjustment.soAdjInterimOccupancyFee.taxesTotal ? +statementAdjustment.soAdjInterimOccupancyFee.taxesTotal : 0;
      const currentValue: number = result.taxesTotal ? +result.taxesTotal : 0;
      taxesTotalChanged = (orgValue != currentValue);
      statementAdjustment.soAdjInterimOccupancyFee = new SoAdjInterimOccupancyFee(result);
      statementAdjustment.description = statementAdjustment.soAdjInterimOccupancyFee.getDescription();
      this.matter.updateInterimOccupancyFeeAdjOnChange();
    } else {
      taxesTotalChanged = result && result.taxesTotal && +result.taxesTotal != 0;
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = statementAdjustmentKey;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjInterimOccupancyFee = new SoAdjInterimOccupancyFee(result);
      statementAdjustment.soAdjInterimOccupancyFee.id = undefined;
      statementAdjustment.description = statementAdjustment.soAdjInterimOccupancyFee.getDescription();
      this.soaUtils.selectedItem(statementAdjustment);
      this.matter.updateInterimOccupancyFeeAdjOnChange();
    }

    await this.soaUtils.enableSave();
  }

  async onInterimEarlyPossessionFeeUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result === ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjInterimEarlyPossessionFee) {
      statementAdjustment.soAdjInterimEarlyPossessionFee = new SoAdjInterimEarlyPossessionFee(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = statementAdjustmentKey;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjInterimEarlyPossessionFee = new SoAdjInterimEarlyPossessionFee(result);
      statementAdjustment.soAdjInterimEarlyPossessionFee.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }
    this.recalculateInterimEarlyPossessionFeeAdj(statementAdjustment, this.matter);

    this.matter.recalculateAllFinalEarlyPossessionFee();
    this.matter.recalculateAllPurchasePriceDeferredPortion();
    await this.soaUtils.enableSave();
  }

  public recalculateInterimEarlyPossessionFeeAdj(statementAdjustment: StatementAdjustment, matter: Matter) {
    let elapsedDays = Number(matter.getElapsedDays(this.adjustmentDate));
    let numberOfDaysInMonth = Number(matter.getNumberofDaysMonth(this.adjustmentDate));
    statementAdjustment.updateInterimEarlyPossessionFeeOnClosingDateChange(elapsedDays, numberOfDaysInMonth);
  }

  async onFinalOccupancyFeeUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result === ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjFinalOccupancyFee) {
      statementAdjustment.soAdjFinalOccupancyFee = new SoAdjFinalOccupancyFee(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = statementAdjustmentKey;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjFinalOccupancyFee = new SoAdjFinalOccupancyFee(result);
      statementAdjustment.soAdjFinalOccupancyFee.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    let interimAdjustAsDate: string = this.matter.getInterimAdjustAsDate();
    let finalAdjustAsDate: string = this.matter.adjustAsAtClosingDateFlag === 'SPECIFY' ? this.matter.adjustAsAtClosingDate : this.matter.matterCloseDate;
    statementAdjustment.updateFinalOccupancyFeeOnClosingDateChange(this.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate, this.matter.isPaysForDateOfClosingPurchaser, this.matter.provinceCode);
    this.matter.recalculateTotalFinalOccupancyFee();
    await this.soaUtils.enableSave();
  }

  async onFinalEarlyPossessionFeeUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result === ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjFinalEarlyPossessionFee) {
      statementAdjustment.soAdjFinalEarlyPossessionFee = new SoAdjFinalEarlyPossessionFee(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = statementAdjustmentKey;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjFinalEarlyPossessionFee = new SoAdjFinalEarlyPossessionFee(result);
      statementAdjustment.soAdjFinalEarlyPossessionFee.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    // interimAdjustAsDate/finalAdjustAsDate should depend on adjustAsAtClosingDateFlagInterim/adjustAsAtClosingDateFlag condition to get value
    let interimAdjustAsDate: string = this.matter.adjustAsAtClosingDateFlagInterim === 'SPECIFY' ? this.matter.adjustAsAtClosingDateInterim : this.matter.matterCloseDate;
    let finalAdjustAsDate: string = this.matter.adjustAsAtClosingDateFlag === 'SPECIFY' ? this.matter.adjustAsAtClosingDate : this.matter.matterCloseDate;
    statementAdjustment.updateFinalEarlyPossessionFeeOnClosingDateChange(this.matter.occupancyDate, interimAdjustAsDate, finalAdjustAsDate);

    await this.soaUtils.enableSave();
  }

  async onTotalOccupancyFeeUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result === ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjTotalOccupancyFee) {
      statementAdjustment.soAdjTotalOccupancyFee = new SoAdjTotalOccupancyFee(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = 'TOTAL_OCCUPANCY_FEE';
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjTotalOccupancyFee = new SoAdjTotalOccupancyFee(result);
      statementAdjustment.soAdjTotalOccupancyFee.id = UUIDUtil.getUUID();
      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.updateTotalOccupancyFeeOnClosingDateChange(this.matter, this.project);
    await this.soaUtils.enableSave();
  }

  async resetImportProjectStatementAdjustment(result: any): Promise<void> {
    if (result && result.selectedAction && result.projectAdjustmentActionType) {
      if (result.selectedAction == 'DELETE') {
        if (this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject() && (this.tabService.activeTab as ProjectTab).projectComponent) {
          await this.resetProjectStatementAdjustment(result.projectAdjustmentActionType);
          let statementAdjParameter = new StatementAdjParameter();
          statementAdjParameter.selectedRemoveAdjustmentAction = result.projectAdjustmentActionType;
          this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementOfAdjustmentReset, undefined, true, statementAdjParameter);
          let subscription: Subscription = (this.tabService.activeTab as ProjectTab).projectComponent.validateSaveProjectAndTemplateMatter().subscribe((result: boolean) => {
            if (result) {
              subscription.unsubscribe();
              this.dialogService.confirm('Confirmation', 'Reset/Import successfully completed', true, 'OK');
            }
          });
        }
      }
      if (result.selectedAction == 'IMPORT' && !!result.sourceProject) {
        await this.importProjectStatementAdjustment(result.projectAdjustmentActionType, result.sourceProject);
        let statementAdjParameter = new StatementAdjParameter();
        statementAdjParameter.selectedRemoveAdjustmentAction = result.projectAdjustmentActionType;
        this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementOfAdjustmentReset, undefined, true, statementAdjParameter);
        let subscription: Subscription = (this.tabService.activeTab as ProjectTab).projectComponent.validateSaveProjectAndTemplateMatter().subscribe((result: boolean) => {
          if (result) {
            subscription.unsubscribe();
            this.dialogService.confirm('Confirmation', 'Reset/Import successfully completed', true, 'OK');
          }
        });
      }
    }
  }

  async resetProjectStatementAdjustment(projectAdjustmentActionType: string): Promise<void> {
    // Resetting payable to
    this.matter.statementOfAdjustmentPayable = undefined;
    this.matter.resetStatementOfAdjustmentPayable(this.matterDocumentProfile, this.documentProfileCache.cachedDefaultDocumentProfile);
    this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.SoaPayableTo);

    // Resetting heading
    this.matter.statementOfAdjustmentHeading = undefined;
    await this.soaUtils.initHeading();
    await this.onHeadingInfoUpdate(this.matter.statementOfAdjustmentHeading, true);

    // resseting footers

    this.matter.matterStatementOfAdjustmentFooters = [];
    await this.soaUtils.initSoaFooters();
    this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentFooters);

    await this.resetAdjustmentExceptSalePriceDeposit();

    await this.soaUtils.resetSalePriceAdj();
    await this.soaUtils.resetDepositAdj();
    this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

    await this.soaUtils.resetSalePriceAdj();
    await this.soaUtils.resetDepositAdj();

    this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

    this.tabService.activeTab.matterSoAdjFieldCodes = [];
  }

  async resetAdjustmentExceptSalePriceDeposit(): Promise<void> {

    let salePriceAndDepositAdjustmentsFinal = this.matter._finalAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment()).slice(0);
    this.matter._finalAdjustments = [];
    this.matter._finalAdjustments.push(...salePriceAndDepositAdjustmentsFinal);
    let salePriceAndDepositAdjustmentsInterim = this.matter._interimAdjustments.filter(item => item.isSalePrice() || item.isDepositAdjustment()).slice(0);
    this.matter._interimAdjustments = [];
    this.matter._interimAdjustments.push(...salePriceAndDepositAdjustmentsInterim);
  }

  async importProjectStatementAdjustment(projectAdjustmentActionType: string, sourceProject: Project): Promise<void> {
    await this.resetProjectStatementAdjustment(projectAdjustmentActionType);

    if (sourceProject) {
      let sourceMatter: Matter = await this.matterService.getMatter(sourceProject.templateMatterId).toPromise();

      // Resetting payable to
      this.matter.statementOfAdjustmentPayable = sourceMatter.statementOfAdjustmentPayable;
      this.matter.statementOfAdjustmentPayable.id = undefined;
      this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.SoaPayableTo);

      // Resetting heading
      this.matter.statementOfAdjustmentHeading = sourceMatter.statementOfAdjustmentHeading;
      this.matter.statementOfAdjustmentHeading.id = undefined;
      await this.onHeadingInfoUpdate(this.matter.statementOfAdjustmentHeading, true);

      // resseting footers

      this.matter.matterStatementOfAdjustmentFooters = sourceMatter.matterStatementOfAdjustmentFooters;
      this.matter.matterStatementOfAdjustmentFooters.map(function (item) {
        item.id = null;
      });
      this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.statementAdjustmentFooters);

      this.matter._finalAdjustments = [];
      this.matter.finalStatementAdjustmentOrders = [];
      sourceMatter._finalAdjustments.forEach(adjustment => {
        let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, adjustment);
        statementAdjustment.clearAllIds();
        statementAdjustment.matterId = null;
        statementAdjustment.id = UUIDUtil.getUUID();
        if (statementAdjustment.matterTax) {
          statementAdjustment.matterTax.id = UUIDUtil.getUUID();
        }
        this.soaUtils.createStatementAdjustmentOrder(statementAdjustment);
        this.matter._finalAdjustments.push(statementAdjustment);
      });

      this.matter._interimAdjustments = [];
      this.matter.interimStatementAdjustmentOrders = [];
      sourceMatter._interimAdjustments.forEach(adjustment => {
        let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, adjustment);
        statementAdjustment.clearAllIds();
        statementAdjustment.matterId = null;
        statementAdjustment.id = UUIDUtil.getUUID();
        if (statementAdjustment.matterTax) {
          statementAdjustment.matterTax.id = UUIDUtil.getUUID();
        }
        this.soaUtils.createStatementAdjustmentOrder(statementAdjustment);
        this.matter._interimAdjustments.push(statementAdjustment);
      });

      this.matter.matterPropertyWithCondo.soaPurchasePrice = sourceMatter.matterPropertyWithCondo.soaPurchasePrice;
      this.matter.considerationLtt.salePriceAdjustment = SalePriceAdjustmentFactory.getSalePriceAdjustment(sourceMatter.considerationLtt.salePriceAdjustment._matterAdjustmentStatusMode, sourceMatter.provinceCode, sourceMatter.considerationLtt.salePriceAdjustment);
      this.matter.considerationLtt.salePriceAdjustment.id = undefined;
      this.matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.forEach(item => {
        item.id = undefined;
      });
      this.matter.matterPropertyWithCondo.depositAmount = sourceMatter.matterPropertyWithCondo.depositAmount;
      this.matter.extraDepositConfig = sourceMatter.extraDepositConfig;
      this.matter.matterPropertyWithCondo.adjustmentFormat = sourceMatter.matterPropertyWithCondo.adjustmentFormat;
      this.matter.matterPropertyWithCondo.multipleDeposit = sourceMatter.matterPropertyWithCondo.multipleDeposit;
      this.matter.matterPropertyWithCondo.deposits = sourceMatter.matterPropertyWithCondo.deposits;
      this.matter.matterPropertyWithCondo.deposits.forEach(item => {
        item.id = undefined;
      });
      this.matter.updateUnitLevelPlanCreated(sourceMatter.matterPropertyWithCondo);
      StatementAdjustmentUtil.updateInterestOnDeferredMoniesDuringOccupancy(this.matter, this.project);
      StatementAdjustmentUtil.updateBuildersTarionNo(this.project.tarionNo, this.matter, this.project.insertTarionNo);
      this.matter.updateSalePriceAdjWithHstReduction(this.project.projectAdjustmentConfig.hstReductionInSalePriceAdjustment, this.soaUtils.ontarioTaxRateSlab, this.soaUtils.torontoTaxRateSlab, this.soaUtils.soaConsiderationTaxes);
      this.matter.updateProjectTaxChange(this.soaUtils.soaConsiderationTaxes, this.project.projectAdjustmentConfig);
      this.matter.recalculateAllInterimOccupancyFee(this.project);
      this.matter.recalculateAllFinalEarlyPossessionFee();
      this.matter.recalculateAllTaxesPaidDuringOccupancy();
      this.matter.recalculateAllTenancyCurrent();
      this.matter.recalculateAllAssumedMortgage();

      await this.soaUtils.recalculateDepositSOAOnAdjustmentChanges();

      await this.soaUtils.updateDisplayItems();
      this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);

      await this.soaUtils.recalculateDepositSOAOnAdjustmentChanges();

      await this.soaUtils.updateDisplayItems();
      this.matter.updateStatusMode(this.matter.isAdjustmentStatusModeFinal ? ProgressionStatus.INTERIM : ProgressionStatus.FINAL);
    }
  }

  async onHeadingInfoUpdate(statementAdjustmentHeading: StatementOfAdjustmentHeading, addToDataPropagationCommand: boolean = true): Promise<void> {
    if (statementAdjustmentHeading) {
      this.matter.statementOfAdjustmentHeading = statementAdjustmentHeading;
      this.matter.statementOfAdjustments.filter(item => item.hstSalePrice != undefined).map(function (statementOfAdjustment) {
        if (statementOfAdjustment.hstSalePrice.isVendorSelected) {
          statementOfAdjustment.hstSalePrice.creditType = statementAdjustmentHeading.rightColumnListsCreditsTo;
        } else {
          statementOfAdjustment.hstSalePrice.creditType = statementAdjustmentHeading.leftColumnListsCreditsTo;
        }

      });
      this.soaUtils.setPaperSizeCode(statementAdjustmentHeading.paperSizeCode ?
        statementAdjustmentHeading.paperSizeCode : this.matterDocumentProfile.statementOfAdjustmentsProfile.paperSizeCode);
      if (addToDataPropagationCommand) {
        this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.SoaHeading);
      }

      await this.soaUtils.enableSave();
    }
  }

  async fulfillCreditVendorTaxRebateModal(result, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result) {
      await this.onCreditVendorTaxRebateModalUpdate(result.soAdjCreditVendorTaxRebate, statementAdjustment);
    }

    await this.soaUtils.enableSave(true);
    this.soaUtils.setFocusAfterModalClose();
  }

  async onCreditVendorTaxRebateModalUpdate(soAdjCreditVendorTaxRebate: SoAdjCreditVendorTaxRebate, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (soAdjCreditVendorTaxRebate) {
      if (statementAdjustment) {
        statementAdjustment.soAdjCreditVendorTaxRebate = new SoAdjCreditVendorTaxRebate(soAdjCreditVendorTaxRebate);
        statementAdjustment.itemKey = StatementAdjustmentKey.CREDIT_VENDOR_TAX_REBATE;
        statementAdjustment.description = statementAdjustment.soAdjCreditVendorTaxRebate.adjustmentHeading;
        statementAdjustment.statementOfAdjustmentCreditType
          = soAdjCreditVendorTaxRebate.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value
          ? StatementAdjustmentAmountTypes.VENDOR
          : null;
        statementAdjustment.amount
          = soAdjCreditVendorTaxRebate.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value
          ? this.matter.considerationLtt.salePriceAdjustment.totalRebatePortionWithTax(this.provinceHstRate,
            this.federalHstRate,
            this.matter.getSalePriceForTaxAdjustments(this.federalHstRate, this.provinceHstRate),
            soAdjCreditVendorTaxRebate.isAdjusted === 'YES'
              ? soAdjCreditVendorTaxRebate.percentage
              : null
          )
          : null;
      } else {
        this.createUpdateCreditVendorTaxRebate(soAdjCreditVendorTaxRebate, this.matter.considerationLtt.salePriceAdjustment);
      }
    } else {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true);
    }
    // update sale price adjustment
    // IF Sale Price Adjustment <> 'No' THEN The Credit Vendor amount is added to the Sale Price adjustment with the following label '+HST Rebate Purchaser Not Eligible' and considered as part of its calculation
    // IF more than one Credit Vendor HST Rebate Purchaser Not Eligible adjustment exists, only the credit value from the first adjustment is added to the Sale Price
    await this.soaUtils.updateSalePriceConsiderationLTT();
  }

  createUpdateCreditVendorTaxRebate(soAdjCreditVendorTaxRebate: SoAdjCreditVendorTaxRebate, salePriceAdjustment: SalePriceAdjustment): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.CREDIT_VENDOR_TAX_REBATE;
    statementAdjustment.soAdjCreditVendorTaxRebate = new SoAdjCreditVendorTaxRebate(soAdjCreditVendorTaxRebate);
    statementAdjustment.soAdjCreditVendorTaxRebate.id = undefined;
    statementAdjustment.description = statementAdjustment.soAdjCreditVendorTaxRebate.adjustmentHeading;
    statementAdjustment.statementOfAdjustmentCreditType
      = soAdjCreditVendorTaxRebate.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value
      ? StatementAdjustmentAmountTypes.VENDOR
      : null;
    statementAdjustment.amount
      = soAdjCreditVendorTaxRebate.showAdjustmentAs === creditVendorTaxRebateShowTypes.separateAdjustment.value
      ? salePriceAdjustment.totalRebatePortionWithTax(this.federalHstRate,
        this.provinceHstRate,
        this.matter.getSalePriceForTaxAdjustments(this.federalHstRate, this.provinceHstRate),
        soAdjCreditVendorTaxRebate.isAdjusted === 'YES' ? soAdjCreditVendorTaxRebate.percentage : null)
      : null;
    statementAdjustment.addedFlag = true;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  onCommonExpenseModalUpdate(soaCommonExpense: any, jumpToSubjectProperty: boolean = false, statementAdjustment?: StatementAdjustment, infoOnly?: boolean): void {
    if (soaCommonExpense) {
      if (statementAdjustment) {
        statementAdjustment.soaCommonExpense = new SoaCommonExpense(soaCommonExpense);
        statementAdjustment.updateFromCommonExpense(this.adjustmentDate, this.matter.isPaysForDateOfClosingVendor);
      } else {
        this.createUpdateSoaCommonExpense(soaCommonExpense, this.adjustmentDate, infoOnly);
        this.updateCommonExpenseDependencies();
      }
    } else if (jumpToSubjectProperty) {
      let matterTab = this.tabService.activeTab as MatterTab;
      let matterComponent = matterTab && matterTab.matterComponent;
      let section: Section = matterComponent && matterComponent.applicableSections.find(item => item.route == 'propertyTeranet');
      matterComponent.openMatterSection('propertyTeranet/subjectProperty', section);

    } else {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true);
      this.updateCommonExpenseDependencies();
    }
  }

  createUpdateSoaCommonExpense(soaCommonExpense: SoaCommonExpense, closingDate: string, infoOnly?: boolean): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = soaCommonExpense.expenseType;
    statementAdjustment.soaCommonExpense = new SoaCommonExpense(soaCommonExpense);
    statementAdjustment.soaCommonExpense.id = undefined;
    statementAdjustment.amount = soaCommonExpense.calculateVendorShareDifference(closingDate, this.matter.isPaysForDateOfClosingVendor);
    statementAdjustment.description = statementAdjustment.getCommonExpenseLabel(this.matter.provinceCode);
    statementAdjustment.statementOfAdjustmentCreditType = soaCommonExpense.adjustmentCreditType(closingDate, this.matter.isPaysForDateOfClosingVendor);
    statementAdjustment.addedFlag = true;
    statementAdjustment.infoOnly = infoOnly;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  private updateCommonExpenseDependencies(): void {
    SoaExpenseAdjustmentUtil.updateSalesIncentiveAdjustments(this.matter, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
    SoaExpenseAdjustmentUtil.updateReserveFundAdjustments(this.matter, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
    SoaExpenseAdjustmentUtil.updateCommonExpenseAdjustments(this.matter, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
    this.matter.updateInterimOccupancyFeeAdjOnChange();
  }

  onRealtyTaxAdjustmentUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soaRealtyTaxAdjustment) {
      statementAdjustment.soaRealtyTaxAdjustment = new SoaRealtyTaxAdjustment(result);
      statementAdjustment.amount = statementAdjustment.soaRealtyTaxAdjustment.creditAmount;
      statementAdjustment.description = statementAdjustment.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear + ' REALTY TAXES';
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    } else {
      let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      statementAdjustment.soaRealtyTaxAdjustment = new SoaRealtyTaxAdjustment(result);
      statementAdjustment.itemKey = StatementAdjustmentKey.REALTY_TAXES;
      statementAdjustment.soaRealtyTaxAdjustment.id = undefined;
      statementAdjustment.amount = result.creditAmount;
      statementAdjustment.description = statementAdjustment.soaRealtyTaxAdjustment.realtyTaxAdjustmentYear + ' REALTY TAXES';
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
      statementAdjustment.addedFlag = true;
      this.soaUtils.selectedItem(statementAdjustment);
    }
  }

  async onPurchasePriceDeferredPortionModalUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result == ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment);
      }
    } else {
      if (statementAdjustment) {
        statementAdjustment.soAdjPurchasePriceDeferredPortion = new SoAdjPurchasePriceDeferredPortion(result);
        statementAdjustment.amount = statementAdjustment.soAdjPurchasePriceDeferredPortion.amount;
        statementAdjustment.description = statementAdjustment.soAdjPurchasePriceDeferredPortion.titleOfAdjustment;
        statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
      } else {
        this.createUpdatePurchasePriceDeferredPortion(result);
      }
    }
    await this.soaUtils.enableSave();
  }

  createUpdatePurchasePriceDeferredPortion(soAdjPurchasePriceDeferredPortion: SoAdjPurchasePriceDeferredPortion): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.PURCHASE_PRICE_DEFERRED_PORTION;
    statementAdjustment.soAdjPurchasePriceDeferredPortion = new SoAdjPurchasePriceDeferredPortion(soAdjPurchasePriceDeferredPortion);
    statementAdjustment.soAdjPurchasePriceDeferredPortion.id = undefined;
    statementAdjustment.amount = soAdjPurchasePriceDeferredPortion.amount;
    statementAdjustment.description = soAdjPurchasePriceDeferredPortion.titleOfAdjustment;
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
    statementAdjustment.addedFlag = true;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  onAmountPaidOnInterimClosingModalUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (result == ModalResult.Delete) { // Delete
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true /*,true*/);
      }
    } else { // OK

      if (statementAdjustment && statementAdjustment.soAdjAmountPaidOnInterimClosing) {
        statementAdjustment.soAdjAmountPaidOnInterimClosing = new SoAdjAmountPaidOnInterimClosing(result);
      } else {
        if (!statementAdjustment) {
          statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
          statementAdjustment.itemKey = StatementAdjustmentKey.AMOUNT_PAID_ON_INTERIM_CLOSING;
          statementAdjustment.addedFlag = true;
        }
        statementAdjustment.soAdjAmountPaidOnInterimClosing = new SoAdjAmountPaidOnInterimClosing(result);
        statementAdjustment.soAdjAmountPaidOnInterimClosing.id = undefined;
        this.soaUtils.selectedItem(statementAdjustment);
      }

      statementAdjustment.amount = StatementAdjustmentUtil.amountPaidOnInterimClosing(statementAdjustment.soAdjAmountPaidOnInterimClosing, this.matter);
      statementAdjustment.description = statementAdjustment.soAdjAmountPaidOnInterimClosing.adjustmentHeading;
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
      statementAdjustment.infoOnly = statementAdjustment.soAdjAmountPaidOnInterimClosing.infoOnly;

    }
  }

  onConsiderationPaidOnInterimClosingModalUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (result == ModalResult.Delete) { // Deal Delete
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true); //Confirm Noha if need removeFromFieldCodeList
      }
    } else { //Deal OK

      if (statementAdjustment && statementAdjustment.soAdjConsiderationPaidOnInterimClosing) {
        statementAdjustment.soAdjConsiderationPaidOnInterimClosing = new SoAdjConsiderationPaidOnInterimClosing(result);
      } else {
        if (!statementAdjustment) {
          statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
          statementAdjustment.itemKey = StatementAdjustmentKey.CONSIDERATION_PAID_ON_INTERIM_CLOSING;
          statementAdjustment.addedFlag = true;
        }
        statementAdjustment.soAdjConsiderationPaidOnInterimClosing = new SoAdjConsiderationPaidOnInterimClosing(result);
        statementAdjustment.soAdjConsiderationPaidOnInterimClosing.id = undefined;
        this.soaUtils.selectedItem(statementAdjustment);
      }

      statementAdjustment.amount = StatementAdjustmentUtil.getConsiderationPaidOnInterimClosingAmount(this.matter.considerationLtt.salePriceAdjustment, this.matter.uniqueStatementAdjustments, null);
      statementAdjustment.description = statementAdjustment.soAdjConsiderationPaidOnInterimClosing.adjustmentHeading;
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
      statementAdjustment.infoOnly = statementAdjustment.soAdjConsiderationPaidOnInterimClosing.infoOnly;
    }
  }

  onTotalDuringOccupancyPeriodAdjustmentModalUpdate(soAdjExpenseOccupancyPeriod: SoAdjExpenseOccupancyPeriod, statementAdjustment?: StatementAdjustment): void {
    if (soAdjExpenseOccupancyPeriod) {
      if (statementAdjustment) {
        statementAdjustment.soAdjExpenseOccupancyPeriod = new SoAdjExpenseOccupancyPeriod(soAdjExpenseOccupancyPeriod);
        statementAdjustment.amount = soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTotalAsNumber(this.matter.getUnitLevelPlanCondominiumTotalExpenses(),
          this.matter.getCondominiumTotalSharePercentage(),
          this.matter.soAdjExpenseOccupancyPeriodClosedate,
          this.matter.soAdjExpenseOccupancyPeriodOccupancyDate);
        statementAdjustment.description = statementAdjustment.soAdjExpenseOccupancyPeriod.adjustmentHeading;
        statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
      } else {
        this.createUpdateTotalDuringOccupancyPeriod(soAdjExpenseOccupancyPeriod);
      }
    } else {
      this.soaUtils.removeStatementAdjustment(statementAdjustment);
    }
  }

  createUpdateTotalDuringOccupancyPeriod(soAdjExpenseOccupancyPeriod: SoAdjExpenseOccupancyPeriod): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = StatementAdjustmentKey.OCCUPANCY_PERIOD;
    statementAdjustment.soAdjExpenseOccupancyPeriod = new SoAdjExpenseOccupancyPeriod(soAdjExpenseOccupancyPeriod);
    statementAdjustment.soAdjExpenseOccupancyPeriod.id = undefined;
    statementAdjustment.amount = soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTotalAsNumber(this.matter.getUnitLevelPlanCondominiumTotalExpenses(),
      this.matter.getCondominiumTotalSharePercentage(),
      this.matter.soAdjExpenseOccupancyPeriodClosedate,
      this.matter.soAdjExpenseOccupancyPeriodOccupancyDate);
    statementAdjustment.description = statementAdjustment.soAdjExpenseOccupancyPeriod.adjustmentHeading;
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;
    statementAdjustment.addedFlag = true;
    this.soaUtils.selectedItem(statementAdjustment);
  }

  onInterestAdjustmentUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment && statementAdjustment.soAdjInterestCal) {
      statementAdjustment.soAdjInterestCal = new InterestSoa(result);
      statementAdjustment.amount = statementAdjustment.soAdjInterestCal.calculateSoAInterest(this.matter.provinceCode, this.matter.isPaysForDateOfClosingVendor, statementAdjustmentKey === StatementAdjustmentKey.INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY);
      statementAdjustment.description = 'INTEREST ON ' + (statementAdjustment.soAdjInterestCal.itemName ? statementAdjustment.soAdjInterestCal.itemName : '');
      statementAdjustment.statementOfAdjustmentCreditType = (statementAdjustment.soAdjInterestCal.isInterestCreditTo == this.soaUtils.purchaserLabel ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR);
    } else {
      let newSoAdj = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      newSoAdj.itemKey = statementAdjustmentKey;
      newSoAdj.soAdjInterestCal = new InterestSoa(result);
      newSoAdj.soAdjInterestCal.id = undefined;
      newSoAdj.amount = newSoAdj.soAdjInterestCal.calculateSoAInterest(this.matter.provinceCode, this.matter.isPaysForDateOfClosingVendor, statementAdjustmentKey === StatementAdjustmentKey.INTEREST_ON_DEFERRED_MONIES_DURING_OCCUPANCY);
      newSoAdj.description = 'INTEREST ON ' + (newSoAdj.soAdjInterestCal.itemName ? newSoAdj.soAdjInterestCal.itemName : '');
      newSoAdj.statementOfAdjustmentCreditType = (newSoAdj.soAdjInterestCal.isInterestCreditTo == this.soaUtils.purchaserLabel ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR);
      newSoAdj.addedFlag = true;
      this.soaUtils.selectedItem(newSoAdj);
    }
  }

  async fulfillOtherFixedAdjustment(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result) {
      if (result != ModalResult.Cancel) {
        await this.onOtherFixedAdjustmentUpdate(result, statementAdjustment);
      }
      await this.soaUtils.enableSave();
    }
    this.soaUtils.setFocusAfterModalClose();
  }

  async onOtherFixedAdjustmentUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.statementOfAdjustments);
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soAdjOtherFixed) {
      statementAdjustment.soAdjOtherFixed = new SoAdjOtherFixed(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.OTHER_FIXED;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjOtherFixed = new SoAdjOtherFixed(result);
      statementAdjustment.soAdjOtherFixed.id = undefined;
      if (statementAdjustment.soAdjOtherFixed.items) {
        statementAdjustment.soAdjOtherFixed.items.forEach(item => {
          item.id = undefined;
        });
      }

      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = StatementAdjustmentUtil.getAmountAdjOtherFixed(statementAdjustment, this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustmentHeading);
    statementAdjustment.description = statementAdjustment.soAdjOtherFixed.adjustmentHeading;
    statementAdjustment.infoOnly = statementAdjustment.soAdjOtherFixed.infoOnly;
    await this.updateDependenciesOnOtherFixedAndMsg(statementAdjustment, existingConsiderationFromTarionOrOtherFixed);
  }

  /**
   * Wrap all related functions into one function
   * @param statementAdjustment
   * @returns {Promise<void>}
   */
  async updateDependenciesOnOtherFixedAndMsg(statementAdjustment: StatementAdjustment, existingConsiderations ?: boolean): Promise<void> {
    await this.updateDependenciesOnOtherFixed(statementAdjustment, existingConsiderations);
    this.checkHSTRebateToDisplayWarningMessageForPSMOrProject();
  }

  async updateDependenciesOnOtherFixed(statementAdjustment: StatementAdjustment, existingConsiderations ?: boolean): Promise<void> {
    let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.statementOfAdjustments);
    StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments, existingConsiderationFromTarionOrOtherFixed || existingConsiderations);
    StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.matter.considerationLtt.salePriceAdjustment, this.matter.uniqueStatementAdjustments, existingConsiderationFromTarionOrOtherFixed || existingConsiderations);
    await this.soaUtils.updateSalePriceConsiderationLTT();
    this.soaUtils.updateHstSalePriceAdjustment();
    // a change on OtherFixedAmount adj. amount value might move the net sale price on other bracket for TARION Warranty
    StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments, this.soaUtils.statementAdjustmentDisplayUtil, this.matter.projectAdjustmentConfig);
    SoaRealtyTaxAdjustmentUtil.updateRealtyTaxAdjustmentOnDateChange(this.matter);
    this.soaUtils.updateSalePriceMinusNetSalePriceAdjustment();
    StatementAdjustmentUtil.updateAmountPaidOnInterimClosingAdj(this.matter);
    await this.soaUtils.customRemove(statementAdjustment);
  }

  async fulfillOtherFixedPayableOnOccupancyAdjustment(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result) {
      if (result != ModalResult.Cancel) {
        await this.onOtherFixedPayableOnOccupancyAdjustmentUpdate(result, statementAdjustment);
      }
      await this.soaUtils.enableSave();
    }
    this.soaUtils.setFocusAfterModalClose();
  }

  async onOtherFixedPayableOnOccupancyAdjustmentUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.statementOfAdjustments);
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soAdjOtherFixedPayableOnOccupancy) {
      statementAdjustment.soAdjOtherFixedPayableOnOccupancy = new SoAdjOtherFixed(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjOtherFixedPayableOnOccupancy = new SoAdjOtherFixed(result);
      statementAdjustment.soAdjOtherFixedPayableOnOccupancy.id = undefined;
      if (statementAdjustment.soAdjOtherFixedPayableOnOccupancy.items) {
        statementAdjustment.soAdjOtherFixedPayableOnOccupancy.items.forEach(item => {
          item.id = undefined;
        });
      }

      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = StatementAdjustmentUtil.getAmountAdjOtherFixedPayableOnOccupancy(statementAdjustment, this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustmentHeading);
    statementAdjustment.description = statementAdjustment.soAdjOtherFixedPayableOnOccupancy.adjustmentHeading;
    statementAdjustment.statementOfAdjustmentCreditType = (statementAdjustment.soAdjOtherFixedPayableOnOccupancy.creditType == OtherFixedCreditTypes.PURCHASER ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR);
    await this.updateDependenciesOnOtherFixedAndMsg(statementAdjustment, existingConsiderationFromTarionOrOtherFixed);
  }

  onComponentAdjustmentUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (!statementAdjustment) {
      statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
      statementAdjustment.itemKey = statementAdjustmentKey;
      statementAdjustment.addedFlag = true;
      this.soaUtils.selectedItem(statementAdjustment);
    }
    statementAdjustment.soAdjComponent = new SoAdjComponent(result.componentSoa);
    statementAdjustment.amount = statementAdjustment.soAdjComponent.creditNoteAmount;
    statementAdjustment.description = this.formatComponentAdjustments(statementAdjustment);
    statementAdjustment.statementOfAdjustmentCreditType = (statementAdjustment.soAdjComponent.creditType == ComponentAdjCreditTypeValue.PURCHASER ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR);
  }

  formatComponentAdjustments(statementAdjustment: StatementAdjustment) {
    if (statementAdjustment.soAdjComponent.useAdjustmentHeading == 'NO' && statementAdjustment.soAdjComponent.items.length > 0) {
      return statementAdjustment.soAdjComponent.items[ 0 ].itemName;
    } else {
      return statementAdjustment.soAdjComponent.adjustmentHeading;
    }
  }

  onOtherProRatedAdjustmentUpdate(statementAdjustmentKey: string, result: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment && statementAdjustment.soAdjOtherProrated) {
      statementAdjustment.soAdjOtherProrated = new SoAdjOtherProrated(result);

    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.OTHER_PRORATED;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjOtherProrated = new SoAdjOtherProrated(result);
      statementAdjustment.soAdjOtherProrated.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = statementAdjustment.soAdjOtherProrated.getCreditAmount(this.adjustmentDate, statementAdjustmentKey, this.matter.paysForDateOfClosing);
    statementAdjustment.description = statementAdjustment.soAdjOtherProrated.heading;
    statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjOtherProrated.getCreditType(this.adjustmentDate, statementAdjustmentKey, this.matter.paysForDateOfClosing);
    statementAdjustment.infoOnly = statementAdjustment.soAdjOtherProrated.infoOnly;
  }

  async fulfillOtherProratedOnPercentageInterestModal(result, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result != ModalResult.Cancel) {
      await this.onOtherProRatedOnPercentageInterestAdjustmentUpdate(statementAdjustmentKey, result, statementAdjustment);
    }
    await this.soaUtils.enableSave();
  }

  async onOtherProRatedOnPercentageInterestAdjustmentUpdate(statementAdjustmentKey: string, result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soAdjOtherProratedOnPercentageInterest) {
      statementAdjustment.soAdjOtherProratedOnPercentageInterest = new SoAdjOtherFixed(result);
    } else {
      if (!statementAdjustment && result == ModalResult.Delete) {
        return;
      }
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjOtherProratedOnPercentageInterest = new SoAdjOtherFixed(result);
      statementAdjustment.soAdjOtherProratedOnPercentageInterest.id = undefined;
      if (statementAdjustment.soAdjOtherProratedOnPercentageInterest.items) {
        statementAdjustment.soAdjOtherProratedOnPercentageInterest.items.forEach(item => {
          item.id = undefined;
        });
      }

      this.soaUtils.selectedItem(statementAdjustment);
      this.checkHSTRebateToDisplayWarningMessageForPSMOrProject();
    }

    statementAdjustment.description = statementAdjustment.soAdjOtherProratedOnPercentageInterest.adjustmentHeading;

    if (statementAdjustment.soAdjOtherProratedOnPercentageInterest.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT || statementAdjustment.soAdjOtherProratedOnPercentageInterest.creditType == OtherFixedCreditTypes.SILENT) {
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
    } else {
      statementAdjustment.statementOfAdjustmentCreditType = (statementAdjustment.soAdjOtherProratedOnPercentageInterest.creditType == StatementAdjustmentAmountTypes.PURCHASER ? StatementAdjustmentAmountTypes.PURCHASER : StatementAdjustmentAmountTypes.VENDOR);
      statementAdjustment.amount = StatementAdjustmentUtil.getAmountOtherProRatedOnPercentageInterest(statementAdjustment,
        this.matter.isMatterProvinceAB ? Number(this.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) : Number(this.matter.matterPropertyWithCondo.condominiumTotalSharePercentage),
        this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustmentHeading);
    }
    await this.updateDependenciesOnOtherProRatedOnPercentageInterest(statementAdjustment);
  }

  /**
   * Wrap all related functions into one function
   * @param statementAdjustment
   * @returns {Promise<void>}
   */
  async updateDependenciesOnOtherProRatedOnPercentageInterest(statementAdjustment: StatementAdjustment): Promise<void> {
    await this.updateDependenciesOnOtherFixed(statementAdjustment);
    // a change on %share should be applied to all other adjustments of this type
    StatementAdjustmentUtil.updateOtherProRatedOnPercentageInterest(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments,
      this.matter.isMatterProvinceAB ? Number(this.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) : Number(this.matter.matterPropertyWithCondo.condominiumTotalSharePercentage),
      this.matter.statementOfAdjustmentHeading);
  }

  onOtherPurchasePricePercentageAdjustmentUpdate(statementAdjustmentKey: string, result: any, statementAdjustment?: StatementAdjustment): void {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soAdjPurchasePricePercentage) {
      statementAdjustment.soAdjPurchasePricePercentage = new SoAdjPurchasePricePercentage(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.PURCHASE_PRICE_PERCENTAGE;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjPurchasePricePercentage = new SoAdjPurchasePricePercentage(result);
      statementAdjustment.soAdjPurchasePricePercentage.id = undefined;

      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.description = statementAdjustment.soAdjPurchasePricePercentage.adjustmentHeading;

    statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjPurchasePricePercentage.creditType;
    statementAdjustment.amount = StatementAdjustmentUtil.getAmountPurchasePricePercentage(statementAdjustment);

  }

  onHomeOwnersResidentFeeModalUpdate(result: any, statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (result == ModalResult.Delete && statementAdjustment) {
      this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
    } else if (statementAdjustment && statementAdjustment.soAdjHomeOwnersResidentFee) {
      statementAdjustment.soAdjHomeOwnersResidentFee = new SoAdjOtherProrated(result);

    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjHomeOwnersResidentFee = new SoAdjOtherProrated(result);
      statementAdjustment.soAdjHomeOwnersResidentFee.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = statementAdjustment.soAdjHomeOwnersResidentFee.getCreditAmount(this.adjustmentDate, statementAdjustmentKey, this.matter.paysForDateOfClosing);
    statementAdjustment.description = statementAdjustment.soAdjHomeOwnersResidentFee.heading;
    statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjHomeOwnersResidentFee.getCreditType(this.adjustmentDate, statementAdjustmentKey, this.matter.paysForDateOfClosing);
  }

  onTenancyCurrentUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment && statementAdjustment.soAdjTenancyCurrent) {
      statementAdjustment.soAdjTenancyCurrent = new SoAdjTenancyCurrent(result);

    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.TENANCY_CURRENT;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjTenancyCurrent = new SoAdjTenancyCurrent(result);
      statementAdjustment.soAdjTenancyCurrent.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = result.getTotalAmount(this.adjustmentDate, this.matter.isPaysForDateOfClosingVendor);
    statementAdjustment.description = result.adjustmentDescription;
    statementAdjustment.statementOfAdjustmentCreditType = result.creditType;
  }

  onTenancyPrepiadUpdate(result: any, statementAdjustment?: StatementAdjustment): void {
    if (statementAdjustment && statementAdjustment.soAdjTenancyPrepaid) {
      statementAdjustment.soAdjTenancyPrepaid = new SoAdjTenancyPrepaid(result);

    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.TENANCY_PREPAID;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjTenancyPrepaid = new SoAdjTenancyPrepaid(result);
      statementAdjustment.soAdjTenancyPrepaid.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = result.totalCreditPurchaser;
    statementAdjustment.description = provinceBasedFieldLabels.get('matter.soa.prePaidRent', this.matter.provinceCode);
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
  }

  onAssumedMortgageUpdate(result: any, statementAdjustment?: StatementAdjustment): void {

    if (statementAdjustment && statementAdjustment.soAdjAssumedMortgage) {
      statementAdjustment.soAdjAssumedMortgage = new SoAdjAssumedMortgage(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.ASSUMED_MORTGAGE;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjAssumedMortgage = new SoAdjAssumedMortgage(result);
      statementAdjustment.soAdjAssumedMortgage.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    statementAdjustment.amount = result.totalCreditPurchaser;
    statementAdjustment.description = result.adjustmentDescription;
    statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
  }

  async fullFillHCRAFee(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    await this.onHCRAFeeUpdate(result, statementAdjustment);
    await this.soaUtils.enableSave();
  }

  async onHCRAFeeUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.allStatementAdjustments);
    if (result == ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjHCRAFee) {
      statementAdjustment.soAdjHCRAFee = new SoAdjHCRAFee(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.HCRA_FEE;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjHCRAFee = new SoAdjHCRAFee(result);
      statementAdjustment.soAdjHCRAFee.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }
    let salePriceAdj: SalePriceAdjustment = this.matter.considerationLtt.salePriceAdjustment;
    statementAdjustment.amount = StatementAdjustmentDisplayHcraFeeBuilder.calculateAdjustmentAmount(salePriceAdj, statementAdjustment.soAdjHCRAFee, this.soaUtils.statementAdjustmentDisplayUtil);
    statementAdjustment.description = statementAdjustment.soAdjHCRAFee.adjustmentDescription;
    if (statementAdjustment.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE' && salePriceAdj && salePriceAdj.isSalePriceFormatNSP()) {
      statementAdjustment.statementOfAdjustmentCreditType = 'VENDOR';
    } else {
      statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjHCRAFee.creditType;
    }

    await this.updateDependenciesOnTarionWarranty(existingConsiderationFromTarionOrOtherFixed);
  }

  async updateDependenciesOnTarionWarranty(existingConsiderationFromTarionOrOtherFixed: boolean): Promise<void> {
    StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments, existingConsiderationFromTarionOrOtherFixed);
    // it could be that the amount was right on threshold and by adding the enrolment fee sale price moved into another bracket
    StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments, this.soaUtils.statementAdjustmentDisplayUtil, this.matter.projectAdjustmentConfig);
    // update additional consideration added by Tarion Warranty
    StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(this.matter.considerationLtt.salePriceAdjustment, this.matter.statementOfAdjustments, existingConsiderationFromTarionOrOtherFixed);
    StatementAdjustmentUtil.updateAdditionalConsiderationPaidOnInterimClosing(this.matter.considerationLtt.salePriceAdjustment, this.matter.uniqueStatementAdjustments, existingConsiderationFromTarionOrOtherFixed);
    await this.soaUtils.updateSalePriceConsiderationLTT();
    this.checkHSTRebateToDisplayWarningMessageForPSMOrProject();
    this.soaUtils.updateHstSalePriceAdjustment();
  }

  async fullFillVacantOccupancyTax(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    await this.onVacantOccupancyTaxUpdate(result, statementAdjustment);
    await this.soaUtils.enableSave();
  }

  async onVacantOccupancyTaxUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    if (result == ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjVacantOccupancyTax) {
      statementAdjustment.soAdjVacantOccupancyTax = new SoAdjVacantOccupancyTax(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.VACANT_OCCUPANCY_TAX;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjVacantOccupancyTax = new SoAdjVacantOccupancyTax(result);
      statementAdjustment.soAdjVacantOccupancyTax.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }
    statementAdjustment.amount = StatementAdjustmentDisplayVacantOccupancyTaxBuilder.calculateVacantOccupancyTaxAmount(statementAdjustment.soAdjVacantOccupancyTax);
    statementAdjustment.description = statementAdjustment.soAdjVacantOccupancyTax.adjustmentDescription;
    statementAdjustment.statementOfAdjustmentCreditType = 'PURCHASER';
  }

  async fullFillTarionWarranty(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    await this.onTarionWarrantyUpdate(result, statementAdjustment);
    await this.soaUtils.enableSave();
  }

  async onTarionWarrantyUpdate(result: any, statementAdjustment?: StatementAdjustment): Promise<void> {
    let existingConsiderationFromTarionOrOtherFixed = StatementAdjustmentUtil.isAdditionalConsiderationsFromTarionOrOtherFixedorHCRA(this.matter.allStatementAdjustments);
    if (result == ModalResult.Delete) {
      if (statementAdjustment) {
        this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
      } else {
        return;
      }
    } else if (statementAdjustment && statementAdjustment.soAdjTarionWarranty) {
      statementAdjustment.soAdjTarionWarranty = new SoAdjTarionWarranty(result);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.itemKey = StatementAdjustmentKey.TARION_WARRANTY;
        statementAdjustment.addedFlag = true;
      }
      statementAdjustment.soAdjTarionWarranty = new SoAdjTarionWarranty(result);
      statementAdjustment.soAdjTarionWarranty.id = undefined;
      this.soaUtils.selectedItem(statementAdjustment);
    }

    let salePriceAdj: SalePriceAdjustment = this.matter.considerationLtt.salePriceAdjustment;
    statementAdjustment.amount = StatementAdjustmentDisplayTarionWarrantyBuilder.calculateAdjustmentAmount(salePriceAdj, statementAdjustment.soAdjTarionWarranty, this.soaUtils.statementAdjustmentDisplayUtil, this.matter.projectAdjustmentConfig);
    statementAdjustment.description = statementAdjustment.soAdjTarionWarranty.adjustmentDescription;
    if (statementAdjustment.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE' && salePriceAdj.isSalePriceFormatNSP()) {
      statementAdjustment.statementOfAdjustmentCreditType = 'VENDOR'; // Purchaser is credited with Enroll fee - part of Additional Consideration, vendor is credited with HST only
    } else {
      statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjTarionWarranty.creditType;
    }
    await this.updateDependenciesOnTarionWarranty(existingConsiderationFromTarionOrOtherFixed);
  }

  async fulfillTaxRateModal(considerationTaxes: ConsiderationTaxes): Promise<void> {
    this.matter.soaHst = considerationTaxes.hstRate;
    this.matter.soaFederalHst = considerationTaxes.hstFederalPortion;
    this.matter.soaProvincialHst = considerationTaxes.hstProvincialPortion;
    this.matter.matterTaxType = considerationTaxes.rateType;
    this.soaUtils.setSoaConsiderationTaxes(considerationTaxes);
    if (this.matter.considerationLtt) {
      this.matter.matterPropertyWithCondo.soaPurchasePrice = this.matter.considerationLtt.creditVendorPrice(this.soaUtils.soaConsiderationTaxes);
      await this.soaUtils.updateSalePriceConsiderationLTT();
    }

    this.soaUtils.updateHstSalePriceAdjustment();
    this.soaUtils.updateCommissionPayableToVendorBroker();
    this.soaUtils.updateSalePriceMinusNetSalePriceAdjustment();
    await this.soaUtils.enableSave();
  }

  async fulfillEditStatementAdjustmentFormat(): Promise<void> {
    await this.soaUtils.enableSave();
    this.soaUtils.setFocusAfterModalClose();
    await this.soaUtils.updateDisplayItems();
  }

  addReserveForVTBMtg(statementAdjustmentKey: string): void {
    let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
    statementAdjustment.itemKey = statementAdjustmentKey;
    statementAdjustment.addedFlag = true;
    statementAdjustment.description = RESERVE_FOR_VTB_MTG_TEXT;
    this.soaUtils.selectedItem(statementAdjustment);
    if (this.soaUtils.isProjectConfigTab && this.soaUtils.isProjectConfigForInterim && this.matter.interimStatementAdjustments && this.matter.interimStatementAdjustments.length) {
      this.copyInterimFinalAdjustment(statementAdjustment, !this.matter.isAdjustmentStatusModeFinal, false);
    }
    this.soaUtils.enableSave();
  }

  async copyInterimFinalAdjustment(statementAdjustmentExisting: StatementAdjustment, moveAdjustmentToFinal: boolean, removeAdjustment: boolean, doNotShowWarning: boolean = false): Promise<void> {

    if (this.matter && ((this.matter.isAdjustmentStatusModeFinal && !moveAdjustmentToFinal) || (!this.matter.isAdjustmentStatusModeFinal && moveAdjustmentToFinal))) {
      let statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode, statementAdjustmentExisting);
      if (moveAdjustmentToFinal) {
        this.matter.finalStatementAdjustments.push(statementAdjustment);
        statementAdjustment.adjustmentStatus = ProgressionStatus.FINAL;
      } else {
        this.matter.interimStatementAdjustments.push(statementAdjustment);
        statementAdjustment.adjustmentStatus = ProgressionStatus.INTERIM;
      }

      if (removeAdjustment) {
        await this.soaUtils.removeAdjustmentAndUpdateMatter(statementAdjustmentExisting, true, false, false, doNotShowWarning);
      } else {
        statementAdjustment.clearAllIds();
        statementAdjustment.id = UUIDUtil.getUUID();
        if (statementAdjustment.matterTax) {
          statementAdjustment.matterTax.id = UUIDUtil.getUUID();
        }
        statementAdjustment.linkId = UUIDUtil.getUUID();
        statementAdjustmentExisting.linkId = statementAdjustment.linkId;
      }
      this.soaUtils.createStatementAdjustmentOrder(statementAdjustment);
      await this.soaUtils.updateAdjustmentType(moveAdjustmentToFinal ? 'Final' : 'Interim');
      this.soaUtils.selectAndSetFocusAdjustment(statementAdjustment);
      await this.applyAdjustmentUpdates(statementAdjustment, this.matter);
      this.matter.updateAdjustments(this.adjustmentDate, this.soaUtils.statementAdjustmentDisplayUtil);

      await this.soaUtils.updateSalePriceConsiderationLTT();
      await this.soaUtils.enableSave(true);
    }
  }

  async applyAdjustmentUpdates(statementAdjustment: StatementAdjustment, matter: Matter, templateMatter?: Matter): Promise<void> {
    if (statementAdjustment.isDepositAdjustment() && templateMatter && templateMatter.matterPropertyWithCondo) {
      let templateMatterDeposits: Deposit[] = [];
      if (templateMatter.matterPropertyWithCondo.deposits) {
        templateMatterDeposits = templateMatter.matterPropertyWithCondo.deposits.slice(0).map(item => {
          item.id = undefined;
          return item;
        });
      }
      matter.updateMatterDepositAdjustment(({
        depositAmount: templateMatter.matterPropertyWithCondo.depositAmount,
        multipleDeposit: templateMatter.matterPropertyWithCondo.multipleDeposit,
        payDepositOutOfTrust: templateMatter.matterPropertyWithCondo.payDepositOutOfTrust,
        adjustmentFormat: templateMatter.matterPropertyWithCondo.adjustmentFormat,
        deposits: templateMatterDeposits,
        infoOnly: statementAdjustment.infoOnly,
        extraDepositConfig: templateMatter.extraDepositConfig
      } as DepositModalContextValue));
      matter.recalculateAllPurchasePriceDeferredPortion();
    } else if (statementAdjustment.isPreviousYearRealtyTaxAdjustment()) {
      SoaRealtyTaxAdjustmentUtil.updateRealtyTaxAdjustment(statementAdjustment, matter);
    } else if (statementAdjustment.soaCommonExpense && (statementAdjustment.isPOTLCommonExpense() || statementAdjustment.isStandardCommonExpense() || statementAdjustment.isOccupancyCommonExpense())) {
      this.onCommonExpenseModalUpdate(statementAdjustment.soaCommonExpense, false, statementAdjustment);
    } else if (statementAdjustment.soaExpenseAdjustment && (statementAdjustment.isReserveFundAdjustment() || statementAdjustment.isSalesIncentiveAdjustment())) {
      let commonExpenseAmount = 0;
      if (statementAdjustment.isReserveFundAdjustment()) {
        commonExpenseAmount = this.soaUtils.getPropertyTabTotalCondoExpenseAmount();
      } else {
        commonExpenseAmount = SoaExpenseAdjustmentUtil.soaCommonExpenseAmount(statementAdjustment.soaExpenseAdjustment, this.soaUtils.getPropertyTabTotalCondoExpenseAmount());
      }
      SoaExpenseAdjustmentUtil.updateCalculations(statementAdjustment.soaExpenseAdjustment, commonExpenseAmount);
      this.soaUtils.onExpenseAdjustmentUpdate(statementAdjustment.soaExpenseAdjustment, statementAdjustment);
    } else if (statementAdjustment.isTotalDuringOccupancyPeriodAdjustment()) {
      statementAdjustment.soAdjExpenseOccupancyPeriod.updateSoAdjExpenseOccupancyPeriod(matter.getUnitLevelPlanCondominiumTotalExpenses(),
        matter.getUnitLevelPlanCondominiumTotalSharePercentage(),
        matter.soAdjExpenseOccupancyPeriodClosedate,
        matter.soAdjExpenseOccupancyPeriodOccupancyDate);
    } else if (statementAdjustment.isCreditVendorTaxRebateAdjustment() && statementAdjustment.soAdjCreditVendorTaxRebate) {
      await this.onCreditVendorTaxRebateModalUpdate(statementAdjustment.soAdjCreditVendorTaxRebate, statementAdjustment);
    } else if (statementAdjustment.isConsiderationPaidOnInterimClosing() && statementAdjustment.soAdjConsiderationPaidOnInterimClosing) {
      this.onConsiderationPaidOnInterimClosingModalUpdate(statementAdjustment.soAdjConsiderationPaidOnInterimClosing, statementAdjustment);
    } else if (statementAdjustment.isInterimEarlyPossessionFee()) {
      this.recalculateInterimEarlyPossessionFeeAdj(statementAdjustment, matter);
      matter.recalculateAllPurchasePriceDeferredPortion();
    } else if (statementAdjustment.isFinalEarlyPossessionFee()) {
      this.matter.recalculateAllFinalEarlyPossessionFee();
    } else if (statementAdjustment.isInterimOccupancyFee()) {
      this.recalculateInterimOccupancyFeeAdj(statementAdjustment, matter, this.project);
    } else if (statementAdjustment.isFinalOccupancyFee()) {
      this.matter.recalculateAllFinalOccupancyFee();
    } else if (statementAdjustment.isTotalOccupancyFee()) {
      this.matter.recalculateTotalFinalOccupancyFee();
    } else if (statementAdjustment.isHstAdjustment() && statementAdjustment.hstSalePrice) {
      await this.onHSTModalUpdate(statementAdjustment.hstSalePrice, statementAdjustment);
    } else if ((statementAdjustment.isInterestOnLumpSum() || statementAdjustment.isInterest() || statementAdjustment.isInterestOnDeferredMoniesDuringOccupancy()) && statementAdjustment.soAdjInterestCal && templateMatter) {
      statementAdjustment.soAdjInterestCal.applyMatterUpdatesOnInterestCalculationPeriod(this.matter);
    } else if (statementAdjustment.isTarionWarranty() && statementAdjustment.soAdjTarionWarranty) {
      await this.onTarionWarrantyUpdate(statementAdjustment.soAdjTarionWarranty, statementAdjustment);
    } else if (statementAdjustment.isHCRAFee() && statementAdjustment.soAdjHCRAFee) {
      await this.onHCRAFeeUpdate(statementAdjustment.soAdjHCRAFee, statementAdjustment);
    }
    if (statementAdjustment.isVacantOccupancyTax() && statementAdjustment.soAdjVacantOccupancyTax) {
      await this.onVacantOccupancyTaxUpdate(statementAdjustment.soAdjVacantOccupancyTax, statementAdjustment);
    } else if (statementAdjustment.isOtherFixed() && statementAdjustment.soAdjOtherFixed) {
      await this.onOtherFixedAdjustmentUpdate(statementAdjustment.soAdjOtherFixed, statementAdjustment);
    } else if (statementAdjustment.isOtherProrated() && statementAdjustment.soAdjOtherProrated && templateMatter) {
      this.onOtherProRatedAdjustmentUpdate(StatementAdjustmentKey.OTHER_PRORATED, statementAdjustment.soAdjOtherProrated, statementAdjustment);
    } else if (statementAdjustment.isOtherProratedOnPercentageInterest() && statementAdjustment.soAdjOtherProratedOnPercentageInterest && templateMatter) {
      await this.onOtherProRatedOnPercentageInterestAdjustmentUpdate(StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST, statementAdjustment.soAdjOtherProratedOnPercentageInterest, statementAdjustment);
    } else if (statementAdjustment.isPercentageOfPurchasePrice() && statementAdjustment.soAdjPurchasePricePercentage && templateMatter) {
      this.onOtherPurchasePricePercentageAdjustmentUpdate(StatementAdjustmentKey.PURCHASE_PRICE_PERCENTAGE, statementAdjustment.soAdjPurchasePricePercentage, statementAdjustment);
    } else if (statementAdjustment.isTaxesPaidDuringOccupancy()) {
      this.matter.recalculateTaxesPaidDuringOccupancy();
    } else if (statementAdjustment.isHSTOtherFactor()) {
      this.onTaxFactorUpdate(statementAdjustment.hstSalePrice, statementAdjustment);
    } else if (statementAdjustment.isOtherFixedPayableOnOccupancy()) {
      await this.onOtherFixedPayableOnOccupancyAdjustmentUpdate(statementAdjustment.soAdjOtherFixedPayableOnOccupancy, statementAdjustment);
    } else if ((statementAdjustment.isTaxesBeginningOfYearUntilOccupancy() || statementAdjustment.isTaxesPaidByVendorAdjustment() || statementAdjustment.isPropertyTax()) && statementAdjustment.matterTax) {
      let ptac: ProjectTaxAdjustmentConfig = statementAdjustment.isAdjustmentStatusFinal() ? this.matter.projectTaxAdjustmentConfigFinal : this.matter.projectTaxAdjustmentConfigInterim;
      matter.updatePropertyTaxAdjustmentTotalTaxFromProjectAdjustmentSettings(statementAdjustment.matterTax, ptac);
      statementAdjustment.matterTax.updatePurchaserPortionValue(matter);
      let rollNumberPropertyTaxesResult = new RollNumberPropertyTaxesResult();
      rollNumberPropertyTaxesResult.propertyTax = statementAdjustment.matterTax;
      rollNumberPropertyTaxesResult.separateTaxAdjustmentByRollNumber = matter.matterPropertyWithCondo.separateTaxAdjustmentByRollNumber;
      rollNumberPropertyTaxesResult.infoOnly = statementAdjustment.infoOnly;
      await this.onPropertyTaxUpdate(rollNumberPropertyTaxesResult, statementAdjustment.itemKey, statementAdjustment, true);
    } else if (statementAdjustment.isTaxesPaidDuringOccupancy() && statementAdjustment.taxPortion) {
      statementAdjustment.updateFinalTaxPortionFromInterimOccAdj(matter);
      await this.onTaxesPaidDuringOccupancyUpdate(statementAdjustment.taxPortion, statementAdjustment);
    } else if (statementAdjustment.isHomeOwnersResidentFee() && statementAdjustment.soAdjHomeOwnersResidentFee) {
      this.onHomeOwnersResidentFeeModalUpdate(statementAdjustment.soAdjHomeOwnersResidentFee, StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE, statementAdjustment);
    } else if (statementAdjustment.isForm4Charges()) {
      this.matter.recalculateForm4Charges();
    } else if (statementAdjustment.isItemizedCreditToVendorPurchaser()) {
      await this.updateMatterOnItemizedCreditAdjustmentChange(matter.considerationLtt.salePriceAdjustment, statementAdjustment);
    } else if (statementAdjustment.isSalePriceMinusNetSalePrice()) {
      this.soaUtils.updateSalePriceMinusNetSalePriceAdjustment();
    } else if (statementAdjustment.isHeat()) {
      this.onHeatAdjustmentUpdate(statementAdjustment.soaHeat, statementAdjustment);
    } else if (statementAdjustment.isTaxOther()) {
      this.onTaxAdjustmentUpdate(statementAdjustment.soAdjTaxOther, statementAdjustment);
    }
  }

  private recalculateInterimOccupancyFeeAdj(statementAdjustment: StatementAdjustment, matter: Matter, project: Project) {
    let elapsedDays = matter.isMatterProvinceAB ? Number(moment(this.adjustmentDate, 'YYYY/MM/DD').date()) : Number(matter.getElapsedDays(this.adjustmentDate));
    ;
    let numberOfDaysInMonth = Number(matter.getNumberofDaysMonth(this.adjustmentDate));
    statementAdjustment.updateInterimOccupancyFeeOnClosingDateChange(elapsedDays, numberOfDaysInMonth, matter, project);
  }

  ngOnDestroy() {
  }

}
