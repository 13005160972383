import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import Utils from '../../../shared-main/utils';
import {InterestRateUtils} from '../../../shared-main/interest-rate-utils';
import {InterestDateCalendarYearItem} from '../../../shared-main/interest-date-calendar-year-item';

export type CombinedOrSeparateType = 'COMBINED' | 'SEPARATE'

export class SoAdjAssumedMortgage extends BaseEntity {

  id: number;
  mortgagePriority: number;
  combinedOrSeparate: CombinedOrSeparateType;
  principalAsAtDate: string;
  principalAmount: number;
  performInterestCalculation: boolean;
  interestRate: number;
  interestAmount: number;

  get performInterestCalculationBoolean(): boolean {
    return Utils.convertToBoolean(this.performInterestCalculation);
  }

  getNumberOfDaysElapsed(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    if (Utils.isNotValidDate(this.principalAsAtDate) || Utils.isNotValidDate(closingDate)) {
      return 0;
    } else {
      let elapsedDays = Utils.getDateDiff(this.principalAsAtDate, closingDate);
      if (isPaysForDateOfClosingVendor) {
        elapsedDays++;
      }
      return elapsedDays;
    }
  }

  totalCreditPurchaser: number;

  get adjustmentDescription(): string {
    let result: string = 'MORTGAGE ASSUMED';
    if (this.mortgagePriority > 0) {
      result = `${ Utils.getLongOrdinal(this.mortgagePriority, true) } ${ result }`;
    } else {
      result = `????? ${ result }`;
    }
    return result;
  }

  validCalculations(closingDate: string): boolean {
    if (this.combinedOrSeparate == 'COMBINED') {
      return true;
    } else {
      if (Utils.isNotValidDate(this.principalAsAtDate)) {
        return false;
      } else {
        return Utils.getDateDiff(this.principalAsAtDate, closingDate) > 0;
      }
    }
  }

  updateTotalAmount(closingDate: string, isPaysForDateOfClosingVendor?: boolean): void {

    if (this.combinedOrSeparate == 'COMBINED') {
      this.totalCreditPurchaser = this.principalAmount;
    } else {
      if (this.performInterestCalculationBoolean) {
        if (!Utils.isNotValidDate(this.principalAsAtDate)) {
          let items: InterestDateCalendarYearItem[] = [];
          items.push(...InterestRateUtils.calculateInterestPerCalendarYear(this.principalAsAtDate, closingDate, this.interestRate, this.principalAmount, isPaysForDateOfClosingVendor));
          this.totalCreditPurchaser = Number(this.principalAmount);
          items.forEach((itm: InterestDateCalendarYearItem) => this.totalCreditPurchaser += itm.rateAmount);
        }
      } else {
        this.totalCreditPurchaser = Number(this.principalAmount) + Number(this.interestAmount);
      }
    }
  }
}
