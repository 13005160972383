import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {matterApi} from '../shared/matter-api';
import {RequisitionTemplate} from '../shared/requisition-template';
import {Matter} from '../shared/matter';
import {RequisitionInstrumentConfig} from '../shared/requisition-instrument-config';
import {MatterUndertaking} from '../shared/matter-undertaking';
import {currentMatter} from '../shared/current-matter';
import {RequisitionsEditorModalResult} from './requisitions-editor.modal.enum';
import {accountApi} from '../../admin/accounts/shared/account-api';
import {SESSION_STORAGE_KEYS} from '../../shared';

@Injectable()
export class RequisitionsService {

  public static readonly DEFAULT_REQUISITIONS_REQ_PATH: string = 'defaultRequisitions';
  public static readonly DEFAULT_REQUISITION_REQ_PATH: string = 'defaultRequisition';
  public static readonly DEFAULT_REQUISITIONS_REQ_KEY: string = 'key';
  public static readonly DEFAULT_REQUISITIONS_RESP_KEY: string = 'DefaultRequisitions';
  public static readonly REQUISITION_INSTRUMENT_CONFIGS_RESP_KEY: string = 'RequisitionInstrumentConfigs';
  public static readonly REQUISITION_PRODUCE_RESP_KEY: string = 'RequisitionTemplateText';

  private cachedConfigs: RequisitionInstrumentConfig[] = [];

  constructor(private http: HttpClient) {
  }

  getDefaultRequisitions(key?: string): Observable<RequisitionTemplate[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = (key) ?
      `${ matterApi.requisitions(accountId) }/${ RequisitionsService.DEFAULT_REQUISITIONS_REQ_PATH }?${ RequisitionsService.DEFAULT_REQUISITIONS_REQ_KEY }=${ encodeURIComponent(key) }` :
      `${ matterApi.requisitions(accountId) }/${ RequisitionsService.DEFAULT_REQUISITIONS_REQ_PATH }`;
    return this.http.get(url)
    .map((res) => {
      let requisitionTemplates: RequisitionTemplate[] = [];
      let resp = res[ RequisitionsService.DEFAULT_REQUISITIONS_RESP_KEY ];
      if (Array.isArray(resp)) {
        resp.forEach(record => {
          requisitionTemplates.push(new RequisitionTemplate(record));
        });
      }
      return requisitionTemplates;
    });
  }

  createDefaultRequisition(requisitionTemplate: RequisitionTemplate): Observable<RequisitionTemplate> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = `${ matterApi.requisitions(accountId) }/${ RequisitionsService.DEFAULT_REQUISITION_REQ_PATH }`;

    return this.http.post(url, JSON.stringify(requisitionTemplate))
    .map((response) => {
      const requisitionTemplate = new RequisitionTemplate(response[ 'DefaultRequisition' ]);
      return requisitionTemplate;
    });

  }

  updateRequisition(requisitionTemplate: RequisitionTemplate): Observable<RequisitionTemplate> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = `${ matterApi.requisitions(accountId) }`;
    url = url + '/' + requisitionTemplate.id;
    return this.http.put(url, JSON.stringify(requisitionTemplate))
    .map((response) => {
      const requisitionTemplate = new RequisitionTemplate(response[ 'DefaultRequisition' ]);
      return requisitionTemplate;
    });

  }

  deleteRequisition(requisitionTemplate: RequisitionTemplate): Observable<any> {
    if (requisitionTemplate && requisitionTemplate.id) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      let url: string = `${ matterApi.requisitions(accountId) }/${ RequisitionsService.DEFAULT_REQUISITION_REQ_PATH }`;
      url = url + '/' + requisitionTemplate.id;
      return this.http.delete(url)
      .map((res) => {
        return res;
      });
    }
  }

  //config requested by matter component and cached.
  getRequisitionInstrumentConfigs(accountId: string): Observable<RequisitionInstrumentConfig[]> {
    let url: string = accountApi.requisitionInstrumentConfig.replace('{customerId}', accountId);
    return this.http.get(url)
    .map((res) => {
      let resp = res[ RequisitionsService.REQUISITION_INSTRUMENT_CONFIGS_RESP_KEY ];
      if (Array.isArray(resp)) {
        resp.forEach(config => {
          this.cachedConfigs.push(new RequisitionInstrumentConfig(config));
        });
      }
      return this.cachedConfigs;
    });
  }

  produceRequisitionText(requisitionTemplate: string, matter: Matter): Observable<string> {
    let url: string = matterApi.produceRequisition(matter.id);
    let tempMatter = new Matter(matter);
    tempMatter.clearCircularReferencesBeforeStringify();
    let data = {
      'template': requisitionTemplate,
      'matter': tempMatter
    };
    return this.http.post(url, data)
    .map(
      (res) => {
        return res[ RequisitionsService.REQUISITION_PRODUCE_RESP_KEY ];
      });
  }

  getCachedConfigs(): RequisitionInstrumentConfig[] {
    return this.cachedConfigs;
  }

  get matter(): Matter {
    return currentMatter.value;
  }

  deleteSelectedRequisition(selectedRequisition: RequisitionTemplate): void {
    this.matter.matterRequisitions.splice(this.matter.matterRequisitions.indexOf(selectedRequisition), 1);
    if (selectedRequisition.isStatusAcceptUndertaking()) {
      this.deleteUndertaking(selectedRequisition);
    }
    this.enableSave();
  }

  onRequisitionEdit(oldRequisitionStatus: string, oldDataHash: string, result: any, requisitionTemplate: RequisitionTemplate): void {
    if (result.action === 'Delete') {
      if (oldRequisitionStatus === 'ACCEPT_UNDERTAKING') {
        this.deleteUndertaking(requisitionTemplate);
      }
      this.deleteSelectedRequisition(requisitionTemplate);
    } else if (result.action === RequisitionsEditorModalResult.ADD_COMPLETED) {
      const newRequisitionStatus: string = requisitionTemplate.requisitionStatus;
      if (newRequisitionStatus !== oldRequisitionStatus) {
        if (oldRequisitionStatus === 'ACCEPT_UNDERTAKING') {
          this.deleteUndertaking(requisitionTemplate);
        }

        if (requisitionTemplate.requisitionStatus === 'ACCEPT_UNDERTAKING') {
          this.onRequisitionStatusChange(requisitionTemplate);
        }
      } else if (newRequisitionStatus === 'ACCEPT_UNDERTAKING' && oldDataHash !== requisitionTemplate.dataHash) {
        this.deleteUndertaking(requisitionTemplate);
        this.onRequisitionStatusChange(requisitionTemplate);
      }
    }
  }

  onRequisitionStatusChange(requisitionTemplate: RequisitionTemplate): void {
    if (requisitionTemplate) {
      if (requisitionTemplate.requisitionStatus === 'ACCEPT_UNDERTAKING') {
        const undertaking: MatterUndertaking = new MatterUndertaking();
        if (!requisitionTemplate.id) {
          requisitionTemplate.id = -Date.now();
        }
        undertaking.requisitionTemplateId = requisitionTemplate.id;
        undertaking.matterUndertakingStatus = 'OUTSTANDING';
        undertaking.description = this.formatUndertakingDescription(requisitionTemplate);
        undertaking.subject = requisitionTemplate.registrationNumber;
        undertaking.matterId = this.matter.id;

        this.matter.matterUndertakings.push(undertaking);
      } else {
        this.deleteUndertaking(requisitionTemplate);
      }
    }
    this.enableSave();
  }

  private formatUndertakingDescription(requisitionTemplate: RequisitionTemplate): string {
    if (requisitionTemplate) {
      return requisitionTemplate.require + ' '
        + requisitionTemplate.encumbranceType
        + ' registered as Instrument Number '
        + requisitionTemplate.registrationNumber
        + (requisitionTemplate.inFavourOf
          ? ' in favour of ' + requisitionTemplate.inFavourOf
          : '');
    } else {
      return '';
    }
  }

  private deleteUndertaking(requisitionTemplate: RequisitionTemplate) {
    if (requisitionTemplate && requisitionTemplate.id) {
      const idx = Array.isArray(this.matter.matterUndertakings) && this.matter.matterUndertakings.findIndex((undertaking: MatterUndertaking) =>
        undertaking.requisitionTemplateId === requisitionTemplate.id);
      if (idx > -1) {
        this.matter.matterUndertakings.splice(idx, 1);
      }
    }
  }

  enableSave(): void {
    this.matter.dirty = true;
  }

}
