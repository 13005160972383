import {Component, Inject} from '@angular/core';
import {ErrorService} from '../../shared/error-handling/error-service';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class TeranetBalanceModalContext {
  teranetUser: TeranetUser;
  autoClose: boolean;
}

@Component({
  selector: 'dp-teranet-balance-modal',
  templateUrl: 'teranet-balance.modal.component.html',
  providers: [ErrorService]
})
export class TeranetBalanceModal extends ModalComponent<TeranetBalanceModalContext> {
  constructor(
    public dialog: MatDialogRef<TeranetBalanceModal>,
    @Inject(MAT_DIALOG_DATA) context?: TeranetBalanceModalContext
  ) {
    super(dialog, context);
  }

  ngAfterViewInit() {
    if (this.context.autoClose) {
      setTimeout(() => {
        this.close();
      }, 3000); //Closes after 3 seconds
    }
  }

  get teranetBalance() {
    return this.context.teranetUser.teranetBalance;
  }

  close(): void {
    this.dialog.close({action: 'OK'});
  }
}
