import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalResult} from '../../../../shared-main/enums';
import {Project} from '../../../../projects/shared/project';
import {Matter} from '../../../shared';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {SoAdjFinalOccupancyFee} from '../../model/so-adj-final-occupancy-fee';
import {SoAdjFinalEarlyPossessionFee} from '../../model/so-adj-final-early-possession-fee';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SelectItem} from 'primeng/api';
import {FinalUseAmountFromInterimType} from '../../model/so-adj-final-fee';
import {AdjustmentBaseModalComponent} from '../adjustment-base.modal.component';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import Utils from '../../../../shared-main/utils';
import {StatementAdjustment} from '../../statement-adjustment';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class FinalOccupancyFeeContext {
  closingDate: string;
  adjustmentKey: string;
  existingFinalFee: SoAdjFinalOccupancyFee | SoAdjFinalEarlyPossessionFee;
  project: Project;
  matter: Matter;
  soaConsiderationTaxes: ConsiderationTaxes;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-final-occupancy-fee-modal-content',
  templateUrl: 'final-occupancy-fee.modal.component.html',
  styleUrls: ['./final-occupancy-fee.modal.component.scss'],
  providers: [ErrorService, DialogService]
})
export class FinalOccupancyFeeModalComponent
  extends AdjustmentBaseModalComponent<SoAdjFinalOccupancyFee | SoAdjFinalEarlyPossessionFee, FinalOccupancyFeeContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  context: FinalOccupancyFeeContext;
  localAdjustment: SoAdjFinalOccupancyFee | SoAdjFinalEarlyPossessionFee;
  closingDateLabel: string = '';
  useAmountFromFinalTypes: SelectItem[] = [];
  purchaserHasPaidFeeTypes: SelectItem[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<FinalOccupancyFeeModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: FinalOccupancyFeeContext
  ) {
    super(dialog, dialogService, context);
    this.statementAdjustmentDisplayUtil = this.context.statementAdjustmentDisplayUtil;
  }

  ok(): void {
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.localAdjustment);
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res: boolean) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  ngOnInit(): void {
    this.buildTypes();

    if (this.isFinalOccupancyFee()) {
      if (!this.context.existingFinalFee) {
        this.localAdjustment = SoAdjFinalOccupancyFee.getDefault(this.context.soaConsiderationTaxes);
        this.newAdj = true;
      } else {
        this.localAdjustment = new SoAdjFinalOccupancyFee(this.context.existingFinalFee);
      }
    }

    if (this.isFinalEarlyPossessionFee()) {
      if (!this.context.existingFinalFee) {
        this.localAdjustment = SoAdjFinalEarlyPossessionFee.getDefault(this.context.soaConsiderationTaxes);
        this.newAdj = true;
      } else {
        this.localAdjustment = new SoAdjFinalEarlyPossessionFee(this.context.existingFinalFee);
      }
    }
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
    }
    this.onUseAmountFromInterim();
  }

  buildTypes(): void {
    this.useAmountFromFinalTypes = [
      {label: 'Yes', value: <FinalUseAmountFromInterimType>'YES'},
      {label: 'No', value: <FinalUseAmountFromInterimType>'NO'}
    ];

    if (this.isFinalOccupancyFee()) {
      this.useAmountFromFinalTypes.push({
        label: 'Yes - Excluding Taxes',
        value: <FinalUseAmountFromInterimType>'YES_EXCLUDING_TAXES'
      });
    }

    this.purchaserHasPaidFeeTypes = [
      {label: 'and including month of closing', value: <FinalUseAmountFromInterimType>'AND_INCLUDING_MONTH_OF_CLOSING'},
      {label: 'end of month preceding closing', value: <FinalUseAmountFromInterimType>'END_OF_MONTH_PRECEDING_CLOSING'},
      {label: 'other - enter own date', value: <FinalUseAmountFromInterimType>'OTHER_ENTER_OWN_DATE'}
    ];
    if (this.isFinalOccupancyFee()) {
      this.purchaserHasPaidFeeTypes.push({
        label: 'No occupancy fees ever paid',
        value: <FinalUseAmountFromInterimType>'NO_OCCUPANCY_FEES_EVER_PAID'
      });
    }
    if (this.isFinalEarlyPossessionFee()) {
      this.purchaserHasPaidFeeTypes.push({
        label: 'No early possession fees ever paid',
        value: <FinalUseAmountFromInterimType>'NO_OCCUPANCY_FEES_EVER_PAID'
      });
    }
    this.purchaserHasPaidFeeTypes.push({
      label: 'Not paid for month of possession',
      value: <FinalUseAmountFromInterimType>'NOT_PAID_FOR_MONTH_OF_POSSESSION'
    });
  }

  onUseAmountFromInterim(): void {
    if (this.localAdjustment.useAmountFromInterim === 'NO') {
      return;
    }

    if (this.isFinalOccupancyFee()) {
      let interimAdj: StatementAdjustment;
      let interimAdjTotal: number;
      let interimAdjTaxesTotal: number;
      if (!this.isNonCondoPotlON()) {
        // To do: this.context.matter.allStatementAdjustments.find should be replaced by function searching for first adjustment in ordered adjustments
        interimAdj = this.context.matter.allStatementAdjustments.find((adj: StatementAdjustment) =>
          adj.isInterimOccupancyFee()
        );
        interimAdjTotal = interimAdj.soAdjInterimOccupancyFee.total;
        interimAdjTaxesTotal = Number(interimAdj.soAdjInterimOccupancyFee.taxesTotal);
      } else {
        // To do: this.context.matter.allStatementAdjustments.find should be replaced by function searching for first adjustment in ordered adjustments
        interimAdj = this.context.matter.allStatementAdjustments.find((adj: StatementAdjustment) =>
          adj.isInterimEarlyPossessionFee()
        );
        interimAdjTotal = interimAdj.soAdjInterimEarlyPossessionFee.total;
        interimAdjTaxesTotal = interimAdj.soAdjInterimEarlyPossessionFee.taxesTotal;
      }
      if (interimAdj) {
        if (this.localAdjustment.useAmountFromInterim === 'YES') {
          this.localAdjustment.amount = interimAdjTotal;
        } else if (this.localAdjustment.useAmountFromInterim === 'YES_EXCLUDING_TAXES') {
          this.localAdjustment.amount = interimAdjTotal - interimAdjTaxesTotal;
        }
      } else {
        this.localAdjustment.amount = 0;
      }
    }

    if (this.isFinalEarlyPossessionFee()) {
      let interimAdj: StatementAdjustment = this.context.matter.allStatementAdjustments.find(
        (adj: StatementAdjustment) => adj.isInterimEarlyPossessionFee()
      );
      if (interimAdj) {
        if (this.localAdjustment.useAmountFromInterim === 'YES') {
          this.localAdjustment.amount = interimAdj.soAdjInterimEarlyPossessionFee.total;
        }
      } else {
        this.localAdjustment.amount = 0;
      }
    }
  }

  isFinalOccupancyFee(): boolean {
    return this.context.adjustmentKey === StatementAdjustmentKey.FINAL_OCCUPANCY_FEE;
  }

  isNonCondoPotlON(): boolean {
    return this.context.project && this.context.project.isNonCondoPotlON;
  }

  isFinalEarlyPossessionFee(): boolean {
    return this.context.adjustmentKey === StatementAdjustmentKey.FINAL_EARLY_POSSESSION_FEE;
  }

  getCreditVendor(): number {
    return 0;
  }

  getLabelForAdjustTaxType(): string {
    return this.isFinalOccupancyFee() && this.context.matter.isMatterProvinceAB ? 'Adjust for GST' : 'Adjust for';
  }

  updateTaxRate(rateType: string): void {
    if (rateType == 'HST') {
      // only in ON
      this.localAdjustment.hstRate = this.context.soaConsiderationTaxes.hstRate;
    } else if (rateType == 'GST') {
      this.localAdjustment.hstRate = Number(this.context.soaConsiderationTaxes.hstFederalPortion);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDateChange(event: any, dateType: string): void {
    this.localAdjustment[dateType] = event.rawDate;
    this.clearCachedDetails();
    this.modalErrorComponent.removeAllDpFieldError();
  }

  checkForErrors(): void {
    this.modalErrorComponent.removeAllDpFieldError();
    if (this.localAdjustment.purchaserHasPaidFee === 'OTHER_ENTER_OWN_DATE') {
      if (Utils.isNotValidDate(this.localAdjustment.paidOn)) {
        this.modalErrorComponent.createDPFieldError('matter.soadj.final.occupancy.fee.paidOn.date.empty');
      }

      if (Utils.getDateDiff(Utils.lastDayOfMonthFromDate(this.context.closingDate), this.localAdjustment.paidOn) > 0) {
        this.modalErrorComponent.createDPFieldError('matter.soadj.final.occupancy.fee.paidOn.date.greater.adj');
      }
    }
  }

  get closingDateMonthAndYear(): string {
    let finalAdjustAsDate: string =
      this.context.matter.adjustAsAtClosingDateFlag === 'SPECIFY'
        ? this.context.matter.adjustAsAtClosingDate
        : this.context.matter.matterCloseDate;
    return `${Utils.getMonthName(finalAdjustAsDate)} ${Utils.getYear(finalAdjustAsDate)}`;
  }
}
