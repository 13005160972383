import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {HttpClient} from '../../core';
import {Observable} from 'rxjs/Rx';
import {StewartTitleUser} from './stewart-title-user';
import {stewartTitleApi} from './stewart-title-constants';
import {Matter} from '../../matters/shared';
import {SubmitFileResponse} from './submit-file-response';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {StewartGetResultResponse} from './stewart-get-result-response';
import Utils from "../utils";

@Injectable()
export class StewartTitleService {

  constructor(private http: HttpClient, private logger: Logger) {
  }

  getStewartTitleUser(): Observable<StewartTitleUser> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = stewartTitleApi.stewartTitleUser(userId);
    return this.http.get(url)
    .map((res) => {
      return new StewartTitleUser(res[ 'StewartTitleUser' ]);
    });
  }

  getSolicitorStewartTitleUsers(): Observable<StewartTitleUser[]> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let url: string = stewartTitleApi.solicitorStewartTitleUsers(accountId);
    return this.http.get(url)
    .map((res) => {
      return (res[ 'SolicitorStewartUsers' ]).map((item) => {
        return new StewartTitleUser(item);
      });
    });
  }

  saveStewartTitleUser(stewartTitleUser: StewartTitleUser): Observable<StewartTitleUser> {
    if (stewartTitleUser && stewartTitleUser.id) {
      return this.updateStewartTitleUser(stewartTitleUser);
    } else {
      return this.createStewartTitleUser(stewartTitleUser);
    }
  }

  createStewartTitleUser(stewartTitleUser: StewartTitleUser): Observable<StewartTitleUser> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = stewartTitleApi.stewartTitleUser(userId);
    return this.http.post(url, stewartTitleUser)
    .map((res) => {
      return new StewartTitleUser(res[ 'StewartTitleUser' ]);
    });
  }

  updateStewartTitleUser(stewartTitleUser: StewartTitleUser): Observable<StewartTitleUser> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = stewartTitleApi.stewartTitleUser(userId);
    url += '/' + stewartTitleUser.id;
    return this.http.put(url, stewartTitleUser)
    .map((res) => {
      return new StewartTitleUser(res[ 'StewartTitleUser' ]);
    });
  }

  getStewartTitleAuthUrl(): Observable<string> {
    let userId = Utils.getAuthenticatedUserId();
    let url = stewartTitleApi.stewartTitleAuthenticationUrl(userId);
    return this.http.get(url)
    .map((res) => {
      return res[ 'StewartTitleAuthUrl' ];
    });
  }

  getRelevantStewartUserForMatter(matterId: number, lockScreen?: boolean): Observable<StewartTitleUser> {
    let url: string = stewartTitleApi.relevantStewartUserForMatter.replace('{matterId}', '' + matterId);
    return this.http.get(url, lockScreen ? lockScreen : false)
    .map((res) => {
      return new StewartTitleUser(res[ 'StewartTitleRelevantUser' ]);
    }).catch(e => {
      if (e.errorCode === 'app.stewartTitleUserNotFound') {
        return Observable.of(null);
      }
    });
  }

  getResult(matter: Matter, stgUser: StewartTitleUser, fileNo: string, lockScreen?: boolean): Observable<StewartGetResultResponse> {
    let url: string = stewartTitleApi.stewartTitleGetResult.replace('{stewartUserId}', '' + stgUser.id).replace('{matterId}', '' + matter.id)
    .replace('{fileNo}', fileNo);
    return this.http.get(url, lockScreen ? lockScreen : false)
    .map((res) => {
      return new StewartGetResultResponse(res[ 'StewartTitleGetResultResponse' ]);
    }).catch(e => {
      return Observable.of(null);
    });
  }

  getStewartUserByContact(contactId: number, lockScreen?: boolean): Observable<StewartTitleUser> {
    let url: string = stewartTitleApi.stewartUserByContact.replace('{contactId}', '' + contactId);
    return this.http.get(url, lockScreen ? lockScreen : false)
    .map((res) => {
      return new StewartTitleUser(res[ 'StewartTitleUserByContact' ]);
    }).catch(e => {
      if (e.errorCode === 'app.stewartTitleUserNotFound') {
        return Observable.of(null);
      }
    });
  }

  deleteStewartTitleUser(stewartTitleUser: StewartTitleUser): Observable<any> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = stewartTitleApi.stewartTitleUser(userId);
    url += '/' + stewartTitleUser.id;
    return this.http.delete(url);
  }

  getHomeToolsUrl(): Observable<string> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = stewartTitleApi.stewartTitleHomeToolsUrl(userId);
    return this.http.get(url)
    .map((response) => {
      const stewartTitleHomeToolsUrl = response[ 'StewartTitleHomeToolsUrl' ];
      return stewartTitleHomeToolsUrl;
    });
  }

  getStewartTitleAccessToken(): Observable<any> {
    let userId = Utils.getAuthenticatedUserId();
    let url: string = stewartTitleApi.stewartTitleAccessToken(userId);
    return this.http.get(url)
    .map((response) => {
      return response[ 'AccessToken' ];
    });
  }

  submitStewartTitleFileRequest(matter: Matter, stgUser: StewartTitleUser): Observable<SubmitFileResponse> {
    let url: string = stewartTitleApi.stewartTitleSubmitFile.replace('{stewartUserId}', '' + stgUser.id).replace('{matterId}', '' + matter.id);
    return this.http.post(url, matter.id) //As post method require some data therefore passing matter id in it, though it's present in url too.
    .map((response) => {
      return new SubmitFileResponse(response[ 'StewartTitleSubmitFile' ]);
    });
  }

  deleteTitleInsurance(matter: Matter): Observable<any> {
    let url: string = stewartTitleApi.removeTitleInsurance.replace('{matterId}', '' + matter.id).replace('{titleInsuranceId}', '' + matter.matterTitleInsurance.id);
    return this.http.delete(url);
  }

  getPremiumCalculatorUrl(): Observable<String> {
    let url: string = stewartTitleApi.premiumCalculatorUrl;
    return this.http.get(url)
    .map((res) => {
      return res[ 'PremiumCalculatorUrlResponse' ];
    });
  }

  downloadDocument(stgUser: StewartTitleUser, matter: Matter, fileNo: string, documentId: number): Observable<any> {
    let url: string = stewartTitleApi.stewartTitleDownloadDocUrl(stgUser.id, matter.id, fileNo, documentId);
    return this.http.get(url);
  }

  deleteStgDocuments(stgUser: StewartTitleUser, matter: Matter, fileNo: string): Observable<any> {
    let url: string = stewartTitleApi.stewartTitleDeleteDocuments(stgUser.id, matter.id, fileNo);
    return this.http.delete(url);
  }
}
