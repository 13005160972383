import {Directive, ElementRef, Input, Renderer2, SimpleChanges} from '@angular/core';
import {DpSortableColumnParams} from './dp-sortable-column-params';

declare var jQuery: any;

@Directive({
  selector: '[dpSortableColumnExt]'
})

export class dpSortableColumnExt {
  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  sortParams: DpSortableColumnParams;

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.initSortedTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initSortedTable();
  }

  initSortedTable() {
    let strClass = '';
    if (this.dpSortableColumnExt) {
      this.sortParams = this.dpSortableColumnExt;

      if (this.sortParams.sortKey == this.sortParams.sortQuery) {
        let sortClass: string = 'active';
        let sortClassType: string = this.sortParams.sortType === 'ASC' ? 'glyphicon-triangle-top' : 'glyphicon-triangle-bottom';
        strClass = 'glyphicon ' + sortClass + ' ' + sortClassType + '';
      }

      let elm = this.el.nativeElement.children[ 0 ].children[ 0 ];
      this.renderer.setAttribute(elm, 'class', strClass);
    }
  }

  @Input() dpSortableColumnExt: DpSortableColumnParams;

}

