<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Verify Password</h1>
            </div>
            <button type="button" id="bypassClickBtn" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body" [ngClass]="{'relogin-modal': context.oldToken}" >

    <div class="col-lg-12">
        <div *ngIf="isUpdateStaffProfileFlow()" class="col-lg-12 text-center boldfont padding-bottom-30 padding-top-10">
            Please enter your current password
        </div>
        <div *ngIf="isExpiredTokenOrSessionFlow()" class="col-lg-12 text-center boldfont padding-bottom-30 padding-top-10">
            For your security, please enter your password such that you can continue where you left off
        </div>
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label col-lg-4">Current Password</label>
                <div class="col-lg-6">
                    <input type="password" class="form-control" [(ngModel)]="verifyPassword"
                           fieldKey="verifyPassword.password" autocomplete="new-password"
                           dp-error-validator
                    />
                </div>
            </div>
        </div>

    </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button *ngIf="isUpdateStaffProfileFlow()" type="button" id="okBtn2" class="dp-btn"
                    [disabled]="okBtnDisabled"
                    (click)="next()">
                <span>Next</span>
            </button>
            <button *ngIf="isExpiredTokenOrSessionFlow()" type="button" id="okBtn2" class="dp-btn"
                    [disabled]="okBtnDisabled"
                    (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span *ngIf="isUpdateStaffProfileFlow()">Cancel</span>
                <span *ngIf="isExpiredTokenOrSessionFlow()">Logout</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
