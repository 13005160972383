import {Injectable} from '@angular/core';
import {MORTGAGE_PAYMENT_FREQUENCY} from '../../../../shared-main/constants';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../../../core';
import {matterApi} from '../../../shared';
import {Utils as MoreUtils} from '../../../shared/utils';
import Utils from '../../../../shared-main/utils';

@Injectable()
export class MortgagePaymentService {

  /* mortgage calculator variables end */
  constructor(private http: HttpClient) {
  }

  postAmortizationSchedule(obj): Observable<any> {
    console.log(JSON.stringify(obj));
    return this.http.postPdf(matterApi.generateAmortizationSchedule(obj.matterId), JSON.stringify(obj))
    .map((res) => {
      console.log(res);
      var mediaType = 'application/pdf';
      var blob = new Blob(([ res.body ]), {type: mediaType});
      let utils = new MoreUtils();
      if (utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, 'amortization.pdf');
        }
      } else {
        // saveAs(blob, filename);
        var fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL); // if you want to open it in new tab
      }
    });

  }

  calculateMortgage(principalAmount: number, amortization: number, interestRateAmount: number, frequency: string, compoundPeriod: string) {

    /*
     Compound Period:
     The number of times per year that the interest is compounded.
     Annually: 1 time per year
     Semi-Annually: 2 times per year
     Quarterly: 4 times per year
     Monthly: 12 times per year
     Bi-Weekly: 26 times per year
     Weekly: 52 times per year
     - Canadian mortgages are compounded semi-annually.
     - US mortgages are compounded monthly.
     */
    /*
     Payment Frequency:
     This is used to determine the number of payments per year.
     Annually: 1 (once per year)
     Semi-Annually: 2 (twice per year)
     Quarterly: 4 times per year
     Bi-Monthly: 6 times per year
     Monthly: 12 times per year
     Semi-Monthly: 24 times per year (2 times per month)
     Bi-Weekly: 26 times per year (once every two weeks)
     Weekly: 52 times per year (once a week)
     Acc Bi-Weekly: 26 times per year, but payment is 1/2 a normal monthly payment
     Acc Weekly: 52 times per year, but payment is 1/4 a normal monthly payment
     */

    /* mortgage calculator variables */
    let mortgageAmount = principalAmount;
    let mortgageAmortizationInMonths = amortization * 12;
    let interestRate = Number(interestRateAmount);
    let paymentFrequency = frequency;
    let compoundingPeriod: string = compoundPeriod;
    let mortgagePayment: number;
    let freqObj: any = MORTGAGE_PAYMENT_FREQUENCY[ paymentFrequency ];
    let numberOfPaymentsForSchedule: number = freqObj.numberOfPaymentsForSchedule;
    let numberOfPaymentsPerYear: number = freqObj.numberOfPaymentsPerYear;
    let factor: number = freqObj.factor;
    let compoundAmount: number = this.getCompoundingAmount(compoundingPeriod);
    let intFactor = this.calculateIntFactor(compoundAmount, interestRate, 12);

    console.log('--START MORTGAGE CALCULATION-- ');

    /// Start calculations from delphi TC code for mortgage payment
    // let SR = (compoundAmount / 12) * Math.log(1 + interestRate / (100 * compoundAmount));

    //let effectiveMonthlyInterestRate = Math.exp(SR) - 1;
    //let intFactor = effectiveMonthlyInterestRate * 100;

    mortgagePayment = this.calculatePayment(mortgageAmount, intFactor, mortgageAmortizationInMonths, numberOfPaymentsPerYear);
    mortgagePayment = Number(mortgagePayment.toFixed(2));

    if (factor > 0) {
      mortgagePayment = mortgagePayment * factor;
      mortgagePayment = Number(mortgagePayment.toFixed(2));
    }
    /// End calculations

    return mortgagePayment;
  }

  calculateIntFactor(compoundAmount, interestRate, paysPerSchedule) {
    /// Start calculations from delphi TC code for mortgage payment
    let SR = (compoundAmount / paysPerSchedule) * Math.log(1 + interestRate / (100 * compoundAmount));
    let effectiveMonthlyInterestRate = Math.exp(SR) - 1;
    let intFactor = effectiveMonthlyInterestRate * 100;
    console.log(intFactor);
    return intFactor;
  }

  calculatePayment(principal, compoundFactor, totalNumOfPayments, totalNumberOfPaymentsPerYear) {
    let temp = Math.exp((-totalNumOfPayments * Math.log(1 + (compoundFactor * 12) / 100 / 12)));
    let result = principal * (compoundFactor * 12) / 100 / totalNumberOfPaymentsPerYear / (1 - temp);
    return result;
  }

  getCompoundingAmount(compoundingPeriod) {
    let compoundAmount: number = 0;
    switch (compoundingPeriod) {
      case 'HALF_YEAR':
        compoundAmount = 2;
        break;
      case 'SEMI_ANNUALLY':
        compoundAmount = 2;
        break;
      case 'ANNUALLY':
        compoundAmount = 1;
        break;
      case 'MONTHLY':
        compoundAmount = 12;
        break;
      case 'QUARTERLY':
        compoundAmount = 4;
        break;
      case 'BI_WEEKLY':
        compoundAmount = 26;
        break;
      case 'WEEKLY':
        compoundAmount = 52;
        break;
      default:
        compoundAmount = 0;

    }
    return compoundAmount;
  }

  calculateAmortizationSchedule(principalAmount, interestRate, amortizationPeriod, paymentAmount, paymentFrequency, compoundingPeriod) {
    console.log('principalAmount=' + principalAmount + ' interestRate=' + interestRate + ' Amortization=' + amortizationPeriod + ' paymentAmount=' +
      paymentAmount + ' paymentFrequency-' + paymentFrequency);
    let balance: number = principalAmount;
    let compoundAmount: number = this.getCompoundingAmount(compoundingPeriod);
    let freqObj: any = MORTGAGE_PAYMENT_FREQUENCY[ paymentFrequency ];
    let intFactor: number = this.calculateIntFactor(compoundAmount, interestRate, freqObj.numberOfPaymentsForSchedule);
    let numberOfPayments = amortizationPeriod * freqObj.numberOfPaymentsForSchedule;

    for (let i = 0; i < numberOfPayments; i++) {
      let interestOnly: number = 0;
      let principalOnly: number = 0;

      interestOnly = Number(((balance * intFactor) / 100).toFixed(2));
      principalOnly = paymentAmount - interestOnly;
      balance = balance - principalOnly;
      if (balance < 0) {
        principalOnly = principalOnly + balance;
        balance = 0;
      }

      // console.log("Payment " + (i+1) + " / Principal " + principalOnly.toFixed(2) + " / Int " + interestOnly.toFixed(2) + " / Bal " +
      // balance.toFixed(2));

    }

  }

  calculateMortgagePaymentForInterestOnly(principal: number, interest: number, frequency: string): number {
    let freqObj: any = MORTGAGE_PAYMENT_FREQUENCY[ frequency ];
    let mortgagePayment = (principal * (interest / 100)) / Number(freqObj.numberOfPaymentsForSchedule);
    return Utils.roundCurrency(mortgagePayment);
  }

}
