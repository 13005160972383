import {ManitobaLTTTier} from './manitoba-ltt-tier';

export class ManitobaLttTierUtil {
  public static calculateLttTax(netSalePrice: number, tiers: ManitobaLTTTier[]): number {
    if (Array.isArray(tiers)) {
      for (let i: number = 0; i < tiers.length; i++) {
        let maxValue: number = i == 0 ? 0 : tiers[ i - 1 ].tierMaximum; // use max value of the previous tier as per confluence attachment
        tiers[ i ].calculatedTaxValue = netSalePrice > maxValue ? Math.round(netSalePrice * tiers[ i ].rate / 1000 - tiers[ i ].minus) : 0;
      }

      for (let i: number = tiers.length - 1; i >= 0; i--) {
        if (tiers[ i ].calculatedTaxValue != 0) {
          return tiers[ i ].calculatedTaxValue;
        }
      }
    }
    return 0;
  }
}
