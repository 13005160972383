import {
  RecipientRole,
  TriggerEvent
} from '../../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {UUIDUtil} from '../../main/uuid-util';
import {MatterParticipant} from './matter-participant';
import {ContactInfo} from './contact-info';

export type NotificationStatus = 'PENDING' | 'CANCELLED' | 'SENT' | 'MUTED' | 'READY' | 'MANUAL_CANCELLED' | 'OPTED_OUT';
export type NotificationType = 'BASIC' | 'SIGNER' | 'TASK';
export type NotificationMethodType = 'EMAIL' | 'UNITY_MESSAGE';

export const NotificationTypeValue = {
  basic: <NotificationType>'BASIC',
  signer: <NotificationType>'SIGNER',
  task: <NotificationType>'TASK'
};

export class MatterNotification extends BaseEntity {
  id: number;
  matterId: number;
  configNotificationId: number;
  precedentId: number;
  recipientRole: RecipientRole;
  triggerEvent: TriggerEvent;
  notificationStatus: NotificationStatus;
  taskIdentifier: number;
  //Note: Saving source contact Id in it, as we need to know if same contact is re-added then don't send notification to it.
  //If source contact Id not available then using snapshopt contact id (for example matter level real estate agent)
  recipientIdentifier: number;
  resendRequired: boolean;
  //In case of a signing officer, saving the parent contact id
  parentRecipientIdentifier: number;
  //To indicate that the participant linked to notification has been removed. Cannot use status to indicate it because if notification was already SENT
  // then it should keep showing that.
  orphaned: boolean;
  notificationType: NotificationType;

  lastUpdatedTimeStamp: number;
  notificationContent: string;
  notificationMethod: NotificationMethodType;
  recipientName: string;
  notificationPendingReason: string; // UI Only
  dataReadyExpectStatus: boolean = false;

  constructor(mn?: MatterNotification) {
    super(mn);
    if (!mn) {
      this.id = UUIDUtil.getUUID();
    }
  }

  isMuted(): boolean {
    return this.notificationStatus == 'MUTED';
  }

  isPending(): boolean {
    return this.notificationStatus == 'PENDING';
  }

  isReady(): boolean {
    return this.notificationStatus == 'READY';
  }

  isSent(): boolean {
    return this.notificationStatus == 'SENT';
  }

  isCancelled(): boolean {
    return this.notificationStatus == 'CANCELLED' && !!this.orphaned;
  }

  isManualCancelled(): boolean {
    return this.notificationStatus == 'CANCELLED' && !this.orphaned;
  }

  isOptedOut(): boolean {
    return this.notificationStatus == 'OPTED_OUT';
  }

  //Only outstanding items gets re-evaluated on matter save
  isOutstanding(): boolean {
    return this.isPending() || (this.isReady() && !this.notificationContent) || this.isOptedOut();
  }

  //Cancelled doesn't need to be cloned
  canBeCloned(): boolean {
    return this.isPending() || this.isReady() || this.isMuted() || (this.isSent() && !this.orphaned) || this.isManualCancelled() || this.isOptedOut();
  }

  //Sent won't be cancelled
  canBeCancelled(): boolean {
    return this.isPending() || this.isReady() || this.isMuted() || this.isManualCancelled() || this.isOptedOut();
  }

  cancelIfPossible(): void {
    if (this.canBeCancelled()) {
      this.notificationStatus = 'CANCELLED';
    }
  }

  get taskNotificationStatus(): string {
    if (this.triggerEvent == 'STARTED') {
      return 'INPROGRESS';
    } else if (this.triggerEvent == 'FINISHED') {
      return 'COMPLETED';
    } else {
      return this.triggerEvent;
    }

  }

  //formatted in ROLE.TRIGGER_EVENT
  get metaDataKey(): string {
    return this.recipientRole + '.' + this.triggerEvent;
  }

  clone(): MatterNotification {
    let clonedNotification: MatterNotification = new MatterNotification(this);
    clonedNotification.id = UUIDUtil.getUUID();
    clonedNotification.recipientIdentifier = null;
    clonedNotification.recipientName = null;
    clonedNotification.taskIdentifier = null;
    clonedNotification.lastUpdatedTimeStamp = null;
    clonedNotification.notificationContent = null;
    clonedNotification.notificationPendingReason = null;
    clonedNotification.notificationType = null;
    return clonedNotification;
  }

  //A participant can be associated with a notification either by source contact id or snapshot id
  isAssociatedWithParticipant(mp: MatterParticipant): boolean {
    return !!this.recipientIdentifier && mp.contact && (this.recipientIdentifier == mp.contact.sourceContactId || this.recipientIdentifier == mp.contact.id);
  }

  isAssociatedWithOtherSideLawClerk(contactInfo: ContactInfo): boolean {
    return !!this.recipientIdentifier && (this.recipientIdentifier == contactInfo.id);
  }

  isChildNotification(): boolean {
    return !!this.parentRecipientIdentifier;
  }

  isBasicNotification(): boolean {
    return this.notificationType == NotificationTypeValue.basic;
  }

  isSignerNotification(): boolean {
    return this.notificationType == NotificationTypeValue.signer;
  }

  isTaskNotification(): boolean {
    return this.notificationType == NotificationTypeValue.task;
  }

  isRealEstateAgentNotification(): boolean {
    return this.recipientRole == 'REALESTATEAGENT';
  }

  isCondoCorpNotification(): boolean {
    return this.recipientRole == 'CONDO_CORPORATION';
  }

  isManagementCompanyCorpNotification(): boolean {
    return this.recipientRole == 'MANAGEMENT_COMPANY';
  }

  isTaskNotificationForOnHoldOverdue(): boolean {
    return this.isTaskNotification() && (this.triggerEvent == 'OVERDUE' || this.triggerEvent == 'ON_HOLD');
  }
}
