<!-- modal -->
<form class="form-horizontal">
    <div class="container-fluid custom-modal-container">
        <div class="modal-header margin-bottom-20">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>Set Warning Level</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
            <div class="clear"></div>
        </div>
        <div class="modal-body">
            <form class="form-horizontal" #warningLevelForm="ngForm">
                <div class="form-group col-xs-12">
                    Show warning when balance is less than or equal to:
                </div>
                <div class="form-group col-xs-12 padding-top-20">
                    <div class="col-xs-12 padding-bottom-5" *ngFor="let level of warningLevels; let i=index ">
                        <span class="radio display-inline" (click)="setWarningLevel(false, level)">
                            <input type="radio" name="warningLevelRadio" [checked]="level == selectedWarningLevel">
                            <label tabindex="0" (keydown.space)="setWarningLevel(false, level)"
                                [ngClass]="{'focus-first' : level == selectedWarningLevel}">
                                {{level | currency}}
                            </label>
                        </span>
                    </div>
                    <div class="col-xs-3 padding-bottom-5">
                        <span class="radio display-inline" (click)="setWarningLevel(true,'#otherInputID')">
                            <input type="radio" name="warningLevelRadio" [checked]="OtherWarningLevel">
                            <label tabindex="0" (keydown.space)="setWarningLevel(true, '#otherInputID')">Other
                                Amount</label>
                        </span>
                    </div>
                    <div class="col-xs-3 relative-bottom-7" [hidden]="!OtherWarningLevel">
                        <input class="form-control" type="text" id="otherInputID" name="otherInputID" dp-currency
                            dp-default-currency [(ngModel)]="selectedWarningLevel" tabindex="0" dp-error-validator
                            [fieldKey]="'billing.warningLevel.otherAmount'" />
                    </div>
                </div>
            </form>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 text-center buttons">
                <button class="dp-btn" type="button" (click)="save()">
                    OK
                </button>
                <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                    Cancel
                </button>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
    </div>
</form>