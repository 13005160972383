import {Permissions} from "./authz/permissions";

export const AccessGuardKey = [
  {
    path: '/main/tabs/matters',
    authKey: [ Permissions.MATTERS ]
  },
  {
    path: '/main/projects/',
    authKey: [ Permissions.PROJECTS ]
  },
  {
    path: '/main/contacts/',
    authKey: [ Permissions.CONTACT_MANAGEMENT, Permissions.MANAGE_GLOBAL_CONTACTS ]
  },
  {
    path: '/main/admin/',
    authKey: [ Permissions.ADMINISTRATION, Permissions.CUSTOMER_ACCOUNT_LIST ]
  },
  {
    path: '/main/accountadmin/',
    authKey: [ Permissions.ADMINISTRATION ]
  },
  {
    path: '/main/billing/',
    authKey: [ Permissions.CUSTOMER_BILLING_INFORMATION ]
  },
  {
    path: '/main/events/',
    authKey: [ Permissions.EVENTS ]
  },
  {
    path: '/main/events/',
    authKey: [ Permissions.MANAGE_ADJUDICATION ]
  }
];

export class AccessGuardHelper {
  static getAccessGuardKey(path: string): any[] {
    let authKey: any[] = [];
    let accessGuards: any = AccessGuardKey.filter((accessGuardKeys) => path.indexOf(String(accessGuardKeys.path)) > -1);
    if (accessGuards && accessGuards.length) {
      accessGuards.forEach((accessGuard) => {
        authKey = accessGuard.authKey;
      });
    }
    return authKey;

  }
}
