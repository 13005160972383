<div class="form-input-wrapper" [ngClass]="{'input-invalid': getErrors()}">
    <ng-content></ng-content>
    <div *ngIf="formTypeId!='loginPage'">
        <div class="col-lg-2"></div>
        <div *ngIf="getErrors()" [hidden]="closed" class="col-lg-4">
            <div class="error-wrapper"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i><p class="error-p">{{getErrors()}}</p>
             <a class="btn-close" (click)="closed=true"><b class="fa fa-times" aria-hidden="true"></b></a>
            </div>
        </div>
    </div>
     <div *ngIf="formTypeId=='loginPage'">
        <div *ngIf="getErrors()" [hidden]="closed" class="col-lg-12 login-error">
           <span class="error-input-img"></span> {{getErrors()}}
        </div>
    </div>
</div>
