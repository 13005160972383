import {AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {dropDowns} from '../../shared/matter-drop-downs';
import {SelectItem} from 'primeng/api';
import {CondominiumPlan} from './condominium-plan';
import {CondominiumExpense} from './condominium-expense';
import {Jurisdiction} from '../../shared/jurisdiction';
import {NgForm} from '@angular/forms';
import {MatterProperty} from '../../shared/matter-property';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import * as _ from 'lodash';
import {ModalF9HelpComponent} from '../../../shared/modal-f9-help/modal-f9-help.component';
import {StatusBarService} from '../../../shared-main/status-bar.service';
import {StatementAdjustment} from '../../statement-adjustment/statement-adjustment';
import {SoaCommonExpense} from '../../statement-adjustment/model/common-expense-soa';
import {StatementAdjustmentKey} from '../../statement-adjustment/statement-adjustment-constants';
import {SoaCommonExpenseModal} from '../../statement-adjustment/modals/common-expense/common-expenses-soa.modal.component';
import {Matter} from '../../shared/matter';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {TaxRateService} from '../../consideration-ltt/tax-rate.service';
import {
  condoPlanTypeDropDownOptions,
  condoUnitTypeDropDownOptions
} from '../../../shared-main/province-based-dropdowns';
import {booleanYesNoDropDowns} from '../../../shared-main/constants';
import {TabsService} from '../../../core';
import {
  provinceBasedFieldLabels,
  subjectPropertyUnitLevelPlanLabels
} from '../../../shared-main/province-based-field-labels';
import {projectConsts} from '../../../projects/shared/project-consts';
import {ProjectTab} from '../../../projects/shared/project-tab';
import {Project} from '../../../projects/shared/project';
import Utils from '../../../shared-main/utils';
import {Utils as SharedUtils} from '../../shared/utils';
import {SelectUnitLevelPlanResult} from '../../shared/select-unit-level-plan-result';
import {UnitLevelPlanUtil} from './unit-level-plan-util';
import {CustomKeyCodesEnum} from '../../../common/key-code-enum';
import {DPError} from '../../../shared/error-handling/dp-error';
import {PropertyPinHelper} from '../../shared/property-pin-helper';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class UnitLevelPlanModalContext {
  propertyJurisdiction: Jurisdiction;
  unitLevelPlanOrg: MatterProperty;
  inSecondModal: boolean;
  commonExpenseAdj: StatementAdjustment;
  currentMatter: Matter;
  isReadOnly: boolean;
  isUnitLevelPinReadOnly: boolean;
  isPreviousMatterBtnVisible: boolean;
  isNextMatterBtnVisible: boolean;
  focusOnUnitNumber: boolean;
}

@Component({
  selector: 'dp-unit-level-plan',
  templateUrl: 'unit-level-plan.component.html',
  styleUrls: ['./unit-level-plan.scss'],
  providers: [ErrorService, StatusBarService]
})
export class UnitLevelPlanModalComponent extends ModalComponent<UnitLevelPlanModalContext> implements OnInit, AfterViewInit {
  @ViewChild('unitLevelPlanDialogForm') unitLevelPlanDialogForm: NgForm;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('modalF9HelpComponent') modalF9HelpComponent: ModalF9HelpComponent;
  unitLevelPlanDialog: boolean = false;
  propertyJurisdiction: Jurisdiction;
  unitLevelPlanOrg: MatterProperty;
  unitLevelPlan: MatterProperty;
  showIfAnyError: boolean = false;
  condoPlanType: SelectItem[];
  exceptionTypeOptions: SelectItem[];
  exceptionYNOptions: SelectItem[];
  condoUnitType: SelectItem[];
  teraviewLegalDescOverriddenTypes: SelectItem[];
  defaultCondoPlanNumber: string = '??????????';
  currentTimeStamp: number = Date.now();
  inSecondModal: boolean;
  commonExpenseAdj: StatementAdjustment;
  currentMatter: Matter;
  provinceHstRateSlab: ConsiderationTaxes;
  soaConsiderationTaxes: ConsiderationTaxes;
  multipleEditExpenseOptions: any[] = [];
  editCommonExpenseCommand: string;
  globalLegalDescription: string = '';
  filteredExceptionTypeOptions: any[];
  sharedUtils = new SharedUtils();
  propertyPinHelper = new PropertyPinHelper();

  commonExpenseUpdated: boolean; //UI only

  constructor(
    public dialog: MatDialogRef<UnitLevelPlanModalComponent>,
    public dialogService: DialogService,
    public taxRateService: TaxRateService,
    public statusBarService: StatusBarService,
    public tabService: TabsService,
    @Inject(MAT_DIALOG_DATA) context?: UnitLevelPlanModalContext
  ) {
    super(dialog, context);
    this.dialogService.tabStateService = tabService;

    this.propertyJurisdiction = this.context.propertyJurisdiction;
    /*
     * Creating a second copy from the original unit level plan will allow:
     * 1. Keeping the original unit level plan unchanged until clicking ok button.
     * 2. Initializing the the local copied version (unitLevelPlanOrg) if required and comparing it against
     *    the updated unitLevelPlan to determine if the modal is dirty
     */

    this.unitLevelPlanOrg = new MatterProperty(this.context.unitLevelPlanOrg);
    this.inSecondModal = this.context.inSecondModal == undefined ? false : this.context.inSecondModal;
    this.currentMatter = this.context.currentMatter;
  }

  ngOnInit() {
    this.dialogService.tabStateService = this.tabService;
    if (this.context.commonExpenseAdj) {
      this.commonExpenseAdj = new StatementAdjustment(
        this.currentMatter.adjustmentStatusMode,
        this.context.commonExpenseAdj.provinceCode,
        this.context.commonExpenseAdj
      );
    }

    if (this.context.currentMatter && this.context.currentMatter.isInterimOccupancyAdjCommonExpOrTaxes()) {
      this.multipleEditExpenseOptions.push({
        key: 'RECALCULATE',
        text: "Recalculate the Interim Occupancy adjustment (leave setting as 'Automatic')"
      });
    }
    if (this.context.currentMatter && this.context.currentMatter.isInterimOccupancyAdjCommonExpAndTaxes()) {
      this.multipleEditExpenseOptions.push({
        key: 'PRESERVE_COMMON_EXP_TAXES',
        text: "Preserve existing amounts for common expenses and taxes (set both to 'Manual')"
      });
    }
    if (this.context.currentMatter && this.context.currentMatter.isInterimOccupancyAdjCommonExp()) {
      this.multipleEditExpenseOptions.push({
        key: 'PRESERVE_COMMON_EXP',
        text: "Preserve existing amounts for common expenses only (set to 'Manual')"
      });
    }
    if (this.context.currentMatter && this.context.currentMatter.isInterimOccupancyAdjTaxes()) {
      this.multipleEditExpenseOptions.push({
        key: 'PRESERVE_TAXES',
        text: "Preserve existing amounts for taxes only (set to 'Manual')"
      });
    }

    this.condoPlanType = condoPlanTypeDropDownOptions[this.currentMatter.provinceCode];
    this.exceptionYNOptions = booleanYesNoDropDowns.YesNo_DefaultBlank;
    this.exceptionTypeOptions = dropDowns.unitPlanExceptionsTypes;
    if (this.isProjectSaleMatterON || this.isProjectSaleMatterAB) {
      this.loadProjectCondoUnitTypes();
    } else {
      this.condoUnitType = condoUnitTypeDropDownOptions[this.currentMatter.provinceCode];
    }

    if (this.isProjectSaleMatterON) {
      this.teraviewLegalDescOverriddenTypes = dropDowns.teraviewLegalDescOverriddenTypes;
    }

    this.initializeUnitLevelPlan();

    this.setupSoaConsiderationTaxes();
    this.setGlobalLegalDescription();
  }

  setGlobalLegalDescription(): void {
    if (this.unitLevelPlan && this.unitLevelPlan.condominiumExpenses && this.unitLevelPlan.condominiumExpenses.length) {
      this.unitLevelPlan.condominiumExpenses.forEach((condominiumExpense) => {
        if (condominiumExpense.overrideDescInd && condominiumExpense.isLegalDescriptionApplyLegalDescEasement()) {
          this.globalLegalDescription = condominiumExpense.teraviewLegalDesc;
        }
      });
    }
  }

  handleDropdownClickExceptionTypeDescription = () =>
    (this.filteredExceptionTypeOptions = this.exceptionTypeOptions.map((item) => item.label));

  filterExceptionTypeDescription(event) {
    this.filteredExceptionTypeOptions = this.exceptionTypeOptions
      .map((item) => item.label)
      .filter((label) => label && label.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
  }

  loadProjectCondoUnitTypes(): void {
    this.condoUnitType = <any>UnitLevelPlanUtil.loadProjectCondoUnitTypes(this.project);
  }

  get isProjectConfigTab(): boolean {
    return this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject();
  }

  get project(): Project {
    if (this.currentMatter.project) {
      return this.currentMatter.project;
    } else if (this.isProjectConfigTab) {
      return this.isProjectConfigTab && (this.tabService.activeTab as ProjectTab).project;
    } else {
      return null;
    }
  }

  get isExpenseReadOnly(): boolean {
    return this.context.isReadOnly;
  }

  get isUnitLevelPinReadOnly(): boolean {
    return this.context.isUnitLevelPinReadOnly;
  }

  initializeUnitLevelPlan(): void {
    this.currentTimeStamp = Date.now();
    this.unitLevelPlanDialog = true;
    this.showIfAnyError = false;

    if (this.unitLevelPlanOrg.isCondominiumPlanEmpty()) {
      this.initializeCondoPlan();
    }
    if (this.unitLevelPlanOrg.isCondominiumExpenseEmpty()) {
      this.initializeCondoExpense();
    } else {
      this.createCondominiumPlanObject();
    }

    if (!this.unitLevelPlanOrg.condominiumTotalExpenses) {
      this.unitLevelPlanOrg.condominiumTotalExpenses = 0;
    }
    if (!this.unitLevelPlanOrg.exceptionTypeDescription) {
      this.unitLevelPlanOrg.exceptionTypeDescription = '';
    }
    if (!this.unitLevelPlanOrg.exceptionType) {
      this.unitLevelPlanOrg.exceptionType = '';
    }
    // pre-populate location field
    if (!this.unitLevelPlanOrg.condominiumJurisdiction) {
      let jurisdictionName =
        this.propertyJurisdiction && this.propertyJurisdiction.jurisdictionName
          ? this.propertyJurisdiction.jurisdictionName.toString()
          : '';
      this.unitLevelPlanOrg.condominiumJurisdiction = this.createJurisdictionToggleValue(jurisdictionName);
    }
    if (
      !this.unitLevelPlanOrg.percentageShareOfTotalRealtyTaxes ||
      (this.unitLevelPlanOrg.percentageShareOfTotalRealtyTaxes &&
        this.unitLevelPlanOrg.percentageShareOfTotalRealtyTaxes.toString().trim() === '')
    ) {
      this.unitLevelPlanOrg.percentageShareOfTotalRealtyTaxes = '0.000000000';
    }

    this.unitLevelPlan = new MatterProperty();
    this.unitLevelPlan = _.cloneDeep(this.unitLevelPlanOrg);
  }

  setupSoaConsiderationTaxes() {
    this.taxRateService
      .cachedConsiderationTaxRate(this.currentMatter.provinceCode)
      .subscribe((considerationTaxes: ConsiderationTaxes[]) => {
        if (considerationTaxes && considerationTaxes.length > 0) {
          //this.provinceHstRateSlab = considerationTaxes.find(item => item.instanceType == Tax_RATE.HST_RATE);
          this.provinceHstRateSlab = this.taxRateService.findConsiderationHstRateAccordingToEffectiveDate(
            considerationTaxes,
            this.currentMatter.getSoaTaxRateEffectiveDate()
          );
          if (this.currentMatter.soaHst) {
            this.soaConsiderationTaxes = new ConsiderationTaxes();
            this.soaConsiderationTaxes.hstRate = this.currentMatter.soaHst;
            this.soaConsiderationTaxes.hstFederalPortion = this.currentMatter.soaFederalHst;
            this.soaConsiderationTaxes.hstProvincialPortion = this.currentMatter.soaProvincialHst;
            this.soaConsiderationTaxes.rateType = this.currentMatter.matterTaxType
              ? this.currentMatter.matterTaxType
              : this.provinceHstRateSlab.rateType;
          } else {
            this.soaConsiderationTaxes = this.provinceHstRateSlab;
          }
        }
      });
  }

  initializeCondoPlan(): void {
    UnitLevelPlanUtil.initializeCondoPlan(this.unitLevelPlanOrg, this.currentMatter);
  }

  createCondominiumPlanObject(): void {
    UnitLevelPlanUtil.createCondominiumPlanObject(this.unitLevelPlanOrg, this.context.currentMatter);
  }

  getCondominiumPlan(planNumber: string): CondominiumPlan {
    let condominiumPlan: CondominiumPlan = null;
    if (planNumber) {
      condominiumPlan = this.unitLevelPlan.condominiumPlans.find(
        (condoPlan) =>
          Utils.buildCondoPlanNumber(
            condoPlan,
            this.currentMatter.isMatterProvinceAB,
            this.unitLevelPlan.condominiumJurisdiction
          ) == planNumber
      );
    }
    return condominiumPlan;
  }

  initializeCondoExpense(): void {
    UnitLevelPlanUtil.initializeCondoExpense(this.unitLevelPlanOrg);
  }

  createJurisdictionToggleValue(currentJurisdiction: string): string {
    if (this.propertyJurisdiction) {
      if (this.propertyJurisdiction.jurisdictionName.toString().indexOf('OTTAWA') > -1) {
        return 'Ottawa-Carleton';
      } else if (this.propertyJurisdiction.jurisdictionName.toString().indexOf('HAMILTON') > -1) {
        return 'Hamilton-Wentworth';
      } else if (this.propertyJurisdiction.jurisdictionName.toString().indexOf('TORONTO') > -1) {
        return currentJurisdiction == 'Metropolitan Toronto' ? 'Toronto' : 'Metropolitan Toronto';
      } else if (
        this.propertyJurisdiction.city &&
        this.propertyJurisdiction.city.municipality &&
        this.propertyJurisdiction.city.municipality.name.toString().toLowerCase().indexOf(' of ') > -1
      ) {
        let jurisdictionValues = this.propertyJurisdiction.city.municipality.name.toString().split(/ of /i);
        return Array.isArray(jurisdictionValues) && jurisdictionValues.length > 1
          ? jurisdictionValues[1].toString().trim()
          : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  closeDialog(): void {
    this.showIfAnyError = false;
    this.modalErrorComponent.removeAllDpFieldError();
    this.unitLevelPlanDialog = false;
    this.dialog.close();
  }

  createNewCondominiumPlan(type?: string): CondominiumPlan {
    return UnitLevelPlanUtil.createNewCondominiumPlan(this.currentMatter, type);
  }

  createCondominiumExpense(): CondominiumExpense {
    return UnitLevelPlanUtil.createCondominiumExpense();
  }

  updateHelpText(): void {
    this.statusBarService.currentHelpText = '';
  }

  get condominiumTotalExpenses(): Number {
    UnitLevelPlanUtil.updateTotalExpenses(this.unitLevelPlan);
    return this.unitLevelPlan.condominiumTotalExpenses;
  }

  getCondoByType(type: string): string {
    let condoPlanType: any = _.find(
      condoPlanTypeDropDownOptions[this.currentMatter.provinceCode],
      (condoPlanTypeObj) => condoPlanTypeObj.type === type
    );
    return condoPlanType ? condoPlanType.value : undefined;
  }

  addNewCondominiumPlan(): void {
    this.unitLevelPlan.condominiumPlans.push(this.createNewCondominiumPlan());

    setTimeout(() => {
      //jQuery(`.condo-plan:last-child .condo-plan-select select`).focus();
      jQuery('.condo-plan-select  select').filter(':visible:last').focus();
    }, 100);
  }

  removeCondominiumPlan(condominiumPlan: CondominiumPlan): void {
    this.unitLevelPlan.removeCondominiumPlan(condominiumPlan);
  }

  removeCondominiumExpense(condominiumExpense: CondominiumExpense): void {
    this.unitLevelPlan.removeCondominiumExpense(condominiumExpense);
    if (!this.currentMatter.isMatterProvinceNBorNS) {
      this.updateDPFieldErrors();
    }
  }

  addCondominiumExpense(): void {
    this.currentTimeStamp = Date.now();
    this.unitLevelPlan.condominiumExpenses.push(this.createCondominiumExpense());

    setTimeout(() => {
      //jQuery(`.condo-expense:last-child .condo-expense-unit input`).focus();
      jQuery('.condo-expense-unit input').filter(':visible:last').focus();
    }, 100);
  }

  ngAfterViewInit(): void {
    if (
      !this.currentMatter.isMatterProvinceNBorNS &&
      this.unitLevelPlan &&
      Array.isArray(this.unitLevelPlan.condominiumExpenses)
    ) {
      this.unitLevelPlan.condominiumExpenses.forEach((condoExpense, index) => {
        this.addCustomPinWarning(condoExpense.pinNumber, this.pinFieldKeyBase, index); //for PS matter, may contains partial pin (needs to show warning message)
      });
    }
    if (this.context.focusOnUnitNumber) {
      setTimeout(() => {
        jQuery(`#unitNumber-${this.currentTimeStamp}`).focus();
      }, 0);
    } else if (this.context.isUnitLevelPinReadOnly) {
      setTimeout(() => {
        jQuery(`#sharePercent-${this.currentTimeStamp}`).focus();
      }, 0);
    }
  }

  f9KeyStruckOnJurisdiction(event): void {
    this.unitLevelPlan.condominiumJurisdiction = this.createJurisdictionToggleValue(event.target.value);
  }

  generateF9HelpJurisdiction(): string {
    if (this.propertyJurisdiction && this.propertyJurisdiction.jurisdictionName) {
      let dropDownPropertyJurisdiction = dropDowns.f9HelpPropertyJurisdiction.find(
        (dropDown) => this.propertyJurisdiction.jurisdictionName.toString().indexOf(dropDown.label) > -1
      );
      if (dropDownPropertyJurisdiction) {
        return dropDownPropertyJurisdiction.value;
      } else if (
        this.propertyJurisdiction.city &&
        this.propertyJurisdiction.city.municipality &&
        this.propertyJurisdiction.city.municipality.name.toString().toLowerCase().indexOf(' of ') > -1
      ) {
        let jurisdictionValues = this.propertyJurisdiction.city.municipality.name.toString().split(/ of /i);
        return Array.isArray(jurisdictionValues) && jurisdictionValues.length > 1
          ? 'F9 = ' + jurisdictionValues[1].toString().trim()
          : '';
      } else {
        return '';
      }
    }
  }

  getPlanNumber(condominiumPlan: CondominiumPlan) {
    return UnitLevelPlanUtil.getPlanNumberUsingMatterProperty(condominiumPlan, this.currentMatter, this.unitLevelPlan);
  }

  updatePlanNumber(): void {
    UnitLevelPlanUtil.updatePlanNumber(this.unitLevelPlan, this.currentMatter, false);
  }

  createSelectUnitLevelPlanResult(isDirty: boolean): SelectUnitLevelPlanResult {
    let selectUnitLevelPlanResult = new SelectUnitLevelPlanResult();
    selectUnitLevelPlanResult.plan = this.context.unitLevelPlanOrg;
    selectUnitLevelPlanResult.editCommonExpenseCommand = this.editCommonExpenseCommand;
    selectUnitLevelPlanResult.isDirty = isDirty;
    return selectUnitLevelPlanResult;
  }

  saveUnitLevelPlanData(navigateToPreviousMatter: boolean = false, navigateToNextMatter: boolean = false): void {
    this.checkOverrideDescription();
    this.showIfAnyError = true;
    UnitLevelPlanUtil.checkLincAndRollNumber(
      this.modalErrorComponent,
      this.unitLevelPlan,
      this.currentMatter.provinceCode
    );
    this.checkPIDAndAccountNumber();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.updatePlanNumber();
      this.unitLevelPlanDialog = false;
      this.showIfAnyError = false;
      let isDirty = !this.unitLevelPlan.equals(this.unitLevelPlanOrg) || this.commonExpenseUpdated;
      this.context.unitLevelPlanOrg = _.cloneDeep(this.unitLevelPlan);
      this.updateCommonExpense();
      let selectUnitLevelPlanResult = this.createSelectUnitLevelPlanResult(isDirty);
      selectUnitLevelPlanResult.navigateToPreviousMatter = navigateToPreviousMatter;
      selectUnitLevelPlanResult.navigateToNextMatter = navigateToNextMatter;
      this.dialog.close(selectUnitLevelPlanResult);
    }
  }

  updateCommonExpense() {
    if (this.commonExpenseAdj && this.commonExpenseAdj.soaCommonExpense) {
      this.context.commonExpenseAdj.soaCommonExpense = new SoaCommonExpense(this.commonExpenseAdj.soaCommonExpense);
      this.context.commonExpenseAdj.infoOnly = this.commonExpenseAdj.infoOnly;
      this.context.commonExpenseAdj.soaCommonExpense.commonExpenseAmount = Number(
        this.unitLevelPlan.condominiumTotalExpenses
      );
      this.context.commonExpenseAdj.updateFromCommonExpense(
        this.currentMatter.getClosingDate(),
        this.currentMatter.isPaysForDateOfClosingVendor
      );
    }
  }

  get pinFieldKeyBase(): string {
    return this.currentMatter && this.currentMatter.isPartialPinAllowed
      ? 'matter.propertyteranet.pin.warningOnly'
      : 'matter.propertyteranet.pin';
  }

  addCustomPinWarning(elementValue, errorElementKey, index): void {
    UnitLevelPlanUtil.addCustomPinWarning(elementValue, errorElementKey, index, this.modalErrorComponent);
  }

  checkPIDAndAccountNumber(): void {
    if (this.currentMatter.isMatterProvinceNBorNS && !this.propertyErrorExists()) {
      this.modalErrorComponent.removeAllDpFieldError();
      this.unitLevelPlan.condominiumExpenses.forEach((item, index) => {
        if (item.pinNumber && !item.assessmentAccountNumber) {
          this.modalErrorComponent.createCustomDPFieldError(
            'matter.subjectProperty.unitLevelPlan.pinNumber' + index,
            'PID Number ' +
              item.pinNumber +
              ' does not have a corresponding' +
              (this.currentMatter.isMatterProvinceNB ? ' PAN Number.' : ' AAN Number.'),
            'PIDAndAccountNumber',
            'ERROR'
          );
        } else if (!item.pinNumber && item.assessmentAccountNumber) {
          this.modalErrorComponent.createCustomDPFieldError(
            'matter.subjectProperty.unitLevelPlan.assessmentAccountNumber' + index,
            (this.currentMatter.isMatterProvinceNB ? 'PAN Number ' : 'AAN Number ') +
              item.assessmentAccountNumber +
              ' does not have a corresponding PID Number.',
            'PIDAndAccountNumber',
            'ERROR'
          );
        }
      });
    }
  }

  propertyErrorExists(): boolean {
    let propertyErrors: DPError[] = this.modalErrorComponent.errorList.filter((item) => 'Property' === item.errorTopic);
    return propertyErrors && propertyErrors.length > 0 ? true : false;
  }

  updateDPFieldErrors(): void {
    UnitLevelPlanUtil.updateDPFieldErrors(this.modalErrorComponent, this.unitLevelPlan);
  }

  f9Clicked(): void {
    if (!this.inSecondModal && !this.isExpenseReadOnly) {
      this.openCommonExpenseAdjustment();
    }
  }

  openCommonExpenseAdjustment(): void {
    if (this.commonExpenseAdj) {
      let soaCommonExpense = this.commonExpenseAdj.soaCommonExpense;
      if (soaCommonExpense) {
        soaCommonExpense.commonExpenseAmount = Number(this.unitLevelPlan.condominiumTotalExpenses);
      }

      this.dialogService.matDialogContent({
        content: SoaCommonExpenseModal,
        context: {
          closingDate: this.currentMatter.getClosingDate(),
          provinceHstRateSlab: this.soaConsiderationTaxes,
          soaCommonExpense: soaCommonExpense,
          expenseType: StatementAdjustmentKey.COMMON_EXPENSE,
          statementAdjustmentHeading: this.currentMatter.statementOfAdjustmentHeading,
          addedFlag: false,
          currentMatter: this.currentMatter,
          inSecondModal: true,
          statementAdjustment: this.commonExpenseAdj
        },
        onFulfillment: (result) => {
          if (result) {
            //need to be mark matter dirty, to be consistent with from statement of adjustment topic
            this.commonExpenseUpdated = true;
            if (soaCommonExpense) {
              this.commonExpenseAdj.soaCommonExpense = new SoaCommonExpense(result.soaCommonExpense);
              this.commonExpenseAdj.updateFromCommonExpense(
                this.currentMatter.getClosingDate(),
                this.currentMatter.isPaysForDateOfClosingVendor
              );
            }
          }
        }
      });
    }
  }

  get title(): string {
    if (this.isProjectSaleMatterON) {
      return this.unitLevelPlan && this.unitLevelPlan.condominiumPlans && this.unitLevelPlan.condominiumPlans.length > 1
        ? projectConsts.unitLvlPlanModalTitle.unitLevelPlan
        : projectConsts.unitLvlPlanModalTitle.unitLevel;
    } else {
      return provinceBasedFieldLabels.get(
        'matter.subjectproperty.unitLevelPlanLabel',
        this.currentMatter && this.currentMatter.provinceCode
      );
    }
  }

  get condominiumTitle(): string {
    if (this.isProjectSaleMatterAB) {
      return subjectPropertyUnitLevelPlanLabels.LincPlanUnit;
    } else {
      return provinceBasedFieldLabels.get(
        'matter.subjectproperty.unitLevelPlanCondominiumTitle',
        this.currentMatter && this.currentMatter.provinceCode
      );
    }
  }

  get pinLabel(): string {
    return provinceBasedFieldLabels.get(
      'matter.subjectproperty.unitLevelPlanPinLabel',
      this.currentMatter && this.currentMatter.provinceCode
    );
  }

  get accountNumberLabel(): string {
    return provinceBasedFieldLabels.get(
      'matter.subjectproperty.unitLevelPlanAccountNumberLabel',
      this.currentMatter && this.currentMatter.provinceCode
    );
  }

  get isProjectSaleMatter(): boolean {
    return this.currentMatter && (this.currentMatter.isProjectSale || this.currentMatter.templateForProject);
  }

  get isProjectSaleMatterON(): boolean {
    return this.isProjectSaleMatter && this.currentMatter && this.currentMatter.isMatterProvinceON;
  }

  get isProjectSaleMatterAB(): boolean {
    return this.currentMatter && this.currentMatter.isProjectSale && this.currentMatter.isMatterProvinceAB;
  }

  get isMatterProvinceNBorNS(): boolean {
    return this.currentMatter && this.currentMatter.isMatterProvinceNBorNS;
  }

  getPinNumberColumnCssClass(): string {
    if (this.isProjectSaleMatterON) {
      return this.unitLevelPlan.condominiumPlans.length > 1 ? 'col-md-1' : 'col-md-3 col-md-3-24pct';
    } else if (this.isMatterProvinceNBorNS) {
      return 'col-md-3';
    } else {
      return this.unitLevelPlan.condominiumPlans.length > 1 ? 'col-md-3' : 'col-md-6';
    }
  }

  getCommonExpensesColumnCssClass(): string {
    if (this.isProjectSaleMatterON) {
      return 'col-md-1 col-md-1-101px';
    } else {
      return 'col-md-2';
    }
  }

  getAddNewColumnCssClass(): string {
    if (this.isProjectSaleMatterON) {
      return 'col-md-2';
    }
    if (this.isMatterProvinceNBorNS) {
      return 'col-md-5';
    }
    return this.unitLevelPlan.condominiumPlans.length > 1 ? 'col-md-4' : 'col-md-5';
  }

  getTotalExpensesColumnCssClass(): string {
    if (this.isProjectSaleMatterON) {
      return 'col-md-1 col-md-1-101px';
    } else {
      return 'col-md-2';
    }
  }

  onAutoCalcChange(event: any, index: number) {
    if (this.unitLevelPlan.condominiumExpenses[index].autoCalcInd) {
      this.updateCondominiumExpense(index);
    }
  }

  updateCondominiumExpense(index: number): void {
    UnitLevelPlanUtil.updateCondominiumExpense(index, this.unitLevelPlan, this.project);
    this.statusBarService.currentHelpText = '';
  }

  overrideDescChange(event: any, index: number) {
    UnitLevelPlanUtil.overrideCondoDescChange(index, this.unitLevelPlan);
  }

  teraviewLegalDescOverriddenTypeChanged(condominiumExpense: CondominiumExpense): void {
    UnitLevelPlanUtil.teraviewLegalDescOverriddenTypeChanged(
      condominiumExpense,
      this.currentMatter,
      this.unitLevelPlan
    );
  }

  get condominiumTotalSharePercentage(): Number {
    UnitLevelPlanUtil.updateTotalShare(this.unitLevelPlan);
    return this.unitLevelPlan.condominiumTotalSharePercentage;
  }

  condominiumExpensef9Clicked(index: number): void {
    if (this.isProjectSaleMatterON) {
      this.updateCondominiumExpense(index);
    }
  }

  updatecondominiumExpenseF9Help(index: number): void {
    if (this.isProjectSaleMatterON && this.project && this.project.projectAdjustmentConfig) {
      this.statusBarService.currentHelpText =
        'F9=' +
        (Number(this.unitLevelPlan.condominiumExpenses[index].sharePercentage) *
          this.project.projectAdjustmentConfig.totalMonthlyCommonExpenses) /
          100;
    }
  }

  isOverrideDescIndVisible(): boolean {
    return UnitLevelPlanUtil.isOverrideDescIndVisible(this.currentMatter);
  }

  checkOverrideDescription(): void {
    UnitLevelPlanUtil.checkOverrideDescription(this.unitLevelPlan);
  }

  clickActionDropDown(event) {
    this.editCommonExpenseCommand = event.key;
  }

  isEditCommonExpenseCommandReCalculate(): boolean {
    return this.editCommonExpenseCommand != undefined;
  }

  isCommonExpenseReadonly(): boolean {
    return this.context.currentMatter && this.context.currentMatter.isInterimOccupancyAdjCommonExpOrTaxes();
  }

  planNumberChanged(condominiumExpense: CondominiumExpense): void {
    if (condominiumExpense.condominiumPlan) {
      condominiumExpense.planNumber = this.getPlanNumber(condominiumExpense.condominiumPlan);
    }
    this.teraviewLegalDescOverriddenTypeChanged(condominiumExpense);
  }

  goToPreviousMatter(): void {
    this.saveUnitLevelPlanData(true, false);
  }

  goToNextMatter(): void {
    this.saveUnitLevelPlanData(false, true);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent = (event: KeyboardEvent) => {
    if (event.shiftKey && event.keyCode == CustomKeyCodesEnum.PageUp) {
      event.stopPropagation();
      this.goToPreviousMatter();
    }
    if (event.shiftKey && event.keyCode == CustomKeyCodesEnum.PageDown) {
      event.stopPropagation();
      this.goToNextMatter();
    }
  };

  getPinMaxLength(): number {
    return this.propertyPinHelper.getPid(this.context.currentMatter);
  }

  getPANMaxLength(): number {
    return this.propertyPinHelper.getPANMaxLength(this.context.currentMatter);
  }

  validatePinNB(elementValue, errorElementKey, index) {
    UnitLevelPlanUtil.validatePinNB(
      this.context.currentMatter,
      elementValue,
      errorElementKey,
      index,
      this.modalErrorComponent,
      this.getPinMaxLength()
    );
  }
}
