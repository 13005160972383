import {Injectable} from '@angular/core';
import {uuidApi} from './uuid-api';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../core';

// This a sharable service used for UUID generation.
@Injectable()
export class UUIDService {

  constructor(private http: HttpClient) {
  }

  /**
   *
   * @param {number} count - number of UUIDs to request
   * @returns {Observable<number[]>} UUIDs returned from backend
   */
  getUUIDsFromServer(count: number): Observable<number[]> {
    let url: string = `${ uuidApi.uuids }?count=${ count }`;

    return this.http.get(url)
    .map((res) => {
      let newUUIDs: number[] = res[ 'GUIDs' ];
      return newUUIDs;
    });
  }
}
