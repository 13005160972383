<div class="dp-theme {{getThemeClass()}} {{getFontSizeClass()}}">
    <div class="matter-header">
        <div class="container">
            <div class="row">
                <ul class="matter-tabs" *ngIf="tabs">
                    <li *ngFor="let tab of horizontalTabs; let tabIndex = index"
                        class="col-lg-2 top-list"
                        [ngClass]="{'tab-first': tabIndex===0,'inActive':tab.active==='inActive','active':tab.active==='active',
                        'locked': (tab.isLocked() ||isTabReadOnly() || isDependantMatterDirty(tab)),
                        'dirty-tab': (tab.isDirty() && !tab.isLocked() && !isTabReadOnly() && !isDependantMatterDirty(tab)) }">
                        <!-- inActive active-->
                        <div class="matter-interior" (click)="openSelectedTab(tab)">
                            <div class="icon-cell">
                                        <span *ngIf="tabIndex>0">
                                            <i class="darkgray icon fa fa-lock" aria-hidden="true"
                                               *ngIf="(tab.isLocked() && !isTabReadOnly() && !isDependantMatterDirty(tab))"></i>
                                            <i class="ddd icon fa fa-file-text-o flip-icon"
                                               [ngClass]="{'primary' : tab.isDirty()}"
                                               aria-hidden="true"
                                               *ngIf="tab.type!='contact' && tab.type!='project' && tab.type!='OPPORTUNITY' && !tab.isLocked() && !isTabReadOnly() &&
                                               !isDependantMatterDirty(tab)"></i>
                                            <i class="fa fa-address-book-o primary icon" aria-hidden="true"
                                               *ngIf="tab.type=='contact' && !tab.isLocked() && !isTabReadOnly() && !isDependantMatterDirty(tab)"
                                               [ngClass]="{'primary' : tab.isDirty()}"></i>
                                             <i class="fa fa-building-o primary icon" aria-hidden="true"
                                                *ngIf="tab.type=='project' && !tab.isLocked()"
                                                [ngClass]="{'primary' : tab.isDirty()}"></i>
                                            <i class="fa fa-lightbulb icon" aria-hidden="true"
                                               *ngIf="tab.type=='OPPORTUNITY' && !tab.isLocked()"
                                               [ngClass]="{'primary' : tab.isDirty()}"></i>
                                            </span>
                                <i *ngIf="tabIndex==0 && showFileTextIcon(tab)"
                                   class="icon fa fa-file-text-o flip-icon matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isIntegrationsTabType(tab)"
                                   class="icon  fal fa-puzzle-piece matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isMortgageInstructingTabType(tab)"
                                   class="icon  fal fa-puzzle-piece matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isEventsTabType(tab)"
                                   class="icon fa fa-calendar matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isMessagesTabType(tab)"
                                   class="icon fal fa-envelope matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isAdjudicationTabType(tab)"
                                   class="icon fas fa-check-double matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isContactsTabType(tab)"
                                   class="icon fa-address-book fal matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isProjectsTabType(tab)"
                                   class="icon fa-building fal matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isDespositManagerTabType(tab)"
                                   class="icon fa-university fal matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isExportFileManagerTabType(tab)"
                                   class="icon fa-file-code-o fal matter-first-icon"
                                   aria-hidden="true"></i>
                                <i *ngIf="tabIndex==0 && isOpportunityMatterTabType(tab)"
                                   class="icon fa-lightbulb fal matter-first-icon"
                                   aria-hidden="true"></i>
                                <span *ngIf="tabIndex==0 && isOpportunitiesTabType(tab)" class="unity-c-icon"><img src="../../../assets/img/UnityC_RGB_app.svg"/></span>
                            </div>
                            <div class="info-cell" *ngIf="!isOpportunitiesTabType(tab)">
                                <div class="info">
                                    <h3 class="mattertype">{{ tab.tabTypeForDisplay }}  {{showProvinceInMatterTab(tab)}}
                                        <a class="pull-right close" *ngIf="tabIndex>0" (click)="closeTab(tab , $event)">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </a>
                                    </h3>
                                    <span class="tool-tip-icon">
                                        <h5 class="fileid text-overflow" *ngIf="tabIndex>0">{{ tab.title  }} </h5>
                                        <h4 class="name text-overflow" *ngIf="tabIndex>0">{{ tab.lineNumber }}</h4>
                                    </span>
                                    <span class="tool-tip-message left-150 top-50">
                                        <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                        <span>{{ tab.title }}<br *ngIf="tab.lineNumber">{{ tab.lineNumber }}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="clear"></div>

                        </div>
                    </li>
                    <li class="col-lg-2 second-list-li" *ngIf="tabs.length>6 && isMenuClosed==false"
                        (mouseleave)="isMenuClosed=true">
                        <ul class="second-list row" [hidden]="isMenuClosed"> {{ isDependantMatterDirty(tab)}}
                            <li *ngFor="let tab of tabs  | slice:6; let tabIndex = index"
                                class="{{ tab.active }} col-lg-12"
                                [ngClass]="{'inActive':tab.active==='inActive','active':tab.active==='active', 'locked': (tab.isLocked() ||isTabReadOnly() || isDependantMatterDirty(tab)), 'dirty-tab': (tab.isDirty() && !isTabReadOnly() && !isDependantMatterDirty(tab)) }"
                            >
                                <div
                                    class="matter-interior" (click)="openSelectedTab(tab)">
                                    <div class="icon-cell">
                                        <span>
                                            <i class="darkgray icon fa fa-lock" aria-hidden="true"
                                               *ngIf="tab.isLocked()"></i>
                                            <i class="ddd icon fa fa-file-text-o flip-icon"
                                               [ngClass]="{'primary' : tab.isDirty()}"
                                               aria-hidden="true"
                                               *ngIf="tab.type!='contact' && tab.type!='project' && !tab.isLocked() && !isTabReadOnly() &&
                                               !isDependantMatterDirty(tab)"></i>
                                            <i class="fa fa-address-book-o primary icon" aria-hidden="true"
                                               *ngIf="tab.type=='contact' && !tab.isLocked() && !isTabReadOnly() && !isDependantMatterDirty(tab)"
                                               [ngClass]="{'primary' : tab.isDirty()}"></i>
                                            <i class="fa fa-building-o primary icon" aria-hidden="true"
                                               *ngIf="tab.type=='project' && !tab.isLocked() && !isTabReadOnly()"
                                               [ngClass]="{'primary' : tab.isDirty()}"></i>
                                            </span>

                                    </div>
                                    <div class="info-cell">
                                        <div class="info">
                                            <h3 class="mattertype">
                                                {{ tab.type }} {{showProvinceInMatterTab(tab)}}
                                                <a class="pull-right close" (click)="closeTab(tab , $event)"><i
                                                class="fa fa-times" aria-hidden="true"></i></a></h3>
                                            <span class="tool-tip-icon">
                                                <h5 class="fileid text-overflow">{{ tab.title }}</h5>
                                                <h4 class="name text-overflow">{{ tab.lineNumber }}</h4>
                                            </span>
                                            <span class="tool-tip-message left-150">
                                                <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                                <span>{{ tab.title }}<br *ngIf="tab.lineNumber">{{ tab.lineNumber }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                            </li>
                        </ul>

                    </li>
                </ul>
                <div class="tab-extra-btn" (click)="openSecondListMenu($event);" *ngIf="tabs && tabs.length>6">
                    <button class="fa fa-caret-down" [ngClass]="{active: !isMenuClosed}"></button>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
    <div class="matters-content" style="margin-bottom: 20px;"
         [ngClass]="{'padding-bottom-220' : isAnyNotificationActive() }">
        <fieldset>
            <ng-container *ngIf="!isContentLoading">
            <router-outlet></router-outlet>
            </ng-container>
        </fieldset>
    </div>

    <div *ngIf="lockScreenService.lockForUpdate" class="lock-screen-spinner">
    </div>


    <div class="inspector-footer">
        <div class="error-box" [ngClass]="{'error-box-open' : isAnyNotificationActive()}">
            <div class="error-header">
                <ng-container *ngFor="let item of dpFooterNotifications">
                    <ul *ngIf="notificationCount(item) > 0" (click)="openFooterNotificationTab(item)">
                        <li [ngClass]="{'active' : item.isActive}"><a innerHtml="{{item.notificationHeader}} ({{notificationCount(item)}})">
                            </a></li>
                    </ul>
                </ng-container>

                <div class="pull-right" (click)="closeFooterNotification();"><a><i class="fa fa-chevron-down"
                                                                                   aria-hidden="true"></i></a></div>
                <div class="clear"></div>
            </div>
            <div class="error-main">
                <ul>
                    <li *ngFor="let dpError of activeNotificationList()">
                        <div>
                            <span *ngIf="dpError.errorType && (dpError.errorType == 'NOTIFICATION')" class="info-icon padding-right-5 padding-left-5"><i
                                 class="fa fa-info-circle tarion-info-circle padding-bottom-2" aria-hidden="true"></i></span>
                            <i  *ngIf="dpError.errorType && (dpError.errorType != 'NOTIFICATION')" class="padding-right-5" [ngClass]="(dpError.errorType && dpError.errorType == 'WARNING')? 'fa fa-exclamation-triangle yellow':'fa fa-times-circle red'"
                               aria-hidden="true">
                            </i>
                            <b>{{replacePlaceHolderInErrorMessage(dpError)}} ></b><span
                            class="padding-left-5 line-height-adjust"
                            *ngIf="!dpError.fieldCode" [innerHTML]="getErrorMessage(dpError)">
                            </span>
                            <a *ngIf="dpError.fieldCode" class="padding-left-5" (click)="focusErrorElement(dpError)" [innerHTML]="getErrorMessage(dpError)"></a>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-bottom-content">
                <ul>
                    <li (click)="openNotification();" *ngIf="totalNotificationCount() > 0">
                        <div class="red-circle">{{totalNotificationCount()}}</div>
                    </li>
                    <li class="middle">&nbsp;{{statusBarService.currentHelpText}}</li>
                    <li class="middle" class="right">&nbsp;{{fieldCodeService.fieldCodeText}}</li>
                    <li></li>
                </ul>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</div>
