import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';

export class WritExecutionInfo {
  writExecutionId: number;
  enforcementOfficeNumber: string;
  enforcementOfficeName: string;

  constructor(writExecutionId: number, enforcementOfficeName: string, enforcementOfficeNumber: string) {
    this.writExecutionId = writExecutionId;
    this.enforcementOfficeNumber = enforcementOfficeNumber;
    this.enforcementOfficeName = enforcementOfficeName;
  }

}

export class TeranetWritNumberSearchRequest extends BaseEntity {
  parcelRegisterId: number;
  writNumber: string;
  writExecutions: WritExecutionInfo[] = [];
  lro: string;
  docketSystemId: number;
  teranetUser: TeranetUser;
  enforcementOfficeNumber: string;
  enforcementOfficeName: string;
  reLine: string;
}


