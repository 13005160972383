import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type VirusScanStatus = 'INFECTED' | 'PENDING' | 'IN_PROGRESS' | 'CLEAN ';

export const VirusScanStatusValue = {
  INFECTED: 'INFECTED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  CLEAN: 'CLEAN'
};

export class UnityConnectDocument extends BaseEntity {

  id: number;
  description: string;
  documentName: string;

  virusScanStatus: VirusScanStatus;

  get isVirusClean(): boolean {
    return this.virusScanStatus === VirusScanStatusValue.CLEAN;
  }

  get isVirusInfected(): boolean {
    return this.virusScanStatus === VirusScanStatusValue.INFECTED;
  }

  get isVirusPending(): boolean {
    return this.virusScanStatus === VirusScanStatusValue.PENDING;
  }

  get isVirusInProgress(): boolean {
    return this.virusScanStatus === VirusScanStatusValue.IN_PROGRESS;
  }

  get isVirusScanPending(): boolean {
    return (this.virusScanStatus === VirusScanStatusValue.PENDING || this.virusScanStatus === VirusScanStatusValue.IN_PROGRESS);
  }

  get isInfected(): boolean {
    return this.virusScanStatus === VirusScanStatusValue.INFECTED;
  }

}
