import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Matter} from '../../../shared/matter';
import {AutoComplete} from 'primeng/autocomplete';
import {PrivateLendersTitleComponent} from '../title/private-lenders-title.component';
import {PrivateLenderInfoComponent} from '../private-lender-info/private-lender-info.component';
import {MatterParticipantWrapper} from '../../../shared/matter-participant-wrapper';
import {Observable, Subject, Subscription} from 'rxjs/Rx';
import {COMPUTER_SHARE_ALTERNATE_NAME, COMPUTER_SHARE_LENDER_NAME, Contact} from '../../../shared/contact';
import {messages} from '../../../../common/messages';
import {AddressTypes} from '../../../shared/address-types';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {Logger} from '@nsalaun/ng-logger';
import {ContactCommandService} from '../../../../contact/contact-command.service';
import {MortgageeEnclosureService} from '../../../../admin/mortgagee-enclosure/mortgagee-enclosure.service';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ContactService} from '../../../../shared-main/contact.service';
import {LegalFirmService} from '../../../../admin/accounts/legal-firm/legal-firm.service';
import {GetGlobalSaveModelService} from '../../../shared/get-global-save-model.service';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {MatterService} from '../../../matter.service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {MatterParticipantService} from '../../../matter-participant-service';
import {TabsService} from '../../../../core/tabs.service';
import {ActivatedRoute} from '@angular/router';
import {dischargeRegisteredByOptions, TitleDetailsTypeOptions} from '../dropdown-options';
import {PurchaserCapacity} from '../../../purchaser/capacity/purchaser-capacity';
import {Utils} from '../../../shared/utils';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {MatterParticipant} from '../../../shared/matter-participant';
import {
  Mortgage,
  MortgageCapacityOption,
  MortgageCapacityType,
  MortgageContactInfoType,
  MortgageeUsageType
} from '../../../shared/mortgage';
import {Address, SameAsAddressOption} from '../../../shared/address';
import {Constants, SnapshotBurgerMenuActions} from '../../../../shared-main/constants';
import {LendingInstitution} from '../../../../contact/lending-institution';
import {LendingInstitutionsModalComponent} from '../../../../contact/lending-institutions.modal.component';
import {CommonContactDialogComponent} from '../../../matter-opening/common-contact-dialog/common-contact-dialog.component';
import {MatterParticipantRoleTypes} from '../../../shared/matter-participant-role-types';
import {ContactTab} from '../../../../contact/contact-tab';
import {AttentionInfoComponent} from '../../../matter-opening/attention/attention-info.component';
import {LenderFormsService} from '../../../forms/lender-forms/lender-forms-service';
import {DpBooleanValueTypes} from '../../../shared/dp-boolean';
import {TitleDetailsTypeDropdown} from '../../../shared/mortgage-contact-info';
import {Account} from '../../../../admin/accounts/shared/account';
import {AccountService} from '../../../../admin/accounts/account.service';
import {MortgageSoAdjService} from '../../../../shared-main/mortgage-so-adj.service';
import {IvfGeneratorBase} from '../../../shared/ivf-generator-base';
import {DocumentProductionService} from '../../../document-production/document-production.service';
import {StatusBarService} from '../../../../shared-main/status-bar.service';
import {LockScreenService} from '../../../../core/lock-screen.service';
import {DocumentProfileCache} from '../../../../shared-main/document-profile-cache.service';
import {ContactChangesListener} from '../../../../contact/contact-changes-listener';
import {AssystPayoutRequestModalComponent} from '../assyst-payout-request-modal/assyst-payout-request.modal.component';
import {PayoutStatement} from '../../../../shared-main/telus/payout-statement';
import {TelusService} from '../../../../shared-main/telus/telus-service';
import {DpFooterTypes} from '../../../../shared/error-handling/dp-footer-notification';
import {TelusAssystDataValidationService} from '../../../../shared-main/telus/telus-assyst-data-validation-service';
import {AuthZService} from '../../../../core/authz/auth-z.service';
import {MatterUtil, Section} from '../../../shared';
import {AssystPayoutHistoryModalComponent} from '../assyst-payout-history/assyst-payout-history.modal.component';
import {PayoutStatementStatus} from '../../../../shared-main/telus/payout-statement-status';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {StewartTitleService} from '../../../../shared-main/stewart-title/stewart-title-service';
import {StewartAssystUser} from '../../../../shared-main/telus/stewart-assyst-user';
import {AssystImportPayoutModalComponent} from '../assyst-import-payout/assyst-import-payout.modal.component';
import {AddressUtil} from '../../../shared/address-util';
import {AssystPayoutData} from '../assyst-import-payout/assyst-payout-data';
import {MortgagePayout} from '../mortgage-payout/mortgage-payout';
import moment from 'moment';
import {MatterUndertaking} from '../../../shared/matter-undertaking';
import {Tab, TabsComponent} from '../../../../shared/tabbing';
import {WizardTab} from '../../../../shared/tabbing/wizard-tab';
import {SelectItem} from 'primeng/api';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {UserStateService} from '../../../../shared-main/user-state/user-state.service';

declare var jQuery: any;

declare var _: any;

const defaultTab = 'Profile';

@Component({
  selector: 'dp-mortgagee',
  templateUrl: 'mortgagee.component.html',
  styleUrls: [ './mortgagee.style.scss' ],
  providers: [ CurrencyPipe ]
})
export class MortgageeComponent extends IvfGeneratorBase implements OnInit {
  @Input() matter: Matter;
  @Input() mortgageeLabel: string = 'Mortgagee';
  @Input() payoutStatement: PayoutStatement;
  @Output() payoutStatementRequested = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @ViewChild(AttentionInfoComponent) attentionInfoComponent: AttentionInfoComponent;
  @ViewChild(PrivateLendersTitleComponent) public privateLendersTitleComponent: PrivateLendersTitleComponent;
  @ViewChild('mortgageBrokerAttentionField') mortgageBrokerAttentionField: AutoComplete;
  @ViewChild('mortgageBrokerField') mortgageBrokerField: AutoComplete;
  @ViewChild('solicitorAutoComplete') solicitorAutoComplete: AutoComplete;
  @ViewChildren(PrivateLenderInfoComponent) privateLenderInfoComponents: QueryList<PrivateLenderInfoComponent>;
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() isOpenedFromWizard: boolean = false;
  @Input() dpReadOnlyBlock: boolean = false;
  @Input() mortgageType: MortgageeUsageType = 'MORTGAGE';

  _mortgage: Mortgage;
  mortgageCapacityOptions: MortgageCapacityOption[];
  isAddressDisabled: boolean = false;
  selectedMortgagees: MatterParticipantWrapper[];
  matterPrivateLenders: MatterParticipantWrapper[] = [];
  mortgageBroker: MatterParticipantWrapper;
  searchTermStreamClient = new Subject<string>();
  sendDeleteAttentionNotification = new Subject<string>();
  public privateLenderSearchSubject: Subject<string> = new Subject<string>();
  public mortgageBrokerSearchSubject: Subject<string> = new Subject<string>();
  public attentionSearchSubject: Subject<string> = new Subject<string>();
  showAttentionsDropDownArrow: boolean;
  autoCompleteEmptyFlag: any;
  mortgagees: Contact[];
  mortgageesLoading: boolean = false;
  privateLenderOptions: Contact[] = [];
  privateLendersLoading: boolean = false;
  mortgageBrokerOptions: Contact[] = [];
  mortgageBrokersLoading: boolean = false;
  mortgageBrokerAttentionLoading: boolean = false;
  mortgageBrokerAttentionOptions: Contact[] = [];
  attentionList: Contact[];
  solicitors: any;
  solicitorsLoading: boolean = false;
  searchTermSolicitor: Subject<string> = new Subject<string>();
  solicitorAutoCompleteEmptyFlag: boolean = false;
  selectedSolicitor: MatterParticipantWrapper;
  lawFirms: any;
  lawFirmsLoading: boolean = false;
  searchTermLawFirm: Subject<string> = new Subject<string>();
  lawFirmAutoCompleteEmptyFlag: boolean = false;
  selectedLawFirm: MatterParticipantWrapper;
  selectedLawFirmInputValue: string;
  lawFirmKeyDownCode: number;
  solicitorsForLawFirm: Contact[] = [];
  selectedSolicitorsForLawFirm: any;
  showSolicitorDropDownArrow: boolean = false;
  firmSelected: boolean = false;
  lawFirmSelected: boolean = false;
  lawFirmTrashMessage: string = messages.mortgage.lawFirmTrashMessage;
  contactAddressType: string = AddressTypes.solicitorAddress;
  solicitorReportAddressDd: SameAsAddressOption[] = [];
  solicitorServiceAddressDd: SameAsAddressOption[] = [];
  solicitorAddressDdOptions: SameAsAddressOption[] = [];
  utils: Utils;
  localAttentionOptions: Contact[] = [];
  needToRefreshAttentionOptions: boolean = false;
  public mortgageContactInfoType: MortgageContactInfoType;
  public activeParamSubscription: Subscription;
  titleDetailsTypeOptions: TitleDetailsTypeDropdown[] = TitleDetailsTypeOptions;
  account: Account;
  lenders: LendingInstitution[] = [];
  historicalPayoutStatements: PayoutStatement[];
  solicitorAssystUser: StewartAssystUser;
  assystPayoutMessages: string[] = [];
  paymentStates: SelectItem[] = [];
  protected readonly provinceBasedFieldLabels = provinceBasedFieldLabels;

  constructor(public contactQueryService: ContactQueryService,
              public documentProfileCache: DocumentProfileCache,
              public contactService: ContactService,
              public matterService: MatterService,
              public legalFirmService: LegalFirmService,
              public logger: Logger,
              public appConfig: AppConfig,
              public globalSaveModelService: GetGlobalSaveModelService,
              public dialogService: DialogService,
              public tabsService: TabsService,
              public mortgageeEnclosureService: MortgageeEnclosureService,
              public errorService: ErrorService,
              public activatedRoute: ActivatedRoute,
              public contactCommandService: ContactCommandService,
              public currencyPipe: CurrencyPipe,
              public lenderFormsService: LenderFormsService,
              public matterParticipantService: MatterParticipantService,
              public mortgageSoAdjService: MortgageSoAdjService,
              public contactChangesListener: ContactChangesListener, public telusService: TelusService, public parentTabsComponent: TabsComponent,
              public accountService: AccountService, public documentProductionService: DocumentProductionService, public statusBarService: StatusBarService,
              public lockScreenService: LockScreenService, public authZService: AuthZService, public teranetService: TeranetService,
              public telusAssystDataValidationService: TelusAssystDataValidationService,
              public stewartTitleService: StewartTitleService,
              public userStateService: UserStateService) {
    super(documentProductionService, dialogService, statusBarService);
  }

  ngOnInit(): void {
    // WARNING do not use ngOnInit instead use initializeState(), because when you switch matter tabs that have similar paths, this component may not be
    // reinitialized and could end up with a stale state of the mortgage data

    // in the case of account information, account will be the same across any of the open tabs so ngOnInit is ok.
    this.getShallowAccount();

  }

  @Input()
  set mortgage(mortgage: Mortgage) {
    this._mortgage = mortgage;
    this.initializeState();

  }

  get mortgage(): Mortgage {
    return this._mortgage;
  }

  get solicitor(): Contact {
    let solicitorMP: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    return solicitorMP && solicitorMP.contact;
  }

  initializeState(): void {
    this.utils = new Utils();
    this.attentionList = [];
    this.showAttentionsDropDownArrow = false;
    this.showSolicitorDropDownArrow = false;
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    this.selectedMortgagees = [];

    this.initializeMortgageCapacity();

    this.initializeMortgageTitleDetailsType();

    // AutoComplete
    this.searchTermStreamClient
    .switchMap((term: string) => {
      this.mortgageesLoading = true;
      if (term.trim() === '') {
        this.autoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });

        return observable;

      } else {
        this.autoCompleteEmptyFlag = false;
        return this.contactService.getActiveContactListPerPage(term.trim(), 'MORTGAGEE_MATTER_TAB', false, 1, 15, null, true);
      }
    })
    .subscribe(
      (data: any) => {
        this.mortgagees = data;
        this.mortgageesLoading = false;
      },
      error => {
        this.mortgageesLoading = false;
        this.logger.error('mortgagees search error:', error);
      }
    );

    this.buildMortgageeStructures();

    this.privateLenderSearchSubject.switchMap((criteria: string) => {
      this.privateLendersLoading = true;
      if (!criteria || criteria.trim() === '') {
        return Observable.create(observer => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
      } else {
        return this.contactService.getActiveContactListPerPage(criteria.trim(), 'CLIENT', false, 1, 15);
      }
    }).subscribe(
      (privateLenders: Contact[]) => {
        this.privateLendersLoading = false;
        this.privateLenderOptions = privateLenders;
        // this.privateLenderOptions = this.sanitizePrivateLendersSearchResults(privateLenders)
      },
      error => {
        this.privateLendersLoading = false;
        this.logger.error('public Lenders search error:', error);
      }
    );
    this.initializeMatterPrivateLenders();

    // Law Firm
    this.searchTermLawFirm.switchMap((term: string) => {
      this.lawFirmsLoading = true;
      this.lawFirmAutoCompleteEmptyFlag = false;
      if (term.trim() === '') {
        this.lawFirmAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next(observer.next(this.utils.getStartTypingToContact()));
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getActiveContactListPerPage(term, 'LAW_FIRM', false);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.lawFirmsLoading = false;
        return this.lawFirms = data;
      },
      error => {
        this.logger.error('law firms search error', error);
        this.lawFirmsLoading = false;
      }
    );

    // Solicitor
    this.searchTermSolicitor.switchMap((term: string) => {
      this.solicitorsLoading = true;
      this.solicitorAutoCompleteEmptyFlag = false;
      if (term.trim() === '') {
        this.solicitorAutoCompleteEmptyFlag = true;
        let observable = Observable.create((observer) => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });

        return observable;

      } else {
        return this.contactService.getContactsType(term, 'SOLICITOR-DISPLAYNAME-ADDRESS', false, null, true);
      }
    })
    .subscribe(
      (data: any[]) => {
        this.solicitorsLoading = false;
        return this.solicitors = data;
      },
      error => {
        this.logger.error('solicitors search error', error);
        this.solicitorsLoading = false;
      }
    );

    this.buildLawFirmStructure(false);
    this.buildSolicitorStructure(false);

    // TODO FIX refactor this
    if (!this.isMassUpdateTab() && !this.matter.isCopyInProgress) {
      this.globalSaveModelService.getItem$.takeUntil(this.ngUnsubscribe).subscribe(() => {
        //Close existing shutters before switching to a new matter,
        // to release any locks when changing to a different tab or after saving a matter
        // open at the same topic
        this.unLockAllMortgagees();
        this.closeAllOpenedPrivateLenderShutters();
        this.buildMortgageeStructures();
        this.buildSolicitorStructure(false);
        this.buildLawFirmStructure(false);

      });
    }

    // should not do the following, otherwise it could overwrite the possible manually updated value
    // this.matter.reCalculateLenderReLine(this.mortgage);

    this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);

    this.initSolicitorReportAndServiceAddressDd();

    this.initPaymentStates();
  }

  changePaymentStatesValue() {
    this.enableSave();
  }

  //set payment state
  initPaymentStates() {
    this.paymentStates = [
      {label: 'Select Payment State', value: 'QUESTION'},
      {label: 'Funds Paid', value: 'FUNDS_PAID'},
      {label: 'Payment Overdue', value: 'PAYMENT_OVERDUE'}
    ];
    if (this.mortgage && this.mortgage.payoutStatement) {
      if (this.mortgage.payoutStatement.paymentState === undefined) {
        this.mortgage.payoutStatement.paymentState = 'QUESTION';
      }
      if (this.mortgage.payoutStatement && this.mortgage.payoutStatement.isCancelled()) {
        this.mortgage.payoutStatement.paymentState = 'QUESTION';
      } else if (this.mortgage.payoutStatement && this.mortgage.payoutStatement.getLatestPayoutStatementStatus() && this.mortgage.payoutStatement.getLatestPayoutStatementStatus().isFundsPaid()) {
        this.mortgage.payoutStatement.paymentState = 'FUNDS_PAID';
      } else if (this.mortgage.payoutStatement && this.mortgage.payoutStatement.getLatestPayoutStatementStatus() && this.mortgage.payoutStatement.isPayoutStatementExpired() && !this.mortgage.payoutStatement.getLatestPayoutStatementStatus().isFundsPaid()) {
        if (this.mortgage.payoutStatement.paymentState === 'QUESTION') {
          this.mortgage.payoutStatement.paymentState = 'PAYMENT_OVERDUE';
        }
      }
    }
  }

  initSolicitorReportAndServiceAddressDd() {
    const solicitorAddress: Address = this.matter.getMortgageSolicitorAddress(this.mortgage);
    this.solicitorReportAddressDd = this.matter.createMortgageSolicitorReportAddressDdOptions(solicitorAddress, this.mortgage);
    this.solicitorServiceAddressDd = this.matter.createMortgageSolicitorServiceAddressDdOptions(solicitorAddress, this.mortgage);
  }

  initializeMortgageCapacity(): void {
    if (this.mortgage && !this.mortgage.mortgageCapacityType && this.matter && !this.isOpportunityMatter()) {
      this.mortgage.mortgageCapacityType = 'SILENT';
      this.mortgage.mortgageCapacityDescription = PurchaserCapacity.getMortgageCapacityDescription('SILENT', this.matter.getPrivateLenders(this.mortgage).length, this.matter.provinceCode);
    }
  }

  initializeMortgageTitleDetailsType(): void {
    if (this.mortgage && this.mortgage.mortgageContactInfo && !this.mortgage.mortgageContactInfo.titleDetailsType) {
      this.mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
    }
  }

  isOpenedFromWizardBeforeMatterSave(): boolean {
    return !!this.isOpenedFromWizard && !!this.dpReadOnlyBlock;
  }

  closeAllOpenedPrivateLenderShutters(): void {
    console.log('closeAllOpenedPrivateLenderShutters');
    this.matterPrivateLenders.forEach((wrapper: MatterParticipantWrapper) => {
      this.setExpanded(wrapper, false);
    });
  }

  unLockAllMortgagees(): void {
    if (Array.isArray(this.selectedMortgagees)) {
      this.selectedMortgagees.forEach(selectedMortgagee => {
        this.matterParticipantService.unlockParticipantOnTabClose(selectedMortgagee, this.matter);
      });
    }
  }

  // This method is for fill the purchaser tab data and create
  //  the autocomplete (contact) dynamically .
  buildMortgageeStructures(): void {
    const matterParticipantSorted: MatterParticipant[] = this.matter.getMortgagees(this.mortgage) || [];
    this.selectedMortgagees = [];
    if (Array.isArray(matterParticipantSorted) && matterParticipantSorted.length > 0) {
      this.createMortgageeListByMatterParticipant(matterParticipantSorted);
    } else {
      this.initializedMortgagee();
    }
  }

  public createMortgageeListByMatterParticipant(matterParticipantSorted: MatterParticipant[]): void {

    this.selectedMortgagees = [];
    const length: number = matterParticipantSorted.length;

    for (let i: number = 0; i < length; i++) {

      const contact: Contact = matterParticipantSorted[ i ].contact;
      matterParticipantSorted[ i ].contact = new Contact(contact);
      this.attentionList = [];

      // let isStale: boolean = false;
      let {sourceContactId}: Contact = matterParticipantSorted[ i ].contact;
      //If contact is already locked then no need to reload source contact and sync again
      if (sourceContactId && !matterParticipantSorted[ i ].sourceContactLockAcquired) {
        this.contactQueryService
        .getContactForMatter(sourceContactId)
        .subscribe((sourceContact: Contact) => {
          const {contact} = matterParticipantSorted[ i ];
          const currentMortgagee: MatterParticipantWrapper = this.selectedMortgagees.find(p => p.contactId === contact.id);
          if (currentMortgagee) {
            this.matterParticipantService.updateParticipantWrapperState(currentMortgagee, sourceContact);
            this.getAttentionPickList(sourceContact);
          }
        });
      } else {
        this.getAttentionPickList();
      }

      this.selectedMortgagees.push(this.createWrapperFromMatterParticipant(matterParticipantSorted[ i ]));
    }
  }

  /**
   * Initialize the first purchaser option
   */
  initializedMortgagee(): void {
    if (this.selectedMortgagees && this.selectedMortgagees.length === 0) {
      let firstMatterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
      firstMatterParticipantWrapper.editMode = true;
      firstMatterParticipantWrapper.selectedDetailsTabIndex = 0;
      this.selectedMortgagees.push(firstMatterParticipantWrapper);
      this.attentionList = [];
    }
  }

  /**
   * This method creates matter participant wrapper from existing matter participant
   * @param matterParticipant
   * @returns {MatterParticipantWrapper}
   */
  createWrapperFromMatterParticipant(matterParticipant: MatterParticipant): MatterParticipantWrapper {
    let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    matterParticipantWrapper.matterParticipant = matterParticipant;
    // matterParticipantWrapper.dataModel = {fullName : matterParticipant.contact.genericFullName};
    matterParticipantWrapper.dataModel = matterParticipant.contact;
    matterParticipantWrapper.editMode = false;
    //Since we have removed the functionality of creating mortgagee locally on matter so it should always be "selected".
    matterParticipantWrapper.createdOrSelected = 'Selected';
    this.isAddressDisabled = true;

    matterParticipantWrapper.selectedDetailsTabIndex = 0;
    return matterParticipantWrapper;
  }

  public initializeMatterPrivateLenders(): void {
    const privateLenders: MatterParticipant[] = this.matter.getPrivateLenders(this.mortgage) || [];
    this.matterPrivateLenders = [];
    if (privateLenders.length > 0) {
      this.buildMatterPrivateLenders(privateLenders);
    } else {
      //this.matterPrivateLenders.push(this.createPrivateLenderWrapper());
    }
    this.updateCommonSpecifiedCapacity();
  }

  updateCommonSpecifiedCapacity(): void {
    if (this.matter && !this.isOpportunityMatter()) {
      this.mortgageCapacityOptions = PurchaserCapacity.getMortgageCapacityOptions(this.matter.getPrivateLenders(this.mortgage).length, this.matter.provinceCode);
      if (this.mortgage.mortgageCapacityType === 'COMMON_SPECIFIED') {
        this.matter.privateLenders.forEach((privateLender: MatterParticipant) => {
          privateLender.purchaserShare = PurchaserCapacity.getDynamicValueInterest(this.matter.getPrivateLenders(this.mortgage).length);
        });
      }
    }
  }

  public buildMatterPrivateLenders(privateLenders: MatterParticipant[]): void {
    privateLenders.forEach(privateLender => {
      const matterPrivateLender = this.createPrivateLenderWrapper(privateLender);
      const privateLenderContact = privateLender.contact;
      if (privateLenderContact.sourceContactId) {
        this.contactQueryService.getContactForMatter(privateLenderContact.sourceContactId).subscribe((sourceContact: Contact) => {
          if (privateLender.sourceContactLockAcquired
            && privateLenderContact.isDirty) {
            // If it is in edit mode and has already modified data, it should not be in out of sync state.
            // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic.
            matterPrivateLender.isStale = false;
            matterPrivateLender.isClearFlagWithoutUpdatingMatter = false;
          } else {
            matterPrivateLender.isStale = privateLenderContact.isStaleContact(sourceContact);
            matterPrivateLender.isClearFlagWithoutUpdatingMatter
              = privateLenderContact.isPrivateLenderClearFlagWithoutUpdatingMatter(sourceContact);
          }
          matterPrivateLender.matterParticipant.sourceContact = sourceContact;
          matterPrivateLender.isPrivateLenderMortgagee = privateLenderContact.isOrganization && sourceContact.isMortgagee;
          this.tabsService.updateIsLockedElsewhereStatus(matterPrivateLender, sourceContact);
          matterPrivateLender.canBeUpdatedFromSourceContact = matterPrivateLender.isStale && !!privateLenderContact.sourceContactId;
          matterPrivateLender.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;
        });
      }
      this.matterPrivateLenders.push(matterPrivateLender);
    });
  }

  public createPrivateLenderWrapper(privateLender?: MatterParticipant, isPrivateLenderMortgagee?: boolean): MatterParticipantWrapper {
    const matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    if (privateLender) {
      matterParticipantWrapper.matterParticipant = privateLender;
      matterParticipantWrapper.dataModel = privateLender.contact;
      matterParticipantWrapper.editMode = false;
      matterParticipantWrapper.createdOrSelected = privateLender.contact.sourceContactId ? 'Selected' : 'Created';
    } else {
      matterParticipantWrapper.editMode = true;
      if (!!isPrivateLenderMortgagee) {
        matterParticipantWrapper.isPrivateLenderMortgagee = true;
      }
    }
    matterParticipantWrapper.setSourceContactFlagsAsPrivate();
    matterParticipantWrapper.selectedTab = defaultTab;
    return matterParticipantWrapper;
  }

  public sanitizePrivateLendersSearchResults(results: Contact[]): Contact[] {
    const sanitizedPrivateLenderSearchResults = results ? results.filter((result: Contact) => result.id && !this.isPrivateLenderAlreadySelected(result.id)) : [];
    if (sanitizedPrivateLenderSearchResults.length === 0) {
      const noResultContact: Contact = new Contact();
      noResultContact.displayName = 'No records available';
      sanitizedPrivateLenderSearchResults.push(noResultContact);
    }
    return sanitizedPrivateLenderSearchResults.sort((left, right) => this.comparePrivateLenders(left, right));
  }

  public isPrivateLenderAlreadySelected(id: number): boolean {
    return !!this.matterPrivateLenders.find((w: MatterParticipantWrapper) => w.dataModel && w.dataModel.sourceContactId === id);
  }

  public comparePrivateLenders(left: Contact, right: Contact): number {
    const leftName: string = this.getPrivateLenderDisplayNameFromContact(left).toUpperCase();
    const rightName: string = this.getPrivateLenderDisplayNameFromContact(right).toUpperCase();
    const nameComparison: number = leftName.localeCompare(rightName);
    if (nameComparison === 0) {
      const leftAddress: string = this.getContactDisplayMailingAddress(left).toUpperCase();
      const rightAddress: string = this.getContactDisplayMailingAddress(right).toUpperCase();
      return leftAddress.localeCompare(rightAddress);
    }
    return nameComparison;
  }

  getPrivateLenderDisplayNameFromContact(privateLender: Contact): string {
    let privateLenderDisplayName: string;
    if (!privateLender || !privateLender.id) {
      privateLenderDisplayName = privateLender.displayName;
    } else if (privateLender.isCorporationOrOtherEntity) {
      privateLenderDisplayName = privateLender.organizationName;
    } else {
      privateLenderDisplayName = `${ privateLender.lastName ? privateLender.lastName : '' }, ${ privateLender.firstName ? privateLender.firstName : '' } ${ privateLender.middleName ? privateLender.middleName : '' }`;
    }
    return privateLenderDisplayName;
  }

  getContactDisplayMailingAddress(privateLender: Contact): string {
    if (!privateLender || !privateLender.id || !privateLender.mailingAddress) {
      return '';
    }

    return privateLender.mailingAddress.addressTextWithoutCountryAndPostalCode;
  }

  public getContactName(contact: Contact): string {
    if (contact) {
      if (contact.organizationName === 'No records available') {
        return contact.organizationName;
      }
      return (contact.isCorporation || contact.isGenderDepartment()) ? contact.organizationName : contact.fullNameStartWithFirstName;
    }
    return '';
  }

  populateLawFirmDataStructure(unshutter: boolean, lawFirmParticipant: MatterParticipant, lawFirmContact: Contact, sourceContact: Contact) {
    this.selectedLawFirm.sourceContactLegfirmId = sourceContact.sourceContactId;
    this.selectedLawFirm.matterParticipant.sourceContact = sourceContact;
    this.selectedLawFirm.dataModel = sourceContact;
    this.updateVendorContactInfoWithLawFirm();
    this.selectedLawFirm.dataModel = null;
    if (lawFirmParticipant.sourceContactLockAcquired
      && lawFirmContact.isDirty) {
      // If it is in edit mode and has already modified data, it should not be in out of sync state.
      // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic.
      this.selectedLawFirm.isStale = false;
      this.selectedLawFirm.isClearFlagWithoutUpdatingMatter = false;
    } else {
      this.selectedLawFirm.isStale = lawFirmContact.isStaleLawFirm(sourceContact);
      this.selectedLawFirm.isClearFlagWithoutUpdatingMatter
        = lawFirmContact.isLawFirmClearFlagWithoutUpdatingMatter(sourceContact);
    }
    this.tabsService.updateIsLockedElsewhereStatus(this.selectedLawFirm, sourceContact);
    this.selectedLawFirm.canBeUpdatedFromSourceContact
      = this.selectedLawFirm.isStale && !!lawFirmContact.sourceContactId;
    this.selectedLawFirm.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;
    this.selectedLawFirm.updateSourceContactFlagsForSnapshot(sourceContact);
    this.setExpanded(this.selectedLawFirm, unshutter);
    this.populateComboBox(lawFirmContact.sourceContactId);
    this.setSolicitorAddressDd(sourceContact);
    this.initSolicitorReportAndServiceAddressDd();
  }

  public buildLawFirmStructure(unshutter: boolean, sourceLawFirm?: Contact): void {
    const lawFirmParticipant: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
    this.createLawFirmWrapper(lawFirmParticipant);
    if (!lawFirmParticipant) {
      this.showSolicitorDropDownArrow = false;
      this.setSolicitorAddressDd(null);
      this.setSolicitorAddressDd(null);
      return;
    }

    const lawFirmContact = lawFirmParticipant.contact;
    if (lawFirmContact && lawFirmContact.sourceContactId) {
      if (sourceLawFirm) {
        this.populateLawFirmDataStructure(unshutter, lawFirmParticipant, lawFirmContact, sourceLawFirm);
      } else {
        this.contactQueryService.getContactForMatter(lawFirmContact.sourceContactId).subscribe((sourceContact: Contact) => {
          this.populateLawFirmDataStructure(unshutter, lawFirmParticipant, lawFirmContact, sourceContact);
        });
      }
    }
  }

  /**
   *  Populating the combo box
   */
  populateComboBox(lawFirmId): void {
    if (lawFirmId) {
      this.contactService.getSolicitorsForLawFirm(lawFirmId, true).subscribe(
        (data: any) => {
          // this.solicitorsForLawFirm = data;
          this.showSolicitorDropDownArrow = Array.isArray(data) && data.length > 0 ? true : false;
          // data can NOT set to this.solicitor.
          // It will show two pick lists. One is searching list, the other is drop down list if do it.
        }
      );
    }

  }

  public createLawFirmWrapper(participant?: MatterParticipant) {
    if (!this.selectedLawFirm) {
      this.selectedLawFirm = new MatterParticipantWrapper();
    }
    if (participant) {
      this.selectedLawFirm.matterParticipant = participant;
      this.selectedLawFirm.dataModel = participant.contact;
      this.selectedLawFirm.editMode = false;
    } else {
      this.selectedLawFirm.editMode = true;
    }
  }

  updateVendorContactInfoWithLawFirm() {
    // Populating the fields
    this.mortgage.mortgageContactInfo.mailingAddress = new Address(this.selectedLawFirm.dataModel.mailingAddress);
    this.mortgage.mortgageContactInfo.mailingAddress.id = null;
    if (!this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode) {
      this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = 'SOLICITOR';
      this.mortgage.mortgageContactInfo.serviceAddress.id = null;
    }
    if (!this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode) {
      this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = 'SOLICITOR';
      this.mortgage.mortgageContactInfo.reportAddress.id = null;
    }
    this.mortgage.mortgageContactInfo.phone = this.selectedLawFirm.dataModel.workPhone;
    this.mortgage.mortgageContactInfo.fax = this.selectedLawFirm.dataModel.faxPhone;
    this.mortgage.mortgageContactInfo.email = this.selectedLawFirm.dataModel.email;
    // this.mortgage.mortgageContactInfo.dear = this.selectedLawFirm.dataModel.dear;
    this.mortgage.mortgageContactInfo.firmName = this.selectedLawFirm.dataModel.legalFirmName;
    this.mortgage.mortgageContactInfo.firmId = this.selectedLawFirm.dataModel.id;
  }

  setExpanded(participantWrapper: MatterParticipantWrapper, expandedTarget: boolean): void {
    if (!participantWrapper || participantWrapper.expanded === expandedTarget) {
      return;
    }
    //if there is not sourceContactId, it means it is snapshot. We should change shutter status immediately
    if (participantWrapper.matterParticipant && participantWrapper.matterParticipant.contact && !participantWrapper.matterParticipant.contact.sourceContactId) {
      participantWrapper.expanded = expandedTarget;
    }

    if (this.matter.locked) {
      //If matter locked, just toggle
      participantWrapper.expanded = expandedTarget;
      return;
    }
    if (!participantWrapper.isOwnedBySystemAccount) {
      // "expandedTarget === true" means it will open shutter and lock this contact
      // If this contact is locked by other user, it will return the locking user information
      // "expandedTarget === false" means it will close shutter and unlock this contact
      if (expandedTarget == true
        && participantWrapper.matterParticipant
        && participantWrapper.matterParticipant.sourceContactLockAcquired) { //It is locked by itself
        participantWrapper.isLockedElsewhere = false;
        participantWrapper.lockedSourceContact = null;
        participantWrapper.expanded = expandedTarget;
      } else {// If this contact is locked by other user, it will return the locking user information
        this.tabsService.lockSourceContactForShutter(participantWrapper, expandedTarget).subscribe((result: Contact) => {
          if (result) {
            if (participantWrapper.matterParticipant.sourceContactLockAcquired
              && participantWrapper.matterParticipant.contact.isDirty) {
              // If it is in edit mode and has already modified data, it should not be in out of sync state.
              // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic
              participantWrapper.isStale = false;
              participantWrapper.isClearFlagWithoutUpdatingMatter = false;
            } else {
              // wrapper.isStale = wrapper.matterParticipant.contact.isStaleSolicitor(result);
              // wrapper.isClearFlagWithoutUpdatingMatter
              //     = wrapper.matterParticipant.contact.isSolicitorClearFlagWithoutUpdatingMatter(result);
              participantWrapper.isStale = participantWrapper.matterParticipant.contact.isStaleContact(result);
              participantWrapper.isClearFlagWithoutUpdatingMatter
                = participantWrapper.refreshClearFlagStatus(result);
            }
            this.tabsService.updateIsLockedElsewhereStatus(participantWrapper, result);

            participantWrapper.canBeUpdatedFromSourceContact
              = participantWrapper.isStale && !!participantWrapper.matterParticipant.contact.sourceContactId;
            participantWrapper.lastUpdatedOn = result.lastUpdatedOnMsg;
            participantWrapper.updateSourceContactFlagsForSnapshot(result);
            participantWrapper.expanded = expandedTarget;
          }
        });
      }
    } else {
      participantWrapper.expanded = expandedTarget;
    }

  }

  public buildSolicitorStructure(unshutter: boolean, sourceContact?: Contact): void {
    const solicitorParticipant: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    this.createSolicitorWrapper(solicitorParticipant);
    if (!solicitorParticipant) {
      return;
    }

    const solicitorContact: Contact = solicitorParticipant.contact;
    if (solicitorContact) {
      if (sourceContact) {
        this.populateSolicitorStructure(unshutter, solicitorParticipant, sourceContact);
      } else {
        this.contactQueryService.getContactForMatter(solicitorContact.sourceContactId).subscribe((sourceSolicitorContact: Contact) => {
          this.populateSolicitorStructure(unshutter, solicitorParticipant, sourceSolicitorContact);
          //serviceAddress OR reportAddress may choose to use sameAsSolicitorAddress
          //so need to refresh the solicitor address in serviceAddress and reportAddress's dropdown
          this.initSolicitorReportAndServiceAddressDd();
        });
      }
    }

  }

  public populateSolicitorStructure(unshutter: boolean, solicitorParticipant: MatterParticipant, sourceContact: Contact) {
    if (solicitorParticipant && solicitorParticipant.contact && sourceContact) {
      let solicitorContact: Contact = solicitorParticipant.contact;
      this.selectedSolicitor.dataModel = sourceContact;
      this.updateVendorContactInfoWithSolicitor();
      this.selectedSolicitor.dataModel = null;
      if (solicitorParticipant.sourceContactLockAcquired
        && solicitorParticipant.contact.isDirty) {
        // If it is in edit mode and has already modified data, it should not be in out of sync state.
        // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic.
        this.selectedSolicitor.isStale = false;
        this.selectedSolicitor.isClearFlagWithoutUpdatingMatter = false;
      } else {
        this.selectedSolicitor.isStale = solicitorContact.isStaleSolicitor(sourceContact);
        this.selectedSolicitor.isClearFlagWithoutUpdatingMatter
          = solicitorContact.isSolicitorClearFlagWithoutUpdatingMatter(sourceContact);
      }
      this.tabsService.updateIsLockedElsewhereStatus(this.selectedSolicitor, sourceContact);
      this.selectedSolicitor.canBeUpdatedFromSourceContact
        = this.selectedSolicitor.isStale && !!solicitorContact.sourceContactId;
      this.selectedSolicitor.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;
      this.selectedSolicitor.updateSourceContactFlagsForSnapshot(sourceContact);
      this.setExpanded(this.selectedSolicitor, unshutter);
    }
  }

  public createSolicitorWrapper(participant?: MatterParticipant) {
    if (!this.selectedSolicitor) {
      this.selectedSolicitor = new MatterParticipantWrapper();
    }
    if (participant) {
      this.selectedSolicitor.matterParticipant = participant;
      this.selectedSolicitor.dataModel = participant.contact;
      this.selectedSolicitor.editMode = false;
    } else {
      this.selectedSolicitor.editMode = true;
    }
  }

  updateVendorContactInfoWithSolicitor() {
    // Populating the fields
    if (this.selectedSolicitor.dataModel.legalFirmId > 0) {
      this.mortgage.mortgageContactInfo.firmId = this.selectedSolicitor.dataModel.legalFirmId;
      // this.populateComboBox(this.selectedSolicitor.dataModel.legalFirmId);
    }

    if (this.selectedSolicitor.dataModel.firmName) {
      // this.selectedLawFirm = this.selectedSolicitor.dataModel.firmName;
      this.mortgage.mortgageContactInfo.firmName = this.selectedSolicitor.dataModel.firmName;
    }

    this.mortgage.mortgageContactInfo.mailingAddress = new Address(this.selectedSolicitor.dataModel.mailingAddress);
    this.mortgage.mortgageContactInfo.mailingAddress.id = null;
    // According to requirement there arr no serviceAddress and reportAddress if there is solicitor
    // The Addresses for Service and Report will be in the Mortgagee section
    // if(this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode !== AddressTypes.manuallyEntered) {
    //     this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = "SOLICITOR";
    // }
    // this.mortgage.mortgageContactInfo.serviceAddress.id = null;
    //
    // if(this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode !== AddressTypes.manuallyEntered) {
    //     this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = "SOLICITOR";
    // }
    // this.mortgage.mortgageContactInfo.reportAddress.id = null;

    this.mortgage.mortgageContactInfo.phone = this.selectedSolicitor.dataModel.workPhone;
    this.mortgage.mortgageContactInfo.fax = this.selectedSolicitor.dataModel.faxPhone;
    this.mortgage.mortgageContactInfo.email = this.selectedSolicitor.dataModel.email;
    // this.mortgage.mortgageContactInfo.dear = this.selectedSolicitor.dataModel.dear;
  }

  isMoreThanOnePrivateLender(): boolean {
    return Array.isArray(this.matterPrivateLenders) && this.matterPrivateLenders.length > 1;
  }

  hasPrivateLenders(): boolean {
    return Array.isArray(this.matterPrivateLenders) && this.matterPrivateLenders.length > 0;
  }

  addAnotherMortgageeLabel(): string {
    return (this.hasPrivateLenders() ? 'Add another ' : 'Add from ') + provinceBasedFieldLabels.get('provinceBasedMortgagee', this.matter.provinceCode);
  }

  clearMortgageeFieldIfNotSelected(idx: number) {
    let activeMortgagee: MatterParticipantWrapper = this.selectedMortgagees[ idx ];
    if (activeMortgagee.dataModel && !activeMortgagee.matterParticipant && !activeMortgagee.createdOrSelected) {
      activeMortgagee.dataModel = null;
    }
  }

  // select the contact form list of contacts
  selectExistingMortgagee(idx: number): void {
    try {
      this.lockScreenService.lockForUpdate = true;
      this.enableSave();
      this.markUndertakingDirty();
      //The selected client (from the drop-down list) would be set here through ng-model attr on the autocomplete control
      let activeMortgagee: MatterParticipantWrapper = this.selectedMortgagees[ idx ];
      activeMortgagee.createdOrSelected = 'Selected';
      if (activeMortgagee.dataModel.id === undefined) {
        if (activeMortgagee.dataModel && activeMortgagee.dataModel.organizationName && activeMortgagee.dataModel.organizationName.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
          activeMortgagee.dataModel = '';
        } else {
          this.addNewMortgageeContact(activeMortgagee);
        }
      } else {
        this.lockScreenService.lockForUpdate = true;
        this.contactQueryService.getContactForMatter(activeMortgagee.dataModel.id)
        .finally(() => this.lockScreenService.lockForUpdate = false)
        .subscribe((contact: Contact) => {
          this.silentAddMortgagee(contact, activeMortgagee);
        });
      }
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  silentAddMortgagee(contact: Contact, activeMortgagee: MatterParticipantWrapper): void {
    this.setContactInActiveMortgageeWrapper(activeMortgagee, contact);
    this.propagateAddMortgageeChanges(contact, this.mortgage);
  }

  addNewMortgageeContact(activeMortgagee: MatterParticipantWrapper) {

    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: {
        matter: this.matter,
        contactType: 'MORTGAGEE'
      },
      onFulfillment: (result) => {
        if (result.contact) {
          this.setContactInActiveMortgageeWrapper(activeMortgagee, result.contact);
          //Build the attention list
          this.getAttentionPickList(result.contact);
          this.propagateAddMortgageeChanges(result.contact, this.mortgage);
        }
      },
      onRejection: (reject: any) => {

      },
      fullScreen: true,

    });
  }

  setContactInActiveMortgageeWrapper(activeMortgagee: MatterParticipantWrapper, sourceContact: Contact) {
    this.attentionList = [];
    this.isAddressDisabled = true;
    activeMortgagee.editMode = false;
    activeMortgagee.matterParticipant = this.matter.addNewMortgageeParticipant(sourceContact, this.mortgage.id);
    activeMortgagee.dataModel = activeMortgagee.matterParticipant.contact;
    this.populateMortgageeFieldsFromMortgagee(sourceContact);
    activeMortgagee.selectedDetailsTabIndex = 0;
    activeMortgagee.createdOrSelected = 'Selected';
    if (!this.isOpenedFromWizard) {
      this.toggleMortgageeShutter(activeMortgagee);
    }
    this.getAttentionPickList(sourceContact);
  }

  populateMortgageeFieldsFromMortgagee(mortgagee: Contact): void {
    this.mortgage.includeAuthorizeSignOfficer = 'N_y';
  }

  deleteMortgagee(selectedMortgagee): void {
    let mortgageeTitle = provinceBasedFieldLabels.get('provinceBasedMortgagee', this.matter.provinceCode);
    let deleteConfirmationMessage = `Proceed to remove this ${ mortgageeTitle } from this matter?`;
    let myClient = selectedMortgagee.matterParticipant.myClient;
    if (myClient) {
      deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, selectedMortgagee.matterParticipant, selectedMortgagee.matterParticipant.matterParticipantRole);
    }

    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(res => {
      if (res) {
        if (myClient) {
          this.handleContactChange(selectedMortgagee, true, true);
        }
        this.silentDeleteMortgagee(selectedMortgagee);
      }
    });
  }

  silentDeleteMortgagee(selectedMortgagee: MatterParticipantWrapper): void {
    this.documentProductionService.tryToRevokePackage(this.matter, selectedMortgagee.matterParticipant, this.contactQueryService);
    if (selectedMortgagee.matterParticipant) {
      let myClient = selectedMortgagee.matterParticipant.myClient;
      this.matter.getChildMatterParticipants(selectedMortgagee.matterParticipant).forEach(matterParticipant => {
        (<any>this.matter.matterParticipants).remove(matterParticipant);
      });
      this.matterParticipantService.removeParticipant(selectedMortgagee, this.matter);
      if (this.attentionInfoComponent) {
        this.attentionInfoComponent.removeAttention();
      }
      this.updateMortgageeMyClientFlag(myClient);
    }
    if (selectedMortgagee.createdOrSelected === 'Selected') {
      this.resetMortgageFields();
    }

    selectedMortgagee.matterParticipant = null;
    selectedMortgagee.createdOrSelected = null;
    selectedMortgagee.dataModel = null;
    selectedMortgagee.editMode = true;
    selectedMortgagee.isStale = false;
    selectedMortgagee.expanded = false;
    selectedMortgagee.isStale = false;
    selectedMortgagee.isLockedElsewhere = false;
    selectedMortgagee.isClearFlagWithoutUpdatingMatter = false;

    this.isAddressDisabled = false;

    this.attentionList = [];
    this.propagateRemoveMortgageeChanges(this.mortgage);
    this.enableSave();
  }

  //In all the cases transit no needs to be reset except if correspondence is changed
  resetMortgageFields(resetTransitNo: boolean = true): void {
    this.mortgage.mortgageContactInfo.mailingAddress = new Address();
    this.mortgage.mortgageContactInfo.mailingAddress.addressTypeCode = AddressTypes.mailing;
    this.mortgage.mortgageContactInfo.serviceAddress = new Address();
    this.mortgage.mortgageContactInfo.serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
    this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;
    this.mortgage.mortgageContactInfo.reportAddress = new Address();
    this.mortgage.mortgageContactInfo.reportAddress.addressTypeCode = AddressTypes.reportAddress;
    this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = AddressTypes.mailing;
    this.mortgage.mortgageContactInfo.name = null;
    this.mortgage.mortgageContactInfo.fax = null;
    this.mortgage.mortgageContactInfo.email = null;
    this.mortgage.mortgageContactInfo.phone = null;
    this.mortgage.mortgageContactInfo.dear = null;
    if (resetTransitNo) {
      this.mortgage.branchOrTransitNo = null;
    }

    this.mortgage.includeAuthorizeSignOfficer = 'N_y';
    this.mortgage.mortgageContactInfo.additionalName1 = null;
    this.mortgage.mortgageContactInfo.titleOfOfficeHeld1 = null;
    this.mortgage.mortgageContactInfo.additionalName2 = null;
    this.mortgage.mortgageContactInfo.titleOfOfficeHeld2 = null;
  }

  getAttentionPickList(sourceContact?: Contact): void {
    //one mortgagees should have one attention. Now multiple mortgagees share one attention.
    //After confirmed with BA, it should only have one mortgagee. It should be refactor in the future.
    if (this.institutionMortgagee != null) {
      if (!sourceContact) {
        this.getSourceContactAndPopulateAttentionPickList(this.institutionMortgagee);
      } else {
        this.populateAttentionPickList(sourceContact);
      }
    }
  }

  getSourceContactAndPopulateAttentionPickList(mortgagee: MatterParticipantWrapper): void {
    this.contactQueryService
    .getContactForMatter(mortgagee.dataModel.sourceContactId)
    .subscribe((sourceContact: Contact) => {
      this.populateAttentionPickList(sourceContact);
    });
  }

  populateAttentionPickList(sourceContact: Contact): void {
    // if(this.mortgage.mortgageContactInfo.name) {
    //    this.addOptionForNewAttention(this.mortgage.mortgageContactInfo.name) ;
    // }
    this.attentionList = [];
    if (sourceContact.privateContacts && sourceContact.privateContacts.persons) {
      this.attentionList.push(...sourceContact.privateContacts.persons);
    }
    this.addDefaultAttentionContacts();
  }

  addDefaultAttentionContacts(): void {
    const mortgageDeptContact: Contact = new Contact();
    const mortgageDischargeContact: Contact = new Contact();
    const addNewRecordContact: Contact = new Contact();

    mortgageDeptContact.firstName = Constants.MORTGAGE_DEPT;
    this.attentionList.push(mortgageDeptContact);
    mortgageDischargeContact.firstName = Constants.MORTGAGE_DISCHARGE;
    this.attentionList.push(mortgageDischargeContact);
    addNewRecordContact.firstName = Constants.ADD_NEW_RECORD_ATTENTION;
    this.attentionList.unshift(addNewRecordContact);
  }

  // autocomplete
  // PURCHASER
  // Omnibar methods to get contacts
  search(event): void {
    let entered: string = event.query;
    this.searchTermStreamClient.next(entered);
  }

  get institutionMortgageeMatterParticipant(): MatterParticipant {
    return this.institutionMortgagee
      && this.institutionMortgagee.matterParticipant;
  }

  async toggleMortgageeShutter(mortgagee: MatterParticipantWrapper): Promise<void> {
    if (!mortgagee.matterParticipant) {
      return;
    }
    if (this.isCorrespondWithSolicitor() || !mortgagee.sourceContactId) {
      mortgagee.expanded = !mortgagee.expanded;
    } else {
      await this.matterParticipantService.updateParticipantStateOnShutterClick(this.matter, mortgagee);
    }

    if (mortgagee.expanded && mortgagee.matterParticipant.sourceContact && mortgagee.matterParticipant.sourceContact.isPrivateExcludeProxy &&
      mortgagee.matterParticipant.sourceContact.isMortgageeLinkedToComputerShareAll() && !mortgagee.isStale && !mortgagee.isLockedElsewhere) {
      //if mortgagee is linked to "computer share All" then giving option to relink it with other lenders
      this.openRelinkLenderInstitutionModal(mortgagee);
    }
  }

  async openRelinkLenderInstitutionModal(selectedMortgagee: MatterParticipantWrapper): Promise<void> {
    //if lenders list not already cached in this component
    if (!this.lenders || this.lenders.length == 0) {
      this.lenders = await this.contactService.getLendingInstitutions(true).toPromise();
    }

    this.contactService.findMatchingLenderInstitutionsByOrganizationName(COMPUTER_SHARE_LENDER_NAME, this.lenders)
    .subscribe((lenderInstitutions: LendingInstitution[]) => {
      let matchedLenders: LendingInstitution[] = lenderInstitutions.filter(value => value.alternateName != COMPUTER_SHARE_ALTERNATE_NAME);
      if (matchedLenders.length > 0) {
        this.dialogService.matDialogContent({
          content: LendingInstitutionsModalComponent,
          context: {lenders: matchedLenders, isReLinkingMode: true},
          onFulfillment: (result) => {
            if (result && result.action === 'OK' && result.selectedLender) {
              this.contactService.linkMortgageeWithLender(selectedMortgagee.matterParticipant.contact, result.selectedLender);
              this.changedMortgagee(selectedMortgagee);
            }
          }
        });
      }

    });
  }

  onSnapshotSelectChange(mortgagee: MatterParticipantWrapper, event: any): void {
    console.log('onSnapshotSelectChange:', event);
    mortgagee.selectedDetailsTabIndex = event.index;
  }

  searchSolicitor(event): void {
    if (!this.showSolicitorDropDownArrow) {
      let entered: string = event.query;
      this.searchTermSolicitor.next(entered);
    } else {
      this.onSolicitorChange(event);
    }
  }

  searchLawFirm(event): void {
    let entered: string = event.query;
    this.searchTermLawFirm.next(entered);
  }

  /**
   * populate solicitor drop down list
   * @param lawFirmId
   */
  populateSolicitor(lawFirmId: number, removeAddNewRecord?: boolean): void {
    if (lawFirmId) {
      this.contactService.getSolicitorsForLawFirm(lawFirmId, true).subscribe(
        (data: any) => {
          this.solicitors = data;
          //It will add a new record when there is dynamic drop down list
          // But If it is my own account, Don't add new solicitor
          if (!removeAddNewRecord
            && typeof this.selectedSolicitor.dataModel === 'string' && this.selectedSolicitor.dataModel.trim()) {
            const addNewRecordData: any = {
              displayName: Constants.ADD_NEW_RECORD + `"${ this.selectedSolicitor.dataModel.trim() }"`
            };
            this.solicitors.unshift(addNewRecordData);

          }
          // Solicitor implements the following two search functions
          // 1. Search solicitor for any law firms (the law firm wasn't selected).
          //    It uses the "completeMethod" of p-autoComplete to open dialog. This works well
          // 2. Search solicitor under the drop down list of the selected law firm (the law firm was selected)
          //    By changing "suggestions"(the dropdown list) of p-autoComplete to open dialog. In this case,
          //    however, there is an issue. When the dropdown list (this.solicitors) is changed from undefined to non-null,
          //    the dialog could not be opened.
          //    Fix: Call the "show" of p-autoComplete to open the dialog
          this.solicitorAutoComplete.show();
          // In IE, clicking item from the dropdown list would generate empty selection while the existing
          // value of autocomplete was empty. This issue happened when Placeholder and Dropdown Arrow are both
          // enabled in autocomplete in IE.
          // The root cause is till unknown, and here is the hack which change the existing value to a space when
          // it is empty.
          if (this.selectedSolicitor.dataModel === '') {
            this.selectedSolicitor.dataModel = ' ';
          }
        }
      );
    }

  }

  lawFirmChange(event) {
    console.log('lawFirmChange:', event.target.value);
    this.enableSave();
    this.mortgage.mortgageContactInfo.firmName = event.target.value;
    this.mortgage.mortgageContactInfo.firmId = null;
    this.lawFirmSelected = true;
  }

  recordLawFirmKeydownCode(event): void {
    this.lawFirmKeyDownCode = event.keyCode;
  }

  dataSelectedLawFirmTimeout(): void {
    setTimeout(() => {
      this.dataSelectedLawFirm();
    }, 0);
  }

  onSelectedLawFirmChange(event) {
    if (typeof event === 'string') {
      this.selectedLawFirmInputValue = event;
    }
  }

  // This is a workaround because of p-autoComplete's special behaviour which was found in DPPMP-9185.
  // While,
  //   1) an item was selected from dropdown, and the input box was displaying the name of the data item
  //   2) made some changes in the input box and left a non-empty string
  //   3) pressed 'Tab' key
  //   4) Displayed the LAST selected record
  // p-autoComplete's reaction:
  //   1) triggered 'ngModelChange' event with current selected data item
  //   2) triggered 'onSelect' event
  //   3) forwarded 'keydown' event of 'Tab' key to the caller, this component
  // The issue caused:
  //   Since 'ngModelChange' was triggered with current select data item and 'onSelect' was triggered as well,
  //   the effect is equivalent to mouse clicked the data item from the dropdown, and user's input change is lost.
  //   In this case, this component has no knowledge that these 'ngModelChange' and 'onSelect' events was triggered
  //   by mouse clicking or 'Tab' key pressing.
  // Workaround:
  //   1) record any user key input to a temp variable
  //   2) delay 'onSelect' handling by put it into a timer, so the handling will be after 'keydown' event. If the latest
  //      'keydown' was 'Tab', restore user's input from the temp variable.
  // Note:
  //   This issue is caused by p-autoComplete's behaviour, and every code using it is affected.
  async dataSelectedLawFirm(): Promise<void> {
    if (this.lawFirmKeyDownCode === 9) {
      this.selectedLawFirm.dataModel = this.selectedLawFirmInputValue;
      this.mortgage.mortgageContactInfo.firmName = this.selectedLawFirm.dataModel;
      this.mortgage.mortgageContactInfo.firmId = null;
    } else {
      if (this.selectedLawFirm.dataModel.id === undefined) {
        // Now take care for adding ne law firm
        if (this.selectedLawFirm.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD_MORTGAGEE) === 0) {
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: {
              matter: this.matter,
              isLawFirm: true,
              contactType: 'LAW_FIRM',
              contactName: this.selectedLawFirm.dataModel.displayName,
              matterParticipantRole: 'MORTGAGE_LEGAL_FIRM'

            },
            onFulfillment: (result) => {
              if (result) {
                if (result.action === 'save') {
                  this.enableSave();
                  let snapshotContact: Contact;
                  //According to backend requirement, UI set law firm snapshot subContact as []
                  if (result.contact) {
                    snapshotContact = new Contact(result.contact);
                    snapshotContact.subContacts = [];
                  }
                  this.matter.addMatterParticipant(snapshotContact,
                    true,
                    'MORTGAGE_LEGAL_FIRM',
                    this.mortgage.id);
                  this.buildLawFirmStructure(false, result.contact);

                  this.selectedLawFirm.showLabel = true;
                  this.selectedLawFirm.createdOrSelected = 'Created';
                }
                this.selectedLawFirm.dataModel = null;
              }
            },
            fullScreen: false,

          });

        } else {
          this.selectedLawFirm.dataModel = null;
          this.mortgage.mortgageContactInfo.firmName = null;
          this.mortgage.mortgageContactInfo.firmId = null;
        }
      } else {
        // Set Solicitor's Address is readOnly
        this.isAddressDisabled = true;

        // Remove the law firm
        let lawFirmMP: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
        if (lawFirmMP) {
          this.matter.removeMatterParticipant(lawFirmMP);
        }
        this.silentAddLegalFirm();
      }
    }
    this.enableSave();
  }

  async silentAddLegalFirm(): Promise<void> {
    let source = await this.contactQueryService.getContactForMatter(this.selectedLawFirm.dataModel.id).toPromise();
    if (source) {
      //According to backend requirement, UI set law firm snapshot subContact as []
      const snapshotContact: Contact = new Contact(source);
      snapshotContact.subContacts = [];
      this.matter.addMatterParticipant(snapshotContact, true, 'MORTGAGE_LEGAL_FIRM', this.mortgage.id);
      this.buildLawFirmStructure(true, source);
    }
  }

  createSolicitorWithExistingLawFirm(matterParticipant: MatterParticipant, displayName: string) {
    if (matterParticipant) {
      if (matterParticipant.contact && matterParticipant.contact.sourceContactId) {
        // Get this existing law firm source contact
        this.contactQueryService.getContactForMatter(matterParticipant.contact.sourceContactId).subscribe((sourceContact: Contact) => {
          if (!Array.isArray(sourceContact.subContacts)) {
            sourceContact.subContacts = [];
          }
          let solicitorContact: Contact = sourceContact.createSolicitorContact(this.documentProfileCache, this.matter && this.matter.provinceCode);
          solicitorContact.handleFullName(displayName);
          solicitorContact.legalFirmId = matterParticipant.contact.sourceContactId;
          solicitorContact.organizationId = matterParticipant.contact.sourceContactId;
          sourceContact.subContacts.push(solicitorContact);
          const context = {
            matter: this.matter,
            contact: sourceContact,
            isOnlySolicitor: true,
            isLawFirm: false,
            // contactKey        : 'LAWYER',
            contactType: 'LAW_FIRM',
            matterParticipantRole: 'MORTGAGE_LEGAL_FIRM',
            contactAddressType: this.contactAddressType,
            solicitorAddressDd: this.solicitorAddressDdOptions,
            fieldPrefix: 'matter.mortgage'
          };
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: context,
            onFulfillment: (result) => {
              if (result) {
                if (result.action === 'save') {
                  this.enableSave();
                  if (result.contact && Array.isArray(result.contact.subContacts) && result.contact.subContacts.length > 0) {
                    this.createSolicitorStructure(result.contact.subContacts[ result.contact.subContacts.length - 1 ].id);
                    this.selectedSolicitor.showLabel = true;
                    this.selectedSolicitor.createdOrSelected = 'Created';
                  }
                }
                this.selectedSolicitor.dataModel = null;
              }
            },
            fullScreen: false,

          });

        });
      }
    }
  }

  createSolicitorWithoutExistingLawFirm() {
    const context = {
      matter: this.matter,
      isOnlySolicitor: false,
      isLawFirm: false,
      contactName: this.selectedSolicitor.dataModel.displayName,
      contactType: 'LAW_FIRM',
      matterParticipantRole: 'MORTGAGE_LEGAL_FIRM',
      contactAddressType: this.contactAddressType,
      solicitorAddressDd: [],
      fieldPrefix: 'matter.mortgage'

    };
    this.dialogService.matDialogContent({
      content: CommonContactDialogComponent,
      context: context,
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'save') {
            this.enableSave();
            // Add law firm info
            let snapshotContact: Contact;
            //According to backend requirement, UI set law firm snapshot subContact as []
            if (result.contact) {
              snapshotContact = new Contact(result.contact);
              snapshotContact.subContacts = [];
            }
            const lawfirmParticipant: MatterParticipant =
              this.matter.addMatterParticipant(snapshotContact,
                true, 'MORTGAGE_LEGAL_FIRM', this.mortgage.id);
            this.buildLawFirmStructure(false, result.contact);
            this.selectedLawFirm.createdOrSelected = 'Created';
            this.selectedLawFirm.dataModel = lawfirmParticipant ? lawfirmParticipant.contact : null;
            // Add solicitor info
            if (result.contact && Array.isArray(result.contact.subContacts) && result.contact.subContacts.length > 0) {
              this.createSolicitorStructure(result.contact.subContacts[ result.contact.subContacts.length - 1 ].id);
              this.selectedSolicitor.showLabel = true;
              this.selectedSolicitor.createdOrSelected = 'Created';
            }

          }
          this.selectedSolicitor.dataModel = null;
        }
      },
      fullScreen: true,

    });

  }

  async dataSelectedSolicitor(): Promise<void> {
    // If there is an existing solicitor in MatterParticipant array of matter, it needs to remove it.
    let solicitorMP: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    if (solicitorMP) {
      this.matter.removeMatterParticipant(solicitorMP);
      if (solicitorMP.contact) {
        this.contactService.unlockContact(solicitorMP.contact.sourceContactId).subscribe((res) => {
          this.logger.info('solicitor has been unlocked now');
        });
      }
    }

    if (this.selectedSolicitor.dataModel.id === undefined) {
      // Now take care for adding a new solicitor
      if (this.selectedSolicitor.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD_MORTGAGEE) === 0) {
        // Remove old solicitor
        let LawFirmrMP: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
        if (LawFirmrMP) {
          this.createSolicitorWithExistingLawFirm(LawFirmrMP, this.selectedSolicitor.dataModel.displayName);
        } else {
          this.createSolicitorWithoutExistingLawFirm();
        }
      } else if (this.selectedSolicitor.dataModel.displayName.indexOf('No records available') === 0) {
        this.selectedSolicitor.dataModel = null;
      } else {
        await this.selectExistingSolicitor();
      }
    } else {
      await this.selectExistingSolicitor();
    }
    this.enableSave();
  }

  async selectExistingSolicitor(): Promise<void> {
    const solicitorParticipant: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);

    //Clean this.selectedSolicitor with new data Model
    //Remove old solicitorParticipant
    if (solicitorParticipant) {
      if (solicitorParticipant.contact) {
        this.contactService.unlockContact(solicitorParticipant.contact.sourceContactId).subscribe((res) => {
          this.logger.info('solicitor has been unlocked now');
        });
      }
      this.matter.removeMatterParticipant(solicitorParticipant);
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.solicitorContact.surname');
    }
    let sourceSolicitor: Contact = await this.contactQueryService.getContactForMatter(this.selectedSolicitor.dataModel.id).toPromise();
    if (sourceSolicitor) {
      const solicitorParticipant = this.matter.addMatterParticipant(sourceSolicitor,
        true, 'MORTGAGE_SOLICITOR', this.mortgage.id);
      this.buildSolicitorStructure(true);
      // Change old user story ComboBox rule
      // Only when law firm is empty, it will get this solicitor's law firm
      // So it can improve performance
      if (!this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage)) {
        // Populating the combo box
        let sourceLawFirm = await this.contactQueryService.getContactForMatter(sourceSolicitor.legalFirmId).toPromise();
        if (sourceLawFirm) {
          //According to backend requirement, UI set law firm snapshot subContact as []
          const snapshotContact: Contact = new Contact(sourceLawFirm);
          snapshotContact.subContacts = [];
          // Add the Law Firm MatterParticipant
          this.matter.addMatterParticipant(snapshotContact, true, 'MORTGAGE_LEGAL_FIRM', this.mortgage.id);
          //Build selectedLawFirm wrapper
          this.buildLawFirmStructure(false, sourceLawFirm);
        }
      }
    }
  }

  clearLawFirm(): void {
    let message: string
      = 'Proceed to remove this Firm and Contact information from this matter?';
    this.dialogService.confirm('Confirmation', message, false, 'Delete').subscribe(res => {
      if (res) {
        this.silentClearLawFirm();
      }
    });
  }

  silentClearLawFirm(): void {
    this.logger.info(' remove this Law Firm | this.mortgage.mortgageContactInfo.mailingAddress = ', this.mortgage.mortgageContactInfo.mailingAddress);
    //Clean ContactInfo. TODO clean up all ContactInfo
    this.mortgage.mortgageContactInfo.mailingAddress = new Address();
    this.mortgage.mortgageContactInfo.mailingAddress.addressTypeCode
      = AddressTypes.mailing;
    this.mortgage.mortgageContactInfo.serviceAddress = new Address();
    this.mortgage.mortgageContactInfo.serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
    this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode
      = AddressTypes.solicitorAddress;
    this.mortgage.mortgageContactInfo.reportAddress = new Address();
    this.mortgage.mortgageContactInfo.reportAddress.addressTypeCode = AddressTypes.reportAddress;
    this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode
      = AddressTypes.solicitorAddress;
    this.mortgage.mortgageContactInfo.phone = null;
    this.mortgage.mortgageContactInfo.fax = null;
    this.mortgage.mortgageContactInfo.email = null;
    this.mortgage.mortgageContactInfo.dear = null;

    this.showAttentionsDropDownArrow = false;
    this.firmSelected = false;
    this.mortgage.mortgageContactInfo.firmId = null;
    this.mortgage.mortgageContactInfo.firmName = null;

    this.showSolicitorDropDownArrow = false;

    const lawFirmMP: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
    if (lawFirmMP) {
      if (lawFirmMP.contact) {
        this.contactService.unlockContact(lawFirmMP.contact.sourceContactId).subscribe((res) => {
          this.logger.info('mortgage lawFirm has been unlocked now');
        });
      }
      this.matter.removeMatterParticipant(lawFirmMP);
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.contact.firmSolicitor.lawFirm.firmName.MISSING');
    }
    this.selectedLawFirm = new MatterParticipantWrapper();
    this.selectedLawFirm.editMode = true;
    this.mortgage.mortgageContactInfo.firmId = null;
    this.mortgage.mortgageContactInfo.firmName = null;

    const solicitorMP: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    if (solicitorMP) {
      this.revokeSharedDocPackageForSolicitor(solicitorMP);
      if (solicitorMP.contact) {
        this.contactService.unlockContact(solicitorMP.contact.sourceContactId).subscribe((res) => {
          this.logger.info('solicitor has been unlocked now');
        });
      }
      this.matter.removeMatterParticipant(solicitorMP);
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.solicitorContact.surname');
    }
    this.selectedSolicitor = new MatterParticipantWrapper();
    this.selectedSolicitor.editMode = true;
    this.selectedSolicitorsForLawFirm = null;
    this.lawFirmSelected = false; // making the fields editable and removing trash icon
    this.notifyChange();
    this.initSolicitorReportAndServiceAddressDd();
  }

  lawFirmComboChanged(event): void { // This is a "wokraround" for <p-dropdown> v.2.0.5 which functions quite different than documented
    let id: number = event.value;
    let selected: Contact = _.find(this.solicitorsForLawFirm, {'id': id});
    if (selected) {
      let address = selected.mailingAddress ? selected.mailingAddress.addressTextWithProvinceName : '';
      setTimeout(() => {
        this.selectedSolicitorsForLawFirm = `${ selected.firstLastNames } ${ address }`;
      }, 0);

      this.selectedSolicitor.dataModel = selected;
      this.dataSelectedSolicitor();
    }
  }

  handleDropDownClickSolicitorPickList(): void {
    const lawFirm = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
    if (lawFirm && lawFirm.contact) {
      if (this.isMyOwnAccountLawFirm()) {
        //If it is my own account, removing to add new solicitor
        this.populateSolicitor(lawFirm.contact.sourceContactId, true);
      } else {
        this.populateSolicitor(lawFirm.contact.sourceContactId);
      }

    }
  }

// Saving Law Firm (both Selected and free type)

  setFreeTypeLawFirmMatterParticipant() {
    if (!this.selectedLawFirm || typeof this.selectedLawFirm != 'string') {
      return;
    }

    let contact: Contact = new Contact;
    contact.instanceType = 'solicitor';
    contact.contactType = 'SOLICITOR';
    contact.gender = 'QUESTION';
    contact.activeFlag = DpBooleanValueTypes.Y_n;

    contact.firmName = contact.lastName = this.selectedLawFirm;
    this.mortgage.mortgageContactInfo.firmName = this.selectedLawFirm;
    this.matter.setMatterParticipant(contact, false, 'MORTGAGE_LEGAL_FIRM', this.mortgage.id);
    this.lawFirmSelected = !!this.selectedLawFirm;
    this.enableSave();
  }

  setSelectedSolicitorMatterParticipant() {
    this.matter.setMatterParticipant(this.selectedSolicitor.dataModel, true, 'MORTGAGE_SOLICITOR', this.mortgage.id);
    this.enableSave();
  }

  getPrivateLenderSearchDisplayName(privateLender: Contact): string {
    // if (!privateLender || !privateLender.id) {
    //     return 'No records available';
    // }

    return this.getPrivateLenderDisplayNameFromContact(privateLender);
  }

  getPrivateLenderDisplayNameFromWrapper(privateLenderWrapper: MatterParticipantWrapper): string {
    let privateLenderDisplayName: string;
    if (!privateLenderWrapper || !privateLenderWrapper.dataModel) {
      privateLenderDisplayName = 'Unknown';
    } else if (privateLenderWrapper.dataModel instanceof Contact) {
      privateLenderDisplayName = this.getPrivateLenderDisplayNameFromContact(privateLenderWrapper.dataModel);
    } else {
      privateLenderDisplayName = privateLenderWrapper.dataModel;
    }
    return privateLenderDisplayName;
  }

  getPrivateLenderName(privateLenderWrapper: MatterParticipantWrapper): string {
    let privateLenderName: string;
    if (privateLenderWrapper && privateLenderWrapper.dataModel && privateLenderWrapper.dataModel instanceof Contact) {
      const privateLenderContact: Contact = privateLenderWrapper.dataModel;
      if (privateLenderContact.isCorporationOrOtherEntity) {
        privateLenderName = privateLenderContact.organizationName;
      } else {
        privateLenderName = `${ privateLenderContact.lastName ? privateLenderContact.lastName : '' }, ${ privateLenderContact.firstName ? privateLenderContact.firstName : '' } ${ privateLenderContact.middleName ? privateLenderContact.middleName : '' }`;
      }
    } else {
      privateLenderName = null;
    }
    return privateLenderName;
  }

  searchPrivateLenders(event): void {
    if (event.query) {
      this.privateLenderSearchSubject.next(event.query);
    }
  }

  async selectPrivateLender(privateLenderWrapper: MatterParticipantWrapper): Promise<void> {
    console.log('start selectPrivateLender');
    if (privateLenderWrapper.dataModel.id === undefined) {
      // Now take care for Add new privateLender
      if (privateLenderWrapper.dataModel.displayName.indexOf(Constants.ADD_NEW_RECORD_MORTGAGEE) === 0) {
        const participant = new MatterParticipant();
        this.setPrivateLenderCapacityAndShare(participant, this.mortgage.mortgageCapacityType);
        if (privateLenderWrapper.isPrivateLenderMortgagee) {
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: {
              matter: this.matter,
              contactType: 'MORTGAGEE'
            },
            onFulfillment: (result) => {
              privateLenderWrapper.dataModel = '';
              if (result.contact) {
                this.enableSave();
                this.markUndertakingDirty();
                // this.updateOnPurchaserClientCreated(result.contact, participant);
                privateLenderWrapper.showLabel = true;
                privateLenderWrapper.createdOrSelected = 'Created';
                privateLenderWrapper.editMode = false;
                privateLenderWrapper.selectedDetailsTabIndex = 0;
                this.silentAddPrivateLender(result.contact, privateLenderWrapper);
              }
            },
            fullScreen: true,

          });
        } else {
          this.dialogService.matDialogContent({
            content: CommonContactDialogComponent,
            context: {
              matter: this.matter,
              mortgage: this.mortgage,
              matterParticipant: participant,
              contactType: 'CLIENT',
              matterParticipantRole: 'PRIVATE_LENDER',
              contactName: privateLenderWrapper.dataModel.displayName
              // matterParticipantRole : 'OTHER_PARTY',
            },
            onFulfillment: (result) => {
              privateLenderWrapper.dataModel = '';
              if (result) {
                if (result.action === 'save') {
                  this.enableSave();
                  this.markUndertakingDirty();

                  // this.updateOnPurchaserClientCreated(result.contact, participant);
                  privateLenderWrapper.showLabel = true;
                  privateLenderWrapper.createdOrSelected = 'Created';
                  privateLenderWrapper.editMode = false;
                  privateLenderWrapper.selectedDetailsTabIndex = 0;
                  this.silentAddPrivateLender(result.contact, privateLenderWrapper);

                }

              }
            },
            fullScreen: true,

          });
        }
      } else {
        // Case No records found and help text
        privateLenderWrapper.dataModel = undefined;
      }
    } else {
      privateLenderWrapper.createdOrSelected = 'Selected';
      this.enableSave();
      this.markUndertakingDirty();
      privateLenderWrapper.editMode = false;
      privateLenderWrapper.selectedDetailsTabIndex = 0;
      let contact = await this.contactQueryService.getContactForMatter(privateLenderWrapper.dataModel.id).toPromise();
      this.silentAddPrivateLender(contact, privateLenderWrapper);

    }
  }

  async silentAddPrivateLender(contact: Contact, privateLenderWrapper: MatterParticipantWrapper): Promise<void> {
    this.checkForServiceAndReportAddress(contact);
    privateLenderWrapper.matterParticipant = this.matter.addMatterParticipant(contact, true, 'PRIVATE_LENDER', this.mortgage.id);
    await this.matterParticipantService.createMatterParticipantAssociatedContactForClient(contact, privateLenderWrapper.matterParticipant, this.matter);
    if (privateLenderWrapper.isPrivateLenderMortgagee) {
      this.updateContactFromMortgageeToPrivateLender(privateLenderWrapper.matterParticipant.contact);
    }
    // dataModel (contact) need to be updated as well.
    privateLenderWrapper.dataModel = privateLenderWrapper.matterParticipant.contact;
    this.setPrivateLenderCapacityAndShare(privateLenderWrapper.matterParticipant, this.mortgage.mortgageCapacityType);
    this.matter.setPrivateLenderPrimaryFlag(privateLenderWrapper.matterParticipant, this.matterPrivateLenders);
    if (this.matterPrivateLenders.length === 1) {
      if (!this.isCorrespondWithSolicitor()) {
        this.populateMortgageContactInfoFromPrivateLender(contact);
      }
    }
    this.updateCommonSpecifiedCapacity();
    this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
    this.matter.reCalculateLenderReLine(this.mortgage);
    this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
    }
    this.setExpanded(privateLenderWrapper, true);
    // privateLenderWrapper.expanded = false;
    this.setFirstMortgageeMyClientFlag();
  }

  updateContactFromMortgageeToPrivateLender(contact: Contact): void {
    contact.contactCategory = 'OTHER_SPECIFY';
    contact.contactType = 'ORGANIZATION';
    contact.otherCategoryName = 'Private Lender';
    contact.mortgageeLenderInstitutionLinkStatus = null;
    contact.mortgagesRegistered = false;
    contact.partyRole = 'CLIENT';
    contact.alternateName = undefined;
    contact.branch = undefined;
    contact.attention = undefined;
    contact.lenderInstitutionId = undefined;
    contact.transitNo = undefined;
    contact.institutionNo = undefined;
    contact.dischargeFax = undefined;
    contact.linkToStandardChargeItem = undefined;
  }

  checkForServiceAndReportAddress(contact: Contact): void {
    if (!contact.serviceAddress) {
      contact.serviceAddress = new Address();
      contact.serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
      contact.serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;
    }
    if (!contact.reportAddress) {
      contact.reportAddress = new Address();
      contact.reportAddress.addressTypeCode = AddressTypes.reportAddress;
      contact.reportAddress.sameAsAddressTypeCode = AddressTypes.mailing;
    }
  }

  public populateMortgageContactInfoFromPrivateLender(privateLenderContact: Contact): void {
    this.mortgage.mortgageContactInfo.mailingAddress = new Address(privateLenderContact.mailingAddress);
    this.mortgage.mortgageContactInfo.mailingAddress.id = null;
    this.mortgage.mortgageContactInfo.serviceAddress = new Address();
    this.mortgage.mortgageContactInfo.serviceAddress.addressTypeCode = AddressTypes.serviceAddress;
    this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;
    this.mortgage.mortgageContactInfo.reportAddress = new Address();
    this.mortgage.mortgageContactInfo.reportAddress.addressTypeCode = AddressTypes.reportAddress;
    this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = AddressTypes.mailing;
    this.mortgage.mortgageContactInfo.phone = privateLenderContact.workPhone;
    this.mortgage.mortgageContactInfo.fax = privateLenderContact.faxPhone;
    this.mortgage.mortgageContactInfo.email = privateLenderContact.email;
    this.mortgage.mortgageContactInfo.dear = privateLenderContact.dear;
    this.mortgage.mortgageContactInfo.name = (privateLenderContact.isCorporationOrOtherEntity)
      ? privateLenderContact.organizationName
      : privateLenderContact.surnameLastFullName;
    this.mortgage.mortgageContactInfo.additionalName1 = privateLenderContact.signingOfficer1;
    this.mortgage.mortgageContactInfo.titleOfOfficeHeld1 = privateLenderContact.titleOfOfficeHeld1;
    this.mortgage.mortgageContactInfo.additionalName2 = privateLenderContact.signingOfficer2;
    this.mortgage.mortgageContactInfo.titleOfOfficeHeld2 = privateLenderContact.titleOfOfficeHeld2;
    if (privateLenderContact.signingOfficer1 || privateLenderContact.signingOfficer2 || privateLenderContact.titleOfOfficeHeld1 || privateLenderContact.titleOfOfficeHeld2) {
      this.mortgage.includeAuthorizeSignOfficer = 'YES';
    }
  }

  setAsPrimaryPrivateLender(privateLender: MatterParticipantWrapper): void {
    if (privateLender.matterParticipant && !privateLender.primary) {
      this.matterPrivateLenders.forEach((w: MatterParticipantWrapper) => {
        if (w.matterParticipant) {
          w.matterParticipant.primary = false;
        }
      });
      privateLender.primary = true;
      //--DPPMP-10003, change of the Private lender should not affect the Solicitor data
      // this.populateMortgageContactInfoFromPrivateLender(privateLender.matterParticipant.contact);
      if (this.matter.isProjectSale) {
        this.mortgageSoAdjService.updateStatementOfAdjustment(this.matter);
      }

      this.enableSave();
    }
  }

  setMortgageeAsMyClient(mortgagee: MatterParticipantWrapper): void {
    if (mortgagee.matterParticipant && !mortgagee.myClient) {
      MatterUtil.resetAllMortgageesMyClientFlag(this.matter);
      mortgagee.myClient = true;
      this.matter.reCalculateMatterLenderReLine();
      if (mortgagee.matterParticipant && mortgagee.matterParticipant.isPrivateLender) {
        this.setAsPrimaryPrivateLender(mortgagee);
      }
      this.enableSave();
    }
  }

  get primaryMatterPrivateLender(): MatterParticipantWrapper {
    return this.matterPrivateLenders
      ? this.matterPrivateLenders.find((w: MatterParticipantWrapper) => !w.editMode
        && w.matterParticipant
        && w.primary
        && !!w.matterParticipant.contact)
      : null;
  }

  get institutionMortgagee(): MatterParticipantWrapper {
    return this.selectedMortgagees
      ? this.selectedMortgagees.find((w: MatterParticipantWrapper) => !w.editMode
        && w.matterParticipant
        && !!w.matterParticipant.contact)
      : null;
  }

  public compareAttentions(left: Contact, right: Contact): number {
    const leftName: string = this.getPrivateLenderDisplayNameFromContact(left).toUpperCase();
    const rightName: string = this.getPrivateLenderDisplayNameFromContact(right).toUpperCase();
    const nameComparison: number = leftName.localeCompare(rightName);
    if (nameComparison === 0) {
      const leftAddress: string = this.getContactDisplayMailingAddress(left).toUpperCase();
      const rightAddress: string = this.getContactDisplayMailingAddress(right).toUpperCase();
      return leftAddress.localeCompare(rightAddress);
    }
    return nameComparison;
  }

  deletePrivateLender(privateLender: MatterParticipantWrapper): void {
    let deleteConfirmationMessage = 'Proceed to remove this Private Lender from this matter?';
    let myClient = privateLender.matterParticipant.myClient;
    if (myClient) {
      deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, privateLender.matterParticipant, privateLender.matterParticipant.matterParticipantRole);
    }

    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(res => {
      if (res) {
        if (privateLender && privateLender.matterParticipant && this.matter && this.documentProductionService && this.contactQueryService) {
          this.documentProductionService.tryToRevokePackage(this.matter, privateLender.matterParticipant, this.contactQueryService);
        }
        let childMatterParticipants = this.matter.getChildMatterParticipants(privateLender.matterParticipant);
        if (privateLender
          && privateLender.matterParticipant
          && privateLender.matterParticipant.sourceContactLockAcquired) { //If it is locked, it will send unlock this source contact
          this.tabsService.unLockSourceContact(privateLender);

        }
        if (childMatterParticipants) {
          childMatterParticipants.forEach(item => {
            if (item.sourceContactLockAcquired) { //If it is locked, it will send unlock this source contact
              this.contactQueryService.unlockContact(item.contact.sourceContactId).subscribe();
            }
          });
        }
        this.errorService.removeDpSaveError(`privateLender.name_${ privateLender.dataModel.identifier }`);
        const errorKey
          = 'mortgage.' + this.mortgageIndex + '.mortgagee.privateLender'
          + '.genderChangeProhibited' + '_privateLenderGender_' + privateLender.matterParticipant.identifier;
        this.errorService.removeDpFieldError(errorKey);
        if (myClient) {
          this.handleContactChange(privateLender, true, true);
        }
        this.silentDeletePrivateLender(privateLender);
        this.setFocusAfterDelete();
      }
    });
  }

  silentDeletePrivateLender(privateLender: MatterParticipantWrapper): void {
    let myClient = privateLender.matterParticipant.myClient;
    let childMatterParticipants = this.matter.getChildMatterParticipants(privateLender.matterParticipant);
    if (this.matterPrivateLenders.length === 1) {
      this.matter.removeMatterParticipant(privateLender.matterParticipant);
      //--DPPMP-10003, change of the Private lender should not affect the Solicitor data
      //this.resetMortgageFields();
      this.matterPrivateLenders = [];
      if (this.mortgage.mortgageContactInfo && this.mortgage.mortgageContactInfo.titleDetailsType == 'MANUALLY_ENTERED') {
        this.mortgage.mortgageContactInfo.titleDetailsDescription = null;
        this.mortgage.mortgageContactInfo.titleDetailsType = 'AUTO_POPULATED';
      }
      //this.matterPrivateLenders.push(this.createPrivateLenderWrapper());
    } else {
      this.matterPrivateLenders = this.matterPrivateLenders.filter((w: MatterParticipantWrapper) => (w.matterParticipantId !== privateLender.matterParticipantId));
      this.matter.removeMatterParticipant(privateLender.matterParticipant);
      if (privateLender.primary) {
        this.setAsPrimaryPrivateLender(this.matterPrivateLenders[ 0 ]);
      }
    }

    this.updateMortgageeMyClientFlag(myClient);

    if (childMatterParticipants) {
      childMatterParticipants.forEach(item => {
        this.matter.removeMatterParticipant(item);
      });
    }
    if (this.matterPrivateLenders.length < 2) {
      this.mortgage.mortgageCapacityType = 'SILENT';
    }
    if (this.privateLenderInfoComponents) {
      const pl: PrivateLenderInfoComponent = this.privateLenderInfoComponents.find((pl: PrivateLenderInfoComponent) => {
        return pl.privateLenderId === privateLender.contactId;
      });
      if (pl) {
        pl.onDelete();
      }
    }

    this.updateCommonSpecifiedCapacity();
    this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
    this.matter.reCalculateLenderReLine(this.mortgage);
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
    }
    this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
    this.markUndertakingDirty();
    this.enableSave();
  }

  setFocusAfterDelete() {
    setTimeout(() => {
      if (this.matterPrivateLenders.length == 1 && !this.matterPrivateLenders[ 0 ].matterParticipant) {
        jQuery('#public-lender-autocomplete .ui-autocomplete-input').focus();
      } else {
        jQuery('.public-lender-header-name').filter(':visible:last').focus();
      }
    }, 900);
  }

  isPrivateLenderMortgageContactInfoVisible(privateLender: MatterParticipantWrapper): boolean {
    return !this.isLawFirmVisible() && privateLender.matterParticipant && privateLender.primary;
  }

  isAddAnotherPrivateLenderVisible() {
    return !this.matterPrivateLenders.find((p: MatterParticipantWrapper) => p.editMode)
      && this.matterPrivateLenders.length < this.appConfig.getMaxNumberOfPurchasers();
  }

  isPrivateLenderVisible() {
    return !this.matterPrivateLenders.find((p: MatterParticipantWrapper) => p.editMode)
      && this.matterPrivateLenders.length < this.appConfig.getMaxNumberOfPurchasers();
  }

  selectTab(selectedTab: string, matterPrivateLender: MatterParticipantWrapper): void {
    matterPrivateLender.selectedTab = selectedTab || defaultTab;

    if (this.isSelectedTabIdDetails(matterPrivateLender.selectedTab)
      && matterPrivateLender.matterParticipant
      && matterPrivateLender.matterParticipant.contact
      && matterPrivateLender.matterParticipant.contact.isDirty) {
      matterPrivateLender.matterParticipant.contact.initializeContactIdDetails();
    }
    setTimeout(function () {
      jQuery('.mat-modal-dialog').find('input[type=text],dp-checkbox label,textarea,select').filter(':visible:first')
      .focus();
    }, 500);
  }

  isSelectedTabIdDetails(selectedTab: string): boolean {
    return (selectedTab === 'ID-Details');
  }

  //This function called from common service after updating participant's snapshot from source contact
  mortgageeCallbackOnUpdateSourceContact = (result: boolean, matterParticipantWrapper: MatterParticipantWrapper, oldSnapshotReference: Contact, sourceContactWithSubcontcts: Contact) => {
    //If result true that means contact updated successfully
    if (result) {
      this.getAttentionPickList(sourceContactWithSubcontcts);
      this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
      this.matter.reCalculateLenderReLine(this.mortgage);
      this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
      this.enableSave();
    }

    //open the shutter after finishing the processing.
    matterParticipantWrapper.expanded = true;
  };

  get isInstitutionMortgageeSelected(): boolean {
    return !!this.institutionMortgagee;
  }

  public getContactAndInvokeCallback(id: number, callBack: (src: Contact) => void) {
    this.contactQueryService.getContactForMatter(id).subscribe((source: Contact) => {
      callBack(source);
    });
  }

  showPrivateLenderTitleDetails(): void {
    this.dialogService.matDialogContent({
      content: PrivateLendersTitleComponent,
      context: {privateLenders: this.matter.getPrivateLenders(this.mortgage)}
    });
  }

  onPrivateLenderActionsClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  addNewPrivateLender(privateLenderType: string): void {
    this.matterPrivateLenders.push(this.createPrivateLenderWrapper(undefined, privateLenderType == 'MORTGAGEE'));
  }

  moveUpPrivateLender(selectedPrivateLender: MatterParticipantWrapper): void {
    this.matter.reorderParticipantByMortgage(selectedPrivateLender.matterParticipant, true, this.mortgage);
    this.reorderMatterPrivateLenders();
    this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
    this.matter.reCalculateLenderReLine(this.mortgage);
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
    }
    this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
    this.notifyChange();
    this.markUndertakingDirty();
  }

  moveDownPrivateLender(selectedPrivateLender: MatterParticipantWrapper): void {
    this.matter.reorderParticipantByMortgage(selectedPrivateLender.matterParticipant, false, this.mortgage);
    this.reorderMatterPrivateLenders();
    this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
    this.matter.reCalculateLenderReLine(this.mortgage);
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
    }
    this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
    this.notifyChange();
    this.markUndertakingDirty();
  }

  public reorderMatterPrivateLenders(): void {
    const reorderedPrivateLenderWrappers: MatterParticipantWrapper[] = [];
    this.matter.getPrivateLenders(this.mortgage).forEach((p: MatterParticipant) => {
      const matched: MatterParticipantWrapper = this.matterPrivateLenders.find((w: MatterParticipantWrapper) => w.matterParticipantId === p.matterParticipantId);
      if (matched) {
        this.setExpanded(matched, false);
        reorderedPrivateLenderWrappers.push(matched);
      }
    });
    this.matterPrivateLenders = reorderedPrivateLenderWrappers;
  }

  onLeavingPrivateLenderOmnibar(privateLenderWrapper: MatterParticipantWrapper) {
    setTimeout(() => {
      if (privateLenderWrapper && (!privateLenderWrapper.dataModel || typeof privateLenderWrapper.dataModel === 'string')) {
        privateLenderWrapper.dataModel = null;
        // Upon exiting the search field without making a selection,
        // screen should reset to the default (hide the search bar, display the two "Add from.." options.
        this.silentDeletePrivateLender(privateLenderWrapper);
      }
    }, 200);
    // if (privateLenderWrapper.createdOrSelected === 'SelectedNoResult') {
    //     privateLenderWrapper.createdOrSelected = null;
    // }
    // if (!privateLenderWrapper.createdOrSelected && privateLenderWrapper.dataModel && !privateLenderWrapper.matterParticipant) {
    //     setTimeout(this.transformToNewPrivateLender, 700, privateLenderWrapper);
    // }
  }

  transformToNewPrivateLender = (privateLenderWrapper: MatterParticipantWrapper) => {
    if (!privateLenderWrapper.createdOrSelected) {
      this.enableSave();
      privateLenderWrapper.matterParticipant = this.createNewPrivateLenderContact(privateLenderWrapper);
      this.setPrivateLenderCapacityAndShare(privateLenderWrapper.matterParticipant, this.mortgage.mortgageCapacityType);
      privateLenderWrapper.dataModel = privateLenderWrapper.matterParticipant.contact;
      privateLenderWrapper.selectedDetailsTabIndex = 0;
      privateLenderWrapper.createdOrSelected = 'Created';
      privateLenderWrapper.editMode = false;

      if (this.matterPrivateLenders.length === 1) {
        privateLenderWrapper.primary = true;
      }
      this.updateCommonSpecifiedCapacity();
      this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
      this.matter.reCalculateLenderReLine(this.mortgage);
      this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
      this.setExpanded(privateLenderWrapper, true);

    } else if (privateLenderWrapper.createdOrSelected === 'SelectedNoResult') {
      privateLenderWrapper.createdOrSelected = null;
    }
  };

  createNewPrivateLenderContact(privateLenderWrapper: MatterParticipantWrapper): MatterParticipant {
    let contact = new Contact();
    if (typeof (privateLenderWrapper.dataModel) === 'string') {
      const nameTokens: string[] = privateLenderWrapper.dataModel.split(',');
      if (nameTokens.length === 1) {
        contact.setDefaultValues('PRIVATE_LENDER', 'OTHERENTITY');
        contact.localGender = contact.gender; // For gender change
        contact.organizationName = nameTokens[ 0 ].trim();
      } else {
        contact.setDefaultValues('PRIVATE_LENDER', 'QUESTION');
        contact.localGender = contact.gender; // For gender change
        contact.lastName = nameTokens[ 0 ].trim();
        contact.firstName = nameTokens[ 1 ].trim();
      }
    }
    contact.sourceContactId = null;
    contact.snapshotFlag = true;
    return this.matter.addMatterParticipant(contact, false, 'PRIVATE_LENDER', this.mortgage.id);
  }

  isCapacityWarningForPrivateLenderVisible(matterPrivateLender: MatterParticipantWrapper): boolean {
    if (this.matter.isMatterProvinceSK) {
      return this.mortgage.mortgageCapacityType === 'OTHER'
        && matterPrivateLender.matterParticipant
        && !matterPrivateLender.matterParticipant.purchaserShare;
    } else {
      return this.mortgage.mortgageCapacityType === 'OTHER'
        && matterPrivateLender.matterParticipant
        && (!matterPrivateLender.matterParticipant.purchaserCapacity || !matterPrivateLender.matterParticipant.purchaserShare);
    }
  }

  onMortgageCapacityChange(mortgageCapacity: MortgageCapacityType): void {
    this.mortgage.mortgageCapacityDescription = PurchaserCapacity.getMortgageCapacityDescription(mortgageCapacity, this.matter.getPrivateLenders(this.mortgage).length, this.matter.provinceCode);
    this.enableSave();
    this.matterPrivateLenders.forEach((w: MatterParticipantWrapper) => {
      if (w.matterParticipant) {
        this.setPrivateLenderCapacityAndShare(w.matterParticipant, mortgageCapacity);
      }
    });
  }

  setPrivateLenderCapacityAndShare(privateLender: MatterParticipant, mortgageCapacity: MortgageCapacityType) {
    switch (mortgageCapacity) {
      case 'JOINT':
        privateLender.purchaserCapacity = 'JOINT_ACCOUNT_SURVIVORSHIP';
        privateLender.purchaserShare = null;
        break;

      case 'COMMON_SPECIFIED':
        privateLender.purchaserCapacity = 'TENANTS_IN_COMMON';
        privateLender.purchaserShare = PurchaserCapacity.getDynamicValueInterest(this.matter.getPrivateLenders(this.mortgage).length);
        break;

      case 'COMMON_UNSPECIFIED':
        privateLender.purchaserCapacity = 'TENANTS_IN_COMMON';
        privateLender.purchaserShare = null;
        break;

      case 'SILENT':
      case 'OTHER':
      default:
        privateLender.purchaserCapacity = '';
        privateLender.purchaserShare = privateLender.previousPurchaserShareValue ? privateLender.previousPurchaserShareValue : '';
    }
  }

  changedMortgagee(selectedMortgagee: MatterParticipantWrapper): void {
    if (selectedMortgagee && selectedMortgagee.matterParticipant) {
      selectedMortgagee.matterParticipant.contact.isDirty = true;
    }
    this.notifyChange();
  }

  checkMortgageContactInfoType(): void {
    this.enableSave();

    if (this.isCorrespondWithSolicitor() && (this.mortgageContactInfoType != 'SOLICITOR')) {
      this.mortgageContactInfoType = 'SOLICITOR';
      this.resetMortgageFields(false);
      this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = AddressTypes.solicitorAddress;
      this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = AddressTypes.solicitorAddress;
      this.initSolicitorReportAndServiceAddressDd();
    } else if (this.isPrivateLenderMortgageeType() && (this.mortgageContactInfoType != 'PRIVATE_LENDER')) {
      this.mortgageContactInfoType = 'PRIVATE_LENDER';
      if (this.primaryMatterPrivateLender) {
        this.populateMortgageContactInfoFromPrivateLender(this.primaryMatterPrivateLender.matterParticipant.contact);
      } else {
        this.resetMortgageFields();
        if (this.isCorrespondWithSolicitor()) {
          this.mortgage.mortgageContactInfo.serviceAddress.sameAsAddressTypeCode = AddressTypes.solicitorAddress;
          this.mortgage.mortgageContactInfo.reportAddress.sameAsAddressTypeCode = AddressTypes.solicitorAddress;
        }
      }
    } else if (this.mortgageContactInfoType != 'MORTGAGEE') {
      this.mortgageContactInfoType = 'MORTGAGEE';
      if (this.institutionMortgagee) {
        this.populateMortgageeFieldsFromMortgagee(this.institutionMortgagee.matterParticipant.contact);
      } else {
        this.resetMortgageFields();
      }
    }
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.addTrustLedgerMortgageRow(this.mortgageIndex - 1, this.mortgage.isLoanTypeBridge(), this.mortgage.isLoanTypeArranged());
    }
    this.isPrivateLenderMortgageeType() ?
      this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage) :
      this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
    this.matter.reCalculateLenderReLine(this.mortgage);
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm.sctFiledBy = this.mortgage.lenderReline;
    }
    this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
  }

  getDefaultQuery(type: string): any {
    return {query: type};
  }

  get constantNoResultValue() {
    return Constants.NO_RESULTS_FOUND;
  }

  updateStatementOfAccount(): void {
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.addOrRemoveFeeForMortgage(this.mortgage, this.mortgageIndex - 1);
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach(collection => {
          collection.addOrRemoveFeeForMortgage(this.mortgage, this.mortgageIndex - 1);
        });
      }
      this.matter.soaTrustLedgerCollection.addTrustLedgerMortgageRow(this.mortgageIndex - 1, this.mortgage.isLoanTypeBridge(), this.mortgage.isLoanTypeArranged());
      this.isPrivateLenderMortgageeType() ?
        this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage) :
        this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
      this.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach(collection => {
          collection.updateERegAndRegisterCharges();
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.closeAllOpenedPrivateLenderShutters();
    if (this.selectedSolicitor && this.selectedSolicitor.matterParticipant) {
      this.setExpanded(this.selectedSolicitor, false);
    }
    if (this.selectedLawFirm && this.selectedLawFirm.matterParticipant) {
      this.setExpanded(this.selectedLawFirm, false);
    }

    this.unLockAllMortgagees();
  }

  get mortgageIndex(): number {
    let index = this.matter.mortgages.findIndex(item => item.id == this.mortgage.id);
    return index >= 0 ? (Number(index) + 1) : index;
  }

  get hasAttentionOptions(): boolean {
    return this.mortgageBrokerAttentionOptions && this.mortgageBrokerAttentionOptions.length > 0;
  }

  getPrivateLenderBurgerMenuItems(index: number, matterPrivateLender: MatterParticipantWrapper): any {
    let menuItems = [];
    if (this.matterPrivateLenders.length > 1) {
      if (index > 0) {
        menuItems.push(SnapshotBurgerMenuActions.MOVE_UP);
      }

      if (index < this.matterPrivateLenders.length - 1) {
        menuItems.push(SnapshotBurgerMenuActions.MOVE_DOWN);
      }
    }
    menuItems.push(SnapshotBurgerMenuActions.DELETE);
    if (matterPrivateLender.matterParticipant && matterPrivateLender.matterParticipant.sourceContact
      && matterPrivateLender.matterParticipant.sourceContact.isProxyCopyOfGlobal) {
      menuItems.push(SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
    }
    if (matterPrivateLender.isStale) {
      menuItems.push(SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
    }
    return menuItems;
  }

  clickPrivateLenderBurgerMenu(event, participantWrapper: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE: {
        this.deletePrivateLender(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_UP: {
        this.moveUpPrivateLender(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.MOVE_DOWN: {
        this.moveDownPrivateLender(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT : {
        this.handleClearReplaceActions(participantWrapper);
        break;
      }
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL : {
        this.matterParticipantService.revertToGlobal(participantWrapper, this.privateLenderCallbackOnRevertToGlobal);
        break;
      }
      default: {
        break;
      }
    }
  }

  privateLenderCallbackOnRevertToGlobal = (result: boolean, matterParticipantWrapper: MatterParticipantWrapper) => {
    if (result) {
      if (matterParticipantWrapper.isPrivateLenderMortgagee) {
        this.updateContactFromMortgageeToPrivateLender(matterParticipantWrapper.matterParticipant.contact);
      }
      this.populateMortgageContactInfoFromPrivateLender(matterParticipantWrapper.matterParticipant.contact);
      this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
      this.matter.reCalculateLenderReLine(this.mortgage);
      this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
      this.enableSave();
    }
  };

  updateSnapshotForPrivateLender(privateLender: MatterParticipantWrapper): void {
    let serviceAddress = privateLender &&
      privateLender.matterParticipant &&
      privateLender.matterParticipant.contact &&
      privateLender.matterParticipant.contact.serviceAddress;

    let reportAddress = privateLender &&
      privateLender.matterParticipant &&
      privateLender.matterParticipant.contact &&
      privateLender.matterParticipant.contact.reportAddress;
    this.getContactAndInvokeCallback(privateLender.matterParticipant.contact.sourceContactId, (source: Contact) => {
      privateLender.matterParticipant.contact.update(source);
      this.matter.getAllSigners(privateLender.matterParticipant).forEach(matterParticipant => {
        this.tabsService.unLockSourceContactForParticipant(matterParticipant);
        (<any>this.matter.matterParticipants).remove(matterParticipant);
      });
      this.matterParticipantService.createMatterParticipantAssociatedContactForClient(privateLender.matterParticipant.contact, privateLender.matterParticipant, this.matter, privateLender);
      if (privateLender.isPrivateLenderMortgagee) {
        this.updateContactFromMortgageeToPrivateLender(privateLender.matterParticipant.contact);
      }
      if (serviceAddress) {
        privateLender.matterParticipant.contact.serviceAddress = serviceAddress;
      }
      if (reportAddress) {
        privateLender.matterParticipant.contact.reportAddress = reportAddress;
      }
      this.initializeMatterPrivateLenders();
      this.populateMortgageContactInfoFromPrivateLender(privateLender.matterParticipant.contact);
      this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage);
      this.matter.reCalculateLenderReLine(this.mortgage);
      this.matter.updateMortgageeInterestOnNotedPolicy(this.mortgage);
      this.updateWrapperFlags(privateLender, source);
      this.enableSave();
    });
  }

  getDisabledItems(selectedMatterParticipantWrapper: MatterParticipantWrapper) {
    let disabledMenuItems = [];
    if (selectedMatterParticipantWrapper.isStale && selectedMatterParticipantWrapper.isLockedElsewhere) {
      disabledMenuItems.push('Clear Flag Without Updating Matter');
      disabledMenuItems.push('Replace Matter With Source Contact');
    }
    return disabledMenuItems;
  }

  toggleContactSnapshot(participantWrapper: MatterParticipantWrapper): void {
    if (participantWrapper && participantWrapper.matterParticipant) {
      this.setExpanded(participantWrapper, !participantWrapper.expanded);
    }
  }

  isExpanded(purchaser: MatterParticipantWrapper): boolean {
    return purchaser.expanded;
  }

  openSourceContactTab(wrapper: MatterParticipantWrapper): void {
    if (wrapper && wrapper.matterParticipant) {
      this.contactQueryService.getContactForOpeningTab(wrapper.matterParticipant.contact.sourceContactId).subscribe((res: any) => {
        let source: Contact = res.contact;
        const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/' + source.contactType, source.contactType);
        this.tabsService.openTab(contactTab);
      });
    }
  }

  isSourceContactLocked(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return matterParticipantWrapper
      && matterParticipantWrapper.sourceContactIsLocked;

  }

  isOutOfSync(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return matterParticipantWrapper && !matterParticipantWrapper.sourceContactIsLocked
      && (matterParticipantWrapper.isStale || matterParticipantWrapper.isClearFlagWithoutUpdatingMatter);
  }

  isStale(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return matterParticipantWrapper && !matterParticipantWrapper.sourceContactIsLocked
      && matterParticipantWrapper.isStale;
  }

  snapshotReadOnly(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return this.isSourceContactLocked(matterParticipantWrapper)
      || this.isOutOfSync(matterParticipantWrapper) || this.isSnapshotPrivateLenderGlobalMortgage(matterParticipantWrapper);
  }

  isSnapshotPrivateLenderGlobalMortgage(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return (matterParticipantWrapper.isPrivateLenderMortgagee
      && matterParticipantWrapper.matterParticipant.sourceContact && matterParticipantWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount);
  }

  capacityReadOnly(matterParticipantWrapper: MatterParticipantWrapper): boolean {
    return this.mortgage.mortgageCapacityType != 'OTHER'
      || this.isSourceContactLocked(matterParticipantWrapper);
  }

  updateWrapperFlags(matterParticipantWrapper: MatterParticipantWrapper, sourceContact: Contact) {
    if (matterParticipantWrapper
      && matterParticipantWrapper.matterParticipant
      && matterParticipantWrapper.matterParticipant.contact) {
      if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired
        && matterParticipantWrapper.matterParticipant.contact.isDirty) {
        // If it is in edit mode and has already modified data, it should not be in out of sync state.
        // So isStale should be false. isClearFlagWithoutUpdatingMatter is same logic
        matterParticipantWrapper.isStale = false;
        matterParticipantWrapper.isClearFlagWithoutUpdatingMatter = false;
      } else {
        matterParticipantWrapper.isStale = matterParticipantWrapper.matterParticipant.contact.isStalePrivateLender(sourceContact);
        matterParticipantWrapper.isClearFlagWithoutUpdatingMatter
          = matterParticipantWrapper.matterParticipant.contact.isPrivateLenderClearFlagWithoutUpdatingMatter(sourceContact);
      }
      this.tabsService.updateIsLockedElsewhereStatus(matterParticipantWrapper, sourceContact);

      matterParticipantWrapper.canBeUpdatedFromSourceContact
        = matterParticipantWrapper.isStale && !!matterParticipantWrapper.matterParticipant.contact.sourceContactId;
      matterParticipantWrapper.lastUpdatedOn = sourceContact.lastUpdatedOnMsg;
    }
  }

  handleClearReplaceActions(selectedMatterParticipantWrapper: MatterParticipantWrapper): void {
    this.contactQueryService.getContactForMatter(selectedMatterParticipantWrapper.matterParticipant
      && selectedMatterParticipantWrapper.matterParticipant.contact
      && selectedMatterParticipantWrapper.matterParticipant.contact.sourceContactId)
    .subscribe((sourceContact: Contact) => {
      // sourceContact.locked means it is locked by other user
      // isContactAlreadyLocked means it is locked by same user but other matter tab or contact tab
      this.tabsService.updateIsLockedElsewhereStatus(selectedMatterParticipantWrapper, sourceContact);
      if (!selectedMatterParticipantWrapper.isLockedElsewhere) {
        this.updateSnapshotForPrivateLender(selectedMatterParticipantWrapper);
      } else {
        this.setExpanded(selectedMatterParticipantWrapper, true);
      }
    });
  }

  openCapacitySection(matterParticipantWrapper: MatterParticipantWrapper): void {
    this.setExpanded(matterParticipantWrapper, true);
    matterParticipantWrapper.selectedTab = 'Capacity-Share';
  }

  openIDVerificationSection(matterParticipantWrapper: MatterParticipantWrapper): void {
    this.setExpanded(matterParticipantWrapper, true);
    matterParticipantWrapper.selectedTab = 'ID-Details';
  }

  get isPrivateLenderWithoutSolicitor(): boolean {
    if (this.matter.isPurchase) {
      return this.mortgage && this.mortgage.mortgageeType === 'PRIVATE_LENDER'
        && !(this.mortgage.acting == 'NO' && this.mortgage.mortgageCorrespondenceType == 'SOLICITOR');
    } else {
      return this.mortgage && this.mortgage.mortgageeType === 'PRIVATE_LENDER'
        && !(this.mortgage.mortgageCorrespondenceType == 'SOLICITOR');
    }
  }

  isVisibleSolicitorSnapshot(wrapper: MatterParticipantWrapper): boolean {

    return this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage) && this.isExpanded(wrapper);
  }

  showLawFirmSnapshot(wrapper: MatterParticipantWrapper): boolean {

    return this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage)
      && this.isExpanded(wrapper);
  }

  showAllLawFirmSnapshotFields(wrapper: MatterParticipantWrapper): boolean {

    return this.showLawFirmSnapshot(wrapper) && this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage) == null;
  }

  get solicitorNameHelpText(): string {
    let helpText: string;

    if (this.showSolicitorDropDownArrow) {
      //If it is my own account, removing to add new solicitor
      if (this.isMyOwnAccountLawFirm()) {
        helpText = 'Select a Solicitor from the Law Firm';
      } else {
        helpText = 'Select a Solicitor from the Law Firm or to add a new one';
      }
    } else {
      helpText = 'Search by "Surname, First Name", Address or Add new';
    }

    return helpText;
  }

  get lawFormSnapshotReadOnly(): boolean {

    return this.isLawFormOrSolicitorLocked(this.selectedLawFirm) || this.isOutOfSync(this.selectedLawFirm)
      || this.isLawFormOrSolicitorGlobal(this.selectedLawFirm)
      || this.isMyOwnAccountLawFirm();
  }

  isLawFormOrSolicitorLocked(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper && wrapper.sourceContactIsLocked) {
      return true;
    }
    return false;
  }

  isLawFormOrSolicitorGlobal(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper) {
      if (wrapper.sourceContactIsLocked
        || (wrapper.matterParticipant
          && wrapper.matterParticipant.contact
          && wrapper.isOwnedBySystemAccount)) {
        return true;
      }
    }
    return false;
  }

  get solicitorSnapshotReadOnly(): boolean {

    return this.isLawFormOrSolicitorLocked(this.selectedSolicitor) || this.isOutOfSync(this.selectedSolicitor)
      || this.isLawFormOrSolicitorGlobal(this.selectedSolicitor) || this.isMyOwnAccountSolicitor();
  }

  showGlobalWindow(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper.matterParticipant && wrapper.matterParticipant.contact) {
      return wrapper.isOwnedBySystemAccount;
    }
    return false;
  }

  showAddressBookWindow(wrapper: MatterParticipantWrapper): boolean {
    if (wrapper.matterParticipant && wrapper.matterParticipant.contact) {
      return wrapper.isProxyCopyOfGlobal;
    }
    return false;
  }

  openSourceSolicitorTab() {
    let solicitor: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    if (solicitor && solicitor.contact && solicitor.contact.sourceContactId) {
      this.contactQueryService.getContactForOpeningTab(solicitor.contact.sourceParentOrganizationId).subscribe((res: any) => {
        let source: Contact = res.contact;
        // const contactDetails : any = ContactDetails.find((item : any) => {
        //     return item.label === purchaser.selectedDetailsTabText;
        // });
        // const subRoute : string = contactDetails && contactDetails.route;
        const contactTab = ContactTab.createContactTab(source, 'main/contacts/contact/LAW_FIRM', source.contactType, 'solicitor', solicitor.contact.sourceContactId);
        this.tabsService.openTab(contactTab);
      });
    }
  }

  isMyOwnAccountSolicitor(): boolean {
    const accountId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    const legFirmId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.legalFirmId));
    if (this.selectedSolicitor
      && this.selectedSolicitor.matterParticipant
      && this.selectedSolicitor.matterParticipant.contact) {
      return (this.selectedSolicitor.matterParticipant.contact.customerAccountId == accountId)
        && (this.selectedLawFirm.sourceContactLegfirmId == legFirmId);
    }

    return false;
  }

  isMyOwnAccountLawFirm(): boolean {
    return this.selectedLawFirm && this.selectedLawFirm.matterParticipant && this.selectedLawFirm.matterParticipant.isMyOwnAccountLawFirm();
  }

  getFirmSolicitorBurgerMenuItems(wrapper: MatterParticipantWrapper): any {
    let menuItems = [];

    menuItems.push(SnapshotBurgerMenuActions.DELETE);
    if (wrapper
      && wrapper.matterParticipant
      && wrapper.matterParticipant.contact) {
      wrapper.menuItemsForRevert(menuItems);
    }
    if (wrapper.isStale) {
      menuItems.push(SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
    }

    return menuItems;
  }

  getFirmSolicitorDisabledItems(wrapper: MatterParticipantWrapper) {
    let disabledMenuItems = [];
    if (wrapper
      && wrapper.matterParticipant
      && wrapper.matterParticipant.contact
      && wrapper.isLockedElsewhere) {
      wrapper.menuItemsForRevert(disabledMenuItems);
    }

    if (wrapper.isStale && wrapper.isLockedElsewhere) {
      disabledMenuItems.push(SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
    }
    return disabledMenuItems;
  }

  clickFirmSolicitorBurgerMenu(event, wrapper: MatterParticipantWrapper, isLawFirm: boolean) {
    this.logger.info('clickBurgerMenu | event:', event);
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE: {
        if (isLawFirm) {
          this.clearLawFirm();
        } else {
          this.deleteSolicitor();
        }
        break;
      }
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL: {
        this.revertClearUpdateAction(wrapper, SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
        break;
      }
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT : {
        this.revertClearUpdateAction(wrapper, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
        break;
      }

      default: {
        break;
      }
    }
  }

  revertClearUpdateAction(wrapper: MatterParticipantWrapper, action: string) {
    this.contactQueryService.getContactForMatter(wrapper.matterParticipant
      && wrapper.matterParticipant.contact
      && wrapper.matterParticipant.contact.sourceContactId)
    .subscribe((sourceContact: Contact) => {
      this.tabsService.updateIsLockedElsewhereStatus(wrapper, sourceContact);

      if (!wrapper.isLockedElsewhere) {
        switch (action) {
          case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
            this.enableSave();
            this.matterService.revertToGlobalAction(wrapper, sourceContact,
              this.mortgage.id, this.matter);
            break;
          case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
            this.updateClearLawFirmSolicitorSnapshot(wrapper, sourceContact,
              SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT);
            break;
          default:
            break;
        }
      } else {
        this.setExpanded(wrapper, true);
      }
    });

  }

  // If it is Out of Sync, it needs update first
  editAsPrivateCopyAction(wrapper: MatterParticipantWrapper) {

    // Locked this contact and set this contact is dirty
    if (wrapper
      && wrapper.matterParticipant
      && wrapper.matterParticipant.contact) {

      this.contactQueryService.getContact(wrapper.matterParticipant.contact.sourceContactId).subscribe(
        (res: Contact) => {
          if (!res.locked && !this.tabsService.isContactAlreadyLocked(wrapper.matterParticipant.contact)) {
            wrapper.lockedSourceContact = null;
            wrapper.matterParticipant.sourceContactLockAcquired = true;
            wrapper.isStale = wrapper.retrieveStaleStatus(res);
            wrapper.isClearFlagWithoutUpdatingMatter = wrapper.refreshClearFlagStatus(res);

            wrapper.matterParticipant.contact.isDirty = true;
            wrapper.isLockedElsewhere = false;

            wrapper.sourceProxyEdited = true;
          } else {
            wrapper.isLockedElsewhere = true;
            wrapper.sourceProxyEdited = res.proxyEdited;
            wrapper.sourcePrivateFlag = res.privateFlag;
            wrapper.sourceProxyForGlobal = res.proxyForGlobal;
            wrapper.lockedSourceContact = res;
            wrapper.matterParticipant.sourceContactLockAcquired = false;
          }
        });
    }
  }

  public updateClearLawFirmSolicitorSnapshot(wrapper: MatterParticipantWrapper, source: Contact, action: string): void {
    if (action === SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT) {
      wrapper.matterParticipant.contact.update(source);
      if (wrapper.matterParticipant.matterParticipantRole === 'MORTGAGE_LEGAL_FIRM') {
        this.buildLawFirmStructure(false, source);
      } else if (wrapper.matterParticipant.matterParticipantRole === 'MORTGAGE_SOLICITOR') {
        this.buildSolicitorStructure(false, source);
        this.handleSolicitorChange(wrapper.matterParticipant);
      }
    }

    this.enableSave();
  }

  deleteSolicitor(): void {
    const solicitor = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    let solicitorTitle = provinceBasedFieldLabels.get('matter.title.solicitor', this.matter.provinceCode).toLowerCase();
    let deleteConfirmationMessage = `Proceed to remove this ${ solicitorTitle }?`;
    deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, solicitor, MatterParticipantRoleTypes.MORTGAGE_SOLICITOR);
    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(res => {
      if (res) {
        this.silentDeleteSolicitor();
      }
    });
  }

  silentDeleteSolicitor(): void {
    this.enableSave();

    this.showSolicitorDropDownArrow = false;

    const solicitor = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    if (solicitor) {
      this.revokeSharedDocPackageForSolicitor(solicitor);
      if (solicitor.contact) {
        this.contactService.unlockContact(solicitor.contact.sourceContactId).subscribe((res) => {
          this.logger.info('solicitor has been unlocked now');
        });
      }
      this.matter.deleteMatterParticipant(solicitor);
      this.errorService.removeDpFieldError('mortgage.' + this.mortgageIndex + '.solicitorContact.surname');
      this.handleSolicitorChange(solicitor, true);
    }
    this.selectedSolicitor = new MatterParticipantWrapper();
    this.selectedSolicitor.editMode = true;

    const lawFirm = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
    if (lawFirm) {
      if (lawFirm && lawFirm.contact) {
        this.contactService.getSolicitorsForLawFirm(lawFirm.contact.sourceContactId, true).subscribe(
          (data: Contact[]) => {
            this.showSolicitorDropDownArrow = Array.isArray(data) && data.length > 0 ? true : false;
            // data can NOT set to this.solicitor.
            // It will show two pick lists. One is searching list, the other is drop down list if do it.
            // this.solicitors = data;
          }
        );
      }
    }
    this.initSolicitorReportAndServiceAddressDd();
  }

  revokeSharedDocPackageForSolicitor(solicitor: MatterParticipant): void {
    if (solicitor && this.documentProductionService && this.contactQueryService) {
      this.documentProductionService.tryToRevokePackage(this.matter, solicitor, this.contactQueryService);
    }
  }

  toggleSolicitorContactSnapshot(wrapper: MatterParticipantWrapper): void {
    if (wrapper.matterParticipant) {
      this.setExpanded(wrapper, !wrapper.expanded);
    }
  }

  toggleLawFirmContactSnapshot(): void {
    this.setExpanded(this.selectedLawFirm, !this.selectedLawFirm.expanded);
  }

  getLawFirmName(lawFirmWrapper: MatterParticipantWrapper): string {
    let organizationName: string;
    const LawFirmMatterParticipant: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
    if (lawFirmWrapper && lawFirmWrapper.dataModel
      && lawFirmWrapper.dataModel instanceof Contact) {
      organizationName = lawFirmWrapper.dataModel.organizationName;
    } else if (LawFirmMatterParticipant && LawFirmMatterParticipant.contact && LawFirmMatterParticipant.contact.organizationName) {
      organizationName = LawFirmMatterParticipant.contact.organizationName;

    } else {
      organizationName = null;
    }
    return organizationName;
  }

  getSolicitorName(solicitorWrapper: MatterParticipantWrapper): string {
    const solicitor = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
    let surnameLastFullName: string;
    if (solicitorWrapper && solicitorWrapper.dataModel
      && solicitorWrapper.dataModel instanceof Contact && solicitorWrapper.dataModel.contactName) {
      surnameLastFullName = solicitorWrapper.dataModel.contactName.surnameLastFullName;
    } else if (solicitor && solicitor.contact && solicitor.contact.contactName) {
      surnameLastFullName = solicitor.contact.contactName.surnameLastFullName;
    } else {
      surnameLastFullName = null;
    }

    return surnameLastFullName;
  }

  onSolicitorChange(event): void {

    if (typeof this.selectedSolicitor.dataModel === 'string' && this.selectedSolicitor.dataModel.trim()) {
      this.handleDropDownClickSolicitorPickList();
    }
  }

  /**
   * It will open a modal window to create a solicitor when it have the following ALL conditions
   *  1. A law firm exists
   *  2. No solicitor is selected
   *  3. There is at least 1 subcontact(solicitor). If there is no any subcontact,
   *     it can do search select new one and update law firm or select "Add new" item to create a new solicitor with the existing law firm
   * @param wrapper
   */
  onLeavingSolicitorOmniBarWithoutFreeInput(wrapper: MatterParticipantWrapper) {
    setTimeout(() => {
      if (wrapper && wrapper.dataModel && typeof wrapper.dataModel === 'string') {
        wrapper.dataModel = null;
      }
    }, 200);
  }

  onLeavingLawFirmOmniBarWithoutFreeInput(wrapper: MatterParticipantWrapper) {
    setTimeout(() => {
      if (wrapper && wrapper.dataModel && typeof wrapper.dataModel === 'string') {
        wrapper.dataModel = null;
        this.mortgage.mortgageContactInfo.firmId = null;
        this.mortgage.mortgageContactInfo.firmName = null;
      }
    }, 200);
  }

  isMortgageSolicitorExists(): boolean {
    return !!this.mortgageSolicitor;
  }

  get mortgageSolicitor() {
    return this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_SOLICITOR', this.mortgage);
  }

  public createSolicitorStructure(sourceContactId: number): void {
    this.contactQueryService.getContactForMatter(sourceContactId).subscribe((sourceSolicitor: Contact) => {
      this.matter.addMatterParticipant(sourceSolicitor,
        true, 'MORTGAGE_SOLICITOR', this.mortgage.id);
      this.buildSolicitorStructure(false, sourceSolicitor);
    });
  }

  clickMortgageeBurgerMenuItem(event, selectedMortgagee: MatterParticipantWrapper) {
    switch (event) {
      case SnapshotBurgerMenuActions.DELETE:
        if (this.payoutStatement && !this.payoutStatement.isCancelled()) {
          this.dialogService.confirm('ERROR', 'There is an active Payout Request associated with this Mortgagee, to delete, first cancel the Payout Request.', true);
        } else {
          this.deleteMortgagee(selectedMortgagee);
        }

        break;
      case SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT:
        this.matterParticipantService.updateMatterContactFromSourceContact(selectedMortgagee, SnapshotBurgerMenuActions.REPLACE_MATTER_WITH_SOURCE_CONTACT, this.mortgageeCallbackOnUpdateSourceContact, this.matter);
        break;
      case SnapshotBurgerMenuActions.REVERT_TO_GLOBAL:
        this.matterParticipantService.revertToGlobal(selectedMortgagee, this.mortgageeCallbackOnUpdateSourceContact);
        break;

    }
  }

  editMortgageeAsPrivateCopy(selectedMortgagee: MatterParticipantWrapper) {
    this.enableSave();
    this.matterParticipantService.editContactAsPrivateCopy(selectedMortgagee);
  }

  //If the selected mortgagee's transit # is '00000' then it show up as a matter only field (not part of the mortgagee record)
  isMortgageeTransitNumberVisibleOnMatter(): boolean {
    return this.matter.isPurchase && this.isInstitutionMortgageeSelected && this.institutionMortgagee.matterParticipant.contact.transitNo === '00000';
  }

  isPrivateLenderMortgageeType(): boolean {
    return this.mortgage.isMortgageePrivateLender();
  }

  isInstitutionMortgageeType(): boolean {
    return this.mortgage.isMortgageeAnInstitution();
  }

  isCorrespondWithSolicitor(): boolean {
    if (this.matter.isPurchase) {
      return (this.mortgage.acting === 'NO') && (this.mortgage.mortgageCorrespondenceType === 'SOLICITOR');
    } else {
      return this.mortgage.mortgageCorrespondenceType == 'SOLICITOR';
    }
  }

  // this method will enable save after change in any filed.
  enableSave(): void {
    this.notifyChange();
  }

  isLawFirmVisible(): boolean {
    return this.matter.isLawFirmVisible(this.mortgage);
    //Move the following to matter.ts
    // if(this.matter.isPurchase) {
    //     return this.isCorrespondVisible() && this.mortgage.mortgageCorrespondenceType == 'SOLICITOR';
    // } else {
    //     return this.mortgage.mortgageCorrespondenceType == 'SOLICITOR';
    // }
  }

  shouldShowCapacity(): boolean {
    return !this.isMortgageeAnInstitution() && !this.isMortgageeBlank() && this.isMoreThanOnePrivateLender() && !this.isOpportunityMatter();
  }

  isMortgageeAnInstitution(): boolean {
    return this.mortgage.isMortgageeAnInstitution();
  }

  isMortgageeBlank(): boolean {
    return this.mortgage.isMortgageeBlank();
  }

  updateLenderReLine(): void {
    this.matter.reCalculateLenderReLine(this.mortgage);
  }

  notifyChange(dataUpdateAttribute?: string): void {
    this.onChange.emit({dataUpdateAttribute: dataUpdateAttribute});
  };

  isActingVisible(): boolean {
    return this.mortgage.loanType == 'ARRANGED';
  }

  isInsuredMortgageVisible(): boolean {
    return this.mortgage.loanType == 'ARRANGED';
  }

  isCorrespondVisible(): boolean {
    return this.isActingVisible() && this.mortgage.acting == 'NO';
  }

  markUndertakingDirty(): void {
    this.mortgage.undertakingDirty = true;
  }

  setSolicitorAddressDd(firmContact: Contact) {
    if (firmContact) {
      this.solicitorAddressDdOptions = firmContact.createSolicitorAddressDdOptions(firmContact);
    } else {
      const contact: Contact = new Contact();
      this.solicitorAddressDdOptions = contact.createSolicitorAddressDdOptions(null);
    }

  }

  get isSelectedSolicitorInEditMode(): boolean {
    return this.selectedSolicitor && this.selectedSolicitor.editMode;
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD_MORTGAGEE) != -1;
  }

  onTitleDetailsChange(): void {
    this.enableSave();
  }

  get capacityTabLabel(): string {
    if (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceNBorNS) {
      return 'Tenure/Share';
    } else {
      return 'Capacity';
    }
  }

  anyPrivateLender(): boolean {
    return this.matterPrivateLenders && this.matterPrivateLenders.length && this.matterPrivateLenders[ 0 ] && !!this.matterPrivateLenders[ 0 ].createdOrSelected;
  }

  getBurgerMenuItemsForMatterParticipant(mp: MatterParticipantWrapper): string[] {
    //The "RevertToGlobal" option shouldn't show up for orphan mortgagees too.
    if (mp.matterParticipant.isMortgagee && this.account && this.account.showPrivateMortgageesOnly == 'YES') {
      // need to check if showPrivateMortgageesOnly is set to yes and remove 'Revert to Global' from burger menu
      let burgerMenuItems: string[] = mp.getBurgerMenuItemsForMatterParticipant();
      let revertToGlobal = burgerMenuItems.find(item => item == SnapshotBurgerMenuActions.REVERT_TO_GLOBAL);
      if (revertToGlobal) {
        (<any>burgerMenuItems).remove(revertToGlobal);
      }
      return burgerMenuItems;
    } else {
      return mp.getBurgerMenuItemsForMatterParticipant();
    }

  }

  getShallowAccount() {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.accountService.getShallowAccount(accountId.toString()).subscribe((account: Account) => {
      this.account = new Account(account);
    });
  }

  isMassUpdateTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isMassUpdateSubType();
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgage: Mortgage) {
    this.matter = matter;
    this.mortgage = mortgage;
  }

  propagateAddMortgageeChanges(contact: Contact, mortgage: Mortgage): void {
    this.setFirstMortgageeMyClientFlag();
    if (mortgage && !this.isOpportunityMatter()) {
      this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(mortgage);
      this.matter.reCalculateLenderReLine(mortgage);
      this.matter.updateMortgageeInterestOnNotedPolicy(mortgage);
      this.lenderFormsService.loadLenderForms(contact.lenderNames, this.matter.provinceCode);
      if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && mortgage.mortgageTerm) {
        mortgage.mortgageTerm.sctFiledBy = mortgage.lenderReline;
      }
    }
  }

  propagateCirfAddMortgageeChanges(contact: Contact, mortgage: Mortgage, targetMatter: Matter): void {
    this.matter = targetMatter;
    this.propagateAddMortgageeChanges(contact, mortgage);
  }

  propagateRemoveMortgageeChanges(mortgage: Mortgage): void {
    if (this.matter.fireInsuranceContactInfo) {
      this.matter.fireInsuranceContactInfo.mortgageSelected = '';
    }
    this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(mortgage);

    this.matter.reCalculateLenderReLine(mortgage);
    this.matter.updateMortgageeInterestOnNotedPolicy(mortgage);
    if ((this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) && mortgage.mortgageTerm) {
      mortgage.mortgageTerm.sctFiledBy = mortgage.lenderReline;
    }
    if (mortgage.mortgageTerm) {
      mortgage.mortgageTerm.sctNo = null;
    }
    mortgage.undertakingDirty = true;
  }

  propagateCirfRemoveMortgageeChanges(mortgage: Mortgage, targetMatter: Matter): void {
    this.matter = targetMatter;
    this.propagateRemoveMortgageeChanges(mortgage);
  }

  showIdWarningForParticipant(participant: MatterParticipant): boolean {
    return participant && participant.contact && (!participant.contact.enteredOn || !participant.contact.idInfoEnteredBy);
  }

  onClickPrintIdForms() {
    this.matterService.callOperationAfterMatterSaved(this.matter, () => {
      let participantList: MatterParticipant[] = this.matter.getParticpantForPrintingIdVerification();
      this.openPrintIdFormsModal(this.matter.id, participantList);
    }, true);
  }

  isExistingMortgage(): boolean {
    return this.mortgage && this.mortgage.isExistingMortgage();
  }

  handleSolicitorChange(mp: MatterParticipant, isDeleted?: boolean) {
    if (this.matter && mp) {
      this.contactChangesListener.handleContactChange(this.matter, mp, MatterParticipantRoleTypes.MORTGAGE_SOLICITOR, this.tabsService, isDeleted);
    }
  }

  handleContactChange(participantWrapper: MatterParticipantWrapper, isDeleted?: boolean, checkForChildren?: boolean): void {
    if (this.matter && participantWrapper && participantWrapper.matterParticipant && participantWrapper.matterParticipant.contact) {
      this.contactChangesListener.handleContactChange(this.matter, participantWrapper.matterParticipant, participantWrapper.matterParticipant.matterParticipantRole, this.tabsService, isDeleted, null, checkForChildren);
    }
  }

  isOpportunityMatter(): boolean {
    return this.matter && this.matter.isOpportunityMatter();
  }

  getDeceasedString(privateLender) {
    return (privateLender && privateLender.id) ? privateLender.getDeceasedString() : '';
  }

  isPayOutVisible(): boolean {
    return MatterUtil.isPayOutVisible(this.matter, this.mortgage, this.payoutStatement, this.authZService, this.appConfig);
  }

  saveMatterBeforeOpenPayoutModal(): void {
    this.matterService.callOperationAfterMatterSaved(this.matter, this.openPayoutRequestModal, true);
  }

  initiatePayoutModal(): void {
    this.assystPayoutMessages = [];
    this.matter.openAssystPayoutModalAfterSave = false;
    this.assystPayoutMessages = this.matterService.extractMessagesOnPayoutDataChange(this.matter, this.mortgage.id);
    if (this.payoutStatement && (!this.payoutStatement.isCancelled() || this.payoutStatement.isReturnedToSolicitor())) {
      //Cancel the payout
      this.saveMatterBeforeOpenPayoutModal();
    } else {
      //Request a payout
      this.validateAndInitiatePayoutRequestModal();
    }
  }

  async validateAndInitiatePayoutRequestModal(): Promise<void> {
    if (!this.solicitorAssystUser) {
      this.solicitorAssystUser = await this.retrieveSolicitorStewartUser().toPromise();
    }
    if (this.payoutValidation(this.solicitorAssystUser)) {
      this.saveMatterBeforeOpenPayoutModal();
    } else {
      this.errorService.openFooterNotification(DpFooterTypes.THIRDPARTY);
    }
  }

  payoutValidation(solicitorAssystUser: StewartAssystUser): boolean {

    // we need this step for the unlikely scenario where user does not open TabD first therefore province might not be populated at this point
    if (this.matter && this.matter.matterProperties && this.matter.matterProperties.length && this.matter.matterProperties[ 0 ].address &&
      (this.matter.matterProperties[ 0 ].address.provinceName == undefined || this.matter.matterProperties[ 0 ].address.provinceName == null)) {
      AddressUtil.setDefaultProvinceValue(this.matter.matterProperties[ 0 ].address, true,
        this.documentProfileCache && this.documentProfileCache.cachedDocumentProfile, this.matter && this.matter.provinceCode, {
          localProvinceName: null,
          cachedDefaultProvinceName: null
        });
    }
    this.telusAssystDataValidationService.validateForPayoutStatement(this.matter, this.mortgage, solicitorAssystUser);
    return !this.errorService.hasAnyMissingFieldErrors();
  }

  retrieveSolicitorStewartUser(): Observable<StewartAssystUser> {
    let solicitorParticipant: MatterParticipant = this.matter.solicitor;
    if (solicitorParticipant && solicitorParticipant.contact) {
      return this.telusService.getTelusUserForSolicitor(solicitorParticipant.contact.id)
      .catch(e => {
        return Observable.of(null);
      });
    } else {
      return Observable.of(null);
    }
  }

  async fetchHistoricalPayoutStatementsBeforeOpenModal(callback: Function): Promise<boolean> {
    this.historicalPayoutStatements = await this.telusService.fetchPayoutStatements(this.matter.id, this.mortgage.id).toPromise();
    if (this.historicalPayoutStatements) {
      callback();
      return true;
    } else {
      return false;
    }
  }

  openPayoutStatementHistory(): void {
    this.fetchHistoricalPayoutStatementsBeforeOpenModal(this.openPayoutStatementHistoryModal);
  }

  isImportDataApplicable(payoutStatement: PayoutStatement): boolean {
    return payoutStatement && payoutStatement.getMostRecentActiveDocument() && this.appConfig.isImportPayoutDataEnabled &&
      (payoutStatement.getMostRecentActiveDocument().isReadyToImport());
  }

    openImportPayoutModal():void{
        this.dialogService.matDialogContent({
            content       : AssystImportPayoutModalComponent,
            context: {
                mortgage: this.mortgage,
                matter : this.matter,
                payoutStatement: this.payoutStatement
            },
            onFulfillment : (result) => {
                if(result.action == 'OK') {
                    this.updateMortgagePayoutData(result);
                    this.dialogService.confirm('INFORMATION', 'Unity® Lender Centre Data Imported', true);
                }else if(result.action == 'PARSING_ERROR'){
                    this.dialogService.confirm('ERROR', 'Unity was unable to automatically import information from the payout statement. Please manually enter the payout details.', true);
                }
            }
        });
    }

  updateMortgagePayoutData(result: any) {
    let payoutStatementStatus = this.payoutStatement.getLatestPayoutStatementStatus();
    if (!this.mortgage.mortgagePayout) {
      this.mortgage.mortgagePayout = new MortgagePayout();
    }
    if (payoutStatementStatus.isDischargeRegistered()) {
      this.mortgage.dischargeRegisteredBy = dischargeRegisteredByOptions.find(item => item.value === 'MORTGAGEE').value;
      let undertaking: MatterUndertaking = this.matter && this.matter.matterUndertakings.find(undertaking => undertaking.id == this.mortgage.undertakingId);
      if (undertaking) {
        undertaking.matterUndertakingStatus = 'FULFILLED';
        undertaking.dischargedByInstrumentNo = this.payoutStatement.registrationNumbers.toString();
        undertaking.registeredDate = this.payoutStatement.registrationDate;
      }
    } else {
      if (result.assystPayoutData) {
        let payoutData: AssystPayoutData = result.assystPayoutData;
        this.mortgage.mortgagePayout.principalAmount = payoutData.totalPayoutAmount;
        this.mortgage.mortgagePayout.useMortgagePayoutCalculator = true;
        this.mortgage.mortgagePayout.interestPerDay = payoutData.dailyAmountOfInterest;
        if (!this.mortgage.isCollateralMortgage()) {
          this.mortgage.mortgagePayout.principalDate = this.matter.matterCloseDate;
          if (!payoutData.totalPayoutAmount) {
            this.mortgage.mortgagePayout.principalAmount = payoutData.projectedTotalPayoffAmount;
          }
        } else {
          this.mortgage.mortgagePayout.principalDate = moment(payoutStatementStatus.statusReceivedTimestamp).format('YYYY/MM/DD');
        }
      }
    }
    this.notifyChange('PayoutUpdated');
  }

  openPayoutRequestModal = (): void => {
    this.dialogService.matDialogContent({
      content: AssystPayoutRequestModalComponent,
      context: {
        mortgage: this.mortgage,
        matter: this.matter,
        payoutStatement: this.payoutStatement
      },
      onFulfillment: (result) => {
        if (this.assystPayoutMessages.length > 0) {
          this.matterService.validateAndOpenAssystModal(this.assystPayoutMessages);
        }
        if (result) {
          this.payoutStatementRequested.emit({payoutStatement: result});
          //updating the latest payout Statement status to mortgage
          this.mortgage.payoutStatement = result;
        }
        this.assystPayoutMessages = [];
        this.matter.openAssystPayoutModalAfterSave = this.matterService.isAssystPayoutApplicableForMatter(this.matter);
        if (result && !!this.isOpenedFromWizard) {
          let wizardTab: Tab = this.tabsService.openTabs.find((t: Tab) => {
            return t.equals((this.tabsService.activeTab as WizardTab));
          });
          let matter: Matter = (this.tabsService.activeTab as WizardTab).matter;
          this.parentTabsComponent.closeTab(wizardTab, undefined);
          let section: Section = {title: 'Existing Mortgage(s)', route: 'existingMortgages/mortgage/0', active: true};
          this.parentTabsComponent.openMatterTab(matter, section);
        }
      }
    });
  };

  openPayoutStatementHistoryModal = (): void => {
    this.dialogService.matDialogContent({
      content: AssystPayoutHistoryModalComponent,
      context: {
        mortgage: this.mortgage,
        matter: this.matter,
        payoutStatements: this.historicalPayoutStatements
      },
    });
  };

  isDocAssociatedWithLatestPayoutStatementStatus(payoutStatement: PayoutStatement): boolean {
    return payoutStatement.getMostRecentActiveDocument() && !!payoutStatement.getMostRecentActiveDocument().thirdPartyDocumentId && payoutStatement.getMostRecentActiveDocument().isPaperClipIconAvailable();
  }

  downloadDocAssociatedWithLatestStatus(payoutStatement: PayoutStatement) {
    let latestPayoutStatementStatus: PayoutStatementStatus = payoutStatement.getMostRecentActiveDocument();
    if (latestPayoutStatementStatus && latestPayoutStatementStatus.thirdPartyDocumentId) {
      this.teranetService.downloadTeranetDocumentAndDisplay(this.matter.id, latestPayoutStatementStatus.thirdPartyDocumentId);
    }

  }

  get payoutStatementButtonLabel() {
    return this.payoutStatement && !this.payoutStatement.isCancelled() && !this.payoutStatement.isReturnedToSolicitor() ? 'Cancel Payout Statement' : 'Request Payout Statement';
  }

  isMyClientVisible(): boolean {
    return this.matter && this.matter.isMortgageMatterAndMortgageePrimary() && !this.isExistingMortgage();
  }

  updateMortgageeMyClientFlag(isMyClient: boolean): void {
    if (isMyClient) {
      MatterUtil.setFirstMortgageeMyClientFlag(this.matter);
    }
  }

  setFirstMortgageeMyClientFlag(): void {
    if (this.matter && this.matter.getNumberOfMortgageesAndPrivateLendersCanBeMyClient() == 1) {
      MatterUtil.setFirstMortgageeMyClientFlag(this.matter);
    }
  }

  get privateLenderAction() {
    if (this.isBCSaleDischargeMatter() || this.isBCPurchaseOpportunity() || this.isBCMortgageOpportunity() || this.matter?.isMatterProvinceBC) {
      if (this.hasPrivateLenders()) {
        return 'Add another Lender';
      } else {
        return 'Add from Lender';
      }
    } else {
      if (this.hasPrivateLenders()) {
        return 'Add another Mortgagee';
      } else {
        return 'Add from Mortgagee';
      }
    }
  }

  isBCSaleDischargeMatter() {
    return (this.userStateService.isDefaultProvinceBC() && (this.matter.isSaleOpportunity || this.matter.isDischargeOpportunity));
  }

  isBCPurchaseOpportunity() {
    return (this.userStateService.isDefaultProvinceBC() && (this.matter.isPurchaseOpportunity));
  }

  isBCMortgageOpportunity() {
    return (this.userStateService.isDefaultProvinceBC() && (this.matter.isMortgageOpportunity));
  }

  getMortgageeHeaderLabel(): string {
    if (!this.matter?.isMatterProvinceBC) {
      return this.getMortgageeLabel();
    }
    if ('CHARGE' === this.mortgageType && (this.matter?.isSaleBC || this.matter?.isMortgageBC)) {
      return 'Registered Owner(s)';
    } else {
      return provinceBasedFieldLabels.get('matter.mortgage.mortgagee', this.matter?.provinceCode);
    }
  }

  getMortgageeLabel(): string {
    return provinceBasedFieldLabels.get('matter.mortgageDetail.mortgagees.label', this.matter?.provinceCode);
  }
}
