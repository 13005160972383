import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {LateClosingInterest, PrincipalAmountTypeForLateInterest} from './late-closing-interest';
import {MortgageUtil} from '../../shared-main/mortgage-utility';
import Utils from '../../shared-main/utils';
import moment from 'moment';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import * as _ from 'lodash';

export class ClosingDatePayment extends BaseEntity {
  id: number;
  cashToClose: number;
  sameAsSoa: boolean;
  delayedAmount: number;
  delayedAmountSameAsTabG: boolean;
  cashDifference: number;
  autoCalculate: boolean;
  registeredMortgageAmount: number;
  registeredMortgageAmountSameAsTabG: boolean;
  lateClosing: string; // Backend needs to changes this to string
  lateClosingDate: string;
  lateClosingInterests: LateClosingInterest[];
  daysToCalculate: string;
  lateInterestAmountTotal: number;
  lateInterestDescription: string;
  interestEstimateDays: number;
  interestEstimateAmount: number;
  differenceAmount: number;
  matterId: number;

  constructor(closingDatePayment?: ClosingDatePayment) {
    super(closingDatePayment);
    if (closingDatePayment) {
      this.lateClosingInterests = [];
      if (closingDatePayment.lateClosingInterests) {
        //Bug28022, since svr side use Set to store/save, the returned order is random, need to sort by the fromDate asc
        const sorted: LateClosingInterest[] = _.orderBy(closingDatePayment.lateClosingInterests, [ 'fromDate' ], [ 'asc' ]);
        for (let lateClosingInterest of sorted) {
          this.lateClosingInterests.push(new LateClosingInterest(lateClosingInterest));
        }
      }
    } else {
      this.sameAsSoa = true;
      this.delayedAmountSameAsTabG = true;
      this.autoCalculate = false;
      this.registeredMortgageAmountSameAsTabG = false;
      this.cashToClose = 0;
      this.delayedAmount = 0;
      this.cashDifference = 0;
      this.registeredMortgageAmount = 0;
      this.lateClosing = 'N_y';
      this.lateClosingInterests = [];
      this.interestEstimateDays = 31;
    }
  }

  isLateClosing(): boolean {
    return this.lateClosing == 'YES';
  }

  isLateClosingDateValid(): boolean {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    return pattern.test(this.lateClosingDate);
  }

  updateFromAndToDateforLateClosingInterest(lateClosingInterest: LateClosingInterest): void {
    if (lateClosingInterest) {
      let lastClosingInterestIndex = this.lateClosingInterests.findIndex(item => item.identifier == lateClosingInterest.identifier);
      if (lastClosingInterestIndex > 0) {
        let previousLastClosingInterset = this.lateClosingInterests[ lastClosingInterestIndex - 1 ];
        if (previousLastClosingInterset) {
          let previousDate = moment(lateClosingInterest.fromDate, 'YYYY/MM/DD').subtract(1, 'days');
          if (previousDate) {
            previousLastClosingInterset.toDate = moment(previousDate).format('YYYY/MM/DD');
            lateClosingInterest.toDate = this.lateClosingDate;
          }

        }

      }

    }

  }

  calculateInterest(lateClosingInterest: LateClosingInterest, provinceCode: ProvinceCode = 'ON'): void {
    if (lateClosingInterest) {
      let lateClosingInterestAmount = this.getPrincipalAmount(lateClosingInterest);
      const closingDateIsSameAsToDate: boolean = this.lateClosingDate == lateClosingInterest.toDate;//moment(this.lateClosingDate, "YYYY/MM/DD").isSame(moment(lateClosingInterest.toDate));
      if ([ 'MB', 'SK' ].indexOf(provinceCode) > -1 && !Utils.isValidDate(this.lateClosingDate)) {
        lateClosingInterest.lateInterestAmount = 0;
      } else {
        lateClosingInterest.lateInterestAmount = MortgageUtil.calculateInterest(lateClosingInterest.fromDate, lateClosingInterest.toDate, lateClosingInterestAmount, lateClosingInterest.interestRate, !closingDateIsSameAsToDate);
      }
    }

  }

  getPrincipalAmount(lateClosingInterest: LateClosingInterest): number {
    if (lateClosingInterest.isPrincipalAmountDelayedAmount()) {
      return this.delayedAmount;
    } else if (lateClosingInterest.isPrincipalAmountCashToCloseAmount()) {
      return this.cashToClose;
    } else if (lateClosingInterest.isPrincipalAmountRegMortgagteAmount()) {
      return this.registeredMortgageAmount;
    } else {
      return 0;
    }
  }

  updateLateInterestAmountTotal(provinceCode: ProvinceCode = 'ON'): void {
    if (this.hasLateClosingInterests()) {
      if ([ 'MB', 'SK' ].indexOf(provinceCode) > -1 && !Utils.isValidDate(this.lateClosingDate)) {
        this.lateInterestAmountTotal = null;
      } else {
        this.lateInterestAmountTotal = this.lateClosingInterests.reduce(function (accumulator, lateClosingInterest) {
          return accumulator + lateClosingInterest.lateInterestAmount;
        }, 0);
      }
    } else {
      this.lateInterestAmountTotal = null;
    }
  }

  hasLateClosingInterests(): boolean {
    return Array.isArray(this.lateClosingInterests) && this.lateClosingInterests.length > 0;
  }

  isClosingDateValid(matterClosingDate: string): boolean {
    let pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
    return pattern.test(matterClosingDate);
  }

  isClosingDateAvailable(matterClosingDate: string): boolean {
    return (matterClosingDate && !matterClosingDate.startsWith('/') && !matterClosingDate.endsWith('/') && matterClosingDate.indexOf('//') < 0);
  }

  getLateInterestDescription(matterClosingDate: string, provinceCode: ProvinceCode = 'ON'): void {
    let lateInterestDescription: string;
    if (!this.isClosingDateAvailable(matterClosingDate) || !this.isClosingDateValid(matterClosingDate)) {
      lateInterestDescription = '***Incomplete Closing Date in Tab "A"';
    } else if (matterClosingDate && this.lateClosingDate && (moment(this.lateClosingDate, 'YYYY/MM/DD').isSameOrBefore(moment(matterClosingDate, 'YYYY/MM/DD')))) {
      switch (provinceCode) {
        case 'MB':
        case 'SK':
          lateInterestDescription = '***Invalid Closing Date ,  The delayed closing date must be after ' + moment(matterClosingDate).format('MMM DD, YYYY');
          break;
        case 'ON':
        case 'AB':
        default:
          lateInterestDescription = '***Invalid Closing Date ,  The late closing date must be after ' + moment(matterClosingDate).format('MMM DD, YYYY');
      }

    } else {

      let lateInterestDesc: string = '';
      this.lateClosingInterests.forEach((item, index) => {

        //bug28090, date value should not include leading zero for day value, and fix the format issue
        lateInterestDesc = 'Interest from ' + (item.fromDate ? moment(item.fromDate).format('MMM D/YY') : '');
        lateInterestDesc += (item.toDate ? '-' + moment(item.toDate).format('MMM D/YY') + ': ' : '');
        const closingDateIsSameAsToDate: boolean = (this.lateClosingDate == item.toDate);
        lateInterestDesc = lateInterestDesc + item.getClosingDateDifference(closingDateIsSameAsToDate) + ' @ ' + item.interestRate + '%';
        lateInterestDesc = lateInterestDesc + '\t' + Utils.formattedCurrencyValue(item.lateInterestAmount);
        lateInterestDescription = index == 0 ? lateInterestDesc : (lateInterestDescription + '\n' + lateInterestDesc);
      });
    }

    this.lateInterestDescription = lateInterestDescription;
  }

  reCalculateDelayedInterest(matterClosingDate: string, provinceCode: ProvinceCode = 'ON'): void {
    if (this.lateClosingInterests) {
      this.lateClosingInterests.forEach(item => {
        this.calculateInterest(item, provinceCode);
      });
      this.updateLateInterestAmountTotal(provinceCode);
      this.updateEstimateInterestAmt();
      this.getLateInterestDescription(matterClosingDate, provinceCode);

    }
  }

  updateEstimateInterestAmt(): void {
    this.interestEstimateAmount = this.getEstimatedInterest();
  }

  getEstimatedInterest(): number {
    if (Array.isArray(this.lateClosingInterests) && this.lateClosingInterests.length > 0) {
      const lastLateClosingInterest: LateClosingInterest = this.lateClosingInterests[ this.lateClosingInterests.length - 1 ];
      const estimateInterest: number = (Number(this.interestEstimateDays) * (Number(lastLateClosingInterest.interestRate / 100) / 365) * Number(this.getLastLateInterestAmt()));
      return isNaN(estimateInterest) ? 0 : Number(estimateInterest.toFixed(2));
    }
    return 0;
  }

  getLastLateInterestAmt(): number {
    let lastLateInterestAmt: number = 0;
    if (Array.isArray(this.lateClosingInterests) && this.lateClosingInterests.length > 0) {
      const lastLateClosingInterest: LateClosingInterest = this.lateClosingInterests[ this.lateClosingInterests.length - 1 ];
      switch (lastLateClosingInterest.principalAmount) {
        case PrincipalAmountTypeForLateInterest.delayedAmountOptionText:
          lastLateInterestAmt = this.delayedAmount;
          break;
        case PrincipalAmountTypeForLateInterest.cashToCloseOptionText:
        case PrincipalAmountTypeForLateInterest.balanceToCloseOptionText:
          lastLateInterestAmt = this.cashToClose;
          break;
        case PrincipalAmountTypeForLateInterest.registeredMortgageOptionText:
          lastLateInterestAmt = this.registeredMortgageAmount;
          break;
      }
    }
    return lastLateInterestAmt;
  }

  removeLateClosingInterest(isLateClosingInterestItemAvailableIndex: number): void {
    if (isLateClosingInterestItemAvailableIndex == 0) {
      this.lateClosingInterests.splice(isLateClosingInterestItemAvailableIndex, 1);
    } else if (this.lateClosingInterests.length > 0
      && this.lateClosingInterests.length == isLateClosingInterestItemAvailableIndex + 1) {
      let prevElement = this.lateClosingInterests[ isLateClosingInterestItemAvailableIndex - 1 ];
      if (prevElement) {
        prevElement.toDate = this.lateClosingInterests[ isLateClosingInterestItemAvailableIndex ].toDate;
      }
      this.lateClosingInterests.splice(isLateClosingInterestItemAvailableIndex, 1);
    } else {
      let prevIndex = isLateClosingInterestItemAvailableIndex - 1;
      let prevElement = prevIndex >= 0 ? this.lateClosingInterests[ prevIndex ] : undefined;
      let nextIndex = isLateClosingInterestItemAvailableIndex + 1;
      let nextElement = nextIndex < this.lateClosingInterests.length ? this.lateClosingInterests[ nextIndex ] : undefined;
      if (prevElement && nextElement) {
        prevElement.toDate = moment((moment(nextElement.fromDate, 'YYYY/MM/DD').subtract(1, 'day'))).format('YYYY/MM/DD');
      }
      this.lateClosingInterests.splice(isLateClosingInterestItemAvailableIndex, 1);
    }
  }

  onLateClosingDateChange(provinceCode: ProvinceCode = 'AB'): void {
    if ([ 'MB', 'SK' ].indexOf(provinceCode) > -1) {
      if (!Utils.isValidDate(this.lateClosingDate)) {
        return;
      }
    }
    if (this.lateClosingInterests && this.lateClosingInterests.length > 0) {
      let lastElement = this.lateClosingInterests[ this.lateClosingInterests.length - 1 ];
      lastElement.toDate = this.lateClosingDate;
      if (moment(this.lateClosingDate, 'YYYY/MM/DD').isBefore(moment(lastElement.fromDate, 'YYYY/MM/DD'))) {
        let deleteIndex = 0;
        let maxLateClosingElementIndex = this.lateClosingInterests.findIndex(item => moment(this.lateClosingDate, 'YYYY/MM/DD').isBefore(moment(item.fromDate, 'YYYY/MM/DD')));
        if (maxLateClosingElementIndex > -1) {
          deleteIndex = maxLateClosingElementIndex;
          if (maxLateClosingElementIndex > 0) {
            let prevElement = this.lateClosingInterests[ maxLateClosingElementIndex - 1 ];
            prevElement.toDate = this.lateClosingDate;
          }
          if (deleteIndex > -1) {
            this.lateClosingInterests.splice(deleteIndex, this.lateClosingInterests.length);
          }
        }
      }
    }
  }

  onMatterClosingDateChange(closingDate: string): void {
    if (this.lateClosingInterests && this.lateClosingInterests.length > 0) {
      let firstElement = this.lateClosingInterests[ 0 ];
      if (moment(closingDate, 'YYYY/MM/DD').isAfter(moment(firstElement.fromDate, 'YYYY/MM/DD'))) {
        let deleteIndex = this.lateClosingInterests.length;
        let maxLateClosingElementIndex = this.lateClosingInterests.findIndex(item => moment(closingDate, 'YYYY/MM/DD').isBefore(moment(item.fromDate, 'YYYY/MM/DD')));
        if (maxLateClosingElementIndex > 0) {
          deleteIndex = maxLateClosingElementIndex;
          let prevElement = this.lateClosingInterests[ maxLateClosingElementIndex - 1 ];
          if (moment(closingDate, 'YYYY/MM/DD').isSameOrBefore(moment(prevElement.toDate, 'YYYY/MM/DD'))) {
            prevElement.fromDate = closingDate;
            deleteIndex = maxLateClosingElementIndex - 1;
          }

        } else {
          let lastElement = this.lateClosingInterests[ this.lateClosingInterests.length - 1 ];
          if (moment(closingDate, 'YYYY/MM/DD').isSameOrBefore(moment(lastElement.toDate, 'YYYY/MM/DD'))) {
            lastElement.fromDate = closingDate;
            deleteIndex = this.lateClosingInterests.length - 1;
          }
        }
        if (deleteIndex > -1) {
          this.lateClosingInterests.splice(0, deleteIndex);

        }
      }

    }
  }
}
