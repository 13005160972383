import {Component, Inject} from '@angular/core';
import {MatterListState} from '../../shared/matter-list-state';
import {SelectItem} from 'primeng/api';
import {dropDowns, Utils as SharedUtils} from '../../shared';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {MatterService} from '../../matter.service';
import {provinceBasedLawyerTitleInPlural} from '../../../shared-main/province-based-dropdowns';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class MatterAdditionalFiltersModalContext {
  matterListState: MatterListState;
  lawyerTitle: string;
  lawClerkTitle: string;
  accountAccessibleProvinces: string[];
  provinceCode: any[];
  isMassUpdate: boolean;
  actionRequiredFilters: any[];
  defaultProvinceCode: ProvinceCode;
}

@Component({
  selector: 'dp-matter-additional-filters-modal-content',
  templateUrl: 'matter-additional-filters.modal.component.html',
  styleUrls: ['./matter-additional-filters.modal.component.scss']
})
export class MatterAdditionalFiltersModalComponent extends ModalComponent<MatterAdditionalFiltersModalContext> {
  isMatterListStateUpdates: boolean;
  isProvinceFilterUpdated: boolean;
  taskStatusFilters: SelectItem[] = dropDowns.taskStatusFilters;
  taskSuggestions: any;
  tasksLoading = false;
  autoCompleteEmptyFlag: any;
  searchTaskStream = new Subject<string>();
  taskSearchText: string;

  constructor(
    public dialog: MatDialogRef<MatterAdditionalFiltersModalComponent>,
    public matterService: MatterService,
    @Inject(MAT_DIALOG_DATA) context?: MatterAdditionalFiltersModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.initTaskSearch();
  }

  initTaskSearch(): void {
    // AutoComplete
    this.searchTaskStream = SharedUtils.refreshSubject(this.searchTaskStream);
    this.searchTaskStream
      .switchMap((term: string) => {
        this.tasksLoading = true;
        if (term.trim() === '') {
          this.autoCompleteEmptyFlag = true;
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
          return observable;
        } else {
          this.autoCompleteEmptyFlag = false;
          return this.matterService.searchTaskNames(term);
        }
      })
      .subscribe(
        (data: any) => {
          this.tasksLoading = false;
          this.taskSuggestions = data;
        },
        (error) => {
          this.tasksLoading = false;
        }
      );
  }

  ok() {
    this.dialog.close({
      matterListState: this.matterListState,
      isMatterListStateUpdates: this.isMatterListStateUpdates,
      isProvinceFilterUpdated: this.isProvinceFilterUpdated
    });
  }

  close() {
    this.dialog.close();
  }

  get matterListState(): MatterListState {
    return this.context.matterListState;
  }

  get lawyerTitle(): string {
    return this.context.lawyerTitle;
  }

  get lawyerTitleInPlural(): string {
    return 'Multiple ' + provinceBasedLawyerTitleInPlural[this.context.defaultProvinceCode];
  }

  get lawClerkTitle(): string {
    return this.context.lawClerkTitle;
  }

  updateMultiSelectFilters(obj: any[], searchType: string): void {
    switch (searchType) {
      case 'province':
        this.matterListState.selectedProvinceCode = obj;
        this.isProvinceFilterUpdated = true;
        break;
      case 'lawyer':
        this.matterListState.selectedLawyers = obj;
        break;
      case 'clerk':
        this.matterListState.selectedClerks = obj;
        break;
      case 'action_required':
        this.matterListState.selectedActionRequiredFilters = obj;
        break;
      case 'projects':
        this.matterListState.selectedProjects = obj;
        break;
    }
    this.isMatterListStateUpdates = true;
  }

  changeTaskRelatedFilter(filter: SelectItem): void {
    if (this.matterListState.taskStatusFilter == filter.value) {
      this.matterListState.taskStatusFilter = '';
      this.matterListState.taskDescription = '';
    } else {
      this.matterListState.taskStatusFilter = filter.value;
    }
    this.isMatterListStateUpdates = true;
  }

  searchTasks(event): void {
    let entered: string = event.query;
    this.searchTaskStream.next(entered);
  }

  dataSelectedTask(selectedTask: any): void {
    this.isMatterListStateUpdates = true;
    this.matterListState.taskDescription = selectedTask;
    console.log(selectedTask);
  }

  omniSearchFocusOut(): void {
    setTimeout(() => {
      this.isMatterListStateUpdates = true;
      if (!this.matterListState.taskDescription) {
        this.matterListState.taskDescription = this.taskSearchText;
      }
    }, 100);
  }

  removeSelectedTask(): void {
    this.isMatterListStateUpdates = true;
    this.matterListState.taskDescription = '';
  }
}
