import {DropdownModel} from './../../../../shared-main/dropdown-model';
import {Address, Telephone, TelephoneTypes} from '../../../../matters/shared';
import * as _ from 'lodash';
import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';

export class DocumentProfileLawFirm extends BaseEntity {

  constructor(documentProfileLawFirm?: DocumentProfileLawFirm) {
    super(documentProfileLawFirm);
    if (documentProfileLawFirm) {
      for (let prop in documentProfileLawFirm) {
        if (documentProfileLawFirm.hasOwnProperty(prop)) {
          this[ prop ] = documentProfileLawFirm[ prop ];
        }
      }
      if (documentProfileLawFirm.address != null) {
        this.address = new Address(documentProfileLawFirm.address);
      }

      if (documentProfileLawFirm.courierAddress != null) {
        this.courierAddress = new Address(documentProfileLawFirm.courierAddress);
      }

      this.telephones = [];
      if (documentProfileLawFirm.telephones) {
        for (let j: number = 0; j < documentProfileLawFirm.telephones.length; j++) {
          this.telephones[ j ] = new Telephone(documentProfileLawFirm.telephones[ j ]);
        }
      }
      if (!this.solePractitionerTypeCode) {
        this.solePractitionerTypeCode = 'NO';
      }
    }
  }

  id: number;

  sameAsDefaultProfileFlag: boolean;

  sameAsAccountFlag: boolean;
  firmName: string;
  solePractitionerTypeCode: string;

  jurisdictionName: string;
  jurisdictionId: number;
  cityOf: string;
  cityLocatedInThe: string;
  municipalityOf: string;
  municipalityInThe: string;
  docPreparedByLine1: string;

  docPreparedByLine2: string;
  docPreparedByLine3: string;
  docPreparedByLine4: string;
  docPreparedByLine5: string;
  docPreparedByLine6: string;

  lttPreparedByLine1: string;
  lttPreparedByLine2: string;

  hstRegNo: string;

  address: Address;

  //used for instrument courier requests
  courierAddress: Address;
  deliveryInstructions: string;

  // phone: Telephone;

  telephones: Telephone[];

  trustLetterFormat: string;

  logoFileName: string;

  logoFileId: number;

  isEmpty(): boolean {
    let hasData: boolean = this.address && !this.address.addressHasNoManuallyEnteredData();
    hasData = hasData || !!this.firmName || !!this.jurisdictionName || !!this.jurisdictionId || !!this.cityOf || !!this.cityLocatedInThe;
    hasData = hasData || !!this.docPreparedByLine1 || !!this.docPreparedByLine2 || !!this.docPreparedByLine3 || !!this.docPreparedByLine4 || !!this.docPreparedByLine5 || !!this.docPreparedByLine6;
    hasData = hasData || !!this.lttPreparedByLine1 || !!this.lttPreparedByLine2 || !!this.hstRegNo;
    hasData = hasData || !!this.phone || !!this.fax;
    return !hasData;
  }

  get phone(): string {

    let workPhone: Telephone = _.find(this.telephones, (telephone: Telephone) => {
      return telephone.phoneTypeCode === TelephoneTypes.work;
    });

    return workPhone ? workPhone.telephoneNumber : '';
  }

  set phone(phoneNumber: string) {

    if (!this.telephones) {
      this.telephones = [];
    }

    let workPhone: Telephone = this.telephones.find((t: Telephone) => t.phoneTypeCode === TelephoneTypes.work);

    if (workPhone !== undefined) {
      workPhone.telephoneNumber = phoneNumber;
    } else {
      this.telephones.push(<Telephone>{
        phoneTypeCode: TelephoneTypes.work,
        telephoneNumber: phoneNumber
      });
    }

    // if (this.phone) {
    //     this.telephones.find((t: Telephone) => t.phoneTypeCode === TelephoneTypes.work).telephoneNumber = phoneNumber;
    //     return;
    // }
  }

  get fax(): string {

    let fax: Telephone = _.find(this.telephones, (telephone: Telephone) => {
      return telephone.phoneTypeCode === TelephoneTypes.fax;
    });

    return fax ? fax.telephoneNumber : '';
  }

  set fax(faxNumber: string) {

    if (!this.telephones) {
      this.telephones = [];
    }

    let faxPhone: Telephone = this.telephones.find((t: Telephone) => t.phoneTypeCode === TelephoneTypes.fax);

    if (faxPhone !== undefined) {
      faxPhone.telephoneNumber = faxNumber;
    } else {
      this.telephones.push(<Telephone>{
        phoneTypeCode: TelephoneTypes.fax,
        telephoneNumber: faxNumber
      });
    }
  }

  replaceTelephones(phones: Telephone[]) {
    this.telephones = _.cloneDeep(phones);

    if (phones) {
      this.telephones.forEach(phn => {
        phn.id = null;
      });
    }
  }
}

export const solePractitionerTypeCodes: DropdownModel[] = [
  {
    label: 'No',
    value: 'NO'
  },
  {
    label: 'Yes (Male)',
    value: 'YES_MALE'
  },
  {
    label: 'Yes (Female)',
    value: 'YES_FEMALE'
  }
];
