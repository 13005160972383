import {DpBooleanValue} from '../shared/dp-boolean';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {PurchaserCommission} from './purchaser-commission';
import {VendorCommission} from './vendor-commission';

export class BrokerCommission extends BaseEntity {
  id: number;

  appliedTowardsPaymentOfCommission: DpBooleanValue;
  commissionBeforeHst: number;
  commissionInclHst: number;
  // depositHeldBy : string; this field has been moved to matter but backend still populates it for doc gen
  purchaserCommission: PurchaserCommission = new PurchaserCommission();

  rateOfHstOnCommission: number;
  rateOfPstOnCommission: number;
  totalCommissionPercentage: number;
  vendorCommission: VendorCommission = new VendorCommission();

  constructor(brokerCommission?: BrokerCommission) {
    super(brokerCommission);
    if (brokerCommission) {
      if (brokerCommission.purchaserCommission) {
        this.purchaserCommission = new PurchaserCommission(brokerCommission.purchaserCommission);
      }
      if (brokerCommission.vendorCommission) {
        this.vendorCommission = new VendorCommission(brokerCommission.vendorCommission);
      }
    } else {
      this.purchaserCommission = new PurchaserCommission();
      this.vendorCommission = new VendorCommission();
    }
  }
}




