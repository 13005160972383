import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {dropDowns} from '../../admin/accounts/shared/account-drop-downs';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';

export const PROVINCE_CODES = {
  ONTARIO: 'ON',
  NEW_BRUNSWICK: 'NB',
  NOVA_SCOTIA: 'NS',
  ALBERTA: 'AB',
  MANITOBA: 'MB',
  SASKATCHEWAN: 'SK',
  BRITISH_COLOMBIA: 'BC'
};

export class UserProvince extends BaseEntity {

  userProvinceId: number;
  provinceCode: ProvinceCode;
  enabled: boolean;
  userAccountProfileId: number;
  documentProfileId: number;

  get provinceName(): string {
    let ProvinceOptions = dropDowns.accessibleProvinces;
    let provinceOption = ProvinceOptions.find(province => province.value === this.provinceCode);
    return provinceOption ? provinceOption.label : '';
  }
}
