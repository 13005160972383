import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/mergeMap';
import {HttpClient} from '../../../core';
import {DocumentProfile} from '../document-profile';
import {documentProfileKey} from '../shared/document-profile-key';
import {documentProfilesApi} from '../shared/document-profile-api';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import * as _ from 'lodash';
import {DocumentTemplate, DocumentTemplateFile} from '../../../matters/document-production/document-template';
import {ProvinceCode} from '../../accounts/shared/account-province';
import {ImportTemplate} from '../../../matters/document-production/import-doprocess-template';
import {CustomerFile} from '../../../matters/shared/customer-file';
import {DocumentTemplateCategory} from '../../../matters/document-production/document-template-category';
import {Matter, matterApi, Utils} from '../../../matters/shared';
import moment from 'moment';
import {StaffProfilesService} from '../../staff-profiles/staff-profiles.service';
import {StaffProfiles} from '../../staff-profiles/staff-profiles';
import {UserProvince} from '../../../matters/shared/user-province';

class DocumentProfileCacheItem {
  private static DEFAULT_EXPIRES_AFTER = 60000; // 1 minute
  private static now(): number {
    return (new Date()).getTime();
  }

  private initialTime: number;
  profile: DocumentProfile;

  constructor(profile: DocumentProfile) {
    this.initialTime = DocumentProfileCacheItem.now();
    this.profile = profile;
  }

  getProfile(expiresAfter?: number): DocumentProfile {
    //ToDo: Does cache need to be expired after certain period of time
    return expiresAfter && this.isExpired(expiresAfter) ? null : this.profile;
  }

  private isExpired(expiresAfter?: number): boolean {
    return (DocumentProfileCacheItem.now() - this.initialTime) > (expiresAfter || DocumentProfileCacheItem.DEFAULT_EXPIRES_AFTER);
  }
}

@Injectable()
export class DocumentProfileService {
  private readonly responseKey: string = 'DOCUMENTPROFILE';
  private profileCache: DocumentProfileCacheItem[] = [];
  private profileListCache: { [ id: string ]: DocumentProfile[]; } = {};

  constructor(private httpClient: HttpClient, private staffProfilesService: StaffProfilesService) {
  }

  //This method should be called after opening matter and document profiles should be cached by then.
  /* async loadDocumentProfileSynchronouslyInCache(id: number, accountId: string, ret: any) {
       console.log("before dpawait ");
       await this.getById(id, accountId).toPromise().then(dp => {
           ret.result = dp;
           console.log("after dpawait ");

       });
   }



   //This method should be called after opening matter and document profiles should be cached by then.
   getCachedDocumentProfileById(id: number, accountId: string): DocumentProfile {
       let dp = this.findInCache(id);
       let ret: any = {};
       if(dp == null) {
           await this.loadDocumentProfileSynchronouslyInCache(id, accountId, ret);
           return <DocumentProfile>ret.result;
       }

       return this.findInCache(id);
   }*/

  //ignoreCache - if it's true then always retrieves the data from api otherwise checks in cache
  getById(id: number, accountId: string, ignoreCache: boolean = false, matter?: Matter): Observable<DocumentProfile> {
    if (!ignoreCache && (!matter || !matter.isMatterTyeDischargeAndProvinceDisabled)) {
      const profile: DocumentProfile = this.findInCache(id);
      if (profile && !isNaN(id)) {
        return Observable.of(profile);
      }
    }

    let url: string = matter && matter.provinceCode && matter.isMatterTyeDischargeAndProvinceDisabled ? `${ documentProfilesApi.systemDocumentProfiles(accountId, matter.provinceCode) }`
      : `${ documentProfilesApi.documentProfiles(accountId) }/${ id }`;
    if (!isNaN(id)) {
      return this.httpClient
      .get(url)
      .map(this.getProfileFromResponse);
    }
  }

  getDefault(id?: string): Observable<DocumentProfile> {
    let dppmUserDocProfileId: any = parseInt(sessionStorage.getItem(SESSION_STORAGE_KEYS.userDocumentProfileId));
    let accountId = id ? id : sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (dppmUserDocProfileId && !isNaN(dppmUserDocProfileId) && dppmUserDocProfileId > 0) {
      return this.getById(dppmUserDocProfileId, accountId);
    } else {
      return this.getDefaultProfileForAccount(accountId);
    }
  }

  getDefaultProfileForAccountFromCache(accountId: string, provinceCode?: string): Observable<DocumentProfile> {

    let defaultProfile: DocumentProfile = this.findDefaultProfileInCache(provinceCode);

    if (defaultProfile) {
      return Observable.of(defaultProfile);
    } else {
      return this.getDefaultProfileForAccount(accountId, provinceCode);
    }
  }

  getDefaultDocumentProfileOfLoggedInUser(accountId: string, provinceCode: string): Observable<DocumentProfile> {

    return this.staffProfilesService.getCachedLoggedInStaffProfile()
    .map((staffProfiles: StaffProfiles) => {
      return staffProfiles;
    })
    .flatMap(profile => {
      let userSelectedProvince: UserProvince;
      if (profile && profile.user) {
        userSelectedProvince = profile.user.userProvinces.find((userProvince: UserProvince) => {
          return userProvince.provinceCode == provinceCode;
        });
      }
      return this.getById(userSelectedProvince.documentProfileId, accountId);
    }).map((response: DocumentProfile) => {
      return response;
    });
  }

  getDefaultDocumentProfileOfLoggedInUserByDefaultProvince(): Observable<DocumentProfile> {

    return this.staffProfilesService.getCachedLoggedInStaffProfile()
    .map((staffProfiles: StaffProfiles) => {
      return staffProfiles;
    })
    .flatMap(profile => {
      let userSelectedProvince: UserProvince;
      if (profile && profile.user) {
        userSelectedProvince = profile.user.userProvinces.find((userProvince: UserProvince) => {
          return userProvince.provinceCode == profile.user.defaultProvinceCode;
        });
      }
      return this.getById(userSelectedProvince.documentProfileId, profile.customerAccountId);
    }).map((response: DocumentProfile) => {
      return response;
    });
  }

  getDefaultProfileForAccount(accountId: string, provinceCode?: string): Observable<DocumentProfile> {

    return this.httpClient
    .get(`${ documentProfilesApi.documentProfiles(accountId) }?filter=defaultProfileFlag_EQ_TRUE` + this.getFilterForProvince(provinceCode))
    .map((response) => {
      return response[ documentProfileKey.documentProfiles ];
    })
    .flatMap((profiles: DocumentProfile[]) => {
      let defaultDocumentProfile: DocumentProfile = this.findDefaultDocumentProfileFromList(profiles);
      return this.httpClient.get(`${ documentProfilesApi.documentProfiles(accountId) }/${ defaultDocumentProfile.id }`);
    }).map((response) => {
      let documentProfile = new DocumentProfile(response[ this.responseKey ]);
      if (documentProfile && documentProfile.id) {
        //if value already exists, it could be non-defaultDocumentProfile, so not set it
        if (!sessionStorage.getItem(SESSION_STORAGE_KEYS.userDocumentProfileId)) {
          sessionStorage.setItem(SESSION_STORAGE_KEYS.userDocumentProfileId, documentProfile.id.toString());
        }
        this.addToCache(documentProfile);
      }
      return documentProfile;
    });
  }

  findDefaultDocumentProfileFromList(profiles: DocumentProfile[]): DocumentProfile {
    if (profiles && profiles.length > 0) {
      let defaultProfiles: DocumentProfile[] = profiles.filter(value => value.defaultFlag);

      if (defaultProfiles.length == 1) {
        //Customer accounts can have only one default document profile.
        return defaultProfiles[ 0 ];
      } else if (defaultProfiles.length > 1) {
        //If account has more than one default document profile (system account can have 2 default document profiles) then returning MS_WORD as
        // that's what LOB wants to show for manage templates.
        return defaultProfiles.find(value => value.templateProcessorType == 'MS_WORD');
      }
    }

    return null;
  }

  getFilterForProvince(provinceCode: string): string {
    if (provinceCode) {
      return `,provinceCode_EQ_` + provinceCode;
    } else {
      return '';
    }
  }

  createDocumentProfile(profile: DocumentProfile, accountId: string): Observable<DocumentProfile> {
    return this.httpClient
    .post(`${ documentProfilesApi.documentProfiles(accountId) }`, profile)
    .map(this.getProfileFromResponse);
  }

  updateDocumentProfile(profile: DocumentProfile, accountId: string): Observable<DocumentProfile> {
    return this.httpClient
    .put(`${ documentProfilesApi.documentProfiles(accountId) }/${ profile.id }`, profile)
    .map(this.getProfileFromResponse);
  }

  updateDocumentProfileStatus(profile: DocumentProfile, accountId: string): Observable<DocumentProfile> {
    let url = documentProfilesApi.updateDocumentProfileStatus
    .replace('{accountId}', accountId).replace('{id}', profile.id.toString()) + '?isActive=' + profile.active;

    return this.httpClient.post(url, null)
    .map((res) => {
      return new DocumentProfile(res[ 'DOCUMENTPROFILE' ]);
    });
  }

  createOrUpdateDocumentProfile(profile: DocumentProfile, accountId: string): Observable<DocumentProfile> {
    if (!profile.id) {
      return this.createDocumentProfile(profile, accountId);
    } else {
      return this.updateDocumentProfile(profile, accountId);
    }
  }

  //ToDo: #TemporaryProvinceCode# provinceCode optional for now but i guess it should be removed after all province related stories are done
  getDocumentProfileList(accountId: string, provinceCode?: ProvinceCode): Observable<DocumentProfile[]> {
    let url = documentProfilesApi.documentProfiles(accountId);
    if (provinceCode) {
      url += '?filter=provinceCode_EQ_' + provinceCode;
    }
    return this.httpClient.get(url)
    .map((response) => {
      let result: DocumentProfile[] = [];
      for (let dp of response[ documentProfileKey.documentProfiles ]) {
        let documentProfile = new DocumentProfile(dp);
        result.push(documentProfile);
      }
      this.profileListCache[ accountId ] = result;
      return result;
    });

  }

  getCachedDocumentProfileList(accountId: string): DocumentProfile[] {
    return this.profileListCache[ accountId ];
  }

  deleteDocumentProfile(id: number, accountId: string): Observable<any> {
    return this.httpClient.delete(`${ documentProfilesApi.documentProfiles(accountId) }/${ id }`)
    .map((response) => {
      return response;
    });
  }

  resetDocumentProfileList(data: DocumentProfile[], deletedDocumentProfile: DocumentProfile = null): DocumentProfile[] {
    if (deletedDocumentProfile !== null) {
      deletedDocumentProfile.profileName += ' (Deleted)';
      deletedDocumentProfile.defaultFlag = deletedDocumentProfile.defaultProfileFlag;
      data.push(deletedDocumentProfile);
    }

    let documentProfiles: DocumentProfile[] = _.filter(data, (item: DocumentProfile) =>
      item.defaultFlag === false && item.profileName !== null
    );
    documentProfiles = _.sortBy(documentProfiles, [ (item: DocumentProfile) => item.profileName.toLowerCase() ]);
    let defaultDocumentProfile: DocumentProfile = _.find(data, (item: DocumentProfile) =>
      item.defaultFlag === true && item.profileName != null
    );
    if (defaultDocumentProfile) {
      documentProfiles.unshift(defaultDocumentProfile);
    }

    return documentProfiles;
  }

  private getProfileFromResponse = (response): DocumentProfile => {
    const profile = new DocumentProfile(response[ this.responseKey ]);
    this.addToCache(profile);
    return profile;
  };

  // TODO the following functions should be done in DocumentProfileCache

  private findInCache(id: number): DocumentProfile {
    const item: DocumentProfileCacheItem = this.profileCache.find(i => i.profile.id == id);
    return item ? item.getProfile() : null;
  }

  private findDefaultProfileInCache(provinceCode: string): DocumentProfile {
    const item: DocumentProfileCacheItem = this.profileCache.find(i => i.profile.provinceCode == provinceCode && i.profile.defaultFlag);
    return item ? item.getProfile() : null;
  }

  public addToCache(profile: DocumentProfile): void {
    this.removeFromCache(profile);
    this.profileCache.push(new DocumentProfileCacheItem(profile));
  }

  private removeFromCache(profile: DocumentProfile): void {
    if (profile.id) {
      this.profileCache = this.profileCache.filter(p => p.profile.id != profile.id);
    }
  }

  getDocTemplateFiles(accountFileFolderId: number): Observable<DocumentTemplateFile[]> {
    let url: string = documentProfilesApi.docTemplateFiles.replace('{id}', '' + accountFileFolderId);
    return this.httpClient.get(url)
    .map((res) => {
      return res[ documentProfileKey.documentTemplateFiles ].map((item) => new DocumentTemplateFile(item));
    });
  }

  getSystemDocTemplateFiles(provinceCode: ProvinceCode, processorType: string): Observable<DocumentTemplateFile[]> {
    let url: string = documentProfilesApi.systemDocTemplateFiles + '?provinceCode=' + provinceCode + '&templateProcessorType=' + processorType;
    return this.httpClient.get(url)
    .map((res) => {
      return res[ documentProfileKey.documentTemplateFiles ].map((item) => new DocumentTemplateFile(item));
    });
  }

  copyDocumentTemplatesToAccountFileFolder(accountId: string, accountFileFolderId: number, importTemplate: ImportTemplate): Observable<DocumentTemplate[]> {
    let url: string = documentProfilesApi.copyDocTemplateFiles.replace('{accountId}', accountId);
    url = url.replace('{accountFileFolderId}', '' + accountFileFolderId);
    return this.httpClient.post(url, JSON.stringify(importTemplate))
    .map((res) => {
      const data: any[] = res[ 'Templates' ];
      let documentTemplates: DocumentTemplate[] = [];
      if (Array.isArray(data)) {
        documentTemplates = data.map((val: DocumentTemplate) => {
          let documentTemplate: DocumentTemplate = new DocumentTemplate(val);
          return documentTemplate;
        });
      }
      return documentTemplates;
    });
  }

  getDocTemplateFile(accountFileFolderId: number, documentTemplateFileId: number): Observable<DocumentTemplateFile> {
    let url: string = documentProfilesApi.docTemplateFiles.replace('{id}', '' + accountFileFolderId);
    url += '/' + documentTemplateFileId;
    return this.httpClient.get(url)
    .map((res) => {
      return new DocumentTemplateFile(res[ documentProfileKey.documentTemplateFile ]);
    });
  }

  editDocTemplateFile(accountFileFolderId: number, documentTemplateFile: DocumentTemplateFile): Observable<DocumentTemplate> {
    let url: string = documentProfilesApi.docTemplate.replace('{id}', '' + accountFileFolderId);
    url += '/' + documentTemplateFile.documentTemplate.docGenTemplateId;
    return this.httpClient.put(url, documentTemplateFile.documentTemplate)
    .map((res) => {
      return new DocumentTemplate(res[ documentProfileKey.documentTemplate ]);
    });
  }

  deleteDocTemplateFile(accountFileFolderId: number, documentTemplateFile: DocumentTemplateFile): Observable<any> {
    let url: string = documentProfilesApi.docTemplateFiles.replace('{id}', '' + accountFileFolderId);
    url += '/' + documentTemplateFile.id;
    return this.httpClient.delete(url)
    .map((res) => {
      return res;
    });
  }

  downloadDocTemplateFile(accountFileFolderId: number, documentTemplateFileId: number) {
    let url: string = documentProfilesApi.downloadTemplate.replace('{accountFileFolderId}', '' + accountFileFolderId);
    url = url.replace('{documentTemplateFileId}', '' + documentTemplateFileId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    console.log('downloadFileAndDisplay url:', url);
    var windowObject = window.open(url, '_blank');
  }

  downloadMultipleDocTemplateFiles(accountFileFolderId: number, documentTemplateFileIds: number[]) {
    let url: string = documentProfilesApi.downloadTemplateFiles.replace('{accountFileFolderId}', '' + accountFileFolderId);
    this.httpClient.downloadThroughPost(url, JSON.stringify(documentTemplateFileIds))
    .subscribe((res) => {
      let fileName = 'DocTemplateFiles_' + moment(new Date(), 'YYYY/MM/DD').format('YYYY-MM-DD hhmmA') + '.zip';
      let blob = new Blob(([ res.body ]), {type: 'application/zip'});
      let utils = new Utils();
      if (utils.isIEOrEdge()) {
        let navigator: any = window.navigator;
        if (navigator) {
          navigator.msSaveBlob(blob, fileName);
        }
      } else {
        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = fileName;
        fileLink.click();
      }
    });
  }

  downloadSystemDocTemplateFile(documentTemplateFileId: number) {
    let url: string = documentProfilesApi.downloadSystemDocumentTemplate;
    url = url.replace('{documentTemplateFileId}', '' + documentTemplateFileId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    console.log('downloadFileAndDisplay url:', url);
    var windowObject = window.open(url, '_blank');
  }

  async uploadLogoFile(formData: any): Promise<CustomerFile> {
    let url: string = documentProfilesApi.customerFileUpload.replace('{accountId}', sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    let res = await this.httpClient.uploadFiles(url, formData).toPromise();
    return new CustomerFile(res[ 'CustomerFile' ]);
  }

  importTemplatesByCategory(accountId: string, accountFileFolderId: string, documentTemplateCategory: string): Observable<DocumentTemplateCategory> {
    return this.httpClient.post(`${ matterApi.importTemplatesByCategory(accountId, accountFileFolderId, documentTemplateCategory) }`, null)
    .map((res) => {
      return new DocumentTemplateCategory(res[ 'DocumentTemplateCategory' ]);
    });
  }

  async getF9DefaultDear(documentProfileId: number, provinceCode: string): Promise<string> {
    let f9DefaultDear: string;
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let response: DocumentProfile = await this.getById(documentProfileId, id).toPromise();
    if (response && response.miscDocumentProfile) {
      if (response.miscDocumentProfile.sameAsDefaultProfileFlag) {
        let defaultDocumentProfile: DocumentProfile = await this.getDefaultProfileForAccountFromCache(id, provinceCode).toPromise();
        if (defaultDocumentProfile && defaultDocumentProfile.miscDocumentProfile) {
          f9DefaultDear = defaultDocumentProfile.miscDocumentProfile.defaultDearFieldsCode;
        }

      } else {
        f9DefaultDear = response.miscDocumentProfile.defaultDearFieldsCode;
      }

    }
    return f9DefaultDear;
  }

  loadCachedDocumentProfiles(): void {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    const documentProfiles: DocumentProfile[] = this.getCachedDocumentProfileList(accountId);
    if (!documentProfiles || documentProfiles.length == 0) {
      this.getDocumentProfileList(accountId).subscribe();
    }

  }

}
