import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class CategoryTemplate extends BaseEntity {

  id: number;
  supplementalTaskCategoryId: number;
  templateDescription: string;
  documentTemplateId: number;
  documentTemplateName: string;
  templateFolderId: number;
  preProduceValidationFlags: string[];
}
