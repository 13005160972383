<form class="form-horizontal container-fluid custom-modal-container" id="tarionAdjForm" *ngIf="localAdjustment" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <ng-container *ngIf="isInterimOccupancyFee()">
                    <h1>Adjustment Type : Interim Occupancy Fees</h1>
                </ng-container>
                <ng-container *ngIf="isInterimEarlyPossessionFee()">
                    <h1>Adjustment Type : Early Possession Fees(Interim Closing)</h1>
                </ng-container>

            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <ng-container *ngIf="localAdjustment">

            <div class="form-group">
                <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                    <div class="form-group main-level">
                        <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Calculate for</label>
                    </div>
                    <div class="col-lg-4">
                        <select type="text"
                                id="calculateFor"
                                name="calculateFor"
                                [(ngModel)]="localAdjustment.calculateFor"
                                class="form-control"
                        >
                            <option *ngFor="let pdctct of calculateForTypes" [value]="pdctct.value">
                                {{pdctct.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3" *ngIf="isInterimOccupancyFee() && !showFieldsForPOTLAndNonCondo()">
                        <select type="text"
                                id="mortgageInterestType"
                                name="mortgageInterestType"
                                [(ngModel)]="localAdjustment.mortgageInterestType"
                                (ngModelChange)="onMortgageInterestTypeChange();updateInterestValueOnChange($event)"
                                class="form-control"
                        >
                            <option *ngFor="let pdctct of mortgageInterestCalculationModeTypes" [value]="pdctct.value">
                                {{pdctct.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-3 vertical-align-label" *ngIf="isInterimEarlyPossessionFee() || showFieldsForPOTLAndNonCondo()">
                        <label class="control-label">Interest on Deferred Purchase Monies</label>
                    </div>
                    <div class="col-lg-2" >
                        <ng-container *ngIf="(isInterimOccupancyFee() && !isMortgageInterestSetToDefault()) || isInterimEarlyPossessionFee()">
                            <div class="col-lg-11">
                                <input type="text"
                                       name="mortgageInterestPercentage"
                                       [(ngModel)]="localAdjustment.mortgageInterestPercentage"
                                       [dp-read-only]="localAdjustment.mortgageInterestType =='AUTOMATIC'"
                                       (ngModelChange)="recalculateMortgageInterestTotal()"
                                       dp-percentage [max-decimals] = 9 [padding-zero]="true" [max-value] = 99.999999999
                                       class="form-control text-right"/>
                            </div>
                            <div class="col-lg-1">
                                <label class="control-label text-left">%</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-lg-2">
                        <ng-container *ngIf="(isInterimOccupancyFee() && !isMortgageInterestSetToDefault()) || isInterimEarlyPossessionFee()">
                            <div class="col-lg-1">
                                <label class="control-label text-right">X</label>
                            </div>
                        </ng-container>
                        <div class="col-lg-11">
                            <input type="text"
                                   name="mortgageInterest"
                                   [(ngModel)]="localAdjustment.mortgageInterest"
                                   [dp-read-only]="localAdjustment.mortgageInterestType =='AUTOMATIC'"
                                   (ngModelChange)="recalculateMortgageInterestTotal()"
                                   dp-currency
                                   dp-default-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="col-lg-3">
                            <label class="control-label text-center">&#247; 12 =</label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text"
                                   name="mortgageInterestTotal"
                                   [(ngModel)]="localAdjustment.mortgageInterestTotal"
                                   [dp-read-only]="localAdjustment.mortgageInterestPercentage > 0 || localAdjustment.mortgageInterest > 0 || localAdjustment.mortgageInterestType =='AUTOMATIC'"
                                   dp-currency
                                   dp-default-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3" *ngIf="isInterimOccupancyFee() && !showFieldsForPOTLAndNonCondo()">
                        <select type="text"
                                id="commonExpenseType"
                                name="commonExpenseType"
                                [(ngModel)]="localAdjustment.commonExpenseType"
                                (ngModelChange)="onCommonExpenseTypeChange()"
                                class="form-control"
                        >
                            <option *ngFor="let pdctct of commonExpenseCalculationModeTypes" [value]="pdctct.value">
                                {{pdctct.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-3 vertical-align-label" *ngIf="isInterimEarlyPossessionFee() || showFieldsForPOTLAndNonCondo()">
                        <label class="control-label">Common Expenses</label>
                    </div>
                    <div class="col-lg-2">
                        <ng-container *ngIf="localAdjustment.commonExpenseType != 'AUTOMATIC'">
                            <div class="col-lg-11">
                                <input type="text"
                                       name="commonExpensePercentage"
                                       [(ngModel)]="localAdjustment.commonExpensePercentage"
                                       (ngModelChange)="recalculateCommonExpenseTotal()"
                                       dp-percentage [max-decimals] = 9 [padding-zero]="true" [max-value] = 99.999999999
                                       class="form-control text-right"/>
                            </div>
                            <div class="col-lg-1">
                                <label class="control-label text-left">%</label>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-lg-2">
                        <ng-container *ngIf="localAdjustment.commonExpenseType != 'AUTOMATIC'">
                            <div class="col-lg-1">
                                <label class="control-label text-right">X</label>
                            </div>
                            <div class="col-lg-11">
                                <input type="text"
                                       name="commonExpense"
                                       [(ngModel)]="localAdjustment.commonExpense"
                                       (ngModelChange)="recalculateCommonExpenseTotal()"
                                       dp-currency
                                       dp-default-currency
                                       class="form-control text-right"/>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-lg-3">
                        <div class="col-lg-3">
                            <ng-container *ngIf="localAdjustment.commonExpenseType != 'AUTOMATIC'">
                                <label class="control-label text-center">&#247; 12 =</label>
                            </ng-container>
                        </div>
                        <div class="col-lg-9">
                            <input type="text"
                                   name="commonExpenseTotal"
                                   [(ngModel)]="localAdjustment.commonExpenseTotal"
                                   [dp-read-only]="localAdjustment.commonExpense > 0 || localAdjustment.commonExpensePercentage > 0 || localAdjustment.commonExpenseType == 'AUTOMATIC'"
                                   dp-currency
                                   dp-default-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3" *ngIf="isInterimOccupancyFee() && !showFieldsForPOTLAndNonCondo()">
                        <select type="text"
                                id="taxesType"
                                name="taxesType"
                                [(ngModel)]="localAdjustment.taxesType"
                                (ngModelChange)="onTaxesTypeChange()"
                                class="form-control"
                        >
                            <option *ngFor="let pdctct of taxesCalculationModeTypes" [value]="pdctct.value">
                                {{pdctct.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-3 vertical-align-label" *ngIf="isInterimEarlyPossessionFee()|| showFieldsForPOTLAndNonCondo()">
                        <label class="control-label">Taxes</label>
                    </div>
                    <div class="col-lg-2">
                        <div class="col-lg-11">
                            <input type="text"
                                   name="taxesPercentage"
                                   [(ngModel)]="localAdjustment.taxesPercentage"
                                   [dp-read-only]="localAdjustment.taxesType == 'AUTOMATIC'"
                                   (ngModelChange)="recalculateTaxesTotal()"
                                   dp-percentage [max-decimals] = 9 [padding-zero]="true"  [max-value] = 99.999999999
                                   class="form-control text-right"/>
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="col-lg-1">
                            <label class="control-label text-right">X</label>
                        </div>
                        <div class="col-lg-11">
                            <input type="text"
                                   name="taxes"
                                   [(ngModel)]="localAdjustment.taxes"
                                   [dp-read-only]="localAdjustment.taxesType == 'AUTOMATIC'"
                                   (ngModelChange)="recalculateTaxesTotal()"
                                   dp-currency
                                   dp-default-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="col-lg-3">
                            <label class="control-label text-center">&#247; 12 =</label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text"
                                   name="taxesTotal"
                                   [(ngModel)]="localAdjustment.taxesTotal"
                                   [dp-read-only]="localAdjustment.taxesPercentage > 0 || localAdjustment.taxes > 0 || localAdjustment.taxesType == 'AUTOMATIC'"
                                   dp-currency
                                   dp-default-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-7"></div>

                    <div class="col-lg-3">
                        <div class="col-lg-3">
                            <label class="control-label">Total:</label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text"
                                   name="total"
                                   [ngModel]="localAdjustment.total"
                                   readonly="true"
                                   dp-currency
                                   dp-default-currency
                                   class="form-control text-right"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label" dp-province-field-label="matter.soa.expense.taxtype">Adjust for</label>
                    </div>
                    <div class="col-lg-1" *ngIf="context.matter.isMatterProvinceON">
                        <ng-container>
                            <select type="text"
                                    id="taxType"
                                    name="taxType"
                                    [(ngModel)]="localAdjustment.taxType"
                                    (ngModelChange)="updateTaxRate($event)"
                                    class="form-control"
                            >
                                <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                                    {{taxType.label}}
                                </option>
                            </select>
                        </ng-container>

                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyTax"
                                name="applyTax"
                                [(ngModel)]="localAdjustment.applyTax"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>

                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">{{localAdjustment.taxType}} percentage is</label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="gstPercentage"
                               name="gstPercentage"
                               [(ngModel)]="localAdjustment.hstRate"
                               class="form-control"
                               dp-percentage
                        />
                    </div>
                    <div class="col-lg-1">
                        <label class="control-label text-left">%</label>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Post-dated cheques to commence</label>
                    </div>

                    <div class="col-lg-7">
                        <select type="text"
                                id="postDatedChequesToCommence"
                                name="postDatedChequesToCommence"
                                [(ngModel)]="localAdjustment.postDatedChequesToCommence"
                                class="form-control"
                        >
                            <option *ngFor="let pdctct of postDatedChequesToCommenceTypes" [value]="pdctct.value">
                                {{pdctct.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <!-- adjustment summary -->
            <div style="border-top: 1px solid #cccccc;">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <ng-container *ngIf="isInterimOccupancyFee()">
                            <label class="control-label" style="font-weight : bold">
                                INTERIM OCCUPANCY FEES
                            </label>
                        </ng-container>
                        <ng-container *ngIf="isInterimEarlyPossessionFee()">
                            <label class="control-label" style="font-weight : bold">
                                EARLY POSSESSION FEES
                            </label>
                        </ng-container>

                    </div>
                </div>

                <div *ngFor="let line of details">
                    <div class="col-lg-12">
                        <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                        <label *ngIf="line.value && line.value.length > 0" class="control-label" [ngClass]="{'col-lg-4': line.pushValueToRight, 'col-lg-2': !line.pushValueToRight}">{{line.value}}</label>
                    </div>
                </div>

            </div>

        </ng-container>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="ok()"
                        class="dp-btn keyboard-save-action">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
                <button type="button"
                        (click)="delete()"
                        *ngIf="allowDelete"
                        class="secondary-button">
                    <span>Delete</span>
                </button>
            </div>
            <div class="col-lg-2" *ngIf="localAdjustment">
                <dp-checkbox fieldId="checkBoxInfoOnly">
                    <input type="checkbox"
                           id="checkBoxInfoOnly"
                           name="checkBoxInfoOnly"
                           [(ngModel)]="localAdjustment.infoOnly"
                           [checked]="localAdjustment.infoOnly"/>
                </dp-checkbox>
                <label class="control-label vertical-align-label display-inline" >Info Only</label>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
