<div class="form-horizontal container-fluid custom-modal-container" >
    <div class="row modal-header margin-bottom-20">
        <div class="col-lg-10"><h1>Adjustment Type: Strata Maintenance Fees</h1></div>
        <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
    </div>
    <div class="modal-sub-header">
        <div class="row">
            <div class="col-lg-10 padding-left-20">
                <label class="control-label margin-bottom-10">Adjustment as at <ng-container *ngIf="context?.matterCloseDate && context?.matterCloseDate != ''">{{context?.matterCloseDate | date : 'MMMM dd, yyyy'}}</ng-container>
                    <ng-container *ngIf="!context?.matterCloseDate || context?.matterCloseDate == ''">{{getEmptyCloseDateText()}}</ng-container>
                </label>
            </div>
            <hr class="hr-title margin-bottom-0 margin-top-0 col-lg-12">
        </div>
    </div>
    <div class="modal-body" style="min-height: 26.5rem;" >
        <div class="form-group row pt-2">
            <label class="control-label col-lg-2" 
              dp-province-field-label="matter.adjustment-type.strata-maintenance-fee.maintenance-amount"
              for="strataMaintenanceAmount">Amount of Strata Maintenance Period</label>
            <div class="col-lg-2">
                <input type="text"
                       id="strataMaintenanceAmount"
                       name="strataMaintenanceAmount"
                       [(ngModel)]="context.strataMaintenanceFee.strataMaintenanceAmount"
                       class="form-control text-right"
                       dp-currency
                       tabindex="-1"
                       dp-error-validator
                       [fieldKey]="'matter.adjustment-type.strata-maintenance-fee.maintenance-amount'"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="control-label col-lg-2" for="maintenancePeriodStartDate">Start date of maintenance period</label>
            <div class="col-lg-4">
                <dp-partial-date id="maintenancePeriodStartDate"
                                 [inputDate]="context.strataMaintenanceFee.maintenanceStartDate"
                                 [fullDateRequired]="true"
                                 statusBarHelp
                                 (dateChange)="onDateChange($event)"
                                 [fieldKey]="'matter.adjustment-type.strata-maintenance-fee.start-date'">
                </dp-partial-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="control-label col-lg-2" for="maintenancePeriodEndDate">End date of maintenance period</label>
            <div class="col-lg-4">
                <dp-partial-date id="maintenancePeriodEndDate"
                                 [inputDate]="context.strataMaintenanceFee.maintenanceEndDate"
                                 [fullDateRequired]="true"
                                 statusBarHelp
                                 (dateChange)="onDateChange($event, false)"
                                 [fieldKey]="'matter.adjustment-type.strata-maintenance-fee.end-date'">
                </dp-partial-date>
            </div>
        </div>
        <div class="form-group row">
            <label class="control-label col-lg-2" for="maintenanceFeePaid">Maintenance fee paid?</label>
            <div class="col-lg-2">
                <select class="form-control"
                        id="maintenanceFeePaid"
                        name="maintenanceFeePaid"
                        [(ngModel)]="context.strataMaintenanceFee.isMaintenanceFeePaid">
                    <option *ngFor="let item  of yesNoItems" [value]="item.value">
                        {{item.label}}
                    </option>
                </select>
            </div>
        </div>
        <div class="row" *ngIf="context.matterType === 'PURCHASE'">
            <hr />
        </div>
        <div class="row padding-top-20" *ngIf="context.matterType === 'PURCHASE'">
            <div class="form-group row">
                <label class="label col-lg-4" for="maintenanceFeePaid">Strata Maintenance Fees</label>
            </div>
            <div class="form-group row">
                <label class="label col-lg-4" for="maintenanceFeePaid">Amount of Maintenance Fee</label>
                <div class="col-lg-3">{{this.context?.strataMaintenanceFee?.strataMaintenanceAmount | currency:'CAD':'symbol-narrow'}}</div>
            </div>
            <div class="form-group row" *ngIf="context.strataMaintenanceFee.isMaintenanceFeePaid == 'YES'">
                <label class="label col-lg-4" for="maintenanceFeePaid" >Buyer share for {{getNoOfDays()}} days</label>
                <div class="col-lg-3">{{buyerShare }}</div>
            </div>
            <div class="form-group row" *ngIf="context.strataMaintenanceFee.isMaintenanceFeePaid == 'NO'">
                <label class="label col-lg-4" for="maintenanceFeePaid">Seller share for {{getNoOfDays()}} days</label>
                <div class="col-lg-3">{{sellerShare}}</div>
            </div>
        </div>
    </div>

    <div class="row modal-footer ">
        <div class="col-lg-10 buttons">
            <button
                type="button"
                id="okBtn"
                class="dp-btn"
                (click)="ok()"
            >
                <span>Ok</span>
            </button>
            <button
                type="button"
                id="closeBtn"
                class="dp-btn"
                (click)="close()"
            >
                <span>Cancel</span>
            </button>
        </div>
        <div class="col-lg-2" *ngIf="context?.matterType === MatterTypesValue.PURCHASE">
            <dp-checkbox fieldId="checkBoxInfoOnly">
                <input type="checkbox"
                       id="checkBoxInfoOnly"
                       name="checkBoxInfoOnly"
                       [(ngModel)]="context.strataMaintenanceFee.infoOnly"
                       [checked]="context.strataMaintenanceFee.infoOnly"/>
            </dp-checkbox>

            <label class="control-label vertical-align-label display-inline" >Info Only</label>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>

