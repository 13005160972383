import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {MainComponent} from '../main';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';
import {SharedMainModule} from '../shared-main';
import {MaterialModule} from '../MaterialModule';
import {WindowRef} from '../shared/window.ref';
import {MDDialogsModule} from '../shared/dialog/dialogs.module';
import {UpdateCredentialsModal} from './update-password/update-credentials.modal.component';
import {DPErrorModule} from '../shared/error-handling/error.module';
import {PersonalDetailsModalComponent} from './personal-details/personal-details.modal.component';
import {RouterModule} from '@angular/router';
import {TabsComponent} from '../shared/tabbing/tabs.component';
import {MatterService} from '../matters/matter.service';
import {JurisdictionService} from '../matters/property-teranet/jurisdiction.service';
import {DocketService} from '../admin/docket/docket.service';
import {StaffProfilesService} from '../admin/staff-profiles/staff-profiles.service';
import {AccountService} from '../admin/accounts/account.service';
import {ContactQueryService} from '../contact/contact-query.service';
import {ContactCommandService} from '../contact/contact-command.service';
import {StatementConfigService} from '../admin/shared/statement-config.service';
import {DefaultFieldsService} from '../shared-main/default-fields.service';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {MortgageeEnclosureService} from '../admin/mortgagee-enclosure/mortgagee-enclosure.service';
import {LegalFirmService} from '../admin/accounts/legal-firm/legal-firm.service';
import {JurisdictionDepartmentsService} from '../admin/jurisdiction-departments/jurisdiction-departments.service';
import {UserDefinedFieldsService} from '../admin/user-defined-fields/user-defined-fields.service';
import {TrustAccountsService} from '../admin/trust-account/trust-accounts.service';
import {
  ManageThirdPartyCredentialsModal
} from './manage-thirdparty-credentials/manage-thirdparty-credentials.modal.component';
import {ManageTeranetUserModal} from './manage-thirdparty-credentials/manage-teranet-user.modal.component';
import {
  TestTeranetUserCredentialModal
} from './manage-thirdparty-credentials/test-teranet-user-credential.modal.component';
import {TeranetChangePasswordModal} from './manage-thirdparty-credentials/teranet-change-password.modal.component';
import {CustomDPDateCodeService} from '../admin/user-defined-dp-date-codes/dp-date-code-service';
import {TeranetInstrumentRequestModal} from '../matters/teranet-connect/teranet-instrument-request.modal.component';
import {LendingInstitutionsModalComponent} from '../contact/lending-institutions.modal.component';
import {EmailFieldService} from '../shared-main/email-field/email-field-service';
import {DPPMCheckBoxModule} from '../shared/checkbox/checkbox.module';
import {
  StewartTitleLawyerProfileModal
} from './manage-thirdparty-credentials/stewart-title/stewart-title-lawyer-profile.modal.component';
import {
  StewartTitleAuthenticationModal
} from './manage-thirdparty-credentials/stewart-title/stewart-title-authentication.modal.component';
import {SoaTrustLedgerHelperService} from '../shared/soa-trustledger-helper.service';
import {TaxRateService} from '../matters/consideration-ltt/tax-rate.service';
import {OrdinalNumberPipe} from '../shared-main/ordinal-number.pipe';
import {
  StewartTitlePortalModal
} from '../matters/title-insurance/stewart-title-portal/stewart-title-portal.modal.component';
import {
  ChicagoTitlePortalModal
} from '../matters/title-insurance/chicago-title-portal/chicago-title-portal.modal.component';
import {DpDirtyCheckService} from '../shared-main/dp-dirty-check.service';
import {GetGlobalSaveModelService} from '../matters/shared/get-global-save-model.service';
import {ThirdPartyCredentialsComponent} from './manage-thirdparty-credentials/third-party-credentials.component';
import {FctPortalModal} from '../matters/title-insurance/fct-portal/fct-portal.modal.component';
import {ConfirmPasswordModalComponent} from './confirm-password-modal/confirm-password.modal.component';
import {MessagesComponent} from '../messages/messages.component';
import {MessageService} from '../messages/message.service';
import {ExportTemplatesService} from '../admin/export-templates/export-templates.service';
import {PartialDateModule} from '../shared/partial-date/partial-date.module';
import {MatterParticipantService} from '../matters/matter-participant-service';
import {
  SimpleCredentialsModalComponent
} from './manage-thirdparty-credentials/simple-credentials/simple-credentials.modal.component';
import {TeranetChangeFieldCodeModal} from '../shared-main/field-codes/teranet-change-field-code.modal.component';
import {TitleInsuranceConfigurationService} from '../integrations/title-insurance-configuration.service';
import {UnityBillingService} from '../billing/unity-billing-service';
import {ProjectService} from '../projects/project.service';
import {CustomEventModalComponent} from '../event/custom-event/custom-event.modal.component';
import {DateTimePickerComponent} from '../shared-main/date-time-picker/date-time-picker.component';
import {ModalF9HelpModule} from '../shared/modal-f9-help/modal-f9-help.module';
import {EventService} from '../event/event.service';
import {ProjectMatterCacheService} from '../core/project-matter-cache.service';
import {DepositManagerService} from '../deposit-manager/deposit-manager-service';
import {TemplateCodeService} from '../matters/template.code.service';
import {StatementAdjustmentService} from '../matters/statement-adjustment/statement-adjustment.service';
import {ChangeFontComponent} from './change-font/change-font.component';
import {CustomerSupportComponent} from './customer-support/customer-support.component';
import {ShareDocumentsComponent} from '../share-documents/share-documents.component';
import {LinkSharedDocumentsModalComponent} from '../share-documents/link-shared-documents-modal.component';
import {PrecedentService} from '../matters/mortgages/mortgage/precedent/precedent.service';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {SoajFieldCodeService} from '../shared-main/soaj-field-code.service';
import {FieldCodeService} from '../shared-main/field-code.service';
import {AppearanceModalComponent} from './appearance/appearance.modal.component';
import {DriverLicenceParsingService} from '../shared-main/driver-licence/driver-licence-parsing.service';
import {
  ImportDriverLicenceModalComponent
} from '../shared-main/driver-licence/import-driver-licence/import-driver-licence-modal.component';
import {CirfHelperService} from '../matters/shared/cirf/cirf-helper.service';
import {InformInviteeModalComponent} from '../event/custom-event/inform-invitee.modal.component';
import {DPPMAccordionModule} from '../../app/shared/accordion/accordion.module';
import {MatterPollingService} from '../../app/core/matter-polling.service';
import {
  MatterNotificationConfigService
} from '../admin/manage-messaging-notifications/matter-notification-config/matter-notification-config.service';
import {CirfConfigService} from '../shared-main/cirf-config/cirf-config.service';
import {EventSchedulingModalComponent} from '../event/custom-event/event-scheduling.modal.component';
import {
  TitlePlusPortalModalComponent
} from '../matters/title-insurance/title-plus-portal/title-plus-portal.modal.component';
import {
  AssignmentNotificationConfigService
} from '../admin/manage-messaging-notifications/assignment-notification-config/assignment-notification-config.service';
import {RenameCredentialModal} from '../admin/account-integeration/rename-credential.modal.component';
import {LawyerMoveService} from '../admin/staff-profiles/lawyer-move/lawyer-move.service';
import {
  AccountReminderConfigurationService
} from '../admin/manage-messaging-notifications/account-reminder-configuration/account-reminder-configurationservice';
import {
  RemoteSigningConfigurationService
} from '../admin/remote-signing-configuration/remote-signing-configuration.service';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {LandingWidgetsModule} from '../../../projects/landing-widgets/src/lib/landing-widgets.module';
import {WelcomePageModalComponent} from './welcome-page/welcome-page.modal.component';
import {CustomerBillingPortalModal} from '../billing/customer-billing-portal/customer-billing-portal.modal.component';
import {WarningLevelModal} from '../billing/warning-level/warning-level.modal.component';
import {OtherIntegrationServicesModalComponent} from '../integrations/other-integration-services.modal.component';
import {AccountingExportModalComponent} from '../integrations/accounting-export.modal.component';
import {AccountingExportService} from '../integrations/accounting-export.service';
import {AccountingModalComponent} from './accounting/accounting.modal.component';
import {LoginLtsaModalComponent} from './manage-thirdparty-credentials/ltsa/login-ltsa.modal.component';
import {ContactSectionedService} from '../contact/contact-sectioned.service';
import {ManageLtsaUserModal} from './manage-thirdparty-credentials/ltsa/manage-ltsa-user.modal.component';
import {ChatAIComponent} from './chat-AI/chat-AI.component';
import {AICardComponent} from './app-AI/app-AI.component';
import {MatCardModule} from '@angular/material/card';
import {ChatAIRoboComponent} from './chat-AI-robo/chat-AI-robo.component';
import {ChatAIToasterComponent} from './chat-AI-toaster/chat-AI-toaster.component';
import {ChatAITermsConditionsComponent} from './chat-AI-terms-condition/chat-AI-terms-condition.component';
import {SpinningComponent} from './app-spinning/app-spinning.component';
import {ApicFeeService} from '../admin/apic-fee/apic-fee.service';
import {ERegistrationService} from '../matters/forms/eregistration/eregistration.service';
import {AdjustmentTemplateService} from '../matters/statement-adjustment/adjustment-template.service';
import {AdjudicationService} from '../adjudications/adjudication.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedMainModule,
    RadioButtonModule,
    ButtonModule, InputTextModule, CalendarModule, DialogModule,
    MaterialModule,
    DPErrorModule,
    MDDialogsModule,
    AutoCompleteModule,
    AccordionModule,
    DPPMCheckBoxModule,
    PartialDateModule,
    ModalF9HelpModule, DPPMAccordionModule, InfiniteScrollModule, LandingWidgetsModule,
    MatCardModule
  ],
  declarations: [
    TabsComponent,
    MainComponent,
    UpdateCredentialsModal,
    ConfirmPasswordModalComponent,
    PersonalDetailsModalComponent,
    ManageThirdPartyCredentialsModal,
    ManageTeranetUserModal,
    ManageLtsaUserModal,
    RenameCredentialModal,
    TestTeranetUserCredentialModal,
    TeranetChangePasswordModal,
    TeranetInstrumentRequestModal,
    LendingInstitutionsModalComponent,
    StewartTitleLawyerProfileModal,
    StewartTitleAuthenticationModal,
    StewartTitlePortalModal,
    TitlePlusPortalModalComponent,
    ChicagoTitlePortalModal,
    FctPortalModal,
    OrdinalNumberPipe,
    ThirdPartyCredentialsComponent,
    MessagesComponent,
    ShareDocumentsComponent,
    SimpleCredentialsModalComponent,
    TeranetChangeFieldCodeModal,
    CustomEventModalComponent,
    EventSchedulingModalComponent,
    InformInviteeModalComponent,
    DateTimePickerComponent,
    ChangeFontComponent,
    CustomerSupportComponent,
    LinkSharedDocumentsModalComponent,
    AppearanceModalComponent,
    AccountingModalComponent,
    ImportDriverLicenceModalComponent,
    WarningLevelModal,
    WelcomePageModalComponent,
    CustomerBillingPortalModal,
    LoginLtsaModalComponent,
    AccountingExportModalComponent,
    OtherIntegrationServicesModalComponent,
    ChatAIComponent,
    AICardComponent,
    ChatAIRoboComponent,
    ChatAIToasterComponent,
    ChatAITermsConditionsComponent,
    SpinningComponent
  ],
  exports: [
    OrdinalNumberPipe,
    CustomEventModalComponent,
    InformInviteeModalComponent,
    DateTimePickerComponent,
    ChangeFontComponent,
    CustomerSupportComponent
  ],
  providers: [
    GetGlobalSaveModelService,
    WindowRef,
    MatterPollingService,
    JurisdictionService,
    DefaultFieldsService,
    DocumentProfileService,
    AccountService,
    MatterNotificationConfigService,
    AssignmentNotificationConfigService,
    AccountReminderConfigurationService,
    LegalFirmService,
    DocketService,
    JurisdictionDepartmentsService,
    CustomDPDateCodeService,
    UserDefinedFieldsService,
    TrustAccountsService,
    MortgageeEnclosureService,
    StatementConfigService,
    StaffProfilesService,
    LawyerMoveService,
    MatterService,
    TemplateCodeService,
    ProjectMatterCacheService,
    EventService,
    ProjectService,
    ContactQueryService,
    ContactCommandService,
    EmailFieldService,
    TaxRateService,
    SoaTrustLedgerHelperService,
    DpDirtyCheckService,
    MessageService,
    ExportTemplatesService,
    MatterParticipantService,
    TitleInsuranceConfigurationService,
    CurrencyPipe,
    DecimalPipe,
    PercentPipe,
    UnityBillingService,
    DepositManagerService,
    StatementAdjustmentService,
    PrecedentService,
    SoajFieldCodeService,
    MortgageSoAdjService,
    FieldCodeService,
    DriverLicenceParsingService,
    CirfHelperService,
    CirfConfigService,
    RemoteSigningConfigurationService,
    AccountingExportService,
    ContactSectionedService,
    ApicFeeService,
    ERegistrationService,
    AdjustmentTemplateService,
    AdjudicationService
  ],
})
export class MainModule {
}