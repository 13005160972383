<div class="row" >
    <div class="col-lg-12">
        <div class="partial-dates {{customStyleClass}}" (keydown)="captureKeyPress($event);" [hidden]="!displayDate"
             [ngClass]="{'partial-dates-focus': showFocusClass}">
            <input maxlength="2"
                   [ngClass]="{'red bold':(isHolidayOrWeekendDate())}"
                   title="{{dateHoliday}}"
                   name="dateDay"
                   #refDateDay
                   type="text"
                   dp-noWhiteSpace
                   numberOnly
                   [dp-read-only]="dateDisable || disableAll"
                   [fieldCode]="fieldCode"
                   placeholder="DD"
                   [fieldKey]="fieldKey"
                   [dynamicHelpCallBackFunction]="dynamicHelpCallBackFunctionForDate()"
                   statusBarHelp
                   dp-error-validator
                   [(ngModel)]="dateDay"
                   (ngModelChange) = "changeFocusToMonthField($event)"
                   (focus)="setFocusedField(0)"
                   (blur)="verifyDateInput($event.target.value, 'day')"> <span>/</span>
            <input maxlength="2"
                   [ngClass]="{'red bold':(isHolidayOrWeekendDate())}"
                   title="{{dateHoliday}}"
                   tabindex="-1"
                   name="dateMonth"
                   #refDateMonth
                   type="text"
                   dp-noWhiteSpace
                   numberOnly
                   [fieldKey]="fieldKey"
                   [dynamicHelpCallBackFunction]="dynamicHelpCallBackFunctionForDate()"
                   statusBarHelp
                   dp-error-validator
                   [dp-read-only]="dateDisable || disableAll"
                   placeholder="MM"
                   [(ngModel)]="dateMonth"
                   (ngModelChange) = "changeFocusToYearField($event)"
                   (focus)="setFocusedField(1)"
                   (blur)="verifyDateInput($event.target.value, 'month')"> <span [hidden]="hideDateYear">/</span>
            <input [ngClass]="{'date-year-field-invisible':hideDateYear, 'red bold':(isHolidayOrWeekendDate()), 'margin-right-10' : disableAll}"
                   title="{{dateHoliday}}"
                   maxlength="4"
                   tabindex="-1"
                   name="dateYear"
                   #refDateYear
                   type="text"
                   dp-noWhiteSpace
                   [dynamicHelpCallBackFunction]="dynamicHelpCallBackFunctionForDate()"
                   statusBarHelp
                   [fieldKey]="fieldKey"
                   dp-error-validator
                   numberOnly
                   [dp-read-only]="dateDisable || disableAll"
                   placeholder="YYYY"
                   [(ngModel)]="dateYear"
                   (ngModelChange) = "emitChangeEvent($event)"
                   (focus)="setFocusedField(2)"
                   (blur)="verifyDateInput($event.target.value, 'year')">

                <div class="mydp">
                    <div class="selectiongroup">
                        <div class="selbtngroup">
                            <button type="button" aria-label="Open Calendar" autocomplete="offQDVTj" name="nameSkpLe" spellcheck="false"
                                *ngIf="!disableAll" #myDatePicker="angular-mydatepicker" [options]="myDatePickerOptions"
                                (dateChanged)="onDatePickerDateChange($event)" (dblclick)="onDatePickerDblClick($event)"
                                [selDate]="selectedDateNormal" [selector]="openCalendarWindow"
                                class="right btnpicker btnpickerenabled btnleftborderradius"
                                [ngClass]="{'my-date-picker-focus': showFocusClass}" angular-mydatepicker name="mydate"
                                (click)="myDatePicker.toggleCalendar()">
                                <span class="mydpicon icon-mydpcalendar"><i class="fa fa-calendar"> </i></span>
                            </button>
                        </div>
                    </div>
                </div>
        </div>
        <div  style="width: 152px;" (keydown)="captureKeyPress($event);" *ngIf="!displayDate">
            <input #refOnDemand
                   id='onDemand'
                   name='onDemand'
                   [dynamicHelpCallBackFunction]="dynamicHelpCallBackFunctionForOnDemand()"
                   statusBarHelp
                   type="text"
                   class="form-control ondemand"
                   [value]="onDemandValue">
        </div>
        <div class="date-string" *ngIf="showFormattedDateLabel"
             [ngClass]="{'red bold':(isHolidayOrWeekendDate())}"
             title="{{dateHoliday}}">
            {{formattedDateLabel}}
        </div>
        <div class="clear"></div>
    </div>
</div>
