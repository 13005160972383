import {Observable} from 'rxjs';
import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';

export interface CleanUpMatterComponent {

  cleanUpMatter: () => boolean | Observable<boolean>;
}

@Injectable()
export class CleanUpMatterDataGuard implements CanDeactivate<CleanUpMatterComponent> {

  constructor() {

  }

  canDeactivate(component: CleanUpMatterComponent): boolean | Observable<boolean> {
    return component.cleanUpMatter() ? true : false;
  }
}
