import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {DpBooleanValue} from '../dp-boolean';
import Utils from '../../../shared-main/utils';
import {Matter} from '../matter';

export type MATTERHOLDBACKTYPE = 'ADVANCE' | 'OTHER';

export const HOLDBACK_TYPE = {
  advance: 'ADVANCE',
  other: 'OTHER'
};

export type HOLDBACKSTATUS = 'NOT_RELEASED' | 'PARTIAL_RELEASE' | 'RELEASED';

export const HOLDBACK_STATUS_TYPE = {
  notReleased: 'NOT_RELEASED',
  partialRelease: 'PARTIAL_RELEASE',
  released: 'RELEASED'

};

export class MatterHoldback extends BaseEntity {
  id: number;
  static clientAssignedIdentifierEntity: boolean = true; //Marker property for indicating client side assigns the ID to this entity
  advanceDate: string;
  holdbackPercentage: number;
  holdbackAmount: number;
  amountInTrust: number;
  amountReleased: number;
  holdbackType: MATTERHOLDBACKTYPE;
  otherHoldbackType: string;
  releaseStatus: HOLDBACKSTATUS;
  releaseDate: string;
  mortgageId: number;
  advanceHoldbackPriority: number;
  holdbackOrder: number;

  isStatusNotReleased(): boolean {
    return this.releaseStatus == HOLDBACK_STATUS_TYPE.notReleased;
  }

  isStatusPartialRelease(): boolean {
    return this.releaseStatus == HOLDBACK_STATUS_TYPE.partialRelease;
  }

  isStatusReleased(): boolean {
    return this.releaseStatus == HOLDBACK_STATUS_TYPE.released;
  }

  isAdvanceHoldback(): boolean {
    return this.holdbackType == HOLDBACK_TYPE.advance;
  }

  isOtherHoldback(): boolean {
    return this.holdbackType == HOLDBACK_TYPE.other;
  }

  getHoldbackMortgagePriority(matter: Matter): string {
    return `${ Utils.getOrdinal(this.getHoldbackMortgagePriorityWithoutOrdinal(matter)) }`;
  }

  getHoldbackMortgagePriorityWithoutOrdinal(matter: Matter, ignoreMortgageIdInCopyHoldBack ?: boolean): number {
    let mortgagePriority: number;
    if (this.mortgageId && !ignoreMortgageIdInCopyHoldBack) {
      let mortgage = matter.getMortgageById(this.mortgageId);
      mortgagePriority = mortgage && matter.getMortgagePriorityById(mortgage);
    } else {
      mortgagePriority = this.advanceHoldbackPriority;
    }
    return mortgagePriority;
  }

}

export class AdvanceMatterHoldbackConfig extends BaseEntity {

  id: number;
  releaseStatus: HOLDBACKSTATUS;
  releaseDate: string;
  lawyerResponsible: DpBooleanValue;
  subCompletionDate: string;
}

export class MortgageHoldbackConfig extends BaseEntity {
  description: string;
  key: string;
  value: number;
  threshold: number;
  highValue: number;
  provinceCode: string;
}

export class HoldbackSummary extends BaseEntity {
  matterRecordNumber: string;
  substantialCompletionDate: string;
  advanceHoldbackCount: number;
  otherHoldbackCount: number;
}

