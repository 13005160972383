<div class="fields-group" *ngIf="matter && !matter.isSale"
     [ngClass]="{'margin-bottom-0' : mortgage.isEmpMortgage(), 'margin-top-0' : !mortgage.isEmpMortgage()}">
    <div class="main-level">
        <span class="title">Mortgage Broker Information</span>
    </div>
    <div class="form-group" *ngIf="mortgageBroker && mortgageBroker.editMode">
        <div class="col-lg-2 control-label">Mortgage Broker</div>
        <div class="col-lg-10">
            <p-autoComplete id="mortgage-broker-autocomplete" #mortgageBrokerField
                            name="mortgage-broker-autocomplete"
                            placeholder="Search by Name, Address or Add new"
                            [(ngModel)]="mortgageBroker.dataModel"
                            [suggestions]="mortgageBrokerOptions"
                            [class.autocomplete-searching]="mortgageBrokersLoading"
                            (completeMethod)="searchMortgageBrokers($event)"
                            field="organizationName"
                            [minLength]="0"
                            [delay]="1000"
                            maxlength="75"
                            scrollHeight="350px"
                            (onSelect)="selectExistingMortgageBroker($event)"
                            (focusout)="cleanupBrokerField()"
                            autocomplete="off" class="autocomplete-search-icon">
                <ng-template let-mortgageBrokerItem pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [ngClass]="{'bold-font' : isAddNew(mortgageBrokerItem)}"
                        [disableItem]="mortgageBrokerItem">
                        <span class="col-lg-6 text-overflow">{{getMortgageBrokerDisplayNameFromContact(mortgageBrokerItem)}}</span>
                        <span class="col-lg-6 text-overflow">{{getContactDisplayMailingAddress(mortgageBrokerItem)}}</span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>
    <ng-container *ngIf="mortgageBroker && !mortgageBroker.editMode">
        <div class="form-group">
            <div class="col-lg-2 control-label">Mortgage Broker</div>
            <div class="col-lg-10 mortgage-broker-header">
                <input type="text"
                       class="form-control mortgage-broker-header-name"
                       readonly="readonly"
                       [value]="mortgageBrokerDisplayName"
                       autocomplete="off"/>
                <button class="button-glyph"
                        (click)="deleteMortgageBroker()"
                        *ngIf="mortgageBroker.dataModel"
                        type="button"><i class="glyphicon glyphicon-trash"></i></button>
            </div>
        </div>
    </ng-container>
    <div class="form-group">
        <div class="col-lg-2 control-label">Attention</div>
        <div class="col-lg-4">
            <p-autoComplete #mortgageBrokerAttentionField
                            id="mortgage-broker-attention"
                            name="mortgage-broker-attention"
                            [placeholder]="mortgageBroker && !mortgageBroker.editMode ? 'Select a Private Contact from the Mortgage Broker selected' : 'Search by Name, Address'"
                            class="dropdown-arrow-refferedBy"
                            minLength="1"
                            delay="1000"
                            maxlength="75"
                            scrollHeight="350px"
                            [class.dropdown-arrow-refferedBy]="true"
                            [class.autocomplete-searching]="mortgageBrokerAttentionLoading"
                            (completeMethod)="searchAttentions($event)"
                            autocomplete="off"
                            tabIndexIgnore
                            [ngModel]="mortgage.mortgageBrokerAttention"
                            (focusout)="cleanupAttentionField()"
                            trimmed
                            (ngModelChange)="notifyChange()"
                            [suggestions]="mortgageBrokerAttentionOptions"
                            [dropdown]="showAttentionsDropDownArrow"
                            (keyup)="onUserInput($event)"
                            (keydown.space)="utils.handleSpaceKeyDropdown($event, mortgage.mortgageBrokerAttention, onMortgageBrokerAttentionDropdownClick)"
                            (keydown.enter)="utils.handleEnterKeyDropdown($event, mortgage.mortgageBrokerAttention, onMortgageBrokerAttentionDropdownClick)"
                            (onDropdownClick)="onMortgageBrokerAttentionDropdownClick($event)"
                            [ngClass]="{'autocomplete-search-icon': mortgageBroker && mortgageBroker.editMode, 'showWideDropDown' : mortgageBroker && mortgageBroker.editMode}"
                            (onSelect)="onMortgageBrokerAttentionSelect($event)">

                <ng-template let-attention pTemplate="item">
                    <ng-container *ngIf="mortgageBroker && mortgageBroker.editMode">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="attention"
                            [ngClass]="{'font-style-italic': attention?.fullName == constantNoResultValue}">
                                        <span class="col-lg-5 text-overflow" *ngIf="attention.displayName != null">
                                               {{attention.displayName }}</span>
                            <span class="col-lg-3  text-overflow" *ngIf="attention.parentOrganizationName != null">
                                            {{attention.parentOrganizationName}}</span>
                            <span class="col-lg-4  text-overflow"
                                  *ngIf="attention.parentOrganizationAddress != null">
                                            {{attention.parentOrganizationAddress}}</span>

                        </li>
                    </ng-container>
                    <ng-container *ngIf="mortgageBroker && !mortgageBroker.editMode">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="attention"
                            [ngClass]="{'font-style-italic': attention?.fullName == constantNoResultValue}">
                                        <span class="col-lg-12 text-overflow">
                                               {{attention.isInstanceTypePerson ? attention.surnameLastFullName : attention.displayName }}</span>

                        </li>
                    </ng-container>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>
    <div class="form-group">
        <ng-container *ngIf="mortgageBroker && !mortgageBroker.editMode">
            <div class="col-lg-2 control-label">Brokerage Fee</div>
            <div class="col-lg-4">
                <input type="text"
                       name="mortgage.brokerageFee"
                       [(ngModel)]="mortgage.brokerageFee"
                       (ngModelChange)="notifyChange();updateBrokerFeeTrustLedger()"
                       dp-default-currency
                       dp-currency
                       class="form-control text-right"/>
            </div>
        </ng-container>
    </div>
    <div class="form-group">
        <ng-container *ngIf="mortgageBroker && !mortgageBroker.editMode">
            <div class="col-lg-2"></div>
            <div class="col-lg-10">
                <dp-checkbox fieldId="automaticallyUpdateBrokerageFeeTrustLedger">
                    <input type="checkbox"
                           id="automaticallyUpdateBrokerageFeeTrustLedger"
                           [(ngModel)]="mortgage.automaticallyUpdateBrokerageFeeTrustLedger"
                           (ngModelChange)="notifyChange();updateBrokerFeeTrustLedger()"
                           [checked]="mortgage.automaticallyUpdateBrokerageFeeTrustLedger"/>
                </dp-checkbox>
                <label class="control-label">Automatically insert/update Brokerage Fee into the Trust Ledger</label>
            </div>
            <div class="row" *ngIf="mortgage.automaticallyUpdateBrokerageFeeTrustLedger">
                <div class="col-lg-2"></div>
                <div class="col-lg-10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label class="control-label padding-top-0">(Unchecking the field will remove the Brokerage fee item from the Trust Ledger)</label>
                </div>
            </div>
        </ng-container>
    </div>
</div>
