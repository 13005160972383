import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SoaExportData} from './soa-export-data';
import {User} from './user';

export class SoaExportConfig extends BaseEntity {

  id: number;
  sendToExport: boolean;
  importToAccounting: boolean;
  matterId: number;
  statementOfAccountId: number;
  soaExportData: SoaExportData[] = [];
  createdByUser: User;
  createdTimeStamp: number;
  matterType: string;
  matterSoaSheetDetails: string;
  uiDisplayField: string;
  soaProgressionType: string;

  constructor(soa?: SoaExportConfig) {
    super(soa);
    for (let prop in soa) {
      if (soa.hasOwnProperty(prop)) {
        this[ prop ] = soa[ prop ];
      }
    }
    if (soa) {
      if (soa && Array.isArray(soa.soaExportData)) {
        for (let i: number = 0; i < soa.soaExportData.length; ++i) {
          this.soaExportData[ i ] = new SoaExportData(soa.soaExportData[ i ]);

        }
      }

    }
  }

}
