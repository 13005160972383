import {Tab} from '../../shared/tabbing/tab';
import {ErrorTab} from '../../shared/error-handling/error-tab';
import {Project} from './project';
import {ProjectComponent} from '../project.component';
import {ProjectListState} from './project-list-state';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {Matter} from '../../matters/shared';
import {DataPropagationCommand} from './data-propagation-commands';
import * as _ from 'lodash';
import {Document} from '../../matters/document-production/document';
import {AuthZService} from '../../core/authz/auth-z.service';

export class ProjectTab extends Tab {

  constructor(json?: ProjectTab) {
    super(json);
    if (json) {
      this.project = new Project(json.project);
      if (json.backEndProject) {
        this.backEndProject = new Project(json.backEndProject);
      }
      this.matterDocsBeforeUpdate = [];
      if (json.matterDocsBeforeUpdate) {
        json.matterDocsBeforeUpdate.forEach(s => {
          this.matterDocsBeforeUpdate.push(new Document(s));
        });
      }
      this.matterDocsAfterUpdate = [];
      if (json.matterDocsAfterUpdate) {
        json.matterDocsAfterUpdate.forEach(s => {
          this.matterDocsAfterUpdate.push(new Document(s));
        });
      }
      this.errorTab = new ErrorTab(json.errorTab);
    }

  }

  project: Project;
  backEndProject: Project;
  projectComponent: ProjectComponent;
  errorTab: ErrorTab;
  updateThirdPartyData: boolean = false;
  projectListState: ProjectListState;
  loggedInTeranetUser: TeranetUser;
  subRoute: string;
  subRouteId: number;
  matter: Matter;
  projectMatters: Matter[];
  isProjectMatterOpen: boolean;
  dataPropagationCommands: DataPropagationCommand[] = [];
  matterDocsBeforeUpdate: Document[] = [];
  matterDocsAfterUpdate: Document[] = [];
  matterSharedDocIds: number[] = [];
  showSharingNotifier: boolean = false;

  static createProjectTab(project: Project): ProjectTab {
    let tab: ProjectTab = new ProjectTab();
    tab.tabType = 'project';
    tab.project = project;
    tab.type = 'project';
    tab.route = 'main/projects/project';
    return tab;
  }

  get routeParams(): string[] {
    if (this.id) {
      if (this.subRoute && this.subRouteId) {
        return [ this.route, this.id.toString(), this.subRoute, this.subRouteId.toString() ];
      } else if (this.subRoute) {
        return [ this.route, this.id.toString(), this.subRoute ];
      } else {
        return [ this.route, this.id.toString() ];
      }
    } else {
      return [ this.route ];
    }
  }

  get title(): string {
    return this.project && this.project.projectRecordNumber;
  }

  get lineNumber(): string {
    return this.project && this.project.projectName;
  }

  isLocked(): boolean {
    return this.project && this.project.lockedByOthers;
  }

  isDirty(): boolean {
    return this.project && this.project.dirty;
  }

  get id(): number {
    return this.project && (!this.project.id && this.project.tempIdForNewProject) ? this.project && this.project.tempIdForNewProject : this.project && this.project.id;
  }

  deCoupleUIComponent(): void {
    this.projectComponent = null;
  }

  addToDataPropagationCommands(type: string, command?: any, onlyOneNeededForSameCmdType: boolean = false, parameter?: any): void {
    if (this.projectMatters && this.projectMatters.length) {
      let dataPropagationCommand = new DataPropagationCommand();
      dataPropagationCommand.type = type;
      dataPropagationCommand.command = command ? command : null;
      dataPropagationCommand.parameter = parameter;
      if (onlyOneNeededForSameCmdType) {
        const sameCmdIdx: number = this.dataPropagationCommands.findIndex(cmd => cmd.type == type && (!parameter || !cmd.parameter || (parameter && cmd.parameter && parameter == cmd.parameter)));
        if (sameCmdIdx > -1) {
          return;
        }
      }
      this.dataPropagationCommands.push(dataPropagationCommand);
    }
  }

  removeDataPropagationCommand(type: string) {
    if (Array.isArray(this.dataPropagationCommands)) {
      _.remove(this.dataPropagationCommands, (dpc: DataPropagationCommand) => dpc.type === type);
    }
  }

  isProjectReadOnly(authZService: AuthZService): boolean {
    return authZService.isProjectReadOnlyAccess() || this.project.lockedByOthers || this.isProjectMatterOpen;
  }

}

