import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type ReferredByType = 'MANUAL_ENTRY' | 'CLIENT' | 'REAL_ESTATE_BROKER' | 'REAL_ESTATE_AGENT' | 'SURVEYOR' |
  'CONDO_CORP' | 'INSURANCE_BROKER' | 'MANAGE_COMP' | 'MORTGAGE_BROKER' | 'SOLICITOR';

export class ReferredByInfo extends BaseEntity {
  id: number;
  referredBy: string;
  referredByType: ReferredByType;
  referredByAttention: string;
  sourceContactId: number; // When we need update referredByAttention after attention name contact is changed in contact tab, this will be populated
}
