import {PayoutStatement} from '../../../../shared-main/telus/payout-statement';

export class PayoutHistoryWrapper {
  id: number;
  parentItem: PayoutHistoryWrapper;
  name: string;
  expanded: boolean = false;
  visible: boolean = true;
  editMode: boolean;
  payoutStatement: PayoutStatement;

}
