<form class="form-horizontal container-fluid custom-modal-container" *ngIf="localAdj" (click)="clearCachedDetails()" (keyup)="clearCachedDetails()">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : Tenancy - Current Rental Period</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">

        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Does tenant occupy entire premises ?</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="tenantOccupiesEntirePremises"
                            name="tenantOccupiesEntirePremises"
                            [(ngModel)]="localAdj.tenantOccupiesEntirePremises"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div *ngIf="!localAdj.tenantOccupiesEntirePremisesBoolean" class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Tenant occupies</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="tenantOccupies"
                            name="tenantOccupies"
                            [(ngModel)]="localAdj.tenantOccupies"
                            class="form-control"
                    >
                        <option *ngFor="let tenancy of tenancyTypes" [value]="tenancy.value">
                            {{tenancy.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div *ngIf = "localAdj.tenantOccupies == 'OTHER' && (!localAdj.tenantOccupiesEntirePremisesBoolean)" class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Description of area occupied</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           name="areaOccupiedDescription"
                           id="areaOccupiedDescription"
                           maxlength="20"
                           [(ngModel)]="localAdj.areaOccupiedDescription"
                           trimmed
                           class="form-control"
                           dp-error-validator
                           [fieldKey]="'matter.soadj.tenancy.current.area.occupied.description'"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Commencement date of current rental period</label>
                </div>
                <div class="col-lg-4">
                    <dp-partial-date #fromDate
                                     [disableAll]="localAdj.commencementDate == 'COMMENCING_ON_ADJUSTMENT_DATE'"
                                     (dateChange)="onDateChange($event ,'commencementDate')"
                                     [fieldKey]="'matter.soadj.tenancy.current.commencement.date.empty'"
                                     dp-error-validator
                                     [inputDate]="localAdj.commencementDate"
                    >
                    </dp-partial-date>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">End date of current rental period</label>
                </div>
                <div class="col-lg-4">
                    <dp-partial-date #fromDate
                                     [disableAll]="localAdj.endDate == 'COMMENCING_ON_ADJUSTMENT_DATE'"
                                     (dateChange)="onDateChange($event ,'endDate')"
                                     [fieldKey]="'matter.soadj.tenancy.current.end.date.empty'"
                                     dp-error-validator
                                     [inputDate]="localAdj.endDate"
                    >
                    </dp-partial-date>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-3 vertical-align-label">
                <label class="control-label">Rental amount</label>
            </div>
            <div class="col-lg-2">
                <input type="text"
                       id="rentalAmount"
                       name="rentalAmount"
                       [(ngModel)]="localAdj.rentalAmount"
                       class="form-control"
                       dp-currency
                       dp-error-validator
                       [fieldKey]="'matter.soadj.tenancy.current.rental.amount'"
                />
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-3 vertical-align-label">
                <label class="control-label">Has {{vendorLabel}} collected rent for the above rental period ?</label>

            </div>
            <div class="col-lg-1">
                <select type="text"
                        id="vendorCollectedRent"
                        name="vendorCollectedRent"
                        [(ngModel)]="localAdj.vendorCollectedRent"
                        class="form-control"
                >
                    <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                        {{yesNo.label}}
                    </option>
                </select>
            </div>
        </div>


        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" [dp-province-field-label]="'matter.soa.tenancy-current.modal.adjustFor'">Adjust for</label>
                </div>
                <div class="col-lg-1" *dpShowByProvince="'matter.soa.hst-sale-price-modal.adjustFor'">
                    <select type="text"
                            id="taxType"
                            name="taxType"
                            [(ngModel)]="localAdj.taxType"
                            class="form-control"
                    >
                        <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                            {{taxType.label}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="applyTax"
                            name="applyTax"
                            [(ngModel)]="localAdj.applyTax"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>

                <div *ngIf="localAdj.applyTaxBoolean" class="col-lg-2 vertical-align-label">
                    <label class="control-label">{{localAdj.taxType}} percentage is</label>
                </div>
                <div class="col-lg-1" *ngIf="localAdj.applyTaxBoolean && localAdj.taxType == context.taxRateType">
                    <input type="text"
                           id="hstPercentage"
                           name="hstPercentage"
                           [(ngModel)]="localAdj.hstRate"
                           class="form-control"
                           dp-percentage
                    />
                </div>
                <div class="col-lg-1" *ngIf="localAdj.applyTaxBoolean && localAdj.taxType == 'GST' && context.defaultPstRate">
                    <input type="text"
                           id="gstPercentage"
                           name="gstPercentage"
                           [(ngModel)]="localAdj.gstRate"
                           class="form-control"
                           dp-percentage
                    />
                </div>
                <div class="col-lg-1" *ngIf="localAdj.applyTaxBoolean">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>
        <!-- if you're here to bring back PST just change false to localAdj.taxType == 'GST' , you're welcome -->
        <div class="form-group" *ngIf="false">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Adjust for PST</label>
                </div>
                <div class="col-lg-1">
                    <select type="text"
                            id="applyPst"
                            name="applyPst"
                            [(ngModel)]="localAdj.applyPst"
                            class="form-control"
                    >
                        <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                            {{yesNo.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">PST percentage is</label>
                </div>
                <div class="col-lg-1">
                    <input type="text"
                           id="pstPercentage"
                           name="taxPercentage"
                           [(ngModel)]="localAdj.pstRate"
                           class="form-control"
                           dp-percentage
                    />
                </div>
                <div class="col-lg-1">
                    <label class="control-label text-left">%</label>
                </div>
            </div>
        </div>

        <!-- adjustment report -->
        <div style="border-top: 1px solid #cccccc;">

            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{localAdj.adjustmentDescription | uppercase}}</label>
                </div>
            </div>

            <div *ngFor="let line of details">
                <div class="col-lg-12">
                    <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                    <label *ngIf="line.value && line.value.length > 0" class="control-label col-lg-2">{{line.value}}</label>
                </div>
            </div>
            <div>
                <div *ngIf="localAdj.creditType == 'VENDOR'" class="col-lg-12">
                    <label *ngIf="localAdj.creditType == 'VENDOR'" class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentHeading.rightColumnListsCreditsTo}}</label>
                    <label *ngIf="localAdj.getVendorsShare(context.closingDate, context.currentMatter.isPaysForDateOfClosingVendor) >= 0"
                           class="control-label col-lg-6">{{localAdj.getTotalAmount(context.closingDate, context.currentMatter.isPaysForDateOfClosingVendor) | currency:CAD:'symbol'}}</label>
                    <label *ngIf="localAdj.getVendorsShare(context.closingDate, context.currentMatter.isPaysForDateOfClosingVendor) < 0 " class="control-label col-lg-6">$??????????.??</label>
                </div>
                <div *ngIf="localAdj.creditType == 'PURCHASER'" class="col-lg-12">
                    <label *ngIf="localAdj.creditType == 'PURCHASER'" class="control-label col-lg-3 text-left">Credit {{context.statementAdjustmentHeading.leftColumnListsCreditsTo}}</label>
                    <label *ngIf="localAdj.getVendorsShare(context.closingDate, context.currentMatter.isPaysForDateOfClosingVendor) >= 0" class="control-label col-lg-4">{{ localAdj.getTotalAmount(context.closingDate, context.currentMatter.isPaysForDateOfClosingVendor) | currency:CAD:'symbol'}}</label>
                    <label *ngIf="localAdj.getVendorsShare(context.closingDate, context.currentMatter.isPaysForDateOfClosingVendor) < 0 " class="control-label col-lg-4">$??????????.??</label>
                </div>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
            <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                <span>Delete</span>
            </button>

        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
