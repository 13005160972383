import {Component, OnInit} from '@angular/core';
import {AcceptTermsConditionsService} from './accept-terms-conditions.service';
import {AuthNService} from '../../../core';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {Logger} from '@nsalaun/ng-logger';
import {AuthZService} from '../../../core/authz/auth-z.service';
import {AcceptTermsConditions, AcceptTermsConditionsDTO} from './accept-terms-conditions';
import {LockScreenService} from '../../../core/lock-screen.service';
import {UtilsService} from '../../../main/utils.service';

@Component({
  selector: 'dp-accept-terms-conditions',
  templateUrl: './accept-terms-conditions.component.html',
  styleUrls: [
    '../../unity-login.styles.scss'
  ]
})

export class AcceptTermsConditionsComponent implements OnInit {

  termsAndConditions: string;
  accepted: boolean = false;

  constructor(public acceptTermsConditionsService: AcceptTermsConditionsService,
              public authNService: AuthNService,
              public authZService: AuthZService,
              public router: Router,
              public dialogService: DialogService,
              public activatedRoute: ActivatedRoute,
              public lockScreenService: LockScreenService,
              public utilsService: UtilsService,
              public logger: Logger) {
  }

  ngOnInit() {
    this.lockScreenService.lockForUpdate = false;
    this.acceptTermsConditionsService.getTermsAndConditions().subscribe(
      (res: AcceptTermsConditions) => {
        if (res) {
          console.log(res);
          this.termsAndConditions = res.termsAndConditions;
        }

      }
    );
  }

  submit() {

    if (this.accepted) {
      this.lockScreenService.lockForUpdate = true;
      let sessionUser = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
      if (sessionUser) {
        let userJSON = JSON.parse(sessionUser);

        let acceptTermsConditionsDTO = new AcceptTermsConditionsDTO();
        acceptTermsConditionsDTO.userId = Number(userJSON.id);
        this.acceptTermsConditionsService.setupTermsAndConditions(acceptTermsConditionsDTO).subscribe(
          (res: any) => {
            userJSON.termsAcceptedDate = res.termsAcceptedDate;
            sessionStorage.setItem(SESSION_STORAGE_KEYS.user, JSON.stringify(userJSON));

            this.authZService.navigateToGetRedirectUrl();
          },
          (err) => {
            this.lockScreenService.lockForUpdate = false;
            this.logger.info('setupTermsAndConditions | submit() | err -res = ', err);
            let errorMessage: string = '';
            if (err && err.fieldErrors && err.fieldErrors.length > 0) {
              err.fieldErrors.forEach((error) => {
                errorMessage = errorMessage.concat(error.message).concat('\n');
              });
            } else {
              errorMessage = err.message;
            }
            this.dialogService.confirm('Error', errorMessage, true);
          }
        );
      } else {
        this.logger.info('acceptTermsConditionsForm | submit() | sessionUser invalid!');
      }

    }

  }

}

