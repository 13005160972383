<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Manage Categories</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="fields-group margin-top-0">
                <div class="fixed-header">
                    <div class="col-lg-3 pull-right text-right padding-bottom-10">
                        <button label="Manage Categories" class="dp-btn" (click)="addNewCategory()" >
                            Add New Category
                        </button>
                    </div>
                    <div class="col-lg-12 main-level">
                        <span class="title">Category Name</span>
                    </div>
                </div>

                <form class="form-horizontal">

                    <div class="form-group padding-top-90" *ngIf="MatterTypeInfos && MatterTypeInfos.length>0">
                        <div class="col-sm-12 padding-bottom-10 padding-left-20 padding-right-20"
                             *ngFor="let cat of documentProductionCategories">
                            <a (click)="openCategory(cat.id)">{{getCategoryDescription(cat)}}</a>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
