import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../shared/matter';
import {ErrorService} from '../../../shared/error-handling/error-service';
import Utils from '../../../shared-main/utils';
import {LateClosingInterest, PrincipalAmountTypeForLateInterest} from '../../shared/late-closing-interest';
import {ClosingDatePayment} from '../../shared/closing-date-payment';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import moment from 'moment';
import {PercentPipe} from '@angular/common';
import {SelectItem} from 'primeng/api';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class LateClosingDetailsModalContext {
  title: string;
  matter: Matter;
  lateClosingInterest: LateClosingInterest;
  closingDatePayment: ClosingDatePayment;
}

@Component({
  selector: 'dp-late-closing-details-modal',
  templateUrl: 'late-closing-details-modal.component.html',
  providers: [ErrorService]
})
export class LateClosingDetailsModalComponent extends ModalComponent<LateClosingDetailsModalContext> implements OnInit {
  lateClosingInterest: LateClosingInterest;
  delayedAmountOptionText: string = PrincipalAmountTypeForLateInterest.delayedAmountOptionText;
  cashToCloseOptionText: string; // = PrincipalAmountTypeForLateInterest.cashToCloseOptionText;
  registeredMortgageOptionText: string = PrincipalAmountTypeForLateInterest.registeredMortgageOptionText;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  principalAmountOptions: SelectItem[] = [];

  constructor(
    public dialog: MatDialogRef<LateClosingDetailsModalComponent>,
    public percentPipe: PercentPipe,
    @Inject(MAT_DIALOG_DATA) context?: LateClosingDetailsModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    if (this.context.matter) {
      switch (this.context.matter.provinceCode) {
        case 'MB':
        case 'SK':
          this.cashToCloseOptionText = PrincipalAmountTypeForLateInterest.balanceToCloseOptionText;
          break;
        case 'AB':
        default:
          this.cashToCloseOptionText = PrincipalAmountTypeForLateInterest.cashToCloseOptionText;
          break;
      }
    }

    this.updatePrincipalAmountOptions();
    if (!this.context.lateClosingInterest) {
      this.lateClosingInterest = new LateClosingInterest();
      this.lateClosingInterest.interestRate = 0.0;
      this.lateClosingInterest.principalAmount = this.delayedAmountOptionText;
    } else {
      this.lateClosingInterest = new LateClosingInterest(this.context.lateClosingInterest);
    }
    //only init the fromDate for MB/SK/AB as closingDate
    if (!this.lateClosingInterest.fromDate && (this.context.matter.isPurchase || this.context.matter.isSale)) {
      switch (this.context.matter.provinceCode) {
        case 'MB':
        case 'SK':
        case 'AB':
          this.lateClosingInterest.fromDate = this.context.matter.getClosingDate();
          break;
      }
    }
  }

  ok(): void {
    if (!this.modalErrorComponent.anyErrorExist()) {
      if (!this.lateClosingInterest.fromDate) {
        this.modalErrorComponent.createCustomDPSaveError(
          'matter.matterOpening.lateClosingInterest.fromDate',
          'An Effective From date is required',
          '',
          'ERROR'
        );
      } else if (this.isInvalidFromDate()) {
        /*        else if ((moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') >= (moment(this.context.closingDatePayment.lateClosingDate, 'YYYY/MM/DD')))
                  || (this.context.matter.isClosingDateAvailable() && this.context.matter.isClosingDateValid() && moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') < (moment(this.context.matter.matterCloseDate, 'YYYY/MM/DD')))) {*/
        this.modalErrorComponent.createCustomDPSaveError(
          'matter.matterOpening.lateClosingInterest.fromDate',
          'A valid Effective From date is required',
          '',
          'ERROR'
        );
      } else {
        this.addToLateClosingInterest();
        this.dialog.close();
      }
    }
  }

  isInvalidFromDate(): boolean {
    switch (this.context.matter.provinceCode) {
      case 'MB':
      case 'SK':
        //if ClosingDate exists and fromDate < closingDate OR lateClosingDate exists and fromDate >= lateClosingDate
        return (
          (!Utils.isNotValidDate(this.context.matter.matterCloseDate) &&
            moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') <
              moment(this.context.matter.matterCloseDate, 'YYYY/MM/DD')) ||
          (!Utils.isNotValidDate(this.context.closingDatePayment.lateClosingDate) &&
            moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') >=
              moment(this.context.closingDatePayment.lateClosingDate, 'YYYY/MM/DD'))
        );
      case 'AB':
      default:
        //if fromDate >= lateClosingDate  OR fromDate < ClosingDate
        return (
          moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') >=
            moment(this.context.closingDatePayment.lateClosingDate, 'YYYY/MM/DD') ||
          (this.context.matter.isClosingDateAvailable() &&
            this.context.matter.isClosingDateValid() &&
            moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') <
              moment(this.context.matter.matterCloseDate, 'YYYY/MM/DD'))
        );
    }
  }

  addToLateClosingInterest(): void {
    this.context.closingDatePayment.calculateInterest(this.lateClosingInterest, this.context.matter.provinceCode);
    if (this.context.closingDatePayment && this.context.closingDatePayment.lateClosingInterests) {
      let lateClosingInterests = this.context.closingDatePayment.lateClosingInterests;

      let isLateClosingInterestItemAvailableIndex =
        this.context.matter.closingDatePayment.lateClosingInterests.findIndex(
          (item) => item.identifier == this.lateClosingInterest.identifier
        );
      if (isLateClosingInterestItemAvailableIndex >= 0) {
        this.context.closingDatePayment.removeLateClosingInterest(isLateClosingInterestItemAvailableIndex);
      }

      let lateClosingInterestExsistIndex = lateClosingInterests.findIndex(
        (item) => this.lateClosingInterest.fromDate == item.fromDate
      );
      if (lateClosingInterestExsistIndex >= 0) {
        this.context.closingDatePayment.removeLateClosingInterest(lateClosingInterestExsistIndex);
      }

      if (this.context.closingDatePayment.lateClosingInterests.length == 0) {
        this.lateClosingInterest.toDate = this.context.closingDatePayment.lateClosingDate;
        this.context.closingDatePayment.lateClosingInterests.push(this.lateClosingInterest);
      } else if (this.context.closingDatePayment.lateClosingInterests.length > 0) {
        let lateClosingInterestIndex = lateClosingInterests.findIndex(
          (item) => moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD') < moment(item.fromDate, 'YYYY/MM/DD')
        );

        if (lateClosingInterestIndex >= 0) {
          let prevIndex = lateClosingInterestIndex - 1;
          let prevElement = prevIndex >= 0 ? lateClosingInterests[prevIndex] : undefined;
          if (prevElement) {
            prevElement.toDate = moment(
              moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD').subtract(1, 'day')
            ).format('YYYY/MM/DD');
          }
          let currentIndexElement = lateClosingInterests[lateClosingInterestIndex];
          this.lateClosingInterest.toDate = moment(
            moment(currentIndexElement.fromDate, 'YYYY/MM/DD').subtract(1, 'day')
          ).format('YYYY/MM/DD');
          this.context.closingDatePayment.lateClosingInterests.splice(
            lateClosingInterestIndex,
            0,
            this.lateClosingInterest
          );
        } else if (lateClosingInterests.length > 0) {
          let prevElement = lateClosingInterests[lateClosingInterests.length - 1];
          if (prevElement) {
            prevElement.toDate = moment(
              moment(this.lateClosingInterest.fromDate, 'YYYY/MM/DD').subtract(1, 'day')
            ).format('YYYY/MM/DD');
          }
          this.lateClosingInterest.toDate = this.context.closingDatePayment.lateClosingDate;
          this.context.closingDatePayment.lateClosingInterests.push(this.lateClosingInterest);
        }
      }
    }
  }

  close() {
    this.dialog.close();
  }

  onDateChangeEffectiveFromDate(event): void {
    const tmpDate = event.rawDate;
    if (tmpDate !== this.lateClosingInterest.fromDate) {
      this.lateClosingInterest.fromDate = tmpDate;
    }
  }

  updatePrincipalAmountOptions(): void {
    this.principalAmountOptions.push({
      label: this.delayedAmountOptionText + Utils.formattedCurrencyValue(this.context.closingDatePayment.delayedAmount),
      value: this.delayedAmountOptionText
    });
    this.principalAmountOptions.push({
      label: this.cashToCloseOptionText + Utils.formattedCurrencyValue(this.context.closingDatePayment.cashToClose),
      value: this.cashToCloseOptionText
    });
    this.principalAmountOptions.push({
      label:
        this.registeredMortgageOptionText +
        Utils.formattedCurrencyValue(this.context.closingDatePayment.registeredMortgageAmount),
      value: this.registeredMortgageOptionText
    });
  }

  syncInterestRateFrom1stMortgage(event): void {
    if (event) {
      //if 1st Mortgage not exists, then return 0;
      this.lateClosingInterest.interestRate = this.context.matter.getFirstMortgageInterestRate();
      this.context.matter.updateFirstMortgageRelatedFields();
    }
  }

  getFormattedInterestRate(): string {
    return this.percentPipe.transform(this.lateClosingInterest.interestRate / 100, '1.3');
  }

  allowSameAs1stMortgageOption(): boolean {
    if (this.context.lateClosingInterest) {
      //try to edit the existing lateClosingInterest
      return (
        this.context.closingDatePayment &&
        Array.isArray(this.context.closingDatePayment.lateClosingInterests) &&
        this.context.closingDatePayment.lateClosingInterests.length == 1
      );
    } else {
      //try to add new lateClosingInterest
      return (
        this.context.closingDatePayment &&
        (!Array.isArray(this.context.closingDatePayment.lateClosingInterests) ||
          this.context.closingDatePayment.lateClosingInterests.length == 0)
      );
    }
  }
}
