import {IdentificationDocument} from './identification-document';
import {Telephone} from './telephone';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

const propertyIgnoreList = [ 'id', 'contactId', 'officerName' ];

export class IdentificationRecord extends BaseEntity {

  constructor(idrecord?: IdentificationRecord) {
    super(idrecord);
    if (idrecord) {
      for (let prop in idrecord) {
        if (idrecord.hasOwnProperty(prop)) {
          this[ prop ] = idrecord[ prop ];
        }
      }

      this.identificationDocuments = [];
      if (Array.isArray(idrecord.identificationDocuments)) {
        for (let i: number = 0; i < idrecord.identificationDocuments.length; i++) {
          this.identificationDocuments[ i ] = new IdentificationDocument(idrecord.identificationDocuments[ i ]);
        }
      }

      this.contactTelephone = new Telephone(idrecord.contactTelephone);

    } else {

      this.contactTelephone = new Telephone();

    }

  }

  id: number;
  occupation: string;
  contactId: number;
  notes: string;
  officerTitle: string;
  officerName: string;
  birthDate: string;
  type: string;
  subject: string;
  contactTelephone: Telephone;
  printNotesOnFlag: boolean = false;
  identificationDocuments: IdentificationDocument[] = [];

  createNewIdentificationRecordClone(idRecord: IdentificationRecord) {
    if (idRecord) {
      for (let prop in idRecord) {
        if (idRecord.hasOwnProperty(prop)) {
          this[ prop ] = idRecord[ prop ];
        }
      }
      this.identificationDocuments = [];
      if (Array.isArray(idRecord.identificationDocuments)) {
        for (let i: number = 0; i < idRecord.identificationDocuments.length; i++) {
          this.identificationDocuments[ i ] = new IdentificationDocument(idRecord.identificationDocuments[ i ]);
          this.identificationDocuments[ i ].id = null;
          this.identificationDocuments[ i ].identificationRecordId = null;
        }
      }

      this.contactTelephone = new Telephone(idRecord.contactTelephone);
      this.contactTelephone.id = null;

    } else {

      this.contactTelephone = new Telephone();

    }
  }

  static update(source: IdentificationRecord, target?: IdentificationRecord): IdentificationRecord {
    if (source) {
      if (!target) {
        target = new IdentificationRecord();
      }

      target.occupation = source.occupation;
      target.notes = source.notes;
      target.officerName = source.officerName;
      target.birthDate = source.birthDate;
      target.type = source.type;
      target.contactTelephone = Telephone.update(source.contactTelephone, target.contactTelephone);
      target.printNotesOnFlag = source.printNotesOnFlag;

      target.identificationDocuments = [];
      source.identificationDocuments.forEach((document) => {
        let newDocument: IdentificationDocument = IdentificationDocument.update(document);
        newDocument.identificationRecordId = target.id;
        target.identificationDocuments.push(newDocument);
      });

      return target;
    }

    return undefined;
  }

  get contactTelephoneNumber(): string {
    return this.contactTelephone && this.contactTelephone.telephoneNumber;
  }

  set contactTelephoneNumber(telephoneNumber: string) {
    if (!this.contactTelephone) {
      this.contactTelephone = new Telephone();
    }
    this.contactTelephone.telephoneNumber = telephoneNumber;
  }

  static createIdentificationRecord(officerName?: string, officerTitle?: string, phoneNumber?: string, phoneTypeCode?: string): IdentificationRecord {
    let identificationRecord = new IdentificationRecord();
    if (officerName) {
      identificationRecord.officerName = officerName;
    }
    if (officerTitle) {
      identificationRecord.officerTitle = officerTitle;
    }
    if (phoneNumber) {
      identificationRecord.contactTelephone.telephoneNumber = phoneNumber;
    }
    if (phoneTypeCode) {
      identificationRecord.contactTelephone.phoneTypeCode = phoneTypeCode;
    }
    IdentificationRecord.createIdentificationDocuments(identificationRecord);
    return identificationRecord;
  }

  static createIdentificationDocuments(record: IdentificationRecord) {
    for (let i = 0; i <= 1; i++) {
      let doc = new IdentificationDocument();
      record.identificationDocuments.push(doc);
    }
  }

  // equals(other: IdentificationRecord): boolean {
  //     return other
  //         && other.id === this.id
  //         && other.occupation === this.occupation
  //         && other.contactId === this.contactId
  //         && other.notes === this.notes
  //         && other.officerName === this.officerName
  //         && other.type === this.type;
  // }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }
}
