import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';
import {RegistryOffice} from '../../matters/shared';

export type TeranetSearchType = 'SEARCH_BY_NAME' | 'SEARCH_BY_ADDRESS' | 'SEARCH_BY_PIN' | 'SEARCH_BY_INSTRUMENT';

export class TeranetParcelRegisterSearchRequest extends BaseEntity {
  matterId: number;
  teranetUser: TeranetUser;
  searchType: TeranetSearchType;
  lroNumber: string;
  //In addition to Lro number also keeping selected LRO in search request as we need to display the name of registry office in search screen. We could get
  // the LRO number from selectedLRro but since backend expects data in lroNumber field so not removing it.
  private _selectedLro: RegistryOffice;
  firstName: string;
  lastName: string;
  streetNumber: string;
  streetName: string;
  unitNumber: string;
  pin: string;
  instrumentNumber: string;

  get selectedLro(): RegistryOffice {
    return this._selectedLro;
  }

  set selectedLro(value: RegistryOffice) {
    this._selectedLro = value;
    this.lroNumber = value.formattedOfficeNumber;
  }
}
