import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import moment from 'moment';
import {ProvinceCode} from '../accounts/shared/account-province';

export type SourceDateType = 'CLOSING_DATE' | 'FILE_OPENING_DATE' | 'OCCUPANCY_DATE' | 'REQUISITION_DATE' |
  'AGREEMENT_OF_PS_DATE' | 'FILE_INACTIVE_DATE' | 'ADJUSTMENT_DATE';
export type DurationType = 'DAYS' | 'MONTHS' | 'YEARS';
export type BeforeAfterType = 'BEFORE' | 'AFTER';

export class CustomDPDateCode extends BaseEntity {

  id: number;
  dpCode: number;
  sourceDate: SourceDateType;
  duration: number;
  durationType: DurationType;
  beforeAfter: BeforeAfterType;
  description: string;
  provinceCode: ProvinceCode;

  calculateSimulatedDateOutput(simulatedDate: string): string {
    if (simulatedDate && this.duration > 0) {
      let outputDate = moment();
      if (this.isBeforeSelected()) {
        if (this.durationType == 'DAYS') {
          outputDate = moment(simulatedDate, 'YYYY/MM/DD').subtract(this.duration, 'days');
        } else if (this.durationType === 'MONTHS') {
          outputDate = moment(simulatedDate, 'YYYY/MM/DD').subtract(this.duration, 'months');
        } else {
          outputDate = moment(simulatedDate, 'YYYY/MM/DD').subtract(this.duration, 'years');
        }
      } else {
        if (this.durationType == 'DAYS') {
          outputDate = moment(simulatedDate, 'YYYY/MM/DD').add(this.duration, 'days');
        } else if (this.durationType === 'MONTHS') {
          outputDate = moment(simulatedDate, 'YYYY/MM/DD').add(this.duration, 'months');
        } else {
          outputDate = moment(simulatedDate, 'YYYY/MM/DD').add(this.duration, 'years');
        }
      }
      return (moment(outputDate) <= moment('9999/12/31', 'YYYY/MM/DD')) && (moment(outputDate) >= moment('1900/01/01', 'YYYY/MM/DD'))
        ? outputDate.format('MMMM D, YYYY') : '';
    } else {
      return '';
    }
  }

  isBeforeSelected(): boolean {
    return (this.beforeAfter === 'BEFORE');
  }

}
