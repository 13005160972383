import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {Matter} from '../../../shared/matter';
import {Mortgage} from '../../../shared/mortgage';
import {MatterParticipantWrapper} from '../../../shared/matter-participant-wrapper';
import {Contact} from '../../../shared/contact';
import {MatterParticipant} from '../../../shared/matter-participant';
import {Constants} from '../../../../shared-main/constants';
import {Observable, Subject} from 'rxjs';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {ContactService} from '../../../../shared-main/contact.service';
import {Logger} from '@nsalaun/ng-logger';
import {CreateMortgageBrokerComponent} from '../create-mortgage-broker/create-mortgage-broker.component';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {AutoComplete} from 'primeng/autocomplete';
import {MatterParticipantRole} from '../../../shared/matter-participant-role-types';
import {Utils} from '../../../shared/utils';
import {DocumentProductionService} from '../../../document-production/document-production.service';

declare var jQuery: any;
declare var _: any;

@Component({
  selector: 'dp-mortgage-broker-info',
  templateUrl: 'mortgage-broker-info.component.html',
  providers: [ CurrencyPipe ]
})
export class MortgageBrokerInfoComponent implements OnInit {
  @Input() matter: Matter;
  @Output() onChange = new EventEmitter();
  @ViewChild('mortgageBrokerAttentionField') mortgageBrokerAttentionField: AutoComplete;
  @ViewChild('mortgageBrokerField') mortgageBrokerField: AutoComplete;
  mortgageBroker: MatterParticipantWrapper;
  localAttentionOptions: Contact[] = [];
  mortgageBrokerOptions: Contact[] = [];
  mortgageBrokersLoading: boolean = false;
  mortgageBrokerAttentionLoading: boolean = false;
  mortgageBrokerAttentionOptions: Contact[] = [];
  showAttentionsDropDownArrow: boolean;
  needToRefreshAttentionOptions: boolean = false;
  utils: Utils;
  _mortgage: Mortgage;
  public mortgageBrokerSearchSubject: Subject<string> = new Subject<string>();
  public attentionSearchSubject: Subject<string> = new Subject<string>();

  constructor(public contactQueryService: ContactQueryService,
              public documentProductionService: DocumentProductionService,
              public contactService: ContactService, public logger: Logger, public dialogService: DialogService) {
    this.utils = new Utils();
  }

  ngOnInit(): void {
  }

  @Input()
  set mortgage(mortgage: Mortgage) {
    this._mortgage = mortgage;
    this.initializeState();

  }

  get mortgage(): Mortgage {
    return this._mortgage;
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgage: Mortgage) {
    this.matter = matter;
    this.mortgage = mortgage;
  }

  initializeState() {
    this.mortgageBrokerSearchSubject.switchMap((criteria: string) => {
      this.mortgageBrokersLoading = true;
      if (!criteria || criteria.trim() === '') {
        return Observable.create(observer => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
      } else {
        return this.contactService.getActiveContactListPerPage(criteria.trim(), 'MORTGAGE_BROKER', false, 1, 15);
      }
    }).subscribe(
      (mortgageBrokers: Contact[]) => {
        this.mortgageBrokerOptions = mortgageBrokers;
        this.mortgageBrokersLoading = false;
      },
      error => {
        this.logger.error('mortgageBrokers search error', error);
        this.mortgageBrokersLoading = false;
      }
    );
    this.initializeMortgageBroker();

    this.attentionSearchSubject.switchMap((criteria: string) => {
      this.mortgageBrokerAttentionLoading = true;
      if (!criteria || criteria.trim() === '') {
        return Observable.create(observer => {
          setTimeout(() => {
            observer.next();
          }, 10);
        });
      } else {
        return this.contactService.getActiveContactListPerPage(criteria.trim(), 'MORTGAGE_BROKER_ATTENTION', true, 1, 15);
      }
    }).subscribe(
      (attentions: Contact[]) => {
        console.log('<<>> get back attention');
        this.mortgageBrokerAttentionLoading = false;
        this.mortgageBrokerAttentionOptions = this.sanitizeAttentionsSearchResults(attentions);
      },
      error => {
        console.log('<<>> fail to get the attention');
        this.mortgageBrokerAttentionLoading = false;
        this.logger.error('attentions search error:', error);
      }
    );
    this.initializeAttentions();

  }

  public initializeAttentions(): void {
    if (this.mortgageBroker && this.mortgageBroker.dataModel) {
      this.localAttentionOptions = this.mortgageBroker.dataModel.subContacts;
    }
    this.mortgageBrokerAttentionOptions = [];
    this.showAttentionsDropDownArrow = !this.mortgageBroker.editMode && this.localAttentionOptions && this.localAttentionOptions.length > 0;
    this.needToRefreshAttentionOptions = false;
  }

  public initializeMortgageBroker(): void {
    this.mortgageBroker = this.createMortgageBrokerWrapper(this.matter.getMortgageBroker(this.mortgage));
    if (this.mortgageBroker.editMode) {
      this.mortgage.mortgageBrokerAttention = '';
    }
  }

  public createMortgageBrokerWrapper(mortgageBroker?: MatterParticipant): MatterParticipantWrapper {
    const matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    if (mortgageBroker) {
      matterParticipantWrapper.matterParticipant = mortgageBroker;
      matterParticipantWrapper.dataModel = mortgageBroker.contact;
      matterParticipantWrapper.editMode = false;
      matterParticipantWrapper.createdOrSelected = 'Selected';
    } else {
      matterParticipantWrapper.editMode = true;
    }
    matterParticipantWrapper.selectedDetailsTabIndex = 0;
    return matterParticipantWrapper;
  }

  searchMortgageBrokers(event): void {
    let entered: string = event.query;
    if (entered === Constants.MORTGAGE_BROKER && this.mortgageBroker.dataModel) {
      return;
    }
    this.mortgageBrokerSearchSubject.next(event.query);
  }

  selectExistingMortgageBroker(event): void {
    if (this.mortgageBroker.dataModel.id) {

      this.contactQueryService.getContactForMatter(this.mortgageBroker.dataModel.sourceContactId).subscribe(
        (source: Contact) => {
          this.populateMortgageBrokerInformation(source, 'Selected');
          this.localAttentionOptions = source.privateContacts && source.privateContacts.persons;
          this.mortgageBrokerAttentionOptions = [];
          this.showAttentionsDropDownArrow = this.localAttentionOptions && this.localAttentionOptions.length > 0;
          this.needToRefreshAttentionOptions = true;
          this.focusOnMortgageBrokerAttentionField();
          this.propagateOnBrokerChanges();
        });
    } else {
      if (event && event.organizationName && event.organizationName.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
        // if (this.mortgageBroker.dataModel && this.mortgageBroker.dataModel.organizationName && this.mortgageBroker.dataModel.organizationName.indexOf(Constants.NO_RESULTS_FOUND) > -1) {
        this.mortgageBroker.dataModel.organizationName = '';
        setTimeout(() => {
          this.mortgageBroker.dataModel = null;
          this.mortgageBroker.createdOrSelected = null;
        }, 0);
      } else {
        this.dialogService.matDialogContent({
          content: CreateMortgageBrokerComponent,
          context: {
            keyboard: null,
            mortgageBrokerTemplate: this.mortgageBroker.dataModel,
            showClose: true
          },
          onFulfillment: (result: Contact | string) => {
            if (result && result instanceof Contact) {
              this.mortgageBroker.dataModel = result;
              this.populateMortgageBrokerInformation(result, 'Created');
              this.localAttentionOptions = result.privateContacts && result.privateContacts.persons;
              this.mortgageBrokerAttentionOptions = [];
              this.showAttentionsDropDownArrow = this.localAttentionOptions && this.localAttentionOptions.length > 0;
              this.needToRefreshAttentionOptions = true;
              this.focusOnMortgageBrokerAttentionField();
              this.propagateOnBrokerChanges();
            } else {
              this.mortgageBroker.dataModel = null;
              this.mortgageBroker.createdOrSelected = null;
            }
          },
          onRejection: (reject: any) => {
            this.mortgageBroker.dataModel = null;
            this.mortgageBroker.createdOrSelected = null;
          }
        });
      }
    }
  }

  public populateMortgageBrokerInformation(mortgageBroker: Contact, type: string) {
    this.mortgageBroker.createdOrSelected = type;
    this.enableSave();
    this.mortgageBroker.dataModel = mortgageBroker;
    this.mortgageBroker.editMode = false;
    this.mortgageBroker.matterParticipant = this.setMortgageBrokerParticipant(mortgageBroker);
    this.mortgage.mortgageBrokerAttention = null;
    this.mortgage.mortgageBrokerAttentionId = null;
  }

  public focusOnMortgageBrokerAttentionField(): void {
    setTimeout(() => {
      if (this.mortgageBrokerAttentionField.inputEL.nativeElement) {
        this.mortgageBrokerAttentionField.inputEL.nativeElement.focus();
      }
    }, 0);
  }

  setMortgageBrokerParticipant(mortgageBrokerContact: Contact): MatterParticipant {
    const mortgageBrokerParticipant = this.buildMortgageBrokerParticipant(mortgageBrokerContact);
    if (!this.matter.matterParticipants) {
      this.matter.matterParticipants = [];
    }
    this.matter.matterParticipants.push(mortgageBrokerParticipant);
    return mortgageBrokerParticipant;
  }

  public buildMortgageBrokerParticipant(mortgageBrokerContact: Contact): MatterParticipant {
    const mortgageBrokerParticipant: MatterParticipant = new MatterParticipant();
    mortgageBrokerParticipant.matterParticipantRole = mortgageBrokerContact.contactType as MatterParticipantRole;
    mortgageBrokerParticipant.contactReferenceId = mortgageBrokerContact.id;
    mortgageBrokerParticipant.contact = mortgageBrokerContact;
    mortgageBrokerParticipant.mortgageId = this.mortgage.id;
    return mortgageBrokerParticipant;
  }

  updateBrokerFeeTrustLedger() {
    if (this.matter.soaTrustLedgerCollection) {

      this.matter.soaTrustLedgerCollection.updateAutogeneratedMortgageBrokerFees(this.mortgage);
      this.matter.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
    }
  }

  enableSave(): void {
    this.notifyChange();
  }

  notifyChange(): void {
    this.onChange.emit();
    this.matter.dirty = true;
  };

  cleanupBrokerField(): void {
    //clean free entered text after leave the field - DPPMP-11262
    if (this.mortgageBroker) {
      if (!this.mortgageBroker.matterParticipant) {
        this.mortgageBrokersLoading = false;
        this.mortgageBroker.dataModel = null;
      }
    }
  }

  get mortgageBrokerDisplayName(): string {
    let mortgageBrokerDisplayName: string;
    if (!this.mortgageBroker || !this.mortgageBroker.dataModel) {
      mortgageBrokerDisplayName = 'Unknown';
    } else if (this.mortgageBroker.dataModel instanceof Contact) {
      mortgageBrokerDisplayName = this.getMortgageBrokerDisplayNameFromContact(this.mortgageBroker.dataModel);
    } else {
      mortgageBrokerDisplayName = this.mortgageBroker.dataModel;
    }
    return mortgageBrokerDisplayName;
  }

  getMortgageBrokerDisplayNameFromContact(mortgageBroker: Contact): string {
    let mortgageBrokerDisplayName: string;
    if (!mortgageBroker) {
      mortgageBrokerDisplayName = 'Unknown';
    } else {
      mortgageBrokerDisplayName = mortgageBroker.displayName ? mortgageBroker.displayName : mortgageBroker.organizationName;
    }
    return mortgageBrokerDisplayName;
  }

  getContactDisplayMailingAddress(privateLender: Contact): string {
    if (!privateLender || !privateLender.id || !privateLender.mailingAddress) {
      return '';
    }

    return privateLender.mailingAddress.addressTextWithoutCountryAndPostalCode;
  }

  deleteMortgageBroker(): void {
    this.dialogService.confirm('Confirmation', 'Proceed to remove this Mortgage Broker from this matter?', false, 'Delete').subscribe(res => {
      if (res) {
        this.documentProductionService.tryToRevokePackage(this.matter, this.mortgageBroker.matterParticipant, this.contactQueryService);
        this.enableSave();
        this.matter.removeMatterParticipant(this.mortgageBroker.matterParticipant);
        this.mortgage.mortgageBrokerAttention = null;
        this.mortgage.mortgageBrokerAttentionId = null;
        this.showAttentionsDropDownArrow = false;

        this.initializeMortgageBroker();
        this.mortgageBroker.dataModel = null;
        this.mortgageBroker.editMode = true;
        this.propagateOnBrokerChanges();
        setTimeout(() => {
          if (this.mortgageBrokerField.inputEL.nativeElement) {
            this.mortgageBrokerField.inputEL.nativeElement.focus();
          }
        }, 0);

      }
    });
  }

  propagateOnBrokerChanges(): void {
    this.initMortgageBrokerInformation();
    this.updateBrokerFeeTrustLedger();
  }

  searchAttentions(event): void {
    if ((this.mortgageBroker.editMode)) {
      this.attentionSearchSubject.next(event.query);
    } else {
      if (this.needToRefreshAttentionOptions) {
        setTimeout(() => {
          this.contactQueryService.getContactForMatter(this.mortgageBroker.dataModel.sourceContactId).subscribe(
            (source: Contact) => {
              this.localAttentionOptions
                = source.privateContacts && source.privateContacts.persons;
              this.needToRefreshAttentionOptions = false;
              if (event.query) {
                this.mortgageBrokerAttentionOptions = this.localAttentionOptions.filter(attention => {
                  if (attention.surnameLastFullName.toUpperCase().indexOf(event.query.trim().toUpperCase()) > -1) {
                    return true;
                  }
                });
              } else {
                this.mortgageBrokerAttentionOptions = this.localAttentionOptions.slice();
              }
            });
        }, 10);
      } else {
        if (event.query) {
          this.mortgageBrokerAttentionOptions = this.localAttentionOptions.filter(attention => {
            if (attention.surnameLastFullName.toUpperCase().indexOf(event.query.trim().toUpperCase()) > -1) {
              return true;
            }
          });
        } else {
          this.mortgageBrokerAttentionOptions = [];
        }
      }
    }

  }

  cleanupAttentionField(): void {
    //clean free entered text after leave the field - DPPMP-11262
    if (!this.mortgage.mortgageBrokerAttentionId) {
      if (this.mortgageBroker) {
        if (!this.mortgageBroker.matterParticipant) {
          this.mortgageBrokerAttentionLoading = false;
          this.mortgageBroker.dataModel = null;
          this.mortgage.mortgageBrokerAttention = null;
        } else {
          if (this.mortgage.mortgageBrokerAttention) {
            this.mortgage.mortgageBrokerAttention = this.mortgage.mortgageBrokerAttention.trim();
          }
        }
      }
    }
  }

  onUserInput(event): void {
    if (event.target.value
      && event.target.value.trim() != this.mortgage.mortgageBrokerAttention) {
      this.mortgage.mortgageBrokerAttentionId = null;
      this.mortgage.mortgageBrokerAttention = event.target.value.trim();
      this.notifyChange();
    } else if (!event.target.value) {
      if (this.mortgage.mortgageBrokerAttention) {
        this.notifyChange();
      }
      this.mortgage.mortgageBrokerAttentionId = null;
      this.mortgage.mortgageBrokerAttention = null;
    }
  }

  onMortgageBrokerAttentionDropdownClick = (event) => this.mortgageBrokerAttentionOptions = this.localAttentionOptions.slice();

  onMortgageBrokerAttentionSelect(contact: Contact): void {
    this.mortgage.mortgageBrokerAttention = ' ';
    this.mortgage.mortgageBrokerAttentionId = null;
    setTimeout(() => {
      this.mortgage.mortgageBrokerAttention = contact.isInstanceTypePerson ? contact.surnameLastFullName : contact.displayName;
      this.mortgage.mortgageBrokerAttentionId = contact.id;
      if (this.mortgageBroker.editMode) {
        this.contactQueryService.getContactForMatter(contact.organizationId).subscribe(
          (source: Contact) => {
            this.mortgageBroker.createdOrSelected = 'Selected';
            this.mortgageBroker.editMode = false;
            this.mortgageBroker.dataModel = source;
            this.mortgageBroker.matterParticipant = this.setMortgageBrokerParticipant(source);
            this.mortgage.brokerageFee = 0;
            this.mortgage.automaticallyUpdateBrokerageFeeTrustLedger = true;
            this.localAttentionOptions = source.privateContacts && source.privateContacts.persons;
            this.mortgageBrokerAttentionOptions = [];
            this.showAttentionsDropDownArrow = this.localAttentionOptions && this.localAttentionOptions.length > 0;
          });
      }
      this.notifyChange();
    }, 0);

  }

  get constantNoResultValue() {
    return Constants.NO_RESULTS_FOUND;
  }

  public sanitizeAttentionsSearchResults(results: Contact[]): Contact[] {
    const sanitizedAttentionsSearchResults = results ? results.filter((attention: Contact) => attention.id && attention.organizationId) : [];
    if (sanitizedAttentionsSearchResults.length === 0) {
      const noResultContact: Contact = new Contact();
      noResultContact.displayName = 'No records available';
      sanitizedAttentionsSearchResults.push(noResultContact);
    }
    return sanitizedAttentionsSearchResults.sort(this.sortContactsByName);
  }

  public sortContactsByName = (left: Contact, right: Contact) => {
    return this.getContactName(left).localeCompare(this.getContactName(right), 'en', {sensitivity: 'base'});
  };

  public getContactName(contact: Contact): string {
    if (contact) {
      if (contact.organizationName === 'No records available') {
        return contact.organizationName;
      }
      return (contact.isCorporation || contact.isGenderDepartment()) ? contact.organizationName : contact.fullNameStartWithFirstName;
    }
    return '';
  }

  get hasAttentionOptions(): boolean {
    return this.mortgageBrokerAttentionOptions && this.mortgageBrokerAttentionOptions.length > 0;
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  initMortgageBrokerInformation() {
    this.mortgage.brokerageFee = 0;
    this.mortgage.automaticallyUpdateBrokerageFeeTrustLedger = true;
  }
}
