import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../../shared/matter';
import {WindowRef} from '../../../../shared/window.ref';
import {Cirf} from '../../../shared/cirf/cirf';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ModalResult} from '../../../../shared-main/enums';
import {CirfImportOwnerModalComponent} from './cirf-import-owner.modal.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {PurchaserService} from '../../purchaser.service';
import {MortgageTypeValue} from '../../../shared/cirf/cirf-mortgage';
import {UnitConnectDataPropagation, UnitConnectDataPropagationCmdType} from './unit-connect-data-propagation';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Address} from '../../../shared/address';
import {CirfClientInfo} from '../../../shared/cirf/cirf-client-info';
import {ApplicableProvisionOptionsTypes, FamilyLawAct, MatterParticipant} from '../../../shared';
import {FlaStatementType} from '../../../../shared-main/constants';
import {CirfImportStickyNotesHelperService} from './cirf-import-sticky-notes-helper.service';
import {DocumentProfile} from '../../../../admin/document-profile/document-profile';
import {SESSION_STORAGE_KEYS} from '../../../../shared';
import {DocumentProfileService} from '../../../../admin/document-profile/document-profile-edit/document-profile.service';
import {ContactUtil} from '../../../shared/contact-util';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class CirfImportContainerModalContext {
  matter: Matter;
  originalMatter: Matter;
  cirf: Cirf;
}

@Component({
  selector: 'dp-cirf-import-container-modal-content',
  templateUrl: 'cirf-import-container.modal.component.html',
  providers: [DocumentProfileService, PurchaserService, CirfImportStickyNotesHelperService, ErrorService ]
})
export class CirfImportContainerModalComponent extends ModalComponent<CirfImportContainerModalContext> implements OnInit {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild(CirfImportOwnerModalComponent) cirfImportOwnerModalComponent: CirfImportOwnerModalComponent;
  tabsList: string[] = [];
  selectedTabIndex: number = 0;
  tabKeys = {
    clientsTab: 'Clients',
    matterDataTab: 'General Client Data',
    newMortgageTab: 'New Mortgages',
    existingMortgageTab: 'Existing Mortgages',
    insuranceTab: 'Fire Insurance',
    attachmentsTab: 'Attachments'
  };
  mortgageTypes = MortgageTypeValue;
  cirfDataPropagation: UnitConnectDataPropagation;

  constructor(
    public dialog: MatDialogRef<CirfImportContainerModalComponent>,
    public dialogService: DialogService,
    public documentProfileService: DocumentProfileService,
    public window: WindowRef,
    public purchaserService: PurchaserService,
    public cirfImportStickyNotesHelperService: CirfImportStickyNotesHelperService,
    @Inject(MAT_DIALOG_DATA) context?: CirfImportContainerModalContext
  ) {
    super(dialog, context);
  }

  //map of each Cirf client to the Matter Participant its info is imported into (needed for spousal linking)
  cirfClientToMatterParticipantMap: Map<string, number> = new Map<string, number>();

  //used to track each matter participant for which the user has chosen to import spousal information (needed for spousal linking)
  cirfClientsWithSpouseImport: string[] = [];

  ngOnInit() {
    this.cirfDataPropagation = new UnitConnectDataPropagation();
    this.loadTabsList();
    this.setTabReviewStatus(this.tabKeys.clientsTab);
    this.cirfImportStickyNotesHelperService.appendDataToMatterTopics(this.context.matter, this.context.cirf);
  }

  async ok(): Promise<void> {
    let notReviewedTabs = this.getNotReviewedTabs();
    if (!notReviewedTabs.length) {
      this.markRequestAsCompleteAndClose();
    } else {
      let message =
        'Are you sure you selected all relevant data from this request?<br>The following tabs have not been reviewed yet:<br><br>';
      notReviewedTabs.forEach((tab, index) => {
        message += `${tab}  <br>`;
      });
      let toProcees = await this.dialogService.confirm('WARNING', message, false, null, null, true).toPromise();
      if (toProcees) {
        this.markRequestAsCompleteAndClose();
      }
    }
  }

  async getDefaultDocumentProfile(): Promise<DocumentProfile> {
    let ret = Promise.resolve(null);
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (this.matter && this.matter.documentProfileId) {
      let documentProfile: DocumentProfile = await this.documentProfileService
        .getById(this.matter.documentProfileId, accountId, true, this.matter)
        .toPromise();
      if (documentProfile) {
        if (documentProfile.miscDocumentProfile && documentProfile.miscDocumentProfile.sameAsDefaultProfileFlag) {
          return this.documentProfileService
            .getDefaultProfileForAccount(accountId, this.matter.provinceCode)
            .toPromise();
        } else {
          ret = Promise.resolve(documentProfile);
        }
      }
    }
    return ret;
  }

  needUpdateAllSuggestions(defaultDocumentProfile: DocumentProfile): boolean {
    return ContactUtil.needUpdateAllSuggestions(
      defaultDocumentProfile,
      this.context.originalMatter.mainClients,
      this.context.matter.mainClients
    );
  }

  async markRequestAsCompleteAndClose(): Promise<void> {
    this.updateSpousalLinks();
    let defaultDocumentProfile: DocumentProfile = await this.getDefaultDocumentProfile();

    // Move it from close cirf dialog
    this.cirfDataPropagation.addToDataPropagationCommands(
      UnitConnectDataPropagationCmdType.CIRF_REFERRAL_IMPORT_MAIN_CLIENT,
      null,
      true,
      this.needUpdateAllSuggestions(defaultDocumentProfile)
    );
    if (this.isPrePostAddressDifferent()) {
      const prePostNewAddress: Address = this.matter.isPurchase
        ? new Address(this.matter.matterContactInfo.preClosingAddress)
        : new Address(this.matter.matterContactInfo.postClosingAddress);
      this.cirfDataPropagation.addToDataPropagationCommands(
        UnitConnectDataPropagationCmdType.CIRF_IMPORT_PRE_CLOSING_ADDRESS,
        null,
        true,
        prePostNewAddress
      );
    }
    this.dialog.close({action: ModalResult.OK, completeRequest: true, cirfDataPropagation: this.cirfDataPropagation});
  }

  //Updating spousal links just before closing
  updateSpousalLinks(): void {
    //iterate through array of cirf clients where user clicked on spousal import
    this.cirfClientsWithSpouseImport.forEach((cirfClientId) => {
      let cirfClient: CirfClientInfo = this.cirf.cirfClients.find((cirfClient) => cirfClient.id == cirfClientId);

      //get matter participant for the cirf client being imported
      let matterParticipantId = this.cirfClientToMatterParticipantMap.get(cirfClient.id);
      let matterParticipant = this.matter.mainClients.find((mp) => mp.matterParticipantId == matterParticipantId);

      //get matter participant for the spouse of the client being imported
      let matterParticipantSpouseId = this.cirfClientToMatterParticipantMap.get(cirfClient.spouseId);
      let matterParticipantSpouse: MatterParticipant = this.matter.mainClients.find(
        (mp) => mp.matterParticipantId == matterParticipantSpouseId
      );

      //User must have imported both the client and spouse for Unity to be able to establish the link
      if (matterParticipant && matterParticipantSpouse) {
        //check if the client has an existing spouse in Unity which is different than the spouse being linked to. if found then clear the spousal link from that existing spouse
        let existingSpouse: MatterParticipant = this.matter.mainClients.find(
          (mp) =>
            mp.getSpouseParticipantId(this.matter.provinceCode) == matterParticipant.matterParticipantId &&
            mp.matterParticipantId != matterParticipantSpouse.matterParticipantId
        );
        if (existingSpouse) {
          existingSpouse.removeSpousalLink(this.matter.provinceCode);
        }

        //link client to spouse
        matterParticipant.setSpouseParticipantId(matterParticipantSpouse.matterParticipantId, this.matter.provinceCode);

        if (this.matter.isMatterProvinceNB) {
          this.initializeSpousalFamilyLawActNB(matterParticipant, matterParticipantSpouse, matterParticipantSpouseId);
        }

        //in case user only clicked spousal import on the client but not the spouse, create the FLA statement for the spouse
        if (!this.matter.isMatterProvinceNB && !matterParticipantSpouse.matterParticipantSpouse) {
          let familyLawAct: FamilyLawAct = new FamilyLawAct();
          familyLawAct.matterParticipantId = matterParticipantSpouseId;
          familyLawAct.familyLawActStatementType = 'MATTER_PARTICIPANT_SPOUSE';
          matterParticipantSpouse.familyLawActs.push(familyLawAct);
        }

        //check if the spouse being linked to is already linked to another spouse and clear family law act info from that other spouse.
        if (
          matterParticipantSpouse.getSpouseParticipantId(this.matter.provinceCode) &&
          matterParticipantSpouse.getSpouseParticipantId(this.matter.provinceCode) !=
            matterParticipant.matterParticipantId
        ) {
          let otherSpouse: MatterParticipant = this.matter.mainClients.find(
            (mp) => mp.matterParticipantId == matterParticipantSpouse.getSpouseParticipantId(this.matter.provinceCode)
          );
          if (otherSpouse) {
            otherSpouse.removeSpousalLink(this.matter.provinceCode);
          }
        }

        //link spouse to client
        matterParticipantSpouse.setSpouseParticipantId(matterParticipant.matterParticipantId, this.matter.provinceCode);
      }
    });
  }

  //create/update spouse's family law act for New Brunswick
  initializeSpousalFamilyLawActNB(
    matterParticipant: MatterParticipant,
    matterParticipantSpouse: MatterParticipant,
    matterParticipantSpouseId: number
  ): void {
    let spouseFamilyLawAct: FamilyLawAct = matterParticipantSpouse.getFamilyLawAct('MARITAL_STATUS_NB');

    if (!spouseFamilyLawAct) {
      spouseFamilyLawAct = new FamilyLawAct();
    }

    spouseFamilyLawAct.matterParticipantId = matterParticipantSpouseId;
    spouseFamilyLawAct.familyLawActStatementType = FlaStatementType.MARITAL_STATUS_NB;
    spouseFamilyLawAct.maritalStatus = 'MARRIED';
    spouseFamilyLawAct.applicableProvision = ApplicableProvisionOptionsTypes.SPOUSE_IS_A_PARTY;

    let familyLawAct: FamilyLawAct = matterParticipant.getFamilyLawAct('MARITAL_STATUS_NB');
    spouseFamilyLawAct.propertyOccupiedAsMaritalHome = familyLawAct && familyLawAct.propertyOccupiedAsMaritalHome;

    if (!matterParticipantSpouse.maritalStatusNB) {
      matterParticipantSpouse.familyLawActs.push(spouseFamilyLawAct);
    }
  }

  close(): void {
    this.cirfImportOwnerModalComponent.releaseContactLocks();
    this.dialog.close();
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get cirf(): Cirf {
    return this.context.cirf;
  }

  get originalMatter(): Matter {
    return this.context.originalMatter;
  }

  onSelectTabChange(event): void {
    this.selectedTabIndex = event.index;
    this.setTabReviewStatus(this.tabsList[this.selectedTabIndex]);
  }

  setTabReviewStatus(tabKey: string): void {
    if (!this.cirf.reviewStatus.find((key) => key == tabKey)) {
      this.cirf.reviewStatus.push(tabKey);
    }
  }

  loadTabsList(): void {
    if (this.cirf.isParticipantCirf()) {
      this.tabsList = [this.tabKeys.clientsTab, this.tabKeys.attachmentsTab];
    } else if (this.matter.isCustomMatter()) {
      this.tabsList = [this.tabKeys.clientsTab, this.tabKeys.matterDataTab, this.tabKeys.attachmentsTab];
    } else if (this.matter.isPurchase) {
      this.tabsList = [
        this.tabKeys.clientsTab,
        this.tabKeys.matterDataTab,
        this.tabKeys.newMortgageTab,
        this.tabKeys.insuranceTab,
        this.tabKeys.attachmentsTab
      ];
    } else if (this.matter.isSale) {
      this.tabsList = [
        this.tabKeys.clientsTab,
        this.tabKeys.matterDataTab,
        this.tabKeys.existingMortgageTab,
        this.tabKeys.attachmentsTab
      ];
    } else if (this.matter.isMortgage) {
      this.tabsList = [
        this.tabKeys.clientsTab,
        this.tabKeys.matterDataTab,
        this.tabKeys.newMortgageTab,
        this.tabKeys.existingMortgageTab,
        this.tabKeys.insuranceTab,
        this.tabKeys.attachmentsTab
      ];
    } else if (this.matter.isWillMatter()) {
      this.tabsList = [this.tabKeys.clientsTab, this.tabKeys.matterDataTab, this.tabKeys.attachmentsTab];
    }
  }

  getNotReviewedTabs(): string[] {
    let nonReviewedTabs = [];
    this.tabsList.forEach((tab) => {
      if (!this.cirf.reviewStatus.find((key) => key == tab)) {
        nonReviewedTabs.push(tab);
      }
    });
    return nonReviewedTabs;
  }

  isTabReviewed(tabKey: string): boolean {
    return !!this.cirf.reviewStatus.find((key) => key == tabKey);
  }

  isPrePostAddressDifferent(): boolean {
    if (this.matter && this.matter.matterContactInfo && this.originalMatter && this.originalMatter.matterContactInfo) {
      const prePostNewAddress: Address = this.matter.isPurchase
        ? this.matter.matterContactInfo.preClosingAddress
        : this.matter.matterContactInfo.postClosingAddress;
      const prePostOriginalAddress: Address = this.originalMatter.isPurchase
        ? this.originalMatter.matterContactInfo.preClosingAddress
        : this.originalMatter.matterContactInfo.postClosingAddress;
      if (prePostNewAddress && prePostOriginalAddress) {
        return (
          prePostNewAddress.addressLine1 != prePostOriginalAddress.addressLine1 ||
          prePostNewAddress.addressLine2 != prePostOriginalAddress.addressLine2 ||
          prePostNewAddress.city != prePostOriginalAddress.city ||
          prePostNewAddress.provinceName != prePostOriginalAddress.provinceName ||
          prePostNewAddress.postalCode != prePostOriginalAddress.postalCode ||
          prePostNewAddress.country != prePostOriginalAddress.country
        );
      }
    }
    return false;
  }
}
