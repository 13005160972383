import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class BurgerMenuExtendedItem extends BaseEntity {

  key: string;
  text: string;
  type: string;
  underline: boolean = false;
  topline: boolean = false;
  action: any;
  isDisabled: boolean = false;
  hidden: boolean = false;
  items: BurgerMenuExtendedItem[];
  isImageCssClassDefined: string;

  constructor(burgerMenuExtendedItem?: BurgerMenuExtendedItem) {
    super(burgerMenuExtendedItem);

    this.items = [];
    if (burgerMenuExtendedItem) {
      if (Array.isArray(burgerMenuExtendedItem.items)) {
        this.items = burgerMenuExtendedItem.items.map(item => {
          return new BurgerMenuExtendedItem(item);
        });
      }
    }
  }

  static of(text: string, key?: string, action?: Function): BurgerMenuExtendedItem {
    let burgerMenuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    if (key) {
      burgerMenuItem.key = key;
    }
    if (action) {
      burgerMenuItem.action = action;
    }
    return burgerMenuItem;
  }

  static createMenuItem(burgerMenuItems: BurgerMenuExtendedItem[], key: string, text: string, action: any, disabled?: boolean, items?: BurgerMenuExtendedItem[], underline?: boolean): void {
    let menuItem: BurgerMenuExtendedItem = new BurgerMenuExtendedItem();
    menuItem.key = key;
    menuItem.text = text;
    menuItem.action = action;
    menuItem.isDisabled = disabled;
    menuItem.items = items;
    menuItem.underline = underline;
    burgerMenuItems.push(menuItem);
  }
}
