<div class="row">
    <ng-container>
    <div class="col-lg-12 fixed-header-matter" [ngClass]="{'padding-bottom-fixed-matter-list': showFilters, 'margin-top-45' : isMassUpdateTab()}">
        <div>
            <div class="col-lg-3">
                <input type="text"
                       #search="ngModel"
                       id="search-by"
                       name="search-by"
                       class="form-control search-by focus-first border-visible"
                       [(ngModel)]="matterListState.searchText"
                       [dpPlaceholder]="searchInputPlaceHolder()"
                       trimmed
                       [ngClass]="{'font-style-normal': matterListState.searchText}"
                       (keyup)="onKeyUp()"
                />
            </div>
            <div class="col-lg-1">
                <select
                        id="searchView"
                        name="searchView"
                        [(ngModel)]="currentSearchView"
                        (ngModelChange)="onSearchViewChange($event)"
                        class="form-control  select-dropdown border-visible">
                    <option *ngFor="let searchView of searchViews"
                            [ngValue]="searchView">
                        {{searchView && searchView.matterListFilterName}}
                    </option>
                </select>
            </div>
            <ng-container *ngIf="!isRecentMattersView">
                <div *ngIf="!isMassUpdateTab()" class="left col-lg-2">
                    <dp-multi-select [multiSelectData]="matterTypes"
                                     [inputName]="'type'"
                                     [title]="'Type'"
                                     [multiSelectedTitle]="'Multiple Types'"
                                     [showLabelInTitle]="true"
                                     [multiSelectDataSelected]="matterListState.selectedMatterTypes"
                                     (updateMultiSelect)="updateMultiSelectFilters($event,'type')"
                    ></dp-multi-select>
                </div>
                <div class="col-lg-2" style="max-width: 170px">
                    <dp-multi-select [multiSelectData]="matterStatusList"
                                     [inputName]="'status'"
                                     [title]="'No Status Selected'"
                                     [multiSelectedTitle]="'Multiple Statuses'"
                                     [showLabelInTitle]="true"
                                     [multiSelectDataSelected]="matterListState.selectedMatterStatuses"
                                     (updateMultiSelect)="updateMultiSelectFilters($event,'status')"
                    ></dp-multi-select>
                </div>
                <div class="col-lg-1 padding-top-5">
                    <span>
                        <a class="fas fa-filter primary font-size-16 padding-right-5 noUnderline" aria-hidden="true"
                           title="Additional Filters"
                           href="javascript:void(0);"
                           (click)="openFiltersModal()" >
                        </a>
                    </span>
                    <span *ngIf="isAnyHiddenFiltersChanged()">
                        <i class="fas fa-circle red font-size-16 padding-right-30" aria-hidden="true" title="Selections have been made on Additional Filters"></i>
                    </span>
                </div>
            </ng-container>
            <div class="action-btns" *ngIf="!isMassUpdateTab()"
                [ngClass]="{'margin-top-0': isRecentMattersView}">
                <div class="matter-component right">
                    <ul class="matter">
                        <li class="padding-left-10 padding-right-10 transaction-count">
                            <div class="padding-top-10 bold right"
                                 title="Total number of Purchase, Sale and Mortgage matters opened this month">
                                {{billedMattersForThisMonthText}}
                            </div>
                        </li>
                        <li class="margin-right-10 matter-refresh-button">
                            <button class="dp-btn-matter-list dp-refresh-list-btn" tabindex="0"
                                (click)="refreshList();">
                                <b class="far fa-sync-alt"></b>
                            </button>
                            <span class="matter-locked-message">
                                <div class="bubble-arrow">
                                    <img src="/assets/img/bubble_arrow_top.png" alt=""/>
                                </div>
                                <span>
                                   Reload Matter List
                                </span>
                            </span>
                        </li>
                        <li>
                            <dp-dropdown-menu [dropDownItems]="multipleMatterOptions"
                                              (itemClicked)="clickActionDropDown($event)" [buttonLabel]="'Actions'"
                                              [openSubMenuOnLeft]="false"
                                              [openSubSubMenuOnLeft]="true"
                                              [secondLevelScrolling]="true"
                                              [addFocus]="true"></dp-dropdown-menu>

                        </li>

                        <li>
                            <dp-dropdown-menu *ngIf="displayAddNew"
                                              [dropDownItems]="AddNewMatterOptions"
                                              (itemClicked)="clickAddNewDropDown($event)" [buttonLabel]="'Add New'"
                                              [firstLevelScrolling]="false"
                                              [secondLevelScrolling]="true"
                                              [openSubMenuOnLeft]="false"
                                              [openSubSubMenuOnLeft]="true"></dp-dropdown-menu>
                        </li>
                        <li *ngIf="displayQuickOpen">
                            <button type="button"  id="quickOpen" (click)="openWizardTemplate()" [disabled]="false" class="dp-btn">
                                <span>Quick Open</span>
                            </button>
                        </li>
                        <li *ngIf="displayOpenNew">
                            <button type="button"  id="openNew" (click)="openWizardTemplate()" [disabled]="false" class="dp-btn">
                                <span>Open New</span>
                            </button>
                        </li>
                    </ul>
                    <div class="clear"></div>
                </div>
                <div class="clear"></div>
            </div>

            <div class="clear"></div>
        </div>
        <div class="col-lg-12 margin-top-10 " *ngIf="showFilters">
            <div class="col-lg-2">
                <div class="col-lg-7 margin-top-5">
                    <label class="control-label">Closing in Next</label>
                </div>
                <div class="col-lg-4 margin-left-minus-17">
                    <input type="number"
                           class="form-control padding-5"
                           min="0" max="999"
                           [(ngModel)]="matterListState.closingDayFilter"
                           (ngModelChange)="onClosingInNDaysFilterChange()"
                    />
                </div>
                <div class="col-lg-1 margin-top-5">
                    <label>days</label>
                </div>
            </div>
            <div class="col-lg-1  margin-top-5 text-center">
                <label class="control-label bold">OR</label>
            </div>
            <div class="col-lg-2" >
                <div class="col-lg-2 margin-top-5 padding-left-0">
                    <label class="control-label">
                        From
                    </label>
                </div>
                <div class="col-lg-10">
                    <dp-partial-date
                        #closingDateFrom
                        [showFormattedDateLabel]="false"
                        [inputDate]="matterListState.closingDateFrom"
                        [fieldKey]="'matter.search.closingDate.from'"
                        (dateChange)="onStartDateChange($event)">
                    </dp-partial-date>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="col-lg-2 margin-top-5 text-right">
                    <label class="control-label">
                        To
                    </label>
                </div>
                <div class="col-lg-10">
                    <dp-partial-date
                        #closingDateTo
                        [showFormattedDateLabel]="false"
                        [fieldKey]="'matter.search.closingDate.to'"
                        [inputDate]="matterListState.closingDateTo"
                        (dateChange)="onEndDateChange($event)">
                    </dp-partial-date>
                </div>
            </div>
            <div class="col-lg-2 padding-top-5">
                <div class="right" [ngClass]="{'not-visible' : !isResetFilterVisible()}">
                    <a class="show-filters-btn"
                       href="javascript:void(0);"
                       (click)="resetFilters()" >Reset Filter</a>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="right matter-component" *ngIf="!isMassUpdateTab()">
                    <ul class="matter">
                        <li class="right">
                            <button *ngIf="!isAllMattersView && !isRecentMattersView" label="Delete Filter"
                                    class="dp-btn-white" (click)="deleteMatterListFilter()">
                                <span>Delete Filter</span>
                                <div class="clear"></div>
                            </button>
                        </li>
                        <li class="right">
                            <button *ngIf="!isAllMattersView && !isRecentMattersView" label="Update Filter"
                                    class="dp-btn-white" (click)="updateMatterListFilter()">
                                <span>Update Filter</span>
                                <div class="clear"></div>
                            </button>
                        </li>
                        <li class="right">
                            <button label="Save Filter" class="dp-btn-white" (click)="createNewMatterListFilter()">
                                <span>Create Filter</span>
                                <div class="clear"></div>
                            </button>
                        </li>


                    </ul>
                </div>
            </div>
            <div class="clear"></div>
        </div>

    </div>
    <div class="clear"></div>
    </ng-container>


    <div class="view-matter">

        <table class="table matter-table" [mfData]="matterListState.rows" #mf="mfDataTable"
               [ngClass]="{'filter-on': showFilters, 'mass-update': isMassUpdateTab()}" tabindex="-1">
            <tbody>
                <tr>
                <td colspan="7" class="padding-0">
                    <div class="matter-inner-table" infinite-scroll [scrollWindow]="true" (scrolled)="getNextPage()"
                    infiniteScrollDistance="1"
                    infiniteScrollThrottle="1000">
                        <table (keydown)="tableKeyCommands($event)" tabindex="-1">
                            <thead>
                            <tr>
                                <th class="table-cell-01">
                                    <ng-container *ngIf="!isMassUpdatePrecedentTab()">
                                        <div class="table-cell-01 padding-left-5">
                                            <dp-checkbox class="checkbox-position" fieldId="master-checkbox"
                                                         [hasTabIndex]="true"
                                            ><input type="checkbox" id="master-checkbox"
                                                    [checked]="isAllMattersSelected()"
                                                    (click)="checkAllMatters()"/>
                                            </dp-checkbox>
                                        </div>
                                    </ng-container>
                                </th>
                                <th class="table-cell-11 vertical-align-bottom">

                                    <div class="table-cell-11 table-head-pointer"
                                         (click)="sortMatterList('matterType')">
                                        Type
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'matterType' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'matterType', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>
                                </th>
                                <th class="table-cell-02 vertical-align-bottom">
                                    <div class="table-cell-02 table-head-pointer"
                                         (click)="sortMatterList('sortIndex.sortIndex')">
                                        Matter
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'sortIndex.sortIndex' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'sortIndex.sortIndex', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>
                                </th>
                                <th class="table-cell-03">
                                </th>
                                <th class="table-cell-04 vertical-align-bottom">
                                    <div class="table-cell-04 table-head-pointer "
                                         (click)="sortMatterList('matterSearchView.clientReline')">Matter RE: Line
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'matterSearchView.clientReline' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'matterSearchView.clientReline', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>
                                </th>
                                <th class="table-cell-05 vertical-align-bottom">

                                    <div class="table-cell-05 table-head-pointer"
                                         (click)="sortMatterList('matterSearchView.propertyAddress')">Address/Description
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'matterSearchView.propertyAddress' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'matterSearchView.propertyAddress', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>

                                </th>
                                <th class="table-cell-06 vertical-align-bottom">
                                    <div class="table-cell-06 table-head-pointer"
                                         (click)="sortMatterList('matterSearchView.lawyerInitials')">{{lawyerTitle}}
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'matterSearchView.lawyerInitials' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'matterSearchView.lawyerInitials', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>


                                </th>
                                <th class="table-cell-07 vertical-align-bottom">
                                    <div class="table-cell-07 table-head-pointer"
                                         (click)="sortMatterList('matterSearchView.lawClerkInitials')">{{lawClerkTitle}}
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'matterSearchView.lawClerkInitials' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'matterSearchView.lawClerkInitials', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>
                                </th>

                                <th class="table-cell-09 vertical-align-bottom">
                                    <div class="table-cell-09 table-head-pointer"
                                         (click)="sortMatterList('sortMatterCloseDate')" dp-province-field-label= "matter.closeDate" [label-province-code]="userStateService.defaultProvinceCode">Closing Date
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'sortMatterCloseDate' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'sortMatterCloseDate', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>

                                </th>
                                <th class="table-cell-10 vertical-align-bottom">

                                    <div class="table-cell-10 table-head-pointer"
                                         (click)="sortMatterList('lastUpdatedTimeStamp')">Last Updated
                                        <div class="sort-icons"
                                             *ngIf="matterListState.sortQuery === 'lastUpdatedTimeStamp' && !isRecentMattersView">
                                            <i class="glyphicon"
                                               [ngClass]="{'active': matterListState.sortQuery === 'lastUpdatedTimeStamp', 'glyphicon-triangle-top': matterListState.sortType === 'ASC', 'glyphicon-triangle-bottom': matterListState.sortType === 'DESC'}"></i>
                                        </div>
                                    </div>

                                </th>

                            </tr>


                            </thead>

                            <tbody>
                            <tr class="loading-row" *ngIf="loading &&  matterListState.pageNo==1">
                                <td colspan="11" class="text-center">
                                    <div id="spinner">
                                        <img src="assets/img/spinner.gif" alt="spinner"/>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let row of mf.data; let i = index;" (click)="handleRowSelect(row,$event)"
                                [ngClass]="{'active': isSelected(row)}" [attr.tabindex]="0" (focus)="setActiveIndex(i)">
                                <td class="datatable-body-cell check-box-column">
                                    <div  class="table-cell-01 text-margin-dropdown check-box-column padding-left-5">
                                        <div *ngIf="row['locked'] && !isMatterReadOnlyAccess" class="matter-lock-icon-div">
                                            <span class="matter-lock-icon">
                                                <i class="fa fa-lock"></i>
                                            </span>
                                            <span class="matter-locked-message">
                                                <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png"
                                                                               alt=""/></div>
                                                <span>
                                                    Locked by: <u><i>{{ row['lockedByUser']['firstName'] + ' ' + row['lockedByUser']['lastName']}}</i></u>
                                                </span>
                                            </span>
                                        </div>
                                        <div  *ngIf="(!row['locked'] || isMatterReadOnlyAccess) && !isMassUpdatePrecedentTab()" class="check-box-column">
                                            <dp-checkbox [hasTabIndex]="false" fieldId="selected_{{i}}"><input
                                                type="checkbox" id="selected_{{i}}"
                                                [disabled]="isMatterCheckboxDisabled(row)"
                                                (click)="handleRowSelect(row,$event)"
                                                [checked]="isSelected(row)"/></dp-checkbox>

                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="table-cell-11 text-overflow"> {{
                                        getMatterTypeShortName(row) }}
                                    </div>
                                </td>
                                <td>
                                    <ng-container *ngIf="isMassUpdateTab() && !isMassUpdatePrecedentTab()">
                                        <div class="table-cell-02">{{getMatterRecordNumber(row)}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!isMassUpdateTab()">
                                    <div class="table-cell-02">
                                        <a (click)="openMatter(row)" title="{{getMatterRecordNumberHelpText(row)
                                            }}" class="text-overflow"><i>{{ getMatterRecordNumber(row)
                                            }}</i></a>
                                    </div>
                                    </ng-container>
                                    <ng-container *ngIf="isMassUpdateTab() && isMassUpdatePrecedentTab()">
                                        <div class="table-cell-02">
                                            <a (click)="loadPrecedentMatter(row)" title="{{getMatterRecordNumberHelpText(row)
                                            }}" class="text-overflow"><i>{{ getMatterRecordNumber(row)
                                                }}</i></a>
                                        </div>
                                    </ng-container>
                                </td>
                                <td>
                                    <div class="table-cell-03 ">
                                        <ng-container *ngIf="!isMassUpdateTab()">
                                            <dp-dropdown-menu
                                                [dropDownItems]="row.matterBurgerMenu"
                                                [menuSize]="[2]"
                                                (menuOpened)="loadMatterRowBurgerMenuOptions(row)"
                                                (itemClicked)="clickMatterRowDropDown(row, $event)"
                                                (burgerFocused)="addClassToRow(i)"
                                                [addFocus]="true"
                                                [tabIndex]="-1">
                                            </dp-dropdown-menu>
                                        </ng-container>
                                    </div>
                                </td>
                                <td>
                                    <div class="table-cell-04 text-overflow padding-right-10 ">{{
                                        row.getMatterReline() }}
                                    </div>
                                </td>
                                <td>
                                    <div class="table-cell-05 text-overflow">
                                        {{ row.getMatterAddress() }}
                                    </div>
                                </td>
                                <td>
                                    <div class="table-cell-06 text-margin-dropdown">
                                        {{ row.getlawSolicitorName() }}
                                    </div>
                                </td>
                                <td>
                                    <div class="table-cell-07 text-margin-dropdown">{{
                                        row.getlawClerkName() }}
                                    </div>
                                </td>
                                <td>
                                    <div class="table-cell-08"></div>
                                </td>
                                <td>
                                    <div class="table-cell-09"
                                         [innerHTML]="row.matterCloseDate | detaileddate"></div>
                                </td>
                                <td>
                                    <div class="table-cell-10">{{ row.lastUpdatedTimeStamp |
                                        date:'MMM d, y' }}
                                    </div>
                                </td>

                            </tr>

                            <tr *ngIf="matterListState.listEndReached && matterListState.rows.length === 0"
                                class="loading-row">
                                <td colspan="11" class="text-center">
                                    <div class="no-match-found">No Match Found</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
                </tr>
            </tbody>
        </table>


    </div>
    <div id="spinner2" class="bottom-loading-row" *ngIf="loading && matterListState.pageNo>1">
        <img src="assets/img/spinner.gif" alt="spinner"/>
    </div>
    <div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
        <div id="export-spinner">
            <span> <img class="lockSpinner" src="assets/img/spinner.gif" alt="spinner"/></span>
        </div>
    </div>

</div>

