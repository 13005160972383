import {api} from '../../common';

export const chicagoTitleApi = {
  submitFile: `${ api }/integrations/chicago-title/matters/{matterId}/files`,
  getAllStatusDetails: `${ api }/integrations/chicago-title/matters/{matterId}/titleInsuranceDetails`,
  downloadDocument: `${ api }/integrations/chicago-title/matters/{matterId}/titleInsurances/{titleInsuranceId}/documents/{documentNumber}`,
  cancelTitleInsurance: `${ api }/integrations/chicago-title/matters/{matterId}/titleInsurances/{titleInsuranceId}/cancel`,
  deleteDocuments: `${ api }/integrations/chicago-title/matters/{matterId}/titleInsurances/{titleInsuranceId}/documents`

};

export const ChicagoTitleStatus = {
  NOT_YET_SUBMITTED: 'Not Yet Submitted',
  READY_FOR_PICKUP: 'Ready for Pickup',
  CANCELLED: 'Cancelled'
};

export const chicagoTitleCancellationOptions = [
  {label: 'Duplicate', value: 'DUPLICATE'},
  {label: 'Deal fell through', value: 'DEAL_FELL_THROUGH'},
  {label: 'Lender does not accept CTIC', value: 'LENDER_DOES_NOT_ACCEPT_CTIC'},
  {label: 'Other', value: 'OTHER'}
];

export const ChicagoTitleXpathConfigs = {
  xPathConfigs: [
    {xpath: '$.matterType', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.realEstateBrokerName', matterType: 'P', xpathType: 'Object'},
    {xpath: '$.matterCloseDate', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].propertyType', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].purchaseIsOfCode', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].isParcelOfTiedLand', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].nameOfCondominiumPlan', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].purchasePrice', matterType: 'P', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].partLot', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].plan', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].address.addressLine1', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].address.addressLine2', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].address.provinceName', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].address.city', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].address.postalCode', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterParticipants[?(@.matterParticipantRole == "PURCHASER")].contact.nameOnDocuments', matterType: 'P', xpathType: 'List'},
    {xpath: '$.matterParticipants[?(@.matterParticipantRole == "MORTGAGOR")].contact.nameOnDocuments', matterType: 'M', xpathType: 'List'},
    {xpath: '$.matterProperties[*].pin', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.matterProperties[*].propertyTaxesSummary', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.matterProperties[?(@.isCondominium == "YES")].condominiumExpenses[*].pinNumber', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.matterProperties[?(@.isCondominium == "YES")].condominiumExpenses[*].unitNumber', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.matterProperties[?(@.isCondominium == "YES")].condominiumExpenses[*].levelNumber', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.mortgages[?(@.mortgageType == "NEW")].mortgagePriority', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.existingMortgages[*].mortgagePriority', matterType: 'M', xpathType: 'List'},
    {xpath: '$.mortgages[*].loanType', matterType: 'P', xpathType: 'List'},
    {
      xpath: '$.matterParticipants[?(@.matterParticipantRole == "PRIVATE_LENDER" || @.matterParticipantRole == "MORTGAGEE")].contact.displayName',
      matterType: 'PM',
      xpathType: 'List'
    },
    {xpath: '$.mortgages[?(@.mortgageType == "NEW")].mortgageTerm.principal', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.mortgages[?(@.mortgageType == "NEW")].mortgageeType', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.existingMortgages[*].mortgageeType', matterType: 'M', xpathType: 'List'},
    {xpath: '$.existingMortgages[*].mortgageDispositionType', matterType: 'M', xpathType: 'List'},
    {xpath: '$.existingMortgages[*].amountPayableToDischarge', matterType: 'M', xpathType: 'List'},
    {xpath: '$.matterRecordNumber', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterParticipants[?(@.matterParticipantRole == "LAWCLERK")].contact.displayName', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].interestEstate', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].numOfUnits', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.city', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.municipality', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.map', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.subdivision', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.parcel', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.portion', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.matterProperties[0].rollNumber.jurisdiction', matterType: 'PM', xpathType: 'Object'},
    {xpath: '$.mortgages[?(@.mortgageType == "NEW")].loanNo', matterType: 'PM', xpathType: 'List'},
    {xpath: '$.matterParticipants[?(@.matterParticipantRole == "SOLICITOR")].contact.displayName', matterType: 'PM', xpathType: 'Object'}
  ]
};

export const chicagoTitleLinks = {
  register: 'https://chicagotitle.ca/register/',
  quote: 'https://chicagotitle.ca/get-a-quote/'
};
