import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {currentMatter, Matter, MatterParticipant, matterUndertakingStatusDropdowns, MatterUtil} from '../shared'; //Matter State injection
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {AppConfig} from '../../shared-main/app-configuration';
import {DialogConfigParams, DialogService} from '../../shared/dialog/dialog.service';
import {TeranetConnectModal} from '../teranet-connect/teranet-connect.modal.component';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {TeranetBalanceModal} from '../teranet-connect/teranet-balance.modal.component';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {MatterTab} from '../matter-tab';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {TeranetSearchModal} from './teranet-connect-detail/search-parcel-register/teranet-search.modal.component';
import {TeranetChangePasswordModal} from '../../main/manage-thirdparty-credentials/teranet-change-password.modal.component';
import {Observable, Subscription} from 'rxjs/Rx';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {ParcelRegister} from '../../shared-main/teranet/parcel-register';
import {TeranetPropertyOwner} from '../../shared-main/teranet/teranet-property-owner';
import {Instrument} from '../../shared-main/teranet/instrument';
import {TeranetDocument} from '../../shared-main/teranet/teranet-document';
import {TeranetOrderMapResponse} from '../../shared-main/teranet/teranet-order-map-response';
import {TeranetOrderMapRequest} from '../../shared-main/teranet/teranet-order-map-request';
import {messages} from '../../common/messages';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {
  LtsaInstrumentMenuActions,
  SpinInstrumentMenuActions,
  TeranetInstrumentMenuActions,
  TeranetWritMenuActions
} from '../../shared-main/burger-menu-actions';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {TeranetRequestInstrumentRequest} from '../../shared-main/teranet/teranet-request-instrument-request';
import {TeranetRequestInstrumentResponse} from '../../shared-main/teranet/teranet-request-instrument-response';
import {TeranetInstrumentRequestModal} from '../teranet-connect/teranet-instrument-request.modal.component';
import {teranetConnectLinks, TeranetRequestInstrumentSubmissionState} from '../../shared-main/teranet/teranet-constants';
import {TeranetOrderDocument, TeranetOrderInstrumentRequest} from '../../shared-main/teranet/teranet-order-instrument-request';
import {TeranetOrderInstrumentResponse} from '../../shared-main/teranet/teranet-order-instrument-response';
import {ParcelNode} from './model/parcel-node';
import {ApplicationError, FieldError} from '../../core/application-error';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {ViewMapModalComponent} from './teranet-connect-detail/view-map/view-map.modal.component';
import {DateFormatPipe} from '../../shared-main/date-format.pipe';
import {TeranetAddInstrumentModal} from './teranet-add.instrument.modal.component';
import {Writ, WritPartyData} from '../../shared-main/teranet/property-writ';
import {MatterParticipantRole} from '../shared/matter-participant-role-types';
import {SelectItem, TreeNode} from 'primeng/api';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import {matterLabels} from '../../shared-main/dp-label-value';
import {Document} from '../document-production/document';
import moment from 'moment';
import {CourierInstrumentModal} from './teranet-connect-detail/courier-instrument/courier-instrument.modal.component';
import {JurisdictionDepartmentsService} from '../../admin/jurisdiction-departments/jurisdiction-departments.service';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import 'rxjs/add/operator/finally';
import {TeranetParcelRegisterPurchaseRequest} from '../../shared-main/teranet/teranet-parcel-register-purchase-request';
import {LockScreenService} from '../../core/lock-screen.service';
import {TeranetParcelRegisterSearchResponse} from '../../shared-main/teranet/teranet-parcel-register-search-response';
import {TeranetParcelRegisterSearchRequest} from '../../shared-main/teranet/teranet-parcel-register-search-request';
import {TeranetPinInfo} from '../../shared-main/teranet/teranet-pin-info';
import {
  AnalysisAdjacentParcelComponent,
  AnalysisAdjacentParcelModalFocus
} from './teranet-connect-detail/analysis-adjacent-parcel/analysis-adjacent-parcel.modal.component';
import {WritNameSearchModal} from './teranet-connect-detail/writ-search/writ-name-search.modal.component';
import {Section} from '../shared/section';
import {TreeNodeUtil} from '../../shared-main/tree-node/tree-node-util';
import {TeranetWritSearchRequest, WritSearchType} from '../../shared-main/teranet/teranet-writ-search-request';
import {TeranetWritNumberSearchRequest, WritExecutionInfo} from '../../shared-main/teranet/teranet-writ-number-search-request';
import {WritNumberSearchModal} from './teranet-connect-detail/writ-search/writ-number-search.modal.component';
import {TeranetWritSearchResponse} from '../../shared-main/teranet/teranet-writ-search-response';
import {RequisitionsAddModal} from '../requisitions/requisitions-add.modal.component';
import {RequisitionsEditorModal} from '../requisitions/requisitions-editor.modal.component';
import {RequisitionsEditorModalResult} from '../requisitions/requisitions-editor.modal.enum';
import {RequisitionTemplate} from '../shared/requisition-template';
import {RequisitionInstrumentConfig} from '../shared/requisition-instrument-config';
import {RequisitionsService} from '../requisitions/requisitions.service';
import {dropDowns, requisitionStatusDropdownOptions} from '../mortgages/mortgage/dropdown-options';
import {TeranetDocket} from '../../shared-main/teranet/teranet-docket';
import {
  GetTeranetDocketSummaryModalComponent
} from './teranet-connect-detail/get-teranet-docket-summary/get-teranet-docket-summary.modal.component';
import {
  ViewTeranetDocketSummaryModalComponent
} from './teranet-connect-detail/view-teranet-docket-summary/view-teranet-docket-summary.modal.component';

import {WritExecution} from '../../shared-main/teranet/property-writ-execution';
import {EnforcementOffice} from '../../shared-main/teranet/enforcement-office';
import {Mortgage} from '../shared/mortgage';
import {
  WritSearchDeclarationsModal,
  WritSearchDeclarationsModalContext
} from './teranet-connect-detail/writ-search/writ-search-declarations.modal.component';
import {Declaration} from '../executions-affidavits/declaration';
import {ParagraphTemplate} from '../shared/paragraph-template';
import {Paragraph} from '../executions-affidavits/paragraph';
import {ParagraphTemplateService} from '../../shared-main/paragraph-template.service';
import Utils from '../../shared-main/utils';
import {DecimalPipe} from '@angular/common';
import {ParagraphDetailModel, ParagraphDetailModelContext} from '../executions-affidavits/paragraph-detail.modal.component';
import {
  WritSearchAddParagraphModal,
  WritSearchAddParagraphModalContext
} from './teranet-connect-detail/writ-search/writ-search-add-paragraph.modal.component';
import {
  WritSearchAddParagraphQuestionnaireModal,
  WritSearchAddParagraphQuestionnaireModalContext
} from './teranet-connect-detail/writ-search/writ-search-add-paragraph-questionnaire.modal.component';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {InstrumentWrapper, WritsCollection, WritWrapper} from '../shared/writ-collection-instrument-wrapper';
import {DpDropDownComponent} from '../../shared-main/dropdown-menu/dropdown-menu.component';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {DocumentProductionService} from '../document-production/document-production.service';
import {
  ExistingFile,
  FileUploadModal,
  FileUploadModalContext,
  UploadStatus
} from '../document-production/upload/file-upload-modal.component';
import {matterApi} from '../shared/matter-api';
import {CapacityTypes, PurchaserCapacity} from '../purchaser/capacity/purchaser-capacity';
import {TeranetImportMortgage} from '../../shared-main/teranet/teranet-import-mortgage';
import {MortgageDispositionType} from '../../shared-main/constants';
import {PermittedRegistration, PermittedRegistrationMaxLength} from '../shared/permitted-registration';
import {MatterUndertaking} from '../shared/matter-undertaking';
import {undertakingTypes} from '../../common/common-labels';
import {PermittedRegistrationModalComponent} from '../undertakings/permitted-registration/permitted-registration-modal.component';
import {UndertakingsDetailModal, UndertakingsDetailModalContext} from '../undertakings/undertakings-detail.modal.component';
import {MatterParticipantService} from '../matter-participant-service';
import {MatterService} from '../matter.service';
import {MortgageSoAdjService} from '../../shared-main/mortgage-so-adj.service';
import {UndertakingsConfigService} from '../../admin/shared/undertaking-config.service';
import {PageEvent} from '@pascalhonegger/ng-datatable';
import {MatDialogRef} from '@angular/material/dialog';
import {ImportDataService} from '../shared/services/import-data.service';
import {PurchaserService} from '../purchaser';
import {Subject} from 'rxjs/Subject';
import {provinceBasedFinancingTypeDropDown} from '../../shared-main/province-based-dropdowns';

declare var jQuery: any;

@Component({
  selector: 'dp-teranet-connect',
  styleUrls: [
    './property-teranet.scss'
  ],
  templateUrl: 'teranet-connect.component.html',
  providers: [ ImportDataService ]
})

@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class TeranetConnectComponent implements OnInit {
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;
  @Input() showWizardFields: boolean = false;
  @Input() blanketMortgage: boolean = false;
  @Input() mortgageId: number;
  @Output() updateOwnerDataForWizard = new EventEmitter<string>();
  @Output() updatePropertyDataForBlanketMortgage = new EventEmitter<ParcelRegister>();
  ACCEPTED_FILE_EXTENSIONS: string;//= '*.pdf'

  navTopSelected = 'PropertyDescription';
  navBottomSelected = 'Instruments';
  actionButtonFlag: boolean = false;
  actionButtonFlagForAddOrder: boolean = false;
  actionButtonFlagForImportData: boolean = false;
  actionButtonFlagForInstrument: boolean = false;
  actionButtonFlagForWrits: boolean = false;
  clickListener: Function;
  selectedParcelRegister: ParcelRegister;
  selectAllInstruments: boolean = false;
  selectedInstrumentsIndexes: number[] = [];
  selectedNameIndexes: number[] = [];
  instrumentArray: InstrumentWrapper[] = [];
  writsCollection: WritsCollection = new WritsCollection(this.matter.teranetDocket);
  orderMapSubcription: Subscription;
  parcelNodes: TreeNode[] = [];
  selectedNode: TreeNode;
  importAvailable: boolean = true;
  displayWrits: boolean = true;
  interestEstates: SelectItem[];
  ontarioTaxRateSlab: ConsiderationTaxes;
  torontoTaxRateSlab: ConsiderationTaxes;
  jurisdictionDepartments: JurisdictionDepartment[] = [];
  analysisFocus: AnalysisAdjacentParcelModalFocus = {primary: 0, adjacent: -1};
  selectedWritExecutions: WritExecutionInfo[] = [];
  enforcementOffices: EnforcementOffice[] = [];

  blankParagraphTemplateSubscription: Subscription;
  newParagraphTemplateSubscription: Subscription;

  MINIMUM_JUDGEMENT_AMOUNT: number = 50000;

  tenureOptions: any[];

  financingTypes: SelectItem[];

  //TODO externalize URL to server side configuration
  readonly URL_AB_MATTER_SUBJECT_PROP_SPIN = 'https://alta.registries.gov.ab.ca/SpinII/logon.aspx';
  readonly URL_SK_MATTER_SUBJECT_PROP_ISC = 'https://www.isc.ca/Pages/default.aspx';
  readonly URL_SK_MATTER_SUBJECT_PROP_TPR = 'https://www.tprmb.ca/tol';

  constructor(public globalSaveModelService: GetGlobalSaveModelService,
              public renderer: Renderer2,
              public route: ActivatedRoute,
              public router: Router,
              public tabService: TabsService,
              public appConfig: AppConfig,
              public documentProfileCache: DocumentProfileCache,
              public teranetService: TeranetService,
              public dialogService: DialogService,
              public dateFormatPipe: DateFormatPipe,
              public errorService: ErrorService,
              public taxRateService: TaxRateService,
              public jurisdictionDepartmentsService: JurisdictionDepartmentsService,
              public lockScreenService: LockScreenService,
              public tabsStateService: TabsService,
              public requisitionsService: RequisitionsService,
              public paragraphTemplateService: ParagraphTemplateService,
              public decimalPipe: DecimalPipe,
              public matterParticipantService: MatterParticipantService,
              public documentProductionService: DocumentProductionService,
              public mortgageSoAdjService: MortgageSoAdjService,
              public matterService: MatterService,
              public purchaserService: PurchaserService,
              public undertakingsConfigService: UndertakingsConfigService,
              public importDataService: ImportDataService
  ) {

    this.clickListener = renderer.listen('document', 'click', (event) => {
      /// need to close up any menus that are left open when we click in other places
      this.resetAllActionButtons();
    });
  }

  ngOnInit(): void {

    if (this.parcelRegisters && this.parcelRegisters.length > 0) {
      this.parcelRegisters.forEach(item => {
        this.matter.updateSameParcelRegisterIndex(item);
      }, this);
      this.selectedParcelRegister = this.parcelRegisters[ this.getLastImportedParcelRegisterIndex() ];
      this.fillInstrumentTable();
      this.fillWritsArray();
      this.loadEnforcementOffices();
    }

    this.jurisdictionDepartmentsService.getDepartmentPairs(null, this.matter.provinceCode)
    .subscribe(
      (jurisdictionDepartments: JurisdictionDepartment[]) => {
        if (jurisdictionDepartments && jurisdictionDepartments.length > 0) {
          this.jurisdictionDepartments = jurisdictionDepartments;

        }
      });

    this.taxRateService.cachedConsiderationTaxRate(this.matter.provinceCode)
    .subscribe(
      (considerationTaxes: ConsiderationTaxes[]) => {
        if (considerationTaxes && considerationTaxes.length > 0) {
          this.ontarioTaxRateSlab = TaxRateService.getLatestOntarioTaxRateSlabFromConsiderationTaxes(
            considerationTaxes
          );
          this.torontoTaxRateSlab = TaxRateService.getLatestTorontoTaxRateSlabFromConsiderationTaxes(
            considerationTaxes
          );
        }
      });
    this.updateParcelTreeTable();

    this.getScrollBarSize();

    this.tenureOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(this.matter.mainClients && this.matter.mainClients.length, this.matter.provinceCode);
    this.financingTypes = this.getFinancingTypeOptions();
    this.ACCEPTED_FILE_EXTENSIONS = this.acceptedFileExtensionByProvince();
  }

  getFinancingTypeOptions() {
    if (this.matter.isMatterProvinceBC) {
      return provinceBasedFinancingTypeDropDown[ this.matter.provinceCode ];
    }
    return dropDowns.financingTypeOptions;
  }

  isImportMortgageMenuOptionApplicable(): boolean {
    return this.importDataService.isImportMortgageMenuOptionApplicable(this.selectedParcelRegister);
  }

  getLastImportedParcelRegisterIndex(): number {
    //return this.matter.matterProperties[0].lastImportedParcelRegisterId != 0? this.parcelRegisters.findIndex( item => item.id === this.matter.matterProperties[0].lastImportedParcelRegisterId): 0;
    return this.matter.matterPropertyWithCondo.lastImportedParcelRegisterId ? this.parcelRegisters.findIndex(item => item.id === this.matter.matterPropertyWithCondo.lastImportedParcelRegisterId) : 0;
  }

  acceptedFileExtensionByProvince(): string {
    let acceptedFileExtension: string = '*.pdf';

    if (this.matter.isMatterProvinceMB) {
      acceptedFileExtension = '*.xml';
    }
    return acceptedFileExtension;
  }

  loadEnforcementOffices(): void {
    this.teranetService.getEnforcementOffices()
    .subscribe(offices => {
      this.enforcementOffices = offices;
    });
  }

  updateParcelTreeTable() {

    let pNodes = this.parcelRegisters
    .sort((a: ParcelRegister, b: ParcelRegister): number => {
      return a.id > b.id
        ? 1
        : (a.id < b.id ? -1 : 0);
    })
    .map(pr => <ParcelNode>{
      parcel: pr,
      canHaveMap: true,
      lt: `(${ pr.registrationType })`,
      certificateTitle: pr.parcelImage?.fileName,
      hasPdf: pr.parcelImage ? pr.parcelImage.downloaded : false,

      adjacentParcels: pr.adjacentParcels.map(apr => <ParcelNode>{
        parcel: apr,
        canHaveMap: false,
        displayPin: apr.pin,
        hasPdf: apr.parcelImage ? apr.parcelImage.downloaded : false
      })
    });
    for (let pNode of pNodes) {
      if (!pNode.displayPin) {
        let nodesWithSamePin: ParcelNode[] = pNodes.filter(item => item.parcel.pin == pNode.parcel.pin);
        if (nodesWithSamePin.length > 1) { //add suffix with (1), (2), etc. if multiple parcel registers for same Pin
          for (let i: number = 0; i < nodesWithSamePin.length; i++) {
            nodesWithSamePin[ i ].displayPin = nodesWithSamePin[ i ].parcel.pin + ' (' + (i + 1) + ')';
          }
        } else {
          pNode.displayPin = pNode.parcel.pin;
        }
      }
    }

    this.parcelNodes = pNodes.map(p => <TreeNode>{
        data: p,
        expanded: true,
        children: p.adjacentParcels.map(pc => <TreeNode>{
          data: pc,
          leaf: true
        })
      }
    );

    if (this.selectedParcelRegister && this.parcelNodes && this.parcelNodes.length > 0) {
      this.selectedNode = this.parcelNodes.find(p => p.data.parcel.id == this.selectedParcelRegister.id);
      this.updatePinDetails(this.selectedParcelRegister);
    }
  }

  openExternalUrl(): void {
    let url: string = this.URL_SK_MATTER_SUBJECT_PROP_ISC;
    if (this.matter.isMatterProvinceAB) {
      url = this.URL_AB_MATTER_SUBJECT_PROP_SPIN;
    } else if (this.matter.isMatterProvinceMB) {
      url = this.URL_SK_MATTER_SUBJECT_PROP_TPR;
    }

    window.open(url, '_blank');
  }

  addTeranetOrder(): void {
    this.callTeranetOperation(() => {
      this.openTeranetSearchModal();
    });
  }

  openImportPropertyData(): Subject<void> {
    return this.importDataService.openImportPropertyData(this.selectedParcelRegister,
      this.ontarioTaxRateSlab, this.torontoTaxRateSlab, this.jurisdictionDepartments,
      this.blanketMortgage, this.updatePropertyDataForBlanketMortgage,
      this.showWizardFields, this.updateOwnerDataForWizard
    );
  }

  openTeranetSearchModal = () => {
    this.dialogService.matDialogContent({
      content: TeranetSearchModal,
      context: {
        matter: this.matter,
        teranetUser: this.teranetUser,
        documentProfileCache: this.documentProfileCache
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK') {
          this.selectedParcelRegister = this.parcelRegisters[ this.parcelRegisters.length - 1 ];
          if (!this.showWizardFields) {
            this.fillInstrumentTable();
            this.updateParcelTreeTable();
            if (this.matter.matterPropertyWithCondo.isTitleSearchPerformed != DpBooleanValueTypes.YES) {
              this.matter.matterPropertyWithCondo.isTitleSearchPerformed = DpBooleanValueTypes.YES;
              this.matter.dirty = true;
            }
          } else if (this.selectedParcelRegister) {
            this.openImportPropertyData();
          }
        } else {
          this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
        }
      },
      onRejection: () => {
        this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
      }
    });
  };

  openOwnerImportTeranetModal(): void {
    return this.importDataService.openOwnerImportTeranetModal(this.selectedParcelRegister, this.showWizardFields, this.updateOwnerDataForWizard);
  }

  openImportTeranetMortgageModal(): void {
    return this.importDataService.openImportTeranetMortgageModal(this.selectedParcelRegister);
  }

  openTeranetConnectLogin(callback: Function): void {
    this.dialogService.matDialogContent({
      content: TeranetConnectModal,
      context: {},
      onFulfillment: (result: any) => {
        if (result && result.action == 'PASSWORD_EXPIRED' && result.teranetUser) {
          this.openChangePasswordModal(callback, result.teranetUser);
        } else if (result && result.teranetUser) {
          this.matterTab.loggedInTeranetUser = result.teranetUser;
          this.showBalanceAndProceedToCallback(callback);
        } else {
          this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
        }
      },
      onRejection: () => {
        this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
      }
    });
  }

  openChangePasswordModal(callback: Function, teranetUser: TeranetUser) {
    this.dialogService.matDialogContent({
      content: TeranetChangePasswordModal,
      context: {
        teranetUser: teranetUser
      },
      onFulfillment: (result: any) => {
        if (result && result.teranetUser) {
          this.dialogService.confirm('Teranet Connect &trade;: Change Password ', 'Your password was successfully updated.', true, 'OK').subscribe(res => {
          });
          this.matterTab.loggedInTeranetUser = result.teranetUser;
          callback();
        }
      }
    });
  }

  openTeranetBalanceModal(teranetUser: TeranetUser, autoClose: boolean, callback?: Function): void {
    this.dialogService.matDialogContent({
      content: TeranetBalanceModal,
      context: {
        teranetUser: teranetUser,
        autoClose: autoClose
      },
      onFulfillment: (result: any) => {
        if (callback) {
          callback();
        }
      }
    });
  }

  openInstrumentRequestModal(teranetUser: TeranetUser, requestInstrumentResponse: TeranetRequestInstrumentResponse, instrument: Instrument, callback?: Function): void {
    this.dialogService.matDialogContent({
      content: TeranetInstrumentRequestModal,
      context: {
        teranetUser: teranetUser,
        requestInstrumentResponse: requestInstrumentResponse,
        instrument: instrument
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK') {
          this.fillInstrumentTable();
        }
        if (instrument.analysisStillNeeded) {
          this.viewAdjacentParcelAnalysis();
        }
      }
    });
  }

  checkTeranetBalance(): void {
    this.callTeranetOperation(() => {
      let teranetUser: TeranetUser = this.matterTab.loggedInTeranetUser;
      this.teranetService.getTeranetBalance(teranetUser).subscribe(
        (teranetResult: TeranetUser) => {
          this.openTeranetBalanceModal(teranetResult, false);
        });
    });
  }

  //This method is a bridge for calling teranet operations. It makes sure that matter is saved and user is authenticated
  callTeranetOperation(callback: Function): void {
    //If matter is not saved then user has to first save the matter
    if (this.matter.dirty) {
      this.callTeranetAfterMatterSave(() => {
        this.callAfterTeranetAuthentication(callback);
      });
    } else {
      //If matter is saved then call operation after the authentication
      this.callAfterTeranetAuthentication(callback);
    }

  }

  callAfterTeranetAuthentication(callback: Function): void {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let userId = Utils.getAuthenticatedUserId();
    let teranetUser: TeranetUser = this.matterTab.loggedInTeranetUser;
    if (teranetUser) {
      callback();
    } else {
      this.openTeranetConnectLogin(callback);
    }
  }

  showBalanceAndProceedToCallback(callback: Function) {
    let teranetUser: TeranetUser = this.matterTab.loggedInTeranetUser;
    this.openTeranetBalanceModal(teranetUser, true, callback);
  }

  callTeranetAfterMatterSave(callback: Function): void {
    this.dialogService.confirm('Confirmation', 'In order to proceed, the record must first be saved', false, 'Save').subscribe(res => {
      if (res && res == true) {
        let matterTab = this.tabService.activeTab as MatterTab;
        if (matterTab && matterTab.matterComponent) {
          let subscription: Subscription = matterTab.matterComponent.validateAndSaveMatter().subscribe((result: boolean) => {
            if (result) {
              //Once matter is saved then call operation after the authentication
              subscription.unsubscribe();
              if (callback) {
                callback();
              }
            }
          });
        }
      } else {
        console.log('cancel');
      }
    });
  }

  public toggleActionButtonFlag(event): void {
    this.resetAllActionButtons();
    this.closeDropDownMenu();
    event.stopPropagation();
    this.actionButtonFlag = !this.actionButtonFlag;
  }

  public toggleActionButtonFlagForAddOrder(event): void {
    this.resetAllActionButtons();
    this.closeDropDownMenu();
    event.stopPropagation();
    this.actionButtonFlagForAddOrder = !this.actionButtonFlagForAddOrder;
  }

  public toggleActionButtonFlagForInstrument(event): void {
    this.resetAllActionButtons();
    this.closeDropDownMenu();
    event.stopPropagation();
    this.actionButtonFlagForInstrument = !this.actionButtonFlagForInstrument;
  }

  public toggleActionButtonFlagForImportData(event): void {
    this.resetAllActionButtons();
    this.closeDropDownMenu();
    event.stopPropagation();
    this.actionButtonFlagForImportData = !this.actionButtonFlagForImportData;
  }

  public toggleActionButtonFlagForWrit(event): void {
    //ToDo: Need a better way to reset all action buttons instead of listing them in each toggle function
    this.resetAllActionButtons();
    event.stopPropagation();
    this.closeDropDownMenu();
    this.actionButtonFlagForWrits = !this.actionButtonFlagForWrits;
  }

  public resetActionButtonFlag(): void {
    this.actionButtonFlag = false;
  }

  public resetActionButtonFlagForAddOrder(): void {
    this.actionButtonFlagForAddOrder = false;
  }

  public resetActionButtonFlagForImportData(): void {
    this.actionButtonFlagForImportData = false;
  }

  public resetActionButtonFlagForInstrument(): void {
    this.actionButtonFlagForInstrument = false;
  }

  public resetActionButtonFlagForWrit(): void {
    this.actionButtonFlagForWrits = false;
  }

  get matterTab(): MatterTab {
    return this.tabService.activeTab as MatterTab;
  }

  get matter() {
    return currentMatter.value;
  }

  get parcelRegisters(): ParcelRegister[] {
    return this.matter && this.matter.teranetDocket ? this.matter.teranetDocket.parcelRegisters : [];
  }

  viewOrOrderMapsForSelectedParcelRegister(withRoads: boolean): string {
    if (this.selectedParcelRegister) {

      if (withRoads) {
        if (this.selectedParcelRegister.mapWithRoads && this.selectedParcelRegister.mapWithRoads.id && this.selectedParcelRegister.mapWithRoads.id > 0) {
          return 'View';
        }
      } else {
        if (this.selectedParcelRegister.mapWithNoRoads && this.selectedParcelRegister.mapWithNoRoads.id && this.selectedParcelRegister.mapWithNoRoads.id > 0) {
          return 'View';
        }
      }
    }
    return 'Order';
  }

  getSelectedInstrumentsIds(): number[] {
    return this.selectedInstrumentsIndexes.map(index => this.instrumentArray[ index ].id);
  }

  mapSelectedInstrumentsIdsToIndexes(selectedInstrumentsIds: number[]) {
    this.selectedInstrumentsIndexes = [];

    selectedInstrumentsIds.forEach(id => {
      let index = this.instrumentArray.findIndex(inst => inst.id == id);
      if (index > -1) {
        this.selectedInstrumentsIndexes.push(index);
      }
    });
  }

  fillInstrumentTable(refreshSelectedInstrumentsIndexes?: boolean): void {

    let selectedInstrumentsIds: number[] = [];
    // "Show Deleted Instruments" check box may cause the change  of content of instrumentArray,
    // thus changes the order of items, then the existing selectedInstrumentsIndexes become invalid.
    // Save the ids of the selected items
    if (refreshSelectedInstrumentsIndexes) {
      selectedInstrumentsIds = this.getSelectedInstrumentsIds();
    }

    let allDocuments: TeranetDocument[] = this.matter.teranetDocket.getAllDocuments();
    this.instrumentArray = [];
    if (this.selectedParcelRegister) {
      for (let i = 0; i < this.selectedParcelRegister.instruments.length; i++) {
        let inst: Instrument = this.selectedParcelRegister.instruments[ i ];

        //If showDeleted true then include all type of instruments else filter out the deleted ones
        if (this.selectedParcelRegister.showDeletedInstruments || inst.instrumentStatus !== 'D') {
          let instrumentWrapper: InstrumentWrapper = new InstrumentWrapper();
          instrumentWrapper.instrument = inst;
          instrumentWrapper.primaryDocument = this.matter.teranetDocket.getPrimaryDocumentForInstrument(inst, allDocuments);
          instrumentWrapper.requisitionStatus = this.requisitionStatus(inst);
          instrumentWrapper.undertakingStatus = this.undertakingStatus(inst);
          instrumentWrapper.typeContext = this.getTypeContext(inst);
          instrumentWrapper.amountFormatted = Utils.formattedCurrencyValue(inst.amountAsNumber);
          instrumentWrapper.hoverText = this.getInstrumentHoverText(instrumentWrapper);
          if (this.matter.isMatterProvinceON) {
            instrumentWrapper.instrumentBurgerMenuItems = this.getInstrumentsBurgerMenuItemsTeranet(instrumentWrapper);
          } else if (this.isSpinBasedProvince()) {
            instrumentWrapper.instrumentBurgerMenuItems = this.getInstrumentsBurgerMenuItemsSpin(instrumentWrapper);
          }

          this.instrumentArray.push(instrumentWrapper);

          if (inst.attachments && inst.attachments.length) {
            for (let j = 0; j < inst.attachments.length; j++) {
              let attachmentWrapper: InstrumentWrapper = new InstrumentWrapper();
              attachmentWrapper.attachment = inst.attachments[ j ];
              //passing in allDocuments to improve performance of this method in IE11. Otherwise teranetDocket.getAllDocuments() will run for each instrument.
              attachmentWrapper.primaryDocument = this.matter.teranetDocket.getPrimaryDocument(inst.attachments[ j ].documentNumber, allDocuments);
              attachmentWrapper.attachmentInstrumentNumber = inst.instrumentNumber;
              if (j === inst.attachments.length - 1) {
                attachmentWrapper.last = true;
              }
              attachmentWrapper.instrumentBurgerMenuItems = this.getInstrumentsBurgerMenuItemsTeranet(attachmentWrapper);
              this.instrumentArray.push(attachmentWrapper);

            }
          }
        }
      }

    }
    //Map IDs to indexes
    if (refreshSelectedInstrumentsIndexes) {
      this.mapSelectedInstrumentsIdsToIndexes(selectedInstrumentsIds);
    }
  }

  //This method fills the writs array for displaying on UI. The writs are created dynamically on frontend and persisted in backend only if user searches them.
  fillWritsArray(): void {
    this.writsCollection = new WritsCollection(this.matter.teranetDocket);
    if (this.selectedParcelRegister) {
      this.addSearchedWrits(this.selectedParcelRegister);
      this.addOwnersFromParcelRegisterToWrits(this.selectedParcelRegister);
      this.addMatterParticipantsToWrits(this.matter.mainClientType);
      this.addMatterParticipantsToWrits('GUARANTOR');
      this.addCondoCorpToWrits();
      for (let i = 0; i < this.writsCollection.writsWrapper.length; i++) {
        this.writsCollection.writsWrapper[ i ].writBurgerMenuItems = this.loadWritBurgerMenuItems(this.writsCollection.writsWrapper[ i ].primaryWrit);
        for (let j = 0; j < this.writsCollection.writsWrapper[ i ].primaryWrit.writExecutions.length; j++) {
          this.writsCollection.writsWrapper[ i ].primaryWrit.writExecutions[ j ].writExecutionBurgerMenuItems =
            this.loadExecutionBurgerMenuItems(this.writsCollection.writsWrapper[ i ].primaryWrit.writExecutions[ j ]);
        }
      }
      //Sorting the writ list by party name
      this.writsCollection.writsWrapper.sort(function (a, b) {
        return (a.primaryWrit.partyName.toUpperCase() < b.primaryWrit.partyName.toUpperCase()) ? -1
          : (a.primaryWrit.partyName.toUpperCase() > b.primaryWrit.partyName.toUpperCase()) ? 1 : 0;
      });

      //This logic is to show the User Added Writs always at the bottom of the Writs Tables in the UI
      let userAddedWritIndex = this.writsCollection.writsWrapper.findIndex(writWrapper => writWrapper.writ.partyOrigin === 'USER_ADDED_WRIT');
      if (userAddedWritIndex >= 0) {
        let userAddedWrit = this.writsCollection.writsWrapper.splice(userAddedWritIndex, 1);
        this.writsCollection.add(userAddedWrit[ 0 ].writ);
      }
    }
  }

  addSearchedWrits(parcelRegister: ParcelRegister) {
    if (parcelRegister.searchedWrits && parcelRegister.searchedWrits.length > 0) {
      parcelRegister.searchedWrits.forEach(searchedWrit => this.writsCollection.add(searchedWrit));
    }
  }

  addOwnersFromParcelRegisterToWrits(parcelRegister: ParcelRegister) {
    if (parcelRegister.propertyOwners && parcelRegister.propertyOwners.length > 0) {
      parcelRegister.propertyOwners.forEach(owner => this.writsCollection.addOwner(owner, parcelRegister.registryOffice));
    } else {
      //If there are no owners then instrument type begins with 'TRANS', TotalFrom > $0 and Registration Date is within the last 40 years (being defined
      // as current date - 40 years), then add the PartyTo name to the listing
      this.addTransferInstrumentsPartiesToWrits(parcelRegister);
    }
  }

  addTransferInstrumentsPartiesToWrits(parcelRegister: ParcelRegister) {
    let transInstrumentsWithIn40Years: Instrument[] = parcelRegister.instruments.filter(value => value.type && value.type.startsWith('TRANS'))
    .filter(value => value.amountAsNumber && value.amountAsNumber > 0)
    .filter(value => !this.isRegistrationDateOlderThan40Years(value));

    transInstrumentsWithIn40Years.forEach(value => value.partiesTo.forEach(party => this.writsCollection.addInstrumentPartyToWrit(party, parcelRegister.isRegistryProperty)));
  }

  isRegistrationDateOlderThan40Years(instrument: Instrument) {
    const baseDate = moment().subtract('years', 40);
    return !!(instrument.instrumentDateAsDate && moment(instrument.instrumentDateAsDate).isBefore(baseDate));
  }

  instrumentDateAsDate(instrument: Instrument): String {
    return instrument.instrumentDate ? moment(instrument.instrumentDate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null;
  }

  addMatterParticipantsToWrits(mpRole: MatterParticipantRole) {
    let participants: MatterParticipant[] = this.matter.getParticipantsByRole(mpRole);
    if (participants && participants.length > 0) {
      participants.forEach(mp => this.writsCollection.addMatterParticipant(mp));
    }
  }

  //Condo corporation is a special case in which participant is added with a dummy contact initially and updated with real contact later, therefore
  // checking if contact name is empty then don't add to the writs list
  addCondoCorpToWrits() {
    let participants: MatterParticipant[] = this.matter.getParticipantsByRole('CONDO_CORPORATION');
    if (participants && participants.length > 0 && participants[ 0 ].contact.organizationName !== '') {
      participants.forEach(mp => this.writsCollection.addMatterParticipant(mp));
    }
  }

  get teranetUser(): TeranetUser {
    if (this.matterTab) {
      return this.matterTab.loggedInTeranetUser;
    }
  }

  getInstrumentsBurgerMenuItemsTeranet(instrumentWrapper: InstrumentWrapper): BurgerMenuExtendedItem[] {
    let instrumentBurgerMenuItems: BurgerMenuExtendedItem[] = [];
    if (instrumentWrapper.isAttachmentWrapper && instrumentWrapper.primaryDocument.isDocumentAvailableForView) {
      //If instrument wrapper row is for attachment do below
      this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.VIEW_ATTACHMENT_LABEL, this.viewDocumentAction);
    } else {
      //If instrument wrapper row is for instrument do below
      if (!instrumentWrapper.isAttachmentWrapper) {
        this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.COURIER_INST_LABEL, this.openCourierRequestModal);
      }

      if (instrumentWrapper.primaryDocument.isDocumentAvailableForView) {
        this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.VIEW_INST_LABEL, this.viewDocumentAction);
      } else if (instrumentWrapper.primaryDocument.isDocumentNotAvailable) {
        this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.REQUEST_INST_LABEL, this.openRequestInstrumentWrapperConfirmationModal);
      }
      if (this.isRequisitionApplicable && !instrumentWrapper.isAttachmentWrapper) {
        if (!this.requisitionForInstrument(instrumentWrapper.instrument)) {
          this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.REQUISITION_INST_LABEL, this.requisitionSelectedInstrument, true);
        } else {
          this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.EDIT_REQUISITION_LABEL, this.editInstrumentRequisition, true);
          this.addToBurgerMenu(instrumentBurgerMenuItems, TeranetInstrumentMenuActions.DELETE_REQUISITION_LABEL, this.deleteInstrumentRequisition);
        }
      }
    }
    return instrumentBurgerMenuItems;

  }

  getInstrumentsBurgerMenuItemsSpin(instrumentWrapper: InstrumentWrapper): BurgerMenuExtendedItem[] {
    if (this.matter.isMatterProvinceBC) {
      return this.fillInstrumentsBurgerMenuItemsForBC(instrumentWrapper);
    }

    let instrumentBurgerMenuItems: BurgerMenuExtendedItem[] = [];

    if (this.undertakingStatus(instrumentWrapper.instrument)) {
      this.addToBurgerMenu(instrumentBurgerMenuItems, SpinInstrumentMenuActions.EDIT, this.editUndertakingSpin);
      this.addToBurgerMenu(instrumentBurgerMenuItems, SpinInstrumentMenuActions.DELETE, this.deleteUndertakingSpin);
    } else {
      if (this.matter.isSale || this.matter.isMortgage) {
        let importMortgageMenuItem: BurgerMenuExtendedItem = this.addToBurgerMenu(instrumentBurgerMenuItems, SpinInstrumentMenuActions.IMPORT_EXISTING_MORTGAGE_LABEL);

        let instrumentBurgerSubMenuItems: BurgerMenuExtendedItem[] = [];
        this.addToBurgerMenu(instrumentBurgerSubMenuItems, SpinInstrumentMenuActions.IMPORT_EXISTING_MORTGAGE_TO_BE_DISCHARGED_LABEL, this.importExistingMortgageDischargedSpin);
        this.addToBurgerMenu(instrumentBurgerSubMenuItems, (this.matter.isMortgage ? SpinInstrumentMenuActions.IMPORT_EXISTING_MORTGAGE_TO_REMAIN_LABEL : SpinInstrumentMenuActions.IMPORT_EXISTING_MORTGAGE_TO_BE_ASSUMED_LABEL), this.importExistingMortgageRemainSpin);
        importMortgageMenuItem.items = instrumentBurgerSubMenuItems;
      }

      //Applicable to all matter types
      this.addToBurgerMenu(instrumentBurgerMenuItems, SpinInstrumentMenuActions.ADD_PERMITTED_REGISTRATION_LABEL, this.addAsPermittedRegistrationSpin);
      this.addToBurgerMenu(instrumentBurgerMenuItems, SpinInstrumentMenuActions.ADD_UNDERTAKING_LABEL, this.addAsUndertakingSpin);
    }

    return instrumentBurgerMenuItems;

  }

  createTeranetImportMortgage(instrument: Instrument, mortgageDispositionType: string): TeranetImportMortgage {
    let teranetImportMortgage = new TeranetImportMortgage();
    teranetImportMortgage.mapInstrumentData(instrument);
    teranetImportMortgage.isMortgageDispositionType = true;
    teranetImportMortgage.mortgageDispositionType = mortgageDispositionType;
    return teranetImportMortgage;
  }

  createExistingMortgageFromSpinInstrument(spinImportMortgage: TeranetImportMortgage, instrument: Instrument): void {
    let existingMortgage: Mortgage;
    if (this.matter.existingMortgages.length < this.appConfig.getMaxNumberOfMortgages()) {
      existingMortgage = this.matter.createMortgage('EXISTING');
      this.matter.existingMortgages.push(existingMortgage);
    }
    if (existingMortgage) {
      if (spinImportMortgage.mortgageDispositionType) {
        existingMortgage.mortgageDispositionType = spinImportMortgage.mortgageDispositionType;
      }
      existingMortgage.mortgageRequestNo = spinImportMortgage.registrationNo;
      if (this.matter.isMatterProvinceMB && !Utils.isNotValidDate(spinImportMortgage.registrationDate)) {
        existingMortgage.mortgageRequestDate = moment(spinImportMortgage.registrationDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
      } else if (!Utils.isNotValidDdMmYyyyDate(spinImportMortgage.registrationDate)) { //Validating date from SPIN, if it is valid, format the date to YYYY/MM/DD to fit in Unity Date Picker.
        existingMortgage.mortgageRequestDate = moment(spinImportMortgage.registrationDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
      }
      existingMortgage.teranetSuggestedMortgagees = spinImportMortgage.mortgageesText;
      existingMortgage.undertakingDirty = true;
      if (existingMortgage.isMortgageDispositionRemain()) {
        let availableMortgagePriority = this.matter.availableMortgagePriority(existingMortgage ? existingMortgage.mortgagePriority : undefined);
        if (existingMortgage.mortgagePriority == undefined) {
          existingMortgage.mortgagePriority = Math.min(...availableMortgagePriority.map(item => {
            return Number(item.value);
          }));
        }
      }
      let financingType = this.financingTypes.find(value => value.label.toUpperCase() === instrument.type.toUpperCase());
      if (financingType) {
        existingMortgage.financingType = financingType.label;
      } else {
        existingMortgage.financingType = 'Mortgage';
      }
      existingMortgage.instrumentId = instrument.id;
      this.matter.updateUndertakings(this.undertakingsConfigService);
      this.matter.updateTrustLedgerAndStatementOfAdjustment(this.mortgageSoAdjService);
      this.matter.dirty = true;
      this.fillInstrumentTable();
    }
  }

  importExistingMortgageDischargedSpin = (instrumentWrapper: InstrumentWrapper) => {
    let spinImportMortgage = this.createTeranetImportMortgage(instrumentWrapper.instrument, MortgageDispositionType.DISCHARGED);
    this.createExistingMortgageFromSpinInstrument(spinImportMortgage, instrumentWrapper.instrument);
    this.fillInstrumentTable();
  };

  importExistingMortgageRemainSpin = (instrumentWrapper: InstrumentWrapper) => {
    let spinImportMortgage = this.createTeranetImportMortgage(instrumentWrapper.instrument, this.matter.isSale ? MortgageDispositionType.ASSUMED : MortgageDispositionType.REMAIN);
    this.createExistingMortgageFromSpinInstrument(spinImportMortgage, instrumentWrapper.instrument);
    this.fillInstrumentTable();
  };

  addAsPermittedRegistrationSpin = (instrumentWrapper: InstrumentWrapper) => {
    let permittedRegistration: PermittedRegistration = new PermittedRegistration();
    permittedRegistration.permittedRegistrationValue = this.getPermittedRegistrationValue(instrumentWrapper.instrument);
    permittedRegistration.instrumentId = instrumentWrapper.instrument.id;
    this.matter.permittedRegistrations.push(permittedRegistration);
    this.matter.dirty = true;
    this.fillInstrumentTable();
  };

  private getPermittedRegistrationValue(instrument: Instrument): string {
    let description: string = instrument.description ? Utils.toMixedCase(instrument.description) + ' ' : '';
    let permittedRegistrationValue: string = description + Utils.toMixedCase(instrument.type)
      + (instrument.instrumentNumber ? ' No. ' + instrument.instrumentNumber : '')
      //+ (instrument.instrumentDate ? ', ' + instrument.instrumentDate: '')
      + (this.getInFavourOf(instrument) ? ' (' + this.getInFavourOf(instrument) + ')' : '');

    return permittedRegistrationValue.substring(0, PermittedRegistrationMaxLength);
  }

  addAsUndertakingSpin = (instrumentWrapper: InstrumentWrapper) => {

    let undertaking: MatterUndertaking = new MatterUndertaking();
    let instrument: Instrument = instrumentWrapper.instrument;

    undertaking.onCCT = true;
    undertaking.actionToBeTaken = 'to pay out sufficient funds to discharge';
    undertaking.instrumentType = Utils.toMixedCase(instrument.type);
    undertaking.description = 'to pay out sufficient funds to discharge ' + undertaking.instrumentType;
    undertaking.typeOfEncumbrance = undertakingTypes.other.key;
    undertaking.instrumentNo = instrument.instrumentNumber;
    undertaking.description += ' No. ' + undertaking.instrumentNo;
    undertaking.matterUndertakingStatus = 'OUTSTANDING';
    undertaking.followupDate = undertaking.calculateFollowupDate(this.matter);
    undertaking.inFavourOf = this.getInFavourOf(instrument);
    if (this.matter.isMatterProvinceMB && instrument.affectedByInstrumentTypeCode && instrument.affectedByInstrumentTypeCode == 'AA') { //Amending Agreement - TPR
      undertaking.inFavourOf += ', including the deletion of the Assignment No. ' + instrument.affectedByInstrumentNumber;
    }
    if (undertaking.inFavourOf) {
      undertaking.description += ' in favour of ' + undertaking.inFavourOf;
    }

    //not currently visible in UI, but may be used in future
    if (this.matter.isMatterProvinceMB && !Utils.isNotValidDate(instrument.instrumentDate)) {
      undertaking.registeredDate = moment(instrument.instrumentDate, 'YYYY/MM/DD').format('YYYY/MM/DD');
    } else if (!Utils.isNotValidDdMmYyyyDate(instrument.instrumentDate)) {
      undertaking.registeredDate = moment(instrument.instrumentDate, 'DD/MM/YYYY').format('YYYY/MM/DD');
    }

    undertaking.instrumentId = instrument.id;
    this.matter.matterUndertakings.push(undertaking);
    this.matter.dirty = true;
    this.fillInstrumentTable();

  };

  private getInFavourOf(instrument: Instrument): string {
    let mortgagees = instrument.partiesTo ? instrument.partiesTo.filter(partyTo => partyTo && partyTo.trim()) : [];
    if (mortgagees.length > 0) {
      return Utils.toMixedCase(mortgagees.map(item => item && item.toString().toUpperCase()).join(' and '), ' ', ',');
    } else {
      return '';
    }
  }

  editUndertakingSpin = (instrumentWrapper: InstrumentWrapper) => {
    let instrument: Instrument = instrumentWrapper.instrument;

    if (instrument) {
      let undertaking: MatterUndertaking = this.undertakingForInstrument(instrument);
      if (undertaking) {
        this.editUndertaking(undertaking);
      } else {
        let permittedRegistration: PermittedRegistration = this.permittedRegistrationForInstrument(instrument);
        if (permittedRegistration) {
          this.editPermittedRegistration();
        }
      }
    }

    this.fillInstrumentTable();
  };

  deleteUndertakingSpin = (instrumentWrapper: InstrumentWrapper) => {
    let instrument: Instrument = instrumentWrapper.instrument;

    if (instrument) {
      let undertaking: MatterUndertaking = this.undertakingForInstrument(instrument);
      if (undertaking) {
        let index: number = this.matter.matterUndertakings.findIndex(undertaking => undertaking.instrumentId == instrument.id);
        if (index > -1) {
          this.matter.matterUndertakings.splice(index, 1);
          this.matter.dirty = true;
        }
      } else {
        let permittedRegistration: PermittedRegistration = this.permittedRegistrationForInstrument(instrument);
        if (permittedRegistration) {
          let index: number = this.matter.permittedRegistrations.findIndex(registration => registration.instrumentId == instrument.id);
          if (index > -1) {
            this.matter.permittedRegistrations.splice(index, 1);
            this.matter.dirty = true;
          }
        }
      }
    }

    this.fillInstrumentTable();
  };

  addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], text: string, action?: any, topline?: boolean): BurgerMenuExtendedItem {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.topline = topline ? topline : false;
    burgerMenuItem.action = action;
    burgerMenuItems.push(burgerMenuItem);
    return burgerMenuItem;
  }

  getOrderMapItems(pr: ParcelRegister): string[] {

    let mapWithRoadsOrdered: boolean = pr.mapWithRoads && pr.mapWithRoads.id && pr.mapWithRoads.id > 0;
    let mapWithoutRoadsOrdered: boolean = pr.mapWithNoRoads && pr.mapWithNoRoads.id && pr.mapWithNoRoads.id > 0;

    return [
      (mapWithoutRoadsOrdered ? 'View' : 'Order') + ' map without roads and railways',
      (mapWithRoadsOrdered ? 'View' : 'Order') + ' map with roads and railways' ];
  }

  clickOrderMapBurgerMenu(pr: ParcelRegister, event): void {
    //console.log(event);
    this.orderOrViewMap(pr, !event.includes('without'));
  }

  openedOrderMapBurgerMenu(node: TreeNode) {
    this.selectedNode = node;

    this.selectedParcelRegister = node.data.parcel;

    let needsScrolling = TreeNodeUtil.needsScrolling(this.parcelNodes, node.data.parcel.id, 4, 2);

    setTimeout(() => {
      //console.log(`${nodesNumber} -> ${indexOfSelectedNode}`);
      if (needsScrolling) {
        // scroll down to display the burger menu
        let scrl = jQuery('dp-property-teranet-main .leftSidePanel')[ 0 ].scrollHeight + jQuery('dp-property-teranet-main .leftSidePanel dp-burger-menu .burger-action')[ 0 ].scrollHeight;
        //console.log('scroll is' + scrl);
        jQuery('dp-property-teranet-main .leftSidePanel').scrollTop(scrl);
      }

    }, 300);
  }

  clickInstrumentsBurgerMenu(instrumentWrapper: InstrumentWrapper, clickedMenuOption: BurgerMenuExtendedItem): void {
    this.resetAllActionButtons();
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(instrumentWrapper);
    }

  }

  requestInstrument(instrument: Instrument): void {
    this.callTeranetOperation(() => {
      let instrumentRequest: TeranetRequestInstrumentRequest = new TeranetRequestInstrumentRequest();
      instrumentRequest.instrumentId = instrument.id;
      instrumentRequest.teranetUser = this.teranetUser;
      if (instrument.parcelRegisterId) {
        instrumentRequest.parcelRegisterId = instrument.parcelRegisterId;
      } else {
        instrumentRequest.parcelRegisterId = this.selectedParcelRegister.id;
      }

      this.teranetService.requestInstrument(instrumentRequest).subscribe(
        (response: TeranetRequestInstrumentResponse) => {
          if (response.submissionState == TeranetRequestInstrumentSubmissionState.INSTRUMENT_FOUND) {
            this.updateParcelRegisterInMatter(new ParcelRegister(response.parcelRegister));
            this.fillInstrumentTable();

          } else if (response.isSuccess()) {
            this.updateParcelRegisterInMatter(new ParcelRegister(response.parcelRegister));
            this.openInstrumentRequestModal(this.teranetUser, response, instrument);
          } else { //error response, don't need to update parcel register
            this.openInstrumentRequestModal(this.teranetUser, response, instrument);
          }
        },
        error => {
          //TODO error handling
        }
      );
    });
  }

  openCourierRequestModal = (instrumentWrapper: InstrumentWrapper) => {
    this.callTeranetOperation(() => {
      this.dialogService.matDialogContent({
        content: CourierInstrumentModal,
        context: {
          matter: this.matter,
          teranetUser: this.teranetUser,
          parcelRegister: this.selectedParcelRegister,
          instrumentId: instrumentWrapper.instrument.id
        },
        onFulfillment: (response: any) => {
          if (response) {
            this.dialogService.confirm('Teranet Connect &trade;: Request Courier Delivery of Instruments', 'Courier request successfully sent.', true, 'OK');
            this.updateParcelRegisterInMatter(response.parcelRegister);
            this.fillInstrumentTable();
          }
        }
      });
    });
  };

  updateParcelRegisterInMatter(parcelRegister: ParcelRegister) {
    let parcelRegisterIndex: number = this.parcelRegisters.findIndex(item => item.id == parcelRegister.id);
    //console.log("parcelRegisterIndex" + parcelRegisterIndex );
    if (parcelRegisterIndex > -1) {
      this.parcelRegisters.splice(parcelRegisterIndex, 1, parcelRegister);
      this.selectedParcelRegister = parcelRegister;
      this.updateParcelTreeTable();
    }
    //let parcelRegisterIndex: number = currentMatter.value.teranetDocket.parcelRegisters.findIndex(item => item.id==parcelRegister.id);
    //currentMatter.value.teranetDocket.parcelRegisters.splice(parcelRegisterIndex,1,parcelRegister);
  }

  isOwnerSelected(owner: TeranetPropertyOwner): boolean {
    return false;//ToDo
  }

  handleOwnerRowSelect(owner: TeranetPropertyOwner, event): void {
    //ToDo
  }

  toggleNameRowSelect(index: number): void {
    if (this.selectedNameIndexes.indexOf(index) !== -1 || this.isWritCertificateAvailable(index)) {
      (<any>this.selectedNameIndexes).remove(index);
    } else {
      this.selectedNameIndexes.push(index);
    }
    this.resetAllActionButtons();
    this.closeDropDownMenu();
  }

  writLinkClick(): void {
    this.navBottomSelected = 'Writs';
    //Setting focus on first row
    setTimeout(() => {
      jQuery('#writ_row_0').focus();
    }, 333);
  }

  toggleExecutionRowSelect(writExecution: WritExecution): void {
    let selectedWritExecutionIndex = this.selectedWritExecutions.findIndex(item => item.writExecutionId == writExecution.id);
    if (selectedWritExecutionIndex !== -1 || writExecution.executionCertificate) {
      (<any>this.selectedWritExecutions).remove(this.selectedWritExecutions[ selectedWritExecutionIndex ]);

    } else {
      this.selectedWritExecutions.push(this.getSelectedWritExecutionInfo(writExecution));
    }
  }

  getSelectedWritExecutionInfo(writExecution: WritExecution): WritExecutionInfo {
    return new WritExecutionInfo(writExecution.id, writExecution.enforcementOffice, this.getEnforcementOfficeNumberByName(writExecution.enforcementOffice));
  }

  getEnforcementOfficeNumberByName(selectedEnforcementOfficeName: string): string {
    let enforcementOffice: EnforcementOffice = this.enforcementOffices.find(enforcementOffice => enforcementOffice.enforcementOfficeName == selectedEnforcementOfficeName);
    if (enforcementOffice) {
      return enforcementOffice.enforcementOfficeNumber;
    }
    return '';
  }

  isExecutionRowSelected(writExecution: WritExecution): boolean {
    let selectedWritExecutionIndex: number = this.selectedWritExecutions.findIndex(writExecutionIfo => writExecutionIfo.writExecutionId == writExecution.id);
    return selectedWritExecutionIndex !== -1 && !writExecution.executionCertificate;
  }

  isNameRowSelected(index: number): boolean {
    return this.selectedNameIndexes.indexOf(index) !== -1 && !this.isWritCertificateAvailable(index);
  }

  isWritCertificateAvailable(index): boolean {
    return this.writsCollection.writsWrapper[ index ].primaryWrit.writCertificate ? true : false;
  }

  isUserAddedWrit(writ: Writ): boolean {
    return writ && writ.writPartyData && writ.writPartyData.partyOrigin === 'USER_ADDED_WRIT';
  }

  isInstrumentSelected(indexOnPage: number, pageEvent: PageEvent): boolean {
    let index = this.getIndexBasedOnPage(pageEvent, indexOnPage);
    return this.selectedInstrumentsIndexes.indexOf(index) != -1;
  }

  toggleInstrumentRowSelect(indexOnPage: number, pageEvent: PageEvent): void {
    let index = this.getIndexBasedOnPage(pageEvent, indexOnPage);
    if (this.isInstrumentAvailable(index)) {
      if (this.selectedInstrumentsIndexes.indexOf(index) != -1) {
        this.removeSelectedInstrument(index);
        this.selectAllInstruments = false;
      } else {
        this.addSelectedInstrument(index);
      }
    }
    this.resetAllActionButtons();
    this.closeDropDownMenu();
  }

  //calculate true index in the array based on current page (otherwise first item on each page will always have index 0)
  private getIndexBasedOnPage(pageEvent: PageEvent, indexOnPage: number) {
    let index: number = (pageEvent.activePage - 1) * pageEvent.rowsOnPage + indexOnPage;
    return index;
  }

  addSelectedInstrument(index): void {
    if (this.isInstrumentAvailable(index)) {
      if (!this.instrumentArray[ index ].isAttachmentWrapper) //parent instrument
      {
        this.selectParentInstrument(index);
      } else {
        this.selectInstrument(index);
        if (!this.isParentInstrumentSelected(index)) {
          this.showInstrumentWarningModal(index);
        }

      }

    }

  }

  selectParentInstrument(index): void {
    if (this.selectedInstrumentsIndexes.indexOf(index) == -1) {
      this.selectInstrument(index);
    }

    //Push all the attachement
    for (let i = index + 1; i < this.instrumentArray.length; i++) {
      if (this.instrumentArray[ i ].isAttachmentWrapper) {
        this.selectInstrument(i);

      } else {
        break;
      }
    }
  }

  isParentInstrumentSelected(attachemntIndex: number): boolean {
    let parentIndex = this.getParentInstrumentIndex(attachemntIndex);
    return this.selectedInstrumentsIndexes.indexOf(parentIndex) != -1;
  }

  getParentInstrumentIndex(attachmentIndex: number): number {
    for (let i = attachmentIndex - 1; i >= 0; i--) {
      if (!this.instrumentArray[ i ].isAttachmentWrapper) {
        return i;
      }
    }
  }

  removeSelectedInstrument(index): void {
    if (!this.instrumentArray[ index ].isAttachmentWrapper) //parent instrument
    {
      (<any>this.selectedInstrumentsIndexes).remove(index);
      //remove all the attachement
      for (let i = index + 1; i < this.instrumentArray.length; i++) {
        if (this.instrumentArray[ i ].isAttachmentWrapper) {
          (<any>this.selectedInstrumentsIndexes).remove(i);
        } else {
          break;
        }
      }
      this.selectAllInstruments = false;
    } else {
      (<any>this.selectedInstrumentsIndexes).remove(index);
      //check if parent is selected
      if (this.isParentInstrumentSelected(index)) {
        this.showInstrumentWarningModal(index);

      }

    }

  }

  openPinDetails(event): void {

    this.selectedInstrumentsIndexes = [];
    this.selectedNameIndexes = [];
    this.selectedWritExecutions = [];

    if (event.node.data.parcel) {
      this.updatePinDetails(event.node.data.parcel);
    } else {
      this.selectedParcelRegister = undefined;
      this.instrumentArray = [];
      this.writsCollection.writsWrapper = [];
      this.importAvailable = false;
      this.displayWrits = false;
    }
  }

  updatePinDetails(parcel: ParcelRegister) {
    this.selectedParcelRegister = parcel;
    this.fillInstrumentTable();
    this.fillWritsArray();
    this.loadEnforcementOffices();
    this.importAvailable = true;
    this.displayWrits = true;
  }

  get headerMessage(): string {
    if (this.selectedParcelRegister) {

      let createdTimeStamp = '';
      if (this.selectedParcelRegister.parcelCreatedTimestamp) {
        createdTimeStamp = `, ${ this.selectedParcelRegister.parcelCreatedTimestamp.toLowerCase() }`;
      }

      let regOffice: string = '';
      if (this.selectedParcelRegister.registryOffice) {
        regOffice = ` ${ this.selectedParcelRegister.registryOffice }`;
      }
      if (this.matter && this.matter.isMatterProvinceAB) {
        return `Certificate of Title No. ${ this.selectedParcelRegister.titleNumber } on ${ this.selectedParcelRegister.certificateDate }`;
      } else if (this.matter && this.matter.isMatterProvinceSK) {
        return `Title No. ${ this.selectedParcelRegister.titleNumber } as of ${ this.selectedParcelRegister.parcelCreatedTimestamp }`;
      } else if (this.matter && this.matter.isMatterProvinceMB) {
        return `Title No. ${ this.selectedParcelRegister.titleNumber } as of ${ moment(this.selectedParcelRegister.certificateDate).format('YYYY-MM-DD') } at ${ moment(this.selectedParcelRegister.certificateDate).format('HH:mm') }`;
      } else if (this.matter?.isMatterProvinceBC) {
        return `Title Number ${ this.selectedParcelRegister.titleNumber } on ${ moment(this.selectedParcelRegister.certificateDate).format('YYYY-MM-DD, HH:mm:ss') }`;
      } else {
        return `Parcel Register for PIN ${ this.selectedParcelRegister.pin }, LRO ${ this.selectedParcelRegister.lro }${ regOffice }${ createdTimeStamp }`;
      }
    } else {
      return '';
    }
  }

  openOrderInstrument(): void {
    this.callTeranetOperation(() => {
      this.openOrderInstrumentModal();
    });
  }

  get isMatterEditingDisabledAfterPostClosingDate(): boolean {
    return MatterUtil.isMatterEditingDisabledAfterPostClosingDate(this.matter);
  }

  checkStatusOfInstruments(): void {
    this.callTeranetOperation(() => {
      this.teranetService.checkStatusInstruments(this.selectedParcelRegister.id, this.teranetUser).subscribe(
        (parcelRegister: ParcelRegister) => {
          this.updateParcelRegisterInMatter(parcelRegister);
          this.fillInstrumentTable();
        },
        error => {
          //TODO error handling
        }
      );
    });
  }

  openOrderInstrumentModal(): void {
    let selectedInstumentCount = this.selectedInstrumentsIndexes.length;
    let instrumentTxt = selectedInstumentCount > 1 ? 'instruments' : 'instrument';
    this.dialogService.confirm('Teranet Connect &trade;: Order Instrument', 'Proceed to order ' + selectedInstumentCount + ' selected ' + instrumentTxt + '?', false, 'Proceed').subscribe(res => {
      if (res) {
        let teranetOrderInstruemntRequest: TeranetOrderInstrumentRequest = this.prepareOrderInstrumentRequest();
        this.orderInstrument(teranetOrderInstruemntRequest);

      }
    });
  }

  orderInstrument(teranetOrderInstruemntRequest: TeranetOrderInstrumentRequest): void {
    if (this.selectedInstrumentsIndexes.length) {

      this.teranetService.orderInstrument(teranetOrderInstruemntRequest).subscribe(
        (res: TeranetOrderInstrumentResponse) => {
          this.selectedInstrumentsIndexes = [];
          if (res && res.successfulOrders && res.successfulOrders.length > 0) {
            console.log(res);
            setTimeout(() => {
              res.successfulOrders.forEach(document => {
                this.matter.teranetDocket.replaceUpdatedDocument(document);
              });
              this.fillInstrumentTable();
              if (teranetOrderInstruemntRequest.analysisStillNeeded) {
                this.viewAdjacentParcelAnalysis();
              }
            }, 100);
          }
          if (res.failedOrders && res.failedOrders.length) {
            let message = '';
            res.failedOrders.forEach(str => {
              message += str + '<br>';
            });
            this.dialogService.confirm('Teranet Connect &trade;: Order Instrument Response', message, true, 'OK');

          }
        }
      );
    }

  }

  prepareOrderInstrumentRequest(): TeranetOrderInstrumentRequest {
    let selectedInstArray: TeranetOrderDocument[] = [];
    for (let i = 0; i < this.selectedInstrumentsIndexes.length; i++) {
      let selectedInstrument: InstrumentWrapper = this.instrumentArray[ this.selectedInstrumentsIndexes[ i ] ];

      let orderedDocuments = new TeranetOrderDocument();
      if (selectedInstrument.isInstrumentWrapper) {
        let primaryDocument: TeranetDocument = this.matter.teranetDocket.getPrimaryDocument(selectedInstrument.instrument.instrumentDocument.documentNumber);
        orderedDocuments.documentId = primaryDocument.id;
        orderedDocuments.instrumentNumber = selectedInstrument.instrumentNumber;
        orderedDocuments.documentType = 'INSTRUMENT';
      } else {
        let primaryDocument: TeranetDocument = this.matter.teranetDocket.getPrimaryDocument(selectedInstrument.attachment.documentNumber);
        orderedDocuments.documentId = primaryDocument.id;
        orderedDocuments.instrumentNumber = selectedInstrument.attachmentInstrumentNumber;
        orderedDocuments.documentType = 'ATTACHMENT';
      }
      selectedInstArray.push(orderedDocuments);

    }
    let teranetOrderInstruemntRequest: TeranetOrderInstrumentRequest = new TeranetOrderInstrumentRequest();
    teranetOrderInstruemntRequest.orderedDocuments = selectedInstArray;
    teranetOrderInstruemntRequest.teranetUser = this.matterTab.loggedInTeranetUser;
    teranetOrderInstruemntRequest.docketSystemId = this.matter.teranetDocket.docketSystemId;
    teranetOrderInstruemntRequest.lro = this.selectedParcelRegister.lro;
    console.log(teranetOrderInstruemntRequest);
    return teranetOrderInstruemntRequest;
  }

  viewInstrument(instrument: InstrumentWrapper): void {
    let documentId: number = instrument.primaryDocument.id;
    this.teranetService.downloadTeranetDocumentAndDisplay(currentMatter.value.id, documentId);
  }

  viewDocumentAction = (instrument: InstrumentWrapper): void => {
    let documentId: number = instrument.teranetDocumentId;
    this.teranetService.downloadTeranetDocumentAndDisplay(currentMatter.value.id, documentId);
  };

  openRequestInstrumentWrapperConfirmationModal = (instrumentWrapper: InstrumentWrapper): void => {
    let instrument: Instrument = instrumentWrapper.instrument;
    this.openRequestInstrumentConfirmationModal(instrument);
  };

  openRequestInstrumentConfirmationModal(instrument: Instrument): void {

    if (instrument.requestedDate) {
      const formattedDate = this.dateFormatPipe.transform(instrument.requestedDate, []);
      this.dialogService.confirm(messages.teranet.previouslyRequested.title, messages.teranet.previouslyRequested.text.replace('DATE_PLACEHOLDER', formattedDate),
        false, 'Proceed').subscribe(res => {
        if (res) {
          this.requestInstrument(instrument);
        }
      });
    } else {
      this.dialogService.confirm(messages.teranet.requestInstrument.title, messages.teranet.requestInstrument.text, false, 'Proceed').subscribe(res => {
        if (res) {
          this.requestInstrument(instrument);
        }
      });
    }
  }

  //In case of clicking an attachment
  showInstrumentWarningModal(index: number): void {
    this.dialogService.confirm(messages.teranet.downloadAll.title, messages.teranet.downloadAll.text, false, 'Select All').subscribe(res => {
      if (res) {
        //select all
        let parentInstrumentIndex: number = this.getParentInstrumentIndex(index);
        this.selectParentInstrument(parentInstrumentIndex);
      }
    });
  }

  toggleSelectAllInstruments(): void {
    if (this.parcelRegisters.length) {
      this.selectedInstrumentsIndexes = [];
      if (!this.selectAllInstruments) {
        for (let i = 0; i < this.instrumentArray.length; i++) {
          this.selectInstrument(i);
        }
      }
      this.selectAllInstruments = !this.selectAllInstruments;
    }

  }

  get isRequisitionApplicable(): boolean {

    let isRequisitionApplicableForCurrentMatter = this.matter && (this.matter.isPurchase || (this.matter.isMortgage && this.matter.actingFor === 'MORTGAGEE') || this.matter.isMatterProvinceMB);
    return this.selectedParcelRegister ?
      (isRequisitionApplicableForCurrentMatter && !this.selectedParcelRegister.isAdjacentParcel) :
      isRequisitionApplicableForCurrentMatter;
  }

  selectInstrument(index): void {
    if (this.isInstrumentAvailable(index)) {
      if (this.selectedInstrumentsIndexes.indexOf(index) == -1) {
        this.selectedInstrumentsIndexes.push(index);
      }
    }

  }

  isInstrumentAvailable(index): boolean {
    return this.instrumentArray[ index ].primaryDocument.documentStatus === 'AVAILABLE_FOR_DOWNLOAD';
  }

  orderOrViewMapOnSelectedParcel(withRoads: boolean) {
    this.orderOrViewMap(this.selectedParcelRegister, withRoads);
  }

  orderOrViewMap(pr: ParcelRegister, withRoads: boolean) {

    if (withRoads && pr.mapWithRoads && pr.mapWithRoads.id) {
      this.viewTeranetMap(withRoads, pr.mapWithRoads.id, pr);
    } else if (!withRoads && pr.mapWithNoRoads && pr.mapWithNoRoads.id) {
      this.viewTeranetMap(withRoads, pr.mapWithNoRoads.id, pr);
    } else {
      this.callTeranetOperation(() => {
        this.orderTeranetMapModal(pr, withRoads);
      });
    }
  }

  viewTeranetMap(withRoads: boolean, mapId: number, pr: ParcelRegister) {
    this.dialogService.matDialogContent({
      content: ViewMapModalComponent,
      context: {
        matterId: this.matter.id,
        parcelId: pr.id,
        mapId: mapId,
        pin: pr.pin,
        creationDate: pr.creationDate,
        withRoads: withRoads,
        adjacentParcels: pr.adjacentParcels
      },
      onFulfillment: (result: any) => {
        if (result && result.action == 'SEARCH_PARCELS' && result.selectedParcels && result.selectedParcels.length > 0) {

          this.callTeranetOperation(() => {
              this.lockScreenService.lockForUpdate = true;
              this.searchAdjacentParcels(pr.id, result.selectedParcels)
              .subscribe(
                pinValidationResults => {
                  this.purchaseAdjacentParcelRegister(pr.id, pinValidationResults, result.selectedParcels);
                },
                (error: ApplicationError) => {
                  this.handleTeranetMapPurchaseError(error);
                  this.lockScreenService.lockForUpdate = false;
                }
              );
            }
          );

        }
      }
    });
  }

  // basically validating PINs and looking for their addresses
  searchAdjacentParcels(parentParcelId: number, selectedParcels: ParcelRegister[]): any {

    let adjParcelSearches: any = [];
    selectedParcels.forEach(sp => {
      adjParcelSearches.push(this.teranetService.searchParcelRegister(this.createParcelRegisterSearchRequestFromAdjacentParcel(sp), true));
    });

    return Observable.forkJoin(adjParcelSearches);
  }

  public createParcelRegisterSearchRequestFromAdjacentParcel(pr: ParcelRegister): TeranetParcelRegisterSearchRequest {
    let req: TeranetParcelRegisterSearchRequest = new TeranetParcelRegisterSearchRequest();
    req.matterId = this.matter.id; // needed for authorization access on API
    req.pin = pr.pin;
    req.lroNumber = pr.lro;
    req.searchType = 'SEARCH_BY_PIN';
    req.streetName = '';
    req.teranetUser = this.teranetUser;
    //req.selectedLro -> not needed ??!?
    return req;
  }

  async purchaseAdjacentParcelRegister(parentParcelId: number, validatedPinResponse: TeranetParcelRegisterSearchResponse[], selectedParcels: ParcelRegister[]): Promise<void> {

    //let prPurchaseReqs : any = [];
    let purchaseRequests: TeranetParcelRegisterPurchaseRequest[] = [];
    validatedPinResponse.forEach(vpr => {
      vpr.searchedProperties.forEach(sp => {
        let selParcel = selectedParcels.find(selParcel => selParcel.pin == sp.pin);
        if (selParcel != null) {
          purchaseRequests.push(this.createAdjacentPurchaseRequestFromSearchResponse(selParcel.id, vpr.lroNumber, sp));
        }
      });
    });
    if (purchaseRequests && purchaseRequests.length) {
      let dialogRef = this.showWaitMessgePopup();
      for (const tprpr of purchaseRequests) {
        try {
          let prcl: ParcelRegister = await this.teranetService.purchaseParcelRegister(tprpr, true).toPromise();
          let parcelRegister: ParcelRegister = new ParcelRegister(prcl);
          //console.log(parcelRegister);
          parcelRegister.isAdjacentParcel = true;
          parcelRegister.showDeletedInstruments = true;
          this.matter.updateAdjacentParcelRegister(parentParcelId, parcelRegister); // returned adjacent parcel should have now more data
          this.updateParcelTreeTable();
        } catch (error) {
          this.handleTeranetMapPurchaseError(error);
        }
      }
      dialogRef.close();
    }
    this.lockScreenService.lockForUpdate = false;
  }

  showWaitMessgePopup(): MatDialogRef<any> {
    let dialogRef: MatDialogRef<any> = this.dialogService.confirmDialog(messages.teranet.purchaseParcelWaitMessage.title, messages.teranet.purchaseParcelWaitMessage.text, true, null, null, false, true);
    return dialogRef;
  }

  createAdjacentPurchaseRequestFromSearchResponse(adjParcelRegisterId: number, lroNumber: string, teranetPinInfo: TeranetPinInfo): TeranetParcelRegisterPurchaseRequest {
    let purchaseRequest: TeranetParcelRegisterPurchaseRequest = new TeranetParcelRegisterPurchaseRequest();
    purchaseRequest.docketSystemId = this.matter.teranetDocket.docketSystemId;
    purchaseRequest.matterDocketId = this.matter.teranetDocket.matterDocketId;
    purchaseRequest.lroNumber = lroNumber;
    purchaseRequest.propertyPin = teranetPinInfo.pin;
    purchaseRequest.includeDeletedInstruments = true;
    purchaseRequest.applyAdjacentPropDiscount = true;
    purchaseRequest.teranetUser = this.teranetUser;
    purchaseRequest.address = teranetPinInfo.fullAddress;
    purchaseRequest.parcelRegisterId = adjParcelRegisterId;
    purchaseRequest.allowDuplicates = true; //no duplicate checking needed for adjacent parcels
    return purchaseRequest;
  }

  orderTeranetMapModal(pr: ParcelRegister, withRoads: boolean) {

    let withOrWithoutU2 = 'with' + (withRoads ? '' : 'out');
    let title: string = 'Teranet Connect &trade;: Order Map';
    let msg: string = `Do you wish to order the map (${ withOrWithoutU2 } roads and railways) for PIN ${ pr.pin }`;

    this.dialogService.confirm(title, msg, false, 'Proceed').subscribe(res => {
      if (res && res == true) {

        this.errorService.clearAllSaveErrors();

        let order = new TeranetOrderMapRequest();
        order.parcelRegisterId = pr.id;
        order.pin = pr.pin;
        order.lro = pr.lro;
        order.includeRailRoadsInd = withRoads;
        order.includeRoadsInd = withRoads;
        order.docketSystemId = this.matter.teranetDocket.docketSystemId;
        order.teranetUser = this.matterTab.loggedInTeranetUser;

        this.orderMapSubcription = this.teranetService.orderMap(order).subscribe(
          (rslt: TeranetOrderMapResponse) => {
            if (rslt && rslt.parcelRegister) {
              //console.log(rslt);
              let returnedParcelRegister = new ParcelRegister(rslt.parcelRegister);
              let idx = this.matter.teranetDocket.parcelRegisters.findIndex(p => p.id == rslt.parcelRegister.id);
              if (idx > -1) {
                this.matter.teranetDocket.parcelRegisters[ idx ] = returnedParcelRegister;
              } else {
                this.matter.teranetDocket.parcelRegisters.push(returnedParcelRegister);
              }
              this.updateParcelTreeTable();

              // view map
              let mapId = withRoads ? returnedParcelRegister.mapWithRoads.id : returnedParcelRegister.mapWithNoRoads.id;
              this.viewTeranetMap(withRoads, mapId, returnedParcelRegister);
            }
          },
          (error: ApplicationError) => {
            this.handleTeranetMapPurchaseError(error);
          }
        );

      } else {
        console.log('Map purchase cancelled.');
      }
    });

  }

  rateSheet(): void {
    window.open(teranetConnectLinks.fees, '_blank');
  }

  handleTeranetMapPurchaseError(error: ApplicationError) {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {

        this.errorService.addDpSaveError(DPError.createCustomDPError(fieldError.errorCode, this.formatErrorMessage(fieldError.errorCode, fieldError.message), null, 'ERROR'));
      });
    } else {
      this.errorService.addDpSaveError(DPError.createCustomDPError(error.errorCode, this.formatErrorMessage(error.errorCode, error.message), null, 'ERROR'));
    }
  }

  formatErrorMessage(errCode: string, errMessage): string {
    if (errCode && errCode === '-120000') {
      return `${ errCode }: In order to search adjacent parcels, you must use the original user that purchased the parcel
                                      and order the map within 24 hours of the parcel purchase.`;
    }
    return `${ errMessage }`;
  }

  toggleShowDeletedInstrument(): void {
    this.selectedParcelRegister.showDeletedInstruments = !this.selectedParcelRegister.showDeletedInstruments;
    this.fillInstrumentTable();
  }

  addNewInstrument(): void {
    this.callTeranetOperation(() => {
      this.openAddInstrumentModal();
    });
  }

  openAddInstrumentModal = () => {
    this.dialogService.matDialogContent({
      content: TeranetAddInstrumentModal,
      context: {
        teranetUser: this.teranetUser,
        selectedParcelRegister: this.selectedParcelRegister
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK' && this.matter && this.matter.teranetDocket) {
          let updateSelectedParcelRegister: boolean = false;
          this.matter.teranetDocket.parcelRegisters = this.parcelRegisters.map(pr => {
            if (pr.adjacentParcels.some(adp => adp.id == this.selectedParcelRegister.id)) {
              pr.adjacentParcels = pr.adjacentParcels.map(adp => adp.id !== this.selectedParcelRegister.id ? adp : result.updatedParcelRegister);
              updateSelectedParcelRegister = true;
              return pr;
            } else {
              if (pr.id !== this.selectedParcelRegister.id) {
                return pr;
              } else {
                updateSelectedParcelRegister = true;
                return result.updatedParcelRegister;
              }
            }
          });

          if (updateSelectedParcelRegister) {
            this.selectedParcelRegister = result.updatedParcelRegister;
          }
          this.fillInstrumentTable();
          this.updateParcelTreeTable();
        }
      }
    });
  };

  //Open Requisitions Section
  addOffTitleRequistion = () => {
    let matterTab = (this.tabsStateService.activeTab as MatterTab);
    let matterComponent = matterTab.matterComponent;
    let section: Section = matterComponent.applicableSections.find(item => item.route == 'requisitions');
    matterComponent.openMatterSection('requisitions', section);
  };

  requisitionSelectedInstrument = (instrumentWrapper: InstrumentWrapper) => {

    let requisitionInstrumentConfigs: RequisitionInstrumentConfig[] = this.requisitionsService.getCachedConfigs();

    let instrumentFromRemarks: Instrument = null;
    if (instrumentWrapper.instrument.type == 'TRANSFER OF CHARGE' && instrumentWrapper.instrument.remarks.length > 0) {
      const instrumentNumberFromRemarks = instrumentWrapper.instrument.remarks[ 0 ];
      instrumentFromRemarks = this.selectedParcelRegister.instruments.find(instrument => instrument.instrumentNumber == instrumentNumberFromRemarks);
    } else if (instrumentWrapper.instrument.type == 'POSTPONEMENT' && instrumentWrapper.instrument.remarks.length > 0) {
      const instrumentNumberFromRemarks: string = instrumentWrapper.instrument.remarks[ 0 ].split(' ')[ 0 ];
      instrumentFromRemarks = this.selectedParcelRegister.instruments.find(instrument => instrument.instrumentNumber == instrumentNumberFromRemarks);
    }

    this.dialogService.matDialogContent({
      content: RequisitionsAddModal,
      context: {
        isCategoryVisible: true,
        instrument: instrumentWrapper.instrument,
        configs: requisitionInstrumentConfigs
      },
      onFulfillment: (result) => {
        if (result) {
          this.dialogService.matDialogContent({
            content: RequisitionsEditorModal,
            context: {
              selectedTemplate: result.selectedTemplate,
              action: 'NEW',
              isCategoryVisible: false,
              instrument: instrumentWrapper.instrument,
              relatedInstrument: instrumentFromRemarks,
              configs: requisitionInstrumentConfigs
            },
            onFulfillment: (result) => {
              if (result && result.action === RequisitionsEditorModalResult.ADD_COMPLETED) {
                this.fillInstrumentTable(); //needed to refresh menu instrument burger
                                            // menu options
                this.requisitionsService.onRequisitionStatusChange(this.matter.matterRequisitions[ this.matter.matterRequisitions.length - 1 ]);
              }
            }
          });
        }
      }
    });
  };

  editInstrumentRequisition = (instrumentWrapper: InstrumentWrapper) => {
    let requisition: RequisitionTemplate = this.requisitionForInstrument(instrumentWrapper.instrument);
    let requisitionInstrumentConfigs: RequisitionInstrumentConfig[] = this.requisitionsService.getCachedConfigs();

    const oldRequisitionStatus: string = requisition.requisitionStatus;
    const oldDataHash: string = requisition.dataHash;

    this.dialogService.matDialogContent({
      content: RequisitionsEditorModal,
      context: {
        selectedTemplate: requisition,
        action: 'EDIT',
        isCategoryVisible: false,
        instrument: instrumentWrapper.instrument,
        configs: requisitionInstrumentConfigs
      },
      onFulfillment: (result) => {
        this.requisitionsService.onRequisitionEdit(oldRequisitionStatus, oldDataHash, result, requisition);
      }
    });
  };

  deleteInstrumentRequisition = (instrumentWrapper: InstrumentWrapper) => {
    let requisition: RequisitionTemplate = this.requisitionForInstrument(instrumentWrapper.instrument);

    if (requisition) {
      this.dialogService.confirm('Confirmation', messages.requisition.deleteConfirmation, false, 'Delete').subscribe(res => {
        if (res == true) {

          //remove requisition from matter
          this.requisitionsService.deleteSelectedRequisition(requisition);
          this.fillInstrumentTable(); //needed to refresh menu instrument burger menu options
        }
      });
    }
  };

  editPermittedRegistration(): void {
    this.dialogService.matDialogContent({
      content: PermittedRegistrationModalComponent,
      context: {
        permittedRegistrations: this.matter.permittedRegistrations,
        existingMortgages: this.matter.existingMortgages
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK') {
          this.matter.permittedRegistrations = result.permittedRegistrationsLocal;
          this.matter.dirty = true;
        }
      },

    });

  };

  editUndertaking(undertaking: MatterUndertaking): void {
    this.dialogService.matDialogContent({
      content: UndertakingsDetailModal,
      context: <UndertakingsDetailModalContext>{
        undertaking: undertaking,
        matter: this.matter,
        action: 'EDIT',
        subject: undertaking.getOnCCTSubject(),
        documentProfileCache: this.documentProfileCache
      },
      onFulfillment: (result) => {

        if (result.action === 'Delete') {
          this.matter.deleteUndertaking(undertaking);
          this.fillInstrumentTable();
          this.matter.dirty = true;
        } else if (result.action === 'OK') {
          undertaking.copy(result.undertaking);
          this.matter.dirty = true;
        }
      },

    });

  };

  getPartyOriginForDisplay(writ: Writ) {
    let labelObj = matterLabels.writPartyOriginKeyLabelMap.find(obj => obj.value === writ.partyOrigin);
    return labelObj ? labelObj.label : '';
  }

  addNameWritSearch(): void {
    this.callTeranetOperation(() => {
      this.openNameWritSearch(this.prepareAddNameWritSearchRequest());
    });
  }

  prepareAddNameWritSearchRequest(): TeranetWritSearchRequest {
    let writSearchRequest: TeranetWritSearchRequest = this.prepareBaseWritSearchRequest('ADD_NAME');
    let blankNameParty: WritPartyData = new WritPartyData();
    blankNameParty.partyType = 'Person';
    blankNameParty.partyOrigin = 'USER_ADDED';
    writSearchRequest.partiesToSearch.push(blankNameParty);
    return writSearchRequest;
  }

  orderNameWritSearch(): void {
    if (this.selectedNameIndexes.length > 0) {
      this.callTeranetOperation(() => {
        this.openNameWritSearch(this.prepareOrderNameWritSearchRequest());
      });
    }
  }

  openNameWritSearch(writSearchRequest: TeranetWritSearchRequest): void {
    this.dialogService.matDialogContent({
      content: WritNameSearchModal,
      context: {
        writSearchRequest: writSearchRequest,
        enforcementOffices: this.enforcementOffices,
        searchedWrits: this.matter.teranetDocket.getAllSearchedWrits()
      },
      onFulfillment: (result: TeranetWritSearchResponse) => {
        if (result) {
          if (result.successfulWrits && result.successfulWrits.length > 0) {
            result.successfulWrits.forEach(writ => {
              this.selectedParcelRegister.searchedWrits.push(writ);
              let parcelRegister = this.matter.teranetDocket.parcelRegisters.find(pr => pr.id == this.selectedParcelRegister.id);
              if (parcelRegister) {
                parcelRegister.searchedWrits.push(writ);
              }
            });
            this.fillWritsArray();
            this.selectedNameIndexes = [];
          }
          if (result.failedOrders && result.failedOrders.length) {
            let message = '';
            result.failedOrders.forEach(str => {
              message += str + '<br>';
            });
            this.dialogService.confirm('Teranet Connect &trade;: Order Name Search Response', message, true, 'OK');
          }
          if (result.updatedWrit) {
            let index = this.selectedParcelRegister.searchedWrits.findIndex(writ => writ.id === result.updatedWrit.id);
            if (index >= 0) {
              this.selectedParcelRegister.searchedWrits[ index ] = result.updatedWrit;
            } else {
              this.selectedParcelRegister.searchedWrits.push(result.updatedWrit);
            }
            this.fillWritsArray();
          }
        }
      }
    });

  }

  prepareOrderNameWritSearchRequest(): TeranetWritSearchRequest {
    let writSearchRequest: TeranetWritSearchRequest = this.prepareBaseWritSearchRequest('ORDER_NAME');
    this.selectedNameIndexes.forEach(selectedIndex => {
      writSearchRequest.partiesToSearch.push(this.preparePartyDataForSearchFromWrit(this.writsCollection.writsWrapper[ selectedIndex ]));
    });
    return writSearchRequest;
  }

  prepareBaseWritSearchRequest(writSearchType: WritSearchType): TeranetWritSearchRequest {
    let writSearchRequest: TeranetWritSearchRequest = new TeranetWritSearchRequest();
    writSearchRequest.matterId = this.matter.id;
    writSearchRequest.parcelRegisterId = this.selectedParcelRegister.id;
    writSearchRequest.writSearchType = writSearchType;
    writSearchRequest.docketSystemId = this.matter.teranetDocket.docketSystemId;
    //In case of re-do search fetching out the EO details from already executed Writ.
    if (writSearchType != 'REDO_SEARCH') {
      writSearchRequest.lro = this.selectedParcelRegister.lro;
      writSearchRequest.enforcementOfficeNumber = this.enforcementOffice.enforcementOfficeNumber;
      writSearchRequest.enforcementOfficeName = this.enforcementOffice.enforcementOfficeName;
    }

    writSearchRequest.teranetUser = this.teranetUser;
    writSearchRequest.reLine = this.getReLineForWritSearch();
    return writSearchRequest;
  }

  get enforcementOffice(): EnforcementOffice {
    let parcelRegisterLro = this.selectedParcelRegister ? this.selectedParcelRegister.lro : '';
    if (parcelRegisterLro && parcelRegisterLro.length == 1) { //need leading zero if LRO is single digit, i.e. "02" instead of "2"
      parcelRegisterLro = '0' + parcelRegisterLro;
    }
    return this.enforcementOffices.find(value => value.lroNumber === parcelRegisterLro);
  }

  preparePartyDataForSearchFromWrit(writWrapper: WritWrapper): WritPartyData {
    let selectedWrit: Writ = writWrapper.primaryWrit;
    let partyToSearch: WritPartyData = new WritPartyData();
    partyToSearch.partyType = selectedWrit.partyType;
    partyToSearch.partyOrigin = selectedWrit.partyOrigin;
    //If company then lastName allows 120 characters for search else 60 characters
    if (selectedWrit.givenName) {
      if (selectedWrit.partyType === 'Company') {
        partyToSearch.givenName = selectedWrit.givenName.substr(0, 120).toUpperCase().trim();
      } else {
        partyToSearch.givenName = selectedWrit.givenName.substr(0, 60).toUpperCase().trim();
      }
    }
    if (selectedWrit.lastName) {
      partyToSearch.lastName = selectedWrit.lastName.substr(0, 60).toUpperCase().trim();
    }

    return partyToSearch;
  }

  getReLineForWritSearch(): string {
    let reLine: string = (this.matter.clientReLine ? this.matter.clientReLine : '') + ' ' + this.matter.matterType + ' (' + this.matter.matterRecordNumber + ')';
    return reLine.trim().substr(0, 27).toUpperCase();
  }

  orderWritsModal(): void {
    this.callTeranetOperation(() => {
      let selectedExecutionsCount = this.selectedWritExecutions.length;
      let writTxt = selectedExecutionsCount > 1 ? 'writs' : 'writ';
      this.dialogService.confirm('Teranet Connect &trade;: Order Writ', 'Proceed to order ' + selectedExecutionsCount + ' selected ' + writTxt + '?', false, 'Proceed').subscribe(res => {
        if (res) {
          this.orderWritExecutions(this.prepareBaseWritNumberSearchRequest());
        }
      });
    });
  }

  prepareBaseWritNumberSearchRequest(): TeranetWritNumberSearchRequest {
    let writNumberSearchRequest: TeranetWritNumberSearchRequest = new TeranetWritNumberSearchRequest();
    writNumberSearchRequest.docketSystemId = this.matter.teranetDocket.docketSystemId;
    writNumberSearchRequest.lro = this.selectedParcelRegister.lro;
    writNumberSearchRequest.enforcementOfficeNumber = this.enforcementOffice.enforcementOfficeNumber;
    writNumberSearchRequest.enforcementOfficeName = this.enforcementOffice.enforcementOfficeName;
    writNumberSearchRequest.parcelRegisterId = this.selectedParcelRegister.id;
    writNumberSearchRequest.teranetUser = this.teranetUser;
    return writNumberSearchRequest;
  }

  orderWritExecutions(writNumberSearchRequest: TeranetWritNumberSearchRequest): void {

    if (SESSION_STORAGE_KEYS && SESSION_STORAGE_KEYS.user) {
      writNumberSearchRequest.reLine = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)).legalFirmName;
    }

    this.selectedWritExecutions.forEach(selectedWritExecution => {
      writNumberSearchRequest.writExecutions.push(selectedWritExecution);
    });
    this.teranetService.orderWrits(writNumberSearchRequest).subscribe(response => {
      if (response) {
        this.selectedWritExecutions = [];
        if (response.successfulWritExecutions && response.successfulWritExecutions.length > 0) {
          console.log(response);
          setTimeout(() => {
            response.successfulWritExecutions.forEach(writExecution => {
              this.matter.teranetDocket.updateWritExecutions(writExecution);
            });
            this.fillWritsArray();
          }, 100);
        }
        if (response.failedOrders && response.failedOrders.length) {
          let message = '';
          response.failedOrders.forEach(str => {
            message += str + '<br>';
          });
          this.dialogService.confirm('Teranet Connect &trade;: Order Writs Response', message, true, 'OK');
        }
      }
    });
  }

  addNumberWritSearch(): void {
    this.callTeranetOperation(() => {
      this.openWritNumberSearch(this.prepareBaseWritNumberSearchRequest());
    });
  }

  openWritNumberSearch(writNumberSearchRequest: TeranetWritNumberSearchRequest): void {
    writNumberSearchRequest.reLine = this.getReLineForWritSearch();
    this.dialogService.matDialogContent({
      content: WritNumberSearchModal,
      context: {
        writNumberSearchRequest: writNumberSearchRequest,
        enforcementOffices: this.enforcementOffices,
        searchedWrits: this.matter.teranetDocket.getAllSearchedWrits()
      },
      onFulfillment: (result: Writ) => {
        if (result) {
          let index = this.selectedParcelRegister.searchedWrits.findIndex(writ => writ.id === result.id);
          if (index >= 0) {
            this.selectedParcelRegister.searchedWrits[ index ] = result;
          } else {
            this.selectedParcelRegister.searchedWrits.push(result);
          }
          this.fillWritsArray();
        }
      }
    });

  }

  getTeranetDocketInstrument(instrumentNumber: string) {
    return this.matter.teranetDocket.getPrimaryInstrument(instrumentNumber);
  }

  getInstrumentHoverText(instrumentWrapper: InstrumentWrapper): string {
    const primaryDocument: TeranetDocument = instrumentWrapper.primaryDocument;
    let str = '';
    let dateFormatPipe: DateFormatPipe = new DateFormatPipe();

    if (instrumentWrapper.isInstrumentWrapper) {
      let primaryInstrument: Instrument = this.getTeranetDocketInstrument(instrumentWrapper.instrument.instrumentNumber);
      if (primaryInstrument && primaryInstrument.courierDate) {
        str += 'Courier requested ' + (dateFormatPipe.transform(primaryInstrument.courierDate, [])) + '. ';
      }
    }

    if (primaryDocument.isDocumentAvailableForDownload || primaryDocument.isDocumentAvailableForView) {
      str += primaryDocument.numOfPages + ' page PDF';
      if (primaryDocument.isDocumentAvailableForView) {
        let dateFormatPipe: DateFormatPipe = new DateFormatPipe();
        str += ' downloaded on ' + (dateFormatPipe.transform(primaryDocument.downloadedDate, [ 'noDay' ]));
      }
    } else if (primaryDocument.isDocumentNotAvailable && instrumentWrapper.instrument && instrumentWrapper.instrument.requestedDate) {
      str += 'Instrument requested ' + (dateFormatPipe.transform(instrumentWrapper.instrument.requestedDate, [ 'noDay' ])) + '.';
    }

    return str;
  }

  openParcelRegisterPdf(parcel: ParcelRegister) {

    if (parcel && parcel.parcelImage && parcel.parcelImage.id && parcel.parcelImage.downloaded) {
      this.teranetService.downloadTeranetDocumentAndDisplay(currentMatter.value.id, parcel.parcelImage.id);
    }
  }

  viewAdjacentParcelAnalysis() {
    this.dialogService.matDialogContent({
      content: AnalysisAdjacentParcelComponent,
      context: {
        teranetDocket: this.matter.teranetDocket,
        matterId: this.matter.id,
        focusFirst: this.analysisFocus,
        adjacentParcelAnalysisAvailable: this.selectedParcelRegister.adjacentParcelAnalysisAvailable
      },
      onFulfillment: (result: any) => {

        if (result && result.action == 'ORDER' && result.instrument) {

          this.callTeranetOperation(() => {

            this.analysisFocus = result.focusFirst;
            let primaryDocument: TeranetDocument = this.matter.teranetDocket.getPrimaryDocumentForInstrument(result.instrument);

            if (primaryDocument.isDocumentNotAvailable) {
              this.openRequestInstrumentConfirmationModal(result.instrument);
            } else {
              let teranetOrderInstruemntRequest: TeranetOrderInstrumentRequest = this.prepareOrderInstrumentPaaRequest(result.instrument);
              this.orderInstrument(teranetOrderInstruemntRequest);
            }
          });
        }
      }
    });
  }

  prepareOrderInstrumentPaaRequest(instrument: Instrument): TeranetOrderInstrumentRequest {

    this.selectedInstrumentsIndexes.length = 1;
    let todList: TeranetOrderDocument[] = [];

    let tod = new TeranetOrderDocument();
    let primaryDocument: TeranetDocument = this.matter.teranetDocket.getPrimaryDocumentForInstrument(instrument);
    tod.documentId = primaryDocument.id;
    tod.instrumentNumber = instrument.instrumentNumber;
    tod.documentType = 'INSTRUMENT';
    todList.push(tod);

    let toir: TeranetOrderInstrumentRequest = new TeranetOrderInstrumentRequest();
    toir.orderedDocuments = todList;
    toir.teranetUser = this.matterTab.loggedInTeranetUser;
    toir.docketSystemId = this.matter.teranetDocket.docketSystemId;
    toir.lro = this.selectedParcelRegister.lro;
    toir.analysisStillNeeded = instrument.analysisStillNeeded;

    return toir;
  }

  requisitionStatus(instrument: Instrument): string {
    let status: string = '';

    if (instrument) {
      let requisition: RequisitionTemplate = this.requisitionForInstrument(instrument);
      if (requisition) {
        status = requisitionStatusDropdownOptions.find(item => item.value == requisition.requisitionStatus).label;
      }
    }

    return status;
  }

  undertakingStatus(instrument: Instrument): string {
    let status: string = '';

    if (instrument) {
      let undertaking: MatterUndertaking = this.undertakingForInstrument(instrument);
      if (undertaking) {
        status = matterUndertakingStatusDropdowns.find(item => item.value == undertaking.matterUndertakingStatus).label;
      } else {
        let permittedRegistration: PermittedRegistration = this.permittedRegistrationForInstrument(instrument);
        if (permittedRegistration) {
          return 'Permitted';
        }
      }
    }

    return status;
  }

  //hide menu if undertaking or permitted registration for instrument was created as a result of an existing mortgage import
  isSpinInstrumentMenuVisible(instrument: Instrument): boolean {
    let undertaking: MatterUndertaking = this.undertakingForInstrument(instrument);
    if (undertaking) {
      return !this.matter.existingMortgages.some((mortgage: Mortgage) => mortgage.undertakingId == undertaking.id);
    } else {
      let permittedRegistration: PermittedRegistration = this.permittedRegistrationForInstrument(instrument);
      if (permittedRegistration) {
        return !this.matter.existingMortgages.some((mortgage: Mortgage) => mortgage.permittedRegistrationId == permittedRegistration.id);
      }
    }

    return true;
  }

  //finds if there is a requisition related to the instrument
  requisitionForInstrument(currentInstrument: Instrument): RequisitionTemplate {

    return this.matter.matterRequisitions.find((template: RequisitionTemplate) => {
      if (template.instrumentId) {
        const requisitionParcelRegister = this.findParcelRegisterByInstrumentId(template.instrumentId);

        if (!requisitionParcelRegister) {
          return false;
        }

        const requisitionInstrument = requisitionParcelRegister.instruments.find(instrument => instrument.id == template.instrumentId);

        if ((currentInstrument) && (currentInstrument.instrumentNumber == requisitionInstrument.instrumentNumber) && (this.selectedParcelRegister.lro == requisitionParcelRegister.lro)) {
          return true;
        }
      }
      return false;
    });
  }

  //finds if there is an undertaking related to the instrument
  undertakingForInstrument(currentInstrument: Instrument): MatterUndertaking {

    let instruments: Instrument[] = this.getAllInstrumentsWithSameNumber(currentInstrument);

    return this.matter.matterUndertakings.find((undertaking: MatterUndertaking) => instruments.some((instrument: Instrument) => instrument.id == undertaking.instrumentId));
  }

  //finds if there is a permitted registration related to the instrument
  permittedRegistrationForInstrument(currentInstrument: Instrument): PermittedRegistration {
    let instruments: Instrument[] = this.getAllInstrumentsWithSameNumber(currentInstrument);

    return this.matter.permittedRegistrations.find((permittedRegistration: PermittedRegistration) => instruments.some((instrument: Instrument) => instrument.id == permittedRegistration.instrumentId));
  }

  //Check other parcel registers for instruments with the same instrument number in case of duplication.
  private getAllInstrumentsWithSameNumber(currentInstrument: Instrument): Instrument[] {
    let instruments: Instrument[] = [];
    this.parcelRegisters.forEach((parcelRegister: ParcelRegister) => {
      parcelRegister.instruments.forEach((instrument: Instrument) => {
        if (currentInstrument.instrumentNumber == instrument.instrumentNumber) {
          instruments.push(instrument);
        }
      });
    });
    return instruments;
  }

  //finds parcel register based on instrument id (ignoring adjacent parcels)
  public findParcelRegisterByInstrumentId(instrumentId: number): ParcelRegister {
    return this.parcelRegisters.find((parcelRegister: ParcelRegister) => {
      if (!parcelRegister.isAdjacentParcel) {
        return parcelRegister.instruments.some(instrument => instrumentId == instrument.id);
      } else {
        return false;
      }
    });
  }

  viewTeranetDocketSummary(): void {
    this.callTeranetOperation(() => {
      this.openGetTeranetDocketSummaryModal();
    });
  }

  openGetTeranetDocketSummaryModal(): void {
    this.dialogService.matDialogContent({
      content: GetTeranetDocketSummaryModalComponent,
      context: {
        teranetDocket: this.matter.teranetDocket,
        matter: this.matter,
        teranetUser: this.teranetUser

      },
      onFulfillment: (result: any) => {
        if (result && result.docketSummary) {
          console.log(result);
          this.openViewTeranetDocketSummaryModal(result);
        }
      }
    });
  }

  openViewTeranetDocketSummaryModal(result: any): void {
    this.dialogService.matDialogContent({
      content: ViewTeranetDocketSummaryModalComponent,
      context: {
        teranetDocket: this.matter.teranetDocket,
        matter: this.matter,
        docketSummary: result.docketSummary,
        startDate: result.startDate,
        endDate: result.endDate,
        documentProfileCache: this.documentProfileCache,
        unityCharges: false

      },
      fullScreen: true,
      onFulfillment: (result: any) => {
      }
    });
  }

  openViewUnityChargesModal(): void {
    this.dialogService.matDialogContent({
      content: ViewTeranetDocketSummaryModalComponent,
      context: {
        teranetDocket: this.matter.teranetDocket,
        matter: this.matter,
        documentProfileCache: this.documentProfileCache,
        unityCharges: true

      },
      fullScreen: true,
      onFulfillment: (result: any) => {
      }
    });
  }

  loadWritBurgerMenuItems(writ: Writ): BurgerMenuExtendedItem[] {
    let writBurgerMenuItems = [];
    let viewWritExecutionItem = this.addToBurgerMenu(writBurgerMenuItems, TeranetWritMenuActions.VIEW_WRIT_EXECUTION_LABEL, this.viewWritCertificateAction);
    let redoSearchItem = this.addToBurgerMenu(writBurgerMenuItems, TeranetWritMenuActions.REDO_SEARCH_LABEL, this.redoSearchAction);
    let declarationItem = this.addToBurgerMenu(writBurgerMenuItems, TeranetWritMenuActions.DECLARATION_LABEL, this.declarationAction, true);
    let additionalParagraphItem = this.addToBurgerMenu(writBurgerMenuItems, TeranetWritMenuActions.ADDITIONAL_PARAGRAPH_LABEL, this.additionalParagraphFromWritAction);
    let blankParagraphItem = this.addToBurgerMenu(writBurgerMenuItems, TeranetWritMenuActions.BLANK_PARAGRAPH_LABEL, this.blankParagraphFromWritAction);

    if (!writ.writCertificate) {
      viewWritExecutionItem.isDisabled = true;
      redoSearchItem.isDisabled = true;
      declarationItem.isDisabled = true;
      blankParagraphItem.isDisabled = true;
    }

    if (!(this.isClientDeclaration(writ) || this.isSolicitorDeclaration(writ))) {
      additionalParagraphItem.isDisabled = true;
      blankParagraphItem.isDisabled = true;
    }

    return writBurgerMenuItems;
  }

  loadExecutionBurgerMenuItems(writExecution: WritExecution): BurgerMenuExtendedItem[] {
    let executionBurgerMenuItems = [];
    let viewWritDetailItem = this.addToBurgerMenu(executionBurgerMenuItems, TeranetWritMenuActions.VIEW_WRIT_LABEL, this.viewExecutionCertificateAction);
    let editParagraphItem = this.addToBurgerMenu(executionBurgerMenuItems, TeranetWritMenuActions.EDIT_PARAGRAPH_LABEL, this.editParagraphAction);
    let additionalParagraphItem = this.addToBurgerMenu(executionBurgerMenuItems, TeranetWritMenuActions.ADDITIONAL_PARAGRAPH_LABEL, this.additionalParagraphFromExecutionAction);
    if (!writExecution.executionCertificate) {
      viewWritDetailItem.isDisabled = true;
      editParagraphItem.isDisabled = true;
      additionalParagraphItem.isDisabled = true;
    }

    if (!(this.isClientDeclarationParagraph(writExecution) || this.isSolicitorDeclarationParagraph(writExecution))) {
      editParagraphItem.isDisabled = true;
    }

    const writ = this.searchedWritById(writExecution.writId);

    if (!(this.isClientDeclaration(writ) || this.isSolicitorDeclaration(writ))) {
      additionalParagraphItem.isDisabled = true;
    }

    return executionBurgerMenuItems;
  }

  clickWritBurgerMenu(writ: Writ, clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(writ);
    }
  }

  clickExecutionBurgerMenu(writExecution: WritExecution, clickedMenuOption: BurgerMenuExtendedItem): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(writExecution);
    }
  }

  viewWritExecutionCertificate(documentId: number): void {
    this.teranetService.downloadTeranetDocumentAndDisplay(currentMatter.value.id, documentId);
  }

  viewWritCertificateAction = (writ: Writ): void => {
    let documentId: number = writ.writCertificate.id;
    this.teranetService.downloadTeranetDocumentAndDisplay(currentMatter.value.id, documentId);
  };

  viewExecutionCertificateAction = (writExecution: WritExecution): void => {
    let documentId: number = writExecution.executionCertificate.id;
    this.teranetService.downloadTeranetDocumentAndDisplay(currentMatter.value.id, documentId);
  };

  redoSearchAction = (writ: Writ): void => {
    this.callTeranetOperation(() => {
      let writTxt = 'A Name Search for ' + writ.partyName + ' (enforcement office:  ' + writ.enforcementOffice +
        ') was previously performed on ' + moment(writ.searchTimeStamp).format('MMM DD, YYYY h:mm a') + '<br>' +
        '<br>' + 'Do you wish to view the existing search or perform a new search?';
      const contentModalConfig: DialogConfigParams = {
        title: 'Teranet Connect &trade;: Name Search Already Exists',
        message: writTxt,
        hideCancelBtn: false,
        customConfirmBtnText: 'View Existing Search',
        customBtnText: 'New Search',
        customReturnText: 'NEW'

      };
      this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => {
        if (res) {
          if (res.toString() === 'NEW') {
            this.openRedoWritSearch(writ);
          } else {
            this.viewWritExecutionCertificate(writ.writCertificate.id);
          }
        }
      });
    });
  };

  openRedoWritSearch(writ: Writ): void {
    let writSearchRequest: TeranetWritSearchRequest = this.prepareBaseWritSearchRequest('REDO_SEARCH');
    let selectedEO: EnforcementOffice = this.enforcementOffices.find(eo => eo.enforcementOfficeName == writ.enforcementOffice);
    writSearchRequest.writId = writ.id;
    let partyToSearch: WritPartyData = new WritPartyData();
    partyToSearch.partyType = writ.partyType;
    partyToSearch.partyOrigin = writ.partyOrigin;
    writSearchRequest.lro = selectedEO.lroNumber;
    writSearchRequest.enforcementOfficeNumber = selectedEO.enforcementOfficeNumber;
    writSearchRequest.enforcementOfficeName = selectedEO.enforcementOfficeName;

    if (writ.givenName) {
      partyToSearch.givenName = writ.givenName;
    }
    if (writ.lastName) {
      partyToSearch.lastName = writ.lastName;
    }
    writSearchRequest.partiesToSearch.push(partyToSearch);
    this.dialogService.matDialogContent({
      content: WritNameSearchModal,
      context: {
        writSearchRequest: writSearchRequest,
        enforcementOffices: this.enforcementOffices
      },
      onFulfillment: (result: TeranetWritSearchResponse) => {
        this.handleRedoSearchResponse(result);
      }
    });

  }

  handleRedoSearchResponse(result: TeranetWritSearchResponse): void {
    if (result && result.updatedWrit) {
      let index = this.selectedParcelRegister.searchedWrits.findIndex(writ => writ.id === result.updatedWrit.id);
      if (index >= 0) {
        this.selectedParcelRegister.searchedWrits[ index ] = result.updatedWrit;
      }
      this.fillWritsArray();
    }
    if (result && result.removedExecutions && result.removedExecutions.length > 0) {
      let message = '';
      result.removedExecutions.forEach(str => {
        message += str + '<br>';
      });
      this.dialogService.confirm('Teranet Connect &trade;: Order Name Search Response', message, true, 'OK');
    }
    if (result && result.newExecutions && result.newExecutions.length > 0) {
      let message = '';
      result.newExecutions.forEach(str => {
        message += str + '<br>';
      });
      this.dialogService.confirm('Teranet Connect &trade;: Order Name Search Response', message, true, 'OK');
    }
  }

  public getSolicitorNames(): string[] {
    let solicitorNames: string[] = [];

    // if(Array.isArray(this.matter.solicitors)) {
    //     this.matter.solicitors.forEach(solicitor => solicitorNames.push(solicitor.contact.displayName));
    // }
    solicitorNames.push(this.matter.solicitorName);
    if (this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.solicitorName) {
      solicitorNames.push(this.matter.otherPartyContactInfo.solicitorName);
    }
    return solicitorNames;
  }

  //Create declaration
  declarationAction = (writ: Writ): void => {
    //check if any writ executions are missing.
    const writExecutionMissing: boolean = writ.writExecutions.some(execution => !execution.executionCertificate);

    if (writExecutionMissing) {
      this.showWritExecutionMissingMessage(writ);
    } else {
      this.dialogService.matDialogContent({
        content: WritSearchDeclarationsModal,
        context: <WritSearchDeclarationsModalContext>{
          solicitorNames: this.getSolicitorNames(),
          writ: writ,
          matter: this.matter
        },
        onFulfillment: (result) => {
          if (result.action === 'OK') {
            if (result.isClientSelected && !this.isClientDeclaration(writ)) {
              this.matter.declarations.push(result.clientDeclaration);
              this.addParagraphs(writ, result.clientDeclaration);
              this.matter.dirty = true;
            }

            if (result.isSolicitorSelected && !this.isSolicitorDeclaration(writ) && this.isMinimumForSolicitorDeclaration(writ)) {
              this.matter.declarations.push(result.solicitorDeclaration);
              this.addParagraphs(writ, result.solicitorDeclaration);
              this.matter.dirty = true;
            }
          }
        }
      });
    }
  };

  isMinimumForSolicitorDeclaration(writ: Writ): boolean {
    //Solicitor declarations require at least one writ with a judgement >= $50,000
    return writ.writExecutions.some((execution: WritExecution) => execution.judgementsTotal >= this.MINIMUM_JUDGEMENT_AMOUNT);
  }

  addParagraphs(writ: Writ, declaration: Declaration): void {
    this.paragraphTemplateService.getParagraphTemplateByGender(declaration.gender)
    .subscribe((paragraphTemplate: ParagraphTemplate) => {

      let executionsRequiringParagraphs: WritExecution[] = declaration.isSolicitorDeclaration() ?
        writ.writExecutions.filter((execution: WritExecution) => execution.judgementsTotal >= this.MINIMUM_JUDGEMENT_AMOUNT) : writ.writExecutions;

      //create paragraph for each writ
      executionsRequiringParagraphs.forEach(execution => this.addOneParagraphToDeclaration(declaration, execution, paragraphTemplate.paragraphText));
      this.fillWritsArray();
    });
  }

  addOneParagraphToDeclaration(declaration: Declaration, execution: WritExecution, paragraphText: string) {
    let paragraph: Paragraph = new Paragraph();
    paragraph.writExecutionId = execution.id;
    paragraph.executionNumber = execution.executionNumber;
    paragraph.debtorName = Utils.convertToTitleCase(execution.debtorName);
    paragraph.plaintiffName = execution.plaintiffName;
    paragraph.judgmentAmount = execution.judgementsTotal ? this.convertToCurrencyFormat(execution.judgementsTotal) : '';
    paragraph.costAmount = (execution.costsTotal && execution.costsTotal > 0) ? this.convertToCurrencyFormat(execution.costsTotal) : 'nil';
    paragraph.setParagraphTextFromTemplate(declaration.clientName, paragraphText, declaration.isSolicitorDeclaration());
    declaration.paragraphs.push(paragraph);
  }

  convertToCurrencyFormat(amount: number): string {
    return this.decimalPipe.transform(amount, '1.2-2');
  }

  isClientDeclaration(writ: Writ): boolean {
    return !!(writ && this.matter.findClientDeclarationByWrit(writ));
  }

  isSolicitorDeclaration(writ: Writ): boolean {
    return !!(writ && this.matter.findSolicitorDeclarationByWrit(writ));
  }

  isClientDeclarationParagraph(writExecution: WritExecution): boolean {
    const declaration: Declaration = this.matter.findClientDeclarationByWritId(writExecution.writId);
    return !!(declaration && declaration.findParagraphByWritExecutionId(writExecution.id));
  }

  isSolicitorDeclarationParagraph(writExecution: WritExecution): boolean {
    const declaration: Declaration = this.matter.findSolicitorDeclarationByWritId(writExecution.writId);
    return !!(declaration && declaration.findParagraphByWritExecutionId(writExecution.id));
  }

  clientDeclarationParagraph(writExecution: WritExecution): Paragraph {
    const declaration: Declaration = this.matter.findClientDeclarationByWritId(writExecution.writId);
    return declaration ? declaration.findParagraphByWritExecutionId(writExecution.id) : null;
  }

  solicitorDeclarationParagraph(writExecution: WritExecution): Paragraph {
    const declaration: Declaration = this.matter.findSolicitorDeclarationByWritId(writExecution.writId);
    return declaration ? declaration.findParagraphByWritExecutionId(writExecution.id) : null;
  }

  showWritExecutionMissingMessage(writ: Writ): void {
    let writTxt = 'An executions paragraph for each writ certificate ordered from Teranet Connect will be automatically generated. Each paragraph is populated with the execution number, plaintiff, judgement amount and judgement costs from the Teranet Connect writ.<br><br>The following writ certificates must first be ordered from the Writs tab:<br><br>';

    writ.writExecutions.filter(execution => !execution.executionCertificate).forEach(execution => writTxt += execution.partyName + '<br>');

    const contentModalConfig: DialogConfigParams = {
      title: 'Teranet Connect &trade;: Declarations',
      message: writTxt,
      hideCancelBtn: true,
      customConfirmBtnText: 'Close',
      alignContentLeft: true
    };
    this.dialogService.confirmCustomDialog(contentModalConfig).subscribe(res => {
      if (res) {
        //Do Nothing
      }
    });
  }

  //Open paragraph modal for editing
  openParagraphDetailModal(paragraph: Paragraph, writExecution: WritExecution, isClientDeclaration: boolean): void {
    this.dialogService.matDialogContent({
      content: ParagraphDetailModel,
      context: <ParagraphDetailModelContext>{
        paragraph: paragraph,
        teranetService: this.teranetService,
        matter: this.matter,
        writExecution: writExecution,
        isDeleteVisible: true
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'OK') {
            this.matter.dirty = true;
          } else if (result.action === 'Delete') {
            let declaration: Declaration;
            if (isClientDeclaration) {
              declaration = this.matter.findClientDeclarationByWritId(writExecution.writId);
            } else {
              declaration = this.matter.findSolicitorDeclarationByWritId(writExecution.writId);
            }

            let paragraphIndex: number = declaration.paragraphs.findIndex(p => p === paragraph);
            if (paragraphIndex > -1) {
              declaration.paragraphs.splice(paragraphIndex, 1);
            }
            this.matter.dirty = true;
          }
        }
      }
    });
  }

  //After 'Additional Paragraph' created, user can view/edit it.
  openParagraphDetailModalAfterAdd(paragraph: Paragraph, writ: Writ, writExecution: WritExecution, isClientSelected: boolean, isSolicitorSelected: boolean): void {
    this.dialogService.matDialogContent({
      content: ParagraphDetailModel,
      context: <ParagraphDetailModelContext>{
        paragraph: paragraph,
        teranetService: this.teranetService,
        matter: this.matter,
        writExecution: writExecution,
        isDeleteVisible: false
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.action === 'OK') {
            const clientDeclaration: Declaration = isClientSelected ? this.matter.findClientDeclarationByWrit(writ) : null;
            const solicitorDeclaration: Declaration = isSolicitorSelected ? this.matter.findSolicitorDeclarationByWrit(writ) : null;

            if (isClientSelected) {
              clientDeclaration.paragraphs.push(paragraph);
            }

            if (!isClientSelected && isSolicitorSelected) {
              solicitorDeclaration.paragraphs.push(paragraph);
            } else if (isClientSelected && isSolicitorSelected) {
              //When paragraph being added to both client and solicitor declarations, need to create a copy of client paragraph and adjust wording for solicitor.
              const solicitorParagraph: Paragraph = solicitorDeclaration.createSolicitorParagraphFromClientParagraph(paragraph);
              solicitorDeclaration.paragraphs.push(solicitorParagraph);
            }

            this.dialogService.confirm('Additional Paragraph Added', 'Additional paragraph added and may be viewed/edited in Executions Affidavits.', true, 'OK');

            this.matter.dirty = true;
          }
        }
      }
    });
  }

  additionalParagraphFromWritAction = (writ: Writ): void => {

    this.dialogService.matDialogContent({
      content: WritSearchAddParagraphModal,
      context: <WritSearchAddParagraphModalContext>{
        writId: writ.id,
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result.action === 'OK') {
          //new paragraph being added based on writ (not execution)
          this.addNewParagraph(writ, null, result.isClientSelected, result.isSolicitorSelected);
        }
      }
    });
  };

  additionalParagraphFromExecutionAction = (writExecution: WritExecution): void => {

    this.dialogService.matDialogContent({
      content: WritSearchAddParagraphModal,
      context: <WritSearchAddParagraphModalContext>{
        writId: writExecution.writId,
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result.action === 'OK') {
          const writ = this.searchedWritById(writExecution.writId);
          //new paragraph being added based on writ execution
          this.addNewParagraph(writ, writExecution, result.isClientSelected, result.isSolicitorSelected);
        }
      }
    });
  };

  searchedWritById(id: number): Writ {
    return this.selectedParcelRegister.searchedWrits.find(writ => writ.id === id);
  }

  addNewParagraph(writ: Writ, writExecution: WritExecution, isClientSelected: boolean, isSolicitorSelected: boolean): void {
    this.dialogService.matDialogContent({
      content: WritSearchAddParagraphQuestionnaireModal,
      context: <WritSearchAddParagraphQuestionnaireModalContext>{
        isClientSelected: isClientSelected,
        isSolicitorSelected: isSolicitorSelected,
        writ: writ,
        writExecution: writExecution
      },
      onFulfillment: (result) => {
        if (result && result.paragraph) {
          const clientDeclaration: Declaration = isClientSelected ? this.matter.findClientDeclarationByWrit(writ) : null;
          const solicitorDeclaration: Declaration = isSolicitorSelected ? this.matter.findSolicitorDeclarationByWrit(writ) : null;

          //get client declaration gender (or solicitor declaration if no client declaration exists)
          const templateGender: string = isClientSelected ? clientDeclaration.gender : solicitorDeclaration.gender;

          this.newParagraphTemplateSubscription = this.paragraphTemplateService.getParagraphTemplateByGender(templateGender)
          .subscribe((paragraphTemplate: ParagraphTemplate) => {
            if (isClientSelected) {
              result.paragraph.setParagraphTextFromTemplate(clientDeclaration.clientName, paragraphTemplate.paragraphText, false);
            } else if (!isClientSelected && isSolicitorSelected) {
              //if paragraph only being added to solicitor declaration
              result.paragraph.setParagraphTextFromTemplate(solicitorDeclaration.clientName, paragraphTemplate.paragraphText, true);
            }

            this.openParagraphDetailModalAfterAdd(result.paragraph, writ, writExecution, isClientSelected, isSolicitorSelected);
          });
        }
      }
    });
  }

  addBlankParagraph(writ: Writ, isClientSelected: boolean, isSolicitorSelected: boolean): void {
    if (isClientSelected) {
      const clientDeclaration: Declaration = this.matter.findClientDeclarationByWrit(writ);
      this.addBlankParagraphToDeclaration(clientDeclaration);
    }

    if (isSolicitorSelected) {
      const solicitorDeclaration: Declaration = this.matter.findSolicitorDeclarationByWrit(writ);
      this.addBlankParagraphToDeclaration(solicitorDeclaration);
    }

    this.dialogService.confirm('Additional Paragraph Added', 'Additional paragraph added and may be viewed/edited in Executions Affidavits.', true, 'OK');

    this.matter.dirty = true;
  }

  public addBlankParagraphToDeclaration(declaration: Declaration) {
    const templateGender: string = declaration.gender;
    this.blankParagraphTemplateSubscription = this.paragraphTemplateService.getParagraphTemplateByGender(templateGender)
    .subscribe((paragraphTemplate: ParagraphTemplate) => {
      const paragraph: Paragraph = new Paragraph();
      paragraph.setParagraphTextFromTemplate(declaration.clientName, paragraphTemplate.paragraphText, declaration.isSolicitorDeclaration());
      declaration.paragraphs.push(paragraph);
    });
  }

  blankParagraphFromWritAction = (writ: Writ): void => {
    this.dialogService.matDialogContent({
      content: WritSearchAddParagraphModal,
      context: <WritSearchAddParagraphModalContext>{
        writId: writ.id,
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result.action === 'OK') {
          this.addBlankParagraph(writ, result.isClientSelected, result.isSolicitorSelected);
        }
      }
    });
  };

  editParagraphAction = (writExecution: WritExecution): void => {
    let isClientDeclaration: boolean = true;
    let paragraph = this.clientDeclarationParagraph(writExecution);

    //Check if there is a solicitor declaration paragraph if client declaration paragraph was not found.
    if (!paragraph) {
      paragraph = this.solicitorDeclarationParagraph(writExecution);
      isClientDeclaration = false;
    }

    if (paragraph) {
      this.openParagraphDetailModal(paragraph, writExecution, isClientDeclaration);
    }
  };

  instrumentsDropdownMenuOpen(event): void {
    this.resetAllActionButtons();
    event == 'open' ? jQuery('.tool-tip-icon:hover + .tool-tip-message').css('display', 'none') :
      jQuery('.tool-tip-icon + .tool-tip-message').css('display', '');

  }

  resetAllActionButtons(): void {
    this.resetActionButtonFlag();
    this.resetActionButtonFlagForAddOrder();
    this.resetActionButtonFlagForInstrument();
    this.resetActionButtonFlagForImportData();
    this.resetActionButtonFlagForWrit();
  }

  closeDropDownMenu(): void {
    DpDropDownComponent.activeInstance = null;
  }

  get partiesTofromColClass(): string {
    if (this.matter?.isMatterProvinceBC) {
      return 'table-partiesTo-AB';
    }
    if (this.isRequisitionApplicable) {
      return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-partiesTo-fromColClass-withReq', this.matter.provinceCode);
    } else {
      return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-partiesTo-fromColClass-withoutReq', this.matter.provinceCode);
    }

  }

  get instrumentNofromColClass(): string {
    if (this.matter?.isMatterProvinceBC) {
      return 'table-instrumentNo-AB';
    }
    return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-instrumentNo', this.matter.provinceCode);
  }

  get typefromColClass(): string {
    if (this.matter?.isMatterProvinceBC) {
      return 'table-type-AB';
    }
    return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-type', this.matter.provinceCode);
  }

  get requisitionStatusfromColClass(): string {
    if (this.matter?.isMatterProvinceBC) {
      return 'table-requisitionStatus-SK';
    }
    return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-requisitionStatus', this.matter.provinceCode);
  }

  get capacityFromColClass(): string {
    return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-capacity-style', this.matter.provinceCode);

  }

  get acceptedFileExtensions(): string {
    return this.ACCEPTED_FILE_EXTENSIONS.replace(/\*/g, '');
  }

  get selectedMatterId(): number {
    return this.matter && this.matter.id;
  }

  get maxSpinFileSizeInMb(): number {
    return this.appConfig.maxSpinUploadedFileSizeInMb;
  }

  get maxTprFileSizeInMb(): number {
    return this.appConfig.maxTprUploadedFileSizeInMB;
  }

  addTeranetUpload(isBlanketMortgage?: boolean, mortgageId ?: number): void {
    let teranetConnectComponent = this;
    if (this.matter.dirty) {
      this.callTeranetAfterMatterSave(() => {
        if (teranetConnectComponent && teranetConnectComponent.fileUploadInput && teranetConnectComponent.fileUploadInput.nativeElement) {
          teranetConnectComponent.fileUploadInput.nativeElement.click();
        }
      });
    } else {
      this.fileUploadInput.nativeElement.click();
    }

  }

  initiateUpload() {
    let uploadFilesControl = this.fileUploadInput.nativeElement;
    if (!(uploadFilesControl.value && uploadFilesControl.files && uploadFilesControl.files.length > 0)) {

      return;
    }

    if (uploadFilesControl.files.length > this.maxSpinFileSizeInMb) {
      const message: string = 'You cannot upload more than ' + this.maxSpinFileSizeInMb + ' files.';
      this.dialogService
      .confirm('Error', message, true, 'Ok')
      .subscribe(res => {

        return;
      });
      return;
    }
    if (uploadFilesControl.files.length > 1 && (this.showWizardFields || this.blanketMortgage)) {
      const message: string = 'You cannot upload more than 1 file ';
      this.dialogService
      .confirm('Error', message, true, 'Ok')
      .subscribe(res => {
        this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
      });
      return;
    }
    //Loading the documents one more time before passing them to the upload control, as we need the latest locking status from the server
    this.documentProductionService.getDocuments(this.selectedMatterId)
    .subscribe((matterDocs: Document[]) => {

      //Package the information about the documents in ExistingFile[] format (the common structure the upload control works with)
      let existingFiles: ExistingFile[] = matterDocs.filter((doc: Document) => this.fetchDocumentTypeByProvince(doc))
      .map((doc: Document) => {
        return {
          name: doc.documentName,
          lastUpdatedTimeStamp: doc.lastUpdatedTimeStamp,
          isOpen: doc.isDocumentOpened,
          isProtected: true
        } as ExistingFile;
      });

      let url = matterApi.uploadSpinDocument.replace('{matterId}', '' + this.matter.id);
      if (this.matter.isMatterProvinceAB && !!this.blanketMortgage && this.mortgageId) {
        url += '?isUploadedForCollateralProperty=' + this.blanketMortgage + '&blanketMortgageId=' + this.mortgageId;
      }
      if (this.matter.isMatterProvinceMB) {
        url = matterApi.uploadTPRDocument.replace('{matterId}', '' + this.matter.id);
      } else if (this.matter.isMatterProvinceSK) {
        url = matterApi.uploadISCDocument.replace('{matterId}', '' + this.matter.id);
      }

      let maxFileSizeByProvince = this.maxSpinFileSizeInMb;
      if (this.matter.isMatterProvinceMB) {
        maxFileSizeByProvince = this.maxTprFileSizeInMb;
      }
      this.dialogService.matDialogContent({
        content: FileUploadModal,
        context: {
          filesSelectedForUpload: this.fileUploadInput.nativeElement.files,
          uploadUrl: url,
          existingFiles: existingFiles,
          acceptedFileExtensions: this.ACCEPTED_FILE_EXTENSIONS,
          maxFileSize: maxFileSizeByProvince
        } as FileUploadModalContext,
        onFulfillment: (result) => {
          this.fileUploadInput.nativeElement.value = '';
          if (this.atLeastOneFileUploadComplete(result)) {
            if (this.matter && !this.blanketMortgage && this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isTitleSearchPerformed != DpBooleanValueTypes.YES) {
              this.matter.matterPropertyWithCondo.isTitleSearchPerformed = DpBooleanValueTypes.YES;
              this.matter.dirty = true;
            }
            this.updateDocket(result);

            if (this.matter && this.matter.matterLink && this.matter.matterLink.linkedMatterId) {
              //if the linked matter is also open, refresh it so it will show the uploaded spin parcel register
              let linkedMatterTab: MatterTab = this.tabService.getMatterTab(this.matter.matterLink.linkedMatterId);
              if (linkedMatterTab) {
                this.lockScreenService.lockForUpdate = true;
                this.matterService.getMatter(linkedMatterTab.matter.id)
                .finally(() => this.lockScreenService.lockForUpdate = false)
                .subscribe((refreshedMatter: Matter) => {
                  linkedMatterTab.matter = refreshedMatter;
                  linkedMatterTab.backEndMatter = refreshedMatter;
                  this.matterTab.linkedMatter = refreshedMatter;
                });
              }
            }
          } else {
            this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
          }
        },
        onRejection: (result) => {
          this.updateOwnerDataForWizard.emit('TeranetSearchAgain');
          this.fileUploadInput.nativeElement.value = '';
        }

      });

    });

  }

  fetchDocumentTypeByProvince(doc: Document): boolean {
    return this.matter && this.matter.isMatterProvinceAB ? doc.isSpinDocument : this.matter && this.matter.isMatterProvinceSK ? doc.isISCDocument : false;
  }

  atLeastOneFileUploadComplete(files: any[]): boolean {
    if (files && Array.isArray(files)) {
      return files.some(item => item.status == UploadStatus.COMPLETE);
    }
    return false;
  }

  getParcelRegister(parcelRegisters: ParcelRegister[], documentId: number) {
    if (Array.isArray(parcelRegisters) && parcelRegisters.length > 0) {
      this.selectedParcelRegister = parcelRegisters.find(item => {
        if (item.parcelImage && item.parcelImage.id == documentId) {
          return true;
        }
      });
    }
  }

//if (files && Array.isArray(files))
  updateDocket(files?: any[]): Subject<void> {
    let returnSubject: Subject<void> = new Subject<void>();
    this.teranetService.getDocket(this.matter.id).subscribe(
      (getDocketResult: TeranetDocket) => {
        this.matter.teranetDocket.parcelRegisters = getDocketResult.parcelRegisters;
        if (this.matter.teranetDocket && Array.isArray(this.matter.teranetDocket.parcelRegisters) && this.matter.teranetDocket.parcelRegisters.length > 0) {
          if (files && Array.isArray(files) && (files.length > 0) && files[ 0 ].documentId && this.blanketMortgage) {
            this.getParcelRegister(this.matter.teranetDocket.parcelRegisters, files[ 0 ].documentId);
          } else {
            this.selectedParcelRegister = this.matter.teranetDocket.parcelRegisters[ this.matter.teranetDocket.parcelRegisters.length - 1 ];
          }
          if ((this.blanketMortgage || this.showWizardFields) && this.selectedParcelRegister) {
            this.openImportPropertyData().subscribe(() => {
              returnSubject.next();
            });
          } else {
            this.updateParcelTreeTable();
            returnSubject.next();
          }
        }
      },
      (error: ApplicationError) => {
        console.log('getDocket failed : ', error);
      });
    return returnSubject;
  }

  getScrollBarSize() {
    // 540px is the height of all elements above and below scroll area
    let scrollBarHeight: number = window.innerHeight - 540;
    if (scrollBarHeight > 60) {
      jQuery('.bottom-table-wrapper').height(scrollBarHeight);
    }
  }

  @HostListener('window:resize', [ '$event' ])
  onResize(event) {
    this.getScrollBarSize();
  }

  getCapacityCodeLabelByValue(capacityCodeValue: string): string {
    const tenureOption = this.tenureOptions.find(item => item.value == capacityCodeValue);
    return tenureOption && tenureOption.label;
  }

  getCapacityCode(owner: TeranetPropertyOwner): string {
    if (this.matter.isMatterProvinceBC) {
      return owner.capacityCode === CapacityTypes.OTHER ? owner.share : this.getCapacityCodeLabelByValue(owner.capacityCode);
    }
    return this.matter.isMatterProvinceAB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB ? this.getCapacityCodeLabelByValue(owner.capacityCode) : owner.capacityCode;
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  get urlLabel(): string {
    let label: string = 'ISC';
    if (this.matter && this.matter.isMatterProvinceAB) {
      label = 'SPIN';
    } else if (this.matter && this.matter.isMatterProvinceMB) {
      label = 'TPR';
    }

    return label;
  }

  getTypeContext(instrument: Instrument): string {
    let context: string;

    if (instrument) {
      if (this.matter.isMatterProvinceMB) {
        context = (instrument.description ? instrument.description + ' ' : '') + instrument.type;
      } else {
        context = instrument.type;
      }
    }

    return context;

  }

  isUndertakingsDisabled(): boolean {
    return this.matter.isPurchase && !!this.matter.matterLink && this.instrumentArray.length > 0;
  }

  //used to improve performance in *ngFor trackBy function
  getInstrumentTrackId(index, inst: InstrumentWrapper) {
    return inst.id;
  }

  shouldDisplayUndertakingStatus(): boolean {
    return this.isRequisitionApplicable || this.matter?.isMatterProvinceAB || this.matter?.isMatterProvinceSK || this.matter?.isMatterProvinceMB || this.matter?.isMatterProvinceBC;
  }

  private isSpinBasedProvince() {
    return this.matter.isMatterProvinceAB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB || this.matter.isMatterProvinceBC;
  }

  fillInstrumentsBurgerMenuItemsForBC(instrumentWrapper: InstrumentWrapper): BurgerMenuExtendedItem[] {
    let instrumentBurgerMenuItems: BurgerMenuExtendedItem[] = [];

    if (this.undertakingStatus(instrumentWrapper.instrument)) {
      this.addToBurgerMenu(instrumentBurgerMenuItems, LtsaInstrumentMenuActions.EDIT, this.editUndertakingSpin);
      this.addToBurgerMenu(instrumentBurgerMenuItems, LtsaInstrumentMenuActions.DELETE, this.deleteUndertakingSpin);
    } else if (this.matter.isSale || this.matter.isMortgage) {
      this.addToBurgerMenu(instrumentBurgerMenuItems, LtsaInstrumentMenuActions.LEAVE, this.addAsPermittedRegistrationSpin);
      this.addToBurgerMenu(instrumentBurgerMenuItems, LtsaInstrumentMenuActions.REMOVE, this.importExistingMortgageDischargedSpin);
    } else {
      this.addToBurgerMenu(instrumentBurgerMenuItems, LtsaInstrumentMenuActions.LEAVE, this.addAsPermittedRegistrationSpin);
      this.addToBurgerMenu(instrumentBurgerMenuItems, LtsaInstrumentMenuActions.REMOVE, this.addAsUndertakingSpin);
    }
    return instrumentBurgerMenuItems;
  }
}
