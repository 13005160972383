export const constValues = {
  registryLt: {
    defaultType: 'QUESTION',
    registry: 'REGISTRY',
    landTitles: 'LANDTITLES'
  },

  registrationMethodsType: {
    electronic: 'Electronic',
    manual: 'Manual'
  },

  referredByType: {
    MANUAL: 'MANUAL_ENTRY'
  },

  yesNoMatterFile: {
    DEFAULT: 'DEFAULT_ACTIVE'
  },

  updateSnapshotCloseCode: {
    update: 'UPDATE_SNAPSHOT',
    clear: 'CLEAR_STALE_FLAG',
    cancel: 'CANCEL'
  },

  insurerOrBroker: {
    BROKER: 'BROKER',
    INSURER: 'INSURER'
  },

  precedent: {
    UNDERTAKING: 'UNDERTAKING'
  },

  netOutHst: {
    YES_FACTOR_IN_HST_REBATE: 'YES_FACTOR_IN_HST_REBATE',
    YES_DIVIDE_SALE_PRICE: 'YES_DIVIDE_SALE_PRICE'
  },

  commissionPaidTo: {
    BOTH_VENDOR_AND_PURCHASER_BROKER: 'BOTH_VENDOR_AND_PURCHASER_BROKER',
    VENDOR_BROKER_ONLY: 'VENDOR_BROKER_ONLY',
    PURCHASER_BROKER_ONLY: 'PURCHASER_BROKER_ONLY'
  },

  depositHeldBy: {
    VENDOR_BROKER: 'VENDOR_BROKER',
    PURCHASER_BROKER: 'PURCHASER_BROKER',
    VENDOR_LAWYER: 'VENDOR_LAWYER',
    PURCHASER_LAWYER: 'PURCHASER_LAWYER',
    BUILDER: 'BUILDER',
    DEVELOPER: 'DEVELOPER'
  },

  commissionDisbursedBy: {
    PURCHASER_LAWYER: 'PURCHASER_LAWYER',
    VENDOR_LAWYER: 'VENDOR_LAWYER'
  },

  commissionDisbursedTo: {
    BOTH_VENDOR_AND_PURCHASER_BROKER: 'BOTH_VENDOR_AND_PURCHASER_BROKER',
    VENDOR_BROKER_ONLY: 'VENDOR_BROKER_ONLY',
    PURCHASER_BROKER_ONLY: 'PURCHASER_BROKER_ONLY'
  },

  brokerReleaseExcessTo: {
    PURCHASER_LAWYER: 'PURCHASER_LAWYER',
    VENDOR_LAWYER: 'VENDOR_LAWYER',
    BUILDER: 'BUILDER',
    DEVELOPER: 'DEVELOPER'
  },

  titleInsuranceCompanies: {
    STEWART: 'Stewart Title Guaranty Company',
    FCT: 'FCT',
    CHICAGO: 'Chicago Title Insurance Company',
    CHICAGOSHORTNAME: 'Chicago Title',
    TitlePlus: 'TitlePLUS'
  }

};
