import {Component, OnInit} from '@angular/core';
import {socialLinks} from './social-links';

@Component({
  selector: 'social-links',
  templateUrl: 'social-links.component.html'
})

/*This is for social link on the login page.
*/
export class SocialLinksComponent implements OnInit {
  socialLinks: Object[] = socialLinks;

  constructor() {
  }

  ngOnInit() {
  }
}
