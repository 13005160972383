import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Router} from '@angular/router';
import {AuthNService, FieldError} from '../core';
import {HttpClient} from '../../app/core/httpClient.service';
import {UtilsService} from './utils.service';
import {TabsService} from '../core/tabs.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {DialogConfigParams, DialogService} from '../shared/dialog/dialog.service';
import {UpdateCredentialsModal} from './update-password/update-credentials.modal.component';
import {StaffProfilesService} from '../admin/staff-profiles/staff-profiles.service';
import {StaffProfiles} from '../admin/staff-profiles/staff-profiles';
import {PersonalDetailsModalComponent} from './personal-details/personal-details.modal.component';
import {Contact} from '../matters/shared/contact';
import {Subscription} from 'rxjs';
import {LockScreenService} from '../core/lock-screen.service';
import {WindowRef} from '../shared/window.ref';
import {AuthZService} from '../core/authz/auth-z.service';
import {AdminTab} from '../admin/admin-tab';
import {DocumentProfileService} from '../admin/document-profile/document-profile-edit/document-profile.service';
import {DocumentProfileCache} from '../shared-main/document-profile-cache.service';
import * as _ from 'lodash';
import {ManageThirdPartyCredentialsModal} from './manage-thirdparty-credentials/manage-thirdparty-credentials.modal.component';
import {Tab} from '../shared/tabbing/tab';
import {UUIDService} from './uuid.service';
import {UUIDUtil} from './uuid-util';
import {MatterParticipantService} from '../matters/matter-participant-service';
import {DpDropDownComponent} from '../shared-main/dropdown-menu/dropdown-menu.component';
import {AccountService} from '../admin/accounts/account.service';
import {Account, UserStatus} from '../admin/accounts/shared/account';
import {AdjudicationRequestRouteList} from '../adjudications/adjudication-list/adjudication-request';
import {ConfirmPasswordModalComponent} from './confirm-password-modal/confirm-password.modal.component';
import {User} from '../matters/shared';
import {dropDowns} from '../admin/accounts/shared/account-drop-downs';
import {contactTypeMapping} from '../contact/contact-type-mapping';
import {MessageService} from '../messages/message.service';
import {Observable} from 'rxjs/Observable';
import {ApplicationError} from '../core/application-error';
import {UserStateService} from '../shared-main/user-state/user-state.service';
import {ProjectMatterCacheService} from '../core/project-matter-cache.service';
import {SoajFieldCodeService} from '../shared-main/soaj-field-code.service';
import {NotifierService} from 'angular-notifier';
import {ErrorService} from '../shared/error-handling/error-service';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {OpportunitiesService} from '../opportunities/opportunities.service';
import {FieldCodeService} from '../shared-main/field-code.service';
import {UndertakingsConfigService} from '../admin/shared/undertaking-config.service';
import {AppearanceModalComponent} from './appearance/appearance.modal.component';
import {ProjectDocSharingNotifierService} from '../projects/shared/project-doc-sharing-notifier.service';
import {MatterRestrictedPeriodService} from '../core/matter-restricted-period.service';
import {NotificationOptOutService} from './notification-opt-out.service';
import {PublicHolidayService} from '../shared-main/public-holiday/public-holiday.service';
import {EventTab} from '../event/event-tab';
import {StewartAssystUser} from '../shared-main/telus/stewart-assyst-user';
import {TelusService} from '../shared-main/telus/telus-service';
import {AppConfig} from '../shared-main/app-configuration';
import {TCOLUser} from '../shared-main/tcol/tcol-user';
import {TCOLService} from '../shared-main/tcol/tcol-service';
import {WelcomePageModalComponent} from './welcome-page/welcome-page.modal.component';
import {Environment} from '../../environments/environment';
import {UnityBillingService} from '../billing/unity-billing-service';
import {WarningLevelModal} from '../billing/warning-level/warning-level.modal.component';
import {CustomerBillingPortalModal} from '../billing/customer-billing-portal/customer-billing-portal.modal.component';
import {LandingWidgetsService} from '../../../projects/landing-widgets/src/lib/landing-widgets.service';
import {AccountingModalComponent} from './accounting/accounting.modal.component';
import {CustomerSupportComponent} from './customer-support/customer-support.component';
import {OtherIntegrationServicesModalComponent} from '../integrations/other-integration-services.modal.component';
import {FeatureService} from '../feature-service';
import {ReferenceDataService} from '../shared-main/reference-data/reference-data.service';
import {ChatAIComponent} from '../main/chat-AI/chat-AI.component';
import {MatDialog} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {ChatAITermsConditionsComponent} from './chat-AI-terms-condition/chat-AI-terms-condition.component';
import {GoCardlessService} from '../gocardless/gocardless.service';
import {PaymentsService} from '../billing/billing-transactions/payments.service';
import {messages} from '../common';
import {accountApi} from '../admin/accounts/shared/account-api';
import {map} from 'rxjs/operators';
import {Permissions} from '../core/authz/permissions';
import {Roles} from '../core/authz/roles';
import Utils from "../shared-main/utils";
import {ProjectTab} from "../projects/shared/project-tab";

declare var jQuery: any;
/*This component will be loaded after login this is parent of all pages
 It has the main '<router-outlet></router-outlet>'(placeholder)
 Where all other page will be included.Main nav bar is  part of this compenet.
 All common pages will come under this component.*/
//Provided MatterParticipantService at the same level where TabsService is provided because otherwise
// angular is injecting incorrect instance of TabService in in MatterParticipantService
@Component({
  selector: 'dp-main',
  templateUrl: 'main.component.html',
  styleUrls: ['./main.styles.scss'],
  host: {
    '(document:keydown)': 'onDocumentKeyDown($event)',
    '(document:click)': 'onDocumentClick($event)'
  },
  providers: [
    AuthZService,
    DialogService,
    DocumentProfileCache,
    DocumentProfileService,
    FieldCodeService,
    GoCardlessService,
    MatterParticipantService,
    MortgageSoAdjService,
    OpportunitiesService,
    PaymentsService,
    ProjectDocSharingNotifierService,
    ProjectMatterCacheService,
    SoajFieldCodeService,
    UtilsService
  ]
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('notification') errorNotificationTemplate;

  dppmUserlogin: string;
  apiVersion: string;
  serverVersion: string = '';
  clientVersion: string = '';
  selectedOptionValue: string;
  displayDialog: boolean;
  authKeys: typeof Permissions;
  authRoles: typeof Roles;
  userStaffProfile: StaffProfiles;
  loggedInStaffProfile: Subscription;
  mainUnityUniSub: Subscription;
  dropdownCloserOnDocumentClick: Function;
  user: User;
  appcuesUserIdentification: boolean = false;
  lastClick: any;
  opportunitiesScheduler: number = 0;
  unityDriveInstallUrl: string;
  areActionType: string = 'Login';
  account: Account;
  isMarketingPageDown: boolean = false;
  waffleMenuLabel: string;
  showRobo: boolean = false;
  chatbotWndCloseFlag: boolean = false;
  termsConditionsWndCloseFlag: boolean = false;
  userAcceptedTnC: boolean = false;
  showToasterMessage: boolean = false;
  toasterMessageText: string = 'Hi, I’m DeeDee, your AI Assistant. How can I help?';
  adminAccess: boolean = false;
  hasPaymentProviderToken: boolean = false;
  checkPaymentProviderTokenSubscription: Subscription;

  subscriptionAI: Subscription;
  isAIAvailable: boolean = false;
  logoutText: string = 'Log out of Dye and Durham';
  stayInUnityText: string = 'Stay logged in';
  closeUPMText: string = 'Close Unity Practice Management';

  constructor(
    public router: Router,
    public accountService: AccountService,
    public authNService: AuthNService,
    public authZService: AuthZService,
    public utilsService: UtilsService,
    public tabsStateService: TabsService,
    public dialogService: DialogService,
    public viewContainerRef: ViewContainerRef,
    public staffProfilesService: StaffProfilesService,
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public lockScreenService: LockScreenService,
    public window: WindowRef,
    public documentProfileService: DocumentProfileService,
    public documentProfileCache: DocumentProfileCache,
    public uuidService: UUIDService,
    public userStateService: UserStateService,
    public messageService: MessageService,
    public errorService: ErrorService,
    public notifierService: NotifierService,
    public opportunitiesService: OpportunitiesService,
    public fieldCodeService: FieldCodeService,
    public matterRestrictedPeriodService: MatterRestrictedPeriodService,
    public notificationOptOutService: NotificationOptOutService,
    public publicHolidayService: PublicHolidayService,
    public tcolService: TCOLService,
    public unityBillingService: UnityBillingService,
    public featureService: FeatureService,
    public referenceDataService: ReferenceDataService,
    public dialog: MatDialog,
    private overlay: Overlay,
    public undertakingsConfigService: UndertakingsConfigService,
    public telusService: TelusService,
    public appConfig: AppConfig,
    public landingWidgetsService: LandingWidgetsService,
    public httpClient: HttpClient,
    public environment: Environment,
    public goCardlessService: GoCardlessService,
    public paymentsService: PaymentsService
  ) {
    this.dialogService.viewContainerRef = this.viewContainerRef;
    this.dialogService.rendererRef = this.renderer;
    this.dialogService.windowRef = this.window;
    this.dropdownCloserOnDocumentClick = renderer.listen('document', 'click', (event) => {
      DpDropDownComponent.activeInstance = null;
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.opportunitiesScheduler);
    if (this.loggedInStaffProfile) {
      this.loggedInStaffProfile.unsubscribe();
    }

    if (this.mainUnityUniSub) {
      this.mainUnityUniSub.unsubscribe();
    }

    if (this.subscriptionAI) {
      this.subscriptionAI.unsubscribe();
    }

    if (this.checkPaymentProviderTokenSubscription) {
      this.checkPaymentProviderTokenSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.errorService.errorNotificationTemplate = this.errorNotificationTemplate;
    let notifierTemplateLeftDistance = (window.innerWidth - jQuery('.nav-table').outerWidth()) / 2;
    this.notifierService.getConfig().position.horizontal.distance = notifierTemplateLeftDistance;
  }

  ngOnInit(): void {
    this.getUserTnCAcceptedFlag();
    if (!this.appConfig.getSystemConfiguration()) {
      this.referenceDataService.initializeAppConfig();
    }

    this.waffleMenuLabel = this.appConfig.enableGeneralEnhancementsPh1 ? 'Integrations' : 'Dye & Durham Suite';
    this.openUnityMarketingPage();
    sessionStorage.setItem('isFieldHarvestingModeOn', 'OFF');
    this.publicHolidayService.loadPublicHolidays();
    this.setAccountInUserStateService();
    this.loadMatterRestrictedTimePeriods();
    this.loadOptedOutEmails();
    this.fieldCodeService.getFieldCodeMappings();
    let sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId);
    let userId = Utils.getAuthenticatedUserId();
    let sessionUser: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
    if (sessionUser && sessionUser != null) {
      this.user = new User(JSON.parse(sessionUser));
      this.opportunitiesService.updateUnreadMessagesCount(this.user.numberOfUnreadMessages);
    }
    this.lockScreenService.lockForUpdate = false;
    this.authKeys = Permissions;
    this.authRoles = Roles;
    this.dppmUserlogin = sessionStorage.getItem(SESSION_STORAGE_KEYS.userLogin);

    this.getStaffProfile().subscribe();

    //Checking if service is set in UUIDUtil. This is injected at login normally. Need to re-inject in case of page refresh.
    if (!UUIDUtil.uuidService) {
      UUIDUtil.setService(this.uuidService);
      UUIDUtil.requestUUIDs();
    }

    this.undertakingsConfigService.getAllConfigs().subscribe();

    this.startOpportunitiesPolling();

    this.getUnityDriveDownloadLink();

    this.setAccount();

    this.checkIfPaymentProviderTokenExists();

    this.checkAIAvailability();

    this.adminAccess = this.hasAdminAccess();
  }

  checkIfPaymentProviderTokenExists(): void {
    const accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.checkPaymentProviderTokenSubscription = this.paymentsService
      .checkIfPaymentProviderTokenExists(accountId)
      .subscribe((response) => {
        this.hasPaymentProviderToken = response;
      });
  }

  checkAIAvailability(): void {
    const loginId = this.user.username;
    const accountId = this.user.publicAccountId;
    this.subscriptionAI = this.isAIEnabled(loginId, accountId).subscribe(
      (isEnabled) => {
        this.isAIAvailable = isEnabled;
        console.log('Is AI enabled:', this.isAIAvailable);
      },
      (error) => {
        console.error('Error checking AI availability:', error);
        this.isAIAvailable = false; // Handle error case by setting the property to false
      }
    );
  }

  private isAIEnabled(loginId: string, accountId: string): Observable<boolean> {
    const url: string = accountApi.getDeedeeSystemFlagConfig(loginId, accountId);
    return this.httpClient.get(this.httpClient.normalizeUrl(url)).pipe(
      map((res: boolean) => {
        if (res != null) {
          return res;
        }
      })
    );
  }

  getUserTnCAcceptedFlag() {
    this.utilsService.getTnCAcceptanceFlagForUser().subscribe((result: any) => {
      if (result && result.tc && result.tc.userAccepted) {
        this.userAcceptedTnC = true;
      } else {
        this.userAcceptedTnC = false;
      }
      this.showRobo = true;
    });
  }

  openAIChatbotTermsConditions(showTnC: boolean) {
    if (showTnC) {
      this.termsConditionsWndCloseFlag = false;
      this.showToasterMessage = false;
      if (!this.dialog.getDialogById('chatDeeDeeAITnC')) {
        const chatTnCModal = this.dialog.open(ChatAITermsConditionsComponent, {
          panelClass: 'custom-dialog-class-for-ChatAITermsConditions',
          id: 'chatDeeDeeAITnC',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
          hasBackdrop: false
        });

        chatTnCModal.beforeClosed().subscribe((result) => {
          if (result) {
            if (result.data === 'showAI') {
              !this.userAcceptedTnC ? this.saveTnCAcceptance() : '';
              this.openAIChatBot(true);
            } else if (result.data === 'closeTnC') {
              this.termsConditionsWndCloseFlag = true;
              !this.showToasterMessage ? this.showToaster(true) : '';
            }
          } else {
            this.termsConditionsWndCloseFlag = true;
            !this.showToasterMessage ? this.showToaster(true) : '';
          }
        });
      }
    } else {
      this.termsConditionsWndCloseFlag = true;
      this.dialog.getDialogById('chatDeeDeeAITnC')?.close({data: 'closeTnC'});
    }
  }

  openAIChatBot(showchatbot: boolean) {
    if (showchatbot) {
      this.showToasterMessage = false;
      this.chatbotWndCloseFlag = false;
      if (!this.dialog.getDialogById('chatDeeDeeAI')) {
        const chatModal = this.dialog.open(ChatAIComponent, {
          panelClass: 'custom-dialog-class-for-ChatAI',
          id: 'chatDeeDeeAI',
          scrollStrategy: this.overlay.scrollStrategies.noop(),
          hasBackdrop: false
        });

        const result = chatModal.componentInstance.onResize.subscribe((res) => {
          if (res.toLowerCase() === 'true') {
            chatModal.removePanelClass('custom-dialog-class-for-ChatAI');
            chatModal.addPanelClass('custom-dialog-class-for-ChatAI-minimized');
          } else {
            chatModal.removePanelClass('custom-dialog-class-for-ChatAI-minimized');
            chatModal.addPanelClass('custom-dialog-class-for-ChatAI');
          }
        });
        chatModal.beforeClosed().subscribe((result) => {
          this.chatbotWndCloseFlag = true;
          !this.showToasterMessage ? this.showToaster(true) : '';
        });
      }
    } else {
      this.chatbotWndCloseFlag = true;
      this.dialog.getDialogById('chatDeeDeeAI')?.close({data: 'closeAI'});
    }
  }

  showToaster(val: boolean) {
    if (val) {
      setTimeout(() => {
        this.showToasterMessage = true;
      }, 1000);
    }
  }

  closeToaster() {
    this.showToasterMessage = false;
  }

  saveTnCAcceptance() {
    this.utilsService.SaveTnCAcceptanceForUser().subscribe((result: any) => {
      if (result && result.tc && result.tc.userAccepted) {
        this.userAcceptedTnC = true;
      } else {
        this.userAcceptedTnC = false;
      }
    });
  }

  setAccountInUserStateService(): void {
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.accountService.getShallowAccount(id).subscribe((account: Account) => {
      this.userStateService.setAccount(new Account(account));
    });
  }

  loadMatterRestrictedTimePeriods(): void {
    if (this.authZService.hasFullAccessToConveyancingMatters()) {
      this.matterRestrictedPeriodService.loadMatterRestrictedTimePeriods();
    }
  }

  loadOptedOutEmails(): void {
    this.notificationOptOutService.loadOptOutEmails().subscribe();
  }

  setAccount(): void {
    let loggedUserId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.accountService.getAccount(loggedUserId).subscribe((account: Account) => {
      if (account) {
        this.account = new Account(account);
      }
    });
  }

  appcuesIdentifyUser(): void {
    if (!this.appcuesUserIdentification) {
      this.appcuesUserIdentification = true;
      this.userStateService.getAccount().subscribe((account: Account) => {
        if (account) {
          sessionStorage.setItem(SESSION_STORAGE_KEYS.accountDefaultProvinceCode, account.defaultProvinceCode);
        }

        let appCuesUserObject = {
          accountName: account.name,
          accountId: account.publicAccountId,
          accountType:
            account.accountTypeCode &&
            dropDowns.accountType.find((type) => {
              return type.value == account.accountTypeCode;
            }).label,
          firmName: account.firmName,
          defaultMatterProvince: account.defaultProvinceCode,
          paymentMethod:
            account.paymentMethodType &&
            dropDowns.paymentMethods.find((type) => {
              return type.value == account.paymentMethodType;
            }).label,
          unityApplicationTrialExpiryDate: this.getUnityExpiryDate(account),
          userType: this.userStaffProfile.accessProfileType,
          businessRole:
            this.userStaffProfile.contact &&
            this.userStaffProfile.contact.contactType &&
            contactTypeMapping.CONTACTTYPES.find((type) => {
              return type.contactType == this.userStaffProfile.contact.contactType;
            }).label,
          userLoginName: this.userStaffProfile.user.loginId,
          userFirstName:
            this.userStaffProfile.contact &&
            this.userStaffProfile.contact.contactName &&
            this.userStaffProfile.contact.contactName.firstName,
          userSurname:
            this.userStaffProfile.contact &&
            this.userStaffProfile.contact.contactName &&
            this.userStaffProfile.contact.contactName.lastName,
          emailAddress: this.userStaffProfile.user.emailId,
          CASLEmailOptIn: !this.userStaffProfile.caslInfo.caslEmailOptOut,
          userStatus: this.userStaffProfile.userStatus
        };
        console.log(appCuesUserObject);
        (<any>window).Appcues.identify(String(this.userStaffProfile.user.loginId), appCuesUserObject);
        this.initAppCueWidget();
      });
    }
    if (this.featureService.isSSOEnabled && this.userStaffProfile?.userStatus == UserStatus.SUSPENDED) {
      this.httpClient.logout();
    }
  }

  initAppCueWidget(): void {
    // This is your widget instance.
    let widget = (<any>window).AppcuesWidget((<any>window).Appcues.user());
    widget.init('#appcuesWidget', {
      // Optionally specify the position of the content relative to the widget icon.
      // Possible values: "center" (default; alias "bottom", "bottom-center"), "left" (alias "bottom-left"),
      // "right" (alias "bottom-right"), "top" (alias "top-center"), "top-left", "top-right"
      position: 'left'
      // Optionally add a header and/or footer.
      // header: "<h1>Tutorials</h1>",
      // footer: "<p>Your footer here</p>"
    });
  }

  getUnityExpiryDate(account: Account): string {
    let expiryDate = 'No Expiry Date';
    if (account.applications && account.applications.length) {
      let unityApplication = account.applications.find((app) => app.applicationName == 'UNITY_CONVEYANCING');
      if (unityApplication) {
        expiryDate = unityApplication.trailExpiryDate;
      }
    }
    return expiryDate;
  }

  getStaffProfile(): Observable<boolean> {
    let observable: Observable<boolean> = new Observable<boolean>((observer) => {
      if (this.loggedInStaffProfile) {
        this.loggedInStaffProfile.unsubscribe();
      }
      this.loggedInStaffProfile = this.staffProfilesService.getLoggedInStaffProfile().subscribe(
        (staffProfiles: StaffProfiles) => {
          if (staffProfiles) {
            this.userStaffProfile = staffProfiles;
            sessionStorage.setItem(SESSION_STORAGE_KEYS.userLogin, this.userStaffProfile.user.loginId);
            this.dppmUserlogin = this.userStaffProfile.user.loginId;
            this.appcuesIdentifyUser();
            observer.next(true);
          }
        },
        (error: ApplicationError) => {
          observer.next(false);
        }
      );
    });
    return observable;
  }

  get isOntarioIncluded(): boolean {
    return this.userStaffProfile && this.userStaffProfile.user && this.userStaffProfile.user.isOntarioIncluded();
  }

  compareClientVersionToApiVersion() {
    this.utilsService.compareClientVersionToApiVersion(this.clientVersion, this.serverVersion);
  }

  // On Logot reset the authentication details in service and redirect to login page
  logout(): void {
    let logoutTitle: string = 'Are you sure you want to log out?';
    let dirtyTab = _.find(this.tabsStateService.tabsCache, (tab) => tab.isDirty());

    if (dirtyTab) {
      console.warn('dirtyTab=', dirtyTab);
      this.dialogService
        .confirm(logoutTitle, messages.logout.UNSAVED, false, this.logoutText, this.stayInUnityText, true)
        .subscribe((res) => {
          if (res) {
            this.doLogout();
          }
        });
    } else {
      let msg: string = this.featureService.isSSOEnabled ? messages.logout.SAVEDSSO : messages.logout.SAVED;
      this.dialogService
        .confirm(logoutTitle, msg, false, this.logoutText, this.stayInUnityText, true)
        .subscribe((res) => {
          if (res) {
            this.doLogout();
          }
        });
    }
  }

  doLogout() {
    //TODO Create a centralized place to clean up the cache
    //For now any cleanup to be added here and in the login component cleanUpBrowserSession()
    this.httpClient.logout();
    this.cleanUpForLogout();
    if (!this.featureService.isSSOEnabled) {
      this.router.navigate(['/login/home']);
    }
    // for Keycloak and R3.7 we dont need Deedee AI, so clear session for AI is not needed ,
    // since it will give 401, after we fix AI enabled bug, we could recomment back this line
  }

  closeAIModal() {
    this.dialog.closeAll();
    this.utilsService
      .ClearAIChatSession()
      .take(1)
      .subscribe((result) => {});
  }

  openUnityUniversity() {
    this.mainUnityUniSub = this.utilsService
      .getDoceboSamlSSO()
      .take(1)
      .subscribe(
        (data) => {
          if (data && data.doceboSamlEndpoint && data.doceboSamlPayload) {
            this.createUnityUniversityForm(data.doceboSamlEndpoint, data.doceboSamlPayload);
          }
        },
        (error) => {},
        () => {
          if (this.mainUnityUniSub) {
            this.mainUnityUniSub.unsubscribe();
          }
        }
      );
  }

  private createUnityUniversityForm(doceboSamlEndpoint: string, doceboSamlPayload: string) {
    let form: HTMLFormElement = document.createElement('form');
    form.style.display = 'none';
    form.target = 'view';
    form.method = 'POST';
    form.action = doceboSamlEndpoint;

    let input: HTMLInputElement = document.createElement('input');
    input.type = 'hidden';
    input.name = 'SAMLResponse';
    input.value = doceboSamlPayload;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  cleanUpForLogout(): void {
    this.staffProfilesService.cleanCachedLoggedInStaffProfile();
    this.authZService.removeAuthenticatedUser();
    this.tabsStateService.clearAllTabs();
    this.userStateService.clearUserStat();
    this.clearOpportunitySchedulerInterval();
    this.closeAIModal();
  }

  clearOpportunitySchedulerInterval(): void {
    clearInterval(this.opportunitiesScheduler);
  }

  getMessageCount(): number {
    return this.opportunitiesService && this.opportunitiesService.unreadMessagesCount;
  }

  getDoceboConfiguration(): void {
    this.loggedInStaffProfile = this.utilsService.getDoceboConfiguration().subscribe((doceboSamlSSO: any) => {
      if (doceboSamlSSO) {
        console.log(doceboSamlSSO);
        this.openWindowWithPost(doceboSamlSSO.doceboSamlEndpoint, doceboSamlSSO.doceboSamlPayload);
      }
    });
  }

  openWindowWithPost(url, data): void {
    var form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = url;
    form.style.display = 'none';

    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'SAMLResponse';
    input.value = data;
    form.appendChild(input);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  activateAdminRouteFromMain() {
    if (this.anchorTab && this.anchorTab.isAdmin() && this.isAdminAnchorTabFromAccountList) {
      if (this.tabsStateService.openTabs && this.tabsStateService.openTabs.length > 1) {
        this.dialogService.confirm('Information', 'To proceed, all active tabs must first be closed.', true);
      } else {
        this.tabsStateService.clearAllTabs();
        let tab: AdminTab = new AdminTab();
        tab.tabSubType = 'anchor';
        tab.type = 'Account Administration';
        tab.tabType = 'admin';
        tab.route = 'main/admin/admin-home';
        this.tabsStateService.openTab(tab);
      }
    } else {
      this.tabsStateService.activateAdminAppRoute(['./main/admin']);
    }
  }

  activateBillingRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isBilling())) {
      this.tabsStateService.activateAppRoute(['main/billing'], true);
    } else {
      this.tabsStateService.openTab(this.anchorTab);
    }
  }

  activateCustomerBillingPortalUrl() {
    this.lockScreenService.lockForUpdate = true;
    this.unityBillingService.getCustomerBillingPortalUrl().subscribe(
      (customerBillingPortalUrl) => {
        this.lockScreenService.lockForUpdate = false;
        this.dialogService.matDialogContent({
          content: CustomerBillingPortalModal,
          fullScreen: true,
          widthXl: true,
          context: {
            url: customerBillingPortalUrl
          }
        });
      },
      (error) => {
        if (error) {
          this.lockScreenService.lockForUpdate = false;
          this.dialogService.confirm(
            'Warning',
            'The billing page is currently not accessible, please contact customer service 1-866-367-7648 or send an e-mail to unity.support@dyedurham.com',
            true,
            'OK'
          );
        }
      }
    );
  }

  openSetWarningLevel(): void {
    this.dialogService.matDialogContent({
      content: WarningLevelModal,
      context: {
        account: this.account,
        modalGrid: '5'
      },
      modalGrid: 5
    });
  }

  activateIntegrationsRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isIntegrations())) {
      this.tabsStateService.activateAppRoute(['./main/integrations/home'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateAccountAdminRouteFromMain() {
    this.tabsStateService.activateAdminAppRoute(['./main/admin/list'], true);
  }

  activateMatterListRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isMatter())) {
      if (this.router.url.indexOf('/main/tabs/matter/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createMatterAnchorTab());
      }

      this.tabsStateService.activateAppRoute(['./main/tabs/matters'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateDepositManagerRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isDepositManager())) {
      if (this.router.url.indexOf('/main/deposit-manager/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createDepositManagerTab());
      }
      this.tabsStateService.activateAppRoute(['./main/deposit-manager/list'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateProjectListRouteFromMain() {
    if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isProject())) {
      if (this.router.url.indexOf('/main/projects/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createProjectAnchorTab());
      }
      this.tabsStateService.activateAppRoute(['./main/projects/list'], true);
    } else {
      this.tabsStateService.openTab(this.anchorTab);
    }
  }

  activateExportFileManagerRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isExportFileManager())) {
      if (this.router.url.indexOf('/main/export-file-manager/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createExportFileManagerAnchorTab());
      }
      this.tabsStateService.activateAppRoute(['./main/export-file-manager/list'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateContactListRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isContact())) {
      if (this.router.url.indexOf('/main/contacts/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createContactAnchorTab());
      }
      this.tabsStateService.activateAppRoute(['./main/contacts/list'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateEventListRouteFromMain(isAvailabilityView: boolean) {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (
      !this.anchorTab ||
      (this.anchorTab && !this.anchorTab.isEvents()) ||
      (isAvailabilityView && this.router.url.indexOf('eventLanding=List') > -1) ||
      (!isAvailabilityView && this.router.url.indexOf('eventLanding=staff') > -1)
    ) {
      if (this.router.url.indexOf('/main/events/') > -1) {
        this.tabsStateService.removeAnchorTab();
        let tab: EventTab = new EventTab();
        tab.type = 'event';
        tab.tabSubType = 'anchor';
        tab.tabType = 'event';
        tab.tabDisplayLabel = isAvailabilityView ? 'Staff Availability View' : 'Events';
        tab.route = '/main/events/list';
        tab.queryParams = {landing: true, eventLanding: isAvailabilityView ? 'staff' : 'List'};
        this.tabsStateService.openTab(tab);
        this.tabsStateService.activateAppRoute(['./main/events/list'], true, {
          landing: true,
          eventLanding: isAvailabilityView ? 'staff' : 'List'
        });
      } else {
        this.tabsStateService.activateAppRoute(['./main/events/list'], true, {
          landing: false,
          eventLanding: isAvailabilityView ? 'staff' : 'List'
        });
      }
    } else {
      let queryParams = this.anchorTab instanceof EventTab ? (this.anchorTab as EventTab).queryParams : undefined;
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab, queryParams);
      });
    }
  }

  activateDayAtGlanceRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isDayAtGlance())) {
      if (this.router.url.indexOf('/main/events/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createDayAtGlanceAnchorTab());
      }
      this.tabsStateService.activateAppRoute(['./main/events/dayAtGlance'], true, {
        landing: true,
        eventLanding: 'List'
      });
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateMessagesListRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isMessages())) {
      this.tabsStateService.activateAppRoute(['./main/messages/list'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateAdjudicationListRouteFromMain() {
    if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isAdjudication())) {
      this.tabsStateService.activateAppRoute(['./' + AdjudicationRequestRouteList], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateDocServicesRouteFromMain() {
    if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isDocServices())) {
      this.tabsStateService.activateAppRoute(['./main/doc-services/home'], true);
    } else {
      this.tabsStateService.openTab(this.anchorTab);
    }
  }

  activateOpportunitiesRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isOpportunities())) {
      if (this.router.url.indexOf('/main/opportunities/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createOpportunitiesAnchorTab());
      }
      this.tabsStateService.activateAppRoute(['./main/opportunities/list'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  activateOpportunityMatterRouteFromMain() {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isOpportunityMatter())) {
      if (this.router.url.indexOf('/main/opportunity-matter/') > -1) {
        this.tabsStateService.removeAnchorTab();
        this.tabsStateService.openTab(Tab.createOpportunityMatterAnchorTab());
      }
      this.tabsStateService.activateAppRoute(['./main/opportunity-matter/list'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  get anchorTab(): Tab {
    return this.tabsStateService.anchorTab;
  }

  get activeNav(): string {
    let url: string = this.router.url;
    if (url.indexOf('/main/contacts/') > -1) {
      return 'CONTACTS';
    } else if (url.indexOf('/main/tabs/matters') > -1 || url.indexOf('/main/tabs/matter') > -1) {
      return 'MATTERS';
    } else if (url.indexOf('/main/integrations') > -1) {
      return 'INTEGRATIONS';
    } else if (url.indexOf('/main/projects/') > -1) {
      return 'PROJECTS';
    } else if (url.indexOf('/main/export-file-manager/') > -1) {
      return 'EXPORT_FILE_MANAGER';
    } else if (url.indexOf('/main/events') > -1) {
      return 'EVENTS';
    } else if (url.indexOf('/main/adjudication') > -1) {
      return 'MANAGE_ADJUDICATION';
    } else if (url.indexOf('/main/billing') > -1) {
      return 'BILLING';
    } else if (url.indexOf('/main/doc-services') > -1) {
      return 'DOC-SERVICES';
    } else if (url.indexOf('/main/admin') > -1) {
      return 'ADMIN';
    } else if (url.indexOf('/main/messages') > -1) {
      return 'MESSAGES';
    } else if (url.indexOf('/main/deposit-manager') > -1) {
      return 'DEPOSIT_MANAGER';
    } else if (url.indexOf('/main/opportunities/') > -1) {
      return 'OPPORTUNITIES';
    } else if (url.indexOf('/main/opportunity-matter/') > -1) {
      return 'OPPORTUNITY_MATTER';
    } else {
      return '';
    }
  }

  get isAdminAnchorTabFromAccountList(): boolean {
    if (
      this.tabsStateService.anchorTab &&
      this.tabsStateService.isAdminTabOpen() &&
      this.tabsStateService.anchorTab instanceof AdminTab
    ) {
      let adminTab = this.tabsStateService.anchorTab as AdminTab;
      return adminTab && adminTab.isTabOpenFromAccountList();
    } else {
      return false;
    }
  }

  // if user click on the Esc key then this method will be called and close the popup window.
  public closeOnEsc(type): void {
    let evt: any = window.event ? event : null;

    if (evt) {
      let charCode = evt.charCode ? evt.charCode : evt.keyCode ? evt.keyCode : evt.which ? evt.which : 0;

      /** When ESC key is pressed **/
      if (charCode === 27) {
        this.displayDialog = type == 'parent' ? false : true;
      }
    }
  }

  onDocumentClick($event: KeyboardEvent) {
    // Adding this solution Temporary to prevent double Click issue when assigning emp to matter from integration.
    // TODO: NEED TO IMPLEMENT SOME GLOBAL SOLUTION FOR DOUBLE CLICK ISSUE.
    // console.log('-------------------------------------------------')
    // console.log( "onDocClick===>" + $event.srcElement.innerHTML + ' ---------> class=' + $event.srcElement.className);
    // console.log('-------------------------------------------------')
    if (jQuery($event.target).hasClass('preventDoubleClick')) {
      // console.log($event.srcElement.outerHTML);
      jQuery($event.srcElement).css('pointer-events', 'none');
      setTimeout(() => jQuery($event.srcElement).css('pointer-events', 'auto'), 1000);
    }
  }

  onDocumentKeyDown($event: KeyboardEvent) {
    if ($event.ctrlKey || $event.metaKey) {
      let charCode = String.fromCharCode($event.which).toLowerCase();
      if (charCode === 's') {
        $event.preventDefault();
        $event.stopPropagation();
        //Get the element that has focus
        let focusedElem = this.elementRef.nativeElement.ownerDocument.activeElement;
        let saveBtn = this.elementRef.nativeElement.querySelector('button#saveBtn');
        console.log('CTRL-S saveBtn.disabled=', saveBtn ? saveBtn.disabled : '');
        if (saveBtn && !saveBtn.disabled) {
          //Set the focus on the save button to invoke blur actions on all the active element
          saveBtn.focus();
          if (this.userStateService.APIRequest) {
            this.userStateService.APIRequest.subscribe((saveFlag: boolean) => {
              if (saveFlag && !this.lockScreenService.lockForUpdate) {
                this.requestMatterSave(saveBtn, focusedElem);
              }
              this.userStateService.APIRequest = null;
            });
          } else if (!this.lockScreenService.lockForUpdate) {
            this.requestMatterSave(saveBtn, focusedElem);
          }
        }
      }
    }
    if ($event.which === 9) {
      let isModalDialogParent = jQuery($event.target).parents('.mat-modal-dialog');
      if (
        (!isModalDialogParent || (isModalDialogParent && isModalDialogParent.length < 1)) &&
        jQuery('.mat-modal-dialog') &&
        jQuery('.mat-modal-dialog').length > 0
      ) {
        $event.preventDefault();
        $event.stopPropagation();
        let focusFirstElement = jQuery('.mat-modal-dialog:last').find('.focus-first').filter(':visible:first');
        jQuery('.mat-modal-dialog:last')
          .find('input[type=text],dp-checkbox label,textarea,select')
          .filter(':visible:first')
          .focus();
      }
      if (
        $event.shiftKey &&
        jQuery($event.target).hasClass('close') &&
        jQuery('.mat-modal-dialog') &&
        jQuery('.mat-modal-dialog').length > 0
      ) {
        $event.preventDefault();
        $event.stopPropagation();
        jQuery('.mat-modal-dialog:last')
          .find('input[type=text],dp-checkbox label,textarea,select')
          .filter(':visible:first')
          .focus();
      }
    }
    if ($event.which === 13) {
      console.warn('main | onDocumentKeyDown | ENTER | target: ', $event.target);
      let className: string = $event.target['className'] || '';
      if (className.indexOf('ui-autocomplete-input') !== -1) {
        return;
      }
      let okBtn: Element = document.body.querySelector('.mat-modal-dialog button#okBtnConfirm');
      if (
        $event.target['type'] === 'text' ||
        $event.target['type'] === 'password' ||
        $event.target['type'] === 'number'
      ) {
        // this case when more than one popup stacked on top of each other. We get all the btns with ids start with "okBtn"
        let okBtnArray = document.body.querySelectorAll('.mat-modal-dialog [id^="okBtn"]');
        if (okBtnArray && okBtnArray.length) {
          okBtn = okBtnArray[okBtnArray.length - 1];
        }
      }
      if (okBtn) {
        jQuery(okBtn).focus();
        okBtn.dispatchEvent(new CustomEvent('click'));
        event.preventDefault();
        $event.stopPropagation();
      }
    }

    if ($event.which === 8) {
      if ($event.target['type'] === 'text' || 'password' || 'number' || 'textarea') {
        let isReadOnly =
          $event.srcElement !== undefined
            ? (<HTMLInputElement>$event.srcElement).readOnly
            : (<HTMLInputElement>$event.target).readOnly;

        if (isReadOnly) {
          $event.preventDefault();
          $event.stopPropagation();
        }
      } else {
        $event.preventDefault();
      }
    }
    if ($event.ctrlKey && $event.keyCode == 122) {
      if (sessionStorage.getItem('isFieldHarvestingModeOn') == 'OFF') {
        sessionStorage.setItem('isFieldHarvestingModeOn', 'ON');
      } else {
        sessionStorage.setItem('isFieldHarvestingModeOn', 'OFF');
      }
    }
    if ($event.shiftKey && $event.ctrlKey && $event.altKey && $event.keyCode == 69 /*E*/) {
      this.areActionType = this.areActionType == 'Login' ? 'Profile' : 'Login';
    }
  }

  get harvestingMode(): string {
    return sessionStorage && sessionStorage.getItem('isFieldHarvestingModeOn') == 'ON' ? ' / HARVESTING MODE: ON ' : '';
  }

  requestMatterSave(saveBtn: any, focusedElem: any): void {
    console.log('       saving..');
    saveBtn.dispatchEvent(new CustomEvent('click'));
    //Set the focus back to the prviously focued element.
    focusedElem.focus();
  }

  confirmPasswordModal(): void {
    if (this.userStaffProfile) {
      this.dialogService.matDialogContent({
        content: ConfirmPasswordModalComponent,
        context: {
          user: this.userStaffProfile.user
        },
        modalGrid: 5,
        onFulfillment: (response: any) => {
          if (response) {
            this.openMyCredentialsModal();
          }
          if (response && response.error) {
            this.logout();
          }
        }
      });
    }
  }

  openMyCredentialsModal(): void {
    if (this.userStaffProfile) {
      this.dialogService.matDialogContent({
        content: UpdateCredentialsModal,
        context: {
          user: this.userStaffProfile.user
        },
        onFulfillment: (response: any) => {
          if (response && response.error) {
            this.logout();
          } else {
            this.getStaffProfile().subscribe();
          }
        },
        onRejection: () => {
          this.getStaffProfile().subscribe();
        }
      });
    }
  }

  openUnitySystemStatusLink() {
    var windowObject = window.open(this.utilsService.getUnityStatusUrl(), '_blank');
  }

  openManageThirdPartyCredentials(thirdPartKey?: string): void {
    this.staffProfilesService.getCachedLoggedInStaffProfile().subscribe((staffProfile: StaffProfiles) => {
      this.dialogService.matDialogContent({
        content: ManageThirdPartyCredentialsModal,
        context: {
          accountId: sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId),
          userId: Utils.getAuthenticatedUserId(),
          staffProfile: staffProfile,
          key: thirdPartKey
        },
        onFulfillment: (result: any) => {
          if (result && result.validateUser) {
            if (!!result.validateUser.isTitlePlusUserValidated) {
              result.validateUser.usersValidated.forEach((userId) => {
                this.errorService.removeDpThirdPartyNotification(
                  'integrations.titlePlus.validCredentials.' + String(userId)
                );
              });
            }
          }
        }
      });
    });
  }

  openCustomerSupport(): void {
    this.dialogService.matDialogContent({
      content: CustomerSupportComponent,
      onFulfillment: (result: any) => {}
    });
  }

  openPersonalDetailsModal(): void {
    this.getStaffProfile().subscribe((updatedStaffProfile: boolean) => {
      this.userStateService.getAccount().subscribe((account: Account) => {
        if (this.userStaffProfile && this.userStaffProfile.contact) {
          this.dialogService.matDialogContent({
            content: PersonalDetailsModalComponent,
            fullScreen: true,
            context: {
              userStaffProfile: this.userStaffProfile,
              staffProfileAddressDd: this.staffProfilesService.createStaffProfileAddressCopyOptions(account),
              tabsService: this.tabsStateService,
              account: account
              /// TODO: tabsService injected from main component because of different instance in dialog modal constructor
            },
            onFulfillment: (result: any) => {
              if (result && result.contact) {
                this.userStaffProfile.contact = new Contact(result.contact);
              }
            }
          });
        }
      });
    });
  }

  hasSystemRole(): boolean {
    return (
      this.authZService.hasRole(Roles.ROLE_SYSTEM_ADMINISTRATOR) || this.authZService.hasRole(Roles.ROLE_SYSTEM_USER)
    );
  }

  hasAdminAccess(): boolean {
    return this.authZService.hasAdminAccess();
  }

  hasContactManagementAccess(): boolean {
    return this.authZService.hasAccess(this.authKeys.CONTACT_MANAGEMENT);
  }

  hasEventAccess(): boolean {
    return this.authZService.hasAccess(this.authKeys.EVENTS);
  }

  hasProjectsAccess(): boolean {
    return this.authZService.hasAccess(this.authKeys.PROJECTS);
  }

  hasProjectsFullAccess(): boolean {
    return this.authZService.hasFullAccessToProjects();
  }

  hasERegAccess(): boolean {
    return this.authZService.hasAccess(this.authKeys.PROJECTS) && this.authZService.userHasAccessToOntarioFeatures();
  }

  hasManageGlobalContactsAccess(): boolean {
    return this.authZService.hasAccess(this.authKeys.MANAGE_GLOBAL_CONTACTS);
  }

  hasOpportunitiesAccess(): boolean {
    return this.authZService.hasAccess(this.authKeys.OPPORTUNITIES); // Opportunities not UnityC
  }

  @HostListener('window:beforeunload', [ '$event' ])
  onWindowBeforeUnload(event) {
    console.log('window.onbeforeunload | event:', event);
    let dirtyTab = _.find(this.tabsStateService.tabsCache, (tab) => tab.isDirty());
    let projectDocSharingNotifier = this.tabsStateService.tabsCache.find(item => item.isProject() && !!(item as ProjectTab).showSharingNotifier && item.isActive());

    //Case for setup-payment-info-modal
    if (jQuery('.mat-modal-dialog dp-setup-payment-info-modal-content').length > 0) {
      this.closeModal();
      return false;
    }
    if (jQuery('.mat-modal-dialog .ignore-unsaved-leave-prompt').length > 0) {
      return null;
    } else {

      if (dirtyTab || this.isModalOpen() || projectDocSharingNotifier) {
        event.returnValue = 'You have unsaved changes that will be lost if you leave. Do you wish to:';
      }
    }

  }

  isModalOpen(): boolean {
    // Chrome unexpectedly triggers the 'beforeunload' event,
    // when opening produced document in a separate Word instance.
    // Therefore we need to ignore the case when Supplemental Task modal is open,
    // so that the browser does not give unnecessary warnings about "unsaved changes"
    if (jQuery('.mat-modal-dialog dp-matter-supplemental-task-container-modal').length > 0) {
      return false;
    }

    return jQuery('.mat-modal-dialog').length > 0;
  }

  closeModal() {
    //find Close button
    let closeBtn: Element = document.body.querySelector('.mat-modal-dialog button.close');
    if (closeBtn) {
      //click on it
      closeBtn.dispatchEvent(new CustomEvent('click'));
    }
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    // In IE there is an issue in scrolling when a focused input element is behind  .matter-title element a blinking cursor appears above

    // even though the element is behind this layer

    // this block of code checks if an active element is touching with the fixed header and then blurs its focus

    // first check if browser is IE
    if (this.window.nativeWindow.document.documentMode && this.window.nativeWindow.document.documentMode < 12) {
      /// elements we want to check if they collide with .matter-title
      let inputs = ['input', 'select', 'button', 'textarea'];
      /// check if we have an active element and the element is one of the inputs in the list above
      if (
        this.window.nativeWindow.document.activeElement &&
        inputs.indexOf(this.window.nativeWindow.document.activeElement.tagName.toLowerCase()) !== -1
      ) {
        // checks if the active element is colliding with the fixed .matter-title.
        if (
          jQuery('.matter-title').offset().top <
          jQuery(this.window.nativeWindow.document.activeElement).offset().top - jQuery('.matter-title').outerHeight()
        ) {
          /// does not collide so don't do anything
        } else {
          // element collides with matter title so we blur the field
          jQuery(this.window.nativeWindow.document.activeElement).blur();
        }
      }
    }

    // this.closeAnyOpenDropdownMenu(); //Disabling this so we allow bigger dropdowns to be scrolled into view
  }

  closeAnyOpenDropdownMenu(): void {
    DpDropDownComponent.activeInstance = null;
  }

  openNotificationFooter(): void {
    this.errorService.openErrorFooterNotification();
  }

  closeNotificationBubble(notificationId: string) {
    this.errorService.closeNotificationBubble(notificationId);
  }

  startOpportunitiesPolling(): void {
    if (!this.hasSystemRole()) {
      this.getOpportunities();
      setTimeout(() => {
        this.opportunitiesScheduler = window.setInterval(() => {
          this.getOpportunities();
        }, 60000);
      }, 3000);
    }
  }

  async getOpportunities(): Promise<void> {
    await this.opportunitiesService.getNumberOfActiveOpportunities().toPromise();
  }

  getActiveOpportunities() {
    return this.opportunitiesService.activeOpportunities;
  }

  openAppearanceModal(): void {
    this.dialogService.matDialogContent({
      content: AppearanceModalComponent,
      context: {
        tabsService: this.tabsStateService
      },
      onFulfillment: (result: any) => {}
    });
  }

  hasUnityDriveAccess(): boolean {
    return this.userStateService.isUnityDriveAvailable();
  }

  async downloadUnityDriveClient() {
    let installUrl = await this.utilsService.getUnityDriveInstallUrl().toPromise();
    if (installUrl) {
      window.open(installUrl, '_blank');
    }
  }

  async getUnityDriveDownloadLink() {
    this.unityDriveInstallUrl = await this.utilsService.getUnityDriveInstallUrl().toPromise();
  }

  isProjectsMenuVisible(): boolean {
    return this.hasProjectsAccess() || this.hasProjectsFullAccess() || this.hasERegAccess();
  }

  isProjectRoutesActive(): boolean {
    return (
      this.activeNav == 'PROJECTS' || this.activeNav == 'DEPOSIT_MANAGER' || this.activeNav == 'EXPORT_FILE_MANAGER'
    );
  }

  isEventsRoutesActive(): boolean {
    return this.activeNav == 'EVENTS' || this.activeNav == 'DAY_AT_GLANCE';
  }

  openNewMortgageInstructions(): void {
    if (this.tabsStateService.massUpdateTab) {
      this.dialogService.confirm('Information', 'To proceed, you need to exit mass update', true);
    } else if (!this.anchorTab || (this.anchorTab && !this.anchorTab.isMortgageInstrucing())) {
      this.tabsStateService.activateAppRoute(['./main/integrations/mortgage-instructions'], true);
    } else {
      this.tabsStateService.checkForProjectNotifierMessages(() => {
        this.tabsStateService.openTab(this.anchorTab);
      });
    }
  }

  openOtherIntegrationService(): void {
    this.dialogService.matDialogContent({
      content: OtherIntegrationServicesModalComponent,
      context: {},
      onFulfillment: (staffProfiles: any) => {
        console.log(staffProfiles);
      }
    });
  }

  openAssyst(): void {
    this.lockScreenService.lockForUpdate = true;
    this.telusService.getTelusUser().subscribe(
      (telusUser: StewartAssystUser) => {
        if (telusUser && telusUser.id) {
          this.openAssystWebSSOUrl(telusUser);
        } else {
          this.openAssystUserNotFoundModal();
        }
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.stewartAssystUserNotFound') {
          this.openAssystUserNotFoundModal();
        } else {
          this.lockScreenService.lockForUpdate = false;
          this.dialogService.confirm('Error', error.message + ' (' + error.errorCode + ').', true, 'OK').subscribe();
        }
      }
    );
  }

  openAssystWebSSOUrl(telusUser: StewartAssystUser): void {
    let actionType = !!this.areActionType ? this.areActionType : 'Login';
    this.telusService.getTelusWebUrl(telusUser.id, actionType).subscribe(
      (url) => {
        this.lockScreenService.lockForUpdate = false;
        window.open(url);
      },
      (error: ApplicationError) => {
        this.lockScreenService.lockForUpdate = false;
        if (error && error.errorCode) {
          if (error.errorCode === 'app.invalidFieldsDataError' && error.fieldErrors.length > 0) {
            let errorMessage: string = '';
            error.fieldErrors.forEach((fieldError: FieldError) => {
              errorMessage = errorMessage + fieldError.message;
            });

            this.dialogService.confirm('Error', errorMessage, true, 'OK').subscribe((res) => {
              if (res) {
              }
            });
          } else {
            this.dialogService
              .confirm('Error', error.message + ' (' + error.errorCode + ').', true, 'OK')
              .subscribe((res) => {
                if (res) {
                }
              });
          }
        }
      }
    );
  }

    openAssystUserNotFoundModal() : void {
        this.lockScreenService.lockForUpdate = false;
        const contentModalConfig : DialogConfigParams = {
            title                : 'Unity® Lender Centre',
            message              : 'Unity® Lender Centre credentials missing for your profile.  Please add credentials or signup with Unity® Lender Centre.',
            hideCancelBtn        : false,
            customConfirmBtnText     : 'Sign-up',
            customBtnText : 'Add Credentials',
            customReturnText : 'ADD_CREDS'

    };
    this.dialogService.confirmCustomDialog(contentModalConfig).subscribe((res: any) => {
      if (res && res == 'ADD_CREDS') {
        this.openManageThirdPartyCredentials('ARE');
      } else if (!!res) {
        window.open('https://login.assystrealestate.com/onboarding');
      }
    });
  }

  get isAssystPayoutEnabled(): boolean {
    return this.appConfig && this.appConfig.isStewartAssystSSOEnabled;
  }

  get isTcolEnabled(): boolean {
    return this.isOntarioIncluded && this.appConfig && this.appConfig.isTcolEnabled;
  }

  openTaxCertificates(): void {
    this.lockScreenService.lockForUpdate = true;
    this.tcolService.getTCOLUser().subscribe(
      (tcolUser: TCOLUser) => {
        if (tcolUser && tcolUser.id) {
          this.openTcoltWebUrl(tcolUser.id);
        } else {
          this.openTaxCertificatesNotFoundModal();
        }
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.tcolUserNotFound') {
          this.openTaxCertificatesNotFoundModal();
        } else {
          this.lockScreenService.lockForUpdate = false;
          this.dialogService.confirm('Error', error.message + ' (' + error.errorCode + ').', true, 'OK').subscribe();
        }
      }
    );
  }

  openTcoltWebUrl(userId: number): void {
    this.tcolService.getTcolWebUrl(userId).subscribe(
      (url) => {
        this.lockScreenService.lockForUpdate = false;
        window.open(url);
      },
      (error: ApplicationError) => {
        this.lockScreenService.lockForUpdate = false;
        if (error && error.errorCode) {
          if (error.errorCode === 'app.invalidFieldsDataError' && error.fieldErrors.length > 0) {
            let errorMessage: string = '';
            error.fieldErrors.forEach((fieldError: FieldError) => {
              errorMessage = errorMessage + fieldError.message;
            });

            this.dialogService.confirm('Error', errorMessage, true, 'OK').subscribe();
          } else {
            this.dialogService
              .confirm(
                'Error',
                'Failed to login TCOL website. Error: ' + error.message + ' (' + error.errorCode + ').',
                true,
                'OK'
              )
              .subscribe();
          }
        } else {
          this.dialogService.confirm('Error', 'Failed to login TCOL website.', true, 'OK').subscribe();
        }
      }
    );
  }

  goCardlessSignup() {
    this.goCardlessService.authorize();
  }

  revokeGoCardlessSignup() {
    const accountId = this.account.id;
    this.dialogService
      .confirm(
        'Confirm GoCardless Token Revoke',
        'Revoking the token will disable GoCardless payments for your firm. Are you sure you want to revoke the token?',
        false,
        'Continue',
        'Cancel'
      )
      .subscribe((revokeToken) => {
        if (revokeToken) {
          this.goCardlessService.revokedAccessToken(accountId).subscribe({
            next: (_) => {
              this.hasPaymentProviderToken = false;
              this.paymentsService.clearToken();
              // TODO: add angular material snackbar indicating success
            },
            error: (error) => {
              // TODO: add angular material snackbar indicating failure
              console.log(error);
            }
          });
        } else {
          window.close();
        }
      });
  }

  openTaxCertificatesNotFoundModal(): void {
    this.lockScreenService.lockForUpdate = false;
    const contentModalConfig: DialogConfigParams = {
      title: 'Tax Certificates Online',
      message:
        'Tax Certificates Online credentials missing for your profile.  Please add credentials or signup with Tax Certificates Online.',
      hideCancelBtn: false,
      customConfirmBtnText: 'Sign-up',
      customBtnText: 'Add Credentials',
      customReturnText: 'ADD_CREDS'
    };
    this.dialogService.confirmCustomDialog(contentModalConfig).subscribe((res: any) => {
      if (res && res == 'ADD_CREDS') {
        this.openManageThirdPartyCredentials('TCOL');
      } else if (!!res) {
        window.open(this.environment.config.tcolUrl);
      }
    });
  }

  get isMarketingPageEnabled(): boolean {
    return this.appConfig && this.appConfig.isMarketingPageEnabled && this.isNonConveyancingUser;
  }

  get isConveyancingDataPageEnabled(): boolean {
    return this.appConfig && this.appConfig.isConveyancingDataPageEnabled && this.isConveyancingUser;
  }

  get isConveyancingUser(): boolean {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.isConveyancingUser) == 'YES';
  }

  get isNonConveyancingUser(): boolean {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.isConveyancingUser) == 'NO';
  }

  openUnityMarketingPage(): void {
    if (this.isMarketingPageEnabled || this.isConveyancingDataPageEnabled) {
      this.isMarketingPageDown = false;
      if (this.isMarketingPageEnabled) {
        this.landingWidgetsService.getLoggedInUserContent().subscribe(
          () => {
            this.openWelcomeModal();
          },
          (error) => {
            console.error(error);
            this.isMarketingPageDown = true;
          }
        );
      } else if (this.isConveyancingDataPageEnabled) {
        this.landingWidgetsService.getDashboardMatters().subscribe(
          () => {
            this.openWelcomeModal();
          },
          (error) => {
            console.error(error);
            this.isMarketingPageDown = true;
          }
        );
      }
    }
  }

  openAccountingModal(): void {
    this.dialogService.matDialogContent({
      content: AccountingModalComponent,
      context: {},
      onFulfillment: () => {}
    });
  }

  openWelcomeModal(): void {
    this.dialogService.matDialogContent({
      content: WelcomePageModalComponent,
      fullScreen: true,
      widthXl: true,
      modalDialogCustomClass: 'welcome-page-modal-dialog',
      modalContentCustomClass: 'welcome-page-modal-content',
      context: {},
      onFulfillment: () => {}
    });
  }

  isSsoEnabled() {
    return this.featureService.isSSOEnabled;
  }

  get displayWaffleMenu() {
    return this.appConfig.enableGeneralEnhancementsPh1 ? 'Integrations' : 'Dye & Durham Suite';
  }
}
