import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {SoaMatter} from '../../../app/matters/statement-account/soa-matter';
import {MatterCalculatedAmount} from '../../../app/matters/shared/matter-calculated-amount';

export class MatterSoaSheet extends BaseEntity {

  static clientAssignedIdentifierEntity: boolean = true;
  id: number;
  feeCalculatedOnInclusivePriceFlag: boolean;
  soaFeesCalculatedOnAllInclusivePrice: number;
  details: string;
  associatedTemplateId: number;
  statementConfigurationId: number;
  soaItems: SoaMatter[];
  receivedOnAccount: number;
  matterCalculatedAmount: MatterCalculatedAmount;

  constructor(matterSoaSheet?: MatterSoaSheet) {
    super(matterSoaSheet);
    if (matterSoaSheet) {
      this.soaItems = [];
      if (Array.isArray(matterSoaSheet.soaItems)) {
        for (let i: number = 0; i < matterSoaSheet.soaItems.length; ++i) {
          this.soaItems [ i ] = new SoaMatter(matterSoaSheet.soaItems[ i ]);
        }

      }
    }
  }

}
