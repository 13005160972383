import {HttpClient} from '../../core';
import {Injectable} from '@angular/core';
import {ReportTemplate} from '../../matters/report-template/report-template';
import {Observable} from '../../../../node_modules/rxjs/Rx';
import {reportTemplatesApi, reportTemplatesKey} from './export-templates-api';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';

@Injectable()
export class ExportTemplatesService {

  constructor(private httpClient: HttpClient) {
  }

  getAllReportTemplates(accountId: number, provinceCode: string): Observable<ReportTemplate[]> {
    let url = reportTemplatesApi.reportTemplates
    .replace('{accountId}', '' + accountId)
    .replace('{provinceCode}', '' + provinceCode);
    return this.httpClient.get(url)
    .map((response) => {
      let result: ReportTemplate[] = [];
      for (let dp of response[ reportTemplatesKey.reportTemplates ]) {
        let reportTemplate = new ReportTemplate(dp);
        result.push(reportTemplate);
      }
      return result;
    });
  }

  downloadReportTemplateFile(accountId: number, reportTemplateId: number) {
    let url: string = reportTemplatesApi.downloadTemplate.replace('{accountId}', '' + accountId).replace('{reportTemplateId}', '' + reportTemplateId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    window.open(url, '_blank');
  }

  editReportTemplateFile(accountId: number, reportTemplate: ReportTemplate) {
    let url: string = reportTemplatesApi.reportTemplate.replace('{accountId}', '' + accountId).replace('{reportTemplateId}', '' + reportTemplate.id);
    return this.httpClient.put(url, reportTemplate)
    .map((res) => {
      return new ReportTemplate(res[ reportTemplatesKey.reportTemplate ]);
    });
  }

  deleteReportTemplateFile(accountId: number, reportTemplateId: number): Observable<any> {
    let url: string = reportTemplatesApi.reportTemplate.replace('{accountId}', '' + accountId).replace('{reportTemplateId}', '' + reportTemplateId);
    return this.httpClient.delete(url)
    .map((res) => {
      return res;
    });
  }
}
