import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';

export class RentInterestRate extends BaseEntity {
  id: number;

  index: number;

  fromDate: string;

  toDate: string;

  interestRate: number;

  provinceCode: ProvinceCode;
}
