<form class="form-horizontal" id="courierInstrumentForm" #courierInstrumentForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect &trade;: Request Courier Delivery of Instruments</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="onCancel()">&times;</button>
            </div>

        </div>

        <div class="row modal-body">
                <div class="form-group">
                    <label class="control-label col-lg-2" for="attention" >Attention*</label>
                    <div class="col-lg-10">
                        <input type="text" id="attention" name="attention" [(ngModel)]="teranetCourierRequest.attention"
                               class="form-control" [fieldKey]="'teranet.courierInstrument.missingAttention'" dp-error-validator>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-lg-2" for="firmName" >Firm Name</label>
                    <div class="col-lg-10">
                        <input type="text" id="firmName" name="firmName" [(ngModel)]="teranetCourierRequest.firmName"
                               class="form-control">
                    </div>
                </div>

                <dp-address-form [address]="teranetCourierRequest.address"
                                 [closeShutter]="false"
                                 [fieldPrefix]="'courier.address'"
                                 addressSummaryLabel="Address to be<br>delivered to"
                                 [showMandatoryFields]="true"
                >
                </dp-address-form>

            <div class="form-group">
                <label class="control-label col-lg-2" for="deliveryInstructions" >Delivery Instructions</label>
                <div class="col-lg-10">
                    <input type="text" id="deliveryInstructions" name="deliveryInstructions" [(ngModel)]="teranetCourierRequest.deliveryInstructions"
                           class="form-control">
                </div>
            </div>

            <div class="margin-top-20">
                Select Instruments to be delivered by Courier (maximum of 10)
                <span class="right">
                    <a class="padding-right-10" href="{{feesLink}} "target="_blank">Show Fees</a>
                    <button class="dp-btn" type="button" (click)="addInstrument()">Add Instrument</button>
                </span>
            </div>

            <div class="col-sm-12 margin-top-20">
                <table class="table tc-table" tabindex="-1" >
                    <thead class="z-index-1 fixed-position margin-top-minus-10">
                    <tr>
                        <th class="width-15-percent">Instrument No.</th>
                        <th class="width-5-percent"></th>
                        <th class="width-15-percent">Date</th>
                        <th class="width-15-percent">No. of Copies</th>
                        <th class="width-10-percent">Pages</th>
                        <th class="width-40-percent">Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let inst of courierInstruments; let i=index;" [attr.tabindex]="0">
                        <td class="width-15-percent">{{inst.instrumentNumber}}</td>
                        <td class="width-5-percent">
                            <dp-burger-menu
                                [dpItems]="instrumentBurgerMenuItems"
                                (itemClicked)="clickInstrumentsBurgerMenu(i, $event)">
                            </dp-burger-menu>
                        </td>
                        <td class="width-15-percent">
                            {{inst.instrumentDate}}
                        </td>
                        <td class="width-10-percent">
                            <select class="form-control width-50-percent" [(ngModel)]="inst.numberCourierCopies" name="numCopies_{{inst.identifier}}" id="numCopies_{{inst.identifier}}">
                                <option *ngFor="let option of copyOptions" [value]="option">
                                    {{option}}
                                </option>
                            </select>
                        </td>
                        <td class="width-10-percent">
                            {{inst.instrumentDocumentPageCount}}
                        </td>
                        <td class="width-40-percent">
                           {{inst.typeWithPartyTo}}
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </div>
    </div>

    <div class="row modal-footer ">
        <div>
            <button id="okBtn" class="dp-btn" type="button" (click)="proceed()" [disabled]="isProceedButtonDisabled">
                Proceed
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="onCancel()">
                Cancel
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>


