import {Subject} from 'rxjs/Subject';

export class ExplicitFocusFirstHelper {

  focusWaitObservable: Subject<any>;

  constructor() {
    this.focusWaitObservable = new Subject<any>();
  }

  triggerFocus(): void {
    this.focusWaitObservable.next();
  }
}
