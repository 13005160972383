import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '../core/httpClient.service';
import {api, SESSION_ID_REQ_PARAM} from '../common/api';
import {DocumentTemplateFile} from '../matters/document-production/document-template';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';

@Injectable()
export class DocServicesService {

  constructor(private http: HttpClient) {

  }

  formsUrl: string = `${ api }/documentServices/formTemplateFiles`;
  fileDownloadUrl: string = `${ api }/documentServices/formTemplateFiles/{templateFileId}/download?${ SESSION_ID_REQ_PARAM }={sessionId}`;
  fileDeleteUrl: string = `${ api }/documentServices/formTemplateFiles/{templateFileId}`;

  uploadFiles(url, formData): Observable<any> {
    return this.http.uploadFiles(url, formData);

  }

  getFormFiles(): Observable<DocumentTemplateFile[]> {

    return this.http.get(this.formsUrl)
    .map((res) => {
      let files = res[ 'DocumentTemplateFiles' ];
      if (files && Array.isArray(files)) {
        return files.map(file => {
          return new DocumentTemplateFile(file);
        });
      } else {
        return [];
      }
    });
  }

  downloadFormTemplateFile(templateFileId: number) {
    let url: string = this.fileDownloadUrl.replace('{templateFileId}', '' + templateFileId);
    url = url.replace('{sessionId}', '' + sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId));
    var windowObject = window.open(url, '_blank');
  }

  deleteFormTemplateFile(templateFileId: number): Observable<boolean> {
    let url: string = this.fileDeleteUrl.replace('{templateFileId}', '' + templateFileId);
    return this.http.delete(url).map((res) => {
      console.log(res);
      return true;
    }).catch(e => {
      return Observable.of(false);
    });
  }

  getLendersList(): Observable<DocumentTemplateFile[]> {

    return this.http.get(this.formsUrl)
    .map((res) => {
      let files = res[ 'DocumentTemplateFiles' ];
      if (files && Array.isArray(files)) {
        return files.map(file => {
          return new DocumentTemplateFile(file);
        });
      } else {
        return [];
      }
    });
  }

}
