import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DpBooleanValue} from './dp-boolean';

export class RequisitionResponse extends BaseEntity {

  requisitionLetterDate: string;
  receivedOnTime: DpBooleanValue;
  responseSent: DpBooleanValue;
  requisitionText: string;
}
