import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export const BaseTaxAdjustmentOn = {
  percentageOfTaxesForProject: 'PERCENTAGE_OF_TAXES_FOR_PROJECT',
  percentageOfUnitPurchasePrice: 'PERCENTAGE_OF_UNIT_PURCHASE_PRICE',
  EqualDivisionAmongUnits: 'EQUAL_DIVISION_AMONG_UNITS',
  percentageOfUnitPurchasePriceIncludingCredits: 'PERCENTAGE_OF_UNIT_PURCHASE_PRICE_INCL_CREDITS'
};

export class ProjectTaxAdjustmentConfig extends BaseEntity {

  constructor(taxAdjustment?: ProjectTaxAdjustmentConfig) {
    super(taxAdjustment);
    if (!taxAdjustment) {
      this.taxAdjustmentType = BaseTaxAdjustmentOn.percentageOfTaxesForProject;
      this.numberOfUnits = 0;
      this.unitPurchasePrice = 0.00;
      this.totalVendorTaxesPaid = 0.00;
    }
  }

  taxAdjustmentType: string;
  totalTaxes: number;
  totalTaxesPreviousYear: number;
  numberOfUnits: number;
  unitPurchasePrice: number;
  totalVendorTaxesPaid: number;
}
