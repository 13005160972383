import {BaseEntity} from '../shared/BaseEntity/base-entity';

export type SharedDocumentType = 'UNITY' | 'THIRD_PARTY';

export class SharedDocument extends BaseEntity {
  id: number;
  name: string;
  originalName: string;
  status: string;
  sharedDocumentType: SharedDocumentType;
  matterDocumentId: number;
  description: string;
  date: string;
  lastSharedTimeStamp: string;
  lastViewedTimeStamp: string;
  sourceDocumentId: number;
  sharingRevoked: boolean;

  constructor(sharedDocument?: SharedDocument) {
    super(sharedDocument);
  }

  get docStatus(): string {
    if (!this.lastViewedTimeStamp) {
      return 'New';
    }
    if (this.lastViewedTimeStamp < this.lastSharedTimeStamp) {
      return 'Updated';
    }
    return '';
  }
}


