import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {Component, Inject} from '@angular/core';
import {dropDowns, Matter} from '../../../shared';
import {StatementAdjustment} from '../../statement-adjustment';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {SoAdjForm4Charge} from '../../model/so-adj-form-4-charge';
import {Utils} from '../../../shared/utils';
import {SelectItem} from 'primeng/api';
import {ModalResult} from '../../../../shared-main/enums';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class Form4ChargeContext {
  public closingDate: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public soAdjForm4Charge: SoAdjForm4Charge;
  public statementAdjustment: StatementAdjustment;
  public matter: Matter;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'form-4-charge-modal-content',
  templateUrl: './form-4-charge.modal.component.html'
})
export class Form4ChargeModal extends ModalComponent<Form4ChargeContext> {
  closingDateLabel: string = 'Closing Date';
  soAdjForm4ChargeLocal: SoAdjForm4Charge;
  taxTypeDropDown: SelectItem[];
  adjustForTypeDropDown: SelectItem[];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<Form4ChargeModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: Form4ChargeContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.newAdj = !this.context.soAdjForm4Charge;

    this.soAdjForm4ChargeLocal = new SoAdjForm4Charge(this.context.soAdjForm4Charge);
    if (!this.soAdjForm4ChargeLocal.numberOfForm4Issued) {
      this.soAdjForm4ChargeLocal.numberOfForm4Issued = 0;
    }
    if (!this.soAdjForm4ChargeLocal.costPerForm4) {
      this.soAdjForm4ChargeLocal.costPerForm4 = 0;
    }
    this.recalculateDepositsWithForm4MarkedAsSent();

    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }
    this.taxTypeDropDown = dropDowns.form4ChargesTaxTypeOptions;
    if (!this.soAdjForm4ChargeLocal.adjustedFor) {
      this.soAdjForm4ChargeLocal.adjustedFor = this.context.provinceHstRateSlab.provinceDefaultTaxRate;
    }
    this.adjustForTypeDropDown = dropDowns.form4ChargesAdjustFor;
    if (!this.soAdjForm4ChargeLocal.adjustedForType) {
      this.soAdjForm4ChargeLocal.adjustedForType = 'YES';
    }
    // if(!this.soAdjForm4ChargeLocal.percentageAdjustedFor){
    //     if(this.soAdjForm4ChargeLocal.adjustedForType == 'YES' && this.soAdjForm4ChargeLocal.adjustedFor === 'HST') {
    //         this.soAdjForm4ChargeLocal.percentageAdjustedFor
    //             =  this.context.provinceHstRateSlab.hstFederalPortion + this.context.provinceHstRateSlab.hstProvincialPortion;
    //     }
    // }
    if (!this.soAdjForm4ChargeLocal.adjustForPst) {
      this.soAdjForm4ChargeLocal.adjustForPst = 'NO';
    }
    this.getTaxValues();
  }

  getNumberOfDepositsWithForm4MarkedAsSent(): number {
    return this.soAdjForm4ChargeLocal.getNumberOfDepositsWithForm4MarkedAsSent(
      this.context.matter.matterPropertyWithCondo.deposits
    );
  }

  ok(): void {
    this.dialog.close(this.soAdjForm4ChargeLocal);
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  get isAdded(): boolean {
    return (
      this.context.statementAdjustment &&
      this.context.statementAdjustment.addedFlag != undefined &&
      !this.context.statementAdjustment.addedFlag
    );
  }

  recalculateDepositsWithForm4MarkedAsSent(): void {
    if (
      !this.soAdjForm4ChargeLocal.overrideCalculatedValue &&
      this.context.matter.matterPropertyWithCondo &&
      this.context.matter.matterPropertyWithCondo.deposits
    ) {
      this.soAdjForm4ChargeLocal.numberOfForm4Issued = this.getNumberOfDepositsWithForm4MarkedAsSent();
    }
  }

  onOverrideCalculatedValue(event) {
    this.soAdjForm4ChargeLocal.overrideCalculatedValue = event;
    this.recalculateDepositsWithForm4MarkedAsSent();
  }

  taxTypeChange(event) {
    this.soAdjForm4ChargeLocal.adjustedFor = event;
    this.getTaxValues();
  }

  adjustForTypeChange(event) {
    this.soAdjForm4ChargeLocal.adjustedForType = event;
    this.getTaxValues();
  }

  adjustForPstChange(event) {
    this.soAdjForm4ChargeLocal.adjustForPst = event;
    this.getTaxValues();
  }

  getTaxValues() {
    if (this.soAdjForm4ChargeLocal.adjustedForType == 'YES') {
      if (this.soAdjForm4ChargeLocal.adjustedFor === 'HST') {
        this.soAdjForm4ChargeLocal.percentageAdjustedFor =
          this.context.provinceHstRateSlab.hstFederalPortion + this.context.provinceHstRateSlab.hstProvincialPortion;
      } else {
        this.soAdjForm4ChargeLocal.percentageAdjustedFor = this.context.provinceHstRateSlab.hstFederalPortion;
      }
    } else {
      this.soAdjForm4ChargeLocal.percentageAdjustedFor = 0;
    }

    if (this.soAdjForm4ChargeLocal.adjustForPst == 'YES') {
      if (this.soAdjForm4ChargeLocal.adjustedFor === 'HST') {
        this.soAdjForm4ChargeLocal.percentageAdjustedForPst = 0;
      } else {
        this.soAdjForm4ChargeLocal.percentageAdjustedForPst = this.context.provinceHstRateSlab.hstProvincialPortion;
      }
    } else {
      this.soAdjForm4ChargeLocal.percentageAdjustedForPst = 0;
    }
  }
}
