import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AdjustmentTemplate} from './model/adjustment-template';
import {matterResponseKey} from '../shared/matter-response-key';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {matterApi} from '../shared/matter-api';
import {HttpClient} from '../../core/httpClient.service';

@Injectable()
export class AdjustmentTemplateService {

  constructor(private httpClient: HttpClient) {
  }

  getAllTemplateAdjustments(): Observable<AdjustmentTemplate[]> {
    let url = `${ matterApi.templateAdjustments(this.accountId) }`;
    return this.httpClient
    .get(url)
    .map((res) => {

      let adjustmentTemplates: AdjustmentTemplate[] = [];
      if (res[ matterResponseKey.templateAdjustment ]) {
        res[ matterResponseKey.templateAdjustment ].forEach(item => adjustmentTemplates.push(new AdjustmentTemplate(item)));
      }
      return adjustmentTemplates;
    });
  }

  getAllTemplateAdjustmentsByItemKey(itemKey: string): Observable<AdjustmentTemplate[]> {
    let url = (itemKey) ?
      `${ matterApi.templateAdjustments(this.accountId) }?itemKey=${ itemKey }` :
      `${ matterApi.templateAdjustments(this.accountId) }`;
    return this.httpClient
    .get(url)
    .map((res) => {

      let adjustmentTemplates: AdjustmentTemplate[] = [];
      if (res[ matterResponseKey.templateAdjustment ]) {
        res[ matterResponseKey.templateAdjustment ].forEach(item => adjustmentTemplates.push(new AdjustmentTemplate(item)));
      }
      return adjustmentTemplates;
    });
  }

  createAdjustmentTemplate(adjustmentTemplate: AdjustmentTemplate): Observable<AdjustmentTemplate> {
    let url = `${ matterApi.templateAdjustments(this.accountId) }`;
    return this.httpClient.post(url, JSON.stringify(adjustmentTemplate))
    .map((res) => {
      return new AdjustmentTemplate(res[ matterResponseKey.templateAdjustment ]);
    });
  }

  updateAdjustmentTemplate(adjustmentTemplate: AdjustmentTemplate): Observable<AdjustmentTemplate> {
    let url = `${ matterApi.templateAdjustment(this.accountId, adjustmentTemplate && adjustmentTemplate.id) }`;
    return this.httpClient.put(url, JSON.stringify(adjustmentTemplate))
    .map((res) => {
      return new AdjustmentTemplate(res[ matterResponseKey.templateAdjustment ]);
    });
  }

  deleteAdjustmentTemplate(adjustmentTemplateId: number): Observable<any> {
    let url = `${ matterApi.templateAdjustment(this.accountId, adjustmentTemplateId) }`;

    return this.httpClient.delete(url)
    .map((res) => {
      return res;
    });
  }

  get accountId(): string {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
  }

}
