import {SelectItem} from 'primeng/api';
import {DocumentTypeType} from '../../document-production/document';
import * as _ from 'lodash';
import {ProducibleDocType} from '../../document-production/document-production-data';

export type DocumentProductionOutputType = 'PDF' | 'WORD' | 'WORDPERFECT' | 'WORD_AND_PDF' | 'WORDPERFECT_AND_PDF';

export class DocumentProductionUtil {

  static getDocTypeDisplayName(documentProfileType: ProducibleDocType): string {
    if (documentProfileType === 'RTF') {
      return 'Word';
    }
    if (documentProfileType === 'WORDPERFECT') {
      return 'WordPerfect';
    }
    if (documentProfileType === 'WORD') {
      return 'Word';
    }
    return 'Word';
  }

  static getOutputMenuItems(documentProfileType: ProducibleDocType): SelectItem[] {
    let tempOutput: SelectItem[] = [];
    let outputText: string = DocumentProductionUtil.getDocTypeDisplayName(documentProfileType);
    tempOutput.push({label: 'PDF Document', value: 'PDF'});
    tempOutput.push({label: outputText + ' Document', value: documentProfileType === 'WORD' ? 'WORD' : 'WORDPERFECT'});
    tempOutput.push({
      label: 'Both ' + outputText + ' Document & PDF Document',
      value: documentProfileType === 'WORD' ? 'WORD_AND_PDF' : 'WORDPERFECT_AND_PDF'
    });
    return tempOutput;
  }

  static getRequestedFileTypesFromOutputType(requestedOutputType?: string): DocumentTypeType[] {

    let dtt: DocumentTypeType[] = [];
    if (requestedOutputType == 'WORD') {
      dtt.push('WORD');
    } else if (requestedOutputType == 'PDF') {
      dtt.push('PDF');
    } else if (requestedOutputType == 'WORD_AND_PDF') {
      dtt.push('WORD');
      dtt.push('PDF');
    } else if (requestedOutputType == 'WORDPERFECT') {
      dtt.push('WORDPERFECT');
    } else if (requestedOutputType == 'WORDPERFECT_AND_PDF') {
      dtt.push('WORDPERFECT');
      dtt.push('PDF');
    } else if (requestedOutputType === 'RTF') {
      dtt.push('RTF');
    }
    return dtt;
  }

  // for back-end's list of id templates
  static getRequestedTemplateFileTypeFromOutputType(isDocumentProfileTypeWord: boolean, requestedOutputType?: string): DocumentTypeType {

    let rtft: DocumentTypeType = 'WORD';
    if (requestedOutputType == 'WORD') {
      rtft = 'WORD';
    } else if (requestedOutputType == 'PDF') {
      rtft = isDocumentProfileTypeWord ? 'WORD' : 'WORDPERFECT';
    } else if (requestedOutputType == 'WORD_AND_PDF') {
      rtft = 'WORD';
    } else if (requestedOutputType == 'WORDPERFECT') {
      rtft = 'WORDPERFECT';
    } else if (requestedOutputType == 'WORDPERFECT_AND_PDF') {
      rtft = 'WORDPERFECT';
    }
    return rtft;
  }

  static getGenerationCompleteMessage(requestedOutputType?: string): string {

    if (!requestedOutputType) {
      return 'PDF Document generation is complete. Do you want to open the document?';
    }

    let docTypes: string = '';
    let plural: string = '';
    if (requestedOutputType == 'WORD') {
      docTypes = 'WORD';
    } else if (requestedOutputType == 'PDF') {
      docTypes = 'PDF';
    } else if (requestedOutputType == 'WORD_AND_PDF') {
      docTypes = 'WORD and PDF';
      plural = 's';
    } else if (requestedOutputType == 'WORDPERFECT') {
      docTypes = 'WORDPERFECT';
    } else if (requestedOutputType == 'WORDPERFECT_AND_PDF') {
      docTypes = 'WORDPERFECT and PDF';
      plural = 's';
    }
    return `${ docTypes } Document generation is complete. Do you want to open the document${ plural }?`;
  }

  static isMultipleDocumentWithSameNameWithoutExtension(documents: string[]): boolean {
    let documentsWithoutExtension = documents.map(doc => this.removeExtensionFromFileName(doc));
    return _.uniq(documentsWithoutExtension).length !== documentsWithoutExtension.length;
  }

  static fetchDuplicateDocumentsWithoutExtensionForSharing(documents: string[]): string[] {
    let documentsWithoutExtension = documents.map(doc => this.removeExtensionFromFileName(doc));
    return _.uniq(documentsWithoutExtension.filter((item, index) => documentsWithoutExtension.indexOf(item) != index));
  }

  static removeExtensionFromFileName(fileName: string) {
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }
}
