export class MunicipalityConfigurationType {
  id: number;
  municipalityConfigurationCode: string;

  constructor(municipalityConfigurationType?: MunicipalityConfigurationType) {
    if (municipalityConfigurationType) {
      for (let prop in municipalityConfigurationType) {
        if (municipalityConfigurationType.hasOwnProperty(prop)) {
          this[ prop ] = municipalityConfigurationType[ prop ];
        }
      }
    }
  }
}

export class MunicipalityConfiguration {
  MunicipalityConfigurationTypes: MunicipalityConfigurationType[];

  constructor(municipalityConfiguration?: MunicipalityConfiguration) {
    if (municipalityConfiguration) {
      for (let prop in municipalityConfiguration) {
        if (municipalityConfiguration.hasOwnProperty(prop)) {
          this[ prop ] = municipalityConfiguration[ prop ];
        }
      }
    }
    this.MunicipalityConfigurationTypes = [];
    if (Array.isArray(municipalityConfiguration.MunicipalityConfigurationTypes)) {
      for (let i: number = 0; i < municipalityConfiguration.MunicipalityConfigurationTypes.length; i++) {
        this.MunicipalityConfigurationTypes[ i ] = new MunicipalityConfigurationType(municipalityConfiguration.MunicipalityConfigurationTypes[ i ]);
      }
    }
  }
}
