import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import moment from 'moment';

export class LateClosingInterest extends BaseEntity {
  id: number;
  closingDatePaymentId: number;
  principalAmount: string;
  interestRate: number;
  sameAs1stMortgage: boolean;
  fromDate: string;
  toDate: string;
  lateInterestAmount: number;

  constructor(lateClosingInterest?: LateClosingInterest) {
    super(lateClosingInterest);

  }

  isPrincipalAmountDelayedAmount(): boolean {
    return this.principalAmount == PrincipalAmountTypeForLateInterest.delayedAmountOptionText;
  }

  isPrincipalAmountCashToCloseAmount(): boolean {
    return (this.principalAmount == PrincipalAmountTypeForLateInterest.cashToCloseOptionText) || (this.principalAmount == PrincipalAmountTypeForLateInterest.balanceToCloseOptionText);
  }

  isPrincipalAmountRegMortgagteAmount(): boolean {
    return this.principalAmount == PrincipalAmountTypeForLateInterest.registeredMortgageOptionText;
  }

  getClosingDateDifference(lastDayIsLateClosingDay: boolean = false): string {
    let closingDays: number = (this.fromDate && this.toDate && moment(this.toDate).diff(moment(this.fromDate), 'days') > 0 ? (moment(this.toDate).diff(moment(this.fromDate), 'days')) : 0);
    if (!lastDayIsLateClosingDay) {
      //if last day is not lateClosingDay, we need to count fromDate and toDate all included
      closingDays++;
    }
    return closingDays <= 0 ? '0 days' : (closingDays == 1 ? '1 day' : (+closingDays + ' days'));
  }

}

export const PrincipalAmountTypeForLateInterest = {
  delayedAmountOptionText: 'Delayed Amount of ',
  cashToCloseOptionText: 'Cash to Close of ',
  balanceToCloseOptionText: 'Balance to Close of ',
  registeredMortgageOptionText: 'Registered Mortgage Amount of '
};
