import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'dp-unity-wills-ai',
  templateUrl: './unity-wills-ai.component.html',
  styleUrls: [ './unity-wills-ai.component.scss' ]

})

export class UnityWillsAiComponent implements OnInit {
  enableWillsAi: boolean = false;

  constructor() {

  }

  ngOnInit(): void {
  }

  enableWillsAI(): void {
    //todo part 1 of 3 Unity Wills.AI ticket
    this.enableWillsAi = true;
  }
}
