/**
 * Data transfer object (NOT entity) for retrieving information about duplicate pins in a project
 */
export class DuplicatePinMatterInfo {
  matterRecordNumber: string;
  purchaserReline: string;
  pin: string;
  projectRecordNumber: string;

  //Not an entity, defining the constructor explicitly
  constructor(json?) {
    if (json) {
      for (let prop in json) {
        if (json.hasOwnProperty(prop)) {
          this[ prop ] = json[ prop ];
        }
      }
    }

  }

}
