import {Component, Inject, OnInit} from '@angular/core';
import {ExportTemplatesService} from '../export-templates.service';
import {ReportTemplate} from '../../../matters/report-template/report-template';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class EditExportTemplateDetailsContext {
  reportTemplate: ReportTemplate;
  accountId: number;
}

@Component({
  selector: 'dp-edit-export-template-details-modal-content',
  templateUrl: 'edit-export-template-details.modal.html'
})
export class EditExportTemplateDetailsComponent
  extends ModalComponent<EditExportTemplateDetailsContext>
  implements OnInit
{
  reportTemplate: ReportTemplate;

  constructor(
    public dialog: MatDialogRef<EditExportTemplateDetailsComponent>,
    private exportTemplatesService: ExportTemplatesService,
    @Inject(MAT_DIALOG_DATA) context?: EditExportTemplateDetailsContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.reportTemplate = new ReportTemplate(this.context.reportTemplate);
  }

  save() {
    this.exportTemplatesService
      .editReportTemplateFile(this.context.accountId, this.reportTemplate)
      .subscribe((data) => {
        this.context.reportTemplate.description = this.reportTemplate.description;
        this.dialog.close();
      });
  }

  close() {
    this.dialog.close();
  }
}
