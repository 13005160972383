import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class StickyNotesModalContext {
  notes: string;
  hideDeleteButton: boolean;
}

@Component({
  selector: 'dp-sticky-notes-modal',
  templateUrl: 'sticky-notes.modal.component.html'
})
export class StickyNotesModal extends ModalComponent<StickyNotesModalContext> implements OnInit {

  stickyNotes: string;
  isDirty: boolean = false;

  constructor(public dialog: MatDialogRef<StickyNotesModal, {status: string, notes: string}>,
              public dialogService: DialogService,
              @Inject(MAT_DIALOG_DATA) context?: StickyNotesModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.stickyNotes = this.context.notes;
  }

  ok(): void {
    this.dialog.close({'status': 'OK', 'notes': this.stickyNotes});
  }

  delete(): void {
    this.dialogService.confirm('Confirmation', 'Do you wish to delete this sticky note?', false).subscribe(res => {
      if (res) {
        this.dialog.close({'status': 'DELETE', 'notes': ''});
      }
    });
  }

  close(): void {
    if (this.isDirty) {
      this.dialogService.confirm('Confirmation', 'Do you wish to save your changes?', false, 'Save', 'Don\'t Save').subscribe(res => {
        if (res) {
          this.ok();
        } else {
          this.dialog.close();
        }
      });
    } else {
      this.dialog.close();
    }
  }
}
