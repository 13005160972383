import {MatterTax} from '../../shared/property-taxes';
import {RollNumberMatterTax} from '../../shared/roll-number-matter-tax';

export class RollNumberPropertyTaxesResult {
  propertyTax: MatterTax;
  separateTaxAdjustmentByRollNumber: boolean;
  infoOnly: boolean;
  propertyTaxesByRollNumber: RollNumberMatterTax[];
  autoInsertTaxAdjustment: boolean;
}
