<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Extras Subject to {{context.taxType}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12">
                <label class="control-label col-lg-3">Adjustment heading*</label>
                <div class="col-lg-4">
                    <input type="text"
                           [(ngModel)]="salesPriceAdjustmentHeading.name"
                           name="adjustmentHeading"
                           id="adjustmentHeading"
                           maxlength="150"
                           dp-error-validator
                           dp-uppercase
                           fieldKey="matter.soa.modal.salePrice.adjustmentHeading.name"
                           class="form-control"/>

                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <label class="control-label col-lg-3">Cost</label>
                <div class="col-lg-2">
                    <input type="text"
                           [(ngModel)]="salesPriceAdjustmentHeading.cost"
                           name="cost"
                           id="cost"
                           allow-negative="true"
                           dp-currency
                           dp-error-validator
                           fieldKey="matter.soa.modal.salePrice.adjustmentHeading.cost"
                           [dp-read-only]="salesPriceAdjustmentHeading.autoCalculate"
                           class="form-control text-right"/>

                </div>
                <div class="col-lg-7 padding-top-5">
                    <dp-checkbox fieldId="autoCalculate">
                        <input id="autoCalculate"
                               name="autoCalculate"
                               type="checkbox"
                               [(ngModel)]="salesPriceAdjustmentHeading.autoCalculate"
                               (change)="calculateHeadingCost()"
                        />
                    </dp-checkbox>
                    <label class="control-label padding-left-10 display-inline"
                           (click)="salesPriceAdjustmentHeading.autoCalculate=!salesPriceAdjustmentHeading.autoCalculate; calculateHeadingCost()" >
                        Auto-calculate cost based on values below</label>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="salesPriceAdjustmentHeading &&
                                        salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems &&
                                        salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length">
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-underline">{{salesPriceAdjustmentHeading.name}}</label>
            </div>
        </div>

        <div class="form-group" *ngFor="let item of salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems; let i=index;">
            <div class="col-lg-12">
                <label class="control-label col-lg-3">Description</label>
                <div class="col-lg-4">
                    <input type="text"
                           [(ngModel)]="item.description"
                           name="{{item + i}}-description"
                           id="{{item + i}}-description"
                           maxlength="38"
                           fieldKey="matter.salesPriceAdjustmentHeading.modal.item{{i}}"
                           dp-error-validator
                           class="form-control"/>

                </div>
                <label class="control-label col-lg-2">Item Cost</label>
                <div class="col-lg-2">
                    <input type="text"
                           [(ngModel)]="item.cost"
                           (change)="calculateHeadingCost()"
                           (keydown.Enter)="calculateHeadingCost()"
                           name="{{item + i}}-cost"
                           id="{{item + i}}-cost"
                           fieldKey="matter.salesPriceAdjustmentHeading.modal.itemCost{{i}}"
                           dp-error-validator
                           allow-negative="true"
                           dp-currency
                           class="form-control text-right"/>

                </div>
                <div class="col-lg-1 padding-top-5">
                    <span>
                        <i class="fa fa-trash" tabindex="0" (click)="removeItem(i)"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-lg-12 padding-top-10" *ngIf="salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems && salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length < 100">
            <div class="col-lg-3"></div>
            <div class="col-lg-4">
                <a href="javascript:void(0);" (click)="addAnotherItem()">
                    Add Another Item
                    <span class="selector fa fa-plus-square-o"></span>
                </a>
            </div>
        </div>
        <div class="col-lg-12 padding-top-10" *ngIf="showWarningMessage()">
            <div class="form-group-warning-message margin-top-0 margin-bottom-10">
                <div class="col-lg-1 padding-top-10 padding-bottom-10">
                    <i aria-hidden="true" class="fa fa-exclamation-triangle"></i>
                </div>
                <div class="col-lg-11 boldfont padding-top-10 padding-bottom-10">
                    <p>Numbers entered in the bottom area of this dialog will be displayed for information purposes only,
                        and are NOT totaled or otherwise used so as to calculate the credit to the vendor</p>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12 padding-top-30 padding-bottom-30">
                <label class="control-label col-lg-3">Credit {{vendorLabel}}</label>
                <div class="col-lg-6"></div>
                <div class="col-lg-2 text-right">
                    <label class="control-label">{{salesPriceAdjustmentHeading.cost | currency:'CAD' : 'symbol-narrow' : '1.2-2'}}</label>

                </div>
            </div>
        </div>



    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn3" class="dp-btn" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
