import {Component, ElementRef, OnDestroy, OnInit, Inject, ViewChild} from '@angular/core';
import {PermittedRegistration, PermittedRegistrationMaxLength} from '../../shared/permitted-registration';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {messages} from '../../../common';
import {Mortgage} from '../../shared/mortgage';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class PermittedRegistrationModalContext {
  permittedRegistrations: PermittedRegistration[];
  existingMortgages: Mortgage[];
}

@Component({
  selector: 'permitted-registration-modal',
  templateUrl: './permitted-registration-modal.component.html',
  styleUrls: ['./permitted-registration.styles.scss']
})
export class PermittedRegistrationModalComponent
  extends ModalComponent<PermittedRegistrationModalContext>
  implements OnInit, OnDestroy
{
  @ViewChild('scrollable') public scrollable: ElementRef;
  newListItemText: string = '';
  permittedRegistrationsLocal: PermittedRegistration[] = [];
  activeListItem: number = -1;
  activeEditing: boolean = false;

  constructor(
    public dialog: MatDialogRef<PermittedRegistrationModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: PermittedRegistrationModalContext
  ) {
    super(dialog, context);
  }

  setActiveIndex(index): void {
    this.activeListItem = index;
    setTimeout(() => {
      jQuery('.permittedRegistrationList li:nth-child(' + (this.activeListItem + 1) + ')').focus();
    }, 100);
  }

  ngOnInit(): void {
    this.context.permittedRegistrations.forEach((item) => {
      this.permittedRegistrationsLocal.push(new PermittedRegistration(item));
    });
  }

  ngOnDestroy(): void {}

  addNewListItem(): void {
    if (this.newListItemText) {
      let item = new PermittedRegistration();
      item.permittedRegistrationValue = this.newListItemText;
      this.permittedRegistrationsLocal.push(item);
      this.newListItemText = '';
      this.scrollToBottom();
      this.setActiveIndex(this.permittedRegistrationsLocal.length - 1);
    }
  }

  moveUp(): void {
    if (this.activeListItem > 0) {
      let item: PermittedRegistration = this.permittedRegistrationsLocal[this.activeListItem];
      let index = this.activeListItem;
      this.permittedRegistrationsLocal.splice(index - 1, 0, item);
      this.permittedRegistrationsLocal.splice(index + 1, 1);
      this.setActiveIndex(index - 1);
    }
  }

  moveDown(): void {
    if (this.activeListItem < this.permittedRegistrationsLocal.length - 1) {
      let item: PermittedRegistration = this.permittedRegistrationsLocal[this.activeListItem];
      let index = this.activeListItem;
      this.permittedRegistrationsLocal.splice(index + 2, 0, item);
      this.permittedRegistrationsLocal.splice(index, 1);
      this.setActiveIndex(index + 1);
    }
  }

  deleteItem(): void {
    let item: PermittedRegistration = this.permittedRegistrationsLocal[this.activeListItem];
    if (this.isFromExistingMortgage(item.id)) {
      this.dialogService
        .confirm('Error', messages.undertaking.permittedRegistrationFromMortgageConfirmation, true)
        .subscribe();
    } else {
      this.dialogService.confirm('Confirmation', 'Do you wish to delete this item?', false).subscribe((res) => {
        if (res) {
          this.permittedRegistrationsLocal.splice(this.activeListItem, 1);
          this.permittedRegistrationsLocal.length > 0 ? this.setActiveIndex(0) : this.setActiveIndex(-1);
          this.scrollToTop();
        }
      });
    }
  }

  //checks if this permitted registration was created from an existing mortgage
  isFromExistingMortgage(permittedRegistrationId: number): boolean {
    return this.context.existingMortgages.some(
      (existingMortgage) => existingMortgage.permittedRegistrationId == permittedRegistrationId
    );
  }

  editItem(): void {
    this.activeEditing = true;
    setTimeout(() => {
      jQuery('#commentEdit_' + this.activeListItem).focus();
    }, 100);
  }

  updateItem(event, comment, index) {
    this.activeEditing = false;
    this.permittedRegistrationsLocal[index].permittedRegistrationValue = comment;
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      this.scrollable.nativeElement.scrollTop = this.scrollable.nativeElement.scrollHeight;
    }, 100);
  }

  public scrollToTop(): void {
    setTimeout(() => {
      this.scrollable.nativeElement.scrollTop = 0;
    }, 100);
  }

  cancelComment(event: any): void {
    if (event.keyCode === 27) {
      // For escape key
      this.activeEditing = false;
    }
  }

  /** Keyup event handler */
  onSelectionChange(event: any): void {
    //console.warn("CustomPickListModalComponent | onSelectionChange | event = ", event);
    if (event.which === 13) {
      /* enter */
      this.editItem();
    }

    if (event.which === 38) {
      /* up arrow */
      if (this.activeListItem > 0) {
        this.setActiveIndex(this.activeListItem - 1);
      }
    }

    if (event.which === 40) {
      /* down arrow */
      if (this.activeListItem < this.permittedRegistrationsLocal.length - 1) {
        this.setActiveIndex(this.activeListItem + 1);
      }
    }
  }

  cancelDialog(): void {
    this.dialog.close();
  }

  ok(): void {
    this.dialog.close({
      action: 'OK',
      permittedRegistrationsLocal: this.permittedRegistrationsLocal
    });
  }

  getPermittedRegistrationMaxLength(): number {
    return PermittedRegistrationMaxLength;
  }
}
