import {Component, Inject} from '@angular/core';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class InterestRateModalModalContext {
  rates: any[];
}

@Component({
  selector: 'dp-interest-rate-modal-content',
  templateUrl: './interest-rate.modal.component.html'
})
export class InterestRateModal extends ModalComponent<InterestRateModalModalContext> {
  constructor(
    public dialog: MatDialogRef<InterestRateModal>,
    @Inject(MAT_DIALOG_DATA) context?: InterestRateModalModalContext
  ) {
    super(dialog, context);
  }

  close(): void {
    this.dialog.close();
  }
}
