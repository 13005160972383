<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal" id="form4Specification">
        <div class="border-radius">
            <div class="row modal-header margin-bottom-20">
                <div class="col-lg-10"><h1>Form 4 Specifications</h1></div>
                <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 padding-bottom-200">
                    <div class="col-lg-7">
                        <div class="form-group col-lg-12">
                            <label class="control-label col-lg-3">For:</label>
                            <div class="col-lg-6">
                                <select class="form-control" id="specificationFor"
                                        name="specificationFor"
                                        [disabled]="context?.initiatedFromTab === 'DepositListManager'"
                                        [(ngModel)]="selectedDeposit.depositPrintFormat">
                                    <option *ngFor="let option of depositPrintFormatOptions"
                                            [value]="option.value">
                                        {{option.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group col-lg-12" *ngIf="isDepositAmountVisible">
                            <label class="control-label col-lg-3">in the amount of</label>
                            <div class="col-lg-4">
                                <input type="text" id="depositAmount" [disabled]="true" dp-currency name="depositAmount" class="form-control text-right"
                                       [ngModel]="depositAmountPerPrintFormat"/>
                            </div>
                        </div>
                        <div class="form-group col-lg-12">
                            <label class="control-label col-lg-3">Dated</label>
                            <div class="col-lg-6">
                                <dp-partial-date
                                                 [fullDateRequired]="true"
                                                 [inputDate]="selectedDeposit.form4Date"
                                                 (dateChange)="onDepositDateChange($event)"
                                                 [fieldKey]="'form4.sentDate'"
                                >
                                </dp-partial-date>
                            </div>
                        </div>
                        <div class="form-group col-lg-12">
                            <div class="col-lg-1 offset-lg-2 padding-right-0 padding-top-7">
                                <dp-checkbox fieldId="form4Sent">
                                    <input type="checkbox"
                                           id="form4Sent"
                                           name="form4Sent"
                                           [(ngModel)]="selectedDeposit.form4Sent"
                                    />
                                </dp-checkbox>
                            </div>
                            <div class="col-lg-6 padding-left-4 text-left">
                                <label class="control-label">Check box if Form 4 has been sent</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row modal-footer">
                <div class="col-lg-12 buttons">
                    <div class="col-lg-12 text-center">
                        <button type="button" class="dp-btn keyboard-save-action" (click)="saveDepositForm(false)" id="okBtn">
                            <span>OK</span>
                        </button>
                        <button type="button" class="dp-btn keyboard-save-action" (click)="saveDepositForm(true)" id="printBtn">
                            <span>Print</span>
                        </button>
                        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
            <dp-modal-error #modalErrorComponent></dp-modal-error>
        </div>
    </form>
</div>


