<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1>{{matterUpdateAction}} Progress</h1>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group" [hidden]="projectMatterUpdateFailed">
            <div class="col-lg-12">
                <div class="main-level">
                        <span class="title col-lg-12 margin-bottom-0">
                        {{getProgressMessage()}}
                        </span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="projectMatterUpdateFailed">
            <div class="col-lg-12 padding-bottom-10 padding-top-10 padding-left-20 font-weight-bold errorMessage text-left">
                <label class="control-label">Project can not be updated due to Unexpected System Error. No Changes have been made, please try again or contact Customer Service.</label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div [hidden]="projectMatterUpdateFailed">
            <div class="progress" style="height: 5px" >
                <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [style.width]="uploadPercentage + '%'">

                </div>
            </div>
        </div>
        <div class="col-lg-12 buttons" *ngIf="uploadPercentage < 100 && projectMatterUpdateTransaction.showProjectMatterUpdateStop">
            <button type="button"
                    (click)="stopMassUpdate()"
                    (keyup.enter)="stopMassUpdate()"
                    tabindex="0"
                    class="dp-btn dp-btn-cancel">
                <span>Stop</span>
            </button>

        </div>
        <div class="col-lg-12 buttons" *ngIf="(uploadPercentage == 100 && isTransactionFinished()) || (uploadPercentage < 100 && projectMatterUpdateFailed)">
            <button type="button"
                    (click)="ok()"
                    (keyup.enter)="ok()"
                    tabindex="0"
                    class="dp-btn dp-btn-green">
                <span>OK</span>
            </button>
        </div>
    </div>
</div>
