<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Contact Customer Support</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <div class="fields-group">
                <div class="fixed-header">
                    If you have any questions or run into any issues, please contact our Customer Service Centre by <span class="hyperlink-text"><a href="https://dyedurham.ca/support/unity/" target="_blank">submitting a ticket</a></span> or by contacting us at 1.866.367.7648.
                </div>


            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" class="dp-btn" (click)="submit()">
                <span>Contact Support</span>
            </button>
        </div>
    </div>
</div>
