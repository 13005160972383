<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
  <div class="modal-header">
    <div class="col-lg-12">
      <div class="col-lg-10">
        <h1>Adjustment Type : Sale Price minus Net Sale price</h1>
      </div>
      <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
        <div class="form-group main-level">
          <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
        </div>
      </div>
    </div>


    <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
      <div class="col-lg-12">
        <div class="col-lg-4">
          <label class="control-label">
            SALE PRICE MINUS NET SALE PRICE
          </label>
        </div>
      </div>

      <div class="col-lg-12">
        <label class="control-label col-lg-5 text-left">
          Sale price as per Agreement, incl {{context.rateType}}
        </label>

        <label class="control-label col-lg-2 text-right">
          {{getFormattedCurrencyValue(context.salePriceAdjustment.agreementSalePrice)}}
        </label>
      </div>
      <div class="col-lg-12 margin-top-minus-3">
        <label class="control-label col-lg-5 text-left" style="white-space: pre-line; line-height: 23px">
          Net Sale Price
        </label>
        <label class="control-label col-lg-2 text-right">
          {{getFormattedCurrencyValue(context.totalConsideration)}}
        </label>
      </div>
      <div class="col-lg-12">
        <label class="control-label col-lg-5 text-left">
          Sale Price minus Net Sale Price
        </label>
        <label class="control-label col-lg-2 text-right">
          {{getFormattedCurrencyValue(context.vendorShare)}}
        </label>
      </div>
    </div>
  </div>

  <div class="row modal-footer">
    <div class="col-lg-12 buttons">
      <div class="col-lg-10 buttons">
        <button type="button" id="okBtn" (click)="ok()" class="dp-btn keyboard-save-action">
          <span>OK</span>
        </button>
        <button type="button" (click)="close()" class="dp-btn dp-btn-cancel">
          <span>Cancel</span>
        </button>
        <button type="button" (click)="delete()" *ngIf="allowDelete" class="dp-btn dp-btn-cancel">
          <span>Delete</span>
        </button>
      </div>
    </div>
  </div>
  <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>