import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {JurisdictionDepartment} from './jurisdiction-departments';
import {Observable} from 'rxjs';
import {jurisdictionDepartmentsApi} from '../shared/jurisdiction-departments-api';
import {Account} from '../accounts/shared/account';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ProvinceCode} from '../accounts/shared/base-province';

/**
 *  Jurisdiction Departments service defines all api calls for Jurisdiction Departments component
 */
@Injectable()
export class JurisdictionDepartmentsService {

  constructor(private http: HttpClient) {
  }

  getDepartmentPairs(account?: Account, provinceCode?: ProvinceCode): Observable<JurisdictionDepartment[]> {
    let accountId = '';
    if (!account) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    } else {
      accountId = account.id.toString();
    }

    let url = jurisdictionDepartmentsApi.departments.replace('*', accountId);
    if (provinceCode) {
      url = `${ url }?provinceCode=${ provinceCode }`;
    }

    return this.http.get(url)
    .map((res) => {
      let data = res[ 'JurisdictionDepartment' ];
      if (Array.isArray(data)) {
        return data.map(item => {
          return new JurisdictionDepartment(item);
        });
      } else {
        return [];
      }
    });
  }

  setDepartmentPairs(values, account: Account) {
    let url = jurisdictionDepartmentsApi.departments;
    let urlEdited = url.replace('*', account.id.toString());

    return this.http.post(urlEdited, values)
    .map((res) => {
      return res;
    });
  }
}
