import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SoaConfig} from '../../../admin/soa/soa-config';
import {SoaFee} from './soa-fees';
import {DialogService} from '../../../shared/dialog/dialog.service';
import * as _ from 'lodash';
import {StatementConfigService} from '../../../admin/shared/statement-config.service';
import {StatementConfig, StatementConfigContext} from '../../../admin/shared/statement-config';
import {CustomKeyCodesEnum} from '../../../common';
import {SESSION_STORAGE_KEYS} from '../../../shared';
import {ProvinceCode} from '../../../admin/accounts/shared/account-province';

export type MoveDirection = 'UP' | 'DOWN';

@Component({
  selector: 'dp-soa-fees',
  templateUrl: './soa-fees.component.html',
  styleUrls: [ './soa-fees.component.scss' ]
})

export class SoaFeesComponent implements OnInit {

  @Input() soaConfig: SoaConfig;
  @Input() matterType: string;
  @Input() provinceCode: string;
  @Input() context: StatementConfigContext = 'ACCOUNT';
  @Output() dirtyNotifier = new EventEmitter<string>();
  addButtonLabel: string = 'Add';
  deleteButtonLabel: string = 'Delete';
  burgerMenuItems: string[] = [ this.addButtonLabel, this.deleteButtonLabel ];
  burgerMenuItemWithoutDelete: string[] = [ this.addButtonLabel ];

  constructor(public dialogService: DialogService,
              public soaConfigService: StatementConfigService) {
  }

  ngOnInit(): void {

    console.log(this.soaConfig);
  }

  burgerMenuClicked(clickedMenuOption: string, selectedSoaFee: SoaFee): void {
    switch (clickedMenuOption) {
      case this.addButtonLabel:
        this.addFees(selectedSoaFee);
        break;
      case this.deleteButtonLabel:
        this.deleteFees(selectedSoaFee);
        break;
      default:
        return;
    }
  }

  get selectedIndex(): number {
    return _.findIndex(this.fees, soaFeeObj => soaFeeObj.isSelected == true);
  }

  get disableMoveUpMoveDown(): boolean {
    return (this.fees[ this.selectedIndex ].isPartOfGroupedFees());
  }

  addFees(selectedSoaFee: SoaFee): void {
    let newFee: SoaFee = this.createNewFee();
    if (selectedSoaFee) {
      let rowIndex = this.soaConfig.fees.findIndex(fee => fee == selectedSoaFee);
      let idx: number = 0;
      if (this.soaConfig.fees[ rowIndex ].isPartOfGroupedFees() && rowIndex > 0 && this.soaConfig.fees[ rowIndex ].matterType === this.matterType) {
        while (rowIndex - idx - 1 >= 0 && this.soaConfig.fees[ rowIndex - idx - 1 ].isPartOfGroupedFees() && this.soaConfig.fees[ rowIndex - idx - 1 ].matterType === this.matterType) {
          idx++;
        }
      }
      rowIndex = (rowIndex - idx < 0) ? 0 : rowIndex - idx;
      this.soaConfig.fees.splice(rowIndex, 0, newFee);
      this.selectedItem(this.soaConfig.fees[ rowIndex ]);
    } else {
      this.soaConfig.fees.push(newFee);
    }
    this.focusOnRow(newFee.identifier);
    this.dirtyNotifier.emit();
  }

  createNewFee(): SoaFee {
    let fee = new SoaFee();
    fee.code = fee.identifier.toString();
    fee.f9DefaultAmount = 0;
    fee.readOnly = false;
    fee.enabled = true;
    fee.matterType = this.matterType;
    return fee;
  }

  deleteFees(selectedSoaFee: SoaFee): void {
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete this Fee?', false, 'Delete').subscribe(res => {
      if (res) {
        // this.statementOfAccount.fees.splice(rowIndex, 1);
        let soaFeeFound = this.soaConfig.fees.find(soaFee => soaFee == selectedSoaFee);
        if (soaFeeFound) {
          soaFeeFound.deleted = true;
        }
        this.dirtyNotifier.emit();
      }
    });

  }

  onKeyPress(event: KeyboardEvent) {
    if (event.keyCode === CustomKeyCodesEnum.Down || event.keyCode === CustomKeyCodesEnum.Up) {
      this.handleArrowKeys(event);
    }
  }

  handleArrowKeys(event): void {
    let rowToSelect: number = -1;
    if (event.srcElement.id.startsWith('feeName-')) {
      event.preventDefault();
      if (event.keyCode === CustomKeyCodesEnum.Down) {
        if (this.selectedRowIndex < this.soaConfig.fees.length) {
          rowToSelect = this.selectedRowIndex + 1;
        }
      } else if (event.keyCode === CustomKeyCodesEnum.Up) {
        if (this.selectedRowIndex > 0) {
          rowToSelect = this.selectedRowIndex - 1;
        }
      }

      if (rowToSelect > -1) {
        this.selectedItem(this.fees[ rowToSelect ]);
        this.focusOnRow(this.fees[ rowToSelect ].identifier);
      }
    }
  }

  //Focus cursor on specifed row with a delay because newly added row may not exist yet.
  focusOnRow(identifier: number): void {
    setTimeout(() => {
      let elementId = 'feeName-' + identifier;
      let elementForFocus: HTMLElement = document.getElementById(elementId);
      if (elementForFocus) {
        elementForFocus.focus();
      }
    }, 200);
  }

  get selectedRowIndex(): number {
    return _.findIndex(this.fees, soaFeeObj => soaFeeObj.isSelected == true);
  }

  keyUpSelectedItem(event, soaFee: SoaFee) {
    if (event.keyCode == '9') {
      this.selectedItem(soaFee);
    }

  }

  //Set selected so row will be highlighted
  selectedItem(soaFee: SoaFee): void {
    setTimeout(() => {
      for (let i = 0; i < this.soaConfig.fees.length; i = i + 1) {
        this.soaConfig.fees[ i ].isSelected = false;
      }
      soaFee.isSelected = !soaFee.isSelected;
    }, 100);
  }

  moveItem(moveDirection: MoveDirection): void {
    let selectedSoaFeeIndex: number = _.findIndex(this.soaConfig.fees, soaFeeObj => soaFeeObj.isSelected == true);
    if (moveDirection === 'UP' && selectedSoaFeeIndex <= 0) {
      return;
    }
    let direction: number = moveDirection === 'UP' ? -1 : 1;
    let tempSoaFee: SoaFee;
    // some of the items that are grouped cannot have other items in the same exclusive group
    if (this.soaConfig.fees[ selectedSoaFeeIndex + direction ].isPartOfGroupedFees()) {
      let i: number = direction;
      while (this.soaConfig.fees[ selectedSoaFeeIndex + i ].isPartOfGroupedFees() &&
      (this.soaConfig.fees[ selectedSoaFeeIndex + i ].matterType === this.matterType)) {
        i += direction;
        if (selectedSoaFeeIndex + i < 0 || selectedSoaFeeIndex + i > this.soaConfig.fees.length - 1) {
          break;
        }
      }

      tempSoaFee = new SoaFee(this.soaConfig.fees[ selectedSoaFeeIndex ]);
      this.soaConfig.fees[ selectedSoaFeeIndex ] = null;
      let targetPosition = selectedSoaFeeIndex + i;
      if (targetPosition > this.soaConfig.fees.length - 1) { // last one is part of the group
        this.soaConfig.fees.push(tempSoaFee);
      } else {
        let offset = direction < 0 ? 1 : 0; //If moving up, we should place the element after element at targetPosition
        this.soaConfig.fees.splice(targetPosition + offset, 0, tempSoaFee);
      }
      if (this.soaConfig.soaSelectedTemplate) {
        this.soaConfig.soaSelectedTemplate.fees = this.soaConfig.fees.filter(Boolean);
      }
      this.soaConfig.orderFeesByMatterType();

    } else {
      tempSoaFee = this.soaConfig.fees[ selectedSoaFeeIndex + direction ];
      this.soaConfig.fees[ selectedSoaFeeIndex + direction ] = this.soaConfig.fees[ selectedSoaFeeIndex ];
      this.soaConfig.fees[ selectedSoaFeeIndex ] = tempSoaFee;
    }
    this.dirtyNotifier.emit();

  }

  moveUp(): void {
    this.moveItem('UP');
  }

  moveDown(): void {
    this.moveItem('DOWN');
  }

  reset(): void {
    this.dialogService.confirm('Confirmation', 'Do you wish to proceed to reset the ' + this.matterTypeCapitalized + ' Fees to the default settings?', false, 'Reset').subscribe(res => {
      if (res) {
        if (!this.context || this.context === 'ACCOUNT') { // when used on Account context reset with value from system account
          this.soaConfigService
          .getSystemStatementConfig(this.provinceCode)
          .subscribe((statementConfig: StatementConfig) => {
            this.applyStatementConfig(statementConfig);
          });
        }
        if (this.context && this.context === 'PROJECT') { // when used on Project context reset with value from current account
          let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
          this.soaConfigService
          .getStatementConfig(accountId, null, <ProvinceCode>this.provinceCode)
          .subscribe((statementConfig: StatementConfig) => {
            if (statementConfig.statementOfAccount && statementConfig.statementOfAccount.soaTemplates) {
              statementConfig.statementOfAccount.soaSelectedTemplate = statementConfig.statementOfAccount.soaTemplates.find(tp => tp.matterType == 'SALE' && !!tp.defaultTemplate);
            }
            this.applyStatementConfig(statementConfig);
          });
        }
      }
    });
  }

  private applyStatementConfig(statementConfig: StatementConfig) {
    if (statementConfig && statementConfig.statementOfAccount) {
      this.soaConfig.initializeSoaFees(statementConfig.statementOfAccount, this.matterType);
      this.dirtyNotifier.emit();
    }
  }

  get fees(): SoaFee[] {
    return this.soaConfig.feesForMatterType(this.matterType);
  }

  get matterTypeCapitalized(): string {
    return this.matterType ? _.capitalize(this.matterType) : '';
  }

  onSeparateFeeForMortgageChange(event) {

    this.soaConfig.fees.forEach(fee => {
      if (fee.isFeeForMortgage() && fee.matterType == 'PURCHASE') {
        fee.enabled = (!!event);
      }
    });
  }

  setFormDirty() {
    this.dirtyNotifier.emit();
  }

}

