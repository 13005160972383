<div class="container-fluid custom-modal-container">
    <form class="form-horizontal">
        <div #ReqEditModal>
            <div class="row modal-header">
                <div class=" col-lg-12">
                    <div class="col-lg-10"><h1>Requisition Editor</h1></div>
                    <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
                </div>
            </div>
            <div class="modal-body">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label col-lg-2">Requisition Name*</label>
                        <div class="col-lg-10">
                            <input class="form-control" id="requisition-name"
                                   name="requisition-name"
                                   maxlength="100"
                                   dp-error-validator
                                   [fieldKey]="'requisitionTemplate.key'"
                                   [(ngModel)]="requisitionTemplate.key">
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label col-lg-2">Require: undertaking to</label>
                        <div class="col-lg-10">
                            <input class="form-control" id="require"
                                   name="require"
                                   maxlength="100"
                                   (keydown.f9)="handleF9Undertakings()"
                                   statusBarHelp
                                   [dynamicHelpCallBackFunction] = "'F9 = obtain and register a good and valid discharge of the'"
                                   [(ngModel)]="requisitionTemplate.require" >
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label col-lg-2">Type of encumbrance</label>
                        <div class="col-lg-10">
                            <input class="form-control" id="encumbrance"
                                   name="encumbrance"
                                   maxlength="100"
                                   (keydown.f9)="handleF9Encumbrance()"
                                   statusBarHelp
                                   [dynamicHelpCallBackFunction] = "'type of encumbrance being requisitioned; eg. mortgage, covenants. F9= <<8>> Encumbrance Type'"
                                   [(ngModel)]="requisitionTemplate.encumbranceType"  >
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label col-lg-2">Registration number</label>
                        <div class="col-lg-10">
                            <input class="form-control" id="registration-number"
                                   name="registration-number"
                                   maxlength="50"
                                   (keydown.f9)="handleF9RegistrationNumber()"
                                   statusBarHelp
                                   [dynamicHelpCallBackFunction] = "'Requisition number of encumbrance being requisition; eg. LT 344333. F9= <<1>> Instrument No.'"
                                   [(ngModel)]="requisitionTemplate.registrationNumber" >
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="control-label col-lg-2">in favour of</label>
                        <div class="col-lg-10">
                            <input class="form-control" id="in-favour-of"
                                   name="in-favour-of"
                                   maxlength="100"
                                   (keydown.f9)="handleF9InFavourOf()"
                                   statusBarHelp
                                   [dynamicHelpCallBackFunction] = "'Party in favour of whom the encumbrance is registered; eg. name of mortgagee. F9= <<6>> Party To'"
                                   [(ngModel)]="requisitionTemplate.inFavourOf"  >
                        </div>
                    </div>
                </div>
                <div class=" form-group col-lg-12">
                    <label style="text-align: left;" class="control-label col-lg-5" >Full text of requisition </label>
                </div>
                <div class="row padding-top-20">
                    <div class="col-lg-12 hide-tool-bar">

                        <div style="position: absolute; margin-left: 400px; padding-top: 5px;"  *ngIf="isCategoryVisible()">
                            <label class="control-label col-lg-1 col-lg-3-75" >Group ID: </label>
                            <div class="col-lg-1" >
                                <select class="form-control col-lg-3-75"  id="requisitionCategory" name="requisitionCategory"
                                        [(ngModel)]="requisitionTemplate.requisitionCategory"   >
                                    <option *ngFor="let requisitionCategoryOption of requisitionCategoryOptions" [value]="requisitionCategoryOption.value">{{requisitionCategoryOption.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div style="position: absolute; margin-left: 535px; padding-top: 5px;" >
                            <label class="control-label col-lg-6 col-lg-6-75" >Merge Code: </label>
                            <div class="col-lg-4" >
                                <select class="form-control col-lg-6-75"  id="mergeCode" name="mergeCode"
                                        [(ngModel)]="requisitionCode" (change)="onCodeChange(requisitionCode)"    >
                                    <option *ngFor="let requisitionCodeOption of requisitionCodeOptions" [value]="requisitionCodeOption.value">{{requisitionCodeOption.label}}</option>
                                </select>
                            </div>
                        </div>
                        <textarea name="rteditorModal" id="rteditorModal"
                                  rows="10" cols="80"
                                  [(ngModel)]="requisitionTemplate.requisitionText"
                        ></textarea>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <div class="col-lg-12 buttons">

                    <button type="button" id="okBtn2" class="dp-btn keyboard-save-action" [disabled]="disableOK" (click)="onOK()" #okButtonControl>
                        <span>OK</span>
                    </button>

                    <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </form>
</div>
