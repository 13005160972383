import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';

export class DocumentProfileSoa extends BaseEntity {

  constructor(documentProfileSoa?: DocumentProfileSoa) {
    super(documentProfileSoa);

    if (documentProfileSoa) {
      for (let prop in documentProfileSoa) {
        if (documentProfileSoa.hasOwnProperty(prop)) {
          this[ prop ] = documentProfileSoa[ prop ];
        }
      }
    }
  }

  id: number;
  sourceStatementOfAdjustmentsProfileId;
  sameAsDefaultProfileFlag: boolean;
  printBalanceFlag: boolean;
  includeVendorHstFlag: boolean;
  paperSizeCode: string;

  directionOfFundsPaperSizeCode: string;

  estTaxIncrease: string;

  balanceDueOnClosing: string;
  soaHeading: string;

  otherHeadingsVendor: string;
  otherHeadingsVendorDataCode: string;

  otherHeadingsPurchaser: string;
  otherHeadingsPurchaserDataCode: string;

  otherHeadingsAddress: string;
  otherHeadingsAddressDataCode: string;

  otherHeadingsAdjustDate: string;
  otherHeadingsAdjustDateDataCode: string;

  wordingDirectionFunds: string;

  headingReportVendor: string;

  showNetSalePriceCode: boolean;

  preferredTerminologyCode: string;

  rightColumnListsCreditsTo: string;

  leftColumnListsCreditsTo: string;

  referenceStatementOfAdjustments: boolean;

  //Rate of Interest on Prepaid Rent  <<only for NS, NB>>
  interestRateOnPrepaidRent: number;
  //Show net sale price in HST On Sale Price and HST Rebate Adjustments?  <<for all provinces>>
  showNetSalePriceInAdjustment: string;

  paysForDateOfClosing: string;
}
