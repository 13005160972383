import {Injectable} from '@angular/core';
import {DocumentTemplateCategory} from './document-template-category';
import {DocumentTemplate} from './document-template';
import {UserConfig} from '../shared/user-config';

@Injectable()
export class DocumentProductionUtilityService {

  constructor() {
  }

  getDocumentProductionCategoryByMatterId(documentTemplates: DocumentTemplate[], applicableMatterTypeCode: string): any[] {
    let categories: DocumentTemplateCategory[] = [];
    documentTemplates.forEach(item => {
      if (categories.length == 0) {
        categories = categories.concat(item.documentTemplateCategories);
      } else {
        for (let i = 0; i < item.documentTemplateCategories.length; i++) {
          let element = categories.find(cat => {
            return cat.id == item.documentTemplateCategories[ i ].id;
          });
          if (element == undefined) {
            categories.push(item.documentTemplateCategories[ i ]);
          }
        }
      }
    });
    categories = categories.filter(cat => cat.applicableMatterTypeCode == applicableMatterTypeCode);
    categories = categories.sort((a: DocumentTemplateCategory, b: DocumentTemplateCategory) => {
      if (a.defaultCategory) {
        return -1;
      } else {
        return (a.categoryDescription.toLowerCase() < b.categoryDescription.toLocaleLowerCase() && !b.defaultCategory) ? -1 : 1;
      }
    });
    return categories;

  }

  getDefaultDocumentProductionCategory(userConfig: UserConfig, matterType: string, categories: DocumentTemplateCategory[]): number {
    let defaultCat: number;

    let documentProductionDefaultCategories = userConfig.documentProductionDefaultCategories;
    let docProdDefaultCat = documentProductionDefaultCategories.find(cat => {
      return cat.matterType == matterType;
    });
    if (docProdDefaultCat) {
      let defaultCategory = categories.find(item => {
        return item.categoryDescription == docProdDefaultCat.defaultCategory;
      });
      if (defaultCategory) {
        defaultCat = defaultCategory.id;
      } else {
        let defaultCategory = categories.find(item => {
          return item.defaultCategory;
        });
        if (defaultCategory) {
          defaultCat = defaultCategory.id;
        }
      }
    } else {
      let defaultCategory = categories.find(item => {
        return item.defaultCategory;
      });
      if (defaultCategory) {
        defaultCat = defaultCategory.id;
      }
    }

    return defaultCat;
  }
}
