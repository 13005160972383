<div
    tabindex="-1" class="dp-accordion-shutter" [ngClass]="{'primary-accordion': primary, 'offset-other-accordion': offsetOther , 'primary-other-accordion': primaryOther, 'secondary-accordion': secondary, 'hide-shutters': hideShutter}">
    <div [ngClass]="{'shutter-bg': expanded,'shutter-bg-closed': !expanded, 'offset-accordion': offset }" tabindex="-1">
        <div (click)="toggleAccordion($event);" (keydown)="toggleAccordion($event);" class="accordion-wrapper form-outline" tabindex="0">
            <div class="shutter-arrow">
                    <div *ngIf="expanded">
                            <img src="/assets/img/minus.svg" alt="" />
                    </div>
                    <div *ngIf="!expanded">
                            <img src="/assets/img/plus.svg" />
                    </div>
            </div>
            <ng-content select=".dp-accordion-header"></ng-content>
        </div>
        <div [hidden]="!expanded"><ng-content select=".dp-accordion-content"></ng-content></div>
    </div>
</div>
