import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {Deposit} from '../../shared/deposit';
import {StatementAdjustment} from '../statement-adjustment';
import {ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD, ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT} from '../../../shared-main/constants';

export class StatementAdjustmentDisplayDepositBuilder {
  static getDisplayItems(mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    const matter = mainBuilder.matter;

    let deposits: Deposit[] = matter.matterPropertyWithCondo.depositNameAvailableDeposits;

    if (deposits.length >= ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD) {

      for (let i: number = 0; i < ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT; i++) { // display only first 8 items
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ deposits[ i ].depositName }`,
          `${ deposits[ i ].depositAmount !== '0' ? mainBuilder.getCurrencyFromString(deposits[ i ].depositAmount) : '' }`));
      }

      sadItemDetails.push(mainBuilder.getNewItemLine(`.`, ``));
      sadItemDetails.push(mainBuilder.getNewItemLine(`.`, ``));
      sadItemDetails.push(mainBuilder.getNewItemLine(`.`, ``));
      sadItemDetails.push(mainBuilder.getNewItemLine(`${ deposits[ deposits.length - 1 ].depositName }`,
        `${ mainBuilder.isStringCurrencyZero(deposits[ deposits.length - 1 ].depositAmount) ? '' : mainBuilder.getCurrencyFromString(deposits[ deposits.length - 1 ].depositAmount) }`));
    } else {

      for (let i: number = 0; i < deposits.length; i++) {
        sadItemDetails.push(mainBuilder.getNewItemLine(`${ deposits[ i ].depositName }`,
          `${ mainBuilder.isStringCurrencyZero(deposits[ i ].depositAmount) ? '' : mainBuilder.getCurrencyFromString(deposits[ i ].depositAmount) }`));
      }
    }

    if (matter && (matter.isProjectSale || matter.templateForProject || (matter.project && matter.isPurchase && !!matter.matterLink)) && matter.project && matter.project.isCondo && matter.isAdjustmentStatusModeFinal) {
      const amt = (matter.extraDepositConfig && matter.extraDepositConfig.depositOnOccupancyAmount) ? String(matter.extraDepositConfig.depositOnOccupancyAmount) : '';
      let lineItem = mainBuilder.getNewItemLine('Deposit paid on occupancy', `${ mainBuilder.isStringCurrencyZero(amt) ? '' : mainBuilder.getCurrencyFromString(amt) }`);
      sadItemDetails.push(lineItem);
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ mainBuilder.purchaserLabel }`, ``));

    return sadItemDetails;
  }

  /**
   * @param {StatementAdjustmentDisplayBuilder} mainBuilder
   * @param {StatementAdjustment} soaItem
   * @return {string} the formatted string of the recalculated soaItem.amount
   */
  static recalculateCreditPurchaserForDeposit(mainBuilder: StatementAdjustmentDisplayBuilder, soaItem: StatementAdjustment): string {
    const amount: number = mainBuilder.matter.calculateCreditPurchaserForDeposit();
    soaItem.amount = amount;
    return mainBuilder.formatCurrency(amount);
  }

}
