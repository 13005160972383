import {Component, Inject, ViewChild} from '@angular/core';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {AdjustmentTemplate} from '../../model/adjustment-template';
import {StatementAdjustment} from '../../statement-adjustment';
import {ModalResult} from '../../../../shared-main/enums';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {AdjustmentTemplateService} from '../../adjustment-template.service';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class AdjustmentTemplateSavePredefinedModalContext {
  public statementAdjustment: StatementAdjustment;
  public modalHeader: string;
}

@Component({
  selector: 'dp-adjustment-template-save-predefined',
  templateUrl: 'adjustment-template-save-predefined.modal.component.html',
  providers: [ErrorService]
})
export class AdjustmentTemplateSavePredefinedModalComponent extends ModalComponent<AdjustmentTemplateSavePredefinedModalContext> {
  adjustmentTemplate: AdjustmentTemplate;
  activeListItem: number = -1;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<AdjustmentTemplateSavePredefinedModalComponent>,
    public dialogService: DialogService,
    public adjustmentTemplateService: AdjustmentTemplateService,
    @Inject(MAT_DIALOG_DATA) context?: AdjustmentTemplateSavePredefinedModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.adjustmentTemplate = new AdjustmentTemplate();
    this.adjustmentTemplate.statementOfAdjustment = this.context.statementAdjustment;
  }

  async save() {
    this.modalErrorComponent.removeAllDpSaveError();
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      await this.saveTemplate();
      this.dialog.close({action: ModalResult.OK});
    }
  }

  cancel() {
    this.dialog.close({action: ModalResult.Cancel});
  }

  checkForErrors() {
    if (!this.adjustmentTemplate.name) {
      this.modalErrorComponent.createDPSaveError('matter.soadj.component.template.name');
    }
  }

  async saveTemplate() {
    let adjustmentTemplates: AdjustmentTemplate[] = await this.adjustmentTemplateService
      .getAllTemplateAdjustmentsByItemKey(this.context.statementAdjustment.itemKey)
      .toPromise();
    if (Array.isArray(adjustmentTemplates)) {
      const adjTemplate: AdjustmentTemplate = adjustmentTemplates.find(
        (item) => item.name === this.adjustmentTemplate.name
      );
      this.adjustmentTemplate.id = adjTemplate && adjTemplate.id;
    }

    if (this.adjustmentTemplate.id) {
      return this.adjustmentTemplateService
        .updateAdjustmentTemplate(new AdjustmentTemplate(this.adjustmentTemplate))
        .toPromise();
    } else {
      return this.adjustmentTemplateService
        .createAdjustmentTemplate(new AdjustmentTemplate(this.adjustmentTemplate))
        .toPromise();
    }
  }
}
