export const matterLabels = {
  writStatusKeyLabelMap: [
    {label: 'not searched', value: 'NOT_SEARCHED'},
    {label: 'Clear', value: 'CLEAR'},
    {label: 'Executions', value: 'EXECUTION'}
  ],
  writPartyOriginKeyLabelMap: [
    {label: 'current owner', value: 'CURRENT_OWNER'},
    {label: 'purchaser', value: 'PURCHASER'},
    {label: 'vendor', value: 'VENDOR'},
    {label: 'mortgagor', value: 'MORTGAGOR'},
    {label: 'guarantor', value: 'GUARANTOR'},
    {label: 'condo corp.', value: 'CONDO_CORPORATION'},
    {label: 'owner', value: 'OWNER'},
    {label: 'transferee', value: 'TRANSFEREE'},
    {label: 'user added name', value: 'USER_ADDED'}
  ]
};
