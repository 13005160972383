import {Component, Inject, OnInit} from '@angular/core';
import {Matter} from '../../shared/matter';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MatterService} from '../../matter.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class RequestResetModalContext {
  public matter: Matter;
}

@Component({
  selector: 'dp-request-reset-modal-content',
  templateUrl: 'request-reset-modal.component.html'
  // styleUrls      : ['./.modal.component.scss']
})
export class RequestResetModalComponent extends ModalComponent<RequestResetModalContext> implements OnInit {
  matter: Matter;
  certifiedBy: string;

  constructor(
    public dialog: MatDialogRef<RequestResetModalComponent>,
    public matterService: MatterService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: RequestResetModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.matter = this.context.matter;
    console.log(this.matter);
  }

  submit() {
    if (this.isValid) {
      let message =
        '<p class="padding-bottom-10">You are about to reset ' +
        this.matter.matterRecordNumber +
        '</p>' +
        '<p class="padding-bottom-20">' +
        this.requestInfo +
        '</p>' +
        '<p>Proceed to submit request for reset?</p>';
      this.dialogService
        .confirm('Request Transaction Reset', message, false, 'Submit Request', 'Cancel', false, '', true)
        .subscribe((res) => {
          if (res) {
            this.matterService.requestReset(this.matter.id, this.certifiedBy).subscribe(
              (matter) => {
                this.matter = matter;
                this.showSuccessDialog();
              },
              (error) => {
                this.showFailureDialog();
              }
            );
          } else {
            this.dialog.close();
          }
        });
    }
  }

  showSuccessDialog(): void {
    let message =
      '<p class="padding-bottom-10">Your request to reset the transaction fee for ' +
      this.matter.matterRecordNumber +
      '</p>' +
      '<p class="padding-bottom-20">' +
      this.requestInfo +
      '</p>' +
      '<p>was successfully processed by Unity Software.</p>';
    this.dialogService.confirm('Information', message, true).subscribe((res) => {
      this.dialog.close(this.matter);
    });
  }

  showFailureDialog(): void {
    let message = 'The reset request cannot be processed at this time - Please try again later.';
    this.dialogService.confirm('ERROR - Unity Billing not available ', message, true).subscribe((res) => {
      this.dialog.close();
    });
  }

  close() {
    this.dialog.close();
  }

  get matterFromTo(): string {
    switch (this.matter.matterType) {
      case 'PURCHASE':
        return 'p/f';
      case 'SALE':
        return 's/t';
      case 'MORTGAGE':
        return 'm/f';
      default:
        return null;
    }
  }

  get isValid(): boolean {
    return this.certifiedBy != undefined && this.certifiedBy != '';
  }

  get requestInfo(): string {
    let str = '';

    if (this.matter.clientReLine) {
      str += this.matter.clientReLine + ' ';
    }

    str += this.matterFromTo + ' ';

    if (this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline) {
      str += this.matter.otherPartyContactInfo.reline;
    }

    str += '; ';
    if (
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.address &&
      this.matter.matterPropertyWithCondo.address.addressText
    ) {
      str += this.matter.matterPropertyWithCondo.address.addressTextWithoutProvinceAndPostalCode;
    }

    return str;
  }
}
