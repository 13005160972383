import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../../../accounts/shared/account-province';
import {DocumentProcessorType} from '../../../accounts/shared/account';
import Utils from '../../../../shared-main/utils';
import {ProducibleDocType} from '../../../../matters/document-production/document-production-data';

export class DocumentProfileTemplateFolder extends BaseEntity {
  constructor(documentProfileTemplateFolder?: DocumentProfileTemplateFolder) {
    super(documentProfileTemplateFolder);
  }

  id: number;
  customerAccountId: number;
  defaultFolder: boolean;
  templateFolderName: string;
  sourceAccountFolderId: number;
  folderPath: string;
  provinceCode: ProvinceCode;
  templateProcessorType: DocumentProcessorType;

  get templateFolderNameWithProcessorType(): string {
    return this.templateFolderName + (this.templateProcessorType ? (' - ' + Utils.getProcessorTypeLabelByValue(this.templateProcessorType)) : '');
  }

  get isWordProcessorType(): boolean {
    return this.templateProcessorType == 'MS_WORD';
  }

  get isWpdProcessorType(): boolean {
    return this.templateProcessorType === 'WORD_PERFECT';
  }

  get isRtfProcessorType(): boolean {
    return this.templateProcessorType === 'RICH_TEXT_FORMAT';
  }

  get producibleDocType(): ProducibleDocType {
    if (this.isWordProcessorType) {
      return 'WORD';
    }
    if (this.isWpdProcessorType) {
      return 'WORDPERFECT';
    }
    if (this.isRtfProcessorType) {
      return 'RTF';
    }
    return 'WORD';
  }
}
