export class InviteeWrapper {
  label: string;
  secondLabel: string;
  value: string; //Using the value as string to allow using 'ALL' in Multiselect
  email: string;

  constructor(label: string, secondLabel: string, value: string, email: string) {
    this.label = label;
    this.secondLabel = secondLabel;
    this.value = value;
    this.email = email;

  }
}
