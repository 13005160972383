import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, Inject} from '@angular/core';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {Matter} from '../../../shared/matter';
import {TeranetImportMortgage} from '../../../../shared-main/teranet/teranet-import-mortgage';
import {DdDispositionType} from '../../../shared/mortgage';
import {mortgageDispositionTypeOptions} from '../../../mortgages/mortgage/dropdown-options';
import {MortgageDispositionType} from '../../../../shared-main/constants';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class TeranetImportMortgageContext {
  matter: Matter;
  selectedParcelRegister: ParcelRegister;
}

interface TeranetImportInstrument {
  teranetConnectMortgage: TeranetImportMortgage;
  matterMortgage: TeranetImportMortgage;
  isCopied: boolean;
  isMortgageIdentical: boolean;
}

@Component({
  selector: 'dp-teranet-import-mortgage-modal-content',
  templateUrl: 'teranet-import-mortgage.modal.component.html',
  providers: [ErrorService]
})
export class TeranetImportMortgageModal extends ModalComponent<TeranetImportMortgageContext> {
  instruments: any[] = [];
  mortgageDispositionTypeItems: DdDispositionType[];
  teranectConnectInstruments: TeranetImportMortgage[] = [];
  matterInstruments: TeranetImportMortgage[] = [];
  allFields: boolean = false;
  dispositionTypes: Map<TeranetImportInstrument, boolean> = new Map();

  constructor(
    public dialog: MatDialogRef<TeranetImportMortgageModal>,
    @Inject(MAT_DIALOG_DATA) context?: TeranetImportMortgageContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.matter && this.context.matter.isSale) {
      this.mortgageDispositionTypeItems = mortgageDispositionTypeOptions.filter(
        (item) => item.value != MortgageDispositionType.REMAIN && item.value != MortgageDispositionType.BRIDGE_FINANCING
      );
    } else if (this.context.matter && this.context.matter.isMortgage) {
      this.mortgageDispositionTypeItems = mortgageDispositionTypeOptions.filter(
        (item) =>
          item.value != MortgageDispositionType.ASSUMED && item.value != MortgageDispositionType.BRIDGE_FINANCING
      );
    } else {
      this.mortgageDispositionTypeItems = mortgageDispositionTypeOptions.filter(
        (item) => item.value != MortgageDispositionType.REMAIN
      );
    }
    if (this.context.selectedParcelRegister) {
      this.context.selectedParcelRegister.instrumentsNonDeletedNonTransferType().forEach((item) => {
        let teranetImportMortgage = new TeranetImportMortgage();
        teranetImportMortgage.mapInstrumentData(item);
        this.teranectConnectInstruments.push(teranetImportMortgage);
      }, this);
    }
    if (this.context.matter && this.context.matter.existingMortgages) {
      this.context.matter.existingMortgages.forEach((item, index) => {
        let teranetImportMortgage = new TeranetImportMortgage();
        teranetImportMortgage.mapMortgageData(item, this.context.matter);
        this.matterInstruments.push(teranetImportMortgage);
      }, this);
    }
    this.teranectConnectInstruments.forEach((item, index) => {
      let mortgageMatchingFound: TeranetImportMortgage;
      let instrument: TeranetImportInstrument;
      if (this.matterInstruments.length > 0 && this.matterInstruments.length > 0) {
        mortgageMatchingFound = this.matterInstruments[index];
        instrument = {
          teranetConnectMortgage: item,
          matterMortgage: new TeranetImportMortgage(mortgageMatchingFound),
          isCopied: false,
          isMortgageIdentical: item.isMortgageMatchingFound(mortgageMatchingFound)
        };
      } else {
        instrument = {
          teranetConnectMortgage: item,
          matterMortgage: new TeranetImportMortgage(),
          isCopied: false,
          isMortgageIdentical: false
        };
      }
      this.setDefaultDispositionType(instrument);
      this.instruments.push(instrument);
      this.dispositionTypes.set(instrument, instrument.matterMortgage.isMortgageDispositionType);
    });
    if (this.matterInstruments.length > this.teranectConnectInstruments.length) {
      this.matterInstruments.forEach((item, index) => {
        if (index >= this.teranectConnectInstruments.length) {
          this.instruments.push({
            teranetConnectMortgage: undefined,
            matterMortgage: new TeranetImportMortgage(item),
            isCopied: false,
            isMortgageIdentical: false
          });
        }
      });
    }
  }

  setDefaultDispositionType(instrument): void {
    if (!instrument.teranetConnectMortgage || instrument.isMortgageIdentical) {
      return;
    }
    if (this.context.matter.isMortgage) {
      instrument.matterMortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
    } else {
      instrument.matterMortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
    }
  }

  ok(): void {
    this.dialog.close(this.instruments);
  }

  close(): void {
    this.dialog.close();
  }

  updateAllFields(event): void {
    this.instruments.map((item) => {
      item.isCopied = event;
      this.updateMortgage(item);
    }, this);
  }

  getFormatted(item: TeranetImportMortgage): string {
    let formattedStrTeranetImport = '';
    formattedStrTeranetImport =
      formattedStrTeranetImport + 'Instrument Type: ' + (item.instrumentType ? item.instrumentType : '') + '\n';
    formattedStrTeranetImport =
      formattedStrTeranetImport + 'Registration Date: ' + (item.registrationDate ? item.registrationDate : '') + '\n';
    formattedStrTeranetImport =
      formattedStrTeranetImport + 'Registration No: ' + (item.registrationNo ? item.registrationNo : '') + '\n';
    formattedStrTeranetImport =
      formattedStrTeranetImport + 'Parties To: ' + (item.mortgageesText ? item.mortgageesText : '');
    return formattedStrTeranetImport;
  }

  updateMortgage(item: any): void {
    if (!item.isMortgageIdentical) {
      if (item.isCopied) {
        if (item.teranetConnectMortgage) {
          item.teranetConnectMortgage.existingMortgageId = item.matterMortgage
            ? item.matterMortgage.existingMortgageId
            : undefined;
          item.teranetConnectMortgage.isMortgageDispositionType = item.matterMortgage.isMortgageDispositionType;
          item.matterMortgage = new TeranetImportMortgage(item.teranetConnectMortgage);
          this.setDefaultDispositionType(item);
        }
      } else {
        if (item.teranetConnectMortgage) {
          item.matterMortgage = new TeranetImportMortgage();
          this.setDefaultDispositionType(item);
          if (item.teranetConnectMortgage.existingMortgageId != undefined) {
            let existingMortgage = this.context.matter.existingMortgages.find(
              (eMortgage) => eMortgage.id == item.teranetConnectMortgage.existingMortgageId
            );
            if (existingMortgage) {
              item.matterMortgage.mapMortgageData(existingMortgage, this.context.matter);
            }
          }
          item.teranetConnectMortgage.existingMortgageId = undefined;
        }
      }
    }
  }

  isMortgageIsToShow(item: TeranetImportInstrument): boolean {
    return item.matterMortgage && item.teranetConnectMortgage && item.isCopied && !this.dispositionTypes.get(item);
  }

  get isImportButtonEnabled() {
    return this.instruments.some((item) => item.isCopied);
  }
}
