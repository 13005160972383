import {BaseEntity} from '../../../app/shared/BaseEntity/base-entity';
import {Contact} from '../../../app/matters/shared/contact';

export type ContactAssociationRole = 'SIGNING_OFFICER' | 'ESTATE_TRUSTEE' | 'POWER_OF_ATTORNEY';

export class ContactAssociation extends BaseEntity {

  id: number;
  associatedContact: Contact;
  parentContactId: number;
  contactAssociationType: ContactAssociationRole;
  associatedContactTitle: string;
  associatedContactLockAcquired: boolean;

  constructor(associatedContact?: ContactAssociation) {
    super(associatedContact);
    if (associatedContact) {
      if (associatedContact.associatedContact) {
        this.associatedContact = new Contact(associatedContact.associatedContact);
      }

    }
  }

  equals(x, a?): boolean {
    if (!x) {
      return false;
    }
    if (typeof (a) === 'undefined') {
      a = this;
    }
    return (x.associatedContact.id == a.associatedContact.id && x.associatedContactTitle == a.associatedContactTitle);
  }

}
