import {Matter} from './matter';
import {MatterOpeningUtil} from '../matter-opening/matter-opening-util';

export type OpportunityCopyParamType = 'NO' | 'YES' | 'REPLACE' | 'ADD';
export type OpportunityCopyFieldType =
  'SOLICITOR'
  | 'PROSPECT'
  | 'MATTER_CLOSE_DATE'
  | 'PROPERTY_ADDRESS'
  | 'PURCHASE_IS_OF_CODE'
  | 'PROPERTY_STICKY_NOTE'
  | 'PURCHASE_PRICE'
  | 'DEPOSIT_AMOUNT'
  | 'REAL_ESTATE_AGENT'
  | 'REAL_ESTATE_BROKER'
  | 'TOTAL_COMMISSION'
  | 'REFERRAL'
  | 'MATTER_OPENING_STICKY_NOTE'
  |
  'PURCHASER_STICKY_NOTE'
  | 'NOTE'
  | 'NOTE_STICKY_NOTE'
  | 'OTHER_SIDE_CLIENT'
  | 'OTHER_SIDE_LAW_FIRM_AND_SOLICITOR'
  | 'OTHER_SIDE_STICKY_NOTES'
  | 'MORTGAGE_NOTES'
  |
  'NEW_MORTGAGE_AMOUNT'
  | 'NEW_MORTGAGE_TYPE_OF_MORTGAGEE'
  | 'NEW_MORTGAGE_MORTGAGEE'
  | 'EXISTING_MORTGAGE_AMOUNT'
  | 'EXISTING_MORTGAGE_TYPE_OF_MORTGAGEE'
  | 'EXISTING_MORTGAGE_MORTGAGEE';

export const OpportunityCopyParamTypeValue = {
  No: <OpportunityCopyParamType>'NO',
  Yes: <OpportunityCopyParamType>'YES',
  Replace: <OpportunityCopyParamType>'REPLACE',
  Add: <OpportunityCopyParamType>'ADD'
};

export class OpportunityCopyParam {

  recordsToBeCopied: OpportunityCopyParamItem[] = [];

  // initializes all copy parameters for data that can be copied over to existing matter
  initializeFromOpportunity(opportunity: Matter, existingMatter: Matter) {
    this.recordsToBeCopied = [];
    if (opportunity.solicitor && opportunity.solicitor.contact) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('SOLICITOR', OpportunityCopyParamTypeValue.No, opportunity.solicitor.contact.id));
    }
    if (opportunity.matterCloseDate) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('MATTER_CLOSE_DATE', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.matterPropertyWithCondo && opportunity.matterPropertyWithCondo.address && opportunity.matterPropertyWithCondo.address.addressText) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('PROPERTY_ADDRESS', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.getMatterTopicByKey('PROPERTY_STICKY_NOTE') && opportunity.getMatterTopicByKey('PROPERTY_STICKY_NOTE').topicNote) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('PROPERTY_STICKY_NOTE', OpportunityCopyParamTypeValue.No));
    }
    if ((existingMatter.isPurchase || existingMatter.isSale) && opportunity.matterPropertyWithCondo && opportunity.matterPropertyWithCondo.purchaseIsOfCode) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('PURCHASE_IS_OF_CODE', OpportunityCopyParamTypeValue.No));
    }
    if ((existingMatter.isPurchase || existingMatter.isSale) && opportunity.matterPropertyWithCondo && opportunity.matterPropertyWithCondo.purchasePrice) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('PURCHASE_PRICE', OpportunityCopyParamTypeValue.No));
    }
    if ((existingMatter.isPurchase || existingMatter.isSale) && opportunity.matterPropertyWithCondo && opportunity.matterPropertyWithCondo.depositAmount > 0) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('DEPOSIT_AMOUNT', OpportunityCopyParamTypeValue.No));
    }
    if ((existingMatter.isPurchase || existingMatter.isSale) && opportunity.realEstateAgent) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('REAL_ESTATE_AGENT', OpportunityCopyParamTypeValue.No));
    }
    if ((existingMatter.isPurchase || existingMatter.isSale) && opportunity.selectedBrokerId) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('REAL_ESTATE_BROKER', OpportunityCopyParamTypeValue.No));
    }
    if (existingMatter.isSale && existingMatter.brokerCommission && opportunity.opportunity.totalCommission > 0) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('TOTAL_COMMISSION', OpportunityCopyParamTypeValue.No));
    }
    if (MatterOpeningUtil.getReferredBy(opportunity)) {
      if (MatterOpeningUtil.getReferredBy(existingMatter)) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('REFERRAL', OpportunityCopyParamTypeValue.Replace));
      } else {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('REFERRAL', OpportunityCopyParamTypeValue.No));
      }
    }
    if (opportunity.getMatterTopicByKey('MATTER_OPENING') && opportunity.getMatterTopicByKey('MATTER_OPENING').topicNote) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('MATTER_OPENING_STICKY_NOTE', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.mainClients && opportunity.mainClients.length) {
      this.recordsToBeCopied.push(...opportunity.mainClients.map(value => {
          return new OpportunityCopyParamItem('PROSPECT', OpportunityCopyParamTypeValue.No, value.matterParticipantId);
        }
      ));
    }
    if (opportunity.getMatterTopicByKey('PURCHASER') && opportunity.getMatterTopicByKey('PURCHASER').topicNote) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('PURCHASER_STICKY_NOTE', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.otherSideClients && opportunity.otherSideClients.length) {
      this.recordsToBeCopied.push(...opportunity.otherSideClients.map(value => {
          return new OpportunityCopyParamItem('OTHER_SIDE_CLIENT', OpportunityCopyParamTypeValue.No, value.matterParticipantId);
        }
      ));
    }
    if (opportunity.otherPartyLawFirm && opportunity.otherPartySolicitor) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('OTHER_SIDE_LAW_FIRM_AND_SOLICITOR', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.getMatterTopicByKey('VENDOR_SOLICITOR') && opportunity.getMatterTopicByKey('VENDOR_SOLICITOR').topicNote) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('OTHER_SIDE_STICKY_NOTES', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.notesList && opportunity.notesList.notes && opportunity.notesList.notes.length) {
      this.recordsToBeCopied.push(...opportunity.notesList.notes.map(value => {
          return new OpportunityCopyParamItem('NOTE', OpportunityCopyParamTypeValue.No, value.id);
        }
      ));
    }
    if (opportunity.getMatterTopicByKey('NOTES') && opportunity.getMatterTopicByKey('NOTES').topicNote) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('NOTE_STICKY_NOTE', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.opportunity && opportunity.opportunity.mortgageNotes && opportunity.opportunity.mortgageNotes.length) {
      this.recordsToBeCopied.push(new OpportunityCopyParamItem('MORTGAGE_NOTES', OpportunityCopyParamTypeValue.No));
    }
    if (opportunity.newMortgages && opportunity.newMortgages.length && (existingMatter.isPurchase || existingMatter.isMortgage) && (existingMatter.newMortgages.length <= 1)) {
      const newMortgage = opportunity.newMortgages[ 0 ];
      if (newMortgage.mortgageTerm && newMortgage.mortgageTerm.principal) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('NEW_MORTGAGE_AMOUNT', OpportunityCopyParamTypeValue.No));
      }
      if (newMortgage.mortgageeType) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('NEW_MORTGAGE_TYPE_OF_MORTGAGEE', OpportunityCopyParamTypeValue.No));
      }
      if ((newMortgage.isMortgageePrivateLender() && opportunity.getPrivateLenders(newMortgage).length) || (!newMortgage.isMortgageePrivateLender() && opportunity.getMortgagees(newMortgage).length)) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('NEW_MORTGAGE_MORTGAGEE', OpportunityCopyParamTypeValue.No));
      }
    }
    if (opportunity.existingMortgages && opportunity.existingMortgages.length && (existingMatter.isSale || existingMatter.isMortgage) && (existingMatter.existingMortgages.length <= 1)) {
      const existingMortgage = opportunity.existingMortgages[ 0 ];
      if (existingMortgage.mortgageTerm && existingMortgage.mortgageTerm.principal) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('EXISTING_MORTGAGE_AMOUNT', OpportunityCopyParamTypeValue.No));
      }
      if (existingMortgage.mortgageeType) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('EXISTING_MORTGAGE_TYPE_OF_MORTGAGEE', OpportunityCopyParamTypeValue.No));
      }
      if ((existingMortgage.isMortgageePrivateLender && opportunity.getPrivateLenders(existingMortgage).length) || (!existingMortgage.isMortgageePrivateLender() && opportunity.getMortgagees(existingMortgage).length)) {
        this.recordsToBeCopied.push(new OpportunityCopyParamItem('EXISTING_MORTGAGE_MORTGAGEE', OpportunityCopyParamTypeValue.No));
      }
    }
  }

  // used for select/unselect all params
  revertActionOnAllRecords() {
    this.recordsToBeCopied.forEach(value => {
      if (value.fieldName == 'PROSPECT' || value.fieldName == 'OTHER_SIDE_CLIENT') {
        if (value.copyAction === OpportunityCopyParamTypeValue.No) {
          value.copyAction = OpportunityCopyParamTypeValue.Add;
        } else {
          value.copyAction = OpportunityCopyParamTypeValue.No;
        }
      } else {
        if (value.copyAction === OpportunityCopyParamTypeValue.No) {
          value.copyAction = OpportunityCopyParamTypeValue.Yes;
        } else if (value.copyAction === OpportunityCopyParamTypeValue.Yes) {
          value.copyAction = OpportunityCopyParamTypeValue.No;
        }
        if (value.copyAction === OpportunityCopyParamTypeValue.Replace) {
          value.copyAction = OpportunityCopyParamTypeValue.Add;
        } else if (value.copyAction === OpportunityCopyParamTypeValue.Add) {
          value.copyAction = OpportunityCopyParamTypeValue.Replace;
        }
      }
    });
  }

  get allSelected(): boolean {
    return this.recordsToBeCopied.every(value => value.copyAction === OpportunityCopyParamTypeValue.Yes || value.copyAction === OpportunityCopyParamTypeValue.Add);
  }

  get allUnSelected(): boolean {
    return this.recordsToBeCopied.every(value => value.copyAction === OpportunityCopyParamTypeValue.No || value.copyAction === OpportunityCopyParamTypeValue.Replace);
  }

  getRecordByType(paramType: OpportunityCopyFieldType): OpportunityCopyParamItem {
    return this.recordsToBeCopied.find(value => value.fieldName === paramType);
  }

  getCopyActionByType(paramType: OpportunityCopyFieldType): OpportunityCopyParamType {
    const record = this.getRecordByType(paramType);
    if (record) {
      return record.copyAction;
    }
    return OpportunityCopyParamTypeValue.No;
  }

  getRecordsByType(paramType: OpportunityCopyFieldType) {
    return this.recordsToBeCopied.filter(value => value.fieldName === paramType);
  }

  changeRecordAction(paramType: OpportunityCopyFieldType, entityId?: number, changeToAction?: OpportunityCopyParamType) {
    const record = (entityId) ?
      this.recordsToBeCopied.find(value => value.fieldName === paramType && value.entityId === entityId) :
      this.recordsToBeCopied.find(value => value.fieldName === paramType);
    if (record) {
      if (changeToAction) {
        record.copyAction = changeToAction;
      } else {
        record.copyAction = record.copyAction == 'NO' ? 'YES' : 'NO';
      }
    }
  }

  getRecordByTypeAndId(paramType: OpportunityCopyFieldType, entityId: number) {
    return this.recordsToBeCopied.find(value => value.fieldName === paramType && value.entityId === entityId);
  }

  getCopyActionByTypeAndId(paramType: OpportunityCopyFieldType, entityId: number): OpportunityCopyParamType {
    const record = this.getRecordByTypeAndId(paramType, entityId);
    if (record) {
      return record.copyAction;
    }
    return OpportunityCopyParamTypeValue.No;
  }

}

export class OpportunityCopyParamItem {
  fieldName: OpportunityCopyFieldType;
  copyAction: OpportunityCopyParamType; // 'NO' | 'YES' | 'REPLACE' | 'ADD',
  entityId: number; // matterParticipantId, contactId

  constructor(fieldName: OpportunityCopyFieldType, copyAction: OpportunityCopyParamType, entityId?: number) {
    this.fieldName = fieldName;
    this.copyAction = copyAction;
    if (entityId) {
      this.entityId = entityId;
    }
  }
}
