import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DpBooleanValue, DpBooleanValueTypes} from '../shared/dp-boolean';

export class CondoCorporationDocumentation extends BaseEntity {
  id: number;
  condoDocuments: string[];
  subjectToGst: DpBooleanValue;
  taxRate: number;
  costOfDocumentsOrdered: number;

  constructor(condoCorporationDocumentation?: CondoCorporationDocumentation) {
    super(condoCorporationDocumentation);

    if (!this.subjectToGst) {
      this.subjectToGst = DpBooleanValueTypes.YES;
    }

    if (!this.costOfDocumentsOrdered) {
      this.costOfDocumentsOrdered = 0;
    }
  }

  isFeeSubjectToHST(): boolean {
    return this.subjectToGst == DpBooleanValueTypes.YES;
  }
}
