import {HttpClient} from '../../core/httpClient.service';
import {AppConfig} from '../../shared-main/app-configuration';
import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {Document} from './document';

export const FOLDER_NAME_MAP: { [ key: string ]: string } = {
  'THIRDPARTY': 'ThirdParty',
  'ISC': 'ISC',
  'SPIN': 'SPIN',
  'TERANETCONNECT': 'TeranetConnect',
  'TPR': 'TPR',
  'STG': 'STG',
  'TELUS': 'Telus',
  'ASSYST REAL ESTATE': 'Assyst Real Estate',
  'UNITY® LENDER CENTRE': 'Unity® Lender Centre',
  'CHICAGOTITLE': 'ChicagoTitle',
  'FCT': 'FCT',
  'TITLEPLUS': 'TitlePLUS',
  'LTSACONNECT': 'LTSA'
};

@Injectable()
export class DocumentUtilityService {
  readonly reservedFolderNameMap = FOLDER_NAME_MAP;
  topTable: string = '180px';

  constructor(private http: HttpClient,
              private appConfig: AppConfig) {
  }

  getDocDescription(templateId: number, rows: any[]): string {
    if (templateId && rows && rows.length > 0) {
      let row = _.find(rows, row => row.template.docGenTemplateId === templateId);
      return row && row.template.description ? row.template.description : '';
    } else {
      return '';
    }

  }

  //We need this method because the description and the template name can not go to the 3rd line. Must stop in the second line plus ... to the end, if
  // it's longer than 2 lines.
  doesValueNeedTriming(value: string, size: number): boolean {

    if (value && value.length > size) {
      return true;
    }

    return false;
  }

  isDocumentLocked(document: any): boolean {
    return !!(document && document.isLocked);
  }

  get tablePadding() {
    if (this.topTable == '200px' || this.appConfig.templateManagement) {
      return '20px';
    } else {
      return '20px';
    }
  }

  mapStatus(status: string, isAlt: boolean): string {
    if (status) {
      switch (status) {
        // Draft MatterDocument record inserted/updated
        case('DRAFT') : {
          return 'Submitted';
        }
        // Template Codes formatted, ready to generate
        case('NEW') : {
          return 'Submitted';
        }
        case('NEW_CONVERT_WP_TO_PDF') : {
          return 'Submitted';
        }
        // Document being generated (Codes are replaced with text)
        case('INPROGRESS') : {
          return 'Submitted';
        }
        // Waiting for prime document to produce
        case('MERGE_WAIT') : {
          return 'Submitted';
        }
        // Document created and saved
        case('COMPLETED') : {
          return 'Available';
        }
        case('UPLOADED') : {
          return 'Available';
        }
        case('FAILED_CIRCULAR') : {
          return isAlt ? 'Self referencing include file' : 'Self-referencing-include-file';
        }
        default : {
          return 'Failed';
        }
      }
    }
    return '';
  }

  mapReservedFolderName(folderName: string) {
    if (folderName) {
      return this.reservedFolderNameMap[ folderName.toUpperCase() ] || folderName;
    }
    return '';
  }

  burgerMenuOpen(row: any, event, documentTemplateIds: string[] = []): void {

    if (row.template && row.template.templateIdWithMortgageOrAffidavitIndex) {
      // console.log(event);
      documentTemplateIds = [];
      documentTemplateIds.push(row.template.templateIdWithMortgageOrAffidavitIndex);
    }
  }

  isDocumentSubmittedOrVirusPending(doc: Document): boolean {
    return (this.mapStatus(doc.status, false) == 'Submitted' || doc.isVirusScanPending);
  }

  isDocumentSubmitted(doc: Document): boolean {
    return (this.mapStatus(doc.status, false) == 'Submitted');
  }
}
