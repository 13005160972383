import {api, SESSION_ID_REQ_PARAM} from '../../common/api';

export const reportTemplatesKey = {

  reportTemplates: 'ReportTemplates',
  reportTemplate: 'ReportTemplate '

};

export const reportTemplatesApi = {

  reportTemplates: `${ api }/account/{accountId}/reportTemplates?provinceCode={provinceCode}`,
  downloadTemplate: `${ api }/account/{accountId}/reportTemplates/{reportTemplateId}/download?${ SESSION_ID_REQ_PARAM }={sessionId}`,
  reportTemplate: `${ api }/account/{accountId}/reportTemplates/{reportTemplateId}`,
  uploadTemplates: `${ api }/account/{accountId}/reportTemplates/upload`
};
