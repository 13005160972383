import {Injectable} from '@angular/core';
import {Matter} from './shared/matter';
import {RollNumber} from './shared/roll-number';
import {Subject} from 'rxjs/Subject';
import {HttpClient} from '../core';
import {MatterOpeningComponent} from './matter-opening/matter-opening.component';
import {Mortgage} from './shared/mortgage';
import {CondoManagedTypeConstValue, MortgageDispositionType} from '../shared-main/constants';
import {ExistingMortgageComponent} from './mortgages/mortgage/existing-mortgage/existing-mortgage.component';
import {MatterParticipantService} from './matter-participant-service';

import * as _ from 'lodash';
import {MatterParticipant} from './shared/matter-participant';
import {Contact} from './shared/contact';
import {MortgageeComponent} from './mortgages/mortgage/mortgagee/mortgagee.component';
import {AttentionInfoComponent} from './matter-opening/attention/attention-info.component';
import {DpBooleanValueTypes} from './shared/dp-boolean';
import {MatterParticipantWrapper} from './shared/matter-participant-wrapper';
import {CondoCorporationComponent} from './condo-corporation/condo-corporation.component';
import {FireInsuranceComponent} from './fire-insurance';
import {MatterProperty} from './shared/matter-property';
import {PropertyTeranetComponent} from './property-teranet';
import {Address} from './shared/address';
import {BrokerCommissionComponent} from './broker-commission/broker-commission.component';
import {ContactQueryService} from '../contact/contact-query.service';
import {MatterParticipantRoleTypes} from './shared/matter-participant-role-types';
import {UUIDUtil} from '../main/uuid-util';
import {CondominiumExpense} from './property-teranet/unit-level-plan/condominium-expense';
import {CondominiumPlan} from './property-teranet/unit-level-plan/condominium-plan';
import {PurchaserComponent} from './purchaser/purchaser.component';
import {MatterContactInfo} from './shared/matter-contact-info';
import {FamilyLawActComponent} from './purchaser/family-law-act/family-law-act.component';
import {AddressTypes} from './shared/address-types';
import {ParcelLegalDescription} from './shared/parcel-legal-description';
import {UndertakingsConfigService} from '../admin/shared/undertaking-config.service';
import {MatterIdCleaner} from './matter-id-cleaner';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {MatterUtil} from './shared/matter-util';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {MatterTitleInsurance} from './shared/matter-title-insurance';
import {ReferredByInfo} from './shared/referred-by-info';
import {MatterCleanUpUtil} from './shared/matter-utils/matter-clean-up-util';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class CopyMatterService {
  constructor(public http: HttpClient,
              public matterOpeningComponent: MatterOpeningComponent,
              public existingMortgageComponent: ExistingMortgageComponent,
              public matterParticipantService: MatterParticipantService,
              public mortgageeComponent: MortgageeComponent,
              public attentionInfoComponent: AttentionInfoComponent,
              public condoCorporationComponent: CondoCorporationComponent,
              public propertyTeranetComponent: PropertyTeranetComponent,
              public brokerCommissionComponent: BrokerCommissionComponent,
              public fireInsuranceComponent: FireInsuranceComponent,
              public contactQueryService: ContactQueryService,
              public familyLawActComponent: FamilyLawActComponent,
              public purchaserComponent: PurchaserComponent,
              public undertakingsConfigService: UndertakingsConfigService,
              public mortgageSoAdjService: MortgageSoAdjService
  ) {

  }

  async callAsynchronously(callback: Function): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    await callback();
    let serviceInstance = this;
    let scheduler = setInterval(() => {

      if (serviceInstance.http.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 500);
    return returnSubject.toPromise();
  }

  async initMatterOpeningComponent(targetMatter: Matter, preserveActionTabSection?: boolean): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.matterOpeningComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.matterOpeningComponent.setLocalInstanceMatter(targetMatter);
        try {
          if (preserveActionTabSection) {
            this.matterOpeningComponent.preserveActionTabSection = true;
          }
          this.matterOpeningComponent.ngOnInit();
        } finally {
          this.matterOpeningComponent.preserveActionTabSection = false;
        }
      }
    });
  }

  async initCondoCorporationComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.condoCorporationComponent) {
        this.condoCorporationComponent.setLocalInstanceMatter(targetMatter);
        this.condoCorporationComponent.ngOnInit();
        this.condoCorporationComponent.openCondoCorporation();
        this.initAttentionInfoComponentAndSetIntoCondoCorpComponent(targetMatter);
      }
    });
  }

  initAttentionInfoComponentAndSetIntoCondoCorpComponent(targetMatter: Matter): void {
    // Build attentionInfoComponent
    this.attentionInfoComponent.matter = targetMatter;
    this.attentionInfoComponent.buildAttentionWrapper();

    this.condoCorporationComponent.attentionInfoComponent = this.attentionInfoComponent;
  }

  async initExistingMortgageComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.existingMortgageComponent) {
        this.existingMortgageComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.existingMortgageComponent.initializeState();
      }
    });
  }

  async initMortgageeComponent(targetMatter: Matter, targetMortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.mortgageeComponent) {
        this.mortgageeComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgage);
        this.mortgageeComponent.ngOnInit();
      }
    });
  }

  async initAttentionInfoComponent(mortgageId: number, parentMatterParticipant: MatterParticipant, targetMatter: Matter, attentionList: Contact[]): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.attentionInfoComponent) {
        this.attentionInfoComponent.setLocalInstancesForMassUpdate(mortgageId, parentMatterParticipant, targetMatter, attentionList);
        this.attentionInfoComponent.buildAttentionWrapper();
      }

    });
  }

  async initSubjectPropertyComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.propertyTeranetComponent) {
        this.propertyTeranetComponent.setLocalInstanceMatter(targetMatter);
        this.propertyTeranetComponent.ngOnInit();
        this.propertyTeranetComponent.openProperty();
        this.propertyTeranetComponent.loadHomeOwnersResidentFeeSOA();
      }
    });
  }

  async initBrokerCommissionComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.brokerCommissionComponent) {
        this.brokerCommissionComponent.setLocalInstanceMatter(targetMatter);
        this.brokerCommissionComponent.openBrokerCommission();
        if (targetMatter.isMatterProvinceAB) {
          this.brokerCommissionComponent.initForSaleMatterProvinceABAndBC();
        }
      }
    });
  }

  async initPurchaserComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.purchaserComponent) {
        this.purchaserComponent.setLocalInstanceMatter(matter);
        this.purchaserComponent.ngOnInit();
        this.purchaserComponent.initPurchaserComponent();
      }
    });
  }

  async copyMatterOpeningFields(sourceMatter: Matter, targetMatter: Matter): Promise<void> {

    await this.initMatterOpeningComponent(targetMatter);
    await this.copyMainClientFields(sourceMatter, targetMatter);
    this.copyLawclerkParticipant(sourceMatter, targetMatter);
    this.copySolicitorParticipant(sourceMatter, targetMatter);
    if (sourceMatter.specialComments) {
      targetMatter.specialComments = sourceMatter.specialComments;
    }
    if (sourceMatter.documentProfileId) {
      targetMatter.documentProfileId = sourceMatter.documentProfileId;
      this.matterOpeningComponent.onSelectedDocumentProfileChange(targetMatter.documentProfileId);
    }
    if (sourceMatter.accessGroupId) {
      targetMatter.accessGroupId = sourceMatter.accessGroupId;
    }
    this.copyreferredByList(sourceMatter, targetMatter);
  }

  copyreferredByList(sourceMatter: Matter, targetMatter: Matter): void {
    if (Array.isArray(sourceMatter.referredByList) && sourceMatter.referredByList.length > 0) {
      targetMatter.referredByList = [];
      sourceMatter.referredByList.forEach((item) => {
        let referredByInfo: ReferredByInfo = new ReferredByInfo(item);
        referredByInfo.id = null;
        targetMatter.referredByList.push(referredByInfo);
      });
    }
  }

  async copyMainClients(sourceMatter: Matter, targetMatter: Matter, isLinkedMatter?: boolean) {

    return await this.callAsynchronously(() => {
      let matterParticipants = isLinkedMatter ? sourceMatter.otherSideClients : sourceMatter.mainClients;

      matterParticipants.forEach(async (item, index) => {
        await this.copyMainClientMatterParticipant(item, index, sourceMatter, targetMatter);
      });
    });
  }

  async copyMainClientMatterParticipant(item: MatterParticipant, index: number, sourceMatter: Matter, targetMatter: Matter,
                                        participantPriority?: number, participantIndex?: number) {

    let sourceContact: Contact = await this.getSourceContact(item.contact);
    // For all four copy scenarios (Purchase to Sale, Purchase to Mortgage, Mortgage to Sale and Mortgage to Mortgage)
    // the client's ID verification fields 'ID verified for this transaction by' and 'On' should not be copied.
    // These fields should default to empty.
    sourceContact.idInfoEnteredBy = null;
    sourceContact.contactIdInfoEnteredBy = null;
    sourceContact.enteredOn = null;
    let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
    //We need give dataMode an object becasue purchaserComponent did not check if it null object. It is better do a refactor in the future.
    matterParticipantWrapper.dataModel = {};
    this.purchaserComponent.selectedClientPurchasers.push(matterParticipantWrapper);
    await this.purchaserComponent.createMatterParticipantForClient(new Contact(sourceContact), matterParticipantWrapper,
      index, true, true, true,
      participantPriority, participantIndex);
    this.matterParticipantService.updateMainClientParticipant(item, matterParticipantWrapper.matterParticipant, this.familyLawActComponent, false, sourceMatter, targetMatter);
    if (!participantPriority && participantPriority != 0) {
      matterParticipantWrapper.matterParticipant.matterParticipantPriority = item.matterParticipantPriority;
    }
    if (sourceMatter.isOpportunityMatter() && item.contact && item.contact.contactCategory == 'PROSPECT' && !sourceContact.locked) {
      matterParticipantWrapper.matterParticipant.sourceContact = sourceContact;
      matterParticipantWrapper.isClearFlagWithoutUpdatingMatter = false;
      await this.convertProspectsToClients(targetMatter, matterParticipantWrapper);
    }

    if (sourceMatter.isOpportunityMatter()) {
      await this.convertSignerProspectsToClients(targetMatter, matterParticipantWrapper);
    }

    MatterCleanUpUtil.cleanUpConsentedSpouseContactOfFla(matterParticipantWrapper.matterParticipant);
  }

  async convertSignerProspectsToClients(targetMatter: Matter, parentMatterParticipantWrapper: MatterParticipantWrapper): Promise<void> {
    let signers: MatterParticipant[] = [];
    signers = targetMatter.getChildSigners(parentMatterParticipantWrapper.matterParticipant);
    for (let i = 0; i < signers.length; i++) {
      if (signers[ i ].contact && signers[ i ].contact.contactCategory == 'PROSPECT' && signers[ i ].sourceContact && !signers[ i ].sourceContact.locked) {
        let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
        //We need give dataMode an object becasue purchaserComponent did not check if it null object. It is better do a refactor in the future.
        matterParticipantWrapper.dataModel = {};
        matterParticipantWrapper.matterParticipant = signers[ i ];
        await this.matterParticipantService.updateParticipantStateOnShutterClick(targetMatter, matterParticipantWrapper);
        if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
          matterParticipantWrapper.matterParticipant.contact.locked = true;
          matterParticipantWrapper.matterParticipant.contact.contactCategory = 'CLIENT';
          matterParticipantWrapper.matterParticipant.contact.isDirty = true;
        }
        await this.matterParticipantService.updateParticipantStateOnShutterClick(targetMatter, matterParticipantWrapper);
      }
    }
  }

  async convertProspectsToClients(targetMatter: Matter, matterParticipantWrapper: MatterParticipantWrapper): Promise<void> {
    await this.matterParticipantService.updateParticipantStateOnShutterClick(targetMatter, matterParticipantWrapper);
    if (matterParticipantWrapper.matterParticipant.sourceContactLockAcquired) {
      matterParticipantWrapper.matterParticipant.contact.locked = true;
      matterParticipantWrapper.matterParticipant.contact.contactCategory = 'CLIENT';
      matterParticipantWrapper.matterParticipant.contact.isDirty = true;
    }
    await this.matterParticipantService.updateParticipantStateOnShutterClick(targetMatter, matterParticipantWrapper);
  }

  async copyMainClientFields(sourceMatter: Matter, targetMatter: Matter, isLinkedMatter?: boolean) {
    //In case we copy a linked matter from opportunity, we use otherSideClients
    let matterParticipants = isLinkedMatter ? sourceMatter.otherSideClients : sourceMatter.mainClients;

    if (matterParticipants && matterParticipants.length > 0) {
      await this.initPurchaserComponent(targetMatter);

      this.purchaserComponent.selectedClientPurchasers = [];

      await this.copyMainClients(sourceMatter, targetMatter, isLinkedMatter);

      this.matterParticipantService.updateFlaSpouseId(sourceMatter.mainClients, targetMatter.mainClients);
      if (this.purchaserComponent.selectedClientPurchasers) {
        let primaryWrapper: MatterParticipantWrapper = this.purchaserComponent.selectedClientPurchasers.find(item => item && item.matterParticipant.primary);
        if (primaryWrapper) {
          this.purchaserComponent.setAsPrimaryPurchaser(primaryWrapper);
        }
      }
      //It needs to wait mainClients' initalizing
      this.updateMainClientMatterFields(sourceMatter, targetMatter);

    } else {
      this.updateMainClientMatterFields(sourceMatter, targetMatter);
    }
  }

  /** Need BA confirm it
   * If Province is ON and AB
   *   Purchase to Sale as purchaser is now selling  - no copy
   *   Purchase to a Mortgage                        - copy
   *   Mortgage to a Sale                            - no copy
   *   Mortgage to a Mortgage                        - copy
   * If Province is SK/MB
   *   Purchase to Sale as purchaser is now selling  - copy
   *   Purchase to a Mortgage                        - copy
   *   Mortgage to a Sale                            - copy
   *   Mortgage to a Mortgage                        - copy
   * @param sourceMatter
   * @param targetMatter
   */
  copyCapacity(sourceMatter: Matter, targetMatter: Matter) {
    if (sourceMatter.isMatterProvinceONOrAB || sourceMatter.isMatterProvinceNBorNS) {
      if (targetMatter.isMortgage) {
        targetMatter.purchasersCapacity = sourceMatter.purchasersCapacity;
      }
    }
    if (sourceMatter.isMatterProvinceMBorSK) {
      targetMatter.purchasersCapacity = sourceMatter.purchasersCapacity;
    }
  }

  /**
   * IF ‘Will xxx reside at subject property? = No THEN for:
   *     Purchase to Sale :  Copy Pre-Closing Address(preClosingAddress) to Pre & Post Closing Address(postClosingAddress)
   *     Purchase to Mortgage: Copy Pre-Closing Address(preClosingAddress) to Mailing Address(postClosingAddress)
   *     Mortgage to Sale: Copy Mailing Address(postClosingAddress) to Pre & Post Closing Address (postClosingAddress)
   *     Mortgage to Mortgage: Copy Mailing Address (postClosingAddress) to Mailing Address (postClosingAddress)
   * IF ‘Will xxx reside at subject property? = Yes (or Y/n) THEN copy not required for all four copy scenarios
   * @param sourceMatter
   * @param targetMatter
   */
  copyAddress(sourceMatter: Matter, targetMatter: Matter) {
    if (sourceMatter.matterContactInfo.residingAtSubjectProperty == DpBooleanValueTypes.NO) {
      let sourceMatterAddress: Address;
      let sourceAddress: Address;
      if (sourceMatter.isPurchase) {
        sourceMatterAddress = sourceMatter.matterContactInfo.preClosingAddress;
      } else {
        sourceMatterAddress = sourceMatter.matterContactInfo.postClosingAddress;
      }

      if (sourceMatterAddress) {
        if (sourceMatterAddress.sameAsAddressTypeCode == AddressTypes.subjectAddress) {
          sourceAddress = sourceMatter.matterPropertyWithCondo && sourceMatter.matterPropertyWithCondo.address;
        } else if (sourceMatterAddress.sameAsAddressTypeCode == AddressTypes.preClosingAddress) {
          sourceAddress = sourceMatter.matterContactInfo.preClosingAddress;
        } else {
          sourceAddress = sourceMatterAddress;
        }
        if (sourceAddress) {
          targetMatter.matterContactInfo.postClosingAddress = new Address(sourceAddress);
          targetMatter.matterContactInfo.postClosingAddress.id = null;
          targetMatter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = null;
          targetMatter.matterContactInfo.postClosingAddress.setAddressHash();
        }
      }
    }
  }

  updateMainClientMatterFields(sourceMatter: Matter, targetMatter: Matter) {
    this.copyCapacity(sourceMatter, targetMatter);
    if (sourceMatter.matterContactInfo) {
      if (!targetMatter.matterContactInfo) {
        targetMatter.matterContactInfo = new MatterContactInfo();
      }
      if (sourceMatter.matterContactInfo.envelopeSalutation) {
        targetMatter.matterContactInfo.envelopeSalutation = sourceMatter.matterContactInfo.envelopeSalutation;
      }
      if (sourceMatter.matterContactInfo.envelopeSalutationContinued) {
        targetMatter.matterContactInfo.envelopeSalutationContinued = sourceMatter.matterContactInfo.envelopeSalutationContinued;
      }
      if (sourceMatter.matterContactInfo.dearText) {
        targetMatter.matterContactInfo.dearText = sourceMatter.matterContactInfo.dearText;
      }
      if (sourceMatter.matterContactInfo.residingAtSubjectProperty) {
        targetMatter.matterContactInfo.residingAtSubjectProperty = sourceMatter.matterContactInfo.residingAtSubjectProperty;
      }
      if (sourceMatter.isMatterProvinceNBorNS) {
        targetMatter.matterContactInfo.identificationExecution = sourceMatter.matterContactInfo.identificationExecution;
      }
      this.copyAddress(sourceMatter, targetMatter);
    }
  }

  async copyCondoCorporationFields(sourceMatter: Matter, targetMatter: Matter): Promise<void> {

    await this.initCondoCorporationComponent(targetMatter);

    const sourceCondoCorporation: MatterParticipant = sourceMatter.condoCorporation;
    const targetCondoCorporation: MatterParticipant = targetMatter.condoCorporation;
    if (sourceCondoCorporation && sourceCondoCorporation.contact) {

      // If there is a condo corp in the target delete it.
      let removeDocumentation: boolean = true;
      if (targetCondoCorporation) {
        if (targetCondoCorporation && targetCondoCorporation.contact
          && (sourceCondoCorporation.contact.sourceContactId === targetCondoCorporation.contact.sourceContactId)) {
          removeDocumentation = false;
        }
        this.condoCorporationComponent.removeCondoCorporation(false, removeDocumentation);
      }

      // Add condoCorporation
      let condoCorpSourceContact: Contact = await this.getSourceContact(sourceCondoCorporation.contact);
      targetMatter.addMatterParticipant(new Contact(condoCorpSourceContact), true, 'CONDO_CORPORATION');
      this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(targetMatter.condoCorporation, sourceMatter.condoCorporation);

      // Handle the CondoManagedTypeConstValue.MANAGEMENT_COMPANY
      if (sourceCondoCorporation.contact.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY
        && sourceMatter.managementCompany
        && sourceMatter.managementCompany.contact) {
        let mngmntCompSourceContact: Contact = await this.getSourceContact(sourceMatter.managementCompany.contact);
        targetMatter.addMatterParticipant(new Contact(mngmntCompSourceContact), true, 'MANAGEMENT_COMPANY');
        this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(targetMatter.managementCompany, sourceMatter.managementCompany);
      }

      // Attention contact
      if (sourceMatter.condoCorpAttention && sourceMatter.condoCorpAttention.contact) {
        let condoCorpAttentionSourceContact: Contact = await this.getSourceContact(sourceMatter.condoCorpAttention.contact);
        if (sourceCondoCorporation.contact.selfManagedManagementCompanyType == CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
          targetMatter.addMatterParticipant(new Contact(condoCorpAttentionSourceContact), true, MatterParticipantRoleTypes.MANAGEMENT_COMPANY_ATTENTION);
        } else {
          targetMatter.addMatterParticipant(new Contact(condoCorpAttentionSourceContact), true, MatterParticipantRoleTypes.CONDO_CORPORATION_ATTENTION);
        }
        this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(targetMatter.condoCorpAttention, sourceMatter.condoCorpAttention);
      }

      if (removeDocumentation && !targetMatter.condoCorporationDocumentation) {
        targetMatter.setCondoCorporationDocumentation();
      }

    }
  }

  async getSourceContact(contact: Contact): Promise<Contact> {
    return (contact && contact.sourceContactId && contact.id !== contact.sourceContactId) ?
      await this.contactQueryService.getContactForMatter(contact.sourceContactId).toPromise() :
      contact;
  }

  copyLawclerkParticipant(sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatter.lawClerk && sourceMatter.lawClerk.contact) {
      targetMatter.selectedLawClerkId = sourceMatter.lawClerk.contact.id;
      this.matterOpeningComponent.onLawClerkNameChange(targetMatter.selectedLawClerkId);
    }
  }

  copySolicitorParticipant(sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatter.solicitor && sourceMatter.solicitor.contact) {
      targetMatter.selectedSolicitorId = sourceMatter.solicitor.contact.id;
      this.matterOpeningComponent.onSolicitorChange(targetMatter.selectedSolicitorId);
    }
  }

  async copyMatterMortgages(sourceMatter: Matter, targetMatter: Matter): Promise<void> {

    targetMatter.existingMortgages = [];
    let sourceMortgages: Mortgage[] = [];
    if (sourceMatter.isPurchase) {
      sourceMortgages = sourceMatter.mortgages.filter(mtg => !mtg.isLoanTypeBridge());
    } else {
      let newMortgages = sourceMatter.mortgages.filter(mtg => !mtg.isMortgageDispositionDischarged());
      let existingMortgages = sourceMatter.existingMortgages.filter(mtg => !mtg.isMortgageDispositionDischarged());
      sourceMortgages = [ ...newMortgages, ...existingMortgages ];
      sourceMortgages = _.orderBy(sourceMortgages, [ 'mortgagePriority' ], [ 'asc' ]);
    }

    for (let i = 0; i < sourceMortgages.length; i++) {

      let sourceMortgage = sourceMortgages[ i ];
      let targetMortgage = targetMatter.createMortgage('EXISTING', 'UNITY', sourceMortgage.mortgagePriority);
      targetMortgage.financingType = 'Mortgage';
      targetMortgage.statementForInformation = DpBooleanValueTypes.Y_n;
      targetMatter.existingMortgages.push(targetMortgage);
      await this.initExistingMortgageComponent(targetMatter, i);
      this.copyMortgageDetails(sourceMatter, sourceMortgage, targetMortgage);
      await this.initMortgageeComponent(targetMatter, targetMortgage);
      await this.copyInstitutionMortgagees(sourceMatter, targetMatter, sourceMortgage, targetMortgage);
      await this.copyPrivateLenders(sourceMatter, targetMatter, sourceMortgage, targetMortgage);
      targetMortgage.undertakingDirty = true;

    }
    targetMatter.updateUndertakings(this.undertakingsConfigService);
  }

  async copyInstitutionMortgagees(sourceMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage) {
    if (sourceMortgage.isMortgageeAnInstitution()) {
      let mortgagees = sourceMatter.getMortgagees(sourceMortgage);
      if (mortgagees && mortgagees.length) {
        await this.initAttentionInfoComponent(targetMortgage.id, this.mortgageeComponent.institutionMortgageeMatterParticipant, targetMatter, this.mortgageeComponent.attentionList);
        this.mortgageeComponent.attentionInfoComponent = this.attentionInfoComponent;
        this.copyMortgagees(sourceMatter, targetMatter, sourceMortgage, targetMortgage);
        if (sourceMortgage.includeAuthorizeSignOfficer != DpBooleanValueTypes.N_y) {
          targetMortgage.includeAuthorizeSignOfficer = sourceMortgage.includeAuthorizeSignOfficer;
          if (sourceMortgage.includeAuthorizeSignOfficer == DpBooleanValueTypes.YES && targetMortgage.mortgageContactInfo && sourceMortgage.mortgageContactInfo) {
            targetMortgage.mortgageContactInfo.additionalName1 = sourceMortgage.mortgageContactInfo.additionalName1;
            targetMortgage.mortgageContactInfo.additionalName2 = sourceMortgage.mortgageContactInfo.additionalName2;
            targetMortgage.mortgageContactInfo.titleOfOfficeHeld1 = sourceMortgage.mortgageContactInfo.titleOfOfficeHeld1;
            targetMortgage.mortgageContactInfo.titleOfOfficeHeld2 = sourceMortgage.mortgageContactInfo.titleOfOfficeHeld2;
          }
        }

      }
    }
  }

  async copyPrivateLenders(sourceMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage) {
    if (sourceMortgage.isMortgageePrivateLender) {
      let otherLenders = sourceMatter.getPrivateLenders(sourceMortgage);
      if (otherLenders && otherLenders.length) {
        await this.copyOtherLenderChanges(sourceMatter, targetMatter, sourceMortgage, targetMortgage);
        if (this.mortgageeComponent.matterPrivateLenders) {
          let primaryWrapper: MatterParticipantWrapper = this.mortgageeComponent.matterPrivateLenders.find(item => item && item.matterParticipant.primary);
          if (primaryWrapper) {
            this.mortgageeComponent.setAsPrimaryPrivateLender(primaryWrapper);
          }
        }
        if (sourceMortgage.mortgageCapacityType) {
          targetMortgage.mortgageCapacityType = sourceMortgage.mortgageCapacityType;
          this.mortgageeComponent.onMortgageCapacityChange(targetMortgage.mortgageCapacityType);
        }
      }
    }
  }

  async copyOtherLenderChanges(templateMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): Promise<void> {
    this.mortgageeComponent.matterPrivateLenders = [];
    for (let item of templateMatter.getPrivateLenders(sourceMortgage)) {

      let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.dataModel = {};
      await this.mortgageeComponent.silentAddPrivateLender(item.contact, matterParticipantWrapper);
      matterParticipantWrapper.primary = item.primary;
      this.mortgageeComponent.matterPrivateLenders.push(matterParticipantWrapper);
      this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(matterParticipantWrapper.matterParticipant, item);
    }
  }

  copyMortgagees(sourceMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    this.mortgageeComponent.selectedMortgagees = [];
    sourceMatter.getMortgagees(sourceMortgage).forEach((item) => {
      let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
      matterParticipantWrapper.dataModel = {};
      this.mortgageeComponent.silentAddMortgagee(item.contact, matterParticipantWrapper);
      this.mortgageeComponent.selectedMortgagees.push(matterParticipantWrapper);
      this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(matterParticipantWrapper.matterParticipant, item);
    });
  }

  copyMortgageDetails(sourceMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage): void {
    if ((sourceMatter.isMatterProvinceABorMB || sourceMatter.isMatterProvinceSK) && sourceMatter.isMortgage && sourceMortgage.isExistingMortgage()) {
      targetMortgage.financingType = sourceMortgage.financingType;
    }
    targetMortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
    this.existingMortgageComponent.onMortgageDispositionTypeChange(targetMortgage);
    targetMortgage.mortgageeType = sourceMortgage.mortgageeType;
    this.existingMortgageComponent.onMortgageeTypeChange(targetMortgage);
    targetMortgage.loanNo = sourceMortgage.loanNo;
    if (sourceMatter.isPurchase || sourceMatter.isMortgage && sourceMortgage.isNewMortgage()) {
      targetMortgage.mortgageRequestNo = sourceMortgage.mortgageReport.mortgageRegistrationNumber;
      targetMortgage.mortgageRequestDate = sourceMortgage.mortgageReport.mortgageRegistrationDate;
    } else {
      targetMortgage.mortgageRequestNo = sourceMortgage.mortgageRequestNo;
      targetMortgage.mortgageRequestDate = sourceMortgage.mortgageRequestDate;
    }
  }

  async copySubjectPropertyFields(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initBrokerCommissionComponent(targetMatter);
    await this.initSubjectPropertyComponent(targetMatter);
    this.propertyTeranetComponent.brokerCommissionComponent = this.brokerCommissionComponent;
    let sourceMatterProperty: MatterProperty = sourceMatter.matterPropertyWithCondo;
    let targetMatterProperty: MatterProperty = targetMatter.matterPropertyWithCondo;
    if (sourceMatter.isMatterProvinceON) {
      await this.copySubjectPropertyON(sourceMatterProperty, targetMatterProperty, sourceMatter, targetMatter);
    } else if (sourceMatter.isMatterProvinceABorMBorSK) {
      await this.copySubjectPropertyABorMBorSK(sourceMatter, targetMatter);
    } else if (sourceMatter.isMatterProvinceNBorNS) {
      await this.copySubjectPropertyNBorNS(sourceMatter, targetMatter);
    }
  }

  async copySubjectPropertyON(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty, sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.updatePropertyJurisdiction(sourceMatterProperty, targetMatterProperty);
    this.updatePropertyAddress(sourceMatterProperty, targetMatterProperty);
    this.copyMatterPropertyDetailsON(sourceMatterProperty, targetMatterProperty, sourceMatter, targetMatter);
  }

  async copySubjectPropertyABorMBorSK(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    if (sourceMatter && targetMatter && sourceMatter.isMatterProvinceABorMBorSK) {
      let sourceMatterProperty: MatterProperty = sourceMatter.matterPropertyWithCondo;
      let targetMatterProperty: MatterProperty = targetMatter.matterPropertyWithCondo;

      //needed for all types of copy: P2S, P2M, M2S, M2M
      await this.updatePropertyJurisdiction(sourceMatterProperty, targetMatterProperty);
      this.updatePropertyAddress(sourceMatterProperty, targetMatterProperty);
      this.copyMatterPropertyDetailsABorMBorSK(sourceMatterProperty, targetMatterProperty, sourceMatter, targetMatter);
    }
  }

  async copySubjectPropertyNBorNS(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    if (sourceMatter && targetMatter && sourceMatter.isMatterProvinceNBorNS) {
      let sourceMatterProperty: MatterProperty = sourceMatter.matterPropertyWithCondo;
      let targetMatterProperty: MatterProperty = targetMatter.matterPropertyWithCondo;

      //needed for all types of copy: P2S, P2M, M2S, M2M
      await this.updatePropertyJurisdiction(sourceMatterProperty, targetMatterProperty);
      this.updatePropertyAddress(sourceMatterProperty, targetMatterProperty);
      this.copyMatterPropertyDetailsNBorNS(sourceMatterProperty, targetMatterProperty, sourceMatter, targetMatter);
    }
  }

  copyMatterPropertyDetailsNBorNS(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty, sourceMatter: Matter, targetMatter: Matter) {
    //there are 4 types of matter copy Purchase To Sale (P2S), Purchase to Mortgage (P2M), Mortgage to Sale (M2S), Mortgage to Mortgage (M2M)

    //Is this a Condo? field: for all 4 copy types
    if (sourceMatterProperty.isCondominium != DpBooleanValueTypes.N_y) {
      targetMatterProperty.isCondominium = sourceMatterProperty.isCondominium;
      this.propertyTeranetComponent.condominiumChange();
    }
    //Purchase Is Of / Sale Is Of field: for P2S, M2S
    if ((targetMatter.isMatterProvinceNS && targetMatter.isSale) ||
      (targetMatter.isMatterProvinceNB && sourceMatter.isPurchase && targetMatter.isSale)
    ) {
      targetMatterProperty.purchaseIsOfCode = sourceMatterProperty.purchaseIsOfCode;
    }
    //Property Type field: for all 4 copy types
    targetMatterProperty.propertyType = sourceMatterProperty.propertyType;
    //No. of residential Units field: for all 4 copy types
    targetMatterProperty.numOfUnits = sourceMatterProperty.numOfUnits;
    //Interest/Estate field: for all 4 copy types
    targetMatterProperty.interestEstate = sourceMatterProperty.interestEstate;
    targetMatterProperty.specify = sourceMatterProperty.specify;//used when interestEstate = OTHER
    //Registry or LT field: for all 4 copy types
    targetMatterProperty.registryOrLt = sourceMatterProperty.registryOrLt;

    if (targetMatterProperty.isCondominium == DpBooleanValueTypes.YES) {
      //Unit/Level/Plan Information fields: for all 4 copy types
      //Copy is conditional on 'Is this a Condo?' = Yes This will include all the underlying modal information associated with Unit/Level/Plan
      //except for the common expense amounts
      targetMatterProperty.unitLevelPlan = sourceMatterProperty.unitLevelPlan;
      //This will also include all the underlying modal information associated with Unit/Level/Plan
      //except for the common expense amounts
      targetMatterProperty.condominiumCorporationName = sourceMatterProperty.condominiumCorporationName;
      this.copyMatterPropertyCondoPlans(sourceMatterProperty, targetMatterProperty);
      this.copyMatterPropertyCondoExpenses(sourceMatterProperty, targetMatterProperty);
      this.propertyTeranetComponent.onCondoValueChange(targetMatterProperty.isCondominium);
    } else {
      if (targetMatter.isMatterProvinceNS) {
        // Legal Description fields: for all 4 copy types
        targetMatterProperty.propertyDescriptionType = sourceMatterProperty.propertyDescriptionType;
        { //Lot option
          //Lot Number field
          targetMatterProperty.lot = sourceMatterProperty.lot;
          //Exception Wording? fields
          targetMatterProperty.exceptionType = sourceMatterProperty.exceptionType;
          targetMatterProperty.exceptionTypeDescription = sourceMatterProperty.exceptionTypeDescription;
        }
        { //Metes and Bounds option
          targetMatterProperty.shortLegalDescription = sourceMatterProperty.shortLegalDescription;
          targetMatterProperty.fullLegalDescription = sourceMatterProperty.fullLegalDescription;
        }
      } else if (targetMatter.isMatterProvinceNB) {
        //Part (Lot) and plan field: for all 4 copy types
        targetMatterProperty.partLot = sourceMatterProperty.partLot;
        targetMatterProperty.plan = sourceMatterProperty.plan;
        this.propertyTeranetComponent.updateTeraviewLegalDescs();
      }
    }

    if (targetMatter.isMatterProvinceNB) {
      //R of W/Covenants fields: for all 4 copy types
      //Interest to benefit parcel
      targetMatterProperty.interestToBenefitParcel = sourceMatterProperty.interestToBenefitParcel;
      //Interest to burden the parcel
      targetMatterProperty.interestToBurndenParcel = sourceMatterProperty.interestToBurndenParcel;
      //Subject to covenants and conditions
      targetMatterProperty.subjectToCovenantsAndConditions = sourceMatterProperty.subjectToCovenantsAndConditions;
    }

    //"City" Field: for all 4 copy types
    targetMatterProperty.city = sourceMatterProperty.city;
    this.propertyTeranetComponent.updatePropertyTaxPaidTrustLedger();
    //"County" Field: for all 4 copy types
    targetMatterProperty.municipality = sourceMatterProperty.municipality;
    //Land Titles Office Field: for all 4 copy types
    targetMatterProperty.registryOfficeName = sourceMatterProperty.registryOfficeName;
    //Title Search Performed field: for all 4 copy types
    targetMatterProperty.isTitleSearchPerformed = sourceMatterProperty.isTitleSearchPerformed;
    //PIDs and ANNs (PANs) fields: for all 4 copy types
    this.copyMatterPropertyPIDsAndAANs(sourceMatterProperty, targetMatterProperty, sourceMatter, targetMatter);
    //Surveyor and Survey Date fields: for all 4 copy types
    this.updatePropertySurveyor(sourceMatter, targetMatter);
    targetMatterProperty.surveyDate = sourceMatterProperty.surveyDate;

    if (targetMatter.isMatterProvinceNB && sourceMatter.isPurchase && targetMatter.isSale
      && sourceMatterProperty.isPurchaseTypeNewBuilderHome()) {
      //New Home Warranty
      targetMatterProperty.newHomeWarranty = sourceMatterProperty.newHomeWarranty;
      //Builder No.
      targetMatterProperty.builderNumber = sourceMatterProperty.builderNumber;
      //Property No.
      targetMatterProperty.propertyNumber = sourceMatterProperty.propertyNumber;
    }

    //Is the survey in the current owner's name? field: for all 4 copy types
    targetMatterProperty.surveyInCurrentOwnersName = sourceMatterProperty.surveyInCurrentOwnersName;
    //Has there been a change to the property since the date of the survey? field: for all 4 copy types
    targetMatterProperty.changeInPropertyAfterSurvey = sourceMatterProperty.changeInPropertyAfterSurvey;

    if (targetMatter.isMatterProvinceNS) {
      //How is property heated? field: for all 4 copy types
      targetMatterProperty.heatType = sourceMatterProperty.heatType;
      //Has property been migrated? field: for all 4 copy types
      targetMatterProperty.hasPropertyMigrated = sourceMatterProperty.hasPropertyMigrated;
    }
    // console.log("Done with copyMatterPropertyDetails for Matter of Province %s", targetMatter.provinceCode);
  }

  //PID, AAN for NS, PID, PAN for NB
  private copyMatterPropertyPIDsAndAANs(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty, sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatterProperty && targetMatterProperty) {
      targetMatterProperty.pin = sourceMatterProperty.pin;
      targetMatterProperty.assessmentAccountNumberSummary = sourceMatterProperty.assessmentAccountNumberSummary;
      //copy additional PID and ANN (PAN for NB) if exists, only applicable for non-condo condition
      if (targetMatterProperty.isCondominium != DpBooleanValueTypes.YES) {
        const sourceAdditionalMatterProperties: MatterProperty[] = sourceMatter.additionalMatterProperty;
        if (Array.isArray(sourceAdditionalMatterProperties) && sourceAdditionalMatterProperties.length > 0) {
          sourceAdditionalMatterProperties.forEach((sourceAdditionalMatterProperty) => {
            let targetAdditionalMatterProperty = new MatterProperty();
            targetAdditionalMatterProperty.matterPropertyCode = 'QUESTION';
            targetAdditionalMatterProperty.pin = sourceAdditionalMatterProperty.pin;
            targetAdditionalMatterProperty.assessmentAccountNumberSummary = sourceAdditionalMatterProperty.assessmentAccountNumberSummary;
            targetMatter.matterProperties.push(targetAdditionalMatterProperty);
          });
        }
      }
    }
  }

  private copyMatterPropertyCondoPlans(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty): void {
    if (sourceMatterProperty && targetMatterProperty) {
      if (Array.isArray(sourceMatterProperty.condominiumPlans)) {
        targetMatterProperty.condominiumPlans = [];
        sourceMatterProperty.condominiumPlans.forEach(sourceCondoPlan => {
          let newCondoPlan: CondominiumPlan = new CondominiumPlan(sourceCondoPlan);
          newCondoPlan.id = undefined;
          targetMatterProperty.condominiumPlans.push(newCondoPlan);
        });
      }
    }
  }

  //for ON/NS, copy condoExpense, excluding amount field
  private copyMatterPropertyCondoExpenses(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty): void {
    if (sourceMatterProperty && targetMatterProperty) {
      if (Array.isArray(sourceMatterProperty.condominiumExpenses)) {
        targetMatterProperty.condominiumExpenses = [];
        sourceMatterProperty.condominiumExpenses.forEach(sourceCondoExpense => {
          let newCondoExpense: CondominiumExpense = new CondominiumExpense(sourceCondoExpense);
          newCondoExpense.id = undefined;
          //exclude the data from 'condomium Fees' column
          newCondoExpense.condominiumExpense = 0;
          targetMatterProperty.condominiumExpenses.push(newCondoExpense);
        });
      }
    }
  }

  //for MB and SK
  private copyMatterPropertyParcelLegalDescriptions(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty): void {
    if (sourceMatterProperty && targetMatterProperty) {
      if (Array.isArray(sourceMatterProperty.parcelLegalDescriptions)) {
        targetMatterProperty.parcelLegalDescriptions = [];
        sourceMatterProperty.parcelLegalDescriptions.forEach(parcel => {
          let newParcel: ParcelLegalDescription = new ParcelLegalDescription(parcel);
          newParcel.id = UUIDUtil.getUUID();
          newParcel.commonElementFees = 0;//based on Heather and Wim's decision, fee and value are not copied
          if (Array.isArray(newParcel.parcelTitles)) {//only for SK
            newParcel.parcelTitles.forEach(title => {
              title.id = undefined;
              title.parcelTitleValue = 0;//based on Heather and Wim's decision, fee and value are not copied
              title.parcelLegalDescriptionId = newParcel.id;
            });
          }
          targetMatterProperty.parcelLegalDescriptions.push(newParcel);
        });
      }
    }
  }

  copyMatterPropertyDetailsABorMBorSK(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty, sourceMatter: Matter, targetMatter: Matter) {
    //there are 4 types of matter copy
    const isCopyPurchaseToSale: boolean = sourceMatter.isPurchase && targetMatter.isSale;
    const isCopyPurchaseToMortgage: boolean = sourceMatter.isPurchase && targetMatter.isMortgage;
    const isCopyMortgageToSale: boolean = sourceMatter.isMortgage && targetMatter.isSale;
    const isCopyMortgageToMortgage: boolean = sourceMatter.isMortgage && targetMatter.isMortgage;

    //copy for both AB, MB, SK
    if (sourceMatterProperty.isCondominium != DpBooleanValueTypes.N_y) {
      //copy isCondominium flag for all 4 types
      targetMatterProperty.isCondominium = sourceMatterProperty.isCondominium;
      this.propertyTeranetComponent.condominiumChange();
    }
    //copy for both AB (P2S), MB(P2S), SK(P2S, M2S)
    if (isCopyPurchaseToSale || (sourceMatter.isMatterProvinceSK && isCopyMortgageToSale)) {
      targetMatterProperty.purchaseIsOfCode = sourceMatterProperty.purchaseIsOfCode;
    }
    //copy interestEstate For all 4 types for both AB, MB, SK
    targetMatterProperty.interestEstate = sourceMatterProperty.interestEstate;

    //For MB and SK only: copy Legal description related fields for all 4 copy types
    if (sourceMatter.isMatterProvinceMBorSK) {
      //each parcel field value is dynamic Created by the following fields
      if (sourceMatterProperty.isCondominium == DpBooleanValueTypes.YES) {
        //condoPlan only available if isCondo = yes
        this.copyMatterPropertyCondoPlans(sourceMatterProperty, targetMatterProperty);
      }
      this.copyMatterPropertyParcelLegalDescriptions(sourceMatterProperty, targetMatterProperty);
      this.propertyTeranetComponent.updateParcelValues();
    }

    //AB only
    if (sourceMatter.isMatterProvinceAB) {

      if (targetMatterProperty.isCondominium == DpBooleanValueTypes.YES) {
        //copy LINC/Plan/Unit/Roll for all 4 types
        targetMatterProperty.unitLevelPlan = sourceMatterProperty.unitLevelPlan;
        //This will also include all the underlying modal information associated with Unit/Level/Plan
        //except for the common expense amounts
        this.copyMatterPropertyCondoPlans(sourceMatterProperty, targetMatterProperty);
        this.copyMatterPropertyCondoExpenses(sourceMatterProperty, targetMatterProperty);
        this.propertyTeranetComponent.onCondoValueChange(targetMatterProperty.isCondominium);
      } else {
        //copy Legal Description  for all 4 types
        targetMatterProperty.propertyDescriptionType = sourceMatterProperty.propertyDescriptionType;
        { //Plan/Block/Lot
          targetMatterProperty.plan = sourceMatterProperty.plan;
          targetMatterProperty.block = sourceMatterProperty.block;
          targetMatterProperty.lot = sourceMatterProperty.lot;
        }
        { //Metes and Bounds
          targetMatterProperty.shortLegalDescription = sourceMatterProperty.shortLegalDescription;
          targetMatterProperty.fullLegalDescription = sourceMatterProperty.fullLegalDescription;
        }

        targetMatterProperty.lincNumber = sourceMatterProperty.lincNumber;
        if (sourceMatterProperty.rollNumber) {//even we just need the value of rollNumber.city
          targetMatterProperty.rollNumber = new RollNumber(sourceMatterProperty.rollNumber);
        }
        //copy additional lincNumber and rollNumber if exists
        let sourceAdditionalMatterProperties: MatterProperty[] = sourceMatter.additionalMatterProperty;
        if (Array.isArray(sourceAdditionalMatterProperties) && sourceAdditionalMatterProperties.length > 0) {
          sourceAdditionalMatterProperties.forEach((sourceAdditionalMatterProperty) => {
            let targetAdditionalMatterProperty = new MatterProperty();
            targetAdditionalMatterProperty.matterPropertyCode = 'QUESTION';
            targetAdditionalMatterProperty.lincNumber = sourceAdditionalMatterProperty.lincNumber;
            if (sourceAdditionalMatterProperty.rollNumber) {//even we just need the value of rollNumber.city
              targetAdditionalMatterProperty.rollNumber = new RollNumber(sourceAdditionalMatterProperty.rollNumber);
            }
            targetMatter.matterProperties.push(targetAdditionalMatterProperty);
          });
        }
      }

      targetMatterProperty.exceptionType = sourceMatterProperty.exceptionType;
      targetMatterProperty.exceptionTypeDescription = sourceMatterProperty.exceptionTypeDescription;
    }

    //copy City for all 4 types
    //for both AB, MB, SK
    if (sourceMatterProperty.city) {
      targetMatterProperty.city = sourceMatterProperty.city;
      this.propertyTeranetComponent.updatePropertyTaxPaidTrustLedger();
    }

    if (sourceMatter.isMatterProvinceABorMB) {
      //copy 'Land Title Office Location' for all 4 types for both AB, MB
      if (sourceMatterProperty.landTitleOfficeLocation) {
        targetMatterProperty.landTitleOfficeLocation = sourceMatterProperty.landTitleOfficeLocation;
        this.propertyTeranetComponent.updateLandTitleOffice();
      }
      //copy 'Land Title Office' for all 4 types for both AB, MB
      targetMatterProperty.registryOfficeName = sourceMatterProperty.registryOfficeName;
    }

    //SK only: copy 'Assessment Roll No' for all 4 copy types
    if (sourceMatter.isMatterProvinceSK) {
      if (sourceMatterProperty.rollNumber) {//even we just need the value of rollNumber.city
        targetMatterProperty.rollNumber = new RollNumber(sourceMatterProperty.rollNumber);
      }
    }

    if (sourceMatter.isMatterProvinceABorMB ||
      (sourceMatter.isMatterProvinceSK && (isCopyPurchaseToSale || isCopyPurchaseToMortgage))) {
      //copy 'RPR Applicable' field for all 4 types
      //for both AB('RPR Applicable'), MB ('Building Location Certificate applicable')
      //copy 'RPR Applicable' field for P2S, P2M
      //for SK('RPR Applicable')
      targetMatterProperty.rprApplicable = sourceMatterProperty.rprApplicable;

      //copy 'RPR Date' (Survey Date <ON>) for all 4 types
      //for both AB('RPR Date'), MB('Building Location Certificate')
      //copy 'RPR Date' field for P2S, P2M
      //for SK('RPR Date')
      targetMatterProperty.surveyDate = sourceMatterProperty.surveyDate;
    }

    //copy 'Surveyor'
    //for AB or MB : for all 4 types, with no pre-condition
    //for SK : only P2S and P2M, with no pre-condition
    if (sourceMatter.isMatterProvinceABorMB ||
      (sourceMatter.isMatterProvinceSK && (isCopyPurchaseToSale || isCopyPurchaseToMortgage))) {
      this.updatePropertySurveyor(sourceMatter, targetMatter);
    }

    //copy 'rentalAgreement' for all 4 types
    //for MB, SK only
    if (sourceMatter.isMatterProvinceMBorSK) {
      targetMatterProperty.rentalAgreements = sourceMatterProperty.rentalAgreements;
    }

    //MB Only DPPMP-35016
    if (sourceMatter.isMatterProvinceMB) {
      targetMatterProperty.farmlandEvidence = sourceMatterProperty.farmlandEvidence;
      targetMatterProperty.farmlandSection = sourceMatterProperty.farmlandSection;
    }

    console.log('Done with copyMatterPropertyDetails for Matter of Province %s', targetMatter.provinceCode);
  }

  async updatePropertyJurisdiction(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty): Promise<void> {
    if (sourceMatterProperty.jurisdiction) {
      this.propertyTeranetComponent.selectedJurisdiction = sourceMatterProperty.jurisdiction;
      targetMatterProperty.jurisdiction = sourceMatterProperty.jurisdiction;
      await this.propertyTeranetComponent.dataSelectedJurisdiction();
    }
  }

  updatePropertyAddress(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty) {
    //for address related fields, exclude province/country which is deferred to be handled in the future story
    if (sourceMatterProperty.address) {
      let targetAddress: Address = new Address(sourceMatterProperty.address);
      targetAddress.id = UUIDUtil.getUUID();
      this.propertyTeranetComponent.updateAddressFormChild({value: targetAddress});
    }
  }

  copyMatterPropertyDetailsON(sourceMatterProperty: MatterProperty, targetMatterProperty: MatterProperty, sourceMatter: Matter, targetMatter: Matter) {
    if (sourceMatterProperty.isCondominium != DpBooleanValueTypes.N_y) {
      targetMatterProperty.isCondominium = sourceMatterProperty.isCondominium;
      this.propertyTeranetComponent.condominiumChange();
    }
    if (sourceMatterProperty.isParcelOfTiedLand != DpBooleanValueTypes.N_y) {
      targetMatterProperty.isParcelOfTiedLand = sourceMatterProperty.isParcelOfTiedLand;
      if (sourceMatterProperty.isPropertyParcelOfTiedLand()) {
        targetMatterProperty.nameOfCondominiumPlan = sourceMatterProperty.nameOfCondominiumPlan;
        targetMatterProperty.percentageInterest = sourceMatterProperty.percentageInterest;
        this.propertyTeranetComponent.onAutoCalculateCommonExpense(targetMatterProperty.percentageInterest);
        targetMatterProperty.commonExpenses = sourceMatterProperty.commonExpenses;
        this.propertyTeranetComponent.matter.updatePOTLAdjustmentsWithCommonExpense();
      }
    }
    if (targetMatterProperty.isCondominium == DpBooleanValueTypes.YES) {
      targetMatterProperty.unitLevelPlan = sourceMatterProperty.unitLevelPlan;
      this.copyCondoExpensesIntoUnitLevelPlan(sourceMatter, targetMatter);
      targetMatterProperty.pin = sourceMatterProperty.pin;
    } else {
      targetMatterProperty.partLot = sourceMatterProperty.partLot;
      targetMatterProperty.plan = sourceMatterProperty.plan;
      targetMatterProperty.beingPart = sourceMatterProperty.beingPart;
      targetMatterProperty.onPlan = sourceMatterProperty.onPlan;
      targetMatterProperty.surveyDate = sourceMatterProperty.surveyDate;
      this.updatePropertySurveyor(sourceMatter, targetMatter);
      targetMatterProperty.pin = sourceMatterProperty.pin;
      let additionalMatterProperty = sourceMatter.additionalMatterProperty;
      if (additionalMatterProperty && additionalMatterProperty.length) {
        additionalMatterProperty.forEach((matterProperty) => {
          let targetMatterProperty = new MatterProperty();
          targetMatterProperty.matterPropertyCode = 'QUESTION';
          targetMatterProperty.isPinValid = true;
          targetMatterProperty.pin = matterProperty.pin;
          targetMatterProperty.propertyTaxesSummary = matterProperty.propertyTaxesSummary;
          targetMatterProperty.address = new Address(matterProperty.address);
          if (targetMatterProperty.address && targetMatterProperty.address.id) {
            targetMatterProperty.address.id = UUIDUtil.getUUID();
          }
          targetMatter.matterProperties.push(targetMatterProperty);
        });
      }
    }
    targetMatterProperty.purchaseIsOfCode = sourceMatterProperty.purchaseIsOfCode;
    targetMatterProperty.interestEstate = sourceMatterProperty.interestEstate;
    targetMatterProperty.parcel = sourceMatterProperty.parcel;
    targetMatterProperty.section = sourceMatterProperty.section;
    targetMatterProperty.easementRightOfWay = sourceMatterProperty.easementRightOfWay;
    if (sourceMatterProperty.city) {
      targetMatterProperty.city = sourceMatterProperty.city;
      this.propertyTeranetComponent.updatePropertyTaxPaidTrustLedger();
    }
    targetMatterProperty.municipality = sourceMatterProperty.municipality;
    targetMatterProperty.registryOffice = sourceMatterProperty.registryOffice;
    targetMatterProperty.registryOfficeName = sourceMatterProperty.registryOfficeName;
    targetMatterProperty.rollNumberType = sourceMatterProperty.rollNumberType;
    if (sourceMatterProperty.rollNumber) {
      targetMatterProperty.rollNumber = new RollNumber(sourceMatterProperty.rollNumber);
    }
    targetMatterProperty.lastTransferNumber = sourceMatterProperty.lastTransferNumber;
    targetMatterProperty.lastInstrumentNumber = sourceMatterProperty.lastInstrumentNumber;

  }

  copyCondoExpensesIntoUnitLevelPlan(sourceMatter: Matter, targetMatter: Matter) {
    targetMatter.matterPropertyWithCondo.condominiumJurisdiction = sourceMatter.matterPropertyWithCondo.condominiumJurisdiction;
    targetMatter.matterPropertyWithCondo.condominiumPlans = [];
    sourceMatter.matterPropertyWithCondo.condominiumPlans.forEach((condominiumPlan) => {
      let condoPlan = new CondominiumPlan();
      condoPlan.condominiumPlanNumber = condominiumPlan.condominiumPlanNumber;
      condoPlan.condominiumPlanType = condominiumPlan.condominiumPlanType;
      targetMatter.matterPropertyWithCondo.condominiumPlans.push(condoPlan);
    });
    targetMatter.copyCondoExpensesIntoUnitLevelPlan(sourceMatter.matterPropertyWithCondo, false);
    targetMatter.matterPropertyWithCondo.condominiumExpenses.forEach((unitLevelPlan: CondominiumExpense) => {
      unitLevelPlan.condominiumExpense = 0;
    });
    targetMatter.matterPropertyWithCondo.condominiumTotalExpenses = 0;
  }

  updatePropertySurveyor(sourceMatter: Matter, targetMatter: Matter) {
    //for field: Surveyor and related Attention dropdowns
    if (sourceMatter.surveyorMatterParticipant) {
      this.propertyTeranetComponent.selectedSurveyorContact = sourceMatter.surveyorMatterParticipant.contact; //attention value is embedded in surveyor contact
      this.propertyTeranetComponent.dataSelectedSurveyor();
      targetMatter.surveyorMatterParticipant.contact.sourceContactId = sourceMatter.surveyorMatterParticipant.contact.sourceContactId;
    }
  }

  async copyMatterFireInsurance(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    if (sourceMatter.isPurchase && targetMatter.isMortgage || sourceMatter.isMortgage && targetMatter.isMortgage) {
      // await this.initFireInsurerComponent(targetMatter);

      sourceMatter.copyFireInsuranceInfoData(targetMatter);

      if (sourceMatter.fireInsuranceContactInfo) {
        targetMatter.fireInsuranceContactInfo[ 'matterId' ] = null;

        let fireInsurerOrBrokerSourceContact: Contact = await this.getSourceContact(sourceMatter.fireInsurerOrBrokerContact);
        if (fireInsurerOrBrokerSourceContact) {
          let newMatterParticipant: MatterParticipant = targetMatter.addMatterParticipant(fireInsurerOrBrokerSourceContact, true, targetMatter.isFireInsuranceContactBroker ? MatterParticipantRoleTypes.BROKER : MatterParticipantRoleTypes.INSURER);
          this.matterParticipantService.updateTemplateParticipantWithTargetParticipant(newMatterParticipant, targetMatter.isFireInsuranceContactBroker ? sourceMatter.brokerMatterParticipant : sourceMatter.insurerMatterParticipant);
        }
        targetMatter.fireInsuranceContactInfo.expiryDate = null;
      }
    }
  }

  async copyMatterWithSameMatterType(sourceMatter: Matter): Promise<Matter> {
    let targetMatter = new Matter(sourceMatter);
    targetMatter.sourceMatterId = sourceMatter.id;
    targetMatter.inaccessible = false;
    targetMatter.matterCloseDate = undefined;
    targetMatter = MatterUtil.initializeCopiedMatter(targetMatter, true);
    targetMatter = MatterIdCleaner.clearMatterIds(targetMatter);
    await this.updateOutOfSyncMatterParticipants(targetMatter);
    this.removeUnwanterDataFromMatterParticipants(targetMatter);
    this.removeEmpMortgages(targetMatter);
    this.clearParcelRegisterId(targetMatter);
    this.clearTeranetConnectCharges(targetMatter);
    if (!sourceMatter.isOtherTitleInsurer()) {
      this.removeTitleInsurance(targetMatter);
    }
    this.clearMatterNotification(targetMatter);
    this.clearInstrumentReferenceFromRequisitions(targetMatter);
    this.clearAltoForms(targetMatter);
    return targetMatter;
  }

  clearParcelRegisterId(targetMatter: Matter) {
    if (targetMatter.matterPropertyWithCondo) {
      targetMatter.matterPropertyWithCondo.lastImportedParcelRegisterId = undefined;
    }
  }

  clearTeranetConnectCharges(targetMatter: Matter) {
    if (targetMatter.matterPropertyWithCondo) {
      targetMatter.soaTrustLedgerCollection.removeTeranetConnectChargesFromSOA();
    }
  }

  clearMatterNotification(targetMatter: Matter): void {
    targetMatter.matterNotifications = [];
  }

  clearInstrumentReferenceFromRequisitions(targetMatter: Matter): void {
    //when copying  a matter, removing link from copied requisitions to exiting instruments since they do not exist in the new matter
    if (targetMatter.matterRequisitions && targetMatter.matterRequisitions.length) {
      targetMatter.matterRequisitions.forEach(requisition => {
        requisition.instrumentId = null;
      });
    }
  }

  clearAltoForms(targetMatter: Matter): void {
    if (targetMatter && targetMatter.altoEForms && targetMatter.altoEForms.length) {
      targetMatter.altoEForms.forEach((item) => {
        item.eFormIdentifier = null;
        item.documentType = null;
        item.documentTypeDescription = null;
        item.lastUpdatedDate = null;
        item.sent = null;
        item.sentDate = null;
      });
    }
  }

  async updateOutOfSyncMatterParticipants(matter: Matter) {
    //Excluding OtherSide Lawclerk (OTHERPARTY_LAWCLERK) from the participants as it will copied as it is.
    let matterParticipantsWithSourceContactId = matter.matterParticipants.filter(mp => mp.contact && mp.contact.sourceContactId
      && MatterParticipantRoleTypes.OTHERPARTY_LAWCLERK != mp.matterParticipantRole);
    let contactCalls: Observable<Contact>[] = [];
    for (let mp of matterParticipantsWithSourceContactId) {
      contactCalls.push(this.contactQueryService.getContactForMatter(mp.contact.sourceContactId));
    }

    if (contactCalls && contactCalls.length) {
      let sourceContacts: Contact[] = await forkJoin(contactCalls).toPromise();
      for (let sourceContact of sourceContacts) {
        let matterParticipant = matterParticipantsWithSourceContactId.find(mp => mp.contact && mp.contact.sourceContactId == sourceContact.id);
        if (matterParticipant && matterParticipant.contact && sourceContact.lastUpdatedTimeStamp > matterParticipant.contact.lastSyncedFromSource) {
          matterParticipant.contact.update(sourceContact);
        }
      }
    }
  }

  removeUnwanterDataFromMatterParticipants(matter: Matter): void {
    matter.matterParticipants.forEach((mp) => {
      if (mp.contact) {
        mp.contact.idInfoEnteredBy = null;
        mp.contact.contactIdInfoEnteredBy = null;
        mp.contact.enteredOn = null;
      }
      mp.welcomePackagePrecedentId = null;
      mp.welcomePackageStatus = null;
      MatterCleanUpUtil.cleanUpConsentedSpouseContactOfFla(mp);
    });
  }

  removeEmpMortgages(matter: Matter): void {
    let empMortgages = matter.mortgages.filter(item => item.isEmpMortgage());
    empMortgages.forEach((empMortgage) => {
      let deletedIndex: number = matter.mortgages.findIndex(item => item == empMortgage);
      let deletedMortgagePriority = empMortgage.mortgagePriority;
      matter.deleteMortgage(empMortgage);
      if (matter.soaTrustLedgerCollection) {
        matter.soaTrustLedgerCollection.updateMortgageIndex(deletedIndex);
        if (matter.secondarySoaSheetsCollection) {
          matter.secondarySoaSheetsCollection.forEach(collection => {
            collection.updateMortgageIndex(deletedIndex);
          });
        }
        matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
        if (matter.secondarySoaSheetsCollection) {
          matter.secondarySoaSheetsCollection.forEach(collection => {
            collection.updateERegAndRegisterCharges();
          });
        }
      }
      MatterUtil.updateMatterAfterChangingPriority(matter, deletedMortgagePriority, this.mortgageSoAdjService);
      this.cleanupUserDefinedFieldsAfterRemovingMortgage(matter, empMortgage);
    });

  }

  cleanupUserDefinedFieldsAfterRemovingMortgage(matter: Matter, mortgage: Mortgage): void {
    matter.matterUserDefinedFields = matter.matterUserDefinedFields.filter(udf => !udf.mortgageId || udf.mortgageId && udf.mortgageId != mortgage.id);
  }

  removeTitleInsurance(matter: Matter): void {
    matter.insurer = null;
    matter.transactionTitleInsuredCode = 'N/y';  //This is a string not a DpBooleanValueTypes
    matter.matterTitleInsurance = new MatterTitleInsurance();
    if (matter && matter.soaTrustLedgerCollection) {
      matter.soaTrustLedgerCollection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
    }
    if (matter.secondarySoaSheetsCollection) {
      matter.secondarySoaSheetsCollection.forEach(collection => {
        collection.addOrRemoveTitleInsuranceSoaAndTrustLedger();
      });
    }
  }
}
