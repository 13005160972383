import {
  AssignmentRegNoTitle,
  ClientCapacity,
  FeeForCondoStatusCertificateTitle,
  FeeForStatusCertificateTitle,
  LateClosingFieldName,
  LawClerkName, LawClerkPluralTitle,
  LawClerkTitle,
  LawyerTitle,
  MortgageeNameTitle,
  MortgageIsToTitle,
  OnClosingDateName,
  Section13CurrentReserveFundAmount,
  Section13ReserveFundStudyDateTitle,
  Section15AnnualReserveFundAmount, SolicitorPluralTitle,
  SolicitorTitle,
  StatusCertificateInformationTitle,
  Witness,
  WitnessName
} from './province-based-dropdowns';

export const DEFAULT_LABEL: string = 'DEFAULT';

export const materDateOfAgreement = {
  DateOfAgrt: 'Date of Agrt of P&S',
  PurchContractDate: 'Purch Contract Date',
  OfferToPurchase: 'Offer to Purchase',
  CompletionDate: 'Completion Date',
  ContractPSDate: 'Contract of P&S Date',
  ContractDate: 'Contract Date'
};

export const TabDTopic = {
  //SUBJECT_PROPERTY_MB : 'Subject Property/TPR',
  //SUBJECT_PROPERTY_AB : 'Subject Property/SPIN',
  SUBJECT_PROPERTY: 'Property'
  //PROPERTY_TERANET : 'Property/Teranet Connect'
};

export const docProfileSoaPrefLabels = {
  PrintBalance: 'Print Balance Due on Closing at the bottom of the page',
  PrintCash: 'Print Cash to Close at the bottom of the page',
  CashToClose: 'Cash to Close',
  BalanceDueOnClosing: 'Balance Due on Closing',
  BalanceToClose: 'Balance to close',
  PrintBalanceToClose: 'Print Balance to close at the bottom of the page'
};

export const jurisdictionDepartmentName = {
  GENERAL: 'General',
  LAND_REGISTRY_OFFICE: 'Land Registry Office',
  LAND_TITLES_OFFICE: 'Land Titles Office',
  LAND_REGISTRATION_OFFICE: 'Land Registration Office'
};

export const subjectPropertyUnitLevelPlanLabels = {
  UnitLevelPlan: 'Unit/Level/Plan',
  LincPlanUnit: 'LINC/Plan/Unit/Roll',
  UnitLevelCondoNumber: 'Unit/Level/Condo Number'
};

export const subjectPropertyCityLabels = {
  CITY_DEFAULT: '\"City\"',
  CITY_AB: 'City'
};
export const subjectPropertyMunicipalityLabels = {
  MUNICIPALITY_DEFAULT: '\"Municipality\"',
  MUNICIPALITY_NS: '\"County\"'
};

export const subjectPropertyLastInstrumentLabels = {
  ON_LAST_INSTRUMENT: 'Last Instrument Number',
  AB_LAST_INSTRUMENT: 'Last Encumbrance No.'
};
export const subjectPropertyPinNoLabels = {
  PID: 'PID Number(s)'
};
export const subjectPropertySurveyDateLabels = {
  ON_SURVEY_DATE: 'Survey Date',
  AB_SURVEY_DATE: 'RPR Date',
  MB_SURVEY_DATE: 'Building Location Certificate'
};
export const subjectPropertyPropertyTaxLabels = {
  ON_PROPERTY_TAX: 'Property Taxes',
  AB_PROPERTY_TAX: 'Property Taxes/TIPPS'
};
export const subjectPropertyRegistryOfficeLabels = {
  REGISTRY_OFFICE_DEFAULT: 'Registry Office',
  REGISTRY_OFFICE_AB: 'Land Title Office',
  REGISTRY_OFFICE_NS: 'Land Titles Office',
  REGISTRY_OFFICE_NB: 'Land Title Office',
  REGISTRY_OFFICE_MB: 'Land Title Office'
};

export const MortgageFinalReportToMortgageeEnclosures = {
  FinalReportToMortgageeEnclosures: 'Final Report to Mortgagee Enclosures',
  FinalReportToMortgageeEnclosuresWithCoverLetter: 'Final Report (Cover Letter) to Mortgagee Enclosures',
  FinalReportToLenderEnclosuresWithCoverLetter: 'Final Report (Cover Letter) to Lender Enclosures'
};

export const CapacityTitleTitle = {
  Capacity: 'Capacity',
  Tenure: 'Tenure',
  Tenancy: 'Tenancy'
};

export const StandardMortgageFormTitleTitle = {
  SCT_No: 'SCT No.',
  StandardMortgageFormTitle: 'Standard Mortgage Form',
  OptionalCovenants: 'Optional Covenants'
};

export const MortgagePayout = {
  TrustLedger: 'Check the box if payout amount is equal to the "Balance of Funds" on the Trust Ledger',
  DirectionReFunds: 'Check this box if payout amount is equal to the "Balance of Funds" from Direction re Funds topic',
  DirectionToPay: 'Check this box if payout amount is equal to the "Balance of Funds" from Direction to Pay topic',
  OrderToPay: 'Check this box if payout amount is equal to the "Balance of Funds" from Order to Pay topic'
};

export const SoaPrePaidRent = {
  PrePaidRent: 'Pre-Paid Rent',
  SecurityDeposit: 'Security Deposit'
};
export const SoaVendorTaxRebate = {
  HST: 'Credit Vendor HST Rebate - Purchaser Not Eligible',
  GST: 'Credit Vendor GST Rebate - Purchaser Not Eligible'
};
export const SoaPrePaidRentInterestRateTitle = {
  PrePaidRent: 'Pre-Paid Rent Interest Rate',
  SecurityDeposit: 'Security Deposit Interest Rate'
};

export const SoaBalanceDueOnClosing = {
  BalanceDueOnClosing: 'Balance Due on Closing',
  CashToClose: 'Cash to Close',
  BalanceToClose: 'Balance to Close',
  Subtotal: 'Subtotal'
};

export const SoaCommonExpenses = {
  CommonExpenses: 'Common Expenses',
  CondominiumFees: 'Condominium Fees',
  CommonElementFees: 'Common Element Fees',
  StrataFees: 'Strata Fees'
};

export const BalanceDueOnClosingTitle = {
  BalanceDueDonClosing: 'Balance due on closing',
  TotalReceipts: 'Total Receipts'
};

export const MortgageEnclosureDescription = {
  interimAndFinalEnclosures: 'Edit default list of enclosures for interim and final reports to mortgagees',
  finalEnclosures: 'Edit default list of enclosures for final reports to mortgagees',
  finalEnclosuresForLenders: 'Edit default list of enclosures for final reports to lenders'
};

export const MortgageEnclosureAdminLabel = {
  reportToMortgageeEnclosure: 'Report to Mortgagee Enclosures',
  reportToLenderEnclosure: 'Report to Lender Enclosures'
};

export const PurchaserReport = {
  transferRegistrationDate: {
    transferRegistrationDate: 'Transfer Registration Date',
    certificateOfTitleDate: 'Certificate of Title Date',
    completionDate: 'Completion Date',
    documentRegistrationDate: 'Document Registration Date'
  },
  asInstrumentNo: {
    asInstrumentNo: 'as Instrument No.',
    certificateOfTitleNumber: 'Certificate of Title Number',
    titleNumbers: 'Title No(s)',
    titleNumber: 'Title No.',
    asDocumentNo: 'as Document No.'
  },
  commonExpenses1stPaymentDue: {
    commonExpenses1stPaymentDue: 'Common Expenses - 1st Payment Due',
    condominiumFees1stPaymentDue: 'Condominium Fees - 1st Payment Due'
  },
  qualificationAffectingTitle: {
    qualificationAffectingTitle: 'Qualification affecting title',
    otherMatters: 'Other Matters',
    benefitsBurdensAffectingTitle: 'Benefits & Burdens affecting title'
  },
  includeDetails: {
    includeDetailsUnfulfilledUndertakings: 'Include details of unfulfilled undertakings of Vendor\'s solicitor?',
    includeDetailsEncumbrances: 'Include details of vendor\'s encumbrances to be discharged in reporting letter?'
  }
};

export const provinceBasedFieldDisplay: any = {
  'matter.matterOpening.possessionDate': [
    {'MB': 'PS'},
    {'SK': 'PS'} ]
};

export const requisitionDateLabel = {
  requisitionDate: 'Requisition Date',
  occupancyDate: 'Occupancy Date',
  titleConditionDate: 'Title Condition Date'
};

export const occupancyDateLabel = {
  occupancyDate: 'Occupancy Date',
  possessionDate: 'Possession Date'
};

export const requisitionSubmittedLabel = {
  titleConditionDate: 'Title Condition Submitted?'
};
export const contractTerminatedLabel = {
  agrtTerminated: 'P&S Agrt terminated',
  contractTerminated: 'P&S Contract terminated'
};
// For Project Sale Matter if for that province you have different label then use -P Identifier with province code
// Example :
// 'lateClosingInterestModal.cashToClose' : {
//     'AB' : 'Cash to close',
//     'AB-P' : 'Nothing to close',
//         'MB' : 'Balance to Close',
//         'SK' : 'Balance to Close'
// },

export const provinceBasedFieldLabels: any = {

  'get': (key: string, provinceCode: string) => {
    if (provinceBasedFieldLabels[ key ]) {
      if (provinceBasedFieldLabels[ key ][ provinceCode ]) {
        return provinceBasedFieldLabels[ key ][ provinceCode ];
      }
      return provinceBasedFieldLabels[ key ][ 'DEFAULT' ];
    } else {
      return '';
    }

  },

  'lateClosingInterestModal.cashDifference': {
    'AB': 'Cash Difference',
    'MB': 'Balance of Down Payment',
    'SK': 'Balance of Down Payment'
  },

  'lateClosingInterestModal.lateInterestAmtTotal': {
    'AB': 'Late Interest Amount Total',
    'MB': 'Interest Re Delay',
    'SK': 'Interest Re Delay'
  },

  'lateClosingInterestModal.lateClosingDate': {
    'AB': 'Late Closing Date',
    'MB': 'Delayed Closing Date',
    'SK': 'Delayed Closing Date'
  },

  'lateClosingInterestModal.cashToClose': {
    'AB': 'Cash to close',
    'MB': 'Balance to Close',
    'SK': 'Balance to Close'
  },

  'provinceBasedLawClerkTitle': {
    'ON': LawClerkTitle.Law_Clerk,
    'NB': LawClerkTitle.Law_Clerk,
    'NS': LawClerkTitle.Paralegal,
    'AB': LawClerkTitle.Legal_Assistant,
    'MB': LawClerkTitle.Legal_Assistant,
    'SK': LawClerkTitle.Legal_Assistant,
    'BC': LawClerkTitle.Assistant
  },
  'matter.matterOpening.lateClosing': {
    'ON': LateClosingFieldName.Late_Closing,
    'NB': LateClosingFieldName.Late_Closing,
    'NS': LateClosingFieldName.Late_Closing,
    'AB': LateClosingFieldName.Late_Closing,
    'MB': LateClosingFieldName.Interest,
    'SK': LateClosingFieldName.Interest,
    'BC': LateClosingFieldName.Late_Closing
  },
  'matter.matterOpening.lawClerkName': {
    'ON': LawClerkName.Law_Clerk,
    'NB': LawClerkName.Law_Clerk,
    'NS': LawClerkName.Paralegal,
    'AB': LawClerkName.Legal_Assistant,
    'MB': LawClerkName.Legal_Assistant,
    'SK': LawClerkName.Legal_Assistant,
    'BC': LawClerkName.Assistant
  },
  'matter.matterOpening.onClosingDate': {
    'AB': OnClosingDateName.On_Closing_Date,
    'MB': OnClosingDateName.On_Possession,
    'SK': OnClosingDateName.On_Possession
  },
  'matter.matterOpening.witnessName': {
    'ON': WitnessName.Witness_Notary_Name,
    'AB': WitnessName.Witness_Name,
    'MB': WitnessName.Witness_Notary_Name,
    'SK': WitnessName.Witness_Notary_Name,
    'NB': WitnessName.Witness_Notary_Name,
    'NS': WitnessName.Witness_Notary_Name,
    'BC': WitnessName.Witness_Name
  },
  'matter.matterOpening.witness': {
    'ON': Witness.Witness_Notary,
    'AB': Witness.Witness,
    'MB': Witness.Witness_Notary,
    'SK': Witness.Witness_Notary,
    'NB': Witness.Witness_Notary,
    'NS': Witness.Witness_Notary,
    'BC': Witness.Witness
  },
  'matter.matterOpening.dateOfAgrtPS': {
    'ON': materDateOfAgreement.DateOfAgrt,
    'NB': materDateOfAgreement.ContractPSDate,
    'NS': materDateOfAgreement.DateOfAgrt,
    'AB': materDateOfAgreement.PurchContractDate,
    'MB': materDateOfAgreement.OfferToPurchase,
    'SK': materDateOfAgreement.OfferToPurchase,
    'BC': materDateOfAgreement.ContractDate
  },
  'matter.matterOpening.requisitionDate': {
    'ON': requisitionDateLabel.requisitionDate,
    'ON-P': requisitionDateLabel.occupancyDate,
    'AB': requisitionDateLabel.requisitionDate,
    'MB': requisitionDateLabel.requisitionDate,
    'SK': requisitionDateLabel.requisitionDate,
    'NS': requisitionDateLabel.titleConditionDate
  },
  'matter.matterOpening.occupancyDate': {
    'DEFAULT': occupancyDateLabel.occupancyDate,
    'BC': occupancyDateLabel.possessionDate
  },
  'matter.matterOpening.requisitionSubmitted': {
    'NS': requisitionSubmittedLabel.titleConditionDate
  },
  'matter.matterOpening.contactTerminated': {
    'ON-P': contractTerminatedLabel.agrtTerminated,
    'AB-P': contractTerminatedLabel.contractTerminated
  },
  'matter.matterOpening.solicitorName': {
    'DEFAULT': 'Solicitor Name',
    'BC': 'Lawyer/Notary Name'
  },
  'matter.matterOpening.solicitor': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'matter.contactIdDetails.corpNo': {
    'DEFAULT': 'Corp.No',
    'BC': 'Inc./Reg. No'
  },
  'matter.matterOverview.agentName': {
    'DEFAULT': 'RE Agent',
    'BC': 'Listing Agent'
  },
  'matter.matterOverview.brokerName': {
    'DEFAULT': 'RE Broker',
    'BC': 'RE Listing Broker'
  },
  'property.teranet.connect.tab.label': {
    'ON': 'Teranet Connect',
    'AB': 'SPIN',
    'SK': 'ISC',
    'BC': 'LTSA',
    'MB': 'TPR'
  },
  'admin.documentProfile.soa.printBalanceFlag': {
    'DEFAULT': docProfileSoaPrefLabels.PrintBalance,
    'AB': docProfileSoaPrefLabels.PrintCash,
    'MB': docProfileSoaPrefLabels.PrintBalanceToClose,
    'SK': docProfileSoaPrefLabels.PrintBalanceToClose
  },
  'admin.documentProfile.soa.balanceDueOnClosing': {
    'DEFAULT': docProfileSoaPrefLabels.BalanceDueOnClosing,
    'AB': docProfileSoaPrefLabels.CashToClose,
    'MB': docProfileSoaPrefLabels.BalanceToClose,
    'SK': docProfileSoaPrefLabels.BalanceToClose
  },
  'admin.documentProfile.misc.displayJurisdiction': {
    'DEFAULT': 'Display jurisdiction where Purchaser is executing doc\'ts in a Sale txn?',
    'BC': 'Display jurisdiction where Buyer is executing doc’ts in a Sale txn?'
  },
  'matter.subjectproperty.unitLevelPlanLabel': {
    'DEFAULT': subjectPropertyUnitLevelPlanLabels.UnitLevelPlan,
    'AB': subjectPropertyUnitLevelPlanLabels.LincPlanUnit,
    'NS': subjectPropertyUnitLevelPlanLabels.UnitLevelCondoNumber
  },
  'matter.subjectproperty.unitLevelPlanCondominiumTitle': {
    'DEFAULT': 'Condominium Plan',
    'NS': 'Condominium Number',
    'NB': 'Condominium Number'
  },
  'matter.subjectproperty.unitLevelPlanPinLabel': {
    'DEFAULT': 'PIN Number',
    'NS': 'PID Number',
    'NB': 'PID Number',
    'BC': 'PID Number'
  },
  'matter.subjectproperty.unitLevelPlanAccountNumberLabel': {
    'DEFAULT': 'AAN',
    'NB': 'PAN'
  },
  'matter.subjectProperty.city': {
    'DEFAULT': subjectPropertyCityLabels.CITY_DEFAULT,
    'AB': subjectPropertyCityLabels.CITY_AB
  },
  'matter.subjectProperty.municipality': {
    'DEFAULT': subjectPropertyMunicipalityLabels.MUNICIPALITY_DEFAULT,
    'NS': subjectPropertyMunicipalityLabels.MUNICIPALITY_NS
  },
  'matter.subjectProperty.registryOffice': {
    'DEFAULT': subjectPropertyRegistryOfficeLabels.REGISTRY_OFFICE_DEFAULT,
    'AB': subjectPropertyRegistryOfficeLabels.REGISTRY_OFFICE_AB,
    'NS': subjectPropertyRegistryOfficeLabels.REGISTRY_OFFICE_NS,
    'NB': subjectPropertyRegistryOfficeLabels.REGISTRY_OFFICE_NB,
    'MB': subjectPropertyRegistryOfficeLabels.REGISTRY_OFFICE_MB
  },
  'matter.subjectProperty.lastInstrumentLabel': {
    'DEFAULT': subjectPropertyLastInstrumentLabels.ON_LAST_INSTRUMENT,
    'AB': subjectPropertyLastInstrumentLabels.AB_LAST_INSTRUMENT
  },
  'matter.subjectProperty.pinNoLabel': {
    'NB': subjectPropertyPinNoLabels.PID,
    'NS': subjectPropertyPinNoLabels.PID,
    'BC': subjectPropertyPinNoLabels.PID
  },
  'matter.subjectProperty.pan1': {
    'DEFAULT': 'PAN 1',
    'NS': 'AAN 1'
  },
  'matter.subjectProperty.pans': {
    'DEFAULT': 'PAN(s)',
    'NS': 'AAN(s)'
  },
  'matter.subjectProperty.addAnotherPIN': {
    'DEFAULT': 'Add Another PIN',
    'NS': 'Add Another PID and AAN',
    'NB': 'Add Another PID',
    'BC': 'Add Another PID'
  },
  'matter.subjectProperty.addLincAndRollNo': {
    'DEFAULT': 'Add Another PIN',
    'NS': 'Add Another PID and AAN',
    'NB': 'Add Another PID',
    'BC': 'Add Another PID'
  },
  'matter.subjectProperty.interestEstate.label': {
    'DEFAULT': 'Interest/Estate',
    'BC': 'Transaction Type'
  },
  'matter.STG.PINLabel': {
    'DEFAULT': 'PIN',
    'AB': 'LINC',
    'MB': 'Title Number',
    'NB': 'PID',
    'BC': 'PID'
  },
  'matter.subjectProperty.landTransferTax': {
    'DEFAULT': 'Land Transfer Tax',
    'NS': 'Deed Transfer Tax'
  },
  'matter.subjectProperty.form54': {
    'DEFAULT': 'Form 54 ',
    'NS': 'Transfer Tax Aff\'d '
  },
  'matter.subjectProperty.surveyDate': {
    'DEFAULT': subjectPropertySurveyDateLabels.ON_SURVEY_DATE,
    'AB': subjectPropertySurveyDateLabels.AB_SURVEY_DATE,
    'SK': subjectPropertySurveyDateLabels.AB_SURVEY_DATE,
    'MB': subjectPropertySurveyDateLabels.MB_SURVEY_DATE
  },
  'matter.subjectProperty.propertyTax': {
    'DEFAULT': subjectPropertyPropertyTaxLabels.ON_PROPERTY_TAX,
    'AB': subjectPropertyPropertyTaxLabels.AB_PROPERTY_TAX,
    'SK': subjectPropertyPropertyTaxLabels.AB_PROPERTY_TAX,
    'MB': subjectPropertyPropertyTaxLabels.AB_PROPERTY_TAX
  },
  'matter.subjectProperty.planBlockLot': {
    'DEFAULT': 'Plan/Block/Lot',
    'NS': 'Lot'
  },
  'matter.subjectProperty.RPRApplicable': {
    'DEFAULT': 'RPR Applicable',
    'MB': 'Building Location Certificate applicable'
  },
  'matter.subjectProperty.condominiumFees': {
    'DEFAULT': 'Condominium Fees',
    'MB': 'Common Element Fees'
  },

  'provinceBasedTabDTopic': {
    'DEFAULT': TabDTopic.SUBJECT_PROPERTY //use for all messages for Tab D for all provinces
    // 'AB' : TabDTopic.SUBJECT_PROPERTY_AB,
    // 'MB' : TabDTopic.SUBJECT_PROPERTY_MB,
  },

  'provinceBasedClientOrSolicitorLabel': {
    'DEFAULT': 'client',
    'BC': 'Seller'
  },

  'provinceBasedClientOrSolicitorLabelPurchase': {
    'DEFAULT': 'client',
    'BC': 'Buyer'
  },

  'provinceBasedDischargedByInstrumentNo': {
    'DEFAULT': 'Discharged by Instrument No.',
    'BC': 'Discharged by Registration No.'
  },

  'provinceBasedMortgageIsToTitle': {
    //'ON' : MortgageIsToTitle.MORTGAGE_IS_TO,
    'DEFAULT': MortgageIsToTitle.IS_TO
  },

  'mortgageRegNo': {
    'DEFAULT': 'Mortgage Reg. No.',
    'NS': 'Mortgage Doc. No.',
    'BC': 'Registration No.'
  },

  'typeOfMortgagee': {
    'DEFAULT': 'Type of Mortgagee',
    'BC': 'Registered Owner Type'
  },

  'mortgageRegDate': {
    'DEFAULT': 'Mortgage Reg. Date',
    'NS': 'Mortgage Doc. Date',
    'BC': 'Registration Date'
  },

  'dischargeRegisteredBy': {
    'DEFAULT': 'Discharge is to be registered by',
    'NB': 'Who is registering the discharge',
    'NS': 'Who is registering the discharge'
  },

  'provinceBasedMortgageeNameTitle': {
    'ON': MortgageeNameTitle.ON_MORTGAGEE_NAME,
    'AB': MortgageeNameTitle.AB_MORTGAGEE_NAME,
    'SK': MortgageeNameTitle.SK_MORTGAGEE_NAME,
    'MB': MortgageeNameTitle.MB_MORTGAGEE_NAME,
    'BC': MortgageeNameTitle.BC_MORTGAGEE_NAME
  },
  'provinceBasedAssignmentRegNoTitle': {
    'ON': AssignmentRegNoTitle.ON_ASSIGNMENT_REG_NO,
    'AB': AssignmentRegNoTitle.AB_ASSIGNMENT_REG_NO,
    'SK': AssignmentRegNoTitle.SK_ASSIGNMENT_REG_NO,
    'NB': AssignmentRegNoTitle.NB_ASSIGNMENT_REG_NO,
    'NS': AssignmentRegNoTitle.NS_ASSIGNMENT_REG_NO

  },
  'provinceBasedRelatedInstrumentsShort': {
    'BC': 'Related Charge',
    'DEFAULT': 'Related Inst.'
  },
  'provinceBasedExistingMortgage': {
    'BC': 'Charge',
    'DEFAULT': 'Mortgage'
  },
  'provinceBasedRelatedInstruments': {
    'BC': 'Related Charges',
    'DEFAULT': 'Related Instruments'
  },
  'provinceBasedInstrumentType': {
    'BC': 'Nature',
    'DEFAULT': 'Instrument Type'
  },
  'provinceBasedAddAnotherRelatedInstrument': {
    'BC': 'Add another related charge',
    'DEFAULT': 'Add another related instrument'
  },
  'provinceBasedMortgageLoanNo': {
    'BC': 'Reference No.',
    'DEFAULT': 'Mortgage Loan No.'
  },
  'provinceBasedMortgagee': {
    'BC': 'Lender',
    'DEFAULT': 'Mortgagee'
  },
  'provinceBasedDischargeStatement': {
    'BC': 'Payout statement',
    'DEFAULT': 'discharge statement?'
  },
  'amountPayableToDischarge': {
    'BC': 'Amount Payable to<br/>discharge',
    'DEFAULT': 'Amount Payable to<br/>discharge this<br/>mortgage'
  },
  'strataMaintenanceFees': {
    'BC': 'Strata Maintenance Fees',
    'DEFAULT': 'Condo Maintenance Fees'
  },
  'contact.jurisdiction.landRegistryOffice': {
    'DEFAULT': jurisdictionDepartmentName.LAND_REGISTRY_OFFICE,
    'AB': jurisdictionDepartmentName.LAND_TITLES_OFFICE,
    'MB': jurisdictionDepartmentName.LAND_TITLES_OFFICE,
    'SK': jurisdictionDepartmentName.LAND_TITLES_OFFICE,
    'NS': jurisdictionDepartmentName.LAND_REGISTRATION_OFFICE
  },
  'contact.jurisdiction.secondLRO': {
    'DEFAULT': jurisdictionDepartmentName.LAND_TITLES_OFFICE
  },
  'contact.condo.feeForCondoStatusCertificate': {
    'DEFAULT': FeeForCondoStatusCertificateTitle.Fee_For_Status_Certificate,
    'NB': FeeForCondoStatusCertificateTitle.Fee_For_Estoppel_Certificate,
    'NS': FeeForCondoStatusCertificateTitle.Fee_For_Estoppel_Certificate
  },
  'contact.condo.section13ReserveFundStudyDate': {
    'DEFAULT': Section13ReserveFundStudyDateTitle.Section13_Reserve_Fund_Study_Date,
    'NB': Section13ReserveFundStudyDateTitle.Reserve_Fund_Study_Date,
    'NS': Section13ReserveFundStudyDateTitle.Reserve_Fund_Study_Date
  },
  'contact.condo.section13CurrentReserveFundAmount': {
    'DEFAULT': Section13CurrentReserveFundAmount.Section13_Current_Reserve_Fund_Amount,
    'NB': Section13CurrentReserveFundAmount.Current_Reserve_Fund_Amount,
    'NS': Section13CurrentReserveFundAmount.Current_Reserve_Fund_Amount
  },
  'contact.condo.section15AnnualReserveFundAmount': {
    'DEFAULT': Section15AnnualReserveFundAmount.Section15_Annual_Reserve_Fund_Amount,
    'NB': Section15AnnualReserveFundAmount.Annual_Reserve_Fund_Amount,
    'NS': Section15AnnualReserveFundAmount.Annual_Reserve_Fund_Amount
  },
  'contact.condo.statusCertificateInformationTitle': {
    'DEFAULT': StatusCertificateInformationTitle.Status_Certificate_Information,
    'NB': StatusCertificateInformationTitle.Estoppel_Certificate_Information,
    'NS': StatusCertificateInformationTitle.Estoppel_Certificate_Information
  },
  'contact.condo.feeForStatusCertificate': {
    'DEFAULT': FeeForStatusCertificateTitle.Fee_For_Status_Certificate,
    'NB': FeeForStatusCertificateTitle.Fee_For_Estoppel_Certificate,
    'NS': FeeForStatusCertificateTitle.Fee_For_Estoppel_Certificate
  },
  'matter.purchaser.capacity': {
    'DEFAULT': ClientCapacity.CAPACITY,
    'AB': ClientCapacity.TENURE,
    'SK': ClientCapacity.TENURE,
    'MB': ClientCapacity.TENURE,
    'NB': ClientCapacity.TENURE,
    'NS': ClientCapacity.TENURE,
    'BC': ClientCapacity.TENANCY
  },
  'matter.purchaser.capacity.share': {
    'AB': 'Tenure/Share',
    'SK': 'Tenure/Share',
    'MB': 'Tenure/Share',
    'NB': 'Tenure/Share',
    'NS': 'Share',
    'BC': 'Fractional Interest'
  },

  'matter.otherParty.lawClerkDetails': {
    'DEFAULT': 'Law Clerk Details',
    'AB': 'Legal Assistant Details',
    'SK': 'Legal Assistant Details',
    'MB': 'Legal Assistant Details',
    'NS': 'Paralegal Details',
    'BC': 'Assistant Details'
  },
  'matter.otherParty.lawClerkName': {
    'DEFAULT': 'Law Clerk\'s Name',
    'AB': 'Legal Assistant Name',
    'SK': 'Legal Assistant Name',
    'MB': 'Legal Assistant Name',
    'NS': 'Paralegal Name',
    'BC': 'Assistant\'s Name'
  },
  'matter.otherParty.lawClerkEmail': {
    'DEFAULT': 'Law Clerk\'s Email',
    'AB': 'Legal Assistant Email',
    'SK': 'Legal Assistant Email',
    'MB': 'Legal Assistant Email',
    'NS': 'Paralegal Email',
    'BC': 'Assistant\'s Email'
  },

  'matter.otherParty.solicitorTitleForSale': {
    'BC': 'Buyer\'s Lawyer/Notary',
    'DEFAULT': 'Purchaser\'s Solicitor'
  },

  'matter.otherParty.directDeposit.label.purchase': {
    'BC': 'seller Lawyer/Notary\'s',
    'DEFAULT': 'vendor solicitor\'s'
  },

  'matter.otherParty.solicitorTitleForPurchase': {
    'BC': 'Seller\'s Lawyer/Notary',
    'DEFAULT': 'Vendor\'s Solicitor'
  },

  'matter.purchase.directDeposit': {
    'DEFAULT': 'funds to client\'s',
    'AB': 'funds to client\'s',
    'SK': 'funds to client\'s',
    'MB': 'funds to client\'s',
    'NB': 'funds to client\'s',
    'NS': 'funds to client\'s',
    'BC': 'funds to Buyer\'s'
  },

  'matter.mortgage.directDeposit': {
    'DEFAULT': 'funds to client\'s'
  },

  'matter.purchase.subjectPropertyResidence': {
    'DEFAULT': 'Does Vendor currently reside at the subject property?',
    'BC': 'Does Seller currently reside at the subject property?'
  },

  'matter.purchase.subjectPropertyResidence.purchase': {
    'DEFAULT': 'Will purchaser be residing at subject property?',
    'BC': 'Will buyer be residing at property being purchased?'
  },

  'matter.otherParty.subjectPropertyResidence': {
    'DEFAULT': 'Will purchaser be residing at subject property?',
    'BC': 'Will Buyer be residing at subject property?'
  },

  'matter.mortgage.report.MortgageFinalReportToMortgageeEnclosures': {
    'DEFAULT': MortgageFinalReportToMortgageeEnclosures.FinalReportToMortgageeEnclosures,
    'AB': MortgageFinalReportToMortgageeEnclosures.FinalReportToMortgageeEnclosuresWithCoverLetter,
    'BC': MortgageFinalReportToMortgageeEnclosures.FinalReportToLenderEnclosuresWithCoverLetter
  },

  'matter.mortgage.report.mortgageRegistrationDate': {
    'DEFAULT': 'Mortgage Registration Date',
    'SK': 'Interest Registration Date',
    'NS': 'Mortgage Doc. Date'
  },

  'matter.mortgage.report.mortgageRegistrationNumber': {
    'DEFAULT': 'Mortgage Registration Number',
    'SK': 'Interest Registration Number',
    'NS': 'Mortgage Doc. Number'
  },

  'matter.mortgage.report.AssignmentOfRentsRegistrationDate': {
    'DEFAULT': 'Assignment of Rents Registration Date',
    'NS': 'Assignment of Rents Doc. Date'
  },

  'matter.mortgage.report.AssignmentOfRentsRegistrationNumber': {
    'DEFAULT': 'Assignment of Rents Registration Number',
    'NS': 'Assignment of Rents Doc. Number'
  },

  'matter.mortgage.mortgagee.provinceBasedCapacityTitle': {
    'ON': CapacityTitleTitle.Capacity,
    'DEFAULT': CapacityTitleTitle.Tenure
  },
  'matter.mortgage.term.provinceBasedStandardMortgageFormTitle': {
    'DEFAULT': StandardMortgageFormTitleTitle.SCT_No,
    'AB': StandardMortgageFormTitleTitle.StandardMortgageFormTitle,
    'NB': StandardMortgageFormTitleTitle.OptionalCovenants,
    'NS': StandardMortgageFormTitleTitle.OptionalCovenants
  },
  'matter.mortgage.mortgagee.mortgagePayout': {
    'AB': MortgagePayout.DirectionToPay,
    'MB': MortgagePayout.DirectionToPay,
    'SK': MortgagePayout.DirectionToPay,
    'ON': MortgagePayout.DirectionReFunds,
    'BC': MortgagePayout.OrderToPay
  },
  'matter.mortgage.mortgagee.mortgagePayout.amountPayableToDischarge': {
    'BC': 'Amount payable to discharge',
    'DEFAULT': 'Amount payable to discharge this mortgage'
  },
  'matter.mortgage.mortgagee.mortgagePayout.calculation': {
    'BC': 'Payout Calculation',
    'DEFAULT': 'Mortgage Payout Calculation'
  },
  'matter.mortgage.mortgagee.mortgagePayout.checkMortgagePayoutCalculator': {
    'BC': 'Check this box to use payout calculator',
    'DEFAULT': 'Check this box to use mortgage payout calculator'
  },
  'matter.soa.prePaidRent': {
    'DEFAULT': SoaPrePaidRent.PrePaidRent,
    'AB': SoaPrePaidRent.SecurityDeposit,
    'MB': SoaPrePaidRent.SecurityDeposit,
    'SK': SoaPrePaidRent.SecurityDeposit,
    'NS': SoaPrePaidRent.SecurityDeposit,
    'BC': SoaPrePaidRent.SecurityDeposit

  },
  'matter.soa.vendorTaxRebate': {
    'ON': SoaVendorTaxRebate.HST,
    'AB': SoaVendorTaxRebate.GST
  },
  'matter.soa.vendorTaxRebate.adjustFor': {
    'ON': 'Adjust for',
    'AB': 'Adjust for GST'
  },
  'matter.soa.other.percentageOfPurchasePrice.adjustFor': {
    'DEFAULT': 'Adjust for',
    'ON': 'Adjust for',
    'AB': 'Adjust for GST?'
  },
  'matter.soa.prePaidRentInterestRateTitle': {
    'DEFAULT': SoaPrePaidRentInterestRateTitle.PrePaidRent,
    'AB': SoaPrePaidRentInterestRateTitle.SecurityDeposit,
    'MB': SoaPrePaidRentInterestRateTitle.SecurityDeposit,
    'SK': SoaPrePaidRentInterestRateTitle.SecurityDeposit

  },
  'matter.soa.balanceDueOnClosing': {
    'DEFAULT': SoaBalanceDueOnClosing.BalanceDueOnClosing,
    'AB': SoaBalanceDueOnClosing.CashToClose,
    'SK': SoaBalanceDueOnClosing.BalanceToClose,
    'MB': SoaBalanceDueOnClosing.BalanceToClose,
    'BC': SoaBalanceDueOnClosing.Subtotal
  },
  'matter.soa.commonExpenses': {
    'DEFAULT': SoaCommonExpenses.CommonExpenses,
    'AB': SoaCommonExpenses.CondominiumFees,
    'SK': SoaCommonExpenses.CondominiumFees,
    'MB': SoaCommonExpenses.CommonElementFees,
    'BC': SoaCommonExpenses.StrataFees
  },
  'matter.soa.expense.commonExpense': {
    'DEFAULT': 'common expenses',
    'AB': 'condominium fees',
    'BC': 'strata fees'

  },
  'matter.soa.expense.taxtype': {
    'DEFAULT': 'Adjust for',
    'AB': 'Adjust for GST?'
  },
  'matter.soa.property.taxes.override.tipp': {
    'AB': 'Override TIPP payment total',
    'SK': 'Override TIPPS payment total',
    'MB': 'Override TIPP payment total'
  },
  'matter.soa.property.accNum' : {
    'DEFAULT' : 'Account Number',
    'BC' : 'Tax Roll Number'
  },

  'day.glance.view.closingOccupancy': {
    'DEFAULT': 'Closing/Occupancy',
    'BC': 'Completion/Possession'
  },
  'day.glance.view.closingOccupancy.date': {
    'DEFAULT': 'Closing/Occupancy Date',
    'BC': 'Completion/Possession Date'
  },

  'day.glance.message.notFound.closingOccupancy': {
    'DEFAULT': 'No Closing/Occupancy Found',
    'BC': 'No Completion/Possession Found'
  },

  'day.glance.view.occupancy': {
    'DEFAULT': 'Occupancy',
    'BC': 'Possession'
  },

  'day.glance.view.closing': {
    'DEFAULT': 'Closing',
    'BC': 'Completion'
  },

  'matter.soa.property.taxes.tipp': {
    'DEFAULT': 'Taxes',
    'AB': 'Taxes/TIPP',
    'SK': 'Taxes/TIPPS',
    'MB': 'Taxes/TIPP'
  },
  'matter.soa.property.taxes.tippOrTipps': {
    'DEFAULT': '',
    'AB': 'TIPP',
    'SK': 'TIPPS',
    'MB': 'TIPP'
  },
  'matter.soa.paysForDateOfClosing': {
    'AB': 'Pays for date of closing',
    'SK': 'Pays for date of possession',
    'MB': 'Pays for date of possession'
  },
  'matter.stc.balanceDueOnClosingTitle': {
    'DEFAULT': BalanceDueOnClosingTitle.BalanceDueDonClosing,
    'AB': BalanceDueOnClosingTitle.TotalReceipts
  },
  'admin.mortgageEnclosure.label' : {
    'DEFAULT': MortgageEnclosureAdminLabel.reportToMortgageeEnclosure,
    'BC': MortgageEnclosureAdminLabel.reportToLenderEnclosure
  },
  'admin.mortgageEnclosure.description': {
    'DEFAULT': MortgageEnclosureDescription.interimAndFinalEnclosures,
    'AB': MortgageEnclosureDescription.finalEnclosures,
    'BC': MortgageEnclosureDescription.finalEnclosuresForLenders
  },
  'matter-purchaser-report.transforRegistrationDate': {
    'DEFAULT': PurchaserReport.transferRegistrationDate.transferRegistrationDate,
    'AB': PurchaserReport.transferRegistrationDate.certificateOfTitleDate,
    'SK': PurchaserReport.transferRegistrationDate.completionDate,
    'MB': PurchaserReport.transferRegistrationDate.completionDate,
    'NS': PurchaserReport.transferRegistrationDate.documentRegistrationDate
  },
  'matter-purchaser-report.asInstrumentNo': {
    'DEFAULT': PurchaserReport.asInstrumentNo.asInstrumentNo,
    'AB': PurchaserReport.asInstrumentNo.certificateOfTitleNumber,
    'SK': PurchaserReport.asInstrumentNo.titleNumbers,
    'MB': PurchaserReport.asInstrumentNo.titleNumber,
    'NS': PurchaserReport.asInstrumentNo.asDocumentNo
  },
  'matter-purchaser-report.commonExpenses1stPaymentDue': {
    'DEFAULT': PurchaserReport.commonExpenses1stPaymentDue.commonExpenses1stPaymentDue,
    'AB': PurchaserReport.commonExpenses1stPaymentDue.condominiumFees1stPaymentDue,
    'SK': PurchaserReport.commonExpenses1stPaymentDue.condominiumFees1stPaymentDue,
    'MB': PurchaserReport.commonExpenses1stPaymentDue.condominiumFees1stPaymentDue
  },
  'matter-purchaser-report.qualificationAffectingTitle': {
    'DEFAULT': PurchaserReport.qualificationAffectingTitle.qualificationAffectingTitle,
    'AB': PurchaserReport.qualificationAffectingTitle.otherMatters,
    'SK': PurchaserReport.qualificationAffectingTitle.otherMatters,
    'MB': PurchaserReport.qualificationAffectingTitle.otherMatters,
    'NS': PurchaserReport.qualificationAffectingTitle.benefitsBurdensAffectingTitle
  },
  'matter-purchaser-report.includeDetails': {
    'DEFAULT': PurchaserReport.includeDetails.includeDetailsUnfulfilledUndertakings,
    'SK': PurchaserReport.includeDetails.includeDetailsEncumbrances,
    'MB': PurchaserReport.includeDetails.includeDetailsEncumbrances
  },
  'supplemental.task.category.hideCategoryFields': {
    'DEFAULT': [ 'DIRECTION RE FUNDS', 'STATEMENT OF ACCOUNT', 'TRUST LEDGER' ],
    'AB': [ 'DIRECTION TO PAY', 'STATEMENT OF ACCOUNT', 'TRUST LEDGER' ]
  },
  'participant.fla.tab.label': {
    'AB': 'Dower Status',
    'SK': 'Homestead Status',
    'MB': 'Homestead Status',
    'NB': 'Marital Status',
    'NS': 'Spousal Status',
    'ON': 'Family Law Act'
  },
  'participant.fla.indicator.label': {
    'AB': 'Dower',
    'NS': 'Spousal Status',
    'SK': 'Homestead',
    'NB': 'Marital Status',
    'MB': 'Homestead',
    'ON': 'FLA'
  },
  'matter.undertaking.instrumentType': {
    'DEFAULT': 'Type of Instrument',
    'SK': 'Type of Interest',
    'BC': 'Nature of Charge'
  },
  'matter.undertaking.instrumentNo': {
    'DEFAULT': 'Instrument Number',
    'SK': 'Interest Register Number',
    'BC': 'Registration Number'
  },
  'matter.undertaking.dateOfInstrument': {
    'DEFAULT': 'Date of Instrument',
    'SK': 'Date of Interest'
  },
  'matter-undertaking-fromLabel-P': {
    'DEFAULT': 'From Req\'n',
    'BC': 'From Title'
  },
  'matter-undertaking-fromLabel': {
    'DEFAULT': 'From',
    'AB': 'From Mtg',
    'SK': 'From Mtg',
    'MB': 'From Mtg',
    'NS': 'From Mtg',
    'NB': 'From Mtg'
  },
  'matter-undertaking-fromContent': {
    'DEFAULT': 'From Mtg',
    'BC': 'From  Chrg'
  },
  'matter-undertaking-fromColClass': {
    'DEFAULT': 'table-cell-2',
    'AB': 'table-cell-2AB',
    'SK': 'table-cell-2AB',
    'MB': 'table-cell-2AB'
  },
  'matter-undertaking-fromColClass-p': {
    'DEFAULT': 'table-cell-2',
    'AB': 'table-cell-2AB-p',
    'SK': 'table-cell-2AB-p',
    'MB': 'table-cell-2AB-p'
  },
  'matter-undertaking-to': {
    'DEFAULT': 'Undertaking to:',
    'AB': 'Undertaking:'
  },
  'matter-undertaking-re': {
    'DEFAULT': 'Undertaking Re:',
    'AB': 'Undertaking:'
  },
  'matter-undertaking-detail-modal-undertakingLabel': {
    'DEFAULT': 'The solicitor for the vendor undertakes:',
    'BC': 'The lawyer/notary for the seller undertakes:'
  },
  'matter-undertaking-detail-modal-undertakingDescriptionLabel-P': {
    'DEFAULT': 'The solicitor for the vendor hereby personally undertakes to:',
    'AB': 'The solicitor for the vendor hereby personally undertakes:',
    'SK': 'The solicitor for the vendor hereby personally undertakes to:',
    'MB': 'The solicitor for the vendor hereby personally undertakes to:',
    'NS': 'The solicitor for the vendor hereby personally undertakes to:',
    'BC': 'The lawyer/notary for the seller hereby personally undertakes to:'
  },
  'matter-undertaking-detail-modal-undertakingDescriptionLabel-S-i': {
    'DEFAULT': 'I hereby personally undertake to:',
    'AB': 'The solicitor for the vendor hereby personally undertakes:',
    'SK': 'The solicitor for the vendor hereby personally undertakes to:',
    'MB': 'The solicitor for the vendor hereby personally undertakes to:',
    'NS': 'The solicitor for the vendor hereby personally undertakes to:'
  },
  'matter-undertaking-detail-modal-undertakingDescriptionLabel-S-we': {
    'DEFAULT': 'We hereby personally undertake to:',
    'AB': 'The solicitor for the vendor hereby personally undertakes:',
    'SK': 'The solicitor for the vendor hereby personally undertakes to:',
    'MB': 'The solicitor for the vendor hereby personally undertakes to:',
    'NS': 'The solicitor for the vendor hereby personally undertakes to:'
  },
  'matter-undertaking-detail-modal-undertakingDescriptionLabel-M': {
    'DEFAULT': 'The solicitor for the mortgagor hereby personally undertakes to:',
    'AB': 'The solicitor for the vendor hereby personally undertakes:',
    'SK': 'The solicitor for the mortgagor hereby personally undertakes to:',
    'MB': 'The solicitor for the mortgagor hereby personally undertakes to:',
    'NS': 'The solicitor for the mortgagor hereby personally undertakes to:',
    'BC': 'The lawyer/notary for the borrower hereby personally undertakes to:'
  },
  'matter.undertaking.cct': {
    'DEFAULT': 'CCT',
    'MB': 'On Title',
    'NS': 'On Title'
  },
  'matter.undertaking.discharged': {
    'DEFAULT': 'Discharged by Instrument No',
    'NS': 'Discharged by Document No',
    'BC': 'Discharged by Registration No.'
  },
  'matter.undertaking-detail-modal.discharged': {
    'DEFAULT': 'Discharged by Instrument No.',
    'NS': 'Discharged by Document No.',
    'BC': 'Discharged by Registration No.'
  },
  'matter.undertaking.permittedRegistrations': {
    'DEFAULT': 'Permitted Registrations',
    'NS': 'Benefits & Burdens'
  },
  'matter.undertaking.numberOfPermittedRegistrations': {
    'DEFAULT': 'Permitted Reg\'ns',
    'NS': 'Benefits & Burdens'
  },
  'matter.undertaking-details-modal.description.fieldKey': {
    'DEFAULT': '',
    'AB': 'matter.undertaking-details-modal.description'
  },
  'matter.soa.tenancy-current.modal.adjustFor': {
    'DEFAULT': 'Adjust for',
    'AB': 'Adjust for GST',
    'SK': 'Adjust for GST',
    'MB': 'Adjust for GST'
  },
  'matter.soa.other-fixed.modal.adjustFor': {
    'DEFAULT': 'Adjust for',
    'AB': 'Adjust for GST',
    'SK': 'Adjust for GST',
    'MB': 'Adjust for GST',
    'BC': 'Adjust for GST'
  },
  'matter.soa.water.modal.adjustFor': {
    'DEFAULT': 'Adjust for',
    'SK': 'Adjust for GST',
    'MB': 'Adjust for GST'
  },
  'matter.soa.tenancy-current.modal.percentage': {
    'DEFAULT': 'percentage is',
    'AB': 'GST percentage is'
  },
  'matter.title.vendor': {
    'DEFAULT': 'Vendor',
    'BC': 'Seller'
  },
  'matter.title.purchaser': {
    'DEFAULT': 'Purchaser',
    'BC': 'Buyer'
  },
  'matter.title.vendorsAndSolicitors': {
    'DEFAULT': 'Vendors & Solicitors',
    'BC': 'Sellers & Lawyer/Notaries'
  },
  'matter.title.purchasersAndSolicitors': {
    'DEFAULT': 'Purchasers & Solicitors',
    'BC': 'Buyers & Lawyer/Notary'
  },
  'matter.title.mortgage.otherSolicitors': {
    'DEFAULT': 'Other Solicitor',
    'BC': 'Other Lawyer/Notary'
  },
  'matter.title.solicitor': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },
  'matter.title.solicitor.Lawyer': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer'
  },
  'matter.title.solicitor.Notary': {
    'DEFAULT': 'Solicitor',
    'BC': 'Notary'
  },
  'matter.title.solicitorName': {
    'DEFAULT': 'Solicitor\'s Name',
    'BC': 'Lawyer/Notary\'s Name'
  },
  'matter.property.type': {
    'DEFAULT': 'Type/Remark',
    'AB': 'Type',
    'SK': 'Type',
    'MB': 'Type/Notes',
    'BC': 'Nature'
  },
  'matter.property.partiesTo': {
    'DEFAULT': 'Parties To',
    'AB': 'In Favour of',
    'SK': 'In Favour of',
    'MB': 'In Favour of',
    'BC': 'Registered Owner'
  },
  'matter.property.instrumentNo': {
    'DEFAULT': 'Instrument No.',
    'SK': 'Int. Register No.',
    'BC': 'Registration No.'
  },
  'matter.property.instruments': {
    'DEFAULT': 'Instruments',
    'SK': 'Interest Register',
    'BC': 'Charges'
  },
  'matter.property.showDeletedInstruments': {
    'DEFAULT': 'Show Deleted Instruments',
    'BC': 'Show Deleted Charges'
  },
  'matter.property.externalLabel': {
    'DEFAULT': 'SPIN',
    'SK': 'ISC'
  },
  'matter.property.reqStatus': {
    'DEFAULT': 'Req Status',
    'AB': 'Undertaking Status',
    'SK': 'Undertaking Status',
    'MB': 'Undertaking Status',
    'BC': 'Undertaking Status'
  },
  'matter.property.Jurisdiction': {
    'DEFAULT': 'Jurisdiction',
    'NS': 'Community'
  },

  'matter.property.isThisCondo': {
    'DEFAULT': 'Is this a condo?',
    'BC': 'Is this a strata?'
  },
  'matter-teranet-connect-partiesTo-fromColClass-withReq': {
    'DEFAULT': 'table-partiesTo',
    'AB': 'table-partiesTo-AB-withReq',
    'SK': 'table-partiesTo-SK-withReq',
    'MB': 'table-partiesTo-MB-withReq'
  },
  'matter-teranet-connect-partiesTo-fromColClass-withoutReq': {
    'DEFAULT': 'table-partiesTo',
    'AB': 'table-partiesTo-AB',
    'MB': 'table-partiesTo-MB',
    'SK': 'table-partiesTo-SK'
  },
  'matter-teranet-connect-instrumentNo': {
    'DEFAULT': 'table-instrumentNo',
    'AB': 'table-instrumentNo-AB',
    'SK': 'table-instrumentNo-SK',
    'MB': 'table-instrumentNo-MB'
  },
  'matter-teranet-connect-type': {
    'DEFAULT': 'table-type',
    'AB': 'table-type-AB',
    'MB': 'table-type-MB'
  },
  'matter-teranet-connect-requisitionStatus': {
    'DEFAULT': 'table-requisitionStatus',
    'AB': 'table-requisitionStatus-AB',
    'SK': 'table-requisitionStatus-SK',
    'MB': 'table-requisitionStatus-MB'
  },
  'matter-teranet-connect-main-spin-lab': {
    'DEFAULT': null,
    'AB': 'spin'
  },
  'matter-teranet-connect-capacity-style': {
    'DEFAULT': 'table-capacity',
    'AB': 'table-capacity-AB',
    'SK': 'table-capacity-AB',
    'MB': 'table-capacity-AB',
    'BC': 'table-capacity-AB'
  },
  'matter.teranet.connect.capacity': {
    'DEFAULT': 'Capacity',
    'AB': 'Tenure',
    'SK': 'Tenure',
    'MB': 'Tenure',
    'BC': 'Tenancy'
  },
  'teranet.import.data.modal.head.label': {
    'DEFAULT': 'Teranet Connect Import Data',
    'AB': 'SPIN Import Data',
    'MB': 'TPR Import Data',
    'SK': 'ISC Import Data',
    'BC': 'Import Property Data'
  },
  'teranet.import.data.modal.title.label': {
    'DEFAULT': 'Property Information in Teranet Parcel Register',
    'AB': 'Property Information in SPIN Title Certificate',
    'MB': 'Property Information in TPR Title Details',
    'SK': 'Property Information in ISC Title Details',
    'BC': 'Property Information from Title Search'
  },
  'teranet.import.data.modal.existing.property.label': {
    'DEFAULT': 'Subject Property Data from \'Property\' tab',
    'BC': 'Existing Property Data from \'Property\' tab'
  },
  'teranet.import.data.modal.property.address.label': {
    'DEFAULT': 'Street Address',
    'BC': 'Property Address'
  },
  'teranet.import.data.modal.description.label': {
    'DEFAULT': 'Parcel Register Legal Description',
    'AB': 'SPIN Legal Description',
    'MB': 'TPR Legal Description',
    'SK': 'ISC Legal Description'
  },
  'teranet.import.data.modal.interestEstate.label': {
    'DEFAULT': 'Ownership interest',
    'AB': ''
  },
  'teranet.import.data.modal.registryOffice.label': {
    'DEFAULT': 'Registry Office',
    'AB': 'LT Office Location',
    'MB': 'Land Titles Office'
  },
  'teranet.import.data.modal.lastInstrumentNo.label': {
    'DEFAULT': 'Last Instrument No.',
    'AB': 'Last Encumbrance No.'
  },
  'teranet.import.data.modal.titleNo.label': {
    'AB': 'Vendor\'s Title No.'
  },
  'teranet.subjectProerty.thumbnailDescription': {
    'DEFAULT': 'Thumbnail description from Teranet Connect',
    'BC': 'Legal description from LTSA',
    'AB': 'Legal description from SPIN'
  },
  'teranet.owner.import.modal.head.label': {
    'DEFAULT': 'Teranet Connect - Import Owners Data',
    'AB': 'SPIN - Import Owners Data',
    'SK': 'ISC - Import Owners Data',
    'MB': 'TPR - Import Owners Data',
    'BC': 'Import Owners Data'
  },
  'teranet.owner.contact.modal.pre.notification': {
    'DEFAULT': 'Owner from Teranet Connect: ',
    'AB': 'Owner from SPIN: ',
    'SK': 'Owner from ISC: ',
    'MB': 'Owner from TPR: ',
    'BC': 'Owner from myLTSA: '
  },
  'teranet.owner.import.modal.title.label': {
    'DEFAULT': 'Owners in Teranet Parcel Register',
    'AB': 'Owners in SPIN Parcel Register',
    'SK': 'Owners in ISC Parcel Register',
    'MB': 'Owners in TPR Title Details',
    'BC': 'Owners From myLTSA Title Search'
  },
  'matter-forms-col1': {
    'DEFAULT': 'col1',
    'AB': 'col1-1'
  },
  'matter.tabB.jurisdiction.placeHolderMsg': {
    'DEFAULT': 'Optional - Select a jurisdiction to automatically populate the "City of" and "Municipality of" fields.',
    'AB': 'Optional - Select a jurisdiction to automatically populate the "City of" field.'
  },
  'integrations.viewDealSummary.closingDate.DEFAULT': {
    'DEFAULT': 'Closing Date',
    'MB': 'Closing Date'
  },
  'integrations.viewDealSummary.closingDate.PURCHASE': {
    'DEFAULT': 'Closing Date',
    'MB': 'Possession Date'
  },
  'integrations.viewDealSummary.closingDate.MORTGAGE': {
    'DEFAULT': 'Closing Date',
    'MB': 'Closing Date'
  },
  'integrations.lenderDealData.closingDate.PURCHASE': {
    'DEFAULT': 'Closing Date',
    'MB': 'Possession Date'
  },
  'integrations.lenderDealData.closingDate.MORTGAGE': {
    'DEFAULT': 'Closing Date',
    'MB': 'Closing Date'
  },
  'matter.mortgage.loanSummary.closingDate.PURCHASE': {
    'DEFAULT': 'Closing Date',
    'MB': 'Possession Date'
  },
  'matter.mortgage.loanSummary.closingDate.MORTGAGE': {
    'DEFAULT': 'Closing Date',
    'MB': 'Closing Date'
  },
  'matter.mortgage.loanType': {
    'DEFAULT': 'Is Mortgage - Arranged (& we act for Mortgagee) / Back to Vendor ?',
    'AB': 'Is Mortgage - New (& we act for Mortgagee) / Back to Vendor ?'
  },

  'matter.mortgage.lienHoldback': {
    'DEFAULT': 'Builders\' Lien Holdback?',
    'ON': 'Construction Lien Holdback?',
    'NB': 'Mechanics\' Lien?'
  },

  'matter.mortgage.advanceModal.lienHoldback': {
    'DEFAULT': 'Builders\' Lien Holdback',
    'ON': 'Construction Lien Holdback',
    'NB': 'Mechanics\' Lien'
  },

  'matter.mortgages.addNewMortgage': {
    'DEFAULT': 'Add New Mortgage',
    'BC': 'Add New Mortgage'
  },

  'matter.mortgages.addNewCharge': {
    'DEFAULT': 'Add Existing Mortgage',
    'BC': 'Add New Charge'
  },

  'matter.mortgage.mortgagee': {
    'DEFAULT': 'Mortgagee(s)',
    'BC': 'Lender(s)'
  },

  'matter.mortgage.lendersReLine': {
    'DEFAULT': 'Lender\'s RE: Line',
    'BC': 'RE: Line'
  },

  'matter.mortgage.solicitorsInformation': {
    'DEFAULT': 'Solicitor\'s Information',
    'BC': 'Lawyer/Notary Information'
  },

  'matter.mortgage.solicitor.solicitorsName': {
    'DEFAULT': 'Solicitor\'s Name',
    'BC': 'Lawyer/Notary Name'
  },

  'matter.mortgage.solicitor.lawFirmName': {
    'DEFAULT': 'Law Firm Name',
    'BC': 'Lawyer/Notary Firm Name'
  },

  'project.projectOpening.lawClerkName': {
    'DEFAULT': 'Law Clerk Name',
    'AB': 'Legal Assistant Name',
    'BC': 'Conveyancer Name'
  },

  'requested.staff.solicitor': {
    'DEFAULT': 'Requested ' + SolicitorTitle.Solicitor,
    'BC': 'Requested ' + LawyerTitle.Lawyer_Notary
  },

  'otherParty.staff.solicitor': {
    'DEFAULT': 'Other Side ' + SolicitorTitle.Solicitor,
    'BC': 'Other Side ' + LawyerTitle.Lawyer_Notary
  },

  'otherParty.staff.solicitorTitle': {
    'DEFAULT': 'Other Solicitor',
    'BC': 'Other Lawyer/Notary'
  },

  'matter.otherParty.solicitorTitle.M': {
    'DEFAULT': 'Solicitor Details',
    'BC': 'Lawyer/Notary Details'
  },

  'contact.jurisdiction.general.municipality': {
    'DEFAULT': '"Municipality"',
    'NS': '"County"'
  },

  'contact.jurisdiction.general.jurisdiction': {
    'DEFAULT': '"Jurisdiction*"',
    'NS': '"Community*"'
  },

  'contact.list.header.jurisdiction': {
    'DEFAULT': '"Jurisdiction"',
    'NS': '"Community"'
  },

  'admin.statement.account.config.ELRSAFeesType': {
    'DEFAULT': 'Electronic Title Registration Fee',
    'ON': 'Title Registration and E-Reg Service Charge (ELRSA)',
    'AB': 'Title Registration Fee',
    'NB': 'Title Registration Fee',
    'NS': 'Title Registration Fee'
  },

  'matter.advanceHoldback.subCompletionDate': {
    'DEFAULT': 'Substantial Completion Date',
    'ON': 'Substantial Performance Date'
  },

  'matter.advanceHoldback.buildersLawyer': {
    'DEFAULT': 'Will Builders\' lawyer be responsible for holdback?',
    'NB': 'Will Mechanics\' lawyer be responsible for holdback?',
    'BC': 'Will Builders\' lawyer/notary be responsible for holdback?'
  },

  'admin.statement.account.config.landTransferTax': {
    'DEFAULT': 'Insert Land Transfer Tax',
    'ON': 'Insert Land Transfer Tax:',
    'NS': 'Insert Deed Transfer Tax:'
  },

  'admin.trust.ledger.config.landTransferTax': {
    'DEFAULT': 'Insert Land Transfer Tax',
    'ON': 'Insert Land Transfer Tax:',
    'NS': 'Insert Deed Transfer Tax:'
  },

  'admin.soa.general.config.insertTitleRegistrationFee': {
    'DEFAULT': 'Insert Title Registration Fee',
    'NB': 'Insert Title and Mortgage Registration Fees',
    'ON': 'Insert Title and Mortgage Registration Fees'
  },

  'jurisdiction.department.lro.name': {
    'DEFAULT': jurisdictionDepartmentName.LAND_REGISTRY_OFFICE,
    'AB': jurisdictionDepartmentName.LAND_TITLES_OFFICE,
    'NS': jurisdictionDepartmentName.LAND_REGISTRATION_OFFICE
  },

  'directionRefundPreviewText': {
    'DEFAULT': 'Direction re Funds Preview',
    'AB': 'Direction to Pay Preview',
    'MB': 'Direction to Pay Preview',
    'SK': 'Direction to Pay Preview'
  },

  'responsible.staff.lawClerk': {
    'ON': 'Responsible ' + LawClerkTitle.Law_Clerk,
    'NB': 'Responsible ' + LawClerkTitle.Law_Clerk,
    'NS': 'Responsible ' + LawClerkTitle.Paralegal,
    'AB': 'Responsible ' + LawClerkTitle.Legal_Assistant,
    'MB': 'Responsible ' + LawClerkTitle.Legal_Assistant,
    'SK': 'Responsible ' + LawClerkTitle.Legal_Assistant,
    'BC': 'Responsible ' + LawClerkTitle.Assistant
  },

  'staff.lawClerks.label': {
    'DEFAULT': LawClerkPluralTitle.Law_Clerk,
    'ON': LawClerkPluralTitle.Law_Clerk,
    'BC': LawClerkPluralTitle.Assistant
  },

  'staff.solicitors.label': {
    'DEFAULT': SolicitorPluralTitle.Lawyer,
    'ON': SolicitorPluralTitle.Lawyer,
    'BC': SolicitorPluralTitle.Lawyer_Notary
  },

  'responsible.staff.solicitor': {
    'DEFAULT': 'Responsible ' + SolicitorTitle.Solicitor,
    'BC': 'Responsible ' + LawyerTitle.Lawyer_Notary
  },

  'assystRequiredRealEstateMessage': {
    'DEFAULT': 'Assyst Real Estate® credentials must be associated to a Unity user profile (lawyer) to use this service.',
    'BC': 'Assyst Real Estate® credentials must be associated to a Unity user profile (lawyer/notary) to use this service.'
  },

  'assystRequiredRealEstateMessageLC': {
    'DEFAULT': 'Unity® Lender Centre credentials must be associated to a Unity user profile (lawyer) to use this service.',
    'BC': 'Unity® Lender Centre credentials must be associated to a Unity user profile (lawyer/notary) to use this service.'
  },

  'fctRequiredMessage': {
    'DEFAULT': 'FCT credentials must be associated to a Unity user profile (lawyer) to use this service.',
    'BC': 'FCT credentials must be associated to a Unity user profile (lawyer/notary) to use this service.'
  },

  'noNewMortgageMessage': {
    'DEFAULT': 'There are no new mortgage instructions available for the selected lawyer(s).',
    'BC': 'There are no new mortgage instructions available for the selected lawyer(s)/notary(ies)'
  },

  'matter.corp.property.cap.name': {
    'DEFAULT': 'Condo',
    'BC': 'Strata'
  },

  'matter.corp.property.lc.name': {
    'DEFAULT': 'condo',
    'BC': 'strata'
  },

  'matter.company.name.label': {
    'DEFAULT': 'Company Name',
    'BC': 'Corporation Name'
  },

  'matter.holdback.modal.header': {
    'DEFAULT': 'Other Holdback',
    'BC': 'Add Holdback'
  },
  'contact.firm.solicitorName': {
    'DEFAULT': 'Solicitor Name',
    'BC': 'Lawyer/Notary Name'
  },

  'contact.firm.solicitorsName': {
    'DEFAULT': 'Solicitor\'s',
    'BC': 'Lawyer/Notary'
  },

  'contact.firm.LawFirmsName': {
    'DEFAULT': 'Firm Name\'s',
    'BC': 'Law/Notary Firm'
  },
  'contact.firm.condominiumCorporation': {
    'DEFAULT': 'Condominium Corporation',
    'BC': 'Strata Corporation'
  },

  'contact.mortgagee.placeholder': {
    'DEFAULT': 'Start typing to search for desired Mortgagee Name or Mortgagee Address...',
    'BC': 'Start typing to search for desired Lender Name or Lender Address...'
  },

  'matter.deposit.creditPurchaser': {
    'DEFAULT': 'Credit Purchaser',
    'BC': 'Credit Buyer'
  },

  'contact.newManaged.lawFirmName': {
    'DEFAULT': 'Law Firm Name *',
    'BC': 'Law/Notary Firm Name *'
  },
  'law-firm.snapshot.lawFirmName': {
    'DEFAULT': 'Law Firm Name*',
    'BC': 'Law/Notary Firm Name*'
  },
  'law-firm.snapshot.barrister_solicitor': {
    'DEFAULT': 'Barrister & Solicitor',
    'BC': 'Type'
  },
  'contact.newManaged.barrister': {
    'DEFAULT': 'Barrister & Solicitor',
    'BC': 'Type'
  },
  'contact.addNew.link': {
    'DEFAULT': 'Add Solicitor',
    'BC': 'Add Lawyer/Notary'
  },
  'matter.trustLedger.modal.header': {
    'DEFAULT': 'Trust Ledger',
    'BC': 'Order to Pay or Trust Reconciliation'
  },
  'matter.trustLedger.table.column.headers.Expenditures': {
    'DEFAULT': 'Expenditures',
    'BC': 'Debits'
  },
  'matter.trustLedger.itemize.title.Expenditure': {
    'DEFAULT': 'Expenditure',
    'BC': 'Debit'
  },
  'matter.trustLedger.table.column.headers.receipts': {
    'DEFAULT': 'Receipts',
    'BC': 'Credits'
  },
  'matter.trustLedger.itemize.title.Receipt': {
    'DEFAULT': 'Receipt',
    'BC': 'Credit'
  },
  'jurisdiction.title': {
    'DEFAULT': 'Add new Jurisdiction',
    'NS': 'Add new Community'
  },
  'matter.overview.occupancyDate': {
    'DEFAULT': 'Occupancy Date',
    'BC': 'Possession Date'
  },
  'matter.closeDate': {
    'DEFAULT': 'Closing Date',
    'BC': 'Completion Date'
  },
  'matter.overview.lawclerkTitle': {
    'DEFAULT': ' Law Clerk',
    'BC': ' Assistant',
    'AB': ' Legal Assistant',
    'MB': ' Legal Assistant',
    'SK': ' Legal Assistant'
  },

  'matter.tax.label': {
    'DEFAULT': '',
    'BC': 'Taxable '
  },

  'matter.noTax.label': {
    'DEFAULT': '',
    'BC': 'Non-Taxable '
  },

  'matter.broker.commission.inTrustLedger': {
    'DEFAULT': 'in trust ledger',
    'BC': 'in Order to Pay'
  },

  'matter.broker.commission.purchaserBroker': {
    'DEFAULT': 'Purchaser Broker',
    'BC': 'Selling Broker'
  },

  'matter.broker.commission.vendorsBrokerCommission': {
    'DEFAULT': 'Vendors\' Broker Commission',
    'BC': 'Listing Commission'
  },

  'matter.broker.commission.purchasersBrokerCommission': {
    'DEFAULT': 'Purchaser\'s broker commission',
    'BC': 'Selling Commission'
  },

  'matter.broker.commission.ExcessDepositRefundableToVendor': {
    'DEFAULT': 'Excess deposit refundable to Vendor',
    'BC': 'Excess Deposit Amount'
  },

  'matter.broker.commission.CommissionPayableToVendorsBroker': {
    'DEFAULT': 'Commission payable to Vendor\'s broker',
    'BC': 'Commission payable to Listing Broker'
  },

  'matter.broker.commission.CommissionPayableToPurchasersBroker': {
    'DEFAULT': 'Commission payable to Purchaser\'s broker',
    'BC': 'Commission payable to Selling Broker'
  },
  'matter.opening.solicitor.inactive': {
    'DEFAULT': 'Solicitor on the matter is inactive.',
    'BC': 'Lawyer/Notary on the matter is inactive.'
  },

  'matter.print.overview.Occupancy.Date': {
    'DEFAULT': 'Occupancy Date',
    'BC': 'Possession Date'
  },

  'matter.print.overview.Closing.Date': {
    'DEFAULT': 'Closing Date',
    'BC': 'Completion Date'
  },

  'matter.print.overview.Solicitor': {
    'DEFAULT': 'Solicitor',
    'BC': 'Lawyer/Notary'
  },

  'matter.notes.print.overview.Lawyer': {
    'DEFAULT': 'Lawyer',
    'BC': 'Lawyer/Notary'
  },

  'matter.notes.print.overview.Law.Clerk': {
    'DEFAULT': 'Law Clerk',
    'BC': 'Assistant'
  },

  'matter.notes.print.overview.Closing.Date': {
    'DEFAULT': 'Closing Date',
    'BC': 'Completion Date'
  },

  'matter.overview.mortgagor.label': {
    'DEFAULT': 'Mortgagor',
    'BC': 'Borrower'
  },

  'matter.title.mortgagor': {
    'DEFAULT': 'Mortgagor',
    'BC': 'Borrower'
  },
  'matter.fire-insurance.information.label': {
    'DEFAULT': 'Fire Insurance Information',
    'BC': 'Home Insurance Information'
  },
  'matter.fire-insurance.mortgageesInterest.label': {
    'DEFAULT': 'Have the interest of all the mortgagees been noted on the policy?',
    'BC': 'Have the interest of all the lenders been noted on the policy?'
  },
  'matter.mortgage.tab.label': {
    'DEFAULT': 'Mortgagee',
    'BC': 'Lender'
  },
  'matter.mortgage.mortgageDetail.typeofMortgagee.label': {
    'DEFAULT': 'Type of Mortgagee',
    'BC': 'Type of Lender'
  },
  'matter.mortgageDetail.mortgagee.label': {
    'DEFAULT': 'Mortgagee',
    'BC': 'Lender'
  },
  'matter.mortgageDetail.loanNo.label': {
    'DEFAULT': 'Mortgage Loan No.',
    'BC': 'Reference No.'
  },
  'matter.mortgageDetail.mortgagees.label': {
    'DEFAULT': 'Mortgagee',
    'BC': 'Lender'
  },
  'existing.mortgage.collateral-mortgage': {
    'DEFAULT': 'Collateral mortgage?',
    'BC': 'Collateral Mortgage?'
  },
  'mortgage.contact.mortgagee.name': {
    'DEFAULT': 'Mortgagee Name',
    'BC': 'Lender Name'
  },
  'matter.mortgage.term.maturityDate': {
    'DEFAULT': 'Maturity Date',
    'BC': 'Last Payment Date'
  },
  'matter.mortgage.term.regularPayment': {
    'DEFAULT': 'Regular Payments',
    'BC': 'Amount of Each Periodic Payment'
  },
  'admin.documentProfile.misc.trustLedger': {
    'DEFAULT': 'Number of characters per line in: Trust Ledger',
    'BC': 'Number of characters per line in: Trust Reconciliation'
  },
  'matter.adjustment-type.strata-maintenance-fee.maintenance-amount': {
    'DEFAULT': 'Amount of Strata Maintenance Period',
    'BC': 'Strata Maintenance Fees'
  },
  'assyst.landTitleOffice': {
    'DEFAULT': 'LRO Location',
    'BC': 'Land Title District'
  }

};

export const provinceBasedCategoryNamesMapping: any = {
  'DIRECTION RE FUNDS': {
    'ON': 'DIRECTION RE FUNDS',
    'AB': 'DIRECTION TO PAY',
    'SK': 'DIRECTION TO PAY',
    'MB': 'DIRECTION TO PAY'
  }
};

