import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  CalculationModeType,
  DEFAULT_INTEREST_PERCENTAGE,
  SoAdjInterimOccupancyFee
} from '../../model/so-adj-interim-occupancy-fee';
import {ModalResult} from '../../../../shared-main/enums';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import Utils from '../../../../shared-main/utils';

import {Project} from '../../../../projects/shared/project';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {OccupancyFeesCalculatedBasedOn} from '../../../../projects/project-condo/project-condo';
import {Matter} from '../../../shared';
import moment from 'moment';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {InterimOccupancyFeeCalculationForType} from '../../model/so-adj-interim-fee';
import {SoAdjInterimEarlyPossessionFee} from '../../model/so-adj-interim-early-possession-fee';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {AdjustmentBaseModalComponent} from '../adjustment-base.modal.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {SelectItem} from 'primeng/api';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class InterimOccupancyFeeModalContext {
  closingDate: string;
  adjustmentKey: string;
  existingInterimFee: SoAdjInterimOccupancyFee | SoAdjInterimEarlyPossessionFee;
  project: Project;
  matter: Matter;
  soaConsiderationTaxes: ConsiderationTaxes;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-interim-occupancy-fee-modal-content',
  templateUrl: 'interim-occupancy-fee.modal.component.html',
  styleUrls: ['./interim-occupancy-fee.modal.component.scss'],
  providers: [ErrorService, DialogService]
})
export class InterimOccupancyFeeModalComponent
  extends AdjustmentBaseModalComponent<
    SoAdjInterimOccupancyFee | SoAdjInterimEarlyPossessionFee,
    InterimOccupancyFeeModalContext
  >
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  context: InterimOccupancyFeeModalContext;
  closingDateLabel: string = '';
  postDatedChequesToCommenceTypes: SelectItem[] = [];
  calculateForTypes: SelectItem[] = [];
  mortgageInterestCalculationModeTypes: SelectItem[] = [];
  commonExpenseCalculationModeTypes: SelectItem[] = [];
  taxesCalculationModeTypes: SelectItem[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<InterimOccupancyFeeModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: InterimOccupancyFeeModalContext
  ) {
    super(dialog, dialogService, context);
    this.statementAdjustmentDisplayUtil = this.context.statementAdjustmentDisplayUtil;
  }

  ok(): void {
    this.dialog.close(this.localAdjustment);
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  showFieldsForPOTLAndNonCondo(): boolean {
    return this.context.matter.isMatterProvinceON && !this.context.project.isCondo && this.context.project.isPOTL;
  }

  delete(): void {
    if (
      this.isInterimEarlyPossessionFee() &&
      this.context.existingInterimFee &&
      !this.context.matter.isInterimEarlyPossessionFeeRemovable()
    ) {
      this.dialogService
        .confirm(
          'Information',
          'In order to delete the "Early Possession Fees (Interim Closing)" adjustment, the "Deferred Portion of' +
            ' Purchase Price" adjustment must first be deleted.',
          true
        )
        .subscribe();
    } else {
      this.dialogService
        .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
        .subscribe((res) => {
          if (res) {
            this.dialog.close(ModalResult.Delete);
          }
        });
    }
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  ngOnInit(): void {
    this.buildTypes();

    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');

    if (this.isInterimOccupancyFee()) {
      if (!this.context.existingInterimFee) {
        this.newAdj = true;
        if (this.showFieldsForPOTLAndNonCondo()) {
          this.localAdjustment = SoAdjInterimOccupancyFee.getDefault(
            this.context.soaConsiderationTaxes,
            this.context.project
          );
        } else {
          this.localAdjustment = SoAdjInterimOccupancyFee.getDefault(this.context.soaConsiderationTaxes);
        }
      } else {
        this.localAdjustment = new SoAdjInterimOccupancyFee(this.context.existingInterimFee);
      }
    }

    if (this.isInterimEarlyPossessionFee()) {
      if (!this.context.existingInterimFee) {
        this.localAdjustment = SoAdjInterimEarlyPossessionFee.getDefault(this.context.soaConsiderationTaxes);
        this.newAdj = true;
      } else {
        this.localAdjustment = new SoAdjInterimEarlyPossessionFee(this.context.existingInterimFee);
      }
    }

    this.onTaxesTypeChange();
    this.onCommonExpenseTypeChange();
    this.onMortgageInterestTypeChange();
  }

  buildTypes(): void {
    /*'month after closing: <Occupancy Date + 1 month>',
    'month of closing: <Occupancy Date>',
    '2 months after closing: <Occupancy Date + 2 month>',
    'month after adjustment date: <Interim Adjust as at Date + 1 month>',
    'month of adjustment date: <Interim Adjust as at Date>',
    '2 months after adjustment date: <Interim Adjust as at Date + 2 month>'
    * */

    let occupancyDate: string = this.context.matter.matterOccupancyDate
      ? this.context.matter.matterOccupancyDate
      : this.context.closingDate;
    let adjustAsAtClosingDateInterim: string = this.context.matter.adjustAsAtClosingDateInterim
      ? this.context.matter.adjustAsAtClosingDateInterim
      : this.context.closingDate;
    this.postDatedChequesToCommenceTypes = [
      {label: `month after closing: ${this.getMonthYear(occupancyDate, 1)}`, value: 0},
      {label: `month of closing: ${this.getMonthYear(occupancyDate, 0)}`, value: 1},
      {label: `2 months after closing: ${this.getMonthYear(occupancyDate, 2)}`, value: 2},
      {label: `month after adjustment date: ${this.getMonthYear(adjustAsAtClosingDateInterim, 1)}`, value: 3},
      {label: `month of adjustment date: ${this.getMonthYear(adjustAsAtClosingDateInterim, 0)}`, value: 4},
      {label: `2 months after adjustment date: ${this.getMonthYear(adjustAsAtClosingDateInterim, 2)}`, value: 5}
    ];

    this.calculateForTypes = [
      {label: 'this month only', value: <InterimOccupancyFeeCalculationForType>'THIS_MONTH_ONLY'},
      {label: 'this month and next month', value: <InterimOccupancyFeeCalculationForType>'THIS_MONTH_AND_NEXT_MONTH'}
    ];

    let mortgageInterestPrefix: string =
      (this.context.project.projectCondo &&
        this.context.project.projectCondo.occupancyFeesCalculatedBasedOn ===
          OccupancyFeesCalculatedBasedOn.phantomMortgage) ||
      (!this.context.project.isCondo && this.context.project.isPOTL)
        ? 'Mtg '
        : '';
    this.mortgageInterestCalculationModeTypes = this.buildCalculationModeTypes(
      this.context.matter.isMatterProvinceAB ? 'Interest' : `${mortgageInterestPrefix}Interest`
    );
    this.commonExpenseCalculationModeTypes = this.buildCalculationModeTypes(
      this.context.matter.isMatterProvinceAB
        ? 'Condominium Fees'
        : this.context.matter.isMatterProvinceBC
          ? 'Strata Fees'
          : 'Common Exp'
    );
    this.taxesCalculationModeTypes = this.buildCalculationModeTypes('Taxes');
  }

  getMonthYear(date: string, addMonth: number): string {
    let newDate = moment(date, 'YYYY/MM/DD').add(addMonth, 'months');
    return moment(newDate).format('MMMM, YYYY');
  }

  buildCalculationModeTypes(prefix: string): SelectItem[] {
    return [
      {label: `${prefix} (manual)`, value: <CalculationModeType>'MANUAL'},
      {label: `${prefix} (automatic)`, value: <CalculationModeType>'AUTOMATIC'}
    ];
  }

  recalculateMortgageInterestTotal(): void {
    this.localAdjustment.recalculateMortgageInterestTotal();
  }

  recalculateCommonExpenseTotal(): void {
    this.localAdjustment.recalculateCommonExpenseTotal();
  }

  recalculateTaxesTotal(): void {
    this.localAdjustment.recalculateTaxesTotal();
  }

  getCreditVendor(): number {
    let elapsedDays: number = Number(this.context.matter.getElapsedDays(this.context.closingDate));
    let numberOfDaysInMonth: number = Number(this.context.matter.getNumberofDaysMonth(this.context.closingDate));
    return this.localAdjustment.getCreditVendor(elapsedDays, numberOfDaysInMonth);
  }

  onMortgageInterestTypeChange(): void {
    if (this.isInterimOccupancyFee()) {
      if (
        this.localAdjustment instanceof SoAdjInterimOccupancyFee &&
        this.localAdjustment.mortgageInterestType == 'AUTOMATIC'
      ) {
        this.localAdjustment.setAutomaticMortgageValues(this.context.matter, this.context.project);
      }
    }
    //this.recalculateMortgageInterestTotal();
  }

  updateInterestValueOnChange(event: string) {
    if (event == 'MANUAL') {
      this.localAdjustment.mortgageInterestPercentage = DEFAULT_INTEREST_PERCENTAGE;
    }
  }

  isMortgageInterestSetToDefault(): boolean {
    return (
      this.context.matter.isMatterProvinceAB &&
      this.localAdjustment instanceof SoAdjInterimOccupancyFee &&
      this.localAdjustment.mortgageInterestType == 'AUTOMATIC'
    );
  }

  onCommonExpenseTypeChange(): void {
    if (this.isInterimOccupancyFee()) {
      if (
        this.localAdjustment instanceof SoAdjInterimOccupancyFee &&
        this.localAdjustment.commonExpenseType == 'AUTOMATIC'
      ) {
        this.localAdjustment.setAutomaticCommonExpenseValues(this.context.matter);
      }
    }
  }

  onTaxesTypeChange(): void {
    if (this.isInterimOccupancyFee()) {
      if (this.localAdjustment instanceof SoAdjInterimOccupancyFee && this.localAdjustment.taxesType == 'AUTOMATIC') {
        this.localAdjustment.setAutomaticTaxesValues(this.context.matter, this.context.project);
      }
    }
  }

  updateTaxRate(rateType: string): void {
    if (rateType == 'HST') {
      // only in ON
      this.localAdjustment.hstRate = this.context.soaConsiderationTaxes.hstRate;
    } else if (rateType == 'GST') {
      this.localAdjustment.hstRate = Number(this.context.soaConsiderationTaxes.hstFederalPortion);
    }
  }

  isInterimOccupancyFee(): boolean {
    return this.context.adjustmentKey === StatementAdjustmentKey.INTERIM_OCCUPANCY_FEE;
  }

  isInterimEarlyPossessionFee(): boolean {
    return this.context.adjustmentKey === StatementAdjustmentKey.INTERIM_EARLY_POSSESSION_FEE;
  }
}
