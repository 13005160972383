import {Component, Input, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {DocumentProductionService} from './document-production.service';
import {Logger} from '@nsalaun/ng-logger';
import {ActivatedRoute} from '@angular/router';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {FileUploadComponent} from '../../shared/file-upload/file-upload-component';
import {HttpClient} from '../../core/httpClient.service';
import {TabsService} from '../../core/tabs.service';
import {currentMatter} from '../shared/current-matter';
import {Matter} from '../shared/matter';
import {DialogService} from '../../shared/dialog/dialog.service';
import {DocumentTemplate, DocumentTemplateUtil} from './document-template';
import {Mortgage} from '../shared/mortgage';
import {SourceTab} from '../../shared-main/constants';
import {AppConfig} from '../../shared-main/app-configuration';
import {WindowRef} from '../../shared/window.ref';
import {DocumentProductionTemplateComponent} from './document-production-template.component';
import Utils from '../../shared-main/utils';
import {Document} from './document';
import {Declaration} from '../executions-affidavits/declaration';
import {HOLDBACK_TYPE} from '../shared/advance-holdback/matter-holdback';
import {Subscription} from 'rxjs/Subscription';

declare var jQuery: any;

@Component({
  selector: 'dp-matter-document-production',
  templateUrl: 'document-production.component.html',
  styleUrls: [
    './document-production-template.component.scss'
  ],
  providers: [ DocumentProductionService ]
})

export class DocumentProductionComponent implements OnInit {
  @ViewChild(FileUploadComponent) public fileUpload: FileUploadComponent;
  @ViewChild(DocumentProductionTemplateComponent) public documentProductionTemplate: DocumentProductionTemplateComponent;
  @Input() usedInModal: boolean = false;

  rows: any[] = []; // Represents a row in a table: {template: DocumentTemplate, document: Document}
  documentTemplates: DocumentTemplate[] = [];
  matterIdSubscription: any;
  actionButtonFlag: boolean = false;
  clickListener: Function;
  categoryFilter: string = 'ALL';
  topTable: string = '180px';
  sourceTabName: SourceTab = 'DOCUMENT_PRODUCTION';
  templateDefaultDate: number = 946684800;
  documentProductionSubscription: Subscription;

  constructor(public documentProductionService: DocumentProductionService,
              public childGetsFromParent: GetGlobalSaveModelService,
              public logger: Logger,
              public dialogService: DialogService,
              public route: ActivatedRoute,
              public http: HttpClient,
              public renderer: Renderer2,
              public tabService: TabsService,
              public appConfig: AppConfig,
              public viewContainerRef: ViewContainerRef,
              public window: WindowRef
  ) {

    this.clickListener = renderer.listen('document', 'click', (event) => {
      this.resetActionButtonFlag();

    });
  }

  ngOnInit(): void {

    // This is an Observable which will notify the changes in javascript object.
    if (!this.matterIdSubscription) {
      this.matterIdSubscription = this.childGetsFromParent.getItem$
      .subscribe(() => {
        this.refreshTemplates();
      });
    }
  }

  refreshTemplates(): void {
    this.topTable = '220px';
    this.page();
  }

  public resetActionButtonFlag(): void {
    this.actionButtonFlag = false;
  }

  // This method will be called to get all document templates for the matter
  page(): void {
    this.loadDocTemplates('ALL');
  }

  loadDocTemplates = (categoryFilter) => {
    // let counter = 0;
    // this.rows = [];
    if (!categoryFilter) {
      categoryFilter = 'ALL';
    }
    if (this.currentMatter.id > 0) {
      if (this.documentProductionSubscription) {
        this.documentProductionSubscription.unsubscribe();
      }
      this.documentProductionSubscription = this.documentProductionService.getDocGenTemplates(this.currentMatter.id, categoryFilter, false, this.currentMatter.documentProfileId)
      .subscribe(data => {
        this.documentTemplates = data;
        let counter = 0;
        this.rows = [];
        data.forEach(template => {
          counter = counter + 1;

          if (DocumentTemplateUtil.isMultiPriorityTemplate(template.fileName)) {
            //If document template is a multi-priority template then it's displayed multiple times based on number of mortgages
            let multiPriorityTemplates: DocumentTemplate[] = this.replicateMultiPriorityTemplateBasedOnMortgageCount(template);
            multiPriorityTemplates.forEach(template => {
              // Creating Default Document Object for sorting
              let wordDefaultDocument = new Document({documentType: 'WORD', lastUpdatedTimeStamp: this.templateDefaultDate});
              let pdfDefaultDocument = new Document({documentType: 'PDF', lastUpdatedTimeStamp: this.templateDefaultDate});
              this.rows.push({template: template, counter: counter, word: wordDefaultDocument, pdf: pdfDefaultDocument});
            });
          } else if (DocumentTemplateUtil.isMultiExistingPriorityTemplate(template.fileName)) {
            //If document template is a multi-priority template then it's displayed multiple times based on number of existing mortgages
            let existingPriorityTemplates: DocumentTemplate[] = this.replicateMultiPriorityTemplateBasedOnExistingMortgageCount(template);
            existingPriorityTemplates.forEach(template => {
              // Creating Default Document Object for sorting
              let wordDefaultDocument = new Document({documentType: 'WORD', lastUpdatedTimeStamp: this.templateDefaultDate});
              let pdfDefaultDocument = new Document({documentType: 'PDF', lastUpdatedTimeStamp: this.templateDefaultDate});
              this.rows.push({template: template, counter: counter, word: wordDefaultDocument, pdf: pdfDefaultDocument});
            });
          } else if (DocumentTemplateUtil.isMultiAffidavitPriorityTemplate(template.fileName)) {
            // If document template is a multi-priority affidavit template then it's displayed multiple times based on number of existing
            // affidavits
            let affidavitPriorityTemplates: DocumentTemplate[] = this.replicateMultiPriorityAffidavitTemplateBasedOnAffidavitCount(template);
            affidavitPriorityTemplates.forEach((at: DocumentTemplate) => {
              // Creating Default Document Object for sorting
              let wordDefaultDocument = new Document({documentType: 'WORD', lastUpdatedTimeStamp: this.templateDefaultDate});
              let pdfDefaultDocument = new Document({documentType: 'PDF', lastUpdatedTimeStamp: this.templateDefaultDate});
              this.rows.push({template: at, counter: counter, word: wordDefaultDocument, pdf: pdfDefaultDocument});
            });
          } else if (DocumentTemplateUtil.isMultiAdvanceHoldbackTemplate(template.fileName)) {
            // If document template is a multi-priority Advance Holdback template then it's displayed multiple times based on number of
            let advanceHoldbackTemplates: DocumentTemplate[] =
              this.replicateHoldbackTemplateBasedOnHoldbackIndex(template, HOLDBACK_TYPE.advance, DocumentTemplateUtil.MULTI_ADVANCE_HOLDBACK_TEMPLATE_REGEX);
            advanceHoldbackTemplates.forEach(template => {
              // Creating Default Document Object for sorting
              let wordDefaultDocument = new Document({documentType: 'WORD', lastUpdatedTimeStamp: this.templateDefaultDate});
              let pdfDefaultDocument = new Document({documentType: 'PDF', lastUpdatedTimeStamp: this.templateDefaultDate});
              this.rows.push({template: template, counter: counter, word: wordDefaultDocument, pdf: pdfDefaultDocument});
            });
          } else if (DocumentTemplateUtil.isMultiOtherHoldbackTemplate(template.fileName)) {
            // If document template is a multi-priority Advance Holdback template then it's displayed multiple times based on number of
            let advanceHoldbackTemplates: DocumentTemplate[] =
              this.replicateHoldbackTemplateBasedOnHoldbackIndex(template, HOLDBACK_TYPE.other, DocumentTemplateUtil.MULTI_OTHER_HOLDBACK_TEMPLATE_REGEX);
            advanceHoldbackTemplates.forEach(template => {
              // Creating Default Document Object for sorting
              let wordDefaultDocument = new Document({documentType: 'WORD', lastUpdatedTimeStamp: this.templateDefaultDate});
              let pdfDefaultDocument = new Document({documentType: 'PDF', lastUpdatedTimeStamp: this.templateDefaultDate});
              this.rows.push({template: template, counter: counter, word: wordDefaultDocument, pdf: pdfDefaultDocument});
            });
          } else {
            // Creating Default Document Object for sorting
            let wordDefaultDocument = new Document({documentType: 'WORD', lastUpdatedTimeStamp: this.templateDefaultDate});
            let pdfDefaultDocument = new Document({documentType: 'PDF', lastUpdatedTimeStamp: this.templateDefaultDate});
            this.rows.push({template: template, counter: counter, word: wordDefaultDocument, pdf: pdfDefaultDocument});
          }

        });
      });
    }
  };

  get currentMatter(): Matter {
    return currentMatter.value;
  }

  public replicateMultiPriorityTemplateBasedOnMortgageCount(template: DocumentTemplate): DocumentTemplate[] {
    let multiPriorityTemplates: DocumentTemplate[] = [];
    if (this.currentMatter.mortgages && this.currentMatter.mortgages.length > 0) {
      this.currentMatter.mortgages.forEach((mortgage: Mortgage, index) => {
        let templateWithMortgageNumber: DocumentTemplate = new DocumentTemplate(template);
        multiPriorityTemplates.push(templateWithMortgageNumber);
        //Replace # in matched characters with Mortgage Number
        var match = template.fileName.match(DocumentTemplateUtil.MULTI_PRIORITY_TEMPLATE_REGEX);
        if (mortgage && mortgage.mortgagePriority != undefined && mortgage.mortgagePriority > 0) {
          index = Number(mortgage.mortgagePriority);
        }
        templateWithMortgageNumber.fileName = template.fileName.replace(match[ 0 ], match[ 1 ] + (index));
        templateWithMortgageNumber.mortgageIndex = (index);
        //Replace first # in template description with Mortgage number but in ordinal format
        if (template.description) {
          templateWithMortgageNumber.description = template.description.replace('#', Utils.getOrdinal(index));
        }
      });
    }
    return multiPriorityTemplates;
  }

  public replicateMultiPriorityTemplateBasedOnExistingMortgageCount(template: DocumentTemplate): DocumentTemplate[] {
    let multiPriorityTemplates: DocumentTemplate[] = [];
    if (this.currentMatter.existingMortgages && this.currentMatter.existingMortgages.length > 0) {
      this.currentMatter.existingMortgages.forEach((mortgage: Mortgage, index) => {
        let templateWithMortgageNumber: DocumentTemplate = new DocumentTemplate(template);
        multiPriorityTemplates.push(templateWithMortgageNumber);
        //Replace # in matched characters with Existing Mortgage Number
        var match = template.fileName.match(DocumentTemplateUtil.MULTI_EXISTING_PRIORITY_TEMPLATE_REGEX);
        templateWithMortgageNumber.fileName = template.fileName.replace(match[ 0 ], match[ 1 ] + (index + 1));
        templateWithMortgageNumber.mortgageIndex = (index);
        //Replace first # in template description with Mortgage number but in ordinal format
        if (template.description) {
          templateWithMortgageNumber.description = template.description.replace('#', Utils.getOrdinal(index + 1));
        }
      });
    }
    return multiPriorityTemplates;
  }

  public replicateMultiPriorityAffidavitTemplateBasedOnAffidavitCount(template: DocumentTemplate): DocumentTemplate[] {
    let multiPriorityAffidavitTemplates: DocumentTemplate[] = [];
    if (this.currentMatter.declarations && this.currentMatter.declarations.length > 0) {
      this.currentMatter.declarations.forEach((declaration: Declaration, index: number) => {
        let templateWithAffidavitNumber: DocumentTemplate = new DocumentTemplate(template);

        // Replace # in matched characters with Affidavit Number
        let match = template.fileName.match(DocumentTemplateUtil.MULTI_AFFIDAVIT_PRIORITY_TEMPLATE_REGEX);
        let matchJudgement = template.fileName.match(DocumentTemplateUtil.MULTI_JUDGEMENT_DECLARATION_TEMPLATE_REGEX);
        templateWithAffidavitNumber.fileName = template.fileName.replace('#', '' + (index + 1));
        templateWithAffidavitNumber.affidavitIndex = index;

        // Replace first # in template description with Mortgage number but in ordinal format
        if ((match !== null && match[ 1 ] && 'aff' === match[ 1 ].toLowerCase()) || (matchJudgement !== null && matchJudgement.length > 0)) {
          multiPriorityAffidavitTemplates.push(templateWithAffidavitNumber);
          // execution affidavit
          if (declaration.isSolicitorDeclaration()) {
            templateWithAffidavitNumber.description = 'solicitor\'s - {} execution(s)'.replace('{}', declaration.clientName);
          } else {
            templateWithAffidavitNumber.description = 'client\'s - {} execution(s)'.replace('{}', declaration.clientName);
          }
        } else if (match[ 1 ] && 'ltr' === match[ 1 ].toLowerCase()) {
          // execution affidavit letter applies only to type 'SOLICITOR'
          if (declaration.isSolicitorDeclaration()) {
            multiPriorityAffidavitTemplates.push(templateWithAffidavitNumber);
            templateWithAffidavitNumber.description = 'ltr to r.o. - {} execution(s)'.replace('{}', declaration.clientName);
          }
        }
      });
    }
    return multiPriorityAffidavitTemplates;
  }

  public replicateHoldbackTemplateBasedOnHoldbackIndex(template: DocumentTemplate, holdbackType: string, mathPhrase: RegExp): DocumentTemplate[] {
    let multiPriorityTemplates: DocumentTemplate[] = [];
    let advanceHoldbacks = this.currentMatter.holdbacks.filter(hb => hb.holdbackType == holdbackType);
    if (advanceHoldbacks && advanceHoldbacks.length) {
      advanceHoldbacks.forEach((hb, index) => {
        let templateWithMortgageNumber: DocumentTemplate = new DocumentTemplate(template);
        multiPriorityTemplates.push(templateWithMortgageNumber);
        //Replace # in matched characters with the index
        let match = template.fileName.match(mathPhrase);
        templateWithMortgageNumber.fileName = template.fileName.replace(match[ 0 ], match[ 1 ] + (index + 1));
        templateWithMortgageNumber.holdbackIndex = (index + 1);
        //Replace first # in template description with Mortgage number but in ordinal format
        if (template.description) {
          templateWithMortgageNumber.description = template.description.replace('#', Utils.getOrdinal(index + 1));
        }
      });
    }
    return multiPriorityTemplates;
  }

  ngOnDestroy(): void {
    if (this.documentProductionSubscription) {
      this.documentProductionSubscription.unsubscribe();
    }
  }
}
