import {Component, Inject} from '@angular/core';
import {ConsiderationTaxes} from '../../../consideration-ltt/consideration-taxes';
import {SalePriceAdjustment} from '../../sale-price-adjustment/sale-price-adjustment';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {HstSalePrice} from '../../model/hst-sale-price-soa';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {StatementAdjustment} from '../../statement-adjustment';
import Utils from '../../../../shared-main/utils';
import {Utils as Utils2} from '../../../shared/utils';
import {Matter} from '../../../shared/matter';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class HstSoaModalContext {
  public closingDate: string;
  public provinceHstRateSlab: ConsiderationTaxes;
  public hstSalePriceSoa: HstSalePrice;
  // public statementAdjustmentHeading : StatementOfAdjustmentHeading;
  public hstType: string;
  // public considerationLtt : ConsiderationLtt;
  public statementAdjustment: StatementAdjustment;
  public salePrice: number;
  public setCreditPurchaserOrVendor: string;
  public taxRateType: string;
  public matter: Matter;
  public sourceFromProject: boolean;
}

@Component({
  selector: 'dp-hst-sale-price-soa-modal-content',
  templateUrl: './hst-sale-price-soa.modal.component.html'
})
export class HstSalePriceSoaModal extends ModalComponent<HstSoaModalContext> {
  creditDropDown: SelectItem[];
  hstSalePriceFormats: SelectItem[];
  closingDateLabel: string = 'Closing Date';
  hstSalePriceSoaLocal: HstSalePrice;
  setCreditPurchaserOrVendor: string = '';
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<HstSalePriceSoaModal>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: HstSoaModalContext
  ) {
    super(dialog, context);
  }

  get salePrice(): number {
    return this.context.matter.considerationLtt && this.context.matter.considerationLtt.salePriceAdjustment
      ? this.context.matter.considerationLtt.creditVendorPrice(this.context.provinceHstRateSlab)
      : 0;
  }

  get salePriceAdjustment(): SalePriceAdjustment {
    return this.context.matter.considerationLtt.salePriceAdjustment;
  }

  get purchaserLabel(): string {
    return this.context.matter.statementOfAdjustmentHeading
      ? this.context.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.matter.statementOfAdjustmentHeading
      ? this.context.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  isTaxRateGst(): boolean {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.provinceDefaultTaxRate == 'GST';
  }

  isProvinceNBNS(): boolean {
    return this.context && this.context.matter && this.context.matter.isMatterProvinceNBorNS;
  }

  isProvinceON(): boolean {
    return this.context && this.context.matter && this.context.matter.isMatterProvinceON;
  }

  get appliedTaxRate(): string {
    return this.isTaxRateGst() ? 'GST' : this.context.provinceHstRateSlab.rateType;
  }

  get federalHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstFederalPortion
      ? this.context.provinceHstRateSlab.hstFederalPortion
      : 0;
  }

  get taxRate(): number {
    if (this.isProvinceON()) {
      return this.appliedRate;
    }
    return this.isProvinceNBNS() && this.context.provinceHstRateSlab
      ? this.context.provinceHstRateSlab.hstRate
      : this.hstSalePriceSoaLocal.isRateTypePST()
        ? this.provinceHstRate
        : this.federalHstRate;
  }

  calculateRebatePortion(): number {
    if (this.salePriceAdjustment) {
      if (this.hstSalePriceSoaLocal.isRateTypePST() && this.salePriceAdjustment.isNotInclusivePrice()) {
        return this.salePriceAdjustment.calculateProvincialRebatePortionSubjectToPST(this.provinceHstRate);
      } else {
        return this.salePriceAdjustment.totalRebatePortion(
          this.provinceHstRate,
          this.federalHstRate,
          this.context.salePrice
        );
      }
    }
    return 0;
  }

  get provinceHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstProvincialPortion
      ? this.context.provinceHstRateSlab.hstProvincialPortion
      : 0;
  }

  get appliedRate(): number {
    if (this.context.matter.isMatterProvinceMB || this.context.matter.isMatterProvinceSK) {
      return this.context.provinceHstRateSlab ? this.context.provinceHstRateSlab.hstFederalPortion : 0;
    } else {
      return this.context.provinceHstRateSlab ? this.context.provinceHstRateSlab.hstRate : 0;
    }
  }

  get isAdded(): boolean {
    return (
      this.context.statementAdjustment &&
      this.context.statementAdjustment.addedFlag != undefined &&
      !this.context.statementAdjustment.addedFlag
    );
  }

  get creditAmount(): number {
    if (
      this.hstSalePriceSoaLocal.isHstSalePrice() &&
      !this.isTaxRateGst() &&
      !this.context.matter.isMatterProvinceNS &&
      !this.context.matter.isMatterProvinceNB
    ) {
      return (
        this.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(
          this.federalHstRate,
          this.federalHstRate,
          this.provinceHstRate
        ) +
        this.salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(
          this.provinceHstRate,
          this.federalHstRate,
          this.provinceHstRate
        )
      );
    }
    if (this.context.matter.isMatterProvinceMB || this.context.matter.isMatterProvinceSK) {
      return this.hstSalePriceSoaLocal.isRateTypePST()
        ? Utils.roundCurrencyTCStyle((this.context.salePrice * this.provinceHstRate) / 100)
        : Utils.roundCurrencyTCStyle((this.context.salePrice * this.federalHstRate) / 100);
    } else {
      return Utils.roundCurrencyTCStyle((this.context.salePrice * (this.provinceHstRate + this.federalHstRate)) / 100);
    }
  }

  ngOnInit() {
    this.creditDropDown = [];
    this.creditDropDown.push({label: this.purchaserLabel, value: this.purchaserLabel});
    this.creditDropDown.push({label: this.vendorLabel, value: this.vendorLabel});
    if (this.context.setCreditPurchaserOrVendor != '' && this.context.setCreditPurchaserOrVendor != undefined) {
      this.setCreditPurchaserOrVendor = this.context.setCreditPurchaserOrVendor;
    }
    this.hstSalePriceFormats = dropDowns.hstSalePriceFormats;
    if (!this.context.hstSalePriceSoa) {
      this.hstSalePriceSoaLocal = new HstSalePrice(this.context.taxRateType);
      this.hstSalePriceSoaLocal.hstType = this.context.hstType;
      if (this.hstSalePriceSoaLocal.isHstSalePrice()) {
        this.hstSalePriceSoaLocal.creditType = this.vendorLabel; // Set the default to Vendor if sales price
      }
      this.newAdj = true;
    } else {
      this.hstSalePriceSoaLocal = new HstSalePrice(this.context.taxRateType, this.context.hstSalePriceSoa);
    }

    if (!this.hstSalePriceSoaLocal.creditType) {
      this.hstSalePriceSoaLocal.creditType = this.purchaserLabel; // default to left column (Purchaser)
      this.hstSalePriceSoaLocal.isVendorSelected = false; // Vendor is the right column
    } else {
      this.hstSalePriceSoaLocal.isVendorSelected = this.hstSalePriceSoaLocal.creditType == this.vendorLabel;
    }
    if (!this.hstSalePriceSoaLocal.soaFormatType) {
      this.hstSalePriceSoaLocal.soaFormatType = 'SHOW_AS_ONE_ADJUSTMENT';
    }
    if (this.context.closingDate) {
      this.closingDateLabel = Utils2.formatDate(this.context.closingDate);
    }

    if (this.setCreditPurchaserOrVendor != '' && this.setCreditPurchaserOrVendor != undefined) {
      if (this.setCreditPurchaserOrVendor === 'VENDOR') {
        this.hstSalePriceSoaLocal.creditType = this.vendorLabel;
        this.hstSalePriceSoaLocal.isVendorSelected = true;
      } else {
        this.hstSalePriceSoaLocal.creditType = this.purchaserLabel;
        this.hstSalePriceSoaLocal.isVendorSelected = false;
      }
    }
  }

  ok(): void {
    this.dialog.close({hstSalePriceSoa: this.hstSalePriceSoaLocal});
  }

  creditTypeChange(): void {
    this.hstSalePriceSoaLocal.isVendorSelected = this.hstSalePriceSoaLocal.creditType == this.vendorLabel;
  }

  close(): void {
    this.dialog.close();
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close({hstSalePriceSoa: undefined});
        }
      });
  }

  getOnSalePriceLabel(): string {
    let label: string = 'on Sale Price';
    if (this.context.matter.considerationLtt) {
      if (
        this.context.matter.considerationLtt.additionalConsideration1Exists(
          this.context.matter.statementOfAdjustments
        ) ||
        (this.context.matter.isMatterProvinceAB &&
          this.context.matter.considerationLtt.calculateSalePriceAdjustmentHeadingsCost() != 0)
      ) {
        label = 'on Total Consideration';
      }
    }
    return label;
  }

  getModalTitle(): string {
    let txt = 'Adjustment Type : ';
    if (this.hstSalePriceSoaLocal.isHstRebate()) {
      txt += this.hstSalePriceSoaLocal.rateType + ' Rebate';
      /*if(this.isTaxRateGst()){
          txt += 'GST Rebate';
      } else {
          txt += this.context.taxRateType ? this.context.taxRateType+' Rebate' : '';
      }*/
    } else {
      txt += this.hstSalePriceSoaLocal.rateType + ' ' + this.getOnSalePriceLabel();
      /*if(this.context.matter.isMatterProvinceMB || this.context.matter.isMatterProvinceSK){
          txt += 'GST ' + this.getOnSalePriceLabel();
      } else {
          txt += this.context.taxRateType ? this.context.taxRateType+' ' + this.getOnSalePriceLabel() : '';
      }*/
    }
    return txt;
  }

  get provincialLabel(): string {
    return this.context.matter.isMatterProvinceON ? 'Ontario' : 'Provincial';
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }
}
