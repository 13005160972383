import {Component, ElementRef, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {Contact} from '../../../shared/contact';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {MatterParticipantWrapper} from '../../../shared/matter-participant-wrapper';
import {DdlTypeForYesNo, Mortgage} from '../../../shared/mortgage';
import {noYesOptions} from '../dropdown-options';
import {ContactService} from '../../../../shared-main/contact.service';
import * as _ from 'lodash';
import {contactTypeMapping} from '../../../../contact/contact-type-mapping';
import {SelectItem} from 'primeng/api';
import {Address, Matter} from '../../../shared';
import {ContactChangesListener} from '../../../../contact/contact-changes-listener';
import {TabsService} from '../../../../core';

type PrivateLenderGender = 'QUESTION' | 'MALE' | 'FEMALE' | 'OTHERENTITY';

interface PrivateLenderGenderOption {
  label: string;
  value: PrivateLenderGender;
}

@Component({
  selector: 'dp-private-lender-info',
  templateUrl: 'private-lender-info.component.html',
  styleUrls: [ './private-lender-info.component.scss' ]
})
export class PrivateLenderInfoComponent {
  @Input() privateLenderWrapper: MatterParticipantWrapper;
  @Input() readonly: boolean;
  @Input() matter: Matter;
  @Input() mortgage: Mortgage;
  @Input() fieldPrefix: string; //used to prefix the field key to uniquely identify the fields in re-usable components
  @Input() fieldIndexId: string;
  @Input() fieldIdentifier: string = '';
  @Input() containerId: string;
  @Input() signingOfficerVisible: boolean;
  @Input() showMailingAddress: boolean;
  @Input() matterSpecificDataReadOnly: boolean = false;
  @Output() privateLenderInfoChanged = new EventEmitter();
  @Input() isVTBMortgagee: boolean;
  privateLenderGenderOptions: SelectItem[];
  fieldKeyGender: string;
  noYesItems: DdlTypeForYesNo[];

  constructor(public errorService: ErrorService,
              public contactService: ContactService,
              public contactChangesListener: ContactChangesListener,
              public tabsService: TabsService,
              public elementRef: ElementRef) {
  };

  ngOnInit() {
    this.noYesItems = noYesOptions;

    // if(!this.fieldIndex) {
    //     this.fieldIndex = '';
    // }
    if (this.privateLenderWrapper && this.privateLenderWrapper.matterParticipant && !this.privateLenderWrapper.matterParticipant.includeAuthorizeSignOfficer) {
      this.privateLenderWrapper.matterParticipant.includeAuthorizeSignOfficer = 'N_y';
    }
    this.fieldKeyGender = this.fieldPrefix + '.genderChangeProhibited' + '_privateLenderGender_' + this.fieldIdentifier;
    let selectedContactTypeMapping = _.find(contactTypeMapping.CONTACTTYPES, contactTypeObj => contactTypeObj.contactKey === 'CLIENT');
    this.privateLenderGenderOptions = selectedContactTypeMapping.genders;

    // Set default value for attention
    // There is not attention because it private Lender is changed to client
    // if(this.privateLender && !this.privateLender.attention){
    //     if(this.privateLender.gender != 'OTHERENTITY'){
    //         this.privateLender.attention = this.privateLender.organizationName;
    //     } else {
    //         this.privateLender.attention = this.privateLender.fullNameStartWithFirstName;
    //     }
    // }
  }

  get signingOfficerInfoVisible(): boolean {
    return this.privateLenderWrapper && this.privateLenderWrapper.matterParticipant && this.privateLenderWrapper.matterParticipant.includeAuthorizeSignOfficer === 'YES';
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeFieldIndex = changes[ 'fieldIdentifier' ];
    const changeFieldPrefix = changes[ 'fieldPrefix' ];
    if (changeFieldIndex || changeFieldPrefix) {
      this.onErrorKeyIndexChange(changeFieldPrefix && changeFieldPrefix.previousValue,
        changeFieldIndex && changeFieldIndex.previousValue);
    }
  }

  get privateLenderId(): number {
    return this.privateLender && this.privateLender.id;
  }

  onDelete() {
    if (this.fieldKeyGender) {
      this.errorService.removeDpFieldError(this.fieldKeyGender);
    }
  }

  onGenderChange(): void {
    //privateLender source contact always exists when creating snapshot
    if (this.privateLender.genderChangeValid()) {
      this.privateLender.updateGender();
      this.privateLender.rebuildContactWithGenderChange(this.privateLender.gender, this.privateLender.localGender, 'PRIVATE_LENDER');
      this.errorService.removeDpFieldError(this.fieldKeyGender);
    } else {
      this.addErrorMsg();
    }
    /* Remove Attention Defaulting on Private Lender per DPPMP-12724
    if(this.privateLender && this.privateLender.isPrivateLender) {
        this.privateLender.setAttentionValue();
    }
    */
  }

  onSurnameChange(): void {
    if (!this.privateLender.isCorporationOrOtherEntity && (!this.privateLender.lastName || (this.privateLender.lastName && this.privateLender.lastName.trim() == ''))) {
      const dpError: DPError = DPError.createDPError(this.fieldPrefix + '.surname', 'privateLenderSurname_' + this.fieldIdentifier, this.containerId, null, this.fieldIndexId + '_' + this.fieldIdentifier);
      this.errorService.addDpFieldError(dpError);
    } else if (this.privateLender.isCorporationOrOtherEntity && (!this.privateLender.organizationName || (this.privateLender.organizationName && this.privateLender.organizationName.trim() == ''))) {
      const dpError: DPError = DPError.createDPError(this.fieldPrefix + '.surname', 'privateLenderOrganization_' + this.fieldIdentifier, this.containerId, null, this.fieldIndexId + '_' + this.fieldIdentifier);
      this.errorService.addDpFieldError(dpError);
    } else {
      this.errorService.removeDpFieldError(this.fieldPrefix + '.surname' + '_privateLenderSurname_' + this.fieldIdentifier);
      this.errorService.removeDpFieldError(this.fieldPrefix + '.surname' + '_privateLenderOrganization_' + this.fieldIdentifier);
    }
    /* Remove Attention Defaulting on Private Lender per DPPMP-12724
    if(this.privateLender && this.privateLender.isPrivateLender) {
        this.privateLender.setAttentionValue();
    }
    */
  }

  public addErrorMsg(): void {
    const fieldKey: string = this.fieldPrefix + '.genderChangeProhibited';
    const fieldId = this.fieldIdentifier != null ? 'privateLenderGender_' + this.fieldIdentifier : 'privateLenderGender';
    const fieldIndexId = this.fieldIndexId ? this.fieldIndexId + '_' + this.fieldIdentifier : null;

    const dpError: DPError = DPError.createDPError(fieldKey, fieldId, this.containerId, null, fieldIndexId);
    // dpError.errorElementKey=this.fieldKeyGender;
    this.fieldKeyGender = fieldKey + '_' + fieldId;
    //dpError.errorMessage=dpError.errorMessage+" "+this.fieldIndex+".";
    this.errorService.addDpFieldError(dpError);
  }

  onErrorKeyIndexChange(prevKey: string, prevIndex: string) {
    if (prevKey || prevIndex) {
      if (!this.privateLender.genderChangeValid()) {
        // const prevFieldKeyGender : string = (prevKey || this.fieldPrefix) + '.genderChangeProhibited' + (prevIndex || this.fieldIndex);
        // this.errorService.removeDpFieldError(prevFieldKeyGender);
        this.errorService.removeDpFieldError(this.fieldKeyGender);
        // this.fieldKeyGender = this.fieldPrefix + '.genderChangeProhibited' + this.fieldIndex;
        this.addErrorMsg();
      }
    }
  }

  onNameChange(): void {
    this.onPrivateLenderInfoChanged();
    /* Remove Attention Defaulting on Private Lender per DPPMP-12724
    if(this.privateLender && this.privateLender.isPrivateLender) {
        this.privateLender.setAttentionValue();
    }
    */
    this.mortgage.undertakingDirty = true;
  }

  onPrivateLenderInfoChanged(): void {
    this.privateLender.isDirty = true;
    this.privateLenderInfoChanged.emit();
  }

  getMortgageeAddressMaxLength(): number {
    return Address.ADDRESS_LINE_MORTGAGEE_MAX_LENGTH;
  }

  get privateLender(): Contact {
    return this.privateLenderWrapper && this.privateLenderWrapper.matterParticipant && this.privateLenderWrapper.matterParticipant.contact;
  }

  get isPrivateLenderCorporationOrOther(): boolean {
    return this.privateLender.gender === 'OTHERENTITY' || this.privateLender.gender === 'CORPORATION';
  }

  // isPrivateLenderContact() : boolean {
  //     return this.privateLender && this.privateLender.isPrivateLender;
  // }

  signingOfficerChanges(): void {
    if (this.matter) {
      this.matter.dirty = true;
    }
  }

  get isSourceContactLocked(): boolean {
    return this.privateLenderWrapper
      && this.privateLenderWrapper.sourceContactIsLocked;

  }

  get isOutOfSync(): boolean {
    return this.privateLenderWrapper && !this.privateLenderWrapper.sourceContactIsLocked
      && (this.privateLenderWrapper.isStale || this.privateLenderWrapper.isClearFlagWithoutUpdatingMatter);
  }

  get isSnapshotPrivateLenderGlobalMortgage(): boolean {
    return (this.privateLenderWrapper && this.privateLenderWrapper.isPrivateLenderMortgagee
      && this.privateLenderWrapper.matterParticipant.sourceContact && this.privateLenderWrapper.matterParticipant.sourceContact.isOwnedBySystemAccount);
  }

  handleContactChange($event?: any) {
    if (this.matter && this.privateLenderWrapper && this.privateLenderWrapper.matterParticipant) {
      this.contactChangesListener.handleContactChange(this.matter, this.privateLenderWrapper.matterParticipant, this.privateLenderWrapper.matterParticipant.matterParticipantRole, this.tabsService);
    }
  }

  onCellPhoneChanged(): void {
    this.handleContactChange();
    this.onPrivateLenderInfoChanged();
  }
}
