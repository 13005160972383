import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {TeranetUser} from './teranet-user';

export class TeranetParcelRegisterPurchaseRequest extends BaseEntity {
  matterDocketId: number;
  docketSystemId: number;
  lroNumber: string;
  propertyPin: string;
  includeDeletedInstruments: boolean;
  applyAdjacentPropDiscount: boolean;
  teranetUser: TeranetUser;
  address: string;
  allowDuplicates: boolean;

  // only used when requesting a parcel register for an adjacent parcel
  parcelRegisterId: number;
}
