<ng-container *ngIf="!showWizardFields">
    <div class="row" tabindex="0">
        <div class="col-lg-12">
            <div class="form-horizontal matter-purchaser">
                <div class="reposition form-content" tabindex="0">
                    <!-- Purchaser -->
                    <div class="padding-top-20 padding-bottom-10">
                        <div class="main-level">
                            <span class="title">{{matter.mainClientHeader}}</span>
                            <ng-container *ngIf="!isMassUpdateTab()">
                                <div class="pull-right actionbtns margin-top-10" *ngIf="!matterCirf">
                                    <ng-container *ngIf="isCustomerIndividualLetterCheckboxVisible() && !isWillClientInfo">
                                        <div class="col-lg-2 padding-top-8">
                                            <dp-checkbox fieldId="sendCustomerIndividualLetter">
                                                <input type="checkbox"
                                                       id="sendCustomerIndividualLetter"
                                                       name="sendCustomerIndividualLetter"
                                                       [(ngModel)]="matter.receiveCustomerIndividualLetterFlag"
                                                       (ngModelChange)="enableSave()"
                                                />
                                            </dp-checkbox>
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="control-label">Clients to receive individual letters&nbsp;&nbsp;&nbsp;</label>
                                        </div>
                                    </ng-container>
                                    <button #btnAddNew *ngIf="!matter?.isOpportunityMatter()"
                                            (click)="initiateMatterCIRFRequest()" class="secondary-button">
                                        <span>{{cirfButtonLabel}}</span>
                                    </button>
                                </div>
                                <div class="pull-right actionbtns margin-top-10" *ngIf="matterCirf">
                                    <ng-container *ngIf="isCustomerIndividualLetterCheckboxVisible()">
                                        <dp-checkbox fieldId="sendCustomerIndividualLetter1">
                                            <input type="checkbox"
                                                   id="sendCustomerIndividualLetter1"
                                                   name="sendCustomerIndividualLetter1"
                                                   [(ngModel)]="matter.receiveCustomerIndividualLetterFlag"
                                                   (ngModelChange)="enableSave()"
                                            />
                                        </dp-checkbox>
                                        <label class="control-label padding-bottom-10">&nbsp;Clients to receive individual
                                            letters&nbsp;&nbsp;&nbsp;</label>
                                    </ng-container>
                                    <span>Client Information Request</span>
                                    <span class="boldfont"> {{matterCirf?.statusDisplay}} </span>
                                    <span>{{matterCirf?.statusDisplayPreposition}} {{matterCirf?.lastUpdatedTimeStamp | date: 'MMM d, y'}}</span>
                                    <dp-dropdown-menu class="margin-top-10"
                                                      [dropDownItems]="cirfBurgerMenuItems"
                                                      [openSubMenuOnLeft]="true"
                                                      (itemClicked)="performCirfActionDropDown($event)"
                                    ></dp-dropdown-menu>
                                </div>
                            </ng-container>
                        </div>
                        <ng-container  *ngTemplateOutlet="clientsTemplateOutlet"></ng-container>
                        <div class="clear"></div>
                        <ng-container *ngIf="!matter?.isOpportunityMatter()">
                            <ng-container *ngIf="matter && matter.isCustomMatter()">
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               *dpIfApplicableForMatter="'matter.mainClient.clientRe'">Client's RE:
                                            Line</label>
                                        <label class="control-label"
                                               *dpIfApplicableForMatter="'matter.mainClient.borrowerRe'">Borrower's RE:
                                            Line</label>
                                    </div>
                                    <div class="col-lg-10">
                                        <input type="text"
                                               name="clientRename"
                                               class="form-control"
                                               [(ngModel)]="matter.clientReLine"
                                               (change)="enableSave()"
                                               (keyup)="enableSave()"
                                               maxlength="200"
                                               trimmed>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="form-group" *dpShowByProvince="'matter.mainClient.capacity'">
                                <ng-container *ngIf="isCapacitySelectVisible && matter && !matter.isCustomMatter()">
                        <span class="col-lg-2 control-label" dp-province-field-label="matter.purchaser.capacity">
                            Capacity
                        </span>
                                    <div class="col-lg-10">
                                        <select class="form-control" id="purchasersCapacity" name="purchasersCapacity"
                                                (ngModelChange)="updatePurchasersCapacities($event)"
                                                [(ngModel)]="matter.purchasersCapacity"
                                                [fieldKey]="fieldKey"
                                                dp-error-validator (mouseenter)="focusOnCapacity();"
                                        >
                                            <option *ngFor="let option of matterPurchasersCapacityOptions"
                                                    [ngValue]="option.value">{{
                                                option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>


                        <!-- //END - Add another purchaser link -->
                    </div>

                    <ng-container *ngIf="!matter?.isOpportunityMatter()">
                        <div class="form-group" *dpShowByProvince="'matter.mainClient.execution'">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Execution</label>
                            </div>
                            <div class="col-lg-10">
                                <select class="form-control"
                                        id="execution"
                                        name="execution"
                                        [(ngModel)]="matter.matterContactInfo.identificationExecution"
                                        (change)="enableSave()"
                                >
                                    <option *ngFor="let execution of executionOptions"
                                            [ngValue]="execution.value">
                                        {{execution.label }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- //END - purchaser -->
                        <ng-container *ngIf="matter">
                            <!-- Using Purchaser data as placeholder - Please wire up to proper data -->
                            <!-- offeror -->
                            <ng-container *ngIf="!matter.isCustomMatter()">
                                <div *dpShowByProvince="'matter.mainClient.offeror'">
                                    <dp-common-offeror
                                        [matter]="matter"
                                        [errorTopic]="'<MAIN_TOPIC>'"
                                        (offerorsChanged)="enableSave()"
                                    ></dp-common-offeror>
                                </div>
                                <!-- //END - Offeror -->

                                <ng-container *dpShowByProvince="'matter.mainClient.witnessKnowsTransferor'">
                                    <div class="form-group">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">The witness knows the Transferor(s)</label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control"
                                                    id="witnessKnowsTransferor"
                                                    name="witnessKnowsTransferor"
                                                    [(ngModel)]="matter.matterContactInfo.witnessKnowsTransferors"
                                                    (change)="enableSave()"

                                            >
                                                <option *ngFor="let witnessOption of witnessKnowsTransferorOptions"
                                                        [value]="witnessOption.value">
                                                    {{witnessOption.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *dpShowByProvince="'matter.mainClient.titleDetails'">
                                    <div class="form-group">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">Title Details</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <select class="form-control"
                                                    id="titleDetails"
                                                    name="titleDetails"
                                                    [(ngModel)]="matter.matterContactInfo.titleDetailsType"
                                                    (change)="enableSave()"

                                            >
                                                <option *ngFor="let titleDetail of titleDetailTypes"
                                                        [value]="titleDetail.value">
                                                    {{titleDetail.label}}
                                                </option>
                                            </select>
                                            <div class="margin-top-5">
                                                <dp-checkbox fieldId="individualTitleDetails">
                                                    <input
                                                        id="individualTitleDetails"
                                                        name="individualTitleDetails"
                                                        type="checkbox"
                                                        #sameAsPrimaryClient="ngModel"
                                                        [(ngModel)]="matter.matterContactInfo.individualTitleDetails"
                                                        [disabled]="false"
                                                        (ngModelChange)="individualTitleDetailsChange($event)">
                                                </dp-checkbox>
                                                <label for="individualTitleDetails">Individual Title<br/> Details</label>
                                            </div>
                                        </div>
                                        <div class="col-lg-8">
                                   <textarea name="titleDetailsText" id="titleDetailsTextAuto" rows="2" cols="80"
                                             [ngModel]="getTitleDetails" [dp-read-only]="true"
                                             *ngIf="!matter.isTitleDetailsManual()"
                                   ></textarea>
                                            <textarea name="titleDetailsText" id="titleDetailsTextManual" rows="2" cols="80"
                                                      [(ngModel)]="matter.matterContactInfo.titleDetails"
                                                      (change)="enableSave()"
                                                      *ngIf="matter.isTitleDetailsManual()"
                                            ></textarea>
                                        </div>
                                    </div>

                                </ng-container>

                                <!--No matter if lost data information, limit maxlength="75" for Client's RE: Line according to BA suggestion -->
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label"
                                               *dpIfApplicableForMatter="'matter.mainClient.clientRe'">Client's
                                            RE: Line</label>
                                        <label class="control-label"
                                               *dpIfApplicableForMatter="'matter.mainClient.borrowerRe'">{{reLineTextLabel}}</label>
                                    </div>
                                    <div class="col-lg-10" *ngIf="!isWillClientInfo">
                                        <input type="text"
                                               name="clientRename"
                                               class="form-control"
                                               [(ngModel)]="matter.clientReLine"
                                               (change)="enableSave()"
                                               (keyup)="enableSave()"
                                               maxlength="200"
                                               trimmed>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="!isWillClientInfo">
                                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Phone
                                        No(s).</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="text"
                                               name="phoneNumber"
                                               class="form-control ellipsis"
                                               [ngModel]="matter.matterContactInfo && matter.matterContactInfo.phoneNumberSummary"
                                               [dp-read-only]="true">
                                    </div>

                                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Fax No.</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input type="text"
                                               name="faxNumber"
                                               class="form-control"
                                               (change)="enableSave()"
                                               (keyup)="enableSave()"
                                               [(ngModel)]="matter.matterContactInfo && matter.matterContactInfo.faxNumber"
                                               maxlength="20"
                                               [dp-read-only]="true"
                                               trimmed>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="!isWillClientInfo">
                                    <div class="col-lg-2 vertical-align-label"><label class="control-label">Email</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <input
                                            type="text"
                                            id="email"
                                            name="email"
                                            class="form-control"
                                            #email="ngModel"
                                            [(ngModel)]="matter.matterContactInfo.email"
                                            fieldKey="matter.mainClient.matterContactInfo.email"
                                            [dp-error-validator]="{isEmail : true, atLeastOneEmail : true}"
                                            maxlength="100"
                                            required
                                            trimmed
                                            dp-email-field
                                            [readonly]="sameAsPrimaryClient.value"
                                            (change)="enableSave()"
                                            (keyup)="enableSave()">
                                        <div class="margin-top-5">
                                            <dp-checkbox fieldId="sameAsPrimaryClient"
                                                         title="Check to adopt primary client's Email address">
                                                <input
                                                    id="sameAsPrimaryClient"
                                                    name="sameAsPrimaryClient"
                                                    type="checkbox"
                                                    #sameAsPrimaryClient="ngModel"
                                                    [(ngModel)]="matter.matterContactInfo.emailSameAsPrimaryContact"
                                                    [disabled]="false"
                                                    (ngModelChange)="emailChange($event)">
                                            </dp-checkbox>
                                            <label for="sameAsPrimaryClient">Same as primary client</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *dpShowByProvince="'matter.mainClient.residingAtSubjectProperty'">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" dp-province-field-label="matter.purchase.subjectPropertyResidence.purchase"
                                               *dpIfApplicableForMatter="'matter.mainClient.subjectPropertyResidence.P'">Will
                                            purchaser
                                            be residing at subject property?</label>
                                        <label class="control-label" dp-province-field-label="matter.purchase.subjectPropertyResidence"
                                               *dpIfApplicableForMatter="'matter.mainClient.subjectPropertyResidence.S'">Does
                                            Vendor
                                            currently reside at the subject property?</label>
                                        <label class="control-label"
                                               *dpIfApplicableForMatter="'matter.mainClient.subjectPropertyResidence.M'">Does
                                            {{getLabelByProvinceCode('matter.title.mortgagor')}}
                                            currently reside at the subject property?</label>
                                    </div>
                                    <div class="col-lg-1" *ngIf="!isWillClientInfo">

                                        <select class="form-control"
                                                id="residingAtSubjectProperty"
                                                name="residingAtSubjectProperty"
                                                [dp-read-only]="matter.matterContactInfo.individualTitleDetails"
                                                [(ngModel)]="matter.matterContactInfo && matter.matterContactInfo.residingAtSubjectProperty"
                                                (ngModelChange)="residingAtSubjectPropertyChange()">
                                            <option *ngFor="let residingAtSubjectProperty of residingAtSubjectPropertys"
                                                    [value]="residingAtSubjectProperty.value">
                                                {{residingAtSubjectProperty.label }}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="isWillClientInfo">
                                <div class="main-level" >
                                    <span class="title">Envelope Info</span>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label">Client's RE Line</label>
                                    </div>
                                    <div class="col-lg-10">
                                        <input type="text"
                                               name="clientRename"
                                               class="form-control"
                                               [(ngModel)]="matter.clientReLine"
                                               (change)="enableSave()"
                                               (keyup)="enableSave()"
                                               maxlength="200"
                                               trimmed>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Envelope Salutation</label></div>
                                <div class="col-lg-10">
                                    <p-autoComplete
                                        name="autocomplete-EnvelopeSalutation"
                                        [(ngModel)]="matter.matterContactInfo && matter.matterContactInfo.envelopeSalutation"
                                        tabIndexIgnore
                                        [minLength]="1"
                                        maxlength="200"
                                        [class.dropdown-arrow-refferedBy]="true"

                                        [suggestions]="contactSalutationSuggestion.getFilteredSuggestions(salEnum.salutation)"
                                        [dropdown]="contactSalutationSuggestion.getShowDropDown(salEnum.salutation)"
                                        (keydown.space)="utils.handleSpaceKeyDropdown($event, matter.matterContactInfo.envelopeSalutation, handleDropdownClickSal)"
                                        (keydown.enter)="utils.handleEnterKeyDropdown($event, matter.matterContactInfo.envelopeSalutation, handleDropdownClickSal)"
                                        (onDropdownClick)="handleDropdownClickSal($event)"
                                        (completeMethod)="contactSalutationSuggestion.filterSuggestions($event, salEnum.salutation)"

                                        (change)="enableSave()"
                                        (onSelect)="enableSave()"
                                        autocomplete="off"
                                        trimmed
                                    >
                                        <ng-template let-envelopeSalutation pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                              <span class="omnibarLi100">
                                                  {{envelopeSalutation}}
                                              </span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Envelope Salutation Cont'd
                                </label></div>
                                <div class="col-lg-10">
                                    <!--maxlength="75" It is temporarily changed to 100 for demo -->
                                    <p-autoComplete
                                        name="envelopeSalutationLine2"
                                        [(ngModel)]="matter.matterContactInfo && matter.matterContactInfo.envelopeSalutationContinued"

                                        maxlength="200"
                                        required
                                        [class.dropdown-arrow-refferedBy]="true"

                                        [suggestions]="contactSalutationSuggestion.getFilteredSuggestions(salEnum.salutationContinued)"
                                        [dropdown]="contactSalutationSuggestion.getShowDropDown(salEnum.salutationContinued)"
                                        (keydown.space)="utils.handleSpaceKeyDropdown($event, matter.matterContactInfo.envelopeSalutationContinued, handleDropdownClickSal2)"
                                        (keydown.enter)="utils.handleEnterKeyDropdown($event, matter.matterContactInfo.envelopeSalutationContinued, handleDropdownClickSal2)"
                                        (onDropdownClick)="handleDropdownClickSal2($event)"
                                        (completeMethod)="contactSalutationSuggestion.filterSuggestions($event, salEnum.salutationContinued)"

                                        (change)="enableSave()"
                                        (onSelect)="enableSave()"
                                        autocomplete="off"
                                        trimmed
                                    >
                                        <ng-template let-envelopeSalutationContinued pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                              <span class="omnibarLi100">
                                                  {{envelopeSalutationContinued}}
                                              </span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>

                            <div class="form-group" id="dear">
                                <div class="col-lg-2 vertical-align-label"><label class="control-label">Dear</label>
                                </div>
                                <div class="col-lg-10">
                                    <p-autoComplete
                                        name="autocomplete-dear"
                                        [(ngModel)]="matter.matterContactInfo && matter.matterContactInfo.dearText"
                                        tabIndexIgnore
                                        [minLength]="1"
                                        maxlength="75"
                                        [class.dropdown-arrow-refferedBy]="true"
                                        (keydown.f9)="onDearF9()"
                                        statusBarHelp
                                        [dynamicHelpCallBackFunction]="generateF9HelpForDear"
                                        [suggestions]="contactSalutationSuggestion.getFilteredSuggestions(salEnum.dear)"
                                        [dropdown]="contactSalutationSuggestion.getShowDropDown(salEnum.dear)"
                                        (keydown.space)="utils.handleSpaceKeyDropdown($event, matter.matterContactInfo.dearText, handleDropdownClickDear)"
                                        (keydown.enter)="utils.handleEnterKeyDropdown($event, matter.matterContactInfo.dearText, handleDropdownClickDear)"
                                        (onDropdownClick)="handleDropdownClickDear($event)"
                                        (completeMethod)="contactSalutationSuggestion.filterSuggestions($event, salEnum.dear)"

                                        (onSelect)="enableSave()"
                                        (change)="enableSave()"
                                        autocomplete="off"
                                        trimmed>
                                        <ng-template let-dearText pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                              <span class="omnibarLi100">
                                                  {{dearText}}
                                              </span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>

                            <ng-container *ngIf="!matter.isCustomMatter()">
                                <dp-address-form *dpIfApplicableForMatter="'matter.mainClient.preClosingAddress'"
                                                 [address]="matter.matterContactInfo.preClosingAddress"
                                                 (onChange)="preClosingAddressChanged()"
                                                 [fieldPrefix]="'matter.mainClient.matterContactInfo.preClosingAddress'"
                                                 [fieldCodeMap]="{postalCode : 'P20001'}"
                                                 [defaultForProvinceAndCountry]="!this.isMassUpdateTab()"
                                                 fieldKey="matter.mainClient.matterContactInfo.preClosingAddress"
                                                 statusBarHelp
                                                 addressSummaryLabel="Pre-Closing Address"
                                                 [primaryShutter]="true"></dp-address-form>
                            </ng-container>
                            <dp-address-form [address]="matter.matterContactInfo.postClosingAddress"
                                             *ngIf="matter.isPurchase || matter.isCustomMatter() || isWillClientInfo"
                                             [showDropdown]="!matter.isCustomMatter() && !isWillClientInfo"
                                             #postClosingAddressForPurchase
                                             [addressDd]="postClosingAddressCopyOptions"
                                             (onChange)="purchasePostClosingAddressChanged($event)"
                                             [fieldPrefix]="'matter.mainClient.matterContactInfo.postClosingAddress'"
                                             fieldKey="matter.mainClient.matterContactInfo.postClosingAddress"
                                             statusBarHelp
                                             addressSummaryLabel="{{matter.isCustomMatter() || isWillClientInfo?'Mailing Address':'Post-Closing Address'}}"
                                             [primaryShutter]="true"></dp-address-form>
                            <ng-container *ngIf="!matter.isCustomMatter()">
                                <dp-address-form [address]="matter.matterContactInfo.postClosingAddress"
                                                 *ngIf="matter.isSale"
                                                 #postClosingAddressForPurchase
                                                 (onChange)="postClosingAddressChanged()"
                                                 [fieldPrefix]="'matter.mainClient.matterContactInfo.postClosingAddress'"
                                                 fieldKey="matter.mainClient.matterContactInfo.postClosingAddress"
                                                 statusBarHelp
                                                 [addressSummaryLabel]="postClosingAddressLabel()"
                                                 [primaryShutter]="true"
                                                 [defaultForProvinceAndCountry]="!this.isMassUpdateTab()"></dp-address-form>

                                <dp-address-form [address]="matter.matterContactInfo.postClosingAddress"
                                                 *ngIf="matter.isMortgage && matter.matterContactInfo?.residingAtSubjectProperty?.startsWith('N')"
                                                 (onChange)="postClosingAddressChanged()"
                                                 [fieldPrefix]="'matter.mainClient.matterContactInfo.postClosingAddress'"
                                                 fieldKey="matter.mainClient.matterContactInfo.postClosingAddress"
                                                 statusBarHelp
                                                 addressSummaryLabel="Mailing Address"
                                                 [primaryShutter]="true"></dp-address-form>


                                <!--Excess funds direct deposit section-->

                                <div class="form-group" *ngIf="!isWillClientInfo">
                                    <div class="col-lg-2  vertical-align-label">
                                        <label class="control-label">
                                            {{matter.isPurchase ? 'Direct deposit excess' : 'Direct deposit balance of'}}
                                            <span [dp-province-field-label]="getDirectDepositLabelByMatterType()"></span> <br/>
                                            bank account</label>
                                    </div>
                                    <div class="col-lg-1">
                                        <select class="form-control"
                                                id="excessFundsDirectDepositChoice"
                                                name="excessFundsDirectDepositChoice"
                                                [(ngModel)]="matter.directDepositInstructionPurchaser && matter.directDepositInstructionPurchaser.directDepositChoice"
                                                (change)="handleDirectDepositOption($event.target.value)"
                                        >
                                            <option *ngFor="let ddOption of directDepositOptions" [value]="ddOption.value">
                                                {{ddOption.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div
                                    *ngIf="matter.directDepositInstructionPurchaser  && matter.directDepositInstructionPurchaser.directDepositChoice === 'YES'">
                                    <dp-direct-deposit-account [ddInstruction]="matter.directDepositInstructionPurchaser"
                                                               [fieldPrefix]="'matter.mainClient'"
                                                               [currectSection]="currentSectionName"
                                                               [clientOrSolicitor]="clientOrSolicitorLabel()"
                                                               [isMassUpdateTab]="isMassUpdateTab()"
                                                               (change)="enableSave()"
                                    >
                                    </dp-direct-deposit-account>
                                </div>


                                <!-- /Excess funds direct deposit section-->
                                <ng-container *ngIf="isUffiApplicable && !isWillClientInfo">
                                    <div class="form-group" *dpShowByProvince="'matter.mainClient.uffi'">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">UFFI {{matter.isMortgage ? 'Declaration' :
                                                'Warranty'}}</label>
                                        </div>
                                        <div class="col-lg-10">
                                            <select class="form-control"
                                                    id="uffiWarrantyCode"
                                                    name="uffiWarrantyCode"
                                                    [(ngModel)]="matter.uffiWarrantyCode"
                                                    (change)="uffiWarrantyCodeChanged()">
                                                <option *ngFor="let item of vendorWarrantyOptions" [value]="item.value">
                                                    {{item.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>


                                <div class="form-group" *ngIf="survivalAfterClosingVisible()">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label">Survival After Closing</label>
                                    </div>
                                    <div class="col-lg-10">
                                        <input type="text"
                                               id="SurvivalAfterClosing"
                                               name="SurvivalAfterClosing"
                                               class="form-control"
                                               [(ngModel)]="matter.survivalAfterClosing"
                                               (change)="enableSave()"
                                               maxlength="50"
                                               trimmed
                                        >
                                    </div>
                                </div>

                                <!--Developer-->
                                <div *dpShowByProvince="'matter.mainClient.developerSection'">
                                    <dp-developer [matter]="matter"></dp-developer>
                                </div>
                            </ng-container>
                        </ng-container>

                        <dp-user-defined-fields [topicName]="topicName" (onChange)="enableSave()">
                        </dp-user-defined-fields>
                    </ng-container>


                </div>


            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!!showWizardFields">
    <div class="form-horizontal">
        <div class="col-lg-12">
            <ng-container  *ngTemplateOutlet="clientsTemplateOutlet"></ng-container>
        </div>
    </div>



</ng-container>
<ng-template #clientsTemplateOutlet>
    <div id="matterPurchaserContainer" class="xpath-parent-container">
        <!--TODO: rename the purchaser to client, to generically work for all matter types-->
        <!--TODO: should confirm if this shutter-bg class is needed. It seems to be replicated in the child container.-->
        <div *ngFor="let selectedClient of selectedClientPurchasers; let i=index"
             [ngClass]="{'shutter-bg': isExpanded(selectedClient) && !isWillClientInfo, 'shutter-bg-closed': !selectedClient.editMode && !isExpanded(selectedClient)}">

            <div class="main-level" *ngIf="selectedClient.matterParticipant?.matterParticipantRole === 'TESTATOR_SPOUSE' && isWillClientInfo">
                <span class="title">Spouse</span>
            </div>

            <div class="xpath-child-container" [ngClass]="{'shutter-bg': isExpanded(selectedClient), 'shutter-bg-closed': !selectedClient.editMode && !isExpanded(selectedClient)}">
                <div class="form-group">
                    <div class="shutter-arrow preventDoubleClick"
                         (click)="toggleContactSnapshot(selectedClient)">
                        <div *ngIf="isExpanded(selectedClient)"><img src="/assets/img/minus.svg"
                                                                     alt=""/></div>
                        <div
                            *ngIf="!selectedClient.editMode && !isExpanded(selectedClient)">
                            <img
                                src="/assets/img/plus.svg"/></div>
                    </div>
                    <div *ngIf="selectedClient.showLabel || !!showWizardFields">
                                    <span class="col-lg-2 control-label preventDoubleClick"
                                          (click)="toggleContactSnapshot(selectedClient)"
                                    >
                                        {{isWillClientInfo ? willClientInfoTitle(i) : mainClientTitle(i)}}
                                    </span>
                    </div>
                    <div *ngIf="!selectedClient.showLabel" class="col-lg-2">

                    </div>
                    <div class="col-lg-10 client-purchaser fullLength-autocomplete"
                         id="clientSurname__{{i}}"
                         [class.custom]="!selectedClient.editMode">
                        <input type="text" class="form-control rightPadding482 preventDoubleClick"
                               readonly="readonly"
                               *ngIf="!selectedClient.editMode"
                               [value]="selectedClient.matterParticipant?.contact?.genericFullName"
                               (click)="toggleContactSnapshot(selectedClient)"
                               id="clientFieldIndex_{{selectedClient.matterParticipant?.matterParticipantId}}"
                               (keydown.f9)="toggleContactSnapshot(selectedClient)"
                               fieldKey="matter.mainClient.shutter"
                               statusBarHelp
                               autocomplete="off"/>
                        <p-autoComplete *ngIf="selectedClient.editMode"
                                        placeholder='Search by "Surname, First Name" or Add new'
                                        id="editablePurchaser"
                                        name="{{i}}"
                                        [(ngModel)]="selectedClient.dataModel"
                                        [class.autocomplete-searching]="clientPurchasersLoading"
                                        [suggestions]="clientPurchasers"
                                        (completeMethod)="search($event)"
                                        field="fullName"
                                        [minLength]="0"
                                        delay="1000"
                                        maxlength="75"
                                        scrollHeight="500px"
                                        (focusout)="cleanupMainClientField(selectedClient)"
                                        (onSelect)="dataSelectedClient(selectedClient.dataModel, i)"
                                        autocomplete="off" class="autocomplete-search-icon">
                            <!-- Removed Help Text function for above autoCompleted:
                            (click)="showClientPurchaserHelpText(selectPurchaser)"
                            -->

                            <ng-template let-clientPurchaser pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                    [ngClass]="{'bold-font' : isAddNew(clientPurchaser)}"
                                    [disableItem]="clientPurchaser">
                                                <span class="col-lg-4 text-overflow"
                                                      *ngIf="autoCompleteEmptyFlag == true">{{ clientPurchaser.displayName }}</span>
                                    <span class="col-lg-4 text-overflow"
                                          *ngIf="autoCompleteEmptyFlag == false">{{ clientPurchaser.displayName }}</span>
                                    <span class="col-lg-5 text-overflow"
                                          *ngIf="autoCompleteEmptyFlag == false">{{ clientPurchaser.displayAddress }}</span>
                                    <span class="col-lg-2 text-overflow"
                                          *ngIf="autoCompleteEmptyFlag == false">{{
                                        getGenderValue(clientPurchaser.gender) }}</span>
                                    <span class="col-lg-1 text-overflow"
                                          *ngIf="autoCompleteEmptyFlag == false">{{
                                        getDeceasedString(clientPurchaser) }}</span>
                                </li>
                            </ng-template>
                        </p-autoComplete>

                        <!-- Action buttons -->
                        <div class="actionbtns" *ngIf="!selectedClient.editMode">
                            <div class="width-132">
                                            <span
                                                *ngIf="!matter.isWillMatter()"
                                                class="primary radio" (click)="setAsPrimaryPurchaser(selectedClient)">
                                              <input type="radio" [checked]="selectedClient.primary">
                                              <label>Primary</label>
                                            </span>
                            </div>
                            <div class="width-75">
                                            <span
                                                *ngIf="selectedClient.matterParticipant &&
                                                    flaFlagVisible(selectedClient.matterParticipant) &&
                                                    selectedClient.matterParticipant.incompleteFamilyLawAct(matter.provinceCode)"
                                                (click)="onClickFlaWarningIcon(selectedClient)">
                                                    <i class="fa fa-warning"></i>
                                                    <span class="span-link"
                                                          dp-province-field-label="participant.fla.indicator.label"
                                                          [title]="getAllFamilyLawActAlerts(selectedClient.matterParticipant, matter)">FLA</span>
                                            </span>
                            </div>

                            <div class="width-80 margin-left-20">
                                            <span
                                                *ngIf="showCapacityWarningForPurchaser(selectedClient.matterParticipant)"
                                                (click)="onClickCapacityWarningIcon(selectedClient)">
                                                <i class="fa fa-warning"></i>
                                                <span class="span-link"
                                                      dp-province-field-label="matter.purchaser.capacity">Capacity</span>
                                            </span>
                            </div>
                            <div class="width-40">
                                            <span *ngIf="showIdWarningForPurchaser(selectedClient.matterParticipant)"
                                                  (click)="onClickIdWarningIcon(selectedClient)"
                                                  [title]="getIdAlertsMsg(selectedClient.matterParticipant)">
                                                <i class="fa fa-warning"></i>
                                                <span class="span-link">ID</span>
                                            </span>
                            </div>
                            <div class="width-40">
                                            <span *ngIf="isParticipantCirf(selectedClient.matterParticipant)"
                                                  [title]="getParticipantCirfStatusMessage(selectedClient.matterParticipant)">
                                                <i class="fa fa-info-circle"></i>
                                                <span class="span-link padding-left-3">CIRF</span>
                                            </span>
                            </div>
                            <div class="width-70 margin-left-10">
                                            <span *ngIf="selectedClient.isStale">
                                                <!--<i class="fa fa-flag" aria-hidden="true">-->
                                                    <span class="text-black out-of-sync-msg"><img
                                                        src="/assets/img/old-icon-18x18.png"/> </span>
                                                   <span class="matter-locked-message">
                                                        <div class="bubble-arrow"><img
                                                            src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                                        <span>
                                                           <u><i>Matter details out of sync with contact, refer to burger menu for options</i></u>
                                                        </span>
                                                    </span>
                                            </span>
                            </div>
                            <div class="width-30 actionbtns">
                                <dp-burger-menu
                                    *ngIf="!selectedClient.editMode && selectedClient.matterParticipant"
                                    [openSubMenuOnLeft]="true"
                                    [allowPopUp]="true"
                                    [items]="selectedClient.getBurgerMenuItemsForMatterParticipant(
                                    i,
                                    matter.mainClients.length,
                                    showAddSpouseButton(selectedClient.matterParticipant),
                                    matter?.isWillMatter())"
                                    [disabledItems]="selectedClient.getDisabledBurgerMenuItemsForMatterParticipant()"
                                    (itemClicked)="clickPurchaserBurgerMenu($event, selectedClient, i)">
                                </dp-burger-menu>
                            </div>
                        </div>
                        <!-- //END -- Action buttons -->
                    </div>
                </div>
                <!-- Add Tap  -->
                <!--Move Snapshot to the child component-->
                <!-- Use 'hidden' instead of 'ngIf', because controls for ALL purchasers must be created -->
                <!-- in order to make error-service auto-index work correctly. -->
                <div class="form-group"
                     *ngIf="(selectedClient.matterParticipant && isExpanded(selectedClient))">
                    <div id="snapshot-{{selectedClient.id}}"
                         class="padding-top-10 col-lg-10 offset-lg-2">
                        <div class="form-group-warning-message"
                             *ngIf="selectedClient.isLockedElsewhere">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The {{matter && matter.mainClientHeader ? (matter.mainClientHeader).toLowerCase() : purchaser}} is currently locked by {{selectedClient &&
                                selectedClient.lockedSourceContact &&
                                selectedClient.lockedSourceContact.lockedByUser.fullName}}.
                                </p>
                            </div>
                        </div>
                        <!--</div> {{selectPurchaser.sourceContactIsLocked}} --  {{selectPurchaser.isStale}} -- {{selectPurchaser.isClearFlagWithoutUpdatingMatter}}-->
                        <div class="form-group-warning-message"
                             *ngIf="!selectedClient.isLockedElsewhere && selectedClient.isStale">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{selectedClient.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openSourceContactTab(selectedClient)"
                                       class="line-height-adjust">
                                        {{selectedClient.outOfSyncUnderlineMsg}}</a>
                                    {{selectedClient.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <ul class="dppm-custom-tab">
                                <li [ngClass]="{active: selectedClient.selectedTab === 'Profile'}"
                                    (click)="selectTab('Profile', selectedClient)" tabindex="0"
                                    (keyDown.enter)="selectTab('Profile', selectedClient)">
                                    <a>Profile</a>
                                </li>
                                <ng-container *ngIf="matter && !matter.isCustomMatter()">
                                    <ng-container *dpShowByProvince="'matter.mainClient.capacity'">
                                        <li *ngIf="isCapacityVisible()"
                                            [ngClass]="{active: selectedClient.selectedTab === 'Capacity-Share'}"
                                            (click)="selectTab('Capacity-Share', selectedClient)"
                                            tabindex="0"
                                            (keyDown.enter)="selectTab('Capacity-Share', selectedClient)">
                                            <a dp-province-field-label="matter.purchaser.capacity">Capacity</a>
                                        </li>
                                    </ng-container>
                                    <ng-container >
                                        <li *ngIf="isFamilyLawActVisible(selectedClient.matterParticipant)"
                                            [ngClass]="{active: selectedClient.selectedTab === 'Family-Law-Act'}"
                                            (click)="selectTab('Family-Law-Act', selectedClient)"
                                            tabindex="0"
                                            (keyDown.enter)="selectTab('Family-Law-Act', selectedClient)">
                                            <a dp-province-field-label="participant.fla.tab.label">Family
                                                Law
                                                Act</a>
                                        </li>
                                    </ng-container>
                                </ng-container>
                                <li [ngClass]="{active: selectedClient.selectedTab === 'ID-Details'}"
                                    (click)="selectTab('ID-Details', selectedClient)" tabindex="0"
                                    (keyDown.enter)="selectTab('ID-Details', selectedClient)">
                                    <a>ID Verification</a>
                                </li>
                                <li
                                    *dpShowByProvince="'matter.buyers.ptt'"
                                    [ngClass]="{active: selectedClient.selectedTab === 'PTT'}"
                                    (click)="selectTab('PTT', selectedClient)" tabindex="0"
                                    (keyDown.enter)="selectTab('PTT', selectedClient)"
                                    data-testid="purchaser-pttTab-li"
                                >
                                    <a>PTT</a>
                                </li>
                                <li [ngClass]="{active: selectedClient.selectedTab === 'Notes'}"
                                    (click)="selectTab('Notes', selectedClient)" tabindex="0"
                                    (keyDown.enter)="selectTab('Notes', selectedClient)">
                                    <a>{{selectedClient.getNotesTab()}}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-12 margin-top-20">
                            <dp-log-diff-snapshot-source [wrapper]="selectedClient">
                            </dp-log-diff-snapshot-source>
                            <div [ngSwitch]="selectedClient.selectedTab">
                                <dp-snapshotProfile *ngSwitchCase="'Profile'"
                                                    [matterParticipant]="selectedClient.matterParticipant"
                                                    [index]="i"
                                                    [fieldPrefix]="purchaserFieldPrefix"
                                                    [fieldIndexId]="purchaserFieldIndexId"
                                                    [fieldIdentifier]="selectedClient.matterParticipant?.matterParticipantId"
                                                    [containerId]="purchaserContainerId"
                                                    [matter]="matter"
                                                    [purchaserExecDocsAt]="matter.purchaserExecDocsAt"
                                                    [profileEditable]="!selectedClient.isParticipantDataReadOnly"
                                                    (onDataChange)="onSnapshotChange(selectedClient)"
                                                    (onSuggestionsChange)="updateSuggestions()"
                                                    (primaryAddressChanged)="updatePreClosingFromPrimaryPurchaserPrimaryAddress($event)"
                                >
                                </dp-snapshotProfile>

                                <dp-familyLawAct *ngSwitchCase="'Family-Law-Act'"
                                                 [shouldCreateSourceContact]="selectedClient.sourceContactId"
                                                 [matter]="matter"
                                                 [participant]="selectedClient.matterParticipant"
                                                 [spouseNameOptions]="selectedClient.spouseNameOptions"
                                                 [fieldPrefix]="purchaserFieldPrefix"
                                                 [fieldIndexId]="purchaserFieldIndexId"
                                                 [fieldIdentifier]="selectedClient.matterParticipant?.matterParticipantId"
                                                 [containerId]="purchaserContainerId"
                                                 [flaStatements]="flaStatements"
                                                 [isConsentSpouseModalVisible]="isConsentSpouseModalVisible"
                                                 [flaReadOnly]="selectedClient.sourceContactIsLocked"
                                                 (saveForm)="updateFamilyLawActFormSave()">
                                </dp-familyLawAct>


                                <ng-container *dpShowByProvince="'matter.mainClient.capacity'">
                                    <dp-capacityShare *ngSwitchCase="'Capacity-Share'"
                                                      [matterParticipant]="selectedClient.matterParticipant"
                                                      [provinceCode]="matter.provinceCode"
                                                      [readOnly]="matter.purchasersCapacity != 'OTHER' || selectedClient.sourceContactIsLocked"
                                                      (updateCapacity)="enableSave()"
                                                      [isPurchaserSharePercentage]="matter.isMatterProvinceMBorSK"
                                                      [purchaserId]="selectedClient.matterParticipantId">
                                    </dp-capacityShare>
                                </ng-container>
                                <dp-snapshotIdDetails *ngSwitchCase="'ID-Details'"
                                                      [participant]="selectedClient.matterParticipant"
                                                      [client]="selectedClient.matterParticipant?.contact"
                                                      [idDetailsEditable]="!selectedClient.isParticipantDataReadOnly"
                                                      [idVerifiedEnterOnFieldsReadOnly]="selectedClient.sourceContactIsLocked"
                                                      [matter]="matter"
                                                      [isUploadDriverLicenceAllowed]="true"
                                                      (onDataChange)="onSnapshotChange(selectedClient)"

                                >
                                </dp-snapshotIdDetails>
                                <dp-ptt
                                    *ngSwitchCase="'PTT'"
                                    [matter]="matter"
                                    [matterParticipant]="selectedClient.matterParticipant"
                                    [index]="i"
                                    [profileEditable]="!selectedClient.isParticipantDataReadOnly"
                                    (enableSave)="enableSave()"
                                    (percentAcquiredChanged)="percentAcquiredChanged()"
                                >
                                </dp-ptt>
                                <!--The following is the contact notes section (with the add button and such)-->
                                <dp-contact-notes *ngSwitchCase="'Notes'"
                                                  [contact]="selectedClient.matterParticipant?.contact"
                                                  (onDataChange)="enableSave()"
                                                  [isReadOnly]="selectedClient.isParticipantDataReadOnly"
                                                  [matter]="matter"
                                >
                                </dp-contact-notes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isWillClientInfo && matter.matterParticipants.length > 0">
                <dp-client-info-remains-section
                    [index]="i"
                    [participant]="selectedClientPurchasers[i].matterParticipant"
                    [isExpanded]="isExpanded(selectedClient)"
                    (enableSave)="enableSave()"
                ></dp-client-info-remains-section>
            </ng-container>
        </div>
    </div>
    <!-- //END - Snapshot -->

    <!-- Add another purchaser link -->
    <div class="form-group">
        <div class="col-lg-10 offset-lg-2">
                            <span class="actionlink"
                                  *ngIf="shouldDisplayActionLink()">
                                  <span>
                                      <a *ngIf="!matter.isWillMatter()" href="javascript:void(0);" (click)="addNewPurchaser()">
                                          Add Another {{matter.mainClientTitle | titlecase }}
                                      </a>
                                      <a *ngIf="matter.isWillMatter()" href="javascript:void(0);" (click)="addSpouseClicked()">
                                          Add Spouse
                                      </a>
                                  </span>
                                <span class="selector fa fa-plus-square-o"></span>
                            </span>
            <ng-container *ngIf="!matter.isOpportunityMatter()">
                                <span class="actionlink margin-left-20" [class.disabled]="clients.length == 0"
                                      *dpIfApplicableForMatter="'matter.mainClient.titleDetails'">
                                <span *dpShowByProvince="'matter.mainClient.client.titleDetailsLink'">
                                    <a href="javascript: void(0)" (click)="showPurchasersTitleDetails()"
                                       *ngIf="clients.length > 0">
                                        Title Details
                                    </a>
                                    <span *ngIf="clients.length == 0">Title Details</span>
                                </span>
                            </span>
                <span class="actionlink margin-left-20" [class.disabled]="clients.length == 0" *ngIf="!matter.isWillMatter() && !appConfig.isHiddenForBCProvince">
                                <span>
                                    <a href="javascript: void(0)" (click)="onClickPrintIdForms()"
                                       *ngIf="clients.length > 0">
                                        Produce ID Forms and Declarations
                                    </a>
                                    <span *ngIf="clients.length == 0">Produce ID Forms and Declarations</span>
                                </span>
                            </span>
            </ng-container>
        </div>
    </div>
    <ng-container *ngIf="isWillClientInfo" >
        <dp-documents-to-produce
            [willMatter]="matter?.willMatter"
            [isSpouseAdded]="isSpouseAdded"
            (enableSave)="enableSave()"
        ></dp-documents-to-produce>
    </ng-container>
</ng-template>
