import {CurrencyPipe} from '@angular/common';
import {Component, Inject, ViewChild} from '@angular/core';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {MatterTax} from '../../shared/property-taxes';
import {StatementOfAdjustmentHeading} from '../../statement-adjustment/model/statement-adjustment-heading';
import {ProvinceCode} from '../../../admin/accounts/shared/account-province';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {RollNumberMatterTax} from '../../shared/roll-number-matter-tax';
import {Matter, Utils} from '../../shared';
import {DpBooleanValue, DpBooleanValueTypes} from '../../shared/dp-boolean';
import * as _ from 'lodash';
import {CondominiumExpense} from '../unit-level-plan/condominium-expense';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import {StatementAdjustmentKey} from '../../statement-adjustment/statement-adjustment-constants';
import {ParcelLegalDescription} from '../../shared/parcel-legal-description';
import {RollNumberPropertyTaxesResult} from './roll-number-property-taxes-result';
import {TabsService} from '../../../core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class RollNumberPropertyTaxesModalContext {
  propertyTax: MatterTax;
  closingDate: string;
  adjustmentLabelDateForTaxBeginningOfYearUntilOcc: string; //=> TaxBeginningOfYearUntilOcc uses occupancy dates for calculation but it displays adjustment date from "adjust as at" or closing
  estTaxIncrease: string;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  matterProvinceCode: ProvinceCode;
  separateTaxAdjustmentByRollNumber: boolean;
  matterTaxesByRollNumber: RollNumberMatterTax[];
  currentMatter: Matter;
  hideDeleteFromModal: boolean;
  disableCheckBoxFromSoa: boolean;
  condominiumExpenses: CondominiumExpense[];
  parcelLegalDescriptions: ParcelLegalDescription[];
  isCondo: DpBooleanValue;
  selectedMatterPropertyTax: MatterTax;
  statementAdjustmentKey: string;
  realtyTaxesPaidByVendor: boolean;
  sourceFromProject: boolean;
  infoOnly: boolean;
  autoInsertTaxAdjustment: boolean;
  hideAutoInsertFromModal: boolean;
}

@Component({
  selector: 'dp-roll-number-property-taxes-modal-content',
  templateUrl: 'roll-number-property-taxes.modal.component.html',
  providers: [CurrencyPipe, ErrorService]
})
export class RollNumberPropertyTaxesModalComponent extends ModalComponent<RollNumberPropertyTaxesModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  context: RollNumberPropertyTaxesModalContext;
  separateTaxAdjustmentByRollNumber: boolean;
  autoInsertTaxAdjustment: boolean;
  emptyCloseDateText: string;

  propertyTax: MatterTax;
  // selectedPropertyTax : MatterTax;
  closingDateLabel: string = 'Closing Date';
  adjustmentDateLabel: string = 'Adjustment Date';
  tempMatterTaxesByRollNumber: RollNumberMatterTax[];
  infoOnly: boolean;
  newAdj: boolean = false;
  dateStatusLabel: string;

  constructor(
    public dialog: MatDialogRef<RollNumberPropertyTaxesModalComponent>,
    protected dialogService: DialogService,
    protected currencyPipe: CurrencyPipe,
    public tabsService: TabsService,
    @Inject(MAT_DIALOG_DATA) context?: RollNumberPropertyTaxesModalContext
  ) {
    super(dialog, context);
  }

  get matterTaxesByRollNumber(): RollNumberMatterTax[] {
    //  let filterOutBlankRollNumbers : RollNumberMatterTax[] = this.context.matterTaxesByRollNumber.filter(rollNumberMatterTax => rollNumberMatterTax.rollNumber != undefined);
    return _.sortBy(this.context.matterTaxesByRollNumber, ['matterTax.rollNumberOrder']);
  }

  ngOnInit() {
    if (!this.context.propertyTax) {
      this.propertyTax = new MatterTax();
      this.newAdj = true;
    } else {
      this.propertyTax = new MatterTax(this.context.propertyTax);
    }
    if (!this.context.separateTaxAdjustmentByRollNumber) {
      this.separateTaxAdjustmentByRollNumber = false;
    } else {
      this.separateTaxAdjustmentByRollNumber = this.context.separateTaxAdjustmentByRollNumber;
    }
    if (this.context.autoInsertTaxAdjustment == undefined || this.context.autoInsertTaxAdjustment == null) {
      this.autoInsertTaxAdjustment = true;
    } else {
      this.autoInsertTaxAdjustment = this.context.autoInsertTaxAdjustment;
    }
    if (
      this.context.statementAdjustmentKey &&
      this.context.statementAdjustmentKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY &&
      this.context.adjustmentLabelDateForTaxBeginningOfYearUntilOcc
    ) {
      this.closingDateLabel = Utils.formatDate(this.context.adjustmentLabelDateForTaxBeginningOfYearUntilOcc);
    } else if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }
    if (this.context.currentMatter?.isMatterProvinceBC && this.context.currentMatter.adjustAsAtClosingDate) {
      this.adjustmentDateLabel = Utils.formatDate(this.context.currentMatter.adjustAsAtClosingDate);
    }
    if (this.context.currentMatter?.isMatterProvinceBC) {
      if (this.context.currentMatter.matterType != 'MORTGAGE') {
        if (this.context.currentMatter.adjustAsAtClosingDate) {
          this.dateStatusLabel = this.adjustmentDateLabel;
        } else {
          this.dateStatusLabel = 'Adjustment Date';
        }
      } else {
        if (this.context.closingDate) {
          this.dateStatusLabel = this.closingDateLabel;
        } else {
          this.dateStatusLabel = 'Completion Date';
        }
      }
    } else {
      this.dateStatusLabel = this.closingDateLabel;
    }
    this.initializeMatterTaxByRollNUmber();
    this.tempMatterTaxesByRollNumber = this.context.matterTaxesByRollNumber;
    if (this.context.infoOnly !== null && this.context.infoOnly !== undefined) {
      this.infoOnly = this.context.infoOnly;
    }
  }

  initializeMatterTaxByRollNUmber() {
    if (
      this.separateTaxAdjustmentByRollNumber &&
      this.context.matterTaxesByRollNumber &&
      this.context.matterTaxesByRollNumber.length > 0
    ) {
      if (this.context.matterProvinceCode == 'MB') {
        if (this.context.parcelLegalDescriptions) {
          this.context.matterTaxesByRollNumber.forEach((item, index) => {
            if (!item.matterTax) {
              let matterTax = new MatterTax();
              matterTax.rollNumberOrder = ParcelLegalDescription.getTitleRollNumberOrder(
                this.context.parcelLegalDescriptions,
                item.rollNumber
              );
              item.matterTax = new MatterTax(matterTax);
              this.activePropertyTax(item);
            }
          });
        }
      } else {
        if (this.context.isCondo === DpBooleanValueTypes.YES) {
          if (this.context.condominiumExpenses) {
            this.context.matterTaxesByRollNumber.forEach((item, index) => {
              if (!item.matterTax) {
                let condoExpenseIndex = this.context.condominiumExpenses.findIndex(
                  (condoExpense) => item.rollNumber === condoExpense.rollNumber
                );
                let matterTax = new MatterTax();
                matterTax.rollNumberOrder = condoExpenseIndex;
                item.matterTax = new MatterTax(matterTax);
                this.activePropertyTax(item);
              }
            });
          }
        } else {
          let rollNumberOrder: number = 0;
          this.context.matterTaxesByRollNumber.forEach((item, index) => {
            if (!item.matterTax) {
              let matterPropertyIndex = this.context.currentMatter.matterProperties.findIndex(
                (property) => item.rollNumber === property.rollNumber.city
              );
              let matterTax = new MatterTax();
              matterTax.rollNumberOrder = matterPropertyIndex;
              item.matterTax = new MatterTax(matterTax);
              this.activePropertyTax(item);
            } /*else{
                        rollNumberOrder = item.matterTax.rollNumberOrder;
                        }*/
          });
        }
      }
      if (this.context.selectedMatterPropertyTax) {
        let matterTaxByRollNumber = this.context.matterTaxesByRollNumber.find(
          (item) => item.matterTax.id === this.context.selectedMatterPropertyTax.id
        );
        this.propertyTax = new MatterTax(matterTaxByRollNumber.matterTax);
      } else {
        this.propertyTax = new MatterTax(this.context.matterTaxesByRollNumber[0].matterTax);
      }
    }
  }

  initializeSelectedPropertyTax(selectedPropertyTax: MatterTax) {
    selectedPropertyTax.initializeForRollNumber(
      this.context.estTaxIncrease,
      this.context.matterProvinceCode,
      this.context.closingDate
    );
  }

  createPropertyTaxByRollNumber(event) {
    if (event) {
      this.context.matterTaxesByRollNumber = [];
      this.separateTaxAdjustmentByRollNumber = true;
      this.tempMatterTaxesByRollNumber.forEach((item, index) => {
        if (item.rollNumber != '') {
          let matterTaxBYRollNumber = new RollNumberMatterTax();
          matterTaxBYRollNumber.rollNumber = item.rollNumber;
          let matterTax = new MatterTax();

          if (this.context.matterProvinceCode == 'MB') {
            if (this.context.parcelLegalDescriptions) {
              let parcelLegalDescriptionIndex = ParcelLegalDescription.getTitleRollNumberOrder(
                this.context.parcelLegalDescriptions,
                item.rollNumber
              );
              if (parcelLegalDescriptionIndex > -1) {
                matterTax.rollNumberOrder = parcelLegalDescriptionIndex;
              }
            }
          } else {
            if (this.context.isCondo === DpBooleanValueTypes.YES && this.context.condominiumExpenses) {
              let condoExpenseIndex = this.context.condominiumExpenses.findIndex(
                (condoExpense) => item.rollNumber === condoExpense.rollNumber
              );
              if (condoExpenseIndex > -1) {
                matterTax.rollNumberOrder = condoExpenseIndex;
              }
            } else {
              let matterPropertyIndex = this.context.currentMatter.matterProperties.findIndex(
                (property) => item.rollNumber === property.rollNumber.city
              );
              if (matterPropertyIndex > -1) {
                matterTax.rollNumberOrder = matterPropertyIndex;
              }
            }
          }
          matterTaxBYRollNumber.matterTax = new MatterTax(matterTax);
          this.initializeSelectedPropertyTax(matterTaxBYRollNumber.matterTax);
          this.context.matterTaxesByRollNumber.push(matterTaxBYRollNumber);
        }
      });
      this.propertyTax = new MatterTax(this.context.matterTaxesByRollNumber[0].matterTax);
    } else {
      let matterTax = new MatterTax();
      this.initializeSelectedPropertyTax(matterTax);
      this.propertyTax = new MatterTax(matterTax);
    }
  }

  activePropertyTax(selectedTab?: RollNumberMatterTax): void {
    this.updateMatterTax();
    if (selectedTab.rollNumber != '') {
      this.initializeSelectedPropertyTax(selectedTab.matterTax);
      this.propertyTax = new MatterTax(selectedTab.matterTax);
    }
  }

  updateMatterTax() {
    let matterTaxByRollNumber = this.context.matterTaxesByRollNumber.find(
      (item) => item.matterTax && item.matterTax.id == this.propertyTax.id
    );
    if (matterTaxByRollNumber) {
      matterTaxByRollNumber.matterTax = new MatterTax(this.propertyTax);
    }
  }

  ok(): void {
    let result: RollNumberPropertyTaxesResult = new RollNumberPropertyTaxesResult();
    result.infoOnly = this.infoOnly;
    result.separateTaxAdjustmentByRollNumber = this.separateTaxAdjustmentByRollNumber;
    result.autoInsertTaxAdjustment = this.autoInsertTaxAdjustment;
    if (this.separateTaxAdjustmentByRollNumber) {
      this.updateMatterTax();
      if (this.isValid(this.context.matterTaxesByRollNumber)) {
        this.cleanUpPropertyTaxes();
        result.propertyTaxesByRollNumber = this.context.matterTaxesByRollNumber;
        this.dialog.close(result);
      }
    } else {
      if (this.noErrors()) {
        this.cleanUpPropertyTaxes();
        result.propertyTax = this.propertyTax;
        this.dialog.close(result);
      }
    }
  }

  cleanUpPropertyTaxes(): void {
    if (this.propertyTax.baseTaxAdjustmentTaxBillType === 'NOT_ASSESSED') {
      this.propertyTax.totalTaxes = undefined;
      this.propertyTax.vendorWillHavePaid = undefined;
      this.propertyTax.vendorShareAmount = undefined;
      this.propertyTax.estimatedPercentageIncrease = undefined;
    }
  }

  noErrors(): boolean {
    let rslt: boolean = true;
    this.modalErrorComponent.removeAllDpFieldError();

    if (this.propertyTax.baseTaxAdjustmentTaxBillType === 'NOT_ASSESSED') {
      rslt = true;
    } else if (
      !this.isProjectTab() && //Relax the validation to allow zero amount for project adjustments
      (!this.propertyTax.totalTaxes ||
        this.propertyTax.totalTaxes == null ||
        (this.propertyTax.totalTaxes != null && this.propertyTax.totalTaxes == 0) ||
        (this.propertyTax.totalTaxes && this.propertyTax.totalTaxes.toString().trim() == ''))
    ) {
      this.modalErrorComponent.createDPFieldError('soa.tax.totalTaxes');
      rslt = false;
    }

    return rslt;
  }

  isProjectTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject();
  }

  isValid(matterTaxesByRollNumber: RollNumberMatterTax[]): boolean {
    let rslt: boolean = true;
    this.modalErrorComponent.removeAllDpFieldError();
    matterTaxesByRollNumber.forEach((matterTaxByRollNumber) => {
      let matterTax = matterTaxByRollNumber.matterTax;
      if (matterTax.baseTaxAdjustmentTaxBillType === 'NOT_ASSESSED') {
        rslt = true;
      } else if (
        !this.isProjectTab() && //Relax the validation to allow zero amount for project adjustments
        (!matterTax.totalTaxes ||
          matterTax.totalTaxes == null ||
          (matterTax.totalTaxes != null && matterTax.totalTaxes == 0) ||
          (matterTax.totalTaxes && matterTax.totalTaxes.toString().trim() == ''))
      ) {
        this.modalErrorComponent.createCustomDPFieldError(
          'soa.tax.totalTaxes',
          'The tax amount must be greater than 0 for Roll Number: ' + matterTaxByRollNumber.rollNumber,
          null,
          'ERROR'
        );

        rslt = false;
      }
    });
    return rslt;
  }

  close(): void {
    this.dialog.close();
  }

  delete(): void {
    if (this.propertyTax && this.propertyTax.isPayOutOfTrustAccount0SetToYes()) {
      this.dialogService
        .confirm(
          'Warning',
          'The Tax adjustment cannot be deleted when the value of the "Pay taxes out of our trust account" field has been set to "Yes"',
          true,
          'Close'
        )
        .subscribe();
    } else {
      this.dialogService
        .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
        .subscribe((res) => {
          if (res) {
            if (this.separateTaxAdjustmentByRollNumber) {
              this.dialog.close({separateTaxAdjustmentByRollNumber: false});
            } else {
              this.propertyTax = new MatterTax();
              this.dialog.close({propertyTax: undefined});
            }
          }
        });
    }
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  get adjustmentDescription(): string {
    if (this.context.statementAdjustmentKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY) {
      return 'Adjustment Type : Taxes - Beginning of Year Until Occupancy';
    }
    return this.propertyTax.hasPaidTaxesUnderTIPP == 'YES'
      ? `Adjustment Type : Taxes (${provinceBasedFieldLabels.get(
          'matter.soa.property.taxes.tippOrTipps',
          this.context.matterProvinceCode
        )})`
      : this.context.realtyTaxesPaidByVendor
        ? 'Taxes Paid by Vendor'
        : 'Adjustment Type : Taxes';
  }

  isPropertyTax(): boolean {
    return this.context.statementAdjustmentKey === StatementAdjustmentKey.PROPERTY_TAX;
  }
}
