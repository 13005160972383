import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../accounts/shared/base-province';
import {DpBooleanValue} from '../../matters/shared/dp-boolean';

export class SyngrafiiPackageTypes {
  public static readonly VIDEO_SIGNING_ROOM: SyngrafiiPackageType = 'VIDEO_SIGNING_ROOM';
  public static readonly SEQUENTIAL: SyngrafiiPackageType = 'SEQUENTIAL';
}

export type SyngrafiiPackageType = 'VIDEO_SIGNING_ROOM' | 'SEQUENTIAL';

export class RemoteSigningConfiguration extends BaseEntity {

  constructor(remoteSigningDefaults?: RemoteSigningConfiguration) {
    super(remoteSigningDefaults);
  }

  id: number;

  accountId: number;
  provinceCode: ProvinceCode;
  documentsToBeSignedRemotely: DpBooleanValue;
  documentsToBeSignedDigitally: DpBooleanValue;
  digitalSignaturePlatform: string;
  defaultSyngrafiiPackageType: SyngrafiiPackageType;
}
