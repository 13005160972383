import {Matter} from '../shared/matter';
import {matterSections} from '../shared';
import {MatterSectionsRouteTypes} from '../shared';
import {OpportunityMatterStatusValue} from '../shared/matter-specific-type';
import {Section} from '../shared/section';
import {Tab} from '../../shared/tabbing/tab';
import {TabsService} from '../../core';
import {TabsComponent} from '../../shared/tabbing';
import {DialogService} from '../../shared/dialog/dialog.service';
import {MatterListState} from '../shared/matter-list-state';
import {WindowRef} from '../../shared/window.ref';
import * as _ from 'lodash';
import {MatterService} from '../matter.service';
import {LockScreenService} from '../../core/lock-screen.service';
import {DefaultMatterListFilterName} from '../../shared-main/constants';
import {MatterListFilter} from './matter-list-filter';
import {SaveMatterListFilterModal} from './modals/save-matter-list-filter-modal.component';

declare var jQuery: any;

export const RemoveDuplicateConfirmation = 'This opportunity will no longer be marked as duplicated.  Do you wish to proceed?';

export class MatterListUtil {
  static getMatterTypeShortName(m: Matter): string {
    return (m.matterType == null) ? '' : m.isProjectSale ? 'P' + m.matterType.charAt(0) :
      ((m && m.isCustomMatter()) ? m.customMatterTypeName : (m && (!!m.isMatterTypeDischarge || m.isDischargeMatter()) ? 'DCH' : m.matterType.charAt(0)));
  }

  static getOpportunityTypeShortName(m: Matter): string {
    return m.isOpportunityPurchaseSaleOrMortgage ? m.customMatterTypeName.charAt(0) : m.customMatterTypeName;
  }

  static getMatterRecordNumberHelpText(m: Matter): string {
    return 'Click here to open ' + (m.isProjectSale ? m.projectRecordNumber + '-' + m.matterRecordNumber : m.matterRecordNumber);
  }

  static getMatterRecordNumber(m: Matter): string {
    if (m.isProjectSale && m.projectRecordNumber) {
      if (m.projectRecordNumber.length + m.matterRecordNumber.length < 32) {
        return m.projectRecordNumber + '-' + m.matterRecordNumber;
      } else if (m.matterRecordNumber.length < 19) {
        return m.projectRecordNumber.substr(0, 10) + '...-' + m.matterRecordNumber;
      } else {
        return m.projectRecordNumber.substr(0, 10) + '...-' + m.matterRecordNumber.substr(0, 15) + '...';
      }
    } else {
      return m.matterRecordNumber;
    }
  }

  // create the dynamic tab for matters page and emit the tabs to matters component.
  static openMatter = (matter: Matter, tabsService: TabsService, tabsComponent: TabsComponent, dialogService: DialogService, sectionRouteType?: string, checkNotesSectionForOpen: boolean = false): void => {
    let tab: Tab = tabsService.getTab(matter.id, 'matter');
    if (tab) {
      tabsComponent.openSelectedTab(tab);
    } else {
      // For message open matter, we only have matterId and have not showNotesOnOpen value.
      // So we check showNotesOnOpen after get matter from back end
      let section: Section;
      if (matter.showNotesOnOpen) {
        section = matterSections.find((section) => section.route === MatterSectionsRouteTypes.NOTES);
      } else {
        let searchSectionsRouteType: string = sectionRouteType ? sectionRouteType : MatterSectionsRouteTypes.OVERVIEW;
        if (matter.id) { //For Existing matters open the overview topic
          section = matterSections.find((section) => section.route === searchSectionsRouteType);
        }
      }
      tabsComponent.openMatter(matter, () => {
        dialogService.confirm('Error',
          'Your access to this matter has been removed by your administrator.', true).subscribe(() => {

        });
      }, section, undefined, checkNotesSectionForOpen);

    }
  };

  static getLockedoropenedMattersCount(matterListState: MatterListState, tabsService: TabsService): number {

    // used to get number of locked or opened matters for checkAllMatters checkbox
    let num = 0;
    if (matterListState && matterListState.rows) {
      matterListState.rows.forEach(item => {
        let obj = item as Matter;
        if (obj.locked || this.isMatterTabOpen(obj.id, tabsService) || this.isLinkedMatterLocked(obj.id, tabsService)) {
          num = num + 1;
        }
      });
    }

    return num;
  }

  static isMatterTabOpen(id, tabsService: TabsService): boolean {
    return tabsService.isMatterTabOpen(id);
  }

  static isLinkedMatterLocked(id, tabsService: TabsService): boolean {
    return tabsService.isLinkedMatterTabOpen(id);
  }

  static isAllMattersSelected(matterListState: MatterListState, tabsService: TabsService): boolean {
    return matterListState.selectedMatters && matterListState.rows
      && (matterListState.selectedMatters.length == (matterListState.rows.length - this.getLockedoropenedMattersCount(matterListState, tabsService)))
      && matterListState.selectedMatters.length > 0;
  }

  static handleRowSelect(event: any, matter: Matter, previousIndexFromTabKey: number, matterListState: MatterListState, window: WindowRef, tabsService: TabsService): void {
    if (event.shiftKey && matterListState.selectedMatters.length > 0) {

      /// select multiple matters using shift key we get starting point (startIndex) of last selected matter
      //  have current matter (endIndex) that was clicked to cycle through adding them to selectedMatters list
      let startIndex = this.getindexofSelectedMatter(matterListState.selectedMatters[ matterListState.selectedMatters.length - 1 ].id, matterListState) + 1;
      let endIndex = this.getindexofSelectedMatter(matter.id, matterListState) + 1;

      this.selectMultipleMatters(startIndex, endIndex, matterListState, tabsService);
      // fixes a bug from shift click that causes text to hilite, removes selected text
      window.nativeWindow.getSelection().removeAllRanges();

      if (window.nativeWindow.document.documentMode && window.nativeWindow.document.documentMode < 12) {

        jQuery(event.target).closest('tr').focus();
      }

    } else {
      if (event.shiftKey && event.type == 'click') {
        let startIndex: number;
        let endIndex: number;

        startIndex = this.getindexofSelectedMatter(matterListState.rows[ previousIndexFromTabKey ].id, matterListState) + 1;
        endIndex = this.getindexofSelectedMatter(matter.id, matterListState) + 1;

        if (startIndex < endIndex) {
          startIndex = startIndex - 1;

        }
        this.selectMultipleMatters(startIndex, endIndex, matterListState, tabsService);
        window.nativeWindow.getSelection().removeAllRanges();
        if (window.nativeWindow.document.documentMode && window.nativeWindow.document.documentMode < 12) {

          jQuery(event.target).closest('tr').focus();
        }

      } else {
        if (matter) {
          // check if matter tab is already open or matter is locked
          if (!this.isMatterTabOpen(matter.id, tabsService) && !matter.locked && !this.isLinkedMatterLocked(matter.id, tabsService)) {

            if (!this.isSelected(matter, matterListState)) {
              /// if matter not already selected we add it to list

              if (event.target.tagName === 'LABEL' || event.ctrlKey === true
                || event.target.tagName.indexOf('DP-CHECKBOX') != -1
                || event.target.className.indexOf('check-box-column') != -1) {
                /// if event.target label -- checkbox was clicked not the row.
                matterListState.selectedMatters.push(matter);
              } else {
                // row was clicked instead so we remove all selected matters and only selected the one row
                matterListState.selectedMatters = [];
                matterListState.selectedMatters.push(matter);
              }
            } else {
              // if matter selected then we remove it from list
              matterListState.selectedMatters.splice(matterListState.selectedMatters.findIndex((m: Matter) => m.id === matter.id), 1);
            }

          }
        }
      }
    }
  }

  static getindexofSelectedMatter(id, matterListState: MatterListState): number {
    let index = _.findIndex(matterListState.rows, function (o) {
      return o.id == id;
    });
    return index;
  }

  static isSelected(matter: Matter, matterListState: MatterListState): boolean {
    return !!matterListState.selectedMatters.find((m: Matter) => m.id === matter.id);
  }

  static selectMultipleMatters(startIndex, endIndex, matterListState: MatterListState, tabsService: TabsService) {
    if (startIndex < endIndex) {
      for (let i = startIndex; i < endIndex; i++) {
        // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already
        if (!this.isMatterTabOpen(matterListState.rows[ i ].id, tabsService) && !matterListState.rows[ i ].locked
          && !this.isSelected(matterListState.rows[ i ], matterListState) && !this.isLinkedMatterLocked(matterListState.rows[ i ].id, tabsService)) {
          matterListState.selectedMatters.push(matterListState.rows[ i ]);
        }
      }
    } else {
      for (let i = startIndex; i > (endIndex - 1); i--) {
        // cycle through from start to end Index and add to selected matters list if they are not opened locked or selected already
        if (!this.isMatterTabOpen(matterListState.rows[ i - 1 ].id, tabsService) && !matterListState.rows[ i - 1 ].locked
          && !this.isSelected(matterListState.rows[ i - 1 ], matterListState) && !this.isLinkedMatterLocked(matterListState.rows[ i - 1 ].id, tabsService)) {
          matterListState.selectedMatters.push(matterListState.rows[ i - 1 ]);
        }
      }
    }
  }

  static openOpportunity(matter: Matter, tabsService: TabsService, tabsComponent: TabsComponent, dialogService: DialogService, openConversionModal: boolean = false): void {
    let tab: Tab = tabsService.getTab(matter.id, 'opportunityMatter');
    if (tab) {
      tabsComponent.openSelectedTab(tab);
    } else {
      let section: Section;
      if (matter.showNotesOnOpen) {
        section = matterSections.find((section) => section.route === MatterSectionsRouteTypes.NOTES);
      }
      tabsComponent.openOpportunity(matter, () => {
        dialogService.confirm('Error',
          'Your access to this matter has been removed by your administrator.', true).subscribe(() => {

        });
      }, section, {'oc': openConversionModal});

    }
  }

  static async removeOpportunityDuplicateLink(opportunity: Matter, matterService: MatterService, lockScreenService: LockScreenService): Promise<boolean> {

    try {
      lockScreenService.lockForUpdate = true;
      opportunity.duplicateOfOpportunityId = null;
      opportunity.opportunity.opportunityStatus = OpportunityMatterStatusValue.qualifying;
      let matter = await matterService.saveMatter(opportunity).toPromise();
      if (matter) {
        return true;
      }
    } catch (e) {
      return false;
    } finally {
      lockScreenService.lockForUpdate = false;
    }

  }

  static updateSearchViews(data: MatterListFilter[], searchViews: MatterListFilter[], isMassUpdate?: boolean) {
    if (Array.isArray(data) && data.length > 0) {
      data.forEach(item => {
        if (!isMassUpdate || item.matterListFilterName != DefaultMatterListFilterName.MY_RECENT) {
          searchViews.push(new MatterListFilter(item));
        }
      });
    }
  }

  static createNewMatterListFilter(matterListFilter: MatterListFilter, dialogService: DialogService, isMassUpdate: boolean, callBackFn: Function): void {

    dialogService.matDialogContent({
      modalGrid: 5,
      content: SaveMatterListFilterModal,
      context: {
        matterListFilter: matterListFilter,
        isMassUpdate: isMassUpdate
      },
      onFulfillment: (result) => {
        if (result.action == 'ok') {
          callBackFn(result.matterListFilter);

        }
      },
      fullScreen: false

    });
  }

  static updateMatterListFilter(matterListFilter: MatterListFilter, filterId: number, matterService: MatterService, dialogService: DialogService, callBackFn: Function): void {

    matterService.updateMatterListFilter(matterListFilter, filterId + '')
    .subscribe(res => {
        callBackFn(res);
      }, (error => {
        console.log('updateFilter : error ', error);
        if (error.errorCode === 'app.filterNotFound') {
          dialogService.confirm('Error', 'The filter cannot be updated', true);
        }
      })
    );
  }

  static deleteMatterListFilter(filterName: string, filterId: number, matterService: MatterService, dialogService: DialogService, callBackFn: Function): void {
    const errorMsg: string = 'Are you sure you want to delete \'' + filterName + '\'?';
    dialogService.confirm('Confirmation',
      errorMsg,
      false, 'OK').subscribe(res => {
      if (res) {
        matterService.deleteMatterListfilter(filterId + '')
        .subscribe(res => {
            console.log('deleteFilter', res);
            callBackFn();
          }, (error => {
            console.log('deleteFilter : error ', error);
            if (error.errorCode === 'app.filterNotFound') {
              callBackFn();
            }
          })
        );
      }

    });
  }

  static openMatterOppFromMessages(data: any, tabsService: TabsService, tabsComponent: TabsComponent, dialogService: DialogService): void {
    if (data && data.additionalMessageData && data.additionalMessageData.matterId) {
      let matter = new Matter();
      matter.id = data.additionalMessageData.matterId;
      let sectionRouteType = MatterSectionsRouteTypes.OVERVIEW;
      if (data.additionalMessageData.taskAssignment) {
        sectionRouteType = data.additionalMessageData.taskAssignment != 'YES' ? MatterSectionsRouteTypes.MATTER_OPENING : MatterSectionsRouteTypes.OVERVIEW;
      } else if (data.additionalMessageData.checkList) {
        sectionRouteType = data.additionalMessageData.checkList != 'YES' ? MatterSectionsRouteTypes.MATTER_OPENING : MatterSectionsRouteTypes.OVERVIEW;
      }
      this.openMatter(matter, tabsService, tabsComponent, dialogService, sectionRouteType, true);
    } else if (data && data.additionalMessageData && data.additionalMessageData.opportunityMatterId) {
      let matter = new Matter();
      matter.id = data.additionalMessageData.opportunityMatterId;
      let sectionRouteType = MatterSectionsRouteTypes.OPPORTUNITY_DETAIL;
      if (data.additionalMessageData.taskAssignment) {
        sectionRouteType = data.additionalMessageData.taskAssignment != 'YES' ? MatterSectionsRouteTypes.OPPORTUNITY_DETAIL : MatterSectionsRouteTypes.WORKITEMS;
      }
      const section = matterSections.find((section) => section.route == sectionRouteType);
      tabsComponent.openOpportunity(matter, () => {
        dialogService.confirm('Error',
          'Your access to this matter has been removed by your administrator.', true).subscribe(() => {

        });
      }, section, undefined, true);
    }
  }

}
