<div id="mortgageReport">
    <div class="form-group" *ngIf="matter && !matter.isSale  && !mortgage.isEmpMortgage()">
        <ng-container *dpShowByProvince="'matter.mortgage.report.generateReportingLetterTo'">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Generate a reporting letter to</label>
            </div>
            <div class="col-lg-4">
                <select
                        class="form-control"
                        name="reportingLetter" id="reportingLetter"
                        (ngModelChange)="onGenerateReportingLetterToChange($event)"
                        [(ngModel)]="mortgageReport.generateReportingLetterTo">
                    <option *ngFor="let reportingLtr of reportingLetterTo" [value]="reportingLtr.value">{{reportingLtr.label}}</option>
                </select>
            </div>
        </ng-container>
    </div>
    <div class="fields-group margin-bottom-0" *ngIf="mortgage.isEmpMortgage()">
        <div class="main-level">
            <span class="title">Info to Report to Lender</span>
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" [dp-province-field-label]="'matter.mortgage.report.mortgageRegistrationDate'">Mortgage Registration Date</label>
        </div>
        <div class="col-lg-4">
            <dp-partial-date #idAdjDate (dateChange)="onDateChangeAdjDate($event)"
                             [inputDate]="getMortgageRegDateValue()"
                             [dateParent]="mortgageReport"
                             [fieldKey]="'mortgage.'+mortgageIndex+'.registrationDate'"
                             statusBarHelp
                             [dynamicHelpCallBackFunction]="getRegistrationDateF9HelpText()"
                             [customF9Behaviour]="onRegistrationDateF9"
                             [dateFieldName]="'mortgageRegistrationDate'"
            >
            </dp-partial-date>
        </div>

        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" [dp-province-field-label]="'matter.mortgage.report.mortgageRegistrationNumber'">Mortgage Registration Number</label>
        </div>
        <div class="col-lg-4">
            <input type="text"
                   name="registrationNumber"
                   id="registrationNumber"
                   maxlength="41"
                   class="form-control uppercase"
                   [(ngModel)]="mortgageReport.mortgageRegistrationNumber"
                   (change)="enableSave()"
                   trimmed/>
        </div>
    </div>

    <div class="form-group" *ngIf="mortgageTerm.isAssignmentOfRents()">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" [dp-province-field-label]="'matter.mortgage.report.AssignmentOfRentsRegistrationDate'">Assignment of Rents Registration Date</label>
        </div>
        <div class="col-lg-4">
            <dp-partial-date #idRentAssignmentRegistrationDate (dateChange)="onDateChangeRentAssignmentRegistrationDate($event)"
                             [inputDate]="mortgageReport.rentAssignmentRegistrationDate"
                             [dateParent]="mortgageReport"
                             [fieldKey]="'mortgage.'+mortgageIndex+'.report.rentAssignmentRegistrationDate'"
                             [dateFieldName]="'rentAssignmentRegistrationDate'"
            >
            </dp-partial-date>
        </div>

        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" [dp-province-field-label]="'matter.mortgage.report.AssignmentOfRentsRegistrationNumber'">Assignment of Rents Registration Number</label>
        </div>
        <div class="col-lg-4">
            <input type="text"
                   name="rentAssignmentRegistrationNumber"
                   id="rentAssignmentRegistrationNumber"
                   maxlength="41"
                   class="form-control"
                   dp-uppercase-preserve-cursor-position
                   [(ngModel)]="mortgageReport.rentAssignmentRegistrationNumber"
                   (change)="enableSave()"
                   trimmed/>
        </div>
    </div>

    <div class="fields-group margin-bottom-0" *ngIf="mortgage.isEmpMortgage()">
        <div class="main-level">
            <span class="title">Info to Report to Mortgagor</span>
        </div>
    </div>

    <ng-container *ngIf="matter && !matter.isSale && !mortgage.isEmpMortgage()">
        <div class="form-group" *ngIf="existingDispositionRemainMortgage && existingDispositionRemainMortgage.length > 0">
            <div class="col-lg-12 vertical-align-label">
                <label class="control-label text-left">Did Mortgagee deduct monies from advance to pay out previous mortgage which it held?</label>
            </div>
        </div>
        <ng-container *ngFor="let item of existingDispositionRemainMortgage">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" [innerHTML]="getMortgageIndexStr(item)"></label>
                </div>
                <div class="col-lg-1">
                    <select
                            class="form-control"
                            name="deductMoniesMortgage" id="deductMoniesMortgage"
                            [(ngModel)]="item.deductMonies"
                            (change)="enableSave()">
                        <option *ngFor="let yesNoItem of deductMoniesOptions" [value]="yesNoItem.value">{{yesNoItem.label}}</option>
                    </select>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <div class="form-group"  *dpShowByProvince="'matter.mortgage.report.includeClauseMtgeeConsentRequiredIfMtgToBeAssumed'">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Include Clause that Mtgee's consent required if mtg to be assumed?</label>
        </div>
        <div class="col-lg-1">
            <select
                    class="form-control"
                    name="consentRequired" id="consentRequired"
                    [(ngModel)]="mortgageReport.includeClause"
                    (change)="enableSave()">
                <option *ngFor="let yesNoItem of yesNoItems" [value]="yesNoItem.value">{{yesNoItem.label}}</option>
            </select>
        </div>
    </div>
    <div class="form-group"  *dpShowByProvince="'matter.mortgage.report.mortgagePrepaymentPrivilege'">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Mortgage Prepayment Privilege</label>
        </div>
        <div class="col-lg-5">
            <select
                    class="form-control"
                    name="prepaymentPrivilege" id="prepaymentPrivilege"
                    [(ngModel)]="mortgageReport.mortgagePrepaymentPrivilege"
                    (change)="prepaymentPrivilegeChange($event)">
                <option *ngFor="let prepaymentPrivileg of prepaymentPrivilege" [value]="prepaymentPrivileg.value">{{prepaymentPrivileg.label}}</option>
            </select>
        </div>
    </div>
    <div class="form-group" *ngIf="isPageNumberAvailable()">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">{{pageNumberStartingLabel}}</label>
        </div>
        <div class="col-lg-4">
            <input type="text" name="pageNumber" id="pageNumber" class="form-control"
                   [(ngModel)]="mortgageReport.pageNumber"
                   maxlength="15"
                   (change)="enableSave()"/>
        </div>
        <div class="col-lg-4 vertical-align-label ">
            <label class="control-label text-left">{{pageNumberEndingLabel}}</label>
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Additional Clause(s)</label>
        </div>
        <!--The additionalClause length is 64k for now-->
        <div class="col-lg-4">
            <textarea rows="15"
                      class="form-control"
                      id="additionalClause"
                      name="additionalClause"
                      maxlength="64000"
                      [fieldKey]="'mortgage.report.additionalClause'"
                      statusBarHelp
                      [(ngModel)]="mortgageReport.additionalClause"
                      (ngModelChange)="notifyChange()"
                      (click)="updateCursorPosition($event)"
                      (keyup)="updateCursorPosition($event)">
            </textarea>
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-2 offset-lg-2">
            <input type="button"
                   class="primary-button"
                   value="Insert Precedent"
                   (click)="insertPrecedent()"/>
        </div>
        <div class="col-lg-2">
            <input type="button"
                   class="primary-button"
                   value="Save As Precedent"
                   [dp-read-only]="!mortgageReport.additionalClause"
                   (click)="saveAsPrecedent()"/>
        </div>
    </div>
    <ng-container *dpShowByProvince="'matter.mortgage.report.mtgFinalReportToMortgageeEnclosures'">
        <div class="form-group" *ngIf="isFinalReportToMortgageeEnclosuresDisabled">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" [dp-province-field-label]="'matter.mortgage.report.MortgageFinalReportToMortgageeEnclosures'">Final Report to Mortgagee Enclosures</label>
            </div>
            <div class="col-lg-10">
                <input type="text" name="finalReport" id="finalReport" class="form-control wrap-input" [dp-read-only]
                       [ngModel]="getMortgageFinalEnclosures()"
                       (keydown.f9)="openInterimEncModal()"
                       [fieldKey]="'mortgage.report.finalReportEnclosures'"
                       statusBarHelp
                       (change)="notifyChange()"/>
                <button type="button"
                        id="finalReportButton"
                        (click)="openInterimEncModal()"
                        tabIndex="-1"
                        class="button-glyph">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isAdditionalMortgageDetailsAvailable">
        <ng-container *dpShowByProvince="'matter.mortgage.term.additionalMortgageDetails'">
            <div class="form-group fields-group margin-bottom-0">
                <div class="col-lg-12 padding-top-20 margin-bottom-0">
                    <div class="main-level">
                        <span class="title min-height-30">Additional Mortgage Details</span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group col-lg-6">
                    <div class="col-lg-4 vertical-align-label">
                        <label class="control-label">Box 6 Additional Provisions</label>
                    </div>
                    <!--The additionalClause length is 64k for now-->
                    <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="box6AdditionalProvisions"
                              name="box6AdditionalProvisions"
                              maxlength="64000"
                              [fieldKey]="'mortgage.term.box6AdditionalProvisions'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.box6AdditionalProvisions"
                              (ngModelChange)="enableSave()"
                              (click)="updateCursorPositionBox6($event)"
                              (keyup)="updateCursorPositionBox6($event)">
                    </textarea>
                    </div>
                    <div class="col-lg-4 offset-lg-4 padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Insert Precedent"
                               (click)="insertPrecedent('BOX6')"/>
                    </div>
                    <div class="col-lg-4  padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Save As Precedent"
                               [dp-read-only]="!mortgageTerm.box6AdditionalProvisions"
                               (click)="saveAsPrecedent('BOX6')"/>
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="col-lg-4 vertical-align-label">
                        <label class="control-label">Mortgage Description</label>
                    </div>
                    <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="mortgageDescription"
                              name="mortgageDescription"
                              maxlength="130"
                              [fieldKey]="'mortgage.term.mortgageDescription'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.mortgageDescription"
                              (ngModelChange)="enableSave()"
                              (click)="updateCursorPositionMtgDesc($event)"
                              (keyup)="updateCursorPositionMtgDesc($event)">
                    </textarea>
                    </div>
                    <div class="col-lg-4 offset-lg-4  padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Insert Precedent"
                               (click)="insertPrecedent('MTG')"/>
                    </div>
                    <div class="col-lg-4  padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Save As Precedent"
                               [dp-read-only]="!mortgageTerm.mortgageDescription"
                               (click)="saveAsPrecedent('MTG')"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-group col-lg-6">
                    <div class="col-lg-4 vertical-align-label">
                        <label class="control-label">Loan Description</label>
                    </div>
                    <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="loanDescription"
                              name="loanDescription"
                              maxlength="130"
                              [fieldKey]="'mortgage.term.loanDescription'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.loanDescription"
                              (ngModelChange)="enableSave()"
                              (click)="updateCursorPositionLoanDesc($event)"
                              (keyup)="updateCursorPositionLoanDesc($event)">
                    </textarea>
                    </div>
                    <div class="col-lg-4 offset-lg-4 padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Insert Precedent"
                               (click)="insertPrecedent('LOAN')"/>
                    </div>
                    <div class="col-lg-4  padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Save As Precedent"
                               [dp-read-only]="!mortgageTerm.loanDescription"
                               (click)="saveAsPrecedent('LOAN')"/>
                    </div>
                </div>
                <div class="form-group col-lg-6">
                    <div class="col-lg-4 vertical-align-label">
                        <label class="control-label">Prime is defined as</label>
                    </div>
                    <div class="col-lg-8">
                    <textarea rows="10"
                              class="form-control"
                              id="primeIsDefinedAs"
                              name="primeIsDefinedAs"
                              maxlength="130"
                              [fieldKey]="'mortgage.term.primeIsDefinedAs'"
                              statusBarHelp
                              [(ngModel)]="mortgageTerm.primeIsDefinedAs"
                              (ngModelChange)="enableSave()"
                              (click)="updateCursorPositionPrimeText($event)"
                              (keyup)="updateCursorPositionPrimeText($event)">
                    </textarea>
                    </div>
                    <div class="col-lg-4 offset-lg-4  padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Insert Precedent"
                               (click)="insertPrecedent('PRIME')"/>
                    </div>
                    <div class="col-lg-4  padding-top-10">
                        <input type="button"
                               class="primary-button"
                               value="Save As Precedent"
                               [dp-read-only]="!mortgageTerm.primeIsDefinedAs"
                               (click)="saveAsPrecedent('PRIME')"/>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <dp-user-defined-fields [topicName]="'MORTGAGES_REPORT'" [mortgageId]="mortgage.id" (onChange)="enableSave()">
    </dp-user-defined-fields>

</div>
