import {DocumentProfileSoa} from '../../../admin/document-profile/document-profile-edit/soa';

export class StatementOfAdjustmentHeading {

  id: number;
  interimStatementOfAdjustmentHeader: string;
  statementOfAdjustmentHeader: string;
  vendorHeading: string;
  purchaserHeading: string;
  vendorDataCode: string;
  purchaserDataCode: string;
  addressHeading: string;
  addressDataCode: string;
  adjustDateHeading: string;
  adjustDateDataCode: string;
  rightColumnListsCreditsTo: string;
  leftColumnListsCreditsTo: string;
  paperSizeCode: string;
  printInUpperCase: boolean;
  printInBold: boolean;
  underline: boolean;
  lastLineCreditVendorOrCreditPurchaser: boolean;
  followAdjustmentWithBlankLine: boolean;
  printDollarSignsOnlyForFirstCreditOfEachColumn: boolean;
  doNotPrintDollarSignsInSubtotalColumn: boolean;
  adoptProjectHeading: boolean;
  includeSolicitorNameOnSigningLineOfDirectionReFunds: boolean;
  includeVendorGst: boolean;

  constructor(statementAdjustmentHeading?: StatementOfAdjustmentHeading) {
    if (statementAdjustmentHeading) {
      for (let prop in statementAdjustmentHeading) {
        if (statementAdjustmentHeading.hasOwnProperty(prop)) {
          this[ prop ] = statementAdjustmentHeading[ prop ];
        }
      }

    }

  }

  adoptProjectHeadingFrom(hdng: StatementOfAdjustmentHeading): void {
    this.interimStatementOfAdjustmentHeader = hdng.interimStatementOfAdjustmentHeader;
    this.statementOfAdjustmentHeader = hdng.statementOfAdjustmentHeader;
    this.vendorHeading = hdng.vendorHeading;
    this.vendorDataCode = hdng.vendorDataCode;
    this.purchaserHeading = hdng.purchaserHeading;
    this.purchaserDataCode = hdng.purchaserDataCode;
    this.addressHeading = hdng.addressHeading;
    this.addressDataCode = hdng.addressDataCode;
    this.adjustDateHeading = hdng.adjustDateHeading;
    this.adjustDateDataCode = hdng.adjustDateDataCode;
    this.rightColumnListsCreditsTo = hdng.rightColumnListsCreditsTo;
    this.leftColumnListsCreditsTo = hdng.leftColumnListsCreditsTo;
    this.paperSizeCode = hdng.paperSizeCode;
  }

  copyFromSoaProfile(soaProfile: DocumentProfileSoa, adoptProjectHeading: boolean = false) {
    this.adoptProjectHeading = adoptProjectHeading;
    this.statementOfAdjustmentHeader = soaProfile.soaHeading;
    this.interimStatementOfAdjustmentHeader = 'INTERIM ' + soaProfile.soaHeading;
    this.purchaserHeading = soaProfile.otherHeadingsPurchaser;
    this.purchaserDataCode = soaProfile.otherHeadingsPurchaserDataCode;
    this.vendorHeading = soaProfile.otherHeadingsVendor;
    this.vendorDataCode = soaProfile.otherHeadingsVendorDataCode;
    this.adjustDateHeading = soaProfile.otherHeadingsAdjustDate;
    this.adjustDateDataCode = soaProfile.otherHeadingsAdjustDateDataCode;
    this.addressHeading = soaProfile.otherHeadingsAddress;
    this.addressDataCode = soaProfile.otherHeadingsAddressDataCode;
    this.rightColumnListsCreditsTo = soaProfile.rightColumnListsCreditsTo;
    this.leftColumnListsCreditsTo = soaProfile.leftColumnListsCreditsTo;
  }
}
