import {OrderProductType, OrderStatus} from './ltsa-constants';

export interface ChargeOnTitle {
  registrationNumber: string;
  status: string;
  registrationDate: string;
  natureOfCharge: string;
  holderOfCharge: string;
  price: number;
}

export interface AssociatedPlan {
  planType: string;
  planNumber: string;
  price: number;
}

export interface DocumentCostInfo {
  totalFee: number,
  totalTax: number,
  productFee: number,
  feeExempted: false,
  subtotalFee: number,
  productFeeTax: number,
  serviceCharge: number,
  serviceChargeTax: number
}

export type Order = {
  productType: OrderProductType;
  status: OrderStatus;
  date: string;
  criteria: string;
  id: number;
  documentId: number | null;
  fileReference: string;
  chargesOnTitle: ChargeOnTitle[];
  associatedPlans: AssociatedPlan[];
  districtCode: string | null;
  parcelIdentifier: string | null;
  titleNumber: string | null;
  groupId: number;
  searchType: string | null;
  displayName?: string | null;
  documentCostInfo?: DocumentCostInfo | null;
}

export type LtsaOrderPack = {
  groupId: number;
  orders: Order[];
}

export class AvailableDocument {
  type: string;
  criteria: string;
  charge: string;
  estCost: string;
  ordered: boolean = false;
  isSelected: boolean = true;
  isExpanded: boolean = false;
  orders: Order[] = [];
  status?: string;
  registrationDate?: string;
  holderOfCharge?: string;

  constructor(type: string, criteria: string, charge: string, estCost: string, status?: string, registrationDate?: string, holderOfCharge?: string) {
    this.type = type;
    this.criteria = criteria;
    this.charge = charge;
    this.estCost = estCost;
    this.status = status;
    this.registrationDate = registrationDate;
    this.holderOfCharge = holderOfCharge;
  }

  get displayName(): string {
    return `${ this.criteria } - ${ this.charge }`;
  }

  get nextAvailableName() {
    return `${ this.criteria } - ${ this.charge }${ this.orders.length > 0 ? ` (${ this.orders.length })` : '' }`;
  }
}

export class OrderResult {
  errors: any[] = [];
  order: Order;

  constructor(order?: Order) {
    this.order = order;
  }

  public addError(error: any) {
    if (error) {
      this.errors.push(error);
    }
  }
}

export enum OrderLabel {
  ORDERED = 'Ordered',
  RE_ORDERED = 'Re-Ordered'
}
