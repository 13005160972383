import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {MatterTax} from './property-taxes';

export class RollNumberMatterTax extends BaseEntity {

  rollNumber: string;
  matterTax: MatterTax;

  constructor(rmt?: RollNumberMatterTax) {
    super(rmt);
    for (let prop in rmt) {
      if (rmt.hasOwnProperty(prop)) {
        this[ prop ] = rmt[ prop ];
      }
    }

  }

}
