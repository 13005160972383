<!-- modal -->
<div class="container-fluid custom-modal-container">
  <form class="form-horizontal" *ngIf="soAdjInterestOnDepositCondo">
    <div class="row modal-header">
      <div class="col-lg-12">
        <div class="col-lg-10">
          <h1>{{adjustmentHeading}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
      </div>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="col-lg-12">
          <div class="col-lg-3 vertical-align-label">
            <label class="control-label">Title of Adjustment</label>
          </div>
          <div class="col-lg-6">
            <input type="text" id="adjustmentTitle" name="adjustmentTitle" maxlength="38"
              [(ngModel)]="soAdjInterestOnDepositCondo.titleOnAdjustment" class="form-control" />
          </div>
        </div>
      </div>
      <fieldset *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositAdjustment()">
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Amount of deposit(s)</label>
            </div>
            <div class="col-lg-3">
              <input type="text" id="depositAmount" name="depositAmount" [ngModel]="depositAmount" class="form-control"
                [dp-read-only]="true" dp-currency />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3"></div>
            <div class="col-lg-9 vertical-align-label text-left">
              <label class="control-label text-left">Interest from date of respective deposits to</label>
            </div>

          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">date of</label>
            </div>
            <div class="col-lg-6">
              <select type="text" id="interestCalcType" name="interestCalcType"
                [(ngModel)]="soAdjInterestOnDepositCondo.depositsInterestFromDateType" class="form-control">
                <option *ngFor="let interestCalculationType of interestCalculationTypes"
                  [value]="interestCalculationType.value">
                  {{interestCalculationType.label}}
                </option>
              </select>
            </div>
            <div class="col-lg-3">
              <dp-partial-date #fromDate [disableAll]="true"
                [inputDate]="soAdjInterestOnDepositCondo.getUpdatedDateOfField(matter)">
              </dp-partial-date>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Deposit amount to be</label>
            </div>
            <div class="col-lg-6">
              <select type="text" id="depositAmountToBe" name="depositAmountToBe"
                [(ngModel)]="soAdjInterestOnDepositCondo.depositAmountToBe"
                (ngModelChange)="updateDepositAmount($event)" class="form-control">
                <option *ngFor="let depositAmountToBe of depositAmountToBeOptions" [value]="depositAmountToBe.value">
                  {{depositAmountToBe.label}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Amount of deposit(s)</label>
            </div>
            <div class="col-lg-3">
              <input type="text" id="depositAmountForOldCondo" name="depositAmountForOldCondo"
                [(ngModel)]="soAdjInterestOnDepositCondo.depositAmount"
                (ngModelChange)="updateInterestRows();setDefaultDescription()" class="form-control"
                [dp-read-only]="!isAmountOfDepositEditable()" dp-currency />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Interest calculation for period</label>
            </div>
            <div class="col-lg-4">
              <select type="text" id="interestCalculationPeriod" name="interestCalculationPeriod"
                [(ngModel)]="soAdjInterestOnDepositCondo.interestCalculationPeriod"
                (ngModelChange)="updateInterestCalculationPeriod($event)" class="form-control">
                <option *ngFor="let interestCalculationPeriod of interestCalculationPeriodOptions"
                  [value]="interestCalculationPeriod.value">
                  {{interestCalculationPeriod.label}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Interest to be calculated for period from</label>
            </div>
            <div class="col-lg-2">
              <dp-partial-date #fromDate [disableAll]="!isInterestCalculatedFromEditable()" [fullDateRequired]="true"
                (dateChange)="onInterestCalculatedFromDateChange($event, matter)"
                [inputDate]="soAdjInterestOnDepositCondo.interestCalculatedFromDate">
              </dp-partial-date>
            </div>
            <div class="col-lg-3">
              <div class="col-lg-1">
                <label class="control-label">to</label>
              </div>
              <div class="col-lg-8">
                <dp-partial-date #fromDate [disableAll]="!isInterestCalculatedToEditable()" [fullDateRequired]="true"
                  (dateChange)="onInterestCalculatedToDateChange($event ,matter)"
                  [inputDate]="soAdjInterestOnDepositCondo.interestCalculatedToDate">
                </dp-partial-date>
              </div>
            </div>
          </div>
        </div>

      </fieldset>

      <fieldset *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositInterestAdjustment()">
        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Unpaid interest on deposits</label>
            </div>
            <div class="col-lg-3">
              <input type="text" id="unpaidInterestOnDeposits" name="unpaidInterestOnDeposits"
                [ngModel]="depositsInterest" class="form-control" [dp-read-only]="true" dp-currency />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Interest calculation for period</label>
            </div>
            <div class="col-lg-4">
              <input type="text" id="calculationForPeriod" name="calculationForPeriod"
                value="From interim closing to adjustment date" class="form-control" [dp-read-only]="true" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-lg-12">
            <div class="col-lg-3 vertical-align-label">
              <label class="control-label">Interest to be calculation for period from</label>
            </div>
            <div class="col-lg-3">
              <dp-partial-date #fromDate [disableAll]="true"
                [inputDate]="soAdjInterestOnDepositCondo.getStartDateForInterestOnDepositsInterest(matter)">
              </dp-partial-date>
            </div>
            <div class="col-lg-1 vertical-align-label">
              <label class="control-label">to</label>
            </div>
            <div class="col-lg-3">
              <dp-partial-date #fromDate [disableAll]="true"
                [inputDate]="soAdjInterestOnDepositCondo.getEndDateForInterestOnDepositsInterest(matter)">
              </dp-partial-date>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <div class="col-lg-12">
          <div class="col-lg-3 vertical-align-label">
            <label class="control-label">Format for printing</label>
          </div>
          <div class="col-lg-3">
            <select type="text" id="printingFormat" name="printingFormat"
              [(ngModel)]="soAdjInterestOnDepositCondo.printingFormat" class="form-control">
              <option *ngFor="let printingFormatType of printingFormatTypes" [value]="printingFormatType.value">
                {{printingFormatType.label}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group" style="border: 1px solid #cccccc; margin-top: 15px;"
        *ngIf="!soAdjInterestOnDepositCondo.isPrintingFormatFinalAmount()">
        <div class="col-lg-12" *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
          <ng-container *ngFor="let interestRow of  interestRows ">
            <div class="display-block padding-5 semi-bold font-size-12"
              [ngClass]="{' padding-top-20': !!interestRow.header }">
              <span
                class="display-inline-block width-300">{{interestRow.text}}{{getFormattedHeaderAmount(interestRow.headerAmount)}}</span><span
                *ngIf="interestRow.amount">{{getFormattedCurrencyValue(interestRow.amount)}}</span>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-12" *ngIf="!soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
          <ng-container *ngFor="let interestRow of  generateInterestOnDepositForCondo() ">
            <div class="display-block padding-5 semi-bold font-size-12"
              [ngClass]="{' padding-top-20': !!interestRow.header }">
              <span
                class="display-inline-block width-300">{{interestRow.text}}{{getFormattedHeaderAmount(interestRow.headerAmount)}}</span><span
                *ngIf="interestRow.amount">{{getFormattedCurrencyValue(interestRow.amount)}}</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="form-group">
        <div class="col-lg-12">
          <div class="col-lg-3">
            <span class="actionlink">
              <span>
                <a href="javascript:void(0);" (click)="viewInterestRateTable();">
                  View Interest Rate Table
                </a>
              </span>
            </span>
          </div>
          <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Interest on Deposits</label>
          </div>
          <div class="col-lg-3" *ngIf="!soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
            <input type="text" id="interestOnDeposits"
              [fieldKey]="'matter.project.adjustment.interest.deposit.titleOnAdjustment'" dp-error-validator
              name="interestOnDeposits" [ngModel]="totalInterestOnDeposit" [dp-read-only]="true" class="form-control" />
          </div>
          <div class="col-lg-3" *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
            <input type="text" id="interestOnDepositsForOldCondo"
              [fieldKey]="'matter.project.adjustment.interest.deposit.titleOnAdjustment'" dp-error-validator
              name="interestOnDepositsForOldCondo"
              [ngModel]="getFormattedCurrencyValue(totalInterestOnDepositForOldCondo())" [dp-read-only]="true"
              class="form-control" />
          </div>
        </div>
      </div>
      <div class="row padding-top-10 padding-left-10" style="border-top: 1px solid #cccccc;">
        <div class="row form-group">
          <div class="col-lg-12">
            <label class="control-label">{{soAdjInterestOnDepositCondo.titleOnAdjustment}}</label>
          </div>
        </div>

        <div class="row form-group" *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
          <div *ngFor="let item of soAdjInterestOnDepositCondo.interestOnDepositCondoItems;let i = index;">
            <div class="col-lg-12 form-group">
              <div class="col-lg-1"></div>
              <div class="col-lg-5">
                <input type="text" id="item-heading-{{i}}" name="item-heading-{{i}}" maxlength="37"
                  [(ngModel)]="item.adjustmentDescription" [dp-read-only]="isAdjustmentDescriptionReadonly(i)"
                  class="form-control" />
                <div class="actionbtns" *ngIf="!isFirstAdjustmentDescription(item)">
                  <div class="width-20">
                    <span>
                      <i class="fa fa-trash" (click)="removeItem(item);" aria-hidden="true"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row form-group" *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositUnderOldCondoAdjustment()">
          <div class="col-lg-1"></div>
          <div class="col-lg-11">
            <span class="actionlink">
              <span>
                <a href="javascript:void(0);" (click)="addNewItem();">
                  Add another Item
                </a>
              </span>
              <span class="selector fa fa-plus-square-o"></span>
            </span>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-lg-2">
            <label class="control-label">Credit {{purchaserLabel}}</label>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-1">
            <label class="control-label padding-left-10">{{totalInterestOnDeposit}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row modal-footer">
      <div class="col-lg-10 buttons">
        <button type="button" id="okBtn" (click)="ok()" class="dp-btn keyboard-save-action">
          <span>OK</span>
        </button>
        <button type="button" (click)="close()" class="dp-btn dp-btn-cancel">
          <span>Cancel</span>
        </button>
      </div>
      <div class="col-lg-2" *ngIf="soAdjInterestOnDepositCondo.isInterestOnDepositAdjustment()">
        <dp-checkbox fieldId="checkBoxInfoOnly">
          <input type="checkbox" id="checkBoxInfoOnly" name="checkBoxInfoOnly"
            [(ngModel)]="soAdjInterestOnDepositCondo.infoOnly" [checked]="soAdjInterestOnDepositCondo.infoOnly" />
        </dp-checkbox>

        <label class="control-label vertical-align-label display-inline">Info Only</label>
      </div>
    </div>



    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
  </form>
</div>