import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../../shared';
import {TeranetDocket} from '../../../../shared-main/teranet/teranet-docket';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {
  BillingTransactionInfoResponse,
  TeranetViewDocketSummaryResponse
} from '../../../../shared-main/teranet/teranet-view-docket-summary';
import {DocumentProfileCache} from '../../../../shared-main/document-profile-cache.service';
import {ApplicationError, FieldError} from '../../../../core/application-error';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export class ViewTeranetDocketSummaryContext {
  teranetDocket: TeranetDocket;
  matter: Matter;
  docketSummary: TeranetViewDocketSummaryResponse[];
  startDate: Date;
  endDate: Date;
  documentProfileCache: DocumentProfileCache;
  unityCharges: boolean;
}

@FocusFirstElementDecorator()
@Component({
  selector: 'dp-view-teranet-docekt-summary-modal',
  templateUrl: 'view-teranet-docket-summary.modal.component.html',
  styleUrls: ['view-teranet-docket-summary.modal.css']
})
export class ViewTeranetDocketSummaryModalComponent
  extends ModalComponent<ViewTeranetDocketSummaryContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  today: Date;
  totalAmount: number = 0;
  totalHstAmount: number = 0;
  totalTaxType: string;
  hstRegNumber: string;
  tableData: any[];

  constructor(
    public dialog: MatDialogRef<ViewTeranetDocketSummaryModalComponent>,
    public teranetService: TeranetService,
    @Inject(MAT_DIALOG_DATA) context?: ViewTeranetDocketSummaryContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.today = new Date();
    if (this.context.unityCharges) {
      this.getUnityCharges();
    } else {
      this.tableData = this.context.docketSummary;
      for (let i = 0; i < this.tableData.length; i++) {
        this.totalAmount += this.tableData[i].amount;
        this.totalHstAmount += this.tableData[i].hstAmount;
      }
    }

    if (
      this.documentProfileCache &&
      this.documentProfileCache.cachedDocumentProfile &&
      this.documentProfileCache.cachedDocumentProfile.firmDocumentProfile
    ) {
      this.hstRegNumber = this.documentProfileCache.cachedDocumentProfile.firmDocumentProfile.hstRegNo;
    }
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get docketId(): string {
    return this.context.teranetDocket.teraviewDocketIdentifier;
  }

  get docketName(): string {
    return this.context.teranetDocket.teraviewDocketName;
  }

  get startDate(): Date {
    return this.context.startDate;
  }

  get endDate(): Date {
    return this.context.endDate;
  }

  get documentProfileCache(): DocumentProfileCache {
    return this.context.documentProfileCache;
  }

  get unityCharges(): boolean {
    return this.context.unityCharges;
  }

  getUnityCharges(): void {
    let billingTransactionInfoResponse: BillingTransactionInfoResponse[] = [];
    this.teranetService.viewBillingInformation(this.matter.id).subscribe(
      (res: BillingTransactionInfoResponse[]) => {
        this.tableData = res;
        for (let i = 0; i < this.tableData.length; i++) {
          this.totalAmount += this.tableData[i].amount;
          this.totalHstAmount += this.tableData[i].taxAmount;
          this.totalTaxType = this.tableData[i].taxType;
        }
      },
      (error: ApplicationError) => {
        this.handleApiError(error);
      }
    );
  }

  handleApiError(error: ApplicationError) {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((fieldError: FieldError) => {
        this.modalErrorComponent.createCustomDPFieldError(
          fieldError.errorCode,
          fieldError.errorCode + ':' + fieldError.message,
          null,
          'ERROR'
        );
      });
    } else {
      this.modalErrorComponent.createCustomDPFieldError(
        error.errorCode,
        error.errorCode + ' : ' + error.errorMessage,
        null,
        'ERROR'
      );
    }
  }

  close() {
    this.dialog.close();
  }

  print() {
    jQuery('#dpPrintBtnId').click();
    setTimeout(() => {
      jQuery('#okBtn').prop('disabled', false);
    }, 1000);
  }

  ngAfterViewInit() {}
}
