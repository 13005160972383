export const dropDowns = {

  categoryFieldOptions: [
    {label: 'Schedules', value: 'Forms'},
    {label: 'Standard Charge Terms', value: 'SCTForms'},
    {label: 'Other', value: 'OtherForms'},
    {label: 'Reports and IVF\'s', value: 'ReportsAndIVFs'},
    {label: 'Mortgage Forms', value: 'MortgageForms'},
    {label: 'Standard Charge Terms', value: 'StdChargeTerms'},
    {label: 'Standard Mortgage Forms', value: 'StdMortgageForms'},
    {label: 'Other', value: 'Other'}
  ],
  showFormsOptions: [
    {label: 'Produced Documents', value: 'PRODUCED_DOCUMENTS'}
  ],
  categorySorting: [ 'Forms', 'SCTForms', 'OtherForms', 'ReportsAndIVFs' ]

};

export const lenderFormsConnectLinks = {
  newFormLink: 'https://doprocess.zendesk.com/hc/en-ca/requests/new?ticket_form_id=38064'

};

export const categoryDescriptionMap = {
  'Forms': 'Schedules',
  'SCTForms': 'Standard Charge Terms',
  'OtherForms': 'Other',
  'ReportsAndIVFs': 'Reports and IVF\'s',
  'MortgageForms': 'Mortgage Forms',
  'StdMortgageForms': 'Standard Mortgage Forms',
  'StdChargeTerms': 'Standard Charge Terms',
  'Other': 'Other'

};

