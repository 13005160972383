import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Contact, ContactService, User} from '../../matters/shared';
import * as _ from 'lodash';
import {SelectItem} from 'primeng/api';
import {AutoComplete} from 'primeng/autocomplete';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';

@Component({
  selector: 'dp-responsible-staff',
  templateUrl: './responsible-staff.component.html',
  styleUrls: [
    './responsible-staff.styles.scss'
  ]
})
export class ResponsibleStaffComponent implements OnInit {
  @ViewChild('lawClerkAutoComplete') lawClerkAutoComplete: AutoComplete;
  @ViewChild('solicitorAutoComplete') solicitorAutoComplete: AutoComplete;

  @Input('readOnly') readOnly: boolean = false;
  @Input('user') user: User;
  @Input('staffProfile') staffProfile: StaffProfiles;
  @Input('accountId') accountId: string; // if opened in a system account should point to the account that gets edited not on system account id
  @Output() enableSave = new EventEmitter();

  activeContacts: Contact[] = [];

  lawClerkFieldKey: string = 'user.profile.responsible.solicitor';
  selectedLawClerk: string | Object;
  lawClerksDropDownList: SelectItem[] = [];

  solicitorFieldKey: string = 'user.profile.responsible.law.clerk';
  selectedSolicitor: string | Object;
  solicitorsDropDownList: SelectItem[] = [];

  constructor(
    public contactService: ContactService,
    public errorService: ErrorService,
    public staffProfilesService: StaffProfilesService) {
  }

  ngOnInit(): void {
    if (!this.accountId) {
      this.accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }
    this.getResponsibleStaff();
  }

  getResponsibleStaff(): any {
    this.staffProfilesService.getStaffProfileContact(this.accountId, true)
    .subscribe(
      (contacts: Contact[]) => {
        if (contacts) {
          this.activeContacts.push(...contacts.filter(item => item.isActive));
          this.initiateSolicitors(contacts.filter(contact => contact.isSolicitor));
          this.initiateLawClerks(contacts.filter(contact => contact.isLawClerk));
        }
      });
  }

  initiateSolicitors(contacts: Contact[]) {
    let dropDownList: any[] = [];
    dropDownList.push({
      label: '',
      value: null
    });
    dropDownList.push(...contacts.filter(item => item.isActive).map((contact) => {
      return {
        label: contact.contactName.surnameLastFullName,
        value: contact.id
      };
    }));

    dropDownList = _.sortBy(dropDownList, (item) => item.label.toLowerCase());
    this.solicitorsDropDownList = dropDownList;
    if (this.user.responsibleSolicitorId) {
      let solicitor = this.solicitorsDropDownList.find(item => item.value === this.user.responsibleSolicitorId);
      if (solicitor) {
        this.selectedSolicitor = solicitor.label;
      } else {
        let inactiveSolicitor = contacts.find(item => item.id === this.user.responsibleSolicitorId);
        if (inactiveSolicitor) {
          this.selectedSolicitor = inactiveSolicitor.contactName.surnameLastFullName;
        }
      }
      this.updateSolicitorError();
    } else if (this.staffProfile && this.staffProfile.contact && this.staffProfile.role === 'SOLICITOR' && !this.user.contactId) {
      let solicitor = this.solicitorsDropDownList.find(item => item.value === this.staffProfile.contact.id);
      if (solicitor) {
        this.onSelectSolicitor(solicitor);
      }
    }
  }

  initiateLawClerks(contacts: Contact[]) {
    let dropDownList: any[] = [];
    dropDownList.push({
      label: '',
      value: null
    });

    dropDownList.push(...contacts.filter(item => item.isActive).map((contact) => {
      return {
        label: contact.getFullNameForContact(false).trim(),
        value: contact.id
      };
    }));

    dropDownList = _.sortBy(dropDownList, (item) => item.label.toLowerCase());
    this.lawClerksDropDownList = dropDownList;
    if (this.user.responsibleLawClerkId) {
      let lawClerk = this.lawClerksDropDownList.find(item => item.value === this.user.responsibleLawClerkId);
      if (lawClerk) {
        this.selectedLawClerk = lawClerk.label;
      } else {
        let inactiveLawClerk = contacts.find(item => item.id === this.user.responsibleLawClerkId);
        if (inactiveLawClerk) {
          this.selectedLawClerk = inactiveLawClerk.getFullNameForContact(false).trim();
        }
      }
      this.updateMatterLawClerkError();
    } else if (this.staffProfile && this.staffProfile.contact && this.staffProfile.role === 'LAWCLERK' && !this.user.contactId) {
      let lawClerk = this.lawClerksDropDownList.find(item => item.value === this.staffProfile.contact.id);
      if (lawClerk) {
        this.onSelectSolicitor(lawClerk);
      }
    }
  }

  onSelectSolicitor(event) {
    if (event.value !== this.user.responsibleSolicitorId) {
      this.selectedSolicitor = event.label;
      this.onSolicitorChange(event.value);
    } else {
      setTimeout(() => {
        this.selectedSolicitor = event.label;
      }, 100);
    }
  }

  onSolicitorChange(contactId) {
    if (contactId) {
      this.user.responsibleSolicitorId = contactId;
    } else {
      this.selectedSolicitor = '';
      this.user.responsibleSolicitorId = null;
    }
    this.updateSolicitorError();
    this.enableSave.emit();
  }

  refreshSolicitorList = () => {
    this.solicitorsDropDownList = this.solicitorsDropDownList.slice();
  };

  displaySolicitorList(event: any) {
    event.stopImmediatePropagation();
    this.refreshSolicitorList();
    this.solicitorAutoComplete.show();
  }

  updateSolicitorError() {
    this.errorService.removeDpFieldError(this.solicitorFieldKey);
    if (this.user && this.user.responsibleSolicitorId && !this.activeContacts.find(contact => contact.id == this.user.responsibleSolicitorId)) {
      this.errorService.addDpFieldError(DPError.createCustomDPError(this.solicitorFieldKey, 'This firm member is inactive.', 'Responsible Person', 'WARNING'));
    }
  }

  onSelectLawClerk(event) {
    if (event.value !== this.user.responsibleLawClerkId) {
      this.selectedLawClerk = event.label;
      this.onLawClerkChange(event.value);
    } else {
      setTimeout(() => {
        this.selectedLawClerk = event.label;
      }, 100);
    }
  }

  onLawClerkChange(contactId) {
    if (contactId) {
      this.user.responsibleLawClerkId = contactId;
    } else {
      this.selectedLawClerk = '';
      this.user.responsibleLawClerkId = null;
    }
    this.updateMatterLawClerkError();
    this.enableSave.emit();
  }

  refreshLawClerkList = () => {
    this.lawClerksDropDownList = this.lawClerksDropDownList.slice();
  };

  displayLawClerkList(event: any) {
    event.stopImmediatePropagation();
    this.refreshLawClerkList();
    this.lawClerkAutoComplete.show();
  }

  updateMatterLawClerkError() {
    this.errorService.removeDpFieldError(this.lawClerkFieldKey);
    if (this.user && this.user.responsibleLawClerkId && !this.activeContacts.find(contact => contact.id == this.user.responsibleLawClerkId)) {
      this.errorService.addDpFieldError(DPError.createCustomDPError(this.lawClerkFieldKey, 'This firm member is inactive.', 'Responsible Person', 'WARNING'));
    }
  }
}
