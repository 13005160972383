import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {ProvinceCode} from '../accounts/shared/account-province';

export class StatementAdjustmentConfig extends BaseEntity {

  id: number;
  description: string;
  condition: string;
  applyByDefault: boolean;
  isSelected: boolean;
  showEditor: boolean = false;
  instanceType: string;
  provinceCode: ProvinceCode;
  projectFooterId: number;
  footerProgressionStatusAvailability: string;
}
