import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {AuthZService} from '../core/authz/auth-z.service';

@Directive({
  selector: '[user-access]'
})
export class UserAccessDirective implements AfterViewInit {

  @Input('user-access') data;

  constructor(private elementRef: ElementRef, private rendrer: Renderer2, private authZService: AuthZService) {
  }

  ngAfterViewInit() {
    if (this.data.operationName != null && this.data.permissionName != null) {
      if (!this.authZService.hasPermission(this.data.operationName, this.data.permissionName)) {
        this.hideElement();
      }

    } else if (this.data.operationName != null) {
      if (!this.authZService.hasAccess(this.data.operationName)) {
        this.hideElement();

      }

    }

  }

  hideElement() {
    this.rendrer.setStyle(this.elementRef.nativeElement, 'display', 'none');
  }

  setReadonly() {
    this.elementRef.nativeElement.attr.readonly = true;
  }

}
