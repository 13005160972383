import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {DpBooleanValue, DpBooleanValueTypes} from '../../matters/shared/dp-boolean';
import moment from 'moment';
import {ProjectCondoPlan} from './project-condo-plan';
import * as _ from 'lodash';

export const OccupancyFeesCalculatedBasedOn = {
  purchaseMonies: 'PURCHASE_MONIES',
  phantomMortgage: 'PHANTOM_MORTGAGE'
};

export class ProjectCondo extends BaseEntity {

  constructor(projectCondo?: ProjectCondo) {
    super(projectCondo);
    if (projectCondo) {
      if (projectCondo.declarationRegistrationDate) {
        this.declarationRegistrationDate = projectCondo.declarationRegistrationDate;
        this.formatedDeclarationRegistrationDate = moment(projectCondo.declarationRegistrationDate).format('YYYY/MM/DD');
      }
      if (projectCondo.customUnitTypes) {
        projectCondo.customUnitTypes.forEach((unitType) => {
          this.customUnitTypes.push(new CustomUnitType(unitType));
        });
      }
      if (projectCondo.projectCondoPlans) {
        projectCondo.projectCondoPlans.forEach((projectCondoPlan) => {
          this.projectCondoPlans.push(new ProjectCondoPlan(projectCondoPlan));
        });
      }
    } else {
      this.occupancyFeesCalculatedBasedOn = OccupancyFeesCalculatedBasedOn.purchaseMonies;
      this.interestRateOnPurchaseMoniesSameForTransactions = DpBooleanValueTypes.Y_n;
      this.interestRateChargeableOnPurchaseMonies = 0;
      //this.interestRateOnDeferredPurchaseMonies = 0;
      this.formatedDeclarationRegistrationDate = '';
    }

  }

  occupancyFeesCalculatedBasedOn: string;
  interestRateOnPurchaseMoniesSameForTransactions: string;
  interestRateChargeableOnPurchaseMonies: number;
  //interestRateOnDeferredPurchaseMonies: number;
  declarationRegistrationDate: Date;
  formatedDeclarationRegistrationDate: string;
  declarationRegistrationNo: string;
  customUnitTypes: CustomUnitType[] = [];
  location: string;
  projectCondoPlans: ProjectCondoPlan[] = [];
  exceptionType: DpBooleanValue;
  exceptionTypeDescription: string;

  get isOccupancyFeesCalculatedBasedOnPurchaseMonies(): boolean {
    return this.occupancyFeesCalculatedBasedOn == OccupancyFeesCalculatedBasedOn.purchaseMonies;
  }

  get isOccupancyFeesCalculatedBasedOnPhantomMortgage(): boolean {
    return this.occupancyFeesCalculatedBasedOn == OccupancyFeesCalculatedBasedOn.phantomMortgage;
  }

  get isInterestRateChargeableOnPurchaseMoniesYes(): boolean {
    return !(this.interestRateOnPurchaseMoniesSameForTransactions == DpBooleanValueTypes.NO);
  }

  get isInterestRateChargeableOnPurchaseMoniesNo(): boolean {
    return this.interestRateOnPurchaseMoniesSameForTransactions == DpBooleanValueTypes.NO;
  }

  get showInterestRateChargeableOnPurchaseMonies(): boolean {
    return this.isOccupancyFeesCalculatedBasedOnPurchaseMonies && this.isInterestRateChargeableOnPurchaseMoniesYes;
  }

  isCondominiumPlanEmpty(): boolean {
    return (!this.projectCondoPlans || this.projectCondoPlans && this.projectCondoPlans.length == 0);
  }

  removeCondominiumPlan(condominiumPlan: ProjectCondoPlan): void {
    if (condominiumPlan) {
      let condominiumPlanIndex: number = _.findIndex(this.projectCondoPlans, condoPlan => condoPlan === condominiumPlan);
      this.projectCondoPlans.splice(condominiumPlanIndex, 1);
    }
  }

  isExceptionForCondo(): boolean {
    return this.exceptionType == DpBooleanValueTypes.YES;
  }
}

export class CustomUnitType extends BaseEntity {
  unitType: string;
  defaultValue: boolean;
}
