<form class="form-horizontal container-fluid custom-modal-container" id="depositForm">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>Link {{context && context.isNotificationMessage ? 'Notifications' : 'Shared Documents'}} To Matter</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>






    <div class="row modal-footer">

        <div  class="col-lg-12 text-center">

            <ng-container>
                <div class="form-group col-lg-12">
                    <div class="col-lg-2">

                    </div>
                    <div class="col-lg-10 text-left">
                        <label class="control-label">Excluded: Closed, Inactive or assigned to a province user does not have access to.</label>
                    </div>
                </div>
                <div class="form-group col-lg-12 padding-bottom-20">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Matter</label>
                    </div>
                    <div class="col-lg-10" *ngIf="linkedMatter && linkedMatter.matterRecordNumber">
                        <div class="col-lg-11">
                        <input type="text" id="matter"
                               class="form-control"
                               [value]="(linkedMatter.matterType ? linkedMatter.matterType.charAt(0) : '')+'     '+(linkedMatter.matterRecordNumber)+'      '+
                               (linkedMatter.clientReLine ? linkedMatter.clientReLine : '')+'     '+(linkedMatter.provinceCode)+'      '+(getMatterPropertyAddress(linkedMatter.matterProperties))+'      '+(linkedMatter.matterCloseDate ? (linkedMatter.matterCloseDate | detaileddate) : '')"
                               dp-read-only="true"
                               autocomplete="off"
                        />
                        </div>
                        <div class="col-lg-1 padding-top-5 margin-left-minus-20">
                        <span class="trash-icon">
                                    <i class="fa fa-trash"
                                       (click)="removeSelectedMatter()"
                                       aria-hidden="true"
                                       tabindex="0"
                                       (keyup.enter)="removeSelectedMatter()"
                                    >
                                    </i>
                                </span>
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <p-autoComplete *ngIf="linkedMatter && !linkedMatter.matterRecordNumber"
                                        [placeholder]="'Start typing to search for desired Matter No., Client RE: Line, or Property Address.'"
                                        id="matterSearch"
                                        name="matterSearch"
                                        [(ngModel)]="matterSerachText"
                                        [class.autocomplete-searching]="matterSearchLoading"
                                        [suggestions]="matterList"
                                        (completeMethod)="search($event)"
                                        field="matter"
                                        [minLength]="0"
                                        [delay]="1000"
                                        maxlength="75"
                                        scrollHeight="500px"
                                        (onSelect)="dataSelectedMatter(matterSerachText , $event)"
                                        autocomplete="off" class="autocomplete-search-icon">
                            <ng-template let-matterData pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="matterData">
                                                <span class="col-lg-4 text-overflow"
                                                      *ngIf="autoCompleteEmptyFlag">{{ matterData.displayName }}</span>
                                    <div style="width:3%;display: inline-block">
                                    <span *ngIf="!autoCompleteEmptyFlag && matterData.locked" class="matter-lock-icon">
                                                    <i class="fa fa-lock"></i>
                                    </span>
                                    </div>
                                    <span class="text-overflow" style="width:2%;display: inline-block"
                                          *ngIf="!autoCompleteEmptyFlag">{{ matterData.matterType ? matterData.matterType.charAt(0) : ''}}</span>
                                    <span class="text-overflow" style="width:20%;display: inline-block"
                                          *ngIf="!autoCompleteEmptyFlag">{{ matterData.matterRecordNumber}}</span>
                                    <span class="text-overflow" style="width:20%;display: inline-block"
                                          *ngIf="!autoCompleteEmptyFlag">{{matterData.clientReLine}}</span>
                                    <span class="text-overflow" style="width:5%;display: inline-block"
                                          *ngIf="!autoCompleteEmptyFlag">{{matterData.provinceCode}}</span>
                                    <span class="text-overflow" style="width:40%;display: inline-block"
                                          *ngIf="!autoCompleteEmptyFlag">{{getMatterPropertyAddress(matterData.matterProperties)}}</span>
                                    <span class="text-overflow" style="width:10%;display: inline-block"
                                          *ngIf="!autoCompleteEmptyFlag" [innerHTML]="matterData.matterCloseDate | detaileddate"></span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
            </ng-container>

            <button class="dp-btn" type="button" [disabled]= "linkedMatter && !linkedMatter.matterRecordNumber"  (click)="linkMatter()">
                <span>Link To Matter</span>
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
   <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
