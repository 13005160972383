import {Matter} from "../../matter";
import {currentMatter} from "../../current-matter";
import {Component, EventEmitter, Input, Output} from "@angular/core";
import {PROVINCE_CODES} from "../../user-province";
import {TCOL_PLUS_PRODUCT_TYPE, TCOLDocumentCost} from "../../../../shared-main/tcol/tcol-document-cost";
import {TCOLService} from "../../../../shared-main/tcol/tcol-service";
import {TCOLMunicipality} from "../../../../shared-main/tcol/tcol-municipality";
import {TcolTaxInfo} from "../../../../shared-main/tcol/tcol-tax-info";
import {OrderLabel} from "../../../../shared-main/ltsa/ltsa-order";

@Component({
  selector: 'dp-tax-certificate',
  templateUrl: "./tax-certificate.component.html"
})
export class TaxCertificateComponent {
  hasTaxCertificate: boolean = true;
  taxInfo: TcolTaxInfo;

  get taxCertificate(): TCOLDocumentCost {
    return this.taxInfo?.documentCost;
  }
  @Input() set taxCertificate(value: TCOLDocumentCost) {
    this.taxInfo = new TcolTaxInfo();
    this.taxInfo.documentCost = value;
  }

  _pid: string;
  get pid(): string {
    return this._pid;
  }
  @Input() set pid(value: string) {
    this._pid = value;
    this.setTaxCertificate(value);
  }
  @Input() showActionButtons: boolean = true;
  @Input() groupId: number;
  @Input() displayDate: boolean = true;
  @Input() displayStatus: boolean = true;
  @Output() taxCertificateMunicipalityEvent = new EventEmitter<TCOLMunicipality>;
  @Output() hasTaxCertificateEvent = new EventEmitter<boolean>;

  constructor(private tcolService:TCOLService) {
  }

  addTaxCertificate() {
    this.hasTaxCertificate = true;
    this.hasTaxCertificateEvent.emit(true);
  }

  removeTaxCertificate() {
    this.hasTaxCertificate = false;
    this.hasTaxCertificateEvent.emit(false);
  }

  setTaxCertificate(pid: string) {
    if (this.matter?.isMatterProvinceBC && pid) {
      this.tcolService.getTcolByPidAndProvince(pid, PROVINCE_CODES.BRITISH_COLOMBIA)
        .subscribe(municipalities => {
          if (municipalities?.length > 0) {
            const municipality = new TCOLMunicipality(municipalities.pop());
            this.taxInfo = new TcolTaxInfo();
            this.taxInfo.documentCost = municipality.getProduct(TCOL_PLUS_PRODUCT_TYPE);
            this.taxCertificateMunicipalityEvent.emit(municipality);
            this.loadTaxCertificates();
          }
        });
    }
  }

  loadTaxCertificates() {
    this.tcolService.getSavedTaxCertificates(this.matter.id)
      .subscribe(res => {
        if (res?.length > 0) {
          res.filter(tc => tc.groupId === this.groupId)
            .forEach(tc => {
              this.taxInfo.createdTimeStamp = tc.createdTimeStamp;
              this.taxInfo.status = OrderLabel.ORDERED;
            })
        }
      });
  }

  isBC(): boolean {
    return this.matter?.isMatterProvinceBC;
  }

  get matter(): Matter {
    return currentMatter.value;
  }
}
