import {TitleInsuranceBaseUser} from '../title-insurance-base-user';

export class StewartTitleUser extends TitleInsuranceBaseUser {
  accessToken: string;
  refreshToken: string;

  constructor(s?: StewartTitleUser) {
    super(s);
  }
}
