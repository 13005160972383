import {ActivatedRouteSnapshot, RouteReuseStrategy} from '@angular/router';
import * as _ from 'lodash';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: {}): void {

  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): {} {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // future.routeConfig === curr.routeConfig Default check for this method
    // where they compare routes
    // In Addition to that we added params too so if we params are same they it can re use routes
    // but if routes are same but params are different then do not re use route
    return future.routeConfig === curr.routeConfig
      && ((_.isEmpty(future.params) && _.isEmpty(curr.params)) ||
        ((JSON.stringify(future.params) == JSON.stringify(curr.params)) || (!_.isEmpty(future.queryParams) &&
          !!future.queryParams[ 'doNotReload' ]) || (!_.isEmpty(curr.queryParams) &&
          !!curr.queryParams[ 'doNotReload' ])));

  }

}

