<form class="form-horizontal" (keydown.Enter)="onEnterKeyDown($event)" (keydown.Escape)="closeModal($event)">
    <div class="modal-header">
        <div class="col-md-12">
            <div class="col-md-10">
                <h1>Login to myLTSA</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="closeModal($event)">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group">
            <label class="control-label col-md-3 text-right">User Name *</label>
            <div class="col-md-6" *ngIf="!isMultipleAvailableLtsaUsers()">
                <input type="text"
                       name="userNameText"
                       id="userNameText"
                       class="form-control focus-first"
                       [(ngModel)]="ltsaCredential.myLtsaUserName"
                       class="form-control"
                       trimmed
                />
            </div>

            <div class="col-md-6" *ngIf="isMultipleAvailableLtsaUsers()">
                <select class="form-control"
                        id="userNameDropdown"
                        name="userNameDropdown"
                        [(ngModel)]="ltsaCredential.myLtsaUserName">
                    <option *ngFor="let ltsaUser of availableLtsaUsers"
                            [value]="ltsaUser.ltsaLoginName">
                        {{ltsaUser.ltsaLoginName}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-3 text-right">Password *</label>
            <div class="col-md-6">
                <input type="password"
                       name="ltsaPassword"
                       id="ltsaPassword"
                       [(ngModel)]="ltsaCredential.myLtsaUserPassword"
                       class="form-control"
                />
            </div>
            <div class="col-md-3 text-left padding-top-10">
            <span class="actionlink">
                <span>
                    <a role="link" tabindex="0" href="{{forgotUrl}}" target="_blank">
                        Forgot Password
                    </a>
                </span>
            </span>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="col-md-12 buttons">
            <button type="button"
                    id="validateBtn"
                    class="dp-btn"
                    (click)="validate()"
                    [disabled]="!enabledValidateBtn()"
                    tabindex="0">
                <span>Validate</span>
            </button>
            <button type="button" id="cancelBtn" class="dp-btn dp-btn-cancel" (keydown.Enter)="closeModal($event)" (click)="closeModal($event)" tabindex="0">
                <span>Cancel</span>
            </button>
        </div>
    </div>

    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>
