<!-- modal -->
<div class="form-horizontal custom-modal-container" *ngIf="itemizedCreditAdjustmentLocal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type: Itemized Credits to Vendor/Purchaser</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at
                        {{context.closingDate | date: 'MMMM dd, yyyy'}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Itemized credits to</label>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control" id="creditsTo" name="creditsTo"
                            [fieldKey]="'matter.itemized.soa.creditTo'" dp-error-validator
                            (change)="onChangeCreditsTo()" [(ngModel)]="itemizedCreditAdjustmentLocal.creditsTo">
                            <option *ngFor="let option of creditsToOptions" [value]="option.value">
                                {{option.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Adjustment type</label>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control" id="adjustmentType" name="adjustmentType"
                            [(ngModel)]="itemizedCreditAdjustmentLocal.adjustmentType">
                            <option *ngFor="let option of adjustmentTypeOptions" [value]="option.value">
                                {{option.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">{{getAutomaticallyInsertLabel()}}</label>
                    </div>
                    <div class="col-lg-2">
                        <select class="form-control" id="automaticallyInsertTotal" name="automaticallyInsertTotal"
                            (change)="onChangeAutomaticallyInsertTotal()"
                            [(ngModel)]="itemizedCreditAdjustmentLocal.automaticallyInsertTotal">
                            <option *ngFor="let option of automaticallyInsertTotalOptions" [value]="option.value">
                                {{option.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-6 text-left">

                        <p class="margin-bottom-10">You have modified this adjustment. Your modifications will be
                            applied in new (subsequently-opened)
                            sale records.</p>
                        <p>NOTE HOWEVER THAT YOUR MODIFICATIONS WILL NOT AFFECT SALE RECORDS WHICH ALREADY APPLY THE
                            ADJUSTMENT IN ITS PREVIOUS FORM. SHOULD
                            YOU WISH TO UPDATE THIS ADJUSTMENT IN SUCH RECORDS, YOU MUST EITHER OPEN THE RECORDS AND
                            MAKE THE NECESSARY CHANGES THEREIN, OR YOU
                            CAN USE THE "MASS UPDATE" FEATURE.</p>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Adjustment heading</label>
                    </div>
                    <div class="col-lg-8">
                        <input type="text" maxlength="38" id="adjustmentHeading"
                            [fieldKey]="'matter.project.adjustment.itemized.credit.adjustmentHeading'"
                            dp-error-validator name="adjustmentHeading"
                            [(ngModel)]="itemizedCreditAdjustmentLocal.adjustmentHeading" class="form-control" />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Divide total</label>
                    </div>
                    <div class="col-lg-8">
                        <select class="form-control" id="divideTotal" name="divideTotal"
                            [(ngModel)]="itemizedCreditAdjustmentLocal.divideTotal">
                            <option *ngFor="let option of divideTotalOptions" [value]="option.value">
                                {{option.label}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row" *ngFor="let creditItem of itemizedCreditAdjustmentLocal.creditItems; let i=index">
                    <div class="form-group">
                        <div class="col-lg-3 vertical-align-label">
                            <label class="control-label">Item {{i + 1}}</label>
                        </div>
                        <div class="col-lg-3">
                            <input type="text" id="itemName-{{i+currentTimeStamp}}" maxlength="38"
                                name="itemName-{{i+currentTimeStamp}}" class="form-control"
                                [(ngModel)]="creditItem.description" />
                            <div class="actionbtns">
                                <div class="width-20"><span class="position-relative top-4">
                                        <i class="fa fa-trash" (click)="removeCreditItem(creditItem)"
                                            aria-hidden="true"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">Amount</label>
                        </div>
                        <div class="col-lg-3">
                            <input type="text" id="itemAmount-{{i+currentTimeStamp}}"
                                name="itemAmount-{{i+currentTimeStamp}}" class="form-control" style="text-align:right;"
                                [dp-read-only]="!creditItem.description" [(ngModel)]="creditItem.amount"
                                allow-negative="true" allow-blank="true" dp-currency />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-4"><span class="actionlink">
                            <span>
                                <a href="javascript:void(0);" (click)="addNewCredit()" id="addAnotherCredit"
                                    tabindex="0">Add another item</a>
                            </span>
                            <span class="selector fa fa-plus-square-o"></span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-top-10 padding-left-5  modal-separator-line">
            <div class="col-lg-12 form-group">
                <div class="col-lg-6">
                    <label class="control-label dp-uppercase">{{itemizedCreditAdjustmentLocal.adjustmentHeading}}
                    </label>
                </div>
            </div>
            <ng-container *ngFor="let creditItem of itemizedCreditAdjustmentLocal.creditItems; let i=index">
                <div class="col-lg-12 form-group" *ngIf="creditItem.description">
                    <div class="col-lg-3">
                        <label class="control-label">{{creditItem.description}}</label>
                    </div>
                    <div class="col-lg-3  text-right">
                        <label class="control-label">{{creditItem.amount | currency:CAD:'symbol'}}</label>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="itemizedCreditAdjustmentLocal.divideTotal != 'NO'">
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <label class="control-label">Total Credits</label>
                    </div>
                    <div class="col-lg-3  text-right">
                        <label class="control-label">{{itemizedCreditAdjustmentLocal.total() |
                            currency:CAD:'symbol'}}</label>
                    </div>
                </div>
                <div class="col-lg-12 form-group">
                    <div class="col-lg-3">
                        <label class="control-label">{{getLessHstComponentLabel()}}</label>
                    </div>
                    <div class="col-lg-3  text-right">
                        <label class="control-label">{{getLessHstComponentAmount()}}</label>
                    </div>
                </div>
            </ng-container>

            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label">{{creditNoteHeading}}</label>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3  text-right"
                    *ngIf="itemizedCreditAdjustmentLocal.adjustmentType == 'CREDIT_TO_PARTY'">
                    <label
                        class="control-label">{{itemizedCreditAdjustmentLocal.totalNetSalePrice(federalHstRate,provinceHstRate)
                        | currency:CAD:'symbol'}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="row padding-top-20">
            <div class="col-lg-12 text-center margin-bottom-10">
                <button type="button" class="dp-btn keyboard-save-action" (click)="ok()" id="okBtn">
                    <span>OK</span>
                </button>

                <button type="button" class="dp-btn dp-btn-cancel" (click)="cancel()">
                    <span>Cancel</span>
                </button>

                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>
            </div>
            <div class="margin-right-10 right-0">
                <dp-checkbox fieldId="checkBoxInfoOnly">
                    <input type="checkbox" id="checkBoxInfoOnly" name="checkBoxInfoOnly"
                        [(ngModel)]="itemizedCreditAdjustmentLocal.infoOnly"
                        [checked]="itemizedCreditAdjustmentLocal.infoOnly" />
                </dp-checkbox>
                <label for="checkBoxInfoOnly" class="control-label display-inline">Info only?</label>
            </div>
        </div>

    </div>
    <div class="margin-left-5 margin-right-minus-5">
        <dp-modal-error #modalErrorComponent></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </div>
</div>