import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {HttpClient} from '../../core';
import {Observable} from 'rxjs/Rx';
import {thirdPartyIntegrationApi} from './third-party-integration-constants';
import {AccountIntegrationCredential} from '../../admin/account-integeration/account-integration-credential';
import {AccountIntegrationUser} from '../../admin/account-integeration/account-integration-user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {SyngrafiiPackageType} from '../../event/signing-envelope';
import {SyngrafiiAuthorizationUrl} from '../../admin/account-integeration/syngrafii-authorization-url';

@Injectable()
export class ThirdPartyIntegrationService {

  constructor(private http: HttpClient, private logger: Logger) {
  }

  getZoomAuthorizationUrl(): Observable<string> {
    let url = thirdPartyIntegrationApi.zoomAuthorizationUrl();
    return this.http.get(url)
    .map((res) => {
      return res[ 'ZoomAuthorizationUrl' ];
    });
  }

  getMicrosoftAuthorizationUrl(): Observable<string> {
    let url = thirdPartyIntegrationApi.microsoftAuthorizationUrl();
    return this.http.get(url)
    .map((res) => {
      return res[ 'MicrosoftAuthorizationUrl' ];
    });
  }

  getGoogleAuthorizationUrl(): Observable<string> {
    let url = thirdPartyIntegrationApi.googleAuthorizationUrl();
    return this.http.get(url)
    .map((res) => {
      return res[ 'GoogleAuthorizationUrl' ];
    });

  }

  getDocuSignAuthorizationUrl(): Observable<string> {
    let url = thirdPartyIntegrationApi.docuSignAuthorizationUrl();
    return this.http.get(url)
    .map((res) => {
      return res[ 'DocuSignAuthorizationUrl' ];
    });

  }

  getSyngrafiiAuthorizationUrl(): Observable<SyngrafiiAuthorizationUrl> {
    let url = thirdPartyIntegrationApi.syngrafiiAuthorizationUrl();
    return this.http.get(url)
    .map((res) => {
      return new SyngrafiiAuthorizationUrl(res[ 'SyngrafiiAuthorizationUrl' ]);
    });

  }

  requestZoomAccessToken(tempAuthorizationCode: string, accountId: number, accountIntegrationCredentialId: string): Observable<AccountIntegrationCredential> {
    let url: string = thirdPartyIntegrationApi.requestZoomAccessToken(accountId);
    if (accountIntegrationCredentialId) {
      url += '?accountIntegrationCredentialId=' + accountIntegrationCredentialId;
    }
    return this.http.post(url, tempAuthorizationCode)
    .map((res) => {
      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  requestMicrosoftAccessToken(tempAuthorizationCode: string, accountId: number): Observable<AccountIntegrationCredential> {
    let url: string = thirdPartyIntegrationApi.requestMicrosoftAccessToken(accountId);
    return this.http.post(url, tempAuthorizationCode)
    .map((res) => {
      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  requestGoogleAccessToken(tempAuthorizationCode: string, accountId: number): Observable<AccountIntegrationCredential> {
    let url: string = thirdPartyIntegrationApi.requestGoogleAccessToken(accountId);
    return this.http.post(url, tempAuthorizationCode)
    .map((res) => {
      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  requestDocuSignAccessToken(tempAuthorizationCode: string, accountId: number): Observable<AccountIntegrationCredential> {
    let url: string = thirdPartyIntegrationApi.requestDocuSignAccessToken(accountId);
    return this.http.post(url, tempAuthorizationCode)
    .map((res) => {
      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  requestSyngrafiiAccessToken(tempAuthorizationCode: string, accountId: number, accountIntegrationCredentialId: string): Observable<AccountIntegrationCredential> {
    let url: string = thirdPartyIntegrationApi.requestSyngrafiiAccessToken(accountId);
    if (accountIntegrationCredentialId) {
      url += '?accountIntegrationCredentialId=' + accountIntegrationCredentialId;
    }
    return this.http.post(url, tempAuthorizationCode)
    .map((res) => {
      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  getAccountIntegrationCredentials(integrationType: string, accountId: number): Observable<AccountIntegrationCredential[]> {
    let url: string = thirdPartyIntegrationApi.getAccountIntegrationCredentials(integrationType, accountId);
    return this.http.get(url)
    .map((res) => {
      let data = res[ 'AccountIntegrationCredential' ];
      let credentials: AccountIntegrationCredential[] = [];
      if (data) {
        credentials.push(...data.map(item => {
          return new AccountIntegrationCredential(item);
        }));
      }
      return credentials;
    });
  }

  deleteAccountIntegrationCredential(userCredential: AccountIntegrationCredential, accountId: number): Observable<any> {
    let url: string = thirdPartyIntegrationApi.deleteAccountIntegrationCredential(userCredential.integrationType, accountId, userCredential.id);
    return this.http.delete(url);
  }

  updateSyngrafiiDefaultPackageType(accountId: number, packageType: SyngrafiiPackageType, accountIntegrationCredentialId: number): Observable<any> {
    let url: string = thirdPartyIntegrationApi.updateSyngrafiiDefaultPackageType(accountId, packageType);
    if (accountIntegrationCredentialId) {
      url += '?accountIntegrationCredentialId=' + accountIntegrationCredentialId;
    }
    return this.http.put(url, null)
    .map((res) => {

      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  updateAccountIntegrationCredential(integrationType: string, accountId: number, userCredential: AccountIntegrationCredential): Observable<any> {
    let url: string = thirdPartyIntegrationApi.updateAccountIntegrationCredential(integrationType, accountId, userCredential.id.toString());
    return this.http.put(url, userCredential)
    .map((res) => {
      return new AccountIntegrationCredential(res[ 'AccountIntegrationCredential' ]);
    });
  }

  getUsers(integrationType: string, accountId?: string): Observable<AccountIntegrationUser[]> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }

    let url: string = thirdPartyIntegrationApi.getUsers(integrationType, accountId);
    return this.http.get(url)
    .map((res) => {
      let data = res[ 'AccountIntegrationUsers' ];
      let users: AccountIntegrationUser[] = [];
      if (data) {
        users.push(...data.map(item => {
          return new AccountIntegrationUser(item);
        }));
      }
      return users;
    });
  }

  getUsersByAccessGrant(integrationType: string, accessGrantId: number, accountId?: string): Observable<AccountIntegrationUser[]> {
    if (!accountId) {
      accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    }

    let url: string = thirdPartyIntegrationApi.getUsersByAccessGrant(integrationType, accountId, accessGrantId.toString());
    return this.http.get(url)
    .map((res) => {
      let data = res[ 'AccountIntegrationUsers' ];
      let users: AccountIntegrationUser[] = [];
      if (data) {
        users.push(...data.map(item => {
          return new AccountIntegrationUser(item);
        }));
      }
      return users;
    });
  }
}
