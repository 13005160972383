import {BaseEntity} from '../../../shared/BaseEntity/base-entity';

export class TrustLedgerItemize extends BaseEntity {

  id: number;
  itemName: string;
  itemAmount: number;
  readOnly: boolean;

  constructor(trustLedgerItemize?: TrustLedgerItemize) {
    super(trustLedgerItemize);
  }

  isItemNameAdded(): boolean {
    return (this.itemName != null && this.itemName != undefined && this.itemName.trim() != undefined);
  }
}
