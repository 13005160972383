import {Injectable} from '@angular/core';
import {AuthenticatedUser} from '../authenticated-user';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {Authority} from '../authority';
import * as _ from 'lodash';
import {User} from '../../matters/shared/user';
import {UUIDUtil} from '../../main/uuid-util';
import {UUIDService} from '../../main/uuid.service';
import {ReferenceDataService} from '../../shared-main/reference-data/reference-data.service';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {UserConfigurationService} from '../../shared-main/user-configuration.service';
import {Router, RouterStateSnapshot} from '@angular/router';
import {Permissions} from './permissions';
import {Operations} from './operations';
import {Roles} from './roles';
import {FeatureService} from '../../feature-service';

@Injectable()
export class AuthZService {
  private _authenticatedUser: AuthenticatedUser = new AuthenticatedUser(
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.authenticatedUser))
  );

  constructor(
    public router: Router,
    private userStateService: UserStateService,
    private userConfigurationService: UserConfigurationService,
    private featureService: FeatureService
  ) {}

  private stateUrl: string = '';

  setStateUrl(state: RouterStateSnapshot): void {
    this.stateUrl = state.url;
  }

  get authenticatedUser(): AuthenticatedUser {
    if (!this._authenticatedUser || this._authenticatedUser.grantedAuthorities.length === 0) {
      if (this.featureService.isSSOEnabled) {
        this._authenticatedUser = new AuthenticatedUser(JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.user)));
      } else {
        this._authenticatedUser = new AuthenticatedUser(
          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.authenticatedUser))
        );
      }
    }
    return this._authenticatedUser;
  }

  set authenticatedUser(value: AuthenticatedUser) {
    this._authenticatedUser = value;
  }

  /**
   * This method checks that logged in user has access to the operation. Access can be of any type read, write etc
   * User role & authorities are cached so if multiple user login on same browser instance then they will be stale.
   * @param operationEnum
   * @returns {boolean}
   */
  hasAccess(operationEnum: Permissions): boolean {
    let authority: Authority;
    const operationVal = Permissions[operationEnum];
    if (this.authenticatedUser) {
      authority = _.find(this.authenticatedUser.grantedAuthorities, function (authority: Authority) {
        return authority.permission.indexOf(String(operationVal)) > -1;
      });
    }
    return !!authority;
  }

  isMatterReadOnlyAccess(): boolean {
    return !this.hasPermission(Permissions.MATTERS, Operations.CREATE);
  }

  isProjectReadOnlyAccess(): boolean {
    return !this.hasPermission(Permissions.MANAGE_PROJECTS, Operations.CREATE);
  }

  hasReadAccessToConveyancingMatter(): boolean {
    return this.hasReadAccess(Permissions.CONVEYANCING);
  }

  hasFullAccessToConveyancingMatters(): boolean {
    return (
      this.hasReadAccess(Permissions.CONVEYANCING) &&
      this.hasCreateAccess(Permissions.CONVEYANCING) &&
      this.hasUpdateAccess(Permissions.CONVEYANCING)
    );
  }

  hasReadOrFullAccessToProjectMatter(): boolean {
    return this.hasReadAccessToProjectMatters() || this.hasFullAccessToProjectMatters();
  }

  hasReadAccessToProjectMatters(): boolean {
    return this.hasReadAccess(Permissions.PROJECT_MATTERS);
  }

  hasFullAccessToProjectMatters(): boolean {
    return (
      this.hasReadAccess(Permissions.PROJECT_MATTERS) &&
      this.hasCreateAccess(Permissions.PROJECT_MATTERS) &&
      this.hasUpdateAccess(Permissions.PROJECT_MATTERS)
    );
  }

  isContactReadOnlyAccess(): boolean {
    return !(
      this.hasPermission(Permissions.CONTACT_MANAGEMENT, Operations.CREATE) ||
      this.hasPermission(Permissions.MANAGE_GLOBAL_CONTACTS, Operations.CREATE)
    );
  }

  isEventReadOnlyAccess(): boolean {
    return !this.hasPermission(Permissions.EVENTS, Operations.CREATE);
  }

  hasFullAccessToEvents(): boolean {
    return this.hasPermission(Permissions.EVENTS, Operations.CREATE);
  }

  hasFullAccessToProjects(): boolean {
    return this.hasPermission(Permissions.MANAGE_PROJECTS, Operations.CREATE);
  }

  hasCreateAccess(authOperation: Permissions): boolean {
    return this.hasPermission(authOperation, Operations.CREATE);
  }

  hasUpdateAccess(authOperation: Permissions): boolean {
    return this.hasPermission(authOperation, Operations.UPDATE);
  }

  hasReadAccess(authOperation: Permissions): boolean {
    return this.hasPermission(authOperation, Operations.READ);
  }

  /**
   * This method checks that authenticated user has the given role.
   * User role & authorities are cached so if multiple user login on same browser instance then they will be stale.
   * @param roleEnum
   * @returns {boolean}
   */
  hasRole(roleEnum: Roles): boolean {
    const roleVal = Roles[roleEnum];
    return !!(
      this.authenticatedUser &&
      this.authenticatedUser.role &&
      this.authenticatedUser.role.find((item) => item === roleVal)
    );
  }

  /**
   * This method checks that logged in user has given permission on the operation
   * @param operationEnum
   * @param permissionEnum
   * @returns {boolean}
   */
  hasPermission(permissionEnum: Permissions, operationEnum: Operations): boolean {
    let authority: Authority;
    const operationVal = Operations[operationEnum];
    const permissionVal = Permissions[permissionEnum];
    if (this.authenticatedUser) {
      authority = _.find(this.authenticatedUser.grantedAuthorities, function (authority: Authority) {
        return authority.permission.indexOf(String(permissionVal)) > -1 && operationVal === authority.operation;
      });
    }
    return !!authority;
  }

  /**
   * This method checks that logged in user has given  "Full Access" to the "Chicago Title Integration" Permission Set on the operation
   * @returns {boolean}
   */
  hasChicagoTitleIntegrationFullAccess(): boolean {
    return (
      this.hasPermission(Permissions.CHICAGO_TITLE_INTEGRATIONS, Operations.CREATE) &&
      this.hasPermission(Permissions.CHICAGO_TITLE_INTEGRATIONS, Operations.READ) &&
      this.hasPermission(Permissions.CHICAGO_TITLE_INTEGRATIONS, Operations.UPDATE) &&
      this.hasPermission(Permissions.CHICAGO_TITLE_INTEGRATIONS, Operations.DELETE)
    );
  }

  hasTitlePlusIntegrationFullAccess(): boolean {
    return (
      this.hasPermission(Permissions.TITLE_PLUS_INTEGRATIONS, Operations.CREATE) &&
      this.hasPermission(Permissions.TITLE_PLUS_INTEGRATIONS, Operations.READ) &&
      this.hasPermission(Permissions.TITLE_PLUS_INTEGRATIONS, Operations.UPDATE) &&
      this.hasPermission(Permissions.TITLE_PLUS_INTEGRATIONS, Operations.DELETE)
    );
  }

  hasManageDataMoveUtilityFullAccess(): boolean {
    return (
      this.hasPermission(Permissions.MANAGE_DATA_MOVE_UTILITY, Operations.CREATE) &&
      this.hasPermission(Permissions.MANAGE_DATA_MOVE_UTILITY, Operations.READ) &&
      this.hasPermission(Permissions.MANAGE_DATA_MOVE_UTILITY, Operations.UPDATE) &&
      this.hasPermission(Permissions.MANAGE_DATA_MOVE_UTILITY, Operations.DELETE)
    );
  }

  /**
   * This method checks that logged in user has given  "Full Access" to the "Fct Integration" Permission Set on the operation
   * @returns {boolean}
   */
  hasFctTitleIntegrationFullAccess(): boolean {
    return (
      this.hasPermission(Permissions.FCT_INTEGRATIONS, Operations.CREATE) &&
      this.hasPermission(Permissions.FCT_INTEGRATIONS, Operations.READ) &&
      this.hasPermission(Permissions.FCT_INTEGRATIONS, Operations.UPDATE) &&
      this.hasPermission(Permissions.FCT_INTEGRATIONS, Operations.DELETE)
    );
  }

  removeAuthenticatedUser(): void {
    if (this.authenticatedUser) {
      this.authenticatedUser = null;
    }
  }

  getDefaultSelectedLandingPageUrl(selectedLandingPage: string) {
    if (this.hasAccess(Permissions.EVENTS) && selectedLandingPage === 'EVENTS') {
      return '/main/events/list';
    }
    if (this.hasAccess(Permissions.MATTERS) && selectedLandingPage === 'MATTER') {
      return '/main/tabs/matters';
    }
    if (this.hasAccess(Permissions.EVENTS) && selectedLandingPage === 'DAYATGLANCE') {
      return '/main/events/dayAtGlance';
    }
  }

  async navigateToGetRedirectUrl(): Promise<void> {
    if (this.stateUrl && this.hasAccess(Permissions.MATTERS) && this.stateUrl.includes('/main/tabs/matters')) {
      await this.router.navigate(['/main/tabs/matters']);
    } else {
      let url = await this.getRedirectUrl();
      await this.router.navigate([url]);
    }
  }

  async getRedirectUrl(): Promise<string> {
    if (this.checkForUserMustReadMessages()) {
      return '/main/messages/list';
    } else if (this.hasRole(Roles.ROLE_SYSTEM_ADMINISTRATOR)) {
      return '/main/admin/list';
    } else if (this.hasRole(Roles.ROLE_SYSTEM_USER)) {
      let userConfig = await this.userConfigurationService.getUserConfiguration().toPromise();
      if (userConfig.configValues.selectedLandingPage) {
        return this.getDefaultSelectedLandingPageUrl(userConfig.configValues.selectedLandingPage);
      }
      if (
        this.hasAccess(Permissions.ACCOUNT_LIST_VIEW) ||
        this.hasAccess(Permissions.GENERAL_ACCOUNT_MAINTENANCE) ||
        this.hasAccess(Permissions.CUSTOMER_ACCOUNT_LIST)
      ) {
        return '/main/admin/list';
      }
      if (this.hasAccess(Permissions.CONTACT_MANAGEMENT)) {
        return '/main/contacts/list';
      }
      if (this.hasAccess(Permissions.FORMS)) {
        return '/main/doc-services';
      }
    } else {
      let userConfig = await this.userConfigurationService.getUserConfiguration().toPromise();
      if (userConfig.configValues.selectedLandingPage) {
        if (userConfig.configValues.selectedLandingPage === 'EVENTS') {
          return '/main/events/list';
        }
        if (userConfig.configValues.selectedLandingPage === 'MATTER') {
          return '/main/tabs/matters';
        }
        if (userConfig.configValues.selectedLandingPage === 'DAYATGLANCE') {
          return '/main/events/dayAtGlance';
        }
      }
    }
    return '/main';
  }

  checkForUserMustReadMessages(): boolean {
    let navigateToMessages = false;
    let sessionUser: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
    if (sessionUser && sessionUser != null) {
      let authenticatedUser = new User(JSON.parse(sessionUser));
      navigateToMessages = authenticatedUser.mustReadMessageExist;
    }
    return navigateToMessages;
  }

  initializationAfterLogin(referenceDataService: ReferenceDataService, uuidService: UUIDService): void {
    //This is a one-time initialization to inject the uuidService into UUIDUtil at login.
    UUIDUtil.setService(uuidService);
    UUIDUtil.requestUUIDs();
    referenceDataService.initializeReferenceData();
  }

  userHasAccessToOntarioFeatures(): boolean {
    return this.userStateService.getEnabledUserProvinceCodes().indexOf('ON') > -1;
  }

  /**
   * This method checks that logged in user has given  "Full Access" to the "Chicago Title Integration" Permission Set on the operation
   * @returns {boolean}
   */
  hasFieldCodeCreateAndUpdateAccess(): boolean {
    return (
      this.hasPermission(Permissions.FIELD_CODE_UI_MAPPING, Operations.CREATE) &&
      this.hasPermission(Permissions.FIELD_CODE_UI_MAPPING, Operations.UPDATE)
    );
  }

  hasAdminAccess(): boolean {
    if (this.hasRole(Roles.ROLE_SYSTEM_USER)) {
      return false;
    }
    if (this.hasRole(Roles.ROLE_SYSTEM_ADMINISTRATOR)) {
      return this.hasAccess(Permissions.DOPROCESS_ACCOUNT_MANAGEMENT);
    } else {
      return this.hasAccess(Permissions.CONFIGURE_TERAVIEW) || this.hasAccess(Permissions.GENERAL_ACCOUNT_MAINTENANCE);
    }
  }

  //TEMPLATE_AND_CATEGORY_MANAGEMENT either has No Access OR Full Access
  hasTemplateAndCategoryManagementFullAccess(): boolean {
    return (
      this.hasPermission(Permissions.TEMPLATE_AND_CATEGORY_MANAGEMENT, Operations.CREATE) &&
      this.hasPermission(Permissions.TEMPLATE_AND_CATEGORY_MANAGEMENT, Operations.READ) &&
      this.hasPermission(Permissions.TEMPLATE_AND_CATEGORY_MANAGEMENT, Operations.UPDATE) &&
      this.hasPermission(Permissions.TEMPLATE_AND_CATEGORY_MANAGEMENT, Operations.DELETE)
    );
  }

  hasFullAccessToOpportunities(): boolean {
    return (
      this.hasReadAccess(Permissions.OPPORTUNITIES) &&
      this.hasCreateAccess(Permissions.OPPORTUNITIES) &&
      this.hasUpdateAccess(Permissions.OPPORTUNITIES)
    );
  }

  isOpportunitiesReadOnlyAccess(): boolean {
    return !this.hasPermission(Permissions.OPPORTUNITIES, Operations.CREATE);
  }
}
