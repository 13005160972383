<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="localAdjustment" >
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : {{adjustmentType}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">{{creditToLabel}}</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="creditTargetType"
                            name="creditTargetType"
                            [(ngModel)]="localAdjustment.creditType"
                            class="form-control"
                    >
                        <option *ngFor="let creditTargetType of creditTargetTypes" [value]="creditTargetType.value">
                            {{creditTargetType.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Adjustment heading</label>
                </div>
                <div class="col-lg-3">
                    <input type="text"
                           name="adjustmentHeading"
                           id="adjustmentHeading"
                           maxlength="38"
                           [(ngModel)]="localAdjustment.adjustmentHeading"
                           dp-uppercase
                           trimmed
                           class="form-control"
                           dp-error-validator
                           [fieldKey]="'matter.soadj.other.fixed.description'"
                    />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Credit Amount</label>
                </div>
            </div>
        </div>

        <div class="form-group" >
            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Percent</label>
                    </div>
                    <div class="col-lg-1">
                        <input type="text"
                               id="percent"
                               name="percent"
                               [(ngModel)]="localAdjustment.percent"
                               class="form-control"
                               max-decimals = 4
                               dp-percentage
                        />
                    </div>
                    <div class="col-lg-1">
                        <label class="control-label text-left width-70-percent">%</label>
                        <label class="control-label text-left">of</label>
                    </div>
                    <div class="col-lg-2">
                        <select type="text"
                                id="percentOf"
                                name="percentOf"
                                [(ngModel)]="localAdjustment.percentOf"
                                class="form-control"
                        >
                            <option *ngFor="let percentOf of percentOfOptions" [value]="percentOf.value">
                                {{percentOf.label}}
                            </option>
                        </select>
                    </div>

                    <div class="col-lg-1 vertical-align-label">
                        <label class="control-label">to a max. of</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                               id="percentToMaxOf"
                               name="percentToMaxOf"
                               [(ngModel)]="localAdjustment.percentToMaxOf"
                               class="form-control width-88-percent display-inline-block"
                               dp-currency
                        />
                        <div class="display-inline-block padding-left-15">:</div>
                    </div>
                    <div class="col-lg-2">
                        <input type="text"
                               id="creditAmount"
                               name="creditAmount"
                               [(ngModel)]="localAdjustment.creditAmount"
                               class="form-control"
                               dp-read-only="true"
                               dp-currency
                        />
                    </div>


                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-12">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label" [dp-province-field-label]="'matter.soa.other.percentageOfPurchasePrice.adjustFor'"></label>
                    </div>
                    <div *ngIf="!isMatterProvinceAB()">
                        <div class="col-lg-1">
                            <select type="text"
                                    id="taxType"
                                    name="taxType"
                                    [(ngModel)]="localAdjustment.taxType"
                                    class="form-control"
                            >
                                <option *ngFor="let taxType of taxTypes" [value]="taxType.value">
                                    {{taxType.label}}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">?</label>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyTax"
                                name="applyTax"
                                [(ngModel)]="localAdjustment.applyTax"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>
                    <ng-container *ngIf="displayTaxFields()">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">{{localAdjustment.taxType}} percentage is</label>
                        </div>
                        <div class="col-lg-1" *ngIf="context.soaConsiderationTaxes && localAdjustment.taxType == context.soaConsiderationTaxes.rateType">
                            <input type="text"
                                   id="hstPercentage"
                                   name="hstPercentage"
                                   [(ngModel)]="localAdjustment.hstRate"
                                   class="form-control"
                                   dp-percentage
                            />
                        </div>
                        <div class="col-lg-1" *ngIf="localAdjustment.taxType == 'GST'  && context.defaultPstRate">
                            <input type="text"
                                   id="gstPercentage"
                                   name="gstPercentage"
                                   [(ngModel)]="localAdjustment.gstRate"
                                   class="form-control"
                                   dp-percentage
                            />
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="form-group" *ngIf="localAdjustment.taxType == 'GST' && localAdjustment.pstRate">
                <div class="col-lg-12">
                    <div class="col-lg-5 vertical-align-label">
                        <label class="control-label">Adjust for PST</label>
                    </div>
                    <div class="col-lg-1">
                        <select type="text"
                                id="applyPst"
                                name="applyPst"
                                [(ngModel)]="localAdjustment.applyPst"
                                class="form-control"
                        >
                            <option *ngFor="let yesNo of yesNoOptions" [value]="yesNo.value">
                                {{yesNo.label}}
                            </option>
                        </select>
                    </div>

                    <ng-container *ngIf="showPstPercentageFields">
                        <div class="col-lg-2 vertical-align-label" >
                            <label class="control-label">PST percentage</label>
                        </div>
                        <div class="col-lg-1">
                            <input type="text"
                                   id="pstPercentage"
                                   name="taxPercentage"
                                   [(ngModel)]="localAdjustment.pstRate"
                                   class="form-control"
                                   dp-percentage
                            />
                        </div>
                        <div class="col-lg-1">
                            <label class="control-label text-left">%</label>
                        </div>
                    </ng-container>

                </div>
            </div>


        </div>

        <div style="border-top: 1px solid #cccccc;">
            <div class="col-lg-12 form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-3">
                    <label class="control-label" style="font-weight : bold">{{localAdjustment.adjustmentHeading | uppercase}}</label>
                </div>
            </div>

            <div *ngFor="let item of localAdjustment.items;let i = index;" >
            <div class="col-lg-12 form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-5">
                    <input type="text"
                           id="item-heading-{{i}}-{{deleteIndex}}"
                           name="item-heading-{{i}}-{{deleteIndex}}"
                           maxlength="37"
                           [(ngModel)]="item.heading"
                           class="form-control"
                    />
                    <div class="actionbtns">
                        <div class="width-20">
                                <span>
                                    <i class="fa fa-trash" (click)="removeItem(item);" aria-hidden="true"></i>
                                </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
            <div class="form-group" *ngIf="localAdjustment.items.length < maxItems">
                <div class="col-lg-1"></div>
                <div class="col-lg-11">
                    <span class="actionlink">
                        <span>
                            <a href="javascript:void(0);" (click)="addNewItem();">
                            Add another Item
                            </a>
                        </span>
                        <span class="selector fa fa-plus-square-o"></span>
                    </span>
                </div>
            </div>
        </div>


        <!-- and now adjustment details -->

        <div *ngFor="let line of details; let i=index">
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-left">{{line.title}}</label>
                <label *ngIf="i + 1  < details.length" class="control-label" [ngClass]="{'col-lg-4': line.pushValueToRight, 'col-lg-2': !line.pushValueToRight}">{{line.value}}</label>
                <label *ngIf="i + 1 == details.length" class="control-label" [ngClass]="{'col-lg-5': line.pushValueToRight, 'col-lg-4': !line.pushValueToRight}">{{line.value}}</label>
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                    <span>OK</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
                <button type="button" class="secondary-button" (click)="delete()" *ngIf="allowDelete">
                    <span>Delete</span>
                </button>
            </div>

        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

