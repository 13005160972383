<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Would you like to add this spouse to the {{this.isOpportunityMatter() ? 'opportunity' : 'matter'}}?</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <ng-container *ngFor="let suggestedSpouse  of suggestedSpouses; let j=index">
                <div class="col-lg-12">
                    <div class="col-lg-1">
                        <span class="radio display-block" *ngIf="suggestedSpouses && suggestedSpouses.length > 1">
                            <input id="spouse_{{suggestedSpouse.identifier}}"
                                   name="spouse"
                                   type="radio"
                                   [value]="suggestedSpouse.spouse && suggestedSpouse.spouse.id"
                                   [(ngModel)]="selectedSpouseId"
                            />
                            <label tabindex="0" class="focus-first" for="spouse_{{suggestedSpouse.identifier}}"
                                   (keyup.space)="selectSuggestedSpouse(suggestedSpouse)"></label>
                        </span>
                    </div>
                    <div class="col-lg-2 padding-top-7">
                        <span >{{suggestedSpouse.spouse && suggestedSpouse.spouse.contactName &&
                            suggestedSpouse.spouse.contactName.surnameLastFullName}}</span>
                    </div>
                    <div class="col-lg-5 padding-top-7">
                        <span >{{suggestedSpouse.spouse && suggestedSpouse.spouse.primaryAddress && suggestedSpouse.spouse.primaryAddress
                            .addressTextWithoutCountryAndPostalCode}}</span>
                    </div>
                    <div class="col-lg-1 padding-top-7">
                        <span>{{getGenderLabel(suggestedSpouse.spouse && suggestedSpouse.spouse.gender)}}</span>
                    </div>
                    <div class="col-lg-2 padding-top-7">
                        <span>{{getSpousalStatusLabel(suggestedSpouse.spouseRelationship)}}</span>
                    </div>
                    <div class="col-lg-1 padding-top-7">
                        <span>{{getDeceasedLabel(suggestedSpouse.spouse)}}</span>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button [disabled]="context.disableAddClient"
                    type="button"
                    id="asClient"
                    (click)="onClickSelectSpouseAsClient()"
                    class="dp-btn keyboard-save-action focus-first">
                <span>{{getClientLabel()}}</span>
            </button>
            <ng-container *ngIf="!isCustomMatter() && !isOpportunityMatter()">
                <button *ngIf="isAsConsentingSpouseButtonVisible()"
                        type="button"
                        id="asConsentingSpouse"
                        (click)="onClickSpouseAsConsented()"
                        class="dp-btn secondary-button">
                    <span>As a consenting spouse</span>
                </button>
                <button *ngIf="isSpouseAsFormerClientVisible()"
                        [disabled]="context.disableAddClient"
                        type="button"
                        id="asFormerSpouseClient"
                        (click)="onClickSelectSpouseAsFormerClient()"
                        class="dp-btn secondary-button">
                    <span>{{getFormerSpouseLabel()}}</span>
                </button>
                <button *ngIf="isSpouseAsFormConsentedVisible()"
                        type="button"
                        id="asFormConsentingSpouse"
                        (click)="onClickSpouseAsFormConsented()"
                        class="dp-btn secondary-button">
                    <span>As a consenting spouse (Former Spouse)</span>
                </button>
                <button *ngIf="isAsASpouseVisible()"
                        type="button"
                        id="asASpouse"
                        (click)="openSpouseTypeSelectionModal()"
                        class="dp-btn secondary-button">
                    <span>As a spouse</span>
                </button>
                <button *ngIf="isProvinceSK()"
                        type="button"
                        id="asInterspousalAgreement"
                        (click)="onClickSpouseAsInterspousalAgreement()"
                        class="dp-btn secondary-button">
                    <span>Has released homestead right</span>
                </button>
                <button *ngIf="isProvinceSK()"
                        type="button"
                        id="asAuthorizedByCourt"
                        (click)="onClickSpouseAsAuthorizedByCourt()"
                        class="dp-btn secondary-button">
                    <span>Has no homestead rights</span>
                </button>
            </ng-container>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Don't add contact</span>
            </button>

        </div>
    </div>
</form>
