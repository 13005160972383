import {Injectable} from '@angular/core';

@Injectable()
export class FeatureService {
  constructor() {}

  private _featureFlags = {};

  public setFeatureFlags(value) {
    this._featureFlags = value;
  }

  get isSSOEnabled(): boolean {
    return this._featureFlags && this._featureFlags['SINGLE_SIGN_ON'];
  }
}
