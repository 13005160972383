import moment from 'moment';
import {Moment} from 'moment';
import {dropDowns} from '../shared/matter-drop-downs';
import * as _ from 'lodash';
import Utils from '../../shared-main/utils';
import {StatementAdjustmentAmountTypes} from './statement-adjustment-constants';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export const WaterSupplyType = {METERED: 'METERED', FLAT_RATE: 'FLAT_RATE', WELL_WATER: 'WELL_WATER'};

export class SoaWater extends BaseEntity {

  id: number;
  waterSupplyType: string;
  currentWaterBillAmount: number;
  commencementDate: string;
  billingPeriodEndDate: string;
  adjustType: string;
  adjustFor: string;
  percentage: number;
  fullAmountPaid: string;
  paidTowardsCurrentBilling: number;

  // closingDate: string; //This is used to keep the closing date used for the calculation, to be injected from the parent component

  isAdjustFor(): boolean {
    return Utils.convertToBoolean(this.adjustFor);
  }

  isFullAmountPaid(): boolean {
    return Utils.convertToBoolean(this.fullAmountPaid);
  }

  get isALeapYear() {
    let b = moment(this.commencementDate, 'YYYY');
    return moment(b).isLeapYear();
  }

  get paymentPeriodInDays(): number {
    /*
    let daysInYear:number;
    let daysInMonth:number = moment(this.commencementDate, "YYYY/MM/DD").daysInMonth();
    if(this.isALeapYear){
        daysInYear = 366;
    } else {
        daysInYear = 365;
    }
    switch(this.billingFrequencyType) {
        case 'ANNUALLY':
            return daysInYear;
        case 'SEMI_ANNUALLY':
            return Utils.cutToTwoDecimals(daysInYear/2);
        case 'QUARTERLY':
            return Utils.cutToTwoDecimals(daysInYear/4);
        case 'MONTHLY':
            return daysInMonth;
        case 'SEMI_MONTHLY':
            return Utils.cutToTwoDecimals(daysInMonth/2);
        case 'BI_WEEKLY':
            return 14;
        case 'WEEKLY':
            return 7;
        case 'DAILY':
            return 1;
        case 'EVERY_FOUR_MONTHS':
            return Utils.cutToTwoDecimals(daysInYear/3);
        default:
            return 0;
    }
    */
    let startDate = moment(this.commencementDate, 'YYYY/MM/DD');
    let endDate = moment(this.billingPeriodEndDate, 'YYYY/MM/DD');

    let result = endDate.diff(startDate, 'days') + 1;
    if (this.commencementDate == null || this.commencementDate == '' || this.commencementDate == undefined || this.billingPeriodEndDate == null || this.billingPeriodEndDate == undefined || this.billingPeriodEndDate == '' || this.billingPeriodEndDate.length < 10 || this.commencementDate.length < 10) {
      return 0;
    } else {
      return result;
    }
  }

  get isTrueAdjustType() {
    let aT: any = this.adjustType;
    if (aT == 'true' || aT == true) {
      return true;
    } else {
      return false;
    }
  }

  get isTrueAdjustFor() {
    let aT: any = this.adjustFor;
    if (aT == 'true' || aT == true) {
      return true;
    } else {
      return false;
    }
  }

  get isTrueFullAmountPaid() {
    let aT: any = this.fullAmountPaid;
    if (aT == 'true' || aT == true) {
      return true;
    } else {
      return false;
    }
  }

  get waterSupplyTypeLabel() {
    let sv: any = _.find(dropDowns.waterSupplyType, [ 'value', this.waterSupplyType ]);
    return sv.label;
  }

  get isWaterSupplyTypeMetered(): boolean {
    return this.waterSupplyType == WaterSupplyType.METERED;
  }

  get isWaterSupplyTypeWellWater(): boolean {
    return this.waterSupplyType == WaterSupplyType.WELL_WATER;
  }

  get isWaterSupplyTypeFlatRate(): boolean {
    return this.waterSupplyType == WaterSupplyType.FLAT_RATE;
  }

  needToCreditVendor(closingDate: string): boolean {
    // return Number(this.paidTowardsCurrentBilling) > Number(this.currentWaterBillAmount);
    return Number(this.paidTowardsCurrentBilling) > Number(this.getVendorsShare(closingDate));
  }

  getSoaCreditType(closingDate: string, isPaysForDateOfClosingVendor?: boolean): string {
    let vendorPaid: number = this.paidTowardsCurrentBilling;
    let vendorShare: number = Number(this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor));
    if (vendorPaid > vendorShare) {
      return StatementAdjustmentAmountTypes.VENDOR;
    }
    if (vendorShare > vendorPaid) {
      return StatementAdjustmentAmountTypes.PURCHASER;
    }
    return StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
  }

  getVendorsShare(closingDate: string, isPaysForDateOfClosingVendor?: boolean) {
    let ratio: number = this.getNumberOfDaysElapsed(closingDate, isPaysForDateOfClosingVendor) / this.paymentPeriodInDays;
    return (Number(this.currentWaterBillAmount) + (Number(this.currentWaterBillAmount) * Number(this.percentage) / 100)) * (ratio > 1 ? 1 : ratio);
  }

  //credit for vendor or purchaser
  getCredit(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    if (this.isWaterSupplyTypeFlatRate) {
      if (this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor) < 0) {
        //invalid data.
        return null;
      }
      if (this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor) > Number(this.paidTowardsCurrentBilling)) {
        return Utils.roundCurrency(this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor) - Number(this.paidTowardsCurrentBilling));
      } else {
        return Utils.roundCurrency(Number(this.paidTowardsCurrentBilling) - this.getVendorsShare(closingDate, isPaysForDateOfClosingVendor));
      }
    } else {
      return 0;
    }
  }

  getNumberOfDaysElapsed(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number {
    if (this.commencementDate && closingDate) {
      let a = moment(this.commencementDate, 'YYYY/MM/DD');
      let b = moment(closingDate, 'YYYY/MM/DD');
      let diffInDays = b.diff(a, 'days');
      return !!isPaysForDateOfClosingVendor ? ++diffInDays : diffInDays;
    } else {
      return 0;
    }
  }

  isClosingDateGEcommencementDate(closingDate: string): boolean {
    // let commencementDate : Moment = moment(this.commencementDate, "YYYY/MM/DD");
    // let closingDate = moment(closingDateStr, "YYYY/MM/DD");
    // return closingDate.isSameOrAfter(commencementDate, 'day');
    return closingDate >= this.commencementDate;
  }

  getVendorShareDays(closingDate: string, isPaysForDateOfClosingVendor?: boolean): number | string {
    if (this.isClosingDateGEcommencementDate(closingDate) && Math.round(this.paymentPeriodInDays) >= this.getNumberOfDaysElapsed(closingDate, isPaysForDateOfClosingVendor)) {
      return this.getNumberOfDaysElapsed(closingDate, isPaysForDateOfClosingVendor);
    } else {
      return '???';
    }
  }

  isClosingDateGreaterThanCommencementDate(closingDateStr: string): boolean {
    // let commencementDate  = moment(this.commencementDate, "YYYY/MM/DD");
    // let closingDate = moment(closingDateStr, "YYYY/MM/DD");
    // return closingDate > commencementDate;
    return closingDateStr > this.commencementDate;
  }

  //this method can handle float value for days, for current Moment.js, it treat 91 and 91.25 equally
  // momentSubstractDays(currentMoment: Moment, days : number){
  //     if(days){
  //         let duration = moment.duration(days, "days");
  //         currentMoment.subtract(duration.asMilliseconds(), "ms");
  //     }
  // }

  getCommencementThreshold(closingDate: string): Moment {
    let threshold: Moment = moment(closingDate, 'YYYY/MM/DD');
    Utils.momentSubstractDays(threshold, this.paymentPeriodInDays);
    threshold.add(1, 'days');

    return threshold;
  }

  isCommencementDateGEthreshold(closingDate: string): boolean {
    return moment(this.commencementDate, 'YYYY/MM/DD') > this.getCommencementThreshold(closingDate) || this.commencementDate == this.getCommencementThreshold(closingDate).format('YYYY/MM/DD');
  }

  getVendorCreditValue(closingDate: string): number | string {

    if (this.isClosingDateGreaterThanCommencementDate((closingDate)) && Math.round(this.paymentPeriodInDays) >= this.getNumberOfDaysElapsed(closingDate)) {
      if (this.isCommencementDateGEthreshold(closingDate)) {
        if (this.isTrueFullAmountPaid) {
          if (Number(this.currentWaterBillAmount) + Number(this.currentWaterBillAmount * this.percentage / 100) > Number(this.getVendorsShare(closingDate))) {
            return (Number(this.currentWaterBillAmount) + Number(this.currentWaterBillAmount * this.percentage / 100)) - Number(this.getVendorsShare(closingDate));
          } else {
            return 'No Adjustment';
          }
        } else {
          if (this.paidTowardsCurrentBilling > this.getVendorsShare(closingDate)) {
            return Number(this.paidTowardsCurrentBilling) - Number(this.getVendorsShare(closingDate));
          }
        }
      }
    } else {
      return 'No Adjustment';
    }
    return '';
  }

  getPurchaserCreditValue(closingDate: string): number | string {

    let vendorCredit = this.getVendorCreditValue(closingDate);

    if (typeof vendorCredit == 'string' && vendorCredit == 'No Adjustment') {
      return 'No Adjustment';
    } else {
      if (this.isClosingDateGreaterThanCommencementDate((closingDate)) && Math.round(this.paymentPeriodInDays) >= this.getNumberOfDaysElapsed(closingDate)) {
        if (this.isCommencementDateGEthreshold) {
          if (this.isTrueFullAmountPaid) {
            if (this.currentWaterBillAmount + this.currentWaterBillAmount * this.percentage / 100 < this.getVendorsShare(closingDate)) {
              return Number(this.getVendorsShare(closingDate)) - Number(this.currentWaterBillAmount) + Number(this.currentWaterBillAmount * this.percentage / 100);
            } else {
              return '';
            }
          } else {
            if (this.paidTowardsCurrentBilling < this.getVendorsShare(closingDate)) {
              return Number(this.getVendorsShare(closingDate)) - Number(this.paidTowardsCurrentBilling);
            } else {
              return '';
            }
          }
        } else {
          return '$?????????.??';
        }
      } else {
        return '$?????????.??';
      }
    }
  }

  isValidAdjustmentDate(adjustmentDate: string): boolean {
    if (adjustmentDate && Utils.isValidDate(adjustmentDate)) {
      //adjustmentDate need to between commencementDate and bilingPeriodEndDate
      //if only these two date is not available
      if (this.commencementDate && this.billingPeriodEndDate) {
        return adjustmentDate >= this.commencementDate && adjustmentDate <= this.billingPeriodEndDate;
      }
      return true;
    }
    return false;
  }

}
