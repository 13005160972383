import {MatterType} from '../matters/shared/index';
import {ProvinceCode} from '../admin/accounts/shared/base-province';
import {BaseEntity} from '../shared/BaseEntity/base-entity';

export class MatterPricing extends BaseEntity {

  matterType: MatterType;
  provinceCode: ProvinceCode;
  amount: number;
  federalTax: number;
  provincialTax: number;

  get total(): Number {
    return Number(this.amount);
  }

  get preTaxAmount(): Number {
    return Number(this.amount) - Number(this.federalTax) - Number(this.provincialTax);
  }
}
