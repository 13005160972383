import {Injectable, Injector} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {api} from '../common/api';
import {HttpClient} from './httpClient.service';
import {Utils} from '../matters/shared/utils';
import {ApplicationLog} from './application-log';
import {SharedDocument} from '../share-documents/shared-document';
import SharedMainUtils from "../shared-main/utils";

export type LogLevelType = 'WARN' | 'INFO' | 'DEBUG';

export class LogLevelTypes {
  public static readonly WARN: LogLevelType = 'WARN';
  public static readonly INFO: LogLevelType = 'INFO';
  public static readonly DEBUG: LogLevelType = 'DEBUG';
}

export type ActionType = 'clientSideMatterSave' | 'clientSideMatterCreate';

export class ActionTypes {
  public static readonly clientSideMatterSave: ActionType = 'clientSideMatterSave';
  public static readonly clientSideMatterCreate: ActionType = 'clientSideMatterCreate';
}

export const logRequestDurationPrefix = 'CLIENT_SIDE_REQUEST_DURATION: ';
export const logSkippedMatterRelockingPrefix = 'SKIPPED_MATTER_RELOCKING: ';
export const statementOfAccountReset = 'STATEMENT_OF_ACCOUNT_RESET';
export const trustLedgerReset = 'TRUST_LEDGER_RESET';
export const previouslySharedDocument = 'PREVIOUSLY_SHARED_DOCUMENT';

@Injectable()
export class GlobalLogger {

  private loggerUrl = (userId: string, level: LogLevelType) => `${ api }/users/${ userId }/logFromClientSide/${ level }`;

  constructor(private injector: Injector) {
  }

  log(level: LogLevelType, msg: string, stackTrace?: string) {
    let utils = new Utils();
    let location = this.injector.get(LocationStrategy);
    let httpClient = this.injector.get(HttpClient);
    const url = location instanceof PathLocationStrategy
      ? location.path() : '';

    let applicationLog: ApplicationLog = new ApplicationLog();
    applicationLog.message = msg;
    applicationLog.url = url;
    applicationLog.browserInfo = utils.getBrowserInfo();
    applicationLog.status = null;
    applicationLog.stack = stackTrace ? stackTrace : null;
    applicationLog.accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    applicationLog.userId = sessionStorage.getItem(SESSION_STORAGE_KEYS.userLogin);

    httpClient.postWithPromise(this.loggerUrl(SharedMainUtils.getAuthenticatedUserId(), level), JSON.stringify(applicationLog))
    .catch(err => {
      console.log('Error happened while publishing client side log to the server ', err);
    });

  }

  getDurationLogMsg(action: ActionType, duration: number, matterId: number): string {
    return logRequestDurationPrefix + action + '=' + duration + ';matterId=' + matterId;
  }

  logRequestDuration(level: LogLevelType, action: ActionType, startTime: number, matterId: number) {
    let logMsg;

    const duration: number = Date.now() - startTime;
    switch (action) {
      case ActionTypes.clientSideMatterSave:
      case ActionTypes.clientSideMatterCreate:
        logMsg = this.getDurationLogMsg(action, duration, matterId);
        break;
      default:
        break;
    }

    if (logMsg) {
      this.log(level, logMsg);
    }
  }

  logStatementOfAccountReset(matterId: number, soaTemplateId?: number): void {
    let now = new Date();
    let logMessage = statementOfAccountReset
    + ';matterId=' + matterId
    + soaTemplateId ? (';soaTemplateId=' + soaTemplateId) : ''
      + ';time' + now;
    this.log(LogLevelTypes.INFO, logMessage);
  }

  logTrustLedgerReset(matterId: number): void {
    let now = new Date();
    let logMessage = trustLedgerReset
      + ';matterId=' + matterId
      + ';time' + now;
    this.log(LogLevelTypes.INFO, logMessage);
  }

  logPreviouslySharedDocument(document: SharedDocument, originalDocName: string, sharingWith: string, matterId: number): void {
    let now = new Date();
    let logMessage = previouslySharedDocument
      + ';matterId=' + matterId
      + ';sharingWith=' + sharingWith
      + ';sourceDocumentId=' + document.sourceDocumentId + ';sharedDocumentId=' + document.id
      + ';originalDocumentName=' + originalDocName + ';documentType=' + document.sharedDocumentType
      + ';time' + now;
    this.log(LogLevelTypes.WARN, logMessage);
  }
}
