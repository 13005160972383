import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type RequisitionStatus = 'UNRESOLVED' | 'ACCEPT_UNDERTAKING' | 'REQUISITION_ACTUAL_DISCHARGE' | 'WAIVED_ACCEPTED' | 'RESOLVED';

export type RequisitionCategory = 'A' | 'B' | 'C' | 'null';

export interface RequisitionStatusDropdown {
  label: string;
  value: RequisitionStatus;
}

export interface RequisitionCategoryDropdown {
  label: string;
  value: RequisitionCategory;
}

export class RequisitionTemplate extends BaseEntity {
  id: number;
  static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
  // localId: number;
  key: string;
  require: string;
  encumbranceType: string;
  registrationNumber: string;
  inFavourOf: string;
  requisitionText: string;
  matterId: number;
  instrumentId: number; //id of instrument related to requisition
  requisitionStatus: RequisitionStatus;
  requisitionCategory: RequisitionCategory;
  instanceType: string;

  get dataHash(): string {
    return this.require + ';' + this.encumbranceType + ';' + this.registrationNumber + ';' + this.inFavourOf;
  }

  isStatusAcceptUndertaking(): boolean {
    return this.requisitionStatus == 'ACCEPT_UNDERTAKING';
  }

  isStatusNotResolved(): boolean {
    return this.requisitionStatus != 'ACCEPT_UNDERTAKING';
  }
}
