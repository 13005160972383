import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {Contact} from '../../matters/shared/contact';
import {User} from '../../matters/shared/user';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {ProjectCondo} from '../project-condo/project-condo';
import {DocumentRegistration} from './document-registration';
import {TeraviewConfig} from '../../admin/docket/teraview-config';
import {projectConsts} from './project-consts';
import {ProjectAdjustmentConfig} from '../project-adjustments/project-adjustment-config';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {StatementConfig} from '../../admin/shared/statement-config';
import {Form4Deposit} from './form4-deposit';
import {ProjectTaxAdjustmentConfig} from '../project-adjustments/project-tax-adjustment-config';
import {DpBooleanValueTypes} from '../../matters/shared/dp-boolean';
import {TrustAccount} from '../../admin/trust-account/trust-account';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import Utils from "../../shared-main/utils";

export type MassUpdateType =
  'TOPIC_A_K_M_N'
  | 'ADD_NEW_SUPPLEMENTAL_TASKS'
  | 'NUMBER_OF_EXISTING_MORTGAGES'
  | 'NUMBER_OF_VTB_MORTGAGES'
  | 'FINAL_ADJUSTMENT_DATE'
  | 'INTERIM_ADJUSTMENT_DATE'
  | 'SOA_GL_CODES'
  | 'SOA_GL_CODES_INTERIM'
  | 'SOA_GL_CODES_FINAL'
  | 'STATEMENT_OF_ACCOUNT'
  | 'STATEMENT_OF_ACCOUNT_INTERIM'
  | 'STATEMENT_OF_ACCOUNT_FINAL'
  | 'UPDATE_FORM4_BANK_ACCOUNT_INFO'
  | 'TRUST_LEDGER'
  | 'TRUST_LEDGER_INTERIM'
  | 'TRUST_LEDGER_FINAL'
  | 'UNIT_TYPES'
  | 'GST_HST_APPLICATION'
  | 'TAX_RATE_OF_SOA_SOAJ'
  | 'IMPORT_MATTER_ADJ_INF';

export const MassUpdateTypes = {
  TOPIC_A_K_M_N: 'TOPIC_A_K_M_N',
  ADD_NEW_SUPPLEMENTAL_TASKS: 'ADD_NEW_SUPPLEMENTAL_TASKS',
  NUMBER_OF_EXISTING_MORTGAGES: 'NUMBER_OF_EXISTING_MORTGAGES',
  NUMBER_OF_VTB_MORTGAGES: 'NUMBER_OF_VTB_MORTGAGES',
  FINAL_ADJUSTMENT_DATE: 'FINAL_ADJUSTMENT_DATE',
  INTERIM_ADJUSTMENT_DATE: 'INTERIM_ADJUSTMENT_DATE',
  SOA_GL_CODES: 'SOA_GL_CODES',
  SOA_GL_CODES_INTERIM: 'SOA_GL_CODES_INTERIM',
  SOA_GL_CODES_FINAL: 'SOA_GL_CODES_FINAL',
  STATEMENT_OF_ACCOUNT: 'STATEMENT_OF_ACCOUNT',
  STATEMENT_OF_ACCOUNT_INTERIM: 'STATEMENT_OF_ACCOUNT_INTERIM',
  STATEMENT_OF_ACCOUNT_FINAL: 'STATEMENT_OF_ACCOUNT_FINAL',
  UPDATE_FORM4_BANK_ACCOUNT_INFO: 'UPDATE_FORM4_BANK_ACCOUNT_INFO',
  TRUST_LEDGER: 'TRUST_LEDGER',
  TRUST_LEDGER_INTERIM: 'TRUST_LEDGER_INTERIM',
  TRUST_LEDGER_FINAL: 'TRUST_LEDGER_FINAL',
  UNIT_TYPES: 'UNIT_TYPES',
  GST_HST_APPLICATION: 'GST_HST_APPLICATION',
  TAX_RATE_OF_SOA_SOAJ: 'TAX_RATE_OF_SOA_SOAJ',
  IMPORT_MATTER_ADJ_INF: 'IMPORT_MATTER_ADJ_INF'
};

export class Project {

  projectBurgerMenu: BurgerMenuExtendedItem[] = []; //UI Only
  id: number;
  projectRecordNumber: string;
  projectName: string;
  provinceCode: ProvinceCode;
  defaultLawClerkId: number;
  defaultSolicitorId: number;
  defaultDocumentProfileId: number;
  status: string;
  condominiumFlag: boolean;
  gstHstNo: string;
  tarionNo: string;
  insertTarionNo: boolean;
  occupancyDateRequired: boolean;
  updateDocumentExecutionDate: boolean;
  useSameDocketIdentifier: boolean;
  deliveryOfCashToClose: string;
  teraviewDocketIdentifier: string;
  interestRate: string;
  lastUpdatedUser: string;
  dirty: boolean;

  tempIdForNewProject: number;
  lastUpdatedTimeStamp: Date;
  locked: boolean;
  lockedByUser: User;

  defaultSolicitorInitials: string;
  defaultLawClerkInitials: string;

  potlFlag: boolean;
  potlPlanApplied: boolean;
  condominiumPlanName: string;
  commonExpensesForEntireCondo: Number = 0;
  declarationRegistrationDate: string;
  declarationRegistrationNumber: string;

  projectCondo: ProjectCondo;
  projectAdjustmentConfig: ProjectAdjustmentConfig;

  teraviewDocket: string;
  insertFileNoIntoTeraviewDocket: boolean;
  docRegistration: DocumentRegistration;
  teraviewConfiguration: TeraviewConfig;
  templateMatterId: number;
  taxType: string;
  documentProfile: DocumentProfile;
  statementConfiguration: StatementConfig;
  statementConfigurationDirty: boolean;

  form4Deposit: Form4Deposit;

  rangeOfMortgage: number; //Only for UI, for Existing Mortgages OR VTB Mortgages
  trustAccount: TrustAccount; //Only for UI, for Form 4 Bank Account Info update
  isCopyInProgress: boolean = false; //UI for copying project
  // fullyCopied will be set to false before copying project matters
  // And is set to true when copying all project matters is complete
  fullyCopied: boolean = true;
  sharingInformation: string;

  fullyInitialized: boolean = true;

  projectDocumentsSharedWithOtherSide: boolean = false;

  massUpdateTransactionId: number;
  //Project can become inaccessible if some mass update transaction cannot be completed
  inaccessible: boolean;
  openTransactionsIds: number[] = [];

  initializeEregOnMatter: boolean = false;

  constructor(project?: Project) {
    this.templateMatterId = null;
    if (project) {
      for (let prop in project) {
        if (project.hasOwnProperty(prop)) {
          this[ prop ] = project[ prop ];
        }
      }
      if (project.projectCondo) {
        this.projectCondo = new ProjectCondo(project.projectCondo);
      }

      if (project.projectAdjustmentConfig) {
        this.projectAdjustmentConfig = new ProjectAdjustmentConfig(project.projectAdjustmentConfig);
      }

      if (project.docRegistration) {
        this.docRegistration = new DocumentRegistration(project.docRegistration);
      }

      if (project.statementConfiguration) {
        this.statementConfiguration = new StatementConfig(project.statementConfiguration);
      }

      if (project.form4Deposit) {
        this.form4Deposit = new Form4Deposit(project.form4Deposit);
      }
      if (project.teraviewConfiguration) {
        this.teraviewConfiguration = new TeraviewConfig(project.teraviewConfiguration);
      }
    }

  }

  isProjectExisting(): boolean {
    return this.id && (this.id > 0);
  }

  updateProjectSolicitorInfo(contact: Contact) {
    if (contact) {
      this.defaultSolicitorId = contact.id;
      this.defaultSolicitorInitials = contact.initials;
    }
  }

  updateProjectLawClerkInfo(contact: Contact) {
    if (contact) {
      this.defaultLawClerkId = contact.id;
      this.defaultLawClerkInitials = contact.initials;
    }
  }

  anyErrorsExistOnProject(errorService: ErrorService): boolean {
    errorService.provinceCode = this.provinceCode;
    errorService.clearAllSaveErrors();
    if (!this.projectRecordNumber || this.projectRecordNumber.trim().length == 0) {
      errorService.addDpSaveError(DPError.createDPError('project.projectDetails.projectNo', '', '', ''));
    }
    if (this.condominiumFlag == null) {
      errorService.addDpSaveError(DPError.createDPError('project.projectDetails.condominium', '', '', ''));
    }
    if (!this.deliveryOfCashToClose && this.isProjectProvinceAB) {
      errorService.addDpSaveError(DPError.createDPError('project.projectDetails.deliveryOfCashToClose', '', '', ''));
    }
    return errorService.hasErrors();
  }

  get lockedByOthers(): boolean {
    const userId = Utils.getAuthenticatedUserId();
    return (this.lockedByUser && this.lockedByUser.id != Number(userId));
  }

  get lockedByCurrentUser(): boolean {
    const userId = Utils.getAuthenticatedUserId();
    return (this.lockedByUser && this.lockedByUser.id == Number(userId));
  }

  get paperSizeCodeSOA(): string {
    return this.documentProfile.statementOfAdjustmentsProfile.paperSizeCode;
  }

  nonCondoOntarioWithOccupancyDateYes(): boolean {
    return this.provinceCode === projectConsts.provinces.ON && !this.condominiumFlag && this.occupancyDateRequired;
  }

  cleanUpProjectBeforeSaving(): void {

    if (!this.teraviewConfiguration) {
      //data-integrity checking for existing project
      this.teraviewConfiguration = new TeraviewConfig();
    }

    if (!this.potlFlag) {
      this.potlPlanApplied = false;
      this.condominiumPlanName = null;
      this.commonExpensesForEntireCondo = null;
      this.declarationRegistrationDate = null;
      this.declarationRegistrationNumber = null;
    }

    if (!this.docRegistration) {
      //data-integrity checking for existing project
      this.docRegistration = new DocumentRegistration();
    } else {
      if (this.docRegistration.registrationMethod == projectConsts.registrationMethods.MANUAL) {
        //reset the values of the following fields: since the following fields get hidden in this case
        this.teraviewDocket = projectConsts.teraviewDocketType.ALL;
        this.insertFileNoIntoTeraviewDocket = false;
        this.docRegistration.transferDocumentType = projectConsts.transferDocumentTypes.TRANSFER;
        this.docRegistration.chargeDocumentType = projectConsts.chargeDocumentTypes.CHARGE;
        this.teraviewConfiguration = new TeraviewConfig();
      }
    }

    if (!this.condominiumFlag && !this.nonCondoOntarioWithOccupancyDateYes()) {
      //if isCondo not true, then the following fields get hidden, so reset them to the default values
      this.docRegistration.docsForDirection = projectConsts.yesNoValues.Ny;
      this.docRegistration.docsForSoa = projectConsts.yesNoValues.Ny;
      this.docRegistration.docsForTl = projectConsts.yesNoValues.Ny;
    }

    if (this.docRegistration.buildNameAndAddressSource == projectConsts.builderNameAddressSources.SAME_AS_VENDOR_TAB) {
      //the following fields get hidden when builderNameAndAddress is not Manually Entered
      this.docRegistration.buiderLegalName = null;
      if (this.docRegistration.builderAddress) {
        this.docRegistration.builderAddress.addressLine1 = null;
        this.docRegistration.builderAddress.addressLine2 = null;
        this.docRegistration.builderAddress.city = null;
        this.docRegistration.builderAddress.provinceCode = projectConsts.provinces.ON;
        this.docRegistration.builderAddress.country = projectConsts.countries.CANADA;
        this.docRegistration.builderAddress.postalCode = null;
        this.docRegistration.builderTelephone.telephoneNumber = null;
      }
    }

    if (this.docRegistration.hstPeriodIsCalendarMonthOfFinalClosing) {
      //the following two fields get hidden when hstPeriod value is Yes, so reset the values
      this.docRegistration.hstPeriodFrom = null;
      this.docRegistration.hstPeriodTo = null;
    }

  }

  /*
  "Is this a Condo?" = "YES" OR "Is there a POTL?" = "Yes" OR "Is occupancy date required for each transaction in this project?" = "Yes"
   */
  isStatementOfAdjustmentInterim(): boolean {
    return (this.condominiumFlag || this.occupancyDateRequired || this.potlFlag);
  }

  isProjectConfigDocForDirectionReFund(): boolean {
    return this.docRegistration && this.docRegistration.docsForDirection == DpBooleanValueTypes.YES;
  }

  isProjectConfigDocForSoa(): boolean {
    return (this.docRegistration && this.docRegistration.docsForSoa == DpBooleanValueTypes.YES);
  }

  isProjectConfigDocForTl(): boolean {
    return (this.docRegistration && this.docRegistration.docsForTl == DpBooleanValueTypes.YES);
  }

  get isProjectProvinceON(): boolean {
    return this.provinceCode == projectConsts.provinces.ON;
  }

  get isProjectProvinceAB(): boolean {
    return this.provinceCode == projectConsts.provinces.AB;
  }

  get isPOTL(): boolean {
    return this.potlFlag;
  }

  get isOccupancyDateRequired(): boolean {
    return this.occupancyDateRequired;
  }

  get isCondo(): boolean {
    return this.condominiumFlag;
  }

  get isCondoOrPotl(): boolean {
    return this.isCondo || this.isPOTL;
  }

  get isNonCondoPotlON(): boolean {
    return !this.isCondo && this.isPOTL && this.provinceCode === projectConsts.provinces.ON;
  }

  get activeInterimTaxAdjustment(): ProjectTaxAdjustmentConfig {
    if (this.isCondo) {
      return this.projectAdjustmentConfig.interimTaxAdjustment;
    }
    return this.projectAdjustmentConfig.finalTaxAdjustment;
  }

  /**
   *  IF    (<Is amount additional consideration to vendor> IS VISIBLE AND <Is amount additional consideration to vendor> <>'No' )
   *      AND ((<Is this is a condo?> = 'Yes') 
   *          OR (<Is this is a condo?> = 'No' and<Is there a POTL> = 'Yes')  
   *          OR (<Is this is a condo?> = 'No' and <Is an occupancy date required for each transaction in this project> = 'Yes'))
   *  THEN
   *      I am able to see a field labeled 'Adjustment to be paid by purchaser on interim closing' 
   *  ELSE
   *      I am NOT able to see a field labeled 'Adjustment to be paid by purchaser on interim closing' 
   *  END IF
   *  This method implement check the following condition
   *   ((<Is this is a condo?> = 'Yes') 
   *          OR (<Is this is a condo?> = 'No' and<Is there a POTL> = 'Yes')  
   *          OR (<Is this is a condo?> = 'No' and <Is an occupancy date required for each transaction in this project> = 'Yes'))
   */
  isAdjustmentPaidOnInterimClosingVisibleExternalCondition(): boolean {
    return this.isProjectProvinceON
      && (this.isCondo || this.isPOTL || this.occupancyDateRequired);
  }
}

