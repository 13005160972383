import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Component, HostListener, Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ContentDialogConfig} from './dialog.service';
import { tap } from 'rxjs/operators';

export interface CloseGuard {
  beforeDismiss?(): boolean | Promise<boolean>;
  beforeClose?(): boolean | Promise<boolean>;
}

@Component({
  template: ``
})
export class ModalComponent<T> {
  public context?: T;
  public closeGuard?: CloseGuard;
  public closeOnEsc?: () => boolean;

  constructor(
    public dialog: MatDialogRef<any, any>,
    @Inject(MAT_DIALOG_DATA) context?: T
  ) {
    this.context = context;
  }

  public setCloseGuard(guard: CloseGuard): void {
    this.closeGuard = guard;
  }
}

@Injectable()
export class ModalDialogService {
  private defaultCssClasses: string[] = ['modal-dialog-default-padding', 'mat-modal-dialog'];

  constructor(private matDialog: MatDialog) {}

  public open<T>(config?: ContentDialogConfig): Observable<any> {

    const component: any = config.content;
    

    const dialog = this.matDialog.open(component, {
      data: config?.context,
      disableClose: true,
      panelClass: [...this.defaultCssClasses, ...(config?.panelClass || [])],
      width: config?.width,
      minWidth: config?.minWidth
    });

    const modalInstance = dialog.componentInstance as ModalComponent<T>;

    const keySubscription = dialog.keydownEvents().subscribe(event => {
      if (event.key?.toLowerCase() === 'escape') {
        if ((modalInstance?.closeOnEsc && modalInstance?.closeOnEsc()) ?? true) { // default behaviour is to close dialog on ESC
          dialog.close();
        }
      }
    });

    dialog.beforeClosed().subscribe((result: T) => {
      if (modalInstance?.closeGuard?.beforeDismiss) {
        Promise.resolve(modalInstance.closeGuard.beforeDismiss()).then(_ => {
          // value redundant, cannot prevent closing from the mat-dialog.beforeClosed event
        });
      }

      if (modalInstance?.closeGuard?.beforeClose) {
        Promise.resolve(modalInstance.closeGuard.beforeClose()).then(_ => {
          // value redundant, cannot prevent closing from the mat-dialog.beforeClosed event
        });
      }
    });

    return dialog.afterClosed().pipe(tap(_ => keySubscription?.unsubscribe()));
  }
}
