import {Address, MatterType} from '../shared';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {PROVINCE_CODES} from '../shared/user-province';
import {ContactSummary} from './contact-summary';

export class MatterInfo extends BaseEntity {
  id: number;
  matterType: MatterType;
  lawFirm: string;
  propertyAddress: Address;
  lawFirmAddress: Address;
  projectName: string;
  matterDescription: string;
  legalDescription: string;
  closingDate: string;
  occupancyDate: string;
  vendorSolicitor: ContactSummary;
  lawClerk: ContactSummary;
  provinceCode: string;
  logoFirmFileId: number;
  projectSharingInformation: string;

  constructor(matterInfo?: MatterInfo) {
    super(matterInfo);

    if (matterInfo) {
      if (matterInfo.propertyAddress) {
        this.propertyAddress = new Address(matterInfo.propertyAddress);
      }
      if (matterInfo.lawFirmAddress) {
        this.lawFirmAddress = new Address(matterInfo.lawFirmAddress);
      }
      if (matterInfo.vendorSolicitor) {
        this.vendorSolicitor = new ContactSummary(matterInfo.vendorSolicitor);
      }
      if (matterInfo.lawClerk) {
        this.lawClerk = new ContactSummary(matterInfo.lawClerk);
      }
    }
  }

  isProvinceABorMBorSK(): boolean {
    return this.provinceCode === PROVINCE_CODES.ALBERTA ||
      this.provinceCode === PROVINCE_CODES.MANITOBA ||
      this.provinceCode === PROVINCE_CODES.SASKATCHEWAN;
  }

  isCustomMatter(): boolean {
    return this.matterType == 'CUSTOM';
  }
}
