export class TelephoneTypes {

  static readonly home: string = 'HOME';

  static readonly work: string = 'WORK';

  static readonly cell: string = 'MOBILE';

  static readonly fax: string = 'FAX';
}

export type PhoneTypeCode = 'HOME' | 'WORK' | 'MOBILE' | 'FAX';
