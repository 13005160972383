<ng-container *ngIf="!showWizardFields">
    <div class="col-lg-12">
        <div class="alert alert-info" *ngIf="actingForBothPartiesInMortgageMatter" style="padding: 20px 60px 20px 60px">
            <i class="fa fa-info-circle" aria-hidden="true" style="float:left; font-size:20px; vertical-align: middle;">&nbsp;&nbsp;&nbsp;
            </i>
            <p>{{actingForBothPartiesMsg}}</p>
        </div>
        <form #vendorSolicitorForm="ngForm"
              role="form" (keyup)="enableSave();"
              class="form-horizontal matter-purchaser" *ngIf="!actingForBothPartiesInMortgageMatter"
        >
            <!--Vendor(s)-->
            <div *dpIfApplicableForMatter="'matter.otherParty.otherPartySearch'">
                <!--Vendor(s)-->
                <div *ngIf="!matter.isMortgageOpportunity">
                    <div class="main-level">
                        <span class="title">{{matter.otherPartyTitle}}(s)</span>
                    </div>
                    <ng-container *ngTemplateOutlet="otherparty"></ng-container>
                </div>



                <ng-container *dpShowByProvince="'matter.otherParty.titleDetails'">
                    <div class="form-group">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">Title Details</label>
                        </div>
                        <div class="col-lg-2">
                            <select class="form-control"
                                    id="titleDetails"
                                    name="titleDetails"
                                    [(ngModel)]="matter.otherPartyContactInfo.titleDetailsType"
                                    (change)="enableSave()"
                            >
                                <option *ngFor="let titleDetail of titleDetailTypes" [value]="titleDetail.value">
                                    {{titleDetail.label}}
                                </option>
                            </select>
                            <div class="margin-top-5">
                                <dp-checkbox fieldId="individualTitleDetails">
                                    <input
                                        id="individualTitleDetails"
                                        name="individualTitleDetails"
                                        type="checkbox"
                                        #sameAsPrimaryClient="ngModel"
                                        [(ngModel)]="matter.otherPartyContactInfo.individualTitleDetails"
                                        [disabled]="false"
                                        (ngModelChange)="individualTitleDetailsChange($event)">
                                </dp-checkbox>
                                <label for="individualTitleDetails">Individual Title<br/> Details</label>
                            </div>
                        </div>
                        <div class="col-lg-8">
                        <textarea name="titleDetailsText" id="titleDetailsTextAuto" rows="2" cols="80"
                                  [ngModel]="getTitleDetails" [dp-read-only]="true"
                                  *ngIf="!matter.otherPartyContactInfo.isTitleDetailsManual()"
                        ></textarea>
                            <textarea name="titleDetailsText" id="titleDetailsTextManual" rows="2" cols="80"
                                      [(ngModel)]="matter.otherPartyContactInfo.titleDetails"
                                      (change)="enableSave()"
                                      *ngIf="matter.otherPartyContactInfo.isTitleDetailsManual()"
                            ></textarea>
                        </div>
                    </div>
                </ng-container>

                <div class="clear"></div>
                <ng-container *ngIf="matter && !matter.isCustomMatter()">
                    <div class="form-group" *dpIfApplicableForMatter="'matter.otherParty.purchasersCapacity'">
                        <span class="col-lg-2 control-label" dp-province-field-label="matter.purchaser.capacity">
                            Capacity
                        </span>
                        <div class="col-lg-10">
                            <select class="form-control" id="purchasersCapacity" name="purchasersCapacity"
                                    [(ngModel)]="matter.otherPartiesCapacity"
                                    (change)="updateOtherPartiesCapacities($event.target.value)"
                                    [fieldKey]="fieldKey"
                                    dp-error-validator
                            >
                                <option *ngFor="let option of matterPurchasersCapacityOptions" [ngValue]="option.value">{{
                                    option.label }}
                                </option>
                            </select>
                        </div>
                    </div>


                    <!-- //END - Add another Other Party link -->

                    <div *dpShowByProvince="'matter.otherParty.offeror'">
                        <dp-common-offeror
                            [matter]="matter"
                            [errorTopic]="'<TAB_C_TOPIC>'"
                            (offerorsChanged)="enableSave()"
                        ></dp-common-offeror>
                    </div>

                    <div class="form-group" *ngIf="!matter?.isOpportunityMatter()">
                        <div class="col-lg-2 vertical-align-label">
                            <label class="control-label">{{matter.otherPartyTitle}}'s RE: Line</label>
                        </div>
                        <div class="col-lg-10">
                            <input type="text"
                                   name="vendor-reline"
                                   class="form-control"
                                   [(ngModel)]="matter.otherPartyContactInfo.reline"
                                   maxlength="200"
                                   trimmed
                            >
                        </div>
                    </div>

                    <ng-container *dpIfApplicableForMatter="'matter.otherParty.subjectProperty'">
                        <div class="form-group">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label" dp-province-field-label="matter.otherParty.subjectPropertyResidence">Will purchaser be residing at subject property?</label>
                            </div>
                            <div class="col-lg-1">
                                <select class="form-control"
                                        id="residingAtSubjectProperty_S"
                                        name="residingAtSubjectProperty_S"
                                        [dp-read-only]="matter.otherPartyContactInfo.individualTitleDetails"
                                        [(ngModel)]="matter.otherPartyContactInfo && matter.otherPartyContactInfo.residingAtSubjectProperty"
                                        (ngModelChange)="residingAtSubjectPropertyChange()">
                                    <option *ngFor="let residingAtSubjectProperty of residingAtSubjectPropertys"
                                            [value]="residingAtSubjectProperty.value">
                                        {{residingAtSubjectProperty.label }}
                                    </option>
                                </select>
                            </div>
                            <ng-container *dpShowByProvince="'matter.otherParty.eligibleForTaxRebate'">
                                <div class="offset-lg-3 col-lg-2 vertical-align-label">
                                    <label class="control-label">Eligible for {{matter.matterTaxType}} rebate?</label>
                                </div>
                                <div class="col-lg-1">
                                    <select class="form-control"
                                            id="eligibleForRebate"
                                            name="eligibleForRebate"
                                            [(ngModel)]="matter.matterContactInfo.eligibleForTaxRebate"
                                            (ngModelChange)="enableSave()">
                                        <option *ngFor="let residingAtSubjectProperty of residingAtSubjectPropertys"
                                                [value]="residingAtSubjectProperty.value">
                                            {{residingAtSubjectProperty.label }}
                                        </option>
                                    </select>
                                </div>
                            </ng-container>

                        </div>

                    </ng-container>
                    <ng-container *dpShowByProvince="'matter.otherParty.serviceAddressToUFFIWarrantySection'">
                        <ng-container *ngIf="displayServiceAddress">
                            <dp-address-form [address]="serviceAddress"
                                             (updateAddress)="enableSave()"
                                             [closeShutter]="false"
                                             [fieldPrefix]="'otherParty.service'"
                                             [addressSummaryLabel]="matter.isProjectSale? getProjectSaleAddressLabel() : 'Address for Service'"
                                             [defaultForProvinceAndCountry]="!this.isMassUpdateTab()"

                            >
                            </dp-address-form>
                        </ng-container>

                        <ng-container *dpShowByProvince="'matter.solicitor.documentsExecutedAt'">

                            <div class="form-group" *ngIf="isDocExecutedAtVisible()">

                                <div class="col-lg-2 vertical-align-label">
                                    <label class="control-label">Documents Executed at</label>
                                </div>
                                <div class="fullLength-autocomplete" class="col-lg-5">
                                    <p-autoComplete id="jurisdiction"
                                                    name="jurisdiction"
                                                    placeholder="Search by Name"
                                                    [(ngModel)]="selectedJurisdiction"
                                                    [class.autocomplete-searching]="jurisdictionsLoading"
                                                    [suggestions]="jurisdictions"
                                                    [dp-read-only]="matter.outOfProvinceVendorExecDocsAt"
                                                    (completeMethod)="searchJurisdiction($event)"
                                                    field="jurisdictionName"
                                                    [minLength]="0"
                                                    [delay]="1000"
                                                    maxlength="75"
                                                    scrollHeight="350px"
                                                    (onSelect)="dataSelectedJurisdiction(selectedJurisdiction)"
                                                    (click)="jurisdictionHelpText()"
                                                    (focus)="jurisdictionHelpText()"
                                                    (change)="validateJurisdiction()"
                                                    tabindex="0"
                                                    (keyup)="checkIfJurisdictionEmpty(selectedJurisdiction)"
                                                    class="autocomplete-search-icon">
                                        <ng-template let-jurisdiction pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                                [disableItem]="jurisdiction">
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="jurisdictionAutoCompleteEmptyFlag == true">
                                    {{jurisdiction.jurisdictionName}}</span>
                                                <span class="col-lg-4 text-overflow"
                                                      *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                    {{jurisdiction.jurisdictionName}}</span>
                                                <span class="col-lg-4 text-overflow"
                                                      *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                    {{jurisdiction?.city?.name}}</span>
                                                <span class="col-lg-4 text-overflow"
                                                      *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                    {{jurisdiction?.city?.municipality?.name}}</span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div class="col-lg-2 padding-top-5"
                                     *dpShowByProvince="'matter.vendors-solicitor.outOfProvinceVendorExecDocsAt'">

                                    <dp-checkbox fieldId="outOfProvinceVendorExecDocsAt">
                                        <input id="outOfProvinceVendorExecDocsAt"
                                               name="outOfProvinceVendorExecDocsAt"
                                               type="checkbox"
                                               [(ngModel)]="matter.outOfProvinceVendorExecDocsAt"
                                               (change)="changeOutOfProvince()"
                                        />
                                    </dp-checkbox>
                                    <label class="control-label display-inline" for="outOfProvinceVendorExecDocsAt">
                                        Out of Province<br>
                                        <span class="padding-left-30">(unspecified)</span>
                                    </label>
                                    <span class="tool-tip-icon">
                                <i class="fa fa-info-circle padding-left-15 margin-top-minus-30 font-16 "
                                    aria-hidden="false"></i>
                            </span>
                                    <span class="tool-tip-message">
                                <div class="bubble-arrow"><img src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                <span>Enter the jurisdiction or to select another province start typing "Other Province..."<br>or if unknown check the "Out of Province" checkbox.</span>
                            </span>
                                </div>
                                <div class="col-lg-1 vertical-align-label">
                                    <label class="control-label">On</label>
                                </div>
                                <div class="col-lg-2 vertical-align-label">
                                    <dp-partial-date
                                        #documentsExecutedOn
                                        [inputDate]="matter.docsExecutedOn"
                                        fieldKey="matter.otherParty.docsExecutedOn"
                                        statusBarHelp
                                        dp-error-validator
                                        (dateChange)="onDateChangeDocsExecutedOnDate($event)">
                                    </dp-partial-date>
                                </div>

                            </div>

                            <div class="form-group" *dpShowByProvince="'matter.otherParty.documentsToBeSignedRemotely'">
                                <div *ngIf="isDocExecutedAtVisible() && matter && matter.isDigitalSignatureEnabledInProvince(appConfig)">
                                    <div class="col-lg-2 vertical-align-label">
                                        <label class="control-label" for="documentsToBeSignedRemotely">
                                            Will documents be signed remotely?
                                        </label>
                                    </div>
                                    <div class="col-lg-2 vertical-align-label">
                                        <select class="form-control"
                                                id="otherSideDocumentsToBeSignedRemotely"
                                                name="otherSideDocumentsToBeSignedRemotely"
                                                [(ngModel)]="matter.otherSideDocumentsToBeSignedRemotely"
                                                (change)="enableSave();">
                                            <option *ngFor="let status of yesNoDefaultNoOptions"
                                                    [value]="status.value">
                                                {{ status.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                        <div class="form-group" *ngIf="isPurchaseMatter">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Vendor's UFFI Warranty</label>
                            </div>
                            <div class="col-lg-10">
                                <select class="form-control"
                                        id="uffiWarrantyCode"
                                        name="uffiWarrantyCode"
                                        [(ngModel)]="matter.uffiWarrantyCode"
                                        (change)="uffiWarrantyCodeChanged()"
                                >
                                    <option *ngFor="let item of vendorWarrantyOptions" [value]="item.value">{{item.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="survivalAfterClosingVisible()">
                            <div class="col-lg-2 vertical-align-label">
                                <label class="control-label">Survival After Closing</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text"
                                       id="SurvivalAfterClosing"
                                       name="SurvivalAfterClosing"
                                       class="form-control"
                                       [(ngModel)]="matter.survivalAfterClosing"
                                       (change)="enableSave()"
                                       maxlength="50"
                                       trimmed
                                >
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <!--Vendor's Solicitor-->
            <div class="fields-group">
                <div class="main-level form-group">
                    <div class="col-lg-6">
                        <span class="title" *dpIfApplicableForMatter="'matter.otherParty.solicitorTitle.p'">
                            <span dp-province-field-label="matter.otherParty.solicitorTitleForPurchase">Vendor's Solicitor</span>
                        </span>
                        <span class="title" *dpIfApplicableForMatter="'matter.otherParty.solicitorTitle.S'">
                            <span dp-province-field-label="matter.otherParty.solicitorTitleForSale">Purchaser's Solicitor</span>
                        </span>
                        <span class="title" *dpIfApplicableForMatter="'matter.otherParty.solicitorTitle.M'" dp-province-field-label="matter.otherParty.solicitorTitle.M">Solicitor Details</span>
                        <span class="title" *dpIfApplicableForMatter="'matter.otherParty.solicitorTitle.c'" [label-province-code]="defaultProvinceCode"
                              dp-province-field-label="otherParty.staff.solicitorTitle">Other Solicitor</span>
                        <span class="title" *dpIfApplicableForMatter="'matter.otherParty.solicitorTitle.O'" [label-province-code]="defaultProvinceCode"
                              dp-province-field-label="otherParty.staff.solicitor">Other Side Solicitor</span>
                    </div>
                    <div class="text-right col-lg-6" *ngIf="lawSocietyMemberDirectoryLink()">
                    <span class="title">
                        <a href="{{lawSocietyMemberDirectoryLink()}}" target="_blank" style="padding: 24px">Law Society Member Directory</a>
                        <span *dpShowByProvince="'matter.otherParty.notarySocietyMemberLink'">
                            <a href="{{notarySocietyMemberLink()}}" target="_blank">Notary Society Member Directory</a>
                        </span>
                    </span>
                    </div>
                </div>



                <!-- Solicitor -->
                <ng-container  *ngTemplateOutlet="solicitor"></ng-container>


                <!-- Law firm -->
                <ng-container  *ngTemplateOutlet="lawFirm"></ng-container>

                <div class="form-group">
                    <label class="control-label col-lg-2">Dear</label>
                    <div class="col-lg-4">
                        <input type="text"
                               name="letterSalutation"
                               class="form-control"
                               [(ngModel)]="matter.otherPartyContactInfo.dear"
                               maxlength="75"
                               fieldKey="matter.otherPartyContactInfo.dear"
                               [dynamicHelpCallBackFunction]="generateF9OnDear()"
                               statusBarHelp
                               (keydown.f9)="handleF9OnDear()"
                               trimmed
                        />
                    </div>

                    <label class="control-label col-lg-2">File Number</label>
                    <div class="col-lg-4">
                        <input type="text"
                               name="File Number"
                               class="form-control"
                               [(ngModel)]="matter.otherPartyContactInfo.fileNumber"
                               maxlength="35"
                               trimmed
                        />
                    </div>

                </div>
            </div>

            <div class="fields-group">
                <!--Law Clerk-->
                <div class="main-level">
                    <span class="title" dp-province-field-label="matter.otherParty.lawClerkDetails">Law Clerk Details</span>
                </div>
                <ng-container  *ngTemplateOutlet="lawClerk"></ng-container>
                <div class="form-group">
                    <label class="control-label col-lg-2">Work Phone</label>
                    <div class="col-lg-4">
                        <input type="text"
                               name="LawClerkWorkPhone"
                               #workPhone="ngModel"
                               class="form-control"
                               [(ngModel)]="matter.otherPartyContactInfo.lawClerkWorkPhone"
                               maxlength="35"
                               trimmed
                        />
                    </div>

                    <label class="control-label col-lg-2">Fax</label>
                    <div class="col-lg-4">
                        <input type="text"
                               name="LawClerkFax"
                               class="form-control"
                               [(ngModel)]="matter.otherPartyContactInfo.lawClerkFaxPhone"
                               maxlength="35"
                               trimmed
                        />
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-lg-2">Cell Phone</label>
                    <div class="col-lg-4">
                        <input type="text"
                               name="LawClerkMobilePhone"
                               class="form-control"
                               [(ngModel)]="matter.otherPartyContactInfo.lawClerkCellPhone"
                               maxlength="35"
                               trimmed
                        />
                    </div>

                    <label class="control-label col-lg-2" dp-province-field-label="matter.otherParty.lawClerkEmail">Law
                        Clerk's Email</label>
                    <div class="col-lg-4">
                        <div class="has-feedback">
                            <input type="text"
                                   id="LawClerkEmail"
                                   name="LawClerkEmail"
                                   [dp-email-field]="emailFieldKey"
                                   #lawClerkEmail="ngModel"
                                   class="form-control"
                                   [fieldKey]="'lawclerk.lawClerkEmail'"
                                   [dp-error-validator]="{isEmail : true}"
                                   [(ngModel)]="matter.otherPartyContactInfo.lawClerkEmail"
                                   (change)="handleLawClerkChanged()"
                                   maxlength="75"
                                   trimmed
                                   multi-email-validator
                            />

                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="matter && !matter.isCustomMatter()">
                <ng-container *dpIfApplicableForMatter="'matter.otherParty.directDeposit'">
                    <!--Excess funds direct deposit section-->
                    <div class="form-group">
                        <div class="col-lg-2  vertical-align-label">
                            <label class="control-label"
                                   *dpIfApplicableForMatter="'matter.otherParty.directDeposit.label.P'"
                            >Direct deposit to the<br/> <span dp-province-field-label="matter.otherParty.directDeposit.label.purchase"> vendor solicitor's </span><br/>trust account</label>
                            <label class="control-label"
                                   *dpIfApplicableForMatter="'matter.otherParty.directDeposit.label.M'"
                            >Direct deposit to other<br/>{{getSolicitorLabel()}}'s trust account</label>
                        </div>
                        <div class="col-lg-1">
                            <select class="form-control"
                                    id="excessFundsDirectDepositChoice"
                                    name="excessFundsDirectDepositChoice"
                                    [(ngModel)]="matter.directDepositInstructionOtherParty.directDepositChoice"
                                    (change)="handleDirectDepositOption($event.target.value)">
                                <option *ngFor="let ddOption of directDepositOptions" [value]="ddOption.value">
                                    {{ddOption.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="isDirectDepositToTrustAccount">
                        <dp-direct-deposit-account
                            [ddInstruction]="matter.directDepositInstructionOtherParty"
                            [fieldPrefix]="'matter.otherParty'"
                            [currectSection]="'Vendors & Solicitor'"
                            [clientOrSolicitor]="resolveClientSolicitorKey()"
                            (change)="enableSave()"
                        >
                        </dp-direct-deposit-account>
                    </div>

                </ng-container>
            </ng-container>

            <dp-user-defined-fields [topicName]="topicName" (onChange)="enableSave()">
            </dp-user-defined-fields>
        </form>
    </div>
</ng-container>
<!--<dp-vendors [typedPurchaserName]="typedPurchaserNameFull" (newVendorCreated)="updateOnVendorCreated($event)">-->
<!--</dp-vendors>-->
<ng-container *ngIf="!!showWizardFields">
    <div class="form-horizontal" *ngIf="!matter.isMortgage">
        <div [ngClass]="showWizardFields? 'col-lg-12' : 'col-lg-12 form-group'">
            <ng-container  *ngTemplateOutlet="otherparty"></ng-container>
        </div>
    </div>

    <div class="form-horizontal">
        <div [ngClass]="showWizardFields? 'col-lg-12' : 'col-lg-12 form-group'">
            <ng-container  *ngTemplateOutlet="solicitor"></ng-container>
        </div>
    </div>
    <div class="form-horizontal">
        <div [ngClass]="showWizardFields? 'col-lg-12' : 'col-lg-12 form-group'">
            <ng-container  *ngTemplateOutlet="lawFirm"></ng-container>
        </div>
    </div>
    <div class="form-horizontal">
        <div [ngClass]="showWizardFields? 'col-lg-12 padding-bottom-10' : 'col-lg-12 form-group'">
            <ng-container  *ngTemplateOutlet="lawClerk"></ng-container>
        </div>
    </div>

</ng-container>
<ng-template #otherparty>
    <div [id]="otherPartyContainerId" class="xpath-parent-container">
        <div *ngFor="let selectedOtherParty of selectedOtherParties; let i=index"
             [ngClass]="{'shutter-bg': isExpanded(selectedOtherParty), 'shutter-bg-closed': !selectedOtherParty.editMode && !isExpanded(selectedOtherParty)}">
            <div class="xpath-child-container">
                <div class="form-group">
                    <div class="shutter-arrow" (click)="toggleContactSnapshot(selectedOtherParty, true)">
                        <div *ngIf="isExpanded(selectedOtherParty)"><img src="/assets/img/minus.svg"
                                                                         alt=""/></div>
                        <div *ngIf="!selectedOtherParty.editMode && !isExpanded(selectedOtherParty)"><img
                            src="/assets/img/plus.svg"/></div>
                    </div>

                    <div *ngIf="selectedOtherParty.showLabel || !!showWizardFields">
                        <span class="col-lg-2 control-label" (click)="toggleContactSnapshot(selectedOtherParty, true)"
                              [class.focus]="fieldHoverIndex===11">
                                {{matter.otherPartyTitle}} {{i + 1}}
                        </span>
                    </div>
                    <div *ngIf="selectedOtherParty && !selectedOtherParty.showLabel" class="col-lg-2">

                    </div>
                    <div class="col-lg-10 vendor fullLength-autocomplete"
                         id="clientSurname_{{i}}"
                         [class.custom]="selectedOtherParty && !selectedOtherParty.editMode">
                        <input type="text" class="form-control rightPadding350"
                               readonly="readonly"
                               *ngIf="!selectedOtherParty.editMode"
                               [value]="selectedOtherParty.dataModel.genericFullName"
                               (click)="toggleContactSnapshot(selectedOtherParty, true)"
                               id="clientFieldIndex_{{selectedOtherParty.matterParticipant?.matterParticipantId}}"
                               fieldKey="matter.mainClient.vendor.shutter"
                               (keydown.f9)="toggleContactSnapshot(selectedOtherParty, true)"
                               statusBarHelp
                               autocomplete="off"/>
                        <p-autoComplete *ngIf="selectedOtherParty.editMode"
                                        id="autocomplete-client"
                                        placeholder='Search by "Surname, First Name" or Add new'
                                        name="{{i}}"
                                        [(ngModel)]="selectedOtherParty.dataModel"
                                        [class.autocomplete-searching]="loadingOtherParties"
                                        [suggestions]="matchedOtherParties"
                                        (completeMethod)="search($event)"
                                        field="genericFullName"
                                        [minLength]="0"
                                        [delay]="1000"
                                        maxlength="100"
                                        scrollHeight="530px"
                                        (focusout)="cleanupOtherPartyField(selectedOtherParty)"
                                        (onSelect)="dataSelectedOtherParty(i)"
                                        autocomplete="off" class="autocomplete-search-icon">
                            <!-- Removed Help Text function for above autoCompleted:
                            (click)="showClientPurchaserHelpText(selectedOtherParty)"
                            -->

                            <ng-template let-otherParty pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                    [ngClass]="{'bold-font' : isAddNew(otherParty)}"
                                    [disableItem]="otherParty">
                                    <span class="col-lg-3 c1"
                                          *ngIf="autoCompleteEmptyFlag === true">
                                        {{ otherParty.displayName || otherParty.contactName.fullName || otherParty.contactName.displayName }}
                                    </span>
                                    <span class="col-lg-3 c2"
                                          *ngIf="autoCompleteEmptyFlag === false">
                                        {{ getNameValue(otherParty) || otherParty.displayName || otherParty.contactName.lastName }}
                                    </span>
                                    <span class="col-lg-6 c3"
                                          *ngIf="autoCompleteEmptyFlag === false">
                                        {{ getAddressValue(otherParty)}}
                                    </span>
                                    <span class="col-lg-2 c4"
                                          *ngIf="autoCompleteEmptyFlag === false">
                                        {{ getGenderValue(otherParty.gender) }}
                                    </span>
                                    <span class="col-lg-1 c4"
                                          *ngIf="autoCompleteEmptyFlag === false">
                                        {{ getDeceasedString(otherParty) }}
                                    </span>
                                </li>
                            </ng-template>
                        </p-autoComplete>

                        <!-- Action buttons -->
                        <div class="actionbtns" *ngIf="!selectedOtherParty.editMode">
                            <!--<div class="actionbtns" *ngIf="isMatterLocked || !selectedOtherParty.editMode">-->
                            <div class="width-132">
                                <span class="primary radio" (click)="setAsPrimaryOtherParty(selectedOtherParty)">
                                  <input type="radio" [checked]="selectedOtherParty.primary">
                                  <label>Primary</label>
                                </span>
                            </div>
                            <ng-container *ngIf="matter && !matter.isCustomMatter()">
                                <div class="width-100" *dpShowByProvince="'matter.otherParty.flaTab'">
                                <span
                                    *ngIf="selectedOtherParty.matterParticipant &&
                                    isFamilyLawActVisible(selectedOtherParty.matterParticipant) &&
                                    selectedOtherParty.matterParticipant.incompleteFamilyLawAct(matter.provinceCode)"
                                    (click)="onClickFlaWarningIcon(selectedOtherParty)">
                                    <i class="fa fa-warning"></i>
                                    <span class="span-link" dp-province-field-label="participant.fla.indicator.label"
                                          [title]="getAllFamilyLawActAlerts(selectedOtherParty.matterParticipant)">FLA</span>
                                </span>
                                </div>

                                <div class="width-80"
                                     *dpIfApplicableForMatter="'matter.otherParty.purchasersCapacity'">
                                            <span
                                                *ngIf="showCapacityWarningForOtherParty(selectedOtherParty.matterParticipant)"
                                                (click)="onClickCapacityWarningIcon(selectedOtherParty)">
                                                <i class="fa fa-warning"></i>
                                                <span class="span-link"
                                                      dp-province-field-label="matter.purchaser.capacity">Capacity</span>
                                            </span>
                                </div>
                            </ng-container>
                            <div class="width-30 out-of-sync-msg-icon">
                                <span *ngIf="selectedOtherParty.isStale">
                                    <!--<i class="fa fa-flag" aria-hidden="true">-->
                                         <span class="text-black out-of-sync-msg"><img
                                             src="/assets/img/old-icon-18x18.png"/> </span>
                                                   <span class="matter-locked-message matter-msg-locked-alt">
                                                        <div class="bubble-arrow"><img
                                                            src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                                        <span>
                                                           <u><i>Matter details out of sync with contact, refer to burger menu for options</i></u>
                                                        </span>
                                                    </span>
                                </span>
                            </div>
                            <div class="width-20">
                                <dp-burger-menu *ngIf="!!selectedOtherParty.matterParticipant"
                                                [openSubMenuOnLeft]="true"
                                                [allowPopUp]="true"
                                                [items]="buildOtherSideClientsBurgerMenu(selectedOtherParty, i)"
                                                [disabledItems]="selectedOtherParty.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                (itemClicked)="clickOtherPartyBurgerMenu($event, selectedOtherParty)">
                                </dp-burger-menu>
                            </div>
                        </div>
                        <!-- //END -- Action buttons -->
                    </div>
                </div>

                <!--Add Tap  -->
                <div class="form-group"
                     *ngIf="(selectedOtherParty.matterParticipant && isExpanded(selectedOtherParty))">
                    <div id="snapshot-{{selectedOtherParty.id}}"
                         class="padding-top-10 col-lg-10 offset-lg-2">
                        <div class="form-group-warning-message"
                             *ngIf="selectedOtherParty.sourceContactIsLocked">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    The {{matter && matter.otherPartyTitle ? (matter.otherPartyTitle).toLowerCase() : vendor}} is currently locked by {{selectedOtherParty &&
                                selectedOtherParty.lockedSourceContact &&
                                selectedOtherParty.lockedSourceContact.lockedByUser.fullName}}.
                                </p>
                            </div>
                        </div>
                        <div class="form-group-warning-message"
                             *ngIf="!selectedOtherParty.isLockedElsewhere && selectedOtherParty.isStale">
                            <div class="col-lg-1">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            </div>
                            <div class="col-lg-11">
                                <p>
                                    {{selectedOtherParty.outOfSyncPreMsg}}
                                    <a href="javascript:void(0);"
                                       (click)="openSourceContactTab(selectedOtherParty)"
                                       class="line-height-adjust">
                                        {{selectedOtherParty.outOfSyncUnderlineMsg}}</a>
                                    {{selectedOtherParty.outOfSyncPostFixMsg}}
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <ul class="dppm-custom-tab">
                                <li [ngClass]="{active: selectedOtherParty.isTabSelected('Profile')}"
                                    (click)="selectTab('Profile', selectedOtherParty)" tabindex="0"
                                    (keyDown.enter)="selectTab('Profile', selectedOtherParty)">
                                    <a>Profile</a>
                                </li>
                                <ng-container *ngIf="matter && !matter.isCustomMatter()">
                                    <ng-container *dpIfApplicableForMatter="'matter.otherParty.capacity'">
                                        <li *ngIf="isCapacityVisible"
                                            [ngClass]="{active: selectedOtherParty.isTabSelected('Capacity-Share')}"
                                            (click)="selectTab('Capacity-Share', selectedOtherParty)"
                                            tabindex="0"
                                            (keyDown.enter)="selectTab('Capacity-Share', selectedOtherParty)">
                                            <a dp-province-field-label="matter.purchaser.capacity">Capacity</a>
                                        </li>
                                    </ng-container>
                                    <ng-container *dpShowByProvince="'matter.otherParty.flaTab'">
                                        <li *ngIf="isFamilyLawActVisible(selectedOtherParty.matterParticipant)"
                                            [ngClass]="{active: selectedOtherParty.isTabSelected('Family-Law-Act')}"
                                            (click)="selectTab('Family-Law-Act', selectedOtherParty)"
                                            tabindex="0"
                                            (keyDown.enter)="selectTab('Family-Law-Act', selectedOtherParty)">
                                            <a dp-province-field-label="participant.fla.tab.label">Family Law
                                                Act</a>
                                        </li>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="hasSourceContact(selectedOtherParty)">
                                    <li [ngClass]="{active: selectedOtherParty.isTabSelected('Notes')}"
                                        (click)="selectTab('Notes', selectedOtherParty)" tabindex="0"
                                        (keyDown.enter)="selectTab('Notes', selectedOtherParty)">
                                        <a>{{selectedOtherParty.getNotesTab()}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>

                        <div class="col-lg-12 margin-top-20">
                            <div [ngSwitch]="selectedOtherParty.selectedTab">
                                <dp-log-diff-snapshot-source [wrapper]="selectedOtherParty">
                                </dp-log-diff-snapshot-source>
                                <div
                                    [dp-read-only-block]="isOtherPartyParticipantDataReadOnly(selectedOtherParty)"
                                    [elementRefComp]="elementRef">
                                    <dp-new-manage-contact *ngSwitchCase="'Profile'"
                                                           [contact]="selectedOtherParty.matterParticipant?.contact"
                                                           [participant]="selectedOtherParty.matterParticipant"
                                                           [matter]="matter"
                                                           [isEditable]="!isOtherPartyParticipantDataReadOnly(selectedOtherParty)"
                                                           [closeOtherInfoShutter]="true"
                                                           (onDataChange)="onOtherPartyDataChanged()"
                                                           [fieldPrefix]="'matter.otherParty'"
                                                           [fieldIndexId]="'clientFieldIndex'"
                                                           [fieldIdentifier]="selectedOtherParty.matterParticipant?.matterParticipantId"
                                                           [containerId]="otherPartyContainerId"
                                                           [isVisibleWithoutSourceContact]="true"
                                                           [addSignerMatterParticipantToMatter]="true"
                                                           [isOtherParty]="true"
                                                           [allClientDetailsAvailable]="hasSourceContact(selectedOtherParty)"
                                                           contactKey="CLIENT">
                                    </dp-new-manage-contact>
                                </div>
                                <ng-container *ngIf="matter && !matter.isCustomMatter()">
                                    <ng-container *dpIfApplicableForMatter="'matter.otherParty.capacity'">
                                        <dp-capacityShare *ngSwitchCase="'Capacity-Share'"
                                                          [matterParticipant]="selectedOtherParty.matterParticipant"
                                                          [provinceCode]="matter.provinceCode"
                                                          [readOnly]="matter.otherPartiesCapacity != 'OTHER' || (selectedOtherParty.sourceContactIsLocked && !selectedOtherParty.matterParticipant?.sourceContactLockAcquired)"
                                                          (updateCapacity)="enableSave()"
                                                          [purchaserId]="selectedOtherParty.matterParticipantId">
                                        </dp-capacityShare>
                                    </ng-container>
                                    <dp-familyLawAct *ngSwitchCase="'Family-Law-Act'"
                                                     [shouldCreateSourceContact]="selectedOtherParty.sourceContactId"
                                                     [flaReadOnly]="(selectedOtherParty.sourceContactIsLocked && !selectedOtherParty.matterParticipant?.sourceContactLockAcquired)"
                                                     [matter]="matter"
                                                     [matterTypeSensitive]="'true'"
                                                     [participant]="selectedOtherParty.matterParticipant"
                                                     [spouseNameOptions]="selectedOtherParty.spouseNameOptions"
                                                     [fieldPrefix]="otherPartyFieldPrefix"
                                                     [fieldIndexId]="otherPartyFieldIndexId"
                                                     [fieldIdentifier]="selectedOtherParty.matterParticipant?.matterParticipantId"
                                                     [containerId]="otherPartyContainerId"
                                                     [flaStatements]="flaStatements"
                                                     (saveForm)="updateFamilyLawActFormSave()">
                                    </dp-familyLawAct>
                                </ng-container>
                                <div>
                                    <!--The following is the contact notes section (with the add button and such). -->
                                    <dp-contact-notes *ngSwitchCase="'Notes'"
                                                      [contact]="selectedOtherParty.matterParticipant?.contact"
                                                      (onDataChange)="enableSave()"
                                                      [isReadOnly]="isOtherPartyParticipantDataReadOnly(selectedOtherParty)"
                                                      [matter]="matter"
                                    >
                                    </dp-contact-notes>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add another Other Party link -->
    <div class="form-group">
        <div class="col-lg-10 offset-lg-2">
            <div class="" style="float: left; clear: none;"
                 *ngIf="!getEditedOtherParties() && (selectedOtherParties && selectedOtherParties.length < appConfig.getMaxNumberOfOtherParties())">
                             <span>
                              <a href="javascript:void(0);" (click)="addNewOtherParty()">
                                  Add Another  {{matter.otherPartyTitle | titlecase}}
                              </a>
                            </span>
                <span class="selector fa fa-plus-square-o"></span>
            </div>

            <ng-container *dpShowByProvince="'matter.otherParty.offeror'">
                            <span *ngIf="hasOfferorsThatCanBeAddedAsPurchasers()" class="margin-left-20">
                                <a href="javascript:void(0);" (click)="addAllOfferorsAsPurchasers()">Add All Offerors as Purchasers</a>
                                <span class="selector fa fa-plus-square-o"></span>
                            </span>
            </ng-container>
            <ng-container *ngIf="!matter?.isOpportunityMatter()">
                            <span class="margin-left-20" [class.disabled]="otherParties.length == 0" *dpShowByProvince="'matter.mainClient.client.titleDetailsLink'">
                                <a href="javascript: void(0)" (click)="showPurchasersTitleDetails()" *ngIf="otherParties.length > 0">
                                    Title Details
                                </a>
                                <span *ngIf="otherParties.length == 0">Title Details</span>
                                <span class="selector fa fa-eye"></span>
                            </span>
            </ng-container>
        </div>
    </div>
</ng-template>
<ng-template #solicitor>
    <div class="form-group" *ngIf="selectedSolicitor.editMode">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label" dp-province-field-label="matter.title.solicitorName" >
                Solicitor's Name
            </label>
        </div>
        <div class="col-lg-10">
            <p-autoComplete
                id="solicitor"
                name="solicitor"
                #solicitor
                [delay]="1000"
                [placeholder]="solicitorNameHelpText"
                [(ngModel)]="selectedSolicitor.dataModel"
                [class.autocomplete-searching]="solicitorsLoading"
                (onSelect)="dataSelectedSolicitor()"
                (focusout)="onLeavingSolicitorOmniBarWithoutFreeInput(selectedSolicitor)"
                [suggestions]="solicitors"
                [class.dropdown-arrow-refferedBy]="true"
                [dropdown]="showSolicitorDropDownArrow"
                (keydown.space)="utils.handleSpaceKeyDropdown($event, selectedSolicitor.dataModel, handleDropDownClickSolicitorPickList)"
                (keydown.enter)="utils.handleEnterKeyDropdown($event, selectedSolicitor.dataModel, handleDropDownClickSolicitorPickList)"
                (onDropdownClick)="handleDropDownClickSolicitorPickList($event)"
                (completeMethod)="searchSolicitor($event)"
                field="firstLastNames"
                [minLength]="0"
                scrollHeight="530px"
                autocomplete="off"
                [ngClass]="{'autocomplete-search-icon': !showSolicitorDropDownArrow && !manualEntryOfSolicitor}"
            >
                <ng-template let-solicitor pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix"
                        [ngClass]="{'bold-font' : isAddNew(solicitor)}"
                        [disableItem]="solicitor">
                        <div *ngIf="solicitor.instanceCreationTime">
                            <span class="col-lg-3 text-overflow">{{solicitor.fullName}}</span>
                            <span class="col-lg-2 text-overflow">{{solicitor.firmName}}</span>
                            <span class="col-lg-5 text-overflow">{{solicitor.mailingAddress?.addressTextWithProvinceName}}</span>
                        </div>
                        <div *ngIf="!solicitor.instanceCreationTime">
                            <span class="col-lg-6 text-overflow">{{solicitor.displayName}}</span>
                        </div>
                        <span class="col-lg-2 text-overflow">{{solicitor.contactName?.initials}}</span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>
    <div *ngIf="!selectedSolicitor.editMode"
         [ngClass]="{'shutter-bg': isExpanded(selectedSolicitor),'shutter-bg-closed': !isExpanded(selectedSolicitor)}">
        <!--<div class="form-group">-->
        <div class="form-group">
            <div class="shutter-arrow" (click)="toggleContactSnapshot(selectedSolicitor)">
                <div *ngIf="isExpanded(selectedSolicitor)"><img src="/assets/img/minus.svg" alt=""/>
                </div>
                <div *ngIf="!isExpanded(selectedSolicitor)"><img
                    src="/assets/img/plus.svg"/></div>
            </div>
            <div>
                <span class="col-lg-2 control-label" dp-province-field-label="matter.title.solicitorName" (click)="toggleContactSnapshot(selectedSolicitor)">
                    {{setSolicitorType(selectedSolicitor?.matterParticipant?.contact)}}
                </span>
            </div>
            <div>
                <div class="col-lg-10 client-purchaser fullLength-autocomplete">
                    <input type="text"
                           id="lawFirm_{{selectedSolicitor?.matterParticipant?.matterParticipantId}}"
                           class="form-control rightPadding482 solicitor-lawfirm"
                           readonly="readonly"
                           (click)="toggleContactSnapshot(selectedSolicitor)"
                           (keydown.f9)="toggleContactSnapshot(selectedSolicitor)"
                           [value]="getSolicitorName(selectedSolicitor)"
                           fieldKey="matter.Vendors.solicitor.shutter"
                           statusBarHelp
                           autocomplete="off"/>
                    <div class="actionbtns">
                        <div class="width-60">
                                        <span *ngIf="showGlobalWindow(selectedSolicitor)">
                                            <i class="fa fa-globe padding-top-5" aria-hidden="true"></i>
                                        </span>
                            <span
                                *ngIf="showAddressBookWindow(selectedSolicitor)">
                                            <i class="fa fa-address-book padding-top-5" aria-hidden="true"></i>
                                        </span>
                        </div>
                        <div class="width-60">
                                        <span *ngIf="selectedSolicitor.isStale">
                                            <span class="text-black out-of-sync-msg"><img
                                                src="/assets/img/old-icon-18x18.png"/> </span>
                                                   <span class="matter-locked-message">
                                                        <div class="bubble-arrow"><img
                                                            src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                                        <span>
                                                           <u><i>Matter details out of sync with contact, refer to burger menu for options</i></u>
                                                        </span>
                                                    </span>

                                        </span>
                        </div>
                        <div class="width-30 actionbtns">
                            <dp-burger-menu *ngIf="!!selectedSolicitor.matterParticipant"
                                            [openSubMenuOnLeft]="true"
                                            [allowPopUp]="true"
                                            [items]="getBurgerMenuItems(selectedSolicitor)"
                                            [disabledItems]="getDisabledItems(selectedSolicitor)"
                                            (itemClicked)="clickBurgerMenu($event, selectedSolicitor)">
                            </dp-burger-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isSolicitorOnSectionVisible(selectedSolicitor)">
            <div class="form-group">
                <label class="control-label col-lg-2">Work Phone</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="solicitorWorkPhone"
                           name="solicitorWorkPhone"
                           class="form-control"
                           dp-read-only="true"
                           [(ngModel)]="selectedSolicitor.matterParticipant.contact.workPhone"
                    />
                </div>

                <label class="control-label col-lg-2">Fax</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="solicitorFaxPhone"
                           name="solicitorFaxPhone"
                           class="form-control"
                           dp-read-only="true"
                           [(ngModel)]="selectedSolicitor.matterParticipant.contact.faxPhone"
                    />
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2">Cell Phone</label>
                <div class="col-lg-4">
                    <input type="text"
                           id="solicitorMobilePhone"
                           name="solicitorMobilePhone"
                           class="form-control"
                           dp-read-only="true"
                           [(ngModel)]="selectedSolicitor.matterParticipant.contact.cellPhone"
                    />
                </div>

                <label class="control-label col-lg-2">Email</label>
                <div class="col-lg-4">
                    <div class="has-feedback">
                        <input type="text"
                               id="solicitorEmail"
                               name="solicitorEmail"
                               class="form-control"
                               [dp-email-field]="emailFieldKey"
                               dp-read-only="true"
                               [(ngModel)]="selectedSolicitor.matterParticipant.contact.email"
                        />

                    </div>
                </div>
            </div>
        </ng-container>
        <!--</div>-->
        <!--<div  class="dp-accordion-content">-->
        <div *ngIf="showSolicitorSnapshot(selectedSolicitor)">
            <div class="right margin-bottom-20 offset-lg-10"
                 *ngIf="!isMyOwnAccountSolicitor() && selectedSolicitor.isOwnedBySystemAccount">
                            <span *ngIf="!isLocked">
                                <a href="javascript:void(0);"
                                   (click)="editAsPrivateCopyAction(selectedSolicitor)">
                                    Edit as Private Copy
                                </a>
                            </span>
                <span *ngIf="isLocked">Edit as Private Copy</span>
            </div>
            <div class="form-group-warning-message padding-top-10 col-lg-10 offset-lg-2"
                 *ngIf="selectedSolicitor.sourceContactIsLocked">
                <div class="col-lg-1">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
                <div class="col-lg-11">
                    <p>
                        The Solicitor is currently locked by {{selectedSolicitor &&
                    selectedSolicitor.lockedSourceContact &&
                    selectedSolicitor.lockedSourceContact.lockedByUser.fullName}}.
                    </p>
                </div>
            </div>
            <div class="form-group-warning-message padding-top-10 col-lg-10 offset-lg-2"
                 *ngIf="isStale(selectedSolicitor)">
                <div class="col-lg-1">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
                <div class="col-lg-11">
                    <p>
                        {{selectedSolicitor.outOfSyncPreMsg}}
                        <a href="javascript:void(0);"
                           (click)="openSourceSolicitorTab()" class="line-height-adjust">
                            {{selectedSolicitor.outOfSyncUnderlineMsg}}</a>
                        {{selectedSolicitor.outOfSyncPostFixMsg}}
                    </p>
                </div>
            </div>
            <div>
                <dp-solicitor-snapshot [readonly]="solicitorSnapshotReadOnly"
                                       [solicitorAddressDd]="solicitorAddressDd"
                                       [fieldPrefix]="'matter.otherParty'"
                                       [solicitor]="selectedSolicitor?.matterParticipant?.contact"
                                       (solicitorInfoChanged)="notifyChange()">
                </dp-solicitor-snapshot>
            </div>

        </div>
        <!--</div>-->
    </div>
</ng-template>
<ng-template #lawFirm>
    <div class="form-group" *ngIf="selectedLawFirm.editMode">
        <div class="col-lg-2 vertical-align-label">
            <label class="control-label">Firm Name</label>
        </div>
        <div class="col-lg-10">
            <div>
                <p-autoComplete
                    id="lawFirm"
                    name="lawFirm"
                    maxlength="200"
                    delay="1000"
                    inputStyleClass="law-firm-autocomplete"
                    placeholder="Search by Name, Address or Add new"
                    [class.autocomplete-searching]="lawFirmsLoading"
                    [suggestions]="lawFirms"
                    (completeMethod)="searchLawFirm($event)"
                    field="legalFirmName"
                    (onSelect)="dataSelectedLawFirmTimeout()"
                    [(ngModel)]="selectedLawFirm.dataModel"
                    (focusout)="onLeavingLawFirmOmniBarWithoutFreeInput(selectedLawFirm)"
                    scrollHeight="530px" [ngClass]="{'autocomplete-search-icon': !selectedLawFirm.dataModel}">
                    <ng-template let-lawfirm pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                            [ngClass]="{'bold-font' : isAddNew(lawfirm)}"
                            [disableItem]="lawfirm">
                            <div *ngIf="lawfirm.instanceCreationTime">
                                <span class="col-lg-6 text-overflow">{{lawfirm.legalFirmName}}</span>
                                <span class="col-lg-6 text-overflow">{{lawfirm.mailingAddress?.addressTextWithProvinceName}}</span>
                            </div>
                            <div *ngIf="!lawfirm.instanceCreationTime">
                                <span class="col-lg-6 text-overflow">{{lawfirm.displayName}}</span>
                            </div>
                        </li>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
    </div>

    <div *ngIf="!selectedLawFirm.editMode"
         [ngClass]="{'shutter-bg': isExpanded(selectedLawFirm),'shutter-bg-closed': !isExpanded(selectedLawFirm)}">
        <!--<div class="form-group">-->
        <div class="form-group">
            <div class="shutter-arrow" (click)="toggleLawFirmContactSnapshot()">
                <div *ngIf="isExpanded(selectedLawFirm)"><img src="/assets/img/minus.svg" alt=""/></div>
                <div *ngIf="!isExpanded(selectedLawFirm)"><img
                    src="/assets/img/plus.svg"/></div>
            </div>
            <div>
                        <span class="col-lg-2 control-label" (click)="toggleLawFirmContactSnapshot()">
                            Firm Name
                        </span>
            </div>
            <div>
                <div class="col-lg-10 client-purchaser fullLength-autocomplete">
                    <input type="text"
                           id="lawFirm_{{selectedLawFirm?.matterParticipant?.matterParticipantId}}"
                           class="form-control rightPadding482 solicitor-lawfirm"
                           readonly="readonly"
                           (click)="toggleLawFirmContactSnapshot()"
                           (keydown.f9)="toggleLawFirmContactSnapshot()"
                           [value]="getLawFirmName(selectedLawFirm)"
                           fieldKey="matter.Vendors.LawFirm.shutter"
                           statusBarHelp
                           autocomplete="off"/>
                    <div class="actionbtns">
                        <div class="width-60">
                                        <span *ngIf="showGlobalWindow(selectedLawFirm)">
                                            <i class="fa fa-globe padding-top-5" aria-hidden="true"></i>
                                        </span>
                            <span
                                *ngIf="showAddressBookWindow(selectedLawFirm)">
                                            <i class="fa fa-address-book padding-top-5" aria-hidden="true"></i>
                                        </span>
                        </div>

                        <div class="width-60">
                                            <span *ngIf="selectedLawFirm.isStale">
                                                <!--<i class="fa fa-flag" aria-hidden="true">-->
                                                    <span class="text-black out-of-sync-msg"><img
                                                        src="/assets/img/old-icon-18x18.png"/> </span>
                                                   <span class="matter-locked-message matter-message-alt">
                                                        <div class="bubble-arrow"><img
                                                            src="/assets/img/bubble_arrow_top.png" alt=""/></div>
                                                        <span>
                                                           <u><i>Matter details out of sync with contact, refer to burger menu for options</i></u>
                                                        </span>
                                                    </span>
                                            </span>
                        </div>
                        <div class="width-30 actionbtns">
                            <dp-burger-menu *ngIf="!!selectedLawFirm.matterParticipant"
                                            [openSubMenuOnLeft]="true"
                                            [allowPopUp]="true"
                                            [items]="getBurgerMenuItems(selectedLawFirm, true)"
                                            [disabledItems]="getDisabledItems(selectedLawFirm, true)"
                                            (itemClicked)="clickBurgerMenu($event, selectedLawFirm, true)">
                            </dp-burger-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--</div>-->
        <!--<div  class="dp-accordion-content">-->
        <div class="form-group" *ngIf="showLawFirmSnapshot(selectedLawFirm)">
            <div class="right margin-bottom-20 offset-lg-10"
                 *ngIf="!isMyOwnAccountLawFirm() && selectedLawFirm.isOwnedBySystemAccount">
                            <span *ngIf="!isLocked">
                                <a href="javascript:void(0);"
                                   (click)="editAsPrivateCopyAction(selectedLawFirm)">
                                    Edit as Private Copy
                                </a>
                            </span>
                <span *ngIf="isLocked">Edit as Private Copy</span>
            </div>
            <div class="form-group-warning-message offset-lg-2"
                 *ngIf="selectedLawFirm.sourceContactIsLocked">
                <div class="col-lg-1">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
                <div class="col-lg-11">
                    <p>
                        The Law Firm is currently locked by {{selectedLawFirm &&
                    selectedLawFirm.lockedSourceContact &&
                    selectedLawFirm.lockedSourceContact.lockedByUser.fullName}}.
                    </p>
                </div>
            </div>
            <div class="form-group-warning-message offset-lg-2"
                 *ngIf="isStale(selectedLawFirm)">
                <div class="col-lg-1">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
                <div class="col-lg-11">
                    <p>
                        {{selectedLawFirm.outOfSyncPreMsg}}
                        <a href="javascript:void(0);"
                           (click)="openSourceContactTab(selectedLawFirm)" class="line-height-adjust">
                            {{selectedLawFirm.outOfSyncUnderlineMsg}}</a>
                        {{selectedLawFirm.outOfSyncPostFixMsg}}
                    </p>
                </div>
            </div>
            <div>
                <dp-law-firm-snapshot [readonly]="lawFormSnapshotReadOnly"
                                      [showAllLawFirmSnapshotFields]="showAllLawFirmSnapshotFields(selectedLawFirm)"
                                      [fieldPrefix]="'matter.otherParty'"
                                      [matter]="matter"
                                      [lawFirm]="selectedLawFirm?.matterParticipant?.contact"
                                      (lawFirmInfoChanged)="notifyChange()">
                </dp-law-firm-snapshot>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #lawClerk>
    <div class="form-group">
        <label class="control-label col-lg-2" dp-province-field-label="matter.otherParty.lawClerkName">Law
            Clerk's Name</label>
        <div class="col-lg-10">
            <p-autoComplete
                id="lawClerkName"
                name="lawClerkName"
                #lawClerkName
                [placeholder]="lawClerkPlaceHolder"
                [(ngModel)]="selectedLawClerk"
                [suggestions]="lawClerks"
                (ngModelChange)="onLawClerkChange($event)"
                (change)="handleLawClerkChanged()"
                [class.dropdown-arrow-refferedBy]="true"
                [dropdown]="showLawClerksDropDownArrow"
                (keydown.space)="utils.handleSpaceKeyDropdown($event, selectedLawClerk, handleDropDownClickLawClerksPickList)"
                (keydown.enter)="utils.handleEnterKeyDropdown($event, selectedLawClerk, handleDropDownClickLawClerksPickList)"
                (onDropdownClick)="handleDropDownClickLawClerksPickList($event)"
                [minLength]="0"
                [delay]="1000"
                maxlength="{{isOpportunityActingForBoth() ? 0 : 75}}"
                scrollHeight="350px"
                (onSelect)="dataSelectedLawClerks($event)"
                autocomplete="off">
                <ng-template let-lawClerk pTemplate="item">
                    <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="lawClerk">
                        <div *ngIf="lawClerk.instanceCreationTime">
                            <span class="col-lg-3 text-overflow">{{lawClerk.fullName}}</span>
                            <span class="col-lg-2 text-overflow">{{lawClerk.firmName}}</span>
                            <span class="col-lg-5 text-overflow">{{lawClerk.mailingAddress?.addressTextWithProvinceName}}</span>
                        </div>
                        <div *ngIf="!lawClerk.instanceCreationTime">
                            <span class="col-lg-6 text-overflow">{{lawClerk.displayName}}</span>
                        </div>
                        <span class="col-lg-2 text-overflow">{{lawClerk.contactName?.initials}}</span>
                    </li>
                </ng-template>
            </p-autoComplete>
        </div>
    </div>
</ng-template>
