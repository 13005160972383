import {Injectable} from '@angular/core';
import {CopyMatterService} from './copy-matter.service';
import {constValues} from './shared/const-values';
import {Contact} from './shared/contact';
import {Matter} from './shared/matter';
import {MatterParticipant} from './shared/matter-participant';
import {MatterProperty} from './shared/matter-property';
import {MatterUtil} from './shared/matter-util';
import {JournalNote, NotesList} from '../admin/account-notes/account-notes';
import {ReferredByInfo} from './shared/referred-by-info';
import {HttpClient} from '../core';
import {MatterOpeningComponent} from './matter-opening';
import {ExistingMortgageComponent} from './mortgages/mortgage/existing-mortgage/existing-mortgage.component';
import {MatterParticipantService} from './matter-participant-service';
import {MortgageeComponent} from './mortgages/mortgage/mortgagee/mortgagee.component';
import {AttentionInfoComponent} from './matter-opening/attention/attention-info.component';
import {CondoCorporationComponent} from './condo-corporation';
import {PropertyTeranetComponent} from './property-teranet';
import {BrokerCommissionComponent} from './broker-commission/broker-commission.component';
import {FireInsuranceComponent} from './fire-insurance';
import {ContactQueryService} from '../contact/contact-query.service';
import {FamilyLawActComponent} from './purchaser/family-law-act';
import {PurchaserComponent} from './purchaser';
import {UndertakingsConfigService} from '../admin/shared/undertaking-config.service';
import {MortgageSoAdjService} from '../shared-main/mortgage-so-adj.service';
import {ActingForValues, MortgageDispositionType} from '../shared-main/constants';
import {DpBooleanValueTypes} from './shared/dp-boolean';
import {MortgagePayout} from './mortgages/mortgage/mortgage-payout/mortgage-payout';
import {MortgageUtil} from '../shared-main/mortgage-utility';
import {Mortgage} from './shared/mortgage';
import {MortgageDetailComponent} from './mortgages/mortgage/mortagage-detail/mortgage-detail.component';
import {MortgageTermComponent} from './mortgages/mortgage/term/mortgage-term.component';
import {UnitConnectImportService} from './purchaser/cirf/cirf-import-data/unit-connect-import.service';
import {MatterParticipantWrapper} from './shared/matter-participant-wrapper';
import {VendorsSolicitorComponent} from './vendors-solicitor';
import {MatterTopic} from './shared/matter-topic';
import {MatterCleanUpUtil} from './shared/matter-utils/matter-clean-up-util';
import {MatterTaxRate} from './shared/matter-tax-rate';
import {ContactInfo} from './shared/contact-info';
import {OpportunityCopyParam, OpportunityCopyParamTypeValue} from './shared/opportunity-copy-param';

@Injectable()
export class CopyOpportunityMatterService extends CopyMatterService {

  constructor(public http: HttpClient,
              public matterOpeningComponent: MatterOpeningComponent,
              public existingMortgageComponent: ExistingMortgageComponent,
              public matterParticipantService: MatterParticipantService,
              public mortgageeComponent: MortgageeComponent,
              public attentionInfoComponent: AttentionInfoComponent,
              public condoCorporationComponent: CondoCorporationComponent,
              public propertyTeranetComponent: PropertyTeranetComponent,
              public brokerCommissionComponent: BrokerCommissionComponent,
              public fireInsuranceComponent: FireInsuranceComponent,
              public contactQueryService: ContactQueryService,
              public familyLawActComponent: FamilyLawActComponent,
              public vendorsSolicitorComponent: VendorsSolicitorComponent,
              public purchaserComponent: PurchaserComponent,
              public undertakingsConfigService: UndertakingsConfigService,
              public mortgageDetailComponent: MortgageDetailComponent,
              public mortgageTermComponent: MortgageTermComponent,
              public unitConnectImportService: UnitConnectImportService,
              public mortgageSoAdjService: MortgageSoAdjService) {

    super(http, matterOpeningComponent, existingMortgageComponent, matterParticipantService,
      mortgageeComponent, attentionInfoComponent, condoCorporationComponent,
      propertyTeranetComponent, brokerCommissionComponent, fireInsuranceComponent,
      contactQueryService, familyLawActComponent, purchaserComponent,
      undertakingsConfigService, mortgageSoAdjService);
  }

  async initMortgageDetailComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageDetailComponent) {
        this.mortgageDetailComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.mortgageDetailComponent.initializeState();
      }
    });
  }

  async initMortgageTermComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageTermComponent) {
        console.log('>> populate the matterOpeningComponent with matterInstance:', targetMatter && targetMatter.matterRecordNumber);
        this.mortgageTermComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.mortgageTermComponent.initMortgageTerm();
      }
    });
  }

  async copyMatterFromOpportunity(sourceOpportunityMatter: Matter, targetMatter: Matter, isLinkedMatter?: boolean): Promise<void> {
    targetMatter.sourceOpportunityId = sourceOpportunityMatter.id;
    targetMatter.isFromOpportunity = true;
    targetMatter.createdFromReferral = sourceOpportunityMatter.createdFromReferral;
    await this.copyOpportunityDetails(sourceOpportunityMatter, targetMatter, isLinkedMatter);
    await this.copyMainClientFields(sourceOpportunityMatter, targetMatter, isLinkedMatter);
    this.copyMatterNotes(sourceOpportunityMatter, targetMatter);
    this.copyMatterTopicsNotes(sourceOpportunityMatter, targetMatter, isLinkedMatter);
    await this.copyOtherSideClientFields(sourceOpportunityMatter, targetMatter, isLinkedMatter);
    if (targetMatter.isPurchase || targetMatter.isMortgage) {
      await this.copyNewMortgage(sourceOpportunityMatter, targetMatter);
    }
    if (targetMatter.isSale || targetMatter.isMortgage) {
      await this.copyExistingMortgage(sourceOpportunityMatter, targetMatter);
    }
    await this.copyPropertyDetails(sourceOpportunityMatter, targetMatter);
    await this.copyRealEstateBrokerAndAgent(sourceOpportunityMatter, targetMatter);
    await this.copyOpportunityTaxRate(sourceOpportunityMatter, targetMatter);
    this.copyRequisitionDate(sourceOpportunityMatter, targetMatter);
  }

  async copyOpportunityTaxRate(sourceOpportunityMatter: Matter, targetMatter: Matter): Promise<void> {
    let opportunityMatterSoaTaxRate: MatterTaxRate = sourceOpportunityMatter.matterTaxRates.find(r => r.isSoAccountTaxRate());
    if (opportunityMatterSoaTaxRate) {
      let soaTaxRate: MatterTaxRate;
      let soaAccountTaxRateIndex: number = targetMatter.matterTaxRates.findIndex(r => r.isSoAccountTaxRate());
      if (soaAccountTaxRateIndex > -1) {
        soaTaxRate = targetMatter.matterTaxRates[ soaAccountTaxRateIndex ];
      } else {
        soaTaxRate = new MatterTaxRate();
        targetMatter.matterTaxRates.push(soaTaxRate);
      }
      soaTaxRate.taxRateType = opportunityMatterSoaTaxRate.taxRateType;
      soaTaxRate.progressionStatus = opportunityMatterSoaTaxRate.progressionStatus;
      soaTaxRate.hstRate = opportunityMatterSoaTaxRate.hstRate;
      soaTaxRate.provincialHstRate = opportunityMatterSoaTaxRate.provincialHstRate;
      soaTaxRate.federalHstRate = opportunityMatterSoaTaxRate.federalHstRate;
    }
  }

  async copyOpportunityDetails(sourceMatter: Matter, targetMatter: Matter, isLinkedMatter?: boolean): Promise<void> {
    await this.initMatterOpeningComponent(targetMatter, true);
    targetMatter.matterCloseDate = sourceMatter.matterCloseDate;
    if (isLinkedMatter) {
      this.copySolicitorForLinkedMatter(sourceMatter);
      this.copyLawClerkForLinkedMatter(sourceMatter);
    } else {
      if (sourceMatter.selectedSolicitorId) {
        this.matterOpeningComponent.onSolicitorChange(sourceMatter.selectedSolicitorId);
      }
    }
    this.copyReferredByList(sourceMatter, targetMatter);
    if ((targetMatter.isPurchase || targetMatter.isSale || targetMatter.isMortgage)
      && (sourceMatter.customMatterTypeName == targetMatter.matterType || targetMatter.isMatterTypeDischarge)) {
      targetMatter.actingFor = sourceMatter.actingFor;
      this.matterOpeningComponent.onActingForChange();
    }
    if (isLinkedMatter) {
      targetMatter.actingFor = sourceMatter.actingFor == ActingForValues.PURCHASER_VENDOR ? ActingForValues.VENDOR_PURCHASER : ActingForValues.PURCHASER_VENDOR;
    }
  }

  // If there is an other side solicitor on the opportunity
  // they become the Solicitor assigned to to the matter.
  // Otherwise assigned solicitor is left empty.
  copySolicitorForLinkedMatter(sourceMatter: Matter): void {
    if (this.matterOpeningComponent) {
      let otherPartySolicitor: MatterParticipant = sourceMatter.otherPartySolicitor;
      if (otherPartySolicitor && otherPartySolicitor.contact && otherPartySolicitor.contact.sourceContactId) {
        this.matterOpeningComponent.onSolicitorChange(otherPartySolicitor.contact.sourceContactId);
      }
    }
  }

  // If there is an other side law clerk on the opportunity become the Law Clerk assigned to the matter.
  // Otherwise assigned law clerk is left empty.
  copyLawClerkForLinkedMatter(sourceMatter: Matter): void {
    if (this.matterOpeningComponent) {
      let otherPartyLawClerk: ContactInfo = sourceMatter.otherPartyContactInfo;
      if (otherPartyLawClerk && otherPartyLawClerk.lawClerkId) {
        this.matterOpeningComponent.onLawClerkNameChange(otherPartyLawClerk.lawClerkId);
      }
    }
  }

  copyMatterNotes(sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatter.notesList && sourceMatter.notesList.notes && sourceMatter.notesList.notes.length) {
      targetMatter.notesList = new NotesList();
      targetMatter.notesList.noteType = 'MATTER';
      targetMatter.notesList.notes = [];
      sourceMatter.notesList.notes.forEach((note) => {
        let newNote = new JournalNote(note);
        newNote.id = null;
        newNote.notesListId = null;
        targetMatter.notesList.notes.push(newNote);
      });
    }
  }

  copyReferredByList(sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatter.referredByList) {
      targetMatter.referredByList = sourceMatter.referredByList.map(referredBy => new ReferredByInfo(referredBy));
    }
  }

  copyMatterTopicsNotes(sourceMatter: Matter, targetMatter: Matter, isLinkedMatter?: boolean): void {
    let sourceTopics = sourceMatter.matterTopics;
    let targetTopics = targetMatter.matterTopics;
    if (sourceTopics && sourceTopics.length && targetTopics && targetTopics.length) {
      //Matter Opening Sticky Notes
      let targetMatterOpeningTopic = this.copyTopicNote(sourceTopics, targetTopics, 'MATTER_OPENING', 'MATTER_OPENING');
      if (targetMatter.isPurchase && sourceMatter.opportunity && sourceMatter.opportunity.totalCommission > 0) {
        if (!targetMatterOpeningTopic.topicNote) {
          targetMatterOpeningTopic.topicNote = '';
        }
        targetMatterOpeningTopic.topicNote += `\nTotalCommission = ${ sourceMatter.opportunity.totalCommission }`;
      }
      //Tab B Stick Notes
      let targetTabBTopicKey = isLinkedMatter ? this.getOtherSideTopicKey(targetMatter) : this.getTabBTopicKey(targetMatter);
      this.copyTopicNote(sourceTopics, targetTopics, 'PURCHASER', targetTabBTopicKey);

      //Other Side Sticky Notes
      let targetOtherSideTopicKey = isLinkedMatter ? this.getTabBTopicKey(targetMatter) : this.getOtherSideTopicKey(targetMatter);
      this.copyTopicNote(sourceTopics, targetTopics, 'VENDOR_SOLICITOR', targetOtherSideTopicKey);

      //Property Sticky Notes
      this.copyTopicNote(sourceTopics, targetTopics, 'PROPERTY', 'PROPERTY');

      //Documents Sticky Notes
      this.copyTopicNote(sourceTopics, targetTopics, 'MATTER_DOCUMENTS', 'MATTER_DOCUMENTS');

      //Notes Sticky Notes
      this.copyTopicNote(sourceTopics, targetTopics, 'NOTES', 'NOTES');

      //Mortgage Notes
      if (sourceMatter.opportunity && sourceMatter.opportunity.mortgageNotes && !targetMatter.isSale) {
        let targetNewMortgageTopic = this.getMatterTopicByKey(targetTopics, 'MORTGAGES_MORTGAGEE');
        if (targetNewMortgageTopic) {
          targetNewMortgageTopic.topicNote = sourceMatter.opportunity.mortgageNotes;
        }
      }
    }
  }

  getTabBTopicKey(targetMatter: Matter): string {
    return (targetMatter.isPurchase || targetMatter.isCustomMatter()) ? 'PURCHASER' :
      (targetMatter.isSale ? 'VENDOR' : 'MORTGAGOR');
  }

  getOtherSideTopicKey(targetMatter: Matter): string {
    return (targetMatter.isPurchase || targetMatter.isCustomMatter()) ? 'VENDOR_SOLICITOR' :
      (targetMatter.isSale ? 'PURCHASER_SOLICITOR' : 'OTHER_SOLICITOR');
  }

  copyTopicNote(sourceTopics: MatterTopic[], targetTopics: MatterTopic[], sourceKey: string, targetKey: string): MatterTopic {
    let targetMatterTopic: MatterTopic = null;
    if (sourceTopics && sourceTopics.length && targetTopics && targetTopics.length) {
      let sourceMatterTopic = this.getMatterTopicByKey(sourceTopics, sourceKey);
      targetMatterTopic = this.getMatterTopicByKey(targetTopics, targetKey);
      if (sourceMatterTopic && sourceMatterTopic.topicNote && targetMatterTopic) {
        targetMatterTopic.topicNote = sourceMatterTopic.topicNote;
      }
    }
    return targetMatterTopic;
  }

  getMatterTopicByKey(matterTopics: MatterTopic[], topicKey: string): MatterTopic {
    return matterTopics.find(topic => topic.matterTopicKey == topicKey);
  }

  async initVendorSolicitorComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.vendorsSolicitorComponent) {
        this.vendorsSolicitorComponent.setLocalInstanceMatter(matter);
        this.vendorsSolicitorComponent.ngOnInit();
        this.vendorsSolicitorComponent.initVendorSolicitorStructure();
      }
    });
  }

  /**
   *  solicitor, law firm and law clerk aren't copied when it is mortgage matter with "We are acting for" starts with "Both"
   * @param matter
   */
  isMortgageWithBothActingFor(matter: Matter) {
    return matter && matter.isMortgage && (matter.actingFor === ActingForValues.BOTH_MORTGAGOR_PRIMARY || matter.actingFor === ActingForValues.BOTH_MORTGAGEE_PRIMARY);
  }

  updateOtherPartyParticipant(sourceParticipant: MatterParticipant, targetParticipant: MatterParticipant, sourceMatter: Matter, targetMatter: Matter) {
    if (targetParticipant && targetParticipant.contact && sourceParticipant && sourceParticipant.contact) {
      this.matterParticipantService.updateParticipantFla(sourceParticipant, targetParticipant, this.familyLawActComponent, false, sourceMatter, targetMatter);
      targetParticipant.primary = sourceParticipant.primary;
      //Don't sync idInfoEnteredBy and enteredOn
      targetParticipant.contact.idInfoEnteredBy = null;
      targetParticipant.contact.contactIdInfoEnteredBy = null;
      targetParticipant.contact.enteredOn = null;
      targetParticipant.purchaserCapacity = sourceParticipant.purchaserCapacity;
      targetParticipant.purchaserShare = sourceParticipant.purchaserShare;
    }
  }

  async copyOtherSideClients(sourceMatter: Matter, targetMatter: Matter, otherSideClients: MatterParticipant[], matterParticipantMap: Map<number, number>, offsetIndex: number = 0) {
    return await this.callAsynchronously(async () => {
      for (let i = 0; i < otherSideClients.length; i++) {
        let sourceContactId: number = otherSideClients[ i ].contact && otherSideClients[ i ].contact.sourceContactId;
        let otherSideContact: Contact;
        if (sourceContactId) {
          otherSideContact = await this.getSourceContact(otherSideClients[ i ].contact);
        } else {
          otherSideContact = new Contact();
          otherSideContact.createNewContactClone(otherSideClients[ i ].contact);
        }
        let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
        matterParticipantWrapper.dataModel = {};
        this.vendorsSolicitorComponent.selectedOtherParties.push(matterParticipantWrapper);

        if (sourceContactId) {
          await this.vendorsSolicitorComponent.createOtherPartyParticipant(otherSideContact, matterParticipantWrapper, i + offsetIndex, true, true);
          this.updateOtherPartyParticipant(otherSideClients[ i ], matterParticipantWrapper.matterParticipant, sourceMatter, targetMatter);
          // When Opportunity convert to linked matter, otherSideContact need convertProspectsToClients
          //According conversation with Dov, if there is sourceContact, we also change otherSideContact contactCategory from 'PROSPECT" to "CLIENT"
          if (matterParticipantWrapper && matterParticipantWrapper.matterParticipant
            && matterParticipantWrapper.matterParticipant.contact && matterParticipantWrapper.matterParticipant.contact.contactCategory == 'PROSPECT'
            && otherSideContact && !otherSideContact.locked) {
            matterParticipantWrapper.matterParticipant.sourceContact = otherSideContact;
            matterParticipantWrapper.isClearFlagWithoutUpdatingMatter = false;

            await this.convertProspectsToClients(targetMatter, matterParticipantWrapper);

          }

          await this.convertSignerProspectsToClients(targetMatter, matterParticipantWrapper);
        } else {
          let newMatterParticipant: MatterParticipant = targetMatter.addMatterParticipant(otherSideContact, false, targetMatter.otherSideClientType, null, null, i);
          this.vendorsSolicitorComponent.updateOtherPartyWrapper(matterParticipantWrapper, newMatterParticipant);
          this.vendorsSolicitorComponent.updateOtherPartyFields(matterParticipantWrapper, i + offsetIndex);
          this.vendorsSolicitorComponent.propogateImportChangesAction(true);
          this.matterParticipantService.copyAssociatedContactsForSnapshot(otherSideClients[ i ], newMatterParticipant, sourceMatter, targetMatter);
        }
        matterParticipantMap.set(otherSideClients[ i ].matterParticipantId, matterParticipantWrapper.matterParticipant.matterParticipantId);
      }
    });
  }

  /**
   *  The method includes other parth, solicitor, law firm and law clerk copy
   * @param sourceMatter
   * @param targetMatter
   * @Param isLinkedMatter - optional - in case we copy a linked matter from opportunity
   */
  async copyOtherSideClientFields(sourceMatter: Matter, targetMatter: Matter, isLinkedMatter?: boolean) {
    let otherSideClients: MatterParticipant[] = isLinkedMatter ? sourceMatter.mainClients : sourceMatter.otherSideClients;
    let otherPartyLawFirm: MatterParticipant = sourceMatter.otherPartyLawFirm;
    let otherPartySolicitor: MatterParticipant = sourceMatter.otherPartySolicitor;
    await this.initVendorSolicitorComponent(targetMatter);
    // we copy purchasers (Project Sale Matter) to purchaser (Project Sale Matter)
    if ((otherSideClients && otherSideClients.length > 0)
      || (otherPartyLawFirm) || (otherPartySolicitor)) {
      //Other party aren't copied when it is mortgage matter
      if (!targetMatter.isMortgage) {
        if (otherSideClients && otherSideClients.length > 0) {
          this.vendorsSolicitorComponent.selectedOtherParties = [];
          // maps old matterPArticipantId to new ones to restore the ids for FamilyLawAct
          // we can't use matterParticipantService.updateFlaSpouseId, because otherSideClients can't include sourceContact.
          let matterParticipantMap: Map<number, number> = new Map();
          await this.copyOtherSideClients(sourceMatter, targetMatter, otherSideClients, matterParticipantMap);
          targetMatter.otherSideClients.forEach(mp => {
            if (mp.familyLawActs) {
              mp.familyLawActs.forEach(fla => {
                if (fla.familyLawActStatementType == 'MATTER_PARTICIPANT_SPOUSE') {
                  fla.matterParticipantId = mp.matterParticipantId;
                  fla.spouseMatterParticipantId = matterParticipantMap.get(fla.spouseMatterParticipantId);
                }
              });
            }
          });
          if (this.vendorsSolicitorComponent.selectedOtherParties) {
            let primaryWrapper: MatterParticipantWrapper = this.vendorsSolicitorComponent.selectedOtherParties.find(item => item && item.matterParticipant.primary);
            if (primaryWrapper) {
              this.vendorsSolicitorComponent.setAsPrimaryOtherParty(primaryWrapper);
            }
          }
        }
      }
      if (!this.isMortgageWithBothActingFor(targetMatter)) {
        if (otherPartyLawFirm) {
          await this.copyOtherSideLawFirm(targetMatter, otherPartyLawFirm);
        }
        if (otherPartySolicitor) {
          if (isLinkedMatter) {
            if (sourceMatter.selectedSolicitorId) {
              //If there is a Requested Solicitor on the Opportunity,
              // they become the Other Side Solicitor.
              await this.copyOtherSideSolicitorForLinkedMatter(targetMatter, sourceMatter.selectedSolicitorId);
            }
          } else {
            await this.copyOtherSideSolicitor(targetMatter, otherPartySolicitor);
          }

        }
      }
    }

    if (!this.isMortgageWithBothActingFor(targetMatter)) {
      //Update other side matter fields
      // For lined matter, we don't copy law clerk info
      MatterUtil.updateOtherSideClientMatterFields(sourceMatter, targetMatter, this.vendorsSolicitorComponent, isLinkedMatter);
    }

  }

  async copyOtherSideLawFirm(targetMatter: Matter, sourceLawFirm: MatterParticipant) {
    if (sourceLawFirm && sourceLawFirm.contact) {
      // At this point, law firm may be added from adding solicitor on the opportunity details. so we have to remove it
      const lawFirm = targetMatter.otherPartyLawFirm;
      if (lawFirm) {
        targetMatter.deleteMatterParticipant(lawFirm);
      }
      let sourceContact: Contact = await this.getSourceContact(sourceLawFirm.contact);
      this.vendorsSolicitorComponent.selectedLawFirm = new MatterParticipantWrapper();
      this.vendorsSolicitorComponent.selectedLawFirm.dataModel = {};
      this.vendorsSolicitorComponent.selectedLawFirm.matterParticipant = targetMatter.addMatterParticipant(sourceContact, true, targetMatter.otherPartyMPRoleLawFirm);
      this.vendorsSolicitorComponent.populateLawFirmDataStructure(false,
        this.vendorsSolicitorComponent.selectedLawFirm.matterParticipant,
        this.vendorsSolicitorComponent.selectedLawFirm.matterParticipant.contact, sourceContact);
    }
  }

  async copyOtherSideSolicitor(targetMatter: Matter, sourceSolicitor: MatterParticipant) {
    if (sourceSolicitor && sourceSolicitor.contact) {
      // At this point, a solicitor may be added from adding solicitor on the opportunity details. so we have to remove it
      const solicitor = targetMatter.otherPartySolicitor;
      if (solicitor) {
        targetMatter.deleteMatterParticipant(solicitor);
      }
      let sourceContact: Contact = await this.getSourceContact(sourceSolicitor.contact);
      await this.assignSolicitor(sourceContact, targetMatter);
    }
  }

  async copyOtherSideSolicitorForLinkedMatter(targetMatter: Matter, sourceContactId: number) {
    let sourceContact: Contact = await this.contactQueryService.getContactForMatter(sourceContactId).toPromise();
    await this.assignSolicitor(sourceContact, targetMatter);
  }

  async assignSolicitor(sourceContact: Contact, targetMatter: Matter): Promise<void> {

    this.vendorsSolicitorComponent.selectedSolicitor = new MatterParticipantWrapper();
    this.vendorsSolicitorComponent.selectedSolicitor.dataModel = {};
    this.vendorsSolicitorComponent.selectedSolicitor.matterParticipant = targetMatter.addMatterParticipant(sourceContact, true, targetMatter.otherPartyMPRoleSolicitor);
    this.vendorsSolicitorComponent.populateSolicitorStructure(this.vendorsSolicitorComponent.selectedSolicitor.matterParticipant, false, sourceContact,
      this.vendorsSolicitorComponent.selectedSolicitor.matterParticipant.contact);
  }

  /**
   *  The method includes RealEstate Broker and Agent
   * @param sourceMatter
   * @param targetMatter
   */
  async copyRealEstateBrokerAndAgent(sourceMatter: Matter, targetMatter: Matter, copyRealEstateBroker: boolean = true, copyRealEstateAgent: boolean = true) {
    if (targetMatter.isPurchase || targetMatter.isSale) {
      if (sourceMatter.realEstateAgent && copyRealEstateAgent) {
        let sourceContactId: number = sourceMatter.realEstateAgent.contact && sourceMatter.realEstateAgent.contact.sourceContactId;
        let agentContact: Contact;
        if (sourceContactId) {
          agentContact = await this.getSourceContact(sourceMatter.realEstateAgent.contact);
        } else {
          agentContact = sourceMatter.realEstateAgent.contact;
        }
        targetMatter.addMatterParticipant(agentContact, true, 'REALESTATEAGENT');
      }
      // For opportunity, we only have realEstateBroker contact id, wwe doesn't have matter participant
      if (sourceMatter.selectedBrokerId && copyRealEstateBroker) {
        if (targetMatter.isPurchase) {
          targetMatter.realEstateBrokerName = sourceMatter.realEstateBrokerName;
          targetMatter.selectedBrokerId = sourceMatter.selectedBrokerId;
        }
        if (targetMatter.isSale) {
          let brokerCompanyForMatter: Contact = await this.contactQueryService.getContactForMatter(sourceMatter.selectedBrokerId).toPromise();
          targetMatter.addMatterParticipant(brokerCompanyForMatter, true, 'REALESTATEBROKER');
          targetMatter.reCalculateTrustLedgerPaidToRealEstateBroker();
        }
      }
    }
  }

  async copyNewMortgage(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    if (sourceMatter.newMortgages && sourceMatter.newMortgages.length) {
      let sourceMatterNewMortgage = sourceMatter.newMortgages[ 0 ];
      if (sourceMatterNewMortgage && sourceMatterNewMortgage.mortgageTerm && sourceMatterNewMortgage.mortgageTerm.principal > 0) {
        let mortgage = targetMatter.createMortgage('NEW', 'UNITY', 1);
        targetMatter.mortgages.push(mortgage);
        this.initMortgageDetailComponent(targetMatter, 0);
        mortgage.loanType = 'ARRANGED';
        this.mortgageDetailComponent.onMortgageLoanChange(mortgage);
        mortgage.mortgageeType = sourceMatterNewMortgage.mortgageeType;
        if (mortgage.mortgageTerm) {
          this.initMortgageTermComponent(targetMatter, 0);
          mortgage.mortgageTerm.principal = sourceMatterNewMortgage.mortgageTerm.principal;
          this.mortgageTermComponent.mortgagePrincipalOnBlur();
        }
        await this.initMortgageeComponent(targetMatter, mortgage);
        await this.copyMortgagee(sourceMatter, targetMatter, sourceMatterNewMortgage, mortgage);
        await this.copyPrivateLender(sourceMatter, targetMatter, sourceMatterNewMortgage, mortgage);
      }
    }
  }

  async copyExistingMortgage(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    if (sourceMatter.existingMortgages && sourceMatter.existingMortgages.length) {
      let sourceMatterExistingMortgage = sourceMatter.existingMortgages[ 0 ];
      if (sourceMatterExistingMortgage && sourceMatterExistingMortgage.mortgageTerm && sourceMatterExistingMortgage.mortgageTerm.principal > 0) {
        let mortgage = targetMatter.createMortgage('EXISTING', 'UNITY', null);
        mortgage.financingType = 'Mortgage';
        mortgage.statementForInformation = DpBooleanValueTypes.Y_n;
        targetMatter.existingMortgages.push(mortgage);
        await this.initExistingMortgageComponent(targetMatter, 0);
        this.copyExistingMortgageDetails(mortgage, sourceMatterExistingMortgage);
        await this.initMortgageeComponent(targetMatter, mortgage);
        await this.copyMortgagee(sourceMatter, targetMatter, sourceMatterExistingMortgage, mortgage);
        await this.copyPrivateLender(sourceMatter, targetMatter, sourceMatterExistingMortgage, mortgage);
      }
    }
  }

  copyExistingMortgageDetails(targetMortgage: Mortgage, sourceMortgage: Mortgage): void {
    targetMortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
    this.existingMortgageComponent.onMortgageDispositionTypeChange(targetMortgage);
    targetMortgage.mortgageeType = sourceMortgage.mortgageeType;
    this.existingMortgageComponent.onMortgageeTypeChange(targetMortgage);
    targetMortgage.mortgagePayout = new MortgagePayout();
    MortgageUtil.initializeMortgagePayout(targetMortgage.mortgagePayout);
    targetMortgage.mortgagePayout.amountPayableToDischarge = sourceMortgage.mortgageTerm.principal;
    targetMortgage.amountPayableToDischarge = sourceMortgage.mortgageTerm.principal;
    this.existingMortgageComponent.updateTrustLedgerAndMortgageDataOnPayoutChange(targetMortgage.mortgagePayout);
  }

  async copyPropertyDetails(sourceMatter: Matter, targetMatter: Matter): Promise<void> {
    await this.initBrokerCommissionComponent(targetMatter);
    await this.initSubjectPropertyComponent(targetMatter);
    this.propertyTeranetComponent.brokerCommissionComponent = this.brokerCommissionComponent;
    let sourceMatterProperty: MatterProperty = sourceMatter.matterPropertyWithCondo;
    let targetMatterProperty: MatterProperty = targetMatter.matterPropertyWithCondo;
    if (sourceMatterProperty && targetMatterProperty) {
      if (targetMatter.isPurchase || targetMatter.isSale || targetMatter.isMortgage) {
        this.updatePropertyAddress(sourceMatterProperty, targetMatterProperty);
      }
      if (targetMatter.isPurchase || targetMatter.isSale) {
        targetMatterProperty.purchaseIsOfCode = sourceMatterProperty.purchaseIsOfCode;
        if (sourceMatterProperty.purchasePrice > 0 && targetMatter.considerationLtt && targetMatter.considerationLtt.salePriceAdjustment) {
          targetMatter.considerationLtt.salePriceAdjustment.agreementSalePrice = sourceMatterProperty.purchasePrice;
          targetMatterProperty.purchasePrice = sourceMatterProperty.purchasePrice;
          this.unitConnectImportService.applySalePriceChanges(targetMatter, this.propertyTeranetComponent);
        }
        if (sourceMatterProperty.depositAmount > 0) {
          targetMatterProperty.depositAmount = sourceMatterProperty.depositAmount;
          this.unitConnectImportService.applyDepositChanges(targetMatter, this.propertyTeranetComponent);
        }
      }
      if (targetMatter.isSale && targetMatter.brokerCommission && sourceMatter.opportunity.totalCommission > 0) {
        MatterCleanUpUtil.cleanUpBrokerCommission(targetMatter);
        targetMatter.depositHeldBy = constValues.depositHeldBy.VENDOR_BROKER;
        targetMatter.commissionBasedOnFixedPercentageOfSalePrice = DpBooleanValueTypes.NO;
        await this.brokerCommissionComponent.onCommissionBasedOnFixedPercentageOfSalePriceChange();
        targetMatter.brokerCommission.commissionBeforeHst = sourceMatter.opportunity.totalCommission;
        this.brokerCommissionComponent.calculateVendorOnlyFieldsForMassUpdate();
      }
    }
  }

  async initMortgageeComponent(targetMatter: Matter, targetMortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      console.log('start initMortgageeComponent');
      if (this.mortgageeComponent) {
        this.mortgageeComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgage);
        this.mortgageeComponent.ngOnInit();
      }
      console.log('end initMortgageeComponent');
    });
  }

  /**
   *  The method includes Mortgagee
   * @param sourceMatter
   * @param targetMatter
   */
  async copyMortgageeParticipants(mortgagees: MatterParticipant[]) {
    return await this.callAsynchronously(async () => {
      for (let i = 0; i < mortgagees.length; i++) {
        let sourceContact: Contact = await this.getSourceContact(mortgagees[ i ].contact);
        // the client's ID verification fields 'ID verified for this transaction by' and 'On' should not be copied.
        // These fields should default to empty.
        sourceContact.idInfoEnteredBy = null;
        sourceContact.contactIdInfoEnteredBy = null;
        sourceContact.enteredOn = null;
        let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
        matterParticipantWrapper.dataModel = {};
        this.mortgageeComponent.silentAddMortgagee(sourceContact, matterParticipantWrapper);
        this.mortgageeComponent.selectedMortgagees.push(matterParticipantWrapper);
      }
    });

  }

  /**
   *  The method includes Mortgagee
   * @param sourceMatter
   * @param targetMatter
   */
  async copyMortgagee(sourceMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage) {
    if (targetMortgage.isMortgageeAnInstitution()) {
      let mortgagees = sourceMatter.getMortgagees(sourceMortgage);
      //Add New Mortgagees
      this.mortgageeComponent.selectedMortgagees = [];
      await this.copyMortgageeParticipants(mortgagees);
    }

  }

  /**
   *  The method includes PrivateLenders
   * @param sourceMatter
   * @param targetMatter
   */
  async copyPrivateLenderParticipants(otherLenders: MatterParticipant[]) {
    return await this.callAsynchronously(async () => {
      for (let i = 0; i < otherLenders.length; i++) {
        let sourceContact: Contact = await this.getSourceContact(otherLenders[ i ].contact);
        // the client's ID verification fields 'ID verified for this transaction by' and 'On' should not be copied.
        // These fields should default to empty.
        sourceContact.idInfoEnteredBy = null;
        sourceContact.contactIdInfoEnteredBy = null;
        sourceContact.enteredOn = null;
        let matterParticipantWrapper: MatterParticipantWrapper = new MatterParticipantWrapper();
        matterParticipantWrapper.dataModel = {};
        await this.mortgageeComponent.silentAddPrivateLender(sourceContact, matterParticipantWrapper);
        matterParticipantWrapper.primary = otherLenders[ i ].primary;
        matterParticipantWrapper.matterParticipant.includeAuthorizeSignOfficer = otherLenders[ i ].includeAuthorizeSignOfficer;
        this.mortgageeComponent.matterPrivateLenders.push(matterParticipantWrapper);
      }
    });
  }

  /**
   *  The method includes PrivateLenders
   * @param sourceMatter
   * @param targetMatter
   */
  async copyPrivateLender(sourceMatter: Matter, targetMatter: Matter, sourceMortgage: Mortgage, targetMortgage: Mortgage) {
    if (targetMortgage.isMortgageePrivateLender) {
      let otherLenders = sourceMatter.getPrivateLenders(sourceMortgage);
      if (otherLenders && otherLenders.length > 0) {
        targetMortgage.undertakingDirty = true;
        this.mortgageeComponent.matterPrivateLenders = [];
        await this.copyPrivateLenderParticipants(otherLenders);
        if (this.mortgageeComponent.matterPrivateLenders) {
          let primaryWrapper: MatterParticipantWrapper = this.mortgageeComponent.matterPrivateLenders.find(item => item && item.matterParticipant.primary);
          if (primaryWrapper) {
            this.mortgageeComponent.setAsPrimaryPrivateLender(primaryWrapper);
          }
        }
      }
    }
  }

  async updateMatterFromOpportunity(opportunityMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): Promise<void> {
    targetMatter.sourceOpportunityId = opportunityMatter.id;
    targetMatter.isFromOpportunity = true;

    if (!targetMatter.createdFromReferral) {
      targetMatter.createdFromReferral = true;
      targetMatter.referralId = opportunityMatter.opportunity.sourceReferralId;
      targetMatter.opportunity.sourceReferralId = opportunityMatter.opportunity.sourceReferralId;
    }

    await this.updateMatterFromOpportunityDetails(opportunityMatter, targetMatter, copyParams);
    await this.updateMainClientsFromOpportunity(opportunityMatter, targetMatter, copyParams);
    await this.updateOtherSideClient(opportunityMatter, targetMatter, copyParams);
    await this.updateMatterOtherSideSolicitorAndFirmFromOpportunity(opportunityMatter, targetMatter, copyParams);
    await this.updateMatterPropertyFromOpportunity(opportunityMatter, targetMatter, copyParams);
    await this.copyRealEstateBrokerAndAgent(opportunityMatter, targetMatter, copyParams.getCopyActionByType('REAL_ESTATE_AGENT') === OpportunityCopyParamTypeValue.Yes, copyParams.getCopyActionByType('REAL_ESTATE_BROKER') === OpportunityCopyParamTypeValue.Yes);
    this.updateMatterStickyNotesFromOpportunity(opportunityMatter, targetMatter, copyParams);
    this.updateMatterNotesFromOpportunity(opportunityMatter, targetMatter, copyParams);
    await this.updateMatterMortgageFromOpportunity(opportunityMatter, targetMatter, copyParams);
  }

  private copyRequisitionDate(sourceOpportunityMatter: Matter, targetMatter: Matter) {
    if (sourceOpportunityMatter.requisitionDateCalculationConfig
      && sourceOpportunityMatter.requisitionDateCalculationConfig.isAutoPopulateEnabled
      && sourceOpportunityMatter.requisitionDateCalculationConfig.isSourceSelectedAsClosingDate
      && sourceOpportunityMatter.isMatterProvinceONOrNB
      && !sourceOpportunityMatter.isConfigurationSourceDateEmptyOnMatter(sourceOpportunityMatter.matterCloseDate)
    ) {
      targetMatter.requisitionDate = sourceOpportunityMatter.calculateConfigDate(sourceOpportunityMatter.matterCloseDate);
    }
  }

  async updateMatterFromOpportunityDetails(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): Promise<void> {
    await this.initMatterOpeningComponent(targetMatter, true);
    if (copyParams.getCopyActionByType('MATTER_CLOSE_DATE') == OpportunityCopyParamTypeValue.Yes) {
      targetMatter.matterCloseDate = sourceMatter.matterCloseDate;
    }
    if (copyParams.getCopyActionByType('SOLICITOR') == OpportunityCopyParamTypeValue.Yes && sourceMatter.selectedSolicitorId) {
      this.matterOpeningComponent.onSolicitorChange(sourceMatter.selectedSolicitorId);
    }
    if (copyParams.getCopyActionByType('REFERRAL') == OpportunityCopyParamTypeValue.Yes || copyParams.getCopyActionByType('REFERRAL') == OpportunityCopyParamTypeValue.Replace) {
      this.replaceReferredByList(sourceMatter, targetMatter);
    } else if (copyParams.getCopyActionByType('REFERRAL') == OpportunityCopyParamTypeValue.Add) {
      this.appendReferredByList(sourceMatter, targetMatter);
    }
  }

  async updateMatterOtherSideSolicitorAndFirmFromOpportunity(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): Promise<void> {
    if (copyParams && copyParams.getCopyActionByType('OTHER_SIDE_LAW_FIRM_AND_SOLICITOR') === OpportunityCopyParamTypeValue.Yes) {
      await this.initVendorSolicitorComponent(targetMatter);
      await this.copyOtherSideLawFirm(targetMatter, sourceMatter.otherPartyLawFirm);
      await this.copyOtherSideSolicitor(targetMatter, sourceMatter.otherPartySolicitor);
    }
  }

  updateMatterNotesFromOpportunity(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): void {
    if (sourceMatter.notesList && sourceMatter.notesList.notes && sourceMatter.notesList.notes.length) {
      if (!targetMatter.notesList) {
        targetMatter.notesList = new NotesList();
        targetMatter.notesList.noteType = 'MATTER';
        targetMatter.notesList.notes = [];
      }
      sourceMatter.notesList.notes.forEach((note) => {
        const param = copyParams.getRecordByTypeAndId('NOTE', note.id);
        if (param && param.copyAction === OpportunityCopyParamTypeValue.Yes) {
          let newNote = new JournalNote(note);
          newNote.id = null;
          newNote.notesListId = null;
          targetMatter.notesList.notes.push(newNote);
        }
      });
    }
  }

  updateMatterStickyNotesFromOpportunity(opportunityMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam) {
    if (copyParams) {

      if (copyParams.getCopyActionByType('MATTER_OPENING_STICKY_NOTE') == OpportunityCopyParamTypeValue.Yes) {
        this.appendTopicNote(opportunityMatter, targetMatter, 'MATTER_OPENING', 'MATTER_OPENING');
      }

      if (copyParams.getCopyActionByType('PROPERTY_STICKY_NOTE') == OpportunityCopyParamTypeValue.Yes) {
        this.appendTopicNote(opportunityMatter, targetMatter, 'PROPERTY', 'PROPERTY');
      }

      if (copyParams.getCopyActionByType('PURCHASER_STICKY_NOTE') == OpportunityCopyParamTypeValue.Yes) {
        this.appendTopicNote(opportunityMatter, targetMatter, 'PURCHASER', this.getTabBTopicKey(targetMatter));
      }

      if (copyParams.getCopyActionByType('OTHER_SIDE_STICKY_NOTES') == OpportunityCopyParamTypeValue.Yes) {
        this.appendTopicNote(opportunityMatter, targetMatter, 'VENDOR_SOLICITOR', this.getOtherSideTopicKey(targetMatter));
      }

      if (copyParams.getCopyActionByType('NOTE_STICKY_NOTE') == OpportunityCopyParamTypeValue.Yes) {
        this.appendTopicNote(opportunityMatter, targetMatter, 'NOTES', 'NOTES');
      }

      if (copyParams.getCopyActionByType('MORTGAGE_NOTES') === 'YES' && opportunityMatter.opportunity && opportunityMatter.opportunity.mortgageNotes) {
        let targetNewMortgageTopic = this.getMatterTopicByKey(targetMatter.matterTopics, 'MORTGAGES_MORTGAGEE');
        if (targetNewMortgageTopic) {
          if (targetNewMortgageTopic.topicNote) {
            targetNewMortgageTopic.topicNote += '\n' + opportunityMatter.opportunity.mortgageNotes;
          } else {
            targetNewMortgageTopic.topicNote = opportunityMatter.opportunity.mortgageNotes;
          }
        }
      }
    }
  }

  appendTopicNote(sourceMatter: Matter, targetMatter: Matter, sourceKey: string, targetKey: string) {
    let targetMatterTopic: MatterTopic = null;
    if (sourceMatter.matterTopics && sourceMatter.matterTopics.length && targetMatter.matterTopics && targetMatter.matterTopics.length) {
      let sourceMatterTopic = this.getMatterTopicByKey(sourceMatter.matterTopics, sourceKey);
      targetMatterTopic = this.getMatterTopicByKey(targetMatter.matterTopics, targetKey);
      if (sourceMatterTopic && sourceMatterTopic.topicNote && targetMatterTopic) {
        if (targetMatterTopic.topicNote) {
          targetMatterTopic.topicNote += '\n' + sourceMatterTopic.topicNote;
        } else {
          targetMatterTopic.topicNote = sourceMatterTopic.topicNote;
        }
      }
    }
  }

  async updateMatterPropertyFromOpportunity(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): Promise<void> {
    await this.initBrokerCommissionComponent(targetMatter);
    await this.initSubjectPropertyComponent(targetMatter);
    this.propertyTeranetComponent.brokerCommissionComponent = this.brokerCommissionComponent;
    let sourceMatterProperty: MatterProperty = sourceMatter.matterPropertyWithCondo;
    let targetMatterProperty: MatterProperty = targetMatter.matterPropertyWithCondo;
    if (sourceMatterProperty && targetMatterProperty) {
      if (copyParams.getCopyActionByType('PROPERTY_ADDRESS') === OpportunityCopyParamTypeValue.Yes && (targetMatter.isPurchase || targetMatter.isSale || targetMatter.isMortgage)) {
        this.updatePropertyAddress(sourceMatterProperty, targetMatterProperty);
      }
      if (targetMatter.isPurchase || targetMatter.isSale) {
        if (copyParams.getCopyActionByType('PURCHASE_IS_OF_CODE') === OpportunityCopyParamTypeValue.Yes) {
          targetMatterProperty.purchaseIsOfCode = sourceMatterProperty.purchaseIsOfCode;
        }
        if (copyParams.getCopyActionByType('PURCHASE_PRICE') === OpportunityCopyParamTypeValue.Yes) {
          if (sourceMatterProperty.purchasePrice > 0 && targetMatter.considerationLtt && targetMatter.considerationLtt.salePriceAdjustment) {
            targetMatter.considerationLtt.salePriceAdjustment.agreementSalePrice = sourceMatterProperty.purchasePrice;
            targetMatterProperty.purchasePrice = sourceMatterProperty.purchasePrice;
            this.unitConnectImportService.applySalePriceChanges(targetMatter, this.propertyTeranetComponent);
          }
        }
        if (copyParams.getCopyActionByType('DEPOSIT_AMOUNT') === OpportunityCopyParamTypeValue.Yes) {
          if (sourceMatterProperty.depositAmount > 0) {
            targetMatterProperty.depositAmount = sourceMatterProperty.depositAmount;
            this.unitConnectImportService.applyDepositChanges(targetMatter, this.propertyTeranetComponent);
          }
        }
      }
      if (copyParams.getCopyActionByType('TOTAL_COMMISSION') === OpportunityCopyParamTypeValue.Yes) {
        if (targetMatter.isSale && targetMatter.brokerCommission && sourceMatter.opportunity.totalCommission > 0) {
          MatterCleanUpUtil.cleanUpBrokerCommission(targetMatter);
          targetMatter.depositHeldBy = constValues.depositHeldBy.VENDOR_BROKER;
          targetMatter.commissionBasedOnFixedPercentageOfSalePrice = DpBooleanValueTypes.NO;
          await this.brokerCommissionComponent.onCommissionBasedOnFixedPercentageOfSalePriceChange();
          targetMatter.brokerCommission.commissionBeforeHst = sourceMatter.opportunity.totalCommission;
          this.brokerCommissionComponent.calculateVendorOnlyFieldsForMassUpdate();
        }
      }
    }
  }

  async updateMatterMortgageFromOpportunity(opportunity: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam) {
    if ((targetMatter.isPurchase || targetMatter.isMortgage) && !targetMatter.isMatterTypeDischarge) {
      if (copyParams.getCopyActionByType('NEW_MORTGAGE_AMOUNT') === 'YES' || copyParams.getCopyActionByType('NEW_MORTGAGE_MORTGAGEE') === 'YES' || copyParams.getCopyActionByType('NEW_MORTGAGE_TYPE_OF_MORTGAGEE') === 'YES') {
        await this.updateNewMortgage(opportunity, targetMatter, copyParams);
      }
    }
    if (targetMatter.isSale || targetMatter.isMortgage || targetMatter.isMatterTypeDischarge) {
      if (copyParams.getCopyActionByType('EXISTING_MORTGAGE_AMOUNT') === 'YES' || copyParams.getCopyActionByType('EXISTING_MORTGAGE_TYPE_OF_MORTGAGEE') === 'YES' || copyParams.getCopyActionByType('EXISTING_MORTGAGE_MORTGAGEE') === 'YES') {
        await this.updateExistingMortgage(opportunity, targetMatter, copyParams);
      }
    }
  }

  async updateNewMortgage(opportunity: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): Promise<void> {
    if (opportunity.newMortgages && opportunity.newMortgages.length) {
      let opportunityNewMortgage = opportunity.newMortgages[ 0 ];
      if (opportunityNewMortgage && opportunityNewMortgage.mortgageTerm) {
        let mortgage: Mortgage;
        let newMortgageAdded: boolean = false;
        if (targetMatter.newMortgages && targetMatter.newMortgages.length == 0) {
          mortgage = targetMatter.createMortgage('NEW', 'UNITY', 1);
          mortgage.loanType = 'ARRANGED';
          mortgage.mortgageeType = opportunityNewMortgage.mortgageeType;
          targetMatter.mortgages.push(mortgage);
          newMortgageAdded = true;
        } else {
          mortgage = targetMatter.newMortgages[ 0 ];
        }
        await this.initMortgageDetailComponent(targetMatter, 0);
        if (newMortgageAdded) {
          this.mortgageDetailComponent.onMortgageLoanChange(mortgage);
        }
        //update mortgaees has to be called before changing mortgageeType on mortgage.
        await this.updateNewMortgageMortgagee(opportunity, targetMatter, opportunityNewMortgage, mortgage, copyParams);
        if (copyParams.getCopyActionByType('NEW_MORTGAGE_TYPE_OF_MORTGAGEE') === 'YES' && mortgage.mortgageeType != opportunityNewMortgage.mortgageeType) {
          mortgage.mortgageeType = opportunityNewMortgage.mortgageeType;
          this.mortgageDetailComponent.checkMortgageContactInfoType();
        }
        if (mortgage.mortgageTerm) {
          await this.initMortgageTermComponent(targetMatter, 0);
          if (copyParams.getCopyActionByType('NEW_MORTGAGE_AMOUNT') === 'YES') {
            mortgage.mortgageTerm.principal = opportunityNewMortgage.mortgageTerm.principal;
            this.mortgageTermComponent.mortgagePrincipalOnBlur();
          }
        }
      }
    }
  }

  async updateNewMortgageMortgagee(opportunity: Matter, targetMatter: Matter, opportunityNewMortgage: Mortgage, existingMortgage: Mortgage, copyParams: OpportunityCopyParam): Promise<void> {
    if (copyParams.getCopyActionByType('NEW_MORTGAGE_MORTGAGEE') === 'YES') {
      await this.initMortgageeComponent(targetMatter, existingMortgage);
      const existingLender = existingMortgage.isMortgageePrivateLender() ? targetMatter.getPrivateLenders(existingMortgage) : targetMatter.getMortgagees(existingMortgage);
      if (existingLender.length) {
        let existingLenderWrapper = this.mortgageeComponent.selectedMortgagees.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == existingLender[ 0 ].matterParticipantId);
        if (existingLenderWrapper) {
          this.mortgageeComponent.silentDeleteMortgagee(existingLenderWrapper);
        }
      }
      await this.copyMortgagee(opportunity, targetMatter, opportunityNewMortgage, existingMortgage);
      await this.copyPrivateLender(opportunity, targetMatter, opportunityNewMortgage, existingMortgage);
    }
  }

  async updateExistingMortgage(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam): Promise<void> {
    if (sourceMatter.existingMortgages && sourceMatter.existingMortgages.length) {
      let sourceMatterExistingMortgage = sourceMatter.existingMortgages[ 0 ];
      if (sourceMatterExistingMortgage && sourceMatterExistingMortgage.mortgageTerm && sourceMatterExistingMortgage.mortgageTerm.principal > 0) {
        let mortgage: Mortgage;
        let existingMortgageAdded: boolean = false;
        if (targetMatter.existingMortgages && targetMatter.existingMortgages.length == 0) {
          mortgage = targetMatter.createMortgage('EXISTING', 'UNITY', null);
          mortgage.financingType = 'Mortgage';
          mortgage.statementForInformation = DpBooleanValueTypes.Y_n;
          mortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
          targetMatter.existingMortgages.push(mortgage);
          existingMortgageAdded = true;
        } else {
          mortgage = targetMatter.existingMortgages[ 0 ];
          ;
        }
        await this.initExistingMortgageComponent(targetMatter, 0);
        if (existingMortgageAdded) {
          this.existingMortgageComponent.onMortgageDispositionTypeChange(mortgage);
        }
        this.updateExistingMortgageDetails(mortgage, sourceMatterExistingMortgage, copyParams);
        await this.updateExistingMortgageMortgagee(sourceMatter, targetMatter, sourceMatterExistingMortgage, mortgage, copyParams);
      }
    }
  }

  async updateExistingMortgageMortgagee(opportunity: Matter, targetMatter: Matter, opportunityNewMortgage: Mortgage, targetMortgage: Mortgage, copyParams: OpportunityCopyParam) {
    if (copyParams.getCopyActionByType('EXISTING_MORTGAGE_MORTGAGEE') === 'YES') {
      await this.initMortgageeComponent(targetMatter, targetMortgage);
      const existingLender = targetMortgage.isMortgageePrivateLender() ? targetMatter.getPrivateLenders(targetMortgage) : targetMatter.getMortgagees(targetMortgage);
      if (existingLender.length) {
        let existingLenderWrapper = this.mortgageeComponent.selectedMortgagees.find(item => item.matterParticipant && item.matterParticipant.matterParticipantId == existingLender[ 0 ].matterParticipantId);
        if (existingLenderWrapper) {
          this.mortgageeComponent.silentDeleteMortgagee(existingLenderWrapper);
        }
      }
      await this.copyMortgagee(opportunity, targetMatter, opportunityNewMortgage, targetMortgage);
      await this.copyPrivateLender(opportunity, targetMatter, opportunityNewMortgage, targetMortgage);
    }
  }

  updateExistingMortgageDetails(targetMortgage: Mortgage, opportunityMortgage: Mortgage, copyParams: OpportunityCopyParam): void {
    if (copyParams.getCopyActionByType('EXISTING_MORTGAGE_TYPE_OF_MORTGAGEE') === 'YES') {
      targetMortgage.mortgageeType = opportunityMortgage.mortgageeType;
      this.existingMortgageComponent.onMortgageeTypeChange(targetMortgage);
    }
    if (copyParams.getCopyActionByType('EXISTING_MORTGAGE_AMOUNT') === 'YES') {
      //targetMortgage.mortgagePayout.cleanUpMortgagePayout();
      targetMortgage.mortgagePayout = new MortgagePayout();
      MortgageUtil.initializeMortgagePayout(targetMortgage.mortgagePayout);
      targetMortgage.mortgagePayout.amountPayableToDischarge = opportunityMortgage.mortgageTerm.principal;
      targetMortgage.mortgagePayout.principalAmount = opportunityMortgage.mortgageTerm.principal;
      targetMortgage.amountPayableToDischarge = opportunityMortgage.mortgageTerm.principal;
      this.existingMortgageComponent.updateTrustLedgerAndMortgageDataOnPayoutChange(targetMortgage.mortgagePayout);
    }
  }

  appendReferredByList(sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatter.referredByList) {
      targetMatter.referredByList.push(...sourceMatter.referredByList.map(referredBy => new ReferredByInfo(referredBy)));
    }
  }

  replaceReferredByList(sourceMatter: Matter, targetMatter: Matter): void {
    if (sourceMatter.referredByList) {
      targetMatter.referredByList = sourceMatter.referredByList.map(referredBy => new ReferredByInfo(referredBy));
    }
  }

  async updateMainClientsFromOpportunity(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam) {
    let matterParticipants = sourceMatter.mainClients.filter(value => {
      return copyParams.getCopyActionByTypeAndId('PROSPECT', value.matterParticipantId) == 'ADD';
    });
    if (matterParticipants.length) {
      const alreadyAPrimary = targetMatter.mainClients.some(value => !!value.primary);
      if (alreadyAPrimary) {
        matterParticipants.forEach(value => value.primary = false);
      }
      const noOfExistingClients = targetMatter.mainClients.length;
      await this.initPurchaserComponent(targetMatter);
      return await this.callAsynchronously(() => {
        this.purchaserComponent.selectedClientPurchasers = [];
        matterParticipants.forEach(async (item, index) => {
          await this.copyMainClientMatterParticipant(item, noOfExistingClients + index, sourceMatter, targetMatter,
            MatterUtil.getMaxMatterParticipantPriority(targetMatter) + index + 1, noOfExistingClients + index);
        });
      });
    }
  }

  async updateOtherSideClient(sourceMatter: Matter, targetMatter: Matter, copyParams: OpportunityCopyParam) {
    let otherSideClientsToBeCopied: MatterParticipant[] = sourceMatter.otherSideClients.filter(value => {
      return copyParams.getCopyActionByTypeAndId('OTHER_SIDE_CLIENT', value.matterParticipantId) == 'ADD';
    });
    if ((otherSideClientsToBeCopied && otherSideClientsToBeCopied.length > 0)) {
      const alreadyAPrimary = targetMatter.otherSideClients.some(value => !!value.primary);
      if (alreadyAPrimary) {
        otherSideClientsToBeCopied.forEach(value => value.primary = false);
      }
      const noOfExistingClients = targetMatter.mainClients.length;
      await this.initVendorSolicitorComponent(targetMatter);
      if (!targetMatter.isMortgage) {
        let matterParticipantMap: Map<number, number> = new Map();
        await this.copyOtherSideClients(sourceMatter, targetMatter, otherSideClientsToBeCopied, matterParticipantMap, noOfExistingClients);
        targetMatter.otherSideClients.forEach(mp => {
          if (mp.familyLawActs) {
            mp.familyLawActs.forEach(fla => {
              if (fla.familyLawActStatementType == 'MATTER_PARTICIPANT_SPOUSE') {
                fla.matterParticipantId = mp.matterParticipantId;
                fla.spouseMatterParticipantId = matterParticipantMap.get(fla.spouseMatterParticipantId);
              }
            });
          }
        });
        if (this.vendorsSolicitorComponent.selectedOtherParties) {
          let primaryWrapper: MatterParticipantWrapper = this.vendorsSolicitorComponent.selectedOtherParties.find(item => item && item.matterParticipant.primary);
          if (primaryWrapper) {
            this.vendorsSolicitorComponent.setAsPrimaryOtherParty(primaryWrapper);
          }
        }
      }
    }
  }
}
