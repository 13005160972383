import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {currentMatter, Matter} from '../../../shared/index';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {TeranetDocket} from '../../../../shared-main/teranet/teranet-docket';
import {ApplicationError} from '../../../../core/application-error';
import {FieldError} from '../../../../core/index';
import {DuplicateDocketModal} from './duplicate-docket.modal.component';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {MatterProperty} from '../../../shared/matter-property';
import * as _ from 'lodash';
import {SearchRequestType, TeranetDocketRequest} from '../../../../shared-main/teranet/teranet-docket-request';
import {Observable} from 'rxjs/Rx';
import {LockScreenService} from '../../../../core/lock-screen.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class CreateDocketContext {
  matter: Matter;
  teranetUser: TeranetUser;
}

@Component({
  selector: 'dp-create-docket-modal',
  templateUrl: 'create-docket.modal.component.html',
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class CreateDocketModal extends ModalComponent<CreateDocketContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  searchDocket: boolean = false;
  actionButtonFlag: boolean = false;
  f9docketName: string = '';
  f9docketId: string = '';
  teranetDocketRequest: TeranetDocketRequest;
  searchDocketResult: TeranetDocket[] = [];
  selectedOwnerIndex: number = -1;
  submitted: boolean = false;
  dropDownIndex: number = 0;

  get teranetDocket(): TeranetDocket {
    return this.matter.teranetDocket;
  }

  constructor(
    public dialog: MatDialogRef<CreateDocketModal>,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    public lockScreenService: LockScreenService,
    @Inject(MAT_DIALOG_DATA) context?: CreateDocketContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.teranetDocket.teranetUser = this.teranetUser;
    this.teranetDocket.matterId = this.matter.id;
    this.teranetDocket.teraviewDocketName = this.defaultTeraviewDocketName;
    this.teranetDocketRequest = new TeranetDocketRequest();
  }

  setF9ValueForDocketId(): void {
    if (
      this.matter.teranetDocket.teraviewDocketIdentifier &&
      this.matter.teranetDocket.teraviewDocketIdentifier.length > 0
    ) {
      this.f9docketId = this.matter.teranetDocket.teraviewDocketIdentifier;
    } else {
      this.f9docketId = this.matter.matterRecordNumber;
    }
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get teranetUser(): TeranetUser {
    return this.context.teranetUser;
  }

  get defaultTeraviewDocketName(): string {
    let teraviewDocketName: string =
      this.getClientReLine() +
      ` (${this.matter.matterRecordNumber})` +
      (this.appendStreetNumberAndName.length ? `; ${this.appendStreetNumberAndName}` : '');
    return teraviewDocketName.length > 50 ? teraviewDocketName.substr(0, 50) : teraviewDocketName;
  }

  private getClientReLine() {
    if (this.matter.clientReLine || (this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline)) {
      let reLine: string = '';
      if (this.matter.clientReLine) {
        reLine = this.matter.clientReLine + (this.matter.isPurchase ? ' P/F' : this.matter.isSale ? ' S/T' : ' M/F');
      }
      if (this.matter.otherPartyContactInfo && this.matter.otherPartyContactInfo.reline) {
        reLine += `${reLine.length ? ' ' : ''} ${this.matter.otherPartyContactInfo.reline}`;
      }
      if (this.matter.isMortgage) {
        reLine +=
          ' ' +
          this.matter.newMortgages
            .map((mortgage) => {
              return mortgage.lenderReline;
            })
            .join('/');
      }
      return reLine;
    }
    return this.matter.isPurchase ? 'PURCHASE' : this.matter.isSale ? 'SALE' : 'MORTGAGE';
  }

  get matterPropertyWithCondo(): MatterProperty {
    return _.find(this.matter.matterProperties, (matterProperty) => matterProperty.isCondominium != undefined);
  }

  get appendStreetNumberAndName(): string {
    return this.matterPropertyWithCondo.address
      ? (this.matterPropertyWithCondo.address.addressLine1
          ? this.matterPropertyWithCondo.address.addressLine1 + ' '
          : '') +
          (this.matterPropertyWithCondo.address.addressLine2 ? this.matterPropertyWithCondo.address.addressLine2 : '')
      : '';
  }

  toggleSearch(focusField) {
    this.searchDocket = !this.searchDocket;
    setTimeout(() => {
      jQuery(focusField).focus();
    }, 100);
  }

  toggleActionButtonFlag(): void {
    this.actionButtonFlag = !this.actionButtonFlag;
    this.dropDownIndex = 0;
  }

  resetActionButtonFlag(): void {
    this.actionButtonFlag = false;
  }

  createDocket(): void {
    this.modalErrorComponent.removeAllDpFieldError();
    this.lockScreenService.lockForUpdate = true;
    this.checkError();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.teranetService
        .createDocket(this.teranetDocket)
        .finally(() => {
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe(
          (createDocketResult: TeranetDocket) => {
            this.closeAndProceedToPurchase(createDocketResult);
          },
          (error: ApplicationError) => {
            if (this.isDocketIdPresent(error)) {
              //If docket already existing then getting actual docket from teranet to show real docket name in duplicate warning
              this.getDocketById(this.teranetDocket.teraviewDocketIdentifier).subscribe(
                (existingTeranetDocket: TeranetDocket) => {
                  this.openWarningModal(existingTeranetDocket, true);
                }
              );
            } else if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
              error.fieldErrors.forEach((fieldError: FieldError) => {
                this.modalErrorComponent.createCustomDPFieldError(
                  fieldError.errorCode,
                  fieldError.errorCode + ':' + fieldError.message,
                  null,
                  'ERROR'
                );
              });
            } else {
              this.modalErrorComponent.createCustomDPFieldError(
                error.errorCode,
                error.errorCode + ':' + error.errorMessage,
                null,
                'ERROR'
              );
            }
          }
        );
    } else {
      this.lockScreenService.lockForUpdate = false;
    }
  }

  getDocketById(docketId): Observable<TeranetDocket> {
    let teranetDocketRequest = new TeranetDocketRequest();
    teranetDocketRequest.matterId = this.matter.id;
    teranetDocketRequest.searchType = 'DOCKET_ID';
    teranetDocketRequest.searchValue = docketId;
    teranetDocketRequest.teranetUser = this.teranetUser;
    return this.teranetService.searchDocket(teranetDocketRequest).map((teranetDocketResult: TeranetDocket[]) => {
      if (teranetDocketResult && teranetDocketResult.length > 0) {
        return teranetDocketResult[0];
      }
    });
  }

  closeAndProceedToPurchase(createDocketResult: TeranetDocket): void {
    this.dialog.close({
      action: 'OK',
      teranetDocket: createDocketResult
    });
  }

  openWarningModal(teranetDocket: TeranetDocket, modalOpenedFromCreateDocket?: boolean): void {
    this.dialogService.matDialogContent({
      content: DuplicateDocketModal,
      context: {
        teranetDocket: teranetDocket,
        matter: this.matter,
        modalOpenedFromCreateDocket: modalOpenedFromCreateDocket,
        teranetUser: this.teranetUser
      },
      onFulfillment: (result: any) => {
        console.log(result);
        if (result && result.action == 'OK' && result.teranetDocket) {
          this.closeAndProceedToPurchase(result.teranetDocket);
        }
      }
    });
  }

  isDocketIdPresent(error: any): boolean {
    if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
      return error.fieldErrors.find((fieldError: FieldError) => fieldError.errorCode === 'DAW21022');
    }
    return false;
  }

  close(): void {
    this.dialog.close({action: 'Cancel'});
  }

  backToProperties(): void {
    this.dialog.close({action: 'backToProperties'});
  }

  handleF9DocketId(): void {
    if (this.matter.matterRecordNumber && this.matter.matterRecordNumber.length > 10) {
      this.teranetDocket.teraviewDocketIdentifier = this.matter.matterRecordNumber.substr(0, 10);
    } else {
      this.teranetDocket.teraviewDocketIdentifier = this.matter.matterRecordNumber;
    }
  }

  generateF9HelpDocketId(): string {
    let teraviewDocketIdentifier: string;

    if (currentMatter.value.matterRecordNumber) {
      teraviewDocketIdentifier = 'F9 = ' + this.matter.matterRecordNumber;
    } else {
      teraviewDocketIdentifier = ' ';
    }
    return teraviewDocketIdentifier;
  }

  handleF9DocketName(): void {
    this.teranetDocket.teraviewDocketName = this.defaultTeraviewDocketName;
  }

  generateF9HelpDocketName(): string {
    let teraviewDocketIdentifier: string;

    if (currentMatter.value.matterRecordNumber) {
      teraviewDocketIdentifier = 'F9 = ' + this.defaultTeraviewDocketName;
    } else {
      teraviewDocketIdentifier = ' ';
    }
    return teraviewDocketIdentifier;
  }

  searchTeranetDocket(searchType: SearchRequestType, searchValue: string): void {
    console.log('searching... ' + searchType + 'Search Value :' + searchValue);
    this.submitted = true;
    let tempSearchValue = this.teranetDocketRequest.searchValue;
    this.searchDocketResult = [];
    this.modalErrorComponent.removeAllDpFieldError();
    this.teranetDocketRequest = new TeranetDocketRequest();
    this.teranetDocketRequest.matterId = this.teranetDocket.matterId;
    this.teranetDocketRequest.searchType = searchType;
    this.teranetDocketRequest.searchValue = searchValue;
    this.teranetDocketRequest.teranetUser = this.teranetUser;

    this.teranetService.searchDocket(this.teranetDocketRequest).subscribe(
      (teranetDocketResult: TeranetDocket[]) => {
        this.searchDocketResult = teranetDocketResult;
        this.updateSelectedItem(0);
        this.submitted = false;
      },
      (error: ApplicationError) => {
        this.submitted = false;
        if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
          error.fieldErrors.forEach((fieldError: FieldError) => {
            this.modalErrorComponent.createCustomDPFieldError(
              fieldError.errorCode,
              fieldError.errorCode + ':' + fieldError.message,
              null,
              'ERROR'
            );
          });
        } else {
          this.modalErrorComponent.createCustomDPFieldError(
            error.errorCode,
            error.errorCode + ':' + error.errorMessage,
            null,
            'ERROR'
          );
        }
      }
    );
    this.teranetDocketRequest.searchValue = tempSearchValue;
  }

  checkError() {
    if (!this.teranetDocket.teraviewDocketName || !this.teranetDocket.teraviewDocketIdentifier) {
      this.modalErrorComponent.createDPFieldError('teranet.createDocket.docketIdAndName');
    }
  }

  onKeyPress(event: KeyboardEvent): void {
    if (
      event.keyCode === 40 || //Down arrow key
      event.keyCode === 38 || //Up arrow key
      event.keyCode === 13 // Enter Key
    ) {
      this.handleArrowKeys(event);
    }
  }

  handleArrowKeys(event): void {
    event.preventDefault();
    if (event.keyCode === 40) {
      //Down arrow key
      if (this.selectedOwnerIndex < this.searchDocketResult.length - 1) {
        this.selectedOwnerIndex++;
      } else {
        this.selectedOwnerIndex = 0;
      }
    } else if (event.keyCode === 38) {
      //Up arrow key
      if (this.selectedOwnerIndex > 0) {
        this.selectedOwnerIndex--;
      } else {
        this.selectedOwnerIndex = this.searchDocketResult.length - 1;
      }
    } else if (event.keyCode === 13) {
      //Enter Key
      this.onSelectTeranetDocket();
    }
  }

  updateSelectedItem(index: number): void {
    this.selectedOwnerIndex = index;
  }

  isRowSelected(index: number): boolean {
    return this.selectedOwnerIndex === index;
  }

  onSelectTeranetDocket(): void {
    if (this.selectedOwnerIndex > -1 && this.selectedOwnerIndex < this.searchDocketResult.length) {
      this.openWarningModal(this.searchDocketResult[this.selectedOwnerIndex]);
    }
  }

  handleSearchArrowDown($event): void {
    if (this.actionButtonFlag) {
      $event.stopPropagation();
      $event.preventDefault();
      if (this.dropDownIndex >= 3) {
        this.dropDownIndex = 0;
      } else {
        this.dropDownIndex++;
      }
    }
  }

  handleSearchArrowUp($event) {
    if (this.actionButtonFlag) {
      $event.stopPropagation();
      $event.preventDefault();
      if (this.dropDownIndex <= 0) {
        this.dropDownIndex = 3;
      } else {
        this.dropDownIndex--;
      }
    }
  }

  openSearchMenu($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.dropDownIndex >= 0 && this.actionButtonFlag) {
      switch (this.dropDownIndex) {
        case 0:
          this.searchTeranetDocket('DOCKET_ID', this.teranetDocketRequest.searchValue + '*');
          break;
        case 1:
          this.searchTeranetDocket('DOCKET_NAME', this.teranetDocketRequest.searchValue + '*');
          break;
        case 2:
          this.searchTeranetDocket('DOCKET_NAME', '*');
          break;
        case 3:
          this.searchTeranetDocket('DOCKET_ID', '*');
          break;
        default:
          break;
      }
    }
    this.toggleActionButtonFlag();
  }

  ngAfterViewInit() {}
}
