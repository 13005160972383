<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type : {{adjustmentTypeTitle}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="soaExpenseAdjustment.isAdjustmentTypeSaleIncentive()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Text referencing Agreement (optional)</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="textReferencingAgreement"
                           name="textReferencingAgreement"
                           class="form-control" maxlength="50"
                           [(ngModel)]="soaExpenseAdjustment.textReferencingAgreement"
                    />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">Number of months <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span></label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="numberOfMonthsCommonExpenses"
                           name="numberOfMonthsCommonExpenses"
                           class="form-control"
                           (change)="updateReserveFundCalculations(soaExpenseAdjustment, soaCommonExpenseAmount(soaExpenseAdjustment))"
                           [(ngModel)]="soaExpenseAdjustment.numberOfMonthsCommonExpenses"
                           [max-int] = 99.99  [padding-zero]="true"
                           dp-percentage
                           [max-decimals] = 2
                    />
                </div>
                <div class="col-lg-2" *ngIf="!soaExpenseAdjustment.isAdjustmentTypeSaleIncentive()">
                    <label class="control-label">@{{getFormattedCurrencyValue(context.soaCommonExpenseAmount)}}/month</label>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="soaExpenseAdjustment.isAdjustmentTypeSaleIncentive()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label">At the monthly amount of</label>
                </div>
                <div class="col-lg-2">
                    <select type="text"
                            id="monthlyAmount"
                            name="monthlyAmount"
                            class="form-control"
                            (ngModelChange)="updateMonthlyAmount($event); updateReserveFundCalculations(soaExpenseAdjustment,  soaCommonExpenseAmount(soaExpenseAdjustment))"
                            [(ngModel)]="soaExpenseAdjustment.monthlyAmountType"
                    >
                        <option *ngFor="let monthlyAmount of atMonthlyAmountOfDropDown" [value]="monthlyAmount.value">
                            {{monthlyAmount.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-2 text-left" *ngIf="soaExpenseAdjustment.isAdditionalAmountApplicable()">
                    <input type="text"
                           id="additionalMonthlyAmount"
                           name="additionalMonthlyAmount"
                           class="form-control"
                           (ngModelChange)="updateReserveFundCalculations(soaExpenseAdjustment, soaCommonExpenseAmount(soaExpenseAdjustment))"
                           [(ngModel)]="soaExpenseAdjustment.additionalMonthlyAmount"
                           [max-int] = 99999.99  [padding-zero]="true"
                           dp-percentage
                           [max-decimals] = 2
                    />
                </div>
            </div>
        </div>


        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label" dp-province-field-label="matter.soa.expense.taxtype">Adjust for</label>
                </div>
                <div class="col-lg-2" *ngIf="context.matter.isMatterProvinceON">
                    <select type="text"
                            id="taxType"
                            name="taxType"
                            class="form-control"
                            (ngModelChange)="updateTaxRate($event) ;updateReserveFundCalculations(soaExpenseAdjustment, soaCommonExpenseAmount(soaExpenseAdjustment))"
                            [(ngModel)]="soaExpenseAdjustment.taxType"
                    >
                        <option *ngFor="let taxType of taxTypeDropDown" [value]="taxType.value">
                            {{taxType.label}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-2 text-left"  >
                    <div *ngIf="context.matter.isMatterProvinceON" style="display:inline-block ">
                        <span class="inline-block width-3percent;">?</span>
                    </div>
                    <div style="display:inline-block ">
                        <select type="text"
                                id="applyTax"
                                name="applyTax"
                                class="form-control"
                                class="form-control inline-block margin-left-5 width-91percent"
                                (ngModelChange)="onChangeApplyTax($event);updateReserveFundCalculations(soaExpenseAdjustment, soaCommonExpenseAmount(soaExpenseAdjustment))"
                                [(ngModel)]="soaExpenseAdjustment.applyTax"
                        >
                            <option *ngFor="let yesNoOption of yesNoDropDown" [value]="yesNoOption.value">
                                {{yesNoOption.label}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group" *ngIf="soaExpenseAdjustment.applyTax == 'YES'">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{soaExpenseAdjustment.taxType}} percentage is</label>
                </div>
                <div class="col-lg-2">
                    <input type="text"
                           id="adjustPercentage"
                           name="adjustPercentage"
                           class="form-control"
                           [(ngModel)]="soaExpenseAdjustment.adjustPercentage"
                           (ngModelChange)="updateReserveFundCalculations(soaExpenseAdjustment, soaCommonExpenseAmount(soaExpenseAdjustment))"
                           [max-int] = 100
                           dp-percentage
                    />
                </div>
                <label class="control-label col-lg-1 text-left">%</label>
            </div>
        </div>

        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd">
            <div class="col-lg-12">
                <div class="col-lg-4">
                    <label class="control-label">
                      {{adjustmentTypeTitle}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12" *ngIf="soaExpenseAdjustment.isAdjustmentTypeSaleIncentive()">
                <div class="col-lg-4">
                    <label class="control-label">
                        {{soaExpenseAdjustment.textReferencingAgreement}}
                    </label>
                </div>
            </div>
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-left">
                    Based on {{soaExpenseAdjustment.numberOfMonthsCommonExpenses}} times
                </label>

            </div>
            <div class="col-lg-12">
                <label class="control-label col-lg-3 text-left">
                    monthly <span dp-province-field-label="matter.soa.expense.commonExpense">common expenses</span> of
                </label>

                <label class="control-label col-lg-2 text-right">
                    {{getFormattedCurrencyValue(soaCommonExpenseAmount(soaExpenseAdjustment))}}
                </label>
            </div>
            <div class="col-lg-12 margin-top-minus-3" *ngIf="soaExpenseAdjustment.applyTax === 'YES'">
                <label class="control-label col-lg-3 text-left" style="white-space: pre-line; line-height: 23px">
                    {{soaExpenseAdjustment.taxType}} calculated at {{soaExpenseAdjustment.adjustPercentage}}%
                </label>
                <label class="control-label col-lg-2 text-right">
                    {{getFormattedCurrencyValue(soaExpenseAdjustment.taxAmount)}}

                </label>
            </div>
            <div class="col-lg-12" *ngIf="!isSalesIncentive">
                <label class="control-label col-lg-3 text-left">
                    Credit Vendor
                </label>

                <label class="control-label col-lg-4 text-right">
                    {{getFormattedCurrencyValue(soaExpenseAdjustment.creditAmount)}}
                </label>
            </div>
            <div class="col-lg-12" *ngIf="isSalesIncentive">
                <label class="control-label col-lg-3 text-left">
                    Credit Purchaser
                </label>
                <div class="col-lg-2"></div>
                <label class="control-label col-lg-1 text-right">
                    {{getFormattedCurrencyValue(soaExpenseAdjustment.creditAmount)}}
                </label>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-10 buttons">
                <button type="button"
                        id="okBtn"
                        (click)="ok()"
                        class="dp-btn keyboard-save-action">
                    <span>OK</span>
                </button>
                <button type="button"
                        (click)="close()"
                        class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
                <button type="button"
                        (click)="delete()"
                        *ngIf="allowDelete"
                        class="secondary-button">
                    <span>Delete</span>
                </button>
            </div>
            <div class="col-lg-2">
                <dp-checkbox fieldId="checkBoxInfoOnly">
                    <input type="checkbox"
                           id="checkBoxInfoOnly"
                           name="checkBoxInfoOnly"
                           [(ngModel)]="soaExpenseAdjustment.infoOnly"
                           [checked]="soaExpenseAdjustment.infoOnly"/>
                </dp-checkbox>
                <label class="control-label vertical-align-label display-inline" >Info Only</label>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
