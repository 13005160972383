import {ErrorService} from '../../shared/error-handling/error-service';
import {Component, Inject} from '@angular/core';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {Logger} from '@nsalaun/ng-logger';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {DialogService} from '../../shared/dialog/dialog.service';
import * as _ from 'lodash';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export type ManageTeranetUserAction = 'Add' | 'Edit';

class ManageTeranetUserModalContext {
  action: ManageTeranetUserAction;
  teranetUser: TeranetUser;
  accountId: string;
  userId: string;
}

@Component({
  selector: 'dp-manage-teranet-user-modal',
  templateUrl: 'manage-teranet-user.modal.component.html',
  providers: [ ErrorService ]
})
@FocusFirstElementDecorator()
export class ManageTeranetUserModal extends ModalComponent<ManageTeranetUserModalContext> {
  teranetUser: TeranetUser;

  constructor(public dialog: MatDialogRef<ManageTeranetUserModal>,
              public dialogService: DialogService,
              public logger: Logger,
              public errorService: ErrorService,
              public teranetService: TeranetService,
              @Inject(MAT_DIALOG_DATA) context?: ManageTeranetUserModalContext
  ) {
    super(dialog, context);
    this.teranetUser = _.cloneDeep(this.context.teranetUser);
  }

  get pageTitle(): string {
    return this.context.action === 'Edit' ? 'Edit Credential' : 'Add Credential';
  }

  saveUser(): void {
    this.teranetService.saveTeranetUser(this.teranetUser).subscribe(
      (teranetUser: TeranetUser) => {
        this.context.teranetUser = teranetUser;
        this.dialog.close({
          action: 'OK',
          teranetUser: teranetUser
        });
      });

  }

  cancel(): void {
    this.dialogService.confirmUnsavedChange(true).subscribe(
      (response: any) => {
        if (response === 'DONT_SAVE') {
          this.dialog.close({action: 'Cancel'});
        } else if (response === 'SAVE') {
          this.saveUser();
        }
      });
  }

  ngAfterViewInit() {
  }

}
