import {api} from '../../common/api';

/** Application endpoint urls
 */
export const requisitionGroupsApi = {

  // requisitionGroups :`${api}/accountRequisitionGroups/`,

  configurationOptionValues: `${ api }/accounts/{accountId}/configurationOptionValues/EnableRequisition`

  // selectedConstrainedConfigurationTypeID: `${api}//accounts/{accountId}/selectedConstrainedConfigurationTypeID`,

};
