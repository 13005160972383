import {Component, Inject, OnInit} from '@angular/core';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {KeyCode, KeyCodesActiveForTable} from '../../../../shared-main/constants';
import {ParcelAdjacentSelection} from '../../model/parcel-adjacent-selection';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export type radioButtonAction = 'SELECT_ALL' | 'SELECT_ALL_REGULAR_PARCEL' | 'SELECT_ALL_ROAD_PARCEL' | 'UNSELECT_ALL';

export class RadioButtonValues {
  label: string;
  active: boolean;
  action: radioButtonAction;
}

export class SearchAdjacentParcelContext {
  adjacentParcels: ParcelRegister[];
  withRoads: boolean;
}

@Component({
  selector: 'dp-search-adjacent-parcel',
  templateUrl: './search-adjacent-parcel.component.html',
  styleUrls: ['./search-adjacent-parcel.component.scss']
})
export class SearchAdjacentParcelComponent extends ModalComponent<SearchAdjacentParcelContext> implements OnInit {
  parcels: ParcelAdjacentSelection[] = [];
  selectedParcelIndex: number = -1;

  actionButtonFlag: boolean = false;

  radioButtons: RadioButtonValues[];

  constructor(
    public dialog: MatDialogRef<SearchAdjacentParcelComponent>,
    public teranetService: TeranetService,
    @Inject(MAT_DIALOG_DATA) context?: SearchAdjacentParcelContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    if (this.context.adjacentParcels) {
      if (this.context.withRoads) {
        this.parcels = this.context.adjacentParcels.map((ap) => new ParcelAdjacentSelection(ap));
      } else {
        this.parcels = this.context.adjacentParcels
          .filter((ap) => ap.adjacentParcelType !== 'R')
          .map((ap) => new ParcelAdjacentSelection(ap));
      }
    }

    this.radioButtons = [
      {label: `Select All (${this.numberOfSelectableParcels})`, active: false, action: 'SELECT_ALL'},
      {label: `Regular Parcel (${this.numberOfRegularParcels})`, active: false, action: 'SELECT_ALL_REGULAR_PARCEL'}
    ];
    if (this.context.withRoads) {
      this.radioButtons.push({
        label: `Road Parcel (${this.numberOfRoadParcels})`,
        active: false,
        action: 'SELECT_ALL_ROAD_PARCEL'
      });
    }
    this.radioButtons.push({label: `Unselect All`, active: true, action: 'UNSELECT_ALL'});
  }

  purchaseParcelRegisters() {
    this.dialog.close({
      action: 'SEARCH_PARCELS',
      selectedParcels: this.context.adjacentParcels.filter((adjParcel) =>
        this.parcels.find((selectedParcel) => selectedParcel.pin === adjParcel.pin && selectedParcel.selected)
      )
    });
  }

  close() {
    this.dialog.close();
  }

  onKeyPress(event: KeyboardEvent): void {
    if (KeyCodesActiveForTable.indexOf(event.keyCode) > -1) {
      event.preventDefault();
      if (event.keyCode === KeyCode.DownArrow) {
        if (this.selectedParcelIndex < this.context.adjacentParcels.length) {
          this.selectedParcelIndex++;
        }
      } else if (event.keyCode === KeyCode.UpArrow) {
        if (this.selectedParcelIndex > 0) {
          this.selectedParcelIndex--;
        }
      } else if (event.keyCode === KeyCode.Enter || event.keyCode === KeyCode.Space) {
        this.onSelectRow();
      }
      jQuery('#parcel-' + this.selectedParcelIndex).focus();
    }
  }

  public onSelectRow() {}

  isRowSelected(index: number): boolean {
    return this.selectedParcelIndex === index;
  }

  updateSelectedItem(index: number): void {
    this.selectedParcelIndex = index;
    this.radioButtons.forEach((rb) => (rb.active = false));
  }

  resetActionButtonFlag(): void {
    this.actionButtonFlag = false;
  }

  toggleActionButtonFlag(event): void {
    event.stopPropagation();
    this.actionButtonFlag = !this.actionButtonFlag;
  }

  get numberOfSelectableParcels(): number {
    return this.parcels.filter((sp) => !sp.ordered).length;
  }

  get numberOfRegularParcels(): number {
    return this.parcels.filter((sp) => sp.parcelType == 'P').length;
  }

  get numberOfRoadParcels(): number {
    return this.parcels.filter((sp) => sp.parcelType == 'R').length;
  }

  onSelectionRadioChange(action: string) {
    this.radioButtons.forEach((rb) => (rb.active = rb.action === action));
    if (action === 'SELECT_ALL') {
      this.checkAllSelectable();
    }
    if (action === 'SELECT_ALL_REGULAR_PARCEL') {
      this.uncheckAll();
      this.checkRegularParcels();
    }
    if (action === 'SELECT_ALL_ROAD_PARCEL') {
      this.uncheckAll();
      this.checkRoadParcels();
    }
    if (action === 'UNSELECT_ALL') {
      this.uncheckAll();
    }
    console.log('radio button changed=' + action);
  }

  checkAllSelectable() {
    this.parcels.forEach((ps) => {
      if (!ps.cannotBeOrdered) {
        ps.selected = true;
      }
    });
  }

  checkParcelsByType(parcelType: string) {
    this.parcels.forEach((ps) => {
      if (!ps.ordered && ps.parcelType === parcelType) {
        ps.selected = true;
      }
    });
  }

  checkRegularParcels() {
    this.checkParcelsByType('P');
  }

  checkRoadParcels() {
    this.checkParcelsByType('R');
  }

  uncheckAll() {
    this.parcels.forEach((ps) => (ps.selected = false));
  }

  get anyItemSelected(): boolean {
    return this.parcels.some((p) => p.selected);
  }
}
