export class ContactInfoType {
  public static readonly DEVELOPER = 'DEVELOPER';
  public static readonly VENDOR = 'VENDOR';
  public static readonly PURCHASER = 'PURCHASER';
  public static readonly LENDER = 'LENDER';
  public static readonly FIRE_INSURER = 'FIRE_INSURER';
  public static readonly LISTING_BROKER = 'LISTING_BROKER';
  public static readonly LISTING_AGENT = 'LISTING_AGENT';
  public static readonly TESTATOR = 'TESTATOR';
}
