import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-chataitoaster',
  templateUrl: 'chat-AI-toaster.component.html'
})
export class ChatAIToasterComponent {
  @Output() closeToasterClicked = new EventEmitter<boolean>();
  @Input() messageText: string;

  constructor() {
  }

  clickCloseToaster() {
    this.closeToasterClicked.emit(false);
  }
}
