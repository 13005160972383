import {ErrorService} from '../../../../shared/error-handling/error-service';
import {Component, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {Matter} from '../../../shared/matter';
import {Observable, Subject} from 'rxjs';
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';
import {
  EstateStatusType,
  EstateTrustee,
  TeranetPropertyOwner
} from '../../../../shared-main/teranet/teranet-property-owner';
import {Contact} from '../../../shared/contact';
import {PurchaserService} from '../../../purchaser/purchaser.service';
import {TeranetOwnerContactModal} from './teranet-owner-contact.modal.component';
import {contactTypeMapping, GenderTypes} from '../../../../contact/contact-type-mapping';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {ContactNameUtil} from '../../../shared/contact-name-util';
import {MatterParticipant} from '../../../shared/matter-participant';
import {TabsService} from '../../../../core/tabs.service';
import {MatterParticipantWrapper} from '../../../shared/matter-participant-wrapper';
import {AppConfig} from '../../../../shared-main/app-configuration';
import {Constants, CustomModalAction, ModalAction} from '../../../../shared-main/constants';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {Address} from '../../../shared/address';
import {PurchaserCapacity} from '../../../purchaser/capacity/purchaser-capacity';
import {AddressTypes} from '../../../shared/address-types';
import {AddressUtil} from '../../../shared/address-util';
import Utils from '../../../../shared-main/utils';
import {TitleDetailsUtil} from '../../../shared/title-details-util';
import {UUIDUtil} from '../../../../main/uuid-util';
import {MatterParticipantService} from '../../../matter-participant-service';
import {ContactUtil} from '../../../shared/contact-util';
import {
  ActionTakenTypes,
  DuplicateContactData
} from '../../../../opportunity-matter/duplicate-prospect/duplicate-contact-data';
import {ContactService} from '../../../../shared-main/contact.service';
import {DuplicateProspectModalComponent} from '../../../../opportunity-matter/duplicate-prospect/duplicate-prospect.modal.component';
import {DuplicateProspectModalResult} from '../../../../shared-main/enums';
import {TeranetOwnerGenderImportModal} from './teranet-owner-gender-import.modal.component';
import {ImportPropertyDataService} from '../../../shared/services/import-property-data.service';
import {PROVINCE_CODES} from '../../../shared/user-province';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

export class TeranetOwnerImportContext {
  matter: Matter;
  selectedParcelRegister: ParcelRegister;
  isWizard: boolean;
}

export class OwnerData {
  parcelRegisterName: string;
  clientContactId: string;
  matterParticipantId: number;
  dataModel: any;
  clientContact: Contact;
  clientName: string;
  itemType: string;
  spinAddress: Address;
  localAddress: Address;
  sameAsSpinAddressFlag: boolean;
  genderType: string;
  estateStatus: EstateStatusType;
  estateTrustees: EstateTrustee[];
  duplicateProspectData: DuplicateContactData;

  isNewRecord(): boolean {
    return this.clientContactId == 'New';
  }

  isNew(): boolean {
    return this.itemType == 'NEW';
  }

  isExisting(): boolean {
    return this.itemType == 'EXISTING';
  }

  getSplittedOwnerName(provinceCode: string): string[] {
    let givenNames: string[] = [];
    if (ContactNameUtil.isItCorporation(this.parcelRegisterName)) {
      givenNames.push(this.parcelRegisterName);
    } else {
      givenNames = ContactNameUtil.parseNamesFromParcelRegister(this.parcelRegisterName, provinceCode);
    }
    return givenNames.filter((s) => s);
  }

  get isContactCorporation(): boolean {
    return this.genderType == GenderTypes.CORPORATION || ContactNameUtil.isItCorporation(this.parcelRegisterName);
  }

  get isEstate(): boolean {
    return this.genderType == GenderTypes.ESTATE || this.parcelRegisterName.includes('personal representative');
  }

  get isOtherentity(): boolean {
    return this.genderType == GenderTypes.OTHERENTITY;
  }
}

export class TenureImportData {
  spinTenure: string;
  localTenure: string;
  sameAsSpinTenureFlag: boolean;
}

@Component({
  selector: 'dp-owner-import-modal-content',
  templateUrl: 'teranet-owner-import.modal.component.html',
  providers: [ErrorService, PurchaserService]
})
export class TeranetOwnerImportModal extends ModalComponent<TeranetOwnerImportContext> {
  ownerData: OwnerData[] = [];
  clients: any[] = [];
  clientPurchasersLoading = false;
  clientPurchasers: any;
  searchTermStreamClient = new Subject<string>();
  autoCompleteEmptyFlag: any;
  genders: SelectItem[];
  selectedContactTypeMapping: any;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  lockedContacts: any[] = [];
  staleContacts: any[] = [];
  tenureData: TenureImportData;
  tenureOptions: any[];

  constructor(
    public dialog: MatDialogRef<TeranetOwnerImportModal>,
    public purchaserService: PurchaserService,
    public dialogService: DialogService,
    public contactService: ContactService,
    public contactQueryService: ContactQueryService,
    public matterParticipantService: MatterParticipantService,
    public tabsService: TabsService,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: TeranetOwnerImportContext
  ) {
    super(dialog, context);
  }

  get matterClients(): MatterParticipant[] {
    return this.context.matter && this.context.matter.isMortgage
      ? this.context.matter.mortgagors
      : this.context.matter.vendors;
  }

  ngOnInit() {
    this.ownerData = [];
    this.selectedContactTypeMapping = _.find(
      contactTypeMapping.CONTACTTYPES,
      (contactTypeObj) => contactTypeObj.contactKey === 'CLIENT'
    );
    if (this.selectedContactTypeMapping) {
      this.genders = this.selectedContactTypeMapping.genders;
    }
    let propertyOwners: TeranetPropertyOwner[] = [];
    if (this.context.selectedParcelRegister.propertyOwners.length > this.appConfig.getMaxNumberOfPurchasers()) {
      propertyOwners = this.context.selectedParcelRegister.propertyOwners.slice(0, 14);
    } else {
      propertyOwners = this.context.selectedParcelRegister.propertyOwners;
    }

    this.clients.push({
      label: 'Select ' + this.teranetParcelRegisterModalLabel() + ' you are replacing',
      value: ''
    });
    this.matterClients.forEach((item) => {
      this.clients.push({
        label: this.matter().isMatterProvinceBC ? item.contact.fullName : item.contact.genericFullName,
        value: item.identifier
      });
    });
    this.clients.push({label: 'Add new ' + this.teranetParcelRegisterModalLabel(), value: 'New'});
    if (propertyOwners) {
      propertyOwners.forEach((item, index) => {
        let ownerDatObject = new OwnerData();
        ownerDatObject.parcelRegisterName = item.fullName;
        ownerDatObject.dataModel = item.lastName
          ? item.lastName
          : item.fullName && item.fullName.indexOf(',') > -1
            ? item.fullName.substring(0, item.fullName.indexOf(','))
            : item.fullName;
        ownerDatObject.clientContactId = this.matterClients && this.matterClients.length ? '' : 'New';
        ownerDatObject.spinAddress = new Address(
          this.matter().isMatterProvinceSK ? AddressUtil.parseOwnerAddressSK(item.address) : item.address
        );
        ownerDatObject.genderType = item.genderType;
        ownerDatObject.estateStatus = item.estateStatus;
        if (item.estateTrustees && item.estateTrustees.length) {
          ownerDatObject.estateTrustees = [];
          item.estateTrustees.forEach((estateTrustee) => {
            ownerDatObject.estateTrustees.push(new EstateTrustee(estateTrustee));
          });
        }
        this.ownerData.push(ownerDatObject);
      });
    }

    this.searchTermStreamClient
      .switchMap((term: string) => {
        this.clientPurchasersLoading = true;
        if (term.trim() === '') {
          this.autoCompleteEmptyFlag = true;
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });

          return observable;
        } else {
          this.autoCompleteEmptyFlag = false;
          return this.purchaserService.getClientPurchaser(term);
        }
      })
      .subscribe(
        (purchasers: any) => {
          this.clientPurchasersLoading = false;
          this.clientPurchasers = purchasers;
        },
        (error) => {
          this.clientPurchasersLoading = false;
        }
      );

    this.initSpin();
    this.openFromMatterWizard();
  }

  async openFromMatterWizard(): Promise<void> {
    if (this.context.isWizard) {
      if (this.context.matter.isMatterProvinceABorMBorSK && this.isTenureItemVisible()) {
        this.tenureData.sameAsSpinTenureFlag = true;
        this.updateTenure();
      }

      let isOwnerDataComplete: boolean = true;
      await this.duplicateOwnerCheck();
      for (let i = 0; i < this.ownerData.length; i++) {
        if (
          this.ownerData[i].duplicateProspectData &&
          this.ownerData[i].duplicateProspectData.duplicateSourceContacts.length > 0
        ) {
          await this.openDuplicateProspectModal(this.ownerData[i].duplicateProspectData, this.ownerData[i]).toPromise();
        } else {
          await this.createOwnersForWizard(this.ownerData[i]);
        }
        if (
          this.ownerData[i].clientContact &&
          (!this.ownerData[i].clientContact.surnameLastFullNameOrBusinessName ||
            (this.ownerData[i].clientContact.surnameLastFullNameOrBusinessName &&
              !this.ownerData[i].clientContact.surnameLastFullNameOrBusinessName.trim()))
        ) {
          isOwnerDataComplete = false;
          break;
        }

        if (!this.ownerData[i].clientContact) {
          isOwnerDataComplete = false;
          break;
        }
      }
      if (!isOwnerDataComplete) {
        this.close(CustomModalAction.WIZARD_OWNER_IMPORT_DATA_ISSUE);
      } else if (this.ownerData.length == 0) {
        this.close();
      } else {
        this.ok();
      }
    }
  }

  openDuplicateProspectModal(duplicateProspectDataList, od: OwnerData): Observable<void> {
    let validationSubject = new Subject<void>();
    this.dialogService.matDialogContent({
      content: DuplicateProspectModalComponent,
      context: {
        duplicateProspectData: duplicateProspectDataList,
        isWizard: this.context.isWizard
      },
      onFulfillment: async (result) => {
        switch (result) {
          case DuplicateProspectModalResult.IGNORE:
            await this.createOwnersForWizard(od);
            validationSubject.next();
            validationSubject.complete();
            break;
          case DuplicateProspectModalResult.REPLACE:
            await this.addExitingForOwnerData(duplicateProspectDataList.replacedSourceContactId, od);
            validationSubject.next();
            validationSubject.complete();
            break;
          default:
            validationSubject.next();
            validationSubject.complete();
            break;
        }
      }
    });
    return validationSubject;
  }

  checkOpenGenderDialog(ownerData: OwnerData): boolean {
    return (
      !!ownerData &&
      !!ownerData.parcelRegisterName &&
      !ownerData.isContactCorporation &&
      !ownerData.isEstate &&
      !ownerData.parcelRegisterName.includes(', ')
    );
  }

  async createOwnersForWizard(ownerData: OwnerData): Promise<void> {
    if (this.checkOpenGenderDialog(ownerData)) {
      await this.createOwnersForWizardCheck(ownerData).toPromise();
    } else {
      await this.createOwnersForWizardAction(ownerData);
    }
  }

  createOwnersForWizardCheck(ownerData: OwnerData): Observable<void> {
    let validationSubject = new Subject<void>();
    this.dialogService.matDialogContent({
      content: TeranetOwnerGenderImportModal,
      context: {
        ownerData: ownerData
      },
      onFulfillment: async (result) => {
        if (result) {
          await this.createOwnersForWizardAction(result);
        } else {
          this.close(CustomModalAction.WIZARD_OWNER_IMPORT_DATA_ISSUE);
        }
        validationSubject.next();
        validationSubject.complete();
      },
      onRejection: () => {
        this.close(CustomModalAction.WIZARD_OWNER_IMPORT_DATA_ISSUE);
      },
      fullScreen: false
    });
    return validationSubject;
  }

  async createOwnersForWizardAction(od: OwnerData): Promise<void> {
    if (this.context.matter) {
      let contact = new Contact();
      ContactUtil.createOwnerDataContact(contact, od, this.context.matter, true);
      let prePopulateAddress: Address = null;
      if (ImportPropertyDataService.hasObjectAddress(this.context.matter.provinceCode)) {
        prePopulateAddress = new Address(od.spinAddress);
        prePopulateAddress.id = null;
        prePopulateAddress.primaryAddress = true;
        prePopulateAddress.addressTypeCode = AddressTypes.mailing;
        prePopulateAddress.changeToMixedCase();
        contact.address = [];
        let contactAddress = new Address(prePopulateAddress);
        contactAddress.primaryAddress = true;
        contact.address.push(contactAddress);
      }
      if (
        this.context.matter.isMortgage ||
        this.context.matter.isSale ||
        (this.context.matter.isPurchase && this.context.matter.actingFor == 'PURCHASER_VENDOR')
      ) {
        ContactUtil.createOwnerDataFullContact(contact);
        let contactUpdated: Contact = await this.contactQueryService.createContact(contact).toPromise();
        if (contactUpdated) {
          let snapShotContact = new Contact();
          snapShotContact.createNewContactClone(contactUpdated);
          snapShotContact.snapshotFlag = true;
          snapShotContact.sourceContactId = contactUpdated.id;
          snapShotContact.lastSyncedFromSource = contact.lastSyncedFromSource;
          this.updateOwnerDataAfterImport(snapShotContact, od);
        }
      } else {
        this.updateOwnerDataAfterImport(contact, od);
      }
    }
  }

  async duplicateOwnerCheck(): Promise<void> {
    let contactObservables: Observable<any>[] = [];
    this.ownerData.forEach((od) => {
      let givenNames = od.getSplittedOwnerName(this.context.matter.provinceCode);
      if (givenNames && givenNames.length > 0) {
        let duplicateOwnerData: DuplicateContactData = new DuplicateContactData();
        duplicateOwnerData.genericName = od.parcelRegisterName;
        duplicateOwnerData.actionTaken = ActionTakenTypes.DO_NOTHING;
        od.duplicateProspectData = duplicateOwnerData;
        if (PROVINCE_CODES.BRITISH_COLOMBIA == this.context.matter.provinceCode) {
          contactObservables.push(this.contactService.duplicateOwnerSearchBC(od, givenNames));
        } else {
          contactObservables.push(this.contactService.duplicateOwnerSearch(od, givenNames));
        }
      }
    });
    if (contactObservables && contactObservables.length) {
      let responseData = await Observable.forkJoin(contactObservables).toPromise();

      responseData.forEach((data: Contact[], index: number) => {
        this.ownerData[index].duplicateProspectData.duplicateSourceContacts = data;
      });
    }
  }

  initSpin() {
    this.tenureData = new TenureImportData();
    if (this.context.matter) {
      if (
        (this.context.matter.isMatterProvinceAB && (this.context.matter.isMortgage || this.context.matter.isSale)) ||
        this.context.matter.isMatterProvinceSK ||
        this.context.matter.isMatterProvinceMB
      ) {
        this.tenureOptions = PurchaserCapacity.getMatterPurchasersCapacityOptions(
          this.context.matter.mainClients && this.context.matter.mainClients.length,
          this.context.matter.provinceCode
        );
        if (!this.context.matter.purchasersCapacity) {
          this.context.matter.initMainClientsCapacity();
        }
        this.tenureData.localTenure = this.context.matter.purchasersCapacity;
        // We only have one tenure in a matter. It should only get one capacityCode for updating the matter tenure.
        if (
          this.context.selectedParcelRegister.propertyOwners &&
          this.context.selectedParcelRegister.propertyOwners.length > 0
        ) {
          this.tenureData.spinTenure = this.context.selectedParcelRegister.propertyOwners[0].capacityCode;
        }
      }
    }
  }

  matter(): Matter {
    return this.context.matter;
  }

  releaseContactLocks(): void {
    this.ownerData
      .filter((item) => item.isExisting())
      .forEach((item) => {
        let matterParticipant = this.matterClients.find(
          (mainClient) => mainClient.identifier == Number(item.clientContactId)
        );
        if (matterParticipant && matterParticipant.sourceContactLockAcquired && !matterParticipant.contact.isDirty) {
          let matterParticipantWrapper = new MatterParticipantWrapper();
          matterParticipantWrapper.matterParticipant = matterParticipant;
          this.tabsService.unLockSourceContact(matterParticipantWrapper);
        }
      });
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpSaveError();
    let duplicateErrorList = this.checkForDuplicateContactSelection();
    let invalidGenders = this.checkForInvalidGender();
    if (duplicateErrorList.length > 0) {
      duplicateErrorList.forEach((contactErrorMsg) => {
        this.modalErrorComponent.createCustomDPSaveError('none', contactErrorMsg, 'CONTACT', 'ERROR');
      });
    } else if (invalidGenders.length > 0) {
      invalidGenders.forEach((contactErrorMsg) => {
        this.modalErrorComponent.createCustomDPSaveError('none', contactErrorMsg, 'CONTACT', 'ERROR');
      });
    } else {
      this.checkContactStaleOrClearFlag().subscribe((item) => {
        if (!item) {
          this.checkContactLocking().subscribe((item) => {
            if (!item) {
              this.updateMatterContactDetails();
            } else {
              this.lockedContacts.forEach((contactErrorMsg) => {
                this.modalErrorComponent.createCustomDPSaveError(
                  'teranet.owner.contact.locked.' + contactErrorMsg.identifier,
                  contactErrorMsg.message,
                  'CONTACT',
                  'ERROR'
                );
              });
            }
          });
        } else {
          this.staleContacts.forEach((contactErrorMsg) => {
            this.modalErrorComponent.createCustomDPSaveError(
              'teranet.owner.contact.sync.' + contactErrorMsg.identifier,
              contactErrorMsg.message,
              'CONTACT',
              'ERROR'
            );
          });
        }
      });
    }
  }

  updateExistingContact(): void {
    this.ownerData.forEach((item) => {
      if (item.isExisting()) {
        let givenNames: string[] = item.getSplittedOwnerName(this.context.matter.provinceCode).filter((name) => !!name);

        let matterParticipant = this.matterClients.find((obj) => obj.identifier == Number(item.clientContactId));
        if (matterParticipant) {
          if (matterParticipant.sourceContactLockAcquired || !matterParticipant.sourceContact) {
            if (
              matterParticipant &&
              matterParticipant.contact &&
              (matterParticipant.contact.isCorporation || matterParticipant.contact.isOtherEntity)
            ) {
              matterParticipant.contact.organizationName = Utils.toMixedCase(givenNames[0]);
              matterParticipant.contact.isDirty = true;
            } else if (
              matterParticipant &&
              matterParticipant.contact &&
              !matterParticipant.contact.isCorporation &&
              givenNames &&
              givenNames.length > 0
            ) {
              ContactNameUtil.convertStringArrayToContactName(givenNames, matterParticipant.contact.contactName, true);
              matterParticipant.contact.isDirty = true;
            }
            matterParticipant.contact.nameOnDocuments = matterParticipant.contact.reLineName;
          }
          if (
            item.sameAsSpinAddressFlag &&
            matterParticipant.contact.address &&
            matterParticipant.contact.address.length > 0
          ) {
            const addressIndex: number = matterParticipant.contact.address.findIndex(
              (item: Address) => item.primaryAddress
            );
            if (addressIndex > -1) {
              matterParticipant.contact.address[addressIndex] = new Address(item.spinAddress).normalizeAddressLines();
              matterParticipant.contact.address[addressIndex].id = null;
              matterParticipant.contact.address[addressIndex].addressTypeCode = AddressTypes.mailing;
              matterParticipant.contact.address[addressIndex].changeToMixedCase();
            }
          }
          //As per discussion with Moses, Vikas, the following update will only apply to S/M matter for all provinces
          //the imported owner data update vendor (P/S)/Mortgagor (M), for Purchase Matter, vendor get updated,
          //but matterContactInfo is for Purchaser, so skip it.
          if (this.matter() && !this.matter().isPurchase) {
            this.updateMatterContactInfoFromPrimaryContact(matterParticipant);
          }
        }
      }
    }, this);
  }

  async updateMatterContactDetails(): Promise<void> {
    this.updateExistingContact();
    await this.updateNewContactDetails();
    this.updateTitleDetailsOnMatter();
    if (this.context.matter.isMortgage || this.context.matter.isSale) {
      this.context.matter.reCalculateClientReLine();
    } else {
      this.context.matter.reCalculateOtherPartyReLine();
    }
    if (
      ((this.context.matter.isMatterProvinceABorSK && this.context.matter.isSale) ||
        this.context.matter.isMortgage ||
        this.context.matter.isMatterProvinceMB) &&
      this.tenureData.sameAsSpinTenureFlag
    ) {
      this.context.matter.purchasersCapacity = this.tenureData.spinTenure;
    }
    if (this.context.matter && this.context.matter.teranetDocket) {
      this.context.matter.teranetDocket.primarySpinOwnerParcelRegisterId = this.context.selectedParcelRegister.id;
      this.context.matter.teranetDocket.ownerImportedFromSpin = true;
    }

    this.dialog.close({action: ModalAction.OK});
  }

  updateTitleDetailsOnMatter() {
    if (this.context.matter.isMatterProvinceSK && this.context.matter.isMortgage) {
      TitleDetailsUtil.generateAndUpdateTitleDetailsSkMb(this.context.matter);
    }
  }

  async updateNewContactDetails(): Promise<void> {
    for (let item of this.ownerData) {
      if (item.isNew()) {
        let person: MatterParticipant = new MatterParticipant();
        let participant: MatterParticipant = null;

        //Creating snapshot from source contact to be added into matter participant
        let snapshot: Contact = new Contact();
        snapshot.createNewContactClone(item.clientContact);
        // DPPMP-37091 Id verified by and on should not be part of a client contact. They are matter only fields.
        snapshot.enteredOn = null;
        snapshot.idInfoEnteredBy = null;
        snapshot.contactIdInfoEnteredBy = null;

        snapshot.snapshotFlag = true;
        snapshot.sourceContactId = item.clientContact.sourceContactId;

        person.contact = snapshot;
        person.matterParticipantRole = this.context.matter.isMortgage ? 'MORTGAGOR' : 'VENDOR';
        person.contact.nameOnDocuments = person.contact.reLineName;
        if (item.sameAsSpinAddressFlag && person.contact.address && person.contact.address.length > 0) {
          const addressIndex: number = person.contact.address.findIndex((item: Address) => item.primaryAddress);
          if (addressIndex > -1) {
            if (snapshot.sourceContactId) {
              person.sourceContactLockAcquired = true;
              person.contact.isDirty = true;
            }
            person.contact.address[addressIndex] = new Address(item.spinAddress).normalizeAddressLines();
            person.contact.address[addressIndex].primaryAddress = true; //flag value lost in the above step
            person.contact.address[addressIndex].id = null;
            person.contact.address[addressIndex].addressTypeCode = AddressTypes.mailing;
            person.contact.address[addressIndex].changeToMixedCase();
          }
        }

        if (!this.context.matter.matterParticipants) {
          this.context.matter.matterParticipants = [];
        }
        //participant.contact.idInfoEnteredBy = null;
        //participant.contact.enteredOn = null;
        participant = new MatterParticipant(person);
        participant.contact.lastSyncedFromSource = item.clientContact.lastUpdatedTimeStamp;
        participant.primary = this.matterClients.length == 0;

        let maxPriority: number =
          this.matterClients.length == 0
            ? 0
            : Math.max(
                ...this.matterClients.map((matterParticipant) => {
                  return Number(matterParticipant.matterParticipantPriority);
                })
              );
        maxPriority = isNaN(maxPriority) ? 0 : maxPriority;
        participant.matterParticipantPriority = maxPriority + 1;

        this.context.matter.matterParticipants.push(participant);
        await this.matterParticipantService.createMatterParticipantAssociatedContactForClient(
          snapshot,
          participant,
          this.context.matter
        );
        //As per discussion with Moses, Vikas, the following update will only apply to S/M matter for all provinces
        //the imported owner data update vendor (P/S)/Mortgagor (M), for Purchase Matter, vendor get updated,
        //but matterContactInfo is for Purchaser, so skip it.
        if (this.matter() && !this.matter().isPurchase) {
          this.updateMatterContactInfoFromPrimaryContact(participant);
        }
      }
    }
  }

  updateMatterContactInfoFromPrimaryContact(participant: MatterParticipant) {
    if (participant.primary) {
      this.context.matter.updateMatterContactInfoFromPrimaryContact(participant.contact);
    }
  }

  close(action?: string): void {
    this.releaseContactLocks();
    this.dialog.close({action: action});
  }

  // autocomplete
  // PURCHASER
  // Omnibar methods to get contacts
  search(event): void {
    let entered: string = event.query;
    this.searchTermStreamClient.next(entered);
  }

  updateClientName(item: OwnerData, index: number): void {
    item.clientName = '';
    if (item.isNewRecord()) {
      let currentComp = this;
      setTimeout(() => {
        jQuery('#clientSearch' + index)
          .find('input')
          .focus();
        jQuery('#clientSearch' + index)
          .find('input')
          .keyup();
        currentComp.searchTermStreamClient.next(item.dataModel);
      }, 100);
      item.itemType = 'NEW';
      if (!item.dataModel) {
        item.dataModel =
          item.parcelRegisterName && item.parcelRegisterName.indexOf(',') > -1
            ? item.parcelRegisterName.substring(0, item.parcelRegisterName.indexOf(','))
            : item.parcelRegisterName;
      }
    } else if (item.clientContactId != undefined && item.clientContactId != '') {
      let client = this.clients.find((obj) => obj.value == item.clientContactId);
      if (client) {
        item.clientName = client.label;
        if (!item.sameAsSpinAddressFlag) {
          let matterParticipant: MatterParticipant = this.matterClients.find(
            (obj) => obj.identifier == Number(item.clientContactId)
          );
          item.localAddress = new Address(matterParticipant.contact && matterParticipant.contact.primaryAddress);
        }
        item.itemType = 'EXISTING';
      }
    }
  }

  clearClient(item: OwnerData): void {
    item.dataModel = '';
  }

  dataSelectedClient(item: OwnerData): void {
    let selectedContact = item.dataModel;
    if (selectedContact && selectedContact.id != undefined) {
      this.addExitingForOwnerData(selectedContact.id, item);
    } else if (
      selectedContact &&
      selectedContact.displayName &&
      selectedContact.displayName.indexOf('Add new record for') > -1
    ) {
      this.addNewRecordForOwnerData(item);
    }
  }

  async addExitingForOwnerData(contactId: number, item: OwnerData): Promise<void> {
    let contact: Contact = await this.contactQueryService.getContactForMatter(contactId).toPromise();
    item.clientContact = new Contact(contact);
    item.clientName = this.matter().isMatterProvinceBC ? item.clientContact.fullName : item.clientContact.genericName;
    if (!item.sameAsSpinAddressFlag) {
      item.localAddress = new Address(item.clientContact.primaryAddress);
    }
    item.itemType = 'NEW';
  }

  addNewRecordForOwnerData(item: OwnerData): void {
    let prePopulateAddress: Address = null;
    let provinceCode = this.context.matter.provinceCode;
    if (ImportPropertyDataService.hasObjectAddress(provinceCode)) {
      prePopulateAddress = new Address(item.spinAddress);
      prePopulateAddress.id = null;
      prePopulateAddress.primaryAddress = true;
      prePopulateAddress.addressTypeCode = AddressTypes.mailing;
      prePopulateAddress.changeToMixedCase();
    }

    this.dialogService.matDialogContent({
      content: TeranetOwnerContactModal,
      context: {
        matter: this.context.matter,
        parcelRegisterName: item.parcelRegisterName,
        isMatterSaleOrMortgage: this.context.matter.isSale || this.context.matter.isMortgage,
        isModalOpenFromLenderData: false,
        address: prePopulateAddress,
        ownerData: item,
        isWizard: this.context.isWizard
      },
      onFulfillment: (contact: Contact) => {
        if (contact) {
          this.updateOwnerDataAfterImport(contact, item);
        } else {
          item.dataModel = '';
        }
      }
    });
  }

  updateOwnerDataAfterImport(contact: Contact, ownerData: OwnerData): void {
    ownerData.clientContact = new Contact(contact);
    ownerData.clientName = this.matter().isMatterProvinceBC
      ? ownerData.clientContact.fullName
      : ownerData.clientContact.genericName;
    if (!ownerData.sameAsSpinAddressFlag) {
      ownerData.localAddress = new Address(ownerData.clientContact.primaryAddress);
      ownerData.localAddress.id = UUIDUtil.getUUID();
    }
    ownerData.itemType = 'NEW';
  }

  public getGenderValue(v) {
    switch (v) {
      case 'FEMALEPOA':
        return 'Female (PoA)';
      case 'MALEPOA':
        return 'Male (PoA)';
      case 'OTHERENTITY':
        return 'Other Entity';
      case 'MALE':
        return 'Male';
      case 'FEMALE':
        return 'Female';
      case 'ESTATE':
        return 'Estate';
      case 'CORPORATION':
        return 'Corporation';
      default:
        return v;
    }
  }

  removeClientName(item: OwnerData): void {
    item.clientContact = undefined;
    item.clientName = undefined;
    item.localAddress = null;
    if (!(item.clientContactId === 'New')) {
      item.clientContactId = '';
    }
    item.dataModel =
      item.parcelRegisterName && item.parcelRegisterName.indexOf(',') > -1
        ? item.parcelRegisterName.substring(0, item.parcelRegisterName.indexOf(','))
        : item.parcelRegisterName;
  }

  checkContactLocking(): Observable<boolean> {
    this.lockedContacts = [];
    let getContactObservables: Observable<any>[] = [];
    //For new one, the address will be updated after create source contact
    // So remove the filter and check all ownerData
    this.ownerData.forEach((item) => {
      // this.ownerData.filter(item => item.isExisting()).forEach(item => {
      if (item.isExisting()) {
        let matterParticipant = this.matterClients.find(
          (mainClient) => mainClient.identifier == Number(item.clientContactId)
        );
        if (
          matterParticipant &&
          matterParticipant.contact &&
          matterParticipant.contact.sourceContactId &&
          !matterParticipant.sourceContactLockAcquired
        ) {
          let matterParticipantWrapper = new MatterParticipantWrapper();
          matterParticipantWrapper.matterParticipant = matterParticipant;
          getContactObservables.push(this.tabsService.lockSourceContactForShutter(matterParticipantWrapper, true));
        }
      }
      // If it is new with source contact and need update address, it needs lock this contact for editing
      // We can not use lockSourceContactForShutter because the matterParticipant is not created
      if (item.isNew() && item.clientContact && item.clientContact.sourceContactId && item.sameAsSpinAddressFlag) {
        getContactObservables.push(this.contactQueryService.getContact(item.clientContact.sourceContactId));
      }
    });
    if (getContactObservables.length > 0) {
      return Observable.forkJoin(getContactObservables).flatMap((refreshedContacts: any[]) => {
        refreshedContacts.forEach((sourceContact) => {
          if (sourceContact.locked) {
            this.lockedContacts.push({
              message: sourceContact.genericFullName + ' is currently locked by ' + sourceContact.lockedByUser.fullName,
              identifier: sourceContact.identifier
            });
          }
        });
        return Observable.of(this.lockedContacts.length > 0);
      });
    } else {
      return Observable.of(false);
    }
  }

  checkContactStaleOrClearFlag(): Observable<boolean> {
    this.staleContacts = [];
    let getContactObservables: Observable<any>[] = [];
    this.ownerData
      .filter((item) => item.isExisting())
      .forEach((item) => {
        //Now new type ownerData will update snapshot address
        //     this.ownerData.forEach(item => {
        let matterParticipant = this.matterClients.find(
          (mainClient) => mainClient.identifier == Number(item.clientContactId)
        );
        if (
          matterParticipant &&
          matterParticipant.contact &&
          matterParticipant.contact.sourceContactId &&
          !matterParticipant.sourceContactLockAcquired
        ) {
          getContactObservables.push(
            this.contactQueryService.getContactForMatter(Number(matterParticipant.contact.sourceContactId))
          );
        }
      });
    if (getContactObservables.length > 0) {
      return Observable.forkJoin(getContactObservables).flatMap((refreshedContacts: any[]) => {
        refreshedContacts.forEach((sourceContact) => {
          if (sourceContact) {
            let matterParticipant = this.matterClients.find(
              (mainClient) => mainClient.contact.sourceContactId == sourceContact.id
            );
            let source: Contact = new Contact(sourceContact);
            let isStale = matterParticipant.contact.isStaleContact(source);
            let isContactStaleFlagClearedWithoutUpdatingMatter =
              matterParticipant.contact.isContactStaleFlagClearedWithoutUpdatingMatter(source);
            if (isStale || isContactStaleFlagClearedWithoutUpdatingMatter) {
              this.staleContacts.push({
                message: this.matter().isMatterProvinceBC
                  ? matterParticipant.contact.genericFullName + ' is flagged as requiring updating.'
                  : this.teranetParcelRegisterModalLabel() +
                    matterParticipant.contact.genericFullName +
                    ' can not be updated as the contact is out of sync.',
                identifier: matterParticipant.contact.identifier
              });
            }
          }
        });
        return Observable.of(this.staleContacts.length > 0);
      });
    } else {
      return Observable.of(false);
    }
  }

  checkForDuplicateContactSelection(): string[] {
    let duplicateErrorList = [];
    if (this.ownerData.filter((item) => item.isExisting()).length > 0) {
      let ownerDataList = this.ownerData.slice(0);
      this.ownerData
        .filter((item) => item.isExisting() && item.clientContactId)
        .forEach((item) => {
          let ownerList = ownerDataList.filter((owner) => owner.clientContactId == item.clientContactId);
          if (ownerList.length > 1) {
            duplicateErrorList.push(
              'Multiple owners are replacing the same ' +
                this.teranetParcelRegisterModalLabel() +
                ' ' +
                ownerList[0].clientName
            );
          }
          (<any>ownerDataList).remove(ownerList);
        });
    }
    if (this.ownerData.filter((item) => item.isNewRecord() && !item.clientContact).length > 0) {
      if (this.matter().isMatterProvinceBC) {
        duplicateErrorList.push(
          'You indicated that a contact was to be added, however you did not complete this action'
        );
      } else {
        this.ownerData
          .filter((item) => item.isNewRecord() && !item.clientContact)
          .forEach((owner) => {
            duplicateErrorList.push(
              'You indicated that a contact was to be added, however you did not complete this action for ' +
                owner.parcelRegisterName
            );
          });
      }
    }
    return duplicateErrorList;
  }

  checkForInvalidGender(): string[] {
    let invalidGenders: string[] = [];
    this.ownerData
      .filter((item) => item.isExisting())
      .forEach((item) => {
        let matterParticipant = this.matterClients.find(
          (mainClient) => mainClient.identifier == Number(item.clientContactId)
        );
        if (
          matterParticipant &&
          matterParticipant.contact &&
          ((item.isContactCorporation && matterParticipant.contact.isPerson) ||
            (!item.isContactCorporation &&
              (matterParticipant.contact.isCorporation || matterParticipant.contact.isOtherEntity)))
        ) {
          invalidGenders.push(
            'Gender can not be changed for ' +
              matterParticipant.contact.genericFullName +
              '. You must remove this contact from the matter before importing.'
          );
        }
      });
    return invalidGenders;
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  get teranetOwnerImportModalLabel(): string {
    return provinceBasedFieldLabels.get('teranet.owner.import.modal.head.label', this.context.matter.provinceCode);
  }

  get teranetOwnerImportModalTitlelabel(): string {
    return provinceBasedFieldLabels.get('teranet.owner.import.modal.title.label', this.context.matter.provinceCode);
  }

  teranetParcelRegisterModalLabel(isTitle?: boolean): string {
    return this.context.matter.isMortgage
      ? this.context.matter.isMatterProvinceBC
        ? `Borrower${isTitle ? 's' : ''}`
        : 'Mortgagor'
      : `${this.context.matter.isMatterProvinceBC ? 'Seller' : 'Vendor'}${isTitle ? 's' : ''}`;
  }

  isAddressCheckboxVisible(item: OwnerData): boolean {
    return (
      !item.clientContact ||
      (item.spinAddress &&
        item.spinAddress.addressTextWithProvinceName != item.clientContact.primaryAddress.addressTextWithProvinceName)
    );
  }

  updateAddress(item: OwnerData) {
    if (item.sameAsSpinAddressFlag) {
      item.localAddress = new Address(item.spinAddress);
      item.localAddress.changeToMixedCase();
    } else {
      if (item.isNewRecord()) {
        item.localAddress = item.clientContact && item.clientContact.primaryAddress;
      } else if (item.clientContactId != undefined && item.clientContactId != '') {
        let matterParticipant: MatterParticipant = this.matterClients.find(
          (mainClient) => mainClient.identifier == Number(item.clientContactId)
        );
        if (matterParticipant) {
          item.localAddress = new Address(matterParticipant.contact && matterParticipant.contact.primaryAddress);
        }
      }
    }
  }

  //check against TabB's capacity field
  isTenureCheckboxVisible(): boolean {
    return this.tenureData.spinTenure != this.context.matter.purchasersCapacity;
  }

  updateTenure() {
    if (this.tenureData.sameAsSpinTenureFlag) {
      this.tenureData.localTenure = this.tenureData.spinTenure;
    } else {
      this.tenureData.localTenure = this.context.matter.purchasersCapacity;
    }
  }

  isTenureItemVisible(): boolean {
    return !!this.tenureData.spinTenure;
  }

  isAddressFieldsVisible(item: OwnerData): boolean {
    // If clientContactId is 'New' and clientContact is not null
    // or clientContactId is not 'New' and clientContactId is matterParticipant identifier(not null),
    // it will show address fields
    // "!!" makes sure return boolean
    // Although return item.isNewRecord() ? !!item.clientContact : !!item.clientContactId is simplified,
    // the following is easy to understanding and debugging
    if (item.isNewRecord()) {
      //For new records, we’re showing the addresses if we have a client contact record
      return !!item.clientContact;
    } else {
      return !!item.clientContactId;
    }
  }
}
