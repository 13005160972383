import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {StatementAdjustment} from '../statement-adjustment';
import {OccupancyFeesCalculatedBasedOn} from '../../../projects/project-condo/project-condo';
import {StatementAdjustmentAmountTypes} from '../statement-adjustment-constants';

export const PurchasePriceDeferredPortionTitleOfAdjustment = 'Deferred Portion of Purchase Price';

export class SoAdjPurchasePriceDeferredPortion extends BaseEntity {
  id: number;
  titleOfAdjustment: string;
  amount: number;

  constructor(soAdjPurchasePriceDeferredPortion?: SoAdjPurchasePriceDeferredPortion) {
    super(soAdjPurchasePriceDeferredPortion);
    if (!soAdjPurchasePriceDeferredPortion) {
      this.titleOfAdjustment = PurchasePriceDeferredPortionTitleOfAdjustment;
    }
  }

  // IF <Occupancy fees calculated based on (Project Record Condo Tab)> = 'deferred purchase monies' THEN
  // The field is populated with the value from the 'Deferred purchase monies' field of the Deposit adjustment
  // ELSE IF (<Is this is a condo?> = 'No' and<Is there a POTL> = 'Yes') OR (<Is this is a condo?> = 'No' and <Is an occupancy date required for each transaction in this project> = 'Yes') THEN
  // The field is populated with the value entered in the currency field on the 'Interest on Deferred Purchase Monies' row of the "Early Possession Fees (Interim Closing)" adjustment (See wireframe)
  // END IF
  updateDeferredPortionOfPurchase(isCondo: boolean, isPotl: boolean, occupancyFeesCalculatedBasedOn: string, occupancyDateRequired: boolean, deferredPurchaseMoniesAmount: number, mortgageInterest: number, deferredPortionOfPurchaseSoa?: StatementAdjustment) {
    this.amount = 0;
    if (isCondo && occupancyFeesCalculatedBasedOn === OccupancyFeesCalculatedBasedOn.purchaseMonies) {
      this.amount = deferredPurchaseMoniesAmount ? deferredPurchaseMoniesAmount : 0;
    }

    if (!isCondo && (isPotl || occupancyDateRequired)) {
      this.amount = mortgageInterest ? mortgageInterest : 0;
    }

    if (deferredPortionOfPurchaseSoa) {
      deferredPortionOfPurchaseSoa.amount = this.amount;
      deferredPortionOfPurchaseSoa.description = this.titleOfAdjustment;
      deferredPortionOfPurchaseSoa.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;

    }
  }
}
