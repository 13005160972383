<!-- Mortgagee -->
<div class="col-lg-12"  [ngClass]="!!isOpenedFromWizard ? 'margin-bottom-20' : 'fields-group'">
    <div class="main-level" *ngIf="!matter.isOpportunityMatter()">
        <span *ngIf ="!isOpenedFromWizard" class="title no-min-height">{{getMortgageeHeaderLabel()}}</span>
        <ng-container *ngIf="isPayOutVisible()">
            <div class="pull-right actionbtns margin-top-minus-10">
                <button #btnAddNew id="initiatePayoutElmentRef"
                         [hidden]="!!isOpenedFromWizard"
                        (click)="initiatePayoutModal()"
                        class="dp-btn-secondary">
                    <span>{{payoutStatementButtonLabel}}</span>
                </button>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="isInstitutionMortgageeType()">
        <ng-container *ngIf="payoutStatement">
            <div class="form-group col-lg-12">
                <div class="right margin-left-10 margin-top-5">
                    <img title="History" alt="History" class="pointerCursor" src="/assets/img/clock-icon.png" (click)="openPayoutStatementHistory()"/>
                </div>
                <div class="right pointerCursor margin-left-15 margin-top-5" *ngIf="isDocAssociatedWithLatestPayoutStatementStatus(payoutStatement)"><i class="fa fa-paperclip pointerCursor font-22" title="Attachment" (click)="downloadDocAssociatedWithLatestStatus(payoutStatement)"></i>
                </div>
                <div class="right pointerCursor margin-left-10" *ngIf="isImportDataApplicable(payoutStatement)" >
                    <button class="dp-btn-secondary" (click)="openImportPayoutModal()">Import Data</button>
                </div>

                <div class="right margin-left-10 margin-top-10">{{payoutStatement.getCurrentStatusText()}}</div>
                <div class="bold right margin-left-10 margin-top-10">Payout Status:</div>
            </div>
            <div class="form-group col-lg-12" *ngIf="payoutStatement.expiryDate && !payoutStatement.isCancelled()">
                <div *ngIf="payoutStatement.getLatestPayoutStatementStatus() && !payoutStatement.getLatestPayoutStatementStatus().isFundsPaid() && mortgage && mortgage.payoutStatement">
                    <!-- <div class="right margin-left-10 margin-top-10" >Overdue</div> -->
                    <div class="right padding-left-10 padding-right-0 col-lg-2">
                        <select class="form-control" [(ngModel)]="mortgage.payoutStatement.paymentState" (change)="changePaymentStatesValue()">
                            <option *ngFor="let state of paymentStates"
                                    [value]="state.value">
                                {{state.label}}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="mortgage.payoutStatement.paymentState === 'PAYMENT_OVERDUE'" class="right margin-left-10 margin-top-5 padding-top-5">
                        <i class="fa fa-ban" style="color: red!important;" aria-hidden="true"></i>
                    </div>
                </div>

                <div *ngIf="payoutStatement.getLatestPayoutStatementStatus() && !payoutStatement.getLatestPayoutStatementStatus().isFundsPaid()" class="right margin-left-10 margin-top-10">{{payoutStatement.formattedExpiryDate()}}</div>
                <div *ngIf="payoutStatement.getLatestPayoutStatementStatus() && !payoutStatement.getLatestPayoutStatementStatus().isFundsPaid()" class="bold right margin-left-10 margin-top-10">Payout Expiry Date:</div>
            </div>
        </ng-container>

        <div class="col-lg-12" *ngFor="let selectedMortgagee of selectedMortgagees; let i=index">
            <div [ngClass]="{'shutter-bg': selectedMortgagee.expanded, 'shutter-bg-closed': (isMatterLocked || !selectedMortgagee.editMode) && !selectedMortgagee.expanded}">
            <div class="form-group">
                <div class="shutter-arrow" (click)="toggleMortgageeShutter(selectedMortgagee)">
                    <div *ngIf="selectedMortgagee.expanded"><img src="/assets/img/minus.svg" alt=""/></div>
                    <div *ngIf="(isMatterLocked || !selectedMortgagee.editMode) && !selectedMortgagee.expanded"><img
                        src="/assets/img/plus.svg"/></div>
                </div>

                <span *ngIf="!isMortgageeAnInstitution()"
                      class="col-lg-2 control-label"
                      (click)="toggleMortgageeShutter(selectedMortgagee)"
                      [class.focus]="fieldHoverIndex===11">{{getMortgageeLabel()}} {{i + 1}}</span>
                <span *ngIf="isMortgageeAnInstitution()"
                      class="col-lg-2 control-label"
                      (click)="toggleMortgageeShutter(selectedMortgagee)"
                      [class.focus]="fieldHoverIndex===11">{{getMortgageeLabel()}}</span>
                <div class="col-lg-10" (focusout)="clearMortgageeFieldIfNotSelected(i)">
                    <input type="text"
                           class="form-control rightPadding350"
                           readonly="readonly"
                           *ngIf="!selectedMortgagee.editMode"
                           [value]="selectedMortgagee.dataModel.organizationName? selectedMortgagee.dataModel.organizationName: ''"
                           (click)="toggleMortgageeShutter(selectedMortgagee)"
                           (keydown.f9)="toggleMortgageeShutter(selectedMortgagee)"
                           autocomplete="off"/>
                    <p-autoComplete *ngIf="selectedMortgagee.editMode"
                                    inputStyleClass="mortgagee-autocomplete"
                                    id="mortgagee-autocomplete"
                                    placeholder="Search by Address, Name or Add new"
                                    name="mortgagee-autocomplete"
                                    [(ngModel)]="selectedMortgagee.dataModel"
                                    [suggestions]="mortgagees"
                                    [class.autocomplete-searching]="mortgageesLoading"
                                    (completeMethod)="search($event)"
                                    field="organizationName"
                                    [minLength]="0"
                                    [delay]="1000"
                                    maxlength="75"
                                    scrollHeight="350px"
                                    (onSelect)="selectExistingMortgagee(i)"
                                    (click)="toggleMortgageeShutter(selectedMortgagee)"
                                    [disabled]="isOpenedFromWizardBeforeMatterSave()"
                                    autocomplete="off" class="autocomplete-search-icon">
                        <ng-template let-mortgagee pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                [ngClass]="{'bold-font' : isAddNew(mortgagee)}"
                                [disableItem]="mortgagee">
                                <span class="col-lg-3 text-overflow">{{ mortgagee.displayName ? mortgagee.displayName : mortgagee.organizationName }}</span>
                                <span class="col-lg-1 text-overflow">{{ mortgagee.alternateName }}</span>
                                <span class="col-lg-4 text-overflow">{{ mortgagee.serviceAddressTextForDisplayingInSearchResult }}</span>
                                <span class="col-lg-4 no-side-padding">
                                    <span class="col-lg-8 text-overflow no-side-padding">{{ mortgagee.branch}}</span>
                                    <span class="col-lg-4 text-overflow no-side-padding">{{ mortgagee.faxPhone}}</span>
                                </span>

                            </li>
                        </ng-template>
                    </p-autoComplete>
                    <div class="actionbtns">
                        <ng-container *ngIf="!isLawFirmVisible()">
                            <div class="padding-right-20 margin-top-minus-5" *ngIf="selectedMortgagee && !selectedMortgagee.editMode && isMyClientVisible()"
                                 (click)="onPrivateLenderActionsClick($event)">
                                            <span class="primary radio">
                                              <input type="radio" [checked]="selectedMortgagee.myClient"/>
                                              <label (click)="setMortgageeAsMyClient(selectedMortgagee)">My Client</label>
                                            </span>
                            </div>
                            <div class="width-30 global-icon" *ngIf="selectedMortgagee.sourceContactId">
                                                <span *ngIf="selectedMortgagee.isGlobalIconVisible()">
                                                    <i class="fa fa-globe" aria-hidden="true"></i>
                                                </span>
                                <span *ngIf="selectedMortgagee.isAddressBookIconVisible()">
                                                    <i class="fa fa-address-book" aria-hidden="true"></i>
                                                </span>
                            </div>
                            <div class="width-60" *ngIf="selectedMortgagee.sourceContactId">
                                                <span *ngIf="selectedMortgagee.isStale">
                                                    <span class="text-black out-of-sync" title="old"><img
                                                        src="/assets/img/old-icon-18x18.png"/> </span>
                                                </span>
                            </div>

                            <div class="width-30 actionbtns">
                                <dp-burger-menu *ngIf="!selectedMortgagee.editMode && selectedMortgagee.dataModel"
                                                [allowPopUp]="true"
                                                [items]="getBurgerMenuItemsForMatterParticipant(selectedMortgagee)"
                                                [menuSize]="[1.5]"
                                                [openSubMenuOnLeft]="true"
                                                [disabledItems]="selectedMortgagee.getDisabledBurgerMenuItemsForMatterParticipant()"
                                                (itemClicked)="clickMortgageeBurgerMenuItem($event, selectedMortgagee)">
                                </dp-burger-menu>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isLawFirmVisible()">
                            <div class="width-30 actionbtns"
                                 *ngIf="!selectedMortgagee.editMode && selectedMortgagee.dataModel">
                                    <span class="trash-icon">
                                        <i class="fa fa-trash"
                                           (click)="deleteMortgagee(selectedMortgagee)"
                                           aria-hidden="true"
                                           [title]="'Delete ' + provinceBasedFieldLabels.get('provinceBasedMortgagee', matter.provinceCode)"
                                           tabindex="0"
                                           (keyup.enter)="deleteMortgagee(selectedMortgagee)"
                                        >
                                        </i>
                                    </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="(selectedMortgagee.matterParticipant)">
                <div class="col-lg-12 margin-top-10">
                    <ng-container *ngIf="isInstitutionMortgageeSelected && selectedMortgagee.expanded">
                        <ng-container *ngIf="!isLawFirmVisible()">
                            <div class="right margin-bottom-20"
                                 *ngIf="selectedMortgagee.sourceContactId && selectedMortgagee.matterParticipant.sourceContact.isOwnedBySystemAccount
                                     && !selectedMortgagee.isStale && !selectedMortgagee.isLockedElsewhere">
                                    <span>
                                        <a href="javascript:void(0);"
                                           (click)="editMortgageeAsPrivateCopy(selectedMortgagee)">
                                            Edit as Private Copy
                                        </a>
                                    </span>
                            </div>
                            <div class="form-group-warning-message col-lg-10 offset-lg-2"
                                 *ngIf="selectedMortgagee.isLockedElsewhere">
                                <div class="col-lg-1">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                </div>
                                <div class="col-lg-11">
                                    <p>
                                        The mortgagee contact is currently locked by
                                        {{selectedMortgagee.lockedByUserFullName}}.
                                    </p>
                                </div>
                            </div>
                            <div class="form-group-warning-message col-lg-10 offset-lg-2"
                                 *ngIf="selectedMortgagee.isStale && !selectedMortgagee.isLockedElsewhere">
                                <!--Lock message takes precedence over stale message, so if contact is both locked & stale then only lock message will show
                                up-->
                                <div class="col-lg-1">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                </div>
                                <div class="col-lg-11" *ngIf="selectedMortgagee.sourceContactId">
                                    <p>
                                        {{selectedMortgagee.outOfSyncPreMsg}}
                                        <a href="javascript:void(0);"
                                           (click)="openSourceContactTab(selectedMortgagee)" class="line-height-adjust">
                                            {{selectedMortgagee.outOfSyncUnderlineMsg}}</a>
                                        {{selectedMortgagee.outOfSyncPostFixMsg}}
                                    </p>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </ng-container>
                        <dp-mortgage-contact-info
                            [mortgage]="mortgage"
                            [matter]="matter"
                            [isSourceContactLocked]="isSourceContactLocked(selectedMortgagee)"
                            [isSnapshotPrivateLenderGlobalMortgage]="isSnapshotPrivateLenderGlobalMortgage(selectedMortgagee)"
                            [isOutOfSync]="isOutOfSync(selectedMortgagee)"
                            [isStale]="selectedMortgagee.isStale"
                            [matterParticipant]="selectedMortgagee.matterParticipant"
                            [primaryContactType]="'MORTGAGEE'"
                            [sourceContactReadonly]="selectedMortgagee.sourceContactId && selectedMortgagee.isParticipantDataReadOnly"
                            [showMailingAddress]="!isPrivateLenderWithoutSolicitor"
                            [hasPayoutStatement]="payoutStatement && !payoutStatement.isCancelled()"
                            (changed)="changedMortgagee(selectedMortgagee)">
                        </dp-mortgage-contact-info>
                    </ng-container>
                </div>
            </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="form-group" *ngIf="isInstitutionMortgageeSelected && isMortgageeTransitNumberVisibleOnMatter()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" for="branchOrTransitNo">Transit No.</label>
                </div>
                <div class="col-lg-1">
                    <input class="form-control"
                           id="branchOrTransitNo"
                           name="branchOrTransitNo"
                           dp-onlynumeric
                           maxlength="5"
                           [(ngModel)]="mortgage.branchOrTransitNo"
                           (change)="notifyChange()"
                           trimmed
                    />
                </div>
            </div>
            <ng-container *ngIf="!isOpenedFromWizard">
                <div class="form-group col-lg-12" *dpShowByProvince="'matter.mortgage.iscClientNumber'">
                    <label class="col-lg-2 control-label">ISC Client Number</label>
                    <div class="col-lg-4">
                        <input id="iscClientNumberInst" name="iscClientNumberInst" class="form-control"
                               [(ngModel)]="mortgage.iscClientNumber" (change)="notifyChange()" maxlength="25">
                    </div>
                </div>
            </ng-container>
            <div class="form-group col-lg-12" *ngIf="isInstitutionMortgageeSelected && !matter.isOpportunityMatter() && !isOpenedFromWizard">
                <div>
                    <label class="col-lg-2 control-label" dp-province-field-label="matter.mortgage.lendersReLine">Lender's RE: Line</label>
                    <div class="col-lg-10">
                        <input id="lenderReLineInst" name="lenderReLineInst" class="form-control"
                               [(ngModel)]="mortgage.lenderReline" (change)="notifyChange()">
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isInstitutionMortgageeSelected && !matter.isOpportunityMatter() && selectedMortgagees.length > 0 && !isOpenedFromWizard">
                <dp-institution-mortgagee-signing-officers
                    [mortgage]="mortgage"
                    [matter]="matter"
                    [parentParticipant]="selectedMortgagees[0].matterParticipant"
                    (changed)="notifyChange()">
                </dp-institution-mortgagee-signing-officers>
            </ng-container>
            <ng-container *ngIf="isInstitutionMortgageeSelected && !isLawFirmVisible() && !matter.isOpportunityMatter() && !isOpenedFromWizard">
                <dp-attention-info
                    [mortgageId]="mortgage.id"
                    [parentMatterParticipant]="institutionMortgageeMatterParticipant"
                    [matter]="matter"
                    [attentionList]="attentionList"
                    (changed)="notifyChange()">
                </dp-attention-info>
            </ng-container>


            <ng-container
                *ngIf="isInstitutionMortgageeSelected && (matter.isPurchase || matter.isMortgage) && isLawFirmVisible() && isMortgageSolicitorExists()">
                <dp-address-form [showDropdown]="true"
                                 [address]="mortgage.mortgageContactInfo.serviceAddress"
                                 [fieldPrefix]="'mortgage.'+mortgageIndex+'.serviceAddress'"
                                 [addressDd]="solicitorServiceAddressDd"
                                 addressSummaryLabel="Address for<br>Service"
                                 (onChange)="initSolicitorReportAndServiceAddressDd(); enableSave()"></dp-address-form>

                <dp-address-form [showDropdown]="true"
                                 [address]="mortgage.mortgageContactInfo.reportAddress"
                                 [fieldPrefix]="'mortgage.'+mortgageIndex+'.reportAddress'"
                                 [addressDd]="solicitorReportAddressDd"
                                 addressSummaryLabel="Address for<br>Report"
                                 (onChange)="enableSave()"></dp-address-form>
            </ng-container>
        </div>
    </ng-container>


    <div id="privateLenderContainer" *ngIf="isPrivateLenderMortgageeType()">
        <ng-container *ngFor="let matterPrivateLender of matterPrivateLenders; let i=index">
            <div class="form-group col-lg-12" *ngIf="matterPrivateLender.editMode">
                <!--<div class="col-lg-2 control-label">Other Lender {{i + 1}}</div>-->
                <div class="col-lg-2 control-label"></div>
                <div class="col-lg-10">
                    <ng-container *ngIf="!matterPrivateLender.isPrivateLenderMortgagee">
                        <p-autoComplete id="private-lender-autocomplete"
                                        name="private-lender-autocomplete"
                                        placeholder='Search by "Surname, First Name" or Add new'
                                        [(ngModel)]="matterPrivateLender.dataModel"
                                        [suggestions]="privateLenderOptions"
                                        [class.autocomplete-searching]="privateLendersLoading"
                                        (completeMethod)="searchPrivateLenders($event)"
                                        field="name"
                                        [minLength]="0"
                                        [delay]="1000"
                                        maxlength="75"
                                        scrollHeight="350px"
                                        (onSelect)="selectPrivateLender(matterPrivateLender)"
                                        (focusout)="onLeavingPrivateLenderOmnibar(matterPrivateLender)"
                                        autocomplete="off" class="autocomplete-search-icon">
                            <ng-template let-privateLender pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                    [ngClass]="{'bold-font' : isAddNew(privateLender)}"
                                    [disableItem]="privateLender">
                                    <span class="col-lg-5 text-overflow">
                                        {{getPrivateLenderDisplayNameFromContact(privateLender)}}
                                    </span>
                                    <span
                                        class="col-lg-6 text-overflow">{{getContactDisplayMailingAddress(privateLender)}}</span>
                                    <span
                                        class="col-lg-1 text-overflow">{{getDeceasedString(privateLender)}}</span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </ng-container>
                    <ng-container *ngIf="matterPrivateLender.isPrivateLenderMortgagee">
                        <p-autoComplete inputStyleClass="mortgagee-autocomplete"
                                        id="mortgagee-autocomplete"
                                        placeholder="Search by Address, Name or Add new"
                                        name="mortgagee-autocomplete"
                                        [(ngModel)]="matterPrivateLender.dataModel"
                                        [suggestions]="mortgagees"
                                        [class.autocomplete-searching]="mortgageesLoading"
                                        (completeMethod)="search($event)"
                                        field="organizationName"
                                        [minLength]="0"
                                        [delay]="1000"
                                        maxlength="75"
                                        scrollHeight="350px"
                                        (onSelect)="selectPrivateLender(matterPrivateLender)"
                                        (focusout)="onLeavingPrivateLenderOmnibar(matterPrivateLender)"
                                        autocomplete="off" class="autocomplete-search-icon">
                            <ng-template let-mortgagee pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                    [ngClass]="{'bold-font' : isAddNew(mortgagee)}"
                                    [disableItem]="mortgagee">
                                    <span class="col-lg-3 text-overflow">{{ mortgagee.displayName ? mortgagee.displayName : mortgagee.organizationName }}</span>
                                    <span class="col-lg-1 text-overflow">{{ mortgagee.alternateName }}</span>
                                    <span class="col-lg-4 text-overflow">{{ mortgagee.serviceAddressTextForDisplayingInSearchResult }}</span>
                                    <span class="col-lg-4 no-side-padding">
                                    <span class="col-lg-8 text-overflow no-side-padding">{{ mortgagee.branch}}</span>
                                    <span class="col-lg-4 text-overflow no-side-padding">{{ mortgagee.faxPhone}}</span>
                                </span>

                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </ng-container>
                </div>
            </div>

            <div *ngIf="!matterPrivateLender.editMode"
                 [ngClass]="{'shutter-bg': isExpanded(matterPrivateLender),'shutter-bg-closed': !isExpanded(matterPrivateLender)}">
                <!--<div class="form-group">-->
                <div class="form-group">
                    <div class="shutter-arrow" (click)="toggleContactSnapshot(matterPrivateLender)">
                        <div *ngIf="isExpanded(matterPrivateLender)"><img src="/assets/img/minus.svg" alt=""/>
                        </div>
                        <div *ngIf="!isExpanded(matterPrivateLender)"><img
                            src="/assets/img/plus.svg"/></div>
                    </div>
                    <div class="col-lg-2 control-label" (click)="toggleContactSnapshot(matterPrivateLender)">Private
                        Lender {{i + 1}}
                    </div>
                    <div class="col-lg-10 private-lender-header">
                        <input type="text"
                               id="privateLenderIndexId_{{matterPrivateLender?.matterParticipant?.identifier}}"
                               class="form-control private-lender-header-name"
                               readonly="readonly"
                               (click)="toggleContactSnapshot(matterPrivateLender)"
                               (keydown.f9)="toggleContactSnapshot(matterPrivateLender)"
                               [value]="getPrivateLenderName(matterPrivateLender)"
                               fieldKey="matter.privateLender.shutter"
                               statusBarHelp
                               autocomplete="off"/>
                        <div class="private-lender-header-actions">
                            <div class="private-lender-header-primary" *ngIf="!isLawFirmVisible()"
                                 (click)="onPrivateLenderActionsClick($event)">
                                            <span class="primary radio">
                                              <input type="radio" [checked]="matterPrivateLender.primary"/>
                                              <label (click)="setAsPrimaryPrivateLender(matterPrivateLender)">Primary Contact</label>
                                            </span>
                            </div>
                            <div class="private-lender-header-capacity">
                                        <span *ngIf="isCapacityWarningForPrivateLenderVisible(matterPrivateLender)">
                                            <i class="fa fa-warning"
                                               (click)="openCapacitySection(matterPrivateLender)"></i>
                                            <span class="capacity-label">{{capacityTabLabel}}</span>
                                        </span>
                            </div>
                            <div class="private-lender-header-capacity">
                                            <span *ngIf="showIdWarningForParticipant(matterPrivateLender.matterParticipant) && !isExistingMortgage()"
                                                  (click)="openIDVerificationSection(matterPrivateLender)"
                                                  [title]="'Incomplete Client ID Information'">
                                                <i class="fa fa-warning"></i>
                                                <span class="span-link">ID</span>
                                            </span>
                            </div>
                            <div class="private-lender-header-my-client" *ngIf="matterPrivateLender && isMyClientVisible()"
                                 (click)="onPrivateLenderActionsClick($event)">
                                            <span class="primary radio">
                                              <input type="radio" [checked]="matterPrivateLender.myClient"/>
                                              <label (click)="setMortgageeAsMyClient(matterPrivateLender)">My Client</label>
                                            </span>
                            </div>
                            <div class="private-lender-header-stale-flag top-4 right-30">
                                                <span *ngIf="matterPrivateLender.isGlobalIconVisible()">
                                                    <i class="fa fa-globe" aria-hidden="true"></i>
                                                </span>
                                <span *ngIf="matterPrivateLender.isAddressBookIconVisible()">
                                                    <i class="fa fa-address-book" aria-hidden="true"></i>
                                                </span>
                            </div>
                            <div class="private-lender-header-stale-flag">
                                        <span *ngIf="matterPrivateLender.isStale">
                                            <span class="text-black out-of-sync" [title]="'old'" aria-hidden="true">
                                                <img src="/assets/img/old-icon-18x18.png"/>
                                            </span>
                                        </span>
                            </div>
                            <div class="width-30 actionbtns">
                                <dp-burger-menu *ngIf="!!matterPrivateLender.matterParticipant"
                                                [openSubMenuOnLeft]="true"
                                                [allowPopUp]="true"
                                                [items]="getPrivateLenderBurgerMenuItems(i, matterPrivateLender)"
                                                [disabledItems]="getDisabledItems(matterPrivateLender)"
                                                (itemClicked)="clickPrivateLenderBurgerMenu($event, matterPrivateLender)">
                                </dp-burger-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <!--</div>-->
                <!--<div  class="dp-accordion-content">-->
                <div class="form-group" *ngIf="isExpanded(matterPrivateLender)">
                    <div class="right margin-bottom-20"
                         *ngIf="matterPrivateLender.isPrivateLenderMortgagee && matterPrivateLender.matterParticipant.sourceContact
                         && matterPrivateLender.matterParticipant.sourceContact.isOwnedBySystemAccount
                                     && !matterPrivateLender.isStale && !matterPrivateLender.isLockedElsewhere">
                                    <span>
                                        <a href="javascript:void(0);"
                                           (click)="editMortgageeAsPrivateCopy(matterPrivateLender)">
                                            Edit as Private Copy
                                        </a>
                                    </span>
                    </div>
                    <div class="form-group-warning-message padding-top-10 col-lg-10 offset-lg-2" *ngIf="matterPrivateLender.sourceContactIsLocked">
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                The Private Lender is currently locked by {{matterPrivateLender &&
                                matterPrivateLender.lockedSourceContact &&
                                matterPrivateLender.lockedSourceContact.lockedByUser.fullName}}.
                            </p>
                        </div>
                    </div>
                    <div class="form-group-warning-message padding-top-10 col-lg-10 offset-lg-2"
                         *ngIf="isStale(matterPrivateLender)">
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                {{matterPrivateLender.outOfSyncPreMsg}}
                                <a href="javascript:void(0);"
                                   (click)="openSourceContactTab(matterPrivateLender)" class="line-height-adjust">
                                    ({{matterPrivateLender.outOfSyncUnderlineMsg}}</a>
                                {{matterPrivateLender.outOfSyncPostFixMsg}}
                            </p>
                        </div>
                    </div>


                    <div class="padding-top-10 col-lg-10 offset-lg-2">
                        <ul class="dppm-custom-tab">
                            <li [ngClass]="{active: matterPrivateLender.selectedTab == 'Profile'}"
                                (click)="selectTab('Profile', matterPrivateLender)" tabindex="0"
                                (keyDown.enter)="selectTab('Profile', matterPrivateLende)">
                                <a>Profile</a>
                            </li>
                            <li *ngIf="mortgage.mortgageCapacityType === 'OTHER'"
                                        [ngClass]="{active: matterPrivateLender.selectedTab === 'Capacity-Share'}"
                                        (click)="selectTab('Capacity-Share', matterPrivateLender)"
                                        tabindex="0"
                                        (keyDown.enter)="selectTab('Capacity-Share', matterPrivateLender)">
                                        <a dp-province-field-label="matter.purchaser.capacity">Capacity</a>
                                    </li>
                            <li *ngIf="!isExistingMortgage()"
                                [ngClass]="{active: matterPrivateLender.selectedTab === 'ID-Details'}"
                                (click)="selectTab('ID-Details', matterPrivateLender)" tabindex="0"
                                (keyDown.enter)="selectTab('ID-Details', matterPrivateLender)">
                                <a>ID Verification</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-10 offset-lg-2 margin-top-20">
                        <div [ngSwitch]="matterPrivateLender.selectedTab">
                            <ng-container *ngSwitchCase="'Profile'">
                                <dp-private-lender-info [readonly]="snapshotReadOnly(matterPrivateLender)"
                                                        [privateLenderWrapper]="matterPrivateLender"
                                                        [mortgage]="mortgage"
                                                        [matter]="matter"
                                                        [matterSpecificDataReadOnly]="matterPrivateLender.sourceContactIsLocked || isSnapshotPrivateLenderGlobalMortgage(matterPrivateLender)"
                                                        [signingOfficerVisible]="!matterPrivateLender.primary || isLawFirmVisible()"
                                                        [showMailingAddress]="isPrivateLenderWithoutSolicitor"
                                                        [fieldPrefix]="'mortgage.' + mortgageIndex + '.mortgagee.privateLender'"
                                                        fieldIndexId="privateLenderIndexId"
                                                        [fieldIdentifier]="matterPrivateLender?.matterParticipant?.identifier"
                                                        containerId="privateLenderContainer"
                                                        (privateLenderInfoChanged)="notifyChange();updateLenderReLine();"></dp-private-lender-info>
                                <dp-mortgage-contact-info
                                    *ngIf="isPrivateLenderMortgageContactInfoVisible(matterPrivateLender)"
                                    [sourceContactReadonly]="matterPrivateLender.sourceContactIsLocked || isSnapshotPrivateLenderGlobalMortgage(matterPrivateLender)"
                                    [mortgage]="mortgage"
                                    [isPrivateLenderMortgagee]="matterPrivateLender.isPrivateLenderMortgagee"
                                    [matter]="matter"
                                    [matterParticipant]="matterPrivateLender.matterParticipant"
                                    [isStale]="matterPrivateLender.isStale"
                                    [isSourceContactLocked]="isSourceContactLocked(matterPrivateLender)"
                                    [isOutOfSync]="isOutOfSync(matterPrivateLender)"
                                    [isSnapshotPrivateLenderGlobalMortgage]="isSnapshotPrivateLenderGlobalMortgage(matterPrivateLender)"
                                    [primaryContactType]="'PRIVATE_LENDER'"
                                    [showMailingAddress]="!isPrivateLenderWithoutSolicitor"
                                    [contactRecordExists]="matterPrivateLender.matterParticipant?.contact.sourceContactId"
                                    [matterReadonly]="snapshotReadOnly(matterPrivateLender) || isSnapshotPrivateLenderGlobalMortgage(matterPrivateLender)"
                                    [signingOfficerVisible]="matterPrivateLender.matterParticipant?.contact.isCorporationOrOtherEntity"
                                    (changed)="notifyChange();updateLenderReLine();">
                                </dp-mortgage-contact-info>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Capacity-Share'">
                                <dp-capacityShare [matterParticipant]="matterPrivateLender.matterParticipant"
                                                  [readOnly]="capacityReadOnly(matterPrivateLender)"
                                                  (updateCapacity)="notifyChange()"
                                                  [purchaserId]="matterPrivateLender.matterParticipantId"></dp-capacityShare>
                            </ng-container>
                            <ng-container *ngSwitchCase="'ID-Details'">
                                <dp-snapshotIdDetails [participant]="matterPrivateLender.matterParticipant"
                                                      [client]="matterPrivateLender.matterParticipant?.contact"
                                                      [idDetailsEditable]="!snapshotReadOnly(matterPrivateLender)"
                                                      [idVerifiedEnterOnFieldsReadOnly]="snapshotReadOnly(matterPrivateLender)"
                                                      (onDataChange)="notifyChange();updateLenderReLine();"

                                >
                                </dp-snapshotIdDetails>
                            </ng-container>
                        </div>

                    </div>
                </div>
                <!--</div>-->
            </div>

        </ng-container>
        <div class="form-group">
            <div class="col-lg-10 offset-lg-2">
                <ng-container *ngIf="isPrivateLenderVisible()">
                     <span class="private-lender-action">
                            <span>
                                <a href="javascript:void(0);" (click)="addNewPrivateLender('MORTGAGEE')">
                                   {{privateLenderAction}}
                                </a>
                            </span>
                            <span class="selector fa fa-plus-square-o"></span>
                        </span>
                    <span class="margin-left-20"></span>
                    <span class="private-lender-action">
                            <span>
                                <a href="javascript:void(0);" (click)="addNewPrivateLender('CLIENT')">
                                   {{ hasPrivateLenders() ? 'Add another Client' : 'Add from Client' }}
                                </a>
                            </span>
                            <span class="selector fa fa-plus-square-o"></span>
                        </span>
                    <span class="margin-left-20"></span>
                    <ng-container *ngIf="hasPrivateLenders()">
                    <span class="private-lender-action" *dpShowByProvince="'matter.mortgage.titleDetails'"
                          [class.disabled]="matter.getPrivateLenders(mortgage).length == 0">
                          <ng-container *ngIf="!matter.isMatterProvinceBC">
                            <a href="javascript: void(0)" (click)="showPrivateLenderTitleDetails()">
                                Title Details
                            </a>
                            <span class="selector fa fa-eye"></span>
                          </ng-container>
                        </span>
                        <span class="actionlink margin-left-20" *ngIf="!isExistingMortgage() && !matter.isOpportunityMatter()">
                                <span>
                                    <a href="javascript: void(0)" (click)="onClickPrintIdForms()"
                                       >
                                        Produce ID Forms and Declarations
                                    </a>
                                </span>
                            </span>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="mortgage.isMortgageePrivateLender()">
            <div *dpShowByProvince="'matter.mortgage.titleDetailsSection'" class="padding-top-5">
                <label class="col-lg-2 control-label">Title Details</label>
                <div class="col-lg-2">
                    <select class="form-control" id="titleDetailsType" name="titleDetailsType"
                            [(ngModel)]="mortgage.mortgageContactInfo.titleDetailsType"
                            (ngModelChange)="onTitleDetailsChange()">
                        <option *ngFor="let option of titleDetailsTypeOptions" [value]="option.value">{{ option.label
                            }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-8">
                        <textarea name="titleDetailsDescriptionAuto"
                                  id="titleDetailsDescriptionAuto"
                                  rows="2" cols="80"
                                  [ngModel]="matter.getPrivateLendersTitleDetails(mortgage)"
                                  [dp-read-only]="true"
                                  *ngIf="!mortgage.mortgageContactInfo.isTitleDetailsManual()"
                        >

                        </textarea>

                    <textarea class="form-control"
                              id="titleDetailsDescription"
                              name="titleDetailsDescription"
                              *ngIf="mortgage.mortgageContactInfo.isTitleDetailsManual()"
                              rows="2" cols="80"
                              [(ngModel)]="mortgage.mortgageContactInfo.titleDetailsDescription"
                              (ngModelChange)="notifyChange()">
                        </textarea>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <ng-container *dpShowByProvince="'matter.mortgage.iscClientNumber'">
            <div class="form-group col-lg-12">
                <label class="col-lg-2 control-label">ISC Client Number</label>
                <div class="col-lg-4">
                    <input id="iscClientNumber" name="iscClientNumber" class="form-control"
                           [(ngModel)]="mortgage.iscClientNumber" (change)="notifyChange()" maxlength="25">
                </div>
            </div>
        </ng-container>
        <div class="form-group col-lg-12" *ngIf="anyPrivateLender() && !matter.isOpportunityMatter()">
            <div>
                <label class="col-lg-2 control-label" dp-province-field-label="matter.mortgage.lendersReLine">Lender's RE: Line</label>
                <div class="col-lg-10">
                    <input id="lenderReline" name="lenderReline" class="form-control"
                           [(ngModel)]="mortgage.lenderReline" maxlength="200" (change)="notifyChange()">
                </div>
            </div>
        </div>
    </div>

    <ng-container
        *ngIf="(matter.isPurchase || matter.isMortgage) && isPrivateLenderMortgageeType() && isCorrespondWithSolicitor()">
        <dp-address-form [showDropdown]="true"
                         [address]="mortgage.mortgageContactInfo.serviceAddress"
                         [fieldPrefix]="'mortgage.'+mortgageIndex+'.serviceAddress'"
                         [addressDd]="solicitorServiceAddressDd"
                         addressSummaryLabel="Address for<br>Service"
                         (onChange)="initSolicitorReportAndServiceAddressDd(); enableSave()"></dp-address-form>

        <dp-address-form [showDropdown]="true"
                         [address]="mortgage.mortgageContactInfo.reportAddress"
                         [fieldPrefix]="'mortgage.'+mortgageIndex+'.reportAddress'"
                         [addressDd]="solicitorReportAddressDd"
                         addressSummaryLabel="Address for<br>Report"
                         (onChange)="enableSave()"></dp-address-form>
    </ng-container>

    <!-- //END - Snapshot -->
    <ng-container *dpShowByProvince="'matter.mortgage.mortgagee.tenure'">
        <div class="form-group col-lg-12"
             *ngIf="shouldShowCapacity()">
            <label class="col-lg-2 control-label" for="mortgageCapacityType"
                   [dp-province-field-label]="'matter.mortgage.mortgagee.provinceBasedCapacityTitle'">Capacity</label>
            <div class="col-lg-10">
                <select class="form-control" id="mortgageCapacityType" name="mortgageCapacityType"
                        [(ngModel)]="mortgage.mortgageCapacityType"
                        (change)="onMortgageCapacityChange($event.target.value)">
                    <option *ngFor="let option of mortgageCapacityOptions" [value]="option.value">{{ option.label }}
                    </option>
                </select>
            </div>
        </div>
    </ng-container>
</div>
    <!--mortgagee and Solicitor/Law firm should put in different fields-group-->
    <!-- Solicitor and Law firm should be put in one ngIf-->
    <div class="fields-group col-lg-12 margin-top-0" *ngIf="isLawFirmVisible()">
        <!-- Solicitor -->
        <div class="form-group">
            <div class="main-level">
                <span class="title" dp-province-field-label="matter.mortgage.solicitorsInformation">Solicitor's Information </span>
            </div>
            <div class="form-group col-lg-12" *ngIf="isSelectedSolicitorInEditMode">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" dp-province-field-label="matter.mortgage.solicitor.solicitorsName">Solicitor's Name</label>
                </div>
                <div class="col-lg-10">
                    <p-autoComplete
                        [delay]="1000"
                        #solicitorAutoComplete
                        [placeholder]="solicitorNameHelpText"
                        [(ngModel)]="selectedSolicitor.dataModel"
                        [class.autocomplete-searching]="solicitorsLoading"
                        (onSelect)="dataSelectedSolicitor()"
                        (focusout)="onLeavingSolicitorOmniBarWithoutFreeInput(selectedSolicitor)"
                        [suggestions]="solicitors"
                        [class.dropdown-arrow-refferedBy]="true"
                        [dropdown]="showSolicitorDropDownArrow"
                        (keydown.space)="utils.handleSpaceKeyDropdown($event, selectedSolicitor.dataModel, handleDropDownClickSolicitorPickList)"
                        (keydown.enter)="utils.handleEnterKeyDropdown($event, selectedSolicitor.dataModel, handleDropDownClickSolicitorPickList)"
                        (completeMethod)="searchSolicitor($event)"
                        field="surnameLastFullName"
                        [minLength]="0"
                        scrollHeight="530px"
                        tabIndexIgnore
                        (onDropdownClick)="handleDropDownClickSolicitorPickList($event)"
                        [ngClass]="{'autocomplete-search-icon': !showSolicitorDropDownArrow}"
                    >
                        <ng-template let-solicitor pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                [ngClass]="{'bold-font' : isAddNew(solicitor)}"
                                [disableItem]="solicitor">
                                <div *ngIf="solicitor.instanceCreationTime">
                                    <span class="col-lg-3 text-overflow">{{solicitor.fullName}}</span>
                                    <span class="col-lg-2 text-overflow">{{solicitor.firmName}}</span>
                                    <span class="col-lg-5 text-overflow">{{solicitor.mailingAddress?.addressTextWithProvinceName}}</span>
                                </div>
                                <div *ngIf="!solicitor.instanceCreationTime">
                                    <span class="col-lg-6 text-overflow">{{solicitor.displayName}}</span>
                                </div>
                                <span class="col-lg-2 text-overflow">{{solicitor.contactName?.initials}}</span>
                            </li>
                        </ng-template>
                    </p-autoComplete>

                </div>
            </div>
            <div *ngIf="!isSelectedSolicitorInEditMode"
                 [ngClass]="{'shutter-bg': isExpanded(selectedSolicitor),'shutter-bg-closed': !isExpanded(selectedSolicitor)}">
                <!--<div class="form-group">-->
                <div class="form-group">
                    <div class="shutter-arrow left-7" (click)="toggleSolicitorContactSnapshot(selectedSolicitor)">
                        <div *ngIf="isExpanded(selectedSolicitor)"><img src="/assets/img/minus.svg" alt=""/>
                        </div>
                        <div *ngIf="!isExpanded(selectedSolicitor)"><img
                            src="/assets/img/plus.svg"/></div>
                    </div>
                    <div>
                        <span class="col-lg-2 control-label"
                              (click)="toggleSolicitorContactSnapshot(selectedSolicitor)"
                              dp-province-field-label="matter.mortgage.solicitor.solicitorsName">
                            Solicitor's Name
                        </span>
                    </div>
                    <div>
                        <div class="col-lg-10 client-purchaser fullLength-autocomplete">
                            <input type="text"
                                   id="lawFirm_{{selectedSolicitor?.matterParticipant?.identifier}}"
                                   class="form-control rightPadding482"
                                   readonly="readonly"
                                   (click)="toggleSolicitorContactSnapshot(selectedSolicitor)"
                                   (keydown.f9)="toggleSolicitorContactSnapshot(selectedSolicitor)"
                                   [value]="getSolicitorName(selectedSolicitor)"
                                   fieldKey="matter.mortgage.solicitor.shutter"
                                   statusBarHelp
                                   autocomplete="off"/>
                            <div class="actionbtns">
                                <div class="width-60">
                                        <span *ngIf="showGlobalWindow(selectedSolicitor)">
                                            <i class="fa fa-globe padding-top-5" aria-hidden="true"></i>
                                        </span>
                                    <span
                                        *ngIf="showAddressBookWindow(selectedSolicitor)">
                                            <i class="fa fa-address-book padding-top-5" aria-hidden="true"></i>
                                        </span>
                                </div>
                                <div class="width-60">
                                        <span *ngIf="selectedSolicitor.isStale">
                                            <span class="text-black padding-top-5" [title]="'old'">
                                                <img src="/assets/img/old-icon-18x18.png"/>
                                            </span>
                                        </span>
                                </div>
                                <div class="width-30 actionbtns">
                                    <dp-burger-menu *ngIf="!!selectedSolicitor.matterParticipant"
                                                    [openSubMenuOnLeft]="true"
                                                    [allowPopUp]="true"
                                                    [items]="getFirmSolicitorBurgerMenuItems(selectedSolicitor)"
                                                    [disabledItems]="getFirmSolicitorDisabledItems(selectedSolicitor)"
                                                    (itemClicked)="clickFirmSolicitorBurgerMenu($event, selectedSolicitor)">
                                    </dp-burger-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--</div>-->
                <!--<div  class="dp-accordion-content">-->
                <div *ngIf="isVisibleSolicitorSnapshot(selectedSolicitor)">
                    <div class="right margin-bottom-20 offset-lg-10"
                         *ngIf="!isMyOwnAccountSolicitor() && selectedSolicitor.isOwnedBySystemAccount">
                            <span *ngIf="!isLocked">
                                <a href="javascript:void(0);"
                                   (click)="editAsPrivateCopyAction(selectedSolicitor)">
                                    Edit as Private Copy
                                </a>
                            </span>
                        <span *ngIf="isLocked">Edit as Private Copy</span>
                    </div>
                    <div class="form-group-warning-message padding-top-10 col-lg-10 offset-lg-2"
                         *ngIf="selectedSolicitor.sourceContactIsLocked">
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                The Solicitor is currently locked by {{selectedSolicitor &&
                                selectedSolicitor.lockedSourceContact &&
                                selectedSolicitor.lockedSourceContact.lockedByUser.fullName}}.
                            </p>
                        </div>
                    </div>
                    <div class="form-group-warning-message padding-top-10 col-lg-10 offset-lg-2"
                         *ngIf="isStale(selectedSolicitor)">
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                {{selectedSolicitor.outOfSyncPreMsg}}
                                <a href="javascript:void(0);"
                                   (click)="openSourceSolicitorTab()" class="line-height-adjust">
                                    {{selectedSolicitor.outOfSyncUnderlineMsg}}</a>
                                {{selectedSolicitor.outOfSyncPostFixMsg}}
                            </p>
                        </div>
                    </div>
                    <div>
                        <dp-solicitor-snapshot [readonly]="solicitorSnapshotReadOnly"
                                               [fieldPrefix]="'matter.mortgage'"
                                               [solicitorAddressDd]="solicitorAddressDdOptions"
                                               [solicitor]="selectedSolicitor?.matterParticipant?.contact"
                                               (solicitorInfoChanged)="initSolicitorReportAndServiceAddressDd(); notifyChange()"
                                               (contactChangedForEvents)="handleSolicitorChange(selectedSolicitor)"
                        >
                        </dp-solicitor-snapshot>
                    </div>

                </div>
                <!--</div>-->
            </div>

        </div>

        <!-- Law firm -->
        <div class="form-group">
            <div class="form-group" *ngIf="selectedLawFirm && selectedLawFirm.editMode">
                <!--<div class="form-group" *ngIf="!selectedLawFirm.editMode">-->
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label" dp-province-field-label="matter.mortgage.solicitor.lawFirmName">Law Firm Name</label>
                </div>
                <div class="col-lg-10">
                    <p-autoComplete inputStyleClass="law-firm-autocomplete"
                                    maxlength="200"
                                    delay="1000"
                                    placeholder="Search by Name, Address or Add new"
                                    [suggestions]="lawFirms"
                                    [class.autocomplete-searching]="lawFirmsLoading"
                                    (completeMethod)="searchLawFirm($event)"
                                    field="legalFirmName"
                                    (onSelect)="dataSelectedLawFirmTimeout()"
                                    [(ngModel)]="selectedLawFirm.dataModel"
                                    (focusout)="onLeavingLawFirmOmniBarWithoutFreeInput(selectedLawFirm)"
                                    (keydown)="recordLawFirmKeydownCode($event)"
                                    scrollHeight="530px"
                                    [ngClass]="{'autocomplete-search-icon': !selectedLawFirm.dataModel}">

                        <ng-template let-lawfirm pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                [ngClass]="{'bold-font' : isAddNew(lawfirm)}"
                                [disableItem]="lawfirm">
                                <div *ngIf="lawfirm.instanceCreationTime">
                                    <span class="col-lg-6 text-overflow">{{lawfirm.legalFirmName}}</span>
                                    <span class="col-lg-6 text-overflow">{{lawfirm.mailingAddress?.addressTextWithProvinceName}}</span>
                                </div>
                                <div *ngIf="!lawfirm.instanceCreationTime">
                                    <span class="col-lg-6 text-overflow">{{lawfirm.displayName}}</span>
                                </div>
                            </li>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>
            <div *ngIf="selectedLawFirm && !selectedLawFirm.editMode"
                 [ngClass]="{'shutter-bg': (mortgageSolicitor == null) && isExpanded(selectedLawFirm),'shutter-bg-closed': (mortgageSolicitor == null) && !isExpanded(selectedLawFirm)}">
                <div class="form-group">
                    <div class="shutter-arrow" (click)="toggleLawFirmContactSnapshot()">
                        <div *ngIf="isExpanded(selectedLawFirm)"><img src="/assets/img/minus.svg" alt=""/></div>
                        <div *ngIf="!isExpanded(selectedLawFirm)"><img
                            src="/assets/img/plus.svg"/></div>
                    </div>
                    <div>
                        <span class="col-lg-2 control-label" (click)="toggleLawFirmContactSnapshot()">
                            Firm Name
                        </span>
                    </div>
                    <div>
                        <div class="col-lg-10 client-purchaser fullLength-autocomplete">
                            <input type="text"
                                   id="lawFirm_{{selectedLawFirm?.matterParticipant?.identifier}}"
                                   class="form-control rightPadding482"
                                   readonly="readonly"
                                   (click)="toggleLawFirmContactSnapshot()"
                                   (keydown.f9)="toggleLawFirmContactSnapshot()"
                                   [value]="getLawFirmName(selectedLawFirm)"
                                   fieldKey="matter.mortgage.LawFirm.shutter"
                                   statusBarHelp
                                   autocomplete="off"/>
                            <div class="actionbtns">
                                <div class="width-60">
                                        <span *ngIf="showGlobalWindow(selectedLawFirm)">
                                            <i class="fa fa-globe padding-top-5" aria-hidden="true"></i>
                                        </span>
                                    <span
                                        *ngIf="showAddressBookWindow(selectedLawFirm)">
                                            <i class="fa fa-address-book padding-top-5" aria-hidden="true"></i>
                                        </span>
                                </div>

                                <div class="width-60">
                                            <span *ngIf="selectedLawFirm.isStale">
                                                <!--<i class="fa fa-flag" aria-hidden="true">-->
                                                    <span class="text-black padding-top-5" title="old"><img
                                                        src="/assets/img/old-icon-18x18.png"/> </span>
                                            </span>
                                </div>
                                <div class="width-30 actionbtns">
                                    <dp-burger-menu *ngIf="!!selectedLawFirm.matterParticipant"
                                                    [openSubMenuOnLeft]="true"
                                                    [allowPopUp]="true"
                                                    [items]="getFirmSolicitorBurgerMenuItems(selectedLawFirm)"
                                                    [disabledItems]="getFirmSolicitorDisabledItems(selectedLawFirm)"
                                                    (itemClicked)="clickFirmSolicitorBurgerMenu($event, selectedLawFirm, true)">
                                    </dp-burger-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="showLawFirmSnapshot(selectedLawFirm)">
                    <div class="right margin-bottom-20 offset-lg-10"
                         *ngIf="!isMyOwnAccountLawFirm() && selectedLawFirm.isOwnedBySystemAccount">
                            <span *ngIf="!isLocked">
                                <a href="javascript:void(0);"
                                   (click)="editAsPrivateCopyAction(selectedLawFirm)">
                                    Edit as Private Copy
                                </a>
                            </span>
                        <span *ngIf="isLocked">Edit as Private Copy</span>
                    </div>
                    <div class="form-group-warning-message offset-lg-2"
                         *ngIf="selectedLawFirm.sourceContactIsLocked">
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                The Law Firm is currently locked by {{selectedLawFirm &&
                                selectedLawFirm.lockedSourceContact &&
                                selectedLawFirm.lockedSourceContact.lockedByUser.fullName}}.
                            </p>
                        </div>
                    </div>
                    <div class="form-group-warning-message offset-lg-2"
                         *ngIf="isStale(selectedLawFirm)">
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                {{selectedLawFirm.outOfSyncPreMsg}}
                                <a href="javascript:void(0);"
                                   (click)="openSourceContactTab(selectedLawFirm)" class="line-height-adjust">
                                    ({{selectedLawFirm.outOfSyncUnderlineMsg}}</a>
                                {{selectedLawFirm.outOfSyncPostFixMsg}}
                            </p>
                        </div>
                    </div>
                    <div>
                        <dp-law-firm-snapshot [readonly]="lawFormSnapshotReadOnly"
                                              [showAllLawFirmSnapshotFields]="showAllLawFirmSnapshotFields(selectedLawFirm)"
                                              [fieldPrefix]="'matter.otherParty'"
                                              [lawFirm]="selectedLawFirm?.matterParticipant?.contact"
                                              (lawFirmInfoChanged)="notifyChange()">
                        </dp-law-firm-snapshot>
                    </div>
                </div>
            </div>
        </div>
        <!-- //END - mortgage -->
    </div>

<div *ngIf="!mortgage.isExistingMortgage() && !matter.isOpportunityMatter() && !isOpenedFromWizard" class="col-lg-12">
    <dp-mortgage-broker-info [matter]="matter" [mortgage]="mortgage"></dp-mortgage-broker-info>
</div>
