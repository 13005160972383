<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Appointment - {{getModalTitle()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="col-lg-12 padding-top-30">
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Status</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="status"
                            name="status"
                            class="form-control"
                            (ngModelChange)="markDirty()"
                            [(ngModel)]="eventData.eventStatus">
                        <option *ngFor="let status of eventStatusOptions" [value]="status.value">
                            {{status.label}}
                        </option>
                    </select>
                </div>
                <ng-container *ngIf="isMatterEvent()">
                    <div class="col-lg-3 vertical-align-label">
                        <label class="control-label">Document Status</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="eventDocsStatus"
                                name="eventDocsStatus"
                                class="form-control"
                                fieldKey="customEvent.eventData.eventDocsStatus"
                                dp-error-validator
                                (change)="markDirty()"
                                [(ngModel)]="eventData.eventDocsStatus">
                            <option *ngFor="let docsStatus of eventDocsStatusOptions" [value]="docsStatus.value">
                                {{docsStatus.label}}
                            </option>
                        </select>
                    </div>
                </ng-container>
            </div>
            <div
                [ngClass]="{'shutter-bg': isAttendeesShutterExpanded && isMatterEvent(), 'shutter-bg-closed': !isAttendeesShutterExpanded && isMatterEvent()}">
                <div *ngIf="isMatterEvent()" class="shutter-arrow preventDoubleClick" (click)="toggleAttendeesShutter()">
                    <div *ngIf="isAttendeesShutterExpanded">
                        <img src="/assets/img/minus.svg"/>
                    </div>
                    <div *ngIf="!isAttendeesShutterExpanded">
                        <img src="/assets/img/plus.svg"/>
                    </div>
                </div>
                <div class="form-group" *ngIf="isMatterEvent()">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Attendees</label>
                    </div>
                    <div class="col-lg-9">
                        <input class="form-control"
                               [dp-read-only]="true"
                               [value]="getAttendeesText()"
                        />
                    </div>
                </div>
                <div class="form-group" *ngIf="isAttendeesShutterExpanded">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Scheduled For</label>
                    </div>
                    <div class="col-lg-3">
                        <select type="text"
                                id="scheduledFor"
                                name="scheduledFor"
                                class="form-control"
                                fieldKey="customEvent.eventData.scheduledFor"
                                dp-error-validator
                                (change)="scheduledForChanged()"
                                [disabled]="isEnvelopeSent"
                                [(ngModel)]="eventData.scheduledForSolicitorId">
                            <option *ngFor="let scheduledFor of context.scheduledForList" [value]="scheduledFor.value">
                                {{scheduledFor.label}}
                            </option>
                        </select>
                    </div>

                </div>
                <div class="form-group" *ngIf="isAttendeesShutterExpanded">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Invited Staff</label>
                    </div>
                    <div class="col-lg-9">
                        <div fieldKey="customEvent.eventData.staff.invitees"  dp-error-validator>
                            <dp-multi-select [multiSelectData]="staffInvitees"
                                             [disabled]="isEnvelopeSent || isEnvelopeStatusCompleted"
                                             [showLabelInTitle]="true"
                                             [inputLabel]="' '"
                                             [inputName]="'Invited Staff'"
                                             [title]="'No staff selected'"
                                             [multiSelectedTitle]="selectedStaffListTitle"
                                             #multiSelectStaffBox
                                             [defaultToAll]="false"
                                             [multiSelectDataSelected]="selectedStaffInviteesIds"
                                             (updateMultiSelect)="updateEventStaffInviteeSelection($event)"
                                             class="invitee-multi-select">
                            </dp-multi-select>
                        </div>

                    </div>
                </div>
                <div class="form-group" *ngIf="isAttendeesShutterExpanded && isMatterEvent()">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Invited Parties</label>
                    </div>
                    <div class="col-lg-9">
                        <div fieldKey="customEvent.eventData.invitees"  dp-error-validator>
                            <dp-multi-select [multiSelectData]="eventInvitees"
                                             [disabled]="isEnvelopeSent || isEnvelopeStatusCompleted"
                                             [showLabelInTitle]="true"
                                             [inputLabel]="' '"
                                             [inputName]="'Invited Parties'"
                                             [title]="'No party selected'"
                                             [multiSelectedTitle]="selectedParticipantsList"
                                             #multiSelectBox
                                             [defaultToAll]="false"
                                             [multiSelectDataSelected]="selectedMeetingParticipantsIds"
                                             (updateMultiSelect)="updateEventInviteeSelection($event)"
                                             class="invitee-multi-select">
                            </dp-multi-select>
                        </div>

                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Subject</label>
                </div>
                <div class="col-lg-9">
                    <input class="form-control"
                           maxlength="250"
                           [(ngModel)]="eventData.eventDescription"
                           (ngModelChange)="markDirty()"
                           fieldKey="customEvent.eventData.eventDescription"
                           dp-error-validator/>
                </div>
            </div>
            <div class="form-group margin-left-1 margin-bottom-0">
                <dp-date-time-picker
                    [startDate]="eventData.startDate"
                    [endDate]="eventData.endDate"
                    [startTime]="eventData.startTime"
                    [endTime]="eventData.endTime"
                    [allDayEvent]="eventData.allDayEvent"
                    [fieldKey]="'customEvent.eventData'"
                    [allowMultiDays]="isMultipleDateAvailable()"
                    (dateTimeChange)="dateTimeChange($event)">
                </dp-date-time-picker>

            </div>
            <div class="form-group padding-bottom-15" *ngIf="eventData && eventData.scheduledForSolicitorId && eventData.startDate && eventData.endTime && !eventData.allDayEvent">
                <div class="col-lg-2">
                </div>
                <div class="col-lg-10 hyperlink-display">
                <a (click)="openSchedulingAssistant()" title="Open Scheduling Assistant">Scheduling Assistant</a>
                </div>
            </div>
            <div class="form-group" *ngIf="isStaffEvent()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Show As</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="showAs"
                            name="showAs"
                            class="form-control"
                            [(ngModel)]="eventData.showStaffAvailabilityAs"
                            (change)="markDirty();">
                        <option *ngFor="let showAs of staffAvailabilityOptions" [value]="showAs.value">
                            {{showAs.label}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group" *ngIf="isDigitalSigningAvailable()">
                <div class="col-lg-3 offset-lg-2">
                    <dp-checkbox fieldId="signWithDocuSign">
                        <input id="signWithDocuSign"
                               name="signWithDocuSign"
                               [disabled]="isThirdPartyEnvelopeId()"
                               type="checkbox"
                               [(ngModel)]="eventData.signingDigitally"
                               (change)="updateEnvelope($event);markDirty();"
                        />
                    </dp-checkbox>
                    <label class="control-label padding-left-10 display-inline" for="signWithDocuSign">Sign with {{signingPlatformLabel}}</label>
                </div>
                <ng-container *ngIf="isDigitalSignPlatformDocuSign()">
                    <div class="col-lg-3"
                         *ngIf="isEnvelopeCreated()">
                        <a href="javascript:void(0);" (click)="openDocuSignPortal();">
                            Open DocuSign Envelope
                        </a>
                    </div>
                    <div class="col-lg-3"
                         *ngIf="eventData && eventData.signingEnvelope && (eventData.signingEnvelope.isEnvelopeSent() || eventData.signingEnvelope.isEnvelopeCompleted()  || eventData.signingEnvelope.isEnvelopeFailed())">
                        <label class="control-label padding-top-0">DocuSign Envelope </label>
                        <label class="control-label padding-top-0 padding-left-3">{{eventData.signingEnvelope.envelopeStatus | titlecase}}</label>
                    </div>
                </ng-container>
                <ng-container *ngIf="isDigitalSignPlatformSyngrafii()">
                    <div class="col-lg-6" *ngIf="isSyngrafiiStatusVisible()">
                        <ng-container *ngIf="isEnvelopeCreated()">
                            <button class="dp-btn dp-btn-white margin-right-20"
                                [disabled]="isEnvelopeSent || isEnvelopeStatusCompleted"
                                (click)="openSyngrafiiPortal()">
                                Open Syngrafii package
                            </button>
                        </ng-container>
                        <button class="dp-btn dp-btn-white"
                                [disabled]="isEnvelopeSent || isEnvelopeStatusCompleted"
                                (click)="sendSyngrafiiPackageToRecipients()">
                            {{sendSyngrafiiBtnText}}
                        </button>
                    </div>
                </ng-container>
            </div>

            <div class="form-group" *ngIf="!!eventData.signingDigitally && isSyngrafii() && isShowSyngrafiiCredentials() && isDigitalSignPlatformSyngrafii()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Syngrafii Credential</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="syngrafiiCredential"
                            name="syngrafiiCredential"
                            class="form-control"
                            fieldKey="customEvent.eventData.accountSyngrafiiCredential"
                            dp-error-validator
                            (change)="onSyngrafiiCredentialChange()"
                            [dp-read-only]="isThirdPartyEnvelopeId()"
                            [(ngModel)]="eventData.virtualSigningAccountIntegrationId"
                    >
                        <option *ngFor="let credential of syngrafiiCredentialOptions" [value]="credential.value">
                            {{credential.label}}
                        </option>
                    </select>
                </div>
            </div>

            <ng-container *ngIf="!!eventData.signingDigitally && isDigitalSignPlatformSyngrafii()">
                <div class="form-group">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Type of Syngrafii Appointment</label>
                    </div>
                    <div class="radio col-lg-4">
                        <span class="radio display-inline" (click)="updatePackageType('VIDEO_SIGNING_ROOM')">
                              <input type="radio" [checked]="isPackageTypeVirtualSigningRoom()"
                                     [disabled]="isThirdPartyEnvelopeId()">
                              <label>Virtual Signing Room</label>
                        </span>
                         <span class="radio display-inline padding-left-10" (click)="updatePackageType('SEQUENTIAL')">
                              <input type="radio" [checked]="isPackageTypeSequential()"
                                                  [disabled]="isThirdPartyEnvelopeId()">
                              <label>Sequential</label>
                         </span>
                    </div>
                </div>
            </ng-container>

            <div class="padding-bottom-10" *ngIf="!!eventData.signingDigitally">
                <dp-accordion [expanded]="documentAccordion">
                    <div class="dp-accordion-header">
                        <div class="row  row-group">
                            <label class="control-label col-lg-3 margin-left-40 text-left">Documents</label>
                        </div>
                    </div>
                    <div class="dp-accordion-content">
                        <div class="form-group" *ngIf="getDocuSignDocList().length > 0">
                            <div class="margin-left-45 margin-bottom-10"><span><i class="fa fa-folder-open padding-top-10 font-16 padding-right-5"></i></span>
                                <label class="control-label">
                                    {{signingPackageLabel}} Documents
                                </label></div>
                            <table class="docusign-table">
                                <thead class="boldfont">
                                <th></th>
                                <th>File Name</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th></th>
                                </thead>
                                <tbody>
                                <ng-container *ngFor="let document of getDocuSignDocList()">
                                    <tr>
                                        <td>
                                            <img *ngIf="document.isWord" src="assets/img/word.jpg"
                                                 alt="word Icon"
                                                 class="word-icon"/>
                                            <img *ngIf="document.isPdf" src="assets/img/pdf.png" alt="pdf Icon"
                                                 class="pdf-icon"/>
                                        </td>
                                        <td>{{document.documentName}}</td>
                                        <td>{{document.description}}</td>
                                        <td><i
                                            *ngIf="getEnvelopeDocument(document.id)">{{getEnvelopeDocument(document.id).sentTimeStamp | date:'mediumDate'}}
                                            , {{getEnvelopeDocument(document.id).sentTimeStamp | date:'shortTime'}}</i>
                                        </td>
                                        <td>
                                            <i class="fa fa-trash padding-right-10" title="Remove Document from {{signingPackageLabel}}" (click)="removeEnvelopeDocument(document.id)" ></i>
                                            <ng-container *ngIf="isDocNewVersionAvailable(document.id)">
                                                <label class="control-label padding-top-0">
                                                    <i class="fa fa-warning text-warning" title="Updated version of this document is available"></i>
                                                    <button type="button" class="dp-btn-white margin-left-10"
                                                            (click)="updateDocuSignEnvelope(document.id)">
                                                        <span>Replace with Latest Version</span>
                                                    </button>
                                                </label>
                                            </ng-container>
                                            <ng-container *ngIf="getEnvelopeDocument(document.id) && getEnvelopeDocument(document.id).isDocumentUpdated()">
                                                <i class="fa fa-info-circle font-14"></i>
                                                <label class="control-label padding-top-0 padding-left-5">
                                                     Replaced with latest document
                                                </label>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group" [hidden]="eventData && eventData.signingEnvelope && (eventData.signingEnvelope.isEnvelopeSent() || eventData.signingEnvelope.isEnvelopeCompleted()  || eventData.signingEnvelope.isEnvelopeFailed())">
                            <div class="margin-left-45"><span><i class="fas fa-file-plus padding-top-10 font-16 padding-right-5"></i></span>
                                <label class="control-label text-left">
                                    Select Documents to add to {{signingPackageLabel}}
                                </label></div>
                            <div class="margin-left-25">


                                <dp-matter-documents-for-this-matter [isMaterOpening]="true"
                                                                     [documentsViewOption]="context.documentViewMode"
                                                                     [treeModel]="context.treeModel"
                                                                     [signingEnvelope]="eventData.signingEnvelope"
                                                                     [customEventMatter]="context.openFromEventList ? context.matter : undefined"
                                                                     #documentsForThisMatterComponent
                                                                     (docAvailable)="updateSelectedDocumentsForThisMatter()"
                                ></dp-matter-documents-for-this-matter>
                            </div>
                        </div>
                    </div>
                </dp-accordion>
            </div>

            <div class="form-group" *ngIf="!isPackageTypeVirtualSigningRoom()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Location</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="eventLocation"
                            name="eventLocation"
                            class="form-control"
                            fieldKey="customEvent.eventData.eventLocation"
                            dp-error-validator
                            [dp-read-only]="eventData.isMeetingCreated()"
                            (ngModelChange)="updateMeetingLink($event)"
                            [(ngModel)]="eventData.eventLocation"
                    >
                        <option *ngFor="let location of eventLocationOptions" [value]="location.value">
                            {{location.label}}
                        </option>
                    </select>
                </div>

                <ng-container *ngIf="eventData.isZoomMeeting() && !isShowZoomCredentials()">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Zoom Account</label>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && zoomAccountOptions.length" class="col-lg-4">
                        <select type="text"
                                id="zoomAccount"
                                name="zoomAccount"
                                class="form-control"
                                fieldKey="customEvent.eventData.zoomUserId"
                                dp-error-validator
                                (ngModelChange)="setUserName($event, eventData.eventLocation)"
                                [(ngModel)]="eventData.externalUserId"
                        >
                            <option *ngFor="let account of zoomAccountOptions" [value]="account.value">
                                {{account.label}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && !zoomAccountOptions.length" class="col-lg-4">
                        <input class="form-control accounts-list"
                               [value]="'Loading...'"
                        />
                    </div>

                    <div *ngIf="eventData.isMeetingCreated()" class="col-lg-4">
                        <input class="form-control"
                               [value]="this.eventData.externalUserName"
                               [dp-read-only]="true"
                        />
                    </div>
                </ng-container>
                <ng-container *ngIf="eventData.isMSTeamsMeeting()">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">MS Teams Account</label>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && msTeamsAccountOptions.length" class="col-lg-4">
                        <select type="text"
                                id="msAccount"
                                name="msAccount"
                                class="form-control"
                                fieldKey="customEvent.eventData.msUserId"
                                dp-error-validator
                                [(ngModel)]="eventData.externalUserId"
                                (ngModelChange)="setUserName($event, eventData.eventLocation)"
                        >
                            <option *ngFor="let account of msTeamsAccountOptions" [value]="account.value">
                                {{account.label}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && !msTeamsAccountOptions.length" class="col-lg-4">
                        <input class="form-control accounts-list"
                               [value]="'Loading...'"
                        />
                    </div>
                    <div *ngIf="eventData.isMeetingCreated()" class="col-lg-4">
                        <input class="form-control"
                               [value]="this.eventData.externalUserName"
                               [dp-read-only]="true"
                        />
                    </div>
                </ng-container>
                <ng-container *ngIf="eventData.isGoogleMeeting()">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Google Meet Account</label>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && googleAccountOptions.length" class="col-lg-4">
                        <select type="text"
                                id="googleAccount"
                                name="googleAccount"
                                class="form-control"
                                fieldKey="customEvent.eventData.googleAccount"
                                dp-error-validator
                                (ngModelChange)="setUserName($event, eventData.eventLocation)"
                                [(ngModel)]="eventData.externalUserId"
                        >
                            <option *ngFor="let account of googleAccountOptions" [value]="account.value">
                                {{account.label}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && !googleAccountOptions.length" class="col-lg-4">
                        <input class="form-control accounts-list"
                               [value]="'Loading...'"
                        />
                    </div>

                    <div *ngIf="eventData.isMeetingCreated()" class="col-lg-4">
                        <input class="form-control"
                               [value]="this.eventData.externalUserName"
                               [dp-read-only]="true"
                        />
                    </div>
                </ng-container>

            </div>

            <div class="form-group" *ngIf="eventData.isZoomMeeting() && isShowZoomCredentials() && !isPackageTypeVirtualSigningRoom()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Zoom Credential</label>
                </div>
                <div class="col-lg-3">
                    <select type="text"
                            id="credential"
                            name="credential"
                            class="form-control"
                            fieldKey="customEvent.eventData.accountCredential"
                            dp-error-validator
                            [dp-read-only]="eventData.isMeetingCreated()"
                            (ngModelChange)="getZoomAccountUsers($event)"
                            [(ngModel)]="eventData.virtualMeetingAccountIntegrationId"
                    >
                        <option *ngFor="let credential of zoomCredentialOptions" [value]="credential.value">
                            {{credential.label}}
                        </option>
                    </select>
                </div>
                <div *ngIf="zoomAccountOptions && zoomAccountOptions.length > 0">
                    <div class="col-lg-2 vertical-align-label">
                        <label class="control-label">Zoom Account</label>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && zoomAccountOptions.length" class="col-lg-4">
                        <select type="text"
                                id="zoomAccount"
                                name="zoomAccount"
                                class="form-control"
                                fieldKey="customEvent.eventData.zoomUserId"
                                dp-error-validator
                                (ngModelChange)="setUserName($event, eventData.eventLocation)"
                                [(ngModel)]="eventData.externalUserId"
                        >
                            <option *ngFor="let account of zoomAccountOptions" [value]="account.value">
                                {{account.label}}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="!eventData.isMeetingCreated() && !zoomAccountOptions.length" class="col-lg-4">
                        <input class="form-control accounts-list"
                               [value]="'Loading...'"
                        />
                    </div>

                    <div *ngIf="eventData.isMeetingCreated()" class="col-lg-4">
                        <input class="form-control"
                               [value]="this.eventData.externalUserName"
                               [dp-read-only]="true"
                        />
                    </div>
                </div>

            </div>

            <div class="form-group" *ngIf="isPackageTypeVirtualSigningRoom() && eventData.meetingLink">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Location</label>
                </div>
                <div class="col-lg-9 padding-top-7">
                    <a href="{{eventData.meetingLink}}" target="_blank">{{eventData.meetingLink}}</a>
                </div>
            </div>

            <div class="form-group" *ngIf="isPackageTypeVirtualSigningRoom() && getSigningEnvelope().isEnvelopeCompleted()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Audit Video Link(s)</label>
                </div>
                <div class="col-lg-6" *ngIf="auditVideoLinks && auditVideoLinks.length">
                    <ul>
                        <li *ngFor="let link of auditVideoLinks; let i = index;" class="padding-top-7">
                            <a href="{{link}}" target="_blank">Video Link{{auditVideoLinks.length > 1 ? ' ' + (i+1) : ''}}</a>
                        </li>
                    </ul>
                </div>

            </div>

            <div class="form-group" *ngIf="eventData.eventLocation && !eventData.isMeetingInPerson() && isSameStartAndEndDate()">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Meeting Link</label>
                </div>
                <div class="col-lg-9" *ngIf="eventData.isMeetingOther()">
                    <input class="form-control"
                           [(ngModel)]="eventData.meetingLink"
                           (ngModelChange)="markDirty()"
                    >
                </div>
                <div *ngIf="!eventData.isMeetingOther() && eventData.meetingLink" class="col-lg-9 padding-top-7">
                    <a href="{{eventData.meetingLink}}" target="_blank">{{eventData.meetingLink}}</a>
                    <span *ngIf="eventData.isMSTeamsMeeting() || eventData.isZoomMeeting() || eventData.isGoogleMeeting()">
                        <i class="fa fa-trash pointerCursor margin-left-10"
                             (click)="deleteMeeting()"
                             aria-hidden="true"
                             [title]="'Delete Meeting'">
                        </i>
                    </span>
                </div>
                <div class="col-lg-9" *ngIf="!eventData.isMeetingOther() && !eventData.meetingLink">
                    <input class="form-control"
                           [dp-read-only]="true"
                    >
                </div>
            </div>

            <div class="form-group">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label">Note</label>
                </div>
                <div class="col-lg-9">
                <textarea rows="4"
                          class="form-control"
                          maxlength="16000"
                          [(ngModel)]="eventData.eventNote"
                          (ngModelChange)="markDirty()">
				</textarea>
                </div>
            </div>

        </div>

    </div>



    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    class="dp-btn dp-btn-green margin-right-10"
                    [disabled]="!dirty && !isDocumentSelectionDirty"
                    (click)="validateAndSave()">
                <span>{{getSaveLabel()}}</span>
            </button>
            <button type="button" class="dp-btn-white margin-right-10"
                    [disabled]="isSaveToCalendarDisabled()"
                    (click)="exportAppointment()">
                <span>Save to Calendar</span>
            </button>
            <button *ngIf="isMatterEvent()"
                    type="button" class="dp-btn-white margin-right-10"
                    [disabled]="!isInviteeSelected" (click)="informInvitee()">
                <span>Send Invite</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Close</span>
            </button>
            <button *ngIf="isAvailableForDelete()"
                    class="dp-btn dp-btn-cancel" (click)="delete()">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>
