import {Tab} from '../shared/tabbing';
import {User} from '../matters/shared';
import {Account} from '../admin/accounts/shared/account';
import {UserMessage} from './user-messages';

export class MessageListState {
  searchText: string;
  lastSearchText: string;
  categories: string[];
  userAllMessages: boolean;
  sortQuery: string;
  sortType: string;
  pageNo: number;
  pageSize: number;
  scrollY: number;
  tableData: UserMessage[];
}

export class MessagesTab extends Tab {

  constructor(src?: MessagesTab) {
    super(src);

    if (src) {
      src.user && (this.user = new User(src.user));
      src.account && (this.account = new Account(src.account));
    }
  }

  user: User;
  account: Account;
  subRoute: string;
  subRouteId: number;

  messageListState: MessageListState;

}

