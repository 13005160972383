<form class="form-horizontal" >
    <div class="container-fluid custom-modal-container">
        <div class="modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>{{isForm4BankAccount ? 'Form 4 Bank Accounts' : 'Firm Trust Accounts'}}</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
            <div class="clear"></div>
        </div>
        <div class="modal-body">
            <div class="col-lg-12 text-right add-new-wrapper">
                <li class="dropdown" *ngIf="isForm4BankAccount" style="display:inline-block;float: none;margin-right: 5px;">
                    <dp-dropdown-menu [dropDownItems]="firmTrustAccountOptions"  [customColor]="'white'"
                                      (itemClicked)="selectFirmTrustAccount($event)"
                                      [disabled]="firmTrustAccountOptions.length == 0"
                                      [buttonLabel]="'Add Firm Trust Account'"
                    ></dp-dropdown-menu>
                </li>
                <button tabindex="0" #addNewBtn class="dp-btn-green" type="button" (click)="addNewTrustAccount()" >
                    <span>Add New</span>
                </button>
            </div>

            <div class="col-xs-12 table-wrapper">
                <table class="table ta-table sticky-headers">
                    <thead>
                    <tr>
                        <th class="width-100  text-center">{{isForm4BankAccount ? 'Default' : 'Deposit'}}</th>
                        <th class="width-172">Name of Bank/Trust Co.</th>
                        <th class="width-40">&nbsp;</th>
                        <th class="width-170">Address</th>
                        <th class="width-140">Account No.</th>
                        <th class="width-170">{{isForm4BankAccount ? '' : 'Trust'}} Account Name</th>
                        <th class="width-200">Comments</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let trustAccount of trustAccounts; let i=index;"
                            (click)="toggleTrustAccountRowSelect(i)"
                            (dblclick)="openEditor(trustAccounts[i])"
                            (keydown)="onKeyPress($event)"
                            [ngClass]="{'active': isRowSelected(i)}">
                        <td class="width-100 text-center">
                            <ng-container *ngIf="!isForm4BankAccount">{{fundsCode[trustAccount.receiveMortgageFundsCode]}}</ng-container>
                            <ng-container *ngIf="isForm4BankAccount">
                                <span  class="radio"><input id="r-default-trust-{{i}}" type="radio" value="true"
                                        name="radio-trust-default" [checked]="trustAccount?.defaultForProject"  (click)="changeDefaultAccount(trustAccount)" /><label for="r-default-trust-{{i}}"></label></span>

                            </ng-container>
                        </td>
                        <td class="width-172 trim-at-170">&nbsp;{{trustAccount?.mortgageeName}}</td>
                        <td class="width-40" #trustAccountRows >
                            <dp-burger-menu #burgerMenus [items]="burgerMenuItems" (itemClicked)="burgerMenuClicked($event, i)"></dp-burger-menu>
                        </td>
                        <td class="width-170">{{trustAccount?.mortgageeMailingAddress?.addressTextWithProvinceName}}</td> <!-- Address -->
                        <td class="width-140">{{trustAccount?.trustAccountNumber}}</td>
                        <td class="width-170 trim-at-170">{{trustAccount?.trustAccountName}}</td>
                        <td class="width-200 trim-at-200">{{trustAccount?.comments}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button tabIndex="0" 
                        type="button"
                        id="btnClose"
                        (click)="close()"
                        class="dp-btn">
                    <span>OK</span>
                </button>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
    </div>
</form>
