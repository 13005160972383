import {StatementAdjustmentRawDataValueHolder} from './statement-adjustment-display-raw-data-value-holder';

export class StatementAdjustmentRawData {

  // The Net Sale Price as displayed on the Preview
  // NOTE: there is a different amount calculated for Net Sale Price as required for documents in netSalePriceWithoutConsiderations
  netSalePrice: string;

  totalCreditAdjustedForRebate: string;
  netSalePriceWithoutConsiderations: string;
  federalSaleTaxAmount: string;
  provincialSaleTaxAmount: string;
  federalTaxRebateAmount: string;
  provincialTaxRebateAmount: string;
  interimOccupancyFeesTotal: string;
  creditVendorAmount: string;
  interimOccupancyFeesOfDaysForPurchasersShare: string;
  interimOccupancyFeesPurchasersShare: string;
  interimOccupancyFeesHstCalculated: string;
  finalOccupancyFeesOfDaysForPurchasersShare: StatementAdjustmentRawDataValueHolder;
  finalOccupancyFeesPurchasersShare: StatementAdjustmentRawDataValueHolder;
  balanceDueOnClosing: string;
  netSalePriceBasedOnConsiderationTypes: string;
  hstAdjustmentHeading: StatementAdjustmentRawDataValueHolder;
  totalDepositsSelected: string;
  netSalePriceBasedOnTaxType: StatementAdjustmentRawDataValueHolder;
  totalCreditTarionWarranty: StatementAdjustmentRawDataValueHolder;
  depositOnDepositInterestUnpaidInterest: StatementAdjustmentRawDataValueHolder;
}
