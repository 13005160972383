<form #purchaseform="ngForm" *ngIf="soaConfig &&  soaConfig.disbursementsConfig" class="form-horizontal overFlow" id="dp-disbursement-config">
    <div class="row"
          tabindex="0">
        <div class="col-lg-12 padding-top-20 padding-bottom-40">
            <div class="fixed-header-soa">
                <div class="form-group padding-top-10 padding-bottom-10 padding-left-20">
                      <span class="radio display-inline" (click)="soaConfig.subjectToHstType = 'SUBJECT_TO_HST';">
                                  <input type="radio" [checked]="soaConfig.subjectToHstType == 'SUBJECT_TO_HST'">
                                  <label>Subject to {{taxRateType}}</label>
                      </span>
                    <span class="radio padding-left-20 display-inline" (click)="soaConfig.subjectToHstType = 'NOT_SUBJECT_TO_HST';">
                                  <input type="radio" [checked]="soaConfig.subjectToHstType == 'NOT_SUBJECT_TO_HST'">
                                  <label>Not Subject to {{taxRateType}}</label>
                    </span>
                    <ng-container *dpShowByProvince="'admin.statement.account.config.disbursement.additional';matterType:matterType;code:provinceCode">
                        <span class="radio padding-left-20 display-inline" (click)="soaConfig.subjectToHstType = 'ADDITIONAL';">
                                      <input type="radio" [checked]="soaConfig.subjectToHstType == 'ADDITIONAL'">
                                      <label>Additional Charges Subject to {{taxRateType}}</label>
                        </span>
                    </ng-container>
                    <ng-container *dpShowByProvince="'admin.statement.account.config.disbursement.subject.to.pst';matterType:matterType;code:provinceCode">
                        <span class="radio padding-left-20 display-inline" (click)="soaConfig.subjectToHstType = 'SUBJECT_TO_PST';">
                                      <input type="radio" [checked]="soaConfig.subjectToHstType == 'SUBJECT_TO_PST'">
                                      <label>Subject to PST</label>
                        </span>
                    </ng-container>
                    <ng-container *dpShowByProvince="'admin.statement.account.config.disbursement.subject.to.gst.and.pst';matterType:matterType;code:provinceCode">
                        <span class="radio padding-left-20 display-inline" (click)="soaConfig.subjectToHstType = 'SUBJECT_TO_GST_PST';">
                                      <input type="radio" [checked]="soaConfig.subjectToHstType == 'SUBJECT_TO_GST_PST'">
                                      <label>Subject to GST and PST</label>
                        </span>
                    </ng-container>

                </div>
                <div class="form-group">
                    <div class="btn2 col-lg-12">
                        <input
                            type="button"
                            class="action primary-button"
                            [disabled]="(selectedIndex===0 || selectedIndex === -1)"
                            (click)="moveUp()"
                            value="Move Up">
                        <input
                            type="button"
                            class="action primary-button padding-0"
                            [disabled]="(selectedIndex===(soaConfig.getDisbursementsLibrary(matterType).length -1) || selectedIndex === -1)"
                            (click)="moveDown()"
                            value="Move Down">
                        <input
                            type="button"
                            class="action primary-button"
                            (click)="reset()"
                            value="Reset">

                    </div>
                </div>
            </div>
            <div class="form-group table-div-disbursement">

                <table class="table disbursement-table" (keydown)="onKeyPress($event)">
                    <thead>
                    <tr>
                        <th>Description of Disbursement</th>
                        <th>&nbsp;</th>
                        <th>F9 Default Amount</th>
                        <th *ngIf="soaConfig.accountingSoftware == 'PCLAW'">Accounting Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="soaConfig.getDisbursementsLibrary(matterType).length === 0">
                        <td></td>
                        <td align="center">
                            <dp-burger-menu
                                [items]="burgerMenuItemWithoutDelete"
                                (itemClicked)="burgerMenuClicked($event, null)"
                                [allowPopUp]="false"></dp-burger-menu>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr *ngFor="let item of soaConfig.getDisbursementsLibrary(matterType); let i=index;" (click)="selectedItem(item)"
                        (keyup)="keyUpSelectedItem($event,item)" [ngClass]="{'selected-active': item.isSelected }">
                        <td >
                            <textarea autoresize rows="1" minHeight="30" maxlength="150" id="disbursementName-{{item.identifier}}" name="disbursementName-{{item.identifier}}"
                                   [ngModel]="mapDisbursementName(item.nameOfDisbursement)"
                                   (ngModelChange)="item.nameOfDisbursement=$event;setFormDirty()"
                                    class="input-font"
                                   [ngClass]="{'selected-active': item.isSelected }">
                            </textarea>
                        </td>
                        <td align="center">
                            <dp-burger-menu
                                    [items]="item.calculated || item.isERegServiceCharge() || item.isRegistrationCharge()  ||
                                     item.isRegistrationTransfer() ||  item.isCompliance() || item.isMLTTConfiguration()
                                     ? burgerMenuItemWithoutDelete : burgerMenuItems"
                                            (itemClicked)="burgerMenuClicked($event,item )" [allowPopUp]="false"></dp-burger-menu>
                        </td>
                        <td>

                                <input type="text" *ngIf="!item.calculated" id="disbursementValue-{{item.identifier}}"
                                       name="disbursementValue-{{item.identifier}}" class="input-font"
                                       [(ngModel)]="item.f9DefaultAmount"
                                       (ngModelChange)="setFormDirty()"
                                       [ngClass]="{'selected-active': item.isSelected }"
                                       dp-currency
                                       dp-default-currency/>
                            <input type="text" *ngIf="item.calculated" id="calculateValue-{{item.identifier}}" name="calculateValue-{{item.identifier}}"
                                   value="Calculated"
                                   [attr.readonly]="(item.calculated ? 'readonly' : null)"
                                   class="text-left"
                                   [ngClass]="{'selected-active': item.isSelected }"
                                   [style.color]="item.calculated ?'#cccccc' : ''"
                                       />
                        </td>
                        <td>
                            <div *ngIf="(soaConfig.accountingSoftware == 'PCLAW'|| soaConfig.accountingSoftware == 'Cosmolex') && !item.isTransactionLevySurcharge()">
                                <select class="form-control"
                                        id="glCode-{{item.identifier}}"
                                        name="glCode-{{item.identifier}}"
                                        [(ngModel)]="item.configAccountCode"
                                        (ngModelChange)="setFormDirty()"
                                >
                                    <option value=""></option>
                                    <option *ngFor="let lawCodes of item.configAccountCodeArray" [value]="lawCodes.value">
                                        {{lawCodes.label}}
                                    </option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>
