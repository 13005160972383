import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Matter} from '../../../shared/matter';
import {HttpClient} from '../../../../core';
import {DataPropagationCommand} from '../../../../projects/shared/data-propagation-commands';
import {FireInsuranceComponent} from '../../../fire-insurance';
import {UnitConnectDataPropagationCmdType} from './unit-connect-data-propagation';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {MortgageeComponent} from '../../../mortgages/mortgage/mortgagee/mortgagee.component';
import {Mortgage} from '../../../shared/mortgage';
import {MatterParticipantRoleTypes} from '../../../shared/matter-participant-role-types';
import {MortgageBrokerInfoComponent} from '../../../mortgages/mortgage/mortgage-broker-information/mortgage-broker-info.component';
import {MatterParticipant} from '../../../shared/matter-participant';
import {VendorsSolicitorComponent} from '../../../vendors-solicitor/vendors-solicitor.component';
import {PurchaserComponent} from '../../purchaser.component';
import {MatterOpeningComponent} from '../../../matter-opening/matter-opening.component';
import {DepositModalContextValue} from '../../../property-teranet/deposit/deposit.modal.component';
import {PropertyTeranetComponent} from '../../../property-teranet/property-teranet.component';
import {MortgageTermComponent} from '../../../mortgages/mortgage/term/mortgage-term.component';
import {BrokerCommissionComponent} from '../../../broker-commission/broker-commission.component';
import {Referral} from '../../../shared/referral/referral';
import {ReferralDocument} from '../../../shared/referral/referral-document';
import {MatterService} from '../../../matter.service';
import {Address} from '../../../shared/address';
import {MatterContactInfo} from '../../../shared/matter-contact-info';

@Injectable()
export class UnitConnectImportService {

  constructor(public http: HttpClient,
              public matterOpeningComponent: MatterOpeningComponent,
              public purchaserComponent: PurchaserComponent,
              public vendorsSolicitorComponent: VendorsSolicitorComponent,
              public propertyTeranetComponent: PropertyTeranetComponent,
              public contactQueryService: ContactQueryService,
              public mortgageeComponent: MortgageeComponent,
              public mortgageTermComponent: MortgageTermComponent,
              public brokerCommissionComponent: BrokerCommissionComponent,
              public mortgageBrokerInfoComponent: MortgageBrokerInfoComponent,
              public fireInsuranceComponent: FireInsuranceComponent,
              public matterService: MatterService) {
  }

  async callAsynchronously(callback: Function, targetMatter?: Matter, parameter?: any): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    callback(targetMatter, parameter);
    let serviceInstance = this;
    let scheduler = setInterval(() => {

      if (serviceInstance.http.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 50);
    return returnSubject.toPromise();
  }

  // some commands might need a saved matter and must be postponed for after saving the matter
  deferredDataPropagationCommands: DataPropagationCommand[] = [];

  async propagateToMatter(matter: Matter, cirfImportDataPropagationCommands: DataPropagationCommand[]): Promise<void> {
    if (cirfImportDataPropagationCommands && cirfImportDataPropagationCommands.length) {
      for (let propagationCommand of cirfImportDataPropagationCommands) {
        if (!propagationCommand.command) {
          propagationCommand.command = this.getPropagationCommand(propagationCommand.type);
        }
        if (propagationCommand.command) {
          if (propagationCommand.deferredAfterMatterSave) { // apply this only after the matter gets saved
            this.deferredDataPropagationCommands.push(propagationCommand);
          } else {
            await this.callAsynchronously(propagationCommand.command, matter, propagationCommand.parameter);
          }
        }
      }
    }
  }

  getPropagationCommand(type: string): Function {
    switch (type) {
      case UnitConnectDataPropagationCmdType.CIRF_FI_REMOVE_BROKER :
        return this.cirfPropagateFireInsuranceBrokerChange;
      case UnitConnectDataPropagationCmdType.CIRF_MD_ON_MORTGAGE_BROKER_CHANGE :
        return this.cirfPropagateMortgageBrokerChange;
      case UnitConnectDataPropagationCmdType.CIRF_MD_ADD_MORTGAGEE :
        return this.cirfPropagateMortgageAddMortgageeChange;
      case UnitConnectDataPropagationCmdType.CIRF_MD_REMOVE_MORTGAGEE :
        return this.cirfPropagateMortgageRemoveMortgageeChange;
      case UnitConnectDataPropagationCmdType.CIRF_REFERRAL_IMPORT_MAIN_CLIENT :
        return this.cirfReferralPropagateImportMainClient;
      case UnitConnectDataPropagationCmdType.CIRF_IMPORT_PRE_CLOSING_ADDRESS :
        return this.cirfReferralPropagateImportPreClosingAddress;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_CLIENT :
        return this.referralPropagateImportOtherSideClient;
      case UnitConnectDataPropagationCmdType.REFERRAL_MP_SOLICITOR_CHANGE :
        return this.referralMatterOpeningSolicitorChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_CLOSING_DATE :
        return this.referralMatterOpeningClosingDateChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_SALE_PRICE :
        return this.referralPropagateSalePriceChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_DEPOSIT :
        return this.referralPropagateDepositChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_MORTGAGE_TERM_PRINCIPAL :
        return this.referralPropagateMortgageTermPrincipalChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_COMMISSION_BEFORE_HST_CHANGE :
        return this.referralPropagateBrokerCommissionChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_SOLICITOR :
        return this.referralPropagateImportOtherSideSolicitor;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_OTHER_SIDE_FIRM :
        return this.referralPropagateImportOtherSideFirm;
      case UnitConnectDataPropagationCmdType.REFERRAL_IMPORT_REAL_ESTATE_BROKER_CHANGE :
        return this.referralPropagateRealEstateBrokerChange;
      case UnitConnectDataPropagationCmdType.REFERRAL_COPY_REFERRAL_DOCUMENT :
        return this.referralPropagateDocumentSelection;
      default:
        return null;
    }
  }

  cirfPropagateFireInsuranceBrokerChange = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initFireInsuranceComponent(targetMatter);
    if (isMethodCompleted) {
      if (targetMatter && targetMatter.brokerMatterParticipant && targetMatter.brokerMatterParticipant.contact) {
        this.contactQueryService.unlockContact(targetMatter.brokerMatterParticipant.contact.sourceContactId);
      }
      this.fireInsuranceComponent.propagateRemoveBrokerChanges();

    }
  };

  async initFireInsuranceComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.fireInsuranceComponent) {
        this.fireInsuranceComponent.setLocalInstanceMatter(matter);
        this.fireInsuranceComponent.ngOnInit();
      }
    });
  }

  cirfPropagateMortgageBrokerChange = async (targetMatter: Matter, mortgageId: number): Promise<void> => {
    let targetMortgage = targetMatter.mortgages.find(item => item.id == mortgageId);
    if (targetMortgage) {
      let isMethodCompleted = await this.initMortgageBrokerInfoComponent(targetMatter, targetMortgage);
      if (isMethodCompleted) {
        this.mortgageBrokerInfoComponent.propagateOnBrokerChanges();
      }
    }
  };

  propagateMortgageOnMortgageeChange = async (targetMatter: Matter, mortgageId: number, isAdd: boolean): Promise<void> => {
    // The concat() method is used to merge two or more arrays. This method does not change the existing arrays, but instead returns a new array.
    // The mortgage can be in mortgages or existingMortgages
    let mortgages: Mortgage[] = targetMatter.mortgages.concat(targetMatter.existingMortgages);
    let targetMortgage = mortgages.find(item => item.id == mortgageId);
    if (targetMortgage) {
      let isMethodCompleted = await this.initMortgageeComponent(targetMatter, targetMortgage);
      if (isMethodCompleted) {
        if (isAdd) {
          let mortgageParticipant: MatterParticipant = targetMatter.findMatterParticipant(MatterParticipantRoleTypes.MORTGAGEE, targetMortgage.id);
          if (mortgageParticipant && mortgageParticipant.contact) {
            this.mortgageeComponent.propagateCirfAddMortgageeChanges(mortgageParticipant.contact, targetMortgage, targetMatter);
          }
        } else {
          this.mortgageeComponent.propagateCirfRemoveMortgageeChanges(targetMortgage, targetMatter);
        }
      }
    }
  };

  cirfPropagateMortgageAddMortgageeChange = async (targetMatter: Matter, mortgageId: number): Promise<void> => {
    await this.propagateMortgageOnMortgageeChange(targetMatter, mortgageId, true);
  };

  cirfPropagateMortgageRemoveMortgageeChange = async (targetMatter: Matter, mortgageId: number): Promise<void> => {
    await this.propagateMortgageOnMortgageeChange(targetMatter, mortgageId, false);
  };

  async initMortgageeComponent(matter: Matter, mortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageeComponent) {
        this.mortgageeComponent.setLocalInstancesForMassUpdate(matter, mortgage);
        this.mortgageeComponent.ngOnInit();
      }
    });
  }

  async initMortgageBrokerInfoComponent(matter: Matter, mortgage: Mortgage): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageBrokerInfoComponent) {
        this.mortgageBrokerInfoComponent.setLocalInstancesForMassUpdate(matter, mortgage);
        this.mortgageBrokerInfoComponent.ngOnInit();
      }
    });
  }

  cirfReferralPropagateImportMainClient = async (targetMatter: Matter, needUpdateSuggestions?: boolean): Promise<void> => {
    let isMethodCompleted = await this.initPurchaserComponent(targetMatter);

    if (isMethodCompleted) {
      //For contact level update suggestions move to CirfImportContainerModal because it needs original matter to compare which contact need to update them.
      //we need to update matter level after updating the snapshot
      this.purchaserComponent.propogateImportChangesAction(needUpdateSuggestions);
    }
  };

  async initPurchaserComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.purchaserComponent) {
        this.purchaserComponent.setLocalInstanceMatter(targetMatter);
        this.purchaserComponent.ngOnInit();
        this.purchaserComponent.initPurchaserComponent();
      }
    });
  }

  cirfReferralPropagateImportPreClosingAddress = async (targetMatter: Matter, prePostClosingAddress: Address): Promise<void> => {
    if (!targetMatter.matterContactInfo) {
      targetMatter.matterContactInfo = new MatterContactInfo();
    }
    if (targetMatter.isPurchase) {
      targetMatter.matterContactInfo.preClosingAddress = prePostClosingAddress;
    } else {
      targetMatter.matterContactInfo.postClosingAddress = prePostClosingAddress;
    }
  };

  referralPropagateImportOtherSideClient = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initVendorsSolicitorComponent(targetMatter);
    if (isMethodCompleted) {
      this.vendorsSolicitorComponent.propogateImportChangesAction();

    }
  };

  referralPropagateImportOtherSideSolicitor = async (targetMatter: Matter): Promise<void> => {
    await this.initVendorsSolicitorComponent(targetMatter);
    // this.vendorsSolicitorComponent.ngOnInit() implement Solicitor Propagation
  };

  referralPropagateImportOtherSideFirm = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initVendorsSolicitorComponent(targetMatter);
    if (isMethodCompleted) {
      if (targetMatter.otherPartyLawFirm && targetMatter.otherPartyLawFirm.contact) {
        this.vendorsSolicitorComponent.updatePayableToValue(targetMatter.otherPartyLawFirm.contact.legalFirmName);
      }
    }
  };

  async initVendorsSolicitorComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.vendorsSolicitorComponent) {
        this.vendorsSolicitorComponent.setLocalInstanceMatter(targetMatter);
        this.vendorsSolicitorComponent.ngOnInit();
        this.vendorsSolicitorComponent.initVendorSolicitorStructure();
      }
    });
  }

  referralMatterOpeningSolicitorChange = async (targetMatter: Matter, solicitorId: number): Promise<void> => {
    let isMethodCompleted = await this.initMatterOpeningComponent(targetMatter);
    if (isMethodCompleted) {
      this.matterOpeningComponent.onSolicitorChange(solicitorId);
    }
  };

  referralMatterOpeningClosingDateChange = async (targetMatter: Matter, newClosingDate: string): Promise<void> => {
    let isMethodCompleted = await this.initMatterOpeningComponent(targetMatter);
    if (isMethodCompleted) {
      this.matterOpeningComponent.onDateChangeClosingDate({rawDate: newClosingDate});
    }
  };

  async initMatterOpeningComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.matterOpeningComponent) {
        this.matterOpeningComponent.setLocalInstanceMatter(targetMatter);
        try {
          this.matterOpeningComponent.preserveActionTabSection = true;
          this.matterOpeningComponent.ngOnInit();
        } finally {
          this.matterOpeningComponent.preserveActionTabSection = false;
        }
      }
    });
  }

  referralPropagateDepositChange = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initSubjectPropertyComponent(targetMatter);
    //Referral import deposit into matterPropertyWithCondo.depositAmount
    //For now only think about brand new matter
    if (isMethodCompleted) {
      this.applyDepositChanges(targetMatter, this.propertyTeranetComponent);
    }
  };

  applyDepositChanges(targetMatter: Matter, propertyTeranetComponent: PropertyTeranetComponent): void {
    let depositUpdate: DepositModalContextValue = {
      depositAmount: targetMatter.matterPropertyWithCondo.depositAmount,
      multipleDeposit: targetMatter.matterPropertyWithCondo.multipleDeposit,
      payDepositOutOfTrust: targetMatter.matterPropertyWithCondo.payDepositOutOfTrust,
      adjustmentFormat: targetMatter.matterPropertyWithCondo.adjustmentFormat,
      deposits: targetMatter.matterPropertyWithCondo.deposits,
      closingDate: targetMatter.getClosingDate()
    };
    propertyTeranetComponent.onDepositUpdate(depositUpdate);
  }

  referralPropagateSalePriceChange = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initSubjectPropertyComponent(targetMatter);
    if (isMethodCompleted) {
      this.applySalePriceChanges(targetMatter, this.propertyTeranetComponent);
    }
  };

  applySalePriceChanges(targetMatter: Matter, propertyTeranetComponent: PropertyTeranetComponent): void {
    // Referral import sale price into agreementSalePrice of salePriceAdjustment
    //Import agreementSalePrice to soaPurchasePrice
    targetMatter.matterPropertyWithCondo.soaPurchasePrice = propertyTeranetComponent.propagateSoaPurchasePrice();
    //salePrice need string. If it is 0, it should be '0'
    if (!targetMatter.matterPropertyWithCondo.purchasePriceType) {
      targetMatter.matterPropertyWithCondo.purchasePriceType = 'SALE_PRICE_AS_IN_SOA';
    }
    let salePriceUpdate = {
      salePrice: targetMatter.matterPropertyWithCondo.purchasePrice + '',
      salePriceSoa: targetMatter.matterPropertyWithCondo.soaPurchasePrice,
      priceType: targetMatter.matterPropertyWithCondo.purchasePriceType,
      salePriceAdjustment: targetMatter.considerationLtt.salePriceAdjustment,
      autoInsertHst: targetMatter.autoInsertHst,
      affidavitTobeSignedBy: targetMatter.matterPropertyWithCondo.affidavitTobeSignedBy,
      statementAdjustmentDisplayUtil: propertyTeranetComponent.statementAdjustmentDisplayUtil,
      overrideValueOfParcels: targetMatter.matterPropertyWithCondo.overrideValueOfParcels
    };
    propertyTeranetComponent.onSalePriceUpdate(salePriceUpdate);
  }

  async initSubjectPropertyComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.propertyTeranetComponent) {
        this.propertyTeranetComponent.setLocalInstanceMatter(targetMatter);
        this.propertyTeranetComponent.ngOnInit();
        this.propertyTeranetComponent.openProperty();
        this.propertyTeranetComponent.loadHomeOwnersResidentFeeSOA();
      }
    });
  }

  async initMortgageTermComponent(targetMatter: Matter, targetMortgageIndex: number): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.mortgageTermComponent) {
        this.mortgageTermComponent.setLocalInstancesForMassUpdate(targetMatter, targetMortgageIndex);
        this.mortgageTermComponent.initMortgageTerm();
      }
    });
  }

  referralPropagateMortgageTermPrincipalChange = async (targetMatter: Matter, mortgageId: number): Promise<void> => {
    let targetMortgage = targetMatter.mortgages.find(item => item.id == mortgageId);
    if (targetMortgage) {
      let isMethodCompleted = await this.initMortgageTermComponent(targetMatter, targetMortgage.id);
      if (isMethodCompleted) {
        if (targetMortgage.mortgageTerm && targetMortgage.mortgageTerm.principal) {
          this.mortgageTermComponent.mortgagePrincipalOnBlur();
        }
      }
    }
  };

  async initBrokerCommissionComponent(targetMatter: Matter): Promise<boolean> {
    return await this.callAsynchronously(() => {
      if (this.brokerCommissionComponent) {
        this.brokerCommissionComponent.setLocalInstanceMatter(targetMatter);
        this.brokerCommissionComponent.openBrokerCommission();
        if (targetMatter.isMatterProvinceAB) {
          this.brokerCommissionComponent.initForSaleMatterProvinceABAndBC();
        }
      }
    });
  }

  referralPropagateBrokerCommissionChange = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initBrokerCommissionComponent(targetMatter);
    if (isMethodCompleted) {
      if (this.brokerCommissionComponent) {
        this.brokerCommissionComponent.updateCommissionPayableToVendorBroker();
      }
    }
  };

  referralPropagateRealEstateBrokerChange = async (targetMatter: Matter): Promise<void> => {
    let isMethodCompleted = await this.initBrokerCommissionComponent(targetMatter);
    if (isMethodCompleted) {
      if (this.brokerCommissionComponent) {
        this.brokerCommissionComponent.propagateAddAndRemoveBrokerChanges();
      }
    }
  };

  // This one needs to wait for a matter save (In case Matter Creation From Referral) as the documents can only be attached to a saved matter
  // it will get postponed by being added to deferredDataPropagationCommands and executed after matter save (if the user saves import of new referral)
  referralPropagateDocumentSelection = async (targetMatter: Matter, referral: Referral): Promise<void> => {
    if (referral) {
      for (const selectedDocumentId of referral.selectedDocuments) {
        let document: ReferralDocument = referral.documents.find(doc => doc.id === selectedDocumentId);
        if (document) {
          const matterDoc = await this.matterService.copyReferralDocumentToMatter(targetMatter.id, referral.id, document).toPromise();
          console.table(matterDoc);
        }
      }
    }
  };

  async applyDeferredCommands(matter: Matter, referralId: number) {
    for (let propagationCommand of this.deferredDataPropagationCommands) {
      // only copying of referral documents gets postponed for now
      if (propagationCommand.type === UnitConnectDataPropagationCmdType.REFERRAL_COPY_REFERRAL_DOCUMENT) {
        if (propagationCommand.parameter && propagationCommand.parameter instanceof Referral && propagationCommand.parameter[ 'id' ] === referralId) {
          const rslt = await this.callAsynchronously(propagationCommand.command, matter, propagationCommand.parameter);
          if (rslt) {
            propagationCommand.type = 'TO_BE_REMOVED';
          }
        }
      }
    }
    this.deferredDataPropagationCommands = this.deferredDataPropagationCommands.filter(pc => pc.type !== "TO_BE_REMOVED");
  }
}
