import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class DocSharingMessage extends BaseEntity {
  id: number;
  message: string;
  messageReadTime: number;
  messageSubject: string;
  lastUpdatedTimeStamp: number;

  constructor(docSharingMessage?: DocSharingMessage) {
    super(docSharingMessage);
  }
}
