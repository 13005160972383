<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" *ngIf="hstSalePriceSoaLocal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>{{getModalTitle()}}</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Credit {{purchaserLabel}} or {{vendorLabel}}</label>
                </div>
                <div class="col-lg-5">
                    <select type="text"
                            id="creditType"
                            name="creditType"
                            (change)="creditTypeChange()"
                            class="form-control"
                            [(ngModel)]="hstSalePriceSoaLocal.creditType"
                    >
                        <option *ngFor="let creditDrpDwn of creditDropDown" [value]="creditDrpDwn.value">
                            {{creditDrpDwn.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="hstSalePriceSoaLocal.isHstSalePrice()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{hstSalePriceSoaLocal.rateType}} on Total Consideration of</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="netSalePrice"
                           name="netSalePrice"
                           dp-read-only="true"
                           dp-currency
                           class="form-control"
                           [ngModel]="context.salePrice"
                    />
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="hstSalePriceSoaLocal.isHstSalePrice()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >{{hstSalePriceSoaLocal.rateType}} percentage is</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"
                           id="percentage"
                           name="percentage"
                           dp-read-only="true"
                           class="form-control"
                           [ngModel]="taxRate+'%'"
                    />
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="!isTaxRateGst() && !isProvinceNBNS()">
            <div class="col-lg-12">
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Format</label>
                </div>
                <div class="col-lg-5">
                    <select type="text"
                            id="format"
                            name="format"
                            class="form-control"
                            [(ngModel)]="hstSalePriceSoaLocal.soaFormatType"
                    >
                        <option *ngFor="let hstSalePriceFormat of hstSalePriceFormats" [value]="hstSalePriceFormat.value">
                            {{hstSalePriceFormat.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="isAdded">
            <div class="col-lg-12">
                <div class="form-group-warning-message margin-bottom-0"
                >
                    <div class="col-lg-1">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </div>
                    <div class="col-lg-11">
                        <p>
                            This adjustment cannot be deleted from here. To delete this adjustment, uncheck the associated checkbox under the Purchase Price
                            of the Property or Consideration & LTT Tabs
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd" *ngIf="hstSalePriceSoaLocal.isHstRebate()">
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    >{{hstSalePriceSoaLocal.rateType}} Rebate Assigned to {{vendorLabel}}</label>
                </div>
            </div>
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    >Based on Net Sale Price of {{context.salePrice | currency:CAD:'symbol'}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"  *ngIf="!isTaxRateGst() && !isProvinceNBNS()">
                <div class="col-lg-3">
                    <label class="control-label"
                    >Federal Portion</label>
                </div>
                <div class="col-lg-2 text-right">
                    {{(salePriceAdjustment ? salePriceAdjustment.calculateFederalRebatePortion(federalHstRate, context.salePrice) : 0) |
                    currency:CAD:'symbol' }}
                </div>
            </div>
            <div class="col-lg-12  form-group"  *ngIf="!isTaxRateGst() && !isProvinceNBNS()">
                <div class="col-lg-3">
                    <label class="control-label"
                    >{{provincialLabel}} Portion</label>
                </div>
                <div class="col-lg-2 text-right" >
                    {{(salePriceAdjustment ? salePriceAdjustment.calculateOntarioRebatePortion(provinceHstRate, context.salePrice) : 0) |
                    currency:CAD:'symbol' }}
                </div>
            </div>
            <div class="col-lg-12  form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    >Credit {{hstSalePriceSoaLocal.creditType}}</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="text-right"  [ngClass]="hstSalePriceSoaLocal.isVendorSelected ? 'col-lg-2' : 'col-lg-3'">
                    {{calculateRebatePortion() | currency:CAD:'symbol'}}
                </div>
            </div>
        </div>
        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd" *ngIf="hstSalePriceSoaLocal.isHstSalePrice()">
            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    >{{hstSalePriceSoaLocal.rateType}} {{getOnSalePriceLabel()}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"  *ngIf="!isTaxRateGst() && !isProvinceNBNS()">
                <div class="col-lg-3">
                    <label class="control-label">Federal Portion {{federalHstRate}}% of {{context.salePrice | currency:CAD:'symbol'}}</label>
                </div>
                <div class="col-lg-2 text-right">
                    {{(salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(federalHstRate, federalHstRate, provinceHstRate)) | currency:CAD:'symbol'}}
                </div>
            </div>
            <div class="col-lg-12  form-group"  *ngIf="!isTaxRateGst() && !isProvinceNBNS()">
                <div class="col-lg-3">
                    <label class="control-label">Ontario Portion {{provinceHstRate}}% of {{context.salePrice | currency:CAD:'symbol'}}</label>
                </div>
                <div class="col-lg-2 text-right">
                    {{(salePriceAdjustment.calculateTaxPortionForAnyNetOutHstFromHSTSalePrice(provinceHstRate, federalHstRate, provinceHstRate)) | currency:CAD:'symbol'}}
                </div>
            </div>
            <div class="col-lg-12  form-group"  *ngIf="isTaxRateGst()">
                <div class="col-lg-3">
                    <label class="control-label"
                    >Calculated at: {{taxRate}}% of {{context.salePrice | currency:CAD:'symbol'}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"  *ngIf="isProvinceNBNS()">
                <div class="col-lg-3">
                    <label class="control-label"
                    >Calculated at: {{appliedRate}}% of {{context.salePrice | currency:CAD:'symbol'}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group" *dpShowByProvince="'matter.soa.hst-sale-price-modal.creditTo'">
                <ng-container *ngIf="isTaxRateGst()">
                    <div class="col-lg-3">
                        <label class="control-label">Credit to {{hstSalePriceSoaLocal.creditType}}</label>
                    </div>
                </ng-container>

            </div>
            <div class="col-lg-12  form-group"  *ngIf="purchaserLabel == hstSalePriceSoaLocal.creditType">
                <div class="col-lg-4">
                    <label class="control-label"
                    >Credit to {{hstSalePriceSoaLocal.creditType}} Pursuant to Agreement</label>
                </div>
            </div>
            <div class="col-lg-12  form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    >Credit {{hstSalePriceSoaLocal.creditType}}</label>
                </div>
                <div class="col-lg-2"></div>
                <div class="text-right" [ngClass]="hstSalePriceSoaLocal.isVendorSelected ? 'col-lg-2' : 'col-lg-3'">
                    {{creditAmount | currency:CAD:'symbol'}}
                </div>
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button *ngIf="!isAdded && allowDelete" type="button" class="secondary-button" (click)="delete()">
                <span>Delete</span>
            </button>
        </div>
    </div>
</form>
