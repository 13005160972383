import {StatementAdjustment} from '../statement-adjustment';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';

export class StatementAdjustmentDisplayOccupancyPeriodBuilder {

  static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    // let provinceCode = mainBuilder.matter && mainBuilder.matter.provinceCode ? mainBuilder.matter.provinceCode : null;

    const monthlyCommonExpense: Number
      = item.soAdjExpenseOccupancyPeriod.getMonthlyCommonExpensesAsNumber(mainBuilder.matter.getUnitLevelPlanCondominiumTotalExpenses(), mainBuilder.matter.getCondominiumTotalSharePercentage());
    sadItemDetails.push(mainBuilder.getNewItemLine(`Monthly ${ this.getLabelBasedOnProvince(mainBuilder) }`,
      `${ mainBuilder.getCurrency(monthlyCommonExpense && monthlyCommonExpense.valueOf()) }`));

    const adjustedForCalculatedPercentageValue: Number
      = item.soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTaxAsNumber(mainBuilder.matter.getUnitLevelPlanCondominiumTotalExpenses(), mainBuilder.matter.getCondominiumTotalSharePercentage());
    if (item.soAdjExpenseOccupancyPeriod.isExpenseOccupancyAdjusted === 'YES') {
      sadItemDetails.push(mainBuilder.getNewItemLine(
        `${ item.soAdjExpenseOccupancyPeriod.adjustedFor } calculated at ${ item.soAdjExpenseOccupancyPeriod.adjustPercentage }%`,
        mainBuilder.getCurrency(adjustedForCalculatedPercentageValue && adjustedForCalculatedPercentageValue.valueOf())));
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(`Total ${ this.getLabelBasedOnProvince(mainBuilder) }`, ''));
    sadItemDetails.push(mainBuilder.getNewItemLine(`${ item.soAdjExpenseOccupancyPeriod.getTotalExpensePeriod(mainBuilder.matter.soAdjExpenseOccupancyPeriodOccupancyDate, mainBuilder.matter.soAdjExpenseOccupancyPeriodClosedate) }`,
      mainBuilder.getCurrency(item.soAdjExpenseOccupancyPeriod.getSoAdjExpenseOccupancyPeriodTotalAsNumber(mainBuilder.matter.getUnitLevelPlanCondominiumTotalExpenses(),
        mainBuilder.matter.getCondominiumTotalSharePercentage(),
        mainBuilder.matter.soAdjExpenseOccupancyPeriodClosedate,
        mainBuilder.matter.soAdjExpenseOccupancyPeriodOccupancyDate))));

    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Vendor`, ''));

    return sadItemDetails;
  }

  static getLabelBasedOnProvince(mainBuilder: StatementAdjustmentDisplayBuilder): string {
    return mainBuilder.matter.isMatterProvinceON ? 'common expenses' :
      mainBuilder.matter.isMatterProvinceBC ? 'strata fees' : 'condominium fees';
  }
}
