<div class="aiTnC-container">
    <div class="aiTnC-container__header">
        <div class="headericon">
            <span class="material-symbols-outlined"> robot_2 </span>
        </div>
        <div class="aiTnC-container__header__textHeader">
            <p class="heading">
                Welcome to DeeDee AI <br />Assistant.<span class="preview preview__heading">Preview</span>
            </p>
        </div>
        <div class="aiTnC-container__header__actionIconsDiv">
            <div class="closeIcon">
                <span (click)="closeModal()" class="aiHeaderBtn material-symbols-outlined">
                    close
                </span>
            </div>
        </div>
    </div>
    <div class="aiTnC-container__content">
        <div class="overlay" *ngIf="isLoading">
            <app-spinning></app-spinning>
        </div>
        <p *ngIf="!isLoading" class="main-content" [innerHTML]="termsconditionsContent">
        </p>
    </div>

    <div class="aiTnC-container__useracceptance">
        <mat-slide-toggle (change)="toggleEnableDeeDeeAIBtn($event)">
        </mat-slide-toggle>
        <label class="custom-control-label" for="tncAcceptance">By using DeeDee AI, you acknowledge that you have read
            and understood these
            Terms and agree to be bound by them.</label>
    </div>

    <div class="chatbotBtn" [ngClass]="{enable: enableDeeDeeAI, disable: !enableDeeDeeAI}" (click)="EnableDeeDeeAI()">
        Enable DeeDee AI
        <span class="preview" [ngClass]="{previewBtnEnabled : enableDeeDeeAI, previewBtnDisabled: !enableDeeDeeAI}">
        Preview</span>
    </div>
</div>
