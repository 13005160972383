import {Matter} from '../matter';
import {MatterEventUtils} from './matter-event-utils';
import {DpBooleanValueTypes} from '../dp-boolean';
import {MatterForm} from '../matterForm';
import {BuilderTypes, NewHomePstRebateForm} from '../../forms/eregistration/modal/pst-rebate-form-new-home-modal/new-home-pst-rebate-form';
import {MatterParticipant} from '../matter-participant';
import {MatterTitleInsurance} from '../matter-title-insurance';
import {BrokerCommission} from '../../broker-commission/broker-commission';
import {BrokerCommissionDropDowns} from '../../broker-commission/broker-commission.dropdown';
import {constValues} from '../const-values';
import {AddressUtil} from '../address-util';
import {Form4AddressedTo} from '../../mortgages/mortgage/dropdown-options';
import {PROVINCE_CODES} from '../user-province';
import {DirectDepositInstruction} from '../direct-deposit-instruction';
import {dropDowns, ROLL_NUMBER_MULTIPLE, ROLL_NUMBER_NOT_ASSIGNED} from '../matter-drop-downs';
import {AddressTypes} from '../address-types';
import {Address} from '../address';
import Utils from '../../../shared-main/utils';
import {DEFAULT_POSSESSION_TIME, DirectionReFundTypes} from '../../../shared-main/constants';
import {TeranetDocket} from '../../../shared-main/teranet/teranet-docket';
import {MatterParticipantRoleTypes} from '../matter-participant-role-types';
import {FamilyLawAct} from '../fla-data';
import {Mortgage, MortgageCorrespondenceTypes} from '../mortgage';
import * as _ from 'lodash';
import {PurchaserCapacity} from '../../purchaser/capacity';
import {TrustLedgerMatter} from '../../trust-ledger/trust-ledger-matter';
import {DirectionReFund} from '../../direction-re-funds/direction-re-funds';
import {MatterTitleDetailsUtil} from './matter-title-details-util';
import {MatterBrokerMatterLevelInfoUtil} from './matter-broker-matter-level-info-util';
import {MatterProperty} from '../matter-property';
import {RollNumber} from '../roll-number';
import {SoaTrustLedgerCollection} from '../soa-trustledger-collection';
import {MatterService} from '../../../../app/matters/matter.service';
import {LegalDescriptionUtil} from '../../title-insurance/title-insurance-legal-description';
import {MatterParticipantService} from '../../matter-participant-service';
import {AppConfig} from '../../../shared-main/app-configuration';

export class MatterCleanUpUtil {

  // static cleanUp(matter: Matter){
  //  if (matter) {
  //       }
  // }

  static cleanTitleInsuranceData(matter: Matter, empMortgage: Mortgage) {
    // if current TI insurer is FCT and new Added EMP is MMS, then story 19892
    if (matter) {
      if (matter.isInsurerFCT() && matter.matterTitleInsurance) {
        matter.matterTitleInsurance.mmsDealExists = DpBooleanValueTypes.N_y;
        matter.matterTitleInsurance.mmsDealId = null;
      }
    }
  }

  static cleanUpWitness(matter: Matter) { // scary name
    if (matter) {
      matter.deleteMatterParticipantByRole(MatterParticipantRoleTypes.WITNESS);
      matter.selectedWitnessId = null;
    }
  }

  static cleanUpSolicitor(matter: Matter) {
    if (matter) {
      matter.deleteMatterParticipantByRole(MatterParticipantRoleTypes.SOLICITOR);
      matter.selectedSolicitorId = null;
    }
  }

  static cleanUpLawClerk(matter: Matter) {
    if (matter) {
      matter.deleteMatterParticipantByRole(MatterParticipantRoleTypes.LAWCLERK);
      matter.selectedLawClerkId = null;
    }
  }

  static cleanUpCommissioner(matter: Matter) {
    if (matter) {
      matter.deleteMatterParticipantByRole(MatterParticipantRoleTypes.COMMISSIONER);
      matter.selectedCommissionerId = null;
    }
  }

  /**
   * In NB, depend on maritalStatus, propertyOccupiedAsMaritalHome, applicableProvisionision values to clean up fla data
   * @param participant
   * @param errorService
   */
  static cleanUpNbFlaData(matter: Matter, participant: MatterParticipant, matterParticipantService: MatterParticipantService) {
    if (matter) {
      if (matter.isMatterProvinceNB) {
        if (matter.matterParticipantHasFLA(participant)) {
          participant.familyLawActs[ 0 ].cleanUpDataForNB(matter, matterParticipantService);
        }
      }
    }
  }

  static revertMatterCleanUp(matter: Matter, backupMapForCleanupUIOnlyField?: Map<string, any>, appConfig?: AppConfig) {
    // Add Matter back on TrustLedgerCollection since it was removed as part of cleanup matter ...
    // In case error we need to set matter back
    if (matter) {
      if (backupMapForCleanupUIOnlyField && appConfig && !appConfig.isMatterSaveStringified) {
        MatterCleanUpUtil.restoreUIOnlyFields(matter, backupMapForCleanupUIOnlyField);
      }
      matter.addMatterToTrustLedgerCollection();
      MatterCleanUpUtil.revertMortgageCleanUp(matter);
      matter.changeCustomMatterToPurchaseMatter();
      matter.changeDischargeMatterToMortgageMatter();
    }
  }

  static revertMortgageCleanUp(matter: Matter) {
    if (matter) {
      if (matter.mortgages && matter.mortgages.length > 0) {
        matter.mortgages = matter.mortgages.filter(item => item.isNewMortgage());
      }
    }
  }

  static cleanUpMortgageAdvances(matter: Matter, mortgageId: number): void {
    if (matter) {
      if (matter.holdbacks && matter.holdbacks.length && matter.holdbacks.some(holdback => holdback.mortgageId == mortgageId)) {
        matter.holdbacks = matter.holdbacks.filter(holdback => holdback.mortgageId != mortgageId);
        if (matter.soaTrustLedgerCollection) {
          //Update Trust Ledger
          matter.soaTrustLedgerCollection.addTrustLedgerHoldbacksRows();
        }
      }
    }
  }

  static cleanUpConsiderationLtt(matter: Matter): void {
    if (matter) {
      if (matter.considerationLtt) {

        if (matter.mainClients.length > 2) {
          matter.considerationLtt.lttAffidavitExecutedBy = 'SOLICITOR';
        }
        if (matter.considerationLtt.lttRateBasedOnType != 'APPORTION') {
          matter.considerationLtt.valueOfSingleFamilyResidence = 0;
          matter.considerationLtt.remainderOfLandUsedFor = undefined;
        }
        if (!matter.isPropertyTeranetJurisdictionToronto()) {
          matter.considerationLtt.applyCityOfTorontoLandTransferTax = undefined;
          matter.considerationLtt.lttRefundEntitlementToronto = undefined;
          matter.considerationLtt.torontoLandTransferTax = 0;
          matter.considerationLtt.torontoLessLttRefund = 0;
          matter.considerationLtt.torontoNetLttPayable = 0;
          matter.considerationLtt.totalOntarioTorontoLttPayable = 0;
        }
        if (matter.considerationLtt.lttRefundEntitlementOntario == 'N/y' || matter.considerationLtt.lttRefundEntitlementOntario == 'NO') {
          matter.considerationLtt.percentageClaimed = undefined;
          matter.considerationLtt.ontarioNetLttPayable = 0;
          matter.considerationLtt.ontarioLessLttRefund = 0;
          matter.considerationLtt.torontoLessLttRefund = 0;
          matter.considerationLtt.torontoNetLttPayable = 0;
        }

        if (matter.matterParticipantSpouse().length >= 2 && !matter.considerationLtt.isMatterParticipantSpouseSelected) {
          matter.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
        } else if (matter.matterParticipantSpouse().length < 2 && matter.considerationLtt.isMatterParticipantSpouseSelected) {
          matter.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
        }
      }
    }
  }

  static cleanUpMortgages(matter: Matter): void {
    if (matter) {
      if (matter.isOpportunityMatter()) {
        this.cleanUpOpportunityMortgages(matter);
      }
      if (matter.existingMortgages && matter.existingMortgages.length > 0) {
        matter.existingMortgages.forEach((mortgage: Mortgage, index) => {
          // Clean up existing mortgage
          mortgage.cleanUpExistingMortgage(matter.provinceCode, matter.matterType);
          mortgage.multiTemplatePriority = index + 1;
          if (mortgage.isMortgageDispositionAssumed() && !matter.isMatterProvinceAB) {
            mortgage.mortgageRequestNo = null;
          }
          if (mortgage.isMortgageePrivateLender()) {
            matter.removeMortgageeFromMortgage(mortgage);
          } else {
            matter.removePrivateLendersFromMortgage(mortgage);
          }
        });
      } else {
        matter.existingMortgages = [];
      }
      if (matter.mortgages && matter.mortgages.length > 0) {
        matter.mortgages.forEach((mortgage: Mortgage, idx) => {
          if (matter.isMatterProvinceAB) {
            MatterTitleDetailsUtil.setDefaultTitleDetailsAB(matter, mortgage);
          } else if (matter.isMatterProvinceSK || matter.isMatterProvinceMB) {
            MatterTitleDetailsUtil.setDefaultTitleDetailsSK(matter, mortgage);
          } else if (matter.isMatterProvinceNBorNS) {
            MatterTitleDetailsUtil.setDefaultTitleDetailsNBOrNS(matter, mortgage);
          }

          this.cleanupBlanketMortgageProperties(mortgage, matter);
          MatterCleanUpUtil.cleanFinalReport(matter, mortgage);
          mortgage.cleanUpMortgage();

          // If purchase matter and not acting for mortgagee then clean correspondence type field from mortgage
          if (matter.isPurchase && mortgage.acting !== DpBooleanValueTypes.NO) {
            mortgage.mortgageCorrespondenceType = '';
          }

          let excludedParticipants: MatterParticipant[] = (mortgage.mortgageeType === 'PRIVATE_LENDER') ? matter.getMortgagees(mortgage) : matter.getPrivateLenders(mortgage);
          excludedParticipants.forEach(item => {
            excludedParticipants.push(...matter.getChildMatterParticipants(item));
          });
          /*
          * Commenting the following part as per LOB requirements.
          * Even if the includeAuthorizeSignOfficer flag is NO, we want to include the signing officers as matter participants
          * And those MPs will be hidden just in case the user switch the flag to YES, then they will be visible
          * */
          // if (mortgage.mortgageeType != 'PRIVATE_LENDER' && mortgage.includeAuthorizeSignOfficer != DpBooleanValueTypes.YES) {
          //     matter.getMortgagees(mortgage).forEach(item => {
          //         excludedParticipants.push(...matter.getChildMatterParticipants(item));
          //     });
          // } else if (mortgage.mortgageeType === 'PRIVATE_LENDER') {
          //     matter.getPrivateLenders(mortgage).forEach(item => {
          //         if (item.includeAuthorizeSignOfficer != DpBooleanValueTypes.YES) {
          //             excludedParticipants.push(...matter.getChildMatterParticipants(item));
          //         }
          //     });
          // }
          matter.matterParticipants = _.difference(matter.matterParticipants, excludedParticipants);

          // Setting mortgageCapacityDescription based on the capacity option selected in drop down. Some drop down options are created dynamically
          // therefore setting on save.
          if (matter.getPrivateLenders(mortgage).length > 0 && mortgage.mortgageCapacityType) {
            mortgage.mortgageCapacityDescription = PurchaserCapacity.getMortgageCapacityDescription(mortgage.mortgageCapacityType, matter.getPrivateLenders(mortgage).length, matter.provinceCode);
          } else if (matter.getPrivateLenders(mortgage).length == 0) {
            mortgage.mortgageCapacityType = null;
            mortgage.mortgageCapacityDescription = null;
          }

          if (mortgage.mortgageTerm) {
            let trustLedgerForMortgage: TrustLedgerMatter = matter.matterTrustLedgers ? matter.matterTrustLedgers.find(mtl => mtl.fromMortgageIndex == idx) : undefined;
            mortgage.mortgageTerm.cleanUpMortgageTerm(mortgage, trustLedgerForMortgage);

            if (mortgage.loanType !== 'ARRANGED') {
              mortgage.mortgageTerm.collateralMortgage = undefined;
              mortgage.mortgageTerm.collateral = undefined;
            }
          }

          matter.updateMortgageeInterestOnNotedPolicy(mortgage);
          mortgage.multiTemplatePriority = mortgage.mortgagePriority;
        });
      } else {
        matter.mortgages = [];
      }
      matter.mortgages.push(...(matter.existingMortgages || []));
    }
  }

  static cleanFinalReport(matter: Matter, mortgage: Mortgage) {
    if (matter) {
      if (matter.isPurchase) {
        if (mortgage.mortgageReport && mortgage.mortgageReport.generateReportingLetterTo !== 'PURCHASER_MORTGAGEE') {
          mortgage.finalEnclosures = undefined;
          mortgage.finalEnclosuresDefaultsOverridden = false;
        }
      } else if (matter.isMortgage) {
        if (mortgage.isUnityNewMortgage()) {
          if (mortgage.mortgageReport && mortgage.mortgageReport.generateReportingLetterTo !== 'MORTGAGOR_MORTGAGEE') {
            mortgage.finalEnclosures = undefined;
            mortgage.finalEnclosuresDefaultsOverridden = false;
          }
        }
      }
    }
  }

  static cleanUpConsentedSpouseContactOfFla(matterParticipant: MatterParticipant) {
    if (matterParticipant && matterParticipant.hasFla() && matterParticipant.familyLawActs[ 0 ].consentedSpouse) {
      matterParticipant.familyLawActs[ 0 ].consentedSpouse = null;
    }
  }

  static cleanUpMatterParticipant(matter: Matter): void {
    if (matter) {
      if (Array.isArray(matter.matterParticipants) && matter.matterParticipants.length > 0) {
        matter.matterParticipants.forEach((matterParticipant: MatterParticipant) => {
          if (matterParticipant.matterParticipantRole === matter.mainClientType
            || matterParticipant.matterParticipantRole === matter.otherSideClientType
            || matterParticipant.matterParticipantRole === MatterParticipantRoleTypes.DEVELOPER
            || matterParticipant.matterParticipantRole === 'GUARANTOR' || matterParticipant.matterParticipantRole === 'SIGNING_OFFICER'
            || matterParticipant.matterParticipantRole === 'POWER_OF_ATTORNEY' || matterParticipant.matterParticipantRole === 'ESTATE_TRUSTEE') {

            if (matterParticipant.contact) {
              matterParticipant.contact.initializeContactIdDetails();
              matterParticipant.contact.setIdDetailsDefaultLabel();
              matterParticipant.contact.setClientIdBusinessAddressDefaultValue(undefined, matter.provinceCode);
            }
          }

          // Checking if law firm name or address has changed. For this check to work the component should add sourceContact in participant
          if (matterParticipant.sourceContact && matterParticipant.contact.isDirty &&
            (matterParticipant.matterParticipantRole == 'MORTGAGE_LEGAL_FIRM'
              || matterParticipant.matterParticipantRole == 'OTHERPARTY_LAW_FIRM'
              || matterParticipant.matterParticipantRole == 'DEVELOPER_LAW_FIRM')
            && (matterParticipant.sourceContact.legalFirmName != matterParticipant.contact.legalFirmName ||
              matterParticipant.sourceContact.mailingAddress.addressText != matterParticipant.contact.mailingAddress.addressText
            )
          ) {
            matterParticipant.contact.lawFirmNameOrAddressChanged = true;
          }
          // MatterCleanUpUtil.cleanUpConsentedSpouseContactOfFla(matterParticipant); Back end won't copy DTO to domain
        });
      }

      matter.purchasers.forEach((purchaser: MatterParticipant) => {
        // "initializeContactIdDetails" will update officerName and nameOnId before saving.
        // We may have a better name for this method.
        purchaser.contact && purchaser.contact.initializeContactIdDetails();
        // Clean TitleDeedName
        if (purchaser.titleDeed && !purchaser.titleDeed.isManuallyEnter()) {
          purchaser.titleDeed.cleanUpTitleDeedName();
        }
      });

      // For AB if there are more than one main clients then dower status (FLA) tab not required
      // DPPMP-35958 When click 'As a ' & <Role> spousal relationship suggestion button, the selected spouse is added to the Matter FLA
      // Although there is not FLA section in UI
      if (matter.provinceCode == 'AB' && matter.mainClients.length > 1) {
        matter.mainClients.forEach(mc => {
          mc.familyLawActs = mc.familyLawActs.filter((item: FamilyLawAct) => item.spouseMatterParticipantId);
        });
      }

      if (Array.isArray(matter.matterParticipants)) {
        for (let i = 0; i < matter.matterParticipants.length; i++) {
          matter.parseAddressForMatterParticipant(matter.matterParticipants[ i ]);
          matter.matterParticipants[ i ].cleanSigningLocationData();
          if (matter.matterParticipants[ i ].contact) {
            matter.matterParticipants[ i ].contact.cleanupRedundantData();
            matter.removeParentContactReferenceFromMatterParticipant(matter.matterParticipants[ i ].contact);
            if (matter.matterParticipants[ i ].contact.isEstate) {
              if (matter.isFlaApplicableForEstate(matter.matterParticipants[ i ])) {
                matter.matterParticipants[ i ].cleanUpFlaExceptOtherFla();
              } else {
                if (!matter.isMainClient(matter.matterParticipants[ i ]) && matter.provinceCode != 'AB') {
                  matter.matterParticipants[ i ].familyLawActs = [];
                }
              }
            }
          }
        }
      }
      MatterCleanUpUtil.cleanupMortgageeAttention(matter);

      // Clean up Gurantors...
      let guarantors = matter.guarantors;
      guarantors.forEach((mp: MatterParticipant) => {

        if (!mp.contact.isContactDialogFormValid('GUARANTOR')) {
          matter.deleteMatterParticipant(mp);
        }
        if (mp.contact && !mp.contact.isCorporationOrOtherEntity) {
          mp.contact.workPhone = null;
        }
      });

      MatterCleanUpUtil.cleanUpInsurerOrBroker(matter);

      MatterCleanUpUtil.cleanUpMortgageParticipant(matter);

      MatterCleanUpUtil.cleanUpMortgageSolicitorOrLegalFirm(matter, MatterParticipantRoleTypes.MORTGAGE_SOLICITOR);
      MatterCleanUpUtil.cleanUpMortgageSolicitorOrLegalFirm(matter, MatterParticipantRoleTypes.MORTGAGE_LEGAL_FIRM);

      // Clean up the Purchaser agent and broker MatterParticipant
      if (matter.commissionPaidTo === 'VENDOR_BROKER_ONLY') {
        if (matter.purchaserRealEstateBroker) {
          matter.removeMatterParticipant(matter.purchaserRealEstateBroker);
        }

        if (matter.purchaserRealEstateAgent) {
          matter.removeMatterParticipant(matter.purchaserRealEstateAgent);
        }
      }

      //Clean opportunity Real Estate Agent and Broker
      if (matter.isOpportunityMatter() && !(matter.isOpportunityPurchase || matter.isOpportunitySale)) {
        if (matter.realEstateAgent) {
          matter.removeMatterParticipant(matter.realEstateAgent);
        }
        matter.realEstateBrokerName = '';
        matter.selectedBrokerId = null;
      }
    }
  }

  static cleanupMortgageeAttention(matter: Matter) {
    if (matter) {
      matter.mortgages.forEach((mortgage) => {
        if (mortgage.isMortgageePrivateLender() || mortgage.isMortgageCorrespondWithSolicitor()) {
          let matterParticipant = matter.matterParticipants.find((mp: MatterParticipant) => {
            return mp.mortgageId == mortgage.id && mp.isMortgageeAttention();
          });
          if (matterParticipant) {
            matter.removeMatterParticipant(matterParticipant);
          }
        }
      });
    }
  }

  static cleanUpInsurerOrBroker(matter: Matter) {
    if (matter) {
      if (matter.fireInsuranceContactInfo && matter.fireInsuranceContactInfo.insuranceBrokerType === 'BROKER') {
        if (matter.insurerMatterParticipant) {
          matter.deleteMatterParticipant(matter.insurerMatterParticipant);
        }
      } else {
        if (matter.brokerMatterParticipant) {
          matter.deleteMatterParticipant(matter.brokerMatterParticipant);
        }
        if (matter.fireInsuranceContactInfo) {
          matter.fireInsuranceContactInfo.companyInsurerName = null;
        }
      }
    }
  }

  static cleanUpMortgageParticipant(matter: Matter) {
    if (matter) {
      // Clean up matter participants whose mortgage are deleted.
      if (matter.matterParticipants) {
        matter.matterParticipants.filter(item => item.mortgageId).forEach(
          matterParticipant => {
            if (matterParticipant && matterParticipant.mortgageId && matter.mortgages) {
              let indexInMortgages = matter.mortgages.findIndex(item => item.id == matterParticipant.mortgageId);
              let indexInExistingMortgages = matter.existingMortgages.findIndex(item => item.id == matterParticipant.mortgageId);
              if (indexInMortgages < 0 && indexInExistingMortgages < 0) {
                //Removing Child participants before deleting the parent
                matter.getChildMatterParticipants(matterParticipant).forEach(childParticipant => {
                  (<any>matter.matterParticipants).remove(childParticipant);
                });
                (<any>matter.matterParticipants).remove(matterParticipant);
              }
            }
          }
        );
      }
    }
  }

  static cleanUpMortgageSolicitorOrLegalFirm(matter: Matter, matterParticipantRole: string): void {
    if (matter) {
      const matterParticipants = matter.matterParticipants;
      if (!Array.isArray(matterParticipants) || matterParticipants.length === 0) {
        return;
      }
      // Clean up matter MORTGAGE_SOLICITOR and MORTGAGE_LEGAL_FIRM participants
      // when "Are we acting for the Mortgagee? is not 'No'" and  "Correspond with? is not 'Solicitor'"
      let participants: MatterParticipant[] = [];
      if (matterParticipantRole === MatterParticipantRoleTypes.MORTGAGE_SOLICITOR) {
        participants = matter.mortgageSolicitors;
      }
      if (matterParticipantRole === MatterParticipantRoleTypes.MORTGAGE_LEGAL_FIRM) {
        participants = matter.mortgageLegalFirms;
      }
      if (Array.isArray(participants)) {
        participants.forEach(
          participant => {
            if (participant && participant.mortgageId && Array.isArray(matter.mortgages)) {
              let index = matter.mortgages.findIndex(item => item.id == participant.mortgageId);
              if (index > -1) {
                // Checking acting flag only for purchase matter
                if ((matter.isPurchase && matter.mortgages[ index ].acting !== DpBooleanValueTypes.NO)
                  || matter.mortgages[ index ].mortgageCorrespondenceType !== MortgageCorrespondenceTypes.SOLICITOR) {
                  (<any>matterParticipants).remove(participant);
                }
              }
            }
          }
        );
      }
    }
  }

  static isOccupancyDateVisible(matter: Matter): boolean {
    if (matter && matter.isMatterProvinceON) {
      if (matter.matterPropertyWithCondo
        && !matter.matterPropertyWithCondo.isPropertyCondominium()
        && matter.matterPropertyWithCondo.isPropertyParcelOfTiedLand()
        && matter.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome()) {
        return true;
      }
    }
    if (matter.provinceCode == 'ON' || matter.provinceCode == 'NB' || matter.provinceCode == 'NS') {
      if (matter.matterPropertyWithCondo) {
        let selectedProperty: MatterProperty = matter.matterPropertyWithCondo;
        return selectedProperty.purchaseIsOfCode == 'NEW_BUILDER_HOME' && selectedProperty.isCondominium == 'YES';
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  static cleanUpMatterOpening(matter: Matter): void {
    if (matter) {
      // needed for Province MB, Sk, if closingDate is not full, then reset the possessionTime field
      if (!Utils.isFullDate(matter.matterCloseDate)) {
        matter.possessionTime = DEFAULT_POSSESSION_TIME;
      }
      if (matter.closed === DpBooleanValueTypes.N_y || matter.closed === DpBooleanValueTypes.NO) {
        matter.closingFileNumber = '';
      }

      // The RequisitionDate field will be renamed to Occupancy Date for the province of Ontario
      if (!matter.needOccupancyDate) {
        matter.requisitionDate = null;
      }

      if (!matter.requisitionDate) {
        if (!matter.isMortgage) {
          matter.requisitionSubmitted = 'N_y';
        }
      }
      if (matter.registrationMethodCode !== 'Electronic') {
        matter.teraviewDocketIdentifier = '';
      }
      if (matter.isProjectSale && !matter.isTemplateMatterForMassUpdate && matter.teraviewDocketIdSameAsFileNo() && !matter.teraviewDocketIdentifier && matter.fileNumber && matter.teranetDocket && !matter.teranetDocket.teraviewDocketIdentifier) {
        matter.teranetDocket.teraviewDocketIdentifier = matter.fileNumber.substring(0, 10).trim().toUpperCase();
      }
      if (matter.isMatterActive) {
        matter.fileInactiveDate = '';
        matter.fileInactiveNotes = '';
      }
      if (Array.isArray(matter.matterProperties) && matter.matterProperties.length > -1) {
        if (!this.isOccupancyDateVisible(matter)) {
          matter.occupancyDate = '';
        }
      }
      if (matter.isClosingDayTypeCashtoClose() && matter.closingDatePayment) {
        matter.closingDatePayment = undefined;
      }
      if (matter.closingDatePayment && !matter.closingDatePayment.isLateClosing()) {
        matter.closingDatePayment.lateClosingDate = '';
        matter.closingDatePayment.lateInterestAmountTotal = 0;
        matter.closingDatePayment.lateInterestDescription = '';
        matter.closingDatePayment.lateClosingInterests = [];
        matter.closingDatePayment.daysToCalculate = '';
        matter.lateClosingText = '';

      }

      if (!matter.teranetDocket) {
        matter.teranetDocket = new TeranetDocket();
      }
      if (!matter.teranetDocket.teraviewDocketIdentifier) {
        matter.teranetDocket.teraviewDocketIdentifier = matter.getMatterDocketValue();
      }

      if (!matter.needOccupancyCompleted) {
        matter.occupancyCompleted = DpBooleanValueTypes.N_y;
      }

      if (!matter.needIsReleaseDateSameAsOccupancyDate) {
        matter.isReleaseDateSameAsOccupancyDate = DpBooleanValueTypes.Y_n;
        matter.releaseDate = null;
      }

      // The RequisitionDate field will be renamed to Occupancy Date for the province of Ontario
      if (matter.needIsReleaseDateSameAsOccupancyDate && (matter.isReleaseDateSameAsOccupancyDate != DpBooleanValueTypes.NO)) {
        matter.releaseDate = matter.requisitionDate;
      }

      if (!matter.matterFlagged && !matter.isMatterReady) {
        matter.matterFlaggedReason = null;
      }

      if (!matter.isDocumentToBeSignedRemotely()) {
        matter.documentsToBeSignedDigitally = DpBooleanValueTypes.N_y;
      }

      if (!matter.isDocumentToBeSignedDigitally()) {
        matter.digitalSignaturePlatform = undefined;
      }
    }
  }

  static cleanUpMatterInfo(matter: Matter) {
    if (matter) {
      // Vendors-solicitor
      if (matter.uffiWarrantyCode !== 'LIMITED_TIME') {
        matter.survivalAfterClosing = null;
      }

      if (matter.reportToPurchaser &&
        ((matter.transactionTitleInsuredCode === dropDowns.yesNoCustom1[ 0 ].value)
          || (matter.transactionTitleInsuredCode === dropDowns.yesNoCustom1[ 4 ].value))) {
        matter.reportToPurchaser.titleInsurancePolicy = null;
      }

      // matter.resetMainClientsCapacity();
      // if(matter.isProjectSale && matter.isMatterProvinceAB) {
      //     matter.resetMainDevelopersCapacity();
      // }

      // fix for issue with cc on R1.0; please remove after R1.1
      if (matter.selectedSolicitorId) {
        matter.selectedSolicitorId = Number(matter.selectedSolicitorId);
      }
      if (matter.selectedLawClerkId) {
        matter.selectedLawClerkId = Number(matter.selectedLawClerkId);
      }

      // Vendors-solicitor

      if (!matter.isSale && matter.matterContactInfo && matter.matterContactInfo.postClosingAddress) {
        if (matter.isMortgage && matter.matterContactInfo && matter.matterContactInfo.residingAtSubjectProperty
          && matter.matterContactInfo.residingAtSubjectProperty.startsWith('N')) {
          // console.log('matter.matterContactInfo : ', matter.matterContactInfo);
          // Keep postClosingAddress
        } else {
          if (matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode !== AddressTypes.manuallyEntered) {
            const addressBeforeSave: Address = matter.matterContactInfo.postClosingAddress;
            matter.matterContactInfo.postClosingAddress = new Address();
            matter.matterContactInfo.postClosingAddress.id = addressBeforeSave.id;
            matter.matterContactInfo.postClosingAddress.sameAsAddressTypeCode = addressBeforeSave.sameAsAddressTypeCode;
          }
        }
      }

      if (matter.actingForBothPartiesInMortgageMatter) {
        if (!matter.isMortgage) {
          matter.deleteMatterParticipant(matter.otherPartySolicitor);
        }
        matter.deleteMatterParticipant(matter.otherPartyLawFirm);

        if (matter.otherPartyContactInfo) {
          matter.otherPartyContactInfo.resetValues();
        }

        if (matter.directDepositInstructionOtherParty) {
          matter.directDepositInstructionOtherParty.directDepositChoice = 'N_y';
          matter.directDepositInstructionOtherParty.cleanUpBankAccount();

        }
      }

      if (matter.matterContactInfo) {
        AddressUtil.populateParsedData(matter.matterContactInfo.postClosingAddress);
        AddressUtil.populateParsedData(matter.matterContactInfo.preClosingAddress);
      }

      // Clean up purchaser Broker Dear and Broker Attention when commissionPaid is not "BOTH_VENDOR_AND_PURCHASER_BROKER"
      if (matter.isSale && !matter.commissionPaidToBothVendorAndPurchaserBroker) {
        MatterBrokerMatterLevelInfoUtil.removePurchaserBrokersMatterLevelInfo(matter);
      }
    }
  }

  static cleanUpBankAccount(matter: Matter) {
    if (matter) {
      if (Array.isArray(matter.directDepositInstructions)) {
        matter.directDepositInstructions.forEach((item: DirectDepositInstruction) => {
          item.cleanUpBankAccount();
        });
      }
      // if(matter.excessFundsDirectDepositChoice && matter.excessFundsDirectDepositChoice == 'NO') {
      //     matter.excessFundsDirectDepositAccount = null;
      //     matter.excessFundsDirectDepositDate = null;
      // }
    }
  }

  static cleanUpStatementOfAccount(matter: Matter, matterService?: MatterService) {
    if (matter) {
      if (matter.soaTrustLedgerCollection) {
        if (matter.soaTrustLedgerCollection.matter) {
          matter.updateInterimTrustLedger();
          matter.soaTrustLedgerCollection.createUpdatePropertyTaxPaidTrustLedgerStatementAdjustmentPS();
        }
        matter.matterSoas = (matter.soaTrustLedgerCollection.allFees
        .concat(matter.soaTrustLedgerCollection.allPurchaseDisbursementsAdditional)
        .concat(matter.soaTrustLedgerCollection.allPurchaseDisbursementsHST))
        .concat(matter.soaTrustLedgerCollection.allPurchaseDisbursementsNotHST)
        .concat(matter.soaTrustLedgerCollection.allPurchaseDisbursementsPST)
        .concat(matter.soaTrustLedgerCollection.allPurchaseDisbursementsGSTandPST)
        .concat(matter.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesGST)
        .concat(matter.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesPST)
        .concat(matter.soaTrustLedgerCollection.allPurchaseTaxableOtherChargesGSTandPST)
        .concat(matter.soaTrustLedgerCollection.allPurchaseNonTaxableOtherCharges);
        matter.matterTrustLedgers = matter.soaTrustLedgerCollection.allMatterTrustLedgers;

      }
      matter.secondarySoaSheetsCollection.forEach(collection => {
        if (collection.sheetId > 0) {
          let matterSheet = matter.secondarySoaSheets.find(item => item.id == collection.sheetId);
          if (matterSheet) {
            matterSheet.soaItems = (collection.allFees
            .concat(collection.allPurchaseDisbursementsAdditional)
            .concat(collection.allPurchaseDisbursementsHST))
            .concat(collection.allPurchaseDisbursementsNotHST)
            .concat(collection.allPurchaseDisbursementsPST)
            .concat(collection.allPurchaseDisbursementsGSTandPST)
            .concat(collection.allPurchaseTaxableOtherChargesGST)
            .concat(collection.allPurchaseTaxableOtherChargesPST)
            .concat(collection.allPurchaseTaxableOtherChargesGSTandPST)
            .concat(collection.allPurchaseNonTaxableOtherCharges);
          }
        }
      });

    }
  }

  static cleanUpCondoCorporation(matter: Matter): void {
    if (matter) {
      if (matter.condoCorporation && (matter.condoCorporation.contact.selfManagedManagementCompanyType !== 'MANAGEMENT_COMPANY')) {
        if (matter.managementCompany) {
          matter.removeMatterParticipant(matter.managementCompany);
        }
        matter.condoCorporation.contact.sourceParentOrganizationId = null;
        matter.condoCorporation.contact.isDirty = true;
      }
    }
  }

  static cleanUpFireInsurance(matter: Matter): void {
    if (matter) {
      if (matter.fireInsuranceContactInfo) {
        if (!matter.fireInsuranceContactInfo.documentationType) {
          matter.fireInsuranceContactInfo.documentationValue = '';
        }
        if (matter.fireInsuranceContactInfo.addresses && matter.fireInsuranceContactInfo.addresses.length > 0) {
          AddressUtil.populateParsedData(matter.fireInsuranceContactInfo.addresses[ 0 ]);
        }
      }
    }
  }

  static cleanUpReportToPurchaser(matter: Matter): void {
    if (matter) {
      if (matter.reportToPurchaser && !matter.reportToPurchaser.isPropertyTaxInfoInReportYes()) {
        matter.reportToPurchaser.mostRecentTaxBill = null;
        matter.reportToPurchaser.vendorPaidEntireTaxBill = null;
        matter.reportToPurchaser.moniesPaidFromTrustForTaxes = null;
        matter.reportToPurchaser.taxPaymentAmount = null;
        matter.reportToPurchaser.reFuturePayments = null;
        matter.reportToPurchaser.matterPaymentInstallments = null;

      } else {
        if (matter.reportToPurchaser && !matter.reportToPurchaser.isReFuturePaymentsReportingLetter()) {
          matter.reportToPurchaser.matterPaymentInstallments = null;
        }
        if (matter.reportToPurchaser && !matter.reportToPurchaser.isMoniesPaidFromTrustForTaxesYes()) {
          matter.reportToPurchaser.taxPaymentAmount = null;
        }
      }
    }
  }

  static cleanUpUndertaking(matter: Matter) {
    if (matter) {
      if (matter.provinceCode === PROVINCE_CODES.ONTARIO) {
        if (Array.isArray(matter.matterUndertakings)
          && matter.matterUndertakings.length > -1) {
          for (let i = 0; i < matter.matterUndertakings.length; i++) {
            if (matter.matterUndertakings[ i ].matterUndertakingStatus && matter.matterUndertakings[ i ].matterUndertakingStatus.trim() === '') {
              matter.matterUndertakings[ i ].matterUndertakingStatus = 'OUTSTANDING';
            }
            if (matter.matterUndertakings[ i ].matterUndertakingStatus === 'OUTSTANDING') {
              matter.matterUndertakings[ i ].dischargedByInstrumentNo = null;
              matter.matterUndertakings[ i ].registeredDate = null;
            }
          }
        }
      }
    }
  }

  static cleanUpMatterProperties(matter: Matter) {
    if (matter) {
      if (matter.matterPropertyWithCondo) {
        const matterProperty = matter.matterPropertyWithCondo;
        if (matter.isOpportunityMatter()) {
          this.cleanUpOpportunityMatterProperty(matter);
        }

        if (matterProperty && matterProperty.address) {
          matterProperty.address.addressTypeCode = 'MAILING';
        }
        // matterProperty.purchasePrice = matter.utils.removeCurrencySymbol(matterProperty.purchasePrice);
        if (matterProperty.isPropertyCondominium() && !matter?.isMatterProvinceBC) {
          matter.removeAdditionalMatterProperty();
          matterProperty.isCondoCleanWorkAsSave();
        } else {
          matterProperty.isNotCondoCleanWorkAsSave();
        }

        // currently user only allowed to choose condoCorp Mp and managementCompany Mp in 'Condo Corporation' page
        // and this page get enabled either isCondo = Yes or isPotl = Yes - Bug27259
        if (!matter.isCondoCorporationTabEnabled) {
          matter.removeMatterParticipant(matter.condoCorporation);
          matter.removeMatterParticipant(matter.managementCompany);
        }

        if (matter.isMatterProvinceABorMB && matterProperty.separateTaxAdjustmentByRollNumber) {
          matterProperty.matterTax = undefined;
        }

        if (!matter.matterPropertyWithCondo.isPropertyTypeMultiUnit()) {
          matter.matterPropertyWithCondo.numOfUnits = undefined;
        }
        if (!matter.matterPropertyWithCondo.isTitleInsurancePropertyTypeMultiUnit()) {
          matter.matterPropertyWithCondo.titleInsuranceNumOfUnits = undefined;
        }
        AddressUtil.populateParsedData(matter.matterPropertyWithCondo.address);
        if (matter.isProjectSale && matter.isMatterProvinceON && matter.project && matter.project.docRegistration) {
          matter.matterPropertyWithCondo.registryOrLt = matter.project.docRegistration.registryOrLt;
        }

        if (matter.templateForProject) {
          // do the cleanup for template matter
          if (matter.matterPropertyWithCondo.isPropertyCondominium() && !matter.matterPropertyWithCondo.occupancyDeposit) {
            // for condo project, there should be always has one paidOnOccupancy deposit
            matter.initializePaidOnOccupancyDeposit();
          }
        }
        //Move clean rollNumber from component. We shouldn't clean it up before saving.
        this.cleanUpRollNumber(matter.matterPropertyWithCondo);
      }

      if (Array.isArray(matter.matterProperties) && matter.matterProperties.length > -1) {
        if (matter.matterProperties[ 0 ] && matter.matterProperties[ 0 ].isPropertyCondominium() && !matter.isMatterProvinceABorMBorSKorNSorNB) {
          matter.matterProperties[ 0 ].surveyDate = '';
          matter.matterProperties[ 0 ].surveyor = null;
          matter.deleteAllMatterParticipantByRole('SURVEYOR');
        }
        if (matter.matterProperties[ 0 ] && (matter.matterProperties[ 0 ].isParcelOfTiedLand === 'NO' || matter.matterProperties[ 0 ].isParcelOfTiedLand === 'N_y')) {
          matter.matterProperties[ 0 ].nameOfCondominiumPlan = '';
          matter.matterProperties[ 0 ].percentageInterest = null;
          matter.matterProperties[ 0 ].commonExpenses = null;
        }
        if (matter.matterProperties[ 0 ] && matter.matterProperties[ 0 ].purchaseIsOfCode !== 'NEW_BUILDER_HOME') {
          matter.matterProperties[ 0 ].newHomeWarranty = '';
          matter.matterProperties[ 0 ].builderNumber = '';
          matter.matterProperties[ 0 ].propertyNumber = '';
        }
        if (matter.matterProperties[ 0 ] && matter.matterProperties[ 0 ].purchaseIsOfCode !== 'MOBILE_HOME') {
          matter.matterProperties[ 0 ].mobileHomeBrand = '';
          matter.matterProperties[ 0 ].mobileHomeYear = '';
          matter.matterProperties[ 0 ].mobileHomeSerial = '';
          matter.matterProperties[ 0 ].mobileHomeModel = '';
        }

        if (matter.matterProperties[ 0 ].address) {
          // address.unitNo is a getter that parses the street line 1 and extracts unitNo from it.
          // Copying it's value into another field 'unitNumber' on address so it can be passed to backend.
          matter.matterProperties[ 0 ].address.unitNumber = matter.matterProperties[ 0 ].address.unitNo;
        }
      }
      // for stewart title integration
      matter.setStgByPassUnitValidation();
      matter.populateParsedDataForBlanketMortgageProperties();

      // Field 'Form 4 Addressed To' only applicable to PS matter and also isCondo
      if (matter.isProjectSale && matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPropertyCondominium()) {
        if (matter.form4AddressedTo == null) {
          matter.form4AddressedTo = Form4AddressedTo.OFFERORS;
        }
      } else {
        matter.form4AddressedTo = null;
      }
    }
  }

  static cleanUpRollNumber(matterProperty: MatterProperty) {
    if (matterProperty.rollNumberType == ROLL_NUMBER_NOT_ASSIGNED || matterProperty.rollNumberType == ROLL_NUMBER_MULTIPLE) {
      matterProperty.rollNumber = RollNumber.createDefaultRollNumber();
    }
  }

  static cleanUpMatterContactInfo(matter: Matter) {
    if (matter) {
      if (matter.matterContactInfo) {
        if (matter.matterContactInfo.envelopeSalutation) {
          matter.matterContactInfo.envelopeSalutation = matter.matterContactInfo.envelopeSalutation.substr(0, 200);
        }
        if (matter.matterContactInfo.dearText) {
          matter.matterContactInfo.dearText = matter.matterContactInfo.dearText.substr(0, 75);
        }
      }
    }
  }

  static cleanUpOtherPartyContactInfo(matter: Matter) {
    if (matter) {
      if (matter.isSale && !matter.isProjectSale) {
        if (matter.otherPartyContactInfo && matter.otherPartyContactInfo.residingAtSubjectProperty != DpBooleanValueTypes.NO) {
          matter.otherPartyContactInfo.clearServiceAddress();
        }
      }

      if (matter.otherPartyContactInfo) {
        matter.copyOtherSolicitorLawFirmValuesForContactInfo(matter.otherPartyContactInfo, matter.otherPartySolicitor, matter.otherPartyLawFirm);
      }
    }
  }

  static cleanUpOthers(matter: Matter) {
    if (matter) {
      if (matter.clientReLine == '') {
        matter.clientReLine = null;
      }

      // DPPMP-10568
      if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.isPurchaseTypeVacantLand() && matter.isPurchaseTypeValueChanged) {
        matter.uffiWarrantyCode = 'NONE';
      }

      if (!matter.isMortgageMatterAndMortgageePrimary()) {
        matter.lenderReLine = null;
      }
    }
  }

  static cleanUpSupplementalTasks(matter: Matter): void {
    if (matter) {
      if (Array.isArray(matter.supplementalTasks) && matter.supplementalTasks.length > -1) {
        for (let i = 0; i < matter.supplementalTasks.length; i++) {
          if (matter.supplementalTasks[ i ].categoryName === 'ILA' && matter.supplementalTasks[ i ].isSolicitorFromMatterOpening()) {
            matter.supplementalTasks[ i ].supplementalTaskCategoryContact = null;
          }
        }
      }
    }
  }

  static cleanUpMatterTaxRate(matter: Matter) {
    if (matter) {
      let supplementalTaskTaxRates = matter.matterTaxRates.filter(item => item.isSupplementalTaskTaxRate());
      if (supplementalTaskTaxRates && supplementalTaskTaxRates.length) {
        supplementalTaskTaxRates.forEach((item) => {
          let supplementalTask = matter.supplementalTasks.find(suppTask => suppTask.id == item.matterSupplementalTaskCategoryId);
          if (!supplementalTask) {
            (<any>matter.matterTaxRates).remove(item);
          }
        });
      }
    }
  }

  static cleanUpBrokerCommission(matter: Matter) {
    if (matter) {
      if (matter.brokerCommission) {
        if (matter.commissionBasedOnFixedPercentageOfSalePrice === '' && !matter.isMatterProvinceAB) {
          matter.brokerCommission = new BrokerCommission();
          matter.brokerCommission.totalCommissionPercentage = 0;
          if (matter.brokerCommission.vendorCommission) {
            matter.brokerCommission.vendorCommission.vendorBrokerPercentageOfCommission = 0;
          }
          if (matter.brokerCommission.purchaserCommission) {
            matter.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission = 0;
          }
          if (matter.brokerCommission.vendorCommission) {
            matter.brokerCommission.vendorCommission.vendorBrokerCommission = 0;
          }
          if (matter.brokerCommission.purchaserCommission) {
            matter.brokerCommission.purchaserCommission.purchaserBrokerCommission = 0;
          }
          // matter.depositHeldBy = constValues.depositHeldBy.VENDOR_BROKER;
          matter.brokerCommission.appliedTowardsPaymentOfCommission = matter.isMatterProvinceBC ? DpBooleanValueTypes.YES
            : BrokerCommissionDropDowns.appliedTowardsPaymentOfCommissionOptions[ 0 ].value;
        }
        if (matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO) {
          matter.brokerCommission.totalCommissionPercentage = 0;
        }
        if (matter.commissionPaidTo !== constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER
          || (matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.NO)) {
          if (matter.brokerCommission.purchaserCommission) {
            matter.brokerCommission.purchaserCommission.purchaserBrokerPercentageOfCommission = 0;
          }
        }
        if (matter.commissionPaidTo !== constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER
          || matter.commissionBasedOnFixedPercentageOfSalePrice === DpBooleanValueTypes.YES) {
          if (matter.brokerCommission.purchaserCommission) {
            matter.brokerCommission.purchaserCommission.purchaserBrokerCommission = 0;
          }
        }
        // if(matter.commissionPaidTo === constValues.commissionPaidTo.VENDOR_BROKER_ONLY){
        //     matter.depositHeldBy = constValues.depositHeldBy.VENDOR_BROKER;
        // }
      }

      if (matter.commissionPaidTo !== constValues.commissionPaidTo.BOTH_VENDOR_AND_PURCHASER_BROKER) {
        matter.separateEntriesForCommissionPaidToTrustLedger = DpBooleanValueTypes.Y_n;
      }
    }
  }

  static cleanUpDirectionReFunds(matter: Matter) {
    if (matter) {
      matter.directionReFunds = [];
      matter.directionReFunds.push(...(matter._interimDirectionReFunds || []));
      matter.directionReFunds.push(...(matter._finalDirectionReFunds || []));

      // $ field population will be cleared if there is no description or description is removed after population
      MatterCleanUpUtil.cleanFundsPayableToWithoutReFundName(matter.directionReFunds);
      if (matter._interimDirectionReFunds && matter._interimDirectionReFunds.length) {
        MatterCleanUpUtil.cleanupInterimOrFinalDirectionReFunds(matter._interimDirectionReFunds);
      }
      if (matter._finalDirectionReFunds && matter._finalDirectionReFunds.length) {
        MatterCleanUpUtil.cleanupInterimOrFinalDirectionReFunds(matter._finalDirectionReFunds);
      }
    }
  }

  static cleanupInterimOrFinalDirectionReFunds(directionReFunds: DirectionReFund[]): void {
    // System will remove the blank rows only if they are at the end.
    // Any blank rows in the middle will be retained when saved.
    // The first two elements should not be removed.
    if (Array.isArray(directionReFunds) && directionReFunds.length > 2) {
      const lastDirectionReFund: DirectionReFund = directionReFunds[ directionReFunds.length - 1 ];
      const lastDirectionReFundIndex = directionReFunds.length - 1;
      if (lastDirectionReFund.directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO && !lastDirectionReFund.reFundName && !lastDirectionReFund.amount) {
        let i: number;
        for (i = lastDirectionReFundIndex; i > 1; i--) {
          if (directionReFunds[ i ].directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO && directionReFunds[ i ].reFundName) {
            break;
          }
        }
        directionReFunds.splice(i + 1, lastDirectionReFundIndex - i);
      }
    }
  }

  // $ field population will be cleared if there is no description or description is removed after population
  static cleanFundsPayableToWithoutReFundName(directionReFunds: DirectionReFund[]) {
    if (Array.isArray(directionReFunds)) {
      directionReFunds.forEach(item => {
        if (item.directionReFundType === DirectionReFundTypes.FUNDS_PAYABLE_TO && !item.reFundName) {
          item.amount = 0;
        }
      });
    }
  }

  static cleanUpTitleInsurance(matter: Matter) {
    if (matter) {
      if (matter.matterTitleInsurance && matter.matterTitleInsurance.clearTitleInsurance) {
        if (matter.isInsurerStewartTitle() || matter.isInsurerChicagoTitle() || matter.isInsurerFCT() || matter.isInsurerTitlePlus() || matter.isNotTitleInsured() || matter.isInsurerNotYetDetermined()) {
          matter.matterTitleInsurance = new MatterTitleInsurance();
        }
      }
      if (matter.matterTitleInsurance) {
        if (matter.isInsurerFCT()) {
          let fctMmsDealId: string = matter.getMmsDealIdFromFctEmp();
          if (fctMmsDealId) {
            matter.matterTitleInsurance.mmsDealId = fctMmsDealId;
            matter.matterTitleInsurance.mmsDealExists = DpBooleanValueTypes.YES;
          }
        } else if (matter.isInsurerStewartTitle() || matter.isInsurerTitlePlus()) {
          LegalDescriptionUtil.constructPinLegalDescriptions(matter);
        } else {
          matter.matterTitleInsurance.mmsDealExists = DpBooleanValueTypes.N_y;
          if (matter.isOtherTitleInsurer) {
            matter.matterTitleInsurance.cleanUpPolicyNo();
            matter.matterTitleInsurance.cleanUpMortgagePolicies();
          }
        }
        if (matter.matterTitleInsurance.mmsDealExists == DpBooleanValueTypes.N_y ||
          matter.matterTitleInsurance.mmsDealExists == DpBooleanValueTypes.NO) {
          matter.matterTitleInsurance.mmsDealId = null;
        }
      }

      if (matter.matterPropertyWithCondo) {
        if (!((matter.isInsurerStewartTitle() || matter.isInsurerTitlePlus()) && matter.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome())) {
          matter.overrideHomeOwnerPolicyAmount = false;
          matter.matterPropertyWithCondo.homeOwnerPolicyAmount = null;
        } else if ((matter.isInsurerStewartTitle() || matter.isInsurerTitlePlus()) && matter.matterPropertyWithCondo.isPurchaseTypeNewBuilderHome() && !matter.overrideHomeOwnerPolicyAmount) {
          matter.matterPropertyWithCondo.homeOwnerPolicyAmount = null;
        }
      }
    }
  }

  static cleanUpPstRebateForm(matter: Matter) {
    if (matter) {
      if (matter.isMatterProvinceSK) {
        const pstRebateFormNewHomeMatterForm: MatterForm = matter.pstRebateFormNewHomeMatterForm;
        if (pstRebateFormNewHomeMatterForm && pstRebateFormNewHomeMatterForm.newHomePstRebateForm) {
          // vendor or purchaser is change, update the related fields  when save matter.
          // vendor or purchaser is deleted, remover the related fields when save matter.
          const newHomePstRebateForm: NewHomePstRebateForm = pstRebateFormNewHomeMatterForm.newHomePstRebateForm;
          // Update the fields with the latest primary vendor data
          if (newHomePstRebateForm.builderInfo && newHomePstRebateForm.builderInfo.builderType == BuilderTypes.SAME_AS_VENDOR) {
            const vendors: MatterParticipant[] = matter.isPurchase ? matter.otherSideClients : matter.mainClients;
            newHomePstRebateForm.cleanUpBuilderTypeFields(vendors.length, vendors.find(item => item.primary));
          }
          // If "Assign to  a single Purchaser" field selected a purchaser, this purchaser is removed in Tab B or Tab C.
          // newHomePstRebateForm.purchaserId should be cleaned up when sve matter
          matter.updateSinglePurchaserField(newHomePstRebateForm);
          // If "Purchaser Information" field selected a purchaser, this purchaser is removed in Tab B or Tab C.
          // The related fields should be cleaned up, when sve matter
          matter.updatePurchaserInformationSection(newHomePstRebateForm);
          // Update this fields with the latest salePrice data
          if (matter.pstRebateFormNewHomeMatterForm && matter.pstRebateFormNewHomeMatterForm.newHomePstRebateForm) {
            matter.pstRebateFormNewHomeMatterForm.newHomePstRebateForm.updateNewHomePstRebateForm(matter.matterPropertyWithCondo, matter.considerationLtt && matter.considerationLtt.salePriceAdjustment, matter.soaFederalHst, matter.soaProvincialHst, matter);
          }
        }
      }
    }
  }

  static cleanUpERegistrationForms(matter: Matter) {
    if (matter) {
      matter.eRegistrationForms.forEach(item => {
        if (item.eregistrationData) {
          if (item.isTransfer() && !item.eregistrationData.isDocumentTypePowerOfSale()) {
            item.eregistrationData.instrumentNo = undefined;
          }
          item.eregistrationData.cleanupParticipantInfo(matter);
          item.eregistrationJson = JSON.stringify(item.eregistrationData);
        }
      });
    }
  }

  static cleanUpSoaTrustLedgerCollectionMatterRef(matter: Matter) {
    if (matter && matter.soaTrustLedgerCollection) {
      matter.clearCircularReferencesBeforeStringify();
    }
  }

  static cleanUpClosingData(matter: Matter) {
    if (matter && !matter.hasPastClosingDate()) {
      matter.closingFileNumber = null;
      matter.closed = DpBooleanValueTypes.N_y;
    }
  }

  static cleanUpMatterEvents(matter: Matter) {
    MatterEventUtils.createUpdateMatterEvents(matter);
  }

  static cleanupUIOnlyFields(matter: Matter) {
    if (matter) {
      matter.matterBurgerMenu = undefined;
      matter.isCopyInProgress = undefined;
      matter.selectedSolicitorId = undefined;
      matter.selectedLawClerkId = undefined;
      matter.selectedWitnessId = undefined;
      matter.soaTrustLedgerCollection = new SoaTrustLedgerCollection(undefined);
      matter.selectedCommissionerId = undefined;
      matter._finalDirectionReFunds = undefined;
      matter._interimAdjustments = undefined;
      matter._finalAdjustments = undefined;
      matter._interimAdjustmentsUnApplied = undefined;
      matter._finalAdjustmentsUnApplied = undefined;
      matter._interimDirectionReFunds = undefined;
      matter.readyForUI = undefined;
      matter.referralId = undefined;
      matter.isSolicitorOrLawClerkDirty = undefined;
      matter.isTemplateMatterForMassUpdate = undefined;
      matter.project = undefined;
      //cleanup the accountCodeArray in the matterSoa
      if (Array.isArray(matter.matterSoas)) {
        matter.matterSoas.forEach(matterSoa => matterSoa.accountCodeArray = []);
      }
    }
  }

  static restoreUIOnlyFields(matter: Matter, backupMapForCleanupUIOnlyField: Map<string, any>) {
    if (matter && backupMapForCleanupUIOnlyField) {
      matter.matterBurgerMenu = backupMapForCleanupUIOnlyField.get('matterBurgerMenu');
      matter.isCopyInProgress = backupMapForCleanupUIOnlyField.get('isCopyInProgress ');
      matter.selectedSolicitorId = backupMapForCleanupUIOnlyField.get('selectedSolicitorId');
      matter.selectedLawClerkId = backupMapForCleanupUIOnlyField.get('selectedLawClerkId =');
      matter.selectedWitnessId = backupMapForCleanupUIOnlyField.get('selectedWitnessId');
      matter.soaTrustLedgerCollection = backupMapForCleanupUIOnlyField.get('soaTrustLedgerCollection');
      matter.selectedCommissionerId = backupMapForCleanupUIOnlyField.get('selectedCommissionerId');
      matter._finalDirectionReFunds = backupMapForCleanupUIOnlyField.get('finalDirectionReFunds');
      matter._interimAdjustments = backupMapForCleanupUIOnlyField.get('interimAdjustments');
      matter._finalAdjustments = backupMapForCleanupUIOnlyField.get('finalAdjustments');
      matter._interimAdjustmentsUnApplied = backupMapForCleanupUIOnlyField.get('interimAdjustmentsUnApplied');
      matter._finalAdjustmentsUnApplied = backupMapForCleanupUIOnlyField.get('finalAdjustmentsUnApplied');
      matter._interimDirectionReFunds = backupMapForCleanupUIOnlyField.get('interimDirectionReFunds');
      matter.readyForUI = backupMapForCleanupUIOnlyField.get('readyForUI');
      matter.referralId = backupMapForCleanupUIOnlyField.get('referralId');
      matter.isSolicitorOrLawClerkDirty = backupMapForCleanupUIOnlyField.get('isSolicitorOrLawClerkDirty ');
      matter.isTemplateMatterForMassUpdate = backupMapForCleanupUIOnlyField.get('isTemplateMatterForMassUpdate');
      matter.project = backupMapForCleanupUIOnlyField.get('project');
    }
  }

  // removes Transferors from matter template if "Is the Transfer coming from a third party" is not "YES"
  static cleanUpProjectTransferors(matter: Matter) {
    if (matter && matter.templateForProject && matter.project && matter.project.docRegistration) {
      if (matter.project.docRegistration.isTransferFromThirdParty.toUpperCase() !== 'YES' && matter.transferors.length > 0) {
        matter.transferors.forEach(transferor => matter.removeMatterParticipant(transferor));
      }
    }
  }

  /*
  * Just to be organized,
  * This section is for Opportunity cleanup
  * ************** START OPPORTUNITY CLEANUP ******************
  * */
  static cleanUpOpportunityAssignedTo(matter: Matter) {
    if (matter) {
      matter.deleteMatterParticipantByRole(MatterParticipantRoleTypes.OPPORTUNITY_ASSIGNEE);
      matter.selectedOpportunityAssignedTo = null;
    }
  }

  static cleanUpOpportunityMortgages(matter: Matter): void {
    if (!matter.isOpportunityExistingMortgageSectionVisible() && matter.existingMortgages && matter.existingMortgages.length) {
      matter.deleteExistingMortgage(matter.existingMortgages[ 0 ]);
    }
    if (!matter.isOpportunityNewMortgageSectionVisible() && matter.mortgages && matter.mortgages.length) {
      matter.deleteMortgage(matter.mortgages[ 0 ]);
      matter.opportunity.mortgageNotes = '';
    }
  }

  static cleanupBlanketMortgageProperties(mortgage: Mortgage, matter: Matter) {
    if (matter.isMatterProvinceAB) {
      if (!DpBooleanValueTypes.isTrue(mortgage.isBlanketMortgage) || !mortgage.isLoanTypeArranged()) {
        mortgage.isBlanketMortgage = 'N_y';
        matter.matterProperties.filter(matterProperty => matterProperty.mortgageId == mortgage.id).forEach(item => {
          (<any>matter.matterProperties).remove(item);
        });
      }
    }
  }

  static cleanUpOpportunityMatterProperty(matter: Matter): void {
    if (matter.matterPropertyWithCondo) {
      let matterProperty = matter.matterPropertyWithCondo;
      if (matterProperty.address && !matter.isOpportunityPurchaseSaleMortgageOrDischarge) {
        matterProperty.address.resetValue();
      }
      if (!matter.isOpportunityPurchaseOrSale) {
        matterProperty.purchaseIsOfCode = 'QUESTION';
        matterProperty.purchasePrice = 0;
        matterProperty.depositAmount = 0;
        matter.opportunity.totalCommission = 0;
      }
    }
  }

  /*
  * ************** END OPPORTUNITY CLEANUP ******************
  * */
}
