import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Project} from '../../projects/shared/project';

@Directive({selector: '[dpProjectShowByProvince]'})
export class DpProjectShowByProvince implements OnInit {

  private viewCreated: boolean;
  private provinceKey: string;
  private provinceCode: string;
  private _project;
  Project;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  ngOnInit(): void {

  }

  get project(): Project {
    return this._project;
  }

  private enableSection() {
    let provinceKeyCode = this.provinceKey;

    const provinceCode = this.provinceCode ? this.provinceCode : (this.project ? this.project.provinceCode : undefined);
    if ((DpProjectShowByProvince.visibleFieldRestrictions[ provinceKeyCode ] &&
        DpProjectShowByProvince.visibleFieldRestrictions[ provinceKeyCode ].some(item => item == provinceCode)) ||
      (DpProjectShowByProvince.hiddenFieldRestrictions[ provinceKeyCode ] &&
        !(DpProjectShowByProvince.hiddenFieldRestrictions[ provinceKeyCode ].some(item => item == provinceCode)))) {
      if (!this.viewCreated) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.viewCreated = true;
      }
    } else {
      this.viewContainer.clear();
      this.viewCreated = false;
    }
  }

  @Input()
  set dpProjectShowByProvince(provinceKey: string) {
    if (provinceKey) {
      this.provinceKey = provinceKey;
      this.enableSection();
    }
  }

  @Input()
  set dpProjectShowByProvinceProject(project: Project) {
    if (project) {
      this._project = project;
      this.enableSection();
    }
  }

  // Visible for specific Province.
  static visibleFieldRestrictions: any = {
    'project.projectDetails.tarionNo': [ 'ON' ],
    'project.projectDetails.occupancyDateRequired': [ 'ON' ],
    'project.projectDetails.useSameDocketIdentifier': [ 'ON' ],
    'project.projectDetails.deliveryOfCashToClose': [ 'AB' ],
    'project.projectDetails.interestRate': [ 'AB' ],
    'project.projectDetails.POTL': [ 'ON' ],
    'project.docReg.ignoreCredits': [ 'ON', 'AB' ],
    'project.docReg.isTransferComeFromThirdParty': [ 'ON' ],
    'project.projectAdjustmentConfig.ignoreCreditInTarionAdjustment': [ 'ON' ],
    'project.projectAdjustmentConfig.hstReductionInSalePriceAdjustment': [ 'ON' ],
    'project.projectAdjustmentConfig.salePricePortionNotSubjectToHST': [ 'ON' ],
    'project.projectAdjustmentConfig.footerNotification': [ 'ON' ],
    'project.projectCondo.Location': [ 'ON' ],
    'project.projectCondo.exception': [ 'AB' ],
    'project.soa.modal.adjustmentPaidOnInterimClosing': [ 'ON', 'AB' ],
    'project.soa.modal.adj.fixed.infoOnly': [ 'ON' ],
    'project.soa.modal.sale.price.adj.infoOnly': [ 'ON', 'AB' ],
    'project.soa.modal.sale.price.adj.salePricePortionNotSubjectToHST': [ 'ON' ]

  };

  // Hidden for specific Province
  static hiddenFieldRestrictions: any = {
    'project.projectCondo.occupancyFeesCalculatedBasedOn': [ 'AB' ],
    'project.projectCondo.interestRateOnPurchaseMoniesSameForTransactions': [ 'AB' ],
    'project.projectCondo.interestRateChargeableOnPurchaseMonies': [ 'AB' ],
    'project.projectCondo.interestRateOnDeferredPurchaseMonies': [ 'AB' ],
    'project.projectCondo.declarationRegistrationDate': [ 'AB' ],
    'project.projectCondo.declarationRegistrationNo': [ 'AB' ]
  };

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }
}
