import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export type TeranetDocumentStatus = 'AVAILABLE_FOR_DOWNLOAD' | 'AVAILABLE_FOR_VIEW' | 'NOT_AVAILABLE';
export type TeranetDocumentFormat = 'PDF' | 'TIF';

export class TeranetDocument extends BaseEntity {
  id: number;
  documentNumber: string;
  documentName: string;
  documentFormat: TeranetDocumentFormat;
  numOfPages: number;
  downloaded: boolean; //indicates if document has been downloaded from teranet and stored in unity
  downloadedDate: string;

  get documentStatus(): TeranetDocumentStatus {
    if (this.downloaded) {
      return 'AVAILABLE_FOR_VIEW';
    } else if (this.numOfPages > 0) {
      return 'AVAILABLE_FOR_DOWNLOAD';
    } else {
      return 'NOT_AVAILABLE';
    }

  }

  get isDocumentAvailableForView(): boolean {
    return this.documentStatus === 'AVAILABLE_FOR_VIEW';
  }

  get isDocumentNotAvailable(): boolean {
    return this.documentStatus === 'NOT_AVAILABLE';
  }

  get isDocumentAvailableForDownload(): boolean {
    return this.documentStatus === 'AVAILABLE_FOR_DOWNLOAD';
  }

}
