import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {StatementAdjustmentUtil} from '../statement-adjustment-util';
import {StatementAdjustment} from '../statement-adjustment';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import Utils from '../../../shared-main/utils';
import {Matter} from '../../shared';

export class StatementAdjustmentDisplaySalePriceBuilder {

  static getDisplayItems(soAdj: SalePriceAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder, headingAdjument?: StatementAdjustment): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];

    if (headingAdjument && headingAdjument.isSalePriceAdjustmentHeading()) {
      if (headingAdjument.salePriceAdjustmentHeadingId && mainBuilder.matter &&
        mainBuilder.matter.considerationLtt && mainBuilder.matter.considerationLtt.salePriceAdjustment &&
        mainBuilder.matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings &&
        mainBuilder.matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.length) {
        let salePriceAdjustmentHeading = mainBuilder.matter.considerationLtt.salePriceAdjustment.salePriceAdjustmentHeadings.find(heading => heading.id == headingAdjument.salePriceAdjustmentHeadingId);
        if (salePriceAdjustmentHeading) {
          if (salePriceAdjustmentHeading.salePriceAdjustmentHeadingItems && salePriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length) {
            salePriceAdjustmentHeading.salePriceAdjustmentHeadingItems.forEach((item) => {
              sadItemDetails.push(mainBuilder.getNewItemLine(item.description, mainBuilder.getCurrency(item.cost), undefined, ``));
            });
          }
          const headingAmtType = salePriceAdjustmentHeading.cost >= 0 ? 'Vendor' : 'Purchaser';
          sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ headingAmtType }`, null, undefined, ``));
        }
      }
      return sadItemDetails;
    }

    // modal sends its own local copy of SalePriceAdjustment while the main screen will get it from salePriceAdj (matter.considerationLtt.salePriceAdjustment)
    let salePriceAdj: SalePriceAdjustment = soAdj;
    if (!salePriceAdj) {
      salePriceAdj = mainBuilder.salePriceAdjustment;
    }

    if (!salePriceAdj) {
      return sadItemDetails;
    }

    // TODO: we most likely do not need to tag this item line because the Agreed Sale Price can be easily obtained from the SalePriceAdjustmentDTO
    sadItemDetails.push(mainBuilder.getNewItemLine(`Agreed Sale Price`, mainBuilder.getCurrency(salePriceAdj.agreementSalePrice), undefined, `10165.agreedSalePrice`));

    if (salePriceAdj.additionalConsiderationsInclHst > 0) {

      let isTarionWarrantyAdjAvailableAndEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndEligibleForTaxRebate(mainBuilder.matter.statementOfAdjustments);
      let isHCRAAdjAvailableAndEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isHCRAAdjAvailableAndEligibleForTaxRebate(mainBuilder.matter.statementOfAdjustments);
      let isOtherFixedAdjAvailableAndEligibleForTaxRebate: boolean = StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(mainBuilder.matter.statementOfAdjustments);

      if (isTarionWarrantyAdjAvailableAndEligibleForTaxRebate || isOtherFixedAdjAvailableAndEligibleForTaxRebate || isHCRAAdjAvailableAndEligibleForTaxRebate) {
        sadItemDetails.push(mainBuilder.getNewItemLine(`+ Additional Consideration`,
          mainBuilder.getCurrency(salePriceAdj.additionalConsiderationsInclHst), false, '',
          '1', 'Ref1'));
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(`+ Additional Consideration`,
          mainBuilder.getCurrency(salePriceAdj.additionalConsiderationsInclHst), false, ''));
      }
    }

    if (salePriceAdj.creditsToPurchaserInclHst > 0) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`- Credits to ${ mainBuilder.purchaserLabel }`,
        mainBuilder.getCurrency(salePriceAdj.creditsToPurchaserInclHst)
      ));
    }

    if (salePriceAdj.otherItems > 0) {
      // Only add the item if it has a name.  If the name disappear, the line item will disappear as well.
      if (salePriceAdj.buyDowns) {
        sadItemDetails.push(mainBuilder.getNewItemLine(`- ${ salePriceAdj.buyDowns }`,
          mainBuilder.getCurrency(salePriceAdj.otherItems)));
      }
    }

    if (salePriceAdj.isTotalAdjusted()) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`= Total including ${ mainBuilder.appliedTaxRate }`, mainBuilder.getCurrency(salePriceAdj.totalPriceWithTax()),
        undefined, 'TotalPriceWithTax'));
    }

    let federalHstRate: number = salePriceAdj.calculateHstPortion(mainBuilder.federalHstRate);
    if (federalHstRate > 0 || (federalHstRate === 0 && salePriceAdj.isInclusivePrice())) {
      let federalPortionTitle: string = '';

      if (salePriceAdj.provinceCode == 'ON') {
        federalPortionTitle = `- ${ mainBuilder.appliedTaxRate } Federal Portion (${ mainBuilder.addDecimalsToPercent(mainBuilder.federalHstRate) }%)`;
      }
      if (salePriceAdj.provinceCode == 'AB' || salePriceAdj.provinceCode == 'MB' || salePriceAdj.provinceCode == 'SK') {
        federalPortionTitle = ' - GST';
      }
      if (salePriceAdj.provinceCode == 'NB' || salePriceAdj.provinceCode == 'NS') {
        let provinceHstRate: number = salePriceAdj.calculateHstPortion(mainBuilder.provinceHstRate);
        federalHstRate = Utils.roundCurrencyTCStyle(federalHstRate + provinceHstRate);
        federalPortionTitle = ' - HST';
      }
      sadItemDetails.push(mainBuilder.getNewItemLine(federalPortionTitle,
        // mainBuilder.getCurrency(salePriceAdj.calculateHstPortion(federalHstRate))
        mainBuilder.getCurrency(federalHstRate), undefined, 'HST.Fed'
      ));

    }

    let provinceHstRate: number = salePriceAdj.calculateHstPortion(mainBuilder.provinceHstRate);
    let provincialLabel: string = salePriceAdj.provinceCode == 'ON' ? 'Ontario' : 'Provincial';
    if (salePriceAdj.provinceCode != 'AB' && salePriceAdj.provinceCode != 'MB' && salePriceAdj.provinceCode != 'SK' &&
      (salePriceAdj.provinceCode == 'ON' && (provinceHstRate > 0 || (provinceHstRate === 0 && salePriceAdj.isInclusivePrice())))
    ) {

      sadItemDetails.push(mainBuilder.getNewItemLine(`- ${ mainBuilder.appliedTaxRate } ${ provincialLabel } Portion (${ mainBuilder.addDecimalsToPercent(mainBuilder.provinceHstRate) }%)`,
        mainBuilder.getCurrency(provinceHstRate), undefined, 'HST.Prov'
      ));
    }

    if (salePriceAdj.showRebateFederalPortion()) {
      let federalPortionTitle: string = '';
      if (salePriceAdj.provinceCode == 'AB' || salePriceAdj.provinceCode == 'MB' || salePriceAdj.provinceCode == 'SK' || salePriceAdj.provinceCode == 'NB' || salePriceAdj.provinceCode == 'NS') {
        federalPortionTitle = `+ ${ mainBuilder.appliedTaxRate } Rebate`;
      }
      if (salePriceAdj.provinceCode == 'ON') {
        federalPortionTitle = `+ ${ mainBuilder.appliedTaxRate } Rebate Federal Portion`;
      }
      sadItemDetails.push(mainBuilder.getNewItemLine(federalPortionTitle,
        // the codeKey notation 10158.fed is probably temporary till we learn if there is a specific corresponding code
        mainBuilder.getCurrency(salePriceAdj.calculateRebate(mainBuilder.federalHstRate)), undefined, `HSTRebate.Fed`
      ));
    }

    if (salePriceAdj.showRebateProvincialPortion()) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`+ ${ mainBuilder.appliedTaxRate } Rebate ${ provincialLabel } Portion`,
        // the codeKey notation 10158.prov is probably temporary till we learn if there is a specific corresponding code
        mainBuilder.getCurrency(salePriceAdj.calculateProvincialRebate(mainBuilder.provinceHstRate)), undefined, `HSTRebate.Prov`
      ));
    }

    if (salePriceAdj.additionalVendorConsidNotEligibleForTaxRebate > 0) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`+ Additional Consideration`,
        mainBuilder.getCurrency(salePriceAdj.additionalVendorConsidNotEligibleForTaxRebate), undefined, 'SalePrice.AdditionalConsideration', '2', 'Ref2'
      ));
    }

    if (salePriceAdj.additionalVendorConsidNotEligibleForTaxRebatePlusTax > 0) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`+ Additional Consideration`,
        mainBuilder.getCurrency(salePriceAdj.additionalVendorConsidNotEligibleForTaxRebatePlusTax), undefined, `10158.ToDo`, '3', 'Ref3'
      ));
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(`= Net Sale Price`, mainBuilder.getCurrency(salePriceAdj.totalNetSalePrice(mainBuilder.federalHstRate, mainBuilder.provinceHstRate)),
      undefined, 'NetSalePrice.Total'));

    if (salePriceAdj.isInclusivePrice()) {
      let firstPartOfSalePriceRebateSoa: StatementAdjustment = mainBuilder.matter.rebatePurchaserNotEligibleSoa;
      let salePrice: number = mainBuilder.matter.getSalePriceForTaxAdjustments(mainBuilder.federalHstRate, mainBuilder.provinceHstRate);
      if (firstPartOfSalePriceRebateSoa && firstPartOfSalePriceRebateSoa.soAdjCreditVendorTaxRebate) {
        sadItemDetails.push(mainBuilder.getNewItemLine(`+ ${ firstPartOfSalePriceRebateSoa.soAdjCreditVendorTaxRebate.adjustmentHeading }`,
          mainBuilder.getCurrency(salePriceAdj.totalRebatePortionWithTax(mainBuilder.provinceHstRate,
            mainBuilder.federalHstRate,
            salePrice,
            firstPartOfSalePriceRebateSoa.soAdjCreditVendorTaxRebate.isAdjustedEqualYes()
              ? firstPartOfSalePriceRebateSoa.soAdjCreditVendorTaxRebate.percentage
              : null))
        ));
      }
    }

    if (salePriceAdj.calculateRealEstateNSP(mainBuilder.federalHstRate, mainBuilder.provinceHstRate) > 0) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`Real Estate portion of NSP`,
        mainBuilder.getCurrency(salePriceAdj.calculateRealEstateNSP(mainBuilder.federalHstRate, mainBuilder.provinceHstRate)), undefined, `NetSalePrice.RealEstate`
      ));
    }

    if (salePriceAdj.chattelsIncludedInSalePrice > 0) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`Chattels Portion of NSP`,
        mainBuilder.getCurrency(salePriceAdj.chattelsIncludedInSalePrice), undefined, 'SalePrice.Chattels'));
    }

    if (salePriceAdj.excludeFromTax && salePriceAdj.excludeFromTax != 0) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`- Exclude from ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } calculation`,
        mainBuilder.getCurrency(salePriceAdj.excludeFromTax), undefined, `salePriceAdj.excludeFromTax`
      ));
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit ${ mainBuilder.vendorLabel } ${ salePriceAdj.isSalePriceFormatNSP() ? '(Net Sale Price)' : '(Agreed Sale Price)' }`, ``));

    return sadItemDetails;
  }

  static getCondominiumTotalSharePercentage(matter: Matter) {
    if (matter) {
      return matter.isMatterProvinceAB ? Number(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) : Number(matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
    }
    return 0;
  }

  // this is used on the sale price adjustment modal to display related TarionWarranty adjustments -> not needed on SOA Preview
  static getDetails1(statementOfAdjustments: StatementAdjustment[], mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let total: number = 0;

    statementOfAdjustments
    .filter(soAdj => soAdj.isTarionWarranty() && soAdj.soAdjTarionWarranty && soAdj.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
    .forEach(soAdjTarion => {
        sadItemDetails.push(mainBuilder.getNewItemLine(`TARION WARRANTY CORPORATION`,
          `(${ mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFee) } + ${ soAdjTarion.soAdjTarionWarranty.taxType })  ${ mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax) }`,
          false, '', '1'));

        total += Number(soAdjTarion.soAdjTarionWarranty.enrolmentFeePlusTax);
      }
    );

    statementOfAdjustments
    .filter(soAdj => soAdj.isHCRAFee() && soAdj.soAdjHCRAFee && soAdj.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
    .forEach(soAdjHCRAFee => {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdjHCRAFee.soAdjHCRAFee.adjustmentDescription,
          `(${ mainBuilder.getCurrency(soAdjHCRAFee.soAdjHCRAFee.enrolmentFee) } + ${ soAdjHCRAFee.soAdjHCRAFee.taxType })  ${ mainBuilder.getCurrency(soAdjHCRAFee.soAdjHCRAFee.enrolmentFeePlusTax) }`,
          false, '', '1'));

        total += Number(soAdjHCRAFee.soAdjHCRAFee.enrolmentFeePlusTax);
      }
    );
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed() && soAdj.soAdjOtherFixed && soAdj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
    .forEach(soAdj => {
        if (soAdj.soAdjOtherFixed.applyTaxBoolean) {
          let totalAmountIncludingGSTOrHST: number = Number(soAdj.soAdjOtherFixed.amount) + Number(soAdj.soAdjOtherFixed.getHstOrGstValueForAmount(soAdj.soAdjOtherFixed.amount, soAdj.provinceCode));
          sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixed.adjustmentHeading,
            `(${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.amount) }  + ${ soAdj.soAdjOtherFixed.taxLabel }) ${ mainBuilder.getCurrency(totalAmountIncludingGSTOrHST) }`,
            false, '', '1'));
          total += Utils.roundCurrency(totalAmountIncludingGSTOrHST);
        } else {
          sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixed.adjustmentHeading,
            `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.amount) }`,
            false, '', '1'));

          total += Number(soAdj.soAdjOtherFixed.amount);

        }

      }
    );
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy() && soAdj.soAdjOtherFixedPayableOnOccupancy && soAdj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
    .forEach(soAdj => {
      if (soAdj.soAdjOtherFixedPayableOnOccupancy.applyTaxBoolean || soAdj.soAdjOtherFixedPayableOnOccupancy.applyPstBoolean) {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentHeading,
          `(${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.amount) } + ${ soAdj.soAdjOtherFixedPayableOnOccupancy.taxLabel }) ${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount) }`,
          false, '', '1'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount);
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.amount) }`,
          false, '', '1'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.amount);
      }
    });
    const ctsp: number = this.getCondominiumTotalSharePercentage(mainBuilder.matter);
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherProratedOnPercentageInterest() && soAdj.soAdjOtherProratedOnPercentageInterest && soAdj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE')
    .forEach(soAdj => {
      if (soAdj.soAdjOtherProratedOnPercentageInterest.applyTaxBoolean || soAdj.soAdjOtherProratedOnPercentageInterest.applyPstBoolean) {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherProratedOnPercentageInterest.adjustmentHeading,
          `(${ mainBuilder.getCurrency(soAdj.soAdjOtherProratedOnPercentageInterest.getAmountForPercentageInterest(ctsp)) } + ${ soAdj.soAdjOtherProratedOnPercentageInterest.taxLabel }) ${ mainBuilder.getCurrency(soAdj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(ctsp)) }`,
          false, '', '1'));

        total += Number(soAdj.soAdjOtherProratedOnPercentageInterest.getTotalAmountPurchaserForPercentageInterest(ctsp));
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherProratedOnPercentageInterest.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherProratedOnPercentageInterest.getAmountForPercentageInterest(ctsp)) }`,
          false, '', '1'));

        total += Utils.roundNumber(soAdj.soAdjOtherProratedOnPercentageInterest.getAmountForPercentageInterest(ctsp));
      }
    });

    sadItemDetails.push(mainBuilder.getNewItemLine(`Total Additional Consideration (eligible for ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } Rebate), including ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) }`,
      mainBuilder.getCurrency(total), false));

    return sadItemDetails;
  }

  // this is used on the sale price adjustment modal to display related TarionWarranty adjustments -> not needed on SOA Preview
  static getDetails2(statementOfAdjustments: StatementAdjustment[], mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let total: number = 0;
    statementOfAdjustments
    .filter(soAdj => soAdj.isTarionWarranty() && soAdj.soAdjTarionWarranty && soAdj.soAdjTarionWarranty.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
    .forEach(soAdjTarion => {

        sadItemDetails.push(mainBuilder.getNewItemLine(`TARION WARRANTY CORPORATION`,
          mainBuilder.getCurrency(soAdjTarion.soAdjTarionWarranty.enrolmentFee),
          false, '', '2'));

        total += Number(soAdjTarion.soAdjTarionWarranty.enrolmentFee);
      }
    );

    statementOfAdjustments
    .filter(soAdj => soAdj.isHCRAFee() && soAdj.soAdjHCRAFee && soAdj.soAdjHCRAFee.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
    .forEach(soAdjHCRAFee => {

        sadItemDetails.push(mainBuilder.getNewItemLine(soAdjHCRAFee.soAdjHCRAFee.adjustmentDescription,
          mainBuilder.getCurrency(soAdjHCRAFee.soAdjHCRAFee.enrolmentFee),
          false, '', '2'));

        total += Number(soAdjHCRAFee.soAdjHCRAFee.enrolmentFee);
      }
    );

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed() && soAdj.soAdjOtherFixed && soAdj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixed.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.amount) }`,
          false, '', '2'));

        total += Number(soAdj.soAdjOtherFixed.amount);
      }
    );
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy() && soAdj.soAdjOtherFixedPayableOnOccupancy && soAdj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.amount) }`,
          false, '', '2'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.amount);
      }
    );
    const ctsp: number = this.getCondominiumTotalSharePercentage(mainBuilder.matter);
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherProratedOnPercentageInterest() && soAdj.soAdjOtherProratedOnPercentageInterest && soAdj.soAdjOtherProratedOnPercentageInterest.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE')
    .forEach(soAdj => {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherProratedOnPercentageInterest.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherProratedOnPercentageInterest.getAmountForPercentageInterest(ctsp)) }`,
          false, '', '2'));

        total += Number(soAdj.soAdjOtherProratedOnPercentageInterest.getAmountForPercentageInterest(ctsp));
      }
    );

    sadItemDetails.push(mainBuilder.getNewItemLine(`Total Additional Consideration (not eligible for ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } Rebate)`,
      mainBuilder.getCurrency(total), false));

    return sadItemDetails;
  }

  static getDetails3(statementOfAdjustments: StatementAdjustment[], mainBuilder: StatementAdjustmentDisplayBuilder): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let total: number = 0;

    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixed() && soAdj.soAdjOtherFixed && soAdj.soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX')
    .forEach(soAdj => {
      if (soAdj.soAdjOtherFixed.applyTaxBoolean || soAdj.soAdjOtherFixed.applyPstBoolean) {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixed.adjustmentHeading,
          `(${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.amount) } + ${ soAdj.soAdjOtherFixed.taxLabel }) ${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.totalAmount) }`,
          false, '', '3'));

        total += Number(soAdj.soAdjOtherFixed.totalAmount);
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixed.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixed.amount) }`,
          false, '', '3'));

        total += Number(soAdj.soAdjOtherFixed.amount);
      }
    });
    statementOfAdjustments
    .filter(soAdj => soAdj.isOtherFixedPayableOnOccupancy() && soAdj.soAdjOtherFixedPayableOnOccupancy && soAdj.soAdjOtherFixedPayableOnOccupancy.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX')
    .forEach(soAdj => {
      if (soAdj.soAdjOtherFixedPayableOnOccupancy.applyTaxBoolean || soAdj.soAdjOtherFixedPayableOnOccupancy.applyPstBoolean) {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentHeading,
          `(${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.amount) } + ${ soAdj.soAdjOtherFixedPayableOnOccupancy.taxLabel }) ${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount) }`,
          false, '', '3'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.totalAmount);
      } else {
        sadItemDetails.push(mainBuilder.getNewItemLine(soAdj.soAdjOtherFixedPayableOnOccupancy.adjustmentHeading,
          `${ mainBuilder.getCurrency(soAdj.soAdjOtherFixedPayableOnOccupancy.amount) }`,
          false, '', '3'));

        total += Number(soAdj.soAdjOtherFixedPayableOnOccupancy.amount);
      }

    });

    sadItemDetails.push(mainBuilder.getNewItemLine(`Total Additional Consideration (not eligible for ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) } Rebate incl. ${ ConsiderationTaxes.calculateRateTypeLabel(mainBuilder.matter.matterTaxType) })`, mainBuilder.getCurrency(total)));
    return sadItemDetails;
  }

}
