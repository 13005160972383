<!--modal-->
<div class="custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1>{{context && context.isAccountingSoftwareEsiLaw ? appConfig.isEsiLawIntegrationEnabled ? 'Prepare Unity® Accounting Sync' : 'Prepare esiLaw Sync' : 'Create account export files' }}</h1>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-12 ">
                <label class="control-label">{{successMessage}}</label>
            </div>
        </div>

        <div class="form-group" style="padding-top: 20px" *ngIf="soaConfigs.length - successfullyExportedMatters > 0">
            <label class="control-label col-lg-3">{{failureMessage}}</label>
            <div class="col-lg-2 height-100" style="overflow-y: scroll; border: solid 1px;">
                <table class="standard-table">
                    <tbody>
                    <tr *ngFor="let uiField of soaExportFailedForMatters;let i=index;">
                        <td>{{uiField}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons" >
            <button type="button"
                    (click)="cancel()"
                    class="dp-btn dp-btn-cancel">
                <span>Close</span>
            </button>
        </div>
    </div>
</div>
