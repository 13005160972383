import {Component, Inject, OnInit} from '@angular/core';
import {InvolvedParty, MatterOverviewService} from './matter-overview.service';
import {EventData} from '../../event/event-data';
import {OpportunityView} from '../../opportunities/opportunities-list/opportunity-view';
import {Contact, ContactService, Matter} from '../shared';
import {Telephone} from '../shared/telephone';
import Utils from '../../shared-main/utils';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';


export class PrintMatterOverviewModalContext {
  matter: Matter;
  matterOverviewService: MatterOverviewService;
}

@Component({
  selector: 'dp-print-matter-overview-modal-content',
  templateUrl: 'print-matter-overview-modal.component.html',
  styleUrls: ['./print-matter-overview-modal.component.scss']
})
export class PrintMatterOverviewModalComponent extends ModalComponent<PrintMatterOverviewModalContext> implements OnInit {
  involvedParties: InvolvedParty[];
  matterEvents: EventData[];
  opportunitiesList: OpportunityView[];
  lawClerkSolicitorList: Contact[];

  constructor(
    public dialog: MatDialogRef<PrintMatterOverviewModalComponent>,
    public contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) context?: PrintMatterOverviewModalContext
  ) {
    super(dialog, context);
  }

  close() {
    this.dialog.close();
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get matterOverviewService(): MatterOverviewService {
    return this.context.matterOverviewService;
  }

  ngOnInit() {
    this.initializeComponent();
  }

  initializeComponent() {
    this.initializeFields();
    this.addParticipantsToInvolvedParties();
    this.getMatterEvents();
    this.getLawClerkSolicitorList();
    this.getMatterOpportunities();
  }

  initializeFields() {
    this.involvedParties = [];
    this.matterEvents = [];
    this.lawClerkSolicitorList = [];
    this.opportunitiesList = [];
  }

  addParticipantsToInvolvedParties() {
    this.matterOverviewService.addParticipantsToInvolvedParties(this.matter, this.involvedParties);
  }

  getPartyPhones(telephones: Telephone[]): string {
    return this.matterOverviewService.getPartyPhones(telephones);
  }

  async getMatterOpportunities() {
    this.opportunitiesList = await this.matterOverviewService.getMatterOpportunities(this.matter);
  }

  async getMatterEvents() {
    if (this.matter.id) {
      this.matterEvents = await this.matterOverviewService.getMatterEvents(this.matter);
    }
  }

  convertToTitleCase(text: string) {
    return Utils.convertToTitleCase(text);
  }

  getLawClerkSolicitorList() {
    let obsSolicitorLawClerks: any[] = [];
    obsSolicitorLawClerks.push(this.contactService.getUpdatedSolicitorLawClerkList('SOLICITOR'));
    obsSolicitorLawClerks.push(this.contactService.getUpdatedSolicitorLawClerkList('LAWCLERK'));
    forkJoin(obsSolicitorLawClerks).subscribe((results) => {
      results.forEach((result: Contact[]) => {
        this.lawClerkSolicitorList.push(...(result || []));
      });
    });
  }

  getAppointmentScheduledFor(contactId: number): string {
    return this.matterOverviewService.getAppointmentScheduledFor(contactId, this.lawClerkSolicitorList);
  }

  getAppointEventSpecificDescInfo(eventData: EventData): string {
    return Utils.join([eventData.inviteeList, eventData.eventDocsStatusLabel], ', ');
  }
}
