import {Component, Inject, OnInit} from '@angular/core';
import {LtsaUser} from '../../../shared-main/ltsa/ltsa-user';
import {LtsaCredential} from '../../../shared-main/ltsa/ltsa-credential';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {LtsaService} from '../../../shared-main/ltsa/ltsa-service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ModalResult} from '../../../shared-main/enums';
import {DPError} from '../../../shared/error-handling/dp-error';
import {forgotUrl} from '../../../shared-main/ltsa/ltsa-constants';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

declare var jQuery: any;

class LoginLtsaModalContext {}

@Component({
  selector: 'dp-login-ltsa',
  templateUrl: 'login-ltsa.modal.component.html',
  providers: [ErrorService]
})
export class LoginLtsaModalComponent extends ModalComponent<LoginLtsaModalContext> implements OnInit {
  availableLtsaUsers: LtsaUser[] = [];
  ltsaCredential: LtsaCredential = new LtsaCredential();

  constructor(
    public dialog: MatDialogRef<LoginLtsaModalComponent>,
    public ltsaService: LtsaService,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: LoginLtsaModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit(): void {
    this.ltsaService.getUsers().subscribe((ltsaUsers: LtsaUser[]) => {
      if (ltsaUsers?.length > 0) {
        this.availableLtsaUsers = ltsaUsers;
        this.ltsaCredential.myLtsaUserName = ltsaUsers[0].ltsaLoginName;
      }
    });
    if (jQuery('.mat-modal-dialog').length > 1) {
      jQuery('.mat-modal-dialog:eq(-2)').hide();
    }
  }

  closeModal($event) {
    $event.stopPropagation();
    if (jQuery('.mat-modal-dialog').length > 1) {
      jQuery('.mat-modal-dialog:eq(-2)').show();
    }
    this.dialog.close({action: ModalResult.Cancel});
  }

  validate() {
    this.errorService.clearAllSaveErrors();
    this.ltsaService.authorize(this.ltsaCredential).subscribe(
      (result) => {
        this.dialog.close({action: ModalResult.OK});
      },
      (error) => {
        this.errorService.addDpSaveError(DPError.createDPError('ltsa.authorize.request.invalidCredentials'));
      }
    );
  }

  enabledValidateBtn() {
    return this.ltsaCredential?.myLtsaUserName && this.ltsaCredential?.myLtsaUserPassword;
  }

  isMultipleAvailableLtsaUsers() {
    return this.availableLtsaUsers.length > 1;
  }

  get forgotUrl() {
    return forgotUrl;
  }

  onEnterKeyDown($event) {
    if (this.enabledValidateBtn()) {
      this.validate();
    }
    $event.preventDefault();
    $event.stopPropagation();
  }
}
