import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementOfAdjustmentDisplayItemLine} from '../model';
import {StatementAdjustment} from '../statement-adjustment';
import {MatterTax} from '../../shared/property-taxes';
import {PaidByVendorStatementAdjustmentUtil} from '../statement-adjustment-paid-by-vendor-util';
import {Matter} from '../../shared/matter';

export class StatementAdjustmentDisplayTaxesVendorBuilder {

  static getDisplayItems(item: StatementAdjustment, mainBuilder: StatementAdjustmentDisplayBuilder, adjustmentStatusMode: string, closingDate: string): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let matter: Matter = mainBuilder.matter;
    let propertyTax: MatterTax = item.matterTax;
    propertyTax.updatePurchaserPortionValue(matter);
    let provinceCode = matter && matter.provinceCode ? matter.provinceCode : null;
    let labelForTaxesPaidByVendor: string = propertyTax ? propertyTax.getLabelForTaxesPaidByVendor(adjustmentStatusMode) : '';

    sadItemDetails.push(mainBuilder.getNewItemLine(`${ labelForTaxesPaidByVendor }`, `${ mainBuilder.getCurrency(propertyTax.totalTaxes) }`));
    if (propertyTax.baseTaxAdjustmentTaxBillType === 'PREVIOUS') {
      //Estimated increase for
      sadItemDetails.push(mainBuilder.getNewItemLine(`Estimated increase for ${ MatterTax.getAdjustmentDateYearRule(true, closingDate) }`, `${ mainBuilder.addDecimalsToPercent(propertyTax.estimatedPercentageIncrease) }%`));
      sadItemDetails.push(mainBuilder.getNewItemLine(`Estimated ${ MatterTax.getAdjustmentDateYearRule(true, closingDate) } taxes`, `${ mainBuilder.getCurrency(propertyTax.calculateEstimatedTaxes(0)) }`));
    }

    let labelForVendor: string = propertyTax.overrideVendorHasPaid ? propertyTax.overrideVendorHasPaidLabel : `${ mainBuilder.vendorLabel } has paid`;
    sadItemDetails.push(mainBuilder.getNewItemLine(`${ labelForVendor }`, `${ mainBuilder.getCurrency(propertyTax.vendorWillHavePaid) }`));

    if (matter && propertyTax) {
      //Unit Share Calculated at
      if (PaidByVendorStatementAdjustmentUtil.isUnitShareAvailable(matter, propertyTax)) {
        const unitShareLabels: string[] =
          PaidByVendorStatementAdjustmentUtil.getUnitShareInfoLabels(matter, propertyTax, mainBuilder.currencyPipe, mainBuilder.decimalPipe);
        if (Array.isArray(unitShareLabels) && unitShareLabels.length == 2) {
          sadItemDetails.push(mainBuilder.getNewItemLine(unitShareLabels[ 0 ], ``));
          let unitShareAmt = PaidByVendorStatementAdjustmentUtil.getUnitShareAmount(matter, propertyTax);
          if (unitShareAmt) {
            unitShareAmt = mainBuilder.getCurrency(+unitShareAmt);
          }
          sadItemDetails.push(mainBuilder.getNewItemLine(unitShareLabels[ 1 ], `${ unitShareAmt }`));
        }
      }

      //Purchaser Share
      if (PaidByVendorStatementAdjustmentUtil.isPurchaserShareFromOccupancyDataAvailable(matter)) {
        const purchaserShareLabels: string[] = PaidByVendorStatementAdjustmentUtil.getPurchaserShareInfoLabels(matter);
        if (Array.isArray(purchaserShareLabels) && purchaserShareLabels.length == 2) {
          sadItemDetails.push(mainBuilder.getNewItemLine(purchaserShareLabels[ 0 ], ``));
          let purchaserShareAmt = PaidByVendorStatementAdjustmentUtil.getPurchaserShareAmtValue(matter, propertyTax);
          if (purchaserShareAmt) {
            purchaserShareAmt = mainBuilder.getCurrency(+purchaserShareAmt);
          }
          sadItemDetails.push(mainBuilder.getNewItemLine(purchaserShareLabels[ 1 ], `${ purchaserShareAmt }`));
        }
      }
    }

    sadItemDetails.push(mainBuilder.getNewItemLine(`Credit Vendor `, ``));
    item.amount = PaidByVendorStatementAdjustmentUtil.calculateVendorShareForPaidByVendor(matter, propertyTax);

    return sadItemDetails;
  }

}
