import {Component, Inject, ViewChild} from '@angular/core';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {StatementOfAdjustmentHeading} from '../model/statement-adjustment-heading';
import {SelectItem} from 'primeng/api';
import {HstSalePrice} from '../model/hst-sale-price-soa';
import {dropDowns} from '../../shared/matter-drop-downs';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {SalePriceAdjustmentFactory} from '../sale-price-adjustment/sale-price-adjustment-factory';
import {Utils} from '../../shared';
import {ModalResult} from '../../../shared-main/enums';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class TaxOtherFactorModalContext {
  public salePrice: string;
  public closingDate: string;
  public vendorLabel: string;
  public provinceHstRateSlab: ConsiderationTaxes;

  public statementAdjustmentHeading: StatementOfAdjustmentHeading;
  public hstSalePriceSoa: HstSalePrice;
  public matterProvinceCode: ProvinceCode;
  public adjustmentStatusMode: string;
  public sourceFromProject: boolean;
  public taxRateType: string;
}

@Component({
  selector: 'dp-tax-other-factor-modal-content',
  templateUrl: './tax-other-factor.modal.component.html',
  providers: [ErrorService]
})
export class TaxOtherFactorModalComponent extends ModalComponent<TaxOtherFactorModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  calculateUsingList: SelectItem[];
  hstSalePriceLocal: HstSalePrice;
  closingDateLabel: string = 'Closing Date';
  salePriceAdjustmentLocal: SalePriceAdjustment;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TaxOtherFactorModalComponent>,
    public dialogService: DialogService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: TaxOtherFactorModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.calculateUsingList = dropDowns.calculateUsingTypeOptions;
    this.calculateUsingList[0].label = 'Credit to ' + this.context.vendorLabel + ' amount';
    if (this.context.closingDate) {
      this.closingDateLabel = Utils.formatDate(this.context.closingDate);
    }

    if (!this.context.hstSalePriceSoa) {
      this.hstSalePriceLocal = new HstSalePrice(this.context.taxRateType);
      this.hstSalePriceLocal.hstType = 'FACTOR_IN_HST_REBATE';
      this.newAdj = true;
    } else {
      this.hstSalePriceLocal = new HstSalePrice(this.context.taxRateType, this.context.hstSalePriceSoa);
    }

    if (!this.hstSalePriceLocal.calculateUsingType) {
      this.hstSalePriceLocal.calculateUsingType = 'CREDIT_AMOUNT';
    }

    if (!this.hstSalePriceLocal.adjustmentHeading) {
      this.hstSalePriceLocal.adjustmentHeading = '';
    }

    if (!this.hstSalePriceLocal.creditAmount) {
      this.hstSalePriceLocal.creditAmount = 0;
    }

    this.salePriceAdjustmentLocal = SalePriceAdjustmentFactory.getSalePriceAdjustment(
      this.context.adjustmentStatusMode,
      this.context.matterProvinceCode
    );

    this.calculateRebate();
  }

  get isCalcUsingTaxOutPrice(): boolean {
    return this.hstSalePriceLocal && this.hstSalePriceLocal.calculateUsingType == 'TAX_OUT_PRICE';
  }

  getTaxOutPrice(): number {
    return this.salePriceAdjustmentLocal.isSalePriceFormatNSP()
      ? this.salePriceAdjustmentLocal.totalNetSalePrice(this.federalHstRate, this.provinceHstRate)
      : this.salePriceAdjustmentLocal.agreementSalePrice;
  }

  calculateRebate() {
    if (this.hstSalePriceLocal.creditAmount >= 0) {
      this.salePriceAdjustmentLocal.agreementSalePrice = this.hstSalePriceLocal.creditAmount;
      this.salePriceAdjustmentLocal.netOutHstFromHSTSalePrice = this.isCalcUsingTaxOutPrice
        ? 'NO'
        : 'YES_FACTOR_IN_HST_REBATE';
      this.salePriceAdjustmentLocal.salePriceAdjustmentFormat = 'CREDIT_VENDOR_NET_SALE_PRICE';
      this.salePriceAdjustmentLocal.federalPortion = true;
      this.salePriceAdjustmentLocal.provincialPortion = true;
    }
  }

  checkForErrors() {
    this.modalErrorComponent.removeDpFieldError('matter.soa.taxotherfactor.adjustheader');
    if (this.hstSalePriceLocal.adjustmentHeading == '') {
      this.modalErrorComponent.createDPFieldError('matter.soa.taxotherfactor.adjustheader');
    }
  }

  ok(): void {
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.hstSalePriceLocal);
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  isTaxRateGst(): boolean {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.provinceDefaultTaxRate == 'GST';
  }

  get appliedTaxRate(): string {
    return this.isTaxRateGst() ? 'GST' : this.context.provinceHstRateSlab.rateTypeLabel;
  }

  get federalHstRate(): number {
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstFederalPortion
      ? this.context.provinceHstRateSlab.hstFederalPortion
      : 0;
  }

  get provinceHstRate(): number {
    // ON: take in consideration both portions
    // AB: there is no provincial rate so this would return 0
    // MB and SK have the provincial tax BUT they follow AB's model therefore forcing this to 0
    if (this.context.matterProvinceCode == 'MB' || this.context.matterProvinceCode == 'SK') {
      return 0;
    }
    return this.context.provinceHstRateSlab && this.context.provinceHstRateSlab.hstProvincialPortion
      ? this.context.provinceHstRateSlab.hstProvincialPortion
      : 0;
  }
}
