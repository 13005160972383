import {FlaData} from './../shared/fla-data';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '../../core/httpClient.service';
import {Injectable} from '@angular/core';
import {matterApi, matterResponseKey, TelephoneTypes, Utils} from '../shared';
import {Contact} from '../shared/contact';
import {MunicipalityConfiguration} from '../shared/municipality-configuration-type';
import {Constants} from '../../shared-main/constants';
import {AuthZService} from '../../core/authz/auth-z.service';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {Address} from '../shared/address';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {MatterType} from '../shared/index';

@Injectable()
export class PurchaserService {

  utils;

  constructor(private http: HttpClient, private authZService: AuthZService) {
    this.utils = new Utils();
  }

  // Get municipality configuration types
  getMunicipalityConfigurationTypes(): Observable<MunicipalityConfiguration> {
    return this.http.get(matterApi.municipalityConfigurationTypes)
    .map((res: any) => {
      return new MunicipalityConfiguration(res);
    });
  }

  // Get data of family law act from back end
  getFlaStatements(provinceCode?: ProvinceCode, matterType?: MatterType): Observable<FlaData> {
    let url = matterApi.flaStaements;
    if (provinceCode) {
      url += `?provinceCode=${ provinceCode }`;
      url += matterType ? `&matterType=${ matterType }` : '';
    } else {
      url += matterType ? `?matterType=${ matterType }` : '';
    }

    return this.http.get(url)
    .map((res: any) => {
      return new FlaData(res);
    });
  }

  // Check if the contact type allowed to be added to Offerors
  isContactAllowed(type): boolean {
    return true;
  }

  appendGenderFilter(genderSearch?: string[]): string {
    let genderFilters: string = '';
    if (Array.isArray(genderSearch)) {
      for (let i = 0; i < genderSearch.length; i++) {
        if (i === 0) {
          genderFilters = '|gender_IN_';
        }
        if (i === (genderSearch.length - 1)) {
          genderFilters += Utils.escapeSearchText(genderSearch[ i ]);
        } else {
          genderFilters += Utils.escapeSearchText(genderSearch[ i ]) + '!';
        }

      }
    }
    return genderFilters;
  }

  // this code it too big due to requirement of the dppm 260 and 583.
  // there are lots of conditions for the purchaser contact.
  // as the elastic search is not implemented yet we are handling everything in ui.
  // this code will be reduced once the elastic search will be implemented.
  // this method call the backend api get a nested json.
  // and convert into a flat json as for the requirements.
  // sort the json filter the json on basic of conditions mentioned in the user story.
  // this page is under consideration this will be refactor
  // exactMatch will search for the displayName that starts with the exact query.
  getClientPurchaser(query: any, removeaddNewRecordLabel?: boolean, removeGenderTypes?: boolean, genderFilters?: any[], exactMatch?: boolean): Observable<any> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let searchQuery = exactMatch ? `displayName_EQ_${ Utils.escapeSearchText(query) }*` : `displayName_EQ_*${ Utils.escapeSearchText(query) }*`;
    let url = `${ matterApi.contactsList(accountId) }?contactType=CLIENT&filter=${ searchQuery }|activeFlag_IN_${ Utils.escapeSearchText('Y_n') }!YES`;
    url = url + this.appendGenderFilter(genderFilters);
    url = url + `&page=1&per_page=15&sort=displayName|ASC`;
    return this.http.get(url)
    .map((res: any) => {

      let omnibarData = [];

      if (res[ matterResponseKey.contacts ]) {

        res[ matterResponseKey.contacts ].map(item => {
          return new Contact(item);
        }).forEach(n => {

          let requiredFields: any = {};
          let addressToDisplay: string;

          if (n.address && n.address.length > 0) {

            let primaryAddress: Address = n.primaryAddress ? n.primaryAddress : n.address[ 0 ];

            requiredFields.addressLine1 = Utils.returnValidString(primaryAddress.addressLine1);

            requiredFields.addressLine2 = Utils.returnValidString(primaryAddress.addressLine2);

            requiredFields.city = Utils.returnValidString(primaryAddress.city);

            requiredFields.postalCode = Utils.returnValidString(primaryAddress.postalCode);

            requiredFields.country = Utils.returnValidString(primaryAddress.country);

            requiredFields.provinceName = Utils.returnValidString(primaryAddress.provinceName);

            addressToDisplay = Address.convertAddressToDisplayAddress(primaryAddress);

          } else {
            requiredFields.addressLine1 = '';
            requiredFields.addressLine2 = '';
            requiredFields.city = '';
            requiredFields.postalCode = '';
            requiredFields.country = '';
            requiredFields.provinceName = '';
            requiredFields.omnibarAddShow = '';
          }

          let nameToDisplay: string;
          let firstLastNames: string;
          let surnameLastFullName: string;
          if (n.contactName) {
            requiredFields.firstName = Utils.returnValidString(n.contactName.firstName);
            requiredFields.middleName = Utils.returnValidString(n.contactName.middleName);
            requiredFields.lastName = Utils.returnValidString(n.contactName.lastName);
            nameToDisplay = n.contactName.fullName;
            firstLastNames = n.contactName.firstLastNames;
            surnameLastFullName = n.contactName.surnameLastFullName;
          } else {
            requiredFields.firstName = '';
            requiredFields.lastName = '';
            requiredFields.middleName = '';
          }
          requiredFields.organizationName = n.organizationName;
          requiredFields.fullName = (nameToDisplay) ? nameToDisplay : Utils.returnValidString(n.nameOnDocuments);
          requiredFields.firstLastNames = (firstLastNames) ? firstLastNames : Utils.returnValidString(n.nameOnDocuments);
          requiredFields.surnameLastFullName = (surnameLastFullName) ? surnameLastFullName : Utils.returnValidString(n.nameOnDocuments);
          requiredFields.id = n.id;
          requiredFields.nameOnDocuments = Utils.returnValidString(n.nameOnDocuments);
          requiredFields.email = Utils.returnValidString(n.email);
          requiredFields.envelopeSalutationLine1 = Utils.returnValidString(n.envelopeSalutationLine1);
          requiredFields.envelopeSalutationLine2 = Utils.returnValidString(n.envelopeSalutationLine2);
          requiredFields.dear = Utils.returnValidString(n.dear);
          requiredFields.faxPhone = Utils.returnValidString(n.faxPhone);

          requiredFields.omnibarAddShow = `${ requiredFields.addressLine1 }${ requiredFields.addressLine2 } ,${ requiredFields.city }${ requiredFields.postalCode }${ requiredFields.country } ,${ requiredFields.provinceName }`;
          requiredFields.displayName = (nameToDisplay) ? nameToDisplay : (n.nameOnDocuments ? Utils.returnValidString(n.nameOnDocuments) : n.organizationName);
          requiredFields.displayAddress = (addressToDisplay) ? addressToDisplay : '';
          requiredFields.gender = n && n.gender;
          requiredFields.deceased = n && n.deceased;

          if (n.telephone) {
            requiredFields.telephone = n.telephone;
            let cellPhone = n.telephone.find(phone => phone.phoneTypeCode == TelephoneTypes.cell);
            requiredFields.cellPhone = cellPhone ? cellPhone.telephoneNumber : '';
          }
          /// if removeGenderTypes is true we set gender type restrictions on the omnibar results
          if (removeGenderTypes === true) {
            if (this.isContactAllowed(n.gender) === true) {
              omnibarData.push(requiredFields);
            }
          } else {
            omnibarData.push(requiredFields);
          }
        }); // 32
      }

      if (!removeaddNewRecordLabel && !this.authZService.isContactReadOnlyAccess()) {
        omnibarData.unshift(this.createNewRecordEntry(query, 'new'));
      }

      if (query === Constants.PURCHASER || query == Constants.OFFEROR) {
        return omnibarData;
      } else if (omnibarData.length === 0 || (omnibarData.length === 1 && omnibarData[ 0 ].displayName.indexOf(Constants.ADD_NEW_RECORD) > -1)) {
        omnibarData.push(this.createNewRecordEntry(query, 'none'));
      }
      return omnibarData;
    });
  }

  createNewRecordEntry(query: string, type: string): any {
    let dummyPurchaser: any = {};
    if (type === 'none') {
      dummyPurchaser.displayName = Constants.NO_RESULTS_FOUND;
    } else {
      dummyPurchaser.displayName = Constants.ADD_NEW_RECORD + `"${ query.trim() }"`;
      dummyPurchaser.typedName = `${ query.trim() }`;
    }
    return dummyPurchaser;
  }

}
