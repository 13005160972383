import {Matter} from './matter';
import {Injectable} from '@angular/core';
import {MatterTab} from '../matter-tab';
import {TabsService} from '../../core';
import {GetGlobalSaveModelService} from './get-global-save-model.service';

@Injectable()
export class MatterFactory {

  public tabService: TabsService;
  public getGlobalSaveModelService: GetGlobalSaveModelService;
  public previousMatter: Matter;

  constructor() {
  }

  public get value(): Matter {
    if (this.tabService && this.tabService.activeTab) {
      let matter = (this.tabService.activeTab as MatterTab).matter;
      return matter ? matter : this.previousMatter;
    }
    return this.previousMatter;
  }

  public set value(matter: Matter) {
    (this.tabService.activeTab as MatterTab).matter = matter;
    this.previousMatter = matter;
    //this.getGlobalSaveModelService.changeModel(matter);
  }

  public updateMatterStateAndNotify(matter: Matter) {
    (this.tabService.activeTab as MatterTab).matter = matter;
    this.previousMatter = matter;
    this.getGlobalSaveModelService.changeModel(matter);
  }
}

export const currentMatter: MatterFactory = new MatterFactory();
