<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-lg-10">
            <h1>Add Note</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="cancelDialog()" >&times;</button>
    </div>
    <div class=" modal-body form-horizontal">
        <div class="col-lg-12 padding-bottom-10" *ngIf="context.isShowNotesOnOpenVisible">
            <dp-checkbox fieldId="checkBoxOption" title="Check to display the notes tab when this matter is opened">
                <input
                    type="checkbox"
                    id="checkBoxOption"
                    [(ngModel)]="showNotesOnOpen"
                    [checked]="showNotesOnOpen"
                />
            </dp-checkbox>
            <label for="checkBoxOption" class="control-label vertical-align-label display-inline" title="Check to display the notes tab when this matter is opened">
                Show notes on open
            </label>
        </div>
        <div class="form-group">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                        <textarea class="form-control"  id="text-area-note"
                                  [(ngModel)]="textAreaNote" maxlength="1000" dp-error-validator [fieldKey]="'admin.notes.addJournalNote'"></textarea>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons text-center">
            <button type="button"
                    class="dp-btn" (click)="closeDialog()">
                <span>Ok</span>
            </button>
            <button type="button"
                    class="dp-btn dp-btn-cancel" (click)="cancelDialog()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
