import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class BrokerContactTypes {
  public static readonly SELLER_BROKER: BrokerContactType = 'SELLER_BROKER';
  public static readonly PURCHASER_BROKER: BrokerContactType = 'PURCHASER_BROKER';
}

export type BrokerContactType = 'SELLER_BROKER' | 'PURCHASER_BROKER';

export class BrokerMatterLevelInfo extends BaseEntity {
  constructor(brokerMatterLevelInfo?: BrokerMatterLevelInfo) {
    super(brokerMatterLevelInfo);
  }

  dear: string;
  attention: string;
  brokerContactType: BrokerContactType;
}
