import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {Matter} from './matter';
import {MassUpdateType} from '../../../app/projects/shared/project';
import {MassUpdateData} from '../../../app/shared/tabbing/mass-update-tab';

export type ProjectMatterUpdateAction = 'MASS_UPDATE' | 'PROJECT_UPDATE';
export type MassUpdateTransactionType = 'MATTERS_WITH_PROJECT_AND_MATTER_TEMPLATE' | 'ONLY_MATTERS' | 'ONLY_PROJECT_AND_TEMPLATE';

export class ProjectMatterUpdateTransaction extends BaseEntity {
  id: number;
  status: string;
  totalMattersCount: number;
  processedMattersCount: number; //updated and sent to be saved
  successfulMattersCount: number;
  failedMatterCount: number;
  successfullySendToProcess: number[] = [];
  targetMatters: Matter[] = [];
  linkedPurchaseMatters: Matter[] = [];
  projectMatterUpdateAction: ProjectMatterUpdateAction;
  projectMassUpdateData: ProjectMassUpdateData;
  adjustmentImportData: any;
  showProjectMatterUpdateStop: boolean = true;
  massUpdateTransactionId: number;
  tempErrorFlagIndex: number;
  performRollBackOrCommit: boolean; // For Testing
  updateOnlyMatters: boolean;
  massUpdateTransactionType: MassUpdateTransactionType;

  constructor(m?: ProjectMatterUpdateTransaction) {
    super(m);
    if (m && m.projectMassUpdateData) {
      this.projectMassUpdateData = new ProjectMassUpdateData(m.projectMassUpdateData);
    }
  }

  get completedMattersCount(): number {
    return this.successfulMattersCount + this.failedMatterCount;
  }

  isStopped(): boolean {
    return this.status == 'STOPPED';
  }

  stopMatterUpdate(): void {
    this.status = 'STOPPED';
  }

  markSuccessfulSend(matterId: number) {
    this.successfullySendToProcess.push(matterId);
    this.processedMattersCount++;
  }

  getRemainingMattersCountIncludingFailed(): number {
    return this.totalMattersCount - this.successfulMattersCount;
  }

  getRemainingMattersIncludingFailed(): Matter[] {
    return this.targetMatters.filter(value => this.successfullySendToProcess.indexOf(value.id) < 0);
  }

  isProjectMatterUpdateActionMassUpdate(): boolean {
    return this.projectMatterUpdateAction == 'MASS_UPDATE';
  }

  isProjectMatterUpdateActionProjectUpdate(): boolean {
    return this.projectMatterUpdateAction == 'PROJECT_UPDATE';
  }

  get projectMatterUpdateMessageAction(): string {
    if (this.isProjectMatterUpdateActionMassUpdate()) {
      return 'Mass update';
    }
    if (this.isProjectMatterUpdateActionProjectUpdate()) {
      return 'Project matter update';
    } else {
      return 'Matter update';
    }
  }

  shouldMassUpdateTransactionRolledBack(): boolean {
    return (this.failedMatterCount > 0 && !!this.massUpdateTransactionId);
  }

  shouldMassUpdateTransactionCommit(): boolean {
    return (this.failedMatterCount == 0 && !!this.massUpdateTransactionId && this.totalMattersCount == this.successfulMattersCount);
  }

  isTransactionFinished() {
    return this.status == 'COMMITTED' || this.status == 'ROLLED_BACK' || this.status == 'ROLLBACK_FAILED';
  }

}

export class ProjectMassUpdateData extends BaseEntity {

  templateMatter: Matter;
  massUpdateType: MassUpdateType;
  massUpdateData: MassUpdateData;

  constructor(m?: ProjectMassUpdateData) {
    super(m);
  }
}



