import {Injectable} from '@angular/core';
import {HttpClient} from '../../core/httpClient.service';
import {Logger} from '@nsalaun/ng-logger';
import {api} from '../../common/api';
import {AddressSourceType, Place} from '../../matters/shared/place';
import {Observable} from 'rxjs';
import {Location} from './address-form.component';
import {Constants} from '../constants';
import {dropDowns} from './drop-downs';

@Injectable()
export class AddressSearchService {
  constructor(private http: HttpClient, private logger: Logger) {

  }

  readonly baseUrl: string = `${ api }/reference/places`;

  //Gets address suggestions based on input string
  searchPlaces(input: string, addressSource: AddressSourceType, countryCode: string, location: Location): Observable<Place[]> {
    let url: string = `${ this.baseUrl }?source=${ addressSource }&countryCode=${ countryCode }&input=${ encodeURIComponent(input) }`;
    if (location) {
      url += '&location=' + location.toUrl();
    }
    return this.http.get(url)
    .map((res) => {
      let result = [];
      if (res[ 'Places' ]) {
        result = res[ 'Places' ].map(item => {
          return new Place(item);
        });
      }
      if (result.length == 0) {
        let place: Place = new Place();
        place.description = Constants.NO_RESULTS_FOUND;
        result.push(place);
      }
      return result;
    });
  }

  //Gets address suggestions based on last Id
  searchPlacesById(lastId: string): Observable<Place[]> {
    let url: string = `${ this.baseUrl }/findById/${ lastId }`;
    return this.http.get(url)
    .map((res) => {
      if (res[ 'Places' ]) {
        return res[ 'Places' ].map(item => {
          return new Place(item);
        });
      }
    });
  }

  //Gets detail info of the place selected from auto-complete
  getPlaceDetail(place: Place): Observable<Place> {
    let url: string = `${ this.baseUrl }/${ place.placeId }?source=${ place.source }`;
    return this.http.get(url)
    .map((res) => {
      if (res[ 'Place' ]) {
        return new Place(res[ 'Place' ]);
      }
    });
  }

  get constantNoResultValue() {
    return Constants.NO_RESULTS_FOUND;
  }

  getProvinceName(provinceCode): string {
    let provinceObj = dropDowns.provinceName.find((item: any) => item.value == provinceCode);
    return provinceObj ? provinceObj.label : '';
  }
}
