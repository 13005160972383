<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Referral</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">
        <div class="form-group row">
            <div class="col-lg-4">
                <label class="control-label text-left">Referral Type</label>
            </div>
            <div class="col-lg-4">
                <label class="control-label text-left">Referrer</label>
            </div>
            <div class="col-lg-3" >
                <label class="control-label text-left">Attention</label>
            </div>
            <div class="col-lg-1">
            </div>
        </div>
        <div class="form-group row"
             *ngFor="let referredByWrapper of referredByWrapperList; let j=index"
             [ngClass]="{'table-header-line' : j == 0}">
            <div class="col-lg-4">
                <select class="form-control" id="referredByManual"
                        name="referredByManual"
                        fieldKey="referredByWrapper.referredByType"
                        statusBarHelp
                        [(ngModel)]="referredByWrapper.referredByType"
                        (change)="referredByWrapper.referredByChange()">
                    <option *ngFor="let referredByContact of referredBy"
                            [value]="referredByContact.value">
                        {{referredByContact.label}}
                    </option>
                </select>
            </div>

            <div class="col-lg-4">
                    <p-autoComplete name="refferedBy-ref-autocomplete"
                                    placeholder="{{referredByWrapper.getPlaceholderForReferrers()}}"
                                    [(ngModel)]="referredByWrapper.selectedReferredBy"
                                    [class.autocomplete-searching]="referredByWrapper.referredByLoading"
                                    (ngModelChange)="referredByWrapper.onSelectedReferredByChange($event)"
                                    [suggestions]="referredByWrapper.refferedByDataOptions"
                                    (completeMethod)="referredByWrapper.searchReferredBy({event:$event, manualReferral: manualReferral})"
                                    maxlength="100" scrollHeight="350px"
                                    (change)="referredByWrapper.cleanUpReferredBy()"
                                    (keydown)="referredByWrapper.recordReferredByKeydowncode($event)"
                                    (keydown.space)="utils.handleSpaceKeyDropdown({event: $event, referredByWrapper: referredByWrapper, customPickListService: customPickListService}, referredByWrapper.selectedReferredBy, referredByWrapper.handleDropdownClickReferredByCustomPickList)"
                                    (keydown.enter)="utils.handleEnterKeyDropdown({event: $event, referredByWrapper: referredByWrapper, customPickListService: customPickListService}, referredByWrapper.selectedReferredBy, referredByWrapper.handleDropdownClickReferredByCustomPickList)"
                                    (onSelect)="dataSelectedReferredByTimeout(referredByWrapper)"
                                    [class.dropdown-arrow-refferedBy]="referredByWrapper.isReferredByManual()"
                                    [dropdown]="referredByWrapper.isReferredByManual() && !referredByWrapper.referredByLoading"
                                    (onDropdownClick)="referredByWrapper.handleDropdownClickReferredByCustomPickList({event: $event,referredByWrapper: referredByWrapper, customPickListService: customPickListService, manualReferral: manualReferral})"
                                    minLength="0" delay="1000"
                                    class="dropdown-arrow"
                                    [ngClass]="{'autocomplete-search-icon':(!referredByWrapper.isReferredByManual() && referredByWrapper.selectedReferredBy), 'showWideDropDown' : referredByWrapper.selectedReferredBy}"
                                    tabIndexIgnore>

                        <ng-template let-refferedByData pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'REAL_ESTATE_BROKER'"
                                [disableItem]="refferedByData">
                                                    <span
                                                        class="col-lg-6 text-overflow">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'REAL_ESTATE_AGENT'"
                                [disableItem]="refferedByData">
                                <span class="col-lg-6 text-overflow">{{refferedByData.fullName}}</span>
                                <span class="col-lg-6 text-overflow">{{refferedByData.email}}</span>

                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'SURVEYOR'"
                                [disableItem]="refferedByData">
                                                    <span
                                                        class="col-lg-6 text-overflow">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'CLIENT'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-3 text-overflow"
                                                          *ngIf="referredByWrapper.isPersonVisible(refferedByData)">{{refferedByData.fullName}}</span>
                                <span class="col-lg-3 text-overflow"
                                      *ngIf="!referredByWrapper.isPersonVisible(refferedByData)">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-3 text-overflow"
                                      *ngIf="refferedByData.gender  != 'QUESTION'">
                                                    {{refferedByData.gender }}
                                                </span>
                                <span class="col-lg-3 text-overflow"
                                      *ngIf="refferedByData.gender  == 'QUESTION'">

                                                </span>
                                <span class="col-lg-6 text-overflow" *ngIf="refferedByData">
                                                    {{refferedByData.omnibarAddShow}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'CONDO_CORP'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-6 text-overflow"
                                                          *ngIf="refferedByData.organizationName">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'INSURANCE_BROKER'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-6 text-overflow"
                                                          *ngIf="refferedByData.organizationName">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'MANAGE_COMP'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-6 text-overflow"
                                                          *ngIf="refferedByData.organizationName">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'MORTGAGE_BROKER'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-6 text-overflow"
                                                          *ngIf="refferedByData.organizationName">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'MORTGAGEE'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-6 text-overflow"
                                                          *ngIf="refferedByData.organizationName">{{refferedByData.organizationName}} {{refferedByData.transitNo ? ', branch: ' + refferedByData.transitNo : ''}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>

                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'PRIVATE_LENDER'"
                                [disableItem]="refferedByData">
                                                    <span class="col-lg-6 text-overflow"
                                                          *ngIf="isPersonVisible(refferedByData)">{{refferedByData.fullName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="!referredByWrapper.isPersonVisible(refferedByData)">{{refferedByData.organizationName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'SOLICITOR'"
                                [disableItem]="refferedByData">
                                                <span class="col-lg-6 text-overflow"
                                                      *ngIf="refferedByData.lastName">{{refferedByData.firstName}} {{refferedByData.middleName}} {{refferedByData.lastName}}</span>
                                <span class="col-lg-6 text-overflow"
                                      *ngIf="referredByWrapper.refferedByDataAddressNotEmpty(refferedByData)">
                                                    {{refferedByData.mailingAddress?.addressTextWithProvinceName}}
                                                </span>
                            </li>
                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                *ngIf="referredByWrapper.referredByType === 'MANUAL_ENTRY'"
                                [disableItem]="refferedByData">
                                <span class="omnibarLi100">{{refferedByData.customPickListItemValue}}</span>
                            </li>
                        </ng-template>
                    </p-autoComplete>
            </div>
            <div class="col-lg-3" [dp-read-only-block]="!referredByWrapper.referredBy || !referredByWrapper.showRefferedByAttention">
                <p-autoComplete id="refferedBy-attention-autocomplete"
                                name="refferedBy-attention-autocomplete"
                                [(ngModel)]="referredByWrapper.referredByAttention"
                                (ngModelChange)="enableSave()"
                                [suggestions]="referredByWrapper.attentionList"
                                [class.dropdown-arrow-refferedBy]="true"
                                [dropdown]="referredByWrapper.attentionDropdownArrowShow"
                                (keydown.space)="utils.handleSpaceKeyDropdown({event: $event, referredByWrapper: referredByWrapper}, referredByWrapper.referredByAttention, handleDropdownClickAttention)"
                                (keydown.enter)="utils.handleEnterKeyDropdown({event: $event, referredByWrapper: referredByWrapper}, referredByWrapper.referredByAttention, handleDropdownClickAttention)"
                                (onDropdownClick)="handleDropdownClickAttention({event: $event, referredByWrapper: referredByWrapper})"
                                [minLength]="0"
                                [delay]="1000"
                                maxlength="75"
                                scrollHeight="350px"
                                (onSelect)="onAttentionSelect($event, referredByWrapper)"
                                autocomplete="off"
                                tabIndexIgnore>
                    <ng-template let-contact pTemplate="item">
                        <div>{{getContactNameForAttention(contact)}}</div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="col-lg-1 padding-top-10" >
                <span *ngIf="isTrashVisible(j)">
                    <i class="fa fa-trash" (click)="removeReferredBy(referredByWrapper);" aria-hidden="true"></i>
                </span>
            </div>
        </div>
        <div class="form-group row padding-bottom-40">
            <div class="col-lg-2 text-left">
                     <span class="actionlink"
                           (click)="addNewReferredBy();">
                            <span>
                                <a href="javascript:void(0);">
                                    Add New
                                </a>
                            </span>
                            <span class="selector fa fa-plus-square-o"></span>
                       </span>

            </div>

            <div class="col-lg-10">

            </div>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="offset-lg-3 col-lg-6 buttons matter-button-center">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>Ok</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>

