<div class="container" *ngIf="isMassUpdateTabMatterLevel()">
    <div class="row">
        <div class="col-lg-12">
            <!-- left Nav -->
            <div
                class="col-lg-2 left-nav {{getSidebarFontSizeClass()}} {{getSidebarFontColor()}} {{getSidebarFontWeight()}}">
                <div *ngIf="matter?.isProjectSale && !isMassUpdateTab()" class="project-button">
                    <dp-dropdown-menu [dropDownItems]="projectDropDownItems"
                                      (itemClicked)="clickProjectDropDown($event)"
                                      [buttonLabel]="'Project: '+ (matterProjectName && matterProjectName.length>30? ((matterProjectName | slice:0:30)+'...'):(matterProjectName) )"
                    ></dp-dropdown-menu>
                </div>
                <div *ngIf="isConvertToMatterBtnVisible()" class="project-button">
                    <button class="dp-btn dp-btn-green"
                            [disabled]="isConvertToMatterBtnDisabled()"
                        (click)="openConvertOpportunityToMatterModal()">{{getConvertToMatterLabel()}}
                    </button>
                </div>
                <div class="top-border-nav-spacer"></div>
                <ul id="left-nav-matter">
                    <li *ngFor="let section of visibleSections; let tabIndex = index"
                        class="{{ (section.active ? 'active': 'inactive')}} {{ isSectionDisabled(section) || notYetImplemented(section.title) ? 'disabled':''}}" >

                        <a class="matter-topic-link-width" tabindex="0" (focusout)="isShortCutModeFocusOutFunction()"
                           (keydown.enter)="openMatterSection(section.route, section);isShortCutMode=false;"
                           (click)="openMatterSection(section.route, section);isShortCutMode=false;">
                            <div class="tab-icons">
                                <i *ngIf="isNotesAvailable(section)" class="svg-icon sticky-note small"></i>
                                <i *ngIf="!isNotesAvailable(section)" class="padding-left-10"></i>
                                <i *ngIf="isStatusAvailable(section)" [ngClass]="getActiveTopicStatusIcon(section)"></i>
                            </div>
                            <div class="tab-text">
                                <ng-container *ngIf="isShortCutMode">
                                    <span class="left-nav-shortcut-key bold">{{section.shortCutKey}}</span>
                                </ng-container>
                                <span [innerHtml]="getSectionTitle(section)"></span>
                            </div>


                        </a>


                    </li>
                </ul>
            </div>
            <!-- END - left Nav -->

            <!-- Main Content -->
            <div class="col-lg-10 matter-content-area">
                <dp-sticky-notes
                    [data]="activeSectionNotes"
                    (dataChanged)="updateActiveSectionNotes($event)"
                    (saveEvent)="enableSave()"
                ></dp-sticky-notes>
                <div class="matter-fixed-background"></div>
                <!--Because the fixed-header-statement-adjustments z-index is 105, we set the menu of div is 106 to let the menu item can be shown up-->
                <div class="matter-title z-index-6">
                    <div class="col-lg-12 matter-inner-title">
                        <h1>
                            <span class="text-overflow matter-title-width">
                                {{getSectionPageTitle(activeSection)}}
                                <div *ngIf="isOpportunityWorkItemSummaryVisible()" class="inline-block">
                                    <div *ngIf="numberOfWorkitemsTasks" class="inline-block"> -
                                        <div class="inline-block relative-position top-minus-5" [ngClass]="{'red': numberOfOverDueTasks > 0}">{{workItemsSummaryText}}</div>
                                    </div>
                                </div>
                            </span>
                            <ul class="right" *ngIf="!isMatterReadOnly">
                                <li *ngIf="!isMassUpdateTab() && projectMatters?.length>1" class="padding-top-5">
                                    <button *ngIf="isPreviousMatterBtnVisible()"
                                            class="matter-nav-btn"
                                            title="Previous Record in this Project - Shift + Page Up"
                                            (focus)="getFocusedElement($event)"
                                            (click)="goToPreviousMatter()">
                                        <i class="svg-icon previous"></i>
                                    </button>
                                    <button *ngIf="isNextMatterBtnVisible()"
                                            class="matter-nav-btn"
                                            title="Next Record in this Project - Shift + Page Down"
                                            (focus)="getFocusedElement($event)"
                                            (click)="goToNextMatter()">
                                        <i class="svg-icon next"></i>
                                    </button>

                                </li>
                                <ng-container *ngIf="!isMassUpdateTab()">
                                    <li class="top-2 text-center padding-left-5 padding-right-5"
                                        *ngIf="isAddNoteVisibleForMatter() && !isActiveSectionDuediligence()">
                                        <button
                                            class="dp-btn dp-btn-cancel margin-left-0"
                                            (click)="openAddNoteModal()"
                                        >Add Note</button>
                                    </li>
                                </ng-container>
                                <li class="top-2 text-center padding-right-5 " [pTooltip]="getNotes()"
                                    tooltipPosition="left" tooltipStyleClass="custom-tooltip"
                                    *ngIf="isStickyNoteVisibleForMatter()"
                                    (click)="showStickyNotes()">
                                    <i class="svg-icon sticky-note pointerCursor"></i>
                                    <h6 class="h6-sticky-notes">Sticky Notes</h6>
                                </li>
                                <li *ngIf="isClosingStatusVisibleForMatter()" class="padding-right-5">
                                    <div class="dropdown status-dropdown">
                                        <button class="btn btn-primary dropdown-item status-dropdown-btn closing-status-dropdown-btn"
                                                type="button" data-toggle="dropdown"><i
                                            class="inline-block padding-left-10 "
                                            [ngClass]="getActiveTopicClosingStatusIcon(activeSection)"></i>{{getMatterClosingStatus()}}
                                        </button>
                                        <ul class="dropdown-menu status-dropdown-menu">
                                            <li *ngFor="let matterTopicClosingStatus of  matterTopicClosingStatuses"><i
                                                class="inline-block"
                                                [ngClass]="getClosingStatusIconClass(matterTopicClosingStatus.value)"></i><a
                                                class="inline-block"
                                                (click)="updateMatterClosingStatus(matterTopicClosingStatus.value)">{{matterTopicClosingStatus.label}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <ng-container *ngIf="!isMassUpdateTab()">
                                    <li *ngIf="isStatusVisibleForMatter()" class="padding-right-5">
                                        <div class="dropdown status-dropdown">
                                            <button class="btn btn-primary dropdown-item status-dropdown-btn"
                                                    type="button" data-toggle="dropdown"><i
                                                class="inline-block padding-left-10 "
                                                [ngClass]="getActiveTopicStatusIcon(activeSection)"></i>{{getActiveTopicStatus()}}
                                            </button>
                                            <ul class="dropdown-menu status-dropdown-menu">
                                                <li *ngFor="let matterTopicStatus of matterTopicStatuses"><i
                                                    class="inline-block"
                                                    [ngClass]="getstatusIconClass(matterTopicStatus.value)"></i><a
                                                    class="inline-block"
                                                    (click)="updateActiveSectionStatus(matterTopicStatus.value)">{{matterTopicStatus.label}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="top-2 text-center padding-left-5 padding-right-5"
                                        *ngIf="matter && isActiveSectionOverview()"
                                        (click)="openPrintOverviewModel()">
                                        <i class="svg-icon print pointerCursor"></i>
                                        <h6 class="h6-sticky-notes">Print</h6>
                                    </li>
                                    <li>
                                        <button id="saveBtn"
                                                type="button"
                                                class="function-icons"
                                                [ngClass]="{'icon-locked' : isMatterLocked, 'icon-dirty' : (matter && matter.dirty && !(isMatterReadOnly || isMatterLocked))}"
                                                [disabled]="(matter && !matter.dirty || isMatterReadOnly || isMatterLocked || disableMatterForThirdPartyDataRefresh)"
                                                (click)="validateAndSaveMatterAndSubscribe()">
                                            <i class="svg-icon save margin-left-5"></i>
                                        </button>
                                        <h6
                                            [ngClass]="{'icon-locked' : isMatterLocked,'icon-dirty' :  (matter && matter.dirty && !(isMatterReadOnly || isMatterLocked))}">
                                            Save</h6>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="isMassUpdateTab()">
                                    <li class="padding-right-15">
                                        <button class="dp-btn-white project-btns" type="button" name="exitMassUpdateBtn"
                                                (click)="exitMassUpdate()"
                                                spellcheck="false">Exit
                                        </button>
                                    </li>
                                    <li>
                                        <button class="dp-btn-green project-btns" type="button"
                                                name="selectAffectedMattersBtn" (click)="validateTemplateMatter()"
                                                spellcheck="false">Select Affected Matters
                                        </button>
                                    </li>
                                </ng-container>
                            </ul>
                        </h1>

                    </div>
                    <div class="col-lg-12 matter-notifications">
                        <p *ngIf="isMatterLocked && !displayUnlockMessageForMatter"
                           class="smallerFont matter-notice-text">{{getMatterTitle()}} Locked by: {{matterLockedByUserName}}. All
                            fields are read
                            only.</p>
                        <p *ngIf="isLinkedMatterDirty" class="smallerFont matter-notice-text">Linked matter edited</p>
                        <p *ngIf="!isMatterLocked  && (isSelectedLinkTopicDisabled() || isMatterOpeningInLinkedPurchseMatter() || ( isUndertakingsInLinkedPurchseMatter() && !matter.isMatterProvinceON ))"
                           class="smallerFont matter-notice-text margin-top-minus-5">
                            {{linkedPurchaseDisabledMsg()}}
                            </p>
                        <p *ngIf="matter && matter.hasBillingFailed()" class="smallerFont matter-notice-text">All fields are read
                            only as matter billing has failed. Please close and re-open the matter once billing has been resolved.
                            <br>{{matter.billingIssues.errorMessage}}
                        </p>

                        <p *ngIf="displayUnlockMessageForMatter" class="smallerFont matter-notice-text">The matter is now unlocked. To edit
                            the matter, please close
                            and reopen the matter.
                        </p>
                        <p *ngIf="disableMatterForThirdPartyDataRefresh" class="smallerFont matter-notice-text">Please
                            wait while data from
                            your integrations related to this matter is being refreshed. <input type="button"
                                                                                                class="dp-btn-white cancel-btn"
                                                                                                (click)="cancelThirdPartyRefresh();"
                                                                                                value="Cancel"
                                                                                                title="Click here to cancel refreshing integrations related to this matter."
                            /></p>
                        <p *ngIf="isMatterProjectLocked()" class="smallerFont matter-notice-text">All fields are read
                            only as you are editing the project record</p>
                        <p *ngIf="isLinkedMatterProjectLocked()" class="smallerFont matter-notice-text">All fields are
                            read only as you are editing the project record of the linked project sale matter</p>
                        <p *ngIf="isMatterInaccessible()"
                           class="smallerFont matter-notice-text">{{inaccessibleMessage()}}</p>
                        <p *ngIf="!isMatterLocked" class="smallerFont">&nbsp;</p>
                    </div>
                </div>

                <div class="form-group clear">
                    <div *ngIf="saveError">
                        <div class="alert alert-danger col-lg-12">
                            <p>{{saveError}}</p>
                        </div>
                    </div>
                    <div class="xpath-container"
                         [dp-read-only-block]="isReadOnlyBlock()"
                         [elementRefComp]="elementRef"
                         [ngClass]="{ 'matter-form-area-locked' : isMatterLocked,'matter-form-area' : !isMatterLocked }">
                        <ng-container  *ngTemplateOutlet="matterRouterOutlet"></ng-container>
                    </div>

                </div>
            </div>
            <!-- END - Main Content -->

        </div>
    </div>
    <div class="matter-sticky-notes-message"
         *ngIf="isNotesAvailable(activeSection)">
        {{getNotes()}}
    </div>
</div>
<div class="container"
     *ngIf="isMassUpdateTabSelectionLevel() || isMassUpdateTabUpdateLevel() || isMassUpdateTabCompletedLevel() || isMassUpdateTabPrecedentLevel()">
    <div class="mass-update-fixed-div">
        <label class="control-label padding-top-25 bold" *ngIf="!isMassUpdateTabPrecedentLevel()">
            {{isMassUpdateTabSelectionLevel() ? 'Select matters to mass update:' : 'Mass Update status:'}}
        </label>
        <label class="control-label padding-top-25 bold" *ngIf="isMassUpdateTabTypeSoaGlCodes()">
            Select a precedent matter for Mass Update of Interim GL Accounting Codes in the Statement of Account.
        </label>
        <label class="control-label padding-top-25 bold" *ngIf="isMassUpdateTabTypeStatementOfAccount()">
            Select a precedent matter for Mass Update of {{getMassUpdateMode()}} Statement of Account.
        </label>
        <label class="control-label padding-top-25 bold" *ngIf="isMassUpdateTabTypeTrustLedger()">
            Select a precedent matter for Mass Update of {{getMassUpdateMode()}} Trust Ledger.
        </label>
        <ul class="float-right padding-top-10">
            <li class="padding-right-15 float-left position-relative"
                *ngIf="!isMassUpdateTabCompletedLevel() && isMassUpdateSupportBackToTemplateMatter() && !isMassUpdateTabPrecedentLevel()">
                <button class="dp-btn-white project-btns" type="button" name="backMassUpdateBtn" spellcheck="false"
                        (click)="backMassUpdate()">
                    Back
                </button>
            </li>
            <li class="padding-right-15  float-left position-relative">
                <button class="dp-btn-white project-btns" type="button" name="exitMassUpdateBtn" spellcheck="false"
                        (click)="exitMassUpdate()">
                    Exit
                </button>
            </li>
            <li class="float-left position-relative" *ngIf="isMassUpdateTabSelectionLevel()">
                <button class="dp-btn-green project-btns" type="button" name="rundMattersBtn"
                        (click)="confirmMassUpdate()"
                        [disabled]="!selectedProjectBasedMattersCnt || selectedProjectBasedMattersCnt == 0"
                        spellcheck="false">{{runMassUpdateBtnLabel}}
                </button>
            </li>
            <li class="float-left position-relative"
                *ngIf="isMassUpdateRestartVisible()">
                <button class="dp-btn-green project-btns" type="button" name="runMattersBtn" spellcheck="false"
                        (click)="restartMassUpdate()">
                    Resume Mass Update
                </button>
            </li>
            <li class="float-left position-relative" *ngIf="isMassUpdateTabUpdateLevel()">
                <button class="dp-btn-green project-btns" type="button" name="runMattersBtn"
                        (click)="performMassUpdate()"
                        [disabled]="!selectedProjectBasedMattersCnt || selectedProjectBasedMattersCnt == 0"
                        spellcheck="false">{{proceedMassUpdateBtnLabel}}
                </button>
            </li>
        </ul>
    </div>
    <div class="row">
        <div class="col-lg-12 matter-section-area"
             *ngIf="isMassUpdateTabSelectionLevel() || isMassUpdateTabPrecedentLevel()">
            <ng-container  *ngTemplateOutlet="matterRouterOutlet"></ng-container>
        </div>
        <div class="col-lg-12 padding-top-50" *ngIf="isMassUpdateTabUpdateLevel() || isMassUpdateTabCompletedLevel()">
            <div class="mass-update-box">
                <ng-container *ngFor="let item of massUpdateMessages">
                    <label class="control-label col-lg-12 padding-top-5" [innerHtml]="item"></label>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<ng-template #matterRouterOutlet>
    <router-outlet
        (activate)="onActivate($event, outlet)" #outlet></router-outlet>
</ng-template>
<!--
[dp-read-only-block]="isMatterReadOnlyAccess || !isMatterLocked"
<div class="matter-lock" *ngIf="isMatterLocked">
    <span><i class="fa fa-lock"></i></span><br/>
    <span><strong>Matter Locked</strong></span><br/>
   <span class="smallerFont"> By: {{matterLockedByUserName}} <br/><br/> All fields are read only.</span>
</div>

<div class="matter-unlock" *ngIf="isMatterLocked === false  && showunlockedmatter===true">
    <span><i class="fa fa-unlock"></i></span><br/>
    <span><strong>The Matter is now unlocked.</strong></span><br/><br/>
    <span class="smallerFont">To edit the Matter, please close and reopen the Matter.</span>
</div>
-->
