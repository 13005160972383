import {Directive, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {Utils} from '../matters/shared/utils';

declare var jQuery: any;

@Directive({
  selector: '[ngModel][dp-alphanumeric-only]',
  host: {
    '(input)': 'onInput($event)'
  }
})

export class AlphanumericOnly implements OnInit {
  utils: any;

  constructor(private model: NgModel) {
  }

  ngOnInit() {
    this.utils = new Utils();
  }

  onInput(e) {
    if (this.model.value) {
      // get the cursor position
      let cursorPosition = this.model.value.slice(0, e.target.selectionStart).length;
      let val = this.model.value.replace(/[\W_]+/g, '');

      this.model.reset(val);
      this.model.valueAccessor.writeValue(val);
      if (this.utils.isIE()) {
        // put cursor back to original position to deal with IE putting cursor at end
        this.setSelectionRange(e.target, cursorPosition, cursorPosition);
      }
    }
  }

  setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      let range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }

}
