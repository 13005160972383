export const SoaConstants = {

  FEE_PURCHASE_TRANSACTION: 'Fee for acting on purchase transaction',
  FEE_ARRANGED_MORTGAGE: 'Fee re each mortgage arranged on purchase',
  FEE_ADDITIONAL_MORTGAGE: 'Fee re each additional mortgage',
  FEE_PURCHASE_TRANSACTION_OUR: 'OUR FEE for acting on purchase',
  FEE_SALE_TRANSACTION_OUR: 'OUR FEE for acting on sale',
  FEE_MORTGAGE_TRANSACTION_OUR: 'OUR FEE for acting on mortgage',
  FEE_DISCHARGE_TRANSACTION_OUR: 'OUR FEE for acting on discharge',
  FEE_ARRANGED_MORTGAGE_OUR: 'OUR FEE re * mortgage arranged',
  FEE_ADDITIONAL_MORTGAGE_OUR: 'OUR fee for acting on additional * mortgage',
  FEE_PURCHASE_TRANSACTION_MY: 'MY FEE for acting on purchase',
  FEE_SALE_TRANSACTION_MY: 'MY FEE for acting on sale',
  FEE_MORTGAGE_TRANSACTION_MY: 'MY FEE for acting on mortgage',
  FEE_DISCHARGE_TRANSACTION_MY: 'MY FEE for acting on discharge',
  FEE_ARRANGED_MORTGAGE_MY: 'MY FEE re * mortgage arranged',
  FEE_ADDITIONAL_MORTGAGE_MY: 'MY fee for acting on additional * mortgage',
  NO_HST_TORONTO_LTT: 'Toronto Land Transfer Tax',
  NO_HST_ONTARIO_LTT: 'Ontario Land Transfer Tax',
  PAID_NO_HST_TORONTO_LTT: 'Paid Toronto Land Transfer Tax',
  PAID_NO_HST_ONTARIO_LTT: 'Paid Ontario Land Transfer Tax',
  PAID_NO_HST_ONTARIO_TORONTO_LTT: 'Paid Ontario and Toronto Land Transfer Tax',
  NO_HST_ONTARIO_TORONTO_LTT: 'Ontario and Toronto Land Transfer Tax',
  LAND_TRANSFER_TAX: 'Land Transfer Tax', // LTT for NB
  DEED_TRANSFER_TAX: 'Deed Transfer Tax', // LTT for NS
  PAID_LAND_TRANSFER_TAX: 'Paid Land Transfer Tax', // Paid LTT for NB
  PAID_NB_LAND_TRANSFER_TAX: 'Paid New Brunswick Land Transfer Tax', //NB_LTT_KEY FOR NB
  PAID_DEED_TRANSFER_TAX: 'Paid Deed Transfer Tax', // Paid LTT for NS
  PAID_ELECTRONIC_MORTGAGE_PRO_FEE: 'Paid Electronic Mortgage Processing Fee',
  ELECTRONIC_MORTGAGE_PRO_FEE: 'Electronic Mortgage Processing Fee',
  PAID_INSURANCE_PREMIUM: 'Paid Title Insurance Premium',
  INSURANCE_PREMIUM: 'Title Insurance Premium',
  TITLE_REGISTRATION_FEE: 'Title Registration Fee',
  MORTGAGE_REGISTRATION_FEE: 'Mortgage Registration fee',
  E_REG_SERVICE_CHARGE: 'E-Reg Service Charge',
  PAID_TITLE_REGISTRATION_FEE: 'Paid Title registration fee',
  PAID_MANITOBA_LTT: 'LTT',
  PAID_MORTGAGE_REGISTRATION_FEE: 'Paid Mortgage registration fee',
  TRUST_LEDGER_EXPENDITURE: 'Expenditure',
  TRUST_LEDGER_RECEIPT: 'Receipt',
  RECEIVED_BRIDGE_FINANCING: 'Received from Bridge Financing',
  RECEIVED_ARRANGED_FINANCING: 'Received from ### mortgagee',
  RECEIVED_ARRANGED_FINANCING_M_GE: 'Received from ### mortgage',
  RECEIVED_NEW_ARRANGED_FINANCING: 'Received from new ### mortgagee',
  RECEIVED_NEW_ARRANGED_FINANCING_M_GE: 'Received from new ### mortgage',
  PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE: 'Paid to discharge previous ### mortgage',
  PAID_DISCHARGE_PREVIOUS_MORTGAGEE: 'Paid discharge fee of previous ### mtgee',
  PAID_TO_BRIDGE_FINANCING: 'Paid to Bridge Financing',
  CASH_SHORTFALL: 'Cash Shortfall',
  BROKERAGE_FEES_TEXT: 'Paid * mtg brokerage fees to ',
  CONDO_STATUS_CERT_FEE_TEXT: 'Condominium Status Certificate',
  CONDOMINIUM_DOCUMENTS: 'Condominium Documents',
  STATUS_CERTIFICATE: 'Status Certificate',
  ESTOPPEL_CERTIFICATE: 'Estoppel Certificate',
  RETAIL_SALES_TAX_CHATTELS_TEXT: 'Retail Sales Tax on Chattels',
  CONDO_STATUS_CERT_DEFAULT_FEE: 100,
  NRST_DESC: 'Non-Resident Speculation Tax',
  PAID_TO_REAL_ESTATE_BROKER: 'Paid to real estate broker',
  RECEIVED_DEPOSIT_HELD_IN_TRUST: 'Received Deposit Held in Trust',
  PAID_REAL_ESTATE_BROKERS: 'Paid Real Estate Brokers',
  RECEIVED_REAL_ESTATE_BROKERS: 'Received from Real Estate Brokers',
  PAID_TO_VENDOR_BROKER: 'Paid Vendor\'s Broker',
  PAID_TO_VENDOR_RE_BROKER: 'Paid to Vendor\'s Real Estate Broker',
  PAID_TO_PURCHASER_BROKER: 'Paid Purchaser\'s Broker',
  PAID_TO_PURCHASER_RE_BROKER: 'Paid to Purchaser\'s Real Estate Broker',
  RECEIVED_EXCESS_DEPOSIT: 'Received excess deposit from',
  NOT_APPLICABLE: 'Not Application for Displaying',
  LABEL_ADDITIONAL_MORTGAGE_OUR: 'OUR FEE for acting on Mortgage transaction',
  LABEL_ADDITIONAL_MORTGAGE_MY: 'MY FEE for acting on Mortgage transaction',
  LABEL_LATE_CLOSING_INTEREST: 'Paid late closing interest',
  LABEL_LATE_CLOSING_INTEREST_ESTIMATE: 'Estimated mortgage interest',
  AS_PER_SOA: 'as per Statement of Adjustment',
  RECEIVED_FROM_PURCHASER_MORTGAGE: 'Received from purchaser\'s ### mortgage',
  RECEIVED_FROM_PURCHASER_MORTGAGEE: 'Received from purchaser\'s ### mortgagee',
  REGISTRATION_OF_TRANSFER: 'Registration of Transfer',
  RECORDING_A_DEED: 'Recording a Deed', // NS name for REGISTRATION_OF_TRANSFER
  REGISTER_TRANSFER_OF_LAND: 'Register Transfer of Land', //AB version of REGISTRATION_OF_TRANSFER
  REGISTRATION_OF_MORTGAGE: 'Registration of Mortgage',
  REGISTRATION_OF_MORTGAGE_DISCHARGE: 'Register Discharge of Mortgage',
  REGISTER_DISCHARGE: 'Register Discharge', // REGISTRATION_OF_MORTGAGE_DISCHARGE for NB
  REGISTRATION_OF_DISCHARGE: 'Registration of Discharge',
  RECORDING_A_DISCHARGE: 'Recording a Discharge', // REGISTRATION_OF_MORTGAGE_DISCHARGE for NS
  RECORDING_A_MORTGAGE: 'Recording a Mortgage',
  REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES: 'Register Discharge of other Encumbrances',
  PAID_TO_REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES: 'Paid to Register Discharge of other Encumbrances',
  PAID_FOR_REGISTRATION_OF_TRANSFER: 'Paid for Registration of Transfer',
  PAID_TO_REGISTER_TRANSFER_OF_LAND: 'Paid to Register Transfer of Land',
  REGISTRATION_OF_CHARGE: 'Registration Of Charge',
  REGISTER_MORTGAGE: 'Register Mortgage', //associated with REGISTRATION_OF_MORTGAGE_KEY
  PAID_TO_REGISTER_MORTGAGE: 'Paid to Register Mortgage', //associated with REGISTRATION_OF_MORTGAGE_KEY
  PAID_FOR_REGISTRATION_OF_CHARGE: 'Paid for Registration of Charge',
  PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE: 'Paid to Register Discharge of Mortgage',
  PAID_TO_REGISTER_DISCHARGE: 'Paid to Register Discharge', // PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE for NB
  PAID_RECORDING_A_DISCHARGE_FEE: 'Paid Recording a Discharge Fee', // PAID_TO_REGISTER_DISCHARGE_OF_MORTGAGE for NS
  PAID_TO_REGISTER_VTB_MORTGAGE: 'Paid to Register VTB Mortgage', //REGISTRATION_OF_VTB_MORTGAGE_KEY for NB
  PAID_TO_RECORD_A_VTB_MORTGAGE_FEE: 'Paid to Record a VTB Mortgage Fee', //REGISTRATION_OF_VTB_MORTGAGE_KEY for NS
  PAID_FOR_REGISTRATION_OF_MORTGAGE: 'Paid for Registration of Mortgage',
  PAID_RECORDING_A_MORTGAGE_FEE: 'Paid Recording a Mortgage Fee',
  PAID_RECORDING_A_DEED_FEE: 'Paid Recording a Deed Fee'

};

export enum AccountingItemKeys {
  ONTARIO_LTT_KEY,
  INSURANCE_PREMIUM_KEY,
  TITLE_REGISTRATION_FEE,
  MORTGAGE_REGISTRATION_FEE,
  PAID_TITLE_REGISTRATION_FEE,
  PAID_MORTGAGE_REGISTRATION_FEE,
  ELECTRONIC_MORTGAGE_FEE_KEY,
  FEE_PURCHASE_TRANSACTION_KEY,
  FEE_SALE_TRANSACTION_KEY,
  FEE_MORTGAGE_TRANSACTION_KEY,
  FEE_ARRANGED_MORTGAGE,
  FEE_ADDITIONAL_MORTGAGE,
  HST_MLTT_ADMIN_FEE,
  RESERVED_FOR_MORTGAGE,
  TRUST_LEDGER_LEGAL_FEES,
  PAID_TO_CLIENT,
  RECEIVED_FROM_CLIENT,
  PAID_TO_VENDOR,
  CONDO_STATUS_CERTIFICATE_FEE,
  CONDOMINIUM_DOCUMENTS,
  STATUS_CERTIFICATE,
  ESTOPPEL_CERTIFICATE,
  RETAIL_SALES_TAX_CHATTELS,
  E_REG_SERVICE_CHARGE_KEY,
  REGISTRATION_OF_TRANSFER_KEY,
  REGISTRATION_OF_CHARGE_KEY,
  TITLE_REGISTRATION_FEE_KEY,
  COMPLIANCE_ITEMS,
  NRST,
  REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY, //UI: Register Discharge of Mortgage
  REGISTRATION_OF_VTB_MORTGAGE_KEY, //UI: Register VTB Mortgage
  RECEIVED_FROM_PURCHASER_ON_CLOSING,
  PAID_TO_DISCHARGE_PREVIOUS_MORTGAGE,
  PAID_TO_REAL_ESTATE_BROKER,
  SEARCH_COSTS,
  EXECUTIONS_CERTIFICATE,
  TRANSACTION_LEVY_SURCHARGE,
  REGISTRATION_OF_MORTGAGE_KEY,
  REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY, //UI: Register Discharge of other Encumbrances (AB)
  REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY, //UI: Register Vendor's Lien Caveat (AB)
  DISCHARGE_VENDOR_LIEN_CAVEAT_KEY, //UI: Discharge Vendor's Lien Caveat (AB)
  SOFTWARE_TRANSACTION_CHARGE, //UI: Software Transaction Charge
  LAND_TITLE_SEARCHES,
  TITLE_SEARCH,
  CONSTRUCTION_MORTGAGE_HOLDBACKS,
  OTHER_HOLDBACKS,
  NB_LTT_KEY,
  NS_DTT_KEY,
  PARALEGAL_FEE_FOR_ACTING_ON_PURCHASE,
  PARALEGAL_FEE_FOR_ACTING_ON_SALE,
  PARALEGAL_FEE_FOR_ACTING_ON_MORTGAGE,
  TERANET_CONNECT_USAGE_CHARGE_KEY,
  FEE_DISCHARGE_TRANSACTION_KEY,
  PARALEGAL_FEE_FOR_ACTING_ON_DISCHARGE,
  FEE_WILL_TRANSACTION_KEY

}

