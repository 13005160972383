import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class TeraviewConfig extends BaseEntity {
  constructor(teraviewConfig?: TeraviewConfig) {
    super(teraviewConfig);
    if (!teraviewConfig) {
      this.docketIdType = 'BLANK';
      this.legalDescriptionType = 'PARCEL_REGISTER';
      this.documentNameType = 'DEFAULT';
    } else {
      this.customDocumentNameFields = teraviewConfig.customDocumentNameFields;
    }

  }

  id: number;
  docketIdType: string;
  documentNameType: string;
  customDocumentNameFields: string[] = [];
  legalDescriptionType: string;

  isDocumentNameTypeCustom(): boolean {
    return (this.documentNameType == 'CUSTOM');
  }

}
