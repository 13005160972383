import {Injectable} from '@angular/core';
import {mortgageeEnclosureApi} from './mortgagee-enclosure-api';
import {MortgageeEnclosureConfig} from './mortgagee-enclosure-config';
import {Logger} from '@nsalaun/ng-logger';
import {HttpClient} from '../../core/httpClient.service';
import {Observable} from 'rxjs';
import {ProvinceCode} from '../accounts/shared/account-province';

@Injectable()
export class MortgageeEnclosureService {
  constructor(private http: HttpClient, private logger: Logger) {
  }

  getMortgageeEnclosuresConfigForAccount(accountId: string, provinceCode?: ProvinceCode): Observable<MortgageeEnclosureConfig> {
    let url = mortgageeEnclosureApi.getMortgageeEnclosure.replace('{accountId}', accountId);
    if (provinceCode) {
      url += `?provinceCode=${ provinceCode }`;
    }
    return this.http.get(url)
    .map((res) => {
      return new MortgageeEnclosureConfig(res[ 'MORTGAGEE_ENCLOSURES' ]);
    });
  }

  updateMortgageeEnclosuresConfig(config: MortgageeEnclosureConfig, accountId: string, provinceCode: ProvinceCode) {
    let url = mortgageeEnclosureApi.putMortgageeEnclosure.replace('{accountId}', accountId).replace('{provinceCode}', provinceCode);
    return this.http.put(url, JSON.stringify(config))
    .map((res) => {
      return new MortgageeEnclosureConfig(res[ 'MORTGAGEE_ENCLOSURES' ]);
    });
  }

}
