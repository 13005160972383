import {Component, Inject, ViewChild} from '@angular/core';
import {AdjustmentTemplate} from '../../model/adjustment-template';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ModalResult} from '../../../../shared-main/enums';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ProvinceCode} from '../../../../admin/accounts/shared/base-province';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {AdjustmentTemplateService} from '../../adjustment-template.service';
import {KeyCode} from '../../../../shared-main/constants';
import {StatementAdjustmentKey} from '../../statement-adjustment-constants';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import * as _ from 'lodash';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var jQuery: any;

class AdjustmentTemplateRetrievePredefinedModalContext {
  public provinceCode: ProvinceCode;
  itemKey: string;
}

@Component({
  selector: 'dp-adjustment-template-retrieve-predefined',
  templateUrl: 'adjustment-template-retrieve-predefined.modal.component.html',
  styleUrls: ['./adjustment-template-retrieve-predefined.modal.component.scss'],
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class AdjustmentTemplateRetrievePredefinedModalComponent extends ModalComponent<AdjustmentTemplateRetrievePredefinedModalContext> {
  adjustmentTemplates: AdjustmentTemplate[];
  activeListItem: number = -1;
  activeTemplate: AdjustmentTemplate = null;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(
    public dialog: MatDialogRef<AdjustmentTemplateRetrievePredefinedModalComponent>,
    public dialogService: DialogService,
    public templateAdjustmentsService: AdjustmentTemplateService,
    @Inject(MAT_DIALOG_DATA) context?: AdjustmentTemplateRetrievePredefinedModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.templateAdjustmentsService.getAllTemplateAdjustmentsByItemKey(this.context.itemKey).subscribe((items) => {
      if (items) {
        this.adjustmentTemplates = _.sortBy(items, (adjTemplate) => {
          return adjTemplate.name.toLowerCase();
        });
      }
    });
  }

  checkForErrors() {
    if (!this.activeTemplate) {
      this.modalErrorComponent.createDPSaveError('matter.soadj.component.retrieve.template');
    }
  }

  select() {
    this.modalErrorComponent.removeAllDpSaveError();
    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close({action: ModalResult.OK, adjustmentTemplate: this.activeTemplate});
    }
  }

  cancel() {
    this.dialog.close({action: ModalResult.Cancel});
  }

  getTemplateBurgerMenuItems(): any[] {
    let items = [];
    items.push({text: 'Delete', key: 'DELETE'});
    return items;
  }

  async clickTemplateBurgerMenu(menuItem: any, template: AdjustmentTemplate) {
    switch (menuItem) {
      case 'DELETE':
        let toProceed = this.dialogService
          .confirm('Confirmation', 'Do you wish to delete this item?', false)
          .toPromise();
        if (toProceed) {
          await this.deleteItem(template);
        }

        break;
      default:
        break;
    }
  }

  setActiveIndex(index, template: AdjustmentTemplate): void {
    this.activeListItem = index;
    this.activeTemplate = template;
    setTimeout(() => {
      jQuery('.componentAdjustmentTemplatesList li:nth-child(' + (this.activeListItem + 1) + ')').focus();
    }, 100);
  }

  async deleteItem(template: AdjustmentTemplate) {
    this.activeListItem = -1;
    this.activeTemplate = null;
    await this.templateAdjustmentsService.deleteAdjustmentTemplate(template.id).toPromise();
    this.adjustmentTemplates = await this.templateAdjustmentsService
      .getAllTemplateAdjustmentsByItemKey(this.context.itemKey)
      .toPromise();
  }

  /** Keyup event handler */
  onSelectionChange(event: any): void {
    //console.warn("CustomPickListModalComponent | onSelectionChange | event = ", event);
    if (event.which === KeyCode.Enter) {
      this.select();
    }

    if (event.which === KeyCode.UpArrow) {
      if (this.activeListItem > 0) {
        const index: number = this.activeListItem - 1;
        this.setActiveIndex(index, this.adjustmentTemplates[index]);
      }
    }

    if (event.which === KeyCode.DownArrow) {
      if (this.activeListItem < this.adjustmentTemplates.length - 1) {
        const index: number = this.activeListItem + 1;
        this.setActiveIndex(index, this.adjustmentTemplates[index]);
      }
    }
  }

  get adjTitle(): string {
    if (this.context.itemKey === StatementAdjustmentKey.OTHER_FIXED) {
      return 'Other (Fixed Amount)';
    }
    if (this.context.itemKey === StatementAdjustmentKey.COMPONENT_ADJUSTMENT) {
      return 'Component';
    }
    return 'Component';
  }
}
