import {BaseEntity} from '../../shared/BaseEntity/base-entity';

export class RequisitionInstrumentConfig extends BaseEntity {

  id: number;
  customerAccountId: number;
  description: string;
  longDescription: string;
  requisitionName: string;
  encumbranceType: string;
  instanceType: string;
}
